<div>
  <app-operator-filter class="dash clearfix" *ipvIsPromoterOrAdmin [sendNull]="true" [selectDateRange]="selectedRange"
    [selectedMerchant]="selectedMerchantID" [displayMerchant]="isDisplayMerchant" [displayDateFrom]="false"
    [displayDateTo]="false" [displayProduct]="false" [displayDentist]="false" [setAutoSelectMerchant]="true"
    (getMerchant)="getMerchant($event)" (getDateTo)="getDateTo($event)" (getDateFrom)="getDateFrom($event)"
    (search)="getResult($event)"></app-operator-filter>

  <app-operator-filter class="dash clearfix" *ipvIsPromoterOrAdmin="false" [sendNull]="true"
    [selectDateRange]="selectedRange" [displayMerchant]="false" [displayDateFrom]="false" [displayDateTo]="false"
    [displayProduct]="false" [displayDentist]="false" (getMerchant)="getMerchant($event)"
    (getDateTo)="getDateTo($event)" (getDateFrom)="getDateFrom($event)" (search)="getResult($event)">
  </app-operator-filter>

  <ng-container *ipvIsPromoterOrAdmin>
    <div class="row clearfix" *ngIf="isSelectMerchantFromFilter == false">
      <h3 class="sr-title">Please select merchant to load data</h3>
    </div>
  </ng-container>

  <div class="warning subMobShow">
    <p>
      <strong>Note:</strong> Viewing the merchant dashboard on a mobile device may result in
      a sub-optimal experience. A dedicated mobile dashboard is coming soon!
    </p>
  </div>

  <div class="backbuttons rel">
    <button class="pull-right absPos btn-back" *ngIf="displayMembership" (click)="goBack()" mat-raised-button
      color="primary">
      <mat-icon>chevron_left</mat-icon>
      Dashboard
    </button>

    <button class="pull-right absPos btn-back" *ngIf="displayInvitation" (click)="goBack()" mat-raised-button
      color="primary">
      <mat-icon>chevron_left</mat-icon>
      Dashboard
    </button>

    <button class="pull-right absPos btn-back" *ngIf="displayActivePatients" (click)="goBack()" mat-raised-button
      color="primary">
      <mat-icon>chevron_left</mat-icon>
      Dashboard
    </button>

    <button class="pull-right absPos btn-back" *ngIf="isDisplayNotes" (click)="cancelDisplayNotes()" mat-raised-button
      color="primary">
      <mat-icon>chevron_left</mat-icon>
      Dashboard
    </button>
  </div>

  <div class="container clearfix main dashboard-card-mt"
    [hidden]="displayMembership || displayInvitation || displayPatients || displayActivePatients || isDisplayNotes">
    <div class="row clearfix qa flex">
      <div class="quick-actions half">
        <mat-card class="quick-actions-card overview-card card flex flex-col" [class.promoter]="promoterTools">
          <app-merchant-welcome-card id="tool1" [isSession]="isSession" [merchantID]="merchantID">
          </app-merchant-welcome-card>

          <div class="tipsContainer" *ngIf="isModuleTipsActive && tips.length > 0">
            <div class="row clearfix">
              <h3 class="sm-h3 white rm-m mb truncateTitle pull-left" matTooltip="{{ currentTip.title }}">
                <mat-icon>flash_on</mat-icon>
                {{ currentTip.title }}
              </h3>

              <div class="pull-right" *ipvIsPromoterOrAdmin>
                <button class="white" [matMenuTriggerFor]="menu10" mat-icon-button>
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu10="matMenu">
                  <button [routerLink]="['/merchant/', { outlets: { page: ['tip-over-view'] } }]" mat-menu-item>
                    <mat-icon>settings</mat-icon>
                    Manage Tips
                  </button>
                </mat-menu>
              </div>
              <hr />
            </div>
            <div class="row clearfix mt">
              <div class="row clearfix" [innerHtml]="currentTip.content | safeHtml"></div>
              <i class="clearFix pull-right grey">{{ currentTip.updated_at | TimeLabelPast }} ago</i>
            </div>
            <hr *ngIf="tips.length > 1" />
            <div class="row clearfix switches" *ngIf="tips.length > 1">
              <div class="pull-right">
                <button class="white" (click)="previousTip()" mat-button>
                  <mat-icon>chevron_left</mat-icon>
                </button>
                <button class="white" (click)="nextTip()" mat-button>
                  <mat-icon>chevron_right</mat-icon>
                </button>
              </div>
            </div>
          </div>

          <div class="full-width welcome-block clearfix flex">
            <div class="deep primary-gradient-img"></div>
            <div class="deep site_background"></div>
          </div>
        </mat-card>
      </div>

      <div class="quick-actions" *ngIf="!hideTaskPanel && showOnboarding">
        <mat-card class="quick-actions-card card PatNumCard">
          <div class="colour-stripe" *ngIf="stripe"></div>
          <div class="row clearfix">
            <div class="pull-left">
              <h3 class="sm-h3 sr-title rm-m mb">
                <mat-icon>grade</mat-icon>
                Complete your account!
              </h3>
            </div>

            <hr />
          </div>

          <app-tasks-view *ngIf="showOnboarding" [merchantID]="merchantID" (hideTaskPanel)="hideTaskPanelEvent($event)">
          </app-tasks-view>
        </mat-card>
      </div>

      <div class="quick-actions" *ngIf="!invitationLoaded">
        <mat-card class="quick-actions-card hasButton card rel">
          <!-- css loader -->
          <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <!-- /css loader -->
        </mat-card>
      </div>
      <div class="quick-actions"
        *ngIf="isModuleTreatmentPlanActive && statisticInvitation && statisticInvitation.status && context!='p4p' && context!='breeze'">
        <mat-card class="quick-actions-card hasButton stacked card" id="tool3">
          <div class="colour-stripe" *ngIf="stripe"></div>
          <div class="row clearfix">
            <div class="pull-left">
              <h3 class="sm-h3 sr-title rm-mb rm-mt">
                <mat-icon>drafts</mat-icon>
                Invitations
              </h3>
            </div>
            <div class="pull-right">
              <button id="treatment-action-menu" [matMenuTriggerFor]="menu1" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu1="matMenu">
                <button *ngIf="isModuleOverviewActive == true"
                  [routerLink]="['/merchant/', { outlets: { page: ['invitation-overview'] } }]" mat-menu-item>
                  <mat-icon>visibility_on</mat-icon>
                  <span> Overview </span>
                </button>
                <mat-divider></mat-divider>
                <button (click)="displayInvitations(null)" mat-menu-item>
                  <span> All Invitations</span>
                </button>

                <mat-divider></mat-divider>
                <button [matMenuTriggerFor]="subMenuWon" mat-menu-item>
                  <span>Invitations Won</span>
                </button>
                <button [matMenuTriggerFor]="subMenuMaybe" mat-menu-item>
                  <span>Invitations Pending</span>
                </button>
                <button [matMenuTriggerFor]="subMenuLost" mat-menu-item>
                  <span>Invitations Lost</span>
                </button>

                <mat-menu #subMenuWon="matMenu">
                  <button *ngFor="
                      let p of statisticInvitation.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.won
                        | orderBy: ['name']
                    " (click)="displayInvitations(p.ID)" mat-menu-item>
                    <span > {{invitationUtil.getStatusLabel(p.ID) | translate }} </span>
                  </button>
                </mat-menu>
                <mat-menu #subMenuMaybe="matMenu">
                  <button *ngFor="
                      let p of statisticInvitation.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.maybe
                        | orderBy: ['name']
                    " (click)="displayInvitations(p.ID)" mat-menu-item>
                    <span> {{invitationUtil.getStatusLabel(p.ID) | translate }} </span>
                  </button>
                </mat-menu>
                <mat-menu #subMenuLost="matMenu">
                  <button *ngFor="
                      let p of statisticInvitation.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.lost
                        | orderBy: ['name']
                    " (click)="displayInvitations(p.ID)" mat-menu-item>
                    <span > {{invitationUtil.getStatusLabel(p.ID) | translate }}  </span>
                  </button>
                </mat-menu>
              </mat-menu>
            </div>
            <hr />
          </div>

          <div class="row clearfix rel mt" id="treatment-chart">
            <div class="row full-width clearfix" *ngIf="invitationStatusGroup == 'proceed'">
              <div class="row clearfix rel">
                <div class="total anim">
                  <span class="totLab">Proceeding</span>
                  <span [endVal]="
                      statisticInvitation.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.won
                        | objectPropertyValue: 'Count(*)'
                        | sum
                    " countUp></span>
                  <span class="totSub">Total</span>
                </div>
                <app-chart-input [showLegend]="false" [showLabel]="false" [view]="undefined" [data]="
                    statisticInvitation.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.won
                      | orderBy: ['name']
                  " [chartType]="'pie'" [dataNameAttribute]="'name'" [xAxisLabel]="'Invitation(s)'"
                  [yAxisLabel]="'Status'" [dataValueAttribute]="'Count(*)'" [title]="''"></app-chart-input>
              </div>
              <div class="row clearfix" @labelAnimation>
                <label class="rm-mt num linetotal anim">
                  <span class="wide">
                    <strong class="greyble">Total Sent</strong>
                    All invitations that you've sent</span>
                  <strong class="pill">{{ statisticInvitation.global['Count(*)'] }}</strong>
                </label>
                <label class="rm-mt num anim" *ngFor="
                    let p of statisticInvitation.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.won
                      | orderBy: ['name'];
                    let i = index
                  " (click)="displayInvitations(p.ID)">
                  <span class="wide">
                    <strong class="greyble" [innerHTML]="invitationUtil.getStatusTitle(p.ID) | safeHtml"
                      [style.color]="chartColors[i % chartColors.length]"></strong>
                      {{invitationUtil.getStatusLabel(p.ID) | translate }}
                  </span>
                  <strong class="pill" [style.background]="chartColors[i % chartColors.length]">{{
                    p['Count(*)']
                    }}</strong>
                </label>
              </div>
            </div>

            <div class="row full-width clearfix" *ngIf="invitationStatusGroup == 'maybe'">
              <!-- <div class="full-width"> -->
              <div class="row clearfix rel">
                <div class="total anim">
                  <span class="totLab">Maybe</span>
                  <span [endVal]="
                      statisticInvitation.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.maybe
                        | objectPropertyValue: 'Count(*)'
                        | toNumberArray
                        | sum
                    " countUp></span>
                  <span class="totSub">Total</span>
                </div>
                <app-chart-input [showLegend]="false" [showLabel]="false" [view]="undefined" [data]="
                    statisticInvitation.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.maybe
                      | orderBy: ['name']
                  " [chartType]="'pie'" [dataNameAttribute]="'name'" [xAxisLabel]="'Invitation(s)'"
                  [yAxisLabel]="'Status'" [dataValueAttribute]="'Count(*)'" [title]="''"></app-chart-input>
              </div>
              <div class="row clearfix" @labelAnimation>
                <label class="rm-mt num anim" *ngFor="
                    let p of statisticInvitation.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.maybe
                      | orderBy: ['name'];
                    let i = index
                  " (click)="displayInvitations(p.ID)">
                  <span class="wide">
                    <strong class="greyble" [innerHTML]="invitationUtil.getStatusTitle(p.ID) | safeHtml"
                      [style.color]="chartColors[i % chartColors.length]"></strong>
                      {{invitationUtil.getStatusLabel(p.ID) | translate }}
                  </span>
                  <strong class="pill" [style.background]="chartColors[i % chartColors.length]">{{
                    p['Count(*)']
                    }}</strong>
                </label>
              </div>
            </div>

            <div class="row full-width clearfix" *ngIf="invitationStatusGroup == 'notProceed'">
              <div class="row clearfix rel">
                <div class="total anim">
                  <span class="totLab">Not proceeding</span>
                  <span [endVal]="
                      statisticInvitation.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.lost
                        | objectPropertyValue: 'Count(*)'
                        | toNumberArray
                        | sum
                    " countUp></span>
                  <span class="totSub">Total</span>
                </div>
                <app-chart-input [showLegend]="false" [showLabel]="false" [view]="undefined" [data]="
                    statisticInvitation.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.lost
                      | orderBy: ['name']
                  " [chartType]="'pie'" [dataNameAttribute]="'name'" [xAxisLabel]="'Invitation(s)'"
                  [yAxisLabel]="'Status'" [dataValueAttribute]="'Count(*)'" [title]="''"></app-chart-input>
              </div>
              <div class="row clearfix" @labelAnimation>
                <label class="rm-mt num anim" *ngFor="
                    let p of statisticInvitation.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.lost
                      | orderBy: ['name'];
                    let i = index
                  " (click)="displayInvitations(p.ID)">
                  <span class="wide">
                    <strong class="greyble" [innerHTML]="invitationUtil.getStatusTitle(p.ID) | safeHtml"
                      [style.color]="chartColors[i % chartColors.length]"></strong>
                      {{invitationUtil.getStatusLabel(p.ID) | translate }}
                  </span>
                  <strong class="pill" [style.background]="chartColors[i % chartColors.length]">{{
                    p['Count(*)']
                    }}</strong>
                </label>
              </div>
              <!-- </div> -->
            </div>
          </div>

          <div class="row clearfix">
            <div class="row clearfix mt invButtonRow">
              <div class="toggles" id="toggles">
                <mat-button-toggle-group #group1="matButtonToggleGroup" [(ngModel)]="invitationStatusGroup">
                  <mat-button-toggle class="pos" [value]="'proceed'" matTooltip="Proceeding">
                    <mat-icon>mood</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle class="maybe" [value]="'maybe'" matTooltip="Maybe">
                    <mat-icon>sentiment_satisfied</mat-icon>
                  </mat-button-toggle>

                  <mat-button-toggle class="neg" [value]="'notProceed'" matTooltip="Not Proceeding">
                    <mat-icon>mood_bad</mat-icon>
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>

              <app-invitation-new-button class="full-width" [title]="'Send an invitation!'" [btnLarge]="true"
                [isMemberButton]="false" [isQuoteButton]="false" [isInviteButton]="true">
              </app-invitation-new-button>
            </div>
          </div>
        </mat-card>
      </div>

      <div class="quick-actions" *ngIf="invitationLoaded != true">
        <mat-card class="quick-actions-card hasButton card rel">
          <!-- css loader -->
          <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <!-- /css loader -->
        </mat-card>
      </div>
      <div class="quick-actions"
        *ngIf="isModuleTreatmentPlanActive == true && statisticInvitation && statisticInvitation.status">
        <mat-card class="quick-actions-card hasButton card" id="toosl3">
          <div class="colour-stripe" *ngIf="stripe == true"></div>
          <div class="row clearfix">
            <div class="pull-left">
              <h3 class="sm-h3 sr-title rm-mb rm-mt">
                <mat-icon>class</mat-icon>
                Finance Status
              </h3>
            </div>
            <div class="pull-right">
              <button id="treatment-action-menu" [matMenuTriggerFor]="menu12" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu12="matMenu">
                <button *ngIf="isModuleSettlementsActive == true"
                  [routerLink]="['/merchant/', { outlets: { page: ['settlement-list-overview'] } }]" mat-menu-item>
                  <mat-icon class="fas fa-comment-dollar" aria-hidden="true"></mat-icon>
                  New Settlement
                </button>
                <mat-divider></mat-divider>
                <button *ngIf="isModulePerformanceActive == true"
                  [routerLink]="['/merchant/', { outlets: { page: ['invitation-performance'] } }]" mat-menu-item>
                  <mat-icon class="fas fa-tachometer-alt" aria-hidden="true"></mat-icon>
                  Performance
                </button>

                <mat-divider></mat-divider>

                <button *ngFor="
                    let p of statisticInvitation.status | FilterArrayMultipleValue: 'ID':invitationUtil.status.contract
                  " (click)="displayInvitations(p.ID)" mat-menu-item>
                  <span [style.color]="newColorSet[i % newColorSet.length]">
                    {{invitationUtil.getStatusLabel(p.ID) | translate }}
                  </span>
                </button>
              </mat-menu>
            </div>
            <hr />
          </div>
          <div class="row clearfix mt">
            <div class="row clearfix rel">
              <div class="total anim">
                <span class="totLab">Total</span>
                <span [endVal]="
                    statisticInvitation.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.contract
                      | objectPropertyValue: 'Count(*)'
                      | toNumberArray
                      | sum
                  " countUp></span>
              </div>
              <app-chart-input [showLegend]="false" [showLabel]="false" [view]="undefined"
                [data]="statisticInvitation.status | FilterArrayMultipleValue: 'ID':invitationUtil.status.contract"
                [chartType]="'pie'" [dataNameAttribute]="'name'"
                [xAxisLabel]="('KEYWROD.Invitation' | translate) + '(s)'" [yAxisLabel]="'Status'"
                [dataValueAttribute]="'Count(*)'" [title]="''" [colorScheme]="newColorSet"></app-chart-input>
            </div>
            <div class="row clearfix" @labelAnimation>
              <label class="rm-mt num anim statusList" *ngFor="
                  let p of statisticInvitation.status | FilterArrayMultipleValue: 'ID':invitationUtil.status.contract;
                  let i = index
                " (click)="displayInvitations(p.ID)">
                <span class="wide splitLabel">
                  <strong class="greyble" [style.color]="newColorSet[i % newColorSet.length]">{{
                    invitationUtil.getStatusTitle(p.ID)
                    }}</strong>
                  {{ invitationUtil.getStatusLabel(p.ID)| translate }}</span>

                <strong class="pill" [style.background]="newColorSet[i % newColorSet.length]">{{
                  p['Count(*)']
                  }}</strong>
              </label>
            </div>
          </div>

          <div class="row clearfix mt invButtonRow flex">
            <button class="btn-large gradient red emButton mb" *ngIf="isModulePerformanceActive"
              [class.full-width]="!isModuleSettlementsActive"
              [routerLink]="['/merchant/', { outlets: { page: ['invitation-performance'] } }]" mat-raised-button
              color="accent" matTooltip="View    {{'KEYWORD.practice' | translate }} performance">
              <mat-icon class="fas fa-tachometer-alt" aria-hidden="true"></mat-icon>
              <span *ngIf="!isModuleSettlementsActive">&nbsp;Performance</span>
            </button>
            <button class="btn-large gradient green mb" *ngIf="isModuleSettlementsActive"
              [routerLink]="['/merchant/', { outlets: { page: ['settlement-list-overview'] } }]" mat-raised-button
              color="accent">
              <mat-icon class="fas fa-comment-dollar" aria-hidden="true"></mat-icon>
              New Settlement
            </button>
          </div>
        </mat-card>
      </div>

      <div class="quick-actions" *ngIf="invitationMembershipLoaded">
        <mat-card class="quick-actions-card hasButton card rel">
          <!-- css loader -->
          <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <!-- /css loader -->
        </mat-card>
      </div>
      <div class="quick-actions"
        *ngIf="isModuleMembershipActive == true && statisticMembership && statisticMembership.status">
        <mat-card class="quick-actions-card hasButton stacked card" id="tool3">
          <div class="colour-stripe" *ngIf="stripe == true"></div>
          <div class="row clearfix">
            <div class="pull-left">
              <h3 class="sm-h3 sr-title rm-mb rm-mt">
                <mat-icon>card_membership</mat-icon>
                Memberships
              </h3>
            </div>
            <div class="pull-right">
              <button id="treatment-action-menu" [matMenuTriggerFor]="menu1" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu1="matMenu">
                <mat-divider></mat-divider>
                <button (click)="displayMembershipList(null)" mat-menu-item>
                  <span>All Invitations </span>
                </button>

                <mat-divider></mat-divider>
                <button [matMenuTriggerFor]="subMenuWon" mat-menu-item>
                  <span>Invitations Won</span>
                </button>
                <button [matMenuTriggerFor]="subMenuMaybe" mat-menu-item>
                  <span> Invitations Pending</span>
                </button>
                <button [matMenuTriggerFor]="subMenuLost" mat-menu-item>
                  <span>Invitations Lost</span>
                </button>

                <mat-menu #subMenuWon="matMenu">
                  <button *ngFor="
                      let p of statisticMembership.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.won
                        | orderBy: ['name']
                    " (click)="displayMembershipList(p.ID)" mat-menu-item>
                    <span>  {{invitationUtil.getStatusLabel(p.ID) | translate }}</span>
                  </button>
                </mat-menu>
                <mat-menu #subMenuMaybe="matMenu">
                  <button *ngFor="
                      let p of statisticMembership.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.maybe
                        | orderBy: ['name']
                    " (click)="displayMembershipList(p.ID)" mat-menu-item>
                    <span>  {{invitationUtil.getStatusLabel(p.ID) | translate }}</span>
                  </button>
                </mat-menu>
                <mat-menu #subMenuLost="matMenu">
                  <button *ngFor="
                      let p of statisticMembership.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.lost
                        | orderBy: ['name']
                    " (click)="displayMembershipList(p.ID)" mat-menu-item>
                    <span>  {{invitationUtil.getStatusLabel(p.ID) | translate }}</span>
                  </button>
                </mat-menu>
              </mat-menu>
            </div>
            <hr />
          </div>

          <div class="row clearfix rel mt" id="treatment-chart">
            <div class="row full-width clearfix" *ngIf="membershipStatusGroup == 'proceed'">
              <div class="row clearfix rel">
                <div class="total anim">
                  <span class="totLab">Proceeding</span>
                  <span [endVal]="
                      statisticMembership.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.won
                        | objectPropertyValue: 'Count(*)'
                        | sum
                    " countUp></span>
                  <span class="totSub">Total</span>
                </div>
                <app-chart-input [showLegend]="false" [showLabel]="false" [view]="undefined" [data]="
                    statisticMembership.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.won
                      | orderBy: ['name']
                  " [chartType]="'pie'" [dataNameAttribute]="'name'" [xAxisLabel]="'Invitation(s)'"
                  [yAxisLabel]="'Status'" [dataValueAttribute]="'Count(*)'" [title]="''"></app-chart-input>
              </div>
              <div class="row clearfix" @labelAnimation>
                <label class="rm-mt num linetotal anim">
                  <span class="wide">
                    <strong class="greyble">Total Sent</strong>
                    All invitations that you've sent
                  </span>
                  <strong class="pill">{{ statisticMembership.global['Count(*)'] }}</strong>
                </label>
                <label class="rm-mt num anim" *ngFor="
                    let p of statisticMembership.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.won
                      | orderBy: ['name'];
                    let i = index
                  " (click)="displayMembershipList(p.ID)">
                  <span class="wide">
                    <strong class="greyble" [innerHTML]="invitationUtil.getStatusTitle(p.ID) | safeHtml"
                      [style.color]="chartColors[i % chartColors.length]"> </strong>
                      {{invitationUtil.getStatusLabel(p.ID) | translate }}
                  </span>
                  <strong class="pill" [style.background]="chartColors[i % chartColors.length]">{{
                    p['Count(*)']
                    }}</strong>
                </label>
              </div>
            </div>

            <div class="row full-width clearfix" *ngIf="membershipStatusGroup == 'maybe'">
              <!-- <div class="full-width"> -->
              <div class="row clearfix rel">
                <div class="total anim">
                  <span class="totLab">Maybe</span>
                  <span [endVal]="
                      statisticMembership.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.maybe
                        | objectPropertyValue: 'Count(*)'
                        | toNumberArray
                        | sum
                    " countUp></span>
                  <span class="totSub">Total</span>
                </div>
                <app-chart-input [showLegend]="false" [showLabel]="false" [view]="undefined" [data]="
                    statisticMembership.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.maybe
                      | orderBy: ['name']
                  " [chartType]="'pie'" [dataNameAttribute]="'name'" [xAxisLabel]="'Invitation(s)'"
                  [yAxisLabel]="'Status'" [dataValueAttribute]="'Count(*)'" [title]="''"></app-chart-input>
              </div>
              <div class="row clearfix" @labelAnimation>
                <label class="rm-mt num anim" *ngFor="
                    let p of statisticMembership.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.maybe
                      | orderBy: ['name'];
                    let i = index
                  " (click)="displayMembershipList(p.ID)">
                  <span class="wide">
                    <strong class="greyble" [innerHTML]="invitationUtil.getStatusTitle(p.ID) | safeHtml"
                      [style.color]="chartColors[i % chartColors.length]"></strong>
                      {{invitationUtil.getStatusLabel(p.ID) | translate }}
                  </span>
                  <strong class="pill" [style.background]="chartColors[i % chartColors.length]">{{
                    p['Count(*)']
                    }}</strong>
                </label>
              </div>
              <!-- </div> -->
            </div>

            <div class="row full-width clearfix" *ngIf="membershipStatusGroup == 'notProceed'">
              <!-- <div class="full-width"> -->
              <div class="row clearfix rel">
                <div class="total anim">
                  <span class="totLab">Not proceeding</span>
                  <span [endVal]="
                      statisticMembership.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.lost
                        | objectPropertyValue: 'Count(*)'
                        | toNumberArray
                        | sum
                    " countUp></span>
                  <span class="totSub">Total</span>
                </div>
                <app-chart-input [showLegend]="false" [showLabel]="false" [view]="undefined" [data]="
                    statisticMembership.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.lost
                      | orderBy: ['name']
                  " [chartType]="'pie'" [dataNameAttribute]="'name'" [xAxisLabel]="'Invitation(s)'"
                  [yAxisLabel]="'Status'" [dataValueAttribute]="'Count(*)'" [title]="''"></app-chart-input>
              </div>
              <div class="row clearfix" @labelAnimation>
                <label class="rm-mt num anim" *ngFor="
                    let p of statisticMembership.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.lost
                      | orderBy: ['name'];
                    let i = index
                  " (click)="displayMembershipList(p.ID)">
                  <span class="wide">
                    <strong class="greyble" [innerHTML]="invitationUtil.getStatusTitle(p.ID) | safeHtml"
                      [style.color]="chartColors[i % chartColors.length]"></strong>
                      {{invitationUtil.getStatusLabel(p.ID) | translate }}
                  </span>
                  <strong class="pill" [style.background]="chartColors[i % chartColors.length]">{{
                    p['Count(*)']
                    }}</strong>
                </label>
              </div>
              <!-- </div> -->
            </div>
          </div>

          <div class="row clearfix">
            <div class="row clearfix mt invButtonRow">
              <div class="toggles" id="toggles2">
                <mat-button-toggle-group #group1="matButtonToggleGroup" [(ngModel)]="membershipStatusGroup">
                  <mat-button-toggle class="pos" [value]="'proceed'" matTooltip="Proceeding">
                    <mat-icon>mood</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle class="maybe" [value]="'maybe'" matTooltip="Maybe">
                    <mat-icon>sentiment_satisfied</mat-icon>
                  </mat-button-toggle>

                  <mat-button-toggle class="neg" [value]="'notProceed'" matTooltip="Not Proceeding">
                    <mat-icon>mood_bad</mat-icon>
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>

              <app-invitation-new-button class="full-width" [title]="'New invitation'" [btnLarge]="true"
                [isMemberButton]="true" [isQuoteButton]="false" [isInviteButton]="false">
              </app-invitation-new-button>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- FEE FUNDING -->
      <div class="quick-actions" *ngIf="context === 'feefunding'">
        <mat-card class="quick-actions-card hasButton card">
          <div class="colour-stripe" *ngIf="stripe"></div>
          <div class="row clearfix">
            <div class="pull-left">
              <h3 class="sm-h3 sr-title rm-mb rm-mt">
                <i class="fas fa-money" aria-hidden="true"> </i> Bills Presented
              </h3>
            </div>
            <div class="pull-right">
              <button [matMenuTriggerFor]="menu3" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu3="matMenu">
                <button [routerLink]="['/merchant/', { outlets: { page: ['fee-funding-bill-create'] } }]" mat-menu-item>
                  <i class="fas fa-money-bill-alt" aria-hidden="true"> </i> Create New Bill
                </button>
                <button [routerLink]="['/merchant/', { outlets: { page: ['existing-patient-list-overview'] } }]"
                  [routerLinkActive]="['active']" mat-menu-item>
                  <span>View All Bills</span>
                </button>
              </mat-menu>
            </div>
            <hr />
          </div>
          <div class="row clearfix rel" @labelAnimation>
            <div class="row clearfix" *ngIf="selectedValueBills == 'billsChart1'">
              <div class="row clearfix rel">
                <div class="total anim">
                  <span class="totLab">Total</span>
                  <span class="totNum">{{ statisticInvitation.all }}</span>
                </div>
                <app-chart-input [showLabel]="false" [showLegend]="false" [data]="chartTreatments1 | orderBy: ['name']"
                  [chartType]="'pie'" [title]="Treatment"></app-chart-input>
              </div>
              <div class="row clearfix" @labelAnimation>
                <label class="rm-mt num red" (click)="displayInvitations('notViewed')">
                  <span> Not Viewed </span>
                  <strong>{{ statisticInvitation.notViewed }}</strong>
                </label>
                <label class="rm-mt num green" (click)="displayInvitations('viewed')">
                  <span> Viewed </span>
                  <strong>{{ statisticInvitation.viewed }}</strong>
                </label>
                <label class="rm-mt num" (click)="displayInvitations('expired')">
                  <span> Expired </span>
                  <strong>{{ statisticInvitation.expired }}</strong>
                </label>
              </div>
            </div>

            <ng-template [ngIf]="selectedValueBills == 'billCharts2'">
              <!-- <div class="full-width"> -->
              <div class="row clearfix rel">
                <div class="total anim">
                  <span class="totLab">Total</span>
                  <span class="totNum">{{ statisticInvitation.all }}</span>
                </div>
                <app-chart-input [showLabel]="false" [showLegend]="false" [data]="chartTreatments2 | orderBy: ['name']"
                  [chartType]="'pie'" [title]="fee - funding"></app-chart-input>
              </div>
              <div class="row clearfix" @labelAnimation>
                <label class="rm-mt num blue" (click)="displayInvitations('financed')">
                  <span> Financed </span>
                  <strong>{{ statisticInvitation.financed }}</strong>
                </label>
                <label class="rm-mt num green" (click)="displayInvitations('proceedingCash')">
                  <span> Paying Cash/Card </span>
                  <strong>{{ statisticInvitation.proceedingCash }}</strong>
                </label>
                <label class="rm-mt num orange" (click)="displayInvitations('notProceeding')">
                  <span> Not Proceeding </span>
                  <strong>{{ statisticInvitation.notProceeding }}</strong>
                </label>
              </div>
              <!-- </div> -->
            </ng-template>

            <div class="toggles">
              <mat-button-toggle-group #group2="matButtonToggleGroup" [(value)]="selectedValueBills">
                <mat-button-toggle (change)="selectionChangedBilling($event)" value="billsChart1">View Status
                </mat-button-toggle>
                <mat-button-toggle (change)="selectionChangedBilling($event)" value="billCharts2">Payment Status
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>

          <div class="row clearfix">
            <div class="row clearfix mt invButtonRow flex">
              <button class="btn-large gradient green mb"
                [routerLink]="['/merchant/', { outlets: { page: ['fee-funding-bill-create'] } }]" mat-raised-button
                color="primary">
                <i class="fas fa-money-bill-alt" aria-hidden="true"> </i> Create New Bill
              </button>
            </div>
          </div>
        </mat-card>
      </div>

      <div class="quick-actions" *ngIf="context == 'feefunding'">
        <mat-card class="quick-actions-card hasButton card">
          <div class="colour-stripe" *ngIf="stripe == true"></div>
          <div class="row clearfix">
            <div class="pull-left">
              <h3 class="sm-h3 sr-title rm-mb rm-mt"><i class="fas fa-coffee" aria-hidden="true"> </i> Engagements</h3>
            </div>
            <div class="pull-right">
              <button [matMenuTriggerFor]="menu3" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu3="matMenu">
                <button [routerLink]="['/merchant/', { outlets: { page: ['fee-funding-engagement-create'] } }]"
                  mat-menu-item>
                  <i class="fas fa-coffee" aria-hidden="true"> </i> New Engagement
                </button>
                <button [routerLink]="['/merchant/', { outlets: { page: ['existing-patient-list-overview'] } }]"
                  [routerLinkActive]="['active']" mat-menu-item>
                  <span>View All Engagements</span>
                </button>
              </mat-menu>
            </div>
            <hr />
          </div>
          <div class="row clearfix rel" @labelAnimation>
            <div class="row clearfix" *ngIf="selectedEngagementValue == 'engagementChart1'">
              <div class="row clearfix rel">
                <div class="total anim">
                  <span class="totLab"> Total </span>
                  <span class="totNum">{{ statisticInvitation.all }}</span>
                </div>
                <app-chart-input [showLabel]="false" [showLegend]="false" [data]="chartTreatments1 | orderBy: ['name']"
                  [chartType]="'pie'" [title]="Treatment"></app-chart-input>
              </div>
              <div class="row clearfix">
                <label class="rm-mt num red" (click)="displayInvitations('notViewed')">
                  <span>Not Viewed</span>
                  <strong>{{ statisticInvitation.notViewed }}</strong>
                </label>
                <label class="rm-mt num green" (click)="displayInvitations('viewed')">
                  <span> Viewed </span>
                  <strong>{{ statisticInvitation.viewed }}</strong>
                </label>
                <label class="rm-mt num" (click)="displayInvitations('expired')">
                  <span> Expired </span>
                  <strong>{{ statisticInvitation.expired }}</strong>
                </label>
              </div>
            </div>

            <ng-template [ngIf]="selectedEngagementValue == 'engagementChart2'">
              <!-- <div class="full-width"> -->
              <div class="row clearfix rel">
                <div class="total anim">
                  <span class="totLab">Total</span>
                  <span class="totNum">{{ statisticInvitation.all }}</span>
                </div>
                <app-chart-input [showLabel]="false" [showLegend]="false" [data]="chartTreatments2 | orderBy: ['name']"
                  [chartType]="'pie'" [title]="newEngagements"></app-chart-input>
              </div>
              <div class="row clearfix" @labelAnimation>
                <label class="rm-mt num blue" (click)="displayInvitations('financed')">
                  <span> Financed </span>
                  <strong>{{ statisticInvitation.financed }}</strong>
                </label>
                <label class="rm-mt num green" (click)="displayInvitations('proceedingCash')">
                  <span> Paying Cash/Card </span>
                  <strong>{{ statisticInvitation.proceedingCash }}</strong>
                </label>
                <label class="rm-mt num orange" (click)="displayInvitations('notProceeding')">
                  <span> Not Proceeding </span>
                  <strong>{{ statisticInvitation.notProceeding }}</strong>
                </label>
              </div>
              <!-- </div> -->
            </ng-template>

            <div class="toggles">
              <mat-button-toggle-group #group3="matButtonToggleGroup" [(value)]="selectedEngagementValue">
                <mat-button-toggle (change)="selectionEngagementChanged($event)" value="engagementChart1">View Status
                </mat-button-toggle>
                <mat-button-toggle (change)="selectionEngagementChanged($event)" value="engagementChart2">Payment Status
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>

          <div class="row clearfix">
            <div class="row clearfix mt invButtonRow flex">
              <button class="btn-large gradient purple mb"
                [routerLink]="['/merchant/', { outlets: { page: ['fee-funding-engagement-create'] } }]"
                mat-raised-button color="primary">
                <i class="fas fa-coffee" aria-hidden="true"> </i> New Engagement
              </button>
            </div>
          </div>
        </mat-card>
      </div>
      <!-- / FEE FUNDING -->

      <div class="quick-actions" *ngIf="patientLoaded != true">
        <mat-card class="quick-actions-card hasButton card rel">
          <!-- css loader -->
          <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <!-- /css loader -->
        </mat-card>
      </div>

      <div class="quick-actions" *ngIf="isModulePatientsActive == true && statisticInvitation">
        <mat-card class="quick-actions-card hasButton card PatNumCard">
          <div class="colour-stripe" *ngIf="stripe == true"></div>
          <div class="row clearfix">
            <div class="pull-left">
              <h3 class="sm-h3 sr-title rm-mb rm-mt">
                <mat-icon>face</mat-icon>
                {{ "KEYWORD.patient" | translate | titlecase}}s
              </h3>
            </div>

            <div class="pull-right">
              <button [matMenuTriggerFor]="menu3" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu3="matMenu">
                <button [routerLink]="['/merchant/', { outlets: { page: ['existing-patient-create'] } }]" mat-menu-item>
                  <mat-icon>person_add</mat-icon>
                  New {{ "KEYWORD.patient" | translate | titlecase }}
                </button>
                <mat-divider></mat-divider>
                <button (click)="displayPatientsList('all')" mat-menu-item>
                  <span>View All </span>
                </button>
                <mat-divider></mat-divider>
                <button (click)="displayPatientsList('customer')" mat-menu-item>
                  <span>View {{ "KEYWORD.patient" | translate | titlecase}}s</span>
                </button>

                <button (click)="displayPatientsList('prospect')" mat-menu-item>
                  <span>View Prospects</span>
                </button>
              </mat-menu>
            </div>
            <hr />
          </div>
          <div class="row clearfix mt">
            <div class="row clearfix rel">
              <label class="total anim">
                <span class="totLab">Total</span>
                <span [endVal]="statisticPatient | objectPropertyValue: 'Count(*)' | sum" countUp></span>
              </label>

              <app-chart-input [showLegend]="false" [showLabel]="false" [view]="undefined"
                [data]="statisticPatient | orderBy: ['name']" [chartType]="'pie'" [dataNameAttribute]="'name'"
                [xAxisLabel]="('KEYWORD.patient' | translate | titlecase) + 's'" [yAxisLabel]="''"
                [dataValueAttribute]="'Count(*)'" [title]="''">
              </app-chart-input>
            </div>
            <div class="row clearfix" @labelAnimation>
              <label class="rm-mt num anim" *ngFor="let p of statisticPatient | orderBy: ['name']; let i = index"
                (click)="displayPatientsList(p.ID)">
                <span class="wide">
                  <strong class="greyble" [style.color]="chartColors[i % chartColors.length]">
                    {{ customerProspectUtil.getTitle(p.ID) }}</strong>
                  {{ customerProspectUtil.getLabel(p.ID) }}</span>
                <strong class="pill" [style.background]="chartColors[i % chartColors.length]">{{
                  p['Count(*)']
                  }}</strong>
              </label>
            </div>
          </div>
          <div class="row clearfix mt invButtonRow">
            <button class="btn-large gradient blue mb"
              [routerLink]="['/merchant/', { outlets: { page: ['existing-patient-create'] } }]" mat-raised-button
              color="primary">
              <mat-icon>person_add</mat-icon>
              New {{ "KEYWORD.patient" | translate | titlecase }}
            </button>
          </div>
        </mat-card>
      </div>
      <div class="quick-actions" *ngIf="isModuleFacilityActive == true">
        <mat-card class="quick-actions-card hasButton card">
          <div class="colour-stripe" *ngIf="stripe == true"></div>
          <div class="row clearfix">
            <div class="pull-left">
              <h3 class="sm-h3 sr-title rm-mb rm-mt">
                <mat-icon>local_atm</mat-icon>
                Pre-Approved Facilities
              </h3>
            </div>
            <div class="pull-right">
              <button [matMenuTriggerFor]="menu4" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu4="matMenu">
                <button [routerLink]="['/merchant/', { outlets: { page: ['settlements-list'] } }]" mat-menu-item
                  mat-menu-item>
                  <mat-icon>monetization_on</mat-icon>
                  <span>New Settlement </span>
                </button>
              </mat-menu>
            </div>
            <hr />
          </div>
          <div class="row clearfix rel" @labelAnimation>
            <div class="row clearfix mt">
              <button class="btn-large gradient green mb"
                [routerLink]="['/merchant/', { outlets: { page: ['facility-invitation-create'] } }]"
                [routerLinkActive]="['active']" mat-raised-button color="primary">
                <mat-icon>local_atm</mat-icon>
                New Invitation
              </button>
            </div>

            <div class="row clearfix">
              <div class="row clearfix rel"></div>
              <div class="row clearfix" @labelAnimation>
                <label class="rm-mt num green">
                  <span>Active Facilities</span>
                  <strong>000</strong>
                </label>
              </div>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- </div> -->
      <div class="quick-actions" *ngIf="notifications == true">
        <app-notification-overview class="quick-actions-card"></app-notification-overview>
      </div>
      <!-- practice star rating -->
      <div class="quick-actions star-rating" *ngIf="isModuleStarRatingActive == true">
        <mat-card class="quick-actions-card card">
          <div class="colour-stripe" *ngIf="stripe == true"></div>
          <div class="row clearfix">
            <div class="pull-left">
              <h3 class="sm-h3 sr-title rm-mb rm-mt">
                <mat-icon>star</mat-icon>
                Your {{ "KEYWORD.practice" | translate | titlecase}} Rating
              </h3>
            </div>
            <div class="pull-right">
              <button [matMenuTriggerFor]="menu4" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu4="matMenu">
                <button [routerLink]="['/merchant/', { outlets: { page: ['settlements-list'] } }]" mat-menu-item
                  mat-menu-item>
                  <mat-icon>equalizer</mat-icon>
                  <span>Compare {{ "KEYWORD.practice" | translate | titlecase }}s</span>
                </button>
                <button [routerLink]="['/merchant/', { outlets: { page: ['performance-overview'] } }]" mat-menu-item
                  mat-menu-item>
                  <mat-icon>timeline</mat-icon>
                  <span>{{ "KEYWORD.practice" | translate | titlecase}} Performance</span>
                </button>
              </mat-menu>
            </div>
            <hr />
          </div>
          <app-star-rating-view></app-star-rating-view>
        </mat-card>
      </div>

      <div class="quick-actions" *ngIf="actionLogsLoaded != true">
        <mat-card class="quick-actions-card hasButton card rel">
          <!-- css loader -->
          <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <!-- /css loader -->
        </mat-card>
      </div>

      <!-- activity log card -->
      <div class="quick-actions wide" *ngIf="isModuleNoteFeaturesActive == true && isSelectMerchantFromFilter == true">
        <mat-card class="quick-actions-card card PatActionLog hasButton">
          <div class="colour-stripe" *ngIf="stripe == true"></div>
          <div class="row clearfix">
            <h3 class="sm-h3 sr-title rm-m mb truncateTitle pull-left">
              <mat-icon class="fas fa-business-time"></mat-icon>
              Recent Activities
              <span class="badge" id="totalActivities">
                <span
                  *ngIf="statisticActionLogs && statisticActionLogs.global && statisticActionLogs.global['Count(*)']"
                  [endVal]="toNumer(statisticActionLogs.global['Count(*)'])" countUp></span>
                <span *ngIf="
                    statisticActionLogs &&
                    statisticActionLogs.global &&
                    toNumer(statisticActionLogs.global['Count(*)']) >= 10000
                  ">+</span>
              </span>
            </h3>

            <div class="pull-right" *ngIf="isPromoterOrAdmin == true">
              <button [matMenuTriggerFor]="menuActivity" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menuActivity="matMenu">
                <button [routerLink]="['/merchant/', { outlets: { page: ['invitation-settings'] } }]" mat-menu-item>
                  <mat-icon>settings</mat-icon>
                  Manage Notes
                </button>
              </mat-menu>
            </div>
            <hr />
          </div>

          <app-activity-list [limit]="10" [section]="1" [dateFrom]="activityDateFrom" [dateTo]="dateTo"
            [merchantID]="merchantID"></app-activity-list>

          <div class="row clearfix mt invButtonRow flex">
            <button class="btn-large gradient red mat-raised-button mat-primary emButton"
              (click)="displayInvitations('patientResponse')" mat-raised-button matTooltip="View responded invitations">
              <mat-icon>mail</mat-icon>
            </button>
            <button class="btn-large gradient blue mat-raised-button mat-primary mb" (click)="displayNotes()"
              mat-raised-button>
              View All Activity
              <mat-icon>search</mat-icon>
            </button>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
  <div class="rel clearFix full-width" *ngIf="displayInvitation == true">
    <app-invitation-list *ngIf="!isPromoterOrAdmin" [membership]="false" [dateFrom]="dateFrom" [dateTo]="dateTo"
      [status]="status">
    </app-invitation-list>
    <app-invitation-list-global *ngIf="isPromoterOrAdmin == true" [membership]="false" [dateFrom]="dateFrom"
      [dateTo]="dateTo" [merchantID]="merchantID" [status]="status"></app-invitation-list-global>
  </div>

  <div class="rel clearFix full-width" *ngIf="displayMembership == true">
    <app-invitation-list *ngIf="!isPromoterOrAdmin" [membership]="true" [dateFrom]="dateFrom" [dateTo]="dateTo"
      [status]="status">
    </app-invitation-list>
    <app-invitation-list-global *ngIf="isPromoterOrAdmin == true" [membership]="true" [dateFrom]="dateFrom"
      [dateTo]="dateTo" [merchantID]="merchantID" [status]="status"></app-invitation-list-global>
  </div>

  <div class="rel clearFix full-width" *ngIf="displayPatients == true">
    <app-customer-prospect-list *ngIf="!isPromoterOrAdmin" [dateFrom]="dateFrom" [dateTo]="dateTo"
      [tableName]="tableName">
    </app-customer-prospect-list>
    <app-customer-prospect-list-global *ngIf="isPromoterOrAdmin == true" [dateFrom]="dateFrom" [dateTo]="dateTo"
      [merchantID]="merchantID" [tableName]="tableName"></app-customer-prospect-list-global>
  </div>
  <div class="rel clearFix full-width" *ngIf="displayActivePatients == true">
    <app-settlement-list-global></app-settlement-list-global>
  </div>

  <div class="rel clearFix full-width" *ngIf="isDisplayNotes == true">
    <app-activity-log-list *ngIf="!isPromoterOrAdmin" [dateFrom]="dateFrom" [dateTo]="dateTo"></app-activity-log-list>
    <app-activity-log-list-global *ngIf="isPromoterOrAdmin == true" [dateFrom]="dateFrom" [dateTo]="dateTo"
      [merchantID]="merchantID" [groupFilter]="noteListGroupFilter" (groupFilterEvent)="getNoteListGroupFilter($event)">
    </app-activity-log-list-global>
  </div>
</div>
