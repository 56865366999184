import { Component, Input } from '@angular/core';
import { ProductInvoice } from '../../../../../shared/services/product-invoice.service';

@Component({
  selector: 'ipv-dental-view-product-invoice',
  templateUrl: './dental-view-product-invoice.component.html',
  styleUrls: ['./dental-view-product-invoice.component.css'],
})
export class DentalViewProductInvoiceComponent {
  @Input() invoiceAppliance: ProductInvoice = null;
}
