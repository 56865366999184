import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { switchMap } from 'rxjs/operators';
import { AppStateService } from '../../../../../../../core/app-state/app-state.service';
import { RootAppComponent } from '../../../../../../../shared/components/root-component/root-component.component';
import { NotifyAppComponent } from '../../../../../../../shared/types/notify-app-component';
import { CustomerProspectCreateComponent } from '../../../../../../customer-prospect/customer-prospect-create/customer-prospect-create.component';
import { InvitationLookupComponent } from '../../../../../../invitation/invitation-lookup/invitation-lookup.component';
import { CustomerAndProspect } from '../../../../../../invitation/shared/types/invitation.type';
import { Merchant } from '../../../../../../merchant/shared/types/merchant.type';
import { ProductCatalogueItem } from '../../../../../shared/services/product-catalogue.service';
import { ProductCustomer } from '../../../../../shared/services/product-customer.service';
import { ProductInvoice, ProductInvoiceService } from '../../../../../shared/services/product-invoice.service';

@Component({
  selector: 'ipv-retail-product-invoice-form',
  templateUrl: './retail-product-invoice-form.component.html',
  styleUrls: ['./retail-product-invoice-form.component.css'],
})
export class RetailProductInvoiceFormComponent implements OnInit, OnDestroy {
  @Input() existingProductInvoice = null;
  @Input() formMode: 'create' | 'update' = 'create';

  isAvailable: '0' | '1' = '0';
  isSerialNumber: '0' | '1' = '1';

  productCatalogueItem: ProductCatalogueItem = {
    ID: '',
    DateTimeCreated: '',
    LastModified: '',
    LastModified_Human: '',
    Product: 'Product Name',
    Model: 'Model Number',
    Product_Type_key: '',
    Stock_Keeping_Unit: 'skuKey',
    Universal_Product_Code: 'upcKey',
    SellPrice: 0,
    CostPrice: 0,
    TaxRate: 0,
    Is_SerialNum_Mandatory: this.isSerialNumber,
    Is_Available: this.isAvailable,
    Size: '',
    Colour: '',
    Weight: '',
    Width: '',
    Height: '',
    Length: '',
    Manufactured_Year: '',
    Thumbnail_Doc_key: '',
    ProfileImage_Doc_key: '',
    AdditionalImages_Doc_key: '1|2|3|4',
    Description: 'hello world',
    Supplier_key: '',
  };
  productCustomer: ProductCustomer = {
    ID: '',
    DateTimeCreated: '',
    LastModified: '',
    LastModified_Human: '',
    Owner_Card_key: '',
    Master_Appliance_Profile_key: '',
    SellPrice: 0,
    CostPrice: 0,
    TaxRate: 0,
    Quantity: '1',
    Discount_Amount: 0,
    Notes: '',
    Invoice_key: '',
    Merchant_key: '',
    PurchaseDate: '',
    SerialNumber: '',
  };
  customer = {
    Merchant_key: '',
    Google_Review_DateTime: '',
    'Google_Review_Status.Code': '',
    'Google_Review_Status.Label': '',
    Google_Review_Sent_DateTime: '',
    ID: 'CC0285D04F7C7647A4740166FB3D8733',
    LastModified: '957VLII5UZ',
    LastModified_Human: '2022-06-13 22:45:01',
    DateTimeCreated: '2021-07-20 08:49:51',
    TableName: 'Customer',
    Name: 'Jenkins',
    FirstName: 'Lisa',
    Salutation: 'Mrs',
    MaternalName: '',
    MiddleName: '',
    PreferredName: 'Lisa',
    TradingAs: '',
    TrusteeFor: '',
    CalculatedName: 'Lisa Jenkins',
    Dear: 'Dear Mrs Jenkins',
    Occupation: 'PFT',
    CardType: 'Consumer - Individual',
    ABN: '',
    ACN: '',
    CompanyType: '',
    'phones.Labels': 'Work;Home',
    'phones.Primary': 'Home',
    'phones.Number': 'undefined',
    'phones.Extension': '',
    'mobiles.Labels': 'Work;Home',
    'mobiles.Primary': 'Home',
    'mobiles.Number': '0061416428991',
    'emails.Labels': 'Work;Home',
    'emails.Primary': 'Home',
    'emails.Email': 'mel@smileright.com.au',
    'addresses.Labels': 'Physical;Postal',
    'addresses.Primary': 'Physical',
    'addresses.Calculated': '1/1 Bondi Res \rBondi Junction NSW 2022',
    'addresses.Suburb': 'Bondi Junction',
    'addresses.State': 'NSW',
    'addresses.Postcode': '2022',
    'addresses.Country.Code': 'au',
    'addresses.Country.Label': 'Australia',
    'addresses.Unit': '1',
    'addresses.Street Nr': '1',
    'addresses.Street Name': 'Bondi',
    'addresses.Street Type': 'Res',
    'addresses.Full Street Type': 'Res',
    'addresses.Property': '',
    'PreferredMessageType.Code': 'E',
    'PreferredMessageType.Label': 'Email',
    URL: '',
    Status: 'Active',
    'PreferredContactTime.Code': 'PM',
    'PreferredContactTime.Label': 'Afternoon',
    'PreferredContactMethod.Code': 'E',
    'PreferredContactMethod.Label': 'Email',
    'Gender.Code': 'F',
    'Gender.Label': 'Female',
    'MarketingConsent.When': '2021-10-07 13:48:14',
    'ElectronicCommsConsent.When': '2021-08-26 09:05',
    'Address.Latitude': -33.8914277,
    'Address.Longitude': 151.2534844,
    ExistingPerson_key: 'AC900F6A8010CA408543586DD0B9744B',
    PossibleContactTypes:
      'GD:Doctor;GP:GP;GUAR:Guarantor;NOK:Next of Kin;OTH:Other;PART:Partner;REL:Relative;SIGN:Signatory',
    'Tag.Label': '',
    'Tag.Description': '',
    'StaticList.Label': '',
    'StaticList.Description': '',
    DateOfBirth: '1990-12-20',
    TimeZoneCode: '',
    TimeZoneValue: '0',
    External_ID: '',
    External_Source: '',
    Webhook_URL: '',
    tags: [],
  };

  selectedPatient: CustomerAndProspect = null;

  productInvoice: ProductInvoice = {
    ID: '',
    DateTimeCreated: '',
    LastModified: '',
    LastModified_Human: '',
    Invoice_Number: '',
    Owner_Card_key: '',
    Contract_key: '',
    Merchant_key: '',
    Amount_Borrowed: 0,
    Notes: '',
    Invoice_Date: '',
    Invoice_Total: 0,
    'Invoice.Type.Code': '',
    'Invoice.Type.Label': '',
    'Invoice.Status.Code': '',
    'Invoice.Status.Label': '',
    CustomerCalculatedName: '',
    CustomerEmail: '',
    ProductLabel: '',
    CustomerNumber: '',
    productCustomer: this.productCustomer,
    customer: this.customer,
    product: this.productCatalogueItem,
  };

  appState$ = this.appStateService.getAppState$();

  constructor(
    private dialogRef: MatDialogRef<RetailProductInvoiceFormComponent>,
    private productInvoiceService: ProductInvoiceService,
    private appStateService: AppStateService
  ) {}

  ngOnInit() {
    if (this.existingProductInvoice) {
      this.productInvoice = this.existingProductInvoice;
    }
  }

  close() {
    this.dialogRef.close();
  }

  create() {
    this.productInvoiceService
      .createProductInvoice$<ProductCatalogueItem>(this.productInvoice)
      .pipe(
        switchMap((itemWithID) => {
          return this.productInvoiceService.addProductInvoice$(itemWithID);
        })
      )
      .subscribe(() => {
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Successfully created invoice');
        this.dialogRef.close();
      });
  }

  selectPatient(practice: Partial<Merchant>, prefill: boolean = false) {
    const invitationLookup = RootAppComponent.dialog.open(InvitationLookupComponent, {
      data: {
        merchantID: practice.ID,
        onlyMyAccount: true,
        useStrict: true,
      },
      width: '600px',
    });

    invitationLookup.componentInstance.closeModal.pipe(untilDestroyed(this)).subscribe(() => {
      invitationLookup.close();
    });

    invitationLookup.componentInstance.getSelectedPatient.pipe(untilDestroyed(this)).subscribe((selectedPatient) => {
      invitationLookup.close();
      this.selectedPatient = selectedPatient;
      this.productCustomer.Owner_Card_key = selectedPatient.ID;
    });
  }

  newPatient(practice: Partial<Merchant>) {
    const customerProspectCreate = RootAppComponent.dialog.open(CustomerProspectCreateComponent, {
      data: {
        merchantID: practice.ID,
        isEmailRequired: false,
      },
      width: '850px',
    });

    customerProspectCreate.componentInstance.close.pipe(untilDestroyed(this)).subscribe(() => {
      customerProspectCreate.close();
    });

    customerProspectCreate.componentInstance.getResult.pipe(untilDestroyed(this)).subscribe((newPatient) => {
      customerProspectCreate.close();
      this.selectedPatient = newPatient;
      this.productCustomer.Owner_Card_key = newPatient.ID;
    });
  }

  update() {
    this.productInvoiceService
      .updateProductInvoice$(this.productInvoice, this.productInvoice)
      .pipe(
        switchMap((item) => {
          return this.productInvoiceService.editProductInvoice$(item);
        })
      )
      .subscribe(() => {
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Successfully updated invoice');

        this.dialogRef.close();
      });
  }

  ngOnDestroy() {}
}
