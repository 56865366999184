<div class="row clearfix defineMessage">
  <!-- blue heading -->
  <div class="card-header primary-gradient-img clearfix" *ngIf="isModal == true">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear pull-right mat-button" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <!-- merchant calculated name -->
      <div class="row titleArea clearfix">
        <mat-icon class="fas fa-file-contract pull-left"></mat-icon>
        <h2 class="message-title summary-header rm-m">
          <span>Personalise cancellation policy</span>
        </h2>
      </div>
    </div>
  </div>
  <!-- end blue heading -->

  <h4 class="sm-h4 sr-title" *ngIf="isModal == false">Personalise cancellation policy</h4>

  <div class="create-well">
    <form #msgForm="ngForm">
      <!--  mat dialog scrolling area -->
      <mat-dialog-content>
        <!-- Notification period -->
        <div class="row clearfix">
          <h3 class="sr-title sm-h3 rm-m mt">Notification period</h3>
          <p class="clearfix small">
            Enter the notice period in hours that require for cancelling appointments.
            {{ "KEYWORD.patient" | translate | titlecase}}s ability to cancel within this period online will be
            disabled, forcing
            them to call the {{ "KEYWORD.practice" | translate }} to reschedule.
          </p>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Notification period for cancelling appointments</mat-label>
            <input
              class="mat-block"
              [(ngModel)]="appointmentCancellingHours"
              type="number"
              matInput
              name="appointmentCancellingHours"
              required
            />
          </mat-form-field>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Cancellation Fee</mat-label>
            <input
              class="mat-block"
              [(ngModel)]="appointmentCancellingFee"
              matInput
              name="appointmentCancellingFee"
              required
              currencyMask
            />
          </mat-form-field>
        </div>

        <!-- SMS confirmation and reminders -->
        <div class="row clearfix">
          <h3 class="sr-title sm-h3 rm-m mt">SMS confirmation and reminders</h3>
          <mat-slide-toggle class="pull-left mt mr mb" [(ngModel)]="smsConfirmAppointment" name="smsConfirmAppointment"
            >Send SMS confirmation on booking the appointment</mat-slide-toggle
          >
          <button class="pull-right mt" (click)="previewSMS()" mat-raised-button color="accent">Preview</button>
          <mat-slide-toggle class="pull-left mr mb" [(ngModel)]="smsAppointmentReminder" name="smsAppointmentReminder"
            >Send SMS 24 hrs before appointment</mat-slide-toggle
          >
          <button class="pull-right" (click)="previewSMS()" mat-raised-button color="accent">Preview</button>
        </div>

        <!-- Terms and conditions -->
        <div class="row clearfix">
          <div class="row clearfix">
            <h3 class="sr-title sm-h3 rm-m mt">Terms and conditions</h3>
            <div>
              <!-- <mat-form-field
                class="full-width messageContent"
                appearance="outline"
              >
                <mat-label>Enter your Terms and conditions</mat-label>
                <textarea
                  name="appointmentCancellingPolicy"
                  matInput
                  #messageBodyID
                  class="dentMsg increase-input-field"
                  placeholder="Enter your Terms and conditions"
                  cols="30"
                  rows="50"
                  required
                  (ngModelChange)="onKey($event)"
                  (keyup)="getcursorPos()"
                  (mouseup)="getcursorPos()"
                  [(ngModel)]="appointmentCancellingPolicy"
                ></textarea>
              </mat-form-field> -->
              <app-text-editor-input
                class="clearfix"
                #textEditor
                [content]="appointmentCancellingPolicy"
                [isPreview]="false"
                [title]="null"
                [isAction]="false"
                [isMultiView]="false"
                (getContent)="onUpdateContent($event)"
                (cursorPos)="updateCursorPos($event)"
                name="appointmentCancellingPolicy"
              >
              </app-text-editor-input>
            </div>
          </div>
          <!-- insert personalisation -->
          <div class="row clearfix">
            <button class="pull-left" [matMenuTriggerFor]="persMenu" mat-button color="primary">
              <mat-icon class="fas fa-share"></mat-icon> Insert personalisation
            </button>
            <!-- {{ "KEYWORD.patient" | translate }} personalisation message -->
            <mat-menu #persMenu="matMenu">
              <div *ngFor="let p of persData">
                <button (click)="addPersonalisation(p.value)" mat-menu-item>
                  {{ p.id }}
                </button>
              </div>
            </mat-menu>
            <!-- <p class="small pull-right">
              Total word count: {{ wordCounter }} words.
            </p> -->
          </div>
        </div>
      </mat-dialog-content>
      <!-- fixed action buttons -->
      <mat-dialog-actions>
        <div class="row clearfix">
          <button
            class="pull-right"
            [disabled]="!msgForm.form.valid"
            (click)="onSave()"
            mat-raised-button
            color="accent"
          >
            Save
          </button>
        </div>
      </mat-dialog-actions>
    </form>
  </div>
</div>
