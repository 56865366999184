import { Component, Input, OnChanges, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { EMPTY_REPORT } from '../../../../../../shared/components/data-table/shared/data/data';
import { DataTablePrintModalComponent } from '../../../../../../shared/components/data-table/shared/modals/data-table-print-modal/data-table-print-modal.component';
import { DataTablePrintModalConfig } from '../../../../../../shared/components/data-table/shared/types/data-table.type';
import { TOTAL_REVENUE_REPORT_LABELS } from '../../data/revenue-report.data';
import { FundersRevenueReportService } from '../../services/funders-revenue-report.service';
import { RevenueReport, RevenueReportActualRecord, RevenueReportFeesActual } from '../../types/revenue-report.type';
import {
  BORROWER_FEES_ACTUAL_DIVIDER,
  BORROWER_INTEREST_ACTUAL_DIVIDER,
  MERCHANT_FEES_ACTUAL_DIVIDER,
} from './data/funders-revenue-report-actual.data';

@Component({
  selector: 'ipv-funders-revenue-report-actual',
  templateUrl: './funders-revenue-report-actual.component.html',
  styleUrls: ['./funders-revenue-report-actual.component.css'],
})
export class FundersRevenueReportActualComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('label', { static: false }) public label: TemplateRef<any>;
  @ViewChild('actual', { static: false }) public actual: TemplateRef<any>;
  @ViewChild('refunds', { static: false }) public refunds: TemplateRef<any>;
  @ViewChild('net', { static: false }) public actualNet: TemplateRef<any>;
  @ViewChild('actualPercentage', { static: false }) public actualPercentage: TemplateRef<any>;

  @Input() revenueReport: RevenueReport;

  report: {
    fullTable: RevenueReportActualRecord[];
    table: RevenueReportActualRecord[];
    totalRecord: RevenueReportActualRecord;
  } = EMPTY_REPORT;

  constructor(private revenueReportService: FundersRevenueReportService, private dialog: MatDialog) {}

  ngOnInit() {
    this.revenueReportService
      .getPrintTableEvents$()
      .pipe(untilDestroyed(this))
      .subscribe((printTable) => {
        if (printTable === 'actual') {
          this.openTablePrint();
        }
      });
  }

  ngOnChanges() {
    this.report = this.revenueReport ? this.getTables(this.revenueReport) : EMPTY_REPORT;
  }

  ngOnDestroy() {
    // required for untilDestroyed();
  }

  getTables(revenueReport: RevenueReport): {
    fullTable: RevenueReportActualRecord[];
    table: RevenueReportActualRecord[];
    totalRecord: RevenueReportActualRecord;
  } {
    let table: RevenueReportActualRecord[] = [];

    const borrowerFees = revenueReport.BorrowerFees_Actual ? revenueReport.BorrowerFees_Actual : [];
    table = this.getBorrowerTable(table, borrowerFees, revenueReport);

    const borrowerInterest = revenueReport.BorrowerInterestActual_Total
      ? revenueReport.BorrowerInterestActual_Total
      : [];
    table = this.getBorrowerInterestTable(table, borrowerInterest, revenueReport);

    table = this.getTotalBorrowerInterestAndFeesSection(table, borrowerFees, borrowerInterest, revenueReport);

    const merchantFees = revenueReport.MerchantFees_Actual ? revenueReport.MerchantFees_Actual : [];
    const merchantSubscriptions = revenueReport.MerchantSubscriptionFees_Actual
      ? revenueReport.MerchantSubscriptionFees_Actual.map((item) => ({ ...item, Label: 'Subscriptions' }))
      : [];
    const merchantFeesAndSubscriptions = merchantFees.concat(merchantSubscriptions);
    table = this.getMerchantTable(table, merchantFeesAndSubscriptions, revenueReport);

    const totalRecord = this.getReportTotal(revenueReport);
    const fullTable = table.concat(totalRecord);

    return { fullTable, table, totalRecord };
  }

  getReportTotal(revenueReport: RevenueReport) {
    return {
      label: 'Total Revenue',
      code: '',
      actual: revenueReport.TotalRevenue_Actual[0].Actual,
      refunds: revenueReport.TotalRevenue_Actual[0].Refunds,
      actualNet: revenueReport.TotalRevenue_Actual[0].Actual_Net,
      actualPercentage: 100,
    };
  }

  getBorrowerTable(
    table: RevenueReportActualRecord[],
    borrowerFees: RevenueReportFeesActual[],
    revenueReport: RevenueReport
  ) {
    const newTable = table;
    newTable.push(BORROWER_FEES_ACTUAL_DIVIDER);

    for (let index = 0; index < borrowerFees.length; index++) {
      const actualPercentage =
        borrowerFees[index].Actual_Net === 0
          ? 0
          : Number(
              ((borrowerFees[index].Actual_Net / revenueReport.TotalRevenue_Actual[0].Actual_Net) * 100).toFixed(2)
            );

      if (borrowerFees[index]) {
        const record: RevenueReportActualRecord = {
          label: borrowerFees[index].Label,
          code: borrowerFees[index].Code,
          actual: borrowerFees[index].Actual,
          refunds: borrowerFees[index].Refunds,
          actualNet: borrowerFees[index].Actual_Net,
          actualPercentage,
        };

        newTable.push(record);
      }
    }

    const borrowersFeesTotalRecord = revenueReport.BorrowerFeesActual_Total[0];
    const reportTotal = revenueReport.TotalRevenue_Actual[0].Actual_Net;

    let total = {
      label: 'Total',
      code: '',
      actual: 0,
      refunds: 0,
      actualPercentage: 0,
      actualNet: 0,
    };
    let totalPercentage = 0;

    if (borrowersFeesTotalRecord) {
      const borrowersFeesTotal = borrowersFeesTotalRecord.Actual_Net;
      totalPercentage = this.getSectionTotalPercentage(borrowersFeesTotal, reportTotal);

      total = {
        label: 'Total',
        code: '',
        actual: borrowersFeesTotalRecord.Actual,
        refunds: borrowersFeesTotalRecord.Refunds,
        actualPercentage: totalPercentage,
        actualNet: borrowersFeesTotalRecord.Actual_Net,
      };
    }

    newTable.push(total);

    return newTable;
  }

  getBorrowerInterestTable(
    table: RevenueReportActualRecord[],
    borrowerInterest: RevenueReportFeesActual[],
    revenueReport: RevenueReport
  ) {
    const newTable = table;
    newTable.push(BORROWER_INTEREST_ACTUAL_DIVIDER);

    for (let index = 0; index < borrowerInterest.length; index++) {
      const actualPercentage =
        borrowerInterest[index].Actual_Net === 0
          ? 0
          : Number(
              ((borrowerInterest[index].Actual_Net / revenueReport.TotalRevenue_Actual[0].Actual_Net) * 100).toFixed(2)
            );

      if (borrowerInterest[index]) {
        const record: RevenueReportActualRecord = {
          label: 'Interest',
          code: '',
          actual: borrowerInterest[index].Actual,
          refunds: borrowerInterest[index].Refunds,
          actualNet: borrowerInterest[index].Actual_Net,
          actualPercentage,
        };

        newTable.push(record);
      }
    }

    return newTable;
  }

  getTotalBorrowerInterestAndFeesSection(
    table: RevenueReportActualRecord[],
    borrowerFees: RevenueReportFeesActual[],
    borrowerInterest: RevenueReportFeesActual[],
    revenueReport: RevenueReport
  ) {
    const newTable = table;

    const borrowersFeesTotalRecord = revenueReport.BorrowerFeesActual_Total[0];
    const borrowerInterestTotalRecord = revenueReport.BorrowerInterestActual_Total[0];
    const reportTotal = revenueReport.TotalRevenue_Actual[0].Actual_Net;
    const total = {
      label: 'Total',
      code: '',
      actual: 0,
      refunds: 0,
      actualPercentage: 0,
      actualNet: 0,
    };

    total.label = 'Total Borrower Interest & Fees';

    if (borrowersFeesTotalRecord) {
      const borrowersFeesTotal = borrowersFeesTotalRecord.Actual_Net;
      const totalPercentage = this.getSectionTotalPercentage(borrowersFeesTotal, reportTotal);

      total.actual = total.actual + borrowersFeesTotalRecord.Actual;
      total.refunds = total.refunds + borrowersFeesTotalRecord.Refunds;
      total.actualPercentage = Number((total.actualPercentage + totalPercentage).toFixed(2));
      total.actualNet = total.actualNet + borrowersFeesTotalRecord.Actual_Net;
    }

    if (borrowerInterestTotalRecord) {
      const borrowerInterestTotal = borrowerInterestTotalRecord.Actual_Net;
      const totalPercentage = this.getSectionTotalPercentage(borrowerInterestTotal, reportTotal);

      total.actual = total.actual + borrowerInterestTotalRecord.Actual;
      total.refunds = total.refunds + borrowerInterestTotalRecord.Refunds;
      total.actualPercentage = Number((total.actualPercentage + totalPercentage).toFixed(2));
      total.actualNet = total.actualNet + borrowerInterestTotalRecord.Actual_Net;
    }

    newTable.push(total);

    return newTable;
  }

  getMerchantTable(
    table: RevenueReportActualRecord[],
    merchantFees: RevenueReportFeesActual[],
    revenueReport: RevenueReport
  ) {
    const newTable = table;
    newTable.push(MERCHANT_FEES_ACTUAL_DIVIDER);

    for (let index = 0; index < merchantFees.length; index++) {
      const actualPercentage =
        merchantFees[index].Actual_Net === 0
          ? 0
          : Number(
              ((merchantFees[index].Actual_Net / revenueReport.TotalRevenue_Actual[0].Actual_Net) * 100).toFixed(2)
            );

      if (merchantFees[index]) {
        const record: RevenueReportActualRecord = {
          label: merchantFees[index].Label,
          code: merchantFees[index].Code,
          actual: merchantFees[index].Actual,
          refunds: merchantFees[index].Refunds,
          actualNet: merchantFees[index].Actual_Net,
          actualPercentage,
        };

        newTable.push(record);
      }
    }

    const borrowersFeesTotalRecord = revenueReport.MerchantFeesActual_Total[0];
    const reportTotal = revenueReport.TotalRevenue_Actual[0].Actual_Net;

    let total = {
      label: 'Total',
      code: '',
      actual: 0,
      refunds: 0,
      actualPercentage: 0,
      actualNet: 0,
    };

    if (borrowersFeesTotalRecord) {
      const borrowersFeesTotal = borrowersFeesTotalRecord.Actual_Net;
      const totalPercentage = this.getSectionTotalPercentage(borrowersFeesTotal, reportTotal);

      total = {
        label: 'Total',
        code: '',
        actual: borrowersFeesTotalRecord.Actual,
        refunds: borrowersFeesTotalRecord.Refunds,
        actualPercentage: totalPercentage,
        actualNet: borrowersFeesTotalRecord.Actual_Net,
      };
    }
    newTable.push(total);

    return newTable;
  }

  openTablePrint() {
    this.dialog.open<DataTablePrintModalComponent, DataTablePrintModalConfig>(DataTablePrintModalComponent, {
      data: {
        table: this.report.fullTable,
        title: 'Actual Revenue Report from ' + this.revenueReport.StartDate + ' to ' + this.revenueReport.EndDate,
        customComponents: {
          label: this.label,
          actual: this.actual,
          refunds: this.refunds,
          actualNet: this.actualNet,
          actualPercentage: this.actualPercentage,
        },
        formatHeaders: {
          actual: 'right',
          refunds: 'right',
          actualNet: 'right',
          actualPercentage: 'right',
        },
        rename: { actualNet: 'Net', actualPercentage: 'Actual %' },
        sectionColumn: 'label',
        sections: [
          { value: 'Borrower Fees', color: '#CE066B', fontColor: 'white' },
          { value: 'Merchant Fees', color: '#168BDD', fontColor: 'white' },
          { value: 'Total Borrower Interest & Fees', color: '#E7E9EB', fontColor: 'black', customLayout: true },
          { value: 'Total Revenue', color: '#E7E9EB', fontColor: 'black', customLayout: true },
        ],
      },
      width: '900px',
    });
  }

  getSectionTotalPercentage(sectionTotal: number, reportTotal: number) {
    const percentage = sectionTotal === 0 ? 0 : Number(((sectionTotal / reportTotal) * 100).toFixed(2));

    return percentage;
  }

  isTotalSection(label: string) {
    return TOTAL_REVENUE_REPORT_LABELS.indexOf(label) !== -1;
  }
}
