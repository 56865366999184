import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Settings } from '../types/settings';

@Pipe({
  name: 'customCurrency',
})
export class CustomCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(value: any, digits = null, currencyCode?: string, symbolDisplay?: boolean): string {
    try {
      currencyCode = Settings.global['currencyCode'];
      if (digits == null) {
        digits = Settings.global['currencyDigits'];
      }
      symbolDisplay = Settings.global['currencyDisplaySymbol'];

      const v = Number(value);

      if (v % 1 === 0) {
        const _digits = digits;

        if (_digits) {
          const _a1 = _digits.split('-')[0];
          const _a3 = _digits.split('-')[1];

          if (_a1) {
            const _b1 = _a1.split('.')[0];

            if (_b1 && _a3) {
              return this.currencyPipe.transform(value, currencyCode, symbolDisplay, _b1 + '.0-' + _a3);
            }
          }
        }



        return this.currencyPipe.transform(value, currencyCode, symbolDisplay, '1.0-3');
      } else {



        return this.currencyPipe.transform(value, currencyCode, symbolDisplay, digits);
      }
    } catch (e) {
      return value;
    }
  }
}
