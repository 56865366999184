import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthenticationModule } from '../authentication/authentication.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule, MatRippleModule } from '@angular/material';
import { ArchwizardModule } from 'angular-archwizard';
import { VerticalTimelineModule } from 'angular-vertical-timeline';
import { NgDatepickerModule } from 'ng2-datepicker';
import { ClipboardModule } from 'ngx-clipboard';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { QuillModule } from 'ngx-quill';
import { HelperRoutingModule } from './shared/helper-routing.module';
import { HelperService } from './shared/helper.service';
import { TemplateMarketingCreateEditComponent } from './template-marketing-create-edit/template-marketing-create-edit.component';

import { SharedModule } from '../../shared/shared.module';
import { BetaInfoComponent } from './beta-info/beta-info.component';
import { BookingModalComponent } from './booking-modal/booking-modal.component';
import { CalendlyComponent } from './calendly/calendly.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { FAQModalConsumerComponent } from './FAQ-modal-consumer/faq-modal-consumer.component';
import { FAQModalMerchantComponent } from './FAQ-modal-merchant/faq-modal-merchant.component';
import { GettingStartedCoordinatorComponent } from './getting-started-coordinator/getting-started-coordinator.component';
import { GettingStartedDentalComponent } from './getting-started-dental/getting-started-dental.component';
import { GettingStartedHomeComponent } from './getting-started-home/getting-started-home.component';
import { GettingStartedMarketingComponent } from './getting-started-marketing/getting-started-marketing.component';
import { GettingStartedMerchantComponent } from './getting-started-merchant/getting-started-merchant.component';
import { GettingStartedMerchantStep1Component } from './getting-started-merchant/step1/step1.component';
import { GettingStartedMerchantStep2Component } from './getting-started-merchant/step2/step2.component';
import { GettingStartedMerchantStep3Component } from './getting-started-merchant/step3/step3.component';
import { GettingStartedPracticeManagerComponent } from './getting-started-practice-manager/getting-started-practice-manager.component';
import { HelpPageCreateComponent } from './help-page-create/help-page-create.component';
import { HelpPageEditComponent } from './help-page-edit/help-page-edit.component';
import { HelpPageListComponent } from './help-page-list/help-page-list.component';
import { HelpPageViewComponent } from './help-page-view/help-page-view.component';
import { MaintenancePageComponent } from './maintenance-page/maintenance-page.component';
import { MediaGalleryDocumentsComponent } from './media-gallery-documents/media-gallery-documents.component';
import { MediaGalleryImagesComponent } from './media-gallery-images/media-gallery-images.component';
import { MediaGalleryTemplatesListComponent } from './media-gallery-templates-list/media-gallery-templates-list.component';
import { MediaGalleryTemplatesComponent } from './media-gallery-templates/media-gallery-templates.component';
import { MediaGalleryComponent } from './media-gallery/media-gallery.component';
import { MessageUsComponent } from './message-us/message-us.component';
import { Page403Component } from './page403/page403.component';
import { Page404Component } from './page404/page404.component';
import { Page500Component } from './page500/page500.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { RegistrationPageComponent } from './registration-page/registration-page.component';
import { SimplePageCreateEditComponent } from './simple-page-create-edit/simple-page-create-edit.component';
import { SimplePageCreateComponent } from './simple-page-create/simple-page-create.component';
import { SimplePageEditComponent } from './simple-page-edit/simple-page-edit.component';
import { SimplePageListComponent } from './simple-page-list/simple-page-list.component';
import { SimplePageSettingsComponent } from './simple-page-settings/simple-page-settings.component';
import { SimplePageStandaloneComponent } from './simple-page-standalone/simple-page-standalone.component';
import { SimplePageViewComponent } from './simple-page-view/simple-page-view.component';
import { SimpleTimeOutComponent } from './simple-time-out/simple-time-out.component';
import { SomethingWrongComponent } from './something-wrong/something-wrong.component';
import { TemplateReviewComponent } from './template-review/template-review.component';
import { TemplateSocialMediaViewComponent } from './template-social-media-view/template-social-media-view.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { TwoFactorInputComponent } from './two-factor-input/two-factor-input.component';
import { TwoFactorModalComponent } from './two-factor-modal/two-factor-modal.component';
import { VersionViewComponent } from './version-view/version-view.component';
import { ZoomInInfoComponent } from './zoom-in-info/zoom-in-info.component';
import { InfoModalComponent } from './info-modal/info-modal.component';
import { ChangeLogComponent } from './change-log/change-log.component';
import { PasswordResetEmptyPageComponent } from './password-reset-empty-page/password-reset-empty-page.component';

@NgModule({
  declarations: [
    TemplateSocialMediaViewComponent,
    TemplateReviewComponent,
    PrivacyComponent,
    SimpleTimeOutComponent,
    Page404Component,
    Page500Component,
    ErrorModalComponent,
    InfoModalComponent,
    SomethingWrongComponent,
    Page403Component,
    RegistrationPageComponent,
    SimplePageViewComponent,
    SimplePageEditComponent,
    TwoFactorInputComponent,
    TwoFactorModalComponent,
    ThankYouComponent,
    FAQModalConsumerComponent,
    FAQModalMerchantComponent,
    MessageUsComponent,
    SimplePageCreateComponent,
    SimplePageListComponent,
    SimplePageSettingsComponent,
    SimplePageStandaloneComponent,
    MaintenancePageComponent,
    HelpPageListComponent,
    HelpPageEditComponent,
    HelpPageCreateComponent,
    HelpPageViewComponent,
    MediaGalleryComponent,
    MediaGalleryImagesComponent,
    MediaGalleryTemplatesComponent,
    MediaGalleryDocumentsComponent,
    MediaGalleryTemplatesListComponent,
    TemplateMarketingCreateEditComponent,
    GettingStartedMerchantComponent,
    GettingStartedMerchantStep1Component,
    GettingStartedMerchantStep2Component,
    GettingStartedMerchantStep3Component,
    GettingStartedPracticeManagerComponent,
    GettingStartedMarketingComponent,
    GettingStartedHomeComponent,
    GettingStartedDentalComponent,
    GettingStartedCoordinatorComponent,
    SimplePageCreateEditComponent,
    BetaInfoComponent,
    VersionViewComponent,
    BookingModalComponent,
    CalendlyComponent,
    ZoomInInfoComponent,
    ChangeLogComponent,
    PasswordResetEmptyPageComponent
  ],
  imports: [
    SharedModule,
    AuthenticationModule,
    CommonModule,
    HelperRoutingModule,
    QuillModule,
    ArchwizardModule,
    FormsModule,
    ReactiveFormsModule,
    MatRippleModule,
    NgDatepickerModule,
    VerticalTimelineModule,
    MatBadgeModule,
    PinchZoomModule,
    ClipboardModule,
  ],
  exports: [
    TemplateSocialMediaViewComponent,
    TemplateReviewComponent,
    PrivacyComponent,
    SimpleTimeOutComponent,
    Page404Component,
    Page500Component,
    ErrorModalComponent,
    InfoModalComponent,
    SomethingWrongComponent,
    Page403Component,
    RegistrationPageComponent,
    SimplePageViewComponent,
    SimplePageEditComponent,
    TwoFactorInputComponent,
    TwoFactorModalComponent,
    ThankYouComponent,
    FAQModalConsumerComponent,
    FAQModalMerchantComponent,
    MessageUsComponent,
    SimplePageCreateComponent,
    SimplePageListComponent,
    SimplePageSettingsComponent,
    SimplePageStandaloneComponent,
    MaintenancePageComponent,
    HelpPageListComponent,
    HelpPageEditComponent,
    HelpPageCreateComponent,
    HelpPageViewComponent,
    MediaGalleryComponent,
    MediaGalleryImagesComponent,
    MediaGalleryTemplatesComponent,
    MediaGalleryDocumentsComponent,
    MediaGalleryTemplatesListComponent,
    TemplateMarketingCreateEditComponent,
    GettingStartedMerchantComponent,
    GettingStartedMerchantStep1Component,
    GettingStartedMerchantStep2Component,
    GettingStartedMerchantStep3Component,

    GettingStartedPracticeManagerComponent,
    GettingStartedMarketingComponent,
    GettingStartedHomeComponent,
    GettingStartedDentalComponent,
    GettingStartedCoordinatorComponent,
    SimplePageCreateEditComponent,
    BetaInfoComponent,
    VersionViewComponent,
    BookingModalComponent,
    CalendlyComponent,
    ZoomInInfoComponent,
    ChangeLogComponent,
    PasswordResetEmptyPageComponent
  ],
  providers: [HelperService],
})
export class HelperModule {}
