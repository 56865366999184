import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { UtilsClass } from '../../types/utils/utils.class';
@Component({
  selector: 'read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 0 })),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),

        group([animate(300, style({ height: 0 })), animate('200ms ease-in-out', style({ opacity: '0' }))]),
      ]),
      transition(':enter', [
        style({ height: '0', opacity: 0 }),

        group([animate(300, style({ height: '*' })), animate('400ms ease-in-out', style({ opacity: '1' }))]),
      ]),
    ]),
  ],
})
export class ReadMoreComponent implements OnInit {
  @Input() text = '';
  @Input() header;
  @Input() maxLength = 100;
  @Input()
  design = 'mode1';
  @Input()
  animated = true;
  currentText = '';
  hideToggle = true;
  @Input()
  isCollapsed = true;

  @Input()
  textMore = 'More';
  @Input()
  textLess = 'Less';

  @Input()
  canCollapse = true;
  @Input()
  hideButtonForce = false;
  @Input()
  hideButton = false;

  @Input()
  loading = false;
  util = new UtilsClass();
  @Output()
  getCollapse = new EventEmitter();

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.determineView();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.determineView();
  }

  toggleView() {
    this.isCollapsed = !this.isCollapsed;
    this.determineView();
  }

  determineView() {
    this.currentText = this.text;
    if (this.header) {
      this.hideToggle = false;
      this.hideButton = false;
    } else if (this.text && this.text.length <= this.maxLength) {
      this.isCollapsed = false;
      this.hideToggle = true;
      this.hideButton = true;
    } else {
      this.hideToggle = false;
      this.hideButton = false;
    }

    this.getCollapse.emit(this.isCollapsed);
  }

  openReadMore() {
    if (this.hideToggle != true && this.canCollapse === true) {
      this.isCollapsed = false;
    }

    this.getCollapse.emit(this.isCollapsed);
  }

  URLify(_string) {
    if (_string) {
      let string = _string;
      let urls = string.match(/(https?:\/\/[^\s]+)/g) || [];

      const localURLs = string.match(/(http?:\/\/[^\s]+)/g);

      if (localURLs && localURLs.length > 0) {
        urls = urls.concat(localURLs);
      }
      if (urls) {
        urls = _.uniq(urls);
        urls.forEach((url) => {
          const title = this.util.getLinkTitle(url);

          if (title) {
            string = string
              .split(url)
              .join(
                '<a target="_blank"  style="margin-right:10px !important;" title="' +
                  title +
                  '" class="inner-link" href="' +
                  url +
                  '"> ' +
                  title +
                  ' </a>'
              );
          } else {
            string = string
              .split(url)
              .join(
                '<a target="_blank"  style="margin-right:10px !important;" class="inner-link" href="' +
                  url +
                  '"> ' +
                  url +
                  ' </a>'
              );
          }
        });
      }

      string = string.replace(/\n/g, '<br>');
      return string;
    }

    return '';
  }
}
