export class MessageUtil {
  allowStatusUpdateLocal = {
    Pending: ['Paused', 'Failed', 'Canceled', 'Submitted', 'Scheduled'],
    Scheduled: ['Pending', 'Paused', 'Failed', 'Canceled', 'Submitted', 'Scheduled'],
    Paused: ['Pending', 'Failed', 'Canceled', 'Submitted', 'Scheduled'],
  };

  allowStatusUpdateGroup = {
    Sending: ['Pending', 'Paused', 'Failed', 'Canceled', 'Complete', 'Scheduled'],
    Pending: ['Sending', 'Paused', 'Failed', 'Canceled', 'Complete', 'Scheduled'],
    Scheduled: ['Pending', 'Sending', 'Paused', 'Failed', 'Canceled', 'Complete'],
    Paused: ['Pending', 'Sending', 'Failed', 'Canceled', 'Complete', 'Scheduled'],
  };

  statusColors = {
    Sending: '#3498db',
    Submitted: '#2ecc71',
    Sent: '#2ecc71',
    Canceled: '#dc143c ',
    Complete: '#27ae60',
    Paused: '#7d7c7c',
    Pending: '#077E8C',
    Draft: '#7d7c7c',
    Scheduled: '#9b59b6',
    Failed: '#e74c3c',
  };

  statusIcons = {
    Sending: 'fas fa-spinner',
    Submitted: 'fas fa-paper-plane',
    Sent: 'fas fa-paper-plane',
    Canceled: 'fas fa-ban',
    Complete: 'fas fa-check-circle',
    Paused: 'fas fa-pause',
    Pending: 'fas fa-stopwatch',
    Draft: 'fas fa-edit',
    Scheduled: 'fas fa-calendar',
    Failed: 'fas fa-exclamation',
  };

  messageStatusIcons = {
    Scheduled: 'fas fa-clock',
    Queued: 'fas fa-clock',
    Delayed: 'fas fa-clock',
    Sent: 'fas fa-paper-plane',
    Printed: 'fas fa-print',
    Delivered: 'fas fa-check-circle',
    Read: 'fas fa-eye',
    Clicked: 'fas fa-mouse-pointer',
    Expired: 'fas fa-hourglass-end',
    Rejected: 'fas fa-times-circle',
    Hard_Bounce: 'fas fa-backspace',
    Soft_Bounced: 'fas fa-backspace',
    'Hard Bounce': 'fas fa-backspace',
    'Soft Bounced': 'fas fa-backspace',
    Failed: 'fas fa-times-circle',
    Invalid: 'fas fa-times-circle',
    Received: 'fas fa-inbox',
    Read_Incoming: 'fas fa-eye',

    'Read Incoming': 'fas fa-eye',
    Actioned: 'fas fa-check-circle',
    Unknown: 'fas fa-question-circle',
    Blacklisted: 'fas fa-ban',
    Unsubscribed: 'fas fa-ban',
    Opened: 'fas fa-eye',
    Spam: 'fas fa-trash-alt',
    Error: 'fas fa-exclamation',
    Bounced: 'fas fa-backspace',
  };

  messageStatusColors = {
    Sending: '#3498db',
    Submitted: '#2ecc71',
    Canceled: '#dc143c ',
    Complete: '#27ae60',
    Paused: '#7d7c7c',
    Pending: '#077E8C',
    Draft: '#7d7c7c',
    Scheduled: '#9b59b6',
    Queued: '#3498db',
    Delayed: '#f39c12',
    Sent: '#27ae60',
    Printed: '#2ecc71',
    Delivered: '#2ecc71',
    Read: '#27ae60',
    Clicked: '#3498db',
    Expired: '#d23232',
    Rejected: '#e74c3c',
    Hard_Bounce: '#C63D2F',
    'Hard Bounce': '#C63D2F',
    'Soft Bounced': '#FF9B50',
    Soft_Bounced: '#FF9B50',
    Failed: '#e74c3c',
    Invalid: '#e74c3c',
    Received: '#2ecc71',
    Read_Incoming: '#27ae60',
    'Read Incoming': '#27ae60',
    Actioned: '#3498db',
    Unknown: '#bdc3c7',
    Blacklisted: '#692c36',
    Unsubscribed: '#dc506c ',
    Opened: '#27ae60',
    Spam: '#ce5e74',
    Error: '#c70000',
    Bounced: '#E25E3E',
  };

  statusMessageArray1 = ['Actioned', 'Blacklisted', 'Bounced', 'Clicked', 'Delayed', 'Delivered', 'Error', 'Expired'];
  statusMessageArray2 = ['Failed', 'Hard Bounce', 'Invalid', 'Opened', 'Printed', 'Queued', 'Read', 'Read Incoming'];
  statusMessageArray3 = [
    'Received',
    'Rejected',
    'Scheduled',
    'Sent',
    'Soft Bounced',
    'Spam',
    'Unknown',
    'Unsubscribed',
  ];

  messageGroupStatus = [
    'Canceled',
    'Complete',
    'Draft',
    'Failed',
    'Paused',
    'Pending',
    'Scheduled',
    'Sending',
  ];
  statusArray = ['Pending', 'Paused', 'Sent', 'Scheduled', 'Failed'];

  messageDescriptions = {
    Scheduled: 'The message is scheduled for future delivery. It is set to be sent at a specified date and time.',
    Queued: 'The message is in a queue, waiting for its turn to be processed and sent out.',
    Delayed:
      'Delivery of the message is intentionally delayed. This delay could be due to various reasons, such as optimization or user-defined settings.',
    Sent: "The message has been successfully sent to the recipient's mail server.",
    Printed: 'The message has been physically printed, possibly for offline distribution or record-keeping purposes.',
    Delivered: "The message has reached its intended recipient's inbox or destination successfully.",
    Read: 'The recipient has opened and read the message. This status indicates that the content has been viewed by the recipient.',
    Clicked: 'The recipient has interacted with the message by clicking on a link or taking some specified action.',
    Expired:
      'The message has reached its expiration date and is no longer considered valid for delivery or interaction.',
    Rejected: "The message has been rejected by the recipient's mail server or system, and delivery has been refused.",
    'Hard Bounce':
      "A permanent delivery failure has occurred. This often happens when the recipient's email address is invalid or does not exist.",
    'Soft Bounced':
      'A temporary delivery failure has occurred, such as a full mailbox. The email server will attempt to deliver the message again later.',
    Failed:
      'The delivery attempt of the message has failed for some reason, and the message was not successfully delivered.',
    Invalid:
      'The message is considered invalid, either due to its structure, content, or other reasons that make it undeliverable or unusable.',
    Received:
      "The recipient's mail server has successfully received the message, though this does not guarantee delivery to the recipient's inbox.",
    'Read Incoming':
      'The incoming message to the recipient has been read. This is often associated with messaging platforms where users can see when their messages are read by others.',
    Actioned:
      'The recipient has taken a specific action in response to the message, such as making a purchase or completing a form.',
    Unknown:
      'The status of the message is currently unknown or cannot be determined. This could be due to a lack of information or system error.',
    Blacklisted:
      'The recipient is on a blacklist, indicating that they have been flagged as someone not to receive messages.',
    Unsubscribed:
      'The recipient has opted out or unsubscribed from further messages, indicating a preference to no longer receive communications.',
    Opened:
      'The message has been opened by the recipient. This status is often associated with tracking whether an email has been viewed.',
    Spam: 'The message has been marked as spam by the recipient, their email provider, or a spam filter. It suggests that the content is perceived as unwanted or unsolicited.',
    Error:
      'An error occurred during the processing of the message. This could include issues with formatting, encoding, or other technical problems.',
    Bounced:
      'The message has bounced back, indicating a failure in delivery. This is a generic term that may include both hard bounces (permanent failures) and soft bounces (temporary failures).',
  };

  messageGroupDescriptions = {
    Canceled:
      'The Bulk SMS campaign has been canceled, and no further messages will be sent. This status is typically set by the user or system to halt the campaign.',
    Complete:
      'The Bulk SMS campaign has been successfully completed, and all messages have been sent to the intended recipients.',
    Draft:
      'The Bulk SMS campaign is in a draft state, indicating that it has been created but not yet finalized or scheduled for sending. Edits and adjustments may still be made.',
    Failed:
      'The Bulk SMS campaign has encountered a failure, and not all messages were successfully sent. This status may require investigation to identify and address the underlying issue.',
    Paused:
      'The Bulk SMS campaign has been temporarily halted or paused. Messages are not currently being sent, but the campaign is not canceled. It can be resumed later.',
    Pending:
      'The Bulk SMS campaign is in a pending state, awaiting further action or approval before it can proceed to the next phase, such as scheduling or sending.',
    Scheduled:
      'The Bulk SMS campaign is scheduled for future sending. It is set to automatically start at a specified date and time in the future.',
    Sending:
      'The Bulk SMS campaign is actively in the process of sending messages to the designated recipients. This status indicates the ongoing execution of the campaign.',
    Submitted:
      'The Bulk SMS campaign has been submitted for processing. It is in the queue and awaiting initiation, typically after being scheduled or manually triggered.',
  };

  statusMessageArray1SMS = ['Blacklisted', 'Delayed', 'Delivered', 'Error', 'Failed'];
  statusMessageArray2SMS = [ 'Invalid', 'Queued', 'Read', 'Received'];
  statusMessageArray3SMS = ['Rejected', 'Scheduled', 'Sent', 'Unknown', 'Unsubscribed'];

  getStatusColor(s, isStatus = true) {
    if (isStatus == true) {
      return this.statusColors[s];
    }
    return this.messageStatusColors[s];
  }

  setStatusColors(s) {
    if (s && s.length > 0) {
      this.statusColors = s;
    }
  }

  setMessageStatusColors(s) {
    if (s && s.length > 0) {
      this.messageStatusColors = s;
    }
  }

  setStatusIcons(s) {
    if (s && s.length > 0) {
      this.statusIcons = s;
    }
  }

  setMessageStatusIcons(s) {
    if (s && s.length > 0) {
      this.messageStatusIcons = s;
    }
  }

  getStatusIcon(s) {
    return this.statusIcons[s];
  }
  getMessageStatusIcon(s) {
    return this.messageStatusIcons[s];
  }
  getMessageGroupDescription(s) {
    return this.messageGroupDescriptions[s] || null;
  }

  getMessageDescription(s) {
    return this.messageDescriptions[s] || null;
  }

  canUpdateMessageGroupStatusLocal(status, targetStatus) {
    if (status && targetStatus) {
      let statusArray = this.allowStatusUpdateLocal[status];

      if (statusArray && statusArray.length > 0 && statusArray.indexOf(targetStatus) !== -1) {
        return true;
      }
    }

    return false;
  }
  canUpdateMessageGroupStatus(status, targetStatus) {
    if (status && targetStatus) {
      let statusArray = this.allowStatusUpdateGroup[status];

      if (statusArray && statusArray.length > 0 && statusArray.indexOf(targetStatus) !== -1) {
        return true;
      }
    }

    return false;
  }
}
