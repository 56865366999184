import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { ClientDetails } from '../types/client-details';

@Pipe({
  name: 'TimeLabelPast',
})
export class TimeLabelPast implements PipeTransform {
  constructor() {}

  transform(expression: any, timeZone = true) {
    try {
      if (expression == '0000-00-00') {
        return null;
      } else if (expression == '0000-00-00 00:00') {
        return null;
      }

      let result;

      const d = ClientDetails.convertTimeZone(expression, timeZone);

      let customerTimeZone = 0;

      if (ClientDetails.timeZoneUTCProfileCode && ClientDetails.timeZoneUTCProfileValue != null) {
        if (ClientDetails.timeZoneUTC != null) {
          customerTimeZone = Number(Number(ClientDetails.timeZoneUTCProfileValue) - Number(ClientDetails.timeZoneUTC));
        } else {
          customerTimeZone = Number(ClientDetails.timeZoneUTCProfileValue);
        }
      }

      if (moment.isDate(expression)) {
        result = moment(d).add(-customerTimeZone, 'hours').toNow();

        result = result.replace('ago', '');
        result = result.replace('in ', '');
        return result;
      } else if (Number.isInteger(expression)) {
        const _tmp = Number(expression);

        if (!Number.isNaN(_tmp)) {
          if (_tmp <= 0) {
            return null;
          } else if (_tmp <= 60) {
            return 'Just Now';
          } else if (_tmp > 60 && _tmp < 3600) {
            const r = Number(_tmp / 60).toFixed(0);
            return r + ' min(s)';
          } else if (_tmp > 3600 && _tmp < 3600 * 24) {
            const r = Number(_tmp / (60 * 60)).toFixed(0);
            return r + ' hour(s)';
          }

          const r = Number(_tmp / (60 * 60 * 24)).toFixed(0);
          return r + ' day(s)';
        }
      } else if (typeof expression == 'string') {
        result = moment(d).add(-customerTimeZone, 'hours').toNow();

        result = result.replace('ago', '');
        result = result.replace('in ', '');

        return result;
      }

      return expression;
    } catch (e) {
      return expression;
    }
  }
}
