import { NgModule } from '@angular/core';
import { NotificationModule } from '../../../../../common/core/notification/notification.module';
import { ActionLogModule } from '../../../../../common/feature/action-log/action-log.module';
import { ActivityLogModule } from '../../../../../common/feature/activity-log/activity-log.module';
import { ContractModule } from '../../../../../common/feature/contract/contract.module';
import { CustomerProspectModule } from '../../../../../common/feature/customer-prospect/customerProspect.module';
import { DentistryTypesModule } from '../../../../../common/feature/dentistry-types/dentistry-types.module';
import { ExistingPatientModule } from '../../../../../common/feature/existing-patient/existing-patient.module';
import { InvitationModule } from '../../../../../common/feature/invitation/invitation.module';
import { MerchantHealthFundsModule } from '../../../../../common/feature/merchant-health-funds/merchant-health-funds.module';
import { MerchantServicesModule } from '../../../../../common/feature/merchant-services/merchant-services.module';
import { MerchantModule } from '../../../../../common/feature/merchant/merchant.module';
import { OperatorModule } from '../../../../../common/feature/operator/operator.module';
import { PerformanceModule } from '../../../../../common/feature/performance/performance.module';
import { TasksModule } from '../../../../../common/feature/tasks/tasks.module';
import { TipModule } from '../../../../../common/feature/tip/tip.module';
import { SharedModule } from '../../../../../common/shared/shared.module';
import { DashboardIntroModalComponent } from './dashboard-intro-modal/dashboard-intro-modal.component';
import { DashbordOverViewComponent } from './dashbord-over-view/dashbord-over-view.component';
import { DashboardRoutingModule } from './shared/dashboard-routing.module';

@NgModule({
  imports: [
    SharedModule,
    MerchantModule,
    InvitationModule,
    CustomerProspectModule,
    ExistingPatientModule,
    TipModule,
    MerchantServicesModule,
    DentistryTypesModule,
    MerchantHealthFundsModule,
    NotificationModule,
    OperatorModule,
    ContractModule,
    ActionLogModule,
    TasksModule,
    PerformanceModule,
    ActivityLogModule,
    DashboardRoutingModule,
  ],
  exports: [DashbordOverViewComponent, DashboardIntroModalComponent],
  declarations: [DashbordOverViewComponent, DashboardIntroModalComponent],
})
export class DashboardModule {}
