import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as ics from 'ics';
import * as moment from 'moment';
import { CalendarShareService } from '../../services/calendar-share.service';

export const enum CalendarType {
  google = 'google',
  apple = 'apple',
  microsoft = 'microsoft',
  all = 'all',
}

export enum ButtonType {
  menu = 'menu',
  raised = 'raised',
}

export class CalendarEvent {
  summary: string;
  location?: string;
  description?: string;
  start: {
    dateTime: Date;
    timeZone?: string;
  };
  end: {
    dateTime: Date;
    timeZone?: string;
  };
  recurrence?: string[];
  attendees?: { email: string }[];
  reminders?: {
    useDefault: boolean;
    overrides: { method: string; minutes: number }[];
  };
}

@Component({
  selector: 'app-calendar-event-share',
  templateUrl: './calendar-event-share.component.html',
  styleUrls: ['./calendar-event-share.component.css'],
})
export class CalendarEventShareComponent implements OnInit {
  @Input() event: CalendarEvent;
  @Input() type: CalendarType = CalendarType.all;
  @Input() designType = ButtonType.menu;
  @Input() appleIsConnected;
  @Input() googleIsConnected;
  @Input() microsoftIsConnected;
  @Input() displayConnectedIcon = true;
  @Input() googleButtonLabel = 'Google Calendar';
  @Input() appleButtonLabel = 'iCalendar';
  @Input() microsoftButtonLabel = 'Outlook Calendar';

  @Output() success = new EventEmitter();

  gapi = window['gapi'];
  googleCalendarEnabled = false;

  // msCalendarEnabled = false;

  constructor(private calendarShare: CalendarShareService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    // init google client
    this.calendarShare.initGoogleClient();
  }

  addToGoogleCalendar() {
    this.calendarShare
      .addToGoogleCalendar(this.event)
      .then((res) => this.success.emit({ id: res, calendar: CalendarType.google }))
      .catch((res) => this.success.emit(false));
  }

  addToAppleCalendar() {
    const startYear = moment(this.event.start.dateTime).year();
    const startMonth = moment(this.event.start.dateTime).month() + 1;
    const startDate = moment(this.event.start.dateTime).date();
    const startHour = moment(this.event.start.dateTime).hour();
    const startMin = moment(this.event.start.dateTime).minute();

    const endYear = moment(this.event.end.dateTime).year();
    const endMonth = moment(this.event.end.dateTime).month() + 1;
    const endDate = moment(this.event.end.dateTime).date();
    const endHour = moment(this.event.end.dateTime).hour();
    const endMin = moment(this.event.end.dateTime).minute();

    const event: ics.EventAttributes = {
      start: [startYear, startMonth, startDate, startHour, startMin],
      end: [endYear, endMonth, endDate, endHour, endMin],
      title: this.event.summary,
      // description: 'Annual 10-kilometer run in Boulder, Colorado',
      // location: 'Folsom Field, University of Colorado (finish line)',
      // attendees: [
      //   { name: 'Adam Gibbons', email: 'adam@example.com', rsvp: true, partstat: 'ACCEPTED', role: 'REQ-PARTICIPANT' },
      //   { name: 'Brittany Seaton', email: 'brittany@example2.org', dir: 'https://linkedin.com/in/brittanyseaton', role: 'OPT-PARTICIPANT' }
      // ]
    };

    this.calendarShare
      .addToAppleCalendar(event)
      .then((res) => this.success.emit({ id: res, calendar: CalendarType.apple }))
      .catch((res) => this.success.emit(false));
  }

  addToMicrosoftCalendar() {
    const event = {
      subject: this.event.summary,
      start: this.event.start,
      end: this.event.end,
    };

    this.calendarShare
      .addToMicrosoftCalendar(event)
      .then((res) => this.success.emit({ id: res, calendar: CalendarType.microsoft }))
      .catch((res) => this.success.emit(false));
  }
}
