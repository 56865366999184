<div class="container audit" *ngIf="invitation">
  <div class="row clearfix">
    <h2 class="sr-title ndge pull-left">Detailed Invitation View</h2>
  </div>
  <div class="row clearfix flex topRow">
    <div class="full-width">
      <mat-card class="">
        <div class="ribbon" *ngIf="givenInformedConsentDate"
          matTooltip="Consent: {{ givenInformedConsentDate | customDateTime }}">
          <span>CONSENT GIVEN</span>
        </div>
        <div class="card-header primary-gradient-img clearfix inModal">
          <div class="row clearfix">
            <!-- merchant calculated name -->
            <div class="row clearfix">
              <app-ep-document-view-image class="patient-invitation-View userIconView pull-left mr" [defaultPicture]="
                  'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
                " [link]="profileLink">
              </app-ep-document-view-image>





              <app-invitation-actions *ngIf="invitation && invitation.ID &&
                sessionType &&
                (sessionType == 'admin' ||
                  sessionType == 'merchant' ||
                  sessionType == 'merchant-admin' ||
                  sessionType == 'promoter') " class=" contract-action-invitation2  pull-right mt"
                [buttonType]="'buttonAccent'" [customerID]="invitation['Prospect_key']" [displayDeleteInvitation]="false"
                [invitationID]="invitation['ID']" [contractID]="invitation['QuotationORContract_Key']"
                [merchantID]="invitation['Merchant_Key']" [dentistID]="invitation['DentistContact_key']"
                [campaignID]="invitation['Campaign_Key']" [instantOfferID]="invitation['Instant_Finance_Offer_Key']"
                [landingPageID]="invitation['Document_Link_key']" [invitation]="invitation" [items]="items"
                [productID]="invitation['Product_Key']" (getDeletedInvitationID)="getDeletedInvitationID($event)"
                (getInvitationUpdate)="getInvitationUpdate($event)"></app-invitation-actions>


              <h2 class="summary-header rm-m ml">
                {{ "KEYWORD.treatment" | translate | titlecase }} Value
                <strong>{{ invitation['treatment.Value'] | customCurrency }}</strong>

                <span class="subLabel"> {{"KEYWORD.Treatment" | translate}} details
                  <span *ngIf="isPromoterOrAdmin">
                    | Merchant:
                    <strong>{{ invitation['MerchantName'] }} </strong>
                  </span>
                </span>
              </h2>

            </div>
          </div>
          <div class="action-button">



            <!-- <button class="actionMenuToggle" *ngIf="
                sessionType &&
                (sessionType == 'admin' ||
                  sessionType == 'merchant' ||
                  sessionType == 'merchant-admin' ||
                  sessionType == 'promoter')
              " [matMenuTriggerFor]="btnMenu" mat-raised-button>
              Actions
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #btnMenu="matMenu">


              <button class=""
                *ngIf="invitation['Product_Key'] && invitation['Product_Key'] != '00000000000000000000000000000000'"
                (click)="openProductViewDialog()" mat-menu-item>
                <mat-icon>business_center</mat-icon> View Product
              </button>

              <button *ngIf="invitation['ID'] && invitation['Campaign_Key']"
                (click)="openCampaign(invitation['Campaign_Key'])" mat-menu-item>
                <mat-icon>language</mat-icon>
                <span>View Campaign</span>
              </button>

              <div [hidden]="invitation['Date.Commenced'] != '0000-00-00' || invitation['Voided'] != '0'">
                <button [matMenuTriggerFor]="submenu" mat-menu-item>
                  <mat-icon>help_outline</mat-icon>
                  Status
                </button>
                <mat-menu #submenu="matMenu">
                  <button *ngIf="
                      (context != 'retail' &&
                        invitation['Status.Invitation.Short_NE'] != 'Not viewed' &&
                        invitation['Status.Invitation.Short_NE'] != 'Submitted' &&
                        invitation['Status.Invitation.Short_NE'] != 'Proceeded' &&
                        invitation['Status.Invitation.Short_NE'] != 'Pay Cash/Card' &&
                        invitation['Status.Invitation.Short_NE'] != 'Expired' &&
                        invitation['Status.Invitation.Short_NE'] != 'Not Proceeding' &&
                        invitation['Status.Invitation.Short_NE'] != 'Intends to Proceed' &&
                        invitation['Status.Invitation.Short_NE'] != 'Via Web') ||
                      isPromoterOrAdmin == true
                    " (click)="payCashInvitation()" mat-menu-item>
                    <mat-icon>thumb_up</mat-icon> Proceeding with Cash
                  </button>
                  <button *ngIf="
                      (context != 'retail' &&
                        invitation['Status.Invitation.Short_NE'] != 'Not viewed' &&
                        invitation['Status.Invitation.Short_NE'] != 'Submitted' &&
                        invitation['Status.Invitation.Short_NE'] != 'Proceeded' &&
                        invitation['Status.Invitation.Short_NE'] != 'Pay Cash/Card' &&
                        invitation['Status.Invitation.Short_NE'] != 'Expired' &&
                        invitation['Status.Invitation.Short_NE'] != 'Not Proceeding' &&
                        invitation['Status.Invitation.Short_NE'] != 'Intends to Proceed' &&
                        invitation['Status.Invitation.Short_NE'] != 'Via Web') ||
                      isPromoterOrAdmin == true
                    " (click)="deferInvitation()" mat-menu-item>
                    <mat-icon>alarm</mat-icon> Deferring
                  </button>
                  <button *ngIf="
                      (context != 'retail' &&
                        invitation['Status.Invitation.Short_NE'] != 'Not viewed' &&
                        invitation['Status.Invitation.Short_NE'] != 'Submitted' &&
                        invitation['Status.Invitation.Short_NE'] != 'Proceeded' &&
                        invitation['Status.Invitation.Short_NE'] != 'Pay Cash/Card' &&
                        invitation['Status.Invitation.Short_NE'] != 'Expired' &&
                        invitation['Status.Invitation.Short_NE'] != 'Not Proceeding' &&
                        invitation['Status.Invitation.Short_NE'] != 'Intends to Proceed' &&
                        invitation['Status.Invitation.Short_NE'] != 'Via Web') ||
                      isPromoterOrAdmin == true
                    " (click)="changesToInvitation()" mat-menu-item>
                    <mat-icon>edit</mat-icon> Requires Changes
                  </button>
                  <button *ngIf="
                      (context != 'retail' &&
                        invitation['Status.Invitation.Short_NE'] != 'Not viewed' &&
                        invitation['Status.Invitation.Short_NE'] != 'Submitted' &&
                        invitation['Status.Invitation.Short_NE'] != 'Proceeded' &&
                        invitation['Status.Invitation.Short_NE'] != 'Pay Cash/Card' &&
                        invitation['Status.Invitation.Short_NE'] != 'Expired' &&
                        invitation['Status.Invitation.Short_NE'] != 'Not Proceeding' &&
                        invitation['Status.Invitation.Short_NE'] != 'Intends to Proceed' &&
                        invitation['Status.Invitation.Short_NE'] != 'Via Web') ||
                      isPromoterOrAdmin == true
                    " (click)="notProceedingInvitation()" mat-menu-item>
                    <mat-icon>report_problem</mat-icon> Not Proceeding
                  </button>
                  <mat-divider *ngIf="
                      (context != 'retail' &&
                        invitation['Status.Invitation.Short_NE'] != 'Not viewed' &&
                        invitation['Status.Invitation.Short_NE'] != 'Submitted' &&
                        invitation['Status.Invitation.Short_NE'] != 'Proceeded' &&
                        invitation['Status.Invitation.Short_NE'] != 'Pay Cash/Card' &&
                        invitation['Status.Invitation.Short_NE'] != 'Expired' &&
                        invitation['Status.Invitation.Short_NE'] != 'Not Proceeding' &&
                        invitation['Status.Invitation.Short_NE'] != 'Intends to Proceed' &&
                        invitation['Status.Invitation.Short_NE'] != 'Via Web') ||
                      isPromoterOrAdmin == true
                    ">
                  </mat-divider>
                  <button *ngIf="
                      invitation['Voided'] == '0' &&
                      invitation['Status.Invitation.Short_NE'] != 'Submitted' &&
                      invitation['Status.Application.Short_NE']?.indexOf('Contract -') == -1 &&
                      invitation['Status.Invitation.Short_NE'] != 'Proceeded' &&
                      invitation['Status.Invitation.Short_NE'] != 'Pay Cash/Card'
                    " (click)="deleteInvitation()" mat-menu-item>
                    <mat-icon class="warn-color">delete</mat-icon>
                    <span class="warn-color">Archive</span>
                  </button>
                </mat-menu>
              </div>

              <button [matMenuTriggerFor]="invmenu" mat-menu-item>
                <mat-icon>email</mat-icon>
                Invitation Tools
              </button>
              <mat-menu #invmenu="matMenu">
                <button class="" *ngIf="
                    invitation['Status.Invitation.Short_NE'] == 'Not viewed' ||
                    invitation['Status.Invitation.Short_NE'] == 'Viewed' ||
                    (invitation['Status.Invitation.Short_NE'] &&
                      invitation['Status.Invitation.Short_NE'].includes('Defered'))
                  " (click)="resendInvitation()" mat-menu-item>
                  <mat-icon>refresh</mat-icon>
                  {{ 'Resend' | translate }}
                </button>

                <button (click)="cloneInvitation()" mat-menu-item>
                  <mat-icon>file_copy</mat-icon>
                  <span>Clone</span>
                </button>

                <button *ngIf="isPromoterOrAdmin == true && invitation['ID']" (click)="invitationLog()" mat-menu-item>
                  <mat-icon class="fa fa-list-alt"></mat-icon>
                  <span>Invitation Events</span>
                </button>

                <button (click)="createNote(invitation['Prospect_key'], invitation['ID'])" mat-menu-item>
                  <mat-icon>note_add</mat-icon>
                  <span>Create Note</span>
                </button>

                <button (click)="viewNotes(invitation['Prospect_key'])" mat-menu-item>
                  <mat-icon class="fa fa-list-alt"></mat-icon>
                  <span>View notes</span>
                </button>

                <button *ngIf="
                    invitation['Status.Invitation.Short_NE'] != 'Expired' &&
                    invitation['WakandaID'] &&
                    isModuleTreatmentTemplateActive == true
                  " (click)="createTemplate()" mat-menu-item>
                  <mat-icon>assignment</mat-icon>
                  <span>Create {{ "KEYWORD.treatment" | translate | titlecase }} Template</span>
                </button>

                <div [hidden]="
                    invitation['Date.Commenced'] == '0000-00-00' ||
                    invitation['Voided'] != '0' ||
                    invitation['Status.Invitation.Short_NE'] == 'Submitted' ||
                    invitation['Status.Application.Short_NE']?.indexOf('Contract -') == -1 ||
                    invitation['Status.Invitation.Short_NE'] == 'Proceeded'
                  ">
                  <button (click)="deleteInvitation()" mat-menu-item>
                    <mat-icon class="warn-color">delete</mat-icon>
                    <span class="warn-color">Archive</span>
                  </button>
                </div>
              </mat-menu>

              <button [matMenuTriggerFor]="patmenu" mat-menu-item>
                <mat-icon>face</mat-icon>
                {{ "KEYWORD.patient" | translate | titlecase }} Tools
              </button>
              <mat-menu #patmenu="matMenu">
                <button *ngIf="invitation['ID'] && isPromoterOrAdmin == true" (click)="wakandaFiles(invitation['ID'])"
                  mat-menu-item>
                  <mat-icon>attach_file</mat-icon>
                  <span>{{"KEYWORD.patient" | translate | titlecase}} Uploaded Files</span>
                </button>

                <button
                  *ngIf="invitation['Prospect_key'] && isModulePatientSMSActive == true && invitation['Merchant_Key']"
                  (click)="sendSMS(invitation['Prospect_key'], invitation['Merchant_Key'])" mat-menu-item>
                  <mat-icon>textsms</mat-icon>
                  <span>Send SMS</span>
                </button>

                <button *ngIf="
                    isPromoterOrAdmin == true &&
                    invitation['Prospect_key'] &&
                    invitation['Prospect_key'] != '00000000000000000000000000000000' &&
                    invitation['Prospect_key'] != '20202020202020202020202020202020'
                  " (click)="patientLog()" mat-menu-item>
                  <mat-icon class="fa fa-list-alt"></mat-icon>
                  <span>View {{ "KEYWORD.patient" | translate | titlecase }} Logs</span>
                </button>

                <button *ngIf="isPromoterOrAdmin == true && invitation['contactID']" (click)="contractLog()"
                  mat-menu-item>
                  <mat-icon class="fa fa-list-alt"></mat-icon>
                  <span>{{ 'View Contract Logs' | translate }}</span>
                </button>

                <button *ngIf="invitation['Email']" (click)="contact()" mat-menu-item>
                  <mat-icon>contact_mail</mat-icon>
                  {{ 'Contact Customer' | translate }}
                </button>

                <button *ngIf="invitation['Address']" mat-menu-item>
                  <mat-icon>contact_mail</mat-icon>
                  {{ 'Map View' | translate }}
                </button>
              </mat-menu>
            </mat-menu> -->
          </div>
        </div>

        <app-invitation-progress-view [invitation]="invitation"></app-invitation-progress-view>
      </mat-card>
    </div>
  </div>

  <div class="row clearfix statuses">
    <mat-card id="tool6" *ngIf="isPromoterOrAdmin == true && isModuleAdvancedTreatmentActive">
      <div class="colour-stripe"></div>
      <div class="row clearfix prerequisites">
        <div class="row clearfix">
          <h3 class="sm-h3 sr-title rm-mb rm-mt">Informed Consent</h3>
          <p class="rm-mt">Must be agreed to before {{ "KEYWORD.treatment" | translate }} can commence</p>
          <hr class="mb" />
        </div>
        <div class="row clearfix">
          <!-- LIST CONSENT -->
          <mat-list>
            <mat-list-item class="flex consent">
              <p class="bold">{{ 'InformedConsentInfo' | translate }}</p>
              <p>Date: 00/00/0000</p>
              <p>Time: 00:00</p>
              <mat-icon class="g" *ngIf="consViewed === true" matTooltip="Consent has been agreed to">check_box
              </mat-icon>
              <mat-icon class="r" *ngIf="consViewed === false" matTooltip="Consent not agreed to">
                check_box_outline_blank
              </mat-icon>
            </mat-list-item>
          </mat-list>
          <div class="row clearfix preRes">
            <p *ngIf="consComplete === false">
              <mat-icon>error_outline</mat-icon>
              <em> Your {{ "KEYWORD.patient" | translate }} has not agreed to the Informed Consent. </em>
            </p>
            <p *ngIf="consComplete === true">
              <mat-icon>thumb_up</mat-icon>
              <em>Your {{ "KEYWORD.patient" | translate }} has agreed to the Informed Consent.</em>
            </p>
          </div>
          <div class="row clearfix">
            <hr class="mb" />
            <h3 class="sm-h3 sr-title rm-mb rm-mt">Supporting Documents</h3>
            <p class="rm-mt">Your {{ "KEYWORD.patient" | translate }} has viewed the following documents</p>
          </div>
          <mat-list>
            <mat-divider></mat-divider>
            <!--  LIST DOCUMENTS -->
            <mat-list-item class="flex document">
              <p class="bold">{{ 'TreatmentPlan' | translate }}</p>
              <p>Date: 00/00/0000</p>
              <p>Time: 00:00</p>
              <mat-icon class="g" *ngIf="docViewed === true" matTooltip="Document has been viewed">check_box</mat-icon>
              <mat-icon class="r" *ngIf="docViewed === false" matTooltip="Document not viewed">check_box_outline_blank
              </mat-icon>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
    </mat-card>
  </div>
  <!--
  <div class="row clearfix statuses"
    *ngIf="isPromoterOrAdmin==true && invitation['Contract_Key'] && invitation['Contract_Key']!='00000000000000000000000000000000'">
    <app-personal-circumstances [contractID]="invitation['Contract_Key']"></app-personal-circumstances>
  </div> -->

  <div class="row clearfix statuses pd"
    *ngIf="invitation['Quoted.Repayments.Count'] != '0' && isPromoterOrAdmin == true">
    <mat-card class="clearfix">
      <div class="colour-stripe"></div>
      <div class="row clearfix">
        <h3 class="sm-h3 sr-title rm-m">{{ 'PaymentDetails' | translate }}</h3>
        <hr class="mb" />
      </div>
      <div class="row clearfix mb">
        <div class="column">
          <div class="icons-col">
            <mat-icon>attach_money</mat-icon>
          </div>
          <div class="row-content-col summary">
            <div class="row clearfix">
              <div class="half-width">
                <div class="col-left">
                  <div class="block">
                    <label>Duration :</label>
                    <p class="rm-mt" *ngIf="invitation['Quoted.Repayments.Count'] != '0'">
                      {{ invitation['Quoted.Repayments.Count'] }}
                      {{ invitation['Quoted.Repayments.Frequency.Noun.Singular'] }}(s)
                    </p>
                  </div>
                </div>
              </div>
              <div class="half-width">
                <div class="col-right">
                  <div class="block">
                    <label>{{ 'Frequency' | translate }}:</label>
                    <p class="rm-mt">{{ invitation['Quoted.Repayments.Frequency.Adverb'] }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row clearfix">
              <div class="half-width">
                <div class="col-left">
                  <div class="block">
                    <label>Customer Contribution:</label>
                    <p class="rm-mt">{{ invitation['CustomerContribution'] | customCurrency }}</p>
                  </div>
                </div>
              </div>
              <div class="half-width">
                <div class="col-right">
                  <div class="block">
                    <label>Total payable by customer:</label>
                    <p class="rm-mt">{{ invitation['Quoted.TotalRepayable'] | customCurrency }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>

  <!-- <div class="row clearfix statuses"
    *ngIf="isPromoterOrAdmin==true && invitation['contactID'] && invitation['contactID']!='00000000000000000000000000000000' && invitation['Status.Contract.Short_NE']=='Active'">
    <mat-card class="clearfix">
      <div class="colour-stripe "></div>
      <app-contract-view [contractID]="invitation['contactID']"></app-contract-view>
    </mat-card>
  </div> -->

  <div class="row clearfix flex consentcard" id="consentNoDoc" #consentNoDoc
    *ngIf="givenInformedConsentDate && documents.length <= 0">
    <!-- title block for printing -->
    <div class="hidden-information">
      <div class="row clearfix">
        <div class="logo-block">
          <img class="site_logo" [src]="getFileLink(settings.BrandingLogoImage)" />
        </div>
        <div class="title-block">
          <h3 class="rm-mb">{{ brandName }}</h3>
          <p class="small rm-mt" [innerHTML]="settings.snapLine | safeHtml"></p>
        </div>
      </div>
      <hr />
    </div>

    <mat-card class="full-width">
      <div class="ribbon" *ngIf="givenInformedConsentDate"
        matTooltip="Consent: {{ givenInformedConsentDate | customDateTime }}">
        <span>CONSENT GIVEN</span>
      </div>
      <div class="colour-stripe"></div>
      <div class="row clearfix">
        <h3 class="sm-h3 sr-title rm-mb rm-m">Consent</h3>

        <hr class="mb" />
      </div>
      <div class="hide-pr">
        <p>{{ "KEYWORD.patient" | translate | titlecase}} Informed Consent Given:</p>
        <p class="sr-title">
          <strong>{{ givenInformedConsentDate | customDateTime }}</strong>
        </p>
      </div>

      <button *ngIf="enablePrinting" mat-raised-button printTitle="Informed Consent {{ invitation['FirstName'] }} {{ invitation['MiddleName'] }} {{
          invitation['LastName']
        }}" printSectionId="consentNoDoc" styleSheetFile="/assets/styles/print-consent.css" ngxPrint color="primary">
        <mat-icon>print</mat-icon> PRINT
      </button>
    </mat-card>

    <div class="hidden-information" *ngIf="givenInformedConsentDate">
      <hr />
      <p>
        The informed consent was digitally signed by
        <strong>{{ invitation['FirstName'] }} {{ invitation['MiddleName'] }} {{ invitation['LastName'] }}</strong> on
        <strong>{{ givenInformedConsentDate | customDateTime }}</strong>
      </p>
      <hr />
    </div>
  </div>

  <!-- DOCUMENT AREA -->

  <div class="full-width flex prerequisites inner-mar" id="docContent" #docContent
    [hidden]="!documents || documents.length <= 0">
    <!-- title block for printing -->
    <div class="hidden-information">
      <div class="row clearfix">
        <div class="logo-block">
          <img class="site_logo" [src]="getFileLink(settings.BrandingLogoImage)" />
        </div>
        <div class="title-block">
          <h3 class="rm-mb">{{ brandName }}</h3>
          <p class="small rm-mt" [innerHTML]="settings.snapLine | safeHtml"></p>
        </div>
      </div>

      <hr />
    </div>

    <mat-card class="full-width btn-pad" [hidden]="!documents || documents.length <= 0"
      [class.vertical]="documents.length >= 3" [class.tall]="documents.length >= 4">
      <div class="ribbon hide-print" *ngIf="givenInformedConsentDate"
        matTooltip="Consent: {{ givenInformedConsentDate | customDateTime }}">
        <span>CONSENT GIVEN</span>
      </div>

      <div class="colour-stripe"></div>

      <div class="row clearfix">
        <h3 class="sm-h3 sr-title rm-mb rm-m pull-left">Informed Consent</h3>

        <div class="pull-right display-print">
          <p class="consent-given rm-m" *ngIf="givenInformedConsentDate">CONSENT GIVEN</p>
        </div>
      </div>

      <hr class="add-mb" />

      <div class="chart-card row clearfix mt" *ngIf="documents && documents.length > 0">
        <div class="row clearfix">
          <div class="row clearfix chart-area hide-print">
            <div class="column">
              <div class="row clearfix rel">
                <div class="total anim">
                  <!--total document number -->
                  <span>{{ documents.length }}</span>
                  <span class="totSub">Total</span>
                </div>
                <app-chart-input class="fullHeight documentlist" *ngIf="documents && documents.length > 0"
                  [titleIsTop]="true" [showLegend]="false" [showLabel]="false" [chartType]="'pie'" [title]="''"
                  [data]="documentChartData | orderBy: ['-name']" [dataNameAttribute]="'name'"
                  [dataValueAttribute]="'value'">
                </app-chart-input>
              </div>
            </div>
          </div>
          <div class="row clearfix legend-area">
            <div class="row clearfix legendary mt">
              <label class="rm-mt num anim statusList">
                <span class="wide splitLabel">
                  <strong class="greyble pr" [style.color]="chartColors[0]">Viewed</strong>
                  {{ "KEYWORD.patient" | translate }}
                </span>
                <strong class="pill" [style.background]="chartColors[0]">{{ seenDocs }}</strong>
              </label>

              <label class="rm-mt num anim statusList">
                <span class="wide splitLabel">
                  <strong class="greyble pr" [style.color]="chartColors[1]">Not Viewed</strong>
                  Documents yet to be seen
                </span>
                <strong class="pill" [style.background]="chartColors[1]">{{ unseenDocs }}</strong>
              </label>

              <label class="rm-mt num anim statusList" [class.good]="seenPercent == '1'">
                <span class="wide splitLabel">
                  <strong class="greyble">{{ 'Completion' | translate }}</strong>
                </span>
                <strong class="pill perc">{{ seenPercent | percent: '1.0-0' }}</strong>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="bottom-btn">
        <hr />

        <button class="pull-left" *ngIf="enablePrinting" mat-raised-button printTitle="Informed Consent {{ invitation['FirstName'] }} {{ invitation['MiddleName'] }} {{
            invitation['LastName']
          }}" printSectionId="docContent" styleSheetFile="/assets/styles/print-consent.css" ngxPrint color="primary">
          <mat-icon>print</mat-icon> PRINT
        </button>

        <p class="pull-right" *ngIf="givenInformedConsentDate">
          {{ "KEYWORD.patient" | translate | titlecase}} Informed Consent Given
          <strong class="primary-color">{{ givenInformedConsentDate | customDateTime }}</strong>
        </p>
      </div>
    </mat-card>

    <div class="hidden-information mb mt" *ngIf="givenInformedConsentDate">
      <p>
        The informed consent was digitally signed by
        <strong>{{ invitation['FirstName'] }} {{ invitation['MiddleName'] }} {{ invitation['LastName'] }}</strong> on
        <strong>{{ givenInformedConsentDate | customDateTime }}</strong>
      </p>
      <hr />
    </div>

    <mat-card class="full-width" [hidden]="!documents || documents.length <= 0">
      <div class="colour-stripe"></div>

      <div class="row clearfix">
        <h3 class="sm-h3 sr-title rm-mb rm-m">{{ 'DocumentationSupplied' | translate }}</h3>

        <hr class="mb hide-pr" />
      </div>

      <div class="row clearfix">
        <app-treatment-document class="clearfix" [canView]="false" [invitationID]="invitation['ID']"
          [isDisplayViewedDate]="true" [isFilter]="false" [view]="'merchant'" (getDocuments)="getDocuments($event)"
          (getViewedDocuments)="getViewedDocuments($event)">
        </app-treatment-document>
      </div>
    </mat-card>

    <!-- no docs -->

    <mat-card class="full-width" [hidden]="documents && documents.length > 0">
      <div class="colour-stripe"></div>

      <div class="row clearfix">
        <h3 class="sm-h3 sr-title rm-mb rm-m">{{ 'DocumentationSupplied' | translate }}</h3>
        <hr class="mb" />
      </div>
      <div class="row clearfix merchantInformedConsent" *ngIf="givenInformedConsentDate">
        <span>
          <span>
            {{ "KEYWORD.patient" | translate | titlecase}} Informed Consent Given
            <span class="pull-right">{{ givenInformedConsentDate | customDateTime }}</span>
          </span>
        </span>

        <button *ngIf="enablePrinting" mat-raised-button printTitle="Informed Consent {{ invitation['FirstName'] }} {{ invitation['MiddleName'] }} {{
            invitation['LastName']
          }}" printSectionId="docContent" styleSheetFile="/assets/styles/print-consent.css" ngxPrint color="primary">
          <mat-icon>print</mat-icon> PRINT
        </button>
      </div>

      <app-empty-list-message class="full-width row clearfix" [title]="''"
        [message]="'There is no document attached to this invitation'"></app-empty-list-message>
    </mat-card>
  </div>

  <!-- END DOCUMENT AREA -->

  <div class="full-width flex">
    <mat-card class="clearfix full-width">
      <div class="colour-stripe"></div>
      <app-message-timeline [invitationID]="invitation['ID']" [patientID]="invitation['Prospect_key']"
        [merchantID]="invitation['Merchant_Key']" matchItemHeight="matchThis"></app-message-timeline>
    </mat-card>
  </div>

  <div class="full-width text-center mt" *ngIf="isParameter">
    <button class="btn-large" (click)="cancel()" mat-raised-button color="accent">Go Back</button>
  </div>

  <div class="full-width text-center mt" *ngIf="isIntegrated == true">
    <button class="btn-large" (click)="goBackEvent()" mat-raised-button color="accent">
      <mat-icon>keyboard_arrow_left</mat-icon>
      Go Back
    </button>
  </div>
</div>
