<!-- header -->
<div class="clearfix full-width" *ngIf="medication && medication.ID">
  <div class="card-header primary-gradient-img clearfix inModal">
    <!-- close modal -->
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button" *ngIf="isModal == true" (click)="closeModal()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <app-drugs-thumbnail class="avatar" [hideZoomButton]="false" [drugID]="medication.Drug_key">
        </app-drugs-thumbnail>
        <h2 class="summary-header rm-m">
          {{ title }}
          <span class="subLabel" *ngIf="description">{{ description }}</span>
        </h2>
      </div>
    </div>
    <div class="date-chip-area">
      <p class="small rm-m mr" *ngIf="medication.DateTimeCreated">
        <mat-icon class="fas fa-clock"></mat-icon>
        {{ medication.DateTimeCreated | TimeLabelPast }} ago
      </p>
    </div>
  </div>
  <!-- content area -->
  <mat-dialog-content>
    <div class="row clearfix flex">
      <div class="full-width">
        <div class="row clearfix full-width flex force">
          <div class="full-width">
            <h5 class="sr-title">Medication name</h5>
            <p class="rm-mt">{{ medication['Drug.Name'] }}</p>
          </div>

          <div class="full-width">
            <h5 class="sr-title">Still taking it</h5>
            <p class="rm-mt" *ngIf="medication['stopDate'] != '0000-00-00'">Yes</p>
            <p class="rm-mt" *ngIf="medication['stopDate'] == '0000-00-00'">No</p>
          </div>

          <div class="full-width" *ngIf="medication['startDate']">
            <h5 class="sr-title">Usage duration</h5>
            <p class="rm-mt">
              {{ medication['startDate'] | timeLeft: medication['stop']:'years,months':false }}
            </p>
          </div>
        </div>
        <hr
          *ngIf="
            medication['DosageFrequency.Label'] ||
            (medication['startDate'] && medication['startDate'] != '0000-00-00') ||
            (medication['stopDate'] && medication['stopDate'] != '0000-00-00')
          "
        />
        <div class="row clearfix full-width flex force">
          <div class="full-width" *ngIf="medication['startDate'] && medication['startDate'] != '0000-00-00'">
            <h5 class="sr-title">First usage</h5>
            <p class="rm-mt">{{ medication['startDate'] | customDate: 'MMMM-YYYYY' }}</p>
          </div>
          <div class="full-width" *ngIf="medication['stopDate'] && medication['stopDate'] != '0000-00-00'">
            <h5 class="sr-title">Last usage</h5>
            <p class="rm-mt">{{ medication['stopDate'] | customDate: 'MMMM-YYYYY' }}</p>
          </div>

          <div class="full-width" *ngIf="medication['DosageFrequency.Label']">
            <h5 class="sr-title">How often it is taken</h5>
            <p class="rm-mt">{{ medication['DosageFrequency.Label'] }}</p>
          </div>
        </div>

        <hr *ngIf="medication['Drug.Type'] || (medication['Dosage.Unit'] && medication['Dosage.Type'])"/>
        <div
          class="row clearfix full-width flex force"
          *ngIf="medication['Drug.Type'] || (medication['Dosage.Unit'] && medication['Dosage.Type'])"
        >
          <div class="full-width" *ngIf="medication['Drug.Type']">
            <h5 class="sr-title">Medication type</h5>
            <p class="rm-mt">{{ medication['Drug.Type'] }}</p>
          </div>

          <div class="full-width" *ngIf="medication['Dosage.Type']">
            <h5 class="sr-title">Dosage type</h5>
            <p class="rm-mt">{{ medication['Dosage.Type'] }}</p>
          </div>

          <div class="full-width" *ngIf="medication['Dosage.Unit'] || medication['Dosage.Amount']">
            <h5 class="sr-title">Dosage Unit</h5>
            <p class="rm-mt">{{ medication['Dosage.Amount'] }} {{ medication['Dosage.Unit'] }}</p>
          </div>
        </div>

        <hr *ngIf="medication['Note']"/>

        <div class="row clearfix full-width flex force" *ngIf="medication['Note']">
          <div class="full-width">
            <h5 class="sr-title">Note</h5>
            <p class="rm-mt">{{ medication['Note'] }}</p>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <div class="full-width drop-buttons clearfix text-center" *ngIf="isModal == true">
    <button
      class="step-next"
      *ngIf="medication && medication.Drug_key && medication['Drug_Source.code'] == 'Promoter'"
      (click)="viewDrug(medication.Drug_key)"
      (click)="closeEvent()"
      mat-raised-button
      color="primary"
    >
      <mat-icon class="fas fa-pills"></mat-icon> View drug
    </button>

    <button class="step-next" (click)="closeEvent()" mat-raised-button color="primary">Close</button>
  </div>
</div>
