import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { MerchantLinkService } from '../shared/merchant-link.service';

import * as _ from 'lodash';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { TagCreateEditComponent } from '../../tags/tag-create-edit/tag-create-edit.component';
import { environment } from 'environments/environment';
@Component({
  selector: 'merchant-link-create-edit',
  templateUrl: './merchant-link-create-edit.component.html',
  styleUrls: ['./merchant-link-create-edit.component.css'],
})
export class MerchantLinkCreateEditComponent implements OnInit {
  @Input()
  icon = 'fas fa-link';
  @Output()
  close = new EventEmitter();
  @Output()
  getResult = new EventEmitter();
  link;
  label;
  @Output()
  getLookup = new EventEmitter();
  displayAddTemplate = true;
  displayTitle = false;
  isDefault = false;
  sessionType;
  createTemplate = false;
  displayLookup = false;

  addTag = new EventEmitter();

  downloadQRCode = new EventEmitter();

  tags = [];
  selectedTags = [];
  defaultSelectedTags = [];
  merchantLinkID;
  constructor(
    private authenticationService: AuthenticationService,
    private merchantLinkService: MerchantLinkService,
    private utilService: UtilsService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.displayTitle === true) {
        this.displayTitle = true;
      } else if (data.displayTitle === false) {
        this.displayTitle = false;
      }
      if (data.displayAddTemplate === true) {
        this.displayAddTemplate = true;
      } else if (data.displayAddTemplate === false) {
        this.displayAddTemplate = false;
      }

      if (data.displayLookup === true) {
        this.displayLookup = true;
      } else if (data.displayLookup === false) {
        this.displayLookup = false;
      }
      if (data.createTemplate === true) {
        this.createTemplate = true;
      } else if (data.createTemplate === false) {
        this.createTemplate = false;
      }
      if (data.merchantLinkID) {
        this.merchantLinkID = data.merchantLinkID;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((r) => {
      this.sessionType = r;

      this.buildTags();

      if (this.merchantLinkID) {
        this.merchantLinkService.getMerchantLink(this.merchantLinkID, {}, this.sessionType).subscribe((r) => {
          if (r) {
            if (r.Link) {
              this.link = r.Link;
            }

            if (r.Label) {
              this.label = r.Label;
            }

            if (r.Label) {
              this.label = r.Label;
            }

            if (r && r.tags && r.tags.length > 0) {
              this.defaultSelectedTags = r.tags;
              this.selectedTags = this.tags;
            }
            this.createTemplate = true;
            this.displayAddTemplate = false;
          }
        });
      }
    });
  }

  isFontAwsome(icon) {
    if (icon) {
      if (icon.indexOf('fas ') !== -1 || icon.indexOf('fab-') !== -1) {
        return true;
      }

      return false;
    }

    return false;
  }
  send() {
    if ((this.createTemplate == true || this.merchantLinkID) && this.link && this.label) {
      const p = {
        label: this.label,
        link: this.link,
        isDefault: this.isDefault,
        tags: this.selectedTags,
      };

      if (this.merchantLinkID) {
        this.merchantLinkService.modifyMerchantLink(this.merchantLinkID, p, this.sessionType).subscribe((_link) => {
          if (_link) {
            NotifyAppComponent.displayToast(
              'success',
              'Successful operation',
              'The Link has been successfully Added to your list'
            );
            this.utilService.createShortcut(this.link).subscribe((r) => {
              if (r && r.shortLink) {
                let label = this.link;
                if (this.label) {
                  label = this.label;
                }

                const result = {
                  ..._link,
                  link: this.link,
                  shortLink: r.shortLink,
                  title: this.label,
                  htmlLink: `<a title="${label}" target="_blank" href="${r.shortLink}">${r.shortLink}</a>`,
                };

                if (this.label) {
                  result.htmlLink = `<a title="${this.label}" target="_blank" href="${r.shortLink}">${this.label}</a>`;
                }

                this.getResult.emit(result);
              }
            });
          }
        });
      } else {
        this.merchantLinkService.createMerchantLink(p, this.sessionType).subscribe((_link) => {
          if (_link) {
            NotifyAppComponent.displayToast(
              'success',
              'Successful operation',
              'The Link has been successfully Added to your list'
            );
            this.utilService.createShortcut(this.link).subscribe((r) => {
              if (r && r.shortLink) {
                let label = this.link;
                if (this.label) {
                  label = this.label;
                }

                const result = {
                  ..._link,
                  link: this.link,
                  shortLink: r.shortLink,
                  title: this.label,
                  htmlLink: `<a title="${label}" target="_blank" href="${r.shortLink}">${r.shortLink}</a>`,
                };

                if (this.label) {
                  result.htmlLink = `<a title="${this.label}" target="_blank" href="${r.shortLink}">${this.label}</a>`;
                }

                this.getResult.emit(result);
              }
            });
          }
        });
      }
    } else {
      if (this.link) {
        this.utilService.createShortcut(this.link).subscribe((r) => {
          if (r && r.shortLink) {
            let label = this.link;
            if (this.label) {
              label = this.label;
            }

            const result = {
              link: this.link,
              shortLink: r.shortLink,
              title: this.label,
              htmlLink: `<a title="${label}" target="_blank" href="${r.shortLink}">${r.shortLink}</a>`,
            };

            if (this.label) {
              result.htmlLink = `<a title="${this.label}" target="_blank" href="${r.shortLink}">${this.label}</a>`;
            }

            this.getResult.emit(result);
          }
        });
      }
    }
  }

  buildTags() {
    if (this.createTemplate === true || this.displayAddTemplate === true) {
      const pTag = {
        fields: 'Label',
        isPromoter: false,
      };

      this.merchantLinkService.getTagsList(pTag, this.sessionType).subscribe((res) => {
        if (res) {
          this.tags = _.map(res, (item) => {
            if (item && item.Label) {
              return item.Label;
            }

            return null;
          });
        }
      });
    }
  }

  createNewTag() {
    const ref = RootAppComponent.dialog.open(TagCreateEditComponent, {
      data: {},

      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res && res.Label) {
        if (
          (!this.tags || (this.tags && this.tags.length <= 0)) &&
          (!this.defaultSelectedTags || (this.defaultSelectedTags && this.defaultSelectedTags.length <= 0))
        ) {
          this.defaultSelectedTags = JSON.parse(JSON.stringify([res.Label]));
          this.tags = JSON.parse(JSON.stringify([res.Label]));
        } else {
          if (!this.tags || (this.tags && this.tags.length <= 0)) {
            this.tags.push(res.Label);
          }
          this.addTag.emit(res.Label);
        }
        ref.close();
      }
    });
  }
  preview() {
    if (this.link) {
      if (this.link.indexOf('http') === -1) {
        window.open('https://' + this.link, '_blank');
      } else {
        window.open(this.link, '_blank');
      }
    }
  }
  getSelectedTags(e) {
    this.selectedTags = e || [];
  }
  closeEvent() {
    this.close.emit(true);
  }

  lookupEvent() {
    this.getLookup.emit(true);
  }

  getRedirectURL(id) {
    if (id) {
      return `${environment.nodeUrl}/merchant-link/redirect/${id}`;
    }

    return null;
  }

  download()
  {
    this.downloadQRCode.emit()
  }
}
