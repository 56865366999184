<div class="row clearfix mt ml mr" *ngIf="link">
  <mat-card class="rel clearfix">
    <div class="card-header primary-gradient-img clearfix inModal rel">
      <div class="row clearfix content">
        <div class="row titleArea clearfix">
          <app-generate-qr
            class="full-width ml mr row"

            [downloadLabel]="'Staff Portal'"
            [source]="link"
            [size]="140"
            [download]="downloadQRCodeDental"
            style="width: 175px !important; margin-bottom: -34px !important"
          >
          </app-generate-qr>

          <h2 class="summary-header rm-m">
            Staff Portal
            <span class="subLabel">
              Sharing this landing page link with your team members enables them to send finance offers to
              {{ "KEYWORD.patient" | translate | titlecase }}'s. <br/><br/>

              This single-purpose page is designed to simplify the experience of communicating finance and reduces the
              need to train on all aspects of the platform.
            </span>
          </h2>
        </div>

        <div class="full-width">
          <button
            class="share-social-media-button pull-right"
            [attr.style]="
              'border: 1px solid #fff !important; background:none !important; color:#fff !important;' | safeStyle
            "
            (click)="linkCopy(link)"
            mat-raised-button
            color="accent"
          >
            <mat-icon class="fas fa-copy smr"></mat-icon> Copy Link
          </button>

          <button
            class="share-social-media-button pull-right mr"
            [attr.style]="
              'border: 1px solid #fff !important; background:none !important; color:#fff !important;' | safeStyle
            "
            (click)="open(link)"
            mat-raised-button
            color="accent"
          >
            <mat-icon class="fas fa-external-link-square-alt smr"></mat-icon> Open Link
          </button>

          <button
            class="share-social-media-button pull-right mr"
            [attr.style]="
              'border: 1px solid #fff !important; background:none !important; color:#fff !important;' | safeStyle
            "
            (click)="downloadQRCodeDental.emit()"
            mat-raised-button
            color="accent"
          >
            Download QR Code
          </button>
        </div>
      </div>
    </div>

    <div class="full-width clearfix flex row">
      <iframe
        class="preview-iframe"
        *ngIf="iFrameSrcSecured && link"
        [src]="iFrameSrcSecured"
        (load)="onFrameLoad($event)"
      ></iframe>
    </div>
  </mat-card>
</div>
