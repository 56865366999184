
import { map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { environment } from "environments/environment";
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

import * as _ from "lodash";
@Injectable()
export class DocumentService {

  utils = new UtilsClass()
  constructor(private http: HttpClient) { }

  URLify(_string) {


    if (_string && this.utils.isContentHtml(_string) != true) {

      let string = _string;
      let urls = string.match(/(https?:\/\/[^\s]+)/g) || [];

      let localURLs = string.match(/(http?:\/\/[^\s]+)/g);

      if (localURLs && localURLs.length > 0) {
        urls = urls.concat(localURLs)
      }
      if (urls) {
        urls = _.uniq(urls)
        urls.forEach((url) => {

          let title = this.utils.getLinkTitle(url)

          if (title) {
            string = string.split(url).join(' <a target="_blank"  style="margin-right:10px !important;"   title="' + title + '"  class="inner-link" href="' + url + '"> ' + title + " </a> ");
          }
          else {
            string = string.split(url).join(' <a target="_blank"  style="margin-right:10px !important;"  class="inner-link" href="' + url + '"> ' + url + " </a> ");
          }

        });
      }

      string = string.replace(/\n/g, '<br>')
      string = string.replace(/   /g, '<br>')


      return `<div class='header-description-inner2'>
      <style>

      .header-description-inner2 p {
        font-size: 1.3rem ;
        line-height: 2.15rem ;
        font-weight: 300;
        font-family: Nunito, sans-serif ;
        color: #666 ;
        margin-top:10px;
        margin-bottom:10px;
      }
      </style>

      ` + string + "</div>";

    } else if (_string && this.utils.isContentHtml(_string) == true) {
      let string = _string;

      string = string.replace(/\n/g, '<br>')
      return `<div class='header-description-inner2'>
      <style>

      .header-description-inner2 p {
        font-size: 1.3rem ;
        line-height: 2.15rem ;
        font-weight: 300;
        font-family: Nunito, sans-serif ;
        color: #666 ;
        margin-top:10px;
        margin-bottom:10px;
      }
      </style>

      ` + string + "</div>";
    }

    return `<div class='header-description-inner2'>
    <style>

    .header-description-inner2 p {
      font-size: 1.3rem ;
      line-height: 2.15rem ;
      font-weight: 300;
      font-family: Nunito, sans-serif ;
      color: #666 ;
      margin-top:10px;
      margin-bottom:10px;
    }
    </style>

    ` + _string + "</div>";


  }

  URLifyDescription(_string) {

    if (_string && this.utils.isContentHtml(_string) != true) {
      let string = _string;
      let urls = string.match(/(https?:\/\/[^\s]+)/g) || [];

      let localURLs = string.match(/(http?:\/\/[^\s]+)/g);

      if (localURLs && localURLs.length > 0) {
        urls = urls.concat(localURLs)
      }
      if (urls) {
        urls = _.uniq(urls)
        urls.forEach((url) => {
          let title = this.utils.getLinkTitle(url)

          if (title) {

            string = string.split(url).join(' <a target="_blank" style="color:#fff !important;margin-right:10px !important;" title="' + title + '"  class="inner-link" href="' + url + '"> ' + title + " </a> ");
          }
          else {
            string = string.split(url).join(' <a target="_blank" style="color:#fff !important;margin-right:10px !important;"  class="inner-link" href="' + url + '"> ' + url + " </a> ");
          }



        });
      }

      string = string.replace(/\n/g, '<br>')
      string = string.replace(/   /g, '<br>')

      return `<div class='header-description-inner'>
      <style>
      .header-description-inner a ,
      .header-description-inner p,
      .header-description-inner h1,
      .header-description-inner h2,
      .header-description-inner h3,
      .header-description-inner h4,
      .header-description-inner h5,
      .header-description-inner h6,
      .header-description-inner span,
      .header-description-inner strong {
        color: #fff;
      }
      .header-description-inner p  {
       margin-top:10px;
       margin-bottom:10px;
      }

      .header-description-inner p , .header-description-inner a {
        line-height: 2.2rem;
        font-size: 1.6rem;
        text-align: justify;
        font-family: Nunito, sans-serif ;
      }
     .header-description-inner {
        color: #fff;
      }
      </style>

      ` + string + "</div>";
    } else if (_string && this.utils.isContentHtml(_string) == true) {
      let string = _string;

      string = string.replace(/\n/g, '<br>')
      string = string.replace(/   /g, '<br>')
      return `<div class='header-description-inner'>
      <style>
      .header-description-inner a ,
      .header-description-inner p,
      .header-description-inner h1,
      .header-description-inner h2,
      .header-description-inner h3,
      .header-description-inner h4,
      .header-description-inner h5,
      .header-description-inner h6,
      .header-description-inner span,
      .header-description-inner strong {
        color: #fff;
      }
      .header-description-inner p  {
        margin-top:10px;
        margin-bottom:10px;
       }
      .header-description-inner p , .header-description-inner a {
        line-height: 2.2rem;
        font-size: 1.6rem;
        text-align: justify;
        font-family: Nunito, sans-serif ;
      }
     .header-description-inner {
        color: #fff;
      }
      </style>


      ` + string + "</div>";
    }
    return `<div class='header-description-inner'>
    <style>
    .header-description-inner a ,
    .header-description-inner p,
    .header-description-inner h1,
    .header-description-inner h2,
    .header-description-inner h3,
    .header-description-inner h4,
    .header-description-inner h5,
    .header-description-inner h6,
    .header-description-inner span,
    .header-description-inner strong {
      color: #fff;
    }
    .header-description-inner p  {
      margin-top:10px;
      margin-bottom:10px;
     }
    .header-description-inner p , .header-description-inner a {
      line-height: 2.2rem;
      font-size: 1.6rem;
      text-align: justify;
      font-family: Nunito, sans-serif ;
    }
   .header-description-inner {
      color: #fff;
    }
    </style>

    ` + _string + "</div>";


  }


  getTagsList(body = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }
    options = {
      params: params
    };
    if (sessionType == "admin" || sessionType == "promoter") {
      return this.http
        .get(environment.nodeUrl + "/tag-merchant/global", options).pipe(
          map((res: HttpResponse<any>) => res['data']));
    }
    else if (sessionType == "merchant" || sessionType == "merchant-admin") {
      return this.http
        .get(environment.nodeUrl + "/tag-merchant", options).pipe(
          map((res: HttpResponse<any>) => res['data']));
    }
    else {
      return this.http
        .get(environment.nodeUrl + "/tag-merchant", options).pipe(
          map((res: HttpResponse<any>) => res['data']));
    }
  }

  getDocumentList(body = {}, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (var key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params: params
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + "/EPDocument/global", options).pipe(
          map((res: HttpResponse<any>) => res['data']));
    }
    else {
      return this.http
        .get(environment.nodeUrl + "/EPDocument", options).pipe(
          map((res: HttpResponse<any>) => res['data']));

    }

  }




  getDocumentDetails(id, body = {}, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (var key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params: params
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + "/EPDocument/" + id, options).pipe(
          map((res: HttpResponse<any>) => res['data']));
    }
    else {
      return this.http
        .get(environment.nodeUrl + "/EPDocument/" + id, options).pipe(
          map((res: HttpResponse<any>) => res['data']));

    }

  }


  createDocument(body = {}, isPromoterOrAdmin = false): Observable<any> {

    if (isPromoterOrAdmin == true) {
      return this.http
        .post(environment.nodeUrl + "/EPDocument/global", body).pipe(
          map((res: HttpResponse<any>) => res['data']));
    }
    else {
      return this.http
        .post(environment.nodeUrl + "/EPDocument", body).pipe(
          map((res: HttpResponse<any>) => res['data']));
    }




  }
  deleteDocumentFromLandingPage(id): Observable<any> {

    return this.http
      .delete(environment.nodeUrl + "/landing-page/document/" + id).pipe(
        map((res: HttpResponse<any>) => res['data']));



  }
  modifyDocument(id, body = {}): Observable<any> {

    return this.http
      .put(environment.nodeUrl + "/EPDocument/" + id, body).pipe(
        map((res: HttpResponse<any>) => res['data']));



  }

  deleteDocument(id): Observable<any> {


    return this.http
      .delete(environment.nodeUrl + "/EPDocument/" + id).pipe(
        map((res: HttpResponse<any>) => res['data']));



  }




  deleteDocumentGroup(ids): Observable<any> {

    return this.http
      .delete(environment.nodeUrl + "/EPDocument/group/" + ids).pipe(
        map((res: HttpResponse<any>) => res['data']));



  }

  getDocumentLinkHeaders(body = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (var key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params: params
    };
    return this.http
      .get(environment.nodeUrl + "/landing-page/document", options).pipe(
        map((res: HttpResponse<any>) => res['data']));
  }
  getDocumentTypeList(body = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (var key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params: params
    };
    return this.http
      .get(environment.nodeUrl + "/document-type"+'/cached/'+Settings.global['lookupHash'], options).pipe(
        map((res: HttpResponse<any>) => res['data']));
  }


  getMerchantList(body = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (var key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params: params
    };

    return this.http
      .get(environment.nodeUrl + "/merchant", options).pipe(
        map((res: HttpResponse<any>) => res['data']));

  }


  getSupplierList(body = {}, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (var key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params: params
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + "/beneficiary/global", options).pipe(
          map((res: HttpResponse<any>) => res['data']));

    }
    else {
      return this.http
        .get(environment.nodeUrl + "/beneficiary", options).pipe(
          map((res: HttpResponse<any>) => res['data']));

    }
  }
  documentLinkListTheme(): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/landing-page/theme', {}).pipe(
        map((res: HttpResponse<any>) => res['data']));
  }

  createDocumentLink(payload, sessionType): Observable<any> {
    if (sessionType == "admin" || sessionType == "promoter") {

      return this.http
        .post(environment.nodeUrl + '/landing-page/global', payload).pipe(
          map((res: HttpResponse<any>) => res['data']));
    }
    else {
      return this.http
        .post(environment.nodeUrl + '/landing-page', payload).pipe(
          map((res: HttpResponse<any>) => res['data']));
    }

  }

  cloneDocumentLink(id, payload, sessionType): Observable<any> {
    if (sessionType == "admin" || sessionType == "promoter") {

      return this.http
        .post(environment.nodeUrl + '/landing-page/clone/global/' + id, payload).pipe(
          map((res: HttpResponse<any>) => res['data']));
    }
    else {
      return this.http
        .post(environment.nodeUrl + '/landing-page/clone/' + id, payload).pipe(
          map((res: HttpResponse<any>) => res['data']));
    }

  }
  modifyDocumentLink(id, payload, sessionType): Observable<any> {
    if (sessionType == "admin" || sessionType == "promoter") {

      return this.http
        .put(environment.nodeUrl + '/landing-page/global/' + id, payload).pipe(
          map((res: HttpResponse<any>) => res['data']));
    }
    else {
      return this.http
        .put(environment.nodeUrl + '/landing-page/' + id, payload).pipe(
          map((res: HttpResponse<any>) => res['data']));
    }

  }
  getDocumentLink(ID, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params: params
    }
    if (sessionType == "admin" || sessionType == "promoter") {
      return this.http
        .get(environment.nodeUrl + '/landing-page/global/' + ID, options).pipe(
          map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == "merchant" || sessionType == "merchant-admin") {
      return this.http
        .get(environment.nodeUrl + '/landing-page/' + ID, options).pipe(
          map((res: HttpResponse<any>) => res['data']));
    }
    else {
      return this.http
        .get(environment.nodeUrl + '/landing-page/public/' + ID, options).pipe(
          map((res: HttpResponse<any>) => res['data']));
    }

  }


  getDocumentLinkList(payload = null, sessionType) {
    let url = "landing-page";
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = "landing-page/global";
    }
    let params: HttpParams = new HttpParams();
    let options = {};
    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = {
        params: params
      };
    }
    return this.http
      .get(`${environment.nodeUrl}/${url}`, options).pipe(
        map((res: HttpResponse<any>) => {
          return res['data']
        }));
  }

  deleteDocumentLink(id, sessionType) {
    let url = "landing-page";
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = "landing-page/global";
    }
    return this.http
      .delete(`${environment.nodeUrl}/${url}/${id}`, {}).pipe(
        map((res: HttpResponse<any>) => {
          return res['data']
        }));
  }


  modifyDocumentLinkGroup(payload, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .put(`${environment.nodeUrl}/landing-page/group/global`, payload).pipe(
          map((res: Response) => res['data']));
    }
    else {
      return this.http
        .put(`${environment.nodeUrl}/landing-page/group`, payload).pipe(
          map((res: Response) => res['data']));
    }
  }

  deleteDocumentLinkGroup(ids, sessionType): Observable<any> {
    let _ids;
    if (Array.isArray(ids) == true) {
      _ids = ids.join(',')
    }
    else if (typeof ids == 'string') {
      _ids = ids;
    }
    if (_ids) {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.http
          .delete(environment.nodeUrl + '/landing-page/group/global/' + _ids).pipe(
            map((res: HttpResponse<any>) => res['data']));
      }
      else {
        return this.http
          .delete(environment.nodeUrl + '/landing-page/group/' + _ids).pipe(
            map((res: HttpResponse<any>) => res['data']));
      }
    }
  }


  getLinkByVisitorType(id, visitorType) {



    let link = `${Settings.global['publicSiteFrontendLink']}/landing-page-overview/${id}`;



    if (visitorType) {
      if (
        visitorType === 'f' ||
        visitorType === 'facebook' ||
        visitorType === 'l' ||
        visitorType === 'linkedin' ||
        visitorType === 'i' ||
        visitorType === 'instagram' ||
        visitorType === 't' ||
        visitorType === 'twitter' ||
        visitorType === 'p' ||
        visitorType === 'pinterest'

      ) {
        link = `${environment.nodeUrl}/landing-page/redirect/${id}/${visitorType}`;
      }
      else {
        link = `${Settings.global['publicSiteFrontendLink']}/landing-page-overview/${id}/${visitorType}`;
      }
    }

    return link

  }

  removeHTML(text) {

    return this.http
      .post(`${environment.nodeUrl}/landing-page/text-only`, { text: text }).pipe(
        map((res: HttpResponse<any>) => {
          return res['data']
        }));
  }


  getLandingPageLink(ID, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params: params
    }
    if (sessionType == "admin" || sessionType == "promoter") {
      return this.http
        .get(environment.nodeUrl + '/landing-page-link/global/' + ID, options).pipe(
          map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/landing-page/' + ID, options).pipe(
          map((res: HttpResponse<any>) => res['data']));
    }


  }
  modifyLandingPageLink(id, payload, sessionType): Observable<any> {
    if (sessionType == "admin" || sessionType == "promoter") {

      return this.http
        .put(environment.nodeUrl + '/landing-page-link/global/' + id, payload).pipe(
          map((res: HttpResponse<any>) => res['data']));
    }
    else {
      return this.http
        .put(environment.nodeUrl + '/landing-page-link/' + id, payload).pipe(
          map((res: HttpResponse<any>) => res['data']));
    }

  }

  createLandingPageLink(payload, sessionType): Observable<any> {
    if (sessionType == "admin" || sessionType == "promoter") {

      return this.http
        .post(environment.nodeUrl + '/landing-page-link/global', payload).pipe(
          map((res: HttpResponse<any>) => res['data']));
    }
    else {
      return this.http
        .post(environment.nodeUrl + '/landing-page-link', payload).pipe(
          map((res: HttpResponse<any>) => res['data']));
    }

  }



  getLandingPagePDFBlob(id, payload, sessionType): Observable<any> {


    if(payload.lastModified)
    {
      let params: HttpParams = new HttpParams();
      let options = {};

      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params: params
      }

      if (sessionType == "admin" || sessionType == "promoter") {

        return this.http
          .get(environment.nodeUrl + '/landing-page/pdf-blob/global/'+id+'/'+payload.lastModified, options).pipe(
            map((res: HttpResponse<any>) => res['data']));
      }
      else {
        return this.http
          .get(environment.nodeUrl + '/landing-page/pdf-blob/'+id+'/'+payload.lastModified, options).pipe(
            map((res: HttpResponse<any>) => res['data']));
      }
    }
    else {
      if (sessionType == "admin" || sessionType == "promoter") {

        return this.http
          .post(environment.nodeUrl + '/landing-page/pdf-blob/global/'+id, payload).pipe(
            map((res: HttpResponse<any>) => res['data']));
      }
      else {
        return this.http
          .post(environment.nodeUrl + '/landing-page/pdf-blob/'+id, payload).pipe(
            map((res: HttpResponse<any>) => res['data']));
      }
    }




  }
  getDocumentTypeByCode(code, payload = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(`${environment.nodeUrl}/document-type/code/${code}`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

}
