import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TestimonyService {
  constructor(private http: HttpClient) {}

  createTestimony(testimony: any): Observable<any> {
    return this.http
      .post(environment.nodeUrl + '/testimony/user', testimony)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getTestimonyList(): Observable<any> {
    return this.http.get(environment.nodeUrl + '/testimony/user').pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getTestimonyListGolbal(): Observable<any> {
    return this.http.get(environment.nodeUrl + '/testimony/global').pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getOneTestimony(id): Observable<any> {
    return this.http.get(environment.nodeUrl + '/testimony/' + id).pipe(map((res: HttpResponse<any>) => res['data']));
  }

  updateTestimony(id, payload): Observable<any> {
    return this.http
      .put(environment.nodeUrl + '/testimony/' + id + '/user', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  deleteTestimony(id): Observable<any> {
    return this.http
      .delete(environment.nodeUrl + '/testimony/' + id + '/global')
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  activeTestimony(id, payload): Observable<any> {
    return this.http
      .put(environment.nodeUrl + '/testimony/' + id + '/global', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }
}
