<div class="rel">
  <div class="pull-right">
    <!-- Modal dismiss button -->
    <button class="btn-close btn-clear mat-button" matDialogClose>
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <h3 class="sr-title rm-mt">Tell us what needs changing</h3>
  <hr/>

  <p class="small">Please enter a short description of what needs changing on your {{ "PHRASE.treatment plan" |
    translate }} .</p>
  <div class="clearfix">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Enter description</mat-label>
      <textarea [(ngModel)]="feedback" matInput placeholder="Enter description"></textarea>
    </mat-form-field>
  </div>

  <br/>

  <div class="drop-buttons">
    <button class="btn-large" (click)="changeInvitation()" mat-raised-button color="accent">
      Submit Changes
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>
