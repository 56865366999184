<mat-card class="rel" *ngIf="appointment" @ngIfAnimation style="margin-bottom: 10px">
  <div class="card-header primary-gradient-img clearfix stacked">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="clearfix">
        <h2 class="sm-h2 pull-left rm-mt rm-mb">
          Appointment for {{ appointment['CustomerOrProspect_CalculatedName'] }}
        </h2>
        <p class="clearfix small rm-mt rm-mb mt">
          {{ appointmentUtil.getStatusDescription(appointment, false) }}
        </p>

        <p class="clearfix small rm-mt rm-mb mt" *ngIf="appointment['Active'] != '1'">
          <span class="chip-disable">Inactive</span>
        </p>
      </div>
    </div>
    <div class="action-button">
      <button class="white" class="actionMenuToggle" [matMenuTriggerFor]="btnPatMenu" mat-mini-fab color="accent">
        <mat-icon class="white">more_vert</mat-icon>
      </button>

      <mat-menu #btnPatMenu="matMenu">
        <button
          *ngIf="
            appointment['Invitation_key'] &&
            (isPromoterOrAdmin || sessionType === 'merchant' || sessionType === 'merchant-admin')
          "
          (click)="openInvitationViewDialog()"
          mat-menu-item
        >
          <mat-icon>email</mat-icon>
          <span>View {{ "KEYWORD.treatment" | translate | titlecase }}</span>
        </button>

        <button *ngIf="appointment['Merchant_key']" (click)="openMerchantViewDialog()" mat-menu-item>
          <mat-icon>person</mat-icon>
          <span>View Merchant</span>
        </button>

        <button *ngIf="appointment['merchantContact_key']" (click)="openDentistViewDialog()" mat-menu-item>
          <mat-icon>account_box</mat-icon>
          <span>View {{ "KEYWORD.practitioner" | translate | titlecase }}</span>
        </button>

        <button
          *ngIf="
            appointment['CustomerOrProspect_Key'] &&
            (isPromoterOrAdmin || sessionType === 'merchant' || sessionType === 'merchant-admin')
          "
          (click)="gotoCustomerPage(appointment['CustomerOrProspect_Key'])"
          mat-menu-item
        >
          <mat-icon>person</mat-icon>
          <span>View Customer</span>
        </button>

        <button
          *ngIf="
            appointment['Campaign_key'] &&
            (isPromoterOrAdmin || sessionType === 'merchant' || sessionType === 'merchant-admin')
          "
          (click)="gotoCampaignPage(appointment['Campaign_key'])"
          mat-menu-item
        >
          <mat-icon>insert_chart</mat-icon>
          <span>View Campaign</span>
        </button>

        <mat-divider></mat-divider>

        <button *ngIf="isPromoterOrAdmin" (click)="publicPage(appointment['ID'])" mat-menu-item>
          <mat-icon>link</mat-icon>
          <span>Public page</span>
        </button>

        <button
          *ngIf="isPromoterOrAdmin || sessionType === 'merchant' || sessionType === 'merchant-admin'"
          (click)="removeAppointment(appointment)"
          mat-menu-item
        >
          <mat-icon>remove_circle</mat-icon>
          <span>Remove Appointment</span>
        </button>

        <button
          *ngIf="sessionType == 'customer' && appointment['Status.Code'] === 'REQUESTED'"
          (click)="removeAppointment(appointment)"
          mat-menu-item
        >
          <mat-icon>remove_circle</mat-icon>
          <span>Remove Appointment</span>
        </button>
      </mat-menu>
    </div>
  </div>

  <mat-dialog-content class="stacked-card-view">
    <p class="small">
      Check the appointment progress and dates, click on <span class="fas fa-envelope comment"></span> to view messages
      from you and your {{"KEYWORD.patient" | translate}}.
    </p>
    <div class="row clearfix flex funnel full-width">
      <div class="step step-1">
        <div class="row clearfix text-center">
          <mat-icon class="fas fa-calendar-alt"></mat-icon>
          <h5 class="sr-title rm-m">Request</h5>

          <hr />
        </div>
        <mat-list class="list-container">
          <mat-list-item *ngIf="appointment['DateTimeCreated'] != '0000-00-00'">
            <p matLine>
              <span class="title"
              >Requested:

                <span
                  class="fas fa-envelope comment"
                  *ngIf="appointment['Comment']"
                  (click)="showComment('Appointment request Comment from the ' + this.patientKeyword)"
                  [matTooltip]="'Appointment request Comment from the ' + ('KEYWORD.patient' | translate)"
                >
                </span>
              </span>
              <span class="value">
                {{ appointment['DateTimeCreated'] | customDate: 'hh:mm a' }},
                {{ appointment['DateTimeCreated'] | customDate }}</span
              >
            </p>
          </mat-list-item>

          <mat-list-item *ngIf="appointment['Date_Request_Date_Change'] != '0000-00-00'">
            <p matLine>
              <span class="title"
              >Request date change:

                <span
                  class="fas fa-envelope comment"
                  *ngIf="appointment['Request_Date_Change_Comment']"
                  (click)="
                    showComment(
                      'Appointment date change Comment from the patient',
                      appointment['Request_Date_Change_Comment']
                    )
                  "
                  [matTooltip]="'Appointment date change Comment from the ' + ('KEYWORD.patient' | translate)"
                >
                </span>
              </span>
              <span class="value"> {{ appointment['Date_Request_Date_Change'] | customDate }}</span>
            </p>
          </mat-list-item>

          <mat-list-item class="date-view-bottom" *ngIf="appointment['DateTimeCreated']">
            <p
              class="small"
              [style.border-color]="util.daysPastColor(appointment['DateTimeCreated'])"
              [style.color]="util.daysPastColor(appointment['DateTimeCreated'])"
              matLine
            >
              Requested
              {{ appointment['DateTimeCreated'] | TimeLabelPast }} ago
              <span
                class="backgroundColor"
                [style.background]="util.daysPastColor(appointment['DateTimeCreated'])"
              ></span>
            </p>
          </mat-list-item>
        </mat-list>
      </div>

      <!-- information about patient card interaction -->
      <div
        class="step step-2"
        [class.disabled]="!appointment['Date_Booked'] || appointment['Date_Booked'] == '0000-00-00'"
      >
        <div class="row clearfix text-center">
          <mat-icon class="fas fa-clock"></mat-icon>
          <h5 class="sr-title rm-m">Booking</h5>
          <hr />
        </div>

        <p
          class="nothingToDisplay text-center"
          *ngIf="!appointment['Date_Booked'] || appointment['Date_Booked'] == '0000-00-00'"
        >
          No activity to display
        </p>

        <mat-list
          class="list-container"
          [hidden]="!appointment['Date_Booked'] || appointment['Date_Booked'] == '0000-00-00'"
        >
          <mat-list-item *ngIf="appointment['Date_Booked'] != '0000-00-00'">
            <p matLine>
              <span class="title"
              >Propose Booking:

                <span
                  class="fas fa-envelope comment"
                  *ngIf="appointment['Merchant_Comment']"
                  (click)="showComment('Booking Comment from the ' + this.practiceKeyword)"
                  [matTooltip]="'Booking Comment from the ' + ('KEYWORD.practice' | translate)"
                >
                </span>
              </span>
              <span class="value"> {{ appointment['Date_Booked'] | customDate }}</span>
            </p>
          </mat-list-item>

          <mat-list-item *ngIf="appointment['Date_Confirmation'] != '0000-00-00'">
            <p matLine>
              <span class="title">Confirmed:</span>
              <span class="value">{{ appointment['Date_Confirmation'] | customDate }}</span>
            </p>
          </mat-list-item>

          <mat-list-item
            *ngIf="appointment['Date_Merchant_Confirmed'] && appointment['Date_Merchant_Confirmed'] != '0000-00-00'"
          >
            <p matLine>
              <span class="title"
              >Confirmed by {{"KEYWORD.practice" | translate}}:

                <span
                  class="fas fa-envelope comment"
                  *ngIf="appointment['Confirmation_Merchant_Comment']"
                  (click)="showComment('Confirmation message from the ' + this.PracticeKeyword, '')"
                  matTooltip="Confirmation message from the {{ 'KEYWORD.practice' | translate | titlecase}}"
                >
                </span>
              </span>
              <span class="value"> {{ appointment['Date_Merchant_Confirmed'] | customDate }}</span>
            </p>
          </mat-list-item>

          <mat-list-item *ngIf="appointment['Date_Request_Reschedule'] != '0000-00-00'">
            <p matLine>
              <span class="title"
              >Request rechedule:

                <span
                  class="fas fa-envelope comment"
                  *ngIf="appointment['Request_Reschedule_Comment']"
                  (click)="
                    showComment(
                      'Requesting rescheduling Comment from the ' + this.patientKeyword,
                      appointment['Request_Reschedule_Comment']
                    )
                  "
                  [matTooltip]="'Requesting rescheduling Comment from the ' + ('KEYWORD.patient' | translate)"
                >
                </span>
              </span>
              <span class="value"> {{ appointment['Date_Request_Reschedule'] | customDate }}</span>
            </p>
          </mat-list-item>
          <mat-list-item *ngIf="appointment['Date_Rescheduled'] != '0000-00-00'">
            <p matLine>
              <span class="title"
              >Rechedule:

                <span
                  class="fas fa-envelope comment"
                  *ngIf="appointment['Rescheduled_Comment']"
                  (click)="showComment('Rescheduling Comment from the ' + this.practiceKeyword)"
                  [matTooltip]="'Rescheduling Comment from the ' + ('KEYWORD.practice' | translate)"
                >
                </span>
              </span>
              <span class="value"> {{ appointment['Date_Rescheduled'] | customDate }}</span>
            </p>
          </mat-list-item>

          <mat-list-item class="date-view-bottom" *ngIf="appointment['Date_Confirmation'] !== '0000-00-00'">
            <p
              class="small"
              [style.border-color]="util.daysPastColor(appointment['Date_Confirmation'])"
              [style.color]="util.daysPastColor(appointment['Date_Confirmation'])"
              matLine
            >
              Booked
              {{ appointment['Date_Confirmation'] | TimeLabelPast }}
              ago
              <span
                class="backgroundColor"
                [style.background]="util.daysPastColor(appointment['Date_Confirmation'])"
              ></span>
            </p>
          </mat-list-item>

          <mat-list-item
            class="date-view-bottom"
            *ngIf="appointment['Date_Merchant_Confirmed'] && appointment['Date_Merchant_Confirmed'] !== '0000-00-00'"
          >
            <p
              class="small"
              [style.border-color]="util.daysPastColor(appointment['Date_Merchant_Confirmed'])"
              [style.color]="util.daysPastColor(appointment['Date_Merchant_Confirmed'])"
              matLine
            >
              Booked
              {{ appointment['Date_Merchant_Confirmed'] | TimeLabelPast }}
              ago
              <span
                class="backgroundColor"
                [style.background]="util.daysPastColor(appointment['Date_Merchant_Confirmed'])"
              ></span>
            </p>
          </mat-list-item>

          <mat-list-item
            class="date-view-bottom"
            *ngIf="
              appointment['Date_Booked'] !== '0000-00-00' &&
              appointment['Date_Confirmation'] == '0000-00-00' &&
              (!appointment['Date_Merchant_Confirmed'] || appointment['Date_Merchant_Confirmed'] == '0000-00-00')
            "
          >
            <p
              class="small"
              [style.border-color]="util.daysPastColor(appointment['Date_Booked'])"
              [style.color]="util.daysPastColor(appointment['Date_Booked'])"
              matLine
            >
              Suggested
              {{ appointment['Date_Booked'] | TimeLabelPast }} ago
              <span class="backgroundColor" [style.background]="util.daysPastColor(appointment['Date_Booked'])"></span>
            </p>
          </mat-list-item>
        </mat-list>
      </div>


      <div
        class="step step-3"
        [class.disabled]="
          (!appointment['Date_Missed'] || appointment['Date_Missed'] == '0000-00-00') &&
          (!appointment['Date_Attended'] || appointment['Date_Attended'] == '0000-00-00') &&
          (!appointment['Date_Canceled'] || appointment['Date_Canceled'] == '0000-00-00')
        "
      >
        <div class="row clearfix text-center">
          <mat-icon class="fas fa-user-clock"></mat-icon>
          <h5 class="sr-title rm-m">Attendance</h5>
          <hr />
        </div>

        <p
          class="nothingToDisplay text-center"
          *ngIf="
            (!appointment['Date_Missed'] || appointment['Date_Missed'] == '0000-00-00') &&
            (!appointment['Date_Attended'] || appointment['Date_Attended'] == '0000-00-00') &&
            (!appointment['Date_Canceled'] || appointment['Date_Canceled'] == '0000-00-00')
          "
        >
          No activity to display
        </p>

        <mat-list
          class="list-container"
          [hidden]="
            (!appointment['Date_Missed'] || appointment['Date_Missed'] == '0000-00-00') &&
            (!appointment['Date_Attended'] || appointment['Date_Attended'] == '0000-00-00') &&
            (!appointment['Date_Canceled'] || appointment['Date_Canceled'] == '0000-00-00')
          "
        >
          <mat-list-item *ngIf="appointment['Date_Attended'] != '0000-00-00'">
            <p matLine>
              <span class="title">Attended:</span>
              <span class="value">{{ appointment['Date_Attended'] | customDate }}</span>
            </p>
          </mat-list-item>
          <mat-list-item *ngIf="appointment['Date_Missed'] != '0000-00-00'">
            <p matLine>
              <span class="title"
              >Missed:
                <span
                  class="fas fa-envelope comment"
                  *ngIf="appointment['Missed_Comment']"
                  (click)="showComment('Missing  Comment from the ' + this.practiceKeyword)"
                  [matTooltip]="'Missing  Comment from the ' + ('KEYWORD.practice' | translate)"
                >
                </span>
              </span>
              <span class="value"> {{ appointment['Date_Missed'] | customDate }}</span>
            </p>
          </mat-list-item>

          <mat-list-item *ngIf="appointment['Date_Canceled'] != '0000-00-00'">
            <p matLine>
              <span class="title"
              >Canceled by {{"KEYWORD.patient" | translate}}:

                <span
                  class="fas fa-envelope comment"
                  *ngIf="appointment['Canceled_Comment']"
                  (click)="showComment('Cancelling  Comment', appointment['Canceled_Comment'])"
                  matTooltip="Cancelling  Comment "
                >
                </span>
              </span>
              <span class="value"> {{ appointment['Date_Canceled'] | customDate }}</span>
            </p>
          </mat-list-item>

          <mat-list-item *ngIf="appointment['Date_Merchant_Canceled'] != '0000-00-00'">
            <p matLine>
              <span class="title"
              >Canceled by {{"KEYWORD.practice" | translate}}:

                <span
                  class="fas fa-envelope comment"
                  *ngIf="appointment['Canceled_Merchant_Comment']"
                  (click)="showComment('Cancelling  Comment', appointment['Canceled_Merchant_Comment'])"
                  matTooltip="Cancelling  Comment "
                >
                </span>
              </span>
              <span class="value"> {{ appointment['Date_Merchant_Canceled'] | customDate }}</span>
            </p>
          </mat-list-item>

          <mat-list-item class="date-view-bottom">
            <p
              class="small"
              [style.border-color]="util.daysPastColor(appointment['LastModified_Human'])"
              [style.color]="util.daysPastColor(appointment['LastModified_Human'])"
              matLine
            >
              Updated
              {{ appointment['LastModified_Human'] | TimeLabelPast }}
              ago
              <span
                class="backgroundColor"
                [style.background]="util.daysPastColor(appointment['LastModified_Human'])"
              ></span>
            </p>
          </mat-list-item>
        </mat-list>
      </div>
    </div>

    <div class="full-width flex">
      <h4 class="primary-color subtitle rm-mb full-width">
        {{ "KEYWORD.patient" | translate | titlecase }} Details

        <button class="pull-right" (click)="gotoCustomerPage()" mat-stroked-button color="primary">
          <mat-icon>visibility</mat-icon>
          {{ "KEYWORD.patient" | translate | titlecase }} profile
        </button>
      </h4>
    </div>

    <div class="full-width flex clearfix">
      <div class="clearfix customer-picture text-center">
        <app-customer-prospect-view-picture
          class="supplierIconAppointmentc pull-left mr"
          [hideZoomButton]="false"
          [patientID]="appointment['CustomerOrProspect_Key']"
        >
        </app-customer-prospect-view-picture>
      </div>
      <div class="clearfix flex">
        <div class="two-thrd-width">
          <div
            class="full-width flex appointment-details-line"
            *ngIf="appointment['CustomerOrProspect_CalculatedName']"
          >
            <p class="full-width patient-details">
              <mat-icon class="fas fa-user appointment-details-line-icon mr" color="accent"></mat-icon>
              {{ appointment['CustomerOrProspect_CalculatedName'] }}
            </p>
          </div>

          <div class="full-width flex appointment-details-line" *ngIf="appointment['CustomerOrProspect_Email']">
            <p class="full-width patient-details">
              <mat-icon class="fas fa-at appointment-details-line-icon mr" color="accent"></mat-icon>
              {{ appointment.CustomerOrProspect_Email }}
            </p>
          </div>
        </div>
        <div class="full-width">
          <div class="full-width flex appointment-details-line" *ngIf="appointment['CustomerOrProspect_Mobile']">
            <p class="full-width patient-details">
              <mat-icon class="fas fa-phone appointment-details-line-icon mr" color="accent"></mat-icon>
              {{ appointment.CustomerOrProspect_Mobile | customPhone }}
            </p>
          </div>
          <div class="full-width flex appointment-details-line" *ngIf="appointment['BestAppointment.Label']">
            <p class="full-width patient-details">
              <mat-icon class="fas fa-clock appointment-details-line-icon mr" color="accent"></mat-icon>
              <strong class="mr">Preferred appointment time</strong> {{ appointment['BestAppointment.Label'] }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="full-width flex">
      <h4 class="primary-color subtitle rm-mb full-width">
        Appointment Details

        <button
          class="pull-right"
          *ngIf="appointment['Invitation_key']"
          (click)="openInvitationViewDialog()"
          mat-stroked-button
          color="primary"
        >
          <mat-icon>visibility</mat-icon> Invitation
        </button>

        <button
          class="pull-right"
          *ngIf="appointment['Campaign_Key']"
          (click)="gotoCampaignPage()"
          mat-stroked-button
          color="primary"
        >
          <mat-icon>visibility</mat-icon> Campaign
        </button>
      </h4>
    </div>
    <div class="full-width flex row">
      <app-appointment-card class="full-width ml mr merchant-app-card" [appointment]="appointment" [isMerchant]="true">
      </app-appointment-card>
    </div>

    <div class="full-width flex mt">
      <app-appointment-description [appointment]="appointment" [isMerchant]="true"></app-appointment-description>
    </div>
  </mat-dialog-content>

  <div class="drop-buttons">
    <button
      class="ml mr"
      *ngFor="let a of appointmentUtil.getOperations(appointment, sessionType)"
      [style.background]="a['color']"
      (click)="runActionEvent(appointment, a.code, false)"
      mat-raised-button
      color="accent"
    >
      <mat-icon>{{ a.icon }}</mat-icon>
      <span>{{ a.label }}</span>
    </button>
  </div>
</mat-card>

<app-appointment-action-hidden [runAction]="runAction" (result)="getActionResult($event)">
</app-appointment-action-hidden>
