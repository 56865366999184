import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'sr-customer-profile-summary-dialog',
  templateUrl: './customer-profile-summary-dialog.component.html',
  styleUrls: ['./customer-profile-summary-dialog.component.css'],
})
export class CustomerProfileSummaryDialogComponent implements OnInit {
  @Output() closed: EventEmitter<void> = new EventEmitter();
  @Output() navigatedToFullProfile: EventEmitter<void> = new EventEmitter();

  user = null;

  patient = null;

  sessionType = 'merchant';

  birthday;

  constructor(@Inject(MAT_DIALOG_DATA) private data: { patient: any }, private router: Router) {}

  ngOnInit(): void {
    if (this.data) {
      this.patient = this.data.patient;
    }
  }

  getBirthday(e) {
    if (e) {
      this.birthday = e;
    }
  }

  dial(phone) {
    window.location.href = 'tel:' + phone;
  }

  email(email) {
    window.location.href = 'mailto:' + email;
  }

  goToFullProfile() {
    this.router
      .navigate([
        '/merchant',
        {
          outlets: {
            page: ['customer-profile', this.patient['ID'], 'patient'],
          },
        },
      ])
      .then(() => {
        this.navigatedToFullProfile.emit();
      });
  }

  close() {
    this.closed.emit();
  }
}
