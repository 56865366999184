import { Component, Input } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppStateService } from '../../../core/app-state/app-state.service';

@Component({
  selector: 'ipv-dynamic-product-invoice-list',
  templateUrl: './dynamic-product-invoice-list.component.html',
  styleUrls: ['./dynamic-product-invoice-list.component.css'],
})
export class DynamicProductInvoiceListComponent {
  @Input() pageSize = 20;

  industryType$ = this.appStateService.getAppState$().pipe(map((appState) => appState.publicSettings.industryType));

  constructor(private appStateService: AppStateService) {}
}
