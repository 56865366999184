import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { MapViewComponent } from '../../../shared/components/map-view/map-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { ActionLogListComponent } from '../../action-log/action-log-list/action-log-list.component';
import { MerchantAccessCreateComponent } from '../merchant-access-create/merchant-access-create.component';
import { MerchantService } from '../shared/merchant.service';

@Component({
  selector: 'app-merchant-view-modal',
  templateUrl: './merchant-view-modal.component.html',
  styleUrls: ['./merchant-view-modal.component.css'],
  providers: [CustomPhonePipe],
  animations: [
    trigger('onChangeAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([style({ opacity: 0, offset: 0, height: 0 }), style({ opacity: 1, offset: 1.0, height: '*' })])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MerchantViewModalComponent implements OnInit {
  @Input() merchantID;
  merchant;
  displayMapFlag = true;

  @Input()
  invitationID;

  @Output()
  close = new EventEmitter();

  profileLink;
  subjectArray = [];
  currentUser;
  isPromoterOrAdmin = false;
  isModal = false;

  sessionType = 'guest';

  constructor(
    private merchantService: MerchantService,
    private authenticationService: AuthenticationService,
    private customPhone: CustomPhonePipe,
    private activeRoute: ActivatedRoute,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.merchantID = data;
      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      this.activeRoute.params.subscribe((params) => {
        if (params['consumerID']) {
          this.invitationID = params['consumerID'];
        }

        this.authenticationService.getCurrentUser().subscribe((res) => {
          if (res) {
            this.currentUser = res.data;
          }
        });

        this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
          this.isPromoterOrAdmin = res;
        });

        if (this.merchantID) {
          this.merchantService.getMerchantDetails(this.merchantID, {}, this.sessionType).subscribe((res1) => {
            this.merchant = res1;

            this.profileLink = null;
            this.profileLink = this.merchantService.getPracticePicStreamLink(this.merchantID);
          });
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.merchantService.getMerchantDetails(this.merchantID, {}, this.sessionType).subscribe((res1) => {
      this.merchant = res1;
      this.profileLink = null;
      this.profileLink = this.merchantService.getPracticePicStreamLink(this.merchantID);
    });
  }

  getMoreInfo() {
    let phone = '';

    if (this.merchant['phones.Number']) {
      phone = this.customPhone.transform(String(this.merchant['phones.Number']), 'landLine');
    }

    const result =
      "<p><strong>Merchant's name: </strong>" +
      this.merchant['CalculatedName'] +
      '</p><p><strong>Phone: </strong>' +
      String(phone) +
      "</p><p><strong>Email: </strong> <a class='emailLink primary-color' href='mailto:" +
      String(this.merchant['emails.Email']) +
      "' title='Email Merchant' target='_blank'>" +
      this.merchant['emails.Email'] +
      '</a></p>';

    return result;
  }

  dialPractice(phone) {
    window.location.href = 'tel:' + phone;
  }

  emailPractice(email) {
    window.location.href = 'mailto:' + email + "?subject=I've got a question about my upcoming treatment";
  }

  openSocialLink(link) {
    // check if link has http or https and add it if not
    let url = '';
    if (!/^http[s]?:\/\//.test(link)) {
      url += 'https://';
    }
    url += link;
    window.open(url, '_blank', 'noopener');
  }

  notFoundEvent(e) {
    if (e == null) {
      this.displayMapFlag = false;
    } else {
      this.displayMapFlag = !e;
    }
  }

  displayMap() {
    const location = {
      zoom: 17,
      postCode: this.merchant['addresses.Postcode'],
      state: this.merchant['addresses.State'],
      streetType: this.merchant['addresses.Street Type'],
      streetName: this.merchant['addresses.Street Name'],
      streetNumber: this.merchant['addresses.Street Nr'],
      suburb: this.merchant['addresses.Suburb'],
      country: this.merchant['addresses.Country.Code'],
      addressLabel: this.merchant['addresses.Calculated'],
      latitude: this.merchant['Address.Latitude'],
      longitude: this.merchant['Address.Longitude'],
      moreInfo: this.getMoreInfo(),
    };

    const ref = RootAppComponent.dialog.open(MapViewComponent, {
      data: location,
      panelClass: 'modal-map',
      width: '800px',
      height: '600px',
    });

    ref.componentInstance.addCoordinate.subscribe((res) => {
      if (res && res.latitude && res.longitude) {
        const payload = {
          latitude: res.latitude,
          longitude: res.longitude,
        };
        this.merchant.addCoordinate(this.merchantID, payload).subscribe((res) => {});
      }
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  contact() {
    const data = {
      invitationID: this.invitationID,
      targetType: 'merchant',
      targetID: this.merchant.ID,
      asGuest: false,
      asProfile: true,
      asPractice: true,
      subjectArray: this.subjectArray,
    };
    AuthenticationService.contactInputEvent.emit(data);
  }

  closeEvent() {
    this.close.emit(true);
  }

  merchantAccess(ID) {
    const ref = RootAppComponent.dialog.open(MerchantAccessCreateComponent, {
      data: ID,
      width: '800px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  viewMerchantLogs(ID) {
    const ref = RootAppComponent.dialog.open(ActionLogListComponent, {
      data: {
        merchantID: ID,
        target: 'merchant',
        targetID: ID,
      },
      width: '900px',
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }
}
