<div class="rel dash intro">
  <mat-dialog-content class="beta-dash" id="sub-plan-dialog-content" [ngClass]="{ 'no-modal-height': isModal != true }">
    <div class="full-width beta-container">
      <div class="full-width beta-container-with-image"></div>
      <div class="full-width main-container">
        <div class="button1"></div>
        <div class="button2"></div>
        <div class="button3"></div>
        <div class="button4"></div>

        <div class="notch">
          <div class="faceid"></div>
          <div class="speaker"></div>
          <div class="camera"></div>
        </div>
        <span class="time">{{ timeNow }} </span>
        <span class="all-icons">
          <mat-icon class="fas fa-battery-three-quarters pull-right"></mat-icon>
          <mat-icon class="fas fa-wifi pull-right"></mat-icon>
          <mat-icon class="fas fa-signal pull-right"></mat-icon>
        </span>
        <div class="full-width header text-center">
          <mat-icon class="fas fa-angle-left primary-color left-side" (click)="closeEvent()" matTooltip="Close">
          </mat-icon>
          <mat-icon class="fas fa-video primary-color right-side"></mat-icon>

          <app-card-picture class="side-card-picture-preview" *ngIf="card" [hideZoomButton]="false"
            [tableName]="tableName" [LastModified]="card['LastModified']" [cardID]="cardID">
          </app-card-picture>

          <p class="card-name full-width" *ngIf="card">{{ card['FirstName'] }}</p>
        </div>
        <div class="full-width sms-body">
          <div class="full-width date-divider mb">
            <p class="text-center small rm-mb">
              <span>Message</span>
            </p>
            <p class="text-center small rm-m">
              <span>Today {{ timeNow }}</span>
            </p>
          </div>

          <div class="full-width">
            <div class="message-body flex sml smr">
              <div class="full-width">
                <div class="full-width message-body-main-sms primary-bg"
                  [innerHTML]="URLify(message || '...') | safeHtml"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="full-width footer">
          <div class="full-width flex">
            <mat-icon class="fas fa-camera mt nav pull-left sml smr"></mat-icon>
            <mat-icon class="fas fa-grin mt nav pull-left sml smr"></mat-icon>
            <mat-form-field class="full-width mr search-input sml" appearance="outline">
              <input [(ngModel)]="messageFilter" matInput name="Filter" placeholder="Your message..." />
              <mat-icon class="fas fa-microphone sms_list-search_icon" matSuffix></mat-icon>
            </mat-form-field>
          </div>
        </div>

        <button class="btn close-big-bottom" *ngIf="isModal==true" (click)="closeEvent()" mat-raised-button color="accent">Close</button>
      </div>
    </div>
  </mat-dialog-content>
</div>
