import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UtilsService } from '../../services/utils.service';

import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
@Component({
  selector: 'app-user-access-view',
  templateUrl: './user-access-view.component.html',
  styleUrls: ['./user-access-view.component.css'],
})
export class UserAccessViewComponent implements OnInit {
  @Input()
  cardID;

  access = [];
  filterName;

  isPromoterOrAdmin;
  accessList = [];

  isActiveFilter = '*';

  constructor(private utilService: UtilsService, private AuthenticationService: AuthenticationService) {}

  ngOnInit() {
    this.setup();
  }

  setup() {
    this.AuthenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      this.utilService.getAccessList().subscribe((accessList) => {
        if (accessList) {
          this.accessList = accessList;

          if (this.isPromoterOrAdmin == true && this.cardID) {
            this.utilService.getGlobalAccess(this.cardID).subscribe((res) => {
              if (res) {
                this.access = res;
              }
            });
          } else {
            this.utilService.getCurrentAccess().subscribe((res) => {
              if (res) {
                this.access = res;
              }
            });
          }
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cardID && changes.cardID.previousValue && !changes.cardID.currentValue) {
      this.setup();
    }
  }
}
