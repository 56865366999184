<ipv-title-modal [title]="title" [hideAllDefaultButtons]="true" [icon]="null" [classIcon]="null">
  <div style="padding-top: 10px" content>
    <ng-container [ngSwitch]="industryType">
      <ipv-retail-product-invoice-form
        *ngSwitchCase="'retail'"
        [formMode]="formMode"
        [existingProductInvoice]="productInvoice"
      ></ipv-retail-product-invoice-form>
      <ipv-retail-product-invoice-form
        *ngSwitchCase="'breeze'"
        [formMode]="formMode"
        [existingProductInvoice]="productInvoice"
      ></ipv-retail-product-invoice-form>
      <ipv-dental-product-invoice-form *ngSwitchCase="'dental'"></ipv-dental-product-invoice-form>
    </ng-container>
  </div>
</ipv-title-modal>
