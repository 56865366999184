import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PCICarComponent } from '../car/car.component';
import { PCICreditCardComponent } from '../credit-card/credit-card.component';
import { PCIEmploymentBenefitComponent } from '../employment-benefit/employment-benefit.component';
import { PCIFurnitureHouseholdComponent } from '../furniture-household/furniture-household.component';
import { PCIHouseResidenceComponent } from '../house-residence/house-residence.component';
import { PCIInterestIncomeComponent } from '../interest-income/interest-income.component';
import { PCIInvestmentLoansComponent } from '../investment-loans/investment-loans.component';
import { PCIInvestmentPropertyComponent } from '../investment-property/investment-property.component';
import { PCILivingExpenseComponent } from '../living-expense/living-expense.component';
import { PCIOtherAssetsComponent } from '../other-assets/other-assets.component';
import { PCIOtherIncomeComponent } from '../other-income/other-income.component';
import { PCIOtherVehicleComponent } from '../other-vehicle/other-vehicle.component';
import { PCIDocumentUploadComponent } from '../personal-circumstance-document-upload/personal-circumstance-document-upload.component';
import { PCIDocumentComponent } from '../personal-circumstance-document/personal-circumstance-document.component';
import { PCIPersonalLoanComponent } from '../personal-loan/personal-loan.component';
import { PCISharesComponent } from '../shares/shares.component';
import { PCITaxDebtComponent } from '../tax-debt/tax-debt.component';

const routes: Routes = [
  {
    path: 'pci-employment-benefit',
    component: PCIEmploymentBenefitComponent,
  },
  {
    path: 'pci-car',
    component: PCICarComponent,
  },
  {
    path: 'pci-credit-card',
    component: PCICreditCardComponent,
  },
  {
    path: 'pci-furniture-household',
    component: PCIFurnitureHouseholdComponent,
  },
  {
    path: 'pci-house-residence',
    component: PCIHouseResidenceComponent,
  },
  {
    path: 'pci-interest-income',
    component: PCIInterestIncomeComponent,
  },
  {
    path: 'pci-investment-loans',
    component: PCIInvestmentLoansComponent,
  },
  {
    path: 'pci-investment-property',
    component: PCIInvestmentPropertyComponent,
  },
  {
    path: 'pci-living-expense',
    component: PCILivingExpenseComponent,
  },
  {
    path: 'pci-other-assets',
    component: PCIOtherAssetsComponent,
  },
  {
    path: 'pci-other-income',
    component: PCIOtherIncomeComponent,
  },
  {
    path: 'pci-other-vehicle',
    component: PCIOtherVehicleComponent,
  },
  {
    path: 'pci-personal-loan',
    component: PCIPersonalLoanComponent,
  },
  {
    path: 'pci-shares',
    component: PCISharesComponent,
  },
  {
    path: 'pci-tax-debt',
    component: PCITaxDebtComponent,
  },
  {
    path: 'pci-document',
    component: PCIDocumentComponent,
  },
  {
    path: 'pci-upload-document',
    component: PCIDocumentUploadComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PersonalCircumstancesRoutingModule {}
