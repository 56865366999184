import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';

@Injectable()
export class InvitationTemplateService {
  constructor(private http: HttpClient) {}

  getInvitationTemplateList(body = {}, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (let key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/invitation-template/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/invitation-template', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  getProductGroup(): Observable<any> {
    return this.http.get(environment.nodeUrl + '/product-group', {}).pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getThirdPartyDetails(id): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/invitation-template/third-party/' + id, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getInvitationTemplateDetails(id, body = {}, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (let key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/invitation-template/global/' + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/invitation-template/' + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  createInvitationTemplate(body = {}, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.http
        .post(environment.nodeUrl + '/invitation-template/global', body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/invitation-template', body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  clearInvitationTemplate(body = {}, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.http
        .post(environment.nodeUrl + '/invitation-template/clear/global', body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/invitation-template/clear', body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  mergeInvitationTemplate(body = {}, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.http
        .post(environment.nodeUrl + '/invitation-template/merge/global', body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/invitation-template/merge', body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  modifyInvitationTemplate(id, body = {}, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.http
        .put(environment.nodeUrl + '/invitation-template/global/' + id, body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/invitation-template/' + id, body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  deleteInvitationTemplate(id, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.http
        .delete(environment.nodeUrl + '/invitation-template/global/' + id)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .delete(environment.nodeUrl + '/invitation-template/' + id)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  cloneInvitationTemplate(id, payload = {}, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.http
        .post(environment.nodeUrl + '/invitation-template/clone/global/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/invitation-template/clone/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  modifyInvitationTemplateGroup(body = {}, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.http
        .put(environment.nodeUrl + '/invitation-template/group/global', body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/invitation-template/group', body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  deleteInvitationTemplateGroup(ids, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.http
        .delete(environment.nodeUrl + '/invitation-template/group/global/' + ids)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .delete(environment.nodeUrl + '/invitation-template/group/' + ids)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  calculateDiscount(payload, sessionType: string): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .post(environment.nodeUrl + `/invitation/discount/global`, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + `/invitation/discount`, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
}
