<div class="card-header primary-gradient-img clearfix inModal" *ngIf="isModal == true">
  <div class="row clearfix">
    <!-- Modal dismiss button -->
    <button class="btn-close btn-clear mat-button white" (click)="closeEvent()" matDialogClose>
      <mat-icon>clear</mat-icon>
    </button>
    <!-- merchant calculated name -->
    <div class="row titleArea clearfix">
      <!-- <h2 class="summary-header rm-m">
        <span class="subLabel"><span style="font-weight: 600"></span></span>
      </h2> -->
    </div>
    <!-- Picture and Name -->
    <div class="display-flex">
      <app-dentist-view-picture class="align-self-center mr" [dentistID]="dentistID"></app-dentist-view-picture>

      <h2 class="align-self-center" *ngIf="dentist?.CalculatedName">
        {{ dentist?.CalculatedName }}
      </h2>

      <button class="pull-right" *ngIf="isEdit" (click)="onEdit()" mat-icon-button color="accent">
        <mat-icon class="fas fa-edit"></mat-icon>
      </button>
    </div>
  </div>
</div>

<!-- <div class="pull-right">
    <button class="btn-close btn-clear  pull-right mat-button">
      <mat-icon>clear</mat-icon>
    </button>
  </div> -->

<mat-dialog-content
  [ngClass]="{
    'no-modal-height': isModal != true
  }"
>
  <div class="pContainer">
    <mat-card
      *ngIf="dentist"
      [ngClass]="{
        'can-accept-1': dentist['Can_Accept_Appointment'] === '1' && displayHighlight,
        'can-accept-0': dentist['Can_Accept_Appointment'] === '0' && displayHighlight
      }"
    >
      <!-- Picture and Name -->
      <div class="display-flex" *ngIf="isModal === false">
        <app-dentist-view-picture class="align-self-center mr" [dentistID]="dentistID"></app-dentist-view-picture>

        <h4 class="sr-title sm-h4" *ngIf="dentist?.CalculatedName">{{ dentist?.CalculatedName }}</h4>
        <div style="flex-grow: 1"></div>
        <button class="pull-right" *ngIf="isModal === false" (click)="onEdit()" mat-icon-button color="accent">
          <mat-icon class="fas fa-edit"></mat-icon>
        </button>
      </div>
      <!-- Edit Controls -->
      <div class="display-flex mt" *ngIf="dentist && isEdit">
        <div class="mr align-self-center">
          <mat-slide-toggle [checked]="dentist['Can_Accept_Appointment'] == '1'" (change)="onToggleCanAccept($event)">
            Accept Appointment
          </mat-slide-toggle>
        </div>
        <div style="flex-grow: 1"></div>
        <input
          class="color-input-background"
          [(colorPicker)]="dentist['Appointment_Color']"
          [style.background]="dentist['Appointment_Color'] || '#0092eb'"
          [cpOutputFormat]="'hex'"
          (colorPickerClose)="onColorPickerClose($event)"
          name="contactColor"
        />
      </div>

      <div class="mt" *ngIf="workingHours && workingHours.length > 0 && displayBusinessHours">
        <h2 class="sr-title m-h2 rm-mt">Business Hours</h2>
        <p class="large">
          {{ "KEYWORD.practitioner" | translate | titlecase }} Timezone:
          <span style="font-weight: 600">{{ merchantTimezone }}</span>
        </p>
      </div>

      <mat-list class="mt" *ngIf="workingHours?.length > 0">
        <mat-list-item class="flex flexList busHours" *ngFor="let t of workingHours">
          <span class="small" *ngIf="t['NotOpen'] == '0'"
          ><span class="dayLabel">{{ t['DayOfWeek.Label.Singular'] }}</span>
            <span class="tradingHour"
            >{{ util.timeConvert(t['OpeningTime'].slice(0, 5)) }} -
              {{ util.timeConvert(t['ClosingTime'].slice(0, 5)) }}</span
            ></span
          >
          <span class="small" *ngIf="t['NotOpen'] == '1'"
          ><span class="dayLabel">{{ t['DayOfWeek.Label.Singular'] }}</span>
            <span class="tradingHour">Close</span></span
          >
        </mat-list-item>
      </mat-list>
    </mat-card>
  </div>
</mat-dialog-content>
