<div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="listDB.data.length == 0 || showDocList == true || showBrandList == true || showProductList == true">
  <div class="row clearfix">
    <h2 class="dataHeader rm-mt">
      <img
        src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/brandLogos/ADA-Fed-Logo.png"/>

      {{ title }}
      <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
    </h2>
  </div>

  <div class="row clearfix flex ctl mb" @ngIfAnimation>
    <div class="full-width flex">
      <button class="" *ngIf="isPromoterOrAdmin == true" (click)="add()" mat-raised-button color="primary">
        <mat-icon>add</mat-icon>
        Add Item Code
      </button>

      <button (click)="goToGuide()" mat-raised-button color="accent">
        Download Guide <mat-icon class="fas fa-file-download"></mat-icon>
      </button>
      <button (click)="goToShop()" mat-raised-button color="primary">
        ADA Login <mat-icon class="fas fa-shopping-basket"></mat-icon>
      </button>
    </div>

    <mat-button-toggle-group class="btn-group rm-m half-width" *ngIf="isPromoterOrAdmin == true"
                             [(ngModel)]="isActiveFilter" (change)="setFilter($event.value, 'Active')">
      <mat-button-toggle class="full-width text-center" [value]="null">
        All
      </mat-button-toggle>
      <mat-button-toggle class="full-width text-center" [value]="'1'">
        Active
      </mat-button-toggle>
      <mat-button-toggle class="full-width text-center" [value]="'0'">
        Not Active
      </mat-button-toggle>
    </mat-button-toggle-group>

    <button class="row select-btn" *ngIf="isPromoterOrAdmin == true" (click)="selectAll()" mat-stroked-button color=""
            matTooltip="Select All">
      <mat-icon>border_all</mat-icon>
    </button>

    <button class="row select-btn" *ngIf="isPromoterOrAdmin == true" (click)="selectPage()" mat-stroked-button color=""
            matTooltip="Select Page">
      <mat-icon>border_outer</mat-icon>
    </button>

    <button class="animated" *ngIf="selectedIDs && selectedIDs.length > 0" [matMenuTriggerFor]="ActionBtnmenu"
            mat-stroked-button matTooltip="Options">
      <mat-icon class="fas fa-cog"></mat-icon>  <span *ngIf="selectedIDs && selectedIDs.length>0"
        class="selected-number-item  accent-background">{{selectedIDs.length}}</span>
    </button>
    <mat-menu #ActionBtnmenu="matMenu">
      <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
        <mat-icon>select_all</mat-icon> Deselect
      </button>

      <button class="row select-btn" (click)="updateGroup()" mat-menu-item color="">
        <mat-icon>edit</mat-icon>
        Edit Group
      </button>

      <button class="row select-btn" (click)="disableGroup()" mat-menu-item color="">
        <mat-icon>cancel</mat-icon>
        Disable
      </button>

      <button class="row select-btn" (click)="enableGroup()" mat-menu-item color="">
        <mat-icon>check_circle</mat-icon>
        Enable
      </button>

      <button class="row select-btn" (click)="addDocumentGroup()" mat-menu-item color="">
        <mat-icon>add_circle</mat-icon>
        Add document
      </button>

      <button class="row select-btn" (click)="importDocumentGroup()" mat-menu-item color="">
        <mat-icon>import_export</mat-icon>
        Import document
      </button>
    </mat-menu>
  </div>

  <!-- <div class="heading-controls pull-right">

    </div>


    <div class="pull-right selection-items" @ngIfAnimation>
        <button mat-raised-button color="accent" (click)="goToGuide()">
          Download Guide <mat-icon class="fas fa-file-download"></mat-icon>
        </button>
        <button mat-raised-button color="primary" (click)="goToShop()">
          ADA Shop <mat-icon class="fas fa-shopping-basket"></mat-icon>
        </button>
      </div> -->

  <!-- <div class="pull-right mr" *ngIf="isPromoterOrAdmin==true">
      <mat-button-toggle-group class="btn-group mb rm-mt flex text-center full-width" [(ngModel)]="isActiveFilter"
        (change)="setFilter($event.value, 'Active')">
        <mat-button-toggle class="full-width text-center" [value]="null">
          {{"All" | translate}}
        </mat-button-toggle>
        <mat-button-toggle class="full-width text-center" [value]="'1'">
          {{"Active" | translate}}
        </mat-button-toggle>
        <mat-button-toggle class="full-width text-center" [value]="'0'">
          {{"Not Active" | translate}}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div> -->

  <!--
    <div class="pull-right selection-items" *ngIf="isPromoterOrAdmin==true" @ngIfAnimation>




      <button mat-raised-button color="" class="row select-btn" (click)="selectAll()">
        <mat-icon>border_all</mat-icon> {{"Select All" | translate }}
      </button>




      <button mat-raised-button color="" class="row select-btn" (click)="selectPage()">
        <mat-icon>border_outer</mat-icon> {{"Select Page" | translate }}
      </button>


      <button mat-raised-button *ngIf="selectedIDs && selectedIDs.length>0" color="" class="row select-btn"
        (click)="unselect()">
        <mat-icon>select_all</mat-icon> Deselect
      </button>

      <button mat-raised-button *ngIf="selectedIDs && selectedIDs.length>0" color="" class="row select-btn"
        (click)="disableGroup()">
        <mat-icon>cancel</mat-icon> {{"Disable" | translate }}
      </button>

      <button mat-raised-button *ngIf="selectedIDs && selectedIDs.length>0" color="" class="row select-btn"
        (click)="enableGroup()">
        <mat-icon>check_circle</mat-icon> {{"Enable" | translate}}
      </button>

      <button mat-raised-button *ngIf="selectedIDs && selectedIDs.length>0" color="" class="row select-btn"
        (click)="addDocumentGroup()">
        <mat-icon>add_circle</mat-icon> {{"Add document" | translate}}
      </button>


      <button mat-raised-button *ngIf="selectedIDs && selectedIDs.length>0" color="" class="row select-btn"
        (click)="importDocumentGroup()">
        <mat-icon>import_export</mat-icon> {{"Import document" | translate}}
      </button>


    </div> -->

  <div class="row clearfix">
    <mat-card class="list-card">
      <div class="row clearfix mb searchArea primary-gradient-img">
        <div class="row clearfix flex">
          <mat-icon class="mt">search</mat-icon>

          <mat-form-field class="full-width ml" appearance="outline">
            <mat-label>Label,Item Code</mat-label>
            <input class="clearfix" (input)="setFilter($event.target.value, 'fullLabel')" name="Labels" placeholder=""
                   matInput/>
          </mat-form-field>
        </div>
      </div>

      <!-- Table -->
      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <ng-container cdkColumnDef="selectCheckBox" sticky>
          <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div class="margin-btm pull-left">
              <mat-checkbox [checked]="isSelected(row['ID'])" (change)="select(row['ID'], $event.checked)"
                            color="accent">
              </mat-checkbox>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="ProfileImage" sticky>
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Logo</mat-header-cell>
          <mat-cell class="text-center" *cdkCellDef="let row" (click)="viewType(row['ID'])">
            <app-type-view-logo class="supplierIcon2 text-center pull-left" *ngIf="row" [hideZoomButton]="true"
                                [typeID]="row['ID']" [LastModified]="row['LastModified']">
            </app-type-view-logo>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Label" sticky>
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Label</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewType(row['ID'])" matTooltip="{{ row['Label'] }}">
            {{ treatmentUtil.getLabel(row['Label']) }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Category">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Service Category</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewType(row['ID'])" matTooltip="{{ row['Category.Label'] }}">
            <strong [ngStyle]="{ color: row['Category.Colour'] }">{{ row['Category.Label'] }} </strong>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="list">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> </mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <button (click)="viewBrands(row['ID'], row['Label'])" mat-icon-button matTooltip="View Brand List">
              <mat-icon>list</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Brands">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Brands</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <button class="btn-small" (click)="viewBrands(row['ID'], row['Label'])" matTooltip="View Type Brands"
                    mat-button color="warning">
              {{ row['NumberOfBrands'] || '...' }}
            </button>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Products">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Products</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <button class="btn-small" (click)="viewProducts(row['ID'], row['Label'])" matTooltip="View Type Products"
                    mat-button color="warning">
              {{ row['NumberOfProducts'] || '...' }}
            </button>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Documents">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Documents</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <button class="btn-small" (click)="viewDocuments(row['ID'], row['Label'])" matTooltip=" View Type Documents"
                    mat-button color="warning">
              {{ row['NumberOfDocuments'] || '...' }}
            </button>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="itemCode">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Item Code</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewType(row['ID'])"
                    matTooltip="{{ row['Dafault_Item_Code'] | customCurrency }}">
            {{ row['Dafault_Item_Code'] || '...' }}</mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="IsActive">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Active</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewType(row['ID'])">
            <span class="list-label smaller" *ngIf="row['Active'] == '1'" matTooltip="Active"
                  style="background-color: rgb(90, 186, 71)">
              <mat-icon>check</mat-icon>
            </span>
            <span class="list-label smaller" *ngIf="row['Active'] != '1'" matTooltip="Inactive"
                  style="background-color: rgb(239, 71, 39)">
              <mat-icon>close</mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button (click)="viewType(row['ID'])" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span>View Type</span>
                </button>

                <button *ngIf="row['Category_key']" (click)="viewCategory(row['Category_key'])" mat-menu-item>
                  <mat-icon class="fas fa-th"></mat-icon>
                  <span>View Category</span>
                </button>

                <button *ngIf="row['ID'] && isPromoterOrAdmin == true" (click)="edit(row['ID'])" mat-menu-item>
                  <mat-icon>edit</mat-icon>
                  <span>Edit Type</span>
                </button>

                <button *ngIf="row['ID'] && isPromoterOrAdmin == true"
                        (click)="addDocument(row['ID'], row['Category_key'])" mat-menu-item>
                  <mat-icon>add_circle</mat-icon>
                  <span>Add Document</span>
                </button>

                <button *ngIf="row['ID'] && isPromoterOrAdmin == true" (click)="importDocument(row['ID'])"
                        mat-menu-item>
                  <mat-icon>import_export</mat-icon>
                  <span>Import Document</span>
                </button>

                <button *ngIf="row['ID']" (click)="viewDocuments(row['ID'], row['Label'])" mat-menu-item>
                  <mat-icon>book</mat-icon>
                  <span>{{ 'View Documents' | translate }}</span>
                </button>

                <button *ngIf="row['ID']" (click)="viewBrands(row['ID'], row['Label'])" mat-menu-item>
                  <mat-icon class="fas fa-ticket-alt"></mat-icon>
                  <span>View Brands</span>
                </button>

                <button *ngIf="row['ID']" (click)="viewProducts(row['ID'], row['Label'])" mat-menu-item>
                  <mat-icon class="fas fa-box-open"></mat-icon>
                  <span>View Products</span>
                </button>

                <button *ngIf="row['Active'] == '1' && isPromoterOrAdmin == true" (click)="disable(row['ID'])"
                        mat-menu-item>
                  <mat-icon>cancel</mat-icon>
                  <span>Disable</span>
                </button>

                <button *ngIf="row['Active'] == '0' && isPromoterOrAdmin == true" (click)="enable(row['ID'])"
                        mat-menu-item>
                  <mat-icon>check_circle</mat-icon>
                  <span>Enable</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="fieldColor">
          <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
          <mat-cell *cdkCellDef="let row" [ngStyle]="{ background: row['Category.Colour'] }"></mat-cell>
        </ng-container>

        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row class="listRow separatedRow" *cdkRowDef="let row; columns: displayedColumns"
                 [ngStyle]="{ 'border-top': '2px solid ' + row['Category.Colour'] }"></mat-row>
      </mat-table>
      <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="pageSize"
                     [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>
    </mat-card>
  </div>
</div>
<div *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <app-empty-list-message></app-empty-list-message>
</div>

<div class="showDocumentList" *ngIf="showDocList == true">
  <div class="row clearfix">
    <app-treatment-document-gallery-container class="clearfix" [typeID]="selectedTypeID" [title]="docListTitle">
    </app-treatment-document-gallery-container>
  </div>
  <div class="row clearfix text-center mt">
    <button (click)="hideViewDetails()" mat-raised-button color="accent">
      <mat-icon>chevron_left</mat-icon> Item Code List
    </button>
  </div>
</div>

<div class="showDocumentList" *ngIf="showBrandList == true">
  <div class="row clearfix">
    <app-brand-list class="clearfix" [typeID]="selectedTypeID" [title]="brandListTitle"></app-brand-list>
  </div>
  <div class="row clearfix text-center mt">
    <button (click)="hideViewDetails()" mat-raised-button color="accent">
      <mat-icon>chevron_left</mat-icon> Item Code List
    </button>
  </div>
</div>

<div class="showDocumentList" *ngIf="showProductList == true">
  <div class="row clearfix">
    <app-treatment-product-list class="clearfix" [typeID]="selectedTypeID" [title]="productListTitle">
    </app-treatment-product-list>
  </div>
  <div class="row clearfix text-center mt">
    <button (click)="hideViewDetails()" mat-raised-button color="accent">
      <mat-icon>chevron_left</mat-icon> Item Code List
    </button>
  </div>
</div>
