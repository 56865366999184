import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { switchMap, take } from 'rxjs/operators';
import { AppStateService } from '../../../../../../../../../../../../core/app-state/app-state.service';
import { User } from '../../../../../../../../../../../../core/authentication/shared/shared/types/authentication.type';
import { CustomDatePipe } from '../../../../../../../../../../../../shared/pipes/custom-date.pipe';
import { NotifyAppComponent } from '../../../../../../../../../../../../shared/types/notify-app-component';
import { Dentist } from '../../../../../../../../../../../dentist/shared/dentist.type';
import { CustomerAndProspect } from '../../../../../../../../../../../invitation/shared/types/invitation.type';
import { Merchant } from '../../../../../../../../../../../merchant/shared/types/merchant.type';
import { NotesService } from '../../../../../../../../../../shared/services/notes.service';
import { Note } from '../../../../../../../../../../shared/type/notes.type';

export class NoteTemplateFormModalConfig {
  formMode?: 'create' | 'update';
  label?: string;
  noteID?: string;
  note?: Note;
  noteText?: string;
  patient: Partial<CustomerAndProspect> | User;
  practice: Partial<Merchant>;
  dentist: Dentist;
  disableApply?: boolean;
}

@Component({
  selector: 'ipv-note-template-form-modal',
  templateUrl: './note-template-form-modal.component.html',
  styleUrls: ['./note-template-form-modal.component.css'],
  providers: [CustomDatePipe],
})
export class NoteTemplateFormModalComponent implements OnInit {
  @Output() apply: EventEmitter<string> = new EventEmitter<any>();
  @Output() recordCreated: EventEmitter<Note> = new EventEmitter<Note>();
  @Output() recordUpdated: EventEmitter<Note> = new EventEmitter<Note>();

  @Input() formMode: 'create' | 'update' = 'create';
  @Input() label: string;
  @Input() noteText: string;
  @Input() noteID: string;
  @Input() note: Note;
  @Input() patient: Partial<CustomerAndProspect> | User;
  @Input() practice: Partial<Merchant>;
  @Input() dentist: Dentist;
  @Input() disableApply: boolean;

  user: User;

  validateForm: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: NoteTemplateFormModalConfig,
    private notesService: NotesService,
    private appStateService: AppStateService,
    private dialogRef: MatDialogRef<NoteTemplateFormModalComponent>,
    private customDatePipe: CustomDatePipe
  ) {}

  ngOnInit() {
    this.disableApply = false;
    this.validateForm = false;

    if (this.data) {
      this.noteText = this.data.noteText;
      this.patient = this.data.patient;
      this.dentist = this.data.dentist;
      this.practice = this.data.practice;

      if (this.data.disableApply) {
        this.disableApply = this.data.disableApply;
      }

      if (this.data.formMode) {
        this.formMode = this.data.formMode;
      }

      if (this.formMode === 'update') {
        this.label = this.data.label;
        this.noteID = this.data.noteID;
        this.note = this.data.note;
        this.validateForm = true;
      }
    }

    this.appStateService
      .getAppState$()
      .pipe(take(1))
      .subscribe((appState) => {
        this.user = appState.user.data;
      });
  }

  setNoteValue(text: string) {
    this.noteText = text;
    this.checkFormValidity();
  }

  save() {
    const newNote: Note = {
      Action: 'Work Performed',
      Template_Label: this.label,
      Is_Template: '1',
      View_By_Funder: '0',
      View_By_Merchant: '0',
      View_By_Patient: '0',
      View_By_Supplier: '0',
      View_By_EP: '0',
      View_By_Promoter: '0',
      View_By_Private: '1',
      Followup_Comments: '',
      'Dates.Recontact': this.customDatePipe.transform(new Date()),
      Description: this.noteText,
      Operator_key: this.user.operatorID,
      RecontactDone: '1',
      Result: 'Completed',
      'Start.Time': '00:00:00',
      'Stop.Time': '00:00:00',
      Subject: 'Administration ',
      'Who.key': this.patient.ID,
    };

    this.notesService
      .createNote$<Note>(newNote)
      .pipe(
        switchMap((newTemplateWithID) => {
          return this.notesService.addNoteTemplate$(newTemplateWithID);
        })
      )
      .subscribe((newNoteWithID) => {
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Successfully created note template');

        this.dialogRef.close();
      });
  }

  update() {
    const updatedNote: Partial<Note> = {
      View_By_Funder: this.note.View_By_Funder,
      View_By_Merchant: this.note.View_By_Merchant,
      View_By_Patient: this.note.View_By_Patient,
      View_By_Supplier: this.note.View_By_Supplier,
      View_By_EP: this.note.View_By_EP,
      View_By_Promoter: this.note.View_By_Promoter,
      View_By_Private: this.note.View_By_Private,
      Followup_Comments: this.note.Followup_Comments,
      Description: this.noteText,
      Template_Label: this.label,
      RecontactDone: this.note.RecontactDone,
    };

    this.notesService
      .updateNote$(this.note, updatedNote)
      .pipe(
        switchMap((note) => {
          return this.notesService.editNoteTemplate$(note);
        })
      )
      .subscribe((updatedNoteWithID) => {
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Successfully updated template');
        this.dialogRef.close();
      });
  }

  saveAndApply() {
    const newNote: Note = {
      Action: 'Work Performed',
      Template_Label: this.label,
      Is_Template: '1',
      View_By_Funder: '0',
      View_By_Merchant: '0',
      View_By_Patient: '0',
      View_By_Supplier: '0',
      View_By_EP: '0',
      View_By_Promoter: '0',
      View_By_Private: '0',
      Followup_Comments: '',
      'Dates.Recontact': this.customDatePipe.transform(new Date()),
      Description: this.noteText,
      Operator_key: this.user.operatorID,
      RecontactDone: '1',
      Result: 'Completed',
      'Start.Time': '00:00:00',
      'Stop.Time': '00:00:00',
      Subject: 'Administration ',
      'Who.key': this.patient.ID,
    };

    this.notesService
      .createNote$<Note>(newNote)
      .pipe(
        switchMap((newTemplateWithID) => {
          return this.notesService.addNoteTemplate$(newTemplateWithID);
        })
      )
      .subscribe((newTemplateWithID) => {
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Successfully created note template');
        this.apply.emit(this.noteText);
        this.dialogRef.close();
      });
  }

  checkFormValidity() {
    if (this.label && this.noteText) {
      this.validateForm = true;
    }
  }

  close() {
    this.dialogRef.close();
  }
}
