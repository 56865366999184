import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { HelpPageCreateComponent } from '../../../helper/help-page-create/help-page-create.component';

@Component({
  selector: 'admin-help-documents-center',
  templateUrl: './admin-help-documents-center.component.html',
  styleUrls: ['./admin-help-documents-center.component.css'],
})
export class AdminHelpDocumentsCenterComponent implements OnInit {
  page: Subject<any> = new Subject();
  constructor(private dialog: MatDialog) {}
  ngOnInit() {}

  addSimplePage() {
    const ref = this.dialog.open(HelpPageCreateComponent);
    // ref.componentInstance.added.subscribe(res => {
    //   if (res.added) {
    //     ref.close();
    //     this.page.next(res);
    //   }
    // });
  }
}
