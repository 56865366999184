import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MedicalHistoryService } from '../shared/medical-history.service';

import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { IllnessCreateEditComponent } from '../illness-create-edit/illness-create-edit.component';

@Component({
  selector: 'app-illness-list',
  templateUrl: './illness-list.component.html',
  styleUrls: ['./illness-list.component.css'],
})
export class IllnessListComponent implements OnInit {
  @Input()
  printMode = false;

  // @Input()
  // medicalHistoryID = "262DC7B73DEEFD45A7A03CE37DF2D9D1";

  @Input()
  medicalHistoryID;

  @Input()
  illnessListInput;

  @Input()
  disableModify = false;

  @Input()
  isCreate = true;

  @Output()
  isEmptyList = new EventEmitter();

  @Output()
  getNumberOfIllness = new EventEmitter();
  @Input()
  illnessList;
  archivedIllnessList;
  archivedIllnessCodeList;
  sessionType = 'merchant';

  isLoaded = false;
  medicationExist = false;

  constructor(
    private authenticationService: AuthenticationService,
    private medicalHistoryService: MedicalHistoryService
  ) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      if (!this.illnessList || (this.illnessList && this.illnessList.length <= 0)) {
        this.getIllnessList();
      } else {
        this.isLoaded = true;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes.medicalHistoryID &&
      changes.medicalHistoryID.previousValue != changes.medicalHistoryID.currentValue
    ) {
      this.authenticationService.getSessionType().subscribe((sessionType) => {
        this.sessionType = sessionType;

        this.getIllnessList();
      });
    }
  }

  getIllnessList() {
    if (this.medicalHistoryID && typeof this.medicalHistoryID == 'string') {
      const payload = {
        medicalHistoryID: this.medicalHistoryID,
        orderBy: 'Type.Code',
        selectMedication: true,
      };

      this.medicalHistoryService.getIllnessList(payload, this.sessionType).subscribe((res) => {
        if (res) {
          this.illnessList = res;
        }
        this.sendResult();
        this.isLoaded = true;
      });
    } else if (this.illnessListInput && Array.isArray(this.illnessListInput) && this.illnessListInput.length > 0) {
      this.illnessList = this.illnessListInput;
      this.isLoaded = true;
      this.sendResult();
    } else {
      this.isLoaded = true;
    }
  }

  sendResult() {
    if (this.illnessList && this.illnessList.length > 0) {
      this.getNumberOfIllness.emit(this.illnessList.length);
      this.isEmptyList.emit(false);
    } else {
      this.isEmptyList.emit(true);
    }
  }

  modifyIllness(item) {
    if (item) {
      const ref = RootAppComponent.dialog.open(IllnessCreateEditComponent, {
        data: {
          illnessID: item['ID'],
          illness: {
            Label: item['Type.Label'],
            Code: item['Type.Code'],
          },
        },
        width: '650px',
      });

      ref.componentInstance.getCreated.subscribe((res) => {
        if (res && res.ID) {
          this.illnessList.push(res);
          ref.close();
        }
      });
      ref.componentInstance.getEdited.subscribe((res) => {
        if (res && res.ID) {
          if (this.illnessList && this.illnessList.length > 0) {
            const index = this.illnessList.findIndex((item) => {
              if (item && item.ID && res.ID == item.ID) {
                return true;
              }
              return false;
            });

            if (index != -1) {
              this.illnessList[index] = res;
            } else {
              this.illnessList.push(res);
            }
          } else {
            this.illnessList.push(res);
          }
          ref.close();
          this.sendResult();
        }
      });
      ref.componentInstance.closeEvent.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  createNew() {
    const ref = RootAppComponent.dialog.open(IllnessCreateEditComponent, {
      data: {
        medicalHistoryID: this.medicalHistoryID,
      },
      width: '650px',
    });
    ref.componentInstance.getCreated.subscribe((res) => {
      if (res && res.ID) {
        this.illnessList.push(res);
        ref.close();
      }
      this.sendResult();
    });
    ref.componentInstance.closeEvent.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  formatDate(date) {
    return moment(date).format('MMMM, YYYY');
  }

  getMedicationExist(e) {
    this.medicationExist = e;
  }

  stringToBoolean(s) {
    if (s == '1') {
      return true;
    }

    return false;
  }
}
