<div class="watch-container flex">
  <section class="timer-counter-label mr">
    <div *ngIf="counter" [ngClass]="{ blink: !running }">
      <span>{{ minutes }}:</span><span>{{ seconds }}:</span> <span>{{ milliseconds }}</span>
    </div>
    <div *ngIf="!counter">
      <span>{{ minutes }}:</span><span>{{ seconds }}:</span> <span>{{ milliseconds }}</span>
    </div>
  </section>
  <div class="timer-button-container" *ngIf="showTimerControls">
    <button class="btn-small btn-timer mr" (click)="startTimer()" mat-raised-button color="accent">
      <mat-icon class="fas fa-stopwatch"></mat-icon>
      {{ startText }}
    </button>
    <!-- <button [disabled]='!counter' [ngClass]="{'disabled': !counter}" class="timer-button" (click)="lapTimeSplit()">Lap</button> -->
    <button class="btn-small" (click)="clearTimer()" mat-raised-button color="primary">Clear</button>
    <!-- <br>
		<div *ngFor="let lap of laps;let i = index">
			<div> &#9873; <b>Lap {{i+1}} ::</b>  {{lap}}</div>
			<br>
		</div> -->
  </div>
</div>
