import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ipv-no-merchant-list-message',
  templateUrl: './no-merchant-list-message.component.html',
  styleUrls: ['./no-merchant-list-message.component.css'],
})
export class NoMerchantListMessageComponent implements OnInit {
  @Input() dataName = 'Data';

  constructor() {}

  ngOnInit() {}
}
