/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./merchant-auth-code-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i7 from "@angular/material/icon";
import * as i8 from "@angular/common";
import * as i9 from "@ngx-translate/core";
import * as i10 from "@angular/material/dialog";
import * as i11 from "./merchant-auth-code-modal.component";
import * as i12 from "ngx-clipboard";
import * as i13 from "../shared/merchant.service";
import * as i14 from "@angular/router";
import * as i15 from "../../../core/authentication/shared/authentication.service";
var styles_MerchantAuthCodeModalComponent = [i0.styles];
var RenderType_MerchantAuthCodeModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MerchantAuthCodeModalComponent, data: { "animation": [{ type: 7, name: "onChangeAnimation", definitions: [{ type: 1, expr: "void => *", animation: [{ type: 11, selector: ".animate", animation: { type: 6, styles: { opacity: 0 }, offset: null }, options: { optional: true } }, { type: 11, selector: ".animate", animation: { type: 12, timings: "100ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 0, offset: 0, height: 0 }, offset: null }, { type: 6, styles: { opacity: 1, offset: 1, height: "*" }, offset: null }] }, timings: "0.15s ease-in-out" }] }, options: { optional: true } }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 11, selector: ".animate", animation: { type: 6, styles: { opacity: 1 }, offset: null }, options: { optional: true } }, { type: 11, selector: ".animate", animation: { type: 12, timings: "100ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 1, offset: 0, height: "*" }, offset: null }, { type: 6, styles: { opacity: 0, offset: 1, height: 0 }, offset: null }] }, timings: "0.15s ease-in-out" }] }, options: { optional: true } }], options: null }], options: {} }] } });
export { RenderType_MerchantAuthCodeModalComponent as RenderType_MerchantAuthCodeModalComponent };
function View_MerchantAuthCodeModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "p", [["class", "small rm-m"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" This Code can be used "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" time(s) "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.numberOfUse; _ck(_v, 3, 0, currVal_0); }); }
function View_MerchantAuthCodeModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "full-width row ml mb code-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["class", "sr-title rm-m sm-h3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["From a tablet or PC in waiting area"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "small"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Copy and paste this Url in you PC or Tablet"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["class", "link-class clearfix"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵeld(7, 0, null, null, 5, "button", [["class", "btn-small mt clearfix"], ["color", "primary"], ["mat-stroked-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copy(_co.practiceLoginPage) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(8, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(9, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(10, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["content_copy"])), (_l()(), i1.ɵted(-1, 0, [" Copy Link "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "p", [["class", "small"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Quote this code to your patient. They will then be prompted to get an SMS that they will need to enter to access the link "])), (_l()(), i1.ɵeld(15, 0, null, null, 7, "div", [["class", "row clearfix rel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "p", [["class", "lead bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MerchantAuthCodeModalComponent_4)), i1.ɵdid(19, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 2, "button", [["class", "btn mt"], ["color", "accent"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.newCode() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(21, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Get New code"]))], function (_ck, _v) { var _co = _v.component; var currVal_4 = "primary"; _ck(_v, 8, 0, currVal_4); _ck(_v, 10, 0); var currVal_8 = (_co.numberOfUse > 0); _ck(_v, 19, 0, currVal_8); var currVal_11 = "accent"; _ck(_v, 21, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.practiceLoginPage, ""); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.practiceLoginPage; _ck(_v, 6, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 8).disabled || null); var currVal_3 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵnov(_v, 10).inline; var currVal_6 = (((i1.ɵnov(_v, 10).color !== "primary") && (i1.ɵnov(_v, 10).color !== "accent")) && (i1.ɵnov(_v, 10).color !== "warn")); _ck(_v, 9, 0, currVal_5, currVal_6); var currVal_7 = _co.code; _ck(_v, 17, 0, currVal_7); var currVal_9 = (i1.ɵnov(_v, 21).disabled || null); var currVal_10 = (i1.ɵnov(_v, 21)._animationMode === "NoopAnimations"); _ck(_v, 20, 0, currVal_9, currVal_10); }); }
function View_MerchantAuthCodeModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "full-width row flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "full-width row mr mb proceed-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [["class", "sr-title rm-m sm-h3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["From your browser on this PC"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", "img-proceed character_secrect"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "p", [["class", "small"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["Assist your ", " directly from your portal, Click on proceed to access to the ", " card"])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "clearfix proceed-button text-center full-width"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["class", "btn"], ["color", "accent"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.proceed() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Proceed"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MerchantAuthCodeModalComponent_3)), i1.ɵdid(15, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = "accent"; _ck(_v, 12, 0, currVal_4); var currVal_5 = (_co.code && (_co.numberOfUse > 0)); _ck(_v, 15, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("KEYWORD.patient")); var currVal_1 = i1.ɵunv(_v, 7, 1, i1.ɵnov(_v, 9).transform("KEYWORD.patient")); _ck(_v, 7, 0, currVal_0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 12).disabled || null); var currVal_3 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_2, currVal_3); }); }
function View_MerchantAuthCodeModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "mat-dialog-content", [["class", "text-center mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i10.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [["class", "lead"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["Access ", " ", "\u2019s ", " two ways"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MerchantAuthCodeModalComponent_2)), i1.ɵdid(5, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.code && (_co.numberOfUse > 0)); _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.invitation.FirstName; var currVal_1 = _co.invitation.LastName; var currVal_2 = _co.target; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_MerchantAuthCodeModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i8.TitleCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "card-header primary-gradient-img inModal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "row titleArea clearfix full-width"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "h2", [["class", "summary-header rm-m white"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " portal access"])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(6, 1), (_l()(), i1.ɵeld(7, 0, null, null, 3, "button", [["class", "btn-close btn-clear mat-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(9, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["clear"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MerchantAuthCodeModalComponent_1)), i1.ɵdid(12, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 9, 0); var currVal_3 = _co.invitation; _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 6, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("KEYWORD.patient")))); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 9).inline; var currVal_2 = (((i1.ɵnov(_v, 9).color !== "primary") && (i1.ɵnov(_v, 9).color !== "accent")) && (i1.ɵnov(_v, 9).color !== "warn")); _ck(_v, 8, 0, currVal_1, currVal_2); }); }
export function View_MerchantAuthCodeModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-merchant-auth-code-modal", [], null, null, null, View_MerchantAuthCodeModalComponent_0, RenderType_MerchantAuthCodeModalComponent)), i1.ɵdid(1, 114688, null, 0, i11.MerchantAuthCodeModalComponent, [i12.ClipboardService, i13.MerchantService, i14.Router, i15.AuthenticationService, [2, i10.MAT_DIALOG_DATA]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MerchantAuthCodeModalComponentNgFactory = i1.ɵccf("app-merchant-auth-code-modal", i11.MerchantAuthCodeModalComponent, View_MerchantAuthCodeModalComponent_Host_0, { invitationID: "invitationID", medicalHistoryInvitationID: "medicalHistoryInvitationID", merchantID: "merchantID", patientID: "patientID", type: "type" }, {}, []);
export { MerchantAuthCodeModalComponentNgFactory as MerchantAuthCodeModalComponentNgFactory };
