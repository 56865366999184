<form #priceServiceForm="ngForm">
  <div class="card-header primary-gradient-img clearfix inModal" *ngIf="practice">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear mat-button white" matDialogClose>
        <mat-icon>clear</mat-icon>
      </button>
      <!-- merchant calculated name -->
      <div class="row titleArea clearfix">
        <!-- <app-merchant-view-picture class=" userIconView-overhead  mr pull-left" [merchantID]="practice['ID']">
        </app-merchant-view-picture> -->
        <div class="clearfix userIconView mr pull-left">
          <app-ep-document-view-image
            class=""
            [defaultPicture]="
              'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
            "
            [link]="profileLink"
          >
          </app-ep-document-view-image>
        </div>

        <h2 class="summary-header rm-m">
          {{ "KEYWORD.practice" | translate | titlecase}} Overheads
          <span class="subLabel">Set master     {{ "KEYWORD.practice" | translate }} overhead</span>

          <span class="subLabel"> {{ practice['TradingAs'] }} </span>
        </h2>
      </div>
    </div>
  </div>

  <mat-dialog-content class="includes-buttons" *ngIf="practice">
    <div class="row clearfix">
      <div class="icons-col">
        <mat-icon class="fas fa-store"></mat-icon>
      </div>
      <div class="row-content-col row clearfix">
        <div class="row clearfix flex">
          <!-- set overhead percentage -->
          <mat-form-field class="full-width mt" *ngIf="useDefault == false">
            <mat-label>Master {{"KEYWORD.practice" | translate}} overhead (%)</mat-label>
            <input
              class="mat-block"
              [(ngModel)]="overheads"
              type="number"
              name="cost_overhead"
              [placeholder]="'Master ' + ('KEYWORD.practice' | translate) + ' overhead (%)'"
              matInput
              required
            />
            <span class="posPrefix" matSuffix>
              <strong>%</strong>
            </span>
          </mat-form-field>

          <mat-form-field class="full-width mt" *ngIf="useDefault == true">
            <mat-label>Master {{"KEYWORD.practice" | translate}} overhead (%)</mat-label>
            <input
              class="mat-block"
              [(ngModel)]="practice['OverheadPercentage.Effective']"
              [disabled]="true"
              type="number"
              name="cost_overhead"
              [placeholder]="'Master ' + ('KEYWORD.practice' | translate) + ' overhead (%)'"
              matInput
            />
            <span class="posPrefix" matSuffix>
              <strong>%</strong>
            </span>
          </mat-form-field>

          <mat-slide-toggle class="pull-right add-mt" [(ngModel)]="useDefault" name="useDefault">
            Use default Value?
          </mat-slide-toggle>
        </div>
      </div>
    </div>

    <div class="row clearfix">
      <p class="small">
        <strong>Note:</strong> This is a universal setting. Adjusting this value will influence the prices of all other
        {{ "KEYWORD.treatment" | translate }} items.
      </p>
    </div>
  </mat-dialog-content>

  <div class="row clearfix" *ngIf="practice">
    <hr/>
    <!-- save changes -->
    <button
      class="pull-right"
      [disabled]="!priceServiceForm.form.valid"
      (click)="save()"
      mat-raised-button
      color="accent"
    >
      Save
    </button>
  </div>
</form>
