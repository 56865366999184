<div class="rel clearfix lookup mt" style="width: 98%">
  <div class="card-header primary-gradient-img clearfix inModal">
    <div class="row clearfix">
      <div class="row titleArea clearfix">
        <mat-icon
          class="fas fa-envelope-open-text pull-left mr main-icon-brush"
          style="font-size: 2.8rem; margin-top: 10px; width: 70px"
        ></mat-icon>
        <h2 class="summary-header rm-m">
          {{ title }}
          <span class="subLabel" [innerHTML]="subtitle | safeHtml"></span>
        </h2>
      </div>
    </div>
  </div>

  <div class="clearfix">
    <div class="flex">
      <mat-form-field class="full-width mr" appearance="outline">
        <mat-label>Search</mat-label>
        <input
          [(ngModel)]="searchFilter"
          (ngModelChange)="changeSearchFilter()"
          matInput
          name="search"
          placeholder="Search by Title, Content"
        />
      </mat-form-field>

      <button
        class="sml actionMenuToggle"
        *ngIf="isCollapsed == true"
        (click)="collapse(false)"
        mat-icon-button
        matTooltip="Open all content "
      >
        <mat-icon class="fas fa-window-maximize mt"></mat-icon>
      </button>

      <button
        class="sml actionMenuToggle"
        *ngIf="isCollapsed != true"
        (click)="collapse(true)"
        mat-icon-button
        matTooltip="Close all  content"
      >
        <mat-icon class="fas fa-window-minimize mt"></mat-icon>
      </button>

      <button class="sml actionMenuToggle" [matMenuTriggerFor]="menu" mat-icon-button>
        <mat-icon class="fas fa-sort mt"></mat-icon>
      </button>
    </div>

    <mat-menu #menu="matMenu">
      <button
        [ngClass]="{ 'accent-color': orderByDirection === 'Template_Label' || orderByDirection === '-Template_Label' }"
        (click)="orderBy('Template_Label')"
        mat-menu-item
      >
        <span>Label</span>
        <span *ngIf="orderByDirection === 'Template_Label' || orderByDirection === '-Template_Label'">
          <mat-icon class="accent-color" *ngIf="orderByDirection === '-Template_Label'">arrow_drop_up</mat-icon>
          <mat-icon class="accent-color" *ngIf="orderByDirection !== '-Template_Label'">arrow_drop_down</mat-icon>
        </span>
      </button>

      <button
        [ngClass]="{
          'accent-color': orderByDirection === 'DateTimeCreated' || orderByDirection === '-DateTimeCreated'
        }"
        (click)="orderBy('-DateTimeCreated')"
        mat-menu-item
      >
        <span>Date</span>
        <span *ngIf="orderByDirection === 'DateTimeCreated' || orderByDirection === '-DateTimeCreated'">
          <mat-icon class="accent-color" *ngIf="orderByDirection === '-DateTimeCreated'">arrow_drop_down</mat-icon>
          <mat-icon class="accent-color" *ngIf="orderByDirection !== '-DateTimeCreated'"> arrow_drop_up</mat-icon>
        </span>
      </button>
    </mat-menu>

    <mat-dialog-content class="lookup-noteTemplate">
      <div class="full-width">
        <div class="patientContainer" *ngIf="noteTemplates$ | async as noteTemplates">
          <div class="patientRecord row clearfix" *ngFor="let noteTemplate of noteTemplates">
            <div class="flex full-width clearfix mt mb normal-container">
              <div class="profile-result full-width flex">
                <span
                  class="chipUser pull-left mr"
                  [matTooltip]="('KEYWORD.practice' | translate | titlecase) + ' Template'"
                  (click)="selectNoteTemplate(noteTemplate)"
                >
                  <mat-icon class="fas fa-user-alt"></mat-icon>
                </span>

                <div class="pull-left full-width truncate-normal" (click)="selectNoteTemplate(noteTemplate)">
                  <div class="full-width mb">
                    <h3 class="sr-title rm-m pull-left title-practice">
                      <strong class="mr"> {{ noteTemplate['Template_Label'] }} </strong>
                      <span class="value chip-new pull-right" *ngIf="isNew(noteTemplate['DateTimeCreated']) == true"
                      >NEW</span
                      >
                    </h3>
                  </div>

                  <message-input
                    class="full-width message-input-component2"
                    [isModal]="false"
                    [displayMore]="displayMore"
                    [isCollapsed]="isCollapsed"
                    [message]="noteTemplate.Description"
                    [displayMessageTemplate]="false"
                    [displayPatient]="false"
                    [displayContact]="false"
                    [displayMerchant]="false"
                    [displayPromoter]="false"
                    [displayMarketing]="false"
                    [displayExpend]="false"
                    [displayDentalOffers]="false"
                    [displayDocument]="false"
                    [saveAsTemplate]="false"
                    [isSMSPreview]="false"
                    [isDesktopPreview]="false"
                    [displaySaveAsTemplate]="false"
                    [encode]="false"
                    [isView]="true"
                    [isMessageConverted]="false"
                  >
                  </message-input>

                  <div class="full-width clearfix smt">
                    <p
                      class="small rm-m date_label grey pull-right"
                      *ngIf="noteTemplate.DateTimeCreated"
                      style="padding-right: 20px"
                    >
                      {{ noteTemplate.DateTimeCreated | TimeLabelPast }} ago
                    </p>
                  </div>
                </div>

                <button
                  class="messageTemplate-view-button"
                  [matMenuTriggerFor]="previewMenu"
                  (click)="$event.stopPropagation()"
                  color="primary"
                  mat-icon-button
                  matTooltip="View Template Details"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #previewMenu="matMenu">
                  <button (click)="openNoteTemplateFormModalUpdateMode(noteTemplate)" mat-menu-item>
                    <mat-icon>edit</mat-icon>
                    <span> Update </span>
                  </button>

                  <button (click)="deleteNote(noteTemplate)" mat-menu-item>
                    <mat-icon>delete</mat-icon>
                    <span>Delete</span>
                  </button>
                </mat-menu>
              </div>
            </div>

            <mat-divider></mat-divider>
          </div>

          <app-empty-list-message
            *ngIf="noteTemplates.length === 0"
            [message]="'No Templates found'"
          ></app-empty-list-message>
        </div>
      </div>
    </mat-dialog-content>

    <hr class="messageTemplate-bottom-divider"/>

    <div class="drop-button full-width text-centerclearfix">
      <button
        class="inline btn-new-invite selectExistingPatient pull-right btn"
        (click)="openNoteTemplateFormModal()"
        mat-raised-button
        color="accent"
      >
        <mat-icon>add</mat-icon>
        <span>Create your own</span>
      </button>
    </div>
  </div>
</div>
