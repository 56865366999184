import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { ContractService } from '../../contract/shared/contract.service';
import { CustomerProspectService } from '../../customer-prospect/shared/customerProspect.service';
import { DefaultMessageService } from '../../default-message/shared/default-message.service';
import { DefaultMessageUtilClass } from '../../default-message/shared/defaultMessageUtil';
import { DentistService } from '../../dentist/shared/dentist.service';
import { InvitationService } from '../../invitation/shared/services/invitation.service';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { MessageService } from '../shared/message.service';

@Component({
  selector: 'app-text-message-create',
  templateUrl: './text-message-create.component.html',
  styleUrls: ['./text-message-create.component.css'],
  providers: [CustomPhonePipe],
})
export class TextMessageCreateComponent implements OnInit {
  @Input()
  invitationID;

  @Input()
  patientID;

  @Input()
  contractID;

  @Output()
  closeModal = new EventEmitter();

  invitationObject;
  merchantObject;
  dentistObject;
  patientObject;

  label;
  textMessage = '';
  _textMessage;

  textMessageCounter = 0;
  textMessagePart = 0;
  utilMessage = new DefaultMessageUtilClass();

  cursorPos;

  isModal = false;

  option = 'setup';

  utils = new UtilsClass();

  isPromoterOrAdmin = false;

  messageTemplates = [];
  selectedTemplateMsg;
  promoter;
  sessionType = 'merchant';
  @ViewChild('messageBodyID', { static: false }) messageBody: ElementRef<HTMLInputElement>;

  constructor(
    private authenticationService: AuthenticationService,
    private defaultmessageService: DefaultMessageService,
    private invitationService: InvitationService,
    private dentistService: DentistService,
    private merchantService: MerchantService,
    private customerProspectService: CustomerProspectService,
    private customePhonePipe: CustomPhonePipe,
    private messageService: MessageService,
    private contractService: ContractService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.invitationID) {
        this.invitationID = data.invitationID;
      }

      if (data.patientID) {
        this.patientID = data.patientID;
      }

      if (data.contractID) {
        this.contractID = data.contractID;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }
      this.authenticationService.getPromoter().subscribe((promoter) => {
        if (promoter && promoter.data) {
          this.promoter = promoter.data;
        } else if (promoter) {
          this.promoter = promoter;
        }
      });
      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        if (this.isPromoterOrAdmin == true) {
          const payload = {
            purposeCode: 'PromSMS',
            orderBy: 'Label',
            onlyActive: true,
          };
          this.defaultmessageService.getDefaultMessageListGlobal(payload).subscribe((msg) => {
            if (msg && msg.length > 0) {
              this.messageTemplates = msg;
            }
          });

          if (this.patientID) {
            this.customerProspectService
              .getCustomerProspectDetails(this.patientID, {}, this.sessionType)
              .subscribe((patient) => {
                if (patient) {
                  this.patientObject = patient;
                }
              });
          }

          if (this.contractID) {
            this.contractService.fetchContractDetails$(this.contractID, {}, this.isPromoterOrAdmin).subscribe((res) => {
              this.merchantService
                .getMerchantDetails(res['Merchant_key'], {}, this.sessionType)
                .subscribe((merchant) => {
                  if (merchant) {
                    this.merchantObject = merchant;
                  }
                });

              this.customerProspectService
                .getCustomerProspectDetails(res['Customer_key'], {}, this.sessionType)
                .subscribe((patient) => {
                  if (patient) {
                    this.patientObject = patient;
                  }
                });
            });
          }

          if (this.invitationID) {
            this.invitationService
              .getInvitationDetails(this.invitationID, {}, this.sessionType, false)
              .subscribe((inv) => {
                if (inv) {
                  this.invitationObject = inv;

                  if (this.invitationObject['DentistContact_key']) {
                    this.dentistService
                      .getContactDetails(this.invitationObject['DentistContact_key'], {}, this.sessionType)
                      .subscribe((dentist) => {
                        if (dentist) {
                          this.dentistObject = dentist;
                        }
                      });
                  }

                  if (this.invitationObject['Merchant_Key']) {
                    this.merchantService
                      .getMerchantDetails(this.invitationObject['Merchant_Key'], {}, this.sessionType)
                      .subscribe((merchant) => {
                        if (merchant) {
                          this.merchantObject = merchant;
                        }
                      });
                  }

                  if (this.invitationObject['Prospect_key']) {
                    this.customerProspectService
                      .getCustomerProspectDetails(this.invitationObject['Prospect_key'], {}, this.sessionType)
                      .subscribe((patient) => {
                        if (patient) {
                          this.patientObject = patient;
                        }
                      });
                  }
                }
              });
          }
        }
      });
    });
  }

  saveAsTemplate() {
    const payload = {
      label: this.label,
      content: this.textMessage,
      purposeCode: 'PromSMS',
      productGroupCode: 'GPF',
    };

    this.defaultmessageService.createDefaultMessage(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast('success', 'Successful Operation', 'You have successfully saved as template!');
      }
    });
  }

  sendMessage() {
    const payload = {
      patientID: this.patientObject['ID'],
      subject: this.label,
      body: this._textMessage,
      asPromoter: true,
      invitationID: this.invitationID,
    };

    this.messageService.promoterSendMessage(payload).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast(
          'success',
          'Successful Operation',
          'You have successfully send message to patient!'
        );
        this.closeModal.emit(true);
      }
    });
  }

  getTemplateMessage() {
    if (this.selectedTemplateMsg) {
      this.label = this.selectedTemplateMsg.Label;
      this.textMessage = this.selectedTemplateMsg.Content;

      this.getContentLength();
    }
  }

  addPersonalisation(p) {
    if (this.cursorPos > 0) {
      this.textMessage =
        this.textMessage.slice(0, this.cursorPos) + ' ' + p.value + ' ' + this.textMessage.slice(this.cursorPos);
    } else {
      this.textMessage = p.value + ' ' + this.textMessage;
    }

    this.getContentLength();
  }

  getcursorPos() {
    this.cursorPos = this.messageBody.nativeElement.selectionStart;
  }

  getContentLength() {
    this._textMessage = this.convertMessage(this.textMessage);
    this.textMessageCounter = this.textMessage.length;
    if (this.textMessageCounter > 0 && this.textMessageCounter <= 160) {
      this.textMessagePart = 1;
    } else if (this.textMessageCounter > 160) {
      this.textMessagePart = Math.ceil(this.textMessageCounter / 153);
    } else {
      this.textMessagePart = 0;
    }
  }

  closeEvent() {
    this.closeModal.emit(true);
  }

  convertMessage(message) {
    let result;

    const rule = {};

    if (this.patientObject) {
      rule['PatientSalutation'] = this.patientObject.Salutation;
    }

    if (this.patientObject) {
      rule['PatientLastName'] = this.patientObject.Name;
    }

    if (this.patientObject) {
      rule['PatientFirstName'] = this.patientObject.FirstName;
    }

    if (this.patientObject) {
      rule['PatientMiddleName'] = this.patientObject.MiddleName;
    }

    if (this.patientObject) {
      rule['PatientMobileNumber'] = this.customePhonePipe.transform(this.patientObject['mobiles.Number'], 'false');
    }

    if (this.patientObject) {
      rule['PatientWorkPhoneNumber'] = this.customePhonePipe.transform(this.patientObject['phones.Number'], 'true');
    }

    if (this.patientObject) {
      rule['PatientEmail'] = this.patientObject['emails.Email'];
    }

    // if (this.user) {
    //   rule['PatientAddress'] = "";
    // }

    if (this.dentistObject) {
      rule['DentistSalutation'] = this.dentistObject['Salutation'];
    }

    if (this.dentistObject) {
      rule['DentistFirstName'] = this.dentistObject['FirstName'];
    }

    if (this.dentistObject) {
      rule['DentistMiddleName'] = this.dentistObject['MiddleName'];
    }

    if (this.dentistObject) {
      rule['DentistLastName'] = this.dentistObject['Name'];
    }

    if (this.dentistObject) {
      rule['DentistMobileNumber'] = this.customePhonePipe.transform(this.dentistObject['mobiles.Number'], 'false');
    }

    if (this.dentistObject) {
      rule['DentistPhoneNumber'] = this.customePhonePipe.transform(this.dentistObject['phones.Number'], 'true');
    }

    if (this.dentistObject) {
      rule['DentistEmail'] = this.dentistObject['emails.Email'];
    }

    if (this.dentistObject) {
      rule['DentistAddress'] = this.dentistObject['addresses.Calculated'];
    }

    if (this.merchantObject) {
      rule['PracticeLegalName'] = this.merchantObject['Name'];
    }

    if (this.merchantObject) {
      rule['PracticeLegalName'] = this.merchantObject['Name'];
    }

    if (this.merchantObject) {
      rule['PracticeAddress'] = this.merchantObject['addresses.Calculated'];
    }

    if (this.merchantObject) {
      rule['PracticeTradingAs'] = this.merchantObject['TradingAs'];
    }

    if (this.merchantObject) {
      rule['PracticePhoneNumber'] = this.customePhonePipe.transform(this.merchantObject['phones.Number'], 'true');
    }

    if (this.merchantObject && this.merchantObject['mobiles.Number']) {
      rule['PracticeMobileNumber'] = this.customePhonePipe.transform(this.merchantObject['mobiles.Number'], 'false');
    } else {
      rule['PracticeMobileNumber'] = '';
    }

    if (this.merchantObject) {
      rule['PracticeAddress'] = this.merchantObject['addresses.Calculated'];
    }

    if (this.merchantObject) {
      rule['PracticeABN'] = this.merchantObject['ABN'];
    }

    if (this.merchantObject) {
      rule['PracticeACN'] = this.merchantObject['ACN'];
    }

    if (this.merchantObject) {
      rule['PracticeEmail'] = this.merchantObject['emails.Email'];
    }

    if (this.promoter) {
      if (this.promoter['TradingAs']) {
        rule['PromoterTradingAs'] = this.promoter['TradingAs'];
      } else {
        rule['PromoterTradingAs'] = ' ';
      }

      if (this.promoter['FirstName']) {
        rule['PromoterFirstName'] = this.promoter['FirstName'];
      } else {
        rule['PromoterFirstName'] = ' ';
      }

      if (this.promoter['Name']) {
        rule['PromoterLegalName'] = this.promoter['Name'];
      } else {
        rule['PromoterLegalName'] = ' ';
      }

      if (this.promoter['mobiles.Number']) {
        rule['PromoterMobileNumber'] = this.customePhonePipe.transform(this.promoter['mobiles.Number'], 'false');
      } else {
        rule['PromoterMobileNumber'] = ' ';
      }

      if (this.promoter['phones.Number']) {
        rule['PromoterPhoneNumber'] = this.customePhonePipe.transform(this.promoter['phones.Number'], 'true');
      } else {
        rule['PromoterPhoneNumber'] = ' ';
      }

      if (this.promoter['emails.Email']) {
        rule['PromoterEmail'] = this.promoter['emails.Email'];
      } else {
        rule['PromoterEmail'] = ' ';
      }

      if (this.promoter['addresses.Calculated']) {
        rule['PromoterAddress'] = this.promoter['addresses.Calculated'];
      } else {
        rule['PromoterAddress'] = ' ';
      }

      if (this.promoter['Facebook']) {
        rule['PromoterFacebook'] = this.promoter['Facebook'];
      } else {
        rule['PromoterFacebook'] = ' ';
      }

      if (this.promoter['Instagram']) {
        rule['PromoterInstagram'] = this.promoter['Instagram'];
      } else {
        rule['PromoterInstagram'] = ' ';
      }
      if (this.promoter['Twitter']) {
        rule['PromoterTwitter'] = this.promoter['Twitter'];
      } else {
        rule['PromoterTwitter'] = ' ';
      }

      if (this.promoter['LinkedIn']) {
        rule['PromoterLinkedIn'] = this.promoter['LinkedIn'];
      } else {
        rule['PromoterLinkedIn'] = ' ';
      }

      if (this.promoter['Pinterest']) {
        rule['PromoterPinterest'] = this.promoter['Pinterest'];
      } else {
        rule['PromoterPinterest'] = ' ';
      }
      if (this.promoter['Tumblr']) {
        rule['PromoterTumblr'] = this.promoter['Tumblr'];
      } else {
        rule['PromoterTumblr'] = ' ';
      }

      if (this.promoter['Vimeo']) {
        rule['PromoterVimeo'] = this.promoter['Vimeo'];
      } else {
        rule['PromoterVimeo'] = ' ';
      }
      if (this.promoter['YouTube']) {
        rule['PromoterYouTube'] = this.promoter['YouTube'];
      } else {
        rule['PromoterYouTube'] = ' ';
      }
      if (this.promoter['URL']) {
        rule['PromoterWebSite'] = this.promoter['URL'];
      } else {
        rule['PromoterWebSite'] = ' ';
      }

      if (this.promoter['ABN']) {
        rule['PromoterABN'] = this.promoter['ABN'];
      } else {
        rule['PromoterABN'] = ' ';
      }
      if (this.promoter['ACN']) {
        rule['PromoterACN'] = this.promoter['ACN'];
      } else {
        rule['PromoterACN'] = ' ';
      }
    }
    result = this.utils.convertMessage(message, rule);

    return result;
  }
}
