import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material';
import { ClipboardModule } from 'ngx-clipboard';
import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '../../shared/shared.module';
import { AuthenticationModule } from '../authentication/authentication.module';
import { SessionModule } from '../session/session.module';
import { EconnectFieldListComponent } from './econnect-field-list/econnect-field-list.component';
import { EconnectFieldViewComponent } from './econnect-field-view/econnect-field-view.component';
import { EconnectOverviewComponent } from './econnect-overview/econnect-overview.component';
import { EconnectTableListComponent } from './econnect-table-list/econnect-table-list.component';
import { EconnectTableViewComponent } from './econnect-table-view/econnect-table-view.component';
import { EconnectRoutingModule } from './shared/econnect-routing.module';
import { EconnectService } from './shared/econnect.service';

@NgModule({
  imports: [
    CdkTableModule,
    MatSortModule,
    NgPipesModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    ClipboardModule,
    AuthenticationModule,
    SessionModule,
    EconnectRoutingModule,
  ],
  declarations: [
    EconnectTableListComponent,
    EconnectFieldListComponent,
    EconnectTableViewComponent,
    EconnectFieldViewComponent,
    EconnectOverviewComponent,
  ],
  exports: [
    EconnectTableListComponent,
    EconnectFieldListComponent,
    EconnectTableViewComponent,
    EconnectFieldViewComponent,
    EconnectOverviewComponent,
  ],
  providers: [EconnectService],
})
export class EconnectModule {}
