import { Component, OnInit } from '@angular/core';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { PasswordChangeComponent } from '../../authentication/password-change/password-change.component';

@Component({
  selector: 'app-password-reset-empty-page',
  templateUrl: './password-reset-empty-page.component.html',
  styleUrls: ['./password-reset-empty-page.component.css'],
})
export class PasswordResetEmptyPageComponent implements OnInit {
  currentUser;

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe((res) => {
      if (res && res.data) {
        this.currentUser = res.data;
      }
      if (
        this.currentUser &&
        this.currentUser['operatorID'] &&
        this.currentUser.PasswordsNeedsResetting === '1'
      ) {
        const ref = RootAppComponent.dialog.open(PasswordChangeComponent, {
          data: {
            title: 'Required password update.',
            buttonText: 'Submit',
            existingTitle:"Temporary Password (Sent by email)",
            isModal: true,
            canClose: false,
            isLogout: true
          },
          width: '650px',
          panelClass: 'noCard',
          hasBackdrop: false,
        });
        ref.backdropClick().subscribe((res) => {});

        ref.componentInstance.close.subscribe((res) => {
          if (res == true) {
            ref.close();
          }
        });
      }
    });
  }
}
