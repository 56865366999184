<div class="supplier-card">
  <div class="product-item-view">
    <mat-accordion class="grey-row">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <h3 class="">
            <mat-icon class="fas fa-qrcode"></mat-icon>
            Customer
          </h3>
        </mat-expansion-panel-header>

        <div class="row clearfix flex wrapped text-left">
          <div class="details block full-width">
            <div class="row-content">
              <p class="rm-mb">Customer Name</p>
              <h4 class="reduce-marg" *ngIf="customer">{{ customer['CalculatedName'] }}</h4>
              <h4 class="reduce-marg" *ngIf="!customer">-</h4>
            </div>
          </div>

          <div class="details block full-width">
            <div class="row-content">
              <p class="rm-mb">Email</p>
              <h4 class="reduce-marg" *ngIf="customer">{{ customer['emails.Email'] }}</h4>
              <h4 class="reduce-marg" *ngIf="!customer">-</h4>
            </div>
          </div>

          <div class="details block full-width">
            <div class="row-content">
              <p class="rm-mb">Mobile</p>
              <h4 class="reduce-marg" *ngIf="customer">{{ customer['phones.Number'] | customPhone }}</h4>
              <h4 class="reduce-marg" *ngIf="!customer">-</h4>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel class="text-left">
        <mat-expansion-panel-header>
          <h3 class="">
            <mat-icon class="fas fa-store"></mat-icon>
            Description
          </h3>
        </mat-expansion-panel-header>

        <div class="row clearfix flex product-detail-tab">
          <div class="row clearfix flex full-width">
            <div class="half-width flex">
              <!-- product catalogue image -->
              <app-ep-document-view-image
                class="full-width view-logo"
                [defaultPicture]="
                  'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/catalogue-icon.png)'
                "
                [link]="existingThumbnailSrc"
              >
              </app-ep-document-view-image>
            </div>

            <div class="half-width">
              <div class="row clearfix">
                <!-- <p class="clearfix description" [innerHTML]="products['Description'] | safeHtml"> -->
                <!--{{ "KEYWORD.treatment" | translate }} product desctiption text -->

                <read-more *ngIf="product" [text]="product['Description']" [maxLength]="500"></read-more>
                <!-- </p> -->
              </div>
            </div>
          </div>
        </div>

        <hr/>

        <div class="row clearfix flex wrapped text-left mt">
          <div class="details block full-width">
            <div class="row-content">
              <p class="rm-mb">Size</p>
              <h4 class="reduce-marg" *ngIf="product">{{ product['Size'] }}</h4>
              <h4 class="reduce-marg" *ngIf="!product">-</h4>
            </div>
          </div>

          <div class="details block full-width">
            <div class="row-content">
              <p class="rm-mb">Colour</p>
              <h4 class="reduce-marg" *ngIf="product">{{ product['Colour'] }}</h4>
              <h4 class="reduce-marg" *ngIf="!product">-</h4>
            </div>
          </div>

          <div class="details block full-width">
            <div class="row-content">
              <p class="rm-mb">Weight</p>
              <h4 class="reduce-marg" *ngIf="product">{{ product['Weight'] }}</h4>
              <h4 class="reduce-marg" *ngIf="!product">-</h4>
            </div>
          </div>

          <div class="details block full-width">
            <div class="row-content">
              <p class="rm-mb">Height</p>
              <h4 class="reduce-marg" *ngIf="product">{{ product['Height'] }}</h4>
              <h4 class="reduce-marg" *ngIf="!product">-</h4>
            </div>
          </div>

          <div class="details block full-width">
            <div class="row-content">
              <p class="rm-mb">Length</p>
              <h4 class="reduce-marg" *ngIf="product">{{ product['Length'] }}</h4>
              <h4 class="reduce-marg" *ngIf="!product">-</h4>
            </div>
          </div>

          <div class="details block full-width">
            <div class="row-content">
              <p class="rm-mb">Supplier</p>
              <h4 class="reduce-marg">
                <!--                <span *ngIf="beneficiary"> {{beneficiary.TradingAs}} </span>-->
                <span> - </span>
              </h4>
            </div>
          </div>

          <div class="details block full-width">
            <div class="row-content">
              <p class="rm-mb">Manufactured Year</p>
              <h4 class="reduce-marg" *ngIf="product">{{ product['Manufactured_Year'] }}</h4>
              <h4 class="reduce-marg" *ngIf="!product">-</h4>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <h3 class="">
            <mat-icon class="fas fa-qrcode"></mat-icon>
            Information
          </h3>
        </mat-expansion-panel-header>

        <div class="row clearfix flex wrapped text-left">
          <div class="details block full-width">
            <div class="row-content">
              <p class="rm-mb">Product Name</p>
              <h4 class="reduce-marg" *ngIf="product">{{ product['Product'] }}</h4>
              <h4 class="reduce-marg" *ngIf="!product">-</h4>
            </div>
          </div>

          <div class="details block full-width">
            <div class="row-content">
              <p class="rm-mb">Model</p>
              <h4 class="reduce-marg" *ngIf="product">{{ product['Model'] }}</h4>
              <h4 class="reduce-marg" *ngIf="!product">-</h4>
            </div>
          </div>

          <div class="details block full-width">
            <div class="row-content">
              <p class="rm-mb">Type</p>
              <!--              <h4 class="reduce-marg" *ngIf="product"> {{productType | titlecase}}</h4>-->
              <h4 class="reduce-marg">-</h4>
            </div>
          </div>

          <div class="details block full-width">
            <div class="row-content">
              <p class="rm-mb">Category</p>
              <!--              <h4 class="reduce-marg" *ngIf="product['Product_Category_key']"> {{productCategory | titlecase}}</h4>-->
              <h4 class="reduce-marg">-</h4>
            </div>
          </div>

          <div class="details block full-width">
            <div class="row-content">
              <p class="rm-mb">Stock Keeping Unit</p>
              <!--              <h4 class="reduce-marg"> {{product['Stock_Keeping_Unit']}} </h4>-->
              <h4 class="reduce-marg">-</h4>
            </div>
          </div>

          <div class="details block full-width">
            <div class="row-content">
              <p class="rm-mb">Universal Product Code</p>
              <!--              <h4 class="reduce-marg"> {{product['Universal_Product_Code']}}</h4>-->
              <h4 class="reduce-marg">-</h4>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <h3 class="">
            <mat-icon class="fas fa-dollar-sign"></mat-icon>
            Pricing
          </h3>
        </mat-expansion-panel-header>

        <div class="row clearfix flex wrapped text-left">
          <div class="details block full-width">
            <div class="row-content">
              <p class="rm-mb">Cost Price</p>
              <!--              <h4 class="reduce-marg"> {{product.CostPrice | customCurrency}} </h4>-->
              <h4 class="reduce-marg">-</h4>
            </div>
          </div>

          <div class="details block full-width">
            <div class="row-content">
              <p class="rm-mb">Sell Price</p>
              <!--              <h4 class="reduce-marg"> {{product.SellPrice | customCurrency}} </h4>-->
              <h4 class="reduce-marg">-</h4>
            </div>
          </div>

          <div class="details block full-width">
            <div class="row-content">
              <p class="rm-mb">Tax Rate</p>
              <!--              <h4 class="reduce-marg"> {{product.TaxRate | customCurrency}} </h4>-->
              <h4 class="reduce-marg">-</h4>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <h3 class="">
            <mat-icon class="fas fa-file-invoice"></mat-icon>
            Gallery
          </h3>
        </mat-expansion-panel-header>

        <div style="display: flex; width: 100%">
          <app-ep-document-view-image
            class="gallery-logo mr"
            *ngFor="let additionalImage of additionalImages"
            [defaultPicture]="
              'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/catalogue-icon.png)'
            "
            [link]="additionalImage"
          >
          </app-ep-document-view-image>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
