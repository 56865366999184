import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExistingPatientListIntroModalComponent } from '../existing-patient-list-intro-modal/existing-patient-list-intro-modal.component';
import { ExistingPatientListComponent } from '../existing-patient-list/existing-patient-list.component';
import { ExistingPatientViewComponent } from '../existing-patient-view/existing-patient-view.component';

import { ExistingPatientCreateLookupComponent } from '../existing-patient-create-lookup/existing-patient-create-lookup.component';
import { ExistingPatientInvitationViewComponent } from '../existing-patient-invitation-view/existing-patient-invitation-view.component';
import { ExistingPatientListGlobalComponent } from '../existing-patient-list-global/existing-patient-list-global.component';
import { ExistingPatientListMapComponent } from '../existing-patient-list-map/existing-patient-list-map.component';
import { ExistingPatientListOverViewComponent } from '../existing-patient-list-overview/existing-patient-list-overview.component';
import { ExistingPatientMapOverViewComponent } from '../existing-patient-map-overview/existing-patient-map-overview.component';
import { ProspectEditComponent } from '../prospect-edit/prospect-edit.component';
import { ProspectViewComponent } from '../prospect-view/prospect-view.component';

import { ExistingPatientCreateComponent } from '../existing-patient-create/existing-patient-create.component';

const routes: Routes = [
  {
    path: 'ExistingPatientListMapComponent',
    component: ExistingPatientListMapComponent,
  },

  {
    path: 'ExistingPatientMapOverViewComponent',
    component: ExistingPatientMapOverViewComponent,
  },

  {
    path: 'existing-patient-list',
    component: ExistingPatientListComponent,
  },
  {
    path: 'existing-patient-list-global',
    component: ExistingPatientListGlobalComponent,
  },
  {
    path: 'existing-patient-list-overview',
    component: ExistingPatientListOverViewComponent,
  },

  {
    path: 'prospect-view',
    component: ProspectViewComponent,
  },

  {
    path: 'prospect-edit/:ID',
    component: ProspectEditComponent,
  },

  {
    path: 'existing-patient-view',
    component: ExistingPatientViewComponent,
  },

  {
    path: 'existing-patient-create',
    component: ExistingPatientCreateComponent,
  },
  {
    path: 'existing-patient-list-intro-modal',
    component: ExistingPatientListIntroModalComponent,
  },
  {
    path: 'existing-patient-create',
    component: ExistingPatientCreateComponent,
  },
  {
    path: 'existing-patient-create-lookup',
    component: ExistingPatientCreateLookupComponent,
  },
  {
    path: 'existing-patient-invitation-view',
    component: ExistingPatientInvitationViewComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ExistingPatientRoutingModule {}
