<div class="side-menu-container" flex>
  <!-- Top bar - logo -->
  <div class="logo-container">
    <div class="site_logo"></div>
    <h1 class="mat-h1">Administration</h1>
  </div>
  <!-- Navigation Menu -->
  <nav id="side-menu" #sidenav1>
    <mat-list class="main-menu">
      <mat-list-item>
        <a mat-button [routerLink]="['/admin', {outlets: {'adminPageRoute': ['general']}}]">
          <mat-icon>dvr</mat-icon>
          <span class="text">Site Details</span>
        </a>
      </mat-list-item>
      <mat-list-item>
        <a mat-button [routerLink]="['/admin', {outlets: {'adminPageRoute': ['econnect-documentation']}}]">
          <mat-icon>menu_book</mat-icon>
          <span class="text">Econnect Documentation</span>
        </a>
      </mat-list-item>

      <mat-list-item>
        <a mat-button [routerLink]="['/admin', {outlets: {'adminPageRoute': ['users']}}]">
          <mat-icon>group</mat-icon>
          <span class="text">Users</span>
        </a>
      </mat-list-item>
      <mat-list-item>
        <a mat-button [routerLink]="['/admin', {outlets: {'adminPageRoute': ['tip']}}]">
          <mat-icon>flash_on</mat-icon>
          <span class="text">Tips</span>
        </a>
      </mat-list-item>

      <mat-list-item>
        <a mat-button [routerLink]="['/admin', {outlets: {'adminPageRoute': ['document-center']}}]" class="">
          <mat-icon>insert_drive_file</mat-icon>
          <span class="text">Document Center</span>
        </a>
      </mat-list-item>
      <mat-list-item>
        <a mat-button [routerLink]="['/admin', {outlets: {'adminPageRoute': ['help-document-center']}}]" class="">
          <mat-icon>help_outline</mat-icon>
          <span class="text">Help Document Center</span>
        </a>
      </mat-list-item>
      <mat-list-item>
        <a mat-button [routerLink]="['/admin', {outlets: {'adminPageRoute': ['communication-settings']}}]" class="">
          <mat-icon>message</mat-icon>
          <span class="text">Communication Settings</span>
        </a>
      </mat-list-item>
      <mat-list-item>
        <a mat-button [routerLink]="['/admin', {outlets: {'adminPageRoute': ['theme']}}]" class="">
          <mat-icon>format_color_fill</mat-icon>
          <span class="text">Themes</span>
        </a>
      </mat-list-item>

      <mat-list-item>
        <a mat-button [routerLink]="['/admin', {outlets: {'adminPageRoute': ['languages']}}]" class="">
          <mat-icon>translate</mat-icon>
          <span class="text">Languages</span>
        </a>
      </mat-list-item>
      <mat-list-item>
        <a mat-button [routerLink]="['/admin', {outlets: {'adminPageRoute': ['system']}}]" class="">
          <mat-icon>memory</mat-icon>
          <span class="text">System Settings</span>
        </a>
      </mat-list-item>
      <mat-list-item>
        <a mat-button [routerLink]="['/admin', {outlets: {'adminPageRoute': ['logs']}}]">
          <mat-icon>description</mat-icon>
          <span class="text">Logs</span>
        </a>
      </mat-list-item>
      <mat-list-item style="margin-top: 40px;">
        <a mat-button routerLink="/merchant/" class="">
          <mat-icon>home</mat-icon>
          <span class="text">Return to Dashboard</span>
        </a>
      </mat-list-item>

      <!-- <mat-list-item>
                <app-logout></app-logout>
            </mat-list-item> -->

    </mat-list>
  </nav>

  <!-- <div class="sidebar-background site_background"></div> -->
</div>
