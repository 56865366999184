<aw-wizard #wizard navBarLayout="large-filled-symbols">
  <aw-wizard-step [stepTitle]="('KEYWORD.patient' | translate | titlecase) + ' Information'" navigationSymbol="1">
    <!-- Step 1  -->
    <div class="container animated fadeInDown" id="step-1">
      <mat-card class="card treatment-card">
        <div class="card-header primary-gradient-img clearfix inModal">
          <div class="row clearfix">
            <div class="row titleArea clearfix">
              <mat-icon class="pull-left">perm_phone_msg</mat-icon>
              <h2 class="summary-header rm-m">
                New Referral
                <span class="subLabel">When referring us a patient, the more information you can provide, the better
                  prepared we can be. We
                  have created an online referral system, where you can also attach any photos or radiographs you have
                  taken.
                </span>
              </h2>
            </div>
          </div>
          <div class="action-button">
            <button class="btn-small mt" id="searchPatient" (click)="openPatientLookUpDialog()" mat-raised-button
                    color="accent">
              {{ "KEYWORD.patient" | translate | titlecase }} LookUp
              <mat-icon class="">contacts</mat-icon>
            </button>
          </div>
        </div>

        <div class="clearfix" *ngIf="isPromoterOrAdmin == true">
          <p class="help-label">
            To send an invitation on behalf of a merchant select a
            merchant below, otherwise, the invitation will be sent by the promoter.
          </p>

          <div class="row clearfix well selectMerchant">
            <mat-select class="margin-btm half-width" name="MerchantName" placeholder="Please Select a Merchant">
              <mat-option [value]="null"> My Account</mat-option>
            </mat-select>
          </div>
        </div>

        <hr class="mt mb" *ngIf="isPromoterOrAdmin == true"/>
        <div class="row clearfix">
          <div class="icons-col">
            <mat-icon class="fas fa-user"></mat-icon>
          </div>
          <div class="row-content-col mb" id="defaultMes">
            <h3 class="sr-title sm-h3 rm-mt mt rm-mb">{{"KEYWORD.patient" | translate | titlecase}} details</h3>
            <label>Please start by entering the details of your {{ "KEYWORD.patient" | translate }}. You can use the '{{
              'KEYWORD.patient' | translate | titlecase
              }}
              Lookup' button to search your existing {{ "KEYWORD.patient" | translate }} records.</label>
          </div>
        </div>

        <div class="row clearfix">
          <form #createForm="ngForm" name="form">
            <div class="row clearfix">
              <div class="icons-col">
                <mat-icon>person</mat-icon>
              </div>
              <div class="row-content-col">
                <div class="clearfix row flex">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Gender</mat-label>
                    <!-- Gender Select -->
                    <mat-select class="full-width" [(ngModel)]="user.gender" [userData]="'patient.gender'" name="gender"
                                placeholder="Gender" required>
                      <mat-option *ngFor="let gender of genders" [value]="gender.Code">
                        {{ gender.Label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <!-- Title Select -->

                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Title</mat-label>
                    <mat-select class="full-width" [(ngModel)]="user.title" [userData]="'patient.title'"
                                placeholder="Title" name="title">
                      <mat-option *ngFor="let title of titles" [value]="title.Label">
                        {{ title.Label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <!--  date of birth -->
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Date of birth</mat-label>
                    <input class="mat-block" matInput placeholder="Date of birth " name="birthdate" required/>
                  </mat-form-field>
                </div>
                <div class="row clearfix flex">
                  <!-- First Name -->
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{ 'PLACEHOLDER.First Name' | translate }} </mat-label>
                    <input class="mat-block" [(ngModel)]="user.firstname" [userData]="'patient.firstName'" matInput
                           placeholder="{{ 'PLACEHOLDER.First Name' | translate }} " name="firstname" required/>
                  </mat-form-field>

                  <!-- Middle Name -->
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{ 'PLACEHOLDER.Middle Name' | translate }} </mat-label>
                    <input class="mat-block" [(ngModel)]="user.middlename" [userData]="'patient.middleName'" matInput
                           placeholder="{{ 'PLACEHOLDER.Middle Name' | translate }} " name="middlename"/>
                  </mat-form-field>

                  <!-- Surname -->
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Surname</mat-label>
                    <input class="mat-block" [(ngModel)]="user.lastname" [userData]="'patient.lastName'" matInput
                           placeholder="{{ 'PLACEHOLDER.Surname' | translate }} " name="lastname" required/>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row clearfix">
              <div class="icons-col">
                <mat-icon class="fas fa-phone"></mat-icon>
              </div>
              <div class="row-content-col mb" id="defaultMes">
                <h3 class="sr-title sm-h3 rm-mt mt rm-mb">{{"KEYWORD.patient" | translate | titlecase}}/Guardian contact
                  details</h3>
                <label>If the {{ "KEYWORD.patient" | translate }} is under-age or unable to be contacted directly, we
                  can
                  contact a Parent/Guardian as
                  an alternative</label>
              </div>
            </div>
            <div class="row clearfix">
              <div class="row clearfix">
                <div class="icons-col">
                  <mat-icon>phone</mat-icon>
                </div>

                <div class="row-content-col">
                  <div class="row clearfix flex">
                    <app-phone-input class="full-width" [label]="'Mobile'" [required]="true"></app-phone-input>

                    <app-phone-input class="full-width" [isLandLine]="true" [label]="'Home'" [required]="false">
                    </app-phone-input>

                    <app-phone-input class="full-width" [isLandLine]="true" [label]="'Work'" [required]="false">
                    </app-phone-input>
                  </div>
                </div>
              </div>

              <div class="row clearfix">
                <div class="icons-col">
                  <mat-icon>email</mat-icon>
                </div>

                <div class="row-content-col">
                  <app-email-input class="full-width" [label]="'Email'" [required]="true"></app-email-input>
                </div>
              </div>
            </div>
          </form>
        </div>
      </mat-card>

      <div class="row clearfix">
        <div class="full-width text-center step-button-row">
          <button class="step-back" (click)="cancel()" mat-mini-fab color="primary">
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>

          <button class="btn-large" (click)="displayMerchantOption()" mat-raised-button type="submit" color="accent"
                  awNextStep>
            Next
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <!--  / Step 1  -->
  </aw-wizard-step>

  <aw-wizard-step stepTitle="Merchant Details" navigationSymbol="2">
    <div class="container animated fadeInDown" id="step-2">
      <div class="row clearfix">
        <mat-card class="card treatment-card card1">
          <div class="card-header primary-gradient-img clearfix inModal">
            <div class="row clearfix">
              <div class="row titleArea clearfix">
                <mat-icon class="fas fa-user-mat pull-left"></mat-icon>
                <h2 class="summary-header rm-m">
                  <!-- {{ beneficiary.CalculatedName}} -->
                  Select a {{ "KEYWORD.practitioner" | translate }}/specialist
                  <span class="subLabel">Enter or select the details of the {{ "KEYWORD.practitioner" | translate
                    }}/specialist you wish to refer a {{ "KEYWORD.patient" | translate }}
                    to.</span>
                </h2>
              </div>
            </div>
            <div class="action-button">
              <button class="btn-small mt" id="searchMerchant" (click)="openMerchantLookUpDialog()" mat-raised-button
                      color="accent">
                {{ "KEYWORD.practice" | translate | titlecase}} Lookup
                <mat-icon class="fas fa-user-mat"></mat-icon>
              </button>
            </div>
          </div>

          <div class="row clearfix">
            <div class="icons-col">
              <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
            </div>
            <div class="row-content-col mb" id="defaultMes">
              <h3 class="sr-title sm-h3 rm-mt mt rm-mb">{{"KEYWORD.Practice" | translate}} details</h3>
              <label>Please enter the name and primary contact details of the {{"KEYWORD.practice" | translate}}.
                Address and
                other information is
                optional if you know it.</label>
            </div>
          </div>

          <div class="row clearfix">
            <div class="icons-col">
              <mat-icon>store</mat-icon>
            </div>
            <div class="row-content-col">
              <div class="row clearfix flex">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>{{ "KEYWORD.practice" | translate | titlecase}} Name</mat-label>
                  <input class="mat-block" matInput placeholder="{{ " KEYWORD.practice" | translate | titlecase}} Name"
                  required />
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>{{ "KEYWORD.practice" | translate | titlecase}} Phone Number</mat-label>
                  <input class="mat-block" matInput placeholder="{{ " KEYWORD.practice" | translate | titlecase}} Phone
                  Number "
                         required/>
                </mat-form-field>
              </div>
            </div>
          </div>

          <app-address-input></app-address-input>

          <div class=" row clearfix">
            <div class="icons-col">
              <mat-icon class="fas fa-user-mat"></mat-icon>
            </div>
            <div class="row-content-col mb" id="defaultMes">
              <h3 class="sr-title sm-h3 rm-mt mt rm-mb">{{"KEYWORD.practitioner" | translate | titlecase}} contact
                details</h3>
            </div>
          </div>

          <div class="row clearfix">
            <div class="icons-col">
              <mat-icon>person</mat-icon>
            </div>
            <div class="row-content-col">
              <div class="clearfix row flex">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Gender</mat-label>

                  <!-- Gender Select -->
                  <mat-select class="full-width" [(ngModel)]="user.gender" [userData]="'patient.gender'"
                              name="gender" placeholder="Gender" required>
                    <mat-option *ngFor="let gender of genders" [value]="gender.Code">
                      {{ gender.Label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- Title Select -->
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Title</mat-label>
                  <mat-select class="full-width" [(ngModel)]="user.title" [userData]="'patient.title'"
                              placeholder="Title" name="title">
                    <mat-option *ngFor="let title of titles" [value]="title.Label">
                      {{ title.Label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="row clearfix flex">
                <!-- First Name -->
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>First Name</mat-label>
                  <input class="mat-block" [(ngModel)]="user.firstname" [userData]="'patient.firstName'" matInput
                         placeholder="{{ 'PLACEHOLDER.First Name' | translate }} " name="firstname" required/>
                </mat-form-field>

                <!-- Middle Name -->
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Middle Name</mat-label>
                  <input class="mat-block" [(ngModel)]="user.middlename" [userData]="'patient.middleName'" matInput
                         placeholder="{{ 'PLACEHOLDER.Middle Name' | translate }} " name="middlename"/>
                </mat-form-field>

                <!-- Surname -->
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>{{ 'PLACEHOLDER.Surname' | translate }} </mat-label>
                  <input class="mat-block" [(ngModel)]="user.lastname" [userData]="'patient.lastName'" matInput
                         placeholder="{{ 'PLACEHOLDER.Surname' | translate }} " name="lastfefefefename" required/>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row clearfix">
            <div class="icons-col">
              <mat-icon>phone</mat-icon>
            </div>
            <div class="row-content-col">
              <div class="row clearfix flex">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>{{ "KEYWORD.practitioner" | translate | titlecase }} Mobile</mat-label>
                  <input class="mat-block" matInput [placeholder]="('Practitioner' | translate) + ' Mobile'"
                         required/>
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>{{ "KEYWORD.practitioner" | translate | titlecase }} Email</mat-label>
                  <input class="mat-block" matInput
                         [placeholder]="('KEYWORD.practitioner' | translate | titlecase) + ' Email'" required/>
                </mat-form-field>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
      <div class="row clearfix">
        <div class="full-width step-button-row text-center">
          <button class="step-back" mat-mini-fab color="primary" awPreviousStep>
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <button class="step-send" class="btn-large" mat-raised-button color="accent" awNextStep>
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </aw-wizard-step>

  <aw-wizard-step [stepTitle]="('KEYWORD.treatment' | translate | titlecase) + ' Details'" navigationSymbol="3">
    <div class="container animated fadeInDown" id="step-3">
      <div class="row clearfix">
        <mat-card class="card treatment-card card1">
          <div class="card-header primary-gradient-img clearfix inModal">
            <div class="row clearfix">
              <div class="row titleArea clearfix">
                <mat-icon class="fas fa-file-medical-alt pull-left"></mat-icon>
                <h2 class="summary-header rm-m">
                  <!-- {{ beneficiary.CalculatedName}} -->
                  Define {{ "KEYWORD.treatment" | translate }} information &amp; add documents
                  <span class="subLabel"></span>
                </h2>
              </div>
            </div>
          </div>

          <div class="row clearfix">
            <div class="icons-col">
              <mat-icon class="fas fa-user-mat"></mat-icon>
            </div>
            <div class="row-content-col" id="defaultMes">
              <h3 class="sr-title sm-h3 rm-mt mt rm-mb">Upload {{ "KEYWORD.patient" | translate }} case files</h3>
            </div>
          </div>

          <div class="row clearfix">
            <div class="full-width">
              <div class="column">
                <!-- <h3 class="sr-title sm-h3 mt rm-mb">{{ "TreatmentPlanUpload2" | translate }}</h3>
                  <p class="small">{{ "TreatmentPlanUploadInfo" | translate }}</p> -->
                <app-file-uploader class="text-center" [isDragable]="true"></app-file-uploader>
                <!-- <app-file-list [documentTypes]="documentTypes" class="" (remove)="removeSimpleTreatmentDocument($event)"
                    [files]="simpleTreatmentDocuments" [invitationID]="invitationID" [canEdit]="true" [canAction]="false">
                  </app-file-list> -->
              </div>
            </div>
          </div>

          <div class="row clearfix mt">
            <div class="row clearfix">
              <div class="icons-col">
                <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
              </div>
              <div class="row-content-col" id="defaultMes">
                <h3 class="sr-title sm-h3 rm-m mt">Define required {{ "KEYWORD.treatment" | translate }}</h3>
                <label>Select the required {{"KEYWORD.treatment" | translate}}(s) which your
                  referred {{ "KEYWORD.patient" | translate }} will need attending to.</label>
              </div>
            </div>

            <div class="row clearfix full-width">
              <app-treatment-informed-consent-create></app-treatment-informed-consent-create>

              <div class="buttons text-right mb">
                <button class="" mat-mini-fab color="accent" matTooltip="Remove row">
                  <mat-icon>delete</mat-icon>
                </button>
                <button class="" mat-mini-fab color="primary" matTooltip="Add another row">
                  <mat-icon>add</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
      <div class="row clearfix">
        <div class="full-width step-button-row text-center">
          <button class="step-back" mat-mini-fab color="primary" awPreviousStep>
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <button class="step-send" class="btn-large" mat-raised-button color="accent" awNextStep>
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </aw-wizard-step>

  <aw-wizard-step stepTitle="Referral notes" navigationSymbol="4">
    <!--  Step 3  -->
    <div class="container animated fadeInDown" id="step-4">
      <div class="row clearfix">
        <mat-card class="card">
          <div class="card-header primary-gradient-img clearfix inModal">
            <div class="row clearfix">
              <div class="row titleArea clearfix">
                <mat-icon class="fas fa-comment pull-left"></mat-icon>
                <h2 class="summary-header rm-m">
                  <!-- {{ beneficiary.CalculatedName}} -->
                  Add additional notes &amp; Send
                  <span class="subLabel"></span>
                </h2>
              </div>
            </div>
          </div>

          <div class="row clearfix mt">
            <div class="column clearfix">
              <div class="icons-col">
                <mat-icon>email</mat-icon>
              </div>
              <div class="row-content-col" id="defaultMes">
                <div class="row clearfix">
                  <h3 class="sr-title sm-h3 rm-mt mt rm-mb pull-left">Enter information/notes regarding referral</h3>
                  <div class="defaultMsgArea pull-right">
                    <mat-icon class="pull-left">chat</mat-icon>
                    <mat-select class="full-width" class="defaultMsg btn-small pull-right" name="messageOption"
                                placeholder="Add Default Message">
                      <mat-option>
                        <p class="small rm-mt rm-mb">...</p>
                      </mat-option>
                    </mat-select>
                  </div>
                </div>

                <mat-form-field class="full-width mt">
                  <textarea class="dentMsg increase-input-field" [(ngModel)]="dentMsg" [userData]="'message.content'"
                            name="dentMsg" matInput placeholder="*Referral notes"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>

          <mat-accordion class="additional-msg">
            <mat-expansion-panel #addMsg>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-checkbox class="addMsg" [checked]="addMsg.expanded"></mat-checkbox>
                  <h3 class="sr-title sm-h3 rm-mt rm-mb pull-left">Add separate note
                    to {{"KEYWORD.patient" | translate}}</h3>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="defaultMsgArea pull-right">
                <mat-icon class="pull-left">chat</mat-icon>
                <mat-select class="full-width" class="defaultMsg btn-small pull-right" name="messageOption"
                            placeholder="Add Default Message">
                  <mat-option>
                    <p class="small rm-mt rm-mb">...</p>
                  </mat-option>
                </mat-select>
              </div>
              <div class="row clearfix">
                <label>This message will be delivered to your {{ "KEYWORD.patient" | translate }} as an email,
                  independant of
                  all other documentation
                  that is being sent to the referred {{ "KEYWORD.practitioner" | translate }}.</label>
              </div>

              <mat-form-field class="full-width mt">
                <textarea class="dentMsg increase-input-field" [userData]="'message.content'" name="dentNote" matInput
                          [placeholder]="'Enter ' + ('KEYWORD.patient' | translate) + ' note'"></textarea>
              </mat-form-field>
            </mat-expansion-panel>
          </mat-accordion>

          <div class="grey">
            <div class="row clearfix">
              <div class="half-width" id="senderName">
                <div class="column clearfix mb">
                  <div class="icons-col">
                    <mat-icon>face</mat-icon>
                  </div>

                  <div class="row-content-col">
                    <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb">Name of sender to appear with message</h3>
                    <div class="clearfix">
                      <!-- Sender Select -->
                      <mat-select class="full-width" [(ngModel)]="selectedSender" [userData]="'message.sender'"
                                  name="selectedSender" placeholder="Sender">
                        <mat-option *ngFor="let sender of senders" [value]="sender.ID">
                          {{ sender.CalculatedName }}
                        </mat-option>
                      </mat-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
      <div class="row clearfix">
        <div class="full-width step-button-row text-center">
          <button class="step-back" mat-mini-fab color="primary" awPreviousStep>
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <button class="step-send" class="btn-large" mat-raised-button color="accent" awNextStep>
            Send
            <mat-icon>send</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <!--  / Step 2 -->
  </aw-wizard-step>

  <!-- The completion step -->
  <aw-wizard-completion-step title="Send!" navigationSymbol="5">
    <!--  Step 3 - finished -->
    <div class="container" id="step-5">
      <div class="row clearfix">
        <mat-card class="card successful airplaneCard animated fadeInDown" @ngIfAnimation>
          <div class="row clearfix anim">
            <div class="full-width text-center">
              <div class="column row clearfix">
                <h1 class="sm-h1 sr-title rm-mt">Well Done!</h1>
                <h3 class="sm-h3 rm-mt">
                  Your {{ "KEYWORD.patient" | translate }}<strong>Firstname Lastname</strong> has been referred to
                  <strong>MerchantName</strong>.
                </h3>
              </div>
              <div class="row clearfix mb">
                <app-plane-animation></app-plane-animation>
              </div>
              <div class="row clearfix mb">
                <button class="step-back btn-small" (click)="cancel()" mat-raised-button color="primary">
                  <mat-icon>keyboard_arrow_left</mat-icon>
                  Dashboard
                </button>

                <button class="btn-small" (click)="viewSentReferral()" mat-raised-button color="accent">
                  View Referral Card
                  <mat-icon> keyboard_arrow_right</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
    <!--  / Step 4  -->
  </aw-wizard-completion-step>
</aw-wizard>
