<div class="row clearfix defineMessage brandEdit">
  <mat-card class="card-centered">
    <mat-dialog-content [ngClass]="{ 'no-modal-height': isModal != true }">
      <div class="card-header primary-gradient-img clearfix">
        <div class="row clearfix">
          <button class="btn-close btn-clear pull-right mat-button" *ngIf="isModal == true" (click)="closeEvent()">
            <mat-icon>clear</mat-icon>
          </button>

          <div class="pic-area pull-left mr">
            <div class="displayUploadImg text-center" *ngIf="isLogoFile == false && isEditMode == true">
              <app-ep-document-view-image
                class="clearfix full-width profile-pic-container"
                [defaultPicture]="
                  'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/brand_placeholder.png)'
                "
                [link]="logoLink"
                [hideZoomButton]="false"
              >
              </app-ep-document-view-image>
            </div>

            <div class="displayUploadImg" *ngIf="isLogoFile == true">
              <img class="display-image-uploaded" [src]="logoSrc"/>
            </div>
          </div>

          <div class="row titleArea clearfix">
            <h2 class="message-title summary-header rm-m" *ngIf="isEditMode == false">
              New {{ "KEYWORD.treatment" | translate | titlecase }} Brand</h2>
            <h2 class="message-title summary-header rm-m" *ngIf="isEditMode == true">
              Edit {{ "KEYWORD.treatment" | translate | titlecase }} Brand
              <span class="subLabel">
                {{ brand['label'] }}
              </span>
            </h2>
          </div>
        </div>
      </div>

      <div class="row clearfix flex grey-row upper mb" *ngIf="isGlobal != true && treatment">
        <div class="full-width">
          <div class="row clearfix details flex" *ngIf="treatment['Manufacturer_Key']">
            <mat-icon class="fas fa-industry mr"></mat-icon>
            <div class="row-content clicktext" matTooltip="Click to view Manufacturer details">
              <p class="small rm-m">Manufactured by </p>
              <h5 class="reduce-marg" (click)="viewSupplier(treatment['Manufacturer_Key'])">
                {{ treatment['Manufacturer.Name'] }}
              </h5>
            </div>
          </div>
        </div>
        <div class="full-width">
          <div class="row clearfix details flex" *ngIf="treatment['Distributor_Key']">
            <mat-icon class="fas fa-truck mr"></mat-icon>
            <div class="row-content clicktext" matTooltip="Click to view Distributor details">
              <p class="small rm-m">{{ 'Distributed by ' | translate }}</p>
              <h5 class="reduce-marg" (click)="viewSupplier(treatment['Distributor_Key'])">
                {{ treatment['Distributor.Name'] }}
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div class="full-width row clearfix mt">
        <form #treatmentGroup="ngForm">
          <div class="full-width clearfix" *ngIf="isDone == false">
            <div class="clearfix full-width" *ngIf="isGlobal == true">
              <div class="row clearfix">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Label</mat-label>
                  <input
                    class="mat-block"
                    [(ngModel)]="brand.label"
                    matInput
                    name="label"
                    required
                    placeholder="Label"
                  />
                </mat-form-field>
              </div>

              <div class="clearfix row full-width">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Category</mat-label>
                  <mat-select
                    class=""
                    [(ngModel)]="brand.categoryID"
                    (selectionChange)="onSelectSimpleCategory(brand.categoryID)"
                    name="category"
                    placeholder="Category"
                    required
                  >
                    <mat-option *ngFor="let c of categories" [value]="c.ID">
                      {{ treatmentUtil.getLabel(c.Label) }}
                    </mat-option>
                  </mat-select>

                  <button
                    class=""
                    *ngIf="brand.categoryID"
                    (click)="$event.stopPropagation(); viewCategory(brand.categoryID)"
                    matTooltip="View Category"
                    color="accent"
                    mat-button
                    matSuffix
                  >
                    <mat-icon>visibility</mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <div class="clearfix row full-width">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Type</mat-label>
                  <mat-select class="" [(ngModel)]="brand.typeID" name="type" placeholder="Type" required>
                    <mat-option *ngFor="let c of types" [value]="c.ID">
                      {{ treatmentUtil.getLabel(c.Label) }}
                    </mat-option>
                  </mat-select>

                  <button
                    class=""
                    *ngIf="brand.typeID"
                    (click)="$event.stopPropagation(); viewType(brand.typeID)"
                    matTooltip="View Type"
                    color="accent"
                    mat-button
                    matSuffix
                  >
                    <mat-icon>visibility</mat-icon>
                  </button>
                </mat-form-field>
              </div>

              <div class="clearfix row full-width">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Manufacturer</mat-label>
                  <mat-select
                    class=""
                    [(ngModel)]="brand.manufacturerID"
                    name="Manufacturer"
                    placeholder="Manufacturer"
                  >
                    <mat-option *ngFor="let m of manufacturers" [value]="m.ID">
                      {{ m.Name }}
                    </mat-option>
                  </mat-select>

                  <button
                    class=""
                    *ngIf="brand.manufacturerID"
                    (click)="$event.stopPropagation(); viewSupplier(brand.manufacturerID)"
                    matTooltip=" View Manufacturer"
                    color="accent"
                    mat-button
                    matSuffix
                  >
                    <mat-icon>visibility</mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <div class="clearfix row full-width">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Distributor</mat-label>
                  <mat-select class="" [(ngModel)]="brand.distributorID" name="Distributor" placeholder="Distributor">
                    <mat-option *ngFor="let d of distributors" [value]="d.ID">
                      {{ d.Name }}
                    </mat-option>
                  </mat-select>

                  <button
                    class=""
                    *ngIf="brand.distributorID"
                    (click)="$event.stopPropagation(); viewSupplier(brand.distributorID)"
                    matTooltip=" View Distributor"
                    color="accent"
                    mat-button
                    matSuffix
                  >
                    <mat-icon>visibility</mat-icon>
                  </button>
                </mat-form-field>
              </div>
            </div>

            <div class="row clearfix flex catType" *ngIf="isGlobal != true && treatment">
              <div class="block full-width" *ngIf="treatment['Category.Label']">
                <mat-icon class="fas fa-th"></mat-icon>
                <div class="row-content">
                  <p class="small rm-m">Category</p>
                  <h4 class="reduce-marg">{{ treatment['Category.Label'] }}</h4>
                </div>
              </div>

              <div class="block full-width" *ngIf="treatment['Type.Label']">
                <mat-icon class="fas fa-th-large"></mat-icon>
                <div class="row-content">
                  <p class="small rm-m">Type</p>
                  <h4 class="reduce-marg">{{ treatment['Type.Label'] }}</h4>
                </div>
              </div>
            </div>
            <!--

            <mat-form-field class="full-width" appearance="outline">
              <input matInput name="labelNote" class="mat-block" placeholder="Default activity-log label"
                [(ngModel)]="brand.labelNote">
            </mat-form-field>


            <mat-form-field class="full-width " appearance="outline">
              <textarea name="categoryNote" placeholder="Default activity-log category" matInput
                class="dentMsg increase-input-field" [(ngModel)]="brand.categoryNote" cols="30" rows="50"></textarea>
            </mat-form-field> -->

            <div class="row full-width text-center clearfix mt mb" *ngIf="isEditMode == true">
              <mat-slide-toggle [(ngModel)]="brand.isActive" name="consentPatient"> Is Active?</mat-slide-toggle>
            </div>
          </div>

          <div class="row clearfix flex full-width mb" *ngIf="isGlobal == true">
            <!--  Logo Upload -->
            <div class="uploadBlock left full-width" *ngIf="isDone != true">
              <div class="row clearfix logo-img image">
                <div class="notUploaded" *ngIf="isLogoFile == false && isEditMode == false">
                  <app-ep-document-view-image
                    class="clearfix notYetUploaded"
                    [defaultPicture]="
                      'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cloud-upload-alt-solid.png)'
                    "
                  >
                  </app-ep-document-view-image>
                </div>
                <div class="displayUploadImg text-center" *ngIf="isLogoFile == false && isEditMode == true">
                  <app-ep-document-view-image
                    class="clearfix full-width profile-pic-container"
                    [defaultPicture]="
                      'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cloud-upload-alt-solid.png)'
                    "
                    [link]="logoLink"
                    [hideZoomButton]="false"
                  >
                  </app-ep-document-view-image>
                </div>
                <div class="displayUploadImg mt" *ngIf="isLogoFile == true">
                  <img class="display-image-uploaded" [src]="logoSrc"/>
                </div>
              </div>
              <div class="row clearfix">
                <h4 class="sr-title sm-h4 rm-mb">Upload Brand logo</h4>
                <app-file-uploader
                  class="text-center clearfix full-width"
                  [allowedExtensionTypes]="['image']"
                  [isFixedAspectRatio]="true"
                  [isImageCropping]="true"
                  [canSkip]="false"
                  [limit]="1"
                  [uploadDirectly]="true"
                  [isDragable]="true"
                  [title]="'CLICK HERE to upload'"
                  (onCompleteAll)="onCompleteLogoAll($event)"
                >
                </app-file-uploader>
              </div>
            </div>
          </div>

          <hr class="mt" *ngIf="isGlobal == false"/>

          <mat-dialog-actions *ngIf="isDone == false">
            <hr class="clearfix"/>
            <div class="row clearfix text-center full-width">
              <button class="" *ngIf="isModal == true" (click)="closeEvent()" mat-raised-button color="primary">
                Close
              </button>
              <button
                class=""
                *ngIf="isEditMode != true"
                [disabled]="!treatmentGroup.form.valid"
                (click)="save()"
                mat-raised-button
                color="accent"
              >
                Save
                <mat-icon>check</mat-icon>
              </button>
              <button
                class=""
                *ngIf="isEditMode == true"
                [disabled]="!treatmentGroup.form.valid"
                (click)="edit()"
                mat-raised-button
                color="accent"
              >
                Save
                <mat-icon>check</mat-icon>
              </button>
            </div>
          </mat-dialog-actions>

          <div *ngIf="isDone == true">
            <div class="container clearfix animated fadeInDown" id="step-4">
              <div class="row clearfix text-center">
                <h2 class="sr-title inline-block rm-mt" tabindex="0">
                  <span *ngIf="isEditMode == false">Brand created!</span>
                  <span *ngIf="isEditMode == true">Brand details updated!</span>
                </h2>
              </div>
              <div class="row clearfix">
                <div class="full-width text-center">
                  <hr class="mt"/>
                  <div class="svg-box">
                    <svg class="circular green-stroke">
                      <circle class="path" cx="75" cy="75" r="50" fill="none" stroke-width="5" stroke-miterlimit="10" />
                    </svg>
                    <svg class="checkmark green-stroke">
                      <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                        <path
                          class="checkmark__check"
                          fill="none"
                          d="M616.306,283.025L634.087,300.805L673.361,261.53"
                        />
                      </g>
                    </svg>
                  </div>

                  <div class="row clearfix">
                    <div class="full-width text-center step-button-row">
                      <button
                        class="step-next"
                        *ngIf="isModal == true"
                        (click)="closeEvent()"
                        mat-raised-button
                        color="primary"
                      >
                        Close
                      </button>

                      <button
                        class="step-next"
                        *ngIf="brandID"
                        (click)="viewBrand(brandID)"
                        mat-raised-button
                        color="accent"
                      >
                        <mat-icon>visibility</mat-icon>
                        View
                      </button>

                      <button
                        class="step-next"
                        *ngIf="isModal != true"
                        (click)="list()"
                        mat-raised-button
                        color="accent"
                      >
                        <mat-icon>list</mat-icon>
                        View Brand list
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </mat-dialog-content>
  </mat-card>
</div>
