import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { PersonalCircumstancesService } from '../shared/personal-circumstances.service';

@Component({
  selector: 'app-pci-house-residence',
  templateUrl: './house-residence.component.html',
  styleUrls: ['./house-residence.component.css'],
})
export class PCIHouseResidenceComponent implements OnInit {
  @Input()
  personalCirID;

  @Input()
  pciEntryObj;

  @Input()
  disabled = false;

  @Output()
  close = new EventEmitter();

  @Output()
  getResult = new EventEmitter();

  isModal = false;

  pcDetail = {
    updatedReason: null,
    updatedComment: null,
    infoDetails: null,
    infoYearsAtAddress: null,
    infoMonthSAtAddress: null,
    infoStartYear: null,
    infoStartMonth: null,
    infoCurrent: null,
    infoHousingModeCode: null,
    infoWhoOwnsItCode: null,
    inforNumberOfOwners: null,
    infoAgentName: null,
    infoPropertyManager: null,
    infoAgentContact: null,
    infoMarketValue: null,
    infoEquitPercent: null,
    infoEquitAmount: null,
    infoBankName: null,
    infoBankCode: null,
    infoTypeOfLoanCode: null,
    infoAmountborrowed: null,
    infoFacilityLimit: null,
    assetValue: null,
    liabilityValue: null,
    expenseValue: null,
    expenseFrequency: null,
    infoBoardeeName: null,
    infoBoardeePhone: null,
    infoBoardeeRelationship: null,
    addressUnit: null,
    addressStreetNumber: null,
    addressStreetName: null,
    addressStreetType: null,
    addressStreetSuburb: null,
    addressStreetPostCode: null,
    addressStreetState: null,
    fileID: null,
    modifiedField: null,
  };

  isPromoterOrAdmin = false;

  persCircumUpdReasons = [];

  housingModeList = [];
  propertyOwnerList = [];
  bankList = [];
  homeLoanTypeList = [];

  frequencies = [];

  isValidAddress = false;

  constructor(
    private authenticationService: AuthenticationService,
    private pciService: PersonalCircumstancesService,
    private lookupService: LookupService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.personalCirID) {
        this.personalCirID = data.personalCirID;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
      if (this.isPromoterOrAdmin == true) {
        this.getDetails();

        this.lookup();
      }
    });
  }

  lookup() {
    this.lookupService.getLookup('CodeLookup', 'PersCircumUpdReason').subscribe((res) => {
      if (res) {
        this.persCircumUpdReasons = res;
      }
    });

    this.lookupService.getLookup('CodeLookup', 'Housing').subscribe((res) => {
      if (res) {
        this.housingModeList = res;
      }
    });

    this.lookupService.getLookup('CodeLookup', 'PropertyOwner').subscribe((res) => {
      if (res) {
        this.propertyOwnerList = res;
      }
    });

    this.lookupService.getLookup('CodeLookup', 'HomeLoanType').subscribe((res) => {
      if (res) {
        this.homeLoanTypeList = res;
      }
    });

    this.pciService.getBankList().subscribe((res) => {
      if (res) {
        this.bankList = res;
      }
    });

    this.lookupService.getLookup('CodeLookup', 'Frequency').subscribe((res) => {
      if (res) {
        this.frequencies = res;
      }
    });
  }

  submit() {
    this.pciService.editPCIHouseResidence(this.pciEntryObj['ID'], this.pcDetail).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast('success', 'Modify record', 'You have successfully modified this record');

        this.pciEntryObj = res;

        this.getDetails();

        const modifiedField = this.pciEntryObj['Modified_Fields'].split('|');
        for (let i = 0; i < modifiedField.length; i++) {
          this.highlightField(modifiedField[i]);
        }

        this.getResult.emit(res);
      }
    });
  }

  create() {
    this.pcDetail['personalCircumstanceID'] = this.personalCirID;

    this.pciService.createPCIHouseResidence(this.pcDetail).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast('success', 'Create record', 'You have successfully created a record');

        this.getResult.emit(res);
      }
    });
  }

  closeModalEvent() {
    this.close.emit(true);
  }

  getDetails() {
    if (this.pciEntryObj) {
      if (this.pciEntryObj['Updating_Reason.Code']) {
        this.pcDetail.updatedReason = this.pciEntryObj['Updating_Reason.Code'];
      }

      if (this.pciEntryObj['Updating_Reason.Comment']) {
        this.pcDetail.updatedComment = this.pciEntryObj['Updating_Reason.Comment'];
      }

      if (this.pciEntryObj['infoDetails']) {
        this.pcDetail.infoDetails = this.pciEntryObj['infoDetails'];
      }

      if (this.pciEntryObj['Info.YearsAtAddress']) {
        this.pcDetail.infoYearsAtAddress = this.pciEntryObj['Info.YearsAtAddress'];
      }

      if (this.pciEntryObj['Info.MonthsAtAddress']) {
        this.pcDetail.infoMonthSAtAddress = this.pciEntryObj['Info.MonthsAtAddress'];
      }

      if (this.pciEntryObj['Info.StartYear']) {
        this.pcDetail.infoStartYear = this.pciEntryObj['Info.StartYear'];
      }

      if (this.pciEntryObj['Info.StartMonth']) {
        this.pcDetail.infoStartMonth = this.pciEntryObj['Info.StartMonth'];
      }

      if (this.pciEntryObj['Info.Current']) {
        this.pcDetail.infoCurrent = this.pciEntryObj['Info.Current'];
      }

      if (this.pciEntryObj['Info.HousingMode.Code']) {
        this.pcDetail.infoHousingModeCode = this.pciEntryObj['Info.HousingMode.Code'];
      }

      if (this.pciEntryObj['Info.WhoOwnsIt.Code']) {
        this.pcDetail.infoWhoOwnsItCode = this.pciEntryObj['Info.WhoOwnsIt.Code'];
      }

      if (this.pciEntryObj['Info.NumberOfOwners']) {
        this.pcDetail.inforNumberOfOwners = this.pciEntryObj['Info.NumberOfOwners'];
      }

      if (this.pciEntryObj['Info.AgentName']) {
        this.pcDetail.infoAgentName = this.pciEntryObj['Info.AgentName'];
      }

      if (this.pciEntryObj['Info.PropertyManager']) {
        this.pcDetail.infoPropertyManager = this.pciEntryObj['Info.PropertyManager'];
      }

      if (this.pciEntryObj['Info.AgentContact']) {
        this.pcDetail.infoAgentContact = this.pciEntryObj['Info.AgentContact'];
      }

      if (this.pciEntryObj['Info.MarketValue']) {
        this.pcDetail.infoMarketValue = this.pciEntryObj['Info.MarketValue'];
      }

      if (this.pciEntryObj['Info.EquityPercent']) {
        this.pcDetail.infoEquitPercent = this.pciEntryObj['Info.EquityPercent'];
      }

      if (this.pciEntryObj['Info.EquityAmount']) {
        this.pcDetail.infoEquitAmount = this.pciEntryObj['Info.EquityAmount'];
      }

      if (this.pciEntryObj['Info.Bank.Code']) {
        this.pcDetail.infoBankCode = this.pciEntryObj['Info.Bank.Code'];
      }

      if (this.pciEntryObj['Info.TypeOfLoan.Code']) {
        this.pcDetail.infoTypeOfLoanCode = this.pciEntryObj['Info.TypeOfLoan.Code'];
      }

      if (this.pciEntryObj['Info.AmountInitiallyBorrowed']) {
        this.pcDetail.infoAmountborrowed = this.pciEntryObj['Info.AmountInitiallyBorrowed'];
      }

      if (this.pciEntryObj['Info.FacilityLimit']) {
        this.pcDetail.infoFacilityLimit = this.pciEntryObj['Info.FacilityLimit'];
      }

      if (this.pciEntryObj['Asset.Value']) {
        this.pcDetail.assetValue = this.pciEntryObj['Asset.Value'];
      }

      if (this.pciEntryObj['Liability.Value']) {
        this.pcDetail.liabilityValue = this.pciEntryObj['Liability.Value'];
      }

      if (this.pciEntryObj['Expense.Value']) {
        this.pcDetail.expenseValue = this.pciEntryObj['Expense.Value'];
      }

      if (this.pciEntryObj['Expense.Frequency']) {
        this.pcDetail.expenseFrequency = this.pciEntryObj['Expense.Frequency'];
      }

      if (this.pciEntryObj['Info.Boardee.Name']) {
        this.pcDetail.infoBoardeeName = this.pciEntryObj['Info.Boardee.Name'];
      }

      if (this.pciEntryObj['Info.Boardee.Phone']) {
        this.pcDetail.infoBoardeePhone = this.pciEntryObj['Info.Boardee.Phone'];
      }

      if (this.pciEntryObj['Info.Boardee.Relationship']) {
        this.pcDetail.infoBoardeeRelationship = this.pciEntryObj['Info.Boardee.Relationship'];
      }

      if (this.pciEntryObj['Modified_Fields']) {
        this.pcDetail.modifiedField = this.pciEntryObj['Modified_Fields'];
      }

      if (this.pciEntryObj['Address.Suburb']) {
        this.pcDetail.addressStreetSuburb = this.pciEntryObj['Address.Suburb'];
      }

      if (this.pciEntryObj['Address.State']) {
        this.pcDetail.addressStreetState = this.pciEntryObj['Address.State'];
      }

      if (this.pciEntryObj['Address.Postcode']) {
        this.pcDetail.addressStreetPostCode = this.pciEntryObj['Address.Postcode'];
      }

      if (this.pciEntryObj['Address.StreetNr']) {
        this.pcDetail.addressStreetNumber = this.pciEntryObj['Address.StreetNr'];
      }

      if (this.pciEntryObj['Address.StreetName']) {
        this.pcDetail.addressStreetName = this.pciEntryObj['Address.StreetName'];
      }

      if (this.pciEntryObj['Address.Unit']) {
        this.pcDetail.addressUnit = this.pciEntryObj['Address.Unit'];
      }

      if (this.pciEntryObj['Address.StreetType.Abbreviated']) {
        this.pcDetail.addressStreetType = this.pciEntryObj['Address.StreetType.Abbreviated'];
      }
    }
  }

  getResidenceAddress(address) {
    if (address) {
      this.pcDetail.addressUnit = address.unitNumber;
      this.pcDetail.addressStreetNumber = address.streetNumber;
      this.pcDetail.addressStreetName = address.streetName;
      this.pcDetail.addressStreetType = address.streetType;
      this.pcDetail.addressStreetSuburb = address.suburb;
      this.pcDetail.addressStreetState = address.state;
      this.pcDetail.addressStreetPostCode = address.postCode;
    }
  }

  isResidenceAddressValidEvent(v) {
    this.isValidAddress = v;
  }

  onCompleteAll(e) {
    if (e && e[0]) {
      this.pcDetail.fileID = e[0];
    }
  }

  removeFile(e) {
    if (e && e == this.pcDetail.fileID) {
      this.pcDetail.fileID = null;
    }
  }

  highlightField(field) {
    let result = '';

    if (this.isModal == false && this.pcDetail.modifiedField) {
      const _modifiedField = this.pcDetail.modifiedField.split('|');

      if (_modifiedField.indexOf(field) != -1) {
        result = 'highlight';
      }
    }

    return result;
  }

  ownerChange(e) {
    if (e == 'CUS') {
      this.pcDetail.infoEquitPercent = 100;
    } else if (e == 'CSP') {
      this.pcDetail.infoEquitPercent = 50;
    }

    // this.equityPercentChangeEvent();

    if (this.pcDetail.infoMarketValue) {
      this.calcuateEquityValue(this.pcDetail.infoEquitPercent);
    }
  }

  calcuateEquityPercent(e) {
    this.pcDetail.infoEquitPercent = 100 / e;

    // this.equityPercentChangeEvent();

    if (this.pcDetail.infoMarketValue) {
      this.calcuateEquityValue(this.pcDetail.infoEquitPercent);
    }
  }

  calcuateEquityValue(e) {
    this.pcDetail.assetValue = this.pcDetail.infoMarketValue * (this.pcDetail.infoEquitPercent / 100);
  }
}
