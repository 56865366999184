<div class="full-width mb clearfix" *ngIf="subFeature && subFeature.ID">
  <div class="main-banner blue-banner" *ngIf="featureType != 'consumer'"></div>

  <div class="row clearfix container mt ml mr">
    <div class="clearfix container-inner-parent">
      <div class="clearfix full-width mt">
        <div class="guid guid-right flex clearfix full-width">
          <div class="container full-width guid-main" @ngIfAnimation data-aos="fade-right" data-aos-delay="400"
            data-aos-once="true">
            <div class="full-width txt-box">
              <div class="row clearfix breadcrumbs">
                <ul>
                  <li class="link" [routerLink]="[root, { outlets: { page: ['main-feature-list', featureType] } }]">
                    Features <mat-icon class="fas fa-chevron-right"></mat-icon>
                  </li>
                  <li class="link" [routerLink]="[
                      root,
                      { outlets: { page: ['main-feature-view', subFeature.Feature_MainLabel_key, featureType] } }
                    ]">
                    {{ subFeature.MainLabel }}
                    <mat-icon class="fas fa-chevron-right"></mat-icon>
                  </li>
                  <li>{{ subFeature.SubLabel }}</li>
                </ul>
              </div>
              <div class="full-width flex-cust" @ngIfAnimation data-aos="fade-left" data-aos-delay="400"
                data-aos-once="true">
                <div class="full-width text-container">
                  <div class="txt">
                    <h1 class="text-left header-title-sub-list rm-mb smb">{{ subFeature.SubLabel }}</h1>

                    <div class="text-left header-description-sub-list-container"
                      [innerHTML]="subFeature.Description | safeHtml"></div>
                  </div>
                </div>
                <div *ngIf="context!='p4p'" class="img-box-left"></div>
                <div *ngIf="context=='p4p'" class="img-box-left p4p"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="full-width" style="position: relative">
      <button class="pull-right btn action-button3" *ngIf="isPromoterOrAdmin == true"
        [matMenuTriggerFor]="ActionBtnmenu" mat-icon-button color="accent">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #ActionBtnmenu="matMenu">
        <button (click)="quickEdit(subFeature['ID'])" mat-menu-item color="">
          <mat-icon>save</mat-icon> Quick Edit
        </button>

        <button (click)="edit(subFeature['ID'])" mat-menu-item color="">
          <mat-icon>edit</mat-icon>
          Edit
        </button>

        <button *ngIf="subFeature && subFeature.FE_Code && subFeature.Is_Deactivated !== '1'"
          [matMenuTriggerFor]="links" mat-menu-item>
          <mat-icon>share</mat-icon> Copy Links
        </button>
        <mat-menu #links="matMenu">
          <button *ngIf="
              publicSiteLink && subFeature && subFeature.Is_Private !== '1' && subFeature.Is_Consumer_Feature === '1'
            " (click)="copy(publicSiteLink + '/consumer/(page:sub-feature-code/' + subFeature.FE_Code + '/consumer)')"
            mat-menu-item>
            Public Site: <strong>Consumer</strong>
          </button>

          <button *ngIf="
              publicSiteLink && subFeature && subFeature.Is_Private !== '1' && subFeature.Is_Merchant_Feature === '1'
            " (click)="copy(publicSiteLink + '/partners/(page:sub-feature-code/' + subFeature.FE_Code + '/merchant)')"
            mat-menu-item>
            Public Site: <strong>Partner</strong>
          </button>

          <hr />
          <button *ngIf="mainAppLink && subFeature && subFeature.Is_Consumer_Feature === '1'"
            (click)="copy(mainAppLink + '/consumer/(page:sub-feature-code/' + subFeature.FE_Code + '/consumer)')"
            mat-menu-item>
            Main Application: <strong>Consumer</strong>
          </button>

          <button *ngIf="mainAppLink && subFeature && subFeature.Is_Merchant_Feature === '1'"
            (click)="copy(mainAppLink + '/merchant/(page:sub-feature-code/' + subFeature.FE_Code + '/merchant)')"
            mat-menu-item>
            Main Application: <strong>Merchant</strong>
          </button>
        </mat-menu>

        <hr />

        <button (click)="delete(subFeature['ID'])" mat-menu-item color="">
          <mat-icon>delete</mat-icon>
          Remove
        </button>
      </mat-menu>

      <app-feature-sub-label-cover-view [subFeatureLabelID]="subFeature.ID" [LastModified]="subFeature.LastModified">
      </app-feature-sub-label-cover-view>
    </div>

    <div class="full-width flex">
      <mat-card class="full-width row card card-container">
        <read-more class="full-width clearfix read-more-sub-label" [header]="subFeature.Description"
          [text]="htmlContent" [loading]="loading" [design]="'mode2'" [hideButtonForce]="true" [isCollapsed]="false"
          [maxLength]="10000">
        </read-more>
      </mat-card>
    </div>

    <div class="full-width mb">
      <button class="btn-large pull-left"
        *ngIf="previousFeature && previousFeature.ID && previousFeature.isMain != true"
        (click)="viewSubRedirect(previousFeature.ID)" mat-raised-button color="accent">
        <mat-icon class="fas fa-chevron-left smr"></mat-icon>

        <span>{{ previousFeature.SubLabel }} </span>
      </button>

      <button class="btn-large pull-left"
        *ngIf="previousFeature && previousFeature.ID && previousFeature.isMain == true"
        (click)="viewSubRedirect(previousFeature.ID)" mat-raised-button color="primary">
        <mat-icon class="fas fa-chevron-left smr"></mat-icon>

        <span>{{ previousFeature.SubLabel }} </span>
      </button>

      <button class="btn-large pull-right" *ngIf="nextFeature && nextFeature.ID && nextFeature.isMain != true"
        (click)="viewSubRedirect(nextFeature.ID)" mat-raised-button color="accent">
        <span> {{ nextFeature.SubLabel }} </span>
        <mat-icon class="fas fa-chevron-right sml"></mat-icon>
      </button>

      <button class="btn-large pull-right" *ngIf="nextFeature && nextFeature.ID && nextFeature.isMain == true"
        (click)="viewSubRedirect(nextFeature.ID)" mat-raised-button color="primary">
        <span> {{ nextFeature.SubLabel }} </span>
        <mat-icon class="fas fa-chevron-right sml"></mat-icon>
      </button>
    </div>
  </div>
</div>

<app-card-footer class="mt full-width" *ngIf="featureType != 'consumer'" [featureType]="featureType"
  [firstBackgroundWhite]="false" [items]="['features', 'demo']">
</app-card-footer>
