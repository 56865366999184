import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-inner-html-isolated-modal',
  templateUrl: './inner-html-isolated-modal.component.html',
  styleUrls: ['./inner-html-isolated-modal.component.css']
})
export class InnerHtmlIsolatedModalComponent implements OnInit {
  @Input()
  content;

  @Output()
  close = new EventEmitter();
  @Input()
  title = 'Preview Report ';
  @Input()
  description;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && data.content) {
      this.content = data.content;
    }

    if (data && data.title) {
      this.title = data.title;
    }

    if (data && data.description) {
      this.description = data.description;
    }
  }

  ngOnInit() {}

  closeEvent() {
    this.close.emit(true);
  }
}
