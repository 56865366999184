<div class="card-header primary-gradient-img clearfix inModal lookup">
  <div class="row clearfix">
    <div class="row titleArea clearfix">
      <mat-icon class="fas fa-user-mat pull-left"></mat-icon>
      <h2 class="summary-header rm-m">
        Select a  {{ "KEYWORD.practitioner" | translate }}/specialist
        <span class="subLabel"
        >Either select an existing  {{ "KEYWORD.practitioner" | translate }}/specialist, or enter a new one to refer your {{ "KEYWORD.patient" | translate }}
          to.</span
        >
      </h2>
    </div>
  </div>
</div>
<div class="row clearfix flex">
  <!-- close this modal, display merchant lookup modal -->
  <button class="full-width btn-large" (click)="selectMerch()" mat-raised-button color="primary">
    <mat-icon class="fas fa-users"></mat-icon> Select existing
  </button>
  <!-- close this modal, enter new merchant -->
  <button class="full-width btn-large" (click)="newMerch()" mat-raised-button color="accent">
    <mat-icon class="fas fa-user-plus"></mat-icon> Create new
  </button>
</div>
