import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { TagsService } from '../shared/tags.service';

@Component({
  selector: 'app-tag-create-edit',
  templateUrl: './tag-create-edit.component.html',
  styleUrls: ['./tag-create-edit.component.css'],
})
export class TagCreateEditComponent implements OnInit {
  @Input()
  merchantID;

  checkIsDone = false;
  @Input()
  tagID;

  @Input()
  label;

  @Input()
  type = 'Tag';

  @Input()
  staticAudience = false;

  nature = 'Tag';
  @Output()
  getResult = new EventEmitter();

  description;

  isModal = false;

  close = new EventEmitter();

  tag = {
    label: null,
    description: null,
  };
  separatorKeysCodes: number[] = [ENTER, COMMA];
  isUnique = true;
  sessionType;

  constructor(
    private tagsService: TagsService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.merchantID != null) {
        this.merchantID = data.merchantID;
      }
      if (data.tagID != null) {
        this.tagID = data.tagID;
      }
      if (data.label != null) {
        this.tag.label = data.label;
      }
      if (data.description != null) {
        this.tag.description = data.description;
      }

      if (data.type != null) {
        this.type = data.type;
      }

      if (data.staticAudience != null) {
        this.staticAudience = data.staticAudience;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((res) => {
      this.sessionType = res;

      if (this.staticAudience === true) {
        this.nature = 'Static Audience';
      } else {
        this.nature = 'Tag';
      }
      this.setup();
    });
  }

  setup() {
    if (this.tagID) {
      this.tagsService.getOneTag(this.tagID, {}, this.sessionType).subscribe((res) => {
        if (res) {
          if (res.Label) {
            this.tag.label = res.Label;
            this.checkIsDone = true;
          }

          if (res.Description) {
            this.tag.description = res.Description;
          }

          if (res.Type) {
            this.type = res.Type;
          }

          if (res.ID) {
            this.tagID = res.ID;
          }
        }
      });
    } else if (this.label) {
      this.tagsService.getOneTagByLabel(this.label, {}, this.sessionType).subscribe((res) => {
        if (res) {
          if (res.Label) {
            this.tag.label = res.Label;
            this.checkIsDone = true;
          }

          if (res.Description) {
            this.tag.description = res.Description;
          }

          if (res.ID) {
            this.tagID = res.ID;
          }

          if (res.Type) {
            this.type = res.Type;
          }
        }
      });
    }
  }

  createTag() {
    if (this.tagID) {
      const payload = {
        label: this.tag.label,
        description: this.tag.description,
        type: this.type,
        staticAudience: this.staticAudience,
      };

      if (this.merchantID) {
        payload['merchantID'] = this.merchantID;
      }

      this.tagsService.modifyTag(this.tagID, payload, this.sessionType).subscribe((res) => {
        if (res) {
          NotifyAppComponent.displayToast(
            'success',
            'Modify ' + this.nature,
            'You have successfully Modified the ' + this.nature
          );

          this.getResult.emit(res);
        }
      });
    } else {
      const payload = {
        label: this.tag.label,
        description: this.tag.description,
        type: this.type,
        staticAudience: this.staticAudience,
      };

      if (this.merchantID) {
        payload['merchantID'] = this.merchantID;
      }

      this.tagsService.createTag(payload, this.sessionType).subscribe((res) => {
        if (res) {
          NotifyAppComponent.displayToast(
            'success',
            'Create ' + this.nature,
            'You have successfully create a new ' + this.nature
          );

          this.getResult.emit(res);
        }
      });
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  verifyUnique() {
    this.isUnique = false;
    this.checkIsDone = false;

    if (this.tag && this.tag.label) {
      this.tagsService.isUniqueTag(this.tag.label, this.sessionType).subscribe((res) => {
        if (res == false) {
          this.isUnique = false;
        } else {
          this.isUnique = true;
        }
        this.checkIsDone = true;
      });
    }
  }
}
