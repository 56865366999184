import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import * as _ from 'lodash';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ColorPalette } from '../../../shared/types/color-palette';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { SubscriptionPlanService } from '../shared/subscription-plan.service';

@Component({
  selector: 'app-subscription-plan-create-edit',
  templateUrl: './subscription-plan-create-edit.component.html',
  styleUrls: ['./subscription-plan-create-edit.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class SubscriptionPlanCreateEditComponent implements OnInit {
  @Input()
  subscriptionPlanID;

  @ViewChild('wizard', { static: false }) wizard: WizardComponent;

  subscriptionPlan;
  updateUsers = false;
  isModal = false;
  color = Settings.global['primaryColor'] || '#1e88e5';
  colorSecond;
  _colorSecond;
  _color = Settings.global['primaryColor'] || '#1e88e5';

  status = null;
  isActive = true;
  marketingDescriptions = [null];
  _marketingDescriptions = [null];

  isPublic = true;
  isRecommended = false;

  root = '/merchant';
  loadMainLabel = false;
  moduleCodes = [];
  subFeatureIDs = [];
  subFeatureLimitedIDs = [];

  useExternalSignupLink=false;
  externalSignupLink

  label;
  description;
  price;
  waivedPeriod = Number(Settings.global['subscriptionPlanWaivedPeriod'] || 0);
  minTreatmentPlan = Number(Settings.global['subscriptionPlanMinTreatmentPlan'] || 0);
  isFreeSMS = true;
  smsPrice;

  isDefault = false;

  isChanged = false;
  createdRecord;

  pinchZoomProperties = [
    {
      wheel: true,
      autoHeight: true,
    },
  ];
  webDescription;
  settings = Settings.global;
  utils = new UtilsClass();
  postPictureLink;
  postPicture;
  loadingPost = false;
  postPictureLinkThumbnail;
  postPictureThumbnail;
  fileIDThumbnail;
  fileID;

  changeSelectionFeature = false;
  changeSelectionModule = false;

  maxFileSize = 5;
  allowExtensions = ['jpeg', 'jpg', 'png','heic', 'gif', 'tiff', 'bmp', 'ico'];
  applyDownload = new EventEmitter();
  sessionType;

  selectedModuleCodes = [];
  selectedSubFeatureIDs = [];
  selectedSubFeatureLimitedIDs = [];

  currentPlan;
  currentPlanID;
  useDefaultColor = false;

  loadModule = false;
  loadFeature = false;

  constructor(
    private subscriptionPlanService: SubscriptionPlanService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    UtilsClass.scrollUp();
    this.activeRoute.params.subscribe((params) => {
      if (params['subscriptionPlanID']) {
        this.subscriptionPlanID = params['subscriptionPlanID'];
      }

      this.subscriptionPlanService.getPromoterPlanCurrent().subscribe((currentPlan) => {
        if (currentPlan) {
          this.currentPlan = currentPlan;
          if (currentPlan && currentPlan.ID) {
            this.currentPlanID = currentPlan.ID;
          }
        }

        this.authenticationService.getSessionType().subscribe((res) => {
          this.sessionType = res;

          this.setup();
        });
      });
    });
  }

  setup() {
    if (this.subscriptionPlanID) {
      this.subscriptionPlanService
        .getPromoterPlanFullDetails(this.subscriptionPlanID, {}, this.sessionType)
        .subscribe((res) => {
          if (res && res.ID) {
            this.subscriptionPlan = res;
            this.buildModule();
            this.loadingPost = true;
            this.subscriptionPlanService.getPromoterPlanCoverImage(res.ID).subscribe((res) => {
              if (res) {
                this.postPicture = res;
              } else {
                this.postPicture = null;
              }
              this.loadingPost = false;
            });
            this.postPictureThumbnail = this.subscriptionPlanService.getPromoterPlanThumbnailImageStreamLink(
              res.ID,
              res.LastModified
            );
          }
        });
    }
  }

  buildModule() {
    if (this.subscriptionPlan && this.subscriptionPlan.ID) {
      this.subscriptionPlanID = this.subscriptionPlan.ID;

      if (this.subscriptionPlan['modulesCodes'] && this.subscriptionPlan['modulesCodes'].length > 0) {
        this.selectedModuleCodes = JSON.parse(JSON.stringify(this.subscriptionPlan['modulesCodes']));
        // this.moduleCodes = this.selectedModuleCodes;
      }

      if (this.subscriptionPlan['subFeatureIDs'] && this.subscriptionPlan['subFeatureIDs'].length > 0) {
        this.selectedSubFeatureIDs = JSON.parse(JSON.stringify(this.subscriptionPlan['subFeatureIDs']));
        // this.subFeatureIDs = this.selectedSubFeatureIDs;
      }
      if (this.subscriptionPlan['subFeatureLimitedIDs'] && this.subscriptionPlan['subFeatureLimitedIDs'].length > 0) {
        this.selectedSubFeatureLimitedIDs = JSON.parse(JSON.stringify(this.subscriptionPlan['subFeatureLimitedIDs']));
        // this.subFeatureLimitedIDs = this.selectedSubFeatureLimitedIDs;
      }


      if (this.subscriptionPlan['Use_External_Signup_Link']==='1') {
        this.useExternalSignupLink=true;
        this.externalSignupLink=this.subscriptionPlan['External_Signup_Link'];
      }
      else {
        this.useExternalSignupLink=false;
        this.externalSignupLink=this.subscriptionPlan['External_Signup_Link']
      }



      if (this.subscriptionPlan['Web_Features']) {
        this.webDescription = this.subscriptionPlan['Web_Features'];
      }
      if (this.subscriptionPlan['Web_Label']) {
        this.label = this.subscriptionPlan['Web_Label'];
      }
      if (this.subscriptionPlan['Web_Description']) {
        this.description = this.subscriptionPlan['Web_Description'];
      }
      if (this.subscriptionPlan['Marketing_Description']) {
        this.marketingDescriptions = this.subscriptionPlan['Marketing_Description'].split('||');
        this._marketingDescriptions = this.subscriptionPlan['Marketing_Description'].split('||');
      }

      if (this.subscriptionPlan['Waived_PeriodInDays']) {
        this.waivedPeriod = this.subscriptionPlan['Waived_PeriodInDays'];
      }

      if (this.subscriptionPlan['Min_TreatmentPlans_Sent']) {
        this.minTreatmentPlan = this.subscriptionPlan['Min_TreatmentPlans_Sent'];
      }

      if (this.subscriptionPlan['Price']) {
        this.price = this.subscriptionPlan['Price'];
      }

      if (this.subscriptionPlan['Color']) {
        this.useDefaultColor = false;
        const split = this.subscriptionPlan['Color'].split('|');
        if (split[0]) {
          this.color = split[0];
          this._color = this.color;
        }

        if (split[1]) {
          this.colorSecond = split[1] || null;
          this._colorSecond = this.colorSecond;
        }

        if (this.color && !this.colorSecond) {
          this.onColorPickerClose(this.color);
        }
      } else if (this.color && !this.colorSecond) {
        this.onColorPickerClose(this.color);
      }
      if (this.subscriptionPlan['SMS_Price']) {
        this.smsPrice = Number(this.subscriptionPlan['SMS_Price']);
      }
      if (this.subscriptionPlan['Is_SMS_Free'] === '1') {
        this.isFreeSMS = true;
      } else {
        this.isFreeSMS = false;
      }
      if (this.subscriptionPlan['Is_Public'] !== '1') {
        this.isPublic = false;
      } else {
        this.isPublic = true;
      }

      if (this.subscriptionPlan['Is_Default'] === '1') {
        this.isDefault = true;
      } else {
        this.isDefault = false;
      }

      if (this.subscriptionPlan['Recommended_Plan'] === 'Yes') {
        this.isRecommended = true;
      } else {
        this.isRecommended = false;
      }

      if (this.subscriptionPlan['Status.Code']) {
        this.status = this.subscriptionPlan['Status.Code'];
      }

      if (this.subscriptionPlan['Is_Active'] === '0') {
        this.isActive = false;
      } else {
        this.isActive = true;
      }
    } else {
      if (this.color && !this.colorSecond) {
        this.onColorPickerClose(this.color);
      }
    }
  }

  onColorPickerClose2(color: string): void {
    if (color) {
      this.colorSecond = color;
    }
  }

  changeDefaultColor() {
    if (this.useDefaultColor === true) {
      this.color = Settings.global['primaryColor'] || '#1e88e5';
      this._color = Settings.global['primaryColor'] || '#1e88e5';
      this.onColorPickerClose(this.color);
    }
  }

  openLink(link) {
    if (link) {
      window.open(link, '_blank');
    }
  }

  onCompleteThumbnail(fileID) {
    if (fileID && fileID[0]) {
      this.fileIDThumbnail = fileID[0];
      this.postPictureThumbnail = null;
      this.postPictureLinkThumbnail = this.utils.getFileLink(this.fileIDThumbnail);
    } else {
      this.postPictureLinkThumbnail = null;
    }
  }

  planList() {
    this.router.navigate([this.root, { outlets: { page: ['subscription-plan-management'] } }]);
  }

  step1() {
    this.wizard.goToStep(0);
  }

  step2() {
    this.wizard.goToStep(1);
  }

  step3() {
    this.wizard.goToStep(2);
  }

  onComplete(fileID) {
    if (fileID && fileID[0]) {
      this.fileID = fileID[0];
      this.postPicture = null;
      this.postPictureLink = this.utils.getFileLink(this.fileID);
    } else {
      this.postPictureLink = null;
    }
  }

  downloadImage() {
    this.applyDownload.emit(true);
  }

  isIndexOf(s, attribute) {
    if (s && attribute && s.indexOf(attribute) != -1) {
      return true;
    }
    return false;
  }

  addMarketingDescription() {
    this.marketingDescriptions.push(null);
    this._marketingDescriptions.push(null);
  }

  removeMarketingDescription(index) {
    this.marketingDescriptions.splice(index, 1);
    this._marketingDescriptions.splice(index, 1);
  }

  changeMarketing(index, value) {
    this._marketingDescriptions[index] = value;
  }

  onColorPickerClose(color: string): void {
    if (color) {
      this.color = color;

      const v = new ColorPalette(color);
      const palette = v.getPalette();

      if (palette && palette.length > 0) {
        const index = palette.findIndex((item) => {
          if (item && item.name == 800) {
            return true;
          } else {
            return false;
          }
        });

        if (palette[index] && palette[index]['hex']) {
          this.colorSecond = palette[index]['hex'];
          this._colorSecond = this.colorSecond;
        }
      }
    }
  }

  proceed() {
    UtilsClass.scrollUp();
    this.wizard.goToNextStep();
  }

  stepBack() {
    UtilsClass.scrollUp();
    this.wizard.goToPreviousStep();
  }

  proceedModule() {
    UtilsClass.scrollUp();
    this.wizard.goToNextStep();

    this.loadModule = true;
  }

  proceedFeature() {
    UtilsClass.scrollUp();
    this.wizard.goToNextStep();

    this.loadFeature = true;
  }

  viewSubscriptionPlan(ID) {
    if (ID) {
      this.router.navigate([this.root, { outlets: { page: ['subscription-plan-view', ID, 'all'] } }]);
    }
  }

  save() {
    const payload = {
      status: this.status,
      label: this.label,
      description: this.description,
      fileIDCover: this.fileID || null,
      fileIDThumbnail: this.fileIDThumbnail || null,
      isActive: this.isActive,
      isPublic: this.isPublic,
      isRecommended: this.isRecommended,
      price: this.price,
      waivedPeriod: this.waivedPeriod,
      minTreatmentPlan: this.minTreatmentPlan,
      isDefault: this.isDefault,
      isFreeSMS: this.isFreeSMS,
      smsPrice: this.smsPrice,
      color: this.color,
      colorSecond: this.colorSecond,
      subFeatureIDs: [],
      subFeatureLimitedIDs: [],
      moduleCodes: [],
      webDescription: this.webDescription,
      updateUsers: false,
      useDefaultColor: this.useDefaultColor,
      useExternalSignupLink:this.useExternalSignupLink,
      externalSignupLink:this.externalSignupLink
    };

    if (this.changeSelectionFeature == true) {
      payload.subFeatureIDs = this.subFeatureIDs || [];
      payload.subFeatureLimitedIDs = this.subFeatureLimitedIDs || [];
    }

    if (this.changeSelectionModule == true) {
      payload.moduleCodes = this.moduleCodes || [];
    }

    if (this.isDefault == true && this.updateUsers == true) {
      payload.updateUsers = true;
    }
    this._marketingDescriptions = _.filter(this._marketingDescriptions, (item) => {
      if (item != null) {
        return true;
      }
      return false;
    });
    if (this._marketingDescriptions && this._marketingDescriptions.length > 0) {
      payload['marketingDescriptions'] = this._marketingDescriptions;
    }

    RootAppComponent.stopSubPlanTimer = true;

    if (this.subscriptionPlanID) {
      this.subscriptionPlanService.editPromoterPlan(this.subscriptionPlanID, payload).subscribe((res) => {
        if (res && res.ID) {
          this.createdRecord = res;
          let _payload = {
            isForceSync: true,
            subscriptionPlanID: res.ID
          };


          this.subscriptionPlanService.sync(_payload).subscribe((r) => {
            if (res.ID === this.currentPlanID) {
              this.authenticationService.getCurrentPlanTransactionForce(false).subscribe((res1) => {
                this.authenticationService.getCurrentUserForce(false).subscribe((res1) => {
                  this.authenticationService.getCurrentForceAccess(false).subscribe((access) => {
                    this.authenticationService.getCurrentPlanForce(false, true).subscribe((res1) => {


                      if (access) {


                        RootAppComponent.setCurrentAccess.emit(access);
                      }
                      this.createdRecord = res;
                      NotifyAppComponent.displayToast("success", "Success!", "Subscription plan  is Modified");
                      RootAppComponent.stopSubPlanTimer = false;
                      this.proceed();
                    });
                  });
                });
              });
            } else {
              NotifyAppComponent.displayToast("success", "Success!", "Subscription plan  is Modified");
              RootAppComponent.stopSubPlanTimer = false;
              this.proceed();
            }
          })

        }
      });
    } else {
      this.subscriptionPlanService.createPromoterPlan(payload).subscribe((res) => {
        if (res && res.ID) {
          window.history.replaceState({}, '', `/merchant/(page:subscription-plan-create-edit/${res.ID})`);

          if (
            this.currentPlan &&
            this.currentPlan.Is_Default === '1' &&
            this.updateUsers == true &&
            this.isDefault == true
          ) {
            this.authenticationService.getCurrentPlanTransactionForce(false).subscribe((res1) => {
              this.authenticationService.getCurrentUserForce(false).subscribe((res1) => {
                this.authenticationService.getCurrentForceAccess(false).subscribe((access) => {
                  this.authenticationService.getCurrentPlanForce(false, true).subscribe((res1) => {
                    if (access) {
                      RootAppComponent.setCurrentAccess.emit(access);
                    }
                    this.createdRecord = res;
                    NotifyAppComponent.displayToast('success', 'Success!', 'Subscription plan  is Modified');
                    RootAppComponent.stopSubPlanTimer = false;
                    this.proceed();
                  });
                });
              });
            });
          } else {
            RootAppComponent.stopSubPlanTimer = false;
            this.createdRecord = res;
            NotifyAppComponent.displayToast('success', 'Success!', 'Subscription plan  is Created');
            this.proceed();
          }
        } else {
          RootAppComponent.stopSubPlanTimer = false;
        }
      });
    }
  }

  changeActivePublic() {
    if (this.isPublic != true || this.isActive != true) {
      this.isDefault = false;
    }
  }

  changeDefault() {
    if (this.isDefault === true) {
      this.isActive = true;
      this.isPublic = true;
    }
  }

  getSelectedSubFeatureIDsEvent(e) {
    this.subFeatureIDs = e || [];
  }

  getSelectedSubFeatureLimitedIDsEvent(e) {
    this.subFeatureLimitedIDs = e || [];
  }

  getSelectedModuleIDsEvent(e) {
    this.changeSelectionModule = true;
    this.moduleCodes = e || [];
  }

  getIsSelectionChangeEvent(e) {
    this.changeSelectionFeature = e || false;
  }
}
