<div class="row clearfix">
  <div class="card-header primary-gradient-img clearfix inModal" *ngIf="isModal == true">
    <div class="row clearfix">
      <button class="btn-close btn-clear pull-right mat-button" (click)="closeModalEvent()">
        <mat-icon>clear</mat-icon>
      </button>

      <div class="row titleArea clearfix">
        <mat-icon class="pull-left fas fa-comment-dollar"></mat-icon>
        <h2 class="summary-header rm-m">Create Employment Benefit</h2>
      </div>
    </div>
  </div>

  <mat-dialog-content [ngStyle]="{ overflow: isModal == false ? 'unset' : 'scroll' }">
    <div class="row clearfix full-width cont">
      <!-- normal employment / job -->

      <div class="row clearfix" *ngIf="disabled == false">
        <div class="row clearfix flex">
          <div class="clearfix full-width">
            <div class="col-left">
              <h4 class="rm-mb">Operator modification</h4>
              <hr/>
              <div class="row clearfix full-width">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Reason</mat-label>
                  <mat-select
                    [(ngModel)]="pcDetail.updatedReason"
                    [ngClass]="highlightField('Updating_Reason.Code')"
                    placeholder="Reason"
                    name="reason"
                    required
                  >
                    <mat-option *ngFor="let reason of persCircumUpdReasons" [value]="reason.Code">
                      {{ reason.Label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="clearfix full-width">
            <div class="col-left">
              <h4 class="rm-mb">Comments</h4>
              <hr/>
              <div class="row clearfix full-width">
                <mat-form-field
                  class="full-width"
                  [ngClass]="highlightField('Updating_Reason.Comment')"
                  appearance="outline"
                >
                  <mat-label>Leave a comment</mat-label>
                  <textarea
                    [(ngModel)]="pcDetail.updatedComment"
                    matInput
                    placeholder="Comment"
                    name="comment"
                  ></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>

          <!-- <div class="clearfix full-width" *ngIf="false">
              <div class="col-left">
                <h4 class="rm-mb">Files</h4>
                <hr>
                <div class="row clearfix full-width">
                  <app-file-uploader class=" text-center clearfix" [uploadDirectly]="true" [isDragable]="true" class="text-center" (onCompleteAll)="onCompleteAll($event)"
                    [limit]="1"></app-file-uploader>


                  <app-file-list class="compact" (remove)="removeFile($event)" [hasExtraInfo]="false" [canEdit]="true" [canAction]="false"
                    [files]="[fileID]"></app-file-list>
                </div>
              </div>
            </div> -->
        </div>
      </div>

      <div class="row clearfix" *ngIf="isModal == true">
        <mat-form-field class="full-width" [ngClass]="highlightField('Updating_Reason.Comment')" appearance="outline">
          <mat-label>Employment Status</mat-label>
          <mat-select [(ngModel)]="pcDetail.typeCode" [disabled]="disabled" name="employmentStatus" required>
            <mat-option *ngFor="let status of employmentStatus" [value]="status.Code">
              {{ status.Label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="pcDetail.typeCode != null">
        <div class="row clearfix" *ngIf="pcDetail.typeCode == 'EMP'">
          <div class="row clearfix flex">
            <div class="clearfix full-width">
              <div class="col-left">
                <h4 class="rm-mb">Employment Details</h4>
                <hr/>
                <div class="row clearfix full-width">
                  <section *ngIf="isModal == false">
                    <mat-form-field
                      class="full-width"
                      [ngClass]="highlightField('Updating_Reason.Comment')"
                      appearance="outline"
                    >
                      <mat-label>Employment Status</mat-label>
                      <mat-select
                        [(ngModel)]="pcDetail.typeCode"
                        [disabled]="disabled"
                        name="employmentStatus"
                        required
                      >
                        <mat-option *ngFor="let status of employmentStatus" [value]="status.Code">
                          {{ status.Label }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </section>

                  <div class="row clearfix">
                    <mat-form-field
                      class="full-width"
                      [ngClass]="highlightField('Info.Employment.Type.Code')"
                      appearance="outline"
                    >
                      <mat-label>Employment Type</mat-label>
                      <mat-select
                        [(ngModel)]="pcDetail.employmentTypeCode"
                        [disabled]="disabled"
                        name="employmentStatus"
                      >
                        <mat-option *ngFor="let e of employmentTypes" [value]="e.Code">
                          {{ e.Label }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field
                      class="full-width"
                      [ngClass]="highlightField('Info.Employment.Role')"
                      appearance="outline"
                    >
                      <mat-label>Role / Occupation</mat-label>
                      <input
                        [(ngModel)]="pcDetail.employmentRole"
                        [disabled]="disabled"
                        matInput
                        placeholder="Role / Occupation"
                      />
                    </mat-form-field>
                  </div>
                  <div class="row clearfix flex">
                    <!-- <mat-form-field class="full-width" appearance="outline" [ngClass]="highlightField('Info.Employment.Start.Year')">
                      <mat-label>Start year</mat-label>
                      <input matInput placeholder="Start year" [(ngModel)]="pcDetail.startYear">
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline" [ngClass]="highlightField('Info.Employment.Start.Month')">
                      <mat-label>Start month</mat-label>
                      <input matInput placeholder="Start month" [(ngModel)]="pcDetail.startMonth">
                    </mat-form-field> -->

                    <!-- <mat-form-field class="full-width animate" appearance="outline" [ngClass]="highlightField('Info.Employment.Start.Year')">
                      <mat-label>Retirment Time</mat-label>
                      <input matInput name="occureDateDP" [(ngModel)]="startDate" [mode]="'month'" theme="dp-material" [dpDayPicker]="dateConfig"
                        placeholder="Start Time" (ngModelChange)="datePicker($event)" [disabled]="disabled">
                    </mat-form-field> -->
                  </div>

                  <div class="row clearfix flex">
                    <mat-form-field
                      class="full-width"
                      [ngClass]="highlightField('Info.Employment.TimeInRole.Years')"
                      appearance="outline"
                    >
                      <mat-label>Years in role</mat-label>
                      <input
                        [(ngModel)]="pcDetail.timeInRoleYears"
                        [disabled]="disabled"
                        matInput
                        placeholder="Years"
                        readonly
                      />
                    </mat-form-field>
                    <mat-form-field
                      class="full-width"
                      [ngClass]="highlightField('Info.Employment.TimeInRole.Months')"
                      appearance="outline"
                    >
                      <mat-label>Months in role</mat-label>
                      <input
                        [(ngModel)]="pcDetail.timeInRoleMonths"
                        [disabled]="disabled"
                        matInput
                        placeholder="Month"
                        readonly
                      />
                    </mat-form-field>
                  </div>

                  <mat-form-field [ngClass]="highlightField('Info.Employment.OnProbation')" appearance="outline">
                    <mat-label>Are You On Probation</mat-label>
                    <mat-select [(ngModel)]="pcDetail.isOnProbation" [disabled]="disabled">
                      <mat-option value="0"> No</mat-option>
                      <mat-option value="1"> Yes</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="clearfix full-width">
              <div class="col-left">
                <h4 class="rm-mb">Employer Details</h4>
                <hr/>
                <div class="row clearfix full-width">
                  <mat-form-field
                    class="full-width"
                    [ngClass]="highlightField('Info.Employment.Employer.Name')"
                    appearance="outline"
                  >
                    <mat-label>Name</mat-label>
                    <input [(ngModel)]="pcDetail.employerName" [disabled]="disabled" matInput placeholder="Name"/>
                  </mat-form-field>
                  <mat-form-field
                    class="full-width"
                    [ngClass]="highlightField('Info.Employment.Employer.ContactName')"
                    appearance="outline"
                  >
                    <mat-label>Contact - Name</mat-label>
                    <input
                      [(ngModel)]="pcDetail.employerContactName"
                      [disabled]="disabled"
                      matInput
                      placeholder="Contact - Name"
                    />
                  </mat-form-field>

                  <app-phone-input
                    class="full-width"
                    [phoneNumber]="pcDetail.employerNumber"
                    [isLandLine]="false"
                    [label]="'Contact - Number'"
                    [required]="false"
                    [ngClass]="highlightField('Info.Employment.Employer.Number')"
                    [isDisabled]="disabled"
                    (validate)="validateEmployerPhoneEvent($event)"
                    (getPhone)="getEmployerPhone($event)"
                  >
                  </app-phone-input>

                  <app-email-input
                    class="full-width"
                    [email]="pcDetail.employerEmail"
                    [label]="'Contact - Email'"
                    [required]="false"
                    [ngClass]="highlightField('Info.Employment.Employer.Email')"
                    [isDisabled]="disabled"
                    (getEmail)="getEmployerEmail($event)"
                    (validate)="validateEmployerEmailEvent($event)"
                  >
                  </app-email-input>
                </div>
              </div>
            </div>
          </div>

          <div class="row clearfix flex mt">
            <div class="clearfix full-width">
              <div class="col-left">
                <h4 class="rm-mb">Employer Address</h4>
                <hr/>
                <div class="row clearfix">
                  <app-address-input
                    [isRequired]="false"
                    [displayMap]="false"
                    [postCode]="pcDetail.addressStreetPostCode"
                    [state]="pcDetail.addressStreetState"
                    [suburb]="pcDetail.addressStreetSuburb"
                    [streetNumber]="pcDetail.addressStreetNumber"
                    [streetName]="pcDetail.addressStreetName"
                    [unitNumber]="pcDetail.addressUnit"
                    [streetType]="pcDetail.addressStreetType"
                    [ngClass]="highlightField('Address')"
                    [isDisabled]="disabled"
                    (getAdress)="getEmployerAddress($event)"
                    (validate)="isEmployerAdressValidEvent($event)"
                  ></app-address-input>
                </div>
              </div>
            </div>

            <div class="clearfix full-width">
              <div class="col-left">
                <h4 class="rm-mb">Payment Details</h4>
                <hr/>
                <div class="row clearfix full-width">
                  <mat-form-field class="full-width" [ngClass]="highlightField('Income.Value')" appearance="outline">
                    <mat-label>Payment Amount</mat-label>
                    <input
                      [(ngModel)]="pcDetail.incomeValue"
                      [disabled]="disabled"
                      matInput
                      placeholder="Payment Amount"
                      currencyMask
                    />
                  </mat-form-field>
                  <mat-form-field
                    class="full-width"
                    [ngClass]="highlightField('Income.Frequency')"
                    appearance="outline"
                  >
                    <mat-label>Payment Period</mat-label>

                    <mat-select
                      [(ngModel)]="pcDetail.incomeFrequency"
                      [disabled]="disabled"
                      placeholder="Payment Period"
                      name="incomeFrequency"
                    >
                      <mat-option *ngFor="let fre of frequencies" [value]="fre.Code">
                        {{ fre.Label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field
                    class="full-width"
                    [ngClass]="highlightField('Info.Employment.Hours')"
                    appearance="outline"
                  >
                    <mat-label>Hours Per Week</mat-label>
                    <input
                      [(ngModel)]="pcDetail.employmentHours"
                      [disabled]="disabled"
                      matInput
                      placeholder="Hours Per Week"
                    />
                  </mat-form-field>

                  <app-date-input
                    class="full-width"
                    [label]="'Next Pay Date'"
                    [defaultDate]="pcDetail.nextPayDate"
                    [ngClass]="highlightField('Info.Employment.NextPayDate')"
                    [disabled]="disabled"
                    (selectedDate)="getDate($event)"
                    name="nextPayDate"
                  ></app-date-input>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row clearfix" *ngIf="pcDetail.typeCode == 'HMD'">
          <div class="clearfix full-width" *ngIf="isModal == false">
            <div class="col-left">
              <h4 class="rm-mb">Employment Details</h4>
              <hr/>
              <div class="row clearfix full-width">
                <mat-form-field class="full-width" [ngClass]="highlightField('TypeCode')" appearance="outline">
                  <mat-label>Employment Status</mat-label>
                  <mat-select [(ngModel)]="pcDetail.typeCode" [disabled]="disabled" name="employmentStatus">
                    <mat-option *ngFor="let status of employmentStatus" [value]="status.Code">
                      {{ status.Label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="clearfix full-width">
            <div class="col-left">
              <h4 class="rm-mb">Home Duties Details</h4>
              <hr/>

              <div class="clearfix full-width">
                <!-- <mat-form-field class="full-width" appearance="outline" [ngClass]="highlightField('Info.Employment.Start.Year')">
                  <mat-label>Start year</mat-label>
                  <input matInput placeholder="Start year" [(ngModel)]="pcDetail.startYear">
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline" [ngClass]="highlightField('Info.Employment.Start.Month')">
                  <mat-label>Start month</mat-label>
                  <input matInput placeholder="Start month" [(ngModel)]="pcDetail.startMonth">
                </mat-form-field> -->

                <mat-form-field
                  class="full-width animate"
                  [ngClass]="highlightField('Info.Employment.Start.Year')"
                  appearance="outline"
                >
                  <mat-label>Retirment Time</mat-label>
                  <input
                    [(ngModel)]="startDate"
                    [mode]="'month'"
                    [dpDayPicker]="dateConfig"
                    [disabled]="disabled"
                    (ngModelChange)="datePicker($event)"
                    matInput
                    name="occureDateDP"
                    theme="dp-material"
                    placeholder="Start Time"
                  />
                </mat-form-field>

                <mat-form-field
                  class="full-width"
                  [ngClass]="highlightField('Info.Employment.TimeInRole.Years')"
                  appearance="outline"
                >
                  <mat-label>Duration - Years</mat-label>
                  <input
                    [(ngModel)]="pcDetail.timeInRoleYears"
                    [disabled]="disabled"
                    matInput
                    placeholder="Years"
                    readonly
                  />
                </mat-form-field>

                <mat-form-field
                  class="full-width"
                  [ngClass]="highlightField('Info.Employment.TimeInRole.Months')"
                  appearance="outline"
                >
                  <mat-label>Duration - Months</mat-label>
                  <input
                    [(ngModel)]="pcDetail.timeInRoleMonths"
                    [disabled]="disabled"
                    matInput
                    placeholder="Month"
                    readonly
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

        <div class="row clearfix" *ngIf="pcDetail.typeCode == 'RET'">
          <div class="clearfix full-width" *ngIf="isModal == false">
            <div class="col-left">
              <h4 class="rm-mb">Employment Details</h4>
              <hr/>
              <div class="row clearfix full-width">
                <mat-form-field class="full-width" [ngClass]="highlightField('TypeCode')" appearance="outline">
                  <mat-label>Employment Status</mat-label>
                  <mat-select [(ngModel)]="pcDetail.typeCode" [disabled]="disabled" name="employmentStatus">
                    <mat-option *ngFor="let status of employmentStatus" [value]="status.Code">
                      {{ status.Label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="clearfix full-width">
            <div class="col-left">
              <h4 class="rm-mb">Times</h4>
              <hr/>

              <div class="clearfix full-width">
                <!-- <mat-form-field class="full-width" appearance="outline" [ngClass]="highlightField('Info.Employment.Start.Year')">
                  <mat-label>Entered Retirment Year</mat-label>
                  <input matInput placeholder="Start year" [(ngModel)]="pcDetail.startYear">
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline" [ngClass]="highlightField('Info.Employment.Start.Month')">
                  <mat-label>Entered Retirment Month</mat-label>
                  <input matInput placeholder="Start month" [(ngModel)]="pcDetail.startMonth">
                </mat-form-field> -->

                <mat-form-field
                  class="full-width animate"
                  [ngClass]="highlightField('Info.Employment.Start.Year')"
                  appearance="outline"
                >
                  <mat-label>Retirment Time</mat-label>
                  <input
                    [(ngModel)]="startDate"
                    [mode]="'month'"
                    [dpDayPicker]="dateConfig"
                    [disabled]="disabled"
                    (ngModelChange)="datePicker($event)"
                    matInput
                    name="occureDateDP"
                    theme="dp-material"
                    placeholder="Start Time"
                  />
                </mat-form-field>

                <mat-form-field
                  class="full-width"
                  [ngClass]="highlightField('Info.Employment.TimeInRole.Years')"
                  appearance="outline"
                >
                  <mat-label>Duration - Years</mat-label>
                  <input
                    [(ngModel)]="pcDetail.timeInRoleYears"
                    [disabled]="disabled"
                    matInput
                    placeholder="Years"
                    readonly
                  />
                </mat-form-field>

                <mat-form-field
                  class="full-width"
                  [ngClass]="highlightField('Info.Employment.TimeInRole.Months')"
                  appearance="outline"
                >
                  <mat-label>Duration - Months</mat-label>
                  <input
                    [(ngModel)]="pcDetail.timeInRoleMonths"
                    [disabled]="disabled"
                    matInput
                    placeholder="Month"
                    readonly
                  />
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="clearfix full-width">
            <div class="col-left">
              <h4 class="rm-mb">Retirement Details</h4>
              <hr/>

              <div class="clearfix full-width">
                <mat-form-field class="full-width" [ngClass]="highlightField('Income.Value')" appearance="outline">
                  <mat-label>Payment Amount</mat-label>
                  <input
                    [(ngModel)]="pcDetail.incomeValue"
                    [disabled]="disabled"
                    matInput
                    placeholder="Payment Amount"
                    currencyMask
                  />
                </mat-form-field>
                <mat-form-field class="full-width" [ngClass]="highlightField('Income.Frequency')" appearance="outline">
                  <mat-label>Payment Period</mat-label>
                  <mat-select
                    [(ngModel)]="pcDetail.incomeFrequency"
                    [disabled]="disabled"
                    placeholder="Payment Period"
                    name="incomeFrequency"
                  >
                    <mat-option *ngFor="let fre of frequencies" [value]="fre.Code">
                      {{ fre.Label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <app-date-input
                  class="full-width"
                  [label]="'Next Payment Date'"
                  [defaultDate]="pcDetail.nextPayDate"
                  [ngClass]="highlightField('Info.Employment.NextPayDate')"
                  [disabled]="disabled"
                  (selectedDate)="getDate($event)"
                  name="nextPayDate"
                ></app-date-input>
              </div>
            </div>
          </div>
        </div>

        <div class="row clearfix flex" *ngIf="pcDetail.typeCode == 'SEM'">
          <div class="clearfix full-width" *ngIf="isModal == false">
            <div class="col-left">
              <h4 class="rm-mb">Employment Details</h4>
              <hr/>
              <div class="row clearfix full-width">
                <mat-form-field class="full-width" [ngClass]="highlightField('TypeCode')" appearance="outline">
                  <mat-label>Employment Status</mat-label>
                  <mat-select [(ngModel)]="pcDetail.typeCode" [disabled]="disabled" name="employmentStatus">
                    <mat-option *ngFor="let status of employmentStatus" [value]="status.Code">
                      {{ status.Label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="clearfix full-width">
            <div class="col-left">
              <h4 class="rm-mb">Business Details</h4>
              <hr/>
              <div class="clearfix full-width">
                <mat-form-field
                  class="full-width"
                  [ngClass]="highlightField('Info.Employment.TradingName')"
                  appearance="outline"
                >
                  <mat-label>Trading As</mat-label>
                  <input
                    [(ngModel)]="pcDetail.tradingName"
                    [disabled]="disabled"
                    matInput
                    placeholder="Payment Amount"
                  />
                </mat-form-field>

                <mat-form-field
                  class="full-width"
                  [ngClass]="highlightField('Info.Employment.ABN')"
                  appearance="outline"
                >
                  <mat-label>ABN</mat-label>
                  <input
                    [(ngModel)]="pcDetail.abn"
                    [disabled]="disabled"
                    matInput
                    type="number"
                    placeholder="Payment Amount"
                  />
                </mat-form-field>

                <app-phone-input
                  class="full-width"
                  [phoneNumber]="pcDetail.employerNumber"
                  [isLandLine]="false"
                  [label]="'Contact - Number'"
                  [required]="false"
                  [ngClass]="highlightField('Info.Employment.Employer.Number')"
                  [isDisabled]="disabled"
                  (validate)="validateEmployerPhoneEvent($event)"
                  (getPhone)="getEmployerPhone($event)"
                >
                </app-phone-input>

                <app-email-input
                  class="full-width"
                  [email]="pcDetail.employerEmail"
                  [label]="'Contact - Email'"
                  [required]="false"
                  [ngClass]="highlightField('Info.Employment.Employer.Email')"
                  [isDisabled]="disabled"
                  (getEmail)="getEmployerEmail($event)"
                  (validate)="validateEmployerEmailEvent($event)"
                >
                </app-email-input>

                <mat-form-field
                  class="full-width"
                  [ngClass]="highlightField('Info.Employment.Role')"
                  appearance="outline"
                >
                  <mat-label>Role / Occupation</mat-label>
                  <input
                    [(ngModel)]="pcDetail.employmentRole"
                    [disabled]="disabled"
                    matInput
                    placeholder="Role / Occupation"
                  />
                </mat-form-field>

                <!-- <mat-form-field class="full-width" appearance="outline" [ngClass]="highlightField('Info.Employment.Start.Year')">
                  <mat-label>Start year</mat-label>
                  <input matInput placeholder="Start year" [(ngModel)]="pcDetail.startYear">
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline" [ngClass]="highlightField('Info.Employment.Start.Month')">
                  <mat-label>Start month</mat-label>
                  <input matInput placeholder="Start month" [(ngModel)]="pcDetail.startMonth">
                </mat-form-field> -->

                <mat-form-field
                  class="full-width animate"
                  [ngClass]="highlightField('Info.Employment.Start.Year')"
                  appearance="outline"
                >
                  <mat-label>Start Time</mat-label>
                  <input
                    [(ngModel)]="startDate"
                    [mode]="'month'"
                    [dpDayPicker]="dateConfig"
                    [disabled]="disabled"
                    (ngModelChange)="datePicker($event)"
                    matInput
                    name="occureDateDP"
                    theme="dp-material"
                    placeholder="Start Time"
                  />
                </mat-form-field>

                <mat-form-field
                  class="full-width"
                  [ngClass]="highlightField('Info.Employment.TimeInRole.Years')"
                  appearance="outline"
                >
                  <mat-label>Duration - Years</mat-label>
                  <input
                    [(ngModel)]="pcDetail.timeInRoleYears"
                    [disabled]="disabled"
                    matInput
                    placeholder="Years"
                    readonly
                  />
                </mat-form-field>

                <mat-form-field
                  class="full-width"
                  [ngClass]="highlightField('Info.Employment.TimeInRole.Months')"
                  appearance="outline"
                >
                  <mat-label>Duration - Months</mat-label>
                  <input
                    [(ngModel)]="pcDetail.timeInRoleMonths"
                    [disabled]="disabled"
                    matInput
                    placeholder="Month"
                    readonly
                  />
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="clearfix full-width">
            <div class="col-left">
              <h4 class="rm-mb">Payment Details</h4>
              <hr/>

              <div class="row clearfix full-width">
                <mat-form-field class="full-width" [ngClass]="highlightField('Income.Value')" appearance="outline">
                  <mat-label>Payment Amount</mat-label>
                  <input
                    [(ngModel)]="pcDetail.incomeValue"
                    [disabled]="disabled"
                    matInput
                    placeholder="Payment Amount"
                    currencyMask
                  />
                </mat-form-field>
                <mat-form-field class="full-width" [ngClass]="highlightField('Income.Frequency')" appearance="outline">
                  <mat-label>Payment Period</mat-label>
                  <mat-select
                    [(ngModel)]="pcDetail.incomeFrequency"
                    [disabled]="disabled"
                    placeholder="Payment Period"
                    name="incomeFrequency"
                  >
                    <mat-option
                      *ngFor="let fre of frequencies | FilterArrayMultipleNotContainValue: ['Code']:'8'"
                      [value]="fre.Code"
                    >
                      {{ fre.Label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field
                  class="full-width"
                  [ngClass]="highlightField('Info.Employment.Hours')"
                  appearance="outline"
                >
                  <mat-label>Hours Per Week</mat-label>
                  <input
                    [(ngModel)]="pcDetail.employmentHours"
                    [disabled]="disabled"
                    matInput
                    placeholder="Hours Per Week"
                  />
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="clearfix full-width">
            <div class="col-left">
              <h4 class="rm-mb">Business Address</h4>
              <hr/>

              <div class="row clearfix full-width">
                <app-address-input
                  [isRequired]="false"
                  [displayMap]="false"
                  [postCode]="pcDetail.addressStreetPostCode"
                  [state]="pcDetail.addressStreetState"
                  [suburb]="pcDetail.addressStreetSuburb"
                  [streetNumber]="pcDetail.addressStreetNumber"
                  [streetName]="pcDetail.addressStreetName"
                  [unitNumber]="pcDetail.addressUnit"
                  [streetType]="pcDetail.addressStreetType"
                  [ngClass]="highlightField('Address')"
                  [isDisabled]="disabled"
                  (getAdress)="getEmployerAddress($event)"
                  (validate)="isEmployerAdressValidEvent($event)"
                ></app-address-input>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row clearfix flex"
          *ngIf="
            pcDetail.typeCode == 'OGB' ||
            pcDetail.typeCode == 'STU' ||
            pcDetail.typeCode == 'WCV' ||
            pcDetail.typeCode == 'PSN' ||
            pcDetail.typeCode == 'UNE'
          "
        >
          <div class="clearfix full-width" *ngIf="isModal == false">
            <div class="col-left">
              <h4 class="rm-mb">Employment Details</h4>
              <hr/>
              <div class="row clearfix full-width">
                <mat-form-field class="full-width" [ngClass]="highlightField('TypeCode')" appearance="outline">
                  <mat-label>Employment Status</mat-label>
                  <mat-select [(ngModel)]="pcDetail.typeCode" [disabled]="disabled" name="employmentStatus">
                    <mat-option *ngFor="let status of employmentStatus" [value]="status.Code">
                      {{ status.Label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="clearfix full-width">
            <div class="col-left">
              <h4 class="rm-mb">Benefit Details</h4>
              <hr/>
              <div class="row clearfix full-width">
                <section *ngIf="pcDetail.typeCode == 'OGB' || pcDetail.typeCode == 'STU'">
                  <mat-form-field
                    class="full-width"
                    [ngClass]="highlightField('Info.Employment.BenefitType')"
                    appearance="outline"
                  >
                    <mat-label>Benefit Type</mat-label>
                    <mat-select [(ngModel)]="pcDetail.benefitType" [disabled]="disabled" name="benefitTypes">
                      <mat-option *ngFor="let status of benefitTypes" [value]="status.Code">
                        {{ status.Label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </section>

                <mat-form-field class="full-width" [ngClass]="highlightField('Income.Value')" appearance="outline">
                  <mat-label>Payment Amount</mat-label>
                  <input
                    [(ngModel)]="pcDetail.incomeValue"
                    [disabled]="disabled"
                    matInput
                    placeholder="Payment Amount"
                    currencyMask
                  />
                </mat-form-field>
                <mat-form-field class="full-width" [ngClass]="highlightField('Income.Frequency')" appearance="outline">
                  <mat-label>Payment Period</mat-label>
                  <mat-select
                    [(ngModel)]="pcDetail.incomeFrequency"
                    [disabled]="disabled"
                    placeholder="Payment Period"
                    name="incomeFrequency"
                  >
                    <mat-option *ngFor="let fre of frequencies" [value]="fre.Code">
                      {{ fre.Label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <app-date-input
                  class="full-width"
                  [label]="'Next Pay Date'"
                  [defaultDate]="pcDetail.nextPayDate"
                  [ngClass]="highlightField('Info.Employment.NextPayDate')"
                  [disabled]="disabled"
                  (selectedDate)="getDate($event)"
                  name="nextPayDate"
                ></app-date-input>
              </div>
            </div>
          </div>

          <div class="clearfix full-width">
            <div class="col-left">
              <h4 class="rm-mb">Times</h4>
              <hr/>
              <div class="row clearfix full-width">
                <div class="row clearfix flex">
                  <!-- <mat-form-field class="full-width" appearance="outline" [ngClass]="highlightField('Info.Employment.Start.Year')">
                    <mat-label>Start year</mat-label>
                    <input matInput placeholder="Start year" [(ngModel)]="pcDetail.startYear">
                  </mat-form-field>
                  <mat-form-field class="full-width" appearance="outline" [ngClass]="highlightField('Info.Employment.Start.Month')">
                    <mat-label>Start month</mat-label>
                    <input matInput placeholder="Start month" [(ngModel)]="pcDetail.startMonth">
                  </mat-form-field> -->

                  <mat-form-field
                    class="full-width animate"
                    [ngClass]="highlightField('Info.Employment.Start.Year')"
                    appearance="outline"
                  >
                    <mat-label>Start Time</mat-label>
                    <input
                      [(ngModel)]="startDate"
                      [mode]="'month'"
                      [dpDayPicker]="dateConfig"
                      [disabled]="disabled"
                      (ngModelChange)="datePicker($event)"
                      matInput
                      name="occureDateDP"
                      theme="dp-material"
                      placeholder="Start Time"
                    />
                  </mat-form-field>
                </div>

                <div class="row clearfix flex">
                  <mat-form-field
                    class="full-width"
                    [ngClass]="highlightField('Info.Employment.TimeInRole.Years')"
                    appearance="outline"
                  >
                    <mat-label>Duration - Years</mat-label>
                    <input
                      [(ngModel)]="pcDetail.timeInRoleYears"
                      [disabled]="disabled"
                      matInput
                      placeholder="Years"
                      readonly
                    />
                  </mat-form-field>
                  <mat-form-field
                    class="full-width"
                    [ngClass]="highlightField('Info.Employment.TimeInRole.Months')"
                    appearance="outline"
                  >
                    <mat-label>Duration - Months</mat-label>
                    <input
                      [(ngModel)]="pcDetail.timeInRoleMonths"
                      [disabled]="disabled"
                      matInput
                      placeholder="Month"
                      readonly
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <div class="row clearfix mt" *ngIf="disabled == false">
    <button
      class="pull-right"
      *ngIf="isModal == false"
      [disabled]="!pcDetail.updatedReason || !pcDetail.typeCode"
      (click)="submit()"
      mat-raised-button
      color="accent"
    >
      Submit
    </button>
    <button
      class="pull-right"
      *ngIf="isModal == true"
      [disabled]="!pcDetail.updatedReason || !pcDetail.typeCode"
      (click)="create()"
      mat-raised-button
      color="accent"
    >
      Create
    </button>
  </div>
</div>
