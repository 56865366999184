<div class="side-menu-container main" flex>
  <!-- Top bar - logo -->
  <div class="logo-container-merchant">
    <div class="site_logo"></div>

    <h1 class="mat-h1  no-p brandName" style="text-align: left;">{{ brandName }}
    </h1>


    <span class="version-application-merchant">

      <strong *ngIf="subPlanLabel">{{subPlanLabel}} plan </strong>
      <strong> V{{ settings['appMerchantVersionNumber'] }}</strong>

    </span>


  </div>

  <!-- Navigation Menu -->
  <div [hidden]="mainModuleLoaded != true">
    <nav id="side-menu" #sidenav1>
      <mat-accordion class="sideNavAccordion">
        <ng-container *ngIf="appState$ | async as appState">
          <!-- DASHBOARD -->
          <mat-expansion-panel @verticalScaleFadeAnimation [ngClass]="{ 'mat-expansion-panel-disabled': false }"
            [matTooltip]="hideTooltip(true)" [hideToggle]="true"
            [routerLink]="['/merchant/', { outlets: { page: ['home'] } }]" [routerLinkActive]="['active']" (click)="
              openModule({
                code: '',
                isBeta: false,
                isActive: true,
                goBack: true
              })
            ">
            <mat-expansion-panel-header>
              <mat-icon>home</mat-icon>
              <span class="text"> {{"SIDE MENU.Dashboard" | translate}} </span>
            </mat-expansion-panel-header>
          </mat-expansion-panel>

          <!-- AI SCANS -->
          <mat-expansion-panel
            *ngIf="getModuleActive('isModuleAiScansActive') && context != 'p4p'  && context != 'breeze' "
            @verticalScaleFadeAnimation
            [ngClass]="{ 'mat-expansion-panel-disabled': !appState.publicModules.isModuleAiScansActive }"
            [matTooltip]="hideTooltip(appState.publicModules.isModuleAiScansActive)"
            [hideToggle]="!appState.publicModules.isModuleAiScansActive" (opened)="
              openModule({
                code: 'isModuleAiScansActive',
                isActive: appState.publicModules.isModuleAiScansActive,
                isBeta: isBeta('isModuleAiScansActive'),
                showModule: true
              })
            ">
            <mat-expansion-panel-header>
              <icon-view [icon]="getModuleIcon('isModuleAiScansActive', 'ai-scans')"></icon-view>
              <span class="text">{{"SIDE MENU.Ai Scans" | translate}}</span>
              <span class="chip chipBeta" *ngIf="isBeta('isModuleAiScansActive')">&beta;</span>
            </mat-expansion-panel-header>

            <div class="panel-content subNavAccordion">
              <mat-list class="sub-menu">
                <mat-divider></mat-divider>

                <ng-container>
                  <mat-list-item (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    ">
                    <a [routerLink]="['/merchant/', { outlets: { page: [ 'ai-scans-list-overview'] } }]"
                      [routerLinkActive]="['active']">
                      {{"SIDE MENU.Scans" | translate}}
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>
              </mat-list>
            </div>
          </mat-expansion-panel>

          <!-- APPOINTMENTS -->
          <mat-expansion-panel
            *ngIf="getModuleActive('isModuleAppointmentActive') && context != 'p4p'  && context != 'breeze'  && !isAppointmentDisabled"
            @verticalScaleFadeAnimation
            [ngClass]="{ 'mat-expansion-panel-disabled': !appState.publicModules.isModuleAppointmentActive }"
            [matTooltip]="hideTooltip(appState.publicModules.isModuleAppointmentActive)"
            [hideToggle]="!appState.publicModules.isModuleAppointmentActive" (opened)="
              openModule({
                code: 'isModuleAppointmentActive',
                isActive: appState.publicModules.isModuleAppointmentActive,
                isBeta: isBeta('isModuleAppointmentActive'),
                showModule: true
              })
            ">
            <mat-expansion-panel-header>
              <icon-view [icon]="getModuleIcon('isModuleAppointmentActive', 'fas fa-calendar-check')"></icon-view>
              <span class="text">{{"SIDE MENU.Appointments" | translate}} </span>
              <span class="chip chipBeta" *ngIf="isBeta('isModuleAppointmentActive')">&beta;</span>
            </mat-expansion-panel-header>

            <div class="panel-content subNavAccordion">
              <button class="sideMenuButton"
                [routerLink]="['/merchant/', { outlets: { page: ['appointment-create-merchant'] } }]" mat-button>
                <mat-icon>alarm_add</mat-icon>
                {{"SIDE MENU.New Appointment" | translate}}
              </button>

              <mat-list class="sub-menu">
                <mat-divider></mat-divider>

                <ng-container>
                  <mat-list-item (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    ">
                    <a [routerLink]="['/merchant/', { outlets: { page: ['appointment-list-calendar-overview'] } }]"
                      [routerLinkActive]="['active']">
                      {{"SIDE MENU.Calendar" | translate}}
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>

                <ng-container>
                  <mat-list-item (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    ">
                    <a [routerLink]="['/merchant/', { outlets: { page: ['appointment-list-overview'] } }]"
                      [routerLinkActive]="['active']">
                      {{"SIDE MENU.Appointment List" | translate}}
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>
              </mat-list>
            </div>
          </mat-expansion-panel>

          <!-- COMMUNICATION -->
          <mat-expansion-panel *ngIf="getModuleActive('isModuleNoteFeaturesActive')" @verticalScaleFadeAnimation
            [ngClass]="{ 'mat-expansion-panel-disabled': !appState.publicModules.isModuleNoteFeaturesActive }"
            [matTooltip]="hideTooltip(appState.publicModules.isModuleNoteFeaturesActive)"
            [hideToggle]="!appState.publicModules.isModuleNoteFeaturesActive" (opened)="
              openModule({
                code: 'isModuleNoteFeaturesActive',
                isActive: appState.publicModules.isModuleNoteFeaturesActive,
                isBeta: isBeta('isModuleNoteFeaturesActive'),
                showModule: true
              })
            ">
            <mat-expansion-panel-header>
              <icon-view [icon]="getModuleIcon('isModuleNoteFeaturesActive', 'import_export')"></icon-view>
              <span class="text"> {{"SIDE MENU.Communications" | translate}} </span>
              <span class="chip chipBeta" *ngIf="isBeta('isModuleNoteFeaturesActive')">&beta;</span>
            </mat-expansion-panel-header>

            <div class="panel-content subNavAccordion">
              <mat-list class="sub-menu">
                <mat-divider></mat-divider>

                <ng-container>
                  <mat-list-item (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    ">
                    <a [routerLink]="[
                        '/merchant/',
                        { outlets: { page: [ 'activity-log-list-overview'] } }
                      ]" [routerLinkActive]="['active']">
                      {{"SIDE MENU.Activities" | translate}}
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>

                <ng-container>
                  <mat-list-item (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    ">
                    <a [routerLink]="['/merchant/', { outlets: { page: [ 'message-list-overview'] } }]"
                      [routerLinkActive]="['active']">
                      {{"SIDE MENU.Email and SMS" | translate}}
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>

              </mat-list>
            </div>
          </mat-expansion-panel>

          <!-- HEALTH HISTORY -->
          <mat-expansion-panel
            *ngIf="healthHistoryActive && getModuleActive('isModulePatientHistoryActive')   && context != 'breeze'  && context != 'p4p'"
            @verticalScaleFadeAnimation
            [ngClass]="{ 'mat-expansion-panel-disabled': !appState.publicModules.isModulePatientHistoryActive }"
            [matTooltip]="hideTooltip(appState.publicModules.isModulePatientHistoryActive)"
            [hideToggle]="!appState.publicModules.isModulePatientHistoryActive" (opened)="
              openModule({
                code: 'isModulePatientHistoryActive',
                isActive: appState.publicModules.isModulePatientHistoryActive,
                isBeta: isBeta('isModulePatientHistoryActive'),
                showModule: true
              })
            ">
            <mat-expansion-panel-header>
              <icon-view [icon]="getModuleIcon('isModulePatientHistoryActive', 'fas fa-heartbeat')"></icon-view>
              <span class="text"> {{"SIDE MENU.Health History" | translate}} </span>
              <span class="chip chipBeta" *ngIf="isBeta('isModulePatientHistoryActive')">&beta;</span>
            </mat-expansion-panel-header>

            <div class="panel-content subNavAccordion">
              <button class="sideMenuButton" [routerLink]="[
                  '/merchant/',
                  { outlets: { page: [ 'medical-history-invitation-create'] } }
                ]" mat-button>
                <mat-icon>add</mat-icon>
                {{"SIDE MENU.New Invitation" | translate}}
              </button>

              <mat-list class="sub-menu">
                <mat-divider></mat-divider>

                <ng-container>
                  <mat-list-item (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    ">
                    <a [routerLink]="[
                        '/merchant/',
                        { outlets: { page: [ 'medical-history-overview'] } }
                      ]" [routerLinkActive]="['active']">
                      {{ "Patient" | translate }} Surveys
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>

                <ng-container *ngIf="getModuleActive('isModuleMedicationActive')">
                  <mat-list-item (click)="
                      openModule({
                        code: 'isModuleMedicationActive',
                        isActive: appState.publicModules.isModuleMedicationActive,
                        isBeta: isBeta('isModuleMedicationActive'),
                        showModule: false,
                        goBack: true
                      })
                    ">
                    <a [ngClass]="{ 'disabled-list-item': !appState.publicModules.isModuleMedicationActive }"
                      [routerLink]="
                        isNavigationEnabled(appState.publicModules.isModuleMedicationActive, [
                          '/merchant/',
                          { outlets: { page: [ 'drugs-list-overview'] } }
                        ])
                      " [routerLinkActive]="['active']">
                      {{"SIDE MENU.Medications" | translate}}
                      <span class="chip chipBeta" *ngIf="isBeta('isModuleMedicationActive')">&beta;</span>
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>
              </mat-list>
            </div>
          </mat-expansion-panel>

          <!-- INFORMED CONSENT -->
          <mat-expansion-panel
            *ngIf="getModuleActive('isModuleInformedConsentActive')   && context != 'breeze' && context != 'p4p'"
            @verticalScaleFadeAnimation
            [ngClass]="{ 'mat-expansion-panel-disabled': !appState.publicModules.isModuleInformedConsentActive }"
            [matTooltip]="hideTooltip(appState.publicModules.isModuleInformedConsentActive)"
            [hideToggle]="!appState.publicModules.isModuleInformedConsentActive" (opened)="
              openModule({
                code: 'isModuleInformedConsentActive',
                isActive: appState.publicModules.isModuleInformedConsentActive,
                isBeta: isBeta('isModuleInformedConsentActive'),
                showModule: false
              })
            ">
            <mat-expansion-panel-header>
              <icon-view [icon]="getModuleIcon('isModuleInformedConsentActive', 'fas fa-file-signature')"></icon-view>
              <span class="text"> {{"SIDE MENU.Informed Consent" | translate}} </span>
              <span class="chip chipBeta" *ngIf="isBeta('isModuleInformedConsentActive')">&beta;</span>
            </mat-expansion-panel-header>

            <div class="panel-content subNavAccordion">
              <mat-list class="sub-menu">
                <mat-divider></mat-divider>

                <ng-container>
                  <mat-list-item (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    ">
                    <a [routerLink]="[
                        '/merchant/',
                        { outlets: { page: [ 'informed-consent-treatment-presented'] } }
                      ]" [routerLinkActive]="['active']">
                      {{ "SIDE MENU.Treatment Presented" | translate }}
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>
              </mat-list>
            </div>
          </mat-expansion-panel>

          <!-- INVITATIONS -->
          <mat-expansion-panel
            *ngIf="getModuleActive('isModuleTreatmentPlanActive') && appState.publicModules.isModuleTreatmentPlanActive"
            @verticalScaleFadeAnimation (opened)="
              openModule({
                code: 'isModuleTreatmentPlanActive',
                isActive: appState.publicModules.isModuleTreatmentPlanActive,
                isBeta: isBeta('isModuleTreatmentPlanActive'),
                showModule: false
              })
            ">
            <mat-expansion-panel-header>
              <icon-view [icon]="getModuleIcon('isModuleTreatmentPlanActive', 'mail_outline')"></icon-view>
              <span class="text">{{"SIDE MENU.Invitations" | translate}}</span>
              <span class="chip chipBeta" *ngIf="isBeta('isModuleTreatmentPlanActive')">&beta;</span>
            </mat-expansion-panel-header>

            <div class="panel-content subNavAccordion">
              <app-invitation-new-button class="sideMenuButton" *ngIf="newInvitationButton" [hideQuery]="true"
                [isSideMenu]="true" [isMemberButton]="false" [isQuoteButton]="false" [isInviteButton]="true">
              </app-invitation-new-button>

              <app-invitation-new-button class="sideMenuButton"
                *ipvHasPublicModules="{ isModulePatientPortalAccessActive: true }" [hideQuery]="true"
                [isSideMenu]="true" [isQuickInvitation]="true" [isMemberButton]="false" [isQuoteButton]="false"
                [isInviteButton]="true">
              </app-invitation-new-button>

              <mat-list class="sub-menu">
                <mat-divider></mat-divider>

                <ng-container
                  *ngIf="getModuleActive('isModuleAppointmentActive')   && context != 'breeze'  && context != 'p4p'">
                  <mat-list-item [matTooltip]="hideTooltip(appState.publicModules.isModuleAppointmentActive)" (click)="
                      openModule({
                        code: 'isModuleAppointmentActive',
                        isActive: appState.publicModules.isModuleAppointmentActive,
                        isBeta: isBeta('isModuleAppointmentActive'),
                        showModule: false,
                        goBack: true
                      })
                    ">
                    <a [ngClass]="{ 'disabled-list-item': !appState.publicModules.isModuleAppointmentActive }"
                      [routerLink]="
                        isNavigationEnabled(appState.publicModules.isModuleAppointmentActive, [
                          '/merchant/',
                          { outlets: { page: [ 'appointment-list'] } }
                        ])
                      " [routerLinkActive]="['active']">
                      {{"SIDE MENU.Appointment" | translate}}
                      <span class="chip chipBeta" *ngIf="isBeta('isModuleAppointmentActive')">&beta;</span>
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>

                <ng-container
                  *ngIf="getModuleActive('isModuleGoogleReviewActive') && currentPractice['Google_Review_Place_ID']">
                  <mat-list-item [matTooltip]="hideTooltip(appState.publicModules.isModuleGoogleReviewActive)" (click)="
                      openModule({
                        code: 'isModuleGoogleReviewActive',
                        isActive: appState.publicModules.isModuleGoogleReviewActive,
                        isBeta: isBeta('isModuleGoogleReviewActive'),
                        showModule: true,
                        goBack: true
                      })
                    ">
                    <a [ngClass]="{ 'disabled-list-item': !appState.publicModules.isModuleGoogleReviewActive }"
                      [routerLink]="
                        isNavigationEnabled(appState.publicModules.isModuleGoogleReviewActive, [
                          '/merchant/',
                          { outlets: { page: ['invitation-communication-list-overview', 'google-review'] } }
                        ])
                      " [routerLinkActive]="['active']">
                      {{"SIDE MENU.Google Review" | translate}}
                      <span class="chip chipBeta" *ngIf="isBeta('isModuleGoogleReviewActive')">&beta;</span>
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>

                <ng-container
                  *ngIf="healthHistoryActive  && context != 'p4p'  && context != 'breeze'  &&   getModuleActive('isModulePatientHistoryActive')">
                  <mat-list-item [matTooltip]="hideTooltip(appState.publicModules.isModulePatientHistoryActive)"
                    (click)="
                      openModule({
                        code: 'isModulePatientHistoryActive',
                        isActive: appState.publicModules.isModulePatientHistoryActive,
                        isBeta: isBeta('isModulePatientHistoryActive'),
                        showModule: true,
                        goBack: true
                      })
                    ">
                    <a [ngClass]="{ 'disabled-list-item': !appState.publicModules.isModulePatientHistoryActive }"
                      [routerLink]="
                        isNavigationEnabled(appState.publicModules.isModulePatientHistoryActive, [
                          '/merchant/',
                          { outlets: { page: ['invitation-communication-list-overview', 'medical-history'] } }
                        ])
                      " [routerLinkActive]="['active']">
                      {{"SIDE MENU.Health History" | translate}}
                      <span class="chip chipBeta" *ngIf="isBeta('isModulePatientHistoryActive')">&beta;</span>
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>

                <ng-container *ngIf="getModuleActive('isModuleSMSOfferActive')">
                  <mat-list-item [matTooltip]="hideTooltip(appState.publicModules.isModuleSMSOfferActive)" (click)="
                      openModule({
                        code: 'isModuleSMSOfferActive',
                        isActive: appState.publicModules.isModuleSMSOfferActive,
                        isBeta: isBeta('isModuleSMSOfferActive'),
                        showModule: true,
                        goBack: true
                      })
                    ">
                    <a [ngClass]="{ 'disabled-list-item': !appState.publicModules.isModuleSMSOfferActive }"
                      [routerLink]="
                        isNavigationEnabled(appState.publicModules.isModuleSMSOfferActive, [
                          '/merchant/',
                          { outlets: { page: ['instant-offer-list-overview'] } }
                        ])
                      " [routerLinkActive]="['active']">
                      Finance
                      <span class="chip chipBeta" *ngIf="isBeta('isModuleSMSOfferActive') == true">&beta;</span>
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>

                <ng-container *ngIf="getModuleActive('isModuleNewPatientInvitationActive')">
                  <mat-list-item [matTooltip]="hideTooltip(appState.publicModules.isModuleNewPatientInvitationActive)"
                    (click)="
                      openModule({
                        code: 'isModuleNewPatientInvitationActive',
                        isActive: appState.publicModules.isModuleNewPatientInvitationActive,
                        isBeta: isBeta('isModuleNewPatientInvitationActive'),
                        showModule: true,
                        goBack: true
                      })
                    ">
                    <a [ngClass]="{ 'disabled-list-item': !appState.publicModules.isModuleNewPatientInvitationActive }"
                      [routerLink]="
                        isNavigationEnabled(appState.publicModules.isModuleNewPatientInvitationActive, [
                          '/merchant/',
                          { outlets: { page: ['invitation-communication-list-overview', 'profile-update'] } }
                        ])
                      " [routerLinkActive]="['active']">
                      New {{ "SIDE MENU.New Patient" | translate }}
                      <span class="chip chipBeta" *ngIf="isBeta('isModuleNewPatientInvitationActive')">&beta;</span>
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>

                <ng-container>
                  <mat-list-item (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    ">
                    <a [routerLink]="['/merchant/', { outlets: { page: ['invitation-list-overview'] } }]"
                      [routerLinkActive]="['active']">
                      {{ "SIDE MENU.Treatment" | translate }}
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>

                <ng-container *ngIf="  context != 'p4p'  && context != 'breeze' ">
                  <mat-list-item (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    ">
                    <a [routerLink]="['/merchant/', { outlets: { page: ['invitation-list-draft'] } }]"
                      [routerLinkActive]="['active']">
                      {{"SIDE MENU.Draft" | translate}}
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>

                <ng-container>
                  <mat-list-item (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    ">
                    <a [routerLink]="['/merchant/', { outlets: { page: ['invitation-list-archives'] } }]"
                      [routerLinkActive]="['active']">
                      {{"SIDE MENU.Archive" | translate}}
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>

                <ng-container
                  *ngIf="getModuleActive('isModuleFacilityActive') && context != 'p4p'  && context != 'breeze' ">
                  <mat-list-item [matTooltip]="hideTooltip(appState.publicModules.isModuleFacilityActive)" (click)="
                      openModule({
                        code: 'isModuleFacilityActive',
                        isActive: appState.publicModules.isModuleFacilityActive,
                        isBeta: isBeta('isModuleFacilityActive'),
                        showModule: true,
                        goBack: true
                      })
                    ">
                    <a [ngClass]="{ 'disabled-list-item': !appState.publicModules.isModuleFacilityActive }"
                      [routerLink]="
                        isNavigationEnabled(appState.publicModules.isModuleFacilityActive, [
                          '/merchant/',
                          { outlets: { page: ['facility-list'] } }
                        ])
                      " [routerLinkActive]="['active']">
                      {{"SIDE MENU.Invitations Facility" | translate}}
                      <span class="chip chipBeta" *ngIf="isBeta('isModuleFacilityActive')">&beta;</span>
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>
              </mat-list>
            </div>
          </mat-expansion-panel>

          <!-- MARKETING -->
          <mat-expansion-panel *ngIf="
              getModuleActive('isModuleMarketingActive') ||
              getModuleActive('isModulePracticeLandingPageActive') ||
              getModuleActive('isModuleSMSOfferActive') ||
              getModuleActive('isModuleBulkSMS') ||
              getModuleActive('isModuleDocumentLinkActive')
            " @verticalScaleFadeAnimation>
            <mat-expansion-panel-header>
              <icon-view [icon]="getModuleIcon('isModuleMarketingActive', 'poll')"></icon-view>
              <span class="text"> {{"SIDE MENU.Marketing" | translate}} </span>
            </mat-expansion-panel-header>

            <div class="panel-content subNavAccordion">

              <button class="sideMenuButton" *ngIf="getModuleActive('isModuleBulkSMSActive')" (click)="bulkSMS()"
                mat-button>
                <mat-icon>add</mat-icon>
                New Bulk SMS
                <span class="chip chipBeta" style="
                      display: inline-block;
                      height: 16px;
                      line-height: 16px;
                      left: 126px;
                      width: 8px;
                      top: 5px;
                      box-shadow: none;
                  " *ngIf="isBeta('isModuleBulkSMSActive')">&beta;</span>
              </button>

              <button class="sideMenuButton" *ngIf="getModuleActive('isModuleMarketingActive')"
                (click)="newMarketingCampaign()" mat-button>
                <mat-icon>add</mat-icon>
                {{"SIDE MENU.New Marketing Campaign" | translate}}
                <span class="chip chipBeta" *ngIf="isBeta('isModuleMarketingActive')">&beta;</span>
              </button>

              <button class="sideMenuButton" *ngIf="getModuleActive('isModuleMarketingActive') != true" (click)="
                  openModule({
                    code: 'isModuleMarketingActive',
                    isActive: appState.publicModules.isModuleMarketingActive,
                    isBeta: isBeta('isModuleMarketingActive'),
                    showModule: true
                  })
                " mat-button>
                <mat-icon>add</mat-icon>
                {{"SIDE MENU.New Marketing Campaign" | translate}}

                <span class="chip chipBeta" *ngIf="isBeta('isModuleMarketingActive')">&beta;</span>
              </button>

              <button class="sideMenuButton" *ngIf="getModuleActive('isModuleDocumentLinkActive')"
                (click)="newLandingPage()" mat-button>
                <mat-icon>add</mat-icon>
                {{"SIDE MENU.New Landing Page" | translate}}
              </button>
              <mat-list class="sub-menu">
                <mat-divider></mat-divider>

                <ng-container *ngIf="getModuleActive('isModuleMarketingActive') && context != 'p4p' ">
                  <mat-list-item (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    ">
                    <a [routerLink]="['/merchant/', { outlets: { page: ['marketing-over-view'] } }]"
                      [routerLinkActive]="['active']">
                      {{"SIDE MENU.Campaign Manager" | translate}}

                      <span class="chip chipBeta" *ngIf="isBeta('isModuleMarketingActive')">&beta;</span>
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>

                <ng-container *ngIf="getModuleActive('isModuleBulkSMSActive') ">
                  <mat-list-item (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    ">
                    <a [routerLink]="['/merchant/', { outlets: { page: ['message-group-list-overview'] } }]"
                      [routerLinkActive]="['active']">
                      Bulk SMS Manager

                      <span class="chip chipBeta" *ngIf="isBeta('isModuleBulkSMSActive')">&beta;</span>
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>

                <ng-container>
                  <mat-list-item (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    ">
                    <a [routerLink]="['/merchant/', { outlets: { page: ['audiences-settings'] } }]"
                      [routerLinkActive]="['active']">
                      {{"SIDE MENU.Audiences" | translate}}
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>

                <ng-container *ngIf="context != 'p4p'  ">
                  <mat-list-item (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    ">
                    <a [routerLink]="['/merchant/', { outlets: { page: ['marketing-campaign-preview'] } }]"
                      [routerLinkActive]="['active']">
                      {{"SIDE MENU.Email and SMS" | translate}}
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>

                <ng-container *ngIf="getModuleActive('isModuleMarketingSocialMediaActive')">
                  <mat-list-item [matTooltip]="hideTooltip(appState.publicModules.isModuleMarketingSocialMediaActive)"
                    (click)="
                      openModule({
                        code: 'isModuleMarketingSocialMediaActive',
                        isActive: appState.publicModules.isModuleMarketingSocialMediaActive,
                        isBeta: isBeta('isModuleMarketingSocialMediaActive'),
                        showModule: true,
                        goBack: true
                      })
                    ">
                    <a [ngClass]="{ 'disabled-list-item': !appState.publicModules.isModuleMarketingSocialMediaActive }"
                      [routerLink]="
                        isNavigationEnabled(appState.publicModules.isModuleMarketingSocialMediaActive, [
                          '/merchant/',
                          { outlets: { page: ['marketing-social-campaign-preview'] } }
                        ])
                      " [routerLinkActive]="['active']">
                      {{"SIDE MENU.Social Media" | translate}}
                      <span class="chip chipBeta" *ngIf="isBeta('isModuleMarketingSocialMediaActive')">&beta;</span>
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>

                <ng-container *ngIf="getModuleActive('isModuleGoogleReviewActive') ">
                  <mat-list-item [matTooltip]="hideTooltip(appState.publicModules.isModuleGoogleReviewActive)" (click)="
                      openModule({
                        code: 'isModuleGoogleReviewActive',
                        isActive: appState.publicModules.isModuleGoogleReviewActive,
                        isBeta: isBeta('isModuleGoogleReviewActive'),
                        showModule: true,
                        goBack: true
                      })
                    ">
                    <a [ngClass]="{ 'disabled-list-item': !appState.publicModules.isModuleGoogleReviewActive }"
                      [routerLink]="
                        isNavigationEnabled(appState.publicModules.isModuleGoogleReviewActive, [
                          '/merchant/',
                          { outlets: { page: ['google-reviews'] } }
                        ])
                      " [routerLinkActive]="['active']">
                      {{"SIDE MENU.Google Reviews" | translate}}
                      <span class="chip chipBeta" *ngIf="isBeta('isModuleGoogleReviewActive')">&beta;</span>
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>

                <mat-expansion-panel class="inner-expend" @verticalScaleFadeAnimation>
                  <mat-expansion-panel-header>
                    <span class="text"> {{"SIDE MENU.Landing Pages" | translate}} </span>
                  </mat-expansion-panel-header>

                  <div class="panel-content subNavAccordion">
                    <mat-list class="sub-menu">
                      <mat-divider></mat-divider>

                      <ng-container *ngIf="getModuleActive('isModuleDocumentLinkActive')">
                        <mat-list-item [matTooltip]="hideTooltip(true)" (click)="
                            openModule({
                              code: 'isModuleDocumentLinkActive',
                              isActive: true,
                              isBeta: isBeta('isModuleDocumentLinkActive'),
                              showModule: false,
                              goBack: true
                            })
                          ">
                          <a [ngClass]="{ 'disabled-list-item': false }" [routerLink]="
                              isNavigationEnabled(appState.publicModules.isModuleDocumentLinkActive, [
                                '/merchant/',
                                { outlets: { page: ['document-link-list'] } }
                              ])
                            " [routerLinkActive]="['active']">
                            {{"SIDE MENU.Gallery" | translate}}
                            <span class="chip chipBeta"
                              *ngIf="isBeta('isModuleDocumentLinkActive') == true">&beta;</span>
                          </a>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                      </ng-container>

                      <ng-container
                        *ngIf="getModuleActive('isModulePracticeLandingPageActive')  && context != 'breeze' && context != 'p4p'">
                        <mat-list-item (click)="
                            openModule({
                              code: 'isModulePracticeLandingPageActive',
                              isActive: true,
                              isBeta: isBeta('isModulePracticeLandingPageActive'),
                              showModule: false,
                              goBack: true
                            })
                          ">
                          <a [ngClass]="{ 'disabled-list-item': false }" [routerLink]="
                              isNavigationEnabled(appState.publicModules.isModulePracticeLandingPageActive, [
                                '/merchant/',
                                { outlets: { page: ['landing', 'practice'] } }
                              ])
                            " [routerLinkActive]="['active']">
                            {{ 'SIDE MENU.Go Green - Practice Page' | translate }}
                          </a>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                      </ng-container>

                      <ng-container
                        *ngIf="getModuleActive('isModulePracticeLandingPageActive') && context != 'breeze' && context != 'p4p'">
                        <mat-list-item
                          [matTooltip]="hideTooltip(appState.publicModules.isModulePracticeLandingPageActive)" (click)="
                            openModule({
                              code: 'isModulePracticeLandingPageActive',
                              isActive: appState.publicModules.isModulePracticeLandingPageActive,
                              isBeta: isBeta('isModulePracticeLandingPageActive'),
                              showModule: true,
                              goBack: true
                            })
                          ">
                          <a [ngClass]="{
                              'disabled-list-item': !appState.publicModules.isModulePracticeLandingPageActive
                            }" [routerLink]="
                              isNavigationEnabled(appState.publicModules.isModulePracticeLandingPageActive, [
                                '/merchant/',
                                { outlets: { page: ['landing-redirect', 'dental'] } }
                              ])
                            " [routerLinkActive]="['active']">
                            {{ "SIDE MENU.Dental Tips and Offers" | translate }}
                          </a>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                      </ng-container>

                      <ng-container *ngIf="
                          getModuleActive('isModuleSelfServicePageActive') &&
                          ((context != 'breeze' ) || isAdminOrPromoter == true)
                        ">
                        <mat-list-item [matTooltip]="hideTooltip(appState.publicModules.isModuleSelfServicePageActive)"
                          (click)="
                            openModule({
                              code: 'isModuleSelfServicePageActive',
                              isActive: appState.publicModules.isModuleSelfServicePageActive,
                              isBeta: isBeta('isModuleSelfServicePageActive'),
                              showModule: true,
                              goBack: true
                            })
                          ">
                          <a [ngClass]="{
                              'disabled-list-item': !appState.publicModules.isModuleSelfServicePageActive
                            }" [routerLink]="
                              isNavigationEnabled(appState.publicModules.isModuleSelfServicePageActive, [
                                '/merchant/',
                                { outlets: { page: ['self-service-landing-page'] } }
                              ])
                            " [routerLinkActive]="['active']">
                            {{ "SIDE MENU.Patient Self Service" | translate }}

                            <span class="chip chipBeta">&beta;</span>
                          </a>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                      </ng-container>

                      <ng-container *ngIf="getModuleActive('isModuleSMSOfferActive')">
                        <mat-list-item [matTooltip]="hideTooltip(appState.publicModules.isModuleSMSOfferActive)"
                          (click)="
                            openModule({
                              code: 'isModuleSMSOfferActive',
                              isActive: appState.publicModules.isModuleSMSOfferActive,
                              isBeta: isBeta('isModuleSMSOfferActive'),
                              showModule: true,
                              goBack: true
                            })
                          ">
                          <a [ngClass]="{ 'disabled-list-item': !appState.publicModules.isModuleSMSOfferActive }"
                            [routerLink]="
                              isNavigationEnabled(appState.publicModules.isModuleSMSOfferActive, [
                                '/merchant/',
                                { outlets: { page: ['staff-portal'] } }
                              ])
                            " [routerLinkActive]="['active']">
                            {{"SIDE MENU.Staff Portal" | translate}}
                            <span class="chip chipBeta" *ngIf="isBeta('isModuleSMSOfferActive')">&beta;</span>
                          </a>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                      </ng-container>

                      <ng-container *ngIf="getModuleActive('isModuleSMSOfferActive')">
                        <mat-list-item [matTooltip]="hideTooltip(appState.publicModules.isModuleSMSOfferActive)"
                          (click)="
                            openModule({
                              code: 'isModuleSMSOfferActive',
                              isActive: appState.publicModules.isModuleSMSOfferActive,
                              isBeta: isBeta('isModuleSMSOfferActive'),
                              showModule: true,
                              goBack: true
                            })
                          ">
                          <a [ngClass]="{ 'disabled-list-item': !appState.publicModules.isModuleSMSOfferActive }"
                            [routerLink]="
                              isNavigationEnabled(appState.publicModules.isModuleSMSOfferActive, [
                                '/merchant/',
                                { outlets: { page: ['instant-offer-settings-overview'] } }
                              ])
                            " [routerLinkActive]="['active']">
                            {{"SIDE MENU.Instant Finance Offer" | translate}}
                            <span class="chip chipBeta" *ngIf="isBeta('isModuleSMSOfferActive')">&beta;</span>
                          </a>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                      </ng-container>

                    </mat-list>
                  </div>
                </mat-expansion-panel>
              </mat-list>
            </div>
          </mat-expansion-panel>

          <!-- MERCHANTS -->
          <mat-expansion-panel *ipvIsPromoterOrAdmin @verticalScaleFadeAnimation>
            <mat-expansion-panel-header>
              <mat-icon class="fas fa-store"></mat-icon>
              <span class="text"> {{"SIDE MENU.Merchants" | translate}} </span>
            </mat-expansion-panel-header>

            <div class="panel-content subNavAccordion">
              <mat-list class="sub-menu">
                <mat-divider></mat-divider>

                <mat-list-item (click)="
                    openModule({
                      code: '',
                      isBeta: false,
                      isActive: true,
                      goBack: true
                    })
                  ">
                  <a [routerLink]="['/merchant/', { outlets: { page: ['merchant-list'] } }]"
                    [routerLinkActive]="['active']">
                    {{"SIDE MENU.Active" | translate}}
                  </a>
                </mat-list-item>
                <mat-divider></mat-divider>

                <mat-list-item (click)="
                    openModule({
                      code: '',
                      isBeta: false,
                      isActive: true,
                      goBack: true
                    })
                  ">
                  <a [routerLink]="['/merchant/', { outlets: { page: ['merchant-list-not-active'] } }]"
                    [routerLinkActive]="['active']">
                    {{"SIDE MENU.Inactive" | translate}}
                  </a>
                </mat-list-item>
                <mat-divider></mat-divider>



                <mat-list-item (click)="
                    openModule({
                      code: '',
                      isBeta: false,
                      isActive: true,
                      goBack: true
                    })
                  ">
                  <a [routerLink]="['/merchant/', { outlets: { page: ['merchant-list-map'] } }]"
                    [routerLinkActive]="['active']">
                    {{"SIDE MENU.Location Map" | translate}}
                  </a>
                </mat-list-item>
                <mat-divider></mat-divider>

                <mat-list-item (click)="
                    openModule({
                      code: '',
                      isBeta: false,
                      isActive: true,
                      goBack: true
                    });
                    newChangeLogEvent()
                  ">
                  <a [routerLink]="['/merchant/', { outlets: { page: ['tasks-summary-list'] } }]"
                    [routerLinkActive]="['active']">
                    {{"SIDE MENU.Onboarding Tasks" | translate}}
                  </a>
                </mat-list-item>
                <mat-divider></mat-divider>

                <mat-list-item (click)="
                    openModule({
                      code: '',
                      isBeta: false,
                      isActive: true,
                      goBack: true
                    })
                  ">
                  <a [routerLink]="['/merchant/', { outlets: { page: ['merchant-prospect-list', 'S'] } }]"
                    [routerLinkActive]="['active']">
                    {{"SIDE MENU.Signed Up" | translate}}
                  </a>
                </mat-list-item>
                <mat-divider></mat-divider>

                <mat-list-item (click)="
                    openModule({
                      code: '',
                      isBeta: false,
                      isActive: true,
                      goBack: true
                    })
                  ">
                  <a [routerLink]="['/merchant/', { outlets: { page: ['merchant-prospect-list', '-'] } }]"
                    [routerLinkActive]="['active']">
                    {{"SIDE MENU.Registered on Portal" | translate}}
                  </a>
                </mat-list-item>
                <mat-divider></mat-divider>

                <mat-list-item (click)="
                    openModule({
                      code: '',
                      isBeta: false,
                      isActive: true,
                      goBack: true
                    })
                  ">
                  <a [routerLink]="['/merchant/', { outlets: { page: ['merchant-prospect-list'] } }]"
                    [routerLinkActive]="['active']">
                    {{"SIDE MENU.Prospect" | translate}}
                  </a>
                </mat-list-item>
                <mat-divider></mat-divider>
              </mat-list>
            </div>
          </mat-expansion-panel>

          <!-- PATIENTS -->
          <mat-expansion-panel *ngIf="getModuleActive('isModulePatientsActive')" @verticalScaleFadeAnimation
            [ngClass]="{ 'mat-expansion-panel-disabled': !appState.publicModules.isModulePatientsActive }"
            [matTooltip]="hideTooltip(appState.publicModules.isModulePatientsActive)"
            [hideToggle]="!appState.publicModules.isModulePatientsActive" (opened)="
              openModule({
                code: 'isModulePatientsActive',
                isActive: appState.publicModules.isModulePatientsActive,
                isBeta: isBeta('isModulePatientsActive'),
                showModule: true
              })
            ">
            <mat-expansion-panel-header>
              <icon-view [icon]="getModuleIcon('isModulePatientsActive', 'portrait')"></icon-view>
              <span class="text"> {{ "SIDE MENU.Patients" | translate }} </span>
              <span class="chip chipBeta" *ngIf="isBeta('isModulePatientsActive')">&beta;</span>
            </mat-expansion-panel-header>

            <div class="panel-content">
              <mat-accordion class="sideNavAccordion3 subNavAccordion">
                <button class="sideMenuButton" [matMenuTriggerFor]="ActionBtnmenu" mat-button>
                  <mat-icon>add</mat-icon>
                  {{ "SIDE MENU.New Patient" | translate }}
                  <span class="chip chipBeta" *ngIf="isBeta('isModuleNewPatientInvitationActive')">&beta;</span>
                </button>

                <mat-menu #ActionBtnmenu="matMenu">
                  <button (click)="createNewPatient()" mat-menu-item>
                    <mat-icon class="fas fa-user-plus mr"></mat-icon>
                    <span> {{"SIDE MENU.Quick Add" | translate}} </span>
                  </button>

                  <button [routerLink]="['/merchant/', { outlets: { page: ['existing-patient-create'] } }]"
                    mat-menu-item>
                    <mat-icon class="fas fa-user-tag mr"></mat-icon>
                    <span> {{"SIDE MENU.Detailed Add" | translate}} </span>
                  </button>
                </mat-menu>

                <mat-list class="sub-menu">
                  <mat-divider></mat-divider>

                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['customer-list-server'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.View List" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['customer-prospect-map-overview'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Locations Map" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container *ngIf="getModuleActive('isModuleMakeOverActive')">
                    <mat-list-item [matTooltip]="hideTooltip(appState.publicModules.isModuleMakeOverActive)" (click)="
                        openModule({
                          code: 'isModuleMakeOverActive',
                          isActive: appState.publicModules.isModuleMakeOverActive,
                          isBeta: isBeta('isModuleMakeOverActive'),
                          showModule: false,
                          goBack: true
                        })
                      ">
                      <a [ngClass]="{ 'disabled-list-item': !appState.publicModules.isModuleMakeOverActive }"
                        [routerLink]="
                          isNavigationEnabled(appState.publicModules.isModuleMakeOverActive, [
                            '/merchant/',
                            { outlets: { page: ['smile-makeover-manager'] } }
                          ])
                        " [routerLinkActive]="['active']">
                        <span class="chip chipBeta" *ngIf="isBeta('isModuleMakeOverActive')">&beta;</span>
                        {{"SIDE MENU.Smile Makeovers" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['customer-prospect-list-archives'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Archives" | translate}}
                      </a>
                    </mat-list-item>
                  </ng-container>
                </mat-list>

                <!-- PATIENTS REFERRALS -->
                <mat-expansion-panel *ngIf="getModuleActive('isModuleReferralActive')" @verticalScaleFadeAnimation
                  [ngClass]="{ 'mat-expansion-panel-disabled': !appState.publicModules.isModuleReferralActive }"
                  [matTooltip]="hideTooltip(appState.publicModules.isModuleReferralActive)"
                  [hideToggle]="!appState.publicModules.isModuleReferralActive" (opened)="
                    openModule({
                      code: 'isModuleReferralActive',
                      isActive: appState.publicModules.isModuleReferralActive,
                      isBeta: isBeta('isModuleReferralActive'),
                      showModule: true
                    })
                  ">
                  <mat-expansion-panel-header>
                    <icon-view [icon]="getModuleIcon('isModuleReferralActive', 'perm_phone_msg')"></icon-view>
                    <span class="text">{{"SIDE MENU.Referrals" | translate}} </span>
                    <span class="chip chipBeta" *ngIf="isBeta('isModuleReferralActive')">&beta;</span>
                  </mat-expansion-panel-header>

                  <div class="panel-content">
                    <mat-list class="sub-menu">
                      <button class="sideMenuButton"
                        [routerLink]="['/merchant/', { outlets: { page: ['referral-create'] } }]" mat-button>
                        <mat-icon>add</mat-icon>
                        {{"SIDE MENU.New Referral" | translate}}
                      </button>
                      <mat-divider></mat-divider>

                      <ng-container>
                        <mat-list-item (click)="
                            openModule({
                              code: '',
                              isBeta: false,
                              isActive: true,
                              goBack: true
                            })
                          ">
                          <a [routerLink]="['/merchant/', { outlets: { page: ['referral-list'] } }]"
                            [routerLinkActive]="['active']">{{"SIDE MENU.View List" | translate}}</a>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                      </ng-container>
                    </mat-list>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </mat-expansion-panel>

          <!-- PERFORMANCE -->
          <mat-expansion-panel
            *ngIf="getModuleActive('isModulePerformanceActive') && context != 'breeze' && context != 'p4p'"
            @verticalScaleFadeAnimation
            [ngClass]="{ 'mat-expansion-panel-disabled': !appState.publicModules.isModulePerformanceActive }"
            [matTooltip]="hideTooltip(appState.publicModules.isModulePerformanceActive)"
            [hideToggle]="!appState.publicModules.isModulePerformanceActive" (opened)="
              openModule({
                code: 'isModulePerformanceActive',
                isActive: appState.publicModules.isModulePerformanceActive,
                isBeta: isBeta('isModulePerformanceActive'),
                showModule: true
              })
            ">
            <mat-expansion-panel-header>
              <icon-view [icon]="getModuleIcon('isModulePerformanceActive', 'fas fa-tachometer-alt')"></icon-view>
              <span class="text"> {{"SIDE MENU.Performance" | translate}} </span>
              <span class="chip chipBeta" *ngIf="isBeta('isModulePerformanceActive')">&beta;</span>
            </mat-expansion-panel-header>

            <div class="panel-content subNavAccordion">
              <mat-list class="sub-menu">
                <ng-container>
                  <mat-list-item (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    ">
                    <a [routerLink]="['/merchant/', { outlets: { page: ['invitation-performance'] } }]"
                      [routerLinkActive]="['active']">
                      {{ "SIDE MENU.Practice Performance" | translate }}
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>

                <ng-container *ipvIsPromoterOrAdmin>
                  <ng-container *ngIf="promoterViewAs === 'promoter'">
                    <mat-list-item>
                      <a [routerLink]="['/merchant/', { outlets: { page: ['invitation-overview'] } }]"
                        [routerLinkActive]="['active']" (click)="
                          openModule({
                            code: '',
                            isBeta: false,
                            isActive: true,
                            goBack: true
                          })
                        ">

                        {{ "SIDE MENU.Invitation Performance" | translate }}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>
                </ng-container>
              </mat-list>
            </div>
          </mat-expansion-panel>

          <!-- SETTLEMENTS -->
          <mat-expansion-panel *ngIf="getModuleActive('isModuleSettlementsActive')" @verticalScaleFadeAnimation
            [ngClass]="{ 'mat-expansion-panel-disabled': !appState.publicModules.isModuleSettlementsActive }"
            [matTooltip]="hideTooltip(appState.publicModules.isModuleSettlementsActive)"
            [hideToggle]="!appState.publicModules.isModuleSettlementsActive" (opened)="
              openModule({
                code: 'isModuleSettlementsActive',
                isActive: appState.publicModules.isModuleSettlementsActive,
                isBeta: isBeta('isModuleSettlementsActive'),
                showModule: true
              })
            ">
            <mat-expansion-panel-header>
              <icon-view [icon]="getModuleIcon('isModuleSettlementsActive', 'fas fa-piggy-bank')"></icon-view>
              <span class="text"> {{"SIDE MENU.Settlements" | translate}} </span>
              <span class="chip chipBeta" *ngIf="isBeta('isModuleSettlementsActive')">&beta;</span>
            </mat-expansion-panel-header>

            <div class="panel-content subNavAccordion">
              <mat-list class="sub-menu">
                <button class="sideMenuButton" (click)="createSettlement()" mat-button>
                  <mat-icon>add</mat-icon>
                  {{"SIDE MENU.New Settlement Request" | translate}}
                </button>

                <button class="sideMenuButton"
                  [routerLink]="['/merchant/', { outlets: { page: ['settlement-refund-create'] } }]" (click)="
                    openModule({
                      code: '',
                      isBeta: false,
                      isActive: true,
                      goBack: true
                    })
                  " mat-button>
                  <mat-icon>add</mat-icon>
                  {{"SIDE MENU.New Refund Request" | translate}}
                </button>

                <ng-container>
                  <mat-list-item (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    ">
                    <a [routerLink]="['/merchant/', { outlets: { page: ['settlement-payments-report'] } }]"
                      [routerLinkActive]="['active']">
                      {{"SIDE MENU.Payments" | translate}}
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>

                <ng-container>
                  <mat-list-item (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    ">
                    <a [routerLink]="['/merchant/', { outlets: { page: ['settlement-customer-list-overview'] } }]"
                      [routerLinkActive]="['active']">
                      {{"SIDE MENU.Settlements" | translate}}
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>


                <mat-expansion-panel class="inner-expend" @verticalScaleFadeAnimation>
                  <mat-expansion-panel-header>
                    <span class="text">Requests </span>
                  </mat-expansion-panel-header>

                  <div class="panel-content subNavAccordion">
                    <mat-list class="sub-menu">


                      <ng-container>
                        <mat-list-item (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    ">
                          <a [routerLink]="['/merchant/', { outlets: { page: ['settlement-list-overview', 'PEND'] } }]"
                            [routerLinkActive]="['active']">
                            Pending {{"SIDE MENU.Settlements" | translate}}
                          </a>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                      </ng-container>


                      <ng-container>
                        <mat-list-item (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    ">
                          <a [routerLink]="['/merchant/', { outlets: { page: ['settlement-list-overview', 'REQU'] } }]"
                            [routerLinkActive]="['active']">
                            Requested {{"SIDE MENU.Settlements" | translate}}
                          </a>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                      </ng-container>

                      <ng-container>
                        <mat-list-item (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    ">
                          <a [routerLink]="['/merchant/', { outlets: { page: ['settlement-list-overview', 'APPR'] } }]"
                            [routerLinkActive]="['active']">
                            Approved {{"SIDE MENU.Settlements" | translate}}
                          </a>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                      </ng-container>

                      <ng-container>
                        <mat-list-item (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    ">
                          <a [routerLink]="['/merchant/', { outlets: { page: ['settlement-list-overview', 'DECL'] } }]"
                            [routerLinkActive]="['active']">
                            Declined {{"SIDE MENU.Settlements" | translate}}
                          </a>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                      </ng-container>


                    </mat-list>
                  </div>

                </mat-expansion-panel>

                <ng-container *ipvIsPromoterOrAdmin>
                  <ng-container *ngIf="promoterViewAs == 'promoter' && getModuleActive('isModuleMembershipActive')">
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['settlement-customer-list-overview'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Settlements Outstanding" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>
                </ng-container>

                <ng-container *ipvIsPromoterOrAdmin>
                  <ng-container *ngIf="promoterViewAs == 'promoter' && getModuleActive('isModuleMembershipActive')">
                    <mat-list-item [matTooltip]="hideTooltip(appState.publicModules.isModuleMembershipActive)" (click)="
                        openModule({
                          code: 'isModuleMembershipActive',
                          isActive: appState.publicModules.isModuleMembershipActive,
                          isBeta: isBeta('isModuleMembershipActive'),
                          showModule: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="
                          isNavigationEnabled(appState.publicModules.isModuleMembershipActive, [
                            '/merchant/',
                            { outlets: { page: ['settlement-list-membership'] } }
                          ])
                        " [routerLinkActive]="['active']">
                        {{"SIDE MENU.Memberships Settlements" | translate}}
                        <span class="chip chipBeta" *ngIf="isBeta('isModuleMembershipActive')">&beta;</span>
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>
                </ng-container>
              </mat-list>

              <!-- SETTLEMENTS SUB MENU -->
              <mat-accordion class="sideNavAccordion2">
                <!-- SETTLEMENTS FINANCED PATIENTS -->
                <ng-container *ipvIsPromoterOrAdmin>
                  <mat-expansion-panel *ngIf="getModuleActive('isModuleCustomerActive')"
                    [ngClass]="{ 'mat-expansion-panel-disabled': !appState.publicModules.isModuleCustomerActive }"
                    [matTooltip]="hideTooltip(appState.publicModules.isModuleCustomerActive)"
                    [hideToggle]="!appState.publicModules.isModuleCustomerActive" (opened)="
                      openModule({
                        code: 'isModuleCustomerActive',
                        isActive: appState.publicModules.isModuleCustomerActive,
                        isBeta: isBeta('isModuleCustomerActive'),
                        showModule: true
                      })
                    ">
                    <mat-expansion-panel-header>
                      <icon-view [icon]="getModuleIcon('isModuleCustomerActive', 'portrait')"></icon-view>
                      <span class="text"> {{ "SIDE MENU.Financed Patients" | translate }}</span>
                      <span class="chip chipBeta" *ngIf="isBeta('isModuleCustomerActive')">&beta;</span>
                    </mat-expansion-panel-header>

                    <div class="panel-content">
                      <mat-list class="sub-menu">
                        <mat-divider></mat-divider>

                        <ng-container>
                          <mat-list-item (click)="
                              openModule({
                                code: '',
                                isBeta: false,
                                isActive: true,
                                goBack: true
                              })
                            ">
                            <a [routerLink]="[
                                '/merchant',
                                { outlets: { page: ['invitation-list-overview', 'active'] } }
                              ]" [routerLinkActive]="['active']">
                              {{"SIDE MENU.Active Contracts" | translate}}
                            </a>
                          </mat-list-item>
                          <mat-divider></mat-divider>
                        </ng-container>

                        <ng-container>
                          <mat-list-item (click)="
                              openModule({
                                code: '',
                                isBeta: false,
                                isActive: true,
                                goBack: true
                              })
                            ">
                            <a [routerLink]="[
                                '/merchant',
                                { outlets: { page: ['invitation-list-overview', 'completed'] } }
                              ]" [routerLinkActive]="['active']">
                              {{"SIDE MENU.Completed Contracts" | translate}}
                            </a>
                          </mat-list-item>
                          <mat-divider></mat-divider>
                        </ng-container>
                      </mat-list>
                    </div>
                  </mat-expansion-panel>
                </ng-container>

                <!-- SETTLEMENTS QUOTATIONS -->
                <ng-container *ipvIsPromoterOrAdmin>
                  <mat-expansion-panel *ngIf="getModuleActive('isModuleContractActive')"
                    [ngClass]="{ 'mat-expansion-panel-disabled': !appState.publicModules.isModuleContractActive }"
                    [matTooltip]="hideTooltip(appState.publicModules.isModuleContractActive)"
                    [hideToggle]="!appState.publicModules.isModuleContractActive" (opened)="
                      openModule({
                        code: 'isModuleContractActive',
                        isActive: appState.publicModules.isModuleContractActive,
                        isBeta: isBeta('isModuleContractActive'),
                        showModule: false
                      })
                    ">
                    <mat-expansion-panel-header>
                      <mat-icon class="fas fa-comments-dollar"></mat-icon>
                      <span class="text"> {{"SIDE MENU.Quotations" | translate}} </span>
                      <span class="chip chipBeta" *ngIf="isBeta('isModuleContractActive')">&beta;</span>
                    </mat-expansion-panel-header>

                    <div class="panel-content">
                      <mat-list class="sub-menu">
                        <mat-divider></mat-divider>

                        <mat-list-item (click)="
                            openModule({
                              code: '',
                              isBeta: false,
                              isActive: true,
                              goBack: tru
                            })
                          ">
                          <a [routerLink]="['/merchant', { outlets: { page: ['quotation-list-overview'] } }]"
                            [routerLinkActive]="['active']">
                            {{"SIDE MENU.Quotation List" | translate}}
                          </a>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                      </mat-list>
                    </div>
                  </mat-expansion-panel>
                </ng-container>

                <!-- SETTLEMENTS CONTRACTS -->
                <ng-container *ipvIsPromoterOrAdmin>
                  <mat-expansion-panel *ngIf="getModuleActive('isModuleContractActive')"
                    [ngClass]="{ 'mat-expansion-panel-disabled': !appState.publicModules.isModuleContractActive }"
                    [matTooltip]="hideTooltip(appState.publicModules.isModuleContractActive)"
                    [hideToggle]="!appState.publicModules.isModuleContractActive" (opened)="
                      openModule({
                        code: 'isModuleContractActive',
                        isActive: appState.publicModules.isModuleContractActive,
                        isBeta: isBeta('isModuleContractActive'),
                        showModule: false
                      })
                    ">
                    <mat-expansion-panel-header>
                      <icon-view [icon]="getModuleIcon('isModuleContractActive', 'fas fa-file-contract')"></icon-view>
                      <span class="text"> {{"SIDE MENU.Contracts" | translate}} </span>
                      <span class="chip chipBeta" *ngIf="isBeta('isModuleContractActive')">&beta;</span>
                    </mat-expansion-panel-header>

                    <div class="panel-content">
                      <mat-list class="sub-menu">
                        <mat-divider></mat-divider>

                        <ng-container>
                          <mat-list-item (click)="
                              openModule({
                                code: '',
                                isBeta: false,
                                isActive: true,
                                goBack: true
                              })
                            ">
                            <a [routerLink]="['/merchant', { outlets: { page: ['contract-list-overview'] } }]"
                              [routerLinkActive]="['active']">
                              {{"SIDE MENU.Contract List" | translate}}
                            </a>
                          </mat-list-item>
                          <mat-divider></mat-divider>
                        </ng-container>
                      </mat-list>
                    </div>
                  </mat-expansion-panel>
                </ng-container>
              </mat-accordion>
            </div>
          </mat-expansion-panel>

          <!-- SALES -->
          <ng-container *ngIf=" context == 'breeze'             ">
            <mat-expansion-panel @verticalScaleFadeAnimation>
              <mat-expansion-panel-header>
                <icon-view [icon]="'fas fa-dollar-sign'"></icon-view>
                <span class="text"> {{"SIDE MENU.Sales" | translate}} </span>
              </mat-expansion-panel-header>

              <div class="panel-content subNavAccordion">
                <mat-list class="sub-menu">
                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['product-customers'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Serialised Products" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['product-invoices'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Invoices" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>
                </mat-list>
              </div>
            </mat-expansion-panel>
          </ng-container>

          <!-- SUPPLIERS -->
          <mat-expansion-panel
            *ngIf="getModuleActive('isModuleBeneficiaryActive') && context != 'breeze' && context != 'p4p'"
            @verticalScaleFadeAnimation
            [ngClass]="{ 'mat-expansion-panel-disabled': !appState.publicModules.isModuleBeneficiaryActive }"
            [matTooltip]="hideTooltip(appState.publicModules.isModuleBeneficiaryActive)"
            [hideToggle]="!appState.publicModules.isModuleBeneficiaryActive" (opened)="
              openModule({
                code: 'isModuleBeneficiaryActive',
                isActive: appState.publicModules.isModuleBeneficiaryActive,
                isBeta: isBeta('isModuleBeneficiaryActive'),
                showModule: true
              })
            ">
            <mat-expansion-panel-header>
              <icon-view [icon]="getModuleIcon('isModuleBeneficiaryActive', 'fas fa-parachute-box')"></icon-view>
              <span class="text">{{"SIDE MENU.Suppliers" | translate}}</span>
              <span class="chip chipBeta" *ngIf="isBeta('isModuleBeneficiaryActive')">&beta;</span>
            </mat-expansion-panel-header>

            <div class="panel-content subNavAccordion">
              <mat-list class="sub-menu">
                <ng-container *ipvIsPromoterOrAdmin>
                  <button class="sideMenuButton"
                    [routerLink]="['/merchant/', { outlets: { page: ['beneficiary-create'] } }]" mat-button>
                    <mat-icon>add</mat-icon>
                    {{"SIDE MENU.New Supplier" | translate}}
                  </button>
                </ng-container>

                <ng-container>
                  <mat-list-item (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    ">
                    <a [routerLink]="['/merchant/', { outlets: { page: ['beneficiary'] } }]"
                      [routerLinkActive]="['active']">
                      {{"SIDE MENU.Suppliers" | translate}}
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>

                <mat-list-item *ngIf="
                 appState && appState.publicSettings &&   ( appState.publicSettings.industryType === 'retail' ||
                    appState.publicSettings.industryType === 'breeze')
                  " (click)="
                    openModule({
                      code: '',
                      isBeta: false,
                      isActive: true,
                      goBack: true
                    })
                  ">
                  <a [routerLink]="['/merchant/', { outlets: { page: ['product-catalogue'] } }]"
                    [routerLinkActive]="['active']">
                    {{"SIDE MENU.Products" | translate}}
                  </a>
                </mat-list-item>
                <mat-divider></mat-divider>

                <ng-container>
                  <mat-list-item (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    ">
                    <a [routerLink]="['/merchant/', { outlets: { page: ['beneficiary-list-map'] } }]"
                      [routerLinkActive]="['active']">
                      {{"SIDE MENU.Location Map" | translate}}
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>
              </mat-list>
            </div>
          </mat-expansion-panel>

          <!-- TEMPLATE -->
          <ng-container *ipvIsPromoterOrAdmin>
            <mat-expansion-panel @verticalScaleFadeAnimation (opened)="
                openModule({
                  code: 'isModuleMarketingActive',
                  isActive: true,
                  isBeta: isBeta('isModuleMarketingActive'),
                  showModule: false
                })
              ">
              <mat-expansion-panel-header>
                <mat-icon>card_membership</mat-icon>
                <span class="text">{{"SIDE MENU.Templates" | translate}}</span>
                <span class="chip chipBeta" *ngIf="isBeta('isModuleMarketingActive')">&beta;</span>
              </mat-expansion-panel-header>

              <div class="panel-content subNavAccordion">
                <button class="sideMenuButton" [matMenuTriggerFor]="menuTemplate" mat-button>
                  <mat-icon>add</mat-icon>
                  {{"SIDE MENU.New Template" | translate}}
                </button>

                <mat-menu #menuTemplate="matMenu">
                  <button [routerLink]="['/merchant/', { outlets: { page: ['template-create'] } }]" (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    " mat-menu-item>
                    <mat-icon class="fas fa-mail-bulk mr"></mat-icon>
                    {{"SIDE MENU.Email SMS Template" | translate}}
                  </button>

                  <button [routerLink]="['/merchant/', { outlets: { page: ['template-social-media-create'] } }]"
                    (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    " mat-menu-item>
                    <mat-icon class="fas fa-hashtag mr"></mat-icon>
                    {{"SIDE MENU.Social Media Template" | translate}}
                  </button>

                  <button [routerLink]="['/merchant/', { outlets: { page: ['editor-component-create-redirect'] } }]"
                    (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    " mat-menu-item>
                    <mat-icon class="fas fa-align-justify mr"></mat-icon>
                    {{"SIDE MENU.Editor Template" | translate}}
                  </button>
                </mat-menu>

                <mat-list class="sub-menu">
                  <mat-divider></mat-divider>

                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['template-list-overview'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Email Templates" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['social-media-template-list'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Social Media Templates" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['editor-component-list-default'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Default Editors" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['editor-component-list-overview'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Editors Management" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['template-purpose-list'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Template Purpose" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>
                </mat-list>
              </div>
            </mat-expansion-panel>
          </ng-container>

          <!-- THIRD PARTY -->
          <ng-container *ipvIsPromoterOrAdmin>
            <mat-expansion-panel
              *ngIf="getModuleActive('isModuleThirdPartyActive') && appState.publicModules.isModuleThirdPartyActive"
              @verticalScaleFadeAnimation
              [ngClass]="{ 'mat-expansion-panel-disabled': !appState.publicModules.isModuleThirdPartyActive }"
              [matTooltip]="hideTooltip(appState.publicModules.isModuleThirdPartyActive)"
              [hideToggle]="!appState.publicModules.isModuleThirdPartyActive" (opened)="
                openModule({
                  code: 'isModuleThirdPartyActive',
                  isActive: appState.publicModules.isModuleThirdPartyActive,
                  isBeta: isBeta('isModuleThirdPartyActive'),
                  showModule: false
                })
              ">
              <mat-expansion-panel-header>
                <mat-icon class="fas fa-user-shield"></mat-icon>
                <span class="text"> {{"SIDE MENU.Third Party" | translate}} </span>
                <span class="chip chipBeta" *ngIf="isBeta('isModuleThirdPartyActive')">&beta;</span>
              </mat-expansion-panel-header>

              <div class="panel-content subNavAccordion">
                <mat-list class="sub-menu">
                  <mat-divider></mat-divider>

                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['third-party-client-list'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Application" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container>
                    <mat-list-item>
                      <a [routerLink]="['/merchant/', { outlets: { page: ['third-party-access-list-overview'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Access" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>
                </mat-list>
              </div>
            </mat-expansion-panel>
          </ng-container>

          <!-- TOOLS -->
          <ng-container *ipvIsPromoterOrAdmin>
            <mat-expansion-panel @verticalScaleFadeAnimation>
              <mat-expansion-panel-header>
                <mat-icon class="fas fa-toolbox"></mat-icon>
                <span class="text">{{"SIDE MENU.Tools" | translate}}</span>
              </mat-expansion-panel-header>

              <div class="panel-content subNavAccordion">
                <mat-list class="sub-menu">
                  <mat-divider></mat-divider>

                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['media-gallery-list'] } }]"
                        [routerLinkActive]="['active']">{{"SIDE MENU.Email Templates" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        });
                        newChangeLogEvent()
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['testimony-list'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Testimonials" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container *ngIf="getModuleActive('isModuleTipsActive')">
                    <mat-list-item [matTooltip]="hideTooltip(appState.publicModules.isModuleTipsActive)" (click)="
                        openModule({
                          code: 'isModuleTipsActive',
                          isActive: appState.publicModules.isModuleTipsActive,
                          isBeta: isBeta('isModuleTipsActive'),
                          showModule: false,
                          goBack: true
                        });
                        newChangeLogEvent()
                      ">
                      <a [routerLink]="
                          isNavigationEnabled(appState.publicModules.isModuleTipsActive, [
                            '/merchant/',
                            { outlets: { page: ['tip-over-view'] } }
                          ])
                        " [routerLinkActive]="['active']">
                        {{"SIDE MENU.Tips" | translate}}
                        <span class="chip chipBeta" *ngIf="isBeta('isModuleTipsActive')">&beta;</span>
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        });
                        newChangeLogEvent()
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['merchant-services-over-view'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Services" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container *ngIf="
                      appState && appState.publicSettings &&   ( appState.publicSettings.industryType === 'default' ||
                      appState.publicSettings.industryType === 'dental')
                    ">
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        });
                        newChangeLogEvent()
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['dentistry-types-over-view'] } }]"
                        [routerLinkActive]="['active']">
                        {{ "SIDE MENU.Dentistry Types" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container *ngIf="
                   appState && appState.publicSettings &&   (    appState.publicSettings.industryType === 'default' ||
                      appState.publicSettings.industryType === 'dental')
                    ">
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        });
                        newChangeLogEvent()
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['merchant-health-funds-over-view'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Health Funds" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container *ngIf="
                  appState && appState.publicSettings &&
                      getModuleActive('isModuleMakeOverActive') &&
                      (appState.publicSettings.industryType === 'default' ||
                        appState.publicSettings.industryType === 'dental')
                    ">
                    <mat-list-item [matTooltip]="hideTooltip(appState.publicModules.isModuleMakeOverActive)" (click)="
                        openModule({
                          code: 'isModuleMakeOverActive',
                          isActive: appState.publicModules.isModuleMakeOverActive,
                          isBeta: isBeta('isModuleMakeOverActive'),
                          showModule: false,
                          goBack: true
                        });
                        newChangeLogEvent()
                      ">
                      <a [routerLink]="
                          isNavigationEnabled(appState.publicModules.isModuleMakeOverActive, [
                            '/merchant/',
                            { outlets: { page: ['wishlist'] } }
                          ])
                        " [routerLinkActive]="['active']">
                        {{"SIDE MENU.Wishlist Manager" | translate}}
                        <span class="chip chipBeta" *ngIf="isBeta('isModuleMakeOverActive')">&beta;</span>
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        });
                        newChangeLogEvent()
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['site-settings', 'general'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Site Details" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        });
                        newChangeLogEvent()
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['third-party-client-list'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Third Party Clients" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        });
                        newChangeLogEvent()
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['patient-portal-assistance-overview'] } }]"
                        [routerLinkActive]="['active']">
                        {{ "SIDE MENU.Patient Portal Assistance" | translate }}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        });
                        newChangeLogEvent()
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['task-manager-list'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Merchant Task Manager" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container>
                    <mat-list-item (click)="
                  openModule({
                    code: '',
                    isBeta: false,
                    isActive: true,
                    goBack: true
                  });
                  newChangeLogEvent()
                ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['theme'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Themes" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: false
                        })
                      ">
                      <a (click)="versionReport()"> {{"SIDE MENU.Version Update" | translate}} </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        });
                        newChangeLogEvent()
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['translation'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Translation Management" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>
                </mat-list>
              </div>
            </mat-expansion-panel>
          </ng-container>

          <!-- TREATMENT -->
          <mat-expansion-panel
            *ngIf="getModuleActive('isModuleTreatmentGroupActive') && context!='p4p' && context!='breeze'"
            @verticalScaleFadeAnimation
            [ngClass]="{ 'mat-expansion-panel-disabled': !appState.publicModules.isModuleTreatmentGroupActive }"
            [matTooltip]="hideTooltip(appState.publicModules.isModuleTreatmentGroupActive)"
            [hideToggle]="!appState.publicModules.isModuleTreatmentGroupActive" (opened)="
              openModule({
                code: 'isModuleTreatmentGroupActive',
                isActive: appState.publicModules.isModuleTreatmentGroupActive,
                isBeta: isBeta('isModuleTreatmentGroupActive'),
                showModule: true
              })
            ">
            <mat-expansion-panel-header>
              <icon-view [icon]="getModuleIcon('isModuleTreatmentGroupActive', 'fas fa-notes-medical')"></icon-view>
              <span class="text">{{ "SIDE MENU.Treatment" | translate }} </span>
              <span class="chip chipBeta" *ngIf="isBeta('isModuleTreatmentGroupActive')">&beta;</span>
            </mat-expansion-panel-header>

            <div class="panel-content subNavAccordion">
              <ng-container *ipvIsPromoterOrAdmin>
                <app-treatment-group-new-button class="sideMenuButton" *ngIf="promoterViewAs === 'promoter'">
                </app-treatment-group-new-button>
              </ng-container>

              <mat-list class="sub-menu">
                <mat-divider></mat-divider>

                <ng-container>
                  <mat-list-item (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    ">
                    <a [routerLink]="['/merchant/', { outlets: { page: ['treatment-category-list'] } }]"
                      [routerLinkActive]="['active']">
                      {{"SIDE MENU.Schedule Of Services" | translate}}
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>

                <ng-container>
                  <mat-list-item (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    ">
                    <a [routerLink]="['/merchant/', { outlets: { page: ['treatment-type-list'] } }]"
                      [routerLinkActive]="['active']">
                      {{"SIDE MENU.Item Codes" | translate}}
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>

                <ng-container>
                  <mat-list-item (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    ">
                    <a *ipvIsPromoterOrAdmin="false"
                      [routerLink]="['/merchant/', { outlets: { page: ['treatment-brand-list'] } }]"
                      [routerLinkActive]="['active']">
                      {{"SIDE MENU.Brands" | translate}}
                    </a>

                    <a *ipvIsPromoterOrAdmin
                      [routerLink]="['/merchant/', { outlets: { page: ['treatment-brand-list-global'] } }]"
                      [routerLinkActive]="['active']">
                      {{"SIDE MENU.Brands" | translate}}
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>

                <ng-container>
                  <mat-list-item (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    ">
                    <a *ipvIsPromoterOrAdmin="false"
                      [routerLink]="['/merchant/', { outlets: { page: ['treatment-dynamic-product-list'] } }]"
                      [routerLinkActive]="['active']">
                      {{"SIDE MENU.Products Prices" | translate}}
                    </a>
                    <a *ipvIsPromoterOrAdmin
                      [routerLink]="['/merchant/', { outlets: { page: ['treatment-dynamic-product-list-global'] } }]"
                      [routerLinkActive]="['active']">
                      {{"SIDE MENU.Products Prices" | translate}}
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>

                <ng-container>
                  <mat-list-item (click)="
                      openModule({
                        code: '',
                        isBeta: false,
                        isActive: true,
                        goBack: true
                      })
                    ">
                    <a [routerLinkActive]="['active']"
                      [routerLink]="['/merchant/', { outlets: { page: ['treatment-document-centre'] } }]">
                      {{"SIDE MENU.Document Centre" | translate}}
                    </a>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>
              </mat-list>
            </div>
          </mat-expansion-panel>

          <!-- USERS -->
          <ng-container *ipvIsPromoterOrAdmin>
            <mat-expansion-panel @verticalScaleFadeAnimation>
              <mat-expansion-panel-header>
                <mat-icon class="fas fa-users"></mat-icon>
                <span class="text">{{"SIDE MENU.Users" | translate}}</span>
              </mat-expansion-panel-header>

              <div class="panel-content subNavAccordion">
                <mat-list class="sub-menu">
                  <mat-divider></mat-divider>

                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="['/merchant', { outlets: { page: ['active-sessions'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Connected Users" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="['/merchant', { outlets: { page: ['connection-history'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Connection History" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="['/merchant', { outlets: { page: ['operator-list-overview'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Operators" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>
                </mat-list>
              </div>
            </mat-expansion-panel>
          </ng-container>

          <!-- SETTINGS -->

          <mat-expansion-panel @verticalScaleFadeAnimation>
            <mat-expansion-panel-header>
              <mat-icon>settings</mat-icon>
              <span class="text">{{"SIDE MENU.Settings" | translate}}</span>
            </mat-expansion-panel-header>

            <div class="panel-content">
              <mat-accordion class="sideNavAccordion4 subNavAccordion">
                <mat-list class="sub-menu">
                  <mat-divider></mat-divider>

                  <ng-container *ngIf="getModuleActive('isModuleMembershipActive')">
                    <mat-list-item [matTooltip]="hideTooltip(appState.publicModules.isModuleOverviewActive)" (click)="
                        openModule({
                          code: 'isModuleOverviewActive',
                          isActive: appState.publicModules.isModuleOverviewActive,
                          isBeta: isBeta('isModuleOverviewActive'),
                          showModule: true,
                          goBack: true
                        })
                      ">
                      <a [ngClass]="{ 'disabled-list-item': !appState.publicModules.isModuleOverviewActive }"
                        [routerLink]="
                          isNavigationEnabled(appState.publicModules.isModuleOverviewActive, [
                            '/merchant/',
                            { outlets: { page: ['dentist-overview', 'public'] } }
                          ])
                        " [routerLinkActive]="['active']">
                        {{"SIDE MENU.Overview" | translate}}
                        <span class="chip chipBeta" *ngIf="isBeta('isModuleOverviewActive')">&beta;</span>
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container *ngIf="getModuleActive('isModuleMembershipActive')">
                    <mat-list-item [matTooltip]="hideTooltip(appState.publicModules.isModuleOverviewActive)" (click)="
                        openModule({
                          code: 'isModuleMembershipActive',
                          isActive: appState.publicModules.isModuleOverviewActive,
                          isBeta: isBeta('isModuleMembershipActive'),
                          showModule: false,
                          goBack: true
                        })
                      ">
                      <a [ngClass]="{ 'disabled-list-item': !appState.publicModules.isModuleOverviewActive }"
                        [routerLink]="
                          isNavigationEnabled(appState.publicModules.isModuleOverviewActive, [
                            '/merchant/',
                            { outlets: { page: ['membership-package-overview'] } }
                          ])
                        " [routerLinkActive]="['active']">
                        {{"SIDE MENU.Membership Settings" | translate}}
                        <span class="chip chipBeta" *ngIf="isBeta('isModuleMembershipActive')">&beta;</span>
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container>
                    <mat-list-item (click)="
                  openModule({
                    code: '',
                    isBeta: false,
                    isActive: true,
                    goBack: true
                  })
                ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['dentist-overview', 'practice'] } }]"
                        [routerLinkActive]="['active']">
                        {{ 'Side Menu.header.Settings.subheader.Practice' | translate }}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['dentist-overview', 'invitation'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Invitation" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container *ngIf="getModuleActive('isModuleMarketingActive')">
                    <mat-list-item [matTooltip]="hideTooltip(appState.publicModules.isModuleMarketingActive)" (click)="
                        openModule({
                          code: 'isModuleMarketingActive',
                          isActive: appState.publicModules.isModuleMarketingActive,
                          isBeta: isBeta('isModuleMarketingActive'),
                          showModule: true,
                          goBack: true
                        })
                      ">
                      <a [ngClass]="{ 'disabled-list-item': !appState.publicModules.isModuleMarketingActive }"
                        [routerLink]="
                          isNavigationEnabled(appState.publicModules.isModuleMarketingActive, [
                            '/merchant/',
                            { outlets: { page: ['dentist-overview', 'marketing'] } }
                          ])
                        " [routerLinkActive]="['active']">
                        {{"SIDE MENU.Marketing" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container
                    *ngIf="getModuleActive('isModuleAppointmentActive') && context != 'p4p'  && context != 'breeze' ">
                    <mat-list-item [matTooltip]="hideTooltip(appState.publicModules.isModuleAppointmentActive)" (click)="
                        openModule({
                          code: 'isModuleAppointmentActive',
                          isActive: appState.publicModules.isModuleAppointmentActive,
                          isBeta: isBeta('isModuleAppointmentActive'),
                          showModule: true,
                          goBack: true
                        })
                      ">
                      <a [ngClass]="{ 'disabled-list-item': !appState.publicModules.isModuleAppointmentActive }"
                        [routerLink]="['/merchant/', { outlets: { page: ['dentist-overview', 'appointment'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Appointments" | translate}}
                        <span class="chip chipBeta" *ngIf="isBeta('isModuleAppointmentActive')">&beta;</span></a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container
                    *ngIf="getModuleActive('isModulePatientHistoryActive')  && context != 'p4p'   && context != 'breeze' ">
                    <mat-list-item [matTooltip]="hideTooltip(appState.publicModules.isModulePatientHistoryActive)"
                      (click)="
                        openModule({
                          code: 'isModulePatientHistoryActive',
                          isActive: appState.publicModules.isModulePatientHistoryActive,
                          isBeta: isBeta('isModulePatientHistoryActive'),
                          showModule: true,
                          goBack: true
                        })
                      ">
                      <a [ngClass]="{ 'disabled-list-item': !appState.publicModules.isModulePatientHistoryActive }"
                        [routerLink]="['/merchant/', { outlets: { page: ['dentist-overview', 'medicalHistory'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Health History" | translate}}
                        <span class="chip chipBeta" *ngIf="isBeta('isModulePatientHistoryActive')">&beta;</span>
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <ng-container *ngIf="getModuleActive('isModuleThirdPartyActive')">
                    <mat-list-item [matTooltip]="hideTooltip(appState.publicModules.isModuleThirdPartyActive)" (click)="
                        openModule({
                          code: 'isModuleThirdPartyActive',
                          isActive: appState.publicModules.isModuleThirdPartyActive,
                          isBeta: isBeta('isModuleThirdPartyActive'),
                          showModule: false,
                          goBack: true
                        })
                      ">
                      <a [ngClass]="{ 'disabled-list-item': !appState.publicModules.isModuleThirdPartyActive }"
                        [routerLink]="['/merchant/', { outlets: { page: ['third-party-access-list'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Third Party Access" | translate}}
                        <span class="chip chipBeta" *ngIf="isBeta('isModuleThirdPartyActive')">&beta;</span>
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <mat-expansion-panel class="inner-expend" @verticalScaleFadeAnimation>
                    <mat-expansion-panel-header>
                      <span class="text"> {{"SIDE MENU.Templates" | translate}} </span>
                    </mat-expansion-panel-header>

                    <div class="panel-content subNavAccordion">
                      <mat-list class="sub-menu">
                        <mat-divider></mat-divider>

                        <ng-container *ngIf="context != 'p4p'  && context != 'breeze'">
                          <mat-list-item
                            [routerLink]="['/merchant/', { outlets: { page: ['informed-consent-template'] } }]">
                            <a> {{"SIDE MENU.Informed Consent" | translate}} </a>
                          </mat-list-item>
                          <mat-divider></mat-divider>
                        </ng-container>

                        <ng-container *ngIf="getModuleActive('isModuleNoteFeaturesActive')  ">
                          <mat-list-item [matTooltip]="hideTooltip(appState.publicModules.isModuleNoteFeaturesActive)"
                            (click)="
                              openModule({
                                code: 'isModuleNoteFeaturesActive',
                                isActive: appState.publicModules.isModuleNoteFeaturesActive,
                                isBeta: isBeta('isModuleNoteFeaturesActive'),
                                showModule: true,
                                goBack: true
                              })
                            ">
                            <a [ngClass]="{ 'disabled-list-item': !appState.publicModules.isModuleNoteFeaturesActive }"
                              [routerLink]="
                                isNavigationEnabled(appState.publicModules.isModuleNoteFeaturesActive, [
                                  '/merchant/',
                                  { outlets: { page: ['note-template'] } }
                                ])
                              " [routerLinkActive]="['active']">
                              {{"SIDE MENU.Notes" | translate}}
                              <span class="chip chipBeta" *ngIf="isBeta('isModuleNoteFeaturesActive')">&beta;</span>
                            </a>
                          </mat-list-item>
                          <mat-divider></mat-divider>
                        </ng-container>


                        <ng-container *ngIf="context != 'p4p'  && context != 'breeze'">
                          <mat-list-item
                            [routerLink]="['/merchant/', { outlets: { page: ['message-template', 'invitation'] } }]">
                            <a> {{"SIDE MENU.Personal Message" | translate}} </a>
                          </mat-list-item>
                          <mat-divider></mat-divider>
                        </ng-container>

                        <ng-container *ngIf="getModuleActive('isModulePatientSMSActive')">
                          <mat-list-item [matTooltip]="hideTooltip(appState.publicModules.isModulePatientSMSActive)"
                            (click)="
                              openModule({
                                code: 'isModulePatientSMSActive',
                                isActive: appState.publicModules.isModulePatientSMSActive,
                                isBeta: isBeta('isModulePatientSMSActive'),
                                showModule: true,
                                goBack: true
                              })
                            ">
                            <a [ngClass]="{ 'disabled-list-item': !appState.publicModules.isModulePatientSMSActive }"
                              [routerLink]="
                                isNavigationEnabled(appState.publicModules.isModulePatientSMSActive, [
                                  '/merchant/',
                                  { outlets: { page: ['sms-template'] } }
                                ])
                              " [routerLinkActive]="['active']">
                              {{"SIDE MENU.SMS" | translate}}
                              <span class="chip chipBeta" *ngIf="isBeta('isModulePatientSMSActive')">&beta;</span>
                            </a>
                          </mat-list-item>
                          <mat-divider></mat-divider>
                        </ng-container>

                        <ng-container
                          *ngIf="getModuleActive('isModuleTreatmentTemplateActive')  && context != 'p4p'  && context != 'breeze'">
                          <mat-list-item
                            [matTooltip]="hideTooltip(appState.publicModules.isModuleTreatmentTemplateActive)" (click)="
                              openModule({
                                code: 'isModuleTreatmentTemplateActive',
                                isActive: appState.publicModules.isModuleTreatmentTemplateActive,
                                isBeta: isBeta('isModuleTreatmentTemplateActive'),
                                showModule: true,
                                goBack: true
                              })
                            ">
                            <a [ngClass]="{
                                'disabled-list-item': !appState.publicModules.isModuleTreatmentTemplateActive
                              }" [routerLink]="
                                isNavigationEnabled(appState.publicModules.isModuleTreatmentTemplateActive, [
                                  '/merchant/',
                                  { outlets: { page: ['treatment-saved-template'] } }
                                ])
                              " [routerLinkActive]="['active']">
                              {{"SIDE MENU.Treatment" | translate}}
                              <span class="chip chipBeta"
                                *ngIf="isBeta('isModuleTreatmentTemplateActive')">&beta;</span>
                            </a>
                          </mat-list-item>
                          <mat-divider></mat-divider>
                        </ng-container>

                        <ng-container>
                          <mat-list-item (click)="linksDirectory()">
                            <a>Links / URL Directory </a>
                          </mat-list-item>
                          <mat-divider></mat-divider>
                        </ng-container>

                      </mat-list>
                    </div>
                  </mat-expansion-panel>

                  <mat-divider></mat-divider>

                  <mat-expansion-panel class="inner-expend" @verticalScaleFadeAnimation>
                    <mat-expansion-panel-header>
                      <span class="text">STAFF </span>
                    </mat-expansion-panel-header>

                    <div class="panel-content subNavAccordion">
                      <mat-list class="sub-menu">

                        <button class="sideMenuButton" [matMenuTriggerFor]="menuListSssssMS"
                          *ngIf="isAdminOrPromoter==true  || isMerchantAdmin==true" mat-button>
                          <mat-icon>add</mat-icon>
                          New Staff Member
                        </button>

                        <mat-menu #menuListSssssMS="matMenu">
                          <button (click)="createNewContact()" mat-menu-item>
                            <strong>Without</strong> Sign-in Account
                          </button>
                          <hr />
                          <button [routerLink]="['/merchant/', { outlets: { page: ['dentist-invite-create'] } }]"
                            mat-menu-item>
                            <strong>With</strong> Sign-in Account
                          </button>

                        </mat-menu>

                        <mat-divider></mat-divider>

                        <ng-container *ngIf="getModuleActive('isModulePatientSMSActive')">
                          <mat-list-item (click)="openCreateGlobalSMSContact()">
                            <a>
                              Staff SMS
                              <span class="chip chipBeta" *ngIf="isBeta('isModulePatientSMSActive')">&beta;</span>
                            </a>
                          </mat-list-item>
                          <mat-divider></mat-divider>
                        </ng-container>

                        <ng-container>
                          <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                            <a (click)="staffLook()">
                              Staff Lookup
                            </a>
                          </mat-list-item>
                          <mat-divider></mat-divider>
                        </ng-container>

                        <ng-container>
                          <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                            <a [routerLink]="['/merchant/', { outlets: { page: ['staff-list-overview'] } }]"
                              [routerLinkActive]="['active']">
                              Staff List
                            </a>
                          </mat-list-item>
                          <mat-divider></mat-divider>
                        </ng-container>

                      </mat-list>
                    </div>
                  </mat-expansion-panel>

                </mat-list>
              </mat-accordion>
            </div>
          </mat-expansion-panel>

          <hr *ngIf="(context != 'breeze' ) || isAdminOrPromoter == true" style="margin-top: 60px" />
          <!-- SUBSCRIPTION -->
          <mat-expansion-panel *ngIf="(context != 'breeze' ) || isAdminOrPromoter == true" @verticalScaleFadeAnimation>
            <mat-expansion-panel-header>
              <mat-icon class="fas fa-handshake"></mat-icon>
              <span class="text"> {{"SIDE MENU.Subscription" | translate}} </span>
            </mat-expansion-panel-header>

            <div class="panel-content subNavAccordion">
              <mat-list class="sub-menu">
                <mat-divider></mat-divider>

                <ng-container *ipvIsPromoterOrAdmin="false">
                  <ng-container *ngIf="currentPlanID">
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="[
                          '/merchant/',
                          { outlets: { page: ['subscription-plan-view-current', 'current', 'all'] } }
                        ]" [routerLinkActive]="['active']">
                        {{"SIDE MENU.My Subscription" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>
                </ng-container>

                <ng-container *ipvIsPromoterOrAdmin>
                  <ng-container *ngIf="currentPlanID">
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="[
                          '/merchant/',
                          { outlets: { page: ['subscription-plan-view-current', 'current', 'merchant'] } }
                        ]" [routerLinkActive]="['active']">
                        {{"SIDE MENU.My Subscription" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>
                </ng-container>

                <ng-container *ipvIsPromoterOrAdmin>
                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['subscription-plan-overview-all', 'all'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Overview" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>
                </ng-container>

                <ng-container *ipvIsPromoterOrAdmin>
                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['subscription-plan-list-card', 'all'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Pricing" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>
                </ng-container>

                <ng-container *ipvIsPromoterOrAdmin="false">
                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="[
                          '/merchant/',
                          { outlets: { page: ['subscription-plan-overview-all', 'merchant'] } }
                        ]" [routerLinkActive]="['active']">
                        {{"SIDE MENU.Overview" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>
                </ng-container>

                <ng-container *ipvIsPromoterOrAdmin="false">
                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="[
                          '/merchant/',
                          { outlets: { page: ['subscription-plan-list-card', 'merchant'] } }
                        ]" [routerLinkActive]="['active']">
                        {{"SIDE MENU.Pricing" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>
                </ng-container>

                <ng-container *ipvIsPromoterOrAdmin>
                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['main-feature-list', 'all'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Features" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>
                </ng-container>

                <ng-container *ipvIsPromoterOrAdmin="false">
                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['main-feature-list', 'merchant'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Features" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>
                </ng-container>

                <ng-container *ipvIsPromoterOrAdmin="false">
                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['module-list', 'merchant'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Modules" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>
                </ng-container>

                <ng-container *ipvIsPromoterOrAdmin>
                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['module-list', 'all'] } }]"
                        [routerLinkActive]="['active']">
                        {{"SIDE MENU.Modules" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>
                </ng-container>

                <ng-container *ipvIsPromoterOrAdmin>
                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['subscription-plan-management'] } }]"
                        [routerLinkActive]="['active']" (click)="goBackEvent()">
                        {{"SIDE MENU.Management" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>
                </ng-container>

                <ng-container *ipvIsPromoterOrAdmin>
                  <ng-container>
                    <mat-list-item (click)="
                        openModule({
                          code: '',
                          isBeta: false,
                          isActive: true,
                          goBack: true
                        })
                      ">
                      <a [routerLink]="['/merchant/', { outlets: { page: ['subscription-plan-users'] } }]"
                        [routerLinkActive]="['active']" (click)="goBackEvent()">
                        {{"SIDE MENU.Users" | translate}}
                      </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>
                </ng-container>
              </mat-list>
            </div>
          </mat-expansion-panel>

          <!-- TRAINING ACADEMY -->
          <mat-expansion-panel *ngIf="context != 'breeze'" @verticalScaleFadeAnimation (click)="booking()"
            hideToggle="true">
            <mat-expansion-panel-header>
              <mat-icon class="fas fa-user-graduate"></mat-icon>
              <span class="text"> {{"SIDE MENU.Training Academy" | translate}} </span>
            </mat-expansion-panel-header>
          </mat-expansion-panel>

          <!-- GET STARTED -->
          <mat-expansion-panel *ngIf=" context != 'breeze' && context != 'p4p'" @verticalScaleFadeAnimation
            [routerLink]="['/merchant/', { outlets: { page: ['getting-started-home'] } }]"
            [routerLinkActive]="['active']" hideToggle="true">
            <mat-expansion-panel-header>
              <mat-icon class="new warn-color">star</mat-icon>
              <span class="text"> {{"SIDE MENU.Get Started" | translate}}</span>
            </mat-expansion-panel-header>
          </mat-expansion-panel>

          <!-- HELP CENTRE -->
          <mat-expansion-panel *ngIf="getModuleActive('isModuleWikiActive') && context != 'breeze' "
            @verticalScaleFadeAnimation [hideToggle]="true"
            [routerLink]="isNavigationEnabled(appState.publicModules.isModuleWikiActive, ['/wiki/'])"
            [routerLinkActive]="['active']" [hideToggle]="true" (opened)="
              openModule({
                code: 'isModuleWikiActive',
                isActive: appState.publicModules.isModuleWikiActive,
                isBeta: isBeta('isModuleWikiActive'),
                showModule: false
              });
              newChangeLogEvent()
            ">
            <mat-expansion-panel-header>
              <icon-view [icon]="getModuleIcon('isModuleWikiActive', 'fas fa-info-circle')"></icon-view>
              <span class="text"> {{"SIDE MENU.Help Centre" | translate}} </span>
              <span class="chip chipBeta" *ngIf="isBeta('isModuleWikiActive')">&beta;</span>
            </mat-expansion-panel-header>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </nav>
  </div>

  <!-- CSS LOADER -->
  <div class="full-width text-center" *ngIf="mainModuleLoaded != true">
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  <div class="primary-gradient-img sidebar-gradient"></div>
</div>
