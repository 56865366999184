import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toNumber',
  pure: false,
})
export class ToNumberPipe implements PipeTransform {
  transform(value: any): number {
    try {
      return Number(value);
    } catch (e) {
      return 0;
    }
  }
}
