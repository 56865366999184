import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, ViewChildren } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { forkJoin, Observable } from 'rxjs';
import { DentistService } from '../../../feature/dentist/shared/dentist.service';
import { AddContactDentistryTypesProfileDialogComponent } from '../../../feature/merchant/dialogs/add-contact-dentistry-types-profile-dialog/add-contact-dentistry-types-profile-dialog.component';
import { AddDentistryTypesProfileDialogComponent } from '../../../feature/merchant/dialogs/add-dentistry-types-profile-dialog/add-dentistry-types-profile-dialog.component';
import { AddHealthFundProfileDialogComponent } from '../../../feature/merchant/dialogs/add-health-fund-profile-dialog/add-health-fund-profile-dialog.component';
import { AddServiceProfileDialogComponent } from '../../../feature/merchant/dialogs/add-service-profile-dialog/add-service-profile-dialog.component';
import { MerchantTradingHoursOverviewComponent } from '../../../feature/merchant/merchant-trading-hours-overview/merchant-trading-hours-overview.component';
import { MerchantService } from '../../../feature/merchant/shared/merchant.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { LookupService } from '../../../shared/services/lookup.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { SessionsService } from '../shared/sessions.service';

@Component({
  selector: 'session-over-view',
  templateUrl: './session-over-view.component.html',
  styleUrls: ['./session-over-view.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('10ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-10%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('10ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({
                  opacity: 0,
                  transform: 'translateY(-10%)',
                  offset: 1.0,
                }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class SessionOverViewComponent implements OnInit {
  @ViewChildren('sidenav') sidenav: MatSidenav;
  isPromoterOrAdmin = false;
  myPractice;
  currentUser;

  displayPalette = false;

  navMode = 'side';
  openFlag = true;

  titles = [];
  genders = [];
  preferredContactTimeOptions = [];
  preferredContactMethodOptions = [];
  preferredMessageTypeOptions = [];
  statusList = [];
  relationshipList = [];

  showProfileDescription = true;
  showProfileGallery = false;
  profileDescription = [];

  myOccupation;
  validateOccupation = false;

  profileImage = [];

  myPhoneNumber;
  myMobile;
  myEmail;
  myState;
  myPostCode;
  mySuburb;
  myStreetNumber;
  myStreetName;
  myStreetType;
  myUnitNumber;
  myLatitude;
  myLongitude;
  myMarketingConsent;
  myStatus;
  addressTypes;
  phoneTypes;
  mobileTypes;
  emailTypes;
  addressType;
  mobileType;
  phoneType;
  emailType;

  practiceSuburb;
  practiceState;
  practicePostCode;
  practiceStreetNumber;
  practiceStreetName;
  practiceUnitNumber;
  practiceStreetType;
  practiceLatitude;
  practiceLongitude;

  showPracticeDescription = true;
  showPracticeGallery = false;
  practiceDescription;
  practiceAppointmentPolicy;
  primaryColor;
  secondaryColor;
  practiceAppointmentHours = 24;
  disableEditPractice = true;

  isCreateTradingTime = false;
  merchantTimezone;
  tradingHours;

  util = new UtilsClass();
  birthdayDate;
  mobilePractice;
  phonePractice;
  emailPractice;
  merchantReplyToEmail;
  validateMobilePractice = false;
  validatePhonePractice = false;
  validateEmailPractice = false;
  isAdressPracticeValid = false;
  validateReplyToEmail = false;
  adrPractice;

  allowedExtensionTypes = ['image'];

  profileLink;
  practiceLink;
  practiceCoverLink;

  coverPic;

  @Input()
  settings = 'public';

  practiceURL;
  dentalURL;

  practiceURLQR;
  dentalURLQR;

  practiceLandingCode;
  practiceLandingCodeOriginal;
  practiceLandingCodeError = false;

  isNewProfilePicture = false;
  isNewPracticePicture = false;
  isNewPracticePictureCover = false;
  smsConfirmAppointment = false;
  smsAppointmentReminder = false;
  downloadQRCode = new EventEmitter();
  downloadQRCodeDental = new EventEmitter();
  timeZoneMerchantCode;
  timeZoneMerchantValue;
  timeZoneCode;
  timeZoneValue;

  merchantProfileServices = [];
  merchantProfileHealthFunds = [];
  merchantProfileDentistryTypes = [];
  contactProfileDentistryTypes = [];

  publicLink = Settings.global['publicSiteFrontendLink'];
  context = Settings.global['context'];
  practiceLegalName;

  constructor(
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private lookupService: LookupService,
    private merchantService: MerchantService,
    private SessionsService: SessionsService,
    private utilService: UtilsService,
    private router: Router,
    private _clipboardService: ClipboardService,
    private dentistService: DentistService
  ) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res3) => {
      this.isPromoterOrAdmin = res3;

      this.setupMyProfile();
      this.setupPracticeProfiel();
    });

    AuthenticationService.updateProfilePicture.subscribe((res) => {
      if (res) {
        this.isNewProfilePicture = !this.isNewProfilePicture;

        this.getProfilePictureTimer();
      }
    });

    AuthenticationService.updatePracticePicture.subscribe((res) => {
      if (res) {
        this.isNewPracticePicture = !this.isNewPracticePicture;
        this.getPracticePictureTimer();
      }
    });

    AuthenticationService.updatePracticePictureCover.subscribe((res) => {
      if (res) {
        this.isNewPracticePictureCover = !this.isNewPracticePictureCover;
        this.getPracticeCoverTimer();
      }
    });
  }

  getDate(e) {
    this.birthdayDate = e;
  }

  getResultTimeZone(e) {
    if (e) {
      if (e.code) {
        this.timeZoneCode = e.code;
      }

      if (e.value) {
        this.timeZoneValue = e.value;
      }
    }
  }

  getResultTimeZoneMerchant(e) {
    if (e) {
      if (e.code) {
        this.timeZoneMerchantCode = e.code;
      }

      if (e.value) {
        this.timeZoneMerchantValue = e.value;
      }
    }
  }

  setupMyProfile() {
    this.authenticationService.getCurrentUser().subscribe((res2) => {
      if (res2 && res2.success && res2.data.Role && (res2.data.Role == 'admin' || res2.data.Role == 'merchant-admin')) {
        this.disableEditPractice = false;
      }

      if (res2 && res2.success && res2.data.contactKey) {
        this.authenticationService.getCurrentDentist().subscribe((res) => {
          if (res) {
            this.currentUser = res;

            this.myPhoneNumber = this.currentUser['phones.Number'];
            this.myMobile = this.currentUser['mobiles.Number'];
            this.myEmail = this.currentUser['emails.Email'];

            this.myState = this.currentUser['addresses.State'];
            this.myPostCode = this.currentUser['addresses.Postcode'];
            this.mySuburb = this.currentUser['addresses.Suburb'];
            this.myStreetNumber = this.currentUser['addresses.Street Nr'];
            this.myStreetName = this.currentUser['addresses.Street Name'];
            this.myStreetType = this.currentUser['addresses.Street Type'];
            this.myUnitNumber = this.currentUser['addresses.Unit'];
            this.myLatitude = this.currentUser['Address.Latitude'];
            this.myLongitude = this.currentUser['Address.Longitude'];

            this.profileDescription = this.currentUser['Description'];

            this.myMarketingConsent = this.currentUser['MarketingConsent.Given'] == '1' ? true : false;

            this.myStatus = this.currentUser['Status'];
            this.myOccupation = this.currentUser['Occupation'];

            if (this.currentUser['addresses.Labels']) {
              this.addressTypes = this.currentUser['addresses.Labels'].split(';');
            }
            if (this.currentUser['phones.Labels']) {
              this.phoneTypes = this.currentUser['phones.Labels'].split(';');
            }
            if (this.currentUser['mobiles.Labels']) {
              this.mobileTypes = this.currentUser['mobiles.Labels'].split(';');
            }
            if (this.currentUser['emails.Labels']) {
              this.emailTypes = this.currentUser['emails.Labels'].split(';');
            }

            this.addressType = this.currentUser['addresses.Primary'];
            this.mobileType = this.currentUser['mobiles.Primary'];
            this.phoneType = this.currentUser['phones.Primary'];
            this.emailType = this.currentUser['emails.Primary'];
            if (this.myPractice) {
              this.primaryColor = this.myPractice['Colour.Primary'] || '#000';
              this.secondaryColor = this.myPractice['Colour.Accent'] || '#000';
            }
            this.setupLookup();
          }
        });

        this.getProfilePicture();
      }
    });
  }

  setupPracticeProfiel() {
    this.authenticationService.getCurrentPractice().subscribe((res1) => {
      this.myPractice = res1;

      this.merchantService
        .getMerchantProfileServicesItemsList({ Merchant_key: this.myPractice['ID'] })
        .subscribe((data) => {
          this.merchantProfileServices = data.sort((a, b) =>
            a['Service_Group_Item.Label'] > b['Service_Group_Item.Label'] ? 1 : -1
          );
        });

      this.merchantService
        .getMerchantProfileHealthFundsList({ Merchant_key: this.myPractice['ID'] })
        .subscribe((data) => {
          this.merchantProfileHealthFunds = data.sort((a, b) =>
            a['HealthFund.Label'] > b['HealthFund.Label'] ? 1 : -1
          );
        });

      this.merchantService
        .getMerchantProfileDentistryTypesList({ Merchant_key: this.myPractice['ID'] })
        .subscribe((data) => {
          this.merchantProfileDentistryTypes = data.sort((a, b) => (a.Label > b.Label ? 1 : -1));
        });

      this.dentistService
        .getContactProfileDentistryTypesList({ Contact_key: this.currentUser['ID'] })
        .subscribe((data) => {
          this.contactProfileDentistryTypes = data.sort((a, b) => (a.Label > b.Label ? 1 : -1));
        });

      if (this.myPractice['CardType'] == 'Commercial - Sole-Trader') {
        this.practiceLegalName =
          this.myPractice['Salutation'] + ' ' + this.myPractice['FirstName'] + ' ' + this.myPractice['Name'];
      } else {
        this.practiceLegalName = this.myPractice['Name'];
      }

      this.getTradingHours();

      this.mobilePractice = this.myPractice['mobiles.Number'];
      this.phonePractice = this.myPractice['phones.Number'];
      this.emailPractice = this.myPractice['emails.Email'];
      this.merchantReplyToEmail = this.myPractice['email.ReplyTo'];

      this.practiceSuburb = this.myPractice['addresses.Suburb'];
      this.practiceState = this.myPractice['addresses.State'];
      this.practicePostCode = this.myPractice['addresses.Postcode'];
      this.practiceStreetNumber = this.myPractice['addresses.Street Nr'];
      this.practiceStreetName = this.myPractice['addresses.Street Name'];
      this.practiceUnitNumber = this.myPractice['addresses.Unit'];
      this.practiceStreetType = this.myPractice['addresses.Street Type'];
      this.practiceLatitude = this.myPractice['Address.Latitude'];
      this.practiceLongitude = this.myPractice['Address.Longitude'];
      this.practiceDescription = this.myPractice['Description'];
      this.practiceAppointmentPolicy = this.myPractice['Appointment_Cancelling_Policy'];
      this.practiceAppointmentHours = this.myPractice['Appointment_Cancelling_Hours'];

      if (this.myPractice['SMS_ConfirmAppointment'] == '1') {
        this.smsConfirmAppointment = true;
      } else {
        this.smsConfirmAppointment = false;
      }

      if (this.myPractice['SMS_AppointmentReminder'] == '1') {
        this.smsAppointmentReminder = false;
      } else {
        this.smsAppointmentReminder = false;
      }

      this.getPracticePicture();
      this.getPracticePictureCover();

      const payload = { fields: 'Code' };
      if (!this.practiceURL || !this.dentalURL) {
        this.merchantService.practiceLandingPageOneByMerchant(this.myPractice.ID, payload).subscribe((res) => {
          this.practiceLandingCode = res.Code;
          this.practiceLandingCodeOriginal = res.Code;
          this.practiceURL = `${Settings.global['publicSiteFrontendLink']}/home/${res.Code}`;
          this.dentalURL = `${Settings.global['publicSiteFrontendLink']}/news/${res.Code}`;

          this.practiceURLQR = `${Settings.global['publicSiteFrontendLink']}/l/p/q/${res.Code}`;
          this.dentalURLQR = `${Settings.global['publicSiteFrontendLink']}/l/d/q/${res.Code}`;
        });
      }
    });
  }

  setupLookup() {
    this.lookupService.getLookup('SimpleLookup', 'Salutation').subscribe((res) => {
      this.titles = res;
    });

    this.lookupService.getLookup('CodeLookup', 'Gender').subscribe((res) => {
      this.genders = res;
    });

    this.lookupService.getLookup('CodeLookup', 'ContactTime').subscribe((res) => {
      this.preferredContactTimeOptions = res;
    });

    this.lookupService.getLookup('CodeLookup', 'ContactMethod').subscribe((res) => {
      this.preferredContactMethodOptions = res;
    });

    this.lookupService.getLookup('CodeLookup', 'MessageType').subscribe((res) => {
      this.preferredMessageTypeOptions = res;
    });
  }

  getOccupation(e) {
    if (e) {
      this.myOccupation = e.Label;
    }
  }

  validateOccupationEvent(v) {
    this.validateOccupation = v;
  }

  convertToLowerCase(val) {
    return val.toLowerCase();
  }

  getTradingHours() {
    const payload = {};
    if (this.myPractice['ID'] && this.isPromoterOrAdmin == true) {
      payload['merchantID'] = this.myPractice['ID'];
    }

    this.merchantService.getTradingHourList(payload, this.isPromoterOrAdmin).subscribe((res2) => {
      if (res2.length == 0 || res2.length < 7) {
        this.isCreateTradingTime = true;
      } else {
        this.merchantTimezone = res2[0]['TimeZone'];

        const order = {
          Monday: 1,
          Tuesday: 2,
          Wednesday: 3,
          Thursday: 4,
          Friday: 5,
          Saturday: 6,
          Sunday: 7,
        };

        this.tradingHours = res2.sort(function (a, b) {
          return order[a['DayOfWeek.Label.Singular']] - order[b['DayOfWeek.Label.Singular']];
        });
      }
    });
  }

  setupTradingHours() {
    const ref = RootAppComponent.dialog.open(MerchantTradingHoursOverviewComponent, {
      data: {
        merchantID: this.myPractice['ID'],
        isSetup: this.isCreateTradingTime,
      },
      width: '800px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
        this.getTradingHours();
      }
    });
  }

  getMobilePractice(m) {
    this.mobilePractice = m;
  }

  getPhonePractice(p) {
    this.phonePractice = p;
  }

  getEmailPractice(e) {
    this.emailPractice = e;
  }

  getReplyToEmail(e) {
    this.merchantReplyToEmail = e;
  }

  validateMobilePracticeEvent(v) {
    this.validateMobilePractice = v;
  }

  validatePhonePracticeEvent(v) {
    this.validatePhonePractice = v;
  }

  validateEmailPracticeEvent(v) {
    this.validateEmailPractice = v;
  }

  validateReplyToEmailEvent(v) {
    this.validateReplyToEmail = v;
  }

  getAdressPractice(adr) {
    if (adr) {
      this.adrPractice = {
        suburb: adr.suburb,
        state: adr.state,
        postCode: adr.postCode,
        streetNumber: adr.streetNumber,
        streetName: adr.streetName,
        unitNumber: adr.unitNumber,
        streetType: adr.streetType,
        latitude: adr.latitude || null,
        longitude: adr.longitude || null,
      };
    }
  }

  isAdressPracticeValidEvent(v) {
    this.isAdressPracticeValid = v || false;
  }

  getProfileContent(c) {
    this.profileDescription = c;
  }

  getPracticeContent(c) {
    this.practiceDescription = c;
  }

  getPracticeAppointmentPolicyContent(c) {
    this.practiceAppointmentPolicy = c;
  }

  editPractice() {
    const payload = {
      firstName: this.currentUser['FirstName'],
      midleName: this.currentUser['MiddleName'],
      lastName: this.myPractice['Name'],
      email: this.emailPractice,
      workPhone: this.phonePractice || 'void',
      mobile: this.mobilePractice || 'void',
      title: this.myPractice['Salutation'],
      abn: this.myPractice['ABN'] || 'void',
      acn: this.myPractice['ACN'] || 'void',
      tradingAs: this.myPractice['TradingAs'],
      website: this.myPractice['URL'] || 'void',
      description: this.practiceDescription || 'void',

      facebook: this.myPractice['Facebook'] || 'void',
      instagram: this.myPractice['Instagram'] || 'void',
      twitter: this.myPractice['Twitter'] || 'void',
      linkedin: this.myPractice['LinkedIn'] || 'void',
      pinterest: this.myPractice['Pinterest'] || 'void',
      tumblr: this.myPractice['Tumblr'] || 'void',
      vimeo: this.myPractice['Vimeo'] || 'void',
      url: this.myPractice['URL'] || 'void',

      streetName: this.adrPractice.streetName,
      streetNumber: this.adrPractice.streetNumber,
      streetType: this.adrPractice.streetType,
      unitNumber: this.adrPractice.unitNumber || 'void',
      suburb: this.adrPractice.suburb,
      state: this.adrPractice.state,
      postCode: this.adrPractice.postCode,
      latitude: this.adrPractice.latitude,
      longitude: this.adrPractice.longitude,
      primaryColour: this.primaryColor,
      secondaryColour: this.secondaryColor,

      // thirdPartyBooking_URL: this.myPractice['ThirdPartyBooking_URL'] || 'void',
      replyToEmail: this.merchantReplyToEmail || 'void',
      appointmentCancellingPolicy: this.practiceAppointmentPolicy,
      appointmentCancellingHours: this.practiceAppointmentHours,

      smsConfirmAppointment: this.smsConfirmAppointment,
      smsAppointmentReminder: this.smsAppointmentReminder,
      timeZoneCode: this.timeZoneMerchantCode || null,
      timeZoneValue: this.timeZoneMerchantValue || null,
    };

    this.authenticationService.editCurrentPractice(payload).subscribe((res) => {
      this.authenticationService.getCurrentUserForce().subscribe((res1) => {
        NotifyAppComponent.displayToast('success', 'Successed', 'Your profile has been updated successfully');
      });
    });
  }

  getPrimaryColour(e) {
    this.primaryColor = e;
  }

  getSecondaryColour(e) {
    this.secondaryColor = e;
  }

  // testURL(link) {
  //   // check if link has http or https and add it if not
  //   let url: string = '';
  //   if (!/^http[s]?:\/\//.test(link)) {
  //     url += 'https://';
  //   }
  //   url += link;
  //   window.open(url, '_blank', 'noopener');
  // }

  openSocialLink(link) {
    // check if link has http or https and add it if not
    let url = '';
    if (!/^http[s]?:\/\//.test(link)) {
      url += 'https://';
    }
    url += link;
    window.open(url, '_blank', 'noopener');
  }

  openEmailLink(link, name) {
    window.location.href = 'mailto:' + link + '?subject=New%20enquiry%20to%20' + name;
  }

  editProfile() {
    const payload = {
      firstName: this.currentUser['FirstName'],
      midleName: this.currentUser['MiddleName'] || 'void',
      lastName: this.currentUser['Name'],
      gender: this.currentUser['Gender.Code'],
      title: this.currentUser['Salutation'],
      birthday: this.birthdayDate || 'void',
      marketingConsent: this.myMarketingConsent,
      preferredContactMethod: this.currentUser['PreferredContactMethod.Code'],
      preferredContactTime: this.currentUser['PreferredContactTime.Code'],
      preferredMessageType: this.currentUser['PreferredMessageType.Code'],
      facebook: this.currentUser['Facebook'] || 'void',
      instagram: this.currentUser['Instagram'] || 'void',
      twitter: this.currentUser['Twitter'] || 'void',
      linkedin: this.currentUser['LinkedIn'] || 'void',
      occupation: this.myOccupation,
      pinterest: this.currentUser['Pinterest'] || 'void',
      tumblr: this.currentUser['Tumblr'] || 'void',
      vimeo: this.currentUser['Vimeo'] || 'void',
      url: this.currentUser['URL'] || 'void',
      description: this.profileDescription || 'void',
      timeZoneCode: this.timeZoneCode || null,
      timeZoneValue: this.timeZoneValue || null,
    };

    this.SessionsService.editProfile(payload).subscribe((res) => {
      this.authenticationService.getCurrentUserForce().subscribe((res1) => {
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Profile has been successfully updated');

        const confirm = new ConfirmDialog(
          'fas fa-info',
          '',
          'The changes will be applied to your session on the next login , Do You to Logout now ?',
          'No',
          'Yes'
        );

        const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
          data: confirm,
        });
        ref.componentInstance.onConfirm.subscribe((confirmation) => {
          if (confirmation === false) {
            ref.close();
          } else {
            this.authenticationService.logout().subscribe(
              (res) => {
                ref.close();

                this.activeRoute.params.subscribe((params) => {
                  this.authenticationService.initCurrUser();
                  if (params['consumerID']) {
                    this.router.navigate(['/login', params['consumerID']]);
                    // window.location.href = '/login/' + params['consumerID'];
                  } else {
                    this.router.navigate(['/login']);
                    // window.location.href = "/login";
                  }
                });
              },
              (err: any) => {
                console.log(err);
              }
            );
          }
        });
      });
    });
  }

  onCompleteAll(fileID) {
    if (fileID && fileID[0]) {
      const payload = {
        fileID: fileID[0],
      };
      this.SessionsService.uploadProfilePic(payload).subscribe((res) => {
        this.getProfilePicture();
        AuthenticationService.updateProfilePicture.emit(res);
      });
    }
  }

  onCompleteAllPractice(fileID) {
    if (fileID && fileID[0]) {
      const payload = {
        fileID: fileID[0],
      };
      this.SessionsService.uploadPracticePic(payload).subscribe((res) => {
        this.getPracticePicture();

        AuthenticationService.updatePracticePicture.emit(res);
      });
    }
  }

  onCompleteAllPracticeBackground(fileID) {
    if (fileID && fileID[0]) {
      const payload = {
        fileID: fileID[0],
      };
      this.SessionsService.uploadPracticePicCover(payload).subscribe((res) => {
        this.getPracticePictureCover();

        AuthenticationService.updatePracticePictureCover.emit(res);
      });
    }
  }

  getProfilePicture() {
    this.profileLink = null;

    this.profileLink = this.authenticationService.getProfilePicStreamLink();
  }

  getPracticePicture() {
    this.practiceLink = null;
    this.practiceLink = this.authenticationService.getPracticePicStreamLink();
  }

  getPracticePictureCover() {
    this.practiceCoverLink = null;
    this.practiceCoverLink = this.authenticationService.getPracticePicCoverStreamLink();
  }

  getPracticeCoverTimer() {
    this.practiceCoverLink = null;
    let countdown = 0;
    const interval = 1000;
    const duration = 3 * 1000;
    const stream$ = Observable.timer(0, interval)
      .finally(() => {
        this.practiceCoverLink = this.authenticationService.getPracticePicCoverStreamLink();
      })
      .takeUntil(Observable.timer(duration + interval))
      .map((value) => duration - value * interval);
    stream$.subscribe((value) => (countdown = value));
  }

  getProfilePictureTimer() {
    this.profileLink = null;
    let countdown = 0;
    const interval = 1000;
    const duration = 3 * 1000;
    const stream$ = Observable.timer(0, interval)
      .finally(() => {
        this.profileLink = this.authenticationService.getProfilePicStreamLink();
      })
      .takeUntil(Observable.timer(duration + interval))
      .map((value) => duration - value * interval);
    stream$.subscribe((value) => (countdown = value));
  }

  getPracticePictureTimer() {
    this.practiceLink = null;
    let countdown = 0;
    const interval = 1000;
    const duration = 3 * 1000;
    const stream$ = Observable.timer(0, interval)
      .finally(() => {
        this.practiceLink = this.authenticationService.getPracticePicStreamLink();
      })
      .takeUntil(Observable.timer(duration + interval))
      .map((value) => duration - value * interval);
    stream$.subscribe((value) => (countdown = value));
  }

  previewLandingPage() {
    window.open(this.practiceURL, '_blank');
  }

  previewDentalPage() {
    window.open(this.dentalURL, '_blank');
  }

  verifyPracticeLandingCode() {
    if (this.practiceLandingCode) {
      this.merchantService.practiceLandingPageExist(this.practiceLandingCode).subscribe((res) => {
        if (res == false) {
          this.practiceLandingCodeError = false;
        } else {
          NotifyAppComponent.displayToast('error', 'Success', 'This key word already exist, please choose another one');

          this.practiceLandingCodeError = true;
        }
      });
    } else {
      if (this.practiceLandingCodeOriginal) {
        this.practiceLandingCode = this.practiceLandingCodeOriginal;
        this.practiceLandingCodeError = false;
      }
    }
  }

  savePracticeLandingCode() {
    if (this.practiceLandingCode) {
      const payload = {
        code: this.practiceLandingCode,
      };
      this.merchantService.practiceLandingPageCreate(payload, this.isPromoterOrAdmin).subscribe((res) => {
        if (res) {
          this.practiceURL = `${Settings.global['publicSiteFrontendLink']}/home/${this.practiceLandingCode}`;
          this.dentalURL = `${Settings.global['publicSiteFrontendLink']}/news/${this.practiceLandingCode}`;

          this.practiceURLQR = `${Settings.global['publicSiteFrontendLink']}/l/p/q/${this.practiceLandingCode}`;
          this.dentalURLQR = `${Settings.global['publicSiteFrontendLink']}/l/d/q/${this.practiceLandingCode}`;
          NotifyAppComponent.displayToast('success', 'Success', 'Your Practice Page code has been updated');
          this.practiceLandingCodeOriginal = this.practiceLandingCode;
          this.practiceLandingCodeError = false;
        } else {
          NotifyAppComponent.displayToast(
            'error',
            'Success',
            'This key word is already exist, please choose another one'
          );

          this.practiceLandingCodeError = true;
        }
      });
    } else {
      if (this.practiceLandingCodeOriginal) {
        this.practiceLandingCode = this.practiceLandingCodeOriginal;
        this.practiceLandingCodeError = false;
      }
    }
  }

  linkCopy() {
    if (this.practiceLandingCode) {
      this._clipboardService.copyFromContent(
        `${Settings.global['publicSiteFrontendLink']}/home/${this.practiceLandingCode}`
      );
      NotifyAppComponent.displayToast('success', 'Copied!', '');
    }
  }

  linkCopy2() {
    if (this.practiceLandingCode) {
      this._clipboardService.copyFromContent(
        `${Settings.global['publicSiteFrontendLink']}/news/${this.practiceLandingCode}`
      );
      NotifyAppComponent.displayToast('success', 'Copied!', '');
    }
  }

  openAddServiceProfilePropertyDialog() {
    const addServiceProfileDialogRef = RootAppComponent.dialog.open(AddServiceProfileDialogComponent, {
      data: { merchantID: this.myPractice['ID'] },
      width: '500px',
    });

    addServiceProfileDialogRef.componentInstance.addServiceProfile.subscribe((changes) => {
      const servicesObservables = [];

      changes.newServices.forEach((newService) => {
        servicesObservables.push(this.merchantService.createMerchantProfileServicesItems(newService));
      });

      changes.removedServices.forEach((removedService) => {
        servicesObservables.push(this.merchantService.deleteMerchantProfileServicesItems(removedService));
      });

      forkJoin(servicesObservables).subscribe((data) => {
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Services Provided successfully updated.');

        addServiceProfileDialogRef.close();

        this.merchantService
          .getMerchantProfileServicesItemsList({ Merchant_key: this.myPractice['ID'] })
          .subscribe((data) => {
            this.merchantProfileServices = data.sort((a, b) =>
              a['Service_Group_Item.Label'] > b['Service_Group_Item.Label'] ? 1 : -1
            );
          });
      });
    });
  }

  openAddHealthFundProfileProperty() {
    const addHealthFundProfileDialogRef = RootAppComponent.dialog.open(AddHealthFundProfileDialogComponent, {
      data: { merchantID: this.myPractice['ID'] },
      width: '500px',
    });

    addHealthFundProfileDialogRef.componentInstance.addHealthFundProfile.subscribe((changes) => {
      const healthFundObservables = [];

      changes.newHealthFunds.forEach((newHealthFund) => {
        healthFundObservables.push(this.merchantService.createMerchantProfileHealthFund(newHealthFund));
      });

      changes.removedHealthFunds.forEach((removedHealthFund) => {
        healthFundObservables.push(this.merchantService.deleteMerchantProfileHealthFund(removedHealthFund));
      });

      forkJoin(healthFundObservables).subscribe((data) => {
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Health Funds successfully updated.');

        addHealthFundProfileDialogRef.close();

        this.merchantService
          .getMerchantProfileHealthFundsList({ Merchant_key: this.myPractice['ID'] })
          .subscribe((data) => {
            this.merchantProfileHealthFunds = data.sort((a, b) =>
              a['HealthFund.Label'] > b['HealthFund.Label'] ? 1 : -1
            );
          });
      });
    });
  }

  openAddDentistryTypesProperty() {
    const addDentistryTypesProfileDialogRef = RootAppComponent.dialog.open(AddDentistryTypesProfileDialogComponent, {
      data: { merchantID: this.myPractice['ID'] },
      width: '500px',
    });

    addDentistryTypesProfileDialogRef.componentInstance.addDentistryTypesProfile.subscribe((changes) => {
      const dentistryTypesObservables = [];

      changes.newDentistryTypes.forEach((newDentistryType) => {
        dentistryTypesObservables.push(this.merchantService.createMerchantProfileDentistryType(newDentistryType));
      });

      changes.removedDentistryTypes.forEach((removedDentistryType) => {
        dentistryTypesObservables.push(this.merchantService.deleteMerchantProfileDentistryType(removedDentistryType));
      });

      forkJoin(dentistryTypesObservables).subscribe((data) => {
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Dentistry Type successfully updated.');

        addDentistryTypesProfileDialogRef.close();

        this.merchantService
          .getMerchantProfileDentistryTypesList({ Merchant_key: this.myPractice['ID'] })
          .subscribe((data) => {
            this.merchantProfileDentistryTypes = data.sort((a, b) => (a.Label > b.Label ? 1 : -1));
          });
      });
    });
  }

  openAddContactDentistryTypesProperty() {
    const addContactDentistryTypesProfileDialogRef = RootAppComponent.dialog.open(
      AddContactDentistryTypesProfileDialogComponent,
      {
        data: { dentistID: this.currentUser['ID'] },
        width: '500px',
      }
    );

    addContactDentistryTypesProfileDialogRef.componentInstance.addContactDentistryTypesProfile.subscribe((changes) => {
      const dentistryTypesObservables = [];

      changes.newDentistryTypes.forEach((newDentistryType) => {
        dentistryTypesObservables.push(this.dentistService.createContactProfileDentistryType(newDentistryType));
      });

      changes.removedDentistryTypes.forEach((removedDentistryType) => {
        dentistryTypesObservables.push(this.dentistService.deleteContactProfileDentistryType(removedDentistryType));
      });

      forkJoin(dentistryTypesObservables).subscribe((data) => {
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Dentistry Type successfully updated.');

        addContactDentistryTypesProfileDialogRef.close();

        this.dentistService
          .getContactProfileDentistryTypesList({ Contact_key: this.currentUser['ID'] })
          .subscribe((data) => {
            this.contactProfileDentistryTypes = data.sort((a, b) => (a.Label > b.Label ? 1 : -1));
          });
      });
    });
  }
}
