import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { DateAdapter, MatDatepicker } from '@angular/material';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';

import * as moment from 'moment';
import { Moment } from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UserDataService } from '../../services/user-data.service';
import { ClientDetails } from '../../types/client-details';
import { APP_DATE_FORMATS, AppDateAdapterMonth } from '../../types/data-adapter';
import { Settings } from '../../types/settings';
import { UtilsClass } from '../../types/utils/utils.class';

@Component({
  selector: 'app-date-month-input',
  templateUrl: './date-month-input.component.html',
  styleUrls: ['./date-month-input.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapterMonth,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS,
    },
  ],
})
export class DateMonthInputComponent implements OnInit {
  inputDateClass = '';

  invitationID = null;
  @Input()
  displayDateInput = true;

  @Input()
  convertTimeZone = false;

  @Input()
  labelText = 'Select date';

  @Input()
  isEndDate = false;

  @Input()
  disabled = false;
  @Input()
  disabledAll = false;
  @Input()
  userDataName = null;

  @Input()
  label = 'Select Date';

  @Input()
  minAge = null;

  @Input()
  maxAge = null;

  @Input()
  minDate = null;

  @Input()
  maxDate = null;

  @Input()
  timePickerType = 'legacy';

  @Input()
  isClear = false;

  @Input('defaultDate')
  date = new Date();

  @Input()
  _date;

  @Input()
  defaultDays: any = 1;

  @Input()
  isTime = false;

  @Input()
  startView = 'multi-year';

  @Input()
  isDate = true;

  @Input('defaultTime')
  _time;

  @Output()
  selectedDate = new EventEmitter();

  @Output()
  selectedDateObject = new EventEmitter();

  @Output()
  getTime = new EventEmitter();

  util = new UtilsClass();

  isMobile = false;

  openedCalendar = false;

  _dateTime = new Date();

  selectedTime = '00:00';

  settings = Settings.global;

  random1 = 'date' + String(Math.random()).substr(3, 9);

  random2 = 'date' + String(Math.random()).substr(3, 9);

  option = { disableTextInput: false, timeFormat: Settings.global['dateTimeFormat'], showDuration: false };

  minCalendarDate = null;
  maxCalendarDate = null;

  hours = 0;

  mins = 0;
  sessionType;

  constructor(
    private activeRoute: ActivatedRoute,
    private userDataService: UserDataService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((r) => {
      this.sessionType = r;
      if (window.matchMedia('(min-width: 992px)').matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }

      this.setup();

      if (this.userDataName) {
        this.activeRoute.params.subscribe((params) => {
          if (params['consumerID']) {
            this.invitationID = params['consumerID'];
          } else if (params['invitationID']) {
            this.invitationID = params['invitationID'];
          }

          if (this.invitationID) {
            this.userDataService
              .getUserDataByFields(this.invitationID, [this.userDataName], this.sessionType)
              .subscribe((res) => {
                if (res) {
                  if (res[this.userDataName]) {
                    this._date = moment(res[this.userDataName], ClientDetails.formatUTC).toDate();
                    this.selectedTime = this.getTimeString(this._date);
                  } else if (this._date) {
                    this._date = moment()
                      .set('m', this._date.getMinutes() + 10)
                      .toDate();
                  }

                  if (this._date) {
                    this.selectedTime = this.getTimeString(new Date(this._date));
                  }

                  if (!this._date) {
                    this.inputDateClass = 'hidden-date';
                  }
                }
              });

            this.userDataService.userDataEvent.subscribe((data) => {
              if (data && data.content && this.util.selectObjectByString(data.content, this.userDataName)) {
                this._date = moment(
                  this.util.selectObjectByString(data.content, this.userDataName),
                  ClientDetails.formatUTC
                ).toDate();

                this.selectedTime = this.getTimeString(this._date);
                this.sendDate(this.selectedTime, false);

                if (!this._date) {
                  this.inputDateClass = 'hidden-date';
                }
              } else if (
                data &&
                data.content &&
                this.util.selectObjectByString(data.content, this.userDataName) === null
              ) {
                this.selectedTime = null;
                this.sendDate(this.selectedTime, false);
              }
            });
          }
        });
      } else {
        if (!this._date) {
          this.inputDateClass = 'hidden-date';
        }

        this.sendDate(null, false);
      }
    });
  }

  setup() {
    if (this.date != null) {
      if (this.convertTimeZone != true) {
        this._date = moment(this.date).toDate();
      } else if (this.isTime) {
        this._date = ClientDetails.convertTimeZone(this.date);
      }
    }

    if (this.isEndDate == true) {
      this.selectedTime = '23:59';
    } else {
      if (moment.isDate(this.date)) {
        this.selectedTime = `${this.date.getHours()}:${this.date.getMinutes()}`;
      }
    }

    if (this._time) {
      this.selectedTime = this._time;
    } else {
      if (moment.isDate(this.date)) {
        this.selectedTime = `${this.date.getHours()}:${this.date.getMinutes()}`;
      }
    }

    if (this.maxDate) {
      if (this.maxDate == 'today') {
        const date = ClientDetails.getMoment().add(1, 'day').toDate();
        this.maxCalendarDate = date;
      } else if (this.maxDate == 'toDay') {
        const date = ClientDetails.getMoment().add(1, 'day').toDate();
        this.maxCalendarDate = date;
      } else {
        this.maxCalendarDate = this.maxDate;
      }
    } else if (this.maxAge) {
      const date = new Date();
      date.setFullYear(date.getFullYear() - Number(this.minAge));
      this.maxCalendarDate = date;
    }

    if (this.minDate) {
      if (this.minDate == 'today') {
        const date = ClientDetails.getMoment().toDate();
        this.minCalendarDate = date;
      } else if (this.minDate == 'toDay') {
        const date = ClientDetails.getMoment().toDate();
        this.minCalendarDate = date;
      } else {
        this.minCalendarDate = this.minDate;
      }
    } else if (this.minAge) {
      const date = new Date();
      date.setFullYear(date.getFullYear() - Number(this.maxAge));
      this.minCalendarDate = date;
    }
    if (this.minCalendarDate) {
      this.minCalendarDate = new Date(this.minCalendarDate);
    }

    if (this.maxCalendarDate) {
      this.maxCalendarDate = new Date(this.maxCalendarDate);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.authenticationService.getSessionType().subscribe((r) => {
      this.sessionType = r;

      if (changes && changes.date && typeof changes.date.currentValue == 'string') {
        this.date = moment(changes.date.currentValue).toDate();
        this.setup();
      } else if (
        changes.date &&
        moment.isDate(changes.date.previousValue) &&
        moment(changes.date.previousValue).format() != moment(changes.date.currentValue).format() &&
        changes._time &&
        changes._time.previousValue != changes._time.currentValue
      ) {
        this.setup();
      } else if (
        changes.date &&
        moment.isDate(changes.date.previousValue) &&
        moment(changes.date.previousValue).format() != moment(changes.date.currentValue).format()
      ) {
        this.setup();
      } else if (
        changes.date &&
        moment.isDate(changes.date.currentValue) &&
        !changes.date.previousValue &&
        moment(changes.date.previousValue).format() != moment(changes.date.currentValue).format()
      ) {
        this.setup();
      } else if (changes._time && changes._time.previousValue != changes._time.currentValue) {
        this.setup();
      }

      if (
        (changes.maxDate && changes.maxDate.previousValue != changes.maxDate.currentValue) ||
        (changes.maxAge && changes.maxAge.previousValue != changes.maxAge.currentValue)
      ) {
        if (this.maxDate) {
          if (this.maxDate == 'today') {
            const date = ClientDetails.getMoment().add(1, 'day').toDate();
            this.maxCalendarDate = date;
          } else if (this.maxDate == 'toDay') {
            const date = ClientDetails.getMoment().add(1, 'day').toDate();
            this.maxCalendarDate = date;
          } else {
            this.maxCalendarDate = this.maxDate;
          }
        } else if (this.maxAge) {
          const date = new Date();
          date.setFullYear(date.getFullYear() - Number(this.minAge));
          this.maxCalendarDate = date;
        }
      }

      if (
        (changes.minDate && changes.minDate.previousValue != changes.minDate.currentValue) ||
        (changes.minAge && changes.minAge.previousValue != changes.minAge.currentValue)
      ) {
        if (this.minDate) {
          if (this.minDate == 'today') {
            const date = ClientDetails.getMoment().toDate();
            this.minCalendarDate = date;
          } else if (this.minDate == 'toDay') {
            const date = ClientDetails.getMoment().toDate();
            this.minCalendarDate = date;
          } else {
            this.minCalendarDate = this.minDate;
          }
        } else if (this.minAge) {
          const date = new Date();
          date.setFullYear(date.getFullYear() - Number(this.maxAge));
          this.minCalendarDate = date;
        }
      }

      if (changes.minCalendarDate && changes.minCalendarDate.previousValue != changes.minCalendarDate.currentValue) {
        if (this.minCalendarDate) {
          this.minCalendarDate = new Date(this.minCalendarDate);
        }
      }

      if (changes.maxCalendarDate && changes.maxCalendarDate.previousValue != changes.maxCalendarDate.currentValue) {
        if (this.maxCalendarDate) {
          this.maxCalendarDate = new Date(this.maxCalendarDate);
        }
      }
    });
  }

  openCalendar() {
    this.openedCalendar = true;
  }

  changeDate() {
    if (this._date == null) {
      this._date = new Date();
      this.inputDateClass = 'hidden-date';
      this.selectedDate.emit(null);
      this.getTime.emit(null);
    }
  }

  getTimeString(dd) {
    const _date = ClientDetails.getMoment(dd);

    let hours = String(_date.hours());
    if (hours.length < 2) {
      hours = '0' + hours;
    }

    let mins = String(_date.minutes());
    if (mins.length < 2) {
      mins = '0' + mins;
    }

    const timeString = hours + ':' + mins;

    return timeString;
  }

  changeTime(time) {
    this.selectedTime = time;
    this.changeDateTime();
    this.sendDate(this._date);
  }

  changeDateTime() {
    if (this.isTime == false && this.isDate == true) {
      if (!this._date) {
        this._date = ClientDetails.getDate();
      }

      this._date = ClientDetails.getMoment(this._date).set('h', 0).set('m', 0).toDate();
    } else if (this.selectedTime && this.isDate == true) {
      const parts = this.selectedTime.split(':');

      this.hours = 0;

      this.mins = 0;

      if (parts.length > 0) {
        this.hours = Number(parts[0]);

        this.mins = Number(parts[1]);

        if (parts[0] == 'PM') {
          this.hours = this.hours + 12;
        }

        if (this.hours >= 24) {
          this.hours = 0;
        }
      }

      if (!this._date) {
        this._date = ClientDetails.getDate();
      }

      this._date = ClientDetails.getMoment(this._date).set('h', this.hours).set('m', this.mins).toDate();
    } else if (this.isDate == true) {
      if (!this._date) {
        this._date = ClientDetails.getDate();
      }

      this._date = ClientDetails.getMoment(this._date).set('h', 0).set('m', 0).toDate();
    }
  }

  sendDate(d, saveUserData = true) {
    if (this._date != null && this._date != undefined) {
      let defaultDate = this.defaultDays;

      const year = moment(this._date).get('year');
      const month = moment(this._date).get('month');

      if (this.defaultDays == 'last') {
        const p = ClientDetails.getMoment()
          .set('year', year)
          .set('month', month + 1)
          .set('date', 1)
          .set('h', 0)
          .set('m', 0);

        defaultDate = p.add(-1, 'd').get('date');
      } else if (this.defaultDays) {
        defaultDate = this.defaultDays;
      }

      this._date = ClientDetails.getMoment()
        .set('year', year)
        .set('month', month)
        .set('date', defaultDate)
        .set('h', 0)
        .set('m', 0)
        .toDate();

      let result;
      let resultObject;
      this.selectedDate.emit(null);
      this.getTime.emit(null);

      if (this._date) {
        this.inputDateClass = '';
      } else {
        this.inputDateClass = 'hidden-date';
      }

      this.changeDateTime();

      if (this.isTime == false && this.isDate) {
        resultObject = ClientDetails.getMoment(this._date).set('h', 0).set('m', 0);
        result = resultObject.format(ClientDetails.formatUTC);
      } else if (this.isTime != false) {
        resultObject = ClientDetails.getMoment(this._date).set('h', this.hours).set('m', this.mins);
        result = resultObject.format(ClientDetails.formatUTC);
      }

      if (this.invitationID && this.userDataName && saveUserData == true) {
        UtilsClass.savingData();
        const saveDate = {
          attribute: this.userDataName,
          value: result,
        };

        this.userDataService.setUserData(this.invitationID, saveDate, this.sessionType).subscribe((res) => {
          this.selectedDate.emit(result);
          this.selectedDateObject.emit(resultObject);

          this.getTime.emit(this.selectedTime);
        });
      } else {
        this.selectedDate.emit(result);
        this.selectedDateObject.emit(resultObject);

        this.getTime.emit(this.selectedTime);
      }
    }
  }

  getTimeLabel() {
    if (this._date) {
      return 'Time';
    } else {
      return '';
    }
  }

  removeDate() {
    this._date = null;
    this.inputDateClass = 'hidden-date';
    this.selectedDate.emit(null);
    this.getTime.emit(this.selectedTime);
  }

  sendDateMobile(e) {
    const x = new Date(e).toISOString().substring(0, 10);
    this.selectedDate.emit(x);
    this.getTime.emit(this.selectedTime);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    let defaultDate = this.defaultDays;

    const year = moment(normalizedMonth).get('year');
    const month = moment(normalizedMonth).get('month');

    if (this.defaultDays == 'last') {
      const p = ClientDetails.getMoment()
        .set('year', year)
        .set('month', month + 1)
        .set('date', 1)
        .set('h', 0)
        .set('m', 0);

      defaultDate = p.add(-1, 'd').get('date');
    } else if (this.defaultDays) {
      defaultDate = this.defaultDays;
    }

    this._date = ClientDetails.getMoment()
      .set('year', year)
      .set('month', month)
      .set('date', defaultDate)
      .set('h', 0)
      .set('m', 0)
      .toDate();

    this.sendDate(this._date);

    datepicker.close();
  }
}
