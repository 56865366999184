<ng-container *ngIf="formMode !== 'list'">
  <div class="note-create-container">
    <hr/>

    <div style="display: flex; justify-content: space-between">
      <div class="sr-title ml" style="font-size: x-large; font-weight: bold; width: fit-content; line-height: 70px">
        <span *ngIf="formMode === 'create'"> Create Note </span>
        <span *ngIf="formMode === 'update'"> Update Note </span>
        <span *ngIf="formMode === 'view'"> View Note </span>
      </div>

      <ipv-note-timer
        [disabled]="timerDisabled"
        [startTime]="startTime"
        [endTime]="endTime"
        [duration]="duration"
        [showTimerButtons]="false"
        [startImmediately]="isFormModeCreate()"
        [forceEndTimeNow]="forceEndTimeNow"
        (getTimerValue)="setTimerValue($event)"
      >
      </ipv-note-timer>
    </div>

    <hr style="margin: 0px"/>

    <div class="note-create-form">
      <ipv-note-input
        *ngIf="formMode === 'create'"
        [patient]="patient"
        [practice]="practice"
        [dentist]="dentist"
        (textUpdated)="setNoteValue($event)"
      >
      </ipv-note-input>

      <mat-tab-group
        *ngIf="formMode === 'update' || formMode === 'view'"
        [selectedIndex]="selectedIndex"
        mat-stretch-tabs
      >
        <mat-tab label="Note">
          <ipv-note-input
            [disabled]="disabledUpdate"
            [text]="noteText"
            [patient]="patient"
            [practice]="practice"
            [dentist]="dentist"
            [size]="'small'"
            (textUpdated)="setNoteValue($event)"
          >
          </ipv-note-input>
        </mat-tab>

        <mat-tab label="Follow Up">
          <ipv-note-input
            [disabled]="disabledUpdate"
            [label]="'Enter a follow up note here'"
            [text]="followUpText"
            [patient]="patient"
            [practice]="practice"
            [dentist]="dentist"
            [size]="'small'"
            (textUpdated)="setFollowUpValue($event)"
          >
          </ipv-note-input>
        </mat-tab>
      </mat-tab-group>

      <div style="display: flex; justify-content: space-between; width: 100%">
        <div style="width: 20%">
          <app-date-range-picker
            class="note-date"
            [hasTimePicker]="false"
            [disabled]="disabledOptions"
            [showCustomRangeLabel]="false"
            [singleDatePicker]="true"
            [selectedRange]="'today'"
            [alwaysShowCalendars]="true"
            [dateLabel]="'Note Date'"
            (getRange)="setNoteDate($event)"
          >
          </app-date-range-picker>
        </div>

        <div *ngIf="formMode === 'create'" style="width: 30%; padding-top: 20px">
          <mat-checkbox [(ngModel)]="enableFollowUp"> Follow Up on This Note</mat-checkbox>
        </div>

        <div style="width: 15%">
          <app-date-range-picker
            class="follow-up-date"
            [selected]="initialDate"
            [disabled]="disabledOptions || !enableFollowUp"
            [hasTimePicker]="false"
            [showCustomRangeLabel]="false"
            [singleDatePicker]="true"
            [alwaysShowCalendars]="true"
            [dateLabel]="'Follow Up Date'"
            (getRange)="setFollowUpNoteDate($event)"
          >
          </app-date-range-picker>
        </div>

        <div style="width: 20%; margin-top: 5px">
          <mat-form-field class="smaller-input full-width" appearance="outline">
            <mat-label> Share With</mat-label>

            <mat-select
              [(ngModel)]="shareWithControlValue"
              [disabled]="formMode === 'view'"
              (selectionChange)="setEmptyOption($event.value)"
              name="share-select"
              multiple
            >
              <mat-select-trigger>
                <span>
                  {{ shareWithControlValue && shareWithControlValue.length > 0 ? shareWithControlValue[0] : 'Private' }}
                </span>

                <span *ngIf="shareWithControlValue?.length > 1">
                  (+{{ shareWithControlValue.length - 1 }}
                  {{ shareWithControlValue.length === 2 ? 'other' : 'others' }})
                </span>
              </mat-select-trigger>

              <mat-option [value]="'make private'" (click)="setPrivateShareOption()">
                {{ 'make private' | titlecase }}
              </mat-option>

              <mat-option
                *ngFor="let shareWithOption of shareWithOptions"
                [value]="shareWithOption"
                (click)="setPublicShareOption()"
              >
                {{ shareWithOption | titlecase }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div
          *ngIf="formMode === 'update' || formMode === 'view'"
          style="width: 30%"
          style="width: 30%; padding-top: 20px"
        >
          <mat-slide-toggle
            *ngIf="formMode === 'update' && selectedNote.RecontactDone === '0'"
            [(ngModel)]="recontactDone"
            color="primary"
          >
            {{ "KEYWORD.patient" | translate | titlecase }} re-contacted
          </mat-slide-toggle>

          <mat-slide-toggle *ngIf="formMode === 'view'" [(ngModel)]="recontactDone" [disabled]="true" color="primary">
            {{ "KEYWORD.patient" | translate | titlecase }} re-contacted
          </mat-slide-toggle>

          <mat-slide-toggle
            *ngIf="formMode === 'update' && selectedNote.RecontactDone === '1'"
            [(ngModel)]="recontactDone"
            [disabled]="true"
            color="primary"
          >
            {{ "KEYWORD.patient" | translate | titlecase }} re-contacted
          </mat-slide-toggle>
        </div>
      </div>

      <p class="small info left" *ngIf="getIsPrivateShareOptionSelected()">
        <mat-icon>info</mat-icon>
        <strong>Private note - this note is seen by you and no other team member.</strong>
      </p>

      <ng-container *ngIf="selectedNote">
        <p class="small info left" *ngIf="selectedNote.View_By_Private === '1'">
          <mat-icon>info</mat-icon>
          <strong>Private note - this note is seen by you and no other team member.</strong>
        </p>
      </ng-container>

      <mat-form-field class="note-type full-width" [appearance]="'outline'">
        <mat-label>Type</mat-label>
        <mat-select
          [(ngModel)]="selectedNoteAction"
          [compareWith]="compareAction"
          [disabled]="disabledOptions"
          (selectionChange)="getNoteSubject()"
          required
        >
          <mat-option *ngFor="let noteAction of noteActions" [value]="noteAction">
            {{ noteAction }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="note-subject full-width" [appearance]="'outline'">
        <mat-label>Subject</mat-label>
        <mat-select
          [(ngModel)]="selectedNoteSubject"
          [disabled]="!selectedNoteAction || disabledOptions"
          [compareWith]="compareSubjects"
          (selectionChange)="getNoteResults()"
          required
        >
          <mat-option *ngFor="let noteSubject of noteSubjects" [value]="noteSubject">
            {{ noteSubject }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="note-result full-width" [appearance]="'outline'">
        <mat-label>Result</mat-label>
        <mat-select
          [(ngModel)]="selectedNoteResult"
          [disabled]="!selectedNoteSubject || disabledOptions"
          [compareWith]="compareResults"
          required
        >
          <mat-option *ngFor="let noteResult of noteResults" [value]="noteResult">
            {{ noteResult }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-chip-list *ngIf="relationshipLink && relationshipLink.label !== null && formMode !== 'view'">
        <div class="sr-title mb" style="font-size: large; margin-right: 5px; padding-top: 20px">
          <span> Relationship: </span>
        </div>

        <mat-chip selected color="primary">
          <mat-icon> link</mat-icon>
          {{ relationshipLink.label | titlecase }}
        </mat-chip>
      </mat-chip-list>

      <mat-chip-list *ngIf="selectedNote && selectedNote.Relationship_Route !== '' && formMode === 'view'">
        <div class="sr-title mb" style="font-size: large; margin-right: 5px; padding-top: 20px; cursor: pointer">
          <span> Relationship: </span>
        </div>

        <mat-chip (click)="openRelationship()" selected color="primary">
          <mat-icon> link</mat-icon>
          {{ selectedNote.Relationship_Label | titlecase }}
        </mat-chip>
      </mat-chip-list>
    </div>

    <button
      class="cancel-note-button"
      *ngIf="formMode !== 'view'"
      (click)="setNoteListToListMode()"
      mat-raised-button
      color="primary"
    >
      <mat-icon> close</mat-icon>
      cancel
    </button>

    <button
      class="save-note-button"
      *ngIf="formMode === 'view'"
      (click)="setNoteListToListMode()"
      mat-raised-button
      color="accent"
    >
      <mat-icon> close</mat-icon>
      Close
    </button>

    <button
      class="update-note-button"
      *ngIf="formMode === 'update' && !this.disabledUpdate"
      [disabled]="
        !startTime ||
        duration === '00:00:00' ||
        !noteText ||
        !followUpText ||
        !selectedNoteAction ||
        !selectedNoteSubject ||
        !selectedNoteResult
      "
      (click)="updateNote()"
      mat-raised-button
      color="accent"
    >
      <mat-icon> save</mat-icon>
      update
    </button>

    <button
      class="save-note-button"
      *ngIf="formMode === 'create'"
      [disabled]="
        !noteText ||
        !selectedNoteAction ||
        !selectedNoteSubject ||
        !selectedNoteResult ||
        shareWithControlValue.length === 0
      "
      (click)="createNote()"
      mat-raised-button
      color="accent"
    >
      <mat-icon> save</mat-icon>
      save
    </button>
  </div>
</ng-container>
