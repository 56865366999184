<!-- <button mat-raised-button *ngIf="displayContractView!=true" color="primary" class="pull-right gradient green " matTooltip="New Settlement"
  [routerLink]="['/merchant/', {outlets: {'page': ['settlement-create']}}]">
  <mat-icon aria-hidden="true" class="fas fa-comment-dollar"></mat-icon> {{"SettlementNew" | translate}}
</button> -->
<!-- new settlement btn requested to be hidden -->

<div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="listDB.data.length == 0">

  <div [hidden]="displayContractView == true">


    <div class="pull-right selection-items" @ngIfAnimation>
      <button class="animated mr" (click)="selectAll()" mat-stroked-button color="" matTooltip="Select All">
        <mat-icon>border_all</mat-icon>
      </button>
      <button class="animated mr" (click)="selectPage()" mat-stroked-button color="" matTooltip="Select Page">
        <mat-icon>border_outer</mat-icon>
      </button>

      <button class="animated mr" *ngIf="selectedIDs && selectedIDs.length > 0" [matMenuTriggerFor]="ActionBtnmenu"
        mat-stroked-button matTooltip="Options">
        <mat-icon class="fas fa-cog"></mat-icon> <span *ngIf="selectedIDs && selectedIDs.length>0"
          class="selected-number-item  accent-background">{{selectedIDs.length}}</span>
      </button>
      <mat-menu #ActionBtnmenu="matMenu">
        <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
          <mat-icon>select_all</mat-icon> Deselect
        </button>

        <hr />



        <button class="row select-btn" *ngIf="selectedIDs && selectedIDs.length > 0" (click)="cancelGroup()"
          mat-menu-item color="">
          <mat-icon>cancel</mat-icon>
          Cancel
        </button>
        <hr />

        <button class="row select-btn" *ngIf="selectedIDs && selectedIDs.length > 0" (click)="removeGroup()"
          mat-menu-item color="">
          <mat-icon>delete</mat-icon>
          Remove
        </button>
      </mat-menu>
    </div>

    <h1 class="dataHeader">
      <!-- Contracts -->
      {{ listTitle | translate }}
      <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
    </h1>
    <mat-card class="list-card">
      <div class="row clearfix searchArea primary-gradient-img">
        <div class="row justify flex">
          <div class="full-width clearfix flex animated fadeInDown">
            <mat-icon class="schIcon">search</mat-icon>

            <mat-form-field class="half-width margin-btm" appearance="outline">
              <mat-label>Merchant</mat-label>
              <mat-select class="full-width" (selectionChange)="setFilter($event.value, 'MerchantName')" name="Merchant"
                placeholder="Merchant">
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option *ngFor="
                    let a of listDB.data | objectPropertyValue: 'MerchantName' | unique | without: ['', null] | orderBy
                  " [value]="a">
                  {{ a }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width margin-btm" appearance="outline">
              <mat-label>Search...</mat-label>
              <input class="clearfix" (input)="setFilter($event.target.value, 'fullName')" name="patientName"
                placeholder="Search..." matInput />
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- Table -->
      <table class="list-table" #table [dataSource]="dataSource" mat-table matSort>
        <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->
        <!-- ID Column -->

        <ng-container matColumnDef="selectCheckBox">
          <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header></th>
          <td *matCellDef="let row" mat-cell>
            <div class="margin-btm pull-left">
              <mat-checkbox [checked]="isSelected(row['ID'])" (change)="select(row['ID'], $event.checked)"
                color="accent">
              </mat-checkbox>
            </div>
          </td>
          <td class="largeFont" *matFooterCellDef mat-footer-cell></td>
        </ng-container>


        <ng-container matColumnDef="Date">
          <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header>Created Date</th>
          <td *matCellDef="let row" (click)="openView(row)" mat-cell
            matTooltip="{{ row['DateTimeCreated'] | customDate }}">
            {{ row['DateTimeCreated'] | customDateTime }}
          </td>
          <td class="largeFont" *matFooterCellDef mat-footer-cell></td>
        </ng-container>

        <ng-container matColumnDef="InvoiceReference">
          <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header>Invoice Reference</th>
          <td *matCellDef="let row" (click)="openView(row)" mat-cell>
            {{ row['Invoice.Reference']  }}
          </td>
          <td class="largeFont" *matFooterCellDef mat-footer-cell></td>
        </ng-container>


        <ng-container matColumnDef="RequestedDate">
          <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header>
            Requested Date
          </th>
          <td *matCellDef="let row" (click)="openView(row)" mat-cell
            matTooltip="{{ row['Requested.ISODateTime'] | customDate }}">
            {{ row['Requested.ISODateTime'] | customDateTime }}
          </td>
          <td class="largeFont" *matFooterCellDef mat-footer-cell></td>
        </ng-container>
        <ng-container matColumnDef="ApprovedDate">
          <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header>Approved Date</th>
          <td *matCellDef="let row" (click)="openView(row)" mat-cell
            matTooltip="{{ row['Approved.ISODateTime'] | customDate }}">
            {{ row['Approved.ISODateTime'] | customDateTime }}
          </td>
          <td class="largeFont" *matFooterCellDef mat-footer-cell></td>
        </ng-container>
        <ng-container matColumnDef="MerchantName">
          <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header>Merchant Name</th>
          <td *matCellDef="let row" (click)="openView(row)" mat-cell>



            {{ row['MerchantName'] }}
          </td>
          <td class="largeFont" *matFooterCellDef mat-footer-cell></td>
        </ng-container>

        <ng-container matColumnDef="merchantPicture">
          <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header></th>
          <td *matCellDef="let row" (click)="openView(row)" mat-cell>

            <app-card-picture class="sms-merchant-pic-settlement text-center pull-left " [hideZoomButton]="true"
              [cardID]="row.Merchant_key" [hideZoomButton]="false" [tableName]="'Merchant'">
            </app-card-picture>

          </td>
          <td class="largeFont" *matFooterCellDef mat-footer-cell></td>
        </ng-container>

        <ng-container matColumnDef="patientPicture">
          <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header></th>
          <td *matCellDef="let row" (click)="openView(row)" mat-cell>


            <app-card-picture class="sms-merchant-pic-settlement text-center pull-left " [hideZoomButton]="true"
              [cardID]="row.Customer_key" [hideZoomButton]="false" [tableName]="'Customer'">
            </app-card-picture>

          </td>
          <td class="largeFont" *matFooterCellDef mat-footer-cell></td>
        </ng-container>



        <ng-container matColumnDef="MaterialRequest">
          <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header>
            Material Advance
          </th>
          <td *matCellDef="let row" (click)="openView(row)" mat-cell>
            <span class="list-label smaller" *ngIf="row['Is_Materials_Request'] == '1'" matTooltip="Material Request"
              style="background-color: rgb(90, 186, 71);display: inline-block !important;">
              <mat-icon>check</mat-icon>
            </span>
            <span class="list-label smaller" *ngIf="row['Is_Materials_Request'] != '1'" matTooltip="Finance Settlement"
              style="background-color: rgb(239, 71, 39);display: inline-block !important;">
              <mat-icon>close</mat-icon>
            </span>
          </td>
          <td class="largeFont" *matFooterCellDef mat-footer-cell></td>
        </ng-container>





        <ng-container matColumnDef="PatientName">
          <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header>
            {{ "KEYWORD.patient" | translate | titlecase }}
            Name
          </th>
          <td *matCellDef="let row" (click)="openView(row)" mat-cell>


            {{ row['Customer.CalculatedName'] }}
          </td>
          <td class="largeFont" *matFooterCellDef mat-footer-cell></td>
        </ng-container>
        <ng-container matColumnDef="SettlementAmount">
          <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header>
            Settlement Amount
          </th>
          <td *matCellDef="let row" (click)="openView(row)" mat-cell
            matTooltip="{{ row['Settlement.Amount'] | customCurrency }}">
            <strong> {{ row['Settlement.Amount'] | customCurrency }} </strong>
          </td>
          <td class="largeFont" *matFooterCellDef mat-footer-cell>
            {{ getTotalColumn('Settlement.Amount') | customCurrency }}
          </td>
        </ng-container>
        <ng-container matColumnDef="SettlementType">
          <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header>Type</th>
          <td *matCellDef="let row" (click)="openView(row)" mat-cell>
            <span class="chip" *ngIf="row['SettleTo.TableName'] == 'Customer'" [style.background]="'red'">Refund</span>
            <span class="chip" *ngIf="row['SettleTo.TableName'] != 'Customer'"
              [style.background]="'green'">Drawdown</span>
          </td>
          <td class="largeFont" *matFooterCellDef mat-footer-cell></td>
        </ng-container>
        <ng-container matColumnDef="InvoiceAmount">
          <th class="largeFont" *matHeaderCellDef mat-sort-header> {{"KEYWORD.Treatment" | translate}} Amount</th>
          <td *matCellDef="let row" (click)="openView(row)" mat-cell
            matTooltip="{{ row['Invoice.Amount'] | customCurrency }}">
            {{ row['Invoice.Amount'] | customCurrency }}
          </td>
          <td class="largeFont" *matFooterCellDef mat-footer-cell></td>
        </ng-container>
        <!-- <ng-container cdkColumnDef="InvoiceType">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header class="largeFont"> {{"Type of Invoice" | translate}} </mat-header-cell>
          <mat-cell *cdkCellDef="let row" matTooltip="{{row['Invoice.Description'] | customCurrency}}"
             (click)="openView(row)"> {{row['Invoice.Description']
            | customCurrency}} </mat-cell>
        </ng-container> -->
        <ng-container matColumnDef="SettlementStatus">
          <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header>
            Settlement Status
          </th>
          >
          <td *matCellDef="let row" (click)="openView(row)" mat-cell matTooltip="{{ row['Status.Label'] }}">
            <span class="chip" [class.requested]="row['Status.Code'] == 'REQU'"
              [class.cancelled]="row['Status.Code'] == 'WDRN'" [class.declined]="row['Status.Code'] == 'DECL'"
              [class.pending]="row['Status.Code'] == 'PEND'"
              [class.approved]="row['Status.Code'] == 'APPR'">

              <mat-icon class="{{row['Status.Icon']}} smr" style="font-size: 0.8rem !important;"></mat-icon>

              {{ row['Status.Label'] }}
            </span>
          </td>
          <td class="largeFont" *matFooterCellDef mat-footer-cell></td>
        </ng-container>

        <ng-container matColumnDef="RequestedBy">
          <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header>Requested By</th>
          <td *matCellDef="let row" (click)="openView(row)" mat-cell>
            {{ settleUtil.getName(row['Requested.By_Name']) }}
          </td>
          <td *matFooterCellDef mat-footer-cell></td>
        </ng-container>

        <ng-container matColumnDef="ApprovedBy">
          <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header>Approved By</th>
          <td *matCellDef="let row" (click)="openView(row)" mat-cell>
            {{ settleUtil.getName(row['Approved.By_Name']) }}
          </td>
          <td *matFooterCellDef mat-footer-cell></td>
        </ng-container>

        <ng-container matColumnDef="PaymentStatus">
          <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header>
            Payment Status
          </th>
          <td *matCellDef="let row" (click)="openView(row)" mat-cell matTooltip="{{ row['PaymentStatus.Label'] }}">
            <span class="chip" [class.requested]="row['PaymentStatus.Label'] == 'Suspended'"
              [class.cancelled]="row['PaymentStatus.Label'] == 'Cancelled'"
              [class.declined]="row['PaymentStatus.Label'] == 'Blocked'"
              [class.pending]="row['PaymentStatus.Label'] == 'Pending'"
              [class.approved]="row['PaymentStatus.Label'] == 'Paid'">{{ row['PaymentStatus.Label'] || '...' }}
            </span>
          </td>
          <td class="largeFont" *matFooterCellDef mat-footer-cell></td>
        </ng-container>

        <ng-container matColumnDef="ConsentedVia">
          <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header>Consented Via</th>
          <td *matCellDef="let row" (click)="openView(row)" mat-cell>
            <span *ngIf="row['ConsentedViaPaperForm'] == '0'">SMS</span>
            <span *ngIf="row['ConsentedViaPaperForm'] == '1'">FORM</span>
          </td>
          <td class="largeFont" *matFooterCellDef mat-footer-cell>Totals</td>
        </ng-container>
        <!-- hidden field colour -->
        <ng-container matColumnDef="fieldColor">
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let row" [class.requested]="row['Status.Code'] == 'REQU'"
            [class.cancelled]="row['Status.Code'] == 'WDRN'" [class.declined]="row['Status.Code'] == 'DECL'"
            [class.pending]="row['Status.Code'] == 'PEND'" [class.approved]="row['Status.Code'] == 'APPR'" mat-cell>
          </td>
          <td class="largeFont" *matFooterCellDef mat-footer-cell></td>
        </ng-container>

        <ng-container matColumnDef="Actions">
          <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header>Actions</th>
          <td *matCellDef="let row" mat-cell>
            <div style="float: right">

              <app-invitation-actions *ngIf="row" [customerID]="row['Customer_key']" [settlement]="row" [items]="items"
                [settlementOnList]="false" [contractID]="row['Contract_key']" [merchantID]="row['Merchant_Key']"
                [productID]="row['Product_key']" (getSettlementUpdate)="getSettlementUpdate($event)">
              </app-invitation-actions>


              <!-- <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button> -->
              <mat-menu #menu="matMenu">
                <button *ngIf="row['ID']" (click)="openView(row)" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span>Settlement Quick View</span>
                </button>

                <button *ngIf="row['Contract_key']" (click)="openView(row)" mat-menu-item>
                  <mat-icon>subject</mat-icon>
                  <span>Contract View</span>
                </button>

                <button *ngIf="row['Customer_key']" (click)="viewPatient(row['Customer_key'])" mat-menu-item>
                  <mat-icon>person</mat-icon>
                  <span>{{ "KEYWORD.patient" | translate | titlecase }} Profile</span>
                </button>

                <button *ngIf="row['Status.Code'] == 'PEND'" (click)="requestSettlement(row['ID'])" mat-menu-item
                  color="warn">
                  <mat-icon>description</mat-icon>
                  <span>Continue settlement request</span>
                </button>

                <button *ngIf="row['Status.Code'] == 'PEND' || row['Status.Code'] == 'REQU'"
                  (click)="withdrawSettlement(row['ID'], row['DrawDownGroupID'])" mat-menu-item color="warn">
                  <mat-icon>block</mat-icon>
                  <span>Withdraw settlement </span>
                </button>

                <!-- <button mat-menu-item color="primary" *ngIf="  isPromoterOrAdmin == true  && row['Status.Code']=='REQU'"
                  (click)="approveSettlement(row['ID'], row['DrawDownGroupID'])">
                  <mat-icon>thumb_up_alt</mat-icon>
                  <span>Approve settlement </span>
                </button>

                <button mat-menu-item color="accent" *ngIf="isPromoterOrAdmin == true  && row['Status.Code']=='REQU'"
                  (click)="declineSettlement(row['ID'], row['DrawDownGroupID'])">
                  <mat-icon>thumb_down_alt</mat-icon>
                  <span>Decline settlement </span>
                </button> -->

                <button *ngIf="false && (row['Status.Code'] == 'REQU' || row['Status.Code'] == 'PEND')"
                  (click)="voidSettlement()" mat-menu-item>
                  <mat-icon>delete</mat-icon>
                  <span>Archive settlement </span>
                </button>
              </mat-menu>
            </div>
          </td>
          <td class="largeFont" *matFooterCellDef mat-footer-cell></td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-footer-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
        <tr *matFooterRowDef="displayedColumns; sticky: true" mat-footer-row></tr>
      </table>
      <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>
    </mat-card>
  </div>
  <div class="contract container" *ngIf="settlementID != null && displayContractView == true">
    <!-- <app-contract-view [contractID]="contractID" *ngIf="!settlementID"></app-contract-view> -->
    <app-settlement-approve-view [settlementID]="settlementID" (getResult)="getResultEvent($event)">
    </app-settlement-approve-view>
    <div class="full-width mt text-center">
      <button class="mb mt pull-left" (click)="hideContractView()" mat-raised-button color="primary">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Settlement list
      </button>
    </div>
  </div>
</div>
<div *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <app-empty-list-message></app-empty-list-message>
</div>
