import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { OperatorLookupComponent } from '../../../feature/operator/operator-lookup/operator-lookup.component';
import { CalendlyComponent } from '../../../shared/components/calendly/calendly.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { VideoTutorialsModalComponent } from '../../../shared/components/video-tutorials-modal/video-tutorials-modal.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { Settings } from '../../../shared/types/settings';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { VersionViewComponent } from '../../helper/version-view/version-view.component';
import { SessionsService } from '../shared/sessions.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';

@Component({
  selector: 'session-compact-menu',
  templateUrl: './session-compact-menu.component.html',
  styleUrls: ['./session-compact-menu.component.css'],
})
export class SessionCompactMenuComponent implements OnInit {
  isPromoterOrAdmin = false;
  userName;
  isNightMode = JSON.parse(localStorage.getItem('isDark'));

  context = Settings.global['context'];

  @Input()
  displayAdmin = true;


  @Input()
  displayPromoterLink = true;

  @Input()
  displayMerchantLink = true;

  @Input()
  displayDashboard = true;

  settings = Settings.global;
  profileLink;

  isLogin = false;

  sessionType = 'merchant';

  @Output()
  getStatus = new EventEmitter();

  isNewProfilePicture = false;
  displayInfoToolTip = true;
  constructor(
    private utilService: UtilsService,
    private SessionsService: SessionsService,
    private authenticationService: AuthenticationService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    if (localStorage.getItem('displayInfoToolTip') === 'false') {
      this.displayInfoToolTip = false;
    }

    this.setup();

    AuthenticationService.loginEvent.subscribe((res) => {
      this.setup();
    });

    AuthenticationService.updateProfilePicture.subscribe((res) => {
      if (res) {
        this.isNewProfilePicture = !this.isNewProfilePicture;

        this.getProfilePictureTimer();
      }
    });
  }

  loginAsUser() {
    const ref = RootAppComponent.dialog.open(OperatorLookupComponent, {
      data: {
        displayOnClick: false,
        loginAsUserOnClick: true,
        isModal: true,
      },
      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  versionReport() {
    const ref = RootAppComponent.dialog.open(VersionViewComponent, {
      data: {},
      width: '1000px',

      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  setup() {
    this.authenticationService.isLogin().subscribe((res) => {
      if (res == true) {
        this.isLogin = true;
        this.authenticationService.getSessionType().subscribe((sessionType) => {
          if (sessionType) {
            this.sessionType = sessionType;

            if (this.sessionType == 'admin' || this.sessionType == 'promoter') {
              this.isPromoterOrAdmin = true;
            } else {
              this.isPromoterOrAdmin = false;
            }
          }

          this.authenticationService.getCurrentUser().subscribe((res) => {
            this.sendResult();

            if (res && res.success && res.data['CalculatedName'] && res.data['CalculatedName']) {
              this.userName = res.data['CalculatedName'];

              this.getProfilePicture();
            }
          });
        });
      } else {
        this.isLogin = false;
        this.sendResult();
      }
    });
  }

  applyNightMode = () => {
    this.isNightMode = !this.isNightMode;
    localStorage.setItem('isDark', JSON.parse(this.isNightMode));
    if (this.isNightMode == true) {
      this.document.body.classList.add('dark');
      localStorage.setItem('darkClass', 'dark');
    } else {
      this.document.body.classList.remove('dark');
      localStorage.setItem('darkClass', '');
    }
    (window as any).ga('send', 'event', {
      eventCategory: 'Dark Mode',
      eventLabel: 'eventLabel',
      eventAction: 'eventAction',
      eventValue: 10,
    });
  };

  booking() {
    const ref = RootAppComponent.dialog.open(CalendlyComponent, {
      width: '650px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  launchVideoModal() {
    // trigger component launch as modal, default to selected video

    const ref = RootAppComponent.dialog.open(VideoTutorialsModalComponent, {
      data: {
        isModal: true,
        autoplay: false,
      },
      width: '650px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  sendResult() {
    this.authenticationService.isLogin().subscribe((res) => {
      const p = {
        isLogin: res,
      };

      this.getStatus.emit(p);
    });
  }

  getProfilePicture() {
    this.profileLink = null;

    this.profileLink = this.authenticationService.getProfilePicStreamLink();
  }

  getProfilePictureTimer() {
    this.profileLink = null;
    let countdown = 0;
    const interval = 1000;
    const duration = 3 * 1000;
    const stream$ = Observable.timer(0, interval)
      .finally(() => {
        this.profileLink = this.authenticationService.getProfilePicStreamLink();
      })
      .takeUntil(Observable.timer(duration + interval))
      .map((value) => duration - value * interval);
    stream$.subscribe((value) => (countdown = value));
  }

  changeInfoToolTip(d) {
    let confirm = new ConfirmDialog(
      'fas fa-info-circle',
      '',
      '<p>You are about to   <strong>Enable Guide Tips</strong>, do you wish to continue?</p>',
      'No',
      'Yes, Proceed'
    );

    if (d === false) {
      confirm = new ConfirmDialog(
        'fas fa-info-circle',
        '',
        '<p>You are about to   <strong>Disable Guide Tips</strong>, do you wish to continue?</p>',
        'No',
        'Yes, Proceed'
      );
    }

    let ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
    });
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        localStorage.setItem('displayInfoToolTip', d);
        this.displayInfoToolTip = d;
        AuthenticationService.getDisplayInfoToolTip.emit(d);
        ref.close();
      }
    });
  }

  portalRedirection(type) {
    if (type == 'promoter' || type == 'merchant') {
      const confirm = new ConfirmDialog(
        'fas fa-info',

        'You are about to leave this page.',
        'Do you want to continue?',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
        width: '500px',
      });

      ref.componentInstance.onConfirm.subscribe((res) => {
        if (res == true) {
          ref.close();

          if (type == 'promoter') {
            window.location.href = this.settings['promoterFrontendLink'];
          } else if (type == 'merchant') {
            window.location.href = this.settings['merchantFrontendLink'];
          }
        } else {
          ref.close();
        }
      });
    } else if (type == 'financeOffer') {
      window.open(`${this.settings['publicSiteFrontendLink']}/finance`, '_blank');
    } else if (type == 'public') {
      window.open(`${this.settings['publicSiteFrontendLink']}`, '_blank');
    }
  }
}
