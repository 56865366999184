import { Settings } from "./settings";

export class DocumentUtilClass {
  context = Settings.global['context'];

  constructor() {}

  ngOnInit() {}

  getIconClass(label) {

    if(this.context==='p4p')
    {
      switch (label) {
        case 'Additional Document':
          return ' fa-file-alt';

        case 'Scan / X-Ray':
          return ' fa-x-ray';

        case 'Treatment Plan':
          return ' fa-paw';

        case 'Treatment Document ':
          return ' fa-paw';

        case 'Image':
          return ' fa-image';
      }

    }
    else  if(this.context==='breeze')
    {
      switch (label) {
        case 'Additional Document':
          return ' fa-file-alt';

        case 'Scan / X-Ray':
          return ' fa-x-ray';

        case 'Treatment Plan':
          return ' fa-vacuum';

        case 'Treatment Document ':
          return ' fa-vacuum';

        case 'Image':
          return ' fa-image';
      }

    }
    else {
      switch (label) {
        case 'Additional Document':
          return ' fa-file-alt';

        case 'Scan / X-Ray':
          return ' fa-x-ray';

        case 'Treatment Plan':
          return ' fa-tooth';

        case 'Treatment Document ':
          return ' fa-tooth';

        case 'Image':
          return ' fa-image';
      }

    }

    return null;
  }
}
