import { animate, group, keyframes, query, stagger, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { FeatureSubLabelQuickEditComponent } from '../feature-sub-label-quick-edit/feature-sub-label-quick-edit.component';
import { SubscriptionPlanService } from '../shared/subscription-plan.service';

@Component({
  selector: 'app-feature-sub-label-view',
  templateUrl: './feature-sub-label-view.component.html',
  styleUrls: ['./feature-sub-label-view.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 0 })),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),

        group([animate(300, style({ height: 0 })), animate('200ms ease-in-out', style({ opacity: '0' }))]),
      ]),
      transition(':enter', [
        style({ height: '0', opacity: 0 }),

        group([animate(300, style({ height: '*' })), animate('400ms ease-in-out', style({ opacity: '1' }))]),
      ]),
    ]),
  ],
})
export class FeatureSubLabelViewComponent implements OnInit {
  @Input()
  featureType = 'all';

  isConsumer = false;
  isMerchant = true;

  root = '/merchant';
  mainID;
  previousFeature;
  nextFeature;
  subFeatureID;
  subFeature;
  loading = true;
  htmlContent;
  sessionType;
  subFeatureCode;

  settings = Settings.global;
  publicSiteLink;
  mainAppLink;
  isPromoterOrAdmin = false;

  context = Settings.global['context'];

  constructor(
    private _clipboardService: ClipboardService,
    private authenticationService: AuthenticationService,
    private subscriptionPlanService: SubscriptionPlanService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
      this.isPromoterOrAdmin = isPromoterOrAdmin;
    });
    UtilsClass.scrollUp();
    this.activeRoute.params.subscribe((params) => {
      if (params['subFeatureID']) {
        this.subFeatureID = params['subFeatureID'];
      }
      if (params['subFeatureCode']) {
        this.subFeatureCode = params['subFeatureCode'];
      }
      if (params['featureType']) {
        this.featureType = params['featureType'];
      }
      this.authenticationService.getSessionType().subscribe((st) => {
        this.sessionType = st;

        this.setup();
      });
    });
  }

  setup() {
    if (this.featureType === 'consumer') {
      this.root = '/consumer';
    } else {
      this.root = '/merchant';
    }
    if (this.settings && this.settings['publicSiteFrontendLink']) {
      this.publicSiteLink = this.settings['publicSiteFrontendLink'];
    }
    if (this.settings && this.settings['merchantFrontendLink']) {
      this.mainAppLink = this.settings['merchantFrontendLink'];
    }

    if (this.featureType === 'consumer') {
      this.isConsumer = true;
      this.isMerchant = null;
    } else if (this.featureType === 'merchant') {
      this.isConsumer = null;
      this.isMerchant = true;
    } else {
      this.isConsumer = null;
      this.isMerchant = null;
    }
    if (this.subFeatureCode) {
      this.subscriptionPlanService
        .getFeatureSubLabelDetailsByCode(
          this.subFeatureCode,
          {
            isMerchant: this.isMerchant,
            isConsumer: this.isConsumer,
            selectNext: true,
            selectPrevious: true,
          },
          this.sessionType
        )
        .subscribe((res) => {
          if (res && res.ID) {
            this.subFeatureID = res.ID;

            if (res.Feature_MainLabel_key) {
              this.mainID = res.Feature_MainLabel_key;
            }
            this.subFeature = res;
            if (this.subFeature['next']) {
              this.nextFeature = this.subFeature['next'];
            }

            if (this.subFeature['previous']) {
              this.previousFeature = this.subFeature['previous'];
            }

            this.subscriptionPlanService
              .getSubFeatureHTMLContent(this.subFeatureID, this.sessionType, res['LastModified'])
              .subscribe((res) => {
                this.htmlContent = res;
                this.loading = false;
              });
          }
        });
    } else if (this.subFeatureID) {
      this.subscriptionPlanService
        .getFeatureSubLabelDetails(
          this.subFeatureID,
          {
            isMerchant: this.isMerchant,
            isConsumer: this.isConsumer,
            selectNext: true,
            selectPrevious: true,
          },
          this.sessionType
        )
        .subscribe((res) => {
          if (res && res.ID) {
            this.subFeature = res;

            if (res.Feature_MainLabel_key) {
              this.mainID = res.Feature_MainLabel_key;
            }
            if (this.subFeature['next']) {
              this.nextFeature = this.subFeature['next'];
            }

            if (this.subFeature['previous']) {
              this.previousFeature = this.subFeature['previous'];
            }

            this.subscriptionPlanService
              .getSubFeatureHTMLContent(this.subFeatureID, this.sessionType, res['LastModified'])
              .subscribe((res) => {
                this.htmlContent = res;
                this.loading = false;
              });
          }
        });
    }
  }

  viewSubRedirect(id) {
    if (id) {
      this.router.navigate([this.root, { outlets: { page: ['main-sub-redirect', id, this.featureType] } }]);
    }
  }

  viewMainRedirect(id) {
    if (id) {
      this.router.navigate([this.root, { outlets: { page: ['main-view-redirect', id, this.featureType] } }]);
    }
  }

  copy(d) {
    if (d) {
      this._clipboardService.copy(d);
      NotifyAppComponent.displayToast('success', 'Success', 'Copied');
    }
  }

  quickEdit(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(FeatureSubLabelQuickEditComponent, {
        data: {
          subFeatureID: id,
        },
        width: '950px',
        panelClass: 'noCard',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res) {
          ref.close();
        }
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res && res.ID) {
          this.subFeature = res;
          ref.close();
        }
      });
    }
  }

  delete(id) {
    if (id) {
      const confirm = new ConfirmDialog(
        'delete',
        '',
        'Are you sure you want to delete this sub feature label ?',
        'No',
        'Yes'
      );
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.subscriptionPlanService.deleteFeatureSubLabel(id).subscribe((res) => {
            if (res) {
              this.router.navigate([
                this.root,
                { outlets: { page: ['main-view-redirect', this.mainID, this.featureType] } },
              ]);

              NotifyAppComponent.displayToast('success', 'Success!', 'Sub feature label is removed ');
              ref.close();
            }
          });
        }
      });
    }
  }

  edit(id) {
    if (this.mainID && id) {
      this.router.navigate([this.root, { outlets: { page: ['sub-feature-edit-create', this.mainID, id] } }]);
    }
  }
}
