import { Component, Input } from '@angular/core';

@Component({
  selector: 'ipv-list-active-label',
  templateUrl: './list-active-label.component.html',
  styleUrls: ['./list-active-label.component.css'],
})
export class ListActiveLabelComponent {
  @Input() isActive = false;
}
