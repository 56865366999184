import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductCatalogueItem } from '../../../shared/services/product-catalogue.service';

export interface ProductFormModalConfig {
  industryType: string;
  formMode?: 'create' | 'update';
  existingProduct?: ProductCatalogueItem;
  selectedSupplierKey?: string;
}

@Component({
  selector: 'ipv-product-form.modal.ts',
  templateUrl: './product-form.modal.ts.component.html',
  styleUrls: ['./product-form.modal.ts.component.css'],
})
export class ProductFormModalComponent {
  title = 'New Product';
  formMode: 'create' | 'update' = 'create';
  industryType = 'dental';
  existingProduct: ProductCatalogueItem = null;
  selectedSupplierKey: string = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ProductFormModalConfig) {
    if (data) {
      this.formMode = data.formMode || 'create';
      this.title = data.formMode === 'update' ? 'Update Product' : this.title;
      this.industryType = data.industryType;
      this.existingProduct = data.existingProduct || null;
      this.selectedSupplierKey = data.selectedSupplierKey || null;

      if (this.formMode === 'update') {
        this.title = 'Update Product';
      }
    }
  }
}
