<div class="rel">
  <div class="card-header primary-gradient-img clearfix confirmModal" *ngIf="isModal == true">
    <button
      class="btn-close btn-clear mat-button smt white custom-close"
      [ngClass]="!title ? 'no-title-close' : ''"
      (click)="closeEvent()"
    >
      <mat-icon>clear</mat-icon>
    </button>
    <div class="full-width flex main-title-container" [ngClass]="!title ? 'main-icon-container-no-title' : ''">
      <div class="main-icon-container text-center">
        <mat-icon class="main-icon accent-color fas fa-link"></mat-icon>
      </div>
      <div class="row full-width title-container clearfix">
        <div class="title-container-sub">
          <h2 class="text-left title-header rm-m">
            {{ title }}
          </h2>
        </div>
      </div>
    </div>
  </div>

  <mat-dialog-content [ngClass]="{ 'no-modal-height': isModal != true }">
    <div class="full-width text-center" *ngIf="isMerchant == true && isPromoterOrAdmin == true">
      <mat-form-field class="full-width text-center practice-list" appearance="outline">
        <mat-label>{{ "KEYWORD.practice" | translate | titlecase}}</mat-label>

        <mat-select
          [(ngModel)]="merchant"
          [placeholder]="'KEYWORD.Practice' | translate"
          (ngModelChange)="chooseMerchant()"
          name="linkFontSize"
        >
          <mat-option *ngFor="let m of merchants" [value]="m">
            {{ m.TradingAs }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-form-field class="full-width" *ngIf="type == 'link'" appearance="outline">
      <mat-label>Link Title</mat-label>
      <input [(ngModel)]="linkTitle" matInput placeholder="Link Title" name="linkTitle" required/>
    </mat-form-field>

    <div class="full-width smb" *ngIf="type == 'link'">
      <mat-form-field class="pull-left mr" style="width: 200px" appearance="outline">
        <mat-label>Font Size</mat-label>

        <mat-select [(ngModel)]="linkFontSize" name="linkFontSize" placeholder="Font Size">
          <mat-option *ngFor="let m of buttonFontSizepx" [value]="m">
            {{ m }}
          </mat-option>
          <hr/>
          <mat-option *ngFor="let m of buttonFontSizerem" [value]="m">
            {{ m }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <input
        class="color-input-sub pull-left"
        [(colorPicker)]="linkColor"
        [style.background]="linkColor"
        [cpOutputFormat]="'hex'"
        name="linkColor"
      />

      <p class="mr rm-mb sml pull-left">Colour</p>
    </div>

    <h3 class="sr-title full-width text-center" *ngIf="type == 'link'">Link Preview</h3>

    <a
      class="preview-link full-width text-center"
      *ngIf="type == 'link'"
      [attr.style]="'color:' + linkColor + ' !important; font-size:' + linkFontSize + ' !important;' | safeStyle"
      (click)="preview()"
    >{{ linkTitle }}</a
    >

    <h3 class="sr-title full-width text-center" *ngIf="type == 'iframe'">Iframe Configuration</h3>
    <h3 class="sr-title full-width text-center" *ngIf="type == 'image'">Image Configuration</h3>
    <div class="full-width flex text-center" *ngIf="type == 'iframe' || type == 'image'">
      <mat-form-field class="full-width mr" appearance="outline">
        <mat-label>Width e.g: 100% , 500px, 40vh or auto. </mat-label>
        <input [(ngModel)]="width" matInput placeholder="Width e.g: 100% , 500px, 40vh or auto." name="width"/>
      </mat-form-field>
      <mat-form-field class="full-width ml" appearance="outline">
        <mat-label>Height e.g: 100% , 500px, 40vh or auto.</mat-label>
        <input [(ngModel)]="height" matInput placeholder="Height e.g: 100% , 500px, 40vh or auto." name="height"/>
      </mat-form-field>
    </div>

    <div class="full-width mt" *ngIf="description">
      <p class="text-center rm-mt">{{ description }}</p>
    </div>
  </mat-dialog-content>
  <hr/>
  <div class="drop-button text-center mt full-width" *ngIf="isModal == true">
    <button class="pull-right btn-large" (click)="insert()" mat-raised-button color="accent">Insert</button>
    <button class="pull-right btn-large mr" (click)="copy()" mat-raised-button color="primary">Copy</button>

    <button class="pull-left btn-large" (click)="preview()" mat-raised-button color="primary">Preview</button>
  </div>
</div>
