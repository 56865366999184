import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'print-btn',
  templateUrl: './print-btn.component.html',
  styleUrls: ['./print-btn.component.css'],
})
export class PrintBtnComponent implements OnInit {
  @Input()
  buttonType = 'small';

  @Output()
  click = new EventEmitter();

  @Input()
  label = 'Print';

  constructor() {}

  ngOnInit() {}

  clickEvent() {
    this.click.emit(true);
  }
}
