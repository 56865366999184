import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { TemplateReviewComponent } from '../../../core/helper/template-review/template-review.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { LookupClass } from '../../../shared/types/lookupClass';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { TagCreateEditCardComponent } from '../../tags/tag-create-edit-card/tag-create-edit-card.component';
import { MarketingService } from '../shared/marketing.service';
import { MarketingUtilClass } from '../shared/marketingUtil';

@Component({
  selector: 'app-marketing-campaign-message-list',
  templateUrl: './marketing-campaign-message-list.component.html',
  styleUrls: ['./marketing-campaign-message-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MarketingCampaignMessageListComponent implements OnInit {
  @Input()
  dateFrom;

  @Input()
  dateTo;

  @Input()
  status;

  @Input()
  statusNative;

  @Input()
  campaignID;
  staticAudience = true;
  showSearch = false;
  selectedIDs = [];

  utilMarketing = new MarketingUtilClass();

  @Input()
  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  utils = new UtilsClass();
  lookup = new LookupClass();
  displayedColumns = ['checkbox', 'Create', 'Label', 'Name', 'Email', 'Status', 'SendDate', 'Actions'];

  isPromoterOrAdmin = false;

  filters = [];

  searchVals = new FormControl();

  searchValList = ['Name', 'Label', 'Status'];

  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  typeLabel;

  filteredSize = null;
  subjectArray = [];

  destroyEvent = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private marketingService: MarketingService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });
  }

  ngOnInit() {
    const params = {
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      campaignID: this.campaignID,
      status: this.status,
      statusNative: this.statusNative,
    };

    this.listDB = new LoadRecords(
      this.marketingService,
      this.authenticationService,
      this.destroyEvent,
      this.isPromoterOrAdmin,
      params
    );
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  ngOnChanges(changes: SimpleChanges) {
    const params = {
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      campaignID: this.campaignID,
      status: this.status,
    };

    this.listDB = new LoadRecords(
      this.marketingService,
      this.authenticationService,
      this.destroyEvent,
      this.isPromoterOrAdmin,
      params
    );
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  createStaticFilterTagAll() {
    this.selectAll();
    this.createStaticFilterTag(true);
  }

  createStaticFilterTag(all = false) {
    const ids = this.selectedIDs;

    if (ids.length > 0) {
      const ref = RootAppComponent.dialog.open(TagCreateEditCardComponent, {
        data: {
          cardIDs: ids,
          staticAudience: this.staticAudience,
          type: 'StaticFilter',
        },
        width: '650px',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res) {
          if (all === true) {
            this.unselect();
          }
          ref.close();
        }
      });
    }
  }

  selectAll() {
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      const o = this.dataSource.filteredData[i];

      if (o && o['Card_key'] && this.selectedIDs.indexOf(o['Card_key']) == -1) {
        this.selectedIDs.push(o['Card_key']);
      }
    }
  }

  selectPage() {
    const index = this.dataSource['_paginator']['index'] || 0;
    const pageSize = this.dataSource['_paginator']['pageSize'] || 0;

    for (let i = 0; i < (index + 1) * pageSize; i++) {
      const o = this.dataSource.filteredData[index * pageSize + i];

      if (o && o['Card_key'] && this.selectedIDs.indexOf(o['Card_key']) == -1) {
        this.selectedIDs.push(o['Card_key']);
      }
    }
  }

  select(id, isSelected) {
    if (isSelected == true) {
      if (this.selectedIDs.indexOf(id) == -1) {
        this.selectedIDs.push(id);
      }
    } else {
      this.selectedIDs.splice(this.selectedIDs.indexOf(id), 1);
    }
  }

  unselect() {
    this.selectedIDs = [];
  }

  isSelected(id) {
    if (!this.selectedIDs || this.selectedIDs.length <= 0) {
      return false;
    } else {
      if (this.selectedIDs.indexOf(id) != -1) {
        return true;
      }
    }

    return false;
  }

  setFilter(event, field) {
    let filter;
    if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  openEmailPreviewDialog(tag) {
    if (tag) {
      const ref = RootAppComponent.dialog.open(TemplateReviewComponent, {
        data: {
          templateTag: tag,
          isPrint: false,
          isProceed: false,
          isDownload: false,
          proceedLabel: null,
        },
        width: '700px',
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewPatient(id) {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['customer-profile', id, 'patient'],
        },
      },
    ]);
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  isDestoyed = false;

  util = new UtilsClass();

  serviceRef;

  constructor(
    private marketingService: MarketingService,
    private authenticationService: AuthenticationService,
    private destroyEvent,
    private isPromoterOrAdmin,
    private params
  ) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      const payload = {
        section: 0,
        status: this.params.status,
        statusNative: this.params.statusNative,
        campaignID: this.params.campaignID,
        dateFrom: this.util.EPdateFormat(this.params.dateFrom),
        dateTo: this.util.EPdateFormat(this.params.dateTo),
      };

      this.serviceRef = this.marketingService
        .getCampaignMessageList(payload, this.isPromoterOrAdmin)
        .subscribe((res) => {
          this.count = res.length;
          this.items = res;

          this.dataChange.next(this.items.slice());
          this.firstLoad = true;
          const innerFunction = (section) => {
            section = section + 1;
            UtilsClass.loadingDataSection(section);
            payload.section = section;

            this.serviceRef = this.marketingService
              .getCampaignMessageList(payload, this.isPromoterOrAdmin)
              .subscribe((res) => {
                if (res.length > 0) {
                  this.count = res.count;
                  this.items = this.items.concat(res);
                  this.dataChange.next(this.items.slice());
                  if (this.isDestoyed != true) {
                    innerFunction(section);
                  }
                } else {
                  return true;
                }
              });
            UtilsClass.stopLoadingInterceptor();
          };

          if (this.isDestoyed != true) {
            innerFunction(1);
          }
        });
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  set addItem(res) {
    this._custDatabase.data.unshift(res);

    this._custDatabase.dataChange.next(this._custDatabase.data);
  }

  set removeItem(id) {
    const data = this._custDatabase.data.filter((row) => row.ID != id);
    this._custDatabase.dataChange.next(data);
  }

  set replaceItem(item) {
    const _index = this._custDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    this._custDatabase.data[_index] = item;
    this._custDatabase.dataChange.next(this._custDatabase.data);
  }

  _filterChange = new BehaviorSubject('');
  field = '';

  filteredData: any[] = [];

  utilMarketing = new MarketingUtilClass();

  constructor(private _custDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._custDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._custDatabase.data.slice().filter((item: any) => {
          let f = '';

          f = item[this.field] || '';

          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'Name':
          [propertyA, propertyB] = [a['Card.Name'], b['Card.Name']];
          break;

        case 'Create':
          [propertyA, propertyB] = [
            moment(a['DateTimeCreated']).toDate().getTime(),
            moment(b['DateTimeCreated']).toDate().getTime(),
          ];
          break;

        case 'SendDate':
          [propertyA, propertyB] = [moment(a['SentWhen']).toDate().getTime(), moment(b['SentWhen']).toDate().getTime()];
          break;

        case 'Label':
          [propertyA, propertyB] = [a['Campaign_Label'], b['Campaign_Label']];
          break;

        case 'Status':
          [propertyA, propertyB] = [a['Status.Label'], b['Status.Label']];
          break;

        case 'Email':
          [propertyA, propertyB] = [a['Destination'], b['Destination']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
