import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'treatment-choice-advanced',
  templateUrl: './treatment-choice-advanced.component.html',
  styleUrls: ['./treatment-choice-advanced.component.css'],
})
export class TreatmentChoiceAdvancedComponent implements OnInit {
  @Output()
  closeModal = new EventEmitter();

  @Output()
  dontShowAgain = new EventEmitter();

  @Output()
  SimpleTreatment = new EventEmitter();

  dontShowAgainType = JSON.parse(localStorage.getItem('dontShowAgainAvancedType'));

  constructor() {}

  ngOnInit() {
    if (this.dontShowAgainType == true) {
      this.closeModal.emit(true);
    }
  }

  doNotShow() {
    localStorage.setItem('dontShowAgainAvancedType', JSON.stringify(this.dontShowAgainType));

    this.dontShowAgain.emit(this.dontShowAgainType);
    this.closeModal.emit(true);
  }

  backToSimple() {
    this.SimpleTreatment.emit(true);
  }
}
