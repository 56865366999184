import { EventEmitter } from '@angular/core';
import { environment } from 'environments/environment';
import * as $ from 'jquery';
import { forEach, isEqual, isObject, transform, uniq } from 'lodash';
import * as moment from 'moment';
import * as momentTZ from 'moment-timezone';
import * as Validator from 'validatorjs';
import { ErrorModalComponent } from '../../../core/helper/error-modal/error-modal.component';
import { RootAppComponent } from '../../components/root-component/root-component.component';
import { ClientDetails } from '../client-details';
import { NotifyAppComponent } from '../notify-app-component';
import { Settings } from '../settings';
import { UtilSchema } from './utils.type';

declare var google: any;

export class UtilsClass {
  settings = Settings.global;

  static updateSideMenu = new EventEmitter();
  static updateSideMenuOneModule = new EventEmitter();
  static updateSideMenuAllModule = new EventEmitter();
  serverTimeZoneUTC = Settings.global['serverTimeZoneUTC'];
  publicWebSite = Settings.global['publicSiteFrontendLink'];

  practiceSchema: UtilSchema[] = [
    {
      code: 'PracticeTradingAs',
      label: 'Trading As',
      field: 'TradingAs',
      group: '1',
    },
    {
      code: 'PracticeFirstName',
      label: 'First Name',
      field: 'FirstName',
      group: '1',
    },
    {
      code: 'PracticeLegalName',
      label: 'Legal Name',
      field: 'Name',
      group: '1',
    },
    {
      code: 'PracticeMobileNumber',
      label: 'Mobile',
      field: 'mobiles.Number',
      group: '2',
      icon: 'fas fa-mobile',
      isPhone: true,
      isLandLine: false,
    },
    {
      code: 'PracticePhoneNumber',
      label: 'Phone',
      field: 'phones.Number',
      group: '2',
      icon: 'fas fa-phone',
      isPhone: true,
      isLandLine: true,
    },

    {
      code: 'DedicatedSMSNumber',
      label: 'SMS Number',
      field: 'Twilio_Number',
      group: '2',
      icon: 'fas fa-sms',
      isPhone: true,
      isLandLine: false,
    },

    {
      code: 'PracticeEmail',
      label: 'Email',
      field: 'emails.Email',
      group: '2',
      icon: 'fas fa-at',
    },
    {
      code: 'PracticeAddress',
      label: 'Address',
      field: 'addresses.Calculated',
      group: '2',
      icon: 'fas fa-map-marker-alt',
    },
    {
      code: 'PracticeFacebook',
      label: 'Facebook',
      field: 'Facebook',
      group: '3',
      icon: 'fab fa-facebook-f',
    },
    {
      code: 'PracticeInstagram',
      label: 'Instagram',
      field: 'Instagram',
      group: '3',
      icon: 'fab fa-instagram',
    },
    {
      code: 'PracticeTwitter',
      label: 'Twitter',
      field: 'Twitter',
      group: '3',
      icon: 'fab fa-twitter',
    },
    {
      code: 'PracticeLinkedIn',
      label: 'LinkedIn',
      field: 'LinkedIn',
      group: '3',
      icon: 'fab fa-linkedin',
    },
    {
      code: 'PracticePinterest',
      label: 'Pinterest',
      field: 'Pinterest',
      group: '3',
      icon: 'fab fa-pinterest',
    },
    {
      code: 'PracticeTumblr',
      label: 'Tumblr',
      field: 'Tumblr',
      group: '3',
      icon: 'fab fa-tumblr',
    },
    {
      code: 'PracticeVimeo',
      label: 'Vimeo',
      field: 'Vimeo',
      group: '3',
      icon: 'fab fa-vimeo',
    },
    {
      code: 'PracticeYouTube',
      label: 'YouTube',
      field: 'YouTube',
      group: '3',
      icon: 'fab fa-youtube',
    },

    {
      code: 'PracticeWebSite',
      label: 'Website',
      field: 'URL',
      group: '3',
      icon: 'fas fa-globe',
    },

    {
      code: 'PracticeABN',
      label: 'ABN',
      field: 'ABN',
      group: '4',
    },

    {
      code: 'PracticeACN',
      label: 'ACN',
      field: 'ACN',
      group: '4',
    },
  ];

  promoterSchema: UtilSchema[] = [
    {
      code: 'PromoterTradingAs',
      label: 'Trading As',
      field: 'TradingAs',
      group: '1',
    },
    {
      code: 'PromoterFirstName',
      label: 'First Name',
      field: 'FirstName',
      group: '1',
    },
    {
      code: 'PromoterLegalName',
      label: 'Legal Name',
      field: 'Name',
      group: '1',
    },
    {
      code: 'PromoterMobileNumber',
      label: 'Mobile',
      field: 'mobiles.Number',
      group: '2',
      icon: 'fas fa-mobile',
      isPhone: true,
      isLandLine: false,
    },
    {
      code: 'PromoterPhoneNumber',
      label: 'Phone',
      field: 'phones.Number',
      group: '2',
      icon: 'fas fa-phone',
      isPhone: true,
      isLandLine: true,
    },
    {
      code: 'PromoterEmail',
      label: 'Email',
      field: 'emails.Email',
      group: '2',
      icon: 'fas fa-at',
    },
    {
      code: 'PromoterAddress',
      label: 'Address',
      field: 'addresses.Calculated',
      group: '2',
      icon: 'fas fa-map-marker-alt',
    },
    {
      code: 'PromoterFacebook',
      label: 'Facebook',
      field: 'Facebook',
      group: '3',
      icon: 'fab fa-facebook-f',
    },
    {
      code: 'PromoterInstagram',
      label: 'Instagram',
      field: 'Instagram',
      group: '3',
      icon: 'fab fa-instagram',
    },
    {
      code: 'PromoterTwitter',
      label: 'Twitter',
      field: 'Twitter',
      group: '3',
      icon: 'fab fa-twitter',
    },
    {
      code: 'PromoterLinkedIn',
      label: 'LinkedIn',
      field: 'LinkedIn',
      group: '3',
      icon: 'fab fa-linkedin',
    },
    {
      code: 'PromoterPinterest',
      label: 'Pinterest',
      field: 'Pinterest',
      group: '3',
      icon: 'fab fa-pinterest',
    },
    {
      code: 'PromoterTumblr',
      label: 'Tumblr',
      field: 'Tumblr',
      group: '3',
      icon: 'fab fa-tumblr',
    },
    {
      code: 'PromoterVimeo',
      label: 'Vimeo',
      field: 'Vimeo',
      group: '3',
      icon: 'fab fa-vimeo',
    },
    {
      code: 'PromoterYouTube',
      label: 'YouTube',
      field: 'YouTube',
      group: '3',
      icon: 'fab fa-youtube',
    },

    {
      code: 'PromoterWebSite',
      label: 'Website',
      field: 'URL',
      group: '3',
      icon: 'fas fa-globe',
    },

    {
      code: 'PromoterABN',
      label: 'ABN',
      field: 'ABN',
      group: '4',
    },

    {
      code: 'PromoterACN',
      label: 'ACN',
      field: 'ACN',
      group: '4',
    },
  ];

  contactSchema: UtilSchema[] = [
    {
      code: 'DentistSalutation',
      label: 'Salutation',
      field: 'Salutation',
      group: '1',
    },
    {
      code: 'DentistFirstName',
      label: 'FirstName',
      field: 'FirstName',
      group: '1',
    },
    {
      code: 'DentistMiddleName',
      label: 'Middle',
      field: 'MiddleName',
      group: '1',
    },
    {
      code: 'DentistLastName',
      label: 'LastName',
      field: 'Name',
      group: '1',
    },
    {
      code: 'DentistMobileNumber',
      label: 'Mobile',
      field: 'mobiles.Number',
      group: '2',
      icon: 'fas fa-mobile',
      isPhone: true,
      isLandLine: false,
    },
    {
      code: 'DentistPhoneNumber',
      label: 'Phone',
      field: 'phones.Number',
      group: '2',
      icon: 'fas fa-phone',
      isPhone: true,
      isLandLine: true,
    },
    {
      code: 'DentistEmail',
      label: 'Email',
      field: 'emails.Email',
      group: '2',
      icon: 'fas fa-at',
    },
    {
      code: 'DentistAddress',
      label: 'Address',
      field: 'addresses.Calculated',
      group: '2',
      icon: 'fas fa-map-marker-alt',
    },
  ];

  receiverSchema: UtilSchema[] = [
    {
      code: 'ReceiverSalutation',
      label: 'Salutation',
      field: 'Salutation',
      group: '1',
    },
    {
      code: 'ReceiverFirstName',
      label: 'FirstName',
      field: 'FirstName',
      group: '1',
    },
    {
      code: 'ReceiverMiddleName',
      label: 'Middle',
      field: 'MiddleName',
      group: '1',
    },
    {
      code: 'ReceiverLastName',
      label: 'LastName',
      field: 'Name',
      group: '1',
    },
    {
      code: 'ReceiverMobileNumber',
      label: 'Mobile',
      field: 'mobiles.Number',
      group: '2',
      icon: 'fas fa-mobile',
      isPhone: true,
      isLandLine: false,
    },
    {
      code: 'ReceiverWorkPhoneNumber',
      label: 'Phone',
      field: 'phones.Number',
      group: '2',
      icon: 'fas fa-phone',
      isPhone: true,
      isLandLine: true,
    },
    {
      code: 'ReceiverEmail',
      label: 'Email',
      field: 'emails.Email',
      group: '2',
      icon: 'fas fa-at',
    },
    {
      code: 'ReceiverAddress',
      label: 'Address',
      field: 'addresses.Calculated',
      group: '2',
      icon: 'fas fa-map-marker-alt',
    },
  ];

  cardSchema: UtilSchema[] = [
    {
      code: 'PatientSalutation',
      label: 'Salutation',
      field: 'Salutation',
      group: '1',
    },
    {
      code: 'PatientFirstName',
      label: 'FirstName',
      field: 'FirstName',
      group: '1',
    },
    {
      code: 'PatientMiddleName',
      label: 'Middle',
      field: 'MiddleName',
      group: '1',
    },
    {
      code: 'PatientLastName',
      label: 'LastName',
      field: 'Name',
      group: '1',
    },
    {
      code: 'PatientMobileNumber',
      label: 'Mobile',
      field: 'mobiles.Number',
      group: '2',
      icon: 'fas fa-mobile',
      isPhone: true,
      isLandLine: false,
    },
    {
      code: 'PatientWorkPhoneNumber',
      label: 'Phone',
      field: 'phones.Number',
      group: '2',
      icon: 'fas fa-phone',
      isPhone: true,
      isLandLine: true,
    },
    {
      code: 'PatientEmail',
      label: 'Email',
      field: 'emails.Email',
      group: '2',
      icon: 'fas fa-at',
    },
    {
      code: 'PatientAddress',
      label: 'Address',
      field: 'addresses.Calculated',
      group: '2',
      icon: 'fas fa-map-marker-alt',
    },
  ];

  public chartTypes = {
    barVertical: 'barVertical',
    barHorizontal: 'barHorizontal',
    barVerticalStacked: 'barVerticalStacked',
    barHorizontalStacked: 'barHorizontalStacked',
    barVerticalGrouped: 'barVerticalGrouped',
    barHorizontalGrouped: 'barHorizontalGrouped',
    barVerticalNormalized: 'barVerticalNormalized',
    barHorizontalNormalized: 'barHorizontalNormalized',
    pie: 'pie',
    pieAdvanced: 'pieAdvanced',
    pieGrid: 'pieGrid',
    line: 'line',
    area: 'area',
  };

  static dateParse(date) {
    if (UtilsClass.isBrowserSafari()) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    } else {
      return Date.parse(date);
    }
  }

  static scrollUp() {
    $('html').animate({ scrollTop: '0' });
    $(window).animate({ scrollTop: '0' });
    $('.mat-drawer-content').animate({ scrollTop: '0' });
  }

  private static isBrowserSafari() {
    let result = false;
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') != -1) {
      if (ua.indexOf('chrome') <= -1) {
        result = true;
      }
    }
    return result;
  }

  public static displayErrorModal(error, code, link) {
    const ref = RootAppComponent.dialog.open(ErrorModalComponent, {
      data: {
        link,
        title: NotifyAppComponent.errorType(code),
        content: error,
      },
      width: '500px',
      height: '330px',
    });
    const sub = ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
  }

  public static startLoadingInterceptor() {
    $('#loader').show();
  }

  public static stopLoadingInterceptor() {
    $('#loader').hide();
  }

  public static startLoading() {
    $('#loaderForce').show();
  }

  public static stopLoading() {
    $('#loaderForce').hide();
  }

  public static startLoadingForce() {
    $('#loaderForce').show();
  }

  public static stopLoadingForce() {
    $('#loaderForce').hide();
  }

  public static savingData() {
    $('#userDataLoader').fadeIn().delay(1500).fadeOut();
  }

  public static loadingDataSection(section) {
    $('#dataLoadingSection').hide();
    $('#dataLoadingSection p ').html('Loading Data: Section ' + section);

    $('#dataLoadingSection').fadeIn('slow', function () {
      $(this).delay(5000).fadeOut('slow');
    });
  }

  public static startLoadingData(Label) {
    $('#dataLoadingSection').hide();
    $('#dataLoadingSection p ').html(Label);
    $('#dataLoadingSection').fadeIn('fast', function () {
      $(this).delay(2000).fadeOut('fast');
    });
  }

  public static stopLoadingData() {
    $('#dataLoadingSection').hide();
  }

  public static displayError(error, code, link) {
    console.error('[' + code + '] ' + error);

    if (code == 0) {
      return false;
    }

    if (code == 401) {
      NotifyAppComponent.displayToast('error', 'Please verify your login and password', error);
      return false;
    } else if (code == 451) {
      NotifyAppComponent.displayToast('error', NotifyAppComponent.errorType(code), error);
      return false;
    } else if (code == 452) {
      error.replace('|', '<br>');
      UtilsClass.displayErrorModal(error, 'Please review your data ', 'reload');
      return false;
    } else if (code == 510) {
      NotifyAppComponent.navigation.emit('/site-offline');
    } else if (code == 457) {
      const e = error.split('|');
      const dentist = e[1];
      const choice = e[0];
      let content = '';

      if (choice && choice === 'DSMO') {
        content = 'We have received your feedback and <strong>' + dentist + '</strong> has been notified. ';
      } else if (choice && choice === 'NOT') {
        content =
          'We are sorry to hear that you are not proceeding and <strong>' +
          dentist +
          '</strong> has been notified of your decision. ' +
          '<br><br> If you change your mind, feel free to contact the practice and they will reactivate your proposed treatment plan for you to consider again. ';
      } else if (choice && choice === 'T_OTH') {
        content =
          'You have indicated that you wish to proceed with your treatment and <strong>' +
          dentist +
          '</strong> has been notified. Expect to hear from the practice soon to confirm your next appointment.' +
          '<br><br> If you change your mind and would like to consider financing this procedure then feel free to contact the practice and they will reactivate your proposed treatment plan for you to consider again. ';
      } else if (choice && choice === 'DISC') {
        content =
          'You have indicated that you do not want to proceed with finance plan, we will contact you to schedule your treatment. ';
      }

      UtilsClass.displayErrorModal(content, 'You will hear from us soon! ', null);
      return false;
    } else if (code < 500 && code >= 400) {
      UtilsClass.displayErrorModal(error, code, link);
    } else {
      NotifyAppComponent.navigation.emit('/500/' + code + '/' + error);
    }
  }

  constructor() {}

  getFileExtensionByFileName(filename) {
    if (!filename || typeof filename !== 'string') {
      return null;
    }

    // Find the position of the last dot in the filename
    const lastDotPosition = filename.lastIndexOf('.');

    // If there's no dot, return null (no extension)
    if (lastDotPosition === -1) {
      return null;
    }

    // Extract the extension from the filename
    const extension = filename.slice(lastDotPosition + 1);

    // Return the extension in lower case for consistency
    return extension.toLowerCase();
  }

  getLabelFromProductGroup(p) {
    if (p && p['GroupLabel']) {
      if (p['GroupCode'] == 'GPF') {
        return 'Treatment';
      } else if (p['GroupCode'] == 'MEM') {
        return 'Smile Club';
      } else {
        const t = p['GroupLabel'].split(' - ');

        if (t && t[0]) {
          if (t[0][t.length - 1] == 's') {
            return t[0].substr(0, t[0].length - 1);
          } else if (t[0][t.length - 2] == 's') {
            return t[0].substr(0, t[0].length - 2);
          }
          return t[0];
        }
      }
    }

    return null;
  }

  URLify(_string, whiteColor = true) {
    if (_string && this.isContentHtml(_string) != true) {
      let string = _string;
      let urls = string.match(/(https?:\/\/[^\s]+)/g) || [];

      const localURLs = string.match(/(http?:\/\/[^\s]+)/g);

      if (localURLs && localURLs.length > 0) {
        urls = urls.concat(localURLs);
      }
      if (urls) {
        urls = uniq(urls);
        urls.forEach((url) => {
          const title = this.getLinkTitle(url);

          if (whiteColor == true) {
            if (title) {
              string = string
                .split(url)
                .join(
                  '<a target="_blank" style="color:white !important ; margin-right:10px !important;" title="' +
                    title +
                    '" class="inner-link" href="' +
                    url +
                    '"> ' +
                    title +
                    ' </a>'
                );
            } else {
              string = string
                .split(url)
                .join(
                  '<a target="_blank" style="color:white !important ; margin-right:10px !important;" class="inner-link" href="' +
                    url +
                    '"> ' +
                    url +
                    ' </a>'
                );
            }
          } else {
            if (title) {
              string = string
                .split(url)
                .join(
                  '<a target="_blank" style=" margin-right:10px !important;" title="' +
                    title +
                    '" class="inner-link" href="' +
                    url +
                    '"> ' +
                    title +
                    ' </a>'
                );
            } else {
              string = string
                .split(url)
                .join(
                  '<a target="_blank" style=" margin-right:10px !important;" class="inner-link" href="' +
                    url +
                    '"> ' +
                    url +
                    ' </a>'
                );
            }
          }
        });
      }

      string = string.replace(/\n/g, '<br>');
      string = string.replace(/   /g, '<br>');
      return string;
    } else if (_string && this.isContentHtml(_string) == true) {
      let string = _string;

      string = string.replace(/\n/g, '<br>');
      string = string.replace(/   /g, '<br>');
      return string;
    }

    return _string;
  }

  replaceAndAddWords = (source, word_to_replace, wordsToAdd) => {
    const result = [];

    source.forEach((item) => {
      result.push({
        ...item,
        code: item.code.replace(word_to_replace, wordsToAdd),
      });
    });

    return result;
  };
  getReceiverSchema() {
    return this.receiverSchema;
  }
  getCardSchema() {
    if (this.settings && this.settings['context'] === 'p4p') {
      return this.replaceAndAddWords(this.cardSchema, 'Patient', 'Client');
    } else if (this.settings && this.settings['context'] === 'breeze') {
      return this.replaceAndAddWords(this.cardSchema, 'Patient', 'Customer');
    } else {
      return this.cardSchema;
    }
  }

  getPracticeSchema() {
    if (this.settings && this.settings['context'] === 'p4p') {
      return this.practiceSchema;
    } else if (this.settings && this.settings['context'] === 'breeze') {
      return this.replaceAndAddWords(this.practiceSchema, 'Practice', 'Merchant');
    } else {
      return this.practiceSchema;
    }
  }

  getContactSchema() {
    if (this.settings && this.settings['context'] === 'p4p') {
      return this.replaceAndAddWords(this.contactSchema, 'Dentist', 'Veterinarian');
    } else if (this.settings && this.settings['context'] === 'breeze') {
      return this.replaceAndAddWords(this.contactSchema, 'Dentist', 'Dealer');
    } else {
      return this.contactSchema;
    }
  }

  getPromoterSchema() {
    return this.promoterSchema;
  }

  buildPromoterConversion(promoter) {
    let promoterRules = [];

    let schema = this.getPromoterSchema();

    if (promoter && promoter.ID) {
      if (schema && schema.length > 0) {
        promoterRules = [];
        forEach(schema, (item) => {
          if (item) {
            const _item = item;
            if (promoter[item['field']] && promoter[item['field']] != '') {
              _item.value = promoter[item['field']];
              promoterRules.push(_item);
            }
          }
        });

        promoterRules = JSON.parse(JSON.stringify(promoterRules));
      }
    }

    return promoterRules;
  }

  buildMerchantConversion(merchant) {
    let practiceRules = [];
    let schema = this.getPracticeSchema();

    if (merchant && merchant.ID) {
      if (schema && schema.length > 0) {
        practiceRules = [];
        forEach(schema, (item) => {
          if (item) {
            const _item = item;
            _item.value = merchant[item['field']] || '';
            practiceRules.push(_item);
          }
        });

        practiceRules = JSON.parse(JSON.stringify(practiceRules));
      }
    }
    return practiceRules;
  }

  buildContactConversion(contact) {
    let contactRules = [];

    let schema = this.getContactSchema();

    if (contact && contact.ID) {
      if (schema && schema.length > 0) {
        contactRules = [];
        forEach(schema, (item) => {
          if (item) {
            const _item = item;

            _item.value = contact[item['field']] || '';
            contactRules.push(_item);
          }
        });

        contactRules = JSON.parse(JSON.stringify(contactRules));
      }
    }
    return contactRules;
  }

  buildCardConversion(card) {
    let cardRules = [];
    let schema = this.getCardSchema();
    if (card && card.ID) {
      if (schema && schema.length > 0) {
        cardRules = [];
        forEach(schema, (item) => {
          if (item) {
            const _item = item;

            _item.value = card[item['field']] || '';
            cardRules.push(_item);
          }
        });

        cardRules = JSON.parse(JSON.stringify(cardRules));
      }
    }

    return cardRules;
  }

  isContentHtml(html) {
    if (html) {
      const reg = new RegExp(/<(?=.*? .*?\/ ?>|br|hr|input|!--|wbr)[a-z]+.*?>|<([a-z]+).*?<\/\1>/i);

      return reg.test(html);
    }

    return false;
  }

  fillMessage(promoter, merchant, contact, card, message, isBoldText = false, customPhonePipe: any, currencyPipe: any) {
    let result = message || null;

    const rule = {};

    let cardRules = [];
    let contactRules = [];
    let practiceRules = [];
    let promoterRules = [];

    if (card) {
      cardRules = this.buildCardConversion(card);
    }
    if (promoter) {
      promoterRules = this.buildPromoterConversion(promoter);
    }
    if (merchant) {
      practiceRules = this.buildMerchantConversion(merchant);
    }
    if (contact) {
      contactRules = this.buildContactConversion(contact);
    }

    if (practiceRules && practiceRules.length > 0) {
      for (let i = 0; i < practiceRules.length; i++) {
        const item = practiceRules[i];

        if (item && item.code === 'PracticePage') {
          rule['PracticePage'] = `${Settings.global['publicSiteFrontendLink']}/l/p/s/${merchant['Terminal_Code']}`;
        } else if (item && item.code === 'TipsAndOffers') {
          rule['TipsAndOffers'] = `${Settings.global['publicSiteFrontendLink']}/l/d/s/${merchant['Terminal_Code']}`;
        } else if (item && item.code === 'PracitceAppointment') {
          rule['PracitceAppointment'] = `${Settings.global['publicSiteFrontendLink']}/a/${merchant['Terminal_Code']}`;
        } else if (item && item.code === 'SelfServicePage') {
          rule['SelfServicePage'] = `${environment.nodeUrl}/s/${merchant['Terminal_Code']}?t=lp`;
        } else if (item && item.code && item.field) {
          if (merchant[item.field]) {
            if (item.isPhone == true) {
              let format = 'mobile';
              if (item.isLandLine == true) {
                format = 'landLine';
              }

              rule[item.code] = customPhonePipe.transform(merchant[item.field], format);
            } else {
              rule[item.code] = merchant[item.field];
            }
          } else {
            rule[item.code] = ' ';
          }
        }
      }
    }

    if (cardRules && cardRules.length > 0) {
      for (let i = 0; i < cardRules.length; i++) {
        const item = cardRules[i];
        if (item && item.code && item.field) {
          if (card[item.field]) {
            if (item.isPhone == true) {
              let format = 'mobile';
              if (item.isLandLine == true) {
                format = 'landLine';
              }

              rule[item.code] = customPhonePipe.transform(card[item.field], format);
            } else {
              rule[item.code] = card[item.field];
            }
          } else {
            rule[item.code] = ' ';
          }
        }
      }
    }

    if (contactRules && contactRules.length > 0) {
      for (let i = 0; i < contactRules.length; i++) {
        const item = contactRules[i];
        if (item && item.code && item.field) {
          if (contact[item.field]) {
            if (item.isPhone == true) {
              let format = 'mobile';
              if (item.isLandLine == true) {
                format = 'landLine';
              }

              rule[item.code] = customPhonePipe.transform(contact[item.field], format);
            } else {
              rule[item.code] = contact[item.field];
            }
          } else {
            rule[item.code] = ' ';
          }
        }
      }
    }

    if (promoterRules && promoterRules.length > 0) {
      for (let i = 0; i < promoterRules.length; i++) {
        const item = promoterRules[i];
        if (item && item.code && item.field) {
          if (promoter[item.field]) {
            if (item.isPhone == true) {
              let format = 'mobile';
              if (item.isLandLine == true) {
                format = 'landLine';
              }

              rule[item.code] = customPhonePipe.transform(promoter[item.field], format);
            } else {
              rule[item.code] = promoter[item.field];
            }
          } else {
            rule[item.code] = ' ';
          }
        }
      }
    }

    if (card && (card['amountToFinance'] || Number(card['amountToFinance']) === 0) && currencyPipe) {
      rule['AmountToFinance'] = currencyPipe.transform(card['amountToFinance']);
    } else {
      if (Settings.global === 'breeze' || Settings.global === 'p4p') {
        rule['AmountToFinance'] = '[Amount To Finance]';
      } else {
        rule['AmountToFinance'] = '[Treatment Amount]';
      }
    }

    result = this.convertMessage(result, rule, { isStrong: isBoldText, isToolTip: isBoldText });

    return result;
  }

  b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(
      atob(str)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }

  getMultiCheckboxValuesResults(object) {
    return Object.entries(object)
      .filter((arrayItems) => arrayItems[1] === true)
      .map((arrayItems) => arrayItems[0]);
  }

  getPreviewHtmlContent(content, style, componentID = null) {
    let preview;

    if (content && style) {
      const index = content.indexOf('</head>');

      if (index === -1) {
        if (componentID) {
          preview = `<section id="ID${componentID}">\n<html>\n<head>\n<style>\n${style}\n</style>\n</head>\n<body>${content}</body>\n</html>\n</section>`;
        } else {
          preview = `<html>\n<head>\n<style>\n${style}\n</style>\n</head>\n<body>\n${content}\n</body>\n</html>`;
        }
      } else {
        preview = content.replace('</head>', `<style>\n${style}\n</style>\n</head>`);
        if (componentID) {
          preview = `<section id="ID${componentID}">\n${content}\n</section>`;
        }
      }
    } else if (content) {
      preview = content;
    }

    return preview;
  }

  generateFileDate() {
    const imageDate = new Date()
      .toLocaleString()
      .replace(/\//g, '-')
      .replace(/,/g, '_')
      .replace(/\s/g, '')
      .replace(/:/g, '-');

    return imageDate;
  }

  getDistanceAway(
    startingPointLatitude: string,
    startingPointLongitude: string,
    targetLatitude: string,
    targetLongitude: string,
    targetCalculatedAddress: string,
    geocoder: any
  ): Promise<{
    distanceAway: string;
    generatedNewCoordinates: boolean;
    targetLatitude: string;
    targetLongitude: string;
  }> {
    let from = 0;
    let to = 0;
    let distanceAway = null;
    let generatedNewCoordinates = false;

    if (geocoder) {
      if (targetLatitude === '0' && targetLongitude === '0') {
        return new Promise((resolve) => {
          geocoder.geocode({ address: `${targetCalculatedAddress}, AUS` }, (results, status) => {
            if (results && results[0] && status === 'OK') {
              const newLatitude = results[0].geometry.location.lat();
              const newLongitude = results[0].geometry.location.lng();

              from = new google.maps.LatLng(startingPointLatitude, startingPointLongitude);
              to = new google.maps.LatLng(newLatitude, newLongitude);
              distanceAway =
                Math.round((Math.round(google.maps.geometry.spherical.computeDistanceBetween(from, to)) / 1000) * 1e2) /
                1e2;
              generatedNewCoordinates = true;

              const result = {
                distanceAway,
                generatedNewCoordinates,
                targetLatitude: results[0].geometry.location.lat(),
                targetLongitude: results[0].geometry.location.lng(),
              };

              resolve(result);
            } else {
              resolve(null);
            }
          });
        });
      } else {
        return new Promise((resolve) => {
          from = new google.maps.LatLng(startingPointLatitude, startingPointLongitude);
          to = new google.maps.LatLng(targetLatitude, targetLongitude);
          distanceAway =
            Math.round((Math.round(google.maps.geometry.spherical.computeDistanceBetween(from, to)) / 1000) * 1e2) /
            1e2;

          const result = {
            distanceAway,
            generatedNewCoordinates,
            targetLatitude,
            targetLongitude,
          };

          resolve(result);
        });
      }
    } else {
      return new Promise((resolve) => {
        resolve(null);
      });
    }
  }

  addHtmlLink(text) {
    if (text) {
      let result;
      const urlRegex =
        /(\b((https?|ftp|file):\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?)/gi;

      result = text.replace(/(\r\n|\r|\n)/g, '<br>');
      result = result.replace(/(<p><\/p>)/g, '<br>');
      result = result.replace(/(<p>&nbsp;<\/p>)/g, '<br>');

      result = result.replace(/(<br><br>)/g, '<br>');
      result = result.replace(urlRegex, (url) => {
        const newUrl = url.indexOf('http') === -1 ? `http://${url}` : url;
        return `<a class="post-link" href="${newUrl}" target="_blank" >${url}</a>`;
      });

      return result;
    }
    return null;
  }

  getFileLink(fileID) {
    return environment.nodeUrl + '/files/file-view/' + fileID;
  }

  isImageExtension(extension) {
    const imagesExt = ['jpeg', 'jpg', 'png', 'gif', 'heic', 'tiff', 'bmp', 'ico'];
    if (extension && imagesExt.indexOf(String(extension).toLocaleLowerCase()) !== -1) {
      return true;
    }

    return false;
  }

  getProprietaryDocumentTypeFromExtension(_extension): { proprietaryType: string; isProprietaryType: boolean } {
    let result = { proprietaryType: _extension, isProprietaryType: false };

    if (_extension) {
      const extension = String(_extension).toLocaleLowerCase();
      const wordDocumentExtensions = ['doc', 'docx'];
      const powerpointDocumentExtensions = ['ppt', 'pptx'];

      if (wordDocumentExtensions.indexOf(extension) !== -1) {
        result = { proprietaryType: 'word', isProprietaryType: true };
      } else if (powerpointDocumentExtensions.indexOf(extension) !== -1) {
        result = { proprietaryType: 'powerpoint', isProprietaryType: true };
      } else {
        result = { proprietaryType: _extension, isProprietaryType: false };
      }
    }

    return result;
  }

  buildTemplateURL(landingCode, linkCode) {
    let result;

    if (landingCode) {
      if (landingCode) {
        if (landingCode.indexOf('@ID@') != -1) {
          const a = landingCode.replace('@ID@', linkCode);

          if (a) {
            result = a;
          }
        } else if (landingCode.indexOf('http') != -1) {
          result = landingCode;
        } else if (landingCode.indexOf('www.') != -1) {
          result = landingCode;
        } else if (landingCode.indexOf('code:') != -1) {
          const code = landingCode.replace('code:', '');
          result = this.publicWebSite + '/product/(page:main/' + code + '/' + linkCode + ')';
        } else {
          result = this.publicWebSite + '/product/(page:main/' + landingCode + '/' + linkCode + ')';
        }
      }
    }

    return result;
  }

  getLinkTitle(url) {
    if (url) {
      if (url.indexOf('?t=g') != -1) {
        return 'Going Green Page';
      } else if (url.indexOf('?t=t') != -1) {
        return 'Tips and Offers Page';
      } else if (url.indexOf('?t=ct') != -1) {
        return 'View Inserted Link';
      } else if (url.indexOf('?t=a') != -1) {
        return 'Appointment Request Page';
      } else if (url.indexOf('?t=m') != -1) {
        return 'Marketing Campaign Landing Page';
      } else if (url.indexOf('?t=d') != -1) {
        return 'Landing Page';
      } else if (url.indexOf('?t=df') != -1) {
        return 'Landing Page with Finance option';
      } else if (url.indexOf('?t=if') != -1) {
        return 'Instant Finance Offer';
      } else if (url.indexOf('?t=sd') != -1) {
        return 'Shared Document';
      }

      return 'View Link';
    }

    return null;
  }

  getTemplateCode(_tag) {
    let result;
    let tag;
    if (_tag) {
      tag = _tag.replace('SMIL:', '');
    }

    if (tag) {
      if (tag == 'campaign-M-CustomersAndProspects-Comm8-sonnodent-cpap') {
        result = 'somnodent';
      } else if (tag == 'campaign-M-CustomersAndProspects-Comm9-invisalign') {
        result = 'invisalign';
      } else if (tag == 'campaign-M-CustomersAndProspects-Comm7-lanap-laser-introduction') {
        result = 'lanap';
      } else if (tag == 'campaign-M-CustomersAndProspects-Comm5-Infection-Control-COVID19-DosDonts') {
        result = 'infectioncontrol';
      } else if (tag == 'campaign-M-CustomersAndProspects-Comm11-smilestyler') {
        result = 'smilestyler';
      } else if (tag == 'campaign-M-CustomersAndProspects-Comm10-oventus') {
        result = 'oventus';
      } else if (tag == 'campaign-M-CustomersAndProspects-Comm4-Infection-Control-COVID19') {
        result = 'infectioncontrol';
      } else if (tag == 'campaign-M-CustomersAndProspects-Comm7-emax') {
        result = 'emax';
      }
    }

    return result;
  }

  getDocumentExtensionsFromType(type) {
    let result = [];

    if (type) {
      if (type == 'document') {
        result = ['doc', 'docx', 'html', 'htm', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'csv', 'tsv'];
      } else if (type == 'video') {
        result = [
          'mp4',
          'm4a',
          'm4v',
          'f4v',
          'f4a',
          'avi',
          'm4p',
          'm4b',
          'm4r',
          'f4b',
          'mov',
          'wmv',
          'wma',
          'webm',
          'flv',
          'mpg',
          'mp2',
          'mpeg',
          'mpe',
          'mpv',
          'ogg',
          'qt',
          'swf',
          'avchd',
          'video/webm',
          'mkv',
          'avchd',
        ];
      } else if (type == 'image') {
        result = ['jpeg', 'jpg', 'png', 'heic', 'gif', 'tiff', 'bmp', 'ico'];
      } else if (type == 'audio') {
        result = ['m4a', 'flac', 'mp3', 'mp4', 'wav', 'wma', 'aac'];
      } else if (type == 'pdf') {
        result = ['pdf'];
      }
    }

    return result;
  }

  getDocumentTypeFromExtension(_extension) {
    let result = 'document';

    if (_extension) {
      const extension = String(_extension).toLocaleLowerCase();
      const documentExtensions = ['doc', 'docx', 'html', 'htm', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'csv', 'tsv'];

      const videoExtensions = [
        'mp4',
        'm4a',
        'm4v',
        'f4v',
        'f4a',
        'm4b',
        'avi',
        'm4p',
        'm4r',
        'f4b',
        'mov',
        'wmv',
        'wma',
        'webm',
        'flv',
        'mpg',
        'mp2',
        'mpeg',
        'mpe',
        'mpv',
        'ogg',
        'qt',
        'swf',
        'avchd',
        'video/webm',
        'mkv',
        'avchd',
      ];

      const imageExtensions = ['jpeg', 'jpg', 'png', 'heic', 'gif', 'bmp', 'tiff'];

      const audioExtensions = ['m4a', 'flac', 'mp3', 'mp4', 'wav', 'wma', 'aac'];

      if (extension == 'pdf') {
        result = 'pdf';
      } else if (extension == 'html') {
        result = 'html';
      } else if (imageExtensions.indexOf(extension) != -1) {
        result = 'image';
      } else if (videoExtensions.indexOf(extension) != -1) {
        result = 'video';
      } else if (documentExtensions.indexOf(extension) != -1) {
        result = 'document';
      } else if (audioExtensions.indexOf(extension) != -1) {
        result = 'audio';
      }
    }

    return result;
  }

  getDocumentType(item) {
    let type = 'document';

    const videoExtensions = [
      'mp4',
      'm4a',
      'm4v',
      'f4v',
      'f4a',
      'm4b',
      'avi',
      'm4p',
      'm4r',
      'f4b',
      'mov',
      'wmv',
      'wma',
      'webm',
      'flv',
      'mpg',
      'mp2',
      'mpeg',
      'mpe',
      'mpv',
      'ogg',
      'qt',
      'swf',
      'avchd',
      'video/webm',
      'mkv',
      'avchd',
    ];
    const imageExtensions = ['jpeg', 'jpg', 'png', 'heic', 'gif', 'bmp', 'tiff'];

    if (item) {
      if (
        (item['Document.BaseURL'] || item['Document.URL'] || item['URL.Base'] || item['URL.Complete']) &&
        (item['Document.URL'] || item['Document.RelativeURL'] || item['URL.Complete'] || item['URL.Relative'])
      ) {
        if (
          item['Document.BaseURL'] &&
          (item['Document.BaseURL'].indexOf('youtube.com') != -1 || item['Document.BaseURL'].indexOf('vimeo.com') != -1)
        ) {
          type = 'video';
        } else if (
          item['Document.URL'] &&
          (item['Document.URL'].indexOf('youtube.com') != -1 || item['Document.URL'].indexOf('vimeo.com') != -1)
        ) {
          type = 'video';
        } else if (
          item['URL.Base'] &&
          (item['URL.Base'].indexOf('youtube.com') != -1 || item['URL.Base'].indexOf('vimeo.com') != -1)
        ) {
          type = 'video';
        } else if (
          item['URL.Complete'] &&
          (item['URL.Complete'].indexOf('youtube.com') != -1 || item['URL.Complete'].indexOf('vimeo.com') != -1)
        ) {
          type = 'video';
        } else if (videoExtensions.indexOf(String(item['Document.Extension']).toLocaleLowerCase()) != -1) {
          type = 'video';
        } else if (imageExtensions.indexOf(String(item['Document.Extension']).toLocaleLowerCase()) != -1) {
          type = 'image';
        } else if (item['URL.Complete']) {
          type = 'link';
        } else if (item['Extension'] == 'pdf') {
          type = 'pdf';
        } else if (item['Document.Extension'] == 'pdf') {
          type = 'pdf';
        } else if (
          item['Extension'] == 'heic' ||
          item['Extension'] == 'bmp' ||
          item['Extension'] == 'png' ||
          item['Extension'] == 'gif' ||
          item['Extension'] == 'jpg' ||
          item['Extension'] == 'jpeg'
        ) {
          type = 'image';
        }
      } else if (videoExtensions.indexOf(String(item['Document.Extension']).toLocaleLowerCase()) != -1) {
        type = 'video';
      } else if (imageExtensions.indexOf(String(item['Document.Extension']).toLocaleLowerCase()) != -1) {
        type = 'image';
      } else if (videoExtensions.indexOf(String(item['Extension']).toLocaleLowerCase()) != -1) {
        type = 'video';
      } else if (imageExtensions.indexOf(String(item['Extension']).toLocaleLowerCase()) != -1) {
        type = 'image';
      } else if (item['Extension'] == 'pdf') {
        type = 'pdf';
      } else if (item['Document.Extension'] == 'pdf') {
        type = 'pdf';
      } else if (
        item['Extension'] == 'bmp' ||
        item['Extension'] == 'gif' ||
        item['Extension'] == 'jpg' ||
        item['Extension'] == 'jpeg'
      ) {
        type = 'image';
      } else if (
        item['Document.Extension'] == 'heic' ||
        item['Extension'] == 'bmp' ||
        item['Document.Extension'] == 'png' ||
        item['Document.Extension'] == 'gif' ||
        item['Document.Extension'] == 'jpg' ||
        item['Document.Extension'] == 'jpeg'
      ) {
        type = 'image';
      } else if (item['Document.Extension'] == 'video/webm') {
        type = 'video';
      } else if (item['Extension'] == 'video/webm') {
        type = 'video';
      } else if (
        item['Extension'] == 'xlsx' ||
        item['Extension'] == 'csv' ||
        item['Extension'] == 'tsv' ||
        item['Extension'] == 'zip'
      ) {
        type = 'excel';
      } else {
        type = 'document';
      }
    }

    return type;
  }

  buildAdvancedChartArray(ArrayObject, _rootNames, _seriesNames, _seriesValues, separator = ' - ') {
    try {
      const seriesNames = _seriesNames.split(',');
      const seriesValues = _seriesValues.split(',');
      const rootNames = _rootNames.split(',');
      const result = [];

      if (rootNames.length != seriesValues.length) {
        return [];
      }

      for (let i = 0; i < rootNames.length; i++) {
        const obj = {
          name: rootNames[i],
          series: [],
        };

        ArrayObject.map((o) => {
          const ojb2 = {
            name: o[seriesNames[0]],
            value: o[seriesValues[i]],
          };

          for (let j = 1; j < seriesNames.length; j++) {
            ojb2.name = ojb2.name + separator + o[seriesNames[j]];
          }

          obj.series.push(ojb2);
        });
        result.push(obj);
      }

      return result;
    } catch (e) {
      return [];
    }
  }

  public getDateTimeZone(date, currentFormat = 'YYYY-MM-DD') {
    if (date && date != '0000-00-00') {
      let customerTimeZone = 0;
      const serverTimeZoneUTC = Settings.global['serverTimeZoneUTC'];
      let result;

      if (ClientDetails && !ClientDetails.timeZoneUTC) {
        ClientDetails.getTimeZoneUTC();
      }

      if (ClientDetails && ClientDetails.timeZoneUTC) {
        customerTimeZone = ClientDetails.timeZoneUTC;
      }

      if (ClientDetails.timeZoneUTCProfileCode && ClientDetails.timeZoneUTCProfileValue != null) {
        customerTimeZone = Number(Number(ClientDetails.timeZoneUTCProfileValue) - Number(ClientDetails.timeZoneUTC));
      }

      if (serverTimeZoneUTC != 0) {
        result = moment(date).add(-(serverTimeZoneUTC + customerTimeZone), 'h');
      } else {
        result = moment(date, currentFormat);
      }

      return result;
    }
    return moment();
  }

  public getTimeZoneValueByCode(v) {
    if (v) {
      const diff = momentTZ().tz(v).utcOffset();
      return diff / 60;
    }
    return null;
  }

  public EPdateFormat = (date) => {
    let result;
    let m;
    const format = 'YYYY-MM-DD HH:mm';
    try {
      if (date == null || !date) {
        return null;
      } else if (typeof date == 'string') {
        let _d;
        try {
          _d = new Date(date).toISOString();
        } catch (e) {
          _d = date;
        }

        m = moment(_d);

        result = m.format(ClientDetails.formatUTC);
      } else if (moment.isDate(date)) {
        m = moment(date.toString());

        result = m.format(ClientDetails.formatUTC);
      } else {
        m = moment();
        result = m.format(ClientDetails.formatUTC);
      }
      return result;
    } catch (e) {
      return null;
    }
  };

  public validateFields = (target, rules) => {
    try {
      const validator = new Validator(target, rules);
      if (validator.passes()) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  };

  public getPastTime(dateTo, unit, type) {
    try {
      let _dateTo = dateTo;
      let result;
      if (!_dateTo) {
        return null;
      } else if (typeof _dateTo == 'string') {
        _dateTo = moment(_dateTo).utcOffset(0).toDate();
      } else if (!moment.isDate(dateTo)) {
        return null;
      }

      if (type == 'day') {
        result = moment(_dateTo).utcOffset(0).add(unit, 'd').set('h', 0).set('m', 0);
        result.hours(0);
        result.minutes(0);
      } else if (type == 'month') {
        result = moment(_dateTo).utcOffset(0).add(unit, 'M').set('h', 0).set('m', 0);
      } else if (type == 'year') {
        result = moment(_dateTo).utcOffset(0).add(unit, 'y').set('h', 0).set('m', 0);
      } else if (type == 'hour') {
        result = moment(_dateTo).utcOffset(0).add(unit, 'h').set('m', 0);
      } else if (type == 'min') {
        result = moment(_dateTo).utcOffset(0).add(unit, 'm');
      }

      return new Date(result.toDate());
    } catch (e) {
      return null;
    }
  }

  public getSessionTypeParams(url) {
    if (url) {
      let _url;
      if (typeof url == 'string') {
        _url = url;
      }

      if (Array.isArray(url) && url && url[0] && url[0]['path']) {
        _url = url[0]['path'];
      }

      if (_url) {
        if (_url.indexOf('-customer') != -1) {
          return 'customer';
        } else if (_url.indexOf('-merchant') != -1) {
          return 'merchant';
        } else if (_url.indexOf('-promoter') != -1) {
          return 'promoter';
        } else if (_url.indexOf('-supplier') != -1) {
          return 'supplier';
        } else if (_url.indexOf('-funder') != -1) {
          return 'funder';
        } else if (_url.indexOf('-guest') != -1) {
          return 'guest';
        }
      }
    }

    return null;
  }

  public getStringTimeFromEP(date) {
    if (date) {
      const d = moment(date, 'YYYY-MM-DD HH:mm:ss');

      const result = d.format('hh:mm A');

      if (result) {
        return result;
      }
    }

    return null;
  }

  public getStringDateFromEP(date) {
    if (date) {
      const d = moment(date, 'YYYY-MM-DD HH:mm:ss');

      const result = d.format('YYYY-MM-DD');

      if (result) {
        return result;
      }
    }

    return null;
  }

  public timeAgo(time) {
    if (time == '0000-00-00 00:00:00' || time == '0000-00-00' || !time) {
      return null;
    }

    const dateAgo = moment(time).toDate();

    const dateNow = new Date();
    const units = [
      { name: 'second', limit: 60, in_seconds: 1 },
      { name: 'minute', limit: 3600, in_seconds: 60 },
      { name: 'hour', limit: 86400, in_seconds: 3600 },
      { name: 'day', limit: 604800, in_seconds: 86400 },
      { name: 'week', limit: 2629743, in_seconds: 604800 },
      { name: 'month', limit: 31556926, in_seconds: 2629743 },
      { name: 'year', limit: null, in_seconds: 31556926 },
    ];
    const diff = (dateNow.getTime() - dateAgo.getTime()) / 1000;
    if (diff < 5) {
      return 'just now';
    }

    let i = 0,
      unit;
    while ((unit = units[i++])) {
      if (diff < unit.limit || !unit.limit) {
        const r = Number(diff / unit.in_seconds).toFixed(0);
        if (!r || isNaN(Number(r))) {
          return null;
        }

        return r + ' ' + unit.name + (Number(r) > 1 ? 's' : '');
      }
    }
  }

  public convertDateObject(seconds) {
    let result = {};
    seconds = Number(seconds);
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);

    result = {
      d: d || 0,
      h: h || 0,
      m: m || 0,
      s: s || 0,
    };

    return result;
  }

  public convertSec(seconds) {
    seconds = Number(seconds);
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);

    const dDisplay = d > 0 ? d + (d == 1 ? ' day ' : ' days ') : '';
    const hDisplay = h > 0 ? h + (h == 1 ? ' hour ' : ' hours ') : '';
    const mDisplay = m > 0 ? m + (m == 1 ? ' minute ' : ' minutes ') : '';
    const sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
    return dDisplay + hDisplay + mDisplay + sDisplay;
  }

  public daysPast(time, timeZone = true) {
    if (time == '0000-00-00 00:00:00' || time == '0000-00-00' || !time) {
      return null;
    }

    const dateAgo = ClientDetails.convertTimeZone(time, timeZone);

    const dateNow = new Date();

    let diff = (dateNow.getTime() - dateAgo.getTime()) / 1000;

    const r = Number(diff / (60 * 60 * 24)).toFixed(0);
    diff = Number(r);

    if (diff <= 0) {
      return 0;
    } else {
      return diff;
    }
  }

  public daysPastLabel(time, _dataNow = null, timeZone = true) {
    if (time == '0000-00-00 00:00:00' || time == '0000-00-00' || !time) {
      return null;
    }

    const dateAgo = ClientDetails.convertTimeZone(time, timeZone);

    let dateNow;

    if (_dataNow) {
      dateNow = moment(_dataNow).toDate();
    } else {
      dateNow = new Date();
    }

    const diff = (dateNow.getTime() - dateAgo.getTime()) / 1000;

    if (diff <= 60) {
      return 'Just Now';
    } else if (diff > 60 && diff < 3600) {
      const r = Number(diff / 60).toFixed(0);

      return r + ' min(s)';
    } else if (diff > 3600 && diff < 3600 * 24) {
      const r = Number(diff / (60 * 60)).toFixed(0);

      return r + ' hour(s)';
    }

    const r = Number(diff / (60 * 60 * 24)).toFixed(0);

    return r + ' day(s)';
  }

  daysPastColor(date) {
    const days = this.daysPast(date);

    if (!days && days != 0) {
      return '#b4bcc1';
    } else if (days == 0) {
      return '#5ABA47';
    } else if (days > 0 && days < 3) {
      return '#5ABA47';
    } else if (days == 3) {
      return '#5ABA47';
    } else if (days > 3 && days < 8) {
      return '#FBA919';
    } else if (days > 60) {
      return '#881d09';
    } else {
      return '#EF4727';
    }
  }

  public getGeoLocationRaw(geo) {
    if (geo) {
      try {
        const result = geo.split('-W')[0].split('-S/');
        result[0] = Number(-result[0]);
        result[1] = Number(-result[1]);

        return result;
      } catch (e) {
        return null;
      }
    } else {
      return null;
    }
  }

  public calculateAge(birthdate) {
    if (birthdate == '0000-00-00') {
      return null;
    }
    let age;

    const dateOfBirth = moment(birthdate).toDate();

    if (birthdate) {
      const timeDiff = this.daysPast(birthdate);
      // Used Math.floor instead of Math.ceil
      // so 26 years and 140 days would be considered as 26, not 27.
      age = Number(timeDiff / 365.25).toFixed(0);
      return age;
    }
  }

  public formatTermsAndConditions(text) {
    if (text) {
      let result = '';

      const a = text.split(/\r\n|\n|\r/g);

      for (let i = 0; i < a.length; i++) {
        result = result + '<p>' + a[i] + '</p>';
      }

      return result;
    } else {
      return '';
    }
  }

  public formatTermsAndConditions2(text) {
    if (text) {
      text = text.replace(/â¢ /g, '<span style="margin-left: 40px; margin-right: 10px;"> &#9679 </span>');

      // text = text.replace(/\r\n|\n|\r/g, "<br /> ");

      return text;
    } else {
      return '';
    }
  }

  getDefaultPic(_type = null, _extension = null) {
    let extension = null;
    let type = _type;

    if (_extension) {
      extension = String(_extension).toLocaleLowerCase();
    }
    if (!type && _extension) {
      type = this.getDocumentTypeFromExtension(_extension);
    }

    if (extension == 'css') {
      return 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/css.png';
    } else if (extension == 'js') {
      return 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/javascript.png';
    } else if (extension == 'txt') {
      return 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/txt.png';
    } else if (extension == 'zip') {
      return 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/zip.png';
    } else if (extension == 'json') {
      return 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/json-file.png';
    } else if (extension == 'xml') {
      return 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/xml.png';
    } else if (extension == 'pdf') {
      return 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/pdf.png';
    } else if (extension == 'html') {
      return 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/html.png';
    } else if (extension == 'docx' || extension == 'doc' || extension == 'document') {
      return 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/word-icon.png';
    } else if (extension == 'ppt' || extension == 'pptx') {
      return 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/pp-icon.png';
    } else if (type == 'pdf') {
      return 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/pdf.png';
    } else if (type == 'document') {
      return 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/file.png';
    } else if (type == 'html') {
      return 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/html.png';
    } else if (type == 'video') {
      return 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/player.png';
    } else if (type == 'link') {
      return 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/default-link-thumbnail.jpg';
    } else if (type == 'image') {
      if (extension == 'jpg' || extension == 'jpeg') {
        return 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/jpg.png';
      } else if (extension === 'png') {
        return 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/png.png';
      } else if (extension === 'heic') {
        return 'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/heic.png';
      } else {
        return 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/image-icon-holder.png';
      }
    } else if (type == 'excel') {
      return 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/default-excel-thumbnail.png';
    }

    return 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/file.png';
  }

  public selectObjectByString(o, s) {
    let _s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    _s = s.replace(/^\./, ''); // strip a leading dot

    let _o = JSON.parse(JSON.stringify(o));
    const a = _s.split('.');
    for (let i = 0, n = a.length; i < n; i += 1) {
      const k = a[i];

      if (k in _o) {
        if (Array.isArray(_o[k])) {
          if (a.length === i + 1) {
            _o = _o[k];
          } else {
            const tempSelectedString = a.slice(i + 1, a.length).join('.');

            const selectArrayAttribute = [];
            const tmpArray = _o[k];
            for (let j = 0; j < tmpArray.length; j += 1) {
              selectArrayAttribute.push(this.selectObjectByString(tmpArray[j], tempSelectedString));
            }
            _o = selectArrayAttribute;
            break;
          }
        } else {
          _o = _o[k];
        }
      } else {
        return undefined;
      }
    }

    return _o;
  }

  // diffrent between two objects
  public diffObjects(object, base): any[] {
    function changes(object, base): any[] {
      return transform(object, function (result, value, key) {
        if (!isEqual(value, base[key])) {
          result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
        }
      });
    }

    return changes(object, base);
  }

  public fusionObject(source, target) {
    const result = source;
    for (const key in target) {
      if (target.hasOwnProperty(key) && !result.hasOwnProperty(key)) {
        result[key] = target[key];
      }
    }

    return result;
  }

  public getCreditCardType(number) {
    if (!number) {
      return null;
    }

    // trim whitesapce
    number = number.replace(/\s+/g, '');

    // MasterCard
    if (/^5[1-5][0-9]{5,}$/.test(number)) {
      return 'MC';
    } else if (/^4[0-9]{6,}$/.test(number)) {
      return 'visa';
    } else if (/^3[47][0-9]{5,}$/.test(number)) {
      return 'amex';
    } else if (/^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/.test(number)) {
      return 'diners';
    } else if (/^6(?:011|5[0-9]{2})[0-9]{3,}$/.test(number)) {
      return 'discover';
    } else if (/^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/.test(number)) {
      return 'jcb';
    }

    return null;
  }

  errorLabel(code) {
    const errorTypes = {
      '550': 'EP Syntax',
      '560': 'EP RunTime',
      '551': 'EP Internal',
      '553': 'EP Lock',
      '554': 'EP Lock',
      '556': 'EP Lockup',
      '558': 'EP File',
      '557': 'EP Connection',
      '401': 'Login Invalid',
      '451': 'Data',
      '452': 'Data',
      '457': 'Data',
      '458': 'Data',
      '0': 'Hidden',
      '503': 'EP Auhtentication',
      '403': 'Auhtentication',
      '555': 'EP External',
      '500': 'Internal',
    };

    return errorTypes[String(code)] || 'Internal';
  }
  public replaceAndMergeObjects(obj, oldWord, newWord) {
    const newObj = {};

    for (const key in obj) {
      const newKey = key.replace(oldWord, newWord);
      newObj[newKey] = obj[key];
    }

    // Merge the original object with the modified object
    const mergedObject = { ...obj, ...newObj };

    return mergedObject;
  }
  public convertMessage(message, _rules, params: any = {}) {
    let rules = {};

    if (_rules) {
      rules = JSON.parse(JSON.stringify(_rules));
    }

    function buildLabel(d) {
      if (d) {
        return d.replace(/[A-Z]/g, ' $&').trim();
      }

      return d;
    }

    if (rules) {
      rules = this.replaceAndMergeObjects(rules, 'Practice', 'Merchant');
      rules = this.replaceAndMergeObjects(rules, 'Patient', 'Customer');
      rules = this.replaceAndMergeObjects(rules, 'Patient', 'Client');
      rules = this.replaceAndMergeObjects(rules, 'Patient', 'Receiver');
      rules = this.replaceAndMergeObjects(rules, 'Dentist', 'Dealer');
      rules = this.replaceAndMergeObjects(rules, 'Dentist', 'Veterinarian');
    }
    if (typeof message == 'string' && typeof rules == 'object') {
      let result = message;
      for (const key in rules) {
        if (rules.hasOwnProperty(key)) {
          if (params.isStrong === true) {
            if (params.isToolTip == true) {
              if (rules[key]) {
                result = result.replace(
                  new RegExp('{{' + key + '}}', 'g'),
                  '<strong title="' + buildLabel(key) + '">' + rules[key] + '</strong>'
                );
              } else {
                result = result.replace(
                  new RegExp('{{' + key + '}}', 'g'),
                  '<span title="' + buildLabel(key) + '"> </span>'
                );
              }
            } else {
              if (rules[key]) {
                result = result.replace(new RegExp('{{' + key + '}}', 'g'), '<strong>' + rules[key] + '</strong>');
              } else {
                result = result.replace(new RegExp('{{' + key + '}}', 'g'), '');
              }
            }
          } else {
            if (params.isToolTip == true) {
              if (rules[key]) {
                result = result.replace(
                  new RegExp('{{' + key + '}}', 'g'),
                  '<span title="' + buildLabel(key) + '">' + rules[key] + '</span>'
                );
              } else {
                result = result.replace(
                  new RegExp('{{' + key + '}}', 'g'),
                  '<span title="' + buildLabel(key) + '"> </span>'
                );
              }
            } else {
              if (rules[key]) {
                result = result.replace(new RegExp('{{' + key + '}}', 'g'), rules[key]);
              } else {
                result = result.replace(new RegExp('{{' + key + '}}', 'g'), '');
              }
            }
          }
        }
      }

      if (result) {
        const reg = /(\{\{.*?\}\})/g;
        result = result.replace(reg, '');
      }

      return result;
    }
    return message;
  }

  timeConvert(time) {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? ' AM' : ' PM';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
  }

  getMessage(a): string {
    return a.response
      ? a.response.Error
        ? typeof a.response.Error.message === 'object'
          ? a.response.Error.message.errno
          : a.response.Error.message
        : ''
      : '';
  }

  downloadBase64File(base64Data: string, fileName: string) {
    const linkSource = base64Data;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  convertImageLinkToBase64(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
          const reader = new FileReader();
          reader.onloadend = function () {
            resolve(reader.result as string);
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
      } catch (ex) {
        reject(ex);
      }
    });
  }

  /**
   * Removes special characters except the basic english symbols
   * @param {*} text string to be removed
   */
  cleanText(text: string) {
    const rgx = /[^\w\d\s`~!@#$%^&*()\-_=+[\]{}\\|;:'",<.>/?]/g;
    return text.replace(rgx, '');
  }
}
