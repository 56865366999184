import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-feature-sub-label-create-redirect',
  templateUrl: './feature-sub-label-create-redirect.component.html',
  styleUrls: ['./feature-sub-label-create-redirect.component.css'],
})
export class FeatureSubLabelCreateRedirectComponent implements OnInit {
  mainFeatureLabelID;
  featureType = 'all';
  root = '/merchant';

  constructor(private router: Router, private activeRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['featureType']) {
        this.featureType = params['featureType'];
      }

      if (this.featureType === 'consumer') {
        this.root = '/consumer';
      } else {
        this.root = '/merchant';
      }
      if (params['mainFeatureLabelID']) {
        this.mainFeatureLabelID = params['mainFeatureLabelID'];

        if (this.mainFeatureLabelID) {
          this.router.navigate([
            this.root,
            { outlets: { page: ['sub-feature-edit-create', this.mainFeatureLabelID] } },
          ]);
        }
      } else if (params['mainViewID']) {
        this.router.navigate([
          this.root,
          { outlets: { page: ['main-feature-view', params['mainViewID'], this.featureType] } },
        ]);
      } else if (params['subViewID']) {
        this.router.navigate([
          this.root,
          { outlets: { page: ['sub-feature', params['subViewID'], this.featureType] } },
        ]);
      }
    });
  }
}
