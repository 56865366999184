import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css'],
})
export class ContactUsComponent implements OnInit {
  simplePageCode = 'contactUs';
  filter = 'sendUS';

  @Input()
  invitationID;

  _invitationID;

  subjectArray = [];

  settings = Settings.global;

  constructor(
    private activeRoute: ActivatedRoute,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.invitationID) {
        this._invitationID = data.invitationID;
      }
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['consumerID']) {
        this.invitationID = params['consumerID'];
      } else {
        this.invitationID = this._invitationID;
      }
    });
  }
}
