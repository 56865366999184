<div class="row clearfix flex full-width" *ngIf="contract && !creditDetails && isDisplayFull == false">
  <mat-card class="clearfix full-width mb">
    <div class="colour-stripe"></div>
    <h3 class="sr-title sm-h3 rm-m">Credit Check</h3>
    <hr/>
    <div class="well text-center">
      <p class="lead">
        <mat-icon class="fas fa-search-dollar op07 mr"></mat-icon>
        This {{"KEYWORD.patient" | translate}} hasn't performed a credit check yet.
      </p>

      <button class="btn-small" mat-raised-button color="accent">
        Perform
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </mat-card>
</div>

<div class="row clearfix flex" *ngIf="contract && creditDetails && isDisplayFull == false">
  <mat-card class="full-width">
    <div class="colour-stripe"></div>
    <h3 class="sr-title sm-h3 rm-m">Personal circumstances</h3>
    <hr/>

    <div class="row clearfix mt">
      <div class="full-width">
        <div class="value">
          <p class="bold">Family Type</p>
          <p>{{ creditDetails['FamilyType'] }}</p>
        </div>

        <div class="value">
          <p class="bold">Location</p>
          <p>{{ creditDetails['Location'] }}</p>
        </div>

        <div class="value">
          <p class="bold">Housing</p>
          <p>{{ creditDetails['Input.Housing.Label'] }}</p>
        </div>

        <div class="value">
          <p class="bold">Residency</p>
          <p>{{ creditDetails['Input.Residency.Label'] }}</p>
        </div>

        <hr class="mt"/>

        <div class="value">
          <p class="bold">Employment Status</p>
          <p>{{ creditDetails['Input.EmploymentStatus.Label'] }}</p>
        </div>

        <div class="value">
          <p class="bold">Employment Type</p>
          <p>{{ creditDetails['Input.EmploymentType.Label'] }}</p>
        </div>

        <hr class="mt"/>

        <div class="value">
          <p class="bold">Months in Job</p>
          <p>{{ creditDetails['Input.Employment.MonthsInCurrentJob'] }}</p>
        </div>

        <div class="value">
          <p class="bold">Number of Jobs</p>
          <p>{{ creditDetails['Input.Employment.NumberOfJobs'] }}</p>
        </div>

        <div class="value">
          <p class="bold">Number of times unemployed</p>
          <p>{{ creditDetails['Input.UnemploymentPeriods.Count'] }}</p>
        </div>

        <div class="value">
          <p class="bold">Average weeks unemployed</p>
          <p>{{ creditDetails['Input.UnemolymentPeriods.AvgWeeks'] }}</p>
        </div>

        <div class="value">
          <p class="bold">Work hrs per week</p>
          <p>{{ creditDetails['Input.Employment.HoursPerWeek'] }}</p>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card class="full-width">
    <div class="colour-stripe"></div>

    <h3 class="sr-title sm-h3 rm-m">Scores</h3>
    <button class="btn-small positionRight" (click)="openScoreModal()" color="primary" mat-raised-button>
      View Score Card
    </button>
    <hr/>

    <div class="row clearfix text-center mt">
      <ngx-gauge
        class="no-title"
        [type]="'arch'"
        [value]="creditDetails['Result.Score.Applied']"
        [label]="contract['CreditCheck.Status']"
        [max]="1000"
        [thresholds]="moreIsBetter"
        [thick]="6"
        [cap]="'round'"
      >
      </ngx-gauge>
    </div>

    <div class="row clearfix">
      <div class="full-width">
        <div class="value">
          <p class="bold">Applied Score</p>
          <p>{{ creditDetails['Result.Score.Applied'] }}</p>
        </div>
        <div class="value">
          <p class="bold">E-Premium Score</p>
          <p>{{ creditDetails['Result.Score.ePremium'] }}</p>
        </div>
      </div>
    </div>
    <div class="row clearfix">
      <hr/>
      <div class="full-width">
        <div class="value">
          <p class="bold">Defaults</p>
          <p>{{ creditDetails['Defaults'] }}</p>
        </div>

        <div class="value">
          <p class="bold">Bankruptcies</p>
          <p>{{ creditDetails['Bankruptcies'] }}</p>
        </div>

        <div class="value">
          <p class="bold">Court actions</p>
          <p>{{ creditDetails['CourtActions'] }}</p>
        </div>

        <div class="value">
          <p class="bold">Personal circumstances</p>
          <p>{{ creditDetails['PersonalCircumstances'] }}</p>
        </div>
      </div>
    </div>
  </mat-card>

  <!-- <mat-card class="full-width clearfix">
      <div class="colour-stripe "></div>
      <mat-card-header>
        <mat-card-title>
          <h3 class="sr-title sm-h3 rm-m">Scores </h3>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row clearfix">
          <label>Method Used: VEDA - Comprehensive</label>
        </div>
        <div class="row clearfix">
          <ngx-gauge class="full-width" [type]="gaugeType" [value]="creditDetails['Result.Score.Applied']"
            [label]="gaugeLabel" [max]="1000" [thresholds]="thresholdConfig" [thick]="12" [cap]="'round'">
          </ngx-gauge>
        </div>
        <div class="row clearfix">
          <div class="creditScoreInfo">
            <div *ngIf="creditDetails['Result.Score.Applied'] >= 1 && creditDetails['Result.Score.Applied'] <= 549">
              <h4>Below Average</h4>
              <p class="small">Where you might see your score as below average, we see great potential. Be careful as
                you may be at risk experiencing an adverse event such as a default, bankruptcy or court judgment in the
                next 12 months. Don’t panic, sometimes simple changes can make a world of difference. We’re here to help
                increase your credit score so that overtime you will be on your way to achieving your financial goals.
              </p>
            </div>
            <div *ngIf="creditDetails['Result.Score.Applied'] >= 550 && creditDetails['Result.Score.Applied'] <= 624">
              <h4>Average</h4>
              <p class="small">Your credit score is similar to that of the average Australian. Although average might
                not sound too exciting it is a good enough score for you to achieve your financial goals. Our goal is to
                help you achieve a good credit score. To get you there, avoid any financial pitfalls over the next 12
                months that could lower your score.</p>
            </div>
            <div *ngIf="creditDetails['Result.Score.Applied'] >= 625 && creditDetails['Result.Score.Applied'] <= 699">
              <h4>Good</h4>
              <p class="small">Well done! You have a good credit score which means your odds of keeping a clean credit
                report over the next 12 months are better than most Australians. Keep up the good work and discover how
                to take your credit score to the next level.</p>
            </div>
            <div *ngIf="creditDetails['Result.Score.Applied'] >= 700 && creditDetails['Result.Score.Applied'] <= 799">
              <h4>Very Good</h4>
              <p class="small">Great work! With such a good credit score, you’re joining some of Australia’s credit
                score elite, outperforming most Australians. This means you have the luxury of more financial freedom
                than most. Keep up the great work and continuing working towards achieving an excellent score!</p>
            </div>
            <div *ngIf="creditDetails['Result.Score.Applied'] >= 800 && creditDetails['Result.Score.Applied'] <= 1000">
              <h4>Excellent</h4>
              <p class="small">Congratulations on your fantastic credit score! You and a select number of Australians
                are leading the credit score world by example. Having such a high score means your financial health
                appears to be in great shape, granting you an excellent chance of accessing better financial offers.</p>
            </div>

          </div>
        </div>
        <div class="row clearfix">

          <div class="clearfix flex labelMargin">
            <label class=""><strong>Equifax Score</strong></label>
            <p class="valueMargin">{{creditDetails['Result.Score.Veda']}} {{contract['CreditCheck.Status']}}</p>
          </div>
          <div class="clearfix flex labelMargin">
            <label class=""><strong>E-Premium Score</strong></label>
            <p class="valueMargin">{{creditDetails['Result.Score.ePremium']}}</p>
          </div>
          <div class="clearfix flex labelMargin" *ngIf="creditDetails['Defaults'] >= 1">
            <label class="">Default</label>
            <p class="valueMargin">{{creditDetails['Defaults']}}</p>
          </div>

          <div class="clearfix flex labelMargin" *ngIf="creditDetails['Bankruptcies'] >= 1">
            <label class="">Bankruptcies</label>
            <p class="valueMargin">{{creditDetails['Bankruptcies']}}</p>
          </div>
          <div class="clearfix flex labelMargin" *ngIf="creditDetails['CourtActions'] >= 1">
            <label class="">Court Actions</label>
            <p class="valueMargin">{{creditDetails['CourtActions']}}</p>
          </div>
          <div class="clearfix flex labelMargin">
            <label class="">Personal Circumstances</label>
            <p class="valueMargin">{{creditDetails['PersonalCircumstances']}}</p>
          </div>
        </div>
      </mat-card-content>
    </mat-card> -->

  <mat-card class="full-width">
    <div class="colour-stripe"></div>
    <h3 class="sr-title sm-h3 rm-m">Scores</h3>
    <hr/>

    <div class="row clearfix mt">
      <h4 class="sr-title">Initial result</h4>

      <div class="full-width">
        <div class="value">
          <p class="bold">Result</p>
          <p>
            <span
              class="chip"
              [class.pass]="creditDetails['Result.Initial'] == 'Accept'"
              [class.fail]="creditDetails['Result.Initial'] == 'Fail'"
            >{{ creditDetails['Result.Initial'] }}</span
            >
          </p>
        </div>

        <div class="value">
          <p class="bold">Date</p>
          <p>{{ creditDetails['Date.Initial'] | customDate }}</p>
        </div>

        <div class="value">
          <p class="bold">Time</p>
          <p>{{ creditDetails['Time.Initial'] }}</p>
        </div>

        <div class="value">
          <p class="bold">Operator</p>
          <p>{{ creditDetails['InitialOperator'] }}</p>
        </div>
      </div>
    </div>

    <hr/>
    <div class="row clearfix mt">
      <h4 class="sr-title">Final result</h4>

      <div class="full-width">
        <div class="value">
          <p class="bold">Result</p>
          <p>
            <span
              class="chip"
              [class.pass]="creditDetails['Result.Final'] == 'Accept'"
              [class.fail]="creditDetails['Result.Final'] == 'Fail'"
            >{{ creditDetails['Result.Final'] }}</span
            >
          </p>
        </div>

        <div class="value">
          <p class="bold">Date</p>
          <p>{{ creditDetails['Date.Final'] | customDate }}</p>
        </div>

        <div class="value">
          <p class="bold">Time</p>
          <p>{{ creditDetails['Time.Final'] }}</p>
        </div>

        <div class="value">
          <p class="bold">Operator</p>
          <p>{{ creditDetails['FinalOperator'] }}</p>
        </div>
      </div>
    </div>

    <div class="row clearfix mt">
      <button
        class="pull-right"
        *ngIf="checkstatus != 'Accept'"
        (click)="makeDecision()"
        mat-raised-button
        color="accent"
      >
        <mat-icon class="fas fa-clipboard-check"></mat-icon> Decide
      </button>
    </div>
  </mat-card>
</div>

<!--

<mat-dialog-content *ngIf="isDisplayFull==true">

  <div class="row clearfix" *ngIf="contract && creditDetails">
    <div class="row clearfix">
      <h2 class="sr-title ndge rm-mt pull-left">Detailed Credit Check View</h2>
    </div>
    <mat-card class="heading">
      <div class="card-header primary-gradient-img clearfix inModal">
        <div class="row clearfix">
          <div class="row titleArea clearfix">

            <app-ep-document-view-image [loading]="loading" class="patientView userIconView pull-left"
              [defaultPicture]="'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'"></app-ep-document-view-image>

            <h2 class="summary-header rm-m">
              Credit Check Summary
              <span class="subLabel">{{contract['Customer.FullName']}}</span>
            </h2>
          </div>
        </div>
      </div>
    </mat-card>

    <div class="full-width">
      <mat-tab-group dynamicHeight="true">
        <mat-tab>
          <ng-template mat-tab-label>
            Overview
          </ng-template>
          <div class="item row clearfix">
            <mat-card class="clearfix">
              <div class="colour-stripe "></div>
              <mat-card-header>
                <mat-card-title>
                  <h3 class="sr-title sm-h3 rm-m">Credit Check</h3>
                </mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="row clearfix">
                  <div class="half-width">
                    <div class="clearfix flex labelMargin">
                      <label class="">{{ "KEYWORD.patient" | translate | titlecase }}</label>
                      <p class="valueMargin">{{contract['Customer.FullName']}}</p>
                    </div>
                    <div class="clearfix flex labelMargin">
                      <label class="">Start Date</label>
                      <p class="valueMargin">{{contract['ContractDate'] | customDate}}</p>
                    </div>
                    <div class="clearfix flex labelMargin">
                      <label class="">Loan Value</label>
                      <p class="valueMargin">{{contract['Amount.FinancedBeforeDeposit'] | customCurrency}}</p>
                    </div>
                  </div>
                  <div class="half-width">
                    <div class="clearfix flex labelMargin">
                      <label class="">Contract</label>
                      <p class="valueMargin">{{contract['ContractDate'] | customDate}}</p>
                    </div>
                    <div class="clearfix flex labelMargin">
                      <label class="">Capacity Check</label>
                      <p class="valueMargin">{{contract['CapacityCheck'] | customDate}}</p>
                    </div>
                    <div class="clearfix flex labelMargin">
                      <label class="">Approval Matrix</label>
                      <p class="valueMargin">{{creditDetails['ApprovalMatrix'] | customDate}}</p>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

          <div class="row clearfix flex">
            <div class="item full-width">
              <mat-card class="clearfix">
                <div class="colour-stripe "></div>
                <mat-card-header>
                  <mat-card-title>
                    <h3 class="sr-title sm-h3 rm-m">Personal Circumstances</h3>
                  </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="row clearfix">
                    <div class="clearfix flex labelMargin">
                      <label class="">Family Type</label>
                      <p class="valueMargin">{{creditDetails['FamilyType']}}</p>
                    </div>
                    <div class="clearfix flex labelMargin">
                      <label class="">Location</label>
                      <p class="valueMargin">{{creditDetails['Location']}}</p>
                    </div>
                    <div class="clearfix flex labelMargin">
                      <label class="">Housing</label>
                      <p class="valueMargin">{{creditDetails['Input.Housing.Label']}}</p>
                    </div>
                    <div class="clearfix flex labelMargin">
                      <label class="">Residency</label>
                      <p class="valueMargin">{{creditDetails['Input.Residency.Label']}}</p>
                    </div>
                    <div class="clearfix flex labelMargin">
                      <label class="">Employment Status</label>
                      <p class="valueMargin">{{creditDetails['Input.EmploymentStatus.Label']}}</p>
                    </div>
                    <div class="clearfix flex labelMargin">
                      <label class="">Employment Type</label>
                      <p class="valueMargin">{{creditDetails['Input.EmploymentType.Label']}}</p>
                    </div>
                    <div class="clearfix flex labelMargin">
                      <label class="">Months In Job</label>
                      <p class="valueMargin">{{creditDetails['Input.Employment.MonthsInCurrentJob']}}</p>
                    </div>
                    <div class="clearfix flex labelMargin">
                      <label class="">Number Of Jobs</label>
                      <p class="valueMargin">{{creditDetails['Input.Employment.NumberOfJobs']}}</p>
                    </div>
                    <div class="clearfix flex labelMargin">
                      <label class="">Number Of Times Unemployed</label>
                      <p class="valueMargin">{{creditDetails['Input.UnemploymentPeriods.Count']}}</p>
                    </div>
                    <div class="clearfix flex labelMargin">
                      <label class="">Average Weeks Unemployed</label>
                      <p class="valueMargin">{{creditDetails['Input.UnemolymentPeriods.AvgWeeks']}}</p>
                    </div>
                    <div class="clearfix flex labelMargin">
                      <label class="">Work Hours Per Week</label>
                      <p class="valueMargin">{{creditDetails['Input.Employment.HoursPerWeek']}}</p>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="item full-width">
              <mat-card class="clearfix">
                <div class="colour-stripe "></div>
                <mat-card-header>
                  <mat-card-title>
                    <h3 class="sr-title sm-h3 rm-m">Scores </h3>
                  </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="row clearfix">
                    <label>Method Used: VEDA - Comprehensive</label>
                  </div>
                  <div class="row clearfix">
                    <ngx-gauge class="full-width" [type]="gaugeType" [value]="creditDetails['Result.Score.Applied']"
                      [label]="gaugeLabel" [max]="1000" [thresholds]="thresholdConfig" [thick]="12" [cap]="'round'">
                    </ngx-gauge>
                  </div>
                  <div class="row clearfix">
                    <div class="creditScoreInfo">
                      <div
                        *ngIf="creditDetails['Result.Score.Applied'] >= 1 && creditDetails['Result.Score.Applied'] <= 549">
                        <h4>Below Average</h4>
                        <p class="small">Where you might see your score as below average, we see great potential. Be
                          careful as you may be at risk experiencing an adverse event such as a default, bankruptcy or
                          court judgment in the next 12 months. Don’t panic, sometimes simple changes can make a world
                          of difference. We’re here to help increase your credit score so that overtime you will be on
                          your way to achieving your financial goals.</p>
                      </div>
                      <div
                        *ngIf="creditDetails['Result.Score.Applied'] >= 550 && creditDetails['Result.Score.Applied'] <= 624">
                        <h4>Average</h4>
                        <p class="small">Your credit score is similar to that of the average Australian. Although
                          average might not sound too exciting it is a good enough score for you to achieve your
                          financial goals. Our goal is to help you achieve a good credit score. To get you there, avoid
                          any financial pitfalls over the next 12 months that could lower your score.</p>
                      </div>
                      <div
                        *ngIf="creditDetails['Result.Score.Applied'] >= 625 && creditDetails['Result.Score.Applied'] <= 699">
                        <h4>Good</h4>
                        <p class="small">Well done! You have a good credit score which means your odds of keeping a
                          clean credit report over the next 12 months are better than most Australians. Keep up the good
                          work and discover how to take your credit score to the next level.</p>
                      </div>
                      <div
                        *ngIf="creditDetails['Result.Score.Applied'] >= 700 && creditDetails['Result.Score.Applied'] <= 799">
                        <h4>Very Good</h4>
                        <p class="small">Great work! With such a good credit score, you’re joining some of Australia’s
                          credit score elite, outperforming most Australians. This means you have the luxury of more
                          financial freedom than most. Keep up the great work and continuing working towards achieving
                          an excellent score!</p>
                      </div>
                      <div
                        *ngIf="creditDetails['Result.Score.Applied'] >= 800 && creditDetails['Result.Score.Applied'] <= 1000">
                        <h4>Excellent</h4>
                        <p class="small">Congratulations on your fantastic credit score! You and a select number of
                          Australians are leading the credit score world by example. Having such a high score means your
                          financial health appears to be in great shape, granting you an excellent chance of accessing
                          better financial offers.</p>
                      </div>

                    </div>
                  </div>
                  <div class="row clearfix">

                    <div class="clearfix flex labelMargin">
                      <label class=""><strong>Equifax Score</strong></label>
                      <p class="valueMargin">{{creditDetails['Result.Score.Veda']}} {{contract['CreditCheck.Status']}}
                      </p>
                    </div>
                    <div class="clearfix flex labelMargin">
                      <label class=""><strong>E-Premium Score</strong></label>
                      <p class="valueMargin">{{creditDetails['Result.Score.ePremium']}}</p>
                    </div>
                    <div class="clearfix flex labelMargin" *ngIf="creditDetails['Defaults'] >= 1">
                      <label class="">Default</label>
                      <p class="valueMargin">{{creditDetails['Defaults']}}</p>
                    </div>

                    <div class="clearfix flex labelMargin" *ngIf="creditDetails['Bankruptcies'] >= 1">
                      <label class="">Bankruptcies</label>
                      <p class="valueMargin">{{creditDetails['Bankruptcies']}}</p>
                    </div>
                    <div class="clearfix flex labelMargin" *ngIf="creditDetails['CourtActions'] >= 1">
                      <label class="">Court Actions</label>
                      <p class="valueMargin">{{creditDetails['CourtActions']}}</p>
                    </div>
                    <div class="clearfix flex labelMargin">
                      <label class="">Personal Circumstances</label>
                      <p class="valueMargin">{{creditDetails['PersonalCircumstances']}}</p>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="item full-width">
              <mat-card class="clearfix">
                <div class="colour-stripe "></div>
                <mat-card-header>
                  <mat-card-title>
                    <h3 class="sr-title sm-h3 rm-m">Result</h3>
                  </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="row clearfix">
                    <h4 class="text-center">Initial Result</h4>
                    <div class="clearfix flex labelMargin">
                      <label class="">Result</label>
                      <p class="valueMargin">{{creditDetails['Result.Initial']}}</p>
                    </div>
                    <div class="clearfix flex labelMargin">
                      <label class="">Date</label>
                      <p class="valueMargin">{{creditDetails['Date.Initial'] | customDate}}</p>
                    </div>
                    <div class="clearfix flex labelMargin">
                      <label class="">Time</label>
                      <p class="valueMargin">{{creditDetails['Time.Initial']}}</p>
                    </div>
                    <div class="clearfix flex labelMargin">
                      <label class="">Operator</label>
                      <p class="valueMargin">{{creditDetails['InitialOperator']}}</p>
                    </div>

                    <h4 class="text-center">Final Result</h4>
                    <div class="clearfix flex labelMargin">
                      <label class="">Result</label>
                      <p class="valueMargin">{{creditDetails['Result.Final']}}</p>
                    </div>
                    <div class="clearfix flex labelMargin">
                      <label class="">Date</label>
                      <p class="valueMargin">{{creditDetails['Date.Final'] | customDate}}</p>
                    </div>
                    <div class="clearfix flex labelMargin">
                      <label class="">Time</label>
                      <p class="valueMargin">{{creditDetails['Time.Final']}}</p>
                    </div>
                    <div class="clearfix flex labelMargin">
                      <label class="">Operator</label>
                      <p class="valueMargin">{{creditDetails['FinalOperator']}}</p>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>

          <div class="item row clearfix" *ngIf="creditDetails['Result.Reason']">
            <mat-card class="clearfix">
              <div class="colour-stripe "></div>
              <mat-card-header>
                <mat-card-title>
                  <h3 class="sr-title sm-h3 rm-m">Reason for Final Decision</h3>
                </mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <p [innerHTML]="creditDetails['Result.Reason'] | safeHtml"></p>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            Equifax - Report
          </ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            Equifax - XML Explorer
          </ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            Equifax - Raw XML
          </ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            Documents
          </ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            Action log
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>


</mat-dialog-content> -->
