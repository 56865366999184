<div class="row" *ngIf="patientID">
  <div class="row clearfix">
    <h2 class="sr-title ndge pull-left rm-mb">{{"KEYWORD.patient" | translate | titlecase}} Documents</h2>
  </div>
  <div class="row clearfix text-left">
    <p class="rm-mt">
      Drag and drop any {{"KEYWORD.patient" | translate}} documents you wish to upload here. Anything uploaded will be
      shared with your {{"KEYWORD.patient" | translate}}
      via the {{"KEYWORD.patient" | translate}} portal and can be accessed
      in {{"PHRASE.treatment planning" | translate}}.
    </p>
  </div>

  <div class="full-width" *ngFor="let d of documentTypes | OrderArrayObject: ['TypeCode']; let index = index">
    <div class="full-width row flex" *ngIf="index % 3 == 0">
      <mat-card class="card treatment-card card1 text-left full-width row mr" *ngIf="documentTypes[index]">
        <div class="colour-stripe"></div>
        <div class="row clearfix">
          <h3 class="sr-title sm-h3 rm-m">
            {{ getLabelByCode(documentTypes[index]['TypeCode'], documentTypes[index]['Label']) }}
          </h3>
          <hr />

          <div class="row clearfix">
            <app-file-uploader
              class="text-center clearfix mb"
              [allowedExtensionTypes]="documentTypes[index]['allowedExtensionTypes']"
              [documentType]="null"
              [isGallery]="false"
              [uploadDirectly]="true"
              [limit]="1"
              [isDragable]="true"
              (onCompleteAll)="onCompleteAll($event, documentTypes[index]['TypeCode'])"
            >
            </app-file-uploader>

            <app-ep-document-list
              class="full-width clearfix"
              *ngIf="
                documentTypes[index]['TypeCode'] &&
                documents[documentTypes[index]['TypeCode']] &&
                documents[documentTypes[index]['TypeCode']].length > 0
              "
              [isDownload]="true"
              [isPrint]="true"
              [canEdit]="true"
              [canRemove]="true"
              [isFilter]="false"
              [defaultCheck]="false"
              [isRedirection]="false"
              [documents]="documents[documentTypes[index]['TypeCode']]"
              [title]="''"
              [canCheck]="false"
              [listViewType]="'default'"
              [directRemove]="false"
              [deleteFile]="removeEvents[documentTypes[index]['TypeCode']]"
              [addFile]="addEvents[documentTypes[index]['TypeCode']]"
              [miniEdit]="true"
              (removeID)="removeDocument($event, documentTypes[index]['TypeCode'])"
            >
            </app-ep-document-list>
          </div>
        </div>
      </mat-card>

      <mat-card class="card treatment-card card1 text-left full-width row mr" *ngIf="documentTypes[index + 1]">
        <div class="colour-stripe"></div>
        <div class="row clearfix">
          <h3 class="sr-title sm-h3 rm-m">
            {{ getLabelByCode(documentTypes[index + 1]['TypeCode'], documentTypes[index + 1]['Label']) }}
          </h3>
          <hr />

          <div class="row clearfix">
            <app-file-uploader
              class="text-center clearfix mb"
              [allowedExtensionTypes]="documentTypes[index + 1]['allowedExtensionTypes']"
              [documentType]="null"
              [isGallery]="false"
              [uploadDirectly]="true"
              [limit]="1"
              [isDragable]="true"
              (onCompleteAll)="onCompleteAll($event, documentTypes[index + 1]['TypeCode'])"
            >
            </app-file-uploader>

            <app-ep-document-list
              class="full-width clearfix"
              *ngIf="
                documentTypes[index + 1]['TypeCode'] &&
                documents[documentTypes[index + 1]['TypeCode']] &&
                documents[documentTypes[index + 1]['TypeCode']].length > 0
              "
              [isDownload]="true"
              [isPrint]="true"
              [canEdit]="true"
              [canRemove]="true"
              [isFilter]="false"
              [defaultCheck]="false"
              [isRedirection]="false"
              [documents]="documents[documentTypes[index + 1]['TypeCode']]"
              [title]="''"
              [canCheck]="false"
              [listViewType]="'default'"
              [directRemove]="false"
              [deleteFile]="removeEvents[documentTypes[index + 1]['TypeCode']]"
              [addFile]="addEvents[documentTypes[index + 1]['TypeCode']]"
              [miniEdit]="true"
              (removeID)="removeDocument($event, documentTypes[index + 1]['TypeCode'])"
            >
            </app-ep-document-list>
          </div>
        </div>
      </mat-card>

      <mat-card class="card treatment-card card1 text-left full-width row" *ngIf="documentTypes[index + 2]">
        <div class="colour-stripe"></div>
        <div class="row clearfix">
          <h3 class="sr-title sm-h3 rm-m">
            {{ getLabelByCode(documentTypes[index + 2]['TypeCode'], documentTypes[index + 2]['Label']) }}
          </h3>
          <hr />

          <div class="row clearfix">
            <app-file-uploader
              class="text-center clearfix mb"
              [allowedExtensionTypes]="documentTypes[index + 2]['allowedExtensionTypes']"
              [documentType]="null"
              [isGallery]="false"
              [uploadDirectly]="true"
              [limit]="1"
              [isDragable]="true"
              (onCompleteAll)="onCompleteAll($event, documentTypes[index + 2]['TypeCode'])"
            >
            </app-file-uploader>

            <app-ep-document-list
              class="full-width clearfix"
              *ngIf="
                documentTypes[index + 2]['TypeCode'] &&
                documents[documentTypes[index + 2]['TypeCode']] &&
                documents[documentTypes[index + 2]['TypeCode']].length > 0
              "
              [isDownload]="true"
              [isPrint]="true"
              [canEdit]="true"
              [canRemove]="true"
              [isFilter]="false"
              [defaultCheck]="false"
              [isRedirection]="false"
              [documents]="documents[documentTypes[index + 2]['TypeCode']]"
              [title]="''"
              [canCheck]="false"
              [listViewType]="'default'"
              [directRemove]="false"
              [deleteFile]="removeEvents[documentTypes[index + 2]['TypeCode']]"
              [addFile]="addEvents[documentTypes[index + 2]['TypeCode']]"
              [miniEdit]="true"
              (removeID)="removeDocument($event, documentTypes[index + 2]['TypeCode'])"
            >
            </app-ep-document-list>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
