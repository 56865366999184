import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { TestimonyService } from '../shared/testimony.service';

import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { ProspectViewComponent } from '../../existing-patient/prospect-view/prospect-view.component';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';

@Component({
  selector: 'app-testimony-view',
  templateUrl: './testimony-view.component.html',
  styleUrls: ['./testimony-view.component.css'],
})
export class TestimonyViewComponent implements OnInit {
  @Input()
  testimonyID = '5c340e13adc10e07c342410b';

  @Output() close = new EventEmitter();

  @Output() update = new EventEmitter();

  @Output() delete = new EventEmitter();

  testimony;

  starList = [];
  rating;

  util = new UtilsClass();
  dateNow;

  constructor(
    private UtilsService: UtilsService,
    private testimonyService: TestimonyService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.testimonyID = data;
    }
  }

  ngOnInit() {
    this.UtilsService.getServerDate().subscribe((ress) => {
      this.dateNow = ress;

      this.testimonyService.getOneTestimony(this.testimonyID).subscribe((res) => {
        this.testimony = res;
        this.rating = this.testimony['starts'];
        this.setRating();
      });
    });
  }

  closeModalEvent() {
    this.close.emit(true);
  }

  setRating() {
    for (let i = 1; i <= this.rating; i++) {
      this.starList.push(false);
    }
  }

  replaceImage(fileID) {
    if (fileID) {
      const s = fileID.replace(
        './assets/avatars/',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/'
      );
      return s;
    }
  }

  viewProspect(id) {
    RootAppComponent.dialog.open(ProspectViewComponent, {
      data: id,
      width: '750px',
      panelClass: 'noCard',
    });
  }

  viewMerchant(id) {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: id,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  deleteTestimony(id) {
    const confirmMessage = new ConfirmDialog(
      'fas fa-info',
      'Delete Testimony',
      'Are you sure you want to detele this testimony?',
      'No',
      'Yes'
    );
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmMessage,
      width: '650px',
    });
    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        this.testimonyService.deleteTestimony(id).subscribe((res) => {
          if (res) {
            this.delete.emit(res);

            NotifyAppComponent.displayToast(
              'success',
              'Successful operation',
              'You successfully delele this testimony'
            );
            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }

  activateTestimony(id) {
    const confirmMessage = new ConfirmDialog(
      'fas fa-info',
      'Activate Testimony',
      'Are you sure you want to activate this testimony?',
      'No',
      'Yes'
    );
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmMessage,
      width: '650px',
    });
    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        const payload = {
          isActive: true,
        };
        this.testimonyService.activeTestimony(id, payload).subscribe((res) => {
          if (res) {
            this.testimony = res;

            this.update.emit(res);
            NotifyAppComponent.displayToast(
              'success',
              'Successful operation',
              'You successfully activate this testimony'
            );
            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }

  deactivateTestimony(id) {
    const confirmMessage = new ConfirmDialog(
      'fas fa-info',
      'Deactivate Testimony',
      'Are you sure you want to deactivate this testimony?',
      'No',
      'Yes'
    );
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmMessage,
      width: '650px',
    });
    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        const payload = {
          isActive: false,
        };
        this.testimonyService.activeTestimony(id, payload).subscribe((res) => {
          if (res) {
            this.testimony = res;
            this.update.emit(res);
            NotifyAppComponent.displayToast(
              'success',
              'Successful operation',
              'You successfully deactivate this testimony'
            );
            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }
}
