import { Contract } from '../../../../contract/shared/types/contract.type';

export class RevenueReportActualRecord {
  label: string;
  code: string;
  actual: number;
  refunds: number;
  actualNet: number;
  actualPercentage: number;
}

export class RevenueReportAccruedRecord {
  label: string;
  code: string;
  accrued: number;
  waviersOrRebates: number;
  accruedNet: number;
  accruedPercentage: number;
}

export class RevenueReportContract extends (Contract as new () => Pick<
  Contract,
  | 'ID'
  | 'Customer.FullName'
  | 'Customer.Email'
  | 'Customer_key'
  | 'Product.Label.Marketing'
  | 'Product_key'
  | 'FunderName'
  | 'Funder_key'
>) {}

export class RevenueReportFeesActual {
  Actual: number;
  Refunds: number;
  Actual_Net: number;
  Code?: string;
  Label?: string;
}

export class RevenueReportFeesAccrued {
  Accrued: number;
  WaiversOrRebates: number;
  Accrued_Net: number;
  Code?: string;
  Label?: string;
}

export class RevenueReportPercentage {
  Actual: number | null;
  Accrued: number;
}

export class RevenueReportRequestOptionals {
  merchantID?: string;
  contractKeys?: string;
  productIDs?: string;
  funderIDs?: string;
  merchantGroupIDs?: string;
}

export class RevenueReportRequest {
  From_Date: string;
  To_Date: string;
  Merchants?: string;
  Contract_key?: string;
  Products?: string;
  Funders?: string;
  Merchant_Groups?: string;

  constructor(fromDate: string, toDate: string, optional?: RevenueReportRequestOptionals) {
    this.From_Date = fromDate;
    this.To_Date = toDate;

    if (optional) {
      if (optional.contractKeys) {
        this.Contract_key = optional.contractKeys;
      }

      if (optional.productIDs) {
        this.Products = optional.productIDs;
      }

      if (optional.funderIDs) {
        this.Funders = optional.funderIDs;
      }

      if (optional.merchantGroupIDs) {
        this.Merchant_Groups = optional.merchantGroupIDs;
      }

      if (optional.merchantID) {
        this.Merchants = optional.merchantID;
      }
    }
  }
}

export class RevenueReport {
  [key: string]: any;

  'BorrowerFees_Actual': RevenueReportFeesActual[];
  'BorrowerFeesActual_Total': RevenueReportFeesActual[];
  'BorrowerInterestActual_Total': RevenueReportFeesActual[];
  'BorrowerInterestAccrued_Total': RevenueReportFeesAccrued[];
  'MerchantFees_Actual': RevenueReportFeesActual[];
  'MerchantSubscriptionFees_Actual': RevenueReportFeesActual[];
  'MerchantSubscriptionOtherFees_Actual': RevenueReportFeesActual[];
  'MerchantFeesActual_Total': RevenueReportFeesActual[];
  'TotalRevenue_Actual': RevenueReportFeesActual[];
  'BorrowerFees_Accrued': RevenueReportFeesAccrued[];
  'BorrowerFeesAccrued_Total': RevenueReportFeesAccrued[];
  'MerchantFees_Accrued': RevenueReportFeesAccrued[];
  'MerchantSubscriptionFees_Accrued': RevenueReportFeesAccrued[];
  'MerchantSubscriptionOtherFees_Accrued': RevenueReportFeesAccrued[];
  'MerchantFeesAccrued_Total': RevenueReportFeesAccrued[];
  'TotalRevenue_Accrued': RevenueReportFeesAccrued[];
  'BorrowerFeesPercentage': RevenueReportPercentage[];
  'BorrowerInterestPercentage': RevenueReportPercentage[];
  'MerchantFeesPercentage': RevenueReportPercentage[];
  'NoOfCntrctsInActualCustRecords': number;
  'FinancedTotal': number;
  'TotalExecludingDep': number;
  'NoOfCntrctsActvOrSus': number;
  'TotalDueTillToday': number;
  'Merchant_Groups': string;
  'Funders': string;
  'Products': string;
  'StartDate': string;
  'EndDate': string;
}
