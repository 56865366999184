import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

@Component({
  selector: 'app-security-code-input',
  templateUrl: './security-code-input.component.html',
  styleUrls: ['./security-code-input.component.css'],
})
export class SecurityCodeInput implements OnInit {
  @Input()
  title = 'Request authorisation';

  @Input()
  subTitle =
    ' An Authorisation code has been sent to your customer via SMS , please get them to recite the code and enter it here.';

  @Output()
  sendCode = new EventEmitter();

  @Output()
  getCode = new EventEmitter();

  @Output()
  getRememberMe = new EventEmitter();

  @Output()
  closeModal = new EventEmitter();

  util = new UtilsClass();

  @Input()
  smsSent = false;

  @Input()
  emailSent = false;

  settings = Settings.global;
  @Input()
  timeLeft = 60;
  interval;

  securityCode;
  @Input()
  displayRememberMe = false;

  @Input()
  isModal = false;
  rememberMe = false;

  @Input()
  validMinutes = 60;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.smsSent != null) {
        this.smsSent = data.smsSent;
      }

      if (data.emailSent != null) {
        this.emailSent = data.emailSent;
      }

      if (data.title) {
        this.title = data.title;
      }

      if (data.subTitle) {
        this.subTitle = data.subTitle;
      }

      if (data.validMinutes) {
        this.validMinutes = data.validMinutes;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.countDown();
  }

  countDown() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  submitEvent() {
    this.getCode.emit(this.securityCode);
  }

  sendCodeEvent() {
    this.sendCode.emit(true);
  }

  closeEvent() {
    this.closeModal.emit(true);
  }

  changeRememberMe(a) {
    this.getRememberMe.emit(this.rememberMe);
  }
}
