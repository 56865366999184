import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { ClientDetails } from '../types/client-details';

@Pipe({ name: 'customTime' })
export class TimeFormat implements PipeTransform {
  transform(time: any, _format: string = null, timeZone = true): any {
    try {
      let format = 'hh:mm A';

      if (_format) {
        format = _format;
      }
      const current = moment();

      const hour = time.split(':')[0];
      const min = time.split(':')[1];

      current.set('h', hour || '0');
      current.set('m', min || '0');

      const d = ClientDetails.convertTimeZone(current, timeZone);

      return moment(d).format(format);
    } catch (e) {
      return null;
    }
  }
}
