import { Location } from '@angular/common';
import { Component, Input, OnInit, ViewChildren } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { HeaderService } from '../../../shared/services/header.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { DocumentCreateComponent } from '../../document/document-create/document-create.component';
import { AddDentistryTypesProfileDialogComponent } from '../../merchant/dialogs/add-dentistry-types-profile-dialog/add-dentistry-types-profile-dialog.component';
import { AddHealthFundProfileDialogComponent } from '../../merchant/dialogs/add-health-fund-profile-dialog/add-health-fund-profile-dialog.component';
import { AddServiceProfileDialogComponent } from '../../merchant/dialogs/add-service-profile-dialog/add-service-profile-dialog.component';
import { MerchantTradingHoursOverviewComponent } from '../../merchant/merchant-trading-hours-overview/merchant-trading-hours-overview.component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-merchant-profile',
  templateUrl: './merchant-profile.component.html',
  styleUrls: ['./merchant-profile.component.css'],
})
export class MerchantProfileComponent implements OnInit {
  @Input()
  merchantID;

  merchant;
  context = Settings.global['context'];
  isPromoterOrAdmin = false;

  profileLink;

  isAdressValid = false;
  validateMobile = false;
  validatePhone = false;
  validateEmail = false;
  validateReplyToEmail = false;

  merchantMobile;
  merchantPhone;
  merchantEmail;
  merchantReplyToEmail;

  merchantSuburb;
  merchantState;
  merchantPostCode;
  merchantStreetNumber;
  merchantStreetName;
  merchantUnitNumber;
  merchantStreetType;
  merchantLatitude;
  merchantLongitude;

  profileDescription;

  @ViewChildren('sidenav') sidenav: MatSidenav;
  navMode = 'side';
  openFlag = true;

  settings = 'practice';
  dentistID;
  hideContents = false;

  isCreateTradingTime = false;
  merchantTimezone;
  tradingHours;

  util = new UtilsClass();

  practiceLegalName;
  sessionType = 'guest';

  merchantProfileServices = [];
  merchantProfileHealthFunds = [];
  merchantProfileDentistryTypes = [];

  constructor(
    private activeRouter: ActivatedRoute,
    private router: Router,
    private merchantService: MerchantService,
    private authenticationService: AuthenticationService,
    private location: Location
  ) {}

  ngOnInit() {
    this.activeRouter.params.subscribe((params) => {
      if (params['merchantID']) {
        this.merchantID = params['merchantID'];
      }

      if (params['settings']) {
        this.settings = params['settings'];
      }

      if (params['itemID']) {
        if (this.settings == 'staff') {
          this.dentistID = params['itemID'];
        }
      }
      this.authenticationService.getSessionType().subscribe((sessionType) => {
        if (sessionType) {
          this.sessionType = sessionType;
        }

        this.authenticationService.isPromoterOrAdmin().subscribe((res1) => {
          this.isPromoterOrAdmin = res1;

          if (this.isPromoterOrAdmin == true) {
            const _mID = JSON.parse(localStorage.getItem('selectedMerchant'));
            if (_mID) {
              this.merchantID = _mID;
              // this.hideContents = true;
              this.setup();
            } else {
              this.setup();
            }

            HeaderService.getPromoterViewAsObject().subscribe((res) => {
              if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'promoter') {
                this.setup();
              } else if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'merchant') {
                this.merchantID = res['merchantID'];
                this.hideContents = true;
                this.setup();
              }
            });
          } else {
            this.router.navigate(['/merchant']);
          }

          this.merchantService
            .getMerchantProfileServicesItemsList({ Merchant_key: this.merchantID })
            .subscribe((data) => {
              this.merchantProfileServices = data.sort((a, b) =>
                a['Service_Group_Item.Label'] > b['Service_Group_Item.Label'] ? 1 : -1
              );
            });

          this.merchantService
            .getMerchantProfileHealthFundsList({ Merchant_key: this.merchantID })
            .subscribe((data) => {
              this.merchantProfileHealthFunds = data.sort((a, b) =>
                a['HealthFund.Label'] > b['HealthFund.Label'] ? 1 : -1
              );
            });

          this.merchantService
            .getMerchantProfileDentistryTypesList({ Merchant_key: this.merchantID })
            .subscribe((data) => {
              this.merchantProfileDentistryTypes = data.sort((a, b) => (a.Label > b.Label ? 1 : -1));
            });
        });
      });
    });
  }

  setup() {
    if (this.merchantID) {
      this.merchantService.getMerchantDetails(this.merchantID, {}, this.sessionType).subscribe((res) => {
        if (res) {
          this.merchant = res;

          if (this.merchant['CardType'] == 'Commercial - Sole-Trader') {
            this.practiceLegalName =
              this.merchant['Salutation'] + ' ' + this.merchant['FirstName'] + ' ' + this.merchant['Name'];
          } else {
            this.practiceLegalName = this.merchant['Name'];
          }

          this.merchantPhone = this.merchant['phones.Number'];
          this.merchantMobile = this.merchant['mobiles.Number'];
          this.merchantEmail = this.merchant['emails.Email'];

          this.merchantReplyToEmail = this.merchant['email.ReplyTo'];

          this.merchantSuburb = this.merchant['addresses.Suburb'];
          this.merchantState = this.merchant['addresses.State'];
          this.merchantPostCode = this.merchant['addresses.Postcode'];
          this.merchantStreetNumber = this.merchant['addresses.Street Nr'];
          this.merchantStreetName = this.merchant['addresses.Street Name'];
          this.merchantUnitNumber = this.merchant['addresses.Unit'];
          this.merchantStreetType = this.merchant['addresses.Street Type'];
          this.merchantLatitude = this.merchant['Address.Latitude'];
          this.merchantLongitude = this.merchant['Address.Longitude'];

          this.profileDescription = this.merchant['Description'];
          this.getPicture();
          this.getTradingHours();
        }
      });
    }
  }

  getPicture() {
    this.profileLink = null;

    if (this.merchantID) {
      this.profileLink = this.merchantService.getPracticePicStreamLink(this.merchantID);
    }
  }

  onCompleteAllPractice(fileID) {
    if (fileID && fileID[0]) {
      const payload = {
        fileID: fileID[0],
      };
      this.profileLink = null;
      this.merchantService.uploadPracticePic(this.merchant['ID'], payload).subscribe((res) => {
        this.getPicture();
      });
    }
  }

  goBack() {
    this.location.back();
  }

  editPractice() {
    const payload = {
      tradingAs: this.merchant['TradingAs'],
      abn: this.merchant['ABN'] || 'void',
      acn: this.merchant['ACN'] || 'void',

      email: this.merchantEmail || 'void',
      mobile: this.merchantMobile || 'void',
      workPhone: this.merchantPhone || 'void',

      streetName: this.merchantStreetName,
      streetNumber: this.merchantStreetNumber,
      streetType: this.merchantStreetType,
      unitNumber: this.merchantUnitNumber,
      suburb: this.merchantSuburb,
      state: this.merchantState,
      postCode: this.merchantPostCode,
      latitude: this.merchantLatitude,
      longitude: this.merchantLongitude,
      replyToEmail: this.merchantReplyToEmail || 'void',
      thirdPartyBooking_URL: this.merchant['ThirdPartyBooking_URL'] || 'void',

      facebook: this.merchant['Facebook'] || 'void',
      instagram: this.merchant['Instagram'] || 'void',
      twitter: this.merchant['Twitter'] || 'void',
      linkedin: this.merchant['LinkedIn'] || 'void',

      pinterest: this.merchant['Pinterest'] || 'void',
      tumblr: this.merchant['Tumblr'] || 'void',
      vimeo: this.merchant['Vimeo'] || 'void',
      url: this.merchant['URL'] || 'void',

      description: this.profileDescription || 'void',
    };

    if (!this.profileDescription || this.profileDescription == null) {
      payload.description = 'void';
    }

    this.merchantService.editMerchant(this.merchantID, payload).subscribe((res) => {
      if (res) {
        this.setup();
        NotifyAppComponent.displayToast(
          'success',
          'Update Practice Profile',
          `You have successfully updated ${this.merchant['TradingAs']}'s profile.`
        );
      }
    });
  }

  getMobile(m) {
    this.merchantMobile = m;
  }

  getPhone(p) {
    this.merchantPhone = p;
  }

  getEmail(e) {
    this.merchantEmail = e;
  }

  getReplyToEmail(e) {
    this.merchantReplyToEmail = e;
  }

  validateMobileEvent(v) {
    this.validateMobile = v;
  }

  validatePhoneEvent(v) {
    this.validatePhone = v;
  }

  validateEmailEvent(v) {
    this.validateEmail = v;
  }

  validateReplyToEmailEvent(v) {
    this.validateReplyToEmail = v;
  }

  isAdressValidEvent(v) {
    this.isAdressValid = v;
  }

  getAdress(adr) {
    if (adr) {
      this.merchantSuburb = adr.suburb;
      this.merchantState = adr.state;
      this.merchantPostCode = adr.postCode;
      this.merchantStreetNumber = adr.streetNumber;
      this.merchantStreetName = adr.streetName;
      this.merchantUnitNumber = adr.unitNumber;
      this.merchantStreetType = adr.streetType;
      this.merchantLatitude = adr.latitude;
      this.merchantLongitude = adr.longitude;
    }
  }

  getProfileContent(c) {
    this.profileDescription = c;
  }

  displayDentistList() {
    this.dentistID = null;
  }

  uploadDocument() {
    const ref = RootAppComponent.dialog.open(DocumentCreateComponent, {
      data: {
        cardID: this.merchantID,
      },
      width: '650px',
    });

    ref.componentInstance.create.subscribe((res) => {
      if (res) {
        ref.close();
      }
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  setupTradingHours() {
    const ref = RootAppComponent.dialog.open(MerchantTradingHoursOverviewComponent, {
      data: {
        merchantID: this.merchantID,
        isSetup: this.isCreateTradingTime,
      },
      width: '800px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
        this.getTradingHours();
      }
    });
  }

  getTradingHours() {
    const payload = {};
    if (this.merchantID && this.isPromoterOrAdmin == true) {
      payload['merchantID'] = this.merchantID;
    }

    this.merchantService.getTradingHourList(payload, this.isPromoterOrAdmin).subscribe((res2) => {
      if (res2.length == 0 || res2.length < 7) {
        this.isCreateTradingTime = true;
      } else {
        this.merchantTimezone = res2[0]['TimeZone'];

        const order = { Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6, Sunday: 7 };

        this.tradingHours = res2.sort(function (a, b) {
          return order[a['DayOfWeek.Label.Singular']] - order[b['DayOfWeek.Label.Singular']];
        });
      }
    });
  }

  openAddServiceProfilePropertyDialog() {
    const addServiceProfileDialogRef = RootAppComponent.dialog.open(AddServiceProfileDialogComponent, {
      data: { merchantID: this.merchant['ID'] },
      width: '500px',
    });

    addServiceProfileDialogRef.componentInstance.addServiceProfile.subscribe((changes) => {
      const servicesObservables = [];

      changes.newServices.forEach((newService) => {
        servicesObservables.push(this.merchantService.createMerchantProfileServicesItems(newService));
      });

      changes.removedServices.forEach((removedService) => {
        servicesObservables.push(this.merchantService.deleteMerchantProfileServicesItems(removedService));
      });

      forkJoin(servicesObservables).subscribe((data) => {
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Services Provided successfully updated.');

        addServiceProfileDialogRef.close();

        this.merchantService
          .getMerchantProfileServicesItemsList({ Merchant_key: this.merchant['ID'] })
          .subscribe((data) => {
            this.merchantProfileServices = data.sort((a, b) =>
              a['Service_Group_Item.Label'] > b['Service_Group_Item.Label'] ? 1 : -1
            );
          });
      });
    });
  }

  openAddHealthFundProfileProperty() {
    const addHealthFundProfileDialogRef = RootAppComponent.dialog.open(AddHealthFundProfileDialogComponent, {
      data: { merchantID: this.merchant['ID'] },
      width: '500px',
    });

    addHealthFundProfileDialogRef.componentInstance.addHealthFundProfile.subscribe((changes) => {
      const healthFundObservables = [];

      changes.newHealthFunds.forEach((newHealthFund) => {
        healthFundObservables.push(this.merchantService.createMerchantProfileHealthFund(newHealthFund));
      });

      changes.removedHealthFunds.forEach((removedHealthFund) => {
        healthFundObservables.push(this.merchantService.deleteMerchantProfileHealthFund(removedHealthFund));
      });

      forkJoin(healthFundObservables).subscribe((data) => {
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Health Funds successfully updated.');

        addHealthFundProfileDialogRef.close();

        this.merchantService
          .getMerchantProfileHealthFundsList({ Merchant_key: this.merchant['ID'] })
          .subscribe((data) => {
            this.merchantProfileHealthFunds = data.sort((a, b) =>
              a['HealthFund.Label'] > b['HealthFund.Label'] ? 1 : -1
            );
          });
      });
    });
  }

  openAddDentistryTypesProperty() {
    const addDentistryTypesProfileDialogRef = RootAppComponent.dialog.open(AddDentistryTypesProfileDialogComponent, {
      data: { merchantID: this.merchant['ID'] },
      width: '500px',
    });

    addDentistryTypesProfileDialogRef.componentInstance.addDentistryTypesProfile.subscribe((changes) => {
      const dentistryTypesObservables = [];

      changes.newDentistryTypes.forEach((newDentistryType) => {
        dentistryTypesObservables.push(this.merchantService.createMerchantProfileDentistryType(newDentistryType));
      });

      changes.removedDentistryTypes.forEach((removedDentistryType) => {
        dentistryTypesObservables.push(this.merchantService.deleteMerchantProfileDentistryType(removedDentistryType));
      });

      forkJoin(dentistryTypesObservables).subscribe((data) => {
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Dentistry Type successfully updated.');

        addDentistryTypesProfileDialogRef.close();

        this.merchantService
          .getMerchantProfileDentistryTypesList({ Merchant_key: this.merchant['ID'] })
          .subscribe((data) => {
            this.merchantProfileDentistryTypes = data.sort((a, b) => (a.Label > b.Label ? 1 : -1));
          });
      });
    });
  }
}
