import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-treatment-plan-view',
  templateUrl: './treatment-plan-view.component.html',
  styleUrls: ['./treatment-plan-view.component.css'],
})
export class TreatmentPlanViewComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
