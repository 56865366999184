<div class="row clearfix">
  <div class="row clearfix mb searchArea primary-gradient-img">
    <div class="row clearfix half-width flex" id="search">
      <h3 class="pull-left white rm-m">Staff List<span class="badge" [endVal]="dentists.length" countUp></span></h3>
      <mat-form-field class="full-width clearfix" appearance="outline">
        <mat-label>Filter</mat-label>
        <input class="clearfix" [(ngModel)]="filter" name="filter" placeholder="Filter" matInput/>
      </mat-form-field>
    </div>
  </div>
</div>

<div class="row clearfix flex">
  <div class="thrd-width">
    <div class="row clearfix animate rel" *ngIf="selectedItem" @onChangeAnimation>
      <button class="close-arrow" (click)="closeSelectedItem()" mat-icon-button matTooltip="Close">
        <mat-icon>keyboard_backspace</mat-icon>
      </button>
      <app-dentist-view class="animate" [dentistID]="selectedItem['ID']"></app-dentist-view>
    </div>
    <div class="row clearfix">
      <mat-card
        class="card listCardView listCardSide"
        *ngIf="dentists && dentists.length > 0"
        [class.push]="!selectedItem"
      >
        <div class="row clearfix">
          <mat-list class="staffList">
            <mat-list-item
              *ngFor="
                let d of dentists
                  | FilterArrayMultipleContainValue: ['CalculatedName']:filter
                  | paginate: { itemsPerPage: 10, currentPage: p };
                let i = index
              "
              [class.highlightRow]="selectedItem && selectedItem.ID && selectedItem.ID == d['ID']"
              (click)="selectItem(d, i, p)"
            >
              <app-dentist-view-picture class="staffIcon text-center" *ngIf="d" [dentistID]="d['ID']">
              </app-dentist-view-picture>
              <p>{{ d.CalculatedName }}</p>
            </mat-list-item>
          </mat-list>
        </div>
        <div class="text-center">
          <mat-divider></mat-divider>
          <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
        </div>
      </mat-card>
    </div>
  </div>

  <div class="two-thrd-width flex">
    <app-map-multiple-view
      class="full-width flex flex-col"
      [displayHeaderBar]="false"
      [streetNameAttribute]="'addresses.Street Name'"
      [streetNumberAttribute]="'addresses.Street Nr'"
      [unitNumberAttribute]="'addresses.Unit'"
      [stateAttribute]="'addresses.State'"
      [suburbAttribute]="'addresses.Suburb'"
      [streetTypeAttribute]="'addresses.Street Type'"
      [countryAttribute]="'addresses.Country.Code'"
      [postCodeAttribute]="'addresses.Postcode'"
      [markerTitleAttribute]="'CalculatedName'"
      [markerDescriptionAttribute]="'addresses.Calculated'"
      [addMarker]="addMarkerEvent"
      [title]="title"
      [resetPositions]="resetPositionsEvent"
      [description]="description"
      [selectedItem]="selectedItemEvent"
      [isAction]="isMapAction"
      [actionIcon]="'format_list_bulleted'"
      [actionLabel]="'Staff Members List'"
      (addCoordinate)="addCoordinateEvent($event)"
      (getSelected)="getSelected($event)"
      (getAction)="getActionEvent($event)"
    ></app-map-multiple-view>
  </div>
</div>

<div class="full-width clearfix row" *ngIf="(!dentists || dentists.length <= 0) && isLoaded == true">
  <app-empty-list-message></app-empty-list-message>
</div>
