import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { combineLatest, EMPTY, of } from 'rxjs';
import { debounceTime, expand, map, switchMap, tap } from 'rxjs/operators';
import { AppStateService } from '../../../../../core/app-state/app-state.service';
import { ConfirmDialogComponent } from '../../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../../../shared/components/root-component/root-component.component';
import { STANDARD_KEYBOARD_DEBOUNCE_TIME } from '../../../../../shared/config/STANDARD_KEYBOARD_DEBOUNCE_TIME';
import { BinaryBoolPipe } from '../../../../../shared/pipes/binary-bool/binary-bool.pipe';
import { ConfirmDialog } from '../../../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../../../shared/types/utils/utils.class';
import { BeneficiaryViewComponent } from '../../../../beneficiary/beneficiary-view/beneficiary-view.component';
import { TreatmentService } from '../../../../treatment/shared/treatment.service';
import { TreatmentProduct, TreatmentProductQuery } from '../../../../treatment/shared/treatment.type';
import { treatmentUtil } from '../../../../treatment/shared/treatmentUtil';
import { TreatmentBrandViewComponent } from '../../../../treatment/treatment-brand-view/treatment-brand-view.component';
import { TreatmentCategoryViewComponent } from '../../../../treatment/treatment-category-view/treatment-category-view.component';
import { TreatmentDocumentCreateComponent } from '../../../../treatment/treatment-document-create/treatment-document-create.component';
import { TreatmentDocumentImportOverviewComponent } from '../../../../treatment/treatment-document-import-overview/treatment-document-import-overview.component';
import { TreatmentProductCreateEditComponent } from '../../../../treatment/treatment-product-create-edit/treatment-product-create-edit.component';
import { TreatmentProductEditMerchantComponent } from '../../../../treatment/treatment-product-edit-merchant/treatment-product-edit-merchant.component';
import { TreatmentProductViewComponent } from '../../../../treatment/treatment-product-view/treatment-product-view.component';
import { TreatmentTypeViewComponent } from '../../../../treatment/treatment-type-view/treatment-type-view.component';

@Component({
  selector: 'ipv-dental-product-list',
  templateUrl: './dental-product-list.component.html',
  styleUrls: ['./dental-product-list.component.css'],
  providers: [BinaryBoolPipe],
})
export class DentalProductListComponent {
  @Input()
  isActive;

  @Input()
  supplierID;

  @Input()
  merchantID;

  @Input()
  manufacturerID;

  @Input()
  distributorID;

  @Input()
  typeID;

  @Input()
  canMultiSelect = false;

  @Input()
  categoryID;

  @Input()
  brandID;

  @Input()
  miniDisplay = false;

  @Input()
  microDisplay = false;

  @Output()
  getLength = new EventEmitter();

  @Input()
  defaultHeading = true;

  @Output()
  displaySlideToggle = new EventEmitter();

  @Input()
  pageSize = 10;
  pageIndex = 0;

  isFetchingInitialData = true;

  treatmentUtil = new treatmentUtil();
  utils = new UtilsClass();

  showDocList = false;
  docProductID;
  docListTitle;

  titleIcon =
    'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/brandLogos/ADA-Fed-Logo.png';
  title = 'Treatment Products';

  hiddenColumns = [
    'ID',
    'fullLabel',
    'LastModified',
    'LastModified_Human',
    'DateTimeCreated',
    'Brand_key',
    'Brand.Label',
    'Type_key',
    'Type.Label',
    'Category_key',
    'Category.Label',
    'Price.Sell.Effective',
    'Price.Cost.Item.Effective',
    'IsOmitted',
    'Manufacturer_Key.Effective',
    'Manufacturer.Name.Effective',
    'Distributor_Key.Effective',
    'Distributor.Name.Effective',
    'Type.Label',
    'Category.Label',
    'Manufacturer_Key.Effective',
    'Distributor_Key.Effective',
    'Distributor.Name.Effective',
    'AssociatedDocuments.ProdAndInherited.SRV.All',
    'AssociatedDocuments.ProdAndInherited.SRVMKT.All',
    'AssociatedDocuments.ProdAndInherited.SRVPRE.All',
    'AssociatedDocuments.ProdAndInherited.SRVPST.All',
    'AssociatedDocuments.ProdAndInherited.SRVSPL.All',
    'AssociatedDocuments.ProdAndInherited.SRVSTC.All',
    'AssociatedDocuments.ProdAndInherited.SRV',
  ];

  renamedColumns = {
    'Item_Code.Effective': 'Item Code',
    Label: 'Product',
    NumberOfDocuments: 'Documents',
  };

  order = ['logo', 'Label', 'Item_Code.Effective', 'NumberOfDocuments', 'Active'];

  // This is to prevent refetch of logos
  checkedItemsWithImages: Partial<TreatmentProduct>[] = [];

  viewModel$ = this.appStateService.getAppState$().pipe(
    switchMap((appState) =>
      this.getFilters$().pipe(
        switchMap(([includeOmmitted, labelItemCode, categoryID, typeID, brandID]) =>
          this.fetchData$({
            payload: {
              includeOmmitted,
              categoryID,
              typeID,
              brandID,
            },
          }).pipe(
            switchMap(() => this.getData$({ labelItemCode })),
            switchMap(([treatmentProducts]) =>
              this.getViewModel$({
                appState,
                treatmentProducts,
                labelItemCode,
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private treatmentService: TreatmentService,
    private dialog: MatDialog,
    private appStateService: AppStateService,
    private binaryBoolPipe: BinaryBoolPipe
  ) {}

  getFilters$() {
    return combineLatest([
      this.getIsOmittedFilter$(),
      this.getLabelItemCodeFilter$(),
      this.getCategoryIdFilter$(),
      this.getTypeIdFilter$(),
      this.getBrandIdFilter$(),
    ]);
  }

  getIsOmittedFilter$() {
    return this.treatmentService
      .getIsOmittedFilter$()
      .pipe(map((isOmitted) => !this.binaryBoolPipe.transform(isOmitted)));
  }

  setIsOmittedFilter(isOmitted) {
    this.treatmentService.setIsOmittedFilter(isOmitted);
  }

  getLabelItemCodeFilter$() {
    return this.treatmentService.getLabelItemCodeFilter$().pipe(debounceTime(STANDARD_KEYBOARD_DEBOUNCE_TIME));
  }

  setLabelItemCodeFilter(labelItemCode: string) {
    this.treatmentService.setLabelItemCodeFilter(labelItemCode);
  }

  getCategoryIdFilter$() {
    return this.treatmentService.getCategoryIdFilter$();
  }

  setCategoryIdFilter(categoryIdFilter: string) {
    this.treatmentService.setCategoryIdFilter(categoryIdFilter);
  }

  getTypeIdFilter$() {
    return this.treatmentService.getTypeIdFilter$();
  }

  setTypeIdFilter(typeIdFilter: string) {
    this.treatmentService.setTypeIdFilter(typeIdFilter);
  }

  getBrandIdFilter$() {
    return this.treatmentService.getBrandIdFilter$();
  }

  setBrandIdFilter(typeIdFilter: string) {
    this.treatmentService.setBrandIdFilter(typeIdFilter);
  }

  fetchData$(parameters: TreatmentProductQuery) {
    return combineLatest([this.fetchTreatmentProductSections$(parameters)]);
  }

  fetchTreatmentProductSections$(parameters: TreatmentProductQuery) {
    this.treatmentService.setTreatmentProducts([]);

    const query: TreatmentProductQuery = {
      payload: Object.assign(
        {
          section: 0,
          supplierID: this.supplierID,
          merchantID: this.merchantID,
          manufacturerID: this.manufacturerID,
          distributorID: this.distributorID,
          isNullProduct: false,
          dateFrom: undefined,
          dateTo: undefined,
          fields:
            'ID,LastModified,Item_Code.Effective,DateTimeCreated,Brand_key,Brand.Label,Type_key,Type.Label,Category_key,Category.Label,Active,Label,Price.Sell.Effective,Price.Cost.Item.Effective' +
            ',IsOmitted,Manufacturer_Key.Effective,Manufacturer.Name.Effective,Distributor_Key.Effective,Distributor.Name.Effective,NumberOfDocuments,' +
            'AssociatedDocuments.ProdAndInherited.SRVPRE.All,AssociatedDocuments.ProdAndInherited.SRVPST.All,AssociatedDocuments.ProdAndInherited.SRVMKT.All,' +
            'AssociatedDocuments.ProdAndInherited.SRVSPL.All,AssociatedDocuments.ProdAndInherited.SRV.All,AssociatedDocuments.ProdAndInherited.SRVSTC.All',
          orderBy: 'Label',
        },
        parameters.payload
      ),
    };

    return this.treatmentService.fetchTreatmentProducts$<Partial<TreatmentProduct>>(query.payload).pipe(
      tap(() => {
        UtilsClass.stopLoadingInterceptor();
        query.payload.section++;
        UtilsClass.loadingDataSection(query.payload.section);
      }),
      expand((treatmentProductsSection) =>
        treatmentProductsSection.length > 0
          ? this.treatmentService.fetchTreatmentProducts$<Partial<TreatmentProduct>>(query.payload).pipe(
              tap(() => {
                UtilsClass.stopLoadingInterceptor();
              })
            )
          : EMPTY
      ),
      tap((treatmentProductsSection) => {
        if (treatmentProductsSection.length > 0) {
          query.payload.section++;
          UtilsClass.loadingDataSection(query.payload.section);
          const treatmentProducts = this.treatmentService.getTreatmentProducts();
          const newTreatmentProducts = treatmentProducts.concat(treatmentProductsSection);
          this.treatmentService.setTreatmentProducts(newTreatmentProducts);
        }
      })
    );
  }

  getData$(parameters: any) {
    const { labelItemCode } = parameters;
    return combineLatest([this.getTreatmentProduct$(labelItemCode)]);
  }

  getTreatmentProduct$(labelItemCode: string) {
    return this.treatmentService.getTreatmentProducts$<Partial<TreatmentProduct>>().pipe(
      map((treatmentProducts: TreatmentProduct[]) => {
        return treatmentProducts
          .map((treatmentProduct) => {
            return {
              logo: '',
              fullLabel: treatmentProduct.Label + ' ' + treatmentProduct['Item_Code.Effective'],
              ...treatmentProduct,
            };
          })
          .filter((treatmentProduct) => treatmentProduct.fullLabel.includes(labelItemCode));
      })
    );
  }

  getViewModel$(parameters: any) {
    const { appState, treatmentProducts, labelItemCode } = parameters;
    this.isFetchingInitialData = false;
    const { user, practice, sessionType, isPromoterOrAdmin, dentist } = appState;

    const result = {
      user,
      practice,
      sessionType,
      isPromoterOrAdmin,
      dentist,
      treatmentProducts,
      labelItemCode,
    };
    this.getLength.emit(treatmentProducts.length);
    return of(result);
  }

  updateIsChecked(treatmentProduct: Partial<TreatmentProduct>[]) {
    this.checkedItemsWithImages = treatmentProduct;
  }

  selectAll(treatmentProducts: Partial<TreatmentProduct>[]) {
    const allSelectedTreatmentProducts = treatmentProducts.map((treatmentProduct) => ({
      ...treatmentProduct,
      isChecked: true,
    }));
    this.checkedItemsWithImages = allSelectedTreatmentProducts;
    this.treatmentService.setTreatmentProducts(allSelectedTreatmentProducts);
  }

  unSelectAll(treatmentProducts: Partial<TreatmentProduct>[]) {
    const allSelectedTreatmentProducts = treatmentProducts.map((treatmentProduct) => ({
      ...treatmentProduct,
      isChecked: false,
    }));
    this.checkedItemsWithImages = allSelectedTreatmentProducts;
    this.treatmentService.setTreatmentProducts(allSelectedTreatmentProducts);
  }

  selectPage(treatmentProducts: Partial<TreatmentProduct>[]) {
    const pageIndex = this.pageIndex + 1;
    const pageSize = this.pageSize;
    const itemsAfterFirstItem = pageSize - 1;
    const lastItemIndex = pageIndex * pageSize - 1;
    const firstItemIndex = lastItemIndex - itemsAfterFirstItem;

    for (let index = firstItemIndex; index < lastItemIndex + 1; index++) {
      if (treatmentProducts[index]) {
        treatmentProducts[index].isChecked = true;
      }
    }
    this.checkedItemsWithImages = treatmentProducts;
    this.treatmentService.setTreatmentProducts(treatmentProducts);
  }

  enableGroup(merchantID: string, isPromoterOrAdmin: boolean) {
    const selectedIDs = this.checkedItemsWithImages.map((treatmentProduct) => treatmentProduct.ID);
    const confirmDialog = new ConfirmDialog(
      'cancel',
      'Enable Selected Products',
      '<p>Once the Product is enabled it will not appear as an option for the user.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val === true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            isActive: true,
            merchantID,
            ids: selectedIDs.join(','),
          };
          this.treatmentService
            .editTreatmentProductGroup(payload, isPromoterOrAdmin)
            .subscribe((enabledTreatmentProducts) => {
              enabledTreatmentProducts.forEach((enabledTreatmentProduct) => {
                const index = this.checkedItemsWithImages.findIndex(
                  (treatmentProduct) => treatmentProduct.ID === enabledTreatmentProduct.ID
                );
                this.checkedItemsWithImages[index] = enabledTreatmentProduct;
              });

              this.treatmentService.setTreatmentProducts(this.checkedItemsWithImages);

              this.unSelectAll(this.checkedItemsWithImages);

              NotifyAppComponent.displayToast('success', 'Success!', 'Products are enabled.');
            });
        });
      } else {
        ref.close();
      }
    });
  }

  disableGroup(isPromoterOrAdmin: boolean) {
    const selectedIDs = this.checkedItemsWithImages.map((treatmentProduct) => treatmentProduct.ID);

    const confirmDialog = new ConfirmDialog(
      'cancel',
      'Disable Selected Products',
      '<p>Once the Product is disabled it will not appear as an option for the user.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val === true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            isActive: false,
            merchantID: this.merchantID,
            ids: selectedIDs.join(','),
          };
          this.treatmentService
            .editTreatmentProductGroup(payload, isPromoterOrAdmin)
            .subscribe((disabledTreatmentProducts) => {
              if (res && res.length > 0) {
                disabledTreatmentProducts.forEach((disabledTreatmentProduct) => {
                  const index = this.checkedItemsWithImages.findIndex(
                    (treatmentProduct) => treatmentProduct.ID === disabledTreatmentProduct.ID
                  );
                  this.checkedItemsWithImages[index] = disabledTreatmentProduct;
                });

                this.treatmentService.setTreatmentProducts(this.checkedItemsWithImages);
                this.unSelectAll(this.checkedItemsWithImages);

                NotifyAppComponent.displayToast('success', 'Success!', 'Products are disabled.');
              }
            });
        });
      } else {
        ref.close();
      }
    });
  }

  addDocumentGroup() {
    const selectedIDs = this.checkedItemsWithImages.map((treatmentProduct) => treatmentProduct.ID);

    const confirmDialog = new ConfirmDialog(
      'cancel',
      'Add New Document',
      '<p>You are about to create a document for multiple treatment products</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val === true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const ref2 = this.dialog.open(TreatmentDocumentCreateComponent, {
            width: '750px',
            data: {
              multiCreate: true,
            },
          });

          ref2.componentInstance.getDocument.subscribe((payload) => {
            if (payload) {
              payload.ids = selectedIDs.join(',');

              this.treatmentService.addDocumentTreatmentProductGroupGlobal(payload).subscribe((responses) => {
                responses.forEach((response) => {
                  const index = this.checkedItemsWithImages.findIndex(
                    (treatmentProduct) => treatmentProduct.ID === response.ID
                  );
                  this.checkedItemsWithImages[index] = response;
                });

                this.treatmentService.setTreatmentProducts(this.checkedItemsWithImages);
                ref2.close();
                this.unSelectAll(this.checkedItemsWithImages);

                NotifyAppComponent.displayToast('success', 'Success!', 'Documents are added.');
              });
            }
          });

          ref2.componentInstance.close.subscribe(() => {
            ref2.close();
          });
        });
      } else {
        ref.close();
      }
    });
  }

  importDocumentGroup() {
    const selectedIDs = this.checkedItemsWithImages.map((treatmentProduct) => treatmentProduct.ID);
    const ref = RootAppComponent.dialog.open(TreatmentDocumentImportOverviewComponent, {
      data: {
        readonly: false,
        displayUserDocuments: false,
        loadUserDocument: false,
        selectedView: 'system',
        displayUserDocumentType: false,
        displayICDocumentType: true,
        isICSideMenu: true,
        isUserSideMenu: false,
      },
      width: '90vw'
    });

    ref.componentInstance.getDocuments.subscribe((res) => {
      if (res && res.length > 0) {
        this.treatmentService.importDocumentTreatmentProductGroup(selectedIDs, res).subscribe((res2) => {
          ref.close();
        });
      }
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  enable(productID, treatmentProducts: Partial<TreatmentProduct>[], isPromoterOrAdmin: boolean) {
    if (productID) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to enable this treatment product  ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          const payload = {
            isActive: true,
            merchantID: this.merchantID,
          };

          this.treatmentService
            .editTreatmentProduct(productID, payload, isPromoterOrAdmin)
            .subscribe((enabledTreatmentProduct) => {
              if (enabledTreatmentProduct) {
                const index = treatmentProducts.findIndex(
                  (treatmentProduct) => treatmentProduct.ID === enabledTreatmentProduct.ID
                );
                treatmentProducts[index] = enabledTreatmentProduct;

                this.treatmentService.setTreatmentProducts(treatmentProducts);

                NotifyAppComponent.displayToast('success', 'Successful operation', 'The Treatment product is enabled');
                ref.close();
              }
            });
        }
      });
    }
  }

  goToGuide() {
    const newWindow = window.open(
      'https://email-graphics-smileright.s3-ap-southeast-2.amazonaws.com/docs-19/Australian_Schedule-2.pdf',
      '_blank'
    );
  }

  goToShop() {
    const newWindow = window.open(
      'https://www.ada.org.au/Utility-Pages/Login?returnurl=/Utility-Pages/SSO?ssoreturnurl=https://ada.storehippo.com/',
      '_blank'
    );
  }

  disable(productID, isPromoterOrAdmin: boolean, treatmentProducts: Partial<TreatmentProduct>[]) {
    if (productID) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to disable this treatment product  ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          const payload = {
            isActive: false,
            merchantID: this.merchantID,
          };

          this.treatmentService.editTreatmentProduct(productID, payload, isPromoterOrAdmin).subscribe((res) => {
            if (res) {
              NotifyAppComponent.displayToast('success', 'Successful operation', 'The Treatment product is disabled');
              ref.close();

              res.forEach((disabledTreatmentProduct) => {
                const index = treatmentProducts.findIndex(
                  (treatmentProduct) => treatmentProduct.ID === disabledTreatmentProduct.ID
                );
                treatmentProducts[index] = disabledTreatmentProduct;
              });

              this.treatmentService.setTreatmentProducts(treatmentProducts);
            }
          });
        }
      });
    }
  }

  viewType(id) {
    const ref = RootAppComponent.dialog.open(TreatmentTypeViewComponent, {
      data: {
        typeID: id,
        isGlobal: true,
      },

      width: '700px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewCategory(id) {
    const ref = RootAppComponent.dialog.open(TreatmentCategoryViewComponent, {
      data: {
        categoryID: id,
      },

      width: '700px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewBrand(id) {
    const ref = this.dialog.open(TreatmentBrandViewComponent, {
      data: {
        brandID: id,
        merchantID: this.merchantID,
        isGlobal: false,
      },

      width: '800px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewTreatment(id) {
    const ref = this.dialog.open(TreatmentProductViewComponent, {
      data: {
        productID: id,
        merchantID: this.merchantID,
        isGlobal: false,
      },

      width: '800px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  editTreatment(id, treatmentProducts: Partial<TreatmentProduct>[]) {
    const ref = this.dialog.open(TreatmentProductEditMerchantComponent, {
      data: {
        productID: id,
        merchantID: this.merchantID,
        isGlobal: false,
      },
      width: '750px',
      panelClass: 'noCard',
    });

    ref.componentInstance.saveObject.subscribe((res) => {
      if (res && res['ID']) {
        const index = treatmentProducts.findIndex((item) => item.ID === id);
        treatmentProducts[index] = res;
        this.treatmentService.setTreatmentProducts(treatmentProducts);
        ref.close();
      } else {
        treatmentProducts = treatmentProducts.filter((item) => item.ID !== id);
        this.treatmentService.setTreatmentProducts(treatmentProducts);
        ref.close();
      }
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewSupplier(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(BeneficiaryViewComponent, {
        data: id,
        width: '600px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewDocuments(id, label) {
    this.showDocList = true;
    this.docProductID = id;
    this.docListTitle = 'Product Documents for: ' + label;
  }

  hideViewDetails() {
    this.showDocList = false;
  }

  add(treatmentProducts: Partial<TreatmentProduct>[]) {
    const ref = this.dialog.open(TreatmentProductCreateEditComponent, {
      width: '950px',
      panelClass: 'noCard',
      data: { isModal: true },
    });

    ref.componentInstance.result.subscribe((res) => {
      treatmentProducts.unshift(res);
      this.treatmentService.setTreatmentProducts(treatmentProducts);
      ref.close();
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  addDocument(productID) {
    const ref = this.dialog.open(TreatmentDocumentCreateComponent, {
      width: '750px',
      data: {
        productID,
      },
    });

    ref.componentInstance.create.subscribe((res) => {
      if (res) {
        ref.close();
      }
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  importDocument(productID) {
    const ref = RootAppComponent.dialog.open(TreatmentDocumentImportOverviewComponent, {
      data: {
        readonly: false,
        displayUserDocuments: false,
        loadUserDocument: false,
        selectedView: 'system',
        displayUserDocumentType: false,
        displayICDocumentType: true,
        isICSideMenu: true,
        isUserSideMenu: false,
      },
      width: '90vw'
    });

    ref.componentInstance.getDocuments.subscribe((res) => {
      if (res && res.length > 0) {
        this.treatmentService.importDocumentTreatmentProduct(productID, res).subscribe((res2) => {
          ref.close();
        });
      }
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  countSelectedItems(treatmentProducts: Partial<TreatmentProduct>[]) {
    return treatmentProducts.filter((treatmentProduct) => treatmentProduct.isChecked).length;
  }

  setPage(pageEvent: PageEvent) {
    this.pageIndex = pageEvent.pageIndex;
    this.pageSize = pageEvent.pageSize;
  }
}
