/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./fetching-list-message.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./fetching-list-message.component";
var styles_FetchingListMessageComponent = [i0.styles];
var RenderType_FetchingListMessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FetchingListMessageComponent, data: { "animation": [{ type: 7, name: "ngIfAnimation", definitions: [{ type: 1, expr: "void => *", animation: [{ type: 10, animation: { type: 8, animation: [{ type: 11, selector: ".row", animation: { type: 6, styles: { opacity: 1 }, offset: null }, options: { optional: true } }, { type: 11, selector: ".row", animation: { type: 12, timings: "100ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 1, transform: "translateY(0)", offset: 0, height: "*" }, offset: null }, { type: 6, styles: { opacity: 0, transform: "translateY(-75%)", offset: 1, height: 0 }, offset: null }] }, timings: "{{ timings }} ease-in" }] }, options: { optional: true } }], options: { params: { timings: "0.5s" } } }, options: { params: { timings: "0.8s" } } }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 10, animation: { type: 8, animation: [{ type: 11, selector: ".row", animation: { type: 6, styles: { opacity: 0 }, offset: null }, options: { optional: true } }, { type: 11, selector: ".row", animation: { type: 12, timings: "100ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 0, transform: "translateY(-75%)", offset: 0, height: 0 }, offset: null }, { type: 6, styles: { opacity: 1, transform: "translateY(0)", offset: 1, height: "*" }, offset: null }] }, timings: "{{ timings }} ease-out" }] }, options: { optional: true } }], options: { params: { timings: "0.5s" } } }, options: { params: { timings: "0.8s" } } }], options: null }], options: {} }] } });
export { RenderType_FetchingListMessageComponent as RenderType_FetchingListMessageComponent };
export function View_FetchingListMessageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "rel"]], [[24, "@ngIfAnimation", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "row clearfix padded rel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "dataHeader rm-mt transparent"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Fetching data..."]))], null, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
export function View_FetchingListMessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-fetching-list-message", [], null, null, null, View_FetchingListMessageComponent_0, RenderType_FetchingListMessageComponent)), i1.ɵdid(1, 114688, null, 0, i2.FetchingListMessageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FetchingListMessageComponentNgFactory = i1.ɵccf("ipv-fetching-list-message", i2.FetchingListMessageComponent, View_FetchingListMessageComponent_Host_0, {}, {}, []);
export { FetchingListMessageComponentNgFactory as FetchingListMessageComponentNgFactory };
