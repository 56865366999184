<mat-card class="rel supplier-card brandView clearfix mb" *ngIf="template" @ngIfAnimation>
  <div class="card-header primary-gradient-img clearfix inModal rel">
    <div class="row clearfix content">
      <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModal()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m">
          {{ template['Label'] }}
        </h2>

        <div class="date-chip-area">
          <p class="small rm-m mr" *ngIf="template.DateTimeCreated && template.DateTimeCreated != '0000-00-00'">
            <mat-icon class="fas fa-clock"></mat-icon>
            {{ template.DateTimeCreated | TimeLabelPast }} ago
          </p>
        </div>
      </div>
    </div>
  </div>

  <mat-dialog-content class="stacked-card-view">
    <div @ngIfAnimation>
      <div class="create-container full-width clearfix">
        <h3 class="sr-title">Check Social Media Posts</h3>
        <p>select your desired social media platform to post to using the buttons below.</p>

        <div class="row clearfix flex mb">
          <div
            class="block full-width icon-social-media mat-elevation-z2 text-center"
            [ngClass]="postType == 'facebook' ? 'selected-social-media' : ''"
            (click)="selectPostType('facebook')"
            matTooltip="Post for Facebook"
          >
            <mat-icon class="fab fa-facebook big-icon"></mat-icon>
            <p class="rm-mb">Facebook</p>
          </div>

          <div
            class="block full-width icon-social-media mat-elevation-z2 text-center"
            [ngClass]="postType == 'instagram' ? 'selected-social-media' : ''"
            (click)="selectPostType('instagram')"
            matTooltip="Post for Instagram"
          >
            <mat-icon class="fab fa-instagram big-icon"></mat-icon>
            <p class="rm-mb">Instagram</p>
          </div>

          <div
            class="block full-width icon-social-media mat-elevation-z2 text-center"
            [ngClass]="postType == 'twitter' ? 'selected-social-media' : ''"
            (click)="selectPostType('twitter')"
            matTooltip="Post for Twitter"
          >
            <mat-icon class="fab fa-twitter big-icon"></mat-icon>
            <p class="rm-mb">Twitter</p>
          </div>

          <div
            class="block full-width icon-social-media mat-elevation-z2 text-center"
            [ngClass]="postType == 'linkedin' ? 'selected-social-media' : ''"
            (click)="selectPostType('linkedin')"
            matTooltip="Post for Linkedin"
          >
            <mat-icon class="fab fa-linkedin big-icon"></mat-icon>
            <p class="rm-mb">LinkedIn</p>
          </div>
        </div>

        <div *ngIf="postType">
          <div class="row clearfix">
            <mat-form-field class="full-width animate" appearance="outline">
              <mat-label>Post Text</mat-label>

              <textarea
                class="mat-block"
                [(ngModel)]="postText"
                [maxLength]="maxLength"
                matInput
                placeholder="Post Text"
                name="postText"
              ></textarea>
            </mat-form-field>

            <app-blob-view
              class="row clearfix flex flexCol full-width full-height"
              *ngIf="postPicture"
              [label]="'Campaign'"
              [loading]="loadingPost"
              [isDownload]="true"
              [isProceed]="false"
              [isPrint]="false"
              [canProceed]="true"
              [content]="postPicture"
              [extension]="'png'"
              [label]=""
            >
            </app-blob-view>
          </div>
          <div class="view-container full-height" *ngIf="(!postPicture || postPicture == null) && loadingPost != true">
            <div class="row clearfix">
              <pinch-zoom
                class="full-height clearfix full-width mb pinchZoom"
                [limit-zoom]="25"
                [wheel]="true"
                [wheelZoomFactor]="0.5"
                [double-tap]="true"
                [properties]="pinchZoomProperties"
              >
                <img
                  [src]="
                    'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/marketing-place-holder.png'
                  "
                />
              </pinch-zoom>
            </div>

            <div class="row clearfix">
              <button
                class="pull-right"
                (click)="
                  download(
                    'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/marketing-place-holder.png',
                    'template.png'
                  )
                "
                mat-raised-button
                color="accent"
                matTooltip="Download "
              >
                Download
                <mat-icon class="fas fa-download"></mat-icon>
              </button>
            </div>
          </div>

          <hr/>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</mat-card>
