<mat-card class="rel create-container supplier-card brandView clearfix mb" id="subscription-plan" @ngIfAnimation>
  <div
    class="card-header primary-gradient-img clearfix inModal rel"
    @ngIfAnimation
    [attr.style]="
      'background:linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
    "
    [attr.style]="
      'background:-moz-linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
    "
    [attr.style]="
      'background:-webkit-linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
    "
  >
    <div class="row clearfix content">
      <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModal()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m" *ngIf="mainFeatureLabelID">
          Edit Main Feature
          <span class="subLabel">Insert and customize Feature details </span>
        </h2>
        <h2 class="summary-header rm-m" *ngIf="!mainFeatureLabelID">
          Create Main Feature
          <span class="subLabel">Insert and customize Feature details </span>
        </h2>
      </div>
    </div>
  </div>
  <div class="full-width" @ngIfAnimation>
    <aw-wizard
      class="template-subscription-plan-create"
      #wizard
      [disableNavigationBar]="true"
      navBarLayout="large-filled-symbols"
    >
      <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Define Feature" awOptionalStep>
        <div class="full-width clearfix mt">
          <form #resetform="ngForm">
            <div class="full-width clearfix flex row mb">
              <mat-card class="full-width row mr animate mat-elevation-z4">
                <h3 class="sr-title" [attr.style]="'color:' + color + ' !important' | safeStyle">
                  Main Feature details
                </h3>
                <div class="full-width flex">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Label</mat-label>
                    <input
                      [(ngModel)]="label"
                      (change)="changeLabel()"
                      name="label"
                      matInput
                      placeholder="Label"
                      required
                    />
                  </mat-form-field>
                </div>
                <div class="full-width clearfix flex">
                  <mat-form-field class="full-width animate" appearance="outline">
                    <mat-label>Short Description</mat-label>
                    <textarea
                      class="mat-block main-textarea"
                      [(ngModel)]="description"
                      matInput
                      name="description"
                      placeholder="Description"
                    >
                    </textarea>
                  </mat-form-field>
                </div>

                <div class="full-width">
                  <mat-form-field
                    class="full-width"
                    [ngClass]="isUnique != true ? 'error-input-manual' : ''"
                    appearance="outline"
                  >
                    <mat-label>Static Code</mat-label>
                    <input
                      [(ngModel)]="code"
                      (change)="isUniqueEvent()"
                      name="code"
                      matInput
                      placeholder="Static  Code"
                      required
                    />
                  </mat-form-field>
                  <mat-error class="full-width text-right" *ngIf="isUnique != true">
                    <mat-icon class="fas fa-exclamation-circle smr"></mat-icon>Static Code must be unique
                  </mat-error>
                </div>
                <h3 class="sr-title" [attr.style]="'color:' + color + ' !important' | safeStyle">
                  Main Feature customization
                </h3>
                <div class="full-width flex">
                  <mat-checkbox
                    class="full-width slider-input-select ml mr"
                    *ngIf="mainFeatureLabelID"
                    [(ngModel)]="isActive"
                    name="isFreeSMS"
                  >
                    Is this feature Active?
                  </mat-checkbox>
                  <mat-checkbox
                    class="slider-width pull-left slider-input-select mr"
                    [(ngModel)]="useDefaultColor"
                    (change)="changeDefaultColor()"
                    name="useDefaultColor"
                  >
                    Use Theme Colors
                  </mat-checkbox>
                  <input
                    class="color-input-sub qtr-width"
                    *ngIf="useDefaultColor != true"
                    [(colorPicker)]="_color"
                    [style.background]="color"
                    [cpOutputFormat]="'hex'"
                    (colorPickerClose)="onColorPickerClose($event)"
                    name="contactColor"
                  />
                  <input
                    class="color-input-sub qtr-width ml"
                    *ngIf="useDefaultColor != true"
                    [(colorPicker)]="_colorSecond"
                    [style.background]="colorSecond"
                    [cpOutputFormat]="'hex'"
                    (colorPickerClose)="onColorPickerClose2($event)"
                    name="contactColorSecond"
                  />
                </div>
                <h3 class="sr-title mt" [attr.style]="'color:' + color + ' !important' | safeStyle">Accessibility</h3>
                <div class="full-width flex">
                  <mat-checkbox
                    class="full-width pull-left slider-input-select ml mr"
                    [(ngModel)]="isPublic"
                    name="isPublic"
                  >
                    is Public?
                  </mat-checkbox>
                  <mat-checkbox
                    class="full-width pull-left slider-input-select ml mr"
                    [(ngModel)]="isSubPlanFeature"
                    name="isSubPlanFeature"
                  >
                    is Sub-Plan Feature?
                  </mat-checkbox>

                  <mat-checkbox
                    class="full-width pull-left slider-input-select mr"
                    [(ngModel)]="isMerchant"
                    name="isMerchant"
                  >
                    is  {{ 'KEYWORD.practice' | translate }} Feature?
                  </mat-checkbox>
                  <mat-checkbox
                    class="full-width pull-left slider-input-select"
                    [(ngModel)]="isConsumer"
                    name="isConsumer"
                  >
                    is Consumer Feature?
                  </mat-checkbox>
                </div>
                <h3 class="sr-title mt" [attr.style]="'color:' + color + ' !important' | safeStyle">Side Menu</h3>
                <div class="full-width flex">
                  <mat-checkbox class="full-width slider-input-select ml mr" [(ngModel)]="isSideMenu" name="isSideMenu">
                    Add to side menu?
                  </mat-checkbox>
                  <mat-form-field class="full-width mr" *ngIf="isSideMenu == true" appearance="outline">
                    <mat-label>Side Menu Label</mat-label>
                    <input
                      [(ngModel)]="sideMenuLabel"
                      name="sideMenuLabel"
                      matInput
                      placeholder="Side Menu Label"
                      required
                    />
                  </mat-form-field>
                  <mat-form-field class="full-width" *ngIf="isSideMenu == true" appearance="outline">
                    <mat-label>Side Menu Icon</mat-label>
                    <input [(ngModel)]="icon" name="icon" matInput placeholder="Side Menu Icon" required/>
                    <span matSuffix>
                      <mat-icon class="{{ icon }}" [attr.style]="'color:#808080 !important' | safeStyle"> </mat-icon>
                    </span>
                  </mat-form-field>
                </div>
                <p class="small" *ngIf="isSideMenu == true">
                  * You can get the icons from the <a href="https://fontawesome.com/v5/search" target="_blank">Link</a>,
                  e.g. <strong>fas fa-user</strong>
                </p>
              </mat-card>
              <div class="qtr-width row blob-for-main-feature-container-thumbnail ml mr">
                <div class="full-width">
                  <app-ep-document-view-image
                    class="squareImg squareImg-social"
                    *ngIf="!postPictureLinkThumbnail && postPictureThumbnail"
                    [hideZoomButton]="false"
                    [defaultPicture]="
                      'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/sheild_icon.png)'
                    "
                    [link]="postPictureThumbnail"
                  >
                  </app-ep-document-view-image>
                  <app-ep-document-view-image
                    class="squareImg squareImg-social"
                    *ngIf="postPictureLinkThumbnail && !postPictureThumbnail"
                    [hideZoomButton]="false"
                    [defaultPicture]="
                      'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/sheild_icon.png)'
                    "
                    [link]="postPictureLinkThumbnail"
                  >
                  </app-ep-document-view-image>
                  <app-ep-document-view-image
                    class="squareImg squareImg-social"
                    *ngIf="!postPictureLinkThumbnail && !postPictureThumbnail"
                    [hideZoomButton]="false"
                    [defaultPicture]="
                      'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/sheild_icon.png)'
                    "
                  >
                  </app-ep-document-view-image>
                </div>
                <div class="clearfix full-width">
                  <hr/>
                  <app-file-uploader
                    class="text-center clearfix full-width"
                    [aspectRatioType]="'full'"
                    [allowedExtensions]="allowExtensions"
                    [isTwoThirdWidth]="false"
                    [maxFileSize]="2"
                    [isImageCropping]="true"
                    [canSkip]="true"
                    [limit]="1"
                    [uploadDirectly]="true"
                    [isDragable]="true"
                    [title]="'CLICK HERE to upload (Max size: ' + 2 + 'MB)'"
                    (onCompleteAll)="onCompleteThumbnail($event)"
                  >
                  </app-file-uploader>
                </div>
              </div>
            </div>
            <div class="full-width row mr">
              <div class="clearfix full-width mb">
                <h3 class="sr-title mt" [attr.style]="'color:' + color + ' !important' | safeStyle">
                  Cover Image
                </h3>
                <p>
                  Recommended dimension is <strong>1000x250 px</strong> (only images are allowed). Max image size is
                  {{ maxFileSize }} MB
                </p>
                <div class="full-width mb blob-for-main-feature-container">
                  <app-blob-view
                    class="row clearfix flex flexCol blob-for-social-media full-width full-height"
                    *ngIf="(!postPictureLink && postPicture && loadingPost != true) || loadingPost == true"
                    [label]="'Cover Image'"
                    [applyDownload]="applyDownload"
                    [loading]="loadingPost"
                    [isDownload]="false"
                    [isProceed]="false"
                    [isPrint]="false"
                    [canProceed]="false"
                    [content]="postPicture"
                    [extension]="'png'"
                    [label]=""
                  >
                  </app-blob-view>
                  <div
                    class="view-container full-height full-width"
                    *ngIf="postPictureLink && !postPicture && loadingPost != true"
                  >
                    <div class="row clearfix">
                      <pinch-zoom
                        class="full-height clearfix full-width pinchZoom"
                        [limit-zoom]="25"
                        [wheel]="true"
                        [wheelZoomFactor]="0.5"
                        [double-tap]="true"
                        [properties]="pinchZoomProperties"
                      >
                        <img [src]="postPictureLink" />
                      </pinch-zoom>
                    </div>
                  </div>
                  <div
                    class="view-container full-height full-width"
                    *ngIf="
                      (!postPicture || postPicture == null) &&
                      (!postPictureLink || postPictureLink == null) &&
                      loadingPost != true
                    "
                  >
                    <div class="row clearfix">
                      <pinch-zoom
                        class="full-height clearfix full-width pinchZoom"
                        [limit-zoom]="25"
                        [wheel]="true"
                        [wheelZoomFactor]="0.5"
                        [double-tap]="true"
                        [properties]="pinchZoomProperties"
                      >
                        <img
                          [src]="
                            'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/cover/pic8.png'
                          "
                        />
                      </pinch-zoom>
                    </div>
                  </div>
                </div>
                <div class="clearfix full-width">
                  <app-file-uploader
                    class="text-center clearfix full-width"
                    [allowedExtensions]="allowExtensions"
                    [aspectRatioType]="'qtr'"
                    [maxFileSize]="maxFileSize"
                    [isFixedAspectRatio]="false"
                    [isImageCropping]="true"
                    [canSkip]="false"
                    [limit]="1"
                    [uploadDirectly]="true"
                    [isDragable]="true"
                    [title]="'CLICK HERE to upload  Cover (Max size: ' + maxFileSize + 'MB)'"
                    (onCompleteAll)="onComplete($event)"
                  >
                  </app-file-uploader>
                </div>
              </div>
            </div>
            <hr class="mb"/>
            <div class="drop-button text-center full-width mt">
              <button
                class="btn-large pull-left"
                [attr.style]="'background:' + color + ' !important' | safeStyle"
                (click)="featureList()"
                mat-raised-button
                color="primary"
              >
                <mat-icon>chevron_left</mat-icon> Feature list
              </button>

              <button
                class="btn-large"
                [disabled]="!resetform.form.valid || isUnique != true"
                (click)="save(false)"
                mat-raised-button
                color="primary"
              >
                <mat-icon class="smr">save</mat-icon>Save Draft
              </button>

              <button
                class="btn-large pull-right"
                [disabled]="!resetform.form.valid || isUnique != true"
                (click)="save(true)"
                mat-raised-button
                color="accent"
              >
                Save
                <mat-icon>chevron_right</mat-icon>
              </button>
            </div>
          </form>
        </div>
      </aw-wizard-step>
      <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Sub Features" awOptionalStep>
        <div class="container mt" id="step-4">
          <div class="full-width">
            <app-feature-sub-label-list
              class="main-label-sub-feature-card subscription-plan-module"
              *ngIf="mainFeatureLabel && mainFeatureLabel.ID"
              [displayHeader]="false"
              [displayCover]="false"
              [mainFeatureLabelID]="mainFeatureLabel.ID"
              [displayEmpty]="true"
              [canHideDisplay]="false"
              [canSelect]="false"
              [canSelectMain]="false"
              [saveOrder]="saveOrder"
              [displaySaveButton]="false"
              [color]="color"
              [colorSecond]="colorSecond"
              [readOnly]="false"
              [canDragAndDrop]="true"
              (getSaveOrder)="getOrderEvent($event)"
            >
            </app-feature-sub-label-list>
          </div>
          <div class="drop-button full-width mt text-center">
            <button
              class="btn-large pull-left"
              [attr.style]="'background:' + color + ' !important' | safeStyle"
              (click)="stepBack()"
              mat-raised-button
              color="primary"
            >
              <mat-icon>chevron_left</mat-icon> Previous
            </button>
            <button class="btn-large pull-right" (click)="saveOrderEvent()" mat-raised-button color="accent">
              Finish
              <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </div>
      </aw-wizard-step>
      <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Done!" awOptionalStep>
        <div class="container mt" id="step-2" *ngIf="createdRecord && createdRecord.ID" @ngIfAnimation>
          <div class="animate clearfix">
            <div class="row clearfix">
              <mat-card class="card successful">
                <div class="row clearfix">
                  <div class="full-width text-center">
                    <h1 class="sm-h1 sr-title" [attr.style]="'color:' + color + ' !important' | safeStyle">
                      Well Done!
                    </h1>
                    <h3 class="sm-h3 rm-m" [attr.style]="'color:' + colorSecond + ' !important' | safeStyle">
                      Main feature has been Modified
                    </h3>
                    <div class="row clearfix">
                      <div class="svg-box">
                        <svg class="circular green-stroke">
                          <circle
                            class="path"
                            cx="75"
                            cy="75"
                            r="50"
                            fill="none"
                            stroke-width="5"
                            stroke-miterlimit="10"
                          />
                        </svg>
                        <svg class="checkmark green-stroke">
                          <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                            <path
                              class="checkmark__check"
                              fill="none"
                              d="M616.306,283.025L634.087,300.805L673.361,261.53"
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row clearfix text-center">
                  <button
                    class="mr"
                    [attr.style]="'background:' + color + ' !important' | safeStyle"
                    (click)="featureList()"
                    mat-raised-button
                    color="primary"
                  >
                    <mat-icon class="smr">list</mat-icon> Feature list
                  </button>
                  <button
                    class="ml"
                    [attr.style]="'background:' + colorSecond + ' !important' | safeStyle"
                    (click)="viewMainFeature(createdRecord.ID)"
                    mat-raised-button
                    color="accent"
                  >
                    <mat-icon class="smr">visibility</mat-icon>
                    <span>View Main Feature</span>
                  </button>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </aw-wizard-step>
    </aw-wizard>
  </div>
</mat-card>
