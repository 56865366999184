import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompleterData } from 'ng2-completer';
import { TeethShape } from '../shared/teeth-shape';
@Component({
  selector: 'app-dental-arch-modal',
  templateUrl: './dental-arch-modal.component.html',
  styleUrls: ['./dental-arch-modal.component.css'],
})
export class DentalArchModalComponent implements OnInit {
  selectQ1;
  selectQ2;
  selectQ3;
  selectQ4;

  @Input() selectedTeeth = [];
  @Output() select = new EventEmitter();
  isAdult = true;
  teethBottom = [];
  teethTop = [];
  options = [];
  selectedValue: any;
  dataService: CompleterData;
  isTopSelected = false;
  isBottomSelected = false;
  isAllSelected = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.selectedTeeth = data;
  }
  ngOnInit() {
    TeethShape.getTopTeethNumbers(this.isAdult).forEach((num) => {
      this.teethTop.push({
        hideNumber: false,
        toothNumber: num,
        selected: this.selectedTeeth.indexOf(num) !== -1 ? true : false,
      });
      this.options.push(num);
    });
    TeethShape.getBottomTeethNumbers(this.isAdult).forEach((num) => {
      this.teethBottom.push({
        hideNumber: false,
        toothNumber: num,
        selected: this.selectedTeeth.indexOf(num) !== -1 ? true : false,
      });
      this.options.push(num);
    });
    this.selectedTeeth.forEach((tooth) => {
      this.getSelectedteeth(tooth);
    });
  }
  selectBottom() {
    this.isBottomSelected = !this.isBottomSelected;
    this.teethBottom.forEach((tooth) => {
      let t = {
        selected: this.isBottomSelected,
        toothNumber: tooth.toothNumber,
      };
      tooth.selected = t.selected;
      if (tooth.selected === true) {
        this.selectedTeeth.push(tooth);
      } else {
        this.selectedTeeth.forEach((selectedtooth) => {
          if (t.toothNumber === selectedtooth.toothNumber) {
            selectedtooth.selected = t.selected;
          }
        });
        this.selectedTeeth = this.selectedTeeth.filter((tooth) => {
          return tooth.selected;
        });
      }
    });
    this.change();
  }

  deselectAll() {}

  selectAll() {
    this.isBottomSelected = !this.isBottomSelected;
    this.teethBottom.forEach((tooth) => {
      let t = {
        selected: this.isBottomSelected,
        toothNumber: tooth.toothNumber,
      };
      tooth.selected = t.selected;
      if (tooth.selected === true) {
        this.selectedTeeth.push(tooth);
      } else {
        this.selectedTeeth.forEach((selectedtooth) => {
          if (t.toothNumber === selectedtooth.toothNumber) {
            selectedtooth.selected = t.selected;
          }
        });
        this.selectedTeeth = this.selectedTeeth.filter((tooth) => {
          return tooth.selected;
        });
      }
    });

    this.isTopSelected = !this.isTopSelected;
    this.teethTop.forEach((tooth) => {
      let t = {
        selected: this.isTopSelected,
        toothNumber: tooth.toothNumber,
      };
      tooth.selected = t.selected;
      if (tooth.selected === true) {
        this.selectedTeeth.push(tooth);
      } else {
        this.selectedTeeth.forEach((selectedtooth) => {
          if (t.toothNumber === selectedtooth.toothNumber) {
            selectedtooth.selected = t.selected;
          }
        });
        this.selectedTeeth = this.selectedTeeth.filter((tooth) => {
          return tooth.selected;
        });
      }
    });
    this.change();
  }

  selectTop() {
    this.isTopSelected = !this.isTopSelected;
    this.teethTop.forEach((tooth) => {
      let t = {
        selected: this.isTopSelected,
        toothNumber: tooth.toothNumber,
      };
      tooth.selected = t.selected;
      if (tooth.selected === true) {
        this.selectedTeeth.push(tooth);
      } else {
        this.selectedTeeth.forEach((selectedtooth) => {
          if (t.toothNumber === selectedtooth.toothNumber) {
            selectedtooth.selected = t.selected;
          }
        });
        this.selectedTeeth = this.selectedTeeth.filter((tooth) => {
          return tooth.selected;
        });
      }
    });
    this.change();
  }
  selectTooth(t) {
    if (
      t.toothNumber >= TeethShape.getTopTeethNumbers(this.isAdult)[7] &&
      t.toothNumber <=
        TeethShape.getTopTeethNumbers(this.isAdult)[TeethShape.getTopTeethNumbers(this.isAdult).length - 1]
    ) {
      this.teethTop.forEach((tooth) => {
        if (t.toothNumber === tooth.toothNumber) {
          tooth.selected = t.selected;
          if (tooth.selected === true) {
            this.selectedTeeth.push(tooth);
          } else {
            this.selectedTeeth.forEach((selectedtooth) => {
              if (t.toothNumber === selectedtooth.toothNumber) {
                selectedtooth.selected = t.selected;
              }
            });
            this.selectedTeeth = this.selectedTeeth.filter((tooth) => {
              return tooth.selected;
            });
          }
        }
      });
    } else {
      this.teethBottom.forEach((tooth) => {
        if (t.toothNumber === tooth.toothNumber) {
          tooth.selected = t.selected;
          if (tooth.selected === true) {
            this.selectedTeeth.push(tooth);
          } else {
            this.selectedTeeth.forEach((selectedtooth) => {
              if (t.toothNumber === selectedtooth.toothNumber) {
                selectedtooth.selected = t.selected;
              }
            });
            this.selectedTeeth = this.selectedTeeth.filter((tooth) => {
              return tooth.selected;
            });
          }
        }
      });
    }
    this.change();
  }
  change() {
    this.select.emit({
      selectedTeeth: this.selectedTeeth,
      isAdult: this.isAdult,
    });
  }
  getSelectedteeth(t) {
    if (
      t.toothNumber >= TeethShape.getTopTeethNumbers(this.isAdult)[7] &&
      t.toothNumber <=
        TeethShape.getTopTeethNumbers(this.isAdult)[TeethShape.getTopTeethNumbers(this.isAdult).length - 1]
    ) {
      this.teethTop.forEach((tooth) => {
        if (t.toothNumber === tooth.toothNumber) {
          tooth.selected = t.selected;
        }
      });
    } else {
      this.teethBottom.forEach((tooth) => {
        if (t.toothNumber === tooth.toothNumber) {
          tooth.selected = t.selected;
        }
      });
    }
  }
}
