<div class="site_background" id="loginPageContainer">
  <div class="container flex isNotConsumer block">
    <mat-card class="card login-card mat-elevation-z8" @simpleFadeAnimation>
      <div class="row flex maincontain">
        <div class="half-width login-area">
          <img class="site_logo"/>

          <form class="mb" [formGroup]="loginForm" name="login" autocomplete="off">
            <mat-form-field class="full-width animate" appearance="outline">
              <mat-label>Username</mat-label>
              <input class="mat-block" matInput formControlName="username" autocomplete="off" placeholder="Username"
                     value="" required/>
            </mat-form-field>

            <mat-form-field class="full-width animate" appearance="outline">
              <mat-label>Password</mat-label>
              <input [type]="hide ? 'password' : 'text'" (keyup.enter)="login()" matInput formControlName="password"
                     placeholder="Password" value="" autocomplete="off" required/>

              <mat-icon class="shp" (click)="changeHide()" matSuffix>{{ hide ? 'visibility_off' : 'visibility' }}
              </mat-icon>
            </mat-form-field>

            <mat-checkbox class="clearfix animate" formControlName="rememberMe">Remember Me</mat-checkbox>

            <div class="row clearfix flex mt mb">
              <button class="animate full-width mr mat-elevation-z7" [disabled]="!loginForm.valid" (click)="login()"
                      mat-raised-button color="accent">
                Login
              </button>

              <button class="animate full-width ml" (click)="gotoSignUp()" mat-stroked-button>Sign up!</button>
            </div>
            <div class="row clearfix full-width mb">
              <app-social-media-input class="clearFix" [directLogin]="true" [buttonStyle]="'icons'"
                                      [facebookLabel]="'Connect via Facebook'" [googleLabel]="'Connect via Google'"
                                      (getResult)="loginBySocialMedia($event)"></app-social-media-input>
            </div>
            <div class="button-row add-mt">
              <button class="btn-clear animate" (click)="openResetPasswordModal()" mat-button color="primary">
                <mat-icon>help</mat-icon>
                Lost Password?
              </button>
            </div>
          </form>
        </div>
        <div class="half-width gradient-area primary-gradient-img">
          <p class="white top rm-mt">{{ "BRANDING.Brand Name" | translate | titlecase }} Dashboard</p>
          <h2 class="white rm-mt">Welcome back, please log in to your account.</h2>

          <p class="small white">
            By signing in you agree to {{ 'BRANDING.Brand Name' | translate }}'s
            <a class="white" href="https://smileright.com.au/consumer/(page:terms)" target="_blank"
               title="Terms and Conditions">Terms &amp; Conditions</a>
            and
            <a class="white" target="_blank" title="Privacy Policy"
               href="https://smileright.com.au/consumer/(page:privacy)">Privacy Policy</a>
          </p>
        </div>
      </div>
    </mat-card>
  </div>
</div>
