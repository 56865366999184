<div class="row clearfix">
  <div class="row clearfix">
    <div class="icons-col">
      <mat-icon class="fas fa-map-marked-alt"></mat-icon>
    </div>
    <div class="row-content-col">
      <div class="clearfix pull-right" *ngIf="isDisabled == false">
        <!-- <button mat-icon-button matTooltip="Delete address" (click)="removeAddressByLabel(addressType)" *ngIf="addressType != targetAddressType && false">
          <mat-icon>delete</mat-icon>
        </button> -->

        <button (click)="editAddressByLabel(addressType)" mat-icon-button matTooltip="Update address">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
      <mat-form-field class="half-width mb" appearance="outline">
        <mat-label>Address Type</mat-label>
        <mat-select
          [(ngModel)]="addressType"
          [disabled]="isDisabled"
          [ngClass]="{ removeArrow: isDisabled == true }"
          (selectionChange)="getAddressByType(addressType)"
          placeholder="Address type"
          name="addressType"
        >
          <mat-option *ngFor="let type of addressTypes" [value]="type">
            <span *ngIf="convertToLowerCase(type) != convertToLowerCase(targetAddressType)">{{
              type | titlecase
              }}</span>
            <span *ngIf="convertToLowerCase(type) == convertToLowerCase(targetAddressType)"
            >{{ type | titlecase }} - Primary</span
            >
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <app-address-input
    class="full-width"
    [displayMap]="false"
    [canClose]="false"
    [postCode]="myPostCode"
    [state]="myState"
    [suburb]="mySuburb"
    [streetNumber]="myStreetNumber"
    [streetName]="myStreetName"
    [unitNumber]="myUnitNumber"
    [streetType]="myStreetType"
    [latitude]="myLatitude"
    [longitude]="myLongitude"
    [isRequired]="false"
    [isDisabled]="true"
  >
  </app-address-input>
</div>
