<aw-wizard #wizard navBarLayout="large-filled-symbols">
  <aw-wizard-step stepTitle="Applicant Details" navigationSymbol="1">
    <!-- Step 1  -->
    <div class="container" id="step-1">
      <h2 class="sr-title">Step 1 - Sign up to {{ 'KEYWORD.Brand Name' | translate }}</h2>

      <mat-card>
        <mat-card-header class="info">
          <p class="lead rm-mb rm-mt">Hi! Welcome to {{ 'KEYWORD.Brand Name' | translate }}.</p>
          <p>
            Let's start by getting to know a bit about you! Fill in the following details to start setting up your
            {{ "BRANDING.Brand Name" | translate | titlecase }} account.
          </p>
          <hr/>
        </mat-card-header>
        <div class="row clearfix">
          <div class="half-width">
            <div class="column">
              <!-- First Name -->
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>First Name</mat-label>

                <input class="mat-block" matInput placeholder="{{ 'PLACEHOLDER.First Name' | translate }} "
                       name="firstname" required/>
              </mat-form-field>
              <!-- Middle Name -->
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Middle Name</mat-label>
                <input class="mat-block" matInput placeholder="{{ 'PLACEHOLDER.Middle Name' | translate }} "
                       name="middlename"/>
              </mat-form-field>
              <!-- Surname -->
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Surname</mat-label>
                <input class="mat-block" matInput placeholder="{{ 'PLACEHOLDER.Surname' | translate }} " name="lastname"
                       required/>
              </mat-form-field>

              <!--  Role in practice -->
              <div class="clearfix">
                <mat-select class="full-width" [placeholder]="'Role in ' + ('KEYWORD.practice' | translate | titlecase)"
                            name="pracRole" required>
                  <mat-option *ngFor="let pracRole of pracRoles" [value]="pracRole.value">
                    {{ pracRole.viewValue }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </div>
          <div class="half-width">
            <div class="column">
              <!-- Email Address -->
              <mat-form-field class="full-width" appearance="outline">
                <mat-label> Email</mat-label>
                <input class="mat-block" [formControl]="emailFormControl" matInput
                       placeholder="{{ 'PLACEHOLDER.Email' | translate }}" type="email" name="email" required/>
                <mat-error *ngIf="emailFormControl.hasError('pattern')"> Please enter a valid email address</mat-error>
                <mat-error *ngIf="emailFormControl.hasError('required')">
                  Email is <strong>required</strong>
                </mat-error>
              </mat-form-field>
              <!-- Mobile Number -->
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Mobile</mat-label>
                <input class="mat-block" matInput type="tel" placeholder="{{ 'PLACEHOLDER.Phone' | translate }} "
                       name="mobile" pattern="([0-9]{10})" required/>
              </mat-form-field>

              <label> Create a new password. This password should be at least eight characters long. </label>
              <!-- Create password -->
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Create password</mat-label>
                <input class="mat-block" matInput placeholder="Create password" name="password" required/>
              </mat-form-field>
              <button mat-raised-button color="primary">
                Show
                <mat-icon>visibility</mat-icon>
              </button>
              <button class="btn-inverse" mat-raised-button>Generate Password</button>
            </div>
          </div>
        </div>
      </mat-card>
      <div class="row clearfix">
        <div class="full-width text-center step-button-row">
          <button class="step-back" (click)="cancel()" mat-mini-fab color="primary">
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <button class="btn-large" mat-raised-button color="accent" awNextStep>Next</button>
        </div>
      </div>
    </div>
    <!--  / Step 1  -->
  </aw-wizard-step>

  <aw-wizard-step [stepTitle]="('KEYWORD.practice' | translate | titlecase) + ' Details'" navigationSymbol="2">
    <!--  Step 2  -->
    <div class="container" id="step-2">
      <div class="row clearfix">
        <h2 class="sr-title">Step 2 - Register your {{ "KEYWORD.practice" | translate }}</h2>

        <mat-card>
          <mat-card-header class="info">
            <p class="lead rm-mb rm-mt">Thanks FirstName.</p>
            <p>
              Your {{ "BRANDING.Brand Name" | translate | titlecase }} account is now set up. Now we need to register
              your
              {{ "KEYWORD.practice" | translate }} for full access to the
              {{ "BRANDING.Brand Name" | translate | titlecase }} platform!
            </p>
            <hr/>
          </mat-card-header>
          <div class="row clearfix">
            <div class="half-width">
              <div class="column">
                <p>Identify your {{ "KEYWORD.practice" | translate }} {{ 'PARAGRAPH.IdentifyABNorACN' | translate }}.
                </p>
                <div class="clearfix">
                  <!-- abn or acn search -->
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Enter your ABN or ACN</mat-label>
                    <input class="mat-block" matInput placeholder="Enter your ABN or ACN" name="abnAcn" required/>
                  </mat-form-field>
                  <!--  search trigger -->
                  <button class="btn-inverse" mat-raised-button>
                    <mat-icon>search</mat-icon>
                    Search
                  </button>
                </div>

                <!--  Well item appears if practice is matched to ABN/ACN -->
                <div class="well foundPractice-base">
                  <p class="rm-mt">
                    <em> Is this your {{ "KEYWORD.practice" | translate }}? </em>
                  </p>
                  <mat-divider></mat-divider>
                  <p class="lead rm-mb">Trading nameTrading Name</p>
                  <p>{{ 'PLACEHOLDER.Street Address with Suburb' | translate }}</p>
                  <button class="btn-clear" mat-raised-button>No</button>
                  <button mat-raised-button color="primary">Yes</button>
                </div>
                <!--  If yes, prefil practice name, email, contact, address -->
                <!-- If no, hide well and prompt try again -->

                <div class="clearfix">
                  <!-- Practice name -->
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{ "KEYWORD.practice" | translate | titlecase}} Name</mat-label>
                    <input class="mat-block" [placeholder]="('KEYWORD.practice' | translate | titlecase) + ' Name'"
                           matInput name="abnAcn" required/>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="half-width">
              <div class="column">
                <!-- Practice Email Address -->
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>{{ "KEYWORD.practice" | translate | titlecase}} Email Address</mat-label>
                  <input class="mat-block"
                         [placeholder]="('KEYWORD.practice' | translate | titlecase) + ' Email Address'"
                         [formControl]="emailFormControl2" matInput type="email" name="email" required/>
                  <mat-error *ngIf="emailFormControl.hasError('pattern')">
                    Please enter a valid email address
                  </mat-error>
                  <mat-error *ngIf="emailFormControl.hasError('required')">
                    Email is <strong>required</strong>
                  </mat-error>
                </mat-form-field>

                <!-- Mobile Number -->
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>{{ "KEYWORD.practice" | translate | titlecase}} Contact Number</mat-label>
                  <input class="mat-block"
                         [placeholder]="('KEYWORD.practice' | translate | titlecase) + ' Contact Number'" matInput
                         type="tel"
                         name="mobile" pattern="([0-9]{10})" required/>
                </mat-form-field>

                <br/>
                <label>{{ "KEYWORD.practice" | translate | titlecase}} Location</label>

                <p>
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{ 'PLACEHOLDER.Street Address' | translate }}</mat-label>
                    <textarea matInput placeholder="Address">{{ 'PLACEHOLDER.Street Address' | translate }}</textarea>
                  </mat-form-field>
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Address 2</mat-label>
                    <textarea matInput placeholder="Address 2"></textarea>
                  </mat-form-field>
                </p>

                <table class="full-width" cellspacing="0">
                  <tr>
                    <td>
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>City</mat-label>
                        <input matInput placeholder="City"/>
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-form-field class="full-width" appearance="outline">
                        <input matInput placeholder="State"/>
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Postal Code</mat-label>
                        <input #postalCode matInput maxlength="4" placeholder="Postal Code" value="1234"/>
                        <mat-hint align="end">{{ postalCode.value.length }} / 4</mat-hint>
                      </mat-form-field>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </mat-card>
      </div>

      <div class="row clearfix">
        <div class="full-width step-button-row text-center">
          <button class="step-back" mat-mini-fab color="primary" awPreviousStep>
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <button class="step-next" class="btn-large" mat-raised-button color="accent" awNextStep>
            Next
          </button>
        </div>
      </div>
    </div>
    <!--  / Step 2  -->
  </aw-wizard-step>

  <aw-wizard-step stepTitle="Product Licenses" navigationSymbol="3">
    <!--  Step 3  -->
    <div class="container" id="step-3">
      <div class="row clearfix">
        <h2 class="sr-title">Step 3 - Select your {{ "BRANDING.Brand Name" | translate | titlecase }} products</h2>

        <mat-card>
          <div class="row clearfix">
            <div class="half-width">
              <div class="column text-center">
                <p class="lead">{{ "BRANDING.Brand Name" | translate | titlecase }} Platform</p>
                <p>
                  With the {{ "BRANDING.Brand Name" | translate | titlecase }} App your {{ "KEYWORD.patient" | translate
                  }}s can
                  request finance quickly and easily - or you can invite them to apply directly - and our technology
                  provides them with a real-time response. There’s no waiting for someone to get back to you or to fill
                  out tedious paperwork, {{ "KEYWORD.patient" | translate }}s simply follow the steps as the app guides
                  them through the process.
                </p>
                <hr/>
                <p class="lead">$500 Establishment Fee</p>
                <hr/>
                <mat-checkbox class="example-margin" [(ngModel)]="checked" [disabled]="disabled" color="primary">
                  Included with your Registration!
                </mat-checkbox>
              </div>
            </div>
            <div class="half-width">
              <div class="column text-center">
                <p class="lead">{{ "BRANDING.Brand Name" | translate | titlecase }} Memberships</p>
                <p>
                  {{ "BRANDING.Brand Name" | translate | titlecase }} Memberships offer a new way to maintain
                  {{ "KEYWORD.patient" | translate }} engagement - {{ "KEYWORD.dental" | translate | titlecase }}
                  Memberships. With
                  a {{ 'KEYWORD.dental' | translate }} membership, {{ "KEYWORD.patient" | translate }}s
                  {{ 'PARAGRAPH.Merchant Create_Oral Care' | translate }}
                  . Choose from predefined {{ "BRANDING.Brand Name" | translate | titlecase }} membership options, or
                  create your
                  own custom membership
                  packages.
                </p>
                <hr/>
                <p class="lead">No Additional Cost</p>
                <hr/>
                <mat-checkbox class="example-margin" color="primary"> Add Membership Packages</mat-checkbox>
              </div>
            </div>
          </div>
        </mat-card>
      </div>

      <div class="row clearfix">
        <div class="full-width step-button-row text-center">
          <button class="step-back" mat-mini-fab color="primary" awPreviousStep>
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <button class="step-next" class="btn-large" mat-raised-button color="accent" awNextStep>
            Next
          </button>
        </div>
      </div>
    </div>
    <!--  / Step 3  -->
  </aw-wizard-step>

  <aw-wizard-step stepTitle="Billing Details" navigationSymbol="4">
    <!--  Step 4  -->
    <div class="container" id="step-4">
      <div class="row clearfix">
        <h2 class="sr-title">Step 4 - Enter your billing details</h2>

        <mat-card>
          <div class="row clearfix">
            <div class="half-width">
              <div class="column">
                <!--  Select payment method  -->
                <div class="clearfix">
                  <mat-select class="full-width" placeholder="Select payment method" name="payMethod" required>
                    <mat-option *ngFor="let payMethod of payMethods" [value]="payMethod.value">
                      {{ payMethod.viewValue }}
                    </mat-option>
                  </mat-select>
                </div>

                <div class="credit-card-payment"></div>

                <div class="bank-account-payment"></div>
              </div>
            </div>
            <div class="half-width">
              <div class="column"></div>
            </div>
          </div>
        </mat-card>
      </div>

      <div class="row clearfix">
        <div class="full-width step-button-row text-center">
          <button class="step-back" mat-mini-fab color="primary" awPreviousStep>
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <button class="step-next" class="btn-large" mat-raised-button color="accent" awNextStep>
            Next
          </button>
        </div>
      </div>
    </div>
    <!--  / Step 4  -->
  </aw-wizard-step>

  <aw-wizard-step stepTitle="Review" navigationSymbol="5">
    <!--  Step 5  -->
    <div class="container" id="step-5">
      <div class="row clearfix">
        <h2 class="sr-title">Step 5 - Review and Submit</h2>

        <mat-card>
          <div class="row clearfix">
            <div class="half-width">
              <div class="column">
                <p>LEFT COL</p>
              </div>
            </div>
            <div class="half-width">
              <div class="column">
                <p>RIGHT COL</p>

                <!-- <div class="signature-area">

                  </div> -->

                <!-- <div class="sig-container" #sigContainer> -->

                <!-- <button mat-mini-fab color="accent" class="clear-sig-button" (click)="clear()" ><mat-icon>clear</mat-icon></button> -->

                <!-- <div fxLayout="row clearfix"> -->
                <!-- <signature-pad [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()"></signature-pad> -->
                <!-- </div> -->
              </div>
            </div>
          </div>
        </mat-card>
      </div>

      <div class="row clearfix">
        <div class="full-width step-button-row text-center">
          <button class="step-back" mat-mini-fab color="primary" awPreviousStep>
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <button class="step-next" class="btn-large" mat-raised-button color="accent" awNextStep>
            Next
          </button>
        </div>
      </div>
    </div>
    <!--  / Step 5  -->
  </aw-wizard-step>

  <!-- The completion step -->
  <aw-wizard-completion-step title="Submit for approval" navigationSymbol="6">
    <!--  Step 3 - finished -->
    <div class="container" id="step-6">
      <div class="row clearfix">
        <div class="full-width">
          <h1>Submitted</h1>
        </div>
      </div>
      <div class="row clearfix">
        <div class="full-width text-center step-button-row">
          <button class="full-back" (click)="finishFunction()" mat-raised-button color="primary">
            <mat-icon>keyboard_arrow_left</mat-icon>
            Dashboard
          </button>
        </div>
      </div>
    </div>
    <!--  / Step 3  -->
  </aw-wizard-completion-step>
</aw-wizard>
