var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EventEmitter, OnInit } from '@angular/core';
import { AuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { CalendarShareService } from '../../../shared/services/calendar-share.service';
import { CalendarService } from '../../../shared/services/calendar.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { AuthenticationService } from '../shared/authentication.service';
export class SocialMediaInputComponent {
    constructor(authSocialService, authenticationService, calendarService, calendarShare) {
        this.authSocialService = authSocialService;
        this.authenticationService = authenticationService;
        this.calendarService = calendarService;
        this.calendarShare = calendarShare;
        this.logout = true;
        this.buttonStyle = 'buttons';
        // Status
        this.facebookStatus = null;
        this.googleStatus = null;
        this.iCloudCalendarStatus = null;
        this.googleCalendarStatus = null;
        this.msExchangeStatus = null;
        // Access Tokens
        this.iCloudCalendarID = null;
        this.googleCalendarID = null;
        this.msExchangeID = null;
        // @Input()
        // office365Status = null;
        // Status Label
        this.facebookStatusLabel = '';
        this.googleStatusLabel = '';
        this.iCloudCalendarStatusLabel = '';
        this.googleCalendarStatusLabel = '';
        this.msExchangeStatusLabel = '';
        // @Input()
        // office365Label;
        // Button Label
        this.facebookButtonLabel = 'Connect Facebook';
        this.googleButtonLabel = 'Connect Google';
        this.iCloudCalendarButtonLabel = 'Connect iCloud Calendar';
        this.googleCalendarButtonLabel = 'Connect Google Calendar';
        this.msExchangeButtonLabel = 'Connect MS Exchange';
        // @Input()
        // office365ButtonLabel = "Connect Office 365";
        // Date
        this.facebookDate = null;
        this.googleDate = null;
        this.iCloudCalendarDate = null;
        this.googleCalendarDate = null;
        this.msExchangeDate = null;
        // @Input()
        // office365Date = null;
        // Display
        this.displayFacebook = true;
        this.displayGoogle = true;
        this.displayiCloudCalendar = false;
        this.displayGoogleCalendar = false;
        this.displayMSExchange = false;
        //
        // @Input()
        // displayOffice365 = false;
        this.directLogin = true;
        this.loginEvent = new EventEmitter();
        this.getResult = new EventEmitter();
        this.getClick = new EventEmitter();
        this.getRemove = new EventEmitter();
        this.canRemove = false;
        this.removeLabel = 'Remove Integration';
        this.type = 'facebook';
        this.clicked = false;
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            // this.calendarShare.initGoogleClient();
            const res = yield this.authenticationService.getSessionType().toPromise();
            if (res) {
                this.sessionType = res;
            }
            this.authSocialService.authState.subscribe((user) => {
                if (user && this.clicked != false) {
                    const p = JSON.parse(JSON.stringify(user)) || {};
                    if (p && p.provider == 'FACEBOOK') {
                        p.facebookID = p.id;
                        p.facebookLabel = p.name;
                    }
                    else {
                        p.facebookID = null;
                        p.facebookLabel = null;
                    }
                    if (p && p.provider == 'GOOGLE') {
                        p.googleID = p.id;
                        p.googleLabel = p.name;
                    }
                    else {
                        p.googleID = null;
                        p.googleLabel = null;
                    }
                    if (p && p.provider == 'TWITTER') {
                        p.twitterID = p.id;
                        p.twitterLabel = p.twitterLabel;
                    }
                    else {
                        p.twitterID = null;
                        p.twitterLabel = null;
                    }
                    if (p && p.photoUrl) {
                        p.profilePicture = p.photoUrl.replace('normal', 'large');
                        p.profilePicture = p.photoUrl.replace('s96-c', 's400-c');
                    }
                    this.getResult.emit(p);
                    this.authSocialService.signOut();
                }
            });
            this.loginEvent.subscribe((res) => {
                if (this.type == 'facebook') {
                    this.authSocialService.signIn(GoogleLoginProvider.PROVIDER_ID);
                }
                else if (this.type == 'google') {
                    this.authSocialService.signIn(GoogleLoginProvider.PROVIDER_ID);
                }
                else if (this.type == 'twitter') {
                }
            });
            // this.checkSyncedCalendarsStillValid();
        });
    }
    syncGoogle() {
        this.clicked = true;
        this.type = 'google';
        if (this.directLogin == true) {
            this.authSocialService.signIn(GoogleLoginProvider.PROVIDER_ID);
        }
        else {
            this.getClick.emit(this.type);
        }
    }
    syncFaceBook() {
        this.clicked = true;
        this.type = 'facebook';
        if (this.directLogin == true) {
            this.authSocialService.signIn(FacebookLoginProvider.PROVIDER_ID);
        }
        else {
            this.getClick.emit(this.type);
        }
    }
    toggleiCloudCalendar() { }
    toggleGoogleCalendar() {
        return __awaiter(this, void 0, void 0, function* () {
            let payload;
            const isConnected = this.googleCalendarStatus === 'valid';
            if (isConnected) {
                payload = {
                    googleID: 'void',
                    googleETag: 'void',
                    googleLabel: 'void',
                    googleExpirationDate: 'void',
                    googleAddedDate: 'void',
                };
                this.calendarShare.googleSignOut();
            }
            else {
                const res = yield this.calendarShare.googleSignIn();
                // const refreshAccessCode = await this.calendarShare
                //   .googleRefreshTokenRequest(res.code)
                //   .toPromise();
                const user = this.calendarShare.googleGetCurrentUserDetails();
                const idToken = user.id_token;
                // const accessToken = user.access_token;
                // const refreshToken = refreshAccessCode.refresh_token;
                const expiresAt = user.expires_at;
                const fullname = user.fullname;
                const email = user.email;
                payload = {
                    googleID: idToken,
                    // googleETag: accessToken,
                    googleETag: res.code,
                    googleLabel: `${fullname} - ${email}`,
                    googleExpirationDate: expiresAt,
                    googleAddedDate: new Date(),
                };
            }
            this.calendarService.syncCalendar(payload, this.sessionType).subscribe((res) => {
                if (isConnected) {
                    if (!res['Google.ID']) {
                        NotifyAppComponent.displayToast('success', 'Calendar Disconnect', 'Google calendar is now disconnected');
                        this.getResult.emit(res);
                    }
                    else {
                        NotifyAppComponent.displayToast('error', 'Calendar Disconnect', 'Something went wrong when disconnecting');
                    }
                }
                else {
                    if (res['Google.ID']) {
                        NotifyAppComponent.displayToast('success', 'Calendar Sync', 'Google calendar signed in');
                        this.getResult.emit(res);
                    }
                    else {
                        NotifyAppComponent.displayToast('error', 'Calendar Sync', 'Something went wrong when signing in');
                    }
                }
            });
        });
    }
    toggleMSExchange() {
        return __awaiter(this, void 0, void 0, function* () {
            let payload;
            const isConnected = this.msExchangeStatus === 'valid';
            if (isConnected) {
                payload = {
                    microsoftID: 'void',
                    microsoftETag: 'void',
                    microsoftLabel: 'void',
                    microsoftExpirationDate: 'void',
                    microsoftAddedDate: 'void',
                };
                this.calendarShare.microsoftSignOut();
            }
            else {
                const res = yield this.calendarShare.msSignIn();
                if (res) {
                    const user = yield this.calendarShare.microsoftGetCurrentUserDetails();
                    const idToken = user.id_token;
                    const accessToken = user.access_token;
                    const expiresAt = user.expires_at;
                    const fullname = user.fullname;
                    const email = user.email;
                    payload = {
                        microsoftID: idToken,
                        microsoftETag: accessToken,
                        microsoftLabel: `${fullname} - ${email}`,
                        microsoftExpirationDate: expiresAt,
                        microsoftAddedDate: new Date(),
                    };
                }
                else {
                    return;
                }
            }
            this.calendarService.syncCalendar(payload, this.sessionType).subscribe((res) => {
                if (isConnected) {
                    if (!res['Microsoft.ID']) {
                        NotifyAppComponent.displayToast('success', 'Calendar Disconnect', 'Microsoft calendar is now disconnected');
                        this.getResult.emit(res);
                    }
                    else {
                        NotifyAppComponent.displayToast('error', 'Calendar Disconnect', 'Something went wrong when disconnecting');
                    }
                }
                else {
                    if (res['Microsoft.ID']) {
                        NotifyAppComponent.displayToast('success', 'Calendar Sync', 'Microsoft calendar signed in');
                        this.getResult.emit(res);
                    }
                    else {
                        NotifyAppComponent.displayToast('error', 'Calendar Sync', 'Something went wrong when signing in');
                    }
                }
            });
        });
    }
    removeEvent(type) {
        if (type) {
            this.getRemove.emit(type);
        }
    }
}
