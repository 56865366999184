import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material';
import { ArchwizardModule } from 'angular-archwizard';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgPipesModule } from 'ngx-pipes';
import { AuthenticationModule } from '../../core/authentication/authentication.module';
import { SharedModule } from '../../shared/shared.module';
import { MerchantModule } from '../merchant/merchant.module';
import { OperatorModule } from '../operator/operator.module';
import { EditorComponentCloneComponent } from './editor-component-clone/editor-component-clone.component';
import { EditorComponentCreateEditComponent } from './editor-component-create-edit/editor-component-create-edit.component';
import { EditorComponentDocumentViewComponent } from './editor-component-document-view/editor-component-document-view.component';
import { EditorComponentEditModalComponent } from './editor-component-edit-modal/editor-component-edit-modal.component';
import { EditorComponentListDefaultComponent } from './editor-component-list-default/editor-component-list-default.component';
import { EditorComponentListOverviewComponent } from './editor-component-list-overview/editor-component-list-overview.component';
import { EditorComponentListComponent } from './editor-component-list/editor-component-list.component';
import { EditorComponentLookupComponent } from './editor-component-lookup/editor-component-lookup.component';
import { EditorComponentRedirectionComponent } from './editor-component-redirection/editor-component-redirection.component';
import { EditorComponentViewComponent } from './editor-component-view/editor-component-view.component';
import { EditorDocumentCreateComponent } from './editor-document-create/editor-document-create.component';
import { EditorDocumentGalleryComponent } from './editor-document-gallery/editor-document-gallery.component';
import { EditorComponentRoutingModule } from './shared/editor-component-routing.module';
import { EditorComponentService } from './shared/editor-component.service';

@NgModule({
  declarations: [
    EditorComponentLookupComponent,
    EditorComponentCreateEditComponent,
    EditorComponentListComponent,
    EditorComponentListOverviewComponent,
    EditorComponentViewComponent,
    EditorComponentRedirectionComponent,
    EditorComponentListDefaultComponent,
    EditorComponentEditModalComponent,
    EditorComponentCloneComponent,
    EditorDocumentCreateComponent,
    EditorDocumentGalleryComponent,
    EditorComponentDocumentViewComponent,
  ],
  imports: [
    ArchwizardModule,
    ReactiveFormsModule,
    CdkTableModule,
    CommonModule,
    NgPipesModule,
    FormsModule,
    SharedModule,
    AuthenticationModule,
    MerchantModule,
    NgxPaginationModule,
    MatSortModule,
    EditorComponentRoutingModule,
    EditorComponentRoutingModule,
    OperatorModule,
  ],
  exports: [
    EditorComponentLookupComponent,
    EditorComponentCreateEditComponent,
    EditorComponentListComponent,
    EditorComponentListOverviewComponent,
    EditorComponentViewComponent,
    EditorComponentRedirectionComponent,
    EditorComponentListDefaultComponent,
    EditorComponentEditModalComponent,
    EditorComponentCloneComponent,
    EditorDocumentCreateComponent,
    EditorDocumentGalleryComponent,
    EditorComponentDocumentViewComponent,
  ],
  providers: [EditorComponentService],
})
export class EditorComponentModule {}
