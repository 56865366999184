<div class="rel">
  <div class="card-header primary-gradient-img clearfix confirmModal" *ngIf=" !confirmDialog.color" [ngStyle]="{ 'background-color':  confirmDialog.color  }">
    <button
      class="btn-close btn-clear mat-button smt white custom-close"
      [ngClass]="!confirmDialog.title ? 'no-title-close' : ''"
      (click)="onClickEvent()"
    >
      <mat-icon>clear</mat-icon>
    </button>
    <div
      class="full-width flex main-title-container"
      [ngClass]="{ 'main-icon-container-no-title': confirmDialog && !confirmDialog.title }"
    >
      <div class="main-icon-container text-center">
        <mat-icon class="main-icon accent-color" *ngIf="isFontAwsome(confirmDialog.icon) != true"  [ngStyle]="{ 'color':  confirmDialog.color  }" >
          {{ confirmDialog.icon || 'info' }}</mat-icon
        >

        <mat-icon [ngStyle]="{ 'color':  confirmDialog.color  }"
          class="main-icon accent-color {{ confirmDialog.icon || 'fas fa-info' }}"
          *ngIf="isFontAwsome(confirmDialog.icon) == true"
        ></mat-icon>
      </div>
      <div class="row full-width title-container clearfix">
        <div class="title-container-sub">
          <h2 class="text-left title-header rm-m">
            {{ confirmDialog.title }}
          </h2>
        </div>
      </div>
    </div>
  </div>

  <div class="card-header  clearfix header-card-customized confirmModal" *ngIf=" confirmDialog.color" [ngStyle]="{ 'background-color':  confirmDialog.color  }">
    <button
      class="btn-close btn-clear mat-button smt white custom-close"
      [ngClass]="!confirmDialog.title ? 'no-title-close' : ''"
      (click)="onClickEvent()"
    >
      <mat-icon>clear</mat-icon>
    </button>
    <div
      class="full-width flex main-title-container"
      [ngClass]="{ 'main-icon-container-no-title': confirmDialog && !confirmDialog.title }"
    >
      <div class="main-icon-container text-center">
        <mat-icon class="main-icon " *ngIf="isFontAwsome(confirmDialog.icon) != true"  [ngStyle]="{ 'color':  confirmDialog.color  }" >
          {{ confirmDialog.icon || 'info' }}</mat-icon
        >

        <mat-icon [ngStyle]="{ 'color':  confirmDialog.color  }"
          class="main-icon  {{ confirmDialog.icon || 'fas fa-info' }}"
          *ngIf="isFontAwsome(confirmDialog.icon) == true"
        ></mat-icon>
      </div>
      <div class="row full-width title-container clearfix">
        <div class="title-container-sub">
          <h2 class="text-left title-header rm-m">
            {{ confirmDialog.title }}
          </h2>
        </div>
      </div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="row clearfix mb">
      <div class="single-more-text" [innerHTML]="confirmDialog.confirmMessage | clearText | safeHtml"></div>
    </div>

    <mat-checkbox
      class="full-width smt smb"
      *ngIf="confirmDialog && confirmDialog.displayDisplay === true"
      [(ngModel)]="dontShow"
      name="isLargeButton"
    >
      Do not show this message again
    </mat-checkbox>

    <hr/>
  </mat-dialog-content>
  <div class="drop-button text-center full-width">
    <button (click)="onClickEvent()" mat-raised-button color="accent" [ngStyle]="{ 'background-color':  confirmDialog.color  }">{{ confirmDialog.buttonMessage }}</button>
    <!-- Can optionally provide a result for the closing dialog. -->
  </div>
</div>
