<ng-container *ipvIsPromoterOrAdmin="true">
  <mat-form-field class="full-width ml mr" *ngIf="merchants.length > 0" appearance="outline">
    <mat-label> Select  {{ 'KEYWORD.practice' | translate }} </mat-label>

    <mat-select
      [(ngModel)]="merchant"
      [compareWith]="matchMerchants"
      (ngModelChange)="setMerchant()"
      name="merchant"
      placeholder="Select Merchant"
    >
      <mat-select-trigger>
        {{ merchant.TradingAs }}
      </mat-select-trigger>

      <mat-option *ngIf="startWithAllMerchants" [value]="allMerchantsOptionValue"> All Merchants</mat-option>

      <mat-option *ngFor="let merchant of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="merchant">
        <span
          class="list-label pull-right"
          *ngIf="merchant['Status'] && merchant['Status'] == 'Active'"
          [style.background]="'#00d07a'"
        >
          {{ merchant['Status'] }}
        </span>

        <span
          class="list-label pull-right"
          *ngIf="merchant['Status'] && merchant['Status'] != 'Active'"
          [style.background]="'#ff5722'"
        >
          {{ merchant['Status'] }}
        </span>

        {{ merchant['TradingAs'] || merchant['CalculatedName'] }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>
