<aw-wizard #wizard navBarLayout="large-filled-symbols">
  <aw-wizard-step stepTitle="Staff Member Details" navigationSymbol="1">
    <!-- Step 1  -->
    <div class="container" id="step-1">
      <div class="row clearfix">
        <div class="full-width rel">
          <div class="text-center">
            <h2 class="sr-title">New Merchant Invitation</h2>
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <div class="half-width">
          <div class="column">LEFT COL</div>
        </div>
        <form class="half-width" #createForm="ngForm" name="form">
          <div class="column">RIGHT COL</div>
        </form>
      </div>
      <div class="row clearfix">
        <div class="full-width text-center step-button-row">
          <button class="btn-large" mat-raised-button color="accent" awNextStep>Next</button>
        </div>
      </div>
    </div>
    <!--  / Step 1  -->
  </aw-wizard-step>
  <aw-wizard-step stepTitle="Profile Details" navigationSymbol="2">
    <!--  Step 2  -->
    <div class="container" id="step-2">
      <div class="row clearfix">
        <h3 class="sm-h3-sml">Step 2</h3>

        <p>Step two inputs</p>
      </div>

      <div class="row clearfix">
        <div class="full-width step-button-row text-center">
          <button class="step-back" mat-mini-fab color="primary" awPreviousStep>
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <button class="step-next" class="btn-large" mat-raised-button color="accent" awNextStep>
            Next
          </button>
        </div>
      </div>
    </div>
    <!--  / Step 2  -->
  </aw-wizard-step>

  <!-- The completion step -->
  <aw-wizard-completion-step title="Send!" navigationSymbol="3">
    <!--  Step 3 - finished -->
    <div class="container" id="step-3">
      <div class="row clearfix">
        <div class="full-width text-center">
          <h1>Invitation is sent</h1>

          <div class="svg-box">
            <svg class="circular green-stroke">
              <circle class="path" cx="75" cy="75" r="50" fill="none" stroke-width="5" stroke-miterlimit="10"/>
            </svg>
            <svg class="checkmark green-stroke">
              <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                <path class="checkmark__check" fill="none" d="M616.306,283.025L634.087,300.805L673.361,261.53"/>
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <div class="full-width text-center step-button-row">
          <button class="full-back" (click)="finishFunction()" mat-raised-button color="primary">
            <mat-icon>keyboard_arrow_left</mat-icon>
            Dashboard
          </button>
        </div>
      </div>
    </div>
    <!--  / Step 3  -->
  </aw-wizard-completion-step>
</aw-wizard>
