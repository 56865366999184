/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./inner-html-isolated.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../empty-list-message/empty-list-message.component.ngfactory";
import * as i3 from "../empty-list-message/empty-list-message.component";
import * as i4 from "../../pipes/safe-html.pipe";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../pipes/clear-text.pipe";
import * as i7 from "@angular/common";
import * as i8 from "./inner-html-isolated.component";
var styles_InnerHtmlIsolatedComponent = [i0.styles];
var RenderType_InnerHtmlIsolatedComponent = i1.ɵcrt({ encapsulation: 3, styles: styles_InnerHtmlIsolatedComponent, data: {} });
export { RenderType_InnerHtmlIsolatedComponent as RenderType_InnerHtmlIsolatedComponent };
function View_InnerHtmlIsolatedComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "read-more-text-inner"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(1, 1), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent, 1), _co.content)))); _ck(_v, 0, 0, currVal_0); }); }
function View_InnerHtmlIsolatedComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-empty-list-message", [], null, null, null, i2.View_EmptyListMessageComponent_0, i2.RenderType_EmptyListMessageComponent)), i1.ɵdid(1, 114688, null, 0, i3.EmptyListMessageComponent, [], { title: [0, "title"], message: [1, "message"] }, null)], function (_ck, _v) { var currVal_0 = "No Content Found"; var currVal_1 = "Nothing to display"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_InnerHtmlIsolatedComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.SafeHtmlPipe, [i5.DomSanitizer]), i1.ɵpid(0, i6.ClearTextPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InnerHtmlIsolatedComponent_1)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InnerHtmlIsolatedComponent_2)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.content; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_InnerHtmlIsolatedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-inner-html-isolated", [], null, null, null, View_InnerHtmlIsolatedComponent_0, RenderType_InnerHtmlIsolatedComponent)), i1.ɵdid(1, 114688, null, 0, i8.InnerHtmlIsolatedComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InnerHtmlIsolatedComponentNgFactory = i1.ɵccf("app-inner-html-isolated", i8.InnerHtmlIsolatedComponent, View_InnerHtmlIsolatedComponent_Host_0, { content: "content" }, {}, []);
export { InnerHtmlIsolatedComponentNgFactory as InnerHtmlIsolatedComponentNgFactory };
