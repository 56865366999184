<div class="row clearfix prod-container" matchItemHeight="matchHeight">
  <div
    class="prodItem"
    *ngFor="let option of options"
    @ngIfAnimation
    [ngClass]="
      option.canAssist == false &&
      showDisabled == true &&
      (sessionType == 'merchant' || sessionType == 'merchant-admin')
        ? 'disable-card'
        : ''
    "
  >
    <mat-card class="prodCard rel mat-elevation-z7" [ngClass]="option['SubType']">
      <div class="ribbon" matTooltip="{{ option['Label.Web'] }}">
        <span
        ><i class="inner-ribbon">{{ option['Label.Web'] }}</i></span
        >
      </div>
      <h2 class="lending-prod-compact-h2">{{ option['Label.Marketing'] }}</h2>
      <div class="value text-center">
        <h3 class="white small from">From</h3>
        <p class="mainValue green-text rm-m">
          {{ option['BasedOnMaxDuration.Repayments.Weekly'] / 7 | number: '1.2-2' | customCurrency }}
        </p>
        <h3 class="white small per-day">Per Day</h3>
      </div>

      <div
        class="product-description matchHeight"
        [innerHtml]="utils.formatTermsAndConditions2(option['Description']) | safeHtml"
      ></div>

      <div class="deepBg"></div>

      <div class="buttons-content">
        <button
          class="btn-outline full-width"
          *ngIf="displaySimulator == true"
          (click)="simulateProduct(option)"
          mat-raised-button
          tabindex="0"
        >
          Details
          <mat-icon>search</mat-icon>
        </button>
        <button
          class="btn-outline full-width"
          *ngIf="isModal == false && isCold == false"
          (click)="selectProduct(option)"
          mat-raised-button
          color=""
          aria-hidden="true"
          tabindex="-1"
        >
          {{ selectTitle }}
          <mat-icon class="fas fa-hand-point-right"></mat-icon>
        </button>
        <button
          class="btn-outline full-width"
          *ngIf="isModal == false && isCold == true"
          (click)="selectProduct(option)"
          mat-raised-button
          color=""
          aria-hidden="true"
          tabindex="-1"
        >
          Click to view
          <mat-icon class="fas fa-hand-point-right"></mat-icon>
        </button>
        <button
          class="btn-outline full-width"
          *ngIf="isModal != false"
          (click)="newInvition()"
          mat-raised-button
          color=""
          aria-hidden="true"
          tabindex="-1"
        >
          Select
          <mat-icon class="fas fa-hand-point-right"></mat-icon>
        </button>
      </div>
    </mat-card>
  </div>
</div>
