<ng-container *ngIf="(wishlist$ | async) as wishlist; else firstLoad">
  <ng-container>
    <div class="padded">
      <div style="display: flex; justify-content: space-between">
        <ipv-data-header [counterValue]="wishlist.length" [title]="'Wishlist'"></ipv-data-header>

        <div>
          <mat-button-toggle-group class="btn-group" [(ngModel)]="category" style="height: 36px" value="cosmetic">
            <mat-button-toggle value="all"> All</mat-button-toggle>
            <mat-button-toggle value="cosmetic"> Cosmetic</mat-button-toggle>
            <mat-button-toggle value="functional"> Functional</mat-button-toggle>
          </mat-button-toggle-group>

          <button
            (click)="openWishlistItemCreateModal(wishlist)"
            color="accent"
            mat-raised-button
            style="margin-left: 30px; height: 36px"
          >
            <mat-icon> add</mat-icon>
            New {{ category }} wishlist item
          </button>
        </div>
      </div>

      <mat-card style="height: 400px; margin-top: 40px">
        <ipv-data-table
          [table]="wishlist"
          [menuTemplate]="actions"
          [hide]="['ID', 'DateTimeCreated', 'LastModified', 'LastModified_Human', 'Is_Hidden']"
          [order]="['Label', 'Code']"
          [rename]="{Code: 'Category'}"
          [sortable]="['Label']"
          [search]="category"
          [searchExceptions]="['all']"
        >
        </ipv-data-table>
      </mat-card>
    </div>
  </ng-container>

  <ng-template #actions let-item>
    <button (click)="openWishlistItemUpdateModal(item, wishlist)" mat-menu-item>
      <mat-icon class="fa fa-pen"></mat-icon>
      <span> Edit </span>
    </button>

    <button (click)="openWishlistItemDeleteModal(item, wishlist)" mat-menu-item>
      <mat-icon class="fa fa-trash"></mat-icon>
      <span> Delete </span>
    </button>
  </ng-template>
</ng-container>

<ng-template #firstLoad>
  <ipv-fetching-list-message></ipv-fetching-list-message>
</ng-template>
