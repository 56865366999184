import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-star-rating-view',
  templateUrl: './star-rating-view.component.html',
  styleUrls: ['./star-rating-view.component.css'],
})
export class StarRatingViewComponent implements OnInit {
  starList: boolean[] = [false, false, false, true, true];

  rating = 3;

  setStar(data: any) {
    this.rating = data + 1;
    for (let i = 0; i <= 4; i++) {
      if (i <= data) {
        this.starList[i] = false;
      } else {
        this.starList[i] = true;
      }
    }
  }

  constructor() {}

  ngOnInit() {}
}
