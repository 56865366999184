import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { ForgotPasswordComponent } from '../../../../common/core/authentication/forgot-password/forgot-password.component';
import { ResetUserNameComponent } from '../../../../common/core/authentication/reset-user-name/reset-user-name.component';
import { AuthenticationService } from '../../../../common/core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../../common/shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../../common/shared/components/root-component/root-component.component';
import { UtilsService } from '../../../../common/shared/services/utils.service';
import { ConfirmDialog } from '../../../../common/shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../../common/shared/types/notify-app-component';
import { Settings } from '../../../../common/shared/types/settings';
import { PasswordChangeComponent } from '../../../../common/core/authentication/password-change/password-change.component';

@Component({
  selector: 'app-login-merchant',
  // providers: [  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class LoginMerchantComponent implements OnInit {
  public loginForm: FormGroup;
  hide = true;

  redirection;
  showLogin = true;
  siteOnline = Settings.global['siteOnline'];
  settings = Settings.global;

  isSubmitted = false;
  isCurrentPractice = false;
  isCurrentAccess = false;
  isCurrentUser = false;
  googleID;
  facebookID;
  twitterID;

  currentUser;

  constructor(
    private utilService: UtilsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private dialog: MatDialog,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    AuthenticationService.updateDrift.emit('merchant');

    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required, Validators.minLength(3)]),
      password: new FormControl('', [Validators.required, Validators.minLength(3)]),
      rememberMe: new FormControl(false, []),
    });

    this.activeRoute.params.subscribe((params) => {
      if (params['redirection']) {
        this.redirection = params['redirection'];
      }

      if (Settings.global['siteOnline'] === false) {
        if (this.router.url == '/login-admin') {
          NotifyAppComponent.displayToast(
            'fas fa-info',
            'The website is Offline',
            'Please Login with an administrator account'
          );
        } else {
          this.router.navigate(['/site-offline']);
        }
      }
    });
  }

  openResetPasswordModal() {
    const ref = this.dialog.open(ForgotPasswordComponent, {
      width: '350px',
    });
    const sub = ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
  }

  openResetUserNameModal() {
    const ref = this.dialog.open(ResetUserNameComponent, {
      width: '350px',
    });
    const sub = ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
  }

  redirect() {
    if (this.isCurrentPractice == true && this.isCurrentAccess == true && this.isCurrentUser == true) {
      if (this.currentUser && this.currentUser['operatorID'] && this.currentUser.PasswordsNeedsResetting === '1') {
        this.router.navigate([
          '/merchant',
          {
            outlets: {
              page: ['password-reset-required'],
            },
          },
        ]);
      } else {
        if (this.redirection) {
          const __url = this.redirection.replace(/\$/g, '/');
          this.router.navigateByUrl(__url);
        } else {
          this.router.navigate(['/merchant/']);
        }
      }
    }
  }

  loginEvent() {
    if (
      this.isSubmitted == false &&
      this.loginForm &&
      this.loginForm.value &&
      this.loginForm.value.username &&
      this.loginForm.value.password
    ) {
      this.isSubmitted = true;
      const body = {
        userName: this.loginForm.value.username,
        password: this.loginForm.value.password,
        rememberMe: this.loginForm.value.rememberMe,
        byPassword: true,
      };
      this.authenticationService.login(body, 'merchant').subscribe(
        (res: any) => {
          this.isSubmitted = false;
          if (res) {
            this.authenticationService.setCurrentUser(res);
            this.currentUser=res;


            this.authenticationService.isLoggedIn = true;

            this.authenticationService.getCurrentPracticeForce().subscribe((r) => {
              this.isCurrentPractice = true;

              this.redirect();
            });

            this.authenticationService.getCurrentForceAccess().subscribe((r) => {
              this.isCurrentAccess = true;
              this.redirect();
            });

            this.isCurrentUser = true;
          }
        },
        (err: any) => {
          this.isSubmitted = false;
          // Log errors if any
          console.log(err);
        }
      );
    }
  }

  login(socialMedia = false) {
    this.authenticationService.isLogin().subscribe((res) => {
      if (res == true) {
        const confirm = new ConfirmDialog(
          'fas fa-info',
          'You are already Connected!',
          'We detected you have an open session, you need to logout before Proceeding to the Merchant Portal',
          'Cancel',
          'Logout and Proceed'
        );

        const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
          data: confirm,
          disableClose: true,
        });
        ref.componentInstance.onConfirm.subscribe((confirmation) => {
          if (confirmation === false) {
            ref.close();
          } else {
            this.authenticationService.logout().subscribe((res) => {
              ref.close();
              if (socialMedia == true) {
                this.loginSocialMediaEvent();
              } else {
                this.loginEvent();
              }
            });
          }
        });
      } else {
        if (socialMedia == true) {
          this.loginSocialMediaEvent();
        } else {
          this.loginEvent();
        }
      }
    });
  }

  changeHide() {
    this.hide = !this.hide;
  }

  getFileLink(fileID) {
    return environment.nodeUrl + '/files/file-view/' + fileID;
  }

  gotoSignUp() {
    window.open('https://smileright.com.au/partners/(page:apply)', '_blank', 'noopener');
  }

  loginSocialMediaEvent() {
    if (this.isSubmitted == false && (this.facebookID || this.googleID || this.twitterID)) {
      this.isSubmitted = true;
      const body = {
        facebookID: this.facebookID,
        googleID: this.googleID,
        twitterID: this.twitterID,
        rememberMe: this.loginForm.value.rememberMe,
        bySocialMedia: true,
      };
      this.authenticationService.login(body, 'merchant').subscribe(
        (res: any) => {
          this.isSubmitted = false;
          if (res) {
            this.authenticationService.setCurrentUser(res);
            this.currentUser=res;


            this.authenticationService.getCurrentPracticeForce().subscribe((r) => {
              this.isCurrentPractice = true;

              this.redirect();
            });

            this.authenticationService.getCurrentForceAccess().subscribe((r) => {
              this.isCurrentAccess = true;
              this.redirect();
            });

            this.isCurrentUser = true;
          }
        },
        (err: any) => {
          this.isSubmitted = false;
          // Log errors if any
          console.log(err);
        }
      );
    }
  }
  displayPasswordReset(currentUser) {
    if (currentUser && currentUser['operatorID'] && currentUser.PasswordsNeedsResetting === '1') {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['password-reset-required'],
          },
        },
      ]);
    }
  }
  loginBySocialMedia(e) {
    if (e && e.facebookID) {
      this.facebookID = e.facebookID;
      this.googleID = null;
      this.twitterID = null;
      this.login(true);
    } else if (e && e.googleID) {
      this.facebookID = null;
      this.googleID = e.googleID;

      this.twitterID = null;
      this.login(true);
    } else if (e && e.twitterID) {
      this.googleID = null;
      this.facebookID = null;
      this.twitterID = e.twitterID;

      this.login(true);
    } else {
      NotifyAppComponent.displayToast('error', 'Error', 'Invalid Login ');
    }
  }
}
