import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DpDatePickerModule } from 'ng2-date-picker';
import { NgxGaugeModule } from 'ngx-gauge';
import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '../../shared/shared.module';
import { OperatorModule } from '../operator/operator.module';
import { PCICarComponent } from './car/car.component';
import { PCICreditCardComponent } from './credit-card/credit-card.component';
import { PCIEmploymentBenefitComponent } from './employment-benefit/employment-benefit.component';
import { PCIFurnitureHouseholdComponent } from './furniture-household/furniture-household.component';
import { PCIHouseResidenceComponent } from './house-residence/house-residence.component';
import { PCIInterestIncomeComponent } from './interest-income/interest-income.component';
import { PCIInvestmentLoansComponent } from './investment-loans/investment-loans.component';
import { PCIInvestmentPropertyComponent } from './investment-property/investment-property.component';
import { PCILivingExpenseComponent } from './living-expense/living-expense.component';
import { PCIOtherAssetsComponent } from './other-assets/other-assets.component';
import { PCIOtherIncomeComponent } from './other-income/other-income.component';
import { PCIOtherVehicleComponent } from './other-vehicle/other-vehicle.component';
import { PCISelectComponent } from './pci-select/pci-select.component';
import { PCIDocumentUploadComponent } from './personal-circumstance-document-upload/personal-circumstance-document-upload.component';
import { PCIDocumentComponent } from './personal-circumstance-document/personal-circumstance-document.component';
import { PCIPersonalLoanComponent } from './personal-loan/personal-loan.component';
import { PersonalCircumstancesRoutingModule } from './shared/personal-circumstances-routing.module';
import { PersonalCircumstancesService } from './shared/personal-circumstances.service';
import { PCISharesComponent } from './shares/shares.component';
import { PCITaxDebtComponent } from './tax-debt/tax-debt.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    CdkTableModule,
    OperatorModule,
    NgPipesModule,
    NgxGaugeModule,
    PersonalCircumstancesRoutingModule,
    DpDatePickerModule,
  ],
  declarations: [
    PCIEmploymentBenefitComponent,
    PCICarComponent,
    PCICreditCardComponent,
    PCIFurnitureHouseholdComponent,
    PCIHouseResidenceComponent,
    PCIInterestIncomeComponent,
    PCIInvestmentLoansComponent,
    PCIInvestmentPropertyComponent,
    PCILivingExpenseComponent,
    PCIOtherAssetsComponent,
    PCIOtherIncomeComponent,
    PCIOtherVehicleComponent,
    PCIPersonalLoanComponent,
    PCISharesComponent,
    PCITaxDebtComponent,
    PCIDocumentComponent,
    PCIDocumentUploadComponent,
    PCISelectComponent,
  ],
  exports: [
    PCIEmploymentBenefitComponent,
    PCICarComponent,
    PCICreditCardComponent,
    PCIFurnitureHouseholdComponent,
    PCIHouseResidenceComponent,
    PCIInterestIncomeComponent,
    PCIInvestmentLoansComponent,
    PCIInvestmentPropertyComponent,
    PCILivingExpenseComponent,
    PCIOtherAssetsComponent,
    PCIOtherIncomeComponent,
    PCIOtherVehicleComponent,
    PCIPersonalLoanComponent,
    PCISharesComponent,
    PCITaxDebtComponent,
    PCIDocumentComponent,
    PCIDocumentUploadComponent,
    PCISelectComponent,
  ],
  providers: [PersonalCircumstancesService],
})
export class PersonalCircumstancesModule {}
