import { HttpClient } from '@angular/common/http';
import { ComponentStore } from '@ngrx/component-store';
import { environment } from 'environments/environment';
import { of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AppStateService } from '../../../../core/app-state/app-state.service';
import { UtilsService } from '../../../../shared/services/utils.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/services/utils.service";
import * as i2 from "@angular/common/http";
import * as i3 from "../../../../core/app-state/app-state.service";
class ProductCustomerState {
}
export class ProductCustomer {
}
export class ProductCustomerQuery {
}
export class ProductCustomerService extends ComponentStore {
    constructor(utilsService, httpClient, appStateService) {
        super({
            productCustomers: [],
            customerCalculatedNameFilter: '',
            customerEmailFilter: '',
            productLabelFilter: '',
        });
        this.utilsService = utilsService;
        this.httpClient = httpClient;
        this.appStateService = appStateService;
        this.baseUrl = environment.nodeUrl;
    }
    getProductCustomers() {
        return this.get().productCustomers;
    }
    getProductCustomers$() {
        return this.select((state) => state.productCustomers);
    }
    setProductCustomers(productCustomers) {
        this.patchState({ productCustomers });
    }
    fetchProductCustomers$(payload) {
        return this.appStateService.getAppState$().pipe(switchMap((appState) => {
            const params = this.utilsService.getHttpParamsFromPayload(payload);
            const options = { params };
            const endpoint = appState.isPromoterOrAdmin ? 'product-customers/global' : 'product-customers';
            const url = `${this.baseUrl}/${endpoint}`;
            return this.httpClient.get(url, options).pipe(map((response) => response['data']));
        }));
    }
    createProductCustomer$(payload) {
        return this.appStateService.getAppState$().pipe(switchMap((appState) => {
            const endpoint = appState.isPromoterOrAdmin ? 'product-customers/global' : 'product-customers';
            const url = `${this.baseUrl}/${endpoint}`;
            return this.httpClient.post(url, payload).pipe(map((response) => response.data));
        }));
    }
    updateProductCustomer$(item, payload) {
        return this.appStateService.getAppState$().pipe(switchMap((appState) => {
            const endpoint = appState.isPromoterOrAdmin
                ? `product-customers/global/${item.ID}`
                : `product-customers/${item.ID}`;
            const url = `${this.baseUrl}/${endpoint}`;
            return this.httpClient.put(url, payload).pipe(map((response) => response.data));
        }));
    }
    deleteProductCustomer$(invoice) {
        return this.appStateService.getAppState$().pipe(take(1), switchMap((appState) => {
            const endpoint = appState.isPromoterOrAdmin
                ? `product-customers/global/${invoice.ID}`
                : `product-customers/${invoice.ID}`;
            const url = `${this.baseUrl}/${endpoint}`;
            return this.httpClient.delete(url).pipe(map((response) => response.data));
        }));
    }
    addProductCustomer$(itemWithID) {
        const currentItems = this.get().productCustomers;
        const items = [...currentItems, itemWithID];
        this.patchState({ productCustomers: items });
        return of(itemWithID);
    }
    editProductCustomer$(itemName) {
        const items = this.get().productCustomers;
        const targetIndex = items.findIndex((item) => itemName.ID === item.ID);
        items[targetIndex] = itemName;
        this.patchState({ productCustomers: items });
        return of(itemName);
    }
    removeProductCustomer$(itemName) {
        // Remove item from local list
        const currentItems = this.get().productCustomers;
        const items = currentItems.filter((item) => itemName.ID !== item.ID);
        this.patchState({ productCustomers: items });
        return of(null);
    }
    getCustomerCalculatedNameFilter$() {
        return this.select((state) => state.customerCalculatedNameFilter);
    }
    setCustomerCalculatedNameFilter(customerCalculatedNameFilter) {
        this.patchState({ customerCalculatedNameFilter });
    }
    getCustomerEmailFilter$() {
        return this.select((state) => state.customerEmailFilter);
    }
    setCustomerEmailFilter(customerEmailFilter) {
        this.patchState({ customerEmailFilter });
    }
    getCustomerProductLabelFilter$() {
        return this.select((state) => state.productLabelFilter);
    }
    setCustomerProductLabelFilter(productLabelFilter) {
        this.patchState({ productLabelFilter });
    }
}
ProductCustomerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProductCustomerService_Factory() { return new ProductCustomerService(i0.ɵɵinject(i1.UtilsService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.AppStateService)); }, token: ProductCustomerService, providedIn: "root" });
