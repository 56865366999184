<div class="rel clearfix lookup">
  <div class="card-header primary-gradient-img clearfix inModal">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <mat-icon class="fas fa-envelope-open-text pull-left mr main-icon-brush "
          style=" font-size: 2.8rem; margin-top: 10px; width: 70px;"></mat-icon>
        <h2 class="summary-header rm-m" *ngIf="viewOnClick!=true">
          {{ title }}
          <span class="subLabel" [innerHTML]="description | safeHtml"></span>
        </h2>
        <h2 class="summary-header rm-m" *ngIf="viewOnClick == true">
          Message Template
          <span class="subLabel">
            Below is a list of Message Templates available. Click to view the template, or if you are feeling creative
            press the 'create your own' button.
          </span>
        </h2>
      </div>
    </div>
  </div>

  <div class="clearfix flex flex-center">
    <mat-form-field class="full-width mr" appearance="outline">
      <mat-label>Search</mat-label>
      <input matInput name="search" placeholder="Search by Title, Description, Message" [(ngModel)]="filterData"
        (ngModelChange)="backToOne()" tabIndex="1">
    </mat-form-field>


    <mat-form-field class=" half-width mr" appearance="outline" *ngIf="tags.length > 0">
      <mat-label>Tags</mat-label>
      <mat-select name="Tag" class="" placeholder="Tags" [(ngModel)]="selectedTags" multiple>
        <mat-option *ngFor="let a of tags       | orderBy" [value]="a">
          {{a}}
        </mat-option>
      </mat-select>
    </mat-form-field>


    <mat-form-field class="half-width mr " *ngIf="purposeCodesFilter && purposeCodesFilter.length>0"
      appearance="outline">
      <mat-label>Message Purpose</mat-label>
      <mat-select name="purposeCode" placeholder="Message Purpose" [(ngModel)]="purposeCode" class="full-width">
        <mat-option *ngFor="let p of (purposeCodesFilter  | OrderArrayObject :  ['Purpose.Label'] )"
          [value]="p['Purpose.Code']">
          {{p['Purpose.Label']}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-icon-button *ngIf="isCollapsed==true" class="sml actionMenuToggle  " matTooltip="Open all content "
      (click)="collapse(false)">
      <mat-icon class="fas fa-window-maximize"></mat-icon>
    </button>

    <button mat-icon-button *ngIf="isCollapsed!=true" class="sml actionMenuToggle  " matTooltip="Close all  content"
      (click)="collapse(true)">
      <mat-icon class="fas fa-window-minimize"></mat-icon>
    </button>

    <button class="sml actionMenuToggle" [matMenuTriggerFor]="menu" mat-icon-button>
      <mat-icon class="fas fa-sort"></mat-icon>
    </button>

    <mat-menu #menu="matMenu">

      <button mat-menu-item [ngClass]="{'accent-color': (orderByFilter==='Label' || orderByFilter==='-Label')}"
        (click)="orderBy('Label')">
        <span>Label</span>
        <span *ngIf="orderByFilter === 'Label' || orderByFilter === '-Label'">
          <mat-icon class="accent-color" *ngIf="orderByFilter === '-Label'">arrow_drop_up</mat-icon>
          <mat-icon class="accent-color" *ngIf="orderByFilter !== '-Label'">arrow_drop_down</mat-icon>
        </span>
      </button>

      <button [ngClass]="{'accent-color': (orderByFilter==='DateTimeCreated' || orderByFilter==='-DateTimeCreated')}"
        mat-menu-item (click)="orderBy('-DateTimeCreated')">
        <span>Date</span>
        <span *ngIf="orderByFilter === 'DateTimeCreated' || orderByFilter === '-DateTimeCreated'">
          <mat-icon class="accent-color" *ngIf="orderByFilter === '-DateTimeCreated'">arrow_drop_down</mat-icon>
          <mat-icon class="accent-color" *ngIf="orderByFilter !== '-DateTimeCreated'"> arrow_drop_up</mat-icon>
        </span>
      </button>
    </mat-menu>
  </div>
  <div class="clearfix smt smb flex flex-center">
    <mat-button-toggle-group class="btn-group rm-m selectType full-width  flex tab" [(ngModel)]="typeFilter"
      (ngModelChange)="changeTab()" value="null">

      <mat-button-toggle [value]="null" class="full-width" *ngIf="!type">

        <span class="badge master" *ngIf="messageTemplates " countUp [endVal]="( messageTemplates  | FilterArrayMultipleOrContain :'search' : filterData | FilterArrayMultipleValue :'tags' : selectedTags  | FilterArrayMultipleOrContain :'Purpose.Code' : purposeCode   | FilterArrayMultipleValue: ['type']:
            null | lengthOfArray ) || 0"></span>
        All
      </mat-button-toggle>

      <mat-button-toggle [value]="'user'" class="full-width" *ngIf="!type || type=='user'">

        <span class="badge master" *ngIf="messageTemplates " countUp [endVal]="( messageTemplates  | FilterArrayMultipleOrContain :'search' : filterData | FilterArrayMultipleValue :'tags' : selectedTags   | FilterArrayMultipleOrContain :'Purpose.Code' : purposeCode  | FilterArrayMultipleValue: ['type']:
            'user' | lengthOfArray ) || 0"></span>
            {{ "KEYWORD.practice" | translate }}
        <mat-icon class="fas fa-user-alt"></mat-icon>
      </mat-button-toggle>

      <mat-button-toggle [value]="'default'" class="full-width" *ngIf="!type || type=='default'">

        <span class="badge master" *ngIf="messageTemplates " countUp [endVal]="( messageTemplates  | FilterArrayMultipleOrContain :'search' : filterData  | FilterArrayMultipleValue :'tags' : selectedTags  | FilterArrayMultipleOrContain :'Purpose.Code' : purposeCode  | FilterArrayMultipleValue: ['type']:
            'default' | lengthOfArray ) || 0"></span> {{"BRANDING.Brand Name" | translate}}
        <mat-icon class="fas {{'fa-tooth' | translate}}">
        </mat-icon>

      </mat-button-toggle>

      <mat-button-toggle [value]="'promoter'" class="full-width"
        *ngIf="( !type || type=='promoter') && isPromoterOrAdmin==true">

        <span class="badge master" *ngIf="messageTemplates " countUp [endVal]="( messageTemplates  | FilterArrayMultipleOrContain :'search' : filterData | FilterArrayMultipleValue :'tags' : selectedTags   | FilterArrayMultipleOrContain :'Purpose.Code' : purposeCode  | FilterArrayMultipleValue: ['type']:
            'promoter' | lengthOfArray ) || 0"></span>
        Promoter
        <mat-icon class="fas fa-user-shield"></mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <mat-dialog-content class="lookup-messageTemplate" [ngClass]="{ 'no-modal-height': isModal != true }">
    <div class="full-width"
         *ngIf="loadContact == true && loadPromoter == true && loadMerchant == true && loadCard == true">
      <div class="patientContainer">
        <div
          *ngFor="let messageTemplate of messageTemplates | FilterArrayMultipleOrContain :'search' : filterData | FilterArrayMultipleValue :'tags' : selectedTags   | FilterArrayMultipleOrContain :'Purpose.Code' : purposeCode  | FilterArrayMultipleValue: ['type']:
          typeFilter |  OrderArrayObject : [orderByFilter]  | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }"
          class="patientRecord row clearfix">

          <div class="full-width clearfix expend-container">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="profile-result full-width flex" (click)="selectMessageTemplate(messageTemplate)">
                      <!-- avatar image -->
                      <span class="chipDefault pull-left primary-bg  mr "
                            matTooltip="  {{'BRANDING.Brand Name' | translate | titlecase}} Template"
                        *ngIf="messageTemplate && messageTemplate.type && messageTemplate.type=='default'">
                        <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
                      </span>


                      <span class="chipPromoter pull-left primary-bg  mr "
                            matTooltip="  {{'BRANDING.Brand Name' | translate | titlecase}} Template"
                        *ngIf="messageTemplate && messageTemplate.type && messageTemplate.type=='promoter'">
                        <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
                      </span>

                      <span class="chipUser pull-left  mr " matTooltip="Practice Template"
                        *ngIf="messageTemplate && messageTemplate.type && messageTemplate.type!='default' && messageTemplate.type!='promoter'">
                        <mat-icon class="fas fa-user-alt"></mat-icon>
                      </span>

                      <div class="pull-left full-width truncate-normal">

                        <div class="full-width ">


                          <h4 class="sr-title rm-m mr pull-left" [ngClass]="
                          (messageTemplate && messageTemplate.type && messageTemplate.type=='user')?'title-practice':(messageTemplate && messageTemplate.type && messageTemplate.type=='promoter')?'title-promoter':''


                          ">
                            <strong class="mr">
                              {{ messageTemplate['Label']}}</strong>
                            <span class="value chip-new pull-right"
                              *ngIf="isNew(messageTemplate['DateTimeCreated'])==true ">NEW</span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="flex ">

                  <div class="profile-result full-width flex ">
                    <div class="pull-left full-width truncate-normal patientRecord-inner"
                      (click)="selectMessageTemplate(messageTemplate)">


                      <message-input class="full-width  message-input-component2" [isModal]="false"
                        [displayMore]="displayMore" [maxLength]="120" [isCollapsed]="isCollapsed"
                        [message]="messageTemplate['Content'] " [showAllCustomization]="true" [card]="card"
                        [promoter]="promoter" [merchant]="merchant" [contact]="contact" [textLength]="0"
                        [displayEmoji]="false" [displayMessageTemplate]="false" [contact]="contact"
                        [displayPatient]="false" [displayContact]="false" [displayMerchant]="false"
                        [displayPromoter]="false" [displayMarketing]="false" [displayExpend]="false"
                        [displayDentalOffers]="false" [displayDocument]="false" [saveAsTemplate]="false"
                        [isSMSPreview]="true" [isDesktopPreview]="true" [displaySaveAsTemplate]="false" [encode]="false"
                        [isView]="true" [isMessageConverted]="true" [tableName]="tableName">
                      </message-input>

                      <mat-chip-list class="full-width template-tag-lookup">
                        <mat-chip *ngFor="let tag of messageTemplate['tags']">{{tag}}
                        </mat-chip>
                      </mat-chip-list>


                      <p class="small full-width  purpose-label-text smt" *ngIf="messageMode!='sms'">
                        <strong>{{messageTemplate['Purpose.Label']}}</strong>

                        <strong class="sml" *ngIf="messageTemplate['ProductGroup.Code']">
                          ({{ productUtil.getLabel(messageTemplate['ProductGroup.Code']) }})
                        </strong>
                      </p>

                      <div class="full-width clearfix smt">
                        <p class="small rm-m date_label grey pull-right" *ngIf="messageTemplate.DateTimeCreated">
                          {{ messageTemplate.DateTimeCreated | TimeLabelPast }} ago
                        </p>
                      </div>
                    </div>


                    <button color="primary" *ngIf="messageTemplate && messageTemplate['ID'] " mat-icon-button
                      matTooltip="View Template Details" class="messageTemplate-view-button"
                      [matMenuTriggerFor]="previewMenu" (click)="$event.stopPropagation();">
                      <mat-icon>more_vert</mat-icon>
                    </button>

                    <mat-menu #previewMenu="matMenu">
                      <button (click)="view(messageTemplate['ID'])" mat-menu-item>
                        <mat-icon>visibility</mat-icon>
                        <span>View</span>
                      </button>
                      <button mat-menu-item
                        *ngIf="(messageTemplate &&   messageTemplate['IsPromoterTemplate']!='1' &&    messageTemplate['Purpose.Code']!='PromSMS' )
                                || (   messageTemplate &&  (  messageTemplate['Purpose.Code']=='PromSMS'  ||  messageTemplate['IsPromoterTemplate']=='1' ) && isPromoterOrAdmin==true )"
                        (click)="editMessageTemplate(messageTemplate['ID'])">
                        <mat-icon>edit</mat-icon>
                        <span>Edit</span>
                      </button>

                      <button (click)="clone(messageTemplate)" mat-menu-item>
                        <mat-icon>content_copy</mat-icon>
                        <span>Clone</span>
                      </button>

                      <button mat-menu-item
                        *ngIf="(messageTemplate &&   messageTemplate['IsPromoterTemplate']!='1' &&    messageTemplate['Purpose.Code']!='PromSMS' )
                                || (   messageTemplate &&  (  messageTemplate['Purpose.Code']=='PromSMS'  ||  messageTemplate['IsPromoterTemplate']=='1' ) && isPromoterOrAdmin==true )"
                        (click)="delete(messageTemplate['ID'])">
                        <mat-icon>delete</mat-icon>
                        <span>Delete</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>

          <div class="flex full-width clearfix mt mb normal-container">
            <div class="profile-result full-width flex">
              <!-- avatar image -->

              <span class="chipDefault pull-left primary-bg  mr "
                    matTooltip="  {{'BRANDING.Brand Name' | translate | titlecase}} Template"
                *ngIf="messageTemplate && messageTemplate.type && messageTemplate.type=='default'"
                (click)="selectMessageTemplate(messageTemplate)">
                <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
              </span>


              <span class="chipPromoter pull-left  mr "
                    matTooltip="  {{'BRANDING.Brand Name' | translate | titlecase}} Template"
                *ngIf="messageTemplate && messageTemplate.type && messageTemplate.type=='promoter'"
                (click)="selectMessageTemplate(messageTemplate)">
                <mat-icon class="fas fa-user-shield"></mat-icon>
              </span>

              <span class="chipUser pull-left  mr " matTooltip="Practice Template"
                *ngIf="messageTemplate && messageTemplate.type && messageTemplate.type!='default' && messageTemplate.type!='promoter'"
                (click)="selectMessageTemplate(messageTemplate)">
                <mat-icon class="fas fa-user-alt"></mat-icon>
              </span>

              <!-- profile information -->
              <div class="pull-left full-width truncate-normal" (click)="selectMessageTemplate(messageTemplate)">
                <div class="full-width mb">
                  <h3 class="sr-title rm-m pull-left" [ngClass]="
                      messageTemplate && messageTemplate.type && messageTemplate.type == 'user'
                        ? 'title-practice'
                        : messageTemplate && messageTemplate.type && messageTemplate.type == 'promoter'
                        ? 'title-promoter'
                        : ''
                    ">
                    <strong class="mr"> {{ messageTemplate['Label'] }}</strong>



                    <span class="value chip-new pull-right"
                      *ngIf="isNew(messageTemplate['DateTimeCreated'])==true ">NEW</span>


                  </h3>
                </div>

                <message-input class="full-width   message-input-component2" [isModal]="false"
                  [displayMore]="displayMore" [isCollapsed]="isCollapsed" [message]="messageTemplate['Content'] "
                  [showAllCustomization]="true" [card]="card" [promoter]="promoter" [merchant]="merchant"
                  [contact]="contact" [textLength]="0" [displayEmoji]="tfalserue" [displayMessageTemplate]="false"
                  [maxLength]="120" [contact]="contact" [displayPatient]="false" [displayContact]="false"
                  [displayMerchant]="false" [displayPromoter]="false" [displayMarketing]="false" [displayExpend]="false"
                  [displayDentalOffers]="false" [displayDocument]="false" [saveAsTemplate]="false" [isSMSPreview]="true"
                  [isDesktopPreview]="true" [displaySaveAsTemplate]="false" [encode]="false" [isView]="true"
                  [isMessageConverted]="true" [tableName]="tableName">
                </message-input>


                <mat-chip-list class="full-width template-tag-lookup">
                  <mat-chip *ngFor="let tag of messageTemplate['tags']">{{tag}}
                  </mat-chip>
                </mat-chip-list>


                <p class="small full-width   purpose-label-text smt" *ngIf="messageMode!='sms'">
                  <strong>{{messageTemplate['Purpose.Label']}}</strong>

                  <strong class="sml" *ngIf="messageTemplate['ProductGroup.Code']">
                    ({{ productUtil.getLabel(messageTemplate['ProductGroup.Code']) }})
                  </strong>
                </p>
                <div class="full-width clearfix smt">
                  <p class="small rm-m date_label grey pull-right" *ngIf="messageTemplate.DateTimeCreated">
                    {{ messageTemplate.DateTimeCreated | TimeLabelPast }} ago
                  </p>
                </div>
              </div>


              <button color="primary" *ngIf="messageTemplate && messageTemplate['ID'] " mat-icon-button
                matTooltip="View Template Details" class="messageTemplate-view-button" [matMenuTriggerFor]="previewMenu"
                (click)="$event.stopPropagation();">
                <mat-icon>more_vert</mat-icon>
              </button>

              <mat-menu #previewMenu="matMenu">
                <button (click)="view(messageTemplate['ID'])" mat-menu-item>
                  <mat-icon>visibility</mat-icon>
                  <span>View</span>
                </button>


                <button mat-menu-item
                  *ngIf="(messageTemplate &&   messageTemplate['IsPromoterTemplate']!='1' &&    messageTemplate['Purpose.Code']!='PromSMS' )
                          || (   messageTemplate &&  (  messageTemplate['Purpose.Code']=='PromSMS'  ||  messageTemplate['IsPromoterTemplate']=='1' ) && isPromoterOrAdmin==true )"
                  (click)="editMessageTemplate(messageTemplate['ID'])">
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>

                <button (click)="clone(messageTemplate)" mat-menu-item>
                  <mat-icon>content_copy</mat-icon>
                  <span>Clone</span>
                </button>


                <button mat-menu-item
                  *ngIf="(messageTemplate &&   messageTemplate['IsPromoterTemplate']!='1' &&    messageTemplate['Purpose.Code']!='PromSMS' )
                         || (   messageTemplate &&  (  messageTemplate['Purpose.Code']=='PromSMS'  ||  messageTemplate['IsPromoterTemplate']=='1' ) && isPromoterOrAdmin==true )"
                  (click)="delete(messageTemplate['ID'])">
                  <mat-icon>delete</mat-icon>
                  <span>Delete</span>
                </button>
              </mat-menu>
            </div>
          </div>

          <mat-divider></mat-divider>
        </div>

        <div class="full-width " *ngIf="(messageTemplates | FilterArrayMultipleOrContain :'search' : filterData| FilterArrayMultipleValue :'tags' : selectedTags  | FilterArrayMultipleOrContain :'Purpose.Code' : purposeCode  | FilterArrayMultipleValue: ['type']:
        typeFilter |  OrderArrayObject : [orderByFilter] | lengthOfArray)<=0 && isLoaded==true">

          <app-empty-list-message [message]="'No Template found'"></app-empty-list-message>
        </div>
      </div>

      <div *ngIf="(messageTemplates | FilterArrayMultipleOrContain :'search' : filterData | FilterArrayMultipleValue :'tags' : selectedTags  | FilterArrayMultipleOrContain :'Purpose.Code' : purposeCode | FilterArrayMultipleValue: ['type']:
      typeFilter |  OrderArrayObject : [orderByFilter] | lengthOfArray)>20 "
        class="full-width text-center  page-container  flex ">

        <div class="ml qtr-width  flex pull-left ">
          <mat-label class="line-h">Page size</mat-label>
          <mat-select class="page-size-in line-h pull-right" [(ngModel)]="itemsPerPage" placeholder="Page size">
            <mat-option [value]="20"> 20</mat-option>
            <mat-option [value]="30"> 30</mat-option>
            <mat-option [value]="40"> 40</mat-option>
            <mat-option [value]="50"> 50</mat-option>
          </mat-select>
        </div>
        <pagination-controls class="full-width clearfix" (pageChange)="changePage($event)" responsive="true">
        </pagination-controls>
      </div>
      <br/>
      <br/>
    </div>
  </mat-dialog-content>
  <hr class=" messageTemplate-bottom-divider ">
  <div class="drop-button   full-width text-centerclearfix">

    <button mat-raised-button color="accent" *ngIf="createNew==true"
      class="inline btn-new-invite selectExistingPatient  pull-right  btn" (click)="newTemplate()">
      <mat-icon>add</mat-icon>
      <span>Create your own</span>
    </button>


    <button mat-raised-button color="primary" *ngIf="createNew==true"
      class="inline btn-new-invite selectExistingPatient pull-left  btn" (click)="closeEvent()">Close
    </button>

    <button mat-raised-button color="primary" *ngIf="createNew!=true"
      class="inline btn-new-invite selectExistingPatient   btn" (click)="closeEvent()">Close
    </button>


    <button mat-raised-button color="primary" *ngIf="isHeader==true"
      class="inline ml btn-new-invite selectExistingPatient pull-left btn" (click)="list()">Template List
    </button>
  </div>
</div>
