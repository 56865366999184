<!-- header -->
<div class="clearfix full-width" @simpleFadeAnimation>
  <form #resetForm="ngForm">
    <div class="card-header primary-gradient-img clearfix inModal">
      <!-- close modal -->
      <div class="row clearfix">
        <button class="btn-close btn-clear mat-button" (click)="closeEvent()">
          <mat-icon>clear</mat-icon>
        </button>
        <div class="row titleArea clearfix">
          <h2 class="summary-header rm-m">
            Insert security code
            <span class="subLabel">For security Reasons we need to confirm your credentials</span>
          </h2>
        </div>
      </div>
    </div>
    <!-- content area -->
    <mat-dialog-content>
      <ng-container *ngIf="codeSent != true && user" @simpleFadeAnimation>
        <app-security-code-info
          [mobileNumber]="user['mobiles.Number']"
          [name]="user['CalculatedName']"
          [isModal]="false"
          (onConfirm)="onConfirmSend($event)"
        >
        </app-security-code-info>
      </ng-container>

      <ng-container *ngIf="codeSent == true && securityRecord" @simpleFadeAnimation>
        <app-security-code-input
          [title]="title"
          [subTitle]="subTitle"
          [displayRememberMe]="false"
          [validMinutes]="securityRecord['MinutesValid']"
          [isModal]="false"
          (getCode)="getCode($event)"
          (sendCode)="sendCode($event)"
        >
        </app-security-code-input>
      </ng-container>
    </mat-dialog-content>

    <div class="drop-button text-center full-width">
      <button class="" *ngIf="isModal == true" (click)="closeEvent()" mat-raised-button color="primary">Cancel</button>
    </div>
  </form>
</div>
