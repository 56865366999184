<div class="cons {{ context }}" id="blueBg"></div>

<ng-container *ngIf="hideContent == false && customInv && customInv.invitation" [class.abso]="hasResult == true">
  <app-patient-card
    *ngIf="context != 'retail' && isModuleQuotebuilderActive == false"
    [invitationID]="invitationID"
    [customerInvitation]="customInv.invitation"
    [emailActions]="actions"
    [viewOnly]="viewOnly"
    [merchantInformedConsent]="merchantInformedConsent"
    [dentistAppointmentUrl]="thankyouThirdPartyBookingURL"
    [class.abso]="hasResult == true"
    [accessByMerchant]="accessByMerchant"
    (consumerChoice)="consumerChoice($event)"
    (deferedWeek)="deferedWeek($event)"
  >
  </app-patient-card>

  <app-patient-card-quotation
    *ngIf="context != 'retail' && isModuleQuotebuilderActive == true"
    [viewOnly]="viewOnly"
    [invitationID]="invitationID"
    [customerInvitation]="customInv.invitation"
    [class.abso]="hasResult == true"
    (consumerChoice)="consumerChoice($event)"
    (deferedWeek)="deferedWeek($event)"
  >
  </app-patient-card-quotation>

  <app-invitation-card
    *ngIf="context == 'retail'"
    [invitationID]="invitationID"
    [viewOnly]="viewOnly"
    [customerInvitation]="customInv.invitation"
    [class.abso]="hasResult == true"
    (consumerChoice)="consumerChoice($event)"
    (deferedWeek)="deferedWeek($event)"
  >
  </app-invitation-card>
</ng-container>

<div class="result-container" *ngIf="hideContent != false">
  <app-thank-you
    class="thankYou clearFix"
    *ngIf="isProceed == true"
    @simpleFadeAnimation
    [canLogin]="!accessByMerchant"
    [isCountDown]="!accessByMerchant"
    [typeOfCard]="status"
    [invitationID]="ID"
    [merchantID]="merchantID"
    [weeks]="weeksDefered"
    [firstName]="thankYouFirstName"
    [merchantPhone]="thankYouMerchantPhone"
    [merchantEmail]="thankYouMerchantEmail"
    [merchantName]="thankYouMerchantName"
    [dentistPhone]="thankYouDentistPhone"
    [dentistEmail]="thankYouDentistEmail"
    [dentistName]="thankYouDentistName"
    [hasBookedAppointment]="thankyouHasBookedAppointment"
    [displayViewTreatment]="true"
    (proceedToWakanda)="proceedToWakanda($event)"
    (viewTreatment)="viewTreatment($event)"
  >
  </app-thank-you>

  <!-- DEFER -->
  <app-thank-you
    class="thankYou clearFix"
    *ngIf="isDefered == true"
    @simpleFadeAnimation
    [canLogin]="!accessByMerchant"
    [typeOfCard]="'defer'"
    [invitationID]="ID"
    [merchantID]="merchantID"
    [weeks]="weeksDefered"
    [firstName]="thankYouFirstName"
    [merchantPhone]="thankYouMerchantPhone"
    [merchantEmail]="thankYouMerchantEmail"
    [merchantName]="thankYouMerchantName"
    [dentistPhone]="thankYouDentistPhone"
    [dentistEmail]="thankYouDentistEmail"
    [dentistName]="thankYouDentistName"
    [displayViewTreatment]="true"
    [hasBookedAppointment]="thankyouHasBookedAppointment"
    (viewTreatment)="viewTreatment($event)"
  >
  </app-thank-you>

  <!-- NOT PROCEED -->
  <app-thank-you
    class="thankYou clearFix"
    *ngIf="isNotProceed == true"
    @simpleFadeAnimation
    [canLogin]="!accessByMerchant"
    [typeOfCard]="'dontProceed'"
    [invitationID]="ID"
    [merchantID]="merchantID"
    [firstName]="thankYouFirstName"
    [merchantPhone]="thankYouMerchantPhone"
    [merchantEmail]="thankYouMerchantEmail"
    [merchantName]="thankYouMerchantName"
    [dentistPhone]="thankYouDentistPhone"
    [dentistEmail]="thankYouDentistEmail"
    [dentistName]="thankYouDentistName"
    [displayViewTreatment]="true"
    [hasBookedAppointment]="thankyouHasBookedAppointment"
    (viewTreatment)="viewTreatment($event)"
  >
  </app-thank-you>

  <!-- REQUEST CHANGES -->
  <app-thank-you
    class="thankYou clearFix"
    *ngIf="isNotProceedApllicationChange == true"
    @simpleFadeAnimation
    [canLogin]="!accessByMerchant"
    [typeOfCard]="'applicationChange'"
    [invitationID]="ID"
    [merchantID]="merchantID"
    [firstName]="thankYouFirstName"
    [merchantPhone]="thankYouMerchantPhone"
    [merchantEmail]="thankYouMerchantEmail"
    [merchantName]="thankYouMerchantName"
    [dentistPhone]="thankYouDentistPhone"
    [dentistEmail]="thankYouDentistEmail"
    [dentistName]="thankYouDentistName"
    [displayViewTreatment]="true"
    [hasBookedAppointment]="thankyouHasBookedAppointment"
    (viewTreatment)="viewTreatment($event)"
  >
  </app-thank-you>

  <!-- PROCEED, PAYING WITH CASH -->
  <app-thank-you
    class="thankYou clearFix"
    *ngIf="isNotProceedNoFinance == true"
    @simpleFadeAnimation
    [canLogin]="!accessByMerchant"
    [typeOfCard]="'proceedCash'"
    [invitationID]="ID"
    [merchantID]="merchantID"
    [firstName]="thankYouFirstName"
    [merchantPhone]="thankYouMerchantPhone"
    [merchantEmail]="thankYouMerchantEmail"
    [merchantName]="thankYouMerchantName"
    [dentistPhone]="thankYouDentistPhone"
    [dentistEmail]="thankYouDentistEmail"
    [title]="'We appreciate you using our services!.'"
    [dentistName]="thankYouDentistName"
    [dentistAppointmentUrl]="thankyouThirdPartyBookingURL"
    [hasBookedAppointment]="thankyouHasBookedAppointment"
    [displayViewTreatment]="true"
    (viewTreatment)="viewTreatment($event)"
  >
  </app-thank-you>

  <!-- PROCEED WITH DISCOUNT -->
  <app-thank-you
    class="thankYou clearFix"
    *ngIf="isNotProceedNoFinanceWithDiscount == true"
    @simpleFadeAnimation
    [canLogin]="!accessByMerchant"
    [typeOfCard]="'proceedDiscount'"
    [invitationID]="ID"
    [merchantID]="merchantID"
    [firstName]="thankYouFirstName"
    [merchantPhone]="thankYouMerchantPhone"
    [merchantEmail]="thankYouMerchantEmail"
    [merchantName]="thankYouMerchantName"
    [dentistPhone]="thankYouDentistPhone"
    [dentistAppointmentUrl]="thankyouThirdPartyBookingURL"
    [hasBookedAppointment]="thankyouHasBookedAppointment"
    [title]="'We appreciate you using our services!.'"
    [dentistEmail]="thankYouDentistEmail"
    [dentistName]="thankYouDentistName"
    [displayViewTreatment]="true"
    (viewTreatment)="viewTreatment($event)"
  >
  </app-thank-you>

  <!-- NORMAL -->
  <app-thank-you
    class="thankYou clearFix"
    *ngIf="isValidCard == false"
    @simpleFadeAnimation
    [canLogin]="!accessByMerchant"
    [typeOfCard]="'thankyou'"
    [invitationID]="ID"
    [firstName]="thankYouFirstName"
    [merchantPhone]="thankYouMerchantPhone"
    [merchantEmail]="thankYouMerchantEmail"
    [merchantName]="thankYouMerchantName"
    [dentistPhone]="thankYouDentistPhone"
    [dentistEmail]="thankYouDentistEmail"
    [dentistName]="thankYouDentistName"
    [displayViewTreatment]="true"
    [dentistAppointmentUrl]="thankyouThirdPartyBookingURL"
    [hasBookedAppointment]="thankyouHasBookedAppointment"
    (viewTreatment)="viewTreatment($event)"
  >
  </app-thank-you>

  <!-- ARCHIVED -->
  <app-thank-you
    class="thankYou clearFix"
    *ngIf="isRemoved == true"
    @simpleFadeAnimation
    [canLogin]="!accessByMerchant"
    [typeOfCard]="'expiredRemoved'"
    [firstName]="thankYouFirstName"
    [merchantID]="merchantID"
    [invitationID]="ID"
    [dentistID]="dentistID"
    [merchantPhone]="thankYouMerchantPhone"
    [displayViewTreatment]="true"
    [merchantEmail]="thankYouMerchantEmail"
    [merchantName]="thankYouMerchantName"
    [dentistPhone]="thankYouDentistPhone"
    [dentistEmail]="thankYouDentistEmail"
    [title]="'Your invitation from your ' + ('KEYWORD.dentist' | translate) + ' looks to have expired.'"
    [hasBookedAppointment]="thankyouHasBookedAppointment"
    (viewTreatment)="viewTreatment($event)"
  >
  </app-thank-you>

  <!-- EXPIRED -->
  <app-thank-you
    class="thankYou clearFix"
    *ngIf="isExpired == true"
    @simpleFadeAnimation
    [canLogin]="!accessByMerchant"
    [typeOfCard]="'expiredRemoved'"
    [firstName]="thankYouFirstName"
    [merchantID]="merchantID"
    [invitationID]="ID"
    [dentistID]="dentistID"
    [merchantPhone]="thankYouMerchantPhone"
    [displayViewTreatment]="true"
    [merchantEmail]="thankYouMerchantEmail"
    [merchantName]="thankYouMerchantName"
    [dentistPhone]="thankYouDentistPhone"
    [dentistEmail]="thankYouDentistEmail"
    [title]="'Your invitation from your ' + ('KEYWORD.dentist' | translate) + ' looks to have expired.'"
    [hasBookedAppointment]="thankyouHasBookedAppointment"
    (viewTreatment)="viewTreatment($event)"
  >
  </app-thank-you>
</div>
