<div class="rel customerModal">
  <mat-card class="customer-summary" *ngIf="customer" @onChangeAnimation>
    <div class="animate">
      <!-- if patient has profile picture set, use as background image for mat-card-header -->
      <div class="card-header primary-gradient-img clearfix inModal">
        <div class="row clearfix">
          <!-- Modal dismiss button -->
          <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeEvent()">
            <mat-icon>clear</mat-icon>
          </button>
          <!-- Customer calculated name -->

          <!-- <h2 class="summary-header sr-title white"></h2> -->

          <div class="row titleArea clearfix full-width">
            <app-ep-document-view-image
              class="customer patientView userIconView pull-left"
              [defaultPicture]="
                'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
              "
              [link]="profileLink"
            >
            </app-ep-document-view-image>
            <h2 class="summary-header rm-m white">
              {{ customer.Salutation }} {{ customer.CalculatedName }}
              <span class="subLabel">{{ "KEYWORD.patient" | translate | titlecase }}</span>
            </h2>
          </div>
        </div>
      </div>
      <div class="button-row">
        <button
          class="actionMenuToggle"
          *ngIf="isCustomer != true"
          [matMenuTriggerFor]="actionsCustMenu"
          mat-raised-button
        >
          Actions
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #actionsCustMenu="matMenu">
          <button (click)="editDetails(customer['ID'])" mat-menu-item color="accent">
            <mat-icon>edit</mat-icon>
            <span>View/Edit Details</span>
          </button>

          <button (click)="createNote(customer['ID'])" mat-menu-item>
            <mat-icon>note_add</mat-icon>
            <span>Create Note</span>
          </button>

          <button (click)="viewNotes(customer['ID'])" mat-menu-item>
            <mat-icon class="fa fa-list-alt"></mat-icon>
            <span>View Notes</span>
          </button>

          <button class="" *ngIf="customer['emails.Email']" (click)="contact()" mat-menu-item color="accent">
            <mat-icon>contact_mail</mat-icon>
            Contact {{ customer.CalculatedName }}
          </button>

          <!-- <button class="" mat-menu-item color="accent" (click)="displayMap()" *ngIf="customer['addresses.Calculated']">
            <mat-icon>place</mat-icon> Map View
          </button> -->

          <!-- <button mat-menu-item *ngIf="customer['ExistingPerson_key']"
            (click)="patientInvitation()">
            <mat-icon>portrait</mat-icon>
            <span>View {{"KEYWORD.patient" | translate | titlecase}} Invitations</span>
          </button> -->

          <button [matMenuTriggerFor]="patientInvLink" [hidden]="customer['Status'] == 'Archived'" mat-menu-item>
            <mat-icon>email</mat-icon>
            <span>New Invitation</span>
          </button>

          <mat-menu #patientInvLink="matMenu">
            <div *ngIf="isModuleTreatmentPlanActive == true && financedProductGroup">
              <div *ngFor="let p of financedProductGroup">
                <button
                  (click)="linkToInvitation(p)"
                  mat-menu-item


                  appCustomTooltip [customToolTipContent]="p.ToolTip"
                  [customShowDelay]="0"     [customHideDelay]="0"   [customToolTipClass]="'additional-tooltip-class'"  [customReplacement]="'bottom'"
                  [customDisplay]="p.HasToolTip" [customIndicatorPosition]="'left'">
                  New <strong>{{ p['Group.Label'] }}</strong> Invitation
                  <mat-icon class="position fas {{ p.Icon }} "></mat-icon>
                </button>

              </div>
            </div>
          </mat-menu>

          <button *ngIf="customer['Status'] != 'Archived'" (click)="archivePatient()" mat-menu-item>
            <mat-icon>archive</mat-icon>
            <span> Archive {{ "KEYWORD.patient" | translate }}</span>
          </button>

          <button
            *ngIf="isPromoterOrAdmin == true && customer['ID']"
            (click)="viewCustomerLogs(customer['ID'])"
            mat-menu-item
          >
            <mat-icon class="fa fa-list-alt"></mat-icon>
            <span>View Customer Logs</span>
          </button>
        </mat-menu>
      </div>
      <mat-dialog-content>
        <div class="row clearfix">
          <div class="icons">
            <mat-icon>person</mat-icon>
          </div>
          <div class="row-content">
            <ng-container *ngIf="isPromoterOrAdmin">
              <p class="small">Gender</p>
              <p class="rm-m">{{ customer['Gender.Label'] }}</p>
            </ng-container>
            <p class="small">Name</p>
            <p class="rm-m">
              {{ customer['Salutation'] }} {{ customer['FirstName'] }} {{ customer['MiddleName'] }}
              {{ customer['Name'] }}
            </p>
          </div>
        </div>

        <div class="row clearfix" *ngIf="customer['DateOfBirth'] && customer['DateOfBirth'] != '0000-00-00'">
          <div class="icons">
            <mat-icon>event</mat-icon>
          </div>
          <div class="row-content">
            <p class="small">Date of birth</p>
            <p class="rm-m">{{ customer['DateOfBirth'] | customDate }}</p>
          </div>
        </div>

        <div class="row clearfix">
          <div class="icons">
            <mat-icon>phone</mat-icon>
          </div>
          <!-- Phone numbers -->
          <div class="row-content">
            <p class="small">Phone/Mobile</p>
            <p class="rm-m">{{ customer['mobiles.Number'] | customPhone }}</p>

            <!--  -->
            <p class="small" *ngIf="customer['phones.Number'] && customer['phones.Number'] != 'undefined'">
              Phone
            </p>
            <p class="rm-m" *ngIf="customer['phones.Number'] && customer['phones.Number'] != 'undefined'">
              {{ customer['phones.Number'] | customPhone: 'landLine' }}
            </p>
          </div>
        </div>

        <!-- Email Addresses -->
        <div class="row clearfix">
          <div class="icons">
            <mat-icon>email</mat-icon>
          </div>
          <div class="row-content">
            <p class="small">Primary Email</p>
            <p class="rm-m">{{ customer['emails.Email'] }}</p>
          </div>
        </div>

        <!-- Location Information -->
        <div class="row clearfix" *ngIf="customer['addresses.Calculated']">
          <div class="icons">
            <mat-icon>location_on</mat-icon>
          </div>
          <div class="row-content">
            <p class="small">Home Address</p>
            <p class="rm-m">{{ customer['addresses.Calculated'] }}</p>

            <!--  -->
          </div>
        </div>

        <app-map-view
          class="mt mb"
          *ngIf="customer['addresses.Calculated'] && displayMapFlag == true"
          [icon]="iconAddres"
          [forceGettingAddress]="true"
          [canClose]="canClose"
          [unitNumber]="customer['addresses.Unit']"
          [canClick]="false"
          [streetType]="customer['addresses.Street Type']"
          [streetName]="customer['addresses.Street Name']"
          [latitude]="customer['Address.Latitude']"
          [longitude]="customer['Address.Longitude']"
          [streetNumber]="customer['addresses.Street Nr']"
          [zoom]="17"
          [postCode]="customer['addresses.Postcode']"
          [state]="customer['addresses.State']"
          [suburb]="customer['addresses.Suburb']"
          [moreInfo]=""
          (notFoundEvent)="notFoundEvent($event)"
        ></app-map-view>

        <p *ngIf="isReferral == true">
          <span class="status-box pull-right">Referral </span>
        </p>
      </mat-dialog-content>
    </div>
  </mat-card>
</div>
