import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class DrugsService {
  constructor(private http: HttpClient) {}

  getDrugList(payload, sessionType = 'merchant'): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/drug/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http.get(environment.nodeUrl + '/drug/', options).pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/drug/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getDrugDetails(ID, payload = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/drug/' + ID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createDrug(payload = {}, sessionType = 'customer'): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .post(environment.nodeUrl + '/drug/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http.post(environment.nodeUrl + '/drug', payload).pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/drug/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  deleteDrugGroup(ids): Observable<any> {
    let _ids;
    if (Array.isArray(ids) == true) {
      _ids = ids.join(',');
    } else if (typeof ids == 'string') {
      _ids = ids;
    }

    if (_ids) {
      return this.http
        .delete(environment.nodeUrl + '/drug/group/global/' + _ids)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  deleteDrug(ID): Observable<any> {
    return this.http
      .delete(environment.nodeUrl + '/drug/global/' + ID)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editDrugGroup(payload = {}): Observable<any> {
    return this.http
      .put(environment.nodeUrl + '/drug/group/global', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editDrug(ID, payload = {}): Observable<any> {
    return this.http
      .put(environment.nodeUrl + '/drug/global/' + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getDrugImage(ID): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/drug/image/' + ID, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getDrugImageStreamLink(ID) {
    if (ID) {
      return environment.nodeUrl + '/drug/image-stream/' + ID;
    }
    return null;
  }

  getDrugThumbnail(ID): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/drug/thumbnail/' + ID, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }
  getDrugThumbnailStreamLink(ID) {
    if (ID) {
      return environment.nodeUrl + '/drug/thumbnail-stream/' + ID;
    }
    return null;
  }

  getAllergyCategoryList(payload = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/allergy-category', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getIllnessCategoryList(payload = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/illness-category', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getDrugSideEffect(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/drug-side-effect', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getDrugUsage(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/drug-usage', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createMedication(payload, role = 'customer'): Observable<any> {
    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .post(environment.nodeUrl + '/medication', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .post(environment.nodeUrl + '/medication/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .post(environment.nodeUrl + '/medication/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/medication/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  modifyMedication(id, payload, role = 'customer'): Observable<any> {
    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .put(environment.nodeUrl + '/medication/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .put(environment.nodeUrl + '/medication/global/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .put(environment.nodeUrl + '/medication/customer/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/medication/customer/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMedicationList(payload = {}, role = 'customer'): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/medication', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/medication/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/medication/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/medication/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMedicationDetails(id, payload = {}, role = 'customer'): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/medication/' + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/medication/global/' + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/medication/customer/' + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/medication/customer/' + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  deleteMedicationDetails(id, role): Observable<any> {
    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .delete(environment.nodeUrl + '/medication/' + id)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .delete(environment.nodeUrl + '/medication/global/' + id)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .delete(environment.nodeUrl + '/medication/customer/' + id)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .delete(environment.nodeUrl + '/medication/customer/' + id)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
}
