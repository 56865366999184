import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { EditorComponentService } from '../shared/editor-component.service';

@Component({
  selector: 'app-editor-component-clone',
  templateUrl: './editor-component-clone.component.html',
  styleUrls: ['./editor-component-clone.component.css'],
})
export class EditorComponentCloneComponent implements OnInit {
  isActive = true;
  isDefault = false;
  types = [];
  typeCode;
  label;

  title;
  description;

  editorComponent;
  editorComponentID;
  sessionType;
  @Output()
  close = new EventEmitter();
  @Output()
  getResult = new EventEmitter();
  @Output()
  getRedirection = new EventEmitter();
  merchantID;

  merchants = [];

  isPromoterOrAdmin = false;
  utils = new UtilsClass();

  constructor(
    private router: Router,
    private lookupService: LookupService,
    private authenticationService: AuthenticationService,
    private editorComponentService: EditorComponentService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.editorComponentID != null) {
        this.editorComponentID = data.editorComponentID;
      }
    }
  }

  ngOnInit() {
    if (this.editorComponentID) {
      this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
        this.isPromoterOrAdmin = isPromoterOrAdmin;

        if (this.isPromoterOrAdmin === true) {
          this.authenticationService
            .getMerchantList({ fields: 'ID,TradingAs' }, this.isPromoterOrAdmin)
            .subscribe((m) => {
              if (m) {
                this.merchants = m;
              }
            });
        }
        this.lookupService.getLookup('CodeLookup', 'Editor_Component_Type').subscribe((res) => {
          this.types = res;
        });
        this.authenticationService.getSessionType().subscribe((r) => {
          this.sessionType = r;
          this.editorComponentService
            .getEditorComponentDetails(
              this.editorComponentID,
              {
                fields:
                  'ID,DateTimeCreated,Merchant_key,Merchant_Trading_As,Label,Description,Is_Default,Type.Code,Type.Label,Nature.Code,Nature.Label,Is_Active',
              },
              this.sessionType
            )
            .subscribe((res) => {
              if (res && res.ID) {
                this.editorComponent = res;
                this.buildItem();
              }
            });
        });
      });
    }
  }

  buildItem() {
    if (this.editorComponent && this.editorComponent.ID) {
      if (this.editorComponent.Label) {
        this.title = this.editorComponent.Label;
        this.label = this.editorComponent.Label + '(cloned)';
      }

      if (this.editorComponent['Merchant_key']) {
        this.merchantID = this.editorComponent['Merchant_key'];
      }

      if (this.editorComponent['Type.Code']) {
        this.typeCode = this.editorComponent['Type.Code'];
      }

      if (this.editorComponent.Description) {
        this.description = this.editorComponent.Description;
      }

      if (this.editorComponent.Is_Default === '1') {
        this.isDefault = true;
      } else {
        this.isDefault = false;
      }
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  sendResult(redirect = false) {
    const payload = {
      label: this.label,
      description: this.description,
      typeCode: this.typeCode,
      isActive: this.isActive,
      isDefault: this.isDefault,
      merchantID: null,
    };

    if (this.isDefault != true) {
      payload.merchantID = this.merchantID;
    }
    this.editorComponentService
      .cloneEditorComponent(this.editorComponentID, payload, this.sessionType)
      .subscribe((res) => {
        if (res && res.ID) {
          NotifyAppComponent.displayToast('success', 'success', 'Editor Component is Cloned');
          if (redirect === true) {
            this.router.navigate(['/merchant', { outlets: { page: ['editor-component-edit', res.ID] } }]);
            this.getRedirection.emit(true);
            this.closeEvent();
          } else {
            this.getResult.emit(res);
          }
        }
      });
  }
}
