import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ClipboardService } from 'ngx-clipboard';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { IframeViewComponent } from '../../../shared/components/iframe-view/iframe-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-landing-page-embed',
  templateUrl: './landing-page-embed.component.html',
  styleUrls: ['./landing-page-embed.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class LandingPageEmbedComponent implements OnInit {
  preLink;

  link;

  displayFooter = true;

  displayHeader = true;

  displayOffers = true;
  sessionType;

  type;

  landingPageID;

  settings = Settings.global;

  merchantID;

  theme = {
    code: 'blue',
    background: 'linear-gradient(135deg, #0092eb 0%, #0dbad3 100%)',
    color: '#1b8bdd',
    color2: '#0dbad3',
  };

  widthTypes = [
    {
      code: 'screen',
      label: 'Static relative to screen',
    },

    {
      code: 'static',
      label: 'Static',
    },
    {
      code: 'percent',
      label: 'Percentage',
    },
  ];

  widthType = 'percent';

  width = 100;
  widthFull = '100%';

  heightTypes = [
    {
      code: 'screen',
      label: 'Relative to screen',
    },

    {
      code: 'static',
      label: 'Static',
    },
    {
      code: 'percent',
      label: 'Percentage',
    },
  ];

  heightType = 'static';

  height = 1200;
  heightFull = '1200px';

  iframeCode;

  close = new EventEmitter();
  constructor(
    private _clipboardService: ClipboardService,
    private _sanitizer: DomSanitizer,
    private authenticationService: AuthenticationService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      if (data.landingPageID) {
        this.landingPageID = data.landingPageID;
        this.type = 'landingPage';
      } else if (data.type) {
        this.type = data.type;
      }
      if (data.theme) {
        this.theme = data.theme;
      }

      if (data.displayFooter == true) {
        this.displayFooter = true;
      } else if (data.displayFooter == false) {
        this.displayFooter = false;
      }

      if (data.displayHeader == true) {
        this.displayHeader = true;
      } else if (data.displayHeader == false) {
        this.displayHeader = false;
      }

      if (data.displayOffers == true) {
        this.displayOffers = true;
      } else if (data.displayOffers == false) {
        this.displayOffers = false;
      }
    }
  }

  ngOnInit() {
    if (Settings.global['context'] === 'p4p') {
      this.theme = {
        code: 'ee350b',
        background: ' linear-gradient(-135deg, #1caaa0 -10.31%, #7462ef  97.05%)',
        color: '#7462ef',
        color2: '#1caaa0',
      };
    }

    this.authenticationService.getSessionType().subscribe((res) => {
      this.sessionType = res;

      this.authenticationService.getCurrentPractice().subscribe((practice) => {
        if (practice && practice.ID) {
          this.merchantID = practice.ID;
        }

        this.setup();
      });
    });
  }

  setup() {
    if (this.landingPageID) {
      this.preLink = `${this.settings['publicSiteFrontendLink']}/landing-page-overview/${this.landingPageID}/pr?t=pr`;
    } else if (this.type === 'practice' || this.type === 'goingGreen') {
      this.theme = {
        code: 'ohhappiness',
        background: ' linear-gradient(-135deg, #00b09b -10.31%, #96c93d  97.05%)',
        color: '#00b09b',
        color2: '#57cc99',
      };

      this.preLink = `${this.settings['publicSiteFrontendLink']}/going-green/${this.merchantID}?t=pr`;
    } else if (this.type === 'dental' || this.type === 'tips' || this.type === 'offers') {
      this.preLink = `${this.settings['publicSiteFrontendLink']}/tips-offers/${this.merchantID}?t=pr`;
    } else if (this.type === 'selfService') {
      this.preLink = `${this.settings['publicSiteFrontendLink']}/self-service/${this.merchantID}?t=pr&isEmbed=true`;
    } else {
      this.preLink = `${this.settings['publicSiteFrontendLink']}/going-green/${this.merchantID}?t=pr`;
    }

    this.buildIframe();
  }

  buildLink() {
    if (this.preLink) {
      this.link = this.preLink;
      if (this.displayFooter == false) {
        this.link = `${this.link}&displayFooter=${this.displayFooter}`;
      }
      if (this.displayHeader == false) {
        this.link = `${this.link}&displayHeader=${this.displayHeader}`;
      }

      if (this.displayOffers == false) {
        this.link = `${this.link}&displayOffers=${this.displayOffers}`;
      }
    }
  }

  buildDimension() {
    this.widthFull = null;
    this.heightFull = null;
    if (this.width > 0 && this.height > 0) {
      if (this.widthType == 'screen') {
        this.widthFull = `${this.width}vw;`;
      } else if (this.widthType == 'static') {
        this.widthFull = `${this.width}px;`;
      } else if (this.widthType == 'percent') {
        this.widthFull = `${this.width}%;`;
      }

      if (this.heightType == 'screen') {
        this.heightFull = `${this.height}vh;`;
      } else if (this.heightType == 'static') {
        this.heightFull = `${this.height}px;`;
      } else if (this.heightType == 'percent') {
        this.heightFull = `${this.height}%;`;
      }
    }
  }

  buildIframe() {
    this.buildLink();
    this.buildDimension();

    if (this.link && this.width > 0 && this.height > 0 && this.heightFull && this.widthFull) {
      this.iframeCode = `
  <iframe
    style="border:none !important;"
    width="${this.widthFull}"
    height="${this.heightFull}"
    src="${this.link}">
  </iframe>
    `;
    }
  }

  copy(url) {
    if (url) {
      this._clipboardService.copyFromContent(url);
      NotifyAppComponent.displayToast('success', 'Copied!', '');
    }
  }

  open(link) {
    if (link) {
      window.open(link, '_blank');
    }
  }
  closeEvent() {
    this.close.emit(true);
  }

  getUnit(u, iswidth = false) {
    if (u == 'screen') {
      if (iswidth == true) {
        return 'vw';
      } else {
        return 'hw';
      }
    } else if (u == 'static') {
      return 'px';
    } else if (u == 'percent') {
      return '%';
    }
  }

  openIframe() {
    if (this.link) {
      const ref2 = RootAppComponent.dialog.open(IframeViewComponent, {
        data: {
          link: this.link,
          isSubmit: false,
          extraDetails: true,
        },
        width: '80%',
        height: '92vh',
        panelClass: 'noCard',
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });

      ref2.componentInstance.getSubmit.subscribe((res) => {
        if (res) {
          ref2.close();
        }
      });
    }
  }
}
