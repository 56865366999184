import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Merchant } from '../../../../merchant/shared/types/merchant.type';
import { ProductCustomer } from '../../../shared/services/product-customer.service';

export interface ViewCustomerProductModalConfig {
  industryType: string;
  productCustomer: ProductCustomer;
  practice: Merchant;
}

@Component({
  selector: 'ipv-view-customer-product',
  templateUrl: './view-customer-product.modal.html',
  styleUrls: ['./view-customer-product.modal.css'],
})
export class ViewCustomerProductModalComponent {
  industryType = 'dental';
  productCustomer: ProductCustomer = null;
  practice: Merchant = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ViewCustomerProductModalConfig,
    private dialogRef: MatDialogRef<ViewCustomerProductModalComponent>
  ) {
    if (data) {
      console.log(data.productCustomer);
      this.industryType = data.industryType;
      this.productCustomer = data.productCustomer;
      this.practice = data.practice;
    }
  }

  close() {
    this.dialogRef.close();
  }
}
