<ipv-title-modal [title]="title" [classIcon]="'fas fa-print'" [hideAllDefaultButtons]="true">
  <div content>
    <div class="padding: 10px; margin-bottom: 30px" id="print-section">
      <div class="section row clearfix mt">
        <div class="pull-left">
          <h1 class="summary-header rm-m sm-mb">{{ title + printTitle }}</h1>
        </div>
        <div class="pull-right" style="text-align: left">
          <p class="bold rm-mt" *ngIf="practiceOrMerchant">{{ practiceOrMerchant['TradingAs'] }}</p>
          <p class="small rm-m" *ngIf="practiceOrMerchant">
            Phone: {{ practiceOrMerchant['phones.Number'] | customPhone }}
          </p>
          <p class="small rm-m" *ngIf="practiceOrMerchant">Address: {{ practiceOrMerchant['addresses.Calculated'] }}</p>
        </div>
        <div class="pull-right" style="height: 60px; width: 60px; margin-top: 10px; margin-bottom: 10px">
          <app-merchant-view-modal-picture
            class="merchantView"
            *ngIf="practiceOrMerchant && practiceOrMerchant['ID']"
            [merchantID]="practiceOrMerchant['ID']"
          >
          </app-merchant-view-modal-picture>
        </div>
      </div>

      <ipv-data-table
        [table]="table"
        [hide]="hide"
        [order]="order"
        [sortable]="sortable"
        [rename]="rename"
        [customComponents]="customComponents"
        [isFixedFirstRow]="isFixedFirstRow"
        [search]="search"
        [searchExceptions]="searchExceptions"
        [paginate]="false"
        [pageSize]="pageSize"
        [menuTemplate]="menuTemplate"
        [formatHeaders]="formatHeaders"
        [sections]="sections"
        [sectionColumn]="sectionColumn"
      >
        <div
          [innerHtml]="searchAreaTemplate"
          style="color: white; margin-top: 20px; font-size: large; text-align: left"
        ></div>
      </ipv-data-table>

      <div class="section">
        <div class="pull-right power-by">
          <img
            [src]="'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/power_by_SR.png'"
          />
        </div>
      </div>
    </div>

    <div style="width: 100%; background: white; position: absolute; bottom: 0px; height: 30px; z-index: 2000">
      <div style="display: flex; width: 100%; justify-content: flex-end; margin-top: 15px">
        <button
          class="pull-left"
          [printTitle]="title + printTitle"
          [useExistingCss]="true"
          color="accent"
          mat-raised-button
          style="margin-left: 10px"
          printSectionId="print-section"
          styleSheetFile="/assets/styles/print-history.css, /assets/styles/application.css, /assets/styles/css/fontawesome.min.css"
          ngxPrint
        >
          <mat-icon class="fas fa-print"></mat-icon>
          Print
        </button>
      </div>
    </div>
  </div>
</ipv-title-modal>
