import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'age',
})
export class AgePipe implements PipeTransform {
  transform(value: Date): string {
    const today = moment();
    const birthdate = moment(value);
    const years = today.diff(birthdate, 'years');
    let result: string;
    if (years > 0) {
      result = years + ' years old';

      // result += today.subtract(years, 'years').diff(birthdate, 'months') + " year";
    } else {
      // result = years + " yr ";

      result = today.subtract(years, 'years').diff(birthdate, 'months') + ' months old';
    }

    return result;
  }
}
