<div class="rel" *ngIf="invitationsDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>
<div [hidden]="invitationsDB.data.length == 0">
  <div class="row clearfix flex" [hidden]="displayInvitationView == true || displayInvitationDetailEpver == true">
    <div class="half-width">
      <h1 class="dataHeader main-data-header">
        <span *ngIf="!status"> Invitations</span>
        <span *ngIf="status" [innerHTML]="statusLabel() | safeHtml"></span>
        <span class="badge" id="totalInvitation">
          <mat-icon>email</mat-icon>
          <!-- <span countUp [endVal]="invitationsDB.data.length"></span> -->
          <span [endVal]="dataSource.filteredData.length" countUp></span>
        </span>
        <span class="dollarValue" id="totalAmount" *ngIf="invitationsDB && invitationsDB.data">
          <mat-icon>account_balance</mat-icon>
          <span class></span>
          $
          <span [endVal]="dataSource.filteredData | objectPropertyValue: 'treatment.Value' | toNumberArray | sum"
            countUp></span>
        </span>
      </h1>
    </div>
    <div class="half-width flexEnd reputation-area-data" *ngIf="isLoadSubAccountStatistics == true">
      <button class="" (click)="viewSubAccountStatisticalData()" mat-stroked-button color="primary"
        matTooltip="View Details">
        <mat-icon>search</mat-icon>
        Email Reputation &nbsp;
        <span class="chip mat-elevation-z1" [ngStyle]="{ 'background-color': '#' + emailReputationColor }">{{
          emailReputation
          }}</span>
      </button>
    </div>
    <div class="pull-right toggles" *ngIf="false"
      [hidden]="displayInvitationView == true || displayInvitationDetailEpver == true">
      <mat-button-toggle-group #group1="matButtonToggleGroup" [(ngModel)]="selectedTreatmentValue"
        [ngClass]="{ disableStyle: activeButton == false && status != null }">
        <mat-button-toggle class="pos" [value]="'proceed'" matTooltip="Proceeding">
          <mat-icon>mood</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle class="maybe" [value]="'maybe'" matTooltip="Maybe">
          <mat-icon>sentiment_satisfied</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle class="neg" [value]="'notProceed'" matTooltip="Not Proceeding">
          <mat-icon>mood_bad</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
      <button class="" *ngIf="status" (click)="reactive()" mat-button color="primary">Active Me</button>
    </div>
  </div>
  <!-- {{ treatmentValue | customCurrency }} -->
  <div [hidden]="displayInvitationView == true || displayInvitationDetailEpver == true">
    <mat-card class="list-card list-card-invitation">
      <!-- expand list -->
      <button class="stretchList btn-small" (click)="stretchListEvent()" mat-button>
        <mat-icon>list</mat-icon>
        Expand List
      </button>
      <!-- search -->
      <div class="row clearfix searchArea primary-gradient-img filter-area-data">
        <div class="row justify flex">
          <div class="row full-width flex">
            <mat-icon class="schIcon mt">search</mat-icon>

            <mat-form-field class="main-patient-filter full-width" appearance="outline">
              <mat-label>Search: First Name, Last Name, Email, Mobile, Product ...</mat-label>
              <input class="clearfix" (input)="setFilter($event.target.value, 'fullName')" name="fullName"
                placeholder="" matInput />
            </mat-form-field>

            <mat-form-field class="half-width" appearance="outline">
              <mat-label>Source</mat-label>
              <mat-select class="full-width" (selectionChange)="setFilter($event.value, 'SourceLabel')"
                name="sourceList" placeholder="">
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option *ngFor="let i of sourceList | unique | without: ['', null] | orderBy" [value]="i">
                  {{ i }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="ml half-width" appearance="outline">
              <mat-label>Add Content Filters</mat-label>
              <mat-select class="schSel" [formControl]="searchVals" matInput placeholder="" multiple>
                <mat-select-trigger>
                  {{ searchVals.value ? searchVals.value[0] : '' }}
                  <span class="additional-selection" *ngIf="searchVals.value?.length > 1">
                    (+{{ searchVals.value.length - 1 }} others)
                  </span>
                </mat-select-trigger>
                <mat-option *ngFor="let searchVal of searchValList" [value]="searchVal">{{ searchVal }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="ml timeOutput clearfix" *ngIf="dateFrom != null || dateTo != null">
            <mat-icon class="pull-left mt">schedule</mat-icon>
            <div class="pull-right">
              <p class="small rm-mb">
                <span *ngIf="dateFrom">From:
                  <strong>{{ dateFrom | customDateTime }} </strong>
                </span>
              </p>
              <p class="small rm-mt">
                <span *ngIf="dateTo">
                  To:
                  <strong>{{ dateTo | customDateTime }}</strong>
                </span>
              </p>
            </div>
          </div>
        </div>

        <div class="full-width flex flexwrap animated fadeInDown" *ngIf="searchVals.value?.length > 0">
          <!-- <app-date-input *ngIf="searchVals.value?.indexOf('Date') !== -1 " class=" full-width" [isClear]="true" name="dateFrom"
            (selectedDate)="dateFromEvent($event)" [label]="dateFromLabel"></app-date-input>
              <app-date-input *ngIf="searchVals.value?.indexOf('Date') !== -1 " class=" full-width" [isClear]="true" name="dateTo"
              (selectedDate)="dateToEvent($event)" [label]="dateToLabel"></app-date-input> -->

          <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Dentist') !== -1" appearance="outline">
            <mat-label>{{ "KEYWORD.practitioner" | translate | titlecase }}</mat-label>
            <mat-select class="full-width" (selectionChange)="setFilter($event.value, 'DentistContactName')"
              name="DentistContactName" placeholder="">
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option *ngFor="
                  let a of invitationsDB.data
                    | objectPropertyValue: 'DentistContactName'
                    | unique
                    | without: ['', null]
                    | orderBy
                " [value]="a">
                {{ a }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Amount') !== -1" appearance="outline">
            <mat-label>Amount</mat-label>
            <input class="clearfix" (input)="setFilter($event.target.value, 'treatment.Value')" name="Amount"
              placeholder="" CurrenyMask matInput />
          </mat-form-field>
          <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Product') !== -1" appearance="outline">
            <mat-label>Product</mat-label>
            <mat-select class="full-width" (selectionChange)="setFilter($event.value, 'Product.Label.Marketing')"
              name="Product" placeholder="">
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option *ngFor="
                  let p of invitationsDB.data
                    | objectPropertyValue: 'Product.Label.Marketing'
                    | unique
                    | without: ['', null]
                    | orderBy
                " [value]="p">
                {{ p }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Product Group') !== -1"
            appearance="outline">
            <mat-label>Product Group</mat-label>
            <mat-select class="full-width" (selectionChange)="setFilter($event.value, 'Product.ThisGroupOnly.Label')"
              name="ProductGroup" placeholder="">
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option *ngFor="
                  let pg of invitationsDB.data
                    | objectPropertyValue: 'Product.ThisGroupOnly.Label'
                    | unique
                    | without: ['', null]
                    | orderBy
                " [value]="pg">
                {{ pg }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Email Status') !== -1"
            appearance="outline">
            <mat-label></mat-label>
            <mat-select class="full-width" (selectionChange)="setFilter($event.value, 'Status.Email.Short_NE')"
              name="emailStatus" placeholder="Email Status">
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option *ngFor="
                  let e of invitationsDB.data
                    | objectPropertyValue: 'Status.Email.Short_NE'
                    | unique
                    | without: ['', null]
                    | orderBy
                " [value]="e">
                {{ e }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Application Status') !== -1"
            appearance="outline">
            <mat-label>Application Status</mat-label>
            <mat-select class="full-width" (selectionChange)="setFilter($event.value, 'Status.Application.Short_NE')"
              name="AppStatus" placeholder="">
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option *ngFor="
                  let a of invitationsDB.data
                    | objectPropertyValue: 'Status.Application.Short_NE'
                    | unique
                    | without: ['', null]
                    | orderBy
                " [value]="a">
                {{ a }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Contract Status') !== -1"
            appearance="outline">
            <mat-label>Contract Status</mat-label>
            <mat-select class="full-width" (selectionChange)="setFilter($event.value, 'Status.Contract.Short_NE')" c
              name="ContractStatus" placeholder="">
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option *ngFor="
                  let c of invitationsDB.data
                    | objectPropertyValue: 'Status.Contract.Short_NE'
                    | unique
                    | without: ['', null]
                    | orderBy
                " [value]="c">
                {{ c }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Invitation Status') !== -1"
            appearance="outline">
            <mat-label>Invitation Status</mat-label>
            <mat-select class="full-width" (selectionChange)="setFilter($event.value, 'Status.Invitation.Short_NE')"
              name="invitationStatus" placeholder="">
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option *ngFor="
                  let i of invitationsDB.data
                    | objectPropertyValue: 'Status.Invitation.Short_NE'
                    | unique
                    | without: ['', null]
                    | orderBy
                " [value]="i">
                {{ i }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <table #table [dataSource]="dataSource" [class.isStretched]="stretchList" mat-table matSort>
        <!-- Customer Column -->
        <ng-container matColumnDef="Customer" sticky>
          <th class="text-center" *matHeaderCellDef mat-header-cell mat-sort-header>Customer</th>
          <td class="rel" *matCellDef="let row"
            [ngStyle]="{ 'border-left': '3px solid ' + row['Status.Invitation.Colour'] }"
            (click)="openInviteViewDetails(row['ID'], row['Prospect_key'])" mat-cell>
            <span class="content">
              {{ row.FirstName | lowercase | ucfirst }} {{ row.LastName | lowercase | ucfirst }}
            </span>
            <span class="colour" [ngStyle]="{ background: row['Status.Invitation.Colour'] }"></span>
          </td>
        </ng-container>

        <!-- Date Column -->
        <ng-container matColumnDef="Date">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Date</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row['ID'], row['Prospect_key'])" mat-cell>
            {{ row['DateTimeCreated'] | customDateTime }}
          </td>
        </ng-container>

        <!-- Merchant Column -->
        <ng-container matColumnDef="Merchant">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Merchant</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row['ID'], row['Prospect_key'])" mat-cell>
            {{ row.MerchantName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="marketingDescription">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Marketing Description</th>
          <td *matCellDef="let row"  mat-cell>

            <div *ngIf="row['Marketing_Description'] && row['Marketing_Description'].length<=300"
              [innerHTML]="row['Marketing_Description'] | safeHtml"></div>


            <read-more class="text-left read-more-marketing-description  full-width "
              *ngIf="row['Marketing_Description'] && row['Marketing_Description'].length>300"
              [text]="row['Marketing_Description']" [design]="'mode2'" [hideButton]="false" [isCollapsed]="true"
              [maxLength]="0" [textMore]="'Show'" [textLess]="'hide'">
            </read-more>

          </td>
        </ng-container>
        <!-- Operator Column -->
        <ng-container matColumnDef="Operator">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Operator</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row['ID'], row['Prospect_key'])" mat-cell>
            {{ row.DentistContactName }}
          </td>
        </ng-container>

        <!-- Amount Column -->
        <ng-container matColumnDef="Amount">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Amount</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row['ID'], row['Prospect_key'])" mat-cell>
            {{ row['treatment.Value'] | customCurrency }}
          </td>
        </ng-container>

        <!-- Product Column -->
        <ng-container matColumnDef="Product">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Product</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row['ID'], row['Prospect_key'])" mat-cell>
            {{ row['Product.Label.Marketing'] }}
          </td>
        </ng-container>

        <ng-container matColumnDef="InformedConsent">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Informed Consent</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row['ID'], row['Prospect_key'])" mat-cell
            matTooltip="Signed {{ row['InformedConsent.Given.When'] | customDate }}">
            <span class="list-label" *ngIf="row['InformedConsent.Given.Flag'] == '1'" [style.background]="'#6fb662'">
              <mat-icon class="consent">check</mat-icon> Consent
            </span>
            <span *ngIf="row['InformedConsent.Given.Flag'] == '0'"></span>
          </td>
        </ng-container>

        <!-- Product Group Column -->
        <ng-container matColumnDef="ProductGroup">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Product Group</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row['ID'], row['Prospect_key'])" mat-cell
            matTooltip="{{ row['Product.ThisGroupOnly.Label'] }}">
            {{ row['Product.ThisGroupOnly.Code'] }}
          </td>
        </ng-container>

        <!-- Source Column -->
        <ng-container matColumnDef="Source">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Source</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row['ID'], row['Prospect_key'])" mat-cell>
            <div class="icon-info-symb">
              <div *ngIf="row && row['External_Source'] != ''" style="display: inline">
                <mat-icon class="smr fas fa-network-wired" matTooltip="Invitation - 3rd Party App"></mat-icon>
              </div>
              <div *ngIf="row && row['Campaign_Key'] && row['External_Source'] == '' && row['Created_From.Label'] == ''"
                style="display: inline">
                <mat-icon class="smr fas fa-bullhorn" matTooltip="Marketing Campaign"></mat-icon>
              </div>
              <div *ngIf="row && row['Campaign_Key'] == '' && row['External_Source'] == '' && row['Created_From.Label']"
                style="display: inline">
                <mat-icon class="smr fas fa-building" *ngIf="row['Created_From.Code'] === 'MER_APP'"
                  matTooltip="{{ row['Created_From.Label'] }}  "></mat-icon>

                <mat-icon class="smr fa-network-wired" *ngIf="row['Created_From.Code'] === 'MER_3rdPARTY'"
                  matTooltip="{{ row['Created_From.Label'] }}  {{ row['Created_From_Description'] }}"></mat-icon>

                <mat-icon class="smr fas fa-paper-plane" *ngIf="row['Created_From.Code'] === 'BRW_INVITATION'"
                  matTooltip="{{ row['Created_From.Label'] }}  "></mat-icon>

                <mat-icon class="smr fas fas fa-project-diagram" *ngIf="row['Created_From.Code'] === 'BRW_3rdPARTY'"
                  matTooltip="{{ row['Created_From.Label'] }}  {{ row['Created_From_Description'] }}"></mat-icon>

                <mat-icon class="smr fas fa-bullhorn" *ngIf="row['Created_From.Code'] === 'BRW_MRKTCMPN'"
                  matTooltip="{{ row['Created_From.Label'] }}  "></mat-icon>

                <mat-icon class="smr fas fa-globe" *ngIf="row['Created_From.Code'] === 'BRW_WEB'"
                  matTooltip="{{ row['Created_From.Label'] }}  "></mat-icon>

                <mat-icon class="smr fas fa-folder-open" *ngIf="row['Created_From.Code'] === 'Doc_Land_Pg'"
                  matTooltip="{{ row['Created_From.Label'] }}  "></mat-icon>
                  <mat-icon class="smr fas fa-user-edit" *ngIf="row['Created_From.Code'] === 'SlfSrvcLnd'"
                  matTooltip="{{ row['Created_From.Label'] }}  "></mat-icon>

                <mat-icon class="smr fas fa-money-bill" *ngIf="row['Created_From.Code'] === 'Instant_Offer'"
                  matTooltip="{{ row['Created_From.Label'] }}  "></mat-icon>
              </div>

              <div *ngIf="
                  row && row['Campaign_Key'] == '' && row['External_Source'] == '' && row['Created_From.Label'] == ''
                " style="display: inline">
                <mat-icon class="smr fas fa-paper-plane" matTooltip="Invitation"></mat-icon>
              </div>

              <mat-icon class="smr fas fa-envelope-open-text" *ngIf="row['SendInvite.ViaEmail'] == '1'"
                matTooltip="Email sent"></mat-icon>

              <mat-icon class="smr fas fa-mobile-alt" *ngIf="row['SendInvite.ViaSMS'] == '1'" matTooltip="SMS Sent">
              </mat-icon>

              <div class="attached-docs" *ngIf="row['Number_Of_Documents'] != '0'"
                matTooltip="{{ row['Number_Of_Documents'] }} Documents are attached to this invitation">
                <mat-icon class="smr fas fa-paperclip"></mat-icon>
                <span class="number-container accent-color">{{ row['Number_Of_Documents'] }}</span>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container cdkColumnDef="SMS">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> SMS</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
            <span class="list-label smaller" *ngIf="row['SendInvite.ViaSMS'] == '1'" matTooltip="SMS Sent"
              style="background-color: rgb(90, 186, 71)">
              <mat-icon>check</mat-icon>
            </span>
            <span class="list-label smaller" *ngIf="row['SendInvite.ViaSMS'] != '1'" matTooltip="Not SMS Sent"
              style="background-color: rgb(239, 71, 39)">
              <mat-icon>close</mat-icon>
            </span>
          </mat-cell>
        </ng-container>
        <!-- EmailStatus Column -->
        <ng-container matColumnDef="EmailStatus">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Email status</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row['ID'], row['Prospect_key'])" mat-cell>
            <span class="list-label" *ngIf="
                row &&
                row['Campaign_Key'] == '' &&
                row['Instant_Finance_Offer_Key'] == '' &&
                row['Document_Link_key'] == '' &&
                row['Created_From.Code'] != 'MER_APP' &&
                row['Created_From.Code'] != 'Doc_Land_Pg'
              " [style.background]="row['Status.Email.Colour'] || '#b4bcc1'">{{ row['Status.Email.Short_NE'] }}
            </span>
            <span class="list-label" *ngIf="
                row && row['Campaign_Key'] && row['Instant_Finance_Offer_Key'] == '' && row['Document_Link_key'] == ''
              " [style.background]="'#6fb662'" matTooltip="Invitation created from Campaign Landing Page">None
            </span>

            <span class="list-label" *ngIf="
                row && row['Campaign_Key'] == '' && row['Instant_Finance_Offer_Key'] && row['Document_Link_key'] == ''
              " [style.background]="'#6fb662'" matTooltip="Invitation created from Offer For  Finance ">None
            </span>

            <span class="list-label" *ngIf="
                row && row['Campaign_Key'] == '' && row['Instant_Finance_Offer_Key'] == '' && row['Document_Link_key']
              " [style.background]="'#6fb662'" matTooltip="Invitation created from Landing Page">None
            </span>

            <span class="list-label" *ngIf="
                row &&
                row['Campaign_Key'] == '' &&
                row['Instant_Finance_Offer_Key'] == '' &&
                row['Document_Link_key'] == '' &&
                row['Created_From.Code'] == 'MER_APP'
              " [style.background]="'#6fb662'"
              [matTooltip]="'Invitation created by the ' + ('KEYWORD.practice' | translate | titlecase)">None
            </span>

            <span class="list-label" *ngIf="
                row &&
                row['Campaign_Key'] == '' &&
                row['Instant_Finance_Offer_Key'] == '' &&
                row['Document_Link_key'] == '' &&
                row['Created_From.Code'] == 'Doc_Land_Pg'
              " [style.background]="'#6fb662'" matTooltip="Invitation created from Landing Page">None
            </span>
          </td>
        </ng-container>

        <!-- InvitationStatus Column -->
        <ng-container matColumnDef="InvitationStatus">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Invitation status</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row['ID'], row['Prospect_key'])" mat-cell>
            <span class="list-label"
              [style.background]="row['Status.Invitation.Colour'] || '#b4bcc1'">{{ row['Status.Invitation.Short_NE'] }}
            </span>
          </td>
        </ng-container>

        <!-- ApplicationStatus Column -->
        <ng-container matColumnDef="ApplicationStatus">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Application status</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row['ID'], row['Prospect_key'])" mat-cell>
            <span class="list-label"
              [style.background]="row['Status.Application.Colour'] || '#b4bcc1'">{{ row['Status.Application.Short_NE'] }}
            </span>
          </td>
        </ng-container>

        <!-- ContractStatus Column -->
        <ng-container matColumnDef="ContractStatus">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Contract Status</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row['ID'], row['Prospect_key'])" mat-cell>
            <span class="list-label" [style.background]="row['Status.Contract.Colour'] || '#b4bcc1'">{{
              row['Status.Contract.Short_NE']
              }}</span>
          </td>
        </ng-container>

        <!-- timeElapsed Column -->
        <ng-container matColumnDef="timeElapsed">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Time Elapsed</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row['ID'], row['Prospect_key'])" mat-cell>
            <span class="list-label" [style.background]="utils.daysPastColor(row['DateTimeCreated'])"
              matTooltip="{{ row['DateTimeCreated'] | customDateTime }}">
              {{ row['DateTimeCreated'] | TimeLabelPast }} ago
            </span>
          </td>
        </ng-container>

        <!-- timeInvited Column -->
        <ng-container matColumnDef="timeInvited">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Invited</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row['ID'], row['Prospect_key'])" mat-cell>
            <span class="list-label" [style.background]="utils.daysPastColor(row['DateTimeCreated'])"
              matTooltip="{{ row['DateTimeCreated'] | customDateTime }}">
              {{ row['DateTimeCreated'] | TimeLabelPast }} ago
            </span>
          </td>
        </ng-container>

        <!-- timeResponded Column -->
        <ng-container matColumnDef="timeResponded">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Responded</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row['ID'], row['Prospect_key'])" mat-cell>
            <span class="list-label" [style.background]="utils.daysPastColor(row['Date.Responded'])"
              matTooltip="{{ row['Date.Responded'] }}">
              {{ row['Date.Responded'] | TimeLabelPast }} ago
            </span>
          </td>
        </ng-container>

        <!-- Response Column -->
        <ng-container matColumnDef="Response">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Response</th>
          <td *matCellDef="let row" [matTooltip]="
              row['PatientChoice.Code'] == 'DSMO' || row['PatientChoice.Code'] == 'NOT'
                ? 'Clicking to view ' + ('KEYWORD.patient' | translate) + ' response'
                : row['PatientChoice.Label_LongThirdPerson']
            " (click)="openPatientResponse(row)" mat-cell>
            <mat-icon class="fas fa-grimace" *ngIf="row['PatientChoice.Code'] == 'DSMO'"></mat-icon>
            <mat-icon class="fas fa-grin-squint-tears" *ngIf="row['PatientChoice.Code'] == 'NOT'"></mat-icon>
            {{ row['PatientChoice.Label_LongThirdPerson'] }}
          </td>
        </ng-container>

        <!-- fieldColor Column -->
        <ng-container matColumnDef="fieldColor">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Colour</th>
          <td *matCellDef="let row" [ngStyle]="{ background: row['Status.Invitation.Colour'] }" mat-cell></td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="Actions" stickyEnd>
          <th *matHeaderCellDef mat-header-cell>Actions</th>
          <td *matCellDef="let row" mat-cell>


            <app-invitation-actions
            [customerID]="row['Prospect_key']"
            [invitationID]="row['ID']"
            [contractID]="row['QuotationORContract_Key']"
            [merchantID]="row['Merchant_Key']"
            [dentistID]="row['DentistContact_key']"
            [campaignID]="row['Campaign_Key']"
            [instantOfferID]="row['Instant_Finance_Offer_Key']"
            [landingPageID]="row['Document_Link_key']"
            [invitation]="row"
            [items]="items"
            [productID]="row['Product_Key']"


            (getDeletedInvitationID)="getDeletedInvitationID($event)"
            (getInvitationUpdate)="getInvitationUpdate($event)"


            ></app-invitation-actions>

            <!-- <button [matMenuTriggerFor]="menu" mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu class="actionMenu" #menu="matMenu">



              <button (click)="openInviteViewDialog(row['ID'])" mat-menu-item>
                <mat-icon>search</mat-icon>
                <span>Quick View</span>
              </button>
              <mat-divider></mat-divider>
              <button *ngIf="isModulePatientSMSActive == true && row['Prospect_key']"
                (click)="sendSMS(row['ID'],row['Prospect_key'], row['Merchant_Key'])" mat-menu-item>
                <mat-icon class="fas fa-sms"></mat-icon>
                <span>Send SMS</span>
              </button>


              <button *ngIf=" row['Prospect_key']" (click)="sendEmail(row['Prospect_key'])" mat-menu-item>
                <mat-icon class="fas fa-at"></mat-icon>
                <span>Send Email</span>
              </button>


              <mat-divider></mat-divider>
              <button *ngIf="isModulePatientPortalAccessActive == true"
                (click)="openAuthCodeDialog(row['ID'], row['Prospect_key'], row['Merchant_Key'])" mat-menu-item>
                <mat-icon>vpn_key</mat-icon>
                <span>{{"KEYWORD.patient" | translate | titlecase}} Portal Access</span>
              </button>

              <button *ngIf="row['Instant_Finance_Offer_Key']"
                (click)="viewInstantOffer(row['Instant_Finance_Offer_Key'])" mat-menu-item>
                <mat-icon class="fas fa-dollar-sign"></mat-icon>
                <span>View Finance Offer</span>
              </button>

              <button *ngIf="row['ID'] && isModuleAppointmentActive == true && isAppointmentDisabled != true"
                (click)="requestAppointment(row['ID'])" mat-menu-item>
                <mat-icon>alarm_add</mat-icon>
                <span>New appointment</span>
              </button>

              <button *ngIf="row['ID'] && row['Campaign_Key']" (click)="openCampaign(row['Campaign_Key'])"
                mat-menu-item>
                <mat-icon>language</mat-icon>
                <span>View Campaign</span>
              </button>

              <button *ngIf="row['ID'] && row['Document_Link_key']"
                (click)="openDocumentLandingPage(row['Document_Link_key'])" mat-menu-item>
                <mat-icon class="fas fa-folder-open"></mat-icon>
                <span>View Landing Page</span>
              </button>

              <mat-divider></mat-divider>

              <button (click)="createNote(row)" mat-menu-item>
                <mat-icon>note_add</mat-icon>
                <span>Create Note</span>
              </button>

              <button (click)="viewNotes(row['Prospect_key'])" mat-menu-item>
                <mat-icon class="fa fa-list-alt"></mat-icon>
                <span>View notes</span>
              </button>

              <mat-divider></mat-divider>

              <button [matMenuTriggerFor]="submenuTools" mat-menu-item>
                <mat-icon class="fas fa-envelope"></mat-icon>
                Invitation Tools
              </button>

              <mat-menu #submenuTools="matMenu">
                <button (click)="resendInvitation(row)" mat-menu-item>
                  <mat-icon>refresh</mat-icon>
                  <span>Resend Invitation</span>
                </button>

                <button (click)="openCloneInvitationModal(row['ID'], row['Prospect_key'])" mat-menu-item>
                  <mat-icon>file_copy</mat-icon>
                  <span>Clone invitation</span>
                </button>

                <button *ngIf="row['ID'] && isAdminOrPromoter == true" (click)="wakandaFiles(row['ID'])" mat-menu-item>
                  <mat-icon>attach_file</mat-icon>
                  <span>Wakanda Files</span>
                </button>

                <button *ngIf="row['ID'] && isAdminOrPromoter == true" (click)="redirect(row)" mat-menu-item>
                  <mat-icon>link</mat-icon>
                  <span>Invitation link</span>
                </button>

                <mat-divider></mat-divider>

                <button (click)="openInviteViewDetails(row['ID'], row['Prospect_key'])" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span>Detailed invitation view</span>
                </button>

                <button *ngIf="row['contractID']" (click)="openQuickViewDialog(row['contractID'])" mat-menu-item>
                  <mat-icon>payment</mat-icon>
                  <span>View Contract</span>
                </button>
                <button *ngIf="
                    row['Status.Invitation.Short_NE'] != 'Expired' &&
                    row['WakandaID'] &&
                    isModuleTreatmentTemplateActive == true
                  " (click)="createTemplate(row['WakandaID'])" mat-menu-item>
                  <mat-icon>assignment</mat-icon>
                  <span>Create {{"KEYWORD.Treatment" | translate}} Template</span>
                </button>

                <mat-divider></mat-divider>
                <button *ngIf="row['ID']" (click)="invitationLog(row['ID'])" mat-menu-item>
                  <mat-icon class="fa fa-list-alt"></mat-icon>
                  <span>Invitation Events</span>
                </button>

                <button *ngIf="
                    row['Prospect_key'] &&
                    row['Prospect_key'] != '00000000000000000000000000000000' &&
                    row['Prospect_key'] != '20202020202020202020202020202020'
                  " (click)="patientLog(row['Prospect_key'])" mat-menu-item>
                  <mat-icon class="fa fa-list-alt"></mat-icon>
                  <span>View {{ "KEYWORD.patient" | translate }} logs</span>
                </button>
              </mat-menu>

              <mat-divider></mat-divider>

              <button [matMenuTriggerFor]="submenuProfiles" mat-menu-item>
                <mat-icon>portrait</mat-icon>
                Information - {{ row.FirstName | lowercase | ucfirst }} {{ row.LastName | lowercase | ucfirst }}
              </button>
              <mat-menu #submenuProfiles="matMenu">
                <button *ngIf="
                    row['Prospect_key'] &&
                    row['Prospect_key'] != '00000000000000000000000000000000' &&
                    row['Prospect_key'] != '20202020202020202020202020202020'
                  " (click)="viewPatient(row['Prospect_key'])" mat-menu-item>
                  <mat-icon class="fas fa-user"></mat-icon>
                  <span>Profile - {{ row.FirstName | lowercase | ucfirst }}
                    {{ row.LastName | lowercase | ucfirst }}</span>
                </button>
                <button
                  *ngIf="row['DentistContact_key'] && row['DentistContact_key'] != '00000000000000000000000000000000'"
                  (click)="viewOperator(row['DentistContact_key'])" mat-menu-item>
                  <mat-icon class="fas fa-user-mat"></mat-icon>
                  <span>{{ "KEYWORD.dentist" | translate | titlecase }} Profile</span>
                </button>
                <button *ngIf="
                    row['Merchant_Key'] && row['Merchant_Key'] != '00000000000000000000000000000000' && !merchantID
                  " (click)="viewMerchant(row['Merchant_Key'])" mat-menu-item>
                  <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
                  <span>Merchant Profile</span>
                </button>

                <mat-divider></mat-divider>

                <button *ngIf="row['Email']" (click)="contact(row['ID'])" mat-menu-item>
                  <mat-icon>contact_mail</mat-icon>
                  <span>Contact - {{ row.FirstName | lowercase | ucfirst }}
                    {{ row.LastName | lowercase | ucfirst }}</span>
                </button>

                <button *ngIf="row['Mobile']" (click)="contactBySMS(row['ID'])" mat-menu-item>
                  <mat-icon>contact_mail</mat-icon>
                  <span>Contact By SMS - {{ row.FirstName | lowercase | ucfirst }}
                    {{ row.LastName | lowercase | ucfirst }}</span>
                </button>

                <mat-divider></mat-divider>

                <button *ngIf="row['Email']" (click)="updateProfileInvite(row['Prospect_key'])" mat-menu-item>
                  <mat-icon>face</mat-icon>
                  <span>{{"KEYWORD.patient" | translate | titlecase}} profile invitation</span>
                </button>
              </mat-menu>

              <mat-divider></mat-divider>

              <div [hidden]="row['Date.Commenced'] != '0000-00-00' || row['Voided'] != '0'">
                <button [matMenuTriggerFor]="submenu" mat-menu-item>
                  <mat-icon>help_outline</mat-icon>
                  Set status
                </button>
                <mat-menu #submenu="matMenu">
                  <button *ngIf="context != 'retail'" (click)="payCashInvitation(row['ID'], row['Prospect_key'])"
                    mat-menu-item>
                    <mat-icon>thumb_up</mat-icon>
                    Proceeding with Cash
                  </button>
                  <button *ngIf="context != 'retail' && row['CashDiscount.Offered.YesNo'] == '1'"
                    (click)="payCashInvitationWithDiscount(row['ID'], row['Prospect_key'])" mat-menu-item
                    matTooltip="Proceeding with Cash plus Discount">
                    <mat-icon>thumb_up</mat-icon>
                    Proceeding with Cash plus Discount
                  </button>
                  <button *ngIf="context != 'retail'" (click)="deferInvitation(row['ID'], row['Prospect_key'])"
                    mat-menu-item>
                    <mat-icon>alarm</mat-icon>
                    Deferring
                  </button>
                  <button *ngIf="context != 'retail'" (click)="changesToInvitation(row['ID'], row['Prospect_key'])"
                    mat-menu-item>
                    <mat-icon>edit</mat-icon>
                    Requires Changes
                  </button>
                  <button (click)="notProceedingInvitation(row['ID'], row['Prospect_key'])" mat-menu-item>
                    <mat-icon>report_problem</mat-icon>
                    Not Proceeding
                  </button>
                  <button *ngIf="
                      (context != 'retail' &&
                        (row['Status.Invitation.Short_NE'] == 'Discuss/Modify' ||
                          row['Status.Invitation.Short_NE'] == 'Not Proceeding' ||
                          row['Status.Invitation.Short_NE'] == 'Pay Cash/Card')) ||
                      row['Status.Invitation.Short_NE'] == 'Proceed - Cash/Card-Discount' ||
                      row['Status.Invitation.Short_NE'] == 'Proceed - Cash/Card-NoDiscount'
                    " (click)="resetPatientChoice(row['ID'], row['Voided'], row['Prospect_key'])" mat-menu-item>
                    <mat-icon>settings_backup_restore</mat-icon>
                    Reset {{ "KEYWORD.patient" | translate | titlecase }} Decision
                  </button>
                  <mat-divider></mat-divider>
                  <button *ngIf="
                      row['Voided'] == '0' &&
                      row['Status.Invitation.Short_NE'] != 'Submitted' &&
                      row['Status.Application.Short_NE']?.indexOf('Contract -') == -1 &&
                      row['Status.Invitation.Short_NE'] != 'Proceeded' &&
                      row['Status.Invitation.Short_NE'] != 'Pay Cash/Card'
                    " (click)="deleteInvitation(row['ID'], row['Prospect_key'])" mat-menu-item>
                    <mat-icon class="warn-color">delete</mat-icon>
                    <span class="warn-color">Archive</span>
                  </button>
                </mat-menu>
              </div>

              <mat-divider [hidden]="
                  row['Date.Commenced'] == '0000-00-00' ||
                  row['Voided'] != '0' ||
                  row['Status.Invitation.Short_NE'] == 'Submitted' ||
                  row['Status.Application.Short_NE']?.indexOf('Contract -') == -1 ||
                  row['Status.Invitation.Short_NE'] == 'Proceeded'
                ">
              </mat-divider>

              <div [hidden]="
                  row['Date.Commenced'] == '0000-00-00' ||
                  row['Voided'] != '0' ||
                  row['Status.Invitation.Short_NE'] == 'Submitted' ||
                  row['Status.Application.Short_NE']?.indexOf('Contract -') == -1 ||
                  row['Status.Invitation.Short_NE'] == 'Proceeded'
                ">
                <button (click)="deleteInvitation(row)" mat-menu-item>
                  <mat-icon class="warn-color">delete</mat-icon>
                  <span class="warn-color">Archive</span>
                </button>
              </div>
            </mat-menu> -->
          </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
      </table>

      <div class="row pagination flex">
        <p class="small info left">
          <mat-icon>info</mat-icon>
          <strong>What do all these statuses mean?</strong>See below for more information on how
          your {{ "KEYWORD.patient" | translate }}s interact
          with the platform.
        </p>

        <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions">
        </mat-paginator>
      </div>

      <!-- legacy table removed after a2b5c01 -->
    </mat-card>

    <app-message-response-legend></app-message-response-legend>
  </div>
  <div *ngIf="invitationID != null && displayInvitationView == true">
    <div class="full-width rel" *ngIf="showInvDetailInList == true">
      <button class="absPos btn-back absPosTop" (click)="cancelInviteViewDetails()" mat-raised-button color="accent">
        <mat-icon>chevron_left</mat-icon>
        <span>Show all {{ "KEYWORD.patient" | translate }} invitations</span>
      </button>
    </div>

    <app-invitation-view [membership]="membership" [invitationID]="invitationID"></app-invitation-view>

    <div class="full-width text-center" *ngIf="showInvDetailInList == false">
      <button class="mt" (click)="cancelInviteViewDetails()" mat-raised-button color="accent">
        <mat-icon>chevron_left</mat-icon>
        <span>Go Back</span>
      </button>
    </div>
  </div>
  <div *ngIf="invitationID != null && displayInvitationDetailEpver == true">
    <app-invitation-details-card [invitationID]="invitationID"></app-invitation-details-card>
    <div class="full-width text-center">
      <button class="mt" (click)="cancelInvitationDetailEpVer()" mat-raised-button color="accent">
        <mat-icon>chevron_left</mat-icon>
        Go Back
      </button>
    </div>
  </div>
</div>
<div *ngIf="invitationsDB.data.length == 0 && invitationsDB.firstLoadEvent != false">
  <app-empty-list-message></app-empty-list-message>
</div>
