import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { SubscriptionPlanService } from '../shared/subscription-plan.service';

import * as _ from 'lodash';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { Settings } from '../../../shared/types/settings';
import { SubscriptionPlanViewModalComponent } from '../subscription-plan-view-modal/subscription-plan-view-modal.component';

@Component({
  selector: 'app-subscription-plan-lookup',
  templateUrl: './subscription-plan-lookup.component.html',
  styleUrls: ['./subscription-plan-lookup.component.css'],
})
export class SubscriptionPlanLookupComponent implements OnInit {
  subscriptionPlans = [];
  typeFilter;

  @Input()
  viewOnClick = true;
  color = Settings.global['primaryColor'] || '#1e88e5';
  @Output()
  getResult = new EventEmitter();

  @Output()
  close = new EventEmitter();

  utils = new UtilsClass();
  showNextBtn = false;

  filterData = '';
  selectedPatient: any;
  utilsClass = new UtilsClass();
  sessionType;

  isModal = false;

  orderByFilter = 'Web_Label';

  @Input()
  title = 'Subscription plans lookup';

  @Input()
  description = 'Below is a list of Subscription plan available.';
  serviceRef;
  isDestoyed = false;
  isLoaded = false;
  itemsPerPage = 20;

  currentPage = 1;

  selectedResult;

  constructor(
    private authenticationService: AuthenticationService,
    private subscriptionPlanService: SubscriptionPlanService,
    private router: Router,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.viewOnClick != null) {
        this.viewOnClick = data.viewOnClick;
        this.isModal = true;
      }

      if (data.isModal != null) {
        this.isModal = data.isModal;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((res) => {
      this.sessionType = res;

      this.setup();
    });
  }

  setup() {
    this.typeFilter = null;

    const payload = {
      orderBy: 'Web_Label',
      isActive: true,
      limit: 500,
      section: 1,
    };

    this.serviceRef = this.subscriptionPlanService.getPromoterPlanList(payload, this.sessionType).subscribe((res) => {
      this.isLoaded = true;
      if (res && res.length > 0) {
        res = _.map(res, (item) => {
          if (item) {
            item.Price = Number(item.Price);
            if (item.Color) {
              if (item.Color.split('|')[0]) {
                item.color = item.Color.split('|')[0];
              }

              if (item.Color.split('|')[1]) {
                item.colorSecond = item.Color.split('|')[1];
              }
            } else {
              item.color = this.color;
            }

            item.search = '';
            if (item.Web_Label) {
              item.search = item.search + ' ' + item.Web_Label;
            }

            if (item.Web_Description) {
              item.search = item.search + ' ' + item.Web_Description;
            }

            if (item.Web_Features) {
              item.search = item.search + ' ' + item.Web_Features;
            }
            if (item.Marketing_Description) {
              item.search = item.search + ' ' + item.Marketing_Description;
            }
          }
          return item;
        });

        this.subscriptionPlans = res;

        const innerFunction = (section) => {
          section = section + 1;
          payload.section = section;

          this.serviceRef = this.subscriptionPlanService
            .getPromoterPlanList(payload, this.sessionType)
            .subscribe((res) => {
              if (res.length > 0) {
                res = _.map(res, (item) => {
                  if (item) {
                    item.Price = Number(item.Price);
                    if (item.Color) {
                      if (item.Color.split('|')[0]) {
                        item.color = item.Color.split('|')[0];
                      }

                      if (item.Color.split('|')[1]) {
                        item.colorSecond = item.Color.split('|')[1];
                      }
                    } else {
                      item.color = this.color;
                    }

                    item.search = '';
                    if (item.Web_Label) {
                      item.search = item.search + ' ' + item.Web_Label;
                    }

                    if (item.Web_Description) {
                      item.search = item.search + ' ' + item.Web_Description;
                    }

                    if (item.Web_Features) {
                      item.search = item.search + ' ' + item.Web_Features;
                    }
                    if (item.Marketing_Description) {
                      item.search = item.search + ' ' + item.Marketing_Description;
                    }
                  }
                  return item;
                });

                this.subscriptionPlans = this.subscriptionPlans.concat(res);

                if (this.isDestoyed != true) {
                  innerFunction(section);
                }
              } else {
                return true;
              }
            });
        };

        if (this.isDestoyed != true) {
          innerFunction(1);
        }
      }
    });
  }

  getSubscriptionPlanImage(id, date) {
    return this.subscriptionPlanService.getPromoterPlanThumbnailImageStreamLink(id, date);
  }

  orderBy(d) {
    if (d) {
      let isUpOrder = true;

      if (d && d[0] == '-') {
        isUpOrder = false;
      }

      if (this.orderByFilter === d) {
        if (isUpOrder == true) {
          this.orderByFilter = '-' + d;
        } else {
          this.orderByFilter = d.replace('-', '');
        }
      } else {
        this.orderByFilter = d;
      }
    }
  }

  viewSubPlan(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(SubscriptionPlanViewModalComponent, {
        data: {
          subscriptionPlanID: id,
          displaySubscribeButton: false,
        },
        width: '1000px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res) {
          ref.close();
        }
      });
    }
  }

  changePage(e) {
    if (e) {
      this.currentPage = e;
    }
  }

  backToOne() {
    this.currentPage = 1;
  }

  closeEvent() {
    this.close.emit(true);
  }

  selectPlan(d) {
    this.getResult.emit(d);
  }
}
