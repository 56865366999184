<div class="row clearfix flex full-width">
  <div class="full-width input-block" *ngFor="let m of metadatas; let index = index">
    <h4 class="primary-color rm-m sm-mb">{{ getLabel(m['Label']) }}</h4>

    <mat-form-field class="full-width clearfix" *ngIf="m && !m['Lookup'] && marketingUserData" appearance="outline">
      <mat-label>{{ getPlaceHolderFromLabel(m['Label']) }}</mat-label>
      <input
        class="full-width"
        [(ngModel)]="marketingUserData[m['ID']]"
        [required]="getRequired(m['Mandatory'])"
        (ngModelChange)="sendResult(m['ID'], m['Type'])"
        type="{{ getType(m['Type']) }}"
        name="subject {{ index }}"
        matInput
        placeholder="{{ getPlaceHolderFromLabel(m['Label']) }}"
      />
    </mat-form-field>

    <app-lookup-input
      *ngIf="m && m['Lookup'] && m['Lookup']['Table'] && m['Lookup']['Table'] == 'CodeLookup' && marketingUserData"
      [type]="'codeLookup'"
      [lookupCodeAttribute]="m['Lookup']['CodeField'] || 'Code'"
      [lookupLabelAttribute]="m['Lookup']['LabelField'] || 'Label'"
      [category]="m['Lookup']['Category']"
      [isRequired]="getRequiredBoolean(m['Mandatory'])"
      [label]="m['Label']"
      (getLookupCode)="getLookupCode($event, m['ID'])"
    >
    </app-lookup-input>

    <app-lookup-input
      *ngIf="m && m['Lookup'] && m['Lookup']['Table'] && m['Lookup']['Table'] == 'SimpleLookup' && marketingUserData"
      [type]="'simpleLookup'"
      [lookupCodeAttribute]="'ID'"
      [lookupLabelAttribute]="m['Lookup']['LabelField'] || 'Label'"
      [category]="m['Lookup']['Category']"
      [isRequired]="getRequiredBoolean(m['Mandatory'])"
      [label]="m['Label']"
      (getLookupCode)="getLookupCode($event, m['ID'])"
    >
    </app-lookup-input>

    <app-lookup-input
      *ngIf="m && m['Lookup'] && m['Lookup']['Table'] && m['Lookup']['Table'] == 'ProductGroup' && marketingUserData"
      [type]="'productGroup'"
      [lookupCodeAttribute]="m['Lookup']['CodeField']"
      [lookupLabelAttribute]="m['Lookup']['LabelField']"
      [isRequired]="getRequiredBoolean(m['Mandatory'])"
      [label]="m['Label']"
      (getLookupCode)="getLookupCode($event, m['ID'])"
    >
    </app-lookup-input>
  </div>
</div>
