import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ThirdPartyService } from '../shared/third-party.service';

@Component({
  selector: 'app-third-party-client-picture',
  templateUrl: './third-party-client-picture.component.html',
  styleUrls: ['./third-party-client-picture.component.css'],
})
export class ThirdPartyClientPictureComponent implements OnInit {
  @Input() thirdPartyClientID;

  @Input()
  hideZoomButton = false;

  @Output() close = new EventEmitter();

  profileLink;

  constructor(
    private ThirdPartyService: ThirdPartyService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.thirdPartyClientID = data;
    }
  }

  ngOnInit() {
    if (this.thirdPartyClientID) {
      this.getProfilePicture();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.thirdPartyClientID.previousValue != changes.thirdPartyClientID.currentValue) {
      if (this.thirdPartyClientID) {
        this.getProfilePicture();
      } else {
        this.profileLink = null;
      }
    }
  }

  getProfilePicture() {
    this.profileLink = null;

    this.profileLink = this.ThirdPartyService.getThirdPartyClientImageStreamLink(this.thirdPartyClientID);
  }

  closeEvent() {
    this.close.emit(true);
  }
}
