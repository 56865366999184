import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { UtilsService } from '../../../../shared/services/utils.service';

@Injectable()
export class WishlistService {
  private baseUrl = environment.nodeUrl;

  private wishlist = new BehaviorSubject<WishlistItem[]>([]);
  readonly wishlist$ = this.wishlist.asObservable();

  constructor(private httpClient: HttpClient, private utilsService: UtilsService) {}

  getWishlist$() {
    return this.wishlist$;
  }

  setWishlist(wishlist: WishlistItem[]) {
    this.wishlist.next(wishlist);
  }

  createWishlistItem(newWishlistItem: NewWishlistItem, wishlist?: WishlistItem[]) {
    const endpoint = 'wishlist';
    const url = `${this.baseUrl}/${endpoint}`;

    return this.httpClient.post<NewWishlistItemResponse>(url, newWishlistItem).pipe(
      map((res) => res['data']),
      tap((wishlistItem: WishlistItem) => {
        if (wishlist) {
          wishlist.push(wishlistItem);
          const sortedWishlistItem = wishlist.sort((a, b) => a.Label.localeCompare(b.Label));
          const newWishlist = [...sortedWishlistItem];
          this.wishlist.next(newWishlist);
        }
      })
    );
  }

  updateWishlistItem(wishlistItem: WishlistItem, wishlist?: WishlistItem[]) {
    const endpoint = 'wishlist';
    const url = `${this.baseUrl}/${endpoint}/${wishlistItem.ID}`;

    return this.httpClient.put<NewWishlistItemResponse>(url, wishlistItem).pipe(
      map((res) => res['data']),
      tap((updatedWishlistItem: WishlistItem) => {
        if (wishlist) {
          const modifiedWishlist = wishlist.filter((oldWishlistItem) => oldWishlistItem.ID !== wishlistItem.ID);

          modifiedWishlist.push(updatedWishlistItem);

          const sortedWishlist = modifiedWishlist.sort((a, b) => a.Label.localeCompare(b.Label));
          const updatedWishlist = [...sortedWishlist];
          this.wishlist.next(updatedWishlist);
        }
      })
    );
  }

  deleteWishlistItem(wishlistItem: WishlistItem, wishlist?: WishlistItem[]) {
    const endpoint = 'wishlist';
    const url = `${this.baseUrl}/${endpoint}/${wishlistItem.ID}`;

    return this.httpClient.delete<NewWishlistItemResponse>(url).pipe(
      map((res) => res['data']),
      tap(() => {
        if (wishlist) {
          const deletedWishlist = wishlist.filter((oldWishlistItem) => oldWishlistItem.ID !== wishlistItem.ID);

          const sortedWishlist = deletedWishlist.sort((a, b) => a.Label.localeCompare(b.Label));
          const updatedWishlist = [...sortedWishlist];
          this.wishlist.next(updatedWishlist);
        }
      })
    );
  }

  fetchWishlist(payload: { ID?: string; Label?: string; Code?: string; Description?: string }) {
    const params: HttpParams = this.utilsService.getHttpParamsFromPayload(payload);

    const options = { params };

    const endpoint = 'wishlist';
    const url = `${this.baseUrl}/${endpoint}`;

    return this.httpClient.get<WishlistItemResponse>(url, options).pipe(map((res) => res['data']));
  }

  fetchPublicWishlist(payload: { ID?: string; Label?: string; Code?: string; Description?: string }) {
    const params: HttpParams = this.utilsService.getHttpParamsFromPayload(payload);

    const options = { params };

    const endpoint = 'wishlist/public';
    const url = `${this.baseUrl}/${endpoint}`;

    return this.httpClient.get<WishlistItemResponse>(url, options).pipe(map((res) => res['data']));
  }

  fetchWishlistIDs(payload: { WishListItems_IDs?: string }) {
    const params: HttpParams = this.utilsService.getHttpParamsFromPayload(payload);

    const options = { params };

    const endpoint = 'wishlist/IDs';
    const url = `${this.baseUrl}/${endpoint}`;

    return this.httpClient.get<WishlistItemResponse>(url, options).pipe(map((res) => res['data']));
  }
}

export class WishlistItemLabelExistsValidator {
  static createValidator(wishlistService: WishlistService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return timer(1000).pipe(
        switchMap(() => {
          return wishlistService.fetchWishlist({ Label: control.value }).pipe(
            map((wishlist) => (wishlist.length > 0 ? { exists: true } : null)),
            first()
          );
        })
      );
    };
  }
}

class WishlistItemResponse {
  success: boolean;
  datetime: string;
  data: WishlistItem[];
}

class NewWishlistItemResponse {
  success: boolean;
  datetime: string;
  data: WishlistItem;
}

export class WishlistItem {
  readonly DateTimeCreated?: string;
  readonly LastModified?: string;
  readonly LastModified_Human?: string;
  readonly Is_Hidden?: string;
  ID: string;
  Code: WishlistCategory;
  Label: string;
  Description: string;
}

export class NewWishlistItem {
  /**
   * code represents the category property
   * */
  Label: string;
  Code: string;
  Description: string;
}

export type WishlistCategory = 'cosmetic' | 'functional';

export const WISHLIST_CATEGORIES: WishlistCategory[] = ['cosmetic', 'functional'];
