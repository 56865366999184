<!-- header -->
<div class="container">
  <ng-container *ngIf="isDone != true" @simpleFadeAnimation>
    <form #addForm="ngForm">
      <mat-card [ngClass]="{ 'no-modal-height': isModal != true }">
        <div class="card-header primary-gradient-img clearfix inModal">
          <!-- close modal -->
          <div class="row clearfix mb">
            <button class="btn-close btn-clear mat-button" *ngIf="isModal == true" (click)="closeEvent()">
              <mat-icon>clear</mat-icon>
            </button>
            <div class="row titleArea clearfix">
              <app-drugs-thumbnail class="avatar" [hideZoomButton]="false" [drugID]="drugID"></app-drugs-thumbnail>
              <h2 class="summary-header rm-m" *ngIf="drugID && drug && drug['name']">
                {{ drug['name'] }}
                <span class="subLabel truncate">{{ drug['description'] }}</span>
              </h2>
              <h2 class="summary-header rm-m" *ngIf="!drugID">New medication</h2>
            </div>
          </div>
          <div class="date-chip-area pull-right mt">
            <p class="small rm-m mr" *ngIf="drugID && drug && drug.DateTimeCreated">
              <mat-icon class="fas fa-clock"></mat-icon>
              {{ drug.DateTimeCreated | TimeLabelPast }} ago
            </p>
            <p class="clearfix small rm-m mr ml" *ngIf="drug['isActive'] == true">
              <span class="chip-enable mat-elevation-z1">Active</span>
            </p>
            <p class="clearfix small rm-m mr ml" *ngIf="drug['isActive'] != true">
              <span class="chip-disable mat-elevation-z1">Not active</span>
            </p>

            <p class="clearfix small rm-m" *ngIf="drug['type']">
              <span class="chip-enable mat-elevation-z1">{{ drug['type'] | ucfirst }}</span>
            </p>
          </div>
        </div>

        <!-- user interface to select prescription drugs -->

        <div class="row clearfix drugs-input-container">
          <div class="row clearfix flex mt mb">
            <!--  Image Upload -->
            <div class="uploadBlock left full-width">
              <div class="row clearfix pic-img image">
                <div class="notUploaded" *ngIf="isImageFile == false && isEditMode == false">
                  <app-ep-document-view-image
                    class="clearfix notYetUploaded"
                    [defaultPicture]="
                      'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/drug-image.png)'
                    "
                  >
                  </app-ep-document-view-image>
                </div>
                <div class="displayUploadImg text-center" *ngIf="isImageFile == false && isEditMode == true">
                  <app-ep-document-view-image
                    class="clearfix full-width profile-pic-container"
                    [defaultPicture]="
                      'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/drug-image.png)'
                    "
                    [link]="imageLink"
                    [hideZoomButton]="false"
                  >
                  </app-ep-document-view-image>
                </div>
                <div class="displayUploadImg mt" *ngIf="isImageFile == true">
                  <img class="display-image-uploaded" [src]="imageSrc"/>
                </div>
              </div>
              <div class="row clearfix">
                <h4 class="sr-title sm-h4 rm-mb">Upload Medication image</h4>
                <app-file-uploader
                  class="text-center clearfix full-width"
                  [allowedExtensionTypes]="['image']"
                  [isFixedAspectRatio]="true"
                  [isImageCropping]="true"
                  [canSkip]="false"
                  [limit]="1"
                  [uploadDirectly]="true"
                  [isDragable]="true"
                  [title]="'CLICK HERE to upload'"
                  (onCompleteAll)="onCompleteImageAll($event)"
                >
                </app-file-uploader>
              </div>
            </div>
            <!--  Picture Upload -->
            <div class="uploadBlock right full-width">
              <div class="row clearfix pic-img image">
                <div class="notUploaded" *ngIf="isThumbnailFile == false && isEditMode == false">
                  <app-ep-document-view-image
                    class="clearfix notYetUploaded"
                    [defaultPicture]="
                      'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/drug-icon.png)'
                    "
                  >
                  </app-ep-document-view-image>
                </div>
                <div class="displayUploadImg text-center" *ngIf="isThumbnailFile == false && isEditMode == true">
                  <app-ep-document-view-image
                    class="clearfix full-width profile-pic-container"
                    [defaultPicture]="
                      'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/drug-icon.png)'
                    "
                    [link]="thumbnailLink"
                    [hideZoomButton]="false"
                  >
                  </app-ep-document-view-image>
                </div>
                <div class="displayUploadImg mt" *ngIf="isThumbnailFile == true">
                  <img class="display-image-uploaded" [src]="thumbnailSrc"/>
                </div>
              </div>
              <div class="row clearfix" *ngIf="isDone != true">
                <h4 class="sr-title sm-h4 rm-mb">Upload thumbnail image</h4>
                <app-file-uploader
                  class="text-center clearfix full-width"
                  [allowedExtensionTypes]="['image']"
                  [isImageCropping]="true"
                  [canSkip]="false"
                  [limit]="1"
                  [uploadDirectly]="true"
                  [isDragable]="true"
                  [title]="'CLICK HERE to upload'"
                  (onCompleteAll)="onCompleteThumbnailAll($event)"
                >
                </app-file-uploader>
              </div>
            </div>
          </div>

          <h3 class="sr-title">Primary details</h3>
          <div class="row clearfix flex">
            <!-- Drug name -->
            <mat-form-field class="full-width animate" appearance="outline">
              <mat-label>Name of medication</mat-label>
              <input
                class="mat-block"
                [(ngModel)]="drug.name"
                matInput
                placeholder="Enter the medication name"
                name="otherDrugNameInput"
                required
              />
            </mat-form-field>
          </div>
          <div class="row clearfix flex">
            <!-- Drug type -->
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Type of medication</mat-label>
              <mat-select
                class="full-width animate"
                [(ngModel)]="drug.drugTypeCode"
                placeholder="Select type of medication"
                name="drugType"
              >
                <mat-option *ngFor="let DrugType of drugTypes | OrderArrayObject: 'Label'" [value]="DrugType.Code">
                  {{ DrugType.Label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!-- drug dose type/method -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Side Effects </mat-label>
              <mat-select
                class="full-width animate"
                [(ngModel)]="drug.sideEffects"
                placeholder="Side Effects"
                name="drugSideEffects"
                multiple
              >
                <mat-option *ngFor="let item of drugSideEffects | OrderArrayObject: 'Label'" [value]="item.Code">
                  {{ item.Label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="row clearfix flex">
            <!-- drug dosage amount -->

            <mat-form-field class="full-width animate" appearance="outline">
              <mat-label>Dosage measurement options</mat-label>
              <input
                class="mat-block"
                [(ngModel)]="drug.dosageOptions"
                matInput
                placeholder="Comma separated (50, 100, 150, etc)"
                name="drugDoseOptions"
              />
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>How is it taken?</mat-label>
              <mat-select
                class="full-width animate"
                [(ngModel)]="drug.doseTypeCode"
                placeholder="Select how your dose is taken"
                name="drugTakenType"
              >
                <mat-option
                  *ngFor="let DrugDoseType of doseTypes | OrderArrayObject: 'Label'"
                  [value]="DrugDoseType.Code"
                >
                  {{ DrugDoseType.Label }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>How is dosage measured?</mat-label>
              <mat-select
                class="full-width animate"
                [(ngModel)]="drug.doseUnitCode"
                placeholder="Select the dose you're prescribed"
                name="drugTakenMeasurement"
              >
                <mat-option
                  *ngFor="let DrugDoseMeasurement of doseUnitTypes | OrderArrayObject: 'Label'"
                  [value]="DrugDoseMeasurement.Code"
                >
                  {{ DrugDoseMeasurement.Label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="row clearfix flex">
            <!-- Drug Illnesses -->
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Illnesses</mat-label>
              <mat-select
                class="full-width"
                [(ngModel)]="drug.illnesses"
                placeholder="Select Illnesses"
                name="drugIllnesses"
                multiple
              >
                <mat-option *ngFor="let item of illnessTypes | OrderArrayObject: 'Label'" [value]="item.Code">
                  {{ item.Label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!-- Drug Alergies -->
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Alergies</mat-label>
              <mat-select
                class="full-width"
                [(ngModel)]="drug.allergies"
                placeholder="Select Alergies"
                name="allergyTypes"
                multiple
              >
                <mat-option
                  *ngFor="let item of allergyTypes | OrderArrayObject: 'DisplayLabel'"
                  [value]="item.Category"
                >
                  {{ item.DisplayLabel }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row clearfix">
            <h3 class="sr-title rm-mt">Supplier information</h3>
            <div class="clearfix row flex full-width">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Manufacturer</mat-label>
                <mat-select class="" [(ngModel)]="drug.manufacturerID" name="Manufacturer" placeholder="Manufacturer">
                  <mat-option *ngFor="let m of manufacturers | OrderArrayObject: 'Name'" [value]="m.ID">
                    <app-beneficiary-view-picture
                      class="supplierIcon2-drug-create text-center mr"
                      *ngIf="m"
                      [beneficiaryID]="m['ID']"
                    >
                    </app-beneficiary-view-picture>

                    <span class="option-label"> {{ m.Name }} </span>
                  </mat-option>
                </mat-select>
                <button
                  class=""
                  *ngIf="drug.manufacturerID"
                  (click)="$event.stopPropagation(); viewSupplier(drug.manufacturerID)"
                  matTooltip=" View Manufacturer"
                  color="accent"
                  mat-button
                  matSuffix
                >
                  <mat-icon>visibility</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Distributor</mat-label>
                <mat-select class="" [(ngModel)]="drug.distributorID" name="Distributor" placeholder="Distributor">
                  <mat-option *ngFor="let d of distributors | OrderArrayObject: 'Name'" [value]="d.ID">
                    <app-beneficiary-view-picture
                      class="supplierIcon2-drug-create text-center mr"
                      *ngIf="d"
                      [beneficiaryID]="d['ID']"
                    >
                    </app-beneficiary-view-picture>
                    <span class="option-label"> {{ d.Name }} </span>
                  </mat-option>
                </mat-select>
                <button
                  class=""
                  *ngIf="drug.distributorID"
                  (click)="$event.stopPropagation(); viewSupplier(drug.distributorID)"
                  matTooltip=" View Distributor"
                  color="accent"
                  mat-button
                  matSuffix
                >
                  <mat-icon>visibility</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </div>
          <h3 class="sr-title">Additional details</h3>
          <div class="row clearfix">
            <!-- Drug Description -->
            <mat-form-field class="full-width animate" appearance="outline">
              <mat-label>Description</mat-label>
              <textarea
                class="mat-block"
                [(ngModel)]="drug.description"
                matInput
                placeholder="Enter the medication description"
                name="drugDescription"
              ></textarea>
            </mat-form-field>
          </div>
          <div class="row clearfix">
            <!-- Drug General Information -->
            <mat-form-field class="full-width animate" appearance="outline">
              <mat-label>General Information</mat-label>
              <textarea
                class="mat-block"
                [(ngModel)]="drug.generalInformation"
                matInput
                placeholder="Enter the medication General Information"
                name="drugGeneralInfo"
              ></textarea>
            </mat-form-field>
          </div>
          <div class="row clearfix">
            <!-- Drug Warnings -->
            <mat-form-field class="full-width animate" appearance="outline">
              <mat-label>Warnings</mat-label>
              <textarea
                class="mat-block"
                [(ngModel)]="drug.warnings"
                matInput
                placeholder="Enter the medication Warnings"
                name="drugWarnings"
              ></textarea>
            </mat-form-field>
          </div>
          <div class="row clearfix">
            <!-- Drug Ingredients -->
            <mat-form-field class="full-width animate" appearance="outline">
              <mat-label>Ingredients</mat-label>
              <textarea
                class="mat-block"
                [(ngModel)]="drug.ingredients"
                matInput
                placeholder="Enter the medication Ingredients"
                name="drugIngredients"
              ></textarea>
            </mat-form-field>
          </div>
          <div class="row clearfix">
            <!-- Drug Directions -->
            <mat-form-field class="full-width animate" appearance="outline">
              <mat-label>Directions</mat-label>
              <textarea
                class="mat-block"
                [(ngModel)]="drug.directions"
                matInput
                placeholder="Enter the medication Directions"
                name="drugDirections"
              ></textarea>
            </mat-form-field>
          </div>
          <div class="full-width clearfix">
            <mat-slide-toggle class="pull-right" *ngIf="isEditMode == true" [(ngModel)]="drug.isActive" name="isActive">
              Is Active?
            </mat-slide-toggle>
          </div>
        </div>
        <!-- form controller for adding or clearing selection -->
      </mat-card>

      <div class="row clearfix drugs-control-container text-center mt">
        <!-- add entered drug to list, clear form values to enter additional drug/s. Only activate when sufficient form data entered - Type, Name, Type of Dose, Amount of Dose -->
        <button
          class="text-center"
          *ngIf="isEditMode == true"
          [disabled]="!addForm.form.valid"
          (click)="save()"
          mat-raised-button
          color="accent"
        >
          <mat-icon>edit</mat-icon> Edit medication
        </button>
        <button
          class="text-center"
          *ngIf="isEditMode != true"
          [disabled]="!addForm.form.valid"
          (click)="save()"
          mat-raised-button
          color="accent"
        >
          <mat-icon>add</mat-icon> Add medication
        </button>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="isDone == true && drugID" @simpleFadeAnimation>
    <mat-card id="step-4" [ngClass]="{ 'no-modal-height': isModal != true }">
      <div class="card-header primary-gradient-img clearfix inModal">
        <!-- close modal -->
        <div class="row clearfix">
          <button class="btn-close btn-clear mat-button" *ngIf="isModal == true" (click)="closeEvent()">
            <mat-icon>clear</mat-icon>
          </button>
          <div class="row titleArea clearfix">
            <app-drugs-thumbnail class="avatar" [hideZoomButton]="false" [drugID]="drugID"></app-drugs-thumbnail>
            <h2 class="summary-header rm-m" *ngIf="drugID && drug && drug['name']">
              {{ drug['name'] }}
              <span class="subLabel">{{ drug['description'] }}</span>
            </h2>
            <h2 class="summary-header rm-m" *ngIf="!drugID">New medication</h2>
          </div>
        </div>
        <div class="date-chip-area pull-left">
          <p class="small rm-m mr" *ngIf="drugID && drug && drug.DateTimeCreated">
            <mat-icon class="fas fa-clock"></mat-icon>
            {{ drug.DateTimeCreated | TimeLabelPast }} ago
          </p>
          <p class="clearfix small rm-m mr ml" *ngIf="drug['isActive'] == true">
            <span class="chip-enable mat-elevation-z1">Active</span>
          </p>
          <p class="clearfix small rm-m mr ml" *ngIf="drug['isActive'] != true">
            <span class="chip-disable mat-elevation-z1">Not active</span>
          </p>

          <p class="clearfix small rm-m" *ngIf="drug['type']">
            <span class="chip-enable mat-elevation-z1">{{ drug['type'] | ucfirst }}</span>
          </p>
        </div>
      </div>

      <div class="animated fadeInDown">
        <div class="row clearfix text-center">
          <h2 class="sr-title inline-block">
            <span *ngIf="isEditMode == false">Medication Article created!</span>
            <span *ngIf="isEditMode == true">Medication Article details updated!</span>
          </h2>
        </div>
        <div class="row clearfix">
          <div class="full-width text-center">
            <hr class="mt"/>
            <div class="svg-box">
              <svg class="circular green-stroke">
                <circle class="path" cx="75" cy="75" r="50" fill="none" stroke-width="5" stroke-miterlimit="10" />
              </svg>
              <svg class="checkmark green-stroke">
                <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                  <path class="checkmark__check" fill="none" d="M616.306,283.025L634.087,300.805L673.361,261.53" />
                </g>
              </svg>
            </div>
            <div class="row clearfix">
              <div class="full-width text-center step-button-row">
                <button
                  class="step-next mr"
                  *ngIf="isModal == true"
                  (click)="closeEvent()"
                  mat-raised-button
                  color="primary"
                >
                  Close
                </button>
                <button class="step-next" class="ml mr" (click)="viewPage()" mat-raised-button color="accent">
                  <mat-icon>visibility</mat-icon> View Medication
                </button>
                <button class="step-next" class="ml mr" (click)="list()" mat-raised-button color="primary">
                  <mat-icon>list</mat-icon> Medication List
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </ng-container>
</div>
