import { Component, EventEmitter, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { TagCreateEditComponent } from '../../tags/tag-create-edit/tag-create-edit.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DentistService } from '../../dentist/shared/dentist.service';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MessageService } from '../shared/message.service';

import * as $ from 'jquery';
import * as _ from 'lodash';
import * as moment from 'moment';
import { CustomerProspectListServerComponent } from '../../customer-prospect/customer-prospect-list-server/customer-prospect-list-server.component';
import { MessageGroupCardListComponent } from '../message-group-card-list/message-group-card-list.component';
import { WizardComponent } from 'angular-archwizard';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { ConfirmDialogSingleButtonComponent } from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { InvitationLookupComponent } from '../../invitation/invitation-lookup/invitation-lookup.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { MarketingFilterCreateComponent } from '../../marketing-filter/marketing-filter-create/marketing-filter-create.component';
import { MessageUtil } from '../shared/messageUtil.type';
import { Settings } from '../../../shared/types/settings';
import { UtilsService } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-message-group-create',
  templateUrl: './message-group-create.component.html',
  styleUrls: ['./message-group-create.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: '*' }))),
    ]),
  ],
})
export class MessageGroupCreateComponent implements OnInit {
  @ViewChild(WizardComponent, { static: false }) public wizard: WizardComponent;
  isModal = false;
  tags = [];
  selectedTags = [];
  defaultSelectedTags = [];
  isPromoterOrAdmin = false;
  addTag = new EventEmitter();
  sendNow = true;
  merchantID;
  marketingConsent = null;
  _marketingConsent = false;
  merchant;
  contact;
  contacts = [];
  label;
  description;
  loadCard = true;

  messageLabel = 'SMS';

  serviceRef;
  isDestoyed = false;
  contactID;
  dateNow = moment().add(60, 'minutes').toDate();
  filterKeys = [];
  promoter;
  filterKeyIDs = [];
  close = new EventEmitter();
  card: any = {
    ID: '00000000000000000000',
    Salutation: 'Mr',
    FirstName: 'John',
    MiddleName: 'Al',
    Name: 'Doe',
    'mobiles.Number': '0061101010101',
    'phones.Number': '0062202020202',
    'emails.Email': 'john.doe@smileright.com.au',
    'addresses.Calculated': 'PO Box 7795 Cloisters Square 6850 Western Australia',
  };

  dateToBeSent = moment().add(2, 'hours').toDate();

  createdObject;

  _dateToBeSent = moment().add(2, 'hours').toDate();
  existingFilterArray = [];

  excludedIDs = [];
  excludedCards = [];
  _excludedCards = [];

  selectedIDs = [];
  selectedCards = [];
  _selectedCards = [];

  sessionType;
  message;
  messageRaw;
  _message;
  currentDentist;
  loadContact = false;
  loadMerchant = false;
  loadPromoter = false;
  targetNumber = null;

  messageGroupDraftID;
  messageGroupCloneID;

  messageGroup;

  isModuleBulkSMS = Settings.global['isModuleBulkSMSActive'];
  isRecordLoad = false;
  isSelectedCardLoad = false;
  isSelectedFilterLoad = false;

  isExcludedCardLoad = false;
  selectAll = true;

  hasMobile = true;
  hasEmail = false;

  messageUtil = new MessageUtil();
  isTagsLoad = false;
  isContactsLoad = false;
  isFilterLoad = false;
  constructor(
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private utilService: UtilsService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['messageGroupDraftID']) {
        this.messageGroupDraftID = params['messageGroupDraftID'];
      }

      if (params['messageGroupCloneID']) {
        this.messageGroupCloneID = params['messageGroupCloneID'];
      }
      this.utilService.getCurrentAccess().subscribe((access) => {
        if (access) {
          this.isModuleBulkSMS = access['isModuleBulkSMSActive'];
        }

        if (this.isModuleBulkSMS === false) {
          this.router.navigate(['404']);
        }
        this.authenticationService.getSessionType().subscribe((sessionType) => {
          this.sessionType = sessionType;

          this.authenticationService.getPromoter().subscribe((r) => {
            if (r && r.data) {
              this.promoter = r.data;
            } else if (r) {
              this.promoter = r;
            }
          });

          this.authenticationService.getCurrentPractice().subscribe((practice) => {
            if (practice && practice.ID) {
              this.merchant = practice;
              this.merchantID = practice.ID;
            }

            this.authenticationService.getCurrentDentist().subscribe((dentist) => {
              if (dentist && dentist.ID) {
                this.contact = dentist;
                this.contactID = dentist.ID;
                this.currentDentist = dentist;
              }

              if (this.messageGroupCloneID || this.messageGroupDraftID) {
                if (this.messageGroupCloneID) {
                  this.messageService
                    .getMessageGroupDetails(this.messageGroupCloneID, {}, this.sessionType)
                    .subscribe((res) => {
                      if (res && res.ID) {
                        this.messageGroup = res;
                        this.buildMessageGroup();
                      }
                    });
                } else if (this.messageGroupDraftID) {
                  this.messageService
                    .getMessageGroupDetails(this.messageGroupDraftID, {}, this.sessionType)
                    .subscribe((res) => {
                      if (res && res.ID) {
                        this.messageGroup = res;

                        if (res['Status.Code'] != 'Draft') {
                          this.isExcludedCardLoad = true;
                          this.isRecordLoad = true;
                          this.isSelectedCardLoad = true;
                          this.isSelectedFilterLoad = true;

                          this.createdObject = res;

                          this.wizard.goToStep(4);
                        } else {
                          this.buildMessageGroup();
                        }
                      }
                    });
                }
              } else {
                this.isExcludedCardLoad = true;
                this.isRecordLoad = true;
                this.isSelectedCardLoad = true;
                this.isSelectedFilterLoad = true;
                this.setup();
              }
            });
          });
        });
      });
    });
  }

  buildMessageGroup() {
    if (this.messageGroup && this.messageGroup.ID) {
      if (this.messageGroup['Sender_key']) {
        this.contactID = this.messageGroup['Sender_key'];
        this.contact = null;
      }

      if (this.messageGroup['selectAll'] === true) {
        this.selectAll = this.messageGroup['selectAll'];
      } else {
        this.selectAll = false;
      }

      if (this.messageGroup['Merchant_key']) {
        this.merchantID = this.messageGroup['Merchant_key'];
      }
      if (this.messageGroup['Tags']) {
        this.messageGroup['tags'] = this.messageGroup['Tags'].split('|');
      }

      this.marketingConsent = this.messageGroup.marketingConsent;
      this._marketingConsent = this.messageGroup.marketingConsent;
      if (this.messageGroup['Group_Filter_keys']) {
        this.messageGroup['filterKeys'] = this.messageGroup['Group_Filter_keys'].split('|');
      }
      if (this.messageGroup['Selected_Card_IDs']) {
        this.messageGroup['selectedIDs'] = this.messageGroup['Selected_Card_IDs'].split('|');
      }

      if (this.messageGroup['Excluded_Card_IDs']) {
        this.messageGroup['ExcludedIDs'] = this.messageGroup['Excluded_Card_IDs'].split('|');
      }

      if (this.messageGroup['Label']) {
        this.label = this.messageGroup['Label'];
      }

      if (this.messageGroup['Description']) {
        this.description = this.messageGroup['Description'];
      }

      if (this.messageGroup['Message']) {
        this.messageRaw = this.messageGroup['Message'];
        this._message = this.messageGroup['Message'];
      }

      if (this.messageGroup['Selected_Card_IDs']) {
        this.selectedIDs = this.messageGroup['Selected_Card_IDs'].split('|');

        let p = {
          tags: this.tags.join(','),
          section: 1,
          selectedIDs: this.selectedIDs.join(','),
          limit: 200,
          merchantID: this.merchantID,
          fields: 'ID,CalculatedName,mobiles.Number,emails.Email,LastModified,DateTimeCreated',
          hasMobile: this.hasMobile,
          hasEmail: this.hasEmail,
          marketingConsent: this.marketingConsent,
        };

        this.serviceRef = this.messageService.getCustomerProspectMergedList(p, this.sessionType).subscribe((res) => {
          if (res && res.length > 0) {
            this._selectedCards = res;
            this.selectedCards = res;
            const innerFunction = (section) => {
              section = section + 1;
              p.section = section;

              this.serviceRef = this.messageService
                .getCustomerProspectMergedList(p, this.sessionType)
                .subscribe((res) => {
                  if (res.length > 0) {
                    this._selectedCards = this._selectedCards.concat(res);
                    this.selectedCards = this.selectedCards.concat(res);

                    if (this.isDestoyed != true) {
                      innerFunction(section);
                    }
                  } else {
                    this.isSelectedCardLoad = true;
                    return true;
                  }
                });
            };

            if (this.isDestoyed != true) {
              innerFunction(1);
            }
          } else {
            this.isSelectedCardLoad = true;
          }
        });
      } else {
        this.isSelectedCardLoad = true;
      }

      if (this.messageGroup['Excluded_Card_IDs']) {
        this.excludedIDs = this.messageGroup['Excluded_Card_IDs'].split('|');

        let p = {
          tags: this.tags.join(','),
          section: 1,
          selectedIDs: this.excludedIDs.join(','),
          limit: 200,
          merchantID: this.merchantID,
          fields: 'ID,CalculatedName,mobiles.Number,emails.Email,LastModified,DateTimeCreated',
          hasMobile: this.hasMobile,
          hasEmail: this.hasEmail,
          marketingConsent: this.marketingConsent,
        };

        this.serviceRef = this.messageService.getCustomerProspectMergedList(p, this.sessionType).subscribe((res) => {
          if (res && res.length > 0) {
            this._excludedCards = res;
            this.excludedCards = res;
            const innerFunction = (section) => {
              section = section + 1;
              p.section = section;

              this.serviceRef = this.messageService
                .getCustomerProspectMergedList(p, this.sessionType)
                .subscribe((res) => {
                  if (res.length > 0) {
                    this._excludedCards = this._excludedCards.concat(res);
                    this.excludedCards = this.excludedCards.concat(res);

                    if (this.isDestoyed != true) {
                      innerFunction(section);
                    }
                  } else {
                    this.isExcludedCardLoad = true;
                    return true;
                  }
                });
            };

            if (this.isDestoyed != true) {
              innerFunction(1);
            }
          } else {
            this.isExcludedCardLoad = true;
          }
        });
      } else {
        this.isExcludedCardLoad = true;
      }
      this.isRecordLoad = true;
      this.setup();
    }
  }
  setup() {
    const pTag = {
      isPromoter: false,
      merchantID: this.merchantID,
      fields: 'Label',
    };

    this.messageService.getTagsList(pTag, this.sessionType).subscribe((res) => {
      if (res) {
        this.tags = _.map(res, (item) => {
          if (item && item.Label) {
            return item.Label;
          }

          return null;
        });

        if (this.messageGroup && this.messageGroup.tags && this.messageGroup.tags.length > 0) {
          this.defaultSelectedTags = this.messageGroup.tags;
          this.selectedTags = this.messageGroup.tags;
        }
      }

      this.isTagsLoad = true;
    });

    const __p = {
      isActive: true,
      merchantID: this.merchantID,
      selectQuery: true,
    };
    this.messageService.getFilterList(__p, this.isPromoterOrAdmin).subscribe((filter) => {
      if (filter && filter.length > 0) {
        this.existingFilterArray = filter;

        if (this.messageGroup && this.messageGroup.filterKeys && this.messageGroup.filterKeys.length > 0) {
          this.existingFilterArray.map((item) => {
            if (item && item.ID && this.messageGroup.filterKeys.indexOf(item.ID) !== -1) {
              this.filterKeys.push(item);
            }
          });
        }
      }
      this.isFilterLoad = true;
    });

    this.authenticationService
      .getDentistList({
        fields: 'ID,CalculatedName',
        orderBy: 'CalculatedName',
        merchantID: this.merchantID,
      })
      .subscribe((r) => {
        if (r) {
          this.contacts = r;
        }

        this.isContactsLoad = true;
      });

    this.buildMessage();
  }

  changeDentist() {
    if (this.contactID) {
      this.loadContact = false;
      this.authenticationService.getCardDetails(this.contactID, 'Contact', {}, this.sessionType).subscribe((r) => {
        if (r && r.ID) {
          this._message = this.messageRaw;
          this.contact = r;
          this.contactID = r.ID;
        }
        this.loadContact = true;
      });
    }
  }

  buildMessage() {
    if (!this.promoter) {
      this.authenticationService.getPromoter().subscribe((r) => {
        if (r && r.data) {
          this.promoter = r.data;
        } else if (r) {
          this.promoter = r;
        }
        this.loadPromoter = true;
      });
    } else {
      this.loadPromoter = true;
    }

    if (!this.contactID && !this.contact) {
      this.authenticationService.getCurrentDentist().subscribe((r) => {
        if (r) {
          this.contact = r;
          this.contactID = r.ID;
        }
        this.loadContact = true;
      });
    } else if (this.contact && this.contact.ID) {
      this.contactID = this.contact.ID;
      this.loadContact = true;
    } else if (this.contactID) {
      this.authenticationService.getCardDetails(this.contactID, 'Contact', {}, this.sessionType).subscribe((r) => {
        if (r && r.ID) {
          this.contact = r;
          this.contactID = r.ID;
        }
        this.loadContact = true;
      });
    }
    if (!this.merchantID && !this.merchant) {
      this.authenticationService.getCurrentPractice().subscribe((r) => {
        if (r) {
          this.merchant = r;
        }
        this.loadMerchant = true;
      });
    } else if (this.merchant && this.merchant.ID) {
      this.merchantID = this.merchant.ID;
      this.loadMerchant = true;
    } else if (this.merchantID) {
      this.authenticationService.getCardDetails(this.merchantID, 'Merchant', {}, this.sessionType).subscribe((r) => {
        if (r && r.ID) {
          this.merchant = r;

          this.merchantID = r.ID;
        }
        this.loadMerchant = true;
      });
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
  getSelectedTags(e) {
    this.selectedTags = e || [];
  }

  selectPatient() {
    const ref = RootAppComponent.dialog.open(InvitationLookupComponent, {
      data: {
        merchantID: this.merchantID,
        onlyMyAccount: true,
        displayChatButton: false,
        useStrict: true,
      },
      width: '600px',
    });
    ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });

    ref.componentInstance.getSelectedPatient.subscribe((u) => {
      if (u && u.ID) {
        const tableName = u['TableName'];
        this.loadCard = false;
        this.messageService.getCardDetails(u.ID, tableName, {}, this.sessionType).subscribe((res) => {
          if (res) {
            this.card = res;
            this._message = this.messageRaw;
            this.loadCard = true;
          }

          ref.close();
        });
      }
    });

    ref.componentInstance.detailedView.subscribe((res) => {
      ref.close();
      ref.afterClosed().subscribe((r) => {
        this.closeEvent();
      });
    });
  }

  changeMarketingConsent() {
    if (this._marketingConsent === true) {
      this.marketingConsent = true;
    } else {
      this.marketingConsent = null;
    }
  }
  createNewTag() {
    const ref = RootAppComponent.dialog.open(TagCreateEditComponent, {
      data: {
        merchantID: this.merchantID,
      },

      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res && res.Label) {
        if (
          (!this.tags || (this.tags && this.tags.length <= 0)) &&
          (!this.defaultSelectedTags || (this.defaultSelectedTags && this.defaultSelectedTags.length <= 0))
        ) {
          this.defaultSelectedTags = JSON.parse(JSON.stringify([res.Label]));
          this.tags = JSON.parse(JSON.stringify([res.Label]));
        } else {
          if (!this.tags || (this.tags && this.tags.length <= 0)) {
            this.tags.push(res.Label);
          }
          this.addTag.emit(res.Label);
        }
        ref.close();
      }
    });
  }

  manualSelection() {
    const ref = RootAppComponent.dialog.open(CustomerProspectListServerComponent, {
      data: {
        title: 'Search Patients',
        description:
          'Search for Patients using Basic or Advanced queries or click All Patients. Once you have your list, you can manually filter records for your campaign',
        merchantID: this.merchantID,
        manualSelection: true,
        backLabel: 'Make another selection',
        hasMobile: true,
        displayEmailMobile: false,
        displayMarketingConsent: false,
        marketingConsent: this.marketingConsent,
      },

      width: '80%',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getSelectedCards.subscribe((res) => {
      if (res) {
        let selectedCards = this.selectedCards.concat(res);
        selectedCards = _.uniqBy(selectedCards, 'ID');

        this._selectedCards = JSON.parse(JSON.stringify(selectedCards));
        this.selectedCards = JSON.parse(JSON.stringify(selectedCards));
        ref.close();
      }
    });
  }

  manualExcluded() {
    const ref = RootAppComponent.dialog.open(CustomerProspectListServerComponent, {
      data: {
        title: 'Search Patients',
        description:
          'Search for Patients using Basic or Advanced queries or click All Patients. Once you have your list, you can manually filter records for your campaign',
        merchantID: this.merchantID,
        manualSelection: true,
        backLabel: 'Make another selection',
        hasMobile: true,
        displayEmailMobile: false,
        displayMarketingConsent: false,
        marketingConsent: this.marketingConsent,
      },

      width: '80%',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getSelectedCards.subscribe((res) => {
      if (res) {
        let excludedCards = this.excludedCards.concat(res);

        this._excludedCards = JSON.parse(JSON.stringify(excludedCards));
        this.excludedCards = JSON.parse(JSON.stringify(excludedCards));
        ref.close();
      }
    });
  }

  getCardsEvent(e) {
    this.selectedCards = e;
  }

  getExcludedCardsEvent(e) {
    this.excludedCards = e;

    console.log(this.excludedCards);
  }
  getResultMessage(e) {
    if (e) {
      this.message = e.convertedMessage;

      this.messageRaw = e.message;
    } else {
      this.message = null;
      this.messageRaw = null;
    }
  }

  step1() {
    this.wizard.goToStep(0);
  }
  step2() {
    this.wizard.goToStep(1);
  }

  step3() {
    this._message = this.messageRaw;

    this.buildIDs();

    let p: any = {
      isStatistics: true,
      filterKeys: this.filterKeyIDs,
      selectedIDs: this.selectedIDs,
      excludedIDs: this.excludedIDs,
      tags: this.selectedTags,
      merchantID: this.merchantID,
      selectAll: this.selectAll,
      hasMobile: this.hasMobile,
      hasEmail: this.hasEmail,
      marketingConsent: this.marketingConsent,
    };
    this.targetNumber = null;
    this.messageService.getCustomerProspectMergedList(p, this.sessionType).subscribe((res) => {
      if (res) {
        if (res && res.total && Number(res.total) >= 0) {
          this.targetNumber = res;
          this.wizard.goToStep(2);
        } else {
          const confirmDialog2 = new ConfirmDialogSingleButton(
            'fas fa-info',
            '',
            'We could not find a match for your query in our database. Please review your entries and make another selection.',
            'OKAY'
          );
          const ref2 = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
            data: confirmDialog2,
            width: '400px',
          });
          ref2.componentInstance.onConfirm.subscribe((res) => {
            ref2.close();
          });
        }
      }
    });
  }
  selectDateFromFilter(date) {
    this.dateToBeSent = date;
  }

  buildIDs() {
    this.excludedIDs = [];

    if (this.excludedCards && this.excludedCards.length > 0) {
      this.excludedIDs = this.excludedCards.map((item) => {
        if (item && item.ID) {
          return item.ID;
        }

        return null;
      });
    }
    this.filterKeyIDs = [];

    if (this.filterKeys && this.filterKeys.length > 0) {
      this.filterKeyIDs = this.filterKeys.map((item) => {
        return item.ID;
      });
    }

    this.selectedIDs = [];

    if (this.selectedCards && this.selectedCards.length > 0) {
      this.selectedIDs = this.selectedCards.map((item) => {
        if (item && item.ID) {
          return item.ID;
        }

        return null;
      });
    }
  }

  preview(type, _isCalcul = false) {
    let refreshTargetOnClose = false;

    this.buildIDs();

    if (type === 'manual') {
      const ref2 = RootAppComponent.dialog.open(MessageGroupCardListComponent, {
        data: {
          cards: this.selectedCards,
          title: 'Preview',
          isRemove: true,
          isViewOnly: false,
          hasMobile: this.hasMobile,
          hasEmail: this.hasEmail,
        },

        width: '700px',
        panelClass: 'noCard',
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();

        if (refreshTargetOnClose === true) {
          this.refreshTargetNumber();
        }
      });

      ref2.backdropClick().subscribe((res) => {
        ref2.close();

        if (refreshTargetOnClose === true) {
          this.refreshTargetNumber();
        }
      });
      ref2.componentInstance.getCards.subscribe((res) => {
        this.selectedCards = JSON.parse(JSON.stringify(res || []));
        this._selectedCards = JSON.parse(JSON.stringify(res || []));

        this.buildIDs;

        if (_isCalcul == true) {
          refreshTargetOnClose = true;
        }
      });
    } else if (type === 'tags') {
      const ref2 = RootAppComponent.dialog.open(MessageGroupCardListComponent, {
        data: {
          tags: this.selectedTags,
          title: 'Preview selected Tags result',
          removeTitle: 'Exclude',
          excludedIDs: this.excludedIDs,
          merchantID: this.merchantID,
          isRemove: true,
          isViewOnly: false,
          hasMobile: this.hasMobile,
          hasEmail: this.hasEmail,
        },

        width: '700px',
        panelClass: 'noCard',
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();

        if (refreshTargetOnClose === true) {
          this.refreshTargetNumber();
        }
      });

      ref2.backdropClick().subscribe((res) => {
        ref2.close();

        if (refreshTargetOnClose === true) {
          this.refreshTargetNumber();
        }
      });
      ref2.componentInstance.getRemovedCard.subscribe((res) => {
        if (res && res.ID) {
          this.addToExcluded(res);
          if (_isCalcul == true) {
            refreshTargetOnClose = true;
          }
        }
      });
    } else if (type == 'filters') {
      const ref2 = RootAppComponent.dialog.open(MessageGroupCardListComponent, {
        data: {
          filterKeys: this.filterKeyIDs,
          title: 'Preview selected Audience result',
          removeTitle: 'Exclude',
          excludedIDs: this.excludedIDs,
          merchantID: this.merchantID,
          isRemove: true,
          isViewOnly: false,
          hasMobile: this.hasMobile,
          hasEmail: this.hasEmail,
        },

        width: '700px',
        panelClass: 'noCard',
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();

        if (refreshTargetOnClose === true) {
          this.refreshTargetNumber();
        }
      });

      ref2.backdropClick().subscribe((res) => {
        ref2.close();

        if (refreshTargetOnClose === true) {
          this.refreshTargetNumber();
        }
      });
      ref2.componentInstance.getRemovedCard.subscribe((res) => {
        if (res && res.ID) {
          this.addToExcluded(res);
          if (_isCalcul == true) {
            refreshTargetOnClose = true;
          }
        }
      });
    } else if (type == 'excluded') {
      const ref2 = RootAppComponent.dialog.open(MessageGroupCardListComponent, {
        data: {
          cards: this.excludedCards,
          title: 'Preview excluded records',
          isRemove: true,
          isViewOnly: false,
          hasMobile: this.hasMobile,
          hasEmail: this.hasEmail,
        },

        width: '700px',
        panelClass: 'noCard',
      });

      ref2.backdropClick().subscribe((res) => {
        ref2.close();

        if (refreshTargetOnClose === true) {
          this.refreshTargetNumber();
        }
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();

        if (refreshTargetOnClose === true) {
          this.refreshTargetNumber();
        }
      });

      ref2.componentInstance.getRemovedCard.subscribe((res) => {
        if (res && res.ID) {
          this.removeToExcluded(res);

          if (_isCalcul == true) {
            refreshTargetOnClose = true;
          }
        }
      });
    } else {
      const ref2 = RootAppComponent.dialog.open(MessageGroupCardListComponent, {
        data: {
          filterKeys: this.filterKeyIDs,
          selectedIDs: this.selectedIDs,
          excludedIDs: this.excludedIDs,
          removeTitle: 'Exclude',
          tags: this.selectedTags,
          merchantID: this.merchantID,
          selectAll: this.selectAll,
          isRemove: true,
          isViewOnly: false,

          hasMobile: this.hasMobile,
          hasEmail: this.hasEmail,
        },

        width: '700px',
        panelClass: 'noCard',
      });

      ref2.backdropClick().subscribe((res) => {
        ref2.close();

        if (refreshTargetOnClose === true) {
          this.refreshTargetNumber();
        }
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();

        if (refreshTargetOnClose === true) {
          this.refreshTargetNumber();
        }
      });

      ref2.componentInstance.getRemovedCard.subscribe((res) => {
        if (res && res.ID) {
          this.addToExcluded(res);

          if (_isCalcul == true) {
            refreshTargetOnClose = true;
          }
        }
      });
    }
  }

  refreshTargetNumber() {
    this.buildIDs();
    let p: any = {
      isStatistics: true,
      filterKeys: this.filterKeyIDs,
      selectedIDs: this.selectedIDs,
      excludedIDs: this.excludedIDs,
      tags: this.selectedTags,
      merchantID: this.merchantID,
      selectAll: this.selectAll,
      hasMobile: this.hasMobile,
      hasEmail: this.hasEmail,
    };
    this.targetNumber = null;
    this.messageService.getCustomerProspectMergedList(p, this.sessionType).subscribe((res) => {
      if (res) {
        this.targetNumber = res;
      }
    });
  }

  getEditFilterEvent(d) {
    if (d && d.ID) {
      let index = this.filterKeys.findIndex((it) => {
        if (it && it.ID && it.ID === it.ID) {
          return true;
        }

        return false;
      });

      if (index !== -1) {
        this.filterKeys[index] = d;
      }

      index = this.existingFilterArray.findIndex((it) => {
        if (it && it.ID && it.ID === it.ID) {
          return true;
        }

        return false;
      });

      if (index !== -1) {
        this.existingFilterArray[index] = d;
      }

      this.filterKeys = JSON.parse(JSON.stringify(this.filterKeys));
      this.existingFilterArray = JSON.parse(JSON.stringify(this.existingFilterArray));

      this.buildIDs();
    }
  }

  createNewFilter() {
    const ref = RootAppComponent.dialog.open(MarketingFilterCreateComponent, {
      data: {
        merchantID: this.merchantID,
        standalone: true,
      },
      width: '900px',

      panelClass: 'noCard',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res && res.ID) {
        const __p = {
          merchantID: this.merchantID,
          selectQuery: true,
          id: res.ID,
          limit: 1,
          section: 1,
        };
        this.messageService.getFilterList(__p, this.isPromoterOrAdmin).subscribe((filter) => {
          if (filter && filter[0] && filter[0]['ID']) {
            this.existingFilterArray.push(filter[0]['ID']);
            this.filterKeys.push(filter[0]['ID']);

            this.filterKeys = JSON.parse(JSON.stringify(this.filterKeys));
            this.existingFilterArray = JSON.parse(JSON.stringify(this.existingFilterArray));

            this.buildIDs();

            ref.close();
          }
        });
      }
    });
  }
  step4() {
    this.wizard.goToStep(3);
  }

  addToExcluded(c) {
    if (c && c.ID) {
      this._excludedCards.push(c);

      this._excludedCards = JSON.parse(JSON.stringify(this._excludedCards));

      this.excludedCards = JSON.parse(JSON.stringify(this._excludedCards));
    }
  }

  removeToExcluded(c) {
    if (c && c.ID) {
      let index = this._excludedCards.findIndex((item) => {
        if (item && item.ID && c.ID === item.ID) {
          return true;
        }
      });

      if (index !== -1) {
        this._excludedCards.splice(index, 1);
      }

      this._excludedCards = JSON.parse(JSON.stringify(this._excludedCards));

      this.excludedCards = JSON.parse(JSON.stringify(this._excludedCards));
    }
  }

  previous2() {
    this.wizard.goToStep(2);
  }
  saveDraft() {
    const confirm = new ConfirmDialog(
      this.messageUtil.statusIcons['Draft'],
      '',
      'Are you sure you want to save this Bulk ' + this.messageLabel + ' as Draft?',
      'No',
      'Yes'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
    });
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        this.buildIDs();
        let payload = {
          label: this.label,
          description: this.description,
          message: this.messageRaw,
          tags: this.selectedTags,
          filterKeys: this.filterKeyIDs,
          selectAll: this.selectAll,
          selectedIDs: this.selectedIDs,
          excludedIDs: this.excludedIDs,
          delayDate: !this.sendNow,
          dateToBeSent: this.dateToBeSent,
          contactID: this.contactID,
          submitDraft: false,
          messageGroupID: this.messageGroupDraftID,
          hasMobile: this.hasMobile,
          hasEmail: this.hasEmail,
          isBulkSMS: true,
          marketingConsent: this.marketingConsent,
        };

        this.messageService.createMessageGroupDraft(payload, this.sessionType).subscribe((r) => {
          if (r && r.ID) {
            this.createdObject = r;
            this.wizard.goToStep(4);
            ref.close();
          }
        });
      }
    });
  }
  submit(isPromoter = false) {
    this.buildIDs();

    let payload = {
      label: this.label,
      description: this.description,
      message: this.messageRaw,
      tags: this.selectedTags,
      filterKeys: this.filterKeyIDs,
      selectAll: this.selectAll,
      selectedIDs: this.selectedIDs,
      excludedIDs: this.excludedIDs,
      delayDate: !this.sendNow,
      dateToBeSent: this.dateToBeSent,
      contactID: this.contactID,
      submitDraft: true,
      messageGroupID: this.messageGroupDraftID,
      hasMobile: this.hasMobile,
      hasEmail: this.hasEmail,
      isBulkSMS: true,
      isPromoter: isPromoter,
      marketingConsent: this.marketingConsent,
    };

    if (Settings.global['demoBulkSMS'] == true) {
      const confirm = new ConfirmDialog(
        'fas fa-paper-plane',
        '',
        '<p> You are about to create a campaign in demo mode.<strong> No SMS will be sent </strong>. Do you wish to continue?  </p>',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.messageService.createMessageGroup(payload, this.sessionType).subscribe((res) => {
            if (res && res.ID) {
              this.createdObject = res;
              this.wizard.goToStep(4);
              ref.close();
            }
          });
        }
      });
    } else {
      const confirm = new ConfirmDialog(
        'fas fa-paper-plane',
        '',
        '<p>You are about to create a  Campaign targeting <strong>' +
          this.targetNumber.total +
          ' Potential recipients</strong>, do you wish to continue? </p>',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.messageService.createMessageGroup(payload, this.sessionType).subscribe((res) => {
            if (res && res.ID) {
              this.createdObject = res;
              this.wizard.goToStep(4);
              ref.close();
            }
          });
        }
      });
    }
  }

  // removeFilter(id) {
  //   if (this.filterKeys && this.filterKeys.length > 0) {
  //     let index = this.filterKeys.findIndex((r) => {
  //       if (r && r.ID && r.ID == id) {
  //         return true;
  //       }

  //       return false;
  //     });

  //     if (index !== -1) {
  //       this.filterKeys.splice(index, 1);

  //       this.filterKeys = JSON.parse(JSON.stringify(this.filterKeys));
  //     }
  //   }
  // }

  ngOnDestroy() {
    this.isDestoyed = true;
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}
