import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ContractService } from '../shared/contract.service';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { QuotationDecisionModalComponent } from '../../quotation/quotation-decision-modal/quotation-decision-modal.component';

@Component({
  selector: 'app-contract-capacity-check',
  templateUrl: './contract-capacity-check.component.html',
  styleUrls: ['./contract-capacity-check.component.css'],
})
export class ContractCapacityCheckComponent implements OnInit {
  @Input()
  contractID;

  @Input()
  isDisplayFull = true;

  @Input()
  checkstatus;

  @Output()
  close = new EventEmitter();

  isModal = false;

  contract;
  isPromoterOrAdmin = false;

  personalCirID;
  displayPersonalCir = false;

  @Output()
  hideBackButton = new EventEmitter();

  uploadedPCIDetails;
  loading = true;
  isModified = true;

  capacityDetails;

  expensesUsed = 0;
  netIncome = 0;
  remainingIncome = 0;

  pciNetIncome = 0;
  pciRemainingIncome = 0;

  annualIncome = 0;
  pciAnnualIncome = 0;

  pciAnnualExpenses = 0;

  netAssets = 0;
  pciNetAssets = 0;

  netGrossAssetsRate = 0;
  pciNetGrossAssetsRate = 0;

  constructor(
    private activeRouter: ActivatedRoute,
    private contractSerivce: ContractService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.contractID = data;
      this.isModal = true;
    }
  }

  ngOnInit() {
    this.activeRouter.params.subscribe((params) => {
      if (params['contractID']) {
        this.contractID = params['contractID'];
      }
      this.loading = true;
      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;
        if (this.isPromoterOrAdmin == true) {
          this.contractSerivce.fetchContractDetails$(this.contractID, null, this.isPromoterOrAdmin).subscribe((res) => {
            if (res) {
              this.contract = res;

              this.contractSerivce.getCapacityCheckDetails(this.contract['ID']).subscribe((ress) => {
                if (ress) {
                  this.capacityDetails = ress;

                  this.expensesUsed =
                    Number(this.capacityDetails['Input.ExpensesPerWeek.Used']) +
                    Number(this.capacityDetails['OtherExpenses']);

                  this.netIncome = Number(this.capacityDetails['Input.IncomePerWeek']) - this.expensesUsed;

                  this.remainingIncome = this.netIncome - Number(this.capacityDetails['Input.ProposedPayPerWeek']);

                  this.annualIncome = Number(this.capacityDetails['Input.IncomePerWeek']) * 52;

                  this.netAssets =
                    Number(this.capacityDetails['TotalAssets']) - Number(this.capacityDetails['TotalLiabilities']);

                  this.netGrossAssetsRate = this.netAssets / Number(this.capacityDetails['TotalAssets']);

                  if (this.capacityDetails['UnlockedPersonalCircum_key']) {
                    this.contractSerivce
                      .getPersonCircumstanceDetails(this.capacityDetails['UnlockedPersonalCircum_key'])
                      .subscribe((res) => {
                        if (res) {
                          this.uploadedPCIDetails = res;

                          this.pciNetIncome =
                            Number(this.uploadedPCIDetails['IncomePerWeek']) -
                            Number(this.uploadedPCIDetails['ExpensesPerWeek']);

                          this.pciRemainingIncome =
                            this.pciNetIncome - Number(this.capacityDetails['Input.ProposedPayPerWeek']);

                          this.pciAnnualIncome = Number(this.uploadedPCIDetails['IncomePerWeek']) * 52;

                          this.pciAnnualExpenses = Number(this.uploadedPCIDetails['ExpensesPerWeek']) * 52;

                          this.pciNetAssets =
                            Number(this.uploadedPCIDetails['Assets.Total']) -
                            Number(this.uploadedPCIDetails['Liabilities.Total']);

                          this.pciNetGrossAssetsRate =
                            this.pciNetAssets / Number(this.uploadedPCIDetails['Assets.Total']);

                          this.loading = false;
                        } else {
                          this.loading = false;
                        }
                      });
                  } else {
                    this.contractSerivce
                      .getPersonCircumstanceDetails(this.capacityDetails['PersonalCircumstances_key'])
                      .subscribe((res) => {
                        if (res) {
                          this.uploadedPCIDetails = res;

                          this.pciNetIncome =
                            Number(this.uploadedPCIDetails['IncomePerWeek']) -
                            Number(this.uploadedPCIDetails['ExpensesPerWeek']);

                          this.pciRemainingIncome =
                            this.pciNetIncome - Number(this.capacityDetails['Input.ProposedPayPerWeek']);

                          this.pciAnnualIncome = Number(this.uploadedPCIDetails['IncomePerWeek']) * 52;

                          this.pciAnnualExpenses = Number(this.uploadedPCIDetails['ExpensesPerWeek']) * 52;

                          this.pciNetAssets =
                            Number(this.uploadedPCIDetails['Assets.Total']) -
                            Number(this.uploadedPCIDetails['Liabilities.Total']);

                          this.pciNetGrossAssetsRate =
                            this.pciNetAssets / Number(this.uploadedPCIDetails['Assets.Total']);
                          this.loading = false;
                        } else {
                          this.loading = false;
                        }
                      });
                  }
                } else {
                  this.loading = false;
                }
              });
            } else {
              this.loading = false;
            }
          });
        } else {
          this.loading = false;
        }
      });
    });
  }

  openCapacityCheck(id) {
    this.personalCirID = id;
    this.displayPersonalCir = true;
    this.hideBackButton.emit(true);
  }

  hidePersonalCir() {
    this.personalCirID = null;
    this.displayPersonalCir = false;
    this.hideBackButton.emit(false);
  }

  toNumber(value) {
    return Number(value);
  }

  closeEvent() {
    this.close.emit(true);
  }

  // decision modal
  makeDecision() {
    const ref = RootAppComponent.dialog.open(QuotationDecisionModalComponent, {
      data: {
        title: 'Capacity check',
      },
      width: '600px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  isModifiedData(a, b) {
    const _a = Math.round(Number(a));

    const _b = Math.round(Number(b));

    if (_a != _b) {
      return true;
    }

    return false;
  }
}
