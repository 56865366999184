import { Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-before-starting-lending',
  templateUrl: './before-starting-lending.component.html',
  styleUrls: ['./before-starting-lending.component.css'],
})
export class BeforeStartingLendingComponent implements OnInit {
  showBeforeStartingInvitation = JSON.parse(localStorage.getItem('showStartingLendingDialog'));
  closeModal = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  doNotShow() {
    localStorage.setItem('showStartingLendingDialog', JSON.stringify(this.showBeforeStartingInvitation));

    this.closeModal.emit(this.showBeforeStartingInvitation);
  }
}
