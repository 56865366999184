<div class="row clearfix" *ngIf="isPromoterOrAdmin == true" [class.PanelOpen]="hideMerchantSelect == true">
  <div class="clearfix">
    <div *ngIf="viewAsPromoter == true">
      <mat-tab-group
        [ngClass]="{ tabs: hideMerchantSelect == true }"
        (selectedIndexChange)="loadList($event)"
        dynamicHeight="true"
      >
        <mat-tab>
          <ng-template *ngIf="hideMerchantSelect == false" mat-tab-label>
            All {{ "KEYWORD.patient" | translate | titlecase}}s
          </ng-template>

          <div class="row clearfix" *ngIf="hideMerchantSelect == false">
            <app-operator-filter
              class=""
              id="part1"
              [displayMerchant]="true"
              [displayDateRange]="false"
              [displayDateFrom]="false"
              [displayTimePicker]="false"
              [displayDateTo]="false"
              [displayDentist]="false"
              [setAutoSelectMerchant]="true"
              [selectedMerchant]="merchantAll"
              (getMerchant)="getMerchantAll($event)"
            >
            </app-operator-filter>
          </div>
          <div class="row clearfix">
            <app-customer-prospect-list-global
              *ngIf="merchantAll != 'none' && allList && displayResult==true"
              [merchantID]="merchantAll"
              [setPatients]="setPatients"
              (detailView)="detailView($event)"
              (getSetPatients)="getSetPatientsEvent($event)"
            ></app-customer-prospect-list-global>
          </div>
          <div class="row clearfix padded" *ngIf="hideMerchantSelect == false">
            <div class="pleaseChoose" *ngIf="merchantAll == 'none'" @ngIfAnimation>
              <p>
                <strong>No merchant selected</strong>
              </p>
              <img class="character_warning" />
              <p>
                Choose your desired merchant from the drop-down above to view their
                {{ "KEYWORD.patient" | translate }}s
              </p>
            </div>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template *ngIf="hideMerchantSelect == false" mat-tab-label>
            Customer
          </ng-template>

          <div class="row clearfix" *ngIf="hideMerchantSelect == false">
            <app-operator-filter
              class=""
              id="part1"
              [displayMerchant]="true"
              [displayDateRange]="false"
              [displayDateFrom]="false"
              [displayTimePicker]="false"
              [displayDateTo]="false"
              [displayDentist]="false"
              [setAutoSelectMerchant]="true"
              [selectedMerchant]="merchantCustomer"
              (getMerchant)="getMerchantCustomer($event)"
            >
            </app-operator-filter>
          </div>
          <div class="row clearfix">
            <app-customer-prospect-list-global
              *ngIf="merchantCustomer != 'none' && customerList && displayResult==true"
              [tableName]="'customer'"
              [merchantID]="merchantCustomer"
              [setPatients]="setPatients"
              (detailView)="detailView($event)"
              (getSetPatients)="getSetPatientsEvent($event)"
            >
            </app-customer-prospect-list-global>
          </div>
          <div class="row clearfix" *ngIf="hideMerchantSelect == false">
            <div class="animated fadeInDown pleaseChoose" *ngIf="merchantCustomer == 'none'">
              <p>
                <strong>No merchant selected</strong>
              </p>
              <img class="character_warning" />
              <p>
                Choose your desired merchant from the drop-down above to view their
                {{ "KEYWORD.patient" | translate }}s
              </p>
            </div>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template *ngIf="hideMerchantSelect == false" mat-tab-label>
            Prospects
          </ng-template>

          <div class="row clearfix" *ngIf="hideMerchantSelect == false">
            <app-operator-filter
              class=""
              id="part1"
              [displayMerchant]="true"
              [displayDateRange]="false"
              [displayDateFrom]="false"
              [displayTimePicker]="false"
              [displayDateTo]="false"
              [displayDentist]="false"
              [setAutoSelectMerchant]="true"
              [selectedMerchant]="merchantProspect"
              (search)="applySearch($event)"
              (getMerchant)="getMerchantProspect($event)"
            >
            </app-operator-filter>
          </div>
          <div class="row clearfix">
            <app-customer-prospect-list-global
              *ngIf="merchantProspect != 'none' && prospectList && displayResult==true"
              [tableName]="'prospect'"
              [merchantID]="merchantProspect"
              [setPatients]="setPatients"
              (detailView)="detailView($event)"
              (getSetPatients)="getSetPatientsEvent($event)"
            >
            </app-customer-prospect-list-global>
          </div>
          <div class="row clearfix" *ngIf="hideMerchantSelect == false">
            <div class="animated fadeInDown pleaseChoose" *ngIf="merchantProspect == 'none'">
              <p>
                <strong>No merchant selected</strong>
              </p>
              <img class="character_warning" />
              <p>
                Choose your desired merchant from the drop-down above to view their
                {{ "KEYWORD.patient" | translate }}s
              </p>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>

    <!-- <div *ngIf="viewAsPromoter==false" class="">
      <app-customer-prospect-list-global *ngIf=" merchantAll!='none' && allList" [merchantID]="merchantAll" [setPatients]="setPatients"
        (detailView)="detailView($event)" (getSetPatients)="getSetPatientsEvent($event)"></app-customer-prospect-list-global>

    </div> -->
  </div>
</div>

<div class="clearfix" *ngIf="isPromoterOrAdmin == false && displayResult==true ">
  <app-customer-prospect-list [setPatients]="setPatients" (getSetPatients)="getSetPatientsEvent($event)">
  </app-customer-prospect-list>
</div>
