<mat-card class="rel clearfix" [ngClass]="{ 'step-1-normal': step == 1 }">
  <form #resetForm="ngForm">
    <div class="card-header primary-gradient-img clearfix stacked"
      [attr.style]="'background:' + theme.background + ' !important' | safeStyle">
      <button class="btn-close btn-clear mat-button smt white custom-close" *ngIf="isModal == true"
        (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>

      <div class="row clearfix">
        <div class="row titleArea clearfix">
          <h2 class="summary-header text-left rm-m" *ngIf="operationType == 'edit'">Modify Landing Page</h2>
          <h2 class="summary-header text-left rm-m" *ngIf="operationType == 'create'">Create Landing Page</h2>

          <h2 class="summary-header text-left rm-m" *ngIf="operationType == 'clone'">Create Landing Page</h2>
        </div>
      </div>
    </div>

    <mat-dialog-content id="documentlinkcreate" [ngClass]="{ 'no-modal-height': isModal != true }">
      <div class="full-width text-center" @simpleFadeAnimation [ngClass]="{ 'step-3': isModal == true }"
        [hidden]="step != 1">
        <div class="full-width flex text-center">
          <div class="full-width text-center">
            <div class="full-width">
              <div class="full-width text-center flex mb">
                <div class="theme-container" *ngFor="let t of themes"
                  [attr.style]="'background:' + t.background + ' !important' | safeStyle" (click)="selectTheme(t)">
                  <mat-icon class="fas fa-check-circle"
                    *ngIf="t && t.code && theme && theme.code && t.code == theme.code">
                  </mat-icon>
                </div>
              </div>

              <div class="full-width flex">
                <mat-form-field class="thrd-width mr" *ngIf="displayType == true" appearance="outline">
                  <mat-label>Type</mat-label>
                  <mat-select [(ngModel)]="type" name="Type" placeholder="Type">
                    <mat-option *ngFor="let type1 of types" [value]="type1.Code">
                      <mat-icon class="{{ type1.Icon }} smr"></mat-icon>
                      {{ type1.Label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="full-width" *ngIf="isStored == true" appearance="outline">
                  <mat-label>Label (for your own record)</mat-label>
                  <input [(ngModel)]="label" name="Label" matInput placeholder="Label (for your own record)" required />
                </mat-form-field>
              </div>

              <div class="full-width flex mt smb"
                *ngIf="addToStore != true || (isStored == true && isPromoterOrAdmin == true)">
                <mat-checkbox class="full-width text-left mr" *ngIf="addToStore != true" [(ngModel)]="isStored"
                  color="accent" name="isStored">
                  Save Landing as page Template
                </mat-checkbox>

                <mat-checkbox class="full-width text-left mr" *ngIf="isStored == true && isPromoterOrAdmin == true"
                  [(ngModel)]="isDefault" color="accent" name="isDefault">
                  Promoter Template
                </mat-checkbox>
              </div>
            </div>

            <h3 class="sr-title text-left full-width text-center rm-mt smb" *ngIf="type == 'FLandPg'"
              [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              Finance
            </h3>

            <h3 class="sr-title text-left full-width text-center rm-mt smb" *ngIf="type != 'FLandPg'"
              [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              Sender
            </h3>

            <div class="clearfix text-center smt full-width" *ngIf="type != 'FLandPg'">
              <div class="clearfix full-width flex">
                <mat-form-field class="full-width mr" appearance="outline">
                  <mat-label>Choose a Sender</mat-label>
                  <mat-select class="full-width" [(ngModel)]="contactID" (ngModelChange)="changeDentist()"
                    placeholder="" name="detnistID" required>
                    <mat-option *ngIf="currentDentist && currentDentist.ID" [value]="currentDentist.ID">
                      <strong>My account</strong>
                    </mat-option>

                    <hr *ngIf="currentDentist && currentDentist.ID" />

                    <mat-option *ngFor="let p of dentists" [value]="p['ID']">
                      {{ p.CalculatedName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="clearfix text-center smt full-width" *ngIf="type == 'FLandPg'">
              <div class="clearfix full-width flex">
                <mat-form-field class="full-width mr" appearance="outline">
                  <mat-label>Choose a Sender</mat-label>
                  <mat-select class="full-width" [(ngModel)]="contactID" (ngModelChange)="changeDentist()"
                    placeholder="" name="detnistID" required>
                    <mat-option *ngIf="currentDentist && currentDentist.ID" [value]="currentDentist.ID">
                      <strong>My account</strong>
                    </mat-option>

                    <hr *ngIf="currentDentist && currentDentist.ID" />

                    <mat-option *ngFor="let p of dentists" [value]="p['ID']">
                      {{ p.CalculatedName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="full-width mr" appearance="outline">
                  <mat-label>Choose product group</mat-label>
                  <mat-select class="full-width" [(ngModel)]="productGroup"
                    (ngModelChange)="getLoanAmount(productGroup)" placeholder="" name="productGroup" required>
                    <mat-option *ngFor="let p of financedProductGroup" [value]="p['GroupCode']">
                      <mat-icon class="fas {{ productUtil.getProductGroupIcon(p['GroupCode']) }} mr iconproductgroup ">
                      </mat-icon>

                      {{ productUtil.getLabel(p['GroupCode']) }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="full-width" *ngIf="settings && settings['context'] != 'breeze' "
                  appearance="outline">
                  <mat-label>{{"KEYWORD.Treatment" | translate}} Value ($)*</mat-label>
                  <input [(ngModel)]="treatmentAmount" (blur)="changeAmount()" name="treatmentAmount" matInput
                    placeholder="Please enter a value" currencyMask />
                </mat-form-field>
                <mat-form-field class="full-width" *ngIf="settings && settings['context'] == 'breeze' "
                  appearance="outline">
                  <mat-label>Amount to Finance ($)*</mat-label>
                  <input [(ngModel)]="treatmentAmount" (blur)="changeAmount()" name="treatmentAmount" matInput
                    placeholder="Please enter a value" currencyMask />
                </mat-form-field>
              </div>
              <p class="small text-left rm-mt" *ngIf="
                  minLoanAmount != null &&
                  maxLoanAmount != null &&
                  settings &&
                  settings['context'] != 'breeze'
                " [ngClass]="{
                  'error-treatment':
                    treatmentAmount && (treatmentAmount < minLoanAmount || treatmentAmount > maxLoanAmount)
                }">
                {{ "KEYWORD.treatment" | translate | titlecase }} Value should be between
                <strong> {{ minLoanAmount | customCurrency }}</strong> and
                <strong> {{ maxLoanAmount | customCurrency }} </strong>
              </p>

              <p class="small text-left rm-mt" *ngIf="
                  minLoanAmount != null &&
                  maxLoanAmount != null &&
                  settings &&
                  settings['context'] == 'breeze'
                " [ngClass]="{
                  'error-treatment':
                    treatmentAmount && (treatmentAmount < minLoanAmount || treatmentAmount > maxLoanAmount)
                }">
                Amount to Finance should be between <strong> {{ minLoanAmount | customCurrency }}</strong> and
                <strong> {{ maxLoanAmount | customCurrency }} </strong>
              </p>
            </div>

            <h3 class="sr-title text-left full-width text-center rm-mt smt smb"
              [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              Expiry Date
            </h3>

            <div class="full-width flex smt">
              <mat-form-field class="thrd-width pull-left mr" *ngIf="expireDateTypes.length > 0" appearance="outline">
                <mat-label>This Landing Page will expire </mat-label>
                <mat-select class="" [(ngModel)]="expireDateType" (ngModelChange)="setExpireDate()" name="ExpireDate"
                  placeholder="This Landing Page will expire">
                  <mat-option *ngFor="let a of expireDateTypes" [value]="a.code">
                    {{ a.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <app-date-input class="pull-left thrd-width mr" *ngIf="expireDateType && expireDateType != 'never'"
                [defaultDate]="expireDateDefault" [minDate]="'today'" [isTime]="false" [isClear]="false"
                (selectedDate)="selectDateToFilter($event)" label="Expires At">
              </app-date-input>

              <mat-icon class="fas fa-exclamation-circle" *ngIf="showExpired() === true"
                style="color: red; font-size: 1.7rem; margin-top: 14px; margin-left: -5px"></mat-icon>
            </div>

            <h3 class="sr-title text-left full-width text-center mt smb"
              *ngIf="settings && settings['context'] != 'breeze' "
              [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              Extra Links & Publishment
            </h3>

            <div class="full-width">
              <div class="full-width flex smb" *ngIf="settings && settings['context'] != 'breeze' ">
                <mat-checkbox class="full-width text-left smt mr" [(ngModel)]="displayAppointmentPage" color="accent"
                  name="displayAppointmentPage">
                  Appointment Request Link
                </mat-checkbox>

                <mat-checkbox class="full-width text-left smt mr" [(ngModel)]="displayTipsAndOffersPage" color="accent"
                  name="displayTipsAndOffersPage">
                  Tips and Offers Link
                </mat-checkbox>

                <mat-checkbox class="full-width text-left smt mr" [(ngModel)]="displayGoingGreenPage" color="accent"
                  name="displayGoingGreenPage">
                  Going Green Link
                </mat-checkbox>
              </div>

              <div class="full-width flex smt smb" *ngIf="linkID">
                <mat-checkbox class="full-width text-left mr" *ngIf="linkID" [(ngModel)]="isActive" color="accent"
                  name="isActive">
                  Publish this Landing Page?
                </mat-checkbox>
              </div>

              <mat-checkbox class="full-width text-left smt mr"
                *ngIf="isStored == true && settings && settings['context'] != 'breeze'" [(ngModel)]="isPublic"
                color="accent" name="isPublic">
                Add this Landing Page to {{ "KEYWORD.dental" | translate | titlecase }} Tips and Offers?
              </mat-checkbox>
            </div>
          </div>

          <mat-card class="editor-card-preview row file animate mat-elevation-z4 full-width"
            [ngClass]="isHeaderImage != true ? 'no-image' : ''">
            <div class="full-width landing-page-create-gallery-container">
              <h3 class="sr-title full-width text-center rm-m smb" *ngIf="isHeaderImage == true"
                [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
                Header Image
              </h3>

              <h3 class="sr-title full-width text-center rm-m smb" *ngIf="isHeaderImage != true"
                [attr.style]="'color:grey  !important' | safeStyle">
                Header Image
              </h3>
              <app-ep-document-view-image class="landing-page-create-gallery" *ngIf="isHeaderImage != true" [imageLink]="
                  'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/no-image.jpg'
                " [hideZoomButton]="true">
              </app-ep-document-view-image>

              <app-ep-document-view-image class="landing-page-create-gallery"
                *ngIf="headerImage != 'merchant' && isHeaderImage == true"
                [ngClass]="getCircle(headerImage) == true ? 'round-circle' : ''" [imageLink]="headerImage"
                [hideZoomButton]="false">
              </app-ep-document-view-image>

              <app-ep-document-view-image class="landing-page-create-gallery round-circle"
                *ngIf="headerImage == 'merchant' && merchantID && isHeaderImage == true" [imageLink]="getMerchantLink()"
                [hideZoomButton]="false">
              </app-ep-document-view-image>
            </div>

            <mat-checkbox class="text-left no-image-checkbox" [(ngModel)]="isHeaderImage" matTooltip="Add Header Image"
              color="accent" name="isHeaderImage">
            </mat-checkbox>

            <div class="full-width text-center card-image-footer">
              <button class="btn pull-right mr" *ngIf="isHeaderImage != true" [disabled]="true" mat-icon-button
                color="accent">
                <mat-icon class="fas fa-upload"></mat-icon>
              </button>

              <button class="btn pull-left ml" *ngIf="isHeaderImage != true" [disabled]="true" mat-raised-button
                color="accent">
                <mat-icon class="fas fa-images smr"></mat-icon> Gallery
              </button>

              <button class="btn pull-right mr" *ngIf="isHeaderImage == true" (click)="upload()" mat-icon-button
                color="accent" matTooltip="Upload New Image">
                <mat-icon class="fas fa-upload" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
                </mat-icon>
              </button>

              <button class="btn pull-left ml" *ngIf="isHeaderImage == true"
                [attr.style]="'background:' + theme.color + ' !important' | safeStyle" (click)="galleryHeader()"
                mat-raised-button color="accent">
                <mat-icon class="fas fa-images smr"></mat-icon> Gallery
              </button>
            </div>
          </mat-card>
        </div>

        <h3 class="sr-title text-left full-width text-center mt smb"
          [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
          Tags
        </h3>

        <div class="full-width">
          <app-tag-list class="full-width" [tags]="tags" [selectedTags]="defaultSelectedTags" [add]="addTag"
            [tagFormatIsObject]="false" [title]="'Attach tags to this landing page'" [canRemove]="true"
            [canCreate]="true" [directRemove]="true" [directRemoveDefault]="true" [hasFilter]="true" [canSelect]="false"
            (getCreate)="createNewTag()" (getSelectedTags)="getSelectedTags($event)">
          </app-tag-list>
        </div>

        <h3 class="sr-title text-left full-width text-center mt smb" *ngIf="addNewDocument == true"
          [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
          Upload new files <span *ngIf="fileIDs && fileIDs.length > 0"> ({{ fileIDs.length }})</span>
        </h3>
        <div class="full-width mb" *ngIf="addNewDocument == true">
          <app-file-uploader class="text-center" [documentType]="'Additional Document'" [fileName]="'Shared Document'"
            [isGallery]="false" [isDragable]="true" (onCompleteAll)="onCompleFile($event)">
          </app-file-uploader>
        </div>
        <div class="full-width" *ngIf="fileIDs && fileIDs.length > 0 && addNewDocument == true">
          <app-file-list class="" [defaultLabel]="'Shared Document'" [documentTypes]="documentTypes" [files]="fileIDs"
            [addFile]="addFile" [canPatientDocument]="false" [canGallery]="false" [canPrivateDocument]="false"
            [canEdit]="true" [canAction]="false" (remove)="removeNewFile($event)">
          </app-file-list>
        </div>

        <div class="full-width" *ngIf="addGalleryDocument == true">
          <h3 class="sr-title text-left pull-left text-center"
            [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
            Documents From Gallery
            <span *ngIf="documentIDs && documentIDs.length > 0">({{ documentIDs.length }})</span>
          </h3>
        </div>
        <div class="full-width" *ngIf="documentIDs && documentIDs.length > 0">
          <app-ep-document-list class="document-link" [isDownload]="false" [isPrint]="false" [canRemove]="true"
            [isModal]="false" [isFilter]="false" [defaultCheck]="false" [isRedirection]="false" [canEdit]="true"
            [documents]="documentIDs" [title]="''" [canCheck]="false" [listViewType]="'thumbnails'"
            (removeID)="removeEPDocument($event)">
          </app-ep-document-list>
        </div>

        <div class="full-width text-center mt mb" *ngIf="addGalleryDocument == true">
          <button class="btn-large" [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
            (click)="addDocumentFromGallery()" mat-raised-button color="accent">
            <mat-icon class="fas fa-images smr"></mat-icon> Add Document From Gallery
          </button>
        </div>
      </div>

      <div class="full-width text-center" @simpleFadeAnimation [ngClass]="{ 'step-1': isModal == true }"
        [hidden]="step != 2">
        <h3 class="sr-title text-left full-width rm-mt"
          [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
          Title
        </h3>

        <mat-form-field class="clearfix full-width" appearance="outline">
          <mat-label>Landing Page Title</mat-label>
          <input [(ngModel)]="title" name="Title" matInput placeholder="Landing Page Title" />
        </mat-form-field>

        <div class="full-width smt mb">
          <div class="full-width flex">
            <div class="full-width mr">
              <h3 class="sr-title text-left full-width rm-mt"
                [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
                Description
              </h3>

              <message-input class="full-width message-input-component"
                *ngIf="loadMessage == true && loadPromoter == true && loadContact == true && loadMerchant == true"
                [isTiny]="true" [ngClass]="
                  isModal == true
                    ? 'message-input-component3-description'
                    : 'message-input-component-not-modal-description'
                " [isModal]="false" [messageMode]="messageMode" [label]="'Please insert a description'"
                [message]="_description" [showAllCustomization]="true" [card]="card" [textLength]="0"
                [displayEmoji]="false" [displayMessageTemplate]="true" [contact]="contact"
                [displayPatient]="displayPatient" [displayContact]="true" [displayMerchant]="true"
                [displayPromoter]="true" [displayMarketing]="true" [displayExpend]="false" [displayDentalOffers]="true"
                [displayDocument]="true" [saveAsTemplate]="false" [isSMSPreview]="true" [isDesktopPreview]="true"
                [displaySaveAsTemplate]="true" [encode]="false" [tableName]="tableName"
                (getResult)="getResultDescription($event)">
              </message-input>
            </div>
            <div class="full-width ml">
              <h3 class="sr-title text-left full-width rm-mt"
                [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
                Preview
              </h3>
              <div class="full-width flex">
                <mat-card class="full-width"
                  [ngClass]="isModal == true ? 'modal-card-description' : 'not-modal-card-description'" [attr.style]="
                    'background:' + theme.background + ' !important;margin-top: 0px !important;' | safeStyle
                  ">
                  <h1 class="white-header header-title text-left">{{ title }}</h1>

                  <div class="white full-width header-description" id="header-description"
                    [innerHTML]="URLifyDescription(description || '...') | safeHtml"></div>
                </mat-card>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="full-width smt mb text-center" @simpleFadeAnimation [ngClass]="{ 'step-1': isModal == true }"
        [hidden]="step != 3">
        <div class="full-width flex">
          <div class="full-width mr">
            <h3 class="sr-title text-left full-width rm-mt"
              [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              Message
            </h3>
            <message-input class="full-width message-input-component"
              *ngIf="loadMessage == true && loadPromoter == true && loadContact == true && loadMerchant == true"
              [isTiny]="true" [isModal]="false" [ngClass]="
                isModal == true ? 'message-input-component3-message' : 'message-input-component-not-modal-message'
              " [messageMode]="messageMode" [label]="'Please insert a  message'" [message]="_message"
              [showAllCustomization]="true" [card]="card" [textLength]="0" [displayEmoji]="false"
              [displayMessageTemplate]="true" [contact]="contact" [displayPatient]="displayPatient"
              [promoter]="promoter" [displayContact]="true" [displayMerchant]="true" [displayPromoter]="true"
              [displayMarketing]="true" [displayExpend]="false" [displayDentalOffers]="true" [displayDocument]="true"
              [saveAsTemplate]="false" [isSMSPreview]="true" [isDesktopPreview]="true" [displaySaveAsTemplate]="true"
              [encode]="false" [tableName]="tableName" (getResult)="getResultMessage($event)">
            </message-input>
          </div>

          <div class="full-width ml">
            <h3 class="sr-title text-left full-width rm-mt"
              [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              Preview
            </h3>
            <div class="full-width flex">
              <mat-card class="full-width" [ngClass]="isModal == true ? 'modal-card-message' : 'not-modal-card-message'"
                style="margin-top: 0px !important">
                <h3 class="sr-title full-width text-center" *ngIf="merchant"
                  [attr.style]="'color:' + theme.color + ' !important;' | safeStyle">
                  Message From {{ merchant['TradingAs'] }}
                </h3>

                <p class="full-width message-text" [innerHTML]="URLify(message || '...') | safeHtml"></p>
              </mat-card>
            </div>
          </div>
        </div>

        <div class="full-with" *ngIf="type == 'FLandPg'">
          <h3 class="sr-title text-left full-width rm-mt"
            *ngIf="loadMessage == true && loadPromoter == true && loadContact == true && loadMerchant == true"
            [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
            Disclaimer Message
          </h3>

          <mat-checkbox class="full-width text-left disclaimer-message-check mb" [(ngModel)]="displayDisclaimerMessage"
            color="accent" name="displayDisclaimerMessage">
            If turned on, the message below will appear before proceeding with a finance application, and the
            Customer must acknowledge it.
          </mat-checkbox>

          <message-input class="full-width message-input-component message-disclaimer-component3-message" *ngIf="
              loadMessage == true &&
              loadPromoter == true &&
              loadContact == true &&
              loadMerchant == true &&
              displayDisclaimerMessage == true
            " [isTiny]="true" [isModal]="false" [messageMode]="messageMode" [label]="'Please insert a  message'"
            [message]="_disclaimerMessage" [showAllCustomization]="true" [card]="card" [textLength]="0"
            [displayEmoji]="false" [displayMessageTemplate]="true" [contact]="contact" [displayPatient]="true"
            [promoter]="promoter" [displayContact]="true" [displayMerchant]="true" [displayPromoter]="true"
            [displayMarketing]="true" [displayExpend]="false" [displayDentalOffers]="true" [displayDocument]="true"
            [saveAsTemplate]="false" [isSMSPreview]="true" [isDesktopPreview]="true" [displaySaveAsTemplate]="true"
            [isCustomTreatmentAmount]="true" [displayPreview]="true" [encode]="false" [tableName]="tableName"
            (getResult)="getResultMessageDisclaimer($event)">
          </message-input>
        </div>
      </div>
    </mat-dialog-content>
    <div class="drop-button text-center white-background" *ngIf="step == 1">
      <hr />

      <button class="action-button btn-large pull-right smt" *ngIf="
          resetForm.form.valid &&
          theme.code !== 'blue' &&
          (type != 'FLandPg' ||
            (type == 'FLandPg' &&
              treatmentAmount > 0 &&
              productGroup &&
              treatmentAmount >= minLoanAmount &&
              treatmentAmount <= maxLoanAmount))
        " [attr.style]="'background:' + theme.color + ' !important' | safeStyle" (click)="next()" mat-raised-button
        color="accent">
        Next <mat-icon class="sml">keyboard_arrow_right</mat-icon>
      </button>
      <button class="action-button btn-large pull-right smt" *ngIf="
          resetForm.form.valid &&
          theme.code == 'blue' &&
          (type != 'FLandPg' ||
            (type == 'FLandPg' &&
              treatmentAmount > 0 &&
              productGroup &&
              treatmentAmount >= minLoanAmount &&
              treatmentAmount <= maxLoanAmount))
        " (click)="next()" mat-raised-button color="accent">
        Next <mat-icon class="sml">keyboard_arrow_right</mat-icon>
      </button>

      <button class="action-button btn-large pull-right smt" *ngIf="
          !resetForm.form.valid ||
          (type == 'FLandPg' &&
            !(
              treatmentAmount > 0 &&
              productGroup &&
              treatmentAmount >= minLoanAmount &&
              treatmentAmount <= maxLoanAmount
            ))
        " [disabled]="true" (click)="next()" mat-raised-button color="accent">
        Next <mat-icon class="sml">keyboard_arrow_right</mat-icon>
      </button>

      <button class="action-button btn-large pull-left smt" *ngIf="isModal == true"
        [attr.style]="'background:' + theme.color + ' !important' | safeStyle" (click)="closeEvent()" mat-raised-button
        color="accent">
        Close
      </button>

      <button class="action-button btn-large pull-left smt" *ngIf="isModal != true"
        [attr.style]="'background:' + theme.color + ' !important' | safeStyle" (click)="goToList()" mat-raised-button
        color="accent">
        <mat-icon class="smr">keyboard_arrow_left</mat-icon> Landing Page List
      </button>
    </div>
    <div class="drop-button text-center white-background" *ngIf="step == 2">
      <hr />

      <button class="action-button btn-large pull-right smt"
        *ngIf="resetForm.form.valid && title && descriptionRaw && theme.code !== 'blue'"
        [attr.style]="'background:' + theme.color + ' !important' | safeStyle" (click)="next2()" mat-raised-button
        color="accent">
        Next <mat-icon class="sml">keyboard_arrow_right</mat-icon>
      </button>
      <button class="action-button btn-large pull-right smt"
        *ngIf="resetForm.form.valid && title && descriptionRaw && theme.code == 'blue'" (click)="next2()"
        mat-raised-button color="accent">
        Next <mat-icon class="sml">keyboard_arrow_right</mat-icon>
      </button>

      <button class="action-button btn-large pull-right smt" *ngIf="!resetForm.form.valid || !title || !descriptionRaw"
        [disabled]="true" (click)="next2()" mat-raised-button color="accent">
        Next <mat-icon class="sml">keyboard_arrow_right</mat-icon>
      </button>

      <button class="action-button btn-large mr smt" [ngClass]="{ 'pull-right': isModal == true }"
        [disabled]="!resetForm.form.valid || !title || !descriptionRaw" (click)="submit(false)" mat-raised-button
        color="primary">
        <mat-icon class="fas fa-desktop mr"></mat-icon> Preview
      </button>

      <button class="action-button btn-large pull-left smt" *ngIf="addGalleryDocument == true"
        [attr.style]="'background:' + theme.color + ' !important' | safeStyle" (click)="previous()" mat-raised-button
        color="accent">
        <mat-icon class="smr">keyboard_arrow_left</mat-icon> Previous
      </button>
    </div>

    <div class="drop-button text-center white-background" *ngIf="step == 3">
      <hr />
      <button class="action-button btn-large pull-right smt" *ngIf="
          resetForm.form.valid &&
          messageRaw &&
          title &&
          descriptionRaw &&
          theme.code !== 'blue' &&
          (type != 'FLandPg' ||
            (type == 'FLandPg' &&
              treatmentAmount > 0 &&
              productGroup &&
              treatmentAmount >= minLoanAmount &&
              treatmentAmount <= maxLoanAmount))
        " [attr.style]="'background:' + theme.color + ' !important' | safeStyle" (click)="submit()" mat-raised-button
        color="accent">
        Submit <mat-icon class="fas fa-paper-plane ml"></mat-icon>
      </button>
      <button class="action-button btn-large pull-right smt" *ngIf="
          resetForm.form.valid &&
          messageRaw &&
          title &&
          descriptionRaw &&
          theme.code == 'blue' &&
          (type != 'FLandPg' ||
            (type == 'FLandPg' &&
              treatmentAmount > 0 &&
              productGroup &&
              treatmentAmount >= minLoanAmount &&
              treatmentAmount <= maxLoanAmount))
        " (click)="submit()" mat-raised-button color="accent">
        Submit <mat-icon class="fas fa-paper-plane ml"></mat-icon>
      </button>

      <button class="action-button btn-large pull-right smt" *ngIf="
          !resetForm.form.valid ||
          !messageRaw ||
          !title ||
          !descriptionRaw ||
          (type == 'FLandPg' &&
            !(
              treatmentAmount > 0 &&
              productGroup &&
              treatmentAmount >= minLoanAmount &&
              treatmentAmount <= maxLoanAmount
            ))
        " [disabled]="true" (click)="submit()" mat-raised-button color="accent">
        Submit <mat-icon class="fas fa-paper-plane ml"></mat-icon>
      </button>

      <button class="action-button btn-large mr smt" [ngClass]="{ 'pull-right': isModal == true }" [disabled]="
          !resetForm.form.valid ||
          !messageRaw ||
          !title ||
          !descriptionRaw ||
          (type == 'FLandPg' &&
            !(
              treatmentAmount > 0 &&
              productGroup &&
              treatmentAmount >= minLoanAmount &&
              treatmentAmount <= maxLoanAmount
            ))
        " (click)="submit(false)" mat-raised-button color="primary">
        <mat-icon class="fas fa-desktop mr"></mat-icon> Preview
      </button>

      <button class="action-button btn-large pull-left smt" *ngIf="addGalleryDocument == true"
        [attr.style]="'background:' + theme.color + ' !important' | safeStyle" (click)="previous2()" mat-raised-button
        color="accent">
        <mat-icon class="smr">keyboard_arrow_left</mat-icon> Previous
      </button>
    </div>
  </form>
</mat-card>
