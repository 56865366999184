import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class BankAccountService {
  params: HttpParams;

  constructor(private http: HttpClient) {}

  getBankAccountList(payload, isAdminOrPromoter = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (isAdminOrPromoter == true) {
      return this.http
        .get(`${environment.nodeUrl}/international-bank-account/global`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(`${environment.nodeUrl}/international-bank-account/mini`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getBankAccountListMini(payload = null, isAdminOrPromoter = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.http
      .get(`${environment.nodeUrl}/international-bank-account/mini`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getBankAccountDetails(ID, payload = {}, isAdminOrPromoter = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    if (isAdminOrPromoter == true) {
      return this.http
        .get(`${environment.nodeUrl}/international-bank-account/global/${ID}`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(`${environment.nodeUrl}/international-bank-account/${ID}`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  editBankAccount(ID, payload, isAdminOrPromoter = false): Observable<any> {
    if (isAdminOrPromoter == true) {
      return this.http
        .put(`${environment.nodeUrl}/international-bank-account/global/${ID}`, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(`${environment.nodeUrl}/international-bank-account/${ID}`, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  createBankAccount(payload, isAdminOrPromoter = false): Observable<any> {
    if (isAdminOrPromoter == true) {
      return this.http
        .post(`${environment.nodeUrl}/international-bank-account/`, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getPaymentList(payload, isAdminOrPromoter = false) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    if (isAdminOrPromoter == true) {
      return this.http
        .get(`${environment.nodeUrl}/payment/global`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(`${environment.nodeUrl}/payment/`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getOnePayment(ID) {
    return this.http.get(`${environment.nodeUrl}/payment/${ID}`).pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPaymentType() {
    return this.http.get(`${environment.nodeUrl}/paymentType`).pipe(map((res: HttpResponse<any>) => res['data']));
  }
}
