<mat-card class="rel supplier-card brandView clearfix" *ngIf="template" @ngIfAnimation>
  <form #resetForm="ngForm">
    <div class="card-header primary-gradient-img clearfix inModal rel">
      <div class="row clearfix content">
        <div class="mr pull-left flex social-media-icon">
          <div
            class="full-width icon-social-media text-center"
            *ngIf="template['SocialMediaType.Code'] == 'Facebook'"
            matTooltip="Post for Facebook"
          >
            <mat-icon class="fab fa-facebook-f big-icon" [style.color]="'#4267B2'"></mat-icon>
          </div>
          <div
            class="full-width icon-social-media text-center"
            *ngIf="template['SocialMediaType.Code'] == 'Instagram'"
            matTooltip="Post for Instagram"
          >
            <mat-icon class="fab fa-instagram big-icon" [style.color]="'#E1306C'"></mat-icon>
          </div>
          <div
            class="full-width icon-social-media text-center"
            *ngIf="template['SocialMediaType.Code'] == 'Twitter'"
            matTooltip="Post for Twitter"
          >
            <mat-icon class="fab fa-twitter big-icon" [style.color]="'#1DA1F2'"></mat-icon>
          </div>
          <div
            class="full-width icon-social-media text-center"
            *ngIf="template['SocialMediaType.Code'] == 'LinkedIn'"
            matTooltip="Post for Linkedin"
          >
            <mat-icon class="fab fa-linkedin-in big-icon" [style.color]="'#2867B2'"></mat-icon>
          </div>
          <div
            class="full-width icon-social-media text-center"
            *ngIf="template['SocialMediaType.Code'] == 'Pinterest'"
            matTooltip="Post for Pinterest"
          >
            <mat-icon class="fab fa-pinterest big-icon" [style.color]="'#c8232c'"></mat-icon>
          </div>
        </div>

        <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModal()">
          <mat-icon>clear</mat-icon>
        </button>
        <div class="row titleArea clearfix">
          <h2 class="summary-header rm-m">
            {{ template['Label'] || 'Social media post' }}
            <span class="subLabel"
            >We've prepared everything you need to create social media posts in 3 simple steps.
            </span>
          </h2>
        </div>
        <div
          class="date-chip-area pull-right"
          *ngIf="template.DateTimeCreated && template.DateTimeCreated != '0000-00-00'"
        >
          <p class="small rm-m mr text-right">
            <mat-icon class="fas fa-clock"></mat-icon>
            {{ template.DateTimeCreated | TimeLabelPast }} ago
          </p>
        </div>
        <div
          class="full-width clearfix tag-social-container flex"
          *ngIf="template && template.Tags && template.Tags.length > 0"
        >
          <div
            class="template-header-tags full-width row clearfix matchThis"
            *ngIf="template && template.Tags && template.Tags.length > 0"
          >
            <p class="small inner-tags text-left rm-mt rm-mb">
              <span *ngFor="let d of template.Tags | orderBy"> #{{ d }} </span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <mat-dialog-content class="stacked-card-view social-media-view">
      <div class="create-container full-width clearfix" *ngIf="step == 1">
        <div class="full-width row flex">
          <div class="full-width">
            <div class="full-width flex mb title-header">
              <h3 class="sr-title rm-m sm-h4">Step 1</h3>
              <p class="text-social rm-m full-width">Use the text provided or edit the text to suit.</p>
            </div>

            <div class="full-width mb">
              <div class="row clearfix full-width">
                <div class="full-width flex">
                  <div class="full-width">
                    <mat-form-field
                      class="full-width animate"
                      [ngClass]="(postText?.length || 0) > maxLength ? 'mat-form-field-invalid' : ''"
                      appearance="outline"
                    >
                      <mat-label>Post Text</mat-label>
                      <textarea
                        class="mat-block"
                        [(ngModel)]="postText"
                        matInput
                        placeholder="Post Text"
                        name="postText"
                      ></textarea>

                      <mat-hint *ngIf="(postText?.length || 0) <= maxLength" align="end"
                      >Post length: {{ postText?.length || 0 }}/
                        {{ maxLength || 0 }}
                      </mat-hint>

                      <mat-hint class="error-color" *ngIf="(postText?.length || 0) > maxLength" align="end"
                      >Post length: <strong> {{ postText?.length || 0 }} </strong>/
                        {{ maxLength || 0 }}
                      </mat-hint>
                    </mat-form-field>

                    <div class="full-width clearfix">
                      <button class="pull-left personalisation-button" [matMenuTriggerFor]="persMenu1" mat-button>
                        <mat-icon class="fas fa-plus"></mat-icon>Insert personalisation
                      </button>
                      <mat-menu #persMenu1="matMenu">
                        <div *ngFor="let p of dynamicData">
                          <button (click)="addPersonalisationText(p.code)" mat-menu-item>
                            {{ p.label }}
                          </button>
                        </div>
                      </mat-menu>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <app-ep-document-view-image
            class="squareImg squareImg-social qtr-width blob-for-social-media-container-thumbnail ml"
            [hideZoomButton]="false"
            [defaultPicture]="
              'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/gettingstarted-img4.png)'
            "
            [link]="postPictureThumbnail"
          >
          </app-ep-document-view-image>
        </div>

        <div class="full-width cleafix">
          <div class="full-width flex mb title-header">
            <h3 class="sr-title rm-m sm-h4">Step 2</h3>
            <p class="text-social rm-m full-width">
              Change the default link (Going Green Page) of this post to an existing email/SMS campaign or landing page.
            </p>
          </div>

          <div class="full-width">
            <div class="full-width clearfix flex">
              <div class="link-container full-width mr text-center">
                <strong class="smr">{{ postType }}: </strong>
                <a class="text-center" *ngIf="postURL" href="{{ postURL }}" target="_blank">{{
                  postURL | textLength: 80
                  }}</a>

                <i *ngIf="!postURL">Select a link to promote</i>
              </div>

              <button [matMenuTriggerFor]="submenuTools" mat-raised-button yPosition="above" color="accent">
                Select a link <mat-icon class="mr">link</mat-icon>
              </button>

              <mat-menu #submenuTools="matMenu">
                <button
                  *ngIf="currentPractice && currentPractice.Terminal_Code"
                  (click)="addAppointmentPageEvent()"
                  mat-menu-item
                >
                  <mat-icon class="fas fa-calendar mr"></mat-icon>
                  <span>Appointment Request Page</span>
                </button>

                <button (click)="campaignLook()" mat-menu-item>
                  <mat-icon class="fas fa-bullhorn mr"></mat-icon>
                  <span>Campaign Landing Page</span>
                </button>

                <button
                  *ngIf="currentPractice && currentPractice.Terminal_Code"
                  (click)="tipsAndOffers()"
                  mat-menu-item
                >
                  <mat-icon class="fas {{'fa-tooth' | translate}} mr"></mat-icon>
                  <span>{{ "KEYWORD.dental" | translate | titlecase }} Tips</span>
                </button>

                <hr *ngIf="isModuleDocumentLinkActive == true"/>

                <!-- <button mat-menu-item (click)="insertDocument()" *ngIf='isModuleDocumentLinkActive==true'>
                  <mat-icon class="fas fa-image  mr "></mat-icon> <span>Insert Document From Gallery</span>
                </button>
                   -->

                <button *ngIf="isModuleDocumentLinkActive == true" (click)="addDocument()" mat-menu-item>
                  <mat-icon class="fas fa-folder-plus mr"></mat-icon>
                  <span>New Landing Page</span>
                </button>

                <button *ngIf="isModuleDocumentLinkActive == true" (click)="documentLinkLookup()" mat-menu-item>
                  <mat-icon class="fas fa-folder-open mr"></mat-icon>
                  <span>Landing Page Lookup</span>
                </button>

                <hr/>

                <button
                  *ngIf="currentPractice && currentPractice.Terminal_Code"
                  (click)="addLandingPageEvent()"
                  mat-menu-item
                >
                  <mat-icon class="fas fa-home mr"></mat-icon>
                  <span>Going Green Page</span>
                </button>

                <button
                  *ngIf="currentPractice && currentPractice['Google_Review_Place_ID']"
                  (click)="addGoogleReview()"
                  mat-menu-item
                >
                  <mat-icon class="fab fa-google mr"></mat-icon>
                  <span>Google Reviews Page</span>
                </button>
                <button
                  *ngIf="currentPractice && currentPractice.Terminal_Code"
                  (click)="addDentalPageEvent()"
                  mat-menu-item
                >
                  <mat-icon class="fas fa-building mr"></mat-icon>
                  <span>{{"KEYWORD.Practice" | translate}} Tips and Offers Page </span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>

        <div class="clearfix full-width mt" *ngIf="template['SocialMediaType.Code'] != 'Instagram'">
          <div class="full-width flex mb title-header">
            <h3 class="sr-title rm-m sm-h4">Step 3</h3>
            <p class="text-social rm-m full-width">Add the Call To Action to capture attention to your post.</p>
          </div>

          <div class="full-width clearfix smt">
            <div class="full-width clearfix mb">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>CTA Title</mat-label>
                <input
                  class="cta-text"
                  [(ngModel)]="actionCallLabel"
                  name="actionCallLabel"
                  matInput
                  [placeholder]="'The title is used to capture attention and seen by ' + ('KEYWORD.patient' | translate) + 's'"
                  required
                />

                <mat-hint
                  [ngClass]="
                    actionCallLabel &&
                    (actionCallLabel.length < minLengthCTALabel || actionCallLabel.length > maxLengthCTALabel)
                      ? 'hint-warning'
                      : ''
                  "
                  align="end"
                >
                  Recommended text length is between {{ minLengthCTALabel }} and {{ maxLengthCTALabel }}:
                  <strong> {{ actionCallLabel?.length || 0 }}/{{ maxLengthCTALabel }} </strong>
                </mat-hint>
              </mat-form-field>

              <div class="full-width clearfix">
                <button class="pull-left personalisation-button" [matMenuTriggerFor]="persMenu2" mat-button>
                  <mat-icon class="fas fa-plus"></mat-icon>Insert personalisation
                </button>
                <mat-menu #persMenu2="matMenu">
                  <div *ngFor="let p of dynamicData">
                    <button (click)="addPersonalisationCTALabel(p.code)" mat-menu-item>
                      {{ p.label }}
                    </button>
                  </div>
                </mat-menu>
              </div>
            </div>
            <div
              class="full-width clearfix mt"
              *ngIf="
                template['SocialMediaType.Code'] != 'Instagram' &&
                template['SocialMediaType.Code'] != 'Pinterest' &&
                template['SocialMediaType.Code'] != 'LinkedIn'
              "
            >
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>CTA Description/Preview</mat-label>

                <textarea
                  class="cta-text-action"
                  [(ngModel)]="actionCallDescription"
                  name="actionCallDescription"
                  matInput
                  placeholder="A brief description provides a preview of what the post contains."
                  required
                ></textarea>

                <mat-hint
                  [ngClass]="
                    actionCallDescription &&
                    (actionCallDescription.length < minLengthCTADescription ||
                      actionCallDescription.length > maxLengthCTADescription)
                      ? 'hint-warning'
                      : ''
                  "
                  align="end"
                >
                  Recommended text length is between {{ minLengthCTADescription }} and {{ maxLengthCTADescription }} :
                  <strong> {{ actionCallDescription?.length || 0 }}/{{ maxLengthCTADescription }} </strong>
                </mat-hint>
              </mat-form-field>

              <div class="full-width clearfix">
                <button class="pull-left personalisation-button" [matMenuTriggerFor]="persMenu3" mat-button>
                  <mat-icon class="fas fa-plus"></mat-icon>Insert personalisation
                </button>
                <mat-menu #persMenu3="matMenu">
                  <div *ngFor="let p of dynamicData">
                    <button (click)="addPersonalisationCTADescription(p.code)" mat-menu-item>
                      {{ p.label }}
                    </button>
                  </div>
                </mat-menu>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="create-container full-width clearfix" *ngIf="step == 2">
        <div class="full-width row text-center">
          <div class="post-card-container text-center mb mt clearfix">
            <div class="full-width post-card-header clearfix mt">
              <app-merchant-view-picture
                class="merchant-picture2 pull-left ml mr"
                [hideZoomButton]="false"
                [merchantID]="merchantID"
              >
              </app-merchant-view-picture>
              <h3 class="summary-header rm-m text-left">{{ currentPractice['TradingAs'] }}</h3>
            </div>

            <div class="full-width flex">
              <div
                class="full-width post-text text-left mb mt ml mr"
                *ngIf="postHtml"
                [innerHTML]="postHtml | safeHtml"
              ></div>
            </div>
            <div class="full-width post-card">
              <div class="full-width post-card-image-cover" (click)="openLink(smLink)">
                <p>
                  Click here to view the link
                  <mat-icon class="fas fa-external-link-alt ml"></mat-icon>
                </p>
              </div>

              <div
                class="full-width flex post-card-image"
                *ngIf="postImage"
                [style.background-image]="'url(' + postImage + ')'"
              >
                <div class="full-width post-card-cta" *ngIf="template['SocialMediaType.Code'] != 'Instagram'">
                  <h4
                    class="ml mt sr-title rm-m sm-h4 text-left"
                    *ngIf="
                      template['SocialMediaType.Code'] != 'Pinterest' && template['SocialMediaType.Code'] != 'LinkedIn'
                    "
                  >
                    {{ actionCallLabel }}
                  </h4>
                  <p
                    class="ml text-left"
                    *ngIf="
                      template['SocialMediaType.Code'] != 'Pinterest' && template['SocialMediaType.Code'] != 'LinkedIn'
                    "
                  >
                    {{ actionCallDescription }}
                  </p>

                  <h4
                    class="sr-title sm-h4 ml text-left"
                    *ngIf="
                      template['SocialMediaType.Code'] == 'Pinterest' || template['SocialMediaType.Code'] == 'LinkedIn'
                    "
                  >
                    {{ actionCallLabel }}
                  </h4>
                </div>
              </div>
              <div
                class="full-width flex post-card-image"
                *ngIf="!postImage"
                [style.background-image]="
                  'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/public/img/popularity.png)'
                "
              >
                <div class="full-width post-card-cta" *ngIf="template['SocialMediaType.Code'] != 'Instagram'">
                  <h4
                    class="ml mt sr-title rm-m sm-h4 text-left"
                    *ngIf="
                      template['SocialMediaType.Code'] != 'Pinterest' && template['SocialMediaType.Code'] != 'LinkedIn'
                    "
                  >
                    {{ actionCallLabel }}
                  </h4>
                  <p
                    class="ml text-left"
                    *ngIf="
                      template['SocialMediaType.Code'] != 'Pinterest' && template['SocialMediaType.Code'] != 'LinkedIn'
                    "
                  >
                    {{ actionCallDescription }}
                  </p>

                  <h4
                    class="sr-title sm-h4 ml text-left"
                    *ngIf="
                      template['SocialMediaType.Code'] == 'Pinterest' || template['SocialMediaType.Code'] == 'LinkedIn'
                    "
                  >
                    {{ actionCallLabel }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="full-width    " [hidden]="true" *ngIf="template['SocialMediaType.Code']=='Instagram'">


          <app-blob-view [isDownload]="true"  [applyDownload]="applyDownload" [isProceed]="false" [isPrint]="false" [canProceed]="false" [content]="blob"
            [extension]="template['Document.ExtensionOrMimeType']"
            [label]="template['Label']+'_'+template['SocialMediaType.Code']"></app-blob-view>

        </div> -->
      </div>
    </mat-dialog-content>

    <div class="text-center clearfix footer-post-card" *ngIf="step == 2">
      <h2 class="sr-title rm-mt sm-h4">What's next</h2>

      <p class="rm-m small smb" *ngIf="template['SocialMediaType.Code'] == 'Instagram'">
        To post on <strong>Instagram</strong> you will need to do it manually . Press Copy & Download to copy the post
        to your clipboad and download the Post Image.
      </p>

      <p class="rm-m small smb" *ngIf="template['SocialMediaType.Code'] != 'Instagram'">
        When you are ready, press Copy & Share to post. The post text you entered will be copied to your clipboard for
        easy access. Just paste (Control V) in the field provided.
      </p>
    </div>

    <hr/>
    <div class="drop-button text-center" *ngIf="step == 1">
      <button
        class="btn-large selectExistingPatient pull-left"
        (click)="closeModal()"
        mat-raised-button
        color="primary"
      >
        Close
      </button>

      <button
        class="btn-large selectExistingPatient pull-right"
        [disabled]="(postText?.length || 0) > maxLength || !resetForm.form.valid"
        (click)="generatePost()"
        mat-raised-button
        color="accent"
      >
        Next
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>

    <div class="drop-button text-center" *ngIf="step == 2">
      <button class="btn-large selectExistingPatient pull-left" (click)="back(1)" mat-raised-button color="primary">
        <mat-icon>chevron_left</mat-icon>
        Back
      </button>

      <button
        class="selectExistingPatient btn-large"
        *ngIf="isModal == true"
        (click)="closeModal()"
        mat-raised-button
        color="primary"
      >
        Close
      </button>

      <button
        class="btn-large selectExistingPatient ml pull-right"
        *ngIf="socialMediaPost.ID && template['SocialMediaType.Code'] == 'Instagram'"
        [disabled]="downloadButtonLoading"
        (click)="downloadImageInstgram()"
        mat-raised-button
        color="accent"
      >
        <mat-icon>system_update</mat-icon>
        Copy & Download
      </button>

      <app-social-share-button
        class="pull-right btn-large share-button"
        *ngIf="socialMediaPost.ID && template['SocialMediaType.Code'] != 'Instagram'"
        [label]="'Copy & Share'"
        [post]="postText"
        [copy]="true"
        [icon]="'fas fa-share-alt'"
        [url]="smLink"
        [title]="actionCallLabel"
        [image]="postImage"
        [type]="toLowerCase(socialMediaPost['TempSocialMedia.SocialMediaTypeCode'])"
        [tags]="template.Tags || []"
        (success)="confirmShareEvent()"
      >
      </app-social-share-button>
    </div>
  </form>
</mat-card>
