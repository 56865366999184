import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatChipInputEvent } from '@angular/material';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { CustomerViewComponent } from '../../../feature/customer/customer-view/customer-view.component';
import { ProspectViewComponent } from '../../../feature/existing-patient/prospect-view/prospect-view.component';
import { TagsService } from '../../../feature/tags/shared/tags.service';
import { LookupService } from '../../services/lookup.service';
import { NotifyAppComponent } from '../../types/notify-app-component';
import { UtilsClass } from '../../types/utils/utils.class';

@Component({
  selector: 'app-patient-lookup',
  templateUrl: './patient-lookup.component.html',
  styleUrls: ['./patient-lookup.component.css'],
})
export class PatientLookupComponent implements OnInit {
  @Input()
  merchantID;

  itemsPerPage = 10;

  currentPage;
  dateOfBirth;
  isPromoterOrAdmin;
  firstName;
  lastName;
  filterData;
  filterEmail;
  filterName;
  filterPhone;
  mobile;
  calculatedName;
  hideFilter = false;
  showNextBtn = false;
  isModal = false;
  @Input()
  useStrict = true;

  patients: any = [];
  selectedPatient: any;
  utilsClass = new UtilsClass();

  showBackButton = true;
  closeModal = new EventEmitter();
  close = new EventEmitter();
  @Output()
  getResult = new EventEmitter();
  @Output()
  createNew = new EventEmitter();

  @Output()
  getSelectedPatientRaw = new EventEmitter();
  @Output()
  getSelectedPatient = new EventEmitter();
  @Output()
  getSelectedPatientID = new EventEmitter();

  profilePic;
  loading = false;
  onlyMyAccount;
  serviceRef;
  isDestoyed = false;

  title =
    'If you are creating an invitation for an existing patient, you can search for them using any (or all) of the field below:';
  title2 =
    'You detected a possible matching patient records in our database. Please confirm if any of the matches bellow are are the same records as the one you are entering.';

  orderByFilter = '-DateTimeCreated';
  @ViewChild('tagInput', { static: false }) tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
  tags = [];
  selectedTags = [];

  tag;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  sessionType = 'guest';

  constructor(
    private authenticationService: AuthenticationService,
    private lookupService: LookupService,
    private tagsService: TagsService,
    private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      if (data.invitationID) {
        this.isModal = true;
      }

      if (data.title) {
        this.title = data.title;
        this.isModal = true;
      }
      if (data.title2) {
        this.title2 = data.title2;
        this.isModal = true;
      }

      if (data.useStrict != null) {
        this.useStrict = data.useStrict;
        this.isModal = true;
      }

      if (data.useStrict != null) {
        this.useStrict = data.useStrict;
        this.isModal = true;
      }

      if (data.patients) {
        this.patients = data.patients || [];
        this.isModal = true;
      }

      if (data.merchantID) {
        this.merchantID = data.merchantID || [];
        this.isModal = true;
      }

      if (data.hideFilter != null) {
        this.hideFilter = data.hideFilter || false;
        this.isModal = true;

        if (this.hideFilter === true) {
          this.showBackButton = false;
        }
      }

      if (data.onlyMyAccount != null) {
        this.onlyMyAccount = data.onlyMyAccount || true;
        this.isModal = true;
      }

      if (data.showNextBtn != null) {
        this.showNextBtn = data.showNextBtn || false;
        this.isModal = true;
      }

      if (data.isModal != null) {
        this.isModal = data.isModal;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      this.tagsService
        .getTagsList(
          {
            fields: 'Label',
            staticAudience: false,
            merchantID: this.merchantID,
            orderBy: 'Label',
          },
          this.sessionType
        )
        .subscribe((res) => {
          if (res) {
            this.tags = res;
          }
        });
    });
  }

  getPatients() {
    this.currentPage = 1;
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        let payload: any = {};

        if (this.isPromoterOrAdmin == true && this.merchantID) {
          payload = {
            email: this.filterEmail,
            name: this.filterName,
            firstName: this.firstName,
            lastName: this.lastName,
            calculatedName: this.calculatedName,
            phone: this.filterPhone,
            mobile: this.mobile,
            merchantID: this.merchantID,
            dateOfBirth: this.dateOfBirth,
            onlyMyAccount: this.onlyMyAccount,
            useStrict: this.useStrict,
            section: 1,
            fields:
              'ID,TableName,ExistingPerson_key,LastModified,CalculatedName,phones.Number,DateOfBirth,DateTimeCreated,Name,Salutation,FirstName,Name,MiddleName,Gender.Code,mobiles.Number,emails.Email,Tag.Label,Tag.Description',
          };
        } else if (this.isPromoterOrAdmin == true && !this.merchantID) {
          payload = {
            email: this.filterEmail,
            name: this.filterName,
            firstName: this.firstName,
            lastName: this.lastName,
            calculatedName: this.calculatedName,
            dateOfBirth: this.dateOfBirth,
            phone: this.filterPhone,
            mobile: this.mobile,
            merchantID: this.merchantID,
            onlyMyAccount: this.onlyMyAccount,
            useStrict: this.useStrict,
            section: 1,
            fields:
              'ID,TableName,ExistingPerson_key,LastModified,CalculatedName,phones.Number,DateOfBirth,DateTimeCreated,Name,Salutation,FirstName,Name,MiddleName,Gender.Code,mobiles.Number,emails.Email,Tag.Label',
          };
        } else {
          payload = {
            email: this.filterEmail,
            name: this.filterName,
            firstName: this.firstName,
            lastName: this.lastName,
            calculatedName: this.calculatedName,
            dateOfBirth: this.dateOfBirth,
            phone: this.filterPhone,
            mobile: this.mobile,
            useStrict: this.useStrict,
            onlyMyAccount: this.onlyMyAccount,
            section: 1,
            fields:
              'ID,TableName,ExistingPerson_key,LastModified,CalculatedName,phones.Number,DateOfBirth,DateTimeCreated,Name,Salutation,FirstName,Name,MiddleName,Gender.Code,mobiles.Number,emails.Email,Tag.Label',
          };
        }

        if (this.selectedTags && this.selectedTags.length > 0 && Array.isArray(this.selectedTags)) {
          payload.tags = this.selectedTags.join(',');
        }
        this.serviceRef = this.lookupService.invitationLookup(payload, this.isPromoterOrAdmin).subscribe((res) => {
          if (res && res.length > 0) {
            this.patients = res;
            this.hideFilter = true;

            const innerFunction = (section) => {
              section = section + 1;
              payload.section = section;

              this.serviceRef = this.lookupService
                .invitationLookup(payload, this.isPromoterOrAdmin)
                .subscribe((res1) => {
                  if (res1.length > 0) {
                    this.patients = this.patients.concat(res1);

                    if (this.isDestoyed != true) {
                      innerFunction(section);
                    }
                  } else {
                    return true;
                  }
                });
            };

            if (this.isDestoyed != true) {
              innerFunction(1);
            }
          } else {
            NotifyAppComponent.displayToast(
              'warning',
              'Sorry!',
              'We did not any match of your patients in our database.'
            );
          }
        });
      });
    });
  }

  ngOnDestroy() {
    this.isDestoyed = true;
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }

  SelectRecordClickEvent(selectedPatient) {
    if (selectedPatient && selectedPatient.ID) {
      const user = {
        ID: null,
        prospectID: null,
        existingPatientID: null,
        firstName: null,
        title: null,
        lastName: null,
        middleName: null,
        gender: null,
        mobile: null,
        homePhone: null,
        workPhone: null,
        email: null,
        birthday: null,
        dateOfBirth: null,
        tableName: null,
        existingPersonID: selectedPatient.ID,
      };

      if (selectedPatient.ID) {
        user.ID = selectedPatient.ID;
        user.prospectID = selectedPatient.ID;
      }

      if (selectedPatient.ExistingPerson_key) {
        user.existingPatientID = selectedPatient.ExistingPerson_key;
      }

      if (selectedPatient['TableName']) {
        user.tableName = selectedPatient['TableName'];
      }

      if (selectedPatient['Email']) {
        user.email = selectedPatient['Email'];
      }

      if (selectedPatient['FirstName']) {
        user.firstName = selectedPatient['FirstName'];
      }

      if (selectedPatient['LastName']) {
        user.lastName = selectedPatient['LastName'];
      }

      if (selectedPatient['Mobile'] && selectedPatient['Mobile'] != 'undefined') {
        user.mobile = selectedPatient['Mobile'];
      }

      if (selectedPatient['emails.Email']) {
        user.email = selectedPatient['emails.Email'];
      }

      if (selectedPatient['FirstName']) {
        user.firstName = selectedPatient['FirstName'];
      }

      if (selectedPatient['LastName']) {
        user.lastName = selectedPatient['LastName'];
      }

      if (selectedPatient['Name']) {
        user.lastName = selectedPatient['Name'];
      }

      if (selectedPatient['MiddleName']) {
        user.middleName = selectedPatient['MiddleName'];
      }

      if (selectedPatient['mobiles.Number'] && selectedPatient['user.mobile'] != 'undefined') {
        user.mobile = selectedPatient['mobiles.Number'];
      }

      if (selectedPatient['phones.Number'] && selectedPatient['phones.Number'] != 'undefined') {
        user.homePhone = selectedPatient['phones.Number'];
      }

      if (selectedPatient['Phone.Work.Number'] && selectedPatient['Phone.Work.Number'] != 'undefined') {
        user.workPhone = selectedPatient['Phone.Work.Number'];
      }

      if (selectedPatient['Salutation']) {
        user.title = selectedPatient['Salutation'];
      }

      if (selectedPatient['Gender.Code']) {
        user.gender = selectedPatient['Gender.Code'];
      }

      if (selectedPatient['DateOfBirth'] && selectedPatient['DateOfBirth'] != '0000-00-00') {
        user.birthday = selectedPatient['DateOfBirth'];
      }
      if (selectedPatient['DateOfBirth'] && selectedPatient['DateOfBirth'] != '0000-00-00') {
        user.dateOfBirth = selectedPatient['DateOfBirth'];
      }

      this.getResult.emit(user);
      this.getSelectedPatient.emit(selectedPatient);
      this.getSelectedPatientID.emit(user.ID);
    }
  }

  closeEvent() {
    this.closeModal.emit(true);
    this.close.emit(true);
  }

  noMatch() {
    this.createNew.emit(true);
  }

  selectDateToFilter(d) {
    this.dateOfBirth = d;
  }

  getSelectedTags(e) {
    this.selectedTags = e || [];
  }

  openPatientViewDialog(ID, TableName) {
    if (ID) {
      if (TableName == 'Prospect') {
        const ref = this.dialog.open(ProspectViewComponent, {
          data: ID,
          width: '750px',
          panelClass: 'noCard',
        });

        ref.componentInstance.closeModal.subscribe((res) => {
          if (res == true) {
            ref.close();
          }
        });
      } else if (TableName == 'Customer') {
        const ref = this.dialog.open(CustomerViewComponent, {
          data: ID,
          width: '600px',
          panelClass: 'noCard',
        });

        ref.componentInstance.closeModal.subscribe((res) => ref.close());
      }
    }
  }

  orderBy(d) {
    if (d) {
      if (this.orderByFilter === d) {
        this.orderByFilter = '-' + d;
      } else {
        this.orderByFilter = d;
      }
    }
  }

  removeTag(t: string): void {
    const index = this.selectedTags.indexOf(t);

    if (index >= 0) {
      this.selectedTags.splice(index, 1);
    }
  }

  tagChange(t) {
    if (t) {
      const index = this.selectedTags.indexOf(t);

      if (index == -1 && typeof t == 'string') {
        this.selectedTags.push(t);
      }

      this.tag = null;
    }
  }

  goBack() {
    this.hideFilter = false;
  }

  goForward() {
    this.hideFilter = true;
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    let value = event.value;

    if (value) {
      value = value.trim();
      const index = this.selectedTags.indexOf(value);

      if (index == -1 && typeof value == 'string') {
        this.selectedTags.push(value);
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.tag = null;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (event && event.option && event.option.viewValue) {
      const v = event.option.viewValue;
      const index = this.selectedTags.indexOf(v);

      if (index == -1 && typeof v == 'string') {
        this.selectedTags.push(v);
      }
      this.tagInput.nativeElement.value = '';
      this.tag = null;
    }
  }

  changePage(e) {
    if (e) {
      this.currentPage = e;
    }
  }
}
