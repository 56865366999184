import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { SharedModule } from '../../shared/shared.module';
import { MerchantServiceCreateComponent } from './merchant-service-create/merchant-service-create.component';
import { MerchantServiceEditComponent } from './merchant-service-edit/merchant-service-edit.component';
import { MerchantServiceGroupCreateComponent } from './merchant-service-group-create/merchant-service-group-create.component';
import { MerchantServiceGroupEditComponent } from './merchant-service-group-edit/merchant-service-group-edit.component';
import { MerchantServiceGroupListComponent } from './merchant-service-group-list/merchant-service-group-list.component';
import { MerchantServiceListComponent } from './merchant-service-list/merchant-service-list.component';
import { MerchantServicesOverviewComponent } from './merchant-services-overview/merchant-services-overview.component';
import { MerchantServicesRoutingModule } from './shared/merchant-services-routing.module';

@NgModule({
  imports: [CommonModule, MerchantServicesRoutingModule, CdkTableModule, SharedModule, MatSortModule],
  declarations: [
    MerchantServiceGroupListComponent,
    MerchantServiceGroupEditComponent,
    MerchantServiceCreateComponent,
    MerchantServiceGroupCreateComponent,
    MerchantServiceListComponent,
    MerchantServicesOverviewComponent,
    MerchantServiceEditComponent,
  ],
  exports: [
    MerchantServiceGroupListComponent,
    MerchantServiceGroupEditComponent,
    MerchantServiceCreateComponent,
    MerchantServiceGroupCreateComponent,
    MerchantServiceListComponent,
    MerchantServicesOverviewComponent,
    MerchantServiceEditComponent,
  ],
  providers: [],
})
export class MerchantServicesModule {}
