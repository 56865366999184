<mat-card class="rel create-container supplier-card brandView clearfix mb" id="subscription-plan" @ngIfAnimation>
  <div class="card-header primary-gradient-img clearfix inModal rel" @ngIfAnimation [attr.style]="
      'background:linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
    " [attr.style]="
      'background:-moz-linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
    " [attr.style]="
      'background:-webkit-linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
    ">
    <div class="row clearfix content">
      <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModal()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m" *ngIf="subscriptionPlanID">
          Edit Subscription plan
          <span class="subLabel">Insert and customize Subscription plan details </span>
        </h2>
        <h2 class="summary-header rm-m" *ngIf="!subscriptionPlanID">
          Create Subscription plan
          <span class="subLabel">Insert and customize Subscription plan details </span>
        </h2>
      </div>
    </div>
  </div>

  <div class="full-width" @ngIfAnimation>
    <aw-wizard class="template-subscription-plan-create" #wizard [disableNavigationBar]="true"
      navBarLayout="large-filled-symbols">
      <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Define template" awOptionalStep>
        <div class="full-width clearfix mt">
          <form #resetform="ngForm">
            <div class="full-width clearfix flex row mb">
              <mat-card class="full-width row mr animate mat-elevation-z4">
                <h3 class="sr-title" [attr.style]="'color:' + color + ' !important' | safeStyle">
                  Subscription Plan details
                </h3>
                <div class="full-width flex">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Label</mat-label>
                    <input [(ngModel)]="label" name="label" matInput placeholder="Label" required />
                  </mat-form-field>
                </div>
                <div class="full-width clearfix">
                  <mat-form-field class="full-width animate" appearance="outline">
                    <mat-label>Short Description</mat-label>
                    <textarea class="mat-block main-textarea" [(ngModel)]="description" matInput name="description"
                      placeholder="Description" required>
                    </textarea>
                  </mat-form-field>
                </div>

                <div class="full-width clearfix">
                  <mat-form-field class="full-width animate" appearance="outline">
                    <mat-label>Web Description</mat-label>
                    <textarea class="mat-block main-textarea" [(ngModel)]="webDescription" matInput
                      name="webDescription" placeholder="Web Description" required>
                    </textarea>
                  </mat-form-field>
                </div>

                <div class="full-width flex mb">
                  <mat-checkbox class="slider-width pull-left slider-input-select full-width mr"
                    [(ngModel)]="useDefaultColor" (change)="changeDefaultColor()" name="useDefaultColor">
                    Use Theme Colors
                  </mat-checkbox>

                  <input class="color-input-sub half-width sml" *ngIf="useDefaultColor != true" [(colorPicker)]="_color"
                    [style.background]="color" [cpOutputFormat]="'hex'" (colorPickerClose)="onColorPickerClose($event)"
                    name="contactColor" />

                  <input class="color-input-sub half-width sml" *ngIf="useDefaultColor != true"
                    [(colorPicker)]="_colorSecond" [style.background]="colorSecond" [cpOutputFormat]="'hex'"
                    (colorPickerClose)="onColorPickerClose2($event)" name="contactColorSecond" />
                </div>

                <h3 class="sr-title" [attr.style]="'color:' + color + ' !important' | safeStyle">
                  Subscription Plan customization
                </h3>

                <div class="full-width flex">
                  <mat-form-field class="half-width" appearance="outline">
                    <mat-label>Price</mat-label>
                    <input [(ngModel)]="price" name="price" matInput placeholder="Price" currencyMask required />
                  </mat-form-field>

                  <mat-form-field class="half-width" appearance="outline">
                    <mat-label>Waived period (days)</mat-label>
                    <input [(ngModel)]="waivedPeriod" type="number" name="waivedPeriod" matInput
                      placeholder="Waived period (days)" required />
                  </mat-form-field>

                  <mat-form-field class="half-width" appearance="outline">
                    <mat-label>Min Treatements sent</mat-label>
                    <input [(ngModel)]="minTreatmentPlan" type="number" name="minTreatmentPlan" matInput
                      placeholder="Min Treatements sent" required />
                  </mat-form-field>
                </div>

                <div class="full-width mb flex">
                  <mat-checkbox class="slider-width pull-left slider-input-select mr" [(ngModel)]="isRecommended"
                    name="isRecommended">
                    Recommended plan?
                  </mat-checkbox>

                  <mat-checkbox class="slider-width pull-left slider-input-select mr" [(ngModel)]="isFreeSMS"
                    name="isFreeSMS">
                    Free SMS?
                  </mat-checkbox>

                  <mat-form-field class="qtr-width pull-left" *ngIf="isFreeSMS != true" appearance="outline">
                    <mat-label>SMS Price</mat-label>
                    <input [(ngModel)]="smsPrice" name="smsPrice" matInput placeholder="Market Value" currencyMask
                      required />
                  </mat-form-field>
                </div>

                <div class="full-width mb">
                  <mat-checkbox class="slider-width pull-left slider-input-select mr" [(ngModel)]="isActive"
                    (change)="changeActivePublic()" name="isActive">
                    Active?
                  </mat-checkbox>

                  <mat-checkbox class="slider-width pull-left slider-input-select ml mr" [(ngModel)]="isPublic"
                    (change)="changeActivePublic()" name="isPublic">
                    Public plan?
                  </mat-checkbox>

                  <mat-checkbox class="slider-width pull-left slider-input-select ml mr" [(ngModel)]="isDefault"
                    (ngModelChange)="changeDefault()" name="isDefault">
                    Default plan?
                  </mat-checkbox>

                  <mat-checkbox class="pull-left slider-width slider-input-select mr" *ngIf="isDefault == true"
                    [(ngModel)]="updateUsers" name="updateUsers">
                    Update Default plan Users?
                  </mat-checkbox>
                </div>

                <h3 class="sr-title" [attr.style]="'color:' + color + ' !important' | safeStyle">
                  Subscription Sign Up
                </h3>
                <div class="full-width mb">
                  <mat-checkbox class="pull-left full-width slider-input-select "
                    [(ngModel)]="useExternalSignupLink" name="useExternalSignupLink">
                    Use external subscription link?
                  </mat-checkbox>


                  <mat-form-field class="full-width" appearance="outline" *ngIf="useExternalSignupLink==true">
                    <mat-label>external subscription link</mat-label>
                    <input [(ngModel)]="externalSignupLink" name="externalSignupLink" matInput placeholder="external subscription link" required />
                  </mat-form-field>

                </div>


                <h3 class="sr-title" [attr.style]="'color:' + color + ' !important' | safeStyle">
                  Marketing descriptions
                </h3>
                <p class="ml">Insert one or multiple Marketing descriptions to be displayed for the user</p>
                <div class="full-width">
                  <div class="list full-width mr">
                    <div class="full-width" *ngFor="let item of marketingDescriptions; let idx = index">
                      <div class="full-width flex">
                        <mat-form-field class="full-width" appearance="outline">
                          <mat-label>{{ idx + 1 }}- Insert a description</mat-label>
                          <textarea class="mat-block sub-textarea" [(ngModel)]="item"
                            (change)="changeMarketing(idx, $event.target.value)" matInput
                            placeholder="{{ idx + 1 }}- Insert a description" name="Insertadescription {{ idx }}">
                          </textarea>
                        </mat-form-field>
                        <button class="pull-right smr marketing-delete"
                          *ngIf="marketingDescriptions && marketingDescriptions.length > 1"
                          (click)="removeMarketingDescription(idx)" mat-icon-button color="primary">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                  <button class="btn mt pull-right"
                    [attr.style]="'background:' + colorSecond + ' !important' | safeStyle"
                    (click)="addMarketingDescription()" mat-mini-fab color="primary">
                    <mat-icon>add</mat-icon>
                  </button>
                </div>
              </mat-card>
              <div class="qtr-width row blob-for-sub-plain-container-thumbnail ml mr">
                <div class="full-width">
                  <app-ep-document-view-image class="squareImg squareImg-social"
                    *ngIf="!postPictureLinkThumbnail && postPictureThumbnail" [hideZoomButton]="false" [defaultPicture]="
                      'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/light_icon2.png)'
                    " [link]="postPictureThumbnail">
                  </app-ep-document-view-image>
                  <app-ep-document-view-image class="squareImg squareImg-social"
                    *ngIf="postPictureLinkThumbnail && !postPictureThumbnail" [hideZoomButton]="false" [defaultPicture]="
                      'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/light_icon2.png)'
                    " [link]="postPictureLinkThumbnail">
                  </app-ep-document-view-image>
                  <app-ep-document-view-image class="squareImg squareImg-social"
                    *ngIf="!postPictureLinkThumbnail && !postPictureThumbnail" [hideZoomButton]="false"
                    [defaultPicture]="
                      'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/light_icon2.png)'
                    ">
                  </app-ep-document-view-image>
                </div>
                <div class="clearfix full-width">
                  <hr />
                  <app-file-uploader class="text-center clearfix full-width" [allowedExtensions]="allowExtensions"
                    [aspectRatioType]="'full'" [maxFileSize]="2" [isImageCropping]="true" [canSkip]="true" [limit]="1"
                    [uploadDirectly]="true" [isDragable]="true" [title]="'CLICK HERE to upload (Max size: ' + 2 + 'MB)'"
                    (onCompleteAll)="onCompleteThumbnail($event)">
                  </app-file-uploader>
                </div>
              </div>
            </div>
            <div class="full-width row mr">
              <div class="clearfix full-width mb">
                <h3 class="sr-title mt" [attr.style]="'color:' + color + ' !important' | safeStyle">
                  Cover Image
                </h3>
                <p>
                  Recommended dimension is <strong>600x400 px</strong> (only images are allowed). Max image size is
                  {{ maxFileSize }} MB
                </p>
                <div class="full-width mb blob-for-sub-plain-container">
                  <app-blob-view class="row clearfix flex flexCol blob-for-social-media full-width full-height"
                    *ngIf="(!postPictureLink && postPicture && loadingPost != true) || loadingPost == true"
                    [label]="'Campaign'" [applyDownload]="applyDownload" [loading]="loadingPost" [isDownload]="false"
                    [isProceed]="false" [isPrint]="false" [canProceed]="false" [content]="postPicture"
                    [extension]="'png'" [label]="">
                  </app-blob-view>
                  <div class="view-container full-height full-width"
                    *ngIf="postPictureLink && !postPicture && loadingPost != true">
                    <div class="row clearfix">
                      <pinch-zoom class="full-height clearfix full-width pinchZoom" [limit-zoom]="25" [wheel]="true"
                        [wheelZoomFactor]="0.5" [double-tap]="true" [properties]="pinchZoomProperties">
                        <img [src]="postPictureLink" />
                      </pinch-zoom>
                    </div>
                  </div>
                  <div class="view-container full-height full-width" *ngIf="
                      (!postPicture || postPicture == null) &&
                      (!postPictureLink || postPictureLink == null) &&
                      loadingPost != true
                    ">
                    <div class="row clearfix">
                      <pinch-zoom class="full-height clearfix full-width pinchZoom" [limit-zoom]="25" [wheel]="true"
                        [wheelZoomFactor]="0.5" [double-tap]="true" [properties]="pinchZoomProperties">
                        <img [src]="
                            'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/cover/smile-cover.png'
                          " />
                      </pinch-zoom>
                    </div>
                  </div>
                </div>
                <div class="clearfix full-width">
                  <app-file-uploader class="text-center clearfix full-width" [allowedExtensions]="allowExtensions"
                    [aspectRatioType]="'two-third'" [maxFileSize]="maxFileSize" [isFixedAspectRatio]="false"
                    [isImageCropping]="true" [canSkip]="false" [limit]="1" [uploadDirectly]="true" [isDragable]="true"
                    [title]="'CLICK HERE to upload  Cover (Max size: ' + maxFileSize + 'MB)'"
                    (onCompleteAll)="onComplete($event)">
                  </app-file-uploader>
                </div>
              </div>
            </div>
            <hr class="mb" />
            <div class="drop-button full-width mt">
              <button class="btn-large pull-left" [attr.style]="'background:' + color + ' !important' | safeStyle"
                (click)="planList()" mat-raised-button color="primary">
                <mat-icon>chevron_left</mat-icon> Subscription Plans list
              </button>
              <button class="btn-large pull-right" [disabled]="!resetform.form.valid" (click)="proceedModule()"
                mat-raised-button color="accent">
                Next
                <mat-icon>chevron_right</mat-icon>
              </button>
            </div>
          </form>
        </div>
      </aw-wizard-step>
      <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Modules" awOptionalStep>
        <div class="container mt" id="step-4">
          <p>Choose <strong>Modules</strong> to be included in this subscription plan.</p>
          <div class="full-width" *ngIf="loadModule == true">
            <app-module-list class="subscription-plan-module" [readOnly]="true" [selectCurrent]="false"
              [isListView]="true" [defaultSelectedCodes]="selectedModuleCodes" [canSelect]="true"
              [displayHeader]="false" (getSelectedCodes)="getSelectedModuleIDsEvent($event)">
            </app-module-list>
          </div>
          <hr class="mb">
          <div class="drop-button full-width mt text-center">
            <button class="btn-large pull-left" [attr.style]="'background:' + color + ' !important' | safeStyle"
              (click)="stepBack()" mat-raised-button color="primary">
              <mat-icon>chevron_left</mat-icon> Previous
            </button>
            <button class="btn-large pull-right" (click)="proceedFeature()" mat-raised-button color="accent">
              Next
              <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </div>
      </aw-wizard-step>
      <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Features" awOptionalStep>
        <div class="container mt" id="step-4">
          <p>Choose <strong>Features</strong> to be included in this subscription plan with access level.</p>
          <div class="full-width" *ngIf="loadFeature == true">
            <app-feature-main-label-list class="subscription-plan-module" [actionButton]="false"
              [isMarketingOnly]="false" [featureType]="'merchant'" [title]="null" [hideEmptyFeatures]="true"
              [isEmbed]="true" [maxText]="150" [allView]="false" [canSelectMain]="true" [displayCover]="false"
              [actionButton]="false" [description]="null" [readOnly]="true" [canSelect]="true" [hideDisplayCheck]="true"
              [defaultSelectedIDs]="selectedSubFeatureIDs" [defaultSelectedLimitedIDs]="selectedSubFeatureLimitedIDs"
              (getSelectedLimitedIDs)="getSelectedSubFeatureLimitedIDsEvent($event)"
              (getSelectedIDs)="getSelectedSubFeatureIDsEvent($event)"
              (getIsSelectionChange)="getIsSelectionChangeEvent($event)">
            </app-feature-main-label-list>
          </div>

          <hr class="mb">
          <div class="drop-button full-width mt text-center">
            <button class="btn-large pull-left" [attr.style]="'background:' + color + ' !important' | safeStyle"
              (click)="stepBack()" mat-raised-button color="primary">
              <mat-icon>chevron_left</mat-icon> Previous
            </button>
            <button class="btn-large pull-right" (click)="save()" mat-raised-button color="accent">
              Submit
              <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </div>
      </aw-wizard-step>

      <aw-wizard-step [navigationSymbol]="{ symbol: '4' }" stepTitle="Done!" awOptionalStep>
        <div class="container mt" id="step-2" *ngIf="createdRecord && createdRecord.ID" @ngIfAnimation>
          <div class="animate clearfix">
            <div class="row clearfix">
              <mat-card class="card successful">
                <div class="row clearfix">
                  <div class="full-width text-center">
                    <h1 class="sm-h1 sr-title">Well Done!</h1>
                    <h3 class="sm-h3 rm-m" [attr.style]="'color:' + color + ' !important' | safeStyle">
                      Module is Modified
                    </h3>
                    <div class="row clearfix">
                      <div class="svg-box">
                        <svg class="circular green-stroke">
                          <circle class="path" cx="75" cy="75" r="50" fill="none" stroke-width="5"
                            stroke-miterlimit="10" />
                        </svg>
                        <svg class="checkmark green-stroke">
                          <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                            <path class="checkmark__check" fill="none"
                              d="M616.306,283.025L634.087,300.805L673.361,261.53" />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row clearfix text-center">
                  <button class="mr" [attr.style]="'background:' + color + ' !important' | safeStyle"
                    (click)="planList()" mat-raised-button color="primary">
                    <mat-icon class="smr">list</mat-icon> Subscription plan list
                  </button>
                  <button class="ml" [attr.style]="'background:' + color + ' !important' | safeStyle"
                    (click)="viewSubscriptionPlan(createdRecord.ID)" mat-raised-button color="accent">
                    <mat-icon class="smr">visibility</mat-icon>
                    <span>View Subscription plan</span>
                  </button>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </aw-wizard-step>
    </aw-wizard>
  </div>
</mat-card>
