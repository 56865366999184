import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ExistingPatientService {
  params: HttpParams;

  constructor(private http: HttpClient) {}

  /**
   * Description
   * Get ExistingPatient details
   */
  getExistingPatientDetails(ID, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (
      sessionType == 'admin' ||
      sessionType == 'promoter' ||
      sessionType == 'merchant' ||
      sessionType == 'merchant-admin'
    ) {
      return this.http
        .get(environment.nodeUrl + '/existing-patient/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/existing-patient/public/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getExistingPersonProfilePic(ID, payload = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.http
      .get(`${environment.nodeUrl}/existing-patient/profile-picture/` + ID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getProfilePicStreamLink(ID) {
    if (ID) {
      return `${environment.nodeUrl}/existing-patient/profile-picture-stream/` + ID;
    }
    return null;
  }

  getExistingPatientFullDetails(ID): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/existing-patient/full/' + ID)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  linkToInv(existingPatientID, invitationID = null): Observable<any> {
    const payload = {
      invitationID,
    };
    return this.http.post(`${environment.nodeUrl}/existing-patient/invitation/${existingPatientID}`, payload);
  }

  addCoordinate(ID, body) {
    return this.http
      .put(environment.nodeUrl + '/prospect/coordinate/' + ID, body)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getProspectDetails(ID, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/prospect/global/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http.get(environment.nodeUrl + '/prospect/' + ID).pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/prospect/public/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  editProspect(ID, payload): Observable<any> {
    return this.http
      .put(environment.nodeUrl + '/prospect/' + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPatientInvitationDetails(ID): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/existing-patient/invitation/' + ID)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getDashbordStatistic(body, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };
    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/existing-patient/dashbord/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/existing-patient/dashbord', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  /**
   * Description
   * Get ExistingPatients List
   */
  getExistingPatientsList(payload, isPromoterOrAdmine = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmine == false) {
      return this.http
        .get(`${environment.nodeUrl}/existing-patient`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(`${environment.nodeUrl}/existing-patient/global`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getExistingGlobalPatientsList(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    return this.http
      .get(`${environment.nodeUrl}/existing-patient/global`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  /**
   * Description
   * Add ExistingPatient
   */
  addExistingPatient(existingPatient, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.http
        .post(environment.nodeUrl + '/prospect/global', existingPatient)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/prospect', existingPatient)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  /**
   * Description
   * Link ExistingPatient to invitation
   */

  getPatients(payload): Observable<any> {
    const urlPatient = environment.nodeUrl + '/existing-patient';
    this.setParams(payload);
    const options = {
      params: this.params,
    };

    return this.http.get(urlPatient, options);
  }

  patientLookup(payload, isPromoterOrAdmin = false): Observable<any> {
    let urlPatient = environment.nodeUrl + '/existing-patient/lookup';
    this.setParams(payload);
    const options = {
      params: this.params,
    };

    if (isPromoterOrAdmin == true) {
      urlPatient = environment.nodeUrl + '/existing-patient/lookup/global';
    }

    return this.http.get(urlPatient, options);
  }

  searchByInvitationId(invitationID, payload = {}, isPromoterOrAdmin = false): Observable<any> {
    let urlPatient = environment.nodeUrl + '/existing-patient/userdata/' + invitationID;
    let params: HttpParams = new HttpParams();
    const options = {};

    if (isPromoterOrAdmin == true) {
      urlPatient = environment.nodeUrl + '/existing-patient/userdata/global/' + invitationID;
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options['params'] = params;
    }
    return this.http.get(urlPatient, options);
  }

  setParams(param) {
    this.params = new HttpParams();
    for (const key in param) {
      if (param.hasOwnProperty(key)) {
        const val = param[key];
        this.params = this.params.append(key, val);
      }
    }
  }

  customerOrProspectList(body, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };
    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/customer-prospect/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/customer-prospect', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  uploadProfilePic(ID, payload = {}) {
    return this.http
      .put(`${environment.nodeUrl}/customer-prospect/profile-picture/` + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getProfilePic(ID, payload = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key]) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(`${environment.nodeUrl}/customer-prospect/profile-picture/` + ID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }
}
