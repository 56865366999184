<div class="card-header primary-gradient-img clearfix inModal saveModal">
  <div class="row clearfix">
    <!-- merchant calculated name -->
    <div class="row titleArea clearfix">
      <mat-icon class="pull-left fas fa-object-ungroup"></mat-icon>
      <h2 class="summary-header rm-m">
        Clone template
        <span class="subLabel">Clone Invitation Template please Insert the Label and Description of new Template</span>
      </h2>
    </div>
    <!-- Modal dismiss button -->
    <button class="btn-close btn-clear mat-button white" (click)="close()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>

<mat-dialog-content>
  <form #editForm="ngForm">
    <div class="row clearfix mt">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Template label</mat-label>
        <input
          class="mat-block largeInput"
          [(ngModel)]="label"
          name="totalTreatmentValue"
          matInput
          placeholder="Template label"
          required
        />
      </mat-form-field>
    </div>

    <div class="row clearfix">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Description</mat-label>
        <textarea [(ngModel)]="description" name="description" matInput placeholder="Description"></textarea>
      </mat-form-field>
    </div>

    <div class="row clearfix mt" *ngIf="isPromoterOrAdmin == true">
      <mat-checkbox class="pull-left clearfix" [(ngModel)]="isDefault" name="discount_toggle2"
      ><span class="text"> Make this template available to all merchants </span></mat-checkbox
      >
    </div>

    <hr/>
    <div class="row clearfix text-center">
      <button (click)="close()" mat-raised-button color="primary">Close</button>

      <button
        class="mr ml"
        [disabled]="!editForm.form.valid"
        (click)="sendResult(false)"
        mat-raised-button
        color="accent"
      >
        Save
      </button>

      <button [disabled]="!editForm.form.valid" (click)="sendResult(true)" mat-raised-button color="accent">
        Save & Edit
      </button>
    </div>
  </form>
</mat-dialog-content>
