/// <reference types="@types/googlemaps" />

import { GoogleMapsAPIWrapper, MapsAPILoader } from '@agm/core';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { google } from "@agm/core/services/google-maps-types";
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsClass } from '../../types/utils/utils.class';
import { RootAppComponent } from '../root-component/root-component.component';

declare var google: any;

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.css'],
})
export class MapViewComponent implements OnInit {
  @Input()
  forceGettingAddress = false;

  @Input()
  positions = [];

  @Input('streetName')
  streetName;

  @Input()
  streetNumber;

  @Input()
  latitude;

  @Input()
  longitude;

  @Input()
  unitNumber;

  isLogged = false;

  addressLineFull;

  @Input()
  addressLine = null;

  @Input('canClose')
  canClose = false;

  @Input('canClick')
  canClick = true;

  @Output()
  close = new EventEmitter();

  @Output()
  notFoundEvent = new EventEmitter();

  @Output()
  getSelected = new EventEmitter();

  @Input()
  addressLabel = null;

  @Input()
  moreInfo = null;
  positionTime = null;
  util = new UtilsClass();

  notFound = false;
  @Input()
  zoom = 12;

  address = '';

  @Input()
  state;

  @Input()
  icon = 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/marker.png';

  @Input()
  suburb;

  @Input()
  streetType;

  loading = true;

  @Input()
  country = 'AU';

  @Input()
  showDetails = false;

  isAddCoordinate = true;

  @Output()
  addCoordinate = new EventEmitter();

  @Input()
  postCode;
  geocoder;
  isNightMode = JSON.parse(localStorage.getItem('isDark'));

  @Input()
  isLightMode = false;

  styles;

  isMapLoaded = false;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private authenticationService: AuthenticationService,
    private mapsApiLoader: MapsAPILoader,
    public gMaps: GoogleMapsAPIWrapper
  ) {
    if (data) {
      if (data.positions) {
        this.positions = data.positions;
      }

      if (data.postCode) {
        this.postCode = data.postCode;
        this.canClose = true;
      }
      if (data.unitNumber) {
        this.unitNumber = data.unitNumber;
      }
      this.canClose = true;

      if (data.icon) {
        this.icon = data.icon;
      }

      if (data.suburb) {
        this.suburb = data.suburb;
        this.canClose = true;
      }
      if (data.state) {
        this.state = data.state;
        this.canClose = true;
      }
      if (data.streetName) {
        this.streetName = data.streetName;
        this.canClose = true;
      }
      if (data.streetNumber) {
        this.streetNumber = data.streetNumber;
        this.canClose = true;
      }
      if (data.streetType) {
        this.streetType = data.streetType;
        this.canClose = true;
      }
      if (data.country) {
        this.country = data.country;

        if (data.zoom) {
          this.zoom = data.zoom;
        }

        if (data.moreInfo) {
          this.moreInfo = data.moreInfo;
        }

        if (data.addressLabel) {
          this.addressLabel = data.addressLabel;
        }

        if (data.latitude) {
          this.latitude = data.latitude;
        }

        if (data.longitude) {
          this.longitude = data.longitude;
        }
      }
    }

    if (this.positions.length > 0) {
      if (
        (this.positions[this.positions.length - 1].Latitude || this.positions[this.positions.length - 1].Longitude) &&
        Number(this.positions[this.positions.length - 1].Latitude) != 0 &&
        Number(this.positions[this.positions.length - 1].Longitude) != 0
      ) {
        this.gMaps.setCenter({
          lat: Number(this.positions[this.positions.length - 1].Latitude),
          lng: Number(this.positions[this.positions.length - 1].Longitude),
        });
      }
    }
  }

  ngOnInit() {
    this.authenticationService.isLogin().subscribe((res) => {
      this.isLogged = res;

      this.getPositionByAddress();

      if (this.isNightMode == true) {
        this.styles = [
          { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
          {
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#242f3e' }],
          },
          { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
          {
            featureType: 'administrative.locality',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }],
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }],
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{ color: '#263c3f' }],
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#6b9a76' }],
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: '#38414e' }],
          },
          {
            featureType: 'road',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#212a37' }],
          },
          {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#9ca5b3' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{ color: '#746855' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#1f2835' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#f3d19c' }],
          },
          {
            featureType: 'transit',
            elementType: 'geometry',
            stylers: [{ color: '#2f3948' }],
          },
          {
            featureType: 'transit.station',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }],
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{ color: '#17263c' }],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#515c6d' }],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#17263c' }],
          },
        ];
      } else if (this.isLightMode == true) {
        this.styles = [
          { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
          {
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#242f3e' }],
          },
          { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
          {
            featureType: 'administrative.locality',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }],
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }],
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{ color: '#263c3f' }],
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#6b9a76' }],
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: '#38414e' }],
          },
          {
            featureType: 'road',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#212a37' }],
          },
          {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#9ca5b3' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{ color: '#746855' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#1f2835' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#f3d19c' }],
          },
          {
            featureType: 'transit',
            elementType: 'geometry',
            stylers: [{ color: '#2f3948' }],
          },
          {
            featureType: 'transit.station',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }],
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{ color: '#17263c' }],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#515c6d' }],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#17263c' }],
          },
        ];
      } else {
        this.styles = '';
      }
    });
  }

  getGeoLocation(lat: number, lng: number) {
    if (navigator.geolocation) {
      const latlng = new google.maps.LatLng(lat, lng);
      const request = { latLng: latlng };
      return Observable.create((observer) => {
        RootAppComponent.geocoder.geocode(request, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            const result = results[0];
            observer.next(result);
            observer.complete();
          }
        });
      });
    }
  }

  getPositionByAddress() {
    this.loading = true;
    this.addressLineFull = '';

    const geoObject = {};

    let getAddress = true;
    geoObject['address'] = '';

    if (this.unitNumber && this.streetNumber && this.streetName) {
      this.addressLineFull = this.addressLineFull + this.unitNumber + '/';
      geoObject['address'] = geoObject['address'] + this.unitNumber + ',';
    }

    if (this.streetNumber && this.streetName) {
      this.addressLineFull = this.addressLineFull + this.streetNumber + ' ';

      geoObject['address'] = geoObject['address'] + this.streetNumber + ',';
    }
    if (this.streetName) {
      this.addressLineFull = this.addressLineFull + this.streetName + ' ';

      geoObject['address'] = geoObject['address'] + this.streetName + ',';

      if (this.streetType) {
        this.addressLineFull = this.addressLineFull + this.streetType + ' ';

        geoObject['address'] = geoObject['address'] + this.streetType + ',';
      }
    }
    if (this.suburb) {
      this.addressLineFull = this.addressLineFull + this.suburb + ' ';
    }
    if (this.state && this.postCode) {
      this.addressLineFull = this.addressLineFull + this.state + ' ';
      geoObject['address'] = geoObject['address'] + this.state + ',';
    }

    if (this.postCode) {
      this.addressLineFull = this.addressLineFull + this.postCode + ' ';
      geoObject['address'] = geoObject['address'] + this.postCode + ',';
    }

    if (geoObject['address'] == '' && this.addressLineFull == '') {
      getAddress = false;
    }
    geoObject['address'] = geoObject['address'] + (this.country || 'AU');

    this.addressLineFull = this.addressLineFull + this.country;

    if (navigator.geolocation && typeof google !== 'undefined' && google && RootAppComponent.geocoder) {
      if (getAddress == true) {
        if (
          !this.latitude ||
          !this.longitude ||
          (this.longitude == 0 && this.latitude == 0) ||
          this.forceGettingAddress != false
        ) {
          if (geoObject['address'] == '') {
            this.notFound = true;
            this.notFoundEvent.emit(true);
          } else {
            this.addressLine = {
              AddressLine: geoObject['address'],
            };
            RootAppComponent.geocoder.geocode(geoObject, (results, status) => {
              this.loading = false;
              if (results && results[0]) {
                this.notFoundEvent.emit(false);
                this.notFound = false;
                const position = {
                  Latitude: results[0].geometry.location.lat(),
                  Longitude: results[0].geometry.location.lng(),
                  time: null,
                };
                if (this.isAddCoordinate == true && this.isLogged == true) {
                  const payload = {
                    latitude: position.Latitude,
                    longitude: position.Longitude,
                  };
                  this.addCoordinate.emit(payload);
                }
                this.getSelected.emit(position);
                this.positions = [];
                this.positions.push(position);
                if (this.addressLabel) {
                  this.addressLine = {
                    AddressLine: this.addressLabel,
                  };
                } else {
                  this.addressLine = {
                    AddressLine: results[0].formatted_address,
                  };
                }
              } else {
                this.notFound = true;

                this.notFoundEvent.emit(true);
              }
            });
          }
        } else if (this.latitude && this.longitude) {
          this.loading = false;
          const position = {
            Latitude: this.latitude,
            Longitude: this.longitude,
            time: null,
          };
          if (this.addressLabel) {
            this.addressLine = {
              AddressLine: this.addressLabel,
            };
          } else {
            this.addressLine = {
              AddressLine: this.addressLineFull,
            };
          }
          this.getSelected.emit(position);
          this.positions = [];
          this.positions.push(position);
        }
      }
    } else {
      this.loading = false;
      console.error('google map is not loaded');
      this.notFound = true;
      this.notFoundEvent.emit(true);
    }
  }

  toNumber(n) {
    return Number(n);
  }

  closeEvent() {
    this.close.emit(true);
  }
}
