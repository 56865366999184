import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { Settings } from '../../types/settings';
import { UtilsClass } from '../../types/utils/utils.class';

@Component({
  selector: 'app-chart-input',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css'],
})
export class ChartComponent implements OnInit {
  // options
  @Input()
  showXAxis = Settings.global['chartShowXAxis'];
  @Input()
  showYAxis = Settings.global['chartShowYAxis'];

  @Input()
  showLegend = Settings.global['chartShowLegend'];

  @Input()
  showLabel = Settings.global['chartShowLabel'];

  @Input()
  showXAxisLabel = Settings.global['chartShowXAxisLabel'];

  @Input()
  showYAxisLabel = Settings.global['chartShowYAxisLabel'];

  @Input()
  roundDomains = Settings.global['chartRoundDomains'];

  @Input()
  showGridLines = Settings.global['chartShowGridLines'];

  @Input()
  barPadding = Number(Settings.global['chartBarPadding']);

  @Input()
  showDoughnut = Settings.global['chartShowDoughnut'];

  @Input()
  showTrimLabels = Settings.global['chartShowTrimLabels'];

  @Input()
  showTimeline = Settings.global['chartShowTimeline'];

  @Input()
  showRefLines = Settings.global['chartShowRefLines'];

  @Input()
  showRefLabels = Settings.global['chartShowRefLabels'];

  @Input()
  autoScale = Settings.global['chartAutoScale'];

  @Input() showXGridLines = true;

  @Input() showYGridLines = false;

  @Input()
  dateFormat = 'DD-MM-YYYY';

  @Input()
  yAxis = false;

  @Input()
  legendPosition = 'right';

  @Input()
  tooltipDisabled;

  @Input()
  trimXAxisTicks;

  @Input()
  xAxis = false;

  @Input()
  titleIsTop = true;

  @Input()
  yAxisLabel;

  @Input()
  xAxisLabel;

  @Input()
  title;

  @Input()
  data = [
    {
      name: 'Germany',
      value: 40632,
    },
    {
      name: 'United States',
      value: 49737,
    },
    {
      name: 'France',
      value: 36745,
    },
    {
      name: 'United Kingdom',
      value: 36240,
    },
    {
      name: 'Spain',
      value: 33000,
    },
    {
      name: 'Italy',
      value: 35800,
    },
  ];

  @Input()
  colorScheme = Settings.global['chartColors'];

  chartColorScheme = {
    domain: this.colorScheme,
  };

  chartColorStandard = {
    domain: ['#1b8bdd', '#ff9800', '#4caf50', '#f44336'],
  };

  @Input()
  customColorScheme;

  @Input()
  gradient = Settings.global['chartGradient'];

  @Input()
  schemeCustom;

  @Input()
  chartWidth;

  @Input()
  chartHeight;

  @Input()
  view = undefined;
  // view = [this.chartWidth, this.chartHeight];

  @Input()
  chartType = 'barVertical';

  _chartType;

  @Output()
  selectItem = new EventEmitter();

  @Input()
  dataNameAttribute = 'name';

  isAdvanced = false;

  @Input()
  dataValueAttribute = 'value';

  @Input()
  dataAdvancedNameAttribute = 'name';

  @Input()
  dataAdvancedValueAttribute = 'series';

  @Input()
  buildAdvanced = false;

  @Input()
  separator = ': ';

  chartData = [];

  utils = new UtilsClass();

  constructor() {}

  ngOnInit() {
    if (this.chartWidth || this.chartHeight) {
      this.view = [this.chartWidth, this.chartHeight];
    }
    this.setUp();
  }

  ngAfterViewInit(): void {
    setTimeout((_) => {
      window.dispatchEvent(new Event('resize'));
    }, 10); // BUGFIX:
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.colorScheme) {
      this.chartColorScheme.domain = this.colorScheme;
    }

    this.setUp();
  }

  setUp() {
    let dataNameAttributeParts = [];
    if (this.dataNameAttribute) {
      dataNameAttributeParts = this.dataNameAttribute.split(',');
    }

    let dataAdvancedNameAttributeParts = [];
    if (this.dataAdvancedNameAttribute) {
      dataAdvancedNameAttributeParts = this.dataAdvancedNameAttribute.split(',');
    }

    let dataAdvancedValueAttributeParts = [];
    if (this.dataAdvancedValueAttribute) {
      dataAdvancedValueAttributeParts = this.dataAdvancedValueAttribute.split(',');
    }

    this.chartData = [];
    const _chartData = [];

    this.isAdvanced = this.isAdvancedChart();

    if (this.data) {
      if (Array.isArray(this.data) && this.isAdvanced == false) {
        this.data.map((item) => {
          if (item[dataNameAttributeParts[0]]) {
            let o;
            if (this.chartType === 'line' || this.chartType === 'area') {
              o = {
                name: moment(item[dataNameAttributeParts[0]], this.dateFormat),
                value: item[this.dataValueAttribute],
              };
            } else {
              o = {
                name: item[dataNameAttributeParts[0]] || null,
                value: item[this.dataValueAttribute] || 0,
              };
            }

            for (let i = 1; i < dataNameAttributeParts.length; i++) {
              o.name = o.name + this.separator + item[dataNameAttributeParts[i]];
            }

            _chartData.push(o);
          }
        });

        this.chartData = _chartData;
        if (
          this.utils.chartTypes.barHorizontalNormalized == this.chartType ||
          this.utils.chartTypes.barHorizontalGrouped == this.chartType ||
          this.utils.chartTypes.barHorizontalStacked == this.chartType
        ) {
          this._chartType == this.utils.chartTypes.barHorizontal;
        } else if (
          this.utils.chartTypes.barVerticalNormalized == this.chartType ||
          this.utils.chartTypes.barVerticalGrouped == this.chartType ||
          this.utils.chartTypes.barVerticalStacked == this.chartType
        ) {
          this._chartType == this.utils.chartTypes.barVertical;
        } else {
          this._chartType = this.chartType;
        }
      } else if (Array.isArray(this.data) && this.isAdvanced == true && this.buildAdvanced == false) {
        this.data.map((item) => {
          if (item[dataAdvancedNameAttributeParts[0]]) {
            const o = {
              name: item[dataAdvancedNameAttributeParts[0]] || null,
              series: [],
            };

            for (let i = 1; i < dataAdvancedNameAttributeParts.length; i++) {
              o.name = o.name + this.separator + item[dataAdvancedNameAttributeParts[i]];
            }

            if (item && item[this.dataAdvancedValueAttribute] && Array.isArray(item[this.dataAdvancedValueAttribute])) {
              item[this.dataAdvancedValueAttribute].map((item2) => {
                if (item2[dataNameAttributeParts[0]]) {
                  const o2 = {
                    name: item2[dataNameAttributeParts[0]] || null,
                    value: item2[this.dataValueAttribute] || 0,
                  };

                  for (let i = 1; i < dataNameAttributeParts.length; i++) {
                    o2.name = o2.name + this.separator + item2[dataNameAttributeParts[i]];
                  }

                  o.series.push(o2);
                }
              });
            }

            _chartData.push(o);
          }
        });

        this.chartData = _chartData;
        this._chartType = this.chartType;
      } else if (Array.isArray(this.data) && this.isAdvanced == true && this.buildAdvanced == true) {
        const o = {
          name: this.yAxisLabel,
          series: [],
        };

        this.data.map((item) => {
          if (item[dataNameAttributeParts[0]]) {
            if (item[dataNameAttributeParts[0]]) {
              let o2;
              if (this.chartType === 'line' || this.chartType === 'area') {
                o2 = {
                  name: item[dataNameAttributeParts[0]],
                  value: item[this.dataValueAttribute],
                };
              } else {
                o2 = {
                  name: item[dataNameAttributeParts[0]] || null,
                  value: item[this.dataValueAttribute] || 0,
                };
              }

              for (let i = 1; i < dataNameAttributeParts.length; i++) {
                o2.name = o2.name + this.separator + item[dataNameAttributeParts[i]];
              }

              o.series.push(o2);
            }
          }
        });

        _chartData.push(o);

        this.chartData = _chartData;
        this._chartType = this.chartType;
      } else if (typeof this.data == 'object') {
        const _data: any = this.data;
        for (const key in _data) {
          if (_data.hasOwnProperty(key)) {
            const o = {};
            o['name'] = key;
            o['series'] = [];
            _data[key].map((item) => {
              if (item[this.dataNameAttribute]) {
                const _o = {
                  name: item[this.dataNameAttribute] || null,
                  value: item[this.dataValueAttribute] || 0,
                };

                o['series'].push(_o);
              }
            });
            this.chartData.push(o);

            this._chartType = this.chartType;
          }
        }
      }
    }
  }

  onSelect(e) {
    this.selectItem.emit(e);
  }

  isAdvancedChart() {
    if (this.chartType == 'barVertical') {
      return false;
    } else if (this.chartType == 'barHorizontal') {
      return false;
    } else if (this.chartType == 'barVerticalStacked') {
      return true;
    } else if (this.chartType == 'barHorizontalStacked') {
      return true;
    } else if (this.chartType == 'barVerticalGrouped') {
      return true;
    } else if (this.chartType == 'barHorizontalGrouped') {
      return true;
    } else if (this.chartType == 'barVerticalNormalized') {
      return true;
    } else if (this.chartType == 'pie') {
      return false;
    } else if (this.chartType == 'pieZero') {
      return false;
    } else if (this.chartType == 'pieAdvanced') {
      return false;
    } else if (this.chartType == 'pieGrid') {
      return false;
    } else if (this.chartType == 'area') {
      return true;
    } else if (this.chartType == 'line') {
      return true;
    }
  }
}
