<div class="full-width" *ngIf="authenticationMethod && authenticationMethod.hasAccount == true">
  <h3 class="sr-title rm-m sm-h3">{{ title }}</h3>
  <hr class="mb"/>
  <p class="small">This {{ "KEYWORD.patient" | translate }} is registered for access using the following methods:</p>
  <div class="row clearfix flex">
    <span
      class="label connect-date-create"
      matTooltip="{{ 'Created ' + (authenticationMethod.createdDate | TimeLabelPast) + ' ago' }}"
    >
      <mat-icon class="fas fa-clock"> </mat-icon>
      Joined Date {{ authenticationMethod.createdDate | customDate }}
    </span>

    <span
      class="label connect-fb"
      *ngIf="authenticationMethod && authenticationMethod.hasFacebookID == true"
      matTooltip="{{ authenticationMethod.facebookLabel || '' }}{{
        authenticationMethod.facebookLabel && authenticationMethod.facebookDate ? ' - ' : ''
      }}{{
        authenticationMethod.facebookDate ? 'Added ' + (authenticationMethod.facebookDate | TimeLabelPast) + ' ago' : ''
      }}"
    >
      <mat-icon class="fab fa-facebook-f"> </mat-icon>
      Facebook {{ authenticationMethod.facebookDate ? ' ' + (authenticationMethod.facebookDate | customDate) : '' }}
    </span>

    <span
      class="label connect-goog"
      *ngIf="authenticationMethod && authenticationMethod.hasGoogleID == true"
      matTooltip="{{ authenticationMethod.googleLabel || '' }}{{
        authenticationMethod.googleLabel && authenticationMethod.googleDate ? ' - ' : ''
      }}{{
        authenticationMethod.googleDate ? 'Added ' + (authenticationMethod.googleDate | TimeLabelPast) + ' ago' : ''
      }}"
    >
      <mat-icon class="fab fa-google"> </mat-icon>

      Google {{ authenticationMethod.googleDate ? ' ' + (authenticationMethod.googleDate | customDate) : '' }}
    </span>
    <span
      class="label connect-pw"
      *ngIf="authenticationMethod && authenticationMethod.hasUsername == true"
      matTooltip="Username  and Password"
    >
      <mat-icon class="fas fa-unlock-alt"> </mat-icon>

      Username/Password
    </span>
  </div>
</div>
