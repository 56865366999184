<div class="rel product-lookup">
  <div class="card-header primary-gradient-img clearfix inModal">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <!-- <mat-icon class="fas fa-box-open pull-left"></mat-icon> -->
        <i class="pull-left">
          <svg
            class="pull-left"
            id="treatLookIcon"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 153.34 136.08"
          >
            <defs>
              <style>
                .cls-1 {
                  fill: none;
                }
              </style>
            </defs>
            <title>treatment-lookup</title>
            <circle class="cls-1" cx="259.58" cy="109.88" r="6.38" transform="translate(-210.22 122.86) rotate(-45)"/>
            <path
              d="M283.57,186.33a2.43,2.43,0,0,1-.6.1H268.08v14.88a2.12,2.12,0,0,1-2.12,2.12H253.2a2.13,2.13,0,0,1-2.13-2.12V186.43H236.19a2.14,2.14,0,0,1-2.13-2.13V171.54a2.13,2.13,0,0,1,2.13-2.12h14.88V154.53a2.13,2.13,0,0,1,2.13-2.12H258a46.33,46.33,0,0,1,.18-17h-22a2.14,2.14,0,0,1-2.13-2.13V129a2.14,2.14,0,0,1,2.13-2.13h24.56a47,47,0,0,1,15.56-20,17,17,0,0,0-33.74,3H221.31a12.76,12.76,0,0,0-12.76,12.76v93.55A12.76,12.76,0,0,0,221.31,229h76.54a12.77,12.77,0,0,0,12.76-12.76V190.56A48.73,48.73,0,0,1,304,191,46.37,46.37,0,0,1,283.57,186.33Zm-24-82.83a6.38,6.38,0,1,1-6.38,6.38A6.37,6.37,0,0,1,259.58,103.5Z"
              transform="translate(-208.55 -92.87)"
            />
            <path
              d="M360.56,189.12l-19-19a4.57,4.57,0,0,0-3.24-1.34h-3.11a39.64,39.64,0,0,0-50.73-58.91,39.85,39.85,0,0,0-16.09,17,39.32,39.32,0,0,0-3,8.51,39.72,39.72,0,0,0-.23,17,39.15,39.15,0,0,0,2.94,8.83,39.83,39.83,0,0,0,17,18,39.66,39.66,0,0,0,43.27-3.61v3.11a4.6,4.6,0,0,0,1.33,3.24l19,19a4.6,4.6,0,0,0,3.24,1.34,4.52,4.52,0,0,0,3.22-1.34l5.4-5.4A4.59,4.59,0,0,0,360.56,189.12Zm-49.95-21.25a24.39,24.39,0,1,1,17.75-23.47A24.26,24.26,0,0,1,310.61,167.87Z"
              transform="translate(-208.55 -92.87)"
            />
            <path
              d="M316.39,139.58h-7.74v-7.75a1.11,1.11,0,0,0-1.11-1.1H300.9a1.11,1.11,0,0,0-1.1,1.1v7.75h-7.74a1.11,1.11,0,0,0-1.11,1.1v6.64a1.11,1.11,0,0,0,1.11,1.1h7.74v7.75a1.11,1.11,0,0,0,1.1,1.1h6.64a1.11,1.11,0,0,0,1.11-1.1v-7.75h7.74a1.1,1.1,0,0,0,1.1-1.1v-6.64A1.1,1.1,0,0,0,316.39,139.58Z"
              transform="translate(-208.55 -92.87)"
            />
          </svg>
        </i>

        <h2 class="summary-header rm-m">
          Hold the bus - we have found {{ products.length }} possible matches
          <span class="subLabel">
            We have found the follow products based on your search criteria. Scroll to find the product or service or
            refine further by typing in the search bar below
          </span>
        </h2>
      </div>
    </div>
  </div>
  <div class="row clearfix mt" *ngIf="products.length > 3">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Refine by - service category, item code, supplier, brand or label</mat-label>
      <input [(ngModel)]="filterData" matInput name="search" placeholder=""/>
    </mat-form-field>
  </div>

  <mat-dialog-content class="indOvf" [class.hasInput]="products.length > 3">
    <div class="patientContainer">
      <div
        *ngFor="
          let product of products
            | FilterArrayMultipleOrContain
              : ['Category.Label', 'Category.Colour', 'Type.Label', 'Brand.Label', 'Label']
              : filterData
        "
      >
        <button
          class="pull-right view-product"
          *ngIf="product && product['Label'] && !product.isMerged"
          (click)="viewProduct(product['ID'])"
          color="accent"
          mat-button
          matTooltip="View Product"
        >
          <mat-icon>visibility</mat-icon>
        </button>

        <div
          class="productRecord clearfix"
          *ngIf="product && product['Label']"
          [ngClass]="{ activeExistingPatient: selectedProduct == product }"
          [ngStyle]="{
            'pointer-events': product.isMerged ? 'none' : 'auto',
            cursor: product.isMerged ? 'default' : 'pointer',
            'border-top': '4px solid ' + product['Category.Colour']
          }"
          (click)="selectProduct(product)"
        >
          <div class="block icon" *ngIf="product['ID']">
            <app-product-view-catalogue
              [productID]="product['ID']"
              [LastModified]="product['LastModified']"
              (clickImage)="clickImageEvent($event)"
            >
            </app-product-view-catalogue>
            <h3 class="rm-m text-center" [ngStyle]="{ color: product['Category.Colour'] }">
              {{ !product.isMerged ? product['Item_Code.Effective'] : '&#160;' }}
            </h3>
          </div>

          <div class="row clearfix full-width contents">
            <h2 class="main-heading">
              {{ treatmentUtil.getLabel(product.name) }}
            </h2>

            <p class="sub-heading" *ngIf="product['Category.Label']">
              {{ treatmentUtil.getLabel(product['Category.Label']) }}
            </p>

            <h3
              class="type-heading"
              *ngIf="product['Type.Label'] && !product.isMerged"
              [ngStyle]="{ color: product['Category.Colour'] }"
            >
              {{ treatmentUtil.getLabel(product['Type.Label']) }}
            </h3>
            <h3 class="type-heading" *ngIf="product.isMerged" [ngStyle]="{ color: product['Category.Colour'] }">
              <span class="btn-show-all" (click)="$event.stopPropagation(); expandProduct(product)">
                Used in various Item codes - (view all
                <strong *ngIf="product.mergedLength">{{ product.mergedLength }}</strong
                >)
              </span>
            </h3>
          </div>

          <div class="backGround" [ngStyle]="{ background: product['Category.Colour'] }"></div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
