<div class="row clearfix" *ngIf="existingPatients && existingPatients.length > 0">
  <div class="row clearfix searchArea primary-gradient-img">
    <div class="row clearfix full-width flex" id="search">
      <mat-form-field class="thrd-width clearfix" appearance="outline">
        <mat-label>Search a {{ "KEYWORD.patient" | translate }} by name</mat-label>
        <input class="clearfix" [(ngModel)]="filter" name="filter" placeholder="Filter" matInput/>

        <button
          class="white position"
          *ngIf="filter"
          (click)="filter = undefined; selectedItem = ''; $event.stopPropagation()"
          mat-button
          matSuffix
          mat-icon-button

        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <div class="full-width">
        <!-- circle radius adjust -->

        <button class="pull-left white radius-toggle" (click)="showRadius = !showRadius" mat-stroked-button>
          <ng-container *ngIf="!showRadius">
            <mat-icon>my_location</mat-icon>
            Show range circle
          </ng-container>
          <ng-container *ngIf="showRadius">
            <mat-icon>my_location</mat-icon>
            Hide range circle
          </ng-container>
        </button>

        <div class="thrd-width radius-slider" *ngIf="showRadius" @simpleFadeAnimation>
          <mat-slider
            class="full-width"
            [(ngModel)]="radius"
            [displayWith]="formatLabel"
            thumbLabel
            tickInterval="1000"
            step="1000"
            min="1000"
            max="20000"
          ></mat-slider>

          <span class="left-label"> 1km </span>
          <span class="center-label"> Displaying {{ radius / 1000 }} km </span>
          <span class="right-label"> 20km </span>
        </div>

        <!-- map interface -->

        <button class="pull-right white map-toggle" (click)="resetMap()" mat-stroked-button>
          <mat-icon class="fas fa-history"></mat-icon> Reset
        </button>

        <button class="pull-right white map-toggle" (click)="myLocation()" mat-stroked-button>
          <mat-icon class="fas fa-map-marker-alt"></mat-icon> My Location
        </button>

        <button class="pull-right white map-toggle" (click)="practiceLocation()" mat-stroked-button>
          <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
          {{ "KEYWORD.practice" | translate | titlecase}} Location
        </button>

        <button class="pull-right white map-toggle" (click)="hasMapSearch = !hasMapSearch" mat-stroked-button>
          <mat-icon class="fas fa-map-marker"></mat-icon> Search Address
        </button>
      </div>
    </div>
  </div>
</div>

<div class="row clearfix flex min-height white-bg">
  <div class="thrd-width flex flex-col flex-1" *ngIf="filter">
    <div class="row clearfix full-height flex flex-col flex-1 rel" *ngIf="selectedItem" @simpleFadeAnimation>
      <button class="close-arrow white" (click)="closeSelectedItem()" mat-button matTooltip="Close">
        <mat-icon>keyboard_backspace</mat-icon> Back to list
      </button>

      <app-customer-prospect-view [patientID]="selectedItem['ID']"></app-customer-prospect-view>
    </div>

    <div
      class="row clearfix full-height flex flex-col flex-1"
      *ngIf="existingPatients && existingPatients.length > 0 && !selectedItem"
      @simpleFadeAnimation
    >
      <mat-card class="card listCardView listCardSide flex flex-col flex-1" [class.push]="!selectedItem">
        <div class="row clearfix flex flex-col flex-1">
          <mat-list class="patientList" [class.shrink]="selectedItem">
            <mat-list-item
              *ngFor="
                let b of existingPatients
                  | FilterArrayMultipleContainValue: ['CalculatedName']:filter
                  | paginate: { itemsPerPage: 10, currentPage: p };
                let i = index
              "
              [class.highlightRow]="selectedItem && selectedItem.ID && selectedItem.ID == b['ID']"
              (click)="selectItem(b, i, p)"
            >
              <app-customer-prospect-view-picture
                class="patientIcon text-center"
                *ngIf="b"
                [patientID]="b['ID']"
                [LastModified]="row['LastModified']"
              >
              </app-customer-prospect-view-picture>

              <p>{{ b['CalculatedName'] }}</p>
            </mat-list-item>
          </mat-list>
        </div>

        <div class="text-center">
          <mat-divider></mat-divider>
          <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
        </div>
      </mat-card>
    </div>
  </div>

  <div class="full-width flex">
    <app-map-multiple-view
      class="full-width clearfix row"
      [displayHeaderBar]="false"
      [streetNameAttribute]="'addresses.Street Name'"
      [streetNumberAttribute]="'addresses.Street Nr'"
      [unitNumberAttribute]="'addresses.Unit'"
      [stateAttribute]="'addresses.State'"
      [suburbAttribute]="'addresses.Suburb'"
      [streetTypeAttribute]="'addresses.Street Type'"
      [countryAttribute]="'addresses.Country.Code'"
      [postCodeAttribute]="'addresses.Postcode'"
      [markerTitleAttribute]="'CalculatedName'"
      [markerDescriptionAttribute]="'addresses.Calculated'"
      [addMarker]="addMarkerEvent"
      [title]="title"
      [resetPositions]="resetPositionsEvent"
      [description]="description"
      [selectedItem]="selectedItemEvent"
      [isLightMode]="true"
      [showRadius]="showRadius"
      [showCustomAction]="false"
      [allowRadius]="true"
      [radius]="radius"
      [displayActions]="false"
      [hasMapSearch]="hasMapSearch"
      (addCoordinate)="addCoordinateEvent($event)"
      (getSelected)="getSelected($event)"
    >
    </app-map-multiple-view>
  </div>
</div>

<div class="full-width clearfix row" *ngIf="(!existingPatients || existingPatients.length <= 0) && isLoaded == true">
  <app-empty-list-message></app-empty-list-message>
</div>
