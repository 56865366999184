/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./something-wrong.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/common";
import * as i9 from "./something-wrong.component";
var styles_SomethingWrongComponent = [i0.styles];
var RenderType_SomethingWrongComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SomethingWrongComponent, data: {} });
export { RenderType_SomethingWrongComponent as RenderType_SomethingWrongComponent };
function View_SomethingWrongComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["(", ") "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.countdown / 1000); _ck(_v, 1, 0, currVal_0); }); }
export function View_SomethingWrongComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "container error-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 18, "mat-card", [["class", "error text-center mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(2, 49152, null, 0, i3.MatCard, [[2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 0, "img", [["class", "character_error"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, 0, 0, "div", [["class", "circle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "h1", [["class", "accent-color"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Oops!"])), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Something went wrong."])), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please try again soon."])), (_l()(), i1.ɵeld(11, 0, null, 0, 8, "div", [["class", "drop-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["class", ""], ["color", "warn"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.contactUs() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(13, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Contact us"])), (_l()(), i1.ɵeld(15, 0, null, null, 4, "button", [["class", "btn-return"], ["color", "accent"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goHome() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(16, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Go Home "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SomethingWrongComponent_1)), i1.ɵdid(19, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = "warn"; _ck(_v, 13, 0, currVal_3); var currVal_6 = "accent"; _ck(_v, 16, 0, currVal_6); var currVal_7 = _co.countdown; _ck(_v, 19, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 13).disabled || null); var currVal_2 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_1, currVal_2); var currVal_4 = (i1.ɵnov(_v, 16).disabled || null); var currVal_5 = (i1.ɵnov(_v, 16)._animationMode === "NoopAnimations"); _ck(_v, 15, 0, currVal_4, currVal_5); }); }
export function View_SomethingWrongComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-something-wrong", [], null, null, null, View_SomethingWrongComponent_0, RenderType_SomethingWrongComponent)), i1.ɵdid(1, 245760, null, 0, i9.SomethingWrongComponent, [i8.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SomethingWrongComponentNgFactory = i1.ɵccf("app-something-wrong", i9.SomethingWrongComponent, View_SomethingWrongComponent_Host_0, {}, {}, []);
export { SomethingWrongComponentNgFactory as SomethingWrongComponentNgFactory };
