<div class="rel clearfix" *ngIf="resent != true && marketing">
  <div class="card-header primary-gradient-img clearfix inModal">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" (click)="close()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <mat-icon class="pull-left">send</mat-icon>
        <h2 class="summary-header rm-m">
          Resend Campaign
          <span class="subLabel">Select a new time to send this campaign again</span>
        </h2>
      </div>
    </div>
  </div>

  <mat-dialog-content>
    <div *ngIf="isPromoterOrAdmin == true && marketing['Status.Code'] != 'PEND'">
      <div class="row clearfix">
        <mat-select
          class="margin-btm half-width"
          [(ngModel)]="merchantID"
          name="MerchantName"
          placeholder="Please Select a Merchant"
        >
          <mat-option [value]="'none'">
            Please Select Merchant
          </mat-option>
          <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m['ID']">
            <span
              class="list-label pull-right"
              *ngIf="m['Status'] && m['Status'] == 'Active'"
              [style.background]="'#00d07a'"
            >
              {{ m['Status'] }}
            </span>

            <span
              class="list-label pull-right"
              *ngIf="m['Status'] && m['Status'] != 'Active'"
              [style.background]="'#ff5722'"
            >
              {{ m['Status'] }}
            </span>
            {{ m['TradingAs'] || m['CalculatedName'] }}
          </mat-option>
        </mat-select>
        <button
          class="pull-right merchBtn"
          *ngIf="merchantID && merchantID != 'none'"
          (click)="viewMerchant()"
          mat-raised-button
          color="primary"
        >
          <mat-icon>person</mat-icon>
          <span class="mobHide">View Merchant</span>
        </button>
      </div>
      <hr class="mb"/>
    </div>

    <form #resendForm="ngForm" name="form">
      <div class="row clearfix">
        <div class="full-width clearfix flex">
          <div class="mb full-width">
            <label style="display: inline; padding-right: 1rem">Email</label>
            <mat-checkbox [(ngModel)]="sendEmail" name="sendByEmail"></mat-checkbox>
          </div>

          <div class="mb full-width">
            <label style="display: inline; padding-right: 1rem">SMS</label>
            <mat-checkbox [(ngModel)]="sendSms" name="sendBySMS"></mat-checkbox>
          </div>
        </div>

        <hr/>

        <div class="full-width clearfix">
          <app-date-input
            class="thin"
            [defaultDate]="minDateDefault"
            [isTime]="true"
            [label]="'Send Date:'"
            [minDate]="minDate"
            (selectedDate)="selectDate($event)"
          ></app-date-input>
        </div>
      </div>
    </form>

    <hr/>
    <div class="full-width text-center">
      <button class="" (click)="submit()" mat-raised-button color="accent">Submit</button>
    </div>
  </mat-dialog-content>
</div>

<!-- <div class="full-width row clearFix rel" *ngIf="resent==true && newMarketing && openView==true">

  <app-marketing-campaign-view [campaignID]="newMarketing['ID']"></app-marketing-campaign-view>

</div> -->
