<div class="rel">
  <form #resetForm="ngForm">
    <div class="card-header primary-gradient-img clearfix confirmModal">
      <button class="btn-close btn-clear mat-button smt white custom-close" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="full-width flex main-title-container">
        <div class="main-icon-container text-center">
          <mat-icon class="main-icon accent-color" *ngIf="isFontAwsome(icon) != true"> {{ icon || 'info' }}</mat-icon>

          <mat-icon class="main-icon accent-color {{ icon || 'fas fa-info' }}" *ngIf="isFontAwsome(icon) == true">
          </mat-icon>
        </div>
        <div class="row full-width title-container clearfix">
          <div class="title-container-sub">
            <h2 class="text-left title-header rm-m" *ngIf="!merchantLinkID">Insert a Link</h2>
            <h2 class="text-left title-header rm-m" *ngIf="merchantLinkID">Edit Link</h2>
          </div>
        </div>
      </div>
    </div>

    <mat-dialog-content>
      <div class="full-width flex smt">
        <div class="full-width ">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Link</mat-label>
            <textarea class="messageArea" [(ngModel)]="link" name="Link" matInput placeholder="Insert a valid link"
              required></textarea>
          </mat-form-field>

          <div class="full-width flex">
            <div class="margin-btm full-width smt mb" *ngIf="displayAddTemplate == true">
              <mat-checkbox [(ngModel)]="createTemplate" color="accent" name="createTemplate">
                Save this Link for future use.
              </mat-checkbox>
            </div>

            <div class="margin-btm full-width smt mb"
              *ngIf="createTemplate == true && (sessionType == 'admin' || sessionType == 'promoter')">
              <mat-checkbox [(ngModel)]="isDefault" color="accent" name="isDefault">
                Save this link as {{ "BRANDING.Brand Name" | translate | titlecase }} Link
              </mat-checkbox>
            </div>
          </div>

          <mat-form-field class="full-width" *ngIf="createTemplate == true" appearance="outline">
            <mat-label>Label</mat-label>
            <input [(ngModel)]="label" matInput placeholder="Label for your own record" name="label" />
          </mat-form-field>

          <div class="full-width" *ngIf="createTemplate == true">
            <app-tag-list class="full-width smt smb" [tags]="tags" [selectedTags]="defaultSelectedTags" [add]="addTag"
              [tagFormatIsObject]="false" [title]="'Attach tags to this link'" [canRemove]="true" [canCreate]="true"
              [directRemove]="true" [directRemoveDefault]="true" [hasFilter]="true" [canSelect]="false"
              (getCreate)="createNewTag()" (getSelectedTags)="getSelectedTags($event)">
            </app-tag-list>
          </div>
        </div>
        <div class="qtr-width ">

          <app-generate-qr class="full-width " style="    margin-top: -10px;  " [downloadLabel]="label"
            *ngIf="merchantLinkID && getRedirectURL(merchantLinkID)" [source]="getRedirectURL(merchantLinkID)"
            [size]="150" [download]="downloadQRCode">
          </app-generate-qr>

        </div>
      </div>

    </mat-dialog-content>
    <hr />
    <div class="drop-button text-center mt full-width">
      <button class="pull-right btn" *ngIf="createTemplate == true" [disabled]="!resetForm.form.valid" (click)="send()"
        mat-raised-button color="accent">
        Submit
      </button>

      <button class="pull-right btn" *ngIf="createTemplate != true" [disabled]="!resetForm.form.valid" (click)="send()"
        mat-raised-button color="accent">
        Submit
      </button>



      <button class="pull-right btn mr" [disabled]="!link" (click)="preview()" mat-raised-button color="primary">
        Preview
      </button>

      <button class="pull-right btn mr" *ngIf="merchantLinkID && getRedirectURL(merchantLinkID)" (click)="download()"
        mat-raised-button color="primary">
        Download QR Code
      </button>
      <button class="btn" *ngIf="displayLookup == true" (click)="lookupEvent()" mat-raised-button color="accent">
        Links Directory
      </button>

      <button class="pull-left btn" (click)="closeEvent()" mat-raised-button color="primary">Close</button>
    </div>
  </form>
</div>
