<div class="rel">
  <form #resetForm="ngForm">
    <div class="card-header primary-gradient-img clearfix confirmModal">
      <button class="btn-close btn-clear mat-button smt white custom-close" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="full-width flex main-title-container">
        <div class="row  full-width  flex r clearfix">

          <div style="width: 100px ">
            <mat-icon class="fas fa-user-plus pull-left mr main-icon-brush " *ngIf="!customer"
              style=" font-size: 2.8rem; margin-top: 10px; width: 70px;">
            </mat-icon>

            <app-customer-prospect-view-picture class="col-pic-modal-cus" *ngIf="customer && customer.ID"
              [patientID]="customer['ID']" >
            </app-customer-prospect-view-picture>


          </div>

          <div class="title-container-sub  full-width " *ngIf="customerID && isMinView!=true">
            <h2 class=" text-left title-header rm-m">
              Modify {{'KEYWORD.patient' | translate | titlecase}}

              <span class="subLabel">Enter the details of your {{'KEYWORD.patient' | translate | titlecase}}.</span>
            </h2>
          </div>
          <div class="title-container-sub  full-width " *ngIf="customerID && customer && customer.ID && isMinView==true">
            <h2 class=" text-left title-header rm-m">
              <strong>{{customer.CalculatedName}}</strong>

              <span class="subLabel">Enter the details of your {{'KEYWORD.patient' | translate | titlecase}}.</span>
            </h2>
          </div>

          <div class="title-container-sub  full-width " *ngIf="!customerID">
            <h2 class=" text-left title-header rm-m">
              New {{'KEYWORD.patient' | translate | titlecase}}

              <span class="subLabel">Enter the details of your {{'KEYWORD.Patient' | translate}}, and if we find any match, you
                can use that
                profile or create a new one.</span>
            </h2>
          </div>
        </div>
      </div>
    </div>

    <mat-dialog-content>

      <div class="full-width smt " *ngIf="isLoaded==true">

        <mat-form-field class="  full-width " *ngIf="isPromoterOrAdmin==true && !customerID" appearance="outline">
          <mat-label>Practice</mat-label>


          <mat-select [(ngModel)]="merchantID" (change)="getTags()" name="linkFontSize"
                      [placeholder]="('KEYWORD.practice' | translate | titlecase)">
            <mat-option *ngFor="let m of merchants" [value]="m.ID">
              {{ m.TradingAs }}
            </mat-option>
          </mat-select>
        </mat-form-field>


        <div class="full-width flex" *ngIf="isMinView!=true">
          <mat-form-field class=" full-width  mr" appearance="outline">
            <mat-label>Gender</mat-label>
            <mat-select class="full-width mr" [(ngModel)]="gender" name="gender" placeholder="Gender" >
              <mat-option *ngFor="let gender of genders" [value]="gender.Code">
                {{ gender.Label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Title</mat-label>
            <mat-select class="full-width" [(ngModel)]="title" name="title" placeholder="Title">
              <mat-option *ngFor="let title of titles" [value]="title.Label">
                {{ title.Label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="full-width flex" *ngIf="isMinView!=true">
          <mat-form-field class=" full-width  mr" appearance="outline">
            <mat-label>First name</mat-label>
            <input [(ngModel)]="firstName" name="Label1" matInput placeholder="First name" required/>
          </mat-form-field>

          <mat-form-field class="full-width mr" appearance="outline">
            <mat-label>Middle name</mat-label>
            <input [(ngModel)]="middleName" name="Label2" matInput placeholder="Middle name"/>
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Last name</mat-label>
            <input [(ngModel)]="lastName" name="Label3" matInput placeholder="Last name" required/>
          </mat-form-field>
        </div>
        <h3 class="sr-title   text-left  full-width  smt smb  " *ngIf="isMinView!=true"> Contact</h3>
        <div class="full-width flex  " *ngIf="isMinView!=true">

          <app-phone-input [clear]="false" class="full-width mr" [phoneNumber]="mobile" [label]="'Mobile'"
            [required]="isMobileRequired" (validate)="validateMobile($event)" (getPhone)="getMobile($event)">
          </app-phone-input>
          <app-phone-input
            class="full-width mr"
            [clear]="false"
            [phoneNumber]="homePhone"
            [isLandLine]="true"
            [label]="'Home Phone'"
            [required]="false"
            (validate)="validateHomePhone($event)"
            (getPhone)="getHomePhone($event)"
          >
          </app-phone-input>
          <app-phone-input
            class="full-width"
            [clear]="false"
            [phoneNumber]="workPhone"
            [isLandLine]="true"
            [label]="'Work Phone'"
            [required]="false"
            (validate)="validateWorkPhone($event)"
            (getPhone)="getWorkPhone($event)"
          >
          </app-phone-input>
        </div>

        <div class="full-width flex " *ngIf="isMinView!=true">
          <app-email-input [clear]="false" class="full-width mr" [email]="email" [label]="'Email'"
            [required]="false" (validate)="validateEmail($event)" (getEmail)="getEmail($event)">
          </app-email-input>

          <app-date-input
            class="half-width"
            [clear]="false"
            [isClear]="true"
            [startView]="'multi-year'"
            [isTime]="false"
            [defaultDate]="null"
            [maxDate]="'toDay'"
            (selectedDate)="getDateOfBirth($event)"
            style="width: calc(50% - 113px) !important"
            label="Date of birth"
          >
          </app-date-input>
        </div>



        <h3 class="sr-title   text-left  full-width  smt smb  "> Marketing</h3>


        <mat-checkbox class="full-width smb " name="marketingConsent" [(ngModel)]="marketingConsent">Marketing Consent -
          SMS</mat-checkbox>
        <mat-checkbox class="full-width smb " name="marketingConsentEmail" [(ngModel)]="marketingConsentEmail">
          Marketing Consent - Email</mat-checkbox>
        <mat-checkbox class="full-width mb " *ngIf="isMinView!=true" name="isReffered" [(ngModel)]="isReffered">Tick
          this if the
          {{'KEYWORD.patient' | translate | titlecase}} has
          been referred to
          your {{ "KEYWORD.Practice" | translate}} by another {{ "KEYWORD.practitioner" | translate | titlecase }}.
        </mat-checkbox>




        <!-- <mat-checkbox class="full-width mb " name="isReffered" [(ngModel)]="isMarketing"> Tick this if the patient was referred.
        </mat-checkbox> -->

        <h3 class="sr-title   text-left  full-width  smt smb  " *ngIf="loadTag==true"> Tags</h3>


        <div class="full-width " *ngIf="loadTag==true && customerID">
          <app-tag-list class="full-width mb" [tags]="tags" [selectedTags]="defaultSelectedTags" [add]="addTag"
            [tagFormatIsObject]="false" [title]="'Attach tags to this record'" [canRemove]="true" [canCreate]="true"
            [directRemove]='false' [directRemoveDefault]='true' [hasFilter]="true" [canSelect]="false"
            (getCreate)="createNewTag()" (getSelectedTags)="getSelectedTags($event)"
            (getRemovedTag)="getRemovedTag($event)">
          </app-tag-list>
        </div>
        <div class="full-width " *ngIf="loadTag==true && !customerID">
          <app-tag-list class="full-width mb" [tags]="tags" [selectedTags]="defaultSelectedTags" [add]="addTag"
            [tagFormatIsObject]="false" [title]="'Attach tags to this record'" [canCreate]="true" [directRemove]='true'
            [directRemoveDefault]='true' [hasFilter]="true" [canSelect]="false" (getCreate)="createNewTag()"
            (getSelectedTags)="getSelectedTags($event)">
          </app-tag-list>

        </div>
        <h3 class="sr-title   text-left  full-width  smt smb  " *ngIf="isMinView!=true"> Address</h3>

        <div class="row clearfix flex  full-width" *ngIf="isMinView!=true">

          <app-address-input class="full-width" [displayMap]="false" [canClose]="false" [noIcons]="true"
            (getAdress)="getAddress($event)" (validate)="isAddressValidEvent($event)" [postCode]="postCode"
            [state]="state" [suburb]="suburb" [streetNumber]="streetNumber" [streetName]="streetName"
            [unitNumber]="unitNumber" [streetType]="streetType" [latitude]="latitude" [longitude]="longitude"
            [isRequired]="false">
          </app-address-input>

        </div>
      </div>
    </mat-dialog-content>

    <hr>
    <div class="drop-button text-center mt  full-width">



      <button mat-raised-button (click)="save()" *ngIf="isMinView==true" class="btn-large  pull-right"
        color="accent">Submit</button>


      <button mat-raised-button (click)="save()" *ngIf="isMinView!=true"
        [disabled]="!resetForm.form.valid || isMobileValid!=true  || isAddressValid!=true || isEmailValid!=true || isWorkPhoneValid!=true || isHomePhoneValid!=true  "
        class="btn-large  pull-right" color="accent">Submit</button>

      <button mat-raised-button (click)="closeEvent()" class="btn-large  pull-left" color="primary">close</button>
    </div>
  </form>
</div>
