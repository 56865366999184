import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-beta-info',
  templateUrl: './beta-info.component.html',
  styleUrls: ['./beta-info.component.css'],
})
export class BetaInfoComponent implements OnInit {
  showBeta = false;
  code;
  getModule = new EventEmitter();
  close = new EventEmitter();

  settings = Settings.global;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data && data.code) {
      this.code = data.code;
    }
  }

  ngOnInit() {
    if (localStorage.getItem('showBetaModule' + this.code) === 'true') {
      this.showBeta = true;
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
  doNotShow() {
    localStorage.setItem('showBetaModule' + this.code, JSON.stringify(this.showBeta));

    this.close.emit(this.showBeta);
  }

  doNotShow2() {
    localStorage.setItem('showBetaModule' + this.code, JSON.stringify(this.showBeta));

    this.getModule.emit(this.showBeta);
  }
}
