/// <reference types="@types/googlemaps" />

import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
// import { google } from "@agm/core/services/google-maps-types";
// import { google } from "@type/googlemaps";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UtilsService } from '../../services/utils.service';
import { Settings } from '../../types/settings';

@Component({
  selector: 'app-map-create',
  templateUrl: './map-create.component.html',
  styleUrls: ['./map-create.component.css'],
})
export class MapCreateComponent implements OnInit {
  minSearchLength = 3;
  address = null;
  addressLine$ = new Subject<any>();
  addresses = [];
  isGoogleMap = true;
  searchControl = '';
  addressByGoogle = null;
  addressLine = {
    AddressLine: '',
  };
  @ViewChild('search', { static: false }) public searchElementRef: ElementRef;

  constructor(private utilsService: UtilsService, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) {}

  ngOnInit() {
    if (Settings && Settings.global['mapValidator'] == 'kleber') {
      this.isGoogleMap = false;
    } else {
      this.isGoogleMap = true;
    }
    this.addressLine$.pipe(debounceTime(800), distinctUntilChanged()).subscribe((term: string) => {
      if (term.length >= this.minSearchLength) {
        // const payload = {
        //   addressLine: term,
        //   resultLimit: '100'
        // };
        this.utilsService.getAddressess(term).subscribe((res) => {
          this.addresses = res;
        });
      }
    });

    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address'],
      });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          // set latitude, longitude and zoom
          const position = {
            Latitude: place.geometry.location.lat().toString(),
            Longitude: place.geometry.location.lng().toString(),
          };
          this.addressLine.AddressLine = place.formatted_address;
          this.addressByGoogle = position;
        });
      });
    });
  }

  onSearchAddress(value) {
    this.addressLine$.next(value);
  }

  onAddressSelected(address) {
    this.utilsService.getAddressDetails(address.RecordId).subscribe((res) => {
      this.address = res;
    });
  }
}
