<div class="rel text-center">
  <mat-icon class="large-modal-icon">help</mat-icon>
  <h3 class="sr-title sm-h3 no-pad">Forgotten your password?</h3>

  <p class="lead rm-mt rm-mb">It happens.</p>
  <p class="">We can help you quickly recover your password using the following form.</p>

  <div class="clearfix">
    <!-- Email Address -->
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Username</mat-label>
      <input
        class="md-block"
        [(ngModel)]="username"
        (keyup.enter)="submit()"
        matInput
        placeholder="Please enter username"
      />
    </mat-form-field>
  </div>
  <br/>

  <div class="drop-buttons">
    <button class="btn-large" [disabled]="!username" (click)="submit()" mat-raised-button color="accent">Submit</button>
  </div>
</div>
