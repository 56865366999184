<div class="row clearfix actionLog" *ngIf="actionLog">
  <div class="card-header primary-gradient-img clearfix inModal">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix full-width">
        <mat-icon
          class="pull-left fa mr"
          [ngClass]="actionLogUtil.getSubjectIcon(actionLog['Action'], actionLog['Subject'])"
        ></mat-icon>
        <h2 class="summary-header rm-m white" *ngIf="!actionLog['Subject']">
          Activity note
          <span class="subLabel"
          >{{ actionLog['Who.Name'] }} - created on {{ actionLog['DateTimeCreated'] | customDate }}</span
          >
        </h2>

        <h2 class="summary-header rm-m white" *ngIf="actionLog['Subject']">
          {{ actionLog['Subject'] }}
          <span class="subLabel"
          >{{ actionLog['Who.Name'] }} - created on {{ actionLog['DateTimeCreated'] | customDate }}</span
          >
        </h2>
      </div>
    </div>
  </div>

  <div class="row clearfix">
    <div class="row clearfix flex times grey-row">
      <div class="full-width">
        <label class="small">Started Time</label>
        <p class="rm-m">{{ actionLog['Start.Time'] | customTime }}</p>
      </div>

      <div class="full-width">
        <label class="small">Finished Time</label>
        <p class="rm-m">{{ actionLog['Stop.Time'] | customTime }}</p>
      </div>

      <div class="full-width">
        <label class="small">Duration</label>
        <p class="rm-m">{{ calculateDuration(actionLog['Duration_in_Minutes']) }}</p>
      </div>
    </div>

    <div class="row clearfix mt flex">
      <div class="full-width">
        <div class="col-left">
          <label class="small">{{"KEYWORD.Practice" | translate}}</label>
          <p class="rm-mt">{{ actionLog['MerchantName'] }}</p>
        </div>
      </div>

      <div class="full-width">
        <div class="col-left">
          <label class="small">Staff Member</label>
          <p class="rm-mt">{{ actionLog['Operator_Name'] }}</p>
        </div>
      </div>

      <div class="full-width">
        <div class="col-left">
          <label class="small">{{"KEYWORD.patient" | translate | titlecase}}</label>
          <p class="rm-mt">{{ actionLog['Who.Name'] }}</p>
        </div>
      </div>
    </div>

    <hr/>

    <div class="row clearfix mt flex">
      <div class="full-width">
        <div class="col-left">
          <label class="small">Type</label>
          <p class="rm-mt">{{ actionLog['Action'] }}</p>
        </div>
      </div>

      <div class="full-width">
        <div class="col-left">
          <label class="small">Subject</label>
          <p class="rm-mt">{{ actionLog['Subject'] }}</p>
        </div>
      </div>

      <div class="full-width">
        <div class="col-left">
          <label class="small">Result</label>
          <p class="rm-mt">{{ actionLog['Result'] }}</p>
        </div>
      </div>
    </div>

    <div class="full-width" *ngIf="actionLog['Description']">
      <hr/>
      <label class="small">Description</label>
      <p class="rm-mt" [innerHTML]="actionLog['Description'] | safeHtml"></p>
    </div>

    <div
      class="full-width mt flex"
      *ngIf="actionLog['Dates.Recontact'] && actionLog['Dates.Recontact'] != '0000-00-00'"
    >
      <div class="full-width">
        <label class="small">Action Recontact Date</label>
        <p class="rm-mt larger">{{ actionLog['Dates.Recontact'] | customDate }}</p>
      </div>
      <div class="full-width">
        <label class="small">Action Recontact Status</label>
        <p class="rm-mt larger" *ngIf="actionLog['RecontactDone'] == 0">
          <span class="badge recontactDone">Suspended</span>
        </p>
        <p class="rm-mt larger" *ngIf="actionLog['RecontactDone'] != 0"><span class="badge">Fired</span></p>
      </div>
    </div>
  </div>
</div>
