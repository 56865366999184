import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

class RevenueReportPageState {
  printTable: 'actual' | 'accrued';
}

@Injectable()
export class FundersRevenueReportService extends ComponentStore<RevenueReportPageState> {
  constructor() {
    super({ printTable: null });
  }

  getPrintTableEvents$() {
    return this.select((state: RevenueReportPageState) => state.printTable);
  }

  setPrintTable(printTable: 'actual' | 'accrued') {
    this.patchState({ printTable });
  }
}
