import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { BankAccountCreateEditComponent } from '../../bank-account/bank-account-create-edit/bank-account-create-edit.component';
import { BankAccountListUserComponent } from '../../bank-account/bank-account-user/BankAccountListUserComponent';
import { BankAccountService } from '../../bank-account/shared/bank-account.service';
import { ContractDetailAddPaymentComponent } from '../contract-detail-add-payment/contract-detail-add-payment.component';
import { ContractService } from '../shared/contract.service';

@Component({
  selector: 'app-contract-detail-payments',
  templateUrl: './contract-detail-payments.component.html',
  styleUrls: ['./contract-detail-payments.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: '*' }))),
    ]),
  ],
})
export class ContractDetailPaymentsComponent implements OnInit {
  @Input()
  contractID;

  @Input()
  contractObject;

  @Input()
  setContractTab = 'repayment';

  contract;
  bankDetails;
  isConsolidated = false;
  isOutstandingOnly = false;
  isPending = true;
  totalObject;
  isPromoterOrAdmin = false;

  constructor(
    private activeRouter: ActivatedRoute,
    private contractService: ContractService,
    private bankAccountService: BankAccountService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.activeRouter.params.subscribe((params) => {
      if (params['contractID']) {
        this.contractID = params['contractID'];
      }
      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        if (this.contractObject && typeof this.contractObject == 'object') {
          this.contract = this.contractObject;
          this.setup();
        } else if (this.contractID) {
          this.contractService.fetchContractDetails$(this.contractID, null, this.isPromoterOrAdmin).subscribe((res) => {
            if (res) {
              this.contract = res;
              this.setup();
            }
          });
        }
      });
    });
  }

  setup() {
    this.bankAccountService
      .getBankAccountDetails(this.contract['DefaultPaymentMethod_key'], {}, this.isPromoterOrAdmin)
      .subscribe((res) => {
        this.bankDetails = res;
      });
  }

  showBankList() {
    const ref = RootAppComponent.dialog.open(BankAccountListUserComponent, {
      data: {
        userID: this.contract['Customer_key'],
      },
      width: '850px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  createPaymentMethod() {
    const ref = RootAppComponent.dialog.open(BankAccountCreateEditComponent, {
      data: {
        userID: this.contract['Customer_key'],
      },
      width: '1100px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  getTotal(e) {
    if (e) {
      this.totalObject = e;
    } else {
      this.totalObject = null;
    }
  }

  clickOutstanding() {
    this.isPending = false;
    this.isConsolidated = false;
  }

  clickConsolidated() {
    this.isOutstandingOnly = false;
    this.isPending = false;
  }

  clickPending() {
    this.isConsolidated = false;
    this.isOutstandingOnly = false;
  }

  modifyDefaultPaymentMethod() {
    const ref = RootAppComponent.dialog.open(BankAccountListUserComponent, {
      data: {
        userID: this.contract['Customer_key'],
        isActive: true,
      },
      width: '850px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  openAddPaymentDialog() {
    const ref = RootAppComponent.dialog.open(ContractDetailAddPaymentComponent, {
      width: '800px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }
}
