<div class="clearfix settleWell" *ngIf="settlement" [class.cancelled]="settlement['Status.Code'] == 'WDRN'"
  [class.declined]="settlement['Status.Code'] == 'DECL'" [class.requested]="settlement['Status.Code'] == 'REQU'"
  [class.approved]="settlement['Status.Code'] == 'APPR'" [class.pending]="settlement['Status.Code'] == 'PEND'"
  [class.awaiting]="settlement['Status.Code'] == 'AWTR'">
  <mat-accordion>
    <mat-expansion-panel class="mat-elevation-z0" [expanded]="isExpanded">
      <mat-expansion-panel-header (click)="clickHeader()">

        <div class="row full-width flex mr">


          <div class="full-width flex-center">



            <div class="status mr">
              <mat-icon class="fas fa-money-bill-wave"
                *ngIf="settlement['Status.Code'] != 'APPR' && settlement['Status.Code'] != 'DECL'"></mat-icon>
              <mat-icon class="fas fa-check" *ngIf="settlement['Status.Code'] == 'APPR'"></mat-icon>
              <mat-icon class="fas fa-times" *ngIf="settlement['Status.Code'] == 'DECL'"></mat-icon>
            </div>
            <h4 class="sm-h4 full-width">
              <strong>{{ settlement['Settlement.Amount'] | customCurrency }}</strong>
              requested on {{ settlement['Settlement.Date'] | customDate }}
            </h4>

            <span *ngIf="settlement['Is_Materials_Request']==='1'" class="chip pull-right"
              style="  width: 120px;  text-align: center;  margin-right: 20px;     min-width: 120px;">Material
              Advanced</span>

          </div>
        </div>
      </mat-expansion-panel-header>

      <div class="row clearfix flex flex-center">
        <div class="status-row row clearfix grey flex full-width">
          <div class="row full-width pointer" (click)="viewSettlement()">
            <div class="row clearfix flex">
              <p class="small rm-mt full-width">
                Invoice Reference: <strong>{{ settlement['Invoice.Reference'] || '...' }}</strong>
              </p>
              <p class="small rm-mt full-width">
                Request status <span class="chip"> {{ settlement['Status.Label'] || '...' }}</span>
              </p>
              <p class="small rm-mt full-width">
                Payment status
                <span class="chip" *ngFor="let pp of splitStatus(settlement['PaymentStatus.Label'])"
                  [class.chipPaymentPaid]="pp == 'Paid'" [class.chipPaymentDeclined]="pp == 'Blocked'"
                  [class.chipPaymentPending]="pp == 'Pending'"
                  [class.chipPaymentRequested]="pp == 'Suspended'">{{ pp || '...' }}</span>
              </p>
            </div>

            <div class="row clearfix flex" *ngIf="settlement['SettleTo.Card_key']">
              <p class="small rm-mt full-width" *ngIf="settlement['SettleTo.Name']">
                Settled To:
                <strong>{{ settlement['SettleTo.Name'] || '...' }}</strong>
              </p>
              <p class="small rm-mt full-width" *ngIf="settlement['SettleTo.Account.AccountNumber']">Bank Account</p>
              <p class="small rm-mt full-width" *ngIf="settlement['SettleTo.Account.CardNumber']">Credit Card</p>
              <p class="small rm-mt full-width" *ngIf="settlement['SettleTo.Account.AccountNumber']">
                Account Number:
                <strong>{{ settlement['SettleTo.Account.AccountNumber'] || '...' }}</strong>
              </p>
              <p class="small rm-mt full-width" *ngIf="settlement['SettleTo.Account.CardNumber']">
                Card Number:
                <strong>{{ settlement['SettleTo.Account.CardNumber'] || '...' }}</strong>
              </p>
            </div>

            <div class="row clearfix flex"
              *ngIf="settlement['Insurance.Contribution'] && settlement['Insurance.Contribution'] !== '0'">
              <p class="small rm-mt full-width" *ngIf="settlement['Insurance.Contribution']">
                Insurance Contribution:
                <strong>{{ settlement['Insurance.Contribution'] | customCurrency }}</strong>
              </p>
              <p class="small rm-mt full-width" *ngIf="settlement['Insurance.Provider']">
                Insurance Provider:
                <strong>{{ settlement['Insurance.Provider'] || '...' }}</strong>
              </p>
              <p class="small rm-mt full-width" *ngIf="settlement['Insurance.Reference']">
                Insurance Reference:
                <strong>{{ settlement['Insurance.Reference'] || '...' }}</strong>
              </p>
            </div>

            <div class="row clearfix flex"
              *ngIf="(settlement['Requested.By'] || settlement['Approved.By_Key']) && isPromoterOrAdmin == true">
              <p class="small rm-mt full-width" *ngIf="settlement['Requested.By']">
                Requested By:
                <strong>{{ settleUtils.getName(settlement['Requested.By_Name']) }}</strong>
              </p>
              <p class="small rm-mt full-width" *ngIf="settlement['Approved.By_Key']">
                Approved By:
                <strong>{{ settleUtils.getName(settlement['Approved.By_Name']) }}</strong>
              </p>
            </div>
          </div>

          <div class="control-action-btn">
            <button class="pull-right" *ngIf="settlement['DrawDownGroupID'] && showGroup == true"
              (click)="viewSettlementGroup()" mat-icon-button color="primary">
              <mat-icon>group_work</mat-icon>
            </button>

            <button class="pull-right actionMenuToggle" [matMenuTriggerFor]="menu" mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
              <button (click)="viewSettlement()" mat-menu-item>
                <mat-icon>search</mat-icon>
                <span>View settlement </span>
              </button>

              <button *ngIf="settlement['DrawDownGroupID'] && showGroup == true" (click)="viewSettlementGroup()"
                mat-menu-item color="warn">
                <mat-icon>group_work</mat-icon>
                <span>View Settlement Group</span>
              </button>

              <!-- <button mat-menu-item *ngIf="settlement['SettleTo.Card_key']"
              (click)="viewBeneficiary(settlement['SettleTo.Card_key'])">
              <mat-icon>search</mat-icon>
              <span>View Third Parties </span>
            </button>

            <button mat-menu-item *ngIf="isPromoterOrAdmin == true  && settlement['SettleTo.Account_key']"
              (click)="viewBankAccount(settlement['SettleTo.Account_key'])">
              <mat-icon>search</mat-icon>
              <span>View Third Party Bank Account</span>
            </button> -->

              <button *ngIf="settlement['Status.Code'] == 'PEND'" (click)="requestSettlement()" mat-menu-item
                color="warn">
                <mat-icon>description</mat-icon>
                <span>Request Settlement</span>
              </button>

              <button *ngIf="settlement['Status.Code'] == 'PEND' || settlement['Status.Code'] == 'REQU'"
                (click)="withdrawSettlement()" mat-menu-item color="warn">
                <mat-icon>block</mat-icon>
                <span>Withdraw settlement </span>
              </button>

              <button *ngIf="
                  isPromoterOrAdmin == true &&
                  settlement['Status.Code'] == 'REQU' &&
                  settlement['DrawDownGroupID'] != '' &&
                  showGroup == true
                " (click)="approveSettlement()" mat-menu-item color="primary">
                <mat-icon>thumb_up_alt</mat-icon>
                <span>Approve settlement </span>
              </button>

              <button *ngIf="
                  isPromoterOrAdmin == true &&
                  settlement['Status.Code'] == 'REQU' &&
                  settlement['DrawDownGroupID'] != '' &&
                  showGroup == true
                " (click)="declineSettlement()" mat-menu-item color="accent">
                <mat-icon>thumb_down_alt</mat-icon>
                <span>Decline settlement </span>
              </button>

              <button *ngIf="false && (settlement['Status.Code'] == 'REQU' || settlement['Status.Code'] == 'PEND')"
                (click)="voidSettlement()" mat-menu-item>
                <mat-icon>delete</mat-icon>
                <span>Archive settlement </span>
              </button>

              <button
                *ngIf="settlement['Status.Code'] != 'PEND' && settlement['DrawDownGroupID'] != '' && showGroup == true"
                (click)="viewDocument()" mat-menu-item>
                <mat-icon>pageview</mat-icon>
                <span>View document</span>
              </button>

              <button *ngIf="isPromoterOrAdmin == true && settlement['ID']" (click)="viewSettlementLogs()"
                mat-menu-item>
                <mat-icon class="fa fa-list-alt"></mat-icon>
                <span>View Settlement logs</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
