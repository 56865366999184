import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import hexRgb from 'hex-rgb';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { UtilsService } from '../../../shared/services/utils.service';
import { ColorPalette } from '../../../shared/types/color-palette';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { SubscriptionPlanService } from '../shared/subscription-plan.service';

@Component({
  selector: 'app-subscription-plan-view',
  templateUrl: './subscription-plan-view.component.html',
  styleUrls: ['./subscription-plan-view.component.css'],
})
export class SubscriptionPlanViewComponent implements OnInit {
  @Output()
  close = new EventEmitter();
  root = '/merchant';
  @Input()
  subscriptionPlanID;
  @Input()
  ids;

  subscriptionPlan;

  displayHeaderPlan = false;
  isImageError = false;
  postCover;
  postCoverURL;

  postPictureThumbnail;
  isActive = true;
  marketingDescriptions = [];

  loading = true;

  utils = new UtilsClass();
  selectedModuleCodes = [];
  selectedSubFeatureIDs = [];
  selectedSubFeatureLimitedIDs = [];

  color = Settings.global['primaryColor'] || '#1e88e5';
  @Input()
  colorSecond;
  isConsumer = null;
  isMerchant = null;
  sessionType;
  isPublic = true;
  scrollAdded = false;
  isClosed = false;
  displayNavigation = false;
  webDescription;
  featureType = 'all';
  isCurrentPlan = false;

  constructor(
    private subscriptionPlanService: SubscriptionPlanService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private domSanitizer: DomSanitizer,
    private utilService: UtilsService,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    $(window).resize(() => {
      const width = $(window).width();
      if (width > 0 && width <= 1024) {
        this.displayHeaderPlan = true;
      } else {
        this.displayHeaderPlan = false;
      }
    });

    this.activeRoute.params.subscribe((params) => {
      if (params['subscriptionPlanID']) {
        this.subscriptionPlanID = params['subscriptionPlanID'];
      }
      if (params['ids']) {
        this.ids = params['ids'].split(',');
      }
      if (params['featureType']) {
        this.featureType = params['featureType'];
      }

      this.authenticationService.getSessionType().subscribe((st) => {
        this.sessionType = st;

        if (this.subscriptionPlanID === 'current') {
          this.featureType = 'merchant';
          this.isCurrentPlan = true;

          this.authenticationService.getCurrentPlanForce(false, false).subscribe((res) => {
            if (res) {
              this.subscriptionPlanID = res;
              this.setup();
            }
          });
        } else {
          this.setup();
        }
      });
    });
  }

  scroll() {
    const header = $('#subscription-plan-view-card-fixed');
    let sticky = null;
    if (header && header[0]) {
      sticky = header[0].offsetTop;
      sticky = Number(sticky) + 500;
    }

    if (this.scrollAdded == false && sticky !== null && header) {
      $('.mat-sidenav-content').unbind('scroll');
      $('.mat-sidenav-content').on('scroll', (e) => {
        if (sticky && header && e && e.target) {
          const pageOffset = e.target.scrollTop || 0;

          const headerHidden = $('#view-sub-plan-footer');
          let stickyHidden = null;
          if (headerHidden && headerHidden[0]) {
            stickyHidden = Number(headerHidden[0].offsetTop) - 400;
          }

          if (stickyHidden && pageOffset > sticky && pageOffset > stickyHidden) {
            $('#subscription-plan-view-card-fixed').removeClass('subscription-plan-view-card-fixed-sticky');
            $('#subscription-plan-view-card-fixed').addClass('subscription-plan-view-card-fixed-sticky-hidden');
          } else if (pageOffset > sticky) {
            $('#subscription-plan-view-card-fixed').removeClass('subscription-plan-view-card-fixed-sticky-hidden');
            $('#subscription-plan-view-card-fixed').addClass('subscription-plan-view-card-fixed-sticky');
            this.isClosed = true;
            this.displayNavigation = true;
          } else {
            $('#subscription-plan-view-card-fixed').removeClass('subscription-plan-view-card-fixed-sticky-hidden');
            $('#subscription-plan-view-card-fixed').removeClass('subscription-plan-view-card-fixed-sticky');
            this.isClosed = false;
            this.displayNavigation = false;
          }
        }
      });

      this.scrollAdded = true;
    }
  }

  setup() {
    if (this.featureType === 'consumer') {
      this.root = '/consumer';
    } else {
      this.root = '/merchant';
    }
    if (this.subscriptionPlanID) {
      if (this.featureType === 'consumer') {
        this.isConsumer = null;
        this.isMerchant = true;
      } else if (this.featureType === 'merchant') {
        this.isConsumer = null;
        this.isMerchant = true;
      } else {
        this.isConsumer = null;
        this.isMerchant = true;
      }

      this.subscriptionPlanService
        .getPromoterPlanFullDetails(
          this.subscriptionPlanID,
          {
            addCustomization: true,
            isConsumer: this.isConsumer,
            isMerchant: this.isMerchant,
          },
          this.sessionType
        )
        .subscribe((res) => {
          if (res) {
            this.subscriptionPlan = JSON.parse(JSON.stringify(res));

            this.scroll();
            this.buildItem();
          }
        });
    }
  }

  buildItem() {
    if (this.subscriptionPlan && this.subscriptionPlan.ID) {
      if (this.subscriptionPlan['Color']) {
        const split = this.subscriptionPlan['Color'].split('|');
        if (split[0]) {
          this.color = split[0];
        }
        if (split[1]) {
          this.colorSecond = split[1] || null;
        }
        if (this.color && !this.colorSecond) {
          this.onColorPickerClose(this.color);
        }
      } else if (this.color && !this.colorSecond) {
        this.onColorPickerClose(this.color);
      }
      this.postCover = this.subscriptionPlanService.getPromoterPlanCoverImageStreamLink(
        this.subscriptionPlan.ID,
        this.subscriptionPlan.LastModified
      );

      this.postCoverURL = this.domSanitizer.bypassSecurityTrustStyle('url(' + this.postCover + ')');

      this.postPictureThumbnail = this.subscriptionPlanService.getPromoterPlanThumbnailImageStreamLink(
        this.subscriptionPlan.ID,
        this.subscriptionPlan.LastModified
      );

      if (this.subscriptionPlan && this.subscriptionPlan.Marketing_Description) {
        this.marketingDescriptions = this.subscriptionPlan.Marketing_Description.split('||');
      }

      if (this.subscriptionPlan['Is_Public'] != '1') {
        this.isPublic = false;
      } else {
        this.isPublic = true;
      }

      if (this.subscriptionPlan['Web_Features']) {
        this.webDescription = this.subscriptionPlan['Web_Features'];
      }

      if (this.subscriptionPlan['modulesCodes'] && this.subscriptionPlan['modulesCodes'].length > 0) {
        this.selectedModuleCodes = JSON.parse(JSON.stringify(this.subscriptionPlan['modulesCodes']));
      }

      if (this.subscriptionPlan['subFeatureIDs'] && this.subscriptionPlan['subFeatureIDs'].length > 0) {
        this.selectedSubFeatureIDs = JSON.parse(JSON.stringify(this.subscriptionPlan['subFeatureIDs']));
      }
      if (this.subscriptionPlan['subFeatureLimitedIDs'] && this.subscriptionPlan['subFeatureLimitedIDs'].length > 0) {
        this.selectedSubFeatureLimitedIDs = JSON.parse(JSON.stringify(this.subscriptionPlan['subFeatureLimitedIDs']));
      }
    } else if (this.color && !this.colorSecond) {
      this.onColorPickerClose(this.color);
    }
  }

  buildShadowBox(color) {
    if (color) {
      const rbg = hexRgb(color);

      if (rbg) {
        return ` 0px 2px 1px -1px rgb(${rbg.red}  ${rbg.green} ${rbg.blue} / 20%), 0px 1px 1px 0px rgb(${rbg.red}  ${rbg.green} ${rbg.blue} / 17%), 0px 1px 3px 0px rgb(${rbg.red}  ${rbg.green} ${rbg.blue} / 14%)`;
      } else {
        return null;
      }
    }
  }

  buildShadowBox2(color) {
    if (color) {
      const rbg = hexRgb(color);

      if (rbg) {
        return `   0px 10px 19px -1px rgb(${rbg.red}  ${rbg.green} ${rbg.blue} / 28%), 0px 1px 6px 0px rgb(${rbg.red}  ${rbg.green} ${rbg.blue} / 13%), 0px 3px 3px 0px rgb(${rbg.red}  ${rbg.green} ${rbg.blue} / 12%)`;
      } else {
        return null;
      }
    }
  }

  onColorPickerClose(color: string): void {
    if (color) {
      this.color = color;

      const v = new ColorPalette(color);
      const palette = v.getPalette();

      if (palette && palette.length > 0) {
        const index = palette.findIndex((item) => {
          if (item && item.name == 800) {
            return true;
          } else {
            return false;
          }
        });

        if (palette[index] && palette[index]['hex']) {
          this.colorSecond = palette[index]['hex'];
        }
      }
    }
  }

  onLoad() {
    this.isImageError = false;
  }

  onError() {
    this.isImageError = true;
  }

  viewFeature() {
    const header = $('#overview-features');
    let sticky = null;
    if (header && header[0]) {
      sticky = header[0].offsetTop;
      sticky = Number(sticky);
    }

    if (sticky != null && header) {
      $('.mat-sidenav-content').animate(
        {
          scrollTop: sticky,
        },
        1000
      );
    }
  }

  viewModule() {
    const header = $('#overview-modules');
    let sticky = null;
    if (header && header[0]) {
      sticky = header[0].offsetTop;
      sticky = Number(sticky + 80);
    }

    if (sticky != null && header) {
      $('.mat-sidenav-content').animate(
        {
          scrollTop: sticky,
        },
        1000
      );
    }
  }

  remove(id) {
    if (id) {
      this.router.navigate([
        this.root,
        {
          outlets: {
            page: ['subscription-plan-list-card', id],
          },
        },
      ]);
    }
  }

  compare() {
    if (this.ids && this.ids.length > 1) {
      this.ids = _.uniq(this.ids);

      this.router.navigate([
        this.root,
        {
          outlets: {
            page: ['subscription-plan-overview', this.ids.join(',')],
          },
        },
      ]);
    } else {
      this.router.navigate([
        this.root,
        {
          outlets: {
            page: ['subscription-plan-overview-all'],
          },
        },
      ]);
    }
  }
}
