import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../shared/authentication.service';

@Component({
  selector: 'app-security-code-current-user',
  templateUrl: './security-code-current-user.component.html',
  styleUrls: ['./security-code-current-user.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class SecurityCodeCurrentUserComponent implements OnInit {
  password;

  @Output()
  isValid = new EventEmitter();

  @Output()
  getResult = new EventEmitter();

  @Output()
  close = new EventEmitter();

  codeSent = false;
  user;
  securityID;
  securityCode;
  securityRecord;

  isCurrentUser = true;

  sessionType = 'guest';
  authenticationType = 'customer';
  cardID;

  isModal = false;

  constructor(
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.customer) {
        this.authenticationType = 'customer';
        this.user = data.customer;
        this.isCurrentUser = false;
      } else if (data.customerID) {
        this.authenticationType = 'customer';
        this.cardID = data.customerID;
        this.isCurrentUser = false;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((res) => {
      this.sessionType = res;

      if (this.isCurrentUser == true) {
        this.authenticationService.getCurrentUser().subscribe((res) => {
          if (res && res.data) {
            this.user = res.data;
          }
        });
      } else if (this.cardID && this.authenticationType == 'customer') {
        this.authenticationService.getCustomerProspectDetails(this.cardID, {}, this.sessionType).subscribe((res) => {
          if (res) {
            this.user = res;
          }
        });
      }
    });
  }

  onConfirmSend(c) {
    if (c) {
      if (this.isCurrentUser == true) {
        this.authenticationService.requestCode().subscribe((res) => {
          if (res) {
            this.securityRecord = res;
            this.securityID = res['ID'];

            this.codeSent = true;
          }
        });
      } else if (this.authenticationType == 'customer' && this.user && this.user['ID']) {
        this.authenticationService.requestConsumerCode(this.user['ID']).subscribe((res) => {
          if (res) {
            this.securityRecord = res;
            this.securityID = res['ID'];

            this.codeSent = true;
          }
        });
      }
    }
  }

  sendCode(sendCode) {
    if (sendCode) {
      if (this.isCurrentUser == true) {
        this.authenticationService.requestCode().subscribe((res) => {
          if (res) {
            this.securityRecord = res;
            this.securityID = res['ID'];

            this.codeSent = true;
          }
        });
      } else if (this.authenticationType == 'customer' && this.user && this.user['ID']) {
        this.authenticationService.requestConsumerCode(this.user['ID']).subscribe((res) => {
          if (res) {
            this.securityRecord = res;
            this.securityID = res['ID'];

            this.codeSent = true;
          }
        });
      }
    }
  }

  getCode(code) {
    if (code) {
      this.securityCode = code;

      this.sendResultEvent();
    }
  }

  sendResultEvent() {
    if (this.securityCode && this.securityID) {
      const p = {
        securityCode: this.securityCode,
        securityID: this.securityID,
      };
      this.getResult.emit(p);
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
}
