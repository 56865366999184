<div class="row clearfix contract-card-container-parent" *ngIf="contract"
  [ngClass]="displayFunder == true ? 'four-holder' : 'three-holder'">
  <div class="row clearfix full-width flex contract-card-container pair-card mt" *ngIf="displayFunder==true">
    <mat-card class="clearfix full-width ">
      <div class="colour-stripe"></div>

      <h3 class="sr-title  rm-m">Funding Details</h3>
      <hr />

      <div class="row flex flex-col">
        <div class="row clearfix flex">
          <div class="block text-center">
            <mat-icon class="fas fa-user-tie"></mat-icon>
            <label class="">Account Manager</label>
            <h4 class="reduce-marg sr-title">{{ contract['AccountManagerName'] }}</h4>
          </div>
          <div class="block text-center">
            <mat-icon class="fas fa-university"></mat-icon>
            <label class="">Funder</label>
            <h4 class="reduce-marg sr-title">{{ contract['FunderName'] }}</h4>
          </div>
          <div class="block text-center">
            <mat-icon class="fas fa-piggy-bank"></mat-icon>
            <label class="">Product License</label>
            <h4 class="reduce-marg sr-title">{{ contract['ProductName'] }}</h4>
          </div>
        </div>
        <div class="row clearfix flex">
          <div class="block text-center">
            <mat-icon class="fas fa-money-check-alt"></mat-icon>
            <label class="">Product type</label>
            <h4 class="reduce-marg sr-title">{{ contract['ProductTypeLabel'] }}</h4>
          </div>
          <div class="block text-center">
            <mat-icon class="fas fa-file-contract"></mat-icon>
            <label class="">Contract Type</label>
            <h4 class="reduce-marg sr-title">{{ contract['ContractType'] }}</h4>
          </div>
          <div class="block text-center">
            <mat-icon class="fas fa-percentage"></mat-icon>
            <label class="">Promotion</label>
            <h4 class="reduce-marg sr-title" *ngIf="contract['Promotion.Name']">{{ contract['Promotion.Name'] }}</h4>
            <h4 class="reduce-marg sr-title" *ngIf="!contract['Promotion.Name']">N/A</h4>
          </div>
        </div>

        <div class="row clearfix flex">
          <div class="block text-center">
            <mat-icon class="fas fa-home"></mat-icon>
            <label class="">Capacity Check</label>
            <h4 class="reduce-marg sr-title" *ngIf="contract['CapacityCheck']">
              {{ contract['CapacityCheck'] }}

              <!-- show when pass -->
              <span class="chip chip-enable">
                <mat-icon>check</mat-icon>
              </span>
            </h4>
          </div>
          <div class="block text-center">
            <mat-icon class="fas fa-money-bill-wave"></mat-icon>
            <label class="">Credit Check</label>
            <h4 class="reduce-marg sr-title" *ngIf="contract['CreditCheck']">
              {{ contract['CreditCheck'] }}

              <!-- show when pass -->
              <span class="chip chip-enable">
                <mat-icon>check</mat-icon>
              </span>
            </h4>
          </div>
          <div class="block text-center">
            <mat-icon class="fas fa-id-card-alt"></mat-icon>
            <label class="">Identity Check</label>
            <h4 class="reduce-marg sr-title" *ngIf="contract['IdentityCheck']">
              {{ contract['IdentityCheck'] }}

              <!-- show when pass -->
              <span class="chip chip-enable">
                <mat-icon>check</mat-icon>
              </span>
            </h4>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
  <div class="row clearfix full-width flex contract-card-container  card2 mt">
    <mat-card class="clearfix full-width ">
      <div class="colour-stripe"></div>

      <h3 class="sr-title  rm-m">{{ "KEYWORD.patient" | translate | titlecase }}</h3>
      <hr />
      <div class="col-left">
        <div class="row clearfix">
          <div class="qtr-width">
            <p>&nbsp;</p>
            <p>Loan Amount</p>
            <p>Deposit</p>
            <p><strong>Financed</strong></p>
          </div>
          <div class="qtr-width text-right">
            <p><strong>Original</strong></p>
            <p>{{ contract['Amount.FinancedBeforeDeposit'] | customCurrency }}</p>
            <p>{{ contract['Deposit'] | customCurrency }}</p>
            <p>
              <strong>{{ contract['Amount.Financed'] | customCurrency }}</strong>
            </p>
          </div>
          <div class="qtr-width text-right">
            <p><strong>Added</strong></p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </div>
          <div class="qtr-width text-right">
            <p><strong>Current</strong></p>
            <p>{{ contract['Amount.FinancedBeforeDeposit'] | customCurrency }}</p>
            <p>{{ contract['Deposit'] | customCurrency }}</p>
            <p>
              <strong>{{ contract['Amount.Financed'] | customCurrency }}</strong>
            </p>
          </div>
        </div>
        <hr>
        <div class="row clearfix">
          <div class="qtr-width noWarp">
            <p>+ Interest</p>
            <p>+ Fees</p>
            <p>+ Commission</p>
            <p>- Refunds</p>
          </div>
          <div class="qtr-width noWarp text-right">
            <p>{{ contract['OriginalInterest'] | customCurrency }}</p>
            <p>{{ contract['OriginalFees'] | customCurrency }}</p>
            <p>{{ contract['CustomerCommission'] | customCurrency }}</p>
            <p><i>(for undrawn funds)</i></p>
          </div>
          <div class="qtr-width text-right">
            <p>{{ contract['AddedInterest'] | customCurrency }}</p>
            <p>{{ contract['AddedFees'] | customCurrency }}</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </div>
          <div class="qtr-width text-right">
            <p>{{ contract['CurrentInterest'] | customCurrency }}</p>
            <p>{{ contract['CurrentFees'] | customCurrency }}</p>
            <p>{{ contract['CustomerCommission'] | customCurrency }}</p>
            <p>{{ contract['RefundsForUndrawn'] | customCurrency }}</p>
          </div>
        </div>
        <hr>
        <div class="row clearfix">
          <div class="qtr-width noWarp">
            <p><strong>Total ex. Dep.</strong></p>
            <p><strong>Total inc. Dep.</strong></p>
          </div>
          <div class="qtr-width text-right">
            <p>
              <strong>{{ contract['Amount.Loan.Original'] | customCurrency }}</strong>
            </p>
            <p>
              <strong>{{ contract['Amount.Original'] | customCurrency }}</strong>
            </p>
          </div>
          <div class="qtr-width text-right">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </div>
          <div class="qtr-width text-right">
            <p>{{ contract['CurrentTotalExDeposit'] | customCurrency }}</p>
            <p>{{ contract['Amount.Loan.Current'] | customCurrency }}</p>
          </div>
        </div>
      </div>
    </mat-card>

  </div>


  <div class="row clearfix  full-width flex contract-card-container  pair-card  card3 mt"
    *ngIf="isContractView == false && estimatedObject">
    <!-- <div class="item"> -->
    <mat-card class="clearfix full-width">
      <div class="colour-stripe"></div>

      <h3 class="sr-title  rm-m">Repayments</h3>
      <hr />
      <div class="col-left">
        <div class="row clearfix">
          <div class="half-width">
            <p>{{ estimatedObject['NumberOfRepayments'] }}</p>
            <p>Regular</p>
            <p>Last</p>
          </div>
          <div class="half-width">
            <p>{{ selectedFrequency }}</p>
            <p>{{ estimatedObject['Repayments.Regular'] | customCurrency }}</p>
            <p>{{ estimatedObject['Repayments.Last'] | customCurrency }}</p>
          </div>
        </div>
        <div class="row clearfix">
          <p>
            Deposit of {{ contract['Deposit'] | customCurrency }} on acceptance, then after one
            {{ contract['RepaymentFrequency.Noun.Singular'] | lowercase }} {{ estimatedObject['NumberOfRepayments'] }}
            {{ contract['RepaymentFrequency.Adverb'] }} payments of
            {{ estimatedObject['Repayments.Regular'] | customCurrency }}, and one final payment of
            {{ estimatedObject['Repayments.Last'] | customCurrency }}, over {{ estimatedObject['NumberOfRepayments'] }}
            {{ contract['RepaymentFrequency.Noun.Plural'] }}.
          </p>
        </div>
      </div>
    </mat-card>
    <!-- </div> -->
    <!-- <div class="item"> -->

  </div>
  <div class="row clearfix full-width flex contract-card-container  pair-card card4  mt" *ngIf="isContractView == true">

    <mat-card class="clearfix full-width">
      <div class="colour-stripe"></div>

      <h3 class="sr-title  rm-m">Contract Balance</h3>
      <hr />
      <div class="row clearfix">
        <div class="qtr-width">
          <p>Paid Via DDR</p>
          <p>Paid Directly</p>
          <p>Waived</p>
          <p>Insurance</p>
          <p><strong>Credits</strong></p>
        </div>
        <div class="qtr-width text-right">
          <p>{{ contract['PaidViaDDRs'] | customCurrency }}</p>
          <p>{{ contract['PaidDirectly'] | customCurrency }}</p>
          <p>{{ contract['Amount.Waived'] | customCurrency }}</p>
          <p>{{ contract['Amounts.InsuranceContributions'] | customCurrency }}</p>
          <p>
            <strong>{{ contract['Amount.Received'] | customCurrency }}</strong>
          </p>
        </div>
        <div class="qtr-width text-right">
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p><strong>Status</strong></p>
          <p><strong>Updated</strong></p>
        </div>
        <div class="qtr-width text-right">
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>
      </div>
      <hr>
      <div class="row clearfix">
        <div class="qtr-width">
          <p [class.accent-color]="contract['Amount.Outstanding'] < 0"><strong>Due</strong></p>
          <p><strong>Contract Total</strong></p>
        </div>
        <div class="qtr-width text-right">
          <p [class.accent-color]="contract['Amount.Outstanding'] < 0">
            {{ contract['Amount.Outstanding'] | customCurrency }}
          </p>
          <p>{{ contract['Amount.Loan.Current'] | customCurrency }}</p>
        </div>
        <div class="qtr-width text-right">
          <p [class.accent-color]="contract['Arrears.Amount'] > 0"><strong>Arrears</strong></p>
          <p>Days</p>
        </div>
        <div class="qtr-width text-right">
          <p [class.accent-color]="contract['Arrears.Amount'] > 0">{{ contract['Arrears.Amount'] | customCurrency }}</p>
          <p>{{ contract['Arrears.days'] }}</p>
        </div>
      </div>
      <hr>
      <div class="row clearfix">
        <div class="qtr-width noWarp">
          <p>For Debt Collection</p>
          <p>Written Off</p>
        </div>
        <div class="qtr-width text-right">
          <p>{{ contract['Amount.DebtCollector'] | customCurrency }}</p>
          <p>{{ contract['Amount.WrittenOff'] | customCurrency }}</p>
        </div>
        <div class="qtr-width text-right">
          <p><strong>Overdue</strong></p>
          <p>Days</p>
        </div>
        <div class="qtr-width text-right">
          <p>{{ contract['Arrears.AmountOverdue'] | customCurrency }}</p>
          <p>{{ contract['Arrears.DaysOverdue'] }}</p>
        </div>
      </div>
    </mat-card>
    <!-- </div> -->
    <!-- <div class="item"> -->
  </div>
  <div class="row clearfix full-width flex contract-card-container  last-card mt">

    <mat-card class="clearfix full-width">
      <div class="colour-stripe"></div>

      <h3 class="sr-title  rm-m">{{ "KEYWORD.practitioner" | translate | titlecase }}</h3>
      <hr />
      <div class="col-left">
        <div class="row clearfix">
          <div class="half-width">
            <p>Drawn</p>
            <p>Not Yet Drawn</p>
            <p>Cancelled Amount</p>
            <p><strong>Total Settlements</strong></p>
          </div>
          <div class="half-width text-right">
            <p>{{ contract['Settlements.Drawn'] | customCurrency }}</p>
            <p>{{ contract['Settlements.NotYetDrawn'] | customCurrency }}</p>

            <p>{{ contract['Cancelled_Amount'] | customCurrency }}</p>
            <p>
              <strong>{{ contract['OriginalLoanAmount'] | customCurrency }}</strong>
            </p>
          </div>
        </div>
        <hr>
        <div class="row clearfix">
          <div class="half-width noWarp">
            <p> Recourse Release MSF</p>
            <p> {{'Merchant' | translate}} Service Fee</p>
            <p> {{'Merchant' | translate}} Membership Fee</p>
            <p> {{'Merchant' | translate}} Risk Fee</p>
            <p> Finance Contribution</p>
            <p> Customer Fees Absorbed</p>
            <p *ngIf="context!='breeze'"> Claimed from Insurance</p>
            <p> Refunds</p>
            <p> Borrower Risk Fee</p>
            <p> Hold Back Fee</p>
            <p> Cancelled Amount</p>
            <p><strong> Total {{'Merchant' | translate}} fees </strong></p>
          </div>
          <div class="half-width text-right">
            <p>{{ contract['Recourse_Release_MSF'] | customCurrency }}</p>
            <p>{{ contract['Merchant_Service_Fee'] | customCurrency }}</p>
            <p>{{ contract['Merchant_Membership_Fee'] | customCurrency }}</p>
            <p>{{ contract['Merchant_Risk_Fee'] | customCurrency }}</p>
            <p>{{ contract['Credit_Charges_Merchant'] | customCurrency }}</p>
            <p>{{ contract['CustomerFeesAbsorbed'] | customCurrency }}</p>
            <p *ngIf="context!='breeze'">{{ contract['Insurance_Contributions'] | customCurrency }}</p>
            <p>{{ contract['RefundsForUndrawn'] | customCurrency }}</p>
            <p>{{ contract['Borrower_Risk_Fee'] | customCurrency }}</p>
            <p>{{ contract['Merchant_HoldBack_Fee'] | customCurrency }}</p>
            <p>{{ contract['Cancelled_Amount'] | customCurrency }}</p>


            <p><strong>{{ getTotalFee() | customCurrency}}</strong></p>
          </div>
        </div>
        <hr>
        <div class="row clearfix">
          <div class="half-width">
            <p><strong>Net Settlements</strong></p>
            <p><strong>Net Settlements So Far</strong></p>
          </div>
          <div class="half-width text-right">
            <p>
              <strong>{{ contract['TotalNetSettlements'] | customCurrency }}</strong>
            </p>
            <p>
              <strong>{{ contract['CurrentNetSettlements'] | customCurrency }}</strong>
            </p>
          </div>
        </div>
      </div>
    </mat-card>
    <!-- </div> -->
  </div>
</div>
