import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MedicalHistoryService {
  params: HttpParams;

  constructor(private http: HttpClient) {}

  createMedicalHistoryAccess(payload, role): Observable<any> {
    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .post(environment.nodeUrl + '/medical-history-access', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .post(environment.nodeUrl + '/medical-history-access/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .post(environment.nodeUrl + '/medical-history-access/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/medical-history-access/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  createMedicalHistory(payload, role): Observable<any> {
    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .post(environment.nodeUrl + '/medical-history', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .post(environment.nodeUrl + '/medical-history/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .post(environment.nodeUrl + '/medical-history/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/medical-history/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getPublicInvitationDetails(id) {
    return this.http
      .get(`${environment.nodeUrl}/invitation/guest-mini/${id}`, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPublicMedicalInvitationDetails(id) {
    return this.http
      .get(`${environment.nodeUrl}/invitation-communication/guest-mini/${id}`, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  viewMedicalInvitation(id, role) {
    if (role == 'admin' || role == 'promoter') {
      return this.http
        .put(`${environment.nodeUrl}/invitation-communication/view/global/${id}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .put(`${environment.nodeUrl}/invitation-communication/view/${id}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .put(`${environment.nodeUrl}/invitation-communication/view/customer/${id}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(`${environment.nodeUrl}/invitation-communication/view/customer/${id}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  commenceMedicalHistory(id, role) {
    if (role == 'admin' || role == 'promoter') {
      return this.http
        .put(`${environment.nodeUrl}/medical-history/commence/global/${id}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .put(`${environment.nodeUrl}/medical-history/commence/${id}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .put(`${environment.nodeUrl}/medical-history/commence/customer/${id}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(`${environment.nodeUrl}/medical-history/commence/customer/${id}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  commenceMedicalInvitation(id, role) {
    if (role == 'admin' || role == 'promoter') {
      return this.http
        .put(`${environment.nodeUrl}/invitation-communication/commence/global/${id}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .put(`${environment.nodeUrl}/invitation-communication/commence/customer/${id}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(`${environment.nodeUrl}/invitation-communication/commence/customer/${id}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  completeMedicalInvitation(id, role) {
    if (role == 'admin' || role == 'promoter') {
      return this.http
        .put(`${environment.nodeUrl}/invitation-communication/complete/global/${id}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .put(`${environment.nodeUrl}/invitation-communication/complete/${id}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .put(`${environment.nodeUrl}/invitation-communication/complete/customer/${id}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(`${environment.nodeUrl}/invitation-communication/complete/customer/${id}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  completeMedicalHistory(id, role) {
    if (role == 'admin' || role == 'promoter') {
      return this.http
        .put(`${environment.nodeUrl}/medical-history/complete/global/${id}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .put(`${environment.nodeUrl}/medical-history/complete/${id}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .put(`${environment.nodeUrl}/medical-history/complete/customer/${id}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(`${environment.nodeUrl}/medical-history/complete/customer/${id}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMedicalHistoryInvitation(ID, payload, role): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/invitation-communication/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/invitation-communication/global/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/invitation-communication/customer/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/invitation-communication/customer/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMedicalHistoryInvitationByPatient(patientID, merchantID, payload, role): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/invitation-communication/medical-history/' + patientID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      if (patientID && merchantID) {
        return this.http
          .get(
            environment.nodeUrl + '/invitation-communication/medical-history/global/' + patientID + '/' + merchantID,
            options
          )
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.http
          .get(environment.nodeUrl + '/invitation-communication/medical-history/global/' + patientID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else if (role == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/invitation-communication/medical-history/customer/' + merchantID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/invitation-communication/medical-history/customer/' + merchantID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  modifyMedicalHistory(id, payload, role): Observable<any> {
    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .put(environment.nodeUrl + '/medical-history/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .put(environment.nodeUrl + '/medical-history/global/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .put(environment.nodeUrl + '/medical-history/customer/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/medical-history/customer/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMedicalHistoryDetails(ID, payload = {}, role): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/medical-history/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/medical-history/global/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/medical-history/customer/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/medical-history/customer/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMedicalHistoryList(payload, role): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/medical-history', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/medical-history/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/medical-history/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/medical-history/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMedicalHistoryAccessList(payload, role): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/medical-history-access', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/medical-history-access/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/medical-history-access/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/medical-history-access/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getIllnessList(payload, role): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/illness', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/illness/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/illness/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/illness/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  createIllness(payload, role): Observable<any> {
    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .post(environment.nodeUrl + '/illness', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .post(environment.nodeUrl + '/illness/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .post(environment.nodeUrl + '/illness/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/illness/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getIllnessObject(id, payload = {}, role): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/illness/' + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/illness/global/' + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/illness/customer/' + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/illness/customer/' + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  modifyIllness(id, payload, role): Observable<any> {
    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .put(environment.nodeUrl + '/illness/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .put(environment.nodeUrl + '/illness/global/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .put(environment.nodeUrl + '/illness/customer/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/illness/customer/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getAllergyCategoryList(payload = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/allergy-category', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createAllergy(payload, role): Observable<any> {
    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .post(environment.nodeUrl + '/allergy', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .post(environment.nodeUrl + '/allergy/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .post(environment.nodeUrl + '/allergy/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/allergy/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  modifyAllergy(id, payload, role): Observable<any> {
    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .put(environment.nodeUrl + '/allergy/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .put(environment.nodeUrl + '/allergy/global/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .put(environment.nodeUrl + '/allergy/customer/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/allergy/customer/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getAllergyList(payload = {}, role): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/allergy', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/allergy/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/allergy/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/allergy/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getAllergyObject(id, payload = {}, role): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/allergy/' + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/allergy/global/' + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/allergy/customer/' + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/allergy/customer/' + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  createConsumerContact(body): Observable<any> {
    return this.http
      .post(environment.nodeUrl + '/contact/customer', body)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getConsumerContactDetail(cardID, payload = {}, role): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/contact/customer/' + cardID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/contact/customer/global/' + cardID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/contact/customer/customer/' + cardID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/contact/customer/customer/' + cardID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  modifyGuardian(guardianID, body): Observable<any> {
    return this.http
      .put(environment.nodeUrl + '/contact/customer/' + guardianID, body)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  removeGuardian(guardianID): Observable<any> {
    return this.http
      .put(environment.nodeUrl + '/contact/remove-guardian/' + guardianID, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getMedicalHistoryAtDateTimeList(payload, role): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/medical-history-at-date-time', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/medical-history-at-date-time/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/medical-history-at-date-time/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/medical-history-at-date-time/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getArchivedMedicalHistoryList(payload, role): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/medical-history-archived', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/medical-history-archived/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/medical-history-archived/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/medical-history-archived/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getArchivedIllnessList(payload, role): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/illness-archived', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/illness-archived/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/illness-archived/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/illness-archived/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  createHealthFund(payload, role): Observable<any> {
    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .post(environment.nodeUrl + '/id-document', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .post(environment.nodeUrl + '/id-document/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .post(environment.nodeUrl + '/id-document/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/id-document/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getHealthFund(payload, role): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/id-document', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/id-document/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/id-document/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/id-document/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  createMedicare(payload, role): Observable<any> {
    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .post(environment.nodeUrl + '/id-document', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .post(environment.nodeUrl + '/id-document/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .post(environment.nodeUrl + '/id-document/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/id-document/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  modifyMedicare(ID, payload, role): Observable<any> {
    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .put(environment.nodeUrl + '/id-document/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .put(environment.nodeUrl + '/id-document/global/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .put(environment.nodeUrl + '/id-document/customer/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/id-document/customer/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMedicare(payload, role): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/id-document', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/id-document/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/id-document/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/id-document/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMedicalHistoryTimeMachine(ID, payload, role): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/medical-history-time-machine/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/medical-history-time-machine/global/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/medical-history-time-machine/customer/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/medical-history-time-machine/customer/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMedicalHistoryEventsClustered(ID, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/medical-history-event-clustered/global/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/medical-history-event-clustered/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  inviteMedicalHistory(payload, isAdminOrPromoter = false): Observable<any> {
    if (isAdminOrPromoter == true) {
      return this.http
        .post(environment.nodeUrl + '/customer-prospect/medical-history/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/customer-prospect/medical-history', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  inviteMedicalHistoryGroup(payload, isAdminOrPromoter = false): Observable<any> {
    if (isAdminOrPromoter == true) {
      return this.http
        .post(environment.nodeUrl + '/customer-prospect/medical-history/group/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/customer-prospect/medical-history/group', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMedicalHistoryFullDetails(ID, payload = {}, role): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/medical-history/full/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/medical-history/full/global/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/medical-history/full/customer/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/medical-history/full/customer/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMedicalHistoryPatientID(patientID, payload, role): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/medical-history/patient/' + patientID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/medical-history/patient/global/' + patientID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/medical-history/patient/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/medical-history/patient/public/' + patientID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  modifyStep(id, step, role): Observable<any> {
    const payload = {
      step: step || 1,
    };

    if (role == 'merchant' || role == 'merchant-admin') {
      return this.http
        .put(environment.nodeUrl + '/medical-history/step/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin' || role == 'promoter') {
      return this.http
        .put(environment.nodeUrl + '/medical-history/step/global/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.http
        .put(environment.nodeUrl + '/medical-history/step/customer/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/medical-history/step/customer/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
}
