<div class="row clearfix">
  <div class="card-header primary-gradient-img clearfix inModal" *ngIf="isModal == true">
    <div class="row clearfix">
      <button class="btn-close btn-clear pull-right mat-button" (click)="closeModalEvent()">
        <mat-icon>clear</mat-icon>
      </button>

      <div class="row titleArea clearfix">
        <mat-icon class="pull-left fas fa-comment-dollar"></mat-icon>
        <h2 class="summary-header rm-m">Create Credit Card</h2>
      </div>
    </div>
  </div>

  <div class="row clearfix full-width cont" *ngIf="disabled == false">
    <div class="row clearfix flex">
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Operator modification</h4>
          <hr/>
          <div class="row clearfix full-width">
            <mat-form-field class="full-width" [ngClass]="highlightField('Updating_Reason.Code')" appearance="outline">
              <mat-label>Reason</mat-label>
              <mat-select [(ngModel)]="pcDetail.updatedReason" placeholder="Reason" name="reason" required>
                <mat-option *ngFor="let reason of persCircumUpdReasons" [value]="reason.Code">
                  {{ reason.Label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Comments</h4>
          <hr/>
          <div class="row clearfix full-width">
            <mat-form-field
              class="full-width"
              [ngClass]="highlightField('Updating_Reason.Comment')"
              appearance="outline"
            >
              <mat-label>Leave a comment</mat-label>
              <textarea [(ngModel)]="pcDetail.updatedComment" matInput placeholder="Comment" name="comment"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Files</h4>
          <hr>
          <div class="row clearfix full-width">
            <app-file-uploader class=" text-center clearfix" [uploadDirectly]="true" [isDragable]="true" class="text-center" (onCompleteAll)="onCompleteAll($event)"
              [limit]="1"></app-file-uploader>


            <app-file-list class="compact" (remove)="removeFile($event)" [hasExtraInfo]="false" [canEdit]="true" [canAction]="false"
              [files]="[fileID]"></app-file-list>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <div class="row clearfix full-width cont">
    <div class="row clearfix flex">
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Loan Details</h4>
          <hr/>
          <div class="row clearfix full-width">
            <mat-form-field class="full-width" [ngClass]="highlightField('Info.TypeOfCard.Code')" appearance="outline">
              <mat-label>Card Type</mat-label>
              <mat-select
                [(ngModel)]="pcDetail.infoTypeOfCardCode"
                [disabled]="disabled"
                (selectionChange)="cardTypeChange()"
                placeholder="Card Type"
                name="cardType"
              >
                <mat-option *ngFor="let type of cardTypes" [value]="type.Code">
                  {{ type.Label }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <section *ngIf="pcDetail.infoTypeOfCardCode == 'CRE'">
              <mat-form-field class="full-width" [ngClass]="highlightField('Info.CardIssuer')" appearance="outline">
                <mat-label>Credit Card Type</mat-label>
                <mat-select
                  [(ngModel)]="pcDetail.infoCardIssuer"
                  [disabled]="disabled"
                  placeholder="Credit Card Type"
                  name="creditCardType"
                >
                  <mat-option *ngFor="let creditType of creditCardTypes" [value]="creditType.ID">
                    {{ creditType.Name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="full-width" [ngClass]="highlightField('Info.Bank.Code')" appearance="outline">
                <mat-label>Issuer</mat-label>

                <mat-select
                  [(ngModel)]="pcDetail.infoBankCode"
                  [disabled]="disabled"
                  placeholder="Issuer"
                  name="Issuer"
                >
                  <mat-option *ngFor="let bank of bankList" [value]="bank.ID">
                    {{ bank.Name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </section>

            <section *ngIf="pcDetail.infoTypeOfCardCode != 'CRE'">
              <mat-form-field class="full-width" [ngClass]="highlightField('Info.CardIssuer')" appearance="outline">
                <mat-label>Issuer</mat-label>

                <mat-select
                  [(ngModel)]="pcDetail.infoCardIssuer"
                  [disabled]="disabled"
                  placeholder="Issuer"
                  name="Issuer"
                >
                  <mat-option *ngFor="let issuer of issuerList" [value]="issuer.Label">
                    {{ issuer.Label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </section>

            <mat-form-field class="full-width" [ngClass]="highlightField('Info.FacilityLimit')" appearance="outline">
              <mat-label>Facility Limit</mat-label>
              <input
                [(ngModel)]="pcDetail.infoFacilityLimit"
                [disabled]="disabled"
                (ngModelChange)="facilityLimitChange($event)"
                matInput
                placeholder="Facility Limit"
                currencyMask
              />
            </mat-form-field>

            <mat-form-field class="full-width" [ngClass]="highlightField('Liability.Value')" appearance="outline">
              <mat-label>Amount Outstanding</mat-label>
              <input
                [(ngModel)]="pcDetail.liabilityValue"
                [disabled]="disabled"
                matInput
                placeholder="Amount Outstanding"
                currencyMask
              />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Declared Repayments</h4>
          <hr/>
          <div class="row clearfix full-width">
            <mat-form-field
              class="full-width"
              [ngClass]="highlightField('Info.DeclaredRepayments.Value')"
              appearance="outline"
            >
              <mat-label>Amount</mat-label>
              <input
                [(ngModel)]="pcDetail.infoDeclaredValue"
                [disabled]="disabled"
                matInput
                placeholder="Amount"
                currencyMask
              />
            </mat-form-field>

            <mat-form-field
              class="full-width"
              [ngClass]="highlightField('Info.DeclaredRepayments.Frequency')"
              appearance="outline"
            >
              <mat-label>Frequency</mat-label>

              <mat-select
                [(ngModel)]="pcDetail.infoDeclaredFrequency"
                [disabled]="disabled"
                placeholder="Frequency"
                name="Frequency"
              >
                <mat-option *ngFor="let fre of frequencies" [value]="fre.Code">
                  {{ fre.Label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="row clearfix flex" *ngIf="isModal == false">
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Deemed Repayments</h4>
          <hr/>
          <div class="row clearfix full-width">
            <mat-form-field
              class="full-width"
              [ngClass]="highlightField('Info.DemedRepayments.Percentage')"
              appearance="outline"
            >
              <mat-label>Percentage</mat-label>
              <input
                [(ngModel)]="pcDetail.infoDemandPercentage"
                [options]="{ prefix: '', suffix: ' %' }"
                [disabled]="disabled"
                (ngModelChange)="deemedPercentChange($event)"
                matInput
                placeholder="Percentage"
                currencyMask
              />
            </mat-form-field>

            <mat-form-field
              class="full-width"
              [ngClass]="highlightField('Info.DemedRepayments.Value')"
              appearance="outline"
            >
              <mat-label>Repayments</mat-label>
              <input
                [(ngModel)]="pcDetail.infoDemandValue"
                [disabled]="disabled"
                matInput
                placeholder="Repayments"
                currencyMask
              />
            </mat-form-field>

            <mat-form-field
              class="full-width"
              [ngClass]="highlightField('Info.DemedRepayments.Frequency')"
              appearance="outline"
            >
              <mat-label>Frequency</mat-label>

              <mat-select
                [(ngModel)]="pcDetail.infoDemandFrequency"
                [disabled]="disabled"
                placeholder="Frequency"
                name="Frequency"
              >
                <mat-option *ngFor="let fre of frequencies" [value]="fre.Code">
                  {{ fre.Label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Repayments Used For Assesment</h4>
          <hr/>
          <div class="row clearfix full-width">
            <mat-form-field
              class="full-width"
              [ngClass]="highlightField('Info.DemedRepayments.Value')"
              appearance="outline"
            >
              <mat-label>Repayments</mat-label>
              <input
                [(ngModel)]="pcDetail.infoDemandValue"
                [disabled]="disabled"
                matInput
                placeholder="Repayments"
                currencyMask
              />
            </mat-form-field>

            <div>
              <p>Based on: Facility Limit</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row clearfix mt" *ngIf="disabled == false">
    <button
      class="pull-right"
      *ngIf="isModal == false"
      [disabled]="!pcDetail.updatedReason"
      (click)="submit()"
      mat-raised-button
      color="accent"
    >
      Submit
    </button>
    <button
      class="pull-right"
      *ngIf="isModal == true"
      [disabled]="!pcDetail.updatedReason"
      (click)="create()"
      mat-raised-button
      color="accent"
    >
      Create
    </button>
  </div>
</div>
