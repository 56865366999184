import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { AppStateService } from '../../core/app-state/app-state.service';
import { ModuleOption } from '../types/settings';

@Directive({
  selector: '[ipvHasPublicModules]',
})
export class HasPublicModulesDirective implements OnInit, OnDestroy {
  @Input() ipvHasPublicModules: { [key in ModuleOption]?: boolean };

  isVisible = false;
  hasAnActiveModule = false;

  constructor(
    private appStateService: AppStateService,
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) {}

  ngOnInit() {
    if (Object.keys(this.ipvHasPublicModules).length > 0) {
      this.appStateService
        .getAppState$()
        .pipe(untilDestroyed(this))
        .subscribe((appState) => {
          if (appState.publicModules) {
            // how to loop through an object?

            Object.keys(this.ipvHasPublicModules).forEach((moduleName) => {
              if (appState.publicModules[moduleName] !== undefined) {
                if (appState.publicModules[moduleName] === this.ipvHasPublicModules[moduleName]) {
                  this.hasAnActiveModule = true;
                }
              }
            });

            if (this.hasAnActiveModule) {
              if (!this.isVisible) {
                this.isVisible = true;
                this.viewContainerRef.createEmbeddedView(this.templateRef);
              }
            } else {
              this.viewContainerRef.clear();
            }
          } else {
            this.viewContainerRef.clear();
          }
        });
    } else {
      this.viewContainerRef.clear();
      console.error(
        `ipvHasActiveModules is missing modules. Example: ipvHasActiveModules:"[{module1: true, module: false}]"`
      );
    }
  }

  ngOnDestroy() {
    /** required for untilDestroyed (Replace with @UntilDestroyed() Decorator in angular 9 and delete ngOnDestroy()) */
  }
}
