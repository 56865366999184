import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';

export class waivers {
  date: string;
  amount: number;
  type: string;
  notes: string;
  status: string;
}

const WAIVERS_DATA: waivers[] = [
  { date: '', amount: 0, type: '', notes: '', status: '' },
  { date: '', amount: 0, type: '', notes: '', status: '' },
  { date: '', amount: 0, type: '', notes: '', status: '' },
  { date: '', amount: 0, type: '', notes: '', status: '' },
  { date: '', amount: 0, type: '', notes: '', status: '' },
];

@Component({
  selector: 'app-contract-waivers',
  templateUrl: './contract-waivers.component.html',
  styleUrls: ['./contract-waivers.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: '*' }))),
    ]),
  ],
})
export class ContractWaiversComponent implements OnInit {
  @Input()
  status;

  @Input()
  contractID;

  isAdminOrPromoter = false;

  displayedColumns: string[] = ['date', 'amount', 'type', 'notes', 'status'];
  waiverSource = WAIVERS_DATA;

  constructor(private AuthenticationService: AuthenticationService, private activeRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['status']) {
        this.status = params['status'];
      }

      this.AuthenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;
      });
    });
  }
}
