import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { TemplateReviewComponent } from '../../../core/helper/template-review/template-review.component';
import { IframeViewComponent } from '../../../shared/components/iframe-view/iframe-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-marketing-campagin-lookup',
  templateUrl: './marketing-campagin-lookup.component.html',
  styleUrls: ['./marketing-campagin-lookup.component.css'],
})
export class MarketingCampaginLookupComponent implements OnInit {
  @Output()
  closeModal = new EventEmitter();
  @Output()
  create = new EventEmitter();

  @Output()
  getCampaign = new EventEmitter();

  @Output()
  getCampaignID = new EventEmitter();

  isCreate = true;

  title = 'Active Campaigns';
  description = 'Choose a campaign to link this social media to';
  orderByFilter;
  campaigns = [];
  filterData;
  isModal = false;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      if (data.campaigns && data.campaigns.length > 0) {
        this.campaigns = data.campaigns;
        this.isModal = true;
      }

      if (data.isCreate != null) {
        this.isCreate = data.isCreate;
      }
    }
  }

  ngOnInit() {}

  closeEvent() {
    this.closeModal.emit(true);
  }

  orderBy(d) {
    if (d) {
      this.orderByFilter = d;
    }
  }

  view(id) {
    if (id) {
      const link = `${Settings.global['publicSiteFrontendLink']}/mc/${id}`;

      const ref2 = RootAppComponent.dialog.open(IframeViewComponent, {
        data: {
          link,
        },
        width: '80%',
        height: '92vh',
        panelClass: 'noCard',
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });
    }
  }

  openEmailPreviewDialog(template) {
    if (template) {
      const ref = RootAppComponent.dialog.open(TemplateReviewComponent, {
        data: {
          templateTag: template,
          isPrint: false,
          isProceed: false,
          isDownload: false,
          proceedLabel: null,
        },
        width: '700px',
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
    }
  }

  createEvent() {
    this.create.emit(true);
  }

  SelectRecordClickEvent(t) {
    if (t && t.ID) {
      this.getCampaign.emit(t);
      this.getCampaignID.emit(t.ID);
    }
  }

  mergeTags(t) {
    let result = '';

    if (t['Tag.Label']) {
      result = t['Tag.Label'];
    }
    if (t['StaticList.Label']) {
      if (result) {
        result = result + '|' + t['StaticList.Label'];
      } else {
        result = t['StaticList.Label'];
      }
    }

    return result;
  }
}
