import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { Settings } from '../../../shared/types/settings';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { TreatmentIntroModalComponent } from '../treatment-intro-modal/treatment-intro-modal.component';

import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { HeaderService } from '../../../shared/services/header.service';

@Component({
  selector: 'app-brand-list-overview',
  templateUrl: './treatment-brand-list-overview.component.html',
  styleUrls: ['./treatment-brand-list-overview.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class TreatmentBrandListOverviewComponent implements OnInit {
  @Input()
  pageSize = Settings.global['listPageSize'] || 10;

  isAdminOrPromoter = false;

  merchants = [];

  merchantID = 'none';

  hideFilter = false;
  isActive = true;

  dateFrom;
  dateTo;
  selectedRange = 'all';

  hideMerchantSelect = false;
  isDisplayMerchant = true;

  constructor(private authenticationService: AuthenticationService) {
    const ref = RootAppComponent.dialog.open(TreatmentIntroModalComponent, {
      width: '700px',
    });
    if (ref.componentInstance.showOnTreatPageLogin == true) {
      ref.close();
    } else {
      const sub = ref.componentInstance.closeModal.subscribe((data) => {
        ref.close();
      });
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isAdminOrPromoter = res;

      if (this.isAdminOrPromoter == true) {
        HeaderService.getPromoterViewAsObject().subscribe((res) => {
          if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'promoter') {
            this.merchantID = 'none';
            this.isDisplayMerchant = true;
          } else if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'merchant') {
            this.merchantID = res['merchantID'];
            this.isDisplayMerchant = false;
          }
        });

        const _mID = JSON.parse(localStorage.getItem('selectedMerchant'));

        if (_mID) {
          this.merchantID = _mID;
          // this.isDisplayMerchant = false;
        }
      }
    });
  }

  viewMerchant() {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: this.merchantID,
      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  applySearch(result) {
    this.merchantID = result.merchantID;
    this.dateFrom = result.dateFrom;
    this.dateTo = result.dateTo;
  }
}
