import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { BeneficiaryViewComponent } from '../../beneficiary/beneficiary-view/beneficiary-view.component';
import { TreatmentService } from '../shared/treatment.service';
import { treatmentUtil } from '../shared/treatmentUtil';
import { TreatmentBrandViewComponent } from '../treatment-brand-view/treatment-brand-view.component';
import { TreatmentCategoryViewComponent } from '../treatment-category-view/treatment-category-view.component';
import { TreatmentTypeViewComponent } from '../treatment-type-view/treatment-type-view.component';

@Component({
  selector: 'app-brand-create-edit',
  templateUrl: './treatment-brand-create-edit.component.html',
  styleUrls: ['./treatment-brand-create-edit.component.css'],
})
export class TreatmentBrandCreateEditComponent implements OnInit {
  @Input()
  brandID;

  @Input()
  merchantID;

  @Input()
  isGlobal = false;

  brand = {
    categoryID: null,
    typeID: null,
    manufacturerID: null,
    distributorID: null,

    label: null,
    categoryNote: null,
    labelNote: null,

    merchantID: null,
    isActive: true,
    isNullBrand: null,
    logoID: null,
  };

  isLogoFile = false;
  logoSrc;
  logoLink;

  treatmentUtil = new treatmentUtil();

  @Output()
  closeModal = new EventEmitter();

  @Output()
  result = new EventEmitter();

  isModal = false;

  isEditMode = false;

  isDone = false;

  isAdminOrPromoter = false;

  categories = [];

  types = [];

  manufacturers = [];
  distributors = [];

  loading = false;
  profilePic;
  src;
  isFile = false;
  treatment;

  constructor(
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private utilsService: UtilsService,
    private router: Router,
    private treatmentService: TreatmentService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.brandID) {
        this.brandID = data.brandID;
      }

      if (data.merchantID) {
        this.merchantID = data.merchantID;
      }

      if (data.isGlobal != null) {
        this.isGlobal = data.isGlobal;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['brandID']) {
        this.brandID = params['brandID'];
      }

      this.brand.merchantID = this.merchantID;

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;
        this.setup();
      });
    });
  }

  setup() {
    if (this.brandID) {
      if (this.isAdminOrPromoter != true) {
        this.isGlobal = false;
      }

      if (this.isGlobal != false) {
        this.treatmentService.getTreatmentBrandDetailsGlobal(this.brandID).subscribe((res) => {
          if (res) {
            this.logoLink = null;
            this.logoLink = this.treatmentService.getBrandLogoStreamLink(this.brandID, res['LastModified']);

            this.isEditMode = true;

            this.fillData(res);

            this.getSuppliers();

            this.getTreatmentCategoryList();
          }
        });
      } else {
        const p = {
          merchantID: this.merchantID,
        };
        this.treatmentService.getTreatmentBrandDetails(this.brandID, p, this.isAdminOrPromoter).subscribe((res) => {
          if (res) {
            this.isEditMode = true;
            this.treatment = res;
            this.fillData(res);
          }
        });
      }
    } else {
      this.isEditMode = false;
      this.isGlobal = true;
      this.getTreatmentCategoryList();

      this.getSuppliers();
    }
  }

  viewType(id) {
    const ref = RootAppComponent.dialog.open(TreatmentTypeViewComponent, {
      data: {
        typeID: id,
        isGlobal: true,
      },

      width: '700px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewCategory(id) {
    const ref = RootAppComponent.dialog.open(TreatmentCategoryViewComponent, {
      data: {
        categoryID: id,
      },

      width: '700px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewBrand(id) {
    const ref = RootAppComponent.dialog.open(TreatmentBrandViewComponent, {
      data: {
        brandID: id,
        isGlobal: true,
      },

      width: '700px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewSupplier(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(BeneficiaryViewComponent, {
        data: id,
        width: '600px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  fillData(res) {
    if (res) {
      if (res['Label']) {
        this.brand.label = res['Label'];
      }

      if (res['Active'] == '1') {
        this.brand.isActive = true;
      }

      if (res['Active'] == '0') {
        this.brand.isActive = false;
      }

      if (res['DefaultNotes.Category'] && this.isGlobal != false) {
        this.brand.categoryNote = res['DefaultNotes.Category'];
      }

      if (res['DefaultNotes.Label'] && this.isGlobal != false) {
        this.brand.labelNote = res['DefaultNotes.Label'];
      }

      if (res['DefaultNotes.Category.Effective'] && this.isGlobal == false) {
        this.brand.categoryNote = res['DefaultNotes.Category.Effective'];
      }

      if (res['DefaultNotes.Label.Effective'] && this.isGlobal == false) {
        this.brand.labelNote = res['DefaultNotes.Label.Effective'];
      }

      if (res['Category_key']) {
        this.brand.categoryID = res['Category_key'];
      }

      if (res['Type_key']) {
        this.brand.typeID = res['Type_key'];
      }

      if (res['Manufacturer_Key']) {
        this.brand.manufacturerID = res['Manufacturer_Key'];
      }

      if (res['Distributor_Key']) {
        this.brand.distributorID = res['Distributor_Key'];
      }

      if (res['IsNullBrand'] == '1') {
        this.brand.isNullBrand = true;
      }

      if (res['IsNullBrand'] == '0') {
        this.brand.isNullBrand = false;
      }
    }
  }

  getSuppliers() {
    const payload = {
      typeCode: 'MANU',
      orderBy: 'CalculatedName',
      fields: 'ID,Name',
      isActive: true,
    };

    this.treatmentService.getSupplierList(payload, this.isAdminOrPromoter).subscribe((res) => {
      this.manufacturers = res;
    });

    const payload2 = {
      typeCode: 'DISTR',
      orderBy: 'CalculatedName',
      fields: 'ID,Name',
      isActive: true,
    };
    this.treatmentService.getSupplierList(payload2, this.isAdminOrPromoter).subscribe((res) => {
      this.distributors = res;
    });
  }

  getTreatmentCategoryList() {
    const payload = {
      fields: 'ID,Label',
      isActive: true,
    };

    this.treatmentService.getCategoryList(payload, this.isAdminOrPromoter).subscribe((res) => {
      this.categories = res;

      if (this.brand.categoryID) {
        const payload2 = {
          fields: 'ID,Label',
          categoryID: this.brand.categoryID,
          isActive: true,
        };
        this.treatmentService.getTreatmentTypeList(payload2, this.isAdminOrPromoter).subscribe((res1) => {
          if (res1) {
            this.types = res1;
          }
        });
      }
    });
  }

  onSelectSimpleCategory(simpleCategory) {
    if (simpleCategory) {
      const payload = {
        fields: 'ID,Label',
        categoryID: simpleCategory,
        isActive: true,
        isNullType: false,
      };

      this.treatmentService.getTreatmentTypeList(payload, this.isAdminOrPromoter).subscribe((res1) => {
        if (res1) {
          this.types = res1;
        }
      });
    }
  }

  closeEvent() {
    this.closeModal.emit(true);
  }

  save() {
    if (this.brand) {
      this.treatmentService.createTreatmentBrandGlobal(this.brand).subscribe((res) => {
        if (res) {
          if (res['ID']) {
            this.brandID = res['ID'];
          }

          NotifyAppComponent.displayToast('success', 'Successful operation', 'Brand has been successfully added');

          this.isDone = true;

          this.result.emit(res);
        }
      });
    }
  }

  edit() {
    if (this.brand) {
      if (this.isGlobal == false) {
        this.treatmentService.editTreatmentBrand(this.brandID, this.brand, this.isAdminOrPromoter).subscribe((res) => {
          if (res) {
            NotifyAppComponent.displayToast('success', 'Successful operation', 'Brand has been successfully updated');
            this.isDone = true;
            this.result.emit(res);
          }
        });
      } else {
        this.treatmentService.editTreatmentBrandGlobal(this.brandID, this.brand).subscribe((res) => {
          if (res) {
            NotifyAppComponent.displayToast('success', 'Successful operation', 'Brand has been successfully updated');
            this.isDone = true;
            this.result.emit(res);
          }
        });
      }
    }
  }

  list() {
    this.router.navigate(['/merchant', { outlets: { page: ['treatment-brand-list-global'] } }]);
  }

  changeLogoImage() {
    this.utilsService.getFileDetails(this.brand.logoID).subscribe((res) => {
      if (res) {
        if (res.contentType == 'image/png' || res.contentType == 'image/jpg' || res.contentType == 'image/jpeg') {
          this.isLogoFile = true;
          this.logoSrc = `${environment.nodeUrl}/files/file-view/${this.brand.logoID}`;
        } else {
          NotifyAppComponent.displayToast(
            'warning',
            'Error during operation',
            'Format of image should be png/jpg/jpeg'
          );
        }
      }
    });
  }

  onCompleteLogoAll(fileID) {
    if (fileID && fileID[0]) {
      this.brand.logoID = fileID[0];
      this.changeLogoImage();
    }
  }
}
