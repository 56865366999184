import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable()
export class TagsService {
  constructor(private http: HttpClient) {}

  getOneTag(ID, body = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }
    options = {
      params,
    };
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/tag-merchant/global' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/tag-merchant/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/tag-merchant/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getOneTagByLabel(label, body = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }
    options = {
      params,
    };
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/tag-merchant/label/global/' + label, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/tag-merchant/label/' + label, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/tag-merchant/label/' + label, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getTagsList(body = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }
    options = {
      params,
    };
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/tag-merchant/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/tag-merchant', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/tag-merchant', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  modifyTag(ID, payload, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .put(environment.nodeUrl + '/tag-merchant/global/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .put(environment.nodeUrl + '/tag-merchant/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/tag-merchant/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  createTag(payload, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .post(environment.nodeUrl + '/tag-merchant/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .post(environment.nodeUrl + '/tag-merchant', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/tag-merchant', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  createTagForCard(payload, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .post(environment.nodeUrl + '/tag-merchant/card/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .post(environment.nodeUrl + '/tag-merchant/card', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/tag-merchant/card', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  deleteTagForCard(payload, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .post(environment.nodeUrl + '/tag-merchant/delete/card/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .post(environment.nodeUrl + '/tag-merchant/delete/card', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/tag-merchant/delete/card', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  isUniqueTag(label, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/tag-merchant/unique/global/' + label, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/tag-merchant/unique/' + label, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/tag-merchant/unique/' + label, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  deleteTagGroup(ids, sessionType): Observable<any> {
    let _ids;
    if (Array.isArray(ids) == true) {
      _ids = ids.join(',');
    } else if (typeof ids == 'string') {
      _ids = ids;
    }
    if (_ids) {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.http
          .delete(environment.nodeUrl + '/tag-merchant/group/global/' + _ids)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
        return this.http
          .delete(environment.nodeUrl + '/tag-merchant/group/' + _ids)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.http
          .delete(environment.nodeUrl + '/tag-merchant/group/' + _ids)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  deleteTag(ID, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .delete(environment.nodeUrl + '/tag-merchant/global/' + ID, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .delete(environment.nodeUrl + '/tag-merchant/' + ID, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .delete(environment.nodeUrl + '/tag-merchant/' + ID, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  addTagsToItem(ID, payload, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .post(environment.nodeUrl + '/tag-merchant/tag/global/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .post(environment.nodeUrl + '/tag-merchant/tag/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/tag-merchant/tag/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  addTagsToItemByLabel(label, payload, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .post(environment.nodeUrl + '/tag-merchant/tag-label/global/' + label, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .post(environment.nodeUrl + '/tag-merchant/tag-label/' + label, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/tag-merchant/tag-label/' + label, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
}
