import { Component, EventEmitter, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelperService } from '../shared/helper.service';

@Component({
  selector: 'app-simple-page-view',
  templateUrl: './simple-page-view.component.html',
  styleUrls: ['./simple-page-view.component.css'],
})
export class SimplePageViewComponent implements OnInit {
  @Input() simplePageCode;
  @Input() displayHeader = true;
  @Input()
  simplePage;

  proceed = new EventEmitter();
  accepted = false;
  isModal = true;
  canAccept = false;
  hideHeader = false;

  title;
  payload;
  color;
  colorSecond;

  constructor(private helperService: HelperService, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data && data.simplePageCode) {
      if (data.simplePageCode) {
        this.simplePageCode = data.simplePageCode;
      }

      if (data.canAccept !== null) {
        this.canAccept = data.canAccept;
      }

      if (data.hideHeader !== null) {
        this.hideHeader = data.hideHeader;
      }

      if (data.payload !== null) {
        this.payload = data.payload;
      }

      if (data.title !== null) {
        this.title = data.title;
      }
      if (data.color !== null) {
        this.color = data.color;
      }

      if (data.colorSecond !== null) {
        this.colorSecond = data.colorSecond;
      }
    } else if (typeof data == 'string') {
      this.simplePageCode = data;
    }
  }

  ngOnInit() {
    // if (!this.simplePage) {
    this.helperService.getSimplePage(this.simplePageCode).subscribe((res) => {
      if (res) {
        if (this.payload && res && res.content) {
          for (let key of Object.keys(this.payload)) {
            res.content = res.content.split(`{{${key}}}`).join(this.payload[key]);
          }
        }
        this.simplePage = res;
      }
    });
    // }
  }
  proceedEvent() {
    this.proceed.emit(true);
  }
}
