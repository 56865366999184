import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-prospect-blank',
  templateUrl: './customer-prospect-blank.component.html',
  styleUrls: ['./customer-prospect-blank.component.css'],
})
export class CustomerProspectBlankComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
