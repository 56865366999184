<ipv-page
  *ngIf="viewModel$ | async as viewModel"
  [dataHeader]="{
    title: title,
    counterValue: viewModel.invoiceAppliances.length,
    imageSrc: titleIcon
  }"
  [isFetchingInitialData]="isFetchingInitialData"
  [headerSize]="'smaller'"
>
  <div class="row clearfix flex mb ctl">
    <div class="full-width flex">
      <button
        *ipvIsPromoterOrAdmin
        (click)="add(viewModel.industryType)"
        mat-raised-button
        style="margin-right: 5px"
        color="primary"
      >
        <mat-icon>add</mat-icon>
        Add Invoice
      </button>
    </div>
  </div>

  <ipv-data-table-control-container class="mt">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Invoice</mat-label>
      <div style="display: flex">
        <input
          class="clearfix"
          [value]="viewModel.Invoice_Number"
          (input)="setInvoiceFilter($event.target.value)"
          name="invoice"
          matInput
        />
        <ipv-clear-search-button *ngIf="viewModel.Invoice_Number" (click)="setInvoiceFilter('')">
        </ipv-clear-search-button>
      </div>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Customer Name</mat-label>
      <div style="display: flex">
        <input
          class="clearfix"
          [value]="viewModel.customerCalculatedNameFilter"
          (input)="setCustomerCalculatedNameFilter($event.target.value)"
          name="customerName"
          matInput
        />
        <ipv-clear-search-button *ngIf="viewModel.customerCalculatedNameFilter" (click)="setInvoiceFilter('')">
        </ipv-clear-search-button>
      </div>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Customer Email</mat-label>
      <div style="display: flex">
        <input
          class="clearfix"
          [value]="viewModel.customerEmailFilter"
          (input)="setCustomerEmailFilter($event.target.value)"
          name="customerEmail"
          matInput
        />
        <ipv-clear-search-button *ngIf="viewModel.customerEmailFilter" (click)="setCustomerEmailFilter('')">
        </ipv-clear-search-button>
      </div>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Product Label</mat-label>
      <div style="display: flex">
        <input
          class="clearfix"
          [value]="viewModel.customerProductLabelFilter"
          (input)="setCustomerProductLabelFilter($event.target.value)"
          name="productLabel"
          matInput
        />
        <ipv-clear-search-button
          *ngIf="viewModel.customerProductLabelFilter"
          (click)="setCustomerProductLabelFilter('')"
        >
        </ipv-clear-search-button>
      </div>
    </mat-form-field>
  </ipv-data-table-control-container>

  <ipv-data-table
    [titleCaseEnabled]="false"
    [table]="viewModel.invoiceAppliances"
    [hide]="hiddenColumns"
    [rename]="renamedColumns"
    [order]="order"
    [menuTemplate]="actions"
    [customComponents]="{
      Invoice_Total: invoiceTotal,
      Invoice_Date: invoiceDate,
      CustomerNumber: invoicePhone
    }"
    (itemClicked)="viewInvoice($event, viewModel.industryType)"
  >
  </ipv-data-table>

  <ng-template #actions let-record>
    <button (click)="viewInvoice(record, viewModel.industryType)" mat-menu-item>
      <mat-icon class="fas fa-box-open"></mat-icon>
      <span>View Invoice</span>
    </button>

    <button *ipvIsPromoterOrAdmin (click)="updateProduct(record, viewModel.industryType)" mat-menu-item>
      <mat-icon>description</mat-icon>
      <span>Edit Invoice</span>
    </button>
  </ng-template>

  <ng-template #invoiceTotal let-record>
    {{ record['Invoice_Total'] | customCurrency }}
  </ng-template>

  <ng-template #invoiceDate let-record>
    {{ record['Invoice_Date'] | customDate }}
  </ng-template>

  <ng-template #invoicePhone let-record>
    {{ record['CustomerNumber'] | customPhone }}
  </ng-template>
</ipv-page>
