<div class="rel text-center wizardModal intro">
  <mat-card class="card">
    <div class="card-header primary-gradient-img clearfix inModal">
      <div class="row clearfix">
        <!-- merchant calculated name -->
        <div class="row text-left clearfix">
          <!-- Modal dismiss button -->
          <button class="btn-close btn-clear mat-button white" (click)="close()">
            <mat-icon>clear</mat-icon>
          </button>
          <h2 class="summary-header rm-m">
            Paying a third party
            <span class="subLabel">Get paid quicker, reduce paperwork and manage your settlements from
              {{ "BRANDING.Brand Name" | translate | titlecase }}</span>
          </h2>
        </div>
      </div>
    </div>

    <mat-dialog-content>
      <aw-wizard class="settlementCreate" navBarLayout="large-filled-symbols">
        <aw-wizard-step [navigationSymbol]="{ symbol: 1 }">
          <img
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/smile_right/settlement-header-1.png"
            mat-card-image/>
          <div class="row clearfix text-center">
            <p class="lead rm-mt">
              Making payments to your suppliers, labs and other third parties has never been easier.
            </p>

            <p class="larger">
              If the {{ "PHRASE.patient treatment'" | translate }} requires settlement to a third party as well as
              your {{"KEYWORD.practice" | translate}} - such as a specialist, supplier or a lab - you can include it in
              the following section to
              streamline your request for funds from {{ "BRANDING.Brand Name" | translate | titlecase }}.
            </p>

            <div class="control-buttons row clearfix mb">
              <button class="pull-left" mat-mini-fab color="primary" disabled>
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button>
              <div class="pull-right">
                <h3 class="sr-title readMore">Learn more...</h3>

                <button class="pull-right" mat-mini-fab color="primary" awNextStep>
                  <mat-icon>chevron_right</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </aw-wizard-step>

        <aw-wizard-step [navigationSymbol]="{ symbol: 2 }">
          <div class="row clearfix text-center">
            <img
              src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/smile_right/settlement-thirdparty-1.jpg"
              mat-card-image/>

            <div class="row clearfix rel text-center">
              <p class="lead rm-mt"><strong>1.</strong> Select the provider that requires a payment</p>
            </div>

            <div class="control-buttons row clearfix mb">
              <button class="pull-left" mat-mini-fab color="primary" awPreviousStep>
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button>
              <div class="pull-right">
                <h3 class="sr-title readMore">Learn more...</h3>
                <button class="pull-right" mat-mini-fab color="primary" awNextStep>
                  <mat-icon>chevron_right</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </aw-wizard-step>

        <aw-wizard-step [navigationSymbol]="{ symbol: 3 }">
          <div class="row clearfix text-center">
            <img
              src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/smile_right/settlement-thirdparty-2.jpg"
              mat-card-image/>

            <div class="row clearfix rel text-center">
              <p class="lead rm-mt"><strong>2.</strong> Enter the amount needed to be sent</p>
            </div>

            <div class="control-buttons row clearfix mb">
              <button class="pull-left" mat-mini-fab color="primary" awPreviousStep>
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button>
              <div class="pull-right">
                <h3 class="sr-title readMore">Learn more...</h3>
                <button class="pull-right" mat-mini-fab color="primary" awNextStep>
                  <mat-icon>chevron_right</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </aw-wizard-step>

        <aw-wizard-step [navigationSymbol]="{ symbol: 4 }">
          <div class="row clearfix text-center">
            <img
              src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/smile_right/settlement-thirdparty-3.jpg"
              mat-card-image/>
            <p class="lead rm-mt"><strong>3.</strong> Confirm their payment details, and we'll handle the rest!</p>
            <p class="larger">
              Once your {{ "KEYWORD.patient" | translate }} has approved the settlement request, {{ 'BRANDING.Brand
              Name' | translate }} will notify
              both you and the third party of the settlement, electronically settle the funds direct to the chosen third
              party, and the remaining balance directly to your {{"KEYWORD.practice" | translate}}.
            </p>

            <hr class="mb"/>
            <div class="control-buttons row clearfix mb">
              <button class="pull-left" mat-mini-fab color="primary" awPreviousStep>
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button>
            </div>
          </div>
        </aw-wizard-step>
      </aw-wizard>
    </mat-dialog-content>

    <div class="row clearfix">
      <hr/>
      <mat-checkbox class="pull-left" [(ngModel)]="showOnThirdparty">Dont Show Again</mat-checkbox>

      <button class="pull-right" (click)="doNotShow()" mat-raised-button color="accent">
        Got It! &nbsp;
        <mat-icon class="fas fa-hand-point-right"></mat-icon>
      </button>
    </div>
  </mat-card>
</div>
