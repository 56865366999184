import { BehaviorSubject, EMPTY, merge as observableMerge, Observable, Subscription } from 'rxjs';

import { transition, trigger, useAnimation } from '@angular/animations';
import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import * as _ from 'lodash';
import { expand, map, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ngIfRowInAnimation, ngIfRowOutAnimation } from '../../../shared/animations/ngIf/ngIfRow.animation';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomDateTimePipe } from '../../../shared/pipes/custom-date-time.pipe';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { DefaultMessageViewComponent } from '../../default-message/default-message-view/default-message-view.component';
import { DefaultMessage, DefaultMessageService } from '../../default-message/shared/default-message.service';
import { DefaultMessageUtilClass } from '../../default-message/shared/defaultMessageUtil';
import { MessageTemplateCreateEditComponent } from '../message-template-create-edit/message-template-create-edit.component';

const animations = [
  trigger('ngIfAnimation', [
    transition('void => *', [useAnimation(ngIfRowInAnimation)]),
    transition('* => void', [useAnimation(ngIfRowOutAnimation)]),
  ]),
];

@Component({
  selector: 'app-default-message-list-global',
  templateUrl: './default-message-list-global.component.html',
  styleUrls: ['./default-message-list-global.component.css'],
  animations,
  providers: [CustomDatePipe, CustomDateTimePipe],
})
export class DefaultMessageListGlobalComponent implements OnChanges, OnDestroy, OnInit {
  subscriptions = new Subscription();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  @Input() merchantID;
  @Input() pageSize = 10;
  @Input() purposeCode;
  @Input() productGroupCode;
  @Input() title = 'Personal Messages';
  @Input() purposeCodes;
  @Input() productGroupCodes;
  @Input() notPurposeCodes;
  @Input() messageMode: 'default' | 'sms' | 'all' = 'default';

  defaultMessageUtilClass = new DefaultMessageUtilClass();

  createMessageButtonLabel = 'New';
  viewMessageMenuItemLabel = 'View';
  editMessageMenuItemLabel = 'Edit';
  deactivateMessageMenuItemLabel = 'Deactivate';
  activateMessageMenuItemLabel = 'Activate';
  removeMessageMenuItemLabel = 'Remove';
  isCollapsed = false;
  isPromoterOrAdmin;
  selectedFavouriteValue = false;
  isFirstLoad = true;

  displayedColumns = ['Label', 'Content', 'tags', 'Purpose', 'type', 'Favourite', 'CreatedDate', 'Actions'];
  merchantTags = [];
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  typeFilter;
  filters = [];
  filteredSize = null;

  card: any = {
    ID: '00000000000000000000',
    Salutation: 'Mr',
    FirstName: 'John',
    MiddleName: 'Al',
    Name: 'Doe',
    'mobiles.Number': '0061101010101',
    'phones.Number': '0062202020202',
    'emails.Email': 'john.doe@smileright.com.au',
    'addresses.Calculated': 'PO Box 7795 Cloisters Square 6850 Western Australia',
  };
  loadContact = false;
  loadMerchant = false;
  loadPromoter = false;
  merchant;
  promoter;
  contact;
  dateNow;

  productGroups = [];
  purposeGroups = [];
  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  constructor(
    private utilsService: UtilsService,
    private customDate: CustomDatePipe,
    private defaultMessageService: DefaultMessageService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {
    const messageMode = this.activatedRoute.snapshot.data['messageMode'];
    this.messageMode = messageMode ? messageMode : this.messageMode;
  }

  ngOnInit() {
    this.setup();
    const messageMode = this.activatedRoute.snapshot.data['messageMode'];

    this.messageMode = messageMode ? messageMode : this.messageMode;
    this.title = this.messageMode === 'default' || this.messageMode === 'all' ? 'Personal Messages' : `Personal SMS's`;

    this.viewMessageMenuItemLabel =
      this.messageMode === 'default' || this.messageMode === 'all'
        ? this.viewMessageMenuItemLabel + ' Message template'
        : this.viewMessageMenuItemLabel + ' SMS template';

    this.createMessageButtonLabel =
      this.messageMode === 'default' || this.messageMode === 'all'
        ? this.createMessageButtonLabel + ' Message template'
        : this.createMessageButtonLabel + ' SMS template';

    this.editMessageMenuItemLabel =
      this.messageMode === 'default' || this.messageMode === 'all'
        ? this.editMessageMenuItemLabel + ' Message template'
        : this.editMessageMenuItemLabel + ' SMS template';

    this.deactivateMessageMenuItemLabel =
      this.messageMode === 'default' || this.messageMode === 'all'
        ? this.deactivateMessageMenuItemLabel + ' Message template'
        : this.deactivateMessageMenuItemLabel + ' SMS template';

    this.activateMessageMenuItemLabel =
      this.messageMode === 'default' || this.messageMode === 'all'
        ? this.activateMessageMenuItemLabel + ' Message template'
        : this.activateMessageMenuItemLabel + ' SMS template';

    this.removeMessageMenuItemLabel =
      this.messageMode === 'default' || this.messageMode === 'all'
        ? this.removeMessageMenuItemLabel + ' Message template'
        : this.removeMessageMenuItemLabel + ' SMS template';
  }

  ngOnChanges() {
    if (this.isPromoterOrAdmin !== undefined) {
      this.loadRecords();
    } else {
      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;
        this.loadRecords();
      });
    }
  }

  collapse(d) {
    this.isCollapsed = d;
  }
  setup() {
    this.listDB.getMerchantTags.subscribe((tags) => {
      this.merchantTags = JSON.parse(JSON.stringify(tags || []));
    });

    if (!this.promoter) {
      this.authenticationService.getPromoter().subscribe((r) => {
        if (r) {
          this.promoter = r;
        }
        this.loadPromoter = true;
      });
    } else {
      this.loadPromoter = true;
    }

    if (!this.contact) {
      this.authenticationService.getCurrentDentist().subscribe((r) => {
        if (r) {
          this.contact = r;
        }
        this.loadContact = true;
      });
    }

    if (!this.merchant) {
      this.authenticationService.getCurrentPractice().subscribe((r) => {
        if (r) {
          this.merchant = r;
        }
        this.loadMerchant = true;
      });
    }
  }
  loadRecords() {
    const params = {
      purposeCode: this.purposeCode,
      productGroupCode: this.productGroupCode,
      purposeCodes: this.purposeCodes,
      notPurposeCodes: this.notPurposeCodes,
      productGroupCodes: this.productGroupCodes,
    };

    if (!this.isFirstLoad) {
      this.utilsService.getServerDate().subscribe((currentDate) => {
        this.dateNow = currentDate;
      });
    }

    this.listDB = new LoadRecords(
      this.defaultMessageService,
      this.merchantID,
      params,
      this.isPromoterOrAdmin,
      this.messageMode,
      this.activatedRoute
    );

    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
    this.listDB.getProductGroups.subscribe((tags) => {
      this.productGroups = JSON.parse(JSON.stringify(tags || []));
    });

    this.listDB.getPurposeGroups.subscribe((tags) => {
      this.purposeGroups = JSON.parse(JSON.stringify(tags || []));
    });
    if (this.isFirstLoad) {
      this.isFirstLoad = false;
      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;
      });
    }
  }

  toggleFavourite(checked: boolean) {
    const filter = { field: 'Favourite', value: '' };

    if (checked) {
      filter.value = '1';
    }

    this.selectedFavouriteValue = !this.selectedFavouriteValue;
    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }
  setFilter(event, field) {
    const filter = { field, value: '' };
    if (field == 'tags' && event && event.length > 0) {
      let e = event;
      if (typeof e === 'string') {
        e = [event];
      }
      filter.field = 'tags';
      filter.value = e;
    } else if (typeof event === 'object' && event !== null) {
      const value = this.customDate.transform(event, Settings.global['dateFormat']);
      filter.value = value;
    } else if (event) {
      filter.value = event;
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  clone(template) {
    const ref2 = RootAppComponent.dialog.open(MessageTemplateCreateEditComponent, {
      data: {
        merchantID: this.merchantID,
        messageMode: this.messageMode,
        message: template.Content,
        label: template.Label + ' (cloned)',
        productGroup: template['ProductGroup.Code'] || null,
        purposeCode: template['Purpose.Code'] || null,
        tags: template['tags'] || null,
        addToStore: true,
      },
      width: '900px',
      panelClass: 'noCard',
    });

    ref2.componentInstance.close.subscribe((res) => {
      ref2.close();
    });
    ref2.componentInstance.createdMessage.subscribe((item) => {
      if (item) {
        this.dataSource.addItem = item;
        ref2.close();
      }
    });
  }

  createMessage() {
    const smsEditorComponent = this.dialog.open(MessageTemplateCreateEditComponent, {
      data: {
        messageMode: this.messageMode,

        purposeCode: this.purposeCode,
        productGroupCode: this.productGroupCode,
        purposeCodes: this.purposeCodes,
        notPurposeCodes: this.notPurposeCodes,
        productGroupCodes: this.productGroupCodes,
      },
      width: '900px',

      panelClass: 'noCard',
    });

    this.subscriptions.add(
      smsEditorComponent.componentInstance.createdMessage.subscribe((createdMessage) => {
        if (createdMessage) {
          this.dataSource.addItem = createdMessage;
          smsEditorComponent.close();
        }
      })
    );

    this.subscriptions.add(
      smsEditorComponent.componentInstance.close.subscribe((closed) => {
        if (closed) {
          smsEditorComponent.close();
        }
      })
    );
  }

  deleteMessage(id: string) {
    const confirmDialog: ConfirmDialog = new ConfirmDialog(
      'fas fa-info',
      '',
      'Are you sure you want to remove these template?',
      'No',
      'Yes'
    );

    const confirmDialogComponent = this.dialog.open<ConfirmDialogComponent, ConfirmDialog>(ConfirmDialogComponent, {
      data: confirmDialog,
    });

    this.subscriptions.add(
      confirmDialogComponent.componentInstance.onConfirm.subscribe((confirmation) => {
        if (!confirmation) {
          confirmDialogComponent.close();
        } else {
          this.defaultMessageService.deleteDefaultMessage(id, this.isPromoterOrAdmin).subscribe((defaultMessage) => {
            if (defaultMessage) {
              this.dataSource.removeItem = id;

              NotifyAppComponent.displayToast('success', 'Success!', 'Template is removed ');
            }

            confirmDialogComponent.close();
          });
        }
      })
    );
  }

  editMessage(message: DefaultMessage) {
    const isDefault = message.IsPromoterTemplate === '1' ? true : false;

    const smsEditorComponent = this.dialog.open(MessageTemplateCreateEditComponent, {
      data: {
        messageTemplateID: message.ID,
        messageMode: this.messageMode,
      },

      width: '900px',

      panelClass: 'noCard',
    });

    this.subscriptions.add(
      smsEditorComponent.componentInstance.createdMessage.subscribe((createdMessage) => {
        if (createdMessage) {
          this.dataSource.replaceItem = createdMessage;
          smsEditorComponent.close();
        }
      })
    );

    this.subscriptions.add(
      smsEditorComponent.componentInstance.close.subscribe((closed) => {
        if (closed) {
          smsEditorComponent.close();
        }
      })
    );
  }

  viewMessage(id: string) {
    if (id) {
      const defaultMessageViewComponent = this.dialog.open(DefaultMessageViewComponent, {
        data: {
          messageID: id,
          messageMode: this.messageMode,
        },
        width: '750px',
        panelClass: 'noCard',
      });

      this.subscriptions.add(
        defaultMessageViewComponent.componentInstance.closeModal.subscribe(() => {
          defaultMessageViewComponent.close();
        })
      );
    }
  }

  setFavourite(ID: string) {
    const type = this.messageMode === 'default' ? 'Message' : 'SMS';

    const confirmMessage: ConfirmDialog = new ConfirmDialog(
      'fas fa-info',
      `Set Favourite ${type}`,
      `Are you sure you want to add this ${type.toLowerCase()} to your favourite list?`,
      'No',
      'Yes'
    );

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: confirmMessage,
      width: '700px',
    });

    this.subscriptions.add(
      confirmDialog.componentInstance.onConfirm.subscribe((confirmed) => {
        if (confirmed) {
          const payload = { active: true };

          this.defaultMessageService
            .editDefaultMessage(ID, payload, this.isPromoterOrAdmin)
            .subscribe((editedMessage) => {
              this.dataSource.replaceItem = editedMessage;
              confirmDialog.close();
              NotifyAppComponent.displayToast(
                'success',
                'Successful Operation',
                `You have successfully added this ${type.toLowerCase()} to your favourite list.`
              );
            });
        }
      })
    );
  }

  cancelFavourite(ID) {
    const type = this.messageMode === 'default' ? 'Message' : 'SMS';

    const confirmMessage: ConfirmDialog = new ConfirmDialog(
      'fas fa-info',
      `Set Favourite ${type}`,
      `Are you sure you want to remove this ${type.toLowerCase()} from your favourite list?`,
      'No',
      'Yes'
    );

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: confirmMessage,
      width: '650px',
    });

    this.subscriptions.add(
      confirmDialog.componentInstance.onConfirm.subscribe((confirmed) => {
        if (confirmed) {
          const payload = { active: false };

          this.defaultMessageService
            .editDefaultMessage(ID, payload, this.isPromoterOrAdmin)
            .subscribe((editedMessage) => {
              this.dataSource.replaceItem = editedMessage;
              confirmDialog.close();
              NotifyAppComponent.displayToast(
                'success',
                'Successful Operation',
                `You have successfully remove this ${type.toLowerCase()} from your favourite list.`
              );
            });
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}

export class LoadRecords implements OnDestroy {
  subscriptions = new Subscription();

  public dataChange: BehaviorSubject<DefaultMessage[]> = new BehaviorSubject<DefaultMessage[]>([]);

  items: DefaultMessage[] = [];
  count: number | string;

  public getMerchantTags = new EventEmitter();
  merchantTags = [];
  get data(): DefaultMessage[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  productGroups = [];
  purposeGroups = [];
  public getProductGroups = new EventEmitter();
  public getPurposeGroups = new EventEmitter();
  constructor(
    private defaultMessageService: DefaultMessageService,
    private merchantID: string,
    private params,
    private isPromoterOrAdmin: boolean,
    private messageMode: 'default' | 'sms' | 'all' = 'default',
    private activatedRoute: ActivatedRoute
  ) {
    const messageModeRoute = this.activatedRoute.snapshot.data['messageMode'];
    this.messageMode = messageModeRoute ? messageModeRoute : this.messageMode;

    const payload = {
      purposeCode: '',
      merchantID: this.merchantID,
      orderBy: 'Label',
      productGroupCode: '',
      selectDefault: true,
      purposeCodes: undefined,
      productGroupCodes: undefined,
      section: 1,
      notPurposeCodes: [],
    };

    if (params.purposeCodes) {
      payload.purposeCodes = Array.isArray(params['purposeCode'])
        ? (payload.purposeCodes = params['purposeCodes'].join(','))
        : (payload.purposeCodes = params['purposeCodes']);
    }

    if (params.productGroupCodes) {
      payload.productGroupCodes = Array.isArray(params['productGroupCodes'])
        ? (payload.productGroupCodes = params['productGroupCodes'].join(','))
        : (payload.productGroupCodes = params['productGroupCodes']);
    }

    if (this.messageMode === 'sms') {
      payload.purposeCodes = ['PromSMS', 'MerSMS'];
    } else if (this.messageMode === 'all') {
      payload.purposeCodes = [];
    } else {
      payload.notPurposeCodes = ['PromSMS', 'MerSMS'];
    }

    if (params.notPurposeCodes && params.notPurposeCodes.length > 0) {
      payload.notPurposeCodes = Array.isArray(params['purposeCode'])
        ? (payload.notPurposeCodes = params['notPurposeCodes'].join(','))
        : (payload.notPurposeCodes = params['notPurposeCodes']);
    }

    UtilsClass.loadingDataSection(payload.section);

    this.defaultMessageService
      .fetchDefaultMessages$(payload, this.isPromoterOrAdmin)
      .pipe(
        tap(() => {
          this.firstLoad = true;
          UtilsClass.stopLoadingInterceptor();
          payload.section++;
          UtilsClass.loadingDataSection(payload.section);
        }),
        expand((messages) =>
          messages.length > 0
            ? this.defaultMessageService.fetchDefaultMessages$(payload, this.isPromoterOrAdmin).pipe(
                tap(() => {
                  UtilsClass.stopLoadingInterceptor();
                })
              )
            : EMPTY
        ),
        tap((messages) => {
          if (messages.length > 0) {
            payload.section++;
            UtilsClass.loadingDataSection(payload.section);
            this.addNextMessagesSection(messages);
          }
        })
      )
      .subscribe();
  }

  addNextMessagesSection(messages: DefaultMessage[]) {
    _.map(messages, (item) => {
      if (item) {
        item['fullName'] = '';
        if (item['Group.Label']) {
          item['fullName'] = item['fullName'] + ' ' +item['Group.Label']
        }

        if (item['Group.Code']) {
          item['fullName'] = item['fullName'] + ' ' +item['Group.Code']
        }

        if (item['Purpose.Code']) {
          item['fullName'] = item['fullName'] + ' ' +item['Purpose.Code']
        }
        if (item['Purpose.Label']) {
          item['fullName'] = item['fullName'] + ' ' +item['Purpose.Label']
        }
        if (item['Label']) {
          item['fullName'] = item['fullName'] + ' ' +item['Label']
        }

        if (item['ID']) {
          item['fullName'] = item['fullName'] + ' ' +item['ID']
        }

        if (item['Card_key']) {
          item['fullName'] = item['fullName'] + ' ' +item['Card_key']
        }
        if (item['Content']) {
          item['fullName'] = item['fullName'] +' ' + item['Content']
        }
      }
      if (item && item['tags'] && item['tags'].length > 0) this.merchantTags = this.merchantTags.concat(item['tags']);

      if (item && item['Group.Code'] && item['Group.Label']) {
        const p = {
          code: item['Group.Code'],
          value: item['Group.Label'],
        };

        this.productGroups.push(p);
      }

      if (item && item['Purpose.Code'] && item['Purpose.Label']) {
        const p = {
          code: item['Purpose.Code'],
          value: item['Purpose.Label'],
        };

        this.purposeGroups.push(p);
      }

      return item;
    });

    this.productGroups = _.uniqBy(this.productGroups, 'code');
    this.purposeGroups = _.uniqBy(this.purposeGroups, 'code');

    this.productGroups = _.orderBy(this.productGroups, 'value');
    this.purposeGroups = _.orderBy(this.purposeGroups, 'value');

    this.getProductGroups.emit(this.productGroups);
    this.getPurposeGroups.emit(this.purposeGroups);

    this.merchantTags = _.uniq(this.merchantTags);
    this.merchantTags = _.orderBy(this.merchantTags);
    this.getMerchantTags.emit(this.merchantTags);

    this.count = messages.length;
    this.items = this.items.concat(messages);
    this.dataChange.next(this.items.slice());
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<DefaultMessage[]> {
  private filterChange = new BehaviorSubject('');
  subscriptions = new Subscription();
  utilMessage = new DefaultMessageUtilClass();

  field = '';

  filteredData: any[] = [];
  constructor(private tableDatabase: LoadRecords, private paginator: MatPaginator, private sort: MatSort) {
    super();

    this.subscriptions.add(
      this.filterChange.subscribe(() => {
        this.paginator.pageIndex = 0;
      })
    );
  }

  get filter(): any {
    return this.filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this.filterChange.next(item.value);
  }

  set addItem(message: DefaultMessage) {
    this.tableDatabase.data.unshift(message);
    this.tableDatabase.dataChange.next(this.tableDatabase.data);
  }

  set replaceItem(targetMessage: DefaultMessage) {
    const index = this.tableDatabase.data.findIndex((message) => message.ID === targetMessage.ID);
    this.tableDatabase.data[index] = targetMessage;
    this.tableDatabase.dataChange.next(this.tableDatabase.data);
  }

  set removeItem(id) {
    const messages = this.tableDatabase.data.filter((message) => message.ID !== id);
    this.tableDatabase.dataChange.next(messages);
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this.tableDatabase.dataChange,
      this.filterChange,
      this.paginator.page,
      this.sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this.tableDatabase.data.slice().filter((message) => {
          if (this.field == 'tags') {
            let f = '';
            f = message[this.field] || '';

            if (this.filter && this.filter.length > 0) {
              for (let j = 0; j < this.filter.length; j++) {
                if (f.indexOf(this.filter[j]) == -1) {
                  return false;
                }
              }
              return true;
            } else {
              return f;
            }
          } else {
            let searchValue = '';

            searchValue = message[this.field] || '';

            const searchText = searchValue.toLowerCase();
            return searchText.indexOf(this.filter.toLowerCase()) !== -1;
          }
        });
        const sortedData = this.getSortedData(this.filteredData.slice());
        const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
        return sortedData.splice(startIndex, this.paginator.pageSize);
      })
    );
  }

  disconnect() {}

  getSortedData(messages: DefaultMessage[]): DefaultMessage[] {
    if (!this.sort.active || this.sort.direction === '') {
      return messages;
    }

    return messages.sort((a, b) => {
      let property1: number | string = '';
      let property2: number | string = '';

      switch (this.sort.active) {
        case 'Label':
          [property1, property2] = [a['Label'], b['Label']];
          break;

        case 'Content':
          [property1, property2] = [a['Content'], b['Content']];
          break;

        case 'PurposeCode':
          [property1, property2] = [a['Purpose.Label'], b['Purpose.Label']];
          break;

        case 'ProductGroupCode':
          [property1, property2] = [
            this.utilMessage.getGroupLabel(a['ProductGroup.Code']),
            this.utilMessage.getGroupLabel(b['ProductGroup.Code']),
          ];
          break;

        case 'CreatedDate':
          [property1, property2] = [
            moment(a['DateTimeCreated']).toDate().getTime(),
            moment(b['DateTimeCreated']).toDate().getTime(),
          ];
          break;
      }

      const valueA = isNaN(+property1) ? property1 : +property1;
      const valueB = isNaN(+property2) ? property2 : +property2;

      return (valueA < valueB ? -1 : 1) * (this.sort.direction === 'asc' ? 1 : -1);
    });
  }
}
