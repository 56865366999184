/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contract-detail-documents.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "../contract-document/contract-document.component.ngfactory";
import * as i6 from "../contract-document/contract-document.component";
import * as i7 from "../shared/contract.service";
import * as i8 from "@angular/router";
import * as i9 from "@angular/material/dialog";
import * as i10 from "@angular/common";
import * as i11 from "./contract-detail-documents.component";
var styles_ContractDetailDocumentsComponent = [i0.styles];
var RenderType_ContractDetailDocumentsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContractDetailDocumentsComponent, data: {} });
export { RenderType_ContractDetailDocumentsComponent as RenderType_ContractDetailDocumentsComponent };
function View_ContractDetailDocumentsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "masonry"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "item full-width"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "mat-card", [["class", "clearfix mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(3, 49152, null, 0, i3.MatCard, [[2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 0, "div", [["class", "colour-stripe"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, 0, 5, "mat-card-header", [["class", "mat-card-header"]], null, null, null, i2.View_MatCardHeader_0, i2.RenderType_MatCardHeader)), i1.ɵdid(6, 49152, null, 0, i3.MatCardHeader, [], null, null), (_l()(), i1.ɵeld(7, 0, null, 1, 3, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i3.MatCardTitle, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "h3", [["class", "sr-title sm-h3 rm-m"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Documents"])), (_l()(), i1.ɵeld(11, 0, null, 0, 3, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(12, 16384, null, 0, i3.MatCardContent, [], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-contract-document", [], null, null, null, i5.View_ContractDocumentComponent_0, i5.RenderType_ContractDocumentComponent)), i1.ɵdid(14, 114688, null, 0, i6.ContractDocumentComponent, [i7.ContractService, i8.ActivatedRoute, [2, i9.MAT_DIALOG_DATA]], { contractID: [0, "contractID"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.contract["ID"]; _ck(_v, 14, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 2, 0, currVal_0); }); }
export function View_ContractDetailDocumentsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContractDetailDocumentsComponent_1)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contract; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ContractDetailDocumentsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contract-detail-documents", [], null, null, null, View_ContractDetailDocumentsComponent_0, RenderType_ContractDetailDocumentsComponent)), i1.ɵdid(1, 114688, null, 0, i11.ContractDetailDocumentsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContractDetailDocumentsComponentNgFactory = i1.ɵccf("app-contract-detail-documents", i11.ContractDetailDocumentsComponent, View_ContractDetailDocumentsComponent_Host_0, { contractObject: "contractObject" }, {}, []);
export { ContractDetailDocumentsComponentNgFactory as ContractDetailDocumentsComponentNgFactory };
