import { Location } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { CustomerService } from '../../customer/shared/customer.service';

@Component({
  selector: 'app-contact-details-edit-modal',
  templateUrl: './contact-details-edit-modal.component.html',
  styleUrls: ['./contact-details-edit-modal.component.css'],
})
export class ContactDetailsEditModalComponent implements OnInit {
  @Input() customerID;
  @Input() canEdit;
  customer = {};

  @Output() closeModal = new EventEmitter();

  validateMobile = false;
  validatePhone = false;
  validateEmail = false;
  phone;
  mobile;
  email;
  mySuburb;

  phoneLabels = [
    { value: 'daytime', name: 'Daytime' },
    { value: 'home', name: 'Home' },
    { value: 'mobile', name: 'Mobile' },
    { value: 'weekend', name: 'Weekend' },
    { value: 'work', name: 'Work' },
  ];

  emailLabels = [
    { value: 'business', name: 'Business' },
    { value: 'home', name: 'Home' },
    { value: 'personal', name: 'Personal' },
    { value: 'relative', name: 'Relative' },
    { value: 'work', name: 'Work' },
  ];

  constructor(
    private location: Location,
    private customerService: CustomerService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.customerID = data;
    }
  }

  ngOnInit() {}

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  editDetail() {
    const payload = {
      email: this.email,
      mobile: this.mobile,
      phone: this.phone,
    };
    this.customerService.editCustomer(this.customer['ID'], payload).subscribe((res) => {
      if (res && typeof res == 'object') {
        this.customer = res;

        NotifyAppComponent.displayToast('success', 'Success!', 'Details have been updated');
      }
    });
  }

  getMobile(m) {
    this.mobile = m;
  }

  getPhone(p) {
    this.phone = p;
  }

  getEmail(e) {
    this.email = e;
  }

  validateMobileEvent(v) {
    this.validateMobile = v;
  }

  validatePhoneEvent(v) {
    this.validatePhone = v;
  }

  validateEmailEvent(v) {
    this.validateEmail = v;
  }
}
