<aw-wizard #wizard [disableNavigationBar]="true" navBarLayout="large-filled-symbols">
  <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Template Name" awOptionalStep>
    <mat-card class="card treatment-card card1 clearfix" id="simpleInvitation">
      <form #createForm="ngForm">
        <div class="row clearfix tab-header closable">
          <h3 class="sm-h3 rm-mt">Create a new Invitation Template</h3>
        </div>

        <div class="row clearfix selectMerchant mt column" *ngIf="isPromoterOrAdmin == true">
          <div class="icons-col">
            <mat-icon class="fas fa-stamp"></mat-icon>
          </div>

          <div class="row-content-col">
            <div class="pull-left clearfix">
              <h3 class="sr-title sm-h3 rm-mt reduce-marg mt">{{ "BRANDING.Brand Name" | translate | titlecase }}
                Template</h3>

              <mat-checkbox class="pull-left clearfix" [(ngModel)]="isDefault" name="discount_toggle2"><span
                class="text">
                  Make this template available to all {{ "KEYWORD.practice" | translate }}s
                </span></mat-checkbox>
            </div>
          </div>
        </div>

        <div class="row clearfix selectMerchant mt column" *ngIf="isPromoterOrAdmin == true && isDefault != true"
             @ngIfAnimation>
          <div class="animate">
            <div class="icons-col">
              <mat-icon class="fas fa-store-alt"></mat-icon>
            </div>
            <div class="row-content-col">
              <!--  allow to select a string of multiple merchants  -->

              <mat-form-field class="margin-btm half-width" appearance="outline">
                <mat-label>Please Select a Merchant</mat-label>
                <mat-select class="" [(ngModel)]="selectedMerchants" (selectionChange)="changeMerchant($event.value)"
                            name="MerchantName" placeholder="Please Select Merchant">
                  <mat-option [value]="null"> My Account</mat-option>
                  <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m['ID']">
                    <span class="list-label pull-right" *ngIf="m['Status'] && m['Status'] == 'Active'"
                          [style.background]="'#00d07a'">
                      {{ m['Status'] }}
                    </span>

                    <span class="list-label pull-right" *ngIf="m['Status'] && m['Status'] != 'Active'"
                          [style.background]="'#ff5722'">
                      {{ m['Status'] }}
                    </span>
                    {{ m['TradingAs'] || m['CalculatedName'] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button class="pull-right merchBtn" *ngIf="merchantID && merchantID != 'none'" (click)="viewMerchant()"
                      mat-raised-button color="primary">
                <mat-icon>person</mat-icon>
                <span class="mobHide">View Merchant</span>
              </button>
            </div>
          </div>
        </div>
        <div class="row clearfix mt column">
          <div class="icons-col">
            <mat-icon class="fas fa-info-circle"></mat-icon>
          </div>
          <div class="row-content-col">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Template Title</mat-label>
              <input class="mat-block" [(ngModel)]="label" name="TemplateTitle" matInput placeholder="Template Title"
                     required/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <textarea class="dentMsg increase-input-field" [(ngModel)]="description" name="description" matInput
                        placeholder="Description"></textarea>
            </mat-form-field>
          </div>
        </div>

        <div class="row clearfix mt column">
          <div class="icons-col">
            <mat-icon class="fas fa-comment-dollar"></mat-icon>
          </div>
          <div class="row-content-col">
            <div class="clearfix full-width">
              <mat-form-field class="margin-btm half-width" appearance="outline">
                <mat-label>Product Group</mat-label>
                <mat-select class="" [(ngModel)]="productGroup" name="productGroup" placeholder="Product Group">
                  <mat-option *ngFor="let productGroup of productGroups | orderBy: ['label']"
                              [value]="productGroup['code']">
                    <mat-icon
                      class="fas {{ productUtil.getProductGroupIcon(productGroup['code']) }} mr iconproductgroup ">
                    </mat-icon>

                    {{ productUtil.getLabel(productGroup['code']) }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
    </mat-card>
    <div class="row clearfix step2">
      <div class="full-width step-button-row text-center">
        <button class="step-back" [routerLink]="['/merchant/', { outlets: { page: ['treatment-saved-template'] } }]"
                mat-mini-fab color="primary" awPreviousStep>
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <button class="btn-large" [disabled]="!createForm.form.valid" (click)="proceed()" mat-raised-button
                color="accent">
          Next
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
  </aw-wizard-step>
  <aw-wizard-step [navigationSymbol]="{ symbol: '2' }"
                  [stepTitle]="('KEYWORD.treatment' | translate | titlecase) + ' Details'" awOptionalStep>
    <div class="row clearfix saved card1" *ngIf="isModuleTreatmentTemplateActive == true">
      <div class="full-width flex flexo">
        <div class="full-width">
          <button class="pull-right" (click)="launchAsModal('418331841', 1)" mat-icon-button color="primary"
                  matTooltip="What's this?">
            <mat-icon class="fas fa-info-circle mat-primary"></mat-icon>
          </button>

          <button class="pull-right" (click)="InvitationTemplateLookup()" mat-raised-button color="primary">
            <mat-icon class="position fas fa-brush"></mat-icon> Apply Template
          </button>
          <h3 class="sr-title sm-h3 rm-m">Invitation template</h3>
          <p class="rm-m small">There are saved invitation templates which you can apply here.</p>
        </div>
      </div>
    </div>

    <mat-card class="card treatment-card card1 text-center" id="simpleInvitation">
      <div class="row clearfix tab-header closable" @ngIfAnimation
           [class.primary-gradient-img]="templates && templates.length">
        <h3 class="sm-h3 rm-mt animate" *ngIf="!templates || (templates && templates.length <= 0)">
          Create New {{"KEYWORD.Treatment" | translate}}
        </h3>
        <ng-container *ngIf="templates && templates.length > 0" @ngIfAnimation>
          <div class="animate">
            <div class="row clearfix">
              <h3 class="sm-h3 rm-mt rm-mb">Create from templates</h3>
            </div>
            <div class="full-width treatment-item-parent text-center mt mb clearfix">
              <div class="full-width treatment-item flex" *ngFor="let template of templates">
                <div class="full-width border-box ml mr">
                  <div class="row clearfix">
                    <p class="white reduce-marg hint">
                      {{ template.label }}
                      <span *ngIf="
                          template &&
                          (template.treatmentValue || template.treatmentValue === 0) &&
                          template.options &&
                          template.options.selectTreatmentValue == true
                        ">:
                      </span>
                      <strong *ngIf="
                          template &&
                          (template.treatmentValue || template.treatmentValue === 0) &&
                          template.options &&
                          template.options.selectTreatmentValue == true
                        ">
                        {{ template.treatmentValue | customCurrency }}
                      </strong>
                    </p>
                  </div>
                  <div class="row clearfix mb">
                    <button class="white" (click)="viewSavedTreatment(template)" mat-stroked-button>
                      <mat-icon> visibility </mat-icon> View Template
                    </button>
                    <button class="white ml" (click)="clearTreatment(template)" mat-stroked-button>
                      <mat-icon> clear </mat-icon> Clear
                    </button>
                    <div class="full-width text-center mt" *ngIf="template && template.options">
                      <div class="white check-template"
                           [ngClass]="{ 'hide-option': template.options.selectTreatmentValue !== true }"
                           matTooltip="{{'KEYWORD.treatment' | translate | titlecase}} value {{
                          template.options.selectTreatmentValue == true ? 'is added' : 'is not added'
                        }}">
                        <mat-icon class="fas fa-dollar-sign"></mat-icon>
                      </div>
                      <div class="white check-template"
                           [ngClass]="{ 'hide-option': template.options.selectDocuments !== true }"
                           matTooltip="{{'KEYWORD.treatment' | translate | titlecase}} Documents {{
                          template.options.selectDocuments == true ? 'are added' : 'are not added'
                        }}">
                        <mat-icon class="fas fa-file-alt"></mat-icon>
                      </div>
                      <div class="white check-template"
                           [ngClass]="{ 'hide-option': template.options.selectImages !== true }"
                           matTooltip="{{'KEYWORD.treatment' | translate | titlecase}} Images {{
                          template.options.selectImages == true ? 'are added' : 'are not added'
                        }}">
                        <mat-icon class="fas fa-image"></mat-icon>
                      </div>
                      <div class="white check-template"
                           [ngClass]="{ 'hide-option': template.options.selectTreatmentPlan !== true }"
                           matTooltip="{{'KEYWORD.treatment' | translate | titlecase}} Items {{
                          template.options.selectTreatmentPlan == true ? 'are added' : 'are not added'
                        }}">
                        <mat-icon class="fas fa-notes-medical"></mat-icon>
                      </div>
                      <div class="white check-template"
                        [ngClass]="{ 'hide-option': template.options.selectTags !== true }"
                           matTooltip="Tags {{ template.options.selectTags == true ? 'are added' : 'are not added' }}">
                        <mat-icon class="fas fa-tags"></mat-icon>
                      </div>

                      <div class="white check-template"
                           [ngClass]="{ 'hide-option': template.options.selectMessage !== true }" [matTooltip]="'Personal message to ' +  ('KEYWORD.patient' | translate) + ' ' +
                          (template.options.selectMessage == true ? 'is added' : 'is not added')">
                        <mat-icon class="fas fa-comment-dots"></mat-icon>
                      </div>
                      <div class="white check-template"
                           [ngClass]="{ 'hide-option': template.options.selectDiscount !== true }"
                           matTooltip="Discount  configuration {{
                          template.options.selectDiscount == true ? 'is added' : 'is not added'
                        }}">
                        <mat-icon class="fas fa-money-bill-wave"></mat-icon>
                      </div>
                      <div class="white check-template"
                           [ngClass]="{ 'hide-option': template.options.selectHealthHistory !== true }"
                           matTooltip=" Health History {{
                          template.options.selectHealthHistory == true ? 'is added' : 'is not added'
                        }}">
                        <mat-icon class="fas fa-heartbeat"></mat-icon>
                      </div>
                      <div class="white check-template"
                           [ngClass]="{ 'hide-option': template.options.selectGoogleReview !== true }"
                           matTooltip="Google Review {{
                          template.options.selectGoogleReview == true ? 'is added' : 'is not added'
                        }}">
                        <mat-icon class="fas fa-star"></mat-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="row clearfix">
        <mat-card class="important-card">
          <div class="colour-stripe"></div>
          <h3 class="sr-title sm-h3 rm-m">1. Default {{ "KEYWORD.treatment" | translate | titlecase }} Value</h3>
          <button class="pull-right position-right" (click)="launchAsModal('418752721', 1)" mat-icon-button
                  color="primary" matTooltip="What's this?">
            <mat-icon class="fas fa-info-circle mat-primary"></mat-icon>
          </button>
          <div class="clearfix mt centered">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label> {{"KEYWORD.Treatment" | translate}} Value ($)</mat-label>
              <input class="mat-block largeInput" [(ngModel)]="treatmentAmount" (blur)="changeTreatmentValue()"
                     name="treatmentAmount" matInput placeholder="Please enter a value" currencyMask/>
            </mat-form-field>
          </div>
        </mat-card>
      </div>
    </mat-card>

    <mat-card class="card treatment-card card1 text-center clearfix" id="simpleInvitation2">
      <div class="colour-stripe"></div>
      <div class="row clearfix">
        <div class="column">
          <div class="row clearfix">
            <h3 class="sr-title sm-h3 rm-mb">2. Default supporting documents</h3>
            <p class="small">
              Upload or choose documents from the {{ "KEYWORD.treatment" | translate | titlecase }} Documents Gallery to
              send
              to {{ "KEYWORD.patient" | translate }}s (PDF format only)

            </p>

            <div class="row clearfix">
              <button class="text-center mat-elevation-z1" (click)="checkTreatmentDocuments()" mat-stroked-button
                      color="primary">
                <mat-icon class="fas fa-file-import"></mat-icon>&nbsp; Search Document Gallery
              </button>
            </div>
            <ng-container>
              <app-file-uploader class="text-center" [isDragable]="true"
                                 [fileName]="('KEYWORD.treatment' | translate | titlecase) + ' Document'"
                                 [isGallery]="false"
                                 [uploadDirectly]="true" (onCompleteAllDetails)="onCompleteAllDetailsSimple($event)">
              </app-file-uploader>
              <app-file-list class="" [documentTypes]="documentTypes" [files]="documents" [canGallery]="true"
                             [canPrivateDocument]="false" [addFile]="addFile" [canEdit]="true" [canAction]="false"
                             (remove)="removeDocument($event)" (metaUpdated)="updateDocument($event)">
              </app-file-list>
            </ng-container>

            <div class="row clearfix mb" *ngIf="EPTreatmentDocuments && EPTreatmentDocuments.length > 0">
              <p class="small rm-m-b">
                Select the {{ "KEYWORD.treatment" | translate | titlecase }} Document that you want to add to
                this {{ "KEYWORD.treatment" | translate }}
              </p>
              <div class="clearfix full-width">
                <app-ep-document-list [isDownload]="isDownload" [isPrint]="isPrint" [canRemove]="true"
                                      [isFilter]="false" [defaultCheck]="true" [isRedirection]="isRedirection"
                                      [documents]="EPTreatmentDocuments" [title]="'Gallery Documents'"
                                      [canCheck]="false"
                                      [listViewType]="'thumbnails'"
                                      (getselectedDocumentIDs)="getselectedTreatmentDocumentIDs($event)">
                </app-ep-document-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card>

    <mat-card class="card treatment-card card1 text-center clearfix" id="simpleInvitation3">
      <div class="colour-stripe"></div>
      <div class="row clearfix">
        <div class="column">
          <div class="row clearfix">
            <h3 class="sr-title sm-h3 mt rm-mb" *ngIf="isModuleStockArtActive == true">
              3. Default images
            </h3>
            <p class="small">
              Upload {{ 'KEYWORD.procedure' | translate }} imagery to send to the {{ "KEYWORD.patient" | translate }} or
              choose some images from our
              gallery.
            </p>
          </div>

          <div class="row clearfix">
            <button class="mat-elevation-z1" (click)="checkStockArtDocuments()" mat-stroked-button color="primary">
              <mat-icon class="fas fa-file-import"></mat-icon>&nbsp; Search Image Gallery
            </button>
          </div>

          <!-- <p class="small">{{ "TreatmentPlanUploadInfo" | translate }}</p> -->
          <app-file-uploader class="text-center" *ngIf="isModuleStockArtActive == true" [documentType]="'Image'"
                             [fileName]="('KEYWORD.procedure' | translate | titlecase) + ' Gallery'" [isGallery]="true"
                             [isDragable]="true" [uploadDirectly]="true"
                             (onCompleteAllDetails)="onCompleteAllStockArtDetailsSimple($event)">
          </app-file-uploader>
          <app-file-list class="mt" *ngIf="isModuleStockArtActive == true"
                         [defaultLabel]="('KEYWORD.procedure' | translate | titlecase) +' Gallery'"
                         [documentTypes]="documentTypes2"
                         [files]="stockArtDocuments" [addFile]="addFileStockArt" [canEdit]="true" [canGallery]="true"
                         [canAction]="false" (remove)="removeSimpleTreatmentStockArtDocument($event)"
                         (metaUpdated)="updateStockArt($event)">
          </app-file-list>
          <div class="row clearfix mb"
               *ngIf="isModuleStockArtActive == true && EPstockArtDocuments && EPstockArtDocuments.length > 0">
            <p class="small rm-m-b">
              Select the {{ 'KEYWORD.procedure' | translate }} images that you want to add to
              this {{ "KEYWORD.treatment" | translate }}
            </p>
            <div class="clearfix full-width">
              <app-ep-document-list [isDownload]="isDownload" [isPrint]="isPrint" [canRemove]="true" [isFilter]="false"
                                    [defaultCheck]="true" [isRedirection]="isRedirection"
                                    [documents]="EPstockArtDocuments"
                                    [title]="'Gallery Images'" [canCheck]="false" [listViewType]="'thumbnails'"
                                    (getselectedDocumentIDs)="getselectedStockArtDocumentIDs($event)">
              </app-ep-document-list>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </mat-card>

    <mat-card class="card treatment-card card1 text-center clearfix" id="simpleInvitation4">
      <div class="colour-stripe"></div>

      <div class="row clearfix mt" *ngIf="isModuleTreatmentGroupActive == true">
        <!-- <div class="row clearfix"> -->
        <div class="row clearfix">
          <div class="column">
            <div class="row clearfix">
              <h3 class="sr-title sm-h3 rm-mb mt">4. Informed Consent</h3>
              <p class="small mb">
                Defining {{ "KEYWORD.treatment" | translate }} allows us to send the
                {{ "KEYWORD.patient" | translate }} various marketing materials and informed consent documentation
                including PDF, videos and images.
              </p>
            </div>
            <div class="infConsTreatCont" *ngFor="let consent of informedConsent; let index = index" @ngIfAnimation>
              <div class="row clearfix infConsTreat animate">
                <div class="row clearfix">
                  <h3 class="sr-title rm-m text-left"> {{"KEYWORD.Treatment" | translate}} {{ index + 1 }}</h3>
                  <app-treatment-informed-consent-create class="" [categoryID]="consent.categoryID" [isGlobal]="false"
                                                         [merchantID]="merchantID" [brandID]="consent.brandID"
                                                         [productID]="consent.productID"
                                                         [typeID]="consent.typeID"
                                                         [preSelectedDocuments]="consent.preDocuments"
                                                         [itemCode]="consent.itemCode"
                                                         [displayStockArtDocuments]="false"
                    [marketingSelectedDocuments]="consent.marketingDocuments"
                                                         [postSelectedDocuments]="consent.postDocuments"
                                                         [stockArtSelectedDocuments]="EPstockArtDocuments"
                                                         [displayPreDocuments]="true" [displayPostDocuments]="true"
                                                         [displayMarketingDocuments]="true"
                                                         [displayStockArtDocuments]="false" [selectPreDocuments]="true"
                                                         [selectPostDocuments]="true"
                                                         [selectMarketingDocuments]="true"
                                                         [selectStockArtDocuments]="true"
                                                         [showPreDocumentsModal]="false"
                                                         [showPostDocumentsModal]="false"
                                                         [showMarketingDocumentsModal]="false"
                                                         [showStockArtDocumentsModal]="true"
                                                         (getInformedConsent)="getInformedConsent($event, index)"
                    (clearInformedConsent)="clearInformedConsentEvent($event, index)"
                    (getStockArtDocuments)="getStockArtDocumentsEvent($event)"
                                                         (getStockArtModalOpened)="getStockArtModalOpened($event)">
                  </app-treatment-informed-consent-create>
                </div>
                <div class="buttons text-right">
                  <button class="mat-stroked-mini-fab mat-elevation-z1" *ngIf="informedConsent.length > 1"
                          (click)="removeConsent(index)" mat-stroked-button color="accent" matTooltip="Remove row">
                    <mat-icon class="fas fa-trash"></mat-icon>
                  </button>
                  <button class="mat-stroked-mini-fab mat-elevation-z1" *ngIf="informedConsent.length <= 1"
                          (click)="clearInformedConsent()" mat-stroked-button color="accent" matTooltip="Clear row">
                    <mat-icon class="fas fa-times"></mat-icon>
                  </button>
                  <button class="mat-stroked-mini-fab mat-elevation-z1" (click)="addConsent()" mat-stroked-button
                          color="primary" matTooltip="Add another row">
                    <mat-icon class="fas fa-plus"></mat-icon>
                  </button>
                </div>
                <hr class="mb" *ngIf="i >= 0"/>
              </div>
            </div>
            <button *ngIf="!informedConsent" (click)="addConsent()" mat-mini-fab color="primary"
                    matTooltip="Add another row">
              <mat-icon class="fas fa-plus"></mat-icon>
            </button>
          </div>
        </div>
      </div>
    </mat-card>

    <div class="row clearfix step2">
      <div class="full-width step-button-row text-center">
        <button class="step-back" (click)="stepBack()" mat-mini-fab color="primary">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <button class="btn-large" (click)="stepNext()" mat-raised-button color="accent">
          Next
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
  </aw-wizard-step>
  <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Schedule Delivery" awOptionalStep>
    <mat-card class="card treatment-card card1 text-center clearfix" id="simpleInvitation">
      <div class="colour-stripe"></div>
      <div class="row clearfix">
        <hr/>
      </div>
      <div class="row clearfix mt" *ngIf="isModuleCustomMessages == true">
        <div class="column clearfix">
          <div class="icons-col">
            <mat-icon>email</mat-icon>
          </div>
          <div class="row-content-col" id="defaultMes">
            <div class="row clearfix">
              <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb pull-left mr">
                Personal message to {{ "KEYWORD.patient" | translate }}
              </h3>
              <div class="clearfix full-width" *ngIf="
                  (isDefault != true && isPromoterOrAdmin != true) ||
                  (isPromoterOrAdmin && sDefault != true && selectedMerchants && selectedMerchants.length == 1)
                ">
                <mat-form-field class="margin-btm half-width" appearance="outline">
                  <mat-label>Please select an operator to preview default message</mat-label>
                  <mat-select class="" [(ngModel)]="contactID" (selectionChange)="changeContact($event.value)"
                              name="contactIDName" placeholder="Please Select Merchant">
                    <mat-option *ngFor="let m of contacts | orderBy: ['CalculatedName']" [value]="m['ID']">
                      {{ m['CalculatedName'] }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- <button mat-raised-button class="pull-right merchBtn" *ngIf="contactID " color="primary"
                  (click)="viewContact()">
                  <mat-icon>person</mat-icon> <span class="mobHide">{{"DentistView" | translate}}</span>
                </button> -->
              </div>

              <div class="clearfix full-width flex" *ngIf="isModuleCustomMessages == true">
                <mat-form-field class="full-width defaultMsg" appearance="outline">
                  <mat-label>Add Default Message</mat-label>
                  <mat-select [(ngModel)]="messageOption" (selectionChange)="defaultMessageChange(messageOption)"
                              name="messageOption" placeholder="Add Default Message">
                    <div class="clearfix flex flex-center mb">
                      <mat-button-toggle-group class="btn-group rm-m selectType full-width" [(ngModel)]="typeFilter"
                                               value="null">
                        <mat-button-toggle class="" [value]="null"> All</mat-button-toggle>

                        <mat-button-toggle class="" [value]="'0'">{{ "KEYWORD.practice" | translate |
                          titlecase}}</mat-button-toggle>

                        <mat-button-toggle class="" [value]="'1'">
                          {{ "BRANDING.Brand Name" | translate | titlecase }}
                        </mat-button-toggle>
                      </mat-button-toggle-group>
                    </div>

                    <mat-option *ngFor="
                        let option of defaultMessageLists | FilterArrayMultipleValue: ['IsPromoterTemplate']:typeFilter
                      " [value]="option">
                      <p class="small rm-mt rm-mb">
                        <span class="chipPromoter primary-bg pull-left mr"
                              *ngIf="option && option.IsPromoterTemplate == '1'">
                          <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
                        </span>

                        <span class="chipUser pull-left mr" *ngIf="option && option.IsPromoterTemplate != '1'">
                          <mat-icon class="fas fa-user-alt"></mat-icon>
                        </span>

                        {{ option.label }}
                      </p>
                    </mat-option>
                    <mat-divider></mat-divider>
                    <mat-option (click)="addNewMessage()">
                      <p class="small rm-mt rm-mb">
                        <mat-icon class="addnew">add</mat-icon>
                        Create new
                      </p>
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <button class="pull-right" (click)="launchAsModal('358964874', 1)" mat-icon-button color="primary"
                        matTooltip="What's this?">
                  <mat-icon class="fas fa-info-circle mat-primary"></mat-icon>
                </button>
              </div>
            </div>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Message</mat-label>
              <textarea class="dentMsg increase-input-field" [(ngModel)]="dentMsg"
                        (change)="updateRawContent($event.target.value)" name="dentMsg" matInput
                        placeholder="Enter your message here..."></textarea>
            </mat-form-field>
            <!-- {{convertedMessage}} -->
          </div>
        </div>
      </div>

      <div class="discount-create row clearfix" *ngIf="isModuleDiscountsActive == true">
        <div class="column clearfix">
          <div class="icons-col">
            <mat-icon class="fas fa-money-bill-wave" aria-hidden="true"></mat-icon>
          </div>

          <div class="row-content-col">
            <div class="row clearfix">
              <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb pull-left">Payment in full</h3>
            </div>

            <div class="row full-width clearfix" *ngIf="hideDiscount == true">
              <button class="pull-right ml btn-small"
                *ngIf="sessionType == 'admin' || sessionType == 'promoter' || sessionType == 'merchant-admin'"
                      (click)="openDiscountSettingDialog()" mat-raised-button color="accent" style="margin-top: 18px">
                <mat-icon>settings</mat-icon> Discount settings
              </button>
              <p *ngIf="sessionType == 'admin' || sessionType == 'promoter' || sessionType == 'merchant-admin'">
                Cash Discounts have been disabled in the setting area. To enable, click on the Discount Setting button.
              </p>

              <p *ngIf="sessionType != 'admin' && sessionType != 'promoter' && sessionType != 'merchant-admin'">
                Cash Discounts have been disabled in the setting area. To enable, Contact
                your {{"KEYWORD.practice" | translate}} administrator.
              </p>
            </div>
            <div class="row clearfix" *ngIf="hideDiscount != true">
              <mat-checkbox class="pull-left" [(ngModel)]="cashDiscountOffered" [userData]="'cashDiscountOffered'"
                            (change)="toggleChange($event.checked)" name="discount_toggle"><span class="text">Offer a discount
                  payment in full on first visit - cash/card</span></mat-checkbox>

              <!-- <button mat-icon-button color="primary" class="pull-right" matTooltip="What's this?"
                (click)="viewVideoInfo('Cash Discounts', 'vimeo.com/355935655/d2720c17e1')">
                <mat-icon class="fas fa-info-circle mat-primary"></mat-icon>
              </button> -->

              <button class="pull-right" (click)="launchAsModal('355935655', 4)" mat-icon-button color="primary"
                      matTooltip="What's this?">
                <mat-icon class="fas fa-info-circle mat-primary"></mat-icon>
              </button>
            </div>
            <mat-expansion-panel class="discountPanel" [expanded]="cashDiscountOffered == true">
              <div class="panel-content" *ngIf="cashDiscountOffered">
                <!-- <hr class="mb"> -->
                <div class="row clearfix mt">
                  <div class="column">
                    <mat-card class="mat-elevation-z6 box">
                      <div class="ribbon"><span>DISCOUNT</span></div>
                      <div class="row clearfix flex">
                        <div class="full-width">
                          <div class="col-left mb">
                            <label> {{"KEYWORD.Treatment" | translate}} value:</label>
                            <p class="lead rm-m">
                              <strong>{{ treatmentAmount | customCurrency }}</strong>
                            </p>
                          </div>
                        </div>
                        <div class="full-width">
                          <div class="column mb middle">
                            <label class="accent-color">Discount offered:</label>
                            <p class="lead rm-m accent-color">
                              <strong>{{ cashDiscountAmount | customCurrency }}</strong>
                            </p>
                          </div>
                        </div>
                        <div class="full-width" *ngIf="cashDiscountDate">
                          <div class="col-right mb">
                            <label>Expires:</label>
                            <p class="lead rm-m">
                              <strong>{{ cashDiscountDate | customDate }}</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="row clearfix button-row" *ngIf="cashDiscountAllowOverride == true">
                        <button class="pull-right btn-small" (click)="openDiscountInvitationDialog()" mat-raised-button
                                color="accent">
                          <mat-icon>edit</mat-icon> Modify Discount
                        </button>
                      </div>

                      <div class="row clearfix button-row" *ngIf="
                          (sessionType == 'admin' || sessionType == 'promoter' || sessionType == 'merchant-admin') &&
                          cashDiscountAllowOverride != true
                        ">
                        <button class="pull-right btn-small" (click)="openDiscountSettingDialog()" mat-raised-button
                                color="accent">
                          <mat-icon>settings</mat-icon> Discount settings
                        </button>
                      </div>

                      <!-- <div class="row clearfix button-row">
                        <button mat-raised-button color="accent" class="pull-right btn-small "
                          (click)="openDiscountSettingDialog()">
                          <mat-icon>settings</mat-icon> Discount settings
                        </button>
                      </div> -->
                    </mat-card>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </div>
        </div>
      </div>

      <div class="discount-create row clearfix mb" *ngIf="isModulePatientHistoryActive == true">
        <div class="column clearfix">
          <div class="icons-col">
            <mat-icon class="fas fas fa-notes-medical" aria-hidden="true"></mat-icon>
          </div>
          <div class="row-content-col">
            <div class="row clearfix">
              <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb pull-left">Health History</h3>
            </div>
            <mat-checkbox class="pull-left" [(ngModel)]="medicalHistory" name="discount_sstoggle5"><span class="text">
                Request that the {{ "KEYWORD.patient" | translate }} completes an online Health History form before
                attending the next appointment
              </span></mat-checkbox>
          </div>
        </div>
      </div>

      <div class="discount-create row clearfix mb">
        <div class="column clearfix">
          <div class="icons-col">
            <mat-icon class="fas fas fa-star" aria-hidden="true"></mat-icon>
          </div>
          <div class="row-content-col">
            <div class="row clearfix">
              <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb pull-left">Google Review</h3>
            </div>
            <mat-checkbox class="pull-left" [(ngModel)]="googleReviewPrompt" name="discount_toggle5"><span class="text">
                Prompt the user for a google review. </span></mat-checkbox>
          </div>
        </div>
      </div>

      <div class="discount-create row clearfix mb">
        <div class="column clearfix">
          <div class="icons-col">
            <mat-icon class="fas fa-tags" aria-hidden="true"></mat-icon>
          </div>
          <div class="row-content-col">
            <div class="clearfix mb full-width">
              <div class="row clearfix">
                <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb pull-left">Tags</h3>
              </div>
              <mat-checkbox class="pull-left" [(ngModel)]="attachTags" name="discount_togssgle5" matTooltip=" Group or categorise
              your {{ 'KEYWORD.patient' | translate }}s automatically. These then can be used throughout the app to
                filter {{ 'KEYWORD.patient' | translate }}s (marketing campaigns etc.)">
                <span class="text">
                  Choose the default tags to be applied to the {{ "KEYWORD.patient" | translate }} when using this
                  template.
                </span>
              </mat-checkbox>
            </div>
            <div class="clearfix full-width"
                 *ngIf="isDefault == true && isPromoterOrAdmin == true && attachTags == true">
              <app-tag-select [isModal]="false" [defaultSelectedTags]="defaultSelectedTagsGlobal"
                              [openPosition]="'above'" [title]="'Attach tags to this template'" [canRemove]="true"
                              [canCreate]="true"
                              [directRemoveDefault]="true" [directRemove]="true" [hasFilter]="true" [canSelect]="false"
                              (getSelectedTags)="getSelectedTagsGlobal($event)"></app-tag-select>
            </div>

            <div class="clearfix full-width"
                 *ngIf="(isDefault != true || isPromoterOrAdmin != true) && attachTags == true">
              <app-tag-select [isModal]="false" [defaultSelectedTags]="defaultSelectedTags" [merchantID]="merchantID"
                              [openPosition]="'above'" [title]="'Attach tags to this template'" [canRemove]="true"
                              [canCreate]="true"
                              [directRemoveDefault]="true" [directRemove]="true" [hasFilter]="true" [canSelect]="false"
                              (getSelectedTags)="getSelectedTags($event)">
              </app-tag-select>
            </div>
          </div>
        </div>
      </div>
    </mat-card>

    <div class="row clearfix step2">
      <div class="full-width step-button-row text-center">
        <button class="step-back" (click)="stepBack()" mat-mini-fab color="primary">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <button class="step-send" class="btn-large" (click)="save()" mat-raised-button color="accent">
          Save
          <mat-icon>send</mat-icon>
        </button>
      </div>
    </div>
  </aw-wizard-step>
  <aw-wizard-step [navigationSymbol]="{ symbol: '4' }" stepTitle="Done!" awOptionalStep>
    <div class="container" id="step-1" *ngIf="isDone == true">
      <div class="row clearfix">
        <mat-card class="card successful">
          <div class="row clearfix">
            <div class="full-width text-center">
              <h1 class="sm-h1 sr-title">Well Done!</h1>
              <h3 class="sm-h3 rm-mt">Your invitation template has been created.</h3>
              <div class="row clearfix">
                <div class="svg-box">
                  <svg class="circular green-stroke">
                    <circle class="path" cx="75" cy="75" r="50" fill="none" stroke-width="5" stroke-miterlimit="10" />
                  </svg>
                  <svg class="checkmark green-stroke">
                    <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                      <path class="checkmark__check" fill="none" d="M616.306,283.025L634.087,300.805L673.361,261.53" />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>

    <div class="row clearfix">
      <div class="full-width text-center step-button-row">
        <button class="full-back ml mr"
                [routerLink]="['/merchant/', { outlets: { page: ['treatment-saved-template'] } }]" mat-raised-button
                color="primary">
          <mat-icon>keyboard_arrow_left</mat-icon>
          Template List
        </button>
        <button class="full-back ml mr" (click)="view()" mat-raised-button color="accent">
          <mat-icon>visibility</mat-icon>
          view
        </button>
      </div>
    </div>
  </aw-wizard-step>
</aw-wizard>
