import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';

import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { MapMultipleViewComponent } from '../../../shared/components/map-multiple-view/map-multiple-view.component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { MerchantService } from '../shared/merchant.service';

@Component({
  selector: 'app-merchant-list-map',
  templateUrl: './merchant-list-map.component.html',
  styleUrls: ['./merchant-list-map.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class MerchantListMapComponent implements OnInit {
  @ViewChild(MapMultipleViewComponent, { static: false }) public mapComponent: MapMultipleViewComponent;

  icon = 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/red-dot.png';

  addMarkerEvent = new EventEmitter();

  isLoaded = false;
  merchants = [];
  serviceRef;

  _merchants = [];

  radius = 5000;
  showRadius = false;

  hasMapSearch = false;

  selectedItemEvent = new EventEmitter();
  p = 1;
  itemPageIndex = 0;
  filter;
  selectedItemIndex;
  selectedItem;

  description = 'Click on marker to view practice details';
  title = 'Practices';

  constructor(private merchantService: MerchantService, private router: Router) {}

  ngOnInit() {
    const payload = {
      section: 0,
      limit: 300,
      hasAdress: true,
      orderBy: 'CalculatedName',
      fields:
        'ID,addresses.Calculated,addresses.Suburb,Status,addresses.State,addresses.Postcode,addresses.Country.Code,' +
        'addresses.Unit,addresses.Street Nr,addresses.Street Name,addresses.Street Type,Address.Latitude,Address.Longitude,' +
        'CalculatedName',
    };

    this.serviceRef = this.merchantService.getList(payload, true).subscribe((res) => {
      if (res && res.length > 0) {
        this.isLoaded = true;
        this.merchants = res;

        for (let i = 0; i < this.merchants.length; i++) {
          // this.addMarkerEvent.emit(this.merchants[i])

          if (this.merchants[i]) {
            this.merchants[i].icon = this.icon;
            this.addMarkerEvent.emit(this.merchants[i]);
            this.getPicture(i);
          }
        }

        const innerFunction = (section) => {
          section = section + 1;
          UtilsClass.loadingDataSection(section);
          payload.section = section;

          this.serviceRef = this.merchantService.getList(payload, true).subscribe((res) => {
            if (res.length > 0) {
              this.merchants = this.merchants.concat(res);

              for (let i = res.length; i < this.merchants.length; i++) {
                if (this.merchants[i]) {
                  this.merchants[i].icon = this.icon;
                  this.addMarkerEvent.emit(this.merchants[i]);
                  this.getPicture(i);
                }
              }

              innerFunction(section);
            } else {
              return true;
            }
          });
          UtilsClass.stopLoadingInterceptor();
        };

        innerFunction(1);
      } else {
        this.isLoaded = true;
      }
    });
  }

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }

  getActionEvent(e) {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['merchant-list'],
        },
      },
    ]);
  }

  getPicture(i) {
    this.merchants[i]['loading'] = true;

    if (this.merchants[i]) {
      this.merchantService.getPracticePic(this.merchants[i]['ID']).subscribe((res) => {
        this.merchants[i]['loading'] = false;
        if (res) {
          this.merchants[i]['pic'] = res;
        }
      });
    }
  }

  getSelected(item) {
    if (item && item['ID']) {
      this.merchants.map((x, index) => {
        if (x && x['ID']) {
          if (x['ID'] == item['ID']) {
            this.selectedItemIndex = index;

            return index;
          }
        }
      });

      this.selectedItem = item;
      this.itemPageIndex = Math.trunc(this.selectedItemIndex / 10) + 1;
      this.pageChange(this.itemPageIndex);
    } else {
      this.pageChange(1);
      this.selectedItem = null;
      this.selectedItemIndex = null;
      this.itemPageIndex = null;
    }
  }

  addCoordinateEvent(item) {
    if (item && item['ID'] && item['latitude'] && item['longitude']) {
      const payload = {
        latitude: item['latitude'],
        longitude: item['longitude'],
      };
      this.merchantService.addCoordinate(item['ID'], payload).subscribe((res) => {});
    }
  }

  selectItem(item, index, pageIndex) {
    if (item && item['ID']) {
      this.selectedItemIndex = index;
      this.selectedItemEvent.emit(item);
      this.selectedItem = item;
      this.itemPageIndex = pageIndex;
      this.pageChange(pageIndex);
    }
  }

  pageChange(pageIndex) {
    this.p = pageIndex;
  }

  filterList(e) {
    if (e) {
      this.merchants = this.merchants.filter(
        (item) => -1 < item['CalculatedName'].toLowerCase().indexOf(e.toLowerCase())
      );
    }
  }

  getDocument(i, p) {
    i = (p - 1) * 10 + i;
    return this.merchants[i]['pic'];
  }

  getLoading(i, p) {
    i = (p - 1) * 10 + i;
    return this.merchants[i]['loading'];
  }

  closeSelectedItem() {
    this.pageChange(1);
    this.selectedItem = null;
    this.selectedItemIndex = null;
    this.itemPageIndex = null;
  }

  // radius circle adjust
  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'km';
    }

    return value;
  }

  // map control input

  practiceLocation() {
    this.mapComponent.changeToCurrentPractice();
  }

  myLocation() {
    this.mapComponent.changeToCurrent();
  }

  resetMap() {
    this.mapComponent.resetMap();
  }
}
