import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { HelperService } from '../shared/helper.service';

@Component({
  selector: 'app-template-social-media-view',
  templateUrl: './template-social-media-view.component.html',
  styleUrls: ['./template-social-media-view.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class TemplateSocialMediaViewComponent implements OnInit {
  isPromoterOrAdmin = false;

  postType = 'facebook';

  utils = new UtilsClass();
  templateTag;
  pinchZoomProperties = [
    {
      wheel: true,
      autoHeight: true,
    },
  ];

  loadingPost = false;

  postPicture;
  postText;
  postURL;

  template;

  settings = Settings.global;

  @Output()
  close = new EventEmitter();

  templateOverideID;

  maxLength;
  isModal = false;

  constructor(
    private utilService: UtilsService,
    private activeRoute: ActivatedRoute,
    private helperService: HelperService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.templateTag) {
        this.templateTag = data.templateTag;
        this.isModal = true;
      }
      if (data.postType) {
        this.postType = data.postType;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['templateTag']) {
        this.templateTag = params['templateTag'];
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        this.setupTemplate();
      });
    });
  }

  selectPostType(e) {
    if (e) {
      this.postType = e;

      this.getSocialMediaDetails();
    }
  }

  setupTemplate() {
    if (this.templateTag) {
      const campaignID = 'CAMPAIGN_ID';

      this.helperService
        .getTemplateOverideTemplatePromoter(this.templateTag, {}, this.isPromoterOrAdmin)
        .subscribe((res) => {
          if (res) {
            this.template = res;

            if (res.ID) {
              this.templateOverideID = res.ID;
            }

            if (!this.template.LandingPageCode) {
              const publicSite = this.settings['publicSiteFrontendLink'];
              if (publicSite && publicSite.indexOf('http') == -1) {
                this.template.LandingPageCode = 'https://' + this.settings['publicSiteFrontendLink'];
              } else if (publicSite && publicSite.indexOf('http') != -1) {
                this.template.LandingPageCode = this.settings['publicSiteFrontendLink'];
              }
            }

            if (!this.template.Tag) {
              NotifyAppComponent.displayToast('warning', 'Template not supported', 'Please choose another Campaign');
            } else {
              if (this.template && this.template.LandingPageCode) {
                this.postURL = this.utils.buildTemplateURL(this.template.LandingPageCode, campaignID);

                let publicSite = this.settings['publicSiteFrontendLink'];
                if (publicSite && publicSite.indexOf('http') == -1) {
                  publicSite = 'https://' + this.settings['publicSiteFrontendLink'];
                }

                if (this.template.LandingPageCode.indexOf('@ID@') != -1) {
                  this.postURL = publicSite + '/c/m/' + campaignID;
                } else if (
                  this.template.LandingPageCode.indexOf('http') != -1 ||
                  this.template.LandingPageCode.indexOf('https') != -1 ||
                  this.template.LandingPageCode.indexOf('wwww.') != -1
                ) {
                  this.utilService.getShortcutLinkByLink(this.template.LandingPageCode).subscribe((res) => {
                    if (res && res['_id']) {
                      this.postURL = publicSite + '/c/l/' + res['_id'];
                    } else {
                      this.postURL = this.template.LandingPageCode;
                    }
                  });
                } else if (this.template.LandingPageCode.indexOf('code:') != -1) {
                  this.postURL = publicSite + '/c/m/' + campaignID;
                } else {
                  this.postURL = publicSite + '/c/m/' + campaignID;
                }
              }
            }

            if (this.postType) {
              this.getSocialMediaDetails();
            }
          } else {
            NotifyAppComponent.displayToast('warning', 'Template not supported', 'Please choose another Campaign');
          }
        });
    }
  }

  getSocialMediaDetails() {
    if (this.postType && this.templateOverideID) {
      this.loadingPost = true;

      if (this.postType == 'facebook') {
        this.postText = this.template['Facebook_Post'];

        this.maxLength = 63206;
      } else if (this.postType == 'twitter') {
        this.postText = this.template['Twitter_Post'];
        this.maxLength = 140;
      } else if (this.postType == 'linkedin') {
        this.postText = this.template['LinkedIn_Post'];
        this.maxLength = 700;
      } else if (this.postType == 'instagram') {
        this.postText = this.template['Instagram_Post'];
        this.maxLength = 2200;
      }

      if (this.postURL) {
        if (this.postText) {
          this.postText = this.postText + ' Go to: ' + this.postURL;
        } else {
          this.postText = ' Go to: ' + this.postURL;
        }
      }

      this.helperService.getTemplateOverideSocialMedia(this.templateOverideID, this.postType).subscribe((res) => {
        if (res) {
          this.postPicture = res;
        } else {
          this.postPicture = null;
        }

        this.loadingPost = false;
      });
    }
  }

  download(url, name) {
    const link = document.createElement('a');
    link.download = name;
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  clickCopy() {
    NotifyAppComponent.displayToast('success', 'Text copied', 'The message text has been copied to your clipboard');
  }

  closeModal() {
    this.close.emit(true);
  }
}
