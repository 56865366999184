/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contract-list-active.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../contract-list/contract-list.component.ngfactory";
import * as i3 from "../../../shared/pipes/custom-date.pipe";
import * as i4 from "../contract-list/contract-list.component";
import * as i5 from "../shared/contract.service";
import * as i6 from "@angular/material/dialog";
import * as i7 from "@angular/router";
import * as i8 from "../../../core/authentication/shared/authentication.service";
import * as i9 from "../../../shared/pipes/custom-phone.pipe";
import * as i10 from "./contract-list-active.component";
var styles_ContractListActiveComponent = [i0.styles];
var RenderType_ContractListActiveComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContractListActiveComponent, data: {} });
export { RenderType_ContractListActiveComponent as RenderType_ContractListActiveComponent };
export function View_ContractListActiveComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-contract-list", [], null, null, null, i2.View_ContractListComponent_0, i2.RenderType_ContractListComponent)), i1.ɵprd(512, null, i3.CustomDatePipe, i3.CustomDatePipe, []), i1.ɵdid(2, 770048, null, 0, i4.ContractListComponent, [i5.ContractService, i6.MatDialog, i7.Router, i3.CustomDatePipe, i8.AuthenticationService, i9.CustomPhonePipe], { approvedOrActive: [0, "approvedOrActive"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeContracts; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ContractListActiveComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contract-list-active", [], null, null, null, View_ContractListActiveComponent_0, RenderType_ContractListActiveComponent)), i1.ɵdid(1, 114688, null, 0, i10.ContractListActiveComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContractListActiveComponentNgFactory = i1.ɵccf("app-contract-list-active", i10.ContractListActiveComponent, View_ContractListActiveComponent_Host_0, {}, {}, []);
export { ContractListActiveComponentNgFactory as ContractListActiveComponentNgFactory };
