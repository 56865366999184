import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import hexRgb from 'hex-rgb';
import { ConfirmDialogSingleButtonComponent } from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ColorPalette } from '../../../shared/types/color-palette';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { SubscriptionPlanService } from '../shared/subscription-plan.service';
import { SubscriptionPlanCongratulationComponent } from '../subscription-plan-congratulation/subscription-plan-congratulation.component';
import { SubscriptionPlanViewModalComponent } from '../subscription-plan-view-modal/subscription-plan-view-modal.component';

import * as $ from 'jquery';
import { SubscriptionPlanCalculatorComponent } from '../subscription-plan-calculator/subscription-plan-calculator.component';

@Component({
  selector: 'app-subscription-plan-card',
  templateUrl: './subscription-plan-card.component.html',
  styleUrls: ['./subscription-plan-card.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 0 })),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),

        group([animate(300, style({ height: 0 })), animate('200ms ease-in-out', style({ opacity: '0' }))]),
      ]),
      transition(':enter', [
        style({ height: '0', opacity: 0 }),

        group([animate(300, style({ height: '*' })), animate('400ms ease-in-out', style({ opacity: '1' }))]),
      ]),
    ]),
  ],
})
export class SubscriptionPlanCardComponent implements OnInit {
  @Input()
  subscriptionPlanID;
  @Input()
  ids;
  @Input()
  subscriptionPlan;

  @Input()
  canCheck = false;

  @Input()
  canClose = true;

  @Input()
  isClosed = false;
  isDefault = false;
  isPublic = true;

  @Input()
  isSubscriptionButton = false;

  @Input()
  isSelectButton = false;
  @Input()
  selectTitle = 'SELECT';

  root = '/merchant';
  @Input()
  isViewButton = true;
  @Output()
  getCheck = new EventEmitter();

  @Output()
  getUncheck = new EventEmitter();
  @Output()
  getRemove = new EventEmitter();
  @Output()
  getSelect = new EventEmitter();
  @Output()
  getEdit = new EventEmitter();
  @Output()
  getSubscription = new EventEmitter();

  @Input()
  customizationOnly = false;

  @Input()
  isActionButton = false;

  postPictureThumbnail;
  isActive = true;
  price;
  label;
  description;
  waivedPeriod = 0;
  minTreatmentPlan = 0;
  minTreatmentPlanMerchant;
  smsPrice = 0;
  isFreeSMS = false;
  marketingDescriptions = [];

  utils = new UtilsClass();
  @Input()
  color = Settings.global['primaryColor'] || '#1e88e5';
  @Input()
  colorSecond;
  isRecommended = false;

  isPromoterOrAdmin = false;

  sessionType;
  currentPlanID;
  isPlanOwn = false;
  isChecked = false;

  @Input()
  isSignupButton = false;

  subscriptionDate;
  @Input()
  featureType = 'all';

  constructor(
    private subscriptionPlanService: SubscriptionPlanService,
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['subscriptionPlanID']) {
        this.subscriptionPlanID = params['subscriptionPlanID'];
      }
      this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
        this.isPromoterOrAdmin = isPromoterOrAdmin;

        this.authenticationService.getSessionType().subscribe((st) => {
          this.sessionType = st;

          this.setup();
        });
      });
    });

    $(window).resize(() => {
      const width = $(window).width();
      if (width > 900) {
        this.calculHeight();
      } else {
        $('.container-main-info').height('auto');
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setup();
  }

  setup() {
    if (this.featureType === 'consumer') {
      this.root = '/consumer';
    } else {
      this.root = '/merchant';
    }
    this.authenticationService.getCurrentPlan().subscribe((currentPlanID) => {
      if (currentPlanID && currentPlanID) {
        this.currentPlanID = currentPlanID;
      }

      if (this.subscriptionPlan && this.subscriptionPlan.ID) {
        this.buildItem();
      } else if (this.subscriptionPlanID && this.subscriptionPlanID !== 'current') {
        this.subscriptionPlanService
          .getPromoterPlanDetails(this.subscriptionPlanID, {}, this.sessionType)
          .subscribe((res) => {
            if (res) {
              this.subscriptionPlan = res;

              this.buildItem();
            }
          });
      } else if (this.color && !this.colorSecond) {
        this.onColorPickerClose(this.color);
      }
    });
  }

  buildItem() {
    if (this.subscriptionPlan && this.subscriptionPlan.ID) {
      if (this.currentPlanID && this.subscriptionPlan.ID == this.currentPlanID) {
        this.isPlanOwn = true;
      } else {
        this.isPlanOwn = false;
      }
      if (this.subscriptionPlan['Color']) {
        const split = this.subscriptionPlan['Color'].split('|');
        if (split[0]) {
          this.color = split[0];
        }
        if (split[1]) {
          this.colorSecond = split[1] || null;
        }
        if (this.color && !this.colorSecond) {
          this.onColorPickerClose(this.color);
        }
      } else if (this.color && !this.colorSecond) {
        this.onColorPickerClose(this.color);
      }

      this.postPictureThumbnail = this.subscriptionPlanService.getPromoterPlanThumbnailImageStreamLink(
        this.subscriptionPlan.ID,
        this.subscriptionPlan.LastModified
      );

      if (this.subscriptionPlan && this.subscriptionPlan.Marketing_Description) {
        this.marketingDescriptions = this.subscriptionPlan.Marketing_Description.split('||');
      }
      if (this.subscriptionPlan['SMS_Price']) {
        this.smsPrice = this.subscriptionPlan['SMS_Price'];
      }
      if (this.subscriptionPlan['subscription_date']) {
        this.subscriptionDate = this.subscriptionPlan['subscription_date'];
      }

      if (this.subscriptionPlan['Is_SMS_Free'] === '1') {
        this.isFreeSMS = true;
      } else {
        this.isFreeSMS = false;
      }

      if (this.subscriptionPlan['Is_Public'] != '1') {
        this.isPublic = false;
      } else {
        this.isPublic = true;
      }

      if (this.subscriptionPlan['Is_Default'] == '1') {
        this.isDefault = true;
      } else {
        this.isDefault = false;
      }

      if (this.subscriptionPlan['Is_Active'] != '1') {
        this.isActive = false;
      } else {
        this.isActive = true;
      }

      if (this.subscriptionPlan['Waived_PeriodInDays']) {
        this.waivedPeriod = this.subscriptionPlan['Waived_PeriodInDays'];
      }

      if (this.subscriptionPlan['Min_TreatmentPlans_Sent']) {
        this.minTreatmentPlan = this.subscriptionPlan['Min_TreatmentPlans_Sent'];
      }

      if (this.subscriptionPlan['Min_TreatmentPlans_Sent_Merchant']) {
        this.minTreatmentPlanMerchant = this.subscriptionPlan['Min_TreatmentPlans_Sent_Merchant'];
      } else {
        this.minTreatmentPlanMerchant = null;
      }

      if (this.subscriptionPlan['Recommended_Plan'] === 'Yes') {
        this.isRecommended = true;
      } else {
        this.isRecommended = false;
      }

      if (this.subscriptionPlan['Price']) {
        this.price = this.subscriptionPlan['Price'];
      }

      if (this.subscriptionPlan['Web_Label']) {
        this.label = this.subscriptionPlan['Web_Label'];
      }
      if (this.subscriptionPlan['Web_Description']) {
        this.description = this.subscriptionPlan['Web_Description'];
      }
    }

    const width = $(window).width();
    if (width > 900) {
      this.calculHeight();
    } else {
      $('.container-main-info').height('auto');
    }
  }

  onColorPickerClose(color: string): void {
    if (color) {
      this.color = color;

      const v = new ColorPalette(color);
      const palette = v.getPalette();

      if (palette && palette.length > 0) {
        const index = palette.findIndex((item) => {
          if (item && item.name == 800) {
            return true;
          } else {
            return false;
          }
        });

        if (palette[index] && palette[index]['hex']) {
          this.colorSecond = palette[index]['hex'];
        }
      }
    }
  }

  moreWaivedDetails() {
    const confirmDialog2 = new ConfirmDialogSingleButton(
      'fas fa-info',
      'Waived period',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' +
        ' Fermentum et sollicitudin ac orci phasellus egestas. Eget lorem dolor sed viverra. Nunc lobortis mattis aliquam faucibus purus in massa tempor.',
      'OKAY'
    );
    const ref2 = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
      data: confirmDialog2,
      width: '500px',
    });
    ref2.componentInstance.onConfirm.subscribe((res) => {
      ref2.close();
    });
  }

  moreMinTreatmentDetails() {
    const confirmDialog2 = new ConfirmDialogSingleButton(
      'fas fa-info',
      'Minimum  required treatment',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' +
        ' Fermentum et sollicitudin ac orci phasellus egestas. Eget lorem dolor sed viverra. Nunc lobortis mattis aliquam faucibus purus in massa tempor.',
      'OKAY'
    );
    const ref2 = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
      data: confirmDialog2,
      width: '500px',
    });
    ref2.componentInstance.onConfirm.subscribe((res) => {
      ref2.close();
    });
  }

  buildShadowBox(color) {
    if (color) {
      const rbg = hexRgb(color);

      if (rbg) {
        return `5px 4px 10px 0px rgb(${rbg.red}  ${rbg.green} ${rbg.blue} / 30%), 0px 1px 6px 0px rgb(${rbg.red}  ${rbg.green} ${rbg.blue} / 13%), -10px 5px 10px 0px rgb(${rbg.red}  ${rbg.green} ${rbg.blue} / 20%)`;
      } else {
        return null;
      }
    }
  }

  changeCloseEvent() {
    if (this.canClose == true) {
      this.isClosed = !this.isClosed;
    }
  }

  changeClose(d) {
    this.isClosed = d;
  }

  viewSubPlanModal(ID) {
    if (ID) {
      const ref = RootAppComponent.dialog.open(SubscriptionPlanViewModalComponent, {
        data: {
          subscriptionPlanID: ID,
          ids: this.ids,
          displaySubscribeButton: false,
          featureType: this.featureType,
        },
        width: '1000px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res) {
          ref.close();
        }
      });
    }
  }

  edit(id) {
    if (id) {
      this.router.navigate([
        this.root,
        {
          outlets: {
            page: ['subscription-plan-create-edit', id],
          },
        },
      ]);
    }
  }

  view(id) {
    if (id) {
      this.router.navigate([
        this.root,
        {
          outlets: {
            page: ['subscription-plan-view', id, this.featureType],
          },
        },
      ]);
    }
  }

  select(id) {
    if (id) {
      this.getSelect.emit(id);
    }
  }

  check(id) {
    if (id) {
      if (this.isChecked == true) {
        this.getCheck.emit(id);
      } else {
        this.getUncheck.emit(id);
      }
    }
  }

  promote(id) {
    if (id) {
      const confirm = new ConfirmDialog(
        'fas fa-bullhorn',
        '',
        'Withen 10 mins, all connected user will recieve a notification to check their plans, Do you want to continue ?',
        'No',
        'Yes'
      );
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.subscriptionPlanService.editPromoterPlan(id, { sendUpdate: true }).subscribe((res) => {
            if (res) {
              NotifyAppComponent.displayToast('success', 'Success!', 'Notification Sent ');
              ref.close();
            }
          });
        }
      });
    }
  }

  remove(id) {
    if (id) {
      const confirm = new ConfirmDialog(
        'delete',
        '',
        'Are you sure you want to delete this subscription plan ?',
        'No',
        'Yes'
      );
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.subscriptionPlanService.deletePromoterPlan(id).subscribe((res) => {
            if (res) {
              this.getRemove.emit(id);

              this.subscriptionPlan = null;

              NotifyAppComponent.displayToast('success', 'Success!', 'Subscription plan label is removed ');
              ref.close();
            }
          });
        }
      });
    }
  }

  subscribe(ID) {
    if (ID) {
      const ref = RootAppComponent.dialog.open(SubscriptionPlanViewModalComponent, {
        data: {
          subscriptionPlanID: ID,
          ids: this.ids,
          featureType: this.featureType,
        },
        width: '1000px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res) {
          ref.close();
        }
      });

      ref.componentInstance.getPlan.subscribe((res) => {
        if (res && res.SubscriptionPlan_key) {
          ref.close();

          const ref2 = RootAppComponent.dialog.open(SubscriptionPlanCongratulationComponent, {
            width: '1000px',
            height: '600px',
            data: {
              subscriptionPlanID: res.SubscriptionPlan_key,
            },
            panelClass: 'noCard',
          });

          ref2.componentInstance.close.subscribe((res) => {
            ref2.close();
          });
        }
      });
    }
  }

  calculHeight() {
    setTimeout(() => {
      let max = 350;
      if ($('.container-main-info') && $('.container-main-info').length > 1) {
        $('.container-main-info').map(function () {
          if ($(this) && $(this).height() && $(this).height() > max) {
            max = $(this).height();
          }
        });

        $('.container-main-info').css({ minHeight: max + 'px' });
        $('.container-main-info').height(max);
      } else {
        $('.container-main-info').css({ minHeight: 'auto' });
        $('.container-main-info').height('auto');
      }
    }, 1000);
  }

  freeSub(id) {
    const ref2 = RootAppComponent.dialog.open(SubscriptionPlanCalculatorComponent, {
      data: {
        subscriptionPlanID: id,
      },

      panelClass: 'noCard',
      height: '1100px',
    });
    ref2.componentInstance.close.subscribe((res) => {
      ref2.close();
    });
  }
}
