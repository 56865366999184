<div class="row clearfix">
  <div class="pull-right" *ngIf="isModal == true">
    <button class="btn-close btn-clear pull-right mat-button" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <mat-card class="toggle-card mt mb clearfix mat-elevation-z6" *ngIf="target">
    <div class="row clearfix">
      <mat-button-toggle-group
        class="btn-group row clearfix flex full-width text-center"
        [(ngModel)]="type"
        (change)="setup()"
      >
        <mat-button-toggle class="text-center full-width" *ngFor="let s of types" [value]="s">
          {{ s | lowercase | ucfirst }}
        </mat-button-toggle>
      </mat-button-toggle-group>
      <hr class="mb"/>
    </div>
  </mat-card>

  <div class="row clearfix" *ngIf="type">
    <div *ngIf="type == 'contract' && contractEventLog && ctcCombinedLog && emptyList != true">
      <div class="pull-right mr">
        <button class="" (click)="refresh()" mat-raised-button color="primary">Refresh</button>
      </div>
      <div class="row clearfix flex">
        <div class="row clearfix full-width" *ngIf="largeHeading == true">
          <h1 class="rm-mt">
            View events
            <span class="badge">
              <mat-icon>new_releases</mat-icon>
              <span [endVal]="ctcCombinedLog.length" countUp></span>
            </span>
          </h1>
        </div>

        <div class="row clearfix full-width" *ngIf="largeHeading == false">
          <div class="colour-stripe"></div>
          <h3 class="sr-title sm-h3 rm-m">
            View events
            <span class="badge">
              <span [endVal]="ctcCombinedLog.length" countUp></span>
            </span>
          </h3>
          <hr/>
        </div>

        <div class="row clearfix flex-center flex clearfix full-width">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Filter</mat-label>
            <input name="filter" matInput placeholder="Filter by date, type or label"/>
          </mat-form-field>

          <!-- <mat-button-toggle-group class="btn-group full-width rm-mt" [(ngModel)]="typeFilter" (change)="setFilter()" *ngIf="isPromoterOrAdmin==true">
          <mat-button-toggle [value]="'none'">
            {{"All" | translate}}
          </mat-button-toggle>
          <mat-button-toggle [value]="'manual'">
            Manual
          </mat-button-toggle>
          <mat-button-toggle [value]="'auto'">
            Auto
          </mat-button-toggle>
          <mat-button-toggle [value]="'internal'">
            Internal
          </mat-button-toggle>
        </mat-button-toggle-group> -->
        </div>
      </div>

      <vertical-timeline [@stagger]="ctcCombinedLog.length">
        <vertical-timeline-card
          class="contract-event"
          *ngFor="let c of ctcCombinedLog"
          [@fade]
          [color]="actionLogUtil.getTypeColor(c['Action'])"
          [dateValue]="c['DateTimeCreated'] | convertDate"
          [timeString]="c['DateTimeCreated'] | customDateTime: 'HH:mm'"
        >
          <button class="pull-right actionAction" [matMenuTriggerFor]="menu" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button (click)="viewEventLog(c['ID'], c['Event.Label'])" mat-menu-item>
              <mat-icon>insert_chart_outlined</mat-icon>
              <span *ngIf="c['Event.Label']">View Event Log</span>
              <span *ngIf="c['Event.Label'] == null">View Action Log</span>
            </button>
          </mat-menu>

          <mat-accordion>
            <mat-expansion-panel [expanded]="isExpanded">
              <mat-expansion-panel-header>
                <h3 *ngIf="c['Event.Label']"><span class="badge eventBadge">Event</span>{{ c['Event.Label'] }}</h3>
                <h3 *ngIf="c['Event.Label'] == null">
                  <mat-icon class="fas" [ngClass]="actionLogUtil.getSubjectIcon(c['Action'], c['Subject'])"></mat-icon>
                  <span class="badge ml">{{ c['Action'] }}</span> {{ c['Subject'] }}
                </h3>
              </mat-expansion-panel-header>

              <div *ngIf="c['Event.Label']">
                <div class="row clearfix">
                  <div class="half-width">
                    <div class="col-left">
                      <p class="small">
                        <strong>This Event taken: </strong>{{ c['ElapsedSince.PreviousEvent.Total'] }} seconds
                      </p>
                      <p class="small">
                        <strong>Total Time taken: </strong>{{ c['ElapsedSince.Milestone1.Total'] }} seconds
                      </p>
                    </div>
                  </div>
                  <div class="half-width">
                    <div class="col-right">
                      <p class="small"><strong>Contract Status: </strong>{{ c['Contract.Status.Label'] }}</p>
                      <p class="small" *ngIf="c['PreviousEvent.Label']">
                        <strong>Previous Step: </strong>{{ c['PreviousEvent.Label'] }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="c['Event.Label'] == null">
                <div class="row clearfix">
                  <p class="small"><strong>Operator: </strong>{{ c['Operator_Name'] }}</p>
                </div>
                <div class="row clearfix">
                  <p class="small"><strong>Description: </strong>{{ c['Description'] }}</p>
                </div>
                <div class="row clearfix">
                  <p class="small"><strong>Action: </strong>{{ c['Action'] }}</p>
                </div>

                <div class="row clearfix">
                  <p class="small"><strong>Subject: </strong>{{ c['Subject'] }}</p>
                </div>
                <div class="row clearfix">
                  <p class="small"><strong>Result: </strong>{{ c['Result'] }}</p>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </vertical-timeline-card>
      </vertical-timeline>
    </div>
  </div>
  <mat-dialog-content>
    <app-empty-list-message
      class="full-width row clearfix"
      *ngIf="emptyList == true"
      [title]="''"
      [message]="' No log found'"
    ></app-empty-list-message>
  </mat-dialog-content>
</div>
