import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GoogleReviewReplyDialogComponent } from '../dialogs/google-review-reply-dialog/google-review-reply-dialog.component';
import { GoogleReviewsBusinessSettingsDialogComponent } from '../dialogs/google-reviews-business-settings-dialog/google-reviews-business-settings-dialog.component';
import { GoogleReviewsPromptDialogComponent } from '../dialogs/google-reviews-prompt-dialog-modal/google-reviews-prompt-dialog.component';
import { GoogleReviewsSignInDialogComponent } from '../dialogs/google-reviews-sign-in-dialog/google-reviews-sign-in-dialog.component';
import { GoogleReviewListComponent } from '../google-review-list/google-review-list.component';
import { GoogleReviewComponent } from '../google-review/google-review.component';
import { GoogleReviewsConfigComponent } from '../google-reviews-config/google-reviews-config.component';

const routes: Routes = [
  {
    path: 'google-reviews-config',
    component: GoogleReviewsConfigComponent,
  },

  {
    path: 'google-review-list',
    component: GoogleReviewListComponent,
  },

  {
    path: 'google-review',
    component: GoogleReviewComponent,
  },

  {
    path: 'google-review-reply-dialog',
    component: GoogleReviewReplyDialogComponent,
  },

  {
    path: 'google-reviews-business-settings-dialog',
    component: GoogleReviewsBusinessSettingsDialogComponent,
  },

  {
    path: 'google-reviews-prompt-dialog',
    component: GoogleReviewsPromptDialogComponent,
  },

  {
    path: 'google-reviews-sign-in-dialog',
    component: GoogleReviewsSignInDialogComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GoogleReviewsRoutingModule {}
