import { Location } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { DentistService } from '../shared/dentist.service';

import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { environment } from 'environments/environment';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dentist-invite-create',
  templateUrl: './dentist-invite-create.component.html',
  styleUrls: ['./dentist-invite-create.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class DentistInviteCreateComponent implements OnInit {
  validateMobile = false;
  validatePhone = false;
  validateHomePhone = false;
  validateEmail = false;
  @Output()
  getResult = new EventEmitter();
  isFile = false;
  src;

  @Output()
  close = new EventEmitter();
  isModal = false;

  isAdressValid = false;

  isPromoterOrAdmin;

  merchants = [];
  selectedMerchant;
  merchantID;

  profileLink;

  genders = [];

  titles = [];

  contactTypeCodes = [];

  staff = {
    firstName: null,
    midleName: null,
    lastName: null,
    email: null,
    homePhone: null,
    phone: null,
    contactTypeCode: null,
    mobile: null,
    gender: null,
    title: null,
    tradingAs: null,
    streetName: null,
    streetNumber: null,
    streetType: null,
    unitNumber: null,
    suburb: null,
    state: null,
    postCode: null,
    fileID: null,
    isOperator: true,
    userName: null,
    latitude: null,
    longitude: null,
  };

  hide = true;

  dentistID;
  isEdit = false;

  displayEditOperator = true;

  sessionType = 'merchant';
  createdContact;
  @ViewChild('wizard', { static: false }) wizard: WizardComponent;

  constructor(
    private activeRoute: ActivatedRoute,
    private merchantService: MerchantService,
    private location: Location,
    private dentistService: DentistService,
    private lookupService: LookupService,
    private authenticationService: AuthenticationService,
    private utilsService: UtilsService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && data.merchantID) {
      this.merchantID = data.merchantID;
    }

    if (data && data.contactID) {
      this.dentistID = data.contactID;
    } else if (data && data.dentistID) {
      this.dentistID = data.dentistID;
    }
    if (data && data.isModal === true) {
      this.isModal = true;
    } else if (data && data.isModal === false) {
      this.isModal = false;
    }
    if (data && data.displayEditOperator === true) {
      this.displayEditOperator = true;
    } else if (data && data.displayEditOperator === false) {
      this.displayEditOperator = false;
    }

  }
  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['dentistID']) {
        this.dentistID = params['dentistID'];
      }
      this.authenticationService.getSessionType().subscribe((sessionType) => {
        if (sessionType) {
          this.sessionType = sessionType;
        }

        if (this.dentistID) {
          this.dentistService
            .getContactDetails(this.dentistID, { selectOperator: true }, this.sessionType)
            .subscribe((resDentist) => {
              if (resDentist) {
                this.isEdit = true;

                this.staff.firstName = resDentist.FirstName;
                this.staff.midleName = resDentist.MiddleName;
                this.staff.lastName = resDentist.Name;
                this.staff.email = resDentist['emails.Email'];
                this.staff.homePhone = resDentist['Phone.Home.Number'];
                this.staff.phone = resDentist['Phone.Work.Number'];
                this.staff.contactTypeCode = resDentist['ContactType.Code'];
                this.staff.mobile = resDentist['mobiles.Number'];
                this.staff.gender = resDentist['Gender.Code'];
                this.staff.title = resDentist['Salutation'];
                this.staff.streetName = resDentist['addresses.Street Name'];
                this.staff.streetNumber = resDentist['addresses.Street Nr'];
                this.staff.streetType = resDentist['addresses.Street Type'];
                this.staff.unitNumber = resDentist['addresses.Unit'];
                this.staff.suburb = resDentist['addresses.Suburb'];
                this.staff.state = resDentist['addresses.State'];
                this.staff.latitude = resDentist['Address.Latitude'];
                this.staff.longitude = resDentist['Address.Longitude'];
                this.staff.postCode = resDentist['addresses.Postcode'];

                if (resDentist && resDentist.hasOperator === true) {
                  this.displayEditOperator = false;
                }
              }
            });
        }

        this.setup();

        this.lookupService.getLookup('SimpleLookup', 'Salutation').subscribe((res) => {
          this.titles = res;
        });

        this.lookupService.getLookup('CodeLookup', 'Gender').subscribe((res) => {
          this.genders = res;
        });

        this.lookupService.getLookup('CodeLookup', 'ContactType').subscribe((res) => {
          this.contactTypeCodes = res;
        });
      });
    });
  }

  setup() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      if (this.isPromoterOrAdmin == true) {
        const payload = {
          fields: 'ID,TradingAs,CalculatedName,Status',
        };
        this.merchantService.getList(payload, this.isPromoterOrAdmin).subscribe((res) => {
          this.merchants = res;
        });
      }
    });
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  sendApplication() {
    let payload: any = {};
    if (this.staff.isOperator == false) {
      payload = {
        firstName: this.staff.firstName,
        midleName: this.staff.midleName,
        lastName: this.staff.lastName,
        email: this.staff.email,
        homePhone: this.staff.homePhone,
        phone: this.staff.phone,
        contactTypeCode: this.staff.contactTypeCode,
        mobile: this.staff.mobile,
        tradingAs: this.staff.tradingAs,
        gender: this.staff.gender,
        title: this.staff.title,
        streetName: this.staff.streetName,
        streetNumber: this.staff.streetNumber,
        streetType: this.staff.streetType,
        unitNumber: this.staff.unitNumber,
        suburb: this.staff.suburb,
        state: this.staff.state,
        latitude: this.staff.latitude,
        longitude: this.staff.longitude,
        postCode: this.staff.postCode,
        fileID: this.staff.fileID,
        isOperator: this.staff.isOperator,
        generatePassword: true,
      };
    } else {
      payload = {
        firstName: this.staff.firstName,
        midleName: this.staff.midleName,
        lastName: this.staff.lastName,
        email: this.staff.email,
        homePhone: this.staff.homePhone,
        phone: this.staff.phone,
        contactTypeCode: this.staff.contactTypeCode,
        mobile: this.staff.mobile,
        tradingAs: this.staff.tradingAs,
        gender: this.staff.gender,
        title: this.staff.title,
        streetName: this.staff.streetName,
        streetNumber: this.staff.streetNumber,
        streetType: this.staff.streetType,
        unitNumber: this.staff.unitNumber,
        suburb: this.staff.suburb,
        state: this.staff.state,
        latitude: this.staff.latitude,
        longitude: this.staff.longitude,
        postCode: this.staff.postCode,
        fileID: this.staff.fileID,
        isOperator: this.staff.isOperator,
        userName: this.staff.userName,
        generatePassword: true,
      };
    }

    if (this.displayEditOperator === false) {
      payload.isOperator = null;
      payload.generatePassword = null;
      payload.userName = null;
    }

    if (this.isPromoterOrAdmin && this.selectedMerchant) {
      payload['merchantID'] = this.selectedMerchant;
    }

    if (this.isEdit == true) {
      this.dentistService.modifyContact(this.dentistID, payload, this.sessionType).subscribe((res) => {
        if (res) {
          this.createdContact = res;

          this.createdContact.tableName = 'Contact';
          this.createdContact.TableName = 'Contact';
          if (this.isModal == true) {
            this.getResult.emit(this.createdContact);
          } else {
            this.wizard.goToNextStep();
          }
        }
      });
    } else {
      this.dentistService.createContact(payload, this.sessionType).subscribe((res) => {
        if (res) {
          this.createdContact = res;

          this.createdContact.tableName = 'Contact';
          this.createdContact.TableName = 'Contact';

          if (this.isModal == true) {
            this.getResult.emit(this.createdContact);
          } else {
            this.wizard.goToNextStep();
          }
        }
      });
    }
  }

  getMobile(m) {
    this.staff.mobile = m;
  }

  getPhone(p) {
    this.staff.phone = p;
  }

  getHomePhone(p) {
    this.staff.homePhone = p;
  }

  getEmail(e) {
    this.staff.email = e;

    if (!this.staff.userName) {
      this.staff.userName = this.staff.email;
    }
  }

  generateRandomString() {
    const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';
    const specialCharacters = '!?$@#';

    let password = '';

    // Add one uppercase letter
    password += uppercaseLetters[Math.floor(Math.random() * uppercaseLetters.length)];

    // Add four numbers
    for (let i = 0; i < 4; i++) {
      password += numbers[Math.floor(Math.random() * numbers.length)];
    }

    // Add one special character
    password += specialCharacters[Math.floor(Math.random() * specialCharacters.length)];

    // Add one lowercase letter
    password += lowercaseLetters[Math.floor(Math.random() * lowercaseLetters.length)];

    // Fill the remaining characters with random letters and numbers
    for (let i = password.length; i < 14; i++) {
      const allCharacters = uppercaseLetters + lowercaseLetters + numbers;
      password += allCharacters[Math.floor(Math.random() * allCharacters.length)];
    }

    // Shuffle the password string
    password = password
      .split('')
      .sort(() => Math.random() - 0.5)
      .join('');

    return password;
  }

  validateMobileEvent(v) {
    this.validateMobile = v;
  }

  validatePhoneEvent(v) {
    this.validatePhone = v;
  }

  validateHomePhoneEvent(v) {
    this.validateHomePhone = v;
  }

  validateEmailEvent(v) {
    this.validateEmail = v;
  }

  getAdress(adr) {
    if (adr) {
      this.staff.suburb = adr.suburb;
      this.staff.state = adr.state;
      this.staff.postCode = adr.postCode;
      this.staff.streetNumber = adr.streetNumber;
      this.staff.streetName = adr.streetName;
      this.staff.unitNumber = adr.unitNumber;
      this.staff.streetType = adr.streetType;
      this.staff.latitude = adr.latitude;
      this.staff.longitude = adr.longitude;
    }
  }

  isAdressValidEvent(v) {
    this.isAdressValid = v || false;
  }


  contact() {
    const data = {
      targetType: 'contact',
      targetID: this.createdContact.ID,
      asGuest: false,
      asProfile: true,
      asPractice: true,
      subjectArray: [],
    };
    AuthenticationService.contactInputEvent.emit(data);
  }

  viewMerchant() {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: this.merchantID,
      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  onCompleteAll(fileID) {
    if (fileID && fileID[0]) {
      this.staff.fileID = fileID[0];
      this.changeImage();
    }
  }

  onCompleteAllEditMode(fileID) {
    if (fileID && fileID[0]) {
      const payload = {
        fileID: fileID[0],
      };
      this.dentistService.uploadProfilePic(this.dentistID, payload).subscribe((res) => {
        this.getProfilePicture();
      });
    }
  }

  changeImage() {
    this.utilsService.getFileDetails(this.staff.fileID).subscribe((res) => {
      if (res) {
        if (res.contentType == 'image/png' || res.contentType == 'image/jpg' || res.contentType == 'image/jpeg') {
          this.isFile = true;
          this.src = `${environment.nodeUrl}/files/file-view/${this.staff.fileID}`;

          NotifyAppComponent.displayToast('success', 'Successful operation', 'Profile has been successfully updated');
        } else {
          NotifyAppComponent.displayToast('warning', 'Error operation', 'Format of image should be png/jpg/jpeg');
        }
      }
    });
  }

  getPicture(id) {
    if (id) {
      this.getProfilePicture();
    }
  }

  getProfilePicture() {
    this.profileLink = null;

    this.profileLink = this.dentistService.getProfilePicStreamLink(this.dentistID);
  }

  closeEvent() {
    this.close.emit(true);
  }
}
