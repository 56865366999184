/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./before-starting.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../../shared/components/video-tutorials-modal/video-tutorials-modal.component.ngfactory";
import * as i6 from "../../../shared/components/video-tutorials-modal/video-tutorials-modal.component";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../../../../../node_modules/@angular/material/checkbox/typings/index.ngfactory";
import * as i9 from "@angular/material/checkbox";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "@angular/platform-browser/animations";
import * as i12 from "@angular/forms";
import * as i13 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i14 from "@angular/material/button";
import * as i15 from "./before-starting.component";
var styles_InvTreatBeforeStartingComponent = [i0.styles];
var RenderType_InvTreatBeforeStartingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InvTreatBeforeStartingComponent, data: {} });
export { RenderType_InvTreatBeforeStartingComponent as RenderType_InvTreatBeforeStartingComponent };
export function View_InvTreatBeforeStartingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 25, "div", [["class", "rel dash invit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "card-header primary-gradient-img inModal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "row titleArea clearfix full-width"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "animated zoomIn feature-img dashboard character_Pair"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [["class", "summary-header rm-m white"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Invitation Templates "])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "button", [["class", "btn-close btn-clear mat-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doNotShow() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(8, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["clear"])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(11, 16384, null, 0, i4.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-video-tutorials-modal", [], null, null, null, i5.View_VideoTutorialsModalComponent_0, i5.RenderType_VideoTutorialsModalComponent)), i1.ɵdid(13, 114688, null, 0, i6.VideoTutorialsModalComponent, [i7.DomSanitizer, i4.MAT_DIALOG_DATA], { autoplay: [0, "autoplay"], defaultSelectedTopic: [1, "defaultSelectedTopic"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 0, "div", [["class", "row clearfix mb"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 6, "mat-checkbox", [["class", "mat-checkbox"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.showBeforeStartingInvitationTemplate = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatCheckbox_0, i8.RenderType_MatCheckbox)), i1.ɵdid(17, 8568832, null, 0, i9.MatCheckbox, [i1.ElementRef, i1.ChangeDetectorRef, i10.FocusMonitor, i1.NgZone, [8, null], [2, i9.MAT_CHECKBOX_CLICK_ACTION], [2, i11.ANIMATION_MODULE_TYPE]], null, null), i1.ɵprd(1024, null, i12.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i9.MatCheckbox]), i1.ɵdid(19, 671744, null, 0, i12.NgModel, [[8, null], [8, null], [8, null], [6, i12.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i12.NgControl, null, [i12.NgModel]), i1.ɵdid(21, 16384, null, 0, i12.NgControlStatus, [[4, i12.NgControl]], null, null), (_l()(), i1.ɵted(-1, 0, ["Please don't show this message again"])), (_l()(), i1.ɵeld(23, 0, null, null, 2, "button", [["class", "ml pull-right"], ["color", "accent"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doNotShow() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_MatButton_0, i13.RenderType_MatButton)), i1.ɵdid(24, 180224, null, 0, i14.MatButton, [i1.ElementRef, i10.FocusMonitor, [2, i11.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Got It! "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 8, 0); var currVal_2 = false; var currVal_3 = "428497337"; _ck(_v, 13, 0, currVal_2, currVal_3); var currVal_18 = _co.showBeforeStartingInvitationTemplate; _ck(_v, 19, 0, currVal_18); var currVal_21 = "accent"; _ck(_v, 24, 0, currVal_21); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).inline; var currVal_1 = (((i1.ɵnov(_v, 8).color !== "primary") && (i1.ɵnov(_v, 8).color !== "accent")) && (i1.ɵnov(_v, 8).color !== "warn")); _ck(_v, 7, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵnov(_v, 17).id; var currVal_5 = null; var currVal_6 = i1.ɵnov(_v, 17).indeterminate; var currVal_7 = i1.ɵnov(_v, 17).checked; var currVal_8 = i1.ɵnov(_v, 17).disabled; var currVal_9 = (i1.ɵnov(_v, 17).labelPosition == "before"); var currVal_10 = (i1.ɵnov(_v, 17)._animationMode === "NoopAnimations"); var currVal_11 = i1.ɵnov(_v, 21).ngClassUntouched; var currVal_12 = i1.ɵnov(_v, 21).ngClassTouched; var currVal_13 = i1.ɵnov(_v, 21).ngClassPristine; var currVal_14 = i1.ɵnov(_v, 21).ngClassDirty; var currVal_15 = i1.ɵnov(_v, 21).ngClassValid; var currVal_16 = i1.ɵnov(_v, 21).ngClassInvalid; var currVal_17 = i1.ɵnov(_v, 21).ngClassPending; _ck(_v, 16, 1, [currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17]); var currVal_19 = (i1.ɵnov(_v, 24).disabled || null); var currVal_20 = (i1.ɵnov(_v, 24)._animationMode === "NoopAnimations"); _ck(_v, 23, 0, currVal_19, currVal_20); }); }
export function View_InvTreatBeforeStartingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-before-starting", [], null, null, null, View_InvTreatBeforeStartingComponent_0, RenderType_InvTreatBeforeStartingComponent)), i1.ɵdid(1, 114688, null, 0, i15.InvTreatBeforeStartingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InvTreatBeforeStartingComponentNgFactory = i1.ɵccf("app-before-starting", i15.InvTreatBeforeStartingComponent, View_InvTreatBeforeStartingComponent_Host_0, {}, {}, []);
export { InvTreatBeforeStartingComponentNgFactory as InvTreatBeforeStartingComponentNgFactory };
