<div class="rel">
  <aw-wizard #wizard [disableNavigationBar]="true" navBarLayout="large-filled-symbols">
    <aw-wizard-step [navigationSymbol]="{ symbol: start }"
                    [stepTitle]="('KEYWORD.patient' | translate | titlecase) + ' information'" awOptionalStep>
      <!-- Step 1  -->

      <div class="container" id="step-1">
        <div class="row clearfix">
          <div class="full-width rel">
            <div class="text-center">
              <h2 class="sr-title inline-block pull-left" *ngIf="patient && patientID">
                Google Review Invitation for
                {{ patient['firstName'] }}
              </h2>

              <h2 class="sr-title inline-block pull-left" *ngIf="!patientID">Creating Google Review Invitation</h2>

              <!-- Patient Lookup Trigger -->
              <button
                class="btn-small mt mb pull-right"
                id="searchPatient"
                *ngIf="displayLookup == true"
                (click)="openPatientLookUpDialog()"
                mat-raised-button
                color="primary"
              >
                {{ "KEYWORD.patient" | translate | titlecase }} LookUp
                <mat-icon>contacts</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <mat-card>
          <div class="colour-stripe"></div>

          <div class="clearfix" *ngIf="isAdminOrPromoter == true && displayLookup == true">
            <p class="help-label">
              To send an invitation on behalf of a merchant select a
              merchant below, otherwise, the invitation will be sent by the promoter.
            </p>
            <div class="row clearfix selectMerchant">
              <div class="icons-col">
                <mat-icon class="fas fa-store-alt"></mat-icon>
              </div>
              <div class="row-content-col">
                <mat-form-field class="margin-btm half-width" appearance="outline">
                  <mat-label>Please Select a Merchant</mat-label>
                  <mat-select
                    class=""
                    [(ngModel)]="merchant"
                    (ngModelChange)="changeMerchant($event)"
                    name="MerchantName"
                    placeholder="Merchant"
                  >
                    <mat-option [value]="null"> My Account</mat-option>
                    <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m">
                      <span
                        class="list-label pull-right"
                        *ngIf="m['Status'] && m['Status'] == 'Active'"
                        [style.background]="'#00d07a'"
                      >
                        {{ m['Status'] }}
                      </span>

                      <span
                        class="list-label pull-right"
                        *ngIf="m['Status'] && m['Status'] != 'Active'"
                        [style.background]="'#ff5722'"
                      >
                        {{ m['Status'] }}
                      </span>
                      {{ m['TradingAs'] || m['CalculatedName'] }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <button
                  class="pull-right merchBtn"
                  *ngIf="merchant"
                  (click)="viewMerchant()"
                  mat-raised-button
                  color="primary"
                >
                  <mat-icon>person</mat-icon>
                  <span class="mobHide">View Merchant</span>
                </button>
              </div>
            </div>
            <hr class="mt mb"/>
          </div>

          <p class="help-label">
            Please start by entering the details of your {{ "KEYWORD.patient" | translate }}. You can use the '{{
            'KEYWORD.patient' | translate | titlecase
            }}
            Lookup' button to search your existing {{ "KEYWORD.patient" | translate }} records.
          </p>
          <form #createForm="ngForm">
            <div class="row clearfix">
              <!-- <div class="half-width"> -->
              <div class="row clearfix">
                <div class="row-content-col">
                  <div class="clearfix">
                    <div class="row clearfix flex">
                      <!-- Gender Select -->
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Gender</mat-label>
                        <mat-select
                          class=""
                          [(ngModel)]="patient.genderCode"
                          [disabled]="isFinDMatch"
                          name="gender"
                          placeholder="Gender"
                          required
                        >
                          <mat-option *ngFor="let gender of genders" [value]="gender.Code">
                            {{ gender.Label }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <!-- Title Select -->
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Title</mat-label>
                        <mat-select
                          class="full-width"
                          [(ngModel)]="patient.title"
                          [disabled]="isFinDMatch"
                          name="title"
                          placeholder="Title"
                        >
                          <mat-option *ngFor="let title of titles" [value]="title.Label">
                            {{ title.Label }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row clearfix flex">
                    <!-- First Name -->
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>First Name</mat-label>
                      <input
                        class="mat-block"
                        [(ngModel)]="patient.firstName"
                        [disabled]="isFinDMatch"
                        name="firstName"
                        matInput
                        placeholder="{{ 'PLACEHOLDER.First Name' | translate }} "
                        required
                      />
                    </mat-form-field>

                    <!-- Middle Name -->
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Middle Name</mat-label>
                      <input
                        class="mat-block"
                        [(ngModel)]="patient.middleName"
                        [disabled]="isFinDMatch"
                        name="middleName"
                        matInput
                        placeholder="{{ 'PLACEHOLDER.Middle Name' | translate }} "
                      />
                    </mat-form-field>

                    <!-- Surname -->
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Surname</mat-label>
                      <input
                        class="mat-block"
                        [(ngModel)]="patient.lastName"
                        [disabled]="isFinDMatch"
                        name="lastName"
                        matInput
                        placeholder="{{ 'PLACEHOLDER.Surname' | translate }} "
                        required
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <!-- </div> -->
              <hr class="mt mb" />


              <div class="patient-details mt" #patientContactBlock>
                <div class="row clearfix">
                  <div class="icons-col">
                    <mat-icon class="">phone</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <div class="row clearfix flex">

                      <app-phone-input
                        class="full-width"
                        [clear]="clearMobile"
                        [phoneNumber]="patient.mobile"
                        [label]="'Mobile'"
                        [required]="true"
                        [isDisabled]="isFinDMatch"
                        (validate)="validateMobile($event)"
                        (getPhone)="getMobile($event)"
                      >
                      </app-phone-input>

                      <app-phone-input
                        class="full-width"
                        [clear]="clearHomePhone"
                        [phoneNumber]="patient.homePhone"
                        [isLandLine]="true"
                        [label]="'Home'"
                        [required]="false"
                        [isDisabled]="isFinDMatch"
                        (validate)="validateHomePhone($event)"
                        (getPhone)="getHomePhone($event)"
                      >
                      </app-phone-input>

                      <app-phone-input
                        class="full-width"
                        [clear]="clearWorkPhone"
                        [phoneNumber]="patient.workPhone"
                        [isLandLine]="true"
                        [label]="'Work'"
                        [required]="false"
                        [isDisabled]="isFinDMatch"
                        (validate)="validateWorkPhone($event)"
                        (getPhone)="getWorkPhone($event)"
                      >
                      </app-phone-input>
                    </div>
                  </div>
                </div>
                <div class="row clearfix">
                  <div class="icons-col">
                    <mat-icon>email</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <!-- Email Address -->
                    <app-email-input
                      class="full-width"
                      [clear]="clearEmail"
                      [email]="patient.email"
                      [label]="'Email'"
                      [required]="true"
                      [isDisabled]="isFinDMatch"
                      (getEmail)="getEmail($event)"
                      (validate)="validateEmail($event)"
                    >
                    </app-email-input>
                  </div>
                </div>

                <div class="row clearfix">
                  <div class="icons-col">
                    <mat-icon>account_circle</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <app-date-input
                      class="full-width"
                      [defaultDate]="dateOfBirth"
                      [clear]="clearDate"
                      [isClear]="true"
                      [startView]="'multi-year'"
                      [disabled]="isFinDMatch"
                      [disabledAll]="isFinDMatch"
                      [isTime]="false"
                      [maxDate]="'toDay'"
                      (selectedDate)="selectDateToFilter($event)"
                      label="Date of birth"
                    >
                    </app-date-input>
                  </div>
                </div>

                <div
                  class="row clearfix"
                  *ngIf="
                    (!patient.genderCode ||
                      !isEmailValid ||
                      !isMobileValid ||
                      !isHomePhoneValid ||
                      !isWorkPhoneValid) &&
                    isFinDMatch == true &&
                    patientID
                  "
                >
                  <div class="icons-col">
                    <mat-icon class="error-invitation" (click)="modifyProfile()">info_outline</mat-icon>
                  </div>
                  <div class="row-content-col row clearfix flex diag">
                    <p class="small mt warning" (click)="modifyProfile()">
                      The {{ "KEYWORD.patient" | translate }} you've selected is missing some details. Please click
                      'modify' to update the {{ "KEYWORD.patient" | translate }}'s profile.
                    </p>
                  </div>
                </div>

                <button
                  class="pull-right mat-stroked-mini-fab mat-elevation-z1"
                  *ngIf="isFinDMatch == true && patientID"
                  (click)="clearPatientDetails()"
                  mat-stroked-button
                  color="accent"
                  [matTooltip]="'Clear ' + ('KEYWORD.patient' | translate | titlecase) + ' Details'"
                >
                  <mat-icon class="fas fa-times"></mat-icon>
                </button>
              </div>
            </div>
          </form>
        </mat-card>

        <div class="row clearfix">
          <div class="full-width text-center step-button-row">
            <button
              class="step-back"
              [routerLink]="['/merchant/', { outlets: { page: ['home'] } }]"
              mat-mini-fab
              color="primary"
              matTooltip="Return to dashboard"
            >
              <mat-icon>home</mat-icon>
            </button>

            <button
              class="btn-large"
              *ngIf="isFinDMatch == false"
              [disabled]="
                !createForm.form.valid ||
                !isEmailValid ||
                !_dateOfBirth ||
                !isMobileValid ||
                !isHomePhoneValid ||
                !isWorkPhoneValid
              "
              (click)="step1()"
              mat-raised-button
              color="accent"

            >
              Next
              <mat-icon>chevron_right</mat-icon>
            </button>

            <button
              class="btn-large"
              *ngIf="isFinDMatch == true && patientID"
              [disabled]="
                !patient.genderCode ||
                !isEmailValid ||
                !_dateOfBirth ||
                !isMobileValid ||
                !isHomePhoneValid ||
                !isWorkPhoneValid
              "
              (click)="step1()"
              mat-raised-button
              color="accent"

            >
              Next
              <mat-icon>chevron_right</mat-icon>
            </button>

            <button
              class="pull-right btn-large"
              *ngIf="isFinDMatch == true && patientID"
              (click)="modifyProfile()"
              mat-raised-button
              color="primary"
              matTooltip="Edit profile"
            >
              <mat-icon>edit</mat-icon>
              Modify
            </button>
          </div>
        </div>
      </div>
    </aw-wizard-step>
    <aw-wizard-step [navigationSymbol]="{ symbol: start + 1 }" stepTitle="Personalised message">
      <!--  Step 2  -->
      <h2 class="sr-title inline-block text-center">Add a message and send!</h2>

      <div class="container" id="step-3">
        <div class="row clearfix">
          <mat-card class="rm-bp">
            <div class="colour-stripe"></div>
            <!-- normal invitation -->
            <div class="row clearfix">
              <div class="column clearfix">
                <div class="icons-col">
                  <mat-icon>email</mat-icon>
                </div>
                <div class="row-content-col" id="defaultMes">
                  <div class="row clearfix">
                    <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb pull-left">Your personal message</h3>
                    <!-- DEFAULT MSG -->
                    <div
                      class="defaultMsgArea pull-right"
                      *ngIf="defaultMessageLists.length <= 0 && isModuleCustomMessages == true"
                    >
                      <button
                        class="pull-right"
                        (click)="viewVideoInfo('Default messages', 'vimeo.com/358964874/19331ac58f')"
                        mat-icon-button
                        color="primary"
                        matTooltip="What's this?"
                      >
                        <mat-icon class="fas fa-info-circle mat-primary"></mat-icon>
                      </button>
                      <mat-form-field class="defaultMsg full-width" appearance="outline">
                        <mat-label>Add Default Message</mat-label>
                        <mat-select class="" name="messageOptionNull" placeholder="Add Default Message">
                          <mat-option (click)="addNewMessage()">
                            <p class="small rm-mt rm-mb">
                              <mat-icon class="addnew">add</mat-icon>
                              Create new
                            </p>
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div
                      class="defaultMsgArea pull-right"
                      *ngIf="isModuleCustomMessages == true && defaultMessageLists && defaultMessageLists.length > 0"
                    >
                      <mat-icon class="pull-left" *ngIf="defaultMessageLists && defaultMessageLists.length > 0"
                      >chat
                      </mat-icon>
                      <mat-form-field
                        class="defaultMsg full-width"
                        *ngIf="defaultMessageLists && defaultMessageLists.length > 0"
                        appearance="outline"
                      >
                        <mat-label>Add Default Message</mat-label>
                        <mat-select
                          [(ngModel)]="messageOption"
                          (selectionChange)="defaultMessageChange(messageOption)"
                          name="messageOption"
                          placeholder="Add Default Message"
                        >
                          <mat-option *ngFor="let option of defaultMessageLists" [value]="option">
                            <p class="small rm-mt rm-mb">{{ option.label }}</p>
                          </mat-option>
                          <mat-divider></mat-divider>
                          <mat-option (click)="addNewMessage()">
                            <p class="small rm-mt rm-mb">
                              <mat-icon class="addnew">add</mat-icon>
                              Create new
                            </p>
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <button
                        class="pull-right"
                        (click)="viewVideoInfo('Default messages', 'vimeo.com/358964874/19331ac58f')"
                        mat-icon-button
                        color="primary"
                        mdTooltip="What's this?"
                      >
                        <mat-icon class="fas fa-info-circle mat-primary"></mat-icon>
                      </button>
                    </div>
                  </div>
                  <mat-form-field class="full-width" appearance="outline">
                    <textarea
                      class="dentMsg increase-input-field"
                      [(ngModel)]="message"
                      name="message"
                      matInput
                      placeholder="Personal message to appear with invitation*"
                    ></textarea>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="grey">
              <div class="row clearfix">
                <div class="half-width" id="senderName">
                  <div class="column clearfix mb">
                    <div class="icons-col">
                      <mat-icon>face</mat-icon>
                    </div>
                    <div class="row-content-col">
                      <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb">Senders name to appear with message</h3>
                      <div class="clearfix flex flex-center">
                        <mat-form-field class="full-width" appearance="outline">
                          <mat-label>Sender</mat-label>
                          <mat-select
                            class=""
                            [(ngModel)]="contact"
                            (ngModelChange)="changeSender()"
                            name="ContactName"
                            placeholder="Contact"
                          >
                            <mat-option
                              *ngFor="let m of contacts | orderBy: ['TradingAs', 'CalculatedName']"
                              [value]="m"
                            >
                              {{ m['TradingAs'] || m['CalculatedName'] }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                        <button
                          class="pull-right"
                          *ngIf="contact"
                          [matTooltip]="'Preview ' + ('KEYWORD.practitioner' | translate | titlecase) + ' Profile'"
                          (click)="viewDentist()"
                          mat-icon-button
                          color="primary"
                          style="margin-left: 5px; margin-bottom: 10px; height: 55px"
                        >
                          <mat-icon class="fas fa-eye mat-primary"></mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="half-width">
                  <div class="icons-col">
                    <mat-icon>phone_iphone</mat-icon>
                  </div>

                  <div class="row-content-col">
                    <h3 class="sr-title sm-h3 rm-mt mt">Schedule SMS</h3>
                    <div class="input-control">
                      <mat-checkbox [(ngModel)]="sendSMS" name="sendSMS"
                      >Send SMS
                      </mat-checkbox>
                      <span class="send-sms-to">{{ patient.mobile | customPhone }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
        <div class="row clearfix">
          <div class="full-width step-button-row text-center">
            <button
              class="step-back"
              mat-mini-fab
              color="primary"
              awPreviousStep

            >
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <button
              class="step-send"
              class="btn-large"
              [disabled]="contact == null"
              (click)="sendResult()"
              mat-raised-button
              color="accent"

            >
              Send
              <mat-icon>send</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <!--  / Step 3 -->
    </aw-wizard-step>
    <aw-wizard-completion-step [navigationSymbol]="{ symbol: start + 2 }" stepTitle="Send">
      <div class="container" id="step-4">
        <mat-card class="card successful airplaneCard" *ngIf="complete == true" @simpleFadeAnimation>
          <div class="row clearfix anim">
            <div class="full-width text-center">
              <div class="column row clearfix">
                <h1 class="sm-h1 sr-title rm-mt">Well Done!</h1>
                <h3 class="sm-h3 rm-mt">
                  Your Google review invitation to
                  <strong>{{ patient['firstName'] }} {{ patient['lastName'] }}</strong> is on it's way!
                </h3>
              </div>
              <div class="row clearfix mb">
                <app-plane-animation [flyAway]="flyAway"></app-plane-animation>
              </div>
              <div class="row finishBtns flex">
                <button
                  class="step-back btn-small"
                  (click)="exitToDash()"
                  mat-raised-button
                  color="primary"

                >
                  <mat-icon>keyboard_arrow_left</mat-icon>
                  Dashboard
                </button>

                <button
                  class="step-back btn-small"
                  *ngIf="patientID"
                  (click)="patientProfile()"
                  mat-raised-button
                  color="accent"
                >
                  <mat-icon>person</mat-icon>
                  {{ "KEYWORD.patient" | translate | titlecase }}
                </button>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </aw-wizard-completion-step>
  </aw-wizard>
</div>
