/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin-help-documents-center.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i7 from "@angular/material/icon";
import * as i8 from "../../../helper/help-page-list/help-page-list.component.ngfactory";
import * as i9 from "../../../helper/help-page-list/help-page-list.component";
import * as i10 from "../../../helper/shared/helper.service";
import * as i11 from "./admin-help-documents-center.component";
import * as i12 from "@angular/material/dialog";
var styles_AdminHelpDocumentsCenterComponent = [i0.styles];
var RenderType_AdminHelpDocumentsCenterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminHelpDocumentsCenterComponent, data: {} });
export { RenderType_AdminHelpDocumentsCenterComponent as RenderType_AdminHelpDocumentsCenterComponent };
export function View_AdminHelpDocumentsCenterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "addDocument pull-right"], ["color", "primary"], ["mat-mini-fab", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addSimplePage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["add"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-help-page-list", [], null, null, null, i8.View_HelpPageListComponent_0, i8.RenderType_HelpPageListComponent)), i1.ɵdid(6, 114688, null, 0, i9.HelpPageListComponent, [i10.HelperService], { simplePage: [0, "simplePage"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "primary"; _ck(_v, 1, 0, currVal_2); _ck(_v, 3, 0); var currVal_5 = _co.page; _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 3).inline; var currVal_4 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_3, currVal_4); }); }
export function View_AdminHelpDocumentsCenterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "admin-help-documents-center", [], null, null, null, View_AdminHelpDocumentsCenterComponent_0, RenderType_AdminHelpDocumentsCenterComponent)), i1.ɵdid(1, 114688, null, 0, i11.AdminHelpDocumentsCenterComponent, [i12.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminHelpDocumentsCenterComponentNgFactory = i1.ɵccf("admin-help-documents-center", i11.AdminHelpDocumentsCenterComponent, View_AdminHelpDocumentsCenterComponent_Host_0, {}, {}, []);
export { AdminHelpDocumentsCenterComponentNgFactory as AdminHelpDocumentsCenterComponentNgFactory };
