import { Component, OnInit, Input, Optional, Inject, Output, EventEmitter, SimpleChanges } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BeneficiaryService } from '../shared/beneficiary.service';

@Component({
  selector: 'app-beneficiary-view-picture',
  templateUrl: './beneficiary-view-picture.component.html',
  styleUrls: ['./beneficiary-view-picture.component.css']
})
export class BeneficiaryViewPictureComponent implements OnInit {
  @Input() beneficiaryID;


  @Input() LastModified = null;

  @Output() close = new EventEmitter();


  profileLink;



  constructor(private beneficiaryService: BeneficiaryService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any) {
    if (data && typeof data == "string") {
      this.beneficiaryID = data;
    }
  }

  ngOnInit() {

    if (this.beneficiaryID) {


      this.getProfilePicture();

    }

  }

  ngOnChanges(changes: SimpleChanges) {


    if (changes.beneficiaryID.previousValue != changes.beneficiaryID.currentValue) {

      if (this.beneficiaryID) {

        this.getProfilePicture();

      }
      else {
        this.profileLink = null;
      }
    }



  }


  getProfilePicture() {
    this.profileLink = null;

    this.profileLink = this.beneficiaryService.getProfilePicStreamLink(this.beneficiaryID,this.LastModified)

  }

  closeEvent() {
    this.close.emit(true);
  }
}
