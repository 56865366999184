<!-- css loader -->
<mat-card class="quick-actions-card card rel" *ngIf="!invitesSentOpened">
  <div class="lds-ellipsis">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</mat-card>
<!-- /css loader -->

<mat-card class="quick-actions-card card" *ngIf="invitesSentOpened">
  <div class="row clearfix">
    <h3 class="sm-h3 sr-title rm-mb rm-mt pull-left">
      <mat-icon class="adj">mail</mat-icon>
      Invitations sent &amp; opened
    </h3>

    <div class="pull-right">
      <button id="treatment-action-menu" [matMenuTriggerFor]="menu1" mat-icon-button>
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu1="matMenu">
        <button [routerLink]="['/merchant/', { outlets: { page: ['promoter-performance-overview'] } }]" mat-menu-item>
          <mat-icon>visibility_on</mat-icon>
          <span>View Performance</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="row clearfix flex-grow rel">
    <div class="row clearfix chart">
      <app-chart-input
        [showLegend]="false"
        [autoScale]="true"
        [showGridLines]="true"
        [showLabel]="false"
        [data]="invitesSentOpened"
        [chartType]="'line'"
        [showXAxis]="true"
        [showYAxis]="true"
        [dataNameAttribute]="'name'"
        [showXAxisLabel]="false"
        [showYAxisLabel]="false"
        [yAxisLabel]="''"
        [title]="''"
      >
      </app-chart-input>
    </div>
  </div>
  <p class="small">
    Number of <span class="primary-color">Invites Sent</span> vs <span class="accent-color">Invited Opened</span>
  </p>
  <app-date-range-picker [showAllTime]="false" (getRange)="getRangeInvitesSentOpened($event)"></app-date-range-picker>
</mat-card>
