<div class="row clearfix container mt ml mr">
  <mat-card class="rel clearfix card-container">
    <div class="colour-stripe"></div>

    <div class="full-width flex clearfix">
      <div class="full-width clearfix ml mr">
        <button
          class="btn mr ml mt pull-right"
          *ngIf="selectedIDs && selectedIDs.length > 1"
          (click)="compare()"
          mat-raised-button
          color="accent"
        >
          <mat-icon class="fas fa-columns"></mat-icon>
          <span class="sml">Compare</span>
        </button>

        <button class="btn mr ml mt pull-right" (click)="createNew()" mat-raised-button color="accent">
          <mat-icon class="fas fa-plus-circle"></mat-icon>
          <span class="sml">Create New</span>
        </button>

        <button
          class="btn mr ml mt pull-right"
          [matMenuTriggerFor]="ActionBtnmenuSelect"
          mat-raised-button
          color="primary"
        >
          <mat-icon class="fas fa-file-contract smr"></mat-icon>
          <span class="sml">Terms &amp Conditions</span>
        </button>
        <mat-menu #ActionBtnmenuSelect="matMenu">
          <button (click)="termsView()" mat-menu-item>
            <mat-icon class="smr">visibility</mat-icon>
            View
          </button>
          <button (click)="termsEdit()" mat-menu-item>
            <mat-icon class="smr">edit</mat-icon>
            Edit
          </button>
        </mat-menu>

        <h2 class="sm-h2 sr-title">Subscription plans management</h2>
      </div>
    </div>

    <div
      class="full-width mt"
      *ngIf="
        (subscriptionPlans
          | FilterArrayMultipleOrContain: ['Is_Active']:'1'
          | FilterArrayMultipleOrContain: ['Is_Public']:'1'
          | lengthOfArray) > 0
      "
    >
      <div class="full-width text-center ml">
        <h2 class="text-left rm-mb title-public">Public plans</h2>
        <hr class="title-public"/>
      </div>
      <div class="full-width row mb container-card-sub-plan">
        <div
          class="thrd-width sub-plan-card-app flex row"
          *ngFor="
            let subscriptionPlan of subscriptionPlans
              | FilterArrayMultipleOrContain: ['Is_Active']:'1'
              | FilterArrayMultipleOrContain: ['Is_Public']:'1'
          "
        >
          <app-subscription-plan-card
            class="full-width row sub-plan-card"
            [selectTitle]="'Edit Plan'"
            [canClose]="true"
            [isClosed]="true"
            [canCheck]="true"
            [subscriptionPlan]="subscriptionPlan"
            [isSubscriptionButton]="false"
            [isSelectButton]="true"
            [isViewButton]="false"
            (getSelect)="getSelect($event)"
            (getRemove)="remove($event)"
            (getCheck)="check($event)"
            (getUncheck)="check($event)"
          >
          </app-subscription-plan-card>
        </div>
      </div>
    </div>

    <div
      class="full-width mt"
      *ngIf="
        (subscriptionPlans
          | FilterArrayMultipleOrContain: ['Is_Active']:'1'
          | FilterArrayMultipleOrContain: ['Is_Public']:'0'
          | lengthOfArray) > 0
      "
    >
      <div class="full-width text-center ml">
        <h2 class="text-left rm-mb title-customized">Customized plans</h2>
        <hr class="title-customized"/>
      </div>
      <div class="full-width row mb container-card-sub-plan">
        <div
          class="thrd-width sub-plan-card-app flex row"
          *ngFor="
            let subscriptionPlan of subscriptionPlans
              | FilterArrayMultipleOrContain: ['Is_Active']:'1'
              | FilterArrayMultipleOrContain: ['Is_Public']:'0'
          "
        >
          <app-subscription-plan-card
            class="full-width row sub-plan-card"
            [selectTitle]="'Edit Plan'"
            [canClose]="true"
            [isClosed]="true"
            [canCheck]="true"
            [subscriptionPlan]="subscriptionPlan"
            [isSubscriptionButton]="false"
            [isSelectButton]="true"
            [isViewButton]="false"
            (getSelect)="getSelect($event)"
            (getRemove)="remove($event)"
            (getCheck)="check($event)"
            (getUncheck)="check($event)"
          >
          </app-subscription-plan-card>
        </div>
      </div>
    </div>

    <div
      class="full-width mt"
      *ngIf="(subscriptionPlans | FilterArrayMultipleOrContain: ['Is_Active']:'0' | lengthOfArray) > 0"
    >
      <div class="full-width text-center ml">
        <h2 class="text-left rm-mb title-inactive">Inactive plans</h2>
        <hr class="title-inactive"/>
      </div>
      <div class="full-width row mb container-card-sub-plan">
        <div
          class="thrd-width sub-plan-card-app flex row"
          *ngFor="let subscriptionPlan of subscriptionPlans | FilterArrayMultipleOrContain: ['Is_Active']:'0'"
        >
          <app-subscription-plan-card
            class="full-width row sub-plan-card"
            [selectTitle]="'Edit Plan'"
            [canClose]="true"
            [isClosed]="true"
            [canCheck]="true"
            [subscriptionPlan]="subscriptionPlan"
            [isSubscriptionButton]="false"
            [isSelectButton]="true"
            [isViewButton]="false"
            (getSelect)="getSelect($event)"
            (getRemove)="remove($event)"
            (getCheck)="check($event)"
            (getUncheck)="check($event)"
          >
          </app-subscription-plan-card>
        </div>
      </div>
    </div>
  </mat-card>
</div>
