<div class="row clearfix" @onChangeAnimation>
  <mat-card class="card clearfix" *ngIf="viewModel$ | async">
    <div class="colour-stripe"></div>

    <ipv-data-header [title]="'Payments'"></ipv-data-header>

    <div style="margin-top: 10px; margin-bottom: 40px; width: 100%; display: flex; justify-content: flex-end">
      <button (click)="openTablePrint()" color="accent" mat-raised-button style="margin-left: 30px; height: 36px">
        <mat-icon class="fas fa-print"></mat-icon>
        Print Report
      </button>
    </div>

    <ipv-data-table-control-container>
      <ng-container *ngIf="isPromoterOrAdmin">
        <mat-form-field appearance="outline" *ngIf="(merchants$ | async) as merchants">
          <mat-label> Select Merchant</mat-label>

          <mat-select
            name="merchant"
            placeholder="Select Merchant"
            [(ngModel)]="merchant"
            [compareWith]="matchMerchants"
            (ngModelChange)="setMerchant()"
          >
            <mat-option [value]="allMerchantsOptionValue" *ngIf="startWithAllMerchants"> All Merchants</mat-option>

            <mat-option
              *ngFor="let merchant of merchants  | orderBy : ['TradingAs', 'CalculatedName']"
              [value]="merchant"
            >
              <span
                class="list-label pull-right"
                *ngIf="merchant['Status'] && merchant['Status']=='Active'"
                [style.background]="'#00d07a'"
              >
                {{merchant['Status']}}
              </span>

              <span
                class="list-label pull-right"
                *ngIf="merchant['Status'] && merchant['Status']!='Active'"
                [style.background]="'#ff5722'"
              >
                {{merchant['Status']}}
              </span>

              {{merchant['TradingAs'] || merchant['CalculatedName'] }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <app-date-input
        [defaultDate]="initialStartDate"
        (selectedDate)="setStartDate($event)"
        [label]="'Start Date'"
      ></app-date-input>

      <app-date-input
        *ngIf="showEndDate"
        [defaultDate]="initialEndDate"
        [minDate]="initialStartDate"
        (selectedDate)="setEndDate($event)"
        [label]="'End Date'"
      ></app-date-input>

      <div style="margin-left: auto; margin-top: 2px; color: white; font-weight: bold">
        <h2 *ngIf="patientPaymentReportTotal !== 0 && selectedTabIndex">
          Total Settled: {{ patientPaymentReportTotal | invertNumber | customCurrency }}
        </h2>
      </div>
    </ipv-data-table-control-container>

    <div class="row full-width clearfix">
      <div class="clearfix rel">
        <div class="row white-background full-width clearfix" @onChangeAnimation>
          <div class="animate">
            <div class="mt mb clearfix">
              <mat-tab-group mat-stretch-tabs>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <mat-icon class="fas fa-money-bill"></mat-icon>
                    <span> Settlements Banked </span>
                    <span
                      class="badge"
                      countUp
                      [endVal]="patientPaymentReportCount"
                      *ngIf="patientPaymentReportCount !==0"
                    ></span>
                  </ng-template>

                  <ng-container *ngIf="patientPaymentReport$ | async as patientPaymentReport">
                    <ipv-settlement-payments-report-patient-payments
                      [patientPaymentReport]="patientPaymentReport"
                      [merchant]="merchant"
                    ></ipv-settlement-payments-report-patient-payments>
                  </ng-container>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <mat-icon class="fas fa-file-invoice-dollar"></mat-icon>
                    <span>  {{ 'KEYWORD.practice' | translate }} Fees </span>
                    <span
                      class="badge"
                      countUp
                      [endVal]="merchantFeeReportCount"
                      *ngIf="merchantFeeReportCount !== 0"
                    ></span>
                  </ng-template>
                  <div class="padded" style="overflow: hidden">
                    <ng-container *ngIf="(merchantFeeReport$ | async) as merchantFeeReport">
                      <ng-container>
                        <ipv-settlements-payments-report-merchant-fees
                          [merchantFeeReport]="merchantFeeReport"
                          [merchant]="merchant"
                        ></ipv-settlements-payments-report-merchant-fees>
                      </ng-container>
                    </ng-container>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
