<div class="row clearfix defineMessage" *ngIf="document">
  <!-- blue heading -->
  <div class="card-header primary-gradient-img clearfix" *ngIf="isModal == true">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear pull-right mat-button" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <!-- merchant calculated name -->

      <div class="row titleArea clearfix">
        <app-ep-document-thumbnail-view class="headNavViewthumbnail userIconViewthumbnail pull-left mr"
          *ngIf="documentID" [documentID]="documentID">
        </app-ep-document-thumbnail-view>

        <h2 class="summary-header rm-m" *ngIf="documentID && document && document['label']">
          <span> Edit {{ document.label }}</span>
          <span class="subLabel">{{ document.description }}</span>
        </h2>

        <h2 class="summary-header rm-m" *ngIf="!documentID">
          <span>{{ title }}</span>
          <span class="subLabel">{{ description }}</span>
        </h2>
      </div>
    </div>
  </div>
  <!-- end blue heading -->

  <div class="create-well">
    <form #msgForm="ngForm">
      <!--  mat dialog scrolling area -->
      <mat-dialog-content>
        <div class="full-width">
          <div class="full-width clearfix mt">
            <mat-form-field class="full-width clearfix"
              *ngIf="!documentID && isPromoterOrAdmin == true && displayMerchantList==true" appearance="outline">
              <mat-label>Merchant</mat-label>
              <mat-select [(ngModel)]="merchantID" name="MerchantName" placeholder="Merchant">
                <mat-option [value]="null"> My Account</mat-option>
                <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m['ID']">
                  <span class="list-label pull-right" *ngIf="m['Status'] && m['Status'] == 'Active'"
                    [style.background]="'#00d07a'">
                    {{ m['Status'] }}
                  </span>

                  <span class="list-label pull-right" *ngIf="m['Status'] && m['Status'] != 'Active'"
                    [style.background]="'#ff5722'">
                    {{ m['Status'] }}
                  </span>
                  {{ m['TradingAs'] || m['CalculatedName'] }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width clearfix"
              *ngIf="types && types.length > 0 && miniEdit != true  && hideDocumentType!=true  "
              appearance="outline">
              <mat-label>Document Type</mat-label>
              <mat-select class=" " [(ngModel)]="document.type" [disabled]="documentID"
                (ngModelChange)="changeDocumentType()" name="DocumentType" placeholder="Document Type">
                <mat-option *ngFor="let type of types | orderBy: ['label']" [value]="type['code']">
                  {{ type.label | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width clearfix" appearance="outline">
              <mat-label>Label</mat-label>
              <input class="mat-block" [(ngModel)]="document.label" matInput name="label" placeholder="Label" />
            </mat-form-field>

            <mat-form-field class="full-width clearfix" appearance="outline">
              <mat-label>Description</mat-label>
              <textarea class="dentMsg increase-input-field" [(ngModel)]="document.description" name="groupDescription"
                matInput placeholder="Description" style="min-height: 70px"></textarea>
            </mat-form-field>

            <div class="full-width flex">
              <div class="margin-btm full-width clearfix" *ngIf="miniEdit != true && hideCheckBoxes != true">
                <mat-checkbox [(ngModel)]="document.isGallery" color="accent" name="isGallery">
                  Add To Gallery
                </mat-checkbox>
              </div>

              <div class="margin-btm full-width clearfix"
                *ngIf="isModulePrivateDocumentActive == true && hideCheckBoxes != true">
                <mat-checkbox [(ngModel)]="document.isPrivate" color="accent" name="isPrivate">
                  Is Private
                </mat-checkbox>
              </div>
            </div>
          </div>

          <div class="full-width flex">
            <div class="full-width row blob-for-ep-create-container-thumbnail">
              <div class="full-width" *ngIf="!document.fileID && documentID && isEdit == true">
                <h3 class="sr-title full-width text-center">File Uploader</h3>

                <app-ep-document-list id="document-create-view-link" [isDownload]="false" [isPrint]="false"
                  [canRemove]="false" [isModal]="false" [isFilter]="false" [defaultCheck]="false"
                  [isRedirection]="false" [canEdit]="false" [documents]="[documentID]" [title]="''" [canCheck]="false"
                  [listViewType]="'thumbnails'">
                </app-ep-document-list>
              </div>

              <div class="full-width" *ngIf="document.fileID || !documentID || isEdit != true">
                <h3 class="sr-title full-width text-center">File Uploader</h3>

                <app-ep-document-view-image class="squareImg squareImg-ep-create-editor"
                  *ngIf="!fileLink && !document.fileID && document && document.ID && !fileType" [hideZoomButton]="false"
                  [defaultPicture]="
                    'url(https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/doc-holder-editor-modified.png)'
                  " [document]="document">
                </app-ep-document-view-image>

                <app-ep-document-view-image class="squareImg squareImg-ep-create-editor"
                  *ngIf="fileLink && !document.fileID" [hideZoomButton]="false" [defaultPicture]="
                    'url(https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/doc-holder-editor-modified.png)'
                  " [link]="fileLink">
                </app-ep-document-view-image>

                <app-file-list class="full-width" *ngIf="document.fileID" [defaultLabel]="label" [showInfo]="false"
                  [title]="false" [files]="[document.fileID]" [canGallery]="false" [canPrivateDocument]="false"
                  [canEdit]="false" [canAction]="false">
                </app-file-list>

                <app-ep-document-view-image class="squareImg squareImg-ep-create-editor default-image-holder"
                  *ngIf="!fileLink && !document.fileID && fileType == 'image'" [hideZoomButton]="false"
                  [defaultPicture]="
                    'url(https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/image-icon-holder.png)'
                  ">
                </app-ep-document-view-image>

                <app-ep-document-view-image class="squareImg squareImg-ep-create-editor default-image-holder"
                  *ngIf="!fileLink && !document.fileID && fileType == 'video'" [hideZoomButton]="false"
                  [defaultPicture]="
                    'url(https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/video-icon-holder.png)'
                  ">
                </app-ep-document-view-image>

                <app-ep-document-view-image class="squareImg squareImg-ep-create-editor default-image-holder" *ngIf="
                    !fileLink &&
                    !document.fileID &&
                    fileType &&
                    (fileType == 'all' || fileType == 'imageDocument' || fileType == 'document')
                  " [hideZoomButton]="false" [defaultPicture]="
                    'url(https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/document-icon-holder.png)'
                  ">
                </app-ep-document-view-image>
              </div>
              <div class="clearfix full-width">
                <app-file-uploader class="text-center clearfix full-width" *ngIf="fileType == 'image'"
                  [fileName]="'File'" [allowedExtensions]="imageExtensions" [maxFileSize]="5" [isImageCropping]="true"
                  [canSkip]="true" [limit]="1" [uploadDirectly]="true" [isDragable]="true"
                  [title]="'CLICK HERE to upload (Max size: ' + 5 + 'MB)'" (onCompleteAll)="onCompleteFile($event)">
                </app-file-uploader>

                <app-file-uploader class="text-center clearfix full-width" *ngIf="fileType == 'document'"
                  [fileName]="'File'" [allowedExtensions]="documentExtensions" [maxFileSize]="5"
                  [isImageCropping]="true" [canSkip]="true" [limit]="1" [uploadDirectly]="true" [isDragable]="true"
                  [title]="'CLICK HERE to upload (Max size: ' + 5 + 'MB)'" (onCompleteAll)="onCompleteFile($event)">
                </app-file-uploader>

                <app-file-uploader class="text-center clearfix full-width" *ngIf="fileType == 'imageDocument'"
                  [fileName]="'File'" [allowedExtensions]="imageDocumentExtensions" [maxFileSize]="5"
                  [isImageCropping]="true" [canSkip]="true" [limit]="1" [uploadDirectly]="true" [isDragable]="true"
                  [title]="'CLICK HERE to upload (Max size: ' + 5 + 'MB)'" (onCompleteAll)="onCompleteFile($event)">
                </app-file-uploader>

                <app-file-uploader class="text-center clearfix full-width" *ngIf="fileType == 'video'"
                  [fileName]="'File'" [backgroundColor]="'none'" [allowedExtensions]="videoExtensions"
                  [maxFileSize]="50" [isImageCropping]="true" [canSkip]="true" [limit]="1" [uploadDirectly]="true"
                  [isDragable]="true" [title]="'CLICK HERE to upload (Max size: ' + 50 + 'MB)'"
                  (onCompleteAll)="onCompleteFile($event)">
                </app-file-uploader>

                <app-file-uploader class="text-center clearfix full-width" *ngIf="fileType == 'all' || !this.fileType"
                  [fileName]="'File'" [backgroundColor]="'none'" [maxFileSize]="10" [isImageCropping]="true"
                  [canSkip]="true" [limit]="1" [uploadDirectly]="true" [isDragable]="true"
                  [title]="'CLICK HERE to upload (Max size: ' + 10 + 'MB)'" (onCompleteAll)="onCompleteFile($event)">
                </app-file-uploader>
              </div>
            </div>

            <div class="full-width row blob-for-ep-create-container-thumbnail" *ngIf="hideThumbnail!=true">
              <div class="full-width">
                <h3 class="sr-title full-width text-center">Thumbnail</h3>
                <app-ep-document-view-image class="squareImg squareImg-ep-create-editor" *ngIf="thumbnailLink"
                  [hideZoomButton]="false" [defaultPicture]="
                    'url(https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/img-holder-editor-modified.png)'
                  " [link]="thumbnailLink">
                </app-ep-document-view-image>
                <app-ep-document-view-image class="squareImg squareImg-ep-create-editor" *ngIf="!thumbnailLink"
                  [hideZoomButton]="false" [defaultPicture]="
                    'url(https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/img-holder-editor-modified.png)'
                  ">
                </app-ep-document-view-image>
              </div>
              <div class="clearfix full-width">
                <app-file-uploader class="text-center clearfix full-width" [allowedExtensions]="allowedImageExtensions"
                  [aspectRatioType]="'full'" [maxFileSize]="1" [isImageCropping]="true" [isFixedAspectRatio]="true"
                  [canSkip]="true" [limit]="1" [uploadDirectly]="true" [isDragable]="true"
                  [title]="'CLICK HERE to upload (Max size: ' + 2 + 'MB)'"
                  (onCompleteAll)="onCompleteThumbnail($event)">
                </app-file-uploader>
              </div>
            </div>
          </div>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions>
        <hr />
        <div class="row clearfix">
          <button class="pull-right" [disabled]="!msgForm.form.valid" (click)="save()" mat-raised-button color="accent">
            Save
            <mat-icon>check</mat-icon>
          </button>

          <button class="pull-left" (click)="closeEvent()" mat-raised-button color="primary">Close</button>
        </div>
      </mat-dialog-actions>
    </form>
  </div>
</div>
