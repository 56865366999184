import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'textLength',
    pure: false
})

export class TextLengthPipe implements PipeTransform {

    transform(value: any, _length = 100): string {
        try {

            let length = Number(_length)
            if (length) {

                if (value && typeof value == 'string' && value.length > length) {
                    return value.substr(0, (length - 3)) + '...'
                }
                else if (value && typeof value == 'string' && value.length <= length) {
                    return value
                }
                else {
                    return value
                }
            }
            else if (length === 0) {
                return ''
            }
            else {
                return value;
            }
        }
        catch (e) {
            return value;
        }
    }

}