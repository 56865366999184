<div class="teethShap selected" (click)="openToothDetectionDialog(tooth)" val="42">
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150.58 247">
    <defs>
      <style>
        .cls-1 {
          fill: #fff;
        }
      </style>
    </defs>
    <title>teeth</title>
    <path *ngFor="let t of toothShape" [ngClass]="t.class" [attr.d]="t.d"/>
  </svg>
  <p class="topNumber" *ngIf="!tooth.hideNumber" style="color: black">{{ tooth.toothNumber }}</p>

  <div class="tooth-indicator-bar">
    <div class="tooth-indicator-bar-item" *ngIf="tooth.apical.length > 0" style="background-color: red"></div>
    <div class="tooth-indicator-bar-item" *ngIf="tooth.caries.length > 0" style="background-color: purple"></div>
    <div class="tooth-indicator-bar-item" *ngIf="tooth.restorations.length > 0" style="background-color: orange"></div>
  </div>
</div>
