<div class="rel dash intro">
  <div [hidden]="true">
    <img [src]="postCover" (error)="onError()" (load)="onLoad()"/>
  </div>

  <div
    class="card-header primary-gradient-img clearfix inModal rel"
    *ngIf="viewType == 'cards' && module && module.ID"
    [attr.style]="'background:linear-gradient(135deg, ' + color + ' 0%, ' + color + ' 100%) !important' | safeStyle"
    [attr.style]="
      'background:-moz-linear-gradient(135deg, ' + color + ' 0%, ' + color + ' 100%) !important' | safeStyle
    "
    [attr.style]="
      'background:-webkit-linear-gradient(135deg, ' + color + ' 0%, ' + color + ' 100%) !important' | safeStyle
    "
  >
    <div class="row clearfix content">
      <div class="pull-left image-thumbnail-card mr">
        <app-ep-document-view-image
          class="square-img-module module-image"
          [hideZoomButton]="false"
          [defaultPicture]="
            'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/bell_icon.png)'
          "
          [link]="postPictureThumbnail"
        >
        </app-ep-document-view-image>
      </div>

      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m">
          {{ module.Label }}
          <span class="subLabel">
            The following Subscription plans contain <strong>{{ module.Label }}</strong>
            module.
          </span>
        </h2>
      </div>
    </div>
  </div>

  <div
    class="card-header inModal module-view-header"
    *ngIf="viewType != 'cards'"
    [ngClass]="{ 'default-background-image': isImageError != false }"
    [attr.style]="' border-bottom:2px solid ' + color + ' !important' | safeStyle"
    [style.background-image]="postCoverURL"
  >
    <button class="btn-close btn-clear mat-button close-module" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>

    <div class="image-thumbnail" *ngIf="viewType == 'details'">
      <app-ep-document-view-image
        class="square-img-module module-image"
        [hideZoomButton]="false"
        [defaultPicture]="
          'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/bell_icon.png)'
        "
        [link]="postPictureThumbnail"
      >
      </app-ep-document-view-image>
    </div>

    <div class="full-width" *ngIf="module && module.ID">
      <span
        class="mr chip-module chip-disabled mat-elevation-z1 pull-right"
        *ngIf="module['Status.Code'] == 'DisableForAll'"
      >Not active</span
      >

      <span class="mr chip-module chip-beta mat-elevation-z1 pull-right" *ngIf="module['Status.Code'] == 'Beta'"
      >Beta</span
      >

      <span
        class="mr chip-module chip-enable mat-elevation-z1 pull-right"
        *ngIf="module['Status.Code'] == 'EnableForAll'"
      >Active</span
      >
    </div>
  </div>

  <mat-dialog-content id="module-dialog-content">
    <div class="full-width html-module-view-container" *ngIf="viewType == 'marketing'" [@slideInOut]>
      <app-ep-document-view
        class="full-width file-content-container-modal"
        *ngIf="EPDocumentID && module['Marketing_Conent_Type.Code'] === 'Document'"
        [isModal]="false"
        [contentOnly]="true"
        [documentID]="EPDocumentID"
        style="margin-top: -20px"
      >
      </app-ep-document-view>

      <div
        class="full-width clearfix"
        class="small"
        *ngIf="
          htmlContent &&
          (module['Marketing_Conent_Type.Code'] === 'Editor' || module['Marketing_Conent_Type.Code'] === 'Raw')
        "
        [innerHTML]="htmlContent | safeHtml"
      ></div>
    </div>
    <div class="full-width mt" *ngIf="viewType == 'details'" [@slideInOut]>
      <div class="module-icon-container">
        <mat-icon
          class="module-icon-back"
          *ngIf="module && module.ID && isIndexOf(module.Icon, 'fa-') == false"
          [style.color]="colorIcon"
        >
          {{ module.Icon }}</mat-icon
        >

        <mat-icon
          class="fas {{ module.Icon }}   module-icon-back "
          *ngIf="module && module.ID && isIndexOf(module.Icon, 'fa-') != false"
          [style.color]="colorIcon"
        >
        </mat-icon>
      </div>

      <div class="full-width detail-container" *ngIf="module && module.ID">
        <div class="full-width ml mt">
          <h2 class="rm-mb" [style.color]="color">
            {{ module.Label }}
          </h2>
        </div>

        <div class="row full-width clearfix add-mb">
          <p class="mb full-width ml mr">{{ module.Description }}</p>

          <div class="full-width ml mr" *ngIf="module.marketingDescriptions && module.marketingDescriptions.length > 0">
            <p class="rm-mb" *ngFor="let s of module.marketingDescriptions">
              <mat-icon class="fas fa-check-circle enable-icon-module smr" [style.color]="color"></mat-icon>
              {{ s }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="full-width flex mt" *ngIf="viewType == 'cards'">
      <div class="full-width row container-card-sub-plan" [@slideInOut]>
        <div class="thrd-width sub-plan-card-app flex row" *ngFor="let subscriptionPlan of subscriptionPlans">
          <app-subscription-plan-card
            class="full-width row sub-plan-card sub-plan-card-module-view"
            [selectTitle]="'More details...'"
            [canClose]="false"
            [isClosed]="true"
            [canCheck]="false"
            [subscriptionPlan]="subscriptionPlan"
            [isSubscriptionButton]="false"
            [isSelectButton]="true"
            [isViewButton]="false"
            (getSelect)="getSelect($event)"
          >
          </app-subscription-plan-card>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <hr *ngIf="module && module.ID"/>

  <div class="drop-button text-center full-width" *ngIf="module && module.ID">
    <button
      class="pull-left smt mr"
      *ngIf="marketingButton == true && viewType == 'marketing'"
      [attr.style]="'background:' + color + ' !important' | safeStyle"
      (click)="viewTypeEvent('details')"
      mat-raised-button
      color="accent"
    >
      <mat-icon class="fas fa-book-open mr"></mat-icon>
      Details
      <mat-icon class="sml">keyboard_arrow_up</mat-icon>
    </button>

    <button
      class="pull-left smt mr"
      *ngIf="viewType == 'cards'"
      [attr.style]="'background:' + color + ' !important' | safeStyle"
      (click)="viewTypeEvent('details')"
      mat-raised-button
      color="accent"
    >
      <mat-icon class="fas fa-book-open mr"></mat-icon>
      Details
    </button>

    <button
      class="pull-left smt mr"
      *ngIf="marketingButton == true && viewType == 'details'"
      [attr.style]="'background:' + color + ' !important' | safeStyle"
      (click)="viewTypeEvent('marketing')"
      mat-raised-button
      color="accent"
    >
      <mat-icon class="fas fa-lightbulb mr"></mat-icon>
      Discover!
      <mat-icon class="sml">keyboard_arrow_down</mat-icon>
    </button>

    <button
      class="pull-left smt ml mr"
      *ngIf="module && module.Wiki_URL"
      [attr.style]="'background:' + colorSecond + ' !important' | safeStyle"
      (click)="openWiki(module.Wiki_URL)"
      mat-raised-button
      color="primary"
    >
      <mat-icon class="fas fa-scroll mr"></mat-icon>
      {{ 'Wiki' | translate }}
    </button>

    <button
      class="pull-right btn-large order-now-button"
      *ngIf="
        displaySubscribeButton == true &&
        isModuleOwned != true &&
        subscriptionPlans &&
        subscriptionPlans.length > 0 &&
        viewType != 'cards'
      "
      (click)="viewModuleCard()"
      mat-raised-button
      color="accent"
    >
      GET IT NOW! <mat-icon class="fas fa-star smr sml"></mat-icon>
    </button>

    <button
      class="pull-right btn-large order-now-button"
      *ngIf="
        displaySubscribeButton == true &&
        isModuleOwned != true &&
        subscriptionPlans &&
        subscriptionPlans.length > 0 &&
        viewType == 'cards'
      "
      (click)="compare()"
      mat-raised-button
      color="accent"
    >
      COMPARE PLANS ({{ subscriptionPlans.length }})
    </button>

    <div class="own-item text-center pull-right" *ngIf="isModuleOwned == true">
      <span>INCLUDED</span>
      <mat-icon class="fas fa-thumbs-up smr ml"></mat-icon>
    </div>

    <button
      class="pull-right smt"
      *ngIf="displaySubscribeButton != true && isModuleOwned != true"
      [attr.style]="'background:' + colorSecond + ' !important' | safeStyle"
      (click)="closeEvent()"
      mat-raised-button
      color="primary"
    >
      Close
    </button>
  </div>
</div>
