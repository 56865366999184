<div class="negative-corner bg-light-gray">
  <div id="blueBg"></div>

  <h3 class="white-header annotation">Step 1</h3>

  <section class="introduction">
    <div class="container">
      <div class="row clearfix introduction-hero">
        <div class="text pull-right half-width">
          <div class="col-left">
            <h1 class="white-header">Setting up your portal</h1>
            <p class="white lead">
              Configure all your settings and lock in your details for optimal usage and seamless communication
            </p>
          </div>
        </div>
        <div class="text pull-left half-width">
          <img
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/gettingstarted-img4.png"
            mat-card-image/>
        </div>
      </div>
    </div>
  </section>

  <section class="top-section">
    <div class="container bg-white topIntro offset-content">
      <h2 class="heading-text">Settings</h2>
      <p class="paragraph-default">
        Just logged into your portal for the first time? Your screen should like something like this.
      </p>
    </div>
    <div class="container">
      <div class="basic-column centered-column offset-image">
        <div class="column">
          <img class="settings-image"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/gettingstarted-img5.jpg"
               alt=""/>
        </div>
        <div class="column">
          <p class="mt-3">Ok, let's get started!</p>
          <p class="getting-started-page">
            Click on your name in the top right-hand corner and go to 'my profile'. You then need to review/fill our all
            of your details and your {{ "KEYWORD.practice" | translate }} details, including any contact information,
            social media links, staff images, and more.
          </p>
          <p class="mt-3">
            Here is a helpful checklist along with how-to articles with detailed instructions to get you on the right
            track:
          </p>
        </div>
        <div class="article-section">
          <span class="article-head">Articles</span>
          <ul class="check">
            <li>
              <div class="text">
                <p class="getting-started-page">
                  <strong>Bookmark Portal</strong> - You should bookmark the portal landing page for easy access when
                  logging in.
                </p>
              </div>
              <a class="article-links" target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon>description</mat-icon>
              </a>
            </li>
            <li>
              <div class="text">
                <p class="getting-started-page">
                  <strong>Personal Profile</strong> - Add/confirm your details, an overview or background about
                  yourself, picture, and any social links you want to share with your
                  {{ "KEYWORD.patient" | translate }}s.
                </p>
              </div>
              <a class="article-links" target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon>description</mat-icon>
              </a>
            </li>
            <li>
              <div class="text">
                <p class="getting-started-page">
                  <strong>{{ "KEYWORD.practice" | translate | titlecase}} Profile and Details</strong> - Confirm
                  {{ "KEYWORD.practice" | translate }} details including a profile and any social links you want to
                  promote; don't forget photos!
                </p>
              </div>
              <a class="article-links" target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon>description</mat-icon>
              </a>
            </li>
            <li>
              <div class="text">
                <p class="getting-started-page">
                  <strong>{{ "KEYWORD.practice" | translate | titlecase}} Hours</strong> - Update hours so your
                  {{ "KEYWORD.patient" | translate }}
                  knows when they can get in touch or expect a response from you.
                </p>
              </div>
              <a class="article-links" href="#!">
                <mat-icon>description</mat-icon>
              </a>
            </li>
            <li>
              <div class="text">
                <p class="getting-started-page">
                  <strong>Import {{ "KEYWORD.patient" | translate | titlecase }} Data</strong> - Bulk upload your
                  {{ "KEYWORD.patient" | translate }} data.
                </p>
              </div>
              <a class="article-links" href="#!">
                <mat-icon>description</mat-icon>
              </a>
            </li>
            <li>
              <div class="text">
                <p class="getting-started-page">
                  <strong>Add Team Members</strong> - All team members should have a profile even if they don't use the
                  platform so you can communicate on their behalf.
                </p>
              </div>
              <a class="article-links" target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon>description</mat-icon>
              </a>
            </li>
            <li>
              <div class="text">
                <p class="getting-started-page">
                  <strong>Update {{ "KEYWORD.practice" | translate | titlecase}} Website</strong> - Let your
                  {{ "KEYWORD.patient" | translate }}s know you offer {{ "BRANDING.Brand Name" | translate | titlecase
                  }} payment
                  plans! We have designed a "plug and play" website so give the snippet of code to your web developer -
                  job done!
                </p>
              </div>
              <a class="article-links" target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon>description</mat-icon>
              </a>
            </li>
          </ul>
        </div>
        <div class="footer-links">
          <button class="btn-large mt btn-green"
                  [routerLink]="['/merchant', { outlets: { page: ['getting-started'] } }]" mat-raised-button
                  color="primary">
            <mat-icon fontSet="fas" fontIcon="fa-angle-left"></mat-icon>
            Back
          </button>
          <button class="btn-large mt btn-green"
                  [routerLink]="['/merchant', { outlets: { page: ['sending-treatments-plans'] } }]" mat-raised-button
                  color="primary">
            Step Two
            <mat-icon fontSet="fas" fontIcon="fa-angle-right"></mat-icon>
          </button>
        </div>
      </div>
    </div>
  </section>
</div>
