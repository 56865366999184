<mat-card class="card mat-elevation-z5 rm-mt">
  <div class="colour-stripe"></div>
  <h3 class="sr-title sm-h3">Identification</h3>
  <div class="row clearfix mb">
    <div class="column">
      <div class="icons-col">
        <mat-icon>person</mat-icon>
      </div>
      <div class="row-content-col summary">
        <div class="row clearfix">
          <div class="half-width">
            <div class="col-left">
              <div class="block">
                <label>Type:</label>
                <p class="larger">Value</p>
              </div>
            </div>
          </div>
          <div class="half-width">
            <div class="col-right">
              <div class="block">
                <label>ID:</label>
                <p class="larger">Value</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>
