import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { filter, map as _map } from 'lodash';
import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { BeneficiaryViewComponent } from '../../beneficiary/beneficiary-view/beneficiary-view.component';
import { TreatmentService } from '../shared/treatment.service';
import { treatmentUtil } from '../shared/treatmentUtil';
import { TreatmentBrandViewComponent } from '../treatment-brand-view/treatment-brand-view.component';
import { TreatmentCategoryViewComponent } from '../treatment-category-view/treatment-category-view.component';
import { TreatmentDocumentCreateComponent } from '../treatment-document-create/treatment-document-create.component';
import { TreatmentDocumentImportOverviewComponent } from '../treatment-document-import-overview/treatment-document-import-overview.component';
import { treatmentEditModalComponent } from '../treatment-edit-modal/treatment-edit-modal.component';
import { TreatmentProductCreateEditComponent } from '../treatment-product-create-edit/treatment-product-create-edit.component';
import { TreatmentProductViewComponent } from '../treatment-product-view/treatment-product-view.component';
import { TreatmentTypeViewComponent } from '../treatment-type-view/treatment-type-view.component';

@Component({
  selector: 'app-treatment-product-list-global',
  templateUrl: './treatment-product-list-global.component.html',
  styleUrls: ['./treatment-product-list-global.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: '0' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: '0' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class TreatmentProductListGlobalComponent implements OnInit {
  @Input()
  isActive;

  @Input()
  supplierID;

  @Input()
  manufacturerID;

  @Input()
  distributorID;

  @Input()
  typeID;

  @Input()
  canMultiSelect = false;

  @Output()
  getLength = new EventEmitter();

  @Input()
  categoryID;

  @Input()
  brandID;

  @Input()
  miniDisplay = false;

  @Input()
  microDisplay = false;

  @Input()
  defaultHeading = true;

  @Output()
  displaySlideToggle = new EventEmitter();

  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];
  treatmentUtil = new treatmentUtil();
  utils = new UtilsClass();
  displayedColumns = [
    'selectCheckBox',
    // 'list',
    'logoPic',
    'Product',
    // "Brand",
    // "cataloguePic",
    // "Manufacturer",
    // "Distributor",
    'itemCode',
    // "SellPrice",
    // "CostPrice",

    // "Date",

    'Documents',

    'ActiveStatus',
    'Actions',
  ];

  filters = [];
  selectedIDs = [];
  ActiveFilter;

  searchVals = new FormControl();
  // searchValList = ['Label', 'Category', 'Type', 'Brand', 'Manufacturer', 'Distributor'];
  searchValList = ['Product', 'Category', 'Type', 'Brand'];
  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  filteredSize = null;

  isPromoterOrAdmin = false;

  showDocList = false;
  docProductID;
  docListTitle;

  destroyEvent = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private treatmentService: TreatmentService,
    private customDate: CustomDatePipe,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });
  }

  ngOnInit() {
    if (this.microDisplay == true) {
      this.displayedColumns = [
        // 'selectCheckBox',
        'logoPic',
        'Product',
        // "Brand",
        // "Manufacturer",
        // "Distributor",
        // "cataloguePic",
        // "SellPrice",
        // "CostPrice",
        // "Date",
        // "ActiveStatus",
        // "Actions"
      ];
    }

    const params = {
      categoryID: this.categoryID,
      typeID: this.typeID,
      manufacturerID: this.manufacturerID,
      supplierID: this.supplierID,
      distributorID: this.distributorID,
      brandID: this.brandID,
      isActive: this.isActive,
    };

    this.listDB = new LoadRecords(
      this.treatmentService,
      this.destroyEvent,
      this.getLength,
      params,
      this.isPromoterOrAdmin
    );
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
    this.displaySlideToggle.emit(true);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.miniDisplay == true) {
      this.displayedColumns = [
        'selectCheckBox',
        'logoPic',
        'Product',
        // "Brand",
        // "Manufacturer",
        // "Distributor",
        // "cataloguePic",
        // "SellPrice",
        // "CostPrice",
        // "Date",
        'itemCode',
        'Documents',
        'ActiveStatus',
        'Actions',
      ];
    }

    const params = {
      categoryID: this.categoryID,
      typeID: this.typeID,
      manufacturerID: this.manufacturerID,
      supplierID: this.supplierID,
      distributorID: this.distributorID,
      brandID: this.brandID,
      isActive: this.isActive,
    };

    this.listDB = new LoadRecords(
      this.treatmentService,
      this.destroyEvent,
      this.getLength,
      params,
      this.isPromoterOrAdmin
    );
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
    this.displaySlideToggle.emit(true);
  }

  setFilter(event, field) {
    let filter;

    if (typeof event == 'object' && event != null) {
      const v = this.customDate.transform(event, Settings.global['dateFormat']);
      filter = {
        field,
        value: v,
      };
    } else if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  goToGuide() {
    const newWindow = window.open(
      'https://email-graphics-smileright.s3-ap-southeast-2.amazonaws.com/docs-19/Australian_Schedule-2.pdf',
      '_blank'
    );
  }

  goToShop() {
    const newWindow = window.open(
      'https://www.ada.org.au/Utility-Pages/Login?returnurl=/Utility-Pages/SSO?ssoreturnurl=https://ada.storehippo.com/',
      '_blank'
    );
  }

  selectAll() {
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      const o = this.dataSource.filteredData[i];

      if (o && o['ID'] && this.selectedIDs.indexOf(o['ID']) == -1) {
        this.selectedIDs.push(o['ID']);
      }
    }
  }

  selectPage() {
    const index = this.dataSource['_paginator']['index'] || 0;
    const pageSize = this.dataSource['_paginator']['pageSize'] || 0;

    for (let i = 0; i < (index + 1) * pageSize; i++) {
      const o = this.dataSource.filteredData[index * pageSize + i];

      if (o && o['ID'] && this.selectedIDs.indexOf(o['ID']) == -1) {
        this.selectedIDs.push(o['ID']);
      }
    }
  }

  select(id, isSelected) {
    if (isSelected == true && this.selectedIDs.indexOf(id) == -1) {
      this.selectedIDs.push(id);
    } else {
      const _index = this.selectedIDs.indexOf(id);
      this.selectedIDs.splice(_index, 1);
    }
  }

  viewType(id) {
    const ref = RootAppComponent.dialog.open(TreatmentTypeViewComponent, {
      data: {
        typeID: id,
        isGlobal: true,
      },

      width: '700px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewCategory(id) {
    const ref = RootAppComponent.dialog.open(TreatmentCategoryViewComponent, {
      data: {
        categoryID: id,
      },

      width: '700px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewBrand(id) {
    const ref = this.dialog.open(TreatmentBrandViewComponent, {
      data: {
        brandID: id,
        isGlobal: true,
      },

      width: '800px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  unselect() {
    this.selectedIDs = [];
  }

  isSelected(id) {
    if (!this.selectedIDs || this.selectedIDs.length <= 0) {
      return false;
    } else {
      if (this.selectedIDs.indexOf(id) != -1) {
        return true;
      }
    }

    return false;
  }

  enableGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialog = new ConfirmDialog(
        'cancel',
        'Enable Selected Products',
        '<p>Once the Product is enabled it will not appear as an option for the user.</p>',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              isActive: true,
              ids: this.selectedIDs.join(','),
            };
            this.treatmentService.editTreatmentProductGroupGlobal(payload).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Products are enabled.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  disableGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialog = new ConfirmDialog(
        'cancel',
        'Disable Selected Products',
        '<p>Once the Product is disabled it will not appear as an option for the user.</p>',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              isActive: false,
              ids: this.selectedIDs.join(','),
            };
            this.treatmentService.editTreatmentProductGroupGlobal(payload).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Products are disabled.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  enable(productID) {
    if (productID) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to enable this treatment product  ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          const payload = {
            isActive: true,
          };

          this.treatmentService.editTreatmentProductGlobal(productID, payload).subscribe((res) => {
            if (res) {
              NotifyAppComponent.displayToast('success', 'Successful operation', 'The Treatment product is enabled');
              ref.close();

              this.dataSource.replaceItem = res;
            }
          });
        }
      });
    }
  }

  disable(productID) {
    if (productID) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to disable this treatment product  ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          const payload = {
            isActive: false,
          };

          this.treatmentService.editTreatmentProductGlobal(productID, payload).subscribe((res) => {
            if (res) {
              NotifyAppComponent.displayToast('success', 'Successful operation', 'The Treatment product is disabled');
              ref.close();

              this.dataSource.replaceItem = res;
            }
          });
        }
      });
    }
  }

  add() {
    const ref = this.dialog.open(TreatmentProductCreateEditComponent, {
      data: {},
      width: '950px',
      panelClass: 'noCard',
    });

    ref.componentInstance.result.subscribe((res) => {
      if (res) {
        this.dataSource.addItem = res;
        ref.close();
      }
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  viewTreatment(id) {
    const ref = this.dialog.open(TreatmentProductViewComponent, {
      data: {
        productID: id,
        isGlobal: true,
      },

      width: '800px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  editTreatment(id) {
    const ref = this.dialog.open(TreatmentProductCreateEditComponent, {
      data: {
        productID: id,
      },
      width: '950px',
      panelClass: 'noCard',
    });

    ref.componentInstance.result.subscribe((res) => {
      if (res && res['ID']) {
        this.dataSource.replaceItem = res;
        ref.close();
      } else {
        this.dataSource.removeItem = id;
        ref.close();
      }
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  updateGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const ref = RootAppComponent.dialog.open(treatmentEditModalComponent, {
        width: '600px',
        panelClass: 'noCard',
      });
      ref.componentInstance.result.subscribe((payload) => {
        if (payload) {
          if (this.selectedIDs && this.selectedIDs.length > 0) {
            const ids = this.selectedIDs.join(',');
            payload['ids'] = ids;

            this.treatmentService.editTreatmentProductGroupGlobal(payload).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Products are updated.');
              }

              ref.close();
            });
          } else {
            ref.close();
          }
        } else {
          ref.close();
        }
      });
    }
  }

  addDocumentGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialog = new ConfirmDialog(
        'cancel',
        'Add New Document',
        '<p>You are about to create a document for multiple treatment products</p>',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const ref2 = this.dialog.open(TreatmentDocumentCreateComponent, {
              width: '750px',
              data: {
                multiCreate: true,
              },
            });

            ref2.componentInstance.getDocument.subscribe((payload) => {
              if (payload) {
                payload.ids = this.selectedIDs.join(',');

                this.treatmentService.addDocumentTreatmentProductGroupGlobal(payload).subscribe((res) => {
                  if (res && res.length > 0) {
                    ref2.close();
                    this.selectedIDs = [];

                    NotifyAppComponent.displayToast('success', 'Success!', 'Documents are added.');
                  }
                });
              }
            });

            ref2.componentInstance.close.subscribe((res) => {
              ref2.close();
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  addDocument(productID) {
    const ref = this.dialog.open(TreatmentDocumentCreateComponent, {
      width: '750px',
      data: {
        productID,
      },
    });

    ref.componentInstance.create.subscribe((res) => {
      if (res) {
        ref.close();
      }
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  importDocument(productID) {
    const ref = RootAppComponent.dialog.open(TreatmentDocumentImportOverviewComponent, {
      data: {
        readonly: false,
        displayUserDocuments: false,
        loadUserDocument: false,
        selectedView: 'system',
        displayUserDocumentType: false,
        displayICDocumentType: true,
        isICSideMenu: true,
        isUserSideMenu: false,
      },
      width: '90vw'
    });

    ref.componentInstance.getDocuments.subscribe((res) => {
      if (res && res.length > 0) {
        this.treatmentService.importDocumentTreatmentProduct(productID, res).subscribe((res2) => {
          ref.close();
        });
      }
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  importDocumentGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const ref = RootAppComponent.dialog.open(TreatmentDocumentImportOverviewComponent, {
        data: {
          readonly: false,
          displayUserDocuments: false,
          loadUserDocument: false,
          selectedView: 'system',
          displayUserDocumentType: false,
          displayICDocumentType: true,
          isICSideMenu: true,
          isUserSideMenu: false,
        },
        width: '90vw'
      });

      ref.componentInstance.getDocuments.subscribe((res) => {
        if (res && res.length > 0) {
          this.treatmentService.importDocumentTreatmentProductGroup(this.selectedIDs, res).subscribe((res2) => {
            ref.close();
          });
        }
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewSupplier(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(BeneficiaryViewComponent, {
        data: id,
        width: '600px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewDocuments(id, label) {
    this.showDocList = true;
    this.docProductID = id;
    this.docListTitle = 'Product Documents for: ' + label;
  }

  hideViewDetails() {
    this.showDocList = false;
  }

  isNew(date) {
    if (date) {
      const days = this.utils.daysPast(date);

      if (days < 15) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  expandProduct(item) {
    this.dataSource.showAllItem = item;
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  util = new UtilsClass();
  serviceRef;
  isDestoyed = false;

  constructor(
    private treatmentService: TreatmentService,
    private destroyEvent,
    private getLength,
    params,
    private isPromoterOrAdmin
  ) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    const payload = {
      section: 0,

      supplierID: params.supplierID,
      categoryID: params.categoryID,
      typeID: params.typeID,
      merchantID: params.merchantID,
      manufacturerID: params.manufacturerID,
      distributorID: params.distributorID,
      orderBy: 'Label',
      isNullProduct: false,
      brandID: params.brandID,
      isActive: params.isActive,
      fields:
        'ID,LastModified,Item_Code.Effective,DateTimeCreated,Brand_key,Brand.Label,Type_key,Type.Label,Category_key,Category.Label,Active,Label,DefaultPrice.Sell,DefaultPrice.Cost.Item' +
        ',Manufacturer_Key.Effective,Manufacturer.Name.Effective,Distributor_Key.Effective,Distributor.Name.Effective,NumberOfDocuments,' +
        'AssociatedDocuments.ProdAndInherited.SRVPRE,AssociatedDocuments.ProdAndInherited.SRVPST,AssociatedDocuments.ProdAndInherited.SRVMKT,' +
        'AssociatedDocuments.ProdAndInherited.SRVSPL,AssociatedDocuments.ProdAndInherited.SRV,AssociatedDocuments.ProdAndInherited.SRVSTC',
    };

    this.serviceRef = this.treatmentService.getTreatmentProductListGlobal(payload).subscribe((res) => {
      if (res && res.length > 0) {
        for (let i = 0; i < res.length; i++) {
          res[i]['fullLabel'] = res[i]['Label'] + ' ' + res[i]['Item_Code.Effective'];
        }
      }

      this.count = res.length;
      this.items = res;

      if (this.items && this.items.length) {
        this.getLength.emit(this.items.length);
      }

      this.dataChange.next(this.items.slice());
      this.firstLoad = true;

      const innerFunction = (section) => {
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.treatmentService.getTreatmentProductListGlobal(payload).subscribe((res) => {
          if (res.length > 0) {
            for (let i = 0; i < res.length; i++) {
              res[i]['fullLabel'] = res[i]['Label'] + ' ' + res[i]['Item_Code.Effective'];
            }

            this.count = res.count;
            this.items = this.items.concat(res);

            if (this.items && this.items.length) {
              this.getLength.emit(this.items.length);
            }

            this.dataChange.next(this.items.slice());

            if (this.isDestoyed != true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };
      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';
  expandingItems = [];

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  set removeItem(id) {
    const data = this._tableDatabase.data.filter((row) => row.ID != id);
    this._tableDatabase.dataChange.next(data);
  }

  set addItem(res) {
    this._tableDatabase.data.unshift(res);

    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  set replaceItem(item) {
    const _index = this._tableDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    this._tableDatabase.data[_index] = item;
    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  set showAllItem(item) {
    this.expandingItems.push(item.Label);
    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        let data = this.getMergedData(this._tableDatabase.data.slice());
        data = data.slice().filter((item: any) => {
          let f = '';
          f = item[this.field] || '';

          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        data = this.getSortedData(data.slice());
        this.filteredData = data.slice();

        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  getMergedData(data: any[]) {
    const key = 'Label';

    const uniqueData = [...new Map(data.map((item) => [item[key], item])).values()];

    const finalData = [];
    uniqueData.forEach((it) => {
      const l = data.filter((o) => o[key] === it[key]).length;

      const isMerged = !this.expandingItems.includes(it[key]) && l > 1;
      if (isMerged) {
        it.isMerged = true;
        it.mergedLength = l;

        const filteredItems = filter(data, (item) => {
          if (item && item[key] && item[key] == it[key]) {
            return true;
          }

          return false;
        });

        if (filteredItems && filteredItems.length > 0) {
          const fullLabel = _map(filteredItems, (item) => {
            return item['Item_Code.Effective'];
          });

          if (fullLabel && fullLabel.length > 0) {
            it['fullLabel'] = it['Label'] + ' ' + fullLabel.join('|');
          }
        }

        finalData.push(it);
      } else {
        it.isMerged = false;
        it['fullLabel'] = it['Label'] + ' ' + it['Item_Code.Effective'];
        finalData.push(...data.filter((o) => o[key] === it[key]));
      }
    });

    return finalData;
  }

  disconnect() {}

  getSortedData(data: any[]): any[] {
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'Category':
          [propertyA, propertyB] = [a['Category.Label'], b['Category.Label']];
          break;
        case 'Type':
          [propertyA, propertyB] = [a['Type.Label'], b['Type.Label']];
          break;
        case 'Brand':
          [propertyA, propertyB] = [a['Brand.Label'], b['Brand.Label']];
          break;
        case 'Product':
          [propertyA, propertyB] = [a['Label'], b['Label']];
          break;

        case 'SellPrice':
          [propertyA, propertyB] = [a['DefaultPrice.Sell'], b['DefaultPrice.Sell']];
          break;
        case 'CostPrince':
          [propertyA, propertyB] = [a['DefaultPrice.Cost.Item'], b['DefaultPrice.Cost.Item']];
          break;

        case 'Manufacturer':
          [propertyA, propertyB] = [a['Manufacturer.Name.Effective'], b['Manufacturer.Name.Effective']];
          break;

        case 'Distributor':
          [propertyA, propertyB] = [a['Distributor.Name.Effective'], b['Distributor.Name.Effective']];
          break;

        case 'Types':
          [propertyA, propertyB] = [a['NumberOfTypes'], b['NumberOfTypes']];
          break;

        case 'Brands':
          [propertyA, propertyB] = [a['NumberOfBrands'], b['NumberOfBrands']];
          break;

        case 'Products':
          [propertyA, propertyB] = [a['NumberOfProducts'], b['NumberOfProducts']];
          break;

        case 'Documents':
          [propertyA, propertyB] = [a['NumberOfDocuments'], b['NumberOfDocuments']];
          break;

        case 'Date':
          [propertyA, propertyB] = [new Date(a['DateTimeCreated']).getTime(), new Date(b['DateTimeCreated']).getTime()];
          break;

        case 'itemCode':
          [propertyA, propertyB] = [a['Item_Code.Effective'], b['Item_Code.Effective']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
