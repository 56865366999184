<div class="rel" *ngIf="module && module.isAvailable == true" @ngIfAnimation>
  <h1 class="dataHeader rm-m">
    <!-- Contracts -->

    <button
      class="pull-right"
      [routerLink]="['/merchant/', { outlets: { page: ['site-settings', 'modules'] } }]"
      [routerLinkActive]="['active']"
      mat-raised-button
      color="accent"
    >
      <mat-icon>vpn_key</mat-icon>
      Modules
    </button>

    {{ module['Label'] }}

    <span
      class="badge"
      *ngIf="moduleAccessListMerged && moduleAccessListMerged.length > 0"
      [endVal]="moduleAccessListMerged | FilterArrayMultipleContainValue: 'Name':filterName | lengthOfArray"
      countUp
    ></span>
    <span class="chip2 chipGood" *ngIf="module.isActive == true && module.isBeta == true">Active</span>
    <span class="chip2 chipGood" *ngIf="module.isActive == true && module.isBeta == false">Active</span>
    <span class="chip2 chipBad" *ngIf="module.isActive == false && module.isBeta == false">Inactive</span>
    <span class="chip2 chipWarning" *ngIf="module.isActive == false && module.isBeta == true">Beta</span>
  </h1>

  <p class="small rm-m">{{ module['Description'] }}</p>

  <div class="pull-right" *ngIf="isModal == true">
    <button class="btn-close btn-clear mat-button abs-right" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>

<div class="row clearfix">
  <mat-card class="list-card clearfix">
    <div class="row clearfix searchArea primary-gradient-img">
      <div class="full-width row clearfix flex" id="search">
        <mat-form-field
          class="full-width clearfix"
          *ngIf="module && moduleAccessListMerged && moduleAccessListMerged.length > 0 && isLoaded == true"
        >
          <mat-label>Merchant name filter</mat-label>

          <input
            class="full-width"
            [(ngModel)]="filterName"
            name="linkLabel"
            matInput
            placeholder="Merchant name filter"
          />
        </mat-form-field>
      </div>
    </div>

    <div
      class="clearfix full-width mt"
      *ngIf="module && moduleAccessListMerged && moduleAccessListMerged.length > 0 && isLoaded == true"
    >
      <div class="flex module-card-flex">
        <mat-card
          class="card module-card"
          id="module-card-tips"
          *ngFor="
            let a of moduleAccessListMerged | FilterArrayMultipleContainValue: ['Name']:filterName;
            let index = index
          "
          [class.disabledModule]="a.isActive == false"
        >
          <div class="row clearfix full-width">
            <app-merchant-view-picture class="text-center merchView mr pull-left" [merchantID]="a['ID']">
            </app-merchant-view-picture>

            <p class="rm-m">
              {{ a['Name'] }}
            </p>
          </div>
          <div class="text-center clearfix block">
            <div class="row">
              <button class="pull-right text-center" [matMenuTriggerFor]="menu" mat-button>
                Actions <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button *ngIf="a['ID']" (click)="openQuickViewDialog(a['ID'])" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span>View merchant</span>
                </button>

                <button *ngIf="a['ID']" (click)="contact(a['ID'])" mat-menu-item>
                  <mat-icon>message</mat-icon>
                  <span>Contact merchant</span>
                </button>

                <button *ngIf="isPromoterOrAdmin == true && a['ID']" (click)="viewMerchantLogs(a['ID'])" mat-menu-item>
                  <mat-icon class="fa fa-list-alt"></mat-icon>
                  <span>View merchant logs</span>
                </button>

                <button *ngIf="isPromoterOrAdmin == true && a['ID']" (click)="merchantAccess(a['ID'])" mat-menu-item>
                  <mat-icon class="fa fa-key"></mat-icon>
                  <span>Merchant Access</span>
                </button>
              </mat-menu>
            </div>
            <div class="row">
              <mat-slide-toggle
                [(ngModel)]="a['isActive']"
                (change)="sliderChange($event, a['ID'])"
                name="{{ a['ID'] }}+{{ index }}"
              >
                <span class="chip2 chipGood" *ngIf="a.isActive == true">Active</span>

                <span class="chip2 chipBad" *ngIf="a.isActive == false">Inactive</span>
              </mat-slide-toggle>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </mat-card>
</div>

<div
  class="full-width clearfix"
  *ngIf="
    (!module ||
      !moduleAccessListMerged ||
      (moduleAccessListMerged && moduleAccessListMerged.length == 0) ||
      (module && module.isAvailable != true)) &&
    isLoaded == true
  "
>
  <app-empty-list-message
    class="clear mt full-width"
    *ngIf="!module && isLoaded == true"
    [title]="'Module not  found'"
    [message]="'There is no EP module with selected code'"
  >
  </app-empty-list-message>

  <app-empty-list-message
    class="clear mt full-width"
    *ngIf="
      module &&
      (!moduleAccessListMerged || (moduleAccessListMerged && moduleAccessListMerged.length == 0)) &&
      isLoaded == true
    "
    [title]="'No user to show'"
    [message]="'No user has access to this module'"
  >
  </app-empty-list-message>
</div>
