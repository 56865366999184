import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthenticationService } from '../shared/authentication.service';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.css'],
})
export class ErrorModalAuthenticationComponent implements OnInit {
  @Output()
  closeModal = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  closeModalEvent() {
    this.closeModal.emit(true);
  }
  contactUS() {
    AuthenticationService.contactUSEvent.emit(true);
  }
}
