import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ClipboardService } from 'ngx-clipboard';
import { ConfirmDialogSingleButton } from '../../types/confirm-dialog-single-button';
import { NotifyAppComponent } from '../../types/notify-app-component';
import { Settings } from '../../types/settings';
import { UtilsClass } from '../../types/utils/utils.class';
import { ConfirmDialogSingleButtonComponent } from '../confirm-dialog-single-button/confirm-dialog-single-button.component';
import { RootAppComponent } from '../root-component/root-component.component';

@Component({
  selector: 'app-html-popup-input',
  templateUrl: './html-popup-input.component.html',
  styleUrls: ['./html-popup-input.component.css'],
})
export class HtmlPopupInputComponent implements OnInit {
  title = 'Checkout this  Link';
  description =
    'Insert a Link to be added to the Popup, Click on Copy Click Event to be added to a link or button in your code.';

  pageTitle;

  link;

  buttonFontSizerem = [
    '0.5rem',
    '0.6rem',
    '0.7rem',
    '0.8rem',
    '0.9rem',
    '1rem',
    '1.1rem',
    '1.2rem',
    '1.3rem',
    '1.4rem',
    '1.5rem',
    '1.6rem',
    '1.7rem',
    '1.8rem',
    '1.9rem',
    '2rem',
    '2.1rem',
    '2.2rem',
    '2.3rem',
    '2.4rem',
    '2.5rem',
    '2.6rem',
    '2.7rem',
    '2.8rem',
    '2.9rem',
    '3rem',
    '3.1rem',
    '3.2rem',
  ];

  buttonFontSizepx = [
    '8px',
    '9px',
    '10px',
    '11px',
    '12px',
    '13px',
    '14px',
    '15px',
    '16px',
    '17px',
    '18px',
    '19px',
    '20px',
    '21px',
    '22px',
    '23px',
    '24px',
    '25px',
    '26px',
    '27px',
    '28px',
    '29px',
    '30px',
  ];

  isModal = true;
  popupHeight = 800;
  popupWidth = 600;
  popupTop = 100;
  popupLeft = 100;

  isLargeButton = true;
  buttonStyle;
  buttonColor = '#ffffff';
  buttonFontSize = '1.1rem';
  buttonBackground = Settings.global['primaryColor'];

  buttonText = 'Click Here';

  @Output()
  close = new EventEmitter();
  @Output()
  getResult = new EventEmitter();
  @Output()
  getStyle = new EventEmitter();

  @Output()
  getContent = new EventEmitter();

  style;
  content;

  eventContent;
  preview;

  utils = new UtilsClass();

  componentID = 'ID' + btoa(Math.random().toString()).substring(2, 20);

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private _clipboardService: ClipboardService
  ) {}

  ngOnInit() {}

  build() {
    let inlineStyle = `color: #ffffff;background: #1e88e5; font-size: 1.1rem;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    box-sizing: border-box; position: relative;  -webkit-user-select: none; -moz-user-select: none;
    -ms-user-select: none; user-select: none; cursor: pointer; outline: 0;
    border: none;-webkit-tap-highlight-color: transparent;  display: inline-block;
    white-space: nowrap;text-decoration: none;    vertical-align: baseline;
    text-align: center;margin: 0; min-width: 64px ; line-height: 36px; overflow: visible;
    transform: translate3d(0,0,0);
    transition: background .4s cubic-bezier(.25,.8,.25,1),box-shadow 280ms cubic-bezier(.4,0,.2,1);
    text-rendering: auto;  appearance: auto;   writing-mode: horizontal-tb;
    word-spacing: normal;   text-indent: 0px;  text-shadow: none;border-radius: 4px;
    align-items: flex-start;
    `;

    this.style = `/* START: ${this.title} Button*/\n\n#${this.componentID}{\n
      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
      box-sizing: border-box !important;
      position: relative !important;
      -webkit-user-select: none !important;
      -moz-user-select: none !important;
      -ms-user-select: none !important;
      user-select: none !important;
      cursor: pointer !important;
      outline: 0 !important;
      border: none !important;
      -webkit-tap-highlight-color: transparent !important;
      display: inline-block !important;
      white-space: nowrap !important;
      text-decoration: none !important;
      vertical-align: baseline !important;
      text-align: center !important;
      margin: 0 !important;
      min-width: 64px !important;
      line-height: 36px !important;
      overflow: visible !important;
      transform: translate3d(0,0,0) !important;
      transition: background .4s cubic-bezier(.25,.8,.25,1),box-shadow 280ms cubic-bezier(.4,0,.2,1) !important;
      text-rendering: auto !important;
      appearance: auto !important;
      writing-mode: horizontal-tb !important;
      word-spacing: normal !important;
      text-indent: 0px !important;
      text-shadow: none !important;
      border-radius: 4px  !important;
      align-items: flex-start !important;\n`;

    if (this.isLargeButton == true) {
      this.style =
        this.style +
        `letter-spacing: 0.5px !important;
      padding: 8px 20px !important;
      text-transform: capitalize !important;\n`;

      inlineStyle = inlineStyle + `letter-spacing: 0.5px;  padding: 8px 20px;text-transform: capitalize;`;
    }

    if (this.buttonBackground) {
      this.style = this.style + `background:${this.buttonBackground} !important;\n`;
      inlineStyle = inlineStyle + `background:${this.buttonBackground};`;
    } else {
      this.style = this.style + `background:#1e88e5 !important;\n`;
      inlineStyle = inlineStyle + `background:#1e88e5;`;
    }
    if (this.buttonColor) {
      this.style = this.style + `color:${this.buttonColor} !important;\n`;
      inlineStyle = inlineStyle + `color:${this.buttonColor} ;`;
    } else {
      this.style = this.style + `color:#ffffff  !important;\n`;
      inlineStyle = inlineStyle + `color:#ffffff  ;`;
    }
    if (this.buttonFontSize) {
      this.style = this.style + `font-size:${this.buttonFontSize} !important;\n`;
      inlineStyle = inlineStyle + `font-size:${this.buttonFontSize} ;`;
    } else {
      this.style = this.style + `font-size: 1.1rem !important;\n`;
      inlineStyle = inlineStyle + `font-size: 1.1rem ;`;
    }

    this.style = this.style + `}\n/* END: ${this.title} Button*/\n\n`;

    let link = this.link;

    if (link.indexOf('http') === -1) {
      link = 'https://' + link;
    }

    this.eventContent = `onclick='window.open("${link}", "${this.title}","directories=no,titlebar=no,toolbar=no,location=no,status=no,resizable=no,menubar=no,scrollbars=no,popup=yes,width=${this.popupWidth},height=${this.popupHeight},top=${this.popupTop},left=${this.popupLeft}");'`;
    this.content = `<!--START: ${this.title} Button-->\n<button  id="${this.componentID}" style="${inlineStyle}"
    ${this.eventContent}
    >${this.buttonText}</button>\n<!--END:  ${this.title} Button-->`;

    this.preview = this.utils.getPreviewHtmlContent(this.content, this.style);
  }

  previewBuild() {
    this.build();
    let link = this.link;

    if (link.indexOf('http') === -1) {
      link = 'https://' + link;
    }
    window.open(
      link,
      this.title,
      `directories=no,titlebar=no,toolbar=no,location=no,status=no,resizable=no,menubar=no,scrollbars=no,popup=yes,width=${this.popupWidth},height=${this.popupHeight},top=${this.popupTop},left=${this.popupLeft}`
    );
  }

  Copy(d) {
    if (d) {
      this._clipboardService.copy(d);

      NotifyAppComponent.displayToast('success', 'success', 'Copied');
    }
  }

  inserResult(isCopy = false) {
    this.build();

    const p = {
      style: this.style,
      content: this.content,
      isCopy,
      isReplace: false,
    };

    if (isCopy == true) {
      this.Copy(p.content);
    }

    this.getResult.emit(p);
    this.getStyle.emit(p.style);
    this.getContent.emit(p.content);
  }

  copyClickEvent() {
    this.build();

    if (this.eventContent) {
      const show = localStorage.getItem('dontShowClickPopupEvent');

      if (show === 'true') {
        const p = {
          content: this.eventContent,
          isCopy: true,
          isReplace: false,
        };

        this.Copy(p.content);

        this.getResult.emit(p);
        this.getStyle.emit(null);
        this.getContent.emit(null);
      } else {
        const confirmDialog2 = new ConfirmDialogSingleButton(
          'fas fa-copy',
          'Click Event',
          "<p style='text-align:center;'>The <strong>onClick</strong> Event will be copied to your clipboard , you can add it  to <strong> 'a' </strong> or <strong>'button'</strong> Tag.</p>",
          'Proceed',
          true
        );
        const ref2 = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
          data: confirmDialog2,
          width: '500px',
        });
        ref2.componentInstance.onClick.subscribe((res) => {
          if (res === true) {
            localStorage.setItem('dontShowClickPopupEvent', 'true');
          }

          ref2.close();
          ref2.afterClosed().subscribe(() => {
            const p = {
              content: this.eventContent,
              isCopy: true,
              isReplace: false,
            };

            this.Copy(p.content);

            this.getResult.emit(p);
            this.getStyle.emit(null);
            this.getContent.emit(null);
          });
        });
      }
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
}
