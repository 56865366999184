import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContactService } from '../shared/contact.service';

@Component({
  selector: 'app-contact-view-picture',
  templateUrl: './contact-view-picture.component.html',
  styleUrls: ['./contact-view-picture.component.css'],
})
export class ContactViewPictureComponent implements OnInit {
  @Input() contactID;
  @Input() LastModified = null;
  @Input() hideZoomButton;
  @Output() close = new EventEmitter();

  profileLink;

  constructor(
    private contactService: ContactService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.contactID = data;
    }
  }

  ngOnInit() {
    if (this.contactID) {
      this.getProfilePicture();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.contactID.previousValue != changes.contactID.currentValue) {
      if (this.contactID) {
        this.getProfilePicture();
      } else {
        this.profileLink = null;
      }
    }
  }

  getProfilePicture() {
    this.profileLink = null;

    this.profileLink = this.contactService.getProfilePicStreamLinkCached(this.contactID, this.LastModified);
  }

  closeEvent() {
    this.close.emit(true);
  }
}
