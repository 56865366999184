import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { of } from 'rxjs';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../../../../../../src/environments/environment';
import { AppStateService } from '../../../../../../../core/app-state/app-state.service';
import { BinaryBoolPipe } from '../../../../../../../shared/pipes/binary-bool/binary-bool.pipe';
import { UtilsService } from '../../../../../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../../../../../shared/types/notify-app-component';
import { Beneficiary, BeneficiaryService } from '../../../../../../beneficiary/shared/beneficiary.service';
import {
  ProductCatalogueItem,
  ProductCatalogueService,
} from '../../../../../shared/services/product-catalogue.service';

@Component({
  selector: 'ipv-retail-product-form',
  templateUrl: './retail-product-form.component.html',
  styleUrls: ['./retail-product-form.component.css'],
  providers: [BinaryBoolPipe],
})
export class RetailProductFormComponent {
  @Input() existingProduct = null;
  @Input() formMode: 'create' | 'update' = 'create';
  @Input() selectedSupplierKey: string;

  suppliers: Beneficiary[] = [];

  viewModel$ = this.appStateService.getAppState$().pipe(
    tap(() => {
      if (this.existingProduct) {
        this.product = this.existingProduct;
        this.Is_Available = this.binaryBoolPipe.transform(this.product.Is_Available);
        this.Is_SerialNum_Mandatory = this.binaryBoolPipe.transform(this.product.Is_SerialNum_Mandatory);
        this.setFileName();
      }
    }),
    mergeMap((appState) => {
      return this.beneficiaryService.fetchBeneficiaries$<Beneficiary>().pipe(
        map((beneficiaries) => {
          this.suppliers = beneficiaries;
          if (this.selectedSupplierKey) {
            this.product.Supplier_key = this.selectedSupplierKey;
          }
          return of(appState);
        })
      );
    })
  );

  product: ProductCatalogueItem = {
    Product: '',
    Model: '',
    Product_Type_key: '',
    Stock_Keeping_Unit: '',
    Universal_Product_Code: '',
    SellPrice: 0,
    CostPrice: 0,
    TaxRate: 0,
    Is_SerialNum_Mandatory: '0',
    Is_Available: '0',
    Size: '',
    Colour: '',
    Weight: '',
    Width: '',
    Height: '',
    Length: '',
    Manufactured_Year: '',
    Thumbnail_Doc_key: '',
    ProfileImage_Doc_key: '',
    AdditionalImages_Doc_key: '',
    Description: '',
    Supplier_key: '',
  };

  Is_Available = false;
  Is_SerialNum_Mandatory = false;

  fileName = '';

  hasThumbnail = false;
  existingThumbnailSrc = '';

  hasProfileImage = false;
  existingProfileImageSrc = '';

  existingAdditionalImages: string[] = [];

  constructor(
    private appStateService: AppStateService,
    private utilsService: UtilsService,
    private dialogRef: MatDialogRef<RetailProductFormComponent>,
    private productCatalogueService: ProductCatalogueService,
    private binaryBoolPipe: BinaryBoolPipe,
    private beneficiaryService: BeneficiaryService
  ) {}

  create() {
    this.productCatalogueService
      .createProductCatalogueItem$<ProductCatalogueItem>(this.product)
      .pipe(
        switchMap((applianceWithID) => {
          return this.productCatalogueService.addProductCatalogueItem$(applianceWithID);
        })
      )
      .subscribe(() => {
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Successfully created product');
        this.dialogRef.close();
      });
  }

  update() {
    this.productCatalogueService
      .updateProductCatalogueItem$(this.product, this.product)
      .pipe(
        switchMap((appliance) => {
          return this.productCatalogueService.editProductCatalogueItem$(appliance);
        })
      )
      .subscribe(() => {
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Successfully updated product');

        this.dialogRef.close();
      });
  }

  setThumbnailImage(fileID: string[]) {
    if (fileID && fileID[0]) {
      this.product.Thumbnail_Doc_key = fileID[0];
      this.changeThumbnailImage();
    }
  }

  changeThumbnailImage() {
    this.utilsService.getFileDetails(this.product.Thumbnail_Doc_key).subscribe((res) => {
      if (res) {
        if (res.contentType === 'image/png' || res.contentType === 'image/jpg' || res.contentType === 'image/jpeg') {
          this.hasThumbnail = true;
          this.existingThumbnailSrc = `${environment.nodeUrl}/files/file-view/${this.product.Thumbnail_Doc_key}`;
        } else {
          NotifyAppComponent.displayToast(
            'warning',
            'Error during operation',
            'Format of image should be png/jpg/jpeg'
          );
        }
      }
    });
  }

  setProfileImage(fileID: string[]) {
    if (fileID && fileID[0]) {
      this.product.ProfileImage_Doc_key = fileID[0];
      this.changeProfileImage();
    }
  }

  changeProfileImage() {
    this.utilsService.getFileDetails(this.product.ProfileImage_Doc_key).subscribe((res) => {
      if (res) {
        if (res.contentType === 'image/png' || res.contentType === 'image/jpg' || res.contentType === 'image/jpeg') {
          this.hasProfileImage = true;
          this.existingProfileImageSrc = `${environment.nodeUrl}/files/file-view/${this.product.ProfileImage_Doc_key}`;
        } else {
          NotifyAppComponent.displayToast(
            'warning',
            'Error during operation',
            'Format of image should be png/jpg/jpeg'
          );
        }
      }
    });
  }

  setFileName() {
    const imageDate = new Date()
      .toLocaleString()
      .replace(/\//g, '-')
      .replace(/,/g, '_')
      .replace(/\s/g, '')
      .replace(/:/g, '-');

    this.fileName = `${this.product.Product}_${imageDate}`;
  }

  addAdditionalImage(fileID: string[]) {
    if (fileID && fileID.length > 0) {
      this.existingAdditionalImages = [...this.existingAdditionalImages, fileID[0]];
      this.product.AdditionalImages_Doc_key = this.existingAdditionalImages.join('|');
    }
  }

  removeFile(fileID: string) {
    this.existingAdditionalImages = this.existingAdditionalImages.filter((imageID) => imageID !== fileID);
    this.product.AdditionalImages_Doc_key = this.existingAdditionalImages.join('|');
  }

  setIsSerialNumMandetory(checked: boolean) {
    this.product.Is_SerialNum_Mandatory = checked ? '1' : '0';
  }

  setIsAvailable(checked: boolean) {
    this.product.Is_Available = checked ? '1' : '0';
  }

  close() {
    this.dialogRef.close();
  }
}
