<mat-dialog-content class="reduce">
  <div class="card-header primary-gradient-img clearfix inModal noShrink">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear mat-button white" (click)="close()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m">
          Problems?
          <span class="subLabel">
            All questions in this form are in English. If you are having problems completing this form in English,
            please talk to one of our staff members for help or seek assistance from a family member to complete the
            form.
          </span>
        </h2>
      </div>
    </div>
  </div>
  <div class="row clearfix text-center" #mandarin>
    <h3 class="sr-title">
      <img
        class="flag"
        src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/flags/1x1/cn.svg"
      />
      问题?
    </h3>
    <p>
      此表格中的所有问题均为英文。如果您在用英语填写此表格时遇到问题，请与我们的一名工作人员联系以寻求帮助，或寻求家庭成员的协助以完成表格。
    </p>
  </div>
  <hr/>
  <div class="row clearfix text-center" #italian>
    <h3 class="sr-title">
      <img
        class="flag"
        src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/flags/1x1/it.svg"
      />
      I problemi?
    </h3>
    <p>
      Tutte le domande in questo modulo sono in inglese. In caso di problemi con la compilazione del modulo in inglese,
      si prega di parlare con uno dei membri del nostro staff per chiedere aiuto o chiedere assistenza a un membro della
      famiglia per completare il modulo.
    </p>
  </div>
  <hr/>
  <div class="row clearfix text-center" #arabic>
    <h3 class="sr-title" dir="rtl">
      تواجه مشكلة؟
      <img
        class="flag"
        src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/flags/1x1/sa.svg"
      />
    </h3>
    <p dir="rtl">
      جميع الأسئلة في هذا النموذج باللغة الإنجليزية. إذا كنت تواجه مشاكل في ملء هذا النموذج باللغة الإنجليزية ، فيرجى
      التحدث إلى أحد موظفينا للحصول على المساعدة أو طلب المساعدة من أحد أفراد الأسرة لاستكمال النموذج.
    </p>
  </div>
  <hr/>
  <div class="row clearfix text-center" #vietnamese>
    <h3 class="sr-title">
      <img
        class="flag"
        src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/flags/1x1/vn.svg"
      />
      Các vấn đề?
    </h3>
    <p>
      Tất cả các câu hỏi trong mẫu này là bằng tiếng Anh. Nếu bạn gặp vấn đề khi hoàn thành mẫu đơn này bằng tiếng Anh,
      vui lòng nói chuyện với một trong các nhân viên của chúng tôi để được giúp đỡ hoặc tìm kiếm sự trợ giúp từ một
      thành viên gia đình để hoàn thành mẫu đơn.
    </p>
  </div>
</mat-dialog-content>
