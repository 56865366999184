<div class="container">
  <div class="row clearfix flex">
    <form #addForm="ngForm">
      <mat-card class="card full-width" *ngIf="group">
        <div class="card-header primary-gradient-img clearfix">
          <div class="row clearfix">
            <!-- Modal dismiss button -->

            <button
              class="btn-close btn-clear white pull-right mat-button"
              *ngIf="isModal == true"
              (click)="closeEvent()"
            >
              <mat-icon>clear</mat-icon>
            </button>

            <p class="small pull-right" *ngIf="group.created_at">
              <mat-icon class="fas fa-clock extra-header-icon"></mat-icon>
              {{ group.created_at | TimeLabelPast }} ago
            </p>

            <p class="small pull-right" *ngIf="group['isActive'] == true && isPromoterOrAdmin == true">
              <span class="chip-enable">Active</span>
            </p>
            <p class="small pull-right" *ngIf="group['isActive'] != true && isPromoterOrAdmin == true">
              <span class="chip-disable">Not active</span>
            </p>

            <!-- merchant calculated name -->
            <div class="row titleArea clearfix">
              <h2 class="message-title summary-header rm-m" *ngIf="group && group.title">
                <mat-icon
                  class="{{ group.icon }} extra-header-icon"
                  *ngIf="group && group.icon && isAwsome(group.icon) == true"
                >
                </mat-icon>
                <mat-icon class="extra-header-icon" *ngIf="group && group.icon && isAwsome(group.icon) != true">
                  {{ group.icon }}
                </mat-icon>

                {{ group.title }}
              </h2>
            </div>
          </div>
        </div>

        <mat-dialog-content [ngClass]="{ 'no-modal-height': isModal != true }">
          <div class="uploadBlock clearfix full-width">
            <div class="row clearfix pic-img image">
              <div class="notUploaded" *ngIf="!fileSrc">
                <app-ep-document-view-image
                  class="clearfix notYetUploaded"
                  [defaultPicture]="
                    'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cloud-upload-alt-solid.png)'
                  "
                >
                </app-ep-document-view-image>
              </div>

              <div class="displayUploadImg mt" *ngIf="fileSrc">
                <app-ep-document-view-image
                  class="full-width text-center clearfix"
                  [hideZoomButton]="false"
                  [imageLink]="fileSrc"
                >
                </app-ep-document-view-image>
              </div>
            </div>
            <div class="row clearfix">
              <h4 class="sr-title sm-h4 rm-mb">Upload Category Cover</h4>
              <app-file-uploader
                class="text-center clearfix full-width"
                [allowedExtensionTypes]="allowedExtensionTypes"
                [isImageCropping]="true"
                [canSkip]="false"
                [limit]="1"
                [uploadDirectly]="true"
                [isDragable]="true"
                [title]="'CLICK HERE to upload'"
                (onCompleteAll)="onCompleteCategoryAll($event)"
              >
              </app-file-uploader>
            </div>
          </div>

          <div class="full-width mt clearfix flexcenter">
            <mat-form-field class="full-width clearfix" appearance="outline">
              <mat-label>Category Title</mat-label>
              <input [(ngModel)]="title" name="title" matInput placeholder="Enter a new category title" required/>
            </mat-form-field>

            <mat-form-field class="full-width clearfix" appearance="outline">
              <mat-label>Category Icon</mat-label>
              <input [(ngModel)]="icon" name="Grouptitle" matInput placeholder="Category Icon"/>
            </mat-form-field>

            <mat-form-field class="full-width clearfix" appearance="outline">
              <mat-label>Category Order by display</mat-label>

              <mat-select
                class="full-width"
                [(ngModel)]="orderBy"
                placeholder="Category Order by display"
                name="orderBy"
                required
              >
                <mat-option [value]="'priority'"> Priority</mat-option>

                <mat-option [value]="'-created_at'"> Newest first</mat-option>

                <mat-option [value]="'created_at'"> Oldest first</mat-option>

                <mat-option [value]="'-views'"> Most Popular first</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width clearfix" appearance="outline">
              <mat-label>Category Left Menu Options</mat-label>

              <mat-select
                class="full-width"
                [(ngModel)]="menu"
                placeholder="Category Left Menu Options"
                name="menu"
                required
              >
                <mat-option [value]="'all'"> Category and Articles</mat-option>

                <mat-option [value]="'only'"> only Category</mat-option>

                <mat-option [value]="'none'"> Don't display</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width clearfix" appearance="outline">
              <mat-label>Category Description</mat-label>
              <textarea
                class="dentMsg increase-input-field"
                [(ngModel)]="description"
                name="groupDescription"
                matInput
                placeholder="Category Description"
              ></textarea>
            </mat-form-field>

            <div class="full-width flex flexcenter mt clearfix">
              <label class="mr">Category Audience </label>
              <mat-checkbox
                class="mr"
                [checked]="isGroupRoleActive('guest')"
                (change)="sliderChangeGroupRole($event, 'guest')"
              >
                Guest
              </mat-checkbox>
              <mat-checkbox
                class="mr"
                [checked]="isGroupRoleActive('customer')"
                (change)="sliderChangeGroupRole($event, 'customer')"
              >
                Customer
              </mat-checkbox>
              <mat-checkbox
                class="mr"
                [checked]="isGroupRoleActive('merchant')"
                (change)="sliderChangeGroupRole($event, 'merchant')"
              >
                Merchant
              </mat-checkbox>

              <mat-checkbox
                class="mr"
                [checked]="isGroupRoleActive('supplier')"
                (change)="sliderChangeGroupRole($event, 'supplier')"
              >
                Supplier
              </mat-checkbox>

              <mat-checkbox
                class="mr"
                [checked]="isGroupRoleActive('funder')"
                (change)="sliderChangeGroupRole($event, 'funder')"
              >
                Funder
              </mat-checkbox>

              <mat-checkbox
                class="mr"
                [checked]="isGroupRoleActive('promoter')"
                (change)="sliderChangeGroupRole($event, 'promoter')"
              >
                Promoter
              </mat-checkbox>
            </div>

            <div class="full-width flex mt clearfix">
              <mat-slide-toggle class="full-width" [(ngModel)]="isActive" name="isActive">
                Is Active?
              </mat-slide-toggle>
            </div>
          </div>
        </mat-dialog-content>

        <mat-dialog-actions class="button-row row mt flexcenter">
          <button
            class="btn btn-primary"
            *ngIf="isModal == true"
            (click)="closeEvent()"
            mat-raised-button
            color="accent"
          >
            Close
          </button>

          <button
            class="btn btn-primary"
            class="pull-left"
            [disabled]="!addForm.form.valid || !roles || (roles && roles.length == 0)"
            (click)="update()"
            mat-raised-button
            color="primary"
          >
            Save
          </button>
        </mat-dialog-actions>
      </mat-card>
    </form>
  </div>
</div>
