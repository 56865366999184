import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';

@Component({
  selector: 'app-benficiary-list-overview',
  templateUrl: './beneficiary-list-overview.component.html',
  styleUrls: ['./beneficiary-list-overview.component.css'],
})
export class BenficiaryListOverview implements OnInit {
  isAdminOrPromoter = false;

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isAdminOrPromoter = res;
    });
  }
}
