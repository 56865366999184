<div class="rel" *ngIf="merchantID && type">
  <form #resetForm="ngForm">
    <div class="card-header primary-gradient-img clearfix confirmModal"
      [attr.style]="'background:' + theme.background + ' !important' | safeStyle">
      <button class="btn-close btn-clear mat-button smt white custom-close" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="full-width flex main-title-container">
        <div class="row full-width title-container clearfix">
          <div class="title-container-sub">
            <h2 class="text-left title-header rm-m">
              {{ title }}
            </h2>
          </div>
        </div>
      </div>
    </div>

    <mat-dialog-content>
      <p class="full-width text-center">
        Choose how you would like to share your landing page. Each option creates a unique tracking code to report on
        visitors and measure success.
      </p>

      <div class="full-width inner-parent-container">
        <div class="full-width flex inner-parent-row">
          <div class="full-width inner-parent" style="width: 150% !important">
            <h3 class="sr-title text-left mt smb" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              Link with tracking
            </h3>

            <p class="small full-width mb flex inner-text">
              Alternatively, manually copy and add the link to your website, document, social post, email, etc.
              <br />
              <br />
              Each link contains a unique tracker to count the visitor's source.
            </p>

            <div class="full-width inner-footer">
              <button class="btn mb mr pull-left manual-button" (click)="copy('pr')" mat-raised-button>
                <mat-icon class="fas fa-globe smr" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
                </mat-icon>
                Your Website
              </button>

              <button class="btn mb mr pull-left manual-button" (click)="copy('email')" mat-raised-button>
                <mat-icon class="fas fa-envelope-open smr"
                  [attr.style]="'color:' + theme.color + ' !important' | safeStyle"></mat-icon>
                Email
              </button>

              <button class="btn mb mr pull-left manual-button" (click)="copy('sms')" mat-raised-button>
                <mat-icon class="fas fa-sms smr" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
                </mat-icon>
                SMS
              </button>

              <button class="btn mb mr pull-left manual-button" (click)="copy('facebook')" mat-raised-button>
                <mat-icon class="fab fa-facebook smr" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
                </mat-icon>
                Facebook
              </button>

              <button class="btn mb mr pull-left manual-button" (click)="copy('twitter')" mat-raised-button>
                <mat-icon class="fab fa-twitter smr" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
                </mat-icon>
                Twitter
              </button>

              <button class="btn mb mr pull-left manual-button" (click)="copy('linkedin')" mat-raised-button>
                <mat-icon class="fab fa-linkedin smr" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
                </mat-icon>
                Linkedin
              </button>

              <button class="btn mb mr pull-left manual-button" (click)="copy('pinterest')" mat-raised-button>
                <mat-icon class="fab fa-pinterest smr"
                  [attr.style]="'color:' + theme.color + ' !important' | safeStyle"></mat-icon>
                Pinterest
              </button>

              <button class="btn mb mr pull-left manual-button" (click)="copy('i')" mat-raised-button>
                <mat-icon class="fab fa-instagram smr"
                  [attr.style]="'color:' + theme.color + ' !important' | safeStyle"></mat-icon>
                Instagram
              </button>
            </div>
          </div>
          <div class="full-width inner-parent">
            <h3 class="sr-title text-left mt smb" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              Social Media
            </h3>

            <p class="small full-width mb inner-text">
              Choose the social media channel, and we will create a post automatically.<br />
              <br />

              <strong>TIP</strong> – Press CTRL V to paste copy once the post appears.
            </p>
            <div class="inner-footer">
              <app-social-share-button class="btn-large share-button-doc2 share-button-linkedin smr"
                [label]="'LinkedIn'" [post]="socialMediaPost" [copy]="true" [icon]="'fab fa-linkedin'"
                [isConfirmModal]="false" [url]="getSocialMediaLink('i')" [title]="socialMediaTitle"
                [image]="socialMediaImage" [type]="'linkedin'">
              </app-social-share-button>

              <app-social-share-button class="btn-large share-button-doc2 share-button-twitter smr" [label]="'Twitter'"
                [post]="socialMediaPost" [copy]="true" [icon]="'fab fa-twitter'" [isConfirmModal]="false"
                [url]="getSocialMediaLink('t')" [title]="socialMediaTitle" [image]="socialMediaImage"
                [type]="'twitter'">
              </app-social-share-button>

              <app-social-share-button class="btn-large share-button-doc2 share-button-pinterest smr"
                [label]="'Pinterest'" [post]="socialMediaPost" [copy]="true" [icon]="'fab fa-pinterest'"
                [isConfirmModal]="false" [url]="getSocialMediaLink('pi')" [title]="socialMediaTitle"
                [image]="socialMediaImage" [type]="'pinterest'">
              </app-social-share-button>

              <app-social-share-button class="btn-large share-button-doc2 share-button-facebook smr"
                [label]="'Facebook'" [post]="socialMediaPost" [copy]="true" [icon]="'fab fa-facebook'"
                [isConfirmModal]="false" [url]="getSocialMediaLink('f')" [title]="socialMediaTitle"
                [image]="socialMediaImage" [type]="'facebook'">
              </app-social-share-button>
            </div>
          </div>
          <div class="full-width inner-parent" *ngIf="urlQR">
            <h3 class="sr-title text-left mt smb" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              QR code
            </h3>

            <p class="small full-width flex inner-text">
              The code will redirect your {{ "KEYWORD.patient" | translate }}s to dynamic landing pages that are
              personalised to your {{"KEYWORD.practice" | translate}}.
              <br />
              <br />
              Use these in your marketing material to share, such as brochures, newsletters, business cards, email
              footers, etc.
            </p>

            <div class="inner-footer">
              <div class="row clearfix full-width text-center flex">
                <app-generate-qr [downloadLabel]="'Staff Portal'" class="full-width ml mr app-generate-qr-share row"
                  [source]="urlQR" [size]="160" [download]="downloadQRCodeDental"
                  style="margin-top: 0px !important; margin-bottom: 15px">
                </app-generate-qr>
              </div>

              <div class="row clearfix full-width text-center mt" style="margin-top: 11px !important">
                <button class="btn text-center" [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
                  (click)="downloadQRCodeDental.emit()" mat-raised-button color="accent">
                  Download QR code
                </button>
              </div>
            </div>
          </div>

          <div class="full-width inner-parent">
            <h3 class="sr-title text-left mt smb" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              PDF
            </h3>

            <p class="small full-width flex inner-text">
              Go old school and turn your landing page into a PDF to either share via email or print as a hard copy.
            </p>

            <div class="inner-footer">
              <div class="row clearfix full-width text-center flex">
                <img class="pdf-img"
                  src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/pdf.png" />
              </div>

              <div class="row clearfix full-width text-center mt" style="margin-top: 11px !important">
                <button class="btn text-center" [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
                  (click)="ViewPDF()" mat-raised-button color="accent">
                  View PDF document
                </button>
              </div>
            </div>
          </div>

          <div class="full-width inner-parent">
            <h3 class="sr-title text-left mt smb" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              Embed
            </h3>

            <p class="small full-width flex inner-text">
              If you want to provide a smoother experience for {{ "KEYWORD.patient" | translate | titlecase }}'s, you
              can set up an
              "Iframe"
              on your webpage. Rather than {{ "KEYWORD.patient" | translate | titlecase }}'s been routed to a new
              webpage.
            </p>

            <div class="inner-footer">
              <div class="row clearfix full-width text-center flex">
                <img class="pdf-img"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/web-design.png" />
              </div>

              <div class="row clearfix full-width text-center mt" style="margin-top: 11px !important">
                <button class="btn text-center" [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
                  (click)="viewEmbed()" mat-raised-button color="accent">
                  Setup Iframe
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <hr />
    <div class="drop-button text-center mt text-center full-width">
      <button class="btn-large pull-left" (click)="closeEvent()" mat-raised-button color="primary">close</button>

      <button class="btn-large pull-right" [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
        (click)="openIframe()" mat-raised-button color="primary">
        View Landing Page
      </button>
    </div>
  </form>
</div>
