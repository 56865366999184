import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ColorPalette } from '../../../shared/types/color-palette';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { SubscriptionPlanService } from '../shared/subscription-plan.service';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { ConfirmDialogSingleButtonComponent } from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { CodeHTMLViewComponent } from '../../../shared/components/html-view/html-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ClearTextPipe } from '../../../shared/pipes/clear-text.pipe';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

@Component({
  selector: 'app-feature-sub-label-create-edit',
  templateUrl: './feature-sub-label-create-edit.component.html',
  styleUrls: ['./feature-sub-label-create-edit.component.css'],
  providers: [ClearTextPipe],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class FeatureSubLabelCreateEditComponent implements OnInit {
  @Input()
  mainFeatureLabelID;
  isEditStyle = false;
  _isEditStyle = false;
  @Input()
  subFeatureLabelID;

  pinchZoomProperties = [
    {
      wheel: true,
      autoHeight: true,
    },
  ];
  isContentPage = false;
  isUnique = true;
  mainLabel;
  @Input()
  label;
  useDefaultColor = true;
  maxLength = 300;

  titleHtml = 'Marketing Html Content';
  descriptionHTML = 'Choose the editor type to create HTML content,you can also change the view type.';

  @ViewChild('wizard', { static: false }) wizard: WizardComponent;

  postPictureLink;
  postPicture;
  fileID;

  style;
  _style;
  utils = new UtilsClass();
  maxFileSize = 5;
  allowExtensions = ['jpeg', 'jpg', 'png','heic', 'gif', 'tiff', 'bmp', 'ico'];
  applyDownload = new EventEmitter();

  color;
  colorSecond;

  htmlResult = new EventEmitter();

  loadingPost = false;

  code = null;

  mainFeature;
  description;

  isActive = true;
  htmlStructure;
  htmlContent;

  isSubPlanFeature = true;

  isNew = true;
  icon = 'fas fa-paper-plane';
  @Output()
  getResult = new EventEmitter();
  postPictureThumbnail;
  content;
  _content;
  structure;
  inputType = 'TinyEditor';
  isModal = false;
  loaded = false;
  close = new EventEmitter();
  sessionType;
  createdRecord;

  subColor = Settings.global['primaryColor'] || '#1e88e5';

  root = '/merchant';
  isSideMenu = false;
  isPublic = true;
  isMerchant = true;
  isConsumer = false;
  sideMenuLabel = null;
  isMainSideMenu = false;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private subscriptionPlanService: SubscriptionPlanService,
    private authenticationService: AuthenticationService,
    private clearTextPipe: ClearTextPipe,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.mainFeatureLabelID != null) {
        this.mainFeatureLabelID = data.mainFeatureLabelID;
      }
      if (data.subFeatureLabelID != null) {
        this.subFeatureLabelID = data.subFeatureLabelID;
      }
      if (data.color != null) {
        this.color = data.color;
      }
      if (data.icon != null) {
        this.icon = data.icon;
      }
      if (data.colorSecond != null) {
        this.colorSecond = data.colorSecond;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    UtilsClass.scrollUp();
    this.activeRoute.params.subscribe((params) => {
      if (params['subFeatureLabelID']) {
        this.subFeatureLabelID = params['subFeatureLabelID'];
      }
      if (params['mainFeatureLabelID']) {
        this.mainFeatureLabelID = params['mainFeatureLabelID'];
      }

      this.authenticationService.getSessionType().subscribe((res) => {
        this.sessionType = res;

        this.setup();
      });
    });
  }

  changeLabel() {
    if (this.label && !this.sideMenuLabel) {
      this.sideMenuLabel = this.label;

      if (!this.code) {
        if (this.label && this.mainLabel) {
          this.code = (this.mainLabel + '_' + this.label).replace(/ /g, '_');
          this.code = this.code.replace(/#/g, '_');
          this.code = this.code.replace(/\//g, '_');
          this.isUniqueEvent();
        }
      }
    }
  }

  setup() {
    this.loaded = false;
    if (this.subFeatureLabelID) {
      const p = {
        selectMain: true,
        fields:
          'ID,DateTimeCreated,Is_Marketing_Only,Icon,Feature_MainLabel_key,MainLabel,MainLabel,Color,MainLabel_Is_Deactivated,MainLabel_DisplayOrder,' +
          'SubLabel,Description,Is_Deactivated,Is_NewFeature,Style_Content,Html_Content,Html_Content_Structure,Is_Side_Menu,Side_Menu_Label,Is_Private,Is_Merchant_Feature,Is_Consumer_Feature,Is_Main_Side_Menu',
      };
      this.subscriptionPlanService
        .getFeatureSubLabelDetails(this.subFeatureLabelID, p, this.sessionType)
        .subscribe((res) => {
          if (res) {
            if (res.SubLabel) {
              this.label = res.SubLabel;
            }

            if (res.MainLabel) {
              this.mainLabel = res.MainLabel;
            }

            if (res.FE_Code) {
              this.code = res.FE_Code;
            } else if (this.label && this.mainLabel) {
              this.code = (this.mainLabel + '_' + this.label).replace(/ /g, '_');
              this.code = this.code.replace(/#/g, '_');
              this.code = this.code.replace(/\//g, '_');
              this.isUniqueEvent();
            }

            if (res.mainFeature) {
              this.mainFeature = res.mainFeature;

              if (this.mainFeature && this.mainFeature.ID) {
                this.mainFeatureLabelID = this.mainFeature.ID;

                if (this.mainFeature && this.mainFeature.ID) {
                  this.postPictureThumbnail = this.subscriptionPlanService.getFeatureMainLabelThumbnailImageStreamLink(
                    this.mainFeature.ID,
                    this.mainFeature.LastModified
                  );
                }
              }
            }

            this.loadingPost = true;
            this.subscriptionPlanService.getFeatureSubLabelCoverImage(res.ID).subscribe((res) => {
              if (res) {
                this.postPicture = res;
              } else {
                this.postPicture = null;
              }
              this.loadingPost = false;
            });

            if (res['Icon']) {
              this.icon = res['Icon'];
            }
            if (res['Is_Side_Menu'] === '0') {
              this.isSideMenu = false;
            } else {
              this.isSideMenu = true;
            }

            if (res['Is_Marketing_Only'] === '1') {
              this.isSubPlanFeature = false;
            } else {
              this.isSubPlanFeature = true;
            }

            if (res['Is_Private'] === '1') {
              this.isPublic = false;
            } else {
              this.isPublic = true;
            }

            if (res['Is_Merchant_Feature'] === '0') {
              this.isMerchant = false;
            } else {
              this.isMerchant = true;
            }

            if (res['Is_Main_Side_Menu'] === '0') {
              this.isMainSideMenu = false;
            } else {
              this.isMainSideMenu = true;
            }

            if (res['Is_Consumer_Feature'] === '0') {
              this.isConsumer = false;
            } else {
              this.isConsumer = true;
            }
            if (res['Side_Menu_Label']) {
              this.sideMenuLabel = res['Side_Menu_Label'];
            } else if (res['SubLabel']) {
              this.sideMenuLabel = res['SubLabel'];
            }

            if (res.Description) {
              this.description = res.Description;
            }
            if (res['Style_Content']) {
              this.style = res['Style_Content'];
              this._style = res['Style_Content'];
              this.isEditStyle = true;
              this._isEditStyle = true;
            }
            if (res['Html_Content']) {
              this.content = res['Html_Content'];
              this._content = res['Html_Content'];
            } else if (res['Description']) {
              this.content = res['Description'];
              this._content = res['Description'];
            }
            if (res['Html_Content_Structure']) {
              this.structure = JSON.parse(res['Html_Content_Structure']);
              this.inputType = 'Editor';
            } else {
              this.inputType = 'TinyEditor';
            }
            this.loaded = true;

            if (res['Is_Deactivated'] === '1') {
              this.isActive = false;
            } else {
              this.isActive = true;
            }

            if (res['Is_NewFeature'] === '1') {
              this.isNew = true;
            } else {
              this.isNew = false;
            }

            if (this.mainFeature && this.mainFeature.Color) {
              const split = this.mainFeature['Color'].split('|');
              if (split[0]) {
                this.color = split[0];
              }

              if (split[1]) {
                this.colorSecond = split[1] || null;
              }

              if (this.color && !this.colorSecond) {
                this.onColorPickerClose(this.color);
              }
            } else if (this.color && !this.colorSecond) {
              this.onColorPickerClose(this.color);
            }

            if (res.Color) {
              this.subColor = res.Color.split('|')[0];
              this.useDefaultColor = false;
            } else if (this.color) {
              this.useDefaultColor = false;
              this.subColor = this.color;
            }
          } else if (this.color && !this.colorSecond) {
            this.onColorPickerClose(this.color);
          }
        });
    } else if (this.mainFeatureLabelID) {
      this.loaded = true;
      this.subscriptionPlanService
        .getFeatureMainLabelDetails(this.mainFeatureLabelID, {}, this.sessionType)
        .subscribe((res) => {
          if (res) {
            this.mainFeature = res;

            if (this.mainFeature && this.mainFeature.ID) {
              this.mainFeatureLabelID = this.mainFeature.ID;

              if (this.mainFeature && this.mainFeature.ID) {
                this.postPictureThumbnail = this.subscriptionPlanService.getFeatureMainLabelThumbnailImageStreamLink(
                  this.mainFeature.ID,
                  this.mainFeature.LastModified
                );
              }
            }
            if (this.mainFeature && this.mainFeature.MainLabel) {
              this.mainLabel = this.mainFeature.MainLabel;
            }
          }
        });
    } else {
      this.loaded = true;
    }
  }

  stepBack(isContentPage = false) {
    UtilsClass.scrollUp();
    this.wizard.goToPreviousStep();
    this.isContentPage = isContentPage;
  }

  onComplete(fileID) {
    if (fileID && fileID[0]) {
      this.fileID = fileID[0];
      this.postPicture = null;
      this.postPictureLink = this.utils.getFileLink(this.fileID);
    } else {
      this.postPictureLink = null;
    }
  }

  downloadImage() {
    this.applyDownload.emit(true);
  }

  isIndexOf(s, attribute) {
    if (s && attribute && s.indexOf(attribute) != -1) {
      return true;
    }
    return false;
  }

  onColorPickerClose(color: string): void {
    if (color) {
      this.color = color;

      const v = new ColorPalette(color);
      const palette = v.getPalette();

      if (palette && palette.length > 0) {
        const index = palette.findIndex((item) => {
          if (item && item.name == 800) {
            return true;
          } else {
            return false;
          }
        });

        if (palette[index] && palette[index]['hex']) {
          this.colorSecond = palette[index]['hex'];
        }
      }
    }
  }

  getStructureEvent(d) {
    this.htmlStructure = d;
  }

  getContentEvent(d) {
    this.htmlContent = d;
  }

  getStyleEvent(d) {
    this.style = d;
  }

  ViewHtmlContent(c, date) {
    if (c) {
      const isPreview = true;
      const isCode = true;
      this.subscriptionPlanService.getSubFeatureHTMLContent(c, this.sessionType, date).subscribe((content) => {
        let width = '750px';
        if (content) {
          width = '75%';
        }

        const ref2 = RootAppComponent.dialog.open(CodeHTMLViewComponent, {
          data: {
            icons: 'fas fa-eye',
            title: 'preview',
            content,
            isPreview,
            isCode,
          },
          width,
        });
        ref2.componentInstance.onConfirm.subscribe((res) => {
          ref2.close();
        });
      });
    }
  }

  save(isProceed = false) {
    const payload: any = {
      label: this.label,
      description: this.description,
      mainID: this.mainFeatureLabelID,
      isActive: this.isActive,
      color: this.subColor || null,

      code: this.code,
      useDefaultColor: this.useDefaultColor,
      icon: this.icon || null,
      isNew: this.isNew,

      fileIDCover: this.fileID || null,
      isConsumer: this.isConsumer,
      isMerchant: this.isMerchant,
      sideMenuLabel: this.sideMenuLabel,
      isPublic: this.isPublic,
      isSideMenu: this.isSideMenu,
      isMainSideMenu: this.isMainSideMenu,
      isMarketingOnly: !this.isSubPlanFeature,
    };
    if (this.htmlStructure) {
      payload.htmlStructure = JSON.stringify(this.htmlStructure);
    } else {
      payload.htmlStructure = 'void';
    }
    if (this.htmlContent) {
      payload.htmlContent = this.htmlContent;
    }
    if (this.style) {
      payload.styleContent = this.style;
    }

    if (this._isEditStyle !== true) {
      payload.styleContent = 'void';
    }

    if (this.subFeatureLabelID) {
      this.subscriptionPlanService.editFeatureSubLabel(this.subFeatureLabelID, payload).subscribe((res) => {
        if (res) {
          if (isProceed == true) {
            this.createdRecord = res;
            this.wizard.goToNextStep();
            this.getResult.emit(res);
            NotifyAppComponent.displayToast('success', 'Success!', 'Sub Feature Label is Modified');
          } else {
            NotifyAppComponent.displayToast('success', 'Success!', 'Sub Feature Label is Saved');
          }
        }
      });
    } else {
      this.subscriptionPlanService.createFeatureSubLabel(payload).subscribe((res) => {
        if (res && res.ID && res.Feature_MainLabel_key) {
          if (isProceed == true) {
            this.createdRecord = res;
            this.getResult.emit(res);
            this.wizard.goToNextStep();
            NotifyAppComponent.displayToast('success', 'Success!', 'Sub Feature Label is Created');
          } else {
            this.subFeatureLabelID = res.ID;

            window.history.replaceState(
              {},
              '',
              `/merchant/(page:sub-feature-edit-create/${res.Feature_MainLabel_key}/${res.ID})`
            );

            NotifyAppComponent.displayToast('success', 'Success!', 'Sub Feature Label is Saved');
          }
        }
      });
    }
  }

  proceed(isContentPage = false) {
    UtilsClass.scrollUp();
    this.wizard.goToNextStep();

    this.isContentPage = isContentPage;
  }

  closeEvent() {
    this.close.emit(true);
  }

  viewMainFeature(ID) {
    if (ID) {
      this.router.navigate([this.root, { outlets: { page: ['main-feature-view', ID] } }]);
    }
  }

  onColorPickerClose2(color: string): void {
    if (color) {
      this.subColor = color;
    }
  }

  isUniqueEvent() {
    if (this.code) {
      const payload: any = { code: this.code };

      if (this.subFeatureLabelID) {
        payload.id = this.subFeatureLabelID;
      }

      this.subscriptionPlanService.isUniqueFeaturSubLabel(payload).subscribe((res) => {
        if (res === true) {
          this.isUnique = true;
        } else {
          this.isUnique = false;
        }
      });
    }
  }

  contentView() {
    if (this.createdRecord && this.createdRecord['Html_Content']) {
      const confirmDialog2 = new ConfirmDialogSingleButton(
        'fas fa-eye',
        'Preview',
        this.clearTextPipe.transform(this.createdRecord['Html_Content']),
        'OKAY'
      );
      const ref2 = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
        data: confirmDialog2,
        width: '1000px',
      });
      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });
    }
  }

  createNew(ID) {
    if (ID) {
      this.router.navigate([this.root, { outlets: { page: ['sub-feature-edit-create-redirect', ID] } }]);
    }
  }

  getContent(d) {
    this.description = d;
  }

  getIsEditStyleEvent(d) {
    this._isEditStyle = d;
  }
}
