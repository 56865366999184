import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../services/utils.service';
import { AddressLabelInputComponent } from '../address-label-input/address-label-input.component';
import { RootAppComponent } from '../root-component/root-component.component';

@Component({
  selector: 'app-address-input-advanced',
  templateUrl: './address-input-advanced.component.html',
  styleUrls: ['./address-input-advanced.component.css'],
})
export class AddressInputAdvancedComponent implements OnInit {
  @Input()
  targetID;

  @Input()
  targetAddressType;

  @Input()
  addressTypes;

  @Input()
  mySuburb = null;

  @Input()
  myState = null;

  @Input()
  myPostCode = null;

  @Input()
  myStreetNumber = null;

  @Input()
  myStreetName = null;

  @Input()
  myUnitNumber = null;

  @Input()
  myStreetType = null;

  @Input()
  myLatitude;

  @Input()
  myLongitude;

  @Input()
  isDisabled = false;

  isPrimaryAddress = true;
  addressType;
  @Input()
  type = 'current';
  sessionType;

  constructor(private utilService: UtilsService, private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((st) => {
      this.sessionType = st;

      if (this.addressTypes && this.addressTypes.indexOf('Postal') != -1) {
        this.addressTypes.splice(this.addressTypes.indexOf('Postal'), 1);
      }

      this.addressType = this.targetAddressType;
    });
  }

  editAddressByLabel(label) {
    const ref = RootAppComponent.dialog.open(AddressLabelInputComponent, {
      data: {
        ownerID: this.targetID,
        label,
        isEdit: true,
        unit: this.myUnitNumber,
        streetNumber: this.myStreetNumber,
        streetName: this.myStreetName,
        streetType: this.myStreetType,
        suburb: this.mySuburb,
        state: this.myState,
        postCode: this.myPostCode,
        isPrimary: this.isPrimaryAddress,
        addressTypes: this.addressTypes,
        type: this.type,
      },
      width: '1000px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        ref.close();
        this.addressType = res.Label;
        if (res['IsPrimary'] == '1') {
          this.targetAddressType = res['Label'];
        }
        this.getAddressByType(res.Label);
      }
    });
  }

  getAddressByType(e) {
    if (e) {
      this.utilService.getAddressByLabel(this.targetID, e, this.type, this.sessionType).subscribe((res) => {
        if (res) {
          this.myUnitNumber = res['Unit'];
          this.myStreetNumber = res['Street Nr'];
          this.myStreetName = res['Street Name'];
          this.myStreetType = res['Street Type'];
          this.mySuburb = res['Suburb'];
          this.myState = res['State'];
          this.myPostCode = res['Postcode'];
          this.isPrimaryAddress = res['IsPrimary'] == '1' ? true : false;
        }
      });
    }
  }

  convertToLowerCase(val) {
    if (val) {
      return val.toLowerCase();
    }
  }
}
