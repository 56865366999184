export class LookupClass {
  lookupCategory = {
    StoreCard: {
      table: 'SimpleLookup',
      category: 'StoreCard',
      result: [
        {
          Label: 'Coles',
          Category: 'StoreCard',
        },
        {
          Label: 'David Jones',
          Category: 'StoreCard',
        },
        {
          Label: 'Myer',
          Category: 'StoreCard',
        },
        {
          Label: 'Other',
          Category: 'StoreCard',
        },
      ],
    },
    IndustryType: {
      table: 'SimpleLookup',
      category: 'IndustryType',
    },
    VEDA_LoanType_Consumer: {
      table: 'CodeLookup',
      category: 'VEDA_LoanType_Consumer',
    },
    VEDA_LoanType_Commercial: {
      table: 'CodeLookup',
      category: 'VEDA_LoanType_Commercial',
    },
    VEDA_AccountType_Consumer: {
      table: 'CodeLookup',
      category: 'VEDA_AccountType_Consumer',
    },
    MessageType: {
      table: 'CodeLookup',
      category: 'MessageType',
    },
    DMS_ProgramArea: {
      table: 'CodeLookup',
      category: 'DMS_ProgramArea',
    },
    PlaceType: {
      table: 'CodeLookup',
      category: 'PlaceType',
    },
    Residency: {
      table: 'CodeLookup',
      category: 'Residency',
    },
    NationalIdentityType: {
      table: 'CodeLookup',
      category: 'NationalIdentityType',
    },
    ID_Type: {
      table: 'CodeLookup',
      category: 'ID_Type',
    },
    CompanyType: {
      table: 'CodeLookup',
      category: 'CompanyType',
    },
    WorkingFrom: {
      table: 'CodeLookup',
      category: 'WorkingFrom',
    },
    WhoPaysLivingExpenses: {
      table: 'CodeLookup',
      category: 'WhoPaysLivingExpenses',
    },
    UnemploymentType: {
      table: 'CodeLookup',
      category: 'UnemploymentType',
    },
    RetirementType: {
      table: 'CodeLookup',
      category: 'RetirementType',
    },
    ResidenceType: {
      table: 'CodeLookup',
      category: 'ResidenceType',
    },
    QuotationStatus: {
      table: 'CodeLookup',
      category: 'QuotationStatus',
    },
    LiabilityType: {
      table: 'CodeLookup',
      category: 'LiabilityType',
    },
    InvestmentLoanType: {
      table: 'CodeLookup',
      category: 'InvestmentLoanType',
      result: [
        {
          Label: 'Line of Credit',
          Code: 'LOC',
        },
        {
          Label: 'Second Mortgage',
          Code: 'SCN',
        },
        {
          Label: 'Overdraft',
          Code: 'OVR',
        },
      ],
    },
    IncomeSource: {
      table: 'CodeLookup',
      category: 'IncomeSource',
    },
    Housing: {
      table: 'CodeLookup',
      category: 'Housing',
      result: [
        {
          Label: 'Home Owner - Outright',
          Code: 'HOO',
        },
        {
          Label: 'Home Owner - Mortgage',
          Code: 'HOM',
        },
        {
          Label: 'No - Renting',
          Code: 'RNT',
        },
        {
          Label: 'No - Boarding',
          Code: 'BRD',
        },
      ],
    },
    ExpenseType: {
      table: 'CodeLookup',
      category: 'ExpenseType',
    },
    Merchant_Options_3: {
      table: 'CodeLookup',
      category: 'Merchant_Options_3',
    },
    URL_Bits_Invitations: {
      table: 'CodeLookup',
      category: 'URL_Bits_Invitations',
    },
    ConsFundProspectStatus: {
      table: 'CodeLookup',
      category: 'ConsFundProspectStatus',
    },
    CardType: {
      table: 'CodeLookup',
      category: 'CardType',
      result: [
        {
          Label: 'Credit Card',
          Code: 'CRE',
        },
        {
          Label: 'Store Card',
          Code: 'STR',
        },
      ],
    },
    AssetType: {
      table: 'CodeLookup',
      category: 'AssetType',
    },
    ContractItemStatus_CF: {
      table: 'CodeLookup',
      category: 'ContractItemStatus_CF',
    },
    UserOrGroupType: {
      table: 'CodeLookup',
      category: 'UserOrGroupType',
    },
    StaffLevel: {
      table: 'CodeLookup',
      category: 'StaffLevel',
    },
    PaymentStatus: {
      table: 'CodeLookup',
      category: 'PaymentStatus',
    },
    Months: {
      table: 'CodeLookup',
      category: 'Months',
    },
    ServiceDoc_SendWhen: {
      table: 'CodeLookup',
      category: 'ServiceDoc_SendWhen',
    },
    Language: {
      table: 'CodeLookup',
      category: 'Language',
    },
    IDCheckType: {
      table: 'CodeLookup',
      category: 'IDCheckType',
    },
    eConnectRoles: {
      table: 'CodeLookup',
      category: 'eConnectRoles',
    },
    AccountPurpose_CF: {
      table: 'CodeLookup',
      category: 'AccountPurpose_CF',
    },
    DMS_TypeTag: {
      table: 'CodeLookup',
      category: 'DMS_TypeTag',
    },
    DMS_LocationType: {
      table: 'CodeLookup',
      category: '_DMS_LocationType',
    },
    ContactType: {
      table: 'CodeLookup',
      category: 'ContactType',
    },
    ChatAccountType: {
      table: 'CodeLookup',
      category: 'ChatAccountType',
    },
    CardStatus: {
      table: 'CodeLookup',
      category: 'CardStatus',
    },
    BankAccountType: {
      table: 'CodeLookup',
      category: 'BankAccountType',
    },
    BankAccountNumberType: {
      table: 'CodeLookup',
      category: 'BankAccountNumberType',
    },
    API_Status: {
      table: 'CodeLookup',
      category: 'API_Status',
    },
    API_GEN: {
      table: 'CodeLookup',
      category: 'API_GEN',
    },
    API_FIN: {
      table: 'CodeLookup',
      category: 'API_FIN',
    },
    API_Command: {
      table: 'CodeLookup',
      category: 'API_Command',
    },
    VEDA_AccountType_Commercial: {
      table: 'CodeLookup',
      category: 'VEDA_AccountType_Commercial',
    },
    AccountType: {
      table: 'CodeLookup',
      category: 'AccountType',
      result: [
        {
          Label: 'Cheque Account',
          Code: 'CHQ',
        },
        {
          Label: 'Savings Account',
          Code: 'SAV',
        },
        {
          Label: 'Term Deposit',
          Code: 'TERM',
        },
      ],
    },
    BenefitType: {
      table: 'CodeLookup',
      category: 'BenefitType',
      result: [
        {
          Label: 'Carer Allowance',
          Code: 'AU_CAR_ALL',
        },
        {
          Label: 'Family Tax Benefit',
          Code: 'AU_FTB',
        },
        {
          Label: 'Jobseeker',
          Code: 'AU_JOBS',
        },
        {
          Label: 'Parenting Payment',
          Code: 'AU_PAR_ALL',
        },
        {
          Label: 'Widow Allowance',
          Code: 'AU_WID_ALL',
        },
        {
          Label: 'Youth Allowance',
          Code: 'AU_YTH_ALL',
        },
        {
          Label: 'Austudy',
          Code: 'AU_STUDY',
        },
      ],
    },
    ChangedCircHowLong: {
      table: 'CodeLookup',
      category: 'ChangedCirc_HowLong',
    },
    ChangedCircHowToRepay: {
      table: 'CodeLookup',
      category: 'ChangedCirc_HowToRepay',
    },
    ChangedCircNatureOf: {
      table: 'CodeLookup',
      category: 'ChangedCirc_NatureOf',
    },
    ContactMethod: {
      table: 'CodeLookup',
      category: 'ContactMethod',
    },
    ContactTime: {
      table: 'CodeLookup',
      category: 'ContactTime',
    },
    Country: {
      table: 'CodeLookup',
      category: 'Country',
    },
    DebtType: {
      table: 'CodeLookup',
      category: 'DebtType',
      result: [
        {
          Label: 'CenterLink - OverPayment of Benefits',
          Code: 'AU_CEN_OP',
        },
        {
          Label: 'Education - HECS',
          Code: 'AU_HECS',
        },
        {
          Label: 'Education - HELP',
          Code: 'AU_HELP',
        },
        {
          Label: 'Education - FEE - HELP',
          Code: 'AU_FEE_HLP',
        },
        {
          Label: 'Education - VET FEE HELP',
          Code: 'AU_VFEE_HLP',
        },
        {
          Label: 'Education - OS - HELP',
          Code: 'AU_OS_HELP',
        },
        {
          Label: 'Education - SA - HELP',
          Code: 'AU_SA_HELP',
        },
        {
          Label: 'Tax - Income Tax',
          Code: 'AU_TAX_INC',
        },
        {
          Label: 'Tax - BAS',
          Code: 'AU_TAX_BAS',
        },
      ],
    },
    EmploymentStatus: {
      table: 'CodeLookup',
      category: 'EmploymentStatus',
      result: [
        {
          Label: 'Employed',
          Code: 'EMP',
        },
        {
          Label: 'Self Employed',
          Code: 'SEM',
        },
        {
          Label: 'Unemployed',
          Code: 'UNE',
        },
        {
          Label: 'Home Duties',
          Code: 'HMD',
        },
        {
          Label: 'Studying or Training',
          Code: 'STU',
        },
        {
          Label: 'Retired',
          Code: 'RET',
        },
        {
          Label: 'Receiving WorkCover',
          Code: 'WCV',
        },
        {
          Label: 'Other Government Benefits',
          Code: 'OGB',
        },
        {
          Label: 'Pension',
          Code: 'PSN',
        },
      ],
    },
    EmploymentType: {
      table: 'CodeLookup',
      category: 'EmploymentType',
      result: [
        {
          Label: 'Permanent - full time',
          Code: 'PFT',
        },
        {
          Label: 'Permanent - part time',
          Code: 'PPT',
        },
        {
          Label: 'Casual-Seasonal-Temp.',
          Code: 'CST',
        },
        {
          Label: 'Contractor',
          Code: 'CON',
        },
        {
          Label: 'Military - Enlisted',
          Code: 'MIE',
        },
        {
          Label: 'Militarty - Officer',
          Code: 'MIO',
        },
      ],
    },
    Gender: {
      table: 'CodeLookup',
      category: 'Gender',
    },
    HomeLoanType: {
      table: 'CodeLookup',
      category: 'HomeLoanType',
      result: [
        {
          Label: 'Home Loan',
          Code: 'HLN',
        },
        {
          Label: 'Line of Credit',
          Code: 'LCR',
        },
      ],
    },
    LivingExpenses: {
      table: 'CodeLookup',
      category: 'LivingExpenses',
    },
    LoanType: {
      table: 'CodeLookup',
      category: 'LoanType',
      result: [
        {
          Label: 'Personal Loan',
          Code: 'PSL',
        },
        {
          Label: 'Line of Credit',
          Code: 'LCR',
        },
        {
          Label: 'Overdraft',
          Code: 'OVD',
        },
      ],
    },
    PensionType: {
      table: 'CodeLookup',
      category: 'PensionType',
    },
    PropertyOwner: {
      table: 'CodeLookup',
      category: 'PropertyOwner',
      result: [
        {
          Label: 'Customer',
          Code: 'CUS',
        },
        {
          Label: 'Customer and Spouse',
          Code: 'CSP',
        },
        {
          Label: 'Other parties and I own the property',
          Code: 'COP',
        },
      ],
    },
    RelationshipStatus: {
      table: 'CodeLookup',
      category: 'RelationshipStatus',
    },
    RelationshipType: {
      table: 'CodeLookup',
      category: 'RelationshipType',
    },
    RelativeType: {
      table: 'CodeLookup',
      category: 'RelativeType',
    },
    StreetType: {
      table: 'CodeLookup',
      category: 'StreetType',
    },
    StudentBenefit: {
      table: 'CodeLookup',
      category: 'StudentBenefit',
      result: [
        {
          Label: 'No Benefit',
          Code: 'AU_NB',
        },
        {
          Label: 'Austudy',
          Code: 'AU_AUST',
        },
        {
          Label: 'Abstudy',
          Code: 'AU_ABST',
        },
      ],
    },
    StudyType: {
      table: 'CodeLookup',
      category: 'StudyType',
    },
    Frequency: {
      table: 'CodeLookup',
      category: 'Frequency',
      result: [
        {
          Label: 'Weekly',
          Code: '1',
        },
        {
          Label: 'Fortnightly',
          Code: '2',
        },
        {
          Label: 'Monthly',
          Code: '4',
        },
        // {
        //     Label: "Bi-Monthly",
        //     Code: "8",
        // },
        {
          Label: 'Quarterly',
          Code: '12',
        },
        {
          Label: 'Half Yearly',
          Code: '24',
        },
        {
          Label: 'Yearly',
          Code: '48',
        },
      ],
    },
    Currency: {
      table: 'CodeLookup',
      category: 'Currency',
    },
    MessageStatus: {
      table: 'CodeLookup',
      category: 'MessageStatus',
    },
    ServiceStatus: {
      table: 'CodeLookup',
      category: 'ServiceStatus',
    },

    InvitationChoice: {
      table: 'CodeLookup',
      category: 'InvitationChoice',
    },
    ContractStatus: {
      table: 'CodeLookup',
      category: 'ContractStatus',
    },
    ApplicationStatus: {
      table: 'CodeLookup',
      category: 'ApplicationStatus',
      result: [
        {
          label: 'Commenced',
          Code: 'Commenced',
        },
        {
          label: 'Credit - Approved',
          Code: 'Credit - Approved',
        },
        {
          label: 'Contract - Approved',
          Code: 'Contract - Approved',
        },
        {
          label: 'Credit - Declined',
          Code: 'Credit - Declined',
        },
        {
          label: 'Contract - Declined',
          Code: 'Contract - Declined',
        },
        {
          label: 'Credit - Referred',
          Code: 'Credit - Referred',
        },
        {
          label: 'Contract - Referred',
          Code: 'Contract - Referred',
        },
        {
          label: 'Submitted',
          Code: 'Submitted',
        },
      ],
    },

    LoanPurpose: {
      table: 'SimpleLookup',
      category: 'LoanPurpose',
    },
    MerchantDocumentDescription: {
      table: 'SimpleLookup',
      category: 'MerchantDocumentDescription',
    },
    Salutation: {
      table: 'SimpleLookup',
      category: 'Salutation',
    },

    DMSExtension: {
      table: 'StaticLookup',
      category: '_DMS_Extension',
    },
    Occupation: {
      table: 'Occupation',
      category: 'Occupation',
    },
    PersCircumUpdReason: {
      table: 'CodeLookup',
      category: 'PersCircumUpdReason',
      result: [
        {
          Label: 'Error in data entry',
          Code: 'DER',
        },
        {
          Label: 'Shared Income with Spouse/Partner',
          Code: 'SHI',
        },
        {
          Label: 'Shared Expense with Spouse/Partner',
          Code: 'SHE',
        },
        {
          Label: 'Net Amount entered (not Gross)',
          Code: 'NAE',
        },
        {
          Label: 'Other',
          Code: 'OTH',
        },
      ],
    },
  };
}
