<div class="row clearfix" *ngIf="isPromoterOrAdmin == true">
  <app-operator-filter
    class="filter"
    id="part1"
    *ngIf="hideFilter == false"
    [displayMerchant]="true"
    [displayDateRange]="false"
    [displayDateFrom]="false"
    [displayTimePicker]="false"
    [displayDateTo]="false"
    [displayDentist]="false"
    (search)="applySearch($event)"
  >
  </app-operator-filter>

  <div class="row clearfix">
    <app-tags-list-global
      *ngIf="merchantID != 'none'"
      [staticAudience]="staticAudience"
      [merchantID]="merchantID"
    ></app-tags-list-global>
  </div>
  <div class="row clearfix">
    <div class="pleaseChoose" *ngIf="merchantID == 'none'">
      <p>
        <strong>No merchant selected</strong>
      </p>
      <img class="character_warning" />
      <p>Choose your desired merchant from the drop-down above to view their messages</p>
    </div>
  </div>
</div>

<div class="row clearfix" *ngIf="isPromoterOrAdmin == false">
  <app-tags-list [staticAudience]="staticAudience"></app-tags-list>
</div>
