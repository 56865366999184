<app-social-media-input
  class="clearFix"
  [canRemove]="true"
  [directLogin]="directLogin"
  [googleStatusLabel]="googleStatusLabel"
  [facebookStatusLabel]="facebookStatusLabel"
  [facebookStatus]="facebookStatus"
  [googleStatus]="googleStatus"
  [buttonStyle]="'buttons'"
  [facebookLabel]="facebookLabel"
  [googleLabel]="googleLabel"
  [facebookButtonLabel]="facebookButtonLabel"
  [googleButtonLabel]="googleButtonLabel"
  [googleDate]="googleDate"
  [facebookDate]="facebookDate"
  (getRemove)="getRemoveEvent($event)"
  (getResult)="getResultSocialMedia($event)"
></app-social-media-input>
