import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { MerchantService } from '../../merchant/shared/merchant.service';

import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';

/**
 * @title Feature-rich data table
 */
@Component({
  selector: 'app-dentist-map-overview',
  templateUrl: './dentist-map-overview.component.html',
  styleUrls: ['./dentist-map-overview.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class DentistMapOverViewComponent implements OnInit {
  isAdminOrPromoter = false;

  merchants = [];
  merchantID = 'none';

  hideExtraComponents = false;

  constructor(
    private AuthenticationService: AuthenticationService,
    private router: Router,
    private merchantService: MerchantService
  ) {}
  ngOnInit() {
    this.AuthenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isAdminOrPromoter = res;

      if (this.isAdminOrPromoter == true) {
        const payload = {
          fields: 'ID,TradingAs,CalculatedName,Status',
        };

        this.merchantService.getList(payload, this.isAdminOrPromoter).subscribe((res) => {
          this.merchants = res;
        });
      }
    });
  }

  viewDetails(e) {
    this.hideExtraComponents = e;
  }

  getActionEvent(e) {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['staff-list-overview'],
        },
      },
    ]);
  }

  viewMerchant() {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: this.merchantID,
      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }
}
