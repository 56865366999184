import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatAutocomplete } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { TagsService } from '../shared/tags.service';

@Component({
  selector: 'app-tag-create-edit-card',
  templateUrl: './tag-create-edit-card.component.html',
  styleUrls: ['./tag-create-edit-card.component.css'],
})
export class TagCreateEditCardComponent implements OnInit {
  @Input()
  merchantID;

  @Input()
  cardIDs = [];

  @Input()
  cardID = null;

  @Input()
  isRemoved = false;

  nature = 'Tag';

  @Input()
  subTitle =
    "Insert a tag or multiple ones to be added to the selected patients. Click on 'New " +
    this.nature +
    "' to create a new  one";

  @Output()
  getResult = new EventEmitter();

  @Output()
  newTag = new EventEmitter();

  labels;
  description;

  sessionType;

  isModal = false;

  close = new EventEmitter();

  @ViewChild('tagInput', { static: false }) tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

  selectedTags = [];
  @Input()
  staticAudience = false;

  tag;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private tagsService: TagsService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.merchantID != null) {
        this.merchantID = data.merchantID;
      }
      if (data.isRemoved != null) {
        this.isRemoved = data.isRemoved;
      }

      if (data.cardID != null) {
        this.cardID = data.cardID;
      }
      if (data.cardIDs != null && Array.isArray(data.cardIDs)) {
        this.cardIDs = data.cardIDs;
      }

      if (data.staticAudience != null) {
        this.staticAudience = data.staticAudience;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    if (this.staticAudience === true) {
      this.nature = 'Static Audience';
    } else {
      this.nature = 'Tag';
    }
    if (this.isRemoved == true) {
      this.subTitle = 'Insert a ' + this.nature + ' or multiple ones to be removed from the selected patients.';
    }
    this.authenticationService.getSessionType().subscribe((res) => {
      this.sessionType = res;
    });
  }

  createTag() {
    if (this.selectedTags && this.selectedTags.length > 0) {
      const payload = {
        labels: this.selectedTags.join(','),
        description: this.description,
        cardIDs: this.cardIDs.join(','),
        cardID: this.cardID,
        staticAudience: this.staticAudience,
      };

      if (this.merchantID) {
        payload['merchantID'] = this.merchantID;
      }

      if (this.isRemoved == true) {
        this.tagsService.deleteTagForCard(payload, this.sessionType).subscribe((res) => {
          if (res) {
            NotifyAppComponent.displayToast(
              'success',
              'Create ' + this.nature,
              'You have successfully create a new ' + this.nature
            );

            this.getResult.emit(res);
          }
        });
      } else {
        this.tagsService.createTagForCard(payload, this.sessionType).subscribe((res) => {
          if (res) {
            NotifyAppComponent.displayToast(
              'success',
              'Create ' + this.nature,
              'You have successfully create a new ' + this.nature
            );

            this.getResult.emit(res);
          }
        });
      }
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  getSelectedTags(t) {
    this.selectedTags = t || [];
  }
}
