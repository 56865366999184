<div class="row clearfix mt ml mr" *ngIf="landingPage">
  <mat-card class="rel clearfix">
    <div
      class="card-header primary-gradient-img clearfix inModal rel"
      [attr.style]="'background:' + landingPage.theme.color + ' !important' | safeStyle"
    >
      <div class="row clearfix content">
        <div class="full-width flex main-title-container">
          <div class="row full-width title-container clearfix">
            <div class="title-container-sub full-width flex">
              <landing-page-header-image
                class="pull-left landing-page-header-image-view mr"
                [headerImage]="landingPage['Header_Image']"
                [hideZoomButton]="false"
              >
              </landing-page-header-image>

              <h2 class="text-left title-header full-width rm-m">
                {{ landingPage.Label }}

                <span class="subLabel">
                  <span> {{ landingPage.Title }}</span>
                  <br/>

                  <span class="mr" *ngIf="landingPage['IsDefault'] !== '1' || isPromoterOrAdmin == true">
                    Visitors

                    <span class="badge mr" [endVal]="landingPage.Visitors" countUp></span>
                  </span>
                  <span class="mr" *ngIf="landingPage && landingPage.documentIDs && landingPage.documentIDs.length">
                    Attached Documents

                    <span class="badge mr" [endVal]="landingPage.documentIDs.length" countUp></span>
                  </span>

                  <span class="ml" *ngIf="landingPage">
                    {{ landingPage['Type.Label'] }}
                  </span>

                  <span class="ml chip chipBeta" *ngIf="landingPage['IsActive'] != '1'">Not Active</span>
                  <span class="ml chip chipBeta2" *ngIf="landingPage['IsActive'] == '1'"> Active</span>

                  <span class="ml chip chipBeta2" *ngIf="landingPage['IsDefault'] == '1'">
                    {{ settings['brandName'] }} Template</span
                  >
                  <br/>
                  <br/>

                  <mat-chip-list class="full-width clearfix" #chipList name="tags-patient">
                    <mat-chip *ngFor="let tag of landingPage.tags" [selectable]="false" [removable]="false">
                      {{ tag }}
                    </mat-chip>
                  </mat-chip-list>
                </span>
              </h2>
            </div>
          </div>
        </div>

        <div class="full-width" *ngIf="landingPage.IsDefault !== '1' || isPromoterOrAdmin == true">
          <button
            class="share-social-media-button ml pull-right"
            [matMenuTriggerFor]="menu"
            mat-raised-button
            color="accent"
          >
            More <mat-icon class="sml"> more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <button (click)="edit(landingPage['ID'])" mat-menu-item>
              <mat-icon>edit</mat-icon>
              <span>Edit</span>
            </button>

            <hr *ngIf="landingPage.IsDefault !== '1' || isPromoterOrAdmin == true"/>

            <button
              *ngIf="landingPage['IsActive'] == '1' && (landingPage.IsDefault !== '1' || isPromoterOrAdmin == true)"
              (click)="disable(landingPage['ID'])"
              mat-menu-item
            >
              <mat-icon>cancel</mat-icon>
              <span>Disable</span>
            </button>

            <button
              *ngIf="landingPage['IsActive'] != '1' && (landingPage.IsDefault !== '1' || isPromoterOrAdmin == true)"
              (click)="enable(landingPage['ID'])"
              mat-menu-item
            >
              <mat-icon>check_circle</mat-icon>
              <span>Enable</span>
            </button>

            <hr/>
            <button (click)="clone()" mat-menu-item>
              <mat-icon>content_copy</mat-icon>
              <span>Clone</span>
            </button>

            <hr *ngIf="landingPage.IsDefault !== '1' || isPromoterOrAdmin == true"/>

            <button
              *ngIf="landingPage.IsDefault !== '1' || isPromoterOrAdmin == true"
              (click)="remove(landingPage['ID'])"
              mat-menu-item
            >
              <mat-icon>remove_circle</mat-icon>
              <span>Remove</span>
            </button>
          </mat-menu>

          <button class="share-social-media-button ml pull-right" (click)="share()" mat-raised-button color="accent">
            Share <mat-icon class="sml">share</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="full-width clearfix">
      <div class="full-width mb" *ngIf="landingPage.IsDefault == '1' && isPromoterOrAdmin != true">
        <p class="text-left info-text">
          <button
            class="btn pull-right smb"
            (click)="clone()"
            mat-raised-button
            color="primary"
            style="margin-top: -5px"
          >
            <mat-icon class="smr">content_copy</mat-icon> Clone
          </button>

          <mat-icon class="fas fa-info-circle smr ml"></mat-icon>
          This is a {{ settings['brandName'] }} Template, you need to <strong>Clone</strong> it to be able to use it .
        </p>
      </div>
      <div class="full-width clearfix flex row">
        <div
          class="row ml thrd-width mr"
          *ngIf="
            landingPage.IsDefault !== '1' ||
            isPromoterOrAdmin == true ||
            (landingPage.IsDefault == '1' &&
              isPromoterOrAdmin != true &&
              (landingPage['Treatment_Amount'] !== '0' ||
                (landingPage.documents && landingPage.documents.length > 0) ||
                landingPage['Product_Group.Code'] ||
                landingPage['Contact_key']))
          "
        >
          <div
            class="full-width flex clearfix"
            *ngIf="
              landingPage.IsDefault !== '1' ||
              isPromoterOrAdmin == true ||
              (landingPage.IsDefault == '1' &&
                isPromoterOrAdmin != true &&
                (landingPage['Treatment_Amount'] !== '0' ||
                  landingPage['Product_Group.Code'] ||
                  landingPage['Contact_key']))
            "
          >
            <mat-card class="full-width mat-elevation-z3 mb">
              <div class="row full-width clearfix">
                <h3
                  class="sr-title pull-left rm-m"
                  [attr.style]="'color:' + landingPage.theme.color + ' !important' | safeStyle"
                >
                  Landing Page Details
                </h3>
              </div>
              <hr/>
              <div class="full-width flex clearfix">
                <div class="full-width detail-container" @simpleFadeAnimation>
                  <div
                    class="row clearfix details flex"
                    *ngIf="landingPage['Treatment_Amount'] && landingPage['Treatment_Amount'] !== '0'"
                  >
                    <div class="row-content">
                      <h4 class="rm-m">{{ landingPage['Treatment_Amount'] | customCurrency }}</h4>
                      <p
                        class="small"
                        *ngIf="settings && settings['context'] != 'breeze' "
                      >
                        {{ "KEYWORD.treatment" | translate | titlecase }} Value
                      </p>
                      <p
                        class="small"
                        *ngIf="settings && settings['context'] == 'breeze' "
                      >
                        Amount to Finance
                      </p>
                    </div>
                  </div>

                  <div class="row clearfix details flex" *ngIf="landingPage['Product_Group.Code']">
                    <div class="row-content">
                      <h4 class="rm-m">
                        <mat-icon
                          class="position fas {{ productUtil.getProductGroupIcon(landingPage['Product_Group.Code']) }} "
                          style="top: 0rem; margin-right: 5px !important; font-size: 1rem"
                        >
                        </mat-icon>
                        {{ productUtil.getLabel(landingPage['Product_Group.Code']) }}
                      </h4>
                      <p class="small">Finance Type</p>
                    </div>
                  </div>

                  <div
                    class="row clearfix details flex"
                    *ngIf="
                      landingPage['Contact_key'] && (landingPage['IsDefault'] !== '1' || isPromoterOrAdmin == true)
                    "
                  >
                    <div
                      class="row-content clicktext"
                      (click)="viewDentist(landingPage['Contact_key'])"
                      matTooltip="Click to view "
                    >
                      <h4 class="rm-m">{{ landingPage['Contact_CalculatedName'] }}</h4>
                      <p class="small">Sender</p>
                    </div>
                  </div>

                  <div class="full-width flex" *ngIf="landingPage['IsDefault'] !== '1' || isPromoterOrAdmin == true">
                    <div class="row clearfix details flex">
                      <div class="row-content">
                        <h4
                          class="rm-m good"
                          *ngIf="
                            landingPage['Date.Expire'] == '2200-01-01' || landingPage['Date.Expire'] == '0000-00-00'
                          "
                        >
                          Never
                        </h4>
                        <h4
                          class="rm-m"
                          *ngIf="
                            landingPage['Date.Expire'] != '2200-01-01' && landingPage['Date.Expire'] != '0000-00-00'
                          "
                        >
                          {{ landingPage['Date.Expire'] | customDate }}
                        </h4>
                        <p class="small">Expired At</p>
                      </div>
                    </div>

                    <div
                      class="row clearfix details flex"
                      *ngIf="landingPage['Date.Expire'] != '2200-01-01' && landingPage['Date.Expire'] != '0000-00-00'"
                    >
                      <div class="row-content">
                        <h4 class="rm-m bad" *ngIf="landingPage['isExpired'] === true">Yes</h4>
                        <h4 class="rm-m good" *ngIf="landingPage['isExpired'] !== true">No</h4>
                        <p class="small">Is Expired</p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="full-width clearfix details flex"
                    *ngIf="
                      (landingPage['IsDefault'] !== '1' || isPromoterOrAdmin == true) &&
                      settings &&
                      settings['context'] != 'breeze'    "
                  >
                    <div class="row-content">
                      <h4 class="rm-m good" *ngIf="landingPage['IsPublic'] === '1'">Yes</h4>
                      <h4 class="rm-m bad" *ngIf="landingPage['IsPublic'] !== '1'">No</h4>

                      <p class="small">Tips & Offers</p>
                    </div>
                  </div>
                  <div class="full-width clearfix details flex">
                    <div class="row-content">
                      <h4 class="rm-m" matTooltip="{{ landingPage['DateTimeCreated'] | customDateTime }}">
                        {{ landingPage['DateTimeCreated'] | TimeLabelPast }} ago
                      </h4>
                      <p class="small">Created at</p>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>

          <div class="full-width flex clearfix" *ngIf="landingPage['IsDefault'] !== '1' || isPromoterOrAdmin == true">
            <mat-card class="full-width mat-elevation-z3 mb">
              <div class="row full-width clearfix">
                <h3
                  class="sr-title pull-left rm-m"
                  [attr.style]="'color:' + landingPage.theme.color + ' !important' | safeStyle"
                >
                  QR code
                </h3>
              </div>
              <hr/>
              <div class="full-width flex clearfix">
                <div class="list-view" @simpleFadeAnimation>
                  <p class="small full-width flex">
                    Unique QR codes: The code or URL will redirect your {{ "KEYWORD.patient" | translate }}s to dynamic
                    landing pages personalised
                    to your {{"KEYWORD.practice" | translate}}. Use these in your marketing material, such as brochures,
                    newsletters, email
                    footers, etc.
                  </p>

                  <div class="row clearfix full-width text-center flex">
                    <app-generate-qr


                  [downloadLabel]="landingPage.Title"

                      class="full-width ml mr row"
                      *ngIf="urlQR"
                      [source]="urlQR"
                      [size]="160"
                      [download]="downloadQRCodeDental"
                    >
                    </app-generate-qr>
                  </div>

                  <div class="row clearfix full-width text-center mt">
                    <button
                      *ngIf="landingPage['IsDefault'] !== '1' || isPromoterOrAdmin == true"
                      [attr.style]="'background:' + landingPage.theme.color + ' !important' | safeStyle"
                      (click)="downloadQRCodeDental.emit()"
                      mat-raised-button
                      color="accent"
                    >
                      Download QR code
                    </button>

                    <button
                      *ngIf="landingPage['IsDefault'] === '1' && isPromoterOrAdmin != true"
                      [attr.style]="'background:' + landingPage.theme.color + ' !important' | safeStyle"
                      (click)="downloadQRCodeDental.emit()"
                      mat-raised-button
                      color="accent"
                    >
                      Download QR code
                    </button>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>

          <div
            class="full-width flex clearfix"
            *ngIf="landingPage && landingPage.documents && landingPage.documents.length > 0"
          >
            <mat-card class="full-width mat-elevation-z3 mb">
              <div class="row full-width clearfix">
                <h3
                  class="sr-title pull-left rm-m"
                  [attr.style]="'color:' + landingPage.theme.color + ' !important' | safeStyle"
                >
                  Attached Documents
                </h3>
              </div>
              <div class="full-width flex clearfix">
                <div class="list-view full-width" @simpleFadeAnimation>
                  <app-ep-document-list-mini
                    class="white-title full-width landing-doc-attachments"
                    [instructionLine]="''"
                    [displayStatus]="false"
                    [title]="''"
                    [displayDate]="false"
                    [documents]="landingPage.documents"
                    [isRedirection]="false"
                    [isDownload]="false"
                    [isPrint]="false"
                    [isAction]="false"
                    [displayViewedDate]="false"
                    [isFilter]="false"
                    [view]="'merchant'"
                  >
                  </app-ep-document-list-mini>
                </div>
              </div>
            </mat-card>
          </div>
          <div class="full-width flex clearfix" *ngIf="landingPage['IsDefault'] !== '1' || isPromoterOrAdmin == true">
            <mat-card class="full-width mat-elevation-z3 mb" *ngIf="totalVisitor">
              <div class="row clearfix">
                <h3
                  class="sr-title pull-left rm-m"
                  [attr.style]="'color:' + landingPage.theme.color + ' !important' | safeStyle"
                >
                  Unique Visits chart
                </h3>
              </div>
              <hr/>
              <!-- <hr> -->
              <div class="chart-view" @simpleFadeAnimation>
                <div class="base-chart-margin base-chart-margin-container rel mr">
                  <div class="total anim totalPosition">
                    <span [endVal]="totalVisitor" countUp></span>
                    <span class="totSub">Total</span>
                  </div>
                  <app-chart-input
                    class="fullHeight visitor-charts mb clearfix"
                    [titleIsTop]="false"
                    [showLegend]="true"
                    [legendPosition]="'bottom'"
                    [showLabel]="false"
                    [chartType]="'pie'"
                    [title]=""
                    [data]="chartVisitorData | orderBy: ['-name']"
                    [dataNameAttribute]="'name'"
                    [dataNameAttribute]="'value'"
                  >
                  </app-chart-input>
                </div>
              </div>
            </mat-card>
          </div>

          <div class="full-width flex clearfix" *ngIf="landingPage['IsDefault'] !== '1' || isPromoterOrAdmin == true">
            <mat-card class="full-width mat-elevation-z3 mb">
              <div class="row clearfix">
                <h3
                  class="sr-title pull-left rm-m"
                  [attr.style]="'color:' + landingPage.theme.color + ' !important' | safeStyle"
                >
                  Unique Visits
                </h3>
              </div>
              <hr/>

              <div class="list-view" @simpleFadeAnimation>
                <mat-list>
                  <mat-list-item>
                    <div class="row flex-row">
                      <p class="reduce-marg">
                        <mat-icon class="fas fa-envelope mr" [style.color]="'#8e8e8e'"></mat-icon>
                        Email
                      </p>
                      <div class="pull-right">
                        <p class="reduce-marg pull-right">{{ landingPage['Email_Visitors'] || 0 }}</p>
                      </div>
                    </div>
                    <mat-divider></mat-divider>
                  </mat-list-item>
                  <mat-list-item>
                    <div class="row flex-row">
                      <p class="reduce-marg">
                        <mat-icon class="fas fa-mobile-alt mr" [style.color]="'#8e8e8e'"></mat-icon>
                        SMS
                      </p>
                      <div class="pull-right">
                        <p class="reduce-marg pull-right">{{ landingPage['SMS_Visitors'] || 0 }}</p>
                      </div>
                    </div>
                    <mat-divider></mat-divider>
                  </mat-list-item>
                  <mat-list-item *ngIf="target != 'practice'">
                    <div class="row flex-row">
                      <p class="reduce-marg">
                        <mat-icon class="fas fa-hospital mr" [style.color]="'#8e8e8e'"></mat-icon>
                        {{ "KEYWORD.practice" | translate | titlecase}} Website
                      </p>
                      <div class="pull-right">
                        <p class="reduce-marg pull-right">{{ landingPage['Practice_Site_Visitors'] || 0 }}</p>
                      </div>
                    </div>
                    <mat-divider></mat-divider>
                  </mat-list-item>

                  <mat-list-item *ngIf="target != 'dental'">
                    <div class="row flex-row">
                      <p class="reduce-marg">
                        <mat-icon class="fas {{'fa-tooth' | translate}} mr" [style.color]="'#8e8e8e'"></mat-icon>
                        Tips and Offers page
                      </p>
                      <div class="pull-right">
                        <p class="reduce-marg pull-right">{{ landingPage['Offer_Page_Visitors'] || 0 }}</p>
                      </div>
                    </div>
                    <mat-divider></mat-divider>
                  </mat-list-item>
                  <mat-list-item>
                    <div class="row flex-row">
                      <p class="reduce-marg">
                        <mat-icon class="fas fa-qrcode mr" [style.color]="'#8e8e8e'"></mat-icon>
                        QR Code Scan
                      </p>
                      <div class="pull-right">
                        <p class="reduce-marg pull-right">{{ landingPage['QR_Scan_Visitors'] || 0 }}</p>
                      </div>
                    </div>
                    <mat-divider></mat-divider>
                  </mat-list-item>
                  <mat-list-item>
                    <div class="row flex-row">
                      <p class="reduce-marg">
                        <mat-icon class="fas fa-bullhorn mr" [style.color]="'#8e8e8e'"></mat-icon>
                        Campaign
                      </p>
                      <div class="pull-right">
                        <p class="reduce-marg pull-right">{{ landingPage['Campaign_Visitors'] || 0 }}</p>
                      </div>
                    </div>
                    <mat-divider></mat-divider>
                  </mat-list-item>

                  <mat-list-item>
                    <div class="row flex-row">
                      <p class="reduce-marg">
                        <mat-icon class="fab fa-facebook mr" [style.color]="'#8e8e8e'"></mat-icon>
                        Facebook
                      </p>
                      <div class="pull-right">
                        <p class="reduce-marg pull-right">{{ landingPage['Facebook_Visitors'] || 0 }}</p>
                      </div>
                    </div>
                    <mat-divider></mat-divider>
                  </mat-list-item>
                  <mat-list-item>
                    <div class="row flex-row">
                      <p class="reduce-marg">
                        <mat-icon class="fab fa-linkedin mr" [style.color]="'#8e8e8e'"></mat-icon>
                        Linkedin
                      </p>
                      <div class="pull-right">
                        <p class="reduce-marg pull-right">{{ landingPage['Linkedin_Visitors'] || 0 }}</p>
                      </div>
                    </div>
                    <mat-divider></mat-divider>
                  </mat-list-item>
                  <mat-list-item>
                    <div class="row flex-row">
                      <p class="reduce-marg">
                        <mat-icon class="fab fa-twitter mr" [style.color]="'#8e8e8e'"></mat-icon>
                        Twitter
                      </p>
                      <div class="pull-right">
                        <p class="reduce-marg pull-right">{{ landingPage['Twitter_Visitors'] || 0 }}</p>
                      </div>
                    </div>
                    <mat-divider></mat-divider>
                  </mat-list-item>
                  <mat-list-item>
                    <div class="row flex-row">
                      <p class="reduce-marg">
                        <mat-icon class="fab fa-instagram mr" [style.color]="'#8e8e8e'"></mat-icon>
                        Instagram
                      </p>
                      <div class="pull-right">
                        <p class="reduce-marg pull-right">{{ landingPage['Instagram_Visitors'] || 0 }}</p>
                      </div>
                    </div>
                    <mat-divider></mat-divider>
                  </mat-list-item>

                  <mat-list-item>
                    <div class="row flex-row">
                      <p class="reduce-marg">
                        <mat-icon class="fab fa-pinterest mr" [style.color]="'#8e8e8e'"></mat-icon>
                        Pinterest
                      </p>
                      <div class="pull-right">
                        <p class="reduce-marg pull-right">{{ landingPage['Pinterest_Visitors'] || 0 }}</p>
                      </div>
                    </div>
                    <mat-divider></mat-divider>
                  </mat-list-item>
                  <mat-list-item>
                    <div class="row flex-row">
                      <p class="reduce-marg">
                        <mat-icon class="fas fa-file mr" [style.color]="'#8e8e8e'"></mat-icon>
                        Other
                      </p>
                      <div class="pull-right">
                        <p class="reduce-marg pull-right">{{ landingPage['Other_Visitors'] || 0 }}</p>
                      </div>
                    </div>
                    <mat-divider></mat-divider>
                  </mat-list-item>
                  <mat-list-item class="mt">
                    <div class="row clearfix full-width" matLine>
                      <p class="reduce-marg bold pull-left">Total Visitors</p>
                      <p class="reduce-marg bold pull-right">{{ totalVisitor }}</p>
                    </div>
                  </mat-list-item>
                </mat-list>
              </div>
            </mat-card>
          </div>
        </div>

        <div class="full-width page-container row" *ngIf="link">
          <div class="row clearfix full-width flex full-height templateViewArea">
            <iframe
              class="preview-iframe"
              *ngIf="iFrameSrcSecured"
              [src]="iFrameSrcSecured"
              (load)="onFrameLoad($event)"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <hr class="mt"/>

    <div class="full-width mt text-center" *ngIf="settings">
      <button
        class="btn-large pull-left mr"
        [attr.style]="'background:' + landingPage.theme.color + ' !important' | safeStyle"
        (click)="goToList()"
        mat-raised-button
        color="accent"
      >
        <mat-icon class="smr">keyboard_arrow_left</mat-icon> Landing Page List
      </button>
    </div>
  </mat-card>
</div>
