import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { BankAccountService } from '../shared/bank-account.service';

@Component({
  selector: 'app-payment-view',
  templateUrl: './payment-view.component.html',
  styleUrls: ['./payment-view.component.css'],
  animations: [
    trigger('fade', [transition(':enter', [style({ opacity: 0 }), animate('.6s ease')])]),
    trigger('stagger', [transition(':enter', [query(':enter', stagger('.3s', [animateChild()]), { optional: true })])]),
  ],
})
export class PaymentViewComponent implements OnInit {
  @Input()
  paymentID;

  @Input()
  paymentObject;

  isModal = false;
  payment;

  isPromoterOrAdmin = false;

  constructor(
    private bankAccountService: BankAccountService,
    private activeRouter: ActivatedRoute,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.paymentID = data;

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.activeRouter.params.subscribe((params) => {
      if (params['paymentID']) {
        this.paymentID = params['paymentID'];
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        if (res == true) {
          if (this.paymentObject && typeof this.paymentObject == 'object') {
            this.payment = this.paymentObject;
          }

          if (this.paymentID) {
            this.bankAccountService.getOnePayment(this.paymentID).subscribe((res) => {
              this.payment = res;
            });
          }
        }
      });
    });
  }
}
