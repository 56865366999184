import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PatientService {
  params: HttpParams;

  constructor(private http: HttpClient) {}

  /**
   * @description
   * Get Decline Reasons
   */
  getDeclineReasons() {
    return this.http.get(`${environment.nodeUrl}/declineReason`).pipe(map((res: Response) => res['data']));
  }

  /**
   * @description
   * Hold off the selected invitation
   * @param id
   * @param choice
   */
  holdInivation(id, choice, force = false) {
    if (force == true) {
      return this.http
        .put(`${environment.nodeUrl}/invitation/hold/force/${id}`, choice)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(`${environment.nodeUrl}/invitation/hold/${id}`, choice)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  resetChoiceInivation(id, force = false) {
    if (force == true) {
      return this.http
        .put(`${environment.nodeUrl}/invitation/resetChoice/force/${id}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(`${environment.nodeUrl}/invitation/resetChoice/${id}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  notFinanceInivation(id, force = false) {
    if (force == true) {
      return this.http
        .put(`${environment.nodeUrl}/invitation/notFinance/force/${id}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(`${environment.nodeUrl}/invitation/notFinance/${id}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  notFinanceInivationWithDiscount(id, force = false) {
    if (force == true) {
      return this.http
        .put(`${environment.nodeUrl}/invitation/notFinanceWithDiscount/force/${id}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(`${environment.nodeUrl}/invitation/notFinanceWithDiscount/${id}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  changeInivation(id, payload, force = false) {
    if (force == true) {
      return this.http
        .put(`${environment.nodeUrl}/invitation/change/force/${id}`, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(`${environment.nodeUrl}/invitation/change/${id}`, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  stopProceedInivation(id, payload, force = false) {
    if (force == true) {
      return this.http
        .put(`${environment.nodeUrl}/invitation/stop/force/${id}`, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(`${environment.nodeUrl}/invitation/stop/${id}`, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  selectProduct(id, payload) {
    return this.http
      .put(`${environment.nodeUrl}/invitation/product/${id}`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  requestInvitationCode(id): Observable<any> {
    return this.http
      .post(environment.nodeUrl + `/invitation/code/${id}`, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  proceedDental(id, body = {}, isPromotionOrAdmin = false): Observable<any> {
    if (isPromotionOrAdmin == true) {
      return this.http
        .put(environment.nodeUrl + `/invitation/proceed/dental/global/${id}`, body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + `/invitation/proceed/dental/${id}`, body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  /**
   * @description
   * Stop proceed the invitation
   * @param id
   * @param choice
   */

  getCustomerInvitation(id, sessionType = 'guest') {
    if (sessionType == 'customer') {
      return this.http
        .get(`${environment.nodeUrl}/invitation/customer/${id}`)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(`${environment.nodeUrl}/invitation/guest/${id}`)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  setParams(param) {
    this.params = new HttpParams();
    for (const key in param) {
      if (param.hasOwnProperty(key)) {
        const val = param[key];
        this.params = this.params.append(key, val);
      }
    }
  }

  autoGenerateNote(body): Observable<any> {
    return this.http
      .post(environment.nodeUrl + '/action-log/customer', body)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  acceptMerchantInformedConsent(invitationID, patientDeclarationDocKey) {
    return this.http
      .put(`${environment.nodeUrl}/informed-consent/accept/${invitationID}/${patientDeclarationDocKey}`, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  uploadProfilePic(ID, payload = {}) {
    return this.http
      .put(`${environment.nodeUrl}/customer-prospect/profile-picture/` + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getProfilePic(ID, payload = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.http
      .get(`${environment.nodeUrl}/customer-prospect/profile-picture/` + ID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getProfilePicStreamLink(ID) {
    if (ID) {
      return `${environment.nodeUrl}/customer-prospect/profile-picture-stream/` + ID;
    }
    return null;
  }
}
