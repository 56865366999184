import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../authentication/shared/authentication.service';

import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-page403',
  templateUrl: './page403.component.html',
  styleUrls: ['./page403.component.css'],
})
export class Page403Component implements OnInit {
  countdown: number;
  stream$;
  subscribe;
  redirection = true;

  type;
  title = 'We are sorry, you do not have access to this page or service.';
  error = 'Try to login or contact us for more details.';
  constructor(
    private router: Router,
    private location: Location,
    private activeRoute: ActivatedRoute,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    AuthenticationService.setDrift.emit('errorDrift');

    this.activeRoute.params.subscribe((params) => {
      if (params && params['type']) {
        this.type = params['type'];
      }
      if (params && params['title']) {
        this.title = params['title'];
      }
      if (params && params['error']) {
        this.error = params['error'];
      }
      if (!this.type || this.type != 'raw') {
        this.startCountdownTimer();
      }
    });
  }

  ngOnDestroy() {
    this.redirection = false;
    this.subscribe.unsubscribe();
  }

  cancel() {
    this.redirection = false;
    this.subscribe.unsubscribe();
    this.router.navigate(['/login']);
  }

  login() {
    this.authService.logout().subscribe(
      (res) => {
        this.activeRoute.params.subscribe((params) => {
          this.authService.initCurrUser();
          this.router.navigate(['/login']);
        });
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  contactUs() {
    if (this.subscribe) {
      this.redirection = false;
      this.subscribe.unsubscribe();
      this.countdown = null;
    }

    AuthenticationService.contactUSEvent.emit();
  }

  goHome() {
    this.redirection = false;
    this.subscribe.unsubscribe();
    window.open('/login', '_self');
  }

  startCountdownTimer() {
    this.countdown = 0;
    const interval = 1000;
    const duration = 15 * 1000;
    const stream$ = Observable.timer(0, interval)
      .finally(() => {
        if (this.redirection == true) {
          this.goHome();
        }
      })
      .takeUntil(Observable.timer(duration + interval))
      .map((value) => duration - value * interval);
    this.subscribe = stream$.subscribe((value) => (this.countdown = value));
  }
}
