<div class="rel">
  <div class="row clearfix">
    <div class="pull-right">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear mat-button" (click)="closeModal()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>

    <h3 class="sr-title rm-mt rm-mb pull-left">Select all reasons for declining this request</h3>
  </div>
  <mat-dialog-content>
    <hr/>
    <div class="alternate-theme">
      <div class="row clearfix" *ngIf="isConsentedByForm == true">
        <h4 class="sr-title">Issues with authorisation form</h4>
        <div class="clearfix row" *ngFor="let a of issuesAuthorisedForm">
          <div class="thr-qtr-width">
            <p class="rm-mt mobSm">{{ a }}</p>
          </div>
          <div class="qtr-width text-center">
            <mat-checkbox (change)="selectReason(a, $event)"></mat-checkbox>
          </div>
        </div>
      </div>

      <div class="row clearfix" *ngIf="isRefund == false">
        <h4 class="sr-title">Issues with invoice</h4>
        <div class="clearfix row" *ngFor="let i of issuesInvoice">
          <div class="thr-qtr-width">
            <p class="rm-mt mobSm">{{ i }}</p>
          </div>
          <div class="qtr-width text-center">
            <mat-checkbox (change)="selectReason(i, $event)"></mat-checkbox>
          </div>
        </div>
      </div>
    </div>

    <mat-divider *ngIf="isRefund == false || isConsentedByForm == true"></mat-divider>
    <div class="clearfix mt">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Any additional comments for {{ "KEYWORD.practice" | translate }} </mat-label>
        <textarea [(ngModel)]="feedback" matInput
                  [placeholder]="'Any additional comments for' + ('KEYWORD.practice' | translate) + ' practice'"></textarea>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <div class="row clearfix text-center">
    <button class="" [disabled]="!feedback && reasons.length == 0" (click)="submit()" mat-raised-button color="accent">
      Submit
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>
