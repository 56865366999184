import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/table';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatPaginator, MatSort } from '@angular/material';
import { MatSelect } from '@angular/material/select';
import * as moment from 'moment';
import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { SideMenuService } from '../../../shared/services/side-menu.service';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { ActionLogUtilClass } from '../../action-log/shared/actionLogUtil';
import { MessageViewComponent } from '../message-view/message-view.component';
import { MessageService } from '../shared/message.service';
import { MessageUtil } from '../shared/messageUtil.type';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { ConfirmDialogSingleButtonComponent } from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { MessageGroupViewComponent } from '../message-group-view/message-group-view.component';
import { Router } from '@angular/router';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';

@Component({
  selector: 'app-message-group-list',
  templateUrl: './message-group-list.component.html',
  styleUrls: ['./message-group-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MessageGroupListComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  dateFrom;

  @Input()
  status;

  @Input()
  messageStatus;
  @Input()
  merchantID;
  messageLabel = 'SMS';
  @Input()
  dateTo;
  @Input()
  title = 'Bulk ' + this.messageLabel + ' ';

  @Input()
  pageSize = Settings['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  searchLabel;
  utils = new UtilsClass();

  displayedColumns = ['selectCheckBox', 'Label', 'Recipients', 'Status', 'Merchant', 'Sender', 'Date', 'Actions'];

  filters = [];

  searchVals = new FormControl();

  searchValList = ['Type'];
  expandedRow: number;
  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  selectedIDs = [];
  filteredSize = null;

  isPromoterOrAdmin = false;

  destroyEvent = new EventEmitter();

  actionLogUtil = new ActionLogUtilClass();

  messageUtil = new MessageUtil();
  groupFilter = 'merchant';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('searchField', { static: false }) searchField: ElementRef;
  @ViewChild('messageStatusSelector', { static: false }) messageStatusSelector: MatSelect;
  @ViewChild('messageTypeSelector', { static: false }) messageTypeSelector: MatSelect;

  sessionType = 'merchant';

  constructor(
    private messageService: MessageService,
    private authenticationService: AuthenticationService,
    private router: Router,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.merchantID) {
        this.merchantID = data.merchantID;
      }
      if (data.status) {
        this.status = data.status;
      }
      if (data.messageStatus) {
        this.messageStatus = data.messageStatus;
      }
    }
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });
  }

  ngOnInit() {
    if (this.status) {
      const index = this.displayedColumns.indexOf('Context');
      if (index != -1) {
        this.displayedColumns.splice(index, 1);
      }
    }
    SideMenuService.goBack.subscribe((res) => {
      if (res === true) {
      }
    });

    let p = {
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      status: this.status,
      merchantID: this.merchantID,
      messageStatus: this.messageStatus,
    };
    this.authenticationService.getSessionType().subscribe((aSessionType) => {
      this.sessionType = aSessionType;
      this.listDB = new LoadRecords(this.messageService, this.destroyEvent, this.sessionType, p);
      this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      if (this.isPromoterOrAdmin === false) {
        this.displayedColumns = ['selectCheckBox', 'Label', 'Recipients', 'Status', 'Sender', 'Date', 'Actions'];
      } else if (this.isPromoterOrAdmin === true && this.merchantID) {
        this.displayedColumns = [
          'selectCheckBox',
          'Label',
          'Recipients',
          'Status',
          'Merchant',
          'Sender',
          'Date',
          'Actions',
        ];
      }
    });
    let p = {
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      status: this.status,
      merchantID: this.merchantID,
    };

    this.listDB = new LoadRecords(this.messageService, this.destroyEvent, this.sessionType, p);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  changeStatusGroup(s) {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      let confirmDialog = new ConfirmDialog(
        this.messageUtil.getStatusIcon(s),
        'Do you want to proceed ?',
        'Change Selected Campaigns  status to ' + s,
        'Cancel',
        'Okay'
      );

      if (s === 'Complete') {
        confirmDialog = new ConfirmDialog(
          this.messageUtil.getStatusIcon(s),
          '',
          'Are you sure you want to mark the selected Campaigns as  <strong>Complete</strong> ?<br><br> Any Pending, Scheduled or Paused Messages will be canceled.',
          'No',
          'Yes'
        );
      }

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              status: s,
              ids: this.selectedIDs.join(','),
            };
            this.messageService.modifyMessageGroupGroup(payload, this.sessionType).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', '');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }
  remove(id) {
    if (id) {
      const confirm = new ConfirmDialog('fas fa-trash', '', 'Are you sure you want to remove Campaign ?', 'No', 'Yes');

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.messageService.deleteMessageGroup(id, this.sessionType).subscribe((res) => {
            if (res) {
              this.dataSource.removeItem = id;

              NotifyAppComponent.displayToast(
                'success',
                'Successful operation',
                'The Bulk ' + this.messageLabel + ' has been successfully removed'
              );
            }
            ref.close();
          });
        }
      });
    }
  }

  selectPage() {
    const index = this.dataSource['_paginator']['index'] || 0;
    const pageSize = this.dataSource['_paginator']['pageSize'] || 0;

    for (let i = 0; i < (index + 1) * pageSize; i++) {
      const o = this.dataSource.filteredData[index * pageSize + i];

      if (o && o['ID'] && this.selectedIDs.indexOf(o['ID']) == -1) {
        this.selectedIDs.push(o['ID']);
      }
    }
  }

  select(id, isSelected) {
    if (isSelected == true && this.selectedIDs.indexOf(id) == -1) {
      this.selectedIDs.push(id);
    } else {
      const _index = this.selectedIDs.indexOf(id);
      this.selectedIDs.splice(_index, 1);
    }
  }

  unselect() {
    this.selectedIDs = [];
  }

  isSelected(id) {
    if (!this.selectedIDs || this.selectedIDs.length <= 0) {
      return false;
    } else {
      if (this.selectedIDs.indexOf(id) != -1) {
        return true;
      }
    }

    return false;
  }

  selectAll() {
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      const o = this.dataSource.filteredData[i];

      if (o && o['ID'] && this.selectedIDs.indexOf(o['ID']) == -1) {
        this.selectedIDs.push(o['ID']);
      }
    }
  }
  add() {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['message-group-create'],
        },
      },
    ]);
  }
  removeGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirm = new ConfirmDialog(
        'fas fa-trash',
        '',
        'Are you sure you want to remove these Bulk ' + this.messageLabel + '   ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.messageService.deleteMessageGroupGroup(this.selectedIDs, this.sessionType).subscribe((res) => {
            if (res && res.length > 0) {
              for (let i = 0; i < res.length; i++) {
                this.dataSource.removeItem = res[i];
              }

              this.selectedIDs = [];
            }
            ref.close();

            NotifyAppComponent.displayToast('success', 'Success!', '');
          });
        }
      });
    }
  }

  displayStatusGroupDescription(s) {
    if (s && this.messageUtil.getMessageGroupDescription(s)) {
      const confirmDialog2 = new ConfirmDialogSingleButton(
        'fas fa-info',
        s,
        '<p>' + this.messageUtil.getMessageGroupDescription(s) + '</p>',
        'OKAY'
      );
      const ref2 = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
        data: confirmDialog2,
        width: '450px',
      });
      ref2.componentInstance.onConfirm.subscribe((res) => {
        ref2.close();
      });
    }
  }

  setFilter(event, field) {
    const filter = {
      field,
      value: event,
    };
    this.searchLabel = event;
    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  setTypeFilter(event, field) {
    let filter;
    if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }
    this.dataSource.filterType = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  setStatusFilter(event, field) {
    let filter;
    if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filterStatus = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  view(id) {
    if (id) {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['message-group-view', id],
          },
        },
      ]);
    }
  }
  continue(id) {
    if (id) {
      let confirm = new ConfirmDialog(
        'fas fa-edit',
        '',
        'You want to  <strong>Continue</strong> editing Campaign ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          ref.close();
          this.router.navigate(['/merchant', { outlets: { page: ['message-group-draft', id] } }]);
        }
      });
    }
  }

  canApplyStatus(row, s) {
    if (
      row &&
      row.ID &&
      row.AllowedStatusUpdates &&
      row.AllowedStatusUpdates.length > 0 &&
      row.AllowedStatusUpdates.indexOf(s) !== -1
    ) {
      return true;
    }
    return false;
  }

  changeStatus(row, s) {
    if (row && row.ID) {
      let confirm = new ConfirmDialog(
        this.messageUtil.getStatusIcon(s),
        '',
        'Are you sure you want to <strong>Cancel</strong> Campaign ?',
        'No',
        'Yes'
      );

      if (s === 'Canceled') {
        confirm = new ConfirmDialog(
          this.messageUtil.statusIcons[s],
          '',
          'Are you sure you want to <strong>Cancel</strong> Campaign ?',
          'No',
          'Yes'
        );
      } else if (s === 'Complete') {
        confirm = new ConfirmDialog(
          this.messageUtil.statusIcons[s],
          '',
          'Are you sure you want to mark this Campaign as  <strong>Complete</strong> ?<br><br> Any Pending, Scheduled or Paused Messages will be canceled.',
          'No',
          'Yes'
        );
      } else if (s === 'Paused') {
        confirm = new ConfirmDialog(
          this.messageUtil.statusIcons[s],
          '',
          'Are you sure you want to <strong>Pause</strong> Campaign ?',
          'No',
          'Yes'
        );
      } else if (s === 'Sending') {
        confirm = new ConfirmDialog(
          this.messageUtil.statusIcons[s],
          '',
          'Are you sure you want to <strong>Resume</strong> Campaign ?',
          'No',
          'Yes'
        );
      }

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.messageService.modifyMessageGroup(row.ID, { status: s }, this.sessionType).subscribe((r) => {
            if (r && r.ID) {
              this.dataSource.replaceItem = r;
              ref.close();
            }
          });
        }
      });
    }
  }

  clone(row) {
    if (row && row.ID) {
      let confirm = new ConfirmDialog(
        'fas fa-clone',
        '',
        'Are you sure you want to <strong>Clone</strong> Campaign ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          ref.close();
          this.router.navigate(['/merchant', { outlets: { page: ['message-group-clone', row.ID] } }]);
        }
      });
    }
  }

  quickView(id) {
    const ref = RootAppComponent.dialog.open(MessageGroupViewComponent, {
      data: {
        messageGroupID: id,
      },
      panelClass: ['noCard', 'max-width-panel-1100'],
      width: '90vw',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });


    ref.componentInstance.getRemove.subscribe((res) => {

      if(res)
      {
        this.dataSource.removeItem = id;
      }
      ref.close();
    });

    ref.componentInstance.getLastVersion.subscribe((res) => {
      if (res && res.ID) {
        this.dataSource.replaceItem = res;
      }
    });
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit, OnDestroy {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;
  firstLoad = false;
  util = new UtilsClass();
  isDestoyed = false;
  serviceRef;
  messageFilter;

  constructor(
    private messageService: MessageService,
    private destroyEvent,
    private sessionType,
    private params: any = {}
  ) {
    if (destroyEvent !== undefined) {
      this.destroyEvent.subscribe((res) => {
        this.isDestoyed = res;
      });
    }
    const payload: any = {
      section: 0,
      fields:
        'ID,DateTimeCreated,LastModified,LastModified_Human,Label,Description,Status.Code,Status.Label,Target_Type.Code,' +
        'Target_Type.Label,Message,Merchant_key,Merchant_Trading_As,Sender_key,Sender.Name,Submit_DateTime,Finshed_DateTime,' +
        'Date_To_be_Sent,Target_Tags_Number,Target_SelectedIDs_Number,Target_ExcludedIDs_Number,Target_Filters_Number,Tags,Group_Filter_keys,' +
        'Excluded_Card_IDs,Selected_Card_IDs,Target_Number,Is_Email,Scheduled_Message_Total,Queued_Message_Total,Delayed_Message_Total,Sent_Message_Total,' +
        'Printed_Message_Total,Delivered_Message_Total,Read_Message_Total,Clicked_Message_Total,Expired_Message_Total,Rejected_Message_Total,Hard_Bounce_Message_Total,' +
        'Soft_Bounced_Message_Total,Failed_Message_Total,Invalid_Message_Total,Received_Message_Total,Read_Incoming_Message_Total,Actioned_Message_Total,Unknown_Message_Total,' +
        'Blacklisted_Message_Total,Unsubscribed_Message_Total,Opened_Message_Total,Spam_Message_Total,Error_Message_Total,Bounced_Message_Total',
    };

    if (this.params.dateFrom) {
      payload.dateFrom = this.util.EPdateFormat(this.params.dateFrom);
    }

    if (this.params.dateTo) {
      payload.dateTo = this.util.EPdateFormat(this.params.dateTo);
    }

    if (this.params.merchantID) {
      payload.merchantID = this.params.merchantID;
    }

    if (this.params.status) {
      payload.status = this.params.status;
    }

    this.serviceRef = this.messageService.getMessageGroupList(payload, this.sessionType).subscribe((res) => {
      res = res.map((data) => {
        data['search'] = '';

        if (data['Label']) {
          data['search'] = data['search'] + ' ' + data['Label'];
        }
        if (data['Message']) {
          data['search'] = data['search'] + ' ' + data['Message'];
        }
        if (data['ID']) {
          data['search'] = data['search'] + ' ' + data['ID'];
        }
        if (data['Merchant_key']) {
          data['search'] = data['search'] + ' ' + data['Merchant_key'];
        }
        if (data['Sender_key']) {
          data['search'] = data['search'] + ' ' + data['Sender_key'];
        }

        if (data['Description']) {
          data['search'] = data['search'] + ' ' + data['Description'];
        }
        if (data['Status.Label']) {
          data['search'] = data['search'] + ' ' + data['Status.Label'];
        }
        if (data['Message']) {
          data['search'] = data['search'] + ' ' + data['Message'];
        }
        if (data['Tags']) {
          data['search'] = data['search'] + ' ' + data['Tags'];
        }
        if (data['Merchant_Trading_As']) {
          data['search'] = data['search'] + ' ' + data['Merchant_Trading_As'];
        }
        if (data['Sender.Name']) {
          data['search'] = data['search'] + ' ' + data['Sender.Name'];
        }

        return data;
      });
      this.items = res;
      this.dataChange.next(this.items);
      this.firstLoad = true;
      const innerFunction = (section) => {
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;
        this.serviceRef = this.messageService.getMessageGroupList(payload, this.sessionType).subscribe((res1) => {
          if (res1.length > 0) {
            res1 = res1.map((data) => {
              data['search'] = '';

              if (data['Label']) {
                data['search'] = data['search'] + ' ' + data['Label'];
              }
              if (data['Message']) {
                data['search'] = data['search'] + ' ' + data['Message'];
              }
              if (data['ID']) {
                data['search'] = data['search'] + ' ' + data['ID'];
              }
              if (data['Merchant_key']) {
                data['search'] = data['search'] + ' ' + data['Merchant_key'];
              }
              if (data['Sender_key']) {
                data['search'] = data['search'] + ' ' + data['Sender_key'];
              }

              if (data['Description']) {
                data['search'] = data['search'] + ' ' + data['Description'];
              }
              if (data['Status.Label']) {
                data['search'] = data['search'] + ' ' + data['Status.Label'];
              }
              if (data['Message']) {
                data['search'] = data['search'] + ' ' + data['Message'];
              }
              if (data['Tags']) {
                data['search'] = data['search'] + ' ' + data['Tags'];
              }
              if (data['Merchant_Trading_As']) {
                data['search'] = data['search'] + ' ' + data['Merchant_Trading_As'];
              }
              if (data['Sender.Name']) {
                data['search'] = data['search'] + ' ' + data['Sender.Name'];
              }
              return data;
            });

            this.items = this.items.concat(res1);
            this.dataChange.next(this.items.slice());
            if (this.isDestoyed !== true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };
      if (this.isDestoyed !== true) {
        innerFunction(1);
      }
    });
  }

  get data(): any[] {
    return this.dataChange.value;
  }

  get firstLoadEvent() {
    return this.firstLoad;
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  _filterStatusChange = new BehaviorSubject('');
  _filterTypeChange = new BehaviorSubject('');
  field = {
    type: '',
    status: '',
    searchField: '',
  };
  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
    this._filterStatusChange.subscribe(() => (this._paginator.pageIndex = 0));
    this._filterTypeChange.subscribe(() => (this._paginator.pageIndex = 0));
  }
  set removeItem(id) {
    const data = this._tableDatabase.data.filter((row) => row.ID != id);
    this._tableDatabase.dataChange.next(data);
  }
  set replaceItem(item) {
    const _res = item;

    if (_res && _res.ID) {
      _res['search'] = '';

      if (_res['Label']) {
        _res['search'] = _res['search'] + ' ' + _res['Label'];
      }
      if (_res['Message']) {
        _res['search'] = _res['search'] + ' ' + _res['Message'];
      }
      if (_res['ID']) {
        _res['search'] = _res['search'] + ' ' + _res['ID'];
      }
      if (_res['Merchant_key']) {
        _res['search'] = _res['search'] + ' ' + _res['Merchant_key'];
      }
      if (_res['Sender_key']) {
        _res['search'] = _res['search'] + ' ' + _res['Sender_key'];
      }

      if (_res['Description']) {
        _res['search'] = _res['search'] + ' ' + _res['Description'];
      }
      if (_res['Status.Label']) {
        _res['search'] = _res['search'] + ' ' + _res['Status.Label'];
      }
      if (_res['Message']) {
        _res['search'] = _res['search'] + ' ' + _res['Message'];
      }
      if (_res['Tags']) {
        _res['search'] = _res['search'] + ' ' + _res['Tags'];
      }
      if (_res['Merchant_Trading_As']) {
        _res['search'] = _res['search'] + ' ' + _res['Merchant_Trading_As'];
      }
      if (_res['Sender.Name']) {
        _res['search'] = _res['search'] + ' ' + _res['Sender.Name'];
      }
    }

    const _index = this._tableDatabase.data.findIndex((_obj) => _obj.ID == _res.ID);
    this._tableDatabase.data[_index] = _res;
    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field['search'] = item.value;
    this._filterChange.next(item.value);
  }

  get filterStatus(): any {
    return this._filterStatusChange.value;
  }

  set filterStatus(item: any) {
    this.field.status = item.field;
    this._filterStatusChange.next(item.value);
  }

  get filterType(): any {
    return this._filterTypeChange.value;
  }

  set filterType(item: any) {
    this.field.type = item.field;
    this._filterTypeChange.next(item.value);
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._filterStatusChange,
      this._filterTypeChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    const result = observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
          if (this.field.type === '' && this.field.status === '' && this.field.searchField === '') {
            return true;
          } else {
            let type = '';
            let status = '';
            let searchField = '';
            type = (item[this.field.type] || '').toLowerCase();
            status = (item[this.field.status] || '').toLowerCase();
            searchField = (item['search'] || '').toLowerCase();

            const filterType = this.filterType.toLowerCase();
            const filterStatus = this.filterStatus.toLowerCase();
            const filter = this.filter.toLowerCase();
            const result =
              (type === '' || filterType === '' || type === filterType) &&
              (status === '' || filterStatus === '' || status === filterStatus) &&
              (searchField === '' || filter === '' || searchField.indexOf(filter) !== -1);
            return result;
          }
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
    return result;
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    const result = data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'Date':
          [propertyA, propertyB] = [
            moment(a['DateTimeCreated']).toDate().getTime(),
            moment(b['DateTimeCreated']).toDate().getTime(),
          ];
          break;
        case 'Recipient':
          [propertyA, propertyB] = [a['Card_Name'], b['Card_Name']];
          break;
        case 'Type':
          [propertyA, propertyB] = [a['Action'], b['Action']];
          break;
        case 'Description':
          [propertyA, propertyB] = [a['Description'], b['Description']];
          break;
        case 'Status':
          [propertyA, propertyB] = [a['Status.Label'], b['Status.Label']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
    return result;
  }
}
