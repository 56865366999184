<div class="rel" *ngIf=" listDB.firstLoadEvent==false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">
      Fetching data...
    </h1>
  </div>
</div>

<div [hidden]="listDB.data.length==0 || showDocList==true   || showProductList==true  || showContactList==true ">

  <button mat-raised-button color="accent" class="pull-right mr"
    [routerLink]="['/merchant/', {outlets: {'page': ['beneficiary-list-map']}}]" [routerLinkActive]="['active']">
    <mat-icon>public</mat-icon> {{"Supplier map" | translate }}
  </button>

  <mat-button-toggle-group class="btn-group mb text-center with-icons pull-right selectType" [(ngModel)]="typeFilter"
    (ngModelChange)="setFilter(typeFilter, 'Type_Code')">

    <mat-button-toggle [value]="null" class="">
      <mat-icon class="fas fa-parachute-box"></mat-icon> {{"All Suppliers" | translate }}
    </mat-button-toggle>

    <mat-button-toggle [value]="'MANU'" class="">
      <mat-icon class="fas fa-industry"></mat-icon> {{"Manufacturers" | translate }}
    </mat-button-toggle>

    <mat-button-toggle [value]="'DISTR'" class="">
      <mat-icon class="fas fa-truck"></mat-icon> {{"Distributors" | translate }}
    </mat-button-toggle>
  </mat-button-toggle-group>

  <div>

    <h1 class="dataHeader rm-m">
      {{"Suppliers Original" | translate}}
      <span class="badge" countUp [endVal]="dataSource.filteredData.length"></span>
    </h1>
    <mat-card class="list-card">

      <div class="row clearfix mb  searchArea primary-gradient-img">
        <div class="row clearfix flex">
          <!-- <div class="qtr-width flex" id="search">
            <mat-icon class="mt mr">search</mat-icon>
            <mat-select class="schSel full-width" matInput placeholder="Add Filters" [formControl]="searchVals" multiple>
              <mat-select-trigger>
                {{searchVals.value ? searchVals.value[0] : ''}}
                <span *ngIf="searchVals.value?.length > 1" class="additional-selection">
                  (+{{searchVals.value.length - 1}} others)
                </span>
              </mat-select-trigger>
              <mat-option *ngFor="let searchVal of searchValList" [value]="searchVal">{{searchVal}}</mat-option>
            </mat-select>
          </div> -->

          <div class="full-width flex animated fadeInDown">

            <!-- <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Label') !== -1 ">
              <input name="Labels" class="clearfix " placeholder="Label" matInput
                (input)="setFilter($event.target.value ,'Label')">
            </mat-form-field>
            <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Trading as') !== -1 ">
              <input name="TradingAs" class="clearfix " placeholder="Trading as" matInput
                (input)="setFilter($event.target.value ,'TradingAs')">
            </mat-form-field> -->
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Name</mat-label>
              <input name="BeneficiaryName" class="clearfix " placeholder="" matInput
                (input)="setFilter($event.target.value ,'CalculatedName')">
            </mat-form-field>
            <!-- <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Email') !== -1 ">
              <input name="BeneficiaryEmail" class="clearfix " placeholder="Email" matInput
                (input)="setFilter($event.target.value ,'emails.Email')">
            </mat-form-field>
            <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Mobile') !== -1 ">
              <input name="BeneficiaryMobile" class="clearfix " placeholder="Mobile" matInput
                (input)="setFilter($event.target.value ,'mobiles.Number')">
            </mat-form-field>
            <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Company Type') !== -1 ">
              <input name="CompanyType" class="clearfix " placeholder="Company Type" matInput
                (input)="setFilter($event.target.value ,'CompanyType')">
            </mat-form-field> -->
          </div>
        </div>
      </div>
      <!-- Table -->
      <mat-table #table [dataSource]="dataSource" matSort class="list-table supplies">
        <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->
        <!-- ID Column -->
        <ng-container cdkColumnDef="ProfileImage" sticky>
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>{{"Company Logo" | translate}} </mat-header-cell>
          <mat-cell class=" text-center" *cdkCellDef="let row" (click)="openCatelogueVIew(row['ID'])">



            <app-beneficiary-view-picture *ngIf="row" class="beneficiaryList text-center" [beneficiaryID]="row['ID']"
              [LastModified]="row['LastModified']">
            </app-beneficiary-view-picture>


          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Type">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> {{"Type" | translate}} </mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openCatelogueVIew(row['ID'])" matTooltip="{{row['Type_Label'] }}">
            <span *ngIf="row['Type_Code']=='BENE'">
              <mat-icon class="fas fa-parachute-box"></mat-icon>
            </span>
            <span *ngIf="row['Type_Code']=='MANU'">
              <mat-icon class="fas fa-industry"></mat-icon>
            </span>
            <span *ngIf="row['Type_Code']=='DISTR'">
              <mat-icon class="fas fa-truck"></mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="BeneficiaryLabel">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> {{"Label" | translate}} </mat-header-cell>
          <mat-cell *cdkCellDef="let row" matTooltip="{{row['Label'] }}" (click)="openCatelogueVIew(row['ID'])"> {{row['Label']
            }} </mat-cell>
        </ng-container>


        <ng-container cdkColumnDef="BeneficiaryName">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> {{"TradingAs" | translate}} </mat-header-cell>
          <mat-cell *cdkCellDef="let row" matTooltip="{{row['TradingAs'] }}" (click)="openCatelogueVIew(row['ID'])">
            {{row['TradingAs']}}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="BeneficiaryEmail">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> {{"Email" | translate}} </mat-header-cell>
          <mat-cell *cdkCellDef="let row" matTooltip="{{row['emails.Email'] }}" (click)="openCatelogueVIew(row['ID'])">
            {{row['emails.Email'] }} </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="CompanyType">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> {{"Company Type" | translate}} </mat-header-cell>
          <mat-cell *cdkCellDef="let row" matTooltip="{{row['CompanyType'] }}" (click)="openCatelogueVIew(row['ID'])">
            {{row['CompanyType'] }} </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="BeneficiaryHomePhone">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> {{"Home Phone" | translate}} </mat-header-cell>
          <mat-cell *cdkCellDef="let row" matTooltip="{{row['phones.Number'] | customPhone: 'landLine'}}"
            (click)="openCatelogueVIew(row['ID'])">
            {{row['phones.Number']
            | customPhone: 'landLine'}} </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="BeneficiaryWorkPhone">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> {{"Work Phone" | translate}} </mat-header-cell>
          <mat-cell *cdkCellDef="let row" matTooltip="{{row['Phone.Work.Number'] | customPhone}}"
            (click)="openCatelogueVIew(row['ID'])">
            {{row['Phone.Work.Number']
            | customPhone}} </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="BeneficiaryMobile">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> {{"Mobile" | translate}} </mat-header-cell>
          <mat-cell *cdkCellDef="let row" matTooltip="{{row['mobiles.Number'] | customPhone}}"
            (click)="openCatelogueVIew(row['ID'])"> {{row['mobiles.Number']
            | customPhone}} </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> {{"Actions" | translate}} </mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float : right;">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button *ngIf="row['ID']" mat-menu-item (click)="openQuickViewDialog(row['ID'])">
                  <mat-icon>description</mat-icon>
                  <span>{{"QuickView" | translate}}</span>
                </button>




                <button *ngIf="row['ID']" mat-menu-item (click)="viewDocuments(row['ID'],row['Label'] )">
                  <mat-icon>book</mat-icon>
                  <span>{{"View  Documents" | translate}}</span>
                </button>


                <button *ngIf="row['ID']" mat-menu-item (click)="viewProducts(row['ID'],row['Label'] )">
                  <mat-icon class="fas fa-box-open"></mat-icon>
                  <span>{{"View  Products" | translate}}</span>
                </button>


                <button *ngIf="row['ID']" mat-menu-item (click)="contact(row['ID'])">
                  <mat-icon>message</mat-icon>
                  <span>{{"Contact Supplier" | translate}}</span>
                </button>

                <button *ngIf="row['ID']" mat-menu-item (click)="viewContactsList(row['ID'], row['TradingAs'])">
                  <mat-icon>view_list</mat-icon>
                  <span>{{"View Contacts" | translate}}</span>
                </button>

              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>
    </mat-card>
  </div>

</div>
<div *ngIf="listDB.data.length == 0   && listDB.firstLoadEvent!=false">
  <app-empty-list-message></app-empty-list-message>
</div>


<!-- show document list as page -->

<div class="showDocumentList" *ngIf="showDocList == true">
  <div class="row clearfix">
    <app-treatment-document-gallery-container class="clearfix" [supplierID]="selectedSupplierID" [title]='docListTitle'>
    </app-treatment-document-gallery-container>
  </div>
  <div class="row clearfix text-center mt">
    <button mat-raised-button color="accent" (click)="  hideViewDetails() ">
      <mat-icon>chevron_left</mat-icon> Suppliers List
    </button>
  </div>
</div>



<div class="showDocumentList" *ngIf="showProductList == true && isPromoterOrAdmin!=true">
  <div class="row clearfix">
    <app-treatment-product-list class="clearfix" [supplierID]="selectedSupplierID" [title]='productListTitle'>
    </app-treatment-product-list>
  </div>
  <div class="row clearfix text-center mt">
    <button mat-raised-button color="accent" (click)="  hideViewDetails() ">
      <mat-icon>chevron_left</mat-icon> Suppliers List
    </button>
  </div>
</div>


<div class="showDocumentList" *ngIf="showProductList == true && isPromoterOrAdmin==true">
  <div class="row clearfix">
    <app-treatment-product-list-global class="clearfix" [supplierID]="selectedSupplierID" [title]='productListTitle'>
    </app-treatment-product-list-global>
  </div>
  <div class="row clearfix text-center mt">
    <button mat-raised-button color="accent" (click)="  hideViewDetails() ">
      <mat-icon>chevron_left</mat-icon> Suppliers List
    </button>
  </div>
</div>




<div class="showContactList" *ngIf="showContactList == true ">

  <div class="row clearfix">
    <app-contact-list [cardID]="selectedSupplierID" [type]="'supplier'" [selectSub]="true" [title]="contactListTitle">
    </app-contact-list>
  </div>
  <div class="row clearfix text-center mt">
    <button mat-raised-button color="accent" (click)="  hideViewDetails() ">
      <mat-icon>chevron_left</mat-icon> Suppliers List
    </button>
  </div>

</div>
