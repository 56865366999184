<div class="primary-gradient mainContainer consumerApplication">
  <aw-wizard class="hidden-steps" @ngIfEnter navBarLayout="small">
    <!-- step 1 -->
    <aw-wizard-step class="onEnter" navigationSymbol="1">
      <div class="container flexFix onEnter">
        <!-- intro text -->

        <div class="row clearfix" *ngIf="isInputProduct == true">
          <h1 class="text-center">
            Hi
            <span *ngIf="!applicantObject"> there!</span>
            <span *ngIf="applicantObject">, {{ applicantObject['firstname'] }}</span>
          </h1>
          <p class="lead text-center">To get started, let's confirm the value of
            your {{"KEYWORD.treatment" | translate}}</p>
          <p class="small mobShow rm-mt text-center">(To the nearest dollar)</p>
        </div>
        <div class="row clearfix" *ngIf="isInputProduct == true">
          <!--{{ "KEYWORD.treatment" | translate }} amount settings -->
          <div class="row clearfix mat-well">
            <div *ngIf="amount">
              <h3 class="text-center rm-mt anim">{{"KEYWORD.Treatment" | translate}}
                value: {{ amount | customCurrency }}</h3>
            </div>
            <!-- display when amount is set -->
            <div class="row clearfix" *ngIf="isShowAmountPicker == false && amount" @ngIfAnimation>
              <div class="anim">
                <div class="row clearfix">
                  <hr/>
                </div>
                <div class="row clearfix">
                  <button class="btn-small pull-right" (click)="showAmountPicker()" mat-raised-button color="warn">
                    <mat-icon class="fas fa-hand-holding-usd"></mat-icon> Change value
                  </button>
                  <p class="align-middle text-center">Need to change your {{ "KEYWORD.treatment" | translate }}
                    value?</p>
                </div>
              </div>
            </div>
            <!-- select amount -->
            <div class="row clearfix" *ngIf="isShowAmountPicker == true || !amount" @ngIfAnimation>
              <app-amount-slider-input
                class="consumer-slider anim block"
                [label]="('KEYWORD.treatment' | translate | titlecase) + ' Amount'"
                (selectAmount)="getAmount($event)"
              ></app-amount-slider-input>
            </div>
          </div>
        </div>

        <!-- if no product, set amount -->

        <div class="row clearfix" *ngIf="isInputProduct == false" @ngIfAnimation>
          <h1 class="text-center anim">Let's get started!</h1>
        </div>

        <div class="" *ngIf="isInputProduct == false" @ngIfAnimation>
          <div class="row clearfix">
            <p class="text-center lead">First, please enter the approximate value of
              your {{"KEYWORD.treatment" | translate}}.</p>
            <p class="small mobShow rm-mt text-center">(To the nearest dollar)</p>
            <div class="row clearfix mat-well">
              <app-amount-slider-input
                class="consumer-slider anim block"
                [label]="('KEYWORD.treatment' | translate | titlecase) + ' Amount'"
                (selectAmount)="getAmount($event)"
              ></app-amount-slider-input>
            </div>
          </div>
        </div>
        <!-- loan purpose select -->
        <div class="row clearfix">
          <p class="lead text-center">Then choose the kind of {{ "KEYWORD.treatment" | translate }} you're receiving</p>
          <div class="row clearfix">
            <mat-select
              class="mat-well block"
              [(ngModel)]="serviceType"
              name="serviceType"
              [placeholder]="('KEYWORD.treatment' | translate | titlecase) + ' type'"
              required
            >
              <mat-option *ngFor="let type of types" [value]="type.Label">
                {{ type.Label }}
              </mat-option>
            </mat-select>
          </div>
        </div>
        <!-- proceed -->
        <div class="row clearfix text-center add-mt mb">
          <button
            class="btn-proceed"
            [disabled]="amount == null || serviceType == null"
            (click)="setNextStep()"
            mat-raised-button
            color="accent"
            awNextStep
          >
            Next <mat-icon class="fas fa-hand-point-right"></mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <!-- step 2 -->
    <aw-wizard-step stepTitle="" navigationSymbol="2">
      <!-- counter specific to pagination animation -->

      <div class="container flexFix onPaginate">
        <div class="anim" *ngIf="isInputProduct == true">
          <div class="row clearfix" *ngIf="product">
            <h1 class="text-center">Your payment options</h1>
            <!-- display selected product name -->
            <p class="lead text-center">
              See if a
              <strong>
                <span *ngIf="selectedProduct == null">{{ product['MarketingLabel'] }}</span>
                <span *ngIf="selectedProduct">{{ selectedProduct['Label.Marketing'] }}</span>
              </strong>
              is right for you...
            </p>

            <!-- product information area -->
            <div class="row clearfix" *ngIf="!productID" @ngIfAnimation>
              <!-- css loader -->
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <!-- /css loader -->
            </div>
            <div class="row clearfix" *ngIf="productID" @ngIfAnimation>
              <app-product-view-modal class="block anim" [productID]="productID"></app-product-view-modal>
            </div>

            <!-- product list -->
            <div class="row clearfix" *ngIf="isShowProductPicker == true">
              <app-suitable-product-compact-list
                [amount]="amount"
                [frequency]="'1'"
                [isCold]="true"
                [displaySimulator]="false"
                (select)="getProduct($event)"
              ></app-suitable-product-compact-list>
            </div>
          </div>
          <!-- display button if product list not visible -->
          <div class="row clearfix" *ngIf="isShowProductPicker == false" @ngIfAnimation>
            <div class="anim mat-well text-center">
              <p class="small">Click to change your selected product.</p>
              <button class="btn-small" (click)="showProductPicker()" mat-raised-button color="warn">
                <mat-icon class="fas fa-hand-holding-heart"></mat-icon> Change product
              </button>
            </div>
          </div>
        </div>

        <!-- display if no product is preselected -->
        <div class="anim" *ngIf="isInputProduct == false">
          <!-- show header if no product selected -->
          <div class="row clearfix" *ngIf="isSelectedProduct != true" @ngIfAnimation>
            <h1 class="text-center anim">Please choose a product.</h1>
            <!-- product list -->
            <app-suitable-product-compact-list
              class="block anim"
              *ngIf="amount"
              [frequency]="'1'"
              [amount]="amount"
              [isCold]="true"
              [displaySimulator]="false"
              (select)="getProduct($event)"
            ></app-suitable-product-compact-list>
          </div>

          <!-- display which product is selected -->
          <div class="row clearfix" *ngIf="isSelectedProduct == true" @ngIfAnimation>
            <p class="lead text-center anim rm-mb">
              You've selected a <strong>{{ selectedProduct['Label.Marketing'] }}</strong>
            </p>
            <p class="text-center">
              Starting from
              <strong
              >{{ selectedProduct['BasedOnMaxDuration.Repayments.Weekly'] / 7 | customCurrency }} per day</strong
              >
            </p>
          </div>

          <!-- the product information -->
          <div class="row clearfix" *ngIf="isSelectedProduct == true" @ngIfAnimation>
            <app-product-view-modal class="block anim" *ngIf="productID" [productID]="productID">
            </app-product-view-modal>
          </div>

          <!-- display button to select another product when one is chosen -->
          <div class="row clearfix" *ngIf="isSelectedProduct == true" @ngIfAnimation>
            <div class="anim mat-well text-center">
              <p class="small">Click to change your selected product.</p>
              <button class="btn-small" (click)="chooseAnotherProduct()" mat-raised-button color="warn">
                <mat-icon class="fas fa-hand-holding-heart"></mat-icon> Change product
              </button>
            </div>
          </div>
        </div>

        <div class="row clearfix mb add-mt">
          <button
            class="pull-left btn-back"
            [disabled]=""
            (click)="setPreviousStep()"
            mat-raised-button
            color="primary"
            awPreviousStep
          >
            <mat-icon class="fas fa-hand-point-left"></mat-icon> Back
          </button>
          <button
            class="pull-right btn-proceed"
            *ngIf="isInputProduct == true"
            [disabled]=""
            (click)="setNextStep()"
            mat-raised-button
            color="accent"
            awNextStep
          >
            Choose product <mat-icon class="fas fa-hand-point-right"></mat-icon>
          </button>
          <button
            class="pull-right btn-proceed"
            *ngIf="isInputProduct == false"
            [disabled]="isSelectedProduct != true"
            (click)="setNextStep()"
            mat-raised-button
            color="accent"
            awNextStep
          >
            Choose product <mat-icon class="fas fa-hand-point-right"></mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <!-- step 3 -->
    <aw-wizard-step stepTitle="" navigationSymbol="3">
      <!-- counter specific to pagination animation -->

      <div class="container flexFix onPaginate">
        <div class="row clearfix">
          <h1 class="text-center">How long do you think you'll need to repay?</h1>
        </div>
        <div class="row clearfix">
          <!-- display calculators -->

          <app-product-calculator
            class="noHeading block anim"
            *ngIf="isSelectedProduct != true && product"
            @ngIfAnimation
            [moreInfo]="false"
            [displayDeposit]="true"
            [ProductID]="product['ID']"
            [dynamicAmount]="false"
            [fixedAmount]="amount"
            [isFrequency]="true"
            [dentalInfo]="true"
            [isTop]="false"
            [isPatientContribution]="false"
            (estimation)="getEstimation($event)"
          ></app-product-calculator>

          <app-product-calculator
            class="noHeading block anim"
            *ngIf="isSelectedProduct == true && selectedProduct"
            @ngIfAnimation
            [moreInfo]="false"
            [displayDeposit]="true"
            [ProductID]="selectedProduct['ID']"
            [dynamicAmount]="false"
            [fixedAmount]="amount"
            [isFrequency]="true"
            [dentalInfo]="true"
            [isTop]="false"
            [isPatientContribution]="false"
            (estimation)="getEstimation($event)"
          >
          </app-product-calculator>
        </div>

        <div class="row clearfix mb mt">
          <button
            class="pull-left btn-back"
            [disabled]=""
            (click)="setPreviousStep()"
            mat-raised-button
            color="primary"
            awPreviousStep
          >
            <mat-icon class="fas fa-hand-point-left"></mat-icon> Back
          </button>
          <button
            class="pull-right btn-proceed"
            [disabled]=""
            (click)="setNextStep()"
            mat-raised-button
            color="accent"
            awNextStep
          >
            Next <mat-icon class="fas fa-hand-point-right"></mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <!-- step 4 -->
    <aw-wizard-step stepTitle="" navigationSymbol="4">
      <!-- counter specific to pagination animation -->

      <div class="container flexFix onPaginate">
        <div class="row clearfix">
          <h1 class="text-center">Review your product and repayment details</h1>
        </div>
        <!-- review fields -->
        <div class="row clearfix text-center add-mb desktop-well" *ngIf="estimateObject" @ngIfAnimation>
          <p class="lead anim">
            {{ "KEYWORD.treatment" | translate | titlecase }} Amount: <strong>{{ amount | customCurrency }}</strong>
          </p>
          <p class="anim">
            Product Name:
            <strong>
              <span *ngIf="selectedProduct == null">{{ product['MarketingLabel'] }}</span>
              <span *ngIf="selectedProduct">{{ selectedProduct['Label.Marketing'] }}</span>
            </strong>
          </p>
          <p class="anim" *ngIf="estimateObject['initialPayment']">
            Initial repayment <strong>{{ estimateObject['initialPayment'] | customCurrency }}</strong>
          </p>
          <p class="anim">
            Regular repayment amount:
            <strong>{{ estimateObject['regularPayment'] | customCurrency }} per {{ timeLabel }}</strong>
          </p>
          <p class="anim">
            Final repayment: <strong>{{ estimateObject['finalPayment'] | customCurrency }}</strong>
          </p>
          <p class="anim">
            Repayment duration: <strong>{{ estimateObject['duration'] }} {{ frequency }}</strong>
          </p>
          <p class="anim">
            Loan purpose: <strong>{{ serviceType }}</strong>
          </p>
        </div>
        <div class="row clearfix mb add-mt">
          <button
            class="pull-left btn-back"
            [disabled]=""
            (click)="setPreviousStep()"
            mat-raised-button
            color="primary"
            awPreviousStep
          >
            <mat-icon class="fas fa-hand-point-left"></mat-icon> Back
          </button>
          <button
            class="pull-right btn-proceed"
            [disabled]=""
            (click)="setNextStep()"
            mat-raised-button
            color="accent"
            awNextStep
          >
            Confirm <mat-icon class="fas fa-hand-point-right"></mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <!-- step 5 -->
    <aw-wizard-step stepTitle="" navigationSymbol="5">
      <div class="container flexFix onPaginate">
        <div class="row clearfix">
          <h1 class="text-center">Now lets confirm your personal details</h1>
        </div>

        <div class="inputs mat-well row clearfix">
          <form #nameForm="ngForm">
            <!-- gender -->
            <div class="full-width input-container">
              <mat-select
                class="full-width"
                [(ngModel)]="user.gender"
                name="Gender"
                placeholder="Gender"
                required
              >
                <mat-option *ngFor="let gender of genders" [value]="gender.Code">
                  {{ gender.Label }}
                </mat-option>
              </mat-select>
            </div>

            <!-- the title -->
            <div class="full-width input-container">
              <mat-select
                class="full-width"
                [(ngModel)]="user.title"
                name="Title"
                placeholder="Title"
                required
              >
                <mat-option *ngFor="let title of titles" [value]="title.Label">
                  {{ title.Label }}
                </mat-option>
              </mat-select>
            </div>
            <!-- first name -->
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>First Name</mat-label>
              <input
                class="mat-block"
                [(ngModel)]="user.firstname"
                matInput
                placeholder="{{ 'PLACEHOLDER.First Name' | translate }} "
                name="firstname"
                required
              />
            </mat-form-field>
            <!-- middle name optional -->
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Middle Name</mat-label>
              <input
                class="mat-block"
                [(ngModel)]="user.middlename"
                matInput
                placeholder="{{ 'PLACEHOLDER.Middle Name' | translate }} "
                name="middlename"
              />
            </mat-form-field>
            <!-- surname -->
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Surname</mat-label>
              <input
                class="mat-block"
                [(ngModel)]="user.lastname"
                matInput
                placeholder="{{ 'PLACEHOLDER.Surname' | translate }} "
                name="lastname"
                required
              />
            </mat-form-field>
            <!-- contact email -->
            <app-email-input
              class="full-width"
              [label]="'Email'"
              [required]="true"
              (getEmail)="getEmail($event)"
              (validate)="validateEmail($event)"
            ></app-email-input>
          </form>
        </div>

        <div class="row clearfix mb mt">
          <button
            class="pull-left btn-back"
            [disabled]=""
            (click)="setPreviousStep()"
            mat-raised-button
            color="primary"
            awPreviousStep
          >
            <mat-icon class="fas fa-hand-point-left"></mat-icon> Back
          </button>
          <button
            class="pull-right btn-proceed"
            [disabled]="!nameForm.form.valid || !isEmailValid"
            (click)="setNextStep()"
            mat-raised-button
            color="accent"
            awNextStep
          >
            Next <mat-icon class="fas fa-hand-point-right"></mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <!-- step 6 -->
    <aw-wizard-step stepTitle="" navigationSymbol="6">
      <div class="container flexFix onPaginate">
        <div class="row clearfix">
          <h1 class="text-center">Please enter your mobile number</h1>
        </div>

        <div class="mat-well row clearfix">
          <p class="text-center">We'll send you a SMS to verify this is you.</p>
          <app-phone-input
            class="mat-block"
            [phoneNumber]="modifiedMobile"
            [isLandLine]="false"
            [label]="'Mobile'"
            [required]="true"
            (validate)="validateMobileEvent($event)"
            (getPhone)="getMobile($event)"
          >
          </app-phone-input>
        </div>

        <div class="row clearfix mb mt">
          <button
            class="pull-left btn-back"
            [disabled]=""
            (click)="setPreviousStep()"
            mat-raised-button
            color="primary"
            awPreviousStep
          >
            <mat-icon class="fas fa-hand-point-left"></mat-icon> Back
          </button>
          <button
            class="pull-right btn-proceed"
            [disabled]="!isMobileValid"
            (click)="openTwoFactorModal()"
            mat-raised-button
            color="accent"
          >
            Next <mat-icon class="fas fa-hand-point-right"></mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <!-- step 7 -->
    <!-- <aw-wizard-step stepTitle="" navigationSymbol="7">
      <div class="container">
        <h3 class="sr-title">Please enter your email address</h3>
      </div>
      <div class="container text-center">
      </div>
      <div class="bottom-buttons">
        <button mat-raised-button color="primary" class="pull-left btn-back" [disabled]="" awPreviousStep (click)="setPreviousStep()">
          <mat-icon class="fas fa-hand-point-left"></mat-icon> Back
        </button>
        <button mat-raised-button color="accent" class="pull-right btn-proceed" [disabled]="!isEmailValid" awNextStep>
          Next <mat-icon class="fas fa-hand-point-right"></mat-icon>
        </button>
      </div>
    </aw-wizard-step> -->

    <!-- step 8 -->
    <aw-wizard-step stepTitle="" navigationSymbol="7">
      <div class="container flexFix onPaginate">
        <h1 class="text-center">Let's review your information</h1>

        <div class="row clearfix text-center desktop-well" *ngIf="estimateObject">
          <h2 class="">Product details</h2>
          <p class="lead">
            {{ "KEYWORD.treatment" | translate | titlecase }} Amount: <strong>{{ amount | customCurrency }}</strong>
          </p>
          <p class="">
            Product Name:
            <strong>
              <span *ngIf="selectedProduct == null">{{ product['MarketingLabel'] }}</span>
              <span *ngIf="selectedProduct">{{ selectedProduct['Label.Marketing'] }}</span>
            </strong>
          </p>
          <p class="" *ngIf="estimateObject['initialPayment']">
            Initial repayment <strong>{{ estimateObject['initialPayment'] | customCurrency }}</strong>
          </p>
          <p class="">
            Regular repayment amount:
            <strong>{{ estimateObject['regularPayment'] | customCurrency }} per {{ timeLabel }}</strong>
          </p>
          <p class="">
            Final repayment: <strong>{{ estimateObject['finalPayment'] | customCurrency }}</strong>
          </p>
          <p class="">
            Repayment duration: <strong>{{ estimateObject['duration'] }} {{ frequency }}</strong>
          </p>
        </div>
        <div class="row clearfix text-center desktop-well">
          <h2 class="">Personal details</h2>
          <p class="lead">
            Applicant name: <strong>{{ user.firstname }} {{ user.middlename }} {{ user.lastname }}</strong>
          </p>
          <p>
            Applicant mobile: <strong>{{ user.mobile }}</strong>
          </p>
          <p>
            Applicant email: <strong>{{ user.email }}</strong>
          </p>
          <p>
            Applicant loan purpose: <strong>{{ serviceType }}</strong>
          </p>
        </div>
        <div class="row clearfix mb mt">
          <button
            class="pull-left btn-back"
            [disabled]=""
            (click)="setPreviousStep()"
            mat-raised-button
            color="primary"
            awPreviousStep
          >
            <mat-icon class="fas fa-hand-point-left"></mat-icon> Back
          </button>
          <button class="pull-right btn-proceed" [disabled]="" mat-raised-button color="accent" awNextStep>
            Confirm <mat-icon class="fas fa-hand-point-right"></mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <!-- Hooray, you're done buddy -->
    <aw-wizard-completion-step title="" navigationSymbol="8">
      <div class="container flexFix onPaginate">
        <h1 class="text-center bounceIn">Thanks {{ user.firstname }}!</h1>

        <div class="row clearfix">
          <p class="lead text-center">
            Now we've confirmed your identification, let's move on to finalize your application.
          </p>
        </div>
        <!-- Proceed with next step in application process -->
        <div class="row clearfix text-center">
          <button class="mt mb btn-proceed animate" (click)="proceed()" mat-raised-button color="accent">
            Let's go
            <mat-icon class="fas fa-thumbs-up"></mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-completion-step>
  </aw-wizard>
</div>
