<div class="full-width" [hidden]="!template">
  <aw-wizard #wizard navBarLayout="large-filled-symbols">
    <aw-wizard-step [navigationSymbol]="{ symbol: 1 }" stepTitle="Social Media">
      <div class="create-container" @ngIfAnimation>
        <!-- <mat-card class="airplaneCard animate clearfix"> -->
        <h1 class="sr-title sm-h1 text-center">Social Media Posts</h1>
        <!-- <div class="full-width clearfix  mb"> -->

        <div class="row flex clearfix block-area">
          <mat-card class="template-preview file animate mat-elevation-z4 mr full-width">
            <h3 class="sr-title reduce-marg">Facebook post</h3>
            <hr class="mb"/>
            <div class="full-width clearfix" #container>
              <mat-form-field class="full-width animate" appearance="outline">
                <mat-label>
                  <mat-icon class="fab fa-facebook"></mat-icon>
                  Facebook Post Text
                </mat-label>
                <textarea
                  class="mat-block"
                  #templatefacebookPost
                  [(ngModel)]="template.facebookPost"
                  [maxLength]="63206 - (postURLLength || 0)"
                  matInput
                  placeholder="Facebook Post Text"
                  name="templatefacebookPost"
                ></textarea>
                <mat-hint align="end"
                >Post length: {{ templatefacebookPost.value?.length || 0 }}/
                  {{ 63206 - (postURLLength || 0) }}
                </mat-hint>
              </mat-form-field>

              <button
                class="pull-left"
                [cbContent]="template.facebookPost"
                [container]="container"
                ngxClipboard
                mat-stroked-button
                color="primary"
                matTooltip="copy text"
              >
                Copy <mat-icon>file_copy</mat-icon>
              </button>
            </div>

            <div class="clearfix full-width">
              <app-file-uploader
                class="text-center clearfix full-width"
                [isFixedAspectRatio]="false"
                [isImageCropping]="true"
                [canSkip]="false"
                [limit]="1"
                [uploadDirectly]="true"
                [isDragable]="true"
                [title]="'CLICK HERE to upload Template Facebook'"
                (onCompleteAll)="onCompleteFacebook($event)"
              >
              </app-file-uploader>

              <app-blob-view
                class="row clearfix flex flexCol full-width full-height"
                *ngIf="facebookImage && facebookImageLoading == false"
                [label]="'Campaign-Facebook'"
                [loading]="facebookImageLoading"
                [isDownload]="false"
                [isProceed]="false"
                [isPrint]="false"
                [canProceed]="true"
                [content]="facebookImage"
                [extension]="'png'"
                [label]=""
              ></app-blob-view>
              <div
                class="view-container full-height"
                *ngIf="(!facebookImage || facebookImage == null) && facebookImageLoading == false"
              ></div>
            </div>
          </mat-card>

          <mat-card class="template-preview file animate mat-elevation-z4 ml full-width">
            <h3 class="sr-title reduce-marg">Instagram post</h3>
            <hr class="mb"/>
            <div class="full-width clearfix" #container>
              <mat-form-field class="full-width animate" appearance="outline">
                <mat-label>
                  <mat-icon class="fab fa-instagram"></mat-icon>
                  Instagram Post Text
                </mat-label>
                <textarea
                  class="mat-block"
                  #templateinstagramPost
                  [(ngModel)]="template.instagramPost"
                  [maxLength]="2200 - (postURLLength || 0)"
                  matInput
                  placeholder="Instagram Post Text"
                  name="templateinstagramPost"
                ></textarea>
                <mat-hint align="end"
                >Post length: {{ templateinstagramPost.value?.length || 0 }}/
                  {{ 2200 - (postURLLength || 0) }}</mat-hint
                >
              </mat-form-field>

              <button
                class="pull-left"
                [cbContent]="template.instagramPost"
                [container]="container"
                ngxClipboard
                mat-stroked-button
                color="primary"
                matTooltip="copy text"
              >
                Copy <mat-icon>file_copy</mat-icon>
              </button>
            </div>

            <div class="clearfix full-width">
              <app-file-uploader
                class="text-center clearfix full-width"
                [isFixedAspectRatio]="false"
                [isImageCropping]="true"
                [canSkip]="false"
                [limit]="1"
                [uploadDirectly]="true"
                [isDragable]="true"
                [title]="'CLICK HERE to upload Template Instagram'"
                (onCompleteAll)="onCompleteInstagram($event)"
              >
              </app-file-uploader>
              <app-blob-view
                class="row clearfix flex flexCol full-width full-height"
                *ngIf="instagramImage && instagramImageLoading == false"
                [label]="'Campaign-Instagram'"
                [loading]="instagramImageLoading"
                [isDownload]="false"
                [isProceed]="false"
                [isPrint]="false"
                [canProceed]="true"
                [content]="instagramImage"
                [extension]="'png'"
                [label]=""
              ></app-blob-view>
              <div
                class="view-container full-height"
                *ngIf="(!instagramImage || instagramImage == null) && instagramImageLoading == false"
              ></div>
            </div>
          </mat-card>
          <!-- </div> -->

          <!-- <div class="full-width flex clearfix "> -->
          <mat-card class="template-preview file animate mat-elevation-z4 mr full-width">
            <h3 class="sr-title reduce-marg">LinkedIn post</h3>
            <hr class="mb"/>
            <div class="full-width clearfix" #container>
              <mat-form-field class="full-width animate" appearance="outline">
                <mat-label>
                  <mat-icon class="fab fa-linkedin"></mat-icon>
                  LinkedIn Post Text
                </mat-label>
                <textarea
                  class="mat-block"
                  #templatelinkedinPost
                  [(ngModel)]="template.linkedInPost"
                  [maxLength]="700 - (postURLLength || 0)"
                  matInput
                  placeholder="LinkedIn Post Text"
                  name="templatelinkedInPost"
                ></textarea>
                <mat-hint align="end"
                >Post length: {{ templatelinkedinPost.value?.length || 0 }}/
                  {{ 700 - (postURLLength || 0) }}</mat-hint
                >
              </mat-form-field>

              <button
                class="pull-left"
                [cbContent]="template.linkedInPost"
                [container]="container"
                ngxClipboard
                mat-stroked-button
                color="primary"
                matTooltip="copy text"
              >
                Copy <mat-icon>file_copy</mat-icon>
              </button>
            </div>

            <div class="clearfix full-width">
              <app-file-uploader
                class="text-center clearfix full-width"
                [isFixedAspectRatio]="false"
                [isImageCropping]="true"
                [canSkip]="false"
                [limit]="1"
                [uploadDirectly]="true"
                [isDragable]="true"
                [title]="'CLICK HERE to upload Template LinkedIn'"
                (onCompleteAll)="onCompleteLinkedin($event)"
              >
              </app-file-uploader>

              <app-blob-view
                class="row clearfix flex flexCol full-width full-height"
                *ngIf="linkedInImage && linkedInImageLoading == false"
                [label]="'Campaign-LinkedIn'"
                [isDownload]="false"
                [loading]="linkedInImageLoading"
                [isProceed]="false"
                [isPrint]="false"
                [canProceed]="true"
                [content]="linkedInImage"
                [extension]="'png'"
                [label]=""
              ></app-blob-view>
              <div
                class="view-container full-height"
                *ngIf="(!linkedInImage || linkedInImage == null) && linkedInImageLoading == false"
              ></div>
            </div>
          </mat-card>

          <mat-card class="template-preview file animate mat-elevation-z4 ml full-width">
            <h3 class="sr-title reduce-marg">Twitter post</h3>
            <hr class="mb"/>
            <div class="full-width clearfix" #container>
              <mat-form-field class="full-width animate" appearance="outline">
                <mat-label>
                  <mat-icon class="fab fa-twitter"></mat-icon>
                  Twitter Post Text
                </mat-label>
                <textarea
                  class="mat-block"
                  #templatetwitterPost
                  [(ngModel)]="template.twitterPost"
                  [maxLength]="140 - (postURLLength || 0)"
                  matInput
                  placeholder="Twitter Post Text"
                  name="templatetwitterPost"
                ></textarea>
                <mat-hint align="end"
                >Post length: {{ templatetwitterPost.value?.length || 0 }}/ {{ 140 - (postURLLength || 0) }}</mat-hint
                >
              </mat-form-field>

              <button
                class="pull-left"
                [cbContent]="template.twitterPost"
                [container]="container"
                ngxClipboard
                mat-stroked-button
                color="primary"
                matTooltip="copy text"
              >
                Copy <mat-icon>file_copy</mat-icon>
              </button>
            </div>

            <div class="clearfix full-width">
              <app-file-uploader
                class="text-center clearfix full-width"
                [isFixedAspectRatio]="false"
                [isImageCropping]="true"
                [canSkip]="false"
                [limit]="1"
                [uploadDirectly]="true"
                [isDragable]="true"
                [title]="'CLICK HERE to upload Template Twitter'"
                (onCompleteAll)="onCompleteTwitter($event)"
              >
              </app-file-uploader>

              <app-blob-view
                class="row clearfix flex flexCol full-width full-height"
                *ngIf="twitterImage && twitterImageLoading == false"
                [label]="'Campaign-Twitter'"
                [isDownload]="false"
                [loading]="twitterImageLoading"
                [isProceed]="false"
                [isPrint]="false"
                [canProceed]="true"
                [content]="twitterImage"
                [extension]="'png'"
                [label]=""
              ></app-blob-view>
              <div
                class="view-container full-height"
                *ngIf="(!twitterImage || twitterImage == null) && twitterImageLoading == false"
              ></div>
            </div>
          </mat-card>
        </div>

        <div class="row clearfix step2 clearfix">
          <div class="full-width step-button-row text-center">
            <button
              class="step-back"
              [routerLink]="['/merchant/', { outlets: { page: ['marketing-social-campaign-preview'] } }]"
              mat-mini-fab
              color="primary"
            >
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>

            <button
              class="btn-large"
              (click)="finish()"
              mat-raised-button
              color="accent"
            >
              Finish
              <mat-icon> chevron_right</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step [navigationSymbol]="{ symbol: 2 }" stepTitle="Done!">
      <div class="container" id="step-3">
        <div class="row clearfix">
          <mat-card class="card successful">
            <div class="row clearfix">
              <div class="full-width text-center">
                <h1 class="sm-h1 sr-title">Well Done!</h1>
                <div class="row clearfix">
                  <div class="svg-box">
                    <svg class="circular green-stroke">
                      <circle class="path" cx="75" cy="75" r="50" fill="none" stroke-width="5" stroke-miterlimit="10" />
                    </svg>
                    <svg class="checkmark green-stroke">
                      <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                        <path
                          class="checkmark__check"
                          fill="none"
                          d="M616.306,283.025L634.087,300.805L673.361,261.53"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
        <div class="row clearfix">
          <div class="full-width text-center step-button-row" *ngIf="templateRawID">
            <button
              class="full-back ml mr"
              [routerLink]="['/merchant/', { outlets: { page: ['marketing-campaign-preview'] } }]"
              mat-raised-button
              color="primary"
            >
              {{ 'Campaign List ' | translate }}
            </button>

            <button
              class="full-back ml mr"
              [routerLink]="['/merchant/', { outlets: { page: ['marketing-social-campaign-preview'] } }]"
              mat-raised-button
              color="primary"
            >
              {{ 'Social media Campaign List ' | translate }}
            </button>
          </div>
        </div>
      </div>
    </aw-wizard-step>
  </aw-wizard>
</div>
