import { Component, EventEmitter, Input, OnInit, SimpleChanges } from '@angular/core';

import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { CustomerViewComponent } from '../../customer/customer-view/customer-view.component';
import { CustomerService } from '../../customer/shared/customer.service';
import { ProspectViewComponent } from '../prospect-view/prospect-view.component';
import { ExistingPatientService } from '../shared/existing-patient.service';

@Component({
  selector: 'app-existing-patient-list-map',
  templateUrl: './existing-patient-list-map.component.html',
  styleUrls: ['./existing-patient-list-map.component.css'],
})
export class ExistingPatientListMapComponent implements OnInit {
  @Input() merchantID;

  @Input() dateFrom;
  @Input() dateTo;

  util = new UtilsClass();

  addMarkerEvent = new EventEmitter();

  resetPositionsEvent = new EventEmitter();

  isLoaded = false;
  existingPatients = [];
  serviceRef;
  description =
    'Click on marker to view patient details , Financed customers are on green circles , and prospect on orange circles';
  title = 'Patients';

  isAdminOrPromoter = false;
  dataArray;

  customerIcon = 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/green-dot.png';
  prospectIcon = 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/orange-dot.png';

  displayMap = false;

  constructor(
    private existingPatientService: ExistingPatientService,
    private authenticationService: AuthenticationService,
    private customerService: CustomerService
  ) {}

  ngOnInit() {
    this.setup();
  }

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }

  setup() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }

    this.resetPositionsEvent.emit(true);

    this.displayMap = false;
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isAdminOrPromoter = res;

      const payload = {
        section: 0,
        limit: 300,
        merchantID: this.merchantID,
        dateTo: this.util.EPdateFormat(this.dateTo),
        dateFrom: this.util.EPdateFormat(this.dateFrom),
        hasAdress: true,
        fields:
          'ID,TableName,addresses.Calculated,addresses.Suburb,addresses.State,addresses.Postcode,addresses.Country.Code,' +
          'addresses.Unit,addresses.Street Nr,addresses.Street Name,addresses.Street Type,Address.Latitude,Address.Longitude,' +
          'CalculatedName',
      };

      this.serviceRef = this.existingPatientService
        .customerOrProspectList(payload, this.isAdminOrPromoter)
        .subscribe((res) => {
          this.displayMap = true;
          if (res && res.length > 0) {
            this.isLoaded = true;
            this.existingPatients = res;
            for (let i = 0; i < this.existingPatients.length; i++) {
              if (this.existingPatients[i]) {
                this.existingPatients[i].icon = this.getIcon(this.existingPatients[i]);
                this.addMarkerEvent.emit(this.existingPatients[i]);
              }
            }

            this.dataArray = this.existingPatients;

            const innerFunction = (section) => {
              section = section + 1;

              UtilsClass.loadingDataSection(section);
              payload.section = section;

              this.serviceRef = this.existingPatientService
                .customerOrProspectList(payload, this.isAdminOrPromoter)
                .subscribe((res) => {
                  if (res.length > 0) {
                    this.existingPatients = this.existingPatients.concat(res);

                    for (let i = res.length; i < this.existingPatients.length; i++) {
                      const o = this.existingPatients[i];

                      o.icon = this.getIcon(o);

                      this.addMarkerEvent.emit(o);
                    }

                    innerFunction(section);
                  } else {
                    return true;
                  }
                });
              UtilsClass.stopLoadingInterceptor();
            };

            innerFunction(1);
          } else {
            this.isLoaded = true;
          }
        });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.merchantID && changes.merchantID.previousValue != changes.merchantID.currentValue) ||
      (changes.dateTo && changes.dateTo.previousValue != changes.dateTo.currentValue) ||
      (changes.dateFrom && changes.dateFrom.previousValue != changes.dateFrom.currentValue)
    ) {
      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;
        this.setup();
      });
    }
  }

  getIcon(item) {
    if (item && item['TableName']) {
      if (item['TableName'] == 'Prospect') {
        return this.prospectIcon;
      } else if (item['TableName'] == 'Customer') {
        return this.customerIcon;
      }
    }
  }

  addCoordinateEvent(item) {
    if (item && item['ID'] && item['latitude'] && item['longitude'] && item['TableName']) {
      const payload = {
        latitude: item['latitude'],
        longitude: item['longitude'],
      };
      if (item['TableName'] == 'Prospect') {
        this.existingPatientService.addCoordinate(item['ID'], payload).subscribe((res) => {});
      } else if (item['TableName'] == 'Customer') {
        this.customerService.addCoordinate(item['ID'], payload).subscribe((res) => {});
      }
    }
  }

  getSelected(item) {
    if (item && item['ID'] && item['TableName']) {
      if (item['TableName'] == 'Prospect') {
        const ref = RootAppComponent.dialog.open(ProspectViewComponent, {
          data: item['ID'],
          width: '750px',
          panelClass: 'noCard',
        });
      } else if (item['TableName'] == 'Customer') {
        const ref = RootAppComponent.dialog.open(CustomerViewComponent, {
          data: item['ID'],
          width: '750px',
          panelClass: 'noCard',
        });
      }
    }
  }
}
