import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../services/utils.service';
import { NotifyAppComponent } from '../../types/notify-app-component';
import { Settings } from '../../types/settings';
import { UtilsClass } from '../../types/utils/utils.class';
import * as _ from 'lodash';
@Component({
  selector: 'app-ep-document-create-edit',
  templateUrl: './ep-document-create-edit.component.html',
  styleUrls: ['./ep-document-create-edit.component.css'],
})
export class EPDocumentCreateEditComponent implements OnInit {
  @Input()
  documentID;

  @Input()
  merchantID;

  @Input()
  cardID;

  @Input()
  contractID;

  @Input()
  invitationID;

  @Input()
  drawDownID;

  @Input()
  paymentID;

  @Input()
  title = 'Create Document';

  @Input()
  description;

  @Input()
  waiverID;

  @Input()
  personalCircumstanceID;

  @Input()
  miniEdit = false;
  @Input()
  displayMerchantList = true;

  @Input()
  hideDocumentType = false;
  @Input()
  hideThumbnail = false;
  sesstionType;

  @Output()
  closeModal = new EventEmitter();

  @Output()
  result = new EventEmitter();

  isModal = false;
  isPromoterOrAdmin = false;

  file;
  loadingFile = false;
  fileSrc;
  isFile = false;
  merchants = [];

  isEdit = false;
  thumbnailLink;
  thumbnailSrc;
  isThumbnailFile = false;

  programAreaCode;
  programAreaCodes;

  hideCheckBoxes = false;
  document = {
    ID: null,
    label: null,
    type: null,
    description: null,
    isGallery: null,
    isPrivate: null,
    thumbnailFileID: null,
    fileID: null,
  };
  @Input()
  types = [
    {
      label: 'Treatment Plan',
      code: 'TRP',
    },
    {
      label: 'Procedure Images',
      code: 'SRVSTC',
    },
    {
      label: 'Procedure Video',
      code: 'IGVIDEO',
    },
  ];

  fileType = 'all';
  fileLink;
  utils = new UtilsClass();
  type;

  isModulePrivateDocumentActive = Settings.global['isModulePrivateDocumentActive'];

  imageExtensions = [];
  videoExtensions = [];
  imageDocumentExtensions = [];
  documentExtensions = [];

  imageDocumentTypes = ['TRP'];
  imageTypes = ['EDTRBKG', 'EDTRCHR', 'EDTRIMG', 'MOD_IMG', 'SMIMG', 'PRMN_I', 'SUPN_I', 'MERN_I', 'SRVSTC'];

  documentypes = [];

  videoTypes = ['VIDEO', 'IGVIDEO', 'EDTRDOC', 'FNDN_V', 'PRMN_V', 'SUPN_V', 'MERN_V', 'MEDR_V'];

  constructor(
    private authenticationService: AuthenticationService,
    private utilService: UtilsService,
    private activeRoute: ActivatedRoute,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.documentID) {
        this.documentID = data.documentID;

        this.isModal = true;
      }

      if (data.merchantID) {
        this.merchantID = data.merchantID;
        this.isModal = true;
      }
      if (data.cardID) {
        this.cardID = data.cardID;
        this.isModal = true;
      }
      if (data.contractID) {
        this.contractID = data.contractID;
        this.isModal = true;
      }
      if (data.invitationID) {
        this.invitationID = data.invitationID;
        this.isModal = true;
      }
      if (data.drawDownID) {
        this.drawDownID = data.drawDownID;
        this.isModal = true;
      }
      if (data.paymentID) {
        this.paymentID = data.paymentID;
        this.isModal = true;
      }

      if (data.type) {
        this.document.type = data.type;

        this.type = data.type;
        this.isModal = true;
      }
      if (data.label) {
        this.document.label = data.label;

        this.isModal = true;
      }
      if (data.types) {
        this.types = this.buildTypes(data.types);

        this.isModal = true;
      }

      if (data.programAreaCode) {
        this.programAreaCode = data.programAreaCode;

        this.isModal = true;
      }

      if (data.programAreaCodes) {
        this.programAreaCodes = data.programAreaCodes;

        this.isModal = true;
      }

      if (data.types && data.types.length > 0) {
        this.types = this.buildTypes(data.types);

        this.isModal = true;
      }

      if (data.merchantID) {
        this.merchantID = data.merchantID;

        this.isModal = true;
      }

      if (data.miniEdit != null) {
        this.miniEdit = data.miniEdit;

        this.isModal = true;
      }

      if (data.displayMerchantList != null) {
        this.displayMerchantList = data.displayMerchantList;

        this.isModal = true;
      }

      if (data.hideThumbnail != null) {
        this.hideThumbnail = data.hideThumbnail;

        this.isModal = true;
      }

      if (data.isGallery != null) {
        this.document.isGallery = data.isGallery;

        this.isModal = true;
      }

      if (data.hideCheckBoxes != null) {
        this.hideCheckBoxes = data.hideCheckBoxes;

        this.isModal = true;
      }

      if (data.hideDocumentType == true && this.document.type) {
        this.hideDocumentType = true;

        this.isModal = true;
      }
      else {
        this.hideDocumentType = false;
      }
    }
  }

  ngOnInit() {
    this.imageExtensions = this.utils.getDocumentExtensionsFromType('image');
    this.videoExtensions = this.utils.getDocumentExtensionsFromType('video');
    this.documentExtensions = this.utils.getDocumentExtensionsFromType('document');
    this.documentExtensions.push('pdf');
    this.imageDocumentExtensions = this.imageExtensions.concat(this.documentExtensions);

    this.activeRoute.params.subscribe((params) => {
      if (params['documentID']) {
        this.documentID = params['documentID'];
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        if (!this.documentID && this.isPromoterOrAdmin == true) {
          const payload = {
            fields: 'ID,TradingAs,CalculatedName',
          };
          this.utilService.getMerchantList(payload).subscribe((res) => {
            if (res && res.length > 0) {
              this.merchants = res;
            }
          });
        }
        if (this.isPromoterOrAdmin == true) {
          const p: any = {};

          if (this.type) {
            p.type = this.type;
          }

          if (this.types && Array.isArray(this.types) && this.types.length > 0) {
            p.types = this.types.join(',');
          } else if (this.types && typeof this.types == 'string') {
            p.types = this.types;
          }

          if (this.programAreaCode) {
            p.programAreaCode = this.programAreaCode;
          }

          if (this.programAreaCodes && Array.isArray(this.programAreaCodes) && this.programAreaCodes.length > 0) {
            p.programAreaCodes = this.programAreaCodes.join(',');
          } else if (this.programAreaCodes && typeof this.programAreaCodes == 'string') {
            p.programAreaCodes = this.programAreaCodes;
          }

          this.utilService.getDocumentTypeList({}).subscribe((res) => {
            if (res && res.length > 0) {
              for (let i = 0; i < res.length; i++) {
                if (res[i] && res[i]['Label'] && res[i]['TypeCode']) {
                  const o = {
                    code: res[i]['TypeCode'],
                    label: res[i]['Label'],
                  };
                  this.types.push(o);
                }
              }
            }
          });
        }

        this.authenticationService.getSessionType().subscribe((res) => {
          this.sesstionType = res;

          this.utilService.getCurrentAccess().subscribe((access) => {
            if (access) {
              this.isModulePrivateDocumentActive = access['isModulePrivateDocumentActive'];
            }
          });

          if (this.documentID) {
            this.thumbnailLink = null;
            this.thumbnailLink = this.utilService.getDocumentThumbnailStreamLink(this.documentID);

            const payload = {
              fields:
                'AWS_URL,AWS_Bucket_Name,DocumentType_Code,DateAdded,Card.Name,DateTimeCreated,Description,Extension,ID,Label,URL.Complete,Tags,ContainsSensitiveData,IsGallery',
            };
            this.utilService.getEpdocument(this.documentID, payload, this.sesstionType).subscribe((res) => {
              if (res) {
                if (res.ID) {
                  this.document.ID = res.ID;
                  this.isEdit = true;
                }

                if (res.Label) {
                  this.document.label = res.Label;
                }

                if (res.DocumentType_Code) {
                  this.document.type = res.DocumentType_Code;
                }

                if (res.Description) {
                  this.document.description = res.Description;
                }
                if (res.IsGallery === '1') {
                  this.document.isGallery = true;
                } else {
                  this.document.isGallery = false;
                }

                if (res.ContainsSensitiveData === '1') {
                  this.document.isPrivate = true;
                } else {
                  this.document.isPrivate = false;
                }
              }
            });
          }
        });
      });
    });
  }

  closeEvent() {
    this.closeModal.emit(true);
  }

  save() {
    if (this.document) {
      let payload = {};

      payload = {
        fields:
          'AWS_URL,AWS_Bucket_Name,DocumentType_Code,DateAdded,DateTimeCreated,Description,Extension,ID,Label,URL.Complete,Tags,ContainsSensitiveData,IsGallery',
        thumbnailFileID: this.document.thumbnailFileID,
        fileID: this.document.fileID,
        label: this.document.label,
        description: this.document.description,
        isGallery: this.document.isGallery,
        isPrivate: this.document.isPrivate,
        type: this.document.type,
        merchantID: this.merchantID,
        cardID: this.cardID,

        contractID: this.contractID,
        invitationID: this.invitationID,
        drawDownID: this.drawDownID,
        paymentID: this.paymentID,
      };

      if (this.documentID) {
        this.utilService.editEpdocument(this.document.ID, payload, this.isPromoterOrAdmin).subscribe((res) => {
          if (res) {
            this.result.emit(res);
            NotifyAppComponent.displayToast(
              'success',
              'Successful Operation',
              'You have successfully edited a document!'
            );
          }
        });
      } else {
        this.utilService.createEpdocument(payload, this.isPromoterOrAdmin).subscribe((res) => {
          if (res) {
            this.result.emit(res);
            NotifyAppComponent.displayToast(
              'success',
              'Successful Operation',
              'You have successfully edited a document!'
            );
          }
        });
      }
    }
  }

  onCompleteThumbnail(e) {
    if (e && e[0]) {
      this.document.thumbnailFileID = e[0];
      if (e && e[0]) {
        this.document.thumbnailFileID = e[0];

        this.thumbnailLink = null;
        setTimeout(() => {
          this.thumbnailLink = this.utils.getFileLink(this.document.thumbnailFileID);
        }, 200);
      } else {
        this.thumbnailLink = null;
      }
    }
  }

  onCompleteFile(e) {
    if (e && e[0]) {
      this.document.fileID = e[0];

      this.fileLink = null;

      setTimeout(() => {
        this.fileLink = this.utils.getFileLink(this.document.fileID);
      }, 200);
    } else {
      this.fileLink = null;
    }
  }

  buildTypes(docTypes) {
    if (docTypes && docTypes.length > 0) {
      if (docTypes && docTypes[0] && docTypes[0]['Label'] && docTypes[0]['TypeCode']) {
        return _.map(docTypes, (d) => {
          let item = {
            code: d.TypeCode,
            label: d.Label,
          };

          return item;
        });
      } else {
        return docTypes;
      }
    }
  }
  changeDocumentType() {
    if (this.document && this.document.type) {
      if (this.imageTypes.indexOf(this.document.type) !== -1) {
        this.fileType = 'image';
      } else if (this.documentypes.indexOf(this.document.type) !== -1) {
        this.fileType = 'document';
      } else if (this.imageDocumentTypes.indexOf(this.document.type) !== -1) {
        this.fileType = 'imageDocument';
      } else if (this.videoTypes.indexOf(this.document.type) !== -1) {
        this.fileType = 'video';
      } else {
        this.fileType = 'all';
      }
    } else {
      this.fileType = 'all';
    }

    if (this.document && !this.document.label && this.document.type) {
      let index = this.types.findIndex((d) => {
        if (d && d.code && d.code == this.document.type) {
          return true;
        }
        return false;
      });

      if (index != -1 && this.types[index] && this.types[index]['label']) {
        this.document.label = this.types[index]['label'];
      }
    }
  }
}
