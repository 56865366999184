/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./document-list-consumer-simple.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../shared/components/file-list/file-list.component.ngfactory";
import * as i5 from "../../../shared/components/file-list/file-list.component";
import * as i6 from "@angular/material/dialog";
import * as i7 from "../../../shared/services/utils.service";
import * as i8 from "../../../shared/services/file.service";
import * as i9 from "@angular/common";
import * as i10 from "./document-list-consumer-simple.component";
import * as i11 from "../shared/treatment.service";
var styles_DocumentListConsumerSimpleComponent = [i0.styles];
var RenderType_DocumentListConsumerSimpleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DocumentListConsumerSimpleComponent, data: {} });
export { RenderType_DocumentListConsumerSimpleComponent as RenderType_DocumentListConsumerSimpleComponent };
function View_DocumentListConsumerSimpleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "h2", [["class", "sr-title sm-h2 rm-mb rm-mt"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["attach_file"])), (_l()(), i1.ɵted(-1, null, [" Attached documents "])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "row-content-col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-file-list", [["class", ""]], null, null, null, i4.View_FileListComponent_0, i4.RenderType_FileListComponent)), i1.ɵdid(8, 638976, null, 0, i5.FileListComponent, [i6.MatDialog, i7.UtilsService, i8.FileService], { fs: [0, "fs"], canEdit: [1, "canEdit"], canAction: [2, "canAction"], invitationID: [3, "invitationID"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_2 = _co.treatments; var currVal_3 = false; var currVal_4 = false; var currVal_5 = _co.invitationID; _ck(_v, 8, 0, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inline; var currVal_1 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_DocumentListConsumerSimpleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentListConsumerSimpleComponent_1)), i1.ɵdid(1, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.treatments.length > 0); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DocumentListConsumerSimpleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-document-list-consumer-simple", [], null, null, null, View_DocumentListConsumerSimpleComponent_0, RenderType_DocumentListConsumerSimpleComponent)), i1.ɵdid(1, 638976, null, 0, i10.DocumentListConsumerSimpleComponent, [i11.TreatmentService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DocumentListConsumerSimpleComponentNgFactory = i1.ɵccf("app-document-list-consumer-simple", i10.DocumentListConsumerSimpleComponent, View_DocumentListConsumerSimpleComponent_Host_0, { invitationID: "invitationID" }, { isExist: "isExist" }, []);
export { DocumentListConsumerSimpleComponentNgFactory as DocumentListConsumerSimpleComponentNgFactory };
