<mat-card class="card">
  <div class="colour-stripe"></div>
  <h3 class="sr-title sm-h3 rm-m">Waivers &amp; Refunds</h3>
  <hr/>
  <div class="row clearfix">
    <table class="full-width" [dataSource]="waiverSource" mat-table>
      <ng-container matColumnDef="date">
        <th *matHeaderCellDef mat-header-cell>Date</th>
        <td *matCellDef="let waiver" mat-cell>{{ waiver.date }}</td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th *matHeaderCellDef mat-header-cell>Amount</th>
        <td *matCellDef="let waiver" mat-cell>{{ waiver.amount | customCurrency }}</td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th *matHeaderCellDef mat-header-cell>Types</th>
        <td *matCellDef="let waiver" mat-cell>{{ waiver.type }}</td>
      </ng-container>

      <ng-container matColumnDef="notes">
        <th *matHeaderCellDef mat-header-cell>Notes</th>
        <td *matCellDef="let waiver" mat-cell>{{ waiver.notes }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th *matHeaderCellDef mat-header-cell>Status</th>
        <td *matCellDef="let waiver" mat-cell>{{ waiver.status }}</td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
    </table>
  </div>
</mat-card>

<mat-card class="card">
  <div class="colour-stripe"></div>
  <h3 class="sr-title sm-h3 rm-m">Direct Payments</h3>
  <hr/>
  <div class="row clearfix">
    <table class="full-width" [dataSource]="waiverSource" mat-table>
      <ng-container matColumnDef="date">
        <th *matHeaderCellDef mat-header-cell>Date</th>
        <td *matCellDef="let waiver" mat-cell>{{ waiver.date }}</td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th *matHeaderCellDef mat-header-cell>Amount</th>
        <td *matCellDef="let waiver" mat-cell>{{ waiver.amount | customCurrency }}</td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th *matHeaderCellDef mat-header-cell>Types</th>
        <td *matCellDef="let waiver" mat-cell>{{ waiver.type }}</td>
      </ng-container>

      <ng-container matColumnDef="notes">
        <th *matHeaderCellDef mat-header-cell>Notes</th>
        <td *matCellDef="let waiver" mat-cell>{{ waiver.notes }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th *matHeaderCellDef mat-header-cell>Status</th>
        <td *matCellDef="let waiver" mat-cell>{{ waiver.status }}</td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
    </table>
  </div>
</mat-card>
