<ipv-container-modal>
  <ng-container banner>
    <button class="btn-close btn-clear mat-button white" (click)="close()" matDialogClose>
      <mat-icon>clear</mat-icon>
    </button>

    <div class="row titleArea clearfix">
      <div class="title-section">
        <mat-icon class="white pull-left" style="margin-right: 5px; line-height: 32px">comment</mat-icon>

        <h2 class="summary-header rm-m" style="font-size: x-large; line-height: 32px; white-space: nowrap">
          {{ clientName }}
        </h2>

        <img [src]="profilePhotoReviewLink" mat-card-avatar style="width: 32px; height: 32px; margin-left: 15px"/>
      </div>
    </div>

    <div style="width: 150px">
      <app-rating *ngIf="starRating" [rating]="starRating"> </app-rating>
    </div>
  </ng-container>

  <ng-container content>
    <mat-form-field
      class="example-full-width"
      *ngIf="editMode"
      appearance="outline"
      style="width: 100%; margin-top: 20px"
    >
      <mat-label>Reply</mat-label>
      <textarea
        [(ngModel)]="comment"
        matInput
        placeholder="Enter reply here..."
        cdkTextareaAutosize
        cdkAutosizeMinRows="10"
      ></textarea>
    </mat-form-field>

    <p *ngIf="!editMode" style="text-align: left; margin-top: 20px; margin-bottom: 20px">
      {{ comment }}
    </p>

    <hr/>

    <div class="row buttonrow flex" style="justify-content: flex-end">
      <button (click)="close()" mat-raised-button color="primary" style="margin-right: 10px">
        <span>Cancel</span>
      </button>

      <button *ngIf="!editMode" (click)="enableEditMode()" mat-raised-button color="accent">
        <span>Edit</span>
      </button>

      <button *ngIf="editMode" (click)="sendReply()" mat-raised-button color="accent">
        <span>Send</span>
      </button>
    </div>
  </ng-container>
</ipv-container-modal>
