<div class="row clearfix full-width">
  <div class="pleaseChoose" @ngIfAnimation>
    <p *ngIf="title != ''">
      <strong>{{ title }}</strong>
    </p>
    <img class="character_warning" />
    <p>{{ message }}</p>

    <button
      class="text-center clearfix"
      *ngIf="isAction == true"
      (click)="actionEventClick(t)"
      mat-raised-button
      color="accent"
      matTooltip="{{ actionLabel }}"
    >
      <mat-icon class="mr" *ngIf="actionIcon">{{ actionIcon }}</mat-icon>
      <span>{{ actionLabel }}</span>
    </button>
  </div>
</div>
