<div class="card-header primary-gradient-img clearfix inModal">
  <div class="row clearfix">
    <button class="btn-close btn-clear mat-button" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="row titleArea clearfix">
      <h2 class="summary-header rm-m">
        {{ title }}
      </h2>
    </div>
  </div>
</div>
<mat-dialog-content class="text-center">
  <img
    src="assets/images/google_review_prompt.png"
    style="display: block; margin: 5px auto; width: 100%; height: 180px"
  />
  <hr/>

  <p class="sr-title" style="font-weight: bold; font-size: x-large; text-align: left">Happy with our service?</p>

  <p style="text-align: left">
    <span class="sr-title" style="font-weight: bold"> {{ patientName }} </span> , the team at
    <span class="sr-title" style="font-weight: bold"> {{ practiceName }} </span> constantly strive to provide the best
    level of {{ "KEYWORD.patient" | translate }} care. If we have done this for you, we kindly invite you to write us a
    positive review on Google.
    It should only take you a few minutes and would mean the world to us - thanks in advance!
  </p>

  <a
    *ngIf="merchantID"
    [href]="'https://smileright.com.au/practice-landing-page/' + merchantID + '?gR=true'"
    (click)="onYes()"
    mat-raised-button
    color="primary"
    target="_blank"
    style="float: right"
  >Write Review</a
  >
</mat-dialog-content>
