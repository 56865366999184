import { Contract } from '../types/contract.type';

export const DEFAULT_ALL_CONTRACTS_OPTION: Pick<
  Contract,
  'Product.Label.Marketing' | 'ApplicationDate' | 'ID' | 'Customer_key'
>[] = [
  {
    'Product.Label.Marketing': 'All',
    ApplicationDate: '',
    ID: null,
    Customer_key: null,
  },
];

export const DEFAULT_CONTRACT_CUSTOMER_OPTION: Pick<Contract, 'Customer.FullName' | 'Customer.Email' | 'Customer_key'> =
  {
    'Customer.FullName': 'All',
    'Customer.Email': '',
    Customer_key: null,
  };

export const DEFAULT_ALL_CONTRACT_FUNDERS_OPTION: Pick<Contract, 'FunderName' | 'Funder_key'>[] = [
  {
    FunderName: 'All',
    Funder_key: null,
  },
];

export const DEFAULT_ALL_CONTRACT_PRODUCTS_OPTION: Pick<Contract, 'Product.Label.Marketing' | 'Product_key'>[] = [
  {
    'Product.Label.Marketing': 'All',
    Product_key: null,
  },
];
