import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MerchantService } from '../../shared/merchant.service';

@Component({
  selector: 'sr-add-services-profile-dialog',
  templateUrl: './add-service-profile-dialog.component.html',
  styleUrls: ['./add-service-profile-dialog.component.css'],
})
export class AddServiceProfileDialogComponent implements OnInit {
  @Output() addServiceProfile: EventEmitter<any> = new EventEmitter();

  services = [];
  merchantID;

  fullServices = [];
  currentServices = [];
  selectedServices = {};

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private merchantService: MerchantService) {
    if (this.data) {
      this.merchantID = data.merchantID;
    }
  }

  ngOnInit(): void {
    this.merchantService.getMerchantServicesItemsList({}).subscribe((fullServices) => {
      this.fullServices = fullServices;

      this.merchantService
        .getMerchantProfileServicesItemsList({ Merchant_key: this.merchantID })
        .subscribe((currentServices) => {
          this.currentServices = currentServices;

          this.currentServices.forEach((currentService) => {
            const service_Group_Item_Label = currentService['Service_Group_Item.Label'].toLowerCase();

            this.selectedServices[service_Group_Item_Label] = true;
          });

          this.merchantService.getMerchantServicesGroupItemsList({}).subscribe((data) => {
            this.services = data.map((dataItem) => {
              return {
                serviceGroup: dataItem.serviceGroup.toLowerCase(),
                services: dataItem.services.map((service) => service.toLowerCase()),
              };
            });
          });
        });
    });
  }

  save() {
    const newItems = [];
    const removedItems = [];

    for (const key in this.selectedServices) {
      if (this.selectedServices[key]) {
        const itemExistsIndex = this.currentServices.findIndex((currentService) => {
          return currentService['Service_Group_Item.Label'].toLowerCase() === key.toLowerCase();
        });

        if (itemExistsIndex < 0) {
          const itemDataIndex = this.fullServices.findIndex((fullService) => {
            return fullService['Label'].toLowerCase() === key.toLowerCase();
          });

          const fullItemData = this.fullServices[itemDataIndex];
          const newService = {
            Merchant_key: this.merchantID,
            ServiceGroupItem_key: fullItemData['ID'],
            Service_Group_key: fullItemData['ServiceGroup_key'],
          };

          newItems.push(newService);
        }
      } else if (this.selectedServices[key] === false) {
        const itemExistsIndex = this.currentServices.findIndex((currentService) => {
          return currentService['Service_Group_Item.Label'].toLowerCase() === key.toLowerCase();
        });

        if (itemExistsIndex > -1) {
          removedItems.push(this.currentServices[itemExistsIndex].ID);
        }
      }
    }

    this.addServiceProfile.emit({
      removedServices: removedItems,
      newServices: newItems,
    });
  }
}
