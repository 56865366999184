<div *ngIf="displayCampaignReview == false">
  <aw-wizard #wizard navBarLayout="large-filled-symbols">
    <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Define campaign">
      <div class="container">
        <!-- The Start of First Wizard Step for Create Marketing Campaign -->

        <button class="step-next pull-right" *ngIf="campaignID != null && editMode == true"
                (click)="viewCampaignModal()" mat-raised-button color="accent">
          <mat-icon>visibility</mat-icon>
          View Campaign
        </button>

        <div class="clearfix row gridView" *ngIf="isPromoterOrAdmin == true">
          <div class="row clearfix selectMerchant">
            <mat-form-field class="mb full-width" appearance="outline">
              <mat-label>Please Select a Merchant</mat-label>
              <mat-select class="" [(ngModel)]="merchantID" (change)="changeMerchant()" name="MerchantName"
                          placeholder="">
                <mat-option [value]="null"> My Account</mat-option>
                <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m['ID']">
                  <span class="list-label pull-right" *ngIf="m['Status'] && m['Status'] == 'Active'"
                        [style.background]="'#00d07a'">
                    {{ m['Status'] }}
                  </span>

                  <span class="list-label pull-right" *ngIf="m['Status'] && m['Status'] != 'Active'"
                        [style.background]="'#ff5722'">
                    {{ m['Status'] }}
                  </span>
                  {{ m['TradingAs'] || m['CalculatedName'] }}
                </mat-option>
              </mat-select>
              <mat-hint>To send an invitation on behalf of a merchant select a
                merchant below, otherwise, the invitation will be sent by the promoter.
              </mat-hint>
            </mat-form-field>

            <button class="pull-right merchBtn" *ngIf="merchantID && merchantID != 'none'" (click)="viewMerchant()"
                    mat-raised-button color="primary">
              <mat-icon>person</mat-icon>
              <span class="mobHide">View Merchant</span>
            </button>
          </div>
        </div>

        <div class="row clearfix">
          <app-marketing-template-gallery *ngIf="loadTempalates == true" [isForMerchant]="isForMerchant"
                                          [isBusiness]="isBusiness" [isBusinessOrDefault]="isBusinessOrDefault"
                                          [isCampaign]="isCampaign"
                                          [isMerchantTemplate]="isMerchantTemplate"
                                          [isManualCreatedOrDefault]="isManualCreatedOrDefault"
                                          [isActive]="isActive" [isDownload]="false" [isProceed]="true"
                                          [isPrint]="false" [isRedirect]="false"
                                          [title]="title" [description]="description"
                                          [templateID]="selectedTemplatePreviewID"
                                          [merchantID]="merchantID" [isPatientCampaign]="true"
                                          (selectTemplate)="selectTemplateEvent($event)"
                                          (proceed)="selectProceed($event)">
          </app-marketing-template-gallery>
        </div>

        <div class="flex sp-between add-mt gridView">
          <button class="" [routerLink]="['/merchant/', { outlets: { page: ['marketing-over-view'] } }]"
                  mat-raised-button color="primary" type="button">
            <mat-icon>chevron_left</mat-icon>
            <span>Return to Marketing Overview</span>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <!-- define/customise audience -->
    <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Customise audience">
      <div class="container create-container">
        <div class="row clearfix text-center">
          <h2 class="lg-h2 sr-title">Choose your audience</h2>
        </div>
        <mat-card class="clearfix card audience">
          <div class="row clearfix">
            <app-template-review [templateID]="selectedTemplateID" [hideMarkup]="true" [hideOptions]="true">
            </app-template-review>
          </div>
          <div class="audience" *ngIf="newAudience == false" @ngIfAnimation>
            <div class="animate">
              <div class="row clearfix">
                <h2 class="sr-title sm-h2 rm-mb">
                  <mat-icon class="mr fas fa-users"></mat-icon>
                  Your audience
                </h2>
                <p>
                  Who are you sending this campaign to? You can choose to send it to all your
                  {{ "KEYWORD.patient" | translate }}s, or define custom audience list by selecting 'Create new
                  audience'.
                </p>
              </div>
              <div class="row clearfix mt mb">
                <button class="pull-left primary-color" *ngIf="showFilterCreate == false" (click)="createFilter()"
                        mat-raised-button>
                  <mat-icon>add</mat-icon> Create new audience
                </button>
                <button class="pull-left" *ngIf="showFilterCreate == true" (click)="chooseExistingFilter()"
                        mat-raised-button color="accent">
                  <mat-icon>chevron_left</mat-icon> Choose existing audience
                </button>
              </div>
              <div class="row clearfix mt mb" *ngIf="showFilterCreate == false">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Please Select a audience Or use Tags</mat-label>
                  <mat-select [(ngModel)]="filterID" placeholder="Please Select a filter  Or use Tags">
                    <mat-option [value]="'none'"><strong>All {{ "KEYWORD.patient" | translate }}s </strong></mat-option>
                    <mat-option *ngIf="tags && tags.length > 0" [value]="'allTags_Select'"><strong>Use Tags </strong>
                    </mat-option>
                    <mat-option *ngIf="staticAudiences && staticAudiences.length > 0"
                                [value]="'allTags_Select_Static_Adience'"><strong>Use Static Audiences</strong>
                    </mat-option>

                    <mat-option *ngFor="let filter of existingFilterArray" [value]="filter.ID">{{ filter.Label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <hr *ngIf="showFilterCreate == true"/>
              <div class="row clearfix mt mb" *ngIf="
                  showFilterCreate == true &&
                  filterID != 'allTags_Select_Static_Adience' &&
                  filterID != 'allTags_Select' &&
                  filterID == 'none'
                ">
                <!-- <h2 class="summary-header rm-m">Marketing Filter</h2> -->

                <app-marketing-filter-create [merchantID]="merchantID" [standalone]="false"
                                             (getResult)="getFilterEvent($event)"></app-marketing-filter-create>
              </div>

              <div class="row clearfix mt mb" *ngIf="
                  filterID &&
                  filterID != 'allTags_Select_Static_Adience' &&
                  filterID != 'allTags_Select' &&
                  filterID != 'none'
                ">
                <!-- filter content -->
                <!-- <p>Display filter content</p> -->
                <app-marketing-filter-view [isEdit]="true" [filterID]="filterID" [viewModal]="false">
                </app-marketing-filter-view>
              </div>

              <div class="row clearfix mb full-width"
                   *ngIf="filterID && filterID == 'allTags_Select' && tags && tags.length > 0">
                <div class="full-width clearfix" *ngIf="tags && tags.length > 0">
                  <mat-form-field class="full-width clearfix" appearance="outline">
                    <mat-label>Add one or Multiple Tags</mat-label>
                    <input #tagInput [(ngModel)]="tag" [matAutocomplete]="auto" [matChipInputFor]="chipList"
                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" matInput
                           name="tags-wiki-model " placeholder="Add one or Multiple Audiences"/>

                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                      <mat-option *ngFor="let t of tags | orderBy | FilterArrayMultipleContainValue: '*':tag"
                                  [value]="t.Label">
                        {{ t.Label }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>

                <div class="full-width text-center">
                  <mat-chip-list #chipList name="tags-wiki">
                    <mat-chip class="tag-chip" *ngFor="let tag of selectedTags" [selectable]="false" [removable]="true"
                              (removed)="removeTag(tag)">
                      {{ tag }}
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                  </mat-chip-list>
                </div>
              </div>

              <div class="row clearfix mb full-width" *ngIf="
                  filterID &&
                  filterID == 'allTags_Select_Static_Adience' &&
                  staticAudiences &&
                  staticAudiences.length > 0
                ">
                <div class="full-width clearfix" *ngIf="staticAudiences && staticAudiences.length > 0">
                  <mat-form-field class="full-width clearfix" appearance="outline">
                    <mat-label>Add one or Multiple Audiences</mat-label>
                    <input #tagInput [(ngModel)]="staticAudience" [matAutocomplete]="auto" [matChipInputFor]="chipList"
                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" matInput
                           name="staticAudiences-wiki-model" placeholder="Add one or Multiple Audiences"/>

                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event, true)">
                      <mat-option *ngFor="
                          let t of staticAudiences | orderBy | FilterArrayMultipleContainValue: '*':staticAudience
                        " [value]="t.Label">
                        {{ t.Label }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>

                <div class="full-width text-center">
                  <mat-chip-list #chipList name="tags-wiki">
                    <mat-chip class="tag-chip" *ngFor="let tag of selectedStaticAudiences" [selectable]="false"
                              [removable]="true" (removed)="removeTag(tag, true)">
                      {{ tag }}
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                  </mat-chip-list>
                </div>
              </div>

              <!-- <p class="small">Total {{ "KEYWORD.patient" | translate }}s: <strong>{{allNumber}}</strong></p> -->
            </div>
          </div>
          <div class="row clearfix define-audience" *ngIf="newAudience == true && false" @ngIfAnimation>
            <div class="animate">
              <div class="row clearfix">
                <h2 class="lg-h2 sr-title">
                  <mat-icon class="mr fas fa-users-cog"></mat-icon>
                  Create new audience
                </h2>
                <p>
                  Using the Audiences below, you can define a custom audience list that targets certain demographics
                  within your {{ "KEYWORD.patient" | translate }} database. You can group audiences by metrics such as
                  age, distance from your
                  {{ "KEYWORD.practice" | translate }}, health conditions and more.
                </p>
              </div>
              <div class="row clearfix">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Enter a name for your Audience List</mat-label>
                  <input name="audienceName" matInput placeholder="Enter a name for your Audience List" required/>
                </mat-form-field>
              </div>

              <div class="row clearfix">
                <h2 class="sr-title">Select existing Audience</h2>

                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Audience</mat-label>
                  <mat-select [(ngModel)]="filterID">
                    <mat-option *ngFor="let filter of existingFilterArray" [value]="filter.ID">{{ filter.Label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <app-marketing-audience-filter></app-marketing-audience-filter>
              <app-marketing-filter-create [merchantID]="merchantID" (getResult)="getFilterEvent($event)">
              </app-marketing-filter-create>

              <div class="row clearfix mt mb buttons">
                <button class="pull-left accent-color mr" (click)="newAudience = !newAudience" mat-raised-button>
                  <mat-icon>block</mat-icon> Cancel
                </button>
                <button class="pull-left" mat-raised-button color="warn">Preview audience</button>
                <button class="pull-right" (click)="newAudience = !newAudience" mat-raised-button color="primary">
                  <mat-icon>save</mat-icon> Save audience
                </button>
              </div>
            </div>
          </div>
        </mat-card>
        <div class="row clearfix mt">
          <button class="pull-left" (click)="templateChoice()" mat-raised-button color="accent" type="button">
            <mat-icon>chevron_left</mat-icon>
            <span>Choose another template</span>
          </button>
          <button class="pull-right" [disabled]="
              filterID == null ||
              (filterID == 'allTags_Select_Static_Adience' &&
                (!selectedStaticAudiences || (selectedStaticAudiences && selectedStaticAudiences.length <= 0))) ||
              (filterID == 'allTags_Select' && (!selectedTags || (selectedTags && selectedTags.length <= 0)))
            " mat-raised-button color="primary" type="button" awNextStep>
            <span>Next</span>
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Campaign Details">
      <div class="container create-container">
        <!-- The Start of First Wizard Step for Create Marketing Campaign -->
        <div class="row clearfix text-center">
          <button class="step-next pull-right" *ngIf="campaignID != null && editMode == true"
                  (click)="viewCampaignModal()" mat-raised-button color="accent">
            <mat-icon>visibility</mat-icon>
            View Campaign
          </button>

          <h2 class="lg-h2 sr-title" *ngIf="campaignID == null || editMode != true">
            Create new campaign - Define campaign
          </h2>
          <h2 class="lg-h2 sr-title" *ngIf="campaignID != null && editMode == true">
            Edit Campaign : {{ campaignName }} ({{ statusLabel }})
          </h2>
        </div>

        <div class="clearfix row mb" *ngIf="isPromoterOrAdmin == true">
          <div class="row clearfix selectMerchant" *ngIf="displayMerchantSelect == true">
            <mat-form-field class="mb full-width" appearance="outline">
              <mat-label>Please Select a Merchant</mat-label>
              <mat-select class="  " [(ngModel)]="merchantID" (change)="changeMerchant()" name="MerchantName"
                          placeholder="">
                <mat-option [value]="null"> My Account</mat-option>
                <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m['ID']">
                  <span class="list-label pull-right" *ngIf="m['Status'] && m['Status'] == 'Active'"
                        [style.background]="'#00d07a'">
                    {{ m['Status'] }}
                  </span>

                  <span class="list-label pull-right" *ngIf="m['Status'] && m['Status'] != 'Active'"
                        [style.background]="'#ff5722'">
                    {{ m['Status'] }}
                  </span>
                  {{ m['TradingAs'] || m['CalculatedName'] }}
                </mat-option>
              </mat-select>

              <mat-hint>To send an invitation on behalf of a merchant select a
                merchant below, otherwise, the invitation will be sent by the promoter.
              </mat-hint>
            </mat-form-field>

            <button class="pull-right merchBtn" *ngIf="merchantID && merchantID != 'none'" (click)="viewMerchant()"
                    mat-raised-button color="primary">
              <mat-icon>person</mat-icon>
              <span class="mobHide">View Merchant</span>
            </button>
          </div>
        </div>

        <div class="row clearfix flex">
          <!-- <div class="half-width"> -->

          <mat-card class="clearfix high create-container">
            <div class="row clearfix">
              <app-template-review [templateID]="selectedTemplateID" [hideMarkup]="true" [hideOptions]="true">
              </app-template-review>
            </div>

            <div class="row clearfix">
              <h2 class="sr-title sm-h2">
                <mat-icon class="mr fas fa-envelope"></mat-icon>
                Campaign Details
              </h2>
            </div>

            <form #createForm="ngForm" name="form">
              <div class="row clearfix">
                <div class="clearfix" *ngIf="false">
                  <mat-select class="full-width" [(ngModel)]="selectedCampaignTarget"
                              [disabled]="campTargets.length == 1" (change)="changeTarget()" name="selectedCampaign"
                              placeholder="Targets of campaign" required>
                    <mat-option *ngFor="let campTarget of campTargets" [value]="campTarget">
                      {{ utilMarketing.getTargetLabel(campTarget) }}
                    </mat-option>
                  </mat-select>
                </div>

                <div class="row clearfix" *ngIf="false">
                  <mat-select class="full-width" [(ngModel)]="selectedTemplateObject" (change)="changeTemplate()"
                              name="selectedTemplate" placeholder="Please Select Your Template" required>
                    <mat-option *ngFor="let template of templates" [value]="template">
                      {{ template['Label'] }}
                    </mat-option>
                  </mat-select>
                </div>

                <div class="row clearfix flex campaignDetail">
                  <div class="full-width clearfix mr">
                    <div class="row clearfix">
                      <h4 class="primary-color rm-m sm-mb">Label</h4>
                    </div>

                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>For your own reference.</mat-label>
                      <input [(ngModel)]="campaignName" name="campaignName" matInput
                             placeholder="For your own reference." required/>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row clearfix flex campaignDetail">
                  <div class="full-width clearfix mr" *ngIf="displayCTA == true">
                    <div class="row clearfix">
                      <h4 class="primary-color rm-m sm-mb">Call to Action (CTA)</h4>
                    </div>

                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>The CTA captures attention on your landing pages.</mat-label>

                      <input [(ngModel)]="actionCallLabel" name="actionCallLabel" matInput
                             placeholder="The CTA captures attention on your landing pages." required/>
                    </mat-form-field>
                  </div>
                  <div class="full-width clearfix"
                       *ngIf="selectedTemplate && selectedTemplate['MetaData'] && selectedTemplate['MetaData']['Amount']">
                    <div class="row clearfix">
                      <h4 class="primary-color rm-m sm-mb">Sender</h4>
                    </div>

                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Choose which member of the team is sending the campaign.</mat-label>
                      <mat-select class="" [(ngModel)]="contactID" name="selectedSender"
                                  placeholder="Choose which member of the team is sending the campaign." required>
                        <mat-option *ngFor="let sender of contacts" [value]="sender.ID">
                          {{ sender.CalculatedName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row clearfix flex campaignDetail">
                  <div class="full-width clearfix" *ngIf="displayCTA == true">
                    <div class="row clearfix">
                      <h4 class="primary-color rm-m sm-mb">Description/Preview</h4>
                    </div>

                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>The description accompanies the CTA and provides a preview of the campaign offer..
                      </mat-label>

                      <textarea [(ngModel)]="actionCallDescription" [maxLength]="350" name="actionCallDescription"
                        matInput
                        placeholder="The description accompanies the CTA and provides a preview of the campaign offer."
                                mdTextareaAutosize></textarea>

                      <mat-hint class="pull-right" align="end">Text length: {{ actionCallDescription?.length || 0 }}/
                        350
                      </mat-hint>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="row clearfix mt" *ngIf="selectedTemplate && selectedTemplate['MetaData']">
                <app-metadata-input [metadata]="selectedTemplate['MetaData']" [marketingUserData]="userData"
                                    (validate)="validateUserDataEvent($event)"
                                    (getUserData)="getUserData($event)"></app-metadata-input>
              </div>

              <!-- time and date -->

              <div class="row clearfix clearfix">
                <div class="row clearfix mb">
                  <div class="row clearfix">
                    <h2 class="sr-title sm-h2" *ngIf="smsSupport == true && emailSupport == true">
                      <mat-icon class="mr fas fa-envelope-open"></mat-icon>
                      Email/SMS Delivery
                    </h2>

                    <h2 class="sr-title sm-h2" *ngIf="smsSupport != true && emailSupport == true">
                      <mat-icon class="mr fas fa-envelope-open"></mat-icon>
                      Email Delivery
                    </h2>

                    <h2 class="sr-title sm-h2" *ngIf="smsSupport == true && emailSupport != true">
                      <mat-icon class="mr fas fa-envelope-open"></mat-icon>
                      SMS Delivery
                    </h2>
                  </div>

                  <section class="slideToggle mt" *ngIf="smsSupport == true && emailSupport == true">
                    <mat-slide-toggle [(ngModel)]="isSendEmail" name="isSendEmail">Email your {{ "KEYWORD.patient" |
                      translate }}s.
                    </mat-slide-toggle>
                  </section>

                  <section class="slideToggle mt" *ngIf="smsSupport == true">
                    <mat-slide-toggle [(ngModel)]="isSendSMS" name="isSendSMS">SMS
                      your {{ "KEYWORD.patient" | translate }}s.
                    </mat-slide-toggle>
                  </section>

                  <section class="slideToggle mt" *ngIf="isSendEmail == true || isSendSMS == true">
                    <mat-slide-toggle [(ngModel)]="delayDate" (change)="changeDelay()" name="delayDate">
                      Delay delivery? Enter the date and time here.
                    </mat-slide-toggle>
                  </section>

                  <div class="full-width clearfix flex flexCenter" *ngIf="delayDate == false">
                    <p style="margin-left: 44px">
                      <strong>DELIVERY - IMMEDIATELY (see Important section below)</strong>
                    </p>
                  </div>

                  <div class="full-width clearfix flex flexCenter"
                       *ngIf="delayDate == true && (isSendEmail == true || isSendSMS == true)">
                    <div class="full-width clearfix mt flex flexCenter">
                      <div class="emailToggle">
                        <label style="display: inline; padding-right: 1rem">Sent at</label>
                      </div>

                      <div class="full-width clearfix">
                        <app-date-input class="thin emailDate" [defaultDate]="whenToSend" [isTime]="true"
                                        [label]="'Date:'" [minDate]="minDate" (selectedDate)="selectDate($event)"
                                        (selectedDateObject)="selectDateObject($event)">
                        </app-date-input>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <hr *ngIf="smsSupport==true">
                <div class="row clearfix mb" *ngIf="smsSupport==true">
                  <div class="row clearfix ">
                    <h2 class="sr-title sm-h2">
                      <mat-icon class="mr fas fa-envelope-open"></mat-icon>{{"SMS Delivery" | translate}}
                    </h2>
                  </div>
                  <section class="slideToggle">
                    <mat-slide-toggle name="isPublicCampaign" [(ngModel)]="isSendSMS">If you wish to send SMS to you
                     {{ "KEYWORD.patient" | translate }}s. </mat-slide-toggle>
                  </section>
                  <section class="slideToggle" *ngIf="isSendSMS==true">
                    <mat-slide-toggle name="isPublicCampaign" [(ngModel)]="delaySMS">If you wish to delay the email
                      delivery, enter the date and time here. Otherwise, the SMS will be delivered <strong>immediately
                      </strong> (see Important section below). </mat-slide-toggle>
                  </section>
                  <div class="full-width clearfix flex flexCenter" *ngIf="delaySMS==true && isSendSMS==true">
                    <div class="full-width clearfix mt flex flexCenter">
                      <div class="emailToggle">
                        <label style="display: inline; padding-right: 1rem;">{{"Sent at" | translate}}</label>
                      </div>
                      <span *ngIf="selectedDateSms==null"><strong>Pick the Date and Time to be able go to the next
                          step</strong></span>
                      <app-date-input class="thin" [defaultDate]="dateSms" [isTime]="true" [label]="'Date:'"
                        [minDate]="minDate" (selectedDateObject)="selectDateSmsObject($event)"
                        (selectedDate)="selectDateSms($event)">
                      </app-date-input>
                    </div>
                  </div>
                </div> -->
              </div>

              <div class="row clearfix mb" *ngIf="displayPracticePage == true">
                <div class="row clearfix">
                  <h2 class="sr-title sm-h2">
                    <mat-icon class="mr fas fa-laptop"></mat-icon>
                    {{ "KEYWORD.practice" | translate | titlecase}} Page
                  </h2>
                </div>

                <section class="slideToggle">
                  <mat-slide-toggle [(ngModel)]="isPublicCampaign" name="isPublicCampaign">Extend the reach of the
                    campaign by publishing on your {{ "KEYWORD.practice" | translate | titlecase}} Page and
                    {{ "KEYWORD.dental" | translate | titlecase }} Tips and Offers Page
                  </mat-slide-toggle>
                </section>
              </div>

              <div class="row clearfix">
                <div class="row clearfix">
                  <h2 class="sr-title sm-h2">
                    <mat-icon class="mr fas fa-calendar-alt"></mat-icon>
                    Time limit
                  </h2>
                </div>

                <section class="slideToggle">
                  <mat-slide-toggle [(ngModel)]="hasExpirationDate" name="hasExpirationDate">If you wish to limit the
                    duration of the offer, enter the expiry date here. Otherwise, the offer
                    will be ongoing.
                  </mat-slide-toggle>
                </section>

                <div class="full-width clearfix mt flex flexCenter" *ngIf="hasExpirationDate == true">
                  <div class="emailToggle">
                    <label style="display: inline; padding-right: 1rem">Expired at'</label>
                  </div>

                  <div class="full-width clearfix">
                    <app-date-input class="thin emailDate" [isTime]="true" [label]="'Date:'"
                                    [defaultDate]="defaultExpirationDate" [minDate]="'toDay'"
                                    (selectedDate)="selectedExpirationDate($event)">
                    </app-date-input>
                  </div>
                </div>
              </div>

              <div class="row clearfix" *ngIf="isPromoterOrAdmin == true">
                <div class="row clearfix">
                  <h2 class="sr-title sm-h2">
                    <mat-icon class="mr fas fa-user-check"></mat-icon>
                    Consent Options
                  </h2>
                </div>

                <section class="slideToggle">
                  <mat-slide-toggle [(ngModel)]="respectMarketing" (click)="openConfirmDialogPatients()"
                                    name="respectMarketing">Override Marketing consent
                    for {{ "KEYWORD.patient" | translate |
                      titlecase}}s *
                  </mat-slide-toggle>
                </section>

                <p class="small">
                  * By selecting this option you are overriding your {{ "KEYWORD.patient" | translate }}s request to opt
                  out of marketing messages.
                </p>
              </div>

              <div class="greyArea bottom">
                <div class="row clearfix">
                  <h4 class="sr-title rm-m">Important:</h4>

                  <ul class="important small">
                    <li>Emails will commence on the date and time you selected</li>
                    <li>SMS's will only send in hours of 11AM-8PM - AEST</li>
                    <li>
                      Any message scheduled outside these times will be sent at the first opportunity within the
                      timeframe.
                    </li>
                  </ul>
                </div>
              </div>
            </form>
          </mat-card>
        </div>

        <div class="create-container">
          <div class="row clearfix mt">
            <button class="pull-left" *ngIf="isParams == true && editMode == false" (click)="backToTemplateGallery()"
                    mat-raised-button color="accent" type="button">
              <mat-icon>chevron_left</mat-icon>
              <span>Back</span>
            </button>
            <button class="pull-left" *ngIf="isParams == false && editMode == false" mat-raised-button color="accent"
                    awPreviousStep type="button">
              <mat-icon>chevron_left</mat-icon>
              <span>Back</span>
            </button>
            <button class="pull-left" *ngIf="editMode == true"
              [routerLink]="['/merchant', { outlets: { page: ['marketing-over-view'] } }]"
                    [routerLinkActive]="['active']" mat-raised-button color="accent" type="button">
              <mat-icon>chevron_left</mat-icon>
              <span>Back</span>
            </button>
            <button class="pull-right" *ngIf="selectedTemplate && !validateMataData(selectedTemplate['MetaData'])"
              [disabled]="
                !createForm.form.valid ||
                (selectedDate == null && delayDate == true) ||
                (validateUserData == false && selectedTemplate && selectedTemplate['MetaData'])
              " (click)="confirmDateSelected()" mat-raised-button color="primary" type="button">
              <span>Next</span>
              <mat-icon>chevron_right</mat-icon>
            </button>
            <button class="pull-right" *ngIf="selectedTemplate && validateMataData(selectedTemplate['MetaData'])"
              [disabled]="!createForm.form.valid || (selectedDate == null && delayDate == true)"
                    (click)="confirmDateSelected()" mat-raised-button color="primary" type="button">
              <span>Next</span>
              <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Review Campaign">
      <!-- The Start of Second Wizard Step for Create Marketing Campaign -->
      <div class="container create-container" *ngIf="createdCampaign">
        <div class="row clearfix text-center">
          <h2 class="lg-h2 sr-title">Review and Send</h2>
        </div>
        <div class="row clearfix flex row">
          <div class="half-width row">
            <div class="full-width row">
              <mat-card class="add-pad clearfix review full-height">
                <div class="primary-gradient-img heading">
                  <h2 class="sm-h2 white rm-m">
                    {{ createdCampaign['Label'] }}
                  </h2>
                  <p class="white rm-m">New campaign</p>
                </div>
                <div class="row clearfix">
                  <div class="clearFix full-width" *ngIf="content && content['Email.Subject']">
                    <label>Subject Line</label>
                    <p class="rm-mt">{{ content['Email.Subject'] }}</p>
                  </div>
                  <div class="clearFix full-width">
                    <label>Description</label>
                    <p class="rm-mt">{{ createdCampaign['Description'] }}</p>
                  </div>

                  <div class="clearFix full-width" *ngIf="createdCampaign && createdCampaign['filterName']">
                    <label>Filter Label</label>
                    <p class="rm-mt">{{ createdCampaign['filterName'] }}</p>
                  </div>
                </div>
                <hr/>

                <div class="row clearfix">
                  <h3 class="sr-title rm-mb">Recipients</h3>
                  <mat-list class="statistics">
                    <mat-list-item>
                      <mat-icon class="iocon fas fa-users" matListIcon></mat-icon>
                      <p mdLine>
                        <span class="labelName" *ngIf="isPromoterOrAdmin == true">
                          Potential recipients
                          <span class="block small">{{
                            utilMarketing.getTargetLabel(createdCampaign['Targets.TableName']) }}(s)</span>
                        </span>
                        <span class="labelName" *ngIf="isPromoterOrAdmin == false"> Potential recipients </span>
                        <span class="value">
                          {{ allTarget }}
                        </span>
                      </p>
                    </mat-list-item>
                    <mat-list-item>
                      <mat-icon class="iocon fas fa-minus" matListIcon></mat-icon>

                      <p mdLine>
                        <span class="labelName"> Excluded from List </span>
                        <span class="value">
                          {{ failedTarget }}
                        </span>
                      </p>
                    </mat-list-item>
                    <mat-list-item>
                      <mat-icon class="iocon fas fa-equals" matListIcon></mat-icon>

                      <p class="bold" mdLine>
                        <span class="labelName">
                          Total Recipients
                        </span>
                        <span class="value">
                          {{ successedTarget }}
                        </span>
                      </p>
                    </mat-list-item>
                  </mat-list>
                </div>
                <hr/>

                <div class="row clearfix">
                  <h3 class="sr-title pull-left sm-mb">Delivery</h3>

                  <div class="clearFix full-width">
                    <div *ngIf="delayDate == true">
                      <label>{{ createdCampaign['Unsent.Email'] || 0 }} Emails will be sent commencing</label>
                      <p class="larger rm-mt">
                        {{ createdCampaign['WhenToSend'] | customDateTime: 'DD/MM/YYYY hh:mm A' }}
                      </p>
                    </div>
                    <div *ngIf="delayDate == false">
                      <label>{{ createdCampaign['Unsent.Email'] || 0 }} Emails will be sent commencing</label>
                      <p class="larger rm-mt">IMMEDIATELY</p>
                    </div>
                  </div>
                  <div class="clearFix full-width" *ngIf="smsSupport == true">
                    <div *ngIf="delayDate == true">
                      <label>{{ createdCampaign['Unsent.SMS'] || 0 }} SMS's will be sent commencing</label>
                      <p class="larger rm-mt">
                        {{ createdCampaign['WhenToSend'] | customDateTime: 'DD/MM/YYYY hh:mm A' }}
                      </p>
                    </div>

                    <div *ngIf="delayDate == false">
                      <label>{{ createdCampaign['Unsent.SMS'] || 0 }} SMS's will be sent commencing </label>
                      <p class="larger rm-mt">IMMEDIATELY</p>
                    </div>
                  </div>
                </div>

                <div class="row clearfix" *ngIf="createdCampaign['WhenToExpire']">
                  <h3 class="sr-title pull-left sm-mb">Expiration date</h3>

                  <div class="clearFix full-width">
                    <div>
                      <label>This offer will end at</label>
                      <p class="larger rm-mt">
                        {{ createdCampaign['WhenToExpire'] | customDateTime: 'DD/MM/YYYY hh:mm A' }}
                      </p>
                    </div>
                  </div>
                </div>
              </mat-card>

              <mat-card class="boost-card mt" *ngIf="isModuleBoostReachActive == true">
                <div class="row clearfix">
                  <h3 class="sr-title rm-mt">Boost reach</h3>
                </div>
                <img class="mat-head-img"
                     src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/boost/boost-header-thin.png"/>
                <div class="row clearfix">
                  <p>
                    Currently, you can reach potentially
                    <strong>{{ currentPatients }}</strong>{{ "KEYWORD.patient" | translate }}s with this
                    campaign, but there are <strong>{{ projectedPatients }}</strong>
                    more in your database alone that you could reach if they updated their details...
                  </p>
                  <p>Click <em>Boost Now</em> to find out how we can help...</p>
                  <p>
                    Want to connect with even more potential {{ "KEYWORD.patient" | translate }}s
                    , {{ "BRANDING.Brand Name" | translate | titlecase }} can put
                    you in touch with over <strong>10 million more Australians</strong> from our licensed 3rd party
                    databases. Find out more by clicking <em>Boost Now!</em>
                  </p>
                  <hr/>
                  <div class="row clearfix">
                    <button class="pull-right" (click)="openBoostDialog()" mat-raised-button color="accent">
                      <mat-icon class="fas fa-rocket"></mat-icon> &nbsp; BOOST NOW!
                    </button>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
          <div class="half-width flex row">
            <div class="col-right full-width">
              <mat-card class="full-height reviewTemplate">
                <app-template-review class="inlineReview" [templateID]="selectedTemplateID" [campaign]="createdCampaign"
                                     [class.withSms]="smsSupport == true" [hideSubject]="true">
                </app-template-review>
              </mat-card>
            </div>
          </div>
        </div>

        <p class="small info mt mb text-center">
          <mat-icon>info</mat-icon> <strong>Please note:</strong> Some email clients will render emails differently to
          others. Because of this, the above may not necessarily be a true representation of how this email will look in
          all email clients.
        </p>

        <div class="flex sp-between add-mt">
          <button (click)="edit()" mat-raised-button color="accent" type="button">
            <mat-icon>chevron_left</mat-icon>
            <mat-icon>edit</mat-icon>
            <span>Edit</span>
          </button>

          <button class="" *ngIf="createdCampaign && createdCampaign.ID" (click)="testCampaign()" mat-raised-button
                  color="warn" type="button">
            <span> Send a test email </span>&nbsp;
            <mat-icon>send</mat-icon>
          </button>

          <button class="" (click)="isSended = false" mat-raised-button color="primary" type="button" awNextStep>
            <span>Confirm</span>
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>
    <aw-wizard-step [navigationSymbol]="{ symbol: '4' }" stepTitle="Push the button">
      <div *ngIf="isSended == false" @ngIfAnimation>
        <mat-card class="airplaneCard animate">
          <h1 class="sr-title sm-h1 text-center">We're ready to go when you are!</h1>

          <app-plane-animation [flyAway]="planeFly"></app-plane-animation>

          <p class="larger text-center">When you're ready, you can let your campaign fly into the wild!</p>
          <p class="text-center">
            Press "Let it fly" to send this campaign out to
            <span class="value">
              {{ successedTarget }}
            </span>
            recipients...
          </p>
        </mat-card>

        <div class="text-center animate button-row">
          <button class="" [disabled]="completCompaignButton != true" (click)="goBackStep()" mat-raised-button
                  color="accent" type="button">
            <mat-icon>chevron_left</mat-icon>
            Wait! I need to review
          </button>

          <button class="" [disabled]="completCompaignButton != true" (click)="completCompaign()" mat-raised-button
                  color="primary" type="button">
            Let it fly!
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <!-- The End of Second Wizard Step for Create Marketing Campaign -->

    <aw-wizard-completion-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Send!">
      <!--  Step 3 - finished -->
      <div class="container" id="step-4" *ngIf="isSent" @ngIfAnimation>
        <div class="animate clearfix">
          <div class="row clearfix">
            <mat-card class="card successful">
              <div class="row clearfix">
                <div class="full-width text-center">
                  <h1 class="sm-h1 sr-title">Well Done!</h1>
                  <h3 class="sm-h3 rm-m">Marketing Campaign Activated</h3>
                  <div class="row clearfix">
                    <div class="svg-box">
                      <svg class="circular green-stroke">
                        <circle class="path" cx="75" cy="75" r="50" fill="none" stroke-width="5"
                                stroke-miterlimit="10"/>
                      </svg>
                      <svg class="checkmark green-stroke">
                        <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                          <path class="checkmark__check" fill="none"
                                d="M616.306,283.025L634.087,300.805L673.361,261.53"/>
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>

                <div class="row full-width clearfix" *ngIf="createdCampaign">
                  <div class="row clearfix">
                    <mat-list class="statistics">
                      <mat-list-item *ngIf="delayDate == true">
                        <p mdLine>
                          <span class="labelName"> When to send </span>
                          <span class="value">
                            {{ createdCampaign['WhenToSend'] | customDateTime }}
                          </span>
                        </p>
                      </mat-list-item>
                      <mat-list-item *ngIf="delayDate == false">
                        <p mdLine>
                          <span class="labelName"> When to send </span>
                          <span class="value"> IMMEDIATELY </span>
                        </p>
                      </mat-list-item>
                      <mat-list-item>
                        <p mdLine>
                          <span class="labelName"> {{ "KEYWORD.patient" | translate | titlecase}}s targeted </span>
                          <span class="value">
                            {{ successedTarget }}
                          </span>
                        </p>
                      </mat-list-item>

                      <mat-list-item>
                        <p mdLine>
                          <span class="labelName"> {{ "KEYWORD.patient" | translate | titlecase}}s excluded </span>
                          <span class="value">
                            {{ failedTarget }}
                          </span>
                        </p>
                      </mat-list-item>

                      <mat-list-item>
                        <p class="subStatscontainer" mdLine>
                          <span class="labelName">
                            <strong> Total </strong>
                          </span>
                          <span class="value">
                            <strong> {{ allTarget }}</strong>
                          </span>
                        </p>
                      </mat-list-item>
                    </mat-list>
                  </div>
                </div>
              </div>
            </mat-card>

            <mat-card class="card successful" *ngIf="
                (createdCampaign['Status.Code'] == 'CMPL' || createdCampaign['Status.Code'] == 'ACTV') &&
                isModuleMarketingSocialMediaActive == true
              ">
              <div class="row clearfix">
                <h3 class="sr-title rm-m sm-h3">Share to social media</h3>
                <hr/>
                <p>
                  You can share this campaign to your social media account by using the assets we've provided for you.
                </p>
                <button class="" (click)="viewSocialMedia(createdCampaign['ID'])" mat-raised-button color="accent">
                  Share this campaign now
                  <mat-icon>share</mat-icon>
                </button>
              </div>
            </mat-card>
          </div>

          <div class="row clearfix text-center button-row">
            <button class="step-back" [routerLink]="['/merchant/', { outlets: { page: ['marketing-over-view'] } }]"
                    mat-raised-button color="primary">
              <mat-icon>keyboard_arrow_left</mat-icon> Marketing Campaign Dashboard
            </button>

            <button class="step-next" *ngIf="campaignID != null" (click)="viewCampaign()" mat-raised-button
                    color="accent">
              <mat-icon>visibility</mat-icon>
              View Campaign
            </button>
          </div>
        </div>
      </div>

      <!--  / Step 3  -->
    </aw-wizard-completion-step>
  </aw-wizard>
</div>

<div *ngIf="campaignID != null && displayCampaignReview == true">
  <app-marketing-campaign-view [campaignID]="campaignID" [showBack]="false"></app-marketing-campaign-view>

  <div class="row clearfix text-center">
    <button class="step-back" [routerLink]="['/merchant/', { outlets: { page: ['marketing-over-view'] } }]"
            mat-raised-button color="primary">
      <mat-icon>keyboard_arrow_left</mat-icon>Marketing Campaign Dashboard
    </button>
  </div>
</div>
