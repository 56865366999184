<div class="rel editDetails">
  <mat-card class="card">
    <div class="card-header primary-gradient-img clearfix inModal">
      <div class="row clearfix">
        <!-- Modal dismiss button -->
        <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModal()">
          <mat-icon>clear</mat-icon>
        </button>

        <div class="row titleArea clearfix full-width">
          <app-ep-document-view-image
            class="customer patientView userIconView pull-left"
            [defaultPicture]="
              'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
            "
            [link]="profileLink"
          >
          </app-ep-document-view-image>
          <h2 class="summary-header rm-m white" *ngIf="customer">
            {{ customer.FirstName }} {{ customer.Name }}
            <span class="subLabel">Edit {{ "KEYWORD.patient" | translate | titlecase }}</span>
          </h2>
        </div>
      </div>
    </div>

    <form #customerDetails="ngForm" *ngIf="customer">
      <mat-dialog-content class="contain">
        <h2 class="mat-h2 sr-title">Personal Details</h2>
        <div class="row clearfix">
          <!-- <div class="column"> -->
          <div class="row clearfix flex">
            <div class="icons-col">
              <mat-icon>person</mat-icon>
            </div>
            <div class="row-content-col">
              <div class="row clearfix flex">
                <mat-select [(ngModel)]="customer.Salutation" placeholder="Title" name="title">
                  <mat-option *ngFor="let title of titles" [value]="title.Label">
                    {{ title.Label }}
                  </mat-option>
                </mat-select>
                <!-- <mat-form-field class="full-width" appearance="outline">
                  <input name="title" matInput placeholder="Title" [(ngModel)]="customer.Salutation" required>
                </mat-form-field> -->
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>First Name</mat-label>
                  <input [(ngModel)]="customer.FirstName" name="firstname" matInput placeholder="First Name" required/>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Middle Name</mat-label>
                  <input [(ngModel)]="customer.MiddleName" name="middlename" matInput placeholder="Middle Name"/>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Surname</mat-label>
                  <input [(ngModel)]="customer.Name" name="lastname" matInput placeholder="Surname" required/>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row clearfix flex">
            <div class="icons-col">
              <mat-icon>today</mat-icon>
            </div>
            <div class="row-content-col">
              <app-date-input
                class="qtr-width"
                [startView]="'multi-year'"
                [label]="'Date Of Birth'"
                [defaultDate]="myBirthdayDefault"
                (selectedDate)="getDate($event)"
                name="DateOfBirth"
              ></app-date-input>
            </div>
            <!-- <div class="icons-col">
              <mat-icon>store</mat-icon>
          </div>
          <mat-form-field class="full-width" appearance="outline">
            <input name="tradingas" matInput placeholder="Trading As" [(ngModel)]="customer.TradingAs" required>
          </mat-form-field> -->
          </div>
        </div>

        <h2 class="mat-h2 sr-title">Contact Details</h2>
        <div class="row clearfix">
          <div class="row clearfix flex">
            <div class="icons-col">
              <mat-icon>phone</mat-icon>
            </div>
            <div class="row-content-col">
              <div class="row clearfix flex">
                <app-phone-input
                  class="full-width"
                  [phoneNumber]="customer['phones.Number']"
                  [isLandLine]="true"
                  [label]="'Phone Number'"
                  [required]="true"
                  (validate)="validatePhoneEvent($event)"
                  (getPhone)="getPhone($event)"
                >
                </app-phone-input>
                <app-phone-input
                  class="full-width"
                  [phoneNumber]="customer['mobiles.Number']"
                  [isLandLine]="false"
                  [label]="'Mobile'"
                  [required]="true"
                  (validate)="validateMobileEvent($event)"
                  (getPhone)="getMobile($event)"
                >
                </app-phone-input>
                <app-email-input
                  class="full-width"
                  [email]="customer['emails.Email']"
                  [label]="'Email'"
                  [required]="true"
                  (getEmail)="getEmail($event)"
                  (validate)="validateEmailEvent($event)"
                >
                </app-email-input>
              </div>
            </div>
          </div>
        </div>
        <!-- referral -->
        <div class="row clearfix" *ngIf="isConcumer == false">
          <div class="icons-col">
            <mat-icon>record_voice_over</mat-icon>
          </div>
          <div class="row-content-col">
            <div class="margin-btm ml">
              <mat-checkbox [(ngModel)]="respectMarketing" name="respectMarketing" color="primary">
                Referred {{"KEYWORD.patient" | translate}}?
              </mat-checkbox>
            </div>
          </div>
        </div>

        <h2 class="mat-h2 sr-title">Address Details</h2>
        <div class="row clearfix">
          <app-address-input
            [displayMap]="true"
            [postCode]="myPostCode"
            [state]="myState"
            [suburb]="mySuburb"
            [streetNumber]="myStreetNumber"
            [streetName]="myStreetName"
            [unitNumber]="myUnitNumber"
            [streetType]="myStreetType"
            [latitude]="myLatitude"
            [longitude]="myLongitude"
            (getAdress)="getAdress($event)"
            (validate)="isAdressValidEvent($event)"
          ></app-address-input>
        </div>

        <!-- patient profile image upload block -->
        <h2 class="mat-h2 sr-title">Upload profile picture</h2>
        <p class="small">
          To upload a profile picture, please drag a <strong>.jpg or .png file</strong> into the grey area below.
        </p>
        <div class="row clearfix mb">
          <app-file-uploader
            class="text-center clearfix full-width noLowerPad"
            [allowedExtensionTypes]="['image']"
            [limit]="1"
            [uploadDirectly]="true"
            [isDragable]="true"
            (onCompleteAll)="onCompleteAll($event)"
          >
          </app-file-uploader>
        </div>
        <!-- / patient profile image upload block -->
      </mat-dialog-content>
      <div class="full-width text-center">
        <!-- <button mat-raised-button (click)="editDetail()" color="accent" [disabled]="!customerDetails.form.valid || isAdressValid==false || validateEmail==false || validatePhone==false">Save</button> -->
        <button (click)="editDetail()" mat-raised-button color="accent">Save</button>
      </div>
    </form>
  </mat-card>
</div>
