<mat-dialog-content class="modal-image-scrollable">
  <div class="modal-image image-{{ step }}">
    <button class="btn-close btn-clear mat-button white" (click)="close()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <div class="wrapper row clearfix">
    <div class="starBar text-center bubble" *ngIf="step == 2">
      <like-animation [anim]="anim" [type]="'star'"></like-animation>
      <mat-icon class="fas fa-star default"></mat-icon>
      <mat-icon class="fas fa-star default"></mat-icon>
    </div>
    <div class="starBar text-center bubble" *ngIf="step == 3">
      <mat-icon class="fas fa-star"></mat-icon>
      <like-animation [anim]="anim" [type]="'star'"></like-animation>
      <mat-icon class="fas fa-star default"></mat-icon>
    </div>
    <div class="starBar text-center bubble" *ngIf="step == 4">
      <mat-icon class="fas fa-star"></mat-icon>
      <mat-icon class="fas fa-star"></mat-icon>
      <like-animation [anim]="anim" [type]="'star'"></like-animation>
    </div>

    <div class="row clearfix text-center" #step1 *ngIf="step == 1">
      <h2 class="sr-title sm-h2 bubble">Let's get started!</h2>
      <p>
        The following couple of steps will ask you to confirm some basic details like your name and date of birth, so we
        know we're speaking to the right person.
      </p>
      <p>
        If you are a parent or someone providing assistance to someone who can't complete this form themselves, you'll
        be asked to confirm this too.
      </p>
    </div>

    <div class="row clearfix text-center" #step2 *ngIf="step == 2">
      <h2 class="sr-title sm-h2">Cool, let's talk about your health</h2>
      <p>
        It can be important for your  {{ "KEYWORD.practitioner" | translate }} to know the whole story when it comes to
        your health. The following
        few questions will just let us know of anything important that needs to be considered for your treatment.
      </p>
    </div>

    <div class="row clearfix text-center" #step3 *ngIf="step == 3">
      <h2 class="sr-title sm-h2">Okay, now we'll talk about your lifestyle</h2>
      <p>
        The next few questions will help us better determine how your current lifestyle might be impacting your smile...
      </p>
    </div>

    <div class="row clearfix text-center" #step3 *ngIf="step == 4">
      <h2 class="sr-title sm-h2">Nice! Now let's see about your smile</h2>
      <p>Well, that's the boring bit out of the way!</p>
      <p>
        Now let's find out about that smile of yours... And what we need to think about to get you the smile you've
        always been dreaming of!
      </p>
    </div>
  </div>
</mat-dialog-content>
<div class="row clearfix text-center">
  <button class="full-width mt" (click)="close()" mat-raised-button color="accent">
    Okay!
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>
