import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';


@Injectable()
export class CardService {


  constructor(
    private http: HttpClient,
  ) {}

  getCustomerProspectProfilePicStreamLink(ID, LastModified = null) {
    if (
      LastModified &&
      LastModified != '' &&
      LastModified != undefined &&
      LastModified != 'null' &&
      LastModified != 'undefined '
    ) {
      return `${environment.nodeUrl}/customer-prospect/profile-picture-stream-cached/${LastModified}/` + ID;
    } else if (ID) {
      return `${environment.nodeUrl}/customer-prospect/profile-picture-stream/` + ID;
    }
    return null;
  }
  modifyCustomerProspect(ID = null, payload, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .put(environment.nodeUrl + '/customer-prospect/global/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .put(environment.nodeUrl + '/customer-prospect/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer') {
      return this.http
        .put(environment.nodeUrl + '/customer-prospect/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/customer-prospect/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  addCoordinate(ID, body, tableName) {
    if (tableName === 'Contact') {
      return this.http
        .put(environment.nodeUrl + '/contact/coordinate/' + ID, body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (tableName === 'Merchant') {
      return this.http
        .put(environment.nodeUrl + '/merchant/coordinate/' + ID, body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (tableName === 'Customer') {
      return this.http
        .put(environment.nodeUrl + '/customer/coordinate/' + ID, body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (tableName === 'Prospect') {
      return this.http
        .put(environment.nodeUrl + '/prospect/coordinate/' + ID, body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/prospect/coordinate/' + ID, body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  editCustomer(ID, payload): Observable<any> {
    return this.http
      .put(environment.nodeUrl + '/customer/' + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editProspect(ID, payload): Observable<any> {
    return this.http
      .put(environment.nodeUrl + '/prospect/' + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }
  getCardDetails(ID, tableName, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (tableName === 'Contact') {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.http.get(environment.nodeUrl + '/contact/' + ID, options).pipe(
          map((_res: HttpResponse<any>) => {
            const res = _res;
            if (res && res['data']) {
              res['data']['TableName'] = tableName;
              res['data']['tableName'] = tableName;
            }

            return res['data'];
          })
        );
      } else {
        return this.http.get(environment.nodeUrl + '/contact/public/' + ID, options).pipe(
          map((_res: HttpResponse<any>) => {
            const res = _res;
            if (res && res['data']) {
              res['data']['TableName'] = tableName;
              res['data']['tableName'] = tableName;
            }

            return res['data'];
          })
        );
      }
    } else if (tableName === 'Merchant' || tableName === 'Promoter') {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.http.get(environment.nodeUrl + '/merchant/' + ID, options).pipe(
          map((_res: HttpResponse<any>) => {
            const res = _res;
            if (res && res['data']) {
              res['data']['TableName'] = tableName;
              res['data']['tableName'] = tableName;
            }

            return res['data'];
          })
        );
      } else {
        return this.http.get(environment.nodeUrl + '/merchant/public/' + ID, options).pipe(
          map((_res: HttpResponse<any>) => {
            const res = _res;
            if (res && res['data']) {
              res['data']['TableName'] = tableName;
              res['data']['tableName'] = tableName;
            }

            return res['data'];
          })
        );
      }
    } else {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.http.get(environment.nodeUrl + '/customer-prospect/global/' + ID, options).pipe(
          map((_res: HttpResponse<any>) => {
            const res = _res;
            if (res && res['data']) {
              res['data']['TableName'] = 'CustomersAndProspects';
              res['data']['tableName'] = 'CustomersAndProspects';
            }

            return res['data'];
          })
        );
      } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
        return this.http.get(environment.nodeUrl + '/customer-prospect/' + ID, options).pipe(
          map((_res: HttpResponse<any>) => {
            const res = _res;
            if (res && res['data']) {
              res['data']['TableName'] = 'CustomersAndProspects';
              res['data']['tableName'] = 'CustomersAndProspects';
            }

            return res['data'];
          })
        );
      } else {
        return this.http.get(environment.nodeUrl + '/customer-prospect/public/' + ID, options).pipe(
          map((_res: HttpResponse<any>) => {
            const res = _res;
            if (res && res['data']) {
              res['data']['TableName'] = tableName;
              res['data']['TableName'] = 'CustomersAndProspects';
              res['data']['tableName'] = 'CustomersAndProspects';
            }

            return res['data'];
          })
        );
      }
    }
  }

  getCardDetailsPublicHidden(ID, tableName, payload = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (tableName === 'Contact') {
      return this.http.get(environment.nodeUrl + '/contact/hidden/public/' + ID, options).pipe(
        map((_res: HttpResponse<any>) => {
          const res = _res;
          if (res && res['data']) {
            res['data']['TableName'] = tableName;
            res['data']['tableName'] = tableName;
          }

          return res['data'];
        })
      );
    } else if (tableName === 'Merchant' || tableName === 'Promoter') {
      return this.http.get(environment.nodeUrl + '/merchant/hidden/public/' + ID, options).pipe(
        map((_res: HttpResponse<any>) => {
          const res = _res;
          if (res && res['data']) {
            res['data']['TableName'] = tableName;
            res['data']['tableName'] = tableName;
          }

          return res['data'];
        })
      );
    } else {
      return this.http.get(environment.nodeUrl + '/customer-prospect/hidden/public/' + ID, options).pipe(
        map((_res: HttpResponse<any>) => {
          const res = _res;
          if (res && res['data']) {
            res['data']['TableName'] = tableName;
            res['data']['TableName'] = 'CustomersAndProspects';
            res['data']['tableName'] = 'CustomersAndProspects';
          }

          return res['data'];
        })
      );
    }
  }

  getProfilePicStreamLink(ID, tableName, LastModified = null) {
    if (tableName === 'Contact') {
      if (
        ID &&
        LastModified &&
        LastModified != '' &&
        LastModified != undefined &&
        LastModified != 'null' &&
        LastModified != 'undefined '
      ) {
        return `${environment.nodeUrl}/contact/profile-picture-stream-cached/${LastModified}/` + ID;
      } else if (ID) {
        return `${environment.nodeUrl}/contact/profile-picture-stream/` + ID;
      }
      return null;
    } else if (tableName === 'Merchant' || tableName === 'Promoter') {
      if (
        ID &&
        LastModified &&
        LastModified != '' &&
        LastModified != undefined &&
        LastModified != 'null' &&
        LastModified != 'undefined '
      ) {
        return `${environment.nodeUrl}/merchant/practice-picture-stream-cached/${LastModified}/` + ID;
      } else if (ID) {
        return `${environment.nodeUrl}/merchant/practice-picture-stream/` + ID;
      }
      return null;
    } else {
      if (
        ID &&
        LastModified &&
        LastModified != '' &&
        LastModified != undefined &&
        LastModified != 'null' &&
        LastModified != 'undefined '
      ) {
        return `${environment.nodeUrl}/customer-prospect/profile-picture-stream-cached/${LastModified}/` + ID;
      } else if (ID) {
        return `${environment.nodeUrl}/customer-prospect/profile-picture-stream/` + ID;
      }
      return null;
    }
  }

  modifyCard(ID = null, payload,tableName, sessionType): Observable<any> {

    if (tableName === 'Contact') {

      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.http
          .put(environment.nodeUrl + '/contact/edit/global/' + ID, payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
        return this.http
          .put(environment.nodeUrl + '/contact/edit/' + ID, payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else if (sessionType == 'customer') {
        return this.http
          .put(environment.nodeUrl + '/contact/customer', payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.http
          .put(environment.nodeUrl + '/contact/edit/' + ID, payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }

    }
    else {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.http
          .put(environment.nodeUrl + '/customer-prospect/global/' + ID, payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
        return this.http
          .put(environment.nodeUrl + '/customer-prospect/' + ID, payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else if (sessionType == 'customer') {
        return this.http
          .put(environment.nodeUrl + '/customer-prospect/customer', payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.http
          .put(environment.nodeUrl + '/customer-prospect/' + ID, payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }

  }



  getCardList( tableName, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (tableName === 'Contact') {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.http.get(environment.nodeUrl + '/contact/global' , options).pipe(
          map((_res: HttpResponse<any>) => {
            const res = _res;
            if (res && res['data']) {
              res['data']['TableName'] = tableName;
              res['data']['tableName'] = tableName;
            }

            return res['data'];
          })
        );
      } else {
        return this.http.get(environment.nodeUrl + '/contact' , options).pipe(
          map((_res: HttpResponse<any>) => {
            const res = _res;
            if (res && res['data']) {
              res['data']['TableName'] = tableName;
              res['data']['tableName'] = tableName;
            }

            return res['data'];
          })
        );
      }
    } else if (tableName === 'Merchant' || tableName === 'Promoter') {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.http.get(environment.nodeUrl + '/merchant' , options).pipe(
          map((_res: HttpResponse<any>) => {
            const res = _res;
            if (res && res['data']) {
              res['data']['TableName'] = tableName;
              res['data']['tableName'] = tableName;
            }

            return res['data'];
          })
        );
      } else {
        return this.http.get(environment.nodeUrl + '/merchant/public' , options).pipe(
          map((_res: HttpResponse<any>) => {
            const res = _res;
            if (res && res['data']) {
              res['data']['TableName'] = tableName;
              res['data']['tableName'] = tableName;
            }

            return res['data'];
          })
        );
      }
    } else {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.http.get(environment.nodeUrl + '/customer-prospect/global' , options).pipe(
          map((_res: HttpResponse<any>) => {
            const res = _res;
            if (res && res['data']) {
              res['data']['TableName'] = 'CustomersAndProspects';
              res['data']['tableName'] = 'CustomersAndProspects';
            }

            return res['data'];
          })
        );
      } else  {
        return this.http.get(environment.nodeUrl + '/customer-prospect' , options).pipe(
          map((_res: HttpResponse<any>) => {
            const res = _res;
            if (res && res['data']) {
              res['data']['TableName'] = 'CustomersAndProspects';
              res['data']['tableName'] = 'CustomersAndProspects';
            }

            return res['data'];
          })
        );
      }
    }
  }
}
