<div class="clearfix" *ngIf="isAdminOrPromoter == true">
  <div class="row clearfix well selectMerchant" *ngIf="isDisplayMerchant == true">
    <button
      class="pull-right merchBtn"
      *ngIf="merchantID && merchantID != 'none'"
      (click)="viewMerchant()"
      mat-raised-button
      color="primary"
    >
      <mat-icon>person</mat-icon>
      <span class="mobHide">View Merchant</span>
    </button>

    <mat-form-field class="margin-btm half-width" appearance="outline">
      <mat-label>Please Select a Merchant</mat-label>
      <mat-select class="" [(ngModel)]="merchantID" name="MerchantName" placeholder="Please Select a Merchant">
        <mat-option [value]="null"> All</mat-option>
        <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m['ID']">
          <span
            class="list-label pull-right"
            *ngIf="m['Status'] && m['Status'] == 'Active'"
            [style.background]="'#00d07a'"
          >
            {{ m['Status'] }}
          </span>

          <span
            class="list-label pull-right"
            *ngIf="m['Status'] && m['Status'] != 'Active'"
            [style.background]="'#ff5722'"
          >
            {{ m['Status'] }}
          </span>
          {{ m['TradingAs'] || m['CalculatedName'] }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="row clearfix">
    <app-customer-prospect-list-map
      *ngIf="merchantID != 'none'"
      [merchantID]="merchantID"
    ></app-customer-prospect-list-map>
  </div>
  <div class="row clearfix" [hidden]="merchantID != 'none'">
    <div class="pleaseChoose" *ngIf="merchantID == 'none'" @ngIfAnimation>
      <p>
        <strong>No merchant selected</strong>
      </p>
      <img class="character_warning" />
      <p>
        Choose your desired merchant from the drop-down above to view their
        {{ "KEYWORD.patient" | translate }}s
      </p>
    </div>
  </div>
</div>

<div class="clearfix" *ngIf="isAdminOrPromoter == false">
  <app-customer-prospect-list-map id="part1"></app-customer-prospect-list-map>
</div>
