<footer class="white-bg clearfix">
  <div class="pull-left">
    <p class="disclaimer" id="disclaimer">Copyright 2017 {{ "BRANDING.Brand Name" | translate | titlecase }} Pty Ltd</p>
  </div>
  <div class="pull-right">
    <button (click)="saveAsDefault()" mat-button>
      <mat-icon>sd_storage</mat-icon>
      Save as Default
    </button>

    <button (click)="initialize()" mat-button color="accent">
      <mat-icon>autorenew</mat-icon>
      Reset
    </button>

    <button class="onlineMode" *ngIf="siteIsOnline != false" (click)="setSiteOffline()" mat-button
            matTooltip="The site is Online , Click to change the mode to Offline">
      <mat-icon>link_off</mat-icon>
      Offline mode
    </button>

    <button class="offlineMode" *ngIf="siteIsOnline == false" (click)="setSiteOnline()" mat-button
            matTooltip="The site is  Offline , Click to change the mode to Online">
      <mat-icon>link</mat-icon>
      Online mode
    </button>
  </div>
</footer>
