<div class="row clearfix" *ngIf="isPromoterOrAdmin == true">
  <app-operator-filter
    class="filter"
    id="part1"
    *ngIf="hideFilter == false"
    [displayMerchant]="true"
    [displayDateRange]="false"
    [displayDateFrom]="false"
    [displayTimePicker]="false"
    [displayDateTo]="false"
    [displayDentist]="false"
    (search)="applySearch($event)"
  >
  </app-operator-filter>

  <div class="row clearfix">
    <app-marketing-filter-list-global
      *ngIf="merchantID != 'none'"
      [merchantID]="merchantID"
    ></app-marketing-filter-list-global>
  </div>
  <div class="row clearfix">
    <div class="pleaseChoose" *ngIf="merchantID == 'none'" @ngIfAnimation>
      <p>
        <strong>No Merchant Selected</strong>
      </p>
      <img class="character_warning" />
      <p>Choose Desired Merchant</p>
    </div>
  </div>
</div>

<div class="row clearfix" *ngIf="isPromoterOrAdmin == false">
  <app-marketing-filter-list></app-marketing-filter-list>
</div>
