import { Component, OnInit } from '@angular/core';
import { ICONS } from './icons';

@Component({
  selector: 'admin-communication-settings',
  templateUrl: './admin-communication-settings.component.html',
  styleUrls: ['./admin-communication-settings.component.css'],
})
export class AdminCommunicationSettingsComponent implements OnInit {
  products = [
    { value: 'product-1', viewValue: 'Product 1' },
    { value: 'product-2', viewValue: 'Product 2' },
    { value: 'product-3', viewValue: 'Product 3' },
  ];

  icons = ICONS;

  defaultLanguages = [];
  defaultCurrencys = [];
  defaultLanguage = {};
  defaultCurrency = {};

  constructor() {}

  ngOnInit() {}

  lendingOptionsLoadup() {}

  generalOptionsLoadup() {}

  addOptionTag() {}

  loadTemplateEditor() {}

  confirmChanges() {}
}
