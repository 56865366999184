<ng-container *ngIf="viewModel$ | async as viewModel">
  <form #productForm="ngForm">
    <h2 class="sr-title mt" style="text-align: left; font-weight: bold">Information</h2>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Supplier</mat-label>

      <mat-select [(ngModel)]="product.Supplier_key" name="supplierKey" placeholder="Supplier" required>
        <mat-option *ngFor="let supplier of suppliers" [value]="supplier.ID">
          {{ supplier.TradingAs | titlecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Product Type</mat-label>

      <mat-select [(ngModel)]="product.Product_Type_key" name="productType" placeholder="Product Type" required>
        <mat-option value="N/A"> N/A </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Product Category</mat-label>

      <mat-select
        [(ngModel)]="product.Product_Category_key"
        name="productCategory"
        placeholder="Product Category"
        required
      >
        <mat-option value="N/A"> N/A </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Product Label</mat-label>
      <input
        [(ngModel)]="product.Product"
        (ngModelChange)="setFileName()"
        matInput
        name="product"
        placeholder="Product"
        required
      />
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Model</mat-label>

      <input [(ngModel)]="product.Model" matInput name="model" placeholder="model" required/>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Stock Keeping Unit</mat-label>
      <input [(ngModel)]="product.Stock_Keeping_Unit" matInput name="sku" placeholder="sku" required/>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Universal Product Code</mat-label>
      <input [(ngModel)]="product.Universal_Product_Code" matInput name="upc" placeholder="upc" required/>
    </mat-form-field>

    <div class="full-width mt mb" style="display: flex; justify-content: space-between">
      <mat-slide-toggle
        [(ngModel)]="Is_SerialNum_Mandatory"
        (change)="setIsSerialNumMandetory($event.checked)"
        name="isSerialNumMandatory"
      >
        <span class="sr-title" style="font-weight: bold; font-size: medium"> Serial Number Required </span>
      </mat-slide-toggle>

      <mat-slide-toggle [(ngModel)]="Is_Available" (change)="setIsAvailable($event.checked)" name="isAvailable">
        <span class="sr-title" style="font-weight: bold; font-size: medium"> Product Available </span>
      </mat-slide-toggle>

      <mat-slide-toggle style="visibility: hidden">
        <span class="sr-title" style="font-weight: bold; font-size: large"> Placeholder </span>
      </mat-slide-toggle>
    </div>

    <h2 class="sr-title mt" style="text-align: left; font-weight: bold">Pricing</h2>

    <div class="full-width" style="display: flex; justify-content: space-between">
      <mat-form-field class="full-width mr" appearance="outline">
        <mat-label>Cost Price</mat-label>
        <input
          [(ngModel)]="product.CostPrice"
          [options]="{ align: 'left' }"
          currencyMask
          matInput
          placeholder="Please enter a value"
          name="costPrice"
          required
        />
      </mat-form-field>

      <mat-form-field class="full-width mr" appearance="outline">
        <mat-label>Sell Price</mat-label>
        <input
          [(ngModel)]="product.SellPrice"
          [options]="{ align: 'left' }"
          currencyMask
          matInput
          name="sellPrice"
          placeholder="Please enter a value"
          required
        />
      </mat-form-field>

      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Tax Rate</mat-label>
        <input
          [(ngModel)]="product.TaxRate"
          [options]="{ align: 'left' }"
          currencyMask
          matInput
          placeholder="Please enter a value"
          name="taxRate"
          required
        />
      </mat-form-field>
    </div>

    <h2 class="sr-title" style="text-align: left; font-weight: bold">Description</h2>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Description</mat-label>
      <textarea
        class="textarea-control-smaller"
        [(ngModel)]="product.Description"
        matInput
        placeholder="description"
        name="description"
      ></textarea>
    </mat-form-field>

    <mat-form-field class="full-width mr" appearance="outline">
      <mat-label>Size</mat-label>
      <input [(ngModel)]="product.Size" matInput name="size" placeholder="Size"/>
    </mat-form-field>

    <mat-form-field class="full-width mr" appearance="outline">
      <mat-label>Colour</mat-label>
      <input [(ngModel)]="product.Colour" matInput name="colour" placeholder="Colour"/>
    </mat-form-field>

    <mat-form-field class="full-width mr" appearance="outline">
      <mat-label>Weight</mat-label>
      <input [(ngModel)]="product.Weight" matInput name="weight" placeholder="Weight"/>
    </mat-form-field>

    <mat-form-field class="full-width mr" appearance="outline">
      <mat-label>Width</mat-label>
      <input [(ngModel)]="product.Width" matInput name="width" placeholder="Width"/>
    </mat-form-field>

    <mat-form-field class="full-width mr" appearance="outline">
      <mat-label>Height</mat-label>
      <input [(ngModel)]="product.Height" matInput name="height" placeholder="Height"/>
    </mat-form-field>

    <mat-form-field class="full-width mr" appearance="outline">
      <mat-label>Length</mat-label>
      <input [(ngModel)]="product.Length" matInput name="length" placeholder="Length"/>
    </mat-form-field>

    <mat-form-field class="full-width mr" appearance="outline">
      <mat-label>Manufactured Year</mat-label>
      <input [(ngModel)]="product.Manufactured_Year" matInput name="manufacturedYear" placeholder="Manufactured Year"/>
    </mat-form-field>

    <ng-container *ngIf="fileName">
      <h2 class="sr-title mt" style="text-align: left; font-weight: bold">Image Upload</h2>

      <div class="row clearfix flex mb full-width">
        <!--  PROFILE IMAGE UPLOAD -->
        <div class="uploadBlock right full-width">
          <div class="row clearfix logo-img image">
            <!-- profile not uploaded (create)-->
            <div class="notUploaded" *ngIf="!hasProfileImage && formMode === 'create'">
              <app-ep-document-view-image
                class="clearfix notYetUploaded"
                [defaultPicture]="
                  'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cloud-upload-alt-solid.png)'
                "
              >
              </app-ep-document-view-image>
            </div>

            <!-- profile not uploaded (edit)-->
            <div class="notYetUploaded" *ngIf="!hasProfileImage && formMode === 'update'">
              <app-ep-document-view-image
                class="clearfix full-width notYetUploaded"
                [defaultPicture]="
                  'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cloud-upload-alt-solid.png)'
                "
                [link]="existingProfileImageSrc"
                [hideZoomButton]="false"
              >
              </app-ep-document-view-image>
            </div>

            <div class="displayUploadImg mt" *ngIf="hasProfileImage">
              <img class="display-image-uploaded" [src]="existingProfileImageSrc"/>
            </div>
          </div>
          <div class="row clearfix">
            <h4 class="sr-title sm-h4 rm-mb">Upload Profile Image</h4>
            <app-file-uploader
              class="text-center clearfix full-width"
              [isDisabled]="product.Product.length === 0"
              [allowedExtensionTypes]="['image']"
              [isFixedAspectRatio]="true"
              [isImageCropping]="true"
              [canSkip]="false"
              [limit]="1"
              [uploadDirectly]="true"
              [isDragable]="true"
              [title]="'CLICK HERE to upload'"
              (onCompleteAll)="setProfileImage($event)"
            >
            </app-file-uploader>
          </div>
        </div>

        <!--  THUMBNAIL IMAGE UPLOAD -->
        <div class="uploadBlock left full-width">
          <div class="row clearfix logo-img image">
            <!-- thumbnail not uploaded (create)-->
            <div class="notUploaded" *ngIf="!hasThumbnail && formMode === 'create'">
              <app-ep-document-view-image
                class="clearfix notYetUploaded"
                [defaultPicture]="
                  'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cloud-upload-alt-solid.png)'
                "
              >
              </app-ep-document-view-image>
            </div>

            <!-- thumbnail not uploaded (edit)-->
            <div class="notYetUploaded" *ngIf="!hasThumbnail && formMode === 'update'">
              <app-ep-document-view-image
                class="clearfix notYetUploaded"
                [defaultPicture]="
                  'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cloud-upload-alt-solid.png)'
                "
                [link]="existingThumbnailSrc"
                [hideZoomButton]="false"
              >
              </app-ep-document-view-image>
            </div>

            <div class="displayUploadImg mt" *ngIf="hasThumbnail">
              <img class="display-image-uploaded" [src]="existingThumbnailSrc"/>
            </div>
          </div>
          <div class="row clearfix">
            <h4 class="sr-title sm-h4 rm-mb">Upload Thumbnail</h4>
            <app-file-uploader
              class="text-center clearfix full-width"
              [isDisabled]="product.Product.length === 0"
              [allowedExtensionTypes]="['image']"
              [isFixedAspectRatio]="true"
              [isImageCropping]="true"
              [canSkip]="false"
              [limit]="1"
              [uploadDirectly]="true"
              [isDragable]="true"
              [title]="'CLICK HERE to upload'"
              (onCompleteAll)="setThumbnailImage($event)"
            >
            </app-file-uploader>
          </div>
        </div>
      </div>

      <!--  PICTURE UPLOAD -->
      <div class="uploadBlock full-width" style="margin: 0 !important">
        <div class="row clearfix">
          <h4 class="sr-title sm-h4 rm-mb">Upload Images</h4>
          <app-file-uploader
            class="text-center clearfix full-width"
            [isDisabled]="product.Product.length === 0"
            [fileName]="fileName"
            [allowedExtensions]="['png', 'jpg', 'jpeg']"
            [allowedExtensionTypes]="['image']"
            [documentType]="'Image'"
            [isImageCropping]="true"
            [canSkip]="false"
            [uploadDirectly]="true"
            [isDragable]="true"
            [title]="'CLICK HERE to upload'"
            (onCompleteAll)="addAdditionalImage($event)"
          >
          </app-file-uploader>
        </div>

        <div class="clearfix" style="width: 50%; display: block; margin: 20px auto 0px auto">
          <app-file-list
            class="text-center centered"
            [documentTypes]="['Image']"
            [canAction]="false"
            [canEdit]="true"
            [hasExtraInfo]="false"
            [files]="existingAdditionalImages"
            [hasResponsiveWidth]="true"
            (remove)="removeFile($event)"
          ></app-file-list>
        </div>
      </div>
    </ng-container>
  </form>

  <div class="row clearfix text-center button-row mt">
    <button class="" (click)="close()" mat-raised-button color="primary">Close</button>
    <button
      class="ml"
      *ngIf="formMode === 'create'"
      [disabled]="!productForm.form.valid || !hasThumbnail || !hasProfileImage"
      (click)="create()"
      mat-raised-button
      color="accent"
    >
      Save
      <mat-icon>check</mat-icon>
    </button>

    <button
      class="ml"
      *ngIf="formMode === 'update'"
      [disabled]="!productForm.form.valid || !hasThumbnail || !hasProfileImage"
      (click)="update()"
      mat-raised-button
      color="accent"
    >
      Update
      <mat-icon>check</mat-icon>
    </button>
  </div>
</ng-container>
