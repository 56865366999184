<div class="rel clearfix lookup">
  <form #msgForm="ngForm">
    <div
      class="card-header primary-gradient-img clearfix inModal"
      [attr.style]="
        'background:linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
      "
      [attr.style]="
        'background:-moz-linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
      "
      [attr.style]="
        'background:-webkit-linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
      "
    >
      <div class="row clearfix">
        <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeEvent()">
          <mat-icon>clear</mat-icon>
        </button>
        <div class="row titleArea clearfix">
          <mat-icon class="pull-left fas fa-handshake mr"></mat-icon>
          <h2 class="summary-header rm-m">
            Preview {{ "KEYWORD.practice" | translate }} subscription plan
            <span class="subLabel">Choose a plan and customize user subscription</span>
          </h2>
        </div>
      </div>
    </div>

    <mat-dialog-content class="lookup-template" [ngClass]="{ 'no-modal-height': isModal != true }">
      <div class="full-width">
        <div class="full-width plan-container mb flex" *ngIf="promoterPlan && promoterPlan.ID">
          <app-ep-document-view-image
            class="subscription-plan-List-sub mr"
            [hideZoomButton]="false"
            [defaultPicture]="
              'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/light_icon2.png)'
            "
            [link]="getSubscriptionPlanImage(promoterPlan['SubscriptionPlan_key'])"
          >
          </app-ep-document-view-image>

          <div class="full-width">
            <h3 class="sr-title full-width rm-m" [attr.style]="'color:' + color + ' !important' | safeStyle">
              <button
                class="pull-right"
                *ngIf="promoterPlan && promoterPlan['ID']"
                (click)="viewSubPlan(promoterPlan['ID'])"
                color="primary"
                mat-icon-button
                matTooltip="View Subscription Plan Details"
              >
                <mat-icon>visibility</mat-icon>
              </button>
              <button
                class="pull-right"
                *ngIf="promoterPlan && promoterPlan['ID']"
                (click)="buildItemPromoter(promoterPlan)"
                color="primary"
                mat-icon-button
                matTooltip="Reset to default"
              >
                <mat-icon>refresh</mat-icon>
              </button>

              <strong> {{ promoterPlan['Web_Label'] }}</strong>
            </h3>
            <p class="small">
              {{ promoterPlan['Web_Features'] }}
            </p>

            <div class="full-width">
              <h3 class="pull-right rm-m smr" [attr.style]="'color:' + color + ' !important' | safeStyle">
                <strong> {{ promoterPlan['Price'] | customCurrency: '1.0-3' }}</strong>
              </h3>

              <span
                class="value chip-new pull-left mr"
                *ngIf="promoterPlan['Recommended_Plan'] === 'Yes'"
                [attr.style]="'background:' + color + ' !important' | safeStyle"
              >Recommended</span
              >

              <span
                class="value chip-new pull-left mr"
                *ngIf="promoterPlan['Is_Default'] === '1'"
                [attr.style]="'background:' + color + ' !important' | safeStyle"
              >Default</span
              >

              <span
                class="value chip-new pull-left mr"
                *ngIf="promoterPlan['Is_Public'] === '0'"
                [attr.style]="'background:' + color + ' !important' | safeStyle"
              >Customized</span
              >

              <span
                class="value chip-new pull-left mr"
                *ngIf="promoterPlan['Is_Public'] === '1'"
                [attr.style]="'background:' + color + ' !important' | safeStyle"
              >Public</span
              >

              <span
                class="value chip-new pull-left mr"
                *ngIf="promoterPlan['Is_SMS_Free'] === '1'"
                [attr.style]="'background:' + color + ' !important' | safeStyle"
              >Free SMS</span
              >
            </div>
          </div>
        </div>
        <hr class="mb" *ngIf="promoterPlan && promoterPlan.ID"/>
        <h3
          class="sr-title full-width"
          *ngIf="merchantName"
          [attr.style]="'color:' + color + ' !important' | safeStyle"
        >
          <strong> {{ merchantName }}</strong>
        </h3>

        <div class="full-width flex smb">
          <mat-form-field class="full-width text-center mr" appearance="outline">
            <mat-label>Price</mat-label>
            <input
              class="text-right"
              [(ngModel)]="price"
              name="price"
              matInput
              placeholder="Price"
              currencyMask
              required
            />
          </mat-form-field>

          <div class="full-width flex">
            <app-date-input
              class="full-width"
              [isTime]="false"
              [defaultDate]="startDate"
              (selectedDate)="selectDateTime($event)"
              label="Start date"
            >
            </app-date-input>
          </div>
        </div>

        <div class="full-width flex smb">
          <mat-form-field class="full-width mr" appearance="outline">
            <mat-label>Min Treatements sent</mat-label>
            <input
              class="text-right"
              [(ngModel)]="minTreatmentPlan"
              type="number"
              name="minTreatmentPlan"
              matInput
              placeholder="Min Treatements sent"
              required
            />
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Waived period (days)</mat-label>
            <input
              class="text-right"
              [(ngModel)]="waivedPeriod"
              type="number"
              matInput
              placeholder="Waived period (days)"
              name="waivedPeriod"
              required
            />
          </mat-form-field>
        </div>
        <div class="full-width flex smb">
          <mat-checkbox class="sml full-width slider-input-select mr" [(ngModel)]="isFreeSMS" name="isFreeSMS">
            Free SMS?
          </mat-checkbox>

          <mat-form-field class="full-width" *ngIf="isFreeSMS != true" appearance="outline">
            <mat-label>SMS Price</mat-label>
            <input [(ngModel)]="smsPrice" name="smsPrice" matInput placeholder="SMS Price" currencyMask required/>
          </mat-form-field>
        </div>

        <div
          class="full-width flex next-plan-parent"
          *ngIf="nextPlan && nextPlan.SubscriptionPlan_key"
          [attr.style]="'border-color:' + nextPlan.color + ' !important' | safeStyle"
        >
          <div class="full-width plan-container next-plan flex">
            <div class="full-width">
              <h3 class="sr-title full-width rm-m" [attr.style]="'color:' + nextPlan.color + ' !important' | safeStyle">
                <button
                  class="pull-right"
                  *ngIf="nextPlan && nextPlan['SubscriptionPlan_key']"
                  (click)="viewSubPlan(nextPlan['SubscriptionPlan_key'])"
                  color="primary"
                  mat-icon-button
                  matTooltip="View Subscription Plan Details"
                >
                  <mat-icon>visibility</mat-icon>
                </button>

                <button
                  class="pull-right"
                  *ngIf="nextPlan && nextPlan['ID']"
                  (click)="delete(nextPlan['ID'])"
                  color="primary"
                  mat-icon-button
                  matTooltip="Remove user subscription plan"
                >
                  <mat-icon>delete</mat-icon>
                </button>

                Scheduled plan: <strong> {{ nextPlan['SubscriptionPlan_Label'] }}</strong>
              </h3>
              <div class="full-width flex">
                <app-ep-document-view-image
                  class="subscription-plan-List-sub-next mr"
                  [hideZoomButton]="false"
                  [defaultPicture]="
                    'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/light_icon2.png)'
                  "
                  [link]="getSubscriptionPlanImage(nextPlan['SubscriptionPlan_key'])"
                >
                </app-ep-document-view-image>
                <div class="full-width">
                  <p class="small rm-m"><strong>Start date:</strong> {{ nextPlan['StartDate'] | customDate }}</p>

                  <div class="full-width flex">
                    <p class="small full-width rm-m mr">
                      <strong>Price:</strong> {{ nextPlan['Price'] | customCurrency: '1.0-3' }}
                    </p>

                    <p class="small full-width rm-m" *ngIf="nextPlan['Is_SMS_Free'] === '0'">
                      <strong>SMS:</strong> {{ nextPlan['SMS_Price'] | customCurrency }}/SMS
                    </p>

                    <p class="small full-width rm-m" *ngIf="nextPlan['Is_SMS_Free'] === '1'">
                      <strong>Free SMS</strong>
                    </p>
                  </div>
                  <div class="full-width flex">
                    <p class="small full-width rm-m mr">
                      <strong>Waived Period:</strong> {{ nextPlan['WaivedPeriodInDays'] }} day(s)
                    </p>

                    <p class="small full-width rm-m">
                      <strong>Min {{ "KEYWORD.treatment" | translate }}s
                        :</strong> {{ nextPlan['MinTreatmentPlansSent'] }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <div class="drop-button text-center full-width clearfix">
      <hr class="mt"/>

      <button
        class="inline btn-new-invite selectExistingPatient smt pull-left"
        [attr.style]="'background:' + colorSecond + ' !important' | safeStyle"
        (click)="selectPlan()"
        mat-raised-button
        color="accent"
      >
        Change Plan
      </button>

      <button
        class="inline btn-new-invite selectExistingPatient smt pull-right"
        *ngIf="msgForm.form.valid && subscriptionPlanID"
        [attr.style]="'background:' + colorSecond + ' !important' | safeStyle"
        (click)="save()"
        mat-raised-button
        color="primary"
      >
        <mat-icon class="fas fa-check smr"></mat-icon>
        Save
      </button>

      <button
        class="inline btn-new-invite selectExistingPatient smt pull-right"
        *ngIf="!msgForm.form.valid || !subscriptionPlanID"
        [disabled]="true"
        (click)="save()"
        mat-raised-button
        color="primary"
      >
        <mat-icon class="fas fa-check smr"></mat-icon>
        Save
      </button>
    </div>
  </form>
</div>
