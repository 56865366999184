import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { DocumentUrlViewComponent } from '../../../shared/components/document-url-view/document-url-view.component';
import { EpDocumentViewComponent } from '../../../shared/components/ep-document-view/ep-document-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { WikiService } from '../shared/wiki.service';
import { WikiGroupEditComponent } from '../wiki-group-edit/wiki-group-edit.component';
import { WikiLeftMenuComponent } from '../wiki-left-menu/wiki-left-menu.component';

@Component({
  selector: 'app-wiki-view',
  templateUrl: './wiki-view.component.html',
  styleUrls: ['./wiki-view.component.css'],
})
export class WikiViewComponent implements OnInit {
  @Input() code;
  @Input() wikiID;

  sessionType = 'guest';

  @Output() closeModal = new EventEmitter();

  isModal = false;
  wiki;
  loading = true;

  search;

  utils = new UtilsClass();

  sessionTypeParams;

  isPromoterOrAdmin = false;

  originLink;

  constructor(
    public authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private wikiService: WikiService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.code) {
        this.code = data.code;
        this.isModal = true;
      }

      if (data.wikiID) {
        this.wikiID = data.wikiID;

        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.originLink = window.location.origin;

    const _res = this.utils.getSessionTypeParams(this.router.url);

    if (_res) {
      this.sessionTypeParams = _res;
    }

    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });

    this.activeRoute.params.subscribe((params) => {
      if (params['wikiID']) {
        this.wikiID = params['wikiID'];
      }

      if (params['code']) {
        this.code = params['code'];
      }

      this.authenticationService.getSessionType().subscribe((sessionType) => {
        if (this.code || this.wikiID) {
          if (sessionType && this.sessionTypeParams && (sessionType == 'admin' || sessionType == 'promoter')) {
            this.sessionType = this.sessionTypeParams;
          } else if (sessionType) {
            this.sessionType = sessionType;
            this.sessionTypeParams = null;
          }

          const p = {
            selectNext: true,
            selectGroup: true,
            selectPrevious: true,
          };

          this.wikiService.getWikiDetails(this.wikiID, this.code, p, this.sessionType).subscribe((wiki) => {
            if (wiki) {
              this.wiki = wiki;

              this.wikiService.updateWikiViews(this.wikiID, this.code).subscribe((res) => {});
            }

            this.loading = false;
          });
        } else {
          this.loading = false;
        }
      });
    });
  }

  clickCopy() {
    NotifyAppComponent.displayToast('success', 'Link copied', 'Link has been copied to your clipboard');
  }

  redirectLink(label, link) {
    if (link) {
      const extension = link.split('.').pop();

      if (link && link.indexOf('@EP:') != -1) {
        const id = link.replace('@EP:', '');

        const ref = RootAppComponent.dialog.open(EpDocumentViewComponent, {
          data: {
            isDownload: false,
            isPrint: false,
            isRedirection: false,
            documentID: id,
            title: label,
            description: 'Wiki Document: ' + this.wiki.title,
          },
          width: '800px',
          panelClass: 'noCard',
        });

        ref.componentInstance.closeModal.subscribe((res) => {
          ref.close();
        });

        ref.componentInstance.noFileFound.subscribe((res) => {
          NotifyAppComponent.displayToast('warning', 'Sorry!', 'We could not get the requested file(s)');
        });
      } else if (
        link.indexOf('vimeo.com') != -1 ||
        link.indexOf('youtube.com') != -1 ||
        String(extension).toLocaleLowerCase() == 'heic' ||
        String(extension).toLocaleLowerCase() == 'png' ||
        String(extension).toLocaleLowerCase() == 'gif' ||
        String(extension).toLocaleLowerCase() == 'jpg' ||
        String(extension).toLocaleLowerCase() == 'jpeg' ||
        String(extension).toLocaleLowerCase() == 'pdf'
      ) {
        const ref = RootAppComponent.dialog.open(DocumentUrlViewComponent, {
          data: {
            title: 'Wiki Document: ' + this.wiki.title,
            description: '',
            documentUrl: link,
          },
          width: '800px',
          panelClass: 'noCard',
        });
        const sub = ref.componentInstance.closeModal.subscribe((data) => {
          ref.close();
        });
      } else {
        window.open(link, '_blank');
      }
    }
  }

  closeEvent() {
    this.closeModal.emit(true);
  }

  enable() {
    if (this.wiki) {
      const confirm = new ConfirmDialog(
        'check_circle',
        '',
        'Are you sure you want to enable thisArticle?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          const p = {
            isActive: true,
            selectGroup: true,
            selectNext: true,
            selectPrevious: true,
          };

          this.wikiService.updateWiki(this.wiki._id, p).subscribe((res) => {
            if (res) {
              ref.close();

              this.wiki = res;
              NotifyAppComponent.displayToast('success', 'Successfull operation', 'Wiki Article is Updated ');
              WikiLeftMenuComponent.updateHeader.emit(true);
            }
          });
        }
      });
    }
  }

  disable() {
    if (this.wiki) {
      const confirm = new ConfirmDialog('cancel', '', 'Are you sure you want to disable thisArticle?', 'No', 'Yes');

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          const p = {
            isActive: false,
            selectGroup: true,
            selectNext: true,
            selectPrevious: true,
          };

          this.wikiService.updateWiki(this.wiki._id, p).subscribe((res) => {
            if (res) {
              ref.close();

              this.wiki = res;
              NotifyAppComponent.displayToast('success', 'Successfull operation', 'Wiki Article is Updated ');
              WikiLeftMenuComponent.updateHeader.emit(true);
            }
          });
        }
      });
    }
  }

  remove() {
    if (this.wiki) {
      const confirm = new ConfirmDialog('delete', '', 'Are you sure you want to Remove thisArticle?', 'No', 'Yes');

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.wikiService.removeWiki(this.wiki._id).subscribe((res) => {
            if (res) {
              ref.close();
              NotifyAppComponent.displayToast('success', 'Successfull operation', 'Wiki Article is Removed ');

              this.router.navigate(['/wiki/']);
              WikiLeftMenuComponent.updateHeader.emit(true);
            }
          });
        }
      });
    }
  }

  edit() {
    if (this.wiki) {
      if (this.sessionTypeParams) {
        this.router.navigate([
          '/wiki-' + this.sessionTypeParams,
          {
            outlets: {
              wikiPageRoute: ['edit', this.wiki._id],
            },
          },
        ]);
      } else {
        this.router.navigate([
          '/wiki',
          {
            outlets: {
              wikiPageRoute: ['edit', this.wiki._id],
            },
          },
        ]);
      }
    }
  }

  editGoup() {
    if (this.wiki && this.wiki.group && this.wiki.group._id) {
      const ref = RootAppComponent.dialog.open(WikiGroupEditComponent, {
        data: {
          groupID: this.wiki.group._id,
        },

        width: '800px',
        panelClass: 'noCard',
      });

      ref.componentInstance.result.subscribe((res) => {
        if (res) {
          this.wiki.group = res;
          WikiLeftMenuComponent.updateHeader.emit(true);
        }
        ref.close();
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
    }
  }

  moveToPage(id) {
    if (id) {
      if (this.sessionTypeParams) {
        this.router.navigate([
          '/wiki-' + this.sessionTypeParams,
          {
            outlets: {
              wikiPageRoute: ['view', id],
            },
          },
        ]);
      } else {
        this.router.navigate([
          '/wiki',
          {
            outlets: {
              wikiPageRoute: ['view', id],
            },
          },
        ]);
      }
    }
  }

  moveToGroup(id) {
    if (id) {
      if (this.sessionTypeParams) {
        this.router.navigate([
          '/wiki-' + this.sessionTypeParams,
          {
            outlets: {
              wikiPageRoute: ['group', id],
            },
          },
        ]);
      } else {
        this.router.navigate([
          '/wiki',
          {
            outlets: {
              wikiPageRoute: ['group', id],
            },
          },
        ]);
      }
    }
  }

  searchEvent(t) {
    if (t) {
      if (this.sessionTypeParams) {
        this.router.navigate([
          '/wiki-' + this.sessionTypeParams,
          {
            outlets: {
              wikiPageRoute: ['tag', t],
            },
          },
        ]);
      } else {
        this.router.navigate([
          '/wiki',
          {
            outlets: {
              wikiPageRoute: ['tag', t],
            },
          },
        ]);
      }
    }
  }

  isNew(date) {
    if (date) {
      const days = this.utils.daysPast(date);

      if (days < 15) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  getSrc(id) {
    if (id) {
      return `${environment.nodeUrl}/files/file-view/${id}`;
    }
  }

  isAwsome(icon) {
    if (icon && icon.indexOf('fa-') != -1) {
      return true;
    }

    return false;
  }

  firstLetterUpperCase(r) {
    if (r && r[0]) {
      return String(r[0]).toUpperCase();
    }
  }

  login() {
    const activeURL = window.location.pathname;
    if (activeURL) {
      const __url = activeURL.replace(/\//g, '$');

      this.router.navigate(['/login/' + __url]);
    } else {
      this.router.navigate(['/login/']);
    }
  }
}
