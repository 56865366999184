import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { Platform } from '@angular/cdk/platform';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule, CurrencyPipe, DatePipe, DeprecatedCurrencyPipe, TitleCasePipe } from '@angular/common';
import { HttpClient, HttpClientModule, HttpHandler } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { FaConfig, FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { ShareButtonModule } from '@ngx-share/button';
import { ShareModule } from '@ngx-share/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ArchwizardModule } from 'angular-archwizard';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { EmailEditorModule } from 'angular-email-editor';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { SwingModule } from 'angular2-swing';
import { QRCodeModule } from 'angularx-qrcode';
import { environment } from 'environments/environment';
import { Ng2CompleterModule } from 'ng2-completer';
import { NgDatepickerModule } from 'ng2-datepicker';
import { FileUploadModule } from 'ng2-file-upload';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { ColorPickerModule } from 'ngx-color-picker';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxGalleryModule } from 'ngx-gallery';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { MomentModule } from 'ngx-moment';
import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor';
import { NgxPaginationModule } from 'ngx-pagination';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { NgPipesModule } from 'ngx-pipes';
import { NgxPrintModule } from 'ngx-print';
import { QuillModule } from 'ngx-quill';
import { SWIPER_CONFIG, SwiperConfigInterface, SwiperModule } from 'ngx-swiper-wrapper';
import { Simple404Component } from '../core/helper/simple404/simple404.component';
import { CustomerProspectViewPictureComponent } from '../feature/customer-prospect/customer-prospect-view-picture/customer-prospect-view-picture.component';
import { InvitationService } from '../feature/invitation/shared/services/invitation.service';
import { OperatorViewPictureComponent } from '../feature/operator/operator-view-picture/operator-view-picture.component';

import { VerticalTimelineModule } from 'angular-vertical-timeline';
import { NgxGaugeModule } from 'ngx-gauge';
import { ToothViewComponent } from '../feature/treatment/tooth-view/tooth-view.component';
import { TreatmentDocumentComponent } from '../feature/treatment/treatment-document/treatment-document.component';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { AddressInputAdvancedComponent } from './components/address-input-advanced/address-input-advanced.component';
import { AddressInputComponent } from './components/address-input/address-input.component';
import { AddressLabelInputComponent } from './components/address-label-input/address-label-input.component';
import { AmountSliderInputComponent } from './components/amount-slider-input/amount-slider-input.component';
import { BackgroundHeaderComponent } from './components/background-header/background-header.component';
import { BankAccountInputComponent } from './components/bank-account-input/bank-account-input.component';
import { BlobViewComponent } from './components/blob-view/blob-view.component';
import { BsbLookupComponent } from './components/bsb-lookup/bsb-lookup.component';
import { CalendarEventShareComponent } from './components/calendar-event-share/calendar-event-share.component';
import { CalendarViewComponent } from './components/calendar-view/calendar-view.component';
import { CalendlyComponent } from './components/calendly/calendly.component';
import { ChartComponent } from './components/chart/chart.component';
import { ClearSearchButtonComponent } from './components/clear-search-button/clear-search-button.component';
import { ClearTextButtonComponent } from './components/clear-text-input/clear-text-button.component';
import { ColorPaletteComponent } from './components/color-palette/color-palette.component';
import { ComboChartComponent, ComboSeriesVerticalComponent } from './components/combo-chart';
import { ConfirmDialogMessageComponent } from './components/confirm-dialog-message/confirm-dialog-message.component';
import { ConfirmDialogMultipleComponent } from './components/confirm-dialog-multiple/confirm-dialog-multiple.component';
import { ConfirmDialogOptionsComponent } from './components/confirm-dialog-options/confirm-dialog-options.component';
import { ConfirmDialogSingleButtonComponent } from './components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { ContainerModalComponent } from './components/container-modal/container-modal.component';
import { ContractDisplayBlobComponent } from './components/contract-display-blob/contract-display-blob.component';
import { CountryListComponent } from './components/country-list/country-list.component';
import { CreditCardInputComponent } from './components/credit-card-input/credit-card-input.component';
import { CreditCardViewComponent } from './components/credit-card-view/credit-card-view.component';
import { DataHeaderComponent } from './components/data-header/data-header.component';
import { DataTableComponent } from './components/data-table/data-table.component';
import { DataTableControlContainerComponent } from './components/data-table/shared/components/data-table-control-container/data-table-control-container.component';
import { DataTablePrintModalComponent } from './components/data-table/shared/modals/data-table-print-modal/data-table-print-modal.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { DateMonthInputComponent } from './components/date-month-input/date-month-input.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { DdrModalComponent } from './components/ddr-modal/ddr-modal.component';
import { DentalArchDetectionsComponent } from './components/dental-arch-detections/dental-arch-detections.component';
import { DentalXrayImageUploaderComponent } from './components/dental-xray-image-uploader/dental-xray-image-uploader.component';
import { DetectBrowserComponent } from './components/detect-browser/detect-browser.component';
import { DisplayLocationComponent } from './components/display-location/display-location.component';
import { DocumentUrlViewComponent } from './components/document-url-view/document-url-view.component';
import { DocumentViewBlockComponent } from './components/document-view-block/document-view-block.component';
import { DoubleAxisChartComponent } from './components/double-axis-chart/double-axis-chart.component';
import { DurationSliderInputComponent } from './components/duration-slider-input/duration-slider-input.component';
import { EmailInputAdvancedComponent } from './components/email-input-advanced/email-input-advanced.component';
import { EmailInputComponent } from './components/email-input/email-input.component';
import { EmailLabelInputComponent } from './components/email-label-input/email-label-input.component';
import { EmojiInputModalComponent } from './components/emoji-input-modal/emoji-input-modal.component';
import { EmptyListMessageComponent } from './components/empty-list-message/empty-list-message.component';
import { EPDocumentCreateEditComponent } from './components/ep-document-create-edit/ep-document-create-edit.component';
import { EpDocumentListMiniComponent } from './components/ep-document-list-mini/ep-document-list-mini.component';
import { EpDocumentListComponent } from './components/ep-document-list/ep-document-list.component';
import { EpDocumentRejectModalComponent } from './components/ep-document-reject-modal/ep-document-reject-modal.component';
import { EpDocumentThumbnailViewComponent } from './components/ep-document-thumbnail-view/ep-document-thumbnail-view.component';
import { EpDocumentViewImageComponent } from './components/ep-document-view-image/ep-document-view-image.component';
import { EpDocumentViewShortComponent } from './components/ep-document-view-short/ep-document-view-short.component';
import { EpDocumentViewComponent } from './components/ep-document-view/ep-document-view.component';
import { FetchingListMessageComponent } from './components/fetching-list-message/fetching-list-message.component';
import { FileContentComponent } from './components/file-content/file-content.component';
import { FileImageCropperComponent } from './components/file-image-cropper/file-image-cropper.component';
import { FileInsertionComponent } from './components/file-insertion/file-insertion.component';
import { FileListComponent } from './components/file-list/file-list.component';
import { FileMenuComponent } from './components/file-menu/file-menu.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { FileViewComponent } from './components/file-view/file-view.component';
import { GenerateQRComponent } from './components/generate-qr/generate-qr.component';
import { HtmlCssInputBackupComponent } from './components/html-css-input-backup/html-css-input-backup.component';
import { CodeHTMLCssInputComponent } from './components/html-css-input/html-css-input.component';
import { CodeHTMLEditComponent } from './components/html-edit/html-edit.component';
import { HtmlIframeInputComponent } from './components/html-iframe-input/html-iframe-input.component';
import { CodeHTMLInputComponent } from './components/html-input/html-input.component';
import { HtmlPopupInputComponent } from './components/html-popup-input/html-popup-input.component';
import { HtmlSimpleLinkInputComponent } from './components/html-simple-link-input/html-simple-link-input.component';
import { CodeHTMLViewComponent } from './components/html-view/html-view.component';
import { IconViewComponent } from './components/icon-view/icon-view.component';
import { IdentitySelectComponent } from './components/identity-select/identity-select.component';
import { IframeViewComponent } from './components/iframe-view/iframe-view.component';
import { ImageViewListComponent } from './components/image-view-list/image-view-list.component';
import { ImageZoomComponent } from './components/image-zoom/image-zoom.component';
import { InnerHtmlIsolatedComponent } from './components/inner-html-isolated/inner-html-isolated.component';
import { LikeAnimationComponent } from './components/like-animation/like-animation.component';
import { LinkInputComponent } from './components/link-input/link-input.component';
import { ListActiveLabelComponent } from './components/list-active-label/list-active-label.component';
import { ListLabelPrintCompatibleComponent } from './components/list-label-print-compatible/list-label-print-compatible.component';
import { ListLabelComponent } from './components/list-label/list-label.component';
import { LookupInputComponent } from './components/lookup-input/lookup-input.component';
import { MapCreateComponent } from './components/map-create/map-create.component';
import { MapMultipleViewComponent } from './components/map-multiple-view/map-multiple-view.component';
import { MapViewComponent } from './components/map-view/map-view.component';
import { MerchantViewPictureModalComponent } from './components/merchant-view-picture-modal/merchant-view-picture-modal.component';
import { MessageModalComponent } from './components/message-modal/message-modal.component';
import { MessageResponseLegendComponent } from './components/message-response-legend/message-response-legend.component';
import { MetadataInputComponent } from './components/metadata-input/metadata-input.component';
import { MiniPatientSelectorComponent } from './components/mini-patient-selector/mini-patient-selector.component';
import { MobileInputAdvancedComponent } from './components/mobile-input-advanced/mobile-input-advanced.component';
import { MobileLabelInputComponent } from './components/mobile-label-input/mobile-label-input.component';
import { ModalTitleComponent } from './components/modal-title/modal-title.component';
import { GalleryComponent } from './components/ngx-gallery/ngx-gallery.component';
import { NoMerchantListMessageComponent } from './components/no-merchant-list-message/no-merchant-list-message.component';
import { OccupationInputComponent } from './components/occupation-input/occupation-input.component';
import { PageComponent } from './components/page/page.component';
import { PatientLookupViewPictureComponent } from './components/patient-lookup-view-picture/patient-lookup-view-picture.component';
import { PatientLookupComponent } from './components/patient-lookup/patient-lookup.component';
import { PatientSelectorComponent } from './components/patient-selector/patient-selector.component';
import { PaymentMethodInputComponent } from './components/payment-method-input/payment-method-input.component';
import { PhoneInputAdvancedComponent } from './components/phone-input-advanced/phone-input-advanced.component';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { PhoneLabelInputComponent } from './components/phone-label-input/phone-label-input.component';
import { PlaneAnimationComponent } from './components/plane-animation/plane-animation.component';
import { PoBoxFormComponent } from './components/po-box-form/po-box-form.component';
import { PrintBtnComponent } from './components/print-btn/print-btn.component';
import { PrintModalComponent } from './components/print-modal/print-modal.component';
import { ProblemModalComponent } from './components/problem-modal/problem-modal.component';
import { PromoterMerchantSelectorComponent } from './components/promoter-merchant-selector/promoter-merchant-selector.component';
import { RatingComponent } from './components/rating/rating.component';
import { ReadMoreComponent } from './components/read-more/read-more.component';
import { RootAppComponent } from './components/root-component/root-component.component';
import { SmsPreviewModalComponent } from './components/sms-preview-modal/sms-preview-modal.component';
import { SocialShareButtonComponent } from './components/social-share-button/social-share-button.component';
import { StarRatingCreateComponent } from './components/star-rating-create/star-rating-create.component';
import { StarRatingLightComponent } from './components/star-rating-light/star-rating-light.component';
import { StarRatingViewComponent } from './components/star-rating-view/star-rating-view.component';
import { StopwatchComponent } from './components/stopwatch/stopwatch.component';
import { SupportingDocumentListComponent } from './components/supporting-document-list/supporting-document-list.component';
import { SwipeCardsComponent } from './components/swipe-cards/swipe-cards.component';
import { TagCreateEditComponent } from './components/tag-create-edit/tag-create-edit.component';
import { TagListComponent } from './components/tag-list/tag-list.component';
import { TextEditorInputComponent } from './components/text-editor-input/text-editor-input.component';
import { TextEditorInsertHtmlComponent } from './components/text-editor-insert-html/text-editor-insert-html.component';
import { TextEditorViewComponent } from './components/text-editor-view/text-editor-view.component';
import { TimeInputComponent } from './components/time-input/time-input.component';
import { TimeOutputComponent } from './components/time-output/time-output.component';
import { TimeZoneListComponent } from './components/time-zone-list/time-zone-list.component';
import { TitleModalComponent } from './components/title-modal/title-modal.component';
import { ToothDetectionDialogComponent } from './components/tooth-detections/components/tooth-detection-dialog/tooth-detection-dialog.component';
import { ToothDetectionsComponent } from './components/tooth-detections/tooth-detections.component';
import { TypeYesInputComponent } from './components/type-yes-input/type-yes-input.component';
import { UserAccessCreateComponent } from './components/user-access-create/user-access-create.component';
import { UserAccessViewComponent } from './components/user-access-view/user-access-view.component';
import { VideoTutorialsModalComponent } from './components/video-tutorials-modal/video-tutorials-modal.component';
import { VideoTutorialsComponent } from './components/video-tutorials/video-tutorials.component';
import { ViewImageSimpleComponent } from './components/view-image-simple/view-image-simple.component';
import { WizardStepCardComponent } from './components/wizard-step-card/wizard-step-card.component';
import { CountUpDirective } from './directives/countup.directive';
import { HasPublicModulesDirective } from './directives/has-public-modules.directive';
import { HasSessionTypesDirective } from './directives/has-session-types.directive';
import { IframeTrackerDirective } from './directives/iframe-tracker.directive';
import { IsPromoterOrAdminDirective } from './directives/is-promoter-or-admin.directive';
import { MatchHeightDirective } from './directives/match-height.directive';
import { NgxPieChartZeroMarginDirective } from './directives/pie-zero-margin.directive';
import { SocialShare } from './directives/social-share.directive';
import { UserDataFormDirective } from './directives/user-data-form.directive';
import { UserDataDirective } from './directives/user-data.directive';
import { MaterialModule } from './modules/material.module';
import { ProviderModule } from './modules/provider.module';
import { arrayFirst } from './pipes/arrayFirst.pipe';
import { arrayLast } from './pipes/arrayLast.pipe';
import { BinaryBoolPipe } from './pipes/binary-bool/binary-bool.pipe';
import { AgePipe } from './pipes/birthday-age.pipe';
import { CleanStringPipe } from './pipes/clean-string.pipe';
import { ClearTextPipe } from './pipes/clear-text.pipe';
import { ConversationMomentDatePipe } from './pipes/conversation-date/conversation-date.pipe';
import { ConvertDatePipe } from './pipes/convert-date.pipe';
import { CountStringIdsPipe } from './pipes/count-string-ids/count-string-ids.pipe';
import { CustomCurrencyPipe } from './pipes/custom-currency.pipe';
import { CustomDateTimePipe } from './pipes/custom-date-time.pipe';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { CustomPhonePipe } from './pipes/custom-phone.pipe';
import { TimeFormat } from './pipes/custom-time.pipe';
import { FilterArrayMultipleContainValuePipe } from './pipes/filter-array-object-contain.pipe';
import { FilterArrayMultipleValueInRange } from './pipes/filter-array-object-in-range.pipe';
import { FilterArrayMultipleNotContainValuePipe } from './pipes/filter-array-object-not-contain.pipe';
import { FilterArrayMultipleNotValuePipe } from './pipes/filter-array-object-not.pipe';
import { FilterArrayMultipleOrContain } from './pipes/filter-array-object-or-contain.pipe';
import { FilterArrayMultipleValue } from './pipes/filter-array-object.pipe';
import { FromObjectToArrayPipe } from './pipes/from-object-to-array.pipe';
import { GenderCodePipe } from './pipes/gender-code/gender-code.pipe';
import { InvertNumberPipe } from './pipes/invert-number/invert-number.pipe';
import { LengthOfArrayPipe } from './pipes/length-of-array .pipe';
import { ObjectPropertyValuePipe } from './pipes/object-property-values.pipe';
import { OrderArrayObject } from './pipes/order-array-object.pipe';
import { ParagraphLinkFormatterPipe } from './pipes/paragraph-link-formatter/paragraph-link-formatter.pipe';
import { PickObjectPropertiesPipe } from './pipes/pick-object-properties/pick-object-properties.pipe';
import { ProfilePicturePipe } from './pipes/profile-picture/profile-picture.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SafeStylePipe } from './pipes/safeStyle.pipe';
import { SplitArray } from './pipes/split-array';
import { SplitTextPipe } from './pipes/split-text/split-text.pipe';
import { StringFilterPipe } from './pipes/string-filter.pipe';
import { SummaryTextPipe } from './pipes/summary-text/summary-text.pipe';
import { TemplateIndexPipe } from './pipes/template-index/template-index.pipe';
import { TextLengthPipe } from './pipes/text-length.pipe';
import { TimeLabelPast } from './pipes/time-label-past.pipe';
import { TimeLeftPipe } from './pipes/time-left.pipe';
import { ToNumberArrayPipe } from './pipes/to-number-array.pipe';
import { ToNumberPipe } from './pipes/to-number.pipe';
import { UniqueByPipe } from './pipes/uniqueBy/unique-by.pipe';
import { UtilPipe } from './pipes/util.pipe';
import { ValidateArrayLengthPipe } from './pipes/validate-array-length/validate-array-length.pipe';
import { WithoutArrayMultipleValue } from './pipes/without-array-object.pipe';
import { CalendarShareService } from './services/calendar-share.service';
import { CalendarService } from './services/calendar.service';
import { CountryService } from './services/country.service';
import { FacebookPixelService } from './services/facebook-pixel/facebook-pixel.service';
import { FileService } from './services/file.service';
import { GlobalService } from './services/global.service';
import { AuthGuardCustomer } from './services/guard-customer.service';
import { HeaderService } from './services/header.service';
import { InterceptedHttp } from './services/intercepted-http.service';
import { LookupService } from './services/lookup.service';
import { SideMenuService } from './services/side-menu.service';
import { UserDataService } from './services/user-data.service';
import { UtilsService } from './services/utils.service';
import { SharedRoutingModule } from './shared/shared-routing.module';
import { httpFactory } from './types/http.factory';
import { ClipboardModule } from 'ngx-clipboard';
import { CookieService } from 'ng2-cookies';
import { reversePipe } from './pipes/reverse.pipe';
import { CustomTooltipDirective } from './directives/customToolTip.directive';
import { InnerHtmlIsolatedModalComponent } from './components/inner-html-isolated-modal/inner-html-isolated-modal.component';

export const customCurrencyMaskConfig = {
  align: 'right',
  allowNegative: false,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '$',
  suffix: '',
  thousands: ',',
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.NATURAL,
};

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.nodeUrl + '/translate/cached/', '.json');
}

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
};

const monacoConfig: NgxMonacoEditorConfig = {
  baseUrl: '/assets',
  defaultOptions: { scrollBeyondLastLine: false },
  // onMonacoLoad: () => { console.log((<any>window).monaco); }
};

@NgModule({
  imports: [
    DeviceDetectorModule.forRoot(),
    QuillModule.forRoot(),
    NgxImageZoomModule.forRoot(),
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    MonacoEditorModule.forRoot(monacoConfig),
    AgmCoreModule.forRoot({
      apiKey: environment.googleAPIKey,
      libraries: ['places', 'geometry'],
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxDaterangepickerMd.forRoot({
      format: 'DD/MM/YYYY',
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    BrowserModule,
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    ArchwizardModule,
    NgxPaginationModule,
    Ng2CompleterModule,
    NgxChartsModule,
    CdkTableModule,
    ColorPickerModule,
    NgxGalleryModule,
    PinchZoomModule,
    NgPipesModule,
    ProviderModule,
    FileUploadModule,
    MomentModule,
    SharedRoutingModule,
    AgmSnazzyInfoWindowModule,
    NgDatepickerModule,
    ShareModule,
    Ng2CompleterModule,
    FormsModule,
    SwiperModule,
    ReactiveFormsModule,
    ShareButtonModule,
    FontAwesomeModule,
    CreditCardDirectivesModule,
    SwingModule,
    ImageCropperModule,
    PdfJsViewerModule,
    DragDropModule,
    QRCodeModule,
    EmailEditorModule,
    NgbRatingModule,
    PickerModule,
    ScrollingModule,
    EditorModule,
    NgxPrintModule,
    FormlyModule,
    VerticalTimelineModule,
    NgxGaugeModule,
    ClipboardModule,
    ColorPickerModule,
    MonacoEditorModule
  ],
  exports: [
    MonacoEditorModule,
    ColorPickerModule,
    ClipboardModule,
    CommonModule,
    SwiperModule,
    NgxGaugeModule,
    ClearSearchButtonComponent,
    ListActiveLabelComponent,
    ListLabelComponent,
    ListLabelPrintCompatibleComponent,
    TimeOutputComponent,
    SocialShareButtonComponent,
    NgxCurrencyModule,
    ArchwizardModule,
    NgxPrintModule,
    EpDocumentListMiniComponent,
    NgxPaginationModule,
    OccupationInputComponent,
    TimeInputComponent,
    PaymentMethodInputComponent,
    BsbLookupComponent,
    ConfirmDialogMessageComponent,
    EpDocumentViewComponent,
    MapMultipleViewComponent,
    EpDocumentViewShortComponent,
    EmptyListMessageComponent,
    BlobViewComponent,
    EpDocumentViewImageComponent,
    AddressInputComponent,
    DetectBrowserComponent,
    ChartComponent,
    ColorPaletteComponent,
    TranslateModule,
    FileContentComponent,
    MetadataInputComponent,
    LookupInputComponent,
    FileViewComponent,
    FileUploaderComponent,
    UserDataDirective,
    NgxPieChartZeroMarginDirective,
    Simple404Component,
    FormsModule,
    ReactiveFormsModule,
    UserDataFormDirective,
    FileMenuComponent,
    SupportingDocumentListComponent,
    CreditCardInputComponent,
    AddressFormComponent,
    PoBoxFormComponent,
    IdentitySelectComponent,
    BankAccountInputComponent,
    DocumentViewBlockComponent,
    GalleryComponent,
    TypeYesInputComponent,
    MapViewComponent,
    MapCreateComponent,
    ContractDisplayBlobComponent,
    FilterArrayMultipleValue,
    WithoutArrayMultipleValue,
    OrderArrayObject,
    TimeLabelPast,
    TimeLeftPipe,
    ToNumberPipe,
    ToNumberArrayPipe,
    LengthOfArrayPipe,
    UtilPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    ObjectPropertyValuePipe,
    CustomCurrencyPipe,
    arrayLast,
    arrayFirst,
    ConvertDatePipe,
    CleanStringPipe,
    SafeStylePipe,
    CustomDatePipe,
    ProfilePicturePipe,
    SummaryTextPipe,
    GenderCodePipe,
    SplitTextPipe,
    BinaryBoolPipe,
    IframeTrackerDirective,
    IframeViewComponent,
    ValidateArrayLengthPipe,
    TemplateIndexPipe,
    InvertNumberPipe,
    ConversationMomentDatePipe,
    CustomPhonePipe,
    TimeFormat,
    CustomDateTimePipe,
    TextLengthPipe,
    reversePipe,
    FileListComponent,
    EpDocumentViewShortComponent,
    EpDocumentListComponent,
    DateInputComponent,
    DateMonthInputComponent,
    EmailInputComponent,
    PhoneInputComponent,
    CountryListComponent,
    StarRatingCreateComponent,
    StarRatingViewComponent,
    StarRatingLightComponent,
    CountUpDirective,
    CustomTooltipDirective,
    MatchHeightDirective,
    AmountSliderInputComponent,
    DurationSliderInputComponent,
    PrintBtnComponent,
    PlaneAnimationComponent,
    FilterArrayMultipleContainValuePipe,
    FilterArrayMultipleNotContainValuePipe,
    FilterArrayMultipleNotValuePipe,
    FilterArrayMultipleOrContain,
    SplitArray,
    FilterArrayMultipleValueInRange,
    UserAccessViewComponent,
    UserAccessCreateComponent,
    ReadMoreComponent,
    EpDocumentThumbnailViewComponent,
    ViewImageSimpleComponent,
    StringFilterPipe,
    SwipeCardsComponent,
    LikeAnimationComponent,
    StopwatchComponent,
    FileImageCropperComponent,
    AddressLabelInputComponent,
    EmailLabelInputComponent,
    MobileLabelInputComponent,
    PhoneLabelInputComponent,
    AgePipe,
    CreditCardViewComponent,
    DocumentUrlViewComponent,
    TextEditorViewComponent,
    TextEditorInputComponent,
    TextEditorInsertHtmlComponent,
    AddressInputAdvancedComponent,
    EmailInputAdvancedComponent,
    PhoneInputAdvancedComponent,
    MobileInputAdvancedComponent,
    IframeTrackerDirective,
    IframeViewComponent,
    CalendlyComponent,
    ComboChartComponent,
    ComboSeriesVerticalComponent,
    DoubleAxisChartComponent,
    DateRangePickerComponent,
    MessageResponseLegendComponent,
    EpDocumentRejectModalComponent,
    EPDocumentCreateEditComponent,
    VideoTutorialsComponent,
    VideoTutorialsModalComponent,
    DdrModalComponent,
    RootAppComponent,
    SocialShare,
    NgPipesModule,
    CalendarEventShareComponent,
    CalendarViewComponent,
    ImageViewListComponent,
    DragDropModule,
    GenerateQRComponent,
    TimeZoneListComponent,
    FromObjectToArrayPipe,
    TagListComponent,
    TagCreateEditComponent,
    ImageZoomComponent,
    WizardStepCardComponent,
    DentalXrayImageUploaderComponent,
    DentalArchDetectionsComponent,
    ToothDetectionsComponent,
    ToothDetectionDialogComponent,
    ToothViewComponent,
    CustomerProspectViewPictureComponent,
    RatingComponent,
    RatingComponent,
    NgbRatingModule,
    PatientLookupComponent,
    TitleModalComponent,
    MessageModalComponent,
    ContainerModalComponent,
    IconViewComponent,
    ClearTextPipe,
    MiniPatientSelectorComponent,
    EmojiInputModalComponent,
    PickerModule,
    InnerHtmlIsolatedComponent,
    InnerHtmlIsolatedModalComponent,
    SmsPreviewModalComponent,
    CodeHTMLViewComponent,
    CodeHTMLEditComponent,
    CodeHTMLCssInputComponent,
    CodeHTMLInputComponent,
    HtmlCssInputBackupComponent,
    HtmlSimpleLinkInputComponent,
    HtmlIframeInputComponent,
    PatientLookupComponent,
    PatientSelectorComponent,
    PatientLookupViewPictureComponent,
    DisplayLocationComponent,
    ProblemModalComponent,
    HtmlPopupInputComponent,
    NoMerchantListMessageComponent,
    ConfirmModalComponent,
    FetchingListMessageComponent,
    DataHeaderComponent,
    DataTableComponent,
    DataTablePrintModalComponent,
    DataTableControlContainerComponent,
    MerchantViewPictureModalComponent,
    ParagraphLinkFormatterPipe,
    PickObjectPropertiesPipe,
    UniqueByPipe,
    CountStringIdsPipe,
    PrintModalComponent,
    TreatmentDocumentComponent,
    LinkInputComponent,
    FileInsertionComponent,
    OperatorViewPictureComponent,
    HasSessionTypesDirective,
    IsPromoterOrAdminDirective,
    PromoterMerchantSelectorComponent,
    HasPublicModulesDirective,
    ClearTextButtonComponent,
    FormlyModule,
    PageComponent,
    BackgroundHeaderComponent,
    MaterialModule,
    VerticalTimelineModule

  ],
  declarations: [
    ListLabelComponent,
    ListLabelPrintCompatibleComponent,
    TimeOutputComponent,
    OccupationInputComponent,
    TimeInputComponent,
    PaymentMethodInputComponent,
    BsbLookupComponent,
    ConfirmDialogMessageComponent,
    EpDocumentListComponent,
    EpDocumentListMiniComponent,
    EpDocumentThumbnailViewComponent,
    EpDocumentViewComponent,
    EpDocumentViewImageComponent,
    EpDocumentViewShortComponent,
    EmptyListMessageComponent,
    AddressInputComponent,
    DetectBrowserComponent,
    ChartComponent,
    ColorPaletteComponent,
    Simple404Component,
    FileContentComponent,
    MetadataInputComponent,
    LookupInputComponent,
    FileViewComponent,
    FileUploaderComponent,
    UserDataDirective,
    MapMultipleViewComponent,
    UserDataFormDirective,
    FileMenuComponent,
    SupportingDocumentListComponent,
    CreditCardInputComponent,
    AddressFormComponent,
    PoBoxFormComponent,
    IdentitySelectComponent,
    BankAccountInputComponent,
    DocumentViewBlockComponent,
    GalleryComponent,
    TypeYesInputComponent,
    ConfirmDialogComponent,
    ConfirmDialogOptionsComponent,
    ConfirmDialogMultipleComponent,
    ConfirmDialogSingleButtonComponent,
    MapViewComponent,
    MapCreateComponent,
    ContractDisplayBlobComponent,
    FilterArrayMultipleValue,
    WithoutArrayMultipleValue,
    OrderArrayObject,
    TimeLabelPast,
    TimeLeftPipe,
    ProfilePicturePipe,
    SummaryTextPipe,
    ConversationMomentDatePipe,
    ToNumberPipe,
    ToNumberArrayPipe,
    LengthOfArrayPipe,
    GenderCodePipe,
    SplitTextPipe,
    BinaryBoolPipe,
    ValidateArrayLengthPipe,
    TemplateIndexPipe,
    InvertNumberPipe,
    FilterArrayMultipleContainValuePipe,
    FilterArrayMultipleNotContainValuePipe,
    FilterArrayMultipleNotValuePipe,
    FilterArrayMultipleOrContain,
    SplitArray,
    FilterArrayMultipleValueInRange,
    UtilPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    TimeFormat,
    ObjectPropertyValuePipe,
    CustomCurrencyPipe,
    arrayLast,
    arrayFirst,
    ConvertDatePipe,
    CleanStringPipe,
    SafeStylePipe,
    CustomDatePipe,
    CustomPhonePipe,
    CustomDateTimePipe,
    TextLengthPipe,
    reversePipe,
    FileListComponent,
    BlobViewComponent,
    DateInputComponent,
    DateMonthInputComponent,
    EmailInputComponent,
    PhoneInputComponent,
    CountryListComponent,
    StarRatingCreateComponent,
    StarRatingViewComponent,
    StarRatingLightComponent,
    CountUpDirective,
    CustomTooltipDirective,
    MatchHeightDirective,
    NgxPieChartZeroMarginDirective,
    AmountSliderInputComponent,
    DurationSliderInputComponent,
    PrintBtnComponent,
    PlaneAnimationComponent,
    UserAccessViewComponent,
    UserAccessCreateComponent,
    ReadMoreComponent,
    ViewImageSimpleComponent,
    StringFilterPipe,
    SwipeCardsComponent,
    LikeAnimationComponent,
    StopwatchComponent,
    FileImageCropperComponent,
    AddressLabelInputComponent,
    EmailLabelInputComponent,
    MobileLabelInputComponent,
    PhoneLabelInputComponent,
    AgePipe,
    CreditCardViewComponent,
    DocumentUrlViewComponent,
    TextEditorViewComponent,
    TextEditorInputComponent,
    TextEditorInsertHtmlComponent,
    AddressInputAdvancedComponent,
    EmailInputAdvancedComponent,
    PhoneInputAdvancedComponent,
    MobileInputAdvancedComponent,
    IframeTrackerDirective,
    IframeViewComponent,
    CalendlyComponent,
    ComboChartComponent,
    ComboSeriesVerticalComponent,
    DoubleAxisChartComponent,
    DateRangePickerComponent,
    MessageResponseLegendComponent,
    EpDocumentRejectModalComponent,
    EPDocumentCreateEditComponent,
    VideoTutorialsComponent,
    VideoTutorialsModalComponent,
    DdrModalComponent,
    RootAppComponent,
    CalendarEventShareComponent,
    SocialShare,
    CalendarViewComponent,
    ImageViewListComponent,
    GenerateQRComponent,
    TimeZoneListComponent,
    FromObjectToArrayPipe,
    TagListComponent,
    TagCreateEditComponent,
    ImageZoomComponent,
    SocialShareButtonComponent,
    WizardStepCardComponent,
    DentalXrayImageUploaderComponent,
    DentalArchDetectionsComponent,
    ToothDetectionsComponent,
    ToothDetectionDialogComponent,
    ToothViewComponent,
    CustomerProspectViewPictureComponent,
    RatingComponent,
    RatingComponent,
    TitleModalComponent,
    MessageModalComponent,
    ContainerModalComponent,
    IconViewComponent,
    ClearTextPipe,
    MiniPatientSelectorComponent,
    EmojiInputModalComponent,
    InnerHtmlIsolatedComponent,
    InnerHtmlIsolatedModalComponent,
    SmsPreviewModalComponent,
    CodeHTMLViewComponent,
    CodeHTMLEditComponent,
    CodeHTMLCssInputComponent,
    CodeHTMLInputComponent,
    HtmlCssInputBackupComponent,
    HtmlSimpleLinkInputComponent,
    HtmlIframeInputComponent,
    HtmlCssInputBackupComponent,
    PatientLookupComponent,
    PatientSelectorComponent,
    PatientLookupViewPictureComponent,
    DisplayLocationComponent,
    ProblemModalComponent,
    HtmlPopupInputComponent,
    NoMerchantListMessageComponent,
    ConfirmModalComponent,
    FetchingListMessageComponent,
    DataHeaderComponent,
    DataTableComponent,
    DataTablePrintModalComponent,
    DataTableControlContainerComponent,
    MerchantViewPictureModalComponent,
    ParagraphLinkFormatterPipe,
    PickObjectPropertiesPipe,
    UniqueByPipe,
    CountStringIdsPipe,
    PrintModalComponent,
    TreatmentDocumentComponent,
    LinkInputComponent,
    FileInsertionComponent,
    OperatorViewPictureComponent,
    HasSessionTypesDirective,
    IsPromoterOrAdminDirective,
    HasPublicModulesDirective,
    PromoterMerchantSelectorComponent,
    ClearTextButtonComponent,
    PageComponent,
    BackgroundHeaderComponent,
    ListActiveLabelComponent,
    ClearSearchButtonComponent,
    ModalTitleComponent,
  ],
  providers: [
    { provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG },
    {
      provide: HttpClient,
      useValue: { useUtc: true },
      useFactory: httpFactory,
      deps: [HttpHandler],
    },
    Platform,
    FaIconLibrary,

    CurrencyPipe,
    FaConfig,
    FacebookPixelService,
    CountryService,
    FileService,
    GlobalService,
    InterceptedHttp,
    LookupService,
    UserDataService,
    CookieService,
    UtilsService,
    GoogleMapsAPIWrapper,
    AuthGuardCustomer,
    DeprecatedCurrencyPipe,
    FilterArrayMultipleValue,
    WithoutArrayMultipleValue,
    OrderArrayObject,
    TimeLabelPast,
    TimeLeftPipe,
    LengthOfArrayPipe,
    ToNumberPipe,
    ToNumberArrayPipe,
    DatePipe,
    UtilPipe,
    TimeFormat,
    CalendarShareService,
    SafeHtmlPipe,
    ObjectPropertyValuePipe,
    CustomCurrencyPipe,
    arrayLast,
    arrayFirst,
    ConvertDatePipe,
    CleanStringPipe,
    SafeStylePipe,
    CustomDatePipe,
    CustomDateTimePipe,
    TextLengthPipe,
    reversePipe,
    CustomPhonePipe,
    FilterArrayMultipleContainValuePipe,
    FilterArrayMultipleNotContainValuePipe,
    FilterArrayMultipleNotValuePipe,
    FilterArrayMultipleOrContain,
    SplitArray,
    FilterArrayMultipleValueInRange,
    StringFilterPipe,
    HeaderService,
    SideMenuService,
    CalendarService,
    FromObjectToArrayPipe,
    ClearTextPipe,
    InvitationService,
    TitleCasePipe,
    CountStringIdsPipe,
  ],
  entryComponents: [
    FileContentComponent,
    ConfirmDialogComponent,
    ConfirmDialogMultipleComponent,
    ConfirmDialogOptionsComponent,
    ConfirmDialogSingleButtonComponent,
    ToothDetectionDialogComponent,
    ConfirmModalComponent,
    TitleModalComponent,
    MessageModalComponent,
    ContainerModalComponent,
    EmojiInputModalComponent,
    SmsPreviewModalComponent,
    PatientLookupComponent,
    PatientSelectorComponent,
    ProblemModalComponent,
    DataTablePrintModalComponent,
    MerchantViewPictureModalComponent,
    PrintModalComponent,
  ],
})
export class SharedModule {}
