import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { InvitationService } from '../../invitation/shared/services/invitation.service';
import { TreatmentService } from '../shared/treatment.service';

@Component({
  selector: 'app-treatment-document',
  templateUrl: './treatment-document.component.html',
  styleUrls: ['./treatment-document.component.css'],
})
export class TreatmentDocumentComponent implements OnInit {
  @Input()
  invitationID;

  @Input()
  isPublic = false;

  @Input()
  treatmentDocumentTitle = 'Practice';
  @Input()
  treatmentInstructionLine = 'Click to view documents';

  @Input()
  preTreatmentDocumentTitle = 'Pre Op';
  @Input()
  preTreatmentInstructionLine = 'Click to view documents';

  @Input()
  postTreatmentDocumentTitle = 'Post Op';
  @Input()
  postTreatmentInstructionLine = 'Click to view documents';

  @Input()
  marketingTreatmentDocumentTitle = 'Marketing';
  @Input()
  marketingTreatmentInstructionLine = 'Click to view documents';

  @Input()
  stockArtTreatmentDocumentTitle = 'Treatment gallery - before / after';
  @Input()
  stockArtTreatmentInstructionLine = 'Click to view images relating to your upcoming treatment';

  @Input()
  view = 'consumer';

  @Input()
  isDisplayViewedDate = false;

  treatmentDocuments = [];
  preTreatmentDocuments = [];
  postTreatmentDocuments = [];
  marketingTreatmentDocuments = [];
  stockArtTreatmentDocuments = [];

  @Input()
  isFilter = true;

  sessionType = 'customer';

  @Output()
  getDocuments = new EventEmitter();

  @Output()
  getDocumentsLength = new EventEmitter();

  @Output()
  getViewedDocuments = new EventEmitter();

  @Output()
  noDocuments = new EventEmitter();

  @Input() viewedDocuments = [];

  @Input()
  canView = false;

  viewStatus = false;

  informedConsentID;

  treatmentPlan;

  cardID;

  isPromoterOrAdmin = false;

  isDownload = false;
  isPrint = false;

  constructor(
    private authenticationService: AuthenticationService,
    private invitationService: InvitationService,
    private treatmentService: TreatmentService,
    private utilService: UtilsService
  ) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;
        if (this.isPromoterOrAdmin == true) {
          this.isDownload = true;
          this.isPrint = true;
        }

        const p = {
          fields: 'ID',
        };

        this.invitationService.getInvitationPatient(this.invitationID, p, false).subscribe((res) => {
          if (res && res['ID']) {
            this.cardID = res['ID'];
          }

          this.getTreatmentPlan();
        });
      });
    });
  }

  getTreatmentPlan() {
    const payload = {
      selectTreatmentDocument: true,
      selectTreatmentStockArtDocument: true,
    };

    let sessionType = this.sessionType;

    if (this.isPublic == true && this.sessionType !== 'consumer' && this.sessionType !== 'customer') {
      sessionType = 'guest';
    }

    this.treatmentService
      .getTreatmentPlanCustomerByInvitationID(this.invitationID, payload, sessionType)
      .subscribe((res) => {
        if (res) {
          if (res['informedConsentID']) {
            this.informedConsentID = res['informedConsentID'];
          }

          if (res['viewedDocuments'] && res['viewedDocuments'].length > 0) {
            this.viewedDocuments = res['viewedDocuments'];
          }

          if (res['preDocuments'] && res['preDocuments'].length > 0) {
            this.preTreatmentDocuments = res['preDocuments'];
          }

          if (res['treatmentDocuments'] && res['treatmentDocuments'].length > 0) {
            this.treatmentDocuments = res['treatmentDocuments'];
          }

          if (res['postDocuments'] && res['postDocuments'].length > 0) {
            this.postTreatmentDocuments = res['postDocuments'];
          }

          if (res['marketingDocuments'] && res['marketingDocuments'].length > 0) {
            this.marketingTreatmentDocuments = res['marketingDocuments'];
          }

          if (res['stockArtDocuments'] && res['stockArtDocuments'].length > 0) {
            this.stockArtTreatmentDocuments = res['stockArtDocuments'];
          }

          this.sendResult();
        }
      });
  }

  sendResult() {
    let result = [];

    if (this.treatmentDocuments && this.treatmentDocuments.length > 0) {
      result = result.concat(this.treatmentDocuments);
    }

    if (this.preTreatmentDocuments && this.preTreatmentDocuments.length > 0) {
      result = result.concat(this.preTreatmentDocuments);
    }

    if (this.postTreatmentDocuments && this.postTreatmentDocuments.length > 0) {
      result = result.concat(this.postTreatmentDocuments);
    }

    if (this.marketingTreatmentDocuments && this.marketingTreatmentDocuments.length > 0) {
      result = result.concat(this.marketingTreatmentDocuments);
    }

    if (this.stockArtTreatmentDocuments && this.stockArtTreatmentDocuments.length > 0) {
      result = result.concat(this.stockArtTreatmentDocuments);
    }

    this.getDocuments.emit(result);

    this.getViewedDocuments.emit(this.viewedDocuments);

    this.getDocumentsLength.emit(result.length);

    if (result && result.length > 0) {
      this.noDocuments.emit(false);
    } else {
      this.noDocuments.emit(true);
    }
  }

  getViewedDocumentEvent(d) {
    if (d && d['ID'] && this.informedConsentID && this.invitationID && this.canView == true) {
      // if (d['ID'] && this.viewedDocuments.indexOf(d['ID']) == -1) {
      //   this.viewedDocuments.push(d['ID'])
      // }

      this.treatmentService
        .viewInformedConsentInvitationDocument(this.invitationID, d['ID'], this.informedConsentID)
        .subscribe((res) => {
          if (res && res['Document_key'] && this.viewedDocuments.indexOf(res['Document_key']) == -1) {
            this.viewedDocuments.push({
              id: res['Document_key'],
              viewedDate: res['Viewed'],
            });
          }
        });
    }
  }
}
