<div class="rel text-center">
  <mat-card class="card">
    <div class="row clearfix">
      <div class="pull-right">
        <!-- Modal dismiss button -->
        <button class="btn-close btn-clear mat-button" (click)="doNotShow()" matDialogClose>
          <mat-icon>clear</mat-icon>
        </button>
      </div>
      <!-- <div class="pull-left">
        <h3 class="sr-title rm-mt rm-mb">Welcome to the Dashboard!</h3>
      </div> -->
    </div>
    <hr/>
    <div class="colour-stripe"></div>

    <p>
      <img class="animated zoomIn feature-img character_payment_schedule"/>
    </p>

    <p class="lead">Welcome to the Settlements Dashboard</p>
    <p>
      From here, you can monitor the progress of your {{ "KEYWORD.patient" | translate }}s financial arrangements, and
      request a draw-down against their finance to cover {{ "KEYWORD.treatment" | translate }} costs.
    </p>
    <p>Watch the tutorial below to discover how you can begin to make settlement requests...</p>
    <hr/>
    <mat-checkbox class="pull-left" [(ngModel)]="showOnSettlementFirstVisit">Dont Show Again</mat-checkbox>
  </mat-card>
  <!-- <button mat-raised-button color="primary" class="gradient green pull-right" *ngIf="isModuleTutorialsActive == true">See the tutorial!</button> -->
  <button class="pull-right" (click)="doNotShow()" mat-raised-button color="accent" matDialogClose>
    Got It!
  </button>
</div>
