import { animate, animateChild, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { BankAccountService } from '../shared/bank-account.service';

@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.css'],
  animations: [
    trigger('fade', [transition(':enter', [style({ opacity: 0 }), animate('.6s ease')])]),
    trigger('stagger', [transition(':enter', [query(':enter', stagger('.3s', [animateChild()]), { optional: true })])]),
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: '0' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: '0' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class PaymentListComponent implements OnInit {
  @Input()
  contractID;

  @Input()
  merchantID;

  @Input()
  customerID;

  @Input()
  dateFrom;

  @Input()
  dateTo;

  @Input()
  status;

  @Input()
  isConsolidated = false;

  @Input()
  isOutstandingOnly = false;

  @Input()
  isPending = false;

  @Output()
  totalObject = new EventEmitter();

  payments;

  isModal = false;
  util = new UtilsClass();
  isPromoterOrAdmin = false;
  serviceRef;
  tempPayments;
  emptyList = false;
  firstLoad = false;
  dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(
    private bankAccountService: BankAccountService,
    private activeRouter: ActivatedRoute,
    private authenticationSerivce: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.contractID = data.contractID;
      this.customerID = data.customerID;
      this.merchantID = data.merchantID;
      this.isConsolidated = data.isConsolidated;
      this.isOutstandingOnly = data.isOutstandingOnly;
      this.isPending = data.isPending;

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.activeRouter.params.subscribe((params) => {
      if (params['contractID']) {
        this.contractID = params['contractID'];
      }

      if (params['customerID']) {
        this.customerID = params['customerID'];
      }

      if (params['merchantID']) {
        this.merchantID = params['merchantID'];
      }

      this.authenticationSerivce.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        if (res == true) {
          this.setup();
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setup();
  }

  setup() {
    let _tempPayments;

    this.authenticationSerivce.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
      const payload = {
        contractID: this.contractID,
        userID: this.customerID || this.merchantID,
        dateFrom: this.dateFrom || null,
        dateTo: this.dateTo || null,
        status: this.status || null,
        section: 0,
      };

      this.serviceRef = this.bankAccountService.getPaymentList(payload, this.isPromoterOrAdmin).subscribe((res) => {
        if (res && res.length > 0) {
          _tempPayments = res;
          this.firstLoad = true;
          const innerFuncation = (section) => {
            section = section + 1;
            UtilsClass.loadingDataSection(section);
            payload.section = section;

            this.serviceRef = this.bankAccountService
              .getPaymentList(payload, this.isPromoterOrAdmin)
              .subscribe((res) => {
                if (res.length > 0) {
                  _tempPayments = _tempPayments.concat(res);
                  innerFuncation(section);
                } else {
                  this.tempPayments = _tempPayments;
                  this.filterPaymentList(this.tempPayments);
                }
              });

            UtilsClass.stopLoadingInterceptor();
          };

          innerFuncation(1);
        } else {
          this.emptyList = true;
        }
      });
    });
  }

  calculateTotal(value) {
    const amountTotal = value.map((x) => Number(x['amounts.Total'])).reduce((a, b) => a + b);

    const dueAmountTotal = value.map((x) => Number(x['amounts.Due'])).reduce((a, b) => a + b);

    const paidDirectlyTotal = value.map((x) => Number(x['amounts.PaidDirect'])).reduce((a, b) => a + b);

    const waivedTotal = value.map((x) => Number(x['amounts.Waived'])).reduce((a, b) => a + b);

    const insuranceTotal = value.map((x) => Number(x['Amounts.InsuranceContribution'])).reduce((a, b) => a + b);

    const rescheduledTotal = value.map((x) => Number(x['amounts.Rescheduled'])).reduce((a, b) => a + b);

    const totalObject = {
      amountTotal,
      dueAmountTotal,
      paidDirectlyTotal,
      waivedTotal,
      insuranceTotal,
      rescheduledTotal,
    };

    this.totalObject.emit(totalObject);
  }

  // Only allow user to choose one filter at a time. Cannot select multiple selection. The filter is in contract-detail-payment component.
  filterPaymentList(list) {
    if (this.isOutstandingOnly == true) {
      // filter the list to display only outstanding record
      const temp = [];
      list.map((x) => {
        if (this.util.timeAgo(x['Dates.Due']) == 'just now') {
          temp.push(x);
        }
      });
      if (temp.length > 0) {
        this.emptyList = false;
        this.payments = temp;
        this.calculateTotal(this.payments); // Calculated the total amount of different value, such as total amount, total pending amount, and etc.
      } else {
        this.emptyList = true;
        this.payments = null;
        this.totalObject.emit(null);
      }
    } else if (this.isPending == true) {
      // filter the list to display only pending record
      const temp = [];
      list.map((x) => {
        if (x['Status.Code'] == '0') {
          temp.push(x);
        }
      });
      if (temp.length > 0) {
        this.emptyList = false;
        this.payments = temp;
        this.calculateTotal(this.payments);
      } else {
        this.emptyList = true;
        this.payments = null;
        this.totalObject.emit(null);
      }
    } else if (this.isConsolidated == true) {
      // filter the list to display consolidated record
      const temp = list.reduce((prev, curr) => {
        let index;

        if ((index = prev.findIndex((item) => item['Dates.Due'] == curr['Dates.Due'])) > -1) {
          if (prev[index]['Status.Code'] == curr['Status.Code']) {
            prev[index]['amounts.Total'] = String(Number(prev[index]['amounts.Total']) + Number(curr['amounts.Total']));
            prev[index]['amounts.Due'] = String(Number(prev[index]['amounts.Due']) + Number(curr['amounts.Due']));
            prev[index]['amounts.PaidDirect'] = String(
              Number(prev[index]['amounts.PaidDirect']) + Number(curr['amounts.PaidDirect'])
            );
            prev[index]['amounts.Waived'] = String(
              Number(prev[index]['amounts.Waived']) + Number(curr['amounts.Waived'])
            );
            prev[index]['amounts.InsuranceContribution'] = String(
              Number(prev[index]['amounts.InsuranceContribution']) + Number(curr['amounts.InsuranceContribution'])
            );
            prev[index]['amounts.Rescheduled'] = String(
              Number(prev[index]['amounts.Rescheduled']) + Number(curr['amounts.Rescheduled'])
            );
          } else {
            prev.push(curr);
          }
        } else {
          prev.push(curr);
        }
        return prev;
      }, []);
      if (temp.length > 0) {
        this.emptyList = false;
        this.payments = temp;
        this.calculateTotal(this.payments);
      } else {
        this.emptyList = true;
        this.payments = null;
        this.totalObject.emit(null);
      }
    } else {
      // display the whole payment list.
      this.emptyList = false;
      this.payments = list;
      this.calculateTotal(this.payments);
    }
  }

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}
