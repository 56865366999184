import { Component, EventEmitter, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { EditorComponentService } from '../shared/editor-component.service';

@Component({
  selector: 'app-editor-document-create',
  templateUrl: './editor-document-create.component.html',
  styleUrls: ['./editor-document-create.component.css'],
})
export class EditorDocumentCreateComponent implements OnInit {
  isModal = true;
  close = new EventEmitter();

  getResult = new EventEmitter();

  isPromoterOrAdmin = false;

  sessionType;
  isGallery = true;
  label;
  fileID;
  type = 'EDTRIMG';
  thumbnailFileID;

  title = 'File';
  types = [
    {
      icon: 'fas fa-image',
      code: 'EDTRBKG',
      label: 'Background',
    },
    {
      icon: 'fas fa-portrait',
      code: 'EDTRCHR',
      label: 'Character',
    },
    {
      icon: 'fas fa-file-alt',
      code: 'EDTRDOC',
      label: 'Document',
    },

    {
      icon: 'fas fa-file-image',
      code: 'EDTRIMG',
      label: 'Image',
    },
    // {
    //   code: 'EDTRJVS',
    //   label: 'Javascript'
    // },
    {
      icon: 'fas fa-file-code',
      code: 'EDTRSTL',
      label: 'Style',
    },
  ];

  allowedDocumentExtensions = [
    'pdf',
    'doc',
    'docx',
    'html',
    'htm',
    'xls',
    'xlsx',
    'ppt',
    'pptx',
    'txt',
    'csv',
    'tsv',
    '',
  ];
  allowedImageExtensions = ['jpeg', 'jpg', 'png','heic' , 'gif', 'tiff', 'bmp', 'ico'];
  allowedJSExtensions = ['js', 'JS'];
  allowedStyleExtensions = ['css', 'CSS', 'scss', 'SCSS'];

  utils = new UtilsClass();
  fileLink;
  thumbnailLink;

  constructor(
    private editorComponentService: EditorComponentService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((r) => {
      this.sessionType = r;

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;
      });
    });
  }

  changeType() {
    this.fileID = null;
    this.thumbnailFileID = null;

    this.fileLink = null;
    this.thumbnailLink = null;
  }

  onCompleteFile(fileID) {
    if (fileID && fileID[0]) {
      this.fileID = fileID[0];

      this.fileLink = null;

      setTimeout(() => {
        this.fileLink = this.utils.getFileLink(this.fileID);
      }, 200);
    } else {
      this.fileLink = null;
    }
  }

  onCompleteThumbnail(fileID) {
    if (fileID && fileID[0]) {
      this.thumbnailFileID = fileID[0];

      this.thumbnailLink = null;
      setTimeout(() => {
        this.thumbnailLink = this.utils.getFileLink(this.thumbnailFileID);
      }, 200);
    } else {
      this.thumbnailLink = null;
      this.thumbnailFileID = null;
    }
  }

  create() {
    const p = {
      fileID: this.fileID,
      thumbnailFileID: this.thumbnailFileID || null,
      type: this.type,
      isGallery: this.isGallery,
      label: this.label,
    };
    this.editorComponentService.createEditorComponentDocument(p, this.sessionType).subscribe((r) => {
      if (r) {
        NotifyAppComponent.displayToast('success', 'success', 'Editor Component Document is created');

        this.getResult.emit(r);
      }
    });
  }

  closeEvent() {
    this.close.emit(true);
  }
}
