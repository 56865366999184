import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class SideMenuService {
  static goBack: EventEmitter<any> = new EventEmitter<any>();

  static signupObject: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}
}
