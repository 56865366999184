/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tip-overview.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../tip-create/tip-create.component.ngfactory";
import * as i3 from "../tip-create/tip-create.component";
import * as i4 from "../shared/tip.service";
import * as i5 from "../tip-list/tip-list.component.ngfactory";
import * as i6 from "../tip-list/tip-list.component";
import * as i7 from "./tip-overview.component";
var styles_TipOverviewComponent = [i0.styles];
var RenderType_TipOverviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TipOverviewComponent, data: {} });
export { RenderType_TipOverviewComponent as RenderType_TipOverviewComponent };
export function View_TipOverviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tip-create", [["class", "full-width mb text-center"]], null, [[null, "add"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("add" === en)) {
        var pd_0 = (_co.createTip($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TipCreateComponent_0, i2.RenderType_TipCreateComponent)), i1.ɵdid(1, 114688, null, 0, i3.TipCreateComponent, [i4.TipService], null, { add: "add" }), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-tip-list", [["class", "full-width clearfix"]], null, null, null, i5.View_TipListComponent_0, i5.RenderType_TipListComponent)), i1.ɵdid(3, 114688, null, 0, i6.TipListComponent, [i4.TipService], { tip: [0, "tip"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.tip; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_TipOverviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tip-overview", [], null, null, null, View_TipOverviewComponent_0, RenderType_TipOverviewComponent)), i1.ɵdid(1, 114688, null, 0, i7.TipOverviewComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TipOverviewComponentNgFactory = i1.ɵccf("app-tip-overview", i7.TipOverviewComponent, View_TipOverviewComponent_Host_0, {}, {}, []);
export { TipOverviewComponentNgFactory as TipOverviewComponentNgFactory };
