<div class="row clearfix pContainer">
  <!-- blue heading -->
  <div class="card-header primary-gradient-img clearfix" *ngIf="isModal == true">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear pull-right mat-button" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>

      <div class="row titleArea clearfix">
        <mat-icon class="fas fa-calendar pull-left"></mat-icon>
        <h2 class="message-title summary-header rm-m">
          <span *ngIf="lookupType == 'AppointmentTypes' && type != 'subType'">New appointment type</span>
          <span *ngIf="lookupType == 'BestAppointmentTime'">New Appointment time</span>
          <span *ngIf="lookupType == 'AppointmentTypes' && type == 'subType'"
          >New appointment type <span *ngIf="category && category.Label">: {{ category.Label }}</span></span
          >

          <span class="subLabel">Customize your appointment types and improve your {{"KEYWORD.patient" | translate}}s
            experience</span>
        </h2>
      </div>
    </div>
  </div>
  <!-- end blue heading -->

  <div>
    <form #aForm="ngForm">
      <!--  mat dialog scrolling area -->
      <mat-dialog-content>
        <!-- Lookup Type -->
        <div class="row clearfix mt" *ngIf="displayLookupType">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Lookup Type</mat-label>
            <mat-select
              class="full-width"
              [(ngModel)]="lookupType"
              (selectionChange)="onSelectLookupType()"
              name="LookupType"
              placeholder="Lookup Type"
              required
            >
              <mat-option *ngFor="let p of lookupTypes" [value]="p.code">
                {{ p.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Category List -->
        <div class="row clearfix" *ngIf="displayCategoryList">
          <p class="clearfix small">Choose a Category group to add attach your new type to it.</p>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Reasons for visiting</mat-label>
            <mat-select
              class="full-width"
              [(ngModel)]="category"
              (ngModelChange)="selectCategory()"
              name="DefaultDuration"
              placeholder="Reasons for visiting"
              required
            >
              <mat-option *ngFor="let option of categories" [value]="p">
                <span>{{ option.Label }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="row clearfix mt">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Label</mat-label>
            <input class="mat-block" [(ngModel)]="label" name="Label" matInput required/>
          </mat-form-field>
        </div>

        <!-- Description -->
        <div class="row clearfix">
          <div>
            <mat-form-field class="full-width messageContent" appearance="outline">
              <mat-label>Description</mat-label>
              <textarea
                class="dentMsg increase-input-field"
                [(ngModel)]="description"
                name="Description"
                matInput
                placeholder="Enter Description"
                cols="30"
                rows="50"
              ></textarea>
            </mat-form-field>
          </div>
        </div>

        <div class="row clearfix" *ngIf="displayDurationBuffers">
          <p class="clearfix small">
            Insert a default duration and buffers , this can be changed when creating an appointment
          </p>
          <!-- Default Duration -->
          <div class="full-width flex">
            <mat-form-field class="full-width mr" appearance="outline">
              <mat-label>Default Duration</mat-label>
              <mat-select
                class="full-width"
                [(ngModel)]="defaultDuration"
                name="DefaultDuration"
                placeholder="Default Duration"
                required
              >
                <mat-option *ngFor="let p of durations" [value]="p.code">
                  {{ p.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Buffers_Pre -->
            <mat-form-field class="full-width ml mr" appearance="outline">
              <mat-label>Buffers Before</mat-label>
              <mat-select
                class="full-width"
                [(ngModel)]="buffersPre"
                name="Buffers_Pre"
                placeholder="Buffers Before Appointment"
                required
              >
                <mat-option *ngFor="let p of buffers" [value]="p.code">
                  {{ p.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Buffers_Post -->
            <mat-form-field class="full-width ml" appearance="outline">
              <mat-label>Buffers After</mat-label>
              <mat-select
                class="full-width"
                [(ngModel)]="buffersPost"
                name="Buffers_Post"
                placeholder="Buffers After Appointment"
                required
              >
                <mat-option *ngFor="let p of buffers" [value]="p.code">
                  {{ p.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </mat-dialog-content>
      <!-- fixed action buttons -->
      <mat-dialog-actions>
        <div class="row clearfix text-center">
          <button class="btn-large" [disabled]="aForm.form.invalid" (click)="onSave()" mat-raised-button color="accent">
            Save
          </button>
        </div>
      </mat-dialog-actions>
    </form>
  </div>
</div>
