import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { SimplePageCreateEditComponent } from '../../../core/helper/simple-page-create-edit/simple-page-create-edit.component';
import { SimplePageViewComponent } from '../../../core/helper/simple-page-view/simple-page-view.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { MerchantService } from '../shared/merchant.service';

@Component({
  selector: 'app-merchant-product-assistant',
  templateUrl: './merchant-product-assistant.component.html',
  styleUrls: ['./merchant-product-assistant.component.css'],
})
export class MerchantProductAssistantComponent implements OnInit {
  @Input() merchantID;
  merchant;

  @Output()
  close = new EventEmitter();

  isPromoterOrAdmin = false;

  sessionType = 'guest';

  isModal = false;

  filterName;
  isActiveFilter = '*';

  products;

  isEdit = true;

  merchantProducts = [];

  constructor(
    private merchantService: MerchantService,
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.merchantID) {
        this.merchantID = data.merchantID;
      }
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['merchantID']) {
        this.merchantID = params['merchantID'];
      }
      this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
        this.isPromoterOrAdmin = isPromoterOrAdmin;
        this.isEdit = isPromoterOrAdmin;

        this.authenticationService.getSessionType().subscribe((s) => {
          this.sessionType = s;

          if (this.isPromoterOrAdmin == true && this.merchantID) {
            const p = {
              fields: 'ID,TradingAs,AssistBrrowerAtPOS_List,Allow_AssistBrrowerAtPOS',
            };
            this.merchantService.getMerchantDetails(this.merchantID, p, this.sessionType).subscribe((merchant) => {
              if (merchant && merchant.ID) {
                this.merchant = merchant;
                this.merchantID = merchant.ID;
                this.setup();
              }
            });
          } else {
            this.authenticationService.getCurrentPractice().subscribe((merchant) => {
              if (merchant && merchant.ID) {
                this.merchant = merchant;
                this.merchantID = merchant.ID;
                this.setup();
              }
            });
          }
        });
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.merchantID.previousValue != changes.merchantID.currentValue) {
      if (this.isPromoterOrAdmin == true && this.merchantID) {
        const p = {
          fields: 'ID,TradingAs,AssistBrrowerAtPOS_List,Allow_AssistBrrowerAtPOS',
        };
        this.merchantService.getMerchantDetails(this.merchantID, p, this.sessionType).subscribe((merchant) => {
          if (merchant && merchant.ID) {
            this.merchant = merchant;
            this.merchantID = merchant.ID;
            this.setup();
          }
        });
      } else {
        this.authenticationService.getCurrentPractice().subscribe((merchant) => {
          if (merchant && merchant.ID) {
            this.merchant = merchant;
            this.merchantID = merchant.ID;
            this.setup();
          }
        });
      }
    }
  }

  setup() {
    if (this.merchantID) {
      const payload = {
        fields: 'ID,Label,Description,SubType,TandCs.Essentials',
        merchantID: this.merchantID,
      };
      this.merchantService.getProductList(payload, this.isPromoterOrAdmin).subscribe((res) => {
        if (res) {
          this.products = res;
          this.buildProducts();
        }
      });
    }
  }

  buildProducts() {
    if (this.merchant && this.merchant['Allow_AssistBrrowerAtPOS'] == '1' && this.merchant['AssistBrrowerAtPOS_List']) {
      this.merchantProducts = this.merchant['AssistBrrowerAtPOS_List'].split('|');
    } else {
      this.merchantProducts = [];
    }
    if (this.products && this.products.length > 0) {
      for (let i = 0; i < this.products.length; i++) {
        if (
          this.products &&
          this.products[i] &&
          this.products[i]['ID'] &&
          this.merchantProducts &&
          this.merchantProducts.length > 0 &&
          this.merchantProducts.indexOf(this.products[i]['ID']) != -1
        ) {
          this.products[i].isActive = true;
        } else {
          this.products[i].isActive = false;
        }
      }

      this.products = JSON.parse(JSON.stringify(this.products));
    }
  }

  sliderChange(value, id) {
    if (id) {
      if (value && value.checked == true) {
        if (this.merchantProducts && this.merchantProducts.indexOf(id) == -1) {
          this.merchantProducts.push(id);
        }

        const payload = {
          assistProductIDs: this.merchantProducts.join(','),
        };

        this.merchantService.editMerchant(this.merchantID, payload).subscribe((merchant) => {
          if (merchant) {
            this.merchant = merchant;
            this.buildProducts();
            NotifyAppComponent.displayToast('success', 'Successfull', 'Successful operation');
          }
        });
      } else {
        if (this.merchantProducts && this.merchantProducts.indexOf(id) != -1) {
          this.merchantProducts.splice(id, 1);
        }
        const payload = {
          assistProductIDs: this.merchantProducts.join(','),
        };

        this.merchantService.editMerchant(this.merchantID, payload).subscribe((merchant) => {
          if (merchant) {
            this.merchant = merchant;
            this.buildProducts();
            NotifyAppComponent.displayToast('success', 'Successfull', 'Successful operation');
          }
        });
      }
    }
  }

  enableAll() {
    const confirmMessage = new ConfirmDialog(
      'check_circle',
      'Enable all product',
      'Are you sure you want to enable all products Assistance for this merchant ?',
      'No',
      'Yes'
    );
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmMessage,
      width: '500px',
    });

    ref.componentInstance.closeEvent.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.onConfirm.subscribe((res) => {
      if (this.products && this.products.length) {
        const p = [];

        for (let i = 0; i < this.products.length; i++) {
          if (this.products[i] && this.products[i]['ID']) {
            p.push(this.products[i]['ID']);
          }
        }

        const payload = {
          assistProductIDs: p.join(','),
        };
        this.merchantService.editMerchant(this.merchantID, payload).subscribe((merchant) => {
          if (merchant) {
            this.merchant = merchant;
            this.buildProducts();
            NotifyAppComponent.displayToast('success', 'Successfull', 'Successful operation');

            ref.close();
          }
        });
      }
    });
  }

  disabledAll() {
    const confirmMessage = new ConfirmDialog(
      'cancel',
      'Disable all product',
      'Are you sure you want to disable all products Assistance for this merchant ?',
      'No',
      'Yes'
    );
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmMessage,
      width: '500px',
    });

    ref.componentInstance.closeEvent.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.onConfirm.subscribe((res) => {
      const payload = {
        assistProductDisabledAll: true,
      };
      this.merchantService.editMerchant(this.merchantID, payload).subscribe((merchant) => {
        if (merchant) {
          this.merchant = merchant;
          this.buildProducts();
          NotifyAppComponent.displayToast('success', 'Successfull', 'Successful operation');
          ref.close();
        }
      });
    });
  }

  enableAssistance() {
    const confirmMessage = new ConfirmDialog(
      'check_circle',
      'Enable Assistance',
      'Are you sure you want to enable patient assistance for this merchant ?',
      'No',
      'Yes'
    );
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmMessage,
      width: '500px',
    });

    ref.componentInstance.closeEvent.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.onConfirm.subscribe((res) => {
      const payload = {
        canAssistPatient: true,
      };
      this.merchantService.editMerchant(this.merchantID, payload).subscribe((merchant) => {
        if (merchant) {
          this.merchant = merchant;
          this.buildProducts();
          NotifyAppComponent.displayToast('success', 'Successfull', 'Successful operation');

          ref.close();
        }
      });
    });
  }

  disableAssistance() {
    const confirmMessage = new ConfirmDialog(
      'cancel',
      'Disable Assistance',
      'Are you sure you want to disable patient assistance for this merchant ?',
      'No',
      'Yes'
    );
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmMessage,
      width: '500px',
    });

    ref.componentInstance.closeEvent.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.onConfirm.subscribe((res) => {
      const payload = {
        canAssistPatient: false,
      };
      this.merchantService.editMerchant(this.merchantID, payload).subscribe((merchant) => {
        if (merchant) {
          this.merchant = merchant;
          this.buildProducts();
          NotifyAppComponent.displayToast('success', 'Successfull', 'Successful operation');

          ref.close();
        }
      });
    });
  }

  changeByType(enable = false, type) {
    let confirmMessage = new ConfirmDialog(
      'check_circle',
      'Enable all loan application product',
      'Are you sure you want to enable all loan application products Assistance for this merchant ?',
      'No',
      'Yes'
    );

    if (type == 'pp' && enable == true) {
      confirmMessage = new ConfirmDialog(
        'check_circle',
        'Enable all payment plan product',
        'Are you sure you want to enable all payment plan products Assistance for this merchant ?',
        'No',
        'Yes'
      );
    } else if (type == 'pp' && enable == false) {
      confirmMessage = new ConfirmDialog(
        'cancel',
        'Disable all payment plan product',
        'Are you sure you want to disable all payment plan products Assistance for this merchant ?',
        'No',
        'Yes'
      );
    } else if (type == 'la' && enable == true) {
      confirmMessage = new ConfirmDialog(
        'check_circle',
        'Enable all loan application product',
        'Are you sure you want to enable all loan application products Assistance for this merchant ?',
        'No',
        'Yes'
      );
    } else if (type == 'la' && enable == false) {
      confirmMessage = new ConfirmDialog(
        'cancel',
        'Disable all loan application product',
        'Are you sure you want to disable all loan application products Assistance for this merchant ?',
        'No',
        'Yes'
      );
    }

    const productIDs = [];

    if (type == 'pp') {
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i] && this.products[i]['ID'] && this.products[i]['SubType']) {
          if (enable == true && this.products[i]['SubType'].indexOf('Payment Plan') != -1) {
            productIDs.push(this.products[i]['ID']);
          } else if (
            enable == false &&
            this.products[i]['SubType'].indexOf('Payment Plan') == -1 &&
            this.merchantProducts &&
            this.merchantProducts.length > 0 &&
            this.merchantProducts.indexOf(this.products[i]['ID']) != -1
          ) {
            productIDs.push(this.products[i]['ID']);
          }
        }
      }
    } else if (type == 'la') {
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i] && this.products[i]['ID'] && this.products[i]['SubType']) {
          if (enable == true && this.products[i]['SubType'].indexOf('With Interest') != -1) {
            productIDs.push(this.products[i]['ID']);
          } else if (
            enable == false &&
            this.products[i]['SubType'].indexOf('With Interest') == -1 &&
            this.merchantProducts &&
            this.merchantProducts.length > 0 &&
            this.merchantProducts.indexOf(this.products[i]['ID']) != -1
          ) {
            productIDs.push(this.products[i]['ID']);
          }
        }
      }
    }

    if (productIDs && productIDs.length > 0) {
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmMessage,
        width: '500px',
      });

      ref.componentInstance.closeEvent.subscribe((res) => {
        ref.close();
      });
      ref.componentInstance.onConfirm.subscribe((res) => {
        if (this.products && this.products.length) {
          const payload = {
            assistProductIDs: productIDs.join(','),
          };
          this.merchantService.editMerchant(this.merchantID, payload).subscribe((merchant) => {
            if (merchant) {
              this.merchant = merchant;
              this.buildProducts();
              NotifyAppComponent.displayToast('success', 'Successfull', 'Successful operation');

              ref.close();
            }
          });
        }
      });
    }
  }

  termsEdit() {
    const ref = RootAppComponent.dialog.open(SimplePageCreateEditComponent, {
      data: {
        simplePageCode: 'ProductAssitanceTerms',
      },
      width: '800px',
    });

    ref.componentInstance.close.subscribe((confirmation) => {
      ref.close();
    });
  }

  termsView() {
    RootAppComponent.dialog.open(SimplePageViewComponent, {
      data: {
        simplePageCode: 'ProductAssitanceTerms',
        canAccept: false,
      },
      width: '800px',
    });
  }
}
