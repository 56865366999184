import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { OperatorService } from '../shared/operator.service';

@Component({
  selector: 'app-operator-contact',
  templateUrl: './operator-contact.component.html',
  styleUrls: ['./operator-contact.component.css'],
})
export class OperatorContactComponent implements OnInit {
  @Input() operatorID;
  contact = {
    subject: '',
    body: '',
  };

  operator: any = {};

  @Output()
  close = new EventEmitter();

  constructor(
    private operatorService: OperatorService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.operatorID = data;
    }
  }

  ngOnInit() {
    this.operatorService.getfullOperatorDetails(this.operatorID).subscribe((res) => {
      if (res) {
        this.operator = res;
      } else {
        this.close.emit(true);
      }
    });
  }

  contactOperator() {
    this.operatorService.contactOperator(this.operatorID, this.contact).subscribe((res) => {
      if (res['success']) {
        this.contact = {
          subject: '',
          body: '',
        };
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Your message has been sent successfully');
      }
    });
  }

  closeEvent() {
    this.close.emit(true);
  }
}
