<div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel animate">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div class="text-right clearfix">
  <button class="btn-small" (click)="createStaticFilterTagAll()" mat-raised-button color="accent">
    <mat-icon>add</mat-icon> All as Static Audience
  </button>

  <button
    class="row select-btn ml"
    (click)="selectAll()"
    mat-stroked-button
    color=""
    matTooltip="Select All"
  >
    <mat-icon>border_all</mat-icon>
  </button>

  <button
    class="row select-btn stroked"
    (click)="selectPage()"
    mat-stroked-button
    color=""
    matTooltip="Select Page"
  >
    <mat-icon>border_outer</mat-icon>
  </button>

  <button
    class="animated stroked"
    *ngIf="selectedIDs && selectedIDs.length > 0"
    [matMenuTriggerFor]="ActionBtnmenu"
    mat-stroked-button
    matTooltip="Options"
  >
    <mat-icon class="fas fa-cog"></mat-icon>  <span *ngIf="selectedIDs && selectedIDs.length>0"
      class="selected-number-item  accent-background">{{selectedIDs.length}}</span>
  </button>
  <mat-menu #ActionBtnmenu="matMenu">
    <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
      <mat-icon>select_all</mat-icon> Deselect
    </button>
    <button class="row select-btn" (click)="createStaticFilterTag()" mat-menu-item color="">
      <mat-icon class="fas fa-tag"></mat-icon>
      Add to a Static Audience
    </button>
  </mat-menu>
</div>

<div [hidden]="listDB.data.length == 0">
  <div class="row clearfix">
    <h1 class="dataHeader" *ngIf="status">
      {{ utilMarketing.getMsgStatusLabel(status) }} Campaign Details
      <span class="badge">{{ dataSource.filteredData.length }}</span>
    </h1>

    <mat-card class="list-card mat-elevation-z3 mb">
      <!-- search -->
      <div class="row clearfix searchArea primary-gradient-img">
        <div class="full-width clearfix flex animated fadeInDown">
          <mat-icon class="mt">search</mat-icon>

          <mat-form-field class="full-width ml rm-mt" appearance="outline">
            <mat-label>Name</mat-label>
            <input
              class="clearfix"
              (input)="setFilter($event.target.value, 'Card.Name')"
              name="Name"
              placeholder="Name"
              matInput
            />
          </mat-form-field>

          <mat-form-field class="full-width ml rm-mt" appearance="outline">
            <mat-label>Email</mat-label>
            <input
              class="clearfix"
              (input)="setFilter($event.target.value, 'Destination')"
              name="Email"
              placeholder="Email"
              matInput
            />
          </mat-form-field>
        </div>
      </div>

      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <ng-container cdkColumnDef="checkbox" sticky>
          <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
          <mat-cell *cdkCellDef="let row" matTooltip="Select {{ row['Card.Name'] | lowercase | ucfirst }}">
            <div class="margin-btm pull-left">
              <mat-checkbox
                [checked]="isSelected(row['Card_key'])"
                (change)="select(row['Card_key'], $event.checked)"
                color="accent"
              >
              </mat-checkbox>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Name" sticky>
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Recipient Name</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openEmailPreviewDialog(row['Campaign_TemplateTag'])"
            matTooltip="{{ row['Card.Name'] }}"
          >
            {{ row['Card.Name'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Create">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Created</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openEmailPreviewDialog(row['Campaign_TemplateTag'])"
            matTooltip="{{ row['DateTimeCreated'] | customDateTime }}"
          >
            {{ row['DateTimeCreated'] | customDateTime }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="SendDate">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Sent Date'</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openEmailPreviewDialog(row['Campaign_TemplateTag'])"
            matTooltip="{{ row['SentWhen'] | customDateTime }}"
          >
            {{ row['SentWhen'] | customDateTime }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Status">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Status</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openEmailPreviewDialog(row['Campaign_TemplateTag'])"
            matTooltip="{{ utilMarketing.getMsgStatusLabel(row['Status']) }}"
          >
            {{ utilMarketing.getMsgStatusLabel(row['Status']) }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Email">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Recipient Email</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openEmailPreviewDialog(row['Campaign_TemplateTag'])"
            matTooltip="{{ utilMarketing.getMsgStatusLabel(row['Destination']) }}"
          >
            {{ utilMarketing.getMsgStatusLabel(row['Destination']) }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Label">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Campaign Label</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openEmailPreviewDialog(row['Campaign_TemplateTag'])"
            matTooltip="{{ row['Campaign_Label'] }}"
          >{{ row['Campaign_Label'] }}</mat-cell
          >
        </ng-container>

        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button
                  *ngIf="row['Campaign_TemplateTag']"
                  (click)="openEmailPreviewDialog(row['Campaign_TemplateTag'])"
                  mat-menu-item
                >
                  <mat-icon>chrome_reader_mode</mat-icon>
                  <span>View Template</span>
                </button>

                <button *ngIf="row['Card_key']" (click)="viewPatient(row['Card_key'])" mat-menu-item>
                  <mat-icon>chrome_reader_mode</mat-icon>
                  <span>{{ "KEYWORD.patient" | translate | titlecase }} Profile</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginator
        [length]="dataSource.filteredData.length"
        [pageIndex]="0"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
      >
      </mat-paginator>
    </mat-card>
  </div>
</div>

<div *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <app-empty-list-message></app-empty-list-message>
</div>
