<div class="rel">
  <div class="row clearfix">
    <div class="pull-right">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear mat-button" matDialogClose>
        <mat-icon>clear</mat-icon>
      </button>
    </div>

    <h3 class="sr-title rm-mt rm-mb pull-left" *ngIf="force != true">Not what you were expecting?</h3>
    <h3 class="sr-title rm-mt rm-mb pull-left" *ngIf="force == true">
      Reason for {{ "KEYWORD.patient" | translate }} not proceeding
    </h3>
  </div>
  <mat-dialog-content>
    <p class="rm-mb" *ngIf="force != true">We're sorry to hear that.</p>
    <p class="mobSm" *ngIf="force != true">
      Could you please let us know honestly what the problem was with the {{ "KEYWORD.treatment" | translate }} we
      proposed using the feedback form below so we can improve our service next time.
    </p>
    <hr/>
    <div class="clearfix row">
      <div class="thr-qtr-width">
        <p *ngIf="force != true">My issue is: (multiple selection available)</p>
        <p *ngIf="force == true">{{ "KEYWORD.patient" | translate | titlecase}}'s reasons (multiple selections are
          available)</p>
      </div>
      <div class="qtr-width text-center">
        <div class="half-width">
          <p>Yes</p>
        </div>
        <div class="half-width">
          <p>No</p>
        </div>
      </div>
    </div>
    <div class="alternate-theme">
      <div class="clearfix row" *ngFor="let option of options">
        <div class="thr-qtr-width">
          <p class="rm-mt mobSm">{{ option.Label }}</p>
        </div>
        <div class="qtr-width text-center">
          <mat-radio-group>
            <div class="half-width">
              <mat-radio-button (click)="selectReason(option, 'yes')" value="yes" name="radio-1"></mat-radio-button>
            </div>
            <div class="half-width">
              <mat-radio-button (click)="selectReason(option, 'no')" value="no" name="radio-2"></mat-radio-button>
            </div>
          </mat-radio-group>
        </div>
      </div>
    </div>
    <div class="clearfix">
      <p class="mobSm" *ngIf="force != true">
        Would you like to say something more? <br/>
        Please leave a detailed comment to assist in answering your query.
      </p>
      <p class="mobSm" *ngIf="force == true">Additional comments</p>

      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Your comments here</mat-label>
        <textarea [(ngModel)]="feedback" matInput placeholder="Your comments here"></textarea>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <div class="row clearfix text-center">
    <button class="" [disabled]="feedback == '' && selectedRadio == false" (click)="submit()" mat-raised-button
            color="accent">
      Submit Feedback
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>
