import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { EditorComponentDocumentViewComponent } from '../../../feature/editor-component/editor-component-document-view/editor-component-document-view.component';
import { EditorComponentLookupComponent } from '../../../feature/editor-component/editor-component-lookup/editor-component-lookup.component';
import { EditorDocumentCreateComponent } from '../../../feature/editor-component/editor-document-create/editor-document-create.component';
import { EditorDocumentGalleryComponent } from '../../../feature/editor-component/editor-document-gallery/editor-document-gallery.component';
import { NotifyAppComponent } from '../../types/notify-app-component';
import { Settings } from '../../types/settings';
import { CodeHTMLEditComponent } from '../html-edit/html-edit.component';
import { HtmlIframeInputComponent } from '../html-iframe-input/html-iframe-input.component';
import { HtmlPopupInputComponent } from '../html-popup-input/html-popup-input.component';
import { HtmlSimpleLinkInputComponent } from '../html-simple-link-input/html-simple-link-input.component';
import { CodeHTMLViewComponent } from '../html-view/html-view.component';
import { RootAppComponent } from '../root-component/root-component.component';

@Component({
  selector: 'app-code-html-css-input',
  templateUrl: './html-css-input.component.html',
  styleUrls: ['./html-css-input.component.css'],
})
export class CodeHTMLCssInputComponent implements OnInit {
  @Input()
  displayEditorOptions = true;

  @Input()
  isEditStyle = true;

  @Input()
  descriptionHTML = 'Choose the editor type to create HTML content,you can also change the view type.';
  @Input()
  descriptionCSS = 'Use this Component to add your CSS content.';

  @Input()
  descriptionDocument = 'Upload file , only accept .html .css';
  @Input()
  descriptionRaw =
    'Use this component to create your HTML Content , you can preview the result in real time on the  panel  in the right ';
  @Input()
  descriptionEditor =
    'Use this component to create your HTML Content, drag and drop modals from the  panel  in the right';

  @Input()
  descriptionPreview = 'This is result of applying styling  with HTML  Preview content.';

  updatingContent = false;
  @Input()
  label = 'Content';
  @Input()
  fileID;
  @Input()
  EPDocumentID;
  @Input()
  displayUploader = true;
  @Input()
  structure;
  @Input()
  inputType = 'TinyEditor';
  @Input()
  isFroalaEditorActive = Settings.global['isFroalaEditorActive'];
  @Input()
  isUnlayerActive = Settings.global['isUnlayerActive'];
  @Input()
  isTinyEditorActive = Settings.global['isTinyEditorActive'];
  @Input()
  isRawPreview = true;
  @Input()
  isCss = false;
  @Input()
  isRawActive = true;
  @Input()
  isTextActive = true;
  @Input()
  content;

  @Input()
  style;
  @Input()
  isComponent = false;
  @Input()
  componentID;

  @Output()
  getContent = new EventEmitter();

  @Output()
  getPreview = new EventEmitter();

  @Output()
  getStructure = new EventEmitter();

  @Output()
  getStyle = new EventEmitter();
  @Output()
  getResult = new EventEmitter();
  @Output()
  getComponentID = new EventEmitter();
  @Output()
  getIsEditStyle = new EventEmitter();
  _content = '';
  _style = '';
  _preview = '';
  _structure;

  _contentDefault = '';
  _styleDefault = '';

  load = true;
  isHidden = false;
  @Input()
  viewType = 'view1';

  settings = Settings.global;

  isContentExist = false;
  isStyleExist = false;

  isFullScreen = false;
  merchantID;
  practice;
  terminalCode;
  staticContent = [];

  constructor(private authenticaitonService: AuthenticationService, private _clipboardService: ClipboardService) {}

  ngOnInit() {
    this.authenticaitonService.getCurrentPractice().subscribe((r) => {
      if (r && r.ID) {
        this.practice = r;
        this.merchantID = r.ID;

        if (this.merchantID) {
          this.authenticaitonService
            .practiceLandingPageOneByMerchant(this.merchantID, { fields: 'Code' })
            .subscribe((c) => {
              if (c && c.Code) {
                this.terminalCode = c.Code;
              }

              this.setup();
            });
        } else {
          this.setup();
        }
      } else {
        this.setup();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.merchantID) {
      this.authenticaitonService.getCurrentPractice().subscribe((r) => {
        if (r && r.ID) {
          this.practice = r;
          this.merchantID = r.ID;

          if (this.merchantID) {
            this.authenticaitonService
              .practiceLandingPageOneByMerchant(this.merchantID, { fields: 'Code' })
              .subscribe((c) => {
                if (c && c.Code) {
                  this.terminalCode = c.Code;
                }

                this.setup();
              });
          } else {
            this.setup();
          }
        } else {
          this.setup();
        }
      });
    } else {
      this.setup();
    }
  }

  setup() {
    if (this.merchantID && this.terminalCode) {
      this.staticContent = [
        {
          type: 'image',
          isMerchant: true,
          title: 'Practice Picture',
          label: 'Practice Picture',
          src: `${this.settings['nodeJSServer']}/merchant/practice-picture-stream/MERCHANT_ID_INSERT`,
        },
        {
          type: 'link',
          isMerchant: true,
          title: 'Click here to check our landing page',
          label: 'Landing Page Link',
          src: `${this.settings['publicSiteFrontendLink']}/home/MERCHANT_TERMINAL_CODE_INSERT`,
        },
        {
          type: 'link',
          isMerchant: true,
          title: 'Click here to check our offers',
          label: 'Offer Page Link',
          src: `${this.settings['publicSiteFrontendLink']}/news/MERCHANT_TERMINAL_CODE_INSERT`,
        },
      ];
    }
    if (!this.componentID) {
      this.componentID = 'ID' + btoa(Math.random().toString()).substring(2, 20);
    }
    if (this.componentID && this.isComponent == true) {
      this.descriptionCSS =
        "We generated an <strong>Container ID</strong> for you, please add wrap your CSS properties under the ID, Clicks on <strong><i class='fas fa-copy' style='color:#33455a;margin-right:5px; margin-left:5px;'></i></strong> to get the Generated.";
    }

    if (this.content) {
      this._content = this.content;
      this._contentDefault = this.content;
      this.isContentExist = true;
    }

    if (this.style) {
      this._style = this.style;
      this._styleDefault = this.style;
      this.isStyleExist = true;
    }

    if (this.structure) {
      this._structure = this.structure;
    }
    if (this.componentID && !this._style && this.isComponent == true) {
      this._style = `#${this.componentID} {\n\n\n}`;
    }

    if (this.isContentExist == true || this.isStyleExist == true) {
      this.getContentEvent(null, false);
    }
  }

  getStructureEvent(s) {
    this._structure = s;
    this.getStructure.emit(this._structure);
  }

  getStyleEvent(s) {
    this._style = s;

    this.getContentEvent(null, false);
  }

  getContentEvent(d = null, updateContent = true) {
    if (d && updateContent === true) {
      this._content = d;
    }

    if (this.componentID && this.isComponent == true) {
      let addSection = true;

      const _IDindex = this._content.indexOf(this.componentID);

      if (_IDindex !== -1) {
        addSection = false;
      }

      setTimeout(() => {
        const index = this._content.indexOf('</head>');

        if (index === -1) {
          if (addSection === true) {
            this._preview = `<!DOCTYPE html>\n<section id="${this.componentID}">\n<html>\n<head>\n<style>\n${this._style}\n</style>\n</head>\n<body>${this._content}</body>\n</html>\n</section>`;
          } else {
            this._preview = `<!DOCTYPE html>\n<html>\n<head>\n<style>\n${this._style}\n</style>\n</head>\n<body>\n${this._content}\n</body>\n</html>`;
          }
        } else {
          this._preview = this._content.replace('</head>', `<style>\n${this._style}\n</style>\n</head>`);
          if (addSection === true) {
            this._preview = `<section id="${this.componentID}">\n${this._content}\n</section>`;
          }
        }

        this.sendResult();
      }, 200);

      this.sendResult();
    } else {
      if (this._content) {
        setTimeout(() => {
          const index = this._content.indexOf('</head>');

          if (index === -1) {
            this._preview = `<!DOCTYPE html>\n<html>\n<head>\n<style>\n${this._style}\n</style>\n</head>\n<body>\n${this._content}\n</body>\n</html>`;
          } else {
            this._preview = this._content.replace('</head>', `<style>\n${this._style}\n</style>\n</head>`);
          }

          this.sendResult();
        }, 200);
      } else {
        this._preview = '';
      }

      this.sendResult();
    }
  }

  buildContent() {}

  sendResult() {
    const r = {
      content: this._content,
      style: this._style,
      componentID: this.componentID,
    };
    if (this.isEditStyle != true) {
      r.style = null;
      this.getIsEditStyle.emit(this.isEditStyle);
      this.getResult.emit(r);
      this.getContent.emit(this._content);
      this.getStyle.emit(null);
      this.getComponentID.emit(this.componentID);
      this.getPreview.emit(this._preview);
    } else {
      this.getIsEditStyle.emit(this.isEditStyle);
      this.getResult.emit(r);
      this.getContent.emit(this._content);
      this.getStyle.emit(this._style);
      this.getComponentID.emit(this.componentID);
      this.getPreview.emit(this._preview);
    }
  }

  copyID() {
    if (this.componentID) {
      this._clipboardService.copy(`#${this.componentID} `);

      NotifyAppComponent.displayToast('success', 'success', 'Copied');
    }
  }

  copy(d) {
    if (d) {
      this._clipboardService.copy(d);

      NotifyAppComponent.displayToast('success', 'success', 'Copied');
    }
  }

  ViewHtmlContent() {
    if (this._preview) {
      const isPreview = true;
      const isCode = true;

      const ref2 = RootAppComponent.dialog.open(CodeHTMLViewComponent, {
        data: {
          icons: 'fas fa-eye',
          title: 'preview',
          content: this._preview,
          style: this._style,
          isPreview,
          isCode,
        },
        width: '1000px',
      });
      ref2.componentInstance.onConfirm.subscribe((res) => {
        ref2.close();
      });
    }
  }

  changeViewType(e) {
    this.viewType = e;

    this.load = false;
    this.isHidden = true;

    setTimeout(() => {
      this.isHidden = false;
      this.load = true;
    }, 100);
  }

  changeEditorType() {
    this._styleDefault = this._style;
    this._contentDefault = this._content;
    this._structure = this._structure;
  }

  changeCSSInput() {
    this.sendResult();

    this.viewType = 'view1';
    this.load = false;
    this.isHidden = true;

    setTimeout(() => {
      this.isHidden = false;
      this.load = true;
    }, 100);
  }

  editorLookup(d) {
    const ref = RootAppComponent.dialog.open(EditorComponentLookupComponent, {
      data: {
        natureCode: d,
      },
      width: '800px',
      panelClass: 'bigger-screen',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        this.manageInsertionAll(res);
      }
      ref.close();
    });
  }

  manageInsertionAll(res) {
    if (res) {
      if (res.isCopy === true) {
        const ref2 = RootAppComponent.dialog.open(CodeHTMLEditComponent, {
          data: {
            content: this._content,
            isCSS: false,
          },
          width: '1000px',
        });
        ref2.componentInstance.getResult.subscribe((c) => {
          res.content = c;
          res.isReplace = true;
          this.manageInsertion(res);

          ref2.close();
        });

        ref2.componentInstance.close.subscribe((s) => {
          ref2.close();
        });

        ref2.backdropClick().subscribe(() => {
          ref2.close();
        });
      } else {
        this.manageInsertion(res);
      }
    }
  }

  manageInsertion(res) {
    if (res) {
      if (res.isReplace == true) {
        if (res.style) {
          this._styleDefault = res.style;
          this._style = res.style;
          this.isEditStyle = true;
        }

        if (res.content) {
          this._contentDefault = res.content;
          this._content = res.content;
        }

        this.getContentEvent(null, false);
      } else {
        if (res.style) {
          this._style = this._style + '\n\n' + res.style;
          this.isEditStyle = true;

          this._styleDefault = this._style;
        }

        if (res.content) {
          if (this._content && this._content.indexOf('</body') !== -1) {
            this._content = this._content.replace('</body', res.content + '\n' + '</body');
          } else {
            this._content = this._content + '\n' + res.content;
          }
        }
        if (res.headCSS) {
          this.isEditStyle = true;

          if (this._content && this._content.indexOf('</head>') !== -1) {
            this._content = this._content.replace('</head>', res.headCSS + '\n' + '</head>');
          } else {
            this._content = `<!DOCTYPE html>\n<html>\n<head>\n${res.headCSS}\n</head>\n<body>\n${this._content}\n</body></html>`;
          }
        }
        if (res.headJS) {
          this.isEditStyle = true;

          if (this._content && this._content.indexOf('</body>') !== -1) {
            this._content = this._content.replace('</body>', res.headJS + '\n' + '</body>');
          } else {
            this._content = `<!DOCTYPE html>\n<html>\n<head>\n</head>\n<body>\n${this._content}\n${res.headJS}\n</body></html>`;
          }
        }

        if (res.script) {
          this.isEditStyle = true;
          if (this._content && this._content.indexOf('</body>') !== -1) {
            this._content = this._content.replace('</body>', res.script + '\n' + '</body>');
          } else {
            this._content = this._content + '\n' + res.script;
          }
        }
        this._contentDefault = this._content;

        this.getContentEvent(null, false);
      }
    }
  }

  displayStatic(c) {
    if (c) {
      const ref2 = RootAppComponent.dialog.open(HtmlSimpleLinkInputComponent, {
        data: {
          title: c.label,
          content: c.content,
          isMerchant: c.isMerchant,
          link: c.src,
          linkTitle: c.title,
          type: c.type,
        },
        width: '600px',
      });
      ref2.componentInstance.getCopy.subscribe((c) => {
        if (c) {
          ref2.close();

          ref2.afterClosed().subscribe((r) => {
            this.manageInsertionAll(c);
          });
        } else {
          ref2.close();
        }
      });

      ref2.componentInstance.getInsert.subscribe((c) => {
        if (c) {
          ref2.close();

          ref2.afterClosed().subscribe((r) => {
            this.manageInsertion(c);
          });
        } else {
          ref2.close();
        }
      });

      ref2.componentInstance.close.subscribe((s) => {
        ref2.close();
      });
    }
  }

  insertStatic(c) {
    if (c) {
      const p = {
        content: c,
        isCopy: false,
        style: null,
        isReplace: false,
      };

      this.manageInsertion(p);
    }
  }

  copyStatic(c) {
    if (c) {
      this.copy(c);
      const p = {
        content: c,
        isCopy: true,
        style: null,
        isReplace: false,
      };

      this.manageInsertionAll(p);
    }
  }

  switchFullScreen(d) {
    this.isFullScreen = d;

    RootAppComponent.hideSideMenu.emit(this.isFullScreen);

    this.load = false;
    this.isHidden = true;

    setTimeout(() => {
      this.isHidden = false;
      this.load = true;
    }, 100);
  }

  linkPopup() {
    const ref = RootAppComponent.dialog.open(HtmlPopupInputComponent, {
      data: {},
      width: '720px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        this.manageInsertionAll(res);
      }
      ref.close();
    });
  }

  iframeContent(type, isMerchant = false) {
    const ref = RootAppComponent.dialog.open(HtmlIframeInputComponent, {
      data: {
        inputType: type,
        isMerchant,
        integrationType: 'popup',
      },
      width: '800px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        this.manageInsertionAll(res);
      }
      ref.close();
    });
  }

  addDocument() {
    const ref = RootAppComponent.dialog.open(EditorDocumentCreateComponent, {
      data: {},
      width: '800px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      ref.close();

      ref.afterClosed().subscribe((c) => {
        if (res && res.ID) {
          const ref2 = RootAppComponent.dialog.open(EditorComponentDocumentViewComponent, {
            data: {
              documentID: res.ID,
              document: res,
              extension: res.ExtensionOrMimeType,
              title: res.Label,
              description: this.getTypeLabel(res.DocumentType_Code),
            },
            width: '800px',
          });
          ref2.componentInstance.close.subscribe((a) => {
            ref2.close();
          });

          ref2.componentInstance.getResult.subscribe((res) => {
            if (res) {
              this.manageInsertionAll(res);
            }
            ref2.close();
          });
        }
      });
    });
  }

  displayGallery() {
    const ref = RootAppComponent.dialog.open(EditorDocumentGalleryComponent, {
      data: {},
      width: '1100px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        this.manageInsertionAll(res);
      }
      ref.close();
    });
  }

  getTypeLabel(d) {
    if (d) {
      if (d === 'EDTRBKG') {
        return 'Background';
      } else if (d === 'EDTRCHR') {
        return 'Character';
      } else if (d === 'EDTRDOC') {
        return 'Document';
      } else if (d === 'EDTRIMG') {
        return 'Image';
      } else if (d === 'EDTRJVS') {
        return 'Javascript';
      } else if (d === 'EDTRSTL') {
        return 'Style';
      }
    }

    return null;
  }
}
