import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { ClipboardService } from 'ngx-clipboard';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { CodeHTMLViewComponent } from '../../../shared/components/html-view/html-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { EditorComponentViewComponent } from '../editor-component-view/editor-component-view.component';
import { EditorComponentService } from '../shared/editor-component.service';

@Component({
  selector: 'app-editor-component-create-edit',
  templateUrl: './editor-component-create-edit.component.html',
  styleUrls: ['./editor-component-create-edit.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class EditorComponentCreateEditComponent implements OnInit {
  @Input()
  editorComponentID;
  isEditStyle = false;
  _isEditStyle = false;
  componentID = 'ID' + btoa(Math.random().toString()).substring(2, 20);

  editorComponent;

  descriptionHTML = 'Choose the editor type to create HTML content,you can also change the view type.';
  descriptionCSSHTML =
    'Choose the editor type to create HTML content to <strong>preview your style</strong> ,you can also change the view type.';

  isContentPage = false;

  createdRecord;
  description;
  label;

  typeCode = 'Feature';
  natureCode = 'PageTem';

  @ViewChild('wizard', { static: false }) wizard: WizardComponent;
  isDefault = true;
  isActive = true;

  sessionType;

  natures = [
    {
      Code: 'Style',
      Label: 'Style',
    },
    {
      Code: 'Template',
      Label: 'Component',
    },
    {
      Code: 'PageTem',
      Label: 'Page Template',
    },
  ];
  types = [];
  loaded = false;

  defaultStyle;
  defaultContent;
  style = '';
  styleComponent = '';
  styleTemplate = '';

  content = `
  <h1>This is Title</h1>
  <h3>This is Sub Title</h3>\n
  <p>This Text</p>\n
  <strong>This Bold Text</strong><br> \n
  <a href="" target="_blank">This Link</a><br><br> \n
  <img src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/cover/smile-cover.png"><br><br> \n
  <iframe src="https://player.vimeo.com/video/687006897?h=d2503f34d3"></iframe>`;
  contentComponent = '';
  contentTemplate = '';

  isLoaded = false;

  constructor(
    private _clipboardService: ClipboardService,
    private router: Router,
    private lookupService: LookupService,
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private editorComponentService: EditorComponentService
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['editorComponentID']) {
        this.editorComponentID = params['editorComponentID'];
      }

      this.authenticationService.getSessionType().subscribe((r) => {
        this.sessionType = r;

        this.setup();
      });
    });
  }

  setup() {
    this.lookupService.getLookup('CodeLookup', 'Editor_Component_Type').subscribe((res) => {
      this.types = res;
    });
    if (this.editorComponentID) {
      const p = {
        fields:
          'ID,DateTimeCreated,Style,Content,Merchant_key,Merchant_Trading_As,Label,Description,Style,Content,Is_Default,Nature.Code,Is_Active',
      };
      this.editorComponentService
        .getEditorComponentDetails(this.editorComponentID, p, this.sessionType)
        .subscribe((res) => {
          if (res) {
            this.editorComponent = res;

            this.buildItem();
          }
        });
    } else {
      this.styleComponent = `#${this.componentID} {\n\n}`;
      this.loaded = true;
    }
  }

  buildItem() {
    if (this.editorComponent) {
      if (this.editorComponent['ID']) {
        this.componentID = 'ID' + this.editorComponent['ID'];
      }

      if (this.editorComponent['Label']) {
        this.label = this.editorComponent['Label'];
      }

      if (this.editorComponent['Description']) {
        this.description = this.editorComponent['Description'];
      }

      if (this.editorComponent['Is_Active'] === '1') {
        this.isActive = true;
      } else {
        this.isActive = false;
      }

      if (this.editorComponent['Content']) {
        this.defaultContent = this.editorComponent['Content'];
      }

      if (this.editorComponent['Style']) {
        this.isEditStyle = true;
        this._isEditStyle = true;
        this.defaultStyle = this.editorComponent['Style'];
      }

      if (this.editorComponent['Is_Default'] === '1') {
        this.isDefault = true;
      } else {
        this.isDefault = false;
      }

      if (this.natureCode == 'Style' || this.natureCode == 'PageTem') {
        if (this.editorComponent['Style']) {
          this.style = this.editorComponent['Style'];
        }

        if (this.editorComponent['Content']) {
          this.content = this.editorComponent['Content'];
        }
      }

      if (this.editorComponent['Type.Code']) {
        this.typeCode = this.editorComponent['Type.Code'];
      }

      if (this.editorComponent['Nature.Code']) {
        this.natureCode = this.editorComponent['Nature.Code'];
      }

      this.styleComponent = `#${this.componentID} {\n\n}`;
    }

    this.loaded = true;
  }

  componentList() {
    this.router.navigate(['/merchant', { outlets: { page: ['editor-component-list-overview'] } }]);
  }

  ViewHtmlContent(c) {
    if (c) {
      const isPreview = true;
      const isCode = true;

      const ref2 = RootAppComponent.dialog.open(CodeHTMLViewComponent, {
        data: {
          icons: 'fas fa-eye',
          title: 'preview',
          content: c,
          isPreview,
          isCode,
        },
        width: '1000px',
      });
      ref2.componentInstance.onConfirm.subscribe((res) => {
        ref2.close();
      });
    }
  }

  getStyle(d, type = 'style') {
    if (type == 'style') {
      this.style = d;

      this.getContent(null, false, type);
    } else if (type == 'template') {
      this.styleTemplate = d;

      this.getContent(null, false, type);
    } else if (type == 'component') {
      this.styleComponent = d;

      this.getContent(null, false, type);
    }
  }

  getContent(d = null, updateContent = true, type = 'style') {}

  copyID() {
    if (this.componentID) {
      this._clipboardService.copy(`#${this.componentID} `);

      NotifyAppComponent.displayToast('success', 'success', 'Copied');
    }
  }

  save(isProceed = true) {
    const p: any = {
      content: null,
      style: null,
      label: this.label,
      description: this.description,
      isActive: this.isActive,
      isDefault: this.isDefault,
      typeCode: this.typeCode,
      natureCode: this.natureCode,
    };

    if (this.natureCode === 'Style') {
      p.content = this.content;
      p.style = this.style;
    } else if (this.natureCode === 'Template') {
      p.content = this.contentComponent;
      p.style = this.styleComponent;
      p.componentID = this.componentID;
    } else if (this.natureCode === 'PageTem') {
      p.content = this.contentTemplate;
      p.style = this.styleTemplate;
    }

    if (this._isEditStyle !== true) {
      p.style = 'void';
    }

    if (this.editorComponentID) {
      this.editorComponentService
        .modifyEditorComponent(this.editorComponentID, p, this.sessionType)
        .subscribe((res) => {
          if (res) {
            NotifyAppComponent.displayToast('success', 'success', 'Editor Component is created');

            if (isProceed == true) {
              this.createdRecord = res;
              this.proceed();
            }
          }
        });
    } else {
      this.editorComponentService.createEditorComponent(p, this.sessionType).subscribe((res) => {
        if (res && res.ID) {
          NotifyAppComponent.displayToast('success', 'success', 'Editor Component is created');

          if (isProceed == true) {
            this.createdRecord = res;
            this.proceed();
          } else {
            window.history.replaceState({}, '', `/merchant/(page:editor-component-edit/${res.ID})`);
            this.editorComponentID = res.ID;
          }
        }
      });
    }
  }

  proceed(isContentPage = false) {
    UtilsClass.scrollUp();
    this.wizard.goToNextStep();

    this.isContentPage = isContentPage;
  }

  stepBack(isContentPage = false) {
    UtilsClass.scrollUp();
    this.wizard.goToPreviousStep();
    this.isContentPage = isContentPage;
  }

  getStyleComponent(e) {
    this.styleComponent = e;
  }

  getContentComponent(e) {
    this.contentComponent = e;
  }

  getStyleStyle(e) {
    this.style = e;
  }

  getContentStyle(e) {
    this.content = e;
  }

  getStyleTemplate(e) {
    this.styleTemplate = e;
  }

  getContentTemplate(e) {
    this.contentTemplate = e;
  }

  createNew() {
    this.router.navigate(['/merchant', { outlets: { page: ['editor-component-create-redirect'] } }]);
  }

  view(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(EditorComponentViewComponent, {
        data: {
          editorComponentID: id,
        },
        width: '900px',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  getIsEditStyleEvent(d) {
    this._isEditStyle = d;
  }
}
