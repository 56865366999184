import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { PatientService } from '../shared/patient.service';

import * as moment from 'moment';

@Component({
  selector: 'app-invitation-status',
  templateUrl: './invitation-status.component.html',
  styleUrls: ['./invitation-status.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),

    trigger('ngIfTab', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.3s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.3s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class InvitationStatusComponent implements OnInit {
  @Input()
  invitation;

  @Input()
  invitationID;

  @Input()
  patientCard = true;

  sessionType = 'guest';

  canView: any = false;
  isProceed = false;
  status;

  closeEvent = false;
  closeAction = false;

  @Input() discountValue = 0.0;

  utils = new UtilsClass();
  deferDate = new Date();
  @Input()
  newTab = false;

  constructor(
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private patientService: PatientService
  ) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      if (this.invitation && this.invitation.ID) {
        this.setup();
      } else {
        this.patientService.getCustomerInvitation(this.invitationID, this.sessionType).subscribe((res) => {
          if (res) {
            this.invitation = res;
            this.setup();
          }
        });
      }
    });
  }

  setup() {
    if (this.invitation) {
      if (this.invitation && this.invitation.ID) {
        this.invitationID = this.invitation.ID;
      }

      if (this.sessionType != 'customer' && this.sessionType != 'admin' && this.sessionType != 'promoter') {
        if (
          this.invitation['Expired'] != '1' &&
          this.invitation['Voided_Really'] != '1' &&
          this.invitation['Voided'] != '1' &&
          this.invitation['PatientChoice.Code'] &&
          this.invitation['PatientChoice.Code'] == 'DEFER'
        ) {
          this.canView = true;
          this.status = 'defered';
        } else {
          this.canView = false;
        }
      } else if (this.sessionType == 'customer' || this.sessionType == 'admin' || this.sessionType == 'promoter') {
        if (
          this.invitation &&
          this.invitation['PatientChoice.Code'] &&
          this.invitation['PatientChoice.Code'] === 'P_APP'
        ) {
          if (this.invitation['Date.Activated'] !== '0000-00-00') {
            this.canView = true;
            this.status = 'activated';
          } else if (this.invitation['Date.Completed'] !== '0000-00-00') {
            this.canView = true;
            this.status = 'completed';
          } else if (this.invitation['Date.FinalApproval'] !== '0000-00-00') {
            this.canView = true;
            this.status = 'finalApproval';
          } else if (this.invitation['Date.Approved'] !== '0000-00-00') {
            this.canView = true;
            this.status = 'approved';
          } else if (this.invitation['Date.Declined'] !== '0000-00-00') {
            this.canView = true;
            this.status = 'declined';
          } else if (this.invitation['Date.Referred'] !== '0000-00-00') {
            this.status = 'referred';
            this.canView = true;
          } else if (this.invitation['Date.Commenced'] !== '0000-00-00') {
            this.canView = true;
            this.status = 'commenced';
          } else if (this.invitation['Date.PlanningToCommence'] !== '0000-00-00') {
            this.canView = true;
            this.status = 'palningToCommence';
          } else {
            this.canView = false;
          }
        } else if (this.invitation) {
          if (this.invitation.Expired == '1') {
            this.canView = true;
            this.status = 'expired';
          } else if (this.invitation.Voided_Really === '1') {
            this.canView = true;
            this.status = 'removed';
          } else if (this.invitation['PatientChoice.Code'] && this.invitation['PatientChoice.Code'] === 'DSMO') {
            this.canView = true;
            this.status = 'applicationChanged';
          } else if (this.invitation['PatientChoice.Code'] && this.invitation['PatientChoice.Code'] === 'NOT') {
            this.canView = true;
            this.status = 'notProceed';
          } else if (this.invitation['PatientChoice.Code'] && this.invitation['PatientChoice.Code'] === 'T_OTH') {
            this.canView = true;
            this.status = 'proceedCash';
          } else if (this.invitation['PatientChoice.Code'] && this.invitation['PatientChoice.Code'] === 'T_OTH_D') {
            this.canView = true;
            this.status = 'proceedCash';
          } else if (this.invitation['PatientChoice.Code'] && this.invitation['PatientChoice.Code'] === 'T_OTH_G') {
            this.canView = true;
            this.status = 'proceedCash';
          } else if (this.invitation['PatientChoice.Code'] && this.invitation['PatientChoice.Code'] === 'DISC') {
            this.canView = true;
            this.status = 'proceedCash';
          } else if (
            this.invitation['PatientChoice.Code'] &&
            (this.invitation['PatientChoice.Code'] === 'P_L1x' ||
              this.invitation['PatientChoice.Code'] === 'P_L2x' ||
              this.invitation['PatientChoice.Code'] === 'P_L3x' ||
              this.invitation['PatientChoice.Code'] === 'P_L4x')
          ) {
            let weeks = 1;

            if (this.invitation['PatientChoice.Code'] === 'P_L2x') {
              weeks = 2;
            } else if (this.invitation['PatientChoice.Code'] === 'P_L3x') {
              weeks = 3;
            } else if (this.invitation['PatientChoice.Code'] === 'P_L4x') {
              weeks = 4;
            }

            this.deferDate = this.utils.getPastTime(new Date(), weeks * 7, 'day');

            const isBefore = moment().isBefore(this.deferDate);

            if (isBefore != true) {
              this.canView = false;
            } else {
              this.canView = true;
              this.status = 'defered';
            }
          } else if (this.invitation['PatientChoice.Code'] == '' || !this.invitation['PatientChoice.Code']) {
            this.canView = true;
            this.status = 'current';
          } else {
            this.canView = false;
          }
        } else {
          this.canView = false;
        }
      }
    } else {
      this.canView = false;
    }
  }

  goToWakanda() {
    if (this.newTab == true) {
      if (Settings.global['isWakandaIntegrated'] && Settings.global['isWakandaIntegrated'] === true) {
        let invitationCard = Settings.global['wakandaInvitationCardLink'];
        invitationCard = invitationCard.replace('_id_', this.invitationID);
        window.open(invitationCard, '_blank');
      }
    } else {
      if (Settings.global['isWakandaIntegrated'] && Settings.global['isWakandaIntegrated'] === true) {
        let invitationCard = Settings.global['wakandaInvitationCardLink'];
        invitationCard = invitationCard.replace('_id_', this.invitationID);
        window.location.href = invitationCard;
      }
    }
  }

  contactUs() {
    AuthenticationService.contactUSEvent.emit();
  }

  requestNewInvitation() {
    if (this.invitation && this.invitation.ID && this.invitation.Merchant_Key) {
      const data = {
        invitationID: this.invitation.ID,
        targetType: 'merchant',
        targetID: this.invitation.Merchant_Key,
        asGuest: true,
        asProfile: false,
        asPractice: false,
        subject: 'Request new invitation',
      };
      AuthenticationService.contactInputEvent.emit(data);
    }
  }
}
