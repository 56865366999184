<mat-card class="rel supplier-card clearfix mb" @ngIfAnimation>
  <div class="card-header primary-gradient-img clearfix stacked">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <h2 class="summary-header text-left rm-m">Version Report</h2>


      </div>


    </div>
  </div>

  <mat-dialog-content>

    <div class="full-width flex flex" style="max-height: 650px;">
      <div class="two-thrd-width mr" style="border-right: 1px solid #e3e3e3; padding-right: 16px;">
        <div class="  full-width    flex  block ">
          <h3 class="sr-title rm-m  two-thrd-width  text-left ">Consumer</h3>
          <div class="full-width flex">
            <p class="half-width  rm-m"> <strong class="mrs">{{settings['appConsumerVersionNumber']}} </strong>
            </p>
            <p class="full-width rm-m text-right" *ngIf="settings['appConsumerVersionDate']"
              matTooltip="{{ settings['appConsumerVersionDate'] | customDateTime }}">
              {{ formatDate(settings['appConsumerVersionDate']) | TimeLabelPast }} ago
            </p>
          </div>
        </div>

        <div class="  full-width    flex  block   impair">
          <h3 class="sr-title  rm-m   two-thrd-width  text-left  ">Integration</h3>
          <div class="full-width flex">
            <p class="half-width  rm-m"> <strong class="mrs">{{settings['appIntegrationVersionNumber']}}
              </strong>
            </p>
            <p class="full-width rm-m text-right" *ngIf="settings['appIntegrationVersionDate']"
              matTooltip="{{ settings['appIntegrationVersionDate'] | customDateTime }}">
              {{ formatDate(settings['appIntegrationVersionDate']) | TimeLabelPast }} ago
            </p>
          </div>
        </div>
        <div class="  full-width flex     block  impair">
          <h3 class="sr-title  rm-m  two-thrd-width   text-left ">Merchant</h3>
          <div class="full-width flex">
            <p class="half-width  rm-m"> <strong class="mrs">{{settings['appMerchantVersionNumber']}} </strong>
            </p>
            <p class="full-width rm-m text-right" *ngIf="settings['appMerchantVersionDate']"
              matTooltip="{{ settings['appMerchantVersionDate'] | customDateTime }}">
              {{ formatDate(settings['appMerchantVersionDate']) | TimeLabelPast }} ago
            </p>
          </div>
        </div>

        <div class="  full-width   flex  block ">
          <h3 class="sr-title  rm-m  two-thrd-width text-left  ">Promoter</h3>
          <div class="full-width flex">
            <p class="half-width  rm-m"> <strong class="mrs">{{settings['appPromoterVersionNumber']}} </strong>
            </p>
            <p class="full-width rm-m text-right" *ngIf="settings['appPromoterVersionDate']"
              matTooltip="{{ settings['appPromoterVersionDate'] | customDateTime }}">
              {{ formatDate(settings['appPromoterVersionDate']) | TimeLabelPast }} ago
            </p>
          </div>
        </div>


        <div class="  full-width    flex  block ">
          <h3 class="sr-title  rm-m   two-thrd-width  text-left ">Public Site</h3>
          <div class="full-width flex">
            <p class="half-width  rm-m"> <strong class="mrs">{{settings['appPublicSiteVersionNumber']}}
              </strong>
            </p>
            <p class="full-width rm-m text-right" *ngIf="settings['appPublicSiteVersionDate']"
              matTooltip="{{ settings['appPublicSiteVersionDate'] | customDateTime }}">
              {{ formatDate(settings['appPublicSiteVersionDate']) | TimeLabelPast }} ago
            </p>
          </div>
        </div>
        <div class="  full-width    flex  block   impair">
          <h3 class="sr-title  rm-m  full-width  text-left  ">Last Cache Refresh</h3>
          <div class="full-width flex">

            <p class="full-width  rm-m text-right" *ngIf="settings['cacheClearupDate']"
              matTooltip="{{settings['cacheClearupDate'] | customDateTime}}">

              {{formatDate(settings['cacheClearupDate']) | TimeLabelPast}} ago
            </p>
          </div>
        </div>


      </div>


      <div class="full-width    " style="overflow-y: auto;height: 560px; width:500px;     padding-right: 20px; "
        *ngIf=" versionDescriptions && versionDescriptions.length>0 && isLoaded==true">


        <div class="full-width flex" *ngFor="let version of versionDescriptions| reverseArray">

          <mat-card class="full-width mb">
            <div class="colour-stripe"></div>
            <div class="full-width flex app-version-holder">

              <div class="full-width app-version-container ">

                <h3 class="sr-title  rm-m   full-width   text-left " *ngIf="version.versionDate"
                  matTooltip="{{ version['versionDate'] | customDateTime }}">
                  <span>{{ formatDate(version['versionDate']) | TimeLabelPast }} ago</span>
                </h3>




                <div class="full-width mt" *ngIf="version && version.applications && version.applications.length>0">


                  <p class="small app-version"
                    *ngFor="let app of version['applications']   | OrderArrayObject: ['name'] ">
                    <strong>{{app.name}}</strong>: {{app.version}}
                  </p>
                </div>

                <div class="full-width mt" *ngIf="version && (!version.applications || ( version.applications && version.applications.length==0))">


                  <p class="small app-version">
                    No application updated for this version.
                  </p>
                </div>

              </div>
            </div>


            <div class="full-width mb  " [innerHTML]="version['versionDescription'] | safeHtml"></div>




          </mat-card>
        </div>


      </div>


      <div class="full-width ml " style=" overflow-y: auto;"
        *ngIf=" settings && isLoaded==true && settings['versionDescription'] && (!versionDescriptions || versionDescriptions.length==0)">


        <div class="full-width mb  " [innerHTML]="settings['versionDescription'] | safeHtml"></div>

        <p class="full-width rm-m text-left" *ngIf="settings['versionDate']"
          matTooltip="{{ settings['versionDate'] | customDateTime }}">
          {{ formatDate(settings['versionDate']) | TimeLabelPast }} ago
        </p>
      </div>


    </div>
  </mat-dialog-content>
</mat-card>
