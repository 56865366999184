<form class="docEdit" #createTreatmentDoc="ngForm">
  <mat-dialog-content *ngIf="treatmentDocumentID && treatmentDocument">
    <div class="card-header primary-gradient-img clearfix inModal" *ngIf="treatmentDocumentID">
      <div class="row clearfix">
        <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModal()">
          <mat-icon>clear</mat-icon>
        </button>

        <div class="pull-left thmb" *ngIf="treatmentDocumentID && treatmentDocument">
          <app-ep-document-thumbnail-view class="full-width thumbnail-card" *ngIf="!thumbnailFileID"
                                          [documentID]="treatmentDocument['Document_key']">
          </app-ep-document-thumbnail-view>
          <div class="ep-view-img-thumbnail no-file-fnd" *ngIf="thumbnailFileID"
               [style.background-image]="newThumbnailPicture"></div>
        </div>
        <div class="row titleArea clearfix">
          <h2 class="summary-header rm-m">
            Edit {{ "KEYWORD.treatment" | translate }} document
            <span class="subLabel">{{ documentLabel }}</span>
          </h2>
        </div>
      </div>
    </div>

    <div class="row clearfix">
      <div class="icons-col">
        <mat-icon class="fas fa-image"></mat-icon>
      </div>
      <div class="row-content-col row clearfix">
        <h3 class="sr-title rm-m">Update Thumbnail</h3>
        <p class="small rm-mb">A new thumbnail image can be uploaded by clicking or dragging on the grey area here.</p>
      </div>
    </div>
    <div class="row clearfix">
      <!-- <app-file-uploader [isDragable]="true" class="text-center" (onCompleteAll)="onCompleteAllFile($event)"
        [limit]="1"></app-file-uploader> -->
      <app-file-uploader class="text-center" [isFixedAspectRatio]="true" [aspectRatio]="'16/9'" [isDragable]="true"
                         [limit]="1" (onCompleteAll)="onCompleteAllFile($event)"></app-file-uploader>
    </div>

    <div class="row clearfix mt">
      <div class="icons-col">
        <mat-icon class="fas fa-pen-alt"></mat-icon>
      </div>
      <div class="row-content-col row clearfix">
        <div class="row clearfix">
          <h3 class="sr-title pull-left rm-mt">Update document details</h3>
        </div>

        <div class="row clearfix flex">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Document Label</mat-label>
            <input class="mat-block largeInput" [(ngModel)]="documentLabel" name="documentLabel" matInput
                   placeholder="Document Label" required/>
          </mat-form-field>

          <mat-slide-toggle [(ngModel)]="isRequired" name="isRequired"> Mandatory reading</mat-slide-toggle>
        </div>

        <div class="row clearfix">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Document Description</mat-label>
            <textarea class="dentMsg increase-input-field" [(ngModel)]="documentDescription" name="documentDescription"
                      matInput placeholder="Document Description"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row clearfix">
      <div class="icons-col">
        <mat-icon class="fas fa-folder-open"></mat-icon>
      </div>

      <div class="row-content-col row clearfix">
        <div class="row clearfix">
          <h3 class="sr-title rm-m">Move document</h3>
          <p class="small">
            Choose a new location to store this document - You can select the category, type or brand.
          </p>
        </div>

        <!-- <div class="clearfix  well"> -->

        <div class="row clearfix flex full-width">
          <!-- category -->

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Category</mat-label>

            <mat-select class=" " [(ngModel)]="categoryID" [required]="false"
                        (selectionChange)="onSelectSimpleCategory(categoryID)" name="category" placeholder="Category"
                        required>
              <mat-option *ngFor="let category of categories" [value]="category['ID']">
                {{ category['Label'] }}
              </mat-option>
            </mat-select>

            <button class="" *ngIf="categoryID" (click)="$event.stopPropagation(); viewCategory(categoryID)"
                    matTooltip="View Category" color="accent" mat-button matSuffix>
              <mat-icon>visibility</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="row clearfix flex full-width">
          <!-- type -->

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Type</mat-label>

            <mat-select class=" " [(ngModel)]="typeID" [required]="false" (selectionChange)="onSelectSimpleType(typeID)"
                        name="type" placeholder="Type">
              <mat-option *ngFor="let type of types" [value]="type['ID']">
                {{ type['Label'] }}
              </mat-option>
            </mat-select>

            <button class="" *ngIf="typeID" (click)="$event.stopPropagation(); viewType(typeID)" matTooltip="View Type"
                    color="accent" mat-button matSuffix>
              <mat-icon>visibility</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="row clearfix flex full-width">
          <!-- brand -->

          <!-- type -->
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Brand</mat-label>

            <mat-select class=" " [(ngModel)]="brandID" [required]="false"
                        (selectionChange)="onSelectSimpleBrand(brandID)" name="brand" placeholder="Brand">
              <mat-option *ngFor="let brand of brands" [value]="brand['ID']">
                {{ brand['Label'] }}
              </mat-option>
            </mat-select>

            <button class="" *ngIf="brandID" (click)="$event.stopPropagation(); brandID(brandID)"
                    matTooltip="View Brand" color="accent" mat-button matSuffix>
              <mat-icon>visibility</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="row clearfix flex full-width">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Product</mat-label>

            <mat-select class=" " [(ngModel)]="productID" [required]="false" name="product" placeholder="Product">
              <mat-option *ngFor="let product of products" [value]="product['ID']">
                {{ product['Label'] }}
              </mat-option>
            </mat-select>

            <button class="" *ngIf="productID" (click)="$event.stopPropagation(); viewProduct(productID)"
                    matTooltip="View Product" color="accent" mat-button matSuffix>
              <mat-icon>visibility</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <!-- </div> -->
      </div>
    </div>

    <hr/>
    <mat-dialog-actions class="button-row row text-center clearfix">
      <button *ngIf="documentID" (click)="viewDocument()" mat-raised-button color="accent">View Document</button>

      <button [disabled]="!createTreatmentDoc.form.valid" (click)="editDocument()" mat-raised-button color="primary">
        Save
      </button>
    </mat-dialog-actions>
  </mat-dialog-content>
</form>
