<ipv-page
  *ngIf="viewModel$ | async as viewModel"
  [pageMode]="pageMode"
  [modalTitle]="modalTitle"
  [isFetchingInitialData]="isFetchingInitialData"
  [headerSize]="'smaller'"
  [dataHeader]="{
    title: title,
    counterValue: viewModel.appliances.length,
    imageSrc: titleIcon
  }"
>
  <div class="row clearfix flex mb">
    <div class="full-width flex">
      <button
        *ipvIsPromoterOrAdmin
        (click)="add(viewModel.industryType)"
        mat-raised-button
        style="margin-right: 5px"
        color="primary"
      >
        <mat-icon>add</mat-icon>
        Add Product
      </button>
    </div>

    <mat-button-toggle-group class="btn-group rm-m half-width" (change)="setIsAvailableFilter($event.value)">
      <mat-button-toggle class="" [value]="null">
        All
      </mat-button-toggle>

      <mat-button-toggle class="" [value]="'1'">
        Available
      </mat-button-toggle>

      <mat-button-toggle class="" [value]="'0'">
        Not Available
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <ipv-data-table-control-container class="mt">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Label</mat-label>
      <div style="display: flex">
        <input
          class="clearfix"
          [value]="viewModel.productFilter"
          (input)="setProductFilter($event.target.value)"
          name="Labels"
          matInput
        />
        <ipv-clear-search-button
          *ngIf="viewModel.productFilter"
          (click)="setProductFilter('')"
        ></ipv-clear-search-button>
      </div>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Model</mat-label>
      <div style="display: flex">
        <input
          class="clearfix"
          [value]="viewModel.modelFilter"
          (input)="setModelFilter($event.target.value)"
          name="Labels"
          matInput
        />
        <ipv-clear-search-button *ngIf="viewModel.modelFilter" (click)="setModelFilter('')"></ipv-clear-search-button>
      </div>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>SKU</mat-label>
      <div style="display: flex">
        <input
          class="clearfix"
          [value]="viewModel.skuFilter"
          (input)="setSkuFilter($event.target.value)"
          name="Labels"
          matInput
        />
        <ipv-clear-search-button *ngIf="viewModel.skuFilter" (click)="setSkuFilter('')"></ipv-clear-search-button>
      </div>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>UPC</mat-label>
      <div style="display: flex">
        <input
          class="clearfix"
          [value]="viewModel.upcFilter"
          (input)="setUpcFilter($event.target.value)"
          name="Labels"
          matInput
        />
        <ipv-clear-search-button *ngIf="viewModel.upcFilter" (click)="setUpcFilter('')"></ipv-clear-search-button>
      </div>
    </mat-form-field>
  </ipv-data-table-control-container>

  <ipv-data-table
    [titleCaseEnabled]="false"
    [table]="viewModel.appliances"
    [hide]="hiddenColumns"
    [rename]="renamedColumns"
    [order]="order"
    [menuTemplate]="actions"
    [customComponents]="{
      logo: logo,
      Is_Available: isAvailableIndicator,
      SellPrice: sellPrice,
      Is_SerialNum_Mandatory: isSerialised
    }"
    (itemClicked)="viewProductSelect($event, viewModel.industryType)"
  >
  </ipv-data-table>

  <ng-template #actions let-record>
    <button (click)="viewProduct(record, viewModel.industryType)" mat-menu-item>
      <mat-icon class="fas fa-box-open"></mat-icon>
      <span>View Product</span>
    </button>

    <button *ipvIsPromoterOrAdmin (click)="updateProduct(record, viewModel.industryType)" mat-menu-item>
      <mat-icon>description</mat-icon>
      <span>Edit Product</span>
    </button>

    <ng-container *ipvIsPromoterOrAdmin>
      <button *ngIf="record['Is_Available'] == '1'" (click)="makeUnavailable(record)" mat-menu-item>
        <mat-icon>cancel</mat-icon>
        <span>Make Unavailable</span>
      </button>

      <button *ngIf="record['Is_Available'] == '0'" (click)="makeAvailable(record)" mat-menu-item>
        <mat-icon>check_circle</mat-icon>
        <span>Make Available</span>
      </button>
    </ng-container>
  </ng-template>
</ipv-page>

<ng-template #logo let-record>
  <app-ep-document-view-image
    class="full-width view-logo"
    [defaultPicture]="
      'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/product-icon.png)'
    "
    [link]="getThumbnailImage(record)"
    [hideZoomButton]="true"
  >
  </app-ep-document-view-image>
</ng-template>

<ng-template #isAvailableIndicator let-record>
  <ipv-list-active-label [isActive]="record['Is_Available'] | binaryBool"></ipv-list-active-label>
</ng-template>

<ng-template #isSerialised let-record>
  <ipv-list-active-label [isActive]="record['Is_SerialNum_Mandatory'] | binaryBool"></ipv-list-active-label>
</ng-template>

<ng-template #sellPrice let-record>
  {{ record['SellPrice'] | customCurrency }}
</ng-template>
