<!-- <div class="container"> -->
<form #editForm="ngForm">
  <div class="row clearfix discount-edit rel">
    <div class="card-header primary-gradient-img clearfix inModal" *ngIf="isModal == true">
      <div class="row clearfix">
        <!-- Modal dismiss button -->
        <button class="btn-close btn-clear mat-button white" matDialogClose>
          <mat-icon>clear</mat-icon>
        </button>
        <div class="row titleArea clearfix full-width">
          <mat-icon class="fas fa-money-bill-wave pull-left white" aria-hidden="true"></mat-icon>
          <h2 class="summary-header rm-m white">
            Cash discount settings
            <span class="subLabel">Savings for cash/card payments in full</span>
          </h2>
        </div>
      </div>
    </div>

    <!-- adjust discount settings -->
    <div class="row clearfix">
      <div class="icons-col">
        <mat-icon class="fas fa-money-bill-wave"></mat-icon>
      </div>
      <div class="row-content-col">
        <h3 class="sr-title sm-h3 rm-m mb" *ngIf="isModal == false">Cash discount settings</h3>
        <div class="row clearfix mb">
          <!-- toggle discount on or off -->
          <mat-slide-toggle
            class="enable-discount mb"
            [checked]="cashDiscountOffered == true"
            (change)="cashDiscountOffered = !cashDiscountOffered"
            color="accent"
          >
            <span *ngIf="isModal == true">Enable full payment discount for this invitation</span>
            <span *ngIf="isModal == false">Enable full payment discounts</span>
          </mat-slide-toggle>
        </div>

        <div class="row clearfix flex discount-settings-area" [class.active]="cashDiscountOffered == true">
          <mat-form-field class="full-width" appearance="outline">
            <!-- discount percent input -->

            <mat-label>"Discount amount (%)</mat-label>

            <input
              class="mat-block"
              [(ngModel)]="cashDiscountPercent"
              [max]="100"
              [min]="0"
              type="number"
              matInput
              placeholder="Discount amount (%)"
              name="discount-amt"
              required
            />
            <span class="posPrefix" matSuffix>
              <strong>%</strong>
            </span>
          </mat-form-field>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Expiry timeframe (days)</mat-label>
            <!-- discount timeframe/expiry input -->
            <input
              class="mat-block"
              [(ngModel)]="cashDiscountExpiredDays"
              type="number"
              matInput
              placeholder="Expiry timeframe (days)"
              name="discount-exp"
              required
            />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row clearfix">
      <div class="icons-col">
        <mat-icon class="fas fa-dollar-sign" aria-hidden="true"></mat-icon>
      </div>
      <div class="row-content-col discount-settings-area" [class.active]="cashDiscountOffered == true">
        <h3 class="sr-title sm-h3 rm-m mb">Discount threshold</h3>
        <p class="small rm-mt">
          The discount threshold is the minimum value of an invitation before a cash discount is applicable. Example: A
          $200 threshold will only apply discounts for {{ "KEYWORD.treatment" | translate }}s above $200.
        </p>
        <!-- maximum amount of discount -->
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Discount threshold($)</mat-label>

          <span matPrefix> $ </span>
          <input
            class="mat-block"
            [(ngModel)]="cashDiscountMinAmount"
            type="number"
            matInput
            placeholder="Discount threshold($)"
            name="discount-thr"
          />
        </mat-form-field>
      </div>
    </div>

    <hr class="mt mb"/>
    <div class="row clearfix">
      <div class="icons-col">
        <mat-icon class="fas fa-cogs" aria-hidden="true"></mat-icon>
      </div>
      <div class="row-content-col">
        <h3 class="sr-title sm-h3 rm-m mb">Override Discount Settings</h3>
        <!-- apply settings to all invitations -->
        <mat-slide-toggle
          class="enable-discount mb"
          [checked]="cashDiscountOveridden"
          (change)="cashDiscountOveridden = !cashDiscountOveridden"
          color="accent"
        >
          <span *ngIf="cashDiscountOveridden == false">No overridding of cash discount is possible</span>
          <span *ngIf="cashDiscountOveridden != false"
          >Individual invitations can be set to different values for the cash discount</span
          >
        </mat-slide-toggle>
      </div>
    </div>
  </div>

  <div class="row clearfix text-center">
    <button class="btn-small mr" *ngIf="isModal == true" (click)="closeEvent()" mat-raised-button color="primary">
      Cancel
    </button>
    <button
      [disabled]="
        !editForm.form.valid ||
        (cashDiscountOffered == true && (cashDiscountPercent == 0 || cashDiscountExpiredDays == 0))
      "
      (click)="save()"
      mat-raised-button
      color="accent"
    >
      Save
    </button>
  </div>
</form>
<!-- </div> -->
