<h3 class="sr-title sm-h3">Identification</h3>
<div class="row clearfix">
  <div class="column">
    <div class="icons-col">
      <mat-icon>drive_eta</mat-icon>
    </div>
    <div class="row-content-col">
      <div class="row clearfix">
        <label style="margin-bottom: 10px"> Do you have a drivers license? </label>
        <mat-radio-group class="alternate-theme" [(ngModel)]="hasDriversLicense" (change)="resetData()">
          <mat-radio-button value="1">Yes</mat-radio-button>
          <mat-radio-button value="0">No</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>
</div>
<!-- IF HAS DRIVERS LICENSE -->
<div class="row clearfix animated fadeInDown" *ngIf="hasDriversLicense == 1">
  <div class="column">
    <div class="icons-col">
      <mat-icon>drive_eta</mat-icon>
    </div>
    <div class="row-content-col">
      <div class="row clearfix">
        <!-- License Number -->
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>License Number</mat-label>
          <input
            [(ngModel)]="licenceDriverID"
            (change)="onValidate()"
            matInput
            placeholder="License Number"
            value=""
            required
          />
        </mat-form-field>
      </div>
      <!-- State has been removed - get State data from address input -->
    </div>
  </div>
</div>
<!-- IF NO DRIVERS LICENSE -->
<div class="row clearfix animated fadeInDown" *ngIf="hasDriversLicense == 0">
  <div class="column">
    <div class="icons-col">
      <mat-icon>account_box</mat-icon>
    </div>
    <div class="row-content-col">
      <div class="row clearfix">
        <!-- Type of ID -->
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Type of identification</mat-label>
          <mat-select class="full-width" placeholder="Type of identification" name="typeOfId" required>
            <mat-option *ngFor="let typeOfId of typeOfIds" [value]="typeOfId.code">
              {{ typeOfId.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row clearfix">
        <!-- ID Number -->
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Enter your ID number</mat-label>
          <input
            [(ngModel)]="identificationID"
            (change)="onValidate()"
            matInput
            placeholder="Enter your ID number"
            value=""
            required
          />
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
