import { BehaviorSubject, fromEvent as observableFromEvent, merge as observableMerge, Observable } from 'rxjs';

import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { InvitationService } from '../../invitation/shared/services/invitation.service';

@Component({
  selector: 'app-existing-patient-list-archives',
  templateUrl: './existing-patient-list-archives.component.html',
  styleUrls: ['./existing-patient-list-archives.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ExistingPatientListArchivesComponent implements OnInit {
  displayedColumns = ['ExistingPatient', 'Treatment', 'Product', 'Source', 'Actions'];
  fields = [
    { field: 'Date', value: 'DateTimeCreated' },
    {
      field: 'Operator',
      value: 'DentistContactName',
    },
    { field: 'Name', value: 'Name' },
    { field: 'Product', value: 'ProductName' },
    {
      field: 'Email Status',
      value: 'Status.Email.Short',
    },
    { field: 'Invitation Status', value: 'Status.Invitation.Short' },
    {
      field: 'Response',
      value: 'ExistingPatientChoice.Label_LongThirdPerson',
    },
    { field: 'Contract Status', value: 'Status.Contract.Short' },
    {
      field: 'Application Status',
      value: 'Status.Application.Short',
    },
  ];
  filters = ['Steve Brown', 'Consumer Site', 'Angelika Sajani', 'Administrator'];
  selectedValue = '';
  selectedField: String = '';
  public invitationsDB: LoadInv | null;
  dataSource: InvitationDataSource | null;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: true }) filter: ElementRef;

  constructor(private invitationService: InvitationService) {}

  ngOnInit() {
    this.invitationsDB = new LoadInv(this.invitationService);
    this.dataSource = new InvitationDataSource(this.invitationsDB, this.paginator, this.sort);
    observableFromEvent(this.filter.nativeElement, 'keyup')
      .pipe(debounceTime(150), distinctUntilChanged())
      .subscribe(() => {
        if (!this.dataSource) {
          return;
        }
        const filter = {
          field: this.selectedField,
          value: this.filter.nativeElement.value ? this.filter.nativeElement.value : '',
        };
        this.dataSource.filter = filter;
      });
  }

  filterInvitations() {
    const filter = {
      field: this.selectedField,
      value: this.selectedValue,
    };
    this.dataSource.filter = filter;
  }

  selectFeild() {}
}

export class LoadInv implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  invitations: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  constructor(private invitationService: InvitationService) {
    const payload = {
      Voided: 0,
      Merchant_Key: 1111111,
      section: 0,
      limit: 100,
    };

    this.invitationService.getInvitations(payload).subscribe((res) => {
      if (res.success) {
        this.count = res.count;
        this.invitations = res.data;
        this.dataChange.next(res.data.slice());
      }
    });
  }

  ngOnInit() {}
}

export class InvitationDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(filter: any) {
    this.field = filter.field;
    this._filterChange.next(filter.value);
  }

  filteredData: any[] = [];

  constructor(private _invDatabase: any, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._invDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._invDatabase.data.slice().filter((item: any) => {
          let f = '';
          if (this.field == 'Name') {
            f = item['FirstName'] + item['LastName'];
          } else {
            f = item[this.field] || '';
          }

          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'Date':
          [propertyA, propertyB] = [a['DateTimeCreated'], b['DateTimeCreated']];
          break;
        case 'Operator':
          [propertyA, propertyB] = [a.DentistContactName, b.DentistContactName];
          break;
        case 'ExistingPatient':
          [propertyA, propertyB] = [a.FirstName, b.FirstName];
          break;
        case 'Treatment':
          [propertyA, propertyB] = [a['Service.Type'], b['Service.Type']];
          break;
        case 'Amount':
          [propertyA, propertyB] = [a['Service.Value'], b['Service.Value']];
          break;
        case 'Product':
          [propertyA, propertyB] = [a.ProductName, b.ProductName];
          break;
        case 'Source':
          [propertyA, propertyB] = [a['ExistingPatientChoice.Code'], b['ExistingPatientChoice.Code']];
          break;
        case 'EmailStatus':
          [propertyA, propertyB] = [a['Status.Email.Short'], b['Status.Email.Short']];
          break;
        case 'InvitationStatus':
          [propertyA, propertyB] = [a['Status.Invitation.Short'], b['Status.Invitation.Short']];
          break;
        case 'ApplicationStatus':
          [propertyA, propertyB] = [a['Status.Application.Short'], b['Status.Application.Short']];
          break;
        case 'ContractStatus':
          [propertyA, propertyB] = [a['Status.Contract.Short'], b['Status.Contract.Short']];
          break;
        case 'Response':
          [propertyA, propertyB] = [
            a['ExistingPatientChoice.Label_LongThirdPerson'],
            b['ExistingPatientChoice.Label_LongThirdPerson'],
          ];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
