import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { OperatorService } from '../shared/operator.service';

@Component({
  selector: 'app-operator-session-overview',
  templateUrl: './operator-session-overview.component.html',
  styleUrls: ['./operator-session-overview.component.css'],
})
export class OperatorSessionOverviewComponent implements OnInit {
  @Input() operatorID;
  operator;
  isPromoterOrAdmin;

  constructor(
    private operatorService: OperatorService,
    private activeRoute: ActivatedRoute,
    private AuthenticationService: AuthenticationService
  ) {
    this.AuthenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (!this.operatorID && params && params.operatorID) {
        this.operatorID = params.operatorID;
      }
      this.operatorService.getfullOperatorDetails(this.operatorID).subscribe((res) => {
        this.operator = res;
      });
    });
  }
}
