<label class="uploaded text-center mt mb" *ngIf="files && files.length > 0 && title && justify != 'left'">{{
  title
  }}</label>
<label class="uploaded pull-left mt mb" *ngIf="files && files.length > 0 && title && justify == 'left'">{{
  title
  }}</label>

<div
  class="flex full-width uploadedContainer"
  *ngIf="files && files.length > 0"
  @ngIfAnimation
  [class.justify-left]="justify == 'left'"
>
  <mat-card
    class="animate file-container"
    *ngFor="let file of files; let i = index"
    @ngIfAnimation
    [ngClass]="{
      'file-container': !isModal,
      'file-container-modal': isModal
    }"
    [attr.style]="'max-width:' + (hasResponsiveWidth ? '100%' : '23%') | safeStyle"
  >
    <div class="img mat-card-image rel" (click)="openFileContentDialog(file)" matTooltip="View">
      <ng-container *ngIf="file.thumbnail">
        <img [src]="file.thumbnail"/>
      </ng-container>

      <ng-container *ngIf="!file.thumbnail && file.defaultImage === undefined">
        <img
          *ngIf="file && file.documentTypeFromExtension == 'image'"
          [src]="fileLink.replace('id', file._id)"
          (error)="onImageError(file)"
        />
        <img
          *ngIf="file && file.documentTypeFromExtension == 'image' && file.isError == true"
          [src]="fileLink.replace('id', file._id)"
        />
        <img
          *ngIf="file && file.documentTypeFromExtension != 'image'"
          [src]="'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/pdf.png'"
        />
      </ng-container>

      <ng-container *ngIf="!file.thumbnail && file.defaultImage">
        <img
          *ngIf="file && file.documentTypeFromExtension == 'image'"
          [src]="fileLink.replace('id', file._id)"
          (error)="onImageError(file)"
        />
        <img
          *ngIf="file && file.documentTypeFromExtension == 'image' && file.isError == true"
          [src]="file.defaultImage"
        />
        <img *ngIf="file && file.documentTypeFromExtension != 'image'" [src]="file.defaultImage"/>
      </ng-container>
    </div>
    <div class="action-fab-buttons">
      <button class="action" [matMenuTriggerFor]="menu" mat-mini-fab>
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button *ngIf="canEdit" (click)="openFileContentDialog(file)" mat-menu-item>
          <mat-icon>search</mat-icon>
          <span>View</span>
        </button>

        <button (click)="download(file)" mat-menu-item>
          <mat-icon>archive</mat-icon>
          <span>Download</span>
        </button>

        <hr/>

        <button *ngIf="displayThumbnail == true" (click)="editThumbnail(file)" mat-menu-item>
          <mat-icon class="fas fa-image"></mat-icon>
          <span>Update Thumbnail</span>
        </button>

        <button *ngIf="canEdit" (click)="onClickDeleteButton(file)" mat-menu-item>
          <mat-icon>delete</mat-icon>
          <span>Remove</span>
        </button>

        <button *ngIf="canAction" (click)="sendFile(file)" mat-menu-item>
          <mat-icon>chevron_right</mat-icon>
          <span>{{ actionLabel }}</span>
        </button>
      </mat-menu>
    </div>
    <mat-card-content *ngIf="showInfo == true">
      <div class="row clearfix">
        <div class="row clearfix" *ngIf="canEdit == true && hasExtraInfo == true">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Label</mat-label>
            <input class="mat-block" [(ngModel)]="file.fileName" (change)="updateMeta(file)" matInput/>
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Type</mat-label>
            <mat-select [(ngModel)]="file.documentType" (selectionChange)="updateMeta(file)" name="docType">
              <mat-option *ngFor="let documentType of documentTypes" [value]="documentType">
                <span>{{ documentType }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="row clearfix">
          <div class="row clearfix" *ngIf="hasExtraInfo == false">
            <p class="small reduce-marg rm-mb" *ngIf="file.fileName"><strong>Label :</strong> {{ file.fileName }}</p>
            <p class="small reduce-marg rm-mb" *ngIf="file.uploadDate">
              <strong>Upload Date :</strong> {{ file.uploadDate | customDateTime }}
            </p>
            <p class="small reduce-marg" *ngIf="file.length"><strong>Size :</strong> {{ bytesToSize(file.length) }}</p>
          </div>

          <div class="row clearfix" *ngIf="canEdit == false && (file.uploadDate || file.length)">
            <p class="small reduce-marg rm-mb" *ngIf="file.documentType && file.documentType != 'undefined'">
              <strong>Document Type :</strong> {{ file.documentType }}
            </p>

            <p
              class="small reduce-marg rm-mb"
              *ngIf="
                file.extension && file.extension != 'undefined' && util.getDocumentTypeFromExtension(file.extension)
              "
            >
              <strong>Document Nature :</strong> {{ util.getDocumentTypeFromExtension(file.extension) }}
            </p>

            <p class="small reduce-marg" *ngIf="file.uploadDate">
              <strong>Upload Date :</strong> {{ file.uploadDate | customDateTime }}
            </p>
          </div>
        </div>

        <div class="row clearfix pull-left" *ngIf="canPrivateDocument == true && isModulePrivateDocumentActive == true">
          <mat-checkbox
            [(ngModel)]="file.isPrivate"
            (change)="updateMeta(file)"
            matTooltip="Document Contain Private Data"
          >
            Private Document
          </mat-checkbox>
        </div>

        <div class="row clearfix pull-left" *ngIf="canGallery == true">
          <mat-checkbox [(ngModel)]="file.isGallery" (change)="updateMeta(file)" matTooltip="Save file for future use">
            Save to gallery
          </mat-checkbox>
        </div>

        <div class="row clearfix pull-left" *ngIf="canPatientDocument == true">
          <mat-checkbox
            [(ngModel)]="file.isPatientDocument"
            [matTooltip]="'Save as ' + ('KEYWORD.patient' | translate | titlecase) + ' Document for future use'"
            (change)="updateMeta(file)"
          >
            {{ "KEYWORD.patient" | translate | titlecase }} Document
          </mat-checkbox>
        </div>

        <mat-form-field
          class="full-width clearfix mt"
          *ngIf="
            patientDocumentTypes &&
            patientDocumentTypes.length > 0 &&
            canPatientDocument == true &&
            file.isPatientDocument == true
          "
          appearance="outline"
        >
          <mat-label> {{ "KEYWORD.patient" | translate | titlecase }} Document Type</mat-label>
          <mat-select
            [(ngModel)]="file.patientDocumentType"
            [placeholder]="('KEYWORD.patient' | translate | titlecase) + ' Document Type'"
            (ngModelChange)="updateMeta(file)"
            name="DocumentType"
          >
            <mat-option *ngFor="let type of patientDocumentTypes | orderBy: ['label']" [value]="type['code']">
              {{ type.label | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>
</div>
