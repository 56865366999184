<div class="rel text-center">
  <mat-card class="card">
    <div class="row clearfix">
      <h3 class="sr-title rm-m pull-left">
        {{ modalTitle }}
      </h3>
      <div class="pull-right">
        <!-- Modal dismiss button -->
        <button class="btn-close btn-clear mat-button" (click)="close()" matDialogClose>
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
    <hr/>
    <div class="colour-stripe"></div>

    <mat-dialog-content>
      <aw-wizard class="settlementCreate" navBarLayout="large-filled-symbols">
        <aw-wizard-step [navigationSymbol]="{ symbol: 1 }" (stepEnter)="setStep(1)">
          <div @simpleFadeAnimation>
            <div class="row clearfix" *ngIf="priceComparisonSlide == 0" @simpleFadeAnimation>
              <p>
                {{ "BRANDING.Brand Name" | translate | titlecase }} has several ways to get your message into even more
                hands. You
                can evaluate all your options by reviewing each choice below.
              </p>
            </div>

            <!-- price comparison tables (1) -->
            <div class="row clearfix flex price-comparison add-mt add-mb" *ngIf="priceComparisonSlide == 0"
                 @simpleFadeAnimation [matchItemHeight]="matchThis">
              <mat-card *ngIf="limitSelection == false">
                <div class="row clearfix matchThis">
                  <img class="icon-img"
                       src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/boost/sms-list.png"/>
                  <h3 class="sr-title">SMS campaign</h3>
                  <hr/>

                  <p class="mb">
                    Send a companion SMS message to your {{"KEYWORD.patient" | translate}}s to the campaign you
                    selected. Research shows that SMS
                    open rates are as high as 98% by opting for this option you could boost your message to further XXXX
                    {{"KEYWORD.patient" | translate}}s in your database.
                  </p>
                </div>
                <button (click)="setProductOption(1)" mat-stroked-button>
                  More
                  <mat-icon>chevron_right</mat-icon>
                </button>
              </mat-card>

              <mat-card *ngIf="limitSelection == false">
                <div class="row clearfix matchThis">
                  <img class="icon-img"
                       src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/boost/social-list.png"/>
                  <h3 class="sr-title">Social Media posts</h3>
                  <hr/>

                  <p class="mb">
                    We provide everything you need to create engaging posts to your favourite social media platforms,
                    including Facebook, Instagram, Twitter and Linkedin.
                  </p>
                </div>
                <button (click)="setProductOption(5)" mat-stroked-button>
                  More
                  <mat-icon>chevron_right</mat-icon>
                </button>
              </mat-card>

              <mat-card class="primary-gradient-img mat-elevation-z7" *ngIf="limitSelection == false">
                <div class="row clearfix matchThis">
                  <img class="icon-img"
                       src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/boost/access-database.png"/>
                  <h3 class="">Access new {{"KEYWORD.patient" | translate}}s</h3>
                  <hr/>

                  <p class="mb">
                    Want to extend your potential {{"KEYWORD.patient" | translate}} base to an even wider audience?
                    Would you like to connect with
                    more {{"KEYWORD.patient" | translate}}s in your surrounding suburbs, your state or nationally? We
                    can connect you with over 10
                    million Australians using our licensed 3rd party data.
                  </p>
                </div>
                <button (click)="setProductOption(3)" mat-stroked-button>
                  More
                  <mat-icon>chevron_right</mat-icon>
                </button>
              </mat-card>

              <mat-card class="z2">
                <div class="row clearfix matchThis">
                  <img class="icon-img"
                       src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/boost/re-engage.png"/>
                  <h3 class="sr-title">Re-engage with {{ "KEYWORD.patient" | translate | titlecase}}s</h3>
                  <hr/>

                  <p class="mb">
                    We'll send your {{"KEYWORD.patient" | translate}}s an email and SMS campaign, prompting them to
                    complete their {{"KEYWORD.patient" | translate}}
                    profile. Doing this helps to target {{"KEYWORD.patient" | translate}}s who might have a mobile
                    number but no email address (or
                    vice versa) You have XXXX {{"KEYWORD.patient" | translate}}s that are missing critical data.
                  </p>
                </div>
                <button (click)="setProductOption(4)" mat-stroked-button>
                  More
                  <mat-icon>chevron_right</mat-icon>
                </button>
              </mat-card>

              <mat-card class="z2">
                <div class="row clearfix matchThis">
                  <img class="icon-img"
                       src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/boost/clean-list.png"/>
                  <h3 class="sr-title">Wash your data</h3>
                  <hr/>

                  <p class="mb">
                    Using our 3rd party data, we can compare your existing {{"KEYWORD.patient" | translate}} data and
                    match your records, merging
                    any missing data into your database (including phone numbers, emails and home addresses). You have
                    XXXX {{"KEYWORD.patient" | translate}}s with missing data.
                  </p>
                </div>
                <button (click)="setProductOption(2)" mat-stroked-button>
                  More
                  <mat-icon>chevron_right</mat-icon>
                </button>
              </mat-card>
            </div>

            <!--  SMS campaign -->
            <div class="row clearfix product-detail" *ngIf="priceComparisonSlide == 1" @simpleFadeAnimation>
              <div class="row clearfix">
                <h3 class="sr-title sm-h3 rm-mt">SMS campaign</h3>
                <div class="row clearfix flex column">
                  <div class="pull-left mr">
                    <img class="icon-img-lr"
                         src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/boost/sms-list.png"/>
                  </div>
                  <div class="ml pull-left full-width text-left">
                    <p class="matchThis">
                      Using our licensed 3rd party dataset, we can find thousands of {{"KEYWORD.patient" | translate}}s
                      that live around your
                      {{"KEYWORD.practice" | translate}}, giving you unparalleled access to vast numbers of
                      new {{"KEYWORD.patient" | translate}}s!
                    </p>
                    <p class="bold">Pricing varies based on how your new {{"KEYWORD.patient" | translate}}s respond.</p>
                  </div>
                </div>

                <hr/>

                <div class="row clearfix flex text-left">
                  <div class="col">
                    <ul class="check">
                      <li>
                        Every successful new {{"KEYWORD.patient" | translate}} that signs-up carries a flagfall of
                        {{ flagfall1 | customCurrency }}. They are added to your list with limited details, such as
                        phone numbers or addresses being hidden.
                      </li>
                    </ul>
                  </div>
                  <div class="col">
                    <ul class="check">
                      <li>
                        Every time a {{"KEYWORD.patient" | translate}} successfully visits a campaign landing page, the
                        flagfall is
                        {{ flagfall2 | customCurrency }}. They are added to your list with limited details.
                      </li>
                    </ul>
                  </div>
                  <div class="col">
                    <ul class="check">
                      <li>
                        If the {{"KEYWORD.patient" | translate}} then responds to a promoted campaign's call-to-action
                        (i.e. completes an enquiry,
                        books an appointment, makes an application etc) a {{ flagfall3 | customCurrency }} flagfall is
                        charged and they are added as a complete {{"KEYWORD.patient" | translate}} record.
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="row clearfix">
                  <p class="small">
                    Boosting price is based on individual campaign value - some product licensed campaigns may have
                    different costs to others based on the overall value of the advertised products. For more
                    information you can <a class="clicky" (click)="contactUs()">contact us</a>.
                  </p>
                </div>
              </div>
            </div>

            <!-- Wash your data -->
            <div class="row clearfix product-detail" *ngIf="priceComparisonSlide == 2" @simpleFadeAnimation>
              <div class="row clearfix">
                <!-- Wash your data -->

                <div class="row clearfix flex column">
                  <div class="pull-left mr">
                    <img class="icon-img-lr add-mt"
                         src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/boost/clean-list.png"/>
                  </div>
                  <div class="ml pull-left full-width text-left">
                    <p class="rm-mt">
                      We can go through your existing {{"KEYWORD.patient" | translate}} data and match your records
                      against our licensed 3rd party
                      dataset, merging any missing data into your database (including phone numbers, emails and home
                      addresses).
                    </p>
                    <p class="rm-mt">We have identified that you have:</p>

                    <mat-list class="boost-list mb">
                      <mat-list-item>
                        <p class="sr-title">Total {{ "KEYWORD.patient" | translate | titlecase}}s <strong>00</strong>
                        </p>
                        <mat-divider></mat-divider>
                      </mat-list-item>
                      <mat-list-item>
                        <p>Missing Email <strong>00</strong></p>
                      </mat-list-item>
                      <mat-list-item>
                        <p>Missing Mobile <strong>00</strong></p>
                      </mat-list-item>
                      <mat-list-item>
                        <p>Missing Both (Email & Mobile) <strong>00</strong></p>
                      </mat-list-item>
                      <mat-list-item>
                        <p>Missing Address <strong>00</strong></p>
                      </mat-list-item>
                    </mat-list>
                  </div>
                </div>

                <!-- <hr> -->

                <div class="row clearfix flex text-left">
                  <div class="col">
                    <ul class="check">
                      <li>
                        Every successful new {{"KEYWORD.patient" | translate}} that signs-up carries a flagfall of
                        {{ flagfall1 | customCurrency }}. They are added to your list with limited details, such as
                        phone numbers or addresses being hidden.
                      </li>
                    </ul>
                  </div>
                  <div class="col">
                    <ul class="check">
                      <li>
                        Every time a {{"KEYWORD.patient" | translate}} successfully visits a campaign landing page, the
                        flagfall is
                        {{ flagfall2 | customCurrency }}. They are added to your list with limited details.
                      </li>
                    </ul>
                  </div>
                  <div class="col">
                    <ul class="check">
                      <li>
                        If the {{"KEYWORD.patient" | translate}} then responds to a promoted campaign's call-to-action
                        (i.e. completes an enquiry,
                        books an appointment, makes an application etc) a {{ flagfall3 | customCurrency }} flagfall is
                        charged and they are added as a complete {{"KEYWORD.patient" | translate}} record.
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="row clearfix">
                  <p class="small">
                    Boosting price is based on individual campaign value - some product licensed campaigns may have
                    different costs to others based on the overall value of the advertised products. For more
                    information you can <a class="clicky" (click)="contactUs()">contact us</a>.
                  </p>
                </div>
              </div>
            </div>

            <!-- Access new {{"KEYWORD.patient" | translate}}s -->
            <div class="row clearfix product-detail" *ngIf="priceComparisonSlide == 3" @simpleFadeAnimation>
              <div class="row clearfix">
                <!-- Access   {{"BRANDING.Brand Name" | translate}} database -->

                <div class="row clearfix flex column mb">
                  <div class="pull-left mr mt">
                    <img class="icon-img-lr"
                         src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/boost/access-database.png"/>
                  </div>
                  <div class="ml pull-left full-width text-left">
                    <p class="matchThis">
                      Using our licensed 3rd party dataset, we can find thousands of {{"KEYWORD.patient" | translate}}s
                      that live around your
                      practice, giving you unparalleled access to vast numbers of
                      new {{ "KEYWORD.patient" | translate }}s!
                    </p>
                    <p>
                      Boosting price is based on individual campaign value - some product licensed campaigns may have
                      different costs to others based on the overall value of the advertised products.
                    </p>
                  </div>
                </div>

                <div class="row clearfix flex text-left details-table">
                  <table class="full-width">
                    <tbody>
                      <tr>
                        <th>Action</th>
                        <th>Flagfall type</th>
                        <th>Merchant action</th>
                        <th>Cost</th>
                      </tr>

                      <tr>
                        <td>New Contact</td>
                        <td>A small flagfall is charged for each new contact that is added from the database</td>
                        <td>{{"KEYWORD.patient" | translate | titlecase}} is added to your list with redacted contact
                          information
                        </td>
                        <td>$0.10 / {{"KEYWORD.patient" | translate}}</td>
                      </tr>

                      <tr>
                        <td>Landing page visit</td>
                        <td>An additional small flagfall is charged if a new contact visits a campaign landing page</td>
                        <td>Pati{{"KEYWORD.patient" | translate | titlecase}}ent is added to your list with redacted
                          contact
                          information
                        </td>
                        <td>$0.20 / {{"KEYWORD.patient" | translate}}</td>
                      </tr>

                      <tr>
                        <td>{{"KEYWORD.patient" | translate | titlecase}} interaction</td>
                        <td>
                          An additional small flagfall is charged when a contact interacts with a landing page action*
                        </td>
                        <td>{{"KEYWORD.patient" | translate | titlecase}} is added to your list with full details
                          available.
                        </td>
                        <td>$0.30 / {{"KEYWORD.patient" | translate}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p class="small">
                  For more information on our pricing structure you can
                  <a class="clicky" (click)="contactUs()">contact us</a>.
                </p>
              </div>
            </div>

            <!-- Re-engage with {{ "KEYWORD.patient" | translate | titlecase}}s -->
            <div class="row clearfix product-detail" *ngIf="priceComparisonSlide == 4" @simpleFadeAnimation>
              <div class="row clearfix">
                <!-- Re-engage with {{ "KEYWORD.patient" | translate | titlecase}}s -->

                <div class="row clearfix flex column">
                  <div class="pull-left mr">
                    <img class="icon-img-lr"
                         src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/boost/re-engage.png"/>
                  </div>

                  <div class="ml pull-left full-width text-left">
                    <p class="rm-mt">We have identified that you have:</p>

                    <mat-list class="boost-list mb">
                      <mat-list-item>
                        <p class="sr-title">Total {{ "KEYWORD.patient" | translate | titlecase}}s <strong>00</strong>
                        </p>
                        <mat-divider></mat-divider>
                      </mat-list-item>
                      <mat-list-item>
                        <p>Missing Email <strong>00</strong></p>
                      </mat-list-item>
                      <mat-list-item>
                        <p>Missing Mobile <strong>00</strong></p>
                      </mat-list-item>
                      <mat-list-item>
                        <p>Missing Both (Email & Mobile) <strong>00</strong></p>
                      </mat-list-item>
                      <mat-list-item>
                        <p>Missing Address <strong>00</strong></p>
                      </mat-list-item>
                    </mat-list>
                  </div>
                </div>
                <div class="row clearfix text-left column">
                  <p class="pull-left two-thrd-width">
                    If you proceed, we will send an email, SMS or both to {{"KEYWORD.patient" | translate}}s that
                    redirects them to a practice
                    branded landing page where they're prompted to update their {{"KEYWORD.patient" | translate}}
                    profile.
                  </p>
                  <button class="pull-right mt" color="primary" mat-raised-button>
                    See {{"KEYWORD.patient" | translate}} experience
                    <mat-icon> visibility_on</mat-icon>
                  </button>
                </div>

                <div class="row clearfix text-left column">
                  <h3 class="sr-title rm-m">Benefits</h3>

                  <div class="row clearfix flex">
                    <div class="col-left half-width">
                      <ul class="check">
                        <li>
                          This is your new, convenient way to keep your {{"KEYWORD.practice" | translate}} up-to-date
                          with all {{"KEYWORD.patient" | translate}} details
                          before you get to the {{"KEYWORD.practice" | translate}} for their next appointment.
                        </li>
                        <li>
                          Doing this helps target {{"KEYWORD.patient" | translate}}s who might have a mobile number but
                          no email address (or vice
                          versa)
                        </li>
                      </ul>
                    </div>
                    <div class="col-right half-width">
                      <ul class="check">
                        <li>
                          Instead of asking {{"KEYWORD.patient" | translate}}s being asked to fill in paper forms at
                          the {{"KEYWORD.practice" | translate}} to update
                          details, {{"KEYWORD.patient" | translate}}s can update themselves before the next appointment.
                        </li>
                        <li>All data is stored securely for your benefit only.</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <!-- <div class="row clearfix">
                  <p class="small">Boosting price is based on individual campaign value - some product licensed
                    campaigns may have different costs to others based on the overall value of the advertised products.
                    For more information you can <a class="clicky" (click)="contactUs()">contact us</a>.</p>
                </div> -->
              </div>
            </div>

            <!-- Social Media posts -->
            <div class="row clearfix product-detail" *ngIf="priceComparisonSlide == 5" @simpleFadeAnimation>
              <div class="row clearfix">
                <!-- <h3 class="sr-title sm-h3 rm-mt">Social Media posts</h3> -->
                <div class="row clearfix flex column">
                  <div class="pull-left mr">
                    <img class="icon-img-lr"
                         src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/boost/social-list.png"/>
                  </div>
                  <div class="ml pull-left full-width text-left">
                    <p class="matchThis">
                      Using our licensed 3rd party dataset, we can find thousands of {{"KEYWORD.patient" | translate}}s
                      that live around your
                      practice, giving you unparalleled access to vast numbers of
                      new {{ "KEYWORD.patient" | translate }}s!
                    </p>
                    <p class="bold">Pricing varies based on how your new {{"KEYWORD.patient" | translate}}s respond.</p>
                  </div>
                </div>

                <hr/>

                <div class="row clearfix flex text-left">
                  <div class="col">
                    <ul class="check">
                      <li>
                        Every successful new {{"KEYWORD.patient" | translate}} that signs-up carries a flagfall of
                        {{ flagfall1 | customCurrency }}. They are added to your list with limited details, such as
                        phone numbers or addresses being hidden.
                      </li>
                    </ul>
                  </div>
                  <div class="col">
                    <ul class="check">
                      <li>
                        Every time a {{"KEYWORD.patient" | translate}} successfully visits a campaign landing page, the
                        flagfall is
                        {{ flagfall2 | customCurrency }}. They are added to your list with limited details.
                      </li>
                    </ul>
                  </div>
                  <div class="col">
                    <ul class="check">
                      <li>
                        If the {{"KEYWORD.patient" | translate}} then responds to a promoted campaign's call-to-action
                        (i.e. completes an enquiry,
                        books an appointment, makes an application etc) a {{ flagfall3 | customCurrency }} flagfall is
                        charged and they are added as a complete {{"KEYWORD.patient" | translate}} record.
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="row clearfix">
                  <p class="small">
                    Boosting price is based on individual campaign value - some product licensed campaigns may have
                    different costs to others based on the overall value of the advertised products. For more
                    information you can <a class="clicky" (click)="contactUs()">contact us</a>.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="row clearfix" @simpleFadeAnimation>
            <div class="row clearfix" *ngIf="selectedOption=='o1'" @simpleFadeAnimation>

              <h3 class="sr-title sm-h3 rm-mt">Wash existing list</h3>
              <img src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/boost/clean-list.png" class="icon-img" />


              <p class="bold">Approximately {{currentPatients}} leads at {{cleanListCost | customCurrency}}/{{"KEYWORD.patient" | translate}}</p>

              <p class="matchThis">We can go through your existing {{"KEYWORD.patient" | translate}} data and match your records against our
                licensed 3rd party
                dataset,
                merging any missing data into your database (including phone numbers, emails and home addresses).
              </p>

              <div class="row clearfix flex text-left">
                <div class="col">
                  <ul class="check">
                    <li>Simple and cost-effective way to extend your existing customer database
                    </li>
                  </ul>
                </div>
                <div class="col">
                  <ul class="check">
                    <li>Increases  {{"KEYWORD.treatment" | translate}}  acceptance by ensuring your {{"KEYWORD.patient" | translate}}s contact information is up-to-date</li>
                  </ul>

                </div>

              </div>


            </div>



            <div class="row clearfix" *ngIf="selectedOption=='o2'" @simpleFadeAnimation>
              <h3 class="sr-title sm-h3 rm-mt">Ask {{"KEYWORD.patient" | translate}}s to update details</h3>

              <img src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/boost/list-campaign.png" class="icon-img" />

              <p class="bold">Campaign cost: {{updateDetailsCost | customCurrency}}</p>

              <p>We'll send your current {{"KEYWORD.patient" | translate}}s an email and sms campaign, asking them to update their existing
                details. This helps by targeting people who might have a mobile number but no email address (or vice versa), and
                prompting them to complete
                their {{"KEYWORD.patient" | translate}} profile.
              </p>


              <div class="row clearfix flex text-left">

                <div class="col">
                  <ul class="check">
                    <li>Customers are provided a link to their very own {{"KEYWORD.patient" | translate}} Portal, allowing them to update all their information including private health and medicare details</li>
                  </ul>

                </div>
                <div class="col">
                  <ul class="check">
                    <li>The {{"KEYWORD.patient" | translate}} Portal then becomes their one-stop dashboard for interacting with their {{"KEYWORD.patient" | translate}} cards, health history, and more!</li>
                  </ul>
                </div>
              </div>


            </div>


            <div class="row clearfix" *ngIf="selectedOption=='o3'" @simpleFadeAnimation>
              <h3 class="sr-title sm-h3 rm-mt">Access   {{"BRANDING.Brand Name" | translate}} database</h3>
              <div class="row clearfix flex">
                <div class="pull-left mr">
                  <img src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/boost/access-database.png" class="icon-img" />
                </div>
                <div class="ml pull-left full-width text-left">


                  <p class="matchThis">Using our licensed 3rd party dataset, we can find thousands of {{"KEYWORD.patient" | translate}}s that live
                    around your practice, giving you unparalleled access to vast numbers of new {{ "KEYWORD.patient" | translate }}s!</p>
                  <p class="bold">Pricing varies based on how your new {{"KEYWORD.patient" | translate}}s respond.</p>

                </div>

              </div>



              <div class="row clearfix flex text-left">
                <div class="col">
                  <ul class="check">
                    <li>Every successful new {{"KEYWORD.patient" | translate}} that signs-up carries a flagfall of {{flagfall1 | customCurrency}}.
                      They are added to your list with limited details, such as phone numbers or addresses being hidden.
                    </li>
                  </ul>
                </div>
                <div class="col">
                  <ul class="check">
                    <li>Every time a {{"KEYWORD.patient" | translate}} successfully visits a campaign landing page, the flagfall is
                      {{flagfall2 | customCurrency}}. They are added to your list with limited details.</li>
                  </ul>

                </div>
                <div class="col">
                  <ul class="check">
                    <li>If the {{"KEYWORD.patient" | translate}} then responds to a promoted campaign's call-to-action (i.e. completes an enquiry,
                      books an appointment, makes an application etc) a {{flagfall3 | customCurrency}} flagfall is
                      charged and they are added as a complete {{"KEYWORD.patient" | translate}} record.</li>
                  </ul>
                </div>
              </div>

              <div class="row clearfix">
                <p class="small">Boosting price is based on individual campaign value - some product licensed campaigns may have different costs to others based on the overall value of the advertised products. For more information you can <a class="clicky" (click)="contactUs()">contact us</a>.</p>
              </div>

            </div>



          </div> -->

          <!-- back / forward buttons if product is selected -->
          <hr *ngIf="priceComparisonSlide != 0"/>
          <div class="reduce-marg clearfix" *ngIf="priceComparisonSlide != 0">
            <ng-container>
              <button class="pull-left" (click)="setProductOption(0)" mat-raised-button>
                <mat-icon>keyboard_arrow_left</mat-icon> Back
              </button>
            </ng-container>

            <ng-container>
              <button class="pull-right" mat-raised-button color="accent" awNextStep>
                Choose option <mat-icon>chevron_right</mat-icon>
              </button>
            </ng-container>
          </div>
        </aw-wizard-step>

        <aw-wizard-step [navigationSymbol]="{ symbol: 2 }" (stepEnter)="setStep(2)">
          <div class="row clearfix text-left">
            <h4 class="rm-mb">Email</h4>

            <mat-list class="boost-list well mb">
              <mat-list-item>
                <p>XXXX {{"KEYWORD.patient" | translate}}s will be reached by email <strong>$FREE</strong></p>
              </mat-list-item>
            </mat-list>

            <h4 class="rm-mb">SMS</h4>

            <p class="rm-mt">
              To make your campaign more effective and reach more {{ "KEYWORD.patient" | translate }}s, you can send an
              SMS. If you elect to proceed
              with SMS option, a charge will be incurred for every SMS sent.
            </p>

            <mat-list class="boost-list well mb">
              <mat-list-item>
                <p>XXXX {{"KEYWORD.patient" | translate}}s will be reached by SMS <strong>$XX.XX (per SMS)</strong></p>
              </mat-list-item>
              <mat-list-item>
                <mat-checkbox>Please send SMS</mat-checkbox>
              </mat-list-item>
            </mat-list>
          </div>

          <hr/>
          <div class="reduce-marg clearfix">
            <ng-container>
              <button class="pull-left" mat-raised-button awPreviousStep>
                <mat-icon>keyboard_arrow_left</mat-icon> Back
              </button>
            </ng-container>

            <ng-container>
              <button class="pull-right" mat-raised-button color="accent" awNextStep>
                Proceed <mat-icon>chevron_right</mat-icon>
              </button>
            </ng-container>
          </div>
        </aw-wizard-step>

        <aw-wizard-step [navigationSymbol]="{ symbol: 3 }" (stepEnter)="setStep(3)">
          <form [formGroup]="paymentForm">
            <div class="tab-content row clearfix">
              <div class="thrd-width pull-left cc-description">
                <p class="lead rm-mb">Pay by Credit Card</p>

                <p class="small">Accepted cards:</p>
                <div class="card-type visa" matTooltip="Visa"></div>
                <div class="card-type mastercard" matTooltip="Mastercard"></div>
                <div class="card-type amex" matTooltip="American Express"></div>

                <div class="row clearfix">
                  <p class="small securePay">
                    <mat-icon>lock</mat-icon> Secure Payments with
                    <strong> <em>ePremium</em>&trade;</strong>
                  </p>
                </div>
              </div>
              <div class="cc-area two-thrd-width pull-right">
                <!-- <span class="title">Enter card details</span> -->
                <div class="row clearfix">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Name on Card</mat-label>

                    <input id="cc-name" type="text" placeholder="Name on Card" formControlName="cardName" required
                           matInput/>
                  </mat-form-field>
                </div>
                <div class="row clearfix">
                  <!-- {{ccnumber}} -->
                  <mat-form-field class="full-width payCreditCard" appearance="outline">
                    <mat-label>Credit Card Number</mat-label>
                    <mat-icon matPrefix>credit_card</mat-icon>

                    <input id="cc-number" type="tel" autocomplete="cc-number" placeholder="Credit Card Number"
                           formControlName="cardNumber" required matInput ccNumber/>
                  </mat-form-field>
                </div>
                <div class="row clearfix flex">
                  <mat-form-field class="full-width rm-ml" appearance="outline">
                    <mat-label>Expiry Date</mat-label>

                    <input id="cc-exp-date" type="tel" autocomplete="cc-exp" placeholder="Expiry Date"
                           formControlName="cardExpireDate" matInput required ccExp/>
                  </mat-form-field>
                  <mat-form-field class="full-width rm-mr" appearance="outline">
                    <mat-label>CVC Number</mat-label>
                    <button (click)="showCvvInfo = !showCvvInfo" mat-button matSuffix matTooltip="What's this?">
                      <mat-icon>help</mat-icon>
                    </button>

                    <input id="cc-cvc" type="tel" autocomplete="cc-cvc" placeholder="CVC Number"
                           formControlName="cardCVS" required matInput ccCVC/>
                  </mat-form-field>
                </div>
                <div class="row clearfix animated fadeInDown" *ngIf="showCvvInfo === true">
                  <div class="half-width">&nbsp;</div>
                  <div class="half-width cvvInfo">
                    <img
                      src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/card-verification-value.png"/>
                    <p>
                      <strong>What's this?</strong>
                    </p>
                    <p class="small">
                      CVV is an anti-fraud security feature to help verify that you are in possession of your credit
                      card. For Visa/Mastercard, the three-digit CVV number is printed on the signature panel on the
                      back of the card immediately after the card's account number.
                    </p>
                  </div>
                </div>
              </div>
              <!-- <hr> -->
            </div>

            <div class="grey clearfix">
              <p>You have elected to target <strong>00</strong>{{ "KEYWORD.patient" | translate }}s</p>
              <p>Based on your selection your projected cost for this campaign will be <strong>$0.00</strong></p>
            </div>
            <div class="row clearfix">
              <p class="small">
                The figure shown is an estimate (maximum) only and based on the projected number
                of {{"KEYWORD.patient" | translate}}s that can be
                reached, and may not reflect the final charge. Once you press proceed your campaign will proceed
                immediately.
              </p>
              <p class="small">Please note once commenced we cannot stop a campaign.</p>
              <mat-checkbox>I understand and wish to proceed</mat-checkbox>
            </div>
            <hr/>
            <div class="reduce-marg clearfix">
              <ng-container>
                <button class="pull-left" mat-raised-button awPreviousStep>
                  <mat-icon>keyboard_arrow_left</mat-icon> Back
                </button>
              </ng-container>

              <ng-container>
                <button class="pull-right" [disabled]="
                    paymentForm.controls.cardNumber.invalid ||
                    paymentForm.controls.cardExpireDate.invalid ||
                    paymentForm.controls.cardCVS.invalid ||
                    paymentForm.controls.cardName.invalid
                  " mat-raised-button color="primary" awNextStep>
                  Next <mat-icon>chevron_right</mat-icon>
                </button>
              </ng-container>
            </div>
          </form>
        </aw-wizard-step>

        <aw-wizard-step [navigationSymbol]="{ symbol: 4 }" (stepEnter)="setStep(4)">
          <h1 class="sm-h1 sr-title">Well Done</h1>
          <h3 class="sm-h3 rm-mt">Your payment is being processed, and we're working on boosting your reach!</h3>
          <p>We'll let you know via email when this process is complete.</p>
          <div class="row clearfix">
            <div class="svg-box">
              <svg class="circular green-stroke">
                <circle class="path" cx="75" cy="75" r="50" fill="none" stroke-width="5" stroke-miterlimit="10" />
              </svg>
              <svg class="checkmark green-stroke">
                <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                  <path class="checkmark__check" fill="none" d="M616.306,283.025L634.087,300.805L673.361,261.53" />
                </g>
              </svg>
            </div>
          </div>
          <hr/>
          <div class="reduce-marg clearfix">
            <ng-container>
              <button class="btn-small pull-right" (click)="proceed()" mat-raised-button color="accent">
                Done
                <mat-icon class="fas fa-hand-point-right"></mat-icon>
              </button>
            </ng-container>
          </div>
        </aw-wizard-step>
      </aw-wizard>
    </mat-dialog-content>
  </mat-card>
</div>
