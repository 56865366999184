import { HttpClient, HttpHandler, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ErrorModalAuthenticationComponent } from '../../core/authentication/error-modal/error-modal.component';
import { AuthenticationService } from '../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../components/root-component/root-component.component';
import { ClientDetails } from '../types/client-details';
import { DeviceDetector } from '../types/device-detector';
import { NotifyAppComponent } from '../types/notify-app-component';
import { UtilsClass } from '../types/utils/utils.class';

@Injectable()
export class InterceptedHttp extends HttpClient {
  pendingReq = 0;

  constructor(handler: HttpHandler) {
    super(handler);
  }

  get(url: string, options: any): Observable<any> {
    url = this.updateUrl(url);

    let canLoad = true;

    if (this.displayLoading(url, true) == true) {
      this.startLoading();
    } else {
      canLoad = false;
    }
    const httpOptions = this.getRequestOptionArgs(options);
    return super
      .get(url, this.getRequestOptionArgs(options))
      .pipe(catchError(this.catchErrors()), tap(this.editResponse()), finalize(this.finishTreament(canLoad)));
  }

  post(url: string, body: any, options: any): Observable<any> {
    url = this.updateUrl(url);

    let canLoad = true;

    if (!options || !options.params || options.params['userData'] !== true) {
      if (this.displayLoading(url, false) == true) {
        this.startLoading();
      } else {
        canLoad = false;
      }
    } else {
      canLoad = false;
    }
    return super
      .post(url, body, this.getRequestOptionArgs(options))
      .pipe(catchError(this.catchErrors()), tap(this.editResponse()), finalize(this.finishTreament(canLoad)));
  }

  put(url: string, body: any, options: any): Observable<any> {
    url = this.updateUrl(url);
    let canLoad = true;

    if (this.displayLoading(url, false) == true) {
      this.startLoading();
    } else {
      canLoad = false;
    }
    return super
      .put(url, body, this.getRequestOptionArgs(options))
      .pipe(catchError(this.catchErrors()), tap(this.editResponse()), finalize(this.finishTreament(canLoad)));
  }

  delete(url: string, options: any): Observable<any> {
    url = this.updateUrl(url);
    this.startLoading();
    return super
      .delete(url, this.getRequestOptionArgs(options))
      .pipe(catchError(this.catchErrors()), tap(this.editResponse()), finalize(this.finishTreament()));
  }

  editResponse() {
    return (res: HttpResponse<any>) => {
      const body = res;
      if (body['success'] == false) {
        this.stopLoading();
        return Observable.throwError(res);
      }
    };
  }

  catchErrors() {
    // let error = new Error();
    return (res: HttpResponse<any>): Observable<any> => {
      const result = res;

      let error = result['error'];

      if (result['error'] && result['error']['error']) {
        error = result['error']['error'];
      }

      this.stopLoading();

      if (res.status !== 400) {
        if (res.status === 404) {
          // window.location.href = "404";

          NotifyAppComponent.navigation.emit('/404');
        } else if (res.status === 403) {
          // window.location.href = "403";

          NotifyAppComponent.navigation.emit('/403');
        } else if (res.status === 401) {
          if (result['error'] && result['error']['success'] === false) {
            if (error && error.code === 433) {
              const ref = RootAppComponent.dialog.open(ErrorModalAuthenticationComponent);

              ref.componentInstance.closeModal.subscribe((res) => {
                ref.close();
              });
            } else {
              UtilsClass.displayError(error.message, 401, null);
            }
          } else {
            UtilsClass.displayError('Authentication failed', 401, null);
          }
        } else if (res.status >= 500 || res.status < 600) {
          // window.location.href = '500/' + 500 + '/Server is down for update';

          NotifyAppComponent.navigation.emit('/500-error');
          AuthenticationService.serverUnreachable.emit('500');
        } else {
          // window.location.href = 'something-wrong';

          NotifyAppComponent.navigation.emit('/500-error');
          AuthenticationService.serverUnreachable.emit('500');
        }
      } else {
        if (result['error'] && result['error']['success'] === false) {
          if (error && error.code === 433) {
            const ref = RootAppComponent.dialog.open(ErrorModalAuthenticationComponent);

            ref.componentInstance.closeModal.subscribe((res) => {
              ref.close();
            });
          } else if (error && error.code === 510) {
            AuthenticationService.serverUnreachable.emit('510');
          } else {
            UtilsClass.displayError(error.message, error.code, null);

            if (result.status === 402) {
              // window.location.href = '/';

              NotifyAppComponent.navigation.emit('/');
            }
          }
        }
      }

      return Observable.throwError(res);
    };
  }

  finishTreament(canLoad = true) {
    return () => {
      if (canLoad == true) {
        this.pendingReq--;
      }

      if (this.pendingReq <= 0) {
        UtilsClass.stopLoadingInterceptor();
      }
    };
  }

  stopLoading() {
    this.pendingReq = 0;
    UtilsClass.stopLoadingInterceptor();
  }

  startLoading() {
    this.pendingReq++;
    UtilsClass.startLoadingInterceptor();
  }

  displayLoading(url, isGet = false) {
    if (url) {
      if (url.indexOf('social-media/thumbnail') != -1 && isGet == true) {
        return false;
      } else if (url.indexOf('social-media/post-picture') != -1 && isGet == true) {
        return false;
      } else if (url.indexOf('picture') != -1 && isGet == true) {
        return false;
      } else if (url.indexOf('ep-module/hidden') != -1) {
        return false;
      } else if (url.indexOf('weekly-report-promoter/hidden') != -1) {
        return false;
      } else if (url.indexOf('message/external/user-statistics-hidden') != -1) {
        return false;
      } else if (url.indexOf('/current/session/alive-hidden') != -1) {
        return false;
      } else if (url.indexOf('/message/external/read-hidden') != -1) {
        return false;
      } else if (url.indexOf('message/external/sms-hidden') != -1) {
        return false;
      } else if (url.indexOf('ep-module/hidden/global') != -1) {
        return false;
      } else if (url.indexOf('statistics-hidden') != -1) {
        return false;
      } else if (url.indexOf('message/external/hidden') != -1) {
        return false;
      } else if (url.indexOf('action-log/hidden') != -1) {
        return false;
      } else if (url.indexOf('merchant-product-group/hidden') != -1) {
        return false;
      } else if (url.indexOf('tasks-assigned/hidden') != -1) {
        return false;
      } else if (url.indexOf('/sync-hidden') != -1) {
        return false;
      } else if (url.indexOf('/hidden/public/') != -1) {
        return false;
      } else if (url.indexOf('tip/hidden') != -1) {
        return false;
      } else if (url.indexOf('userData/hidden') != -1) {
        return false;
      } else if (url.indexOf('settings-hidden') != -1) {
        return false;
      } else if (url.indexOf('transaction-current-hidden') != -1) {
        return false;
      } else if (url.indexOf('transaction-current') != -1) {
        return false;
      } else if (url.indexOf('current-hidden') != -1) {
        return false;
      } else if (url.indexOf('current/hidden') != -1) {
        return false;
      } else if (url.indexOf('html-hidden') != -1) {
        return false;
      } else if (url.indexOf('html-cached-hidden') != -1) {
        return false;
      } else if (url.indexOf('coordinate') != -1) {
        return false;
      } else if (url.indexOf('EPDocument/mini-details' && isGet == true) != -1) {
        return false;
      }else if (url.indexOf('merged-list-hidden') != -1) {
        return false;
      }
      else if (url.indexOf('local-group-hidden') != -1) {
        return false;
      }

      else if (url.indexOf('merchant-personal-message/hidden') != -1) {
        return false;
      }

      else if (url.indexOf('merchant-personal-message/global-hidden') != -1) {
        return false;
      }



    }
    return true;
  }

  private updateUrl(req: string) {
    return environment.origin + req;
  }

  private getRequestOptionArgs(options?: any): any {
    if (options == null) {
      options = {};
    }
    options.withCredentials = true;

    if (options.headers == null) {
      options.headers = new HttpHeaders();
    }

    if (ClientDetails && !ClientDetails.timeZoneUTC) {
      ClientDetails.getTimeZoneUTC();
    }

    let customerTimeZone;

    if (ClientDetails.timeZoneUTC != null) {
      customerTimeZone = ClientDetails.timeZoneUTC;
    }

    if (ClientDetails.timeZoneUTCProfileCode && ClientDetails.timeZoneUTCProfileValue != null) {
      if (ClientDetails.timeZoneUTC != null) {
        customerTimeZone = Number(Number(ClientDetails.timeZoneUTCProfileValue) - Number(ClientDetails.timeZoneUTC));
      } else {
        customerTimeZone = Number(ClientDetails.timeZoneUTCProfileValue);
      }
    }

    if (ClientDetails && ClientDetails.timeZoneUTC != null) {
      options.headers = options.headers.set('time-zone', String(customerTimeZone));
    }

    if (ClientDetails && ClientDetails.accessToken) {
      options.headers = options.headers.set('Authorization', 'Bearer ' + ClientDetails.accessToken);
    }

    if (!options.headers.get('enctype') && !options.headers.get('Content-Type')) {
      options.headers = options.headers.append('Content-Type', 'application/json');
    }

    if (ClientDetails.geolocation && !options.headers.get('gps-location-r')) {
      options.headers = options.headers.append('gps-location-r', ClientDetails.geolocation);
    }

    if (ClientDetails.ipAddress && !options.headers.get('ip-address')) {
      options.headers = options.headers.append('ip-address', ClientDetails.ipAddress);
    }

    if (ClientDetails.browser && !options.headers.get('browser')) {
      options.headers = options.headers.append('browser', ClientDetails.browser);
    }

    if (ClientDetails.os && !options.headers.get('os')) {
      options.headers = options.headers.append('os', ClientDetails.os);
    }

    if (ClientDetails.device && !options.headers.get('device')) {
      options.headers = options.headers.append('device', ClientDetails.device);
    }

    if (options.headers.get('isLogin') == 'true') {
      options.headers = options.headers.delete('isLogin');
      const deviceInfo = DeviceDetector.getDeviceInfo();
      for (const headerName in deviceInfo) {
        if (headerName != 'userAgent' && !options.headers.get(headerName)) {
          options.headers = options.headers.append(headerName, deviceInfo[headerName]);
        }
      }
    }
    return options;
  }
}
