import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ipv-dental-product-form',
  templateUrl: './dental-product-form.component.html',
  styleUrls: ['./dental-product-form.component.css'],
})
export class DentalProductFormComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
