import { animate, state, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { environment } from 'environments/environment';
import { forkJoin, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { DocumentUrlViewComponent } from '../../../shared/components/document-url-view/document-url-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { HeaderService } from '../../../shared/services/header.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { CustomerProspectService } from '../../customer-prospect/shared/customerProspect.service';
import { MessageTemplateCreateEditComponent } from '../../default-message/message-template-create-edit/message-template-create-edit.component';
import { DefaultMessageService } from '../../default-message/shared/default-message.service';
import { DentistViewComponent } from '../../dentist/dentist-view/dentist-view.component';
import { DentistService } from '../../dentist/shared/dentist.service';
import { MarketingService } from '../../marketing/shared/marketing.service';
import { MedicalHistoryService } from '../../medical-history/shared/medical-history.service';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { SmsConversationComponent } from '../../message/sms-conversation/sms-conversation.component';
import { SmsPromoterComponent } from '../../message/sms-promoter/sms-promoter.component';
import { InvitationCustomerProspectEditComponent } from '../invitation-customer-prospect-edit/invitation-customer-prospect-edit.component';
import { InvitationLookupComponent } from '../invitation-lookup/invitation-lookup.component';
import { InvitationService } from '../shared/services/invitation.service';

@Component({
  selector: 'app-invitation-communication-create',
  templateUrl: './invitation-communication-create.component.html',
  styleUrls: ['./invitation-communication-create.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class InvitationCommunicationCreate implements OnInit {
  @ViewChild('wizard', { static: true }) wizard: WizardComponent;

  @Input() merchantID;

  @Input()
  displayLookup = true;

  lookupPage = true;

  @Input() title = 'An invitation will be sent to patient  ';

  @Input() purpose;

  @Output() close = new EventEmitter();

  @Output() getResult = new EventEmitter();

  @Input() patientID;

  util = new UtilsClass();

  promoter;
  merchants = [];
  contacts = [];

  genders = [];
  titles = [];
  defaultMessageLists = [];

  isAdminOrPromoter = false;

  message;

  merchant;
  contact;

  isModal = false;

  selectedExistingPatientID;
  invitationPatientID;

  patient;
  isMobileValid = false;
  isHomePhoneValid = false;
  isWorkPhoneValid = false;
  isEmailValid = false;

  isModuleCustomMessages = Settings.global['isModuleCustomMessagesActive'];
  isModuleGoogleReviewActive = Settings.global['isModuleGoogleReviewActive'];

  complete = false;
  stopFlying = false;
  flyAway = false;

  selectedMerchant;
  selectedSender;

  rawMessage;
  clearDate = new EventEmitter();
  hideMerchantSelection = false;
  isFinDMatch = false;
  dateOfBirth;
  _dateOfBirth;
  user = {
    firstname: null,
    title: null,
    lastname: null,
    middlename: null,
    gender: null,
    mobile: null,
    home_phone: null,
    work_phone: null,
    email: null,
  };
  messagePurposeCode = 'MerPUInv';
  clearEmail = new EventEmitter();
  clearMobile = new EventEmitter();
  clearHomePhone = new EventEmitter();
  clearWorkPhone = new EventEmitter();

  skipLookup = false;
  sessionType = 'merchant';

  googleReviewPrompt = false;

  constructor(
    private utilService: UtilsService,
    private merchantService: MerchantService,
    private invitationService: InvitationService,
    private medicalHistoryService: MedicalHistoryService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private dentistService: DentistService,
    private customerProspectService: CustomerProspectService,
    private authenticationService: AuthenticationService,
    private lookupService: LookupService,
    private customePhonePipe: CustomPhonePipe,
    private defaultMessageSerivce: DefaultMessageService,
    private location: Location,
    private dialog: MatDialog,
    private marketingService: MarketingService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.merchantID) {
        this.merchantID = data.merchantID;

        this.isModal = true;
      }
      if (data.patientID) {
        this.patientID = data.patientID;
        this.displayLookup = false;

        this.lookupPage = false;
        this.isModal = true;
      }

      if (data.title) {
        this.title = data.title;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['patientID']) {
        this.patientID = params['patientID'];
        this.displayLookup = false;
        this.lookupPage = false;
      }

      if (params['purpose']) {
        this.purpose = params['purpose'];
      }

      this.authenticationService.getSessionType().subscribe((sessionType) => {
        if (sessionType) {
          this.sessionType = sessionType;
        }

        this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
          this.isAdminOrPromoter = res;
          this.authenticationService.getPromoter().subscribe((promoter) => {
            if (promoter && promoter.data) {
              this.promoter = promoter.data;
            } else if (promoter) {
              this.promoter = promoter;
            }
          });
          this.utilService.getCurrentAccess().subscribe((access) => {
            if (access) {
              this.isModuleCustomMessages = access['isModuleCustomMessagesActive'];
              this.isModuleGoogleReviewActive = access['isModuleGoogleReviewActive'];

              this.lookupService.getLookup('CodeLookup', 'Gender').subscribe((data) => {
                this.genders = data;
              });
              this.lookupService.getLookup('SimpleLookup', 'Salutation').subscribe((data) => {
                this.titles = data;
              });

              if (this.isAdminOrPromoter == true) {
                const p = {
                  fields: 'ID,Name,FirstName,TradingAs,CalculatedName,Status',
                };

                this.merchantService.getList(p, this.isAdminOrPromoter).subscribe((res) => {
                  if (res) {
                    this.merchants = res;

                    if (this.merchantID) {
                      for (let i = 0; i < this.merchants.length; i++) {
                        if (
                          this.merchants[i] &&
                          this.merchants[i]['ID'] &&
                          this.merchants[i]['ID'] == this.merchantID
                        ) {
                          this.merchant = this.merchants[i];
                        }
                      }
                    }
                  }
                });

                const _mID = JSON.parse(localStorage.getItem('selectedMerchant'));
                if (_mID) {
                  this.merchantID = _mID;
                  // this.hideMerchantSelection = true;
                }
                HeaderService.getPromoterViewAsObject().subscribe((res) => {
                  if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'promoter') {
                    this.merchantID = null;
                    this.hideMerchantSelection = false;
                  } else if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'merchant') {
                    this.merchantID = res['merchantID'];
                    this.hideMerchantSelection = true;
                  }
                });
              }

              const __p = {
                fields: 'ID,Name,FirstName,TradingAs,CalculatedName',
              };

              this.dentistService.getList(__p, this.isAdminOrPromoter).subscribe((res) => {
                if (res && res.length > 0) {
                  this.contacts = res;

                  this.authenticationService.getCurrentDentist().subscribe((res) => {
                    if (res) {
                      this.selectedSender = res;

                      for (let i = 0; i < this.contacts.length; i++) {
                        if (
                          this.contacts[i] &&
                          this.contacts[i]['ID'] &&
                          this.selectedSender &&
                          this.selectedSender['ID'] &&
                          this.contacts[i]['ID'] == this.selectedSender['ID']
                        ) {
                          this.contact = this.contacts[i];
                        }
                      }
                    }
                  });
                }
              });

              this.getDefaultMessageList();

              if (this.patientID) {
                const p = {
                  fields:
                    'ID,Name,FirstName,MiddleName,Name,DateOfBirth,CalculatedName,Salutation,Gender.Code,Gender.Label,phones.Number,mobiles.Number,emails.Email',
                };

                this.customerProspectService
                  .getCustomerProspectDetails(this.patientID, p, this.sessionType)
                  .subscribe((res) => {
                    this.patient = res;
                    this.buildPatient(this.patient);
                  });
              }
            }

            this.authenticationService.getCurrentPractice().subscribe((res) => {
              if (res) {
                this.selectedMerchant = res;
              }
            });
          });
        });
      });
    });
  }

  changeSender() {
    if (this.contact && this.contact.ID) {
      this.dentistService.getContactDetails(this.contact.ID, {}, this.sessionType).subscribe((res) => {
        if (res) {
          this.selectedSender = res;
          this.message = this.convertMessage();
        }
      });
    }
  }

  viewMerchant() {
    const ref = this.dialog.open(MerchantViewComponent, {
      data: this.merchantID,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  selectMerchant(ID) {
    this.merchantID = ID;

    this.getDefaultMessageList(this.merchantID);

    const __p = {
      merchantID: this.merchantID,
      fields: 'ID,Name,FirstName,TradingAs,CalculatedName',
    };

    this.dentistService.getList(__p, this.isAdminOrPromoter).subscribe((res) => {
      if (res && res.length > 0) {
        this.contacts = res;
        this.contact = this.contacts[0];
      }
    });
  }

  viewDentist() {
    if (this.contact && this.contact.ID) {
      const ref = this.dialog.open(DentistViewComponent, {
        data: this.contact.ID,
        width: '600px',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  getEmail(p) {
    this.user.email = p;
  }

  getMobile(p) {
    this.user.mobile = p;
  }

  getHomePhone(p) {
    this.user.home_phone = p;
  }

  getWorkPhone(p) {
    this.user.work_phone = p;
  }

  validateMobile(v) {
    this.isMobileValid = v;
  }

  validateHomePhone(v) {
    this.isHomePhoneValid = v;
  }

  validateWorkPhone(v) {
    this.isWorkPhoneValid = v;
  }

  validateEmail(v) {
    this.isEmailValid = v;
  }

  selectDateToFilter(dateTo) {
    this._dateOfBirth = dateTo;
  }

  sendResult() {
    const resultObservables: Observable<any>[] = [];

    const p = {
      message: this.message,
      contactID: this.contact.ID,
      sendEmail: true,
      sendSms: false,
      googleReviewPrompt: this.googleReviewPrompt,
    };

    if (this.patientID) {
      p['patientID'] = this.patientID;
      p['cardID'] = this.patientID;
    } else {
      if (this.user.gender) {
        p['genderCode'] = this.user.gender;
      }

      if (this.user.title) {
        p['salutation'] = this.user.title;
      }

      if (this.user.firstname) {
        p['firstName'] = this.user.firstname;
      }

      if (this.user.middlename) {
        p['middleName'] = this.user.middlename;
      }

      if (this.user.lastname) {
        p['lastName'] = this.user.lastname;
      }

      if (this.user.email) {
        p['email'] = this.user.email;
      }

      if (this.user.mobile) {
        p['mobile'] = this.user.mobile;
      }

      if (this.user.home_phone) {
        p['homePhone'] = this.user.home_phone;
      }

      if (this.user.work_phone) {
        p['workPhone'] = this.user.work_phone;
      }

      if (this._dateOfBirth) {
        p['dateOfBirth'] = this._dateOfBirth;
      }
    }

    if (this.isAdminOrPromoter == true) {
      p['merchantID'] = this.merchantID;
    }

    if (this.purpose == 'medical-history') {
      const inviteMedicalHistory$ = this.medicalHistoryService.inviteMedicalHistory(p, this.isAdminOrPromoter).pipe(
        tap((res) => {
          if (res) {
            this.messagePurposeCode = 'MerMHInv';
            if (res['Prospect_key']) {
              this.invitationPatientID = res['Prospect_key'];
            }
            this.getResult.emit(res);
          }
        })
      );

      resultObservables.push(inviteMedicalHistory$);
    } else if (this.purpose == 'profile-update') {
      this.messagePurposeCode = 'MerPUInv';
      p['purposeCode'] = 'COM_UCD';
      const createInvitationCommunication$ = this.invitationService
        .createInvitationCommunication(p, this.isAdminOrPromoter)
        .pipe(
          tap((res) => {
            if (res) {
              if (res['Prospect_key']) {
                this.invitationPatientID = res['Prospect_key'];
              }
              this.getResult.emit(res);
            }
          })
        );

      resultObservables.push(createInvitationCommunication$);
    }

    if (this.googleReviewPrompt) {
      const inviteGoogleReview$ = this.marketingService.inviteGoogleReview(p, this.isAdminOrPromoter);
      resultObservables.push(inviteGoogleReview$);
    }

    forkJoin(resultObservables).subscribe(() => {
      NotifyAppComponent.displayToast('success', 'Successful operation', 'The patient has been successfully invited ');

      this.wizard.goToNextStep();
      this.complete = true;
    });
  }

  openPatientLookUpDialog() {
    const ref = this.dialog.open(InvitationLookupComponent, {
      data: {
        merchantID: this.merchantID,
        onlyMyAccount: true,
        useStrict: true,
      },
      width: '600px',
    });
    ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });

    ref.componentInstance.patientChat.subscribe((res) => {
      if (res && res.cardID) {
        if (res.isPromoterOrAdmin == true) {
          const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true,
            },
            panelClass: 'noCard',
            width: '500px',
          });
          ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });

          ref2.componentInstance.getResult.subscribe((data) => {
            if (data) {
              const ref3 = RootAppComponent.dialog.open(SmsConversationComponent, {
                data,
                panelClass: 'bigger-screen',
                width: '70%',
                height: '95vh',
              });
              const sub = ref3.componentInstance.close.subscribe((data) => {
                ref3.close();
              });
            }

            ref2.close();
          });
        } else {
          const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true,
            },
            panelClass: 'bigger-screen',
            width: '70%',
            height: '95vh',
          });
          const sub = ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });
        }
      }
    });

    ref.componentInstance.detailedView.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.getSelectedPatient.subscribe((res) => {
      if (res) {
        if (res.ID) {
          ref.close();
          this.patientID = res.ID;
          this.isFinDMatch = true;
          this.skipLookup = true;

          this.buildPatient(res);
        }
      }
    });
  }

  buildPatient(patient) {
    if (patient['Gender.Code']) {
      this.user.gender = patient['Gender.Code'];
    }

    if (patient['Salutation']) {
      this.user.title = patient['Salutation'];
    }

    if (patient['FirstName']) {
      this.user.firstname = patient['FirstName'];
    }

    if (patient['MiddleName']) {
      this.user.middlename = patient['MiddleName'];
    }

    if (patient['Name']) {
      this.user.lastname = patient['Name'];
    }

    if (patient['mobiles.Number']) {
      this.user.mobile = patient['mobiles.Number'];
    }

    if (patient['phone.Home.Number']) {
      this.user.home_phone = patient['phone.Home.Number'];
    }

    if (patient['phone.Work.Number']) {
      this.user.work_phone = patient['phone.Work.Number'];
    }

    if (patient['emails.Email']) {
      this.user.email = patient['emails.Email'];
    }

    if (patient['dateOfBirth'] && patient['dateOfBirth'] != '0000-00-00') {
      this.dateOfBirth = patient['dateOfBirth'];
      this._dateOfBirth = patient['dateOfBirth'];
    }

    if (patient['DateOfBirth'] && patient['DateOfBirth'] != '0000-00-00') {
      this.dateOfBirth = patient['DateOfBirth'];
      this._dateOfBirth = patient['DateOfBirth'];
    }
  }

  getDefaultMessageList(merchantID = null) {
    if (this.purpose == 'medical-history') {
      this.messagePurposeCode = 'MerMHInv';
      // _purposeCode = 'MerPUInv';
    } else if (this.purpose == 'profile-update') {
      // _purposeCode = 'MerPUInv';
      this.messagePurposeCode = 'MerPUInv';
    }

    if (this.isModuleCustomMessages == true) {
      if (this.isAdminOrPromoter == false || merchantID == null) {
        const payload = {
          purposeCode: this.messagePurposeCode,
          orderBy: 'Label',
          onlyActive: true,
        };
        this.defaultMessageSerivce.getDefaultMessageList(payload).subscribe((res) => {
          if (res && res.length > 0) {
            if (this.defaultMessageLists && this.defaultMessageLists.length > 0) {
              this.defaultMessageLists = [];
            }

            for (let i = 0; i < res.length; i++) {
              const r = {
                ID: res[i]['ID'],
                label: res[i]['Label'],
                value: res[i]['Content'],
              };

              this.defaultMessageLists.push(r);
            }
          }
        });
      } else {
        const payload = {
          onlyActive: true,
          purposeCode: this.messagePurposeCode,
          orderBy: 'Label',
          merchantID: this.merchantID,
        };
        this.defaultMessageSerivce.getDefaultMessageListGlobal(payload).subscribe((res) => {
          if (res && res.length > 0) {
            if (this.defaultMessageLists && this.defaultMessageLists.length > 0) {
              this.defaultMessageLists = [];
            }

            for (let i = 0; i < res.length; i++) {
              const r = {
                ID: res[i]['ID'],
                label: res[i]['Label'],
                value: res[i]['Content'],
              };

              this.defaultMessageLists.push(r);
            }
          }
        });
      }
    }
  }

  backToLookup() {
    this.patientID = null;
    this.patient = null;
    this.lookupPage = true;
  }

  addNewMessage() {
    const ref = this.dialog.open(MessageTemplateCreateEditComponent, {
      data: {
        merchantID: this.merchantID,
        purposeCode: this.messagePurposeCode,
        displayProductGroup: false,
        displayPurpose: false,
      },
      width: "900px",
      panelClass: 'noCard',
    });

    ref.componentInstance.createdMessage.subscribe((data) => {
      if (data) {
        const r = {
          ID: data['ID'],
          label: data['Label'],
          value: data['Content'],
        };

        this.defaultMessageLists.push(r);

        ref.close();
      }
    });

    const sub = ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
  }

  step1() {
    if (!this.patientID) {
      const payload = {
        firstName: this.user.firstname,
        lastName: this.user.lastname,
        mobile: this.user.mobile,
        phone: this.user.home_phone,
        email: this.user.email,
        dateOfBirth: this._dateOfBirth,
        merchantID: this.merchantID,
      };
      this.customerProspectService.patientLookup(payload, this.isAdminOrPromoter).subscribe((res) => {
        if (res && res.length > 0) {
          const patientLookup = this.dialog.open(InvitationLookupComponent, {
            data: {
              patients: res,
              hideFilter: true,
              showNextBtn: true,
            },
            width: '600px',
          });

          patientLookup.componentInstance.patientChat.subscribe((res) => {
            if (res && res.cardID) {
              if (res.isPromoterOrAdmin == true) {
                const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
                  data: {
                    merchantID: res.merchantID,
                    cardID: res.cardID,
                    singleChat: true,
                  },
                  panelClass: 'noCard',
                  width: '500px',
                });
                ref2.componentInstance.close.subscribe((data) => {
                  ref2.close();
                });

                ref2.componentInstance.getResult.subscribe((data) => {
                  if (data) {
                    const ref3 = RootAppComponent.dialog.open(SmsConversationComponent, {
                      data,
                      panelClass: 'bigger-screen',
                      width: '70%',
                      height: '95vh',
                    });
                    const sub = ref3.componentInstance.close.subscribe((data) => {
                      ref3.close();
                    });
                  }

                  ref2.close();
                });
              } else {
                const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
                  data: {
                    merchantID: res.merchantID,
                    cardID: res.cardID,
                    singleChat: true,
                  },
                  panelClass: 'bigger-screen',
                  width: '70%',
                  height: '95vh',
                });
                const sub = ref2.componentInstance.close.subscribe((data) => {
                  ref2.close();
                });
              }
            }
          });
          patientLookup.componentInstance.closeModal.subscribe((res) => {
            patientLookup.close();
          });
          patientLookup.componentInstance.detailedView.subscribe((res) => {
            patientLookup.close();
          });
          patientLookup.componentInstance.getSelectedPatient.subscribe((res) => {
            if (res) {
              if (res.ID) {
                this.patientID = res.ID;
                this.isFinDMatch = true;
                patientLookup.close();
                this.wizard.goToNextStep();
                this.buildPatient(res);
              }
            }
          });

          patientLookup.componentInstance.createNew.subscribe((res) => {
            this.skipLookup = true;
            patientLookup.close();
            this.wizard.goToNextStep();
          });
        } else {
          this.wizard.goToNextStep();
        }
      });
    } else {
      this.wizard.goToNextStep();
    }
  }

  clearPatientDetails() {
    this.clearEmail.emit(true);
    this.clearMobile.emit(true);
    this.clearHomePhone.emit(true);
    this.clearWorkPhone.emit(true);

    this.user = {
      firstname: '',
      title: '',
      lastname: '',
      middlename: '',
      gender: '',
      mobile: '',
      home_phone: '',
      work_phone: '',
      email: '',
    };

    this.patientID = null;
    this.isFinDMatch = false;
    this.skipLookup = false;
    this.dateOfBirth = null;
    this._dateOfBirth = null;
    this.clearDate.emit(true);
  }

  modifyProfile() {
    if (this.patientID) {
      const ref = this.dialog.open(InvitationCustomerProspectEditComponent, {
        data: {
          patientID: this.patientID,
        },
        width: '650px',
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res && res.ID) {
          this.message = this.convertMessage();
          ref.close();

          this.buildPatient(res);
        }
      });
    }
  }

  viewVideoInfo(videoTitle, docUrl) {
    const ref = this.dialog.open(DocumentUrlViewComponent, {
      data: {
        title: videoTitle,
        description: '',
        documentUrl: docUrl,
      },
      width: '800px',
      panelClass: 'noCard',
    });
    const sub = ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
  }

  goBack() {
    this.location.back();
  }

  exitToDash() {
    this.flyAway = true;
    setTimeout(() => {
      this.router.navigate(['/merchant']);
    }, 1800);
  }

  convertMessage() {
    let result = this.message;

    const rule = {};

    if (this.user && this.selectedMerchant && this.selectedSender && this.rawMessage) {
      if (this.user['Salutation']) {
        rule['PatientSalutation'] = this.user['Salutation'];
      } else {
        rule['PatientSalutation'] = '';
      }

      if (this.user['Name']) {
        rule['PatientLastName'] = this.user['Name'];
      } else {
        rule['PatientLastName'] = '';
      }

      if (this.user['FirstName']) {
        rule['PatientFirstName'] = this.user['FirstName'];
      } else {
        rule['PatientFirstName'] = '';
      }

      if (this.user['MiddleName']) {
        rule['PatientMiddleName'] = this.user['MiddleName'];
      } else {
        rule['PatientMiddleName'] = '';
      }

      if (this.user['mobiles.Number']) {
        rule['PatientMobileNumber'] = this.customePhonePipe.transform(this.user['mobiles.Number'], 'false');
      } else {
        rule['PatientMobileNumber'] = '';
      }
      if (this.merchant) {
        rule['PracticeFirstName'] = this.merchant['FirstName'];
      } else {
        rule['PracticeFirstName'] = ' ';
      }

      if (this.user['phones.Number']) {
        rule['PatientWorkPhoneNumber'] = this.customePhonePipe.transform(this.user['phones.Number'], 'true');
      } else {
        rule['PatientWorkPhoneNumber'] = '';
      }

      if (this.user['Salutation']) {
        rule['PatientEmail'] = this.user['Salutation'];
      } else {
        rule['PatientEmail'] = '';
      }

      if (this.selectedSender['Salutation']) {
        rule['DentistSalutation'] = this.selectedSender['Salutation'];
      } else {
        rule['DentistSalutation'] = '';
      }

      if (this.selectedSender['FirstName']) {
        rule['DentistFirstName'] = this.selectedSender['FirstName'];
      } else {
        rule['DentistFirstName'] = '';
      }

      if (this.selectedSender['MiddleName']) {
        rule['DentistMiddleName'] = this.selectedSender['MiddleName'];
      } else {
        rule['DentistMiddleName'] = '';
      }

      if (this.selectedSender['Name']) {
        rule['DentistLastName'] = this.selectedSender['Name'];
      } else {
        rule['DentistLastName'] = '';
      }

      if (this.selectedSender['mobiles.Number']) {
        rule['DentistMobileNumber'] = this.customePhonePipe.transform(this.selectedSender['mobiles.Number'], 'false');
      } else {
        rule['DentistMobileNumber'] = '';
      }

      if (this.selectedSender['phones.Number']) {
        rule['DentistPhoneNumber'] = this.customePhonePipe.transform(this.selectedSender['phones.Number'], 'true');
      } else {
        rule['DentistPhoneNumber'] = '';
      }

      if (this.selectedSender['emails.Email']) {
        rule['DentistEmail'] = this.selectedSender['emails.Email'];
      } else {
        rule['DentistEmail'] = '';
      }

      if (this.selectedSender['addresses.Calculated']) {
        rule['DentistAddress'] = this.selectedSender['addresses.Calculated'];
      } else {
        rule['DentistAddress'] = '';
      }

      if (this.selectedMerchant['Name']) {
        rule['PracticeLegalName'] = this.selectedMerchant['Name'];
      } else {
        rule['PracticeLegalName'] = '';
      }

      if (this.selectedMerchant['Name']) {
        rule['PracticeLegalName'] = this.selectedMerchant['Name'];
      } else {
        rule['PracticeLegalName'] = '';
      }

      if (this.selectedMerchant['addresses.Calculated']) {
        rule['PracticeAddress'] = this.selectedMerchant['addresses.Calculated'];
      } else {
        rule['PracticeAddress'] = '';
      }

      if (this.selectedMerchant['TradingAs']) {
        rule['PracticeTradingAs'] = this.selectedMerchant['TradingAs'];
      } else {
        rule['PracticeTradingAs'] = '';
      }

      if (this.selectedMerchant['phones.Number']) {
        rule['PracticePhoneNumber'] = this.customePhonePipe.transform(this.selectedMerchant['phones.Number'], 'true');
      } else {
        rule['PracticePhoneNumber'] = '';
      }

      if (this.selectedMerchant['mobiles.Number']) {
        rule['PracticeMobileNumber'] = this.customePhonePipe.transform(
          this.selectedMerchant['mobiles.Number'],
          'false'
        );
      } else {
        rule['PracticeMobileNumber'] = '';
      }

      if (this.selectedMerchant['addresses.Calculated']) {
        rule['PracticeAddress'] = this.selectedMerchant['addresses.Calculated'];
      } else {
        rule['PracticeAddress'] = '';
      }

      if (this.selectedMerchant['ABN']) {
        rule['PracticeABN'] = this.selectedMerchant['ABN'];
      } else {
        rule['PracticeABN'] = '';
      }

      if (this.selectedMerchant['ACN']) {
        rule['PracticeACN'] = this.selectedMerchant['ACN'];
      } else {
        rule['PracticeACN'] = '';
      }

      if (this.selectedMerchant['emails.Email']) {
        rule['PracticeEmail'] = this.selectedMerchant['emails.Email'];
      } else {
        rule['PracticeEmail'] = '';
      }

      if (this.selectedMerchant['Facebook']) {
        rule['PracticeFacebook'] = this.selectedMerchant['Facebook'];
      } else {
        rule['PracticeFacebook'] = '';
      }

      if (this.selectedMerchant['Instagram']) {
        rule['PracticeInstagram'] = this.selectedMerchant['Instagram'];
      } else {
        rule['PracticeInstagram'] = '';
      }

      if (this.selectedMerchant['Twitter']) {
        rule['PracticeTwitter'] = this.selectedMerchant['Twitter'];
      } else {
        rule['PracticeTwitter'] = '';
      }

      if (this.selectedMerchant['LinkedIn']) {
        rule['PracticeLinkedIn'] = this.selectedMerchant['LinkedIn'];
      } else {
        rule['PracticeLinkedIn'] = '';
      }

      if (this.selectedMerchant['Pinterest']) {
        rule['PracticePinterest'] = this.selectedMerchant['Pinterest'];
      } else {
        rule['PracticePinterest'] = '';
      }

      if (this.selectedMerchant['Tumblr']) {
        rule['PracticeTumblr'] = this.selectedMerchant['Tumblr'];
      } else {
        rule['PracticeTumblr'] = '';
      }

      if (this.selectedMerchant['Vimeo']) {
        rule['PracticeVimeo'] = this.selectedMerchant['Vimeo'];
      } else {
        rule['PracticeVimeo'] = '';
      }

      if (this.selectedMerchant['YouTube']) {
        rule['PracticeYouTube'] = this.selectedMerchant['YouTube'];
      } else {
        rule['PracticeYouTube'] = '';
      }

      if (this.selectedMerchant['URL']) {
        rule['PracticeWebSite'] = this.selectedMerchant['URL'];
      } else {
        rule['PracticeWebSite'] = '';
      }
      if (this.selectedMerchant['Twilio_Number']) {
        rule['DedicatedSMSNumber'] = this.customePhonePipe.transform(this.selectedMerchant['Twilio_Number'], 'false');
      } else {
        rule['DedicatedSMSNumber'] = '';
      }

      if (this.selectedMerchant['Terminal_Code']) {
        rule['SelfServicePage'] = `${environment.nodeUrl}/s/${this.merchant['Terminal_Code']}?t=lp`;
      } else {
        rule['SelfServicePage'] = '';
      }
      if (this.selectedMerchant['Terminal_Code']) {
        rule[
          'PracitceAppointment'
        ] = `${Settings.global['publicSiteFrontendLink']}/a/${this.selectedMerchant['Terminal_Code']}`;
      } else {
        rule['PracitceAppointment'] = '';
      }

      if (this.selectedMerchant['Terminal_Code']) {
        rule[
          'PracticePage'
        ] = `${Settings.global['publicSiteFrontendLink']}/l/p/s/${this.selectedMerchant['Terminal_Code']}`;
      } else {
        rule['PracticePage'] = '';
      }

      if (this.selectedMerchant['Terminal_Code']) {
        rule[
          'TipsAndOffers'
        ] = `${Settings.global['publicSiteFrontendLink']}/l/d/s/${this.selectedMerchant['Terminal_Code']}`;
      } else {
        rule['TipsAndOffers'] = '';
      }

      if (this.promoter) {
        if (this.promoter['TradingAs']) {
          rule['PromoterTradingAs'] = this.promoter['TradingAs'];
        } else {
          rule['PromoterTradingAs'] = ' ';
        }

        if (this.promoter['FirstName']) {
          rule['PromoterFirstName'] = this.promoter['FirstName'];
        } else {
          rule['PromoterFirstName'] = ' ';
        }

        if (this.promoter['Name']) {
          rule['PromoterLegalName'] = this.promoter['Name'];
        } else {
          rule['PromoterLegalName'] = ' ';
        }

        if (this.promoter['mobiles.Number']) {
          rule['PromoterMobileNumber'] = this.customePhonePipe.transform(this.promoter['mobiles.Number'], 'false');
        } else {
          rule['PromoterMobileNumber'] = ' ';
        }

        if (this.promoter['phones.Number']) {
          rule['PromoterPhoneNumber'] = this.customePhonePipe.transform(this.promoter['phones.Number'], 'true');
        } else {
          rule['PromoterPhoneNumber'] = ' ';
        }

        if (this.promoter['emails.Email']) {
          rule['PromoterEmail'] = this.promoter['emails.Email'];
        } else {
          rule['PromoterEmail'] = ' ';
        }

        if (this.promoter['addresses.Calculated']) {
          rule['PromoterAddress'] = this.promoter['addresses.Calculated'];
        } else {
          rule['PromoterAddress'] = ' ';
        }

        if (this.promoter['Facebook']) {
          rule['PromoterFacebook'] = this.promoter['Facebook'];
        } else {
          rule['PromoterFacebook'] = ' ';
        }

        if (this.promoter['Instagram']) {
          rule['PromoterInstagram'] = this.promoter['Instagram'];
        } else {
          rule['PromoterInstagram'] = ' ';
        }
        if (this.promoter['Twitter']) {
          rule['PromoterTwitter'] = this.promoter['Twitter'];
        } else {
          rule['PromoterTwitter'] = ' ';
        }

        if (this.promoter['LinkedIn']) {
          rule['PromoterLinkedIn'] = this.promoter['LinkedIn'];
        } else {
          rule['PromoterLinkedIn'] = ' ';
        }

        if (this.promoter['Pinterest']) {
          rule['PromoterPinterest'] = this.promoter['Pinterest'];
        } else {
          rule['PromoterPinterest'] = ' ';
        }
        if (this.promoter['Tumblr']) {
          rule['PromoterTumblr'] = this.promoter['Tumblr'];
        } else {
          rule['PromoterTumblr'] = ' ';
        }

        if (this.promoter['Vimeo']) {
          rule['PromoterVimeo'] = this.promoter['Vimeo'];
        } else {
          rule['PromoterVimeo'] = ' ';
        }
        if (this.promoter['YouTube']) {
          rule['PromoterYouTube'] = this.promoter['YouTube'];
        } else {
          rule['PromoterYouTube'] = ' ';
        }
        if (this.promoter['URL']) {
          rule['PromoterWebSite'] = this.promoter['URL'];
        } else {
          rule['PromoterWebSite'] = ' ';
        }

        if (this.promoter['ABN']) {
          rule['PromoterABN'] = this.promoter['ABN'];
        } else {
          rule['PromoterABN'] = ' ';
        }
        if (this.promoter['ACN']) {
          rule['PromoterACN'] = this.promoter['ACN'];
        } else {
          rule['PromoterACN'] = ' ';
        }
      }
      result = this.util.convertMessage(this.rawMessage, rule);
    }
    return result;
  }

  defaultMessageChange(m) {
    if (m && m.value) {
      this.rawMessage = m.value;
      this.message = this.convertMessage();
    }
  }

  patientProfile() {
    if (this.invitationPatientID) {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['customer-profile', this.invitationPatientID, 'patient'],
          },
        },
      ]);
    }
  }
}
