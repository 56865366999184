import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '../../shared/shared.module';
import { ThemeRoutingModule } from './shared/theme-routing.module';
import { ThemeService } from './shared/theme.service';
import { ThemeCreateComponent } from './theme-create/theme-create.component';
import { ThemeListComponent } from './theme-list/theme-list.component';
import { ThemeViewComponent } from './theme-view/theme-view.component';

@NgModule({
  imports: [CommonModule, NgPipesModule, SharedModule, ThemeRoutingModule],
  declarations: [ThemeCreateComponent, ThemeViewComponent, ThemeListComponent],
  exports: [ThemeCreateComponent, ThemeViewComponent, ThemeListComponent],
  providers: [ThemeService],
})
export class ThemeModule {}
