import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { LookupService } from '../../../shared/services/lookup.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { InvitationService } from '../../invitation/shared/services/invitation.service';
import { PatientCardChangesModalComponent } from '../../patient/patient-card-changes-modal/patient-card-changes-modal.component';
import { PatientCardDeferModalComponent } from '../../patient/patient-card-defer-modal/patient-card-defer-modal.component';
import { PatientCardStopModalComponent } from '../../patient/patient-card-stop-modal/patient-card-stop-modal.component';
import { PatientService } from '../../patient/shared/patient.service';
import { InformedConsentComponent } from '../informed-consent/informed-consent.component';

@Component({
  selector: 'app-invitation-card',
  templateUrl: './invitation-card.component.html',
  styleUrls: ['./invitation-card.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-50%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-50%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class InvitationCardComponent implements OnInit {
  isPaymentPlan = false;

  reasons = [];
  feedback = [];

  serviceType;
  serviceTypes = [];

  @Input()
  invitationID;

  @Input()
  viewOnly = false;

  @Input('customerInvitation')
  customInv = null;

  @Output()
  consumerChoice = new EventEmitter();

  @Output()
  deferedWeek = new EventEmitter();

  settings = Settings.global;

  isDocumentExisted = false;

  deposits;

  consumerCanChange = false;

  patientContribution;
  frequency = 4;
  duration;
  productID;

  context = Settings.global['context'];

  constructor(
    private dialog: MatDialog,
    private lookupService: LookupService,
    private patientService: PatientService,
    private invitationService: InvitationService
  ) {}

  ngOnInit() {
    this.invitationService.markAsViewed(this.customInv['ID']).subscribe((res) => {
      this.lookupService.getLookup('simpleLookup', 'LoanPurpose').subscribe((res1) => {
        this.serviceTypes = res1;
        this.serviceType = this.customInv['Service.Type'] || null;
      });
    });
  }

  frequencyValidation(freq) {
    if (freq && Number(freq) != 0) {
      this.frequency = Number(freq);
      return this.frequency;
    }
  }

  showPaymentCard() {
    // let ref = this.dialog.open(InformedConsentComponent, {
    //   panelClass: "infConsent"
    // });

    // ref.componentInstance.agreement.subscribe(data => {
    // if (data == true) {
    //   ref.close();
    const payload = {
      frequency: Number(this.frequency),
      serviceType: this.serviceType,
      productID: this.productID,
      duration: Number(this.duration),
      patientContribution: Number(this.patientContribution) || null,
    };

    this.invitationService.proceedRetail(this.invitationID, payload).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast(
          'success',
          'Successfull selected',
          'You will be redirecting to a page to finish you application '
        );

        if (Settings.global['isWakandaIntegrated'] && Settings.global['isWakandaIntegrated'] === true) {
          if (this.isPaymentPlan) {
            let ppLink = Settings.global['wakandaPPLink'];
            ppLink = ppLink.replace('_id_', this.invitationID);

            debugger
            window.open(`${Settings.global['publicSiteFrontendLink']}/thank-you-pp/customer-card?link=${encodeURIComponent(ppLink)}`, '_parent').focus();
          } else {
            let laLink = Settings.global['wakandaLALink'];
            laLink = laLink.replace('_id_', this.invitationID);
            window.open(`${Settings.global['publicSiteFrontendLink']}/thank-you-la/customer-card?link=${encodeURIComponent(laLink)}`, '_parent').focus();
          }
        } else {
          if (this.isPaymentPlan) {
            let ppLink = Settings.global['wakandaPPLink'];
            ppLink = ppLink.replace('_id_', this.invitationID);

            debugger
            window.open(`${Settings.global['publicSiteFrontendLink']}/thank-you-pp/customer-card?link=${encodeURIComponent(ppLink)}`, '_parent').focus();
          } else {
            let laLink = Settings.global['wakandaLALink'];
            laLink = laLink.replace('_id_', this.invitationID);
            window.open(`${Settings.global['publicSiteFrontendLink']}/thank-you-la/customer-card?link=${encodeURIComponent(laLink)}`, '_parent').focus();
          }
        }
      }
    });

    // }

    // });
  }

  openPatientCardChangesModal() {
    const ref = this.dialog.open(PatientCardChangesModalComponent, {
      data: {
        invitationID: this.customInv['ID'],
      },
      width: '500px',
    });

    const sub = ref.componentInstance.done.subscribe((data) => {
      ref.close();

      this.consumerChoice.emit('DSMO');
    });
  }

  openPatientCardDeferModal() {
    const ref = this.dialog.open(PatientCardDeferModalComponent, {
      data: {
        invitationID: this.customInv['ID'],
      },

      width: '350px',
    });

    const sub = ref.componentInstance.done.subscribe((data) => {
      ref.close();
      this.consumerChoice.emit('DEFER');
      this.deferedWeek.emit(data.weeksDefered);
    });
  }

  openPatientCardStopModal() {
    const ref = this.dialog.open(PatientCardStopModalComponent, {
      data: {
        invitationID: this.customInv['ID'],
      },

      width: '500px',
      panelClass: 'flex',
    });

    const sub = ref.componentInstance.done.subscribe((data) => {
      this.consumerChoice.emit('NOT');

      ref.close();
    });
  }

  confirmNotFincance() {
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: new ConfirmDialog(
        'check_circle',
        'Proceed, and pay at the practice',
        'You are selecting to proceed with your treatment, arranging to settle your bill at the practice using cash or credit card. Are you sure you wish to proceed?',
        'Let me see my options again',
        'Yes, Proceed'
      ),
      width: '500px',
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const ref1 = this.dialog.open(InformedConsentComponent, {
            panelClass: 'infConsent',
          });

          ref1.componentInstance.agreement.subscribe((data) => {
            if (data == true) {
              this.patientService.notFinanceInivation(this.customInv['ID']).subscribe((res) => {
                if (res) {
                  NotifyAppComponent.displayToast(
                    'success',
                    'Successful operation',
                    'Your can now proceed and pay at practice'
                  );

                  this.consumerChoice.emit('DISC');

                  ref1.close();
                }
              });
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  selectProduct(product) {
    if (
      product &&
      product['ID'] &&
      (this.customInv['Product.PatientCanChoose'] == '1' ||
        this.customInv['Product_Key'] == '' ||
        this.customInv['Product_Key'] == '00000000000000000000000000000000')
    ) {
      this.customInv['Product_Key'] = product['ID'];
    }
  }

  calculator(res) {
    if (res) {
      this.duration = res['duration'];
      this.productID = res['ProductID'];
      this.frequency = res['frequency'];
      this.patientContribution = res['patientContribution'];
      this.isPaymentPlan = res['isPaymentPlan'] || false;
    }
  }
}
