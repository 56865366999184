import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MarketingCampaginEditModalComponent } from '../marketing-campagin-edit-modal/marketing-campagin-edit-modal.component';
import { MarketingCampaginLookupComponent } from '../marketing-campagin-lookup/marketing-campagin-lookup.component';
import { MarketingCampaignCreateEditOverviewComponent } from '../marketing-campaign-create-edit-overview/marketing-campaign-create-edit-overview.component';
import { MarketingCampaignCreateEditComponent } from '../marketing-campaign-create-edit/marketing-campaign-create-edit.component';
import { MarketingCampaignMessageListOverviewComponent } from '../marketing-campaign-message-list-overview/marketing-campaign-message-list-overview.component';
import { MarketingCampaignMessageListComponent } from '../marketing-campaign-message-list/marketing-campaign-message-list.component';
import { MarketingCampaignReviewComponent } from '../marketing-campaign-review/marketing-campaign-review.component';
import { MarketingCampaignSocialMediaCreateComponent } from '../marketing-campaign-social-media-create/marketing-campaign-social-media-create.component';
import { MarketingCampaignTipsOffersLookupComponent } from '../marketing-campaign-tips-offers-lookup/marketing-campaign-tips-offers-lookup.component';
import { MarketingCampaignViewComponent } from '../marketing-campaign-view/marketing-campaign-view.component';
import { MarketingEmailViewModalComponent } from '../marketing-email-view-modal/marketing-email-view-modal.component';
import { MarketingGoogleReviewInvitationCreate } from '../marketing-google-review-invitation-create/marketing-google-review-invitation-create.component';
import { MarketingListGlobalComponent } from '../marketing-list-global/marketing-list-global.component';
import { MarketingListComponent } from '../marketing-list/marketing-list.component';
import { MarketingNewCampaignModalComponent } from '../marketing-new-campaign-modal/marketing-new-campaign-modal.component';
import { MarketingOverViewComponent } from '../marketing-over-view/marketing-over-view.component';
import { MarketingRedirectionComponent } from '../marketing-redirection/marketing-redirection.component';
import { MarketingResendModalComponent } from '../marketing-resend-modal/marketing-resend-modal.component';
import { MarketingTemplateGalleryComponent } from '../marketing-template-gallery/marketing-template-gallery.component';
import { MarketingViewLogoComponent } from '../marketing-view-logo/marketing-view-logo.component';
import { NonReachedTargetListComponent } from '../non-reached-targets-list/non-reached-targets-list.component';

const routes: Routes = [
  {
    path: 'MarketingCampaignMessageListComponent',
    component: MarketingCampaignMessageListComponent,
  },
  {
    path: 'MarketingCampaignMessageListOverviewComponent/:campaignID',
    component: MarketingCampaignMessageListOverviewComponent,
  },

  {
    path: 'MarketingCampaginEditModalComponent',
    component: MarketingCampaginEditModalComponent,
  },
  {
    path: 'MarketingGoogleReviewInvitationCreate',
    component: MarketingGoogleReviewInvitationCreate,
  },

  {
    path: 'MarketingCampaignSocialMediaCreateComponent',
    component: MarketingCampaignSocialMediaCreateComponent,
  },

  {
    path: 'MarketingCampaginLookupComponent',
    component: MarketingCampaginLookupComponent,
  },

  {
    path: 'c/:linkCode/:linkID',
    component: MarketingRedirectionComponent,
  },

  {
    path: 'marketing-over-view',
    component: MarketingOverViewComponent,
  },
  {
    path: 'marketing-campaign-create',
    component: MarketingCampaignCreateEditComponent,
  },

  {
    path: 'MarketingViewLogoComponent',
    component: MarketingViewLogoComponent,
  },

  {
    path: 'marketing-campaign-edit/:campaignID',
    component: MarketingCampaignCreateEditComponent,
  },
  {
    path: 'MarketingTemplateGalleryComponent',
    component: MarketingTemplateGalleryComponent,
  },

  {
    path: 'marketing-campaign-view',
    component: MarketingCampaignViewComponent,
  },
  {
    path: 'marketing-campaign-view/:campaignID',
    component: MarketingCampaignViewComponent,
  },
  {
    path: 'marketing-campaign-review',
    component: MarketingCampaignReviewComponent,
  },
  {
    path: 'marketing-list-global',
    component: MarketingListGlobalComponent,
  },
  {
    path: 'marketing-list',
    component: MarketingListComponent,
  },
  {
    path: 'marketing-resend-modal',
    component: MarketingResendModalComponent,
  },

  {
    path: 'marketing-email-view-modal',
    component: MarketingEmailViewModalComponent,
  },
  {
    path: 'marketing-campaign-create-edit-overview',
    component: MarketingCampaignCreateEditOverviewComponent,
  },
  {
    path: 'marketing-new-campaign-modal',
    component: MarketingNewCampaignModalComponent,
  },
  {
    path: 'non-reached-targets-list',
    component: NonReachedTargetListComponent,
  },

  {
    path: 'tips-marketing-offer',
    component: MarketingCampaignTipsOffersLookupComponent,
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MarketingRoutingModule {}
