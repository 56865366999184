<div class="row clearfix defineMessage">
  <mat-card class="card-centered">
    <!-- blue heading -->
    <div class="card-header primary-gradient-img clearfix">
      <div class="row clearfix">
        <!-- Modal dismiss button -->
        <button class="btn-close btn-clear pull-right mat-button" *ngIf="isModal == true" (click)="closeEvent()">
          <mat-icon>clear</mat-icon>
        </button>
        <!-- merchant calculated name -->
        <div class="row titleArea clearfix">
          <h2 class="message-title summary-header rm-m" *ngIf="isEditMode == false">
            <span> New {{ "KEYWORD.treatment" | translate | titlecase }} Product </span>
          </h2>
          <h2 class="message-title summary-header rm-m" *ngIf="isEditMode == true">
            <span> Edit {{ "KEYWORD.treatment" | translate | titlecase }} Product </span>
            <span class="subLabel">{{ product['label'] }}</span>
          </h2>
        </div>
      </div>
    </div>
    <mat-dialog-content [ngClass]="{ 'no-modal-height': isModal != true }">
      <div class="row clearfix flex">
        <!--  Logo Upload -->
        <div class="uploadBlock left full-width" *ngIf="isDone != true">
          <div class="row clearfix logo-img image">
            <div class="notUploaded" *ngIf="isLogoFile == false && isEditMode == false">
              <app-ep-document-view-image
                class="clearfix notYetUploaded"
                [defaultPicture]="
                  'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cloud-upload-alt-solid.png)'
                "
              >
              </app-ep-document-view-image>
            </div>
            <div class="displayUploadImg text-center" *ngIf="isLogoFile == false && isEditMode == true">
              <app-ep-document-view-image
                class="clearfix full-width profile-pic-container"
                [defaultPicture]="
                  'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cloud-upload-alt-solid.png)'
                "
                [link]="logoLink"
                [hideZoomButton]="false"
              >
              </app-ep-document-view-image>
            </div>
            <div class="displayUploadImg mt" *ngIf="isLogoFile == true">
              <img class="display-image-uploaded" [src]="logoSrc"/>
            </div>
          </div>
          <div class="row clearfix">
            <h4 class="sr-title sm-h4 rm-mb">Upload product logo</h4>
            <app-file-uploader
              class="text-center clearfix full-width"
              [allowedExtensionTypes]="['image']"
              [isFixedAspectRatio]="true"
              [isImageCropping]="true"
              [canSkip]="false"
              [limit]="1"
              [uploadDirectly]="true"
              [isDragable]="true"
              [title]="'CLICK HERE to upload'"
              (onCompleteAll)="onCompleteLogoAll($event)"
            >
            </app-file-uploader>
          </div>
        </div>
        <!--  Picture Upload -->
        <div class="uploadBlock right full-width">
          <div class="row clearfix pic-img image">
            <div class="notUploaded" *ngIf="isCatalogueFile == false && isEditMode == false">
              <app-ep-document-view-image
                class="clearfix notYetUploaded"
                [defaultPicture]="
                  'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cloud-upload-alt-solid.png)'
                "
              >
              </app-ep-document-view-image>
            </div>
            <div class="displayUploadImg text-center" *ngIf="isCatalogueFile == false && isEditMode == true">
              <app-ep-document-view-image
                class="clearfix full-width profile-pic-container"
                [defaultPicture]="
                  'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cloud-upload-alt-solid.png)'
                "
                [link]="catalogueLink"
                [hideZoomButton]="false"
              >
              </app-ep-document-view-image>
            </div>
            <div class="displayUploadImg mt" *ngIf="isCatalogueFile == true">
              <img class="display-image-uploaded" [src]="catalogueSrc"/>
            </div>
          </div>
          <div class="row clearfix" *ngIf="isDone != true">
            <h4 class="sr-title sm-h4 rm-mb">Upload catalogue image</h4>
            <app-file-uploader
              class="text-center clearfix full-width"
              [allowedExtensionTypes]="['image']"
              [isImageCropping]="true"
              [canSkip]="false"
              [limit]="1"
              [uploadDirectly]="true"
              [isDragable]="true"
              [title]="'CLICK HERE to upload'"
              (onCompleteAll)="onCompleteCatalogueAll($event)"
            >
            </app-file-uploader>
          </div>
        </div>
      </div>

      <div class="row clearfix">
        <form class="row clearfix" #treatmentGroup="ngForm">
          <div class="row clearfix" *ngIf="isDone == false">
            <div class="grey-row">
              <div class="row clearfix">
                <div class="row clearfix">
                  <h3 class="sr-title rm-mt pull-left">Primary information</h3>

                  <div class="pull-right" *ngIf="isEditMode == true">
                    <mat-slide-toggle [(ngModel)]="product.isActive" name="isActive">
                      <span *ngIf="product.isActive == true">Product active</span>
                      <span *ngIf="product.isActive == false">Product inactive</span>
                    </mat-slide-toggle>
                  </div>
                </div>

                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Label</mat-label>

                  <input
                    class="mat-block"
                    [(ngModel)]="product.label"
                    matInput
                    name="label"
                    required
                    placeholder="Label"
                  />
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Description</mat-label>
                  <textarea
                    [(ngModel)]="product.description"
                    matInput
                    placeholder="description"
                    name="Description"
                  ></textarea>
                </mat-form-field>
              </div>

              <div class="row clearfix">
                <div class="half-width">
                  <div class="col-left">
                    <mat-form-field class="full-width large-input green-text" appearance="outline">
                      <mat-label> {{"KEYWORD.Treatment" | translate}} Sell Price</mat-label>
                      <input
                        class="mat-block"
                        [(ngModel)]="product.sellPrice"
                        matInput
                        name="treatmentSellPrice"
                        [placeholder]="('KEYWORD.treatment' | translate | titlecase) + ' Sell Price'"
                        currencyMask
                      />
                    </mat-form-field>
                  </div>
                </div>
                <div class="half-width">
                  <div class="col-right">
                    <mat-form-field class="full-width large-input accent-color" appearance="outline">
                      <mat-label> {{"KEYWORD.Treatment" | translate}} Cost Price</mat-label>
                      <input
                        class="mat-block"
                        [(ngModel)]="product.costPrice"
                        matInput
                        name="costPrice"
                        [placeholder]="('KEYWORD.treatment' | translate | titlecase) + ' Cost Price'"
                        currencyMask
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <h3 class="sr-title">Category information</h3>

            <div class="row clearfix full-width flex">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Item Code</mat-label>
                <input
                  class="mat-block"
                  [(ngModel)]="product.itemCode"
                  (change)="changeItemCode()"
                  matInput
                  name="itemCode"
                  placeholder="Item Code"
                />
              </mat-form-field>
            </div>

            <div class="clearfix row full-width">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Category</mat-label>
                <mat-select
                  class=""
                  [(ngModel)]="product.categoryID"
                  (selectionChange)="onSelectSimpleCategory(product.categoryID)"
                  name="category"
                  placeholder="Category"
                  required
                >
                  <mat-option *ngFor="let c of categories" [value]="c.ID">
                    {{ treatmentUtil.getLabel(c.Label) }}
                  </mat-option>
                </mat-select>

                <button
                  class=""
                  *ngIf="product.categoryID"
                  (click)="$event.stopPropagation(); viewCategory(product.categoryID)"
                  matTooltip="View Category"
                  color="accent"
                  mat-button
                  matSuffix
                >
                  <mat-icon>visibility</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div class="clearfix row full-width">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Type</mat-label>
                <mat-select
                  class=""
                  [(ngModel)]="product.typeID"
                  (selectionChange)="onSelectSimpleType(product.typeID)"
                  name="type"
                  placeholder="Type"
                  required
                >
                  <mat-option *ngFor="let c of types" [value]="c.ID" (click)="setCode(c.Dafault_Item_Code)">
                    {{ treatmentUtil.getLabel(c.Label) }}
                  </mat-option>
                </mat-select>

                <button
                  class=""
                  *ngIf="product.typeID"
                  (click)="$event.stopPropagation(); viewType(product.typeID)"
                  matTooltip="View Type"
                  color="accent"
                  mat-button
                  matSuffix
                >
                  <mat-icon>visibility</mat-icon>
                </button>
              </mat-form-field>
            </div>

            <div class="clearfix row full-width">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Brand</mat-label>
                <mat-select
                  class=""
                  [(ngModel)]="product.brandID"
                  (selectionChange)="onSelectSimpleBrand(product.brandID)"
                  name="type"
                  placeholder="Brand"
                  required
                >
                  <mat-option *ngFor="let c of brands" [value]="c.ID">
                    {{ treatmentUtil.getLabel(c.Label) }}
                  </mat-option>
                </mat-select>

                <button
                  class=""
                  *ngIf="product.brandID"
                  (click)="$event.stopPropagation(); viewBrand(product.brandID)"
                  matTooltip="View Brand"
                  color="accent"
                  mat-button
                  matSuffix
                >
                  <mat-icon>visibility</mat-icon>
                </button>
              </mat-form-field>
            </div>

            <div class="row clearfix">
              <h3 class="sr-title rm-mt">Supplier information</h3>

              <div class="clearfix half-width">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Manufacturer</mat-label>
                  <mat-select
                    class=""
                    [(ngModel)]="product.manufacturerID"
                    name="Manufacturer"
                    placeholder="Manufacturer"
                  >
                    <mat-option *ngFor="let m of manufacturers" [value]="m.ID">
                      {{ m.Name }}
                    </mat-option>
                  </mat-select>

                  <button
                    class=""
                    *ngIf="product.manufacturerID"
                    (click)="$event.stopPropagation(); viewSupplier(product.manufacturerID)"
                    matTooltip=" View Manufacturer"
                    color="accent"
                    mat-button
                    matSuffix
                  >
                    <mat-icon>visibility</mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <div class="half-width">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Distributor</mat-label>
                  <mat-select class="" [(ngModel)]="product.distributorID" name="Distributor" placeholder="Distributor">
                    <mat-option *ngFor="let d of distributors" [value]="d.ID">
                      {{ d.Name }}
                    </mat-option>
                  </mat-select>

                  <button
                    class=""
                    *ngIf="product.distributorID"
                    (click)="$event.stopPropagation(); viewSupplier(product.distributorID)"
                    matTooltip=" View Distributor"
                    color="accent"
                    mat-button
                    matSuffix
                  >
                    <mat-icon>visibility</mat-icon>
                  </button>
                </mat-form-field>
              </div>
            </div>

            <h3 class="sr-title"> {{"KEYWORD.Treatment" | translate}} &amp; diagnosis</h3>

            <div class="row clearfix">
              <div class="half-width">
                <div class="col-left">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Diagnosis notes</mat-label>

                    <textarea
                      class="dentMsg increase-input-field"
                      [(ngModel)]="product.diagnosisNote"
                      name="diagnosisNote"
                      placeholder="Diagnosis notes"
                      matInput
                      cols="30"
                      rows="50"
                    ></textarea>
                  </mat-form-field>
                </div>
              </div>
              <div class="half-width">
                <div class="col-right">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label> {{"KEYWORD.Treatment" | translate}} notes</mat-label>
                    <textarea
                      class="dentMsg increase-input-field"
                      [(ngModel)]="product.treatmentNote"
                      name="treatmentNote"
                      [placeholder]="('KEYWORD.treatment' | translate | titlecase) + ' notes'"
                      matInput
                      cols="30"
                      rows="50"
                    ></textarea>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="grey-row">
              <h3 class="sr-title rm-mt">Additional notes</h3>

              <div class="row clearfix">
                <div class="half-width">
                  <div class="col-left">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Default note label</mat-label>

                      <textarea
                        class="mat-block"
                        [(ngModel)]="product.labelNote"
                        name="labelNote"
                        placeholder="Default note label"
                        matInput
                        cols="30"
                        rows="50"
                      ></textarea>
                    </mat-form-field>
                  </div>
                </div>
                <div class="half-width">
                  <div class="col-right">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Default note category</mat-label>
                      <textarea
                        class="dentMsg increase-input-field"
                        [(ngModel)]="product.categoryNote"
                        name="categoryNote"
                        placeholder="Default note category"
                        matInput
                        cols="30"
                        rows="50"
                      ></textarea>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="isDone == true">
            <div class="container animated fadeInDown" id="step-4">
              <div class="row clearfix text-center">
                <h2 class="sr-title inline-block rm-mt" tabindex="0">
                  <span *ngIf="isEditMode == false">Product created!</span>
                  <span *ngIf="isEditMode == true">Product details updated!</span>
                </h2>
              </div>
              <div class="row clearfix">
                <div class="full-width text-center">
                  <hr class="mt"/>
                  <div class="svg-box">
                    <svg class="circular green-stroke">
                      <circle class="path" cx="75" cy="75" r="50" fill="none" stroke-width="5" stroke-miterlimit="10" />
                    </svg>
                    <svg class="checkmark green-stroke">
                      <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                        <path
                          class="checkmark__check"
                          fill="none"
                          d="M616.306,283.025L634.087,300.805L673.361,261.53"
                        />
                      </g>
                    </svg>
                  </div>

                  <div class="row clearfix">
                    <div class="full-width text-center step-button-row">
                      <button
                        class="step-next"
                        *ngIf="isModal == true"
                        (click)="closeEvent()"
                        mat-raised-button
                        color="primary"
                      >
                        Close
                      </button>
                      <button
                        class="step-next"
                        *ngIf="productID"
                        (click)="viewProduct(productID)"
                        mat-raised-button
                        color="accent"
                      >
                        <mat-icon>visibility</mat-icon> View
                      </button>
                      <button
                        class="step-next"
                        *ngIf="isModal != true"
                        (click)="list()"
                        mat-raised-button
                        color="accent"
                      >
                        <mat-icon>list</mat-icon> View Product list
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </mat-dialog-content>

    <!-- fixed action buttons -->

    <div class="row clearfix text-center button-row" *ngIf="isDone == false">
      <button class="" *ngIf="isModal == true" (click)="closeEvent()" mat-raised-button color="primary">Close</button>
      <button
        class=""
        *ngIf="isEditMode != true"
        [disabled]="!treatmentGroup.form.valid"
        (click)="save()"
        mat-raised-button
        color="accent"
      >
        Save
        <mat-icon>check</mat-icon>
      </button>
      <button
        class=""
        *ngIf="isEditMode == true"
        [disabled]="!treatmentGroup.form.valid"
        (click)="edit()"
        mat-raised-button
        color="accent"
      >
        Save
        <mat-icon>check</mat-icon>
      </button>
    </div>
  </mat-card>
</div>
