import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { AppStateService } from '../../../../../core/app-state/app-state.service';
import { EpDocumentViewComponent } from '../../../../../shared/components/ep-document-view/ep-document-view.component';
import { RootAppComponent } from '../../../../../shared/components/root-component/root-component.component';
import { CountStringIdsPipe } from '../../../../../shared/pipes/count-string-ids/count-string-ids.pipe';
import { CustomDateTimePipe } from '../../../../../shared/pipes/custom-date-time.pipe';
import { CustomDatePipe } from '../../../../../shared/pipes/custom-date.pipe';
import { CustomPhonePipe } from '../../../../../shared/pipes/custom-phone.pipe';
import { NotifyAppComponent } from '../../../../../shared/types/notify-app-component';
import { MerchantService } from '../../../../merchant/shared/merchant.service';
import { Merchant } from '../../../../merchant/shared/types/merchant.type';
import { InformedConsent, InformedConsentService } from '../../../shared/informed-consent.service';

@Component({
  selector: 'ipv-informed-consent-treatment-presented-main',
  templateUrl: './informed-consent-treatment-presented.component.html',
  styleUrls: ['./informed-consent-treatment-presented.component.css'],
  providers: [TitleCasePipe, CustomDatePipe, CustomDateTimePipe, CustomPhonePipe, CountStringIdsPipe],
})
export class InformedConsentTreatmentPresentedComponent implements OnInit, OnDestroy {
  @Input() informedConsentList: InformedConsent[] = [];
  @Input() printMerchant: Partial<Merchant> = null;
  @Input() isPromoterOrAdmin = false;

  hiddenColumns = [
    'ID',
    'TreatmentPlan_key',
    'PatientDeclaration_Doc_Key',
    'PatientDeclaration',
    'Invitation_key',
    'Merchant_key',
    'Invitation.LastName',
    'Services.Presented.When',
    'Services.Presented.Flag',
    'Merchant.Name',
  ];

  constructor(
    private merchantService: MerchantService,
    private customDatePipe: CustomDatePipe,
    private informedConsentService: InformedConsentService,
    private appStateService: AppStateService
  ) {}

  ngOnInit() {
    if (this.isPromoterOrAdmin) {
      this.hiddenColumns = this.hiddenColumns.filter((column) => column !== 'Merchant.Name');
    }
  }

  ngOnDestroy() {
    /** required for untilDestroyed (Replace with @UntilDestroyed() Decorator in angular 9 and delete ngOnDestroy()) */
  }

  viewPDF(informedConsent: InformedConsent) {
    if (informedConsent.PatientDeclaration_Doc_Key) {
      this.showDocumentDialog(informedConsent.PatientDeclaration_Doc_Key, informedConsent);
    } else {
      this.createPDF(informedConsent);
    }
  }

  createPDF(informedConsent: InformedConsent) {
    if (informedConsent['InformedConsent.Given.Flag'] === '1') {
      this.appStateService
        .getAppState$()
        .pipe(
          untilDestroyed(this),
          mergeMap((appState) => {
            if (informedConsent.PatientDeclaration) {
              return of(appState.sessionType);
            } else {
              return this.merchantService
                .getMerchantDetails(informedConsent.Merchant_key, {}, appState.sessionType)
                .pipe(
                  untilDestroyed(this),
                  mergeMap((merchantDetails) => {
                    informedConsent.PatientDeclaration = merchantDetails.InformedConsent_Declaration;
                    return this.informedConsentService
                      .updateInformedConsent(informedConsent.ID, {
                        PatientDeclaration: merchantDetails.InformedConsent_Declaration,
                      })
                      .pipe(
                        map(() => {
                          return appState.sessionType;
                        })
                      );
                  })
                );
            }
          }),
          mergeMap(() => {
            if (this.isPromoterOrAdmin) {
              return this.merchantService
                .fetchMerchants$({
                  ID: informedConsent.Merchant_key,
                })
                .pipe(
                  untilDestroyed(this),
                  mergeMap((printMerchant) => {
                    return this.informedConsentService.createInformedConsentPDF(informedConsent.ID).pipe(
                      map((documentID) => {
                        return { documentID, printMerchant: printMerchant[0] };
                      })
                    );
                  }),
                  mergeMap(({ documentID, printMerchant }) => {
                    return this.informedConsentService
                      .updateInformedConsent(informedConsent.ID, {
                        PatientDeclaration_Doc_Key: documentID,
                      })
                      .pipe(
                        tap(() => {
                          const newInformedConsent = { ...informedConsent, PatientDeclaration_Doc_Key: documentID };
                          this.informedConsentService.editInformedConsentList(newInformedConsent);
                          this.showDocumentDialog(documentID, informedConsent);
                        })
                      );
                  })
                );
            } else {
              return this.appStateService.getAppState$().pipe(
                untilDestroyed(this),
                mergeMap((printMerchant) => {
                  return this.informedConsentService.createInformedConsentPDF(informedConsent.ID).pipe(
                    map((documentID) => {
                      return { documentID, printMerchant };
                    })
                  );
                }),
                mergeMap(({ documentID, printMerchant }) => {
                  return this.informedConsentService
                    .updateInformedConsent(informedConsent.ID, {
                      PatientDeclaration_Doc_Key: documentID,
                    })
                    .pipe(
                      tap(() => {
                        const newInformedConsent = { ...informedConsent, PatientDeclaration_Doc_Key: documentID };
                        this.informedConsentService.editInformedConsentList(newInformedConsent);
                        this.showDocumentDialog(documentID, informedConsent);
                      })
                    );
                })
              );
            }
          })
        )
        .subscribe();
    }
  }

  showDocumentDialog(documentID: any, informedConsent: InformedConsent) {

    const title = `Informed Consent for ${informedConsent['Invitation.FirstName']} ${
      informedConsent['Invitation.LastName']
    } (${this.customDatePipe.transform(informedConsent['InformedConsent.Given.When'])})`;

    const ref = RootAppComponent.dialog.open(EpDocumentViewComponent, {
      data: {
        documentID,
        title,
      },
      width: '800px',
      panelClass: 'noCard',
    });

    ref.componentInstance.noFileFound.subscribe((res) => {
      NotifyAppComponent.displayToast('warning', 'Sorry!', 'We could not get the requested file(s)');
    });

    ref.componentInstance.closeModal.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res === true) {
        ref.close();
      }
    });
  }
}
