<div class="container error-page">
  <mat-card class="error text-center">
    <div class="card-header error-gradient clearfix inModal">
      <div class="row clearfix text-left">
        <div class="row titleArea clearfix full-width">
          <mat-icon class="fas fa-unlock-alt pull-left"></mat-icon>
          <h2 class="summary-header rm-m white">
            Unauthorized
            <span class="subLabel">Error code: <strong>403</strong></span>
          </h2>
        </div>
      </div>
    </div>

    <img
      class="bannerImg"
      src="https://email-graphics-smileright.s3-ap-southeast-2.amazonaws.com/error-modal-banner.png"
    />

    <div class="errorContent mb">
      <p class="lead">{{ title }}</p>
      <p>{{ error }}</p>
      <hr/>
    </div>

    <div class="button-row full-width text-center">
      <button class="mr ml pull-right" (click)="contactUs()" mat-raised-button color="warn">Contact us</button>

      <button class="mr ml" *ngIf="type != 'raw'" (click)="login()" mat-raised-button color="accent">Login</button>

      <button class="" (click)="goHome()" mat-raised-button color="accent">
        Go Home <span *ngIf="countdown && type != 'raw'">({{ countdown / 1000 }}) </span>
      </button>

      <!-- <button mat-raised-button color="accent" class="mr ml" (click)="login()">Login Again</button> -->
    </div>
  </mat-card>
</div>
