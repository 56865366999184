<aw-wizard #wizard navBarLayout="large-filled-symbols">
  <aw-wizard-step stepTitle="Customer Details" navigationSymbol="1">
    <!-- Step 1  -->
    <div class="container" id="step-1">
      <div class="row clearfix">
        <div class="full-width rel">
          <div class="text-center">
            <h2 class="sr-title inline-block rm-mt">New Finance Invitation</h2>
            <div class="pat-lookup-btn">
              <!-- Patient Lookup Trigger -->
              <button class="btn-small" (click)="openPatientLookUpDialog()" mat-raised-button color="primary">
                {{ "KEYWORD.patient" | translate | titlecase }} LookUp
                <mat-icon>contacts</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <mat-card>
        <div class="colour-stripe"></div>
        <div class="clearfix" *ngIf="isPromoterOrAdmin == true">
          <p class="help-label">
            To send an invitation on behalf of a merchant select a
            merchant below, otherwise, the invitation will be sent by the promoter.
          </p>
          <div class="row clearfix well selectMerchant">
            <mat-select class="margin-btm half-width" [(ngModel)]="merchantID" [userData]="'merchantID'"
                        name="MerchantName" placeholder="Please Select a Merchant">
              <mat-option [value]="null"> My Account</mat-option>
              <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m['ID']">
                <span class="list-label pull-right" *ngIf="m['Status'] && m['Status'] == 'Active'"
                      [style.background]="'#00d07a'">
                  {{ m['Status'] }}
                </span>

                <span class="list-label pull-right" *ngIf="m['Status'] && m['Status'] != 'Active'"
                      [style.background]="'#ff5722'">
                  {{ m['Status'] }}
                </span>
                {{ m['TradingAs'] || m['CalculatedName'] }}
              </mat-option>
            </mat-select>
            <button class="pull-right merchBtn" *ngIf="merchantID && merchantID != 'none'" (click)="viewMerchant()"
                    mat-raised-button color="primary">
              <mat-icon>person</mat-icon>
              <span class="mobHide">View Merchant</span>
            </button>
          </div>
        </div>
        <hr class="mt mb" *ngIf="isPromoterOrAdmin == true"/>
        <p class="help-label">
          Please start by entering the details of your {{ "KEYWORD.patient" | translate }}. You can use the '{{
          'KEYWORD.patient' | translate | titlecase
          }}
          Lookup' button to search your existing {{ "KEYWORD.patient" | translate }} records.
        </p>
        <div class="row clearfix">
          <form #createForm="ngForm" name="form">
            <!-- <div class="half-width"> -->
            <div class="column">
              <div class="icons-col">
                <mat-icon>person</mat-icon>
              </div>
              <div class="row-content-col">
                <div class="clearfix">
                  <div class="half-width">
                    <div class="col-left">
                      <!-- Gender Select -->
                      <mat-select class="full-width" [(ngModel)]="user.gender" [userData]="'patient.gender'"
                                  name="gender" placeholder="Gender" required>
                        <mat-option *ngFor="let gender of genders" [value]="gender.Code">
                          {{ gender.Label }}
                        </mat-option>
                      </mat-select>
                    </div>
                  </div>
                  <div class="half-width">
                    <div class="col-right">
                      <!-- Title Select -->
                      <mat-select class="full-width" [(ngModel)]="user.title" [userData]="'patient.title'"
                                  placeholder="Title" name="title">
                        <mat-option *ngFor="let title of titles" [value]="title.Label">
                          {{ title.Label }}
                        </mat-option>
                      </mat-select>
                    </div>
                  </div>
                </div>
                <div class="row clearfix flex">
                  <!-- First Name -->
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>First Name</mat-label>
                    <input class="mat-block" [(ngModel)]="user.firstname" [userData]="'patient.firstName'" matInput
                           placeholder="First Name " name="firstname" required/>
                  </mat-form-field>
                  <!-- Middle Name -->
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Middle Name</mat-label>
                    <input class="mat-block" [(ngModel)]="user.middlename" [userData]="'patient.middleName'" matInput
                           placeholder="Middle Name " name="middlename"/>
                  </mat-form-field>
                  <!-- Surname -->
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Surname</mat-label>
                    <input class="mat-block" [(ngModel)]="user.lastname" [userData]="'patient.lastName'" matInput
                           placeholder="Surname " name="lastname" required/>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <!-- </div> -->
            <hr class="mt mb"/>
            <!-- <div class="half-width"> -->
            <div class="column">
              <div class="row">
                <div class="icons-col">
                  <mat-icon>phone</mat-icon>
                </div>
                <div class="row-content-col">
                  <div class="row clearfix flex">
                    <!-- Mobile Number -->
                    <app-phone-input class="full-width" [userDataName]="'patient.mobile'" [label]="'Mobile'"
                                     [required]="true" (validate)="validateMobile($event)">
                    </app-phone-input>
                    <app-phone-input class="full-width" [userDataName]="'patient.homePhone'" [isLandLine]="true"
                                     [label]="'Home'" [required]="false" (validate)="validateHomePhone($event)">
                    </app-phone-input>
                    <app-phone-input class="full-width" [userDataName]="'patient.workPhone'" [isLandLine]="true"
                                     [label]="'Work'" [required]="false" (validate)="validateWorkPhone($event)">
                    </app-phone-input>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="icons-col">
                  <mat-icon>email</mat-icon>
                </div>
                <div class="row-content-col">
                  <!-- Email Address -->
                  <app-email-input class="full-width" [userDataName]="'patient.email'" [label]="'Email'"
                                   [required]="true" (validate)="validateEmail($event)">
                  </app-email-input>
                </div>
              </div>
            </div>
            <!-- </div> -->
          </form>
        </div>
      </mat-card>
      <div class="row clearfix">
        <div class="full-width text-center step-button-row">
          <button class="step-back" (click)="cancel()" mat-mini-fab color="primary">
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <button class="btn-large" [disabled]="
              !createForm.form.valid || !isEmailValid || !isMobileValid || !isHomePhoneValid || !isWorkPhoneValid
            " (click)="step1()" mat-raised-button type="submit" color="accent">
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <!--  / Step 1  -->
  </aw-wizard-step>
  <aw-wizard-step stepTitle="Finance Details" navigationSymbol="2">
    <!--  Step 2  -->
    <div class="container" id="step-2">
      <div class="row clearfix text-center">
        <!-- <h2 class="sr-title inline-block rm-mt">Finance Details</h2> -->
      </div>
      <div class="row clearfix">
        <!-- <div class="half-width flex"> -->
        <div class="full-width container">
          <mat-card class="card treatment-card card1 text-center">
            <div class="row clearfix tab-header closable">
              <h3 class="sm-h3 rm-mt">Finance Details</h3>
              <p class="hint">
                <label>Customise invitation</label>
                Select 'Choose Product' to set the details of the lending product you wish to send to your customer.
              </p>
              <div class="site_background"></div>
            </div>
            <div class="row clearfix">
              <mat-card class="important-card">
                <div class="colour-stripe"></div>
                <h2 class="sr-title sm-h2 rm-mb rm-mt">1. Enter the {{ "KEYWORD.treatment" | translate }} value</h2>
                <p>Enter the value for the {{"KEYWORD.treatment" | translate}}.</p>
                <hr class="mb"/>
                <div class="clearfix mt centered">
                  <div class="clearfix mt centered">
                    <app-amount-slider-input class="full-width" [minAmount]="minAmount" [maxAmount]="maxAmount"
                                             [amount]="amount" (selectAmount)="selectAmount($event)">
                    </app-amount-slider-input>
                  </div>
                </div>

                <div class="row clearfix mt mb">
                  <h3 class="sr-title sm-h3 rm-mt">2.Choose How to proceed</h3>
                  <mat-radio-group class="alternate-theme" [(ngModel)]="consumerCanChange"
                                   (change)="consumerCanChangeEvent()" name="scssFile">
                    <mat-radio-button [value]="false"> Choose Product </mat-radio-button>
                    <mat-radio-button [value]="true">
                      Leave it to the Consumer
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </mat-card>
              <div class="row clearfix mt mb" *ngIf="consumerCanChange == false && groupCode">
                <div class="half-width">
                  <div class="column">
                    <h3 class="sr-title sm-h3 rm-mb rm-mt">3. Select a Product (mandatory)</h3>
                    <p class="small">Select a suitable product from the list below.</p>
                    <div class="clearfix mt text-center centered">
                      <mat-select class="full-width clearfix" [(ngModel)]="productID"
                                  (selectionChange)="changeProductID()" placeholder="Select desired finance product"
                                  name="product" required>
                        <mat-option *ngFor="let product of products" [value]="product.ID">
                          {{ product['Label.Marketing'] }}
                        </mat-option>
                      </mat-select>
                    </div>
                  </div>
                </div>
                <div class="half-width">
                  <div class="column">
                    <h3 class="sr-title sm-h3 rm-mb rm-mt">4. Select reason for loan</h3>
                    <p class="small">Select the reason for the customer seeking finance.</p>
                    <div class="clearfix mt text-center centered">
                      <mat-select class="full-width clearfix" [(ngModel)]="serviceType" [userData]="'serviceType'"
                                  placeholder="Finance purpose" name="serviceType" required>
                        <mat-option *ngFor="let s of serviceTypes | objectPropertyValue: 'Label'" [value]="s">
                          {{ s }}
                        </mat-option>
                      </mat-select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row clearfix mt" *ngIf="consumerCanChange == false && productIDInput">
                <div class="column">
                  <hr class="mt mb"/>
                  <h3 class="sr-title sm-h3 rm-mb rm-mt">5. Select Duration (mandatory)</h3>
                  <p class="small">Select a duration and check replayments.</p>
                  <div class="row clearfix full-width">
                    <!--
                    [productDataName]="'productID'"
                    [durationDataName]="'duration'"
                    [frequencyDataName]="'frequency'"
                    [amountDataName]="'totalTreatmentValue'"
                    [patientContributionDataName]="'patientContribution'"
                   -->
                    <app-product-calculator class="" [isTop]="true" [moreInfo]="true" [dentalInfo]="false"
                                            [retailInfo]="true" [duration]="durationInput" [displayDeposit]="true"
                                            [ProductID]="productIDInput" [dynamicAmount]="false"
                                            [fixedAmount]="amountInput"
                                            [frequency]="frequencyInput"
                                            [patientContribution]="patientContributionInput" [isFrequency]="true"
                                            [isPatientContribution]="true" (estimation)="calculator($event)">
                    </app-product-calculator>
                  </div>
                </div>
              </div>

              <!-- <mat-card class="card treatment-card card1 text-center"> -->
              <div class="row clearfix mt">
                <div class="column">
                  <h3 class="sr-title sm-h3 rm-mb rm-mt">Attached documents</h3>
                  <app-file-uploader class="text-center" [isDragable]="true" [invitationID]="invitationID"
                                     (onCompleteAll)="onCompleteAllInvitationDocument($event)"></app-file-uploader>
                  <app-file-list class="" [documentTypes]="documentTypes" [files]="invitationDocuments"
                                 [invitationID]="invitationID" [canEdit]="true" [canAction]="false"
                                 (remove)="removeInvitationDocument($event)">
                  </app-file-list>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
      <div class="row clearfix">
        <div class="full-width step-button-row text-center">
          <button class="step-back" (click)="onStepChange()" mat-mini-fab color="primary" awPreviousStep>
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <button class="btn-large" *ngIf="consumerCanChange == true" [disabled]="!amount" (click)="step3()"
                  mat-raised-button type="submit" color="accent" awNextStep>
            Next
          </button>

          <button class="btn-large" *ngIf="consumerCanChange == false"
                  [disabled]="!amount || !duration || !productID || isStep2 == false || !serviceType" (click)="step3()"
                  mat-raised-button type="submit" color="accent" awNextStep>
            Next
          </button>
        </div>
      </div>
    </div>
    <!--  / Step 2  -->
  </aw-wizard-step>
  <aw-wizard-step stepTitle="Review and Confirm" navigationSymbol="3">
    <!--  Step 3  -->
    <div class="container" id="step-3">
      <div class="row clearfix">
        <!-- <div class="half-width"> -->
        <mat-card>
          <div class="colour-stripe"></div>
          <div class="row clearfix">
            <div class="column mb">
              <div class="icons-col">
                <mat-icon>email</mat-icon>
              </div>
              <div class="row-content-col">
                <div class="row clearfix">
                  <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb pull-left">Your personal message</h3>
                  <div class="defaultMsgArea pull-right">
                    <mat-icon class="pull-left">chat</mat-icon>
                    <mat-select class="full-width" class="defaultMsg btn-small pull-right" [(ngModel)]="messageOption"
                                [userData]="'message.messageOption'" (ngModelChange)="defaultMessage()"
                                name="messageOption"
                                placeholder="Add Default Message">
                      <mat-option *ngFor="let option of dentMsgOptions" [value]="option.key">
                        <p class="small rm-mt rm-mb">{{ option.value }}</p>
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Personal message to appear with invitation</mat-label>
                  <textarea class="dentMsg increase-input-field" [(ngModel)]="dentMsg" [userData]="'message.content'"
                            name="dentMsg" matInput
                            placeholder="Personal message to appear with invitation*"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row clearfix">
            <div class="column mb">
              <div class="icons-col">
                <mat-icon>face</mat-icon>
              </div>
              <div class="row-content-col">
                <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb">Senders name to appear with message</h3>
                <div class="clearfix">
                  <!-- Sender Select -->
                  <mat-select class="full-width" [(ngModel)]="selectedSender" [userData]="'message.sender'"
                              (ngModelChange)="senderChanged()" name="selectedSender" placeholder="Sender">
                    <mat-option *ngFor="let sender of senders" [value]="sender.ID">
                      {{ sender.CalculatedName }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
            </div>
          </div>
          <div class="row clearfix">
            <div class="column mb">
              <div class="icons-col">
                <mat-icon>build</mat-icon>
              </div>
              <div class="row-content-col">
                <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb">Settings</h3>
                <p>
                  The invitation will be sent to
                  <span class="invite-sent-to" [userData]="'patient.firstName'"></span>
                  <span class="invite-sent-to" [userData]="'patient.lastName'"></span>
                </p>
                <div class="alternate-theme">
                  <mat-checkbox [(ngModel)]="sendSMS" [userData]="'message.viaSMS'" name="sendSMS">Send SMS
                  </mat-checkbox>
                  <span class="send-sms-to" [userData]="'patient.mobile'" [pipe]="'phone'"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="row clearfix">
            <div class="column mb">
              <div class="icons-col">
                <mat-icon>alarm</mat-icon>
              </div>
              <div class="row-content-col">
                <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb">Schedule</h3>
                <p>
                  he invitation you created will be sent straight away to your {{ "KEYWORD.patient" | translate }} after
                  you click on 'send'. If you wish to change the scheduled time please modify below.
                </p>
                <div class="row clearfix">
                  <div class="half-width">
                    <div class="col-left">
                      <div class="input-control">
                        <app-date-input class="thin" [label]="'Email Send Date:'" [userDataName]="'message.sendDate'"
                                        [minDate]="'today'"></app-date-input>
                      </div>
                      <button class="btn-small inline" *ngIf="false" (click)="openEmailPreviewDialog()"
                              mat-raised-button>
                        <mat-icon>visibility_on</mat-icon>
                        Preview Email
                      </button>
                    </div>
                  </div>
                  <div class="half-width">
                    <div class="col-right animated fadeInDown" *ngIf="sendSMS">
                      <div class="input-control">
                        <app-date-input class="thin" [isTime]="true" [label]="'SMS Send Date:'"
                                        [userDataName]="'message.sendDateSms'" [minDate]="'today'"></app-date-input>
                      </div>
                      <button class="btn-small inline" *ngIf="false" (click)="openSmsPreviewDialog()" mat-raised-button>
                        <mat-icon>visibility_on</mat-icon>
                        Preview SMS
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
      <div class="row clearfix">
        <div class="full-width step-button-row text-center">
          <button class="step-back" (click)="onStepChange()" mat-mini-fab color="primary" awPreviousStep>
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <button class="step-send" class="btn-large" [disabled]="!isSelectedSender || selectedSender == null"
                  (click)="saveInvitation()" mat-raised-button color="accent">
            Send
            <mat-icon>send</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <!--  / Step 3 -->
  </aw-wizard-step>
  <!-- The completion step -->
  <aw-wizard-completion-step title="Send!" navigationSymbol="4">
    <!--  Step 4 - finished -->
    <div class="container" id="step-4">
      <mat-card class="card successful">
        <div class="row clearfix">
          <div class="full-width text-center">
            <h1 class="sm-h1 sr-title">Well Done!</h1>
            <h3 class="sm-h3 rm-mt">Your invitation is complete</h3>
            <div class="row clearfix">
              <div class="svg-box">
                <svg class="circular green-stroke">
                  <circle class="path" cx="75" cy="75" r="50" fill="none" stroke-width="5" stroke-miterlimit="10" />
                </svg>
                <svg class="checkmark green-stroke">
                  <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                    <path class="checkmark__check" fill="none" d="M616.306,283.025L634.087,300.805L673.361,261.53" />
                  </g>
                </svg>
              </div>
            </div>
            <button class="view-invite" class="btn-small" (click)="openInviteViewDetails()" mat-raised-button
                    color="primary">
              View Invitation
            </button>
            <button class="view-invite" class="btn-small" *ngIf="isPromoterOrAdmin == true" (click)="invitationPage()"
                    mat-raised-button color="accent">
              Invitation Link
            </button>
          </div>
        </div>
      </mat-card>
      <div class="row clearfix text-center">
        <button class="step-back" [routerLink]="['/merchant/', { outlets: { page: ['home'] } }]" mat-raised-button
                color="primary">
          <mat-icon>keyboard_arrow_left</mat-icon>
          Dashboard
        </button>
      </div>
    </div>
    <!--  / Step 4  -->
  </aw-wizard-completion-step>
</aw-wizard>
