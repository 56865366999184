import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CustomerService {
  constructor(private http: HttpClient) {}

  /**
   * Description
   * Get Customer details
   */
  getCustomerDetails(ID, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/customer/global/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/customer/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/customer/public/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getCustomerProspectDetails(ID, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/customer-prospect/global/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/customer-prospect/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/customer-prospect/public/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }



  getProspectDetails(ID, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/prospect/global/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/prospect/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/prospect/public/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  addCoordinate(ID, body) {
    return this.http
      .put(environment.nodeUrl + '/customer/coordinate/' + ID, body)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editCustomer(ID, payload): Observable<any> {
    return this.http
      .put(environment.nodeUrl + '/customer/' + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  /**
   * Description
   * Get Customers List
   */
  getCustomersList(payload, isAdminOrPromoter = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    if (isAdminOrPromoter == true) {
      return this.http
        .get(`${environment.nodeUrl}/customer/global`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(`${environment.nodeUrl}/customer`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  customerLookup(payload, isAdminOrPromoter = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    if (isAdminOrPromoter == true) {
      return this.http
        .get(`${environment.nodeUrl}/customer/lookup/global`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(`${environment.nodeUrl}/customer/lookup`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  /**
   * Description
   * Add Customer
   */
  addCustomer(customer): Observable<any> {
    return this.http.post(environment.nodeUrl + '/customer', customer);
  }

  /**
   * Description
   * Link Customer to invitation
   */
  linkToInv(customerID): Observable<any> {
    return this.http.post(`${environment.nodeUrl}/customer/invitation/${customerID}`, null);
  }

  uploadProfilePic(ID, payload = {}) {
    return this.http
      .put(`${environment.nodeUrl}/customer-prospect/profile-picture/` + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getProfilePic(ID, payload = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.http
      .get(`${environment.nodeUrl}/customer-prospect/profile-picture/` + ID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getProfilePicStreamLink(ID) {
    if (ID) {
      return `${environment.nodeUrl}/customer-prospect/profile-picture-stream/` + ID;
    }
    return null;
  }

  getCustomerContactLabel(ID, payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(`${environment.nodeUrl}/customer/contact-label/` + ID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }
}
