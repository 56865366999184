<mat-form-field class="main-date-container" [ngClass]="{ 'smaller-input': smallerInput }" appearance="outline">
  <mat-label>
    {{ dateLabel }}
  </mat-label>
  <input
    class="form-control"
    [(ngModel)]="selected"
    [showCustomRangeLabel]="showCustomRangeLabel"
    [alwaysShowCalendars]="false"
    [ranges]="ranges"
    [showClearButton]="true"
    [showCancel]="true"
    [linkedCalendars]="false"
    [locale]="{ applyLabel: 'Done' }"
    [keepCalendarOpeningWithRange]="keepCalendarOpeningWithRange"
    [showRangeLabelOnInput]="showRangeLabelOnInput"
    [autoApply]="false"
    [singleDatePicker]="singleDatePicker"
    [timePicker]="hasTimePicker"
    [locale]="{ format: 'DD/MM/YYYY' }"
    [disabled]="disabled"
    (datesUpdated)="datesUpdated($event)"
    matInput
    type="text"
    ngxDaterangepickerMd
    placeholder="  {{ dateLabel }}"
  />
</mat-form-field>
