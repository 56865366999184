<div class="card-header primary-gradient-img inModal">
  <div class="row titleArea clearfix full-width">
    <h2 class="summary-header rm-m white">{{"KEYWORD.patient" | translate | titlecase}} portal access</h2>
  </div>
  <button class="btn-close btn-clear mat-button" (click)="closeEvent()">
    <mat-icon>clear</mat-icon>
  </button>
</div>
<mat-dialog-content class="text-center" *ngIf="invitation">
  <p class="lead">Access {{ invitation.FirstName }} {{ invitation.LastName }}’s {{ target }} two ways</p>

  <div class="full-width row flex" *ngIf="code && numberOfUse > 0">
    <div class="full-width row mr mb proceed-container">
      <h3 class="sr-title rm-m sm-h3">From your browser on this PC</h3>
      <p>
        <img class="img-proceed character_secrect" />
      </p>

      <p class="small">Assist your {{ "KEYWORD.patient" | translate }} directly from your portal, Click on proceed to
        access to the {{ "KEYWORD.patient" | translate }} card</p>

      <div class="clearfix proceed-button text-center full-width">
        <button class="btn" (click)="proceed()" mat-raised-button color="accent">Proceed</button>
      </div>
    </div>

    <div class="full-width row ml mb code-container" *ngIf="code && numberOfUse > 0">
      <h3 class="sr-title rm-m sm-h3">From a tablet or PC in waiting area</h3>
      <p class="small">Copy and paste this Url in you PC or Tablet</p>

      <a class="link-class clearfix" href="{{ practiceLoginPage }}" target="_blank">
        {{ practiceLoginPage }}
      </a>

      <button class="btn-small mt clearfix" (click)="copy(practiceLoginPage)" mat-stroked-button color="primary">
        <mat-icon>content_copy</mat-icon> Copy Link
      </button>

      <p class="small">
        Quote this code to your patient. They will then be prompted to get an SMS that they will need to enter to access
        the link
      </p>

      <div class="row clearfix rel">
        <p class="lead bold">
          {{ code }}
        </p>

        <p class="small rm-m" *ngIf="numberOfUse > 0">
          This Code can be used <strong>{{ numberOfUse }}</strong> time(s)
        </p>
        <button class="btn mt" (click)="newCode()" mat-raised-button color="accent">Get New code</button>
      </div>
    </div>
  </div>
</mat-dialog-content>
