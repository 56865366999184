import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { BankAccountViewComponent } from '../bank-account-view/bank-account-view.component';
import { BankAccountService } from '../shared/bank-account.service';

@Component({
  selector: 'app-bank-account-list-user',
  templateUrl: './bank-account-list-user.component.html',
  styleUrls: ['./bank-account-list-user.component.css'],
  animations: [
    trigger('fade', [transition(':enter', [style({ opacity: 0 }), animate('.6s ease')])]),
    trigger('stagger', [transition(':enter', [query(':enter', stagger('.3s', [animateChild()]), { optional: true })])]),
  ],
})
export class BankAccountListUserComponent implements OnInit {
  @Input()
  userID;

  @Input()
  bankAccountID;



  @Input()
  embedded = true;

  @Input()
  isActive;

  @Input()
  isVerified;

  @Input()
  userName;

  @Input()
  actionIcon = 'keyboard_arrow_right';

  @Input()
  actionLabel = 'Select';

  @Input()
  isAction;

  @Input()
  isActionButton = false;

  @Input()
  canEdit = false;

  @Output()
  getAction = new EventEmitter();

  @Output()
  getBankAccount = new EventEmitter();

  @Output()
  getBankAccounts = new EventEmitter();

  isModal = false;

  @Output()
  closeModal = new EventEmitter();

  bankAccount;
  bankAccounts = [];

  isPromoterOrAdmin = false;

  @Input()
  description;

  @Input()
  title;

  isLoaded = false;

  constructor(
    private router: Router,
    private bankAccountService: BankAccountService,
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.userID) {
        this.isModal = true;
        this.userID = data.userID;
      }

      if (data.userName) {
        this.userName = data.userName;
      }

      if (data.isActive) {
        this.isActive = data.isActive;
      }
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['id']) {
        this.userID = params['id'];
      }

      if (params['userName']) {
        this.userID = params['userName'];
      }

      this.setup();
    });
  }

  setup() {
    if (this.userID) {
      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        const payload: any = {
          userID: this.userID,
        };

        if (this.isActive != null) {
          payload.active = this.isActive;
        }

        if (this.isVerified != null) {
          payload.verified = this.isVerified;
        }

        if (this.isPromoterOrAdmin == true) {
          this.bankAccountService.getBankAccountList(payload, this.isPromoterOrAdmin).subscribe((res1) => {
            if (res1) {
              this.bankAccounts = res1;
              this.getBankAccounts.emit(this.bankAccounts);

              if (this.bankAccountID) {
                for (let i = 0; i < this.bankAccounts.length; i++) {
                  if (this.bankAccountID == this.bankAccounts[i]['ID']) {
                    this.bankAccount = this.bankAccounts[i];

                    this.getBankAccount.emit(this.bankAccount);

                    if (this.isAction == true) {
                      this.getAction.emit(this.bankAccount);
                    }

                    break;
                  }
                }
              } else if (this.bankAccounts && this.bankAccounts.length == 1) {
                this.bankAccount = this.bankAccounts[0];

                this.getBankAccount.emit(this.bankAccount);

                if (this.isAction == true) {
                  this.getAction.emit(this.bankAccount);
                }
              }
            }

            this.isLoaded = true;
          });
        } else {
          this.bankAccountService.getBankAccountListMini(payload, this.isPromoterOrAdmin).subscribe((res1) => {
            if (res1) {
              this.bankAccounts = res1;
              this.getBankAccounts.emit(this.bankAccounts);

              if (this.bankAccountID) {
                for (let i = 0; i < this.bankAccounts.length; i++) {
                  if (this.bankAccountID == this.bankAccounts[i]['ID']) {
                    this.bankAccount = this.bankAccounts[i];

                    this.getBankAccount.emit(this.bankAccount);

                    if (this.isAction == true) {
                      this.getAction.emit(this.bankAccount);
                    }

                    break;
                  }
                }
              } else if (this.bankAccounts && this.bankAccounts.length == 1) {
                this.bankAccount = this.bankAccounts[0];

                this.getBankAccount.emit(this.bankAccount);

                if (this.isAction == true) {
                  this.getAction.emit(this.bankAccount);
                }
              }
            }

            this.isLoaded = true;
          });
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userID && changes.userID.previousValue != changes.userID.currentValue) {
      this.setup();
    }
  }

  enable(ID) {
    const confirmDialog = new ConfirmDialog(
      'check_circle',
      'Enable Bank Account',
      '<p>Are you sure?</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            active: true,
          };
          this.bankAccountService.editBankAccount(ID, payload, this.isPromoterOrAdmin).subscribe((res) => {
            if (res) {
              let index = this.bankAccounts.findIndex((r) => {
                if (r && r.ID && r.ID == ID) {
                  return true;
                }

                return false;
              });

              if (index != -1) {
                this.bankAccounts[index] = res;
              }

              NotifyAppComponent.displayToast('success', 'Success!', 'Beneficiary is enabled.');
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  disable(ID) {
    const confirmDialog = new ConfirmDialog('cancel', 'Disable Bank Account', '<p>Are you sure?</p>', 'Cancel', 'Okay');

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            active: false,
          };
          this.bankAccountService.editBankAccount(ID, payload, this.isPromoterOrAdmin).subscribe((res) => {
            if (res) {
              let index = this.bankAccounts.findIndex((r) => {
                if (r && r.ID && r.ID == ID) {
                  return true;
                }

                return false;
              });

              if (index != -1) {
                this.bankAccounts[index] = res;
              }

              NotifyAppComponent.displayToast('success', 'Success!', 'Beneficiary is enabled.');
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  openQuickViewDialog(ID) {
    const ref = RootAppComponent.dialog.open(BankAccountViewComponent, {
      data: ID,
      width: '600px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  selectBank(b) {
    if (b) {
      this.bankAccount = b;
      this.bankAccountID = b.ID;
      this.actionEvent(b);
    }
  }

  actionEvent(b) {
    if (b) {
      this.getAction.emit(b);
    }
  }

  actionEventEmpty(e) {
    this.closeEvent();
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['bank-account-create', this.userID, this.userName],
        },
      },
    ]);
  }

  closeEvent() {
    this.closeModal.emit(true);
  }

  getCardType(d) {
    if (d === 'MC') {
      return 'MC';
    } else if (d === 'AX') {
      return 'AX';
    } else if (d === 'VS') {
      return 'VS';
    } else if (d === 'VIS') {
      return 'VS';
    } else if (d === 'VISA') {
      return 'VS';
    }

    return 'CC';
  }
}
