<div class="rel text-center wizardModal intro">
  <mat-card class="card">
    <div class="card-header primary-gradient-img clearfix inModal">
      <div class="row clearfix">
        <!-- merchant calculated name -->
        <div class="row text-left clearfix">
          <!-- Modal dismiss button -->
          <button class="btn-close btn-clear mat-button white" (click)="doNotShow()">
            <mat-icon>clear</mat-icon>
          </button>
          <h2 class="summary-header rm-m">
            Welcome to the Settlement Refund Interface!
            <!-- <span class="subLabel">If something wasn't right with your settlement, you can request a refund.</span> -->
          </h2>
        </div>
      </div>
    </div>

    <mat-dialog-content>
      <aw-wizard class="settlementCreate" navBarLayout="large-filled-symbols">
        <aw-wizard-step [navigationSymbol]="{ symbol: 1 }" (stepEnter)="setStep(1)">
          <img
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/smile_right/refund-intro-slide-1.jpg"
            mat-card-image/>
          <div class="row clearfix text-center">
            <p class="lead rm-mt">
              If you need to refund an unused portion of {{ "KEYWORD.treatment" | translate }} to your patient, you can
              request a refund to be made.
            </p>
            <p class="">
              Before you go down this path it may be good to consider leaving the unused amount as a future credit at
              the {{"KEYWORD.practice" | translate}}. Your {{ "KEYWORD.patient" | translate }} can always come back for
              further {{ "KEYWORD.treatment" | translate }} later and claim this amount.
            </p>
            <p class="add-mb">
              Using the following screen, you can request a refund directly to the
              {{ "KEYWORD.patient" | translate }}'s {{ "BRANDING.Brand Name" | translate | titlecase }} account. Click
              'Learn more'
              below to see how...
            </p>
            <!-- <div class="control-buttons row clearfix mb">
              <button class="pull-left" mat-mini-fab color="primary" disabled>
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button>
              <div class="pull-right">
                <h3 class="sr-title readMore">Learn more...</h3>
                <button mat-mini-fab color="primary" class="pull-right" awNextStep>
                  <mat-icon>chevron_right</mat-icon>
                </button>
              </div>
            </div> -->
          </div>
        </aw-wizard-step>

        <!-- <img src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/smile_right/newSettlementRequest.png" class="thr-qtr-width" /> -->

        <aw-wizard-step [navigationSymbol]="{ symbol: 2 }" (stepEnter)="setStep(2)">
          <img class="border-bottom"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/smile_right/refund-intro-slide-2.jpg"
               mat-card-image/>

          <div class="row clearfix text-center">
            <p class="lead rm-mt"><strong>1.</strong> Enter the refund amount.</p>
            <p class="add-mb">
              Start by entering the amount you wish to refund to your {{ "KEYWORD.patient" | translate }}'s
              {{ "BRANDING.Brand Name" | translate | titlecase }} account.
            </p>
          </div>
        </aw-wizard-step>

        <aw-wizard-step [navigationSymbol]="{ symbol: 3 }" (stepEnter)="setStep(3)">
          <img class="border-bottom"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/smile_right/refund-intro-slide-3.jpg"
               mat-card-image/>
          <div class="row clearfix text-center">
            <p class="lead rm-mt"><strong>2.</strong> Next step is {{ "KEYWORD.patient" | translate }} acknowledgment.
            </p>
            <p class="">
              You can either choose to have an SMS sent to your patient, or print off an acknowledgement form for them
              to sign.
            </p>
            <p class="add-mb">
              If you choose the SMS option you will need the {{ "KEYWORD.patient" | translate }} to give you the code as
              their authority, then enter
              it into the refund form, alternatively you can upload the signed acknowledgement form at this point.
            </p>
          </div>
        </aw-wizard-step>
      </aw-wizard>
    </mat-dialog-content>

    <div class="row clearfix">
      <hr/>

      <ng-container *ngIf="step == 2 || step == 3">
        <button class="pull-left" (click)="prevStep()" mat-raised-button>
          <mat-icon>keyboard_arrow_left</mat-icon> Back
        </button>
      </ng-container>

      <mat-checkbox class="mt" [(ngModel)]="showOnSettlementCreate">Dont Show Again</mat-checkbox>

      <ng-container *ngIf="step == 1 || step == 2">
        <div class="pull-right">
          <button class="pull-right" (click)="nextStep()" mat-raised-button color="primary">
            Next <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </ng-container>

      <ng-container *ngIf="step == 3">
        <button class="btn-small pull-right" (click)="doNotShow()" mat-raised-button color="accent">
          Got It! &nbsp;
          <mat-icon class="fas fa-hand-point-right"></mat-icon>
        </button>
      </ng-container>
    </div>
  </mat-card>
</div>
