<div class="full-width clearfix" *ngIf="title || description">
  <h1 class="sm-h1 sr-title" *ngIf="title">Staff appointment availability</h1>
  <p *ngIf="description">Manage your staffs availability for appointment along with their workings hours</p>
</div>

<div class="full-width clearfix">
  <mat-form-field class="full-width clearfix" *ngIf="contacts && contacts.length > 0" appearance="outline">
    <mat-label>Filter by staff name, email , mobile or description</mat-label>

    <input
      class="full-width"
      [(ngModel)]="filterName"
      name="linkLabel"
      matInput
      placeholder="Filter by staff name, email , mobile or description"
    />
  </mat-form-field>
</div>
<div class="full-width clearfix">
  <!-- <div class="thrd-width merchant-hours">
    <div class="ml mr">
      
      <section *ngIf="merchant && merchantID" class="profile-header primary-gradient-img rel">
        <div class="clearfix row full-width text-center mb">
          <app-merchant-view-picture [hideZoomButton]="false" [merchantID]="merchantID" class="merchant-picture">
          </app-merchant-view-picture>
        </div>
        <div class="profile-header-details">
          <h2 *ngIf="merchant['TradingAs']">
            {{ merchant["TradingAs"] }}
          </h2>
        </div>
      </section>

      <app-merchant-trading-hours-view *ngIf="merchantID" [displayBusinessHours]="false" [isModal]="false"
        [merchantID]="merchantID"></app-merchant-trading-hours-view>
    </div>
  </div> -->

  <div
    class="thrd-width"
    *ngFor="
      let d of contacts
        | FilterArrayMultipleContainValue
          : ['CalculatedName', 'phones.Number', 'TradingAs', 'mobiles.Number', 'emails.Email', 'Description']
          : filterName
    "
  >
    <app-dentist-working-hours-overview
      class="ml mr"
      [displayHighlight]="true"
      [isModal]="false"
      [dentistID]="d['ID']"
      [dentist]="d"
      [workingHours]="d.workingHours"
      [isEdit]="true"
      [displayBusinessHours]="false"
    >
    </app-dentist-working-hours-overview>
  </div>
</div>
