import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { InvitationTemplateService } from '../shared/invitation-template.service';

@Component({
  selector: 'app-treatment-save-modal',
  templateUrl: './treatment-save-modal.component.html',
  styleUrls: ['./treatment-save-modal.component.css'],
})
export class TreatmentSaveModalComponent implements OnInit {
  @Input()
  invitationTemplateID;

  @Output()
  closeModal = new EventEmitter();

  @Output()
  result = new EventEmitter();

  label;
  description;

  selectAll = true;
  selectDocuments = true;
  selectImages = true;
  selectTreatmentPlan = true;
  selectTags = true;
  selectTreatmentValue = true;
  selectMessage = true;
  selectHealthHistory = true;
  selectGoogleReview = true;
  selectDiscount = true;

  isPromoterOrAdmin = false;

  constructor(
    private invitationTemplateService: InvitationTemplateService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.invitationTemplateID) {
        this.invitationTemplateID = data.invitationTemplateID;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      if (this.invitationTemplateID) {
        this.invitationTemplateService
          .getInvitationTemplateDetails(this.invitationTemplateID, {}, this.isPromoterOrAdmin)
          .subscribe((res) => {
            if (res) {
              this.label = res['label'];
              this.description = res['description'];
            }
          });
      }
    });
  }

  close() {
    this.closeModal.emit(true);
  }

  selectAllEvent() {
    debugger;
    if (this.selectAll == true) {
      this.selectDocuments = true;
      this.selectImages = true;
      this.selectTreatmentPlan = true;
      this.selectTags = true;
      this.selectTreatmentValue = true;
      this.selectMessage = true;
      this.selectHealthHistory = true;
      this.selectGoogleReview = true;
      this.selectDiscount = true;
    } else {
      this.selectDocuments = false;
      this.selectImages = false;
      this.selectTreatmentPlan = false;
      this.selectTags = false;
      this.selectTreatmentValue = false;
      this.selectMessage = false;
      this.selectHealthHistory = false;
      this.selectGoogleReview = false;
      this.selectDiscount = false;
    }
  }
  sendResult() {
    if (this.label) {
      const result = {
        label: this.label,
        description: this.description,

        selectAll: this.selectAll,
        selectDocuments: this.selectDocuments,
        selectImages: this.selectImages,
        selectTreatmentPlan: this.selectTreatmentPlan,
        selectTags: this.selectTags,
        selectTreatmentValue: this.selectTreatmentValue,
        selectMessage: this.selectMessage,
        selectHealthHistory: this.selectHealthHistory,
        selectGoogleReview: this.selectGoogleReview,
        selectDiscount: this.selectDiscount,
      };

      if (this.invitationTemplateID) {
        result['id'] = this.invitationTemplateID;
        result['invitationTemplateID'] = this.invitationTemplateID;
      }

      this.result.emit(result);
    }
  }
}
