import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArchwizardModule } from 'angular-archwizard';
import { MomentModule } from 'ngx-moment';
import { NgPipesModule } from 'ngx-pipes';
// import { ChartsModule } from 'ng2-charts';
import { SharedModule } from '../../shared/shared.module';
import { OperatorModule } from '../operator/operator.module';
import { ProductModule } from '../product/product.module';
import { MarketingCampaignCreateEditOverviewComponent } from './marketing-campaign-create-edit-overview/marketing-campaign-create-edit-overview.component';
import { MarketingCampaignCreateEditComponent } from './marketing-campaign-create-edit/marketing-campaign-create-edit.component';
import { MarketingCampaignReviewComponent } from './marketing-campaign-review/marketing-campaign-review.component';
import { MarketingCampaignViewComponent } from './marketing-campaign-view/marketing-campaign-view.component';
import { MarketingEmailViewModalComponent } from './marketing-email-view-modal/marketing-email-view-modal.component';
import { MarketingListGlobalComponent } from './marketing-list-global/marketing-list-global.component';
import { MarketingListOverViewComponent } from './marketing-list-overview/marketing-list-overview.component';
import { MarketingListComponent } from './marketing-list/marketing-list.component';
import { MarketingNewCampaignModalComponent } from './marketing-new-campaign-modal/marketing-new-campaign-modal.component';
import { MarketingOverViewComponent } from './marketing-over-view/marketing-over-view.component';
import { MarketingResendModalComponent } from './marketing-resend-modal/marketing-resend-modal.component';
import { MarketingTemplateGalleryComponent } from './marketing-template-gallery/marketing-template-gallery.component';
import { MarketingRoutingModule } from './shared/marketing-routing.module';
import { MarketingService } from './shared/marketing.service';

import { MatSortModule } from '@angular/material/sort';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxGaugeModule } from 'ngx-gauge';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { HelperModule } from '../../core/helper/helper.module';
import { CustomerProspectModule } from '../customer-prospect/customerProspect.module';
import { PerformanceModule } from '../performance/performance.module';
import { SocialMediaModule } from '../social-media/social-media.module';
import { TagsModule } from '../tags/tags.module';
import { TreatmentModule } from '../treatment/treatment.module';
import { MarketingCampaginEditModalComponent } from './marketing-campagin-edit-modal/marketing-campagin-edit-modal.component';
import { MarketingCampaginLookupComponent } from './marketing-campagin-lookup/marketing-campagin-lookup.component';
import { MarketingCampaignMessageListOverviewComponent } from './marketing-campaign-message-list-overview/marketing-campaign-message-list-overview.component';
import { MarketingCampaignMessageListComponent } from './marketing-campaign-message-list/marketing-campaign-message-list.component';
import { MarketingCampaignSocialMediaCreateComponent } from './marketing-campaign-social-media-create/marketing-campaign-social-media-create.component';
import { MarketingCampaignTipsOffersLookupComponent } from './marketing-campaign-tips-offers-lookup/marketing-campaign-tips-offers-lookup.component';
import { MarketingGoogleReviewInvitationCreate } from './marketing-google-review-invitation-create/marketing-google-review-invitation-create.component';
import { MarketingRedirectionComponent } from './marketing-redirection/marketing-redirection.component';
import { MarketingSocialMediaRedirectionComponent } from './marketing-social-media-redirection/marketing-social-media-redirection.component';
import { NonReachedTargetListComponent } from './non-reached-targets-list/non-reached-targets-list.component';
import { MarketingFilterModule } from '../marketing-filter/marketing-filter.module';
import { MarketingAudienceFilterComponent } from './marketing-audience-filter/marketing-audience-filter.component';

@NgModule({
  imports: [
    NgPipesModule,
    CommonModule,
    MomentModule,
    CdkTableModule,
    ClipboardModule,
    FormsModule,
    ArchwizardModule,
    ReactiveFormsModule,
    MarketingRoutingModule,
    ProductModule,
    SharedModule,
    HelperModule,
    OperatorModule,
    MatSortModule,
    PinchZoomModule,
    NgxGaugeModule,
    PerformanceModule,
    TreatmentModule,
    CustomerProspectModule,
    TagsModule,
    SocialMediaModule,
    MarketingFilterModule
  ],
  exports: [
    MarketingTemplateGalleryComponent,
    MarketingOverViewComponent,
    MarketingListOverViewComponent,
    MarketingCampaignCreateEditComponent,
    MarketingCampaignViewComponent,
    MarketingCampaignReviewComponent,
    MarketingListGlobalComponent,
    MarketingListComponent,
    MarketingCampaignCreateEditOverviewComponent,
    MarketingNewCampaignModalComponent,
    NonReachedTargetListComponent,
    MarketingCampaignMessageListComponent,
    MarketingCampaignMessageListOverviewComponent,
    MarketingSocialMediaRedirectionComponent,
    MarketingRedirectionComponent,
    MarketingCampaginLookupComponent,
    MarketingCampaignSocialMediaCreateComponent,
    MarketingCampaginEditModalComponent,
    MarketingGoogleReviewInvitationCreate,
    MarketingCampaignTipsOffersLookupComponent,
    MarketingAudienceFilterComponent
  ],
  declarations: [
    MarketingTemplateGalleryComponent,
    MarketingOverViewComponent,
    MarketingListOverViewComponent,
    MarketingCampaignCreateEditComponent,
    MarketingCampaignViewComponent,
    MarketingCampaignReviewComponent,
    MarketingListGlobalComponent,
    MarketingListComponent,
    MarketingResendModalComponent,
    MarketingEmailViewModalComponent,
    MarketingCampaignCreateEditOverviewComponent,
    MarketingNewCampaignModalComponent,
    NonReachedTargetListComponent,
    MarketingCampaignMessageListComponent,
    MarketingCampaignMessageListOverviewComponent,
    MarketingSocialMediaRedirectionComponent,
    MarketingRedirectionComponent,
    MarketingCampaginLookupComponent,
    MarketingCampaignSocialMediaCreateComponent,
    MarketingCampaginEditModalComponent,
    MarketingGoogleReviewInvitationCreate,
    MarketingCampaignTipsOffersLookupComponent,
    MarketingAudienceFilterComponent
  ],
  providers: [MarketingService],
})
export class MarketingModule {}
