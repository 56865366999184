<div class="full-width" *ngIf="appointment">
  <div class="full-width flex appointment-details-line" *ngIf="appointmentUtil.getTitle(appointment)">
    <p class="half-width">
      <mat-icon class="fas fa-star appointment-details-line-icon" color="accent"></mat-icon>
      <strong>Status</strong>
    </p>

    <h4 class="sr-title sm-h4 accent background full-width">
      <span class="chip appointment-chip" [style.background]="appointmentUtil.getStatusColor(appointment)"
      >{{ appointmentUtil.getTitle(appointment) }}
        <span
          *ngIf="
            (appointment['Status.Code'] === 'BOOKED' && appointmentUtil.isDatePass(appointment) == true) ||
            (appointment['Status.Code'] === 'WAITINGCONFIRMATION' && appointmentUtil.isDatePass(appointment) == true)
          "
        >
          (Passed)
        </span>
      </span>
    </h4>
  </div>

  <div
    class="full-width flex appointment-details-line"
    *ngIf="appointment['Source.Label'] && sessionType != 'consumer' && sessionType != 'guest'"
  >
    <p class="half-width">
      <mat-icon class="fas fa-scroll appointment-details-line-icon" color="accent"></mat-icon>
      <strong>Source of booking</strong>
    </p>
    <div class="full-width">
      <p class="full-width">
        {{ appointment['Source.Label'] }}
      </p>
    </div>
  </div>

  <div
    class="full-width flex appointment-details-line"
    *ngIf="appointment['Date'] && appointment['Date'] != '0000-00-00'"
  >
    <p class="half-width">
      <mat-icon class="fas fa-calendar appointment-details-line-icon" color="accent"></mat-icon>
      <strong>Booking time</strong>
    </p>
    <div class="full-width">
      <p class="full-width">
        {{ appointment['Date'] + ' ' + appointment['Time'] | customDateTime: 'hh:mm:a' }}
        <span *ngIf="appointment['Date_End'] && appointment['Date_End'] != '0000-00-00'">
          - {{ appointment['Date_End'] + ' ' + appointment['Time_End'] | customDateTime: 'hh:mm:a' }} </span
        >,
        {{ appointment['Date'] + ' ' + appointment['Time'] | customDateTime: 'dddd, MMMM, DD, YYYY' }}
      </p>
    </div>
  </div>

  <div class="full-width flex appointment-details-line" *ngIf="appointment['Type.Label']">
    <p class="half-width">
      <mat-icon class="fas fa-heartbeat appointment-details-line-icon" color="accent"></mat-icon>
      <strong> Reason for visiting</strong>
    </p>
    <div class="full-width">
      <p class="full-width">
        <span matTooltip=" {{ appointment['Type.Description'] | cleanString }}"> {{ appointment['Type.Label'] }} </span>
        <span *ngIf="appointment['SubType.Label']" matTooltip=" {{ appointment['SubType.Description'] | cleanString }}"
        >- {{ appointment['SubType.Label'] }}</span
        >
      </p>
    </div>
  </div>

  <div class="full-width flex appointment-details-line" *ngIf="appointment['MerchantContact_CalculatedName']">
    <p class="half-width">
      <mat-icon class="fas fa-user appointment-details-line-icon" color="accent"></mat-icon>
      <strong> {{ "KEYWORD.practitioner" | translate | titlecase }} </strong>
    </p>
    <p class="full-width">
      <span>{{ appointment['MerchantContact_CalculatedName'] }} </span>

      <button (click)="viewDentist()" mat-icon-button color="accent">
        <mat-icon>visibility</mat-icon>
      </button>
    </p>
  </div>

  <div class="full-width flex appointment-details-line" *ngIf="appointment['LastModified_Human']">
    <p class="half-width">
      <mat-icon class="fas fa-clock appointment-details-line-icon" color="accent"></mat-icon>
      <strong> Updated At </strong>
    </p>
    <p class="full-width">
      {{ appointment['LastModified_Human'] | customDate: 'hh:mm a' }},
      {{ appointment['LastModified_Human'] | customDate }}

      <span class="list-label ml" [style.background]="util.daysPastColor(appointment['LastModified_Human'])">
        {{ appointment.LastModified_Human | TimeLabelPast }} ago</span
      >
    </p>
  </div>
  <div class="full-width flex appointment-details-line" *ngIf="message">
    <p class="half-width">
      <mat-icon class="fas fa-envelope appointment-details-line-icon" color="accent"></mat-icon>
      <strong>
        {{ title }}
      </strong>
    </p>
    <p class="full-width">
      {{ message }}
    </p>
  </div>
</div>
