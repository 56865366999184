export class ModuleUtilClass {
  modules = [
    {
      icon: 'mail_outline',
      code: 'isModuleAdvancedTreatmentActive',
      title: 'Advanced Treatment ',
      description: 'Create an advanced treatment',
    },

    {
      icon: 'group',
      code: 'isModuleStaffActive',
      title: 'Staff Module',
      description: 'Staff list and map',
    },

    {
      icon: 'local_hospital',
      code: 'isModuleEmergercyActive',
      title: 'Emergercy Invitation Module',
      description: 'Create fast invitations for emergencies',
    },

    {
      icon: 'money',
      code: 'isModuleBeneficiaryActive',
      title: 'Complience Module',
      description: 'Complience list ,Complience map.',
    },

    {
      icon: 'business',
      code: 'isModulePracticeActive',
      title: 'Practice Module',
      description: 'Merchant list ,Merchant map.',
    },

    {
      icon: 'today',
      code: 'isModuleAppointmentActive',
      title: 'Appointments Module',
      description: 'Create and manage patient appointments',
    },

    {
      icon: 'ballot',
      code: 'isModuleChangeLogActive',
      title: 'Change Log',
      description: 'Display Change Log list',
    },

    {
      icon: 'assignment_turned_in',
      code: 'isModuleContractActive',
      title: 'Contract Module ',
      description: 'Get Contract list',
    },

    {
      icon: 'chat',
      code: 'isModuleCustomMessagesActive',
      title: 'Custom Messages',
      description: 'Allow merchants to create custom invitation messages',
    },

    {
      icon: 'compare',
      code: 'isModuleDarkModeActive',
      title: 'Dark Mode ',
      description: 'Display Dark mode toggle',
    },

    {
      icon: 'fa-money-bill-wave ',
      code: 'isModuleDiscountsActive',
      title: 'Discount Module',
      description: 'Create and manage discounts',
    },

    {
      icon: 'fa-flask',
      code: 'isModuleExperimentalFeaturesActive',
      title: 'Experimental Features',
      description: 'Enable experimental features still in development but not yet release ready',
    },

    {
      icon: 'fa-network-wired',
      code: 'isModuleExternalLoginActive',
      title: 'External authentication',
      description: 'Allow use to authenticate via google and facebook accounts',
    },

    {
      icon: 'local_atm',
      code: 'isModuleFacilityActive',
      title: 'Facility Lending Module',
      description: 'Create and send invitations for pre-approved lending facilities',
    },

    {
      icon: 'monetization_on',
      code: 'isModuleFeeFundingActive',
      title: 'Fee Funding Module',
      description: 'Dashboard modals for Fee Funding Context',
    },

    {
      icon: 'how_to_reg',
      code: 'isModuleCustomerActive',
      title: 'Financed Patients',
      description: 'Financed Patients Management',
    },

    {
      icon: 'how_to_reg',
      code: 'isModulePatientHistoryActive',
      title: 'Health History',
      description: 'Patient health and health history forms',
    },

    {
      icon: 'folder_shared',
      code: 'isModuleTreatmentGroupActive',
      title: 'Treatment Group',
      description: 'Manage treatment groups : Categories , Types and brands .',
    },

    {
      icon: 'assignment',
      code: 'isModuleInformedConsentActive',
      title: 'Informed Consent Module',
      description: 'Require patients to sign an informed consent before proceeding with treatment',
    },

    {
      icon: 'new_releases',
      code: 'isModuleMarketingActive',
      title: 'Marketing Module',
      description: 'Create and send campaigns',
    },

    {
      icon: 'map',
      code: 'isModuleMapViewActive',
      title: 'Map Views (Multiple user maps)',
      description: 'Display maps containing multiple users',
    },

    {
      icon: 'card_membership',
      code: 'isModuleMembershipActive',
      title: 'Membership Module',
      description: 'Create in-house member packages',
    },

    {
      icon: 'settings',
      code: 'isModuleMyPracticeActive',
      title: 'My Practice Module',
      description: 'View and manage the status of your practice',
    },

    {
      icon: 'fa-sticky-custom-note',
      code: 'isModuleNoteFeaturesActive',
      title: 'Note Module',
      description: 'View and manage the custom-note of your patient',
    },

    {
      icon: 'dashboard',
      code: 'isModuleOverviewActive',
      title: 'Overviews Module',
      description: 'Display a data overview page for each module',
    },

    {
      icon: 'portrait',
      code: 'isModulePatientsActive',
      title: 'Patients Module',
      description: 'View and modify patient records',
    },

    {
      icon: 'fa-tachometer-alt',
      code: 'isModulePerformanceActive',
      title: 'Performance Module',
      description: 'Display reports on practice performance and invitation feedback',
    },

    {
      icon: 'fa-file-invoice-dollar',
      code: 'isModuleQuotebuilderActive',
      title: 'Quotation Module',
      description: "Also known as 'Treatment Plan Builder' - create tailored treatment plan invitations",
    },

    {
      icon: 'perm_phone_msg',
      code: 'isModuleReferralActive',
      title: 'Referrals Module',
      description: 'Create in-house member packages',
    },

    {
      icon: 'payment',
      code: 'isModuleSettlementsActive',
      title: 'Settlements Module',
      description: 'Request and manage patient settlements',
    },

    {
      icon: 'payment',
      code: 'isModuleSettlementsAdvancedActive',
      title: 'Settlements Advanced Module',
      description: 'Allow HICAPS and Third Party Payments',
    },

    {
      icon: 'lock',
      code: 'isModulePrivateDocumentActive',
      title: 'Private Document Module',
      description: 'Give the user possibility to define if a document contains private data',
    },

    {
      icon: 'offline_bolt',
      code: 'isModuleTipsActive',
      title: 'Tips Module',
      description: 'Display helpful information on the dashboard',
    },

    {
      icon: 'fa-house-user',
      code: 'isModuleConsumerPortalActive',
      title: 'Consumer Portal Module',
      description: 'Hide Consumer side menu (if an account does not exist),show/display signup button',
    },

    {
      icon: 'fa-house-user',
      code: 'isModulePatientDocumentActive',
      title: 'Patient Documents Module',
      description:
        'Add patient documents on treatment view and patient card, Patient can view docuements on Customer Portal',
    },

    {
      icon: 'mail_outline',
      code: 'isModuleTreatmentPlanActive',
      title: 'Treatment Plan Module',
      description: 'Create and send patient treatment invitations',
    },

    {
      icon: 'stars',
      code: 'isModuleTutorialsActive',
      title: 'Tutorials Module',
      description: 'In-page popup walkthrough guides',
    },

    {
      icon: 'fa-id-card',
      code: 'isModulePatienCardtSecurityActive',
      title: 'Patient Card security Module',
      description:
        'By Enabling this the Consumer will have to use 2 steps authentication to proceed the application and view health history',
    },

    {
      icon: 'fa-file-signature',
      code: 'isModuleTreatmentTemplateActive',
      title: 'Treatment Template',
      description:
        'Saving Treatment Template, use defined treatment to make it easier and faster to create invitation/',
    },

    {
      icon: 'fa-clipboard-list',
      code: 'isModuleMerchantInformedConsentActive',
      title: 'Merchant Informed Consent',
      description: 'Manage the merchant defined informed consent',
    },

    {
      icon: 'library_books',
      code: 'isModuleWikiActive',
      title: 'Wiki Module',
      description: 'Access to Wiki pages',
    },

    {
      icon: 'verified_user',
      code: 'isModuleDemoFeaturesActive',
      title: 'Demo Module',
      description: 'Displays certain UI elements to assist in demonstrations of the platform',
    },

    {
      icon: 'fas fa-pills',
      code: 'isModuleMedicationActive',
      title: 'Medication Module',
      description: 'Management of Medications  ,  add new record and list ',
    },

    {
      icon: 'fas fa-bruch',
      code: 'isModuleStockArtActive',
      title: 'Stock Art Module',
      description: 'Adding Stock artwork to the Procedure Gallery while creating an invitation ',
    },

    {
      icon: 'fas fa-hands-helping',
      code: 'isModulePatientPortalAccessActive',
      title: 'Patient Portal access',
      description:
        'Access to patient card as merchant or provide an access code to  the patient a code to view the invitation via a practice login',
    },

    {
      icon: 'fas fa-user-lock',
      code: 'isModuleProductPermissionActive',
      title: 'Procced application permission',
      description: 'Access to proceed an application with financial option.',
    },

    {
      icon: 'fas fa-pager',
      code: 'isModulePracticeLandingPageActive',
      title: 'Practice Page access',
      description: 'Access to the Practice Page',
    },

    {
      icon: 'fas fa-rocket',
      code: 'isModuleBoostReachActive',
      title: 'Marketing Boost Reach',
      description: 'Used for boosting prospect reach from 3rd party database',
    },

    {
      icon: 'fas fa-question-circle',
      code: 'isModuleMarketingSocialMediaActive',
      title: 'Marketing Social Media',
      description: 'Used for boosting prospect reach from 3rd party database',
    },

    {
      icon: 'fas fa-search-location',
      code: 'isModuleGoogleReviewActive',
      title: 'Google reviews',
      description: 'Used to send Google reviews invitation and managing your Google Business account',
    },

    {
      icon: 'fas fa-question-circle',
      code: 'isModuleAiScansActive',
      title: 'AI Scans',
      description: '',
    },

    {
      icon: 'fas fa-user-plus',
      code: 'isModuleNewPatientInvitationActive',
      title: 'New patients',
      description: 'New patients invitation module',
    },
  ];

  constructor() {}

  ngOnInit() {}
}
