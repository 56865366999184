import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { DocumentCreateComponent } from '../../document/document-create/document-create.component';
import { MerchantProspectService } from '../shared/merchant-prospect.service';

import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { SideMenuService } from '../../../shared/services/side-menu.service';

@Component({
  selector: 'app-merchant-prospect-list',
  templateUrl: './merchant-prospect-list.component.html',
  styleUrls: ['./merchant-prospect-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MerchantProspectListComponent implements OnInit {
  dateFrom;
  dateTo;

  status;
  isPromoterOrAdmin = false;

  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  utils = new UtilsClass();

  displayedColumns = [
    'TradingAS',
    'Status',
    'Phone',
    'CreatedDate',
    'Address',
    'State',
    'Postcode',
    // "TradingAS",
    'Actions',
  ];
  filters = [];

  searchVals = new FormControl();

  searchValList = ['Trading as', 'Full Name', 'Email', 'Phone', 'Mobile'];

  expandedRow: number;
  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  filteredSize = null;

  destroyEvent = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private activeRoute: ActivatedRoute,
    private merchantProspectService: MerchantProspectService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['status']) {
        this.status = params.status;
      }

      if (this.status) {
        this.displayedColumns = ['TradingAS', 'Phone', 'CreatedDate', 'Address', 'State', 'Postcode', 'Actions'];
      }

      SideMenuService.goBack.subscribe((res) => {
        if (res == true) {
          this.hideViewDetails();
        }
      });

      if (this.isPromoterOrAdmin == true) {
        this.listDB = new LoadRecords(this.merchantProspectService, this.destroyEvent, this.status);
        this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
      } else {
        this.router.navigate(['/merchant']);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.listDB = new LoadRecords(this.merchantProspectService, this.destroyEvent, this.status);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  setFilter(event, field) {
    let filter;

    if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  convertPhone(val) {
    if (val) {
      if (val.startsWith('13')) {
        return val;
      } else {
        return '0061' + val.substring(1);
      }
    }
  }

  contact(id) {
    const data = {
      targetType: 'merchant',
      targetID: id,
      asGuest: false,
      asProfile: true,
      asPractice: true,
    };
    AuthenticationService.contactInputEvent.emit(data);
  }

  openQuickViewDialog(ID) {
    this.router.navigate([
      '/merchant',
      {
        outlets: { page: ['merchant-prospect-profile', ID] },
      },
    ]);
  }

  hideViewDetails() {}

  uploadDoc(id) {
    const ref = RootAppComponent.dialog.open(DocumentCreateComponent, {
      data: {
        cardID: id,
      },
      width: '650px',
    });

    ref.componentInstance.create.subscribe((res) => {
      if (res) {
        ref.close();
      }
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  serviceRef;
  isDestoyed = false;

  constructor(private merchantProspectService: MerchantProspectService, private destroyEvent, private status) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    const payload = {
      section: 0,
      statusCode: this.status,
      isConverted: true,
      fields:
        'ID,TradingAs,Status.Label,DateTimeCreated,phones.Number,emails.Email,' +
        'addresses.Unit,addresses.Street Nr,addresses.Street Name,addresses.Street Type,addresses.State,addresses.Postcode',
    };

    this.serviceRef = this.merchantProspectService.getList(payload, true).subscribe((res) => {
      this.count = res.length;
      this.items = res;

      this.dataChange.next(this.items.slice());
      this.firstLoad = true;
      const innerFunction = (section) => {
        const length = this.items.length;
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.merchantProspectService.getList(payload, true).subscribe((res) => {
          if (res.length > 0) {
            this.count = res.count;
            this.items = this.items.concat(res);

            this.dataChange.next(this.items.slice());
            if (this.isDestoyed != true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };

      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
          let f = '';

          f = item[this.field] || '';

          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'CreatedDate':
          [propertyA, propertyB] = [
            moment(a['DateTimeCreated']).toDate().getTime(),
            moment(b['DateTimeCreated']).toDate().getTime(),
          ];
          break;
        case 'TradingAS':
          [propertyA, propertyB] = [a['TradingAs'], b['TradingAs']];
          break;

        case 'MerchantName':
          [propertyA, propertyB] = [a['CalculatedName'], b['CalculatedName']];
          break;
        case 'MerchantMobile':
          [propertyA, propertyB] = [a['mobiles.Number'], b['mobiles.Number']];
          break;
        case 'MerchantEmail':
          [propertyA, propertyB] = [a['emails.Email'], b['emails.Email']];
          break;
        case 'Phone':
          [propertyA, propertyB] = [a['phones.Number'], b['phones.Number']];
          break;
        case 'Address':
          [propertyA, propertyB] = [a['addresses.Street Name'], b['addresses.Street Name']];
          break;

        case 'State':
          [propertyA, propertyB] = [a['addresses.State'], b['addresses.State']];
          break;

        case 'Postcode':
          [propertyA, propertyB] = [a['addresses.Postcode'], b['addresses.Postcode']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
