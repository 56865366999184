<mat-card
  class="rel create-container supplier-card brandView clearfix mb"
  id="subscription-plan"
  @ngIfAnimation
  [ngClass]="isContentPage == true ? 'create-container-content' : 'create-container'"
>
  <div
    class="card-header primary-gradient-img clearfix inModal rel"
    @ngIfAnimation
    [attr.style]="
      'background:linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
    "
    [attr.style]="
      'background:-moz-linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
    "
    [attr.style]="
      'background:-webkit-linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
    "
  >
    <div class="row clearfix content">
      <app-ep-document-view-image
        class="square-img-feature pull-left module-sub-feature"
        [hideZoomButton]="false"
        [defaultPicture]="
          'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/bell_icon.png)'
        "
        [link]="postPictureThumbnail"
      >
      </app-ep-document-view-image>
      <div class="row titleArea clearfix">
        <h2 class="message-title summary-header rm-m">
          <span *ngIf="!subFeatureLabelID && mainFeature && mainFeature.MainLabel"
          >Create Sub Feature For <strong> {{ mainFeature.MainLabel }} </strong></span
          >
          <span *ngIf="subFeatureLabelID && mainFeature && mainFeature.MainLabel"
          >Edit Sub Feature <strong> {{ mainFeature.MainLabel }} </strong></span
          >
          <span class="subLabel">Insert a label and a description for your Sub Feature Label </span>
        </h2>
      </div>
    </div>
  </div>
  <div class="full-width" @ngIfAnimation>
    <aw-wizard
      class="template-subscription-plan-create"
      #wizard
      [disableNavigationBar]="true"
      navBarLayout="large-filled-symbols"
    >
      <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Define Sub Feature" awOptionalStep>
        <div class="full-width clearfix mt">
          <form #resetform="ngForm">
            <h3 class="sr-title mt">Sub feature details</h3>
            <div class="row clearfix flex mt mr">
              <mat-form-field class="two-thrd-width mr" appearance="outline">
                <mat-label>Label</mat-label>
                <input
                  [(ngModel)]="label"
                  (change)="changeLabel()"
                  name="label"
                  matInput
                  placeholder="Label"
                  required
                />
              </mat-form-field>
              <div class="full-width flex">
                <div class="full-width flex">
                  <mat-checkbox class="full-width mt mr" [(ngModel)]="useDefaultColor" name="useDefaultColor">
                    Feature Color?
                  </mat-checkbox>
                  <input
                    class="color-input-sub full-width mr"
                    *ngIf="useDefaultColor != true"
                    [(colorPicker)]="subColor"
                    [style.background]="subColor"
                    [cpOutputFormat]="'hex'"
                    (colorPickerClose)="onColorPickerClose2($event)"
                    name="contactColor"
                  />
                </div>
              </div>
            </div>
            <div class="full-width clearfix mb">
              <p class="rm-mb">Header (To be displayed before clicking on Read more)*</p>
              <app-text-editor-input
                class="html-input-text-editor-info"
                [content]="description"
                [isMultiView]="false"
                [view]="'editor'"
                [title]="''"
                [isPreview]="false"
                [isAction]="false"
                (getContent)="getContent($event)"
                name="editor"
              >
              </app-text-editor-input>
              <div class="full-width">
                <span class="pull-right" [ngClass]="{ 'red-error': description?.length > maxLength }"
                >Text length: <strong> {{ description?.length || 0 }} </strong>/ {{ maxLength || 0 }}</span
                >
              </div>
            </div>

            <div class="full-width">
              <mat-form-field
                class="full-width"
                [ngClass]="isUnique != true ? 'error-input-manual' : ''"
                appearance="outline"
              >
                <mat-label>Static Code</mat-label>
                <input
                  [(ngModel)]="code"
                  (change)="isUniqueEvent()"
                  name="code"
                  matInput
                  placeholder="Static  Code"
                  required
                />
              </mat-form-field>
              <mat-error class="full-width text-right" *ngIf="isUnique != true">
                <mat-icon class="fas fa-exclamation-circle smr"></mat-icon>Static Code must be unique
              </mat-error>
            </div>

            <h3 class="sr-title mt" [attr.style]="'color:' + color + ' !important' | safeStyle">Accessibility</h3>

            <div class="full-width flex mb">
              <mat-checkbox class="slider-width pull-left slider-input-select ml mr" [(ngModel)]="isNew" name="isNew">
                Mark it as NEW ?
              </mat-checkbox>
              <mat-checkbox
                class="slider-width pull-left slider-input-select mr"
                [(ngModel)]="isMerchant"
                name="isMerchant"
              >
                is  {{ 'KEYWORD.practice' | translate }} Feature?
              </mat-checkbox>
              <mat-checkbox
                class="slider-width pull-left slider-input-select"
                [(ngModel)]="isConsumer"
                name="isConsumer"
              >
                is Consumer Feature?
              </mat-checkbox>
            </div>
            <div class="full-width flex mb">
              <mat-checkbox
                class="slider-width pull-left slider-input-select ml mr"
                [(ngModel)]="isSubPlanFeature"
                name="isSubPlanFeature"
              >
                is Sub-Plan Feature?
              </mat-checkbox>

              <mat-checkbox
                class="slider-width pull-left slider-input-select mr"
                [(ngModel)]="isPublic"
                name="isPublic"
              >
                is Public?
              </mat-checkbox>
              <mat-checkbox
                class="slider-width pull-left slider-input-select mr"
                [(ngModel)]="isActive"
                name="isActive"
              >
                Is Active?
              </mat-checkbox>
            </div>

            <h3 class="sr-title" [attr.style]="'color:' + color + ' !important' | safeStyle">Side Menu</h3>
            <div class="full-width flex">
              <mat-checkbox class="slider-width slider-input-select ml mr" [(ngModel)]="isSideMenu" name="isSideMenu">
                Add to side menu?
              </mat-checkbox>
              <mat-checkbox
                class="slider-width slider-input-select mr"
                *ngIf="isSideMenu == true"
                [(ngModel)]="isMainSideMenu"
                name="isMainSideMenu"
              >
                Stand alone item?
              </mat-checkbox>
              <mat-form-field class="full-width mr" *ngIf="isSideMenu == true" appearance="outline">
                <mat-label>Side Menu Label</mat-label>
                <input [(ngModel)]="sideMenuLabel" name="sideMenuLabel" matInput placeholder="Side Menu Label"/>
              </mat-form-field>
              <mat-form-field
                class="full-width ml"
                *ngIf="isSideMenu == true && isMainSideMenu == true"
                appearance="outline"
              >
                <mat-label>Side Menu Icon</mat-label>
                <input [(ngModel)]="icon" name="icon" matInput placeholder="Side Menu Icon" required/>
                <span matSuffix>
                  <mat-icon class="{{ icon }}" [attr.style]="'color:#808080 !important' | safeStyle"> </mat-icon>
                </span>
              </mat-form-field>
            </div>
            <p class="small" *ngIf="isSideMenu == true && isMainSideMenu == true">
              * You can get the icons from the <a href="https://fontawesome.com/v5/search" target="_blank">Link</a>,
              e.g. <strong>fas fa-user</strong>
            </p>
            <div class="full-width row mr">
              <div class="clearfix full-width mb">
                <h3 class="sr-title mt" [attr.style]="'color:' + color + ' !important' | safeStyle">
                  Cover Image
                </h3>
                <p>
                  Recommended dimension is <strong>1000x250 px</strong> (only images are allowed). Max image size is
                  {{ maxFileSize }} MB
                </p>
                <div class="full-width mb blob-for-main-feature-container">
                  <app-blob-view
                    class="row clearfix flex flexCol blob-for-social-media full-width full-height"
                    *ngIf="(!postPictureLink && postPicture && loadingPost != true) || loadingPost == true"
                    [label]="'Cover Image'"
                    [applyDownload]="applyDownload"
                    [loading]="loadingPost"
                    [isDownload]="false"
                    [isProceed]="false"
                    [isPrint]="false"
                    [canProceed]="false"
                    [content]="postPicture"
                    [extension]="'png'"
                    [label]=""
                  >
                  </app-blob-view>
                  <div
                    class="view-container full-height full-width"
                    *ngIf="postPictureLink && !postPicture && loadingPost != true"
                  >
                    <div class="row clearfix">
                      <pinch-zoom
                        class="full-height clearfix full-width pinchZoom"
                        [limit-zoom]="25"
                        [wheel]="true"
                        [wheelZoomFactor]="0.5"
                        [double-tap]="true"
                        [properties]="pinchZoomProperties"
                      >
                        <img [src]="postPictureLink" />
                      </pinch-zoom>
                    </div>
                  </div>
                  <div
                    class="view-container full-height full-width"
                    *ngIf="
                      (!postPicture || postPicture == null) &&
                      (!postPictureLink || postPictureLink == null) &&
                      loadingPost != true
                    "
                  >
                    <div class="row clearfix">
                      <pinch-zoom
                        class="full-height clearfix full-width pinchZoom"
                        [limit-zoom]="25"
                        [wheel]="true"
                        [wheelZoomFactor]="0.5"
                        [double-tap]="true"
                        [properties]="pinchZoomProperties"
                      >
                        <img
                          [src]="
                            'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/cover/pic8.png'
                          "
                        />
                      </pinch-zoom>
                    </div>
                  </div>
                </div>
                <div class="clearfix full-width">
                  <app-file-uploader
                    class="text-center clearfix full-width"
                    [allowedExtensions]="allowExtensions"
                    [aspectRatioType]="'qtr'"
                    [maxFileSize]="maxFileSize"
                    [isFixedAspectRatio]="false"
                    [isImageCropping]="true"
                    [canSkip]="false"
                    [limit]="1"
                    [uploadDirectly]="true"
                    [isDragable]="true"
                    [title]="'CLICK HERE to upload  Cover (Max size: ' + maxFileSize + 'MB)'"
                    (onCompleteAll)="onComplete($event)"
                  >
                  </app-file-uploader>
                </div>
              </div>
            </div>

            <hr class="mb"/>
            <div class="drop-button text-center full-width mt">
              <button
                class="btn-large pull-left"
                *ngIf="mainFeatureLabelID"
                [attr.style]="'background:' + color + ' !important' | safeStyle"
                (click)="viewMainFeature(mainFeatureLabelID)"
                mat-raised-button
                color="primary"
              >
                <mat-icon>chevron_left</mat-icon> Main Feature
              </button>

              <button
                class="btn-large"
                [disabled]="!resetform.form.valid || !description || isUnique != true"
                (click)="save(false)"
                mat-raised-button
                color="primary"
              >
                <mat-icon class="smr">save</mat-icon>Save Draft
              </button>

              <button
                class="btn-large pull-right"
                [disabled]="!resetform.form.valid || !description || isUnique != true"
                (click)="proceed(true)"
                mat-raised-button
                color="accent"
              >
                Next
                <mat-icon>chevron_right</mat-icon>
              </button>
            </div>
          </form>
        </div>
      </aw-wizard-step>
      <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="HTML content" awOptionalStep>
        <div class="container mt" id="step-4">
          <div class="full-width" *ngIf="loaded == true">
            <h3 class="sr-title mt">Marketing Html Content</h3>

            <app-code-html-css-input
              class="full-width mb"
              [label]="label"
              [isEditStyle]="isEditStyle"
              [isComponent]="false"
              [descriptionHTML]="descriptionHTML"
              [descriptionRaw]="descriptionHTML"
              [descriptionDocument]="descriptionHTML"
              [descriptionEditor]="descriptionHTML"
              [displayUploader]="false"
              [content]="_content"
              [style]="_style"
              [structure]="structure"
              [descriptionRaw]="descriptionCSSHTML"
              (getIsEditStyle)="getIsEditStyleEvent($event)"
              (getStructure)="getStructureEvent($event)"
              (getStyle)="getStyleEvent($event)"
              (getContent)="getContentEvent($event)"
            >
            </app-code-html-css-input>
          </div>
          <div class="drop-button text-center full-width mt text-center">
            <button
              class="btn-large pull-left"
              [attr.style]="'background:' + color + ' !important' | safeStyle"
              (click)="stepBack(false)"
              mat-raised-button
              color="primary"
            >
              <mat-icon>chevron_left</mat-icon> Previous
            </button>

            <button class="btn-large" (click)="save(false)" mat-raised-button color="primary">
              <mat-icon class="smr">save</mat-icon>Save Draft
            </button>

            <button class="btn-large pull-right" (click)="save(true)" mat-raised-button color="accent">
              Save
              <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </div>
      </aw-wizard-step>
      <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Done!" awOptionalStep>
        <div class="container mt" id="step-2" *ngIf="createdRecord && createdRecord.ID" @ngIfAnimation>
          <div class="animate clearfix">
            <div class="row clearfix">
              <mat-card class="card successful">
                <div class="row clearfix">
                  <div class="full-width text-center">
                    <h1 class="sm-h1 sr-title" [attr.style]="'color:' + color + ' !important' | safeStyle">
                      Well Done!
                    </h1>
                    <h3 class="sm-h3 rm-m" [attr.style]="'color:' + colorSecond + ' !important' | safeStyle">
                      Sub feature has been Modified
                    </h3>
                    <div class="row clearfix">
                      <div class="svg-box">
                        <svg class="circular green-stroke">
                          <circle
                            class="path"
                            cx="75"
                            cy="75"
                            r="50"
                            fill="none"
                            stroke-width="5"
                            stroke-miterlimit="10"
                          />
                        </svg>
                        <svg class="checkmark green-stroke">
                          <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                            <path
                              class="checkmark__check"
                              fill="none"
                              d="M616.306,283.025L634.087,300.805L673.361,261.53"
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row clearfix text-center">
                  <button
                    class="smr"
                    *ngIf="createdRecord && createdRecord.ID"
                    [attr.style]="'background:' + color + ' !important' | safeStyle"
                    (click)="ViewHtmlContent(createdRecord.ID, createdRecord.LastModified)"
                    mat-raised-button
                    color="accent"
                  >
                    <mat-icon class="fab fa-html5 smr"></mat-icon>
                    Preview
                  </button>

                  <button
                    class="smr"
                    *ngIf="createdRecord && createdRecord.Feature_MainLabel_key"
                    [attr.style]="'background:' + color + ' !important' | safeStyle"
                    (click)="viewMainFeature(createdRecord.Feature_MainLabel_key)"
                    mat-raised-button
                    color="accent"
                  >
                    <mat-icon class="smr">visibility</mat-icon>
                    <span>Main Feature</span>
                  </button>
                  <button
                    class="smr"
                    *ngIf="createdRecord && createdRecord.Feature_MainLabel_key"
                    [attr.style]="'background:' + color + ' !important' | safeStyle"
                    (click)="createNew(createdRecord.Feature_MainLabel_key)"
                    mat-raised-button
                    color="accent"
                  >
                    <mat-icon class="smr">add</mat-icon>
                    <span>Create new</span>
                  </button>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </aw-wizard-step>
    </aw-wizard>
  </div>
</mat-card>
