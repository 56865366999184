import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { EventLogListComponent } from '../../action-log/event-log-list/event-log-list.component';
import { ActionLogService } from '../../action-log/shared/action-log.service';
import { DentistViewComponent } from '../../dentist/dentist-view/dentist-view.component';
import { ExistingPatientSummaryComponent } from '../../existing-patient/existing-patient-summary/existing-patient-summary.component';
import { ExistingPatientViewComponent } from '../../existing-patient/existing-patient-view/existing-patient-view.component';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { NotesModal, NotesModalConfig } from '../../notes/modals/notes-modal/notes.modal';
import { ProductViewModalComponent } from '../../product/product-view-modal/product-view-modal.component';
import { ProductService } from '../../product/shared/product.service';
import { InvitationResendComponent } from '../invitation-resend/invitation-resend.component';
import { InvitationService } from '../shared/services/invitation.service';

@Component({
  selector: 'app-invitation-view-modal',
  templateUrl: './invitation-view-modal.component.html',
  styleUrls: ['./invitation-view-modal.component.css'],
  animations: [
    trigger('onChangeAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(0)', offset: 0, height: 0 }),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(0)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class InvitationViewModalComponent implements OnInit {
  @Input()
  invitationID;

  @Input()
  membership = false;

  @Input()
  showInvDetailInList = false;

  subjectArray = [];

  @Output()
  close: EventEmitter<any> = new EventEmitter();

  @Output()
  openDetails: EventEmitter<any> = new EventEmitter();

  invitation = {};
  util = new UtilsClass();
  cardID;

  lastActivity;

  createdDate;
  dates = [];
  role = null;

  colorInv = 'primary';
  modeInv = 'buffer';
  progressInv = 'Invitation Created';
  StepInv = 'Invitation Card';
  valueInv = 0;
  bufferValueInv = 0;

  color = 'primary';
  mode = 'buffer';
  progress = 'Invitation Created';
  Step = 'Invitation Card';
  value = 0;
  bufferValue = 0;

  profileLink;

  Settings = Settings;

  invitationEvents = [];
  emailEvent = {
    deliveredElapsedSince: null,
    sentTime: null,
    viewedTime: null,
  };
  respondEvent = {
    respondInitDate: null,
    respondInitElapsedSince: null,
    respondInitStatus: null,
    respondFinDate: null,
    respondFinStatus: null,
    respondFinElapsedSince: null,
  };

  amountDrown;

  context = Settings.global['context'];

  isModuleNoteFeaturesActive = Settings.global['isModuleNoteFeaturesActive'];
  sessionType = 'guest';

  dateNow;
  isPromoterOrAdmin = false;
  ApplicationProgressLabel = 'Application';

  constructor(
    private router: Router,
    private productService: ProductService,
    private UtilsService: UtilsService,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private invitationService: InvitationService,
    private actionLogService: ActionLogService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.invitationID) {
        this.invitationID = data.invitationID;
      }

      if (data.membership != null) {
        this.membership = data.membership;
      }

      if (data.showInvDetailInList) {
        this.showInvDetailInList = data.showInvDetailInList;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      this.UtilsService.getCurrentAccess().subscribe((access) => {
        if (access) {
          this.isModuleNoteFeaturesActive = access['isModuleNoteFeaturesActive'];
        }

        this.UtilsService.getServerDate().subscribe((res) => {
          this.dateNow = res;

          this.authenticationService.isPromoterOrAdmin().subscribe((res2) => {
            this.isPromoterOrAdmin = res2;
            this.authenticationService.getCurrentUser().subscribe((res) => {
              if (res && res.success && res.data['merchantKey'] && res.data['merchantKey']) {
                this.cardID = res.data['merchantKey'];
              }
            });

            this.authenticationService.getCurrentUser().subscribe((res) => {
              if (res && res.success && res.data['Role'] && res.data['Role']) {
                this.role = res.data['Role'];
              }
            });

            this.invitationService
              .getInvitationDetails(this.invitationID, {}, this.sessionType, this.membership)
              .subscribe((res) => {
                this.invitation = res;

                this.setupInvitation();
                // if (this.invitation['contactID']) {
                //   this.contractService.getContractDetails(this.invitation['contactID']).subscribe(res => {
                //     this.amountDrown = res['Settlements.Drawn'];
                //   })
                // }

                this.getEventLogs(this.invitation['ID']);

                this.getPicture();
              });
          });
        });
      });
    });
  }

  viewPatient() {
    // let ref = this.dialog.open(CustomerProspectViewComponent, {
    //   data: this.invitation['Prospect_key'],
    //   width: "600px",
    //   panelClass: "noCard"
    // });
    // ref.componentInstance.closeModal.subscribe(res => {
    //   if (res == true) {
    //     ref.close();
    //   }else if (res == 'createdNote') {
    //     ref.close();
    //     this.close.emit(true);
    //   }
    // })
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['customer-profile', this.invitation['Prospect_key'], 'patient'],
        },
      },
    ]);

    this.close.emit(true);
  }

  openCampaign(id) {
    this.router.navigate(['/merchant', { outlets: { page: ['marketing-campaign-view', id] } }]);
    this.close.emit(true);
  }

  getPicture() {
    this.profileLink = null;
    if (this.invitation['Prospect_key']) {
      this.profileLink = this.invitationService.getProfilePicStreamLink(this.invitation['Prospect_key']);
    }
  }

  cloneInvitation() {
    const confirm = new ConfirmDialog(
      'fas fa-info',
      '',
      'Are you sure you want to clone this invitation ?',
      'No',
      'Yes'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
    });
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        this.invitationService.cloneInvitation(this.invitationID, this.isPromoterOrAdmin, false).subscribe((res) => {
          if (res) {
            ref.close();

            if (this.context == 'retail') {
              this.router.navigate(['/merchant', { outlets: { page: ['lending-invitation-create', res] } }]);
            } else {
              this.router.navigate(['/merchant', { outlets: { page: ['invitation-create', res] } }]);
            }

            this.close.emit(true);
          } else {
            ref.close();
          }
        });
      }
    });
  }

  contractLog() {
    const ref = this.dialog.open(EventLogListComponent, {
      data: {
        customerID: this.invitation['contactID'],
        type: 'contract',
        targetID: this.invitation['contactID'],
      },
      width: '900px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  patientLog() {
    const ref = this.dialog.open(EventLogListComponent, {
      data: {
        customerID: this.invitation['Prospect_key'],
        target: 'customer',
        targetID: this.invitation['Prospect_key'],
      },
      width: '900px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  invitationLog() {
    const ref = this.dialog.open(EventLogListComponent, {
      data: {
        invitationID: this.invitation['ID'],
        type: 'invitation',
        itemID: this.invitation['ID'],
      },
      width: '900px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  setupInvitation() {
    this.getProgress();

    this.createdDate = moment(this.invitation['DateTimeCreated']).toDate();
    if (this.invitation) {
      if (this.invitation['Date.Activated'] && this.invitation['Date.Activated'] != '0000-00-00') {
        this.dates.push(moment(this.invitation['Date.Activated']).toDate());
      }

      if (this.invitation['Date.Approved'] && this.invitation['Date.Approved'] != '0000-00-00') {
        this.dates.push(moment(this.invitation['Date.Approved']).toDate());
      }

      if (this.invitation['Date.Commenced'] && this.invitation['Date.Commenced'] != '0000-00-00') {
        this.dates.push(moment(this.invitation['Date.Commenced']).toDate());
      }

      if (this.invitation['Date.Completed'] && this.invitation['Date.Completed'] != '0000-00-00') {
        this.dates.push(moment(this.invitation['Date.Completed']).toDate());
      }

      if (this.invitation['Date.Declined'] && this.invitation['Date.Declined'] != '0000-00-00') {
        this.dates.push(moment(this.invitation['Date.Declined']).toDate());
      }
      if (this.invitation['Date.Discontinued'] && this.invitation['Date.Discontinued'] != '0000-00-00') {
        this.dates.push(moment(this.invitation['Date.Discontinued']).toDate());
      }

      if (this.invitation['Date.FinalApproval'] && this.invitation['Date.FinalApproval'] != '0000-00-00') {
        this.dates.push(moment(this.invitation['Date.FinalApproval']).toDate());
      }
      if (this.invitation['Date.Responded'] && this.invitation['Date.Responded'] != '0000-00-00') {
        this.dates.push(moment(this.invitation['Date.Responded']).toDate());
      }

      if (this.invitation['DateToBeSent'] && this.invitation['DateToBeSent'] != '0000-00-00') {
        this.dates.push(moment(this.invitation['DateToBeSent']).toDate());
      }

      this.dates.sort(function (a, b) {
        return b.getTime() - a.getTime();
      });

      if (this.dates[0]) {
        this.lastActivity = moment(this.dates[0]).format('YYYY-MM-DD HH:mm:ss');
      }
    }
  }

  deleteInvitation() {
    const confirmDialog = new ConfirmDialog(
      'delete',
      'Archive Invitation?',
      '<p>You are about to archive this invitation.</p> <p>This invitation will no longer be active. Further reminders and notifications by email and SMS to the patient with not be generated.</p> <p><strong>Are you sure?</strong></p>',
      'No',
      'Yes, archive'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        this.invitationService
          .deleteInvitation(this.invitationID, this.isPromoterOrAdmin, this.membership)
          .subscribe((res) => {
            if (res == true) {
              this.invitation['Voided'] = '1';
              NotifyAppComponent.displayToast(
                'success',
                'Successfull operation',
                'The file has been successfully removed'
              );
              ref.close();
            }
          });

        ref.close();
      }
    });
  }

  redirect(invitation) {
    if (invitation && invitation.Instant_Finance_Offer_Key) {
      const url = `${environment.nodeUrl}/d/` + invitation.Instant_Finance_Offer_Key;
      window.open(url, '_blank', 'noopener');
    } else if (invitation && invitation.WakandaID) {
      const url =
        Settings.global['consumerFrontendLink'] + '/consumer/(page:invitation-card/' + invitation.WakandaID + ')';
      window.open(url, '_blank', 'noopener');
    }
  }

  openProductViewDialog() {
    const ref = this.dialog.open(ProductViewModalComponent, {
      data: this.invitation['Product_Key'],
      width: '750px',
      panelClass: 'noCard',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  contact() {
    const data = {
      targetType: 'invitation',
      targetID: this.invitation['ID'],
      asGuest: false,
      asProfile: true,
      asPractice: true,
      subjectArray: this.subjectArray,
    };
    AuthenticationService.contactInputEvent.emit(data);
  }

  resendInvitation() {
    const ref = this.dialog.open(InvitationResendComponent, {
      data: {
        invitationID: this.invitation['ID'],
        membership: this.membership,
      },

      width: '600px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });

    ref.componentInstance.getInvitation.subscribe((res) => {
      if (res) {
        this.invitation = res;
        this.setupInvitation();
      }
    });
  }

  openCustomerDialog() {
    const ref = this.dialog.open(ExistingPatientSummaryComponent, {
      data: this.invitation['ExistingPatientID'],
      width: '750px',
      panelClass: 'noCard',
    });
  }

  fullDetails() {
    // this.router.navigate([
    //   "/merchant",
    //   { outlets: { 'page': ["invitation-view", this.invitation['ID']] } }
    // ]);
    if (this.showInvDetailInList == false) {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['customer-profile', this.invitation['Prospect_key'], 'treatment', this.invitation['ID']],
          },
        },
      ]);
    }

    this.close.emit(true);
  }

  createNote(patientID: string, invitationID: string) {
    this.dialog.open<NotesModal, NotesModalConfig>(NotesModal, {
      data: {
        patientID,
        parentRoute: 'merchant',
        noteListMode: 'create',
        relationshipLink: {
          label: 'invitation',
          route: `customer-profile/${patientID}/treatment/${invitationID}`,
          itemID: patientID,
        },
      },
    });
  }

  viewNotes(patientID: string) {
    this.dialog.open<NotesModal, NotesModalConfig>(NotesModal, {
      data: {
        parentRoute: 'merchant',
        patientID,
      },
    });
  }

  viewCustomer() {
    const ref = this.dialog.open(ExistingPatientViewComponent, {
      data: this.invitation['ExistingPatientID'],
      width: '600px',
      panelClass: 'noCard',
    });
  }

  viewMerchant() {
    const ref = this.dialog.open(MerchantViewComponent, {
      data: this.invitation['Merchant_Key'],
      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewDentist() {
    const ref = this.dialog.open(DentistViewComponent, {
      data: this.invitation['DentistContact_key'],
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  contractDetails() {
    this.router.navigate(['/merchant', { outlets: { page: ['contract-view', this.invitation['contactID']] } }]);

    this.close.emit(true);
  }

  getEventLogs(ID) {
    const payload = {
      invitationID: ID,
    };
    this.actionLogService.getInvitationEvents(payload, this.sessionType).subscribe((res) => {
      this.invitationEvents = res;
      res.map((item) => {
        if (item['Event.Code'] == 'INV-CrCmm.Msg.Email.Sent') {
          this.emailEvent['sentTime'] = item['DateTime_ISO'];
          this.emailEvent['deliveredElapsedSince'] = item['ElapsedSince.Milestone1.Total'];
        } else if (item['Event.Code'] == 'INV-CrCmm.Msg.Email.View') {
          this.emailEvent['viewedTime'] = item['DateTime_ISO'];
        } else if (item['Event.Code'] == 'INV-CrCmm.Rspd.Ini') {
          this.respondEvent['respondInitDate'] = item['DateTime_ISO'];
          this.respondEvent['respondInitElapsedSince'] = item['ElapsedSince.Milestone1.Total'];
          this.respondEvent['respondInitStatus'] = item['Invitation.CustomerChoice.Label'];
        } else if (item['Event.Code'] == 'INV-CrCmm.Rspd.Fin') {
          this.respondEvent['respondFinDate'] = item['DateTime_ISO'];
          this.respondEvent['respondFinElapsedSince'] = item['ElapsedSince.Milestone1.Total'];
          this.respondEvent['respondFinStatus'] = item['Invitation.CustomerChoice.Label'];
        }
      });
    });
  }

  getProgress() {
    let applicationStatus = this.invitation['Status.Application.Short'];
    let invitationStatus = this.invitation['Status.Invitation.Short'];
    let contractStatus = this.invitation['Status.Contract.Short'];
    let emailStatus = this.invitation['Status.Email.Short'];
    const productName = this.invitation['ProductName'];

    if (emailStatus == '') {
      emailStatus = null;
    }

    if (invitationStatus == '') {
      invitationStatus = null;
    }

    if (contractStatus == '') {
      contractStatus = null;
    }

    if (applicationStatus == '') {
      applicationStatus = null;
    }

    if (
      (emailStatus == 'Sent' || emailStatus == 'Read') &&
      !contractStatus &&
      !invitationStatus &&
      !applicationStatus
    ) {
      this.value = 5;
      this.color = 'primary';
      this.progress = 'Email ' + emailStatus;
      this.bufferValue = 5;
      this.Step = 'Invitation Card';
      this.ApplicationProgressLabel = 'Application Progress';
    } else if (emailStatus == 'Delivered' && !contractStatus && !invitationStatus && !applicationStatus) {
      this.valueInv = 22;
      this.colorInv = 'primary';
      this.progressInv = 'Invitation: ' + this.invitation['Status.Invitation.Short_NE'];
      this.bufferValueInv = 22;
      this.StepInv = 'Invitation Card';
    } else if (emailStatus == 'To Be Sent' && !contractStatus && !invitationStatus && !applicationStatus) {
      this.valueInv = 10;
      this.colorInv = 'primary';
      this.progressInv = 'Invitation: ' + this.invitation['Status.Invitation.Short_NE'];
      this.bufferValueInv = 10;
      this.StepInv = 'Invitation Card';
    } else if (
      emailStatus != 'To Be Sent' &&
      emailStatus != 'Delivered' &&
      !contractStatus &&
      !invitationStatus &&
      !applicationStatus
    ) {
      this.valueInv = 10;
      this.colorInv = 'accent';
      this.progressInv = 'Invitation: ' + this.invitation['Status.Invitation.Short_NE'];
      this.bufferValueInv = 10;
      this.StepInv = 'Invitation Card';
    } else if (emailStatus != 'Sent' && !contractStatus && !invitationStatus && !applicationStatus) {
      this.value = 5;
      this.color = 'accent';
      this.progress = 'Email: ' + emailStatus;
      this.bufferValue = 5;
      this.Step = 'Invitation Card';
      this.ApplicationProgressLabel = 'Application Progress';
    } else if (emailStatus && invitationStatus.indexOf('Defered') != -1 && !contractStatus && !applicationStatus) {
      this.value = 10;
      this.color = 'accent';
      this.progress = 'Customer Choice: ' + this.invitation['PatientChoice.Label_LongThirdPerson'];
      this.bufferValue = 10;
      this.Step = 'Invitation Card';
      this.ApplicationProgressLabel = 'Application Progress';

      this.valueInv = 75;
      this.colorInv = 'primary';
      this.progressInv = 'Invitation: ' + invitationStatus;
      this.bufferValueInv = 75;
      this.StepInv = 'Invitation Card';
    } else if (emailStatus && invitationStatus.indexOf('Expired') != -1 && !contractStatus && !applicationStatus) {
      this.value = 100;
      this.color = 'accent';
      this.progress = 'Invitation: ' + invitationStatus;
      this.bufferValue = 100;
      this.Step = 'Invitation Card';
      this.ApplicationProgressLabel = 'Application Progress';
    } else if (
      emailStatus &&
      (invitationStatus.indexOf('Expired') != -1 || invitationStatus.indexOf('Not Proceeding') != -1) &&
      !contractStatus &&
      !applicationStatus
    ) {
      this.value = 100;
      this.color = 'accent';
      this.progress = 'Customer Choice: ' + this.invitation['PatientChoice.Label_LongThirdPerson'];
      this.bufferValue = 100;
      this.Step = 'Invitation Card';
      this.ApplicationProgressLabel = 'Application Progress';

      this.valueInv = 75;
      this.colorInv = 'accent';
      this.progressInv = 'Invitation: ' + invitationStatus;
      this.bufferValueInv = 75;
      this.StepInv = 'Invitation Card';
    } else if (emailStatus && invitationStatus.indexOf('Pay Cash') != -1 && !contractStatus && !applicationStatus) {
      this.valueInv = 100;
      this.colorInv = 'primary';
      this.progressInv = 'Invitation: ' + invitationStatus;
      this.bufferValueInv = 100;
      this.StepInv = 'Invitation Card';
    } else if (emailStatus && invitationStatus.indexOf('Intends') != -1 && !contractStatus && !applicationStatus) {
      this.value = 1;
      this.color = 'primary';
      this.progress = 'Invitation: ' + invitationStatus;
      this.bufferValue = 1;
      this.Step = 'Application Card';
    } else if (emailStatus && invitationStatus == 'Not Viewed' && !contractStatus && !applicationStatus) {
      this.value = 10;
      this.color = 'primary';
      this.progress = 'Invitation: ' + invitationStatus;
      this.bufferValue = 10;
      this.Step = 'Invitation Card';
      this.ApplicationProgressLabel = 'Application Progress';
    } else if (emailStatus && invitationStatus == 'Viewed' && !contractStatus && !applicationStatus) {
      this.value = 10;
      this.color = 'primary';
      this.progress = 'Invitation: ' + invitationStatus;
      this.bufferValue = 10;
      this.Step = 'Invitation Card';
      this.ApplicationProgressLabel = 'Application Progress';

      this.valueInv = 47;
      this.colorInv = 'primary';
      this.progressInv = 'Invitation: ' + invitationStatus;
      this.bufferValueInv = 47;
      this.StepInv = 'Invitation Card';
    } else if (emailStatus && invitationStatus == 'Intends to Proceed' && !contractStatus && !applicationStatus) {
      this.value = 47;
      this.color = 'primary';
      this.progress = 'Invitation: ' + invitationStatus;
      this.bufferValue = 47;
      this.Step = 'Invitation Card';
      this.ApplicationProgressLabel = 'Application Progress';

      this.valueInv = 65;
      this.colorInv = 'primary';
      this.progressInv = 'Invitation: ' + invitationStatus;
      this.bufferValueInv = 65;
      this.StepInv = 'Invitation Card';
    } else if (emailStatus && invitationStatus == 'Commenced' && !contractStatus && !applicationStatus) {
      this.value = 33;
      this.color = 'primary';
      this.progress = 'Invitation: ' + invitationStatus;
      this.bufferValue = 33;
      this.Step = 'Get Started ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (emailStatus && invitationStatus == 'Via Web' && !contractStatus && !applicationStatus) {
      this.value = 33;
      this.color = 'primary';
      this.progress = 'Invitation: ' + invitationStatus;
      this.bufferValue = 33;
      this.Step = 'Get Started ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (emailStatus && invitationStatus == 'Discuss/Modify' && !contractStatus && !applicationStatus) {
      this.value = 33;
      this.color = 'primary';
      this.progress = 'Invitation: ' + invitationStatus;
      this.bufferValue = 33;
      this.Step = 'Get Started ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;

      this.valueInv = 75;
      this.colorInv = 'accent';
      this.progressInv = 'Invitation: ' + invitationStatus;
      this.bufferValueInv = 75;
      this.StepInv = 'Invitation Card';
    } else if (emailStatus && invitationStatus && applicationStatus.indexOf('Commenced') != -1 && !contractStatus) {
      this.value = 3;
      this.color = 'primary';
      this.progress = 'Application: ' + applicationStatus;
      this.bufferValue = 3;
      this.Step = 'Finalising ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (
      emailStatus &&
      invitationStatus &&
      applicationStatus.indexOf('Application Complete') != -1 &&
      !contractStatus
    ) {
      this.value = 58;
      this.color = 'primary';
      this.progress = 'Application: ' + applicationStatus;
      this.bufferValue = 58;
      this.Step = 'Finalising ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (
      emailStatus &&
      invitationStatus &&
      applicationStatus.indexOf('Application Pending') != -1 &&
      !contractStatus
    ) {
      this.value = 18;
      this.color = 'primary';
      this.progress = 'Application: ' + applicationStatus;
      this.bufferValue = 18;
      this.Step = 'Finalising ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (emailStatus && invitationStatus && applicationStatus.indexOf('Referred') != -1 && !contractStatus) {
      this.value = 30;
      this.color = 'primary';
      this.progress = 'Application: ' + applicationStatus;
      this.bufferValue = 30;
      this.Step = 'Finalising ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (emailStatus && invitationStatus && applicationStatus.indexOf('Approved') != -1 && !contractStatus) {
      this.value = 39;
      this.color = 'primary';
      this.progress = 'Application: ' + applicationStatus;
      this.bufferValue = 39;
      this.Step = 'Finalising ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (emailStatus && invitationStatus && applicationStatus.indexOf('Declined') != -1 && !contractStatus) {
      this.value = 35;
      this.color = 'accent';
      this.progress = 'Application: ' + applicationStatus;
      this.bufferValue = 35;
      this.Step = 'Finalising ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (emailStatus && invitationStatus && applicationStatus && contractStatus == 'Submitted') {
      this.value = 60;
      this.color = 'primary';
      this.progress = 'Contract: ' + contractStatus;
      this.bufferValue = 60;
      this.Step = 'Finalised ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (emailStatus && invitationStatus && applicationStatus && contractStatus == 'Approved') {
      this.value = 70;
      this.color = 'primary';
      this.progress = 'Contract: ' + contractStatus;
      this.bufferValue = 70;
      this.Step = 'Finalised ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (emailStatus && invitationStatus && applicationStatus && contractStatus == 'Active') {
      this.value = 100;
      this.color = 'primary';
      this.progress = 'Contract: ' + contractStatus;
      this.bufferValue = 100;
      this.Step = 'Finalised ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (emailStatus && invitationStatus && applicationStatus && contractStatus == 'Complete') {
      this.value = 100;
      this.color = 'primary';
      this.progress = 'Contract: ' + contractStatus;
      this.bufferValue = 100;
      this.Step = 'Finalised ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    }

    // else if (emailStatus && invitationStatus && applicationStatus.indexOf("Reffered") != -1) {
    //   this.value = 66;
    //   this.color = "accent";
    //   this.progress = applicationStatus;
    //   this.bufferValue = 66;
    //   this.Step = "Get Started "
    //   this.ApplicationProgressLabel = "Application Progress - " + productName
    // }

    // else if (emailStatus && invitationStatus && applicationStatus.indexOf("Declined") != -1) {
    //   this.value = 100;
    //   this.color = "accent";
    //   this.progress = applicationStatus;
    //   this.bufferValue = 100;
    //   this.Step = "Get Started "
    //   this.ApplicationProgressLabel = "Application Progress - " + productName
    // }

    // else if (emailStatus && invitationStatus && applicationStatus.indexOf("Submitted") != -1) {
    //   this.value = 100;
    //   this.color = "primary";
    //   this.progress = applicationStatus;
    //   this.bufferValue = 100;
    //   this.Step = "Get Started "
    //   this.ApplicationProgressLabel = "Application Progress - " + productName
    // }
  }
}
