export class ActionLogUtilClass {
  typeList = [
    {
      name: 'Auto',
      icon: 'fa-robot',
      color: '#f6b93b',
    },
    {
      name: 'Appointment',
      icon: 'fa-calendar-check',
      color: '#f6b93b',
    },
    {
      name: 'Contact',
      icon: 'fa-phone',
      color: '#e58e26',
    },
    {
      name: 'Meeting',
      icon: 'fa-handshake',
      color: '#079992',
    },
    {
      name: 'Onsite Visit',
      icon: 'fa-car',
      color: '#4a69bd',
    },
    {
      name: 'Phone In',
      icon: 'fa-phone-volume',
      color: '#eb2f06',
    },
    {
      name: 'Phone Out',
      icon: 'fa-phone-volume',
      color: '#b71540',
    },
    {
      name: 'Sales Opportunity',
      icon: 'fa-search-dollar',
      color: '#0a3d62',
    },
    {
      name: 'Work Performed',
      icon: 'fa-hammer',
      color: '#38ada9',
    },
    {
      name: 'Patient Response',
      icon: 'fa-paper-plane',
      color: '#1b8bdd',
    },
    {
      name: 'Invitation actions',
      icon: 'fa-paper-plane',
      color: '#1b8bdd',
    },
  ];

  subjectList = [
    {
      name: 'Applying for finance',
      icon: 'fa-money-bill-wave',
      color: '#3498db',
    },
    {
      name: 'Proceeding-cash/card',
      icon: 'fa-money-bill-wave',
      color: '#27ae60',
    },
    {
      name: 'Deferred decision for one week',
      icon: 'fa-user-clock',
      color: '#d35400',
    },
    {
      name: 'Deferred decision for one week',
      icon: 'fa-user-clock',
      color: '#d35400',
    },
    {
      name: 'Deferred decision for two weeks',
      icon: 'fa-user-clock',
      color: '#d35400',
    },
    {
      name: 'Deferred decision for three weeks',
      icon: 'fa-user-clock',
      color: '#d35400',
    },
    {
      name: 'Deferred decision for four weeks',
      icon: 'fa-user-clock',
      color: '#d35400',
    },
    {
      name: 'Action required - requested changes',
      icon: 'fa-user-edit',
      color: '#38ada9',
    },
    {
      name: 'Not proceeding',
      icon: 'fa-ban',
      color: '#EA2027',
    },
    {
      name: 'Clone invitation',
      icon: 'fa-copy',
      color: '#5758BB',
    },
    {
      name: 'Archive invitation',
      icon: 'fa-archive',
      color: '#6F1E51',
    },
    {
      name: 'Resend invitation',
      icon: 'fa-redo-alt',
      color: '#12CBC4',
    },
    {
      name: 'Reset patient-decision',
      icon: 'fa-history',
      color: '#5758BB',
    },
  ];

  constructor() {}

  getTypeIcon(type) {
    const result = this.typeList.find((x) => x.name == type);
    if (result) {
      return result.icon;
    } else {
      return 'fa-sticky-activity-log';
    }
  }

  getTypeColor(type) {
    const result = this.typeList.find((x) => x.name == type);

    if (result) {
      return result.color;
    } else {
      return '';
    }
  }

  getSubjectIcon(type, subject) {
    const result = this.subjectList.find((x) => x.name == subject);
    if (result) {
      return result.icon;
    } else {
      // return "fa-sticky-activity-log"

      return this.getTypeIcon(type);
    }
  }

  getSubjectColor(type, subject) {
    const result = this.subjectList.find((x) => x.name == subject);

    if (result) {
      return result.color;
    } else {
      // return "";

      return this.getTypeColor(type);
    }
  }

  changeSubject(subject) {
    if (subject) {
      const label = {
        'Create invitation': 'Sent a treatment plan',
        'Clone invitation': 'Sent a treatment plan',
        'View invitation': 'View a treatment plan',
        'Resend invitation': 'Sent a treatment plan',
        'Archive invitation': 'Archived treatment plan',
        'Reset patient decision': 'Reset decision',
        'Not proceeding': 'Is not proceeding with treatment',
        'Proceeding-cash/card': 'Proceeding-cash/card',
        'Proceeding-cash/card with discount': 'Proceeding-cash/card with discount',
        'Action required - requested changes': 'Requested changes of a treatment plan',
        'Deferred decision for one week': 'Deferred a treatment plan for one week',
        'Deferred decision for two week': 'Deferred a treatment plan for two weeks',
        'Deferred decision for three week': 'Deferred a treatment plan for three weeks',
        'Deferred decision for four week': 'Deferred a treatment plan for four weeks',
        'Applying for finance': 'Applying for finance',
      };

      if (label[subject]) {
        return label[subject];
      } else {
        return 'Sent a treatment plan';
      }
    } else {
      return 'Sent a treatment plan';
    }
  }

  changeText(subject) {
    if (subject) {
      const label = {
        'Create invitation': 'Created',
        'Clone invitation': 'Cloned',
        'View invitation': 'Invited',
        'Resend invitation': 'Resent',
        'Archive invitation': 'Archived',
        'Reset patient decision': 'Reset',
        'Not proceeding': 'Invited',
        'Proceeding-cash/card': 'Invited',
        'Proceeding-cash/card with discount': 'Invited',
        'Action required - requested changes': 'Invited',
        'Deferred decision for one week': 'Invited',
        'Deferred decision for two week': 'Invited',
        'Deferred decision for three week': 'Invited',
        'Deferred decision for four week': 'Invited',
        'Applying for finance': 'Invited',
      };

      if (label[subject]) {
        return label[subject];
      } else {
        return 'Invited';
      }
    } else {
      return 'Invited';
    }
  }
}
