<div class="clearfix row full-width contact-modal" *ngIf="(targetType && target) || targetType == 'us'">
  <!-- contact smileright -->
  <div class="row clearfix cardheader contact-header primary-gradient-img inModal" *ngIf="targetType == 'us'">
    <button
      class="btn-close btn-clear white pull-right mat-button"
      *ngIf="isModal == true"
      (click)="close()"
      tabIndex="-1"
    >
      <mat-icon>clear</mat-icon>
    </button>

    <div class="row titleArea clearfix">
      <div class="logoContainer pull-left">
        <!-- <img class="headNavImg userIconImg site_logo" /> -->

        <!-- needs merchant key -->
        <app-merchant-view-picture class="pull-left contactInputPic" [merchantID]="" [hideZoomButton]="false">
        </app-merchant-view-picture>
      </div>

      <h2 class="summary-header rm-m white">
        Use the form below to get in touch with {{ brandName }}.
        <!-- <span class="subLabel">Alternatively, our direct contact details can be found on the next tab.</span> -->
      </h2>
    </div>
  </div>

  <!-- contact someone other than smileright -->
  <div class="row clearfix cardheader contact-header primary-gradient-img inModal" *ngIf="targetType != 'us'">
    <button
      class="btn-close btn-clear white pull-right mat-button"
      *ngIf="isModal == true"
      (click)="close()"
      tabIndex="-1"
    >
      <mat-icon>clear</mat-icon>
    </button>

    <div class="row titleArea clearfix">
      <app-ep-document-view-image
        class="headNavcustomer contactInputPic userIconContactHeader contactImage pull-left"
        [defaultPicture]="
          'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
        "
        [link]="profileLink"
      >
      </app-ep-document-view-image>

      <h2 class="summary-header rm-m white">
        Send email message to <Strong> {{ target.name }} </Strong>
        <span class="subLabel" *ngIf="!asGuest"
        >To: {{ target.email }}

          <span class="" *ngIf="target.mobile" matTooltip="Mobile Phone"> - {{ target.mobile | customPhone }} </span>
        </span>
      </h2>
    </div>
  </div>

  <mat-dialog-content class="contact">
    <div class="row clearfix">
      <mat-button-toggle-group class="btn-group two-thrd-width clearfix flex" *ngIf="settings" [(ngModel)]="filter">
        <mat-button-toggle class="half-width" [value]="'sendUS'"> Send us a message</mat-button-toggle>
        <mat-button-toggle class="half-width" [value]="'contactUS'"> Contact us</mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="row clearfix" *ngIf="filter == 'sendUS'">
      <form class="row clearfix" #contactForm="ngForm">
        <div class="row clearfix" *ngIf="messageSent == false">
          <!-- sending as a guest -->
          <div
            class="row clearfix"
            *ngIf="asGuest == true && asProfile == false && asPractice == false && asInvitation == false"
          >
            <div class="row clearfix full-width">
              <div class="icons-col">
                <mat-icon>email</mat-icon>
              </div>
              <div class="row-content-col">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Email</mat-label>
                  <input
                    class="mat-block"
                    [(ngModel)]="email"
                    name="emailGuest1"
                    matInput
                    placeholder="Email "
                    pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                    required
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="row clearfix full-width">
              <div class="icons-col">
                <mat-icon>person</mat-icon>
              </div>
              <div class="row-content-col">
                <div class="row clearfix flex">
                  <mat-form-field class="full-width mr" appearance="outline">
                    <mat-label>First name</mat-label>
                    <input
                      class="mat-block"
                      [(ngModel)]="firstName"
                      name="firstnameGuest1"
                      matInput
                      placeholder="First name "
                      required
                    />
                  </mat-form-field>
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Last name </mat-label>
                    <input
                      class="mat-block"
                      [(ngModel)]="lastName"
                      name="lastnameGuest1"
                      matInput
                      placeholder="Last name "
                      required
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>

          <div class="row clearfix" *ngIf="asProfile == true || asPractice == true || asInvitation == true">
            <div class="row clearfix" *ngIf="contactType == 'guest'">
              <div class="row clearfix">
                <div class="icons-col">
                  <mat-icon>email</mat-icon>
                </div>
                <div class="row-content-col">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Email</mat-label>
                    <input
                      class="mat-block"
                      [(ngModel)]="email"
                      name="emailGuest"
                      matInput
                      placeholder="Email "
                      pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                      required
                    />
                  </mat-form-field>
                </div>
              </div>

              <div class="row clearfix">
                <div class="icons-col">
                  <mat-icon>person</mat-icon>
                </div>
                <div class="row-content-col flex">
                  <mat-form-field class="full-width mr" appearance="outline">
                    <mat-label>First name</mat-label>
                    <input
                      class="mat-block"
                      [(ngModel)]="firstName"
                      name="firstnameGuest"
                      matInput
                      placeholder="First name "
                      required
                    />
                  </mat-form-field>
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Last name</mat-label>
                    <input
                      class="mat-block"
                      [(ngModel)]="lastName"
                      name="lastnameGuest"
                      matInput
                      placeholder="Last name "
                      required
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="row clearfix" *ngIf="contactType == 'invitation' && invitation">
              <div class="row clearfix">
                <div class="icons-col">
                  <mat-icon>email</mat-icon>
                </div>
                <div class="row-content-col">
                  <p class="small rm-mb">Email address</p>
                  <p class="rm-m bold op08">{{ invitation['Email'] }}</p>
                </div>
              </div>

              <div class="row clearfix">
                <div class="icons-col">
                  <mat-icon>person</mat-icon>
                </div>
                <div class="row-content-col">
                  <p class="small rm-mb">From</p>
                  <p class="rm-mt bold op08">{{ invitation['FirstName'] }} {{ invitation['LastName'] }}</p>
                </div>
              </div>
            </div>
          </div>

          <!-- actual message area -->
          <div class="row clearfix flex">
            <div class="icons-col">
              <mat-icon>announcement</mat-icon>
            </div>
            <div class="row-content-col">
              <mat-form-field class="full-width" *ngIf="subjectArray.length <= 0" appearance="outline">
                <mat-label>{{ subjectLabel }}</mat-label>
                <input
                  class="mat-block"
                  [(ngModel)]="subject"
                  name="subject"
                  matInput
                  placeholder="{{ subjectLabel }}"
                  tabIndex="1"
                  required
                />
              </mat-form-field>

              <div class="row clearfix">
                <mat-form-field class="full-width" *ngIf="subjectArray.length > 0" appearance="outline">
                  <mat-label>subject</mat-label>
                  <mat-select
                    class="full-width"
                    [(ngModel)]="subject"
                    name="subjectList"
                    name="theme"
                    placeholder="{{ subjectLabel }}"
                    required
                  >
                    <mat-option *ngFor="let s of subjectArray" [value]="s">
                      {{ s }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>

          <!--  resending invitation -->

          <ng-container *ngIf="resendingInvitation">
            <div class="row clearfix">
              <div class="icons-col">
                <mat-icon>person</mat-icon>
              </div>
              <div class="row-content-col flex">
                <mat-form-field class="full-width mr" appearance="outline">
                  <mat-label>First name</mat-label>
                  <input
                    class="mat-block"
                    [(ngModel)]="firstName"
                    name="firstnameGuest"
                    matInput
                    placeholder="First name "
                    required
                  />
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Last name</mat-label>
                  <input
                    class="mat-block"
                    [(ngModel)]="lastName"
                    name="lastnameGuest"
                    matInput
                    placeholder="Last name "
                    required
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="row clearfix">
              <div class="icons-col">
                <mat-icon>calendar_today</mat-icon>
              </div>
              <div class="row-content-col flex">
                <mat-form-field class="full-width mr" appearance="outline">
                  <mat-label>Original {{ "KEYWORD.treatment" | translate }} date</mat-label>
                  <input class="mat-block" name="treatmentDate" matInput
                         [placeholder]="('KEYWORD.treatment' | translate | titlecase) + ' date'" required/>
                </mat-form-field>
              </div>
            </div>

            <div class="row clearfix">
              <div class="icons-col">
                <mat-icon>attach_money</mat-icon>
              </div>
              <div class="row-content-col flex">
                <mat-form-field class="full-width mr" appearance="outline">
                  <mat-label>Original {{ "KEYWORD.treatment" | translate }} amount</mat-label>
                  <input class="mat-block" name="treatmentAmount" matInput
                         [placeholder]="('KEYWORD.treatment' | translate | titlecase) + ' amount'" required/>
                </mat-form-field>
              </div>
            </div>
          </ng-container>

          <div class="row clearfix">
            <div class="icons-col">
              <mat-icon class="fas fa-comments"></mat-icon>
            </div>
            <div class="row-content-col">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Your Message</mat-label>
                <textarea
                  class="messageArea"
                  [(ngModel)]="message"
                  name="message"
                  matInput
                  placeholder="Your Message"
                  required
                  tabIndex="2"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>

        <!-- message sent, show content -->
        <div class="row clearfix text-center animated fadeInDown" *ngIf="messageSent == true">
          <h2 class="sm-h2 sr-title">Thanks!</h2>
          <p>{{ thankLabel }}</p>
        </div>

        <!-- sending button -->
        <div class="full-width text-center" *ngIf="messageSent == false">
          <button
            class="mt book-training pull-left mb"
            *ngIf="showBooking == true"
            (click)="booking()"
            mat-raised-button
            type="submit"
            color="accent"
          >
            <mat-icon>calendar_today</mat-icon> Book Training
          </button>
          <button
            class="mt contact-us mb"
            [class.pull-right]="showBooking == true"
            [disabled]="!contactForm.form.valid"
            (click)="contactSend()"
            mat-raised-button
            type="submit"
            color="accent"
          >
            <mat-icon>email</mat-icon>
            {{ sendLabel }}
          </button>
        </div>
      </form>
    </div>

    <div class="row clearfix contacts add-mt" *ngIf="settings && filter == 'contactUS'">
      <p>
        <mat-icon>phone</mat-icon>
        <span class="details"
        ><span class="labels">Call us on</span>
          <strong>{{ settings.contactPhone | customPhone: 'landLine' }}</strong></span
        >
      </p>
      <hr/>
      <p>
        <mat-icon>access_time</mat-icon>
        <span class="details"
        ><span class="labels">Opening hours:</span> <strong>Monday - Friday from 7:00am - 5:00pm AWST</strong>
          <span class="labels">(10:30am - 7:00pm *EST.)</span></span
        >
      </p>
      <hr/>
      <p>
        <mat-icon>alternate_email</mat-icon>
        <span class="details"
        ><span class="labels">Email us at</span> <strong> {{ settings.contactEmail }}</strong></span
        >
      </p>
      <hr/>
      <p>
        <mat-icon>home</mat-icon>
        <span class="details"
        ><span class="labels">Address:</span> <strong>{{ settings.address }} </strong></span
        >
      </p>

      <hr/>
      <p class="small">
        <em>{{ settings.license }}</em>
      </p>

      <!-- <hr class="mt" *ngIf="simplePageCode">
      <div class="row full-width   clearfix" *ngIf="simplePageCode">
        <app-simple-page-view [simplePageCode]="simplePageCode" [displayHeader]="false"> </app-simple-page-view>
      </div> -->
    </div>
  </mat-dialog-content>
</div>
