import { transition, trigger } from '@angular/animations';
import { CurrencyPipe, DOCUMENT } from '@angular/common';
import { Component, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import { AppStateService } from '../../../core/app-state/app-state.service';
import { AppState } from '../../../core/app-state/app-state.type';
import { SecurityCodeInfoComponent } from '../../../core/authentication/security-code-info/security-code-info.component';
import { SecurityCodeInput } from '../../../core/authentication/security-code-input/security-code-input.component';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ErrorModalComponent } from '../../../core/helper/error-modal/error-modal.component';
import { ngIfAnimateInAnimation, ngIfAnimateOutAnimation } from '../../../shared/animations/ngIf/ngIfAnimate.animation';
import {
  ngIfAnimateTabInAnimation,
  ngIfAnimateTabOutAnimation,
} from '../../../shared/animations/ngIf/ngIfAnimateTab.animation';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomCurrencyPipe } from '../../../shared/pipes/custom-currency.pipe';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { LookupService } from '../../../shared/services/lookup.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { SessionType } from '../../../shared/types/session-type.type';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AppointmentCreateCustomerComponent } from '../../appointment/appointment-create-customer/appointment-create-customer.component';
import { InformedConsent, InformedConsentService } from '../../informed-consent/shared/informed-consent.service';
import { InvitationService } from '../../invitation/shared/services/invitation.service';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { Merchant } from '../../merchant/shared/types/merchant.type';
import { ProductCalculatorComponent } from '../../product/product-calculator/product-calculator.component';
import { ProductService } from '../../product/shared/product.service';
import { TreatmentService } from '../../treatment/shared/treatment.service';
import { TreatmentPlan, TreatmentPlanDocument, TreatmentPlanQuery } from '../../treatment/shared/treatment.type';
import { InformedConsentComponent } from '../informed-consent/informed-consent.component';
import { PatientCardChangesModalComponent } from '../patient-card-changes-modal/patient-card-changes-modal.component';
import { PatientCardDeferModalComponent } from '../patient-card-defer-modal/patient-card-defer-modal.component';
import { DiscountInfoModalComponent } from '../patient-card-discount-info-modal/patient-card-discount-info-modal.component';
import { PatientCardStopModalComponent } from '../patient-card-stop-modal/patient-card-stop-modal.component';
import { PatientService } from '../shared/patient.service';

@Component({
  selector: 'app-patient-card',
  templateUrl: './patient-card.component.html',
  styleUrls: ['./patient-card.component.css'],
  providers: [CurrencyPipe, CustomDatePipe],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', ngIfAnimateOutAnimation),
      transition('* => void', ngIfAnimateInAnimation),
    ]),

    trigger('ngIfTab', [
      transition('void => *', ngIfAnimateTabOutAnimation),
      transition('* => void', ngIfAnimateTabInAnimation),
    ]),
  ],
})
export class PatientCardComponent implements OnInit, OnDestroy {
  utils = new UtilsClass();

  settings = Settings.global;
  context = Settings.global['context'];

  isModuleAppointmentOldActive = Settings.global['isModuleAppointmentOldActive'];
  isModuleDiscountsActive = Settings.global['isModuleDiscountsActive'];
  isModulePatientHistoryActive = Settings.global['isModulePatientHistoryActive'];
  isModulePatienCardtSecurityActive = Settings.global['isModulePatienCardtSecurityActive'];
  isModuleMerchantInformedConsentActive = Settings.global['isModuleMerchantInformedConsentActive'];
  isModuleAppointmentActive = Settings.global['isModuleAppointmentActive'];

  @Output() consumerChoice = new EventEmitter();
  @Output() deferedWeek = new EventEmitter();
  @Output() isExist: EventEmitter<any> = new EventEmitter();

  @Input('customerInvitation') customInv = null;
  @Input() viewOnly = false;
  @Input() invitationID;
  @Input() emailActions = null;
  @Input() dentistAppointmentUrl = null;
  @Input() merchantInformedConsent = null;
  @Input() accessByMerchant = false;

  isPromoterOrAdmin = false;
  loading = false;
  isFile = false;
  treatmentCategorySet = false;
  isMobileValid = false;
  isHomePhoneValid = false;
  isEmailValid = false;
  isAddressValid = false;
  displayDiscount = false;
  stepTwoVisited = false;
  stepThreeVisited = false;

  reasons = [];
  feedback = [];
  titles = [];
  documents = [];

  treatment = {};

  headPos = 'below';

  sessionType: SessionType = 'guest';
  practice: Partial<Merchant>;

  step = 0;
  selectedTab = 0;

  mySuburb;
  myState;
  myPostCode;
  myStreetNumber;
  myStreetName;
  myUnitNumber;
  myStreetType;
  myLatitude;
  myLongitude;
  phone;
  mobile;
  email;

  cashDiscountDateCounter;
  interval;
  expireTill;
  cashDiscountDate;
  minAmount;

  informedConsent: InformedConsent;
  treatmentPlan: TreatmentPlan;

  viewedDocuments: { id: string; viewedDate: string }[];
  treatmentDocuments: TreatmentPlanDocument[];
  stockArtDocuments: TreatmentPlanDocument[];
  preDocuments: TreatmentPlanDocument[];
  postDocuments: TreatmentPlanDocument[];
  marketingDocuments: TreatmentPlanDocument[];

  isInitialLoaded = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private utilService: UtilsService,
    private dialog: MatDialog,
    private productService: ProductService,
    private patientService: PatientService,
    private treatmentService: TreatmentService,
    private dataPipe: CustomDatePipe,
    private currencyPipe: CustomCurrencyPipe,
    private lookupService: LookupService,
    private authentication: AuthenticationService,
    private invitationService: InvitationService,
    private customPhonePipe: CustomPhonePipe,
    private merchantService: MerchantService,
    private informedConsentService: InformedConsentService,
    private appStateService: AppStateService
  ) {}

  ngOnInit() {
    this.appStateService
      .getAppState$()
      .pipe(
        untilDestroyed(this),
        tap((appState) => this.initialize(appState)),
        switchMap(() => this.getInformedConsentList$()),
        switchMap((informedConsent) => this.getTreatmentPlan$(informedConsent[0]))
      )
      .subscribe(() => {
        this.isInitialLoaded = true;
        this.viewedDocuments = this.treatmentPlan.viewedDocuments || [];
        this.treatmentDocuments = this.treatmentPlan.treatmentDocuments
          ? this.getTreatmentDocumentRecords(this.treatmentPlan.treatmentDocuments)
          : [];
        this.stockArtDocuments = this.treatmentPlan.stockArtTreatmentDocuments
          ? this.getTreatmentDocumentRecords(this.treatmentPlan.stockArtTreatmentDocuments)
          : [];
        this.preDocuments = this.treatmentPlan.preDocuments
          ? this.getTreatmentDocumentRecords(this.treatmentPlan.preDocuments)
          : [];
        this.postDocuments = this.treatmentPlan.postDocuments
          ? this.getTreatmentDocumentRecords(this.treatmentPlan.postDocuments)
          : [];
        this.marketingDocuments = this.treatmentPlan.marketingDocuments
          ? this.getTreatmentDocumentRecords(this.treatmentPlan.marketingDocuments)
          : [];

        if (this.customInv) {
          this.getPersonalInfo();
          this.utilService.getAccessInvitationList(this.customInv['ID']).subscribe((access) => {
            if (access) {
              this.isModuleAppointmentOldActive = access['isModuleAppointmentOldActive'];
              this.isModuleDiscountsActive = access['isModuleDiscountsActive'];
              this.isModulePatientHistoryActive = access['isModulePatientHistoryActive'];
              this.isModulePatienCardtSecurityActive = access['isModulePatienCardtSecurityActive'];
              this.isModuleMerchantInformedConsentActive = access['isModuleMerchantInformedConsentActive'];
              this.isModuleAppointmentActive = access['isModuleAppointmentActive'];
            }

            if (window.innerWidth < 1023) {
              this.headPos = 'below';
            }

            if (window.innerWidth > 1023) {
              this.headPos = 'above';
            }

            if (this.accessByMerchant !== true) {
              this.invitationService.markAsViewed(this.customInv['ID']).subscribe(() => {});
            }

            this.treatmentService.getSimpleTreatment(this.invitationID).subscribe((res) => {
              this.treatment = res;

              if (!this.treatment || !this.treatment['totalTreatmentValue'] || this.treatment['isAdvanced'] === true) {
                this.isExist.emit(false);
              } else {
                this.isExist.emit(true);
              }
            });

            if (
              this.customInv &&
              this.customInv['Product.ThisGroupOnly.Code'] &&
              this.customInv['Merchant_Key'] &&
              this.customInv['Treatment.Value']
            ) {
              const payload = {
                merchantKey: this.customInv['Merchant_Key'],
                amount: this.customInv['Treatment.Value'],
                groupCode: this.customInv['Product.ThisGroupOnly.Code'],
                fields: 'Min(BasedOnMaxDuration.Repayments.Weekly)',
              };

              this.productService.getSuitableProductMinValue(payload).subscribe((res) => {
                if (res) {
                  this.minAmount = Number(Number(res['Min(BasedOnMaxDuration.Repayments.Weekly)']) / 7);
                }
              });
            }

            if (this.customInv && this.customInv['CashDiscount.Expiry.Date'] !== '0000-00-00') {
              this.displayDiscount = moment().isBefore(
                moment(this.customInv['CashDiscount.Expiry.Date'], 'YYYY-MM-DD')
              );

              const _now = new Date().getTime();

              const target = moment(this.customInv['CashDiscount.Expiry.Date'], 'YYYY-MM-DD').toDate().getTime();

              this.expireTill = target - _now;

              if (this.displayDiscount === true) {
                this.countDown();
              }
            }

            if (this.emailActions && this.emailActions === 'payCash') {
              this.selectedTab = 2;
              if (
                this.isModuleDiscountsActive === true &&
                this.customInv['CashDiscount.Offered.YesNo'] === '1' &&
                this.displayDiscount === true
              ) {
                this.confirmNotFincanceWithDiscount();
              } else {
                this.confirmNotFincance();
              }
            } else if (this.emailActions && this.emailActions === 'dontProceed') {
              this.selectedTab = 3;
              this.openPatientCardStopModal();
            } else if (this.emailActions && this.emailActions === 'holdOff') {
              this.selectedTab = 3;
              this.openPatientCardDeferModal();
            } else if (this.emailActions && this.emailActions === 'requestChanges') {
              this.selectedTab = 3;
              this.openPatientCardChangesModal();
            } else if (this.emailActions && this.emailActions === 'proceedFinance') {
              this.selectedTab = 2;
            } else if (this.emailActions && this.emailActions === 'medicalHistory') {
              this.selectedTab = 1;
            } else {
              this.selectedTab = 0;
            }
          });
        }
      });
  }

  initialize(appState: AppState) {
    this.sessionType = appState.sessionType;
    this.isPromoterOrAdmin = appState.isPromoterOrAdmin;
    this.practice = appState.practice;
    return;
  }

  getInformedConsentList$() {
    return this.informedConsentService.fetchInformedConsentList$({ invitationID: this.customInv['ID'] }).pipe(
      tap((informedConsentList: InformedConsent[]) => {
        this.informedConsent = informedConsentList[0];
      })
    );
  }

  getTreatmentPlan$(informedConsent: InformedConsent) {
    const query: TreatmentPlanQuery = {
      payload: {
        informedConsentID: informedConsent.ID,
        selectTreatmentDocument: false,
        selectTreatmentStockArtDocument: true,
        invitationID: informedConsent.Invitation_key,
      },
    };

    return this.treatmentService.fetchTreatmentPlan$(query.payload).pipe(
      tap((treatmentPlan) => {
        this.treatmentPlan = treatmentPlan;
      })
    );
  }

  getTreatmentDocumentRecords(treatmentPlanDocuments: TreatmentPlanDocument[]) {
    return (
      treatmentPlanDocuments.map((document) => {
        const preLoadedDocument = {
          ...document,
          imageLink: this.utilService.getDocumentThumbnailStreamLink(document.ID),
          viewedDate: this.getViewedDate(document, this.viewedDocuments),
        };
        return preLoadedDocument;
      }) || []
    );
  }

  ngOnDestroy() {
    /** required for untilDestroyed (Replace with @UntilDestroyed() Decorator in angular 9 and delete ngOnDestroy()) */
  }

  countDown() {
    this.interval = setInterval(() => {
      this.expireTill = this.expireTill - 1000;

      if (this.expireTill > 0) {
        this.cashDiscountDateCounter = this.utils.convertDateObject(this.expireTill / 1000);
      } else {
        this.displayDiscount = false;
        this.cashDiscountDateCounter = null;
        clearInterval(this.interval);
      }
    }, 1000);
  }

  getDocuments(e) {
    if (e) {
      this.documents = e;
    }
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  tabClick(tab) {
    if (tab === 1) {
      this.stepTwoVisited = true;
    }

    if (tab === 2) {
      this.stepThreeVisited = true;
    }
  }

  changeTab() {
    this.selectedTab += 1;
    if (this.selectedTab >= 4) {
      this.selectedTab = 0;
    }

    if (this.selectedTab === 1) {
      this.stepTwoVisited = true;
    }

    if (this.selectedTab === 2) {
      this.stepThreeVisited = true;
    }

    UtilsClass.scrollUp();
  }

  backTab() {
    this.selectedTab -= 1;
    UtilsClass.scrollUp();
  }

  print(): void {
    let printContents;
    let popupWin;

    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html lang="en">
        <head>
          <title>Print tab</title>
          <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
          <style>
          html, body {
              height: 100%;
          }
          body, html, .mat-button, .mat-raised-button, .mat-icon-button, .mat-card, .mat-list-item, mat-card, .sr-title, div[class*=' mat-'], .mat-table, text, h1, h2, h3, h4, p, table, .table-striped, .dataTable {
              font-family: 'Open Sans', 'Helvetica Neue', sans-serif !important;
          }
          .clearfix:after {
              display: block;
              content: "";
              clear: both;
          }
          .print-only {
              display: block;
            }
            .sr-title, .half-width .lead {
                color: #1b8bdd;
            }
            .toothView {
                width: 30px;
                float: left;
                margin-top: 5px;
            }
            .pull-left {
              float: left;
            }
            .pull-right {
                float: right;
            }
            div.teethShap p {
                color: black;
                width: 100%;
                text-align: center;
                font-size: 14px;
                margin: 1px;
            }
            .sm-h3 {
                font-weight: 200;
                font-size: 1.2rem;
                line-height: 1.5rem;
                padding: 1rem 0;
            }
            p {
                font-weight: 300;
            }
            p.lead {
                font-size: 1.5rem;
                font-weight: 400;
                line-height: 1.85rem;
            }
            h2.summary-header.sr-title.rm-mt {
                font-weight: normal;
            }
            .material-icons {
                font-family: 'Material Icons';
                font-weight: normal;
                font-style: normal;
                font-size: 24px;
                line-height: 1;
                letter-spacing: normal;
                text-transform: none;
                display: inline-block;
                white-space: nowrap;
                word-wrap: normal;
                direction: ltr;
                -webkit-font-feature-settings: 'liga';
                -webkit-font-smoothing: antialiased;
            }
            .mat-icon {
                background-repeat: no-repeat;
                display: inline-block;
                fill: currentColor;
                height: 24px;
                width: 24px;
            }
            p.small {
                font-size: .8rem;
                font-style: italic;
                color: #777;
                margin-top: 2px;
            }
            .details mat-icon {
                color: #c5c5c5;
                margin-right: 10px;
                top: -0.8rem;
                position: relative;
             }
            h3.wide-header {
                padding: 10px !important;
                border-radius: 3px;
                color: #fff;
                text-shadow: 0 1px 6px rgba(0, 39, 64, 0.3);
            }
            .primary-light-bg {
                background-color: #bbdefb;
            }
            .description {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -ms-flex-direction: row;
                flex-direction: row;
            }
            .description .qtr-width {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
            }
            .half-width {
                width: 50% !important;
                float: left;
            }
            .qtr-width {
                width: 25% !important;
                float: left;
            }
            .full-width: {
              width: 100%;
            }
            .clearfix::after {
                clear: both;
                display: block;
                content: "";
                height: 0;
            }
            .details.flex {
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: start;
                -ms-flex-pack: start;
                justify-content: flex-start;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -ms-flex-direction: row;
                flex-direction: row;
            }
            .rm-mt {
                margin-top: 0 !important;
                padding-top: 0 !important;
            }
            .rm-mb {
                margin-bottom: 0 !important;
                padding-bottom: 0 !important;
            }
            button {
              display: none;
            }
            app-treatment-list-consumer h2 {
               font-size: 1.2rem;
               margin-top: 0;
            }
            h4.subtitle-h4 {
                margin-top: 5px;
                font-weight: normal;
            }
            .qtr-width.clearfix.flex {
                display: none;
            }
            app-dental-arch-view {
                display: flex;
                margin-bottom: 20px;
            }
            app-dental-arch-view p.tot {
                margin-right: 20px;
            }
            p {
                font-size: 0.9rem;
                color: #585858;
            }
            .text-center {
              text-align: center;
            }
            h2 app-gravatar {
                top: 15px !important;
                position: relative;
                margin-right: 5px;
            }
            img.grav {
                border-radius: 50%;
            }
            hr {
                border: none;
                border-bottom: 2px solid #d4d4d4;
            }
            .printFooter {
              position: absolute;
              bottom: 0;
              background: #fdfdfd;
              width: 100%;
              display: block;
              left:0;
              right: 0;
            }
            .printFooter .mt {
              margin-top: 5px;
            }
            .printFooter p.small {
                font-size: .6rem;
                font-style: italic;
                color: #777;
                margin-top: 2px;
            }
             .printFooter img {
                max-width: 50px;
                float: right;
                margin-top: 10px;
            }
          </style>
        </head>
         <body onload="window.print();window.close()">${printContents}</body>
      </html>`);
    popupWin.document.close();
  }

  openPatientCardChangesModal() {
    const ref = this.dialog.open(PatientCardChangesModalComponent, {
      data: {
        invitationID: this.customInv['ID'],
        force: false,
      },
      width: '500px',
    });

    ref.componentInstance.done.subscribe(() => {
      ref.close();
      this.consumerChoice.emit('DSMO');
    });
  }

  openDiscountInfoModal() {
    const ref = this.dialog.open(DiscountInfoModalComponent, {
      data: {
        customInvDate: this.customInv['CashDiscount.Expiry.Date'],
        treatmentAmount: this.customInv['Treatment.Value'],
        discountAmount: this.customInv['CashDiscount.Offered.Amount'],
      },

      width: '600px',
    });

    ref.componentInstance.closeModal.subscribe(() => {
      ref.close();
    });

    ref.componentInstance.closeProceeding.subscribe(() => {
      ref.close();
      this.confirmNotFincanceWithDiscount();
    });
  }

  openPatientCardDeferModal() {
    const ref = this.dialog.open(PatientCardDeferModalComponent, {
      data: {
        invitationID: this.customInv['ID'],
        force: false,
      },
      width: '350px',
    });

    ref.componentInstance.done.subscribe((data) => {
      ref.close();
      this.consumerChoice.emit('DEFER');
      this.deferedWeek.emit(data);
    });
  }

  openPatientCardStopModal() {
    const ref = this.dialog.open(PatientCardStopModalComponent, {
      data: {
        invitationID: this.customInv['ID'],
        force: false,
      },

      width: '500px',
      panelClass: 'flex',
    });

    ref.componentInstance.done.subscribe(() => {
      this.consumerChoice.emit('NOT');

      ref.close();
    });
  }

  confirmNotFincance() {
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: new ConfirmDialog(
        'check_circle',
        'Proceed, and pay at the practice',
        'You are selecting to proceed with your treatment, arranging to settle your bill at the practice using cash or credit card. Are you sure you wish to proceed?',
        'Let me see my options again',
        'Yes, Proceed'
      ),
      width: '500px',
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val === true) {
        ref.close();
        ref.afterClosed().subscribe(() => {
          if (this.isModuleMerchantInformedConsentActive === true) {
            const ref1 = this.dialog.open(InformedConsentComponent, {
              data: this.merchantInformedConsent,
              panelClass: 'infConsent',
            });

            ref1.componentInstance.agreement.subscribe((data) => {
              this.informedConsentService.createInformedConsentPDF(this.informedConsent.ID).subscribe((documentID) => {
                if (data == true) {
                  this.patientService.notFinanceInivation(this.customInv['ID'], false).subscribe((res) => {
                    if (res) {
                      ref1.close();
                      ref1.afterClosed().subscribe((close) => {
                        if (this.isModuleAppointmentActive == true) {
                          if (this.customInv['ThirdPartyBooking_URL']) {
                            window.open(this.customInv['ThirdPartyBooking_URL'], '_blank');

                            NotifyAppComponent.displayToast(
                              'success',
                              'Successful operation',
                              'Your can now proceed and pay at practice'
                            );

                            this.consumerChoice.emit('T_OTH_G');
                          } else {
                            const refAppointment = RootAppComponent.dialog.open(AppointmentCreateCustomerComponent, {
                              data: {
                                invitationID: this.customInv['ID'],
                                cancelLabel: 'Close',
                                submitLabel: 'Submit',
                                displayReasonForVisiting: false,

                                isApplicationProceed: true,
                              },
                              disableClose: true,
                              width: '800px',
                              panelClass: 'noCard',
                            });

                            refAppointment.componentInstance.close.subscribe((res) => {
                              refAppointment.close();

                              NotifyAppComponent.displayToast(
                                'success',
                                'Successful operation',
                                'Your can now proceed and pay at practice'
                              );

                              this.consumerChoice.emit('T_OTH_G');
                            });
                            refAppointment.componentInstance.result.subscribe((res) => {
                              if (res && res.ID) {
                                refAppointment.close();
                                NotifyAppComponent.displayToast(
                                  'success',
                                  'Successful operation',
                                  'Your can now proceed and pay at practice'
                                );

                                this.consumerChoice.emit('T_OTH_G');
                              }
                            });
                          }
                        } else {
                          NotifyAppComponent.displayToast(
                            'success',
                            'Successful operation',
                            'Your can now proceed and pay at practice'
                          );

                          this.consumerChoice.emit('T_OTH_G');
                        }
                      });

                      this.patientService
                        .acceptMerchantInformedConsent(this.customInv['ID'], documentID)
                        .subscribe((res) => {
                          if (res) {
                            // NotifyAppComponent.displayToast(
                            //   "success",
                            //   "Informed Consent",
                            //   "You have accepted infomred consent"
                            // );
                          }
                        });
                    }
                  });
                }
              });
            });
          } else {
            this.patientService.notFinanceInivation(this.customInv['ID'], false).subscribe((response) => {
              if (response) {
                if (this.isModuleAppointmentActive === true) {
                  if (this.customInv['ThirdPartyBooking_URL']) {
                    window.open(this.customInv['ThirdPartyBooking_URL'], '_blank');

                    NotifyAppComponent.displayToast(
                      'success',
                      'Successful operation',
                      'Your can now proceed and pay at practice'
                    );

                    this.consumerChoice.emit('T_OTH_G');
                  } else {
                    const refAppointment = RootAppComponent.dialog.open(AppointmentCreateCustomerComponent, {
                      data: {
                        invitationID: this.customInv['ID'],
                        cancelLabel: 'Close',
                        submitLabel: 'Submit',
                        displayReasonForVisiting: false,

                        isApplicationProceed: true,
                      },
                      disableClose: true,
                      width: '800px',
                      panelClass: 'noCard',
                    });

                    refAppointment.componentInstance.close.subscribe(() => {
                      refAppointment.close();

                      NotifyAppComponent.displayToast(
                        'success',
                        'Successful operation',
                        'Your can now proceed and pay at practice'
                      );

                      this.consumerChoice.emit('T_OTH_G');
                    });
                    refAppointment.componentInstance.result.subscribe((appointmentResult) => {
                      if (appointmentResult && appointmentResult.ID) {
                        refAppointment.close();
                        NotifyAppComponent.displayToast(
                          'success',
                          'Successful operation',
                          'Your can now proceed and pay at practice'
                        );

                        this.consumerChoice.emit('T_OTH_G');
                      }
                    });
                  }
                } else {
                  NotifyAppComponent.displayToast(
                    'success',
                    'Successful operation',
                    'Your can now proceed and pay at practice'
                  );

                  this.consumerChoice.emit('T_OTH_G');
                }
              }
            });
          }
        });
      } else {
        ref.close();
      }
    });
  }

  // discount

  confirmNotFincanceWithDiscount() {
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: new ConfirmDialog(
        'check_circle',
        'Proceed, and pay at the practice',
        'By selecting to proceed with your treatment and arranging to settle your bill at the practice using cash or credit card before ' +
          this.dataPipe.transform(this.customInv['CashDiscount.Expiry.Date'], null) +
          ', you are eligible for a discount of ' +
          this.currencyPipe.transform(this.customInv['CashDiscount.Offered.Amount'], null, null, null) +
          ' off your treatment. Do you wish to continue?',
        'Let me see my options again',
        'Yes, Proceed'
      ),
      width: '500px',
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val === true) {
        ref.close();
        ref.afterClosed().subscribe(() => {
          if (this.isModuleMerchantInformedConsentActive === true) {
            const ref1 = this.dialog.open(InformedConsentComponent, {
              data: this.merchantInformedConsent,
              panelClass: 'infConsent',
            });

            ref1.componentInstance.agreement.subscribe((data) => {
              this.informedConsentService.createInformedConsentPDF(this.informedConsent.ID).subscribe((documentID) => {
                if (data == true) {
                  this.patientService.notFinanceInivationWithDiscount(this.customInv['ID'], false).subscribe((res) => {
                    if (res) {
                      ref1.close();

                      ref1.afterClosed().subscribe((close) => {
                        if (this.isModuleAppointmentActive == true) {
                          if (this.customInv['ThirdPartyBooking_URL']) {
                            window.open(this.customInv['ThirdPartyBooking_URL'], '_blank');

                            NotifyAppComponent.displayToast(
                              'success',
                              'Successful operation',
                              'Your can now proceed and pay at practice'
                            );

                            this.consumerChoice.emit('T_OTH_D');
                          } else {
                            const refAppointment = RootAppComponent.dialog.open(AppointmentCreateCustomerComponent, {
                              data: {
                                invitationID: this.customInv['ID'],
                                cancelLabel: 'Close',
                                submitLabel: 'Submit',
                                displayReasonForVisiting: false,

                                isApplicationProceed: true,
                              },
                              disableClose: true,
                              width: '800px',
                              panelClass: 'noCard',
                            });

                            refAppointment.componentInstance.close.subscribe((res) => {
                              refAppointment.close();

                              NotifyAppComponent.displayToast(
                                'success',
                                'Successful operation',
                                'Your can now proceed and pay at practice'
                              );

                              this.consumerChoice.emit('T_OTH_D');
                            });
                            refAppointment.componentInstance.result.subscribe((res) => {
                              if (res && res.ID) {
                                refAppointment.close();

                                NotifyAppComponent.displayToast(
                                  'success',
                                  'Successful operation',
                                  'Your can now proceed and pay at practice'
                                );

                                this.consumerChoice.emit('T_OTH_D');
                              }
                            });
                          }
                        } else {
                          NotifyAppComponent.displayToast(
                            'success',
                            'Successful operation',
                            'Your can now proceed and pay at practice'
                          );

                          this.consumerChoice.emit('T_OTH_D');
                        }
                      });

                      this.patientService
                        .acceptMerchantInformedConsent(this.customInv['ID'], documentID)
                        .subscribe((res) => {
                          if (res) {
                            // NotifyAppComponent.displayToast(
                            //   "success",
                            //   "Informed Consent",
                            //   "You have accepted infomred consent"
                            // );
                          }
                        });
                    }
                  });
                }
              });
            });
          } else {
            this.patientService.notFinanceInivationWithDiscount(this.customInv['ID'], false).subscribe((response) => {
              if (response) {
                if (this.isModuleAppointmentActive === true) {
                  if (this.customInv['ThirdPartyBooking_URL']) {
                    window.open(this.customInv['ThirdPartyBooking_URL'], '_blank');

                    NotifyAppComponent.displayToast(
                      'success',
                      'Successful operation',
                      'Your can now proceed and pay at practice'
                    );

                    this.consumerChoice.emit('T_OTH_D');
                  } else {
                    const refAppointment = RootAppComponent.dialog.open(AppointmentCreateCustomerComponent, {
                      data: {
                        invitationID: this.customInv['ID'],
                        cancelLabel: 'Close',
                        submitLabel: 'Submit',
                        displayReasonForVisiting: false,

                        isApplicationProceed: true,
                      },
                      disableClose: true,
                      width: '800px',
                      panelClass: 'noCard',
                    });

                    refAppointment.componentInstance.close.subscribe(() => {
                      refAppointment.close();

                      NotifyAppComponent.displayToast(
                        'success',
                        'Successful operation',
                        'Your can now proceed and pay at practice'
                      );

                      this.consumerChoice.emit('T_OTH_D');
                    });
                    refAppointment.componentInstance.result.subscribe((appointmentResult) => {
                      if (appointmentResult && appointmentResult.ID) {
                        refAppointment.close();

                        NotifyAppComponent.displayToast(
                          'success',
                          'Successful operation',
                          'Your can now proceed and pay at practice'
                        );

                        this.consumerChoice.emit('T_OTH_D');
                      }
                    });
                  }
                } else {
                  NotifyAppComponent.displayToast(
                    'success',
                    'Successful operation',
                    'Your can now proceed and pay at practice'
                  );

                  this.consumerChoice.emit('T_OTH_D');
                }
              }
            });
          }
        });
      } else {
        ref.close();
      }
    });
  }


  getPersonalInfo() {
    if (this.customInv && this.customInv['Prospect_key']) {
      this.lookupService.getLookup('SimpleLookup', 'Salutation').subscribe((res) => {
        this.titles = res;
      });
    }
  }

  getAdress(adr) {
    if (adr) {
      this.mySuburb = adr.suburb;
      this.myState = adr.state;
      this.myPostCode = adr.postCode;
      this.myStreetNumber = adr.streetNumber;
      this.myStreetName = adr.streetName;
      this.myUnitNumber = adr.unitNumber;
      this.myStreetType = adr.streetType;
      this.myLatitude = adr.latitude;
      this.myLongitude = adr.longitude;
    }
  }

  getMobile(m) {
    this.mobile = m;
  }

  getPhone(p) {
    this.phone = p;
  }

  getEmail(e) {
    this.email = e;
  }

  isAdressValidEvent(v) {
    this.isAddressValid = v || false;
  }

  validateMobile(v) {
    this.isMobileValid = v;
  }

  validatePhone(v) {
    this.isHomePhoneValid = v;
  }

  validateEmail(v) {
    this.isEmailValid = v;
  }

  onCompleteAll(fileID) {
    if (fileID && fileID[0] && this.customInv && this.customInv['Prospect_key']) {
      const payload = {
        fileID: fileID[0],
      };


      this.patientService.uploadProfilePic(this.customInv['Prospect_key'], payload).subscribe((res) => {

        this.isFile = true;

      });
    }
  }

  selectProduct(product) {
    if (product && product.ID) {
      const refProduct = this.dialog.open(ProductCalculatorComponent, {
        data: {
          isTop: true,
          dentalInfo: true,
          isProceedButton: true,
          moreInfo: false,
          ProductID: product.ID,
          dynamicAmount: false,
          amount: this.customInv['Treatment.Value'],
        },
        width: '850px',
      });

      refProduct.componentInstance.close.subscribe(() => {
        refProduct.close();
      });

      refProduct.componentInstance.proceedEvent.subscribe((estimation) => {
        const payload = {
          frequency: Number(estimation['frequency']),
          productID: product.ID,
          duration: Number(estimation['duration']),
          patientContribution: Number(estimation['patientContribution']) || null,
          securityCode: null,
          securityCodeID: null,
        };

        refProduct.close();
        refProduct.afterClosed().subscribe(() => {
          if (this.isModuleMerchantInformedConsentActive === true) {
            const ref = this.dialog.open(InformedConsentComponent, {
              data: this.merchantInformedConsent,
              panelClass: 'infConsent',
            });

            ref.componentInstance.agreement
              .pipe(mergeMap(() => this.utilService.getServerDate()))
              .subscribe((currentDate) => {
                this.informedConsent['InformedConsent.Given.When'] = currentDate;
                this.informedConsent['InformedConsent.Given.Flag'] = '1';

                this.informedConsentService
                  .createInformedConsentPDF(this.informedConsent.ID)
                  .subscribe((documentID) => {
                    if (documentID) {
                      ref.close();

                      if (this.isPromoterOrAdmin == true) {
                        this.invitationService
                          .proceedDental(this.invitationID, payload, false, this.sessionType)
                          .subscribe((res) => {
                            if (res) {
                              NotifyAppComponent.displayToast(
                                'success',
                                'Successfull selected',
                                'You will be redirecting to a page to finish you application '
                              );

                              this.redirect(product);
                            }
                          });
                      } else if (
                        this.isModulePatienCardtSecurityActive != true &&
                        this.sessionType != 'merchant' &&
                        this.sessionType != 'merchant-admin'
                      ) {
                        this.invitationService
                          .proceedDental(this.invitationID, payload, false, this.sessionType)
                          .subscribe((res) => {
                            if (res) {
                              NotifyAppComponent.displayToast(
                                'success',
                                'Successfull selected',
                                'You will be redirecting to a page to finish you application '
                              );

                              this.redirect(product);
                            }
                          });
                      } else {
                        if (this.sessionType == 'merchant' || this.sessionType == 'merchant-admin') {
                          this.merchantService
                            .canAssitPatientProduct(this.customInv['Merchant_Key'], product.ID, this.sessionType)
                            .subscribe((res) => {
                              if (res) {
                                this.proceedWithSMSauthentication(payload, product);
                              } else {
                                const refError = RootAppComponent.dialog.open(ErrorModalComponent, {
                                  data: {
                                    content:
                                      `<p><strong>      You do not have access to make this decision on behalf of your patient.</strong></p>` +
                                      `<p style="margin:5px" >  Contact the help desk to request permission.</p>` +
                                      `<p style="margin:5px" > Alternatively, your patient can proceed with an application for finance themselves by clicking on the links sent to them by email and SMS.
                                  </p>` +
                                      `<p style="margin:5px" >Questions? Please contact us. </p>`,
                                    title: 'Sorry!',
                                    displayContact: true,
                                  },

                                  width: '700px',
                                });
                                const sub = refError.componentInstance.closeModal.subscribe((data) => {
                                  refError.close();
                                });
                              }
                            });
                        } else {
                          this.proceedWithSMSauthentication(payload, product);
                        }
                      }

                      this.patientService
                        .acceptMerchantInformedConsent(this.customInv['ID'], documentID)
                        .subscribe((res) => {
                          if (res) {
                            // NotifyAppComponent.displayToast(
                            //   "success",
                            //   "Informed Consent",
                            //   "You have accepted infomred consent"
                            // );
                          }
                        });
                    }
                  });
              });
          } else {
            if (this.isPromoterOrAdmin === true) {
              this.invitationService
                .proceedDental(this.invitationID, payload, false, this.sessionType)
                .subscribe((proceedDental) => {
                  if (proceedDental) {
                    NotifyAppComponent.displayToast(
                      'success',
                      'Successfull selected',
                      'You will be redirecting to a page to finish you application '
                    );

                    this.redirect(product);
                  }
                });
            } else if (
              this.isModulePatienCardtSecurityActive !== true &&
              this.sessionType !== 'merchant' &&
              this.sessionType !== 'merchant-admin'
            ) {
              this.invitationService
                .proceedDental(this.invitationID, payload, false, this.sessionType)
                .subscribe((proceedDental) => {
                  if (proceedDental) {
                    NotifyAppComponent.displayToast(
                      'success',
                      'Successfull selected',
                      'You will be redirecting to a page to finish you application '
                    );

                    this.redirect(product);
                  }
                });
            } else {
              if (this.accessByMerchant === true) {
                this.merchantService
                  .canAssitPatientProduct(this.customInv['Merchant_Key'], product.ID, this.sessionType)
                  .subscribe((canAssistPatientProduct) => {
                    if (canAssistPatientProduct) {
                      this.proceedWithSMSauthentication(payload, product);
                    } else {
                      const refError = RootAppComponent.dialog.open(ErrorModalComponent, {
                        data: {
                          content:
                            `<p><strong>      You do not have access to make this decision on behalf of your patient.</strong></p>` +
                            `<p style="margin:5px" >  Contact the help desk to request permission.</p>` +
                            `<p style="margin:5px" > Alternatively, your patient can proceed with an application for finance themselves by clicking on the links sent to them by email and SMS.
                          </p>` +
                            `<p style="margin:5px" >Questions? Please contact us. </p>`,
                          title: 'Sorry!',
                          displayContact: true,
                        },

                        width: '700px',
                      });
                      refError.componentInstance.closeModal.subscribe(() => {
                        refError.close();
                      });
                    }
                  });
              } else {
                this.proceedWithSMSauthentication(payload, product);
              }
            }
          }
        });
      });
    }
  }

  proceedWithSMSauthentication(_payload, _product) {
    const payload = _payload;
    const product = _product;

    const description =
      `<p>You need <strong>${this.customInv['FirstName']} ${this.customInv['LastName']}</strong>'s   ` +
      ` consent to assist with the application process.</p>` +
      `<p>If your patient has requested your assistance,   ` +
      `click the button below and a secure code will be sent to your patient's mobile <strong> :  ` +
      `${this.customPhonePipe.transform(this.customInv['Mobile'])} </strong>. </p>` +
      `<p>Once you have the code, you'll need to enter it on the next screen. </p>` +
      `<p><strong>IMPORTANT - Only request a code if the patient has asked for your assistance.</strong></p>`;

    const ref2 = RootAppComponent.dialog.open(SecurityCodeInfoComponent, {
      data: {
        mobileNumber: this.customInv['Mobile'],
        description,
      },
      width: '700px',
    });

    ref2.componentInstance.closeModal.subscribe(() => {
      ref2.close();
    });
    ref2.componentInstance.onConfirm.subscribe((res) => {
      ref2.close();
      ref2.afterClosed().subscribe(() => {
        if (res === true) {
          let securityCodeID;
          let securityCode;
          this.invitationService.requestInvitationCode(this.customInv['ID']).subscribe((invitationCode) => {
            if (invitationCode) {
              const smsSent = true;
              let emailSent = false;
              if (invitationCode && invitationCode['Send.Email'] === '1') {
                emailSent = true;
              }
              securityCodeID = invitationCode['ID'];
              const ref = RootAppComponent.dialog.open(SecurityCodeInput, {
                data: {
                  smsSent,
                  emailSent,
                },
                width: '600px',
                panelClass: 'noCard',
              });
              ref.componentInstance.closeModal.subscribe(() => {
                ref.close();
              });
              ref.componentInstance.sendCode.subscribe(() => {
                this.invitationService
                  .requestInvitationCode(this.customInv['ID'])
                  .subscribe((requestedInvitationCode) => {
                    securityCodeID = requestedInvitationCode['ID'];
                    NotifyAppComponent.displayToast('success', 'Successfull', 'Authorisation code is sent again');
                  });
              });
              ref.componentInstance.getCode.subscribe((code) => {
                if (code) {
                  securityCode = code;

                  payload.securityCode = securityCode;
                  payload.securityCodeID = securityCodeID;

                  this.invitationService
                    .proceedDental(this.invitationID, payload, false, this.sessionType)
                    .subscribe((proceedDental) => {
                      if (proceedDental) {
                        if (this.accessByMerchant !== true) {
                          this.invitationService.markAsViewed(this.customInv['ID']).subscribe(() => {});
                        }

                        ref.close();

                        NotifyAppComponent.displayToast(
                          'success',
                          'Successfull selected',
                          'You will be redirecting to a page to finish you application '
                        );

                        this.redirect(product);
                      }
                    });
                }
              });
            }
          });
        }
      });
    });
  }

  redirect(product) {
    const p = {
      choice: 'P_APP',
      productID: null,
    };

    if (product && product.ID) {
      p.productID = product.ID;
    }
    if (Settings.global['isWakandaIntegrated'] && Settings.global['isWakandaIntegrated'] === true) {
      if (product['SubType'] === 'Payment Plan') {
        let ppLink = Settings.global['wakandaPPLink'];
        ppLink = ppLink.replace('_id_', this.invitationID);

        debugger

        if (this.accessByMerchant !== true) {
          window.open(`${Settings.global['publicSiteFrontendLink']}/thank-you-pp/customer-card?link=${encodeURIComponent(ppLink)}`, '_parent').focus();
        } else {
          this.consumerChoice.emit(p);
          window.open(`${Settings.global['publicSiteFrontendLink']}/thank-you-pp/customer-card?link=${encodeURIComponent(ppLink)}`, '_blank');
        }
      } else {
        let laLink = Settings.global['wakandaLALink'];
        laLink = laLink.replace('_id_', this.invitationID);

        if (this.accessByMerchant !== true) {
          window.open(`${Settings.global['publicSiteFrontendLink']}/thank-you-la/customer-card?link=${encodeURIComponent(laLink)}`, '_parent').focus();
        } else {
          this.consumerChoice.emit(p);
          window.open(`${Settings.global['publicSiteFrontendLink']}/thank-you-la/customer-card?link=${encodeURIComponent(laLink)}`, '_blank');
        }
      }
    } else {
      if (product['SubType'] === 'Payment Plan') {
        let ppLink = Settings.global['wakandaPPLink'];
        ppLink = ppLink.replace('_id_', this.invitationID);



        if (this.accessByMerchant !== true) {
          window.open(`${Settings.global['publicSiteFrontendLink']}/thank-you-pp/customer-card?link=${encodeURIComponent(ppLink)}`, '_parent').focus();
        } else {
          this.consumerChoice.emit(p);
          window.open(`${Settings.global['publicSiteFrontendLink']}/thank-you-pp/customer-card?link=${encodeURIComponent(ppLink)}`, '_blank');
        }
      } else {
        let laLink = Settings.global['wakandaLALink'];
        laLink = laLink.replace('_id_', this.invitationID);

        if (this.accessByMerchant !== true) {
          window.open(`${Settings.global['publicSiteFrontendLink']}/thank-you-la/customer-card?link=${encodeURIComponent(laLink)}`, '_parent').focus();
        } else {
          this.consumerChoice.emit(p);
          window.open(`${Settings.global['publicSiteFrontendLink']}/thank-you-la/customer-card?link=${encodeURIComponent(laLink)}`, '_blank');
        }
      }
    }
  }

  getViewedDate(document, viewedDocuments) {
    const viewedDocumentIndex = viewedDocuments.findIndex((viewedDocument) => document.ID === viewedDocument.id);

    if (viewedDocumentIndex > -1) {
      return viewedDocuments[viewedDocumentIndex].viewedDate;
    } else {
      return null;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 1023) {
      this.headPos = 'below';
    }
    if (event.target.innerWidth > 1023) {
      this.headPos = 'above';
    }
  }

  createMedicalHistory() {
    if (this.customInv && this.customInv['Prospect_key'] && this.customInv['Merchant_Key']) {
      const url =
        window.location.origin +
        '/customer/(page:medical/' +
        this.customInv['Prospect_key'] +
        '/' +
        this.customInv['Merchant_Key'] +
        ')';

      window.open(url, '_blank').focus();
    }
  }
}
