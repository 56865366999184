<div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="listDB.data.length == 0">
  <div>
    <h1 class="dataHeader">
      <!-- Contracts -->
      Targets Not Reached
      <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
    </h1>
    <mat-card class="list-card mb">
      <!-- Search -->
      <div class="row clearfix searchArea primary-gradient-img">
        <div class="row justify flex">
          <div class="qtr-width flex">
            <mat-icon class="schIcon mr mt">search</mat-icon>
            <mat-form-field class="schSel" appearance="outline">
              <mat-label>Add Content Filters</mat-label>
              <mat-select class="schSel" [formControl]="searchVals" matInput placeholder="Add Content Filters" multiple>
                <mat-select-trigger>
                  {{ searchVals.value ? searchVals.value[0] : '' }}
                  <span class="additional-selection" *ngIf="searchVals.value?.length > 1">
                    (+{{ searchVals.value.length - 1 }} others)
                  </span>
                </mat-select-trigger>
                <mat-option *ngFor="let searchVal of searchValList" [value]="searchVal">{{ searchVal }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="full-width clearfix flex animated fadeInDown" *ngIf="searchVals.value?.length > 0">
            <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Patient Name') !== -1">
              <mat-label>{{"KEYWORD.patient" | translate | titlecase}} Name</mat-label>
              <input
                class="clearfix"
                (input)="setFilter($event.target.value, 'Who.Name')"
                name="PatientName"
                [placeholder]="('KEYWORD.patient' | translate | titlecase) + ' Name'"
                matInput
              />
            </mat-form-field>
            <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Operator Name') !== -1">
              <mat-label>Operator Name</mat-label>
              <input
                class="clearfix"
                (input)="setFilter($event.target.value, 'Operator_Name')"
                name="OperatorName"
                placeholder="Operator Name"
                matInput
              />
            </mat-form-field>

            <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Type') !== -1">
              <mat-label>Note Type</mat-label>
              <mat-select
                class="full-width"
                (selectionChange)="setFilter($event.value, 'Action')"
                name="Type"
                placeholder="Note Type"
              >
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option
                  *ngFor="let e of listDB.data | objectPropertyValue: 'Action' | unique | without: ['', null] | orderBy"
                  [value]="e"
                >
                  {{ e }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="ml timeOutput clearfix" *ngIf="dateFrom != null || dateTo != null">
            <mat-icon class="pull-left mt">schedule</mat-icon>
            <div class="pull-right">
              <p class="small rm-mb">
                <span *ngIf="dateFrom"
                >From:
                  <strong>{{ dateFrom | customDateTime }} </strong>
                </span>
              </p>
              <p class="small rm-mt">
                <span *ngIf="dateTo">
                  To:
                  <strong>{{ dateTo | customDateTime }}</strong>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Table -->
      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
        <!-- ID Column -->

        <ng-container cdkColumnDef="PatientName">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>{{ "KEYWORD.patient" | translate | titlecase }}Name
          </mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openPatientView(row['Card_key'])"
            matTooltip="{{ row['Card.Name'] }}"
          >
            {{ row['Card.Name'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="CampaignLabel">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Campaign Label</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openPatientView(row['Card_key'])" matTooltip="{{ row['Label'] }}">
            {{ row['Label'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Reason">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Reason</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openPatientView(row['Card_key'])"
            matTooltip="{{ row['Reason.Label'] }}"
          >
            {{ row['Reason.Label'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button *ngIf="row['Who.key']" (click)="openPatientView(row['Card_key'])" mat-menu-item>
                  <mat-icon>person</mat-icon>
                  <span>{{ 'Patient View' }}</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginator
        [length]="dataSource.filteredData.length"
        [pageIndex]="0"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
      >
      </mat-paginator>
    </mat-card>
  </div>
</div>
<div *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <app-empty-list-message></app-empty-list-message>
</div>
