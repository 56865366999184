<ipv-title-modal [title]="'Services'" [hideAllDefaultButtons]="true" [icon]="''">
  <ng-container content>
    <div class="row clearfix">
      <ng-container *ngFor="let service of services">
        <h3 class="primary" style="text-align: left">{{ service.serviceGroup | titlecase }}</h3>

        <ng-container *ngFor="let serviceItem of service.services">
          <mat-checkbox
            class="text-wrap"
            [(ngModel)]="selectedServices[serviceItem]"
            [value]="serviceItem"
            style="display: block; text-align: left"
          >
            <span style="color: black"> {{ serviceItem | titlecase }} </span>
          </mat-checkbox>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ipv-title-modal>

<mat-dialog-actions style="flex-direction: row-reverse">
  <button class="button-save" [color]="'primary'" (click)="save()" mat-raised-button>Save</button>
</mat-dialog-actions>
