import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'ipv-config-error-modal',
  templateUrl: './problem-modal.component.html',
  styleUrls: ['./problem-modal.component.css'],
})
export class ProblemModalComponent implements OnInit {
  @Output() closeModal = new EventEmitter();

  message;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.message = data.message;
    }
  }

  ngOnInit() {}

  close() {
    this.closeModal.emit();
  }
}
