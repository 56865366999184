/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./merchant-health-funds-overview.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../merchant-health-fund-list/merchant-health-fund-list.component.ngfactory";
import * as i3 from "../merchant-health-fund-list/merchant-health-fund-list.component";
import * as i4 from "../../merchant/shared/merchant.service";
import * as i5 from "./merchant-health-funds-overview.component";
var styles_MerchantHealthFundsOverviewComponent = [i0.styles];
var RenderType_MerchantHealthFundsOverviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MerchantHealthFundsOverviewComponent, data: {} });
export { RenderType_MerchantHealthFundsOverviewComponent as RenderType_MerchantHealthFundsOverviewComponent };
export function View_MerchantHealthFundsOverviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-merchant-health-fund-list", [["class", "full-width clearfix"]], null, null, null, i2.View_MerchantHealthFundListComponent_0, i2.RenderType_MerchantHealthFundListComponent)), i1.ɵdid(1, 114688, null, 0, i3.MerchantHealthFundListComponent, [i4.MerchantService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_MerchantHealthFundsOverviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "merchant-health-funds-overview", [], null, null, null, View_MerchantHealthFundsOverviewComponent_0, RenderType_MerchantHealthFundsOverviewComponent)), i1.ɵdid(1, 114688, null, 0, i5.MerchantHealthFundsOverviewComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MerchantHealthFundsOverviewComponentNgFactory = i1.ɵccf("merchant-health-funds-overview", i5.MerchantHealthFundsOverviewComponent, View_MerchantHealthFundsOverviewComponent_Host_0, {}, {}, []);
export { MerchantHealthFundsOverviewComponentNgFactory as MerchantHealthFundsOverviewComponentNgFactory };
