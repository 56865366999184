import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AppointmentUtilClass } from '../shared/appointmentUtil';

@Component({
  selector: 'app-appointment-list-overview',
  templateUrl: './appointment-list-overview.component.html',
  styleUrls: ['./appointment-list-overview.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class AppointmentListOverviewComponent implements OnInit {
  isPromoterOrAdmin = false;
  dateFrom;
  dateTo;
  _dateFrom;
  _dateTo;
  _merchantID = null;
  merchantID = 'none';
  dateBookingFrom;
  _dateBookingFrom;
  dateBookingTo;
  _dateBookingTo;
  statusGroup = 'pending';
  hideExtraComponents = false;
  isDisplayMerchant = true;
  dentistID;
  groupFilter = 'merchant';

  utils = new UtilsClass();

  searchApplied = false;

  sendNull = true;
  selectedRange = 'days90';

  appointmentUtil = new AppointmentUtilClass();

  constructor(private authenticationService: AuthenticationService, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      this.authenticationService.getCurrentPractice().subscribe((m) => {
        if (m && m.ID) {
          this.merchantID = m.ID;
        }
      });
    });
  }

  getMerchant(d) {
    this._merchantID = d;
    this.changeDetectorRef.detectChanges();
  }

  getDateFrom(d) {
    this._dateFrom = d;
  }

  getDateTo(d) {
    this._dateTo = d;
  }

  getDateSecondFrom(d) {
    this._dateBookingFrom = d;
  }

  getDateSecondTo(d) {
    this._dateBookingTo = d;
  }

  applySearch(result) {
    this.merchantID = result.merchantID;
    this.dateFrom = result.dateFrom;
    this.dateTo = result.dateTo;

    this.searchApplied = true;
    this.changeDetectorRef.detectChanges();
  }
}
