import { animate, state, style, transition, trigger } from '@angular/animations';
import { DOCUMENT, Location } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnInit, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { Settings } from '../../../shared/types/settings';
import { CustomerProspectService } from '../../customer-prospect/shared/customerProspect.service';
import { MedicalHistoryService } from '../../medical-history/shared/medical-history.service';
import { medicalHistoryUtil } from '../../medical-history/shared/medicalHistoryUtil';
import { NotesModal, NotesModalConfig } from '../../notes/modals/notes-modal/notes.modal';
import { ProductService } from '../../product/shared/product.service';
import { ProductUtil } from '../../product/shared/productUtil.type';

@Component({
  selector: 'app-customer-prospect-profile',
  templateUrl: './customer-prospect-profile.component.html',
  styleUrls: ['./customer-prospect-profile.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: '*' }))),
    ]),
  ],
})
export class CustomerProspectProfileComponent implements OnInit {
  @Input()
  patientID;

  patient;

  tabs;

  itemID;

  isExpanded = false;

  @ViewChildren('sidenav') sidenav: MatSidenav;
  @ViewChildren('sticky') menuElement: ElementRef;

  navMode = 'side';
  openFlag = true;

  stickyStuck = false;
  menuPosition: any;

  isPromoterOrAdmin = false;
  medicalHistoryID;
  isSmoke = false;
  isDrink = false;
  hasImmediateProblem = false;

  medicalHistoryUtil = new medicalHistoryUtil();
  illnessIconList = [];
  allergyIconList = [];

  showGobackBtn = false;

  birthday;

  isModuleNoteFeaturesActive = Settings.global['isModuleNoteFeaturesActive'];
  isModuleTreatmentPlanActive = Settings.global['isModuleTreatmentPlanActive'];
  isModulePatientHistoryActive = Settings.global['isModulePatientHistoryActive'];

  financedProductGroup;
  productUtil = new ProductUtil();

  sessionType = 'merchant';

  @Input()
  merchantID = localStorage.getItem('selectedMerchant');

  constructor(
    private authenticationService: AuthenticationService,
    private customerProspectService: CustomerProspectService,
    private activeRoute: ActivatedRoute,
    private medicalHistoryService: MedicalHistoryService,
    private location: Location,
    private utilService: UtilsService,
    private router: Router,
    private dialog: MatDialog,
    private productService: ProductService,
    @Inject(DOCUMENT) document
  ) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      this.utilService.getCurrentAccess().subscribe((access) => {
        if (access) {
          this.isModuleNoteFeaturesActive = access['isModuleNoteFeaturesActive'];
          this.isModuleTreatmentPlanActive = access['isModuleTreatmentPlanActive'];
          this.isModulePatientHistoryActive = access['isModulePatientHistoryActive'];
        }

        this.activeRoute.params.subscribe((params) => {
          if (params['patientID']) {
            this.patientID = params['patientID'];
          }

          if (params['merchantID']) {
            this.merchantID = params['merchantID'];
          }

          if (params['tabs']) {
            this.tabs = params['tabs'];
            this.showGobackBtn = true;
          }

          if (params['itemID']) {
            this.itemID = params['itemID'];
            this.showGobackBtn = true;
          }

          this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
            this.isPromoterOrAdmin = res;

            if (this.patientID) {
              this.customerProspectService
                .getCustomerProspectDetails(this.patientID, {}, this.sessionType)
                .subscribe((res) => {
                  if (res) {
                    this.patient = res;

                    const payload = {
                      cardID: this.patient['ID'],
                    };

                    this.medicalHistoryService.getMedicalHistoryList(payload, this.sessionType).subscribe((res) => {
                      if (res && res.length > 0) {
                        this.medicalHistoryID = res[0]['ID'];
                        this.isSmoke = res[0]['IsSmoking'] == '1' ? true : false;
                        this.isDrink = res[0]['DrinkWine'] == '1' ? true : false;
                        this.hasImmediateProblem = res[0]['HasDentalProblem'] == '1' ? true : false;

                        const payload = {
                          medicalHistoryID: res[0]['ID'],
                        };
                        this.medicalHistoryService.getIllnessList(payload, this.sessionType).subscribe((res) => {
                          if (res && res.length > 0) {
                            this.illnessIconList = this.medicalHistoryUtil.getIllnessIcon(res);
                          }
                        });

                        this.medicalHistoryService.getAllergyList(payload, this.sessionType).subscribe((res) => {
                          if (res && res.length > 0) {
                            this.allergyIconList = this.medicalHistoryUtil.getAllergyIcon(res);
                          }
                        });
                      }
                    });
                  }
                });
            }
          });
        });
      });
    });
    // scroll listener
    window.addEventListener('scroll', this.scroll, true); // third parameter
  }

  // remove scroll listener
  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  scroll = (event: any): void => {
    // Here scroll is a variable holding the anonymous function
    // this allows scroll to be assigned to the event during onInit
    // and removed onDestroy
    const number = event.srcElement.scrollTop;
    if (number > 65) {
      this.stickyStuck = true;
    } else {
      this.stickyStuck = false;
    }
  };

  collapseSidePanel() {
    this.isExpanded = !this.isExpanded;
    setTimeout(function () {
      window.dispatchEvent(new Event('resize'));
    }, 320);
  }

  goBack() {
    this.location.back();
  }

  getMerchantID(e) {
    if (e) {
      this.merchantID = e;

      const p1 = {
        isFinance: true,
      };

      if (this.isPromoterOrAdmin == true) {
        p1['merchantID'] = this.merchantID;
      }
      this.productService.getMerchantProductGroup(p1, this.isPromoterOrAdmin).subscribe((res) => {
        this.financedProductGroup = res;
      });
    } else {
      this.authenticationService.getCurrentUser().subscribe((res) => {
        if (res && res.success && res.data['ContactforCard_key']) {
          const p2 = {
            isFinance: true,
          };
          if (this.isPromoterOrAdmin == true) {
            p2['merchantID'] = res.data['ContactforCard_key'];
          }
          this.productService.getMerchantProductGroup(p2, this.isPromoterOrAdmin).subscribe((res) => {
            this.financedProductGroup = res;
          });
        }
      });
    }
  }

  getBirthday(e) {
    if (e) {
      this.birthday = e;
    }
  }

  createNote(patientID: string) {
    this.dialog.open<NotesModal, NotesModalConfig>(NotesModal, {
      data: {
        patientID,
        parentRoute: 'merchant',
        noteListMode: 'create',
        relationshipLink: {
          label: 'patient',
          route: `customer-profile/${patientID}/patient`,
          itemID: patientID,
        },
      },
    });
  }

  viewNotes(patientID: string) {
    this.dialog.open<NotesModal, NotesModalConfig>(NotesModal, {
      data: {
        parentRoute: 'merchant',
        patientID,
      },
    });
  }

  linkToInvitation(p) {
    if (p && p['GroupCode']) {
      const payload = {
        productGroupCode: p['GroupCode'],
      };

      if (this.patient['TableName'] == 'Customer') {
        this.customerProspectService.linkCustomerToInv(this.patient['ID'], payload).subscribe((res) => {
          if (res.success) {
            this.router.navigate(['/merchant', { outlets: { page: ['invitation-create', res.data] } }]);
          }
        });
      } else if (this.patient['TableName'] == 'Prospect') {
        this.customerProspectService.linkProspectToInv(this.patient['ID'], payload).subscribe((res) => {
          if (res.success) {
            this.router.navigate(['/merchant', { outlets: { page: ['invitation-create', res.data] } }]);
          }
        });
      }
    }
  }

  dial(phone) {
    window.location.href = 'tel:' + phone;
  }

  email(email) {
    window.location.href = 'mailto:' + email;
  }

  inviteMedicalHistory() {
    if (this.patientID) {
      this.router.navigate([
        '/merchant',
        { outlets: { page: ['medical-history', 'medical-history-invitation-create', this.patientID] } },
      ]);
    }
  }
}
