<aw-wizard #wizard navBarLayout="large-filled-symbols">
  <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Supplier Details">
    <!-- Step 1  -->
    <div class="container" id="step-1">
      <form #beneficiaryForm="ngForm">
        <div class="row clearfix flex">
          <div class="qtr-width">
            <div class="col-left">
              <mat-card class="mat-card uploadBlock card">
                <!--  Picture Upload -->
                <div class="row clearfix image">
                  <div class="notUploaded" *ngIf="isFile == false && isEdit == false">
                    <app-ep-document-view-image
                      class="clearfix full-width notYetUploaded"
                      [defaultPicture]="
                        'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cloud-upload-alt-solid.png)'
                      "
                    >
                    </app-ep-document-view-image>
                  </div>

                  <div class="displayUploadImg text-center" *ngIf="isFile == false && isEdit == true">
                    <app-ep-document-view-image
                      class="clearfix full-width profile-pic-container"
                      [defaultPicture]="
                        'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cloud-upload-alt-solid.png)'
                      "
                      [link]="profileLink"
                      [hideZoomButton]="false"
                    >
                    </app-ep-document-view-image>
                  </div>

                  <div class="displayUploadImg" *ngIf="isFile == true">
                    <img class="display-image-uploaded" [src]="src"/>
                  </div>
                </div>
                <div class="row clearfix" *ngIf="isAdminOrPromoter == true">
                  <h4 class="sr-title sm-h4 rm-mb">Upload Supplier Logo</h4>
                  <p class="small rm-m">
                    To upload a new supplier logo image, please drag your <strong>jpg or png file</strong> into the grey
                    area below.
                  </p>
                  <app-file-uploader
                    class="text-center clearfix full-width"
                    [allowedExtensionTypes]="['image']"
                    [isFixedAspectRatio]="true"
                    [isImageCropping]="true"
                    [canSkip]="false"
                    [limit]="1"
                    [uploadDirectly]="true"
                    [isDragable]="true"
                    (onCompleteAll)="onCompleteAll($event)"
                  >
                  </app-file-uploader>
                </div>
              </mat-card>
            </div>
          </div>

          <mat-card class="thr-qtr-width card">
            <div class="row clearfix">
              <!-- <div class="col-right"> -->

              <h3 class="sr-title sm-h3 rm-mt">Subsidiary Details</h3>
              <div class="row clearfix flex full-width">
                <div class="icons-col">
                  <mat-icon>business</mat-icon>
                </div>
                <div class="row-content-col">
                  <div class="row clearfix flex">
                    <!-- DISTRIBUTOR OR MANUFACTURER -->
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Manufacturer or Distributor</mat-label>
                      <mat-select
                        class=""
                        [(ngModel)]="beneficiary.cardType"
                        [disabled]="isAdminOrPromoter != true"
                        placeholder=""
                        name="type"
                        required
                        readonly
                      >
                        <mat-option *ngFor="let t of cardTypes" [value]="t">
                          {{ t }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <!-- <mat-form-field class="full-width" appearance="outline">
                    <input matInput placeholder="Trading as" type="text" name="tradinAs"
                      [(ngModel)]="beneficiary.tradingAs" required>
                  </mat-form-field>

                  <mat-form-field class="full-width" appearance="outline">
                    <input matInput placeholder="Label" type="text" name="label" [(ngModel)]="beneficiary.label"
                      required>
                  </mat-form-field> -->
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Entity Type</mat-label>
                      <mat-select
                        class=""
                        [(ngModel)]="beneficiary.cardType"
                        [disabled]="isAdminOrPromoter != true"
                        placeholder=""
                        name="type"
                        required
                      >
                        <mat-option *ngFor="let t of cardTypes" [value]="t">
                          {{ t }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="row clearfix flex full-width">
                <div class="icons-col">
                  <mat-icon>perm_identity</mat-icon>
                </div>

                <div
                  class="row-content-col"
                  *ngIf="
                    beneficiary.cardType === 'Consumer - Individual' ||
                    beneficiary.cardType === 'Commercial - Sole-Trader'
                  "
                >
                  <div class="row clearfix flex">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Title</mat-label>
                      <mat-select
                        class="full-width"
                        [(ngModel)]="beneficiary.title"
                        [disabled]="isAdminOrPromoter != true"
                        placeholder=""
                        name="title"
                        required
                      >
                        <mat-option *ngFor="let title of titles" [value]="title.Label">
                          {{ title.Label }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>First Name</mat-label>
                      <input
                        [(ngModel)]="beneficiary.firstName"
                        [disabled]="isAdminOrPromoter != true"
                        name="firstName"
                        matInput
                        placeholder=" "
                        required
                      />
                    </mat-form-field>
                    <!-- Middle Name Input -->
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Middle Name</mat-label>
                      <input
                        [(ngModel)]="beneficiary.midleName"
                        [disabled]="isAdminOrPromoter != true"
                        matInput
                        placeholder=" "
                        name="midleName"
                      />
                    </mat-form-field>
                    <!-- Last Name Input -->
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Surname</mat-label>
                      <input
                        class="mat-block"
                        [(ngModel)]="beneficiary.lastName"
                        [disabled]="isAdminOrPromoter != true"
                        matInput
                        placeholder=" "
                        name="lastName"
                        required
                      />
                    </mat-form-field>
                  </div>

                  <div class="row clearfix flex">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Trading as</mat-label>
                      <input
                        [(ngModel)]="beneficiary.tradingAs"
                        [disabled]="isAdminOrPromoter != true"
                        matInput
                        placeholder=""
                        type="text"
                        name="tradinAs"
                        required
                      />
                    </mat-form-field>
                  </div>
                </div>

                <div class="row-content-col" *ngIf="beneficiary.cardType === 'Commercial - Company'">
                  <div class="row clearfix flex">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Company Type</mat-label>
                      <mat-select
                        class="full-width"
                        [(ngModel)]="beneficiary.companyType"
                        [disabled]="isAdminOrPromoter != true"
                        placeholder=""
                        name="companyType"
                        required
                      >
                        <mat-option *ngFor="let c of companyTypes" [value]="c.Label">
                          {{ c.Label }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Name</mat-label>
                      <input
                        [(ngModel)]="beneficiary.supplierName"
                        [disabled]="isAdminOrPromoter != true"
                        matInput
                        placeholder=""
                        type="text"
                        name="name"
                        required
                      />
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Trading as</mat-label>
                      <input
                        [(ngModel)]="beneficiary.tradingAs"
                        [disabled]="isAdminOrPromoter != true"
                        matInput
                        placeholder=""
                        type="text"
                        name="tradinAs"
                        required
                      />
                    </mat-form-field>
                  </div>
                  <div class="row clearfix flex">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label> ABN</mat-label>
                      <input
                        [(ngModel)]="beneficiary.abn"
                        [disabled]="isAdminOrPromoter != true"
                        matInput
                        placeholder=""
                        name="ABN"
                      />
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label> ACN</mat-label>
                      <input
                        [(ngModel)]="beneficiary.acn"
                        [disabled]="isAdminOrPromoter != true"
                        matInput
                        placeholder=""
                        name="ACN"
                      />
                    </mat-form-field>
                  </div>
                </div>

                <div class="row-content-col" *ngIf="beneficiary.cardType === 'Commercial - Partnership'">
                  <div class="row clearfix flex">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Name</mat-label>
                      <input
                        [(ngModel)]="beneficiary.supplierName"
                        [disabled]="isAdminOrPromoter != true"
                        matInput
                        placeholder=""
                        type="text"
                        name="name"
                        required
                      />
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Trading as</mat-label>
                      <input
                        [(ngModel)]="beneficiary.tradingAs"
                        [disabled]="isAdminOrPromoter != true"
                        matInput
                        placeholder=""
                        type="text"
                        name="tradinAs"
                        required
                      />
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label> ABN</mat-label>
                      <input
                        [(ngModel)]="beneficiary.abn"
                        [disabled]="isAdminOrPromoter != true"
                        matInput
                        placeholder=""
                        name="ABN"
                      />
                    </mat-form-field>
                  </div>
                </div>

                <div class="row-content-col" *ngIf="beneficiary.cardType === 'Commercial - Trust'">
                  <div class="row clearfix flex">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Trustee For</mat-label>
                      <input
                        [(ngModel)]="beneficiary.trusteeFor"
                        [disabled]="isAdminOrPromoter != true"
                        matInput
                        placeholder=""
                        type="text"
                        name="TrusteeFor"
                        required
                      />
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Trustee Name</mat-label>
                      <input
                        [(ngModel)]="beneficiary.supplierName"
                        [disabled]="isAdminOrPromoter != true"
                        matInput
                        placeholder=""
                        type="text"
                        name="TrusteeName"
                        required
                      />
                    </mat-form-field>
                  </div>
                  <div class="row clearfix flex">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Trading as</mat-label>
                      <input
                        [(ngModel)]="beneficiary.tradingAs"
                        [disabled]="isAdminOrPromoter != true"
                        matInput
                        placeholder=""
                        type="text"
                        name="tradinAs"
                        required
                      />
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label> ABN</mat-label>
                      <input
                        [(ngModel)]="beneficiary.abn"
                        [disabled]="isAdminOrPromoter != true"
                        matInput
                        placeholder=""
                        name="ABN"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="row clearfix flex full-width">
                <div class="icons-col">
                  <mat-icon class="fas fa-at"></mat-icon>
                </div>
                <div class="row-content-col">
                  <div class="row clearfix flex">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label> Website</mat-label>
                      <input
                        [(ngModel)]="beneficiary.url"
                        [disabled]="isAdminOrPromoter != true"
                        matInput
                        placeholder=""
                        name="website"
                      />
                    </mat-form-field>

                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label> Facebook</mat-label>
                      <input
                        [(ngModel)]="beneficiary.facebook"
                        [disabled]="isAdminOrPromoter != true"
                        matInput
                        placeholder=""
                        name="facebook"
                      />
                    </mat-form-field>
                  </div>
                  <div class="row clearfix flex">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label> LinkedIn</mat-label>
                      <input
                        [(ngModel)]="beneficiary.linkedIn"
                        [disabled]="isAdminOrPromoter != true"
                        matInput
                        placeholder=""
                        name="linkedin"
                      />
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label> Twitter</mat-label>
                      <input
                        [(ngModel)]="beneficiary.twitter"
                        [disabled]="isAdminOrPromoter != true"
                        matInput
                        placeholder=""
                        name="twitter"
                      />
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label> Instagram</mat-label>
                      <input
                        [(ngModel)]="beneficiary.instagram"
                        [disabled]="isAdminOrPromoter != true"
                        matInput
                        placeholder=""
                        name="instagram"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <hr class="mb"/>
              <h3 class="sr-title sm-h3 rm-mt">Subsidiary Address</h3>
              <!-- <div class="row clearfix flex  full-width">
                <div class="icons-col">
                  <mat-icon class="fas fa-globe"></mat-icon>
                </div>
                <div class="row-content-col">

                  <div class="row clearfix flex">

                    <mat-select placeholder="Select Country" class="half-width" name="companyCountry" required>
                      <mat-option selected>
                        Australia
                      </mat-option>
                    </mat-select>

                  </div>

                </div>
              </div> -->
              <div class="row clearfix flex full-width">
                <!-- Address Input -->
                <app-address-input
                  class="full-width"
                  [displayMap]="true"
                  [canClose]="false"
                  [postCode]="beneficiary.postCode"
                  [state]="beneficiary.state"
                  [suburb]="beneficiary.suburb"
                  [streetNumber]="beneficiary.streetNumber"
                  [streetName]="beneficiary.streetName"
                  [unitNumber]="beneficiary.unitNumber"
                  [streetType]="beneficiary.streetType"
                  [latitude]="beneficiary.latitude"
                  [longitude]="beneficiary.longitude"
                  [isRequired]="false"
                  (getAdress)="getAdress($event)"
                  (validate)="isAdressValidEvent($event)"
                >
                </app-address-input>
              </div>
              <hr class="mb"/>
              <h3 class="sr-title sm-h3 rm-mt">Subsidiary Contact Details</h3>

              <div class="row clearfix flex full-width">
                <div class="icons-col">
                  <mat-icon>settings_phone</mat-icon>
                </div>
                <div class="row-content-col flex">
                  <!-- General Phone Input -->

                  <app-phone-input
                    class="full-width"
                    [phoneNumber]="beneficiary.phone"
                    [isLandLine]="true"
                    [label]="'Work Phone'"
                    [required]="false"
                    (validate)="validatePhoneEvent($event)"
                    (getPhone)="getPhone($event)"
                  ></app-phone-input>
                  <!-- Mobile Input -->

                  <app-phone-input
                    class="full-width"
                    [phoneNumber]="beneficiary.mobile"
                    [isLandLine]="false"
                    [label]="'Mobile'"
                    [required]="false"
                    (validate)="validateMobileEvent($event)"
                    (getPhone)="getMobile($event)"
                  ></app-phone-input>
                </div>
              </div>

              <div class="row clearfix flex full-width">
                <div class="icons-col">
                  <mat-icon>mail</mat-icon>
                </div>
                <div class="row-content-col flex">
                  <app-email-input
                    class="full-width"
                    [email]="beneficiary.email"
                    [label]="'Email'"
                    [required]="false"
                    (getEmail)="getEmail($event)"
                    (validate)="validateEmailEvent($event)"
                  >
                  </app-email-input>

                  <!-- <app-occupation-input class="full-width" [isRequired]="true"
                    (validate)="validateOccupationEvent($event)" (getOccupation)="getOccupation($event)">
                  </app-occupation-input> -->
                </div>
              </div>

              <!-- </div> -->
              <!--  -->
            </div>
          </mat-card>
        </div>

        <div class="row clearfix">
          <div class="full-width text-center mt">
            <button
              class="btn-large pull-right"
              *ngIf="isEdit == false && isAdminOrPromoter == true"
              [disabled]="!beneficiaryForm.form.valid"
              (click)="sendApplication()"
              mat-raised-button
              color="accent"
            >
              Proceed
            </button>

            <button
              class="btn-large pull-left"
              *ngIf="isEdit == true"
              (click)="goBack()"
              mat-raised-button
              color="primary"
            >
              Go Back
            </button>

            <button
              class="btn-large pull-right"
              *ngIf="isEdit == true && isAdminOrPromoter == true"
              [disabled]="!beneficiaryForm.form.valid"
              (click)="editSupplier()"
              mat-raised-button
              color="accent"
            >
              Proceed
            </button>
          </div>
        </div>
      </form>
    </div>
    <!--  / Step 1  -->
  </aw-wizard-step>

  <aw-wizard-step
    *ngIf="isAdminOrPromoter == true"
    [navigationSymbol]="{ symbol: '2' }"
    stepTitle="Bank Details (Optional)"
  >
    <div class="container" id="step-2">
      <mat-card class="card">
        <div class="row clearfix">
          <p class="full-width well">Enter a label to be able to create a bank account}</p>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Label</mat-label>
            <input [(ngModel)]="supplierLabel" matInput placeholder="" name="label"/>
          </mat-form-field>
          <button class="pull-right" (click)="checkLabel()" mat-raised-button color="accent">Check</button>
          <div class="row clearfix">
            <app-bank-account-create-edit
              class="full-width"
              *ngIf="isCreateBank == true"
              [userID]="beneficiaryCreated['ID']"
              [userName]="supplierLabel"
            ></app-bank-account-create-edit>
          </div>
        </div>
      </mat-card>
      <div class="row clearfix">
        <div class="full-width text-center mt">
          <button class="btn-large pull-left" mat-raised-button color="primary" awPreviousStep>
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button class="btn-large pull-right" mat-raised-button color="accent" awNextStep>
            <mat-icon>chevron_right</mat-icon>Next
          </button>
        </div>
      </div>
    </div>
  </aw-wizard-step>

  <!-- The completion step -->
  <!-- <aw-wizard-completion-step title="Bank Account" [navigationSymbol]="2">

    <div class="container" id="step-2">
      <mat-card class="card ">

        <app-payment-method-input class="full-width" [isRequired]="true" (getResult)="getBankResult($event)" (validate)="validateBankEvent($event)">



        </app-payment-method-input>


      </mat-card>


      <div class="row clearfix">
        <div class="full-width text-center step-button-row">
          <button class="step-back" mat-mini-fab color="primary" awPreviousStep>
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>

          <button [disabled]="validateBank!=true" mat-raised-button class="btn-large" color="accent" (click)="sendApplication()">Send</button>
        </div>
      </div>
    </div>


  </aw-wizard-completion-step> -->
  <aw-wizard-completion-step *ngIf="isAdminOrPromoter == true" [navigationSymbol]="{ symbol: '3' }" title="Complete!">
    <div class="container animated fadeInDown" id="step-4" *ngIf="beneficiaryCreated">
      <div class="row clearfix text-center">
        <h2 class="sr-title inline-block rm-mt">
          <span *ngIf="isEdit == false">Subsidiary created!</span>
          <span *ngIf="isEdit == true">Subsidiary details updated!</span>
        </h2>
      </div>
      <div class="row clearfix">
        <div class="full-width text-center">
          <mat-card>
            <hr class="mt"/>
            <div class="svg-box">
              <svg class="circular green-stroke">
                <circle class="path" cx="75" cy="75" r="50" fill="none" stroke-width="5" stroke-miterlimit="10" />
              </svg>
              <svg class="checkmark green-stroke">
                <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                  <path class="checkmark__check" fill="none" d="M616.306,283.025L634.087,300.805L673.361,261.53" />
                </g>
              </svg>
            </div>

            <div class="row clearfix">
              <div class="full-width text-center step-button-row">
                <button
                  class="step-next"
                  *ngIf="beneficiaryCreated && beneficiaryCreated['ID']"
                  (click)="viewBeneficiary()"
                  mat-raised-button
                  color="primary"
                >
                  <mat-icon>visibility</mat-icon>
                  {{ 'View Subsidiary' | translate }}
                </button>

                <button
                  class="step-next"
                  *ngIf="beneficiaryCreated && beneficiaryCreated['ID']"
                  (click)="addNewBankAccount()"
                  mat-raised-button
                  color="accent"
                >
                  <mat-icon>visibility</mat-icon>
                  {{ 'Add Bank Account' | translate }}
                </button>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </aw-wizard-completion-step>
</aw-wizard>
