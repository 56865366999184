<div class="rel" *ngIf="isFetchingInitialData" @ngIfRowAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div class="row clearfix" *ngIf="!isFetchingInitialData">
  <mat-card class="card clearfix" style="display: flex; flex-direction: column">
    <ng-container *ngIf="pageMode === 'modal'">
      <ipv-modal-title [title]="modalTitle" [icon]="null"></ipv-modal-title>
    </ng-container>

    <ng-container *ngIf="pageMode !== 'modal'">
      <div class="colour-stripe" *ngIf="showDataHeader"></div>
    </ng-container>

    <ipv-data-header
      *ngIf="showDataHeader"
      [title]="dataHeader.title"
      [counterValue]="dataHeader.counterValue"
      [headerSize]="headerSize"
      [imageSrc]="dataHeader.imageSrc"
    ></ipv-data-header>
    <ipv-background-header *ngIf="showBackgroundHeader" [title]="backgroundHeader.title"></ipv-background-header>

    <ng-content></ng-content>
  </mat-card>
</div>
