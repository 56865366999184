import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { IframeViewComponent } from '../../../shared/components/iframe-view/iframe-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-marketing-campaign-tips-offers-lookup',
  templateUrl: './marketing-campaign-tips-offers-lookup.component.html',
  styleUrls: ['./marketing-campaign-tips-offers-lookup.component.css'],
})
export class MarketingCampaignTipsOffersLookupComponent implements OnInit {
  @Output()
  getResult = new EventEmitter();

  @Output()
  close = new EventEmitter();

  viewOnly = false;
  merchantID;

  offer;
  link;
  offers = [
    {
      code: 'hygiene-dental-tips',
      label: 'Dental Tips for a Good Oral Hygiene! ',
      image: 'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/campaign/Tips/shut_1.png',
    },
    {
      code: 'hygiene-periodontist',
      label: 'Oral health and the mouth-body connection',
      image: 'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/campaign/Tips/shut_2.png',
    },
    {
      code: 'hygiene-bad-breath',
      label: 'Suffering from bad breath? We can help!',
      image: 'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/campaign/Tips/shut_3.png',
    },
    {
      code: 'infectioncontrol',
      label: 'Feeling a little Corono-phobic? Fear not!',
      image: 'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/campaign/Tips/shut_6.png',
    },
    {
      code: 'coronavirus-dos-and-donts',
      label: "Corona-Do's and Dont's",
      image: 'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/cov-precautions.png',
    },

    {
      code: 'hygiene-recal1',
      label: "Unlike a car, your teeth don't come with a service reminder light.",
      image:
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/campaign/Tips/Mechanic%2BMax%2Bno%2Bbg-02.png',
    },
    {
      code: 'hygiene-recal2',
      label: "It's time to remove your unwanted bacteria with a dental check-up!",
      image:
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/campaign/Tips/Family%2BCleaning%2Bno%2Bbg%2B2-02.png',
    },
  ];

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private authenticationService: AuthenticationService
  ) {
    if (data && data.merchantID) {
      this.merchantID = data.merchantID;
    }

    if (data && data.viewOnly != null) {
      this.viewOnly = data.viewOnly;
    }
  }

  ngOnInit() {
    if (!this.merchantID) {
      this.authenticationService.getCurrentPractice().subscribe((r) => {
        if (r && r.ID) {
          this.merchantID = r.ID;
        }
      });
    }
  }

  preview(code) {
    if (this.merchantID) {
      this.buildLink(code);
      if (this.link) {
        const ref2 = RootAppComponent.dialog.open(IframeViewComponent, {
          data: {
            link: this.link,
            isSubmit: !this.viewOnly,
            SubmitLabel: 'Insert',
          },
          width: '80%',
          height: '92vh',
          panelClass: 'noCard',
        });

        ref2.componentInstance.close.subscribe((res) => {
          ref2.close();
        });

        ref2.componentInstance.getSubmit.subscribe((res) => {
          if (res) {
            ref2.close();
            ref2.afterClosed().subscribe((r) => {
              this.getResult.emit(this.link);
            });
          }
        });
      }
    }
  }

  submitOrPreview(d) {
    if (d) {
      if (this.viewOnly === true) {
        this.preview(d);
      } else {
        this.select(d);
      }
    }
  }

  select(code) {
    if (this.merchantID) {
      this.buildLink(code);

      this.getResult.emit(this.link);
    }
  }

  buildLink(code) {
    this.link = `${Settings.global['publicSiteFrontendLink']}/product/(page:main-merchant/${code}/${this.merchantID})`;
  }

  closeEvent() {
    this.close.emit(true);
  }
}
