import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/table';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, MatSort } from '@angular/material';
import { MatSelect } from '@angular/material/select';
import * as moment from 'moment';
import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { SideMenuService } from '../../../shared/services/side-menu.service';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { ActionLogUtilClass } from '../../action-log/shared/actionLogUtil';
import { MessageViewComponent } from '../message-view/message-view.component';
import { MessageService } from '../shared/message.service';

@Component({
  selector: 'app-message-list-global',
  templateUrl: './message-list-global.component.html',
  styleUrls: ['./message-list-global.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MessageListGlobalComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  dateFrom;
  @Input()
  templateTypeID;

  @Input()
  title = 'Messages';
  @Input()
  dateTo;

  @Input()
  merchantID;

  @Input()
  pageSize = Settings['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  searchLabel;
  utils = new UtilsClass();

  displayedColumns = [
    'Recipient',
    'Date',
    'Type',
    'Direction',
    'Target',
    'Context',
    'EmailNumber',
    'Subject',
    'Description',
    'TemplateTag',
    'Status',
    'Delivered',
    'Actions',
  ];

  statusColours = [
    { status: 'Bounced', color: '#ffb32a' },
    { status: 'Clicked', color: '#5ABA47' },
    { status: 'Delivered', color: '#1a88db' },
    { status: 'Error', color: '#8b9296' },
    { status: 'Opened', color: '#06c18d' },
    { status: 'Read', color: '#06c18d' },
    { status: 'Rejected', color: '#d20e0e' },
    { status: 'Sent', color: '#09aeda' },
    { status: 'Soft-Bounced', color: '#ff6003' },
    { status: 'Unknown', color: '#ff0000' },
  ];

  filters = [];

  searchVals = new FormControl();

  searchValList = ['Type'];
  expandedRow: number;
  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  filteredSize = null;

  isPromoterOrAdmin = false;

  destroyEvent = new EventEmitter();

  actionLogUtil = new ActionLogUtilClass();

  groupFilter = 'merchant';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('searchField', { static: false }) searchField: ElementRef;
  @ViewChild('messageStatusSelector', { static: false }) messageStatusSelector: MatSelect;
  @ViewChild('messageTypeSelector', { static: false }) messageTypeSelector: MatSelect;

  sessionType;

  constructor(private messageService: MessageService, private authenticationService: AuthenticationService) {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });
  }

  colour(status) {
    const result = this.statusColours.find((data) => data['status'] === this.statusLabel(status));
    return result ? result.color : null;
  }

  colourAlpha(status) {
    const result = this.statusColours.find((data) => data['status'] === this.statusLabel(status));
    return result ? result.color + '40' : null;
  }

  statusLabel(status) {
    return status || 'unknown';
  }

  ngOnInit() {
    if (this.templateTypeID) {
      const index = this.displayedColumns.indexOf('Context');
      if (index != -1) {
        this.displayedColumns.splice(index, 1);
      }
    }
    SideMenuService.goBack.subscribe((res) => {
      if (res === true) {
      }
    });
    this.authenticationService.getSessionType().subscribe((aSessionType) => {
      this.sessionType = aSessionType;
      this.listDB = new LoadRecords(
        this.messageService,
        this.isPromoterOrAdmin,
        this.destroyEvent,
        this.merchantID,
        this.dateFrom,
        this.dateTo,
        this.sessionType,
        this.templateTypeID
      );
      this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.templateTypeID) {
      const index = this.displayedColumns.indexOf('Context');
      if (index != -1) {
        this.displayedColumns.splice(index, 1);
      }
    }
    this.listDB = new LoadRecords(
      this.messageService,
      this.isPromoterOrAdmin,
      this.destroyEvent,
      this.merchantID,
      this.dateFrom,
      this.dateTo,
      this.sessionType,
      this.templateTypeID
    );
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  setFilter(event, field) {
    const filter = {
      field,
      value: event,
    };
    this.searchLabel = event;
    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  setTypeFilter(event, field) {
    let filter;
    if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }
    this.dataSource.filterType = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  setStatusFilter(event, field) {
    let filter;
    if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filterStatus = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  openMessageView(id, type) {
    const ref = RootAppComponent.dialog.open(MessageViewComponent, {
      data: {
        messageID: id,
        messageType: type,
        resendButton: true,
      },
      width: '800px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit, OnDestroy {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;
  firstLoad = false;
  util = new UtilsClass();
  isDestoyed = false;
  serviceRef;
  messageFilter;

  constructor(
    private messageService: MessageService,
    private isPromoterOrAdmin,
    private destroyEvent,
    private merchantID,
    private dateFrom,
    private dateTo,
    private sessionType,
    private templateTypeID
  ) {
    if (destroyEvent !== undefined) {
      this.destroyEvent.subscribe((res) => {
        this.isDestoyed = res;
      });
    }
    const payload = {
      section: 0,
      dateFrom: this.util.EPdateFormat(this.dateFrom),
      dateTo: this.util.EPdateFormat(this.dateTo),
      merchantID: this.merchantID,
      templateTypeID: this.templateTypeID || null,
    };
    if (sessionType !== undefined) {
      this.serviceRef = this.messageService.getMessageList(payload, this.sessionType).subscribe((res) => {
        res = res.map((data) => {
          if (data['Card_Name'] && data['Card_Name'].includes('trading as')) {
            const name = data['Card_Name'];
            const index = name.indexOf('trading as');
            data['Card_Name'] = name.substring(index + 11);
          }
          data['searchField'] = ''.concat(
            data['Card_NumberOrAddress'],
            ', ',
            data['Card_Name'],
            ', ',
            data['Subject'],
            ', ',
            data['Description']
          );
          return data;
        });
        this.items = res;
        this.dataChange.next(this.items);
        this.firstLoad = true;
        const innerFunction = (section) => {
          section = section + 1;
          UtilsClass.loadingDataSection(section);
          payload.section = section;
          this.serviceRef = this.messageService.getMessageList(payload, this.sessionType).subscribe((res1) => {
            if (res1.length > 0) {
              res1 = res1.map((data) => {
                if (data['Card_Name'] && data['Card_Name'].includes('trading as')) {
                  const name = data['Card_Name'];
                  const index = name.indexOf('trading as');
                  data['Card_Name'] = name.substring(index + 11);
                }
                data['searchField'] = ''.concat(
                  data['Card_NumberOrAddress'],
                  ', ',
                  data['Card_Name'],
                  ', ',
                  data['Subject'],
                  ', ',
                  data['Description']
                );
                return data;
              });

              this.items = this.items.concat(res1);
              this.dataChange.next(this.items.slice());
              if (this.isDestoyed !== true) {
                innerFunction(section);
              }
            } else {
              return true;
            }
          });
          UtilsClass.stopLoadingInterceptor();
        };
        if (this.isDestoyed !== true) {
          innerFunction(1);
        }
      });
    }
  }

  get data(): any[] {
    return this.dataChange.value;
  }

  get firstLoadEvent() {
    return this.firstLoad;
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  _filterStatusChange = new BehaviorSubject('');
  _filterTypeChange = new BehaviorSubject('');
  field = {
    type: '',
    status: '',
    searchField: '',
  };
  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
    this._filterStatusChange.subscribe(() => (this._paginator.pageIndex = 0));
    this._filterTypeChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field['searchField'] = item.value;
    this._filterChange.next(item.value);
  }

  get filterStatus(): any {
    return this._filterStatusChange.value;
  }

  set filterStatus(item: any) {
    this.field.status = item.field;
    this._filterStatusChange.next(item.value);
  }

  get filterType(): any {
    return this._filterTypeChange.value;
  }

  set filterType(item: any) {
    this.field.type = item.field;
    this._filterTypeChange.next(item.value);
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._filterStatusChange,
      this._filterTypeChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    const result = observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        if (this._tableDatabase.data !== undefined) {
          this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
            if (this.field.type === '' && this.field.status === '' && this.field.searchField === '') {
              return true;
            } else {
              let type = '';
              let status = '';
              let searchField = '';
              type = (item[this.field.type] || '').toLowerCase();
              status = (item[this.field.status] || '').toLowerCase();
              searchField = (item['searchField'] || '').toLowerCase();

              const filterType = this.filterType.toLowerCase();
              const filterStatus = this.filterStatus.toLowerCase();
              const filter = this.filter.toLowerCase();
              const result =
                (type === '' || filterType === '' || type === filterType) &&
                (status === '' || filterStatus === '' || status === filterStatus) &&
                (searchField === '' || filter === '' || searchField.indexOf(filter) !== -1);
              return result;
            }
          });
          const data = this.getSortedData(this.filteredData.slice());
          // Grab the page's slice of data.
          const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
          const result2 = data.splice(startIndex, this._paginator.pageSize);
          return result2;
        } else {
          this.filteredData = [];
          return [];
        }
      })
    );
    return result;
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    const result = data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'Date':
          [propertyA, propertyB] = [
            moment(a['DateTimeCreated']).toDate().getTime(),
            moment(b['DateTimeCreated']).toDate().getTime(),
          ];
          break;
        case 'Recipient':
          [propertyA, propertyB] = [a['Card_Name'], b['Card_Name']];
          break;
        case 'Type':
          [propertyA, propertyB] = [a['Action'], b['Action']];
          break;
        case 'Description':
          [propertyA, propertyB] = [a['Description'], b['Description']];
          break;
        case 'Status':
          [propertyA, propertyB] = [a['Status.Label'], b['Status.Label']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
    return result;
  }
}
