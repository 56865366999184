<div class="full-width clearfix container">
  <div class="full-width mt clearfix">
    <button
      class="pull-left"
      *ngIf="isAction == true"
      (click)="actionEvent()"
      mat-mini-fab
      color="accent"
      matTooltip="{{ actionLabel }}"
    >
      <mat-icon>{{ actionIcon }}</mat-icon>
    </button>

    <button
      class="pull-left"
      *ngIf="isAction2 == true"
      (click)="action2Event()"
      mat-mini-fab
      color="accent"
      matTooltip="{{ action2Label }}"
    >
      <mat-icon>{{ action2Icon }}</mat-icon>
    </button>

    <button
      class="btn-small pull-right"
      id="searchPatient"
      *ngIf="displayLookup == true"
      (click)="openPatientLookUpDialog()"
      mat-raised-button
      color="primary"
    >
      {{ "KEYWORD.patient" | translate | titlecase }} LookUp
      <mat-icon>contacts</mat-icon>
    </button>
  </div>

  <p class="help-label mt full-width clearfix" *ngIf="displayLookup == true">
    Please start by entering the details of your {{ "KEYWORD.patient" | translate }}. You can use the '{{
    'KEYWORD.patient' | translate | titlecase
    }}
    Lookup' button to search your existing {{ "KEYWORD.patient" | translate }} records.
  </p>

  <hr class="mt mb clearfix"/>

  <div class="row clearfix">
    <form #createForm="ngForm" name="form">
      <div class="row clearfix">
        <div class="icons-col">
          <mat-icon>person</mat-icon>
        </div>
        <div class="row-content-col">
          <div class="clearfix">
            <div class="half-width">
              <div class="col-left">
                <mat-select
                  class="full-width"
                  [(ngModel)]="user.gender"
                  [required]="requiredLabel"
                  (ngModelChange)="sendResult()"
                  name="gender"
                  placeholder="Gender"
                >
                  <mat-option *ngFor="let gender of genders" [value]="gender.Code">
                    {{ gender.Label }}
                  </mat-option>
                </mat-select>
              </div>
            </div>

            <div class="half-width">
              <div class="col-right">
                <mat-select
                  class="full-width"
                  [(ngModel)]="user.title"
                  [required]="requiredLabel"
                  (ngModelChange)="sendResult()"
                  placeholder="Title"
                  name="title"
                >
                  <mat-option *ngFor="let title of titles" [value]="title.Label">
                    {{ title.Label }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </div>

          <div class="row clearfix flex">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>First Name</mat-label>
              <input
                class="mat-block"
                [(ngModel)]="user.firstName"
                [required]="requiredLabel"
                (ngModelChange)="sendResult()"
                matInput
                placeholder="{{ 'PLACEHOLDER.First Name' | translate }} "
                name="firstName"
              />
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Middle Name</mat-label>
              <input
                class="mat-block"
                [(ngModel)]="user.middleName"
                (ngModelChange)="sendResult()"
                matInput
                placeholder="{{ 'PLACEHOLDER.Middle Name' | translate }} "
                name="middleName"
              />
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Surname</mat-label>
              <input
                class="mat-block"
                [(ngModel)]="user.lastName"
                [required]="requiredLabel"
                (ngModelChange)="sendResult()"
                matInput
                placeholder="{{ 'PLACEHOLDER.Surname' | translate }} "
                name="lastName"
              />
            </mat-form-field>
          </div>
        </div>
      </div>

      <hr class="mt mb"/>

      <div class="row clearfix">
        <div class="icons-col">
          <mat-icon>phone</mat-icon>
        </div>

        <div class="row-content-col">
          <div class="row clearfix flex">
            <app-phone-input
              class="full-width"
              [label]="'Mobile'"
              [required]="isRequired"
              [phoneNumber]="user.mobile"
              (validate)="validateMobileEvent($event)"
              (getPhone)="getMobile($event)"
            >
            </app-phone-input>

            <app-phone-input
              class="full-width"
              [isLandLine]="true"
              [label]="'Home'"
              [required]="false"
              [phoneNumber]="user.homePhone"
              (validate)="validateHomePhoneEvent($event)"
              (getPhone)="getHomePhone($event)"
            >
            </app-phone-input>

            <app-phone-input
              class="full-width"
              [isLandLine]="true"
              [label]="'Work'"
              [required]="false"
              [phoneNumber]="user.workPhone"
              (validate)="validateWorkPhoneEvent($event)"
              (getPhone)="getWorkPhone($event)"
            >
            </app-phone-input>
          </div>
        </div>
      </div>

      <div class="row clearfix">
        <div class="icons-col">
          <mat-icon>email</mat-icon>
        </div>

        <div class="row-content-col">
          <app-email-input
            class="full-width"
            [label]="'Email'"
            [required]="isRequired"
            [email]="user.email"
            (getEmail)="getEmail($event)"
            (validate)="validateEmailEvent($event)"
          >
          </app-email-input>
        </div>
      </div>
    </form>
  </div>
</div>
