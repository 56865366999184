import { Component, Input } from '@angular/core';

@Component({
  selector: 'ipv-clear-search-button',
  templateUrl: './clear-search-button.component.html',
  styleUrls: ['./clear-search-button.component.css'],
})
export class ClearSearchButtonComponent {
  @Input() iconType: 'light' | 'dark' = 'light';
}
