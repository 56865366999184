import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { TreatmentService } from '../../treatment/shared/treatment.service';
import { TreatmentDocumentCreateComponent } from '../treatment-document-create/treatment-document-create.component';
import { TreatmentPracticeOverheadEditComponent } from '../treatment-practice-overhead-edit/treatment-practice-overhead-edit.component';

@Component({
  selector: 'app-treatment-product-edit-merchant',
  templateUrl: './treatment-product-edit-merchant.component.html',
  styleUrls: ['./treatment-product-edit-merchant.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.edit-treat-merch', style({ opacity: 0 }), { optional: true }),
        query(
          '.edit-treat-merch',
          stagger('100ms', [
            animate(
              '0.3s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.edit-treat-merch', style({ opacity: 1 }), { optional: true }),
        query(
          '.edit-treat-merch',
          stagger('100ms', [
            animate(
              '0.3s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class TreatmentProductEditMerchantComponent implements OnInit {
  @Input()
  productID;

  @Input()
  merchantID;

  @Output()
  close = new EventEmitter();

  @Output()
  saveObject = new EventEmitter();

  isModal = false;

  treatment;
  isActive = false;

  step;

  useDefault = false;

  preDocuments = [];
  postDocuments = [];
  marketingDocuments = [];
  stockArtDocuments = [];

  preDocumentsEnabled = [];
  postDocumentsEnabled = [];
  marketingDocumentsEnabled = [];
  stockArtDocumentsEnabled = [];

  isPromoterOrAdmin = false;

  product = {
    merchantID: null,
    sellPrice: null,
    sellTotalPrice: null,
    sellPercentPrice: null,
    costPrice: null,
    costTotalPrice: null,
    costPercentPrice: null,
    profitPrice: null,
    costOverheadPrice: null,
    costOverheadPercent: null,
    categoryNote: null,
    labelNote: null,
    isActive: true,
    catelogueID: null,
    logoID: null,
  };

  logoPic;
  logoLoading = false;

  cataloguePic;
  catalogueLoading = false;

  categories = [];
  types = [];
  brands = [];

  manufacturers = [];
  distributors = [];

  isDownload = false;
  isRedirection = false;
  isPrint = false;

  setStep(index: number) {
    this.step = index;
  }

  constructor(
    private authenticationServer: AuthenticationService,
    private treatmentService: TreatmentService,
    private activeRoute: ActivatedRoute,
    private dialog: MatDialog,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.productID) {
        this.productID = data.productID;
      }

      if (data.merchantID) {
        this.merchantID = data.merchantID;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationServer.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      if (this.isPromoterOrAdmin == true) {
        this.isDownload = true;
        this.isRedirection = true;
        this.isPrint = true;
      }

      this.activeRoute.params.subscribe((params) => {
        if (params['merchantID']) {
          this.merchantID = params['merchantID'];
        }

        if (params['productID']) {
          this.productID = params['productID'];
        }
        this.product.merchantID = this.merchantID;

        this.setup();
      });
    });
  }

  useDefaultEvent() {
    this.fillData(this.treatment, this.useDefault);

    this.step = 0;
  }

  setup() {
    if (this.productID) {
      this.logoLoading = true;
      this.treatmentService.getProductLogo(this.productID).subscribe((res) => {
        this.logoLoading = false;
        if (res) {
          this.logoPic = res;
        }
      });

      this.catalogueLoading = true;
      this.treatmentService.getProductCatalogue(this.productID).subscribe((res) => {
        this.catalogueLoading = false;
        if (res) {
          this.cataloguePic = res;
        }
      });

      const p = {
        merchantID: this.merchantID,
      };
      this.treatmentService.getTreatmentProductDetails(this.productID, p, this.isPromoterOrAdmin).subscribe((res) => {
        if (res) {
          this.treatment = res;
          this.fillData(res);
          this.calculatePrice();
          this.setupDocument();
        }
      });
    }
  }

  fillData(res, useDefault = false) {
    if (res) {
      if (res['Active'] == '1') {
        this.product.isActive = true;
      }

      if (res['Active'] == '0') {
        this.product.isActive = false;
      }

      if (useDefault != false) {
        if (res['Price.Sell.Inherited']) {
          this.product.sellPrice = Number(res['Price.Sell.Inherited']);
        } else {
          this.product.sellPrice = 0;
        }

        if (res['Price.Cost.Item.Inherited']) {
          this.product.costPrice = Number(res['Price.Cost.Item.Inherited']);
        } else {
          this.product.costPrice = 0;
        }

        if (res['Price.Cost.Total.Inherited']) {
          this.product.costTotalPrice = Number(res['Price.Cost.Total.Inherited']);
        } else {
          this.product.costTotalPrice = 0;
        }

        if (res['Price.Profit.Inherited']) {
          this.product.profitPrice = Number(res['Price.Profit.Inherited']);
        } else {
          this.product.profitPrice = 0;
        }

        if (res['Price.Cost.Overheads.Inherited']) {
          this.product.costOverheadPrice = Number(res['Price.Cost.Overheads.Inherited']);
        } else {
          this.product.costOverheadPrice = 0;
        }

        if (this.product.sellPrice > 0) {
          this.product.costOverheadPercent = Number(
            (Number(this.product.costOverheadPrice) / Number(this.product.sellPrice)) * 100
          ).toFixed(0);
        } else {
          this.product.costOverheadPercent = 0;
        }

        if (res['DefaultNotes.Category.Inherited']) {
          this.product.categoryNote = Number(res['DefaultNotes.Category.Inherited']);
        }

        if (res['DefaultNotes.Label.Inherited']) {
          this.product.labelNote = Number(res['DefaultNotes.Label.Inherited']);
        }
      } else {
        if (res['Price.Sell.Effective']) {
          this.product.sellPrice = Number(res['Price.Sell.Effective']);
        } else {
          this.product.sellPrice = 0;
        }

        if (res['Price.Cost.Item.Effective']) {
          this.product.costPrice = Number(res['Price.Cost.Item.Effective']);
        } else {
          this.product.costPrice = 0;
        }

        if (res['Price.Cost.Total.Effective']) {
          this.product.costTotalPrice = Number(res['Price.Cost.Total.Effective']);
        } else {
          this.product.costTotalPrice = 0;
        }

        if (res['Price.Profit.Effective']) {
          this.product.profitPrice = Number(res['Price.Profit.Effective']);
        } else {
          this.product.profitPrice = 0;
        }

        if (res['Price.Cost.Overheads.Effective']) {
          this.product.costOverheadPrice = Number(res['Price.Cost.Overheads.Effective']);
        } else {
          this.product.costOverheadPrice = 0;
        }
        if (this.product.sellPrice > 0) {
          this.product.costOverheadPercent = Number(
            (Number(this.product.costOverheadPrice) / Number(this.product.sellPrice)) * 100
          ).toFixed(0);
        } else {
          this.product.costOverheadPercent = 0;
        }

        if (res['DefaultNotes.Category.Effective']) {
          this.product.categoryNote = Number(res['DefaultNotes.Category.Effective']);
        }

        if (res['DefaultNotes.Label.Effective']) {
          this.product.labelNote = Number(res['DefaultNotes.Label.Effective']);
        }
      }
    }
  }

  setupDocument() {
    const payload = {
      includeOmmitted: true,
      section: 0,
      includeInherited: true,
      documentTypes: 'SRVPRE,SRVPST,SRVMKT,SRVSTC',
      fields: 'Document.Type.Code,Document_key,Omitted',
      merchantID: this.merchantID,
      productID: this.productID,
    };

    this.treatmentService.getTreatmentDocumentList(payload, this.isPromoterOrAdmin).subscribe((_res) => {
      const res = _res['data'];
      if (res && res.length > 0) {
        const _preDoc = [];
        const _postDoc = [];
        const _marketingDoc = [];
        const _stockArtDoc = [];

        const _preDocEnabled = [];
        const _postDocEnabled = [];
        const _marketingDocEnabled = [];
        const _stockArtDocEnabled = [];

        for (let i = 0; i < res.length; i++) {
          if (
            res[i] &&
            res[i] &&
            res[i]['Document_key'] &&
            res[i]['Document.Type.Code'] &&
            res[i]['Document.Type.Code'] == 'SRVPST'
          ) {
            _postDoc.push(res[i]['Document_key']);

            if (res[i]['Omitted'] == '0') {
              _postDocEnabled.push(res[i]['Document_key']);
            }
          } else if (
            res[i] &&
            res[i]['Document_key'] &&
            res[i]['Document.Type.Code'] &&
            res[i]['Document.Type.Code'] == 'SRVPRE'
          ) {
            _preDoc.push(res[i]['Document_key']);

            if (res[i]['Omitted'] == '0') {
              _preDocEnabled.push(res[i]['Document_key']);
            }
          } else if (
            res[i] &&
            res[i]['Document_key'] &&
            res[i]['Document.Type.Code'] &&
            res[i]['Document.Type.Code'] == 'SRVMKT'
          ) {
            _marketingDoc.push(res[i]['Document_key']);

            if (res[i]['Omitted'] == '0') {
              _marketingDocEnabled.push(res[i]['Document_key']);
            }
          } else if (
            res[i] &&
            res[i]['Document_key'] &&
            res[i]['Document.Type.Code'] &&
            res[i]['Document.Type.Code'] == 'SRVSTC'
          ) {
            _stockArtDoc.push(res[i]['Document_key']);

            if (res[i]['Omitted'] == '0') {
              _stockArtDocEnabled.push(res[i]['Document_key']);
            }
          }
        }

        if (_preDocEnabled && _preDocEnabled.length > 0) {
          this.preDocumentsEnabled = _preDocEnabled;
        } else {
          this.preDocumentsEnabled = [];
        }

        if (_postDocEnabled && _postDocEnabled.length > 0) {
          this.postDocumentsEnabled = _postDocEnabled;
        } else {
          this.postDocumentsEnabled = [];
        }

        if (_marketingDocEnabled && _marketingDocEnabled.length > 0) {
          this.marketingDocumentsEnabled = _marketingDocEnabled;
        } else {
          this.marketingDocumentsEnabled = [];
        }

        if (_stockArtDocEnabled && _stockArtDocEnabled.length > 0) {
          this.stockArtDocumentsEnabled = _stockArtDocEnabled;
        } else {
          this.stockArtDocumentsEnabled = [];
        }

        if (_preDoc && _preDoc.length > 0) {
          this.preDocuments = _preDoc;
        } else {
          this.preDocuments = [];
        }

        if (_postDoc && _postDoc.length > 0) {
          this.postDocuments = _postDoc;
        } else {
          this.postDocuments = [];
        }

        if (_marketingDoc && _marketingDoc.length > 0) {
          this.marketingDocuments = _marketingDoc;
        } else {
          this.marketingDocuments = [];
        }

        if (_stockArtDoc && _stockArtDoc.length > 0) {
          this.stockArtDocuments = _stockArtDoc;
        } else {
          this.stockArtDocuments = [];
        }
      }
    });
  }

  enableDocument(doc) {
    if (doc && doc['ID']) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to enable this document  ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          const payload = {
            merchantID: this.merchantID,
            isActive: true,
            documentID: doc['ID'],
          };

          this.treatmentService
            .editTreatmentProductDocument(this.productID, payload, this.isPromoterOrAdmin)
            .subscribe((res) => {
              NotifyAppComponent.displayToast('success', 'Successful operation', 'The document is enabled');
              ref.close();
            });
        }
      });
    }
  }

  disableDocument(doc) {
    if (doc && doc['ID']) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to disable this document  ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          const payload = {
            merchantID: this.merchantID,
            isActive: false,
            documentID: doc['ID'],
          };

          this.treatmentService
            .editTreatmentProductDocument(this.productID, payload, this.isPromoterOrAdmin)
            .subscribe((res) => {
              NotifyAppComponent.displayToast('success', 'Successful operation', 'The document is disabled');

              ref.close();
            });
        }
      });
    }
  }

  setupPriceMerchant() {
    if (this.treatment) {
      this.fillData(this.treatment);
      this.calculatePrice();
    }
  }

  calculatePrice() {
    if (this.product.costOverheadPercent > 0) {
      this.product.costOverheadPrice =
        (Number(this.product.sellPrice) * Number(this.product.costOverheadPercent)) / 100;

      this.product.costTotalPrice = Number(this.product.costPrice) + Number(this.product.costOverheadPrice);
      this.product.profitPrice = Number(this.product.sellPrice) - Number(this.product.costPrice);
    } else {
      this.product.costOverheadPrice = 0;
      this.product.costTotalPrice = Number(this.product.costPrice);
      this.product.profitPrice = Number(this.product.sellPrice) - Number(this.product.costPrice);
    }
  }

  viewMerchant() {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: this.merchantID,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  editOverheadModal() {
    const ref = this.dialog.open(TreatmentPracticeOverheadEditComponent, {
      width: '450px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getOverheads.subscribe((res) => {
      ref.close();

      this.product.costOverheadPercent = res || 0;
      this.calculatePrice();
    });
  }

  editDocument() {
    const ref = this.dialog.open(TreatmentDocumentCreateComponent, {
      width: '750px',
      data: {
        productID: this.productID,
      },
    });

    ref.componentInstance.create.subscribe((res) => {
      if (res) {
        this.treatment = res;
        this.setup();
        ref.close();
      }
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  closeModal() {
    this.close.emit(true);
  }

  toNumber(value) {
    return Number(value);
  }

  save() {
    this.treatmentService
      .editTreatmentProduct(this.productID, this.product, this.isPromoterOrAdmin)
      .subscribe((res) => {
        if (res && res['ID']) {
          NotifyAppComponent.displayToast('success', 'Successful operation', 'The Treatment product is updated');

          this.saveObject.emit(res);
        } else if (this.isActive == false) {
          NotifyAppComponent.displayToast('success', 'Successful operation', 'The Treatment product is updated');

          this.saveObject.emit(null);
        }
      });
  }

  saveClose() {
    this.treatmentService
      .editTreatmentProduct(this.productID, this.product, this.isPromoterOrAdmin)
      .subscribe((res) => {
        if (res && res['ID']) {
          NotifyAppComponent.displayToast('success', 'Successful operation', 'The Treatment product is updated');
          this.saveObject.emit(res);
          this.close.emit(true);
        } else if (this.isActive == false) {
          NotifyAppComponent.displayToast('success', 'Successful operation', 'The Treatment product is updated');
          this.saveObject.emit(null);
          this.close.emit(true);
        }
      });
  }
}
