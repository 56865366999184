import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { saveAs } from 'file-saver';
import { ClipboardService } from 'ngx-clipboard';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { DocumentLinkCreateComponent } from '../../document/document-link-create/document-link-create.component';
import { MarketingCampaginLookupComponent } from '../../marketing/marketing-campagin-lookup/marketing-campagin-lookup.component';
import { MarketingCampaignTipsOffersLookupComponent } from '../../marketing/marketing-campaign-tips-offers-lookup/marketing-campaign-tips-offers-lookup.component';
import { TreatmentDocumentImportOverviewComponent } from '../../treatment/treatment-document-import-overview/treatment-document-import-overview.component';
import { SocialMediaService } from '../shared/social-media.service';

import { uniq } from 'lodash';
import { DocumentListThumbnailComponent } from '../../document/document-list-thumbnail/document-list-thumbnail.component';
import { LandingPageNewModalComponent } from '../../document/landing-page-new-modal/landing-page-new-modal.component';

@Component({
  selector: 'app-social-media-view',
  templateUrl: './social-media-view.component.html',
  styleUrls: ['./social-media-view.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class SocialMediaViewComponent implements OnInit {
  isPromoterOrAdmin = false;

  socialMediaPost;
  socialMediaPostID;
  sessionType;
  smLink;

  postType = 'Going Green Page';
  step = 1;
  campaigns;
  utils = new UtilsClass();

  pinchZoomProperties = [
    {
      wheel: true,
      autoHeight: true,
    },
  ];

  isModuleAppointmentActive = Settings.global['isModuleAppointmentActive'];
  isModuleDocumentLinkActive = Settings.global['isModuleDocumentLinkActive'];
  isModuleGoogleReviewActive = Settings.global['isModuleGoogleReviewActive'];

  loadingPost = false;

  merchantID;
  template;

  postPicture;
  postURL;
  postImage;
  downloadButtonLoading = false;
  postText;
  visitorsType = 'facebook';
  postHtml;
  actionCallLabel;
  actionCallDescription;

  minLengthCTALabel = 30;
  maxLengthCTALabel = 60;

  minLengthCTADescription = 55;
  maxLengthCTADescription = 110;

  @Input()
  templateID;

  postPictureThumbnail;

  settings = Settings.global;
  @Output()
  confirmShare = new EventEmitter();

  applyDownload = new EventEmitter();
  @Output()
  close = new EventEmitter();

  campaignID;

  socialMediaCode = 'f';

  maxLength = 63206;
  isModal = false;
  marketingCampaign;

  socialMediaType;

  urlType = 'practice';
  currentPractice;

  renderValue = {};

  dynamicData = [];

  // blob;
  constructor(
    private http: HttpClient,
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private socialMediaService: SocialMediaService,
    private _clipboardService: ClipboardService,
    private utilService: UtilsService,
    private customPhonePipe: CustomPhonePipe,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.templateID) {
        this.templateID = data.templateID;
        this.isModal = true;
      } else if (data.template) {
        this.template = data.template;
        this.isModal = true;
      }
      if (data.campaignID) {
        this.campaignID = data.campaignID;
        this.isModal = true;
      }

      if (data.socialMediaType) {
        this.socialMediaType = data.socialMediaType;
        this.isModal = true;
      }

      if (data.urlType) {
        this.urlType = data.urlType;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleAppointmentActive = access['isModuleAppointmentActive'];
        this.isModuleGoogleReviewActive = access['isModuleGoogleReviewActive'];
        this.isModuleDocumentLinkActive = access['isModuleDocumentLinkActive'];
      }
      this.activeRoute.params.subscribe((params) => {
        if (params['templateID']) {
          this.templateID = params['templateID'];
        }

        this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
          this.isPromoterOrAdmin = res;
          this.authenticationService.getSessionType().subscribe((s) => {
            this.sessionType = s;
            this.authenticationService.getCurrentPractice().subscribe((currentPractice) => {
              this.currentPractice = currentPractice;

              if (this.currentPractice.ID) {
                this.merchantID = this.currentPractice.ID;
              }
              this.addDynamicData();

              this.setup();
            });
          });
        });
      });
    });
  }

  addDynamicData() {
    if (this.currentPractice) {
      if (this.currentPractice['TradingAs']) {
        this.renderValue['practiceTradingAs'] = this.currentPractice['TradingAs'];

        this.dynamicData.push({
          label: 'Trading As',
          code: this.currentPractice['TradingAs'],
        });
      } else {
        this.renderValue['practiceTradingAs'] = '';
      }

      if (this.currentPractice['phones.Number']) {
        this.renderValue['practicePhoneNumber'] = this.customPhonePipe.transform(
          this.currentPractice['phones.Number'],
          'landLine'
        );
        this.dynamicData.push({
          label: 'Phone number',
          code: this.customPhonePipe.transform(this.currentPractice['phones.Number'], 'landLine'),
        });
      } else {
        this.renderValue['practicePhoneNumber'] = '';
      }

      if (this.currentPractice['emails.Email']) {
        this.renderValue['practiceEmail'] = this.currentPractice['emails.Email'];
        this.dynamicData.push({
          label: 'Email',
          code: this.currentPractice['emails.Email'],
        });
      } else {
        this.renderValue['practiceEmail'] = '';
      }

      if (this.currentPractice['addresses.Calculated']) {
        this.renderValue['practiceAddress'] = this.currentPractice['addresses.Calculated'];
        this.dynamicData.push({
          label: 'Address',
          code: this.currentPractice['addresses.Calculated'],
        });
      } else {
        this.renderValue['practiceAddress'] = '';
      }

      if (this.currentPractice['URL']) {
        this.renderValue['practiceSite'] = this.currentPractice['URL'];
        this.dynamicData.push({
          label: 'Web Site',
          code: this.currentPractice['URL'],
        });
      } else {
        this.renderValue['practiceSite'] = '';
      }
    }
  }

  getImageLink() {
    if (this.templateID && this.template) {
      return this.socialMediaService.getThumbnailImageStreamLink(this.templateID, this.template['LastModified']);
    }
  }

  setup() {
    if (this.templateID) {
      const payload = {};
      this.socialMediaService.getSocialMediaDetails(this.templateID, payload, this.sessionType).subscribe((res) => {
        if (res) {
          this.template = res;
          this.setSocialMediaDetails();
        }
      });
    } else if (this.template && this.template.ID) {
      this.templateID = this.template.ID;
      this.setSocialMediaDetails();
    } else if (this.campaignID && this.socialMediaType) {
      const payloadCampaign = {
        fields:
          'ID,LastModified,DateTimeCreated,Label,Description,Social_Media_Only,Template.Label,Template.FacebookPost_Key,Template.LinkedInPost_Key,Template.TwitterPost_Key,Template.InstagramPost_Key,Template.Tag,Template.LandingPageURL',
      };

      this.socialMediaService
        .getMarketingDetails(this.campaignID, payloadCampaign, this.sessionType)
        .subscribe((marketingCampaign) => {
          if (marketingCampaign) {
            this.marketingCampaign = marketingCampaign;
            if (this.socialMediaType === 'Facebook' && this.marketingCampaign['Template.FacebookPost_Key']) {
              this.templateID = this.marketingCampaign['Template.FacebookPost_Key'];
              this.visitorsType = 'f';
            } else if (this.socialMediaType === 'LinkedIn' && this.marketingCampaign['Template.LinkedInPost_Key']) {
              this.templateID = this.marketingCampaign['Template.LinkedInPost_Key'];
              this.visitorsType = 'l';
            } else if (this.socialMediaType === 'Twitter' && this.marketingCampaign['Template.TwitterPost_Key']) {
              this.templateID = this.marketingCampaign['Template.TwitterPost_Key'];
              this.visitorsType = 't';
            } else if (this.socialMediaType === 'Instagram' && this.marketingCampaign['Template.InstagramPost_Key']) {
              this.templateID = this.marketingCampaign['Template.InstagramPost_Key'];
              this.visitorsType = 'i';
            }

            if (this.templateID) {
              const payload = {};
              this.socialMediaService
                .getSocialMediaDetails(this.templateID, payload, this.sessionType)
                .subscribe((res) => {
                  if (res) {
                    this.template = res;

                    this.setSocialMediaDetails();
                  }
                });
            } else {
              this.template = {};
              this.template['DateTimeCreated'] = this.marketingCampaign['DateTimeCreated'];
              this.template['SocialMediaType.Code'] = this.socialMediaType;
              this.template['Post'] = '';

              this.setSocialMediaDetails();
            }
          }
        });
    }
  }

  setSocialMediaDetails() {
    if (this.template && this.template.ID) {
      if (this.template['Tag.Label'] && this.template['StaticList.Label']) {
        this.template['StaticList.Label'] = this.template['Tag.Label'] + '|' + this.template['StaticList.Label'];
      } else if (this.template['Tag.Label']) {
        this.template['StaticList.Label'] = this.template['Tag.Label'];
      }

      if (this.template['StaticList.Label']) {
        this.template['StaticList.Label'] = this.template['StaticList.Label'].split('|');
      } else {
        this.template['StaticList.Label'] = [];
      }

      if (this.template['StaticList.Label'] && Array.isArray(this.template['StaticList.Label'])) {
        this.template['Tags'] = this.template['StaticList.Label'];
      } else {
        if (this.template['Tag.Label'] && this.template['StaticList.Label']) {
          this.template['Tags'] = this.template['Tag.Label'] + '|' + this.template['StaticList.Label'];
        } else if (this.template['Tag.Label']) {
          this.template['Tags'] = this.template['Tag.Label'];
        }

        if (this.template['StaticList.Label']) {
          this.template['Tags'] = this.template['StaticList.Label'].split('|');
        } else {
          this.template['Tags'] = [];
        }
      }

      this.template['Tags'].push(this.currentPractice['TradingAs']);

      this.postPictureThumbnail = this.socialMediaService.getThumbnailImageStreamLink(
        this.template.ID,
        this.template['LastModified']
      );

      // if (this.template['SocialMediaType.Code'] == "Instagram") {
      //   this.socialMediaService.getPostImage(this.templateID).subscribe(res => {
      //     if (res) {
      //       this.blob = res;
      //     }
      //   })
      // }
    }

    if (this.template && this.template.ID) {
      this.loadingPost = true;

      const length = 0;

      // if (this.smLink && this.smLink.length) {
      //   length = this.smLink.length
      // }

      if (this.template['SocialMediaType.Code'] == 'Facebook') {
        this.maxLength = 63206 - length;

        this.socialMediaCode = 'f';
      } else if (this.template['SocialMediaType.Code'] == 'Twitter') {
        this.maxLength = 280 - length;
        this.socialMediaCode = 't';
      } else if (this.template['SocialMediaType.Code'] == 'LinkedIn') {
        this.maxLength = 700 - length;
        this.socialMediaCode = 'l';
      } else if (this.template['SocialMediaType.Code'] == 'Instagram') {
        this.maxLength = 2200 - length;
        this.socialMediaCode = 'i';
      } else if (this.template['SocialMediaType.Code'] == 'Pinterest') {
        this.maxLength = 500 - length;
        this.socialMediaCode = 'pi';
      }
      if (this.template && this.template['Post']) {
        this.postText = this.convertMessage(this.template['Post']);
      }

      if (this.template && this.template['ActionCall.Label']) {
        this.actionCallLabel = this.convertMessage(this.template['ActionCall.Label']);
      } else if (this.template && this.template['Label']) {
        this.actionCallLabel = this.convertMessage(this.template['Label']);
      }
      if (this.template && this.template['ActionCall.Description']) {
        this.actionCallDescription = this.convertMessage(this.template['ActionCall.Description']);
      } else if (this.currentPractice && this.currentPractice['TradingAs']) {
        this.actionCallDescription = this.currentPractice['TradingAs'];
      }

      this.postPicture = this.socialMediaService.getPostImageStreamLink(this.templateID, this.template['LastModified']);
    } else if (this.template && this.campaignID) {
      this.loadingPost = true;

      let length = 0;

      if (this.smLink && this.smLink.length) {
        length = this.smLink.length;
      }

      if (this.template['SocialMediaType.Code'] == 'Facebook') {
        this.maxLength = 63206 - length;

        this.socialMediaCode = 'f';
      } else if (this.template['SocialMediaType.Code'] == 'Twitter') {
        this.maxLength = 140 - length;
        this.socialMediaCode = 't';
      } else if (this.template['SocialMediaType.Code'] == 'LinkedIn') {
        this.maxLength = 700 - length;
        this.socialMediaCode = 'l';
      } else if (this.template['SocialMediaType.Code'] == 'Instagram') {
        this.maxLength = 2200 - length;
        this.socialMediaCode = 'i';
      } else if (this.template['SocialMediaType.Code'] == 'Pinterest') {
        this.maxLength = 500 - length;
        this.socialMediaCode = 'pi';
      }

      if (this.template && this.template['Post']) {
        this.postText = this.convertMessage(this.template['Post']);
      }

      if (this.template && this.template['ActionCall.Label']) {
        this.actionCallLabel = this.convertMessage(this.template['ActionCall.Label']);
      } else if (this.template && this.template['Label']) {
        this.actionCallLabel = this.convertMessage(this.template['Label']);
      }

      if (this.template && this.template['ActionCall.Description']) {
        this.actionCallDescription = this.convertMessage(this.template['ActionCall.Description']);
      } else if (this.currentPractice && this.currentPractice['TradingAs']) {
        this.actionCallDescription = this.currentPractice['TradingAs'];
      }

      if (this.marketingCampaign && this.marketingCampaign['templateID']) {
        this.postPicture = this.socialMediaService.getPostImageStreamLink(
          this.templateID,
          this.template['LastModified']
        );
      } else {
        this.loadingPost = false;
      }
    }

    if (this.campaignID) {
      this.urlType = 'campaign';
      this.buildURL();
    } else if (this.urlType) {
      this.buildURL();
    }
  }

  getTextPost() {
    let publicSite = this.settings['publicSiteFrontendLink'];
    if (publicSite && publicSite.indexOf('http') == -1) {
      publicSite = 'https://' + this.settings['publicSiteFrontendLink'];
    }

    if (this.socialMediaCode && this.campaignID && this.urlType == 'campaign') {
      this.postURL = publicSite + '/s/' + this.socialMediaCode + '/m/' + this.campaignID;
    } else if (this.urlType == 'practice' && this.currentPractice && this.currentPractice.Terminal_Code) {
      this.postURL = publicSite + '/l/p/' + this.socialMediaCode + '/' + this.currentPractice.Terminal_Code;
    } else if (this.urlType == 'appointment' && true && this.currentPractice && this.currentPractice.Terminal_Code) {
      this.postURL = publicSite + '/a/' + this.currentPractice.Terminal_Code;
    }

    if (this.postText) {
      const splitArray = this.postText.split(publicSite)[0];
      if (splitArray) {
        if (this.postURL) {
          if (splitArray[splitArray.length - 1] == '\n') {
            this.postText = splitArray + this.postURL;
          } else {
            this.postText = splitArray + '\n' + this.postURL;
          }
        } else {
          this.postText = splitArray;
        }
      } else {
        if (this.postURL) {
          this.postText = this.postURL;
        } else {
          this.postText = this.template['Post'];
        }
      }
    } else if (this.template && this.template['Post']) {
      if (this.postURL) {
        this.postText = this.template['Post'] + '\n' + this.postURL;
      } else {
        this.postText = this.template['Post'];
      }
    } else if (this.template && this.postURL) {
      this.postText = this.postURL;
    }
  }

  buildURL() {
    let _url;
    let publicSite = this.settings['publicSiteFrontendLink'];
    if (publicSite && publicSite.indexOf('http') == -1) {
      publicSite = 'https://' + this.settings['publicSiteFrontendLink'];
    }

    let nodeServer = environment.nodeUrl;
    if (nodeServer && nodeServer.indexOf('localhost') != -1) {
      nodeServer = 'https://sandbox.smileright.com.au/api';
    }

    if (this.urlType == 'googleReview') {
      _url = nodeServer + '/merchant/google-review/' + this.merchantID;
    } else if (this.socialMediaCode && this.campaignID && this.urlType == 'campaign') {
      _url = publicSite + '/s/' + this.socialMediaCode + '/m/' + this.campaignID;
    } else if (this.urlType == 'practice' && this.currentPractice && this.currentPractice.Terminal_Code) {
      _url = publicSite + '/l/p/' + this.socialMediaCode + '/' + this.currentPractice.Terminal_Code;
    } else if (this.urlType == 'appointment' && true && this.currentPractice && this.currentPractice.Terminal_Code) {
      _url = publicSite + '/a/' + this.currentPractice.Terminal_Code;
    } else if (this.urlType == 'dental' && true && this.currentPractice && this.currentPractice.Terminal_Code) {
      _url = publicSite + '/l/d/' + this.socialMediaCode + '/' + this.currentPractice.Terminal_Code;
    }

    if (_url) {
      this.utilService.createShortcut(_url).subscribe((r) => {
        if (r && r.shortLink) {
          this.postURL = r.shortLink;
        }
      });
    }
  }

  clickCopy() {
    NotifyAppComponent.displayToast('success', 'Text copied', 'The post text has been copied to your clipboard');
  }

  closeModal() {
    this.close.emit(true);
  }

  linkCopy(p) {
    if (p) {
      this._clipboardService.copyFromContent(p);
      NotifyAppComponent.displayToast('success', 'Copied!', '');
    }
  }

  linkCopyInstgram(p) {
    if (p) {
      this._clipboardService.copyFromContent(p);
      NotifyAppComponent.displayToast('success', 'Copied!', '');
    }
  }

  confirmShareEvent() {
    if (this.socialMediaPostID) {
      this.socialMediaService.confirmShareSocialMediaPost(this.socialMediaPostID, this.sessionType).subscribe((res) => {
        if (res) {
          this.confirmShare.emit(res);

          NotifyAppComponent.displayToast('success', 'Successful operation', '');
        }
      });
    }
  }

  campaignLook() {
    if (this.campaigns && this.campaigns.length > 0) {
      this.lookupModal();
    } else {
      const payload: any = {
        statusCodes: 'ACTV,CMPL',
        isExpired: false,
        fields:
          'ID,DateTimeCreated,LastModified,LastModified,Label,Description,Social_Media_Only,Template.Label,Template.FacebookPost_Key,Template.LinkedInPost_Key,Template.TwitterPost_Key,Template.InstagramPost_Key,Template.Tag,Template.LandingPageURL',
      };
      this.socialMediaService.getCamaignList(payload, false).subscribe((res) => {
        if (res && res.length > 0) {
          this.campaigns = res;

          this.lookupModal();
        }
      });
    }
  }

  lookupModal() {
    const ref = RootAppComponent.dialog.open(MarketingCampaginLookupComponent, {
      data: {
        campaigns: this.campaigns,
        isCreate: false,
      },
      width: '700px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.getCampaign.subscribe((c) => {
      if (c && c.ID) {
        this.campaignID = c.ID;
        this.marketingCampaign = c;
        this.urlType = 'campaign';
        ref.close();
        ref.afterClosed().subscribe((r) => {
          this.postType = 'Campaign Landing Page';
          this.buildURL();
        });
      }
    });
  }

  addLandingPageEvent() {
    this.urlType = 'practice';
    this.campaignID = null;
    this.postType = 'Going Green Page';
    this.buildURL();
  }

  addAppointmentPageEvent() {
    this.urlType = 'appointment';
    this.campaignID = null;

    this.postType = 'Appointment Request Page';
    this.buildURL();
  }

  addGoogleReview() {
    this.urlType = 'googleReview';
    this.campaignID = null;
    this.postType = 'Google Reviews Page';
    this.buildURL();
  }

  addDentalPageEvent() {
    this.urlType = 'dental';
    this.campaignID = null;
    this.postType = 'Practice Tips and Offers';
    this.buildURL();
  }

  downloadImage() {
    this.applyDownload.emit(true);
  }

  downloadImageInstgram() {
    const name = this.template['Label'] + '_' + this.template['SocialMediaType.Code'];
    const extension = this.template['Document.ExtensionOrMimeType'];
    let text = '';
    if (this.postText) {
      text = this.postText;
      if (this.smLink) {
        text = text + '\n' + this.smLink;
      }
    } else if (this.smLink) {
      text = this.smLink;
    }

    if (text) {
      this._clipboardService.copyFromContent(text);
    }

    UtilsClass.startLoadingForce();
    this.downloadButtonLoading = true;

    this.http
      .get(this.postPicture, { responseType: 'blob' })
      .toPromise()
      .then((blob) => {
        if (!blob || (blob && blob.size == 4) || (blob && blob.type == 'application/json')) {
          throw new Error('blob is null');
        }
        this.downloadButtonLoading = false;

        saveAs(blob, name + '.' + extension);
        UtilsClass.stopLoadingForce();

        NotifyAppComponent.displayToast('success', 'Copied!', '');

        this.confirmSharedModal();
      })
      .catch((err) => {
        const link = document.createElement('a');
        link.href = '/assets/images/popularity.png';
        link.target = '_blank';
        link.setAttribute('download', this.template['Label'] + '_' + this.template['SocialMediaType.Code']);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.downloadButtonLoading = false;
        UtilsClass.stopLoadingForce();

        NotifyAppComponent.displayToast('success', 'Copied!', '');

        this.confirmSharedModal();
      });
  }

  confirmSharedModal() {
    const confirmDialogParams = {
      data: new ConfirmDialog(
        'fas fa-info',
        'Confirm Share',
        '<p>Did you successfully share the Post  ?</p>',
        'No',
        'Yes'
      ),
    };
    const confirmDialogRef = RootAppComponent.dialog.open(ConfirmDialogComponent, confirmDialogParams);
    confirmDialogRef.componentInstance.onConfirm.subscribe((result) => {
      if (result === true) {
        this.confirmShareEvent();
      }
      confirmDialogRef.close();
    });
  }

  loadingPostEvent(e) {
    this.loadingPost = e;
  }

  addPersonalisationText(code) {
    if (code) {
      if (this.postText) {
        this.postText = this.postText + ' ' + code + ' ';
      } else {
        this.postText = code + ' ';
      }
    }
  }

  convertMessage(message) {
    if (message) {
      let result;
      result = this.utils.convertMessage(message, this.renderValue);
      return result;
    }

    return message;
  }

  openLink(l) {
    if (l.indexOf('http') === -1) {
      l = 'https://' + l;
    }
    window.open(l, '_blank');
  }

  generatePost() {
    const payload: any = {};

    if (this.template) {
      if (this.template['ID']) {
        payload['socialMediaID'] = this.template['ID'];
      }
      if (this.template['ID']) {
        payload['socialMediaID'] = this.template['ID'];
      }
      if (this.actionCallLabel) {
        payload['actionCallLabel'] = this.actionCallLabel;
      }

      if (this.actionCallDescription) {
        payload['actionCallDescription'] = this.actionCallDescription;
      }

      if (this.campaignID) {
        payload['campaignID'] = this.campaignID;
      }
      if (this.postURL) {
        payload['url'] = this.postURL;
      }
      if (this.postText) {
        payload['post'] = this.postText;
      }

      if (this.urlType == 'campaign') {
        payload['typeCode'] = 'Campaign';
      } else if (this.urlType == 'dental') {
        payload['typeCode'] = 'DentalPage';
      } else if (this.urlType == 'appointment') {
        payload['typeCode'] = 'Other';
      } else if (this.urlType == 'practice') {
        payload['typeCode'] = 'DentalPractice';
      } else {
        payload['typeCode'] = 'Other';
      }

      payload['isActive'] = false;
      payload['delete'] = true;
      if (this.socialMediaPostID) {
        this.socialMediaService
          .editSocialMediaPost(this.socialMediaPostID, payload, this.sessionType)
          .subscribe((res) => {
            if (res && res.ID) {
              this.socialMediaPostID = res.ID;
              this.socialMediaPost = res;

              if (res.Post) {
                this.postText = res.Post;
              }

              if (res['ActionCall.Label']) {
                this.actionCallLabel = res['ActionCall.Label'];
              }
              if (res['ActionCall.Description']) {
                this.actionCallDescription = res['ActionCall.Description'];
              }
              let nodeServer = environment.nodeUrl;
              if (nodeServer && nodeServer.indexOf('localhost') != -1) {
                nodeServer = 'https://sandbox.smileright.com.au/api';
              }

              this.smLink = nodeServer + '/sm/' + this.socialMediaPostID;

              if (this.socialMediaPost.Image) {
                this.postImage =
                  Settings.global['AWSS3Prefix'] + '/'+this.settings['AWSS3PublicFolder'] + this.socialMediaPost.Image;
              } else {
                this.postImage =
                  'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/public/img/popularity.png';
              }

              if (this.postText) {
                this.postHtml = this.utils.addHtmlLink(this.postText);
              } else if (this.smLink) {
                this.postHtml = '<a href="' + this.smLink + '" target="_blank">' + this.smLink + '</a>';
              }

              this.step = 2;
            }
          });
      } else {
        this.socialMediaService.createSocialMediaPost(payload, this.sessionType).subscribe((res) => {
          if (res && res.ID) {
            this.socialMediaPostID = res.ID;
            this.socialMediaPost = res;
            if (res.Post) {
              this.postText = res.Post;
            }

            if (res['ActionCall.Label']) {
              this.actionCallLabel = res['ActionCall.Label'];
            }
            if (res['ActionCall.Description']) {
              this.actionCallDescription = res['ActionCall.Description'];
            }
            let nodeServer = environment.nodeUrl;
            if (nodeServer && nodeServer.indexOf('localhost') != -1) {
              nodeServer = 'https://sandbox.smileright.com.au/api';
            }

            this.smLink = nodeServer + '/sm/' + this.socialMediaPostID;

            if (this.socialMediaPost.Image) {
              this.postImage =
                Settings.global['AWSS3Prefix'] + '/'+this.settings['AWSS3PublicFolder'] +  this.socialMediaPost.Image;
            } else {
              this.postImage =
                'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/public/img/popularity.png';
            }

            if (this.postText) {
              this.postHtml = this.utils.addHtmlLink(this.postText);
            } else if (this.smLink) {
              this.postHtml = '<a href="' + this.smLink + '" target="_blank">' + this.smLink + '</a>';
            }

            this.step = 2;
          }
        });
      }
    }
  }

  back(step = 1) {
    this.step = step;
  }

  next(step = 2) {
    this.step = step;
  }

  tipsAndOffers() {
    const ref = RootAppComponent.dialog.open(MarketingCampaignTipsOffersLookupComponent, {
      data: {
        merchantID: this.merchantID,
        viewOnly: false,
      },
      width: '1000px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.getResult.subscribe((link) => {
      if (link) {
        this.utilService.createShortcut(link + '?m' + this.merchantID).subscribe((r) => {
          if (r && r.shortLink) {
            this.postURL = r.shortLink;
            ref.close();
          }
        });
      }
    });
  }

  addDocument() {
    const refLandingPageNewModalComponent = RootAppComponent.dialog.open(LandingPageNewModalComponent, {
      data: {},
      width: '650px',
    });

    refLandingPageNewModalComponent.componentInstance.getType.subscribe((type) => {
      refLandingPageNewModalComponent.close();

      refLandingPageNewModalComponent.afterClosed().subscribe((r) => {
        const ref2 = RootAppComponent.dialog.open(DocumentLinkCreateComponent, {
          data: {
            merchantID: this.merchantID,
            visitorsType: this.visitorsType,
            type,

            addToStore: false,
            displayType: false,
          },
          panelClass: 'noCard',
        });
        ref2.backdropClick().subscribe((res) => {});
        ref2.componentInstance.close.subscribe((res) => {
          ref2.close();
        });
        ref2.componentInstance.getLink.subscribe((link) => {
          if (link) {
            this.utilService.createShortcut(link).subscribe((r) => {
              if (r && r.shortLink) {
                this.postURL = r.shortLink;

                this.postType = 'Insert a Landing Page';
                ref2.close();
              }
            });
          }
        });
      });

      refLandingPageNewModalComponent.componentInstance.close.subscribe((res) => {
        refLandingPageNewModalComponent.close();
      });
    });
  }

  documentLinkLookup() {
    const ref2 = RootAppComponent.dialog.open(DocumentListThumbnailComponent, {
      data: {
        isModal: true,
        viewOnly: false,
        isClonedStored: false,
        isClonedDefault: false,
        isClonedPublic: false,
        cloneDefault: true,
      },
      width: '85vw',

      panelClass: 'noCard',
    });

    ref2.componentInstance.close.subscribe((res) => {
      ref2.close();
    });
    ref2.componentInstance.getLandingPageURL.subscribe((link) => {
      if (link) {
        this.utilService.createShortcut(link).subscribe((r) => {
          if (r && r.shortLink) {
            this.postURL = r.shortLink;

            this.postType = 'Landing Page Templates';

            ref2.close();
          }
        });
      }
    });
  }

  insertDocument() {
    const payload = {
      merchantID: this.merchantID,
      readonly: false,
      isGallery: true,
      loadUserDocument: true,
      loadICDocument: true,
      loadPatientDocument: false,
      selectedView: 'system',
      userDocumentType: ['SRVSTC', 'TRP', 'IGVIDEO'],
      ICDocumentType: ['SRVSTC', 'TRP', 'IGVIDEO'],
      displayUserDocumentType: true,
      displayICDocumentType: true,
      displayPatientDocumentType: true,
      isICSideMenu: true,
      isUserSideMenu: true,
      isPatientSideMenu: true,
      buttonLabel: 'Documents',
      displayPatientDocuments: true,
      nature: ['document', 'image', 'video'],
      natureICDocument: ['document', 'image', 'video'],
      natureUserDocument: ['document', 'image', 'video'],
      naturePatientDocument: ['document', 'image', 'video'],
      addTitle: 'Insert Documents',
      selectAnotherPatient: true,
    };

    const ref = RootAppComponent.dialog.open(TreatmentDocumentImportOverviewComponent, {
      data: payload,
      width: '90vw',
    });
    ref.backdropClick().subscribe((data) => {
      ref.close();
    });
    ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
    ref.componentInstance.getDocuments.subscribe((res) => {
      if (res && res.length > 0) {
        const EPTreatmentDocuments = JSON.parse(JSON.stringify(uniq(res)));

        const ref2 = RootAppComponent.dialog.open(DocumentLinkCreateComponent, {
          data: {
            documentIDs: EPTreatmentDocuments,
            merchantID: this.merchantID,
            addNewDocument: false,
            addGalleryDocument: false,
            message: '<p>Please see attached some information we thought you might find interesting.</p>',
            title: 'Some further information ',
            description: ' Your Dentist wants to shared documents with you.',
            type: 'PLandPg',
            addToStore: false,
            displayType: false,
          },
          panelClass: 'noCard',
        });
        ref2.backdropClick().subscribe((res) => {});
        ref2.componentInstance.close.subscribe((res) => {
          ref2.close();
        });
        ref2.componentInstance.getLink.subscribe((link) => {
          if (link) {
            this.utilService.createShortcut(link).subscribe((r) => {
              if (r && r.shortLink) {
                this.postURL = r.shortLink;

                this.postType = 'Insert Document From Gallery';

                ref2.close();
                ref2.afterClosed().subscribe((r) => {
                  ref.close();
                });
              }
            });
          }
        });
      } else {
        ref.close();
      }
    });
  }

  toLowerCase(t) {
    if (t) {
      return String(t).toLocaleLowerCase();
    }
    return t;
  }

  addPersonalisationCTALabel(code) {
    if (code) {
      if (this.actionCallLabel) {
        this.actionCallLabel = this.actionCallLabel + ' ' + code + ' ';
      } else {
        this.actionCallLabel = code + ' ';
      }
    }
  }

  addPersonalisationCTADescription(code) {
    if (code) {
      if (this.actionCallDescription) {
        this.actionCallDescription = this.actionCallDescription + ' ' + code + ' ';
      } else {
        this.actionCallDescription = code + ' ';
      }
    }
  }
}
