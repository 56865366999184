import { Component, EventEmitter, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { UtilsService } from '../../services/utils.service';
import { Settings } from '../../types/settings';
import { UtilsClass } from '../../types/utils/utils.class';

@Component({
  selector: 'file-insertion',
  templateUrl: './file-insertion.component.html',
  styleUrls: ['./file-insertion.component.css'],
})
export class FileInsertionComponent implements OnInit {
  @Input()
  icon = 'fas fa-upload';
  close = new EventEmitter();
  getResult = new EventEmitter();
  getFileID = new EventEmitter();
  title;

  headerTitle;
  fileID;
  @Input()
  allowedExtensions;
  documentType;
  isFixedAspectRatio = false;
  roundCropper = false;
  displayTitle = false;
  displayGallery = false;
  aspectRatioType;
  isGallery = false;
  documentNature;
  keepOriginal = true;
  utils = new UtilsClass();
  link;
  displayThumbnail = true;
  uploadOnSubmit = true;
  maxFileSize = Settings.global['fileMaxSize'];
  constructor(
    private utilService: UtilsService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.displayTitle === true) {
        this.displayTitle = true;
      } else if (data.displayTitle === false) {
        this.displayTitle = false;
      }
      if (data.isGallery === true) {
        this.isGallery = true;
      } else if (data.isGallery === false) {
        this.isGallery = false;
      }

      if (data.roundCropper === true) {
        this.roundCropper = true;
      } else if (data.roundCropper === false) {
        this.roundCropper = false;
      }

      if (data.keepOriginal === true) {
        this.keepOriginal = true;
      } else if (data.keepOriginal === false) {
        this.keepOriginal = false;
      }

      if (data.uploadOnSubmit === true) {
        this.uploadOnSubmit = true;
      } else if (data.uploadOnSubmit === false) {
        this.uploadOnSubmit = false;
      }

      if (data.displayThumbnail === true) {
        this.displayThumbnail = true;
      } else if (data.displayThumbnail === false) {
        this.displayThumbnail = false;
      }

      if (data.isFixedAspectRatio === true) {
        this.isFixedAspectRatio = true;
      } else if (data.isFixedAspectRatio === false) {
        this.isFixedAspectRatio = false;
      }

      if (data.maxFileSize) {
        this.maxFileSize = data.maxFileSize;
      }
      if (data.headerTitle) {
        this.headerTitle = data.headerTitle;
      }

      if (data.aspectRatioType) {
        this.aspectRatioType = data.aspectRatioType;
      }

      if (data.documentNature) {
        this.documentNature = data.documentNature;
      }

      if (data.title) {
        this.title = data.title;
      }

      if (data.documentType) {
        this.documentType = data.documentType;
      }

      if (data.displayGallery === true) {
        this.displayGallery = true;
      } else if (data.displayGallery === false) {
        this.displayGallery = false;
      }
    }
  }

  ngOnInit() {
    if (this.documentNature) {
      this.allowedExtensions = this.utils.getDocumentExtensionsFromType(this.documentNature);
    }
  }

  isFontAwsome(icon) {
    if (icon) {
      if (icon.indexOf('fas ') !== -1 || icon.indexOf('fab-') !== -1) {
        return true;
      }

      return false;
    }

    return false;
  }
  send() {
    if (this.fileID) {
      if (this.uploadOnSubmit == false) {
        this.getFileID.emit(this.fileID);
      } else {
        const p = {
          fileID: this.fileID,
          title: this.title,
          isGallery: this.isGallery,
          type: this.documentType,
        };
        this.utilService.uploadPublicDocument(p).subscribe((document) => {
          if (document && document.AWS_Bucket_Name && document.AWS_URL) {
            this.link = `${Settings.global['AWSS3Prefix']}/${document.AWS_Bucket_Name}${document.AWS_URL}`;

            this.utilService.createShortcut(this.link).subscribe((r) => {
              if (r && r.shortLink) {
                let title = this.link;
                if (this.title) {
                  title = this.title;
                }

                const result = {
                  ...document,
                  link: this.link,
                  shortLink: r.shortLink,
                  title: this.title,
                  htmlLink: `<a title="${title}" target="_blank" href="${r.shortLink}">${r.shortLink}</a>`,
                };

                this.getResult.emit(result);
              }
            });
          }
        });
      }
    }
  }
  onCompleFile(f) {
    if (f && f[0]) {
      this.fileID = f[0];
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
}
