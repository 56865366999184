import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataResponse } from '../../../shared/types/data-response.type';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { environment } from 'environments/environment';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { UtilsService } from '../../../shared/services/utils.service';

@Injectable()
export class DefaultMessageService {
  private baseUrl = environment.nodeUrl;

  utilsClass = new UtilsClass();

  private defaultMessage = new BehaviorSubject<DefaultMessage>(null);
  readonly defaultMessage$ = this.defaultMessage.asObservable();

  private defaultMessages = new BehaviorSubject<DefaultMessage[]>([]);
  readonly defaultMessages$ = this.defaultMessages.asObservable();

  constructor(private httpClient: HttpClient, private utilsService: UtilsService) {}

  getDefaultMessage$() {
    return this.defaultMessage$;
  }

  setDefaultMessage(defaultMessage: DefaultMessage) {
    this.defaultMessage.next(defaultMessage);
  }


  getTagsList(body = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }
    options = {
      params,
    };
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .get(environment.nodeUrl + '/tag-merchant/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.httpClient
        .get(environment.nodeUrl + '/tag-merchant', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/tag-merchant', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  getOneDefaultMessage(id: string, sessionType) {
    let endpoint = 'merchant-personal-message';

    if (sessionType == 'admin' || sessionType == 'promoter')
      endpoint = 'merchant-personal-message/global';
    const url = `${this.baseUrl}/${endpoint}/${id}`;

    return this.httpClient.get<DataResponse<DefaultMessage>>(url).pipe(
      map((response) => response['data']),
      map((message) => {

        const newMessage = {
          ...message,
          Label: message.Label.replace('SMSFilter-', ''),
        };
        return newMessage;

      })
    );
  }


  fetchDefaultMessages$(payload: object, isPromoterOrAdmin: boolean) {
    const params = this.utilsService.getHttpParamsFromPayload(payload);

    const options = { params };

    const endpoint = isPromoterOrAdmin ? 'merchant-personal-message/global' : 'merchant-personal-message';
    const url = `${this.baseUrl}/${endpoint}`;

    return this.httpClient.get<DataResponse<DefaultMessage[]>>(url, options).pipe(
      map((response) => response['data']),
      map((messages) => {
        try {
          return messages.map((message) => {
            const newMessage = {
              ...message,
              Label: message.Label.replace('SMSFilter-', ''),
              IsPromoterTemplate: message['Purpose.Code'] === 'PromSMS' ? '2' : message.IsPromoterTemplate,
            };
            return newMessage;
          });
        } catch (e) {
          return [];
        }
      })
    );
  }

  getDefaultMessageList(payload) {
    const params = this.utilsService.getHttpParamsFromPayload(payload);

    const options = { params };

    const endpoint = 'merchant-personal-message';
    const url = `${this.baseUrl}/${endpoint}`;

    return this.httpClient.get<DataResponse<DefaultMessage[] & { count: string | number }>>(url, options).pipe(
      map((response) => response['data']),
      map((messages) => {
        try {
          return messages.map((message) => {
            const newMessage = {
              ...message,
              Label: message.Label.replace('SMSFilter-', ''),
              IsPromoterTemplate: message['Purpose.Code'] === 'PromSMS' ? '2' : message.IsPromoterTemplate,
            };
            return newMessage;
          });
        } catch (e) {
          return [];
        }
      })
    );
  }

  getDefaultMessageListGlobal(payload) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params: params,
    };
    return this.httpClient.get(environment.nodeUrl + '/merchant-personal-message/global', options).pipe(
      map((response) => response['data']),
      map((messages) => {
        try {
          return messages.map((message) => {
            const newMessage = {
              ...message,
              Label: message.Label.replace('SMSFilter-', ''),
              IsPromoterTemplate: message['Purpose.Code'] === 'PromSMS' ? '2' : message.IsPromoterTemplate,
            };
            return newMessage;
          });
        } catch (e) {
          return [];
        }
      })
    );
  }
  defaultMessageList(payload, sessionType) {
    const params = this.utilsService.getHttpParamsFromPayload(payload);

    const options = { params };

    let endpoint = 'merchant-personal-message';

    if (sessionType === 'admin' || sessionType == 'promoter') {
      endpoint = 'merchant-personal-message/global';
    }
    const url = `${this.baseUrl}/${endpoint}`;

    return this.httpClient.get<DataResponse<DefaultMessage[] & { count: string | number }>>(url, options).pipe(
      map((response) => response['data']),
      map((messages) => {
        try {
          return messages
            .map((message) => {
              const newMessage = {
                ...message,
                Label: message.Label.replace('SMSFilter-', ''),
                IsPromoterTemplate: message['Purpose.Code'] === 'PromSMS' ? '2' : message.IsPromoterTemplate,
              };
              return newMessage;
            });
        }
        catch (e) {
          return []
        }

      })
    );
  }


  defaultMessageListHidden(payload, sessionType) {
    const params = this.utilsService.getHttpParamsFromPayload(payload);

    const options = { params };

    let endpoint = 'merchant-personal-message/hidden';

    if (sessionType === 'admin' || sessionType == 'promoter') {
      endpoint = 'merchant-personal-message/global-hidden';
    }
    const url = `${this.baseUrl}/${endpoint}`;

    return this.httpClient.get<DataResponse<DefaultMessage[] & { count: string | number }>>(url, options).pipe(
      map((response) => response['data']),
      map((messages) => {
        try {
          return messages
            .map((message) => {
              const newMessage = {
                ...message,
                Label: message.Label.replace('SMSFilter-', ''),
                IsPromoterTemplate: message['Purpose.Code'] === 'PromSMS' ? '2' : message.IsPromoterTemplate,
              };
              return newMessage;
            });
        }
        catch (e) {
          return []
        }

      })
    );
  }

  createDefaultMessage(
    payload: { [key: string]: any },
    isPromoterOrAdmin = false,
    messageMode: 'default' | 'sms' = 'default'
  ) {
    if (messageMode === 'sms') {
      if (payload.label && payload.purposeCode !== 'PromSMS') {
        payload.label = 'SMSFilter-' + payload.label;
      }
    }

    const endpoint = isPromoterOrAdmin ? 'merchant-personal-message/global' : 'merchant-personal-message';
    const url = `${this.baseUrl}/${endpoint}`;
    return this.httpClient.post<DefaultMessage>(url, payload).pipe(
      map((response) => response['data']),
      map((message) => {
        if (messageMode === 'default') {
          return message;
        } else {
          const newMessage = {
            ...message,
            Label: message.Label.replace('SMSFilter-', ''),
          };
          return newMessage;
        }
      })
    );
  }

  editDefaultMessage(
    id: string,
    payload: { [key: string]: any },
    isPromoterOrAdmin = false,
    messageMode: 'default' | 'sms' = 'default'
  ) {
    if (messageMode === 'sms') {
      if (payload.label && payload.purposeCode !== 'PromSMS') {
        payload.label = 'SMSFilter-' + payload.label;
      }
    }

    delete payload.purposeCode;

    const endpoint = isPromoterOrAdmin ? 'merchant-personal-message/global' : 'merchant-personal-message';
    const url = `${this.baseUrl}/${endpoint}/${id}`;

    return this.httpClient.put<DataResponse<DefaultMessage>>(url, payload).pipe(
      map((response) => response['data']),
      map((message) => {
        if (messageMode === 'default') {
          return message;
        } else {
          const newMessage = {
            ...message,
            Label: message.Label.replace('SMSFilter-', ''),
          };
          return newMessage;
        }
      })
    );
  }

  deleteDefaultMessage(id: string, isPromoterOrAdmin = false) {
    const endpoint = isPromoterOrAdmin ? 'merchant-personal-message/global' : 'merchant-personal-message';
    const url = `${this.baseUrl}/${endpoint}/${id}`;

    return this.httpClient.delete(url).pipe(map((response: DataResponse) => response['data']));
  }

  convertMessage(id, payload, customPhonePipe: CustomPhonePipe, isPormoterOrAdmin = false): Observable<any> {
    let rule = {};

    let result;
    let patient;
    let dentist;
    let merchant;
    let rawContent;
    let promoter;
    let transform = function () {
      if (rawContent) {
        if (payload.patient) {
          patient = payload.patient;
        } else if (payload.isPatientPlaceHolder == true) {
          patient = {
            title: 'SALUTATION',
            lastname: 'LAST_NAME',
            firstname: 'FIRST_NAME',
            middlename: 'MIDDLENAME',
            mobile: 'MOBILE',
            work_phone: 'WORK_NUMBER',
            email: 'EMAIL',
          };
        }

        if (patient) {
          rule['PatientSalutation'] = patient.title || '';
          rule['PatientLastName'] = patient.lastname || '';
          rule['PatientFirstName'] = patient.firstname || '';
          rule['PatientMiddleName'] = patient.middlename || '';

          if (rule['PatientMobileNumber']) {
            rule['PatientMobileNumber'] = customPhonePipe.transform(patient.mobile, 'false');
          } else {
            rule['PatientMobileNumber'] = '';
          }

          if (rule['PatientWorkPhoneNumber']) {
            rule['PatientWorkPhoneNumber'] = customPhonePipe.transform(patient.mobile, 'true');
          } else {
            rule['PatientWorkPhoneNumber'] = '';
          }

          rule['PatientEmail'] = patient.email || '';
        }

        if (payload.dentist) {
          dentist = payload.dentist;
        } else if (payload.isDentistPlaceHolder == true) {
          dentist = {
            Salutation: 'DENTIST_SALUTATION',
            FirstName: 'DENTIST_FIRST_NAME',
            MiddleName: 'DENTIST_MIDDLE',
            Name: 'DENTIST_NAME',
            'mobiles.Number': 'DENTIST_MOBILE',
            'phones.Number': 'DENTIST_PHONE_NUMBER',
            'emails.Email': 'DENTIST_EMAIL',
            'addresses.Calculated': 'DENTIST_ADDRESSS',
          };
        }

        if (dentist) {
          rule['DentistSalutation'] = dentist['Salutation'] || '';

          rule['DentistFirstName'] = dentist['FirstName'] || '';

          rule['DentistMiddleName'] = dentist['MiddleName'] || '';

          rule['DentistLastName'] = dentist['Name'] || '';

          rule['DentistEmail'] = dentist['emails.Email'] || '';

          rule['DentistAddress'] = dentist['addresses.Calculated'] || '';

          if (dentist['mobiles.Number']) {
            rule['DentistMobileNumber'] = customPhonePipe.transform(dentist['mobiles.Number'], 'false');
          } else {
            rule['DentistMobileNumber'] = '';
          }

          if (dentist['phones.Number']) {
            rule['DentistPhoneNumber'] = customPhonePipe.transform(dentist['phones.Number'], 'true');
          } else {
            rule['DentistPhoneNumber'] = '';
          }
        }

        if (payload.merchant) {
          merchant = payload.merchant;
        } else if (payload.isMerchantPlaceHolder == true) {
          merchant = {
            Name: 'PRACTICE_NAME',
            'addresses.Calculated': 'PRACTICE_ADDRESS',
            TradingAs: 'PRACTICE_TRADING_AS',
            'phones.Number': 'PRACTICE_PHONE_NUMBER',
            'mobiles.Number': 'PRACTICE_MOBILE',
            ABN: 'PRACTICE_ABN',
            ACN: 'PRACTICE_ACN',
            'emails.Email': 'PRACTICE_EMAIL',
          };
        }

        if (merchant) {
          rule['PracticeLegalName'] = merchant['Name'] || '';


          rule['PracticeAddress'] = merchant['addresses.Calculated'] || '';

          rule['PracticeTradingAs'] = merchant['TradingAs'] || '';


          rule['PracticeABN'] = merchant['ABN'] || '';

          rule['PracticeACN'] = merchant['ACN'] || '';

          rule['PracticeEmail'] = merchant['emails.Email'] || '';

          if (merchant['mobiles.Number']) {
            rule['PracticeMobileNumber'] = customPhonePipe.transform(merchant['mobiles.Number'], 'false');
          } else {
            rule['PracticeMobileNumber'] = '';
          }

          if (merchant['phones.Number']) {
            rule['PracticePhoneNumber'] = customPhonePipe.transform(merchant['phones.Number'], 'true');
          } else {
            rule['PracticePhoneNumber'] = '';
          }
        }


        if (payload.promoter) {
          promoter = payload.promoter;
        } else if (payload.isPromoterPlaceHolder == true) {
          promoter = {
            Name: 'PROMOTER_NAME',
            'addresses.Calculated': 'PROMOTER_ADDRESS',
            TradingAs: 'PROMOTER_TRADING_AS',
            'phones.Number': 'PROMOTER_PHONE_NUMBER',
            'mobiles.Number': 'PROMOTER_MOBILE',
            ABN: 'PROMOTER_ABN',
            ACN: 'PROMOTER_ACN',
            'emails.Email': 'PROMOTER_EMAIL',
          };
        }


        if (promoter) {

          rule['PromoterLegalName'] = promoter['Name'] || '';

          rule['PromoterAddress'] = promoter['addresses.Calculated'] || '';

          rule['PromoterTradingAs'] = promoter['TradingAs'] || '';

          rule['PromoterAddress'] = promoter['addresses.Calculated'] || '';

          rule['PromoterABN'] = promoter['ABN'] || '';

          rule['PromoterACN'] = promoter['ACN'] || '';

          rule['PromoterEmail'] = promoter['emails.Email'] || '';

          if (promoter['mobiles.Number']) {
            rule['PromoterMobileNumber'] = customPhonePipe.transform(promoter['mobiles.Number'], 'false');
          } else {
            rule['PromoterMobileNumber'] = '';
          }

          if (promoter['phones.Number']) {
            rule['PromoterPhoneNumber'] = customPhonePipe.transform(promoter['phones.Number'], 'true');
          } else {
            rule['PromoterPhoneNumber'] = '';
          }
        }




      }
    };

    if (id) {
      return this.httpClient.get(environment.nodeUrl + '/merchant-personal-message/' + id).pipe(
        map((res: HttpResponse<any>) => {
          if (res && res['data']) {
            if (res['data']['Content']) {
              rawContent = res['data']['Content'];
            }

            transform();

            if (rule) {
              result = this.utilsClass.convertMessage(rawContent, rule);
            }

            return result;
          }
        })
      );
    } else if (payload.rawContent) {
      rawContent = payload.rawContent;

      transform();

      if (rule) {
        result = this.utilsClass.convertMessage(rawContent, rule);
      }

      return observableOf(result);
    }
  }
}

export class DefaultMessage {
  [key: string]: any;

  ID: string;
  Card_key: string;
  Content: string;
  Favourite: '0' | '1';
  IsPromoterTemplate: '0' | '1' | '2';
  Label: string;
  'ProductGroup.Code': string;
  'Purpose.Code': string;
  'Purpose.Label': string;
  search?: string;
  active?: boolean;
}
