import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsClass } from '../../types/utils/utils.class';

@Component({
  selector: 'app-image-view-list',
  templateUrl: './image-view-list.component.html',
  styleUrls: ['./image-view-list.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class ImageViewListComponent implements OnInit, OnChanges {
  @Input() imageLinks: string[]; // Array of links , either Array of strings or array of objects , for array of objects format will be { link , title , description } , if there is a title u can use it under the image to display image title .
  @Input() canDownload: boolean; // true to display download button ( for each image) false otherwise
  @Input() canAction: boolean; // to display Action button ( for each image) ( which a button that emit the event getAction if we want to have more generic use for the component )
  @Input() canCheck: boolean; // to display/hide check box
  @Input() canMultipleDowload: boolean; // to display/hide multiple download image
  @Input() canMultipleAction: boolean; // to display multiple Action button ( which a button that emit the event sendAction if we want to have more generic use for the component )
  @Input() actionButtonLabel = 'Send'; // just label of action button button , default 'Send'
  @Input() multipleActionButtonLabel = 'Send';
  @Input() title = 'Image Gallery';
  @Input() description = 'Select images';

  @Output() sendAction = new EventEmitter(); // emit link when you click on action button
  @Output() sendMultipleActions = new EventEmitter(); // emit array of checked links on click on action button
  @Output() sendSelectedImages = new EventEmitter<string[]>(); // once u select an images , emit selected array
  @Output() closeModal = new EventEmitter();
  sessionType = 'merchant';
  isAdminOrPromoter = false;
  isModal = false;
  fullsizeClick = false;
  _imageLinks;

  utils = new UtilsClass();

  constructor(
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.isModal = true;
      if (data.imageLinks != null) {
        this.imageLinks = data.imageLinks;
      }

      if (data.canDownload != null) {
        this.canDownload = data.canDownload;
      }

      if (data.canAction != null) {
        this.canAction = data.canAction;
      }

      if (data.canCheck != null) {
        this.canCheck = data.canCheck;
      }

      if (data.canMultipleDowload != null) {
        this.canMultipleDowload = data.canMultipleDowload;
      }

      if (data.canMultipleAction != null) {
        this.canMultipleAction = data.canMultipleAction;
      }

      if (data.actionButtonLabel != null) {
        this.actionButtonLabel = data.actionButtonLabel;
      }

      if (data.multipleActionButtonLabel != null) {
        this.multipleActionButtonLabel = data.multipleActionButtonLabel;
      }
    }
  }

  async ngOnInit() {
    const res = await Promise.all([
      this.authenticationService.getSessionType().toPromise(),
      this.authenticationService.isPromoterOrAdmin().toPromise(),
    ]);

    this.sessionType = res[0];
    this.isAdminOrPromoter = res[1];

    this.setUp();
  }

  ngOnChanges() {
    this.setUp();
  }

  setUp() {
    if (this.imageLinks) {
      this._imageLinks = this.imageLinks.map((it) => {
        return {
          link: it,
          checked: false,
        };
      });
    }
  }

  async downloadImage(imageLink) {
    const base64 = await this.utils.convertImageLinkToBase64(imageLink.link);
    this.utils.downloadBase64File(base64, 'download.png');
  }

  multipleActionEvent() {
    const selectedImages = this._imageLinks.filter((it) => it.checked).map((it) => it.link);
    this.sendSelectedImages.emit(selectedImages);
  }

  closeEvent() {
    this.closeModal.emit(true);
  }
}
