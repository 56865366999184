<div class="full-width text-center" *ngIf="buttonStyle == 'buttons'">
  <!-- facebook -->
  <div class="row clearfix flex mb full-width" *ngIf="displayFacebook">
    <button
      class="mr remove"
      *ngIf="canRemove == true && facebookStatus == 'valid'"
      @simpleFadeAnimation
      (click)="removeEvent('facebook')"
      mat-mini-fab
      color="accent"
    >
      <mat-icon>delete</mat-icon>
    </button>

    <button
      class="connect-fb btn-large icon-l full-width"
      (click)="syncFaceBook()"
      mat-raised-button
      matTooltip="{{ facebookLabel || '' }}{{ facebookLabel && facebookDate ? ' - ' : '' }}{{
        facebookDate ? 'Added ' + (facebookDate | TimeLabelPast) + ' ago' : ''
      }}"
    >
      {{ facebookButtonLabel }}
      <mat-icon class="fab fa-facebook-square pull-right mr"></mat-icon>
    </button>

    <div class="text-center ml statuses">
      <mat-icon class="validStatus" *ngIf="facebookStatus == 'valid'">power</mat-icon>
      <p class="small rm-m vs" *ngIf="facebookStatus == 'valid'">Connected</p>
      <mat-icon class="warningStatus" *ngIf="facebookStatus == 'warning'">power_off</mat-icon>
      <p class="small rm-m ws" *ngIf="facebookStatus == 'warning'">Disconnected</p>
      <mat-icon class="errorStatus fas fa-times-circle" *ngIf="facebookStatus == 'error'">close</mat-icon>
      <p class="small rm-m es" *ngIf="facebookStatus == 'error'">Error</p>
    </div>
  </div>

  <!-- google -->
  <div class="row clearfix flex mb full-width" *ngIf="displayGoogle">
    <button
      class="mr remove"
      *ngIf="canRemove == true && googleStatus == 'valid'"
      @simpleFadeAnimation
      (click)="removeEvent('google')"
      mat-mini-fab
      color="accent"
    >
      <mat-icon>delete</mat-icon>
    </button>

    <button
      class="connect-goog btn-large icon-l full-width"
      (click)="syncGoogle()"
      mat-raised-button
      matTooltip="{{ googleLabel || '' }}{{ googleLabel && googleDate ? ' - ' : '' }}{{
        googleDate ? 'Added ' + (googleDate | TimeLabelPast) + ' ago' : ''
      }}"
    >
      {{ googleButtonLabel }}
      <mat-icon class="fab fa-google pull-right mr"></mat-icon>
    </button>

    <div class="text-center ml statuses">
      <mat-icon class="validStatus" *ngIf="googleStatus == 'valid'">power</mat-icon>
      <p class="small rm-m vs" *ngIf="googleStatus == 'valid'">Connected</p>
      <mat-icon class="warningStatus" *ngIf="googleStatus == 'warning'">power_off</mat-icon>
      <p class="small rm-m ws" *ngIf="googleStatus == 'warning'">Disconnected</p>
      <mat-icon class="errorStatus fas fa-times-circle" *ngIf="googleStatus == 'error'">close</mat-icon>
      <p class="small rm-m es" *ngIf="googleStatus == 'error'">Error</p>
    </div>
  </div>

  <!-- iCloud Calendar -->
  <div class="row clearfix flex mb full-width" *ngIf="displayiCloudCalendar">
    <button
      class="mr remove"
      *ngIf="canRemove == true && iCloudCalendarStatus == 'valid'"
      @simpleFadeAnimation
      mat-mini-fab
      color="accent"
    >
      <mat-icon>delete</mat-icon>
    </button>

    <button
      class="connect-icloud-calendar btn-large icon-l full-width"
      (click)="toggleiCloudCalendar()"
      mat-raised-button
      matTooltip="{{ iCloudCalendarLabel || '' }}{{
        iCloudCalendarLabel && iCloudCalendarDate && iCloudCalendarDate !== '0000-00-00' ? ' - ' : ''
      }}{{
        iCloudCalendarDate && iCloudCalendarDate !== '0000-00-00'
          ? 'Added ' + (iCloudCalendarDate | TimeLabelPast) + ' ago'
          : ''
      }}"
    >
      {{ iCloudCalendarButtonLabel }}
      <mat-icon class="fab fa-apple pull-right mr"></mat-icon>
    </button>

    <div class="text-center ml statuses">
      <mat-icon class="validStatus" *ngIf="iCloudCalendarStatus == 'valid'">power</mat-icon>
      <p class="small rm-m vs" *ngIf="iCloudCalendarStatus == 'valid'">Connected</p>
      <mat-icon class="warningStatus" *ngIf="iCloudCalendarStatus == 'warning'">power_off</mat-icon>
      <p class="small rm-m ws" *ngIf="iCloudCalendarStatus == 'warning'">Disconnected</p>
      <mat-icon class="errorStatus fas fa-times-circle" *ngIf="iCloudCalendarStatus == 'error'">close</mat-icon>
      <p class="small rm-m es" *ngIf="iCloudCalendarStatus == 'error'">Error</p>
    </div>
  </div>

  <!-- Google Calender -->
  <div class="row clearfix flex mb full-width" *ngIf="displayGoogleCalendar">
    <button
      class="mr remove"
      *ngIf="canRemove == true && googleCalendarStatus == 'valid'"
      @simpleFadeAnimation
      mat-mini-fab
      color="accent"
    >
      <mat-icon>delete</mat-icon>
    </button>

    <button
      class="connect-google-calendar btn-large icon-l full-width"
      (click)="toggleGoogleCalendar()"
      mat-raised-button
      matTooltip="{{ googleCalendarLabel || '' }}{{
        googleCalendarLabel && googleCalendarDate && googleCalendarDate !== '0000-00-00' ? ' - ' : ''
      }}{{
        googleCalendarDate && googleCalendarDate !== '0000-00-00'
          ? 'Added ' + (googleCalendarDate | TimeLabelPast) + ' ago'
          : ''
      }}"
    >
      {{ googleCalendarButtonLabel }}
      <mat-icon class="fab fa-google pull-right mr"></mat-icon>
    </button>

    <div class="text-center ml statuses">
      <mat-icon class="validStatus" *ngIf="googleCalendarStatus == 'valid'">power</mat-icon>
      <p class="small rm-m vs" *ngIf="googleCalendarStatus == 'valid'">Connected</p>
      <mat-icon class="warningStatus" *ngIf="googleCalendarStatus == 'warning'">power_off</mat-icon>
      <p class="small rm-m ws" *ngIf="googleCalendarStatus == 'warning'">Disconnected</p>
      <mat-icon class="errorStatus fas fa-times-circle" *ngIf="googleCalendarStatus == 'error'">close</mat-icon>
      <p class="small rm-m es" *ngIf="googleCalendarStatus == 'error'">Error</p>
    </div>
  </div>

  <!-- MS Exchange -->
  <div class="row clearfix flex mb full-width" *ngIf="displayMSExchange">
    <button
      class="mr remove"
      *ngIf="canRemove == true && msExchangeStatus == 'valid'"
      @simpleFadeAnimation
      mat-mini-fab
      color="accent"
    >
      <mat-icon>delete</mat-icon>
    </button>

    <button
      class="connect-ms-exchange btn-large icon-l full-width"
      (click)="toggleMSExchange()"
      mat-raised-button
      matTooltip="{{ msExchangeLabel || '' }}{{
        msExchangeLabel && msExchangeDate && msExchangeDate !== '0000-00-00' ? ' - ' : ''
      }}{{
        msExchangeDate && msExchangeDate !== '0000-00-00' ? 'Added ' + (msExchangeDate | TimeLabelPast) + ' ago' : ''
      }}"
    >
      {{ msExchangeButtonLabel }}
      <mat-icon class="fab fa-microsoft pull-right mr"></mat-icon>
    </button>

    <div class="text-center ml statuses">
      <mat-icon class="validStatus" *ngIf="msExchangeStatus == 'valid'">power</mat-icon>
      <p class="small rm-m vs" *ngIf="msExchangeStatus == 'valid'">Connected</p>
      <mat-icon class="warningStatus" *ngIf="msExchangeStatus == 'warning'">power_off</mat-icon>
      <p class="small rm-m ws" *ngIf="msExchangeStatus == 'warning'">Disconnected</p>
      <mat-icon class="errorStatus fas fa-times-circle" *ngIf="msExchangeStatus == 'error'">close</mat-icon>
      <p class="small rm-m es" *ngIf="msExchangeStatus == 'error'">Error</p>
    </div>
  </div>

  <!-- Office 365 -->
  <!-- <div class="row clearfix flex mb full-width" *ngIf="displayOffice365">

    <button *ngIf="canRemove==true && office365Status=='valid'" mat-mini-fab
      class="mr remove" color="accent" @simpleFadeAnimation>
      <mat-icon>delete</mat-icon>
    </button>

    <button mat-raised-button class="connect-office-365 btn-large full-width "
      matTooltip="{{office365Label || ''}}{{(office365Label && office365Date)?' - ':''}}{{office365Date ? ('Added '+ (office365Date | TimeLabelPast) +' ago'):'' }}"
      (click)="syncOffice365()">{{office365ButtonLabel}}
      <mat-icon class="fab fa-microsoft"></mat-icon></button>

    <div class="text-center ml statuses">
      <mat-icon *ngIf="office365Status=='valid'" class="validStatus ">power</mat-icon>
      <p class="small rm-m vs" *ngIf="office365Status=='valid'">Connected</p>
      <mat-icon *ngIf="office365Status=='warning'" class="warningStatus  ">power_off</mat-icon>
      <p class="small rm-m ws" *ngIf="office365Status=='warning'">Disconnected</p>
      <mat-icon *ngIf="office365Status=='error'" class="errorStatus fas fa-times-circle  ">close</mat-icon>
      <p class="small rm-m es" *ngIf="office365Status=='error'">Error</p>
    </div>

  </div> -->
</div>

<div class="full-width text-center" *ngIf="buttonStyle == 'icons'">
  <button
    class="connect-fb btn-small mr"
    *ngIf="displayFacebook"
    (click)="syncFaceBook()"
    mat-mini-fab
    matTooltip="{{ facebookButtonLabel }}"
  >
    <mat-icon class="fab fa-facebook-f"></mat-icon>
  </button>

  <button
    class="connect-goog btn-small"
    *ngIf="displayGoogle"
    (click)="syncGoogle()"
    mat-mini-fab
    matTooltip="{{ googleButtonLabel }}"
  >
    <mat-icon class="fab fa-google"></mat-icon>
  </button>

  <button
    class="connect-icloud-calendar btn-small"
    *ngIf="displayiCloudCalendar"
    (click)="toggleiCloudCalendar()"
    mat-mini-fab
    matTooltip="{{ iCloudCalendarButtonLabel }}"
  >
    <mat-icon class="fab fa-apple"></mat-icon>
  </button>

  <button
    class="connect-google-calendar btn-small"
    *ngIf="displayGoogleCalendar"
    (click)="toggleGoogleCalendar()"
    mat-mini-fab
    matTooltip="{{ googleCalendarButtonLabel }}"
  >
    <mat-icon class="fab fa-google"></mat-icon>
  </button>

  <button
    class="connect-ms-exchange btn-small"
    *ngIf="displayMSExchange"
    (click)="toggleMSExchange()"
    mat-mini-fab
    matTooltip="{{ msExchangeButtonLabel }}"
  >
    <mat-icon class="fab fa-microsoft"></mat-icon>
  </button>

  <!-- <button *ngIf="displayOffice365" mat-mini-fab class="connect-office-365 btn-small" matTooltip="{{office365ButtonLabel}}" (click)="syncOffice365()">
    <mat-icon class="fab fa-microsoft"></mat-icon>
  </button> -->
</div>
