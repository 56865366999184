import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Observable, of, Subscription } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { GoogleReviewsBusinessSettingsDialogComponent } from '../dialogs/google-reviews-business-settings-dialog/google-reviews-business-settings-dialog.component';
import { GoogleReviewsSignInDialogComponent } from '../dialogs/google-reviews-sign-in-dialog/google-reviews-sign-in-dialog.component';
import { GoogleReviewsService } from '../shared/google-reviews.service';

@Component({
  selector: 'sr-google-reviews-config',
  templateUrl: './google-reviews-config.component.html',
  styleUrls: ['./google-reviews-config.component.css'],
})
export class GoogleReviewsConfigComponent implements OnInit, OnDestroy {
  @Output() connected: EventEmitter<void> = new EventEmitter();
  @Output() disconnected: EventEmitter<void> = new EventEmitter();

  private subscriptions = new Subscription();

  isServiceReady = false;
  isCheckingAccountStatus = false;

  merchantID: string;
  merchant;

  sessionType = 'guest';

  Google_Review_Code: string;
  Google_Reviews_Account_ID: string;
  Google_Reviews_Location_ID: string;
  Google_Review_Place_ID: string;

  businessInfo$: Observable<{
    Google_Reviews_Business_Name: string;
    Google_Reviews_Business_Address: string;
    Google_Reviews_Business_Rating: number;
    Google_Reviews_Business_Star_Rating: number;
    Google_Reviews_Business_Review_Count: number;
  }> = of(null);

  @Input() hasFullWidth = false;

  constructor(
    private googleReviewsService: GoogleReviewsService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      this.authenticationService.getCurrentPractice().subscribe((merchant) => {
        this.merchantID = merchant['ID'];
        this.merchant = merchant;
        this.Google_Review_Code = merchant['Google_Review_Code'];
        this.Google_Reviews_Account_ID = merchant['Google_Reviews_Account_ID'];
        this.googleReviewsService.onLoad().subscribe(() => {
          if (this.Google_Reviews_Account_ID) {
            this.Google_Reviews_Location_ID = merchant['Google_Reviews_Location_ID'];
            this.Google_Review_Place_ID = merchant['Google_Review_Place_ID'];
            this.isServiceReady = true;
            this.getBusinessInfo();
          } else {
            this.isServiceReady = true;
          }
        });
      });
    });
  }

  openManageGoogleReviewsDialog() {
    console.log(this.Google_Review_Code);
    console.groupCollapsed;
    if (this.Google_Review_Code) {
      this.openGoogleReviewsBusinessSettingsDialog();
    } else {
      this.openGoogleReviewsSignInDialog();
    }
  }

  openGoogleReviewsSignInDialog() {
    const dialogContainerRef = RootAppComponent.dialog.open<GoogleReviewsSignInDialogComponent, {}>(
      GoogleReviewsSignInDialogComponent,
      {
        disableClose: true,
      }
    );

    dialogContainerRef.afterClosed().subscribe((Google_Review_Code) => {
      if (Google_Review_Code) {
        this.Google_Review_Code = Google_Review_Code;
        this.merchant['Google_Review_Code'] = Google_Review_Code;

        this.authenticationService.setCurrentPractice(this.merchant);

        NotifyAppComponent.displayToast('success', 'Success', 'Google My Business Account Connected');

        this.openGoogleReviewsBusinessSettingsDialog();
      }
    });
  }

  openGoogleReviewsBusinessSettingsDialog() {
    const dialogContainerRef = RootAppComponent.dialog.open<GoogleReviewsBusinessSettingsDialogComponent, {}>(
      GoogleReviewsBusinessSettingsDialogComponent,
      {
        disableClose: true,
      }
    );

    dialogContainerRef.componentInstance.authenticationExpired.subscribe(() => {
      dialogContainerRef.close();
      this.openGoogleReviewsSignInDialog();
    });

    dialogContainerRef
      .afterClosed()
      .subscribe(
        (success: {
          Google_Reviews_Account_ID: string;
          Google_Reviews_Location_ID: string;
          Google_Review_Place_ID: string;
        }) => {
          if (success) {
            this.Google_Reviews_Account_ID = success.Google_Reviews_Account_ID;
            this.Google_Reviews_Location_ID = success.Google_Reviews_Location_ID;
            this.Google_Review_Place_ID = success.Google_Review_Place_ID;

            this.getBusinessInfo();
          }
        }
      );
  }

  getBusinessInfo() {
    this.isCheckingAccountStatus = true;

    this.businessInfo$ = this.googleReviewsService
      .getLocation(this.merchantID, this.sessionType, this.Google_Reviews_Location_ID)
      .pipe(
        mergeMap((location) => {
          return this.googleReviewsService
            .getReviews(
              this.merchantID,
              this.sessionType,
              this.Google_Reviews_Account_ID,
              this.Google_Reviews_Location_ID
            )
            .pipe(
              map((placeReview) => {
                this.connected.emit();

                return {
                  Google_Reviews_Business_Name: location.title,
                  Google_Reviews_Business_Address: location.storefrontAddress.addressLines[0],
                  Google_Reviews_Business_Rating: placeReview.averageRating,
                  Google_Reviews_Business_Star_Rating: Math.round(placeReview.averageRating),
                  Google_Reviews_Business_Review_Count: placeReview.totalReviewCount,
                };
              }),
              tap(() => {
                this.isCheckingAccountStatus = false;
              })
            );
        })
      );
  }

  disconnectAccount() {
    const confirmDialog: any = new ConfirmDialog(
      'settings',
      'Are you sure?',
      '<p>You are about to disconnect your Google My Business account. You will loose access to the Google Reviews feature and patients will not be able to perform reviews</p> ',
      'No',
      'Yes, disconnect'
    );

    const confirmDialogRef = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      width: '400px',
    });

    confirmDialogRef.componentInstance.onConfirm.subscribe((confirmed) => {
      if (confirmed === false) {
        confirmDialogRef.close();
      } else {
        this.isCheckingAccountStatus = true;
        confirmDialogRef.close();

        this.googleReviewsService.disconnectGoogleReviewAccount(this.sessionType, this.merchantID).subscribe(
          () => {
            this.disconnected.emit();

            this.Google_Reviews_Account_ID = null;
            this.Google_Reviews_Location_ID = null;
            this.Google_Review_Place_ID = null;
            this.Google_Review_Code = null;

            this.merchant['Google_Reviews_Account_ID'] = '';
            this.merchant['Google_Reviews_Location_ID'] = '';
            this.merchant['Google_Review_Place_ID'] = '';
            this.merchant['Google_Review_Code'] = '';

            this.authenticationService.setCurrentPractice(this.merchant);

            this.businessInfo$ = of(null);

            this.isCheckingAccountStatus = false;

            NotifyAppComponent.displayToast(
              'success',
              'Success',
              'Google Review Account Disconnected. Google Reviews Disabled.'
            );
          },
          (error) => {
            this.isCheckingAccountStatus = false;
            NotifyAppComponent.displayToast(
              'error',
              'Error',
              'Something went wrong when trying to disconnect your Google account for Google reviews.'
            );
          }
        );
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
