<div class="row clearfix">
  <mat-card class="card">
    <h3 class="sr-title rm-mb">Message responses</h3>
    <label>Understanding the different type of statuses</label>
    <hr/>

    <div class="row clearfix">
      <mat-tab-group [dynamicHeight]="true">
        <mat-tab #emailStatus *ngIf="showEmail" label="Email Statuses">
          <table class="full-width" [dataSource]="dataSourceEmail" mat-table>
            <ng-container matColumnDef="symbol">
              <th *matHeaderCellDef mat-header-cell>Icon</th>
              <td *matCellDef="let element" mat-cell>
                <mat-icon class="fas fa-{{ element.symbol }}"></mat-icon>
              </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th *matHeaderCellDef mat-header-cell>Name</th>
              <td *matCellDef="let element" mat-cell>{{ element.name }}</td>
            </ng-container>
            <ng-container matColumnDef="description">
              <th *matHeaderCellDef mat-header-cell>Description</th>
              <td *matCellDef="let element" mat-cell>{{ element.description }}</td>
            </ng-container>
            <tr *matHeaderRowDef="displayedColumns1" mat-header-row></tr>
            <tr *matRowDef="let row; columns: displayedColumns1" mat-row></tr>
          </table>
        </mat-tab>

        <mat-tab #inviteStatus *ngIf="showInvite" label="Invitation Statuses">
          <table class="full-width" [dataSource]="dataSourceInvitation" mat-table>
            <ng-container matColumnDef="name">
              <th *matHeaderCellDef mat-header-cell>Name</th>
              <td *matCellDef="let element" mat-cell>{{ element.name }}</td>
            </ng-container>
            <ng-container matColumnDef="description">
              <th *matHeaderCellDef mat-header-cell>Description</th>
              <td *matCellDef="let element" mat-cell>{{ element.description }}</td>
            </ng-container>
            <tr *matHeaderRowDef="displayedColumns2" mat-header-row></tr>
            <tr *matRowDef="let row; columns: displayedColumns2" mat-row></tr>
          </table>
        </mat-tab>

        <mat-tab *ngIf="showApp" label="Application Statuses">
          <table class="full-width" [dataSource]="dataSourceApplication" mat-table>
            <ng-container matColumnDef="name">
              <th *matHeaderCellDef mat-header-cell>Name</th>
              <td *matCellDef="let element" mat-cell>{{ element.name }}</td>
            </ng-container>
            <ng-container matColumnDef="description">
              <th *matHeaderCellDef mat-header-cell>Description</th>
              <td *matCellDef="let element" mat-cell>{{ element.description }}</td>
            </ng-container>
            <tr *matHeaderRowDef="displayedColumns2" mat-header-row></tr>
            <tr *matRowDef="let row; columns: displayedColumns2" mat-row></tr>
          </table>
        </mat-tab>

        <mat-tab *ngIf="showCont" label="Contract Statuses">
          <table class="full-width" [dataSource]="dataSourceContract" mat-table>
            <ng-container matColumnDef="name">
              <th *matHeaderCellDef mat-header-cell>Name</th>
              <td *matCellDef="let element" mat-cell>{{ element.name }}</td>
            </ng-container>
            <ng-container matColumnDef="description">
              <th *matHeaderCellDef mat-header-cell>Description</th>
              <td *matCellDef="let element" mat-cell>{{ element.description }}</td>
            </ng-container>
            <tr *matHeaderRowDef="displayedColumns2" mat-header-row></tr>
            <tr *matRowDef="let row; columns: displayedColumns2" mat-row></tr>
          </table>
        </mat-tab>

        <mat-tab *ngIf="showOther" label="Other Statuses">
          <div class="row clearfix otherstatus">
            <h4 class="rm-m">Source</h4>

            <table class="full-width" [dataSource]="dataSourceOther1" mat-table>
              <ng-container matColumnDef="symbol">
                <th *matHeaderCellDef mat-header-cell>Icon</th>
                <td *matCellDef="let element" mat-cell>
                  <mat-icon class="fas fa-{{ element.symbol }}"></mat-icon>
                </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th *matHeaderCellDef mat-header-cell>Name</th>
                <td *matCellDef="let element" mat-cell>{{ element.name }}</td>
              </ng-container>
              <ng-container matColumnDef="description">
                <th *matHeaderCellDef mat-header-cell>Description</th>
                <td *matCellDef="let element" mat-cell>{{ element.description }}</td>
              </ng-container>
              <tr *matHeaderRowDef="displayedColumns3" mat-header-row></tr>
              <tr *matRowDef="let row; columns: displayedColumns3" mat-row></tr>
            </table>
          </div>
          <div class="row clearfix otherstatus">
            <h4 class="rm-mb">Informed Consent</h4>

            <table class="full-width" [dataSource]="dataSourceOther2" mat-table>
              <ng-container matColumnDef="symbol">
                <th *matHeaderCellDef mat-header-cell>Icon</th>
                <td *matCellDef="let element" mat-cell>
                  <mat-icon class="fas fa-{{ element.symbol }}"></mat-icon>
                </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th *matHeaderCellDef mat-header-cell>Name</th>
                <td *matCellDef="let element" mat-cell>{{ element.name }}</td>
              </ng-container>
              <ng-container matColumnDef="description">
                <th *matHeaderCellDef mat-header-cell>Description</th>
                <td *matCellDef="let element" mat-cell>{{ element.description }}</td>
              </ng-container>
              <tr *matHeaderRowDef="displayedColumns3" mat-header-row></tr>
              <tr *matRowDef="let row; columns: displayedColumns3" mat-row></tr>
            </table>
          </div>

          <div class="row clearfix otherstatus">
            <h4 class="rm-mb">Time Elapsed</h4>

            <table class="full-width" [dataSource]="dataSourceOther3" mat-table>
              <ng-container matColumnDef="name">
                <th *matHeaderCellDef mat-header-cell>Name</th>
                <td *matCellDef="let element" mat-cell>{{ element.name }}</td>
              </ng-container>
              <ng-container matColumnDef="description">
                <th *matHeaderCellDef mat-header-cell>Description</th>
                <td *matCellDef="let element" mat-cell>{{ element.description }}</td>
              </ng-container>
              <tr *matHeaderRowDef="displayedColumns2" mat-header-row></tr>
              <tr *matRowDef="let row; columns: displayedColumns2" mat-row></tr>
            </table>
          </div>
          <div class="row clearfix">
            <h4 class="rm-mb">Reminders</h4>

            <p class="small">
              After 48 hours has elapsed, and the {{ "KEYWORD.patient" | translate }} has yet to make a selection
              (proceed, defer, not proceed etc.) automated reminders will automatically commence.
              The {{ "KEYWORD.patient" | translate }} will
              receive up to 5 seperate reminders over 60 day period.
            </p>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </mat-card>
</div>
