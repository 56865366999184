import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogSingleButtonComponent } from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { DentistViewModalComponent } from '../../dentist/dentist-view-modal/dentist-view-modal.component';
import { AppointmentUtilClass } from '../shared/appointmentUtil';

@Component({
  selector: 'app-appointment-card',
  templateUrl: './appointment-card.component.html',
  styleUrls: ['./appointment-card.component.css'],
})
export class AppointmentCardComponent implements OnInit {
  @Input()
  appointment;
  @Input()
  displayOnlyPatientComment = false;
  @Input()
  isMerchant = false;
  message;
  title;
  util = new UtilsClass();
  appointmentUtil = new AppointmentUtilClass();
  sessionType;

  constructor(private authenticationService: AuthenticationService, private customDate: CustomDatePipe) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((res) => {
      this.sessionType = res;
      this.buildComment();
    });
  }

  buildComment() {
    if (this.appointment) {
      if (this.displayOnlyPatientComment == true) {
        if (this.appointment['Comment']) {
          this.message = this.appointment['Comment'] || null;
          if (this.isMerchant == true) {
            this.title = 'Patient message';
          } else {
            this.title = 'Your message';
          }
        }
      } else {
        if (
          this.appointment['Status.Code'] === 'WAITINGCONFIRMATION' &&
          this.appointment['Date_Rescheduled'] == '0000-00-00'
        ) {
          this.message = this.appointment['Merchant_Comment'] || null;
          if (this.isMerchant == true) {
            this.title = 'Your additional message';
          } else {
            this.title = 'Dentist message';
          }
        } else if (
          this.appointment['Status.Code'] === 'WAITINGCONFIRMATION' &&
          this.appointment['Date_Rescheduled'] != '0000-00-00'
        ) {
          this.message = this.appointment['Rescheduled_Comment'] || null;
          if (this.isMerchant == true) {
            this.title = 'Your additional comment';
          } else {
            this.title = 'Dentist comment';
          }
        } else if (this.appointment['Status.Code'] === 'MISSED') {
          this.message = this.appointment['Missed_Comment'] || null;
          if (this.isMerchant == true) {
            this.title = 'Your additional message';
          } else {
            this.title = 'Dentist message';
          }
        } else if (this.appointment['Status.Code'] === 'CANCELED') {
          this.message = this.appointment['Canceled_Comment'] || null;
          if (this.isMerchant == true) {
            this.title = 'Patient message';
          } else {
            this.title = 'Your message';
          }
        } else if (this.appointment['Status.Code'] === 'BOOKED') {
          if (
            this.appointment['Date_Merchant_Confirmed'] &&
            this.appointment['Date_Merchant_Confirmed'] != '0000-00-00'
          ) {
            this.message = this.appointment['Confirmation_Merchant_Comment'] || null;
            if (this.isMerchant == true) {
              this.title = 'Your message';
            } else {
              this.title = 'Practice message';
            }
          }
        } else if (this.appointment['Status.Code'] === 'CANCELEDBYPRACTICE') {
          this.message = this.appointment['Canceled_Merchant_Comment'] || null;
          if (this.isMerchant == true) {
            this.title = 'Your additional message';
          } else {
            this.title = 'Dentist message';
          }
        } else if (this.appointment['Status.Code'] === 'REQUESTRESCHEDULE') {
          this.message = this.appointment['Request_Reschedule_Comment'] || null;
          if (this.isMerchant == true) {
            this.title = 'Your additional message';
          } else {
            this.title = 'Your message';
          }
        } else if (this.appointment['Status.Code'] === 'REQUESTDATECHANGE') {
          this.message = this.appointment['Request_Date_Change_Comment'] || null;
          if (this.isMerchant == true) {
            this.title = 'Patient message';
          } else {
            this.title = 'Your message';
          }
        } else if (this.appointment['Status.Code'] === 'REQUESTED') {
          this.message = this.appointment['Comment'] || null;
          if (this.isMerchant == true) {
            this.title = 'Patient message';
          } else {
            this.title = 'Your message';
          }
        }
      }
    }
  }

  showComment(title, comment) {
    if (title && comment) {
      const confirmDialog2 = new ConfirmDialogSingleButton('message', title, comment, 'OKAY');
      const ref2 = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
        data: confirmDialog2,
      });
      ref2.componentInstance.onConfirm.subscribe((res) => {
        ref2.close();
      });
    }
  }

  viewDentist() {
    if (this.appointment && this.appointment['MerchantContact_key']) {
      const ref = RootAppComponent.dialog.open(DentistViewModalComponent, {
        data: this.appointment['MerchantContact_key'],
        width: '550px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }
}
