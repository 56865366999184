import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { CustomerProspectService } from '../../customer-prospect/shared/customerProspect.service';

@Component({
  selector: 'app-customer-prospect-profile-tabs',
  templateUrl: './customer-prospect-profile-tabs.component.html',
  styleUrls: ['./customer-prospect-profile-tabs.component.css'],
})
export class CustomerProspectProfileTabsComponent implements OnInit {
  @Input()
  patientID;

  @Input()
  tabs;

  @Input()
  itemID;

  @Input()
  appointmentID;

  @Input()
  selectedMerchant = localStorage.getItem('selectedMerchant');

  @Output()
  merchantID = new EventEmitter();

  @Output()
  birthday = new EventEmitter();

  @Input()
  isIntegrated = false;

  @Output()
  goBack = new EventEmitter();

  @Input()
  displayTabs = true;
  @Input()
  settings = 'patient';

  customerID;
  prospectID;
  sessionType = 'guest';

  constructor(
    private authenticationService: AuthenticationService,
    private customerProspectService: CustomerProspectService,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['patientID']) {
        this.patientID = params['patientID'];
      }
      if (params['appointmentID']) {
        this.appointmentID = params['appointmentID'];
      }

      this.authenticationService.getSessionType().subscribe((sessionType) => {
        if (sessionType) {
          this.sessionType = sessionType;
        }

        const payload = {
          fields: 'ID,TableName',
        };
        if (this.patientID) {
          this.customerProspectService
            .getCustomerProspectDetails(this.patientID, payload, this.sessionType)
            .subscribe((res) => {
              if (res) {
                if (res['TableName'] == 'Customer') {
                  this.customerID = res['ID'];
                } else if (res['TableName'] == 'Prospect') {
                  this.prospectID = res['ID'];
                }
              }
            });
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const payload = {
      fields: 'ID,TableName',
    };

    this.customerProspectService
      .getCustomerProspectDetails(this.patientID, payload, this.sessionType)
      .subscribe((res) => {
        if (res['TableName'] === 'Prospect') {
          this.prospectID = res['ID'];
          this.customerID = null;
        } else if (res['TableName'] === 'Customer') {
          this.customerID = res['ID'];
          this.prospectID = null;
        }
      });
  }

  getMerchantID(e) {
    this.merchantID.emit(e);
  }

  getBirthday(e) {
    if (e) {
      this.birthday.emit(e);
    }
  }

  goBackEvent(e) {
    this.goBack.emit(e);
  }
}
