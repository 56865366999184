<ipv-container-modal [enableDragging]="true">
  <ng-container banner>
    <div style="display: flex; justify-content: space-between">
      <div style="display: flex">
        <mat-icon style="font-size: 40px; height: 40px; width: 40px"> visibility</mat-icon>

        <div style="display: block">
          <h2 class="summary-header rm-m">PREVIEW SMS</h2>
        </div>
      </div>

      <button class="btn-close btn-clear mat-button white" (click)="close()" style="height: 24px" matDialogClose>
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </ng-container>

  <ng-container content>
    <div class="marvel-device iphone8" style="zoom: 0.8">
      <div class="top-bar"></div>
      <div class="sleep"></div>
      <div class="volume"></div>
      <div class="camera"></div>
      <div class="sensor"></div>
      <div class="speaker"></div>
      <div class="screen">
        <span class="messages">
          <p class="speech-bubble preview" [innerHTML]="message | safeHtml"></p>
        </span>
      </div>

      <div class="home"></div>
      <div class="bottom-bar"></div>
    </div>
  </ng-container>
</ipv-container-modal>
