<div class="row clearfix full-width">
  <mat-button-toggle-group
    class="full-width btn-group text-center with-icons"
    #groupB="matButtonToggleGroup"
    [(ngModel)]="groupFilter"
  >
    <mat-button-toggle class="text-center full-width" [value]="'all'">
      <mat-icon class="fas fa-infinity"></mat-icon>
      All
    </mat-button-toggle>
    <mat-button-toggle class="text-center full-width"
                       [value]="'treatment'"> {{"KEYWORD.Treatment" | translate}} </mat-button-toggle>
    <mat-button-toggle class="text-center full-width" [value]="'dentist'">
      {{ "KEYWORD.practitioner" | translate | titlecase }}
    </mat-button-toggle>
    <mat-button-toggle class="text-center full-width"
                       [value]="'practice'">{{ "KEYWORD.practice" | translate | titlecase}}</mat-button-toggle>
    <mat-button-toggle class="text-center full-width" [value]="'supplier'"> Supplier</mat-button-toggle>
    <mat-button-toggle class="text-center full-width"
                       [value]="'patient'"> {{ "KEYWORD.patient" | translate | titlecase }}</mat-button-toggle>
  </mat-button-toggle-group>

  <div class="full-width row clearfix">
    <app-treatment-document-gallery
      *ngIf="groupFilter == 'all'"
      [merchantID]="merchantID"
      [manufacturerID]="manufacturerID"
      [distributorID]="distributorID"
      [supplierID]="supplierID"
      [categoryID]="categoryID"
      [brandID]="brandID"
      [typeID]="typeID"
      [dateFrom]="dateFrom"
      [dateTo]="dateTo"
      [productID]="productID"
      [title]="title"
      [pageSize]="pageSize"
    >
    </app-treatment-document-gallery>
    <app-treatment-document-gallery
      *ngIf="groupFilter == 'treatment'"
      [merchantID]="merchantID"
      [manufacturerID]="manufacturerID"
      [distributorID]="distributorID"
      [supplierID]="supplierID"
      [categoryID]="categoryID"
      [brandID]="brandID"
      [typeID]="typeID"
      [dateFrom]="dateFrom"
      [dateTo]="dateTo"
      [productID]="productID"
      [title]="title"
      [pageSize]="pageSize"
      [documentTypes]="'SRVPRE,SRVPST,SRVMKT,SRVSTC'"
    >
    </app-treatment-document-gallery>

    <app-treatment-document-gallery
      *ngIf="groupFilter == 'dentist'"
      [merchantID]="merchantID"
      [manufacturerID]="manufacturerID"
      [distributorID]="distributorID"
      [supplierID]="supplierID"
      [categoryID]="categoryID"
      [brandID]="brandID"
      [typeID]="typeID"
      [dateFrom]="dateFrom"
      [dateTo]="dateTo"
      [productID]="productID"
      [title]="title"
      [pageSize]="pageSize"
      [displayedColumns]="displayedColumnsDentistDocs"
      [documentTypes]="'SRVSPL'"
    >
    </app-treatment-document-gallery>

    <app-document-list *ngIf="groupFilter == 'patient'" [merchantID]="merchantID" [programAreaCode]="'MEDRD'">
    </app-document-list>

    <app-document-list *ngIf="groupFilter == 'supplier'" [merchantID]="merchantID" [type]="'SRVSPL'">
    </app-document-list>

    <app-document-list
      *ngIf="groupFilter == 'practice'"
      [merchantID]="merchantID"
      [type]="'SRVSTC'"
      [cardID]="merchantID"
    >
    </app-document-list>
  </div>
</div>
