import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { SessionsService } from '../../../core/session/shared/sessions.service';
import { CalendlyComponent } from '../../../shared/components/calendly/calendly.component';
import { ConfirmDialogSingleButtonComponent } from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { InvitationService } from '../../invitation/shared/services/invitation.service';
import { MarketingNewCampaignModalComponent } from '../../marketing/marketing-new-campaign-modal/marketing-new-campaign-modal.component';
import { MarketingService } from '../../marketing/shared/marketing.service';
import { MerchantDataImportComponent } from '../../merchant/merchant-data-import/merchant-data-import.component';
import { MerchantUpdateWebsiteComponent } from '../../merchant/merchant-update-website-modal/merchant-update-website-modal.component';
import { ProductService } from '../../product/shared/product.service';
import { ProductUtil } from '../../product/shared/productUtil.type';
import { TestimonyService } from '../../testimony/shared/testimony.service';
import { TestimonyCreateEditComponent } from '../../testimony/testimony-create-edit/testimony-create-edit.component';
import { TasksService } from '../shared/tasks.service';

@Component({
  selector: 'app-tasks-view',
  templateUrl: './tasks-view.component.html',
  styleUrls: ['./tasks-view.component.css'],
})
export class TasksViewComponent implements OnInit {
  @Input()
  merchantID;

  items = [
    { complete: true, label: 'Review your practice details', action: 'practiceDetails()', actionLabel: 'Review Now' },
    { complete: false, label: 'Book in Training Session', action: 'bookTraining()', actionLabel: 'Book Training' },
    { complete: false, label: 'Import your patient database', action: 'importData()', actionLabel: 'Import Data' },
    {
      complete: false,
      label: 'Send first marketing email',
      action: 'marketingCreate()',
      actionLabel: 'Create Campaign',
    },
    { complete: true, label: 'Send first patient invite', action: 'invitationCreate()', actionLabel: 'Send Invite' },
    { complete: true, label: 'Bookmark page in your browser', action: 'bookmark()', actionLabel: 'Bookmark' },
  ];

  @Output()
  hideTaskPanel = new EventEmitter();
  tasks;

  isPromoterOrAdmin = false;

  value = 0;
  prograssValue = 0;

  financedProductGroup = [];

  isModuleTreatmentPlanActive = Settings.global['isModuleTreatmentPlanActive'];
  productUtil = new ProductUtil();

  constructor(
    private router: Router,
    private tasksService: TasksService,
    private authenticationService: AuthenticationService,
    private invitationService: InvitationService,
    private marketingService: MarketingService,
    private sessionService: SessionsService,
    private utilService: UtilsService,
    private productService: ProductService,
    private testimonyService: TestimonyService
  ) {}

  ngOnInit() {
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleTreatmentPlanActive = access['isModuleTreatmentPlanActive'];
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        const payload = {
          orderBy: 'Task.Type',
        };
        setTimeout(() => {
          if (this.isPromoterOrAdmin == true) {
            payload['merchantID'] = this.merchantID;
          } else {
            this.tasksService.getAssignedTasksListHidden(payload, this.isPromoterOrAdmin).subscribe((res) => {
              if (res && res.length > 0) {
                this.tasks = res;
                this.autoSetTasks();
                this.calculateProgress();
              }
            });
          }

          if (this.isModuleTreatmentPlanActive == true) {
            const p1 = {
              isFinance: true,
            };
            this.productService.getMerchantProductGroupHidden(p1, this.isPromoterOrAdmin).subscribe((res) => {
              if (res) {
                this.financedProductGroup = res;
              }
            });
          }
        }, 500);
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      const payload = {
        orderBy: 'Task.Type',
      };

      if (this.isPromoterOrAdmin == true) {
        payload['merchantID'] = this.merchantID;
      }
      this.tasksService.getAssignedTasksList(payload, this.isPromoterOrAdmin).subscribe((res) => {
        if (res && res.length > 0) {
          this.tasks = res;
          this.calculateProgress();
        }
      });
    });
  }

  calculateProgress() {
    const count = this.tasks.filter((item) => item['IsCompleted'] == '1').length;
    this.value = count / this.tasks.length;
    this.prograssValue = Math.floor((count / this.tasks.length) * 100);

    if (this.value == 1) {
      this.hideTaskPanel.emit(true);
    }
  }

  autoSetTasks() {
    if (this.tasks && this.isPromoterOrAdmin == false) {
      const invTask = this.tasks.find((obj) => obj['Task.Type'] == 'Send Invite');
      const campaignTask = this.tasks.find((obj) => obj['Task.Type'] == 'Create Campaign');
      const practiceTask = this.tasks.find((obj) => obj['Task.Type'] == 'Review Now');
      const rateUsTask = this.tasks.find((obj) => obj['Task.Type'] == 'Rate Us');
      if (invTask && invTask.IsCompleted == '0') {
        const payloadInvitation = {
          merchantID: this.merchantID,
          selectProduct: false,
          selectYears: false,
          selectDays: false,
          selectMonths: false,
          selectDentist: false,
          selectMerchant: false,
          selectStatus: false,
        };

        this.invitationService
          .getStatistics(payloadInvitation, this.isPromoterOrAdmin, false, true)
          .subscribe((res) => {
            if (res && res.global['Count(*)'] && Number(res.global['Count(*)']) > 0) {
              this.tasksService.setTaskCompleted(invTask.ID).subscribe((res) => {
                if (res) {
                  this.replaceItem(res);
                }
              });
            }
          });
      }

      if (campaignTask && campaignTask.IsCompleted == '0') {
        const payloadCampaign = {
          merchantID: this.merchantID,
          selectStatus: false,
        };

        this.marketingService.getStatistics(payloadCampaign, this.isPromoterOrAdmin).subscribe((res) => {
          if (res && res.global['Count(*)'] && Number(res.global['Count(*)']) > 0) {
            this.tasksService.setTaskCompleted(campaignTask.ID).subscribe((res) => {
              if (res) {
                this.replaceItem(res);
              }
            });
          }
        });
      }

      if (practiceTask && practiceTask.IsCompleted == '0') {
        this.authenticationService.getCurrentPracticeForce().subscribe((res) => {
          if (res && res.Description) {
            this.sessionService.getPracticePic().subscribe((pic) => {
              if (pic) {
                this.tasksService.setTaskCompleted(practiceTask.ID).subscribe((res) => {
                  if (res) {
                    this.replaceItem(res);
                  }
                });
              }
            });
          }
        });
      }

      if (rateUsTask && rateUsTask.IsCompleted == '0') {
        this.testimonyService.getTestimonyList().subscribe((res) => {
          if (res && res.length > 0) {
            this.tasksService.setTaskCompleted(rateUsTask.ID).subscribe((res) => {
              if (res) {
                this.replaceItem(res);
              }
            });
          }
        });
      }
    }
  }

  actions(_task) {
    if (_task && _task.ID && _task.IsCompleted == '0') {
      const action = _task['Task.Name'];

      switch (action) {
        case 'Bookmark':
          const confirm = new ConfirmDialog(
            'fas fa-info',
            'Bookmark the app',
            'Have you bookmarked the app in your browser? You can <strong>press CTRL + D</strong> on your keyboard to automatically create a bookmark ( &#8984; + D on Apple computers).',
            'No',
            'Yes'
          );
          const refBookmark = RootAppComponent.dialog.open(ConfirmDialogComponent, {
            data: confirm,
            width: '650px',
          });

          refBookmark.componentInstance.onConfirm.subscribe((res) => {
            if (res == true) {
              this.tasksService.setTaskCompleted(_task.ID).subscribe((res) => {
                if (res) {
                  NotifyAppComponent.displayToast('success', 'Set Task', `Successfully set task as completed.`);
                  refBookmark.close();

                  this.replaceItem(res);
                }
              });
            } else {
              refBookmark.close();
            }
          });
          break;

        case 'Send Invite':
          break;

        case 'Create Campaign':
          const refCampaign = RootAppComponent.dialog.open(MarketingNewCampaignModalComponent, {
            width: '750px',
          });
          refCampaign.componentInstance.closeModal.subscribe((res) => {
            refCampaign.close();
          });
          break;

        case 'Import Data':
          const refImportData = RootAppComponent.dialog.open(MerchantDataImportComponent, {
            width: '750px',
          });

          refImportData.componentInstance.close.subscribe((res) => {
            refImportData.close();
          });
          break;

        case 'Book Training':
          const refBooking = RootAppComponent.dialog.open(CalendlyComponent, {
            width: '650px',
          });

          refBooking.componentInstance.close.subscribe((res) => {
            refBooking.close();
          });
          break;

        case 'Review Now':
          this.router.navigate([
            '/merchant',
            {
              outlets: { page: ['dentist-overview', 'practice'] },
            },
          ]);
          break;

        case 'Rate Us':
          const refRate = RootAppComponent.dialog.open(TestimonyCreateEditComponent, {
            width: '650px',
            panelClass: 'rm-bp',
          });
          refRate.componentInstance.close.subscribe((res) => {
            refRate.close();
          });
          break;

        case 'Update Website':
          const refWebsite = RootAppComponent.dialog.open(MerchantUpdateWebsiteComponent, {
            width: '700px',
            panelClass: 'rm-bp',
          });
          refWebsite.componentInstance.close.subscribe((res) => {
            refWebsite.close();
          });
          refWebsite.componentInstance.onConfirm.subscribe((res) => {
            if (res == true) {
              this.tasksService.setTaskCompleted(_task.ID).subscribe((res) => {
                if (res) {
                  NotifyAppComponent.displayToast('success', 'Set Task', `Successfully set task as completed.`);
                  refBookmark.close();

                  this.replaceItem(res);
                }
              });
            } else {
              refBookmark.close();
            }
          });
          break;

        default:
        // code
      }
    }
  }

  replaceItem(item) {
    const _index = this.tasks.findIndex((_obj) => _obj.ID == item.ID);
    this.tasks[_index] = item;
    this.calculateProgress();
  }

  getLabelFromProductGroup(p) {
    if (p && p['GroupLabel']) {
      const t = p['GroupLabel'].split(' - ');

      if (t && t[0]) {
        if (t[0][t.length - 1] == 's') {
          return t[0].substr(0, t[0].length - 1);
        } else if (t[0][t.length - 2] == 's') {
          return t[0].substr(0, t[0].length - 2);
        }
        return t[0];
      }
      return null;
    }

    return null;
  }

  newPatientInv(p) {
    if (p && p['GroupCode']) {
      const paylaod = {};
      this.productService.getMerchantProductGroup(paylaod, this.isPromoterOrAdmin).subscribe((res) => {
        const check = res.some((val) => {
          if (val['GroupCode'] === p['GroupCode']) {
            return true;
          } else {
            return false;
          }
        });

        if (check == true) {
          this.router.navigate(['/merchant', { outlets: { page: ['new-invitation', p['GroupCode']] } }]);
        } else {
          const confirmDialog = new ConfirmDialogSingleButton(
            'warning',
            p['Group.Label'] + " hasn't been set up for your practice.",
            'Please contact Smile Right for more information',
            'Okay'
          );
          const ref = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
            data: confirmDialog,
            width: '650px',
          });

          ref.componentInstance.onConfirm.subscribe((res) => {
            ref.close();
          });
        }
      });
    }
  }

  getProductIcon(p) {
    if (p && p['GroupCode']) {
      return this.productUtil.getProductGroupIcon(p['GroupCode']);
    } else {
      return 'envelope-open';
    }
  }
}
