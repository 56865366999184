import { Routes } from '@angular/router';
import { ActivityLogListOverviewComponent } from '../activity-log-list-overview/activity-log-list-overview.component';
const ɵ0 = { title: 'Activities' };
const routes = [
    {
        path: 'activity-log-list-overview',
        component: ActivityLogListOverviewComponent,
        data: ɵ0,
    },
];
export class ActivityLogRoutingModule {
}
export { ɵ0 };
