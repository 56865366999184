<div class="rel">
  <div class="card-header primary-gradient-img clearfix confirmModal">
    <button class="btn-close btn-clear mat-button smt white custom-close" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="full-width flex main-title-container">
      <div class="main-icon-container text-center">
        <mat-icon class="main-icon accent-color" *ngIf="isFontAwsome(icon) != true"> {{ icon || 'info' }}</mat-icon>

        <mat-icon
          class="main-icon accent-color {{ icon || 'fas fa-info' }}"
          *ngIf="isFontAwsome(icon) == true"
        ></mat-icon>
      </div>
      <div class="row full-width title-container clearfix">
        <div class="title-container-sub">
          <h2 class="text-left title-header rm-m">Insert a Link</h2>
        </div>
      </div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="full-width smt">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Link</mat-label>
        <input [(ngModel)]="link" matInput placeholder="Insert a valid link" name="link" required/>
      </mat-form-field>

      <mat-form-field class="full-width" *ngIf="displayTitle == true" appearance="outline">
        <mat-label>Title</mat-label>
        <input [(ngModel)]="title" matInput placeholder="Insert a title" name="title"/>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <hr/>
  <div class="drop-button text-center mt full-width">
    <button class="pull-right btn-large" [disabled]="!link" (click)="send()" mat-raised-button color="accent">
      Insert
    </button>

    <button class="pull-right btn-large mr" [disabled]="!link" (click)="preview()" mat-raised-button color="primary">
      Preview
    </button>

    <button class="pull-left btn-large" (click)="closeEvent()" mat-raised-button color="primary">Close</button>
  </div>
</div>
