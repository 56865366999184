import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { ClientDetails } from '../types/client-details';
import { Settings } from '../types/settings';

@Pipe({
  name: 'customDateTime',
})
export class CustomDateTimePipe implements PipeTransform {
  constructor() {}

  transform(expression: any, format: string = null, timeZone = true): string {
    try {
      if (expression == '0000-00-00') {
        return null;
      } else if (expression == '0000-00-00 00:00') {
        return null;
      }
      let d;
      const raw = moment(expression);
      const hours = raw.hours();
      const mins = raw.minutes();
      const seconds = raw.seconds();
      const milSeconds = raw.milliseconds();

      if (hours == 0 && mins == 0 && seconds == 0 && milSeconds == 0) {
        d = moment(expression).toDate();
      } else {
        d = ClientDetails.convertTimeZone(expression, timeZone);
      }

      if (!expression || expression == '') {
        return null;
      }

      if (format == null) {
        format = Settings.global['dateTimeFormat'];
      }

      return moment(d).format(format);
    } catch (e) {
      return null;
    }
  }
}
