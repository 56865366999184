import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';

import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as momentTZ from 'moment-timezone';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { SessionsService } from '../../../core/session/shared/sessions.service';
import { MapViewComponent } from '../../../shared/components/map-view/map-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { MerchantMapModalComponent } from '../../merchant/merchant-map-modal/merchant-map-modal.component';
import { MerchantTradingHoursViewComponent } from '../../merchant/merchant-trading-hours-view/merchant-trading-hours-view.component';
import { MerchantViewModalComponent } from '../../merchant/merchant-view-modal/merchant-view-modal.component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { DentistViewModalComponent } from '../dentist-view-modal/dentist-view-modal.component';
import { DentistService } from '../shared/dentist.service';

@Component({
  selector: 'app-dentist-view-consumer',
  templateUrl: './dentist-view-consumer.component.html',
  styleUrls: ['./dentist-view-consumer.component.css'],
  providers: [CustomPhonePipe],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),

    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 0 })),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),

        group([animate(300, style({ height: 0 })), animate('200ms ease-in-out', style({ opacity: '0' }))]),
      ]),
      transition(':enter', [
        style({ height: '0', opacity: 0 }),

        group([animate(300, style({ height: '*' })), animate('400ms ease-in-out', style({ opacity: '1' }))]),
      ]),
    ]),
  ],
})
export class DentistViewConsumerComponent implements OnInit {
  @Input() dentistID;

  @Input() merchantID;
  @Input() displayProfilePicture = true;
  @Input() displayPracticeCard = true;

  @Input() displayDentistCard = true;

  @Input() displayFullWidth = false;

  @Input() displayIntroText = false;

  @Input() largeProfile = false;
  @Input() messageFromPractice;
  @Input() messageFromPracticeTitle;
  subjectArray = [];

  @Input()
  invitationID;

  @Output() close = new EventEmitter();
  dentist;
  merchant;
  profilePic;
  practicePic;
  loading = false;
  currentUser;
  operators;
  isOpen = false;
  closeTime;
  openTime;
  util = new UtilsClass();

  profileLink;

  practiceCoverLink;
  practiceCoverLinkURL;
  defaultPicture =
    'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/consumer/dentist-portal-header.png)';
  practiceLink;

  isModal = false;

  @Input()
  actionView = true;

  @Input()
  messageFromDentist;

  @Input()
  displayAdress = true;
  sessionType = 'merchant';

  constructor(
    private dentistService: DentistService,
    private customPhone: CustomPhonePipe,
    private seesionService: SessionsService,
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private merchantService: MerchantService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.dentistID = data;
      this.isModal = true;
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['consumerID']) {
        this.invitationID = params['consumerID'];
      }
      this.authenticationService.getSessionType().subscribe((sessionType) => {
        if (sessionType) {
          this.sessionType = sessionType;
        }
        this.authenticationService.getCurrentUser().subscribe((res) => {
          if (res) {
            this.currentUser = res.data;
          }

          if (this.dentistID) {
            this.dentistService.getContactDetails(this.dentistID, {}, this.sessionType).subscribe((res) => {
              this.dentist = res;
              this.loading = true;

              this.getProfilePicture();
              this.getPracticePicture(this.dentist['ContactForCard_key']);
              const payload = {
                merchantID: this.dentist['ContactForCard_key'],
              };
              this.merchantService
                .getMerchantDetails(this.dentist['ContactForCard_key'], {}, this.sessionType)
                .subscribe((res) => {
                  this.merchant = res;
                  this.merchantID = this.dentist['ContactForCard_key'];
                });
              this.merchantService.getTradingHourListGlobal(payload).subscribe((res) => {
                const timeList = res;

                const todayDate = momentTZ().format('dddd');
                for (const item of timeList) {
                  if (todayDate == item['DayOfWeek.Label.Singular']) {
                    this.checkOpen(item);
                  }
                }
              });
            });
          } else if (!this.dentistID && this.merchantID) {
            this.getProfilePicture();
            this.getPracticePicture(this.merchantID);

            this.practiceCoverLink = this.merchantService.getPracticePicCoverStreamLink(this.merchantID);
            if (this.practiceCoverLink) {
              this.practiceCoverLinkURL = 'url(' + this.practiceCoverLink + ')';
            }

            const payload = {
              merchantID: this.merchantID,
            };
            this.merchantService.getMerchantDetails(this.merchantID, {}, this.sessionType).subscribe((res) => {
              this.merchant = res;
            });
            this.merchantService.getTradingHourListGlobal(payload).subscribe((res) => {
              const timeList = res;

              const todayDate = momentTZ().format('dddd');
              for (const item of timeList) {
                if (todayDate == item['DayOfWeek.Label.Singular']) {
                  this.checkOpen(item);
                }
              }
            });

            this.loading = true;

            this.merchantService.getPracticePic(this.merchantID).subscribe((res) => {
              this.loading = false;
              if (res) {
                this.practicePic = res;
              }
            });
          }
        });
      });
    });
  }

  dialPractice(phone) {
    window.location.href = 'tel:' + phone;
  }

  getMoreInfo() {
    let phone = '';

    if (this.dentist['phones.Number']) {
      phone = this.customPhone.transform(String(this.dentist['phones.Number']), 'landLine');
    }

    const result =
      "<p><strong>Dentist's name: </strong>" +
      this.dentist['CalculatedName'] +
      '</p><p><strong>Phone: </strong>' +
      String(phone) +
      "</p><p><strong>Email: </strong> <a class='emailLink primary-color' href='mailto:" +
      String(this.dentist['emails.Email']) +
      "' title='Email Dentist' target='_blank'>" +
      this.dentist['emails.Email'] +
      '</a></p>';

    return result;
  }

  emailPractice(email) {
    window.location.href = 'mailto:' + email + "?subject=I've got a question about my upcoming treatment";
  }

  practiceWebsite(link) {
    let url = '';
    if (!/^http[s]?:\/\//.test(link)) {
      url += 'https://';
    }
    url += link;
    window.open(url, '_blank', 'noopener');
  }

  openSocialLink(link) {
    // check if link has http or https and add it if not
    let url = '';
    if (!/^http[s]?:\/\//.test(link)) {
      url += 'https://';
    }
    url += link;
    window.open(url, '_blank', 'noopener');
  }

  displayMap() {
    const location = {
      zoom: 17,
      postCode: this.dentist['addresses.Postcode'],
      state: this.dentist['addresses.State'],
      streetType: this.dentist['addresses.Street Type'],
      streetName: this.dentist['addresses.Street Name'],
      streetNumber: this.dentist['addresses.Street Nr'],
      suburb: this.dentist['addresses.Suburb'],
      country: this.dentist['addresses.Country.Code'],
      addressLabel: this.dentist['addresses.Calculated'],
      latitude: this.dentist['Address.Latitude'],
      longitude: this.dentist['Address.Longitude'],
      moreInfo: this.getMoreInfo(),
    };

    const ref = RootAppComponent.dialog.open(MapViewComponent, {
      data: location,
      width: '650px',
    });

    ref.componentInstance.addCoordinate.subscribe((res) => {
      if (res && res.latitude && res.longitude) {
        const payload = {
          latitude: res.latitude,
          longitude: res.longitude,
        };
        this.dentistService.addCoordinate(this.dentistID, payload).subscribe((res) => {});
      }
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  contactDentist() {
    const data = {
      invitationID: this.invitationID,
      targetType: 'contact',
      targetID: this.dentist.ID,
      asGuest: false,
      asProfile: true,
      asPractice: true,
      subjectArray: this.subjectArray,
    };
    AuthenticationService.contactInputEvent.emit(data);
  }

  closeEvent() {
    this.close.emit(true);
  }

  checkOpen(date) {
    const localTimezone = momentTZ.tz.guess();
    const time = momentTZ.tz(date['TimeZone']);
    const start = momentTZ.tz(date['OpeningTime'], 'HH:mm', date['TimeZone']);
    const end = momentTZ.tz(date['ClosingTime'], 'HH:mm', date['TimeZone']);

    if (time.isBetween(start, end) && date['NotOpen'] == '0') {
      this.isOpen = true;
      const _closeTime = momentTZ.tz(end, localTimezone).format('HH:mm');
      this.closeTime = this.util.timeConvert(_closeTime);
    } else {
      this.isOpen = false;
    }
  }

  viewDentistModal() {
    if (this.dentistID) {
      const ref = RootAppComponent.dialog.open(DentistViewModalComponent, {
        data: this.dentistID,
        width: '550px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  viewMerchantModal() {
    if (this.merchant.ID) {
      const ref = RootAppComponent.dialog.open(MerchantViewModalComponent, {
        data: this.merchant['ID'],
        width: '550px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  viewMerchantMapModal() {
    const location = {
      merchantID: this.merchant['ID'],
      zoom: 17,
      postCode: this.dentist['addresses.Postcode'],
      state: this.dentist['addresses.State'],
      streetType: this.dentist['addresses.Street Type'],
      streetName: this.dentist['addresses.Street Name'],
      streetNumber: this.dentist['addresses.Street Nr'],
      suburb: this.dentist['addresses.Suburb'],
      country: this.dentist['addresses.Country.Code'],
      addressLabel: this.dentist['addresses.Calculated'],
      latitude: this.dentist['Address.Latitude'],
      longitude: this.dentist['Address.Longitude'],
      moreInfo: this.getMoreInfo(),
    };

    const ref = RootAppComponent.dialog.open(MerchantMapModalComponent, {
      data: location,
      width: '750px',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  viewTradingHrs() {
    const ref = RootAppComponent.dialog.open(MerchantTradingHoursViewComponent, {
      data: this.merchant['ID'],
      width: '450px',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  getProfilePicture() {
    this.profileLink = null;

    this.profileLink = this.dentistService.getProfilePicStreamLink(this.dentistID);
  }

  getPracticePicture(merchantID) {
    this.practiceLink = null;

    this.practiceLink = this.merchantService.getPracticePicStreamLink(merchantID);
  }
}
