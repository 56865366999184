import { NgModule } from '@angular/core';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { SharedModule } from '../../shared/shared.module';
import { GeneralSettingsComponent } from './general-settings/general-settings.component';
import { ModuleEditComponent } from './module-edit/module-edit.component';
import { SettingsRoutingModule } from './shared/settings-routing.module';
import { SettingsService } from './shared/settings.service';

@NgModule({
  imports: [PasswordStrengthMeterModule, SharedModule, SettingsRoutingModule],
  declarations: [GeneralSettingsComponent, ModuleEditComponent],
  exports: [GeneralSettingsComponent, ModuleEditComponent],
  providers: [SettingsService],
})
export class SettingsModule {}
