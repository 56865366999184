import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TasksService {
  constructor(private http: HttpClient) {}

  createTasks(body): Observable<any> {
    return this.http
      .post(environment.nodeUrl + '/tasks-assigned', body)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editTasks(id, body): Observable<any> {
    return this.http
      .put(environment.nodeUrl + '/tasks-assigned/taskMerchant/' + id, body)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  deletePracticeTask(id): Observable<any> {
    return this.http
      .delete(environment.nodeUrl + '/tasks-merchant/' + id)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPracticeTaskList(body = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/tasks-merchant', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPracticeTaskPicture(id): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/tasks-merchant/picture/' + id, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getAssignedTasksList(body = {}, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/tasks-assigned/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/tasks-assigned', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getAssignedTasksListHidden(body = {}, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/tasks-assigned/hidden/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/tasks-assigned/hidden', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getFullAssignedTasksList(body = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/tasks-assigned/global/full', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  assignTask(body): Observable<any> {
    return this.http
      .post(environment.nodeUrl + '/tasks-assigned', body)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  setTaskCompleted(id): Observable<any> {
    return this.http
      .put(environment.nodeUrl + '/tasks-assigned/setTaskCompleted/' + id, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  setTaskUncompleted(id): Observable<any> {
    return this.http
      .put(environment.nodeUrl + '/tasks-assigned/setTaskUncompleted/' + id, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPracticeTaskDetails(ID): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/tasks-merchant/' + ID, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getAssignedTaskDetails(ID): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/tasks-assigned/' + ID, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }
}
