import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import * as $ from 'jquery';
import { ZoomInInfoComponent } from '../../../core/helper/zoom-in-info/zoom-in-info.component';
import { FileService } from '../../services/file.service';
import { NotifyAppComponent } from '../../types/notify-app-component';
import { UtilsClass } from '../../types/utils/utils.class';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../root-component/root-component.component';

/**
 * @File content modal
 */
@Component({
  selector: 'app-file-content',
  templateUrl: 'file-content.component.html',
  styleUrls: ['./file-content.component.css'],
})
export class FileContentComponent implements OnInit, OnChanges {
  @Output() remove = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() getFile = new EventEmitter();
  @Input() canAction = false;
  @Input() actionLabel = 'Select';

  @Input()
  isExternalLink = false;

  defaultImage;
  @Input() contentOnly = false;

  @Input() isModal = false;
  @Input()
  showInfo = true;

  isError = false;
  isLoaded = false;

  isFullScreenModal = false;
  @Input() invitation_id = '';
  imgTypes = ['image/png', 'image/jpeg'];
  fileIndex = 1;
  filename = '';
  pdfUrl: any;

  fileLink;
  htmlUrlString = '';
  imgUrlString = '';
  htmlUrl;
  imgUrl;
  file: any = {};
  mimeDocumentType;
  @Input() startIndex = 0;
  @Input() files: any[] = [];
  @Input() fileID;
  @Input() canEdit = false;
  filesContent: any[] = [];
  zoomInInfo = false;
  videoSource: string;
  documentSource;

  util = new UtilsClass();

  pinchZoomProperties = [
    {
      wheel: true,
      autoHeight: true,
    },
  ];

  constructor(
    private dialog: MatDialog,
    private activeRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private fileService: FileService
  ) {
    if (data) {
      if (data.startIndex) {
        this.startIndex = data.startIndex;
      }
      if (data.fileID) {
        this.fileID = data.fileID;
      }

      if (data.files) {
        this.isModal = true;
      }
      if (data.canEdit != null) {
        this.canEdit = data.canEdit;
      }

      if (data.invitationID != null) {
        this.invitation_id = data.invitationID;
      }

      if (data.showInfo != null) {
        this.showInfo = data.showInfo;
      }

      if (data.isModal !== null) {
        this.isModal = data.isModal;
      }

      if (data.contentOnly !== null) {
        this.contentOnly = data.contentOnly;
      }

      if (data.files && data.files.length > 0) {
        const ids = this.transfor(data.files);

        if (ids.length > 0) {
          this.fileService.getArrayFile(ids).subscribe((res) => {
            if (res && res.length > 0) {
              this.files = res;

              this.file = this.files[this.startIndex];
              this.filesContent.push(this.file);
              this.displayFile();
            }
          });
        }
      } else if (data.file) {
        this.isModal = true;
        this.fileIndex = 0;
        this.file = data.file;
        this.files.push(this.file);
        this.filesContent.push(this.file);
        this.displayFile();
      } else {
        this.files = [];
      }
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['documentID']) {
        this.files = params['documentID'].split(',');

        this.setUp();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fileID && changes.fileID.previousValue !== changes.fileID.currentValue && changes.fileID.currentValue) {
      this.setUp();
    } else if (
      changes.files &&
      changes.files.currentValue &&
      JSON.stringify(changes.files.previousValue) !== JSON.stringify(changes.files.currentValue)
    ) {
      this.setUp();
    }
  }

  setUp() {
    if (this.fileID) {
      this.fileService.getFile(this.fileID).subscribe((res) => {
        if (res) {
          this.files = [res];
          this.fileIndex = 0;
          this.file = this.files[0];

          this.filesContent.push(this.file);
          this.displayFile();
        }
      });
    } else {
      const ids = this.transfor(this.files);

      if (ids.length > 0) {
        this.fileService.getArrayFile(ids).subscribe((res) => {
          if (res && res.length > 0) {
            this.files = res;
            this.fileIndex = this.startIndex;
            this.file = this.files[this.startIndex];

            this.filesContent.push(this.file);
            this.displayFile();
          }
        });
      } else {
        this.files = [];
      }
    }
  }

  transfor(_array) {
    const ids = [];

    if (typeof _array === 'string') {
      _array = _array.split(',');
    } else if (!Array.isArray(_array)) {
      _array = [];
    }

    for (let i = 0; i < _array.length; i++) {
      if (typeof _array[i] === 'object' && _array[i] != null && _array[i] != '') {
        ids.push(_array[i]['_id']);
      } else if (typeof _array[i] === 'string' && _array[i] != null && _array[i] != '') {
        ids.push(_array[i]);
      }
    }

    return ids;
  }

  displayFile() {
    this.imgUrl = null;
    this.pdfUrl = null;
    this.videoSource = null;
    this.imgUrlString = null;
    this.defaultImage = null;
    this.htmlUrl = null;
    const contentType = this.util.getDocumentTypeFromExtension(this.file.extension);

    this.fileLink = environment.nodeUrl + '/files/file-view/' + this.file._id;
    if (contentType == 'pdf') {
      this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.fileLink);
    } else if (contentType == 'image') {
      this.imgUrlString = this.fileLink;

      this.imgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.fileLink);
    } else if (contentType == 'video') {
      this.videoSource = this.fileLink;
    } else if (contentType == 'html') {
      this.htmlUrlString = this.fileLink;

      this.htmlUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.fileLink);
    } else {
      this.documentSource = this.fileLink;
    }

    this.defaultImage = this.util.getDefaultPic(contentType, this.file['extension']);
  }

  download() {
    const fileLink = environment.nodeUrl + '/files/file-view/' + this.file._id;
    window.location.href = fileLink;
  }

  getNextFile() {
    let copyFile = this.file;
    if (this.filesContent[this.fileIndex + 1]) {
      this.file = this.filesContent[this.fileIndex + 1];
      if (this.file.contentType !== copyFile.contentType) {
        this.resetValues();
      }
      copyFile = null;
      this.displayFile();
    } else {
      this.getFileDetails(this.files[this.fileIndex + 1]);
    }
    this.fileIndex += 1;
  }

  getPreviousFile() {
    let copyFile = this.file;
    if (this.filesContent[this.fileIndex - 1]) {
      this.file = this.filesContent[this.fileIndex - 1];
      if (this.file.contentType !== copyFile.contentType) {
        this.resetValues();
      }
      copyFile = null;
      this.displayFile();
    } else {
      this.getFileDetails(this.files[this.fileIndex - 1]);
    }
    this.fileIndex -= 1;
  }

  getFileDetails(inputFile) {
    if (typeof inputFile === 'object') {
      this.file = inputFile;
      this.filesContent.push(this.file);
      this.displayFile();
    } else {
      UtilsClass.startLoadingForce();
      this.fileService.getFile(inputFile).subscribe((res) => {
        UtilsClass.stopLoadingForce();
        if (this.file.contentType !== res.contentType) {
          this.resetValues();
        }
        this.file = res;
        this.filesContent.push(this.file);
        this.displayFile();
      });
    }
  }

  bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) {
      return '0 Byte';
    }
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  }

  resetValues() {
    this.pdfUrl = '';
    this.imgUrl = '';
    this.videoSource = '';
  }

  onClickDeleteButton(file) {
    const ref = this.dialog.open(ConfirmDialogComponent);
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val === true) {
        this.fileService.deleteFile(file._id).subscribe((res) => {
          if (res['success']) {
            if (this.filesContent.length === 1) {
              this.remove.emit(file._id);
            } else {
              this.filesContent.splice(this.file, 1);
              this.file = this.filesContent[0];
              this.remove.emit(file._id);
            }
            NotifyAppComponent.displayToast(
              'success',
              'Successful operation',
              'The file has been successfully removed'
            );
            ref.close();
            this.close.emit(true);
          }
        });
      } else {
        ref.close();
      }
    });
  }

  sendFile(file) {
    this.getFile.emit(file);
  }

  closeModalEvent() {
    this.close.emit(true);
  }

  printEvent() {
    document.getElementById('iframeDoc')['contentWindow'].print();
  }

  openInNewTab() {
    if (this.file && this.file._id) {
      window.open('/file/' + this.file._id, '_blank');
    }
  }

  onLoad() {
    this.isLoaded = true;
    this.isError = false;

    this.displayZoomInInfo();
  }

  onError() {
    this.isLoaded = false;
    this.isError = true;

    this.displayZoomInInfo();
  }

  actionEventEmpty() {
    this.download();
  }

  fullScreenModal() {
    if (this.isFullScreenModal !== true) {
      const fullScreen = $('.mat-dialog-container').last() as any;
      fullScreen.addClass('full-screen', 5000);
      this.isFullScreenModal = true;
    } else {
      $('.mat-dialog-container').removeClass('full-screen');
      this.isFullScreenModal = false;
    }
  }

  ngOnDestroy() {
    if (this.isFullScreenModal == true) {
      $('.mat-dialog-container').removeClass('full-screen');
    }
  }

  displayZoomInInfo() {
    if (this.imgUrl && this.zoomInInfo != true && this.isError != true && this.isLoaded != false) {
      let show = false;

      if (localStorage.getItem('zoomInImageModule') === 'true') {
        show = true;
      }

      if (show !== true) {
        const ref = RootAppComponent.dialog.open(ZoomInInfoComponent, {
          data: {},
          width: '650px',
          height: '700px',
          panelClass: 'noCard',
        });

        const sub = ref.componentInstance.close.subscribe((data) => {
          ref.close();
        });

        this.zoomInInfo = true;
      }
    }
  }
}
