<div class="row clearfix">
  <button class="pull-right" (click)="showEditView()" mat-raised-button color="accent">
    <span *ngIf="isEdit == false">Edit Informed Consent</span>
    <span *ngIf="isEdit == true">Review Informed Consent</span>
  </button>

  <h2 class="sr-title">Set up your own the informed consent form</h2>

  <div class="row clearfix" *ngIf="isEdit == false">
    <h3 class="sr-title sm-h3 rm-m mt">Current Informed Consent</h3>

    <div class="row clearfix">
      <mat-button-toggle-group
        class="btn-group text-center full-width with-icons"
        #group="matButtonToggleGroup"
        [(ngModel)]="option"
      >
        <mat-button-toggle class="text-center full-width" [value]="'desktop'">
          <mat-icon class="fas fa-user"></mat-icon>
          Desktop Review
        </mat-button-toggle>
        <mat-button-toggle class="text-center full-width" [value]="'mobile'">
          <mat-icon class="fas fa-eye"></mat-icon>
          Mobile Review
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="row clearfix" *ngIf="option == 'desktop'">
      <div class="desktopReview" *ngIf="informedConsent" [innerHTML]="informedConsent | safeHtml"></div>
    </div>

    <div class="row clearfix" *ngIf="option == 'mobile'">
      <mat-dialog-content>
        <div class="marvel-device iphone8">
          <div class="top-bar"></div>
          <div class="sleep"></div>
          <div class="volume"></div>
          <div class="camera"></div>
          <div class="sensor"></div>
          <div class="speaker"></div>
          <div class="screen">
            <span class="messages">
              <p class="speech-bubble" *ngIf="informedConsent" [innerHTML]="informedConsent | safeHtml"></p>
            </span>
          </div>

          <div class="home"></div>
          <div class="bottom-bar"></div>
        </div>
      </mat-dialog-content>
    </div>
  </div>

  <div class="row clearfix" *ngIf="isEdit == true">
    <div class="row clearfix">
      <h3 class="sr-title sm-h3 rm-m mt">Edit Informed Consent</h3>

      <app-text-editor-input *ngIf="informedConsent"
        class="clearfix"
        [content]="informedConsent"
        [isPreview]="false"
        [title]="null"
        [isAction]="false"
        (getContent)="getContent($event)"
        name="editor"
      >
      </app-text-editor-input>
    </div>
    <!-- insert personalisation -->
    <!-- <div class="row clearfix">
        <button mat-button color="primary" [matMenuTriggerFor]="persMenu" class="pull-left">
          <mat-icon class="fas fa-share"></mat-icon> Insert personalisation
        </button>
        <mat-menu #persMenu="matMenu">
          <button mat-menu-item [matMenuTriggerFor]="patientPersMenu">
            <mat-icon>portrait</mat-icon> {{ "KEYWORD.patient" | translate | titlecase }}
          </button>
          <mat-menu #patientPersMenu="matMenu">
            <div *ngFor="let p of utilMessage.patientPersonalisation">
              <button mat-menu-item (click)="addPersonalisation(p)">
                {{p.id}}
              </button>
            </div>
          </mat-menu>

          <button mat-menu-item [matMenuTriggerFor]="dentistPersMenu">
            <mat-icon>portrait</mat-icon> {{ "KEYWORD.practitioner" | translate | titlecase }}
          </button>
          <mat-menu #dentistPersMenu="matMenu">
            <div *ngFor="let p of utilMessage.dentistPersonalisation">
              <button mat-menu-item (click)="addPersonalisation(p)">
                {{p.id}}
              </button>
            </div>
          </mat-menu>

          <button mat-menu-item [matMenuTriggerFor]="practicePersMenu">
            <mat-icon>portrait</mat-icon> {{ "KEYWORD.practice" | translate | titlecase}}
          </button>
          <mat-menu #practicePersMenu="matMenu">
            <div *ngFor="let p of utilMessage.practicePersonalisation">
              <button mat-menu-item (click)="addPersonalisation(p)">
                {{p.id}}
              </button>
            </div>
          </mat-menu>

        </mat-menu>
      </div> -->

    <div class="row clearfix text-center">
      <button class="" (click)="save()" mat-raised-button color="accent">Save</button>
    </div>
  </div>
</div>
