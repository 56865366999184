import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-client';
import { environment } from 'environments/environment';
import * as ics from 'ics';
import * as Msal from 'msal';
import propertiesToJSON from 'properties-to-json';

@Injectable()
export class CalendarShareService {
  gapi = window['gapi'];
  msClient: MicrosoftGraph.Client;
  msalApplication: Msal.UserAgentApplication;

  constructor(private http: HttpClient) {}

  initGoogleClient(token: string = null): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.gapi.load('client:auth2', async () => {
          if (token) {
            this.gapi.client.setToken({ access_token: token });
          }
          this.gapi.client.init({
            clientId: environment.googleID,
            discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
            scope: 'https://www.googleapis.com/auth/calendar.events',
          });

          setTimeout(() => {
            resolve(false);
          }, 500);
          await this.gapi.client.load('calendar', 'v3');
          resolve(true);
        });
      } catch (ex) {
        reject();
      }
    });
  }

  googleSignIn() {
    // return this.gapi.auth2.getAuthInstance().signIn();
    return this.gapi.auth2.getAuthInstance().grantOfflineAccess();
  }

  addToGoogleCalendar(payload): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.googleSignIn().then(() => {
          const request = this.gapi.client.calendar.events.insert({
            calendarId: 'primary',
            resource: payload,
          });

          request.execute((event: any) => {
            resolve(event.id);
          });
        });
      } catch (ex) {
        console.error(ex);
        reject(ex);
      }
    });
  }

  // googleRefreshTokenRequest(code): Observable<{ refresh_token: string }> {
  //   const headers = new HttpHeaders()
  //     .set("Access-Control-Allow-Origin", "*")
  //     .set("Access-Control-Allow-Methods", "GET,POST,PATCH,DELETE,PUT,OPTIONS")
  //     .set(
  //       "Access-Control-Allow-Headers",
  //       "Origin, Content-Type, X-Auth-Token, content-type"
  //     );
  //   const payload = {
  //     code,
  //     client_id: environment.googleID,
  //     redirect_uri: "postmessage",
  //     grant_type: "authorization_code",
  //   };
  //   return this.http
  //     .post(`https://www.googleapis.com/oauth2/v4/token`, payload, {
  //       headers,
  //     })
  //     .pipe(
  //       map((res: HttpResponse<{ refresh_token: string }>) => {
  //         return res["data"];
  //       })
  //     );
  // }

  // googleAccessTokenRequest(code): Observable<{ access_token: string }> {
  //   const payload = {
  //     code,
  //     client_id: environment.googleID,
  //     refresh_token: code,
  //     grant_type: "refresh_token",
  //   };
  //   return this.http
  //     .post(`https://www.googleapis.com/oauth2/v4/token`, payload, {
  //       headers: { "Access-Control-Allow-Origin": "*" },
  //     })
  //     .pipe(
  //       map((res: HttpResponse<{ access_token: string }>) => {
  //         return res["data"];
  //       })
  //     );
  // }

  googleGetCurrentUserDetails() {
    const auth = this.gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse();
    const profile = this.gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile();
    return {
      ...auth,
      fullname: profile.getName(),
      email: profile.getEmail(),
    };
  }

  googleSignOut() {
    if (this.gapi && this.gapi.auth2 && this.gapi.auth2.getAuthInstance()) {
      this.gapi.auth2.getAuthInstance().signOut();
    }
  }

  addToAppleCalendar(payload): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        ics.createEvent(payload, (error, value) => {
          if (error) {
            console.log(error);
            reject(error);
            return;
          }

          window.open('data:text/calendar;charset=utf8,' + escape(value));
          const icsJson = propertiesToJSON(value);
          resolve(icsJson.UID);
        });
      } catch (ex) {
        console.error(ex);
        reject(ex);
      }
    });
  }

  async msSignIn() {
    return new Promise((resolve, reject) => {
      try {
        const msalConfig = {
          auth: {
            clientId: environment.msID,
            redirectUri: window.location.origin,
          },
        };
        if (!this.msalApplication) {
          this.msalApplication = new Msal.UserAgentApplication(msalConfig);
        }
        const params = {
          scopes: ['Calendars.ReadWrite'],
          prompt: 'select_account',
        };
        this.msalApplication
          .loginPopup(params)
          .then((value) => {
            // this.msalApplication
            //   .acquireTokenSilent(params)
            //   .then((token) => resolve(token.accessToken));
            resolve(true);
          })
          .catch((error) => {
            console.log('error: ', error);
            reject(error);
          });
      } catch (ex) {
        reject();
      }
    });
  }

  async microsoftGetCurrentUserDetails() {
    const params = {
      scopes: ['Calendars.ReadWrite'],
    };
    const res = await this.msalApplication.acquireTokenSilent(params);
    return {
      id_token: res.idToken.rawIdToken,
      access_token: res.accessToken,
      fullname: res.account.name,
      expires_at: res.expiresOn,
      email: res.account.userName,
    };
  }

  microsoftSignOut() {
    if (this.msalApplication) {
      this.msalApplication.logout();
    }
  }

  addToMicrosoftCalendar(payload): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.msSignIn()
          .then((token: string) => {
            this.msClient = MicrosoftGraph.Client.init({
              authProvider: (done) => done(null, token),
            });

            this.msClient
              .api('/me/events')
              .post(payload)
              .then((res) => {
                resolve(res.id);
              });
          })
          .catch((ex) => {
            console.error(ex);
            reject();
          });
      } catch (ex) {
        console.error(ex);
        reject();
      }
    });
  }
}
