<ng-container *ngIf="appState$ | async as appState">
  <form #productForm="ngForm">
    <mat-form-field class="full-width" (click)="selectPatient(appState.practice)" appearance="outline">
      <mat-label> Customer</mat-label>

      <mat-select name="customer" placeholder="Select Customer" required value="'placeholder'">
        <mat-select-trigger>
          <div style="display: flex">
            <ng-container>
              <app-card-picture
                class="note-header-container__profile-picture mr"
                *ngIf="selectedCustomer"
                [hideZoomButton]="false"
                [tableName]="selectedCustomer['TableName']"
                [LastModified]="selectedCustomer['LastModified']"
                [cardID]="selectedCustomer['ID']"
              >
              </app-card-picture>

              <app-card-picture class="note-header-container__profile-picture mr" *ngIf="!selectedCustomer">
              </app-card-picture>

              <div *ngIf="!selectedCustomer" style="width: 100%; height: 70px">
                <h2 class="sr-title mt mr" style="text-align: left; font-weight: bold">Select Customer</h2>
              </div>

              <div *ngIf="selectedCustomer" style="width: 100%; height: 70px">
                <div class="bold" style="margin-bottom: 10px">
                  <span *ngIf="selectedCustomer">
                    Name: {{ selectedCustomer['CalculatedName'] ? selectedCustomer['CalculatedName'] : '-' }}
                  </span>
                  <span *ngIf="!selectedCustomer"> Name: - </span>
                </div>

                <div style="margin-bottom: 5px; font-style: italic">
                  <span *ngIf="selectedCustomer">
                    Email: {{ selectedCustomer['emails.Email'] ? selectedCustomer['emails.Email'] : '-' }}
                  </span>
                  <span *ngIf="!selectedCustomer"> Email: - </span>
                </div>

                <div style="margin-bottom: 5px; font-style: italic">
                  <span *ngIf="selectedCustomer">
                    Mobile:
                    {{ selectedCustomer['phones.Number'] ? selectedCustomer['phones.Number'] : ('-' | customPhone) }}
                  </span>
                  <span *ngIf="!selectedCustomer"> Mobile: - </span>
                </div>
              </div>
            </ng-container>
          </div>
        </mat-select-trigger>
        <mat-option value="'placeholder'" hidden></mat-option>
      </mat-select>
    </mat-form-field>

    <div style="width: 100%; display: flex; justify-content: flex-end">
      <button class="ml small-button" (click)="newPatient(appState.practice)" mat-raised-button color="accent">
        create
      </button>
      <button
        class="ml small-button"
        *ngIf="selectedCustomer"
        (click)="openPatientProfile()"
        mat-raised-button
        color="primary"
      >
        view
      </button>
    </div>

    <mat-form-field class="full-width" (click)="selectSerialisedProducts()" appearance="outline">
      <mat-label> Product </mat-label>

      <mat-select name="product" placeholder="Select Product" required value="'placeholder'">
        <mat-select-trigger>
          <div style="display: flex">
            <ng-container>
              <app-ep-document-view-image
                class="full-width view-logo"
                *ngIf="selectedProduct"
                [defaultPicture]="
                  'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/product-icon.png)'
                "
                [link]="getThumbnailImage()"
                [hideZoomButton]="true"
              >
              </app-ep-document-view-image>

              <app-ep-document-view-image
                class="full-width view-logo"
                *ngIf="!selectedProduct"
                [defaultPicture]="
                  'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/product-icon.png)'
                "
              >
              </app-ep-document-view-image>

              <div *ngIf="!selectedProduct" style="width: 100%; height: 70px">
                <h2 class="sr-title mt mr" style="text-align: left; font-weight: bold">Select Product</h2>
              </div>

              <div *ngIf="selectedProduct" style="width: 100%; height: 70px">
                <div class="bold" style="margin-bottom: 10px">
                  <span *ngIf="selectedProduct">
                    Product: {{ selectedProduct['Product'] ? selectedProduct['Product'] : '-' }}
                  </span>
                  <span *ngIf="!selectedProduct"> Product: - </span>
                </div>

                <div style="margin-bottom: 5px; font-style: italic">
                  <span *ngIf="selectedProduct">
                    Model: {{ selectedProduct['Model'] ? selectedProduct['Model'] : '-' }}
                  </span>
                  <span *ngIf="!selectedProduct"> Model: - </span>
                </div>

                <div style="margin-bottom: 5px; font-style: italic">
                  <span *ngIf="selectedProduct">
                    Sell Price: {{ selectedProduct['SellPrice'] ? selectedProduct['SellPrice'] : (0 | customCurrency) }}
                  </span>
                  <span *ngIf="!selectedProduct"> Sell Price: {{ 0 | customCurrency }} </span>
                </div>
              </div>
            </ng-container>
          </div>
        </mat-select-trigger>
        <mat-option value="'placeholder'" hidden></mat-option>
      </mat-select>
    </mat-form-field>

    <div style="width: 100%; display: flex; justify-content: flex-end">
      <button
        class="ml small-button"
        *ngIf="selectedProduct"
        (click)="viewProduct(appState.publicSettings.industryType)"
        mat-raised-button
        color="primary"
      >
        view
      </button>
    </div>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Serial Number</mat-label>
      <input
        [(ngModel)]="productCustomer.SerialNumber"
        matInput
        name="serialNumber"
        placeholder="Serial Number"
        required
      />
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Invoice key</mat-label>
      <input [(ngModel)]="productCustomer.Invoice_key" matInput name="invoiceKey" placeholder="Invoice Key" required/>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Notes</mat-label>
      <textarea
        class="textarea-control-smaller"
        [(ngModel)]="productCustomer.Notes"
        matInput
        placeholder="notes"
        name="notes"
      ></textarea>
    </mat-form-field>
  </form>

  <ng-container *ngIf="productCustomer.SerialNumber.length > 0">
    <h2 class="sr-title mt" style="text-align: left; font-weight: bold">Image Upload</h2>

    <!--  PICTURE UPLOAD -->
    <div class="uploadBlock full-width" style="margin: 0 !important">
      <div class="row clearfix">
        <h4 class="sr-title sm-h4 rm-mb">Upload Images</h4>
        <app-file-uploader
          class="text-center clearfix full-width"
          [fileName]="fileName"
          [allowedExtensions]="['png', 'jpg', 'jpeg']"
          [allowedExtensionTypes]="['image']"
          [documentType]="'Image'"
          [isImageCropping]="true"
          [canSkip]="false"
          [uploadDirectly]="true"
          [isDragable]="true"
          [title]="'CLICK HERE to upload'"
          (onCompleteAll)="addAdditionalImage($event)"
        >
        </app-file-uploader>
      </div>

      <div class="clearfix" style="width: 50%; display: block; margin: 20px auto 0px auto">
        <app-file-list
          class="text-center centered"
          [documentTypes]="['Image']"
          [canAction]="false"
          [canEdit]="true"
          [hasExtraInfo]="false"
          [files]="existingAdditionalImages"
          [hasResponsiveWidth]="true"
          (remove)="removeFile($event)"
        ></app-file-list>
      </div>
    </div>
  </ng-container>

  <div class="row clearfix text-center button-row">
    <button class="" (click)="close()" mat-raised-button color="primary">Close</button>
    <button
      class="ml"
      *ngIf="formMode === 'create'"
      [disabled]="!productForm.form.valid"
      (click)="create(appState.user.data.merchantKey)"
      mat-raised-button
      color="accent"
    >
      Save
      <mat-icon>check</mat-icon>
    </button>

    <button
      class="ml"
      *ngIf="formMode === 'update'"
      [disabled]="!productForm.form.valid"
      (click)="update()"
      mat-raised-button
      color="accent"
    >
      Save
      <mat-icon>check</mat-icon>
    </button>
  </div>
</ng-container>
