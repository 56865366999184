import { Component, EventEmitter, Input, OnDestroy, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-stopwatch',
  templateUrl: './stopwatch.component.html',
  styleUrls: ['./stopwatch.component.css'],
})
export class StopwatchComponent implements OnDestroy {
  clock: any;
  minutes: any = '00';
  seconds: any = '00';
  milliseconds: any = '00';

  @Input() start = false;
  @Input() showTimerControls: boolean;

  @Output()
  getStartTime = new EventEmitter();

  @Output()
  getDuration = new EventEmitter();

  _startTime;

  laps: any = [];
  counter: number;
  timerRef;
  running = false;
  startText = 'Start';
  isStart = false;

  constructor() {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes['start'].currentValue) {
      this.startTimer();
    } else {
      this.clearTimer();
    }
  }

  startTimer() {
    if (this.isStart == false) {
      this._startTime = moment().format('HH:mm:ss');
      this.getStartTime.emit(this._startTime);
    }
    this.isStart = true;

    this.running = !this.running;
    if (this.running) {
      this.startText = 'Stop';
      const startTime = Date.now() - (this.counter || 0);
      this.timerRef = setInterval(() => {
        this.counter = Date.now() - startTime;
        this.milliseconds = Math.floor(Math.floor(this.counter % 1000) / 10).toFixed(0);
        this.minutes = Math.floor(this.counter / 60000);
        this.seconds = Math.floor(Math.floor(this.counter % 60000) / 1000).toFixed(0);
        if (Number(this.minutes) < 10) {
          this.minutes = '0' + this.minutes;
        } else {
          this.minutes = '' + this.minutes;
        }
        if (Number(this.milliseconds) < 10) {
          this.milliseconds = '0' + this.milliseconds;
        } else {
          this.milliseconds = '' + this.milliseconds;
        }
        if (Number(this.seconds) < 10) {
          this.seconds = '0' + this.seconds;
        } else {
          this.seconds = '' + this.seconds;
        }
      });
    } else {
      this.startText = 'Resume';
      clearInterval(this.timerRef);
      const _duration = {
        minutes: this.minutes,
        seconds: this.seconds,
      };
      this.getDuration.emit(_duration);
    }
  }
  lapTimeSplit() {
    const lapTime = this.minutes + ':' + this.seconds + ':' + this.milliseconds;
    this.laps.push(lapTime);
  }

  clearTimer() {
    this.getStartTime.emit(null);
    this.getDuration.emit(null);
    this.isStart = false;
    this.running = false;
    this.startText = 'Start';
    this.counter = undefined;
    (this.milliseconds = '00'), (this.seconds = '00'), (this.minutes = '00');
    this.laps = [];
    clearInterval(this.timerRef);
  }

  ngOnDestroy() {
    clearInterval(this.timerRef);
  }
}
