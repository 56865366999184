import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Settings } from '../../../shared/types/settings';

import { Router } from '@angular/router';
import { UtilsService } from '../../../shared/services/utils.service';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';

@Component({
  selector: 'app-media-gallery-templates',
  templateUrl: './media-gallery-templates.component.html',
  styleUrls: ['./media-gallery-templates.component.css'],
})
export class MediaGalleryTemplatesComponent implements OnInit {
  selectedField;

  @Input()
  isBusiness;

  @Input()
  title = 'Templates';

  @Input()
  description;

  @Input()
  actionLabel = 'Use this Template';

  @Input()
  actionIcon = 'assignment';

  @Input()
  emailLabel = 'Preview email';

  @Input()
  smsLabel = 'Preview sms';

  @Output()
  selectTemplate = new EventEmitter();

  @Input()
  mode = 'mode1';

  templates = [];

  util = new UtilsClass();

  selectedTemplate;
  selectedPreview;

  preview = 'email';
  isAdminOrPromoter = false;

  @Output()
  closeModal = new EventEmitter();

  settings = Settings.global;

  filter;
  serviceRef;

  constructor(
    private utilService: UtilsService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isAdminOrPromoter = res;

      const payload = {
        isBusiness: this.isBusiness,
        section: 1,
      };
      this.utilService.getTemplateList(payload, this.isAdminOrPromoter).subscribe((res) => {
        if (res) {
          this.templates = this.templates.concat(res);

          const innerFunction = (section) => {
            section = section + 1;
            UtilsClass.loadingDataSection(section);
            payload.section = section;

            this.serviceRef = this.utilService.getTemplateList(payload, this.isAdminOrPromoter).subscribe((res) => {
              if (res) {
                if (res.length > 0) {
                  this.templates = this.templates.concat(res);
                  innerFunction(payload.section);
                }
              }
            });
          };

          innerFunction(1);

          UtilsClass.stopLoadingInterceptor();
        }
      });
    });
  }

  getYesLabel(i) {
    if (i && i == '1') {
      return 'Yes';
    } else if (i && i == '0') {
      return 'No';
    } else {
      return 'No';
    }
  }

  previewTemplate(t) {
    if (t && t['Tag']) {
      this.selectedTemplate = t;

      if (this.selectedTemplate['Supports.Email'] == '1' && this.selectedTemplate['Supports.SMS'] == '1') {
        this.preview = 'email';
      } else if (this.selectedTemplate['Supports.Email'] == '1' && this.selectedTemplate['Supports.SMS'] == '0') {
        this.preview = 'email';
      } else {
        this.preview = 'sms';
      }

      this.utilService.previewMessageByTemplate(t['Tag']).subscribe((res) => {
        if (res) {
          this.selectedPreview = res;
        }
      });
    }
  }

  actionEvent(t) {
    if (t) {
      this.selectTemplate.emit(t);
      this.closeModal.emit(true);
    }
  }

  getTags(t) {
    let result = [];
    if (t && t['Tag']) {
      result = t['Tag'].split('-');
    }

    return result;
  }

  selectTemplateEvent(e) {
    if (e && e['ID']) {
      this.router.navigate(['/merchant', { outlets: { page: ['marketing-campaign-create', e['ID']] } }]);
    }
  }
}
