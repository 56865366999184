import { BehaviorSubject, merge as observableMerge, Observable, Subject } from 'rxjs';

import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { TipService } from '../shared/tip.service';

import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { TipEditComponent } from '../tip-edit/tip-edit.component';
import { TipViewComponent } from '../tip-view/tip-view.component';

@Component({
  selector: 'app-tip-list',
  templateUrl: './tip-list.component.html',
  styleUrls: ['./tip-list.component.css'],
})
export class TipListComponent implements OnInit {
  rows = [];
  selectedTip: any;
  displayedColumns = ['active', 'title', 'updated_at', 'Actions'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;
  public tipsDB: LoadTips | null;
  dataSource: TipsDataSource | null;
  @ViewChild('input', { static: false }) input;
  priority = new Subject<any>();

  @Input()
  set tip(tip) {
    if (tip) {
      this.dataSource.addTip = tip;
    }
  }

  constructor(private tipService: TipService) {}

  ngOnInit() {
    this.tipsDB = new LoadTips(this.tipService);
    this.dataSource = new TipsDataSource(this.tipsDB, this.paginator, this.sort);

    this.priority.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((value: string) => {
      this.updateTip(value);
    });
  }

  onPriorityEnter(value, row): void {
    this.selectedTip = row;
    this.priority.next(value);
  }

  updateTip(value) {
    const selectedTip = this.selectedTip;
    selectedTip.priority = value;
    this.tipService.updateTip(selectedTip._id, selectedTip).subscribe((res) => {
      if (res['success']) {
        NotifyAppComponent.displayToast('success', 'Successful operation', 'The tip has been successfully updated');
      }
    });
  }

  onClickDeleteButton(tip) {
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent);
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        this.tipService.removeTip(tip._id).subscribe((res) => {
          if (res['success']) {
            this.dataSource.removeItem = tip._id;
            NotifyAppComponent.displayToast('success', 'Successful operation', 'The tip has been successfully removed');
            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }

  editIsActiveProp(tip) {
    if (tip) {
      this.tipService.updateTip(tip._id, tip).subscribe((res) => {
        if (res['success']) {
          NotifyAppComponent.displayToast('success', 'Successful operation', 'The tip has been successfully updated');
        }
      });
    }
  }

  viewTip(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(TipViewComponent, {
        data: {
          tipID: id,
        },
        width: '700px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  updateTipEvent(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(TipEditComponent, {
        data: {
          tipID: id,
        },
        width: '700px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
      ref.componentInstance.update.subscribe((res) => {
        if (res) {
          this.dataSource.replaceItem = res;
          ref.close();
        }
      });
    }
  }
}

export class LoadTips implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  rows = [];
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  serviceRef;

  constructor(private tipService: TipService) {
    this.serviceRef = tipService.getTipsGlobal().subscribe((res) => {
      this.rows = res;
      this.count = res.length;
      this.dataChange.next(this.rows.slice());
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class TipsDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';
  dateF = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set removeItem(id) {
    const data = this._dataBase.data.filter((row) => row._id != id);
    this._dataBase.dataChange.next(data);
  }

  set addTip(tip) {
    const data = this._dataBase.dataChange.value;
    data.push(tip);
    this._dataBase.dataChange.next(data);
  }

  set filter(item: any) {
    this.field = item.field;
    this.dateF = item.dateFrom ? item.dateFrom : '';
    this._filterChange.next(item.value);
  }

  set replaceItem(item) {
    const _index = this._dataBase.data.findIndex((_obj) => _obj._id == item._id);
    this._dataBase.data[_index] = item;
    this._dataBase.dataChange.next(this._dataBase.data);
  }

  filteredData: any[] = [];

  constructor(private _dataBase: any, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._dataBase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._dataBase.data.slice().filter((item: any) => {
          let f = '';
          f = item[this.field] || '';
          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'priority':
          [propertyA, propertyB] = [a.priority, b.priority];
          break;
        case 'title':
          [propertyA, propertyB] = [a.title, b.title];
          break;
        case 'content':
          [propertyA, propertyB] = [a.content, b.content];
          break;

        case 'updated_at':
          [propertyA, propertyB] = [new Date(a.updated_at).getTime(), new Date(b.updated_at).getTime()];
          break;

        case 'active':
          [propertyA, propertyB] = [a.isActive, b.isActive];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
