<div class="clearfix mt mb" *ngIf="isSession == true">
  <div class="row clearfix flex">
    <app-ep-document-view-image
      class="headNav userIconWelcome"
      [defaultPicture]="
        'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
      "
      [link]="profileLink"
    ></app-ep-document-view-image>
    <h3 class="welcome rm-mt">
      <span class="operator-name">Welcome, {{ merchant.calculatedName }}</span>
    </h3>
  </div>

  <h4 class="welcome rm-mt tradingAs mb">
    <span>{{ tradingAs }}</span>
  </h4>
  <div class="lastVisited">
    <p>You last logged in : {{ merchant.lastLogin | amTimeAgo }}</p>
    <p>You last checked in : {{ merchant.lastCheck | amTimeAgo }}</p>
  </div>
</div>

<div class="clearfix mt mb" *ngIf="isSession == false">
  <div class="row clearfix flex">
    <app-merchant-view-picture class="mat-elevation-z2" [merchantID]="merchantID"></app-merchant-view-picture>
    <h3 class="welcome rm-mt">
      <span class="operator-name">Welcome, {{ tradingAs }}</span>
    </h3>
  </div>
</div>
