import { Observable } from 'rxjs';

import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class SubscriptionPlanService {
  constructor(private http: HttpClient) {}
  getPromoterPlanListFull(payload = null, sessionType) {
    let url = 'subscription-plan-promoter/full';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'subscription-plan-promoter/full/global';
    } else if (sessionType === 'guest' || !sessionType) {
      url = 'subscription-plan-promoter/full/public';
    }
    let params: HttpParams = new HttpParams();
    let options = {};
    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = {
        params,
      };
    }
    return this.http.get(`${environment.nodeUrl}/${url}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  getPromoterPlanList(payload = null, sessionType) {
    let url = 'subscription-plan-promoter';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'subscription-plan-promoter/global';
    }
    let params: HttpParams = new HttpParams();
    let options = {};
    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = {
        params,
      };
    }
    return this.http.get(`${environment.nodeUrl}/${url}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  getSubscriptionPlanUserList(payload = null) {
    const url = 'subscription-plan-merchant/users';

    let params: HttpParams = new HttpParams();
    let options = {};
    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = {
        params,
      };
    }
    return this.http.get(`${environment.nodeUrl}/${url}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  getPromoterPlanDetails(id, payload = null, sessionType) {
    let url = 'subscription-plan-promoter';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'subscription-plan-promoter/global';
    }
    let params: HttpParams = new HttpParams();
    let options = {};
    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = {
        params,
      };
    }
    return this.http.get(`${environment.nodeUrl}/${url}/${id}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  getPromoterPlanCurrent(payload = null) {
    const url = 'subscription-plan-promoter/current';

    let params: HttpParams = new HttpParams();
    let options = {};
    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = {
        params,
      };
    }
    return this.http.get(`${environment.nodeUrl}/${url}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  getMerchantPlanDetails(id, payload = null, sessionType) {
    let url = 'subscription-plan-merchant';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'subscription-plan-merchant/global';
    }
    let params: HttpParams = new HttpParams();
    let options = {};
    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = {
        params,
      };
    }
    return this.http.get(`${environment.nodeUrl}/${url}/${id}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  modifyMerchantPlan(id, payload = null) {
    const url = 'subscription-plan-merchant';

    return this.http.put(`${environment.nodeUrl}/${url}/${id}`, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  sync(payload) {
    const url = 'subscription-plan-merchant';

    return this.http.post(`${environment.nodeUrl}/${url}/sync`, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  syncHidden(payload) {
    const url = 'subscription-plan-merchant';

    return this.http.post(`${environment.nodeUrl}/${url}/sync-hidden`, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  createMerchantPlan(payload = {}, sessionType) {
    let url = 'subscription-plan-merchant';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'subscription-plan-merchant/global';
    }

    return this.http.post(`${environment.nodeUrl}/${url}`, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  createMerchantPlanGroup(payload = {}) {
    const url = 'subscription-plan-merchant/group';

    return this.http.post(`${environment.nodeUrl}/${url}`, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  getPromoterPlanFullDetails(id, payload = null, sessionType) {
    let url = 'subscription-plan-promoter/full';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'subscription-plan-promoter/full/global';
    } else if (sessionType === 'guest' || !sessionType) {
      url = 'subscription-plan-promoter/full/public';
    }
    let params: HttpParams = new HttpParams();
    let options = {};
    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = {
        params,
      };
    }
    return this.http.get(`${environment.nodeUrl}/${url}/${id}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  createPromoterPlan(payload) {
    return this.http.post(`${environment.nodeUrl}/subscription-plan-promoter`, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  editPromoterPlan(id, payload) {
    return this.http.put(`${environment.nodeUrl}/subscription-plan-promoter/${id}`, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  deleteMerchantPlan(id) {
    return this.http.delete(`${environment.nodeUrl}/subscription-plan-merchant/${id}`, {}).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  deletePromoterPlan(id) {
    return this.http.delete(`${environment.nodeUrl}/subscription-plan-promoter/${id}`, {}).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  getPromoterPlanCoverImageStreamLink(ID, LastModified = null) {
    if (
      LastModified &&
      LastModified != '' &&
      LastModified != undefined &&
      LastModified != 'null' &&
      LastModified != 'undefined '
    ) {
      return `${environment.nodeUrl}/subscription-plan-promoter/cover-stream-cached/${LastModified}/` + ID;
    } else if (ID) {
      if (ID) {
        return `${environment.nodeUrl}/subscription-plan-promoter/cover-stream/` + ID;
      }
    }
    return null;
  }
  getPromoterPlanThumbnailImageStreamLink(ID, LastModified = null) {
    if (
      LastModified &&
      LastModified != '' &&
      LastModified != undefined &&
      LastModified != 'null' &&
      LastModified != 'undefined '
    ) {
      return `${environment.nodeUrl}/subscription-plan-promoter/thumbnail-stream-cached/${LastModified}/` + ID;
    } else if (ID) {
      if (ID) {
        return `${environment.nodeUrl}/subscription-plan-promoter/thumbnail-stream/` + ID;
      }
    }
    return null;
  }
  getPromoterPlanCoverImage(ID) {
    return this.http
      .get(`${environment.nodeUrl}/subscription-plan-promoter/cover/` + ID, {})
      .pipe(map((res: Response) => res['data']));
  }
  getPromoterPlanThumbnailImage(ID) {
    return this.http
      .get(`${environment.nodeUrl}/subscription-plan-promoter/thumbnail/` + ID, {})
      .pipe(map((res: Response) => res['data']));
  }

  getFeatureMainLabelListFull(payload = null, sessionType) {
    let url = 'feature-main-label/full';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'feature-main-label/full/global';
    } else if (sessionType === 'guest' || !sessionType) {
      url = 'feature-main-label/full/public';
    }

    let params: HttpParams = new HttpParams();
    let options = {};
    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = {
        params,
      };
    }
    return this.http.get(`${environment.nodeUrl}/${url}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  getFeatureMainLabelList(payload = null, sessionType) {
    let url = 'feature-main-label';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'feature-main-label/global';
    } else if (sessionType === 'guest' || !sessionType) {
      url = 'feature-main-label/public';
    }

    let params: HttpParams = new HttpParams();
    let options = {};
    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = {
        params,
      };
    }
    return this.http.get(`${environment.nodeUrl}/${url}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  getFeatureMainLabelDetails(id, payload = null, sessionType) {
    let url = 'feature-main-label';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'feature-main-label/global';
    } else if (sessionType === 'guest' || !sessionType) {
      url = 'feature-main-label/public';
    }

    let params: HttpParams = new HttpParams();
    let options = {};
    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = {
        params,
      };
    }
    return this.http.get(`${environment.nodeUrl}/${url}/${id}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  editFeatureMainLabel(id, payload) {
    return this.http.put(`${environment.nodeUrl}/feature-main-label/${id}`, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  isUniqueFeatureMainLabel(p) {
    return this.http.put(`${environment.nodeUrl}/feature-main-label/unique`, p).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  createFeatureMainLabel(payload) {
    return this.http.post(`${environment.nodeUrl}/feature-main-label`, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  deleteFeatureMainLabel(id) {
    return this.http.delete(`${environment.nodeUrl}/feature-main-label/${id}`, {}).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  getFeatureMainLabelCoverImageStreamLink(ID, LastModified = null) {
    if (
      LastModified &&
      LastModified != '' &&
      LastModified != undefined &&
      LastModified != 'null' &&
      LastModified != 'undefined '
    ) {
      return `${environment.nodeUrl}/feature-main-label/cover-stream-cached/${LastModified}/` + ID;
    } else if (ID) {
      if (ID) {
        return `${environment.nodeUrl}/feature-main-label/cover-stream/` + ID;
      }
    }
    return null;
  }
  getFeatureMainLabelThumbnailImageStreamLink(ID, LastModified = null) {
    if (
      LastModified &&
      LastModified != '' &&
      LastModified != undefined &&
      LastModified != 'null' &&
      LastModified != 'undefined '
    ) {
      return `${environment.nodeUrl}/feature-main-label/thumbnail-stream-cached/${LastModified}/` + ID;
    } else if (ID) {
      if (ID) {
        return `${environment.nodeUrl}/feature-main-label/thumbnail-stream/` + ID;
      }
    }
    return null;
  }
  getFeatureMainLabelCoverImage(ID) {
    return this.http
      .get(`${environment.nodeUrl}/feature-main-label/cover/` + ID, {})
      .pipe(map((res: Response) => res['data']));
  }
  getFeatureMainLabelThumbnailImage(ID) {
    return this.http
      .get(`${environment.nodeUrl}/feature-main-label/thumbnail/` + ID, {})
      .pipe(map((res: Response) => res['data']));
  }
  editFeatureMainLabelGroup(payload = {}): Observable<any> {
    return this.http
      .put(environment.nodeUrl + '/feature-main-label/group', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }
  deleteFeatureMainLabelGroup(ids): Observable<any> {
    let _ids;
    if (Array.isArray(ids) == true) {
      _ids = ids.join(',');
    } else if (typeof ids == 'string') {
      _ids = ids;
    }

    if (_ids) {
      return this.http
        .delete(environment.nodeUrl + '/feature-main-label/group/' + _ids)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getPracticePicStreamLink(ID) {
    if (ID) {
      return `${environment.nodeUrl}/merchant/practice-picture-stream/` + ID;
    }
    return null;
  }

  getFeatureSubLabelList(payload = null, sessionType) {
    let url = 'feature-sub-label';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'feature-sub-label/global';
    } else if (sessionType === 'merchant' || sessionType === 'merchant-admin') {
      url = 'feature-sub-label';
    } else {
      url = 'feature-sub-label/public';
    }

    let params: HttpParams = new HttpParams();
    let options = {};
    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = {
        params,
      };
    }
    return this.http.get(`${environment.nodeUrl}/${url}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  getFeatureSubLabelDetails(id, payload = null, sessionType) {
    let url = 'feature-sub-label';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'feature-sub-label/global';
    } else if (sessionType === 'merchant' || sessionType === 'merchant-admin') {
      url = 'feature-sub-label';
    } else {
      url = 'feature-sub-label/public';
    }

    let params: HttpParams = new HttpParams();
    let options = {};
    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = {
        params,
      };
    }
    return this.http.get(`${environment.nodeUrl}/${url}/${id}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  getFeatureMainLabelDetailsByCode(code, payload = null, sessionType) {
    let url = 'feature-main-label/code';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'feature-main-label/global-code';
    } else if (sessionType === 'guest' || !sessionType) {
      url = 'feature-main-label/public-code';
    }

    let params: HttpParams = new HttpParams();
    let options = {};
    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = {
        params,
      };
    }
    return this.http.get(`${environment.nodeUrl}/${url}/${code}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  getFeatureSubLabelDetailsByCode(code, payload = null, sessionType) {
    let url = 'feature-sub-label/code';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'feature-sub-label/global-code';
    } else if (sessionType === 'guest' || !sessionType) {
      url = 'feature-sub-label/public-code';
    }

    let params: HttpParams = new HttpParams();
    let options = {};
    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = {
        params,
      };
    }
    return this.http.get(`${environment.nodeUrl}/${url}/${code}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  editFeatureSubLabel(id, payload) {
    return this.http.put(`${environment.nodeUrl}/feature-sub-label/${id}`, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  isUniqueFeaturSubLabel(p) {
    return this.http.put(`${environment.nodeUrl}/feature-sub-label/unique`, p).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  createFeatureSubLabel(payload) {
    return this.http.post(`${environment.nodeUrl}/feature-sub-label`, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  deleteFeatureSubLabel(id) {
    return this.http.delete(`${environment.nodeUrl}/feature-sub-label/${id}`, {}).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  getFeatureSubLabelCoverImageStreamLink(ID, LastModified = null) {
    if (
      LastModified &&
      LastModified != '' &&
      LastModified != undefined &&
      LastModified != 'null' &&
      LastModified != 'undefined '
    ) {
      return `${environment.nodeUrl}/feature-sub-label/cover-stream-cached/${LastModified}/` + ID;
    } else if (ID) {
      if (ID) {
        return `${environment.nodeUrl}/feature-sub-label/cover-stream/` + ID;
      }
    }
    return null;
  }
  getFeatureSubLabelThumbnailImageStreamLink(ID, LastModified = null) {
    if (
      LastModified &&
      LastModified != '' &&
      LastModified != undefined &&
      LastModified != 'null' &&
      LastModified != 'undefined '
    ) {
      return `${environment.nodeUrl}/feature-sub-label/thumbnail-stream-cached/${LastModified}/` + ID;
    } else if (ID) {
      if (ID) {
        return `${environment.nodeUrl}/feature-sub-label/thumbnail-stream/` + ID;
      }
    }

    return null;
  }
  getFeatureSubLabelCoverImage(ID) {
    return this.http
      .get(`${environment.nodeUrl}/feature-sub-label/cover/` + ID, {})
      .pipe(map((res: Response) => res['data']));
  }
  getFeatureSubLabelThumbnailImage(ID) {
    return this.http
      .get(`${environment.nodeUrl}/feature-sub-label/thumbnail/` + ID, {})
      .pipe(map((res: Response) => res['data']));
  }
  editFeatureSubLabelGroup(payload = {}): Observable<any> {
    return this.http
      .put(environment.nodeUrl + '/feature-sub-label/group', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }
  deleteFeatureSubLabelGroup(ids): Observable<any> {
    let _ids;
    if (Array.isArray(ids) == true) {
      _ids = ids.join(',');
    } else if (typeof ids == 'string') {
      _ids = ids;
    }

    if (_ids) {
      return this.http
        .delete(environment.nodeUrl + '/feature-sub-label/group/' + _ids)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  getSubFeatureHTMLContent(id, sessionType, LastModified = null): Observable<any> {
    if (
      LastModified &&
      LastModified != '' &&
      LastModified != undefined &&
      LastModified != 'null' &&
      LastModified != 'undefined '
    ) {
      if (sessionType === 'admin' || sessionType === 'promoter') {
        return this.http
          .get(environment.nodeUrl + '/feature-sub-label/html-cached/global/' + LastModified + '/' + id)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else if (sessionType === 'guest' || !sessionType) {
        return this.http
          .get(environment.nodeUrl + '/feature-sub-label/html-cached/public/' + LastModified + '/' + id)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.http
          .get(environment.nodeUrl + '/feature-sub-label/html-cached/' + LastModified + '/' + id)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (sessionType === 'admin' || sessionType === 'promoter') {
        return this.http
          .get(environment.nodeUrl + '/feature-sub-label/html/global/' + id)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else if (sessionType === 'guest' || !sessionType) {
        return this.http
          .get(environment.nodeUrl + '/feature-sub-label/html/public/' + id)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.http
          .get(environment.nodeUrl + '/feature-sub-label/html/' + id)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }
  getSubFeatureHTMLContentHidden(id, sessionType, LastModified = null): Observable<any> {
    if (
      LastModified &&
      LastModified != '' &&
      LastModified != undefined &&
      LastModified != 'null' &&
      LastModified != 'undefined '
    ) {
      if (sessionType === 'admin' || sessionType === 'promoter') {
        return this.http
          .get(environment.nodeUrl + '/feature-sub-label/html-cached-hidden/global/' + LastModified + '/' + id)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else if (sessionType === 'guest' || !sessionType) {
        return this.http
          .get(environment.nodeUrl + '/feature-sub-label/html-cached-hidden/public/' + LastModified + '/' + id)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.http
          .get(environment.nodeUrl + '/feature-sub-label/html-cached-hidden/' + LastModified + '/' + id)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (sessionType === 'admin' || sessionType === 'promoter') {
        return this.http
          .get(environment.nodeUrl + '/feature-sub-label/html-hidden/global/' + id)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else if (sessionType === 'guest' || !sessionType) {
        return this.http
          .get(environment.nodeUrl + '/feature-sub-label/html-hidden/public/' + id)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.http
          .get(environment.nodeUrl + '/feature-sub-label/html-hidden/' + id)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }
}
