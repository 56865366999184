<div class="rel" *ngIf="editorComponent && editorComponent.ID">
  <div class="card-header primary-gradient-img clearfix confirmModal">
    <button class="btn-close btn-clear mat-button smt white custom-close" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="full-width flex main-title-container">
      <div class="row full-width title-container clearfix">
        <div class="title-container-sub">
          <h2 class="text-left title-header rm-m">
            {{ title }}

            <span class="subLabel" *ngIf="description">{{ description }}</span>
          </h2>
          <p class="clearfix small rm-mb">
            <span
              [ngClass]="{
                'chip-enable': editorComponent.Is_Active === '1',
                'chip-disable': !editorComponent.Is_Active === '0'
              }"
            >{{ editorComponent.Is_Active == '0' ? 'Not Active' : 'Active' }}</span
            >

            <span class="chip-enable" *ngIf="editorComponent.Is_Default === '1'">Default</span>

            <span class="chip-custom" *ngIf="editorComponent['Nature.Code'] == 'Style'" matTooltip="Style">Style</span>
            <span class="chip-custom" *ngIf="editorComponent['Nature.Code'] == 'PageTem'" matTooltip="Page Template"
            >Page Template</span
            >
            <span class="chip-custom" *ngIf="editorComponent['Nature.Code'] == 'Template'" matTooltip="Component"
            >Component</span
            >
            <span
              class="chip-custom"
              *ngIf="editorComponent['Type.Label']"
              matTooltip="{{ editorComponent['Type.Label'] }}"
            >{{ editorComponent['Type.Label'] }}</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="full-width smt" *ngIf="isLoaded == true && isEdit === true" @ngIfAnimation>
      <div class="full-width" *ngIf="natureCode === 'Template'">
        <app-html-css-input-backup
          class="full-width"
          [isComponent]="true"
          [componentID]="'ID' + editorComponentID"
          [displayUploader]="false"
          [viewType]="viewType"
          [content]="defaultContent"
          [style]="defaultStyle"
          [descriptionHTML]="descriptionHTML"
          [descriptionRaw]="descriptionHTML"
          [descriptionDocument]="descriptionHTML"
          [descriptionEditor]="descriptionHTML"
          (getIsEditStyle)="getIsEditStyleEvent($event)"
          (getStyle)="getStyleEvent($event)"
          (getContent)="getContentEvent($event)"
        >
        </app-html-css-input-backup>
      </div>

      <div class="full-width" *ngIf="natureCode === 'Style'">
        <app-html-css-input-backup
          class="full-width"
          [isComponent]="false"
          [descriptionHTML]="descriptionHTML"
          [viewType]="viewType"
          [displayUploader]="false"
          [content]="defaultContent"
          [style]="defaultStyle"
          [descriptionRaw]="descriptionCSSHTML"
          (getIsEditStyle)="c($event)"
          (getStyle)="getStyleEvent($event)"
          (getContent)="getContentEvent($event)"
        >
        </app-html-css-input-backup>
      </div>

      <div class="full-width" *ngIf="natureCode === 'PageTem'">
        <app-html-css-input-backup
          class="full-width"
          [isComponent]="false"
          [descriptionHTML]="descriptionHTML"
          [viewType]="viewType"
          [displayUploader]="false"
          [content]="defaultContent"
          [style]="defaultStyle"
          [descriptionRaw]="descriptionHTML"
          [descriptionDocument]="descriptionHTML"
          [descriptionEditor]="descriptionHTML"
          (getIsEditStyle)="getIsEditStyleEvent($event)"
          (getStyle)="getStyleEvent($event)"
          (getContent)="getContentEvent($event)"
        >
        </app-html-css-input-backup>
      </div>
    </div>

    <div class="full-width" *ngIf="isLoaded == true && isEdit !== true" @ngIfAnimation style="margin-top: -15px">
      <app-code-html-view
        *ngIf="(style || content) && isEditStyle == true"
        [isModal]="false"
        [content]="preview"
        [style]="style"
      >
      </app-code-html-view>
      <app-code-html-view
        *ngIf="(style || content) && isEditStyle != true"
        [isModal]="false"
        [content]="preview"
        [style]="null"
      >
      </app-code-html-view>

      <app-empty-list-message
        class="full-width"
        *ngIf="!style && !content"
        [title]="'No content'"
        [message]="'Nothing to display'"
      ></app-empty-list-message>
    </div>
  </mat-dialog-content>

  <hr *ngIf="isEdit !== true"/>
  <div class="drop-button text-center full-width">
    <button class="pull-left btn mr" (click)="closeEvent()" mat-raised-button color="primary">Close</button>
    <button class="pull-left btn" *ngIf="isEdit != true" (click)="edit()" mat-raised-button color="accent">
      <mat-icon class="smr">edit</mat-icon> Edit
    </button>
    <button class="pull-left btn" *ngIf="isEdit == true" (click)="view()" mat-raised-button color="primary">
      <mat-icon class="smr">visibility</mat-icon> View
    </button>

    <button
      class="pull-right btn ml"
      *ngIf="natureCode === 'Template'"
      (click)="inserResult()"
      mat-raised-button
      color="accent"
    >
      Insert Content & Style
    </button>

    <button
      class="pull-right btn ml"
      *ngIf="natureCode === 'Template'"
      (click)="inserResult(true, false)"
      mat-raised-button
      color="primary"
    >
      Copy Code & Insert Style
    </button>

    <button
      class="pull-right btn ml"
      *ngIf="natureCode === 'Style'"
      (click)="inserResultOverride()"
      mat-raised-button
      color="accent"
    >
      Insert Style & Close
    </button>

    <button
      class="pull-right btn ml"
      *ngIf="natureCode === 'Style'"
      (click)="inserResult(true, false)"
      mat-raised-button
      color="primary"
    >
      Copy Style & Close
    </button>

    <button
      class="pull-right btn ml"
      *ngIf="natureCode === 'PageTem'"
      (click)="replaceResult()"
      mat-raised-button
      color="accent"
    >
      Replace Content & Style
    </button>

    <button
      class="pull-right btn ml"
      *ngIf="natureCode === 'PageTem'"
      (click)="inserResultOverride()"
      mat-raised-button
      color="accent"
    >
      Insert Content & Style
    </button>

    <button
      class="pull-right btn ml"
      *ngIf="natureCode === 'PageTem'"
      (click)="inserResult(true, false)"
      mat-raised-button
      color="primary"
    >
      Copy Code & Insert Style
    </button>
  </div>
</div>
