<div class="white-background clearfix">
  <div class="row clearfix cardHeader primary-gradient-img inModal">
    <!-- close button -->
    <button class="btn-close btn-clear white pull-right mat-button" (click)="closeEvent()" mat-dialog-close>
      <mat-icon>clear</mat-icon>
    </button>
    <div class="row titleArea clearfix">
      <h2 class="summary-header rm-m white">
        {{ title }}
        <span class="subLabel">{{ description }}</span>
      </h2>
    </div>
  </div>

  <mat-dialog-content>
    <div *ngIf="content" [innerHTML]="content | safeHtml"></div>
  </mat-dialog-content>
  <mat-dialog-actions class="button-row text-right">
    <div class="clearfix row text-right full-width">
      <hr/>
      <button class="" (click)="closeEvent()" mat-raised-button color="primary">Close</button>
    </div>
  </mat-dialog-actions>
</div>
