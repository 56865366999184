<ipv-data-table
  [table]="informedConsentList"
  [hide]="hiddenColumns"
  [order]="[
    'Invitation.FirstName',
    'Invitation.Email',
    'InformedConsent.Given.Flag',
    'InformedConsent.Given.When',
    'Documents.Presented',
    'Documents.Viewed'
  ]"
  [rename]="{
    'Invitation.FirstName': 'Patient',
    'Invitation.Email': 'Email',
    'Merchant.Name': 'Merchant',
    'InformedConsent.Given.When': 'Consent Date',
    'InformedConsent.Given.Flag': 'Informed Consent',
    'Documents.Presented': 'Documents Presented',
    'Documents.Viewed': 'Documents Viewed',
    DateTimeCreated: 'Date Created'
  }"
  [formatHeaders]="{
    'Documents.Presented': 'center',
    'Documents.Viewed': 'center'
  }"
  [menuTemplate]="actions"
  [customComponents]="{
    'Invitation.FirstName': firstname,
    'InformedConsent.Given.When': consentDate,
    'InformedConsent.Given.Flag': informedConsent,
    DateTimeCreated: dateCreated,
    'Documents.Presented': documentsPresented,
    'Documents.Viewed': documentsViewed
  }"
  (itemClicked)="viewPDF($event)"
></ipv-data-table>

<ng-template #firstname let-record>
  <div style="white-space: nowrap">
    {{ record['Invitation.FirstName'] + ' ' + record['Invitation.LastName'] | titlecase }}
  </div>
</ng-template>

<ng-template #informedConsent let-record>
  <ipv-list-label *ngIf="record['InformedConsent.Given.Flag'] == '1'; else consentNotGiven" [color]="'#6fb662'">
    <span> ✔ Consented </span>
  </ipv-list-label>

  <ng-template #consentNotGiven>
    <ipv-list-label [color]="'#ef4827'">
      <span> ✘ Unconsented </span>
    </ipv-list-label>
  </ng-template>
</ng-template>

<ng-template #consentDate let-record>
  <div *ngIf="record['InformedConsent.Given.When']; else noConsentDate" style="white-space: nowrap">
    {{ record['InformedConsent.Given.When'] | customDate }}
  </div>

  <ng-template #noConsentDate> None</ng-template>
</ng-template>

<ng-template #dateCreated let-record>
  {{ record['DateTimeCreated'] | customDate }}
</ng-template>

<ng-template #documentsPresented let-record>
  <div *ngIf="record['Documents.Presented']; else noDocumentsProperty" style="text-align: center">
    {{ record['Documents.Presented'] | countStringIDs }}
  </div>

  <ng-template #noDocumentsProperty>
    <div style="text-align: center">0</div>
  </ng-template>
</ng-template>

<ng-template #documentsViewed let-record>
  <div *ngIf="record['Documents.Viewed']; else documentsViewed" style="text-align: center">
    {{ record['Documents.Viewed'] | countStringIDs }}
  </div>

  <ng-template #documentsViewed>
    <div style="text-align: center">0</div>
  </ng-template>
</ng-template>

<ng-template #actions let-record>
  <button [disabled]="record['InformedConsent.Given.Flag'] === '0'" (click)="viewPDF(record)" mat-menu-item>
    <mat-icon class="fas fa-file-pdf" style="text-align: center"></mat-icon>
    <span [ngStyle]="{ 'text-decoration': record['InformedConsent.Given.Flag'] === '1' ? 'none' : 'line-through' }">
      View PDF
    </span>
  </button>
</ng-template>
