<mat-dialog-content>
  <form #bsbForm="ngForm">
    <div class="full-width row clearfix cardheader primary-gradient-img">
      <button class="btn-close btn-clear pull-right mat-button" (click)="closeModalEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <p class="lead clearfix rm-mt text-center shadow-text"><strong>BSB Lookup</strong></p>
    </div>
    <div class="flex full-width mb mt row clearfix">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Select your Bank</mat-label>

        <input
          class="full-width"
          [(ngModel)]="bankVendorName"
          [matAutocomplete]="autoBank"
          (ngModelChange)="getBankList($event)"
          name="bankVesndorName"
          type="text"
          placeholder="Select your Bank"
          matInput
          required
        />
        <mat-autocomplete #autoBank="matAutocomplete" (optionSelected)="getBankDetails($event.option.value)">
          <mat-option *ngFor="let b of banks | orderBy: ['Name']" [value]="b">
            {{ b.Name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Select your Suburb</mat-label>
        <mat-select
          class="full-width"
          [(ngModel)]="selectedSuburb"
          [disabled]="banksSuburb && banksSuburb.length <= 0"
          (selectionChange)="getAddresss()"
          matInput
          placeholder="Select your Suburb"
          name="bankVendorName"
          required
        >
          <mat-option
            *ngFor="let bankSuburb of banksSuburb | objectPropertyValue: 'Suburb' | unique | orderBy"
            [value]="bankSuburb"
          >
            {{ bankSuburb }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Select the address</mat-label>
        <mat-select
          class="full-width"
          [(ngModel)]="bankAddress"
          [disabled]="addresses && addresses.length <= 0"
          (selectionChange)="selectAdress(bankAddress)"
          matInput
          placeholder="Select the address"
          name="bankVendorAddress"
          required
        >
          <mat-option *ngFor="let bankVendorAddress of addresses | orderBy: ['Address']" [value]="bankVendorAddress">
            {{ bankVendorAddress.Address }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row clearfix text-center full-width difference flex" *ngIf="bank">
      <div class="full-width">
        <h4 class="accent">Bank Name</h4>
        <p>{{ bank['Bank.Code'] }}</p>
      </div>
      <div class="full-width">
        <h4 class="accent">Branch</h4>
        <p>{{ bank['Branch.Name'] }}</p>
      </div>
      <div class="full-width">
        <h4 class="accent">Your BSB</h4>
        <p>{{ bank['BSB'] }}</p>
      </div>
    </div>
    <div class="row mt mb clearfix t full-width" *ngIf="bank && bank['State'] && bank['Suburb'] && bank['Postcode']">
      <app-map-view
        class="clearfix"
        [icon]="iconAddres"
        [canClose]="false"
        [canClick]="false"
        [zoom]="17"
        [postCode]="bank['Postcode']"
        [state]="bank['State']"
        [suburb]="bank['Suburb']"
        [moreInfo]=""
      ></app-map-view>
    </div>
    <div class="row clearfix text-center">
      <button
        class="btn-large"
        [disabled]="!bank || !bank.BSB || !bsbForm.form.valid"
        (click)="select()"
        mat-raised-button
        color="accent"
      >
        Select
      </button>
    </div>
  </form>
</mat-dialog-content>
