import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { AuthenticationService } from '../../authentication/shared/authentication.service';

@Component({
  selector: 'app-page500',
  templateUrl: './page500.component.html',
  styleUrls: ['./page500.component.css'],
})
export class Page500Component implements OnInit {
  code: any = null;
  error: any = null;
  countdown: number;
  subscribe;
  redirection = true;

  constructor(private activeRoute: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['error']) {
        this.error = params['error'];
      }

      if (params['code']) {
        this.code = params['code'];
      }
      if (this.code && this.error) {
        NotifyAppComponent.displayToast('error', this.code, this.error);

        console.error('Code: ' + this.code + ' | Error: ' + this.error);
      }
      AuthenticationService.setDrift.emit('errorDrift');
      this.startCountdownTimer();
    });
  }

  ngOnDestroy() {
    this.redirection = false;
    this.subscribe.unsubscribe();
  }

  goHome() {
    this.redirection = false;
    this.subscribe.unsubscribe();
    window.location.href = '/login';
  }

  startCountdownTimer() {
    this.countdown = 0;
    const interval = 1000;
    const duration = 60 * 1000;
    const stream$ = Observable.timer(0, interval)
      .finally(() => {
        if (this.redirection == true) {
          this.goHome();
        }
      })
      .takeUntil(Observable.timer(duration + interval))
      .map((value) => duration - value * interval);
    this.subscribe = stream$.subscribe((value) => (this.countdown = value));
  }

  contactUs() {
    if (this.subscribe) {
      this.redirection = false;
      this.subscribe.unsubscribe();
      this.countdown = null;
    }

    AuthenticationService.contactUSEvent.emit();
  }
}
