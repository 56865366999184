import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material";
import { DomSanitizer } from "@angular/platform-browser";
import { ColorPalette } from "../../../shared/types/color-palette";
import { Settings } from "../../../shared/types/settings";
import { UtilsClass } from "../../../shared/types/utils/utils.class";
import { AuthenticationService } from "../../authentication/shared/authentication.service";
import { SubscriptionPlanService } from "../shared/subscription-plan.service";

import * as _ from "lodash";
import hexRgb from "hex-rgb";
import { Router } from "@angular/router";
import { RootAppComponent } from "../../../shared/components/root-component/root-component.component";
import { SimplePageViewComponent } from "../../helper/simple-page-view/simple-page-view.component";
import {
  ConfirmDialogSingleButtonComponent
} from "../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component";
import { ConfirmDialogSingleButton } from "../../../shared/types/confirm-dialog-single-button";
import { CustomCurrencyPipe } from "../../../shared/pipes/custom-currency.pipe";
import { InfoModalComponent } from "../../helper/info-modal/info-modal.component";
import { CustomPhonePipe } from "../../../shared/pipes/custom-phone.pipe";

@Component({
  selector: "app-subscription-plan-view-modal",
  templateUrl: "./subscription-plan-view-modal.component.html",
  styleUrls: ["./subscription-plan-view-modal.component.css"]
})
export class SubscriptionPlanViewModalComponent implements OnInit {
  @Input()
  subscriptionPlanID;
  @Input()
  ids;
  @Input()
  color = Settings.global["primaryColor"] || "#1e88e5";
  @Input()
  colorSecond;
  subscriptionPlan;
  root = "/merchant";
  @Input()
  featureType;
  sessionType;
  @Output()
  close = new EventEmitter();

  @Output()
  getPlan = new EventEmitter();
  settings = Settings.global
  postPictureThumbnail;
  isActive = true;
  price;
  label;
  description;
  waivedPeriod = 0;
  minTreatmentPlan = 0;
  smsPrice = 0;
  isFreeSMS = false;
  marketingDescriptions = [];
  isPublic = true;
  utils = new UtilsClass();

  isRecommended = false;
  addCustomization;
  currentPlanID;
  isPlanOwn = false;
  isImageError = false;
  postCover;
  postCoverURL;
  webDescription;
  minTreatmentPlanMerchant;
  displaySubscribeButton = true;

  currentPrice;

  isPromoterOrAdmin = false;
  promoter
  constructor(
    private authenticationService: AuthenticationService,
    private subscriptionPlanService: SubscriptionPlanService,
    private router: Router,
    private domSanitizer: DomSanitizer,
    private customPhonePipe: CustomPhonePipe,
    private currencyPipe: CustomCurrencyPipe,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.subscriptionPlanID) {
        this.subscriptionPlanID = data.subscriptionPlanID;
      }
      if (data.featureType) {
        this.featureType = data.featureType;
      }

      if (data.displaySubscribeButton != null) {
        this.displaySubscribeButton = data.displaySubscribeButton;
      }
      if (data.addCustomization != null) {
        this.addCustomization = data.addCustomization;
      }

      if (data.ids && typeof data.ids === "string") {
        this.ids = data.ids.split(",");
      } else if (data.ids && data.ids.length > 0) {
        this.ids = data.ids;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getPromoter().subscribe(res => {
      if (res && res.data) {
        this.promoter = res.data
      } else if (res) {
        this.promoter = res;
      }
      this.authenticationService.isPromoterOrAdmin().subscribe(r => {
        this.isPromoterOrAdmin = r;


        this.authenticationService.getSessionType().subscribe((sessionType) => {
          this.sessionType = sessionType;

          this.setup();
        });
      })
    })
  }

  setup() {
    if (this.featureType === "consumer") {
      this.root = "/consumer";
    } else {
      this.root = "/merchant";
    }
    this.authenticationService.getCurrentPlan().subscribe((currentPlanID) => {
      if (currentPlanID && currentPlanID) {
        this.currentPlanID = currentPlanID;
      }

      if (this.subscriptionPlanID) {
        this.subscriptionPlanService
          .getPromoterPlanDetails(
            this.subscriptionPlanID,
            { addCustomization: this.addCustomization },
            this.sessionType
          )
          .subscribe((res) => {
            if (res) {
              this.subscriptionPlan = res;
              this.buildItem();
            }
          });
      } else {
        this.onColorPickerClose(this.color);
      }
    });
  }

  buildItem() {
    if (this.subscriptionPlan && this.subscriptionPlan.ID) {
      if (this.currentPlanID && this.subscriptionPlan.ID == this.currentPlanID) {
        this.isPlanOwn = true;
      } else {
        this.isPlanOwn = false;
      }

      if (this.subscriptionPlan["Color"]) {
        let split = this.subscriptionPlan["Color"].split("|");
        if (split[0]) {
          this.color = split[0];
        }
        if (split[1]) {
          this.colorSecond = split[1] || null;
        }
        if (this.color && !this.colorSecond) {
          this.onColorPickerClose(this.color);
        }
      } else if (this.color && !this.colorSecond) {
        this.onColorPickerClose(this.color);
      }

      if (this.subscriptionPlan["Web_Features"]) {
        this.webDescription = this.subscriptionPlan["Web_Features"];
      }

      this.postPictureThumbnail = this.subscriptionPlanService.getPromoterPlanThumbnailImageStreamLink(
        this.subscriptionPlan.ID,
        this.subscriptionPlan.LastModified
      );

      if (this.subscriptionPlan && this.subscriptionPlan.Marketing_Description) {
        this.marketingDescriptions = this.subscriptionPlan.Marketing_Description.split("||");
      }
      if (this.subscriptionPlan["SMS_Price"]) {
        this.smsPrice = this.subscriptionPlan["SMS_Price"];
      }
      if (this.subscriptionPlan["Is_SMS_Free"] === "1") {
        this.isFreeSMS = true;
      } else {
        this.isFreeSMS = false;
      }

      if (this.subscriptionPlan["Is_Public"] != "1") {
        this.isPublic = false;
      } else {
        this.isPublic = true;
      }

      if (this.subscriptionPlan["Is_Active"] != "1") {
        this.isActive = false;
      } else {
        this.isActive = true;
      }

      this.postCover = this.subscriptionPlanService.getPromoterPlanCoverImageStreamLink(
        this.subscriptionPlan.ID,
        this.subscriptionPlan.LastModified
      );

      this.postCoverURL = this.domSanitizer.bypassSecurityTrustStyle("url(" + this.postCover + ")");

      if (this.subscriptionPlan["Waived_PeriodInDays"]) {
        this.waivedPeriod = this.subscriptionPlan["Waived_PeriodInDays"];
      }

      if (this.subscriptionPlan["Min_TreatmentPlans_Sent"]) {
        this.minTreatmentPlan = this.subscriptionPlan["Min_TreatmentPlans_Sent"];
      }

      if (this.subscriptionPlan["Min_TreatmentPlans_Sent_Merchant"]) {
        this.minTreatmentPlanMerchant = this.subscriptionPlan["Min_TreatmentPlans_Sent_Merchant"];
      } else {
        this.minTreatmentPlanMerchant = null;
      }

      if (this.subscriptionPlan["Recommended_Plan"] === "Yes") {
        this.isRecommended = true;
      } else {
        this.isRecommended = false;
      }

      if (this.subscriptionPlan["Price"]) {
        this.price = Number(this.subscriptionPlan["Price"] || 0);
      }

      if (this.subscriptionPlan["Web_Label"]) {
        this.label = this.subscriptionPlan["Web_Label"];
      }
      if (this.subscriptionPlan["Web_Description"]) {
        this.description = this.subscriptionPlan["Web_Description"];
      }
    }
  }

  onLoad() {
    this.isImageError = false;
  }

  onError() {
    this.isImageError = true;
  }

  onColorPickerClose(color: string): void {
    if (color) {
      this.color = color;

      let v = new ColorPalette(color);
      let palette = v.getPalette();

      if (palette && palette.length > 0) {
        let index = palette.findIndex((item) => {
          if (item && item.name == 800) {
            return true;
          } else {
            return false;
          }
        });

        if (palette[index] && palette[index]["hex"]) {
          this.colorSecond = palette[index]["hex"];
        }
      }
    }
  }

  buildShadowBox(color) {
    if (color) {
      let rbg = hexRgb(color);

      if (rbg) {
        return `5px 4px 10px 0px rgb(${rbg.red}  ${rbg.green} ${rbg.blue} / 30%), 0px 1px 6px 0px rgb(${rbg.red}  ${rbg.green} ${rbg.blue} / 13%), -10px 5px 10px 0px rgb(${rbg.red}  ${rbg.green} ${rbg.blue} / 20%)`;
      } else {
        return null;
      }
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  view() {
    if (this.ids && this.ids.length > 1 && this.subscriptionPlan && this.subscriptionPlan.ID) {
      this.ids = _.uniq(this.ids);
      this.closeEvent();
      this.router.navigate([
        this.root,
        {
          outlets: {
            page: ["subscription-plan-view", this.subscriptionPlan.ID, this.ids.join(",")]
          }
        }
      ]);
    } else {
      this.closeEvent();
      this.router.navigate([
        this.root,
        {
          outlets: {
            page: ["subscription-plan-view", this.subscriptionPlan.ID]
          }
        }
      ]);
    }
  }

  getPlanEvent() {
    let ref = RootAppComponent.dialog.open(SimplePageViewComponent, {
      data: {
        simplePageCode: "subscriptionPlanTermAndCondition",
        color: this.color,
        colorSecond: this.colorSecond,
        title: this.subscriptionPlan.Web_Label + " Subscription plan  Terms and Conditions",
        canAccept: true,
        payload: {
          fee: this.currencyPipe.transform(this.price),
          smsFee: this.currencyPipe.transform(this.smsPrice),
          plan: this.subscriptionPlan.Web_Label
        }
      },
      width: "800px"
    });

    ref.componentInstance.proceed.subscribe((res) => {
      RootAppComponent.stopSubPlanTimer = true;
      let p = {
        subscriptionPlanID: this.subscriptionPlanID,
        updateCurrent: true
      };
      this.subscriptionPlanService.createMerchantPlan(p, this.sessionType).subscribe((plan) => {
        if (plan && plan.ID) {
          this.authenticationService.getCurrentPlanTransactionForce(false).subscribe((res1) => {
            this.authenticationService.getCurrentUserForce(false).subscribe((res) => {
              this.authenticationService.getCurrentForceAccess(false).subscribe((access) => {
                this.authenticationService.getCurrentPlanForce(false, true).subscribe((res) => {
                  if (access) {
                    RootAppComponent.setCurrentAccess.emit(access);
                  }

                  this.router.navigate([
                    this.root,
                    {
                      outlets: {
                        page: ["subscription-plan-view-current", "current"]
                      }
                    }
                  ]);
                  this.getPlan.emit(plan);
                  RootAppComponent.stopSubPlanTimer = false;
                  ref.close();
                });
              });
            });
          });
        }
      });
    });
  }

  moreWaivedDetails() {
    let confirmDialog2 = new ConfirmDialogSingleButton(
      "fas fa-info",
      "Waived period",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." +
      " Fermentum et sollicitudin ac orci phasellus egestas. Eget lorem dolor sed viverra. Nunc lobortis mattis aliquam faucibus purus in massa tempor.",
      "OKAY"
    );
    let ref2 = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
      data: confirmDialog2,
      width: "500px"
    });
    ref2.componentInstance.onConfirm.subscribe((res) => {
      ref2.close();
    });
  }

  moreMinTreatmentDetails() {
    let confirmDialog2 = new ConfirmDialogSingleButton(
      "fas fa-info",
      "Minimum  required treatment",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." +
      " Fermentum et sollicitudin ac orci phasellus egestas. Eget lorem dolor sed viverra. Nunc lobortis mattis aliquam faucibus purus in massa tempor.",
      "OKAY"
    );
    let ref2 = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
      data: confirmDialog2,
      width: "500px"
    });
    ref2.componentInstance.onConfirm.subscribe((res) => {
      ref2.close();
    });
  }

  downgrade() {



    const refError = RootAppComponent.dialog.open(InfoModalComponent, {
      data: {
        content:
          `<p>Once downgraded, you will lose access to several key features in the platform</p> ` +
          `<p  >Please call our office on <strong> ${this.customPhonePipe.transform(this.promoter['phones.Number'], 'landLine')} </strong> and discuss this with the sales team to organise.</p><br>` ,
        title: 'Looking to downgrade your subscription?',
        displayContact: true,
      },

      width: '500px',
    });
    refError.componentInstance.closeModal.subscribe((data) => {
      refError.close();
    });
  }
}
