<div class="row clearfix">
  <!-- blue heading -->
  <mat-card class="card full-width white-background">
    <div class="card-header primary-gradient-img clearfix">
      <div class="row clearfix">
        <!-- Modal dismiss button -->
        <button class="btn-close btn-clear pull-right mat-button" (click)="closeEvent()">
          <mat-icon>clear</mat-icon>
        </button>
        <!-- merchant calculated name -->
        <div class="row titleArea clearfix">
          <h2 class="message-title summary-header rm-m">
            <span>Message Template </span>
            <span class="subLabel">
              Insert a label and content for your template<br />
              {{ productGroup }}
            </span>
          </h2>
        </div>
      </div>
    </div>
    <!-- end blue heading -->

    <form #msgForm="ngForm">
      <!--  mat dialog scrolling area -->
      <mat-dialog-content>

        <mat-form-field class="full-width mt" appearance="outline" *ngIf="isPromoterOrAdmin==true && messageMode == 'sms'">
          <mat-label>Choose the type of message</mat-label>

          <mat-select class="full-width" name="messageType" placeholder="Choose the type of message" [(ngModel)]="templateNature">
            <mat-option [value]="'promoter'">
              <strong> Promoter </strong> SMS
            </mat-option>

            <mat-option [value]="'brand'">
              <strong>{{"BRANDING.Brand Name" | translate}} </strong>SMS (Default)
            </mat-option>

            <mat-option [value]="'practice'">
              <strong> {{ "KEYWORD.practice" | translate }} </strong> SMS
            </mat-option>

          </mat-select>
        </mat-form-field>


        <div class="row clearfix smt" *ngIf="!messageTemplateID && messageMode != 'sms'">
          <mat-form-field class="full-width" *ngIf="displayPurpose == true" appearance="outline">
            <mat-label>Choose the type of message</mat-label>
            <mat-select class="full-width" [(ngModel)]="purposeCode" [disabled]="messageTemplateID" name="purposeCode"
              placeholder="Choose the type of message" required>
              <mat-option *ngFor="let p of purposeCodes" [value]="p['Code']">
                {{ p['Label'] }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="row clearfix full-width"
          *ngIf="displayProductGroup == true && !messageTemplateID && purposeCode == 'MerInv'">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Select a product group</mat-label>
            <mat-select class="full-width" [(ngModel)]="productGroup" [disabled]="messageTemplateID"
              name="productGroupCode" placeholder="Select a product group">
              <mat-option [value]="''">All</mat-option>
              <mat-option *ngFor="let p of productGroupCodes" [value]="p['GroupCode']">
                <mat-icon class="fas {{ productUtil.getProductGroupIcon(p['GroupCode']) }} mr iconproductgroup ">
                </mat-icon>

                {{ productUtil.getLabel(p['GroupCode']) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="row clearfix smt">
          <mat-form-field class="full-width" appearance="outline">
            <input [(ngModel)]="label" matInput placeholder="Label" name="label" required />
          </mat-form-field>
        </div>


        <h3 *ngIf="loadCard==true && loadMerchant==true && loadPromoter==true && loadContact==true"
          class="sr-title   text-left  full-width  smt smb  "> Content</h3>


        <div class="row clearfix "
          *ngIf="loadCard==true && loadMerchant==true && loadPromoter==true && loadContact==true && messageMode == 'sms'">

          <message-input class="full-width  message-input-component" [isModal]="false" [message]="message"

          [isTiny]="false"

            [showAllCustomization]="true" [promoter]="promoter" [card]="card" [textLength]="160" [displayEmoji]="false"
            [displayMessageTemplate]="false" [contact]="contact" [displayPatient]="true" [displayContact]="true"
            [displayMerchant]="true" [displayPromoter]="true" [displayMarketing]="true" [displayExpend]="false"
            [displayDentalOffers]="true" [displayDocument]="true" [saveAsTemplate]="false" [isSMSPreview]="true"
            [isDesktopPreview]="true" [displaySaveAsTemplate]="false" [encode]="false" [tableName]="tableName"
            (getResult)="getResultEvent($event)">
          </message-input>
        </div>

        <div class="row clearfix "
        *ngIf="loadCard==true && loadMerchant==true && loadPromoter==true && loadContact==true && messageMode != 'sms'">

        <message-input class="full-width  message-input-component" [isModal]="false" [message]="message"

        [isTiny]="false"

          [showAllCustomization]="true" [promoter]="promoter" [card]="card" [textLength]="0" [displayEmoji]="false"
          [displayMessageTemplate]="false" [contact]="contact" [displayPatient]="true" [displayContact]="true"
          [displayMerchant]="true" [displayPromoter]="true" [displayMarketing]="true" [displayExpend]="false"
          [displayDentalOffers]="true" [displayDocument]="true" [saveAsTemplate]="false" [isSMSPreview]="true"
          [isDesktopPreview]="true" [displaySaveAsTemplate]="false" [encode]="false" [tableName]="tableName"
          (getResult)="getResultEvent($event)">
        </message-input>
      </div>

        <h3 *ngIf="loadTag==true" class="sr-title   text-left  full-width  smt smb  "> Tags</h3>


        <div class="full-width " *ngIf="loadTag==true && tags && tags.length>0">
          <app-tag-list class="full-width mb" [tags]="tags" [selectedTags]="defaultSelectedTags" [add]="addTag"
            [tagFormatIsObject]="false" [title]="'Attach tags to this record'" [canRemove]="true" [canCreate]="true"
            [directRemove]='true' [directRemoveDefault]='true' [hasFilter]="true" [canSelect]="false"
            (getCreate)="createNewTag()" (getSelectedTags)="getSelectedTags($event)">
          </app-tag-list>

        </div>

        <div class="full-width " *ngIf="loadTag==true && (!tags || (tags &&  tags.length<=0))">

          <button class="pull-left  btn" (click)="createNewTag()" mat-raised-button color="accent">
            New tag <mat-icon class=" fas fa-tag sml" style="font-size:1rem !important;margin-top: 10px;"></mat-icon>
          </button>

        </div>



        <div class="row  full-width  clearfix flex mt mb">

          <div class="  full-width text-left" *ngIf="isPromoterOrAdmin==true && messageMode != 'sms'">
            <mat-checkbox name="isDefault" [(ngModel)]="isDefault" class="  smr" matTooltip="Save As Template">
              <span>Save As Promoter Template</span>
            </mat-checkbox>
          </div>

          <div class="full-width text-left" *ngIf="messageTemplateID">
            <mat-checkbox class="smr" [(ngModel)]="isActive" name="isActive">
              <span>Is active Template</span>
            </mat-checkbox>
          </div>
        </div>




      </mat-dialog-content>
      <mat-dialog-actions>
        <hr />
        <div class="row clearfix full-width text-center">
          <button class="pull-right btn-large smb" *ngIf="saveAndApply == true"
            [disabled]="!content || !purposeCode || !label" (click)="save(true)" mat-raised-button color="accent">
            Apply & Submit
          </button>

          <button class="pull-right btn-large mr smb" *ngIf="saveAndApply == true"
            [disabled]="!content || !purposeCode || !label" (click)="save()" mat-raised-button color="accent">
            Submit
          </button>

          <button class="pull-right btn-large smb" *ngIf="saveAndApply != true"
            [disabled]="!content || !purposeCode || !label" (click)="save()" mat-raised-button color="accent">
            Submit
          </button>
          <button class="pull-left btn-large smb" (click)="closeEvent()" mat-raised-button color="primary">
            Close
          </button>
        </div>
      </mat-dialog-actions>
    </form>
  </mat-card>
</div>
