import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as momentTZ from 'moment-timezone';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { SessionsService } from '../shared/sessions.service';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Observable } from 'rxjs';
import { MerchantTradingHoursOverviewComponent } from '../../../feature/merchant/merchant-trading-hours-overview/merchant-trading-hours-overview.component';
import { MerchantTradingHoursComponent } from '../../../feature/merchant/merchant-trading-hours/merchant-trading-hours.component';
import { MerchantService } from '../../../feature/merchant/shared/merchant.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { LookupService } from '../../../shared/services/lookup.service';

@Component({
  selector: 'session-edit-profile',
  templateUrl: './session-edit-profile.component.html',
  styleUrls: ['./session-edit-profile.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class SessionEditProfileComponent implements OnInit {
  profile = {};
  merchant;
  validateMobileProfile = false;
  validatePhoneProfile = false;
  validateEmailProfile = false;

  validateMobilePractice = false;
  validatePhonePractice = false;
  validateEmailPractice = false;

  phoneProfile;
  mobileProfile;
  emailProfile;

  mobilePractice;
  phonePractice;
  emailPractice;

  myPractice;
  myProfile;
  isAdressProfileValid = false;
  isAdressPracticeValid = false;

  profileMarker =
    'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/practice-marker.png';
  practiceMarker = 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profil-marker.png';

  titles = [];

  userName;
  userrole;

  tabIndex = 0;

  profileSuburb;
  profileState;
  profilePostCode;
  profileStreetNumber;
  profileStreetName;
  profileUnitNumber;
  profileStreetType;
  profileLatitude;
  profileLongitude;

  practiceSuburb;
  practiceState;
  practicePostCode;
  practiceStreetNumber;
  practiceStreetName;
  practiceUnitNumber;
  practiceStreetType;
  practiceLatitude;
  practiceLongitude;
  timeZoneCode;
  timeZoneValue;
  adrProfile;
  adrPractice;
  tradingHours;

  isCreateTradingTime = false;

  isPromoterOrAdmin = false;
  isMerchantAdmin = false;
  merchantID;
  merchantTimezone;

  profileLink;
  practiceLink;

  practiceCoverLink;
  @Input()
  tab = 'profile';
  util = new UtilsClass();

  isNewProfilePicture = false;
  isNewPracticePicture = false;

  isNewPracticePictureCover = false;

  allowedExtensionTypes = ['image'];

  constructor(
    private SessionsService: SessionsService,
    private router: Router,
    private lookupService: LookupService,
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private merchantService: MerchantService
  ) {}

  ngOnInit() {
    this.lookupService.getLookup('SimpleLookup', 'Salutation').subscribe((res) => {
      this.titles = res;

      this.setup();
    });

    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
      if (res == true) {
        this.isMerchantAdmin = true;
        this.authenticationService.getCurrentPractice().subscribe((res1) => {
          this.merchantID = res1['ID'];
        });
        this.getTradingHours();
      } else {
        this.getTradingHours();
      }
    });
  }

  setup() {
    this.authenticationService.getCurrentUserForce().subscribe((res) => {
      if (res && res.success && res.data) {
        this.myProfile = res.data;

        this.userName = this.myProfile['CalculatedName'];
        this.userrole = this.myProfile['Role'];
        this.profileSuburb = this.myProfile['addresses.Suburb'];
        this.profileState = this.myProfile['addresses.State'];
        this.profilePostCode = this.myProfile['addresses.Postcode'];
        this.profileStreetNumber = this.myProfile['addresses.Street Nr'];
        this.profileStreetName = this.myProfile['addresses.Street Name'];
        this.profileUnitNumber = this.myProfile['addresses.Unit'];
        this.profileStreetType = this.myProfile['addresses.Street Type'];

        this.profileLatitude = this.myProfile['Address.Latitude'];
        this.profileLongitude = this.myProfile['Address.Longitude'];

        this.getProfilePicture();
        this.getPracticePicture();
        this.getPracticePictureCover();

        AuthenticationService.updateProfilePicture.subscribe((res) => {
          if (res) {
            this.isNewProfilePicture = !this.isNewProfilePicture;

            this.getProfilePictureTimer();
          }
        });

        AuthenticationService.updatePracticePicture.subscribe((res) => {
          if (res) {
            this.isNewPracticePicture = !this.isNewPracticePicture;
            this.getPracticePictureTimer();
          }
        });

        AuthenticationService.updatePracticePictureCover.subscribe((res) => {
          if (res) {
            this.isNewPracticePictureCover = !this.isNewPracticePictureCover;
            this.getPracticeCoverTimer();
          }
        });
      }

      this.authenticationService.getCurrentPractice().subscribe((res) => {
        if (res) {
          this.myPractice = res;

          this.practiceSuburb = this.myPractice['addresses.Suburb'];
          this.practiceState = this.myPractice['addresses.State'];
          this.practicePostCode = this.myPractice['addresses.Postcode'];
          this.practiceStreetNumber = this.myPractice['addresses.Street Nr'];
          this.practiceStreetName = this.myPractice['addresses.Street Name'];
          this.practiceUnitNumber = this.myPractice['addresses.Unit'];
          this.practiceStreetType = this.myPractice['addresses.Street Type'];
          this.practiceLatitude = this.myPractice['Address.Latitude'];
          this.practiceLongitude = this.myPractice['Address.Longitude'];
        }
      });
    });
  }

  getResultTimeZone(e) {
    if (e) {
      if (e.code) {
        this.timeZoneCode = e.code;
      }

      if (e.value) {
        this.timeZoneValue = e.value;
      }
    }
  }

  editPractice() {
    const payload = {
      firstName: this.myProfile['FirstName'],
      midleName: this.myProfile['MiddleName'],
      lastName: this.myPractice['Name'],
      email: this.emailPractice,
      workPhone: this.phonePractice,
      mobile: this.mobilePractice,
      title: this.myPractice['Salutation'],
      abn: this.myPractice['ABN'],
      acn: this.myPractice['ACN'],
      tradingAs: this.myPractice['TradingAs'],
      website: this.myPractice['URL'],

      streetName: this.adrPractice.streetName,
      streetNumber: this.adrPractice.streetNumber,
      streetType: this.adrPractice.streetType,
      unitNumber: this.adrPractice.unitNumber,
      suburb: this.adrPractice.suburb,
      state: this.adrPractice.state,
      postCode: this.adrPractice.postCode,
      latitude: this.adrPractice.latitude,
      longitude: this.adrPractice.longitude,

      thirdPartyBooking_URL: this.myPractice['ThirdPartyBooking_URL'],
    };

    this.authenticationService.editCurrentPractice(payload).subscribe((res) => {
      this.authenticationService.getCurrentUserForce().subscribe((res1) => {
        NotifyAppComponent.displayToast('success', 'Successed', 'Your profile has been updated successfully');
      });
    });
  }

  editProfile() {
    const payload = {
      firstName: this.myProfile['FirstName'],
      midleName: this.myProfile['MiddleName'],
      lastName: this.myProfile['Name'],
      email: this.emailProfile,
      workPhone: this.phoneProfile,
      mobile: this.mobileProfile,

      streetName: this.adrProfile.streetName,
      streetNumber: this.adrProfile.streetNumber,
      streetType: this.adrProfile.streetType,
      unitNumber: this.adrProfile.unitNumber,
      suburb: this.adrProfile.suburb,
      state: this.adrProfile.state,
      postCode: this.adrProfile.postCode,

      latitude: this.adrProfile.latitude,
      longitude: this.adrProfile.longitude,

      timeZoneCode: this.timeZoneCode || null,
      timeZoneValue: this.timeZoneValue || null,
    };

    this.SessionsService.editProfile(payload).subscribe((res) => {
      this.authenticationService.getCurrentUserForce().subscribe((res1) => {
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Profile has been successfully updated');

        const confirm = new ConfirmDialog(
          'fas fa-info',
          '',
          'The changes will be applied to your session on the next login , Do You to Logout now ?',
          'No',
          'Yes'
        );

        const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
          data: confirm,
        });
        ref.componentInstance.onConfirm.subscribe((confirmation) => {
          if (confirmation === false) {
            ref.close();
          } else {
            this.authenticationService.logout().subscribe(
              (res) => {
                ref.close();

                this.activeRoute.params.subscribe((params) => {
                  this.authenticationService.initCurrUser();
                  if (params['consumerID']) {
                    this.router.navigate(['/login', params['consumerID']]);
                    // window.location.href = '/login/' + params['consumerID'];
                  } else {
                    this.router.navigate(['/login']);
                    // window.location.href = "/login";
                  }
                });
              },
              (err: any) => {
                console.log(err);
              }
            );
          }
        });
      });
    });
  }

  getMobileProfile(m) {
    this.mobileProfile = m;
  }

  getPhoneProfile(p) {
    this.phoneProfile = p;
  }

  getEmailProfile(e) {
    this.emailProfile = e;
  }

  validateMobileProfileEvent(v) {
    this.validateMobileProfile = v;
  }

  validatePhoneProfileEvent(v) {
    this.validatePhoneProfile = v;
  }

  validateEmailProfileEvent(v) {
    this.validateEmailProfile = v;
  }

  getMobilePractice(m) {
    this.mobilePractice = m;
  }

  getPhonePractice(p) {
    this.phonePractice = p;
  }

  getEmailPractice(e) {
    this.emailPractice = e;
  }

  validateMobilePracticeEvent(v) {
    this.validateMobilePractice = v;
  }

  validatePhonePracticeEvent(v) {
    this.validatePhonePractice = v;
  }

  validateEmailPracticeEvent(v) {
    this.validateEmailPractice = v;
  }

  onCompleteAll(fileID) {
    if (fileID && fileID[0]) {
      const payload = {
        fileID: fileID[0],
      };
      this.SessionsService.uploadProfilePic(payload).subscribe((res) => {
        this.getProfilePicture();
        AuthenticationService.updateProfilePicture.emit(res);
      });
    }
  }

  onCompleteAllPractice(fileID) {
    if (fileID && fileID[0]) {
      const payload = {
        fileID: fileID[0],
      };
      this.SessionsService.uploadPracticePic(payload).subscribe((res) => {
        this.getPracticePicture();

        AuthenticationService.updatePracticePicture.emit(res);
      });
    }
  }

  onCompleteAllPracticeCover(fileID) {
    if (fileID && fileID[0]) {
      const payload = {
        fileID: fileID[0],
      };
      this.SessionsService.uploadPracticePicCover(payload).subscribe((res) => {
        this.getPracticePictureCover();

        AuthenticationService.updatePracticePictureCover.emit(res);
      });
    }
  }

  getAdressProfile(adr) {
    if (adr) {
      this.adrProfile = {
        suburb: adr.suburb,
        state: adr.state,
        postCode: adr.postCode,
        streetNumber: adr.streetNumber,
        streetName: adr.streetName,
        unitNumber: adr.unitNumber,
        streetType: adr.streetType,
        latitude: adr.latitude || null,
        longitude: adr.longitude || null,
      };
    }
  }

  getAdressPractice(adr) {
    if (adr) {
      this.adrPractice = {
        suburb: adr.suburb,
        state: adr.state,
        postCode: adr.postCode,
        streetNumber: adr.streetNumber,
        streetName: adr.streetName,
        unitNumber: adr.unitNumber,
        streetType: adr.streetType,
        latitude: adr.latitude || null,
        longitude: adr.longitude || null,
      };
    }
  }

  isAdressProfileValidEvent(v) {
    this.isAdressProfileValid = v || false;
  }

  isAdressPracticeValidEvent(v) {
    this.isAdressPracticeValid = v || false;
  }

  getTradingHours() {
    const payload = {};
    if (this.merchantID && this.isPromoterOrAdmin == true) {
      payload['merchantID'] = this.merchantID;
    }

    this.merchantService.getTradingHourList(payload, this.isPromoterOrAdmin).subscribe((res2) => {
      if (res2.length == 0 || res2.length < 7) {
        this.isCreateTradingTime = true;
      } else {
        this.merchantTimezone = res2[0]['TimeZone'];

        const order = { Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6, Sunday: 7 };

        this.tradingHours = res2.sort(function (a, b) {
          return order[a['DayOfWeek.Label.Singular']] - order[b['DayOfWeek.Label.Singular']];
        });
      }
    });
  }

  modifyTradingHours() {
    const ref = RootAppComponent.dialog.open(MerchantTradingHoursComponent, {
      data: {
        isSetup: this.isCreateTradingTime,
      },
      width: '800px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
        this.getTradingHours();
      }
    });
  }

  setupTradingHours() {
    const ref = RootAppComponent.dialog.open(MerchantTradingHoursOverviewComponent, {
      data: {
        merchantID: this.merchantID,
        isSetup: this.isCreateTradingTime,
      },
      width: '800px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
        this.getTradingHours();
      }
    });
  }

  convertTime(time) {
    const now = momentTZ();
    const localOffset = now.utcOffset();
    now.tz(this.merchantTimezone);
    const centralOffset = now.utcOffset();
    const diffInMinutes = localOffset - centralOffset;
    const newTime = momentTZ.utc(time, 'HH:mm').add(diffInMinutes, 'minutes').format('HH:mm');
    return newTime;
  }

  getProfilePicture() {
    this.profileLink = null;
    this.profileLink = this.authenticationService.getProfilePicStreamLink();
  }

  getPracticePicture() {
    this.practiceLink = null;
    this.practiceLink = this.authenticationService.getPracticePicStreamLink();
  }

  getPracticePictureCover() {
    this.practiceCoverLink = null;
    this.practiceCoverLink = this.authenticationService.getPracticePicCoverStreamLink();
  }

  getProfilePictureTimer() {
    this.profileLink = null;
    let countdown = 0;
    const interval = 1000;
    const duration = 3 * 1000;
    const stream$ = Observable.timer(0, interval)
      .finally(() => {
        this.profileLink = this.authenticationService.getProfilePicStreamLink();
      })
      .takeUntil(Observable.timer(duration + interval))
      .map((value) => duration - value * interval);
    stream$.subscribe((value) => (countdown = value));
  }

  getPracticePictureTimer() {
    this.practiceLink = null;
    let countdown = 0;
    const interval = 1000;
    const duration = 3 * 1000;
    const stream$ = Observable.timer(0, interval)
      .finally(() => {
        this.practiceLink = this.authenticationService.getPracticePicStreamLink();
      })
      .takeUntil(Observable.timer(duration + interval))
      .map((value) => duration - value * interval);
    stream$.subscribe((value) => (countdown = value));
  }

  getPracticeCoverTimer() {
    this.practiceCoverLink = null;
    let countdown = 0;
    const interval = 1000;
    const duration = 3 * 1000;
    const stream$ = Observable.timer(0, interval)
      .finally(() => {
        this.practiceCoverLink = this.authenticationService.getPracticePicCoverStreamLink();
      })
      .takeUntil(Observable.timer(duration + interval))
      .map((value) => duration - value * interval);
    stream$.subscribe((value) => (countdown = value));
  }
}
