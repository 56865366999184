<div class="illness card">
  <div class="card-header primary-gradient-img clearfix inModal">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="close()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <illness-allergy-icon
          class="pull-left"
          [illClass]="'illness'"
          [iconContent]="illnessObject?.typeCode"
          [numOfSteps]="1"
          [step]="1"
        ></illness-allergy-icon>
        <h2 class="summary-header rm-m" *ngIf="title">
          {{ title }}
        </h2>
        <h2 class="summary-header rm-m" *ngIf="!title">New Illness</h2>
      </div>
    </div>
  </div>

  <form #createForm="ngForm">
    <mat-dialog-content>
      <div class="row clearfix mt">
        <mat-form-field class="full-width mb" appearance="outline">
          <mat-label>Illness</mat-label>
          <mat-select
            class=""
            [(ngModel)]="illnessObject.typeCode"
            (change)="changeTitle()"
            name="category"
            placeholder="Please select Illness"
            required
          >
            <mat-option *ngFor="let c of categories" [value]="c.Code">{{ c.Label }}</mat-option>
          </mat-select>
        </mat-form-field>

        <div class="full-width clearfix" *ngIf="illnessObject.typeCode">
          <div class="row clearfix">
            <h3 class="sr-title rm-mt">Are you still suffering from this illness/condition?</h3>
            <mat-button-toggle-group
              class="divided-toggle animate full-width text-center"
              [(ngModel)]="illnessObject.isOngoing"
              name="stillSuffer"
            >
              <mat-button-toggle class="btn-icon thrd-width no" [value]="false" color="accent" ngDefaultControl>
                <mat-icon class="fas fa-thumbs-down"></mat-icon>
                No
              </mat-button-toggle>
              <mat-button-toggle class="btn-icon thrd-width yes" [value]="true" color="primary" ngDefaultControl>
                <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="full-width flex clearfix">
            <div class="row full-width clearfix" *ngIf="illnessObject.isOngoing != null" @ngIfAnimation>
              <app-date-month-input
                class="full-width"
                [defaultDays]="1"
                [defaultDate]="startDate"
                [startView]="'multi-year'"
                [isTime]="false"
                [maxDate]="'toDay'"
                [isClear]="true"
                (selectedDate)="selectDateToFilter($event, 'firstOccurDate')"
                label="When did it first start?"
              >
              </app-date-month-input>
            </div>
            <div class="row ml full-width clearfix" *ngIf="illnessObject.isOngoing == false" @ngIfAnimation>
              <app-date-month-input
                class="full-width"
                [defaultDays]="'last'"
                [defaultDate]="stopDate"
                [startView]="'multi-year'"
                [isTime]="false"
                [minDate]="illnessObject?.firstOccurDate"
                [maxDate]="'toDay'"
                [isClear]="true"
                (selectedDate)="selectDateToFilter($event, 'ceaseDate')"
                label="When did it finish?"
              >
              </app-date-month-input>
            </div>
          </div>

          <div class="full-width flex" *ngIf="illnessObject?.firstOccurDate">
            <h3 class="sr-title rm-mt animate full-width text-left">
              Occuring duration:

              <span *ngIf="illnessObject.isOngoing === false" [style.color]="'black'">
                {{ illnessObject?.firstOccurDate | timeLeft: illnessObject?.ceaseDate:'years,months':false }}
              </span>

              <span *ngIf="illnessObject.isOngoing !== false" [style.color]="'black'">
                {{ illnessObject?.firstOccurDate | timeLeft: null:'years,months':false }}
              </span>
            </h3>
          </div>

          <div class="row clearfix" *ngIf="illnessObject.isOngoing != null" @ngIfAnimation>
            <h3 class="sr-title rm-mt animate">More details (optional)</h3>
            <div class="row clearfix animate">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Would you like to tell us anything else you feel may be relevant?</mat-label>
                <textarea
                  class="mat-block"
                  [(ngModel)]="illnessObject.description"
                  matInput
                  placeholder="Would you like to tell us anything else you feel may be relevant?"
                  name="description"
                ></textarea>
              </mat-form-field>
            </div>
          </div>

          <div class="full-width clearfix" [hidden]="medicationExist != true && illnessObject?.isOngoing != true">
            <app-medication-list
              *ngIf="isLoaded == true"
              [medications]="_medications"
              [title]="'Are you taking any medication for it?'"
              [canCreate]="true"
              [canEdit]="true"
              [illnessID]="illnessID"
              [illnessCode]="illnessObject?.typeCode"
              [startDate]="illnessObject?.firstOccurDate"
              [stopDate]="illnessObject?.ceaseDate"
              [showStopDate]="!illnessObject?.isOngoing"
              [canAction]="false"
              [canYesNo]="true"
              (getNewMedications)="getNewMedications($event)"
              (getMedicationExist)="getMedicationExist($event)"
            >
            </app-medication-list>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <div class="row clearfix">
      <hr/>
      <button class="" (click)="close()" mat-raised-button color="primary">
        <mat-icon>block</mat-icon>
        Cancel
      </button>
      <button
        class="pull-right"
        *ngIf="isEdit == false"
        [disabled]="!createForm.form.valid"
        (click)="createIllness()"
        mat-raised-button
        color="accent"
      >
        Save
        <mat-icon>check</mat-icon>
      </button>
      <button
        class="pull-right"
        *ngIf="isEdit == true"
        [disabled]="!createForm.form.valid"
        (click)="modifyIllness()"
        mat-raised-button
        color="accent"
      >
        Update
        <mat-icon>check</mat-icon>
      </button>
    </div>
  </form>
</div>
