<div class="clearfix" *ngIf="isAdminOrPromoter == true">
  <!--
  <app-operator-filter [selectDateRange]="selectedRange" [displayMerchant]="false" [displayDateRange]="true"
    [displayDateFrom]="false" [displayTimePicker]="false" [displayDateTo]="false" [displayDentist]="false"
    (search)="applySearch($event)" [autoSelectMerchant]="false" id="part1" class="dash">
  </app-operator-filter> -->

  <div class="row clearfix">
    <div class="reduce-padding mb clearfix mat-elevation-z1 full-width white-background">
      <div class="toggle-card row clearfix full-width mb">
        <mat-button-toggle-group class="btn-group row clearfix flex full-width text-center" [(ngModel)]="searchType">
          <mat-button-toggle class="text-center full-width" [value]="'Promoter'"> Promoter</mat-button-toggle>

          <mat-button-toggle class="text-center full-width" [value]="'Patient'">
            {{ "KEYWORD.patient" | translate | titlecase }}
          </mat-button-toggle>

          <mat-button-toggle class="text-center full-width" [value]="'Merchant'"> Merchant</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div class="row clearfix full-width tabArea mt white-background">
        <drug-list
          *ngIf="searchType == 'Promoter'"
          [dateFrom]="dateFrom"
          [source]="'Promoter'"
          [isActive]="true"
          [dateTo]="dateTo"
          [pageSize]="pageSize"
        >
        </drug-list>

        <drug-list
          *ngIf="searchType == 'Merchant'"
          [dateFrom]="dateFrom"
          [source]="'Merchant'"
          [title]="'Drugs added by Merchant'"
          [isActive]="null"
          [dateTo]="dateTo"
          [pageSize]="pageSize"
        >
        </drug-list>

        <drug-list
          *ngIf="searchType == 'Patient'"
          [dateFrom]="dateFrom"
          [source]="'Patient'"
          [title]="'Drugs added by ' + ('KEYWORD.patient' | translate | titlecase)"
          [isActive]="null"
          [dateTo]="dateTo"
          [pageSize]="pageSize"
        >
        </drug-list>
      </div>
    </div>
  </div>

  <div class="row clearfix">
    <div class="pleaseChoose" *ngIf="merchantID == 'none'" @ngIfAnimation>
      <p>
        <strong>No merchant selected</strong>
      </p>
      <img class="character_warning" />
      <p>
        Choose your desired merchant from the drop-down above to view their
        {{ "KEYWORD.patient" | translate }}s
      </p>
    </div>
  </div>
</div>

<div class="clearfix" *ngIf="isAdminOrPromoter == false">
  <!--
  <app-operator-filter [selectDateRange]="selectedRange" [displayMerchant]="false" [displayDateRange]="true"
    [displayDateFrom]="false" [displayTimePicker]="false" [displayDateTo]="false" [displayDentist]="false"
    (search)="applySearch($event)" [autoSelectMerchant]="false" id="part1" class="dash">
  </app-operator-filter> -->

  <div class="row clearfix">
    <drug-list [dateFrom]="dateFrom" [source]="'Promoter'" [isActive]="true" [dateTo]="dateTo" [pageSize]="pageSize">
    </drug-list>
  </div>
</div>
