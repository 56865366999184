import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'admin-left-menu',
  templateUrl: './admin-left-menu.component.html',
  styleUrls: ['./admin-left-menu.component.css'],
})
export class AdminLeftMenuComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
