<div class="rel dash intro" *ngIf="loaded == true">
  <div class="full-width text-center">
    <div class="image-thumbnail-sub-plan-congratulation">

      <img  *ngIf="settings && settings['context']!='breeze' && settings['context']!='p4p'"
        src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/character/dentist-thumbs-up.png" />


        <img  *ngIf="settings && settings['context']=='breeze'"
        src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/idea-2-2.png" />

        <img  *ngIf="settings  && settings['context']=='p4p'"
        src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/information2-square.png" />

    </div>
  </div>

  <div class="full-width flex container-modal">
    <div class="full-width">
      <div class="context full-width text-center">
        <h1>Congratulation!</h1>
        <div class="context-description">
          <p class="text-center" *ngIf="isExternal != true">
            You have successfully subscribed to the <strong>{{ label }}</strong> Plan!<br/>
            Click Proceed to start exploring what the platform has to offer.
          </p>

          <p class="text-center" *ngIf="isExternal == true">
            Your subscription plan has been updated !<br/>
            Click on <strong> Check it out</strong> to find out more about the new features.
          </p>
        </div>
      </div>

      <div
        class="area"
        [attr.style]="
          'background:linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
        "
        [attr.style]="
          'background:-moz-linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
        "
        [attr.style]="
          'background:-webkit-linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important'
            | safeStyle
        "
      >
        <ul class="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </div>
  </div>

  <div class="full-width text-center" style="margin-top: -80px">
    <button
      class="btn-large close-button pull-left"
      *ngIf="isExternal == true"
      (click)="closeEvent()"
      mat-raised-button
      color="primary"
    >
      Close
    </button>

    <button
      class="btn-large close-button-activate pull-right"
      *ngIf="isExternal == true"
      (click)="checkPlan()"
      mat-raised-button
      color="primary"
    >
      CHECK IT OUT <mat-icon class="fas fa-crown sml smr"></mat-icon>
    </button>

    <button
      class="btn-large close-button-activate pull-right"
      *ngIf="isExternal != true"
      (click)="closeEvent()"
      mat-raised-button
      color="primary"
    >
      PROCEED <mat-icon class="fas fa-crown sml smr"></mat-icon>
    </button>
  </div>
</div>
