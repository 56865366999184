import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, merge as observableMerge, Observable, Subject } from 'rxjs';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { DentistViewModalComponent } from '../../dentist/dentist-view-modal/dentist-view-modal.component';
import { InvitationViewModalComponent } from '../../invitation/invitation-view-modal/invitation-view-modal.component';
import { MerchantViewModalComponent } from '../../merchant/merchant-view-modal/merchant-view-modal.component';
import { AppointmentCreateComponent } from '../appointment-create/appointment-create.component';
import { AppointmentViewComponent } from '../appointment-view/appointment-view.component';
import { AppointmentService } from '../shared/appointment.service';
import { AppointmentUtilClass } from '../shared/appointmentUtil';

@Component({
  selector: 'app-appointment-list',
  templateUrl: './appointment-list.component.html',
  styleUrls: ['./appointment-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('10ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-10%)', offset: 0 }),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('10ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                style({ opacity: 0, transform: 'translateY(-10%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class AppointmentListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() merchantID;
  @Input() patientID;
  @Input() invitationID;
  @Input() dentistID;
  @Input() status;
  @Input() statusCodes;
  @Input() source;
  @Input() dateFrom;
  @Input() dateTo;
  @Input() dateBookingFrom;
  @Input() dateBookingTo;
  @Input() statusGroup;
  @Input() title = 'Appointments';

  @Input() appointmentID;

  rows = [];
  displayedColumns = [
    'selectCheckBox',
    'dateTimeCreated',
    'profilePicture',
    'customerName',
    // "customerEmail",
    'customerMobile',
    'source',

    'appointmentDate',
    'appointmentType',
    'merchantName',
    'dentistName',
    'statusLabelApp',
    'dateTimeUpdated',

    'Actions',
  ];
  selectedIDs = [];
  isPromoterOrAdmin = false;
  sessionType;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;
  public listDB: LoadRecords | null;
  dataSource: ListDataSource | null;
  @ViewChild('input', { static: false }) input;
  priority = new Subject<any>();
  currentUser;
  destroyEvent = new EventEmitter();
  util = new UtilsClass();
  runAction = new EventEmitter();
  appointmentUtil = new AppointmentUtilClass();
  settings = Settings.global;

  constructor(
    private appointmentService: AppointmentService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      this.setup();
    });
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });

    const payloadRecord = {
      merchantID: this.merchantID,
      patientID: this.patientID,
      invitationID: this.invitationID,
      dentistID: this.dentistID,
      status: this.status,
      statusCodes: this.statusCodes,
      source: this.source,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      dateBookingFrom: this.dateBookingFrom,
      dateBookingTo: this.dateBookingTo,
      orderBy: '-DateTimeCreated',
      section: 0,
      fields:
        'Active,Campaign_Key,CustomerOrProspect_CalculatedName,CustomerOrProspect_Email,CustomerOrProspect_Mobile,' +
        'CustomerOrProspect_Key,Date,DateTimeCreated,ID,Invitation_key,MerchantContact_CalculatedName,Merchant_CalculatedName,' +
        'Merchant_key,Source.Label,Status.Code,Status.Label,Time,Date_Requested,Date_Booked,Type.Label,SubType.Label,MerchantContact_key,Merchant_key,' +
        'ConfirmationBySMS,ReminderBySMS,Type.DefaultDuration,SubType.DefaultDuration,Date_End,Time_End,LastModified_Human,LastModified,Merchant_SMS_ConfirmAppointment,Merchant_SMS_AppointmentReminder',
    };

    this.listDB = new LoadRecords(this.appointmentService, this.destroyEvent, payloadRecord, this.sessionType);
    this.dataSource = new ListDataSource(this.listDB, this.paginator, this.sort);
  }

  ngOnChanges() {
    this.setup();

    const payloadRecord = {
      merchantID: this.merchantID,
      patientID: this.patientID,
      invitationID: this.invitationID,
      dentistID: this.dentistID,
      status: this.status,
      statusCodes: this.statusCodes,
      source: this.source,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      dateBookingFrom: this.dateBookingFrom,
      dateBookingTo: this.dateBookingTo,
      orderBy: '-DateTimeCreated',
      section: 0,
      fields:
        'Active,Campaign_Key,CustomerOrProspect_CalculatedName,CustomerOrProspect_Email,CustomerOrProspect_Mobile,' +
        'CustomerOrProspect_Key,Date,DateTimeCreated,ID,Invitation_key,MerchantContact_CalculatedName,Merchant_CalculatedName,' +
        'Merchant_key,Source.Label,Status.Code,Status.Label,Time,Date_Requested,Date_Booked,Type.Label,SubType.Label,MerchantContact_key,Merchant_key,' +
        'ConfirmationBySMS,ReminderBySMS,Type.DefaultDuration,SubType.DefaultDuration,Date_End,Time_End,LastModified_Human,LastModified,Merchant_SMS_ConfirmAppointment,Merchant_SMS_AppointmentReminder',
    };
    this.listDB = new LoadRecords(this.appointmentService, this.destroyEvent, payloadRecord, this.sessionType);
    this.dataSource = new ListDataSource(this.listDB, this.paginator, this.sort);
  }

  setup() {
    if (!this.sessionType) {
      return;
    }

    if (this.appointmentID) {
      this.openQuickViewDialog(this.appointmentID);
    }

    if (this.sessionType == 'promoter' || this.sessionType == 'admin') {
      if (this.patientID) {
        this.displayedColumns = [
          'selectCheckBox',
          'dateTimeCreated',
          'profilePicture',
          'customerName',
          'appointmentType',
          'source',
          'merchantName',

          'appointmentDate',
          'dentistName',
          'statusLabelApp',
          'dateTimeUpdated',

          'Actions',
        ];
      } else if (this.merchantID) {
        this.displayedColumns = [
          'selectCheckBox',
          'dateTimeCreated',
          'profilePicture',
          'customerName',
          // "customerEmail",
          'customerMobile',
          'appointmentType',
          'source',

          'appointmentDate',
          'dentistName',
          'statusLabelApp',
          'dateTimeUpdated',

          'Actions',
        ];
      } else {
        this.displayedColumns = [
          'selectCheckBox',
          'dateTimeCreated',
          'profilePicture',
          'customerName',
          // "customerEmail",
          'customerMobile',
          'merchantName',
          'appointmentType',
          'source',

          'appointmentDate',
          'dentistName',
          'statusLabelApp',
          'dateTimeUpdated',

          'Actions',
        ];
      }
    } else if (this.sessionType == 'merchant' || this.sessionType == 'merchant-admin') {
      this.authenticationService.getCurrentUser().subscribe((res) => (this.merchantID = res.data.merchantKey));
      if (this.patientID) {
        this.displayedColumns = [
          'selectCheckBox',
          'dateTimeCreated',
          'profilePicture',
          'customerName',
          'dentistName',
          'source',

          'appointmentDate',
          'appointmentType',
          'statusLabelApp',
          'dateTimeUpdated',

          'Actions',
        ];
      } else if (this.dentistID) {
        this.displayedColumns = [
          'selectCheckBox',
          'dateTimeCreated',
          'profilePicture',
          'customerName',
          // "customerEmail",
          'customerMobile',
          'source',

          'appointmentDate',
          'appointmentType',
          'dentistName',
          'statusLabelApp',
          'dateTimeUpdated',

          'Actions',
        ];
      } else {
        this.displayedColumns = [
          'selectCheckBox',
          'dateTimeCreated',
          'profilePicture',
          'customerName',
          // "customerEmail",
          'customerMobile',
          'source',

          'appointmentDate',
          'appointmentType',
          'dentistName',
          'statusLabelApp',
          'dateTimeUpdated',

          'Actions',
        ];
      }
    } else if (this.sessionType == 'customer' || this.sessionType == 'consumer') {
      this.authenticationService.getCurrentUser().subscribe((res) => (this.merchantID = res.data.merchantKey));
      this.displayedColumns = [
        'selectCheckBox',
        'dateTimeCreated',
        'dentistName',
        'appointmentType',
        'source',

        'appointmentDate',
        'statusLabelApp',
        'dateTimeUpdated',

        'Actions',
      ];
    }

    if (this.status) {
      if (this.status === 'REQUESTED') {
        this.title = 'Requested appointments';

        let index = this.displayedColumns.indexOf('appointmentDate');
        if (index != -1) {
          this.displayedColumns.splice(index, 1);
        }
        index = this.displayedColumns.indexOf('dentistName');
        if (index != -1) {
          this.displayedColumns.splice(index, 1);
        }
      } else if (this.status === 'BOOKED') {
        this.title = 'Booked appointments';
      } else if (this.status === 'RESCHEDULED') {
        this.title = 'Rescheduled appointments';
      } else if (this.status === 'CANCELED') {
        this.title = 'Canceled appointments';
      } else if (this.status === 'ATTENDED') {
        this.title = 'Attended appointments';
      } else if (this.status === 'MISSED') {
        this.title = 'Missed appointments';
      } else if (this.status === 'REQUESTRESCHEDULE') {
        this.title = 'Rescheduling Requested appointments';
      } else if (this.status === 'WAITINGCONFIRMATION') {
        this.title = 'Wating for confirmation appointments';
      } else if (this.status === 'REQUESTDATECHANGE') {
        this.title = 'Change date requested appointments';
      }
    } else if (this.statusGroup) {
      if (this.statusGroup == 'pending') {
        this.statusCodes = 'REQUESTED,REQUESTRESCHEDULE,REQUESTDATECHANGE';
        this.title = 'Requested appointments';

        let index = this.displayedColumns.indexOf('appointmentDate');
        if (index != -1) {
          this.displayedColumns.splice(index, 1);
        }

        index = this.displayedColumns.indexOf('dentistName');
        if (index != -1) {
          this.displayedColumns.splice(index, 1);
        }
      } else if (this.statusGroup == 'waitingForConfirmation') {
        this.statusCodes = 'WAITINGCONFIRMATION';
        this.title = 'Waiting for confirmation';
      } else if (this.statusGroup == 'booked') {
        this.statusCodes = 'BOOKED,RESCHEDULED';
        this.title = 'Booked appointments';
      } else if (this.statusGroup == 'attended') {
        this.statusCodes = 'ATTENDED';
        this.title = 'Attended appointments';
      } else if (this.statusGroup == 'missed') {
        this.statusCodes = 'MISSED';
        this.title = 'Missed appointments';
      } else if (this.statusGroup == 'canceled') {
        this.statusCodes = 'CANCELED';
        this.title = 'Canceled appointments';
      } else if (this.statusGroup == 'all') {
        this.statusCodes = null;
        this.title = 'Appointments';
      }
    }
  }

  setFilter(event, field) {
    let filter;
    if (event !== null && event != undefined) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
  }

  newAppointmentNavigate() {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['appointment-create-merchant'],
        },
      },
    ]);
  }

  isSelected(id) {
    if (!this.selectedIDs || this.selectedIDs.length <= 0) {
      return false;
    } else {
      if (this.selectedIDs.indexOf(id) != -1) {
        return true;
      }
    }

    return false;
  }

  select(id, isSelected) {
    if (isSelected == true && this.selectedIDs.indexOf(id) == -1) {
      this.selectedIDs.push(id);
    } else {
      const _index = this.selectedIDs.indexOf(id);
      this.selectedIDs.splice(_index, 1);
    }
  }

  selectAll() {
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      const o = this.dataSource.filteredData[i];

      if (o && o['ID'] && this.selectedIDs.indexOf(o['ID']) == -1) {
        this.selectedIDs.push(o['ID']);
      }
    }
  }

  selectPage() {
    const index = this.dataSource['_paginator']['index'] || 0;
    const pageSize = this.dataSource['_paginator']['pageSize'] || 0;

    for (let i = 0; i < (index + 1) * pageSize; i++) {
      const o = this.dataSource.filteredData[index * pageSize + i];

      if (o && o['ID'] && this.selectedIDs.indexOf(o['ID']) == -1) {
        this.selectedIDs.push(o['ID']);
      }
    }
  }

  unselect() {
    this.selectedIDs = [];
  }

  requestAppointment() {
    const ref = RootAppComponent.dialog.open(AppointmentCreateComponent, {
      data: {
        customerID: this.patientID,
        patientID: this.patientID,
        invitationID: this.invitationID,
      },
      width: '800px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  publicPage(key) {
    if (key && this.settings && this.settings['consumerFrontendLink']) {
      window.open(this.settings['consumerFrontendLink'] + '/consumer/(page:appointment-card/' + key + ')', '_blank');
    }
  }

  openQuickViewDialog(key) {
    const ref = RootAppComponent.dialog.open(AppointmentViewComponent, {
      data: {
        appointmentID: key,
        closeOnProposeBooking: true,
      },
      width: '900px',
      height: '90%',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });

    ref.componentInstance.proposeBooking.subscribe((res) => {
      if (res) {
        ref.close();
        ref.afterClosed().subscribe((r) => {
          this.runAction.emit(res);
        });
      }
    });

    ref.componentInstance.updateAppointment.subscribe((res) => {
      if (res) {
        this.dataSource.replaceItem = res;
      }
    });

    ref.componentInstance.deleteAppointment.subscribe((res) => {
      if (res) {
        this.dataSource.removeItem = res;
      }
    });
  }

  openInvitationViewDialog(key) {
    const ref = RootAppComponent.dialog.open(InvitationViewModalComponent, {
      data: { invitationID: key },
      width: '900px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  openMerchantViewDialog(key) {
    const ref = RootAppComponent.dialog.open(MerchantViewModalComponent, {
      data: key,
      width: '550px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  openDentistViewDialog(key) {
    const ref = RootAppComponent.dialog.open(DentistViewModalComponent, {
      data: key,
      width: '550px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  gotoCustomerPage(key) {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['customer-profile', key, 'patient'],
        },
      },
    ]);
  }

  gotoCampaignPage(key) {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['marketing-campaign-view', key],
        },
      },
    ]);
  }

  removeAppointment(item) {
    const isGroup = Array.isArray(item);
    const id = isGroup ? item : item.ID;
    const confirmDialogParams = {
      data: new ConfirmDialog(
        'fas fa-info',
        'Are you sure?',
        '<p>Once the appointment is removed it will not appear on patient appointment list</p>',
        'No',
        'Yes'
      ),
    };
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, confirmDialogParams);
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        this.appointmentService.removeAppointment(id, this.sessionType).subscribe((res) => {
          if (res) {
            if (isGroup) {
              if (res.length > 0) {
                for (const item of res) {
                  this.dataSource.removeItem = item;
                }
                NotifyAppComponent.displayToast('success', 'Remove appointments', 'Appointments are now removed');
              } else {
                NotifyAppComponent.displayToast(
                  'warning',
                  'Remove appointments',
                  'None of the selected appointments can be removed'
                );
              }
            } else {
              this.dataSource.removeItem = res;
              NotifyAppComponent.displayToast('success', 'Remove appointment', 'Appointment is now removed');
            }
            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }

  runActionEvent(item, operation, isGroup = false) {
    if (item) {
      const p = {
        item,
        operation,
        isGroup,
      };
      this.runAction.emit(p);
    }
  }

  getActionResult(r) {
    if (r && Array.isArray(r) && r.length > 0) {
      for (const item of r) {
        this.dataSource.replaceItem = item;
      }

      this.unselect();
    } else if (r && r.ID) {
      this.dataSource.replaceItem = r;
    }
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public records: any[];
  public count: any;
  maxSection = 20;
  sessionType;
  serviceRef;

  payload;

  get data(): any[] {
    return this.dataChange.value;
  }

  get firstLoadEvent() {
    return this.firstLoad;
  }

  firstLoad = false;
  isDestoyed = false;

  constructor(private appointmentService: AppointmentService, private destroyEvent, payload, sessionType) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    this.payload = payload;
    this.sessionType = sessionType;

    this.serviceRef = this.appointmentService.getAppointmentList(payload, sessionType).subscribe((res) => {
      if (res.length > 0) {
        for (let i = 0; i < res.length; i++) {
          if (res[i] && !res[i]['MerchantContact_CalculatedName']) {
            res[i]['MerchantContact_CalculatedName'] = 'Unassigned';
          }

          if (res[i] && !res[i]['Status.Label']) {
            res[i]['Status.Label'] = 'Unknown';
          }
        }
      }
      this.count = res.length;
      this.records = res;
      this.dataChange.next(this.records.slice());
      this.firstLoad = true;
      const innerFunction = (section) => {
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.appointmentService.getAppointmentList(payload, sessionType).subscribe((res) => {
          if (res.length > 0) {
            for (let i = 0; i < res.length; i++) {
              if (res[i] && !res[i]['MerchantContact_CalculatedName']) {
                res[i]['MerchantContact_CalculatedName'] = 'Unassigned';
              }

              if (res[i] && !res[i]['Status.Label']) {
                res[i]['Status.Label'] = 'Unknown';
              }
            }

            this.count = res.count;
            this.records = this.records.concat(res);
            this.dataChange.next(this.records.slice());
            if (this.isDestoyed != true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };

      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class ListDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  set replaceItem(item) {
    const _index = this._listDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    this._listDatabase.data[_index] = item;
    this._listDatabase.dataChange.next(this._listDatabase.data);
  }

  set removeItem(id) {
    const data = this._listDatabase.data.filter((row) => row.ID != id);
    this._listDatabase.dataChange.next(data);
  }

  filteredData: any[] = [];
  utils = new UtilsClass();

  constructor(private _listDatabase: any, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._listDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._listDatabase.data.slice().filter((item: any) => {
          if (Array.isArray(this.field)) {
            let res = false;
            this.field.forEach((col) => {
              let f = '';
              f = item[col] || '';

              const searchStr = f.toLowerCase();
              res = res || searchStr.indexOf(this.filter.toLowerCase()) != -1;
            });
            return res;
          } else if (this.field === 'fromDate') {
            const itemVal = moment(item.Date_Requested).toDate();
            const filterVal = moment(this.filter).toDate();
            return this.filter === '' || filterVal <= itemVal;
          } else if (this.field === 'toDate') {
            const itemVal = moment(item.Date_Requested).toDate();
            const filterVal = moment(this.filter).toDate();
            return this.filter === '' || filterVal > itemVal;
          } else if (this.field === 'fromBookingDate') {
            const itemVal = moment(item.Date_Booked).toDate();
            const filterVal = moment(this.filter).toDate();
            return this.filter === '' || filterVal <= itemVal;
          } else if (this.field === 'toBookingDate') {
            const itemVal = moment(item.Date_Booked).toDate();
            const filterVal = moment(this.filter).toDate();
            return this.filter === '' || filterVal > itemVal;
          } else {
            let f = '';
            f = item[this.field] || '';

            const searchStr = f.toLowerCase();
            return searchStr.indexOf(this.filter.toLowerCase()) != -1;
          }
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'customerName':
          [propertyA, propertyB] = [a['CustomerOrProspect_CalculatedName'], b['CustomerOrProspect_CalculatedName']];
          break;
        case 'source':
          [propertyA, propertyB] = [a['Source.Label'], b['Source.Label']];
          break;
        case 'merchantName':
          [propertyA, propertyB] = [a['Merchant_CalculatedName'], b['Merchant_CalculatedName']];
          break;
        case 'statusLabelApp':
          [propertyA, propertyB] = [a['Status.Label'], b['Status.Label']];
          break;
        case 'appointmentDate':
          [propertyA, propertyB] = [
            moment(`${a['Date']} ${a['Time']}`).toDate().getTime(),
            moment(`${b['Date']} ${b['Time']}`).toDate().getTime(),
          ];
          break;
        case 'dateTimeCreated':
          [propertyA, propertyB] = [
            moment(a['DateTimeCreated']).toDate().getTime(),
            moment(b['DateTimeCreated']).toDate().getTime(),
          ];
          break;

        case 'dateTimeUpdated':
          [propertyA, propertyB] = [
            moment(a['LastModified_Human']).toDate().getTime(),
            moment(b['LastModified_Human']).toDate().getTime(),
          ];
          break;

        case 'active':
          [propertyA, propertyB] = [a['Active'], b['Active']];
          break;
      }

      const valueA = isNaN(+propertyA) || propertyA === '' ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) || propertyB === '' ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
  }
}
