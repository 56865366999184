/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dental-view-customer-product.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./dental-view-customer-product.component";
var styles_DentalViewCustomerProductComponent = [i0.styles];
var RenderType_DentalViewCustomerProductComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DentalViewCustomerProductComponent, data: {} });
export { RenderType_DentalViewCustomerProductComponent as RenderType_DentalViewCustomerProductComponent };
export function View_DentalViewCustomerProductComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["dental-view-product works!"]))], null, null); }
export function View_DentalViewCustomerProductComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-dental-view-customer-product", [], null, null, null, View_DentalViewCustomerProductComponent_0, RenderType_DentalViewCustomerProductComponent)), i1.ɵdid(1, 49152, null, 0, i2.DentalViewCustomerProductComponent, [], null, null)], null, null); }
var DentalViewCustomerProductComponentNgFactory = i1.ɵccf("ipv-dental-view-customer-product", i2.DentalViewCustomerProductComponent, View_DentalViewCustomerProductComponent_Host_0, { productCustomer: "productCustomer" }, {}, []);
export { DentalViewCustomerProductComponentNgFactory as DentalViewCustomerProductComponentNgFactory };
