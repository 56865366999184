import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material';
import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '../../shared/shared.module';
import { OperatorModule } from '../operator/operator.module';
import { InstantOfferCreateComponent } from './instant-offer-create/instant-offer-create.component';
import { InstantOfferListOverviewComponent } from './instant-offer-list-overview/instant-offer-list-overview.component';
import { InstantOfferListComponent } from './instant-offer-list/instant-offer-list.component';
import { InstantOfferViewComponent } from './instant-offer-view/instant-offer-view.component';
import { InstantFinanceOfferRoutingModule } from './shared/instant-finance-offer-routing.module';

import { AuthenticationModule } from '../../core/authentication/authentication.module';
import { CustomerProspectModule } from '../customer-prospect/customerProspect.module';
import { DentistService } from '../dentist/shared/dentist.service';
import { DocumentService } from '../document/shared/document.service';
import { MerchantService } from '../merchant/shared/merchant.service';
import { MessageModule } from '../message/message.module';
import { ProductService } from '../product/shared/product.service';
import { InstantOfferExternalLinkViewComponent } from './instant-offer-external-link-view/instant-offer-external-link-view.component';
import { InstantOfferExternalLinkComponent } from './instant-offer-external-link/instant-offer-external-link.component';
import { InstantOfferHeaderImageComponent } from './instant-offer-header-image/instant-offer-header-image.component';
import { InstantOfferSettingsOverviewComponent } from './instant-offer-settings-overview/instant-offer-settings-overview.component';
import { InstantOfferSettingsComponent } from './instant-offer-settings/instant-offer-settings.component';
import { InstantOfferService } from './shared/instant-finance-offer.service';

@NgModule({
  imports: [
    NgPipesModule,
    CommonModule,
    FormsModule,
    CdkTableModule,
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    MatSortModule,
    OperatorModule,
    CustomerProspectModule,
    InstantFinanceOfferRoutingModule,
    AuthenticationModule,
    MessageModule,
  ],
  declarations: [
    InstantOfferCreateComponent,
    InstantOfferListComponent,
    InstantOfferViewComponent,
    InstantOfferListOverviewComponent,
    InstantOfferHeaderImageComponent,
    InstantOfferSettingsComponent,
    InstantOfferExternalLinkComponent,
    InstantOfferExternalLinkViewComponent,
    InstantOfferSettingsOverviewComponent,
  ],
  exports: [
    InstantOfferCreateComponent,
    InstantOfferListComponent,
    InstantOfferViewComponent,
    InstantOfferListOverviewComponent,
    InstantOfferHeaderImageComponent,
    InstantOfferSettingsComponent,
    InstantOfferExternalLinkComponent,
    InstantOfferExternalLinkViewComponent,
    InstantOfferSettingsOverviewComponent,
  ],
  providers: [InstantOfferService, ProductService, DentistService, MerchantService, DocumentService],
})
export class InstantFinanceOfferModule {}
