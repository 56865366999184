import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material';
import { ArchwizardModule } from 'angular-archwizard';
import { NgDatepickerModule } from 'ng2-datepicker';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgPipesModule } from 'ngx-pipes';
import { AuthenticationModule } from '../../core/authentication/authentication.module';
import { HelperModule } from '../../core/helper/helper.module';
import { SessionModule } from '../../core/session/session.module';
import { SharedModule } from '../../shared/shared.module';
import { ActionLogModule } from '../action-log/action-log.module';
import { ActivityLogModule } from '../activity-log/activity-log.module';
import { AppointmentModule } from '../appointment/appointment.module';
import { BankAccountModule } from '../bank-account/bank-account.module';
import { ContactModule } from '../contact/contact.module';
import { ContractModule } from '../contract/contract.module';
import { CustomerProspectModule } from '../customer-prospect/customerProspect.module';
import { CustomerModule } from '../customer/customer.module';
import { DefaultMessageModule } from '../default-message/default-message.module';
import { DentistModule } from '../dentist/dentist.module';
import { DocumentModule } from '../document/document.module';
import { InvitationTemplateModule } from '../invitation-template/invitationTemplate.module';
import { InvitationModule } from '../invitation/invitation.module';
import { MarketingModule } from '../marketing/marketing.module';
import { MedicalHistoryModule } from '../medical-history/medical-history.module';
import { MerchantProspectModule } from '../merchant-prospect/merchant-prospect.module';
import { MerchantModule } from '../merchant/merchant.module';
import { MessageModule } from '../message/message.module';
import { NotesModule } from '../notes/notes.module';
import { QuotationModule } from '../quotation/quotation.module';
import { SettlementModule } from '../settlement/settlement.module';
import { TagsModule } from '../tags/tags.module';
import { AddressEditModalComponent } from './address-edit-modal/address-edit-modal.component';
import { ChangePrimaryModalComponent } from './change-primary-modal/change-primary-modal.component';
import { ContactDetailsEditModalComponent } from './contact-details-edit-modal/contact-details-edit-modal.component';
import { CustomerDetailsCardEditComponent } from './customer-details-card-edit/customer-details-card-edit.component';
import { CustomerProfileTabsComponent } from './customer-profile-tabs/customer-profile-tabs.component';
import { CustomerProspectDetailsComponent } from './customer-prospect-details/customer-prospect-details.component';
import { CustomerProspectProfileTabsComponent } from './customer-prospect-profile-tabs/customer-prospect-profile-tabs.component';
import { CustomerProspectProfileComponent } from './customer-prospect-profile/customer-prospect-profile.component';
import { DentistProfileComponent } from './dentist-profile/dentist-profile.component';
import { DetailsCardContactsListComponent } from './details-card-contacts-list/details-card-contacts-list.component';
import { DetailsCardLendingComponent } from './details-card-lending/details-card-lending.component';
import { DetailsCardProfileComponent } from './details-card-profile/details-card-profile.component';
import { DetailsCardVerificationComponent } from './details-card-verification/details-card-verification.component';
import { DetailsCardViewComponent } from './details-card-view/details-card-view.component';
import { InvitationSettingsComponent } from './invitation-settings/invitation-settings.component';
import { MarketingSettingsComponent } from './marketing-settings/marketing-settings.component';
import { MerchantProfileComponent } from './merchant-profile/merchant-profile.component';
import { MerchantProspectProfileComponent } from './merchant-prospect-profile/merchant-prospect-profile.component';
import { PatientInvitationListComponent } from './patient-invitation-list/patient-invitation-list.component';
import { ProfileSessionEditComponent } from './profile-session-edit/profile-session-edit.component';
import { ProfileSessionOverViewComponent } from './profile-session-over-view/profile-session-over-view.component';
import { ProspectDetailsCardEditComponent } from './prospect-details-card-edit/prospect-details-card-edit.component';
import { ProspectProfileTabsComponent } from './prospect-profile-tabs/prospect-profile-tabs.component';
import { ProfilsRoutingModule } from './shared/profils-routing.module';
import { MarketingFilterModule } from '../marketing-filter/marketing-filter.module';

@NgModule({
  declarations: [
    CustomerProspectProfileComponent,
    CustomerProspectProfileTabsComponent,
    CustomerProfileTabsComponent,
    ProspectProfileTabsComponent,
    ProfileSessionEditComponent,
    ProfileSessionOverViewComponent,
    DentistProfileComponent,
    DetailsCardViewComponent,
    ProspectDetailsCardEditComponent,
    CustomerDetailsCardEditComponent,
    PatientInvitationListComponent,
    DetailsCardProfileComponent,
    DetailsCardContactsListComponent,
    DetailsCardLendingComponent,
    DetailsCardVerificationComponent,
    AddressEditModalComponent,
    ContactDetailsEditModalComponent,
    ChangePrimaryModalComponent,
    CustomerProspectDetailsComponent,
    MerchantProspectProfileComponent,
    MarketingSettingsComponent,
    InvitationSettingsComponent,
    MerchantProfileComponent,
  ],
  imports: [
    CommonModule,
    CdkTableModule,
    NgxPaginationModule,
    ProfilsRoutingModule,
    HelperModule,
    SharedModule,
    CustomerModule,
    CustomerProspectModule,
    FormsModule,
    ReactiveFormsModule,
    NgDatepickerModule,
    DentistModule,
    ArchwizardModule,
    MatRippleModule,
    MedicalHistoryModule,
    SessionModule,
    MerchantModule,
    MessageModule,
    MerchantProspectModule,
    QuotationModule,
    InvitationModule,
    InvitationTemplateModule,
    ContractModule,
    BankAccountModule,
    ContactModule,
    ActionLogModule,
    DocumentModule,
    AuthenticationModule,
    NgPipesModule,
    ActivityLogModule,
    DefaultMessageModule,
    SettlementModule,
    MarketingModule,
    TagsModule,
    AppointmentModule,
    NotesModule,
    MarketingFilterModule
  ],
  exports: [
    CustomerProspectProfileComponent,
    CustomerProspectProfileTabsComponent,
    CustomerProfileTabsComponent,
    ProspectProfileTabsComponent,
    ProfileSessionEditComponent,
    ProfileSessionOverViewComponent,
    DentistProfileComponent,
    DetailsCardViewComponent,
    ProspectDetailsCardEditComponent,
    CustomerDetailsCardEditComponent,
    PatientInvitationListComponent,
    DetailsCardProfileComponent,
    DetailsCardContactsListComponent,
    DetailsCardLendingComponent,
    DetailsCardVerificationComponent,
    AddressEditModalComponent,
    ContactDetailsEditModalComponent,
    ChangePrimaryModalComponent,
    CustomerProspectDetailsComponent,
    MerchantProspectProfileComponent,
    MarketingSettingsComponent,
    InvitationSettingsComponent,
    MerchantProfileComponent,
  ],
})
export class ProfilsModule {}
