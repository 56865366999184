<div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="listDB.data.length == 0">
  <div>
    <button class="pull-right mr" (click)="newFilter()" mat-raised-button color="accent">
      <mat-icon>add</mat-icon>Create
    </button>

    <div class="pull-right selection-items" @ngIfAnimation>
      <button
        class="animated mr"
        (click)="selectAll()"
        mat-stroked-button
        color=""
        matTooltip="Select All"
      >
        <mat-icon>border_all</mat-icon>
      </button>
      <button
        class="animated mr"
        (click)="selectPage()"
        mat-stroked-button
        color=""
        matTooltip="Select Page"
      >
        <mat-icon>border_outer</mat-icon>
      </button>

      <button
        class="animated mr"
        *ngIf="selectedIDs && selectedIDs.length > 0"
        [matMenuTriggerFor]="ActionBtnmenu"
        mat-stroked-button
        matTooltip="Options"
      >
        <mat-icon class="fas fa-cog"></mat-icon>  <span *ngIf="selectedIDs && selectedIDs.length>0"
          class="selected-number-item  accent-background">{{selectedIDs.length}}</span>
      </button>
      <mat-menu #ActionBtnmenu="matMenu">
        <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
          <mat-icon>select_all</mat-icon> Deselect
        </button>

        <hr/>

        <button
          class="row select-btn"
          *ngIf="selectedIDs && selectedIDs.length > 0"
          (click)="enableGroup()"
          mat-menu-item
          color=""
        >
          <mat-icon>check_circle</mat-icon>
          Enable
        </button>

        <button
          class="row select-btn"
          *ngIf="selectedIDs && selectedIDs.length > 0"
          (click)="disableGroup()"
          mat-menu-item
          color=""
        >
          <mat-icon>cancel</mat-icon>
          Disable
        </button>
      </mat-menu>
    </div>

    <h1 class="dataHeader ml">
      Audiences
      <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
      <p class="small">You can choose who to communicate to by defining a customer audience</p>
    </h1>
    <mat-card class="list-card no-box-card">
      <!-- Search -->
      <div class="row clearfix searchArea primary-gradient-img">
        <div class="full-width clearfix flex animated fadeInDown">
          <mat-icon class="mt">search</mat-icon>

          <mat-form-field class="ml full-width" appearance="outline">
            <mat-label>Audience Label</mat-label>
            <input
              class="clearfix"
              (input)="setFilter($event.target.value, 'Label')"
              name="PatientName"
              placeholder="Filter Label"
              matInput
            />
          </mat-form-field>

          <mat-form-field class="ml full-width" appearance="outline">
            <mat-label>Audience status</mat-label>
            <mat-select
              class="full-width"
              (selectionChange)="setFilter($event.value, 'Active')"
              name="Type"
              placeholder="Audience status"
            >
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option
                *ngFor="let e of listDB.data | objectPropertyValue: 'Active' | unique | without: ['', null] | orderBy"
                [value]="e"
              >
                <span *ngIf="e == '1'">Active</span>
                <span *ngIf="e != '1'">Inactive</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- Table -->
      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
        <!-- ID Column -->
        <ng-container cdkColumnDef="selectCheckBox" sticky>
          <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div class="margin-btm pull-left">
              <mat-checkbox
                [checked]="isSelected(row['ID'])"
                (change)="select(row['ID'], $event.checked)"
                color="accent"
              >
              </mat-checkbox>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Date" sticky>
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Created at</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openFilterView(row['ID'])"
            matTooltip="{{ row['DateTimeCreated'] | customDateTime }}"
          >
            {{ row.DateTimeCreated | TimeLabelPast }} ago
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Label" sticky>
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Label</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openFilterView(row['ID'])" matTooltip="{{ row['Label'] }}">
            <h3>{{ row['Label'] }}</h3>
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Description">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Description</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openFilterView(row['ID'])" matTooltip="{{ row['Description'] }}">
            {{ row['Description'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="IsActive">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Is Active</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openFilterView(row['ID'])" matTooltip="{{ row['Active'] }}">
            <span
              class="list-label smaller"
              *ngIf="row['Active'] == '1'"
              matTooltip="Active"
              style="background-color: rgb(90, 186, 71)"
            >
              <mat-icon>check</mat-icon>
            </span>
            <span
              class="list-label smaller"
              *ngIf="row['Active'] != '1'"
              matTooltip="Inactive"
              style="background-color: rgb(239, 71, 39)"
            >
              <mat-icon>close</mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button *ngIf="row['ID']" (click)="openFilterView(row['ID'])" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span>View Audience</span>
                </button>

                <button *ngIf="row['ID']" (click)="editFilter(row['ID'])" mat-menu-item>
                  <mat-icon>edit</mat-icon>
                  <span>Edit Audience</span>
                </button>

                <button *ngIf="row['ID'] && row['Active'] == '0'" (click)="enableFilter(row['ID'])" mat-menu-item>
                  <mat-icon>check_circle</mat-icon>
                  <span>Enable</span>
                </button>

                <button *ngIf="row['ID'] && row['Active'] == '1'" (click)="disableFilter(row['ID'])" mat-menu-item>
                  <mat-icon>cancel</mat-icon>
                  <span>Disable</span>
                </button>

                <!-- <button *ngIf="row['ID'] " mat-menu-item (click)="removeFilter(row['ID'])">
                  <mat-icon>delete</mat-icon>
                  <span>Remove</span>
                </button> -->
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row class="listRow" *cdkRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginator
        [length]="dataSource.filteredData.length"
        [pageIndex]="0"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
      >
      </mat-paginator>
    </mat-card>
  </div>
</div>
<div *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <app-empty-list-message
    [actionIcon]="'add'"
    [isAction]="true"
    [actionLabel]="'New Audience'"
    (actionEvent)="newFilter()"
  ></app-empty-list-message>
</div>
