import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataResponse } from '../types/data-response.type';
import { LookupClass } from '../types/lookupClass';
import { UtilsService } from './utils.service';
import { Settings } from '../types/settings';

@Injectable()
export class LookupService {
  private baseUrl = environment.nodeUrl;

  lookupResult = {};
  Lookup = new LookupClass();

  constructor(private http: HttpClient, private utilsService: UtilsService) {}

  getLookup<T = any>(table: string, category: string): Observable<T> {
    const payload = { Category: category };

    const params: HttpParams = this.utilsService.getHttpParamsFromPayload(payload);

    const options = category ? { params } : {};

    const endpoint = table;
    const url = `${this.baseUrl}/${endpoint}`;

    if (this.lookupResult && this.lookupResult[category]) {
      return new Observable<any>((observer) => {
        observer.next(this.lookupResult[category]);
      });
    } else if (
      this.Lookup &&
      this.Lookup.lookupCategory &&
      this.Lookup.lookupCategory[category] &&
      this.Lookup.lookupCategory[category]['result']
    ) {
      return new Observable<any>((observer) => {
        observer.next(this.Lookup.lookupCategory[category]['result']);
      });
    } else {
      return this.http.get<DataResponse<T>>(url+'/cached/'+Settings.global['lookupHash'], options).pipe(
        map((res) => {
          return res['data'];
        })
      );
    }
  }

  invitationLookup(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (let key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/customer-prospect/lookup/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/customer-prospect/lookup', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getCustomerProspectProfilePicStreamLink(ID) {
    if (ID) {
      return `${environment.nodeUrl}/customer-prospect/profile-picture-stream/` + ID;
    }
    return null;
  }
}

export class PurposeCode {
  [key: string]: any;

  Code: string;
  ID: string;
  Label: string;
  Label2: string;
  Label3: string;
  Label4: string;
  SortOrder: string;
}
