import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogMessageComponent } from '../../../shared/components/confirm-dialog-message/confirm-dialog-message.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { ContractService } from '../../contract/shared/contract.service';
import { CustomerService } from '../../customer/shared/customer.service';
import { QuotationDecisionModalComponent } from '../quotation-decision-modal/quotation-decision-modal.component';
import { QuotationDocumentCreateComponent } from '../quotation-document-create/quotation-document-create.component';
import { QuotationService } from '../shared/quotation.service';

// checks overview

// export class checksPerformed {
//   check: string;
//   result: string;
//   view: string;
//   review: string;
// }

// const REF_DATA: checksPerformed[] = [

//   { check: 'Capacity Check', result: 'PASS', view: '', review: 'approve'},
//   { check: 'Credit Check', result: 'FAIL', view: '', review: 'decide'},
//   { check: 'ID Check', result: 'FAIL', view: '', review: 'decide'},
// ];

// id checks

export class idChecks {
  name: string;
  result: string;
  score: string;
  weight: string;
  id: string;
}

const ID_CHK_DATA: idChecks[] = [
  { name: 'Australian Electoral Roll', result: 'FAIL', score: '0', weight: '0.60', id: 'DGHE-2354-23423' },
  { name: 'National Tenancy Database', result: 'FAIL', score: '0', weight: '0.25', id: 'VEDA-322-23234' },
  { name: 'Credit Bureau Commercial', result: 'PASS', score: '0', weight: '0.60', id: 'DGHE-32345324-23' },
  { name: 'Veda Phone Number Directory', result: 'FAIL', score: '0', weight: '0.15', id: 'VEDA-1324-23542' },
  { name: 'Credit Bureau Public Record', result: 'PASS', score: '0', weight: '0.60', id: 'DGHE-2354-23423' },
  { name: 'Drivers License', result: 'PASS', score: '0', weight: '0.60', id: 'DGHE-2354-23423' },
];

@Component({
  selector: 'app-quotation-contract-view',
  templateUrl: './quotation-contract-view.component.html',
  styleUrls: ['./quotation-contract-view.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: '*' }))),
    ]),
  ],
})
export class QuotationContractViewComponent implements OnInit {
  @Input()
  quotationID;

  @Input()
  patientID;

  @Input()
  setApplicationsTab = 0;

  setReferredTab = 0;
  quotation;
  patient;

  loading;

  moreIsBetter = {
    '100': { color: '#db291b' },
    '300': { color: '#ff5722' },
    '400': { color: '#ff9800' },
    '600': { color: '#ffc107' },
    '700': { color: '#ffeb3b' },
    '800': { color: '#8bc34a' },
  };

  //  demo referred
  // displayedColumns: string[] = ['check', 'result', 'view', 'review'];
  // dataReferred = REF_DATA;

  //  demo id check
  displayedIDColumns: string[] = ['name', 'result', 'score', 'weight', 'id'];
  dataIdCheck = ID_CHK_DATA;

  @Input()
  referred = false;

  isAdminOrPromoter = false;

  frequency;

  selectedFrequency;
  selectedDuration;
  enableSubmitBtn = false;
  paymentList;
  totalRepayment;
  totalInterest;
  isParamter = false;
  quotationProductSubType;

  paymentsTab = false;
  complianceTasksTab = false;
  actionLogTab = false;
  documentsTab = false;
  sessionType = 'guest';

  possibleStatus = [];

  supportingDocPromoterDecision;

  constructor(
    private contractService: ContractService,
    private authenticationService: AuthenticationService,
    private customerService: CustomerService,
    private lookupService: LookupService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private quotationService: QuotationService
  ) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      this.activeRoute.params.subscribe((params) => {
        if (params['quotationID']) {
          // console.log(params['quotationID']);

          this.quotationID = params['quotationID'];
          this.isParamter = true;
        }

        this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
          this.isAdminOrPromoter = res;

          this.contractService
            .fetchContractDetails$(this.quotationID, {selectDocuments:true}, this.isAdminOrPromoter)
            .subscribe((res) => {
              if (res) {
                this.quotation = res;

                // this.getQuotationPayment();
                // this.selectedFrequency = this.quotation['RepaymentFrequency.Code'];
                // this.selectedDuration = this.quotation['LoanInstalments'];
                this.customerService
                  .getCustomerDetails(this.quotation['Customer_key'], {}, this.sessionType)
                  .subscribe((res1) => {
                    if (res1) {
                      this.patient = res1;
                    }
                  });

                const p = {
                  contractID: this.quotation['ID'],
                };

                this.quotationService.getContractPossibleStatus(p).subscribe((res) => {
                  if (res) {
                    this.possibleStatus = res.filter(
                      (x) => x['NewStatus.ReallyCode'] != 'COMPLETE' && x['NewStatus.ReallyCode'] != 'SUBMITTED'
                    );

                    if (
                      this.quotation['CreditCheck.Status'] != 'Accept' &&
                      this.quotation['CapacityCheck.Status'] != 'Accept'
                    ) {
                      this.possibleStatus = res.filter(
                        (x) =>
                          x['NewStatus.ReallyCode'] != 'COMPLETE' &&
                          x['NewStatus.ReallyCode'] != 'APPRV_COND' &&
                          x['NewStatus.ReallyCode'] != 'SUBMITTED'
                      );
                    }
                  }
                });
                // this.lookupService.getLookup("CodeLookUp", "Frequency").subscribe(res => {
                //   this.frequency = res;
                // })

                const payload = {
                  fields: 'PromoterDecision,DocumentType_Code',
                };

                this.quotationService.getConractDocument(this.quotation['ID'], payload).subscribe((res) => {
                  if (res) {
                    const documents = res.filter(
                      (x) =>
                        x.DocumentType_Code == 'IDL' ||
                        x.DocumentType_Code == 'IDP' ||
                        x.DocumentType_Code == 'IDC' ||
                        x.DocumentType_Code == 'PID' ||
                        x.DocumentType_Code == 'POE' ||
                        x.DocumentType_Code == 'PRA'
                    );

                    if (documents.every((x) => x.PromoterDecision == 'PASS')) {
                      this.supportingDocPromoterDecision = 'PASS';
                    } else if (documents.every((x) => x.PromoterDecision == '')) {
                      this.supportingDocPromoterDecision = 'TODO';
                    } else {
                      this.supportingDocPromoterDecision = 'REJECT';
                    }
                  }
                });
              }
            });
        });
      });
    });
  }

  subTabChange() {
    // console.log(this.setApplicationsTab);
  }

  tabChange(index) {
    if (index == 1) {
      this.paymentsTab = true;
    } else if (index == 2) {
      this.complianceTasksTab = true;
    } else if (index == 3) {
      this.actionLogTab = true;
    } else if (index == 4) {
      this.documentsTab = true;
    }
  }

  toNumber(value) {
    return Number(value);
  }

  changeDuration(e) {
    // console.log(e)
  }

  changeFrequency(e) {
    this.selectedFrequency = e;
    this.enableSubmitBtn = true;
  }

  submit() {
    const payload = {
      id: this.quotation['ID'],
      frequencyCode: this.selectedFrequency,
    };

    this.contractService.editContract(this.quotation['ID'], payload, this.isAdminOrPromoter).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast(
          'success',
          'Successfully Operation',
          'You have successfully modified the Repayment Frequency'
        );

        this.quotation = res;
        this.selectedFrequency = this.quotation['RepaymentFrequency.Code'];
        this.selectedDuration = this.quotation['LoanInstalments'];

        this.enableSubmitBtn = false;
      }
    });
  }

  getQuotationPayment() {
    // Format the payment data, combine all data into one single list
    this.contractService.getQuotationPayment(this.quotation['ID']).subscribe((res) => {
      // console.log(res);
      this.quotationProductSubType = res['Payment.CalculationData'][0]['ProductSubType'];

      const repaymentAmounts = res['Payment.CalculationData'][0]['Repayments'];
      this.totalRepayment = repaymentAmounts['Amounts'].reduce((a, b) => a + b);
      const paymentListA = [];
      const paymentListB = [];

      if (res['Payment.CalculationData'][0]['ProductSubType'] != 'Payment Plan') {
        const establishmentFees = res['Payment.CalculationData'][0]['EstablishmentFee'];
        const accountKeepingFees = res['Payment.CalculationData'][0]['Fees']['Customer']['ACK_FE']['Schedule'];

        this.totalInterest = establishmentFees['InterestAmounts'].reduce((a, b) => a + b);

        for (let i = 0; i < repaymentAmounts['Dates'].length; i++) {
          if (repaymentAmounts['Dates'][i] === establishmentFees['Dates'][i]) {
            paymentListA.push({
              dates: repaymentAmounts['Dates'][i],
              repayments: repaymentAmounts['Amounts'][i],
              fee: establishmentFees['Amounts'][i] - establishmentFees['InterestAmounts'][i],
              interest: establishmentFees['InterestAmounts'][i],
              offsetCode: repaymentAmounts['Relative']['Offset_Code'][i],
              offsetHowMany: repaymentAmounts['Relative']['Offset_HowMany'][i],
            });
          }
        }

        for (let ii = 0; ii < accountKeepingFees['Dates'].length; ii++) {
          paymentListB.push({
            dates: accountKeepingFees['Dates'][ii],
            akf: accountKeepingFees['Amounts'][ii],
            offsetHowMany: ii + 1,
          });
        }

        this.paymentList = paymentListA.concat(paymentListB);

        this.paymentList.sort((a, b) => {
          return a.dates.localeCompare(b.dates);
        });

        if (this.quotation['RepaymentFrequency.Code'] == '4') {
          const _paymentlist = this.paymentList;

          const _p = _paymentlist.reduce((acc, elem) => {
            const _filter = acc.filter((item) => item.dates == elem.dates)[0];
            if (_filter) {
              _filter.akf = elem['akf'];
            } else {
              acc.push(elem);
            }
            return acc;
          }, []);
          this.paymentList = _p;
        }
      } else {
        for (let i = 0; i < repaymentAmounts['Dates'].length; i++) {
          paymentListA.push({
            dates: repaymentAmounts['Dates'][i],
            repayments: repaymentAmounts['Amounts'][i],
            offsetCode: repaymentAmounts['Relative']['Offset_Code'][i],
            offsetHowMany: repaymentAmounts['Relative']['Offset_HowMany'][i],
          });
        }

        this.paymentList = paymentListA;
      }
    });
  }

  getOffsetCodeLabel(code) {
    if (code == '1') {
      return 'Week';
    } else if (code == '2') {
      return 'Fortnight';
    } else if (code == '4') {
      return 'Month';
    }
  }

  openContractDetail(ID) {
    // console.log(ID)
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['contract-details', ID],
        },
      },
    ]);
  }

  openScoreModal() {
    // open VEDA score card in modal
  }

  // decision modal
  makeDecision(checkType) {
    const ref = RootAppComponent.dialog.open(QuotationDecisionModalComponent, {
      data: {
        checkType,
        quotationID: this.quotation['ID'],
      },
      width: '600px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();

      this.contractService.fetchContractDetails$(this.quotationID, null, this.isAdminOrPromoter).subscribe((res) => {
        if (res) {
          this.quotation = res;
        }
      });
    });
  }

  addSupportingDocument() {
    const ref = RootAppComponent.dialog.open(QuotationDocumentCreateComponent, {
      data: {},
      width: '500px',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  changeTabs(index) {
    this.setReferredTab = index;
  }

  gotoCustomerProfile() {
    const url = this.router.url;

    if (url.indexOf('promoter') != -1) {
      this.router.navigate([
        '/promoter',
        {
          outlets: {
            page: ['customer-profile', this.quotation['Customer_key'], 'patient'],
          },
        },
      ]);
    } else {
      this.router.navigate([
        '/merchant',
        {
          page: ['customer-profile', this.quotation['Customer_key'], 'patient'],
        },
      ]);
    }
  }

  gotoMerchantProfile() {
    const url = this.router.url;

    if (url.indexOf('promoter') != -1) {
      this.router.navigate([
        '/promoter',
        {
          outlets: {
            page: ['merchant-profile', this.quotation['Merchant_key']],
          },
        },
      ]);
    } else {
      this.router.navigate([
        '/merchant',
        {
          page: ['merchant-profile', this.quotation['Merchant_key']],
        },
      ]);
    }
  }

  perform(checkType) {
    this.quotationService.performCheck(this.quotation['ID'], checkType).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast(
          'success',
          'Perform Credit Check',
          'You have successfully performed credit check'
        );

        this.contractService.fetchContractDetails$(this.quotationID, null, this.isAdminOrPromoter).subscribe((res) => {
          if (res) {
            this.quotation = res;
          }
        });
      }
    });
  }

  changeStatus(status) {
    const ref = RootAppComponent.dialog.open(ConfirmDialogMessageComponent, {
      data: {
        icon: 'sync',
        title: 'Convert Status',
        description: `Convert contract status to ${status['NewStatus.Code']}`,
        yesLabel: 'Convert',
        noLabel: 'Cancel',
        textLabel: 'Reason',
      },
      width: '650px',
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res && res.confirm == true) {
        const payload = {
          reason: res.text,
          status: status['NewStatus.ReallyCode'],
        };

        this.quotationService.changeContractStatus(this.quotation['ID'], payload).subscribe((res) => {
          if (res) {
            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }
}
