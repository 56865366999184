<div class="rel" *ngIf="((clientIDs && clientIDs.length > 0) || (accessIDs && accessIDs.length > 0)) && templateID">
  <div class="card-header primary-gradient-img clearfix confirmModal">
    <button class="btn-close btn-clear mat-button smt white custom-close no-title-close" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="full-width flex main-title-container">
      <div class="main-icon-container text-center">
        <mat-icon class="main-icon accent-color fas fa-exclamation-triangle"></mat-icon>
      </div>
      <div class="row full-width title-container clearfix">
        <div class="title-container-sub">
          <h2 class="text-left title-header rm-m">Error in removing/disabling Template.</h2>
        </div>
      </div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="row clearfix full-width mb">
      <p class="text-center full-width" *ngIf="accessIDs && accessIDs.length > 0 && clientIDs && clientIDs.length > 0">
        The Template you're attempting to remove has been used or linked to the following instances.<br/>
        <strong>{{ clientIDs.length }}</strong> third-party application(s) use this Template.<br/>
        <strong>{{ accessIDs.length }}</strong> third-party API Key(s) use this as a Default Template.<br/><br/>
        You will need to update these before being able to remove this one.<br/>
      </p>

      <p class="text-center full-width" *ngIf="accessIDs.length <= 0 && clientIDs && clientIDs.length > 0">
        The Template you're attempting to remove has been used or linked to the following instances.<br/>
        <strong>{{ clientIDs.length }}</strong> third-party application(s) use this Template.<br/><br/>
        You will need to update these before being able to remove this one.<br/>
      </p>

      <p class="text-center full-width" *ngIf="accessIDs && accessIDs.length > 0 && clientIDs.length <= 0">
        The Template you're attempting to remove has been used or linked to the following instances.<br/>
        <strong>{{ accessIDs.length }}</strong> third-party API Key(s) use this as a Default Template.<br/><br/>
        You will need to update these before being able to remove this one.<br/>
      </p>
    </div>

    <hr/>
    <p class="text-center">Please choose how do you wish to proceed.</p>
  </mat-dialog-content>

  <div class="drop-button text-center full-width">
    <button class="mr ml" (click)="closeEvent()" mat-raised-button color="primary">Close</button>

    <button
      class="mr ml"
      *ngIf="((clientIDs && clientIDs.length > 0) || (accessIDs && accessIDs.length > 0)) && templateID"
      (click)="viewAll()"
      mat-raised-button
      color="accent"
    >
      Solve
    </button>
  </div>
</div>
