<mat-card class="rel supplier-card clearfix mb" @ngIfAnimation>

  <div class="card-header primary-gradient-img clearfix stacked">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">

        <h2 class="summary-header text-left rm-m">
          Update version
        </h2>
        <div class="full-width mt " *ngIf="versionDescriptions && versionDescriptions.length>0">


          <mat-slide-toggle class="pull-left  mr mb" [(ngModel)]="displayLog" name="displayLog">Display version log
          </mat-slide-toggle>
        </div>
      </div>
    </div>

  </div>

  <mat-dialog-content>
    <div class="full-width flex">
      <div class="full-width mr">


        <h3 class="sr-title full-width      text-left rm-mt ">What new!</h3>

        <div class="full-width flex mb">
          <div class="margin-btm full-width clearfix   mr ">
            <mat-checkbox color="accent" name="updateDescription" [(ngModel)]="updateDescription">
              Update description </mat-checkbox>
          </div>
          <div class="margin-btm full-width clearfix   ml  mr">
            <mat-checkbox color="accent" name="updateLastVersion" [(ngModel)]="updateLastVersion">
              Overwrite last description </mat-checkbox>
          </div>

        </div>
        <message-input [isTiny]="true" class="full-width  message-input-component  message-version" [isModal]="false"
          [messageMode]="messageMode" [label]="'Please insert a  description'" [message]="_versionDescription"
          [showAllCustomization]="true" [textLength]="0" [displayEmoji]="false" [displayMessageTemplate]="false"
          [displayPatient]="false" [displayContact]="false" [displayMerchant]="false" [displayPromoter]="false"
          [displayMarketing]="true" [displayExpend]="false" [displayDentalOffers]="true" [displayDocument]="true"
          [saveAsTemplate]="false" [isSMSPreview]="false" [isDesktopPreview]="true" [displaySaveAsTemplate]="true"
          [isCustomTreatmentAmount]="false" [displayPreview]="false" [encode]="false"
          (getResult)="getResultMessageDisclaimer($event)">
        </message-input>







      </div>

      <div class="half-width ml">

        <div class="  full-width  block  flex  ">

          <h3 class="sr-title  rm-m  full-width mt   mr    text-left ">Promoter</h3>

          <div class="full-width">

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Release</mat-label>
              <mat-select name="promoterVersion" placeholder="Release" [(ngModel)]="promoterVersion">
                <mat-option [value]="'none'">
                  None
                </mat-option>
                <mat-option [value]="'major'">
                  Major
                </mat-option>
                <mat-option [value]="'minor'">
                  Minor
                </mat-option>
                <mat-option [value]="'bug'">
                  Bug fixes
                </mat-option>
                <mat-option [value]="'manual'">
                  Manual
                </mat-option>

              </mat-select>
            </mat-form-field>

            <mat-form-field class="  full-width" *ngIf="promoterVersion==='manual'" appearance="outline">
              <mat-label>Version</mat-label>
              <input name="lastname" matInput placeholder="Version" [(ngModel)]="promoterVersionText">
            </mat-form-field>
          </div>

        </div>
        <div class="  full-width  block impair flex ">

          <h3 class="sr-title  rm-m  full-width  mt    mr  text-left ">Merchant</h3>
          <div class="full-width ">

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Release</mat-label>
              <mat-select name="merchantVersion" placeholder="Release" [(ngModel)]="merchantVersion">
                <mat-option [value]="'none'">
                  None
                </mat-option>
                <mat-option [value]="'major'">
                  Major
                </mat-option>
                <mat-option [value]="'minor'">
                  Minor
                </mat-option>
                <mat-option [value]="'bug'">
                  Bug fixes
                </mat-option>
                <mat-option [value]="'manual'">
                  Manual
                </mat-option>

              </mat-select>
            </mat-form-field>

            <mat-form-field class="  full-width" *ngIf="merchantVersion==='manual'" appearance="outline">
              <mat-label>Version</mat-label>
              <input name="lastnMobileMobileame" matInput placeholder="Version" [(ngModel)]="merchantVersionText">
            </mat-form-field>
          </div>
        </div>
        <div class="  full-width  block   flex ">

          <h3 class="sr-title  rm-m  full-width  mt   mr   text-left ">Consumer</h3>
          <div class="full-width ">

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Release</mat-label>
              <mat-select name="consumerVersion" placeholder="Release" [(ngModel)]="consumerVersion">
                <mat-option [value]="'none'">
                  None
                </mat-option>
                <mat-option [value]="'major'">
                  Major
                </mat-option>
                <mat-option [value]="'minor'">
                  Minor
                </mat-option>
                <mat-option [value]="'bug'">
                  Bug fixes
                </mat-option>
                <mat-option [value]="'manual'">
                  Manual
                </mat-option>

              </mat-select>
            </mat-form-field>

            <mat-form-field class="  full-width" *ngIf="consumerVersion==='manual'" appearance="outline">
              <mat-label>Version</mat-label>
              <input name="lastnaMobileMobileMobileme" matInput placeholder="Version" [(ngModel)]="consumerVersionText">
            </mat-form-field>
          </div>
        </div>
        <div class="  full-width  block impair  flex">

          <h3 class="sr-title  rm-m  full-width  mt   mr   text-left ">Integration</h3>
          <div class="full-width ">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Release</mat-label>
              <mat-select name="integrationVersion" placeholder="Release" [(ngModel)]="integrationVersion">
                <mat-option [value]="'none'">
                  None
                </mat-option>
                <mat-option [value]="'major'">
                  Major
                </mat-option>
                <mat-option [value]="'minor'">
                  Minor
                </mat-option>
                <mat-option [value]="'bug'">
                  Bug fixes
                </mat-option>
                <mat-option [value]="'manual'">
                  Manual
                </mat-option>

              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width" *ngIf="integrationVersion==='manual'" appearance="outline">
              <mat-label>Version</mat-label>
              <input name="lastnsasaame" matInput placeholder="Version" [(ngModel)]="integrationVersionText">
            </mat-form-field>
          </div>
        </div>
        <div class="  full-width  block  flex  ">
          <h3 class="sr-title  rm-m  full-width mt    mr   text-left ">Public Site</h3>
          <div class="full-width ">

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Release</mat-label>
              <mat-select name="publicSiteVersion" placeholder="Release" [(ngModel)]="publicSiteVersion">
                <mat-option [value]="'none'">
                  None
                </mat-option>
                <mat-option [value]="'major'">
                  Major
                </mat-option>
                <mat-option [value]="'minor'">
                  Minor
                </mat-option>
                <mat-option [value]="'bug'">
                  Bug fixes
                </mat-option>
                <mat-option [value]="'manual'">
                  Manual
                </mat-option>

              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width" *ngIf="publicSiteVersion==='manual'" appearance="outline">
              <mat-label>Version</mat-label>
              <input name="lastnzazaame" matInput placeholder="Version" [(ngModel)]="publicSiteVersionText">
            </mat-form-field>
          </div>

        </div>
        <div class="  full-width  block  impair ">
          <div class="margin-btm full-width clearfix ">
            <mat-checkbox color="accent" name="isLookupHash" [(ngModel)]="isLookupHash">
              Refresh lookup cache</mat-checkbox>
          </div>

          <div class="margin-btm full-width clearfix ">
            <mat-checkbox color="accent" name="isDocumentTypeHash" [(ngModel)]="isDocumentTypeHash">
              Refresh document type cache </mat-checkbox>
          </div>

          <div class="margin-btm full-width clearfix ">
            <mat-checkbox color="accent" name="isSuburbHash" [(ngModel)]="isSuburbHash">
              Refresh suburb cache </mat-checkbox>
          </div>


          <div class="margin-btm full-width clearfix ">
            <mat-checkbox color="accent" name="isTranslationHash" [(ngModel)]="isTranslationHash">
              Refresh translation file </mat-checkbox>
          </div>

          <div class="margin-btm full-width clearfix ">
            <mat-checkbox color="accent" name="isTranslationHash" [(ngModel)]="isCacheHash">
              Refresh documents/global cache </mat-checkbox>
          </div>
          <div class="margin-btm full-width clearfix ">
            <mat-checkbox color="accent" name="cleanMessageGroupLocalData" [(ngModel)]="cleanMessageGroupLocalData">
              Clean message group local data</mat-checkbox>
          </div>

        </div>
      </div>

      <div class="full-width  "
        *ngIf="versionDescriptions && versionDescriptions.length>0 && displayLog===true &&  isLoaded==true"
        style="    max-height: 680px;    width:400px;    overflow-y: auto; padding-right: 20px;  padding-left: 10px;">

        <div class="full-width flex" *ngFor="let version of versionDescriptions | reverseArray">
          <mat-card class="full-width mb">
            <div class="colour-stripe"></div>

            <div class="full-width flex app-version-holder">

              <div class="full-width app-version-container ">

                <button *ngIf="version.id" class="ml smr pull-right btn" (click)="removeVersion(version.id)"
                  style="height: 20px; width: 20px" color="accent" mat-icon-button>
                  <mat-icon>delete</mat-icon>
                </button>


                <h3 class="sr-title  rm-m   full-width   text-left " *ngIf="version.versionDate"
                  matTooltip="{{ version['versionDate'] | customDateTime }}">
                  <span>{{ formatDate(version['versionDate']) | TimeLabelPast }} ago</span>
                </h3>




                <div class="full-width mt" *ngIf="version && version.applications && version.applications.length>0">


                  <p class="small app-version"
                    *ngFor="let app of version['applications']   | OrderArrayObject: ['name'] ">
                    <strong>{{app.name}}</strong>: {{app.version}}
                  </p>
                </div>
              </div>
            </div>


            <div class="full-width mb  " [innerHTML]="version['versionDescription'] | safeHtml"></div>




          </mat-card>
        </div>

      </div>


    </div>
  </mat-dialog-content>

  <hr>
  <div class="drop-button text-center full-width ">

    <button mat-raised-button color="accent" class="btn-large" (click)="save()">Save</button>

  </div>

</mat-card>
