import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { map } from 'rxjs/operators';

import { DataSource } from '@angular/cdk/table';
import { InvitationService } from '../shared/services/invitation.service';

@Component({
  selector: 'app-invitation-list-view',
  templateUrl: './invitation-list-view.component.html',
  styleUrls: ['./invitation-list-view.component.css'],
})
export class InvitationListViewComponent implements OnInit {
  displayedColumns = ['Date', 'LastName', 'FirstName', 'Source', 'Product', 'InvitationStatus'];
  lastUpdate: any;
  public invitationsDB: LoadInv | null;
  dataSource: InvitationDataSource | null;
  test = new Date();

  @Input()
  membership = false;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private invitationService: InvitationService) {}

  ngOnInit() {
    this.invitationsDB = new LoadInv(this.invitationService, this.membership);
    this.dataSource = new InvitationDataSource(this.invitationsDB, this.sort);
  }
}

export class LoadInv implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  invitations: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  serviceRef;

  constructor(private invitationService: InvitationService, private membership) {
    const payload = {
      Voided: 0,
      section: 0,
      limit: 5,
    };

    this.serviceRef = this.invitationService.getInvitations(payload, false, this.membership).subscribe((res) => {
      this.firstLoad = true;
      if (res.success) {
        this.count = res.count;
        this.invitations = res.data;
        this.dataChange.next(res.data.slice());
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class InvitationDataSource extends DataSource<any> {
  lastData: any;

  constructor(private _invDatabase: any, private _sort: MatSort) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [this._invDatabase.dataChange, this._sort.sortChange];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        this.lastData = this._invDatabase.data.slice()[0];
        this.lastData = this.lastData ? this.lastData['DateTimeCreated'] : new Date();
        const data = this.getSortedData(this._invDatabase.data.slice());
        // Grab the page's slice of data.
        return data;
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'Date':
          [propertyA, propertyB] = [a['DateTimeCreated'], b['DateTimeCreated']];
          break;
        case 'FirstName':
          [propertyA, propertyB] = [a.FirstName, b.FirstName];
          break;
        case 'LastName':
          [propertyA, propertyB] = [a.LastName, b.LastName];
          break;
        case 'Product':
          [propertyA, propertyB] = [a['Product.Label.Marketing'], b['Product.Label.Marketing']];
          break;
        case 'Source':
          [propertyA, propertyB] = [a['PatientChoice.Code'], b['PatientChoice.Code']];
          break;
        case 'InvitationStatus':
          [propertyA, propertyB] = [a['Status.Invitation.Short_NE'], b['Status.Invitation.Short_NE']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
