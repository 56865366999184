import { Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-ddr-modal',
  templateUrl: './ddr-modal.component.html',
  styleUrls: ['./ddr-modal.component.css'],
})
export class DdrModalComponent implements OnInit {
  closeModal = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  close() {
    this.closeModal.emit(true);
  }
}
