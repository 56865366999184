<div class="row clearfix padded mb">
  <ng-container *ngIf="smileMakeovers$ | async as smileMakeovers; else firstLoad">
    <ng-container>
      <div style="display: flex; justify-content: space-between">
        <ipv-data-header [counterValue]="smileMakeovers.length" [title]="'Smile Makeovers'"></ipv-data-header>
      </div>

      <div class="row clearfix">
        <app-operator-filter
          id="part1"
          *ngIf="isPromoterOrAdmin"
          [displayMerchant]="true"
          [displayDateRange]="false"
          [displayDateFrom]="false"
          [displayTimePicker]="false"
          [displayDateTo]="false"
          [displayDentist]="false"
          [setAutoSelectMerchant]="true"
          [selectedMerchant]="merchantKey"
          (getMerchant)="changeMerchant($event)"
        >
        </app-operator-filter>
      </div>

      <mat-card style="height: 400px; margin-top: 40px">
        <ng-container *ngIf="merchantKey; else noMerchant">
          <ipv-data-table
            [table]="smileMakeovers"
            [customComponents]="{ 'Card.Gender': gender }"
            [menuTemplate]="actions"
            [isFixedFirstRow]="false"
            [hide]="[
              'ID',
              'LastModified',
              'LastModified_Human',
              'Is_Hidden',
              'Merchant_key',
              'Card_key',
              'Card.FirstName',
              'Card.MiddleName',
              'Card.LastName',
              'Card.Email',
              'Card.Mobile',
              'WishListItems_IDs',
              'RightImageDoc_key',
              'LeftImageDoc_key',
              'UpperImageDoc_key',
              'BottomImageDoc_key',
              'FrontImageDoc_key',
              'Comment'
            ]"
            [order]="['DateTimeCreated', 'Card.Salutation', 'Card.CalculatedName']"
            [rename]="{
              DateTimeCreated: 'Date',
              'Card.CalculatedName': 'Name',
              'Card.Gender': 'Gender',
              'Card.Salutation': 'Title'
            }"
            [sortable]="['DateTimeCreated']"
            (itemClicked)="openSmileMakeoverViewModal($event)"
          >
          </ipv-data-table>
        </ng-container>

        <ng-template #noMerchant>
          <ipv-no-merchant-list-message [dataName]="'Smile Makeovers'"></ipv-no-merchant-list-message>
        </ng-template>
      </mat-card>

      <ng-template #gender let-item>
        {{ item['Card.Gender'] | genderCode }}
      </ng-template>
    </ng-container>

    <ng-template #actions let-item>
      <button (click)="openSmileMakeoverDeleteModal(item, smileMakeovers)" mat-menu-item>
        <mat-icon class="fa fa-trash"></mat-icon>
        <span> Delete </span>
      </button>
    </ng-template>
  </ng-container>

  <ng-template #firstLoad>
    <ipv-fetching-list-message></ipv-fetching-list-message>
  </ng-template>
</div>
