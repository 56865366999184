<button
  class="pull-right gradient green"
  *ngIf="
    displayContractView != true &&
    isModuleSettlementsActive == true &&
    displayInvitationView != true &&
    displayNewSettlement != true
  "
  (click)="newSettlement()"
  mat-raised-button
  color="primary"
  matTooltip="New Settlement"
>
  <mat-icon class="fas fa-comment-dollar" aria-hidden="true"></mat-icon>
  New Settlement
</button>
<!-- new settlement btn requested to be hidden -->

<div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="listDB.data.length == 0">
  <p class="pull-right" *ngIf="dateFrom != null || dateTo != null">
    <span *ngIf="dateFrom"
    >From:
      <strong>{{ dateFrom | customDateTime }} </strong>
    </span>

    <span *ngIf="dateTo">
      To:
      <strong>{{ dateTo | customDateTime }}</strong>
    </span>
  </p>
  <div [hidden]="displayContractView == true || displayInvitationView == true || displayNewSettlement == true">
    <h1 class="dataHeader">
      <!-- Contracts -->
      {{ "KEYWORD.patient" | translate | titlecase }} Settlements
      <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
    </h1>
    <mat-card class="list-card">
      <!-- Search -->
      <div class="row clearfix searchArea primary-gradient-img">
        <div class="row justify flex">
          <div class="row clearfix flex full-width">
            <mat-icon class="schIcon mt">search</mat-icon>

            <mat-form-field class="full-width margin-btm" appearance="outline">
              <mat-label>Search...</mat-label>
              <input
                class="clearfix"
                (input)="setFilter($event.target.value, 'fullName')"
                name="CustomerName"
                placeholder="Search..."
                matInput
              />
            </mat-form-field>

            <mat-form-field class="half-width" appearance="outline">
              <mat-label>Loan Type</mat-label>

              <mat-select
                class="full-width"
                (selectionChange)="setFilter($event.value, 'ProductName')"
                name="loanType"
                placeholder="Loan Type"
              >
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option
                  *ngFor="
                    let a of listDB.data | objectPropertyValue: 'ProductName' | unique | without: ['', null] | orderBy
                  "
                  [value]="a"
                >
                  {{ a }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="half-width" appearance="outline">
              <mat-label>Settlement Status</mat-label>

              <mat-select
                class="full-width"
                (selectionChange)="setFilter($event.value, 'Settlement_Status')"
                name="SettlementStatus"
                placeholder="Settlement Status"
              >
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option [value]="false"> Remaining</mat-option>
                <mat-option [value]="true"> Complete</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <!-- Table -->
      <table class="list-table" #table [dataSource]="dataSource" mat-table matSort>
        <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
        <!-- ID Column -->
        <ng-container matColumnDef="CustomerName">
          <th class="largeFont" *matHeaderCellDef mat-header-cell
              mat-sort-header>{{ "KEYWORD.patient" | translate | titlecase }}</th>
          <td
            *matCellDef="let row"
            (click)="openQuickViewDialog(row['ID'])"
            mat-cell
            matTooltip="{{ row['Customer.FullName'] }}"
          >
            {{ row['Customer.FullName'] }}
          </td>
        </ng-container>
        <ng-container matColumnDef="MerchantName">
          <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header>Merchant</th>
          <td
            *matCellDef="let row"
            (click)="openQuickViewDialog(row['ID'])"
            mat-cell
            matTooltip="{{ row['MerchantName'] }}"
          >
            {{ row['MerchantName'] }}
          </td>
        </ng-container>
        <ng-container matColumnDef="CustomerMobile">
          <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header>Phone/Mobile</th>
          <td
            *matCellDef="let row"
            (click)="openQuickViewDialog(row['ID'])"
            mat-cell
            matTooltip="{{ row['Customer.Mobile'] | customPhone }}"
          >
            {{ row['Customer.Mobile'] | customPhone }}
          </td>
        </ng-container>
        <ng-container matColumnDef="LoanType">
          <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header>Loan Type</th>
          <td
            *matCellDef="let row"
            (click)="openQuickViewDialog(row['ID'])"
            mat-cell
            matTooltip="{{ row['ProductName'] }}"
          >
            {{ row['ProductName'] }}
          </td>
        </ng-container>

        <ng-container matColumnDef="patientPicture">
          <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header></th>
          <td *matCellDef="let row" (click)="openQuickViewDialog(row['ID'])" mat-cell>


            <app-card-picture class="sms-merchant-pic-settlement text-center pull-left " [hideZoomButton]="true"
              [cardID]="row.Customer_key" [hideZoomButton]="false" [tableName]="'Customer'">
            </app-card-picture>

          </td>
        </ng-container>


        <ng-container matColumnDef="TreatmentValue">
          <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header>
            {{ "KEYWORD.treatment" | translate | titlecase }} Value
          </th>
          <td
            *matCellDef="let row"
            (click)="openQuickViewDialog(row['ID'])"
            mat-cell
            matTooltip="{{ row['Amount.FinancedBeforeDeposit'] | customCurrency }}"
          >
            {{ row['Amount.FinancedBeforeDeposit'] | customCurrency }}
          </td>
        </ng-container>

        <ng-container matColumnDef="SettlementStatus">
          <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header>
            Settlement Status
          </th>
          <td *matCellDef="let row" (click)="openQuickViewDialog(row['ID'])" mat-cell>
            <!-- <span class="chip" *ngIf="row['Settlements.NotYetDrawn']=='0'" [style.background]="'green'">Complete</span>
            <span class="chip" *ngIf="row['Settlements.NotYetDrawn']!='0'" [style.background]="'red'">Outstanding</span> -->

            <span class="chip" [style.background]="row['Settlement_Status'] == 'Complete' ? '#4caf50' : '#FFBF00'">{{
              row['Settlement_Status']
              }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="Actions">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Actions</th>
          <td *matCellDef="let row" mat-cell>
            <div style="float: right">

              <app-invitation-actions *ngIf="row" [customerID]="row['Customer_key']"
              [invitationID]="row['Invitation_key']" [items]="items" [contractID]="row['ID']"
              [merchantID]="row['Merchant_Key']" [dentistID]="row['DentistContact_key']"
              [campaignID]="row['Campaign_Key']" [contract]="row" [productID]="row['Product_key']">
            </app-invitation-actions>

              <!-- <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button> -->
              <mat-menu #menu="matMenu">
                <button *ngIf="row['ID']" (click)="openQuickViewDialog(row['ID'])" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span>Contract Details</span>
                </button>
                <!-- <button mat-menu-item *ngIf="row['ID']" (click)="openDetailContractDialog(row['ID'])">
                  <mat-icon>portrait</mat-icon>
                  <span>{{"ContractDetails" | translate}}</span>
                </button> -->

                <button (click)="createSettlement(row['Customer_key'])" mat-menu-item>
                  <mat-icon class="fas fa-comment-dollar" aria-hidden="true"></mat-icon>
                  <span> New Settlement </span>
                </button>
              </mat-menu>
            </div>
          </td>
        </ng-container>
        <tr *cdkHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
        <tr *cdkRowDef="let row; columns: displayedColumns" mat-row></tr>
      </table>
      <mat-paginator
        #paginator
        [length]="dataSource.filteredData.length"
        [pageIndex]="0"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
      >
      </mat-paginator>
    </mat-card>
  </div>
  <div *ngIf="customerID != null && displayContractView == true">
    <app-contracts-view [customerID]="customerID"></app-contracts-view>
    <div class="full-width text-center">
      <button class="mt mb pull-left" (click)="hideViewDetails()" mat-raised-button color="primary">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Settlement list
      </button>
    </div>
  </div>

  <div *ngIf="customerID != null && displayNewSettlement == true">
    <app-settlement-create [customerID]="customerID" (noContract)="notDisplaySettlementCreate($event)">
    </app-settlement-create>
    <div class="full-width text-center">
      <button class="mt mb pull-left" (click)="hideViewDetails()" mat-raised-button color="primary">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Settlement list
      </button>
    </div>
  </div>
</div>
<div *ngIf="listDB && listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <app-empty-list-message></app-empty-list-message>
</div>
