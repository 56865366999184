import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-medical-history-acknowledgement',
  templateUrl: './medical-history-acknowledgement.component.html',
  styleUrls: ['./medical-history-acknowledgement.component.css'],
})
export class MedicalHistoryAcknowledgementComponent implements OnInit {
  @Input()
  merchantInformedConsent;

  @Output()
  agreement: EventEmitter<any> = new EventEmitter();

  isAgree = false;
  // merchantInformedConsent = 'Consent wording goes here.';

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.merchantInformedConsent = data;
    }
  }

  ngOnInit(): void {}

  changeAgreement() {
    this.agreement.emit(this.isAgree);
  }
}
