<div class="rel text-center cdia">
  <form #resetForm="ngForm">
    <div
      class="card-header clearfix confirmModal"
      [ngClass]="{
        'primary-gradient-img': !headerBGColor,
        'customed-gradient-img': !!headerBGColor
      }"
      [style.backgroundColor]="headerBGColor"
    >
      <button
        class="btn-close btn-clear mat-button smt white custom-close"
        [ngClass]="!title ? 'no-title-close' : ''"
        (click)="onNo()"
        matDialogClose
      >
        <mat-icon>clear</mat-icon>
      </button>
      <div
        class="full-width flex main-title-container"
        [ngClass]="{ 'main-icon-container-no-title': !title && !subTitle }"
      >
        <div class="main-icon-container text-center">
          <mat-icon class="main-icon accent-color" *ngIf="icon && isFontAwsome(icon) != true">{{ icon }}</mat-icon>
          <mat-icon class="main-icon accent-color {{ icon }}" *ngIf="icon && isFontAwsome(icon) == true"></mat-icon>
        </div>
        <div class="row full-width title-container clearfix">
          <div class="title-container-sub">
            <h2 class="text-left title-header rm-m">
              {{ title }}
              <span class="subLabel" *ngIf="subTitle">{{ subTitle }}</span>
            </h2>
          </div>
        </div>
      </div>
    </div>

    <mat-dialog-content>
      <div class="row clearfix mb text-center">
        <div [innerHTML]="description | safeHtml"></div>
      </div>

      <mat-form-field class="clearfix full-width mb" *ngIf="isAmountInput == true" appearance="outline">
        <mat-label>{{ amountLabel }}</mat-label>
        <input
          [(ngModel)]="amount"
          [required]="isAmountInputRequired"
          type="{{ amountType }}"
          matInput
          placeholder="{{ amountLabel }}"
          name="amountLabel"
          currencyMask
        />
      </mat-form-field>

      <app-email-input
        class="clearfix full-width mb text-center"
        *ngIf="isEmailInput == true"
        [email]="defaultEmail"
        [label]="emailLabel"
        [required]="isEmailRequired"
        (getEmail)="getEmail($event)"
        (validate)="validateEmail($event)"
      >
      </app-email-input>

      <mat-form-field class="clearfix full-width mb" *ngIf="isTextInput == true" appearance="outline">
        <mat-label>{{ textLabel }}</mat-label>
        <input
          [(ngModel)]="text"
          [required]="isTextInputRequired"
          matInput
          placeholder="{{ textLabel }}"
          name="textLabel"
        />
      </mat-form-field>

      <div class="clearfix full-width mb" *ngIf="isDateInput == true">
        <app-date-input
          class="full-width"
          [defaultDate]="defaultDateDate"
          [defaultTime]="defaultDateTime"
          [isTime]="false"
          [label]="dateLabel"
          [minDate]="minDate"
          (selectedDate)="selectDate($event)"
        >
        </app-date-input>
      </div>

      <div class="clearfix full-width mb" *ngIf="isDateTimeInput == true">
        <app-date-input
          class="full-width"
          [defaultDate]="defaultDateTimeDate"
          [defaultTime]="defaultDateTimeTime"
          [isTime]="true"
          [label]="dateTimeLabel"
          [minDate]="minDate"
          (selectedDate)="selectDateTime($event)"
        >
        </app-date-input>
      </div>

      <div class="clearfix full-width mb" *ngIf="isDateSecondInput == true">
        <app-date-input
          class="full-width"
          [defaultDate]="defaultDateDateSecond"
          [defaultTime]="defaultDateTimeSecond"
          [isTime]="false"
          [label]="dateLabelSecond"
          [minDate]="minDateSecond"
          (selectedDate)="selectDateSecond($event)"
        >
        </app-date-input>
      </div>

      <div class="clearfix full-width mb" *ngIf="isDateTimeSecondInput == true">
        <app-date-input
          class="full-width"
          [defaultDate]="defaultDateTimeDateSecond"
          [defaultTime]="defaultDateTimeTimeSecond"
          [isTime]="true"
          [label]="dateTimeLabelSecond"
          [minDate]="minDateSecond"
          (selectedDate)="selectDateTimeSecond($event)"
        >
        </app-date-input>
      </div>

      <mat-form-field
        class="clearfix full-width mb text-input"
        *ngIf="optionList && optionList.length > 0 && isOption == true"
        appearance="outline"
      >
        <mat-label>{{ optionLabel }}</mat-label>

        <mat-select
          class="full-width"
          [(ngModel)]="optionValue"
          [required]="isOptionRequired"
          name="optionValue"
          placeholder="{{ optionLabel }}"
        >
          <mat-option *ngFor="let o of optionList | OrderArrayObject: optionViewValue" [value]="o[optionCode]">
            {{ o[optionViewValue] }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <h4
        class="primary-color subtitle rm-mb rm-mt"
        *ngIf="checkboxTitle && isCheckbox == true"
        [style.textAlign]="'left'"
      >
        {{ checkboxTitle }}
      </h4>
      <div class="clearfix full-width mb" *ngIf="isCheckbox == true">
        <mat-slide-toggle class="clearfix" [(ngModel)]="checkboxValue" name="checkboxValue"
        >{{ checkboxLabel }}
        </mat-slide-toggle>
      </div>

      <div class="clearfix full-width mb" *ngIf="isCheckboxSecond == true">
        <mat-slide-toggle class="clearfix" [(ngModel)]="checkboxValueSecond" name="checkboxValueSecond">
          {{ checkboxLabelSecond }}
        </mat-slide-toggle>
      </div>

      <div *ngIf="messageTitle && isTextArea == true" [style.textAlign]="'left'">
        {{ messageTitle }}
      </div>
      <mat-form-field class="clearfix full-width text-input" *ngIf="isTextArea == true" appearance="outline">
        <mat-label>
          {{ messageLabel }}
        </mat-label>

        <textarea
          class="replymsg-c"
          [(ngModel)]="message"
          [required]="isTextAreaRequired"
          matInput
          placeholder="{{ messageLabel }}"
          name="replymsg"
        ></textarea>
      </mat-form-field>

      <div class="full-width mb margin-btm" *ngIf="isPolicy == true">
        <mat-checkbox class="pull-left" [(ngModel)]="policyValue" color="accent" name="policyValue">
          {{ policyLabel }}
        </mat-checkbox>

        <span class="pull-left">
          <a class="link-class" (click)="clickPolicyEvent()"
          ><strong> {{ policyName }} </strong></a
          >
        </span>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="button-row text-center">
      <button *ngIf="displayNo" (click)="onNo()" mat-raised-button>
        {{ noLabel }}
      </button>
      <button
        [disabled]="
          !resetForm.form.valid ||
          isEmailValidate != true ||
          (isCheckbox == true && isCheckboxRequired == true && !checkboxValue) ||
          (isCheckboxSecond == true && isCheckboxSecondRequired == true && !checkboxValueSecond) ||
          (isPolicy == true && isPolicyRequired == true && !policyValue) ||
          (isDateInput == true && isDateRequired == true && !date) ||
          (isDateSecondInput == true && isDateSecondRequired == true && !dateSecond) ||
          (isDateTimeInput == true && isDateTimeRequired == true && !dateTime) ||
          (isDateTimeSecondInput == true && isDateTimeSecondRequired == true && !dateTimeSecond) ||
          (isOptionRequired == true && isOption == true && !optionValue)
        "
        (click)="onYes()"
        mat-raised-button
        color="accent"
      >
        {{ yesLabel }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
