export class PatientPaymentReportRequestOptionals {
  merchantKey?: string;
  endDate?: string;
}

export class PatientPaymentReportRequest {
  StartDate: string;
  Merchant_key?: string;
  EndDate?: string;

  constructor(startDate: string, optional?: PatientPaymentReportRequestOptionals) {
    this.StartDate = startDate;

    if (optional) {
      if (optional.endDate) {
        this.EndDate = optional.endDate;
      }

      if (optional.merchantKey) {
        this.Merchant_key = optional.merchantKey;
      }
    }
  }
}

export class PatientPaymentReport {
  StartDate: string;
  EndDate?: string;
  Merchant_key: string;
  Date_Processed: string[];
  Patient_Name: string[];
  Product_Label: string[];
  Treatment_Value: number[];
  Settled_Amount: number[];
}

export class PatientPaymentRecord {
  date: string;
  patient: string;
  product: string;
  treatmentValue: number;
  settlementAmount: number;
}

export class MerchantFeesReportRequest {
  StartDate: string;
  Merchant_key?: string;
  EndDate?: string;

  constructor(startDate: string, optional?: PatientPaymentReportRequestOptionals) {
    this.StartDate = startDate;

    if (optional) {
      if (optional.endDate) {
        this.EndDate = optional.endDate;
      }

      if (optional.merchantKey) {
        this.Merchant_key = optional.merchantKey;
      }
    }
  }
}

export class MerchantFeesReportRequestOptionals {
  merchantKey?: string;
  endDate?: string;
}

export class MerchantFeeReport {
  StartDate: string;
  EndDate?: string;
  Merchant_key: string;
  Date_Processed: string[];
  Patient_Name: string[];
  Product_Label: string[];
  Treatment_Value: number[];
  Fees_Amount: number[];
}

export class MerchantFeeRecord {
  date: string;
  patient: string;
  product: string;
  treatmentValue: number;
  fee: number;
}
