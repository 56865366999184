import { Component, EventEmitter, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { CustomerProspectService } from '../../customer-prospect/shared/customerProspect.service';
import { CustomerService } from '../../customer/shared/customer.service';
import { ExistingPatientService } from '../../existing-patient/shared/existing-patient.service';

@Component({
  selector: 'app-invitation-customer-prospect-edit',
  templateUrl: './invitation-customer-prospect-edit.component.html',
  styleUrls: ['./invitation-customer-prospect-edit.component.css'],
})
export class InvitationCustomerProspectEditComponent implements OnInit {
  @Input()
  patientID;

  dateOfBirth;

  _dateOfBirth;
  closeModal = new EventEmitter();

  isModal = false;
  patient;
  modifiedDate = null;
  isMobileValid = false;
  isPhoneValid = false;
  isEmailValid = false;
  genders = [];
  titles = [];

  getResult = new EventEmitter();

  sessionType = 'guest';

  constructor(
    private authenticationService: AuthenticationService,
    private lookupService: LookupService,
    private customerProspectService: CustomerProspectService,
    private customerService: CustomerService,
    private existingPatientService: ExistingPatientService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.patientID) {
        this.patientID = data.patientID;

        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      const payload = {
        fields:
          'ID,FirstName,Gender.Code,LastModified,MiddleName,Name,Salutation,TableName,' +
          'emails.Email,mobiles.Number,phones.Number,DateOfBirth',
      };
      if (this.patientID) {
        this.customerProspectService
          .getCustomerProspectDetails(this.patientID, payload, this.sessionType)
          .subscribe((res) => {
            if (res) {
              this.patient = res;

              if (this.patient['LastModified']) {
                this.modifiedDate = this.patient['LastModified'];
              }
              if (res && res['DateOfBirth'] !== '0000-00-00') {
                this.dateOfBirth = moment(res['DateOfBirth'], 'YYYY-MM-DD').toDate();
                this._dateOfBirth = moment(res['DateOfBirth'], 'YYYY-MM-DD').toDate();
              }
            }

            this.lookupService.getLookup('CodeLookup', 'Gender').subscribe((data) => {
              this.genders = data;
            });
            this.lookupService.getLookup('SimpleLookup', 'Salutation').subscribe((data) => {
              this.titles = data;
            });
          });
      }
    });
  }

  closeEvent(e) {
    this.closeModal.emit(e);
  }

  selectDOB(d) {
    if (d) {
      this._dateOfBirth = d;
    }
  }

  getMobile(p) {
    this.patient['mobiles.Number'] = p;
  }

  getPhone(p) {
    this.patient['phones.Number'] = p;
  }

  getEmail(p) {
    this.patient['emails.Email'] = p;
  }

  validateMobile(v) {
    this.isMobileValid = v;
  }

  validatePhone(v) {
    this.isPhoneValid = v;
  }

  validateEmail(v) {
    this.isEmailValid = v;
  }

  modify() {
    const payload = {
      firstName: this.patient.FirstName,
      midleName: this.patient.MiddleName,
      lastName: this.patient.Name,
      email: this.patient['emails.Email'],
      mobile: this.patient['mobiles.Number'],
      homePhone: this.patient['phones.Number'],
      title: this.patient.Salutation,
      gender: this.patient['Gender.Code'],
      dateOfBirth: null,
    };

    if (this._dateOfBirth) {
      payload.dateOfBirth = this._dateOfBirth;
    }

    if (this.patient['TableName'] == 'Prospect') {
      this.existingPatientService.editProspect(this.patient.ID, payload).subscribe((res) => {
        if (res) {
          if (res['LastModified']) {
            this.modifiedDate = res['LastModified'];
          }

          this.getResult.emit(res);
        }
      });
    } else {
      this.customerService.editCustomer(this.patient.ID, payload).subscribe((res) => {
        if (res) {
          if (res['LastModified']) {
            this.modifiedDate = res['LastModified'];
          }

          this.getResult.emit(res);
        }
      });
    }
  }
}
