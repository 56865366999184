import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { WizardComponent } from 'angular-archwizard';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { ExistingPatientService } from '../shared/existing-patient.service';

import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { HeaderService } from '../../../shared/services/header.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { CustomerProspectService } from '../../customer-prospect/shared/customerProspect.service';
import { InvitationLookupComponent } from '../../invitation/invitation-lookup/invitation-lookup.component';
import { SmsConversationComponent } from '../../message/sms-conversation/sms-conversation.component';
import { SmsPromoterComponent } from '../../message/sms-promoter/sms-promoter.component';

@Component({
  selector: 'app-existing-patient-create',
  templateUrl: './existing-patient-create.component.html',
  styleUrls: ['./existing-patient-create.component.css'],
})
export class ExistingPatientCreateComponent implements OnInit {
  existingPatient: any = {
    firstName: null,
    title: null,
    lastName: null,
    midleName: null,
    gender: null,
    mobile: null,
    homePhone: null,
    workPhone: null,
    email: null,
    suburb: null,
    state: null,
    streetNumber: null,
    streetName: null,
    unitNumber: null,
    streetTypeCode: null,
    latitude: null,
    longitude: null,
    postCode: null,
  };

  validateMobile = false;
  validatePhone = false;
  validateWorkPhone = false;
  validateEmail = false;
  isAdressValid = false;
  isReffered = false;
  patientID;
  merchantID;
  merchants = [];
  isPromoterOrAdmin = false;
  genders = [];
  titles = [];
  sessionType;
  hideMerchantSelection = false;

  @ViewChild(WizardComponent, { static: true }) public wizard: WizardComponent;

  constructor(
    private location: Location,
    private ExistingPatientService: ExistingPatientService,
    private lookupService: LookupService,
    private AuthenticationService: AuthenticationService,
    private merchantService: MerchantService,
    private existingPatientService: ExistingPatientService,
    private customerProspectService: CustomerProspectService,
    private router: Router
  ) {} // inject Location into class constructor

  ngOnInit() {
    this.AuthenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      this.AuthenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;
        if (this.isPromoterOrAdmin == true) {
          const payload = {
            fields: 'ID,TradingAs,CalculatedName,Status',
          };
          this.merchantService.getList(payload, this.isPromoterOrAdmin).subscribe((res) => {
            this.merchants = res;
          });

          const _mID = JSON.parse(localStorage.getItem('selectedMerchant'));

          if (_mID) {
            this.merchantID = _mID;
            // this.hideMerchantSelection = true;
          }

          HeaderService.getPromoterViewAsObject().subscribe((res) => {
            if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'promoter') {
              this.merchantID = null;
              this.hideMerchantSelection = false;
            } else if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'merchant') {
              this.merchantID = res['merchantID'];
              this.hideMerchantSelection = true;
            }
          });
        }
        this.lookupService.getLookup('CodeLookup', 'Gender').subscribe((data) => {
          this.genders = data;
        });
        this.lookupService.getLookup('SimpleLookup', 'Salutation').subscribe((data) => {
          this.titles = data;
        });
      });
    });
  }

  getAdress(adr) {
    if (adr) {
      this.existingPatient.streetName = adr.streetName;
      this.existingPatient.streetNumber = adr.streetNumber;
      this.existingPatient.streetTypeCode = adr.streetType;
      this.existingPatient.unitNumber = adr.unitNumber;
      this.existingPatient.latitude = adr.latitude;
      this.existingPatient.longitude = adr.longitude;
      this.existingPatient.suburb = adr.suburb;
      this.existingPatient.state = adr.state;
      this.existingPatient.postCode = adr.postCode;
    }
  }

  isAdressValidEvent(v) {
    this.isAdressValid = v || false;
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  viewMerchant() {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: this.merchantID,
      width: '600px',
    });
  }

  createExistingPatient() {
    if (this.merchantID) {
      this.existingPatient['merchantID'] = this.merchantID;
    }

    const lookupPayload = {
      email: this.existingPatient['email'],
      lastName: this.existingPatient['lastName'],
      firstName: this.existingPatient['firstName'],
      mobile: this.existingPatient['mobile'],
      phone: this.existingPatient['homePhone'],
      merchantID: this.merchantID,
    };

    this.customerProspectService.patientLookup(lookupPayload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res && res.length > 0) {
        const ref = RootAppComponent.dialog.open(InvitationLookupComponent, {
          data: {
            patients: res,
            hideFilter: true,
            showNextBtn: true,
          },
          width: '600px',
        });

        ref.componentInstance.patientChat.subscribe((res) => {
          if (res && res.cardID) {
            if (res.isPromoterOrAdmin == true) {
              const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
                data: {
                  merchantID: res.merchantID,
                  cardID: res.cardID,
                  singleChat: true,
                },
                panelClass: 'noCard',
                width: '500px',
              });
              ref2.componentInstance.close.subscribe((data) => {
                ref2.close();
              });

              ref2.componentInstance.getResult.subscribe((data) => {
                if (data) {
                  const ref3 = RootAppComponent.dialog.open(SmsConversationComponent, {
                    data,
                    panelClass: 'bigger-screen',
                    width: '70%',
                    height: '95vh',
                  });
                  const sub = ref3.componentInstance.close.subscribe((data) => {
                    ref3.close();
                  });
                }

                ref2.close();
              });
            } else {
              const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
                data: {
                  merchantID: res.merchantID,
                  cardID: res.cardID,
                  singleChat: true,
                },
                panelClass: 'bigger-screen',
                width: '70%',
                height: '95vh',
              });
              const sub = ref2.componentInstance.close.subscribe((data) => {
                ref2.close();
              });
            }
          }
        });
        ref.componentInstance.closeModal.subscribe((res) => {
          ref.close();
        });
        ref.componentInstance.detailedView.subscribe((res) => {
          ref.close();
        });
        ref.componentInstance.getSelectedPatient.subscribe((patient) => {
          if (patient && patient.ID) {
            if (patient['TableName']) {
              this.existingPatient['tableName'] = patient['TableName'];
              this.existingPatient['TableName'] = patient['TableName'];
            }
            const confirmDialog = new ConfirmDialog(
              'fas fa-info',
              'Are you sure?',
              'You are about to Edit this  patient ',
              'No',
              'Yes, Proceed'
            );
            const ref2 = RootAppComponent.dialog.open(ConfirmDialogComponent, {
              data: confirmDialog,
            });
            ref2.componentInstance.onConfirm.subscribe((res) => {
              if (res == false) {
                ref2.close();
              } else {
                this.customerProspectService
                  .modifyCustomerProspect(patient.ID, this.existingPatient, this.sessionType)
                  .subscribe((patient2) => {
                    if (patient2 && patient2['ID']) {
                      ref.close();
                      ref2.close();
                      ref2.afterClosed().subscribe((res) => {
                        this.patientID = patient2['ID'];
                        this.wizard.goToNextStep();
                      });
                    }
                  });
              }
            });
          }
        });

        ref.componentInstance.createNew.subscribe((res) => {
          if (res == true) {
            const confirmDialog = new ConfirmDialog(
              'fas fa-info',
              'Are you sure?',
              'You are about to create a new patient',
              'No',
              'Yes, Proceed'
            );
            const ref2 = RootAppComponent.dialog.open(ConfirmDialogComponent, {
              data: confirmDialog,
            });
            ref2.componentInstance.onConfirm.subscribe((res) => {
              if (res == false) {
                ref2.close();
              } else {
                this.existingPatientService
                  .addExistingPatient(this.existingPatient, this.isPromoterOrAdmin)
                  .subscribe((patient2) => {
                    if (patient2 && patient2['ID']) {
                      ref.close();
                      ref2.close();
                      ref2.afterClosed().subscribe((res) => {
                        this.patientID = patient2['ID'];
                        this.wizard.goToNextStep();
                      });
                    }
                  });
              }
            });
          } else {
            ref.close();
          }
        });
      } else {
        this.existingPatientService
          .addExistingPatient(this.existingPatient, this.isPromoterOrAdmin)
          .subscribe((patient) => {
            if (patient && patient['ID']) {
              this.patientID = patient['ID'];
              this.wizard.goToNextStep();
            }
          });
      }
    });
  }

  getMobile(m) {
    this.existingPatient['mobile'] = m;
  }

  getPhone(p) {
    this.existingPatient['homePhone'] = p;
  }

  getWorkPhone(p) {
    this.existingPatient['workPhone'] = p;
  }

  getEmail(e) {
    this.existingPatient['email'] = e;
  }

  validateMobileEvent(v) {
    this.validateMobile = v;
  }

  validateWorkPhoneEvent(v) {
    this.validateWorkPhone = v;
  }

  validatePhoneEvent(v) {
    this.validatePhone = v;
  }

  validateEmailEvent(v) {
    this.validateEmail = v;
  }

  selectDateToFilter(dateTo) {
    this.existingPatient['dateOfBirth'] = dateTo;
  }

  viewPatient() {
    if (this.patientID) {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['customer-profile', this.patientID],
          },
        },
      ]);
    }
  }

  resetData() {
    this.patientID = null;
    this.merchantID = null;

    this.existingPatient = {
      firstName: '',
      title: '',
      lastName: '',
      midleName: '',
      gender: '',
      mobile: '',
      homePhone: '',
      workPhone: '',
      email: '',
    };
  }
}
