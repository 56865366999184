import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'lodash';

@Pipe({
  name: 'pickObjectProperties',
})
export class PickObjectPropertiesPipe implements PipeTransform {
  constructor() {}

  transform(targetObjects: { [key: string]: any }[], properties: string[]) {
    try {
      const result = map(targetObjects, (targetObject) => {
        const finalObject = {};

        for (let index = 0; index < properties.length; index++) {
          finalObject[properties[index]] = targetObject[properties[index]];
        }

        return finalObject;
      });

      return result;
    } catch (e) {
      return targetObjects;
    }
  }
}
