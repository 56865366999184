import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-action-log',
  templateUrl: './patient-action-log.component.html',
  styleUrls: ['./patient-action-log.component.css'],
})
export class PatientActionLogComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
