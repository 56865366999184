<div class="error-page rel error">
  <!-- <mat-card class="error text-center"> -->

  <img class="character_error" />
  <!-- <div class="circle"></div> -->

  <h2 class="accent-color">{{ error.title }}</h2>

  <div class="errorContent" *ngIf="error.content">
    <p class="mb" [innerHTML]="error.content | safeHtml"></p>
  </div>
  <div class="errorContent" *ngIf="!error.content">
    <p>
      seems to be experiencing some technical difficulties at this time that are stopping you from proceeding.
      <br/>suggest you try again later or if you wish to discuss this issue with our technical team <br/>feel free to
      call <strong> {{ settings.contactPhone }} </strong> during office hours.
      <br/>
      <br/>
    </p>
  </div>

  <div class="drop-buttons">
    <button class="ml mr" *ngIf="!error.link" (click)="close()" mat-raised-button color="accent">Close</button>

    <button class="ml mr" *ngIf="displayContact == true" (click)="contactUs()" mat-raised-button color="primary">
      Contact Us
    </button>

    <button
      class="ml mr"
      *ngIf="error.link && error.link != 'reload'"
      (click)="openLink()"
      mat-raised-button
      color="accent"
    >
      Ok
    </button>
    <button
      class="ml mr"
      *ngIf="error.link && error.link == 'reload'"
      (click)="openLink()"
      mat-raised-button
      color="accent"
    >
      Continue
    </button>
  </div>

  <!-- </mat-card> -->
</div>
