import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { MarketingService } from '../shared/marketing.service';
import { MarketingUtilClass } from '../shared/marketingUtil';

@Component({
  selector: 'app-marketing-email-view-modal',
  templateUrl: './marketing-email-view-modal.component.html',
  styleUrls: ['./marketing-email-view-modal.component.css'],
})
export class MarketingEmailViewModalComponent implements OnInit {
  @Input()
  campaign;

  @Output()
  close = new EventEmitter();

  utilMarketing = new MarketingUtilClass();

  selectedTemplate;
  emailContent = null;
  emailSubject = null;

  constructor(
    private utilService: UtilsService,
    private marketingService: MarketingService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.campaign = data;
    }
  }

  ngOnInit() {
    this.selectedTemplate = this.campaign['Template.Tag'];

    this.utilService.previewMessageByTemplate(this.selectedTemplate).subscribe((res) => {
      if (res) {
        this.emailContent = res['Email.HTML'];
        this.emailSubject = res['Email.Subject'];
      } else {
        this.emailContent = null;
        this.emailSubject = null;
      }
    });
  }

  closeModal() {
    this.close.emit(true);
  }
}
