<div class="row clearfix">
  <div class="card-header primary-gradient-img clearfix inModal" *ngIf="isModal == true">
    <div class="row clearfix">
      <button class="btn-close btn-clear pull-right mat-button" (click)="closeModalEvent()">
        <mat-icon>clear</mat-icon>
      </button>

      <div class="row titleArea clearfix">
        <mat-icon class="pull-left fas fa-comment-dollar"></mat-icon>
        <h2 class="summary-header rm-m">Create Car</h2>
      </div>
    </div>
  </div>

  <div class="row clearfix full-width cont" *ngIf="disabled == false">
    <div class="row clearfix flex">
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Operator modification</h4>
          <hr/>
          <div class="row clearfix full-width">
            <mat-form-field class="full-width" [ngClass]="highlightField('Updating_Reason.Code')" appearance="outline">
              <mat-label>Reason</mat-label>
              <mat-select
                [(ngModel)]="pcDetail.updatedReason"
                [disabled]="disabled"
                placeholder="Reason"
                name="reason"
                required
              >
                <mat-option *ngFor="let reason of persCircumUpdReasons" [value]="reason.Code">
                  {{ reason.Label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Comments</h4>
          <hr/>
          <div class="row clearfix full-width">
            <mat-form-field
              class="full-width"
              [ngClass]="highlightField('Updating_Reason.Comment')"
              appearance="outline"
            >
              <mat-label>Leave a comment</mat-label>
              <textarea
                [(ngModel)]="pcDetail.updatedComment"
                [disabled]="disabled"
                matInput
                placeholder="Comment"
                name="comment"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Files</h4>
          <hr>
          <div class="row clearfix full-width">


            <app-file-uploader class=" text-center clearfix" [uploadDirectly]="true" [isDragable]="true" class="text-center" (onCompleteAll)="onCompleteAll($event)"
              [limit]="1"></app-file-uploader>


            <app-file-list class="compact" (remove)="removeFile($event)" [hasExtraInfo]="false" [canEdit]="true" [canAction]="false"
              [files]="[fileID]"></app-file-list>


          </div>
        </div>
      </div> -->
    </div>
  </div>

  <div class="row clearfix full-width cont">
    <div class="row clearfix flex">
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Vehicle Details</h4>
          <hr/>
          <div class="row clearfix full-width">
            <mat-form-field class="full-width" [ngClass]="highlightField('Info.Make')" appearance="outline">
              <mat-label>Make</mat-label>
              <input [(ngModel)]="pcDetail.carMake" [disabled]="disabled" matInput placeholder="Make"/>
            </mat-form-field>

            <mat-form-field class="full-width" [ngClass]="highlightField('Info.Model')" appearance="outline">
              <mat-label>Model</mat-label>
              <input [(ngModel)]="pcDetail.carModel" [disabled]="disabled" matInput placeholder="Model"/>
            </mat-form-field>

            <mat-form-field class="full-width" [ngClass]="highlightField('Info.Year')" appearance="outline">
              <mat-label>Year</mat-label>
              <input [(ngModel)]="pcDetail.carYear" [disabled]="disabled" matInput placeholder="Year"/>
            </mat-form-field>

            <mat-form-field class="full-width" [ngClass]="highlightField('Asset.Value')" appearance="outline">
              <mat-label>Value</mat-label>
              <input
                [(ngModel)]="pcDetail.assetValue"
                [disabled]="disabled"
                matInput
                placeholder="Value"
                currencyMask
              />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Vehicle Expense Allowance</h4>
          <hr/>
          <div class="row clearfix full-width">
            <mat-form-field class="full-width" [ngClass]="highlightField('Expense.Frequency')" appearance="outline">
              <mat-label>Frequency</mat-label>
              <mat-select [(ngModel)]="pcDetail.expenseFrequency" placeholder="Frequency" name="ExpenseFrequency">
                <mat-option *ngFor="let fre of frequencies" [value]="fre.Code">
                  {{ fre.Label }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width" [ngClass]="highlightField('Expense.Value')" appearance="outline">
              <mat-label>Licensing</mat-label>
              <input
                [(ngModel)]="pcDetail.licensingExpense"
                (ngModelChange)="licensingExpenseChangeEvent($event)"
                matInput
                placeholder="Amount"
                currencyMask
              />
            </mat-form-field>

            <mat-form-field class="full-width" [ngClass]="highlightField('Expense.Value')" appearance="outline">
              <mat-label>Insurance</mat-label>
              <input
                [(ngModel)]="pcDetail.insuranceExpense"
                (ngModelChange)="insuranceExpenseChangeEvent($event)"
                matInput
                placeholder="Amount"
                currencyMask
              />
            </mat-form-field>

            <mat-form-field class="full-width" [ngClass]="highlightField('Expense.Value')" appearance="outline">
              <mat-label>Maintenance</mat-label>
              <input
                [(ngModel)]="pcDetail.maintenanceExpense"
                (ngModelChange)="maintenanceExpenseChangeEvent($event)"
                matInput
                placeholder="Amount"
                currencyMask
              />
            </mat-form-field>

            <mat-form-field class="full-width" [ngClass]="highlightField('Expense.Value')" appearance="outline">
              <mat-label>Total Expense</mat-label>
              <input [(ngModel)]="pcDetail.expenseValue" matInput placeholder="Amount" currencyMask disabled/>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Financing</h4>
          <hr/>
          <div class="row clearfix full-width">
            <mat-form-field class="full-width" [ngClass]="highlightField('Info.Own')" appearance="outline">
              <mat-label>Is This Vehicle Owned Outright?</mat-label>
              <mat-select [(ngModel)]="pcDetail.infoOwn" [disabled]="disabled">
                <mat-option value="0"> No</mat-option>
                <mat-option value="1"> Yes</mat-option>
              </mat-select>
            </mat-form-field>

            <section *ngIf="pcDetail.infoOwn == '0'">
              <mat-form-field class="full-width" [ngClass]="highlightField('Info.Bank.Code')" appearance="outline">
                <mat-label>Lender</mat-label>
                <mat-select
                  [(ngModel)]="pcDetail.infoBankCode"
                  [disabled]="disabled"
                  placeholder="Issuer"
                  name="Issuer"
                >
                  <mat-option *ngFor="let bank of bankList" [value]="bank.ID">
                    {{ bank.Name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                class="full-width"
                [ngClass]="highlightField('Info.AmountInitiallyBorrowed')"
                appearance="outline"
              >
                <mat-label>Amount Borrowed</mat-label>
                <input
                  [(ngModel)]="pcDetail.infoAmountBorrowed"
                  [disabled]="disabled"
                  matInput
                  placeholder="Amount Borrowed"
                  currencyMask
                />
              </mat-form-field>

              <mat-form-field class="full-width" [ngClass]="highlightField('Liability.Value')" appearance="outline">
                <mat-label>Amount Outstanding</mat-label>
                <input
                  [(ngModel)]="pcDetail.liabilityValue"
                  [disabled]="disabled"
                  matInput
                  placeholder="Amount Outstanding"
                  currencyMask
                />
              </mat-form-field>

              <mat-form-field
                class="full-width"
                [ngClass]="highlightField('Info.RepaymentsTotal.Value')"
                appearance="outline"
              >
                <mat-label>Repayment Amount</mat-label>
                <input
                  [(ngModel)]="pcDetail.repaymentValue"
                  [disabled]="disabled"
                  matInput
                  placeholder="Repayment Amount"
                  currencyMask
                />
              </mat-form-field>

              <mat-form-field
                class="full-width"
                [ngClass]="highlightField('Info.RepaymentsTotal.Frequency')"
                appearance="outline"
              >
                <mat-label>Repayment Frequency</mat-label>
                <mat-select
                  [(ngModel)]="pcDetail.repaymentFrequency"
                  [disabled]="disabled"
                  placeholder="Frequency"
                  name="RepaymentFrequency"
                >
                  <mat-option *ngFor="let fre of frequencies" [value]="fre.Code">
                    {{ fre.Label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row clearfix mt" *ngIf="disabled == false">
    <button
      class="pull-right"
      *ngIf="isModal == false"
      [disabled]="!pcDetail.updatedReason"
      (click)="submit()"
      mat-raised-button
      color="accent"
    >
      Submit
    </button>
    <button
      class="pull-right"
      *ngIf="isModal == true"
      [disabled]="!pcDetail.updatedReason"
      (click)="create()"
      mat-raised-button
      color="accent"
    >
      Create
    </button>
  </div>
</div>
