import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { DentistService } from '../../dentist/shared/dentist.service';
import { MerchantService } from '../../merchant/shared/merchant.service';

@Component({
  selector: 'app-appointment-availability-list',
  templateUrl: './appointment-availability-list.component.html',
  styleUrls: ['./appointment-availability-list.component.css'],
})
export class AppointmentAvailabilityListComponent implements OnInit {
  @Input() merchantID: string;
  @Input() merchant;

  @Input() contacts: any[];
  @Input()
  title = 'Staff appointment availability';
  @Input()
  description = 'Manage your staffs availability for appointment along with their workings hours';
  isPromoterOrAdmin = false;
  sessionType = 'guest';
  filterName;

  constructor(
    private authenticationService: AuthenticationService,
    private dentistService: DentistService,
    private merchantService: MerchantService,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['merchantID']) {
        this.merchantID = params['merchantID'];
      }
      this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
        this.isPromoterOrAdmin = isPromoterOrAdmin;

        this.authenticationService.getSessionType().subscribe((sessionType) => {
          this.sessionType = sessionType;

          this.fetchMerchant(this.isPromoterOrAdmin, this.merchantID, this.sessionType);
        });
      });
    });
  }

  private fetchMerchant(isPromoterOrAdmin: boolean, merchantID: string, sessionType: any): void {
    if (isPromoterOrAdmin == true && merchantID) {
      this.merchantService.getMerchantDetails(merchantID, {}, sessionType).subscribe((merchant) => {
        if (merchant && merchant.ID) {
          this.updateMerchant(merchant);
          this.fetchContacts(isPromoterOrAdmin, merchantID, sessionType);
        }
      });
    } else {
      this.authenticationService.getCurrentPractice().subscribe((merchant) => {
        if (merchant && merchant.ID) {
          this.updateMerchant(merchant);
          this.fetchContacts(isPromoterOrAdmin, merchantID, sessionType);
        }
      });
    }
  }

  private updateMerchant(merchant): void {
    this.merchant = merchant;
    this.merchantID = merchant.ID;
  }

  private fetchContacts(isPromoterOrAdmin: boolean, merchantID: string, sessionType: any): void {
    const payload = {
      merchantID, // equivalent to merchantID: merchantID
      selectWorkingHours: true,
    };
    this.dentistService.getList(payload, isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        // console.log(res);
        this.contacts = res;
      }
    });
  }
}
