<div class="row text-center clearfix" *ngIf="filtered != true"
  [ngClass]="filtered == true  ?  ['white-background',' manual-selection-container'] : 'manual-selection-container'    ">
  <mat-card class="rel card mt clearfix mb full-width" id="subscription-plan" style="max-width: 900px; float: none">
    <div class="clearfix">
      <div class="card-header primary-gradient-img clearfix inModal" style="    margin: -24px -16px -20px !important;">
        <div class="row clearfix">
          <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeEvent()"
            tabIndex="-1">
            <mat-icon>clear</mat-icon>
          </button>
          <div class="row titleArea clearfix">
            <span style="position:relative;">
              <mat-icon class="pull-left fas fa-file-contract  patient-main-icon"></mat-icon>

              <mat-icon class=" fas fa-search primary-color" style="position: absolute;left: 37px;top: 45px;font-size: 1.8rem;"></mat-icon>

            </span>
            <h2 class="summary-header rm-m text-left">
              <!-- {{ beneficiary.CalculatedName}} -->
              {{title | translate}}

              <span class="subLabel text-left">
                {{description | translate}}
              </span>
            </h2>
          </div>


        </div>
      </div>
      <mat-dialog-content id="messageGroupCreaste" [ngClass]="{ 'no-modal-height': isModalView != true }">
        <form class="lookup-form" #lookupForm="ngForm">
          <div class="full-width clearfix ">
            <mat-form-field class="full-width" *ngIf="
              merchants && merchants.length > 0 &&  isPromoterOrAdmin == true
            " appearance="outline">
              <mat-label>Merchant</mat-label>

              <mat-select [(ngModel)]="merchantID" (ngModelChange)="changeMerchant()" name="merchantID"
                placeholder="Merchant">
                <mat-select-trigger>
                  <div class="full-width flex flex-cust" *ngIf="merchant && merchant.ID">
                    <app-card-picture class="sms-merchant-pic222 prod text-center pull-left mr" [hideZoomButton]="true"
                      [cardID]="merchant.ID" [hideZoomButton]="false" [tableName]="'Merchant'"
                      [LastModified]="merchant['LastModified']">
                    </app-card-picture>
                    <p class="rm-m third-party-p full-width pull-left">
                      {{ merchant.TradingAs }}
                    </p>
                    <button class="mr" (click)="$event.stopPropagation(); viewMerchant(merchantID)" mat-button
                      mat-icon-button>
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </div>

                  <div class="full-width flex flex-cust" *ngIf="!merchantID">

                    <p class=" full-width ">
                      <strong>All</strong>
                    </p>

                  </div>

                </mat-select-trigger>
                <mat-option [value]="null">
                  <div class="full-width flex flex-cust">
                    <p class=" full-width ">
                      <strong>All</strong>
                    </p>
                  </div>
                </mat-option>

                <hr>

                <mat-option [value]="currentMerchant.ID">
                  <div class="full-width flex flex-cust">
                    <app-card-picture class="sms-merchant-pic prod text-center pull-left mr" [hideZoomButton]="true"
                      [cardID]="currentMerchant.ID" [hideZoomButton]="false" [tableName]="'Merchant'"
                      [LastModified]="currentMerchant['LastModified']">
                    </app-card-picture>
                    <p class="rm-m third-party-p full-width pull-left">
                      <strong>Promoter</strong>
                    </p>

                    <button class="mr" (click)="$event.stopPropagation(); viewMerchant(currentMerchant['ID'])"
                      mat-button mat-icon-button>
                      <mat-icon style="width: 40px">visibility</mat-icon>
                    </button>
                  </div>
                </mat-option>

                <hr>


                <mat-option *ngFor="let s of merchants | OrderArrayObject: ['TradingAs']" [value]="s.ID">
                  <div class="full-width flex flex-cust">
                    <app-card-picture class="sms-merchant-pic222 prod text-center pull-left mr" [hideZoomButton]="true"
                      [cardID]="s.ID" [hideZoomButton]="false" [tableName]="'Merchant'"
                      [LastModified]="s['LastModified']">
                    </app-card-picture>
                    <p class="rm-m third-party-p full-width pull-left">
                      {{ s.TradingAs }}
                    </p>

                    <button class="mr" (click)="$event.stopPropagation(); viewMerchant(s['ID'])" mat-button
                      mat-icon-button>
                      <mat-icon style="width: 40px">visibility</mat-icon>
                    </button>
                  </div>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="full-width" *ngIf="customerSelected==false">




            <button class="btn pull-right" (click)="selectPatient()" mat-raised-button color="accent">
              {{ "KEYWORD.patient" | translate | titlecase }} Lookup <mat-icon class="sml">search</mat-icon>
            </button>



            <h3 class="sr-title  smb   text-left"> {{ "KEYWORD.patient" | translate | titlecase }} Search</h3>
            <p class="full-width sml text-left mb rm-mt">
              Search {{ "KEYWORD.patient" | translate | titlecase }}'s using one or more fields below with either full
              or
              partial data
            </p>

            <div class="full-width clearfix flex">
              <mat-form-field class="full-width mr" appearance="outline">
                <mat-label>First Name</mat-label>
                <input [(ngModel)]="partFirstName" matInput name="FirstName" placeholder="First Name" />
              </mat-form-field>

              <!-- <mat-form-field class="half-width sml smr" appearance="outline">
                <mat-label>Middle Name</mat-label>
                <input [(ngModel)]="partMiddleName" matInput name="LastName" placeholder="Middle Name" />
              </mat-form-field> -->

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Last Name</mat-label>
                <input [(ngModel)]="partLastName" matInput name="LastName" placeholder="Last Name" />
              </mat-form-field>
            </div>
            <div class="full-width clearfix flex">
              <mat-form-field class="full-width mr" appearance="outline">
                <mat-label>Mobile</mat-label>
                <input [(ngModel)]="partMobile" type="text" matInput placeholder="Mobile" name="partMobile"
                  pattern="\d{1,10}" />
              </mat-form-field>

              <mat-form-field class="full-width " appearance="outline">
                <mat-label>Email</mat-label>
                <input [(ngModel)]="partEmail" matInput name="Email" placeholder="Email" />
              </mat-form-field>
            </div>

          </div>
          <div class="full-width flex mb"
            *ngIf="customerSelected==true && customerID && selectedCustomer && selectedCustomer.ID">
            <mat-card class="full-width">

              <h3 class="sr-title   text-left">Selected {{'Customer' | translate}}</h3>


              <div class="full-width flex flex-cust">
                <app-card-picture class="sms-merchant-pics2225 prod text-center pull-left mr" [hideZoomButton]="true"
                  [cardID]="selectedCustomer.ID" [hideZoomButton]="false" [tableName]="'Customer'"
                  [LastModified]="selectedCustomer['LastModified']">
                </app-card-picture>
                <div class="full-width">



                  <p class="rm-m  full-width text-left">
                    <strong> {{ selectedCustomer.CalculatedName }}</strong>
                  </p>

                  <p class="rm-m  full-width text-left">
                    {{ selectedCustomer['emails.Email'] }}
                  </p>
                  <p class="rm-m full-width text-left">
                    {{ selectedCustomer['mobiles.Number'] | customPhone }}
                  </p>

                  <p class="rm-m full-width text-left">
                    {{ selectedCustomer['DateOfBirth'] | customPhone }}
                  </p>
                </div>


              </div>


              <button class="pull-right ml" style="margin-top: -30px !important;"
                (click)="$event.stopPropagation(); clearCustomer()" mat-mini-fab matTooltip="View profile"
                color="primary">
                <mat-icon class="fas fa-times"></mat-icon>
              </button>


              <button class="pull-right " style="margin-top: -30px !important;"
                (click)="$event.stopPropagation(); viewPatient(selectedCustomer['ID'])" matTooltip="Clear selection"
                mat-mini-fab color="accent">
                <mat-icon class="fas fa-eye"></mat-icon>
              </button>


            </mat-card>
          </div>

          <h3 class="sr-title  smb mt   text-left">
            Status
          </h3>

          <div class="full-width flex">

            <mat-form-field class="full-width mr" appearance="outline">
              <mat-label>Application Status</mat-label>
              <mat-select class="full-width" [(ngModel)]="statusCodes" name="statusCodes"
                placeholder="Application Status" multiple>

                <mat-option *ngFor="let row of defaultContractStatus" [value]="row.Code">
                  <strong class="chip chip-enable" [style.background]="row['Color']">
                    <mat-icon class="{{row['Icon']}} smr" style="font-size: 1rem !important;"></mat-icon>
                    {{row['Label']}}
                  </strong>
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="full-width " appearance="outline">
              <mat-label>Quotation Status</mat-label>
              <mat-select class="full-width" [(ngModel)]="quotationStatusCodes" name="quotationStatusCodes"
                placeholder="Quotation Status" multiple>

                <mat-option *ngFor="let row of defaultQuotationStatus" [value]="row.Code">
                  <strong class="chip chip-enable" [style.background]="row['Color']">
                    <mat-icon class="{{row['Icon']}} smr" style="font-size: 1rem !important;"></mat-icon>
                    {{row['Label']}}
                  </strong>
                </mat-option>
              </mat-select>
            </mat-form-field>


          </div>



          <h3 class="sr-title   mb mt   text-left">
            Advanced Search
          </h3>



          <div class="full-width">
            <mat-form-field class="full-width  " *ngIf="isPromoterOrAdmin == true" appearance="outline">
              <mat-label>Invitation ID, Wakanda ID or Wakanda Link. </mat-label>
              <input [(ngModel)]="invitationID" (ngModelChange)="checkInvitationID()" matInput
                name="invitationIDOrWakandaID" placeholder="Invitation ID" />
            </mat-form-field>
          </div>
          <div class="full-width flex">

            <mat-form-field class="full-width  mr" appearance="outline">
              <mat-label>Customer ID</mat-label>
              <input [(ngModel)]="customerID" matInput name="customerID" placeholder="Customer ID" />
            </mat-form-field>

            <mat-form-field class="full-width  " *ngIf="isPromoterOrAdmin == true" appearance="outline">
              <mat-label>Contract ID</mat-label>
              <input [(ngModel)]="contractID" matInput name="contractID" placeholder="Contract ID" />
            </mat-form-field>


          </div>

          <div class="full-width flex">
            <app-date-range-picker class="date-range-list-server full-width mr" [smallerInput]="false"
              [dateLabel]="'Application Date'" *ngIf="displayRange==true" [selectedRange]="selectedRange" (getRange)="setDateRangeFilter($event)">
            </app-date-range-picker>

            <mat-form-field class="half-width" appearance="outline">
              <mat-label>Limit results displayed</mat-label>
              <mat-select class="full-width" [(ngModel)]="maxRecords" name="maxRecords"
                placeholder="Limit results displayed:">
                <mat-option [value]="null">
                  <strong>No limit</strong>
                </mat-option>
                <mat-option *ngFor="let r of recordLength" [value]="r">
                  {{ r }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>


        </form>
      </mat-dialog-content>
    </div>

    <div class="full-width text-center smb smt">
      <hr class="mb" />


      <button class="btn-large pull-right" (click)="apply()" mat-raised-button color="accent">
        Search <mat-icon class="sml">keyboard_arrow_right</mat-icon>
      </button>


      <button class="btn-large  pull-right mr " (click)="clearAll()" mat-raised-button >
        <span class="primary-color"> Clear all  </span><mat-icon class="sml primary-color fas fa-times-circle"></mat-icon>
      </button>



      <button mat-raised-button color="primary" class="btn-large  pull-left " (click)="goToApplicationList()">
        All Applications
      </button>




    </div>


  </mat-card>
</div>

<div class="full-width " *ngIf="filtered == true" [ngClass]="filtered == true  ?  ['white-background'] : ''    ">

  <div class="clearfix" *ngIf="filtered == true">

    <mat-dialog-content id="contract-server-ID" [ngClass]="{ 'no-modal-height': isModalView != true }">


      <app-contract-list-global [merchantID]="merchantID" [partFirstName]="partFirstName" [partLastName]="partLastName"
        [quotationStatusCodes]="quotationStatusCodes" [statusCodes]="statusCodes" [dateTo]="dateTo"
        [dateFrom]="dateFrom" [contractID]="contractID" [invitationIDOrWakandaID]="invitationIDOrWakandaID"
        [maxRecords]="maxRecords" [partMobile]="partMobile" [customerID]="customerID">
      </app-contract-list-global>
    </mat-dialog-content>
    <div class="text-center full-width  ">
      <hr>

      <button class="btn-large mt " (click)="backSearch()" mat-raised-button color="accent">
        <mat-icon class="smr">keyboard_arrow_left</mat-icon> {{backLabel}}
      </button>
    </div>


  </div>


</div>
