<mat-card class="rel clearfix step-1-normal">
  <div class="card-header primary-gradient-img clearfix stacked" style=" margin-bottom: 0;">
    <button class="btn-close btn-clear mat-button smt white custom-close" *ngIf="isModal == true"
      (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="row clearfix">
      <div class="row titleArea clearfix">
        <h2 class="summary-header text-left rm-m">Bulk {{messageLabel}} Report</h2>
      </div>
    </div>
  </div>
  <mat-dialog-content id="message-group-view-component" *ngIf="messageGroup && messageGroup.ID"
    [ngClass]="{ 'no-modal-height': isModal != true }">

    <div class="full-widh">
      <div class="full-width" style="float: none !important; "></div>
      <div class="full-width flex">
        <mat-card class=" full-width      " style="    background: #f9f9f9;">




          <button (click)="$event.stopPropagation();" *ngIf="noAction!=true" class="pull-right"
            [matMenuTriggerFor]="menu2222" color="accent" mat-raised-button matTooltip="Actions">
            <span>Actions</span>
            <mat-icon class="sml">more_vert</mat-icon>
          </button>
          <mat-menu #menu2222="matMenu">
            <button *ngIf="messageGroup['Status.Label'] ==='Draft'" (click)="continue()" mat-menu-item>
              <mat-icon class="fas fa-edit smr"></mat-icon> Continue Editing
            </button>

            <button (click)="clone()" mat-menu-item>
              <mat-icon class="fas fa-clone smr"></mat-icon> Clone
            </button>



            <hr *ngIf=" messageGroup['Status.Label']==='Sending' || messageGroup['Status.Label']==='Sending'">


            <button *ngIf="messageGroup['Status.Label']==='Sending'" (click)="changeStatus('Paused')" mat-menu-item>
              <mat-icon class="fas fa-pause smr" [ngStyle]="{ 'color': messageUtil.getStatusColor('Paused', true)  }">
              </mat-icon><strong [ngStyle]="{ 'color':  messageUtil.getStatusColor('Paused', true)  }"> Pause</strong>
            </button>

            <button *ngIf="messageGroup['Status.Label']==='Paused'" (click)="changeStatus('Sending')" mat-menu-item>
              <mat-icon class="fas fa-play smr" [ngStyle]="{ 'color': messageUtil.getStatusColor('Sending', true)  }">
              </mat-icon> <strong [ngStyle]="{ 'color':  messageUtil.getStatusColor('Sending', true) }"> Resume</strong>
            </button>

            <button
              *ngIf="messageGroup['Status.Label']==='Pending' || messageGroup['Status.Label']==='Sending' || messageGroup['Status.Label']==='Paused' || messageGroup['Status.Label']==='Scheduled'"
              (click)="changeStatus('Complete')" mat-menu-item>
              <mat-icon class=" {{messageUtil.getStatusIcon('Complete') }} smr"
                [ngStyle]="{ 'color': messageUtil.getStatusColor('Complete', true)  }">
              </mat-icon> <strong [ngStyle]="{ 'color':  messageUtil.getStatusColor('Complete', true) }">
                Complete</strong>
            </button>

            <hr *ngIf="canApplyStatus('Canceled')">

            <button *ngIf="canApplyStatus('Canceled')" (click)="changeStatus('Canceled')" mat-menu-item>
              <mat-icon class="fas fa-ban smr" [ngStyle]="{ 'color': messageUtil.getStatusColor('Canceled', true) }">
              </mat-icon> <strong [ngStyle]="{ 'color':  messageUtil.getStatusColor('Canceled', true)  }">
                Cancel</strong>
            </button>

            <hr *ngIf="messageGroup && messageGroup['Status.Code'] && messageGroup['Status.Code']==='Complete' ">
            <button *ngIf="messageGroup && messageGroup['Status.Code'] && messageGroup['Status.Code']==='Complete' "
              (click)="$event.stopPropagation();hardRefresh()" mat-menu-item>
              <mat-icon class="fas fa-redo-alt smr"></mat-icon>
              Hard Refresh
            </button>


            <hr>
            <button (click)="remove()" mat-menu-item>
              <mat-icon class="fas fa-trash smr" style="color:red;"></mat-icon> <span style="color:red;"> Delete</span>
            </button>


          </mat-menu>



          <button (click)="refreshMessageGroup()" class="pull-right mr" color="primary" mat-raised-button>

            <mat-icon class="fas fa-sync-alt smr"></mat-icon> Refresh
          </button>


          <h3 class="sr-title text-left rm-mt    ">
            <span [ngStyle]="{ 'background-color':  messageGroup['Status.Color']  }"
              (click)="displayStatusGroupDescription(s)" class="status-container title-status mr  ">
              <mat-icon class="{{messageUtil.getStatusIcon(messageGroup['Status.Label'])}} smr "
                style="font-size: 1.2rem;width: 0.9rem;height: 0.9rem;">
              </mat-icon>
              {{messageGroup['Status.Label']}}
              <span class="sml" *ngIf="messageGroup['Status.Label']==='Scheduled'">
                : {{messageGroup['Date_To_be_Sent'] | customDateTime}}
              </span>
            </span>
            Sending Progress <strong> ({{progress}}%)</strong>
          </h3>
          <div class="full-width  mt flex" *ngIf="hideProgress!=true">
            <span style="color:#656565 !important; font-size:0.8rem !important;width: 40px !important;">
              <mat-icon style="color:#656565 !important; " class="fas fa-stopwatch "></mat-icon>
              <strong>0%</strong>
            </span>
            <mat-progress-bar *ngIf="progressClass==='progress-bar-message-0'"
              class="full-width  progress-bar-message-0  progress-bar-message " mode="buffer" [value]="progress"
              [bufferValue]="progress">
            </mat-progress-bar>
            <mat-progress-bar *ngIf="progressClass==='progress-bar-message-10'"
              class="full-width  progress-bar-message-10   progress-bar-message " mode="buffer" [value]="progress"
              [bufferValue]="progress">
            </mat-progress-bar>
            <mat-progress-bar *ngIf="progressClass==='progress-bar-message-20'"
              class="full-width   progress-bar-message-20  progress-bar-message " mode="buffer" [value]="progress"
              [bufferValue]="progress">
            </mat-progress-bar>
            <mat-progress-bar *ngIf="progressClass==='progress-bar-message-30'"
              class="full-width  progress-bar-message-30   progress-bar-message " mode="buffer" [value]="progress"
              [bufferValue]="progress">
            </mat-progress-bar>
            <mat-progress-bar *ngIf="progressClass==='progress-bar-message-40'"
              class="full-width   progress-bar-message-40  progress-bar-message " mode="buffer" [value]="progress"
              [bufferValue]="progress">
            </mat-progress-bar>
            <mat-progress-bar *ngIf="progressClass==='progress-bar-message-50'"
              class="full-width    progress-bar-message-50  progress-bar-message " mode="buffer" [value]="progress"
              [bufferValue]="progress">
            </mat-progress-bar>
            <mat-progress-bar *ngIf="progressClass==='progress-bar-message-60'"
              class="full-width   progress-bar-message-60  progress-bar-message " mode="buffer" [value]="progress"
              [bufferValue]="progress">
            </mat-progress-bar>
            <mat-progress-bar *ngIf="progressClass==='progress-bar-message-70'"
              class="full-width  progress-bar-message-70   progress-bar-message " mode="buffer" [value]="progress"
              [bufferValue]="progress">
            </mat-progress-bar>
            <mat-progress-bar *ngIf="progressClass==='progress-bar-message-80'"
              class="full-width   progress-bar-message-80  progress-bar-message " mode="buffer" [value]="progress"
              [bufferValue]="progress">
            </mat-progress-bar>
            <mat-progress-bar *ngIf="progressClass==='progress-bar-message-90'"
              class="full-width  progress-bar-message-90   progress-bar-message " mode="buffer" [value]="progress"
              [bufferValue]="progress">
            </mat-progress-bar>
            <mat-progress-bar *ngIf="progressClass==='progress-bar-message-100'"
              class="full-width  progress-bar-message-100   progress-bar-message " mode="buffer" [value]="progress"
              [bufferValue]="progress">
            </mat-progress-bar>
            <mat-progress-bar *ngIf="progressClass==='progress-bar-message-pause'"
              class="full-width   progress-bar-message-pause  progress-bar-message " mode="buffer" [value]="progress"
              [bufferValue]="progress">
            </mat-progress-bar>
            <mat-progress-bar *ngIf="progressClass==='progress-bar-message-error'"
              class="full-width   progress-bar-message-error  progress-bar-message " mode="buffer" [value]="progress"
              [bufferValue]="progress">
            </mat-progress-bar>

            <span *ngIf="progress<100"
              style="color:#656565 !important;font-size:0.8rem !important;width: 40px !important;">
              <mat-icon style="color:#656565 !important; " class="fas fa-paper-plane "></mat-icon>
              <strong>100%</strong>
            </span>

            <span *ngIf="progress>=100"
              style="color:#27ae60 !important;font-size:0.8rem !important;width: 40px !important;">
              <mat-icon style="color:#27ae60 !important; " class="fas fa-paper-plane "></mat-icon>
              <strong>100%</strong>
            </span>

          </div>
        </mat-card>
      </div>
      <div class="full-width mt  flex">
        <mat-card class="full-width">
          <div class="full-width ">
            <h3 class="sr-title text-left  rm-m  full-width">
              {{messageGroup['Label']}}
            </h3>
            <p class=" text-left  full-width   smt   rm-mb " *ngIf="messageGroup['Description']">
              {{messageGroup['Description']}}
            </p>
            <div class="full-width mb">
              <h3 class="sr-title text-left   smb full-width "
                *ngIf="messageGroup && messageGroup.contact && messageGroup.contact.CalculatedName">
                Sender
              </h3>
              <p class="  text-left  full-width      rm-m smb sender"
                *ngIf="messageGroup && messageGroup.contact && messageGroup.contact.CalculatedName"
                (click)="viewDentist(messageGroup.Sender_key)">
                {{messageGroup.contact.CalculatedName}}</p>
              <hr class="full-width">
              <div class="full-width mb flex">
                <div class="full-width mr ">
                  <h3 class="sr-title text-left   smb full-width ">
                    Date Created
                  </h3>
                  <p class=" full-width   rm-m " *ngIf="messageGroup['DateTimeCreated']"
                    matTooltip="{{ messageGroup['DateTimeCreated'] |  TimeLabelPast }} ago">
                    {{  messageGroup['DateTimeCreated'] | customDateTime : 'YYYY-MM-DD HH:mm:ss'}}
                  </p>
                  <p class=" full-width    rm-m" *ngIf="!messageGroup['DateTimeCreated']">
                    ...
                  </p>
                </div>
                <div class="full-width mr ">
                  <h3 class="sr-title text-left   smb full-width ">
                    Date Submitted
                  </h3>
                  <p class=" full-width   rm-m " *ngIf="messageGroup['Submit_DateTime']"
                    matTooltip="{{ messageGroup['Submit_DateTime'] | TimeLabelPast }} ago">
                    {{   messageGroup['Submit_DateTime'] | customDateTime : 'YYYY-MM-DD HH:mm:ss' }}
                  </p>
                  <p class=" full-width    rm-m" *ngIf="!messageGroup['Submit_DateTime']">
                    ...
                  </p>
                </div>
                <div class="full-width mr ">
                  <h3 class="sr-title text-left   smb full-width ">
                    Date Completed
                  </h3>
                  <p class=" full-width    rm-m" *ngIf="messageGroup['Finshed_DateTime']"
                    matTooltip="{{ messageGroup['Finshed_DateTime'] |  TimeLabelPast}} ago">
                    {{ messageGroup['Finshed_DateTime']  | customDateTime: 'YYYY-MM-DD HH:mm:ss'  }}
                  </p>
                  <p class=" full-width    rm-m" *ngIf="!messageGroup['Finshed_DateTime']">
                    ...
                  </p>
                </div>
              </div>
              <hr class="full-width">

              <h3 class="sr-title text-left   full-width   mb smt  "
                *ngIf="messageGroup && messageGroup.marketingConsent==true">
                <strong> Marketing related bulk {{messageLabel}}: </strong>

                <span></span>
                <mat-icon class="fas fa-check-circle  "
                  style="font-size: 1.4rem;color: #2ecc71;height: 1.4rem;width: 1.4rem;margin-left: 5px !important;">
                </mat-icon>

              </h3>



              <h3 class="sr-title text-left     full-width  mb smt "
                *ngIf="messageGroup && messageGroup.marketingConsent!=true">
                <strong> Marketing related bulk {{messageLabel}}: </strong>

                <mat-icon class="fas fa-times-circle  "
                  style="font-size: 1.4rem;color: red;height: 1.4rem;width: 1.4rem;margin-left: 5px !important;">
                </mat-icon>
              </h3>

              <h3 class="sr-title text-left  smt full-width  smb"
                *ngIf="messageGroup && messageGroup.tags && messageGroup.tags.length>0">
                Tags
              </h3>
              <mat-chip-list class="full-width  "
                *ngIf="messageGroup && messageGroup.tags && messageGroup.tags.length>0">
                <mat-chip class="mr pull-left" *ngFor="let tag of messageGroup.tags">{{ tag  }}
                </mat-chip>
              </mat-chip-list>
              <h3 class="sr-title text-left   smb full-width "
                *ngIf="messageGroup && messageGroup.filterKeys && messageGroup.filterKeys.length>0">
                Audiences
              </h3>
              <mat-chip-list class="full-width  "
                *ngIf="messageGroup && messageGroup.filterKeys && messageGroup.filterKeys.length>0">
                <mat-chip class="mr pull-left" *ngFor="let tag of messageGroup.filterKeys">{{ tag.Label  }}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </mat-card>
        <mat-card class=" qtr-width  ml    preview-card-container  " style="    background: #f9f9f9;">
          <h3 class="sr-title text-left   full-width  smb">
            Targets Summary
          </h3>
          <mat-list class="statistics">
            <mat-list-item *ngIf="messageGroup.selectAll==true && messageGroup.marketingConsent!=true">
              <div class="full-width flex">
                <mat-icon class="iocon fas fa-user-check" matListIcon></mat-icon>

                <p class="full-width flex " mdLine>
                  <span class="labelName full-width"> All
                    {{"Patient" | translate}}s</span>

                  <span class=" full-width text-right  bold value " style=" color: #2ecc71;">
                    <mat-icon class="fas fa-check-circle " style="font-size: 1.4rem;  color: #2ecc71;"></mat-icon>
                  </span>

                </p>
              </div>
            </mat-list-item>

            <mat-list-item *ngIf="messageGroup.selectAll==true && messageGroup.marketingConsent==true">
              <div class="full-width flex">
                <mat-icon class="iocon fas fa-user-check" matListIcon></mat-icon>

                <p class="full-width flex " mdLine>
                  <span class="labelName full-width">All {{"Patient" | translate}}s who
                    consented to marketing messages</span>

                  <span class="  text-right  bold value " style="width: 30px !important; color: #2ecc71;">
                    <mat-icon class="fas fa-check-circle " style="font-size: 1.4rem;    margin-top: 10px;  color: #2ecc71;"></mat-icon>
                  </span>

                </p>
              </div>
            </mat-list-item>




            <mat-list-item>
              <div class="full-width flex">
                <mat-icon class="iocon fas fa-user-check" matListIcon></mat-icon>
                <p class="full-width flex " mdLine>
                  <span class="labelName full-width">{{'Patient' | translate}}s Query </span>
                  <span class=" half-width text-right  bold value">
                    {{ messageGroup['Target_SelectedIDs_Number']  || 0 }}
                  </span>
                </p>
              </div>
            </mat-list-item>
            <mat-list-item>
              <div class="full-width flex">
                <mat-icon class="iocon fas fa-tags" matListIcon></mat-icon>
                <p class="full-width flex " mdLine>
                  <span class="labelName full-width"> Tags</span>
                  <span class=" half-width text-right bold  value">
                    {{ messageGroup['Target_Tags_Number']  || 0  }}
                  </span>
                </p>
              </div>
            </mat-list-item>
            <mat-list-item>
              <div class="full-width flex">
                <mat-icon class="iocon fas fa-filter" matListIcon></mat-icon>
                <p class="full-width flex " mdLine>
                  <span class="labelName full-width "> Audiences</span>
                  <span class=" half-width text-right  bold value">
                    {{ messageGroup['Target_Filters_Number']  || 0  }}
                  </span>
                </p>
              </div>
            </mat-list-item>
            <mat-list-item>
              <div class="full-width flex">
                <mat-icon class="iocon fas fa-ban" style="    background: rgb(220, 20, 60);" matListIcon></mat-icon>
                <p class="full-width flex " style="color:rgb(220, 20, 60);" mdLine>
                  <span class="labelName full-width"> Excluded {{'Patient' | translate}}s</span>
                  <span class=" half-width text-right bold  value">
                    {{ messageGroup['Target_ExcludedIDs_Number']  || 0 }}
                  </span>
                </p>
              </div>
            </mat-list-item>
            <mat-list-item>
              <div class="full-width flex">
                <mat-icon class="iocon fas fa-users" matListIcon></mat-icon>
                <p class="bold full-width flex" mdLine>
                  <span class="labelName full-width">
                    Potential recipients
                  </span>
                  <span class=" half-width text-right  value">
                    <span class="badge  pull-right ml master" style="
                              font-size: 1rem;
                              padding-left: 10px;
                              padding-right: 10px;
                              font-weight: 600;
                              margin-top: 3px;
                              margin-right: 0px !important; " [endVal]="messageGroup['Target_Number']  || 0"
                      countUp></span>
                  </span>
                </p>
              </div>
            </mat-list-item>
          </mat-list>
          <hr
            *ngIf="(messageGroup['selectedIDs'] && messageGroup['selectedIDs'].length>0) ||(messageGroup['ExcludedIDs'] && messageGroup['ExcludedIDs'].length>0)">
          <div class="full-width"
            *ngIf="(messageGroup['selectedIDs'] && messageGroup['selectedIDs'].length>0) ||(messageGroup['ExcludedIDs'] && messageGroup['ExcludedIDs'].length>0)">
            <h3 class="sr-title text-left   full-width  smb"
              *ngIf="(messageGroup['selectedIDs'] && messageGroup['selectedIDs'].length>0) ||(messageGroup['ExcludedIDs'] && messageGroup['ExcludedIDs'].length>0)">
              Preview:
            </h3>
            <div class="full-width">
              <button class=" btn  pull-left  primary-color   mr   " style="background:#fff !important;"
                *ngIf="messageGroup['selectedIDs'] && messageGroup['selectedIDs'].length>0" (click)="preview('manual')"
                mat-raised-button color="primary">
                <span> {{'Patient' | translate}}s Query <strong
                    class="sml">({{messageGroup['selectedIDs'].length}})</strong></span>
              </button>
              <button class=" btn  pull-left  primary-color  mr   " style="background:#fff !important;"
                *ngIf="messageGroup['ExcludedIDs'] && messageGroup['ExcludedIDs'].length>0"
                (click)="preview('excluded',true)" mat-raised-button color="primary">
                <span>
                  Excluded <strong class="sml">({{messageGroup['ExcludedIDs'].length}})</strong></span>
              </button>
            </div>
          </div>
          <div class="full-width" style="float: none !important; "></div>
        </mat-card>
      </div>
      <div class="full-width  mt " *ngIf="isQuickView == true">
        <h3 class="sr-title text-left smb  full-width">
          Queue List & preview
        </h3>

        <p>
          The "Queue Message List" allows you to view messages queued for delivery. Click on action buttons to preview
          each message, check recipient details, or cancel the message, providing you with convenient control and
          insights
          into the queued messaging process.
        </p>
      </div>
      <div class="full-width " [ngClass]="{ 'flex': isQuickView == true }">
        <div class="full-width    mt mb" [ngClass]="{ 'flex': isQuickView != true }"
          *ngIf="(chartVisitorDataLocal && chartVisitorDataLocal.length>0) || (chartVisitorData && chartVisitorData.length>0)">
          <div class="full-width flex">
            <app-empty-list-message class="mr full-width report-empty"
              *ngIf="!chartVisitorDataLocal || (chartVisitorDataLocal && chartVisitorDataLocal.length<=0)"
              style=" height: 297px; " [message]="'No Queue Report found'" [message]="'No messages found in the Queue'">
            </app-empty-list-message>
            <mat-card class="full-width mat-elevation-z3 mr "
              *ngIf="chartVisitorDataLocal && chartVisitorDataLocal.length>0" style=" height: 297px; ">
              <div class="colour-stripe"></div>
              <div class="full-width flex">
                <div class="chart-view full-width">
                  <div class="row clearfix">
                    <h3 class="sr-title text-center ">
                      Queue Status
                    </h3>

                  </div>
                  <div
                    class="base-chart-margin base-chart-margin-container base-chart-margin-container-msg-group rel mr">
                    <div class="total anim">
                      <span class="totLab">Total</span>
                      <span class="balance" [endVal]="totalMessagesLocal" countUp></span>
                      <!-- <span class="totSub">Total</span> -->
                    </div>
                    <app-chart-input class="fullHeight visitor-charts mb clearfix" [titleIsTop]="false"
                      [showLegend]="false" [colorScheme]="colorSchemeLocal" [legendPosition]="'right'"
                      [showLabel]="false" [chartType]="'pie'" [title]="" [data]="chartVisitorDataLocal"
                      [dataNameAttribute]="'name'" [dataNameAttribute]="'value'">
                    </app-chart-input>
                  </div>
                </div>
                <div class=" full-width  ml    preview-card-container-legend  ">
                  <div class="full-width flex smb legend-holder" *ngFor="let s of chartVisitorDataLocal"
                    (click)="viewMessageList(s.code,null)" matTooltip="Click to check  messages list ">
                    <div class="full-width">
                      <p class="small ml pull-left " style="  padding-top: 5px; ">
                        <mat-icon class="{{messageUtil.getStatusIcon(s.code)}} smr"
                          style="font-size: 0.9rem;opacity: 0.8;"
                          [ngStyle]="{ 'color':  messageUtil.getStatusColor(s.code,true)  }"></mat-icon> <strong>
                          {{s.name}}</strong>
                      </p>
                    </div>
                    <p class="small  full-width text-right ">
                      <span class="pull-right mr status-container "
                        [ngStyle]="{ 'background-color':  messageUtil.getStatusColor(s.code,true)  }">
                        {{s.value}}
                      </span>
                    </p>
                  </div>
                  <!-- <p class="chart-total-text pull-right"> Total: <span [endVal]="totalMessages" countUp></span></p> -->
                </div>
              </div>
            </mat-card>
          </div>
          <div class="full-width flex" [ngClass]="{ 'quick-view-panel': isQuickView == true }">
            <app-empty-list-message class=" full-width report-empty"
              *ngIf="!chartVisitorData || (chartVisitorData && chartVisitorData.length<=0)" style=" height: 297px; "
              [message]="'No Delivery Report found'">
            </app-empty-list-message>
            <mat-card class="full-width mat-elevation-z3 panel-chart-view  "
              *ngIf="chartVisitorData && chartVisitorData.length>0" style=" height: 297px; ">
              <div class="colour-stripe"></div>
              <div class="full-width flex">
                <div class="chart-view full-width">
                  <div class="row clearfix">
                    <h3 class="sr-title text-center ">
                      Messages Delivery Status
                    </h3>

                  </div>
                  <div
                    class="base-chart-margin base-chart-margin-container base-chart-margin-container-msg-group rel mr">
                    <div class="total anim">
                      <span class="totLab">Total</span>
                      <span class="balance" [endVal]="totalMessages" countUp></span>
                      <!-- <span class="totSub">Total</span> -->
                    </div>
                    <app-chart-input class="fullHeight visitor-charts mb clearfix" [titleIsTop]="false"
                      [showLegend]="false" [colorScheme]="colorScheme" [legendPosition]="'right'" [showLabel]="false"
                      [chartType]="'pie'" [title]="" [data]="chartVisitorData" [dataNameAttribute]="'name'"
                      [dataNameAttribute]="'value'">
                    </app-chart-input>
                  </div>
                </div>
                <div class=" full-width  ml    preview-card-container-legend  ">
                  <div class="full-width flex smb legend-holder" *ngFor="let s of chartVisitorData"
                    (click)="viewMessageList(null,s.code)" matTooltip="Click to check  messages list ">
                    <div class="full-width">
                      <p class="small ml pull-left " style="  padding-top: 5px; ">
                        <mat-icon class="{{messageUtil.getMessageStatusIcon(s.code)}} smr"
                          style="font-size: 0.9rem;opacity: 0.8;"
                          [ngStyle]="{ 'color':  messageUtil.getStatusColor(s.code,false)  }"></mat-icon>

                        <strong>
                          {{s.name}}</strong>
                      </p>
                    </div>
                    <p class="small  full-width text-right ">
                      <span class="pull-right mr status-container "
                        [ngStyle]="{ 'background-color':  messageUtil.getStatusColor (s.code,false)  }">
                        {{s.value}}
                      </span>
                    </p>
                  </div>
                  <!-- <p class="chart-total-text pull-right"> Total: <span [endVal]="totalMessages" countUp></span></p> -->
                </div>
              </div>
            </mat-card>
          </div>
        </div>
        <div class="mobile-preview-width ml  " *ngIf="isQuickView===true && message">




          <app-sms-preview class="full-width  mt " *ngIf="message" [message]="message" [card]="card" [isModal]="false">
          </app-sms-preview>


          <div class="full-width text-center" style="margin-top: -104px;">

            <button class=" btn  lookup-botton " (click)="selectPatient()" matTooltip="Choose Patient To Preview"
              mat-raised-button color="primary">
              <mat-icon class="fas fa-user smr" style="font-size:1rem !important;height: 1rem !important;"></mat-icon>
              Lookup
            </button>
          </div>
        </div>

      </div>
      <div class="full-width flex text-center  mb" *ngIf="isQuickView!==true">
        <app-message-group-local-list class="full-width mt mr  message-group-local-list-container "
          [multipSelect]="messageUtil.canUpdateMessageGroupStatus(messageGroup['Status.Label'],'Canceled')"
          [hideHeader]="true" *ngIf="messageGroupID" [messageGroupID]="messageGroupID"
          (getRefresh)="refreshStatistics()">
        </app-message-group-local-list>
        <div class="mobile-preview-width ml  ">




          <app-sms-preview class="full-width  mt " *ngIf="message" [message]="message" [card]="card" [isModal]="false">
          </app-sms-preview>


          <div class="full-width text-center" style="margin-top: -104px;">

            <button class=" btn  lookup-botton " (click)="selectPatient()" matTooltip="Choose Patient To Preview"
              mat-raised-button color="primary">
              <mat-icon class="fas fa-user smr" style="font-size:1rem !important;height: 1rem !important;"></mat-icon>
              Lookup
            </button>
          </div>
        </div>

      </div>
      <mat-expansion-panel class="full-width  advanced-search" [expanded]="true">
        <mat-expansion-panel-header style="height: 80px !important">
          <mat-panel-title>
            <p class="full-width text-left sml mb   smt">
              <strong class="smr">SMS Statuses: </strong> see the list below for a better understanding of the different
              types of
              statuses. Click on each icon for a detailed explanation.
            </p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="full-width flex mt full-width full-width status-holder">
          <div class="full-width flex">
            <div class="qtr-width" style="    background: #f9f9f9; padding-right: 10px; padding-left: 10px ;"
              *ngIf="isSMS==true">
              <h3 class="sr-title text-left full-width  ">
                Campaign Status
              </h3>
              <div class="full-width mr">
                <p class="full-width smb rm-mt " *ngFor="let s of splitCampaginStatusArray(1)">
                  <span [ngStyle]="{ 'background-color':  messageUtil.getStatusColor (s,true)  }"
                    (click)="displayStatusGroupDescription(s)" class="status-container">
                    <mat-icon class="{{messageUtil.getStatusIcon(s)}} smr "
                      style="font-size:  0.9em;width: 0.9rem;height: 0.9rem;">
                    </mat-icon> {{s}}
                  </span>
                </p>
              </div>
            </div>
            <div class="qtr-width"
              style="    background: #f9f9f9; padding-right: 10px; padding-left: 10px ;     padding-top: 53px;"
              *ngIf="isSMS==true">

              <div class="full-width mr">
                <p class="full-width smb rm-mt " *ngFor="let s of splitCampaginStatusArray(2)">
                  <span [ngStyle]="{ 'background-color':  messageUtil.getStatusColor (s,true)  }"
                    (click)="displayStatusGroupDescription(s)" class="status-container">
                    <mat-icon class="{{messageUtil.getStatusIcon(s)}} smr "
                      style="font-size:  0.9em;width: 0.9rem;height: 0.9rem;">
                    </mat-icon> {{s}}
                  </span>
                </p>
              </div>
            </div>
            <div class="qtr-width" style="    background: #f9f9f9; padding-right: 10px; padding-left: 10px ;"
              *ngIf="isSMS!=true">
              <h3 class="sr-title text-left full-width  ">
                Campaign Status
              </h3>
              <div class="full-width mr">
                <p class="full-width smb rm-mt " *ngFor="let s of messageGroupStatus">
                  <span [ngStyle]="{ 'background-color':  messageUtil.getStatusColor (s,true)  }"
                    (click)="displayStatusGroupDescription(s)" class="status-container">
                    <mat-icon class="{{messageUtil.getStatusIcon(s)}} smr "
                      style="font-size:  0.9em;width: 0.9rem;height: 0.9rem;">
                    </mat-icon> {{s}}
                  </span>
                </p>
              </div>
            </div>
            <div class="full-width ">
              <h3 class="sr-title ml text-left full-width  ">
                Message Status
              </h3>
              <div class="full-width  flex">
                <div class="full-width  " style="border-left: 1px solid #909090;">
                  <p class="full-width smb rm-mt ml " *ngFor="let s of statusMessageArray1">
                    <span [ngStyle]="{ 'background-color':  messageUtil.getStatusColor (s,false)  }"
                      (click)="displayStatusDescription(s)" class="status-container">
                      <mat-icon class="{{messageUtil.getMessageStatusIcon(s)}} smr "
                        style="font-size:  0.9em;width: 0.9rem;height: 0.9rem;">
                      </mat-icon> {{s}}
                    </span>
                  </p>
                </div>
                <div class="full-width ">
                  <p class="full-width smb rm-mt  " *ngFor="let s of statusMessageArray2">
                    <span [ngStyle]="{ 'background-color':  messageUtil.getStatusColor (s,false)  }"
                      (click)="displayStatusDescription(s)" class="status-container">
                      <mat-icon class="{{messageUtil.getMessageStatusIcon(s)}} smr "
                        style="font-size:  0.9em;width: 0.9rem;height: 0.9rem;">
                      </mat-icon> {{s}}
                    </span>
                  </p>
                </div>
                <div class="full-width " style="border-right: 1px solid #909090;">
                  <p class="full-width smb rm-mt mr " *ngFor="let s of statusMessageArray3">
                    <span [ngStyle]="{ 'background-color':  messageUtil.getStatusColor (s,false)  }"
                      (click)="displayStatusDescription(s)" class="status-container">
                      <mat-icon class="{{messageUtil.getMessageStatusIcon(s)}} smr "
                        style="font-size:  0.9em;width: 0.9rem;height: 0.9rem;">
                      </mat-icon> {{s}}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="qtr-width" style="    background: #f9f9f9; padding-right: 20px; padding-left: 20px ; ">
              <h3 class="sr-title text-left full-width  ">
                Queue Status
              </h3>
              <div class="full-width ">
                <p class="full-width smb rm-mt " *ngFor="let s of statusArray">
                  <span [ngStyle]="{ 'background-color':  messageUtil.getStatusColor (s,true)  }"
                    class="status-container">
                    <mat-icon class="{{messageUtil.getStatusIcon(s)}} smr "
                      style="font-size:  0.9em;width: 0.9rem;height: 0.9rem;">
                    </mat-icon> {{s}}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
  </mat-dialog-content>
  <div class="drop-button text-center clearfix mt full-width"
    *ngIf="isQuickView == true && messageGroup && messageGroup.ID ">
    <hr>
    <button class=" btn ml mr mt" (click)="closeEvent()" mat-raised-button color="primary">
      Close
    </button>
    <button class=" btn ml mr  mt"
      *ngIf="messageGroup && (messageGroup['Status.Label']==='Sender' || messageGroup['Status.Label']==='Pending')"
      (click)="refreshMessageGroup()" mat-raised-button color="accent">
      <mat-icon class="fas fa-sync-alt smr"></mat-icon> Refresh
    </button>

    <button class=" btn ml mr  mt" *ngIf="messageGroup && (messageGroup['Status.Label']==='Draft') && noAction!=true"
      (click)="continue()" mat-raised-button color="accent">
      <mat-icon class="fas fa-edit smr"></mat-icon> Continue Editing
    </button>



  </div>

  <div class="drop-button text-center clearfix mt full-width"
    *ngIf="isQuickView != true  && messageGroup && messageGroup.ID">
    <hr>

    <button class=" btn-large   mt pull-left" *ngIf="noAction!=true" (click)="list()" mat-raised-button color="accent">
      <mat-icon class="fas fa-chevron-left smr"></mat-icon> Campaigns list
    </button>
  </div>

</mat-card>
