/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./quotation-list-overview.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../operator/operator-filter/operator-filter.component.ngfactory";
import * as i3 from "../../operator/operator-filter/operator-filter.component";
import * as i4 from "../../../core/authentication/shared/authentication.service";
import * as i5 from "../../merchant/shared/merchant.service";
import * as i6 from "../../product/shared/product.service";
import * as i7 from "../../dentist/shared/dentist.service";
import * as i8 from "@angular/common";
import * as i9 from "../quotation-list-global/quotation-list-global.component.ngfactory";
import * as i10 from "../../../shared/pipes/custom-date.pipe";
import * as i11 from "../quotation-list-global/quotation-list-global.component";
import * as i12 from "../../../shared/services/utils.service";
import * as i13 from "../../contract/shared/contract.service";
import * as i14 from "@angular/material/dialog";
import * as i15 from "@angular/router";
import * as i16 from "../../invitation/shared/services/invitation.service";
import * as i17 from "../../../shared/pipes/custom-phone.pipe";
import * as i18 from "./quotation-list-overview.component";
var styles_QuotationListOverviewComponent = [i0.styles];
var RenderType_QuotationListOverviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QuotationListOverviewComponent, data: { "animation": [{ type: 7, name: "ngIfAnimation", definitions: [{ type: 1, expr: "void => *", animation: [{ type: 11, selector: "*", animation: { type: 6, styles: { opacity: 0 }, offset: null }, options: { optional: true } }, { type: 11, selector: "*", animation: { type: 12, timings: "100ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 0, transform: "translateY(-75%)", offset: 0, height: 0 }, offset: null }, { type: 6, styles: { opacity: 1, transform: "translateY(0)", offset: 1, height: "*" }, offset: null }] }, timings: "0.8s ease-out" }] }, options: { optional: true } }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 11, selector: "*", animation: { type: 6, styles: { opacity: 1 }, offset: null }, options: { optional: true } }, { type: 11, selector: "*", animation: { type: 12, timings: "100ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 1, transform: "translateY(0)", offset: 0, height: "*" }, offset: null }, { type: 6, styles: { opacity: 0, transform: "translateY(-75%)", offset: 1, height: 0 }, offset: null }] }, timings: "0.8s ease-in" }] }, options: { optional: true } }], options: null }], options: {} }] } });
export { RenderType_QuotationListOverviewComponent as RenderType_QuotationListOverviewComponent };
function View_QuotationListOverviewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-operator-filter", [["class", "dash clearfix"], ["id", "part1"]], null, [[null, "getMerchant"], [null, "getDateTo"], [null, "getDateFrom"], [null, "search"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("getMerchant" === en)) {
        var pd_0 = (_co.getMerchant($event) !== false);
        ad = (pd_0 && ad);
    } if (("getDateTo" === en)) {
        var pd_1 = (_co.getDateTo($event) !== false);
        ad = (pd_1 && ad);
    } if (("getDateFrom" === en)) {
        var pd_2 = (_co.getDateFrom($event) !== false);
        ad = (pd_2 && ad);
    } if (("search" === en)) {
        var pd_3 = (_co.applySearch($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_OperatorFilterComponent_0, i2.RenderType_OperatorFilterComponent)), i1.ɵdid(2, 114688, null, 0, i3.OperatorFilterComponent, [i4.AuthenticationService, i5.MerchantService, i6.ProductService, i7.DentistService], { displayMerchant: [0, "displayMerchant"], displayDateFrom: [1, "displayDateFrom"], displayDateTo: [2, "displayDateTo"], displayDentist: [3, "displayDentist"], selectDateRange: [4, "selectDateRange"], sendNull: [5, "sendNull"], autoSelectMerchant: [6, "autoSelectMerchant"] }, { getMerchant: "getMerchant", getDateTo: "getDateTo", getDateFrom: "getDateFrom", search: "search" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = true; var currVal_2 = true; var currVal_3 = false; var currVal_4 = _co.selectedRange; var currVal_5 = true; var currVal_6 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_QuotationListOverviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuotationListOverviewComponent_2)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "app-quotation-list-global", [], null, null, null, i9.View_QuotationListGlobalComponent_0, i9.RenderType_QuotationListGlobalComponent)), i1.ɵprd(512, null, i10.CustomDatePipe, i10.CustomDatePipe, []), i1.ɵdid(6, 770048, null, 0, i11.QuotationListGlobalComponent, [i12.UtilsService, i13.ContractService, i14.MatDialog, i15.Router, i10.CustomDatePipe, i4.AuthenticationService, i16.InvitationService, i12.UtilsService, i17.CustomPhonePipe], { dateFrom: [0, "dateFrom"], dateTo: [1, "dateTo"], status: [2, "status"], pageSize: [3, "pageSize"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.hideExtraComponents == false); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.dateFrom; var currVal_2 = _co.dateTo; var currVal_3 = _co.status; var currVal_4 = _co.pageSize; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_QuotationListOverviewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "clearfix"]], null, null, null, null, null))], null, null); }
export function View_QuotationListOverviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuotationListOverviewComponent_1)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuotationListOverviewComponent_3)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.isAdminOrPromoter == true) && (_co.load == true)); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.isAdminOrPromoter == false); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_QuotationListOverviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-quotation-list-overview", [], null, null, null, View_QuotationListOverviewComponent_0, RenderType_QuotationListOverviewComponent)), i1.ɵdid(1, 114688, null, 0, i18.QuotationListOverviewComponent, [i4.AuthenticationService, i15.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QuotationListOverviewComponentNgFactory = i1.ɵccf("app-quotation-list-overview", i18.QuotationListOverviewComponent, View_QuotationListOverviewComponent_Host_0, { pageSize: "pageSize", status: "status" }, {}, []);
export { QuotationListOverviewComponentNgFactory as QuotationListOverviewComponentNgFactory };
