<!-- <div class="container"> -->
<form #editForm="ngForm">
  <div class="row clearfix discount-edit rel">
    <div class="card-header primary-gradient-img clearfix inModal" *ngIf="isModal == true">
      <div class="row clearfix">
        <!-- Modal dismiss button -->
        <button class="btn-close btn-clear mat-button white" matDialogClose>
          <mat-icon>clear</mat-icon>
        </button>
        <div class="row titleArea clearfix full-width">
          <mat-icon class="fas fa-money-bill-wave pull-left white" aria-hidden="true"></mat-icon>
          <h2 class="summary-header rm-m white">
            Invitation cash discount
            <span class="subLabel">Savings for cash/card payments in full</span>
          </h2>
        </div>
      </div>
    </div>

    <mat-dialog-content>
      <div class="full-width">
        <!-- adjust discount settings -->
        <div class="row clearfix">
          <div class="row clearfix mb">
            <!-- toggle discount on or off -->
            <mat-slide-toggle
              class="enable-discount mb"
              [(ngModel)]="cashDiscountOffered"
              name="cashDiscountOffered"
              color="accent"
            >
              <span>Enable full payment discount for this invitation</span>
            </mat-slide-toggle>
          </div>

          <div class="row clearfix flex discount-settings-area" [class.active]="cashDiscountOffered == true">
            <mat-form-field class="full-width" *ngIf="cashDiscountOffered != true" appearance="outline">
              <!-- discount percent input -->

              <mat-label>"Discount amount (%)</mat-label>

              <input
                class="mat-block"
                [disabled]="true"
                [max]="100"
                [min]="0"
                type="number"
                matInput
                placeholder="Discount amount (%)"
                name="discount-amt"
                value="0"
              />
              <span class="posPrefix" matSuffix>
                <strong>%</strong>
              </span>
            </mat-form-field>

            <mat-form-field class="full-width" *ngIf="cashDiscountOffered == true" appearance="outline">
              <!-- discount percent input -->

              <mat-label>"Discount amount (%)</mat-label>

              <input
                class="mat-block"
                [(ngModel)]="cashDiscountPercent"
                [max]="100"
                [min]="0"
                (ngModelChange)="calculDiscount()"
                type="number"
                matInput
                placeholder="Discount amount (%)"
                name="discount-amt"
                required
              />
              <span class="posPrefix" matSuffix>
                <strong>%</strong>
              </span>
            </mat-form-field>

            <mat-form-field class="full-width" *ngIf="cashDiscountOffered != true" appearance="outline">
              <mat-label>Expiry timeframe (days)</mat-label>
              <!-- discount timeframe/expiry input -->
              <input
                class="mat-block"
                [disabled]="true"
                type="number"
                matInput
                value="0"
                placeholder="Expiry timeframe (days)"
                name="discount-exp"
              />
            </mat-form-field>

            <mat-form-field class="full-width" *ngIf="cashDiscountOffered == true" appearance="outline">
              <mat-label>Expiry timeframe (days)</mat-label>
              <!-- discount timeframe/expiry input -->
              <input
                class="mat-block"
                [(ngModel)]="cashDiscountExpiredDays"
                [disabled]="cashDiscountOffered != true"
                (ngModelChange)="calculDiscount()"
                type="number"
                matInput
                placeholder="Expiry timeframe (days)"
                name="discount-exp"
                required
              />
            </mat-form-field>
          </div>
        </div>
        <div class="full-width flex">
          <div
            class="full-width flex mt mb result-container"
            *ngIf="cashDiscountOffered === true && treatmentAmount && cashDiscountAmount && cashDiscountDate"
          >
            <div class="full-width normal mr">
              <label>{{"KEYWORD.Treatment" | translate}} value:</label>
              <p class="lead rm-m">
                <strong> {{ treatmentAmount | customCurrency }}</strong>
              </p>
            </div>

            <div class="full-width normal mr">
              <label class="accent-color">Discount offered:</label>
              <p class="lead rm-m accent-color">
                <strong> {{ cashDiscountAmount | customCurrency }}</strong>
              </p>
            </div>

            <div class="full-width">
              <label>Expires:</label>
              <p class="lead rm-m">
                <strong> {{ cashDiscountDate | customDate }}</strong>
              </p>
            </div>
          </div>
        </div>
        <div
          class="full-width mt mb"
          *ngIf="
            (isPromoterOrAdmin || sessionType == 'merchant-admin') &&
            cashDiscountOffered === true &&
            cashDiscountPercent &&
            cashDiscountPercent > 0 &&
            cashDiscountExpiredDays &&
            cashDiscountExpiredDays > 0
          "
        >
          <div class="row full-width clearfix pull-right text-right margin-btm">
            <mat-checkbox [(ngModel)]="applyAsDefault" name="applyAsDefault">
              Set as default for all invitations
            </mat-checkbox>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <div class="row clearfix full-width text-center">
      <hr class="mt"/>
      <button class="btn-small mr pull-left" (click)="closeEvent()" mat-raised-button color="primary">Close</button>

      <button
        class="btn-small"
        *ngIf="merchant && merchant.ID"
        (click)="resetDefault()"
        mat-raised-button
        color="primary"
      >
        Default settings
      </button>

      <button
        class="pull-right"
        [disabled]="
          !editForm.form.valid ||
          (cashDiscountOffered == true &&
            (!cashDiscountExpiredDays ||
              !cashDiscountPercent ||
              cashDiscountPercent <= 0 ||
              cashDiscountExpiredDays <= 0))
        "
        (click)="save()"
        mat-raised-button
        color="accent"
      >
        Apply
      </button>
    </div>
  </div>
</form>
<!-- </div> -->
