<div class="rel">
  <form #resetform="ngForm">
    <div class="card-header primary-gradient-img clearfix confirmModal" *ngIf="isModal == true">
      <button class="btn-close btn-clear mat-button smt white custom-close" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="full-width flex main-title-container">
        <div class="main-icon-container text-center">
          <mat-icon class="main-icon accent-color fas fa-folder-open"></mat-icon>
        </div>
        <div class="row full-width title-container clearfix">
          <div class="title-container-sub">
            <h2 class="text-left title-header rm-m">
              Create Editor Asset

              <span class="subLabel"
              >Choose The Asset type, add a Label and upload your file, optionally you can add a Thumbnail.</span
              >
            </h2>
          </div>
        </div>
      </div>
    </div>

    <mat-dialog-content [ngClass]="{ 'no-modal-height': isModal != true }">
      <div class="full-width">
        <div class="full-width flex">
          <div class="full-width mr">
            <h3 class="sr-title">Details</h3>

            <div class="full-width flex">
              <mat-form-field class="full-width mr" appearance="outline">
                <mat-label>Label</mat-label>
                <input [(ngModel)]="label" matInput placeholder="Label" name="Label" required/>
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Type</mat-label>
                <input [(ngModel)]="type" matInput placeholder="Type" name="Type"/>
              </mat-form-field>
            </div>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Description</mat-label>
              <textarea
                class="messageArea"
                [(ngModel)]="description"
                name="description"
                matInput
                placeholder="Description"
                required
              ></textarea>
            </mat-form-field>

            <div class="full-width flex">
              <mat-form-field class="full-width mr" appearance="outline">
                <mat-label>Priority</mat-label>
                <mat-select [(ngModel)]="priorityCode" name="Priority" placeholder="Priority" required>
                  <div *ngFor="let m of priorities">
                    <mat-option [value]="m.Code">
                      {{ m.Label }}
                    </mat-option>
                  </div>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="full-width" [class.mr]="actionCode == 'others'" appearance="outline">
                <mat-label>Action</mat-label>
                <mat-select [(ngModel)]="actionCode" name="Action" placeholder="Action">
                  <div *ngFor="let m of actions">
                    <mat-option [value]="m.code">
                      {{ m.label }}
                    </mat-option>
                  </div>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="full-width r" *ngIf="actionCode == 'others'" appearance="outline">
                <mat-label>Other Action</mat-label>
                <input [(ngModel)]="action" matInput placeholder="Other Action" name="Actionsss"/>
              </mat-form-field>
            </div>
          </div>
          <div class="row blob-for-task-container-thumbnail ml mr flex">
            <div class="full-width">
              <h3 class="sr-title full-width text-center">Thumbnail</h3>
              <app-ep-document-view-image
                class="squareImg squareImg-task"
                *ngIf="thumbnailLink"
                [hideZoomButton]="false"
                [defaultPicture]="
                  'url(https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/task_icon.png)'
                "
                [link]="thumbnailLink"
              >
              </app-ep-document-view-image>
              <app-ep-document-view-image
                class="squareImg squareImg-task squareImg-task-default"
                *ngIf="!thumbnailLink"
                [hideZoomButton]="false"
                [defaultPicture]="
                  'url(https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/task_icon.png)'
                "
              >
              </app-ep-document-view-image>

              <div class="clearfix full-width">
                <app-file-uploader
                  class="text-center clearfix full-width"
                  [allowedExtensions]="allowedImageExtensions"
                  [backgroundColor]="'none'"
                  [maxFileSize]="2"
                  [isImageCropping]="true"
                  [canSkip]="true"
                  [limit]="1"
                  [uploadDirectly]="true"
                  [isDragable]="true"
                  [title]="'CLICK HERE to upload (Max size: ' + 2 + 'MB)'"
                  (onCompleteAll)="onCompleteThumbnail($event)"
                >
                </app-file-uploader>
              </div>
            </div>
          </div>
        </div>

        <div class="full-width">
          <h3 class="sr-title">Marketing descriptions</h3>
          <div class="full-width mb">
            <div class="list full-width mr">
              <div class="full-width" *ngFor="let item of marketingDescriptions; let idx = index">
                <div class="full-width flex">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{ idx + 1 }}- Insert a description</mat-label>
                    <textarea
                      class="mat-block sub-textarea"
                      [(ngModel)]="item"
                      (change)="changeMarketing(idx, $event.target.value)"
                      matInput
                      placeholder="{{ idx + 1 }}- Insert a description"
                      name="Insertadescription {{ idx }}"
                    >
                    </textarea>
                  </mat-form-field>
                  <button
                    class="pull-right smr marketing-delete"
                    *ngIf="marketingDescriptions && marketingDescriptions.length > 1"
                    (click)="removeMarketingDescription(idx)"
                    mat-icon-button
                    color="primary"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <button class="btn pull-right" (click)="addMarketingDescription()" mat-raised-button color="primary">
              <mat-icon>add</mat-icon> Add
            </button>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <hr/>
    <div class="drop-button text-center mt full-width" *ngIf="isModal == true">
      <button
        class="pull-right btn-large"
        [disabled]="!resetform.form.valid"
        (click)="save()"
        mat-raised-button
        color="accent"
      >
        Save
      </button>

      <button class="pull-left btn-large" (click)="closeEvent()" mat-raised-button color="primary">close</button>
    </div>
  </form>
</div>
