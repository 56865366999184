import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions } from 'ngx-gallery';

@Component({
  selector: 'app-gallery',
  templateUrl: './ngx-gallery.component.html',
  styleUrls: ['./ngx-gallery.component.css'],
})
export class GalleryComponent implements OnInit {
  @Input()
  inCard = false;

  @Input()
  canPreview = true;

  @Input() galleryOptions: NgxGalleryOptions[] = [
    {
      width: '100%',
      height: '400px',
      thumbnailsColumns: 4,
      imageAnimation: NgxGalleryAnimation.Slide,
      previewForceFullscreen: false,
      preview: this.canPreview,
    },
    // max-width 800
    {
      breakpoint: 800,
      width: '100%',
      height: '600px',
      imagePercent: 80,
      thumbnailsPercent: 20,
      thumbnailsMargin: 20,
      thumbnailMargin: 20,
    },
    {
      breakpoint: 400,
      preview: false,
    },
  ];

  @Input() galleryImages: NgxGalleryImage[] = [
    {
      small: 'https://source.unsplash.com/k6SFjtLiZWI/140x100',
      medium: 'https://source.unsplash.com/k6SFjtLiZWI/800x600',
      big: 'https://source.unsplash.com/k6SFjtLiZWI/1600x900',
    },
    {
      small: 'https://source.unsplash.com/pRqF0180Wj4/140x100',
      medium: 'https://source.unsplash.com/pRqF0180Wj4/800x600',
      big: 'https://source.unsplash.com/pRqF0180Wj4/1600x900',
    },
    {
      small: 'https://source.unsplash.com/JiqalEW6Ml0/140x100',
      medium: 'https://source.unsplash.com/JiqalEW6Ml0/800x600',
      big: 'https://source.unsplash.com/JiqalEW6Ml0/1600x900',
    },
  ];

  // https://www.npmjs.com/package/ngx-gallery

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}

  /*  override z-index for full screen preview */

  previewOpen() {
    this.renderer.addClass(document.body, 'gallery-preview-open');
  }

  previewClose() {
    this.renderer.removeClass(document.body, 'gallery-preview-open');
  }
}
