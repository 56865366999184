import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogMessageComponent } from '../../../shared/components/confirm-dialog-message/confirm-dialog-message.component';
import { ConfirmDialogSingleButtonComponent } from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { DentistService } from '../../dentist/shared/dentist.service';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { ScheduleAppointmentViewComponent } from '../schedule-appointment-view/schedule-appointment-view.component';
import { AppointmentService } from '../shared/appointment.service';
import { AppointmentUtilClass } from '../shared/appointmentUtil';

@Component({
  selector: 'app-appointment-action-hidden',
  templateUrl: './appointment-action-hidden.component.html',
  styleUrls: ['./appointment-action-hidden.component.css'],
})
export class AppointmentActionHiddenComponent implements OnInit {
  @Input()
  runAction = new EventEmitter();

  @Input()
  merchantID;

  @Output()
  result = new EventEmitter();

  sessionType = 'guest';

  appointmentUtil = new AppointmentUtilClass();

  dentists;
  isPromoterOrAdmin = false;

  constructor(
    private authenticationService: AuthenticationService,
    private appointmentService: AppointmentService,
    private customDate: CustomDatePipe,
    private merchantService: MerchantService,
    private dentistService: DentistService
  ) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
      this.isPromoterOrAdmin = isPromoterOrAdmin;

      this.authenticationService.getSessionType().subscribe((res) => {
        if (res) {
          this.sessionType = res;
        }
      });

      this.runAction.subscribe((p) => {
        const { operation, item, isGroup } = p;

        if (operation == 'proposeBooking') {
          this.proposeAppointment(item);
        } else if (operation == 'confirmBooking') {
          this.confirmAppointment(item, isGroup);
        } else if (operation == 'confirmBookingMerchant') {
          this.confirmBookingMerchant(item, isGroup);
        } else if (operation == 'requestDateChangeBooking') {
          this.requestDateChangeAppointment(item, isGroup);
        } else if (operation == 'requestReschedule') {
          this.requestReschedule(item, isGroup);
        } else if (operation == 'reschedule') {
          this.reschedule(item);
        } else if (operation == 'attend') {
          this.attendAppointment(item, isGroup);
        } else if (operation == 'miss') {
          this.missAppointment(item, isGroup);
        } else if (operation == 'cancelMerchant') {
          this.cancelAppointmentMerchant(item, isGroup);
        } else if (operation == 'cancelPatient') {
          this.cancelAppointmentPatient(item, isGroup);
        } else if (operation == 'assignDentist') {
          this.assignDentist(item, isGroup);
        } else if (operation == 'disable') {
          this.disableAppointment(item, isGroup);
        } else if (operation == 'enable') {
          this.enableAppointment(item, isGroup);
        }
      });
    });
  }

  proposeAppointmentDeprecated(item, isGroup = false) {
    let isOption = false;
    const optionLabel = 'Assign a dentist';
    let optionList = [];
    let title;
    let description;

    const payload = {
      merchantID: isGroup ? this.merchantID : item['Merchant_key'],
      fields: 'ID,CalculatedName',
      canAcceptAppointment: true,
    };

    let smsConfirmAppointment = false;
    let smsAppointmentReminder = false;

    if (item['Merchant_SMS_ConfirmAppointment'] == '1') {
      smsConfirmAppointment = true;
    }

    if (item['Merchant_SMS_AppointmentReminder'] == '1') {
      smsAppointmentReminder = true;
    }

    let bookDateEnd;
    let diffrentDurationMinutes;
    if (item['SubType.DefaultDuration']) {
      bookDateEnd = moment().add(Number(item['SubType.DefaultDuration']), 'minutes').toDate();
      diffrentDurationMinutes = Number(item['SubType.DefaultDuration']);
    } else if (item['Type.DefaultDuration']) {
      bookDateEnd = moment().add(Number(item['Type.DefaultDuration']), 'minutes').toDate();
      diffrentDurationMinutes = Number(item['Type.DefaultDuration']);
    } else {
      bookDateEnd = moment().add(60, 'minutes').toDate();
      diffrentDurationMinutes = Number(60);
    }

    this.dentistService.getList(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        optionList = res;
        isOption = true;
      }

      if (isGroup) {
        title = 'Book appointment for these patients';
        description = `Patients will recieve a confirmation message with booking date`;
      } else {
        title = `Book an appointment for ${item.CustomerOrProspect_CalculatedName}`;
        description = `Your patient will recieve a confirmation message with booking date`;
      }

      const ref = RootAppComponent.dialog.open(ConfirmDialogMessageComponent, {
        data: {
          icon: this.appointmentUtil.operations.proposeBooking.icon,
          title,
          yesLabel: 'Book',
          noLabel: 'Cancel',
          textLabel: 'Comment',
          description,
          messageLabel: 'You can add a comment to be sent with the booking email',
          messageTitle: '',
          dateTimeLabel: 'Booking date and time',
          dateTimeLabelSecond: 'Appointment end at ',
          isDateRequired: false,
          isDateTimeRequired: true,
          isDateTimeInput: true,
          isDateInput: false,

          isDateSecondRequired: false,
          isDateTimeSecondRequired: true,
          isDateTimeSecondInput: true,
          isDateSecondInput: false,
          dateTimeDefaultSecond: bookDateEnd,

          diffrentDurationMinutes,
          isDiffrentDuration: true,

          isTextInputRequired: false,
          isTextAreaRequired: false,
          isTextArea: true,
          isTextInput: false,

          checkboxValue: smsConfirmAppointment,
          checkboxValueSecond: smsAppointmentReminder,

          isCheckbox: true,
          isCheckboxRequired: false,
          checkboxLabel: 'Send SMS to confirm the appointment',

          isCheckboxSecond: true,
          isCheckboxSecondRequired: false,
          checkboxLabelSecond: 'Send SMS reminder 24 Hrs before treatment ',

          option: item['MerchantContact_key'] || null,
          isOption,
          isOptionRequired: false,
          optionLabel,
          optionCode: 'ID',
          optionViewValue: 'CalculatedName',
          optionList,
        },
        width: '650px',
      });
      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
      ref.componentInstance.onConfirm.subscribe((res) => {
        if (res && res.confirm == true) {
          const data: any = {
            bookDate: res.dateTime,
            bookDateEnd: res.dateTimeSecond,
            comment: res.message,
            operation: 'proposeBooking',
            dentistID: res.optionValue || null,
            smsConfirmAppointment: res.checkboxValue,
            smsAppointmentReminder: res.checkboxValueSecond,
          };

          if (isGroup) {
            data.ids = item;
          } else {
            data.id = item.ID;
          }

          this.appointmentService.editAppointment(item.ID, data, this.sessionType).subscribe((res) => {
            if (res) {
              ref.close();
              ref.afterClosed().subscribe((c) => {
                this.result.emit(res);

                if (isGroup) {
                  if (res.length > 0) {
                    NotifyAppComponent.displayToast('success', 'Book appointments', 'Appointments are now booked');
                  } else {
                    NotifyAppComponent.displayToast(
                      'warning',
                      'Book appointments',
                      'None of the selected appointments can be booked'
                    );
                  }
                } else {
                  NotifyAppComponent.displayToast('success', 'Book appointment', 'Appointment is now booked');
                }
              });
            }
          });
        } else {
          ref.close();
        }
      });
    });
  }

  proposeAppointment(item) {
    const ref = RootAppComponent.dialog.open(ScheduleAppointmentViewComponent, {
      data: {
        appointmentID: item.ID,
      },
      width: '900px',
      height: '100%',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });

    ref.componentInstance.confirm.subscribe((res) => {
      if (res) {
        this.appointmentService.editAppointment(item.ID, res, this.sessionType).subscribe((res) => {
          if (res) {
            ref.close();
            ref.afterClosed().subscribe((c) => {
              this.result.emit(res);

              NotifyAppComponent.displayToast('success', 'Book appointment', 'Appointment is now booked');
            });
          }
        });
      } else {
        ref.close();
      }
    });
  }

  confirmAppointment(item, isGroup = false) {
    let _date;
    let _time;
    let title;
    let description;
    let policyName;

    if (isGroup) {
      title = 'Confirm your bookings';
      description = 'Do you want to confirm the selected appointment bookings';
      policyName = 'Cancelling policy';
    } else {
      title = 'Confirm your booking';
      if (item['Date']) {
        _date = this.customDate.transform(item['Date']);
      }
      if (item['Time']) {
        _time = this.customDate.transform(item['Date'] + ' ' + item['Time'], 'hh:ss A');
      }
      description =
        `<p>Do you want to confirm your appointment booking with ${item.Merchant_CalculatedName} ` +
        `on <strong> ${_date}</strong> at <strong> ${_time}</strong>  </p>`;
      policyName = item.Merchant_CalculatedName + ' Cancelling policy';
    }

    const ref = RootAppComponent.dialog.open(ConfirmDialogMessageComponent, {
      data: {
        icon: this.appointmentUtil.operations.confirmBooking.icon,
        title,
        yesLabel: 'Confirm',
        noLabel: 'Cancel',
        textLabel: 'Comment',
        description,
        messageLabel: '',
        dateTimeLabel: 'Booking date and time',
        isDateRequired: false,
        isDateTimeRequired: false,
        isDateTimeInput: false,
        isDateInput: false,
        isTextInputRequired: false,
        isTextAreaRequired: false,
        isTextArea: false,
        isTextInput: false,

        isPolicy: true,
        isPolicyRequired: true,
        policyLabel: 'I agree to ',
        policyName,

        isCheckbox: false,
        checkboxLabel: 'Notify the patient',
      },
      width: '650px',
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.clickPolicy.subscribe((res) => {
      const merchantKey = isGroup ? this.merchantID : item['Merchant_key'];
      if (merchantKey) {
        const _p = {
          fields: 'ID,Appointment_Cancelling_Policy',
        };
        this.merchantService.getMerchantDetails(merchantKey, _p, 'guest').subscribe((m) => {
          if (m && m['Appointment_Cancelling_Policy']) {
            const confirmDialog2 = new ConfirmDialogSingleButton(
              'policy',
              isGroup
                ? 'Appointment cancelling policy'
                : item.Merchant_CalculatedName + 'Appointment cancelling policy',
              m['Appointment_Cancelling_Policy'],
              'OKAY'
            );

            const ref2 = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
              data: confirmDialog2,
            });

            ref2.componentInstance.onConfirm.subscribe((res) => {
              ref2.close();
            });
          }
        });
      }
    });
    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res && res.confirm == true) {
        const data: any = {
          operation: 'confirmBooking',
          comment: res.message,
        };

        if (isGroup) {
          data.ids = item;
        } else {
          data.id = item.ID;
        }
        this.appointmentService.editAppointment(item.ID, data, 'guest').subscribe((res) => {
          if (res) {
            ref.close();
            ref.afterClosed().subscribe((c) => {
              this.result.emit(res);

              if (isGroup) {
                if (res.length > 0) {
                  NotifyAppComponent.displayToast('success', 'Confirm appointments', 'Appointments are now booked');
                } else {
                  NotifyAppComponent.displayToast(
                    'warning',
                    'Confirm appointments',
                    'None of the selected appointments can be confirmed'
                  );
                }
              } else {
                NotifyAppComponent.displayToast('success', 'Confirm appointment', 'Appointment is now booked');
              }
            });
          }
        });
      } else {
        ref.close();
      }
    });
  }

  confirmBookingMerchant(item, isGroup = false) {
    let _date;
    let _time;
    let title;
    let description;
    let policyName;

    if (isGroup) {
      title = 'Confirm  bookings';
      description = 'Do you want to confirm the selected appointment bookings on behalf of patients';
    } else {
      title = 'Confirm booking on behalf of the patient ';
      if (item['Date']) {
        _date = this.customDate.transform(item['Date']);
      }
      if (item['Time']) {
        _time = this.customDate.transform(item['Date'] + ' ' + item['Time'], 'hh:ss A');
      }
      description =
        `<p>Do you want to confirm your appointment booking with ${item.CustomerOrProspect_CalculatedName} ` +
        `on <strong> ${_date}</strong> at <strong> ${_time}</strong>  </p>`;
    }

    const ref = RootAppComponent.dialog.open(ConfirmDialogMessageComponent, {
      data: {
        icon: this.appointmentUtil.operations.confirmBooking.icon,
        title,
        yesLabel: 'Confirm',
        noLabel: 'Cancel',
        textLabel: 'Comment',
        description,
        messageLabel: 'You can add a comment to be sent to the patient',
        messageTitle: '',
        dateTimeLabel: 'Booking date and time',
        isDateRequired: false,
        isDateTimeRequired: false,
        isDateTimeInput: false,
        isDateInput: false,
        isTextInputRequired: false,
        isTextAreaRequired: false,
        isTextArea: true,
        isTextInput: false,

        isPolicy: false,
        isPolicyRequired: false,
        policyLabel: 'I agree to ',
        policyName,

        isCheckbox: false,
        checkboxLabel: 'Notify the patient',
      },
      width: '650px',
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res && res.confirm == true) {
        const data: any = {
          operation: 'confirmBookingMerchant',
          comment: res.message,
        };

        if (isGroup) {
          data.ids = item;
        } else {
          data.id = item.ID;
        }
        this.appointmentService.editAppointment(item.ID, data, this.sessionType).subscribe((res) => {
          if (res) {
            ref.close();
            ref.afterClosed().subscribe((c) => {
              this.result.emit(res);

              if (isGroup) {
                if (res.length > 0) {
                  NotifyAppComponent.displayToast('success', 'Confirm appointments', 'Appointments are now booked');
                } else {
                  NotifyAppComponent.displayToast(
                    'warning',
                    'Confirm appointments',
                    'None of the selected appointments can be confirmed'
                  );
                }
              } else {
                NotifyAppComponent.displayToast('success', 'Confirm appointment', 'Appointment is now booked');
              }
            });
          }
        });
      } else {
        ref.close();
      }
    });
  }

  requestDateChangeAppointment(item, isGroup = false) {
    let title;
    let description;
    let policyName;
    let _date;
    let _time;

    if (isGroup) {
      title = 'Request changing appointment dates';
      description = 'Are you sure  you want to change the appointment dates';
      policyName = 'Cancelling policy';
    } else {
      title = 'Request changing appointment date';
      if (item['Date']) {
        _date = this.customDate.transform(item['Date']);
      }
      if (item['Time']) {
        _time = this.customDate.transform(item['Date'] + ' ' + item['Time'], 'hh:ss A');
      }
      description =
        `<p>Are you sure  you want to  change the appointment date  suggested by your dentist ` +
        ` on <strong> ${_date}</strong> at <strong> ${_time}</strong>  </p><p>Your dentist will be notified and make another booking for you</p>`;
      policyName = item.Merchant_CalculatedName + ' Cancelling policy';
    }

    const ref = RootAppComponent.dialog.open(ConfirmDialogMessageComponent, {
      data: {
        icon: this.appointmentUtil.operations.requestDateChangeBooking.icon,
        title,
        yesLabel: 'Submit',
        noLabel: 'Cancel',
        textLabel: '',
        description,
        messageLabel: 'A comment to be sent with your request',
        messageTitle: '',
        dateTimeLabel: 'Booking date and time',
        isDateRequired: false,
        isDateTimeRequired: false,
        isDateTimeInput: false,
        isDateInput: false,
        isTextInputRequired: false,
        isTextAreaRequired: false,
        isTextArea: true,
        isTextInput: false,

        isPolicy: false,
        isPolicyRequired: false,
        policyLabel: 'I agree to ',
        policyName,

        isCheckbox: false,
        checkboxLabel: 'Notify the patient',
      },
      width: '650px',
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res && res.confirm == true) {
        const data: any = {
          operation: 'rejectBooking',
          comment: res.message,
        };
        if (isGroup) {
          data.ids = item;
        } else {
          data.id = item.ID;
        }
        this.appointmentService.editAppointment(item.ID, data, 'guest').subscribe((res) => {
          if (res) {
            ref.close();
            ref.afterClosed().subscribe((c) => {
              this.result.emit(res);
              if (isGroup) {
                if (res.length > 0) {
                  NotifyAppComponent.displayToast(
                    'success',
                    'Change date appointments',
                    'Appointments are now updated with new date'
                  );
                } else {
                  NotifyAppComponent.displayToast(
                    'warning',
                    'Change date appointments',
                    'None of the selected appointments can be updated with new date'
                  );
                }
              } else {
                NotifyAppComponent.displayToast(
                  'success',
                  'Change date appointment',
                  'Appointment is now updated with new date'
                );
              }
            });
          }
        });
      } else {
        ref.close();
      }
    });
  }

  requestReschedule(item, isGroup = false) {
    let title;
    let description;
    let policyName;
    let _date;
    let _time;

    if (isGroup) {
      title = 'Request a rescheduling for appointments';
      description = 'Are you sure you want to reschedule the appointments';
      policyName = 'Cancelling policy';
    } else {
      title = 'Request a rescheduling for your appointment';
      if (item['Date']) {
        _date = this.customDate.transform(item['Date']);
      }
      if (item['Time']) {
        _time = this.customDate.transform(item['Date'] + ' ' + item['Time'], 'hh:ss A');
      }
      description =
        `<p>Are you sure you want to reschedule your` +
        `  <strong> ${_date}</strong> at <strong> ${_time}</strong>   Appointment</p><p>` +
        `Your dentist will be notified and make another booking for you</p>`;
      policyName = item.Merchant_CalculatedName + ' Cancelling policy';
    }

    const ref = RootAppComponent.dialog.open(ConfirmDialogMessageComponent, {
      data: {
        icon: this.appointmentUtil.operations.requestReschedule.icon,
        title,
        yesLabel: 'Submit',
        noLabel: 'Cancel',
        textLabel: 'Comment',
        description,
        messageLabel: 'A comment to be sent with your request',
        messageTitle: '',
        dateTimeLabel: 'Booking date and time',
        isDateRequired: false,
        isDateTimeRequired: false,
        isDateTimeInput: false,
        isDateInput: false,
        isTextInputRequired: false,
        isTextAreaRequired: false,
        isTextArea: true,
        isTextInput: false,

        isPolicy: true,
        isPolicyRequired: true,
        policyLabel: 'I agree to ',
        policyName,

        isCheckbox: false,
        checkboxLabel: 'Notify the patient',
      },
      width: '650px',
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.clickPolicy.subscribe((res) => {
      const merchantKey = isGroup ? this.merchantID : item['Merchant_key'];
      if (merchantKey) {
        const _p = {
          fields: 'ID,Appointment_Cancelling_Policy',
        };
        this.merchantService.getMerchantDetails(merchantKey, _p, 'guest').subscribe((m) => {
          if (m && m['Appointment_Cancelling_Policy']) {
            const confirmDialog2 = new ConfirmDialogSingleButton(
              'policy',
              isGroup
                ? 'Appointment cancelling policy'
                : item.Merchant_CalculatedName + ' Appointment cancelling policy',
              m['Appointment_Cancelling_Policy'],
              'OKAY'
            );

            const ref2 = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
              data: confirmDialog2,
            });

            ref2.componentInstance.onConfirm.subscribe((res) => {
              ref2.close();
            });
          }
        });
      }
    });
    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res && res.confirm == true) {
        const data: any = {
          operation: 'requestReschedule',
          comment: res.message,
        };
        if (isGroup) {
          data.ids = item;
        } else {
          data.id = item.ID;
        }
        this.appointmentService.editAppointment(item.ID, data, this.sessionType).subscribe((res) => {
          if (res) {
            ref.close();
            ref.afterClosed().subscribe((c) => {
              this.result.emit(res);
              if (isGroup) {
                if (res.length > 0) {
                  NotifyAppComponent.displayToast(
                    'success',
                    'Request rescheduling',
                    'Appointments are now requested to be rescheduled'
                  );
                } else {
                  NotifyAppComponent.displayToast(
                    'warning',
                    'Request rescheduling',
                    'None of the selected appointments can be requested to be rescheduled'
                  );
                }
              } else {
                NotifyAppComponent.displayToast(
                  'success',
                  'Request rescheduling',
                  'Appointment is now requested to be rescheduled'
                );
              }
            });
          }
        });
      } else {
        ref.close();
      }
    });
  }

  reschedule(item) {
    const ref = RootAppComponent.dialog.open(ScheduleAppointmentViewComponent, {
      data: {
        appointmentID: item.ID,
      },
      width: '900px',
      height: '100%',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });

    ref.componentInstance.confirm.subscribe((res) => {
      if (res) {
        res.operation = 'reschedule';
        this.appointmentService.editAppointment(item.ID, res, this.sessionType).subscribe((res) => {
          if (res) {
            ref.close();
            ref.afterClosed().subscribe((c) => {
              this.result.emit(res);

              NotifyAppComponent.displayToast('success', 'Book appointment', 'Appointment is now recheduled');
            });
          }
        });
      } else {
        ref.close();
      }
    });
  }

  cancelAppointmentPatient(item, isGroup = false) {
    const title = `Cancel your appointment request`;
    const description = 'Thanks for letting us know and sorry to hear we will not see you this time.';

    const ref = RootAppComponent.dialog.open(ConfirmDialogMessageComponent, {
      data: {
        icon: this.appointmentUtil.operations.cancelPatient.icon,
        title,
        yesLabel: 'Cancel the Appointment',
        noLabel: 'Close',
        textLabel: 'Comment',
        description,
        messageTitle: '',
        messageLabel: 'Can you tell us why or how we can improve our service?',
        isDateRequired: false,
        isDateTimeRequired: false,
        isDateTimeInput: false,
        isDateInput: false,
        isTextInputRequired: false,
        isTextAreaRequired: false,
        isTextArea: true,
        isTextInput: false,

        isPolicy: true,
        isPolicyRequired: true,
        policyLabel: 'By proceeding, I agree with Terms and Conditions for online bookings.',

        isCheckbox: false,
      },
      width: '650px',
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.clickPolicy.subscribe((res) => {
      const merchantKey = isGroup ? this.merchantID : item['Merchant_key'];
      if (merchantKey) {
        const _p = {
          fields: 'ID,Appointment_Cancelling_Policy',
        };
        this.merchantService.getMerchantDetails(merchantKey, _p, 'guest').subscribe((m) => {
          if (m && m['Appointment_Cancelling_Policy']) {
            const confirmDialog2 = new ConfirmDialogSingleButton(
              'policy',
              isGroup
                ? 'Appointment cancelling policy'
                : item.Merchant_CalculatedName + ' Appointment cancelling policy',
              m['Appointment_Cancelling_Policy'],
              'OKAY'
            );

            const ref2 = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
              data: confirmDialog2,
            });

            ref2.componentInstance.onConfirm.subscribe((res) => {
              ref2.close();
            });
          }
        });
      }
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res && res.confirm == true) {
        const data: any = {
          bookDate: res.dateTime,
          bookDateEnd: res.dateTimeSecond,
          comment: res.message,
          operation: 'cancelPatient',
        };

        if (isGroup) {
          data.ids = item;
        } else {
          data.id = item.ID;
        }

        this.appointmentService.editAppointment(item.ID, data, this.sessionType).subscribe((res) => {
          if (res) {
            ref.close();
            ref.afterClosed().subscribe((c) => {
              this.result.emit(res);
              if (isGroup) {
                if (res.length > 0) {
                  NotifyAppComponent.displayToast('success', 'Cancel appointments', 'Appointments are now canceled');
                } else {
                  NotifyAppComponent.displayToast(
                    'warning',
                    'Cancel appointments',
                    'None of the selected appointments can be canceled'
                  );
                }
              } else {
                NotifyAppComponent.displayToast('success', 'Cancel appointment', 'Appointment is now canceled');
              }
            });
          }
        });
      } else {
        ref.close();
      }
    });
  }

  cancelAppointmentMerchant(item, isGroup = false) {
    let title;
    let description;
    let _date;
    let _time;

    if (isGroup) {
      title = `Cancel Appointment Requests`;
      description = 'You are about to cancel the requests';
    } else {
      title = `Cancel Appointment Request`;
      description = `You are about to cancel the request made by <br>${item[
        'CustomerOrProspect_CalculatedName'
      ].toUpperCase()}<br>Are you sure you wish to continue?`;
    }

    const ref = RootAppComponent.dialog.open(ConfirmDialogMessageComponent, {
      data: {
        icon: '',
        headerBGColor: '#e24325',
        title,
        yesLabel: 'Proceed',
        displayNo: false,
        description,
        messageTitle: 'Provide a personalised message to the patient',
        messageLabel: 'Optionally add a comment that will be seen by your patient',
        isDateRequired: false,
        isDateTimeRequired: false,
        isDateTimeInput: false,
        isDateInput: false,
        isTextInputRequired: false,
        isTextAreaRequired: false,
        isTextArea: true,
        isTextInput: false,

        isCheckbox: true,
        checkboxTitle: 'Notification options',
        checkboxLabel: 'Send email to patient',
      },
      width: '650px',
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res && res.confirm == true) {
        const data: any = {
          bookDate: res.dateTime,
          bookDateEnd: res.dateTimeSecond,
          comment: res.message,
          sendEmail: res.checkboxValue,
          operation: 'cancelMerchant',
        };

        if (isGroup) {
          data.ids = item;
        } else {
          data.id = item.ID;
        }

        this.appointmentService.editAppointment(item.ID, data, this.sessionType).subscribe((res) => {
          if (res) {
            ref.close();
            ref.afterClosed().subscribe((c) => {
              this.result.emit(res);
              if (isGroup) {
                if (res.length > 0) {
                  NotifyAppComponent.displayToast('success', 'Cancel appointments', 'Appointments are now canceled');
                } else {
                  NotifyAppComponent.displayToast(
                    'warning',
                    'Cancel appointments',
                    'None of the selected appointments can be canceled'
                  );
                }
              } else {
                NotifyAppComponent.displayToast('success', 'Cancel appointment', 'Appointment is now canceled');
              }
            });
          }
        });
      } else {
        ref.close();
      }
    });
  }

  attendAppointment(item, isGroup = false) {
    let title;
    let description;
    let _date;
    let _time;

    if (isGroup) {
      title = `Confirm appointments attendance`;
      description = '<p>Did the patients attend the appointments</p>';
    } else {
      if (item['Date']) {
        _date = this.customDate.transform(item['Date']);
      }
      if (item['Time']) {
        _time = this.customDate.transform(item['Date'] + ' ' + item['Time'], 'hh:ss A');
      }
      title = `Confirm appointment attendance`;
      description =
        `<p>Did your patient ${item.CustomerOrProspect_CalculatedName}  attend the appointment` +
        `<strong> ${_date}</strong> at <strong> ${_time}</strong>  on time? </p>`;
    }

    let isOption = false;
    let isOptionRequired = false;
    let optionLabel = 'Who was the assigned dentist';
    let optionList = [];

    const payload = {
      merchantID: isGroup ? this.merchantID : item['Merchant_key'],
      canAcceptAppointment: true,
      fields: 'ID,CalculatedName',
    };
    this.dentistService.getList(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        optionList = res;
        isOption = true;
        isOptionRequired = false;
        optionLabel = 'Who was the assigned dentist';
      }

      const ref = RootAppComponent.dialog.open(ConfirmDialogMessageComponent, {
        data: {
          icon: this.appointmentUtil.operations.attend.icon,
          title,
          yesLabel: 'Confirm',
          noLabel: 'Cancel',
          textLabel: 'Comment',
          description,
          messageLabel: 'You can add a comment to be sent to the patient',
          messageTitle: '',
          dateTimeLabel: 'Booking date and time',
          isDateRequired: false,
          isDateTimeRequired: false,
          isDateTimeInput: false,
          isDateInput: false,
          isTextInputRequired: false,
          isTextAreaRequired: false,
          isTextArea: false,
          isTextInput: false,

          isCheckbox: false,
          checkboxLabel: 'Notify the patient',
          option: item['MerchantContact_key'] || null,

          isOption,
          isOptionRequired,
          optionLabel,
          optionCode: 'ID',
          optionViewValue: 'CalculatedName',
          optionList,
        },
        width: '650px',
      });
      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
      ref.componentInstance.onConfirm.subscribe((res) => {
        if (res && res.confirm == true) {
          const data: any = {
            operation: 'attend',
            dentistID: res.optionValue,
          };

          if (isGroup) {
            data.ids = item;
          } else {
            data.id = item.ID;
          }

          this.appointmentService.editAppointment(item.ID, data, this.sessionType).subscribe((res) => {
            if (res) {
              ref.close();
              ref.afterClosed().subscribe((c) => {
                this.result.emit(res);
                if (isGroup) {
                  if (res.length > 0) {
                    NotifyAppComponent.displayToast(
                      'success',
                      'Mark attend appointments',
                      'Appointments are now marked as attended'
                    );
                  } else {
                    NotifyAppComponent.displayToast(
                      'warning',
                      'Mark attend appointments',
                      'None of the selected appointments can be marked as attended'
                    );
                  }
                } else {
                  NotifyAppComponent.displayToast(
                    'success',
                    'Mark attend appointment',
                    'Appointment is now marked as attended'
                  );
                }
              });
            }
          });
        } else {
          ref.close();
        }
      });
    });
  }

  assignDentist(item, isGroup = false) {
    let description;
    let isOption = false;
    let isOptionRequired = false;
    let optionLabel = 'Choose a dentist';
    let optionList = [];

    const payload = {
      merchantID: isGroup ? this.merchantID : item['Merchant_key'],
      canAcceptAppointment: true,
      fields: 'ID,CalculatedName',
    };
    this.dentistService.getList(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        optionList = res;
        isOption = true;
        isOptionRequired = true;
        optionLabel = 'Choose a dentist';
      }

      if (optionList && optionList.length > 0 && Array.isArray(optionList)) {
        let _date;
        let _time;

        if (isGroup) {
          description = `<p>Assign a dentist to  the appointments</p>`;
        } else {
          if (item['Date']) {
            _date = this.customDate.transform(item['Date']);
          }
          if (item['Time']) {
            _time = this.customDate.transform(item['Date'] + ' ' + item['Time'], 'hh:ss A');
          }
          description = `<p>Assign a dentist to  ${item.CustomerOrProspect_CalculatedName}  appointment  </p>`;
        }

        const ref = RootAppComponent.dialog.open(ConfirmDialogMessageComponent, {
          data: {
            icon: this.appointmentUtil.operations.assignDentist.icon,
            title: `Dentist assignment`,
            yesLabel: 'Submit',
            noLabel: 'Cancel',
            textLabel: 'Comment',
            description,
            messageLabel: 'You can add a comment to be sent to the patient',
            messageTitle: '',
            dateTimeLabel: 'Booking date and time',
            isDateRequired: false,
            isDateTimeRequired: false,
            isDateTimeInput: false,
            isDateInput: false,
            isTextInputRequired: false,
            isTextAreaRequired: false,
            isTextArea: false,
            isTextInput: false,

            isCheckbox: false,
            checkboxLabel: 'Notify the patient',
            option: item['MerchantContact_key'] || null,

            isOption,
            isOptionRequired,
            optionLabel,
            optionCode: 'ID',
            optionViewValue: 'CalculatedName',
            optionList,
          },
          width: '650px',
        });
        ref.componentInstance.closeModal.subscribe((res) => {
          ref.close();
        });
        ref.componentInstance.onConfirm.subscribe((res) => {
          if (res && res.confirm == true) {
            const data: any = {
              dentistID: null,
            };

            if (isGroup) {
              data.ids = item;
            } else {
              data.id = item.ID;
            }

            if (res.optionValue) {
              data.dentistID = res.optionValue;
            }
            this.appointmentService.editAppointment(item.ID, data, this.sessionType).subscribe((res) => {
              if (res) {
                ref.close();
                ref.afterClosed().subscribe((c) => {
                  this.result.emit(res);
                  if (isGroup) {
                    if (res.length > 0) {
                      NotifyAppComponent.displayToast(
                        'success',
                        'Assign dentist to appointments',
                        'Appointments are now assigned with the dentist'
                      );
                    } else {
                      NotifyAppComponent.displayToast(
                        'warning',
                        'Assign dentist to appointments',
                        'None of the selected appointments can be assigned with the dentist'
                      );
                    }
                  } else {
                    NotifyAppComponent.displayToast(
                      'success',
                      'Assign dentist to appointment',
                      'Appointment is now assigned with the dentist'
                    );
                  }
                });
              }
            });
          } else {
            ref.close();
          }
        });
      }
    });
  }

  missAppointment(item, isGroup = false) {
    const assignDentistEvent = () => {
      const ref = RootAppComponent.dialog.open(ConfirmDialogMessageComponent, {
        data: {
          icon: this.appointmentUtil.operations.miss.icon,
          title,
          yesLabel: 'Submit',
          noLabel: 'Cancel',
          textLabel: 'Comment',
          description,
          messageLabel: 'You can add a comment to be sent to the patient',
          messageTitle: '',
          dateTimeLabel: 'Booking date and time',
          isDateRequired: false,
          isDateTimeRequired: false,
          isDateTimeInput: false,
          isDateInput: false,
          isTextInputRequired: false,
          isTextAreaRequired: false,
          isTextArea: true,
          isTextInput: false,

          isCheckbox: false,
          checkboxLabel: 'Notify the patient',
          option: item['MerchantContact_key'] || null,

          isOption,
          isOptionRequired,
          optionLabel,
          optionCode: 'ID',
          optionViewValue: 'CalculatedName',
          optionList,
        },
        width: '650px',
      });
      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
      ref.componentInstance.onConfirm.subscribe((res) => {
        if (res && res.confirm == true) {
          const data: any = {
            operation: 'miss',
            comment: res.message,
            dentistID: null,
          };

          if (isGroup) {
            data.ids = item;
          } else {
            data.id = item.ID;
          }

          if (res.optionValue) {
            data.dentistID = res.optionValue;
          }
          this.appointmentService.editAppointment(item.ID, data, this.sessionType).subscribe((res) => {
            if (res) {
              ref.close();
              ref.afterClosed().subscribe((c) => {
                this.result.emit(res);
                if (isGroup) {
                  if (res.length > 0) {
                    NotifyAppComponent.displayToast(
                      'success',
                      'Mark missed appointments',
                      'Appointments are now marked as missed'
                    );
                  } else {
                    NotifyAppComponent.displayToast(
                      'warning',
                      'Assign dentist to appointments',
                      'None of the selected appointments can be marked as missed'
                    );
                  }
                } else {
                  NotifyAppComponent.displayToast(
                    'success',
                    'Mark missed appointment',
                    'Appointment is now marked as missed'
                  );
                }
              });
            }
          });
        } else {
          ref.close();
        }
      });
    };

    let title;
    let description;
    let _date;
    let _time;

    if (isGroup) {
      title = `Appointments missed`;
      description = '<p>Did patients miss the appointments</p>';
    } else {
      if (item['Date']) {
        _date = this.customDate.transform(item['Date']);
      }
      if (item['Time']) {
        _time = this.customDate.transform(item['Date'] + ' ' + item['Time'], 'hh:ss A');
      }
      title = `Appointment missed`;
      description =
        `<p>Did your patient ${item.CustomerOrProspect_CalculatedName}  miss the appointment` +
        `<strong> ${_date}</strong> at <strong> ${_time}</strong> ? </p>`;
    }

    let isOption = false;
    let isOptionRequired = false;
    let optionLabel = 'Who was the assigned dentist';
    let optionList = [];
    if (item && item.dentists && item.dentists.length > 0 && Array.isArray(item.dentists)) {
      optionList = item.dentists;
      isOption = true;
      isOptionRequired = false;
      optionLabel = 'Who was the assigned dentist';
      assignDentistEvent();
    } else {
      const payload = {
        merchantID: isGroup ? this.merchantID : item['Merchant_key'],
        canAcceptAppointment: true,
        fields: 'ID,CalculatedName',
      };
      this.dentistService.getList(payload, this.isPromoterOrAdmin).subscribe((res) => {
        if (res) {
          optionList = res;
          isOption = true;
          isOptionRequired = false;
          optionLabel = 'Who was the assigned dentist';

          assignDentistEvent();
        }
      });
    }
  }

  disableAppointment(item, isGroup = false) {
    const confirmDialogParams = {
      data: new ConfirmDialog(
        'block',
        'Are you sure?',
        '<p>Once the appointment is disabled the patient will not be able to access to it or change it </p>',
        'No',
        'Yes'
      ),
    };
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, confirmDialogParams);
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        const payload: any = {
          isActive: false,
        };

        if (isGroup) {
          payload.ids = item;
        }
        this.appointmentService.editAppointment(item.ID, payload, this.sessionType).subscribe((res) => {
          if (res) {
            ref.close();
            ref.afterClosed().subscribe((c) => {
              this.result.emit(res);
              if (isGroup) {
                if (res.length > 0) {
                  NotifyAppComponent.displayToast('success', 'Disable appointments', 'Appointments are now disabled');
                } else {
                  NotifyAppComponent.displayToast(
                    'warning',
                    'Disable appointments',
                    'None of the selected appointments can be disabled'
                  );
                }
              } else {
                NotifyAppComponent.displayToast('success', 'Disable appointment', 'Appointment is now disabled');
              }
            });
          }
        });
      } else {
        ref.close();
      }
    });
  }

  enableAppointment(item, isGroup = false) {
    const confirmDialogParams = {
      data: new ConfirmDialog(
        'done',
        'Are you sure?',
        '<p>Once the appointment is enable the patient will  be able to access to it and change it </p>',
        'No',
        'Yes'
      ),
    };
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, confirmDialogParams);
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        const payload: any = {
          isActive: true,
        };

        if (isGroup) {
          payload.ids = item;
        }

        this.appointmentService.editAppointment(item.ID, payload, this.sessionType).subscribe((res) => {
          if (res) {
            ref.close();
            ref.afterClosed().subscribe((c) => {
              this.result.emit(res);
              if (isGroup) {
                if (res.length > 0) {
                  NotifyAppComponent.displayToast('success', 'Enable appointments', 'Appointments are now enabled');
                } else {
                  NotifyAppComponent.displayToast(
                    'warning',
                    'Enable appointments',
                    'None of the selected appointments can be enabled'
                  );
                }
              } else {
                NotifyAppComponent.displayToast('success', 'Enable appointment', 'Appointment is now enabled');
              }
            });
          }
        });
      } else {
        ref.close();
      }
    });
  }
}
