import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MerchantService } from '../../../feature/merchant/shared/merchant.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { SubscriptionPlanService } from '../shared/subscription-plan.service';
import { SubscriptionPlanLookupComponent } from '../subscription-plan-lookup/subscription-plan-lookup.component';
import { SubscriptionPlanViewModalComponent } from '../subscription-plan-view-modal/subscription-plan-view-modal.component';

@Component({
  selector: 'app-subscription-plan-user-edit',
  templateUrl: './subscription-plan-user-edit.component.html',
  styleUrls: ['./subscription-plan-user-edit.component.css'],
})
export class SubscriptionPlanUserEditComponent implements OnInit {
  @Output()
  getResult = new EventEmitter();

  color = Settings.global['primaryColor'] || '#1e88e5';
  colorSecond;

  @Output()
  close = new EventEmitter();

  utils = new UtilsClass();

  subscriptionPlanID;

  merchantPlanID;

  merchantIDs = [];

  sessionType;

  isModal = true;
  merchantID;
  price;
  waivedPeriod = Number(Settings.global['subscriptionPlanWaivedPeriod'] || 0);
  minTreatmentPlan = Number(Settings.global['subscriptionPlanMinTreatmentPlan'] || 0);
  isFreeSMS = true;
  smsPrice;
  startDate = new Date();
  promoterPlan;
  merchantPlan;

  changePromoterPlan = false;

  nextPlan;
  currentPracticeID;
  merchantName;

  constructor(
    private authenticationService: AuthenticationService,
    private merchantService: MerchantService,
    private subscriptionPlanService: SubscriptionPlanService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.merchantID != null) {
        this.merchantID = data.merchantID;
      }

      if (data.merchantIDs && data.merchantIDs.length > 0) {
        this.merchantIDs = data.merchantIDs;
      }

      if (data.merchantPlanID != null) {
        this.merchantPlanID = data.merchantPlanID;
      }

      if (data.subscriptionPlanID != null) {
        this.subscriptionPlanID = data.subscriptionPlanID;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getCurrentPractice().subscribe((currentPractice) => {
      if (currentPractice && currentPractice.ID) {
        this.currentPracticeID = currentPractice.ID;
      }
      this.authenticationService.getSessionType().subscribe((st) => {
        this.sessionType = st;

        this.setup();
      });
    });
  }

  setup() {
    if (this.subscriptionPlanID && !this.merchantPlanID) {
      this.subscriptionPlanService
        .getPromoterPlanDetails(this.subscriptionPlanID, {}, this.sessionType)
        .subscribe((res) => {
          if (res) {
            if (this.merchantID) {
              this.merchantService
                .getMerchantDetails(this.merchantID, { fields: 'TradingAs' }, this.sessionType)
                .subscribe((m) => {
                  if (m && m.TradingAs) {
                    this.merchantName = m.TradingAs;
                  }
                });
            }

            this.promoterPlan = res;
            this.buildItemPromoter(res);

            if (!this.subscriptionPlanID) {
              this.selectPlan();
            }
          }
        });
    } else if (this.merchantPlanID) {
      this.subscriptionPlanService
        .getMerchantPlanDetails(
          this.merchantPlanID,
          {
            selectNext: true,
            selectPromoterPlan: true,
          },
          this.sessionType
        )
        .subscribe((res) => {
          if (res) {
            this.merchantPlan = res;

            if (this.merchantPlan['Merchant_key']) {
              this.merchantService
                .getMerchantDetails(this.merchantPlan['Merchant_key'], { fields: 'TradingAs' }, this.sessionType)
                .subscribe((m) => {
                  if (m && m.TradingAs) {
                    this.merchantName = m.TradingAs;
                  }
                });
            }

            if (this.merchantPlan && this.merchantPlan.nextPlan) {
              if (this.merchantPlan.nextPlan.Color) {
                if (this.merchantPlan.nextPlan.Color.split('|')[0]) {
                  this.merchantPlan.nextPlan.color = this.merchantPlan.nextPlan.Color.split('|')[0];
                }

                if (this.merchantPlan.nextPlan.Color.split('|')[1]) {
                  this.merchantPlan.nextPlan.colorSecond = this.merchantPlan.nextPlan.Color.split('|')[1];
                }
              }

              this.nextPlan = this.merchantPlan.nextPlan;
            }

            if (this.merchantPlan && this.merchantPlan.promoterPlan) {
              this.promoterPlan = this.merchantPlan.promoterPlan;
            }
            this.buildItem(res);

            if (!this.subscriptionPlanID) {
              this.selectPlan();
            }
          }
        });
    } else {
      this.selectPlan();
    }
  }

  buildItem(res) {
    if (res && res.ID) {
      if (res.Merchant_key) {
        this.merchantID = res.Merchant_key;
      }
      if (res.ID) {
        this.subscriptionPlanID = res.ID;
      }
      if (res['StartDate']) {
        this.startDate = res['StartDate'];
      }

      if (res['Price']) {
        this.price = Number(res['Price']);
      }

      if (res['MinTreatmentPlansSent']) {
        this.minTreatmentPlan = res['MinTreatmentPlansSent'];
      }
      if (res['WaivedPeriodInDays']) {
        this.waivedPeriod = res['WaivedPeriodInDays'];
      }

      if (res['Is_SMS_Free'] === '1') {
        this.isFreeSMS = true;
      } else {
        this.isFreeSMS = false;
      }

      if (res['SMS_Price'] && this.isFreeSMS != true) {
        this.smsPrice = Number(res['SMS_Price']);
      } else {
        this.smsPrice = 0;
      }

      if (res.Color) {
        if (res.Color.split('|')[0]) {
          this.color = res.Color.split('|')[0];
        }

        if (res.Color.split('|')[1]) {
          this.colorSecond = res.Color.split('|')[1];
        }
      } else {
        this.color = this.color;
      }
    }
  }

  delete(id) {
    if (id) {
      const confirm = new ConfirmDialog(
        'delete',
        '',
        'Are you sure you want to delete this Merchant subscription plan ?',
        'No',
        'Yes'
      );
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.subscriptionPlanService.deleteMerchantPlan(id).subscribe((res) => {
            if (res) {
              this.nextPlan = null;
              NotifyAppComponent.displayToast('success', 'Success!', 'Merchant subscription plan is removed ');
              ref.close();
            }
          });
        }
      });
    }
  }

  buildItemPromoter(res) {
    if (res && res.ID) {
      if (res.ID) {
        this.subscriptionPlanID = res.ID;
      }
      if (res['StartDate']) {
        this.startDate = res['StartDate'];
      }

      if (res['Price'] === '0' || res['Price'] === 0) {
        this.price = 0;
      } else if (res['Price']) {
        this.price = res['Price'];
      }

      if (res['Waived_PeriodInDays']) {
        this.waivedPeriod = res['Waived_PeriodInDays'];
      }

      if (res['Min_TreatmentPlans_Sent']) {
        this.minTreatmentPlan = res['Min_TreatmentPlans_Sent'];
      }
      if (res['Is_SMS_Free'] === '1') {
        this.isFreeSMS = true;
      } else {
        this.isFreeSMS = false;
      }

      if (res['SMS_Price'] && this.isFreeSMS == true) {
        this.smsPrice = res['SMS_Price'];
      } else {
        this.smsPrice = 0;
      }

      if (res.Color) {
        if (res.Color.split('|')[0]) {
          this.color = res.Color.split('|')[0];
        }

        if (res.Color.split('|')[1]) {
          this.colorSecond = res.Color.split('|')[1];
        }
      } else {
        this.color = this.color;
      }
    }
  }

  selectDateTime(e) {
    if (e) {
      this.startDate = e;
    }
  }

  viewSubPlan(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(SubscriptionPlanViewModalComponent, {
        data: {
          subscriptionPlanID: id,
          displaySubscribeButton: false,
        },
        width: '1000px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res) {
          ref.close();
        }
      });
    }
  }

  selectPlan() {
    const ref = RootAppComponent.dialog.open(SubscriptionPlanLookupComponent, {
      data: {
        displaySubscribeButton: false,
      },
      width: '800px',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res) {
        ref.close();
      }
    });
    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        if (res && res.ID && res.ID != this.subscriptionPlanID) {
          this.changePromoterPlan = true;
        }
        this.promoterPlan = res;
        this.buildItemPromoter(res);
        ref.close();
      }
    });
  }

  getSubscriptionPlanImage(id) {
    return this.subscriptionPlanService.getPromoterPlanThumbnailImageStreamLink(id);
  }

  save() {
    const payload: any = {
      status: 'Approved',
      price: this.price,
      merchantID: this.merchantID,
      waivedPeriod: this.waivedPeriod,
      minTreatmentPlan: this.minTreatmentPlan,
      isFreeSMS: this.isFreeSMS,
      smsPrice: this.smsPrice,
      startDate: this.startDate,
      subscriptionPlanID: this.promoterPlan.ID,
    };

    if (this.merchantIDs && this.merchantIDs.length > 0) {
      payload.merchantIDs = this.merchantIDs;
      RootAppComponent.stopSubPlanTimer = true;
      this.subscriptionPlanService.createMerchantPlanGroup(payload).subscribe((plans) => {
        if (plans && plans.length > 0) {
          if (this.currentPracticeID) {
            const index = plans.findIndex((item) => {
              if (item && item.Merchant_key === this.currentPracticeID) {
                return true;
              }
              return false;
            });

            if (index !== -1) {
              this.authenticationService.getCurrentPlanTransactionForce(false).subscribe((res1) => {
                this.authenticationService.getCurrentUserForce(false).subscribe((res) => {
                  this.authenticationService.getCurrentForceAccess(false).subscribe((access) => {
                    this.authenticationService.getCurrentPlanForce(false, true).subscribe((res) => {
                      if (access) {
                        RootAppComponent.setCurrentAccess.emit(access);
                      }

                      RootAppComponent.stopSubPlanTimer = false;
                      this.getResult.emit(plans);
                      NotifyAppComponent.displayToast('success', 'Success!', 'User Subscription plans  are  created');
                    });
                  });
                });
              });
            } else {
              this.getResult.emit(plans);
              RootAppComponent.stopSubPlanTimer = false;
              NotifyAppComponent.displayToast('success', 'Success!', 'User Subscription plans  are  created');
            }
          }
        }
      });
    } else {
      if (this.changePromoterPlan === false && this.merchantPlanID) {
        this.subscriptionPlanService.modifyMerchantPlan(this.merchantPlanID, payload).subscribe((res) => {
          if (res) {
            this.getResult.emit(res);
            NotifyAppComponent.displayToast('success', 'Success!', 'User Subscription plan  is Modified');
          }
        });
      } else {
        RootAppComponent.stopSubPlanTimer = true;
        this.subscriptionPlanService.createMerchantPlan(payload, this.sessionType).subscribe((res) => {
          if (res) {
            if (this.currentPracticeID && res && res.Merchant_key == this.currentPracticeID) {
              this.authenticationService.getCurrentPlanTransactionForce(false).subscribe((res1) => {
                this.authenticationService.getCurrentUserForce(false).subscribe((res) => {
                  this.authenticationService.getCurrentForceAccess(false).subscribe((access) => {
                    this.authenticationService.getCurrentPlanForce(false, true).subscribe((res) => {
                      if (access) {
                        RootAppComponent.setCurrentAccess.emit(access);
                      }

                      RootAppComponent.stopSubPlanTimer = false;
                      this.getResult.emit(res);
                      NotifyAppComponent.displayToast('success', 'Success!', 'User Subscription plan  is created');
                    });
                  });
                });
              });
            } else {
              RootAppComponent.stopSubPlanTimer = false;
              this.getResult.emit(res);
              NotifyAppComponent.displayToast('success', 'Success!', 'User Subscription plan  is created');
            }
          }
        });
      }
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
}
