<div class="row clearfix" *ngIf="message">
  <!-- blue heading -->
  <div class="card-header primary-gradient-img clearfix" *ngIf="isModal == true">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear white pull-right mat-button" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <!-- merchant calculated name -->
      <div class="row titleArea clearfix">
        <h2 class="message-title summary-header rm-m">
          <mat-icon class="fas fa-eye mr"></mat-icon>
          View Message
        </h2>
      </div>
    </div>
  </div>
  <!-- end blue heading -->

  <div class="row clearfix" *ngIf="messageType == 'E'">
    <!-- <mat-dialog-content>
      <div [innerHTML]="message.BodyBase64 | safeHtml"></div>
    </mat-dialog-content> -->

    <app-blob-view
      class="row clearfix flex flexCol full-width full-height"
      [isDownload]="false"
      [isProceed]="false"
      [isPrint]="false"
      [canProceed]="false"
      [content]="message.BodyBase64"
      [extension]="'html'"
    ></app-blob-view>
  </div>

  <div class="row clearfix" *ngIf="messageType == 'S'">
    <mat-dialog-content>
      <div class="marvel-device iphone8">
        <div class="top-bar"></div>
        <div class="sleep"></div>
        <div class="volume"></div>
        <div class="camera"></div>
        <div class="sensor"></div>
        <div class="speaker"></div>
        <div class="screen">
          <span class="messages">
            <p class="speech-bubble preview" [innerHTML]="message.Body | safeHtml"></p>
          </span>
        </div>

        <div class="home"></div>
        <div class="bottom-bar"></div>
      </div>
    </mat-dialog-content>
  </div>
</div>
