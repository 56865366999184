<ipv-container-modal>
  <div banner>
    <div class="flex" style="justify-content: space-between">
      <div class="flex full-width">
        <!--        <app-product-view-logo class="supplierIcon pull-left" [productID]="appliance['ID']" [LastModified]="appliance['LastModified']">-->
        <!--        </app-product-view-logo>-->
        <div style="display: flex; flex-direction: column">
          <h2 class="summary-header rm-m heading-1">{{product['Product'] | titlecase}}</h2>
          <div class="subLabel" style="padding-left: 10px" *ngIf="product['Model']">Model: {{product['Model']}}</div>
        </div>
      </div>

      <button class="btn-close btn-clear mat-button white" (click)="close()">
        <mat-icon style="position: relative; top: -25px">clear</mat-icon>
      </button>
    </div>
    <div class="flex full-width" style="justify-content: flex-end">
      <div class="date-chip" *ngIf="product.DateTimeCreated && product.DateTimeCreated!=='0000-00-00'">
        <p class="small rm-m mr">
          <mat-icon class="fas fa-clock"></mat-icon>
          {{product.DateTimeCreated | TimeLabelPast}} ago
        </p>
      </div>

      <div class="date-chip">
        <ng-container>
          <p class="clearfix small rm-m" *ngIf="product['Is_SerialNum_Mandatory']=='1'">
            <span class="chip-enable">Serialised</span>
          </p>

          <p class="clearfix small rm-m" *ngIf="product['Is_SerialNum_Mandatory']=='0'">
            <span class="chip-disable">Unserialised</span>
          </p>
        </ng-container>
      </div>

      <div class="date-chip">
        <ng-container>
          <p class="clearfix small rm-m" *ngIf="product['Is_Available']=='1'">
            <span class="chip-enable">Available</span>
          </p>

          <p class="clearfix small rm-m" *ngIf="product['Is_Available']=='0'">
            <span class="chip-disable">Unavailable</span>
          </p>
        </ng-container>
      </div>
    </div>
  </div>

  <div content>
    <ng-container [ngSwitch]="industryType">
      <ipv-retail-view-product *ngSwitchCase="'retail'" [product]="product"></ipv-retail-view-product>
      <ipv-retail-view-product *ngSwitchCase="'breeze'" [product]="product"></ipv-retail-view-product>
      <ipv-dental-view-product *ngSwitchCase="'dental'" [customerAppliance]="product"></ipv-dental-view-product>
    </ng-container>
  </div>
</ipv-container-modal>
