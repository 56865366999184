import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { environment } from 'environments/environment';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { FileInsertionComponent } from '../../../shared/components/file-insertion/file-insertion.component';
import { IframeViewComponent } from '../../../shared/components/iframe-view/iframe-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { DentistService } from '../../dentist/shared/dentist.service';
import { LandingPageHeaderLookupComponent } from '../../document/landing-page-header-lookup/landing-page-header-lookup.component';
import { DocumentService } from '../../document/shared/document.service';
import { ProductService } from '../../product/shared/product.service';
import { ProductUtil } from '../../product/shared/productUtil.type';
import { TagCreateEditComponent } from '../../tags/tag-create-edit/tag-create-edit.component';
import { InstantOfferExternalLinkViewComponent } from '../instant-offer-external-link-view/instant-offer-external-link-view.component';
import { InstantOfferService } from '../shared/instant-finance-offer.service';

@Component({
  selector: 'instant-offer-create',
  templateUrl: './instant-offer-create.component.html',
  styleUrls: ['./instant-offer-create.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: '*' }))),
    ]),
  ],
})
export class InstantOfferCreateComponent implements OnInit {
  settings = Settings.global;
  isInstantFinanceLink = false;

  instantOfferLinkID;
  getLink = new EventEmitter();
  getResult = new EventEmitter();
  close = new EventEmitter();

  sessionType;

  title = 'Simple & Painless Financing for Dental Procedures';

  displayDisclaimerMessage;

  _disclaimerMessage;
  disclaimerMessage;
  disclaimerMessageRaw;

  _description = `<p>At {{PracticeTradingAs}} , we pride ourselves on providing our patients with straightforward, open, and transparent solutions.  <br><br>
So with ${Settings.global['brandName']} {{PatientFirstName}} , we are giving you 3 easy to understand options for a range of dental procedures.</p>`;
  _message = `<p>Hi {{PatientFirstName}},<br>
As discussed, we provide payment options for your next procedure. Your new Smile is just three steps away.
<ul>
<li> <strong>Step 1:</strong> Choose the product right for you, and our simple and guided application process takes as little as 5 minutes to complete (depending on the selected product).</li>
<li> <strong>Step 2:</strong> ${Settings.global['brandName']} will notify us once approved, and you are free to commence treatment.
${Settings.global['brandName']} settles your bill with us as the procedure is completed - Hassle-free.</li>
<li> <strong>Step 3:</strong> Smile and enjoy - {{PatientFirstName}} now you can confidently continue your treatment, knowing ${Settings.global['brandName']} has removed the financial burden. <br>
If you have any further queries on which plan suits your needs, feel free to call the team at ${Settings.global['brandName']} on {{PromoterPhoneNumber}}. </li>
</ul>
<br>
Kind Regards<br>
{{DentistFirstName}} <br>
{{PracticeTradingAs}} <br>
{{PracticePhoneNumber}}</p>`;

  description = `<p>At {{PracticeTradingAs}} , we pride ourselves on providing our patients with straightforward, open, and transparent solutions.  <br><br>
  So with ${Settings.global['brandName']} {{PatientFirstName}} , we are giving you 3 easy to understand options for a range of dental procedures.</p>`;
  descriptionRaw = `<p>At {{PracticeTradingAs}} , we pride ourselves on providing our patients with straightforward, open, and transparent solutions.  <br><br>
  So with ${Settings.global['brandName']} {{PatientFirstName}} , we are giving you 3 easy to understand options for a range of dental procedures.</p>`;
  messageRaw = `<p>Hi {{PatientFirstName}},<br>
  As discussed, we provide payment options for your next procedure. Your new Smile is just three steps away.
  <ul>
  <li> <strong>Step 1:</strong> Choose the product right for you, and our simple and guided application process takes as little as 5 minutes to complete (depending on the selected product).</li>
  <li> <strong>Step 2:</strong> ${Settings.global['brandName']} will notify us once approved, and you are free to commence treatment.
  ${Settings.global['brandName']} settles your bill with us as the procedure is completed - Hassle-free.</li>
  <li> <strong>Step 3:</strong> Smile and enjoy - {{PatientFirstName}} now you can confidently continue your treatment, knowing ${Settings.global['brandName']} has removed the financial burden. <br>
  If you have any further queries on which plan suits your needs, feel free to call the team at ${Settings.global['brandName']} on {{PromoterPhoneNumber}}. </li>
  </ul>
  <br>
  Kind Regards<br>
  {{DentistFirstName}} <br>
  {{PracticeTradingAs}} <br>
  {{PracticePhoneNumber}}</p>`;
  message = `<p>Hi {{PatientFirstName}},<br>
  As discussed, we provide payment options for your next procedure. Your new Smile is just three steps away.
  <ul>
  <li> <strong>Step 1:</strong> Choose the product right for you, and our simple and guided application process takes as little as 5 minutes to complete (depending on the selected product).</li>
  <li> <strong>Step 2:</strong> ${Settings.global['brandName']} will notify us once approved, and you are free to commence treatment.
  ${Settings.global['brandName']} settles your bill with us as the procedure is completed - Hassle-free.</li>
  <li> <strong>Step 3:</strong> Smile and enjoy - {{PatientFirstName}} now you can confidently continue your treatment, knowing ${Settings.global['brandName']} has removed the financial burden. <br>
  If you have any further queries on which plan suits your needs, feel free to call the team at ${Settings.global['brandName']} on {{PromoterPhoneNumber}}. </li>
  </ul>
  <br>
  Kind Regards<br>
  {{DentistFirstName}} <br>
  {{PracticeTradingAs}} <br>
  {{PracticePhoneNumber}}</p>`;

  sendEmail = false;
  sendSMS = false;
  theme = {
    code: 'blue',
    background: 'linear-gradient(135deg, #0092eb 0%, #0dbad3 100%)',
    color: '#1b8bdd',
  };
  documentTypes = ['Treatment Plan', 'Additional Document'];
  link;
  themes;
  instantOfferID;

  treatmentAmount;
  productUtil = new ProductUtil();
  createdForPreview = false;

  messageMode = 'all';

  isActive = true;
  tags = [];
  selectedTags = [];
  defaultSelectedTags = [];

  addTag = new EventEmitter();

  isPromoterOrAdmin;
  maxLoanAmount;
  minLoanAmount;

  isExpired = false;

  expireDate;
  expireDateType = 'never';
  expireDateTypes = [
    {
      code: '1days',
      label: '1 Day',
      days: 1,
    },

    {
      code: '3days',
      label: '3 Days',
      days: 3,
    },
    {
      code: 'week',
      label: '1 Week',
      days: 7,
    },
    {
      code: '2weeks',
      label: '2 Weeks',
      days: 15,
    },
    {
      code: 'month',
      label: '1 Month',
      days: 30,
    },
    {
      code: 'month',
      label: '3 Months',
      days: 90,
    },
    {
      code: 'year',
      label: '1 Year',
      days: 365,
    },
    {
      code: 'setDate',
      label: 'Set Date',
      days: 10,
    },
    {
      code: 'never',
      label: 'Never',
    },
  ];
  expireDateDefault;
  isHeaderImage = true;
  merchantID;
  contactID;
  cardID;
  merchant;
  promoter;
  card;
  contact;

  step = 1;
  displayPatient = false;
  tableName;

  displayAppointmentPage = true;
  displayTipsAndOffersPage = true;
  displayGoingGreenPage = true;

  loadMessage = false;
  loadContact = false;
  loadMerchant = false;
  loadPromoter = false;
  loadCard = false;

  fileIDs = [];

  addFile = new EventEmitter();

  headerImage =
    'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/character/dentist_hero_pair.png';
  financedProductGroup = [];
  dentists;
  productGroup;

  currentDentistID;
  isModal = true;
  constructor(
    private documentService: DocumentService,

    private dentistService: DentistService,
    private authenticationService: AuthenticationService,
    private instantOfferService: InstantOfferService,
    private productService: ProductService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.description = this._description;
    this.descriptionRaw = this._description;

    this.message = this._message;
    this.messageRaw = this._message;

    if (data) {
      if (data && data.cardID) {
        this.cardID = data.cardID;
      }
      if (data && data.card) {
        this.card = data.card;
      }
      if (data && data.merchant) {
        this.merchant = data.merchant;
      }
      if (data && data.messageMode) {
        this.messageMode = data.messageMode;
      }

      if (data && data.promoter) {
        this.promoter = data.promoter;
      }
      if (data && data.contact) {
        this.contact = data.contact;
      }
      if (data && data.contactID) {
        this.contactID = data.contactID;
      }

      if (data && data.tableName) {
        this.tableName = data.tableName;
      }

      if (data && data.sendEmail != null) {
        this.sendEmail = data.sendEmail;
      }

      if (data && data.isInstantFinanceLink != null) {
        this.isInstantFinanceLink = data.isInstantFinanceLink;
      }

      if (data && data.sendSMS != null) {
        this.sendSMS = data.sendSMS;
      }

      if (data && data.isActive != null) {
        this.isActive = data.isActive;
      }

      if (data && data.merchantID) {
        this.merchantID = data.merchantID;
      }

      if (data && data.title) {
        this.title = data.title;
      }
      if (data && data.description) {
        this.description = data.description;
      }
    }
  }

  ngOnInit() {
    if (Settings.global['context'] === 'p4p') {
      this.theme = {
        code: 'ee350b',
        background: ' linear-gradient(-135deg, #1caaa0 -10.31%, #7462ef  97.05%)',
        color: '#7462ef',
      };

      this.headerImage =
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/duke-contact+-+Copy.png?type=b';

      this.title = 'Simple & Painless Financing';
      this._description = `<p>At {{PracticeTradingAs}} , we pride ourselves on providing our customers with straightforward, open, and transparent solutions.
      <br /><br />
      So with ${Settings.global['brandName']} {{PatientFirstName}} , we are giving you 3 easy to understand options for a range of consumer purchases.</p>`;

      this._message = `<p>Hi {{PatientFirstName}},<br>
      As discussed, we provide payment options for your next procedure.
      <ul>
      <li> <strong>Step 1:</strong> Choose the product right for you, and our simple and guided application process takes as little as 5 minutes to complete (depending on the selected product).</li>
      <li> <strong>Step 2:</strong> ${Settings.global['brandName']} will notify us once approved, and you are free to commence treatment.
      ${Settings.global['brandName']} settles your bill with us as the procedure is completed - Hassle-free.</li>
      <li> <strong>Step 3:</strong> Smile and enjoy - {{PatientFirstName}} now you can confidently continue your treatment, knowing ${Settings.global['brandName']} has removed the financial burden. <br>
      If you have any further queries on which plan suits your needs, feel free to call the team at ${Settings.global['brandName']} on {{PromoterPhoneNumber}}. </li>
      </ul>
      <br>
      Kind Regards<br>
      {{DentistFirstName}} <br>
      {{PracticeTradingAs}} <br>
      {{PracticePhoneNumber}}</p>`;



      this.description=this._description;
      this.descriptionRaw=this._description;


      this.message=this._message;
      this.messageRaw=this._message;


    } else if (Settings.global['context'] === 'breeze') {
      this.title = 'Simple & Painless Financing';
      this._description = `<p>At {{PracticeTradingAs}} , we pride ourselves on providing our customers with straightforward, open, and transparent solutions.
      <br /><br />
      So with ${Settings.global['brandName']} {{PatientFirstName}} , we are giving you 3 easy to understand options for a range of consumer purchases.</p>`;
      this._message ==
        `<p>Hi {{PatientFirstName}},</p><p><br />
      As we talked about, we provide payment options for your next purchase. Your new Kirby is just three steps away.</p>
      <ul>
      <li><strong>Step 1:</strong> Choose the product right for you, and our simple and guided application process takes as little as 5 minutes to complete (depending on the selected product). </li>
      <br /> <li><strong>Step 2:</strong> Breeze will let us know once approved. Breeze settles your bill with us, and we delivery your goods - Hassle-free.</li>

      <li>
      <strong>Step 3:</strong> Enjoy - {{PatientFirstName}} now you can confidently continue to enjoy your new Kirby, knowing Breeze has removed the financial burden. <br />
      If you have any further queries on which plan suits your needs, please call the team at Breeze on {{PromoterPhoneNumber}}.</li>

      </ul><p>
      &nbsp;<br /><br />
      Kind Regards<br />{{DentistFirstName}} <br />
      {{PracticeTradingAs}} <br />
      {{PracticePhoneNumber}}</p>`;
      this.headerImage = 'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/MSSS-3-600x600.jpg?type=c';



      this.description=this._description;
      this.descriptionRaw=this._description;


      this.message=this._message;
      this.messageRaw=this._message;
    }

    if (this.isInstantFinanceLink == true) {
      this._message = '';
      this.messageRaw = '';
      this.message = '';
    }

    this.authenticationService.getCurrentDentist().subscribe((currentDentist) => {
      this.authenticationService.getSessionType().subscribe((sessionType) => {
        this.sessionType = sessionType;
        this.authenticationService.isPromoterOrAdmin().subscribe((r) => {
          this.isPromoterOrAdmin = r;

          if (currentDentist && currentDentist.ID) {
            this.currentDentistID = currentDentist.ID;
          }

          if ((!this.merchantID && !this.merchant) || this.isPromoterOrAdmin != true) {
            this.authenticationService.getCurrentPractice().subscribe((r) => {
              if (r && r.ID) {
                this.merchantID = r.ID;
                this.merchant = r;

                this.dentistService
                  .getList({ merchantID: this.merchantID, fields: 'ID,CalculatedName' }, this.isPromoterOrAdmin)
                  .subscribe((res) => {
                    if (res && res.length > 0) {
                      this.dentists = res;
                    }
                  });

                this.init();
              }
            });
          } else if ((this.merchant && this.merchant.ID) || this.merchantID) {
            if (this.merchant && this.merchant.ID) {
              if (!this.merchantID) {
                this.merchantID = this.merchant.ID;
              }
              this.dentistService
                .getList({ merchantID: this.merchantID, fields: 'ID,CalculatedName' }, this.isPromoterOrAdmin)
                .subscribe((res) => {
                  if (res && res.length > 0) {
                    this.dentists = res;
                  }
                });

              this.init();
            } else {
              this.authenticationService.getMerchantDetails(this.merchantID, {}, this.sessionType).subscribe((r) => {
                if (r && r.ID) {
                  this.merchant = r;

                  this.dentistService
                    .getList({ merchantID: this.merchantID, fields: 'ID,CalculatedName' }, this.isPromoterOrAdmin)
                    .subscribe((res) => {
                      if (res && res.length > 0) {
                        this.dentists = res;
                      }
                    });

                  this.init();
                }
              });
            }
          }
        });
      });
    });
  }
  init() {
    if (this.merchant && this.merchant.ID) {
      this.buildMerchant(this.merchant);
    }

    const p1 = {
      merchantID: this.merchantID,
      isFinance: true,
    };

    this.productService.getMerchantProductGroup(p1, this.isPromoterOrAdmin).subscribe((res) => {
      if (res && res.length > 0) {
        const index = res.findIndex((item) => {
          if (item && item['GroupCode'] === 'GPF') {
            return true;
          }
          return false;
        });
        if (index != -1 && !this.productGroup) {
          this.financedProductGroup = res;
          this.productGroup = 'GPF';
        } else if (!this.productGroup) {
          this.financedProductGroup = res;
          if (res && res[0] && res[0]['GroupCode']) {
            this.productGroup = res[0]['GroupCode'];
          }
        } else {
          this.financedProductGroup = res;
        }
      }

      if (this.isInstantFinanceLink == true) {
        this.displayPatient = false;
      } else if ((this.card && this.card.ID) || this.cardID) {
        this.displayPatient = true;
      }

      this.loadMessage = true;

      const pTag = {
        isPromoter: false,
        merchantID: this.merchantID,
        fields: 'Label',
      };

      this.instantOfferService.getTagsList(pTag, this.sessionType).subscribe((res) => {
        if (res) {
          this.tags = _.map(res, (item) => {
            if (item && item.Label) {
              return item.Label;
            }

            return null;
          });
        }
      });

      this.authenticationService.getSessionType().subscribe((r) => {
        this.sessionType = r;

        if (!this.merchantID) {
          this.authenticationService.getCurrentPractice().subscribe((r) => {
            if (r && r.ID) {
              this.merchantID = r.ID;
            }

            this.setup();
            this.instantOfferService.instantOfferListTheme().subscribe((t) => {
              if (t && t.length > 0) {
                this.themes = t;
              }
              if (this.productGroup) {
                this.getLoanAmount(this.productGroup);
              }
            });
          });
        } else {
          this.setup();
          this.instantOfferService.instantOfferListTheme().subscribe((t) => {
            if (t && t.length > 0) {
              this.themes = t;
            }
            if (this.productGroup) {
              this.getLoanAmount(this.productGroup);
            }
          });
        }
      });
    });
  }

  setup() {
    if (this.cardID) {
      this.authenticationService.getCardDetails(this.cardID, this.tableName, {}, this.sessionType).subscribe((r) => {
        if (r && r.ID) {
          this.card = r;
          this.cardID = r.ID;
        }
        this.loadCard = true;
      });
    }

    if (!this.promoter) {
      this.authenticationService.getPromoter().subscribe((r) => {
        if (r) {
          this.promoter = r;
        }
        this.loadPromoter = true;
      });
    } else {
      this.loadPromoter = true;
    }

    if (!this.contactID && !this.contact) {
      this.authenticationService.getCurrentDentist().subscribe((r) => {
        if (r) {
          this.contact = r;
          this.contactID = r.ID;
        }
        this.loadContact = true;
      });
    } else if (this.contact && this.contact.ID) {
      this.contactID = this.contact.ID;
      this.loadContact = true;
    } else if (this.contactID) {
      this.authenticationService.getCardDetails(this.contactID, 'Contact', {}, this.sessionType).subscribe((r) => {
        if (r && r.ID) {
          this.contact = r;
          this.contactID = r.ID;
        }
        this.loadContact = true;
      });
    }

    if (!this.merchantID && !this.merchant) {
      this.authenticationService.getCurrentPractice().subscribe((r) => {
        if (r) {
          this.merchant = r;
        }
        this.loadMerchant = true;
      });
    } else if (this.merchant && this.merchant.ID) {
      this.merchantID = this.merchant.ID;
      this.loadMerchant = true;
    } else if (this.merchantID) {
      this.authenticationService.getCardDetails(this.merchantID, 'Merchant', {}, this.sessionType).subscribe((r) => {
        if (r && r.ID) {
          this.merchant = r;
          this.merchantID = r.ID;
        }
        this.loadMerchant = true;
      });
    }
  }

  changeDentist() {
    if (this.contactID) {
      this.loadContact = false;
      this.authenticationService.getCardDetails(this.contactID, 'Contact', {}, this.sessionType).subscribe((r) => {
        if (r && r.ID) {
          this._description = this.descriptionRaw;

          this._disclaimerMessage = this.disclaimerMessageRaw;
          this._message = this.messageRaw;
          this.contact = r;
          this.contactID = r.ID;
        }
        this.loadContact = true;
      });
    }
  }

  createNewTag() {
    const ref = RootAppComponent.dialog.open(TagCreateEditComponent, {
      data: {
        merchantID: this.merchantID,
      },

      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res && res.Label) {
        if (
          (!this.tags || (this.tags && this.tags.length <= 0)) &&
          (!this.defaultSelectedTags || (this.defaultSelectedTags && this.defaultSelectedTags.length <= 0))
        ) {
          this.defaultSelectedTags = JSON.parse(JSON.stringify([res.Label]));
          this.tags = JSON.parse(JSON.stringify([res.Label]));
        } else {
          if (!this.tags || (this.tags && this.tags.length <= 0)) {
            this.tags.push(res.Label);
          }
          this.addTag.emit(res.Label);
        }
        ref.close();
      }
    });
  }
  selectDateToFilter(d) {
    this.expireDate = d;
  }

  getSelectedTags(e) {
    this.selectedTags = e || [];
  }

  showExpired() {
    if (
      moment(this.expireDate).isBefore(moment()) &&
      moment(this.expireDate).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD') &&
      this.expireDateType !== 'never'
    ) {
      return true;
    }

    return false;
  }
  setExpireDate() {
    if (this.expireDateType && this.expireDateType !== 'never') {
      const index = this.expireDateTypes.findIndex((item) => {
        if (item && item.code && item.code === this.expireDateType) {
          return true;
        }
        return false;
      });

      if (index != -1) {
        const item = this.expireDateTypes[index];

        if (item && item.days) {
          this.expireDateDefault = moment().add(item.days, 'd').toDate();
        }
      }
    }

    this.isExpired = false;
  }

  getResultDescription(e) {
    if (e) {
      this.description = e.convertedMessage;
      this.descriptionRaw = e.message;
    } else {
      this.description = null;
      this.descriptionRaw = null;
    }
  }
  getResultMessage(e) {
    if (e) {
      this.message = e.convertedMessage;

      this.messageRaw = e.message;
    } else {
      this.message = null;
      this.messageRaw = null;
    }
  }

  selectTheme(d) {
    if (d && d.code) {
      this.theme = d;
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  view(id) {
    if (id) {
      const link = `${Settings.global['publicSiteFrontendLink']}/offer/${id}`;

      const ref2 = RootAppComponent.dialog.open(IframeViewComponent, {
        data: {
          link,
        },
        width: '80%',
        height: '92vh',
        panelClass: 'noCard',
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });
    }
  }

  buildMerchant(merchant) {
    if (merchant) {
      if (merchant['Hide_Landing_Page_HdThumbnail'] === '1') {
        this.isHeaderImage = false;
      } else {
        this.isHeaderImage = true;
      }

      if (merchant['Hide_Landing_Page_Appointment'] === '1') {
        this.displayAppointmentPage = false;
      } else {
        this.displayAppointmentPage = true;
      }

      if (merchant['Hide_Landing_Page_Going_Green'] === '1') {
        this.displayGoingGreenPage = false;
      } else {
        this.displayGoingGreenPage = true;
      }

      if (merchant['Hide_Landing_Page_Tips'] === '1') {
        this.displayTipsAndOffersPage = false;
      } else {
        this.displayTipsAndOffersPage = true;
      }

      if (this.merchant['Hide_Disclaimer_Message'] === '1') {
        this.displayDisclaimerMessage = false;
      } else if (this.merchant['Hide_Disclaimer_Message'] === '0') {
        this.displayDisclaimerMessage = true;
      }

      if (this.merchant['Disclaimer_Message']) {
        this.disclaimerMessage = this.merchant['Disclaimer_Message'];
        this._disclaimerMessage = this.merchant['Disclaimer_Message'];
      }

      if (merchant['Landing_Page_Default_Label']) {
        this.title = merchant['Landing_Page_Default_Label'];
      }

      if (merchant['Landing_Page_Default_Descrpt']) {
        this.description = merchant['Landing_Page_Default_Descrpt'];
        this._description = merchant['Landing_Page_Default_Descrpt'];
        this.descriptionRaw = merchant['Landing_Page_Default_Descrpt'];
      }

      if (merchant['Landing_Page_Default_Message'] && this.isInstantFinanceLink != true) {
        this.message = merchant['Landing_Page_Default_Message'];
        this._message = merchant['Landing_Page_Default_Message'];
        this.messageRaw = merchant['Landing_Page_Default_Message'];
      }

      if (merchant['Landing_Page_Default_Sender_key']) {
        this.contactID = merchant['Landing_Page_Default_Sender_key'];
      }

      if (merchant['Landing_Page_Default_Thumbnail']) {
        this.headerImage = merchant['Landing_Page_Default_Thumbnail'];
      }
      if (merchant['Landing_Page_Default_Product_Group']) {
        this.productGroup = merchant['Landing_Page_Default_Product_Group'];
      }

      if (merchant['Landing_Page_Default_Theme'] && this.themes && this.themes.length > 0) {
        const index = this.themes.findIndex((e) => {
          if (e && e.code && e.code === merchant['Landing_Page_Default_Theme']) {
            return true;
          }

          return false;
        });
        if (index !== -1) {
          this.theme = this.themes[index];
        }
      }
    }
  }

  changeAmount() {
    if (this.treatmentAmount) {
      this.card.AmountToFinance = this.treatmentAmount;
    }
  }
  getResultMessageDisclaimer(e) {
    if (e) {
      this.disclaimerMessage = e.convertedMessage;

      this.disclaimerMessageRaw = e.message;
    } else {
      this.disclaimerMessage = null;
      this.disclaimerMessageRaw = null;
    }
  }
  submitInstantOffer(sendResult = true) {
    const p: any = {
      merchantID: this.merchantID,
      cardID: this.cardID,
      patientID: this.cardID,
      theme: this.theme.code,
      label: this.title,
      description: this.description,
      tags: this.selectedTags,
      message: this.message,
      isActive: this.isActive,
      canExpire: false,
      expireDate: null,
      treatmentAmount: this.treatmentAmount,
      productGroupCode: this.productGroup,
      contactID: this.contactID,
      fileIDs: this.fileIDs || [],

      headerImage: this.headerImage,
      displayAppointmentPage: this.displayAppointmentPage,
      displayTipsAndOffersPage: this.displayTipsAndOffersPage,
      displayGoingGreenPage: this.displayGoingGreenPage,
      sendEmail: this.sendEmail,
      sendSMS: this.sendSMS,

      disclaimerMessage: this.disclaimerMessageRaw,
      displayDisclaimerMessage: this.displayDisclaimerMessage,
    };

    if (this.isHeaderImage != true) {
      p.headerImage = 'none';
    }

    if (this.expireDateType != 'never') {
      p.canExpire = true;
      p.expireDate = this.expireDate;
    }
    if (this.createdForPreview == true && sendResult === false) {
      p.isActive = false;
      p.sendSMS = false;
      p.sendEmail = false;
    } else if (sendResult != false) {
      this.createdForPreview = false;
    }

    if (this.instantOfferID) {
      this.instantOfferService.modifyInstantOffer(this.instantOfferID, p, this.sessionType).subscribe((r) => {
        if (r && r.ID) {
          if (this.isPromoterOrAdmin == true) {
            this.link = `${environment.nodeUrl}/o/${r.ID}?t=if`;
          } else {
            this.link = `${environment.nodeUrl}/_m/${r.ID}?t=if`;
          }

          r.link = this.link;
          if (sendResult === true) {
            this.getResult.emit(r);
            this.getLink.emit(this.link);
          } else {
            this.view(r.ID);
          }
        }
      });
    } else {
      if (sendResult === false) {
        p.isActive = false;
        p.sendSMS = false;
        p.sendEmail = false;
      }
      this.instantOfferService.createInstantOffer(p, this.sessionType).subscribe((r) => {
        if (r && r.ID) {
          if (sendResult == false) {
            this.createdForPreview = true;
          }
          this.instantOfferID = r.ID;

          if (this.isPromoterOrAdmin == true) {
            this.link = `${environment.nodeUrl}/o/${r.ID}?t=if`;
          } else {
            this.link = `${environment.nodeUrl}/_m/${r.ID}?t=if`;
          }
          r.link = this.link;
          if (sendResult === true) {
            this.getResult.emit(r);

            this.getLink.emit(this.link);
          } else {
            this.view(r.ID);
          }
        }
      });
    }
  }

  submitOfferLink(sendResult = true) {
    const payload: any = {
      merchantID: this.merchantID,
      theme: this.theme.code,
      title: this.title,
      description: this.description,
      tags: this.selectedTags,
      productDetails: this.message,
      isActive: this.isActive,
      treatmentAmount: this.treatmentAmount,
      productGroup: this.productGroup,
      contactID: this.contactID,
      senderID: this.contactID,
      fileIDs: this.fileIDs || [],

      headerImage: this.headerImage,
      displayAppointmentPage: this.displayAppointmentPage,
      displayTipsAndOffersPage: this.displayTipsAndOffersPage,
      displayGoingGreenPage: this.displayGoingGreenPage,
    };

    if (this.instantOfferLinkID) {
      this.instantOfferService
        .modifyInstantOfferLink(this.instantOfferLinkID, payload, this.sessionType)
        .subscribe((r) => {
          if (r && r.ID) {
            this.instantOfferLinkID = r.ID;
            NotifyAppComponent.displayToast('success', 'Successfull', ``);

            const ref2 = RootAppComponent.dialog.open(InstantOfferExternalLinkViewComponent, {
              data: {
                id: r.ID,
              },
              width: '900px',
              panelClass: 'noCard',
            });

            ref2.componentInstance.close.subscribe((res) => {
              ref2.close();
            });
          }
        });
    } else {
      this.instantOfferService.createInstantOfferLink(payload, this.sessionType).subscribe((r) => {
        if (r && r.ID) {
          this.instantOfferLinkID = r.ID;

          NotifyAppComponent.displayToast('success', 'Successfull', ``);

          const ref2 = RootAppComponent.dialog.open(InstantOfferExternalLinkViewComponent, {
            data: {
              id: r.ID,
            },
            width: '900px',
          });

          ref2.componentInstance.close.subscribe((res) => {
            ref2.close();
          });
        }
      });
    }
  }

  submit(sendResult = true) {
    if (this.isInstantFinanceLink == true) {
      this.submitOfferLink(sendResult);
    } else {
      this.submitInstantOffer(sendResult);
    }
  }

  getLoanAmount(productCode) {
    if (productCode) {
      const payload = {
        groupCode: productCode,
        merchantID: this.merchantID,
        fields: 'Parameter.MaxLoanValue,Parameter.MinLoanValue',
      };
      this.productService.getList(payload, this.isPromoterOrAdmin).subscribe((res) => {
        if (res && res.length > 0) {
          const temp = res.map((x) => Number(x['Parameter.MaxLoanValue']));
          this.maxLoanAmount = Math.max(...temp) || 0;

          const temp2 = res.map((x) => Number(x['Parameter.MinLoanValue']));
          this.minLoanAmount = Math.min(...temp2) || 0;
        }
      });
    }
  }
  onCompleFile(f) {
    if (f && f[0]) {
      this.addFile.emit(f[0]);
      this.fileIDs.push(f[0]);
    }
  }
  removeNewFile(e) {
    if (e) {
      const index = this.fileIDs.findIndex((item) => {
        if (item && item === e) {
          return true;
        }
        return false;
      });

      if (index !== -1) {
        this.fileIDs.splice(index, 1);
      }
    }
  }

  upload() {
    const ref = RootAppComponent.dialog.open(FileInsertionComponent, {
      data: {
        documentType: 'LP_HI',
        displayGallery: false,
        isGallery: true,
        isFixedAspectRatio: true,
        roundCropper: true,
        aspectRatioType: 'full',
        documentNature: 'image',
        keepOriginal: false,
        maxFileSize: 1,
      },
      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.getResult.subscribe((document) => {
      if (document && document.AWS_Bucket_Name && document.AWS_URL) {
        this.headerImage = `${Settings.global['AWSS3Prefix']}/${document.AWS_Bucket_Name}${document.AWS_URL}?type=c`;

        ref.close();
      }
    });
  }

  getCircle(headerImage) {
    if (headerImage && headerImage.indexOf('?type=c') != -1) {
      return true;
    }

    return false;
  }
  getMerchantLink() {
    return environment.nodeUrl + '/merchant/practice-picture-stream/' + this.merchantID;
  }

  galleryHeader() {
    const ref2 = RootAppComponent.dialog.open(LandingPageHeaderLookupComponent, {
      data: {
        merchantID: this.merchantID,
      },
      width: '900px',
    });

    ref2.componentInstance.close.subscribe((res) => {
      ref2.close();
    });

    ref2.componentInstance.getResult.subscribe((res) => {
      if (res) {
        this.headerImage = res;
        ref2.close();
      }
    });
  }
  URLifyDescription(s) {
    return this.documentService.URLifyDescription(s);
  }

  URLify(d) {
    return this.documentService.URLify(d);
  }

  next1() {
    this.step = 2;
  }
  next2() {
    this.step = 3;
  }

  preview1() {
    this.step = 1;
  }

  preview2() {
    this.step = 2;
  }
}
