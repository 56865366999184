import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { MessageService } from '../shared/message.service';
import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';

import { ModuleViewComponent } from '../../../core/module/module-view/module-view.component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-sms-send',
  templateUrl: './sms-send.component.html',
  styleUrls: ['./sms-send.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.stacked-card-view', style({ opacity: 0 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.stacked-card-view', style({ opacity: 1 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class SmsSendComponent implements OnInit {
  @Input()
  displayEmoji = true;
  @Input()
  displaySaveAsTemplate = true;

  @Input()
  displayMessageTemplate = true;

  @Input()
  displayPatient = true;

  @Input() label = 'Please insert a message';

  @Input()
  displayContact = true;

  @Input()
  displayMerchant = true;

  @Input()
  displayPromoter = true;

  @Input()
  displayMarketing = true;

  @Input()
  showMarketingConsentWarning = false;

  @Input()
  isContactStaff = false;
  @Input()
  displayDentalOffers = true;

  @Input()
  displayDocument = true;

  messageTemplateID;
  @Input()
  marketingConsentDescription =
    'This customer has not consented to receiving marketing messages. By proceeding, you confirm the message is not marketing in nature.';

  @Input()
  marketingConsentTitle = '';

  @Input()
  saveAsTemplate = false;

  @Input()
  textLength = 160;
  @Input()
  isModal = true;

  @Input()
  isSMSPreview = true;
  @Input()
  isDesktopPreview = false;
  @Input()
  isSaveAsTemplate = true;

  @Input()
  isSendSMS = true;

  @Input()
  isCreate = true;

  selectedCustomer

  createdRecord;

  @Input()
  tableName = 'CustomersAndProspects';

  @Input()
  cardID;

  @Input()
  merchantID;

  @Input()
  contactID = null;
  isContactLoaded = true;
  @Input()
  contact;
  @Input()
  merchant;
  @Input()
  card;

  @Input()
  displayExpend = true;

  @Input()
  isAction = true;

  @Input()
  message;

  _message;
  close = new EventEmitter();
  @Input()
  encode = true;

  isModulePatientSMSActive = Settings.global['isModulePatientSMSActive'];

  util = new UtilsClass();

  @Input()
  visitorsType = 'sms';
  @Input()
  invitationID;
  @Input()
  contractID;
  @Input()
  financeOfferID

  @Output()
  getMessage = new EventEmitter();
  @Output()
  getResult = new EventEmitter();
  @Output()
  getConvertedMessage = new EventEmitter();
  @Output()
  updateMarketingConsent = new EventEmitter();
  messageIsLoadingEvent = new EventEmitter();
  convertedMessage;
  isPromoterOrAdmin = false;

  sessionType;
  @Input()
  dentists = [];
  messagePayload;

  @Input()
  isCustomizedSender = false;

  @Input()
  clear = new EventEmitter();
  promoter;
  constructor(
    private utilService: UtilsService,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data && data.cardID) {
        this.cardID = data.cardID;
        this.isModal = true;
      }

      if (data && data.merchantID) {
        this.merchantID = data.merchantID;
        this.isModal = true;
      }
      if (data && data.contactID) {
        this.contactID = data.contactID;
        this.isModal = true;
      }
      if (data && data.contact) {
        this.contact = data.contact;
        this.isModal = true;
      }
      if (data && data.merchant) {
        this.merchant = data.merchant;
        this.isModal = true;
      }
      if (data && data.card) {
        this.card = data.card;
        this.isModal = true;
      }

      if (data && data.tableName) {
        this.tableName = data.tableName;
        this.isModal = true;
      }

      if (data && data.message) {
        this.message = data.message;
        this._message = data.message;
        this.isModal = true;
      }
      if (data && data.isModal != null) {
        this.isModal = data.isModal;
      }

      if (data && data.isAction != null) {
        this.isAction = data.isAction;
      }

      if (data && data.isSendSMS != null) {
        this.isSendSMS = data.isSendSMS;
      }
      if (data && data.isSMSPreview != null) {
        this.isSMSPreview = data.isSMSPreview;
      }

      if (data && data.isCustomizedSender != null) {
        this.isCustomizedSender = data.isCustomizedSender;
      }

      if (data && data.isDesktopPreview != null) {
        this.isDesktopPreview = data.isDesktopPreview;
      }
      if (data && data.displaySaveAsTemplate != null) {
        this.displaySaveAsTemplate = data.displaySaveAsTemplate;
      }

      if (data && data.saveAsTemplate != null) {
        this.saveAsTemplate = data.saveAsTemplate;
      }

      if (data && data.isSaveAsTemplate != null) {
        this.isSaveAsTemplate = data.isSaveAsTemplate;
      }
      if (data && data.displayEmoji != null) {
        this.displayEmoji = data.displayEmoji;
      }
      if (data && data.isCreate != null) {
        this.isCreate = data.isCreate;
      }

      if (data && data.displayMessageTemplate != null) {
        this.displayMessageTemplate = data.displayMessageTemplate;
      }
      if (data && data.displayPatient != null) {
        this.displayPatient = data.displayPatient;
      }
      if (data && data.displayContact != null) {
        this.displayContact = data.displayContact;
      }
      if (data && data.displayMerchant != null) {
        this.displayMerchant = data.displayMerchant;
      }
      if (data && data.displayPromoter != null) {
        this.displayPromoter = data.displayPromoter;
      }
      if (data && data.displayMarketing != null) {
        this.displayMarketing = data.displayMarketing;
      }
      if (data && data.displayDentalOffers != null) {
        this.displayDentalOffers = data.displayDentalOffers;
      }
      if (data && data.displayDocument != null) {
        this.displayDocument = data.displayDocument;
      }

      if (data && data.encode != null) {
        this.encode = data.encode;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
      this.isPromoterOrAdmin = isPromoterOrAdmin;
      this.authenticationService.getSessionType().subscribe((sessionType) => {
        this.sessionType = sessionType;
        this.authenticationService.getCurrentDentist().subscribe((CurrentDentist) => {
          if (CurrentDentist && CurrentDentist.ID) {
            if (!this.contact) {
              this.contact = CurrentDentist;
              this.contactID = CurrentDentist.ID;
              this.isContactLoaded = true;
            } else {
              this.isContactLoaded = true;
            }
          }

          if (this.isPromoterOrAdmin === true) {
            this.authenticationService.getPromoter().subscribe((r) => {
              if (r && r.data) {
                this.promoter = r.data;
              } else if (r) {
                this.promoter = r;
              }
            });
          }

          if (this.merchant && this.merchant.ID) {
            this.merchantID = this.merchant.ID;
          }

          if (this.isCustomizedSender == true && (!this.dentists || (this.dentists && this.dentists.length <= 0))) {
            this.authenticationService
              .getDentistList(
                {
                  fields: 'ID,CalculatedName,LastModified,ContactType.Label',
                  orderBy: 'CalculatedName',
                  merchantID: this.merchantID,
                },
                this.isPromoterOrAdmin
              )
              .subscribe((res) => {
                if (res) {
                  this.dentists = res;
                }
              });
          }
          this.utilService.getCurrentAccess().subscribe((access) => {
            if (access) {
              this.setAccess(access);
            }
          });
        });
      });
    });
  }

  setAccess(_access) {
    if (_access) {
      this.isModulePatientSMSActive = _access['isModulePatientSMSActive'];
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  getResultEvent(m) {
    this.messagePayload = m;

    if (this.invitationID) {
      this.messagePayload.invitationID = this.invitationID;
    } else {
      this.messagePayload.invitationID = null;
    }

    if (this.financeOfferID) {
      this.messagePayload.financeOfferID = this.financeOfferID;
    } else {
      this.messagePayload.financeOfferID = null;
    }



    if (this.contractID) {
      this.messagePayload.contractID = this.contractID;
    } else {
      this.messagePayload.contractID = null;
    }

    this.message = m.message;
    this.convertedMessage = m.convertedMessage;

    if (m.contactID) {
      this.contactID = m.contactID;
    }

    if (m.messageTemplateID) {
      this.messageTemplateID = m.messageTemplateID;
    }

    if (m.cardID) {
      this.cardID = m.cardID;
    }

    if (m.merchantID) {
      this.merchantID = m.merchantID;
    }
  }

  sendResult() {
    this.getMessage.emit(this.message || null);

    this.getConvertedMessage.emit(this.convertedMessage || null);

    let cardName;
    if (this.card) {
      cardName = this.card.CalculatedName;
    }

    if (this.card && this.tableName === 'Merchant') {
      cardName = this.card.TradingAs;
    }

    if (this.createdRecord && (this.createdRecord.Card_Name === '' || this.createdRecord.Card_Name === '')) {
      this.createdRecord.Card_Name = cardName;
    }

    let p = {
      message: this.message,
      messageTemplateID: this.messageTemplateID,
      convertedMessage: this.convertedMessage,
      saveAsTemplate: this.saveAsTemplate,
      createdRecord: this.createdRecord,
      MarketingConsent: this.card['MarketingConsent.Given']
    };

    this.getResult.emit(p);
  }

  save(sendAsMerchant = false) {


    if (this.isCreate == true && this.messagePayload) {
      this.messageIsLoadingEvent.emit(true);

      let payload: any = this.messagePayload;

      payload.sendAsMerchant = sendAsMerchant;

      payload.iStaffMessage = this.isContactStaff;

      if (
        (this.showMarketingConsentWarning == true &&
          this.cardID &&
          this.cardID !== '00000000000000000000' &&
          (this.tableName == 'CustomersAndProspects' ||
            this.tableName == 'Customer' ||
            this.tableName == 'Prospect')) ||
        (this.showMarketingConsentWarning == true &&
          this.card &&
          this.card.ID &&
          this.card.ID !== '00000000000000000000' &&
          (this.tableName == 'CustomersAndProspects' || this.tableName == 'Customer' || this.tableName == 'Prospect'))
      ) {
        let cardID = this.cardID;

        if (!cardID) {
          cardID = this.card.ID;
        }
        const confirmDialog = new ConfirmDialog(
          'info',
          `${this.marketingConsentTitle}`,
          `<p>${this.marketingConsentDescription}</p>`,
          'No',
          'Update consent to marketing'
        );

        const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
          data: confirmDialog,
        });

        ref.componentInstance.onConfirm.subscribe((res) => {
          if (res == true) {
            let payloadPatient = {
              isMarketing: true,
              marketingConsent: true,
            };
            this.messageService.modifyCustomerProspect(cardID, payloadPatient, this.sessionType).subscribe((selectedCustomer) => {
              this.messageService.sendSMS(payload, this.sessionType).subscribe((res) => {
                if (res && res.ID) {
                  this.card=selectedCustomer
                  this.messageIsLoadingEvent.emit(false);
                  this.createdRecord = res;
                  NotifyAppComponent.displayToast('success', 'Successfull', 'Message is sent');

                  this.sendResult();

                  this._message = null;
                  this.message = null;

                  this.clear.emit(true);
                }
                ref.close();
              });
            });
          } else {
            ref.close();
          }
        });
      } else {
        this.messageService.sendSMS(payload, this.sessionType).subscribe((res) => {
          if (res && res.ID) {
            this.messageIsLoadingEvent.emit(false);
            this.createdRecord = res;
            NotifyAppComponent.displayToast('success', 'Successfull', 'Message is sent');

            this.sendResult();

            this._message = null;
            this.message = null;

            this.clear.emit(true);
          }
        });
      }
    } else {
      this.sendResult();
      this._message = null;
    }
  }

  viewModule() {
    let ref = RootAppComponent.dialog.open(ModuleViewComponent, {
      data: {
        moduleCode: 'isModulePatientSMSActive',
      },
      width: '1000px',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res) {
        ref.close();
      }
    });
  }

  changeDentist() {
    if (this.contactID) {
      this.isContactLoaded = false;
      this.authenticationService.getCardDetails(this.contactID, 'Contact', {}, this.sessionType).subscribe((r) => {
        if (r && r.ID) {
          this._message = this.message;
          this.contact = r;
          this.isContactLoaded = true;
        }
      });
    }
  }

  getMerchantNumber() {
    if (this.merchant && this.merchant.Twilio_Number) {
      return this.merchant.Twilio_Number;
    } else if (this.isPromoterOrAdmin == true) {
      return this.getPromoterNumber();
    }

    return null;
  }

  getPromoterNumber() {
    if (this.isContactStaff == true) {
      if (this.promoter && this.promoter['Twilio.PromoterTeam']) {
        return this.promoter['Twilio.PromoterTeam'];
      }
    } else {
      if (this.promoter && this.promoter['Twilio.Promoter']) {
        return this.promoter['Twilio.Promoter'];
      }
    }

    return null;
  }
}
