import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { SubscriptionPlanService } from '../shared/subscription-plan.service';

import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { SimplePageCreateEditComponent } from '../../helper/simple-page-create-edit/simple-page-create-edit.component';
import { SimplePageViewComponent } from '../../helper/simple-page-view/simple-page-view.component';

@Component({
  selector: 'app-subscription-plan-card-list-global',
  templateUrl: './subscription-plan-card-list-global.component.html',
  styleUrls: ['./subscription-plan-card-list-global.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class SubscriptionPlanCardListGlobalComponent implements OnInit {
  root = '/merchant';
  selectedIDs = [];
  subscriptionPlans = [];
  sessionType;

  constructor(
    private subscriptionPlanService: SubscriptionPlanService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    UtilsClass.scrollUp();
    this.activeRoute.params.subscribe((params) => {
      this.authenticationService.getSessionType().subscribe((res) => {
        this.sessionType = res;
        this.subscriptionPlanService
          .getPromoterPlanList({ orderBy: '-Is_Active,-Is_Public,Price' }, this.sessionType)
          .subscribe((list) => {
            if (list) {
              this.subscriptionPlans = list;
            }
          });
      });
    });
  }

  remove(id) {
    if (id) {
      const index = this.subscriptionPlans.findIndex((item) => {
        if (item && item.ID && item.ID == id) {
          return true;
        }
        return false;
      });

      if (index !== -1) {
        this.subscriptionPlans.splice(index, 1);
        this.subscriptionPlans = JSON.parse(JSON.stringify(this.subscriptionPlans));
      }
    }
  }

  getSelect(id) {
    if (id) {
      this.router.navigate([
        this.root,
        {
          outlets: {
            page: ['subscription-plan-create-edit', id],
          },
        },
      ]);
    }
  }

  compare() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      this.selectedIDs = _.uniq(this.selectedIDs);

      this.router.navigate([
        this.root,
        {
          outlets: {
            page: ['subscription-plan-overview', this.selectedIDs.join(',')],
          },
        },
      ]);
    }
  }

  createNew() {
    this.router.navigate([this.root, { outlets: { page: ['subscription-plan-create-edit'] } }]);
  }

  freeAccount() {
    window.open(Settings['global']['publicSiteFrontendLink'] + '/partners/(page:pricing-free)', '_blank');
  }

  check(id) {
    if (id) {
      const index = this.selectedIDs.indexOf(id);

      if (index === -1) {
        this.selectedIDs.push(id);
      } else {
        this.selectedIDs.splice(index, 1);
      }
    }
  }

  termsEdit() {
    const ref = RootAppComponent.dialog.open(SimplePageCreateEditComponent, {
      data: {
        simplePageCode: 'subscriptionPlanTermAndCondition',
      },
      width: '800px',
    });

    ref.componentInstance.close.subscribe((confirmation) => {
      ref.close();
    });
  }

  termsView() {
    RootAppComponent.dialog.open(SimplePageViewComponent, {
      data: {
        simplePageCode: 'subscriptionPlanTermAndCondition',
        canAccept: false,
      },
      width: '800px',
    });
  }

  allPlans() {
    this.router.navigate([
      this.root,
      {
        outlets: {
          page: ['subscription-plan-management', 'all'],
        },
      },
    ]);
  }
}
