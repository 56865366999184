<!-- <div class="container"> -->
<div class="row clearfix" *ngIf="patient">
  <mat-card class="overview-card">
    <mat-card-header>
      <div class="example-header-image" mat-card-avatar></div>
      <mat-card-title>{{ patient.CalculatedName }}</mat-card-title>
      <mat-card-subtitle>{{ "KEYWORD.patient" | translate | titlecase }} Profile</mat-card-subtitle>
    </mat-card-header>

    <!-- <mat-tab-group [dynamicHeight]="true">

			  <mat-tab label="Details"> -->
    <app-patient-profile-merchant [patient]="patient"></app-patient-profile-merchant>
    <!-- </mat-tab>

			  <mat-tab label="Contacts">
			  	<app-patient-contacts></app-patient-contacts>
			  </mat-tab>

			  <mat-tab label="Lending">Content 2</mat-tab>

			  <mat-tab label="Marketing">Content 2</mat-tab>

			  <mat-tab label="Messaging">
			  	<app-patient-message-list></app-patient-message-list>
			  </mat-tab>

			  <mat-tab label="Action Log">Content 2</mat-tab>

			  <mat-tab label="Verification">Content 2</mat-tab>

			  <mat-tab label="Documents">
			  	<app-patient-documents></app-patient-documents>
			  </mat-tab>

			  <mat-tab label="Logons">Content 2</mat-tab>

			</mat-tab-group> -->
  </mat-card>
</div>
<!-- </div> -->
