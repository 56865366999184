<mat-card class="rel clearfix">
  <div class="card-header primary-gradient-img clearfix stacked">
    <div class="row clearfix">
      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m">SMS Promoter Filter</h2>
      </div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="full-width text-center" @simpleFadeAnimation>

      <mat-form-field class="full-width mt smb" *ngIf="merchants && merchants.length > 0 && singleChat != true"
        appearance="outline">
        <mat-label>Merchant</mat-label>

        <mat-select [(ngModel)]="merchantID" (ngModelChange)="getClientObject()" name="merchantID"
          placeholder="Merchant">
          <mat-select-trigger>
            <div class="full-width flex flex-cust" *ngIf="merchant && merchant.ID">
              <app-card-picture class="sms-merchant-pic prod text-center pull-left mr" [hideZoomButton]="true"
                [cardID]="merchant.ID" [hideZoomButton]="false" [tableName]="'Merchant'"
                [LastModified]="merchant['LastModified']">
              </app-card-picture>
              <p class="rm-m third-party-p full-width pull-left">
                {{ merchant.TradingAs }}
              </p>
              <button class="mr" (click)="$event.stopPropagation(); viewMerchant(merchantID)" mat-button
                mat-icon-button>
                <mat-icon>visibility</mat-icon>
              </button>
            </div>
          </mat-select-trigger>
          <mat-option [value]="currentMerchant.ID">
            <div class="full-width flex flex-cust">
              <app-card-picture class="sms-merchant-pic prod text-center pull-left mr" [hideZoomButton]="true"
                [cardID]="currentMerchant.ID" [hideZoomButton]="false" [tableName]="'Merchant'"
                [LastModified]="currentMerchant['LastModified']">
              </app-card-picture>
              <p class="rm-m third-party-p full-width pull-left">
                <strong>Promoter</strong>
              </p>

              <button class="mr" (click)="$event.stopPropagation(); viewMerchant(currentMerchant['ID'])" mat-button
                mat-icon-button>
                <mat-icon style="width: 40px">visibility</mat-icon>
              </button>
            </div>
          </mat-option>

          <mat-option *ngFor="let s of merchants | OrderArrayObject: ['TradingAs']" [value]="s.ID">
            <div class="full-width flex flex-cust">
              <app-card-picture class="sms-merchant-pic prod text-center pull-left mr" [hideZoomButton]="true"
                [cardID]="s.ID" [hideZoomButton]="false" [tableName]="'Merchant'" [LastModified]="s['LastModified']">
              </app-card-picture>
              <p class="rm-m third-party-p full-width pull-left">
                {{ s.TradingAs }}
              </p>

              <button class="mr" (click)="$event.stopPropagation(); viewMerchant(s['ID'])" mat-button mat-icon-button>
                <mat-icon style="width: 40px">visibility</mat-icon>
              </button>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="full-width " appearance="outline" *ngIf="isContactStaff!=true">
        <mat-label>Message Type </mat-label>
        <mat-select [(ngModel)]="messageType" name="messageType" placeholder="Message Type ">
          <mat-option *ngFor="let item of messageTypes" [value]="item.code">
            {{ item.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>



      <mat-form-field class="full-width " appearance="outline" *ngIf="isContactStaff==true">
        <mat-label>Message Type </mat-label>
        <mat-select [(ngModel)]="messageType" name="messageType" placeholder="Message Type ">
          <mat-option *ngFor="let item of messageStaffTypes" [value]="item.code">
            {{ item.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="full-width mb" *ngIf="isContactStaff!=true">
        <p class="text-left sml rm-mt" *ngIf="messageType === 'all'">View all communication sent via the platform</p>
        <p class="text-left sml rm-mt" *ngIf="messageType === 'merchantAll'">
          View all merchant messages (including hidden messages)
        </p>
        <p class="text-left sml rm-mt" *ngIf="messageType === 'merchant'">View messages as the merchant, see them</p>
        <p class="text-left sml rm-mt" *ngIf="messageType === 'promoter'">
          View only promoter messages (excluding merchant)
        </p>
        <p class="text-left sml rm-mt" *ngIf="messageType === 'customized'">
          View messages filtered by message template
        </p>
      </div>

      <mat-form-field class="full-width"
        *ngIf="messageType && messageType == 'customized' && templates && templates.length > 0 && isContactStaff!=true"
        appearance="outline">
        <mat-label>Templates</mat-label>
        <mat-select [(ngModel)]="templateTags" name="templates" placeholder="Templates" multiple>
          <mat-option *ngFor="let template of templates | OrderArrayObject: ['Label']" [value]="template.Tag">
            {{ template.Label }}
          </mat-option>
        </mat-select>
      </mat-form-field>


      <!--
      <mat-form-field
        class="full-width"
        *ngIf="promoterID && promoterID == merchantID && globalNumbers && globalNumbers.length > 0 && isContactStaff!=true"
        appearance="outline"
      >
        <mat-label>Destination Number</mat-label>
        <mat-select [(ngModel)]="globalNumber" name="templates" placeholder="Destination Number">
          <mat-option *ngFor="let globalNumber of globalNumbers" [value]="globalNumber.code">
            {{ globalNumber.label }}
          </mat-option>
        </mat-select>
      </mat-form-field> -->

      <div class="margin-btm mt ml mb pull-left" *ngIf="isContactStaff!=true">
        <mat-checkbox [(ngModel)]="inCludeCampaignMessages" color="accent" name="inCludeCampaignMessages">
          Include Marketing Campaign Messages
        </mat-checkbox>
      </div>

      <div class="margin-btm ml mb pull-left" *ngIf="singleChat != true && isContactStaff!=true">
        <mat-checkbox [(ngModel)]="loadDefaultChat" color="accent" name="loadDefaultChat">
          Load Latest Chats
        </mat-checkbox>
      </div>
    </div>
  </mat-dialog-content>
  <div class="drop-button text-center white-background">
    <hr />
    <button class="action-button btn pull-right smt" (click)="submit()" mat-raised-button color="accent">Submit</button>
    <button class="action-button btn pull-left smt" (click)="closeEvent()" mat-raised-button color="primary">
      Close
    </button>
  </div>
</mat-card>
