import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { uniqBy } from 'lodash';
import { UtilsService } from '../../../shared/services/utils.service';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';

@Component({
  selector: 'app-template-review',
  templateUrl: './template-review.component.html',
  styleUrls: ['./template-review.component.css'],
})
export class TemplateReviewComponent implements OnInit {
  @Input()
  hideMarkup = false;

  @Input()
  templateID;

  @Input()
  templateTag;

  @Input()
  invitationID;

  @Input()
  campaignID;
  @Input()
  campaign;

  @Input()
  hideOptions = false;

  @Output() closeModal = new EventEmitter();
  util = new UtilsClass();

  @Input()
  hideSubject = false;

  @Output() proceed = new EventEmitter();

  @Input()
  isPrint = false;

  @Input()
  isProceed = false;

  @Input()
  isDownload = false;

  @Input()
  proceedLabel = 'Use Template';

  @Input()
  previewInit;

  template;
  content;
  settings = Settings.global;
  iFrameSrc;
  iFrameSrcSecured;
  loadIFrame = false;
  preview = 'email';
  isModal = false;

  setting = Settings.global;
  currentPractice;
  tabs = [];

  constructor(
    private _sanitizer: DomSanitizer,
    private activeRoute: ActivatedRoute,
    private utilService: UtilsService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.isProceed != null) {
        this.isProceed = data.isProceed;
      }

      if (data.isDownload != null) {
        this.isDownload = data.isDownload;
      }

      if (data.proceedLabel) {
        this.proceedLabel = data.proceedLabel;
      }

      if (data.isPrint != null) {
        this.isPrint = data.isPrint;
      }

      if (data.templateID) {
        this.templateID = data.templateID;
        this.isModal = true;
      }

      if (data.templateTag) {
        this.templateTag = data.templateTag;
        this.isModal = true;
      }

      if (data.campaignID) {
        this.campaignID = data.campaignID;
        this.isModal = true;
      } else if (data.campaign && data.campaign.ID) {
        this.campaign = data.campaign;
        this.isModal = true;
      }

      if (data.invitationID) {
        this.invitationID = data.invitationID;
      }

      if (data.previewInit) {
        this.previewInit = data.previewInit;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getCurrentPractice().subscribe((currentPractice) => {
      this.currentPractice = currentPractice;
      this.activeRoute.params.subscribe((params) => {
        if (params['templateID']) {
          this.templateID = params['templateID'];
        }

        if (params['templateTag']) {
          this.templateTag = params['templateTag'];
        }

        this.setup();
      });
    });
  }

  proceedEvent() {
    this.proceed.emit(this.template);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.templateID && changes.templateID.previousValue != changes.templateID.currentValue) {
      this.setup();
    }
  }

  setup() {
    this.tabs = [];
    if (
      this.campaign &&
      this.campaign.ID &&
      (this.campaign['Status.Code'] == 'CMPL' ||
        this.campaign['Status.Code'] == 'PEND' ||
        this.campaign['Status.Code'] == 'ACTV') &&
      this.campaign['LandingPageCode']
    ) {
      const link = this.setting['publicSiteFrontendLink'] + `/campaign/${this.campaign.ID}`;
      this.updateIFrameSrc(link);

      if (this.previewInit != null) {
        this.preview = this.previewInit;
      } else {
        this.preview = 'landingPage';
      }
      this.tabs.push({
        label: 'Landing Page',
        icon: 'send',
        value: 'landingPage',
      });
    }

    if (this.templateID) {
      this.utilService.getTemplateDetails(this.templateID).subscribe((res) => {
        if (res && res['ID']) {
          this.template = res;

          if (this.template['Supports.Email'] == '1' && this.template['Supports.SMS'] == '1') {
            this.tabs.push({
              label: 'Email',
              icon: 'email',
              value: 'email',
            });
            this.tabs.push({
              label: 'SMS',
              icon: 'textsms',
              value: 'sms',
            });
          } else if (this.template['Supports.Email'] == '1' && this.template['Supports.SMS'] == '0') {
            this.tabs.push({
              label: 'Email',
              icon: 'email',
              value: 'email',
            });
          } else {
            this.tabs.push({
              label: 'SMS',
              icon: 'textsms',
              value: 'sms',
            });
          }

          if (this.template && this.template['Tag']) {
            this.template = this.template;

            if (this.previewInit != null) {
              this.preview = this.previewInit;
            } else if (this.template['Supports.Email'] == '1' && this.template['Supports.SMS'] == '1') {
              if (!this.preview) {
                this.preview = 'email';
              }
            } else if (this.template['Supports.Email'] == '1' && this.template['Supports.SMS'] == '0') {
              if (!this.preview) {
                this.preview = 'email';
              }
            } else {
              if (!this.preview) {
                this.preview = 'sms';
              }
            }

            this.utilService.previewMessageByTemplate(this.template['Tag']).subscribe((res) => {
              if (res) {
                this.content = res;
              } else {
                this.closeModalEvent();
              }
            });
          } else {
            this.closeModalEvent();
          }
        }
      });
    } else if (this.templateTag) {
      this.utilService.getTemplateTag(this.templateTag).subscribe((res) => {
        if (res && res['ID']) {
          this.template = res;

          if (this.template['Supports.Email'] == '1' && this.template['Supports.SMS'] == '1') {
            this.tabs.push({
              label: 'Email',
              icon: 'email',
              value: 'email',
            });
            this.tabs.push({
              label: 'SMS',
              icon: 'textsms',
              value: 'sms',
            });
          } else if (this.template['Supports.Email'] == '1' && this.template['Supports.SMS'] == '0') {
            this.tabs.push({
              label: 'Email',
              icon: 'email',
              value: 'email',
            });
          } else {
            this.tabs.push({
              label: 'SMS',
              icon: 'textsms',
              value: 'sms',
            });
          }
          this.templateID = res['ID'];

          if (this.template && this.template['Tag']) {
            this.template = this.template;

            if (this.previewInit != null) {
              this.preview = this.previewInit;
            } else if (this.template['Supports.Email'] == '1' && this.template['Supports.SMS'] == '1') {
              if (!this.preview) {
                this.preview = 'email';
              }
            } else if (this.template['Supports.Email'] == '1' && this.template['Supports.SMS'] == '0') {
              if (!this.preview) {
                this.preview = 'email';
              }
            } else {
              if (!this.preview) {
                this.preview = 'sms';
              }
            }
            const payload = {};
            if (this.invitationID) {
              payload['invitationID'] = this.invitationID;
            }
            this.utilService.previewMessageByTemplate(this.template['Tag'], payload).subscribe((res) => {
              if (res) {
                this.content = res;
              } else {
                this.closeModalEvent();
              }
            });
          } else {
            this.closeModalEvent();
          }
        }
      });
    } else {
      this.closeModalEvent();
    }
  }

  closeModalEvent() {
    this.closeModal.emit(true);
  }

  getImageLink(id, LastModified) {
    return this.utilService.getTemplateImageStreamLink(id, LastModified);
  }

  getTemplateImg() {
    this.utilService.getTemplateImage(this.templateID).subscribe((res) => {
      if (res) {
        if (res['Thumbnail.Pic']) {
          this.template['pic'] = {
            Extension: 'png',
            PhysicalFileInBLOB: res['Thumbnail.Pic'],
          };
        } else {
          this.template['pic'] = null;
        }
      } else {
        this.template['pic'] = null;
      }
    });
  }

  openLandingPage() {
    if (this.campaign && this.campaign.ID) {
      const url = `${this.settings['publicSiteFrontendLink']}/campaign/${this.campaign.ID}`;

      window.open(url, '_blank');
    } else if (this.template && this.template['LandingPageURL']) {
      const t = this.template;
      if (
        t['LandingPageURL'].indexOf('{{SITE_URL_Public}}') !== -1 ||
        t['LandingPageURL'].indexOf('{{SITE_URL_MERCHANT}}') !== -1
      ) {
        let nodeServer = environment.nodeUrl;
        if (nodeServer && nodeServer.indexOf('localhost') != -1) {
          nodeServer = 'https://sandbox.smileright.com.au/api';
        }

        let link = t['LandingPageURL'];

        link = link.replace('{{MERCHANT_CardKey}}', this.currentPractice.ID);
        link = link.replace('{{MERCHANT_TerminalCode}}', this.currentPractice.Terminal_Code);
        link = link.replace('{{SITE_URL_Public}}', this.settings['publicSiteFrontendLink']);

        link = link.replace('{{SITE_URL_MERCHANT}}/api', nodeServer);
        window.open(link, '_blank');
      } else {
        const url = t['LandingPageURL'];
        window.open(url, '_blank');
      }
    } else if (this.template && this.template['LandingPageCode']) {
      const url = `${this.settings['publicSiteFrontendLink']}/product/(page:main/${this.template['LandingPageCode']})`;

      window.open(url, '_blank');
    }
  }

  updateIFrameSrc(src: string): void {
    this.iFrameSrcSecured = this._sanitizer.bypassSecurityTrustResourceUrl(src);
  }

  onFrameLoad(e) {
    this.loadIFrame = true;
  }

  uniquteTab() {
    return uniqBy(this.tabs, 'label');
  }
}
