<div class="profile">
  <section class="profile-header primary-gradient-img rel expando">
    <app-customer-prospect-view-picture
      class="profile-lead-image"
      @simpleFadeAnimation
      [patientID]="patient['ID']"
      [LastModified]="patient['LastModified']"
    >
    </app-customer-prospect-view-picture>
    <app-customer-prospect-view-picture
      class="profile-shrink-image pointer"
      @simpleFadeAnimation
      [patientID]="patient['ID']"
      [LastModified]="patient['LastModified']"
      [hideZoomButton]="true"
      matTooltip="Click to expand"
    >
    </app-customer-prospect-view-picture>
    <div class="profile-header-details" @simpleFadeAnimation>
      <h2>{{ patient['FirstName'] }} {{ patient['MiddleName'] }} {{ patient['Name'] }}</h2>


      <p class="small" *ngIf="birthday && birthday != '0000-00-00'">
        Age: <strong>{{ birthday | age }}</strong>
      </p>

      <p class="small" *ngIf="patient['addresses.Calculated']">{{ patient['addresses.Calculated'] }}</p>
      <p class="small" *ngIf="patient['DateTimeCreated']">
        Created Date: {{ patient['DateTimeCreated'] | customDateTime }}
      </p>
      <p class="small" *ngIf="patient['LastModified_Human']">
        Last modified Date: {{ patient['LastModified_Human'] | customDateTime }}
      </p>
    </div>
  </section>

  <div class="profile-sidebar-container">
    <div class="row clearfix" @simpleFadeAnimation>
      <!-- Phone numbers -->
      <div class="row clearfix" *ngIf="patient['mobiles.Number'] || patient['workPhone']">
        <div class="icons">
          <mat-icon
            class="pointer"
            *ngIf="patient['mobiles.Number']"
            (click)="dial(patient['mobiles.Number'])"
            matTooltip="Call {{ patient['FirstName'] }}"
          >phone
          </mat-icon>
          <mat-icon
            class="pointer"
            *ngIf="patient['workPhone'] && patient['workPhone'] != 'undefined'"
            (click)="dial(patient['workPhone'])"
            matTooltip="Call {{ patient['FirstName'] }}"
          >phone
          </mat-icon>
        </div>
        <div class="row-content" @simpleFadeAnimation>
          <p class="rm-m" *ngIf="patient['mobiles.Number']">{{ patient['mobiles.Number'] | customPhone }}</p>
          <p class="small" *ngIf="patient['mobiles.Number']">Phone/Mobile</p>
          <p class="rm-m" *ngIf="patient['workPhone'] && patient['workPhone'] != 'undefined'">
            {{ patient['workPhone'] | customPhone: 'landLine' }}
          </p>
          <p class="small" *ngIf="patient['workPhone'] && patient['workPhone'] != 'undefined'">
            Work
          </p>
        </div>
      </div>
      <mat-divider *ngIf="patient['mobiles.Number'] || patient['workPhone']"></mat-divider>
      <!-- Email Addresses -->

      <div class="row clearfix" *ngIf="patient['emails.Email']">
        <div class="icons">
          <mat-icon
            class="pointer"
            (click)="email(patient['emails.Email'])"
            matTooltip="Email {{ patient['FirstName'] }}"
          >email
          </mat-icon>
        </div>
        <div class="row-content" @simpleFadeAnimation>
          <p class="rm-m">{{ patient['emails.Email'] }}</p>
          <p class="small">Primary Email</p>
        </div>
      </div>

      <mat-divider *ngIf="patient['emails.Email']"></mat-divider>

      <div class="consent" @simpleFadeAnimation>
        <!-- elec consent basic -->
        <span class="label green" *ngIf="patient['ElectronicCommsConsent.Given'] == '1'">
          <mat-icon>check</mat-icon> Electronic Consent
        </span>
        <span class="label red" *ngIf="patient['ElectronicCommsConsent.Given'] == '0'">
          <mat-icon>clear</mat-icon> Electronic Consent
        </span>
        <!-- marketing consent basic -->
        <span class="label green" *ngIf="patient['MarketingConsent.Given'] == '1'">
          <mat-icon>check</mat-icon> Marketing Consent
        </span>
        <span class="label red" *ngIf="patient['MarketingConsent.Given'] == '0'">
          <mat-icon>clear</mat-icon> Marketing Consent
        </span>
        <!-- data storage basic -->
        <span class="label green" *ngIf="patient['ElectronicCommsConsent.Given'] == '1'">
          <mat-icon>check</mat-icon> Data Storage
        </span>
        <span class="label red" *ngIf="patient['ElectronicCommsConsent.Given'] == '0'">
          <mat-icon>clear</mat-icon> Data Storage
        </span>
        <!-- privacy basic -->
        <span class="label green" *ngIf="patient['ElectronicCommsConsent.Given'] == '1'">
          <mat-icon>check</mat-icon> Privacy Consent
        </span>
        <span class="label red" *ngIf="patient['ElectronicCommsConsent.Given'] == '0'">
          <mat-icon>clear</mat-icon> Privacy Consent
        </span>
      </div>

      <mat-divider *ngIf="patient['ReferredCustomer'] == '1'"></mat-divider>

      <div class="consent">
        <span class="label green" *ngIf="patient['ReferredCustomer'] == '1'">
          <mat-icon>check</mat-icon> Referred {{"KEYWORD.patient" | translate | titlecase}}
        </span>
      </div>
    </div>
  </div>
</div>
