import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { InvitationService } from '../../invitation/shared/services/invitation.service';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { AppointmentService } from '../shared/appointment.service';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { AppointmentLookupService } from '../shared/appointmentLookup.service';

@Component({
  selector: 'app-appointment-create-customer',
  templateUrl: './appointment-create-customer.component.html',
  styleUrls: ['./appointment-create-customer.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.stacked-card-view', style({ opacity: 0 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.stacked-card-view', style({ opacity: 1 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class AppointmentCreateCustomerComponent implements OnInit {
  @Input() invitationID;

  isModal = true;
  merchantProfile;
  defaultProfile = 'assets/img/placeholder.png';
  merchantID;
  merchant;

  bestContactTimes = [];
  bestContactTime;
  types = [];
  type;
  subTypes = [];
  subType;
  comment;

  isCreated = false;
  sessionType;
  isApplicationProceed = false;

  typeID;
  subTypeID;
  displayReasonForVisiting = true;

  cancelLabel = 'Cancel';
  submitLabel = 'Submit';

  @Output()
  result = new EventEmitter();

  @Output()
  close = new EventEmitter();

  constructor(
    private utilService: UtilsService,
    private authenticationService: AuthenticationService,
    private appointmentService: AppointmentService,
    private merchantService: MerchantService,
    private invitationService: InvitationService,
    private appointmentLookupService: AppointmentLookupService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      if (data.invitationID) {
        this.invitationID = data.invitationID;
      }
      if (data.typeID) {
        this.typeID = data.typeID;
      }

      if (data.subTypeID) {
        this.subTypeID = data.subTypeID;
      }
      if (data.cancelLabel) {
        this.cancelLabel = data.cancelLabel;
      }
      if (data.submitLabel) {
        this.submitLabel = data.submitLabel;
      }

      if (data.isApplicationProceed != null) {
        this.isApplicationProceed = data.isApplicationProceed;
      }
      if (data.displayReasonForVisiting != null) {
        this.displayReasonForVisiting = data.displayReasonForVisiting;
      }
    }
  }

  async ngOnInit() {
    this.sessionType = await this.authenticationService.getSessionType().toPromise();

    let res;
    if (!this.invitationID) {
      this.invitationID = this.activeRoute.snapshot.paramMap.get('invitationID');
    }

    if (this.invitationID) {
      const payload = {
        fields: 'ID,FirstName,LastName,Merchant_Key,MerchantName,Prospect_key',
      };
      res = await this.invitationService.getInvitationDetails(this.invitationID, payload, 'guest').toPromise();
    } else if (this.sessionType === 'customer') {
      res = await this.authenticationService.getCurrentUser().toPromise();
    }

    if (res) {
      this.merchantID = this.invitationID ? res.Merchant_Key : res.data.merchantID;
    } else {
      this.router.navigate(['/404']);
    }

    this.setup();
  }

  closeEvent() {
    this.close.emit(true);
  }

  addSubType() {
    if (this.type && this.type.Code) {
      const payload = {
        category: this.type.Code,
        isActive: true,
        merchantID: this.merchantID,
        fields: 'Lookup_Type,Category,Code,Label,Description,Sort_Order,DefaultDuration,Buffers_Pre,Buffers_Post',
      };
      this.appointmentLookupService.list(payload, this.sessionType).subscribe((res) => {
        if (res) {
          this.subTypes = res;
          if (this.subTypeID) {
            const index = this.subTypes.findIndex((item) => {
              if (item && item.Code && item.Code == this.subTypeID) {
                return true;
              }
              return false;
            });

            if (index != -1) {
              this.subType = this.subTypes[index];
            }
          }
        }
      });
    }
  }

  setup() {
    if (this.merchantID) {
      this.merchantProfile = this.merchantService.getPracticePicStreamLink(this.merchantID);

      if (this.displayReasonForVisiting == true) {
        const payload = {
          merchantID: this.merchantID,
          isActive: true,
          lookupType: 'AppointmentTypes',
          fields: 'Lookup_Type,Category,Code,Label,Description,Sort_Order,DefaultDuration,Buffers_Pre,Buffers_Post',
        };
        this.appointmentLookupService.list(payload, this.sessionType).subscribe((res) => {
          if (res) {
            this.types = res;

            if (this.typeID) {
              const index = this.types.findIndex((item) => {
                if (item && item.Code && item.Code == this.typeID) {
                  return true;
                }
                return false;
              });

              if (index != -1) {
                this.type = this.types[index];
              }
            }
          }
        });
      }

      const payload2 = {
        merchantID: this.merchantID,
        isActive: true,
        lookupType: 'BestAppointmentTime',
        fields: 'Lookup_Type,Category,Code,Label,Description,Sort_Order,DefaultDuration,Buffers_Pre,Buffers_Post',
      };
      this.appointmentLookupService.list(payload2, this.sessionType).subscribe((res) => {
        if (res) {
          this.bestContactTimes = res;
        }
      });

      this.merchantService.getMerchantDetails(this.merchantID, {}, this.sessionType).subscribe(async (res) => {
        if (res) {
          this.merchant = res;
        } else {
          this.router.navigate(['/404']);
        }
      });
    }
  }

  book() {
    const p: any = {
      bestAppointmentCode: this.bestContactTime,
      comment: this.comment,
    };

    if (this.type && this.type.Code) {
      p.typeCode = this.type.Code;
    } else if (this.typeID) {
      p.typeCode = this.typeID;
    }

    if (this.type && this.type.Label) {
      p.typeDescription = this.type.Label;
    }

    if (this.subType && this.subType.Code) {
      p.subTypeCode = this.subType.Code;
    } else if (this.subTypeID) {
      p.subTypeCode = this.subTypeID;
    }

    if (this.subType && this.subType.Label) {
      p.subTypeDescription = this.subType.Label;
    }

    if (this.invitationID) {
      p.isApplicationProceed = this.isApplicationProceed;
      this.appointmentService.createForInvitation(this.invitationID, p).subscribe((res) => {
        if (res) {
          this.isCreated = true;
          this.result.emit(res);
          NotifyAppComponent.displayToast(
            'success',
            'Success!',
            'Your dentist has been notified for your appointment request.'
          );
        }
      });
    } else {
      this.appointmentService.createForCustomer(p).subscribe((res) => {
        if (res) {
          this.isCreated = true;
          this.result.emit(res);
          NotifyAppComponent.displayToast(
            'success',
            'Success!',
            'Your dentist has been notified for your appointment request.'
          );
        }
      });
    }
  }

  replaceAllString(e) {
    if (e && typeof e == 'string') {
      return String(e).replace(/�/g, "'");
    }
  }
}
