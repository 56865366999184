/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./session-change-avatar.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./session-change-avatar.component";
var styles_SessionChangeAvatar = [i0.styles];
var RenderType_SessionChangeAvatar = i1.ɵcrt({ encapsulation: 0, styles: styles_SessionChangeAvatar, data: {} });
export { RenderType_SessionChangeAvatar as RenderType_SessionChangeAvatar };
export function View_SessionChangeAvatar_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_SessionChangeAvatar_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "session-change-avatar", [], null, null, null, View_SessionChangeAvatar_0, RenderType_SessionChangeAvatar)), i1.ɵdid(1, 114688, null, 0, i2.SessionChangeAvatar, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SessionChangeAvatarNgFactory = i1.ɵccf("session-change-avatar", i2.SessionChangeAvatar, View_SessionChangeAvatar_Host_0, {}, {}, []);
export { SessionChangeAvatarNgFactory as SessionChangeAvatarNgFactory };
