import { Component, OnDestroy } from '@angular/core';
import { uniq } from 'lodash';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { labelAnimation } from '../../../shared/animations/label.animation';
import { onChangeAnimation } from '../../../shared/animations/onChange.animation';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { ContractService } from '../../contract/shared/contract.service';
import {
  DEFAULT_ALL_CONTRACT_FUNDERS_OPTION,
  DEFAULT_ALL_CONTRACT_PRODUCTS_OPTION,
  DEFAULT_ALL_CONTRACTS_OPTION,
  DEFAULT_CONTRACT_CUSTOMER_OPTION,
} from '../../contract/shared/data/contract';
import {
  ContractCustomerSelect,
  ContractFunderSelect,
  ContractProductSelect,
  ContractSelect,
} from '../../contract/shared/types/contract.type';
import { DEFAULT_ALL_MERCHANT_GROUPS_OPTION, DEFAULT_MERCHANT_OPTION } from '../../merchant/shared/data/merchant';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { Merchant, MerchantGroupSelect, MerchantSelect } from '../../merchant/shared/types/merchant.type';
import { SettlementService } from '../../settlement/shared/settlement.service';
import { FundersRevenueReportService } from './shared/services/funders-revenue-report.service';
import {
  RevenueReportContract,
  RevenueReportRequest,
  RevenueReportRequestOptionals,
} from './shared/types/revenue-report.type';

@Component({
  selector: 'ipv-funders-revenue-report',
  templateUrl: './funders-revenue-report.page.html',
  styleUrls: ['./funders-revenue-report.page.css'],
  animations: [labelAnimation, onChangeAnimation],
  providers: [CustomDatePipe, FundersRevenueReportService],
})
export class FundersRevenueReportPage implements OnDestroy {
  viewModel$ = combineLatest([
    this.merchantService.getMerchants$(),
    this.merchantService.getMerchantGroups$(),
    this.contractService.getContracts$().pipe(map((contracts) => contracts as RevenueReportContract[])),
    this.settlementService.getRevenueReport$(),
  ]).pipe(
    map(([merchants, merchantGroups, contracts, revenueReport]) => {
      return {
        merchants,
        merchantGroups,
        contracts,
        revenueReport,
      };
    })
  );

  selectedTabIndex = 0;

  startDate: string;
  endDate: string;
  initialStartDate = new Date();
  initialEndDate = new Date();

  merchantControlValue: MerchantSelect = DEFAULT_MERCHANT_OPTION;
  merchantID: string;
  allPracticesOptionValue = DEFAULT_MERCHANT_OPTION;
  merchantsLoaded = false;

  merchantGroupControlValues: MerchantGroupSelect[] = DEFAULT_ALL_MERCHANT_GROUPS_OPTION;
  merchantGroupIDs: string;
  allMerchantGroupsOption = DEFAULT_ALL_MERCHANT_GROUPS_OPTION;
  hasAllMerchantsOption = true;
  merchantGroupsLoaded = false;

  productControlValues: ContractProductSelect[] = DEFAULT_ALL_CONTRACT_PRODUCTS_OPTION;
  productIDs: string;
  allProductsOption = DEFAULT_ALL_CONTRACT_PRODUCTS_OPTION;
  hasAllProductsOption = true;

  funderControlValues: ContractFunderSelect[] = DEFAULT_ALL_CONTRACT_FUNDERS_OPTION;
  funderIDs: string;
  allFundersOption = DEFAULT_ALL_CONTRACT_FUNDERS_OPTION;
  hasAllFundersOption = true;

  // Labeled as borrower in the select control
  customerControlValue: ContractCustomerSelect = DEFAULT_CONTRACT_CUSTOMER_OPTION;
  customerID: string;
  allCustomersOption = DEFAULT_CONTRACT_CUSTOMER_OPTION;

  contractControlValues: ContractSelect[] = DEFAULT_ALL_CONTRACTS_OPTION;
  contractIDs: string;
  hasAllContractsOption = true;
  contractsLoaded = false;

  constructor(
    private settlementService: SettlementService,
    private contractService: ContractService,
    private merchantService: MerchantService,
    private revenueReportService: FundersRevenueReportService
  ) {}

  ngOnDestroy() {
    /** required for untilDestroyed (Replace with @UntilDestroyed() Decorator in angular 9 and delete ngOnDestroy()) */
  }

  fetchMerchants$() {
    if (!this.merchantsLoaded) {
      this.merchantsLoaded = true;
      this.merchantService
        .fetchMerchants$({ fields: 'ID,TradingAs,CalculatedName,Status' })
        .pipe(
          untilDestroyed(this),
          tap((merchants) => {
            this.merchantService.setMerchants(merchants);
          })
        )
        .subscribe();
    }
  }

  fetchMerchantGroups$() {
    if (!this.merchantGroupsLoaded) {
      this.merchantGroupsLoaded = true;
      this.merchantService
        .fetchCurrentMerchantGroups$({ fields: 'ID,Label' })
        .pipe(
          tap((merchantGroups: MerchantGroupSelect[]) => {
            this.merchantService.setMerchantGroups(merchantGroups);
          })
        )
        .subscribe();
    }
  }

  fetchContracts$() {
    if (!this.contractsLoaded) {
      this.contractsLoaded = true;
      this.contractService
        .fetchContracts$<RevenueReportContract>({
          fields:
            'ID,Customer.FullName,Customer.Email,Customer_key,Product.Label.Marketing,Product_key,FunderName,Funder_key',
        })
        .pipe(
          untilDestroyed(this),
          tap((contracts: RevenueReportContract[]) => {
            this.contractService.setContracts(contracts);
          })
        )
        .subscribe();
    }
  }

  setStartDate(startDate: string) {
    if (startDate) {
      this.startDate = startDate;
      this.initialStartDate = new Date(this.startDate);
    }
  }

  setEndDate(endDate: string) {
    if (endDate) {
      this.endDate = endDate;
      this.initialEndDate = new Date(this.endDate);
    }
  }

  compareMerchant(current: MerchantGroupSelect, other: MerchantGroupSelect) {
    return other && current.ID === other.ID && current.Label === other.Label;
  }

  compareProduct(current: ContractProductSelect, other: ContractProductSelect) {
    return (
      other &&
      current.Product_key === other.Product_key &&
      current['Product.Label.Marketing'] === other['Product.Label.Marketing']
    );
  }

  compareFunder(current: ContractFunderSelect, other: ContractFunderSelect) {
    return other && current.Funder_key === other.Funder_key && current.FunderName === other.FunderName;
  }

  compareMerchants(merchantFromList: Partial<Merchant>, selectedMerchant: Partial<Merchant>): boolean {
    return merchantFromList.ID === selectedMerchant.ID;
  }

  compareCustomer(current: ContractCustomerSelect, other: ContractCustomerSelect) {
    return (
      other &&
      current.Customer_key === other.Customer_key &&
      current['Customer.FullName'] === other['Customer.FullName'] &&
      current['Customer.Email'] === other['Customer.Email']
    );
  }

  setMerchantGroups(merchantGroups: MerchantGroupSelect[]) {
    if (this.hasAllMerchantsOption) {
      this.merchantGroupControlValues = merchantGroups.filter((merchantGroup) => merchantGroup.ID !== null);
      this.hasAllMerchantsOption = false;
      this.merchantGroupIDs = this.merchantGroupControlValues
        .map((merchantGroupControlValue) => merchantGroupControlValue.ID)
        .join('|');
    } else if (merchantGroups.length === 0) {
      this.merchantGroupControlValues = DEFAULT_ALL_MERCHANT_GROUPS_OPTION;
      this.hasAllMerchantsOption = true;
      this.merchantGroupIDs = null;
    } else {
      const foundAllOption = merchantGroups.findIndex((merchantGroup) => merchantGroup.ID === null) > -1;
      if (foundAllOption) {
        this.merchantGroupControlValues = DEFAULT_ALL_MERCHANT_GROUPS_OPTION;
        this.hasAllMerchantsOption = true;
        this.merchantGroupIDs = null;
      } else if (merchantGroups.length === 0) {
        this.merchantGroupControlValues = DEFAULT_ALL_MERCHANT_GROUPS_OPTION;
        this.hasAllMerchantsOption = true;
        this.merchantGroupIDs = null;
      } else {
        this.merchantGroupIDs = this.merchantGroupControlValues
          .map((merchantGroupControlValue) => merchantGroupControlValue.ID)
          .join('|');
      }
    }
  }

  setProducts(products: ContractProductSelect[]) {
    if (this.hasAllProductsOption) {
      this.productControlValues = products.filter((product) => product.Product_key !== null);
      this.hasAllProductsOption = false;
      this.productIDs = this.productControlValues
        .map((productControlValue) => productControlValue.Product_key)
        .join('|');
    } else if (products.length === 0) {
      this.productControlValues = DEFAULT_ALL_CONTRACT_PRODUCTS_OPTION;
      this.hasAllProductsOption = true;
      this.productIDs = this.productControlValues
        .map((productControlValue) => productControlValue.Product_key)
        .join('|');
    } else {
      const foundAllOption = products.findIndex((product) => product.Product_key === null) > -1;
      if (foundAllOption) {
        this.productControlValues = DEFAULT_ALL_CONTRACT_PRODUCTS_OPTION;
        this.hasAllProductsOption = true;
        this.productIDs = null;
      } else {
        this.productIDs = this.productControlValues
          .map((productControlValue) => productControlValue.Product_key)
          .join('|');
      }
    }
  }

  setFunders(funders: ContractFunderSelect[]) {
    if (this.hasAllFundersOption) {
      this.funderControlValues = funders.filter((funder) => funder.Funder_key !== null);
      this.hasAllFundersOption = false;
      this.funderIDs = this.funderControlValues.map((funderControlValue) => funderControlValue.Funder_key).join('|');
    } else if (funders.length === 0) {
      this.funderControlValues = DEFAULT_ALL_CONTRACT_FUNDERS_OPTION;
      this.hasAllFundersOption = true;
      this.funderIDs = null;
    } else {
      const foundAllOption = funders.findIndex((funder) => funder.Funder_key === null) > -1;
      if (foundAllOption) {
        this.funderControlValues = DEFAULT_ALL_CONTRACT_FUNDERS_OPTION;
        this.hasAllFundersOption = true;
        this.funderIDs = null;
      } else {
        this.funderIDs = this.funderControlValues.map((funderControlValue) => funderControlValue.Funder_key).join('|');
      }
    }
  }

  setCustomer(customer: ContractCustomerSelect, allContracts: RevenueReportContract[]) {
    this.customerID = customer.Customer_key;
    this.contractControlValues = DEFAULT_ALL_CONTRACTS_OPTION;
    this.hasAllContractsOption = true;
    this.contractIDs = uniq(
      allContracts.filter((contract) => contract.Customer_key === this.customerID).map((contract) => contract.ID)
    ).join('|');
  }

  setMerchant(merchant: MerchantSelect) {
    this.merchantID = merchant.ID;
    this.merchantControlValue = DEFAULT_MERCHANT_OPTION;
    this.hasAllMerchantsOption = true;
  }

  /**
   * Check that the payload has the right combination of properties to be accepted by the server
   */
  isValidPayload(payload: RevenueReportRequest) {
    return !!(payload.From_Date && payload.To_Date);
  }

  updateRevenueReport() {
    let optional: RevenueReportRequestOptionals = {};

    optional = this.merchantID ? { ...optional, merchantID: this.merchantID } : optional;
    optional = this.merchantGroupIDs ? { ...optional, merchantGroupIDs: this.merchantGroupIDs } : optional;
    optional = this.productIDs ? { ...optional, productIDs: this.productIDs } : optional;
    optional = this.funderIDs ? { ...optional, funderIDs: this.funderIDs } : optional;
    optional = this.contractIDs ? { ...optional, contractKeys: this.contractIDs } : optional;

    const payload = new RevenueReportRequest(this.startDate, this.endDate, optional);

    if (this.isValidPayload(payload)) {
      this.settlementService
        .fetchRevenueReport$(payload)
        .pipe(
          untilDestroyed(this),
          tap((revenueReport) => {
            this.settlementService.setRevenueReport(revenueReport);
          })
        )
        .subscribe();
    }
  }

  openTablePrint() {
    switch (this.selectedTabIndex) {
      case 1:
        this.revenueReportService.setPrintTable('actual');
        break;

      case 2:
        this.revenueReportService.setPrintTable('accrued');
        break;
    }
  }

  setCurrentTab(index: number) {
    this.selectedTabIndex = index;
  }
}
