<ng-container *ngIf="printMode == false">
  <div class="row clearfix allList mt" *ngIf="allergyList && allergyList.length > 0">
    <mat-card class="card allergyList no-box-card mt mb clearfix" *ngFor="let item of allergyList">
      <div class="card-header primary-gradient-img clearfix inModal">
        <div class="row clearfix">
          <illness-allergy-icon
            class="pull-left"
            [illClass]="'allergy'"
            [iconContent]="item['Type.Label']"
            [numOfSteps]="allergyList.length"
            [step]="i"
          ></illness-allergy-icon>

          <h2 class="summary-header rm-m">
            {{ item['Type.Label'] }}
            <span class="subLabel">
              {{ item['Category.Label'] }}
            </span>
          </h2>
        </div>
        <button
          *ngIf="disableModify == false"
          (click)="modifyAllergy(item['ID'])"
          mat-mini-fab
          color="accent"
          matTooltip="Edit"
        >
          <mat-icon>edit</mat-icon>
        </button>
      </div>
      <div class="row clearfix text-center">
        <div class="full-width clearfix">
          <div class="row clearfix">
            <div class="row clearfix full-width flex force">
              <div class="full-width">
                <h5 class="sr-title">{{"KEYWORD.patient" | translate | titlecase}} still suffering?</h5>
                <p class="rm-mt" *ngIf="item['IsOngoing'] == '1'">Yes</p>
                <p class="rm-mt" *ngIf="item['IsOngoing'] == '0'">No</p>
              </div>
              <div class="full-width" *ngIf="item['FirstOccurDate'] && item['FirstOccurDate'] != '0000-00-00'">
                <h5 class="sr-title">First Occurance</h5>
                <p class="rm-mt">{{ formatDate(item['FirstOccurDate']) }}</p>
              </div>
              <!-- <hr class="mobShow" *ngIf="item['CeaseDate'] && item['CeaseDate']!='0000-00-00'"> -->
              <div class="full-width" *ngIf="item['CeaseDate'] && item['CeaseDate'] != '0000-00-00'">
                <h5 class="sr-title">Ceased</h5>
                <p class="rm-mt">{{ formatDate(item['CeaseDate']) }}</p>
              </div>

              <div class="full-width" *ngIf="item['FirstOccurDate'] && item['FirstOccurDate'] != '0000-00-00'">
                <h5 class="sr-title">Allergy occuring duration</h5>
                <p class="rm-mt" *ngIf="item['IsOngoing'] == '0'">
                  {{ item['FirstOccurDate'] | timeLeft: item['CeaseDate']:'years,months':false }}
                </p>

                <p class="rm-mt" *ngIf="item['IsOngoing'] == '1'">
                  {{ item['FirstOccurDate'] | timeLeft: null:'years,months':false }}
                </p>
              </div>
            </div>
          </div>
          <hr *ngIf="item['Description']"/>
          <div class="row clearfix" *ngIf="item['Description']">
            <h5 class="sr-title">Additional notes from {{"KEYWORD.patient" | translate}}</h5>
            <p class="rm-mt">{{ item['Description'] }}</p>
          </div>
        </div>

        <hr [hidden]="medicationExist != true"/>
        <div class="row clearfix flex full-width">
          <app-medication-list
            class="full-width"
            *ngIf="isLoaded == true"
            [canView]="true"
            [title]="'Medications'"
            [canCreate]="false"
            [canEdit]="false"
            [allergyID]="item.ID"
            [allergyCode]="item['Category.Code']"
            [medications]="item.medications"
            [startDate]="item['FirstOccurDate']"
            [stopDate]="item['CeaseDate']"
            [showStopDate]="!stringToBoolean(item['IsOngoing'])"
            [canAction]="false"
            [canYesNo]="false"
            (getMedicationExist)="getMedicationExist($event)"
          >
          </app-medication-list>
        </div>
      </div>
    </mat-card>

    <div class="row clearfix add-mt full-width add-mb" *ngIf="disableModify == false">
      <button class="full-width" (click)="createNewAllergy()" mat-raised-button color="warn">
        <mat-icon>add</mat-icon> Add allergy
      </button>
    </div>
  </div>

  <div class="row clearfix full-width" *ngIf="allergyList && allergyList.length <= 0">
    <div class="image-area row clearfix allergies" *ngIf="isConsumer == true">
      <img
        class="big animated bounceInDown"
        src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/allergies-tissue.png"
      />
    </div>

    <h3 class="text-center full-width mt mb white" *ngIf="isConsumer == true">No allergies added.</h3>
    <app-empty-list-message *ngIf="isConsumer == false"></app-empty-list-message>

    <div class="row clearfix full-width add-mt add-mb" *ngIf="disableModify == false">
      <button class="full-width" (click)="createNewAllergy()" mat-raised-button color="warn">
        <mat-icon>add</mat-icon> Add allergy
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="printMode == true">
  <div class="row clearfix allList" *ngIf="allergyList && allergyList.length > 0">
    <div class="allergyList clearfix mt" *ngFor="let item of allergyList">
      <div class="row clearfix">
        <h5 class="rm-mt">
          {{ item['Category.Label'] }}:
          <strong>{{ item['Type.Label'] }}</strong>
        </h5>
      </div>

      <div class="row clearfix">
        <div class="row clearfix">
          <p class="">
            {{ "KEYWORD.patient" | translate | titlecase }} still suffering?
            <strong *ngIf="item['IsOngoing'] == '1'">Yes</strong>
            <strong *ngIf="item['IsOngoing'] == '0'">No</strong>
          </p>
        </div>
        <div class="row clearfix">
          <p class="rm-mt" *ngIf="item['FirstOccurDate'] && item['FirstOccurDate'] != '0000-00-00'">
            First Occurance:
            <strong>{{ formatDate(item['FirstOccurDate']) }}</strong>
          </p>
          <p class="rm-mt" *ngIf="item['CeaseDate'] && item['CeaseDate'] != '0000-00-00'">
            Ceased:
            <strong>{{ formatDate(item['CeaseDate']) }}</strong>
          </p>
        </div>

        <hr [hidden]="medicationExist != true"/>
        <div class="row clearfix flex full-width">
          <app-medication-list
            class="full-width"
            *ngIf="isLoaded == true"
            [canView]="false"
            [title]="'Medications'"
            [canCreate]="false"
            [canEdit]="false"
            [allergyID]="item.ID"
            [allergyCode]="item['Category.Code']"
            [medications]="item.medications"
            [startDate]="item['FirstOccurDate']"
            [stopDate]="item['CeaseDate']"
            [showStopDate]="!stringToBoolean(item['IsOngoing'])"
            [canAction]="false"
            [canYesNo]="false"
            (getMedicationExist)="getMedicationExist($event)"
          >
          </app-medication-list>
        </div>
        <div class="row clearfix" *ngIf="item['Description']">
          <p class="rm-mt">
            Additional notes from {{"KEYWORD.patient" | translate}}:
            <strong>{{ item['Description'] }}</strong>
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-container>
