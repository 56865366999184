/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bank-account-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./bank-account-list.component";
var styles_BankAccountListComponent = [i0.styles];
var RenderType_BankAccountListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BankAccountListComponent, data: { "animation": [{ type: 7, name: "fade", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: null, timings: ".6s ease" }], options: null }], options: {} }, { type: 7, name: "stagger", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 11, selector: ":enter", animation: { type: 12, timings: ".3s", animation: [{ type: 9, options: null }] }, options: { optional: true } }], options: null }], options: {} }] } });
export { RenderType_BankAccountListComponent as RenderType_BankAccountListComponent };
export function View_BankAccountListComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_BankAccountListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bank-account-list", [], null, null, null, View_BankAccountListComponent_0, RenderType_BankAccountListComponent)), i1.ɵdid(1, 114688, null, 0, i2.BankAccountListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BankAccountListComponentNgFactory = i1.ɵccf("app-bank-account-list", i2.BankAccountListComponent, View_BankAccountListComponent_Host_0, {}, {}, []);
export { BankAccountListComponentNgFactory as BankAccountListComponentNgFactory };
