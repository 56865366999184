import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit, ViewChildren } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import {AppStateService} from '../../../../common/core/app-state/app-state.service';
import { AuthenticationService } from '../../../../common/core/authentication/shared/authentication.service';
import { SubscriptionPlanUpdatedComponent } from '../../../../common/core/subscription-plan/subscription-plan-updated/subscription-plan-updated.component';
import { MessageInputComponent } from '../../../../common/feature/message/message-input/message-input.component';
import { RootAppComponent } from '../../../../common/shared/components/root-component/root-component.component';
import { HeaderService } from '../../../../common/shared/services/header.service';
import { UtilsService } from '../../../../common/shared/services/utils.service';
import { Settings } from '../../../../common/shared/types/settings';
import { UtilsClass } from '../../../../common/shared/types/utils/utils.class';
import { PasswordPromptComponent } from '../../../../common/feature/operator/password-prompt/password-prompt.component';

const SMALL_WIDTH_BREAKPOINT = 1200;
@Component({
  selector: 'app-merchant-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainMerchantComponent implements OnInit, AfterViewInit {
  type = 'consumer';
  headerType: string;
  util = new UtilsClass();
  @ViewChildren('sidenav') sidenav: MatSidenav;
  navMode = 'side';
  openFlag = false;
  isDragOpen = false;
  noteType;
  noteTargetID;
  noteTarget;
  noteItemID;
  noteItemMerchantName;
  noteItemMerchantID;
  noteItemDentistName;
  messageRandom
  isMessageOpened = false
  displaySideMenu = true;
  isModuleExperimentalFeaturesActive = Settings.global['isModuleExperimentalFeaturesActive'];
  constructor(
    private utilService: UtilsService,
    private authenticationService: AuthenticationService,
    private _router: Router,
    private cdr: ChangeDetectorRef,
private appStateService: AppStateService
  ) { }
  ngOnInit() {


    // Used to quickly check appState
    // this.appStateService.getAppState$().subscribe(console.log);

    RootAppComponent.expendMessageInput.subscribe(r => {
      if (r) {


        if (this.isMessageOpened === false) {

          this.isMessageOpened = true;

          if (r && r.random) {
            this.messageRandom = r.random
          }

          const ref = RootAppComponent.dialog.open(MessageInputComponent, {
            data: {
              contact: r.contact,
              dentist: r.contact,
              merchant: r.merchant,
              card: r.card,
              messageMode: r.messageMode,
              tableName: r.tableName,
              message: r.message,
              random: r.random,
              isSendSMS: r.isSendSMS,
              isSMSPreview: r.isSMSPreview,
              isTiny: r.isTiny,
              isDesktopPreview: r.isDesktopPreview,
              saveAsTemplate: r.saveAsTemplate,
              displaySaveAsTemplate: r.displaySaveAsTemplate,
              displayEmoji: r.displayEmoji,
              displayMessageTemplate: r.displayMessageTemplate,
              displayPatient: r.displayPatient,
              displayContact: r.displayContact,
              displayMerchant: r.displayMerchant,
              displayInstantOffer: r.displayInstantOffer,
              displayPromoter: r.displayPromoter,
              displayMarketing: r.displayMarketing,
              displayDentalOffers: r.displayDentalOffers,
              displayDocument: r.displayDocument,
              patientID: r.patientID,
              messageTemplateID: r.messageTemplateID,
              encode: r.encode,

              isCard: r.isCard,
              cardTitle: r.cardTitle,
              cardDescription: r.cardDescription,
              inputTitle: r.inputTitle,
              previewTitle: r.previewTitle,
              theme: r.theme,
              displayPreview: r.displayPreview,

              isCustomTreatmentAmount: r.isCustomTreatmentAmount,
              displayUseDefault: r.displayUseDefault,
              useDefault: r.useDefault,
              useDefaultMessage: r.useDefaultMessage,


              isModal: true,
              isAction: true,
              isExpend: true
            },
            panelClass: 'noCard',
            width: '70vw',
          });
          ref.backdropClick().subscribe((data) => {

            this.isMessageOpened = false;

            ref.close();
          });
          ref.componentInstance.close.subscribe((data) => {

            this.isMessageOpened = false;

            ref.close();
          });

          ref.componentInstance.getAction.subscribe((data) => {

            if (data) {
              data.random = this.messageRandom;
            }
            RootAppComponent.getExpendMessageInputResult.emit(data)

            this.isMessageOpened = false;
            ref.close();
          });
        }
      }
    })


    RootAppComponent.hideSideMenu.subscribe(res => {

      if (res === true) {
        this.displaySideMenu = false;
      } else {
        this.displaySideMenu = true;
      }


    })
    RootAppComponent.newSubscriptionPlan.subscribe(res => {
      if (res) {


        const ref2 = RootAppComponent.dialog.open(SubscriptionPlanUpdatedComponent, {
          width: '650px',
          height: '650px',
          panelClass: 'noCard'
        });

        ref2.componentInstance.close.subscribe((res) => {
          ref2.close();
        });
      }
    })

    // this.onResize(event)
    this.openFlag = false;
    setTimeout(() => {
      this.openFlag = true;
    }, 250);
    this.authenticationService.getCurrentUser().subscribe(
      (res: any) => {

        AuthenticationService.updateDrift.emit('merchant');
      }
    );
    this.utilService.getCurrentAccess().subscribe(access => {
      if (access) {
        this.isModuleExperimentalFeaturesActive = access['isModuleExperimentalFeaturesActive'];
      }
    })
    HeaderService.getNoteObject().subscribe(res => {
      if (res) {


        this.isDragOpen = true;
        if (res.targetID) {
          this.noteTargetID = res.targetID;
        }
        if (res.itemID) {
          this.noteItemID = res.itemID;
        }
        if (res.noteTarget) {
          this.noteTarget = res.noteTarget;
        }

        if (res.itemMerchantID) {
          this.noteItemMerchantID = res.itemMerchantID;
        }

        if (res.itemMerchantName) {
          this.noteItemMerchantName = res.itemMerchantName;
        }

        if (res.itemDentistName) {
          this.noteItemDentistName = res.itemDentistName;
        }
      }
    })
  }
  ngAfterViewInit() {
    this._router.events.subscribe(() => {
      if (this.isScreenSmall()) {
        this.openFlag = false;
      }
    });
    window.dispatchEvent(new Event('resize'));
    this.cdr.detectChanges();
  }
  isScreenSmall(): boolean {
    return window.matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`).matches;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 1200) {
      this.navMode = 'over';
      this.openFlag = false;
    }
    if (event.target.innerWidth > 1200) {
      this.navMode = 'side';
      this.openFlag = true;
    }
  }
  closeNoteCreate(e) {
    if (e == true) {
      this.isDragOpen = false;
      this.noteTargetID = null;
      this.noteItemID = null;
      this.noteType = null;
    }
  }
  isDragEvent(e) {
    this.isDragOpen = e;
  }
  getNoteType(type) {
    if (type) {
      this.noteType = type;
    } else {
      this.noteType = null;
    }
  }
}
