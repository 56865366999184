import { EventEmitter } from '@angular/core';

export class NotifyAppComponent {
  static errorEvent = new EventEmitter<any>();
  static errorObject = {};
  static navigation = new EventEmitter();

  public static errorType(epError) {
    const types = {
      '550': 'EP Syntax error ',
      '560': 'EP runtime error ',
      '551': 'EP Internal error ',
      '401': 'Authentication error',
      '402': 'Authentication error',
      '552': 'Schema error',
      '451': 'Data related error',
      '452': 'Data related error',
      '457': 'Data related error',
      '458': 'Data related error',
      '553': 'Record is locked',
      '556': 'EP Lockup Error',
      '558': 'EP File',
      '557': 'EP Connection Error',
      '454': 'LMOD',
      '503': 'EP Auhtentication',
      '555': 'EP Auhtentication',
      '455': 'Authentication error',
      '456': 'Lookup related error',
      '554': 'EP External error',
      '500': 'Internal error',
      '460': 'Sorry!',
    };

    return types[epError] || epError || 500;
  }

  public static displayToast(type, title, content) {
    if (Number.isInteger(Number(title))) {
      title = NotifyAppComponent.errorType(title);
    }
    NotifyAppComponent.errorObject = {
      type,
      title: content,
      content: title,
    };

    NotifyAppComponent.errorEvent.emit(NotifyAppComponent.errorObject);
  }
}
