import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { MessageService } from '../shared/message.service';

@Component({
  selector: 'app-sms-statistics',
  templateUrl: './sms-statistics.component.html',
  styleUrls: ['./sms-statistics.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class SmsStatisticsComponent implements OnInit {
  @Input()
  cardID;
  @Input()
  merchantID;

  @Input()
  cardName;

  @Input()
  tableName;
  @Input()
  LastModified;
  total = 0;
  @Input()
  type = 'S';

  @Input()
  isTemplateSupportSMS = true;
  @Input()
  statistics;
  chartVisitorData;
  loaded = false;
  close = new EventEmitter();
  sessionType;

  messageType;
  includeCampaing;
  templateTags;
  globalNumber;

  constructor(
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      if (data && data.cardID) {
        this.cardID = data.cardID;
      }
      if (data && data.merchantID) {
        this.merchantID = data.merchantID;
      }
      if (data && data.messageType) {
        this.messageType = data.messageType;
      }
      if (data && data.includeCampaing) {
        this.includeCampaing = data.includeCampaing;
      }
      if (data && data.globalNumber) {
        this.globalNumber = data.globalNumber;
      }

      if (data && data.templateTags) {
        this.templateTags = data.templateTags;
      }

      if (data && data.type) {
        this.type = data.type;
      }

      if (data && data.tableName) {
        this.tableName = data.tableName;
      }
      if (data && data.cardName) {
        this.cardName = data.cardName;
      }

      if (data && data.LastModified) {
        this.LastModified = data.LastModified;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((r) => {
      this.sessionType = r;

      this.setup();
    });
  }

  setup() {
    if (this.cardID) {
      const payload = {
        cardID: this.cardID,
        merchantID: this.merchantID,
        isTemplateSupportSMS: this.isTemplateSupportSMS,
        messageType: this.messageType,
        includeCampaing: this.includeCampaing,
        globalNumber: this.globalNumber,
        templateTags: this.templateTags,
        selectAll: true,
        type: this.type,
      };

      this.messageService.getMessageUserStatisticsList(payload, this.sessionType).subscribe((stats) => {
        if (stats && stats.length > 0) {
          this.total = 0;
          const index = stats.findIndex((item) => {
            if (item && item.code && item.code === 'all') {
              return true;
            }

            return false;
          });

          if (index !== -1 && stats[index] && stats[index]['value']) {
            this.total = stats[index]['value'];

            const chartVisitorData = [];

            if (this.total > 0) {
              for (let i = 0; i < stats.length; i++) {
                const element = stats[i];
                if (element && element.code != 'all') {
                  chartVisitorData.push({
                    name: `${element['label']}: ${Number(element['value'])} -  ${Math.trunc(
                      (Number(element['value']) / Number(this.total)) * 100
                    )}%`,
                    value: Number(element['value']),
                  });
                }
              }
            }
            this.statistics = chartVisitorData;
          }
        }

        this.loaded = true;
      });
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
}
