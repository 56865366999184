<mat-card class="rel clearfix step-1-normal">

  <div class="card-header primary-gradient-img clearfix stacked" style=" margin-bottom: 0;">
    <button class="btn-close btn-clear mat-button smt white custom-close" *ngIf="isModal == true"
      (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>

    <div class="row clearfix">
      <div class="row titleArea clearfix">

        <h2 *ngIf="!messageGroupDraftID" class="summary-header text-left rm-m">Create Bulk {{messageLabel}} Campaign.
        </h2>

        <h2 *ngIf="messageGroupDraftID" class="summary-header text-left rm-m">Continuing, Create Bulk {{messageLabel}}
          Campaign.
        </h2>
        <br>

      </div>
    </div>
  </div>

  <mat-dialog-content id="message-group-create-component" [ngClass]="{ 'no-modal-height': isModal != true }">

    <aw-wizard #wizard navBarLayout="large-filled-symbols">
      <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Select {{'patient' | translate}}s" awOptionalStep
        @simpleFadeAnimation>

        <div class="full-width text-center"
          *ngIf="isSelectedCardLoad==true && isExcludedCardLoad==true && isRecordLoad==true">




          <h3 class="sr-title text-left full-width  mt smb  ">
            Marketing consent
          </h3>




          <mat-slide-toggle class="clearfix full-width  " [(ngModel)]="_marketingConsent" name="_marketingConsent"
            (ngModelChange)="changeMarketingConsent()">
            <p> <strong> IMPORTANT:</strong> If your message is marketing-related, select this option to respect
              patients
              who opt out of SMS marketing.</p>
          </mat-slide-toggle>


          <h3 class="sr-title text-left full-width  mt smb  ">
            All {{'patient' | translate}}s
          </h3>


          <p class="text-left">
            Choose to send to ALL {{'patient' | translate}}s or manually select your {{'patient' | translate}}s by
            querying your data. You can make multiple
            queries to filter your data, as the final list of {{'patient' | translate}}s will combine all your
            selections.

          </p>




          <mat-slide-toggle class="clearfix full-width smb " [(ngModel)]="selectAll" name="selectAll">
            Send to all {{'patient' | translate}}s
          </mat-slide-toggle>









          <!-- <div class="or-devider">
            <hr>
            <p class="full-width accent-color ">OR</p>

          </div> -->

          <div class="full-width contaier-not-all " [ngClass]="{ 'pair-container': (selectAll==true) }">

            <div class="full-width   smb">

              <h3 class="sr-title text-left full-width  mt smb  ">
                Query {{'patient' | translate}}s
              </h3>

              <button class="action-button ml btn pull-right  rm-mt  " [disabled]="selectAll"
                style="  margin-top: -20px !important; " (click)="manualSelection()" mat-raised-button color="accent">
                <mat-icon class="fas fa-tasks smt smr  " style="font-size: 1rem;"></mat-icon> Select
              </button>

              <p class="    text-left  rm-mt mb">Click on 'Select' to perform queries on your
                {{'Patient' | translate}} data or manually select the {{'Patient' | translate}}s from a list.</p>



              <hr *ngIf="_selectedCards && _selectedCards.length>0">

              <app-message-group-card-list class="full-width customer-card-list " [showEmpty]="false"
                *ngIf="_selectedCards && _selectedCards.length>0" [isRemove]="true" [isOneLine]="true"
                [hideHeader]="true" [hideActions]="true" [cards]="_selectedCards" (getCards)="getCardsEvent($event)">
              </app-message-group-card-list>
            </div>

            <div class="full-width mt " *ngIf=" isTagsLoad==true">

              <div class="full-width ">

                <button *ngIf="selectedTags && selectedTags.length>0" [disabled]="selectAll"
                  class="action-button ml primary-color  btn pull-right  smb " style="background: #fff !important; "
                  (click)="preview('tags')" mat-raised-button color="primary">
                  <mat-icon class="fas fa-search  smt smr   " style="font-size: 1rem;"></mat-icon> Preview
                </button>

                <h3 class="sr-title text-left  pull-left text-center  rm-mt    smb">
                  Tags
                </h3>

              </div>

              <p class="    text-left  rm-mt mb">

                Choose {{'patient' | translate}}s by existing tags applied to {{'Patient' | translate}}s.
              </p>


              <div class="full-width smb">
                <app-tag-list class="full-width" [tags]="tags" [selectedTags]="defaultSelectedTags" [add]="addTag"
                  [disabled]="selectAll" [tagFormatIsObject]="false" [title]="'Filter by tags'" [canRemove]="true"
                  [canCreate]="true" [directRemove]="true" [directRemoveDefault]="true" [hasFilter]="true"
                  [canSelect]="false" (getCreate)="createNewTag()" (getSelectedTags)="getSelectedTags($event)">
                </app-tag-list>
              </div>
            </div>
            <div class="full-width mt"
              *ngIf="existingFilterArray && existingFilterArray.length>0 && isFilterLoad==true">
              <div class="full-width ">

                <button *ngIf="filterKeys && filterKeys.length>0 " [disabled]="selectAll"
                  class="action-button ml   primary-color   btn pull-right  " style="background: #fff  !important;"
                  (click)="preview('filters')" mat-raised-button color="primary">
                  <mat-icon class="fas fa-search smt smr " style="font-size: 1rem;"></mat-icon> Preview
                </button>

                <button class="action-button ml     btn pull-right  " [disabled]="selectAll" (click)="createNewFilter()"
                  mat-raised-button color="accent">
                  <mat-icon class="fas fa-plus smt smr  " style="font-size: 1rem;"></mat-icon> Add
                </button>



                <h3 class="sr-title text-left pull-left text-center   rm-mt    smb">
                  Audiences

                </h3>

              </div>
              <p class="    text-left  rm-mt mb">
                Choose {{'Patient' | translate}}s by existing audiences you have created or create a new audience with
                the ADD button.
              </p>
              <div class="row clearfix  full-width  smb">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label> Select an Audience</mat-label>
                  <mat-select name="filterKeys" [(ngModel)]="filterKeys" [disabled]="selectAll"
                    placeholder="Please Select one or multiple Audiences" multiple>
                    <mat-option *ngFor="let filter of existingFilterArray" [value]="filter">{{ filter.Label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class=" full-width row clearfix mt   marketing-filter-view-card-container mb"
                *ngIf="filterKeys && filterKeys.length>0">
                <mat-card class="full-width" class=" row marketing-filter-view-card mb" *ngFor="let f of filterKeys">

                  <app-marketing-filter-view class="full-width" [isEdit]="true" [hideTreatment]="false" [filter]="f"
                    (getEdit)="getEditFilterEvent($event)" [viewModal]="false">
                  </app-marketing-filter-view>
                </mat-card>
              </div>

            </div>
          </div>
          <div class="full-width mt  ">

            <div class="full-width ">

              <h3 class="sr-title text-left  rm-mt   pull-left text-center   smb">
                Exclude {{'Patient'  | translate }}s

              </h3>


            </div>

            <button class="action-button ml btn pull-right rm-mt   " style="  margin-top: -20px !important; "
              (click)="manualExcluded()" mat-raised-button color="accent">
              <mat-icon class="fas fa-tasks  smt smr  " style="font-size: 1rem;"></mat-icon> Select
            </button>

            <p class="    text-left  rm-mt mb">
              Click on 'Select' to perform queries on your {{'Patient' | translate}} data or manually select the
              {{'Patient' | translate}}s to exclude from your campaign.
            </p>


            <div class="full-width  mb">
              <hr *ngIf="_excludedCards && _excludedCards.length>0">

              <app-message-group-card-list class="full-width customer-card-list " [showEmpty]="false"
                *ngIf="_excludedCards && _excludedCards.length>0" [isRemove]="true" [isOneLine]="true"
                [hideHeader]="true" [hideActions]="true" [cards]="_excludedCards"
                (getCards)="getExcludedCardsEvent($event)"></app-message-group-card-list>

            </div>

          </div>
        </div>
        <div class="drop-button text-center white-background">
          <hr />

          <button class="action-button btn-large pull-right smt"
            [disabled]="! ((selectedTags && selectedTags.length>0)  ||    (selectedCards && selectedCards.length>0) || (filterKeys && filterKeys.length>0) || (selectAll==true)) "
            (click)="step2()" mat-raised-button color="accent">
            Next <mat-icon class="sml">keyboard_arrow_right</mat-icon>
          </button>

          <button class="action-button btn-large  smt" (click)="saveDraft()" mat-raised-button color="primary">
            Save as Draft
          </button>

        </div>

      </aw-wizard-step>

      <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Campaign Details" awOptionalStep
        @simpleFadeAnimation>
        <form #resetForm="ngForm">
          <div class="full-width text-center"
            *ngIf="isSelectedCardLoad==true && isExcludedCardLoad==true && isRecordLoad==true">

            <div class="full-width clearfix  ">

              <h3 class="sr-title text-left full-width text-center   smb ">
                Label & Description
              </h3>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Label (For your own record)
                </mat-label>

                <textarea [(ngModel)]="label" name="Label" matInput placeholder="Label" required></textarea>

              </mat-form-field>

              <div class="full-width mt clearfix">
                <mat-form-field class="full-width animate" appearance="outline">
                  <mat-label>Description (For your own record)</mat-label>
                  <textarea class="mat-block main-textarea" [(ngModel)]="description" matInput name="description"
                    placeholder="Description">
                  </textarea>
                </mat-form-field>
              </div>
            </div>

            <h3 class="sr-title text-left full-width text-center  smb"
              *ngIf="isContactsLoad==true  && contacts && contacts.length>0">
              Messages Sender
            </h3>

            <p class="    text-left  rm-mt mb">Any merged fields will use the sender’s details. Choose the team
              member who will be sending this
              message.</p>

            <div class="clearfix text-center  smb  full-width"
              *ngIf="isContactsLoad==true  && contacts && contacts.length>0">
              <div class="clearfix full-width flex">
                <mat-form-field class="full-width mr" appearance="outline">

                  <mat-select class="full-width" [(ngModel)]="contactID" (ngModelChange)="changeDentist()"
                    placeholder="" name="detnistID" required>
                    <mat-option *ngIf="currentDentist && currentDentist.ID" [value]="currentDentist.ID">
                      <strong>My account</strong>
                    </mat-option>

                    <hr *ngIf="currentDentist && currentDentist.ID" />

                    <mat-option *ngFor="let p of contacts" [value]="p['ID']">
                      {{ p.CalculatedName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="full-width pair-container">
              <h3 class="sr-title text-left full-width text-center  rm-m smb">
                Delivery Time Frame
              </h3>


              <div class="full-width  flex" style=" height: 73px;  ">
                <mat-slide-toggle name="sendNow" class="mr mb pull-left" [(ngModel)]="sendNow"
                  style=" height: 53px !important;   margin-bottom: 0px !important;  ">
                  DELIVERY - IMMEDIATELY (see Important section below)
                </mat-slide-toggle>

                <app-date-input *ngIf="sendNow!=true" id="date-delay-time" class="ml  pull-left"
                  [defaultDate]="_dateToBeSent" [label]="''" [minDate]="dateNow" [isClear]="false" [isTime]="true"
                  (selectedDate)="selectDateFromFilter($event)" name="fromDate">
                </app-date-input>

              </div>


              <h4 class=" text-left full-width text-center  rm-m smb">
                Important
              </h4>


              <ul class="full-width text-left rm-mt">
                <li>
                  <p class="small    text-left  rm-m">SMSs will commence on the date and time you selected</p>
                </li>
                <li>
                  <p class="small    text-left  rm-m">SMSs will only be sent during the hours of 10 AM - 8 PM (AEST)</p>
                </li>
                <li>
                  <p class="small    text-left  rm-m">Any message scheduled outside these times will be sent at the
                    first
                    opportunity within the timeframe.
                  </p>
                </li>
                <li>
                  <p class="small    text-left  rm-m">Please note large campaigns can take some time to send</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="drop-button text-center white-background">
            <hr />
            <button class="action-button btn-large pull-right smt" [disabled]="!resetForm.form.valid" (click)="step3()"
              mat-raised-button color="accent">
              Next <mat-icon class="sml">keyboard_arrow_right</mat-icon>
            </button>

            <button class="action-button btn-large  smt" (click)="saveDraft()" mat-raised-button color="primary">
              Save as Draft
            </button>

            <button class="action-button btn-large pull-left smt" (click)="step1()" mat-raised-button color="primary">
              <mat-icon class=" smr">keyboard_arrow_left</mat-icon> Previous
            </button>

          </div>

        </form>
      </aw-wizard-step>

      <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Review Audience " awOptionalStep
        @simpleFadeAnimation>

        <div class="full-width text-center"
          *ngIf="isSelectedCardLoad==true && isExcludedCardLoad==true && isRecordLoad==true">

          <h1 class="sr-title text-center rm-m  full-width">
            Summary
          </h1>

          <p class="full-width smb text-center">
            Based on your selection, this is the number of potential{{"Patient" | translate}}s that will receive your
            message without duplications.
          </p>



          <div class="full-width" style="float: none !important; "></div>
          <div class="full-width flex">
            <mat-card class=" full-width mt mb    preview-card-container  " *ngIf="targetNumber"
              style="    background: #f9f9f9;">

              <div class="colour-stripe"></div>

              <mat-list class="statistics">

                <mat-list-item *ngIf="targetNumber.all && targetNumber.all>0 && marketingConsent!=true">
                  <div class="full-width flex">
                    <mat-icon class="iocon fas fa-user-check" matListIcon></mat-icon>

                    <p class="full-width flex " mdLine>
                      <span class="labelName full-width"> All
                        {{"Patient" | translate}}s</span>

                      <span class=" full-width text-right  bold value " style=" color: #2ecc71;">
                        <mat-icon class="fas fa-check-circle " style="font-size: 1.4rem;  color: #2ecc71;"></mat-icon>
                      </span>


                    </p>
                  </div>
                </mat-list-item>

                <mat-list-item *ngIf="targetNumber.all && targetNumber.all>0 && marketingConsent==true">
                  <div class="full-width flex">
                    <mat-icon class="iocon fas fa-user-check" matListIcon></mat-icon>

                    <p class="full-width flex " mdLine>


                      <span class="labelName full-width">All {{"Patient" | translate}}s who
                        consented to marketing messages</span>
                      <span class="  text-right  bold value " style="width: 30px !important; color: #2ecc71;">
                        <mat-icon class="fas fa-check-circle " style="font-size: 1.4rem;  color: #2ecc71;"></mat-icon>
                      </span>


                    </p>
                  </div>
                </mat-list-item>



                <mat-list-item>
                  <div class="full-width flex">
                    <mat-icon class="iocon fas  fa-tasks" matListIcon></mat-icon>

                    <p class="full-width flex " mdLine>
                      <span class="labelName full-width"> {{"Patient" | translate}}s Query</span>
                      <span class=" full-width text-right  bold value">
                        {{ targetNumber.selected }}
                      </span>
                    </p>
                  </div>
                </mat-list-item>

                <mat-list-item>
                  <div class="full-width flex">

                    <mat-icon class="iocon fas fa-tags" matListIcon></mat-icon>

                    <p class="full-width flex " mdLine>
                      <span class="labelName full-width"> Tags</span>

                      <span class=" full-width text-right bold  value">
                        {{ targetNumber.tags }}
                      </span>



                    </p>

                  </div>

                </mat-list-item>
                <mat-list-item>
                  <div class="full-width flex">
                    <mat-icon class="iocon fas fa-filter" matListIcon></mat-icon>

                    <p class="full-width flex " mdLine>
                      <span class="labelName full-width "> Audiences</span>

                      <span class=" full-width text-right  bold value">
                        {{ targetNumber.filters }}
                      </span>



                    </p>

                  </div>

                </mat-list-item>

                <mat-list-item>
                  <div class="full-width flex">
                    <mat-icon class="iocon fas fa-ban" style="    background: rgb(220, 20, 60);" matListIcon></mat-icon>

                    <p class="full-width flex " style="    color: rgb(220, 20, 60);" mdLine>
                      <span class="labelName full-width"> Excluded {{'Patient' | translate}}s</span>

                      <span class=" full-width text-right bold  value">
                        {{ targetNumber.excluded }}
                      </span>



                    </p>

                  </div>
                </mat-list-item>

                <mat-list-item>
                  <div class="full-width flex">

                    <mat-icon class="iocon fas fa-users" matListIcon></mat-icon>

                    <p class="bold full-width flex" mdLine>
                      <span class="labelName full-width">
                        Potential recipients
                      </span>
                      <span class=" full-width text-right  value">

                        <span class="badge  pull-right ml master" *ngIf="targetNumber" style="
                                    font-size: 1rem;
                                    padding-left: 10px;
                                    padding-right: 10px;
                                    font-weight: 600;
                                    margin-top: 3px;
                                    margin-right: 0px !important; " [endVal]="targetNumber.total" countUp></span>

                      </span>
                    </p>
                  </div>
                </mat-list-item>


              </mat-list>

              <div class="full-width" style="float: none !important; "></div>

            </mat-card>

          </div>

          <div class="full-width" style="float: none !important; "></div>


          <hr class="mb">


          <h3 class="sr-title text-left rm-m  smb full-width">
            Review and Edit
          </h3>


          <button class=" btn pull-right     mb   "
            *ngIf="  targetNumber && targetNumber.all>0 && (targetNumber.selected==0 && targetNumber.tags ==0 && targetNumber.filters==0 && targetNumber.excluded==0)"
            (click)="preview('all',true)" mat-raised-button color="accent">
            <mat-icon class="fas fa-users smt smr" style="font-size: 1rem !important;"></mat-icon>
            <span>Potential recipients </span> <strong class="ml">({{targetNumber.total}})</strong>
          </button>


          <p class=" rm-mt  smb text-left">
            Review your selection and make any changes by clicking on the Potential Recipients or Excluded buttons below

          </p>





          <div class="full-width text-left  smt mb"
            *ngIf="(targetNumber && targetNumber.selected>0) ||( targetNumber && targetNumber.tags>0)  || (targetNumber && targetNumber.filters>0) || (targetNumber && targetNumber.excluded>0) ">

            <button class=" btn pull-right        " *ngIf="targetNumber && targetNumber.total>0"
              (click)="preview('all',true)" mat-raised-button color="accent">
              <mat-icon class="fas fa-users smt smr" style="font-size: 1rem !important;"></mat-icon>
              <span>Potential recipients </span> <strong class="ml">({{targetNumber.total}})</strong>
            </button>

            <button class=" btn  pull-left  primary-color   mr   " style="background:#fff !important;"
              *ngIf="targetNumber && targetNumber.selected>0" (click)="preview('manual',true)" mat-raised-button
              color="primary">
              <mat-icon class=" fas fa-tasks  smt smr" style="font-size: 1rem !important;"></mat-icon>
              <span>{{'Patient' | translate}}s Query </span> <strong *ngIf="targetNumber.selected>0"
                class="sml">({{targetNumber.selected}})</strong>
            </button>


            <button class=" btn  pull-left  primary-color  smr  " style="background:#fff !important;"
              *ngIf=" targetNumber && targetNumber.tags>0" (click)="preview('tags',true)" mat-raised-button
              color="primary">
              <mat-icon class=" fas fa-tags  smt smr" style="font-size: 1rem !important;"></mat-icon> <span>
                Tags </span> <strong *ngIf="targetNumber.tags>0" class="sml">({{targetNumber.tags}})</strong>
            </button>


            <button class=" btn   pull-left   primary-color  mr   " style="background:#fff !important;"
              *ngIf="targetNumber && targetNumber.filters>0" (click)="preview('filters',true)" mat-raised-button
              color="primary">
              <mat-icon class=" fas fa-filter  smt smr" style="font-size: 1rem !important;"></mat-icon>
              <span>Audiences</span> <strong *ngIf="targetNumber.filters>0"
                class="sml">({{targetNumber.filters}})</strong>
            </button>


            <button class=" btn  pull-left  primary-color  mr   " style="background:#fff !important;"
              *ngIf="targetNumber && targetNumber.excluded>0" (click)="preview('excluded',true)" mat-raised-button
              color="primary">
              <mat-icon class=" fas fa-ban  smt smr" style="font-size: 1rem !important;"></mat-icon> <span>
                Excluded</span> <strong *ngIf="targetNumber.excluded>0" class="sml">({{targetNumber.excluded}})</strong>
            </button>




          </div>


          <div class="full-width  pair-container mb">
            <h3 class="sr-title text-left  rm-m  full-width" *ngIf="label">
              Label
            </h3>

            <p class=" text-left  full-width  smt" *ngIf="label">

              {{label}}
            </p>

            <h3 class="sr-title text-left  rm-m   full-width" *ngIf="description">
              Description
            </h3>

            <p class=" text-left  full-width   smt " *ngIf="description">
              {{description}}
            </p>





            <h3 class="sr-title text-left   full-width   mb smt  " *ngIf="marketingConsent==true">
              <strong> Marketing related bulk {{messageLabel}}: </strong>

              <span></span>
              <mat-icon class="fas fa-check-circle  "
                style="font-size: 1.4rem;color: #2ecc71;height: 1.4rem;width: 1.4rem;margin-left: 5px !important;">
              </mat-icon>

            </h3>

            <h3 class="sr-title text-left   full-width  mb smt " *ngIf="marketingConsent!=true">
              <strong> Marketing related bulk {{messageLabel}}: </strong>

              <mat-icon class="fas fa-times-circle  "
                style="font-size: 1.4rem;color: red;height: 1.4rem;width: 1.4rem;margin-left: 5px !important;">
              </mat-icon>
            </h3>


          </div>






          <div class="full-width    "
            *ngIf="(selectedTags && selectedTags.length>0) || (filterKeys && filterKeys.length>0)">
            <h3 class="sr-title text-left  rm-m  full-width" *ngIf="selectedTags && selectedTags.length>0">
              Tags
            </h3>

            <mat-chip-list class="full-width smt mb" *ngIf="selectedTags && selectedTags.length>0">
              <mat-chip class="mr pull-left" *ngFor="let tag of selectedTags">{{ tag  }}
              </mat-chip>

            </mat-chip-list>

            <h3 class="sr-title text-left  rm-m  full-width" *ngIf="filterKeys && filterKeys.length>0">
              Audiences
            </h3>

            <mat-chip-list class="full-width smt mb " *ngIf="filterKeys && filterKeys.length>0">
              <mat-chip class="mr pull-left" *ngFor="let tag of filterKeys">{{ tag.Label  }}
              </mat-chip>

            </mat-chip-list>

          </div>

          <div class="full-width "
            [ngClass]="{ 'pair-container': (selectedTags && selectedTags.length>0) || (filterKeys && filterKeys.length>0) }">
            <h3 class="sr-title pull-left rm-m">Delivery Time Frame</h3>
            <div class="clearFix   smt  text-left full-width">
              <div *ngIf="sendNow == false">
                <p class="larger  bold rm-mt">
                  {{ dateToBeSent | customDateTime: 'DD/MM/YYYY hh:mm A' }}
                </p>
              </div>
              <div *ngIf="sendNow == true">
                <p class="larger bold rm-mt">IMMEDIATELY</p>
              </div>
            </div>
          </div>

        </div>
        <div class="drop-button text-center white-background">
          <hr />
          <button class="action-button btn-large pull-right smt" (click)="step4()"
            [disabled]="!(targetNumber && targetNumber.total>0)" mat-raised-button color="accent">
            Next <mat-icon class="sml">keyboard_arrow_right</mat-icon>
          </button>

          <button class="action-button btn-large  smt" (click)="saveDraft()" mat-raised-button color="primary">
            Save as Draft
          </button>

          <button class="action-button btn-large pull-left smt" (click)="step2()" mat-raised-button color="primary">
            <mat-icon class=" smr">keyboard_arrow_left</mat-icon> Previous
          </button>

        </div>

      </aw-wizard-step>

      <aw-wizard-step [navigationSymbol]="{ symbol: '4' }" stepTitle="Build Message" awOptionalStep
        @simpleFadeAnimation>

        <div class="full-width " *ngIf="isSelectedCardLoad==true && isExcludedCardLoad==true && isRecordLoad==true">


          <div class="full-width flex mt ">

            <div class="full-width mr">
              <p class="full-width text-left">
                Create your message below and a preview of the message will be displayed.
              </p>


              <div class="full-width flex">
                <mat-card class=" full-width  mb   preview-patient-card" *ngIf="card && card.ID"
                  style=" position:relative;   background: #f9f9f9;">
                  <div class="colour-stripe"></div>

                  <button class="pull-right " (click)="selectPatient()" color="primary"
                    style="  position: absolute;  right: 16px;  top: 10px;" mat-icon-button>
                    <mat-icon class="fas fa-user "></mat-icon>
                  </button>
                  <h3 class="sr-title text-left  rm-mt  full-width">
                    {{'Patient' | translate}} Previewed
                  </h3>

                  <hr class="mb">
                  <div *ngIf="card['FirstName']" class=" full-width smb flex">

                    <strong class="text-right rm-m   half-width">
                      Full Name :
                    </strong>
                    <p class="sr-title text-left   rm-m full-width">
                      {{card['FirstName']}} {{card['MiddleName']}} {{card['Name']}}
                    </p>

                  </div>

                  <div *ngIf="card['mobiles.Number']" class=" full-width  smb  flex">

                    <strong class="text-right rm-m  half-width">
                      Mobile Number :
                    </strong>
                    <p class="sr-title text-left   rm-m full-width">
                      {{card['mobiles.Number'] | customPhone}}
                    </p>

                  </div>
                  <div *ngIf="card['mobiles.Number']" class=" full-width  smb  flex">

                    <strong class="text-right rm-m  half-width">
                      Phone Number :
                    </strong>
                    <p class="sr-title text-left   rm-m full-width">
                      {{card['mobiles.Number']| customPhone: 'landLine'}}
                    </p>

                  </div>

                  <div *ngIf="card['emails.Email']" class=" full-width  smb  flex">

                    <strong class="text-right rm-m half-width">
                      Email :
                    </strong>
                    <p class="sr-title text-left   rm-m  full-width">
                      {{card['emails.Email']}}
                    </p>

                  </div>

                  <div *ngIf="card['addresses.Calculated']" class=" full-width  smb  flex">

                    <strong class="text-right rm-m   half-width">
                      Address :
                    </strong>
                    <p class="sr-title text-left rm-m  full-width">
                      {{card['addresses.Calculated']}}
                    </p>

                  </div>

                  <div class="full-width" style="float: none !important; "></div>
                </mat-card>
              </div>

              <message-input class="full-width  mr message-grp-input-component"
                *ngIf="loadPromoter == true && loadCard==true && loadContact == true && loadMerchant == true"
                [isTiny]="false" [isModal]="false" [messageMode]="messageMode" [label]="'Please insert a  message'"
                [message]="_message" [showAllCustomization]="true" [card]="card" [textLength]="160"
                [displayEmoji]="false" [displayMessageTemplate]="true" [contact]="contact" [displayPatient]="true"
                [promoter]="promoter" [displayContact]="true" [displayMerchant]="true" [displayPromoter]="true"
                [displayMarketing]="true" [displayExpend]="false" [displayDentalOffers]="true" [displayDocument]="true"
                [saveAsTemplate]="false" [isSMSPreview]="false" [isDesktopPreview]="false"
                [displaySaveAsTemplate]="true" [encode]="false" [tableName]="tableName"
                (getResult)="getResultMessage($event)">
              </message-input>

            </div>
            <app-sms-preview class="full-width ml " style="width: 340px !important;" [message]="message" [card]="card"
              [isModal]="false">

            </app-sms-preview>
          </div>
        </div>
        <div class="drop-button text-center white-background">
          <hr />
          <button class="action-button btn-large pull-right smt" (click)="submit(false)" [disabled]="!messageRaw"
            mat-raised-button color="accent">
            Submit Campaign <mat-icon class=" fas fa-paper-plane sml"></mat-icon>
          </button>

          <button *ngIf="isPromoterOrAdmin==true && false" class="action-button btn-large pull-right smt"
            (click)="submit(true)" [disabled]="!messageRaw" mat-raised-button color="accent">
            Submit As Promoter <mat-icon class=" fas fa-paper-plane sml"></mat-icon>
          </button>
          <button *ngIf="isPromoterOrAdmin==true && false" class="action-button btn-large pull-right ml smt"
            (click)="submit(false)" [disabled]="!messageRaw" mat-raised-button color="primary">
            Submit as {{'Merchant' | translate}} <mat-icon class="fas {{'fa-tooth' | translate}} sml">
              keyboard_arrow_right</mat-icon>
          </button>


          <button class="action-button btn-large  smt" (click)="saveDraft()" mat-raised-button color="primary">
            Save as Draft
          </button>

          <button class="action-button btn-large pull-left smt" (click)="previous2()" mat-raised-button color="primary">
            <mat-icon class="smr">keyboard_arrow_left</mat-icon> Previous
          </button>

        </div>

      </aw-wizard-step>

      <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Done!" awOptionalStep @simpleFadeAnimation>
        <div class="container" id="step-3" *ngIf="createdObject && createdObject.ID">
          <div class="row clearfix">
            <mat-card class="card successful">
              <div class="row clearfix">
                <div class="full-width text-center">
                  <h1 class="sm-h1 sr-title">Well Done!</h1>
                  <h3 class="sm-h3 rm-m"
                    *ngIf=" createdObject && createdObject.ID && createdObject['Status.Code']==='Draft'">
                    Your Bulk {{messageLabel}} Campaign
                    is saved as Draft!</h3>
                  <h3 class="sm-h3 rm-m"
                    *ngIf=" createdObject && createdObject.ID && createdObject['Status.Code']==='Pending' && createdObject['isSchedule']==true">
                    Your Bulk {{messageLabel}} Campaign is scheduled to be sent!</h3>
                  <h3 class="sm-h3 rm-m"
                    *ngIf=" createdObject && createdObject.ID && createdObject['Status.Code']==='Pending' && createdObject['isSchedule']!=true">
                    Your campaign is underway. You can check the progress by clicking the View Status button below or
                    going to the Bulk SMS Manager</h3>
                  <div class="row clearfix">
                    <div class="svg-box">
                      <svg class="circular green-stroke">
                        <circle class="path" cx="75" cy="75" r="50" fill="none" stroke-width="5"
                          stroke-miterlimit="10" />
                      </svg>
                      <svg class="checkmark green-stroke">
                        <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                          <path class="checkmark__check" fill="none"
                            d="M616.306,283.025L634.087,300.805L673.361,261.53" />
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row clearfix">

                <mat-list class="statistics">
                  <mat-list-item *ngIf="createdObject['Target_Number']">
                    <div mdLine class="flex full-width">

                      <h3 class="sr-title full-width smt "> Potential recipients</h3>
                      <div class="full-width">

                        <span class="value smt  pull-right">
                          <strong> {{ createdObject['Target_Number'] }}</strong>
                        </span>
                      </div>
                    </div>

                  </mat-list-item>


                  <mat-list-item *ngIf=" createdObject && createdObject.ID && createdObject['isSchedule']==true">

                    <div mdLine class="flex full-width">

                      <h3 class="sr-title full-width smt "> Scheduled to be sent at: </h3>
                      <div class="full-width">

                        <span class="value smt  pull-right">
                          <strong> {{ createdObject['Date_To_be_Sent'] | customDateTime }}</strong>
                        </span>
                      </div>
                    </div>
                  </mat-list-item>


                  <mat-list-item *ngIf=" createdObject && createdObject.ID && createdObject['Submit_DateTime']">

                    <div mdLine class="flex full-width">

                      <h3 class="sr-title full-width smt ">Submitted at: </h3>
                      <div class="full-width">

                        <span class="value smt  pull-right">
                          <strong> {{ createdObject['Submit_DateTime'] | customDateTime }}</strong>
                        </span>
                      </div>
                    </div>
                  </mat-list-item>


                  <mat-list-item *ngIf=" createdObject && createdObject.ID && createdObject['Finshed_DateTime']">

                    <div mdLine class="flex full-width">

                      <h3 class="sr-title full-width smt ">Completed at: </h3>
                      <div class="full-width">

                        <span class="value smt  pull-right">
                          <strong> {{ createdObject['Finshed_DateTime'] | customDateTime }}</strong>
                        </span>
                      </div>
                    </div>
                  </mat-list-item>
                  <mat-list-item *ngIf="createdObject['Status.Code']">

                    <div mdLine class="flex full-width">

                      <h3 class="sr-title full-width  smt "> Status</h3>
                      <div class="full-width">
                        <span class="status-container smt  pull-right" style="padding:5px !important;"
                          [ngStyle]="{ 'background-color':  createdObject['Status.Color']  }">



                          {{ createdObject['Status.Label'] }}

                          <mat-icon class=" {{createdObject['Icon']}} sml smr "
                            style="font-size: 1.2rem;width: 0.9rem;height: 0.9rem;"> </mat-icon>
                        </span>
                      </div>
                    </div>
                  </mat-list-item>

                  <mat-list-item *ngIf="createdObject['ID']">



                    <div mdLine class="full-width text-center step-button-row">


                      <button class=" ml pull-right  "
                        [routerLink]="['/merchant/', { outlets: { page: ['message-group-view',createdObject.ID] } }]"
                        mat-raised-button color="primary">
                        View Status
                      </button>


                      <button class="pull-right  "
                        [routerLink]="['/merchant/', { outlets: { page: ['message-group-create-new'] } }]"
                        mat-raised-button color="accent">
                        New Bulk {{messageLabel}} Campaign
                      </button>
                    </div>


                  </mat-list-item>

                </mat-list>


              </div>

            </mat-card>
          </div>

        </div>
      </aw-wizard-step>

    </aw-wizard>

  </mat-dialog-content>

</mat-card>
