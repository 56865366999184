import { ThemeCreateComponent } from '../../theme/theme-create/theme-create.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminMainComponent } from '../pages/admin-main/admin-main.component';
import { AdminSystemSettingsComponent } from '../features/admin-system-settings/admin-system-settings.component';
import { AdminCommunicationSettingsComponent } from '../features/admin-communication-settings/admin-communication-settings.component';
import { AdminDocumentsCenterComponent } from '../features/admin-documents-center/admin-documents-center.component';
import { AdminLogsComponent } from '../features/admin-logs/admin-logs.component';
import { AdminUsersComponent } from '../features/admin-users/admin-users.component';
import { AdminTipComponent } from '../features/admin-tip/admin-tip.component';
import { AdminGeneralSettingsComponent } from '../features/admin-general-settings/admin-general-settings.component';
import { AdminLanguagesComponent } from '../features/admin-languages/admin-languages.component';
import { AdminThemeComponent } from '../features/admin-theme/admin-theme.component';
import { AdminHelpDocumentsCenterComponent } from '../features/admin-help-documents-center/admin-help-documents-center.component';
import { AuthAdminGuard } from './admin-guard.service';
import { EconnectOverviewComponent } from '../../econnect/econnect-overview/econnect-overview.component';

const routes: Routes = [
	{
		path: 'admin',
		canActivate: [AuthAdminGuard],
		component: AdminMainComponent,
		children:
			[

				{
					path: '', component: AdminGeneralSettingsComponent,
					data: { title: 'Administration Dashboard' },
					outlet: 'adminPageRoute'
				},
				{
					path: 'general', component: AdminGeneralSettingsComponent,
					data: { title: 'Site Settings' },
					outlet: 'adminPageRoute'
				},
				{
					path: 'tip', component: AdminTipComponent,
					data: { title: 'Tips Setttings' },
					outlet: 'adminPageRoute'
				},
				{
					path: 'users', component: AdminUsersComponent,
					data: { title: 'Users Setttings' }, outlet: 'adminPageRoute'
				},
				{
					path: 'logs', component: AdminLogsComponent,
					data: { title: 'Logs Setttings' }, outlet: 'adminPageRoute'
				},
				{
					path: 'communication-settings', component: AdminCommunicationSettingsComponent,
					data: { title: 'Communication Settings' }, outlet: 'adminPageRoute'
				},
				{
					path: 'document-center', component: AdminDocumentsCenterComponent,
					data: { title: 'Document Center Setttings' }, outlet: 'adminPageRoute'
				},
				{
					path: 'languages', component: AdminLanguagesComponent,
					data: { title: 'Languages Setttings' }, outlet: 'adminPageRoute'
				},
				{
					path: 'system', component: AdminSystemSettingsComponent,
					data: { title: 'System Setttings' }, outlet: 'adminPageRoute'
				},
				{
					path: 'theme', component: AdminThemeComponent,
					data: { title: 'Themes Setttings' }, outlet: 'adminPageRoute'
				},
				{
					path: 'theme-create', component: ThemeCreateComponent,
					data: { title: 'Create Theme' }, outlet: 'adminPageRoute'
				},
				{
					path: 'theme-create/:id', component: ThemeCreateComponent,
					data: { title: 'Edit Theme' }, outlet: 'adminPageRoute'
				},
				{
					path: 'help-document-center', component: AdminHelpDocumentsCenterComponent,
					data: { title: 'Help Center' }, outlet: 'adminPageRoute'
				},

				{
					path: 'econnect-documentation',
					component: EconnectOverviewComponent,
					data: { title: 'Econnect Documentation' },
					outlet: 'adminPageRoute'
				},

				{
					path: 'econnect-documentation/:tableID',
					component: EconnectOverviewComponent,
					data: { title: 'Econnect Documentation' },
					outlet: 'adminPageRoute'
				},

				{
					path: 'econnect-documentation/:tableID/:fieldID',
					component: EconnectOverviewComponent,
					data: { title: 'Econnect Documentation' },
					outlet: 'adminPageRoute'
				},

			]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AdminRoutingModule { }
