<div class="container">
  <div class="row clearfix flex">
    <div class="full-width flex flexCol">
      <!-- introduction paragraphs -->
      <mat-card class="card mb">
        <div class="card-header primary-gradient-img clearfix">
          <div class="row clearfix">
            <div class="row titleArea clearfix">
              <h2 class="summary-header rm-m">Welcome to the {{ "BRANDING.Brand Name" }} Help centre</h2>
            </div>
          </div>
        </div>

        <p>Welcome to the {{ "BRANDING.Brand Name" }} Help centre.</p>
        <p>
          From here you can find important help articles to help you make the most out of the
          {{ "BRANDING.Brand Name" }} platform.
        </p>
        <p>
          Use the search bar at the top to quickly find articles, or explore the resource using the menu on the left.
        </p>
      </mat-card>

      <!-- latest article preview -->

      <app-wiki-search *ngIf="loading != true" [sessionTypeParams]="sessionTypeParams" [limit]="recentPagesLimit"
                       [title]="'Latest articles'" [orderBy]="recentPagesOrderBy" [layout]="'grid'"
                       [displatOrderLayout]="false"
                       [displayLayout]="true">
      </app-wiki-search>
    </div>

    <app-wiki-summary class="thrd-width ml" *ngIf="loading != true" [sessionTypeParams]="sessionTypeParams">
    </app-wiki-summary>
  </div>
</div>
