import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/';
import { InvitationIntroModalComponent } from '../../invitation/invitation-intro-modal/invitation-intro-modal.component';
import { InvitationService } from '../../invitation/shared/services/invitation.service';

import { UtilsService } from '../../../shared/services/utils.service';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-settlement-overview',
  templateUrl: './settlement-overview.component.html',
  styleUrls: ['./settlement-overview.component.css'],
})
export class SettlementOverviewComponent implements OnInit {
  isModuleMembershipActive = Settings.global['isModuleMembershipActive'];

  chart1type = 2;
  chart2type = 2;
  chart3type = 2;

  statistics: any;
  // ========== Start=======
  // Simple data -----Alex
  revenueDataOne = [
    {
      name: 'Draft',
      value: 14,
      amount: 48445,
    },
    {
      name: 'Requested',
      value: 20,
      amount: 4451,
    },
    {
      name: 'Approved',
      value: 40,
      amount: 5545,
    },
    {
      name: 'Declined',
      value: 5,
      amount: 4849,
    },
    {
      name: 'Canceled',
      value: 10,
      amount: 5454,
    },
  ];
  totalRequestedOne = this.revenueDataOne.map((p) => p.value).reduce((a, b) => a + b, 0);
  totalAmountRequestedOne = this.revenueDataOne.map((p) => p.amount).reduce((a, b) => a + b, 0);
  totalDaysOne = 16;

  revenueDataTwo = [
    {
      name: 'Draft',
      value: 55,
      amount: 89789,
    },
    {
      name: 'Requested',
      value: 29,
      amount: 5151,
    },
    {
      name: 'Approved',
      value: 61,
      amount: 6454,
    },
    {
      name: 'Declined',
      value: 16,
      amount: 8444,
    },
    {
      name: 'Canceled',
      value: 20,
      amount: 8988,
    },
  ];
  totalRequestedTwo = this.revenueDataTwo.map((p) => p.value).reduce((a, b) => a + b, 0);
  totalAmountRequestedTwo = this.revenueDataTwo.map((p) => p.amount).reduce((a, b) => a + b, 0);
  totalDaysTwo = 30;

  revenueDataThr = [
    {
      name: 'Draft',
      value: 100,
      amount: 45485,
    },
    {
      name: 'Requested',
      value: 26,
      amount: 8165,
    },
    {
      name: 'Approved',
      value: 10,
      amount: 15484,
    },
    {
      name: 'Declined',
      value: 5,
      amount: 1545,
    },
    {
      name: 'Canceled',
      value: 5,
      amount: 4545,
    },
  ];
  totalRequestedThr = this.revenueDataThr.map((p) => p.value).reduce((a, b) => a + b, 0);
  totalAmountRequestedThr = this.revenueDataThr.map((p) => p.amount).reduce((a, b) => a + b, 0);
  totalDaysThr = 7;

  revenueDataSum = [
    {
      name: 'Draft',
      value: 11,
      amount: 1654,
    },
    {
      name: 'Requested',
      value: 26,
      amount: 456,
    },
    {
      name: 'Approved',
      value: 10,
      amount: 565,
    },
    {
      name: 'Declined',
      value: 5,
      amount: 894,
    },
    {
      name: 'Canceled',
      value: 5,
      amount: 9415,
    },
  ];
  totalRequestedSum = this.revenueDataSum.map((p) => p.value).reduce((a, b) => a + b, 0);
  totalAmountRequestedSum = this.revenueDataSum.map((q) => q.amount).reduce((a, b) => a + b, 0);
  totalDaysSum = 7;

  // =========== Finish ==========
  pracInvitationChart = {
    title: 'Invitation Value',
    subtitle: 'Value generated this settlement period',
    totalsub: 'Total Fees Generated',
    lastUpdate: new Date(),
    labels: [],
    data: [],
    cutoutPercentage: 70,
    isCurrency: 0,
  };

  currBillingChart = {
    title: 'This settlement period',
    subtitle: 'Settlements from this week',
    totalsub: 'Total Fees Generated',
    lastUpdate: new Date(),
    labels: [],
    data: [],
    cutoutPercentage: 70,
    isCurrency: 1,
  };
  lastBillingChart = {
    title: 'Last settlement period',
    subtitle: 'Settlements from the previous week',
    totalsub: 'Total Fees Generated',
    lastUpdate: new Date(),
    labels: [],
    data: [],
    cutoutPercentage: 70,
    isCurrency: 1,
  };

  yearToDateChart = {
    title: 'Year to date',
    subtitle: 'Total Settlements for the year',
    totalsub: 'Fees Generated This Year',
    lastUpdate: new Date(),
    labels: ['Net Sebbled', 'Charges Abbociated', 'Chabebacks'],
    data: [8500, 1350, 4000],
    cutoutPercentage: 70,
    isCurrency: 1,
  };

  reqSetChart = {
    title: 'Current Requests',
    subtitle: 'Number of Requests',
    totalsub: 'Requests this cycle',
    lastUpdate: new Date(),
    labels: ['Draft', 'Requested', 'Approved', 'Declined', 'Canceled'],
    data: [24, 8, 25, 4, 5],
    cutoutPercentage: 70,
    isCurrency: 0,
  };

  reqLastWkSetChart = {
    title: 'Previous Requests',
    subtitle: 'Number of Requests',
    totalsub: 'Requests last cycle',
    lastUpdate: new Date(),
    labels: ['Draft', 'Requested', 'Approved', 'Declined', 'Canceled'],
    data: [31, 7, 22, 8, 9],
    cutoutPercentage: 70,
    isCurrency: 0,
  };

  reqTotalSetChart = {
    title: 'Requests YTD',
    subtitle: 'Number of Requests',
    totalsub: 'Requests this year',
    lastUpdate: new Date(),
    labels: ['Draft', 'Requested', 'Approved', 'Declined', 'Canceled'],
    data: [884, 98, 125, 89, 120],
    cutoutPercentage: 70,
    isCurrency: 0,
  };

  constructor(
    private utilService: UtilsService,
    private invitationService: InvitationService,
    private dialog: MatDialog
  ) {
    const ref = this.dialog.open(InvitationIntroModalComponent, {
      width: '800px',
      panelClass: 'noCard',
    });
    if (ref.componentInstance.showOnInvitationFirstVisit == true) {
      ref.close();
    } else {
      const sub = ref.componentInstance.closeModal.subscribe((data) => {
        ref.close();
      });
      // const tour = ref.componentInstance.startTour.subscribe(data => {
      //   // startTour();

      //   ref.close();
      //   this.startTour();
      // });
    }
  }

  ngOnInit() {
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleMembershipActive = access['isModuleMembershipActive'];
      }
    });
  }
}
