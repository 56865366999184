import { Settings } from '../../../shared/types/settings';

export class ProductUtil {
  context = Settings.global['context'];

  defaultProductGroupIcons = {
    SPCM: 'fa-paper-plane',
    GPM: 'fa-envelope-open',
    SPCF: 'fa-paper-plane',
    GPF: 'fa-envelope-open',
    IHF: 'fa-home',
    PRE: 'fa-heart',
    SER: 'fa-tools',

    DNTW: 'fas fa-file-signature',
    MEM: 'fas fa-hospital-user',
    FIN: 'fas fa-wallet',
    NDP: 'fas fa-money-bill-wave',
    SEC: 'fas fa-search-dollar',
    EXP: 'fas fa-file-invoice-dollar',
    NDP36: 'fas fa-calendar-check',
    EXT36: 'fas fa-business-time',
  };
  dentalProductGroupIcons = {
    SPCM: 'fa-user-tag',
    GPM: 'fa-user-plus',
    SPCF: 'fa-teeth',
    GPF: 'fa-tooth',
    IHF: 'fa-home',
    PRE: 'fa-heart',
    SER: 'fa-tools',
    DNTW: 'fas fa-file-signature',
    MEM: 'fas fa-hospital-user',
    FIN: 'fas fa-wallet',
    NDP: 'fas fa-money-bill-wave',
    SEC: 'fas fa-search-dollar',
    EXP: 'fas fa-file-invoice-dollar',
    NDP36: 'fas fa-calendar-check',
    EXT36: 'fas fa-business-time',
  };

  retailProductGroupIcons = {
    SPCM: 'fa-paper-plane',
    GPM: 'fa-envelope-open',
    SPCF: 'fa-paper-plane',
    GPF: 'fa-envelope-open',
    IHF: 'fa-home',
    PRE: 'fa-heart',
    SER: 'fa-tools',
    DNTW: 'fas fa-file-signature',
    MEM: 'fas fa-hospital-user',
    FIN: 'fas fa-wallet',
    NDP: 'fas fa-money-bill-wave',
    SEC: 'fas fa-search-dollar',
    EXP: 'fas fa-file-invoice-dollar',
    NDP36: 'fas fa-calendar-check',
    EXT36: 'fas fa-business-time',
  };

  p4pProductGroupIcons = {
    SPCM: 'fa-paper-plane',
    GPM: 'fa-envelope-open',
    SPCF: 'fa-paper-plane',
    GPF: 'fa-envelope-open',
    IHF: 'fa-home',
    PRE: 'fa-heart',
    SER: 'fa-tools',
    DNTW: 'fas fa-file-signature',
    MEM: 'fas fa-hospital-user',
    FIN: 'fas fa-wallet',
    NDP: 'fas fa-money-bill-wave',
    SEC: 'fas fa-search-dollar',
    EXP: 'fas fa-file-invoice-dollar',
    NDP36: 'fas fa-calendar-check',
    EXT36: 'fas fa-business-time',
  };

  breezeProductGroupIcons = {
    SPCM: 'fa-paper-plane',
    GPM: 'fa-envelope-open',
    SPCF: 'fa-paper-plane',
    GPF: 'fa-envelope-open',
    IHF: 'fa-home',
    PRE: 'fa-heart',
    SER: 'fa-tools',
    DNTW: 'fas fa-file-signature',
    MEM: 'fas fa-hospital-user',
    FIN: 'fas fa-wallet',
    NDP: 'fas fa-money-bill-wave',
    SEC: 'fas fa-search-dollar',
    EXP: 'fas fa-file-invoice-dollar',
    NDP36: 'fas fa-calendar-check',
    EXT36: 'fas fa-business-time',
  };
  groupLabel = {
    SPCM: 'Orthodontist - Membership',
    GPM: 'General and other Specialist Practitioner - Membership',
    SPCF: 'Orthodontist',
    // GPF: "General and other Specialist Practitioner - Finance",
    GPF: 'Treatment',
    IHF: 'In-House Finance',
    PRE: 'Preloved Finance',
    SER: 'Service and Maintenance Finance',

    DNTW: 'Dental Warranty',
    MEM: 'Smile Club',
    FIN: 'Finance',
    NDP: 'No Deposit Finance',
    SEC: 'Second Chance Finance',
    EXP: 'Payment Plans - Extended Terms',
    NDP36: 'No Deposit Finance - 36 months',
    EXT36: 'Payment Plans Extended Terms - 36 months',
  };

  dentalGroupLabel = {
    SPCM: 'Orthodontist - Membership',
    GPM: 'General and other Specialist Practitioner - Membership',
    SPCF: 'Orthodontist',
    // GPF: "General and other Specialist Practitioner - Finance",
    GPF: 'Treatment',
    IHF: 'In-House Finance',
    PRE: 'Preloved Finance',
    SER: 'Service and Maintenance Finance',

    DNTW: 'Dental Warranty',
    MEM: 'Smile Club',
    FIN: 'Finance',
    NDP: 'No Deposit Finance',
    SEC: 'Second Chance Finance',
    EXP: 'Payment Plans - Extended Terms',
    NDP36: 'No Deposit Finance - 36 months',
    EXT36: 'Payment Plans Extended Terms - 36 months',
  };

  retailGroupLabel = {
    SPCM: 'Orthodontist - Membership',
    GPM: 'General and other Specialist Practitioner - Membership',
    SPCF: 'Orthodontist',
    // GPF: "General and other Specialist Practitioner - Finance",
    GPF: 'Treatment',
    IHF: 'In-House Finance',
    PRE: 'Preloved Finance',
    SER: 'Service and Maintenance Finance',

    DNTW: 'Dental Warranty',
    MEM: 'Smile Club',
    FIN: 'Finance',
    NDP: 'No Deposit Finance',
    SEC: 'Second Chance Finance',
    EXP: 'Payment Plans - Extended Terms',
    NDP36: 'No Deposit Finance - 36 months',
    EXT36: 'Payment Plans Extended Terms - 36 months',
  };

  p4pGroupLabel = {
    SPCM: 'Orthodontist - Membership',
    GPM: 'General and other Specialist Practitioner - Membership',
    SPCF: 'Orthodontist',
    // GPF: "General and other Specialist Practitioner - Finance",
    GPF: 'Treatment',
    IHF: 'In-House Finance',
    PRE: 'Preloved Finance',
    SER: 'Service and Maintenance Finance',
    DNTW: 'Dental Warranty',

    NDP: 'No Deposit Finance',
    MEM: 'Smile Club',
    FIN: 'Loans and Payment Plans',
    SEC: 'Second Chance Finance',
    EXP: 'Payment Plans - Extended Terms',
    NDP36: 'No Deposit Finance - 36 months',
    EXT36: 'Payment Plans Extended Terms - 36 months',
  };

  breezeGroupLabel = {
    SPCM: 'Orthodontist - Membership',
    GPM: 'General and other Specialist Practitioner - Membership',
    SPCF: 'Orthodontist',
    // GPF: "General and other Specialist Practitioner - Finance",
    GPF: 'Treatment',
    IHF: 'In-House Finance',
    PRE: 'Preloved Finance',
    SER: 'Service and Maintenance Finance',
    DNTW: 'Dental Warranty',

    NDP: 'No Deposit Finance',
    MEM: 'Smile Club',
    FIN: 'Loans and Payment Plans',
    SEC: 'Second Chance Finance',
    EXP: 'Payment Plans - Extended Terms',
    NDP36: 'No Deposit Finance - 36 months',
    EXT36: 'Payment Plans Extended Terms - 36 months',
  };

   template =  `<div class='tool-tip-left'>
   <h3 >ddddddddddd</h3>

   <p>dddddddddd</p>
   <p>dddddddddd</p>
   <p style='text-align:left; font-weight:bold;'><strong>Additional Functionality</strong> </p>

   <ul >
   <li >dddddd</li>
   <li >dddddd</li>
   <li >dddddd</li>
   <li >dddddd</li>
   <li >dddddd</li>
   <li >dddddd</li>
   <li >dddddd</li>
   </ul >
   </div>`;

  groupToolTip = {
    SPCM: 'Orthodontist - Membership',
    GPM: 'General and other Specialist Practitioner - Membership',
    SPCF: 'Orthodontist',
    // GPF: "General and other Specialist Practitioner - Finance",
    GPF:  `<div class='tool-tip-left'>
    <h3 >TREATMENT -STANDARD FINANCE</h3>

    <p>Create advanced personalised offers to patients with our <strong>> STANDARD</strong> offers of finance included. An email and SMS will be sent to patients with a link so they can review the offer and content provided before proceeding with treatment.  </p>
    <p>Offers can be created in less than 30 seconds using templates.</p>
    <p style='text-align:left; font-weight:bold;'><strong>Additional Functionality</strong> </p>

    <ul >
    <li >Personal Message – Provide detailed personal messages with templates.</li>
    <li >Patient Education – Access brochures, handouts, and fact sheets from industry suppliers or upload your own content. </li>
    <li >Images & Videos – Access 1000’s of supplier industry images and videos or upload your content.</li>
    <li >Tag Patients for further remarketing</li>
    <li >Cash Discount – Offer a personalised discount for payment upfront on the first visit, including the generation of the voucher.</li>
    <li >Feedback – Patients can request changes, hold off for now or not proceed with treatment.</li>
    <li >Reminders – Automated email and SMS reminders nurture patients to respond. </li>
    <li >Informed Consent – An informed consent report is generated containing documents provided and terms of treatment emailed to practice. </li>
    <li >Team Members – Present treatment on behalf of other team members.</li>
    </ul >
    </div>`,
    IHF: 'In-House Finance',
    PRE: 'Preloved Finance',
    SER: 'Service and Maintenance Finance',

    DNTW: 'Dental Warranty',
    MEM: `<div class='tool-tip-left'>
    <h3 >SMILE CLUB - MEMBERSHIP</h3>

    <p>Create Smile Club Membership offers patients packages of all their hygiene and dental care needs for the next 12 months in one simple weekly payment. Think of it like a gym membership for your teeth! All plans come with NO waiting periods, NO gap payments or claim forms and up to $3000 in restorative treatment. An email and SMS will be sent to patients with a link so they can review the offer and content provided before proceeding with treatment.  </p>
    <p>Offers can be created in less than 30 seconds using templates.</p>
    <p style='text-align:left; font-weight:bold;'><strong>Additional Functionality</strong> </p>

    <ul >
    <li >Personal Message – Provide detailed personal messages with templates.</li>
    <li >Patient Education – Access brochures, handouts, and fact sheets from industry suppliers or upload your own content. </li>
    <li >Images & Videos – Access 1000’s of supplier industry images and videos or upload your content.</li>
    <li >Tag Patients for further remarketing</li>
    <li >Cash Discount – Offer a personalised discount for payment upfront on the first visit, including the generation of the voucher.</li>
    <li >Feedback – Patients can request changes, hold off for now or not proceed with treatment.</li>
    <li >Reminders – Automated email and SMS reminders nurture patients to respond. </li>
    <li >Informed Consent – An informed consent report is generated containing documents provided and terms of treatment emailed to practice. </li>
    <li >Team Members – Present treatment on behalf of other team members.</li>
    </ul >
    </div>`,
    FIN: 'Finance',
    NDP: `<div class='tool-tip-left'>
    <h3 >TREATMENT – NO DEPOSIT FINANCE</h3>

    <p>Create advanced personalised offers to patients with our <strong> NO DEPOSIT </strong> offers of finance included. An email and SMS will be sent to patients with a link so they can review the offer and content provided before proceeding with treatment.  </p>
    <p>Offers can be created in less than 30 seconds using templates.</p>
    <p style='text-align:left; font-weight:bold;'><strong>Additional Functionality</strong> </p>

    <ul >
    <li >Personal Message – Provide detailed personal messages with templates.</li>
    <li >Patient Education – Access brochures, handouts, and fact sheets from industry suppliers or upload your own content. </li>
    <li >Images & Videos – Access 1000’s of supplier industry images and videos or upload your content.</li>
    <li >Tag Patients for further remarketing</li>
    <li >Cash Discount – Offer a personalised discount for payment upfront on the first visit, including the generation of the voucher.</li>
    <li >Feedback – Patients can request changes, hold off for now or not proceed with treatment.</li>
    <li >Reminders – Automated email and SMS reminders nurture patients to respond. </li>
    <li >Informed Consent – An informed consent report is generated containing documents provided and terms of treatment emailed to practice. </li>
    <li >Team Members – Present treatment on behalf of other team members.</li>
    </ul >
    </div>`,
    SEC: 'Second Chance Finance',
    EXP: `<div class='tool-tip-left'>
    <h3 >TREATMENT – EXTENDED TERMS FINANCE</h3>

    <p>Create advanced personalised offers to patients with our <strong>EXTENDED TERM</strong> offers of finance included. An email and SMS will be sent to patients with a link so they can review the offer and content provided before proceeding with treatment.  </p>
    <p>Offers can be created in less than 30 seconds using templates.</p>
    <p style='text-align:left; font-weight:bold;'><strong>Additional Functionality</strong> </p>

    <ul >
    <li >Personal Message – Provide detailed personal messages with templates.</li>
    <li >Patient Education – Access brochures, handouts, and fact sheets from industry suppliers or upload your own content. </li>
    <li >Images & Videos – Access 1000’s of supplier industry images and videos or upload your content.</li>
    <li >Tag Patients for further remarketing</li>
    <li >Cash Discount – Offer a personalised discount for payment upfront on the first visit, including the generation of the voucher.</li>
    <li >Feedback – Patients can request changes, hold off for now or not proceed with treatment.</li>
    <li >Reminders – Automated email and SMS reminders nurture patients to respond. </li>
    <li >Informed Consent – An informed consent report is generated containing documents provided and terms of treatment emailed to practice. </li>
    <li >Team Members – Present treatment on behalf of other team members.</li>
    </ul >
    </div>`,
    NDP36:`<div class='tool-tip-left'>
    <h3 >TREATMENT – NO DEPOSIT FINANCE</h3>

    <p>Create advanced personalised offers to patients with our <strong> NO DEPOSIT </strong> offers of finance included. An email and SMS will be sent to patients with a link so they can review the offer and content provided before proceeding with treatment.  </p>
    <p>Offers can be created in less than 30 seconds using templates.</p>
    <p style='text-align:left; font-weight:bold;'><strong>Additional Functionality</strong> </p>

    <ul >
    <li >Personal Message – Provide detailed personal messages with templates.</li>
    <li >Patient Education – Access brochures, handouts, and fact sheets from industry suppliers or upload your own content. </li>
    <li >Images & Videos – Access 1000’s of supplier industry images and videos or upload your content.</li>
    <li >Tag Patients for further remarketing</li>
    <li >Cash Discount – Offer a personalised discount for payment upfront on the first visit, including the generation of the voucher.</li>
    <li >Feedback – Patients can request changes, hold off for now or not proceed with treatment.</li>
    <li >Reminders – Automated email and SMS reminders nurture patients to respond. </li>
    <li >Informed Consent – An informed consent report is generated containing documents provided and terms of treatment emailed to practice. </li>
    <li >Team Members – Present treatment on behalf of other team members.</li>
    </ul >
    </div>`,
    EXT36:  `<div class='tool-tip-left'>
    <h3 >TREATMENT – EXTENDED TERMS FINANCE</h3>

    <p>Create advanced personalised offers to patients with our <strong>EXTENDED TERM</strong> offers of finance included. An email and SMS will be sent to patients with a link so they can review the offer and content provided before proceeding with treatment.  </p>
    <p>Offers can be created in less than 30 seconds using templates.</p>
    <p style='text-align:left; font-weight:bold;'><strong>Additional Functionality</strong> </p>

    <ul >
    <li >Personal Message – Provide detailed personal messages with templates.</li>
    <li >Patient Education – Access brochures, handouts, and fact sheets from industry suppliers or upload your own content. </li>
    <li >Images & Videos – Access 1000’s of supplier industry images and videos or upload your content.</li>
    <li >Tag Patients for further remarketing</li>
    <li >Cash Discount – Offer a personalised discount for payment upfront on the first visit, including the generation of the voucher.</li>
    <li >Feedback – Patients can request changes, hold off for now or not proceed with treatment.</li>
    <li >Reminders – Automated email and SMS reminders nurture patients to respond. </li>
    <li >Informed Consent – An informed consent report is generated containing documents provided and terms of treatment emailed to practice. </li>
    <li >Team Members – Present treatment on behalf of other team members.</li>
    </ul >
    </div>`,
  }

  dentalGroupToolTip = {
    SPCM: 'Orthodontist - Membership',
    GPM: 'General and other Specialist Practitioner - Membership',
    SPCF: 'Orthodontist',
    // GPF: "General and other Specialist Practitioner - Finance",
    GPF: 'Treatment',
    IHF: 'In-House Finance',
    PRE: 'Preloved Finance',
    SER: 'Service and Maintenance Finance',

    DNTW: 'Dental Warranty',
    MEM: 'Smile Club',
    FIN: 'Finance',
    NDP: 'No Deposit Finance',
    SEC: 'Second Chance Finance',
    EXP: 'Payment Plans - Extended Terms',
    NDP36: 'No Deposit Finance - 36 months',
    EXT36: 'Payment Plans Extended Terms - 36 months',
  };

  retailGroupToolTip = {
    SPCM: 'Orthodontist - Membership',
    GPM: 'General and other Specialist Practitioner - Membership',
    SPCF: 'Orthodontist',
    // GPF: "General and other Specialist Practitioner - Finance",
    GPF: 'Treatment',
    IHF: 'In-House Finance',
    PRE: 'Preloved Finance',
    SER: 'Service and Maintenance Finance',

    DNTW: 'Dental Warranty',
    MEM: 'Smile Club',
    FIN: 'Finance',
    NDP: 'No Deposit Finance',
    SEC: 'Second Chance Finance',
    EXP: 'Payment Plans - Extended Terms',
    NDP36: 'No Deposit Finance - 36 months',
    EXT36: 'Payment Plans Extended Terms - 36 months',
  };

  p4pGroupToolTip = {
    SPCM: 'Orthodontist - Membership',
    GPM: 'General and other Specialist Practitioner - Membership',
    SPCF: 'Orthodontist',
    // GPF: "General and other Specialist Practitioner - Finance",
    GPF: 'Treatment',
    IHF: 'In-House Finance',
    PRE: 'Preloved Finance',
    SER: 'Service and Maintenance Finance',
    DNTW: 'Dental Warranty',

    NDP: 'No Deposit Finance',
    MEM: 'Smile Club',
    FIN: 'Loans and Payment Plans',
    SEC: 'Second Chance Finance',
    EXP: 'Payment Plans - Extended Terms',
    NDP36: 'No Deposit Finance - 36 months',
    EXT36: 'Payment Plans Extended Terms - 36 months',
  };

  breezeGroupToolTip = {
    SPCM: 'Orthodontist - Membership',
    GPM: 'General and other Specialist Practitioner - Membership',
    SPCF: 'Orthodontist',
    // GPF: "General and other Specialist Practitioner - Finance",
    GPF: 'Treatment',
    IHF: 'In-House Finance',
    PRE: 'Preloved Finance',
    SER: 'Service and Maintenance Finance',
    DNTW: 'Dental Warranty',

    NDP: 'No Deposit Finance',
    MEM: 'Smile Club',
    FIN: 'Loans and Payment Plans',
    SEC: 'Second Chance Finance',
    EXP: 'Payment Plans - Extended Terms',
    NDP36: 'No Deposit Finance - 36 months',
    EXT36: 'Payment Plans Extended Terms - 36 months',
  };

  getProductGroupIcon(groupCode) {
    if (this.context == 'retail') {
      if (this.retailProductGroupIcons && this.retailProductGroupIcons[groupCode]) {
        return this.retailProductGroupIcons[groupCode];
      } else {
        return 'envelope-open';
      }
    } else if (this.context == 'dental') {
      if (this.dentalProductGroupIcons && this.dentalProductGroupIcons[groupCode]) {
        return this.dentalProductGroupIcons[groupCode];
      } else {
        return 'envelope-open';
      }
    } else if (this.context == 'p4p') {
      if (this.p4pProductGroupIcons && this.p4pProductGroupIcons[groupCode]) {
        return this.p4pProductGroupIcons[groupCode];
      } else {
        return 'envelope-open';
      }
    } else if (this.context == 'breeze') {
      if (this.breezeProductGroupIcons && this.breezeProductGroupIcons[groupCode]) {
        return this.breezeProductGroupIcons[groupCode];
      } else {
        return 'envelope-open';
      }
    } else {
      if (this.defaultProductGroupIcons && this.defaultProductGroupIcons[groupCode]) {
        return this.defaultProductGroupIcons[groupCode];
      } else {
        return 'envelope-open';
      }
    }
  }

  getLabel(code) {
    if (code) {
      if (this.context == 'retail') {
        return this.retailGroupLabel[code] || null;
      } else if (this.context == 'dental') {
        return this.dentalGroupLabel[code] || null;
      } else if (this.context == 'p4p') {
        return this.p4pGroupLabel[code] || null;
      } else if (this.context == 'breeze') {
        return this.breezeGroupLabel[code] || null;
      } else {
        return this.groupLabel[code] || null;
      }

    } else {
      return null;
    }
  }

  getToolTip(code) {
    if (code) {
      if (this.context == 'retail') {
        return this.retailGroupToolTip[code] || null;
      } else if (this.context == 'dental') {
        return this.dentalGroupToolTip[code] || null;
      } else if (this.context == 'p4p') {
        return this.p4pGroupToolTip[code] || null;
      } else if (this.context == 'breeze') {
        return this.breezeGroupToolTip[code] || null;
      } else {
        return this.groupToolTip[code] || null;
      }
    } else {
      return null;
    }
  }

  getToolTipFinanceOffer() {
    let result =  `<div class='tool-tip-left'>
    <h3 >INSTANT FINANCE OFFER</h3>

    <p >Create an offer for finance in seconds. Choose or enter the patient, enter the amount, and press send. An email and SMS offer will be sent with a link to apply.</p>

    <p style='text-align:left; font-weight:bold;'><strong>Additional Functionality</strong> </p>

    <ul >
    <li >Flexible Finance – Choose from Standard, No Deposit and Extended Terms finance options.</li>
    <li >Send Files - optionally attach a copy of the treatment plan or invoice.</li>
    <li >Tag Patients for further remarketing.</li>
    <li >Customise your patient experience with your branding and personal messaging.</li>
    <li >Team Members – Present offers on behalf of other team members.  </li>
    </ul >
    </div>`

    if (this.context == 'retail') {
      return result;
    } else if (this.context == 'dental') {
      return result;
    } else if (this.context == 'p4p') {
      return result;
    } else if (this.context == 'breeze') {
      return result;
    } else {
      return result;
    }
  }

  getToolTipHealthHistory() {

    let result =  `<div class='tool-tip-left'>
    <h3 >HEALTH HISTORY</h3>

    <p >Create requests for patients to complete a detailed health history before attending the practice for treatment. An email and SMS will be sent to patients with a link so they can complete the survey.</p>

    <p ><strong>Additional Functionality</strong> </p>

    <ul >
    <li >Medical History – Captures patient Illnesses, allergies and medication taken.</li>
    <li >Patient Details – Captures name, contact details, next of kin, family GP etc.</li>
    <li >Oral Health – Capture overall oral health survey.</li>
    <li >Gamified – Engaging animated content in an easy-to-use format.</li>
    <li >Historical Data – Retains previous surveys and data so the next time, it is reviewed and modified only by patients.  </li>
    <li >Survey Report - A health history report containing survey data is generated and emailed to the practice to go onto the patient file.
    </li>
    </ul >
    </div>`

    if (this.context == 'retail') {
      return result;
    } else if (this.context == 'dental') {
      return result;
    } else if (this.context == 'p4p') {
      return result;
    } else if (this.context == 'breeze') {
      return result;
    } else {
      return result;
    }
  }

  getToolTipAppoinement() {

    let result =  `<div class='tool-tip-left'>
    <h3 >APPOINTMENT</h3>

    <p >Create scheduled appointments for patients. An email and SMS outlining the appointment details will be sent to patients with a link to review appointment details.</p>

    <p ><strong>Additional Functionality</strong> </p>

    <ul >
    <li >Customise - Your patient experience with your branding and personal messaging.</li>
    <li >Cancellation Policy – Define your own cancellation policy terms and conditions.</li>

    </ul >
    </div>`

    if (this.context == 'retail') {
      return result;
    } else if (this.context == 'dental') {
      return result;
    } else if (this.context == 'p4p') {
      return result;
    } else if (this.context == 'breeze') {
      return result;
    } else {
      return result;
    }
  }
}
