import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { HeaderService } from '../../../shared/services/header.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { Settings } from '../../../shared/types/settings';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { SettlementNewModalComponent } from '../settlement-new-modal/settlement-new-modal.component';
import { MAT_DIALOG_DATA } from '@angular/material';

/**
 * @title Feature-rich data table
 */
@Component({
  selector: 'app-settlement-list-overview',
  templateUrl: './settlement-list-overview.component.html',
  styleUrls: ['./settlement-list-overview.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class SettlementListOverViewComponent implements OnInit {
  @Input()
  status;

  @Input()
  isMaterialRequest;

  @Input()
  statusCodes;

  close = new EventEmitter();

  pageSize = Settings.global['listPageSize'] || 20;
  merchants = [];

  merchantID = null;
  activeContracts = true;
  isAdminOrPromoter = null;
  hideMerchantSelect = false;
  customerID;
  contractID;

  dateFrom;
  dateTo;

  isModuleSettlementsActive = Settings.global['isModuleSettlementsActive'];

  viewOnly = false;
  isModal = false;

  isLoaded = false;

  constructor(
    private activeRoute: ActivatedRoute,
    private utilService: UtilsService,
    private AuthenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.status) {
        this.status = data.status;
        this.isModal = true;
      }

      if (data.merchantID) {
        this.merchantID = data.merchantID;
        this.isModal = true;
      }

      if (data.viewOnly === true) {
        this.viewOnly = true;
        this.isModal = true;
      } else if (data.viewOnly === false) {
        this.viewOnly = false;
        this.isModal = true;
      }
      if (data.pageSize) {
        this.pageSize = data.pageSize;
        this.isModal = true;
      }

      if (data.contractID) {
        this.contractID = data.contractID;
        this.isModal = true;
      }
      if (data.customerID) {
        this.customerID = data.customerID;
        this.isModal = true;
      }
      if (data.statusCodes && Array.isArray(data.statusCodes) && data.statusCodes.length > 0) {
        this.statusCodes = data.statusCodes;
        this.isModal = true;
      } else if (data.statusCodes && typeof data.statusCodes === 'string') {
        this.statusCodes = data.statusCodes;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleSettlementsActive = access['isModuleSettlementsActive'];

        this.activeRoute.params.subscribe((params) => {
          if (params['status']) {
            this.status = params['status'];
          }
        });

        this.AuthenticationService.isPromoterOrAdmin().subscribe((res) => {
          this.isAdminOrPromoter = res;

          if (this.isAdminOrPromoter == true) {
            const _mID = JSON.parse(localStorage.getItem('selectedMerchant'));
            if (_mID) {
              this.merchantID = _mID;
              // this.hideMerchantSelect = true;
            }
            HeaderService.getPromoterViewAsObject().subscribe((res) => {
              if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'promoter') {
                this.merchantID = 'none';
                this.hideMerchantSelect = false;
              } else if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'merchant') {
                this.merchantID = res['merchantID'];
                this.hideMerchantSelect = true;
              }
            });
          }
        });
      }

      this.isLoaded = true;
    });
  }

  hideDetails(e) {
    this.hideMerchantSelect = e;
  }

  applySearch(result) {
    this.merchantID = result.merchantID;
    this.dateFrom = result.dateFrom;
    this.dateTo = result.dateTo;
  }

  getMerchant(e) {
    this.merchantID = e;
  }

  closeEvent() {
    this.close.emit(true);
  }
}
