import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayLast',
})
export class arrayLast implements PipeTransform {
  constructor() {}

  transform(expression: any) {
    try {
      const result = [];

      if (Array.isArray(expression) && expression.length && expression[expression.length - 1]) {
        result.push(expression[expression.length - 1]);

        return result;
      } else {
        return expression;
      }
    } catch (e) {
      return expression;
    }
  }
}
