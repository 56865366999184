import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { CustomerProspectService } from '../../customer-prospect/shared/customerProspect.service';

import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';

import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'environments/environment';
import { TeethShape } from '../../treatment/shared/teeth-shape';
import { DentalXrayScanDetectionItem, DentalXrayScanResult } from '../shared/types/dental-xray.type';

@Component({
  selector: 'app-ai-scan-report-view',
  templateUrl: './ai-scan-report-view.component.html',
  styleUrls: ['./ai-scan-report-view.component.css'],
})
export class AiScanReportViewComponent implements OnInit, OnChanges {
  @Input() aiScanID;

  @Input() title = 'AI Scan Report';

  @Input() dentalXrayScanResult: DentalXrayScanResult;
  reportResults: {
    apical: DentalXrayScanDetectionItem[];
    caries: DentalXrayScanDetectionItem[];
    restorations: DentalXrayScanDetectionItem[];
  } = { apical: [], caries: [], restorations: [] };

  util = new UtilsClass();
  step = 0;

  teethBottom = [];
  teethTop = [];
  options = [];

  merchant;

  @Input() patient;

  profileLink;
  fileLink = `${environment.nodeUrl}/files/file-view/id`;

  sessionType = 'merchant';
  isAdminOrPromoter = false;

  constructor(
    private authenticationService: AuthenticationService,
    private customerProspectService: CustomerProspectService,
    public domSanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    TeethShape.getTopTeethNumbers(true).forEach((num) => {
      this.teethTop.push({
        hideNumber: false,
        toothNumber: num,
        selected: false,
      });
    });
    TeethShape.getBottomTeethNumbers(true).forEach((num) => {
      this.teethBottom.push({
        hideNumber: false,
        toothNumber: num,
        selected: false,
      });
    });

    this.authenticationService.isPromoterOrAdmin().subscribe((isAdminOrPromoter) => {
      this.isAdminOrPromoter = isAdminOrPromoter;

      this.authenticationService.getCurrentPractice().subscribe((currentPractice) => {
        if (currentPractice) {
          this.merchant = currentPractice;
        }

        this.authenticationService.getSessionType().subscribe((sessionType) => {
          if (sessionType) {
            this.sessionType = sessionType;
          }
        });
      });
    });
  }

  ngOnChanges() {
    this.reportResults.apical = this.dentalXrayScanResult.apicalDetections.sort((a, b) =>
      a.toothName > b.toothName ? 1 : -1
    );
    this.reportResults.caries = this.dentalXrayScanResult.cariesDetections.sort((a, b) =>
      a.toothName > b.toothName ? 1 : -1
    );
    this.reportResults.restorations = this.dentalXrayScanResult.restorationsDetections.sort((a, b) =>
      a.toothName > b.toothName ? 1 : -1
    );
  }

  getUniqueSubTypes(detections: DentalXrayScanDetectionItem[]): { toothName: number; detections: string[] }[] {
    const uniqueDetections: { toothName: number; detections: string[] }[] = [];

    detections.forEach((detection) => {
      const existingToothName = uniqueDetections.filter(
        (uniqueDetection) => uniqueDetection.toothName === detection.toothName
      );

      if (existingToothName.length > 0) {
        const uniqueDetectionIndex = uniqueDetections.findIndex(
          (uniqueDetection) => uniqueDetection.toothName === detection.toothName
        );
        uniqueDetections[uniqueDetectionIndex].detections.push(detection.subtype);
      } else {
        uniqueDetections.push({ toothName: detection.toothName, detections: [] });

        const uniqueDetectionIndex = uniqueDetections.findIndex(
          (uniqueDetection) => uniqueDetection.toothName === detection.toothName
        );
        uniqueDetections[uniqueDetectionIndex].detections.push(detection.subtype);
      }
    });

    return uniqueDetections;
  }

  getPicture() {
    this.profileLink = this.customerProspectService.getProfilePicStreamLink(this.patient['ID']);
  }
}
