import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class HelperService {
  private settingsUrl: string = environment.nodeUrl + '/settings';
  constructor(private http: HttpClient) {}
  /**
   * @description
   * Get simple page by type
   * @param type
   */
  getSimplePage(type: string): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/simplepage/' + type + '/')
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  /**
   * @description
   * Set simpla page content
   * @param payload
   */
  editSimplePage(payload): Observable<any> {
    return this.http
      .post(environment.nodeUrl + '/simplepage/', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  /**
   * @description
   * Contact us
   * @param data
   */
  contactus(data) {
    return this.http.post(`${environment.nodeUrl}/message/us`, data);
  }

  getSimplePages(): Observable<any> {
    return this.http.get(`${environment.nodeUrl}/simplepage`).pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getSimplePagesByContext(context = null): Observable<any> {
    return this.http
      .get(`${environment.nodeUrl}/simplepage/context${context}`)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  addSimplePage(data): Observable<any> {
    return this.http.post(`${environment.nodeUrl}/simplePage`, data);
  }

  updateSimplePage(page): Observable<any> {
    return this.http.put(`${environment.nodeUrl}/simplePage/${page.type}`, page);
  }

  removeSimplePage(pageID): Observable<any> {
    return this.http.delete(`${environment.nodeUrl}/simplepage/${pageID}`);
  }

  messageUserUs(data) {
    return this.http.post(`${environment.nodeUrl}/message/user/us`, data);
  }

  messageUserUser(id, data) {
    return this.http.post(`${environment.nodeUrl}/message/user/user/${id}`, data);
  }

  messageUserInvitation(id, data, isAdmin = false) {
    if (isAdmin == true) {
      return this.http.post(`${environment.nodeUrl}/message/user/invitation/global/${id}`, data);
    } else {
      return this.http.post(`${environment.nodeUrl}/message/user/invitation/${id}`, data);
    }
  }

  messageUserGuest(data) {
    return this.http.post(`${environment.nodeUrl}/message/user/guest`, data);
  }

  messageGuestUs(data) {
    return this.http.post(`${environment.nodeUrl}/message/guest/us`, data);
  }

  messageGuestUser(id, data) {
    return this.http.post(`${environment.nodeUrl}/message/guest/user/${id}`, data);
  }

  messageInvitationUs(id, data) {
    return this.http.post(`${environment.nodeUrl}/message/invitation/us/${id}`, data);
  }

  messageInvitationUser(id, data) {
    return this.http.post(`${environment.nodeUrl}/message/invitation/user/${id}`, data);
  }

  subscribe(body): Observable<any> {
    return this.http
      .post(environment.nodeUrl + '/updateListedContacts/subscribe', body)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  whileListContact(body, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.http
        .post(environment.nodeUrl + '/updateListedContacts/while-list/global', body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/updateListedContacts/while-list', body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  checkUnsubscribe(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/listed-contacts/unsubscribe', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  unsubscribe(body): Observable<any> {
    return this.http
      .post(environment.nodeUrl + '/updateListedContacts/unsubscribe', body)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  isContactBlackList(payload): Observable<any> {
    return this.http
      .put(environment.nodeUrl + '/listed-contacts/blackListed', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  ////////////////////////////////////////

  getTemplateOverideDefaultByTag(ID, payload = {}, isAdminOrPromoter = false) {
    const params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key]) {
          params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    if (isAdminOrPromoter == true) {
      return this.http
        .get(`${environment.nodeUrl}/templateMerchant/tag/global/` + ID, options)
        .pipe(map((res: Response) => res['data']));
    } else {
      return this.http
        .get(`${environment.nodeUrl}/templateMerchant/tag/` + ID, options)
        .pipe(map((res: Response) => res['data']));
    }
  }

  getTemplateOverideDefault(ID, payload = {}, isAdminOrPromoter = false) {
    const params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key]) {
          params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    if (isAdminOrPromoter == true) {
      return this.http
        .get(`${environment.nodeUrl}/marketing/templateMerchant/global` + ID, options)
        .pipe(map((res: Response) => res['data']));
    } else {
      return this.http
        .get(`${environment.nodeUrl}/marketing/templateMerchant/` + ID, options)
        .pipe(map((res: Response) => res['data']));
    }
  }

  getTemplateOverideList(payload, isAdminOrPromoter = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (isAdminOrPromoter == true) {
      return this.http
        .get(`${environment.nodeUrl}/templateMerchant/global/`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(`${environment.nodeUrl}/templateMerchant/`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  createTemplateOveride(payload) {
    const urlPatient = environment.nodeUrl + '/templateMerchant';
    return this.http.post(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getCreateTemplateOveride(id) {
    const urlPatient = environment.nodeUrl + '/templateMerchant/overide/' + id;
    return this.http.put(urlPatient, {}).pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editTemplateOveride(id, payload) {
    const urlPatient = environment.nodeUrl + '/templateMerchant/' + id;
    return this.http.put(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getTemplateOverideLogo(ID, payload = {}) {
    const params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key]) {
          params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(`${environment.nodeUrl}/templateMerchant/logo-picture/` + ID, options)
      .pipe(map((res: Response) => res['data']));
  }

  getTemplateOverideLogoStreamLink(ID) {
    if (ID) {
      return `${environment.nodeUrl}/templateMerchant/logo-picture-stream/` + ID;
    }

    return null;
  }
  getPublicSettings(): Observable<any> {
    return this.http.get(`${this.settingsUrl}/public`, {}).pipe(map((res: Response) => res['data']));
  }
  getGlobalSettings(): Observable<any> {
    return this.http.get(`${this.settingsUrl}/global`, {}).pipe(map((res: Response) => res['data']));
  }
  updateSettings(id, body): Observable<any> {
    return this.http.put(`${this.settingsUrl}/${id}`, body).pipe(map((res: Response) => res['data']));
  }

  getTemplateOverideFacebook(ID, payload = {}) {
    const params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key]) {
          params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(`${environment.nodeUrl}/templateMerchant/facebook-picture/` + ID, options)
      .pipe(map((res: Response) => res['data']));
  }

  getTemplateOverideTwitter(ID, payload = {}) {
    const params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key]) {
          params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(`${environment.nodeUrl}/templateMerchant/twitter-picture/` + ID, options)
      .pipe(map((res: Response) => res['data']));
  }

  getTemplateOverideLinkedin(ID, payload = {}) {
    const params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key]) {
          params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(`${environment.nodeUrl}/templateMerchant/linkedin-picture/` + ID, options)
      .pipe(map((res: Response) => res['data']));
  }

  getTemplateOverideInstagram(ID, payload = {}) {
    const params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key]) {
          params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(`${environment.nodeUrl}/templateMerchant/instagram-picture/` + ID, options)
      .pipe(map((res: Response) => res['data']));
  }

  getTemplateOverideTemplate(ID, payload = {}) {
    const params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key]) {
          params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http.get(`${environment.nodeUrl}/template/` + ID, options).pipe(map((res: Response) => res['data']));
  }

  getTemplateOverideSocialMedia(ID, type, payload = {}) {
    const params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key]) {
          params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(`${environment.nodeUrl}/templateMerchant/social-media/` + ID + '/' + type, options)
      .pipe(map((res: Response) => res['data']));
  }

  getTemplateOverideSocialMediaStreamLink(ID, type) {
    if (ID && type) {
      return `${environment.nodeUrl}/templateMerchant/social-media-stream/` + ID + '/' + type;
    }
    return null;
  }

  ///////////////

  editTemplateOverideLogo(ID, payload = {}) {
    return this.http
      .put(`${environment.nodeUrl}/templateMerchant/logo/` + ID, payload)
      .pipe(map((res: Response) => res['data']));
  }

  editTemplateOverideFacebook(ID, payload = {}) {
    return this.http
      .put(`${environment.nodeUrl}/templateMerchant/facebook/` + ID, payload)
      .pipe(map((res: Response) => res['data']));
  }

  editTemplateOverideTwitter(ID, payload = {}) {
    return this.http
      .put(`${environment.nodeUrl}/templateMerchant/twitter/` + ID, payload)
      .pipe(map((res: Response) => res['data']));
  }

  editTemplateOverideLinkedin(ID, payload = {}) {
    return this.http
      .put(`${environment.nodeUrl}/templateMerchant/linkedin/` + ID, payload)
      .pipe(map((res: Response) => res['data']));
  }

  editTemplateOverideInstagram(ID, payload = {}) {
    return this.http
      .put(`${environment.nodeUrl}/templateMerchant/instagram/` + ID, payload)
      .pipe(map((res: Response) => res['data']));
  }

  getTemplateOverideTemplatePromoter(tag, payload = {}, isAdminOrPromoter = false) {
    const params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key]) {
          params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (isAdminOrPromoter == true) {
      return this.http
        .get(`${environment.nodeUrl}/templateMerchant/template-promoter/global/` + tag, options)
        .pipe(map((res: Response) => res['data']));
    } else {
      return this.http
        .get(`${environment.nodeUrl}/templateMerchant/template-promoter/` + tag, options)
        .pipe(map((res: Response) => res['data']));
    }
  }

  getTemplateLogo(ID, payload = {}) {
    const params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key]) {
          params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(`${environment.nodeUrl}/template/picture/` + ID, options)
      .pipe(map((res: Response) => res['data']));
  }

  getTemplateFacebook(ID, payload = {}) {
    const params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key]) {
          params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(`${environment.nodeUrl}/template/facebook-picture/` + ID, options)
      .pipe(map((res: Response) => res['data']));
  }

  getTemplateTwitter(ID, payload = {}) {
    const params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key]) {
          params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(`${environment.nodeUrl}/template/twitter-picture/` + ID, options)
      .pipe(map((res: Response) => res['data']));
  }

  getTemplateLinkedin(ID, payload = {}) {
    const params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key]) {
          params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(`${environment.nodeUrl}/template/linkedin-picture/` + ID, options)
      .pipe(map((res: Response) => res['data']));
  }

  getTemplateInstagram(ID, payload = {}) {
    const params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key]) {
          params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(`${environment.nodeUrl}/template/instagram-picture/` + ID, options)
      .pipe(map((res: Response) => res['data']));
  }
}
