import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GoogleApiService, GoogleAuthService } from 'ng-gapi';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { GmbAccount, GmbLocation, GmbPlaceReview } from './types/gmb.type';

@Injectable()
export class GoogleReviewsService {
  constructor(
    private googleApiService: GoogleApiService,
    private googleAuthService: GoogleAuthService,
    private httpClient: HttpClient
  ) {}

  onLoad() {
    return this.googleApiService.onLoad();
  }

  openOAuthDialog() {
    return this.googleAuthService.getAuth().pipe(
      switchMap((auth) => {
        return from(auth.grantOfflineAccess());
      })
    );
  }

  connectGoogleReviewAccount(merchantID: string, sessionType: string, Google_Review_Code: string) {
    let url = '/google-reviews/connect';

    if (sessionType === 'admin' || sessionType === 'promoter') {
      url = '/google-reviews/connect/global';
    }

    return this.httpClient.post(`${environment.nodeUrl}${url}`, {
      Google_Review_Code,
      merchantID,
    });
  }

  updateGoogleReviewBusinessSettings(
    merchantID: string,
    sessionType: string,
    Google_Reviews_Account_ID: string,
    Google_Reviews_Location_ID: string,
    Google_Review_Place_ID: string
  ) {
    let url = '/google-reviews/settings';

    if (sessionType === 'admin' || sessionType === 'promoter') {
      url = '/google-reviews/settings/global';
    }

    return this.httpClient.post(`${environment.nodeUrl}${url}`, {
      Google_Reviews_Account_ID,
      Google_Reviews_Location_ID,
      Google_Review_Place_ID,
      merchantID,
    });
  }

  disconnectGoogleReviewAccount(merchantID: string, sessionType: string) {
    let url = '/google-reviews/disconnect';

    if (sessionType === 'admin' || sessionType === 'promoter') {
      url = '/google-reviews/disconnect/global';
    }

    return this.httpClient.post(`${environment.nodeUrl}${url}`, { merchantID });
  }

  getAccounts(merchantID: string, sessionType: string) {
    let url = '/google-reviews/accounts';

    if (sessionType === 'admin' || sessionType === 'promoter') {
      url = '/google-reviews/accounts/global';
    }

    return this.httpClient
      .post<{
        success: boolean;
        datetime: Date;
        data: GmbAccount[];
      }>(`${environment.nodeUrl}${url}`, { merchantID })
      .pipe(map((result) => result.data));
  }

  getLocations(merchantID: string, sessionType: string, Google_Review_Account_ID: string) {
    let url = '/google-reviews/locations';

    if (sessionType === 'admin' || sessionType === 'promoter') {
      url = '/google-reviews/locations/global';
    }

    return this.httpClient
      .post<{
        success: boolean;
        datetime: Date;
        data: GmbLocation[];
      }>(`${environment.nodeUrl}${url}`, {
        Google_Review_Account_ID,
        merchantID,
      })
      .pipe(map((result) => result.data));
  }

  getLocation(merchantID: string, sessionType: string, Google_Review_Location_ID: string) {
    let url = '/google-reviews/location';

    if (sessionType === 'admin' || sessionType === 'promoter') {
      url = '/google-reviews/location/global';
    }

    return this.httpClient
      .post<{
        success: boolean;
        datetime: Date;
        data: GmbLocation;
      }>(`${environment.nodeUrl}${url}`, {
        Google_Review_Location_ID,
        merchantID,
      })
      .pipe(map((result) => result.data));
  }

  getReviews(
    merchantID: string,
    sessionType: string,
    Google_Review_Account_ID: string,
    Google_Review_Location_ID: string
  ) {
    let url = '/google-reviews/reviews';

    if (sessionType === 'admin' || sessionType === 'promoter') {
      url = '/google-reviews/reviews/global';
    }

    return this.httpClient
      .post<{
        success: boolean;
        datetime: Date;
        data: GmbPlaceReview;
      }>(`${environment.nodeUrl}${url}`, {
        Google_Review_Account_ID,
        Google_Review_Location_ID,
        merchantID,
      })
      .pipe(map((result) => result.data));
  }

  replyToReview(
    merchantID: string,
    sessionType: string,
    Google_Review_Account_ID: string,
    Google_Review_Location_ID: string,
    Google_Review_ID: string,
    comment: string
  ) {
    let url = '/google-reviews/reply';

    if (sessionType === 'admin' || sessionType === 'promoter') {
      url = '/google-reviews/reply/global';
    }

    return this.httpClient
      .post<{
        success: boolean;
        datetime: Date;
        data: string;
      }>(`${environment.nodeUrl}${url}`, {
        Google_Review_Account_ID,
        Google_Review_Location_ID,
        Google_Review_ID,
        comment,
        merchantID,
      })
      .pipe(map((result) => result.data));
  }
}
