import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '../../shared/shared.module';
import { PriceServiceEditComponent } from './price-service-edit/price-service-edit.component';
import { PriceServiceListComponent } from './price-service-list/price-service-list.component';
import { PriceServiceViewComponent } from './price-service-view/price-service-view.component';
import { PriceServiceRoutingModule } from './shared/price-service-routing.module';
import { PriceServiceService } from './shared/price-service.service';

@NgModule({
  imports: [
    NgPipesModule,
    CommonModule,
    FormsModule,
    CdkTableModule,
    CommonModule,
    PriceServiceRoutingModule,
    SharedModule,
    MatSortModule,
  ],
  providers: [PriceServiceService],
  declarations: [PriceServiceEditComponent, PriceServiceViewComponent, PriceServiceListComponent],
  exports: [PriceServiceEditComponent, PriceServiceViewComponent, PriceServiceListComponent],
})
export class PriceServiceModule {}
