import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { Settings } from '../../../shared/types/settings';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-marketing-new-campaign-modal',
  templateUrl: './marketing-new-campaign-modal.component.html',
  styleUrls: ['./marketing-new-campaign-modal.component.css'],
})
export class MarketingNewCampaignModalComponent implements OnInit {
  @Output()
  closeModal = new EventEmitter();

  isPromoterOrAdmin = false;

  patientCampaignActive = true;
  socialCampaignActive = true;
  blogCampaignActive = false;
  merchantCampaignActive = true;
  isCommunication = false
  isModuleMarketingSocialMediaActive = Settings.global['isModuleMarketingSocialMediaActive'];

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private utilService: UtilsService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {

      if(data.isCommunication===true)
      {
        this.isCommunication=true;
      }

    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
      this.utilService.getCurrentAccess().subscribe((access) => {
        if (access) {
          this.isModuleMarketingSocialMediaActive = this.socialCampaignActive;
        }
      });
    });
  }

  triggerNewPatientCampaign() {
    this.router.navigate(['/merchant', { outlets: { page: ['marketing-campaign-create'] } }]);
    this.closeModal.emit(true);
  }

  triggerNewSocialCampaign() {
    this.router.navigate(['/merchant', { outlets: { page: ['marketing-social-create-redirection'] } }]);
    this.closeModal.emit(true);
  }

  triggerNewBlogCampaign() {
    this.router.navigate(['/merchant', { outlets: { page: ['marketing-campaign-create'] } }]);
    this.closeModal.emit(true);
  }

  bulkSMS() {
    this.router.navigate(['/merchant', { outlets: { page: ['message-group-create'] } }]);
    this.closeModal.emit(true);
  }

  bulkEmail() {
    this.router.navigate(['/merchant', { outlets: { page: ['message-group-create'] } }]);
    this.closeModal.emit(true);
  }




  close() {
    this.closeModal.emit(true);
  }
}
