var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { CustomerProspectService } from '../../customer-prospect/shared/customerProspect.service';
import { DentistService } from '../../dentist/shared/dentist.service';
import { InvitationService } from '../../invitation/shared/services/invitation.service';
import { AppointmentService } from '../shared/appointment.service';
import { AppointmentLookupService } from '../shared/appointmentLookup.service';
export class AppointmentCreateComponent {
    constructor(utilService, authenticationService, appointmentService, dentistService, invitationService, appointmentLookupService, customerProspectService, router, activeRoute, data) {
        this.utilService = utilService;
        this.authenticationService = authenticationService;
        this.appointmentService = appointmentService;
        this.dentistService = dentistService;
        this.invitationService = invitationService;
        this.appointmentLookupService = appointmentLookupService;
        this.customerProspectService = customerProspectService;
        this.router = router;
        this.activeRoute = activeRoute;
        this.data = data;
        this.types = [];
        this.subTypes = [];
        this.bookDate = new Date();
        this.minDate = new Date();
        this.LastModified = null;
        this.bestContactTimes = [];
        this.title = 'Book an appointment for';
        this.description = 'Your patient will receive a confirmation by email.';
        this.isPromoterOrAdmin = false;
        this.close = new EventEmitter();
        this.duration = 60;
        this.smsConfirmAppointment = false;
        this.smsAppointmentReminder = false;
        if (data && typeof data === 'object') {
            this.invitationID = data.invitationID;
            this.customerID = data.customerID;
            this.merchantID = data.merchantID;
        }
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.sessionType = yield this.authenticationService.getSessionType().toPromise();
            this.isPromoterOrAdmin = yield this.authenticationService.isPromoterOrAdmin().toPromise();
            this.dentists = yield this.dentistService
                .getList({
                fields: 'ID,CalculatedName',
                merchantID: this.merchantID,
                canAcceptAppointment: true,
            }, this.isPromoterOrAdmin)
                .toPromise();
            let res;
            if (!this.invitationID) {
                this.invitationID = this.activeRoute.snapshot.paramMap.get('invitationID');
            }
            if (!this.customerID) {
                this.customerID = this.activeRoute.snapshot.paramMap.get('customerID');
            }
            if (!this.merchantID) {
                this.merchantID = this.activeRoute.snapshot.paramMap.get('merchantID');
            }
            if (this.invitationID) {
                const payload = {
                    fields: 'ID,FirstName,LastName,Merchant_Key,MerchantName,Prospect_key',
                };
                res = yield this.invitationService.getInvitationDetails(this.invitationID, payload, this.sessionType).toPromise();
                this.firstName = res.FirstName;
                this.lastName = res.LastName;
                this.patientKey = res.Prospect_key;
                this.customerID = res.Prospect_key;
            }
            else if (this.customerID) {
                const patientPayload = {
                    fields: 'ID,CalculatedName,FirstName,Name,emails.Email,LastModified',
                };
                res = yield this.customerProspectService
                    .getCustomerProspectDetails(this.customerID, patientPayload, this.sessionType)
                    .toPromise();
                this.firstName = res.FirstName;
                this.lastName = res.Name;
                this.patientKey = res.ID;
                this.LastModified = res.LastModified;
            }
            if (res) {
                this.customer = res;
            }
            else {
                this.router.navigate(['/404']);
            }
            const payloadBestContactTimes = {
                merchantID: this.merchantID,
                isActive: true,
                lookupType: 'BestAppointmentTime',
                fields: 'Lookup_Type,Category,Code,Label,Description,Sort_Order,DefaultDuration,Buffers_Pre,Buffers_Post',
            };
            this.bestContactTimes = yield this.appointmentLookupService
                .list(payloadBestContactTimes, this.sessionType)
                .toPromise();
            const payloadtypes = {
                merchantID: this.merchantID,
                isActive: true,
                lookupType: 'AppointmentTypes',
                fields: 'Lookup_Type,Category,Code,Label,Description,Sort_Order,DefaultDuration,Buffers_Pre,Buffers_Post',
            };
            this.types = yield this.appointmentLookupService.list(payloadtypes, this.sessionType).toPromise();
            this.authenticationService.getCurrentPractice().subscribe((res) => {
                if (res) {
                    this.selectedMerchant = res;
                    if (this.selectedMerchant['SMS_ConfirmAppointment'] == '1') {
                        this.smsConfirmAppointment = true;
                    }
                    if (this.selectedMerchant['SMS_AppointmentReminder'] == '1') {
                        this.smsAppointmentReminder = true;
                    }
                }
            });
        });
    }
    closeEvent() {
        this.close.emit(true);
    }
    addSubType() {
        if (this.type && this.type.Label3) {
            this.duration = Number(this.type.Label3);
        }
        else {
            this.duration = 60;
        }
        if (this.duration) {
            this.bookDateEnd = moment(this.bookDate).add(this.duration, 'minutes').toDate();
            this.defaultBookEndDate = moment(this.bookDateEnd).toDate();
            this.defaultBookEndTime = moment(this.bookDateEnd).format('HH:mm');
        }
        if (this.type && this.type.Code) {
            const payload = {
                category: this.type.Code,
                isActive: true,
                merchantID: this.merchantID,
                fields: 'Lookup_Type,Category,Code,Label,Description,Sort_Order,DefaultDuration,Buffers_Pre,Buffers_Post',
            };
            this.appointmentLookupService.list(payload, this.sessionType).subscribe((res) => {
                if (res) {
                    this.subTypes = res;
                }
            });
        }
    }
    subTypeSelect() {
        if (this.subType && this.subType.Label3) {
            this.duration = Number(this.subType.Label3);
        }
        else if (this.type && this.type.Label3) {
            this.duration = Number(this.type.Label3);
        }
        else {
            this.duration = 60;
        }
    }
    selectDateTime(d) {
        if (d) {
            this.bookDate = d;
            if (this.duration) {
                this.bookDateEnd = moment(this.bookDate).add(this.duration, 'minutes').toDate();
                this.defaultBookEndDate = moment(this.bookDateEnd).toDate();
                this.defaultBookEndTime = moment(this.bookDateEnd).format('HH:mm');
            }
        }
    }
    selectDateTimeSecond(d) {
        if (d) {
            this.bookDateEnd = d;
        }
    }
    book() {
        const p = {
            bookDate: this.bookDate,
            bookDateEnd: this.bookDateEnd,
            comment: this.comment,
            smsConfirmAppointment: this.smsConfirmAppointment,
            smsAppointmentReminder: this.smsAppointmentReminder,
        };
        if (this.type && this.type.Code) {
            p.typeCode = this.type.Code;
        }
        if (this.type && this.type.Label) {
            p.typeDescription = this.type.Label;
        }
        if (this.subType && this.subType.Code) {
            p.subTypeCode = this.subType.Code;
        }
        if (this.subType && this.subType.Label) {
            p.subTypeDescription = this.subType.Label;
        }
        if (this.dentist) {
            p.dentistID = this.dentist;
        }
        if (this.customerID) {
            p.customerID = this.customerID;
            p.patientID = this.customerID;
        }
        if (this.bestContactTime) {
            p.bestAppointmentCode = this.bestContactTime;
        }
        else {
            p.bestAppointmentCode = 'Afternoon';
        }
        this.appointmentService.createForMerchantWithPatient(p, this.sessionType).subscribe((res) => {
            if (res) {
                this.closeEvent();
                NotifyAppComponent.displayToast('success', 'Success!', 'Your patient has been notified .');
            }
        });
    }
    replaceAllString(e) {
        if (e && typeof e == 'string') {
            return String(e).replace(/�/g, "'");
        }
    }
}
