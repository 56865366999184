<div class="row clearfix" *ngIf="invitation">
  <!-- blue header region -->
  <mat-card class="bluecard">
    <div class="card-header primary-gradient-img clearfix inModal">
      <div class="row clearfix">
        <div class="row titleArea clearfix">
          <!-- customer/patient avatar -->
          <app-ep-document-view-image
            class="patientView userIconView pull-left"
            [defaultPicture]="
              'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
            "
            [link]="profileLink"
          >
          </app-ep-document-view-image>
          <!-- names and status -->
          <h2 class="summary-header rm-m">
            {{ invitation['FirstName'] }} {{ invitation['MiddleName'] }} {{ invitation['LastName'] }}
            <span class="subLabel"
            >Created: <strong>{{ invitation['DateTimeCreated'] | customDateTime }}</strong></span
            >
            <span class="badge" [style.background]="invitation['Status.Invitation.Colour'] || '#b4bcc1'"
            >Status: {{ invitation['Status.Invitation.Short'] }}</span
            >
          </h2>
        </div>

        <p class="chip small good pull-right" *ngIf="invitation['Status.Contract.Short_NE'] == 'Active'">
          <span class="">Contract active <mat-icon>check</mat-icon></span>
        </p>
        <p class="chip small bad pull-right" *ngIf="invitation['Expired'] == '1'">
          <span class="">Contract has expired</span>
        </p>
        <p class="chip small bad pull-right" *ngIf="invitation['Voided'] == '1'">
          <span class="">Marked as voided</span>
        </p>
      </div>
    </div>
  </mat-card>
  <!-- primary tab group -->
  <mat-tab-group class="primary" dynamicHeight="true">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="fas fa-envelope"></mat-icon>
        Details
      </ng-template>
      <div class="item full-width top">
        <div class="row clearfix flex">
          <mat-card class="full-width">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">Main Information</h3>
            <hr/>
            <div class="row clearfix flex text-center">
              <div class="half-width block">
                <mat-icon class="fas fa-user"></mat-icon>
                <label>{{ "KEYWORD.patient" | translate | titlecase }}</label>
                <h4 class="sr-title rm-mt">
                  {{ invitation['FirstName'] }} {{ invitation['MiddleName'] }}
                  {{ invitation['LastName'] }}
                </h4>
              </div>
              <div class="half-width block">
                <mat-icon class="fas fa-hand-holding-heart"></mat-icon>
                <label>Product License</label>
                <h4 class="sr-title rm-mt">
                  {{ invitation['ProductName'] }}
                </h4>
              </div>
            </div>

            <mat-list class="values-list">
              <mat-list-item>
                <p>{{"KEYWORD.patient" | translate | titlecase}} can choose:</p>
                <span class="value chip good" *ngIf="invitation['Product.PatientCanChoose'] == '1'">YES</span>
                <span class="value chip bad" *ngIf="invitation['Product.PatientCanChoose'] == '0'">NO</span>
              </mat-list-item>
              <mat-list-item>
                <p>Source:</p>
                <span class="value">{{ invitation['MerchantName'] }} {{ invitation['MerchantContactName'] }}</span>
              </mat-list-item>
              <mat-list-item>
                <p>Web ID:</p>
                <span class="value small">
                  {{ invitation['WebID'] }}

                  <button (click)="copy(invitation['WebID'])" mat-icon-button matTooltip="Copy to clipboard">
                    <mat-icon class="fas fa-copy"></mat-icon>
                  </button>
                </span>
              </mat-list-item>
              <mat-list-item>
                <p>URL:</p>
                <span class="value small">
                  <!-- buttons to copy or go to invitation url -->
                  <!-- {{invitation['URL']}}  -->

                  <button (click)="copy(invitation['URL'])" mat-icon-button matTooltip="Copy to clipboard">
                    <mat-icon class="fas fa-copy"></mat-icon>
                  </button>

                  <button (click)="onNavigate(invitation['URL'])" mat-icon-button matTooltip="Go to URL">
                    <mat-icon class="fas fa-external-link-alt"></mat-icon>
                  </button>
                </span>
              </mat-list-item>
            </mat-list>
          </mat-card>
          <mat-card class="full-width">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">Status</h3>
            <hr/>
            <div class="row clearfix flex text-center">
              <div class="half-width block">
                <mat-icon class="fas fa-envelope"></mat-icon>
                <label>Email</label>
                <h4 class="sr-title rm-mt">{{ invitation['Status.Email.Short'] }}</h4>
              </div>
              <div class="half-width block">
                <mat-icon class="fas fa-envelope-open"></mat-icon>
                <label>Invitation</label>
                <h4 class="sr-title rm-mt">
                  {{ invitation['Status.Invitation.Short'] }}
                  <span class="chip small good pull-right" *ngIf="invitation['Status.Invitation.Short'] == 'Proceeded'">
                    <mat-icon>check</mat-icon>
                  </span>
                </h4>
              </div>
            </div>
            <div class="row clearfix flex text-center">
              <div class="half-width block">
                <mat-icon class="fas fa-clipboard-list"></mat-icon>
                <label>Application</label>
                <h4 class="sr-title rm-mt">
                  {{ invitation['Status.Application.Short'] }}
                  <span
                    class="chip small good pull-right"
                    *ngIf="invitation['Status.Application.Short'] == 'Credit - Approved'"
                  >
                    <mat-icon>check</mat-icon>
                  </span>
                </h4>
              </div>
              <div class="half-width block">
                <mat-icon class="fas fa-file-signature"></mat-icon>
                <label>Contract</label>
                <h4 class="sr-title rm-mt">
                  {{ invitation['Status.Contract.Short'] }}
                  <span class="chip small good pull-right" *ngIf="invitation['Status.Contract.Short_NE'] == 'Active'">
                    <mat-icon>check</mat-icon>
                  </span>
                </h4>
              </div>
            </div>
            <div class="row clearfix flex text-center" *ngIf="invitation['CustomerChoice.Label']">
              <div class="full-width block">
                <mat-icon class="fas fa-reply-all"></mat-icon>
                <label>Response</label>
                <h4 class="sr-title rm-mt">{{ invitation['CustomerChoice.Label'] }}</h4>
              </div>
            </div>
          </mat-card>
          <mat-card class="full-width">
            <div class="colour-stripe"></div>

            <h3 class="sr-title rm-m sm-h3">Dates</h3>
            <hr/>

            <div class="row clearfix">
              <mat-list class="values-list">
                <mat-list-item *ngIf="invitation['DateTimeCreated'] != '0000-00-00'">
                  <p>Created:</p>
                  <span class="value">{{ invitation['DateTimeCreated'] | customDate }} </span>
                </mat-list-item>
                <mat-list-item *ngIf="invitation['Date.DateToBeSent'] != '0000-00-00'">
                  <p>To Be Sent:</p>
                  <span class="value">{{ invitation['DateToBeSent'] | customDate }}</span>
                </mat-list-item>
                <mat-list-item *ngIf="invitation['Date.Viewed'] != '0000-00-00'">
                  <p>Viewed:</p>
                  <span class="value">{{ invitation['Date.Viewed'] | customDate }}</span>
                </mat-list-item>
                <mat-list-item *ngIf="invitation['Date.Expiry'] != '0000-00-00'">
                  <p>Expiry:</p>
                  <span class="value">{{ invitation['Date.Expiry'] | customDate }}</span>
                </mat-list-item>
                <mat-list-item *ngIf="invitation['Date.Voided'] != '0000-00-00'">
                  <p>Voided:</p>
                  <span class="value">{{ invitation['Date.Voided'] | customDate }}</span>
                </mat-list-item>
                <mat-list-item *ngIf="invitation['Date.Discontinued'] != '0000-00-00'">
                  <p>Discontinued:</p>
                  <span class="value">{{ invitation['Date.Discontinued'] | customDate }}</span>
                </mat-list-item>
                <mat-list-item *ngIf="invitation['Date.Responded'] != '0000-00-00'">
                  <p>Responded:</p>
                  <span class="value">{{ invitation['Date.Responded'] | customDate }}</span>
                </mat-list-item>

                <mat-list-item *ngIf="invitation['Date.PlanningToCommence'] != '0000-00-00'">
                  <p>Planning to Commence:</p>
                  <span class="value">{{ invitation['Date.PlanningToCommence'] | customDate }}</span>
                </mat-list-item>
                <mat-list-item *ngIf="invitation['Date.Commenced'] != '0000-00-00'">
                  <p>Commenced:</p>
                  <span class="value">{{ invitation['Date.Commenced'] | customDate }}</span>
                </mat-list-item>
                <mat-list-item *ngIf="invitation['Date.Approved'] != '0000-00-00'">
                  <p>Approved:</p>
                  <span class="value">{{ invitation['Date.Approved'] | customDate }}</span>
                </mat-list-item>
                <mat-list-item *ngIf="invitation['Date.Referred'] != '0000-00-00'">
                  <p>Referred:</p>
                  <span class="value">{{ invitation['Date.Referred'] | customDate }}</span>
                </mat-list-item>
                <mat-list-item *ngIf="invitation['Date.Declined'] != '0000-00-00'">
                  <p>Declined:</p>
                  <span class="value">{{ invitation['Date.Declined'] | customDate }}</span>
                </mat-list-item>
                <mat-list-item *ngIf="invitation['Date.Completed'] != '0000-00-00'">
                  <p>Completed:</p>
                  <span class="value">{{ invitation['Date.Completed'] | customDate }}</span>
                </mat-list-item>
                <mat-list-item *ngIf="invitation['Date.Discontinued'] != '0000-00-00'">
                  <p>Discontinued:</p>
                  <span class="value">{{ invitation['Date.Discontinued'] | customDate }}</span>
                </mat-list-item>
              </mat-list>
            </div>
          </mat-card>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="fas fa-comment-dollar"></mat-icon>
        Quoted Values
      </ng-template>
      <div class="item full-width top">
        <div class="row clearfix flex">
          <mat-card class="full-width">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">Primary details</h3>
            <hr/>
            <div class="row clearfix">
              <div class="row clearfix flex text-center">
                <div class="half-width block">
                  <app-dentist-view-picture class="headNavView userIconView" [dentistID]="invitation['Merchant_key']">
                  </app-dentist-view-picture>

                  <label>{{ "KEYWORD.practitioner" | translate | titlecase }}</label>
                  <h4 class="sr-title rm-mt">{{ invitation['MerchantName'] }}</h4>
                </div>
                <div class="half-width block">
                  <mat-icon class="fas fa-hand-holding-heart"></mat-icon>
                  <label>Product License</label>
                  <h4 class="sr-title rm-mt">
                    {{ invitation['ProductName'] }}
                  </h4>
                </div>
              </div>

              <div class="row clearfix flex text-center">
                <div class="half-width block">
                  <mat-icon class="fas fa-hand-pointer"></mat-icon>
                  <label>{{"KEYWORD.patient" | translate | titlecase}} can choose</label>
                  <h4 class="sr-title rm-mt">
                    <span class="" *ngIf="invitation['Product.PatientCanChoose'] == '1'">Yes</span>
                    <span class="" *ngIf="invitation['Product.PatientCanChoose'] == '0'">No</span>
                  </h4>
                </div>
                <div class="half-width block">
                  <mat-icon class="fas fa-concierge-bell"></mat-icon>
                  <label>Type of Service</label>
                  <h4 class="sr-title rm-mt">
                    {{ invitation['Service.Type'] }}
                  </h4>
                </div>
              </div>
            </div>
          </mat-card>
          <mat-card class="full-width">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">Total values</h3>
            <hr/>
            <div class="row clearfix">
              <mat-list class="values-list">
                <mat-list-item>
                  <p>Invoice Total:</p>
                  <span class="value">{{ invitation['Treatment.Value'] | customCurrency }}</span>
                </mat-list-item>
                <mat-list-item>
                  <p>Less {{ "KEYWORD.patient" | translate | titlecase }} Contribution:</p>
                  <span class="value">{{ invitation['CustomerContribution'] | customCurrency }}</span>
                </mat-list-item>
                <mat-list-item>
                  <p>Lending Total:</p>
                  <span class="value">{{
                    toNumber(invitation['Treatment.Value']) - toNumber(invitation['CustomerContribution'])
                      | customCurrency
                    }}</span>
                </mat-list-item>
                <mat-list-item>
                  <p>Less Lending Deposit:</p>
                  <span class="value">{{ invitation['Quoted.Deposit'] | customCurrency }}</span>
                </mat-list-item>
                <mat-list-item>
                  <p>Interest & Fees:</p>
                  <span class="value">{{ invitation['Quoted.InterestAndFees'] | customCurrency }} </span>
                </mat-list-item>
                <mat-list-item>
                  <p>Total Repayments:</p>
                  <span class="value">{{ invitation['Quoted.TotalRepayable'] | customCurrency }} </span>
                </mat-list-item>
                <mat-list-item>
                  <p>Interest Rate:</p>
                  <span class="value">{{ invitation['Quoted.InterestRate'] }}%</span>
                </mat-list-item>
                <mat-list-item>
                  <p>Repayments :</p>
                  <span class="multiple">
                    <span class="value">{{ invitation['Quoted.Repayments.Count'] }}</span>
                    <span class="value">{{ invitation['Quoted.Repayments.Frequency.Noun.Plural'] }}</span
                    >,
                    <span class="value">
                      <span *ngIf="invitation['Quoted.Repayments.Frequency.Code'] == '1'">{{
                        invitation['Quoted.Approx.Weekly'] | customCurrency
                        }}</span>
                      <span *ngIf="invitation['Quoted.Repayments.Frequency.Code'] == '2'"
                      >{{ invitation['Quoted.Approx.Fortnightly'] | customCurrency }}
                      </span>
                      <span *ngIf="invitation['Quoted.Repayments.Frequency.Code'] == '4'">{{
                        invitation['Quoted.Approx.Monthly'] | customCurrency
                        }}</span>
                    </span>
                    <span class="value">{{ invitation['Quoted.Repayments.Frequency.Adverb'] }}</span>
                  </span>
                </mat-list-item>
              </mat-list>
            </div>
          </mat-card>

          <div class="full-width">
            <mat-card class="nudge-btm">
              <div class="colour-stripe"></div>
              <h3 class="sr-title rm-m sm-h3">Approximate Repayments</h3>
              <hr class="mb"/>
              <div class="row clearfix flex">
                <div class="block">
                  <label>Weekly</label>
                  <h3 class="sr-title rm-m">
                    {{ invitation['Quoted.Approx.Weekly'] | customCurrency }}
                  </h3>
                </div>
                <div class="block">
                  <label>Fortnightly</label>
                  <h3 class="sr-title rm-m">{{ invitation['Quoted.Approx.Fortnightly'] | customCurrency }}</h3>
                </div>
                <div class="block">
                  <label>Monthly</label>
                  <h3 class="sr-title rm-m">{{ invitation['Quoted.Approx.Monthly'] | customCurrency }}</h3>
                </div>
              </div>
            </mat-card>
            <mat-card class="">
              <div class="colour-stripe"></div>
              <h3 class="sr-title rm-m sm-h3">Cash Discount</h3>
              <hr/>
              <!-- details of cash discount if offered -->
              <div class="row clearfix">
                <mat-list class="values-list">
                  <mat-list-item>
                    <p>Offered:</p>
                    <span class="value">
                      <span class="value chip good" *ngIf="invitation['CashDiscount.Offered.YesNo'] == '1'">YES</span>
                      <span class="value chip bad" *ngIf="invitation['CashDiscount.Offered.YesNo'] == '0'">NO</span>
                    </span>
                  </mat-list-item>
                  <mat-list-item *ngIf="invitation['CashDiscount.Offered.YesNo'] == '1'">
                    <p>Discount:</p>
                    <span class="multiple">
                      <span class="value">{{ invitation['CashDiscount.Offered.Percentage'] }}</span
                      >% -
                      <span class="value primary-color">{{
                        invitation['CashDiscount.Offered.Amount'] | customCurrency
                        }}</span>
                    </span>
                  </mat-list-item>
                  <mat-list-item>
                    <p>Expires after:</p>
                    <span class="multiple">
                      <span class="value">{{ invitation['CashDiscount.Expiry.Days'] }}</span> days -
                      <span
                        class="value primary-color"
                        *ngIf="invitation['CashDiscount.Expiry.Date'] != '0000-00-00'"
                      >{{ invitation['CashDiscount.Expiry.Date'] | customDate }}</span
                      ></span
                    >
                  </mat-list-item>
                  <mat-list-item>
                    <p>Accepted:</p>
                    <span class="multiple" *ngIf="invitation['CashDiscount.Accepted.Amount'] > 0">
                      <span class="value">{{ invitation['CashDiscount.Accepted.Percentage'] }}</span
                      >% -
                      <span class="value">{{ invitation['CashDiscount.Accepted.Amount'] | customCurrency }}</span>
                    </span>
                    <span class="multiple" *ngIf="invitation['CashDiscount.Accepted.Amount'] == 0">
                      <span class="value chip bad">NO</span>
                    </span>
                  </mat-list-item>
                  <mat-list-item *ngIf="invitation['CashDiscount.Accepted.Date'] != '0000-00-00'">
                    <p>On:</p>
                    <span class="value">{{ invitation['CashDiscount.Accepted.Date'] | customDate }}</span>
                  </mat-list-item>
                </mat-list>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab *ngIf="invitation['Status.Invitation.Short_NE'] == 'Proceeded' && contract">
      <ng-template mat-tab-label>
        <mat-icon class="fas fa-file-invoice-dollar"></mat-icon>
        Quotation
      </ng-template>
      <div class="item full-width top">
        <div class="row clearfix flex">
          <mat-card class="thrd-width">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">Primary details</h3>
            <hr/>
            <div class="row clearfix flex text-center">
              <div class="half-width block">
                <mat-icon class="fas fa-user"></mat-icon>
                <label>{{ "KEYWORD.patient" | translate | titlecase }}</label>
                <h4 class="sr-title rm-mt">
                  {{ invitation['FirstName'] }} {{ invitation['MiddleName'] }}
                  {{ invitation['LastName'] }}
                </h4>
              </div>
              <div class="half-width block">
                <mat-icon class="fas fa-file-invoice-dollar"></mat-icon>
                <label>Converted to Quotation</label>
                <h4 class="sr-title rm-mt">
                  {{ contract['DateTimeCreated'] | customDateTime }}
                </h4>
              </div>
            </div>
            <div class="row clearfix flex text-center">
              <div class="full-width block">
                <mat-icon class="fas fa-calendar-alt"></mat-icon>
                <label>Installments</label>
                <h4 class="sr-title rm-mt multiple">
                  <span class="value"
                  >{{ contract['LoanInstalments'] }} {{ contract['RepaymentFrequency.Noun.Plural'] }} </span
                  >,
                  <span class="value"
                  >{{ contract['Amount.PerPayment'] | customCurrency }} per
                    {{ contract['RepaymentFrequency.Noun.Singular'] }}
                  </span>
                </h4>
              </div>
            </div>
          </mat-card>

          <mat-card class="full-width">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">Quotation details</h3>
            <hr/>
            <div class="row clearfix">
              <mat-list class="values-list calculation">
                <mat-list-item>
                  <p class="primary-color"><strong>Invoice Total (Less {{ "KEYWORD.patient" | translate | titlecase }}
                    Contribution)</strong></p>
                  <span class="value primary-color"
                  ><strong>{{ contract['Amount.Invoice'] | customCurrency }}</strong></span
                  >
                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-minus-circle"></mat-icon>
                    Deposit
                  </p>
                  <span class="value">{{ contract['Deposit'] | customCurrency }}</span>
                </mat-list-item>
                <mat-list-item>
                  <p class="primary-color"><strong>Amount Financed</strong></p>
                  <span class="value primary-color"
                  ><strong>{{ contract['Amount.Financed'] | customCurrency }}</strong></span
                  >
                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-plus-circle"></mat-icon>
                    Establishment Fee
                  </p>
                  <span class="value">{{ contract['EstablishmentFee'] | customCurrency }}</span>
                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-plus-circle"></mat-icon>
                    Stamp Duty
                  </p>
                  <span class="value">{{ contract['Amount.StampDuty'] | customCurrency }}</span>
                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-plus-circle"></mat-icon>
                    Interest
                  </p>
                  <span class="value">{{ contract['OriginalInterest'] | customCurrency }}</span>
                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-plus-circle"></mat-icon>
                    Account Keeping Fees
                  </p>
                  <span class="value">{{ contract['AccountKeepingFees'] | customCurrency }}</span>
                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-plus-circle"></mat-icon>
                    Commission
                  </p>
                  <span class="value">{{ contract['CustomerCommission'] | customCurrency }}</span>
                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-equals"></mat-icon>
                    <strong>Sub Total</strong>
                  </p>
                  <span class="value"
                  ><strong>{{
                    toNumber(contract['EstablishmentFee']) +
                    toNumber(contract['OriginalInterest']) +
                    toNumber(contract['AccountKeepingFees']) +
                    toNumber(contract['CustomerCommission']) | customCurrency
                    }}</strong></span
                  >
                </mat-list-item>
                <mat-list-item class="primary-bg total">
                  <p><strong>Loan Inc. Charges</strong></p>
                  <span class="value">{{ contract['Amount.Loan.Original'] | customCurrency }}</span>
                </mat-list-item>
              </mat-list>
            </div>
          </mat-card>
        </div>
      </div>
    </mat-tab>
    <mat-tab
      *ngIf="
        invitation['Status.Invitation.Short_NE'] == 'Proceeded' &&
        invitation['Contract_Key'] &&
        invitation['Contract_Key'] != '00000000000000000000000000000000' &&
        contract
      "
    >
      <ng-template mat-tab-label>
        <mat-icon class="fas fa-file-signature"></mat-icon>
        Contract
      </ng-template>
      <div class="item full-width top">
        <div class="row clearfix flex">
          <mat-card class="full-width">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">Primary details</h3>
            <hr/>
            <div class="row clearfix flex text-center">
              <div class="half-width block">
                <mat-icon class="fas fa-user"></mat-icon>
                <label>{{ "KEYWORD.patient" | translate | titlecase }}</label>
                <h4 class="sr-title rm-mt">{{ contract['Customer.FullName'] }}</h4>
              </div>
              <div class="half-width block">
                <mat-icon class="fas fa-file-invoice-dollar"></mat-icon>
                <label>Status</label>
                <h4 class="sr-title rm-mt">
                  {{ contract['Status'] }}
                </h4>
              </div>
            </div>
            <div class="row clearfix flex text-center">
              <div class="half-width block">
                <mat-icon class="fas fa-clock"></mat-icon>
                <label>Loan Start</label>
                <h4 class="sr-title rm-mt">
                  <span class="value" *ngIf="contract['LoanStartDate']">{{
                    contract['LoanStartDate'] | customDate
                    }}</span>
                  <span class="value" *ngIf="!contract['LoanStartDate'] || contract['LoanStartDate'] == '0000-00-00'"
                  >Not yet started
                  </span>
                </h4>
              </div>
              <div class="half-width block">
                <mat-icon class="fas fa-calendar-alt"></mat-icon>
                <label>Installments</label>
                <h4 class="sr-title rm-mt multiple">
                  <span class="value"
                  >{{ contract['LoanInstalments'] }} {{ contract['RepaymentFrequency.Noun.Plural'] }} </span
                  >,
                  <span class="value"
                  >{{ contract['Amount.PerPayment'] | customCurrency }} per
                    {{ contract['RepaymentFrequency.Noun.Singular'] }}
                  </span>
                </h4>
              </div>
            </div>
            <hr *ngIf="contract['ID']"/>
            <!-- show go to contract button if contract ID exists -->
            <div class="row clearfix">
              <button
                class="pull-right"
                *ngIf="contract['ID']"
                (click)="linktoContract(contract['ID'])"
                mat-raised-button
                color="primary"
                matTooltip="Link to Contract"
              >
                Link to contract <mat-icon>search</mat-icon>
              </button>
            </div>
          </mat-card>

          <mat-card class="full-width">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">As Quoted</h3>
            <hr/>

            <div class="row clearfix">
              <mat-list class="values-list calculation">
                <mat-list-item>
                  <p class="primary-color"><strong>Invoice Total (Less {{ "KEYWORD.patient" | translate | titlecase }}
                    Contribution)</strong></p>
                  <span class="value primary-color"
                  ><strong>{{ contract['Amount.Invoice'] | customCurrency }}</strong></span
                  >
                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-minus-circle"></mat-icon>
                    Less Deposit
                  </p>
                  <span class="value">{{ contract['Deposit'] | customCurrency }}</span>
                </mat-list-item>
                <mat-list-item>
                  <p class="primary-color"><strong>Amount Financed</strong></p>
                  <span class="value primary-color">{{ contract['Amount.Financed'] | customCurrency }}</span>
                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-plus-circle"></mat-icon>
                    Interest
                  </p>
                  <span class="value">{{ contract['OriginalInterest'] | customCurrency }}</span>
                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-plus-circle"></mat-icon>
                    Fees & Charges
                  </p>
                  <span class="value">{{ contract['OriginalFees'] | customCurrency }}</span>
                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-plus-circle"></mat-icon>
                    Added Expenses
                  </p>
                  <span class="value">{{ contract['AddedFees'] | customCurrency }}</span>
                </mat-list-item>
                <mat-list-item class="primary-bg total">
                  <p><strong>Total Inc. Charges</strong></p>
                  <span class="value">{{ contract['Amount.Original'] | customCurrency }}</span>
                </mat-list-item>
              </mat-list>
            </div>
          </mat-card>

          <mat-card class="full-width">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">Actual</h3>
            <hr/>
            <div class="row clearfix">
              <mat-list class="values-list calculation">
                <mat-list-item>
                  <p class="primary-color"><strong>Drawn Amount</strong></p>
                  <span class="value primary-color" *ngIf="contract['Status.Code'] != 'ACTIVE'">$0.00</span>
                  <span class="value primary-color" *ngIf="contract['Status.Code'] == 'ACTIVE'">{{
                    contract['Settlements.Drawn'] | customCurrency
                    }}</span>
                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-minus-circle"></mat-icon>
                    Less Deposit
                  </p>
                  <span class="value" *ngIf="contract['Status.Code'] != 'ACTIVE'">$0.00</span>
                  <span class="value" *ngIf="contract['Status.Code'] == 'ACTIVE'">{{
                    contract['Deposit'] | customCurrency
                    }}</span>
                </mat-list-item>
                <mat-list-item>
                  <p class="primary-color"><strong>Amount Financed</strong></p>
                  <span class="value primary-color" *ngIf="contract['Status.Code'] != 'ACTIVE'">$0.00</span>
                  <span class="value primary-color" *ngIf="contract['Status.Code'] == 'ACTIVE'">{{
                    contract['Settlements.Drawn'] | customCurrency
                    }}</span>
                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-plus-circle"></mat-icon>
                    Interest
                  </p>
                  <span class="value" *ngIf="contract['Status.Code'] != 'ACTIVE'">$0.00</span>
                  <span class="value" *ngIf="contract['Status.Code'] == 'ACTIVE'">{{
                    contract['OriginalInterest'] | customCurrency
                    }}</span>
                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-plus-circle"></mat-icon>
                    Fees &amp; Charges
                  </p>
                  <span class="value" *ngIf="contract['Status.Code'] != 'ACTIVE'">$0.00</span>
                  <span class="value" *ngIf="contract['Status.Code'] == 'ACTIVE'">{{
                    contract['OriginalFees'] | customCurrency
                    }}</span>
                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-plus-circle"></mat-icon>
                    Added Expenses
                  </p>
                  <span class="value" *ngIf="contract['Status.Code'] != 'ACTIVE'">$0.00</span>
                  <span class="value" *ngIf="contract['Status.Code'] == 'ACTIVE'">{{
                    contract['AddedFees'] | customCurrency
                    }}</span>
                </mat-list-item>
                <mat-list-item class="primary-bg total">
                  <p><strong>Total Inc. Charges</strong></p>
                  <span class="value" *ngIf="contract['Status.Code'] != 'ACTIVE'">$0.00</span>
                  <span class="value" *ngIf="contract['Status.Code'] == 'ACTIVE'">{{
                    contract['Amount.Original'] | customCurrency
                    }}</span>
                </mat-list-item>
              </mat-list>
            </div>
          </mat-card>
        </div>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="fas fa-user"></mat-icon>
        Personal Details
      </ng-template>
      <div class="item top">
        <div class="row clearfix flex">
          <mat-card class="full-width">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">Personal Information</h3>
            <hr/>

            <div class="row clearfix" *ngIf="invitation['Gender.Label']">
              <div class="icons">
                <mat-icon class="fas fa-mars" *ngIf="invitation['Gender.Label'] == 'Male'"></mat-icon>
                <mat-icon class="fas fa-venus" *ngIf="invitation['Gender.Label'] == 'Female'"></mat-icon>
                <mat-icon
                  class="fas fa-venus-mars"
                  *ngIf="invitation['Gender.Label'] != 'Male' && invitation['Gender.Label'] != 'Female'"
                ></mat-icon>
              </div>
              <div class="row-content">
                <p class="lead">{{ invitation['Gender.Label'] }}</p>
                <p class="small">Gender</p>
              </div>
            </div>
            <hr/>
            <div class="row clearfix">
              <div class="icons">
                <mat-icon class="fas fa-user"></mat-icon>
              </div>
              <div class="row-content">
                <div class="pull-left mr">
                  <p class="lead" *ngIf="invitation['Salutation']">{{ invitation['Salutation'] }}</p>
                  <p class="small" *ngIf="invitation['Salutation']">Salutation</p>
                </div>
                <div class="pull-left mr">
                  <p class="lead" *ngIf="invitation['FirstName']">{{ invitation['FirstName'] }}</p>
                  <p class="small" *ngIf="invitation['FirstName']">First Name</p>
                </div>
                <div class="pull-left mr">
                  <p class="lead" *ngIf="invitation['MiddleName']">{{ invitation['MiddleName'] }}</p>
                  <p class="small" *ngIf="invitation['MiddleName']">Middle Name</p>
                </div>
                <div class="pull-left">
                  <p class="lead" *ngIf="invitation['LastName']">{{ invitation['LastName'] }}</p>
                  <p class="small" *ngIf="invitation['LastName']">Last Name</p>
                </div>
              </div>
            </div>
            <hr/>
            <div class="row clearfix">
              <div class="icons">
                <mat-icon class="fas fa-mobile"></mat-icon>
              </div>
              <div class="row-content">
                <p class="lead" *ngIf="invitation['Mobile']">{{ invitation['Mobile'] }}</p>
                <p class="small" *ngIf="invitation['Mobile']">Mobile</p>

                <p class="lead" *ngIf="invitation['Phone.Home']">{{ invitation['Phone.Home'] }}</p>
                <p class="small" *ngIf="invitation['Phone.Home']">Home Phone</p>

                <p class="lead" *ngIf="invitation['Phone.Work']">{{ invitation['Phone.Work'] }}</p>
                <p class="small" *ngIf="invitation['Phone.Work']">Work Phone</p>
              </div>
            </div>
            <hr/>
            <div class="row clearfix">
              <div class="icons">
                <mat-icon class="fas fa-envelope"></mat-icon>
              </div>
              <div class="row-content">
                <p class="lead">{{ invitation['Email'] }}</p>
                <p class="small">Email</p>
              </div>
            </div>
          </mat-card>

          <mat-card class="full-width">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">Status</h3>
            <hr/>

            <div class="row clearfix flex text-center">
              <div class="half-width block">
                <mat-icon class="fas fa-envelope"></mat-icon>
                <label>Email</label>
                <h4 class="sr-title rm-mt">{{ invitation['Status.Email.Short_NE'] }}</h4>
              </div>
              <div class="half-width block">
                <mat-icon class="fas fa-envelope-open"></mat-icon>
                <label>Invitation</label>
                <h4 class="sr-title rm-mt">
                  {{ invitation['Status.Invitation.Short_NE'] }}
                  <span
                    class="chip small good pull-right"
                    *ngIf="invitation['Status.Invitation.Short_NE'] == 'Proceeded'"
                  >
                    <mat-icon>check</mat-icon>
                  </span>
                </h4>
              </div>
            </div>
            <div class="row clearfix flex text-center">
              <div class="half-width block">
                <mat-icon class="fas fa-clipboard-list"></mat-icon>
                <label>Application</label>
                <h4 class="sr-title rm-mt">
                  {{ invitation['Status.Application.Short_NE'] }}
                  <span
                    class="chip small good pull-right"
                    *ngIf="invitation['Status.Application.Short_NE'] == 'Credit - Approved'"
                  >
                    <mat-icon>check</mat-icon>
                  </span>
                </h4>
              </div>
              <div class="half-width block">
                <mat-icon class="fas fa-file-signature"></mat-icon>
                <label>Contract</label>
                <h4 class="sr-title rm-mt">
                  {{ invitation['Status.Contract.Short_NE'] }}
                  <span class="chip small good pull-right" *ngIf="invitation['Status.Contract.Short_NE'] == 'Active'">
                    <mat-icon>check</mat-icon>
                  </span>
                </h4>
              </div>
            </div>
            <hr/>
            <div class="row clearfix flex text-center">
              <div class="full-width block">
                <app-dentist-view-picture class="headNavView userIconView" [dentistID]="invitation['Merchant_key']">
                </app-dentist-view-picture>

                <label>Source</label>
                <h4 class="sr-title rm-mt">{{ invitation['MerchantName'] }}</h4>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="fas fa-info-circle"></mat-icon>
        Informed Consent
      </ng-template>
      <div class="item top">
        <div class="row clearfix flex">
          <div class="two-thrd-width" *ngIf="invitation['Prospect_key'] && invitation['Prospect_key'] != ''">
            <app-treatment-document
              class="clearfix"
              [invitationID]="invitation['ID']"
              [canView]="false"
              [isDisplayViewedDate]="true"
              (getDocuments)="getDocuments($event)"
              (getViewedDocuments)="getViewedDocuments($event)"
            >
            </app-treatment-document>
          </div>
          <div class="thrd-width" *ngIf="documents && documents.length > 0">
            <mat-card class="chart-card">
              <div class="colour-stripe"></div>
              <div class="row clearfix rel">
                <div class="total anim">
                  <span class="totLab">&nbsp;</span>
                  <!--total document number -->
                  <span>{{ documents.length }}</span>
                  <span class="totSub">Total</span>
                </div>
                <app-chart-input
                  class="fullHeight"
                  *ngIf="documents && documents.length > 0"
                  [titleIsTop]="true"
                  [showLegend]="false"
                  [showLabel]="false"
                  [chartType]="'pie'"
                  [title]="'Informed Consent'"
                  [data]="chartData | orderBy: ['-name']"
                  [dataNameAttribute]="'name'"
                  [dataValueAttribute]="'value'"
                >
                </app-chart-input>
              </div>
              <div class="row clearfix legendary">
                <label class="rm-mt num anim statusList">
                  <span class="wide splitLabel">
                    <strong class="greyble" [style.color]="chartColors[0]">Viewed</strong>
                    Documents seen by {{"KEYWORD.patient" | translate}}</span
                  >
                  <strong class="pill" [style.background]="chartColors[0]">{{ seenDocs }}</strong>
                </label>
                <mat-divider></mat-divider>
                <label class="rm-mt num anim statusList">
                  <span class="wide splitLabel">
                    <strong class="greyble" [style.color]="chartColors[1]">Not viewed</strong>
                    Documents yet to be seen</span
                  >
                  <strong class="pill" [style.background]="chartColors[1]">{{ unseenDocs }}</strong>
                </label>

                <label class="rm-mt num anim statusList totalRow" [class.good]="seenPercent == '1'">
                  <span class="wide splitLabel">
                    <strong class="greyble">Completion</strong>
                  </span>
                  <strong class="pill">{{ seenPercent | percent: '1.0-0' }}</strong>
                </label>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
      <div class="item top" *ngIf="documents && documents.length == 0">
        <app-empty-list-message [title]="'No Documents Found'" [message]="'There are no documents to show'">
        </app-empty-list-message>
      </div>
    </mat-tab>
    <!-- <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="fas fa-comment"></mat-icon> Comments
      </ng-template>
      <div class="full-width prerequisites messages" *ngIf="(invitation['MessageFromDentist'] && invitation['MessageFromDentist']!='') ||
      (invitation['PatientDeclineReasons.Feedback'] && invitation['PatientDeclineReasons.Feedback']!='')
      || (invitation['PatientDeclineReasons.Labels'] && invitation['PatientDeclineReasons.Labels']!='')">

        <div class="full-height">

          <mat-card class="">

            <div class="row clearfix">
              <h3 class="sm-h3 sr-title rm-mb rm-mt">{{"Message log" | translate}}</h3>
              <hr class="mb">
            </div>
            <div class="row clearfix">
              <div class="pull-left">
                <h2 class="sr-title sm-h3 rm-m mb">
                 From Merchant
                </h2>
              </div>
              <div class="pull-right">
                <h2 class="sr-title sm-h3 rm-m mb">From {{"KEYWORD.Consumer" | translate}}</h2>
              </div>
            </div>
            <div class="row clearfix">
              <div class="column">
                <p class="larger speech-bubble primary-bg">{{invitation['MessageFromDentist'] || '...'}}</p>
                <p class="larger pull-right speech-bubble-right primary-light-bg" *ngIf="invitation['PatientDeclineReasons.Feedback'] && invitation['PatientDeclineReasons.Feedback']!=''">{{invitation['PatientDeclineReasons.Feedback']
                  || '...'}}</p>
                <p class="larger pull-right speech-bubble-right primary-light-bg" *ngIf="invitation['PatientDeclineReasons.Labels'] && invitation['PatientDeclineReasons.Labels']!=''">{{invitation['PatientDeclineReasons.Labels']
                  || '...'}}</p>
              </div>
            </div>
          </mat-card>

        </div>

      </div>
    </mat-tab> -->
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="fas fa-comments"></mat-icon>
        Communications
      </ng-template>
      <div class="item top">
        <app-message-timeline [invitationID]="invitation['ID']"></app-message-timeline>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="fas fa-clipboard-list"></mat-icon>
        Action Logs
      </ng-template>
      <div class="item top">
        <app-event-log-list [type]="'invitation'" [invitationID]="invitation['ID']"></app-event-log-list>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
