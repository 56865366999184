<app-customer-profile-tabs
  *ngIf="customerID"
  [tabs]="tabs"
  [isIntegrated]="isIntegrated"
  [settings]="settings"
  [displayTabs]="displayTabs"
  [selectedMerchant]="selectedMerchant"
  [itemID]="itemID"
  [patientID]="customerID"
  (goBack)="goBackEvent($event)"
  (merchantID)="getMerchantID($event)"
  (birthday)="getBirthday($event)"
></app-customer-profile-tabs>
<app-prospect-profile-tabs
  *ngIf="prospectID"
  [tabs]="tabs"
  [isIntegrated]="isIntegrated"
  [settings]="settings"
  [displayTabs]="displayTabs"
  [selectedMerchant]="selectedMerchant"
  [itemID]="itemID"
  [patientID]="prospectID"
  (goBack)="goBackEvent($event)"
  (merchantID)="getMerchantID($event)"
  (birthday)="getBirthday($event)"
></app-prospect-profile-tabs>
