<ipv-page
  *ngIf="(viewModel$ | async) as viewModel"
  [dataHeader]="{title: ('Side Menu.header.Suppliers.Suppliers' | translate), counterValue: viewModel.beneficiaries.length}"
  [isFetchingInitialData]="isFetchingInitialData"
>
    <!-- TABLE TOOLBAR -->
    <ng-container>
      <div class="row flex ctl flexwrap" style="margin-bottom: 40px !important;">

        <div class="half-width flex">
          <ng-container *ipvIsPromoterOrAdmin>
            <button
                    mat-raised-button
                    color="primary"
                    style="margin-right: 5px"
                    [routerLink]="['/merchant/', {outlets: {'page': ['beneficiary-create']}}]"
                    [routerLinkActive]="['active']"
            >
              <mat-icon>add</mat-icon> {{"Add Supplier" | translate }}
            </button>

            <button
                    mat-raised-button
                    color="accent"
                    [routerLink]="['/merchant/', {outlets: {'page': ['beneficiary-list-map']}}]"
                    [routerLinkActive]="['active']"
            >
              <mat-icon>public</mat-icon> {{"Supplier map" | translate }}
            </button>
          </ng-container>
        </div>

        <div class="half-width flex flexend">
          <mat-button-toggle-group
            class="btn-group rm-m selectType"
            (change)="setTypeCodeFilter($event.value)"
          >

            <mat-button-toggle [value]="null">
              <mat-icon class="fas fa-parachute-box"></mat-icon> {{"All Suppliers" | translate }}
            </mat-button-toggle>

            <mat-button-toggle [value]="'MANU'">
              <mat-icon class="fas fa-industry"></mat-icon> {{"Manufacturers" | translate }}
            </mat-button-toggle>


            <mat-button-toggle [value]="'DISTR'">
              <mat-icon class="fas fa-truck"></mat-icon> {{"Distributors" | translate }}
            </mat-button-toggle>

          </mat-button-toggle-group>

          <button
            mat-raised-button
            color="accent"
            [routerLink]="['/merchant/', {outlets: {'page': ['beneficiary-list-map']}}]"
            [routerLinkActive]="['active']"
            *ipvIsPromoterOrAdmin="false"
          >
            <mat-icon>public</mat-icon> {{"Supplier map" | translate }}
          </button>

          <button
            mat-stroked-button
            class="row select-btn"
            (click)="selectAll(viewModel.beneficiaries)"
            *ipvIsPromoterOrAdmin
            matTooltip='{{"Select All" | translate }}'
          >
            <mat-icon>border_all</mat-icon>
          </button>

          <button
            mat-stroked-button
            class="row select-btn"
            (click)="selectPage(viewModel.beneficiaries)"
            *ipvIsPromoterOrAdmin
            matTooltip='{{"Select Page" | translate }}'
          >
            <mat-icon>border_outer</mat-icon>
          </button>

                <button
                  mat-stroked-button class="animated"
                  [matMenuTriggerFor]="ActionBtnmenu"
                  *ngIf="countSelectedItems(viewModel.beneficiaries)"
                  matTooltip='{{"Options" | translate }}'
                >
                  <mat-icon class="fas fa-cog"></mat-icon>
                </button>

                <mat-menu #ActionBtnmenu="matMenu">

                  <button mat-menu-item class="row select-btn" (click)="unSelectAll(viewModel.beneficiaries)">
                    <mat-icon>select_all</mat-icon> {{"Unselect" | translate }}
                  </button>

                  <button mat-menu-item class="row select-btn" (click)="disableGroup(viewModel.beneficiaries)">
                    <mat-icon>cancel</mat-icon> {{"Disable" | translate }}
                  </button>

                  <button mat-menu-item color="" class="row select-btn" (click)="enableGroup(viewModel.beneficiaries)">
                    <mat-icon>check_circle</mat-icon> {{"Enable" | translate}}
                  </button>

                  <button mat-menu-item color="" class="row select-btn" (click)="addDocumentGroup(viewModel.beneficiaries)">
                    <mat-icon>add_circle</mat-icon> {{"Add document" | translate}}
                  </button>
                </mat-menu>
        </div>
      </div>
    </ng-container>

    <!-- TABLE -->
    <ng-container>
      <ipv-data-table-control-container>

        <!-- SUPPLIER SEARCH -->
        <mat-form-field
          class="full-width"
          appearance="outline"
        >
          <mat-label>Name</mat-label>
          <div style="display: flex">
            <input
              name="BeneficiaryName"
              class="clearfix"
              matInput
              [value]="viewModel.tradingAs"
              (input)="setTradingAsFilter($event.target.value)"
            >
            <ipv-clear-search-button *ngIf="viewModel.tradingAs" (click)="setTradingAsFilter(null)"></ipv-clear-search-button>
          </div>

        </mat-form-field>


      </ipv-data-table-control-container>
      <ipv-data-table
        (pageChanged)="setPage($event)"
        (itemClicked)="openCatelogueVIew($event.ID)"
        (itemChecked)="updateIsChecked($event)"
        [hasCheckboxes]="viewModel.isPromoterOrAdmin"
        [customComponents]="{
          'Company Logo': companyLogo,
          'Type_Code': companyType,
          'Active': isActiveIndicator
        }"
        [rename]="{'Type_Code': 'Type', 'TradingAs': 'Trading As'}"
        [hide]="hiddenColumns"
        [table]="viewModel.beneficiaries"
        [menuTemplate]="actions"
        [order]="order"
        [pageSize]="pageSize"
        [pageIndex]="pageIndex"
      >

      </ipv-data-table>
    </ng-container>

  <!-- show document list as page -->

  <div class="showDocumentList" *ngIf="showDocList == true">
    <div class="row clearfix">
      <app-treatment-document-gallery-container class="clearfix" [supplierID]="selectedSupplierID" [title]='docListTitle'>
      </app-treatment-document-gallery-container>
    </div>
    <div class="row clearfix text-center mt">
      <button mat-raised-button color="accent" (click)="  hideViewDetails() ">
        <mat-icon>chevron_left</mat-icon> Supplier List
      </button>
    </div>
  </div>


<ng-container *ipvIsPromoterOrAdmin>
  <div class="showDocumentList" *ngIf="showProductList == true">
    <div class="row clearfix">
      <app-treatment-product-list class="clearfix" [supplierID]="selectedSupplierID" [title]='productListTitle'>
      </app-treatment-product-list>
    </div>
    <div class="row clearfix text-center mt">
      <button mat-raised-button color="accent" (click)="  hideViewDetails() ">
        <mat-icon>chevron_left</mat-icon> Suppliers List
      </button>
    </div>
  </div>
</ng-container>

  <ng-container *ipvIsPromoterOrAdmin>
    <div class="showDocumentList" *ngIf="showProductList == true">
      <div class="row clearfix">
        <app-treatment-product-list-global class="clearfix" [supplierID]="selectedSupplierID" [title]='productListTitle'>
        </app-treatment-product-list-global>
      </div>
      <div class="row clearfix text-center mt">
        <button mat-raised-button color="accent" (click)="  hideViewDetails() ">
          <mat-icon>chevron_left</mat-icon> Suppliers List
        </button>
      </div>
    </div>
  </ng-container>


  <!-- show supplier contact list -->
  <div class="showContactList" *ngIf="showContactList == true && isEditContact==false">

    <div class="row clearfix">
      <app-contact-list [cardID]="selectedSupplierID" [type]="'supplier'" [selectSub]="true" [title]="contactListTitle"
                        (openContactEdit)="openContactEditEvent($event)">
      </app-contact-list>
    </div>
    <div class="row clearfix text-center mt">
      <button mat-raised-button color="accent" (click)="  hideViewDetails() ">
        <mat-icon>chevron_left</mat-icon> Suppliers List
      </button>
    </div>

  </div>

  <div class="showContactCreate" *ngIf="showContactCreate == true ">
    <div class="row clearfix">
      <app-contact-create-edit [cardID]="selectedSupplierID" [title]="contactCreateTitle"
                               [possibleTypes]="possibleContactTypes">
      </app-contact-create-edit>
    </div>
    <div class="row clearfix text-center mt">
      <button mat-raised-button color="accent" (click)="hideViewDetails()">
        <mat-icon>chevron_left</mat-icon> Suppliers List
      </button>
    </div>
  </div>

  <div class="showContactEdit" *ngIf="isEditContact==true && selectedContactID">
    <div class="row clearfix">
      <app-contact-create-edit [contactID]="selectedContactID" [title]="contactListTitle">
      </app-contact-create-edit>
    </div>
    <div class="row clearfix text-center mt">
      <button mat-raised-button color="accent" (click)="hideContactEdit()">
        <mat-icon>chevron_left</mat-icon> Patient Detail
      </button>
    </div>
  </div>

  <ng-template #actions let-record>
    <button mat-menu-item (click)="openQuickViewDialog(record['ID'])">
      <mat-icon>description</mat-icon>
      <span>{{"QuickView" | translate}}</span>
    </button>

    <button *ipvIsPromoterOrAdmin mat-menu-item (click)="editSupplier(record['ID'], record['ParentOrganisation_key'])">
      <mat-icon>edit</mat-icon>
      <span>{{"Edit Supplier" | translate}}</span>
    </button>



    <button *ipvIsPromoterOrAdmin mat-menu-item (click)="addDocument(record['ID'])">
      <mat-icon>add_circle</mat-icon>
      <span>{{"Add Document" | translate}}</span>
    </button>



    <button mat-menu-item (click)="viewDocuments(record['ID'], record['Label'] )">
      <mat-icon>book</mat-icon>
      <span>{{"View  Documents" | translate}}</span>
    </button>


    <button mat-menu-item (click)="viewProducts(record['ID'], record['Label'] )">
      <mat-icon class="fas fa-box-open"></mat-icon>
      <span>{{"View  Products" | translate}}</span>
    </button>


    <button *ipvIsPromoterOrAdmin mat-menu-item (click)="createBankAccounts(record['ID'] , record['Label'])">
      <mat-icon>add</mat-icon>
      <span>{{"New Bank Account" | translate}}</span>
    </button>

    <button *ipvIsPromoterOrAdmin mat-menu-item (click)="viewBankAccounts(record['ID'] , record['Label'])">
      <mat-icon>account_balance</mat-icon>
      <span>{{"View Bank Accounts" | translate}}</span>
    </button>

    <button mat-menu-item (click)="contact(record['ID'])">
      <mat-icon>message</mat-icon>
      <span>{{"Contact Supplier" | translate}}</span>
    </button>

    <button mat-menu-item
            *ipvIsPromoterOrAdmin
            (click)="createNewContact(record['ID'],record['TradingAs'],record['PossibleContactTypes'])">
      <mat-icon>person_add</mat-icon>
      <span>{{"Create New Contact" | translate}}</span>
    </button>

    <button mat-menu-item (click)="viewContactsList(record['ID'],record['TradingAs'])">
      <mat-icon>view_list</mat-icon>
      <span>{{"View Contacts" | translate}}</span>
    </button>

    <ng-container *ipvIsPromoterOrAdmin>
      <button *ngIf="record['Active']==='1'" mat-menu-item
              (click)="disable(record['ID'], viewModel.beneficiaries)">
        <mat-icon>cancel</mat-icon>
        <span>{{"Disable" | translate}}</span>
      </button>

      <button *ngIf="record['Active']==='0'" mat-menu-item (click)="enable(record['ID'], viewModel.beneficiaries)">
        <mat-icon>check_circle</mat-icon>
        <span>{{"Enable" | translate}}</span>
      </button>
    </ng-container>

  </ng-template>
</ipv-page>

<ng-template #companyLogo let-record>
  <app-beneficiary-view-picture class="beneficiaryList" [beneficiaryID]="record['ID']" [LastModified]="record['LastModified']">
  </app-beneficiary-view-picture>
</ng-template>

<ng-template #companyType let-record>
<div matTooltip="{{record['Type_Label'] }}">
  <span *ngIf="record['Type_Code']=='BENE'">
    <mat-icon class="fas fa-parachute-box industry-type-icon"></mat-icon>
  </span>
  <span *ngIf="record['Type_Code']=='MANU'">
    <mat-icon class="fas fa-industry industry-type-icon"></mat-icon>
  </span>
  <span *ngIf="record['Type_Code']=='DISTR'">
    <mat-icon class="fas fa-truck industry-type-icon"></mat-icon>
  </span>
</div>
</ng-template>

<ng-template #isActiveIndicator let-record>
  <ipv-list-active-label [isActive]="record['Active'] | binaryBool"></ipv-list-active-label>
</ng-template>
