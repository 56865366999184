import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material';
import { ClipboardModule } from 'ngx-clipboard';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { NgPipesModule } from 'ngx-pipes';
import { AuthenticationModule } from '../../core/authentication/authentication.module';
import { SharedModule } from '../../shared/shared.module';
import { BeneficiaryModule } from '../beneficiary/beneficiary.module';
import { MarketingViewLogoComponent } from '../marketing/marketing-view-logo/marketing-view-logo.component';
import { MerchantModule } from '../merchant/merchant.module';
import { TreatmentModule } from '../treatment/treatment.module';
import { SocialMediaRoutingModule } from './shared/social-media-routing.module';
import { SocialMediaService } from './shared/social-media.service';
import { SocialMediaCreateEditComponent } from './social-media-create-edit/social-media-create-edit.component';
import { SocialMediaGalleryListComponent } from './social-media-gallery-list/social-media-gallery-list.component';
import { SocialMediaListComponent } from './social-media-list/social-media-list.component';
import { SocialMediaThumbnailViewComponent } from './social-media-thumbnail-view/social-media-thumbnail-view.component';
import { SocialMediaViewComponent } from './social-media-view/social-media-view.component';

@NgModule({
  imports: [
    CdkTableModule,
    MatSortModule,
    NgPipesModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    ClipboardModule,
    SocialMediaRoutingModule,
    BeneficiaryModule,
    TreatmentModule,
    AuthenticationModule,
    PinchZoomModule,
    MerchantModule,
  ],
  declarations: [
    SocialMediaViewComponent,
    SocialMediaListComponent,
    SocialMediaGalleryListComponent,
    MarketingViewLogoComponent,
    SocialMediaThumbnailViewComponent,
    SocialMediaCreateEditComponent,
  ],
  exports: [
    SocialMediaViewComponent,
    SocialMediaListComponent,
    SocialMediaGalleryListComponent,
    MarketingViewLogoComponent,
    SocialMediaThumbnailViewComponent,
    SocialMediaCreateEditComponent,
  ],
  providers: [SocialMediaService],
})
export class SocialMediaModule {}
