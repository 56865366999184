<div class="row clearfix text-center starRating">
  <a class="starList" *ngFor="let item of starList; let i = index" (click)="setStar(i)">
    <i class="fas fa-star animated" [ngClass]="{ unchk: item, tada: !item }"></i>
  </a>
</div>
<div class="row clearfix text-center">
  <p>
    {{ label }}: <strong>{{ rating }} stars</strong>
  </p>
</div>
