import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css'],
})
export class NotificationListComponent implements OnInit {
  notificationsEmpty;

  constructor(private location: Location) {}

  ngOnInit() {}

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }
}
