<div class="card-header primary-gradient-img clearfix inModal">
  <div class="row clearfix">
    <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModal()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="row titleArea clearfix hasIcon">
      <mat-icon class="white pull-left fas fa-file-medical"></mat-icon>
      <h2 class="summary-header rm-m">Add Supporting Document</h2>
    </div>
  </div>
</div>

<mat-dialog-content class="doc-create-edit">
  <form #createTreatmentDoc="ngForm">
    <div class="row clearfix mt">
      <div class="row clearfix flex">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Document Type</mat-label>
          <mat-select
            class="full-width"
            [(ngModel)]="group"
            (change)="changeGroup()"
            name="docGroup"
            placeholder="Document group"
            required
          >
            <mat-option *ngFor="let dg of documentGroups" [value]="dg.TypeCode">
              {{ dg.Label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="row clearfix">
        <mat-form-field class="full-width primary-color" appearance="outline">
          <mat-label>Enter name of document</mat-label>
          <input
            class="mat-block"
            [(ngModel)]="label"
            name="documentName"
            placeholder="Enter name of document"
            matInput
            required
          />
        </mat-form-field>
      </div>

      <div class="row clearfix">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Description</mat-label>
          <textarea
            class="dentMsg increase-input-field"
            [(ngModel)]="description"
            name="dentMsg"
            matInput
            placeholder="Description"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="row clearfix">
        <mat-form-field class="full-width primary-color" appearance="outline">
          <mat-label>Enter some tags to define this document</mat-label>
          <input
            class="mat-block"
            [(ngModel)]="tags"
            name="documentTags"
            placeholder="Enter some tags to define this document"
            matInput
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row clearfix">
      <app-file-uploader
        class="text-center"
        [isDragable]="true"
        [limit]="1"
        (onCompleteAll)="onCompleteAllFile($event)"
        (isUploaded)="checkUploadCompleted($event)"
      ></app-file-uploader>

      <app-file-list
        [hasExtraInfo]="false"
        [files]="fileID"
        [canEdit]="true"
        [canAction]="false"
        (remove)="removeNewTreatmentDocument($event)"
      >
      </app-file-list>
    </div>

    <div class="button-row row text-center clearfix mt">
      <button
        class="text-center"
        [disabled]="!createTreatmentDoc.form.valid || (isUploadCompleted == false && type == 'Document')"
        (click)="createNewDoc()"
        mat-raised-button
        color="primary"
      >
        Save
      </button>
    </div>
  </form>
</mat-dialog-content>
