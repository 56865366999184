import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';

import { map as _map } from 'lodash';
import { EditorComponentEditModalComponent } from '../editor-component-edit-modal/editor-component-edit-modal.component';
import { EditorComponentViewComponent } from '../editor-component-view/editor-component-view.component';
import { EditorComponentService } from '../shared/editor-component.service';

@Component({
  selector: 'app-editor-component-lookup',
  templateUrl: './editor-component-lookup.component.html',
  styleUrls: ['./editor-component-lookup.component.css'],
})
export class EditorComponentLookupComponent implements OnInit {
  @Input()
  isActive = true;
  @Input()
  isDefault;

  @Input()
  selectDefault = true;
  @Input()
  typeCode;
  @Input()
  natureCode;
  defaultType;
  @Input()
  merchantID;

  @Input()
  viewOnClick = false;

  @Output()
  getResult = new EventEmitter();

  @Output()
  getComponentID = new EventEmitter();

  @Output()
  getComponent = new EventEmitter();

  filterData = '';
  selectedPatient: any;
  utilsClass = new UtilsClass();

  isPromoterOrAdmin = true;

  @Output()
  close = new EventEmitter();

  components = [];
  isModal = true;

  orderByFilter = 'label';
  selectAll;
  component;
  viewOnly = true;
  @Input()
  title = 'Select your Editor Component';

  @Input()
  description =
    "Below is a list of modals available. Select the one you wish to apply , or if you are feeling creative press the 'create your own' button.";
  serviceRef;
  isDestoyed = false;
  isLoaded = false;
  itemsPerPage = 20;

  utils = new UtilsClass();
  currentPage;
  currentPageDefault = 1;
  currentPageUser = 1;
  currentPageSM = 1;

  selectedResult;

  constructor(
    private authenticationService: AuthenticationService,
    private editorComponentService: EditorComponentService,
    private router: Router,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.merchantID) {
        this.merchantID = data.merchantID;
        this.isModal = true;
      }

      if (data.viewOnly != null) {
        this.viewOnly = data.viewOnly;
        this.isModal = true;
      }
      if (data.isActive != null) {
        this.isActive = data.isActive;
        this.isModal = true;
      }

      if (data.isDefault != null) {
        this.isDefault = data.isDefault;
        this.isModal = true;
      }

      if (data.selectDefault != null) {
        this.selectDefault = data.selectDefault;
        this.isModal = true;
      }

      if (data.natureCode != null) {
        this.natureCode = data.natureCode;
        this.isModal = true;
      }
      if (data.typeCode != null) {
        this.typeCode = data.typeCode;
        this.isModal = true;
      }

      if (data.viewOnClick != null) {
        this.viewOnClick = data.viewOnClick;
        this.isModal = true;
      }
      if (data.selectAll != null) {
        this.selectAll = data.selectAll;
        this.isModal = true;
      }

      if (data.isModal != null) {
        this.isModal = data.isModal;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      this.setup();
    });
  }

  isNew(date) {
    if (date) {
      const days = this.utils.daysPast(date);

      if (days < 15) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  setup() {
    this.defaultType = null;

    const payload = {
      orderBy: 'label',
      isActive: this.isActive,
      isDefault: this.isDefault,
      selectDefault: this.selectDefault,
      natureCode: this.natureCode,
      typeCode: this.typeCode,
      fields:
        'ID,DateTimeCreated,Merchant_key,Merchant_Trading_As,Label,Description,Is_Default,Type.Code,Type.Label,Nature.Code,Nature.Label,Is_Active',
      merchantID: this.merchantID,
      limit: 500,
      section: 1,
    };

    this.serviceRef = this.editorComponentService
      .getEditorComponentList(payload, this.isPromoterOrAdmin)
      .subscribe((res) => {
        this.isLoaded = true;
        if (res && res.length > 0) {
          res = _map(res, (item) => {
            if (item) {
              item.search = '';
              if (item.label) {
                item.search = item.search + ' ' + item.label;
              }

              if (item.description) {
                item.search = item.search + ' ' + item.description;
              }

              if (item.Merchant_Trading_As) {
                item.search = item.search + ' ' + item.Merchant_Trading_As;
              }
            }
            return item;
          });

          this.components = res;

          const userComponentsCount = this.components.filter((component) => component.type === 'user').length;

          if (userComponentsCount > 0) {
            this.defaultType = '0';
          } else {
            this.defaultType = '1';
          }

          const innerFunction = (section) => {
            section = section + 1;
            payload.section = section;

            this.serviceRef = this.editorComponentService
              .getEditorComponentList(payload, this.isPromoterOrAdmin)
              .subscribe((res) => {
                if (res.length > 0) {
                  res = _map(res, (item) => {
                    if (item) {
                      item.search = '';
                      if (item.label) {
                        item.search = item.search + ' ' + item.label;
                      }

                      if (item.description) {
                        item.search = item.search + ' ' + item.description;
                      }

                      if (item.Merchant_Trading_As) {
                        item.search = item.search + ' ' + item.Merchant_Trading_As;
                      }
                    }
                    return item;
                  });

                  this.components = this.components.concat(res);

                  if (this.defaultType != '0') {
                    const _userComponentsCount = this.components.filter(
                      (component) => component.Is_Default === '0'
                    ).length;

                    if (_userComponentsCount > 0) {
                      this.defaultType = '0';
                    } else {
                      this.defaultType = '1';
                    }
                  }

                  if (this.isDestoyed != true) {
                    innerFunction(section);
                  }
                } else {
                  return true;
                }
              });
          };

          if (this.isDestoyed != true) {
            innerFunction(1);
          }
        }
      });
  }

  selectComponent(component) {
    if (component && component.ID) {
      if (this.viewOnClick == true) {
        this.component = component;

        const ref = RootAppComponent.dialog.open(EditorComponentViewComponent, {
          data: {
            editorComponentID: component.ID,
          },
          width: '1000px',
        });
        ref.componentInstance.close.subscribe((res) => {
          ref.close();
        });
      } else {
        this.component = component;

        const ref = RootAppComponent.dialog.open(EditorComponentEditModalComponent, {
          data: {
            editorComponentID: component.ID,
          },
          width: '80%',
        });
        ref.componentInstance.close.subscribe((res) => {
          ref.close();
        });

        ref.componentInstance.getResult.subscribe((res) => {
          if (res) {
            this.selectedResult = res;
            ref.close();
            ref.afterClosed().subscribe((r) => {
              this.sendResult();
            });
          }
        });
      }
    }
  }

  ngOnDestroy() {
    this.isDestoyed = true;
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }

  sendResult() {
    if (this.selectedResult) {
      this.getResult.emit(this.selectedResult);
    }
  }

  backToOne() {
    this.currentPage = 1;
    this.currentPageDefault = 1;
    this.currentPageSM = 1;
    this.currentPageUser = 1;
  }

  changePage(e) {
    if (e) {
      if (this.defaultType == '0') {
        this.currentPageUser = e;
        this.currentPage = e;
      } else if (this.defaultType == '1') {
        this.currentPageDefault = e;
        this.currentPage = e;
      } else {
        this.currentPageSM = e;
        this.currentPage = e;
      }
    }
  }

  changeTab() {
    if (this.defaultType == '0') {
      this.currentPage = this.currentPageUser;
    } else if (this.defaultType == '1') {
      this.currentPage = this.currentPageDefault;
    } else {
      this.currentPage = this.currentPageSM;
    }
  }

  viewComponent(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(EditorComponentViewComponent, {
        data: {
          editorComponentID: id,
        },
        width: '1000px',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  orderBy(d) {
    if (d) {
      let isUpOrder = true;

      if (d && d[0] == '-') {
        isUpOrder = false;
      }

      if (this.orderByFilter === d) {
        if (isUpOrder == true) {
          this.orderByFilter = '-' + d;
        } else {
          this.orderByFilter = d.replace('-', '');
        }
      } else {
        this.orderByFilter = d;
      }
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  gotoNewComponent() {
    const confirm = new ConfirmDialog(
      'fas fa-info',

      'Do you want to continue?',
      'You are about to leave this page.',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
      width: '650px',
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        this.router.navigate(['/merchant', { outlets: { page: ['editor-component-create-redirect'] } }]);
        ref.close();
        this.close.emit(true);
      } else {
        ref.close();
      }
    });
  }
}
