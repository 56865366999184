import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { environment } from '../../../../../../src/environments/environment';
import { AppStateService } from '../../../../core/app-state/app-state.service';
import { UtilsService } from '../../../../shared/services/utils.service';
import { DataResponse } from '../../../../shared/types/data-response.type';
import { QueryParameters } from '../../../../shared/types/query-parameters.type';
import { CustomerAndProspect } from '../../../invitation/shared/types/invitation.type';
import { ProductCatalogueItem } from './product-catalogue.service';
import { ProductCustomer } from './product-customer.service';

class ProductInvoiceState {
  productInvoices: Partial<ProductInvoice>[] | ProductInvoice[];
  invoiceLabelFilter?: string;
  customerCalculatedNameFilter?: string;
  customerEmailFilter?: string;
  productLabelFilter?: string;
}

export class ProductInvoice {
  readonly 'ID'?: string;
  readonly 'DateTimeCreated'?: string;
  readonly 'LastModified'?: string;
  readonly 'LastModified_Human'?: string;
  'Invoice_Number': string;
  'Owner_Card_key': string;
  'Contract_key'?: string;
  'Merchant_key': string;
  'Amount_Borrowed': number;
  'Notes': string;
  'Invoice_Date': string;
  'Invoice_Total': number;
  'Invoice.Type.Code': string;
  'Invoice.Type.Label': string;
  'Invoice.Status.Code': string;
  'Invoice.Status.Label': string;
  'CustomerCalculatedName'?: string;
  'CustomerEmail'?: string;
  'ProductLabel'?: string;
  'CustomerNumber'?: string;
  productCustomer: Partial<ProductCustomer> | null;
  customer?: Partial<CustomerAndProspect> | null;
  product?: Partial<ProductCatalogueItem> | null;
}

export class ProductInvoiceQuery {
  payload: Partial<ProductInvoice> &
    QueryParameters & {
      invoiceFilter?: string;
      calculatedNameFilter?: string;
      emailFilter?: string;
      productLabelFilter?: string;
    };
}

@Injectable()
export class ProductInvoiceService extends ComponentStore<ProductInvoiceState> {
  private baseUrl = environment.nodeUrl;

  constructor(
    private utilsService: UtilsService,
    private httpClient: HttpClient,
    private appStateService: AppStateService
  ) {
    super({
      productInvoices: [],
      invoiceLabelFilter: '',
      customerCalculatedNameFilter: '',
      customerEmailFilter: '',
      productLabelFilter: '',
    });
  }

  getProductInvoices<T extends ProductInvoice | Partial<ProductInvoice>>(): T[] {
    return this.get().productInvoices as T[];
  }

  getProductInvoices$<T extends ProductInvoice | Partial<ProductInvoice>>(): Observable<T[]> {
    return this.select((state) => state.productInvoices) as Observable<T[]>;
  }

  setProductInvoices(productInvoices: ProductInvoice[] | Partial<ProductInvoice>[]): void {
    this.patchState({ productInvoices });
  }

  fetchProductInvoices<T extends ProductInvoice | Partial<ProductInvoice>>(
    payload?: ProductInvoiceQuery['payload']
  ): Observable<T[]> {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const params = this.utilsService.getHttpParamsFromPayload(payload);

        const options = { params };

        const endpoint = appState.isPromoterOrAdmin ? 'product-invoices/global' : 'product-invoices';

        const url = `${this.baseUrl}/${endpoint}`;

        return this.httpClient.get<DataResponse<T[]>>(url, options).pipe(map((response) => response['data']));
      })
    );
  }

  createProductInvoice$<T extends ProductInvoice | Partial<ProductInvoice>>(
    payload?: Partial<ProductInvoice>
  ): Observable<T> {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const endpoint = appState.isPromoterOrAdmin ? 'product-invoices/global' : 'product-invoices';

        const url = `${this.baseUrl}/${endpoint}`;

        return this.httpClient.post<DataResponse<T>>(url, payload).pipe(map((response) => response.data));
      })
    );
  }

  updateProductInvoice$<T extends ProductInvoice | Partial<ProductInvoice>>(
    item: ProductInvoice | Partial<ProductInvoice>,
    payload?: Partial<ProductInvoice>
  ): Observable<T> {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const endpoint = appState.isPromoterOrAdmin
          ? `product-invoices/global/${item.ID}`
          : `product-invoices/${item.ID}`;

        const url = `${this.baseUrl}/${endpoint}`;

        return this.httpClient.put<DataResponse<T>>(url, payload).pipe(map((response) => response.data));
      })
    );
  }

  deleteProductInvoice$(item: ProductInvoice | Partial<ProductInvoice>): Observable<string> {
    return this.appStateService.getAppState$().pipe(
      take(1),
      switchMap((appState) => {
        const endpoint = appState.isPromoterOrAdmin
          ? `product-invoices/global/${item.ID}`
          : `product-invoices/${item.ID}`;

        const url = `${this.baseUrl}/${endpoint}`;

        return this.httpClient.delete<DataResponse<Partial<string>>>(url).pipe(map((response) => response.data));
      })
    );
  }

  addProductInvoice$(itemWithID: ProductInvoice | Partial<ProductInvoice>) {
    const currentItems = this.get().productInvoices;
    const items = [...currentItems, itemWithID];
    this.patchState({ productInvoices: items });
    return of(itemWithID);
  }

  editProductInvoice$(itemName: ProductInvoice | Partial<ProductInvoice>) {
    const items = this.get().productInvoices;
    const targetIndex = items.findIndex((item) => itemName.ID === item.ID);
    items[targetIndex] = itemName;
    this.patchState({ productInvoices: items });
    return of(itemName);
  }

  removeProductInvoice$(itemName: ProductInvoice | Partial<ProductInvoice>): Observable<null> {
    // Remove item from local list
    const currentItems = this.get().productInvoices;
    const items = currentItems.filter((item) => itemName.ID !== item.ID);

    this.patchState({ productInvoices: items });

    return of(null);
  }

  getInvoiceLabelFilter$() {
    return this.select((state) => state.invoiceLabelFilter);
  }

  setInvoiceLabelFilter(invoiceLabelFilter: string): void {
    this.patchState({ invoiceLabelFilter });
  }

  getCustomerCalculatedNameFilter$() {
    return this.select((state) => state.customerCalculatedNameFilter);
  }

  setCustomerCalculatedNameFilter(customerCalculatedNameFilter: string): void {
    this.patchState({ customerCalculatedNameFilter });
  }

  getCustomerEmailFilter$() {
    return this.select((state) => state.customerEmailFilter);
  }

  setCustomerEmailFilter(customerEmailFilter: string): void {
    this.patchState({ customerEmailFilter });
  }

  getCustomerProductLabelFilter$() {
    return this.select((state) => state.productLabelFilter);
  }

  setCustomerProductLabelFilter(productLabelFilter: string): void {
    this.patchState({ productLabelFilter });
  }
}
