<div class="rel dentist-card" *ngIf="merchant" @onChangeAnimation>
  <div class="animate">
    <mat-dialog-content class="DentistMapProfile">
      <div class="card-header primary-gradient-img clearfix stacked">
        <button class="btn-close btn-clear mat-button" (click)="closeEvent()">
          <mat-icon>clear</mat-icon>
        </button>

        <div class="row clearfix">
          <div class="row titleArea clearfix text-left">
            <h2 class="summary-header rm-m">
              {{ merchant['TradingAs'] }}
              <span class="subLabel" *ngIf="merchant['addresses.Calculated']">{{
                merchant['addresses.Calculated']
                }}</span>
            </h2>
          </div>
        </div>
      </div>

      <div class="row clearfix">
        <app-map-view
          class=""
          *ngIf="addressLabel && displayMapFlag == true"
          [icon]="iconAddres"
          [forceGettingAddress]="true"
          [canClose]="false"
          [unitNumber]="unitNumber"
          [canClick]="false"
          [streetType]="streetType"
          [streetName]="streetName"
          [latitude]="merchant['Address.Latitude']"
          [longitude]="longitude"
          [streetNumber]="streetNumber"
          [zoom]="17"
          [postCode]="postCode"
          [state]="state"
          [suburb]="suburb"
          [moreInfo]=""
          [showDetails]="false"
          (notFoundEvent)="notFoundEvent($event)"
        ></app-map-view>
      </div>
    </mat-dialog-content>
  </div>
</div>
