<mat-dialog-content>
  <div class="rel" *ngIf="loadIFrame != true" @ngIfAnimation>
    <div class="row clearfix padded rel">
      <h1 class="dataHeader rm-mt transparent">Loading appointment Iframe...</h1>
    </div>
  </div>

  <iframe *ngIf="iFrameSrcSecured" [src]="iFrameSrcSecured" (load)="onFrameLoad($event)"></iframe>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close color="accent">Close</button>
</mat-dialog-actions>
