import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerCreateComponent } from '../customer-create/customer-create.component';
import { CustomerEditComponent } from '../customer-edit/customer-edit.component';
import { CustomerListIntroModalComponent } from '../customer-list-intro-modal/customer-list-intro-modal.component';
import { CustomerListViewComponent } from '../customer-list-view/customer-list-view.component';
import { CustomerListComponent } from '../customer-list/customer-list.component';
import { CustomerViewComponent } from '../customer-view/customer-view.component';

const routes: Routes = [
  {
    path: 'customer-list',
    component: CustomerListComponent,
  },
  {
    path: 'customer-view',
    component: CustomerViewComponent,
  },
  {
    path: 'customer-edit',
    component: CustomerEditComponent,
  },
  {
    path: 'customer-create',
    component: CustomerCreateComponent,
  },
  {
    path: 'customer-list-view',
    component: CustomerListViewComponent,
  },
  {
    path: 'customer-list-intro-modal',
    component: CustomerListIntroModalComponent,
  },
  {
    path: 'customer-create',
    component: CustomerCreateComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomerRoutingModule {}
