var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import * as JSZip from 'jszip';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { PracticeAppointmentCancellationPolicyComponent } from '../../../core/session/practice-appointment-cancellation-policy/practice-appointment-cancellation-policy.component';
import { ConfirmDialogSingleButtonComponent } from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { ImageViewListComponent } from '../../../shared/components/image-view-list/image-view-list.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomCurrencyPipe } from '../../../shared/pipes/custom-currency.pipe';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AppointmentAvailabilityComponent, } from '../appointment-availability/appointment-availability.component';
import { AppointmentIframeViewComponent, } from '../appointment-iframe-view/appointment-iframe-view.component';
import { AppointmentPageCustomizeComponent, } from '../appointment-page-customize/appointment-page-customize.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { MerchantService } from '../../merchant/shared/merchant.service';
export class AppointmentLandingCreateComponent {
    constructor(authenticationService, _sanitizer, dialog, customCurrencyPipe, customPhonePipe, merchantService, changeDetectorRef, router) {
        this.authenticationService = authenticationService;
        this._sanitizer = _sanitizer;
        this.dialog = dialog;
        this.customCurrencyPipe = customCurrencyPipe;
        this.customPhonePipe = customPhonePipe;
        this.merchantService = merchantService;
        this.changeDetectorRef = changeDetectorRef;
        this.router = router;
        this.settings = Settings.global;
        this.title = 'Request an Appointment';
        this.description = 'Let your dentist know what time might suit you best for an appointment. The  practice will then contact you to arrange a suitable appointment date.';
        this.backgroundCode = 'default';
        this.downloadQRCode = new EventEmitter();
        this.displayStaffAppointment = false;
        this.displayAppointmentLookup = false;
        this.displayAppointmentLookupSorting = false;
        this.showContact = true;
        this.showHeader = true;
        this.backgroundColor = '#1b8bdd';
        this.width = 650;
        this.height = 800;
        this.contactColor = '#ffffff';
        this.contactBackgroundColor = '#1b8bdd';
        this.displayAdmin = false;
        this.useThirdPartyBookingURL = false;
        this.thirdPartyBookingURL = '';
        this.loadIFrame = false;
        this.utils = new UtilsClass();
        this.isAppointmentDisabled = true;
        this.isFirstLoad = false;
    }
    ngOnInit() {
        this.authenticationService.getCurrentUser().subscribe((res) => {
            if (res && res.data && res.data.merchantKey) {
                AuthenticationService.updateDrift.emit('none');
                this.merchantID = res.data.merchantKey;
            }
        });
        this.authenticationService.getCurrentPractice().subscribe((res) => {
            if (res) {
                this.updateCurrentPractice(res);
            }
        });
    }
    previewAdvanceIntegration() {
        const dialogData = {
            iFrameSrc: this.getPreviewLinkFromCurrentPractice(this.currentPractice),
        };
        const ref = RootAppComponent.dialog.open(AppointmentIframeViewComponent, {
            panelClass: 'noCard',
            data: dialogData,
            width: this.width + 'px',
            height: this.height + 'px',
        });
    }
    getAppointmentCancellingPolicy(currentPractice) {
        if (currentPractice && currentPractice['Appointment_Cancelling_Policy']) {
            let appointmentCancellingPolicy = currentPractice['Appointment_Cancelling_Policy'];
            // {{PracticeName}}
            appointmentCancellingPolicy = appointmentCancellingPolicy
                .split('{{PracticeName}}')
                .join(currentPractice['TradingAs'] || '');
            // {{Fee}}
            const fee = currentPractice['Appointment_Cancelling_Fee'] || 55;
            appointmentCancellingPolicy = appointmentCancellingPolicy
                .split('{{Fee}}')
                .join(this.customCurrencyPipe.transform(fee));
            // {{Hours}}
            appointmentCancellingPolicy = appointmentCancellingPolicy
                .split('{{Hours}}')
                .join(currentPractice['Appointment_Cancelling_Hours'] || 24);
            // {{Description}}
            appointmentCancellingPolicy = appointmentCancellingPolicy
                .split('{{Description}}')
                .join(currentPractice['Description'] || '');
            // {{PracticeEmail}}
            appointmentCancellingPolicy = appointmentCancellingPolicy
                .split('{{PracticeEmail}}')
                .join(currentPractice['emails.Email'] || '');
            // {{PracticePhone}}
            const phone = this.customPhonePipe.transform(currentPractice['phones.Number']);
            appointmentCancellingPolicy = appointmentCancellingPolicy.split('{{PracticePhone}}').join(phone || '');
            return appointmentCancellingPolicy;
        }
        return '';
    }
    onCompleteAll(fileID) {
        if (fileID && fileID[0]) {
            this.backgroundFileID = fileID[0];
            this.backgroundImage = `${environment.nodeUrl}/files/file-view/${this.backgroundFileID}`;
        }
    }
    clickCopy() {
        NotifyAppComponent.displayToast('success', 'Code copied', '');
    }
    linkCopy() {
        NotifyAppComponent.displayToast('success', 'Link copied', '');
    }
    goToLink() {
        this.getLink();
        if (this.appointmentLandingPage) {
            window.open(this.appointmentLandingPage, '_blank');
        }
    }
    previewCancellationPolicy() {
        const confirm = new ConfirmDialogSingleButton('fas fa-info', 'Appointment Cancelling Policy', this.getAppointmentCancellingPolicy(this.currentPractice), 'okay');
        // Confirm Dialog Single Button
        const ref = this.dialog.open(ConfirmDialogSingleButtonComponent, {
            data: confirm,
            width: '650px',
        });
        ref.componentInstance.onConfirm.subscribe((res) => {
            ref.close();
        });
    }
    personaliseCancellationPolicy() {
        const ref = RootAppComponent.dialog.open(PracticeAppointmentCancellationPolicyComponent, {
            data: {},
            width: '750px',
        });
        ref.componentInstance.editedAppointmentCancellingPolicy.subscribe((res) => {
            if (res) {
                this.updateCurrentPractice(res);
            }
        });
        ref.componentInstance.closeModal.subscribe((res) => {
            if (res == true) {
                ref.close();
            }
        });
    }
    onSaveThirdPartyBookingURL(url) {
        const val = this.useThirdPartyBookingURL ? url : 'void';
        const payload = {
            thirdPartyBooking_URL: val,
            useThirdPartyBookingURL: this.useThirdPartyBookingURL,
        };
        if (val && val != 'void') {
            const confirmDialog = new ConfirmDialog('warning', 'Are you sure you want to continue ?', 'By using your own booking engine, you and your patients will no longer be able to use our Appointment feature', 'Cancel', 'Okay');
            const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
                data: confirmDialog,
            });
            ref.componentInstance.onConfirm.subscribe((val) => {
                if (val == true) {
                    ref.close();
                    ref.afterClosed().subscribe((res) => {
                        this.authenticationService.editCurrentPractice(payload).subscribe((res) => {
                            this.authenticationService.getCurrentUserForce().subscribe((res1) => {
                                // update currentPractice property
                                this.updateCurrentPractice(res);
                                NotifyAppComponent.displayToast('success', 'Successed', 'Your profile has been updated successfully');
                            });
                        });
                    });
                }
                else {
                    ref.close();
                }
            });
        }
        else {
            this.authenticationService.editCurrentPractice(payload).subscribe((res) => {
                this.authenticationService.getCurrentUserForce().subscribe((res1) => {
                    // update currentPractice property
                    this.updateCurrentPractice(res);
                    NotifyAppComponent.displayToast('success', 'Successed', 'Your profile has been updated successfully');
                });
            });
        }
    }
    personaliseLookAndFeel() {
        const dialogData = {
            currentPractice: this.currentPractice,
        };
        const ref = RootAppComponent.dialog.open(AppointmentPageCustomizeComponent, {
            data: dialogData,
            width: '900px',
        });
        ref.componentInstance.editedCurrentPractice.subscribe((currentPractice) => {
            if (currentPractice) {
                this.updateCurrentPractice(currentPractice);
            }
        });
        ref.componentInstance.closeModal.subscribe((res) => {
            if (res == true) {
                ref.close();
            }
        });
    }
    ngOnDestroy() {
        AuthenticationService.updateDrift.emit('merchant');
    }
    updatePreview(changeLink = true) {
        const link = this.getPreviewLinkFromCurrentPractice(this.currentPractice);
        this.queryParams = link;
        if (changeLink) {
            this.iframeSrc = this._sanitizer.bypassSecurityTrustResourceUrl(link);
            // this.loadIFrame = false;
        }
        if (this.iframeSrc) {
            if (this.appointmentLandingPage) {
                this.iframeCode = `<iframe \n style="border:none !important;"  \n width="${this.width}px" height="${this.height}px"     \n src="${this.appointmentLandingPage}"> \n </iframe>`;
            }
        }
    }
    previewToNewTab() {
        const link = this.getPreviewLinkFromCurrentPractice(this.currentPractice);
        window.open(link, '_blank');
    }
    personaliseAppointmentAvailability() {
        const dialogData = {
            merchantID: this.currentPractice['ID'],
        };
        const ref = RootAppComponent.dialog.open(AppointmentAvailabilityComponent, {
            data: dialogData,
            width: '500px',
        });
        ref.componentInstance.editedMerchant.subscribe((res) => {
            if (res) {
                this.updateCurrentPractice(res);
            }
        });
        ref.componentInstance.closeModal.subscribe((res) => {
            if (res == true) {
                ref.close();
            }
        });
    }
    staffAvailability() {
        this.displayStaffAppointment = true;
        this.displayAppointmentLookup = false;
        this.displayAppointmentLookupSorting = false;
    }
    appointmentLookup() {
        this.displayStaffAppointment = false;
        this.displayAppointmentLookup = true;
        this.displayAppointmentLookupSorting = false;
    }
    appointmentLookupSorting() {
        this.displayStaffAppointment = false;
        this.displayAppointmentLookup = false;
        this.displayAppointmentLookupSorting = true;
    }
    backToMain() {
        this.displayStaffAppointment = false;
        this.displayAppointmentLookup = false;
        this.displayAppointmentLookupSorting = false;
    }
    onTestBookingUrl() {
        if (this.thirdPartyBookingURL) {
            window.open(this.thirdPartyBookingURL, '_blank');
        }
    }
    updateCurrentPractice(currentPractice) {
        if (currentPractice) {
            this.currentPractice = currentPractice;
            this.thirdPartyBookingURL = currentPractice['ThirdPartyBooking_URL'] || '';
            this.useThirdPartyBookingURL = this.thirdPartyBookingURL !== '';
            const payload = { fields: 'Code' };
            if (!this.practiceLandingPageCode) {
                this.merchantService.practiceLandingPageOneByMerchant(currentPractice.ID, payload).subscribe((res) => {
                    if (res && res.Code) {
                        this.practiceLandingPageCode = res.Code;
                    }
                    this.getLink();
                    this.updatePreview();
                    this.checkBookingEngine();
                });
            }
            else {
                this.checkBookingEngine();
                this.updatePreview();
            }
        }
    }
    getPreviewLinkFromCurrentPractice(currentPractice) {
        let link = this.settings['publicSiteFrontendLink'];
        if (currentPractice && currentPractice['AppointmentPageURL']) {
            link = link + '/' + currentPractice['AppointmentPageURL'];
        }
        else {
            link = link + '/appointment/' + currentPractice['ID']; // same as `merchantID`
        }
        return link;
    }
    onFrameLoad(e) {
        if (!this.loadIFrame) {
            this.loadIFrame = true;
            this.changeDetectorRef.detectChanges();
        }
        else {
            this.loadIFrame = true;
        }
    }
    downloadButtons() {
        const imageLinks = [];
        for (let idx = 1; idx <= 46; idx++) {
            imageLinks.push(`/assets/images/appointment/buttons/${idx}.png`);
        }
        const ref = RootAppComponent.dialog.open(ImageViewListComponent, {
            data: {
                imageLinks,
                canDownload: true,
                canCheck: true,
                canMultipleDowload: true,
                multipleActionButtonLabel: 'Download',
            },
            width: '910px',
            panelClass: 'noCard',
        });
        ref.componentInstance.closeModal.subscribe((res) => {
            if (res == true) {
                ref.close();
            }
        });
        ref.componentInstance.sendSelectedImages.subscribe((res) => __awaiter(this, void 0, void 0, function* () {
            if (res.length > 0) {
                let imgData = yield Promise.all(res.map((it) => fetch(it)));
                imgData = yield Promise.all(imgData.map((it) => it.blob()));
                const zip = new JSZip();
                imgData.forEach((it, idx) => __awaiter(this, void 0, void 0, function* () {
                    const fileImg = new File([it], `${idx + 1}.png`);
                    zip.file(`${idx + 1}.png`, fileImg);
                }));
                zip.generateAsync({ type: 'blob' }).then(function (blob) {
                    saveAs(blob, 'download.zip');
                }, (err) => {
                    console.log(err);
                });
            }
        }));
    }
    updateCurrentPracticeCalendar(calResponse) {
        this.currentPractice.calendar = calResponse;
    }
    checkBookingEngine() {
        if (this.currentPractice && this.currentPractice['ThirdPartyBooking_URL']) {
            this.isAppointmentDisabled = true;
        }
        else {
            this.isAppointmentDisabled = false;
        }
        this.isFirstLoad = true;
        AuthenticationService.appoinmentAccess.emit(this.isAppointmentDisabled);
    }
    getLink() {
        let link;
        if (this.currentPractice && this.practiceLandingPageCode) {
            link = `${this.settings['publicSiteFrontendLink']}/appointment-request/${this.practiceLandingPageCode}`;
        }
        else if (this.currentPractice && this.currentPractice.ID) {
            link = `${this.settings['publicSiteFrontendLink']}/appointment-practice/${this.currentPractice.ID}`;
        }
        this.appointmentLandingPage = link;
    }
}
