import { Component, EventEmitter, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { Settings } from '../../../shared/types/settings';
import { CONTEXTS } from '../shared/CONTEXT';
import { TranslationService } from '../shared/services/translation.service';

@Component({
  selector: 'app-translation-management-export',
  templateUrl: './translation-management-export.component.html',
  styleUrls: ['./translation-management-export.component.css'],
})
export class TranslationManagementExportComponent implements OnInit {
  maxFileSize = Settings.global['fileMaxSize'];

  fileID;
  contexts = CONTEXTS;
  content;
  source = 'file';

  type = 'replace';
  apply = false;

  isResult = false;

  result;

  editorOptionsCss = {
    fontSize: 12,
    formatOnType: true,
    formatOnPaste: true,
    language: 'json',
    autoIndent: true,
    autoClosingBrackets: 'always',
    dragAndDrop: false,
    fixedOverflowWidgets: true,
    theme: 'vs-dark',
    automaticLayout: true,
  };

  close = new EventEmitter();
  getResult = new EventEmitter();

  constructor(
    private translationService: TranslationService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {}

  ngOnInit() {}
  onCompleFile(f) {
    if (f && f[0]) {
      this.fileID = f[0];
    }
  }

  submit() {
    const payload = {
      apply: this.apply,
      data: null,
      fileID: null,
      operation: this.type,
      updateDefault: false,
    };

    if (this.source === 'file') {
      payload.fileID = this.fileID;
    } else {
      payload.data = this.content;
    }

    this.translationService.importTranslation(payload).subscribe((r) => {
      if (r) {
        this.isResult = true;
        this.result = r;
      }
    });
  }

  back() {
    this.isResult = false;
  }
  closeEvent() {
    this.close.emit(true);
  }

  isODD(i) {
    if (i % 2 == 1) {
      return true;
    }

    return false;
  }
}
