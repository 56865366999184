import { Component, OnInit, Inject, EventEmitter } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Settings } from "../../../shared/types/settings";
import { AuthenticationService } from "../../authentication/shared/authentication.service";

@Component({
  selector: "app-info-modal",
  templateUrl: "./info-modal.component.html",
  styleUrls: ["./info-modal.component.css"]
})
export class InfoModalComponent implements OnInit {
  closeModal = new EventEmitter();
  promoter: any;

  title = "Sorry!";

  description

  isModal = false;

  settings = Settings.global;

  image; 

  displayContact = false
  constructor(
    private authenticationService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if (data) {
      if (data.title) {
        this.isModal = true;
        this.title = data.title;
      }
      if (data.displayContact != null) {
        this.isModal = true;
        this.displayContact = data.displayContact;
      }


      if (data.description) {
        this.isModal = true;
        this.description = data.description;
      }
      else if (data.content) {
        this.isModal = true;
        this.description = data.content;
      }

      if (data.image) {
        this.isModal = true;
        this.image = data.image;
      }


      



    }

  }

  ngOnInit() {
    this.promoter = JSON.parse(localStorage.getItem("promoter"));

    if (!this.promoter) {
      this.authenticationService.getPromoter().subscribe(res => {
        if (res && res.data) {
          this.promoter = res.data
        } else if (res) {
          this.promoter = res;
        }

      });
    }

  }
  close() {
    this.closeModal.emit(true);
  }


  contactUs() {

    AuthenticationService.contactUSEvent.emit();


  }


}
