import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { environment } from 'environments/environment';
import { ClipboardService } from 'ngx-clipboard';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { BlobViewComponent } from '../../../shared/components/blob-view/blob-view.component';
import { IframeViewComponent } from '../../../shared/components/iframe-view/iframe-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { LandingPageEmbedComponent } from '../../merchant/landing-page-embed/landing-page-embed.component';
import { MerchantService } from '../shared/merchant.service';

@Component({
  selector: 'landing-page-share',
  templateUrl: './landing-page-share.component.html',
  styleUrls: ['./landing-page-share.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class LandingPageShareComponent implements OnInit {
  @Output()
  close = new EventEmitter();
  sessionType;
  isPromoterOrAdmin = false;
  merchantID;

  isDisabled = false;

  landingPageContactID;

  merchant;

  utils = new UtilsClass();

  iframeLink;

  downloadQRCodeDental = new EventEmitter();

  env = environment.nodeUrl;
  urlQR;
  type;

  theme = {
    code: 'blue',
    background: 'linear-gradient(135deg, #0092eb 0%, #0dbad3 100%)',
    color: '#1b8bdd',
    color2: '#0dbad3',
  };

  // Dynamic content

  title;

  prelinkPublicSite;
  prelinkBackend;
  prelinkSocialMedia;
  socialMediaTitle;
  socialMediaImage;
  socialMediaPost;

  keyWord;

  settings = Settings.global;

  pdfLabel;
  qrCodeTitle;

  constructor(
    private utilService: UtilsService,
    private merchantService: MerchantService,
    private _clipboardService: ClipboardService,
    private authenticationService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      if (data && data.type) {
        this.type = data.type;
      }
    }
  }

  ngOnInit() {
    if (Settings.global['context'] === 'p4p') {
      this.theme = {
        code: 'ee350b',
        background: ' linear-gradient(-135deg, #1caaa0 -10.31%, #7462ef  97.05%)',
        color: '#7462ef',
        color2: '#1caaa0',
      };
    }

    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
        this.isPromoterOrAdmin = isPromoterOrAdmin;

        this.authenticationService.getCurrentPractice().subscribe((r) => {
          if (r && r.ID) {
            this.merchantID = r.ID;
            this.merchant = r;

            this.setup();
          }
        });
      });
    });
  }

  setup() {
    if (this.type === 'goinggreen') {
      this.title = 'Share Going Green landing page';
      this.qrCodeTitle='Going Green landing page'
      this.socialMediaPost =
        'In the past, we printed our treatment plans on paper and would hand them to you after seeing the dentist – but now we are digital!';
      this.socialMediaTitle = 'WE ARE GOING GREEN! ';

      this.socialMediaImage =
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/13675815_0103spring9.jpg';

      this.keyWord = 'going-green';
      this.pdfLabel = `Go Green Landing Page -${this.merchant.TradingAs}`;

      this.theme = {
        code: 'ohhappiness',
        background: ' linear-gradient(-135deg, #00b09b -10.31%, #96c93d  97.05%)',
        color: '#00b09b',
        color2: '#57cc99',
      };
    } else if (this.type === 'selfService') {
      this.title = 'Share Self Service landing page';

      this.qrCodeTitle='Self Service landing page'
      this.socialMediaPost =
        'From a simple filling to a full smile makeover, we now have payment options to suit any treatment or budget.';
      this.socialMediaTitle =
        'So, you have seen your dentist, received a treatment plan, and now need time to pay. No problems. Smile Right is here to help.';

      this.socialMediaImage =
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/pay-online.jpg';

      this.keyWord = 'self-service';

      this.pdfLabel = `Self Service landing Page -${this.merchant.TradingAs}`;

      if (this.merchant && this.merchant.selfServiceTheme) {
        this.theme = this.merchant.selfServiceTheme;
      }
    } else if (this.type === 'offers') {
      this.title = 'Share Dental Tips and Offers landing page';

      this.qrCodeTitle='Dental Tips and Offers landing page'
      this.socialMediaPost =
        'Explore all things dental via a wide range of articles, fact sheets and videos that we have assembled.';
      this.socialMediaTitle = 'Your trusted source for better oral health';

      this.socialMediaImage =
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dentist-with-smile+(2).jpg';

      this.keyWord = 'tips-offers';

      this.pdfLabel = `Dental Tips and Offers landing Page -${this.merchant.TradingAs}`;
    }

    this.prelinkPublicSite = `${this.settings['publicSiteFrontendLink']}/${this.keyWord}/${this.merchantID}`;
    this.prelinkBackend = `${environment.nodeUrl}/r/${this.keyWord}/${this.merchantID}`;
    this.prelinkSocialMedia = `${environment.nodeUrl}/r/redirect/${this.keyWord}/${this.merchantID}`;

    this.utilService.createShortcut(this.getBackendLink('qr')).subscribe((r) => {
      if (r && r.shortLink) {
        this.urlQR = r.shortLink;
      }
    });
  }

  getBackendLink(t) {
    if (t && this.prelinkBackend) {
      return this.prelinkBackend + `/${t}?t=${t}`;
    }
  }
  getSocialMediaLink(t) {
    if (t && this.prelinkSocialMedia) {
      return this.prelinkSocialMedia + `/${t}?t=${t}`;
    }
  }

  buildURL() {
    const urlQR = `${Settings.global['publicSiteFrontendLink']}/landing-page-overview/${'this._ID'}/qr`;

    this.utilService.createShortcut(urlQR).subscribe((r) => {
      if (r && r.shortLink) {
        this.urlQR = r.shortLink;
      }
    });
  }
  closeEvent() {
    this.close.emit(true);
  }
  copy(t) {
    if (t) {
      this._clipboardService.copyFromContent(this.getBackendLink(t));
      NotifyAppComponent.displayToast('success', 'Copied!', '');
    }
  }

  viewEmbed() {
    const ref2 = RootAppComponent.dialog.open(LandingPageEmbedComponent, {
      data: {
        type: this.type,
      },
      width: '600px',
      panelClass: 'noCard',
    });

    ref2.componentInstance.close.subscribe((res) => {
      ref2.close();
    });
  }

  ViewPDF() {
    if (this.merchantID && this.keyWord) {
      this.merchantService.getLandingPagePDFBlob(this.merchantID, this.keyWord).subscribe((content) => {
        const ref = RootAppComponent.dialog.open(BlobViewComponent, {
          data: {
            content,
            label: this.pdfLabel,
            title: null,
            description: null,
            isProceed: false,
            isPrint: false,
            downloadPullRight: true,
            noTitleMode: true,
          },
          width: '800px',
        });

        ref.componentInstance.closeModal.subscribe((res) => {
          if (res == true) {
            ref.close();
          }
        });
      });
    }
  }
  openIframe() {
    if (this.prelinkPublicSite) {
      const ref2 = RootAppComponent.dialog.open(IframeViewComponent, {
        data: {
          link: this.prelinkPublicSite,
          isSubmit: false,
        },
        width: '80%',
        height: '92vh',
        panelClass: 'noCard',
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });

      ref2.componentInstance.getSubmit.subscribe((res) => {
        if (res) {
          ref2.close();
        }
      });
    }
  }
}
