import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-merchant-invite',
  templateUrl: './merchant-invite.component.html',
  styleUrls: ['./merchant-invite.component.css'],
})
export class MerchantInviteComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  finishFunction() {}
}
