import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import {
  CalendarEvent,
  CalendarType,
} from '../../../shared/components/calendar-event-share/calendar-event-share.component';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { AppointmentService } from '../shared/appointment.service';
import { AppointmentUtilClass } from '../shared/appointmentUtil';

/**
 * This Component is for consumer or guest only
 */
@Component({
  selector: 'app-appointment-sync-calendar-card',
  templateUrl: './appointment-sync-calendar-card.component.html',
  styleUrls: ['./appointment-sync-calendar-card.component.css'],
})
export class AppointmentSyncCalendarCardComponent implements OnInit, OnChanges {
  @Input()
  appointment;

  sessionType = 'guest';
  appointmentUtil = new AppointmentUtilClass();
  calEvent: CalendarEvent;

  @Output()
  success = new EventEmitter();

  constructor(
    private authenticationService: AuthenticationService,
    private appointmentService: AppointmentService,
    private customDate: CustomDatePipe
  ) {}

  async ngOnInit() {
    const res = await this.authenticationService.getSessionType().toPromise();
    if (res) {
      this.sessionType = res;
    }

    this.calEvent = this.appointmentUtil.buildCalendarEvent(this.appointment);
  }

  ngOnChanges() {
    this.calEvent = this.appointmentUtil.buildCalendarEvent(this.appointment);
  }

  displaySync() {
    return (
      this.appointment &&
      moment(this.customDate.transform(this.appointment.Date, 'YYYY-MM-DD'), 'YYYY-MM-DD').toDate() > new Date() &&
      (this.appointment['Status.Code'] === 'WAITINGCONFIRMATION' ||
        this.appointment['Status.Code'] === 'BOOKED' ||
        this.appointment['Status.Code'] === 'REQUESTRESCHEDULE' ||
        this.appointment['Status.Code'] === 'REQUESTDATECHANGE')
    );
  }

  calendarSynced(data: { id: string; calendar: string }) {
    if (data.id) {
      const payload: any = {};
      const key = `${data.calendar}CalendarConsumerKey`;

      payload[key] = data.id;

      this.appointmentService.editAppointment(this.appointment.ID, payload, this.sessionType).subscribe((res) => {
        this.success.emit(res);
        let msg = 'Your appointment is now synced to your calendar of choosing';
        if (data.calendar === CalendarType.apple) {
          msg = 'Please execute the downloaded ics file to your apple device';
        }
        NotifyAppComponent.displayToast('success', 'Calendar Sync', msg);
      });
    } else {
      NotifyAppComponent.displayToast(
        'error',
        'Calendar Sync',
        'Your appointment is not synced to your calendar of choosing'
      );
    }
  }
}
