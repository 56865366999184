<div class="ba-area mt full-width clearfix row">
  <span class="title" *ngIf="title">{{ title }}</span>
  <form #bsbForm="ngForm">
    <div class="row clearfix full-width">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Account Name</mat-label>
        <input
          [(ngModel)]="accountName"
          [required]="requiredLabel"
          (ngModelChange)="sendResult()"
          autocomplete="off"
          matInput
          placeholder=""
          name="accountName"
        />
      </mat-form-field>
    </div>
    <div class="row clearfix flex full-width">
      <mat-form-field class="half-width" appearance="outline">
        <mat-label>Account Number</mat-label>
        <input
          [(ngModel)]="accountNumber"
          [required]="requiredLabel"
          (ngModelChange)="sendResult()"
          autocomplete="off"
          matInput
          placeholder=""
          name="accountNumber"
        />
      </mat-form-field>

      <mat-form-field class="half-width" appearance="outline">
        <mat-label>Account type</mat-label>
        <mat-select class="" [(ngModel)]="accountType" (ngModelChange)="sendResult()" name="accountType" placeholder="">
          <mat-option *ngFor="let t of accountTypes" [value]="t.Code">{{ t.Label }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row clearfix flex full-width">
      <button class="bsb-lookup" (click)="bsbLookup()" mat-mini-fab color="primary" matTooltip="Find Your BSB ">
        <mat-icon>search</mat-icon>
      </button>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>BSB</mat-label>
        <span class="error inline" *ngIf="bsbValid == false">Invalid Number</span>
        <mat-icon class="bsbValid" *ngIf="bsbValid == false">check</mat-icon>
        <input
          [(ngModel)]="bankBSB"
          [required]="requiredLabel"
          (change)="validateBsb()"
          (ngModelChange)="sendResult()"
          autocomplete="off"
          matInput
          placeholder=""
          name="bankBSB"
        />
      </mat-form-field>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Bank Name</mat-label>
        <input
          [(ngModel)]="bankName"
          [disabled]="true"
          [required]="requiredLabel"
          autocomplete="off"
          matInput
          name="BankName1"
          placeholder=""
        />
      </mat-form-field>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Branch</mat-label>
        <input
          [(ngModel)]="bankBranch"
          [disabled]="true"
          [required]="requiredLabel"
          autocomplete="off"
          matInput
          name="BranchName1"
          placeholder=""
        />
      </mat-form-field>
    </div>

    <div class="row mt clearfix t full-width" *ngIf="bank && bank['State'] && bank['Suburb'] && bank['Postcode']">
      <hr class="mb"/>
      <app-map-view
        class="clearfix"
        [icon]="iconAddres"
        [canClose]="false"
        [canClick]="false"
        [zoom]="17"
        [postCode]="bank['Postcode']"
        [state]="bank['State']"
        [suburb]="bank['Suburb']"
        [moreInfo]=""
      ></app-map-view>
    </div>
  </form>
</div>
