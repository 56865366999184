<div class="rel invitQuickView" *ngIf="invitation && invitationID" @onChangeAnimation style="margin-bottom: 10px">
  <div class="card-header primary-gradient-img">
    <!-- Modal dismiss button -->
    <button class="btn-close btn-clear mat-button white" matDialogClose>
      <mat-icon>clear</mat-icon>
    </button>

    <!-- patient name and basic details -->
    <div class="row clearfix">
      <app-ep-document-view-image
        class="userIcons pull-left"
        [defaultPicture]="
          'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
        "
        [link]="profileLink"
      >
      </app-ep-document-view-image>
      <h2 class="sm-h2 pull-left rm-mb">
        {{ invitation.FirstName | lowercase | ucfirst }} {{ invitation.LastName | lowercase | ucfirst }}
      </h2>
    </div>
  </div>
  <mat-dialog-content class="animate">
    <div class="row clearfix rel flex">
      <div class="full-width mr">
        <h4 class="primary-color sm-h4 rm-mb">
          <mat-icon class="fas fa-paper-plane"></mat-icon>
          Invitation
        </h4>
        <div class="row clearfix">
          <p class="rm-mt pull-left">Status:</p>
          <p class="rm-mt pull-right">
            <span
              class="status-box list-label"
              *ngIf="invitation['Status.Invitation.Short_NE']"
              [style.backgroundColor]="invitation['Status.Invitation.Colour'] || '#b4bcc1'"
            >{{ invitation['Status.Invitation.Short_NE'] }}
            </span>
          </p>
        </div>

        <div class="row clearfix">
          <p class="rm-mt pull-left">Created At</p>
          <p class="rm-mt pull-right">
            <strong>{{ invitation['DateTimeCreated'] | customDateTime }}</strong>
            <span
              class="pill"
              *ngIf="invitation['DateTimeCreated']"
              [style.background]="util.daysPastColor(invitation['DateTimeCreated'])"
            >
              {{ invitation['DateTimeCreated'] | TimeLabelPast }} ago
            </span>
          </p>
        </div>

        <div class="row clearfix" *ngIf="invitation['Date.Viewed'] && invitation['Date.Viewed'] != '0000-00-00'">
          <p class="rm-mt pull-left">Viewed At</p>
          <p class="rm-mt pull-right">
            <strong>{{ invitation['Date.Viewed'] | customDate }}</strong>
            <span
              class="pill"
              *ngIf="invitation['Date.Viewed']"
              [style.background]="util.daysPastColor(invitation['Date.Viewed'])"
            >
              {{ invitation['Date.Viewed'] | TimeLabelPast }} ago
            </span>
          </p>
        </div>
      </div>

      <div class="full-width ml">
        <h4 class="primary-color sm-h4 rm-mb">
          <mat-icon class="fas fa-envelope"></mat-icon>
          Email & SMS
        </h4>

        <div class="row clearfix">
          <p class="rm-mt pull-left">Email status:</p>
          <p class="rm-mt pull-right">
            <span
              class="status-box list-label"
              *ngIf="invitation['Status.Email.Short_NE']"
              [style.backgroundColor]="invitation['Status.Email.Colour'] || '#b4bcc1'"
            >{{ invitation['Status.Email.Short_NE'] }}
            </span>
          </p>
        </div>
        <div class="row clearfix">
          <p class="rm-mt pull-left">SMS sent:</p>
          <p class="rm-mt pull-right">
            <span class="status-box list-label" *ngIf="invitation['SendInvite.ViaSMS'] == '1'"> Yes </span>
            <span
              class="status-box list-label"
              *ngIf="invitation['SendInvite.ViaSMS'] == '0'"
              [style.backgroundColor]="'#ed400c'"
            >
              No
            </span>
          </p>
        </div>
      </div>
    </div>

    <hr/>

    <!-- row -{{ "KEYWORD.treatment" | translate }}-->
    <div class="row clearfix rel" *ngIf="invitation && invitation['MessageFromDentist']">
      <div class="full-width">
        <div class="row clearfix">
          <h4 class="primary-color sm-h4 rm-mb">
            <mat-icon class="fas fa-comment-dots"></mat-icon>
            Message
          </h4>
        </div>
        <div class="row clearfix">
          <p class="pull-left text-left rm-mt">
            {{ invitation['MessageFromDentist'] }}
          </p>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
