import { Merchant, MerchantGroup } from '../types/merchant.type';

export const DEFAULT_MERCHANT_OPTION: Merchant = {
  'Address.Latitude': null,
  'Address.Longitude': null,
  AppointmentPageURL: null,
  Appointment_Cancelling_Fee: null,
  Appointment_Cancelling_Hours: null,
  Appointment_Cancelling_Policy: null,
  Appointment_Limit_To_Days: null,
  Appointment_Time_Step: null,
  CalculatedName: null,
  'Colour.Accent': null,
  'Colour.Primary': null,
  DateTimeCreated: null,
  Default_Buffers_Post: null,
  Default_Buffers_Pre: null,
  Description: null,
  Facebook: null,
  FirstName: null,
  Google_Review_Place_ID: null,
  'HealthHistoryEnabled.Effective': null,
  'HealthHistoryEnabled.Inherited': null,
  'HealthHistoryEnabled.Overridden': null,
  Health_History_Auto_Update: null,
  Health_History_Update_Duration: null,
  ID: null,
  InformedConsent_Declaration: null,
  Instagram: null,
  LastModified: null,
  LastModified_Human: null,
  LinkedIn: null,
  MiddleName: null,
  Name: null,
  Pinterest: null,
  'PreferredMessageType.Code': null,
  'PreferredMessageType.Label': null,
  Salutation: null,
  Status: null,
  Terminal_Code: null,
  ThirdPartyBooking_URL: null,
  TimeZoneCode: null,
  TimeZoneValue: null,
  TradingAs: 'All Merchants',
  Tumblr: null,
  Twitter: null,
  URL: null,
  Vimeo: null,
  YouTube: null,
  'addresses.Calculated': null,
  'addresses.Country.Code': null,
  'addresses.Country.Label': null,
  'addresses.Postcode': null,
  'addresses.Property': null,
  'addresses.Send': null,
  'addresses.State': null,
  'addresses.Street Name': null,
  'addresses.Street Nr': null,
  'addresses.Street Type': null,
  'addresses.Suburb': null,
  'addresses.Unit': null,
  'email.ReplyTo': null,
  'emails.Email': null,
  'emails.Send': null,
  'faxes.Number': null,
  'faxes.Send': null,
  'mobiles.Number': null,
  'mobiles.Send': null,
  'phones.Extension': null,
  'phones.Number': null,
};
export const DEFAULT_ALL_MERCHANT_GROUPS_OPTION: Pick<MerchantGroup, 'ID' | 'Label'>[] = [{ ID: null, Label: 'All' }];
