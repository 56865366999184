import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-contract-document-view',
  templateUrl: './contract-document-view.component.html',
  styleUrls: ['./contract-document-view.component.css'],
})
export class ContractDocumentViewComponent implements OnInit {
  @Input()
  content = `JVBERi0xLjUKJb/3ov4KMTAgMCBvYmoKPDwgL0xpbmVhcml6ZWQgMSAvTCAxNzk4NiAvSCBbIDY5
  MSAxNDYgXSAvTyAxNCAvRSAxNTc0MSAvTiA1IC9UIDE3NjY5ID4+CmVuZG9iagogICAgICAgICAg
  ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg
  ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAKMTEgMCBvYmoKPDwg
  L1R5cGUgL1hSZWYgL0xlbmd0aCA1MCAvRmlsdGVyIC9GbGF0ZURlY29kZSAvRGVjb2RlUGFybXMg
  PDwgL0NvbHVtbnMgNCAvUHJlZGljdG9yIDEyID4+IC9XIFsgMSAyIDEgXSAvSW5kZXggWyAxMCAx
  NSBdIC9JbmZvIDE5IDAgUiAvUm9vdCAxMiAwIFIgL1NpemUgMjUgL1ByZXYgMTc2NzAgICAgICAg
  ICAgICAgICAgIC9JRCBbPDk4ZmY5YThiNDU3NTE2Mzg4ZGY4MTBiYTVlMGVhZDBkPjw5OGZmOWE4
  YjQ1NzUxNjM4OGRmODEwYmE1ZTBlYWQwZD5dID4+CnN0cmVhbQp4nGNiZOBnYGJgOAkkmFaAWMZA
  gnESiLgIIviAhMk+kGwYAxPj0VsgJQyM2AgAFKEGOAplbmRzdHJlYW0KZW5kb2JqCiAgICAgICAg
  ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg
  ICAgICAgICAgICAgCjEyIDAgb2JqCjw8IC9QYWdlcyAyMCAwIFIgL1R5cGUgL0NhdGFsb2cgPj4K
  ZW5kb2JqCjEzIDAgb2JqCjw8IC9GaWx0ZXIgL0ZsYXRlRGVjb2RlIC9TIDYzIC9MZW5ndGggNjgg
  Pj4Kc3RyZWFtCnicY2BgYAKizSCS8Q4DHwMUgNlMDMxAJssBhocFDAVMDpwMDPYH/NSFOw/A+UiA
  FYoZGC6CjGG+89uhlfUCAwBrPw6eCmVuZHN0cmVhbQplbmRvYmoKMTQgMCBvYmoKPDwgL0NvbnRl
  bnRzIDE1IDAgUiAvTWVkaWFCb3ggWyAwIDAgNjEyIDc5MiBdIC9QYXJlbnQgMjAgMCBSIC9SZXNv
  dXJjZXMgPDwgL0V4dEdTdGF0ZSA8PCAvRzAgMjEgMCBSID4+IC9Gb250IDw8IC9GMCAyMiAwIFIg
  Pj4gL1Byb2NTZXRzIFsgL1BERiAvVGV4dCAvSW1hZ2VCIC9JbWFnZUMgL0ltYWdlSSBdID4+IC9U
  eXBlIC9QYWdlID4+CmVuZG9iagoxNSAwIG9iago8PCAvRmlsdGVyIC9GbGF0ZURlY29kZSAvTGVu
  Z3RoIDE5OCA+PgpzdHJlYW0KeJylUdEKwjAMfO9X5FlwJtnSriA+CLpnpeAHqBsIE5z/D6YrYwqi
  EwnXhiN3SVoC1JiTHs4zHFtzM5GxxD3Rnc1hBldlMyd97XBrKUGMfQUp6RqzqBCae+9QkgVCsdGi
  /sAIl5iYncZzd8pY/h9Ahnbe9pS3OA6AUZwSFa+DWWwRiEsItaHxaZg8hNaoA0I4wRKRLaKwghRO
  IYhFoYi8W0G4mE34aijOp22SKeZThZYky4l/kuY6/usS00X0VhU/7AENIHOIZW5kc3RyZWFtCmVu
  ZG9iagoxNiAwIG9iago8PCAvRmlsdGVyIC9GbGF0ZURlY29kZSAvTGVuZ3RoMSAzMDc2MCAvTGVu
  Z3RoIDEzNDEzID4+CnN0cmVhbQp4nO19CVxUx9JvdZ+ehX0A2REO26iAgIAiamRQcIlxRyPGhQEG
  GGWTAdHcRPG64xKzedUsLjGJ0SSOuASNiWaPJkazmE3jkuiNublGk2vMNSDzqvucYTM3yfe97/3e
  +72fjP9T1d3V3dVV1dV9EBQIALhBHUiQVFBmrizeM/IBAL/PAXxXF8yultdWfjQboF8ggDapqLK4
  rLbbcz8D9CgB0NiLS+cWxQ2MPwCQ808AfWyJxVx4NP36GhzxIKJPCVb4lHlvR/4aIrqkrHrO0S02
  EwDpC9BlU2lFgRm6XcWxTcOwvLXMPKfS5QP3h7Ed60AuN5dZXiPFAwCmYjnEo7LKUvn9C7O+BkjE
  sutN4LrTD//5lyUX1k/3GvCzPkQP/GvLN91iOd17R8O7v+68WWzop78Liy4oT4QAPnUDW0bBYAP8
  urMlxdBPrW/90nTnNZru+MiAAtAABQMkwiAA5o7zSkC1ahea3go7/QymMRv4IYbrukKtZiJMIkth
  Mt0O93FIXcHEnocqlN2O5UykB3hflJ+AOIsYgJiICFbrRiLMiPG8jLL7eV8co5KPI6gNJuvDoUIz
  0XET51ureQeKEE8iv4V9A9u06VCG5a3Y7xADSOMy2Getdjusw/rHsb0A655EOgnLm5Gfgv2SVN5F
  twqCOEVosb4HjrNCXW836TXow2yO87iWXBzzTsQSnGMM0iGIESjji3QQYil5B5aRdxxbsB0pLMT5
  l/J6RJZKh+E4i7E9A/tFY3kh8sGohxapFyIC0Z0+D+m0CxxEmojrv1tZN+IdKOFrbl0T6q/qdCsU
  HUe0B875CiKKpjsuInVpp1tnLOyE4VIK1CGdiQhBjKXHoIzdBQTttV5zESQOjExupzOIO1ghjMIy
  QT3Ha/bABl5GjBSwOW6yx2GTdA36Ytu92rW4jkK0dy/EdUik/4Se2hiYj/GVheMvQDyJY14S8VAI
  OTh/AtIUdlHE0BLESpzritNO3DZYXoB+HYdzNfMdg/3HI4aiX+oQpVwfnD+R25z7nUxsSUfZCygz
  hQPrAwRw7TwmeR/eH8eKUeNwSxuFLSizCu16DilD+HEdnBBxpgLb3sZxghBaRFdEAuIiYgtiJqIf
  4iVEd5wbcF5JxCvGDI9NER8YG5p30Iaom4hZZQ1PCn8qe2azOhafJ0L7PMxUEcHH5PuFxyzqsss5
  Nt9TPGacVMT3TB735Ee+Th5TrRT3HvsehnIdxB7E2HJSvu9QZ74f1tIJsAzpBozjhTxmuX5Oyu3C
  Y03YBPeESge0W2uS2CNIJYAoNdYXOqnTFq20BLbimHnafMwpm2AYq4Zh0oOQz65CltQDEjRJWIfr
  QVk7/R7G6Q9DCvpyNJbXd6LrOHQnyQzNYVznDrTnSXgCbTqLnaSR7CTRaHY4vtMAOaLZQecJ/hba
  GeSw0sYpR/u2/2r9fwf0U80OzJk7HP/QnHQ4cD0P8T2h+54kIWQnxfoGRB0iVh9H1ulnkkbdBDBo
  8WxDVDAT9NOYII0dRv/4YZ7HvYD1EzTn4ZC0Cpazk44vSB3U0ZOwROcHZroWcxrORT+FhRx8fKSV
  7eKoQ8x1jiUndcZrZ8pzvhpT4Ui1uP8+UHFBxXXEzxhHTxFljjSen8X5gDkasUSJV8evrfF5BJ5G
  usIZn53idGan+HTvHJedqThbML879ynqsdy5fp4feY7jOZLnOZ5nnPKdabv+9XQ7xjHPw8dgsrqv
  I1XciTp+re59zMPo77sdDu0Qx7PaPY5tko9jmzYZ+c8RGsezuO45rWfqJEeLep72cJ6lSj24Oc9R
  TQqUqflsq8g3P8Ej4hydKPRz0e6E+Zom9DvmQKHvJnUPoj1R75ksD22+AVbiOoKkpbgfsR4xhdtE
  +AIgkJ8L/EyUHkU787NoFSyUTuF9gfdNAW9xXmTA3aj7EVGHZyqnvE5zN2zRfg/JbALm2sNQyH3F
  18H14b7X14CH3g/zxEnoxZ5DGT9wRblNwgYmeFbEBe87E+9FaAtdAegwZkehDB9vs+hjAh/VHluF
  LUR/vIvw+OK2wDG1fjBO3Ce+h42aCXA37qHNujrYrJ2Ae84PtuEYT2O/CVwX7BcszutH4R7cX8sw
  Ny3DnAMi/ic7mqQduJ45mNcRUh3aaAcEaurQhjPF2rOYkmOX8v0jbQcjjxHto5iH+X3iUahncZCt
  nQmrsG6VBvMkzrsC6xbh/k3Cvbsc+4ereRtw7uVYz/tm8LsMvyPw/aIzga+2TtwDQOjA7yk4v/Qd
  bJbuhGUYx5n6R9EOi6EnnhcEYy8M0UuBKM9TsVKBqDMolERIBrhf1KfAR3S75IZxy8/Q/WwBWNlE
  SJZ6QRDzhp7sQ9yrN+AxyQums6PwGGuElbzMfKG7ZMf178G7Ja8/DmN4Pf0Iy+tgMhuA/ZdBOZsO
  NmkXxt4n4MqK0NfYT7Ma4yQa+/+E46og38BkaSLurSXI33A8z+XEHHscd3OwYdBT9GsHoasTnXSm
  I9Bud6JPUV/Od9AXdW3V06njb+gn1snHxX5chj0G+M7gOI2IUWjLWLoKdiA20S9hsDQS5pJtmGAe
  hyHkIuJxFS/AMEF3IcbiGd+b3IdIYL3hJcQC5OORvorYqZTx7tYbTiEW49ivId3N3ws46CDowynW
  PYlYh3jP2dYefK7fqm8PTQh0LO+FOg5yzXGTo7M82rkPzteH3YH2RGAsruHQzofJutnov25YH4Zj
  dirjPMlsL8z4I33+COQ4JAkbKjC1X6PTH0j9/wROt6Myp+rZ8L+l338H6N/5iKnCvj+AnxpDnuRT
  iEQ6EelEqQbmcGC5J5ZznfYk+PYrsA0eFvWt/lPqMVbwlRLu6FzfudzZr39Uprvh6fZwxkFrPDwE
  izhYBsojOpf1R2ARh/YtbHvr1jJ79g8wGWKlDUInEDHWqawdjWcmgkajrsGiz0qO1vJx3MsILiv6
  e8AqDrF3EXQPWDla23tj/ka0s2sfblecU7Q7/eP0S2f/oH4m9gFiMp4VH0AS0vFIM520Nb7VfNEh
  5scq8d5a5rnkYieZtj3RtjeO87Pmt8f8/wm4d44i3kG8/X96Lp5leI4w8DxxGu8hGXiPPIn3k3tg
  IcBNzCXNiYhnMA/lIP0M6/D0bumB8EDeG+uKkT4B0PQz8lVYf1KBg7IQ2KTeK4Owbp/aV6+ON17p
  3/QuwK8YUb/uVPo3bUfMQP5HxP3If4X0NaTrUP4f2G8R0teV9pvTsTwbcRDL32O5FDEJ+TVI/ZDG
  I3wRPth/LQe/j9zyHvo/Tn/7/ePPUryzFKCe4fx7Xkjv6/wO8aep059/QDu/azj9/0e03fcMOlHF
  DvjO9DXe++zt331+7x3HSdGfLe3BJjhu4p3Snd+j+V2W35/F/VGl4v1N3GNxXoAuTsrvzvz+yu/O
  /P6KdDPSZVqN0GcCf8/neoE4UgRCxYYAl3FYQs51EjCXPvx7sPzboNAX5pH55AHyENlM7OQ0cdBc
  +g49Qr+SiCRJLlKUNE+ql1ZKm6UPmDsbzaaw6exh9jf2BHuK7WYvsy/Yd5r9mjc0/9Bc07prQ7Th
  2n7acdqZ2jLtLO087RLtOu1W7XPandr3tSe1N8IWh92QvWQ/OUyOlI1ygpwkp8j95AHyQDlLrpDn
  y1vlZ+XnIzQRvhH+EZERxoiEiJyIaRGPRmyLpJHaSK9In0i/yODI8MgekXGRwyLNkZYoGmWIioiB
  GBrjHmOI6RITGBMaEx0TH5MaMyCmNKYuZlHMspiVMQ/HbI55PqYh5kDMwZg3Y96LOR7zRczfjQOM
  JuMgY56xwFhknHlJcynwUr+r9GqvJtokN/VpGtA0sCmzKatpdFNu0/1NK5oebXI059/MuPlTS7Oj
  2eHg36GGTcJym8hOcoz8ipZ7Gy33uQStlluEllstPcUI82Rj2TS2hq1lG9gW9iJrZJ+zSxq75mXN
  Cc1V1XIRWpM27zctdzWsLmyT7C77ygGyjJaLRcsly+mq5Wag5Z5Cy23vYLnxEfdErGm1nDdaLigy
  TLVcXmShsJz8Hyw3ptVya2I2xWxvtdxRtNznaLl+rZazGGdcIsJy5CprImi52Ka+aDlT0+CmIU0T
  m+5tqm9a3dTcPO3mQLRcHbec4xsMzEcdXehR+oqU6DhN38cd4YUR+RCpJTNJVfMmLFt5zLbEtcS2
  9Gjpjux9cC/MhlIogbtgYPNXzaebTzS/13yu+aPm41yyeX3zuubnmzfj5+Hm+c2Lmv/abG1OAfhm
  KsDXp5Xv6p9bjHj0/D3nFp27cX7buVosvYTAvHqu/tz952vOzjg799yBb+LPrT677ezaM2vPbDmz
  AuDMM7zv2YAzs85gZj6TdMZ0JuVM9Okhp7NPDzidfrrP6ZTTSad7nI48HXK6y2ly6odT35+6dOri
  qa95r1Nvnzp06tVTOMupt049fWrnqexTg05lnoo+FXkq4lRY8OHgX4PPG17Fm96rumd0T+ge1z2m
  26Bbr1unO6J7QbdZtxHPr++0AzX4dioV8L1L+nT8ewr6dwUdylclf2dZKoTf+ZJGYab57ZbViCfx
  RjSKjWN5SPPbt7JpiCIF/+mLjeFg49TSqN/To1NPI+veykf/rqTrf2y5q0NRgqdgESyWpsFa+Dss
  gdWwAp6A52ArXhHq0awL4WG4Cj/CKvgbLIPX4TRcgSdhO/wLfoJrsAWeh3fhbXgB8qEA1kAhHAUL
  vANH4AN4D96HY/AtFMGHcBxOwItQDD/Ag/AJfAQfY6x+B9/DcpgBVpgJZRi95bAJKmAWVEIV2KAG
  qjGma+ESzMHongt/gfsxzl+CzTAf5kEdLIB/wD9hP1lL/kYokQgjGmiCZrKOrCcbyGNwE1qIluiI
  HhzkcfIEeZJsxFy0mbgQV+JG3MkW8hRch1/IVvI0eYY8S7aR58h2soM8T14gL2LOspNdpIHshn/D
  SVJPVpA9ZC/ZR14ijcSDeJL95ADxIgbiTXzgHJwnvqQLeZkcJH7En6wkr5BXySFymLxGXicBJBB2
  gp0EkWDyBnmThJBQ0pWEkbfI23ADfoWv4RsSTmQSQSLJO+RdcoQcJe+R9zFnfkCiSDSJIUZynJwg
  H5KPyMfkE7whdCPdSQ8SCxfgIjkJn8JZ+AK+hFNwBj6Dr8gVcpX8iGfVT+Rf5Bq5Tn4h/yY3yK8k
  jjSRZnKTtJB4PMeAEkqpRBnVUC3VUT11oa6kJ3Wj7tSDelIvaqDe1If60i4kgfpRf5JIkmgADaRB
  NJiG0FDalYbRcCrTlTSCRpJeJJlGkRQaTWOokXaj3WkPGkvj6DK6XGPQeNMr0gJpobRYWiotl1ZJ
  D0gPS49K66Un8OR8WnpO2iG9IO2Udkl7pf3SK9Jr0lvSEekY7tUPpZPSF9JX0nnpovSddFm6Iv1I
  f6Q/0X/Ra/Rnep3+Qv9Nb9BfaRNtllwlN8kdTxeCi9rKnmbPsGfZNvYc2852sOfZC3iq7GR2tos1
  4Mm8h+1l+9hLeM7sZwfwnD7IXmGvskPsMHuNvc7eYG+yt9jb7B32LjvCjrL32PvsGPuAHWcn2Ifs
  I/Yx+4SdZJ+yz/CU+oJ9yU6x0+wrdoadZefYefY1+4ZdYBfZ39m37BL7jv2Dfc/+yS6zH9gVdpX9
  yH5i/2LX2M/kG3KBXWe/sH+zG+xX1gS7oIHWk1TYC/vgDXw72g174E34K7wGSzEXjZbGSWOksdIE
  aaJ0tzRJGi/lwM/kW3qYzYODsB4u4858Gh4iGfAAySSzyYN4XjxMaqGR3Ecukx/YLFbFFjCblCtN
  lu6RpkhT2SJWw2rZYjabLWFz2VK2jC1n9WwFW8nmsEfYKraaPYAn8oPiTH6MPY53mifxZrOOrWf3
  s41sE9uMJ/VTUm+pj/Qvib8jagGcf1FMKD5op7SDjRLTaHV6F1c3dw9PL4O3j28XP/+AwKDgkNCu
  YeFyRGRUdIyxW/cesXHxPRMSk3olp6T27pPWN71f/wF3DMwwZQ4anJU9ZOiw4XeOuGvkqNFjxo4b
  nzNh4t2TciffM2XqtOl5ZsgvKLQUFZdYZ8wsLSuvqJxVZauumV07Z+69f7nv/nnz6xb8deGixUuW
  Lltev2LlqtUPrHnwoYcfeXTt39at3/DY4088uXHT5i1PbX36mWe3Pbd9h/T8Cy/utO9q2L1n776X
  GvcfePngK68eOvza62+8+dbb77x75Oh77x/74PgJ+PCjjz85+elnn3/x5anTX505e/vuePvuePvu
  ePvuePvuePvuePvuePvuePvu+OfujqbMTFPGwDsG9O+X3jetd2pKcq+kxISe8XGxPbp3M8ZER0VG
  yOFhXUNDgoMCA/z9uvj6eBu8PD3c3Vxd9DqthkmUQHx21JA82W7MszNj1LBhPXk5yowV5nYVeXYZ
  q4Z0lLHLeUJM7ihpQsmiTpImRdLUKkkM8gAY0DNezo6S7ceyouRGMnnsJORXZUXlyvbLgh8p+DWC
  90A+IgI7yNmBJVmyneTJ2fYhs0vqs/OycLhdbq6DowZbXHvGwy5XN2TdkLMHRFXuIgEDiWBoQHa/
  XRT0HqiUPTgqK9seFJXFNbBLMdnmQvuYsZOys0IiInJ7xtvJ4IKofDtEDbJ7xQkRGCymsWsH23Vi
  GtnKVwMr5F3xh+tXNhogPy/OvTCq0Dxlkl0y5/I5vONw3ix7wL0XAtuKOLjP4ElL27eGSPXZgVaZ
  F+vrl8r2TWMntW+N4M/cXBwD+9KYIXn1Q3DqlWjEEeNlnI0uzp1kJ4txSpmvhK9KWZ8lKpvX5M2Q
  7S5Rg6JK6mfkoWuC6+0wbm5EQ3Cwab/jHARny/U5k6Ii7BkhUbnmrNBdXaB+3NzdQSY5qGNLz/hd
  Bm/FsLs8vVTG3aM9Y2ltE5wQ59yIca2WJVyjqOEYEHa5QEZNJkXhmvryh6Uv1Bf0RTH8yiXYy16I
  HrHaXQbn1Rv68Xre366JwTti/c+Y2/OiLv+zY41ZrdHGGH4GzvI4aQ01bHfy9rg4e2wsDxHdYPQp
  6jhQlHv3jJ/dSKOiKg0yEjQfjEHbmnP7JaL5IyK4g1c0miAfC/a6sZOUsgz5IQ1gSozLtdM83nLY
  2eI3gbfUOVtau+dFYSTvEW99fna9sfWPl8HfN7ukn534/06zRWkfMT5qxNjJk+Ts+jzVtiNyOpSU
  9r6tbSpn9x08SQqhKkdDJNGKQTmlVZgXJrnbWQz+0YqgLmzU6TEqRQ2Rh9gNecOUZ65rRMSf7NTo
  uMp7CdLWTVXT3i+uY7l/h3IH9dzrJVSYGemInMn19a4d2jDUlAmHqwQjHnImRciD7TABd2YM/ml0
  HO7LkRtiN6HJBnMBjD+lSi12EAxR+Vz84tHZM34IJrr6+iFR8pD6vHpzo6MuP0o2RNXvp6/T1+sr
  s/OcgdPoOLAixD5kZS7aqoT065kZBV5SAFxBOBAShOMzETEaMR3xAGIjQivkeE0FYj7iEOKqaDFJ
  AQ0PpZgakawQZPeM0mRRNCvFKVNFcffduQodOVahWcMVsX6KWK9UpTphkEK7xSvUJya5jlNXj+TD
  mf54dT+BoFCJT0LfBC9CIBw2SX5gR1BJq9aYJJ/d0cbkjYckBngdkAheS8MdhyXS4OGdnOlKHfQK
  +EA4/YFeVlro5d2e3skbM++kX8NOxCGERL/Gz3l6HubTc7gDvPCZgdiIOIQ4jriC0NJz+DmLnzP0
  DEp9BYmIDMR0xEbEIcQVhI5+hU8DPc33k3hyPgNB6Wl8GugpXNYpfHrRL5H7kn6Jqn3ckJaevF8w
  cYkqEx6jMgEhKuPjn9xIP2q40SO8kX6zW44L35SZRD8BO4LiZJ/g4J+AjBiDyENUIrTIfYrcp1CH
  WIPYhLAjtNjnU+zzKfY5ingf8SkkIUyIMQg9PdGA0zTS4w3GQeGZ/vQD+g4EoFGP0XcFfZ++Leh7
  9C1BjyANQ3qUvt0QFg6ZbtgO2MeA1IA0Eds19LXd0T7hjkxvegjNE47PREQGYjRiOuIBhJYeopEN
  heE+OMjLcFQPKNkA3wn6DGzRg2lGuMk4GGNM5g9jvzuQw8dGeaORmoxr12ORP4yrH0KOP4yLViLH
  H8Z7FyDHH8bS2cjxh7FwBnL8YZw8HTn+MI7OQQ4fjfTJl6K7haeNnknkTC9ai1aqRSvVopVqgdFa
  /oEbjOv2WENsLFpsgymuR2x43QFSd5DUjSN1W0idhdTNI3ULSN0AUjeN1MWRulBSF0bqTKTuZdIX
  TVFHTHs6FNNNgaTuKKl7gdTZSJ2R1MWQumhSJ5M0UyONaBieIki2ILsz+b5CesfAZC/UMQItGoFh
  HYHb/hA+jyMcomRCITlSEQ4K4zRyd2yGUk7ol1yROYy+gR3fQDe8AWcRDB30BobRGzjIGziAFz4z
  ENMRhxFXEA6EFqUjUfEHxNMLn4mIDMR0xHzEFYRWqHMFQaFCVXGnUCxRVXo0L9E38BOJnwgaYepq
  CDXEGYZJD4QSrzAyOswRRtPAn7/l+3jrvfFtbd8vHv/+xQNcMl3oavoAdEVHrFHpAw03uoY3knUN
  xpfDM/3I3yCMYdSRdDCSGKR9wSbKvSFUz2kqhNIdSJMbQidiN68GY3z4AeLJe+0LvxF6Ify70EaK
  7KXQl8M/kxsZaQg/iTU79oV/Ero8/Ehiox5rDhobCZIDshDdH9o3/IWjQnQBNmxoCJ/Hyb7w+0OH
  hs8MFQ0WpWGaDUsmr/Bxxsnhw3C8rND8cJMNx9wXnhE6LXyAItWb99kXnoQqxClsLCrbI1RMGhUm
  BpyQ1khKTPG6tbpJutG6PrpkXbwuQheu66oL0XXR++gNek+9u95Vr9dr9UxP9aDv0ug4Z4rj3wDu
  ojVwwn9mgAATvIHyJ/9eMc9rRE/hTrD7SiPoiPGDyAj74QIYkS/br4+PaiSueIBqogYRu88IGJEz
  yN43bkSjzjHOnhY3wq4bc8+kXYSszsVaO13WSPD0ayQOXrU4hF9V9wMh3otXhXDaffGq3FwI9J+d
  EZjhM9A7fUjWbzzy1Gdc21dgB76rfe2I8ZPs27vm2pM54+iaO8L+ML/L7sf356vZWfvxVRpJ7qT9
  0kDyU/Y4Xi8NzMrNHdFIJgo5kMmPKIcR86OQ04eBzOVA1ocpchsUuRjsj3LRnKCciwvECLkYFxch
  xwiX22WLzs7aFR0tZAJksAkZW4DcXuZoDMrExAgZ/zo4KmSO+tdxGftAIRIaiiJhoUKEBEOoEAkl
  wUJkYptIoiqyvFVkuZhJIm0yoYqMxzmnjMc5lIn7s1+WQXFxZHf/3IIp/D0gLyrbgsizr5hdEmiv
  y5flXQW56guCMS+/oIRTs8WeG2XJshdEZcm7+k/5jeYpvLl/VNYumJKdM2nXFJMlq6G/qX92lDkr
  d/fQMalpHeZa3jpX6pjfGGwMHyyVzzU07Tea03jzUD5XGp8rjc811DRUzAUixsdM2qWHQbl47RR0
  N3VzxXjNC4nIHeRvqBwogrd/ROC8kAOM/2CfG97C3fGNzgPBm3pm9szkTbineJMnf9lTmwLn9Y8I
  OUC2qU0GrPaOGgRx1TW2GgjMtmYpf2z4hVXVNdzgyjPO9p++sC0b39uybNUAI+yx40fYM/Ceu0un
  w9o8viR7P2edm1s2XjeVygSs7McrJalVkNcN4HUuLqrgrf6vUelgvgvq6Mu7iSmMVIMtV7KHjcih
  mApy1Fv1Abwu8ePBlosLtJE4YnOOIdQGhQe+Xieqa1ROtUO1SpVe2MXmNEfrF/bBVKU5AEGIYM2z
  EMSMEAjg+BZxidMWq+MSb+eU/gOFG1UAbIMXiBVegEPwOrkK/Dt7+2EP8BtPFjwO98EjsBRPsclY
  sxzG4UeD9Y+QIMceSITNeI5thmMoezfMgwPgTwId38F8WCx9jL0WgwdEQiaMgQpYRe5y1MAUOMsW
  QhrcBeVQSeockxyrHQ85tsLTsF9613ET3CAYCvBzzPGD5nPHaeiJPR6F9XCWPOSyF0w4Sx1KPgFV
  sEGayoij2PErahABtagDg5FwjBymcTi6Bb4lgeQ+aTCO8pTD7ngTpUJhKpTABjhAepOhNEIzxTHS
  cQz8cY45OOp6aIB9+GmEV+BL4q656tjquApBEA/DcT174ANyWGq5uaAlgxsardQD0rGlAl6Fd+AE
  iSKv0QqNuyZZY9Lc6/gEukAvmIDaPos9/05+ofPwM196mw1xDAJPtMuD3NrwFpwnwSSRjCYTaQ9a
  QZ+UqkCPM/bCTyFY0d7rcPQzGDX7qDs9Lj3FdrAmbdeWcw5P9IgRHoMn4DXigSuViY38lXxKvqGD
  6XT6GP1aeoQ9xz7SmXHV06AMVsEO+IX4kL5kLLmHlJD7yFLyIFlPjpET5BLNpDl0Jr0ilUizpFfY
  IPyMZza2ULNEs0J7qWVSy5stH7b84kh2LIGxGA8LUPtH4Ulc2X44Dl/g5yx8TTTEjXjih3/XdwL5
  C37mkVVki/ge9B6c5QT5mnyHJ9DPpIniwUq1NIR/lxU/UbQKL5SP0MfpcfycoP+kN6QAKVKKk3pL
  A6RcqQK1Wiqtwc9e6TwLZseZA+2crFmr2ajZptmheZ3/fZrur3ikv9/81M3Ym2daoGVZy9qWhpY9
  jvPghz7EwwJfoQag9mb8zEB/r8WI2wkfE3e0XTCJJQPJXWiZ6WQGmUXmoCUXkQ3kaaH7i+QgWukz
  cgV19qChQucE2psOoqPxM41a6Cy8ez1E99BP6a+STnKTvCQ/KVYaKk2VLFK1NFdaK9ml96WvpK+l
  61IzfhzMlYWzSGZkcWwom85q2JPsW/atZormPc1Frau2TLtE26j9ES8xA3VjdGN1U3UP6PbpPtHn
  8e+iwl54qf1fdZBz0gIpW9oLq2kKC8I3lg8wnqdDoTSSYqTSbWQZvZ/sodGaOdr+tD8ZBVfx1f4R
  +jbdSK/T/tJIMoKMhxn8N1X5l7YL47/5PYC9AZfZQVzbBzjyHK07mUevaN2hgYjfmyZvSUksTnoP
  vpTOEh3bDKeYKwkgl+mz0hiMglfYQM0kiJAehxelWeR+2EuzAVyb9CsxjkeR7ZgXckgy+bfkwFvv
  KIyiNOkbWAgz6edwGffxMvgbKWTFsBpSyH3wLTyDu6KHplwbq/UjR6iV1VNfsgcoe47/PjOJJpKm
  CywiU6UN2iv0C6iB48wVzkjPo/bH6YvSSHZVM46U4A64H5bALMcCmKuZxD4ixSCRiRDDzmF2u09K
  ZhFI52NWmYI5bR/u7gOYBzKlkVgTiJFzF8bFBMwQG/CzDvMEwwiy4h6/G7PYB7BHm0MboVjjSTDr
  ALD3WsbBZMczsN5RDOWOh6An5oOljvtwxG1wER6AbWRxy1+gEt8cv8C9fZdmCD2uGeLoSevpF3Q8
  XdvRv2jtGBII/8DPizAEBmpehnr2GYyHDMdKx0mM7u6YYddDPt5PL+Aqf8AZhkmHIaVlFN3lGCJV
  4nrPwljHs45w4goljlIYDQfhaZ0GzLo4dYLS/zdB6v/vQLpLAav689Aswb3zZBt00xToY2/jNm7j
  Nm7jNm7jNm7jNm7jNm7jNm7jNm7jNm7jd0CJ+AsXDf+pfh0M2kPJBa2uka43+YKGXZDAVccuEAjS
  azUXqHSQ9gIXsp4kQGCc4fqAmwNGGa4NGHlzAGQgb2jGR6+kCO8I7xh8EGDQLEuHm038h+xldpj/
  XX+R41vNbM3H0BUaTXkFdEZXKkOyRwFUQnXXOljUdQ1s0OyQnvbYL+3xeMfjBFzo+q+u3p4+Xb27
  dpVitd29Y0Pl8KEeE7vc7TcxqEQzs+tffFb4bJDWe24I3Ua20m3eJz19oQsEG7oYghltdJxp6J5O
  Gh2HTd26pxu8gLAQ3zB3KSSMuRiMXneCUSaEBIcHGGU90QeFFUwJjMMFTR15eZThOj6vXYaMyxmX
  vQPSeyWRuLipU2fBVFJFArQsKjKa9k71iU5JZgE6ozEqUkv9uvj4pyT3YXtev6PljYuXWz57bCcZ
  /PppEt//UMrrDz/3zZSyvy956mtKe11peo2Uf3SRTNh17r2emx7a0nLlwZdbvqs/CBTudFxioWwg
  dIc00tW02sXDJTbIIzi2h0dsbLpHH7+0kH6xw2OnekyNneFhjc1LqvdY0mOD/2PBz3n4PRO0vfu+
  oJe7vxl0vPtHfl9112f5k/CA8MC4+NjUdJYeP5wNi5+oz40r0lvjZrsvdT/ifsPjRpx3WqonYYbE
  6NSA5IgugdN7VPSgPUITPTM8H/Dc6Onw1Gz03Ol5xVPy9AyVAhrpdpN/4KNdQkN1kN3NNTlUcuth
  NpghJiK6kd5jMnQzgdFglI1Jxp1GjbFXOjd7eFhUalL64XS6KZ2kB8QERiZGH9Ie19JwbYaWanv1
  RTPPQjMbps6Kuz718rUBNy9e5Ca/kHH55gVvn/REbJ2FFP+kE28f7gf0wKypMCtGq42KNPZO7dMn
  TXx6p3bjXtB1G0hTkv39A/z9/Lr4B0QZJa3OE13DPYNC0oDC/TN2HhxqG9Z75pfFJCV72fy5Xe2B
  5SeWL9s+xuASEHkwNCD/zYopyWXWki3GrgsnDNmxeNSCUV08PYKjY1zLe96ROytw1ooRJvOdCXOu
  Ni2+oy/5qnuoofvIxGF594y+oxbje4zjknQZPRgMx0xDXdxJeOhg38EB433HB+T55gU8Rh+TNnhs
  NWwNdtd7BLnOoFZphqbGvdKjzuMZ970u+1z3urv7uy9x/4ZKnpHTvSq85ntJXoQbfngSmGAM5OFG
  WQOb4BxcBRfw8nIDYD6hbrrAUOYW6kW8oj0jQ1CLaLe4cEIAg3t4qF/0cR0J12XoqK5XSOqbfN+i
  VfFRpf6w6n4g/KdDL1ddu1wl4h3t7p2eaJh6Af9wg88i+CeAGxy8U336oH3VmFfsKg3Y1fXKi1+2
  /FL13fIXTofvDJo/edn2rYtmrCaLA146TroS1+cJXbBzc8jM0jc+/vT1v6J+SzHZ/B2t5A/vm3w1
  ktaXbjM0Gr6RvvW9Kl331bJGx1VTLzeP1LkGss5wIvBcoCOQyfounl38fUI1OqL193D18HT3jHYz
  pfRJdbgR/OM2KpAHXHBqn1R74NVAWhm4KdAeeDiQBUo0xc8/hgBv9kH5q/xHjmQ4gUZkMCqAmyMO
  E9e1AUoUzoq7PMDAE1oG7vx04oMxN3iuyV/r7eKqd9W5SlqD0VvrGUK8XH1CCMRhXohdgAaCuKmz
  UrxT/Pqo4ecd5Z2qGMl76Zaar/I2jzG47omdOcz2LDP+bWd25cjk+2/a6JLyssyH3r/J934W7v1u
  aBMPCILXTFN9dK5B7kO1w/QTtbn6Yq1Vr0819PPp5987MNswwmeEf3bgFM0Ul3GGqT5T/ccFlmnK
  XAoNZT5l/oWBtcTPRavxuEfK0eS43uNeKlk0FtdSd9eAUKbzDnVz6xKt46bwjY5JTdIR0Bl0sk7S
  9TobQkJ4fRDfssh7RoMJRcIhA5XrFZyaJiIn7rJhVtzU61OREbGCiZFvx8FTJplcxmvGu+Rr8l0Y
  mZrra0hDS4BfFxE2viJQeqdye2RtXf7WKeL/l+9XnG25vL9h6ZKG3YuXNlBf0m317JbzN499/1cS
  Rjzef+/9D9967yi6ajEAfRvt4g0LTf0TfYmBkSiWygaz8ayIVTOti7feRe/i4evt4gGSnriFajFA
  wNWl+xrM6ZGyL/Glkd6q+/1ucb/PUHVHYAhgvF+rugAZwvHp3iLlpKeD4chSz/vf5BuhikwVLsaV
  4Q7g6Qa9u3jLQGvGPdMGDhrUf1qXMGbcPGtYv2e7Dc3Iq7r5CT/xoh0/0VjNegiAuv3g6ji8O8qY
  6sKVyUSmLgh3qbuHK5HA3+AS5+Wq9ce06mWIhEji4RPjThw6fbZLdp6uUlenW6NjgL7apLPrDutO
  6LS6A3QGBJI+u4qUJVy7YLjMj+EL1wZw3yDrnY65MyXFcATdNDUuLiaAJ01jb++o3ineabiSKO8u
  /ugZagi+a0B+afyiRbv37vWN6x62eaNhoGULLVhJdKUtq1befHhkfLDyd5lDpFGg/As6AC3q7wLy
  VbqSgSpPwVNzBpz/0s40zWGVZ+1kNBCo+UHlteCpDVN5HbypjVd5PRh196m8C9R7bFV5V/a6mJnz
  bpDvmaDy7lDkuUblPbR7tFdV3hOmeF5v/RX1+V7jwPnvnWu8flR5CjqfTJWXINEnWeVZOxkNuPsM
  V3ktyptVXgf5PiUqrwdfX4PKu0C2f7TKu1Kz14cq7wa9/K0q7w4p/htU3kOa7HNU5T0hwf8Y/5eJ
  mIS6ufs3CV78i+sBboLX8vqAEMHrRH03wesFnyZ4F9VHCq/4SOEVHym84iOFZ+1kFB8pvOIjhVd8
  pPCKjxRe8ZHCKz5SeMVHCq/4SOEVHym84iPOu7Zbr5tYy1DBu7er9xRrv1vwBr6WgGLB+yLvE1Aj
  +C7t5P34OCrv364+SPRdKvgQMZcyZtd2MuHt+Ggh/6jgYwX/lOB7Cn4X5/Xt9Ne3m8u9Xb27cy05
  MBePcwsUgRkKkMrwHCIHSgQ/EiqgHFGtSskwGEtVyPOnGeutQkLGmlLsn4Bclqg3/2+OlNiqmQzj
  saVU/F6tImPDuuFIlfl6QTp+kqCnyiWL2kzsUYp0HPYpRh2qRa9xOJ4NUQWz8VkodCjHNguUtWpS
  hfPKKGVWZ1LkrWghGXvw/nzEcogXs/AWs5ipQB3LjDVKzzIxIl9BCWpfJka0Yku1kC4Rc3GrV6sz
  2MQKC0TfatFeLkbhlOtUIXSwqmupFGNzjQqEVjYxG2/h8oWCKvrXiNlkMUN7raxi/GpsLxflWjF2
  iTq7RZWtEGMpczvrS8XY1apFCrCkWKazXDWOaRFWsSJVxi5Qa2qEpbmv2qKkQvilSli0VPTnmvLo
  KFN7OWcoEP1nq7Na1ZXyNsWabVYoQkk+mlLbZlerat0KdSVWIV8jSm1etYmILRXa/XZMOHeOrXUt
  vK1MjNc2RhXOM1PV1qzav0DEtKzGvdNmhWLuYlGr9K/FFqvqQy5Tir5XYqQCn8XYNlu1tjJC2142
  C18p0SELGxao67cKr5UKmUqxz5RoLBc9lZW0j25ra2TJ2D5H9UyZ0IbHpuI3m7qTS1v1KBOltuit
  7pRvbJ3WV6DOkS9GqBGWLuwQmxaYhfVOy9aInyR1rrBIxLYsYmCOsK1NxF218EZxq9e57sp+53sp
  vnU32dQoa8tHSmuZ8IgZ7hX9Fa35uAWitS3SlNkLhbUqxS6Z27oK59y8f61oNwtLVKlz8D2kWLFa
  9Hdq7By9UsRQmcihTt0Sbsmr/Tp4jee7YhH/3Lv9YKI6nzPX8lzZF58yvu2PFD6oEvtB2Uc92o01
  EuO6rfSiiPMqdd+XidFntvr4v5vzFb8Uq5nQoua3tjyljDoBzwMZX0PNwr9GMd9IfI7GuYtE5Dot
  xmPTJqxdoo6WAKNQLgdPjyGIwbgizo/GWt5/CD7vEvXZWDMen3wPDEUrZuNnpKjNwXcjV4EcEbW2
  34hpubVe0VjxXKXq27a9cKt9lDOvAm1QJaKjREg71+PM/M54yhetc1G+pnXOgtYcqtiuRvRty30W
  dXfwDNWWr5U8YVVzs03NHcViFEtr7uW2zVVn41lktpqz81tPPWXO6t+xjDO2aluzoEXd2ZbWvVMl
  8lS1mjeK1Lj/LXs5dzu3mKXdKG3Z4tb5CtX44rGcLzKwonW+6plydeTf8lA3saqOllIy/61RcevM
  zhzKs6VZ3GjMOGupam2bmqv+09wJIvbL2+Xzubf4wqLeZtrvHOWUMAuNKoVl+bllFfvtj30uq7FY
  3i6HOuflu79QWNra7rSqanfjim+VrmoXt213hN+3FNeuTIzvjKuKDuPVCv/PFN5sn02cebhNsgJl
  lTxTIyzOxy9pXY+iV/voLlMzt2J/ZVdVqvHRluE7xtDvragtPoaLtd/qOecdj59tFvUmqKxGuVcW
  CK+Wd/JBVSd7t43M11chMn+hmldniztYLbS/xf2x953jKXvSot41Op7IzvFu9aNirbabcYEY89Z9
  7PSYuZOti/5L2rZZ+dYZOt4rOmpkUW/L1XhCOkfgp0wm1vYEfjb2hVRIw/NQxmcvLPXE941URBLw
  d84JMEKVTBK/1ZGKH4VPgxQE79UHeuO7CQcfvUTcSSpxvkT81IpPgjjbO+74ApH5/tM5wbkssTtr
  W+NCOQWtarblOo0TGVo5Q0ep96wK9QbP96dyklaJFqvwwHh8tp0bPKr4mxW/J/zX9E4U8vxfSErE
  Z7XIENxXieLsmS6iRLlPJLRK/s/OUCvuAIqs5X9kFmdbYqd4bB07Z26lpchcYJGfk3NKLPLIivKK
  aqySB1dUVVZUmautFeVyZWlBgpxlrjb/gVAiH0weX1Faw2ts8vBy7NcrPT2pJz6SE+TM0lJ5nLW4
  pNomj7PYLFWzLYWDK8qrLWV8kKq5ss2MnbDeWiQXWmzW4vJ4ObPKai6VC1DKbMXGsooqi1xSU2Yu
  t9qq5YISc5W5oBo72KqtBTa5usRcLmPbXLmiSLbiLJVVlkJLgcVmq6iyyebyQtmM49cUlMhWdShr
  uVxdU26Ra63VJdjdgrUVhbw350vNOAf2N6MyzrrqWkt5tdWC0gXI1FTNTZCFSSpmW6rMuLzqKou5
  ugybeIeCGlyijU9mqyhCNYUKRTWlpcgKXXH6sgqcxFpeWGOrFku1Vc8ttbS3BHeOjc9iqSqzlguJ
  qoqZOKwZ9S+owYnKhWaFVnNxBW+vLbHiCksspZVokQq52DrbIgSEl81yKZpDLrOg7cqtBShurqy0
  oBnLCyw4iWJuKzeWbJmDiymzlM6VcW02dHIpH6PMWirMW63GjU2drwB75FvkGpulULGmZVYNV7am
  gNtfLqrAJeOIuKjqamt5MV96lQX9Xm2L526yoclEHGGxzFxsvtdajkNbqgviFaNh90KrrbLUPJdP
  wXuXW2ptleZKVA1FClHFaquND8zFK6sqyirEaAnOWO2nLG2cpbim1FzVbyL241GbnNA3We4+0lpQ
  VcF91ENIjcwRZJucU4W+LzNXzeQr/r3Ix7UUYxBaMN5ETKHohPHyGHO1bJRzRsqji4oShGKWUpul
  tgTFEkaNzhk+ZPjgzJzho0fJo4fIdw0fnD1qfLacOXRcdvbI7FE5Hq4erjkl6Aqnpblb+MC4OFx1
  tfBCqz648yqKq8yVJXPFPDz4uZ3y58pzK2p4zwIeoahdTXmhiD6MCQwoEdcYE1aMZhQ3F1dZLDx6
  E+Rc7FZixtCpyOdbD3tWd1CGW6uWh6AFnW3h3qmyFFRjbBSh7dv04m6vKLYIEREWrf3QnRjx+TXV
  ODSqWYG7sN2CutmcSmHwt5qitTOPUHm2ubTGnI9RabZhVLXvnSBPKBdxPte5ClyT6hzcEmbZVmkp
  sBZZC25duYxWLBcRyvuaCwut3McYOVUiccXz6iphW5EROilVai2z8gXhJEKutqJqpk0JbBHDorKi
  FmOmJr/Uaivh8+BYirnLMLhRf3RV5VxZCXjVQh0nEvYYXtS2OJ7xZtVYbGIazJUFlqpydQVVqt5C
  2FZSUVNaiLE622qpVVLcLcvncuhJC2aNwra02LpGVEsk44LqNh/zhZlVrYt+e1ihcmsHNVeoA+E8
  5up+XGDC+Ey5p9y9b2paDzmtV9+eSalJSS4uE0ZgZVKvXqmp+ExLSZPT+vRO753u4VpSXV3ZLzGx
  trY2oczp+IKKsvZ7wiJnVZlruS1wC6JSONK4inzcoaMwZ1Vggo/nm7TKWmA1y+PNYm/Y8MTqm/wf
  xk4sqS4rTSyr5v+jbGKZbbqZ54kEXvknO9RaSrHW8sddeClRtaOQxstQhXgNNot/wnGuuCbNJR54
  mM/A8nfiKuBsHy8ui/xKxC8thdIGaZf0inQIsV86ID3fbiyzuBg4y+fF2JYOc1k6jCbGY2GsFxvB
  hrI78JmO0mbxilioXkdKiJ1slkBc8fg3YarE9YyPAfC/AKimKJtlbmRzdHJlYW0KZW5kb2JqCjE3
  IDAgb2JqCjw8IC9GaWx0ZXIgL0ZsYXRlRGVjb2RlIC9MZW5ndGggMjcwID4+CnN0cmVhbQp4nF2R
  zWqFMBCF93mKWd4uLtFc9W5EKLctuOgPtX0ATUYbqEmIceHbNz/WQgMJfJxzJpMJvbUPrZIO6JvV
  vEMHo1TC4qJXyxEGnKQiOQMhudspnnzuDaE+3G2Lw7lVoyZ1DUDfvbo4u8HpXugB7wh9tQKtVBOc
  Pm+d52415htnVA4y0jQgcPSVnnvz0s8INMbOrfC6dNvZZ/4cH5tBYJHz1A3XAhfTc7S9mpDUmV8N
  1E9+NQSV+KdXKTWM/Ku30X3x7ixjWROIVZGKS6SiiFTliZJWJa0sI11ZomuiIt65V89/7zpaK/No
  K1mq9Li7kx6aDUM9JsFXa/0Q4uTj68O7pcLjc4w2IRX2D4vdihplbmRzdHJlYW0KZW5kb2JqCjE4
  IDAgb2JqCjw8IC9UeXBlIC9PYmpTdG0gL0xlbmd0aCA0ODAgL0ZpbHRlciAvRmxhdGVEZWNvZGUg
  L04gNiAvRmlyc3QgMzkgPj4Kc3RyZWFtCnicdVJNj9owEL33V8wRDsTfcSKtkGApXVSxu1po91Bx
  8BI3tRriKDFS+fcdm6allarIE2Vm/N6bN2ElUOAUBAPOgFH84MC4BC6AS4UHlNTv7u6APPe+Oh9t
  D5Pdd2fI82oNp1xOYT5P5Xt/bgMoIB9dNcAXYBKRX4ClKFJUKeoUD0D2l84irKntMIIst0AefX8y
  DZCjwctj3gx27RGfLHpnmu0eyMoOR9tWpg2xEBm5GJHft0dfubYGsqlsG1y4zB6A7M5vIVFGYoov
  /6l12GiBXTX9UpSI/k98v1ntLkOwp0371UNseuor20e6yUg3BfJiazeE/gKTReXf7DTyd11jT9iB
  bPN5Qtr7D5vV1nR/lOJkr1hPKuKMveuC7+MiksTfQ+Dl2BIl87+Uk1f0guLRikJ8uNZZITgVaI0o
  YoHzjOW61JjIY0KpPGOKi8ih8B84QMGg4Df5QmGbECKjVBfsH9DD6NYiriRASVXGtZASVZruwbr6
  WwDNVFZgPxtXF2DGGctKJmmO0htTDyCvMyyX/gfyzfJcZkohI8wEl5mmGvk45UWWmBkVOmO0xKEO
  14tr16AbLL96FTOP5mRvlrcJpnHHRVs3Npq8wzV+BonKykIizI2PN97jdD8B/PnZl2VuZHN0cmVh
  bQplbmRvYmoKMSAwIG9iago8PCAvQ29udGVudHMgMiAwIFIgL01lZGlhQm94IFsgMCAwIDYxMiA3
  OTIgXSAvUGFyZW50IDIwIDAgUiAvUmVzb3VyY2VzIDw8IC9FeHRHU3RhdGUgPDwgL0cwIDIxIDAg
  UiA+PiAvRm9udCA8PCAvRjAgMjIgMCBSID4+IC9Qcm9jU2V0cyBbIC9QREYgL1RleHQgL0ltYWdl
  QiAvSW1hZ2VDIC9JbWFnZUkgXSA+PiAvVHlwZSAvUGFnZSA+PgplbmRvYmoKMiAwIG9iago8PCAv
  RmlsdGVyIC9GbGF0ZURlY29kZSAvTGVuZ3RoIDIwNSA+PgpzdHJlYW0KeJytUdEKwjAMfO9X5FnY
  lmRLu4H4IOielYIfoG4gTHD+P5iu4hQUHUi4Nj1y16QlQI2EdHEVw74zFxMYSzwQ/dHsZnBWNnUy
  1MY9uVcThNjWEJO+NVmN0F7VhFAslGRjokbNJxIHRrjEyGw0ntuglOVfncijExIeyMri2AgGh5io
  w9KbbK2lXIJvDI1vRax3+85UVg/+AHNEtojCClI4hSAWhSLwbgH+ZFb+u6O4Ks4VXTH/WWlJ0px4
  mrbQR3idY4KK3srC790AMQd34WVuZHN0cmVhbQplbmRvYmoKMyAwIG9iago8PCAvQ29udGVudHMg
  NCAwIFIgL01lZGlhQm94IFsgMCAwIDYxMiA3OTIgXSAvUGFyZW50IDIwIDAgUiAvUmVzb3VyY2Vz
  IDw8IC9FeHRHU3RhdGUgPDwgL0cwIDIxIDAgUiA+PiAvRm9udCA8PCAvRjAgMjIgMCBSID4+IC9Q
  cm9jU2V0cyBbIC9QREYgL1RleHQgL0ltYWdlQiAvSW1hZ2VDIC9JbWFnZUkgXSA+PiAvVHlwZSAv
  UGFnZSA+PgplbmRvYmoKNCAwIG9iago8PCAvRmlsdGVyIC9GbGF0ZURlY29kZSAvTGVuZ3RoIDIw
  NyA+PgpzdHJlYW0KeJytUdEKwjAMfO9X5FnYTLKl7UB8EHTPSsEPUDcQJjj/H0xXcAqKDiRcmx65
  I0kJUCMjPVzFcOjM1UTGEg9EfzL7GVyUzZ0MtenOSHwZywli7GpISd+aeY3Q3tSFSU08WSAUG52a
  TyQOjLDHxGw1nvugnOVvrcijFUY/kJXFsROMDilRh1Uw8w0CsYfQGBq3xUVBEDpTWX2EIywQ2SIK
  K0jhFIJYlorIuyWEs1mH747iqjRYcsXiZ6UlyQviaVrRJbzOMUFFb2Xx++7rZngmZW5kc3RyZWFt
  CmVuZG9iago1IDAgb2JqCjw8IC9Db250ZW50cyA2IDAgUiAvTWVkaWFCb3ggWyAwIDAgNjEyIDc5
  MiBdIC9QYXJlbnQgMjAgMCBSIC9SZXNvdXJjZXMgPDwgL0V4dEdTdGF0ZSA8PCAvRzAgMjEgMCBS
  ID4+IC9Gb250IDw8IC9GMCAyMiAwIFIgPj4gL1Byb2NTZXRzIFsgL1BERiAvVGV4dCAvSW1hZ2VC
  IC9JbWFnZUMgL0ltYWdlSSBdID4+IC9UeXBlIC9QYWdlID4+CmVuZG9iago2IDAgb2JqCjw8IC9G
  aWx0ZXIgL0ZsYXRlRGVjb2RlIC9MZW5ndGggMjA3ID4+CnN0cmVhbQp4nK1RywoCMQy89ytyFnZN
  0k3aBfEgqGel4Af4AkFB/X8w3YKroKggYdp0yAxJSoAWFdkRWob10Z1dZpS4Iy5btxrAydg6SFdb
  7op90FxOkGM5h5Jc9m44R9hfzcWTRoikQCianXbvSOwY4YiFWVg89kE1y99akXsrrE1Htop9J5gd
  SmIOk+SGMwTiCGnnqN+W9zFAOrpW7ZE2MEJkRRQ2kCEYBLFpDJkPY0gHN02fHSW0ZbDiiv5rpZLU
  nvg3rdgSnuf4QUUvZfn7bk56eHdlbmRzdHJlYW0KZW5kb2JqCjcgMCBvYmoKPDwgL0NvbnRlbnRz
  IDggMCBSIC9NZWRpYUJveCBbIDAgMCA2MTIgNzkyIF0gL1BhcmVudCAyMCAwIFIgL1Jlc291cmNl
  cyA8PCAvRXh0R1N0YXRlIDw8IC9HMCAyMSAwIFIgPj4gL0ZvbnQgPDwgL0YwIDIyIDAgUiA+PiAv
  UHJvY1NldHMgWyAvUERGIC9UZXh0IC9JbWFnZUIgL0ltYWdlQyAvSW1hZ2VJIF0gPj4gL1R5cGUg
  L1BhZ2UgPj4KZW5kb2JqCjggMCBvYmoKPDwgL0ZpbHRlciAvRmxhdGVEZWNvZGUgL0xlbmd0aCAx
  OTggPj4Kc3RyZWFtCnicrVDBCsIwDL33K3IWtiVp024gHgTdWSn4AeoGwgTn/4PpCs6DogcJr0kf
  yetrCFCjID1Cw3AczM0kxhNPxHg2hwVclS2DTL05F5Z8ndoJUuxbyMXYm6pF6O+q4pgd1OSBUHxS
  6j6RODHCNWZmp/Hqg0qWv1mRpxXL+eHG4+wEk0IuVGEdTbVFIK4hdobmbTnnLMTBNF4v8QRLRPaI
  wgpSBIUgOqdIfFhBvJhN/K4oockfy6pof570JKUlfjebNvoAgENiXmVuZHN0cmVhbQplbmRvYmoK
  OSAwIG9iago8PCAvVHlwZSAvWFJlZiAvTGVuZ3RoIDMzIC9GaWx0ZXIgL0ZsYXRlRGVjb2RlIC9E
  ZWNvZGVQYXJtcyA8PCAvQ29sdW1ucyA0IC9QcmVkaWN0b3IgMTIgPj4gL1cgWyAxIDIgMSBdIC9T
  aXplIDEwIC9JRCBbPDk4ZmY5YThiNDU3NTE2Mzg4ZGY4MTBiYTVlMGVhZDBkPjw5OGZmOWE4YjQ1
  NzUxNjM4OGRmODEwYmE1ZTBlYWQwZD5dID4+CnN0cmVhbQp4nGNiAAImRttaBiYGxvMgQgTOEkNh
  MYBYTLwMAG6SBGEKZW5kc3RyZWFtCmVuZG9iagogICAgICAgICAgICAgICAgICAgICAgICAgICAg
  ICAgICAgICAgIApzdGFydHhyZWYKMjE2CiUlRU9GCg==`;
  @Input()
  docs = [
    {
      type: 'blob',
      content: this.content,
    },
    {
      type: 'url',
      content: 'https://www.youtube.com/watch?v=KhzGSHNhnbI',
    },
  ];
  @Input() email = '';
  @Input() title = 'title blob';
  @Input() label = ' Please review and accept your ' + this.title;
  @Input() contractID;
  @Input() docCode;
  @Input() codeForRead;
  @Input() codeForDownload;
  @Input() codeForAccept;

  @Output() download = new EventEmitter();
  @Output() agreed = new EventEmitter();
  @Output() readed = new EventEmitter();
  fileURL: any;
  isDownloaded = false;
  isReaded = false;
  isAgreed = '';
  scroll$: any;
  @ViewChild('iframe', { static: false }) iframe: ElementRef;
  constructor(private domSanitizer: DomSanitizer, private renderer: Renderer2) {}

  ngOnInit() {}

  isAgreedAgreement() {
    if (this.isAgreed === 'yes') {
      this.agreed.emit(true);
    } else if (this.isAgreed === 'no') {
      this.agreed.emit(false);
    }
  }

  downloadFile(evt) {
    this.download.emit(true);
  }
}
