<div class="rel invitQuickView" *ngIf="invitation" @onChangeAnimation style="margin-bottom: 10px">
  <mat-dialog-content class="animate">
    <div class="card-header primary-gradient-img">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear mat-button white" matDialogClose>
        <mat-icon>clear</mat-icon>
      </button>

      <!-- patient name and basic details -->
      <div class="row clearfix">
        <app-ep-document-view-image
          class="userIcons pull-left"
          [defaultPicture]="
            'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
          "
          [link]="profileLink"
        >
        </app-ep-document-view-image>
        <h2 class="sm-h2 pull-left rm-mb">
          {{ invitation.FirstName | lowercase | ucfirst }} {{ invitation.LastName | lowercase | ucfirst }}
          <span class="subLabel"> Last Activity: {{ lastActivity | customDate }} </span>
        </h2>
      </div>
      <div class="row clearfix"></div>
      <div class="action-button">
        <button class="actionMenuToggle" [matMenuTriggerFor]="menu" mat-raised-button>
          Actions
          <mat-icon>more_vert</mat-icon>
        </button>
        <!-- context menu -->
        <mat-menu #menu="matMenu">
          <button
            *ngIf="invitation['ID'] && invitation['Campaign_Key']"
            (click)="openCampaign(invitation['Campaign_Key'])"
            mat-menu-item
          >
            <mat-icon>language</mat-icon>
            <span>View Campaign</span>
          </button>

          <button
            *ngIf="isPromoterOrAdmin == true"
            (click)="openCustomerDialog()"
            mat-menu-item
            matTooltip="Invitation link"
          >
            <mat-icon>link</mat-icon>
            <span>Invitation Link</span>
          </button>
          <button *ngIf="isPromoterOrAdmin == true && invitation['ID']" (click)="invitationLog()" mat-menu-item>
            <mat-icon class="fa fa-list-alt"></mat-icon>
            <span>Invitation Events</span>
          </button>

          <button (click)="createNote(invitation['Prospect_key'], invitation['ID'])" mat-menu-item>
            <mat-icon>note_add</mat-icon>
            <span>Create Note</span>
          </button>

          <button (click)="viewNotes(invitation['Prospect_key'])" mat-menu-item>
            <mat-icon class="fa fa-list-alt"></mat-icon>
            <span>View notes</span>
          </button>

          <button
            *ngIf="
              isPromoterOrAdmin == true &&
              invitation['Prospect_key'] &&
              invitation['Prospect_key'] != '00000000000000000000000000000000' &&
              invitation['Prospect_key'] != '20202020202020202020202020202020'
            "
            (click)="patientLog()"
            mat-menu-item
          >
            <mat-icon class="fa fa-list-alt"></mat-icon>
            <span>View {{ "KEYWORD.patient" | translate }} logs</span>
          </button>
          <button *ngIf="isPromoterOrAdmin == true && invitation['contractID']" (click)="contractLog()" mat-menu-item>
            <mat-icon class="fa fa-list-alt"></mat-icon>
            <span>View Contract logs</span>
          </button>
          <button
            *ngIf="
              invitation['Prospect_key'] &&
              invitation['Prospect_key'] != '00000000000000000000000000000000' &&
              invitation['Prospect_key'] != '20202020202020202020202020202020'
            "
            (click)="viewPatient()"
            mat-menu-item
            matTooltip="{{ 'KEYWORD.patient' | translate | titlecase }} Profile"
          >
            <mat-icon>face</mat-icon>
            <span>{{"KEYWORD.patient" | translate | titlecase}} Profile</span>
          </button>
          <button
            *ngIf="
              invitation['Merchant_Key'] &&
              cardID &&
              cardID != invitation['Merchant_Key'] &&
              invitation['Merchant_Key'] != '00000000000000000000000000000000'
            "
            (click)="viewMerchant()"
            mat-menu-item
            matTooltip="Merchant profile"
          >
            <mat-icon>person</mat-icon>
            <span>Merchant Profile</span>
          </button>
          <button
            *ngIf="
              invitation['Status.Invitation.Short_NE'] &&
              (invitation['Status.Invitation.Short_NE'] == 'Not viewed' ||
                invitation['Status.Invitation.Short_NE'] == 'Viewed' ||
                (invitation['Status.Invitation.Short_NE'] &&
                  invitation['Status.Invitation.Short_NE'].includes('Defered')))
            "
            (click)="resendInvitation()"
            mat-menu-item
            matTooltip="Resend Invitation"
          >
            <mat-icon>refresh</mat-icon>
            <span>Resend Invitation</span>
          </button>
          <button
            *ngIf="invitation['contactID'] && invitation['contactID'] != '00000000000000000000000000000000'"
            (click)="contractDetails()"
            mat-menu-item
            matTooltip="View Contract"
            matTooltip="View Contract "
          >
            <mat-icon>description</mat-icon>
            <span>Contract Details</span>
          </button>
          <button
            *ngIf="invitation['Product_Key'] && invitation['Product_Key'] != '00000000000000000000000000000000'"
            (click)="openProductViewDialog()"
            mat-menu-item
            matTooltip="View Product "
          >
            <mat-icon>business_center</mat-icon>
            <span>View Product</span>
          </button>
          <!-- <button mat-menu-item matTooltip="View Customer" (click)="viewCustomer()"
            *ngIf="invitation['ExistingPatientID'] &&  invitation['ExistingPatientID']!='' &&  invitation['ExistingPatientID']!='00000000000000000000000000000000'">
            <mat-icon>person</mat-icon>
            <span>View Customer</span>
          </button> -->
          <button *ngIf="invitation['Email']" (click)="contact()" mat-menu-item matTooltip="Contact Customer">
            <mat-icon>contact_mail</mat-icon>
            <span>Contact Customer</span>
          </button>

          <div>
            <button (click)="cloneInvitation()" mat-menu-item>
              <mat-icon>file_copy</mat-icon>
              <span>Clone</span>
            </button>
          </div>

          <button
            *ngIf="
              invitation['Voided'] == '0' &&
              invitation['Status.Invitation.Short_NE'] != 'Submitted' &&
              invitation['Status.Application.Short_NE']?.indexOf('Contract -') == -1 &&
              invitation['Status.Invitation.Short_NE'] != 'Proceeded'
            "
            (click)="deleteInvitation()"
            mat-menu-item
            matTooltip="Delete Invitation"
          >
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
          </button>
        </mat-menu>
      </div>
    </div>

    <!-- row - dentist -->

    <div
      class="row clearfix grey animate rel"
      *ngIf="
        invitation['DentistContact_key'] &&
        invitation['DentistContact_key'] != '00000000000000000000000000000000' &&
        isPromoterOrAdmin == true
      "
    >
      <app-dentist-view
        [dentistID]="invitation['DentistContact_key']"
        [embedded]="true"
        [displayAdress]="false"
        [displayPhone]="false"
        [actionView]="false"
        displayEmail="false"
        displayActions="false"
      ></app-dentist-view>

      <!-- <div class="pull-right">
        <span class="pull-right status-box list-label primary-gradient" *ngIf="invitation['Voided']=='0'">{{progress}}
        </span>
        <span *ngIf="invitation['Voided']=='1'" class="pull-right status-box list-label"
          [style.backgroundColor]="'#EF4727'">
          {{"Deleted" | translate}}
        </span>
      </div> -->
    </div>

    <!-- INVITATION STATUS BAR -->
    <div
      class="applicationStatusBar animate"
      [hidden]="invitation['Product.Label.Marketing'] && invitation['Product.Label.Marketing'] != ''"
    >
      <div class="row clearfix">
        <div class="fifty-percent">
          <h4 class="primary-color sm-h4 rm-mb">
            <mat-icon class="fas fa-pen-alt"></mat-icon>
            Invitation
          </h4>
        </div>
        <div class="fifty-percent right">
          <h4 class="primary-color sm-h4 rm-mb">
            <span class="pull-left">
              <mat-icon class="fas fa-arrow-alt-circle-right"></mat-icon> Status - {{ StepInv }}
            </span>
            <!-- <span class="pull-right status-box list-label" [style.background]="invitation['Status.Invitation.Colour'] || '#b4bcc1'"
              *ngIf="invitation['Voided']=='0'">{{progressInv}}
            </span> -->
            <!-- <span *ngIf="invitation['Voided']=='1'" class="pull-right status-box list-label"
              [style.backgroundColor]="'#EF4727'">
              {{"Deleted" | translate}}
            </span> -->
          </h4>
        </div>
      </div>

      <section class="progress-section rel" *ngIf="!invitation['creditRemaining']">
        <div class="prog-marker s-1 invBar">
          Sent
        </div>
        <div class="prog-marker s-2 invBar">
          Delivered
        </div>
        <div class="prog-marker s-3 invBar">
          Viewed
        </div>
        <div class="prog-marker s-4 invBar">
          Decision Made
        </div>
        <div class="prog-marker s-5 invBar">
          Finish
        </div>
        <mat-progress-bar
          class="mt progress-margin"
          [color]="colorInv"
          [mode]="modeInv"
          [value]="valueInv"
          [bufferValue]="bufferValueInv"
        >
        </mat-progress-bar>
      </section>
      <hr/>
    </div>

    <!-- APPLICATION STATUS BAR (shows when customer proceeds with finance, hide when application complete) -->
    <div
      class="applicationStatusBar animate"
      *ngIf="invitation['Product.Label.Marketing'] && invitation['Product.Label.Marketing'] != ''"
    >
      <div class="row clearfix">
        <div class="fifty-percent">
          <h4 class="primary-color sm-h4 rm-mb">
            <mat-icon class="fas fa-pen-alt"></mat-icon>
            ApplicationProgressLabel
          </h4>
        </div>
        <div class="fifty-percent right">
          <h4 class="primary-color sm-h4 rm-mb">
            <span class="pull-left">
              <mat-icon class="fas fa-arrow-alt-circle-right"></mat-icon> Status - {{ Step }}
            </span>
          </h4>
        </div>
      </div>

      <section class="progress-section rel" *ngIf="!invitation['creditRemaining']">
        <div class="prog-marker s-1 appBar">Commenced</div>
        <div class="prog-marker s-2 appBar">
          Submitted
        </div>
        <div class="prog-marker s-3 appBar">
          Credit Approval
        </div>
        <div class="prog-marker s-4 appBar">
          Finalised
        </div>
        <div class="prog-marker s-5 appBar">
          Contract Activated
        </div>
        <div class="prog-marker s-6 appBar">
          <mat-icon class="fas fa-flag-checkered"></mat-icon>
          Finish
        </div>
        <mat-progress-bar
          class="mt progress-margin"
          [color]="color"
          [mode]="mode"
          [value]="value"
          [bufferValue]="bufferValue"
        >
        </mat-progress-bar>
      </section>

      <hr/>
    </div>

    <!-- row - invitation -->
    <div class="row clearfix rel">
      <div class="fifty-percent">
        <h4 class="primary-color sm-h4 rm-mb">
          <mat-icon class="fas fa-paper-plane"></mat-icon>
          Invitation
        </h4>
        <div class="row clearfix">
          <p class="rm-mt pull-left">Email status:</p>
          <p class="rm-mt pull-right">
            <span
              class="status-box list-label"
              *ngIf="invitation['Status.Email.Short_NE']"
              [style.backgroundColor]="invitation['Status.Email.Colour'] || '#b4bcc1'"
            >{{ invitation['Status.Email.Short_NE'] }}
            </span>
          </p>
        </div>
        <div class="row clearfix">
          <p class="rm-mt pull-left">Created At </p>
          <p class="rm-mt pull-right">
            <strong>{{ invitation['DateTimeCreated'] | customDateTime }}</strong>
            <span
              class="pill"
              *ngIf="invitation['DateTimeCreated']"
              [style.background]="util.daysPastColor(invitation['DateTimeCreated'])"
            >
              {{ invitation['DateTimeCreated'] | TimeLabelPast }} ago
            </span>
          </p>
        </div>
      </div>

      <div class="fifty-percent right">
        <h4 class="primary-color sm-h4 rm-mb">
          <mat-icon class="fas fa-notes-medical"></mat-icon>
          {{ "KEYWORD.patient" | translate | titlecase }} card - Response
        </h4>
        <div class="row clearfix">
          <p class="rm-mt pull-left">{{ "KEYWORD.patient" | translate | titlecase }} card View Date:</p>
          <p class="rm-mt pull-right" *ngIf="emailEvent['viewedTime']">
            <strong>{{ emailEvent['viewedTime'] | customDateTime }}</strong>
          </p>
          <p class="rm-mt pull-right" *ngIf="emailEvent['viewedTime'] == null">
            <strong>...</strong>
          </p>
        </div>
        <div class="row clearfix">
          <div class="row clearfix" *ngIf="respondEvent['respondInitStatus'] && !respondEvent['respondFinStatus']">
            <p class="rm-mt pull-left">Initial response:</p>
            <p class="rm-mt pull-right" *ngIf="respondEvent['respondInitStatus']">
              <span
                class="status-box list-label"
                [style.backgroundColor]="invitation['Status.Invitation.Colour'] || '#b4bcc1'"
              >{{ respondEvent['respondInitStatus'] }}
              </span>
            </p>
            <p class="rm-mt pull-right" *ngIf="respondEvent['respondInitStatus'] == null">
              <strong>...</strong>
            </p>
          </div>
          <!-- <div class="row clearfix offset">
            <p class="rm-mt pull-left small">{{"Response Date" | translate}}:
            </p>
            <p class="rm-mt pull-right small" *ngIf="respondEvent['respondInitDate']">
              <strong>{{respondEvent['respondInitDate'] | customDateTime}}</strong>
            </p>
            <p class="rm-mt pull-right small" *ngIf="respondEvent['respondInitDate']==null">
              <strong>...</strong>
            </p>
          </div> -->
          <!-- <div class="row clearfix offset">
            <p class="rm-mt pull-left small">{{"Time taken to respond" | translate}}:
            </p>
            <p class="rm-mt pull-right small">
              <strong>{{util.convertSec(respondEvent['respondInitElapsedSince']) || '...'}}</strong>
            </p>
          </div> -->
        </div>
        <div class="row clearfix">
          <div class="row clearfix" *ngIf="respondEvent['respondFinStatus']">
            <p class="rm-mt pull-left">Final response:</p>
            <p class="rm-mt pull-right" *ngIf="respondEvent['respondFinStatus']">
              <span
                class="status-box list-label"
                [style.backgroundColor]="invitation['Status.Invitation.Colour'] || '#b4bcc1'"
              >{{ respondEvent['respondFinStatus'] }}
              </span>
            </p>
            <p class="rm-mt pull-right" *ngIf="respondEvent['respondFinStatus'] == null">
              <strong>...</strong>
            </p>
          </div>
          <!-- <div class="row clearfix offset">
            <p class="rm-mt pull-left small">{{"Response Date" | translate}}:
            </p>
            <p class="rm-mt pull-right small" *ngIf="respondEvent['respondFinDate']">
              <strong>{{respondEvent['respondFinDate'] | customDateTime}}</strong>
            </p>
            <p class="rm-mt pull-right small" *ngIf="respondEvent['respondFinDate']==null">
              <strong>...</strong>
            </p>
          </div>
          <div class="row clearfix offset">
            <p class="rm-mt pull-left small">{{"Time taken to respond" | translate}}:
            </p>
            <p class="rm-mt pull-right small">
              <strong>{{util.convertSec(respondEvent['respondFinElapsedSince']) || '...'}}</strong>
            </p>
          </div> -->
        </div>
      </div>
    </div>

    <hr/>

    <!-- row - treatment -->
    <div class="row clearfix rel">
      <div class="fifty-percent">
        <div class="row clearfix">
          <h4 class="primary-color sm-h4 rm-mb">
            <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
            {{"KEYWORD.Treatment" | translate}}<span *ngIf="false">: Category - Type - Brand </span>
          </h4>
        </div>
        <div class="row clearfix">
          <p class="pull-left rm-mt">{{ "KEYWORD.treatment" | translate | titlecase }} Value</p>
          <p class="pull-right rm-mt">
            <strong>{{ invitation['treatment.Value'] | customCurrency }}</strong>
          </p>
        </div>
      </div>

      <div class="fifty-percent merch right">
        <div class="row clearfix">
          <h4 class="primary-color sm-h4 rm-mb">
            <mat-icon class="fas fa-edit"></mat-icon>
            Application
          </h4>
        </div>
        <div class="row clearfix">
          <p class="rm-mt pull-left">Status:</p>
          <div class="pull-right">
            <span class="pull-right status-box list-label primary-gradient" *ngIf="invitation['Voided'] == '0'"
            >{{ progress }}
            </span>
            <span
              class="pull-right status-box list-label"
              *ngIf="invitation['Voided'] == '1'"
              [style.backgroundColor]="'#EF4727'"
            >
              Deleted
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- row - finance -->
    <div
      class="row clearfix rel animate"
      *ngIf="invitation['creditRemaining'] && invitation['lastDrawDownDate'] !== null"
    >
      <hr/>
      <h4 class="primary-color sm-h4 rm-mb">
        <mat-icon class="fas fa-hand-holding-usd"></mat-icon>
        Finance
      </h4>
      <div class="row clearfix">
        <div class="half-width">
          <p class="name rm-mt">Amount Financed:</p>
          <p class="name rm-mt">
            <strong>{{ invitation['Quoted.TotalRepayable'] | customCurrency }}</strong>
          </p>
        </div>

        <div class="half-width">
          <p class="name rm-mt">Amount Drawn:</p>
          <p class="name rm-mt">
            <strong>{{ invitation['Quoted.TotalRepayable'] - invitation['creditRemaining'] | customCurrency }}</strong>
          </p>
        </div>
      </div>
      <div class="row clearfix">
        <div class="half-width">
          <p class="name rm-mt">Credit Remaining:</p>
          <p class="name rm-mt">
            <strong>{{ invitation['creditRemaining'] | customCurrency }}</strong>
          </p>
        </div>

        <div class="half-width">
          <p class="name rm-mt">Last Drawdown:</p>
          <p class="name rm-mt">
            <strong>{{ invitation['lastDrawDownDate'] | customDate }}</strong>
          </p>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <div class="drop-buttons">
    <button (click)="fullDetails()" mat-raised-button color="primary">Detailed View</button>
  </div>
</div>
