<div class="profile-container" *ngIf="currentUser && myPractice">
  <div class="profile-content">
    <div class="tab-content clearfix" *ngIf="settings == 'public'">
      <!-- name and personal info -->
      <mat-card class="card full-width profile">
        <div class="card-header primary-gradient-img clearfix stacked">
          <div class="row clearfix">
            <div class="row titleArea clearfix">
              <app-ep-document-view-image class="profile-lead-image" [defaultPicture]="
                  'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/practice-pic.png'
                " [link]="profileLink" tabindex="0" alt="Your default profile image">
              </app-ep-document-view-image>
              <h2 class="summary-header rm-m mt">
                {{ currentUser['Salutation'] }} {{ currentUser['FirstName'] }}
                {{ currentUser['Name'] }}
                <span class="subLabel" *ngIf="currentUser['Occupation']">{{ currentUser['Occupation'] }}</span>
              </h2>
            </div>
          </div>
        </div>

        <div class="grey-row upper mb" *ngIf="
            currentUser['URL'] ||
            currentUser['Facebook'] ||
            currentUser['Twitter'] ||
            currentUser['LinkedIn'] ||
            currentUser['Instagram'] ||
            currentUser['Pinterest'] ||
            currentUser['Tumblr'] ||
            currentUser['Vimeo']
          ">
          <div class="row clearfix socials">
            <!-- open social links in new tab -->
            <button *ngIf="currentUser['URL']" (click)="openSocialLink(currentUser['URL'])" mat-icon-button
                    matTooltip="Visit URL page">
              <mat-icon class="fas fa-globe"></mat-icon>
            </button>
            <button *ngIf="currentUser['Facebook']" (click)="openSocialLink(currentUser['Facebook'])" mat-icon-button
                    matTooltip="Visit Facebook page">
              <mat-icon class="fab fa-facebook"></mat-icon>
            </button>
            <button *ngIf="currentUser['Twitter']" (click)="openSocialLink(currentUser['Twitter'])" mat-icon-button
                    matTooltip="Visit Twitter page">
              <mat-icon class="fab fa-twitter"></mat-icon>
            </button>
            <button *ngIf="currentUser['LinkedIn']" (click)="openSocialLink(currentUser['LinkedIn'])" mat-icon-button
                    matTooltip="Visit LinkedIn page">
              <mat-icon class="fab fa-linkedin"></mat-icon>
            </button>
            <button *ngIf="currentUser['Instagram']" (click)="openSocialLink(currentUser['Instagram'])" mat-icon-button
                    matTooltip="Visit Instagram page">
              <mat-icon class="fab fa-instagram"></mat-icon>
            </button>
            <button *ngIf="currentUser['Pinterest']" (click)="openSocialLink(currentUser['Pinterest'])" mat-icon-button
                    matTooltip="Visit Pinterest page">
              <mat-icon class="fab fa-pinterest"></mat-icon>
            </button>
            <button *ngIf="currentUser['Tumblr']" (click)="openSocialLink(currentUser['Tumblr'])" mat-icon-button
                    matTooltip="Visit Tumblr page">
              <mat-icon class="fab fa-tumblr"></mat-icon>
            </button>
            <button *ngIf="currentUser['Vimeo']" (click)="openSocialLink(currentUser['Vimeo'])" mat-icon-button
                    matTooltip="Visit Vimeo page">
              <mat-icon class="fab fa-vimeo"></mat-icon>
            </button>
          </div>
        </div>

        <div class="row clearfix">
          <div class="row clearfix mb" *ngIf="showProfileGallery == true">
            <!-- Display user images as gallery (if more than one) -->
            <app-gallery [inCard]="true" [canPreview]="true"></app-gallery>

            <!-- Display user images as image (if only one ) -->
            <!-- <app-ep-document-view-image class="profile-inner-image" [hideZoomButton]="false"
                [defaultPicture]="'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/practice-pic.png'" [document]="profilePic" tabindex="0"
                alt="Your default profile image">
              </app-ep-document-view-image> -->
          </div>

          <!-- profile description -->
          <div class="row clearfix bio" *ngIf="currentUser['Description']">
            <read-more [text]="currentUser['Description']" [maxLength]="450"></read-more>
          </div>

          <hr />

          <div class="row clearfix flex flexwrap">
            <div class="block full-width">
              <mat-icon class="pull-left">phone</mat-icon>
              <div class="full-width">
                <label>Phone</label>
                <p class="rm-mt">
                  {{ currentUser['phones.Number'] | customPhone: 'landLine' }}
                </p>
              </div>
            </div>

            <div class="block full-width" *ngIf="currentUser['mobiles.Number']">
              <mat-icon class="pull-left">phone_iphone</mat-icon>
              <div class="full-width">
                <label>Mobile</label>
                <p class="rm-mt">
                  {{ currentUser['mobiles.Number'] | customPhone }}
                </p>
              </div>
            </div>

            <div class="block full-width">
              <mat-icon class="pull-left">email</mat-icon>
              <div class="full-width pointer"
                   (click)="openEmailLink(currentUser['emails.Email'], currentUser['CalculatedName'])">
                <label>Email</label>
                <p class="rm-mt">{{ currentUser['emails.Email'] }}</p>
              </div>
            </div>
          </div>
        </div>
      </mat-card>

      <!--  practice preview -->

      <mat-card class="card full-width profile">
        <div class="card-header primary-gradient-img clearfix stacked">
          <div class="row clearfix">
            <div class="row titleArea clearfix">
              <app-ep-document-view-image class="profile-lead-image2" [hideZoomButton]="true" [defaultPicture]="
                  'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/practice-pic.png'
                " [link]="practiceLink" tabindex="0" alt="Your default profile image">
              </app-ep-document-view-image>
              <h2 class="summary-header rm-m mt">
                {{ myPractice['TradingAs'] }}
                <span class="subLabel" *ngIf="myPractice['addresses.Calculated']">{{
                  myPractice['addresses.Calculated']
                  }}</span>
              </h2>
            </div>
          </div>
        </div>

        <div class="grey-row upper mb" *ngIf="
            myPractice['URL'] ||
            myPractice['Facebook'] ||
            myPractice['Twitter'] ||
            myPractice['LinkedIn'] ||
            myPractice['Instagram'] ||
            myPractice['Pinterest'] ||
            myPractice['Tumblr'] ||
            myPractice['Vimeo']
          ">
          <div class="row clearfix socials">
            <!-- open social links in new tab -->
            <button *ngIf="myPractice['URL']" (click)="openSocialLink(myPractice['URL'])" mat-icon-button
                    matTooltip="Visit URL page">
              <mat-icon class="fas fa-globe"></mat-icon>
            </button>
            <button *ngIf="myPractice['Facebook']" (click)="openSocialLink(myPractice['Facebook'])" mat-icon-button
                    matTooltip="Visit Facebook page">
              <mat-icon class="fab fa-facebook"></mat-icon>
            </button>
            <button *ngIf="myPractice['Twitter']" (click)="openSocialLink(myPractice['Twitter'])" mat-icon-button
                    matTooltip="Visit Twitter page">
              <mat-icon class="fab fa-twitter"></mat-icon>
            </button>
            <button *ngIf="myPractice['LinkedIn']" (click)="openSocialLink(myPractice['LinkedIn'])" mat-icon-button
                    matTooltip="Visit LinkedIn page">
              <mat-icon class="fab fa-linkedin"></mat-icon>
            </button>
            <button *ngIf="myPractice['Instagram']" (click)="openSocialLink(myPractice['Instagram'])" mat-icon-button
                    matTooltip="Visit Instagram page">
              <mat-icon class="fab fa-instagram"></mat-icon>
            </button>
            <button *ngIf="myPractice['Pinterest']" (click)="openSocialLink(myPractice['Pinterest'])" mat-icon-button
                    matTooltip="Visit Pinterest page">
              <mat-icon class="fab fa-pinterest"></mat-icon>
            </button>
            <button *ngIf="myPractice['Tumblr']" (click)="openSocialLink(myPractice['Tumblr'])" mat-icon-button
                    matTooltip="Visit Tumblr page">
              <mat-icon class="fab fa-tumblr"></mat-icon>
            </button>
            <button *ngIf="myPractice['Vimeo']" (click)="openSocialLink(myPractice['Vimeo'])" mat-icon-button
                    matTooltip="Visit Vimeo page">
              <mat-icon class="fab fa-vimeo"></mat-icon>
            </button>
          </div>
        </div>

        <div class="row clearfix">
          <div class="row clearfix mb" *ngIf="showPracticeGallery == true">
            <!-- Display user images as gallery (if more than one) -->
            <app-gallery [inCard]="true" [canPreview]="true"></app-gallery>

            <!-- Display user images as image (if only one ) -->
            <!-- <app-ep-document-view-image class="profile-inner-image" [hideZoomButton]="false"
                  [defaultPicture]="'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/practice-pic.png'" [document]="profilePic" tabindex="0"
                  alt="Your default profile image">
                </app-ep-document-view-image> -->
          </div>

          <!-- profile description -->
          <div class="row clearfix bio" *ngIf="myPractice['Description']">
            <read-more [text]="myPractice['Description']" [maxLength]="450"></read-more>
          </div>

          <hr />

          <div class="row clearfix flex flexwrap">
            <div class="block full-width">
              <mat-icon class="pull-left">phone</mat-icon>
              <div class="full-width">
                <label>Phone</label>
                <p class="rm-mt">
                  {{ myPractice['phones.Number'] | customPhone: 'landLine' }}
                </p>
              </div>
            </div>

            <div class="block full-width" *ngIf="myPractice['mobiles.Number']">
              <mat-icon class="pull-left">phone_iphone</mat-icon>
              <div class="full-width">
                <label>Mobile</label>
                <p class="rm-mt">
                  {{ myPractice['mobiles.Number'] | customPhone }}
                </p>
              </div>
            </div>

            <div class="block full-width">
              <mat-icon class="pull-left">email</mat-icon>
              <div class="full-width pointer"
                   (click)="openEmailLink(myPractice['emails.Email'], myPractice['TradingAs'])">
                <label>Email</label>
                <p class="rm-mt">{{ myPractice['emails.Email'] }}</p>
              </div>
            </div>
          </div>
        </div>
      </mat-card>

      <!--  change PW -->
    </div>

    <div class="tab-content clearfix" *ngIf="settings == 'personal'">
      <form class="full-width" #personalDetails="ngForm">
        <div class="row clearfix flex flexwrap">
          <mat-card class="card full-width two-third">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">
              Personal Details
            </h3>
            <hr class="mb" />
            <div class="row clearfix">
              <div class="row clearfix flex">
                <div class="icons-col">
                  <mat-icon>person</mat-icon>
                </div>
                <div class="row-content-col">
                  <div class="row clearfix flex inputFlex">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Title</mat-label>
                      <mat-select class="full-width" [(ngModel)]="currentUser['Salutation']" placeholder="Title"
                                  name="title">
                        <mat-option *ngFor="let title of titles" [value]="title.Label">
                          {{ title.Label }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Gender</mat-label>
                      <mat-select class="full-width" [(ngModel)]="currentUser['Gender.Code']" placeholder="Gender"
                                  name="gender">
                        <mat-option *ngFor="let gender of genders" [value]="gender.Code">
                          {{ gender.Label }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="row clearfix flex inputFlex">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>FirstName</mat-label>
                      <input [(ngModel)]="currentUser['FirstName']" name="firstname" matInput placeholder="First Name"
                             required/>
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Middle Name</mat-label>
                      <input [(ngModel)]="currentUser['MiddleName']" name="middlename" matInput
                             placeholder="Middle Name"/>
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Surname</mat-label>
                      <input [(ngModel)]="currentUser['Name']" name="lastname" matInput placeholder="Surname"
                             required/>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="row clearfix flex">
                <div class="icons-col">
                  <mat-icon>today</mat-icon>
                </div>
                <div class="row-content-col">
                  <app-date-input class="full-width" [startView]="'multi-year'" [label]="'Date Of Birth'"
                                  [defaultDate]="currentUser['DateOfBirth']" (selectedDate)="getDate($event)"
                                  name="DateOfBirth">
                  </app-date-input>
                </div>
              </div>

              <div class="row clearfix flex">
                <div class="icons-col">
                  <mat-icon>av_timer</mat-icon>
                </div>
                <div class="row-content-col">
                  <app-time-zone-list [timeZoneCode]="currentUser['TimeZoneCode']"
                                      (getResult)="getResultTimeZone($event)">
                  </app-time-zone-list>
                </div>
              </div>
            </div>
          </mat-card>

          <mat-card class="card full-width third">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">Upload profile picture</h3>
            <hr class="mb" />
            <div class="row clearfix text-center mt">
              <div class="upload-container">
                <app-file-uploader class="text-center clearfix full-width noLowerPad avatarUploader"
                                   [allowedExtensionTypes]="allowedExtensionTypes" [limit]="1" [uploadDirectly]="true"
                                   [isDragable]="true" [keepOriginal]="false" [isFixedAspectRatio]="true"
                                   [resizeToHeight]="400"
                                   [resizeToWidth]="400" (onCompleteAll)="onCompleteAll($event)">
                </app-file-uploader>
                <app-ep-document-view-image class="profile-lead-image2 clearfix sml" [hideZoomButton]="true"
                  [defaultPicture]="
                    'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
                  " [link]="profileLink">
                </app-ep-document-view-image>
              </div>
              <p class="small">
                To upload a profile picture, please drag a
                <strong>.jpg or .png file</strong> into the grey area.
              </p>
            </div>
          </mat-card>

          <mat-card class="card full-width" *ngIf="showProfileDescription == true">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">Profile Description</h3>
            <hr class="mb" />
            <div class="row clearfix">
              <p class="small rm-mb">
                Add some descriptive text about yourself, which will be seen by {{ "KEYWORD.patient" | translate }}s
                and {{ "KEYWORD.dentist" | translate }}s previewing your
                profile.
              </p>
              <app-text-editor-input class="clearfix" [content]="profileDescription" [isPreview]="false" [title]="null"
                                     [isAction]="false" [isMultiView]="false" (getContent)="getProfileContent($event)"
                                     name="editor">
              </app-text-editor-input>
            </div>
          </mat-card>

          <!-- additional -->
          <mat-card class="card full-width third">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">
              Social Media
            </h3>
            <hr class="mb" />

            <div class="row clearfix">
              <div class="row clearfix flex">
                <div class="icons-col">
                  <mat-icon class="fas fa-globe"></mat-icon>
                </div>
                <div class="row-content-col">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Personal Website</mat-label>
                    <input [(ngModel)]="currentUser['URL']" name="URL" matInput
                           placeholder="Please enter the address"/>
                  </mat-form-field>
                </div>
              </div>

              <div class="row clearfix flex">
                <div class="icons-col">
                  <mat-icon class="fab fa-facebook"></mat-icon>
                </div>
                <div class="row-content-col">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Facebook</mat-label>
                    <input [(ngModel)]="currentUser['Facebook']" name="facebook" matInput placeholder="Facebook"/>
                  </mat-form-field>
                </div>
              </div>

              <div class="row clearfix flex">
                <div class="icons-col">
                  <mat-icon class="fab fa-instagram"></mat-icon>
                </div>
                <div class="row-content-col">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Instagram</mat-label>
                    <input [(ngModel)]="currentUser['Instagram']" name="instagram" matInput placeholder="Instagram"/>
                  </mat-form-field>
                </div>
              </div>

              <div class="row clearfix flex">
                <div class="icons-col">
                  <mat-icon class="fab fa-twitter"></mat-icon>
                </div>
                <div class="row-content-col">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Twitter</mat-label>
                    <input [(ngModel)]="currentUser['Twitter']" name="twitter" matInput placeholder="Twitter"/>
                  </mat-form-field>
                </div>
              </div>

              <div class="row clearfix flex">
                <div class="icons-col">
                  <mat-icon class="fab fa-linkedin"></mat-icon>
                </div>
                <div class="row-content-col">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>LinkedIn</mat-label>
                    <input [(ngModel)]="currentUser['LinkedIn']" name="linkedin" matInput placeholder="LinkedIn"/>
                  </mat-form-field>
                </div>
              </div>

              <div class="row clearfix flex">
                <div class="icons-col">
                  <mat-icon class="fab fa-pinterest"></mat-icon>
                </div>
                <div class="row-content-col">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Pinterest</mat-label>
                    <input [(ngModel)]="currentUser['Pinterest']" name="Pinterest" matInput placeholder="Pinterest"/>
                  </mat-form-field>
                </div>
              </div>

              <div class="row clearfix flex">
                <div class="icons-col">
                  <mat-icon class="fab fa-tumblr"></mat-icon>
                </div>
                <div class="row-content-col">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Tumblr</mat-label>
                    <input [(ngModel)]="currentUser['Tumblr']" name="Tumblr" matInput placeholder="Tumblr"/>
                  </mat-form-field>
                </div>
              </div>

              <div class="row clearfix flex">
                <div class="icons-col">
                  <mat-icon class="fab fa-vimeo"></mat-icon>
                </div>
                <div class="row-content-col">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Vimeo</mat-label>
                    <input [(ngModel)]="currentUser['Vimeo']" name="Vimeo" matInput placeholder="Vimeo"/>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <!-- <mat-checkbox name="referredCustomer">Referred from another practice
          </mat-checkbox> -->
          </mat-card>

          <mat-card class="card full-width third"  *ngIf="context!='p4p' && context!='breeze' ">
            <div class="colour-stripe"></div>

            <div style="display: flex; justify-content: space-between">
              <h3 class="sr-title rm-m sm-h3">{{"PHRASE.dentistry practiced" | translate | titlecase}} </h3>
              <div>
                <button (click)="openAddContactDentistryTypesProperty()" mat-icon-button
                        style="display: block; height: 20px; line-height: 20px">
                  <mat-icon> edit_mode</mat-icon>
                </button>
              </div>
            </div>
            <hr class="mb"/>

            <mat-chip-list>
              <mat-chip *ngFor="let contactProfileDentistryType of contactProfileDentistryTypes" [color]="'primary'"
                        selected>{{ contactProfileDentistryType['Label'] }}</mat-chip>
            </mat-chip-list>
          </mat-card>

          <!-- phone numbers and email -->
          <mat-card class="card full-width third">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">
              Contact Details
            </h3>
            <hr class="mb" />
            <div class="row clearfix">
              <div class="row clearfix">
                <app-phone-input-advanced [type]="'current'" [targetID]="currentUser['ID']"
                                          [targetPhoneType]="phoneType" [phoneTypes]="phoneTypes"
                                          [myPhoneNumber]="myPhoneNumber">
                </app-phone-input-advanced>
              </div>
              <div class="row clearfix">
                <app-mobile-input-advanced [type]="'current'" [targetID]="currentUser['ID']"
                                           [targetMobileType]="mobileType" [mobileTypes]="mobileTypes"
                                           [myMobile]="myMobile">
                </app-mobile-input-advanced>
              </div>
              <div class="row clearfix">
                <app-email-input-advanced [type]="'current'" [targetID]="currentUser['ID']"
                                          [targetEmailType]="emailType" [emailTypes]="emailTypes" [myEmail]="myEmail">
                </app-email-input-advanced>
              </div>
            </div>

            <h3 class="sr-title rm-m mt sm-h3">Preferences</h3>
            <hr class="mb" />
            <div class="row clearfix">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Contact Method</mat-label>
                <mat-select [(ngModel)]="currentUser['PreferredContactMethod.Code']" name="contactMethod"
                            placeholder="Contact Method">
                  <mat-option *ngFor="let contactMethod of preferredContactMethodOptions" [value]="contactMethod.Code">
                    {{ contactMethod.Label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Contact Time</mat-label>
                <mat-select [(ngModel)]="currentUser['PreferredContactTime.Code']" name="contactTime"
                            placeholder="Contact Time">
                  <mat-option *ngFor="let contactTime of preferredContactTimeOptions" [value]="contactTime.Code">
                    {{ contactTime.Label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Message Type</mat-label>
                <mat-select [(ngModel)]="currentUser['PreferredMessageType.Code']" name="messageType"
                            placeholder="Message Type">
                  <mat-option *ngFor="let messageType of preferredMessageTypeOptions" [value]="messageType.Code">
                    {{ messageType.Label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <div class="row clearfix">
                <hr />

                <div class="row clearfix flex">
                  <mat-checkbox class="full-width mt" [(ngModel)]="myMarketingConsent" [disableRipple]="true"
                                [labelPosition]="'end'" (click)="$event.preventDefault()" name="electronic">
                    Consents to Marketing Material
                  </mat-checkbox>
                  <p class="full-width" *ngIf="myMarketingConsent == true">
                    {{ currentUser['MarketingConsent.When'] | customDateTime }}
                  </p>
                </div>
              </div>
            </div>
          </mat-card>

          <mat-card class="card full-width">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">
              Address details
            </h3>
            <hr class="mb" />
            <div class="row clearfix">
              <app-address-input-advanced [type]="'current'" [targetID]="currentUser['ID']"
                                          [targetAddressType]="addressType" [addressTypes]="addressTypes"
                                          [myPostCode]="myPostCode"
                                          [myState]="myState" [mySuburb]="mySuburb" [myStreetNumber]="myStreetNumber"
                                          [myStreetName]="myStreetName" [myUnitNumber]="myUnitNumber"
                                          [myStreetType]="myStreetType"
                                          [myLatitude]="myLatitude" [myLongitude]="myLongitude">
              </app-address-input-advanced>
            </div>
          </mat-card>

          <mat-card class="card half-width passchange">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">
              Login Details
            </h3>
            <hr class="mb" />

            <div class="row clearfix">
              <app-password-change></app-password-change>
            </div>
          </mat-card>

          <mat-card class="card half-width passchange">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">Connect social media</h3>
            <p class="small">Update your Social Media link to be able to use it to Login to the application.</p>

            <hr class="mb" />
            <div class="row clearfix text-center mt">
              <app-change-social-media></app-change-social-media>
            </div>
          </mat-card>

          <div class="row clearfix button-row">
            <button class="pull-right" [disabled]="!personalDetails.form.valid" (click)="editProfile()"
                    mat-raised-button color="accent">
              <mat-icon>save</mat-icon>
              Save
            </button>
          </div>
        </div>
      </form>
    </div>

    <div class="tab-content clearfix" *ngIf="settings == 'practice'">
      <form class="full-width" #PracticeDetails="ngForm">
        <div class="row clearfix flex flexwrap">
          <!-- name and personal info -->
          <mat-card class="card full-width two-third">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">Main {{ "KEYWORD.practice" | translate }} details</h3>
            <hr class="mb" />
            <div class="row clearfix">
              <div class="row clearfix flex full-width">
                <div class="icons-col">
                  <mat-icon>store_mall_directory</mat-icon>
                </div>
                <div class="row-content-col flex">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Trading as</mat-label>
                    <input [(ngModel)]="myPractice['TradingAs']" [disabled]="disableEditPractice" name="Trading"
                           matInput placeholder="Trading as" required/>
                  </mat-form-field>
                  <mat-form-field class="full-width" *ngIf="myPractice['CardType'] != 'Commercial - Sole-Trader'"
                                  appearance="outline">
                    <mat-label>Legal entity name </mat-label>
                    <input [(ngModel)]="practiceLegalName" [disabled]="disableEditPractice" name="entity" matInput
                           placeholder="Legal entity name"/>
                  </mat-form-field>
                  <mat-form-field class="full-width" *ngIf="myPractice['CardType'] == 'Commercial - Sole-Trader'"
                                  appearance="outline">
                    <mat-label>Legal entity name </mat-label>
                    <input [(ngModel)]="practiceLegalName" [disabled]="disableEditPractice" name="entity" matInput
                           placeholder="Legal entity name"/>
                  </mat-form-field>
                </div>
              </div>

              <div class="row clearfix flex full-width">
                <div class="icons-col">
                  <mat-icon>av_timer</mat-icon>
                </div>
                <div class="row-content-col">
                  <app-time-zone-list [timeZoneCode]="myPractice['TimeZoneCode']" [disabled]="disableEditPractice"
                                      (getResult)="getResultTimeZoneMerchant($event)">
                  </app-time-zone-list>
                </div>
              </div>

              <div class="row clearfix flex full-width">
                <div class="icons-col">
                  <mat-icon>domain</mat-icon>
                </div>
                <div class="row-content-col flex">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>ABN</mat-label>
                    <input [(ngModel)]="myPractice['ABN']" [disabled]="disableEditPractice" name="ABN" matInput
                           placeholder="ABN"/>
                  </mat-form-field>
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>ACN</mat-label>
                    <input [(ngModel)]="myPractice['ACN']" [disabled]="disableEditPractice" name="ACN" matInput
                           placeholder="ACN"/>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-card>

          <mat-card class="card full-width third">
            <div class="colour-stripe"></div>
            <ng-container>
              <h3 class="sr-title rm-m sm-h3">{{ "KEYWORD.practice" | translate | titlecase}} Image</h3>
              <hr />
            </ng-container>

            <div class="row clearfix text-center upload-area">
              <!-- practice logo -->
              <div class="upload-container avatar">
                <app-file-uploader class="text-center clearfix full-width noLowerPad avatarUploader"
                                   *ngIf="disableEditPractice == false" [allowedExtensionTypes]="allowedExtensionTypes"
                                   [limit]="1"
                                   [uploadDirectly]="true" [isDragable]="true" [keepOriginal]="false"
                                   [isFixedAspectRatio]="true"
                                   [resizeToHeight]="400" [resizeToWidth]="400"
                                   (onCompleteAll)="onCompleteAllPractice($event)">
                </app-file-uploader>
                <app-ep-document-view-image class="profile-lead-image2 sml" [hideZoomButton]="true" [defaultPicture]="
                    'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
                  " [link]="practiceLink">
                </app-ep-document-view-image>
              </div>
              <!-- end practice logo -->
            </div>

            <p class="small" *ngIf="disableEditPractice == false">
              To upload a profile picture, please drag a
              <strong>.jpg or .png file</strong> into the grey area. Best size for image is 512px x 512px.
            </p>
          </mat-card>

          <mat-card class="card full-width third">
            <div class="colour-stripe"></div>
            <ng-container>
              <h3 class="sr-title rm-m sm-h3">{{ "KEYWORD.practice" | translate | titlecase}} Background</h3>
              <hr />
            </ng-container>

            <div class="row clearfix text-center upload-area practice">
              <div class="upload-container practice-image">
                <app-file-uploader class="text-center clearfix practiceImageUploader"
                                   *ngIf="disableEditPractice == false" [allowedExtensionTypes]="allowedExtensionTypes"
                                   [limit]="1"
                                   [uploadDirectly]="true" [isDragable]="true" [keepOriginal]="false" [isCover]="true"
                                   (onCompleteAll)="onCompleteAllPracticeBackground($event)">
                </app-file-uploader>
                <app-ep-document-view-image class="profile-practice-image lrg" [hideZoomButton]="true"
                                            [defaultPicture]="
                    'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/consumer/dentist-portal-header.png)'
                  " [link]="practiceCoverLink">
                </app-ep-document-view-image>
              </div>
              <!-- end practice cover image -->
            </div>

            <p class="small" *ngIf="disableEditPractice == false">
              To upload a profile picture, please drag a
              <strong>.jpg or .png file</strong> into the grey area. Best size for image is 1024px x 512px.
            </p>
          </mat-card>

          <!-- colours for branding -->

          <mat-card class="card third">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">{{ "KEYWORD.practice" | translate | titlecase}} Landing Pages</h3>
            <hr class="mb"/>

            <p class="small full-width flex">
              Unique QR codes: The code or URL will redirect your {{ "KEYWORD.patient" | translate }}s to dynamic
              landing pages personalised to your {{ "KEYWORD.practice" | translate }}. Use these in your marketing
              material, such as brochures, newsletters, email footers, etc.
            </p>

            <div class="row clearfix full-width mr">
              <h3 class="sr-title rm-m sm-h3">{{ "KEYWORD.practice" | translate | titlecase}} Page</h3>
              <hr class="mb"/>

              <app-generate-qr class="qtr-width row" *ngIf="practiceURLQR" [downloadLabel]="'Landing Page'"   [source]="practiceURLQR" [size]="115"
                               [download]="downloadQRCode">
              </app-generate-qr>

              <span class="qrButtons">
                <button (click)="previewLandingPage()" mat-raised-button color="primary">
                  Preview the page
                </button>
                <button (click)="downloadQRCode.emit()" mat-raised-button color="accent">
                  Download QR code
                </button>
              </span>
            </div>

            <div class="row clearfix full-width mr">
              <h3 class="sr-title rm-m sm-h3">{{ "KEYWORD.dental" | translate | titlecase }} Tips and Offers Page</h3>
              <hr class="mb"/>

              <app-generate-qr class="qtr-width row" *ngIf="dentalURLQR"   [downloadLabel]="'Tips and Offers Page'"  [source]="dentalURLQR" [size]="115"
                               [download]="downloadQRCodeDental">
              </app-generate-qr>

              <span class="qrButtons">
                <button (click)="previewDentalPage()" mat-raised-button color="primary">
                  Preview the page
                </button>
                <button (click)="downloadQRCodeDental.emit()" mat-raised-button color="accent">
                  Download QR code
                </button>
              </span>
            </div>

            <hr />
            <div class="full-width clearfix">
              <p class="full-width clearfix small">
                Customise your link by choosing a key word to make your landing pages easy to be shared. Click on Save
                to confirm
              </p>
              <div class="full-width flex">
                <mat-form-field class="full-width clearfix" appearance="outline">
                  <input [(ngModel)]="practiceLandingCode" name="practiceLandingCode" type="text" matInput/>

                  <span class="landingPageLinkCode" *ngIf="publicLink" matPrefix>
                    <i>e.g. {{ publicLink }}/home/</i></span>

                  <mat-icon class="error-icon-signup" *ngIf="practiceLandingCodeError" matSuffix
                            matTooltip="the key  word must be unique">error
                  </mat-icon>

                  <button *ngIf="practiceLandingCodeError != true" [matMenuTriggerFor]="menu" matSuffix mat-button
                          color="primary" matTooltip="Click to copy the link">
                    <mat-icon>file_copy</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button (click)="linkCopy()" mat-menu-item>
                      <span class="mobHide">Copy {{ "KEYWORD.practice" | translate | titlecase}} Page Link</span>
                    </button>
                    <button (click)="linkCopy2()" mat-menu-item>
                      <span class="mobHide">Copy {{ "KEYWORD.dental" | translate | titlecase }} Tips and Offers page
                        Link</span>
                    </button>
                  </mat-menu>
                </mat-form-field>
              </div>
            </div>

            <div class="full-width clearfix mt text-center">
              <button class="ml pull-right"
                [disabled]="practiceLandingCode == practiceLandingCodeOriginal || practiceLandingCodeError == true"
                      (click)="savePracticeLandingCode()" mat-raised-button color="accent">
                <mat-icon>save</mat-icon>
                Save
              </button>
            </div>
          </mat-card>

          <!-- end colours -->

          <!-- communications card -->
          <mat-card class="card half-width comms">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">{{ "KEYWORD.patient" | translate | titlecase }} communication</h3>
            <hr class="mb" />
            <div class="row clearfix">
              <div class="row clearfix flex">
                <div class="icons-col">
                  <mat-icon class="fas fa-reply-all"></mat-icon>
                </div>
                <div class="row-content-col">
                  <app-email-input class="full-width" [email]="merchantReplyToEmail" [label]="'Forwarding address'"
                                   [required]="false" [isDisabled]="disableEditPractice"
                                   (getEmail)="getReplyToEmail($event)"
                                   (validate)="validateReplyToEmailEvent($event)">
                  </app-email-input>
                  <p class="small">
                    Enter an email address here and we will automatically forward you any
                    {{ "KEYWORD.patient" | translate }} responses to communication sent via the platform.
                  </p>
                </div>
              </div>
            </div>
          </mat-card>

          <mat-card class="card full-width third comms">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">
              Email Branding
            </h3>
            <hr class="mb" />

            <p class="small">
              These colours are used in your email campaigns that are sent to your {{"KEYWORD.patient" | translate}}s
              and adds brand recognition
              to these communications.
            </p>
            <div class="row clearfix">
              <div class="row clearfix flex">
                <div class="icons-col">
                  <mat-icon class="fas fa-palette"></mat-icon>
                </div>
                <div class="row-content-col flex">
                  <div class="row clearfix full-width mr ml">
                    <p class="small">Primary colour</p>
                    <app-color-palette class="full-width clearfix row" [coreColor]="primaryColor"
                                       [displayPalette]="displayPalette" [isPalette]="false"
                                       (getCoreColor)="getPrimaryColour($event)">
                    </app-color-palette>
                  </div>
                  <div class="row clearfix full-width mr ml">
                    <p class="small">Secondary colour</p>
                    <app-color-palette class="full-width clearfix row" [coreColor]="secondaryColor"
                                       [displayPalette]="displayPalette" [isPalette]="false"
                                       (getCoreColor)="getSecondaryColour($event)">
                    </app-color-palette>
                  </div>
                </div>
              </div>
            </div>
          </mat-card>
          <!-- end communication cards -->

          <mat-card class="card full-width" *ngIf="showPracticeDescription == true">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">{{ "KEYWORD.practice" | translate | titlecase}} Description</h3>
            <hr class="mb" />
            <div class="row clearfix mt">
              <p class="small rm-mb">
                Add some descriptive text about your {{ "KEYWORD.practice" | translate }} which will be seen by
                {{ "KEYWORD.patient" | translate }}s previewing your profile.
              </p>

              <app-text-editor-input class="clearfix" [content]="practiceDescription" [isPreview]="false" [title]="null"
                                     [isAction]="false" [isMultiView]="false" [hidden]="disableEditPractice"
                                     (getContent)="getPracticeContent($event)" name="editor">
              </app-text-editor-input>

              <div *ngIf="disableEditPractice" [innerHTML]="myPractice['Description'] | safeHtml"></div>
            </div>
          </mat-card>

          <!-- <mat-card
            class="card full-width"
            *ngIf="showPracticeDescription == true"
          >
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">
              {{ "Appointment policies" | translate }}
            </h3>
            <hr class="mb" />
            <div class="row clearfix mt">
              <p class="small rm-mb">
                {{ "Appointment cancelling policy" | translate }}
              </p>

              <app-text-editor-input
                name="editor"
                class="clearfix appintment-cancel"
                [content]="practiceAppointmentPolicy"
                [isPreview]="false"
                [title]="null"
                [isAction]="false"
                [isMultiView]="false"
                (getContent)="getPracticeAppointmentPolicyContent($event)"
                [hidden]="disableEditPractice"
              >
              </app-text-editor-input>

              <div
                *ngIf="disableEditPractice"
                [innerHTML]="
                  myPractice['Appointment_Cancelling_Policy'] | safeHtml
                "
              ></div>

              <div class="full-width mt">
                <p class="pull-left small rm-mb">
                  Notification Period for Cancelation
                </p>
                <mat-form-field class="qtr-width" appearance="outline">
                  <mat-label>Hours </mat-label>
                  <input
                    name="lifetime"
                    type="number"
                    [disabled]="disableEditPractice"
                    matInput
                    placeholder="Hours "
                    [minlength]="1"
                    [(ngModel)]="practiceAppointmentHours"
                  />
                </mat-form-field>
              </div>

              <h3 class="sr-title rm-m sm-h3">SMS Notification</h3>

              <hr class="mb" />
              <div class="row clearfix flex">
                <mat-checkbox
                  class="full-width mt"
                  name="smsConfirmAppointment"
                  [(ngModel)]="smsConfirmAppointment"
                >
                  Send SMS to confirm the appointment
                </mat-checkbox>
                <mat-checkbox
                  class="full-width mt"
                  name="smsAppointmentReminder"
                  [(ngModel)]="smsAppointmentReminder"
                >
                  Send SMS reminder 24 Hrs before treatment
                </mat-checkbox>
              </div>
            </div>
          </mat-card> -->

          <!-- additional -->

          <mat-card class="card full-width third">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">
              Address details
            </h3>
            <hr class="mb" />
            <app-address-input class="full-width" [displayMap]="false" [canClose]="false" [postCode]="practicePostCode"
                               [state]="practiceState" [suburb]="practiceSuburb" [streetNumber]="practiceStreetNumber"
                               [streetName]="practiceStreetName" [unitNumber]="practiceUnitNumber"
                               [streetType]="practiceStreetType"
                               [latitude]="practiceLatitude" [longitude]="practiceLongitude" [isRequired]="false"
                               [isDisabled]="disableEditPractice" (getAdress)="getAdressPractice($event)"
                               (validate)="isAdressPracticeValidEvent($event)">
            </app-address-input>
          </mat-card>

          <mat-card class="card full-width third">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">
              Contact Details
            </h3>
            <hr class="mb" />
            <div class="row clearfix">
              <div class="row clearfix flex">
                <div class="icons-col">
                  <mat-icon>phone</mat-icon>
                </div>
                <div class="row-content-col">
                  <div class="row clearfix">
                    <app-phone-input class="full-width" [isLandLine]="true" [label]="'Phone Number'" [required]="true"
                                     [phoneNumber]="phonePractice" [isDisabled]="disableEditPractice"
                                     (validate)="validatePhonePracticeEvent($event)"
                                     (getPhone)="getPhonePractice($event)">
                    </app-phone-input>
                  </div>
                </div>
              </div>
              <div class="row clearfix flex">
                <div class="icons-col">
                  <mat-icon>phone_android</mat-icon>
                </div>
                <div class="row-content-col">
                  <div class="row clearfix">
                    <app-phone-input class="full-width" [isLandLine]="false" [label]="'Mobile'" [required]="false"
                                     [phoneNumber]="mobilePractice" [isDisabled]="disableEditPractice"
                                     (validate)="validateMobilePracticeEvent($event)"
                                     (getPhone)="getMobilePractice($event)">
                    </app-phone-input>
                  </div>
                </div>
              </div>
              <div class="row clearfix flex">
                <div class="icons-col">
                  <mat-icon>email</mat-icon>
                </div>
                <div class="row-content-col">
                  <div class="row clearfix">
                    <app-email-input class="full-width" [label]="'Email'" [required]="true" [email]="emailPractice"
                                     [isDisabled]="disableEditPractice" (getEmail)="getEmailPractice($event)"
                                     (validate)="validateEmailPracticeEvent($event)">
                    </app-email-input>
                  </div>
                </div>
              </div>
            </div>
          </mat-card>

          <mat-card class="card full-width third">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">
              Social Media
            </h3>
            <hr class="mb" />

            <div class="row clearfix flex">
              <div class="row full-width">
                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon class="fas fa-globe"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Personal Website</mat-label>
                      <input [(ngModel)]="myPractice['URL']" [disabled]="disableEditPractice" name="URL" matInput
                             placeholder="Please enter the address"/>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon class="fab fa-facebook"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Facebook</mat-label>
                      <input [(ngModel)]="myPractice['Facebook']" [disabled]="disableEditPractice" name="facebook"
                             matInput placeholder="Facebook"/>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon class="fab fa-instagram"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Instagram</mat-label>
                      <input [(ngModel)]="myPractice['Instagram']" [disabled]="disableEditPractice" name="instagram"
                             matInput placeholder="Instagram"/>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon class="fab fa-twitter"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Twitter</mat-label>
                      <input [(ngModel)]="myPractice['Twitter']" [disabled]="disableEditPractice" name="twitter"
                             matInput placeholder="Twitter"/>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="row full-width ml">
                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon class="fab fa-linkedin"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>LinkedIn</mat-label>
                      <input [(ngModel)]="myPractice['LinkedIn']" [disabled]="disableEditPractice" name="linkedin"
                             matInput placeholder="LinkedIn"/>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon class="fab fa-pinterest"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Pinterest</mat-label>
                      <input [(ngModel)]="myPractice['Pinterest']" [disabled]="disableEditPractice" name="Pinterest"
                             matInput placeholder="Pinterest"/>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon class="fab fa-tumblr"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Tumblr</mat-label>
                      <input [(ngModel)]="myPractice['Tumblr']" [disabled]="disableEditPractice" name="Tumblr" matInput
                             placeholder="Tumblr"/>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon class="fab fa-vimeo"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Vimeo</mat-label>
                      <input [(ngModel)]="myPractice['Vimeo']" [disabled]="disableEditPractice" name="Vimeo" matInput
                             placeholder="Vimeo"/>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </mat-card>

          <mat-card class="card full-width third"  *ngIf="context!='p4p' && context!='breeze' ">
            <div class="colour-stripe"></div>

            <div style="display: flex; justify-content: space-between">
              <h3 class="sr-title rm-m sm-h3">Services Provided</h3>
              <div>
                <button (click)="openAddServiceProfilePropertyDialog()" mat-icon-button
                        style="display: block; height: 20px; line-height: 20px">
                  <mat-icon> edit_mode</mat-icon>
                </button>
              </div>
            </div>
            <hr class="mb"/>

            <mat-chip-list>
              <mat-chip *ngFor="let merchantProfileService of merchantProfileServices" [color]="'primary'" selected>{{
                merchantProfileService['Service_Group_Item.Label']
                }}</mat-chip>
            </mat-chip-list>
          </mat-card>

          <mat-card class="card full-width third" *ngIf="context!='p4p' && context!='breeze' ">
            <div class="colour-stripe"></div>

            <div style="display: flex; justify-content: space-between">
              <h3 class="sr-title rm-m sm-h3">Health Funds</h3>
              <div>
                <button (click)="openAddHealthFundProfileProperty()" mat-icon-button
                        style="display: block; height: 20px; line-height: 20px">
                  <mat-icon> edit_mode</mat-icon>
                </button>
              </div>
            </div>
            <hr class="mb"/>

            <mat-chip-list>
              <mat-chip *ngFor="let merchantProfileHealthFund of merchantProfileHealthFunds" [color]="'primary'"
                        selected>{{ merchantProfileHealthFund['HealthFund.Label'] }}</mat-chip>
            </mat-chip-list>
          </mat-card>

          <mat-card class="card full-width third" *ngIf="context!='p4p' && context!='breeze' ">
            <div class="colour-stripe"></div>

            <div style="display: flex; justify-content: space-between">
              <h3 class="sr-title rm-m sm-h3">{{"PHRASE.dentistry practiced" | translate | titlecase}} </h3>
              <div>
                <button (click)="openAddDentistryTypesProperty()" mat-icon-button
                        style="display: block; height: 20px; line-height: 20px">
                  <mat-icon> edit_mode</mat-icon>
                </button>
              </div>
            </div>
            <hr class="mb"/>

            <mat-chip-list>
              <mat-chip *ngFor="let merchantProfileDentistryType of merchantProfileDentistryTypes" [color]="'primary'"
                        selected>{{ merchantProfileDentistryType['Label'] }}</mat-chip>
            </mat-chip-list>
          </mat-card>

          <div class="row clearfix button-row" *ngIf="disableEditPractice == false">
            <button class="pull-right" [disabled]="
                !PracticeDetails.form.valid ||
                isAdressPracticeValid == false ||
                validateEmailPractice == false ||
                validatePhonePractice == false
              " (click)="editPractice()" mat-raised-button color="accent">
              <mat-icon>save</mat-icon>
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
