/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./info-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../../shared/pipes/safe-html.pipe";
import * as i7 from "@angular/platform-browser";
import * as i8 from "@angular/common";
import * as i9 from "./info-modal.component";
import * as i10 from "../../authentication/shared/authentication.service";
import * as i11 from "@angular/material/dialog";
var styles_InfoModalComponent = [i0.styles];
var RenderType_InfoModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InfoModalComponent, data: {} });
export { RenderType_InfoModalComponent as RenderType_InfoModalComponent };
function View_InfoModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "character_thumbsup"]], null, null, null, null, null))], null, null); }
function View_InfoModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.image; _ck(_v, 0, 0, currVal_0); }); }
function View_InfoModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "errorContent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "mb"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.description)); _ck(_v, 1, 0, currVal_0); }); }
function View_InfoModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "errorContent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["seems to be experiencing some technical difficulties at this time that are stopping you from proceeding. "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["suggest you try again later or if you wish to discuss this issue with our technical team "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["feel free to call "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵted(-1, null, [" during office hours. "])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings.contactPhone; _ck(_v, 8, 0, currVal_0); }); }
function View_InfoModalComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "ml mr"], ["color", "primary"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.contactUs() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Contact Us "]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_InfoModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.SafeHtmlPipe, [i7.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "error-page rel error"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfoModalComponent_1)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfoModalComponent_2)), i1.ɵdid(5, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h2", [["class", "accent-color"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfoModalComponent_3)), i1.ɵdid(9, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfoModalComponent_4)), i1.ɵdid(11, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 5, "div", [["class", "drop-buttons mt"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "button", [["class", "ml mr"], ["color", "accent"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(15, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Close "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfoModalComponent_5)), i1.ɵdid(18, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.image; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.image; _ck(_v, 5, 0, currVal_1); var currVal_3 = _co.description; _ck(_v, 9, 0, currVal_3); var currVal_4 = !_co.description; _ck(_v, 11, 0, currVal_4); var currVal_7 = "accent"; _ck(_v, 15, 0, currVal_7); var currVal_8 = (_co.displayContact == true); _ck(_v, 18, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.title; _ck(_v, 7, 0, currVal_2); var currVal_5 = (i1.ɵnov(_v, 15).disabled || null); var currVal_6 = (i1.ɵnov(_v, 15)._animationMode === "NoopAnimations"); _ck(_v, 14, 0, currVal_5, currVal_6); }); }
export function View_InfoModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-info-modal", [], null, null, null, View_InfoModalComponent_0, RenderType_InfoModalComponent)), i1.ɵdid(1, 114688, null, 0, i9.InfoModalComponent, [i10.AuthenticationService, i11.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InfoModalComponentNgFactory = i1.ɵccf("app-info-modal", i9.InfoModalComponent, View_InfoModalComponent_Host_0, {}, {}, []);
export { InfoModalComponentNgFactory as InfoModalComponentNgFactory };
