<div class="rel" *ngIf="isPromoterOrAdmin">
  <div class="card-header primary-gradient-img clearfix confirmModal">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModal()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <mat-icon class="white pull-left">attach_file</mat-icon>
        <h2 class="summary-header rm-m">
          Wakanda file List

          <span class="subLabel">Find the invitation and contract files stored on Wakanda server</span>
        </h2>
      </div>
    </div>
  </div>

  <mat-dialog-content class="text-center" [ngClass]="{ 'no-modal-height': isModal != true }">
    <div class="row clearfix mb mt" *ngIf="loaded == true && wakandaFiles.length > 0">
      <p class="pull-left mb clearfix">Files Stored on Wakanda Server: Click on a link to display the file content</p>

      <div class="full-width text-center clearfix">
        <app-ep-document-view-image
          class="wakandaImage-mini main-doc-wakanda pull-left mr smt smb ml"
          *ngFor="let f of wakandaFiles; let index = index"
          [hideZoomButton]="true"
          [imageLink]="getImageLink(f)"
          (click)="documentClick(f)"
        >
        </app-ep-document-view-image>
      </div>
    </div>

    <div class="row clearfix mb mt" *ngIf="loaded == true && epFiles.length > 0">
      <p class="pull-left mb clearfix">Files Stored on EPremium from PP or LA application</p>

      <div class="full-width pull-left clearfix">
        <app-ep-document-list
          class="pull-left clearfix"
          [isDownload]="true"
          [isPrint]="true"
          [canRemove]="false"
          [isFilter]="false"
          [defaultCheck]="false"
          [isRedirection]="isRedirection"
          [documents]="epFiles"
          [title]="''"
          [canCheck]="false"
          [listViewType]="'thumbnails'"
        >
        </app-ep-document-list>
      </div>
    </div>

    <app-empty-list-message
      class="full-width row clearfix"
      *ngIf="loaded == true && wakandaFiles.length <= 0"
      [title]="''"
      [message]="'No file was found for this invitation or contract stored on Wakanda server'"
    >
    </app-empty-list-message>

    <!-- <hr> -->
  </mat-dialog-content>
  <mat-dialog-actions class="button-row text-center">
    <button *ngIf="isModal == true" (click)="closeModal()" mat-raised-button color="accent">Close</button>
  </mat-dialog-actions>
</div>
