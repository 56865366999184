import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions } from 'ngx-gallery';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { SmileMakeover } from '../../merchant/shared/types/smile-makeover.type';
import { WishlistCategory, WishlistItem } from '../../wishlist/shared/services/wishlist.service';

export class SmileMakeoverModalViewConfig {
  smileMakeover: SmileMakeover;
  wishlist: WishlistItem[];
}

export class WishlistManagerModalResult {
  ID?: string;
  Code: WishlistCategory;
  Label: string;
  Description: string;

  constructor(Label: string, Code: WishlistCategory, Description: string, optional: { ID?: string } = {}) {
    this.Code = Code;
    this.Label = Label;
    this.Description = Description;
    this.ID = optional.ID;
  }
}

@Component({
  selector: 'ipv-ventures-smile-makeover-modal-view',
  templateUrl: './smile-makeover-modal-view.component.html',
  styleUrls: ['./smile-makeover-modal-view.component.css'],
})
export class SmileMakeoverModalViewComponent implements OnInit {
  smileMakeover: SmileMakeover;
  calculatedName = '';
  images: NgxGalleryImage[] = [];
  wishlist: WishlistItem[] = [];

  galleryOptions: NgxGalleryOptions[] = [
    {
      width: '100%',
      height: '300px',
      imageAnimation: NgxGalleryAnimation.Slide,
      thumbnailsColumns: 5,
      previewDescription: true,
    },
  ];

  galleryImages: NgxGalleryImage[] = [
    {
      small: 'https://source.unsplash.com/k6SFjtLiZWI/140x100',
      medium: 'https://source.unsplash.com/k6SFjtLiZWI/800x600',
      big: 'https://source.unsplash.com/k6SFjtLiZWI/1600x900',
      label: 'Left View',
      description: 'Left View',
    },
    {
      small: 'https://source.unsplash.com/pRqF0180Wj4/140x100',
      medium: 'https://source.unsplash.com/pRqF0180Wj4/800x600',
      big: 'https://source.unsplash.com/pRqF0180Wj4/1600x900',
      label: 'Right View',
      description: 'Right View',
    },
    {
      small: 'https://source.unsplash.com/JiqalEW6Ml0/140x100',
      medium: 'https://source.unsplash.com/JiqalEW6Ml0/800x600',
      big: 'https://source.unsplash.com/JiqalEW6Ml0/1600x900',
      label: 'Upper View',
      description: 'Upper View',
    },
    {
      small: 'https://source.unsplash.com/JiqalEW6Ml0/140x100',
      medium: 'https://source.unsplash.com/JiqalEW6Ml0/800x600',
      big: 'https://source.unsplash.com/JiqalEW6Ml0/1600x900',
      label: 'Bottom View',
      description: 'Bottom View',
    },
    {
      small: 'https://source.unsplash.com/JiqalEW6Ml0/140x100',
      medium: 'https://source.unsplash.com/JiqalEW6Ml0/800x600',
      big: 'https://source.unsplash.com/JiqalEW6Ml0/1600x900',
      label: 'Front View',
      description: 'Front View',
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: SmileMakeoverModalViewConfig,
    private dialogRef: MatDialogRef<SmileMakeoverModalViewComponent>,
    private utilsService: UtilsService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    if (this.data) {
      this.smileMakeover = this.data.smileMakeover;
      this.wishlist = this.data.wishlist;
      this.calculatedName = this.smileMakeover['Card.CalculatedName'];

      console.log(this.smileMakeover);
      console.log(this.wishlist);

      this.images = [];

      this.authenticationService.getSessionType().subscribe((sessionType) => {
        // this.utilsService
        //   .getEpdocument(this.smileMakeover.LeftImageDoc_key, {}, sessionType)
        //   .subscribe(console.log);
      });
    }
  }

  close() {
    this.dialogRef.close();
  }
}
