import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { HeaderService } from '../../../shared/services/header.service';
import { Settings } from '../../../shared/types/settings';

/**
 * @title Feature-rich data table
 */
@Component({
  selector: 'app-invitation-communication-list-overview',
  templateUrl: './invitation-communication-list-overview.component.html',
  styleUrls: ['./invitation-communication-list-overview.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class InvitationCommunicationListOverViewComponent implements OnInit {
  @Input()
  purposeCode;

  isAdminOrPromoter = null;

  merchants = [];
  dateFrom;
  dateTo;
  _dateFrom;
  _dateTo;
  _merchantID = null;
  merchantID = null;
  productID;
  dentistID;

  role;

  hideExtraComponents = false;

  isStatusParameter = false;

  sendNull = true;
  selectedRange = 'days90';

  @Input()
  pageSize = Settings.global['listPageSize'] || 20;

  isDisplayMerchant = true;

  searchApplied = false;

  constructor(private AuthenticationService: AuthenticationService, private activeRoute: ActivatedRoute) {}

  ngOnInit() {
    this.AuthenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isAdminOrPromoter = res;

      this.activeRoute.params.subscribe((params) => {
        if (params['purpose'] == 'medical-history') {
          this.purposeCode = 'COM_UHH';
        } else if (params['purpose'] == 'profile-update') {
          this.purposeCode = 'COM_UCD';
        } else if (params['purpose'] == 'google-review') {
          this.purposeCode = 'COM_GGL';
        }
      });

      if (this.isAdminOrPromoter == true) {
        const _mID = JSON.parse(localStorage.getItem('selectedMerchant'));
        if (_mID) {
          this.merchantID = _mID;
          // this.isDisplayMerchant = false;
        }

        HeaderService.getPromoterViewAsObject().subscribe((res) => {
          if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'promoter') {
            this.merchantID = null;
            this.isDisplayMerchant = true;
          } else if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'merchant') {
            this.merchantID = res['merchantID'];
            this.isDisplayMerchant = false;
          }
        });
      }
    });
  }

  viewDetails(e) {
    this.hideExtraComponents = e;
  }

  getMerchant(d) {
    this._merchantID = d;
  }

  getDateFrom(d) {
    this._dateFrom = d;
  }

  getDateTo(d) {
    this._dateTo = d;
  }

  applySearch(result) {
    this.merchantID = result.merchantID;
    this.dateFrom = result.dateFrom;
    this.dateTo = result.dateTo;
    this.productID = result.productID;
    this.dentistID = result.dentistID;
    this.searchApplied = true;
  }
}
