import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LookupService } from '../../../shared/services/lookup.service';
@Component({
  selector: 'app-patient-contact-details-create',
  templateUrl: './patient-contact-details-create.component.html',
  styleUrls: ['./patient-contact-details-create.component.css'],
})
export class PatientContactDetailsCreateComponent implements OnInit {
  @Input() InvitationID = '';
  @Output() validate = new EventEmitter();
  prefContactMethods = [];
  contactTimes = [];
  patientContact = {
    mobile: '',
    homePhone: '',
    workPhone: '',
    email: '',
    preferedContact: '',
    time: '',
  };
  constructor(private lookupService: LookupService) {}

  ngOnInit() {
    this.lookupService.getLookup('codelookup', 'ContactMethod').subscribe((res) => {
      this.prefContactMethods = res;
    });
    this.lookupService.getLookup('codelookup', 'ContactTime').subscribe((res) => {
      this.contactTimes = res;
    });
  }

  checkFormValidate(isValid) {
    this.validate.emit(isValid);
  }
}
