<div class="row clearfix">
  <div class="card-header primary-gradient-img clearfix inModal" *ngIf="isModal == true">
    <div class="row clearfix">
      <button class="btn-close btn-clear pull-right mat-button" (click)="closeModalEvent()">
        <mat-icon>clear</mat-icon>
      </button>

      <div class="row titleArea clearfix">
        <mat-icon class="pull-left fas fa-comment-dollar"></mat-icon>
        <h2 class="summary-header rm-m">Create Investment Property</h2>
      </div>
    </div>
  </div>

  <form #PCIForm="ngForm">
    <mat-dialog-content [ngStyle]="{ overflow: isModal == false ? 'unset' : 'scroll' }">
      <div class="row clearfix full-width cont" *ngIf="disabled == false">
        <div class="row clearfix flex">
          <div class="clearfix full-width">
            <div class="col-left">
              <h4 class="rm-mb">Operator modification</h4>
              <hr/>
              <div class="row clearfix full-width">
                <mat-form-field
                  class="full-width"
                  [ngClass]="highlightField('Updating_Reason.Code')"
                  appearance="outline"
                >
                  <mat-label>Reason</mat-label>
                  <mat-select [(ngModel)]="pcDetail.updatedReason" placeholder="Reason" name="reason" required>
                    <mat-option *ngFor="let reason of persCircumUpdReasons" [value]="reason.Code">
                      {{ reason.Label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="clearfix full-width">
            <div class="col-left">
              <h4 class="rm-mb">Comments</h4>
              <hr/>
              <div class="row clearfix full-width">
                <mat-form-field
                  class="full-width"
                  [ngClass]="highlightField('Updating_Reason.Comment')"
                  appearance="outline"
                >
                  <mat-label>Leave a comment</mat-label>
                  <textarea
                    [(ngModel)]="pcDetail.updatedComment"
                    matInput
                    placeholder="Comment"
                    name="comment"
                  ></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>

          <!-- <div class="clearfix full-width">
                <div class="col-left">
                  <h4 class="rm-mb">Files</h4>
                  <hr>
                  <div class="row clearfix full-width">
                    <app-file-uploader class=" text-center clearfix" [uploadDirectly]="true" [isDragable]="true" class="text-center" (onCompleteAll)="onCompleteAll($event)"
                      [limit]="1"></app-file-uploader>


                    <app-file-list class="compact" (remove)="removeFile($event)" [hasExtraInfo]="false" [canEdit]="true" [canAction]="false"
                      [files]="[fileID]"></app-file-list>
                  </div>
                </div>
              </div> -->
        </div>
      </div>

      <div class="row clearfix full-width cont">
        <div class="row clearfix flex">
          <div class="clearfix full-width">
            <div class="col-left">
              <h4 class="rm-mb">Property Address</h4>
              <hr/>
              <div class="row clearfix">
                <app-address-input
                  [displayMap]="false"
                  [postCode]="pcDetail.addressStreetPostCode"
                  [state]="pcDetail.addressStreetState"
                  [suburb]="pcDetail.addressStreetSuburb"
                  [streetNumber]="pcDetail.addressStreetNumber"
                  [streetName]="pcDetail.addressStreetName"
                  [unitNumber]="pcDetail.addressUnit"
                  [streetType]="pcDetail.addressStreetType"
                  [isDisabled]="disabled"
                  (getAdress)="getInvestmentPropertyAddress($event)"
                  (validate)="isInvestmentPropertyAddressValidEvent($event)"
                ></app-address-input>
              </div>
            </div>
          </div>

          <div class="clearfix full-width">
            <div class="col-left">
              <h4 class="rm-mb">Property Ownership</h4>
              <hr/>
              <div class="row clearfix full-width">
                <div class="row clearfix">
                  <mat-form-field class="full-width" [ngClass]="highlightField('Info.WhoOwns')" appearance="outline">
                    <mat-label>Who Owns The Property</mat-label>
                    <mat-select
                      [(ngModel)]="pcDetail.infoWhoOwns"
                      [disabled]="disabled"
                      (selectionChange)="ownerChange($event.value)"
                      placeholder="Who Owns The Property"
                      name="ownProperty"
                      required
                    >
                      <mat-option *ngFor="let l of propertyOwnerList" [value]="l.Code">
                        {{ l.Label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field
                    class="full-width"
                    *ngIf="pcDetail.infoWhoOwns == 'COP'"
                    [ngClass]="highlightField('Info.NumberOfOwners')"
                    appearance="outline"
                  >
                    <mat-label>Number of Owners</mat-label>
                    <input
                      [(ngModel)]="pcDetail.infoNumberofOwners"
                      [disabled]="disabled"
                      (ngModelChange)="calcuateEquityPercent($event)"
                      matInput
                      placeholder="Number of Owners"
                      name="numOfOwners"
                    />
                  </mat-form-field>

                  <mat-form-field
                    class="full-width"
                    [ngClass]="highlightField('Info.MarketValue')"
                    appearance="outline"
                  >
                    <mat-label>Market Value</mat-label>
                    <input
                      [(ngModel)]="pcDetail.infoMarketValue"
                      [disabled]="disabled"
                      (ngModelChange)="calcuateEquityValue($event)"
                      matInput
                      placeholder="Market Value"
                      name="marketValue"
                      currencyMask
                      required
                    />
                  </mat-form-field>
                </div>
                <div class="row clearfix flex">
                  <mat-form-field
                    class="full-width"
                    [ngClass]="highlightField('Info.EquityPercent')"
                    appearance="outline"
                  >
                    <mat-label>Equity In Property (%)</mat-label>
                    <input
                      [(ngModel)]="pcDetail.infoEquityPercent"
                      [options]="{ prefix: '', suffix: ' %' }"
                      matInput
                      placeholder="Equity In Property"
                      name="equityPercent"
                      currencyMask
                      disabled
                    />
                  </mat-form-field>

                  <mat-form-field class="full-width" [ngClass]="highlightField('Asset.Value')" appearance="outline">
                    <mat-label>Equity In Property ($)</mat-label>
                    <input
                      [(ngModel)]="pcDetail.assetValue"
                      matInput
                      placeholder="Equity In Property"
                      name="assetValue"
                      currencyMask
                      disabled
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row clearfix flex" *ngIf="isValidAddress == true && pcDetail.infoMarketValue != null">
          <div class="clearfix full-width">
            <div class="col-left">
              <h4 class="rm-mb">Rental Income</h4>
              <hr/>
              <div class="row clearfix full-width">
                <mat-form-field
                  class="full-width"
                  [ngClass]="highlightField('Info.IncomeTotal.Amount')"
                  appearance="outline"
                >
                  <mat-label>Rent Paid</mat-label>
                  <input
                    [(ngModel)]="pcDetail.infoIncomeTotalAmount"
                    [disabled]="disabled"
                    (ngModelChange)="calculateApplicantShareRentPaid($event)"
                    matInput
                    placeholder="Rent Paid"
                    name="totalIncome"
                    currencyMask
                  />
                </mat-form-field>

                <mat-form-field
                  class="full-width"
                  [ngClass]="highlightField('Info.IncomeTotal.Frequency')"
                  appearance="outline"
                >
                  <mat-label>Frequency</mat-label>
                  <mat-select
                    [(ngModel)]="pcDetail.infoIncomeTotalFrequency"
                    [disabled]="disabled"
                    placeholder="Frequency"
                    name="incomeFrequency"
                  >
                    <mat-option *ngFor="let fre of frequencies" [value]="fre.Code">
                      {{ fre.Label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field
                  class="full-width"
                  [ngClass]="highlightField('Info.Income.Actual')"
                  appearance="outline"
                >
                  <mat-label>Applicants Share of Rent Paid</mat-label>
                  <input
                    [(ngModel)]="pcDetail.infoIncomeActual"
                    matInput
                    placeholder="Applicants Share of Rent Paid"
                    name="actualIncome"
                    currencyMask
                    disabled
                  />
                </mat-form-field>

                <mat-form-field
                  class="full-width"
                  [ngClass]="highlightField('Info.Income.Deemed')"
                  appearance="outline"
                >
                  <mat-label>Deemed Income Paid</mat-label>
                  <input
                    [(ngModel)]="pcDetail.infoIncomeDeemed"
                    [disabled]="disabled"
                    matInput
                    placeholder="Deemed Income Paid"
                    name="deemedIncome"
                    currencyMask
                  />
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="clearfix full-width">
            <div class="col-left">
              <h4 class="rm-mb">Property Expenses (Maintenance, Rates, Agent Fees etc.)</h4>
              <hr/>
              <div class="row clearfix full-width">
                <mat-form-field class="full-width" [ngClass]="highlightField('Expense.Frequency')" appearance="outline">
                  <mat-label>Frequency</mat-label>
                  <mat-select
                    [(ngModel)]="pcDetail.expenseFrequency"
                    [disabled]="disabled"
                    placeholder="Frequency"
                    name="ExpenseFrequency"
                  >
                    <mat-option *ngFor="let fre of frequencies" [value]="fre.Code">
                      {{ fre.Label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field
                  class="full-width"
                  [ngClass]="highlightField('MaintenanceAllowanceExpense')"
                  appearance="outline"
                >
                  <mat-label>Maintenance Allowance</mat-label>
                  <input
                    [(ngModel)]="pcDetail.maintenanceExpense"
                    [disabled]="disabled"
                    (ngModelChange)="maintenanceValueChange($event)"
                    matInput
                    placeholder="Maintenance Allowance"
                    name="maintenance"
                    currencyMask
                  />
                </mat-form-field>

                <mat-form-field class="full-width" [ngClass]="highlightField('RatesExpense')" appearance="outline">
                  <mat-label>Rates</mat-label>
                  <input
                    [(ngModel)]="pcDetail.ratesExpense"
                    [disabled]="disabled"
                    (ngModelChange)="reatesValueChange($event)"
                    matInput
                    placeholder="Rates"
                    name="rates"
                    currencyMask
                  />
                </mat-form-field>

                <mat-form-field class="full-width" [ngClass]="highlightField('AgentFeesExpense')" appearance="outline">
                  <mat-label>Agent Fees</mat-label>
                  <input
                    [(ngModel)]="pcDetail.agentFeesExpense"
                    [disabled]="disabled"
                    (ngModelChange)="agentFeesValueChange($event)"
                    matInput
                    placeholder="Agent Fees"
                    name="agentFees"
                    currencyMask
                  />
                </mat-form-field>

                <mat-form-field
                  class="full-width"
                  [ngClass]="highlightField('Info.Expense.TotalAmount')"
                  appearance="outline"
                >
                  <mat-label>Total Property Expense</mat-label>
                  <input
                    [ngModel]="pcDetail.totalExpense"
                    matInput
                    placeholder="Total Property Expense"
                    name="totalExpenses"
                    currencyMask
                    disabled
                  />
                </mat-form-field>

                <mat-form-field
                  class="full-width"
                  [ngClass]="highlightField('Info.Expense.Actual')"
                  appearance="outline"
                >
                  <mat-label>Applicant Share of Property Expense</mat-label>
                  <input
                    [ngModel]="pcDetail.applicantExpense"
                    matInput
                    placeholder="Applicant Share of Property Expense"
                    name="applicantExpense"
                    currencyMask
                    disabled
                  />
                </mat-form-field>

                <mat-form-field
                  class="full-width"
                  [ngClass]="highlightField('Info.Expense.Deemed')"
                  appearance="outline"
                >
                  <mat-label>Deemed Property Expense</mat-label>
                  <input
                    [(ngModel)]="pcDetail.deemedExpense"
                    [disabled]="disabled"
                    matInput
                    placeholder="Deemed Property Expense"
                    name="deemedExpense"
                    currencyMask
                  />
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="clearfix full-width">
            <div class="col-left">
              <h4 class="rm-mb">Property Loan</h4>
              <hr/>
              <div class="row clearfix full-width">
                <mat-form-field [ngClass]="highlightField('Info.Owned')" appearance="outline">
                  <mat-label>Is This Property Owned Outright?</mat-label>
                  <mat-select [(ngModel)]="pcDetail.infoOwned" [disabled]="disabled" name="infoOwned">
                    <mat-option value="0"> No</mat-option>
                    <mat-option value="1"> Yes</mat-option>
                  </mat-select>
                </mat-form-field>

                <section *ngIf="pcDetail.infoOwned == '0'">
                  <mat-form-field class="full-width" [ngClass]="highlightField('Info.Bank.Code')" appearance="outline">
                    <mat-label>Lender</mat-label>
                    <mat-select
                      [(ngModel)]="pcDetail.infoBankCode"
                      [disabled]="disabled"
                      placeholder="Lender"
                      name="Lender"
                    >
                      <mat-option *ngFor="let bank of bankList" [value]="bank.ID">
                        {{ bank.Name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field
                    class="full-width"
                    [ngClass]="highlightField('Info.TypeOfLoan.Code')"
                    appearance="outline"
                  >
                    <mat-label>Loan Type</mat-label>
                    <mat-select
                      [(ngModel)]="pcDetail.infoTypeOfLoanCode"
                      [disabled]="disabled"
                      placeholder="Loan Type"
                      name="LoanType"
                    >
                      <mat-option *ngFor="let l of loanTypeList" [value]="l.Code">
                        {{ l.Label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field
                    class="full-width"
                    [ngClass]="highlightField('Info.AmountInitiallyBorrowed')"
                    appearance="outline"
                  >
                    <mat-label>Original Loan Amount</mat-label>
                    <input
                      [(ngModel)]="pcDetail.infoAmountBorrowed"
                      [disabled]="disabled"
                      matInput
                      placeholder="Original Loan Amount"
                      name="infoAmountBorrowed"
                      currencyMask
                    />
                  </mat-form-field>

                  <mat-form-field class="full-width" [ngClass]="highlightField('Liability.Value')" appearance="outline">
                    <mat-label>Current Debt</mat-label>
                    <input
                      [(ngModel)]="pcDetail.liabilityValue"
                      [disabled]="disabled"
                      matInput
                      placeholder="Current Debt"
                      name="liabilityValue"
                      currencyMask
                    />
                  </mat-form-field>

                  <mat-form-field
                    class="full-width"
                    [ngClass]="highlightField('Info.RepaymentsTotal.Amount')"
                    appearance="outline"
                  >
                    <mat-label>Repayment Amount</mat-label>
                    <input
                      [(ngModel)]="pcDetail.infoRepaymentValue"
                      matInput
                      placeholder="Repayment Amount"
                      name="infoRepaymentValue"
                      currencyMask
                    />
                  </mat-form-field>

                  <mat-form-field
                    class="full-width"
                    [ngClass]="highlightField('Info.RepaymentsTotal.Frequency')"
                    appearance="outline"
                  >
                    <mat-label>Repayment Frequency</mat-label>
                    <mat-select
                      [(ngModel)]="pcDetail.infoRepaymentFrequency"
                      placeholder="Repayment Frequency"
                      name="RepaymentFrequency"
                    >
                      <mat-option *ngFor="let fre of frequencies" [value]="fre.Code">
                        {{ fre.Label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <div class="row clearfix mt" *ngIf="disabled == false">
      <button
        class="pull-right"
        *ngIf="isModal == false"
        [disabled]="!pcDetail.updatedReason || !PCIForm.form.valid || isValidAddress == false"
        (click)="submit()"
        mat-raised-button
        color="accent"
      >
        Submit
      </button>
      <button
        class="pull-right"
        *ngIf="isModal == true"
        [disabled]="!pcDetail.updatedReason || !PCIForm.form.valid || isValidAddress == false"
        (click)="create()"
        mat-raised-button
        color="accent"
      >
        Create
      </button>
    </div>
  </form>
</div>
