import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-duration-slider-input',
  templateUrl: './duration-slider-input.component.html',
  styleUrls: ['./duration-slider-input.component.css'],
})
export class DurationSliderInputComponent implements OnInit {
  minAmount;
  maxAmount;
  // Added to allow compiler to run

  @Input() period = 12;
  @Input() minPeriod = 1;
  @Input() maxPeriod = 60;
  @Input() frequency = 4;
  @Output() selectPeriod = new EventEmitter();

  @Output() selectPeriodChangeInput = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  selectRepayPeriod(evt) {
    if (evt.value) {
      this.period = evt.value;
      this.selectPeriod.emit(this.period);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.period) {
      this.selectPeriodChangeInput.emit(this.period);
    }
  }

  RepayPeriod() {
    if (this.maxPeriod && Number(this.period) > this.maxPeriod) {
      this.period = this.maxPeriod;
    } else if (this.minPeriod && Number(this.period) < this.minPeriod) {
      this.period = this.minPeriod;
    }

    if (this.period) {
      this.selectPeriod.emit(this.period);
    }
  }

  durationLabel() {
    const value = this.frequency;
    if (value == 1) {
      return 'Weekly';
    }

    if (value == 2) {
      return 'Fortnightly';
    }

    if (value == 4) {
      return 'Monthly';
    }
  }

  timeLabel() {
    const value = this.frequency;

    if (value == 1) {
      return 'Weeks';
    }

    if (value == 2) {
      return 'Fortnights';
    }

    if (value == 4) {
      return 'Months';
    }
  }
}
