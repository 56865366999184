import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../authentication/shared/authentication.service';

@Component({
  selector: 'app-something-wrong',
  templateUrl: './something-wrong.component.html',
  styleUrls: ['./something-wrong.component.css'],
})
export class SomethingWrongComponent implements OnInit {
  countdown;
  subscribe;
  redirection = true;

  constructor(private location: Location) {}

  ngOnInit() {}

  cancel() {
    this.redirection = false;
    this.subscribe.unsubscribe();
    this.location.back(); // <-- go back to previous location on cancel
  }

  ngOnDestroy() {
    this.redirection = false;
    this.subscribe.unsubscribe();
  }

  goHome() {
    this.redirection = false;
    this.subscribe.unsubscribe();
    window.location.href = '/login';
  }
  startCountdownTimer() {
    this.countdown = 0;
    const interval = 1000;
    const duration = 60 * 1000;

    const stream$ = Observable.timer(0, interval)
      .finally(() => {
        if (this.redirection == true) {
          this.goHome();
        }
      })
      .takeUntil(Observable.timer(duration + interval))
      .map((value) => duration - value * interval);
    this.subscribe = stream$.subscribe((value) => (this.countdown = value));
  }

  contactUs() {
    if (this.subscribe) {
      this.subscribe.unsubscribe();
      this.countdown = null;
    }

    AuthenticationService.contactUSEvent.emit(true);
  }
}
