import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActionLogViewComponent } from '../action-log-view/action-log-view.component';
import { ActionLogListComponent } from '../action-log-list/action-log-list.component';
import { EventLogListComponent } from '../event-log-list/event-log-list.component';
import { EventLogViewComponent } from '../event-log-view/event-log-view.component';
import { ActionLogInterfaceComponent } from '../action-log-interface/action-log-interface.component';

const routes: Routes = [
  {
    path: 'action-log-list',
    component: ActionLogListComponent
  },
  {
    path: 'action-log-view',
    component: ActionLogViewComponent
  },
  {
    path: 'action-log-interface',
    component: ActionLogInterfaceComponent
  },
  {
    path: 'event-log-list',
    component: EventLogListComponent
  },
  {
    path: 'event-log-view',
    component: EventLogViewComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ActionLogRoutingModule { }
