<mat-card class="rel supplier-card clearfix mb" *ngIf="session" @ngIfAnimation>
  <!-- is a modal -->

  <div class="card-header primary-gradient-img clearfix stacked">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <app-session-view-profile-picture class="profile-picture-view" [sessionID]="sessionID">
        </app-session-view-profile-picture>
        <h2 class="summary-header rm-m">
          {{ session['CalculatedName'] }}
          <span class="subLabel" *ngIf="session['TradingAs']">Trading as: {{ session['TradingAs'] }}</span>
        </h2>
        <p class="clearfix small rm-mb">
          <span class="chip-enable">{{ session['sessionType'] }}</span>
          <span
            *ngIf="operator && operator.hasOwnProperty('enabled')"
            [ngClass]="{
              'chip-enable': operator.enabled,
              'chip-disable': !operator.enabled
            }"
          >{{ operator.enabled ? 'enabled' : 'disabled' }}</span
          >
          <span
            [ngClass]="{
              'chip-enable': session.isAlive,
              'chip-disable': !session.isAlive
            }"
          >{{ session.isAlive ? 'online' : 'offline' }}</span
          >
        </p>
      </div>
    </div>
    <div class="action-button">
      <button class="white" class="actionMenuToggle" [matMenuTriggerFor]="btnPatMenu" mat-mini-fab color="accent">
        <mat-icon class="white">more_vert</mat-icon>
      </button>

      <mat-menu #btnPatMenu="matMenu">
        <button (click)="viewAgentDetails()" mat-menu-item>
          <mat-icon>web</mat-icon>
          <span>Browser Details</span>
        </button>

        <button *ngIf="session.geoLocation" (click)="displayMap()" mat-menu-item>
          <mat-icon>person_pin_circle</mat-icon>
          <span>Show Locations</span>
        </button>

        <button (click)="viewUserLogs()" mat-menu-item>
          <mat-icon>list</mat-icon>
          <span>User Logs</span>
        </button>

        <ng-container *ngIf="operator">
          <button (click)="changePassword()" mat-menu-item>
            <mat-icon>vpn_key</mat-icon>
            <span>Change Password</span>
          </button>

          <ng-container *ngIf="operator.hasOwnProperty('enabled')">
            <button *ngIf="operator.enabled" (click)="disableLogin()" mat-menu-item>
              <mat-icon>block</mat-icon>
              <span>Disable Login</span>
            </button>

            <button *ngIf="!operator.enabled" (click)="enableLogin()" mat-menu-item>
              <mat-icon>verified_user</mat-icon>
              <span>Enable Login</span>
            </button>
          </ng-container>

          <button (click)="displayUserInfo()" mat-menu-item>
            <mat-icon>info</mat-icon>
            <span>User Profile</span>
          </button>
        </ng-container>

        <button (click)="contactUser()" mat-menu-item>
          <mat-icon>contact_mail</mat-icon>
          <span>Contact User</span>
        </button>

        <button (click)="goToOperatorSessionOverview()" mat-menu-item>
          <mat-icon>info</mat-icon>
          <span>Go to Operator Session Overview</span>
        </button>

        <button (click)="deleteSession()" mat-menu-item>
          <mat-icon>delete</mat-icon>
          <span>Delete Session</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <mat-dialog-content class="stacked-card-view" *ngIf="isModal == true">
    <div class="details-container">
      <h1 class="sm-h1 sr-title">Session Details</h1>
      <div class="full-width mb clearfix row">
        <div class="row clearfix details flex" *ngIf="session['last_check']">
          <mat-icon class="ico">schedule</mat-icon>
          <div class="row-content clearfix">
            <p class="rm-mb rm-mt">
              {{ session['last_check'] | timeLeft }} -
              {{ session['last_check'] | customDateTime }}
            </p>
            <p class="small">Last Login</p>
          </div>
        </div>

        <div class="row clearfix details flex" *ngIf="session['expires']">
          <mat-icon class="ico">schedule</mat-icon>
          <div class="row-content clearfix">
            <p class="rm-mb rm-mt">
              {{ session['expires'] | timeLeft }} -
              {{ session['expires'] | customDateTime }}
            </p>
            <p class="small">Expires in</p>
          </div>
        </div>
        <div class="flex detail-buttons">
          <button (click)="viewAgentDetails()" mat-raised-button color="accent">
            <mat-icon>web</mat-icon>
            <span>Browser Details</span>
          </button>

          <button *ngIf="session.geoLocation" (click)="displayMap()" mat-raised-button color="accent">
            <mat-icon>person_pin_circle</mat-icon>
            <span>Show Locations</span>
          </button>

          <button (click)="viewUserLogs()" mat-raised-button color="accent">
            <mat-icon>list</mat-icon>
            <span>User Logs</span>
          </button>
        </div>
      </div>

      <h1 class="sm-h1 sr-title">User Details</h1>
      <div class="full-width mb clearfix row">
        <div class="row clearfix details flex" *ngIf="session['operatorType']">
          <mat-icon class="ico">info</mat-icon>
          <div class="row-content clearfix">
            <p class="rm-mb rm-mt">
              {{ session['operatorType'] }}
            </p>
            <p class="small">Type</p>
          </div>
        </div>
        <div class="row clearfix details flex" *ngIf="operator && operator['userName']">
          <mat-icon class="ico">info</mat-icon>
          <div class="row-content clearfix">
            <p class="rm-mb rm-mt">
              {{ operator['userName'] }}
            </p>
            <p class="small">User Name</p>
          </div>
        </div>
        <div class="row clearfix details flex" *ngIf="session['status']">
          <mat-icon class="ico">info</mat-icon>
          <div class="row-content clearfix">
            <p class="rm-mb rm-mt">
              {{ session['status'] }}
            </p>
            <p class="small">Status</p>
          </div>
        </div>
        <div class="row clearfix details flex" *ngIf="session['email']">
          <mat-icon class="ico">email</mat-icon>
          <div class="row-content clearfix">
            <p class="rm-mb rm-mt">
              {{ session['email'] }}
            </p>
            <p class="small">Email</p>
          </div>
        </div>
        <div class="row clearfix details flex" *ngIf="session['phone']">
          <mat-icon class="ico">phone</mat-icon>
          <div class="row-content clearfix">
            <p class="rm-mb rm-mt">
              {{ session['phone'] | customPhone: 'landLine' }}
            </p>
            <p class="small">Mobile</p>
          </div>
        </div>
        <div class="flex detail-buttons" *ngIf="operator">
          <button (click)="changePassword()" color="accent" mat-raised-button>
            <mat-icon>vpn_key</mat-icon>
            <span>Change Password</span>
          </button>

          <ng-container *ngIf="operator.hasOwnProperty('enabled')">
            <button *ngIf="operator.enabled" (click)="disableLogin()" color="accent" mat-raised-button>
              <mat-icon>block</mat-icon>
              <span>Disable Login</span>
            </button>

            <button *ngIf="!operator.enabled" (click)="enableLogin()" color="accent" mat-raised-button>
              <mat-icon>verified_user</mat-icon>
              <span>Enable Login</span>
            </button>
          </ng-container>

          <button (click)="displayUserInfo()" mat-raised-button color="accent">
            <mat-icon>person</mat-icon>
            <span>Operator details</span>
          </button>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <!-- is not a modal -->

  <div class="stacked-card-view not-modal" *ngIf="isModal == false">
    <div class="card-header primary-gradient-img clearfix stacked">
      <div class="row clearfix">
        <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeEvent()">
          <mat-icon>clear</mat-icon>
        </button>
        <div class="row titleArea clearfix">
          <app-session-view-profile-picture class="profile-picture-view" [sessionID]="sessionID">
          </app-session-view-profile-picture>
          <h2 class="summary-header rm-m">
            <!-- {{ session.CalculatedName}} -->
            {{ session['Name'] }}
            <span class="subLabel" *ngIf="session['TradingAs']">Trading as: {{ session['TradingAs'] }}</span>
            <span class="subLabel" *ngIf="session['TrusteeFor']">Trustee for: {{ session['TrusteeFor'] }}</span>
          </h2>
          <p class="clearfix small rm-mb" *ngIf="session['Active'] == '1' && isPromoterOrAdmin == true">
            <span class="chip-enable">Activated</span>
          </p>
          <p class="clearfix small rm-mb" *ngIf="session['Active'] == '0' && isPromoterOrAdmin == true">
            <span class="chip-disable">Not Activated</span>
          </p>
        </div>
      </div>
      <div class="action-button">
        <button class="actionMenuToggle" [matMenuTriggerFor]="btnPatMenu" mat-raised-button>
          Actions <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #btnPatMenu="matMenu">
          <button *ngIf="isPromoterOrAdmin == false" (click)="viewSupplier()" mat-menu-item>
            <mat-icon>search</mat-icon>
            <span>View Details</span>
          </button>

          <button (click)="openCatelogueVIew()" mat-menu-item>
            <mat-icon class="fas fa-newspaper"></mat-icon>
            <span>View Catalogue</span>
          </button>

          <button *ngIf="isPromoterOrAdmin == true" (click)="editSupplier()" mat-menu-item>
            <mat-icon>edit</mat-icon>
            <span>Edit Supplier</span>
          </button>

          <button *ngIf="isPromoterOrAdmin == true" (click)="viewBankAccounts()" mat-menu-item>
            <mat-icon class="fas fa-wallet"></mat-icon>
            <span>View Bank Accounts</span>
          </button>
          <button
            class=""
            *ngIf="session['emails.Email']"
            (click)="openMailTo()"
            mat-menu-item
            color="accent"
            matTooltip="Contact {{ session.CalculatedName }}"
          >
            <mat-icon>contact_mail</mat-icon>
            <span>Contact User</span>
          </button>

          <button
            *ngIf="session['addresses.Calculated']"
            (click)="displayMap()"
            mat-menu-item
            color="accent"
            matTooltip="View on map"
          >
            <mat-icon>place</mat-icon>
            <span>View on map</span>
          </button>
          <button *ngIf="session['Active'] == '1' && isPromoterOrAdmin == true" (click)="disable()" mat-menu-item>
            <mat-icon>cancel</mat-icon>
            <span>Disable</span>
          </button>
          <button *ngIf="session['Active'] == '0' && isPromoterOrAdmin == true" (click)="enable()" mat-menu-item>
            <mat-icon>check_circle</mat-icon>
            <span>Enable</span>
          </button>
        </mat-menu>
      </div>
    </div>

    <div
      class="grey-row upper mb"
      *ngIf="session['Facebook'] || session['Twitter'] || session['LinkedIn'] || session['Instagram']"
    >
      <div class="row clearfix socials">
        <!-- open social links in new tab -->
        <button
          *ngIf="session['Facebook']"
          (click)="openSocialLink(session['Facebook'])"
          mat-icon-button
          matTooltip="Visit Facebook page"
        >
          <mat-icon class="fab fa-facebook"></mat-icon>
        </button>
        <button
          *ngIf="session['Twitter']"
          (click)="openSocialLink(session['Twitter'])"
          mat-icon-button
          matTooltip="Visit Twitter page"
        >
          <mat-icon class="fab fa-twitter"></mat-icon>
        </button>
        <button
          *ngIf="session['LinkedIn']"
          (click)="openSocialLink(session['LinkedIn'])"
          mat-icon-button
          matTooltip="Visit LinkedIn page"
        >
          <mat-icon class="fab fa-linkedin"></mat-icon>
        </button>
        <button
          *ngIf="session['Instagram']"
          (click)="openSocialLink(session['Instagram'])"
          mat-icon-button
          matTooltip="Visit Instagram page"
        >
          <mat-icon class="fab fa-instagram"></mat-icon>
        </button>
      </div>
    </div>

    <div class="row clearfix details flex" *ngIf="session['addresses.Calculated']">
      <mat-icon class="ico">room</mat-icon>
      <div class="row-content clicktext" (click)="displayMap()" matTooltip="Click to view on map">
        <h4 class="rm-m">{{ session['addresses.Calculated'] }}</h4>
        <p class="small">Address</p>
      </div>
    </div>

    <div class="mb clearfix row flex wrapped">
      <div class="row clearfix details flex" *ngIf="session['Phone.Work.Number']">
        <mat-icon class="ico">phone</mat-icon>
        <div class="row-content">
          <h4 class="rm-m">
            {{ session['Phone.Work.Number'] | customPhone: 'landLine' }}
          </h4>
          <p class="small">Business Phone</p>
        </div>
        <div class="flex detail-buttons">
          <button (click)="viewAgentDetails()" mat-raised-button color="accent">
            <mat-icon>web</mat-icon>
            <span>Browser Details</span>
          </button>

          <button *ngIf="session.geoLocation" (click)="displayMap()" mat-raised-button color="accent">
            <mat-icon>person_pin_circle</mat-icon>
            <span>Show Locations</span>
          </button>

          <button (click)="viewUserLogs()" mat-raised-button color="accent">
            <mat-icon>list</mat-icon>
            <span>User Logs</span>
          </button>
        </div>
      </div>

      <div class="row clearfix details flex" *ngIf="session['emails.Email']">
        <mat-icon class="ico">email</mat-icon>
        <div class="row-content clicktext" (click)="openMailTo()" matTooltip="Click to create email">
          <h4 class="rm-m">{{ session['emails.Email'] }}</h4>
          <p class="small">Email</p>
        </div>
      </div>

      <div class="row clearfix details flex" *ngIf="session['URL']">
        <mat-icon class="ico">devices</mat-icon>
        <div class="row-content clicktext">
          <h4 class="rm-m" (click)="openSocialLink(session['URL'])" matTooltip="Click to visit website">
            {{ session['URL'] }}
          </h4>
          <p class="small">Website</p>
        </div>
      </div>
    </div>

    <div class="grey-row" *ngIf="abnDetails == true && isPromoterOrAdmin == true">
      <p class="small rm-m">
        <span class="baseLabel" *ngIf="session['Type_Code'] == 'BENE'">
          <mat-icon class="fas fa-parachute-box"></mat-icon> Supplier
        </span>
        <span class="baseLabel" *ngIf="session['Type_Code'] == 'MANU'">
          <mat-icon class="fas fa-industry"></mat-icon> Manufacturer
        </span>
        <span class="baseLabel" *ngIf="session['Type_Code'] == 'DISTR'">
          <mat-icon class="fas fa-truck"></mat-icon> Distributor
        </span>
        : {{ session.CalculatedName }}
      </p>
      <hr *ngIf="session['ACN'] || session['ABN']" />
      <div class="row clearfix flex">
        <div class="row clearfix full-width text-center" *ngIf="session['ACN']">
          <p class="small rm-m">
            ACN: <strong>{{ session['ACN'] }}</strong>
          </p>
        </div>
        <div class="row clearfix details flex" *ngIf="session['phone']">
          <mat-icon class="ico">phone</mat-icon>
          <div class="row-content clearfix">
            <p class="rm-mb rm-mt">
              {{ session['phone'] | customPhone: 'landLine' }}
            </p>
            <p class="small">Mobile</p>
          </div>
        </div>
        <div class="flex detail-buttons" *ngIf="operator">
          <button (click)="changePassword()" color="accent" mat-raised-button>
            <mat-icon>vpn_key</mat-icon>
            <span>Change Password</span>
          </button>

          <ng-container *ngIf="operator.hasOwnProperty('enabled')">
            <button *ngIf="operator.enabled" (click)="disableLogin()" color="accent" mat-raised-button>
              <mat-icon>block</mat-icon>
              <span>Disable Login</span>
            </button>

            <button *ngIf="!operator.enabled" (click)="enableLogin()" color="accent" mat-raised-button>
              <mat-icon>verified_user</mat-icon>
              <span>Enable Login</span>
            </button>
          </ng-container>

          <button (click)="displayUserInfo()" mat-raised-button color="accent">
            <mat-icon>person</mat-icon>
            <span>Operator Overview</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</mat-card>
