import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { DataSource } from '@angular/cdk/collections';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { map } from 'rxjs/operators';
import { ProductDocumentLibraryCreateComponent } from '../product-document-library-create/product-document-library-create.component';

/**
 * @title Table with pagination
 */
@Component({
  selector: 'app-product-document-library',
  templateUrl: './product-document-library.component.html',
  styleUrls: ['./product-document-library.component.css'],
})
export class ProductDocumentLibraryComponent {
  displayedColumns = ['userId', 'userName', 'itemType', 'brand', 'docs', 'Actions'];
  exampleDatabase = new ExampleDatabase();
  dataSource: ExampleDataSource | null;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public addNewItem = false;

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    this.dataSource = new ExampleDataSource(this.exampleDatabase, this.paginator);
  }

  openModal() {
    const ref = this.dialog.open(ProductDocumentLibraryCreateComponent, {
      width: '800px',
      height: '400px',
    });
    // const sub = ref.componentInstance.closeModal.subscribe(data => {
    //   ref.close();
    // });
  }
}

/** Constants used to fill up our data base. */
const COLORS = ['General Dentistry', 'Specilised Dentistry', 'Oral Hygeine'];
const NAMES = [
  'Veneer',
  'Onlay',
  'Bridge',
  'Cosmetic Orthodontics',
  'Retainer',
  'Splint',
  'All on Four',
  'Occlusal equilibration',
  'Occlusal splint',
  'Extraction',
  'Wisdom Tooth Extraction',
  'Bone Regenration',
  'Jaw Xray',
  'Full Xray',
  'Apicectomy',
  'General Checkup',
  'Extended Checkup',
  'Free Lollypop',
  'Sinus Lift',
];
const DOC = ['Post-Op', 'Pre-Op'];
const BRAND = ['Henry Schrudinger', 'Bayer', 'IG Farben', 'Monsanto', '3M', 'Johnson & Johnson'];

export class UserData {
  id: string;
  name: string;
  brand: string;
  docs: string;
  cost: string;
  itemType: string;
}

/** An example database that the data source uses to retrieve data for the table. */
export class ExampleDatabase {
  /** Stream that emits whenever the data has been modified. */
  dataChange: BehaviorSubject<UserData[]> = new BehaviorSubject<UserData[]>([]);

  get data(): UserData[] {
    return this.dataChange.value;
  }

  constructor() {
    // Fill up the database with 100 users.
    for (let i = 0; i < 100; i++) {
      this.addUser();
    }
  }

  /** Adds a new user to the database. */
  addUser() {
    const copiedData = this.data.slice();
    copiedData.push(this.createNewUser());
    this.dataChange.next(copiedData);
  }

  /** Builds and returns a new User. */
  private createNewUser() {
    const name = NAMES[Math.round(Math.random() * (NAMES.length - 1))];
    const doc = DOC[Math.round(Math.random() * (DOC.length - 1))];
    const brands = BRAND[Math.round(Math.random() * (BRAND.length - 1))];
    return {
      id: (this.data.length + 1).toString(),
      name,
      brand: brands,
      docs: doc,
      cost: Math.round(Math.random() * 100).toString(),
      itemType: COLORS[Math.round(Math.random() * (COLORS.length - 1))],
    };
  }
}

/**
 * Data source to provide what data should be rendered in the table. Note that the data source
 * can retrieve its data in any way. In this case, the data source is provided a reference
 * to a common data base, ExampleDatabase. It is not the data source's responsibility to manage
 * the underlying data. Instead, it only needs to take the data and send the table exactly what
 * should be rendered.
 */
export class ExampleDataSource extends DataSource<any> {
  constructor(private _exampleDatabase: ExampleDatabase, private _paginator: MatPaginator) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<UserData[]> {
    const displayDataChanges = [this._exampleDatabase.dataChange, this._paginator.page];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        const data = this._exampleDatabase.data.slice();

        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}
}
