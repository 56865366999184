import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { BeneficiaryViewComponent } from '../../beneficiary/beneficiary-view/beneficiary-view.component';
import { TreatmentService } from '../shared/treatment.service';
import { treatmentUtil } from '../shared/treatmentUtil';
import { TreatmentCategoryViewComponent } from '../treatment-category-view/treatment-category-view.component';
import { TreatmentTypeViewComponent } from '../treatment-type-view/treatment-type-view.component';

@Component({
  selector: 'app-brand-view',
  templateUrl: './treatment-brand-view.component.html',
  styleUrls: ['./treatment-brand-view.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.stacked-card-view', style({ opacity: 0 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.stacked-card-view', style({ opacity: 1 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class TreatmentBrandViewComponent implements OnInit {
  @Input()
  brandID;

  @Input()
  isGlobal = false;

  @Input()
  merchantID;

  @Output()
  close = new EventEmitter();

  @Output()
  saveObject = new EventEmitter();

  isModal = false;

  treatment;
  isActive = false;

  useDefault = false;

  treatmentUtil = new treatmentUtil();

  isPromoterOrAdmin = false;

  diagnosticNote;
  treatmentNote;

  productList = [];

  constructor(
    private authenticationServer: AuthenticationService,
    private TreatmentService: TreatmentService,
    private activeRoute: ActivatedRoute,
    private dialog: MatDialog,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.brandID) {
        this.brandID = data.brandID;
      }

      if (data.merchantID) {
        this.merchantID = data.merchantID;
      }

      if (data.isGlobal != null) {
        this.isGlobal = data.isGlobal;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationServer.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      this.activeRoute.params.subscribe((params) => {
        if (params['merchantID']) {
          this.merchantID = params['merchantID'];
        }

        if (params['brandID']) {
          this.brandID = params['brandID'];
        }

        if (this.isPromoterOrAdmin == false) {
          this.isGlobal = false;
        }

        this.setup();
      });
    });
  }

  viewCategory(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(TreatmentCategoryViewComponent, {
        data: {
          categoryID: id,
        },

        width: '700px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewType(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(TreatmentTypeViewComponent, {
        data: {
          typeID: id,
          isGlobal: true,
        },

        width: '700px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewSupplier(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(BeneficiaryViewComponent, {
        data: id,
        width: '600px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  setup() {
    const p = {
      merchantID: this.merchantID,
    };

    if (this.isGlobal == true && this.isPromoterOrAdmin == true) {
      this.TreatmentService.getTreatmentBrandDetailsGlobal(this.brandID, p).subscribe((res) => {
        if (res) {
          this.treatment = res;
        }
      });
    } else {
      this.TreatmentService.getTreatmentBrandDetails(this.brandID, p, this.isPromoterOrAdmin).subscribe((res) => {
        if (res) {
          this.treatment = res;
        }
      });
    }
  }

  closeModal() {
    this.close.emit(true);
  }

  toNumber(value) {
    return Number(value);
  }
}
