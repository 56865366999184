<div class="rel">
  <div class="card-header primary-gradient-img clearfix confirmModal" *ngIf="isModal == true">
    <button class="btn-close btn-clear mat-button smt white custom-close" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="full-width flex main-title-container">
      <div class="main-icon-container text-center">
        <mat-icon class="main-icon accent-color fas fa-folder-open"></mat-icon>
      </div>
      <div class="row full-width title-container clearfix">
        <div class="title-container-sub">
          <h2 class="text-left title-header rm-m">
            Create Editor Asset

            <span class="subLabel">Choose The Asset type, add a Label and upload your file, optionally you can add a
              Thumbnail.</span>
          </h2>
        </div>
      </div>
    </div>
  </div>

  <mat-dialog-content [ngClass]="{ 'no-modal-height': isModal != true }">
    <div class="full-width flex smt">
      <mat-form-field class="half-width" appearance="outline">
        <mat-label>Type</mat-label>
        <mat-select [(ngModel)]="type" (ngModelChange)="changeType()" name="integrationType" placeholder="Type">
          <div *ngFor="let m of types">
            <mat-option [value]="m.code">
              <mat-icon class="{{ m.icon }} mr" style="color: rgba(0, 0, 0, 0.4)"></mat-icon>
              {{ m.label }}
            </mat-option>
            <hr class="option-divider" />
          </div>
        </mat-select>
      </mat-form-field>

      <div class="full-width ml">
        <mat-checkbox class="full-width mt" [(ngModel)]="isGallery" name="isGallery">
          Add it to gallery
        </mat-checkbox>
      </div>
    </div>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Label</mat-label>
      <input [(ngModel)]="label" matInput placeholder="Label" name="Label" required />
    </mat-form-field>

    <div class="full-width">
      <p class="smb text-center" *ngIf="type == 'EDTRBKG' || type == 'EDTRCHR' || type == 'EDTRIMG'">
        Allowed Extensions : {{ allowedImageExtensions.join(', ') }}.
      </p>
      <p class="smb text-center" *ngIf="type == 'EDTRDOC'">
        Allowed Extensions : {{ allowedDocumentExtensions.join(', ') }}.
      </p>
      <p class="smb text-center" *ngIf="type == 'EDTRJVS'">
        Allowed Extensions : {{ allowedJSExtensions.join(', ') }}.
      </p>
      <p class="smb text-center" *ngIf="type == 'EDTRSTL'">
        Allowed Extensions : {{ allowedStyleExtensions.join(', ') }}.
      </p>

      <div class="full-width flex mb">
        <div class="full-width row blob-for-editor-container-thumbnail">
          <div class="full-width">
            <h3 class="sr-title full-width text-center" *ngIf="type == 'EDTRBKG'">Background Image</h3>
            <h3 class="sr-title full-width text-center" *ngIf="type == 'EDTRCHR'">Character</h3>
            <h3 class="sr-title full-width text-center" *ngIf="type == 'EDTRIMG'">Image</h3>
            <h3 class="sr-title full-width text-center" *ngIf="type == 'EDTRDOC'">Document</h3>
            <h3 class="sr-title full-width text-center" *ngIf="type == 'EDTRJVS'">Javascript File</h3>
            <h3 class="sr-title full-width text-center" *ngIf="type == 'EDTRSTL'">CSS File</h3>

            <app-ep-document-view-image class="squareImg squareImg-editor"
              *ngIf="fileLink && (type == 'EDTRBKG' || type == 'EDTRCHR' || type == 'EDTRIMG')" [hideZoomButton]="false"
              [defaultPicture]="
                'url(https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/doc-holder-editor-modified.png)'
              " [link]="fileLink">
            </app-ep-document-view-image>

            <app-file-list class="full-width"
              *ngIf="fileID && type != 'EDTRBKG' && type != 'EDTRCHR' && type != 'EDTRIMG'" [defaultLabel]="label"
              [showInfo]="false" [title]="false" [files]="[fileID]" [canGallery]="false" [canPrivateDocument]="false"
              [canEdit]="false" [canAction]="false">
            </app-file-list>

            <app-ep-document-view-image class="squareImg squareImg-editor" *ngIf="!fileLink" [hideZoomButton]="false"
              [defaultPicture]="
                'url(https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/doc-holder-editor-modified.png)'
              ">
            </app-ep-document-view-image>
          </div>
          <div class="clearfix full-width">
            <app-file-uploader class="text-center clearfix full-width"
              *ngIf="type == 'EDTRBKG' || type == 'EDTRCHR' || type == 'EDTRIMG'" [fileName]="'File'"
              [allowedExtensions]="allowedImageExtensions" [maxFileSize]="5" [isImageCropping]="true" [canSkip]="true"
              [limit]="1" [uploadDirectly]="true" [isDragable]="true"
              [title]="'CLICK HERE to upload (Max size: ' + 5 + 'MB)'" (onCompleteAll)="onCompleteFile($event)">
            </app-file-uploader>

            <app-file-uploader class="text-center clearfix full-width" *ngIf="type == 'EDTRDOC'" [fileName]="'File'"
              [allowedExtensions]="allowedDocumentExtensions" [maxFileSize]="5" [isImageCropping]="true"
              [canSkip]="true" [limit]="1" [uploadDirectly]="true" [isDragable]="true"
              [title]="'CLICK HERE to upload (Max size: ' + 5 + 'MB)'" (onCompleteAll)="onCompleteFile($event)">
            </app-file-uploader>

            <app-file-uploader class="text-center clearfix full-width" *ngIf="type == 'EDTRJVS'" [fileName]="'File'"
              [allowedExtensions]="allowedJSExtensions" [maxFileSize]="5" [isImageCropping]="true" [canSkip]="true"
              [limit]="1" [uploadDirectly]="true" [isDragable]="true"
              [title]="'CLICK HERE to upload (Max size: ' + 5 + 'MB)'" (onCompleteAll)="onCompleteFile($event)">
            </app-file-uploader>

            <app-file-uploader class="text-center clearfix full-width" *ngIf="type == 'EDTRSTL'" [fileName]="'File'"
              [backgroundColor]="'none'" [allowedExtensions]="allowedStyleExtensions" [maxFileSize]="5"
              [isImageCropping]="true" [canSkip]="true" [limit]="1" [uploadDirectly]="true" [isDragable]="true"
              [title]="'CLICK HERE to upload (Max size: ' + 5 + 'MB)'" (onCompleteAll)="onCompleteFile($event)">
            </app-file-uploader>
          </div>
        </div>

        <div class="full-width row blob-for-editor-container-thumbnail">
          <div class="full-width">
            <h3 class="sr-title full-width text-center">Thumbnail</h3>
            <app-ep-document-view-image class="squareImg squareImg-editor" *ngIf="thumbnailLink"
              [hideZoomButton]="false" [defaultPicture]="
                'url(https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/img-holder-editor-modified.png)'
              " [link]="thumbnailLink">
            </app-ep-document-view-image>
            <app-ep-document-view-image class="squareImg squareImg-editor" *ngIf="!thumbnailLink"
              [hideZoomButton]="false" [defaultPicture]="
                'url(https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/img-holder-editor-modified.png)'
              ">
            </app-ep-document-view-image>
          </div>
          <div class="clearfix full-width">
            <app-file-uploader class="text-center clearfix full-width" [allowedExtensions]="allowedImageExtensions"
              [aspectRatioType]="'full'" [maxFileSize]="2" [isImageCropping]="true" [canSkip]="true" [limit]="1"
              [uploadDirectly]="true" [isDragable]="true" [title]="'CLICK HERE to upload (Max size: ' + 2 + 'MB)'"
              (onCompleteAll)="onCompleteThumbnail($event)">
            </app-file-uploader>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <hr />
  <div class="drop-button text-center mt full-width" *ngIf="isModal == true">
    <button class="pull-right btn-large" [disabled]="!fileID || !label || !type" (click)="create()" mat-raised-button
      color="accent">
      Create
    </button>

    <button class="pull-left btn-large" (click)="closeEvent()" mat-raised-button color="primary">close</button>
  </div>
</div>
