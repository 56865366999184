<mat-sidenav-container class="profile-container" *ngIf="merchantProspect">
  <mat-sidenav class="profile-sidebar" #sidenavProfile [mode]="navMode" [opened]="openFlag" [position]="'end'">
    <div class="sticky">
      <section class="profile-header primary-gradient-img rel">
        <app-ep-document-view-image
          class="profile-lead-image"
          [defaultPicture]="
            'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/practice-pic.png'
          "
          [link]="profileLink"
          tabindex="0"
        ></app-ep-document-view-image>

        <div class="profile-header-details">
          <h2>{{ merchantProspect['TradingAs'] }}</h2>
          <p class="small" *ngIf="merchantProspect['DateTimeCreated']">
            Created Date: {{ merchantProspect['DateTimeCreated'] | customDateTime }}
          </p>
          <p class="small" *ngIf="merchantProspect['LastModified_Human']">
            Last modifed Date: {{ merchantProspect['LastModified_Human'] | customDateTime }}
          </p>
        </div>

        <div class="ctrlbtn">
          <button
            class="documentCreated"
            (click)="uploadDocument()"
            mat-mini-fab
            color="accent"
            matTooltip="Upload Document"
            mat-TooltipPosition="left"
          >
            <mat-icon class="fas fa-file-upload"></mat-icon>
          </button>
        </div>
      </section>
    </div>
  </mat-sidenav>

  <div class="profile-content">
    <div class="full-width toggle-card mb clearfix">
      <mat-button-toggle-group
        class="btn-group session-profile rm-m mat-elevation-z4 full-width flex"
        [(ngModel)]="settings"
      >
        <mat-button-toggle class="full-width text-center" [value]="'practice'">
          <mat-icon class="fas fa-store-alt"></mat-icon>
          {{ "KEYWORD.practice" | translate | titlecase}} Details
        </mat-button-toggle>

        <mat-button-toggle class="full-width text-center" [value]="'staff'">
          <mat-icon class="fas fa-users"></mat-icon>
          {{ "KEYWORD.practice" | translate | titlecase}} Staff
        </mat-button-toggle>

        <mat-button-toggle class="full-width text-center" [value]="'message'">
          <mat-icon class="fas fa-comments"></mat-icon>
          Messages
        </mat-button-toggle>

        <mat-button-toggle class="full-width text-center" [value]="'activity-log'">
          <mat-icon class="fas fa-clipboard-list"></mat-icon>
          Notes
        </mat-button-toggle>

        <mat-button-toggle class="full-width text-center" [value]="'document'">
          <mat-icon class="fas fa-file-alt"></mat-icon>
          Documents
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="tab-content clearfix" *ngIf="settings == 'practice'">
      <div class="row clearfix">
        <form #PracticeDetails="ngForm">
          <div class="row clearfix flex flexwrap">
            <!-- name and personal info -->
            <mat-card class="card full-width two-third">
              <div class="colour-stripe"></div>
              <h3 class="sr-title rm-m sm-h3">Main {{ "KEYWORD.practice" | translate }} details</h3>
              <hr class="mb"/>
              <div class="row clearfix">
                <div class="row clearfix flex full-width">
                  <div class="icons-col">
                    <mat-icon>store_mall_directory</mat-icon>
                  </div>
                  <div class="row-content-col flex">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Trading as</mat-label>
                      <input
                        [(ngModel)]="merchantProspect['TradingAs']"
                        name="Trading"
                        matInput
                        placeholder="Trading as"
                        required
                      />
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Legal entity name</mat-label>
                      <input
                        [(ngModel)]="merchantProspect['Name']"
                        name="entity"
                        matInput
                        placeholder="Legal entity name"
                        required
                      />
                    </mat-form-field>
                  </div>
                </div>

                <div class="row clearfix flex full-width">
                  <div class="icons-col">
                    <mat-icon>domain</mat-icon>
                  </div>
                  <div class="row-content-col flex">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>ABN</mat-label>
                      <input [(ngModel)]="merchantProspect['ABN']" name="ABN" matInput placeholder="ABN"/>
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>ACN</mat-label>
                      <input [(ngModel)]="merchantProspect['ACN']" name="ACN" matInput placeholder="ACN"/>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </mat-card>

            <mat-card class="card full-width third">
              <div class="colour-stripe"></div>
              <h3 class="sr-title rm-m sm-h3">{{ "KEYWORD.practice" | translate | titlecase}} Image</h3>
              <hr class="mb"/>
              <div class="row clearfix text-center mt">
                <div class="upload-container">
                  <app-file-uploader
                    class="text-center clearfix full-width noLowerPad avatarUploader"
                    [allowedExtensionTypes]="['image']"
                    [limit]="1"
                    [uploadDirectly]="true"
                    [isDragable]="true"
                    [keepOriginal]="false"
                    [isFixedAspectRatio]="true"
                    [resizeToHeight]="400"
                    [resizeToWidth]="400"
                    (onCompleteAll)="onCompleteAllPractice($event)"
                  >
                  </app-file-uploader>
                  <app-ep-document-view-image
                    class="profile-lead-image"
                    [defaultPicture]="
                      'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
                    "
                    [link]="profileLink"
                  >
                  </app-ep-document-view-image>
                </div>
                <p class="small">
                  To upload a profile picture, please drag a <strong>.jpg or .png file</strong> into the grey area.
                </p>
              </div>
            </mat-card>

            <mat-card class="card full-width">
              <div class="colour-stripe"></div>
              <h3 class="sr-title rm-m sm-h3">Profile Description</h3>
              <hr class="mb"/>
              <div class="row clearfix">
                <p class="small rm-mb">
                  Add some descriptive text about yourself, which will be seen by {{ "KEYWORD.patient" | translate }}s
                  and {{"KEYWORD.dentist" | translate}}s previewing your
                  profile.
                </p>
                <app-text-editor-input
                  class="clearfix"
                  [content]="profileDescription"
                  [isPreview]="false"
                  [title]="null"
                  [isAction]="false"
                  [isMultiView]="false"
                  (getContent)="getProfileContent($event)"
                  name="editor"
                >
                </app-text-editor-input>
              </div>
            </mat-card>

            <mat-card class="card full-width third">
              <div class="colour-stripe"></div>
              <h3 class="sr-title rm-m sm-h3">
                Social Media
              </h3>
              <hr class="mb"/>

              <div class="row clearfix">
                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon class="fas fa-globe"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Personal Website</mat-label>
                      <input
                        [(ngModel)]="merchantProspect['URL']"
                        name="URL"
                        matInput
                        placeholder="Please enter the address"
                      />
                    </mat-form-field>
                  </div>
                </div>

                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon class="fab fa-facebook"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Facebook</mat-label>
                      <input
                        [(ngModel)]="merchantProspect['Facebook']"
                        name="facebook"
                        matInput
                        placeholder="Facebook"
                      />
                    </mat-form-field>
                  </div>
                </div>

                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon class="fab fa-instagram"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Instagram</mat-label>
                      <input
                        [(ngModel)]="merchantProspect['Instagram']"
                        name="instagram"
                        matInput
                        placeholder="Instagram"
                      />
                    </mat-form-field>
                  </div>
                </div>

                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon class="fab fa-twitter"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Twitter</mat-label>
                      <input [(ngModel)]="merchantProspect['Twitter']" name="twitter" matInput placeholder="Twitter"/>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon class="fab fa-linkedin"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>LinkedIn</mat-label>
                      <input
                        [(ngModel)]="merchantProspect['LinkedIn']"
                        name="linkedin"
                        matInput
                        placeholder="LinkedIn"
                      />
                    </mat-form-field>
                  </div>
                </div>

                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon class="fab fa-pinterest"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Pinterest</mat-label>
                      <input
                        [(ngModel)]="merchantProspect['Pinterest']"
                        name="Pinterest"
                        matInput
                        placeholder="Pinterest"
                      />
                    </mat-form-field>
                  </div>
                </div>

                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon class="fab fa-tumblr"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Tumblr</mat-label>
                      <input [(ngModel)]="merchantProspect['Tumblr']" name="Tumblr" matInput placeholder="Tumblr"/>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon class="fab fa-vimeo"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Vimeo</mat-label>
                      <input [(ngModel)]="merchantProspect['Vimeo']" name="Vimeo" matInput placeholder="Vimeo"/>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </mat-card>

            <mat-card class="card full-width two-third">
              <div class="colour-stripe"></div>
              <h3 class="sr-title rm-m sm-h3">Address details</h3>
              <hr class="mb"/>
              <app-address-input
                class="full-width"
                [displayMap]="false"
                [canClose]="false"
                [postCode]="merchantProspectPostCode"
                [state]="merchantProspectState"
                [suburb]="merchantProspectSuburb"
                [streetNumber]="merchantProspectStreetNumber"
                [streetName]="merchantProspectStreetName"
                [unitNumber]="merchantProspectUnitNumber"
                [streetType]="merchantProspectStreetType"
                [latitude]="merchantProspectLatitude"
                [longitude]="merchantProspectLongitude"
                [isRequired]="true"
                (getAdress)="getAdress($event)"
                (validate)="isAdressValidEvent($event)"
              >
              </app-address-input>
            </mat-card>

            <mat-card class="card full-width third">
              <div class="colour-stripe"></div>
              <h3 class="sr-title rm-m sm-h3">Contact Details</h3>
              <hr class="mb"/>
              <div class="row clearfix">
                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon>phone</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <div class="row clearfix">
                      <app-phone-input
                        class="full-width"
                        [isLandLine]="true"
                        [label]="'Phone Number'"
                        [required]="true"
                        [phoneNumber]="merchantProspectPhone"
                        (validate)="validatePhoneEvent($event)"
                        (getPhone)="getPhone($event)"
                      >
                      </app-phone-input>
                    </div>
                  </div>
                </div>
                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon>phone_android</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <div class="row clearfix">
                      <app-phone-input
                        class="full-width"
                        [isLandLine]="false"
                        [label]="'Mobile'"
                        [required]="false"
                        [phoneNumber]="merchantProspectMobile"
                        (validate)="validateMobileEvent($event)"
                        (getPhone)="getMobile($event)"
                      >
                      </app-phone-input>
                    </div>
                  </div>
                </div>
                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon>email</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <div class="row clearfix">
                      <app-email-input
                        class="full-width"
                        [label]="'Email'"
                        [required]="true"
                        [email]="merchantProspectEmail"
                        (getEmail)="getEmail($event)"
                        (validate)="validateEmailEvent($event)"
                      >
                      </app-email-input>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>

            <mat-card class="card full-width third">
              <div class="colour-stripe"></div>
              <h3 class="sr-title rm-m sm-h3">{{ "KEYWORD.patient" | translate | titlecase }} communication</h3>
              <hr class="mb"/>
              <div class="row clearfix">
                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon class="fas fa-reply-all"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <app-email-input
                      class="full-width"
                      [email]="merchantProspectReplyToEmail"
                      [label]="'Reply-to address'"
                      (getEmail)="getReplyToEmail($event)"
                      (validate)="validateReplyToEmailEvent($event)"
                    >
                    </app-email-input>
                    <p class="small">
                      Enter an email address here and we will automatically forward you any
                      {{ "KEYWORD.patient" | translate }} responses to communication sent via the platform.
                    </p>
                  </div>
                </div>

                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon class="fas fa-calendar-check"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Enter URL</mat-label>
                      <input
                        [(ngModel)]="merchantProspect.ThirdPartyBooking_URL"
                        name="thirdPartyUrl"
                        matInput
                        placeholder="Enter URL"
                      />
                    </mat-form-field>

                    <p class="small">
                      At the end of the invitation process you can route your {{ "KEYWORD.patient" | translate }}s to
                      your third-party booking engine by entering the URL here.

                      <br/>(Be sure to add the entire address, <strong>including</strong> the 'https' part of the URL.
                    </p>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </form>
        <div class="row clearfix">
          <button class="" (click)="goBack()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>
            Back
          </button>

          <button
            class="pull-right"
            [disabled]="!PracticeDetails.form.valid"
            (click)="editPractice()"
            mat-raised-button
            color="accent"
          >
            <mat-icon>save</mat-icon>
            Save
          </button>
        </div>
      </div>
    </div>

    <div class="tab-content clearfix" *ngIf="settings == 'staff'">
      <app-dentist-list class="noEmbedCard full-width" [merchantID]="merchantProspect['ID']"></app-dentist-list>
      <!-- <div class="row clearfix full-width" *ngIf="!dentistID">
            <app-dentist-list [merchantID]="merchant['ID']" class="noEmbedCard full-width">
            </app-dentist-list>
        </div>

        <div class="row clearfix full-width" *ngIf="dentistID">
          <div class="full-width rel">
            <button mat-raised-button color="accent" class="pull-right" (click)="displayDentistList()">
              <mat-icon>chevron_left</mat-icon> {{"DentistList" | translate}}
            </button>
          </div>
          <app-dentist-profile [dentistID]="dentistID" [hideSide]="true"></app-dentist-profile>
        </div> -->
      <div class="clearfix mt">
        <button class="" (click)="goBack()" mat-raised-button color="primary">
          <mat-icon>chevron_left</mat-icon>
          Back
        </button>
      </div>
    </div>

    <div class="tab-content clearfix" *ngIf="settings == 'message'">
      <app-message-timeline class="full-width" [cardID]="merchantProspect['ID']"></app-message-timeline>

      <!-- back btn -->
      <div class="row clearfix mt">
        <button class="" (click)="goBack()" mat-raised-button color="primary">
          <mat-icon>chevron_left</mat-icon>
          Back
        </button>
      </div>
    </div>

    <div class="tab-content clearfix" *ngIf="settings == 'activity-log'">
      <app-action-log-list
        class="full-width"
        *ngIf="isPromoterOrAdmin != true"
        [targetID]="merchantProspect['ID']"
        [withoutAutoAction]="true"
        [withoutInternalAction]="true"
      ></app-action-log-list>
      <app-action-log-list class="full-width" *ngIf="isPromoterOrAdmin == true" [targetID]="merchantProspect['ID']">
      </app-action-log-list>

      <!-- back btn -->
      <div class="row clearfix mt">
        <button class="" (click)="goBack()" mat-raised-button color="primary">
          <mat-icon>chevron_left</mat-icon>
          Back
        </button>
      </div>
    </div>

    <div class="tab-content clearfix" *ngIf="settings == 'document'">
      <app-document-list-mini class="full-width" [cardID]="merchantProspect['ID']"></app-document-list-mini>

      <!-- back btn -->
      <div class="row clearfix mt">
        <button class="" (click)="goBack()" mat-raised-button color="primary">
          <mat-icon>chevron_left</mat-icon>
          Back
        </button>
      </div>
    </div>
  </div>
</mat-sidenav-container>
