<div class="row clearfix" [class.PanelOpen]="hideMerchantSelect == true">
  <div class="clearfix">
    <div>
      <div class="row clearfix">
        <app-ai-scans-list-global
          *ngIf="merchantAll != 'none' && allList"
          [merchantID]="merchantAll"
          [setPatients]="setPatients"
          (detailView)="detailView($event)"
          (getSetPatients)="getSetPatientsEvent($event)"
        ></app-ai-scans-list-global>
      </div>
    </div>
  </div>
</div>
