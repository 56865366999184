<h1>File manager</h1>

<mat-tab-group dynamicHeight="true">
  <mat-tab label="Documents">
    <app-media-gallery-documents></app-media-gallery-documents>
  </mat-tab>
  <mat-tab label="Uploaded Images">
    <app-media-gallery-images></app-media-gallery-images>
  </mat-tab>
</mat-tab-group>
