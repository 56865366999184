<button class="btn-close btn-clear adjust pull-right mat-button" *ngIf="isModal == true" (click)="closeEvent()">
  <mat-icon>clear</mat-icon>
</button>

<aw-wizard #wizard navBarLayout="large-filled-symbols">
  <aw-wizard-step stepTitle="Bank Account Details" navigationSymbol="1">
    <!--  Step 2 - finished -->
    <div class="container" id="step-1">
      <div class="row clearfix">
        <div class="full-width rel">
          <div class="text-center">
            <h2 class="sr-title" *ngIf="userName">
              Create Bank Account for <strong> {{ userName }}</strong>
            </h2>

            <h2 class="sr-title" *ngIf="!userName">New Compliance</h2>
          </div>
        </div>
      </div>

      <div class="clearfix full-width row">
        <app-payment-method-input
          class="full-width"
          [title]="title"
          [description]="description"
          [isRequired]="true"
          (getResult)="getBankResult($event)"
          (validate)="validateBankEvent($event)"
        >
        </app-payment-method-input>
      </div>
      <div class="row clearfix">
        <div class="full-width text-center step-button-row">
          <!-- <button mat-raised-button class="btn-large" color="accent" (click)="test()">test</button> -->
          <button
            class="btn-large"
            [disabled]="validateBank != true"
            (click)="createBankAccount()"
            mat-raised-button
            color="accent"
          >
            Save
          </button>
        </div>
      </div>
    </div>
    <!--  / Step 2  -->
  </aw-wizard-step>
  <aw-wizard-completion-step title="Create!" navigationSymbol="2">
    <div class="container animated fadeInDown" id="step-2" *ngIf="bankAccountCreated">
      <div class="row clearfix text-center">
        <h2 class="sr-title inline-block rm-mt" tabindex="0">Complete!</h2>
      </div>
      <div class="row clearfix">
        <div class="full-width text-center">
          <mat-card>
            <h1 class="sr-title sm-h1">Bank Account created</h1>

            <hr class="mt"/>
            <div class="svg-box">
              <svg class="circular green-stroke">
                <circle class="path" cx="75" cy="75" r="50" fill="none" stroke-width="5" stroke-miterlimit="10" />
              </svg>
              <svg class="checkmark green-stroke">
                <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                  <path class="checkmark__check" fill="none" d="M616.306,283.025L634.087,300.805L673.361,261.53" />
                </g>
              </svg>
            </div>

            <div class="row clearfix">
              <div class="full-width text-center step-button-row">
                <button
                  class="step-next"
                  *ngIf="bankAccountCreated && bankAccountCreated['ID']"
                  (click)="viewBankAccount()"
                  mat-raised-button
                  color="accent"
                >
                  <mat-icon>visibility</mat-icon>
                  View Bank Account
                </button>

                <button
                  class="step-next"
                  *ngIf="bankAccountCreated && bankAccountCreated['ID']"
                  (click)="viewBankAccounts()"
                  mat-raised-button
                  color="accent"
                >
                  <mat-icon>visibility</mat-icon>
                  All User Bank Accounts
                </button>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </aw-wizard-completion-step>
</aw-wizard>
