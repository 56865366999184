import { HammerGestureConfig } from '@angular/platform-browser';
export class CustomHammerConfig extends HammerGestureConfig {
    constructor() {
        super(...arguments);
        this.overrides = {
            pinch: { enable: false },
            rotate: { enable: false },
        };
    }
}
export class SmileMakeoverManagerModule {
}
