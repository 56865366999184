import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-contract-detail-funding-history',
  templateUrl: './contract-detail-funding-history.component.html',
  styleUrls: ['./contract-detail-funding-history.component.css'],
})
export class ContractDetailFundingHistoryComponent implements OnInit {
  @Input()
  contractObject;

  @Output()
  closeModal = new EventEmitter();

  isModal = false;

  contract;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.contractObject = data;
      this.isModal = true;
    }
  }

  ngOnInit() {
    if (this.contractObject && typeof this.contractObject == 'object') {
      this.contract = this.contractObject;
    }
  }

  closeEvent() {
    this.closeModal.emit(true);
  }
}
