<div class="rel">
  <aw-wizard #wizard [disableNavigationBar]="true" navBarLayout="large-filled-symbols">
    <aw-wizard-step [navigationSymbol]="{ symbol: start }"
                    [stepTitle]="'Choose ' + ('KEYWORD.patient' | translate | titlecase)"
                    awOptionalStep>
      <!-- Step 1  -->
      <div class="container" id="step-1">
        <div class="row clearfix">
          <div class="full-width rel">
            <div class="text-center">
              <h2 class="sr-title inline-block pull-left" *ngIf="patient && patientID">
                Assist {{ patient['firstName'] }} in Applying for Finance
              </h2>
              <h2 class="sr-title inline-block pull-left" *ngIf="!patientID">
                Assist the {{ "KEYWORD.patient" | translate | titlecase }} in Applying for Finance
              </h2>
              <!-- Patient Lookup Trigger -->
              <button class="btn-small mt mb pull-right" id="searchPatient" *ngIf="displayLookup == true"
                      (click)="openPatientLookUpDialog()" mat-raised-button color="primary">
                {{ "KEYWORD.patient" | translate | titlecase }} LookUp
                <mat-icon>contacts</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <mat-card>
          <div class="colour-stripe"></div>

          <div class="clearfix" *ngIf="isPromoterOrAdmin == true && displayLookup == true">
            <p class="help-label">Assist the {{ "KEYWORD.patient" | translate }} in applying now on behalf of the
              merchant</p>
            <div class="row clearfix selectMerchant">
              <div class="icons-col">
                <mat-icon class="fas fa-store-alt"></mat-icon>
              </div>
              <div class="row-content-col">
                <mat-form-field class="margin-btm half-width" appearance="outline">
                  <mat-label>Please Select a Merchant</mat-label>
                  <mat-select class="" [(ngModel)]="merchant" (ngModelChange)="changeMerchant($event)"
                              name="MerchantName" placeholder="Merchant">
                    <mat-option [value]="null"> My Account</mat-option>
                    <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m">
                      <span class="list-label pull-right" *ngIf="m['Status'] && m['Status'] == 'Active'"
                            [style.background]="'#00d07a'">
                        {{ m['Status'] }}
                      </span>

                      <span class="list-label pull-right" *ngIf="m['Status'] && m['Status'] != 'Active'"
                            [style.background]="'#ff5722'">
                        {{ m['Status'] }}
                      </span>
                      {{ m['TradingAs'] || m['CalculatedName'] }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <button class="pull-right merchBtn" *ngIf="merchant" (click)="viewMerchant()" mat-raised-button
                        color="primary">
                  <mat-icon>person</mat-icon>
                  <span class="mobHide">View Merchant</span>
                </button>
              </div>
            </div>
            <hr class="mt mb"/>
          </div>

          <p class="help-label">
            Please start by entering the details of your {{ "KEYWORD.patient" | translate }}. You can use the '{{
            'KEYWORD.patient' | translate | titlecase
            }}
            Lookup' button to search your existing {{ "KEYWORD.patient" | translate }} records.
          </p>
          <form #createForm="ngForm">
            <div class="row clearfix">
              <!-- <div class="half-width"> -->
              <div class="row clearfix">
                <div class="row-content-col">
                  <div class="clearfix">
                    <div class="row clearfix flex">
                      <!-- Gender Select -->
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Gender</mat-label>
                        <mat-select class="" [(ngModel)]="patient.genderCode" [disabled]="isFinDMatch" name="gender"
                                    placeholder="Gender" required>
                          <mat-option *ngFor="let gender of genders" [value]="gender.Code">
                            {{ gender.Label }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <!-- Title Select -->
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Title</mat-label>
                        <mat-select class="full-width" [(ngModel)]="patient.title" [disabled]="isFinDMatch" name="title"
                                    placeholder="Title">
                          <mat-option *ngFor="let title of titles" [value]="title.Label">
                            {{ title.Label }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row clearfix flex">
                    <!-- First Name -->
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>First Name</mat-label>
                      <input class="mat-block" [(ngModel)]="patient.firstName" [disabled]="isFinDMatch" name="firstName"
                             matInput placeholder="{{ 'PLACEHOLDER.First Name' | translate }} " required/>
                    </mat-form-field>

                    <!-- Middle Name -->
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Middle Name</mat-label>
                      <input class="mat-block" [(ngModel)]="patient.middleName" [disabled]="isFinDMatch"
                             name="middleName" matInput placeholder="{{ 'PLACEHOLDER.Middle Name' | translate }} "/>
                    </mat-form-field>

                    <!-- Surname -->
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Surname</mat-label>
                      <input class="mat-block" [(ngModel)]="patient.lastName" [disabled]="isFinDMatch" name="lastName"
                             matInput placeholder="{{ 'PLACEHOLDER.Surname' | translate }} " required/>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <!-- </div> -->
              <hr class="mt mb" />


              <div class="patient-details mt" #patientContactBlock>
                <div class="row clearfix">
                  <div class="icons-col">
                    <mat-icon class="">phone</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <div class="row clearfix flex">

                      <app-phone-input class="full-width" [clear]="clearMobile" [phoneNumber]="patient.mobile"
                                       [label]="'Mobile'" [required]="true" [isDisabled]="isFinDMatch"
                                       (validate)="validateMobile($event)" (getPhone)="getMobile($event)">
                      </app-phone-input>

                      <app-phone-input class="full-width" [clear]="clearHomePhone" [phoneNumber]="patient.homePhone"
                                       [isLandLine]="true" [label]="'Home'" [required]="false"
                                       [isDisabled]="isFinDMatch"
                                       (validate)="validateHomePhone($event)" (getPhone)="getHomePhone($event)">
                      </app-phone-input>

                      <app-phone-input class="full-width" [clear]="clearWorkPhone" [phoneNumber]="patient.workPhone"
                                       [isLandLine]="true" [label]="'Work'" [required]="false"
                                       [isDisabled]="isFinDMatch"
                                       (validate)="validateWorkPhone($event)" (getPhone)="getWorkPhone($event)">
                      </app-phone-input>
                    </div>
                  </div>
                </div>
                <div class="row clearfix">
                  <div class="icons-col">
                    <mat-icon>email</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <!-- Email Address -->
                    <app-email-input class="full-width" [clear]="clearEmail" [email]="patient.email" [label]="'Email'"
                                     [required]="true" [isDisabled]="isFinDMatch" (getEmail)="getEmail($event)"
                                     (validate)="validateEmail($event)">
                    </app-email-input>
                  </div>
                </div>

                <div class="row clearfix">
                  <div class="icons-col">
                    <mat-icon>account_circle</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <app-date-input class="full-width" [defaultDate]="dateOfBirth" [clear]="clearDate"
                                    [startView]="'multi-year'" [disabled]="isFinDMatch" [disabledAll]="isFinDMatch"
                                    [isTime]="false"
                                    [maxDate]="'toDay'" (selectedDate)="selectDateToFilter($event)"
                                    label="Date of birth*">
                    </app-date-input>
                  </div>
                </div>

                <div class="row clearfix" *ngIf="
                    (!patient.genderCode ||
                      !isEmailValid ||
                      !_dateOfBirth ||
                      !isMobileValid ||
                      !isHomePhoneValid ||
                      !isWorkPhoneValid) &&
                    isFinDMatch == true &&
                    patientID
                  ">
                  <div class="icons-col">
                    <mat-icon class="error-invitation" (click)="modifyProfile()">info_outline</mat-icon>
                  </div>
                  <div class="row-content-col row clearfix flex diag">
                    <p class="small mt warning" (click)="modifyProfile()">
                      The {{ "KEYWORD.patient" | translate }} you've selected is missing some details. Please click
                      'modify' to update the {{ "KEYWORD.patient" | translate }}'s profile.
                    </p>
                  </div>
                </div>

                <button class="pull-right mat-stroked-mini-fab mat-elevation-z1"
                        *ngIf="isFinDMatch == true && patientID" (click)="clearPatientDetails()" mat-stroked-button
                        color="accent"
                        [matTooltip]="'Clear ' + ('KEYWORD.patient' | translate | titlecase) + ' Details'">
                  <mat-icon class="fas fa-times"></mat-icon>
                </button>
              </div>
            </div>
          </form>
        </mat-card>

        <div class="row clearfix">
          <div class="full-width text-center step-button-row">
            <button class="step-back" [routerLink]="['/merchant/', { outlets: { page: ['home'] } }]" mat-mini-fab
                    color="primary" matTooltip="Return to dashboard">
              <mat-icon>home</mat-icon>
            </button>

            <button class="btn-large" *ngIf="isFinDMatch == false" [disabled]="
                !createForm.form.valid ||
                !isEmailValid ||
                !_dateOfBirth ||
                !isMobileValid ||
                !isHomePhoneValid ||
                !isWorkPhoneValid
              " (click)="step1()" mat-raised-button color="accent">
              Next
              <mat-icon>chevron_right</mat-icon>
            </button>

            <button class="btn-large" *ngIf="isFinDMatch == true && patientID" [disabled]="
                !patient.genderCode ||
                !isEmailValid ||
                !_dateOfBirth ||
                !isMobileValid ||
                !isHomePhoneValid ||
                !isWorkPhoneValid
              " (click)="step1()" mat-raised-button color="accent">
              Next
              <mat-icon>chevron_right</mat-icon>
            </button>

            <button class="pull-right btn-large" *ngIf="isFinDMatch == true && patientID" (click)="modifyProfile()"
                    mat-raised-button color="primary" matTooltip="Edit profile">
              <mat-icon>edit</mat-icon>
              Modify
            </button>
          </div>
        </div>
      </div>
    </aw-wizard-step>
    <aw-wizard-step [navigationSymbol]="{ symbol: start + 1 }" stepTitle="Create Offer">
      <div class="container" id="step-3">
        <div class="row clearfix">
          <div *ngIf="patientID && invitationListExist == true" @ngIfAnimation>
            <div [hidden]="displayList != true">
              <mat-card class="card treatment-card card1 text-center" id="simpleInvitation9">
                <div class="row clearfix tab-header closable">
                  <div class="animate">
                    <div class="row clearfix">
                      <p class="title-invitation-list">
                        You have existing Invitations for this {{ "KEYWORD.patient" | translate }} with unaccepted {{
                        "KEYWORD.treatment" | translate }}. Select and assist or
                        skip to create a new application
                      </p>
                    </div>
                  </div>
                </div>

                <div class="clearfix" style="margin-top: -40px !important">
                  <app-invitation-list-overview class="quick-invitation-list" id="quick-invitation-list-id"
                                                [productGroupCode]="productGroup" [selectedRange]="'days180'"
                                                [patientID]="patientID"
                                                [displayedColumns]="displayedColumns" [hideExtraComponents]="true"
                                                [merchantID]="merchantID"
                                                [isExpired]="false" [hasPatientChoice]="false" [onClickEvent]="true"
                                                (clickEvent)="selectInvitation($event)">
                  </app-invitation-list-overview>
                </div>
              </mat-card>
            </div>
          </div>
          <div *ngIf="displayList != true || invitationListExist != true" @ngIfAnimation>
            <mat-card class="card treatment-card card1 text-center" id="simpleInvitation">
              <div class="row clearfix tab-header closable">
                <div class="animate">
                  <div class="row clearfix">
                    <h3 class="sm-h3 rm-mt rm-mb mb">Create offer for {{ patient.firstName }}</h3>
                    <p class="title-invitation-list mb"
                       *ngIf="(minLoanAmount && maxLoanAmount) || (minLoanAmount == 0 && maxLoanAmount)">
                      {{ "KEYWORD.treatment" | translate | titlecase }} Value should be between
                      <strong>{{ minLoanAmount |
                      customCurrency }}</strong> and
                      <strong>{{ maxLoanAmount | customCurrency }}</strong>.
                    </p>
                  </div>
                </div>
              </div>
              <div class="row clearfix">
                <mat-card class="important-card">
                  <div class="colour-stripe"></div>
                  <h3 class="sr-title sm-h3 rm-m">Enter the {{ "KEYWORD.treatment" | translate }} value</h3>
                  <button class="pull-right position-right" (click)="launchAsModal('418752721', 1)" mat-icon-button
                          color="primary" matTooltip="What's this?" style="margin-top: -33px">
                    <mat-icon class="fas fa-info-circle mat-primary"></mat-icon>
                  </button>
                  <div class="clearfix mt centered">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Please enter a value</mat-label>
                      <input class="mat-block largeInput" [(ngModel)]="totalTreatmentValue" (blur)="validateAmount()"
                             name="totalTreatmentValue" matInput placeholder="Please enter a value" currencyMask
                             required/>
                    </mat-form-field>
                  </div>
                </mat-card>

                <div class="row clearfix warning-panel" *ngIf="noProductFound == true">
                  <div class="icons-col">
                    <mat-icon>warning</mat-icon>
                  </div>
                  <div class="row-content-col row clearfix flex diag">
                    <p class="small cleafix">There is no product with the {{ "KEYWORD.treatment" | translate }} amount
                      you entered.</p>
                  </div>
                </div>
              </div>
            </mat-card>

            <mat-card class="card treatment-card mt">
              <div class="row clearfix tab-header closable">
                <div class="animate">
                  <div class="row clearfix">
                    <h3 class="sm-h3 rm-mt rm-mb">Extra Details (Optional)</h3>
                    <p class="title-invitation-list">Add to the section below or press Next button to continue.</p>
                  </div>
                </div>
              </div>

              <div class="inner-treatment">
                <div class="row clearfix"
                     *ngIf="isModuleAdvancedTreatmentActive == false || !isModuleAdvancedTreatmentActive">
                  <!-- <div class="full-width"> -->

                  <div class="row clearfix">
                    <h3 class="sr-title text-left sm-h3 rm-m mb">
                      Upload or select the {{ "PHRASE.treatment plan" | translate }} or supporting documents...
                    </h3>

                    <ng-container>
                      <app-file-uploader class="text-center mb" [documentType]="'Treatment Plan'"
                                         [fileDescription]="treatmentDocumentDescription"
                                         [fileName]="treatmentDocumentLabel"
                                         [isGallery]="false" [patientDocumentType]="'MEDR_T'" [isDragable]="true"
                                         (onCompleteAll)="onCompleteAllSimple($event)">
                      </app-file-uploader>
                      <app-file-list class=""
                                     [defaultLabel]="('KEYWORD.treatment' | translate | titlecase) + ' Document'"
                                     [documentTypes]="documentTypes"
                                     [addFile]="addFile" [canPatientDocument]="true" [canGallery]="true"
                                     [canPrivateDocument]="true"
                                     [canEdit]="true" [canAction]="false"
                                     (remove)="removeSimpleTreatmentDocument($event)">
                      </app-file-list>
                    </ng-container>
                  </div>
                </div>
                <div class="smt">
                  <div class="clearfix smb">
                    <h3
                      class="sr-title sm-h3 rm-mt mt rm-mb smb">{{ "KEYWORD.practitioner" | translate | titlecase }}</h3>
                    <div class="clearfix flex flex-center">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-select class="" [(ngModel)]="contactID" name="ContactName" placeholder="Select a value">
                          <mat-option *ngFor="let m of contacts | orderBy: ['TradingAs', 'CalculatedName']"
                                      [value]="m.ID">
                            {{ m['CalculatedName'] || m['TradingAs'] }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <button class="ml merchBtn" *ngIf="contact" (click)="viewDentist()" mat-icon-button
                              color="primary" matTooltip="View profile"
                              style="margin-left: 5px; margin-bottom: 10px; height: 55px">
                        <mat-icon class="fas fa-eye mat-primary"></mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="discount-create row clearfix mb"
                     *ngIf="isModulePatientHistoryActive == true && healthHistoryActive == true && context!=='p4p' && context!=='breeze'">
                  <div class="clearfix">
                    <div class="row clearfix">
                      <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb pull-left">Health History</h3>
                    </div>
                    <mat-checkbox [(ngModel)]="medicalHistory" name="discount_toggle"><span class="text">
                        Request that the {{ "KEYWORD.patient" | translate }} completes an online Health History form
                        before attending the next
                        appointment
                      </span></mat-checkbox>
                  </div>
                </div>

                <div class=" ">
                  <div class="clearfix">
                    <h3 class="sr-title sm-h3 rm-mt mt rm-mb">Tags</h3>
                    <div class="clearfix full-width">
                      <app-tag-select [isModal]="false" [merchantID]="merchantID"
                                      [title]="'Attach tags to this treatment'" [canRemove]="true" [canCreate]="true"
                                      [directRemoveDefault]="true" [directRemove]="true" [hasFilter]="true"
                                      [canSelect]="false"
                                      (getSelectedTags)="getTags($event)">
                      </app-tag-select>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
        <div class="row clearfix">
          <div class="full-width step-button-row text-center">
            <button class="step-back" *ngIf="invitationListExist != true" mat-mini-fab color="primary" awPreviousStep>
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>

            <button class="step-back" *ngIf="displayList == true && invitationListExist == true" mat-mini-fab
                    color="primary" awPreviousStep>
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>

            <button class="step-back" *ngIf="displayList != true && invitationListExist == true"
                    (click)="displayInvitationListEvent()" mat-mini-fab color="primary">
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>

            <button class="step-send pull-right" class="btn-large"
              *ngIf="displayList != true || invitationListExist != true"
                    [disabled]="totalTreatmentValue <= 0 || !totalTreatmentValue" (click)="changeAmount()"
                    mat-raised-button
                    color="accent">
              Next
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>

            <button class="step-send pull-right" class="btn-large"
                    *ngIf="displayList == true && invitationListExist == true" (click)="hideInvitationListEvent()"
                    mat-raised-button color="primary">
              Skip & Create
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <!--  / Step 2 -->
    </aw-wizard-step>

    <aw-wizard-step [navigationSymbol]="{ symbol: start + 2 }" stepTitle="Select Product">
      <!--  Step 2  -->
      <h2 class="sr-title inline-block text-center">Select Product from the list</h2>

      <p class="rm-mb text-center">
        If the product is <strong> "greyed out"</strong>, you cannot assist. You will need to send
        a {{"KEYWORD.treatment" | translate}}
        invitation to the {{ "KEYWORD.patient" | translate }} so they can complete it.
      </p>

      <div class="container" id="step-3">
        <div class="row clearfix">
          <div class="mb mt quick-invitation-product" *ngIf="hideProductList != true">
            <app-suitable-product-compact-list class="card-content-product-compact-list" [frequency]="'1'"
                                               [showDisabled]="true" [displaySimulator]="true"
                                               [amount]="suitableProductAmount" [isModal]="false"
                                               [groupCode]="productGroup" [selectTitle]="'Select'"
                                               [merchantKey]="merchantID"
                                               (select)="selectProduct($event)">
            </app-suitable-product-compact-list>
          </div>
        </div>
        <div class="row clearfix">
          <div class="full-width step-button-row text-center">
            <button class="step-back" mat-mini-fab color="primary" awPreviousStep>
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <!--  / Step 2 -->
    </aw-wizard-step>

    <aw-wizard-completion-step [navigationSymbol]="{ symbol: start + 3 }" stepTitle="Apply">
      <div class="container" id="step-4">
        <mat-card class="card successful airplaneCard" @ngIfAnimation [class.wide]="isPromoterOrAdmin == true">
          <div class="row clearfix anim">
            <div class="full-width text-center">
              <div class="column row clearfix">
                <h1 class="sm-h1 sr-title rm-mt">Well Done!</h1>
                <h3 class="sm-h3 rm-mt">You have commenced the application</h3>
              </div>
              <div class="row clearfix mb">
                <app-plane-animation [flyAway]="flyAway"></app-plane-animation>
              </div>
              <div class="row finishBtns flex">
                <button class="step-back btn-small" (click)="exitToDash()" mat-raised-button color="primary">
                  <mat-icon>keyboard_arrow_left</mat-icon>
                  Dashboard
                </button>
                <button class="view-invite" class="btn-small" (click)="openInviteViewDetails()" mat-raised-button
                        color="warn">
                  <mat-icon>visibility_on</mat-icon> View Application
                </button>
                <button class="view-invite" class="btn-small" (click)="invitationPage()" mat-raised-button
                        color="accent">
                  <mat-icon>exit_to_app</mat-icon> Continue Application
                </button>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </aw-wizard-completion-step>
  </aw-wizard>
</div>
