<mat-card class="rel clearfix">
  <div class="card-header primary-gradient-img clearfix stacked">
    <div class="row clearfix">
      <div class="row titleArea clearfix">
        <app-card-picture
          class="profile-picture-sms-view2"
          *ngIf="cardID && tableName"
          [tableName]="tableName"
          [LastModified]="LastModified"
          [cardID]="cardID"
        >
        </app-card-picture>
        <h2 class="summary-header rm-m" *ngIf="cardName">
          {{ cardName }}
          <span class="subLabel"> Message statistics</span>
        </h2>
        <h2 class="summary-header rm-m" *ngIf="!cardName">Message statistics</h2>
      </div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="full-width text-center">
      <div
        class="chart-view"
        *ngIf="cardID && total > 0 && statistics && statistics.length > 0 && loaded == true"
        @simpleFadeAnimation
      >
        <div class="base-chart-margin base-chart-margin-container rel mr">
          <div class="total anim totalPosition">
            <span [endVal]="total" countUp></span>
            <span class="totSub">Total</span>
          </div>
          <app-chart-input
            class="fullHeight visitor-charts mb clearfix"
            [titleIsTop]="false"
            [showLegend]="true"
            [legendPosition]="'bottom'"
            [showLabel]="false"
            [chartType]="'pie'"
            [title]=""
            [data]="statistics | orderBy: ['-value']"
            [dataNameAttribute]="'name'"
            [dataNameAttribute]="'value'"
          >
          </app-chart-input>
        </div>
      </div>

      <app-empty-list-message
        *ngIf="!(cardID && total > 0 && statistics && statistics.length > 0) && loaded == true"
        [message]="'No Statistics Found'"
      >
      </app-empty-list-message>
    </div>
  </mat-dialog-content>

  <div class="drop-button text-center white-background">
    <hr/>

    <button class="action-button btn" (click)="closeEvent()" mat-raised-button color="primary">Close</button>
  </div>
</mat-card>
