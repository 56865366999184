<aw-wizard #wizard [disableNavigationBar]="true" navBarLayout="large-filled-symbols">
  <aw-wizard-step [navigationSymbol]="{ symbol: '1' }"
                  [stepTitle]="('KEYWORD.patient' | translate | titlecase) + ' Information'" awOptionalStep>
    <!-- Step 1  -->
    <div class="container" id="step-1">
      <div class="row clearfix">
        <div class="full-width rel">
          <div class="text-center">
            <h2 class="sr-title inline-block">New {{ "KEYWORD.treatment" | translate | titlecase }} Invitation</h2>
            <div class="pat-lookup-btn">
              <!-- Patient Lookup Trigger -->
              <button class="btn-small mt" id="searchPatient" (click)="openPatientLookUpDialog()" mat-raised-button
                      color="primary">
                {{ "KEYWORD.patient" | translate | titlecase }} Lookup
                <mat-icon>contacts</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <mat-card>
        <div class="colour-stripe"></div>
        <div class="clearfix" *ngIf="isPromoterOrAdmin == true && hideMerchantSelection == false">
          <p class="help-label">
            To send an invitation on behalf of a merchant select a
            merchant below, otherwise, the invitation will be sent by the promoter.
          </p>
          <div class="row clearfix selectMerchant">
            <div class="icons-col">
              <mat-icon class="fas fa-store-alt"></mat-icon>
            </div>
            <div class="row-content-col">
              <mat-form-field class="margin-btm half-width" appearance="outline">
                <mat-label>Please Select a Merchant</mat-label>
                <mat-select class="" [(ngModel)]="merchantID" (selectionChange)="merchantChange()" name="MerchantName"
                            placeholder="Please Select Merchant">
                  <mat-option [value]="null"> My Account</mat-option>
                  <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m['ID']">
                    <span class="list-label pull-right" *ngIf="m['Status'] && m['Status'] == 'Active'"
                          [style.background]="'#00d07a'">
                      {{ m['Status'] }}
                    </span>
                    <span class="list-label pull-right" *ngIf="m['Status'] && m['Status'] != 'Active'"
                          [style.background]="'#ff5722'">
                      {{ m['Status'] }}
                    </span>
                    {{ m['TradingAs'] || m['CalculatedName'] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button class="pull-right merchBtn" *ngIf="merchantID && merchantID != 'none'" (click)="viewMerchant()"
                      mat-raised-button color="primary">
                <mat-icon>person</mat-icon>
                <span class="mobHide">View Merchant</span>
              </button>
            </div>
          </div>
        </div>
        <hr class="mt mb" *ngIf="isPromoterOrAdmin == true"/>
        <!-- toggle to activate parent guardian section -->
        <div class="guardian-toggle" *ngIf="isAllowedGuardian == true && isModuleExperimentalFeaturesActive == true">
          <div class="row clearfix">
            <div class="icons-col">
              <mat-icon class="fas fa-user-friends top"></mat-icon>
            </div>
            <div class="row-content-col">
              <mat-checkbox [(ngModel)]="isGuardian">{{"KEYWORD.patient" | translate | titlecase}} requires
                parent/guardian
              </mat-checkbox>
              <label class="mt">Select this option if your {{ "KEYWORD.patient" | translate }} is under 18 years of age
                and/or requires a legal guardian to handle
                communications &amp; financial decisions.</label>
            </div>
          </div>
          <hr/>
        </div>
        <p class="help-label">
          Please start by entering the details of your {{ "KEYWORD.patient" | translate }}. You can use the '{{
          'KEYWORD.patient' | translate | titlecase
          }}
          Lookup' button to search your existing {{ "KEYWORD.patient" | translate }} records.
        </p>
        <form #createForm="ngForm">
          <div class="row clearfix">
            <!-- <div class="half-width"> -->
            <div class="row clearfix">
              <div class="icons-col">
                <mat-icon [class.top]="isGuardian == true">person</mat-icon>
              </div>
              <div class="row-content-col">
                <div class="clearfix">
                  <div class="row clearfix" *ngIf="isGuardian == true" @ngIfAnimation>
                    <h3 class="sr-title sm-h3 rm-m mb anim">{{ "KEYWORD.patient" | translate | titlecase }} s" |
                      translate }}
                      details</h3>
                  </div>
                  <div class="row clearfix flex">
                    <!-- Gender Select -->
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Gender</mat-label>
                      <mat-select class="" [(ngModel)]="user.gender" [disabled]="isFinDMatch"
                                  (selectionChange)="setUserDataEvent('patient.gender', user.gender)" name="gender"
                                  placeholder="Gender" required>
                        <mat-option *ngFor="let gender of genders" [value]="gender.Code">
                          {{ gender.Label }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <!-- Title Select -->
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Title</mat-label>
                      <mat-select class="full-width" [(ngModel)]="user.title" [disabled]="isFinDMatch"
                                  (selectionChange)="setUserDataEvent('patient.title', user.title)" name="title"
                                  placeholder="Title">
                        <mat-option *ngFor="let title of titles" [value]="title.Label">
                          {{ title.Label }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row clearfix flex">
                  <!-- First Name -->
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>First Name</mat-label>
                    <input class="mat-block" [(ngModel)]="user.firstname" [disabled]="isFinDMatch"
                           (change)="setUserDataEvent('patient.firstName', user.firstname)" name="firstname" matInput
                           placeholder="{{ 'PLACEHOLDER.First Name' | translate }} " required/>
                  </mat-form-field>
                  <!-- Middle Name -->
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Middle Name</mat-label>
                    <input class="mat-block" [(ngModel)]="user.middlename" [disabled]="isFinDMatch"
                           (change)="setUserDataEvent('patient.middleName', user.middlename)" name="middlename" matInput
                           placeholder="{{ 'PLACEHOLDER.Middle Name' | translate }} "/>
                  </mat-form-field>
                  <!-- Surname -->
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Surname</mat-label>
                    <input class="mat-block" [(ngModel)]="user.lastname" [disabled]="isFinDMatch"
                           (change)="setUserDataEvent('patient.lastName', user.lastname)" name="lastMobilename" matInput
                           placeholder="{{ 'PLACEHOLDER.Surname' | translate }} " required/>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <!-- </div> -->
            <hr class="mt mb"/>
            <!-- GUARDIAN DETAILS -->
            <div class="guardian-inputs" *ngIf="isGuardian == true" @ngIfAnimation>
              <div class="anim">
                <div class="row clearfix">
                  <div class="icons-col">
                    <mat-icon class="fas fa-user-friends top"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <h3 class="sr-title sm-h3 rm-mt">Guardian's details</h3>
                  </div>
                </div>
                <!-- <div class="half-width"> -->
                <div class="row clearfix">
                  <div class="icons-col">
                    <!-- <mat-icon>person</mat-icon> -->
                    &nbsp;
                  </div>
                  <div class="row-content-col">
                    <div class="clearfix flex row">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Type of Guardian</mat-label>
                        <mat-select class="" placeholder="Type of guardian">
                          <mat-option> Carer</mat-option>
                          <mat-option> Parent</mat-option>
                          <mat-option> Of the galaxy</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Gender</mat-label>
                        <mat-select class="" [(ngModel)]="user.gender"
                                    (selectionChange)="setUserDataEvent('patient.gender', user.gender)" name="gender"
                                    placeholder="Gender" required>
                          <mat-option *ngFor="let gender of genders" [value]="gender.Code">
                            {{ gender.Label }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Title</mat-label>
                        <mat-select class="" [(ngModel)]="user.title"
                                    (selectionChange)="setUserDataEvent('patient.title', user.title)"
                                    placeholder="Title"
                                    name="title">
                          <mat-option *ngFor="let title of titles" [value]="title.Label">
                            {{ title.Label }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="row clearfix flex">
                      <!-- First Name -->
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>First Name</mat-label>
                        <input class="" [(ngModel)]="user.firstname"
                               (change)="setUserDataEvent('patient.firstname', user.firstname)" matInput
                               placeholder="{{ 'PLACEHOLDER.First Name' | translate }} " name="firstname" required/>
                      </mat-form-field>
                      <!-- Middle Name -->
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Middle Name</mat-label>
                        <input class="" [(ngModel)]="user.middlename"
                               (change)="setUserDataEvent('patient.middleName', user.middlename)" matInput
                               placeholder="{{ 'PLACEHOLDER.Middle Name' | translate }} " name="middlename"/>
                      </mat-form-field>
                      <!-- Surname -->
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Surname</mat-label>
                        <input class="" [(ngModel)]="user.lastname"
                               (change)="setUserDataEvent('patient.lastname', user.lastname)" matInput
                               placeholder="{{ 'PLACEHOLDER.Surname' | translate }} " name="lastname" required/>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <hr class="mb"/>
              </div>
            </div>
            <!-- GUARDIAN CONTACT DETAILS -->
            <div class="guardian-details" #guardianContactInputs *ngIf="isGuardian == true">
              <div class="row clearfix">
                <div class="icons-col">
                  <mat-icon [class.top]="isGuardian == true">phone</mat-icon>
                </div>
                <div class="row-content-col">
                  <!-- <div @ngIfAnimation class="row clearfix">
                    <h3 class="sr-title sm-h3 rm-mt anim">{{ "Guardian's contact information" | translate }}</h3>
                  </div> -->
                  <div class="row clearfix flex mt">
                    <app-phone-input class="full-width" [clear]="clearMobile" [phoneNumber]="user.mobile"
                                     [label]="'Mobile'" [required]="true" (validate)="validateMobile($event)"
                                     (getPhone)="getMobile($event); setUserDataEvent('patient.mobile', $event)">
                    </app-phone-input>
                    <app-phone-input class="full-width" [clear]="clearHomePhone" [phoneNumber]="user.home_phone"
                                     [isLandLine]="true" [label]="'Home'" [required]="false"
                                     (validate)="validateHomePhone($event)"
                                     (getPhone)="getHomePhone($event); setUserDataEvent('patient.homePhone', $event)">
                    </app-phone-input>
                    <app-phone-input class="full-width" [clear]="clearWorkPhone" [phoneNumber]="user.work_phone"
                                     [isLandLine]="true" [label]="'Work'" [required]="false"
                                     (validate)="validateWorkPhone($event)"
                                     (getPhone)="getWorkPhone($event); setUserDataEvent('patient.workPhone', $event)">
                    </app-phone-input>
                  </div>
                </div>
              </div>
              <div class="row clearfix">
                <div class="icons-col">
                  <mat-icon>email</mat-icon>
                </div>
                <div class="row-content-col">
                  <!-- Email Address -->
                  <app-email-input class="full-width" [clear]="clearEmail" [email]="user.email" [label]="'Email'"
                                   [required]="true" (validate)="validateEmail($event)"
                                   (getEmail)="getEmail($event); setUserDataEvent('patient.email', $event)">
                  </app-email-input>
                </div>
              </div>
            </div>

            <div class="patient-details mt" #patientContactBlock
                 *ngIf="isGuardian == false || isGuardianContactSame == false">
              <hr class="mb" *ngIf="isGuardian == true"/>
              <div class="row clearfix">
                <div class="icons-col">
                  <mat-icon class="">phone</mat-icon>
                </div>
                <div class="row-content-col">
                  <div class="row clearfix" *ngIf="isGuardian == true" @ngIfAnimation>
                    <h3 class="sr-title sm-h3 rm-mt anim">
                      {{ "KEYWORD.patient" | translate | titlecase }} s'" | translate }} contact information
                    </h3>
                  </div>
                  <div class="row clearfix flex">

                    <app-phone-input class="full-width" [clear]="clearMobile" [phoneNumber]="user.mobile"
                                     [label]="'Mobile'" [required]="true" [isDisabled]="isFinDMatch"
                      (validate)="validateMobile($event)"
                                     (getPhone)="getMobile($event); setUserDataEvent('patient.mobile', $event)">
                    </app-phone-input>
                    <app-phone-input class="full-width" [clear]="clearHomePhone" [phoneNumber]="user.home_phone"
                                     [isLandLine]="true" [label]="'Home'" [required]="false" [isDisabled]="isFinDMatch"
                      (validate)="validateHomePhone($event)"
                                     (getPhone)="getHomePhone($event); setUserDataEvent('patient.homePhone', $event)">
                    </app-phone-input>
                    <app-phone-input class="full-width" [clear]="clearWorkPhone" [phoneNumber]="user.work_phone"
                                     [isLandLine]="true" [label]="'Work'" [required]="false" [isDisabled]="isFinDMatch"
                      (validate)="validateWorkPhone($event)"
                                     (getPhone)="getWorkPhone($event); setUserDataEvent('patient.workPhone', $event)">
                    </app-phone-input>
                  </div>
                </div>
              </div>
              <div class="row clearfix">
                <div class="icons-col">
                  <mat-icon>email</mat-icon>
                </div>
                <div class="row-content-col">
                  <!-- Email Address -->
                  <app-email-input class="full-width" [clear]="clearEmail" [email]="user.email" [label]="'Email'"
                                   [required]="true" [isDisabled]="isFinDMatch" (validate)="validateEmail($event)"
                                   (getEmail)="getEmail($event); setUserDataEvent('patient.email', $event)">
                  </app-email-input>
                </div>
              </div>
              <div class="row clearfix">
                <div class="icons-col">
                  <mat-icon>account_circle</mat-icon>
                </div>
                <div class="row-content-col">
                  <app-date-input class="full-width" [clear]="clearDate" [isClear]="true" [startView]="'multi-year'"
                                  [disabled]="isFinDMatch" [disabledAll]="isFinDMatch" [isTime]="false"
                                  [defaultDate]="user.dateOfBirth" [maxDate]="'toDay'"
                                  (selectedDate)="setUserDataEvent('patient.dateOfBirth', $event)"
                                  label="Date of birth">
                  </app-date-input>
                </div>
              </div>
              <div class="row clearfix" *ngIf="
                  (!user.gender || !isEmailValid || !isMobileValid || !isHomePhoneValid || !isWorkPhoneValid) &&
                  isFinDMatch == true &&
                  selectedPatientID
                ">
                <div class="icons-col">
                  <mat-icon class="error-invitation" (click)="modifyProfile()">info_outline</mat-icon>
                </div>
                <div class="row-content-col row clearfix flex diag">
                  <p class="small mt warning" (click)="modifyProfile()">
                    The {{ "KEYWORD.patient" | translate }} you've selected is missing some details. Please click
                    'modify' to update the {{ "KEYWORD.patient" | translate }}'s profile.
                  </p>
                </div>
              </div>
              <button class="pull-right mat-stroked-mini-fab mat-elevation-z1"
                      *ngIf="isFinDMatch == true && selectedPatientID" (click)="clearPatientDetails()"
                      mat-stroked-button
                      color="accent" [matTooltip]="'Clear ' + ('KEYWORD.patient' | translate | titlecase) +' Details'">
                <mat-icon class="fas fa-times"></mat-icon>
              </button>
            </div>
            <hr *ngIf="isPatientReferredToggle == true"/>
            <!-- referral -->
            <div class="row clearfix" *ngIf="isPatientReferredToggle == true">
              <div class="column">
                <div class="row clearfix mt">
                  <div class="icons-col">
                    <mat-icon class="noTop">record_voice_over</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <div class="row clearfix">
                      <mat-checkbox class="referral" [(ngModel)]="user.isReferred"
                                    (change)="setUserDataEvent('patient.isReferred', user.isReferred)"
                                    name="isReferred">
                        Referred patient
                      </mat-checkbox>
                      <p class="small">
                        Tick this if the {{ "KEYWORD.patient" | translate }} has been referred to your
                        {{"KEYWORD.practice" | translate}} by another {{ "KEYWORD.dentist" | translate }}.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- </div> -->
          </div>
        </form>
      </mat-card>
      <div class="row clearfix">
        <div class="full-width text-center step-button-row">
          <button class="step-back" *ngIf="isIntegrated == false"
                  [routerLink]="['/merchant/', { outlets: { page: ['home'] } }]" mat-mini-fab color="primary"
                  matTooltip="Return to dashboard">
            <mat-icon>home</mat-icon>
          </button>
          <button class="btn-large" *ngIf="isFinDMatch == false" [disabled]="
              !createForm.form.valid || !isEmailValid || !isMobileValid || !isHomePhoneValid || !isWorkPhoneValid
            " (click)="step1()" mat-raised-button color="accent">
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
          <button class="btn-large" *ngIf="isFinDMatch == true && selectedPatientID"
            [disabled]="!user.gender || !isEmailValid || !isMobileValid || !isHomePhoneValid || !isWorkPhoneValid"
                  (click)="step1()" mat-raised-button color="accent">
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
          <button class="pull-right btn-large" *ngIf="isFinDMatch == true && selectedPatientID"
                  (click)="modifyProfile()" mat-raised-button color="primary" matTooltip="Edit profile">
            <mat-icon>edit</mat-icon>
            Modify
          </button>
        </div>
      </div>
    </div>
    <!--  / Step 1  -->
  </aw-wizard-step>
  <aw-wizard-step [navigationSymbol]="{ symbol: '2' }"
                  [stepTitle]="('KEYWORD.treatment' | translate | titlecase) + ' Details'" awOptionalStep>
    <!--  Step 2  -->
    <div class="container" id="step-2">
      <div class="row clearfix saved card1" *ngIf="isModuleTreatmentTemplateActive == true">
        <div class="full-width flex flexo">
          <div class="full-width">
            <button class="pull-right" (click)="launchAsModal('418331841', 1)" mat-icon-button color="primary"
                    matTooltip="What's this?">
              <mat-icon class="fas fa-info-circle mat-primary"></mat-icon>
            </button>
            <!--
            <button mat-raised-button class=" pull-right" *ngIf="!templates || (templates && templates.length<=0)" color="primary" (click)="InvitationTemplateLookup()"
              aria-label="Click here  to  view your invitation templates">
              <mat-icon class="position fas fa-stamp"></mat-icon> Templates Lookup
            </button> -->

            <button class="pull-right" (click)="InvitationTemplateLookup()" mat-raised-button color="primary">
              <mat-icon class="position fas fa-brush"></mat-icon> Apply Template
            </button>
            <h3 class="sr-title sm-h3 rm-m">Invitation template</h3>
            <p class="rm-m small">There are saved invitation templates which you can apply here.</p>
          </div>
        </div>
      </div>
      <!--{{ "KEYWORD.treatment" | translate }} plan create component -->
      <app-treatment-plan-create class="clearfix full-width" *ngIf="invitationID" [patientID]="selectedPatientID"
                                 [disableTreatmentValue]="disableTreatmentValue" [selectedTab]="selectedTab"
                                 [merchantID]="merchantID"
                                 [invitationID]="invitationID" [refreshTreatment]="refreshTreatment"
                                 [totalTreatmentValue]="totalTreatmentValue"
                                 [treatmentDocumentLabel]="('PHRASE.treatment plan' | translate | titlecase) + ' - ' + user?.firstname + ' ' + user?.lastname"
                                 [informedConsent]="informedConsent" [treatmentDocumentDescription]="
          ('PHRASE.treatment plan' | translate | titlecase) + ' - ' +
          user?.firstname +
          ' ' +
          user?.lastname +
          ' - ' +
          user?.email +
          ' - ' +
          (user?.mobile | customPhone)
        " [invitationTemplateID]="invitationTemplateID" [invitationTemplateLabel]="invitationTemplateLabel"
                                 [simpleTreatmentDocuments]="simpleTreatmentDocuments"
                                 [EPTreatmentDocuments]="EPTreatmentDocuments"
                                 [stockArtDocuments]="stockArtDocuments" [EPstockArtDocuments]="EPstockArtDocuments"
                                 (clearTemplate)="clearTemplate($event)"
                                 (validate)="onValidateTreatmentCreation($event)"
        (informedConsentUpdated)="setInformedConsent($event)"
        (simpleTreatmentDocumentsUpdated)="setSimpleTreatmentDocuments($event)"
        (EPTreatmentDocumentsUpdated)="setEpTreatmentDocuments($event)"
        (stockArtDocumentsUpdated)="setStockArtDocuments($event)"
                                 (EPstockArtDocumentsUpdated)="setEPStockartDocuments($event)"
                                 (refreshTotalCost)="refreshTotalCost($event)"
                                 (templateSelectAll)="templateSelectAll($event)">
      </app-treatment-plan-create>

      <!-- navigation -->
      <div class="row clearfix step2">
        <div class="full-width step-button-row text-center">
          <button class="step-back" (click)="onStepChange()" mat-mini-fab color="primary" awPreviousStep>
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <button class="btn-large" [disabled]="!validateCreatedTreatment" (click)="step3()" mat-raised-button
                  color="accent">
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <!--  / Step 2  -->
  </aw-wizard-step>
  <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Schedule invitation" awOptionalStep>
    <!--  Step 3  -->
    <div class="container" id="step-3" *ngIf="hideAll != true">
      <div class="row clearfix">
        <mat-card>
          <div class="colour-stripe"></div>
          <!-- appointment block -->
          <div class="row clearfix grey top"
               *ngIf="isAllowedAppointment == true && isModuleExperimentalFeaturesActive == true">
            <div class="column clearfix">
              <div class="icons-col">
                <mat-icon class="fas fa-calendar-plus"></mat-icon>
              </div>
              <div class="row-content-col" id="appointment">
                <div class="row clearfix">
                  <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb pull-left">{{ 'Set appointment time' | translate }}</h3>
                </div>
                <div class="row clearfix">
                  <div class="row clearfix">
                    <div class="two-thrd-width">
                      <app-date-input class="thin full-width" [isTime]="true" [label]="'Appointment Date & Time'"
                                      [minDate]="'today'"></app-date-input>
                    </div>
                    <div class="thrd-width">
                      <mat-form-field class="full-width duration" appearance="outline">
                        <mat-label>Approx. duration</mat-label>
                        <mat-select class="" placeholder="Approx. duration">
                          <mat-option> ...</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row clearfix mb">
                    <mat-checkbox class="pull-left">{{"KEYWORD.patient" | translate | titlecase}} requires
                      fasting
                    </mat-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- normal invitation -->
          <div class="row clearfix">
            <div class="column clearfix">
              <div class="icons-col">
                <mat-icon>email</mat-icon>
              </div>
              <div class="row-content-col" id="defaultMes">
                <div class="row clearfix">
                  <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb pull-left">Your personal message</h3>
                  <div class="defaultMsgArea pull-right"
                       *ngIf="defaultMessageLists.length <= 0 && isModuleCustomMessages == true">
                    <!-- <button mat-icon-button color="primary" class="pull-right" matTooltip="What's this?"
                      (click)="viewVideoInfo('Default messages', 'vimeo.com/358964874/19331ac58f')">
                      <mat-icon class="fas fa-info-circle mat-primary"></mat-icon>
                    </button> -->
                    <button class="pull-right" (click)="launchAsModal('358964874', 4)" mat-icon-button color="primary"
                            matTooltip="What's this?">
                      <mat-icon class="fas fa-info-circle mat-primary"></mat-icon>
                    </button>
                    <mat-form-field class="defaultMsg full-width" appearance="outline">
                      <mat-label>Add Default Message</mat-label>
                      <mat-select class="" name="messageOptionNull" placeholder="Add Default Message">
                        <mat-option (click)="addNewMessage()">
                          <p class="small rm-mt rm-mb">
                            <mat-icon class="addnew">add</mat-icon>
                            Create new
                          </p>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="defaultMsgArea" *ngIf="isModuleCustomMessages == true">
                  <!-- <mat-icon *ngIf="defaultMessageLists && defaultMessageLists.length>0" class="pull-left">chat
                    </mat-icon> -->
                  <mat-form-field class="defaultMsg full-width"
                                  *ngIf="defaultMessageLists && defaultMessageLists.length > 0" appearance="outline">
                    <mat-label>Add Default Message</mat-label>
                    <mat-select [(ngModel)]="messageOption" (selectionChange)="defaultMessageChange(messageOption)"
                                name="messageOption" placeholder="Add Default Message">
                      <div class="clearfix flex flex-center mb">
                        <mat-button-toggle-group class="btn-group rm-m selectType full-width" [(ngModel)]="typeFilter"
                                                 value="null">
                          <mat-button-toggle class="" [value]="null"> All</mat-button-toggle>
                          <mat-button-toggle class="" [value]="'0'">{{ "KEYWORD.Practice" | translate
                            }}</mat-button-toggle>
                          <mat-button-toggle class="" [value]="'1'">
                            {{ "BRANDING.Brand Name" | translate | titlecase }}
                          </mat-button-toggle>
                        </mat-button-toggle-group>
                      </div>
                      <mat-option *ngFor="
                          let option of defaultMessageLists
                            | FilterArrayMultipleValue: ['IsPromoterTemplate']:typeFilter
                        " [value]="option">
                        <p class="small pro-label rm-mt rm-mb">
                          <span class="chipPromoter primary-bg pull-left mr"
                                *ngIf="option && option.IsPromoterTemplate == '1'">
                            <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
                          </span>
                          <span class="chipUser pull-left mr"
                                *ngIf="option && (!option.IsPromoterTemplate || option.IsPromoterTemplate != '1')">
                            <mat-icon class="fas fa-user-alt"></mat-icon>
                          </span>
                          {{ option.label }}
                        </p>
                      </mat-option>
                      <mat-divider></mat-divider>
                      <mat-option (click)="addNewMessage()">
                        <p class="small rm-mt rm-mb">
                          <mat-icon class="addnew">add</mat-icon>
                          Create new
                        </p>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- <button mat-icon-button color="primary" class="pull-right" matTooltip="What's this?"
                    (click)="viewVideoInfo('Default messages', 'vimeo.com/358964874/19331ac58f')">
                    <mat-icon class="fas fa-info-circle mat-primary"></mat-icon>
                  </button> -->
                  <button class="pull-right" (click)="launchAsModal('358964874', 4)" mat-icon-button color="primary"
                          matTooltip="What's this?">
                    <mat-icon class="fas fa-info-circle mat-primary"></mat-icon>
                  </button>
                </div>
                <mat-form-field class="full-width" appearance="outline">
                  <textarea class="dentMsg increase-input-field" [(ngModel)]="dentMsg"
                            (change)="setUserDataEvent('message.content', dentMsg)" name="dentMsg" matInput
                            placeholder="Personal message to appear with invitation*"></textarea>
                </mat-form-field>
                <!-- {{convertedMessage}} -->
              </div>
            </div>
          </div>
          <div class="discount-create row clearfix mb"
               *ngIf="isModulePatientHistoryActive == true && healthHistoryActive == true">
            <div class="column clearfix">
              <div class="icons-col">
                <mat-icon class="fas fa-notes-medical" aria-hidden="true"></mat-icon>
              </div>
              <div class="row-content-col">
                <div class="row clearfix">
                  <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb pull-left">Health History</h3>
                </div>
                <mat-checkbox [(ngModel)]="medicalHistory" (change)="setUserDataEvent('medicalHistory', medicalHistory)"
                              name="discount_toggle"><span class="text">
                    Request that the {{ "KEYWORD.patient" | translate }} completes an online Health History form before
                    attending the next
                    appointment
                  </span></mat-checkbox>
              </div>
            </div>
          </div>
          <div class="discount-create row clearfix mb" *ngIf="merchant && merchant['Google_Review_Place_ID']">
            <div class="column clearfix">
              <div class="icons-col">
                <mat-icon class="fas fa-star" aria-hidden="true"></mat-icon>
              </div>

              <div class="row-content-col">
                <div class="row clearfix">
                  <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb pull-left">Google Review</h3>
                  <!-- <button mat-icon-button color="primary" class="pull-right" matTooltip="What's this?"
                  (click)="viewVideoInfo('Cash Discounts', 'vimeo.com/355935655/d2720c17e1')">
                  <mat-icon class="fas fa-info-circle mat-primary"></mat-icon>
                </button> -->
                </div>
                <mat-checkbox [(ngModel)]="googleReviewPrompt"
                  (change)="setUserDataEvent('googleReviewPrompt', googleReviewPrompt)"
                              name="google_review_prompt_toggle"><span
                  class="text"> Prompt {{ "KEYWORD.patient" | translate }} to
                    provide a google review </span></mat-checkbox>
              </div>
            </div>
          </div>

          <div class="discount-create row clearfix mb">
            <div class="column clearfix">
              <div class="icons-col">
                <mat-icon class="fas fa-tags" aria-hidden="true"></mat-icon>
              </div>
              <div class="row-content-col">
                <div class="clearfix mb full-width">
                  <div class="row clearfix">
                    <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb pull-left">Tags</h3>
                  </div>
                  <mat-checkbox class="pull-left" [(ngModel)]="attachTags"
                                (change)="setUserDataEvent('attachTags', attachTags)" name="discount_toggledd"
                                matTooltip="Use these throughout the app to filter {{ 'KEYWORD.patient' | translate }}s (marketing campaigns etc.)">
                    <span class="text"> Choose the tags to group or categorise your patient. </span>
                  </mat-checkbox>
                </div>
                <div class="clearfix full-width" *ngIf="attachTags == true">
                  <app-tag-select [isModal]="false" [defaultSelectedTags]="defaultSelectedTags"
                                  [merchantID]="merchantID" [title]="'Attach tags to this invitation'"
                                  [canRemove]="true"
                                  [canCreate]="true" [directRemoveDefault]="true" [directRemove]="true"
                                  [hasFilter]="true"
                                  [canSelect]="false" (getSelectedTags)="setUserDataEvent('tags', $event)">
                  </app-tag-select>
                </div>
              </div>
            </div>
          </div>

          <div class="discount-create row clearfix" *ngIf="isModuleDiscountsActive == true">
            <div class="column clearfix">
              <div class="icons-col">
                <mat-icon class="fas fa-money-bill-wave" aria-hidden="true"></mat-icon>
              </div>
              <div class="row-content-col">
                <div class="row clearfix">
                  <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb pull-left">Payment in full</h3>
                </div>
                <div class="row full-width clearfix" *ngIf="hideDiscount == true">
                  <button class="pull-right ml btn-small"
                    *ngIf="sessionType == 'admin' || sessionType == 'promoter' || sessionType == 'merchant-admin'"
                          (click)="openDiscountSettingDialog()" mat-raised-button color="accent"
                          style="margin-top: 18px">
                    <mat-icon>settings</mat-icon> Discount settings
                  </button>
                  <p *ngIf="sessionType == 'admin' || sessionType == 'promoter' || sessionType == 'merchant-admin'">
                    Cash Discounts have been disabled in the setting area. To enable, click on the Discount Setting
                    button.
                  </p>
                  <p *ngIf="sessionType != 'admin' && sessionType != 'promoter' && sessionType != 'merchant-admin'">
                    Cash Discounts have been disabled in the setting area. To enable, Contact your {{"KEYWORD.practice"
                    | translate}}
                    administrator.
                  </p>
                </div>
                <div class="row clearfix" *ngIf="hideDiscount != true">
                  <mat-checkbox class="pull-left" [(ngModel)]="cashDiscountOffered"
                                (change)="changeDiscountOffer($event.checked)" name="discount_toggle"><span
                    class="text">Offer a
                      discount payment in full on first visit - cash/card</span></mat-checkbox>
                  <!-- <button mat-icon-button color="primary" class="pull-right" matTooltip="What's this?"
                    (click)="viewVideoInfo('Cash Discounts', 'vimeo.com/355935655/d2720c17e1')">
                    <mat-icon class="fas fa-info-circle mat-primary"></mat-icon>
                  </button> -->
                  <button class="pull-right" (click)="launchAsModal('355935655', 4)" mat-icon-button color="primary"
                          matTooltip="What's this?">
                    <mat-icon class="fas fa-info-circle mat-primary"></mat-icon>
                  </button>
                </div>
                <mat-expansion-panel class="discountPanel" [expanded]="cashDiscountOffered == true">
                  <div class="panel-content" *ngIf="cashDiscountOffered">
                    <!-- <hr class="mb"> -->
                    <div class="row clearfix mt">
                      <div class="column">
                        <mat-card class="mat-elevation-z6 box">
                          <div class="ribbon"><span>DISCOUNT</span></div>
                          <div class="row clearfix flex">
                            <div class="full-width">
                              <div class="col-left mb">
                                <label>{{"KEYWORD.Treatment" | translate}} value:</label>
                                <p class="lead rm-m">
                                  <strong>{{ treat_plan_cost | customCurrency }}</strong>
                                </p>
                              </div>
                            </div>
                            <div class="full-width">
                              <div class="column mb middle">
                                <label class="accent-color">Discount offered:</label>
                                <p class="lead rm-m accent-color">
                                  <strong>{{ cashDiscountAmount | customCurrency }}</strong>
                                </p>
                              </div>
                            </div>
                            <div class="full-width" *ngIf="cashDiscountDate">
                              <div class="col-right mb">
                                <label>Expires:</label>
                                <p class="lead rm-m">
                                  <strong>{{ cashDiscountDate | customDate }}</strong>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="row clearfix button-row" *ngIf="cashDiscountAllowOverride == true">
                            <button class="pull-right btn-small" (click)="openDiscountInvitationDialog()"
                                    mat-raised-button color="accent">
                              <mat-icon>edit</mat-icon> Modify Discount
                            </button>
                          </div>
                          <div class="row clearfix button-row" *ngIf="
                              (sessionType == 'admin' ||
                                sessionType == 'promoter' ||
                                sessionType == 'merchant-admin') &&
                              cashDiscountAllowOverride != true
                            ">
                            <button class="pull-right btn-small" (click)="openDiscountSettingDialog()" mat-raised-button
                                    color="accent">
                              <mat-icon>settings</mat-icon> Discount settings
                            </button>
                          </div>
                          <!-- <div class="row clearfix button-row">
                            <button mat-raised-button color="accent" class="pull-right btn-small "
                              (click)="openDiscountSettingDialog()">
                              <mat-icon>settings</mat-icon> Discount settings
                            </button>
                          </div> -->
                        </mat-card>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </div>
            </div>
          </div>
          <div class="grey">
            <div class="row clearfix">
              <div class="half-width" id="senderName">
                <div class="column clearfix mb">
                  <div class="icons-col">
                    <mat-icon>face</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb">Senders name to appear with message</h3>
                    <div class="clearfix">
                      <!-- Sender Select -->
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Sender</mat-label>
                        <mat-select class="" [(ngModel)]="selectedSender" (selectionChange)="senderChanged()"
                                    name="selectedSender" placeholder="Sender">
                          <mat-option *ngFor="let sender of senders" [value]="sender.ID">
                            {{ sender.CalculatedName }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="half-width" id="setting">
                <div class="column mb">
                  <div class="icons-col">
                    <mat-icon>visibility_on</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb">Preview</h3>
                    <button class="btn-small inline mat-elevation-z1" (click)="openEmailPreviewDialog()"
                            mat-stroked-button color="primary">
                      <mat-icon>drafts</mat-icon>
                      Email Preview
                    </button>
                    <!-- <button mat-raised-button class="btn-small inline" color="primary" (click)="openSmsPreviewDialog()"
                      aria-label="Click here to view SMS Preview">
                      <mat-icon>phone_iphone</mat-icon> Preview SMS
                    </button> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="row clearfix" id="schedule">
              <div class="column clearfix mb">
                <div class="icons-col">
                  <mat-icon>alarm</mat-icon>
                </div>
                <div class="row-content-col">
                  <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb">Schedule</h3>
                  <p>
                    The invitation you created will be sent to
                    <strong><span class="invite-sent-to">{{ user.firstname }}</span>&nbsp;<span
                      class="invite-sent-to">{{ user.lastname }}</span></strong>
                    straight away after you click on "send". If you wish to change the scheduled time please modify
                    below.
                  </p>
                </div>
              </div>
            </div>
            <div class="row clearfix mb" id="scheduleSetting">
              <div class="row clearfix">
                <div class="half-width">
                  <div class="column">
                    <div class="icons-col">
                      <mat-icon>drafts</mat-icon>
                    </div>
                    <div class="row-content-col">
                      <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb">Schedule Email</h3>
                      <div class="input-control">
                        <app-date-input class="thin" [label]="'Email Send Date:'" [defaultDate]="messageDate"
                                        [minDate]="'today'"
                                        (selectedDate)="setUserDataEvent('message.sendDate', $event)">
                        </app-date-input>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="half-width">
                  <div class="icons-col">
                    <mat-icon>phone_iphone</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <h3 class="sr-title sm-h3 rm-mt mt">Schedule SMS</h3>
                    <div class="input-control">
                      <mat-checkbox [(ngModel)]="sendSMS" (change)="setUserDataEvent('message.viaSMS', sendSMS)"
                                    name="sendSMS">Send SMS
                      </mat-checkbox>
                      <span class="send-sms-to">{{ user.mobile | customPhone }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr/>
            <!-- save {{ "KEYWORD.treatment" | translate }}-->
            <div class="saveTreatment mt" *ngIf="isModuleTreatmentTemplateActive == true">
              <div class="row clearfix">
                <div class="column clearfix mb">
                  <div class="icons-col">
                    <mat-icon class="fas fa-save"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <div class="pull-left">
                      <h3 class="sr-title sm-h3 rm-m">Save as template</h3>
                      <p class="rm-m small">
                        You can save this invitation as a template, which can be applied to future invitations.
                      </p>
                    </div>
                    <div class="clearfix pull-right">
                      <button class="btn-back ml pull-right mat-elevation-z2" (click)="saveDefinedTreatment(null)"
                              mat-stroked-button color="primary">
                        Create Template <mat-icon>add_circle</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
      <div class="row clearfix">
        <div class="full-width step-button-row text-center">
          <button class="step-back" (click)="onStepChange()" mat-mini-fab color="primary" awPreviousStep>
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <button class="step-send" class="btn-large" [disabled]="!isSelectedSender || selectedSender == null"
                  (click)="saveInvitation()" mat-raised-button color="accent">
            Send
            <mat-icon>send</mat-icon>
          </button>
          <button class="pull-right btn-large" *ngIf="isIntegrated == false" (click)="saveDraft()" mat-raised-button
                  color="primary">
            Save as a Draft
            <mat-icon>drafts</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <!--  / Step 3 -->
  </aw-wizard-step>
  <!-- The completion step -->
  <aw-wizard-completion-step [navigationSymbol]="{ symbol: '4' }" stepTitle="Send">
    <!--  Step 4 - finished -->
    <div class="container" id="step-4">
      <mat-card class="card successful airplaneCard" @ngIfAnimation [class.wide]="isPromoterOrAdmin == true">
        <div class="row clearfix anim">
          <div class="full-width text-center">
            <div class="column row clearfix">
              <h1 class="sm-h1 sr-title rm-mt">Well Done!</h1>
              <h3 class="sm-h3 rm-mt">
                Your invitation to
                <strong><span class="invite-sent-to">{{ user.firstname }} </span>&nbsp;<span class="invite-sent-to">{{
                  user.lastname
                  }}</span></strong>
                is on it's way!
              </h3>
            </div>
            <div class="row clearfix mb">
              <app-plane-animation [flyAway]="flyAway"></app-plane-animation>
            </div>
            <div class="row finishBtns flex" *ngIf="isIntegrated == false">
              <button class="step-back btn-small" (click)="exitToDash()" mat-raised-button color="primary">
                <mat-icon>keyboard_arrow_left</mat-icon>
                Dashboard
              </button>
              <button class="view-invite" class="btn-small" (click)="openInviteViewDetails()" mat-raised-button
                      color="warn">
                <mat-icon>visibility_on</mat-icon> View Invitation
              </button>
              <button class="view-invite" class="btn-small"
                      *ngIf="isPromoterOrAdmin == true || invitationLinkOnStep == true" (click)="invitationPage()"
                      mat-raised-button color="accent">
                <mat-icon>exit_to_app</mat-icon>
                Invitation Link
              </button>
            </div>
            <div class="profile-link text-center mt"
                 *ngIf="isModulePatientPortalAccessActive == true && isIntegrated == false">
              <hr/>
              <p class="small">
                Want to review and assist your {{ "KEYWORD.patient" | translate }} with the {{ "PHRASE.treatment plan"
                | translate }} just sent? Click on the button below to
                get access before the {{ "KEYWORD.patient" | translate }} leaves.
              </p>
              <button (click)="openAuthCodeDialog()" mat-raised-button color="accent">
                <mat-icon>vpn_key</mat-icon>
                <span>{{"KEYWORD.patient" | translate | titlecase}} Portal Access</span>
              </button>
            </div>

            <div class="profile-link text-center mt"
                 *ngIf="isModulePatientPortalAccessActive == true && isIntegrated == true">
              <hr/>
              <p class="small">
                Want to review and assist your {{ "KEYWORD.patient" | translate }} with the {{ "PHRASE.treatment plan"
                | translate }} just sent? Click on the button below to
                get access before the {{ "KEYWORD.patient" | translate }} leaves.
              </p>
              <button (click)="openAuthCodeDialog(true)" mat-raised-button color="accent">
                <mat-icon>vpn_key</mat-icon>
                <span>{{"KEYWORD.patient" | translate | titlecase}} Portal Access</span>
              </button>
            </div>
            <div class="profile-link text-center mt" *ngIf="isIntegrated == true">
              <hr/>
              <button *ngIf="isCloseWebhookButton == true || redirectURL" (click)="sendResult()" mat-raised-button
                      color="accent">
                <span>Go back</span>
                <mat-icon>keyboard_arrow_right</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
    <!--  / Step 4  -->
  </aw-wizard-completion-step>
</aw-wizard>
