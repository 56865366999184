import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

import { animate, state, style, transition, trigger } from '@angular/animations';
import * as moment from 'moment';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { PerformanceService } from '../shared/performance.service';

@Component({
  selector: 'app-performance-merchant',
  templateUrl: './performance-merchant.component.html',
  styleUrls: ['./performance-merchant.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class PerformanceMerchantComponent implements OnInit {
  @Input()
  colorScheme = Settings.global['chartColors'];

  chartColorScheme = {
    domain: this.colorScheme,
  };

  @Input()
  customColorScheme;

  // DUAL AXIS CHART

  view: any[];

  gradient = false;
  showXAxis = true;
  showYAxis = true;
  showLegend = true;
  showXAxisLabel = true;
  showYAxisLabel = true;
  xAxisLabel = 'date';
  multiyAxisLabel = 'Portfolio Value';
  multiyAxisLabel2 = 'Arrears Value';
  autoScale = true;
  timeLine = true;
  animations = false;
  tooltipDisabled = false;

  //  dentist signup

  invitesSentOpened = null;

  applications = null;

  utils = new UtilsClass();

  constructor(private performanceService: PerformanceService) {}

  ngOnInit() {
    this.setup();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.colorScheme) {
      this.chartColorScheme.domain = this.colorScheme;
    }
  }

  setup() {
    const _startDate = moment().subtract(89, 'days');
    const payload = {
      startDate: this.utils.EPdateFormat(_startDate),
    };
    this.performanceService.getWeeklyPerformance(payload).subscribe((res) => {
      if (
        res &&
        res['ToDate']['ToDate'] &&
        Array.isArray(res['ToDate']['ToDate']) &&
        res['ToDate']['ToDate'].length > 0
      ) {
        const toDates = res['ToDate']['ToDate'];

        const _invitesSent = [];
        const _invitesViewed = [];

        const _appCommenced = [];
        const _appApproved = [];
        const _appActivated = [];

        for (let i = 0; i < toDates.length; i++) {
          _invitesSent.push({
            name: new Date(toDates[i]),
            value: res['Invites.Sent']['Invites']['Sent'][i],
          });

          _invitesViewed.push({
            name: new Date(toDates[i]),
            value: res['Invites.Viewed']['Invites']['Viewed'][i],
          });

          _appCommenced.push({
            name: new Date(toDates[i]),
            value: res['Applications.Commenced']['Applications']['Commenced'][i],
          });
          _appApproved.push({
            name: new Date(toDates[i]),
            value: res['Applications.Approved']['Applications']['Approved'][i],
          });
          _appActivated.push({
            name: new Date(toDates[i]),
            value: res['Applications.Activated']['Applications']['Activated'][i],
          });
        }

        this.invitesSentOpened = [
          {
            name: 'Sent',
            series: _invitesSent,
          },
          {
            name: 'Viewed',
            series: _invitesViewed,
          },
        ];
      }
    });
  }

  onResize(event) {
    // this.view = [event.target.innerWidth / 1.35, 400];
  }

  getRange(e, _fields) {
    let fields = [];
    if (e && _fields && typeof _fields == 'string') {
      fields = _fields.split(',');

      const payload = {
        startDate: e.startDate,
        endDate: e.endDate,
        fields: `ToDate,${_fields}`,
      };
    }
  }

  getRangeInvitesSentOpened(e) {
    const payload = {
      startDate: this.utils.EPdateFormat(e.startDate),
      endDate: this.utils.EPdateFormat(e.endDate),
      fields: 'ToDate,Invites.Sent,Invites.Viewed',
    };
    this.performanceService.getWeeklyPerformance(payload).subscribe((res) => {
      if (
        res &&
        res['ToDate']['ToDate'] &&
        Array.isArray(res['ToDate']['ToDate']) &&
        res['ToDate']['ToDate'].length > 0
      ) {
        const toDates = res['ToDate']['ToDate'];

        const sent = [];
        const viewed = [];
        for (let i = 0; i < toDates.length; i++) {
          sent.push({
            name: new Date(toDates[i]),
            value: res['Invites.Sent']['Invites']['Sent'][i],
          });
          viewed.push({
            name: new Date(toDates[i]),
            value: res['Invites.Viewed']['Invites']['Viewed'][i],
          });
        }
        this.invitesSentOpened = [
          {
            name: 'Sent',
            series: sent,
          },
          {
            name: 'Viewed',
            series: viewed,
          },
        ];
      }
    });
  }
}
