<div>
  <div class="main-banner blue-banner"></div>
  <ng-container class="white-background" *ngIf="page == 1">
    <section class="step1">
      <div class="top flex container">
        <div class="top-leftbox">
          <div class="top-txt" @ngIfAnimation data-aos="fade-right" data-aos-delay="400" data-aos-once="true">
            <h1 class="getting-started-page">Everything you need to go digital</h1>
            <p class="getting-started-page">
              Welcome to the first step on your journey to growing your {{ "KEYWORD.practice" | translate }}! This page
              will help you and your team with everything they need to start communicating digitally.
            </p>
          </div>
        </div>
        <div class="top-rightbox" @ngIfAnimation data-aos="fade-left" data-aos-delay="400" data-aos-once="true">
          <div class="imgbox">
            <img class="img"
                 src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_6.png"/>
          </div>
        </div>
      </div>
      <div class="main container">
        <div class="title" @ngIfAnimation data-aos="fade-up" data-aos-delay="400" data-aos-once="true">
          <h2 class="getting-started-page">Getting Started</h2>
          <p class="getting-started-page">
            A new platform can be overwhelming - there are so many new features to explore and you aren't sure where to
            start. Taking those first few steps can be daunting, but the hard part is over! Decisions like these are
            just one of many towards creating a successful {{ "KEYWORD.practice" | translate }}. Choose a step to get
            started.
          </p>
        </div>
        <div class="intro flex">
          <div class="intro-fi">
            <div class="intro-con" @ngIfAnimation data-aos="fade-right" data-aos-delay="400" data-aos-once="true">
              <div class="intro-numbox" (click)="pageS(2)">
                <div class="intro-num">
                  <h2 class="getting-started-page">1</h2>
                </div>
              </div>
              <div class="intro-txt">
                <h4 class="getting-started-page">Review Your Profile</h4>
              </div>
            </div>
          </div>
          <div class="intro-se">
            <div class="intro-con" @ngIfAnimation data-aos="fade-right" data-aos-delay="400" data-aos-once="true">
              <div class="intro-numbox" (click)="pageS(3)">
                <div class="intro-num">
                  <h2 class="getting-started-page">2</h2>
                </div>
              </div>
              <div class="intro-txt">
                <h4 class="getting-started-page">Sending {{"PHRASE.treatment plan" | translate | titlecase}}s </h4>
              </div>
            </div>
          </div>
          <div class="intro-th">
            <div class="intro-con" @ngIfAnimation data-aos="fade-right" data-aos-delay="400" data-aos-once="true">
              <div class="intro-numbox" (click)="pageS(4)">
                <div class="intro-num">
                  <h2 class="getting-started-page">3</h2>
                </div>
              </div>
              <div class="intro-txt">
                <h4 class="getting-started-page">Getting Paid</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="intro-bot" @ngIfAnimation data-aos="fade-up" data-aos-delay="400" data-aos-once="true">
          <p class="getting-started-page">
            <span class="strong">Important</span> - The {{ "KEYWORD.practice" | translate }} manager should have your
            portal set up and ready to go with all the
            correct {{"PARAGRAPH.Core Getting Started Settings and More" | translate}}. If not, be sure
            to mention it to your {{ "KEYWORD.practice" | translate }} manager or principal
            {{ "KEYWORD.practitioner" | translate }} to confirm this is done. Alternatively, click on the button below
            to follow the setup instructions.
          </p>
          <button class="btn" (click)="pageS(2)" mat-stroked-button>
            Portal Manager Setup <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
      <div class="guid flex">
        <div class="container guid-main" @ngIfAnimation data-aos="fade-right" data-aos-delay="400" data-aos-once="true">
          <div class="half-width img-box-left">
            <div class="guid-imgbox">
              <img class="img"
                   src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_7.png"/>
            </div>
          </div>
          <div class="half-width txt-box">
            <div class="txt" @ngIfAnimation data-aos="fade-left" data-aos-delay="400" data-aos-once="true">
              <h2 class="getting-started-page">Read Me!</h2>
              <p class="getting-started-page">
                We've created a detailed User Guide to help take you through each feature of your
                {{ "KEYWORD.practice" | translate }} portal. With insights and helpful links to articles, you will be a
                portal pro in no time!
              </p>
              <button class="btn" (click)="openNewTab()" mat-stroked-button>
                Download User Guide <mat-icon>chevron_right</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="visit flex white-background">
        <div class="container visit-main">
          <div class="visit-imgbox" @ngIfAnimation data-aos="fade-right" data-aos-delay="400" data-aos-once="true">
            <img class="img"
                 src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_8.png"/>
          </div>
          <div class="visit-text" @ngIfAnimation data-aos="fade-left" data-aos-delay="400" data-aos-once="true">
            <p class="getting-started-page">
              Need some more detailed help? Visit the help centre located in your portal for access to easy to follow
              how-to articles
            </p>
          </div>
          <button class="btn" @ngIfAnimation (click)="openNewTab1()" mat-stroked-button data-aos="fade-up"
                  data-aos-delay="400" data-aos-once="true">
            View Help Centre <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
      <div class="book flex">
        <div class="container book-main">
          <div class="book-title">
            <h2 class="getting-started-page">Still need help? Get in touch!</h2>
          </div>
          <div class="book-contact">
            <p class="getting-started-page">Email: {{ practiceAddress }}</p>
            <p class="getting-started-page">Phone: {{ practiceNumber }}</p>
            <p class="getting-started-page">{{"BRANDING.Operating Hours AEST" | translate}}</p>
          </div>
          <div class="book-bot">
            <p class="getting-started-page">
              Alternatively, you can book in some training with our experts. Training is
              <span class="strong">FREE</span> with unlimited access to courses that will help you and your team become
              portal pros!
            </p>
          </div>
          <button class="btn" (click)="openNewTab2()" mat-stroked-button>
            Book Training <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </section>
  </ng-container>
  <ng-container *ngIf="page == 2">
    <section class="step2">
      <div class="top flex container">
        <div class="top-leftbox">
          <div class="top-txt" @ngIfAnimation data-aos="fade-right" data-aos-delay="400" data-aos-once="true">
            <h1 class="getting-started-page">Setting up your portal</h1>
            <p class="getting-started-page">
              As the {{ "KEYWORD.practice" | translate }} manager it is your responsibility to ensure the practice
              settings are correct and up to date.
            </p>
          </div>
        </div>
        <div class="top-rightbox" @ngIfAnimation data-aos="fade-left" data-aos-delay="400" data-aos-once="true">
          <div class="imgbox">
            <img class="img"
                 src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_2.png"/>
          </div>
        </div>
      </div>
      <div class="main container">
        <div class="title" @ngIfAnimation data-aos="fade-up" data-aos-delay="400" data-aos-once="true">
          <h2 class="getting-started-page">Settings</h2>
          <p class="getting-started-page">
            Just logged into your portal for the first time? Your screen should like something like this.
          </p>
        </div>
        <div class="step2-imgbox">
          <img class="img"
               src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_9.png"/>
        </div>
        <div class="step2-txt">
          <p class="getting-started-page">Ok, let's get started!</p>
          <p class="getting-started-page">
            Click on your name in the top right-hand corner and go to 'my profile'. You then need to review/fill our all
            of your details and your {{ "KEYWORD.practice" | translate }} details, including any contact information,
            social media links, staff images, and more.
          </p>
          <p class="getting-started-page">
            Here is a helpful checklist along with how-to articles with detailed instructions to get you on the right
            track:
          </p>
        </div>
        <div class="artic">
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Bookmark Portal</span> - You should bookmark the portal landing page for easy
                access when logging in.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/settingup-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Personal Profile</span> - Add/confirm your details, an overview or background about
                yourself, picture, and any social links you want to share with your
                {{ "KEYWORD.patient" | translate }}s.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/settingup-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">{{ "KEYWORD.practice" | translate | titlecase}} Profile and Details</span> -
                Confirm
                {{ "KEYWORD.practice" | translate }} details including a profile and any social links you want to
                promote; don't forget photos!
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/settingup-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">{{ "KEYWORD.practice" | translate | titlecase}} Hours</span> - Update hours so your
                {{ "KEYWORD.patient" | translate }} knows when they can get in touch or expect a response from you.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Import {{ "KEYWORD.patient" | translate | titlecase }} Data</span> - Bulk upload
                your
                {{ "KEYWORD.patient" | translate }} data.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-upyour-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Add Team Members</span> - All team members should have a profile even if they don't
                use the platform so you can communicate on their behalf
              </p>
            </div>
            <div class="art-art">
              <a target="_blank" href="https://app.smileright.com.au/wiki/(wikiPageRoute:viewcode/adding-your-staff">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Update {{ "KEYWORD.practice" | translate | titlecase}} Website</span> - Let your
                {{ "KEYWORD.patient" | translate }}s know you offer {{ "BRANDING.Brand Name" | translate | titlecase }}
                payment
                plans! We have designed a "plug and play" website so give the snippet of code to your web developer -
                job done!
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:viewcode/setting-up-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="step2-bot">
        <div class="left-btn">
          <button class="btn" (click)="pageS(1)" mat-stroked-button>
            <mat-icon>chevron_left</mat-icon>
            Start
          </button>
        </div>
        <div class="right-btn">
          <button class="btn" (click)="pageS(3)" mat-stroked-button>
            Step Two
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </section>
  </ng-container>
  <ng-container *ngIf="page == 3">
    <section class="step3">
      <div class="top flex container">
        <div class="s3-top-leftbox top-leftbox">
          <div class="s3-top-txt top-txt" @ngIfAnimation data-aos="fade-right" data-aos-delay="400"
               data-aos-once="true">
            <h1 class="getting-started-page">Sending {{ "PHRASE.treatment plan" | translate }}s </h1>
            <p class="getting-started-page">
              Paper is a thing of the past, it’s time to go digital! We have made thing easy with just a few clicks you
              will be communicating digitally in no time.
            </p>
          </div>
        </div>
        <div class="s3-top-rightbox top-rightbox" @ngIfAnimation data-aos="fade-left" data-aos-delay="400"
             data-aos-once="true">
          <div class="s3-top-txt imgbox">
            <img class="img"
                 src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_10.png"/>
          </div>
        </div>
      </div>
      <div class="main container">
        <div class="title" @ngIfAnimation data-aos="fade-up" data-aos-delay="400" data-aos-once="true">
          <h2 class="getting-started-page">What do I need to do for my {{ "KEYWORD.patient" | translate }}s?</h2>
        </div>
        <div class="step2-txt">
          <p class="getting-started-page">
            Depending on how you run your {{ "KEYWORD.practice" | translate }}
            , {{"PHRASE.treatment planning" | translate}} and presentation can either be done directly by
            the {{ "KEYWORD.practitioner" | translate }} or assigned to the
            reception/{{ "KEYWORD.treatment" | translate }} coordinator. As the {{ "KEYWORD.practice" | translate }}
            manager, you may not be
            directly engaging with {{ "KEYWORD.patient" | translate }}s. However, you need to ensure that
            {{ "KEYWORD.patient" | translate }} data is uploaded and your settings configured for things to run
            smoothly.
          </p>
          <p class="getting-started-page">
            An essential thing for getting started is defining your {{ "KEYWORD.treatment" | translate }} templates.
            Templates help save time when
            sending {{ 'PHRASE.treatment plan' | translate }}s by
            reducing {{ 'PHRASE.treatment planning' | translate }} to a few keystrokes and, in most cases, less than 30
            seconds!. Use the examples provided by {{ "BRANDING.Brand Name" | translate | titlecase }}, modify to suit
            your {{ "KEYWORD.practice" | translate }}
            or create your own from scratch.
          </p>
        </div>
        <div class="artic">
          <h2 class="getting-started-page">Templates</h2>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Create New Template</span> - Go to the invitations tab and select new invitation >
                new invitation template, then give the template a name. For example, “Invisalign Adult”.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">{{ "KEYWORD.treatment" | translate | titlecase }} Value</span> - Give a
                default {{ "KEYWORD.treatment" | translate }} value (this can be edited at any
                time). We suggest using popular {{ "KEYWORD.treatment" | translate }} and costs you offer at
                your {{ "KEYWORD.practice" | translate }}.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Informed Consent</span> - Define the {{ "KEYWORD.treatment" | translate }} using
                ADA codes and supplier to
                capture informed consent. By doing this you will automatically be shown the attached documents.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Supporting Documents</span> - Upload any supporting documentation or imagery you
                want {{ "KEYWORD.patient" | translate }}s to have relating to {{ "KEYWORD.treatment" | translate }} or
                to your {{ "KEYWORD.practice" | translate }}, or
                alternatively you can select these from the 1000’s available in our data base.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="">
              <img class="img"
                   src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_18.png"/>
            </div>
          </div>
          <h2 class="getting-started-page">Default Messages</h2>
          <p class="getting-started-page">
            Make your {{ 'PHRASE.treatment plan' | translate }}s that much more personalise using Default messages.
            Either use the templates
            provided by {{ "BRANDING.Brand Name" | translate | titlecase }}, modify or create your own from scratch.
          </p>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Create New Message</span> - Go to settings > invitation > default messages > create
                new message. Give the message a title, for example “Welcome to {{ "KEYWORD.dental" | translate |
                uppercase}}
                _{{ "KEYWORD.practice" | translate | uppercase }}”.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Select Product Group</span> - Help define the message
                into groups.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Type Message</span> - Type out your message how you want it to read. We recommend
                using the “insert personalisation” button to have details autofilled when the template gets used.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="bot">
        <div class="step2-bot">
          <div class="left-btn">
            <button class="btn" (click)="pageS(2)" mat-stroked-button>
              <mat-icon>chevron_left</mat-icon> Step One
            </button>
          </div>
          <div class="right-btn">
            <button class="btn" (click)="pageS(4)" mat-stroked-button>
              Step Three <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </section>
  </ng-container>
  <ng-container *ngIf="page == 4">
    <section class="step4">
      <div class="top flex container">
        <div class="s3-top-leftbox top-leftbox">
          <div class="s3-top-txt top-txt" @ngIfAnimation data-aos="fade-right" data-aos-delay="400"
               data-aos-once="true">
            <h1 class="getting-started-page">Getting Paid</h1>
            <p class="getting-started-page">
              Provided {{ "KEYWORD.treatment" | translate }}? Now it’s time to get paid by putting in a settlement
              request.
            </p>
          </div>
        </div>
        <div class="s3-top-rightbox top-rightbox" @ngIfAnimation data-aos="fade-left" data-aos-delay="400"
             data-aos-once="true">
          <div class="s4-imgbox imgbox">
            <img class="img"
                 src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_12.png"/>
          </div>
        </div>
      </div>
      <div class="main container">
        <div class="title" @ngIfAnimation data-aos="fade-up" data-aos-delay="400" data-aos-once="true">
          <h2 class="getting-started-page">Settlement Request</h2>
        </div>
        <div class="step2-txt">
          <p class="getting-started-page">
            Once approved for finance, {{ "BRANDING.Brand Name" | translate | titlecase }} is now responsible for
            settling the
            {{ "KEYWORD.patient" | translate }}'s bill so you can proceed with confidence.
          </p>
          <p class="getting-started-page">
            Simply provide the {{ "KEYWORD.treatment" | translate }} and request settlement as the work is performed and
            invoiced to the
            {{ "KEYWORD.patient" | translate }}. There is no limit to the number of requests you can make.
          </p>
        </div>
        <div class="artic">
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">New Settlement Request</span> - In your blue menu bar go to settlements > new
                settlement request. You can also press the blue "piggybank" at the top of every page. Search your
                {{ "KEYWORD.patient" | translate }} and select their {{ "PHRASE.treatment plan" | translate }} .
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/requestingsettlements-">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Settlement Amount</span>
                - {{"PARAGRAPH.Core Getting Started Settlement Amount" | translate}}
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/requestingsettlements-">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Authorise Settlement</span> - Once happy with your request, confirm settlement with
                your {{ "KEYWORD.patient" | translate }}. We will send an SMS with an authorisation code. You need this
                code to continue with the settlement request. When you have the code, your
                {{ "KEYWORD.patient" | translate }} is good to go.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/requestingsettlements-">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Finalise Settlement</span> - Upload a copy of your {{ "KEYWORD.treatment" |
                translate }} invoice and submit for
                processing.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/requestingsettlements-">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Tracking Payments</span> - View your request status by going to your blue menu bar
                and pressing settlements > settlements. You can see your "approved"
                {{ "KEYWORD.patient" | translate }}s and the maximum value you can claim. Click on the
                {{ "KEYWORD.patient" | translate }} to see a detailed view of all claim and payment made.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/requestingsettlements-">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="">
              <img class="img"
                   src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_14.png"/>
            </div>
          </div>
        </div>
      </div>
      <div class="step-mid flex">
        <div class="container mid-main">
          <div class="manage-title">
            <h2 class="getting-started-page">Helpful Tools</h2>
            <p class="getting-started-page">At the top of every screen, you will find the following valuable tools.</p>
          </div>
          <div class="step-itembox">
            <div>
              <div class="item-con">
                <div class="item-icon">
                  <mat-icon class="icon-left"><span class="step4-icon material-icons">zoom_in</span></mat-icon>
                </div>
                <div class="item-txt">
                  <p class="getting-started-page">
                    <span class="strong">Product Lookup:</span> Used to search for all the
                    supplier products available
                    on the platform and will display any information relating to the product
                    selected, including
                    supporting documents, images and videos. Search by Item Code, brand
                    or product. Can also be used for
                    a variety of things, including research, staff training,
                    {{ "KEYWORD.patient" | translate }} presentation, invitation
                    templates, {{ 'PHRASE.treatment planning' | translate }} and more!
                  </p>
                </div>
              </div>
              <div class="item-con">
                <div class="item-txt">
                  <p class="getting-started-page">
                    <span class="strong">Financial Calculator:</span> This is a helpful feature! If
                    a {{ "KEYWORD.patient" | translate }} is
                    enquiring about payment options, costs or repayments, use this feature to show/tell your
                    {{ "KEYWORD.patient" | translate }} and answer any query they might have
                    on {{ "BRANDING.Brand Name" | translate | titlecase }} payment
                    options. Input the {{ "KEYWORD.treatment" | translate }} value, and the calculator will show all
                    options available.
                  </p>
                </div>
                <div class="item-icon">
                  <mat-icon class="icon-left"><span class="step4-icon material-icons">attach_money</span></mat-icon>
                </div>
              </div>
              <div class="item-con">
                <div class="item-icon">
                  <mat-icon class="icon-left"><span class="step4-icon material-icons">notifications</span></mat-icon>
                </div>
                <div class="item-txt">
                  <p class="getting-started-page">
                    <span class="strong">Activity Bell:</span> Every action or interaction either by a team member or
                    {{ "KEYWORD.patient" | translate }} that happens on the platform will give you a notification. It
                    would help if you viewed these notifications regularly to stay up to date with what is happening.
                    You can mark notifications as read, or "star" them if they are essential and return to them later.
                    To view a full audit trail, click the communications tab in the blue bar and see activities and
                    communication to and from the platform.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bot">
        <div class="step2-bot">
          <div class="left-btn">
            <button class="btn" (click)="pageS(3)" mat-stroked-button>
              <mat-icon>chevron_left</mat-icon> Step Two
            </button>
          </div>
          <div class="right-btn">
            <button class="btn" [routerLink]="['/merchant/', { outlets: { page: ['getting-started-home'] } }]"
                    mat-stroked-button>
              Back to Start <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </section>
  </ng-container>
</div>
