<!-- this faux-button steals focus on page load so that Enter key doesn't accidentally open the modal -->
<button class="faux-btn" mat-icon-button></button>
<!-- these are the buttons to launch the thumb/preview modal -->

<button
  class="zoom-button"
  *ngIf="fileURL && !imageLink && !link && loading != true && hideZoomButton == false && isFullScreen == true"
  (click)="fullscreen(fileURLRaw)"
  mat-icon-button
>
  <mat-icon class="fas fa-search-plus"></mat-icon>
</button>

<button
  class="zoom-button"
  *ngIf="!fileURL && imageLink && !link && loading != true && hideZoomButton == false && isFullScreen == true"
  (click)="fullscreenImageLink(imageLink)"
  mat-icon-button
>
  <mat-icon class="fas fa-search-plus"></mat-icon>
</button>

<button
  class="zoom-button"
  *ngIf="!fileURL && !imageLink && link && loading != true && hideZoomButton == false && isFullScreen == true"
  (click)="fullscreenImageLink(link)"
  mat-icon-button
>
  <mat-icon class="fas fa-search-plus"></mat-icon>
</button>

<!-- content container if store is loading -->
<div
  class="ep-view-img file-loadn"
  *ngIf="loading == true"
  [style.height]="height"
  [style.width]="width"
  (click)="emitClick()"
>
  <div class="spinner">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
  </div>
</div>

<!-- content container if doc exists and finish loading -->

<div
  class="ep-view-img file-fnd"
  *ngIf="!fileURL && !imageLink && loading != true && link"
  [style.height]="height"
  [style.width]="width"
  [ngClass]="{ 'low-opacity': notChecked == true }"
  [ngStyle]="{ 'background-image': activeBackground }"
  (click)="emitClick()"
></div>

<div
  class="ep-view-img file-fnd"
  *ngIf="!fileURL && imageLink && imageLinkURL && loading != true && !link"
  [style.height]="height"
  [style.width]="width"
  [ngClass]="{ 'low-opacity': notChecked == true }"
  [ngStyle]="{ 'background-image': activeBackground }"
  (click)="emitClick()"
></div>

<div
  class="ep-view-img file-fnd"
  *ngIf="fileURL && !imageLink && loading != true && !link"
  [style.height]="height"
  [style.width]="width"
  [style.background-image]="fileURL"
  [ngClass]="{ 'low-opacity': notChecked == true }"
  (click)="emitClick()"
></div>
<!-- doc container if no doc and finish loading -->
<div
  class="ep-view-img no-file-fnd"
  *ngIf="!fileURL && !imageLink && loading != true && !link"
  @ngIfAnimation
  [style.height]="height"
  [style.width]="width"
  [style.background-image]="defaultPicture"
  [ngClass]="{ 'low-opacity': notChecked == true }"
  (click)="emitClick()"
></div>

<div [hidden]="true">
  <img *ngIf="!fileURL && !imageLink && link" [src]="link" (error)="onError(defaultPicture)" (load)="onLoad(linkURL)"/>
  <img
    *ngIf="!fileURL && imageLink && imageLinkURL && !link"
    [src]="imageLink"
    (error)="onError(defaultPicture)"
    (load)="onLoad(imageLinkURL)"
  />

  <img
    *ngIf="fileURL && !imageLink && !imageLinkURL && !link"
    [src]="fileURL"
    (error)="onError(defaultPicture)"
    (load)="onLoad(fileURL)"
  />

  <img
    *ngIf="!fileURL && !imageLink && !imageLinkURL && !link"
    [src]="getWithoutURL(defaultPicture)"
    (error)="onError(defaultPicture)"
    (load)="onLoad(defaultPicture)"
  />
</div>
