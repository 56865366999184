import { Component, ElementRef, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { EmailEditorComponent } from 'angular-email-editor';
import { environment } from 'environments/environment';
import * as FileSaver from 'file-saver';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { TemplateReviewComponent } from '../../../core/helper/template-review/template-review.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { TagsService } from '../../tags/shared/tags.service';
import { TagCreateEditComponent } from '../../tags/tag-create-edit/tag-create-edit.component';
import { TemplateService } from '../shared/template-service';
import { TemplateUtilClass } from '../shared/templateUtils';
import { TemplatePurposeCreateEditComponent } from '../template-purpose-create-edit/template-purpose-create-edit.component';

@Component({
  selector: 'app-template-create-edit',
  templateUrl: './template-create-edit.component.html',
  styleUrls: ['./template-create-edit.component.css'],
})
export class TemplateCreateEditComponent implements OnInit {
  @Input()
  templateID;

  isEditMode = false;

  isPromoterOrAdmin = false;

  @ViewChild(EmailEditorComponent, { static: true })
  private emailEditor: EmailEditorComponent;

  options = {
    projectId: 4036,
  };

  templatePicLink;
  isTemplatePic = false;
  picSrc;

  emailBodyFileID;

  keepTemplate = false;
  merchants = [];
  merchantID;
  isDuplicateTag = false;
  orginalTemplateTag;
  selectedTags = [];
  defaultSelectedTags = [];
  utils = new UtilsClass();
  template = {
    templateLabel: null,
    templateTag: null,
    templatePurpose: null,
    templateFor: null,
    templateTarget: null,

    templateSubject: null,
    templatePictureID: null,
    templateStructure: null,
    templateBodyHtml: null,

    templateLandingPageURL: null,
    templateLandingPageCode: null,
    templateMetaDate: null,
    supportSMS: null,
    smsBody: null,
    actionCallDescription: null,
    actionCallLabel: null,
  };

  createdObject;

  templateUtil = new TemplateUtilClass();

  templateForList = ['Promoter', 'Funder', 'Supplier', 'Merchant', 'Patient'];
  templatePurposeList = [];
  templateTargetList = [];
  content;
  fileToUpload: File = null;
  inputType = 'upload';
  @ViewChild(WizardComponent, { static: false })
  public wizard: WizardComponent;

  cursorPosSMS;
  cursorPosSubject;
  editorOptions = {
    fontSize: 12,
    formatOnType: true,
    formatOnPaste: true,
    language: 'html',
    autoIndent: true,
    autoClosingBrackets: 'always',
    dragAndDrop: true,
    fixedOverflowWidgets: true,
    theme: 'vs-dark',
  };
  allowedExtensionsHTML = ['html'];

  metaDataFinance = false;
  metaDataComment = false;
  metaDataProductGroup = false;
  metaDataBestContactTime = false;
  metaDataSalutation = false;
  displayContent = true;
  isDefault = true;
  marketingTemplate = true;
  removetag = new EventEmitter();
  addTag = new EventEmitter();

  tags;
  sessionType;

  recipientPlaceHolder = [
    {
      label: 'First name',
      code: '{{RECIPIENT_FIRST_NAME}}',
    },
    {
      label: 'Last name',
      code: '{{RECIPIENT_NAME}} ',
    },
    {
      label: 'Calculated name',
      code: '{{RECIPIENT_CALCULATED_NAME}}',
    },

    {
      label: 'Mobile',
      code: '{{RECIPIENT_MOBILE}}',
    },
    {
      label: 'Phone',
      code: '{{RECIPIENT_PHONE}}',
    },
    {
      label: 'Email',
      code: '{{RECIPIENT_EMAIL}}',
    },
  ];

  LinkPlaceHolder = [
    {
      label: 'Public site',
      code: '{{SITE_URL_Public}}',
    },
    {
      label: 'Practice Page',
      code: '{{SITE_URL_Public}}/p/{{MERCHANT_TerminalCode}}',
    },
    {
      label: 'Dental Tips and Offers page',
      code: '{{SITE_URL_Public}}/dental/{{MERCHANT_TerminalCode}}',
    },
    {
      label: 'Practice appointment page',
      code: '{{SITE_URL_Public}}/a/{{MERCHANT_TerminalCode}}',
    },
    {
      label: 'Google Reviews page',
      code: '{{SITE_URL_MERCHANT}}/api/merchant/gr/{{MERCHANT_CardKey}}',
    },
  ];

  merchantPlaceHolder = [
    {
      label: 'Trading as',
      code: '{{MERCHANT_TRADING_NAME}}',
    },
    {
      label: 'Phone',
      code: '{{MERCHANT_PHONE}} ',
    },
    {
      label: 'Email',
      code: '{{MERCHANT_EMAIL}}',
    },

    {
      label: 'Address',
      code: '{{MERCHANT_CALCULATED_ADDRESS}}',
    },

    {
      label: 'Practice Page',
      code: '{{SITE_URL_Public}}/p/{{MERCHANT_TerminalCode}}',
    },
    {
      label: 'Dental Tips and Offers page',
      code: '{{SITE_URL_Public}}/p/{{MERCHANT_TerminalCode}}',
    },
    {
      label: 'Practice appointment page',
      code: '{{SITE_URL_Public}}/a/{{MERCHANT_TerminalCode}}',
    },
    {
      label: 'Google Reviews page',
      code: '{{SITE_URL_MERCHANT}}/api/merchant/gr/{{MERCHANT_CardKey}}',
    },
  ];

  dentistPlaceHolder = [
    {
      label: 'First name',
      code: '{{[JBE_Card]First_Name:8009}}',
    },
    {
      label: 'Last name',
      code: '{{[JBE_Card]Name:8008}} ',
    },
    {
      label: 'Calculated name',
      code: '{{[JBE_Card]Calculated_Name:8014}}',
    },

    {
      label: 'Email',
      code: '{{[JBE_Card]Email:8021}}',
    },
    {
      label: 'Mobile',
      code: '{{[JBE_Card]Mobile:8019}}',
    },
  ];

  campaignPlaceHolder = [
    {
      label: 'Label',
      code: '{{[MSG_Campaign]Label:49007}}',
    },
    {
      label: 'Description',
      code: '{{[MSG_Campaign]Description:49008}} ',
    },
    {
      label: 'Amount/Day',
      code: '{{Campaign_CheapestPerDay_minPerDay}}/day',
    },

    {
      label: 'Email: landing page with recipient',
      code: '{{SITE_URL_Public}}/ec/{{[MSG_Campaign]PK:49001}}/{{RECIPIENT_CardKey}}',
    },
    {
      label: 'SMS: landing page without recipient',
      code: '{{SITE_URL_Public}}/mc/{{[MSG_Campaign]PK:49001}}',
    },

    {
      label: 'SMS: landing page with recipient',
      code: '{{SITE_URL_Public}}/mc/{{[MSG_Campaign]PK:49001}}/{{RECIPIENT_CardKey}}',
    },
  ];

  @ViewChild('smsBodyID', { static: false }) smsBody: ElementRef<HTMLInputElement>;

  @ViewChild('subjectID', { static: false }) subjectBody: ElementRef<HTMLInputElement>;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private merchantService: MerchantService,
    private templateService: TemplateService,
    private activeRoute: ActivatedRoute,
    private utilsService: UtilsService,
    private tagService: TagsService
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['templateID']) {
        this.templateID = params['templateID'];
        this.isEditMode = true;
      }
      this.authenticationService.isPromoterOrAdmin().subscribe((sessionType) => {
        this.sessionType = sessionType;

        this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
          this.isPromoterOrAdmin = res;

          if (this.isPromoterOrAdmin == true) {
            const payload = {
              fields: 'ID,TradingAs,CalculatedName,Status',
            };
            this.merchantService.getList(payload).subscribe((res) => {
              this.merchants = res;
            });
          } else {
            this.router.navigate(['/merchant']);
          }
        });

        const pTag = {
          isPromoter: true,
          fields: 'Label',
        };
        this.tagService.getTagsList(pTag, this.sessionType).subscribe((res) => {
          if (res) {
            this.tags = res;
          }
        });

        this.templateService.getTemplatePurposeList().subscribe((res) => {
          if (res && res.length > 0) {
            this.templatePurposeList = res;
          }
        });

        if (this.isEditMode == true) {
          this.editSetup();
        }
      });
    });
  }

  editSetup() {
    if (this.templateID) {
      const payload = {
        isIncludeStructure: true,
      };
      this.templateService.getOneTemplate(this.templateID, payload).subscribe((res) => {
        if (res) {
          this.templateTargetList = ['Merchant', 'Merchant-Prospect', 'CustomersAndProspects'];
          if (res['Label']) {
            this.template.templateLabel = res['Label'];
          }

          if (res['Tag']) {
            if (res['Purpose_Label']) {
              this.template.templatePurpose = this.templatePurposeList.filter(
                (x) => x['Label'] == res['Purpose_Label']
              )[0];
            }

            this.orginalTemplateTag = res['Tag'];
            let _tags = res['Tag'];

            if (res['Tag'].split(':').length > 1) {
              _tags = res['Tag'].split(':')[1];
            }

            _tags = _tags.split('-');

            if (_tags[0] == 'campaign') {
              this.template.templatePurpose = this.templatePurposeList.filter((x) => x['Label'] == 'Campaign')[0];
              if (_tags[1] == 'P') {
                this.template.templateFor = 'Promoter';
                this.templateTargetList = ['Merchant', 'Merchant-Prospect', 'CustomersAndProspects'];
              } else if (_tags[1] == 'M') {
                this.template.templateFor = 'Merchant';
                this.templateTargetList = ['CustomersAndProspects'];
              } else if (_tags[1] == 'F') {
                this.template.templateFor = 'Funder';
                this.templateTargetList = ['Merchant', 'Merchant-Prospect', 'CustomersAndProspects'];
              } else if (_tags[1] == 'S') {
                this.template.templateFor = 'Supplier';
                this.templateTargetList = ['Merchant', 'Merchant-Prospect', 'CustomersAndProspects'];
              } else if (_tags[1] == 'P') {
                this.template.templateFor = 'Patient';
                this.templateTargetList = ['Merchant', 'Merchant-Prospect', 'CustomersAndProspects'];
              } else {
                this.template.templateFor = _tags[0];
                this.templateTargetList = ['Merchant', 'Merchant-Prospect', 'CustomersAndProspects'];
              }

              this.template.templateTarget = _tags[2];
            } else {
              this.template.templateFor = _tags[0];

              if (
                this.template.templateFor == 'Supplier' ||
                this.template.templateFor == 'Funder' ||
                this.template.templateFor == 'Promoter'
              ) {
                this.templateTargetList = ['Merchant', 'Merchant-Prospect', 'CustomersAndProspects'];
              } else {
                this.templateTargetList = ['CustomersAndProspects'];
              }

              this.template.templateTarget = _tags[1];
            }

            if (this.template.templateTarget) {
              const tagSplit = res['Tag'].split(this.template.templateTarget + '-');
              if (tagSplit && tagSplit[1]) {
                this.template.templateTag = tagSplit[1];
              }
            }
          }

          if (res['Subject']) {
            this.template.templateSubject = res['Subject'];
          }

          if (res['ActionCall.Label']) {
            this.template.actionCallLabel = res['ActionCall.Label'];
          }

          if (res['ActionCall.Description']) {
            this.template.actionCallDescription = res['ActionCall.Description'];
          }

          if (res['LandingPageURL']) {
            this.template.templateLandingPageURL = res['LandingPageURL'];
          }

          if (res['Supports.SMS'] == '1') {
            this.template.supportSMS = true;
          } else {
            this.template.supportSMS = false;
          }

          if (res['IsDefault'] == '1') {
            this.isDefault = true;
          } else {
            this.isDefault = false;
          }

          if (res['IsOfBusinessNature'] == '1') {
            this.marketingTemplate = false;
          } else {
            this.marketingTemplate = true;
          }
          if (res['LandingPageCode']) {
            this.template.smsBody = res['SMS.Body'];
          }

          if (res['LandingPageCode']) {
            this.template.templateLandingPageCode = res['LandingPageCode'];
          }

          if (res['Tag.Label'] && res['StaticList.Label']) {
            res['StaticList.Label'] = res['Tag.Label'] + '|' + res['StaticList.Label'];
          } else if (res['Tag.Label']) {
            res['StaticList.Label'] = res['Tag.Label'];
          }

          if (res['StaticList.Label']) {
            this.defaultSelectedTags = JSON.parse(JSON.stringify(res['StaticList.Label'].split('|')));
          }

          if (res['MetaData']) {
            if (res['MetaData']['Amount']) {
              this.metaDataFinance = true;
            }

            if (res['MetaData']['Comment']) {
              this.metaDataComment = true;
            }

            if (res['MetaData']['ContactTime']) {
              this.metaDataBestContactTime = true;
            }

            if (res['MetaData']['ProductGroup']) {
              this.metaDataProductGroup = true;
            }

            if (res['MetaData']['Salutation']) {
              this.metaDataSalutation = true;
            }
          }

          this.getPicture();
        }
      });
    }
  }

  editorLoaded() {
    if (this.template.templateStructure && this.template.templateStructure.body) {
      this.emailEditor.loadDesign(this.template.templateStructure);
    }
  }

  exportHtml() {
    this.emailEditor.exportHtml((data) => {
      const blob = new Blob([data['html']], { type: 'text/html' });

      FileSaver.saveAs(blob, `${this.template.templateLabel}.html`);
    });
  }

  exportJson() {
    this.emailEditor.exportHtml((data) => {
      const blob = new Blob([JSON.stringify(data['design'])], { type: 'application/json;charset=utf-8' });

      FileSaver.saveAs(blob, `${this.template.templateLabel}.json`);
    });
  }

  getTemplateForList() {
    if (this.template.templatePurpose) {
      if (this.template.templatePurpose.Label == 'Campaign') {
        this.templateForList = ['Promoter', 'Merchant'];

        this.metaDataBestContactTime = false;
        this.metaDataComment = false;
        this.metaDataProductGroup = true;
        this.metaDataFinance = true;
        this.metaDataSalutation = false;
      } else {
        this.metaDataBestContactTime = false;
        this.metaDataComment = false;
        this.metaDataProductGroup = false;
        this.metaDataFinance = false;
        this.metaDataSalutation = false;
        this.templateForList = ['Promoter', 'Funder', 'Supplier', 'Merchant', 'Patient'];
      }
    }
  }

  getTemplateTargetList() {
    if (this.template.templateFor) {
      if (this.template.templateFor == 'Promoter' || this.template.templateFor == 'Funder') {
        this.templateTargetList = ['Merchant', 'Merchant-Prospect', 'CustomersAndProspects'];
      } else {
        this.templateTargetList = ['CustomersAndProspects'];
      }

      this.template.templateTarget = this.templateTargetList[0];
    }
  }

  changeViewType() {
    if (this.inputType == 'raw') {
      if (this.emailEditor) {
        this.emailEditor.exportHtml((data) => {
          if (data['html']) {
            this.content = data['html'];
            this.displayContent = true;
          } else {
            this.displayContent = true;
          }
        });
      } else {
        this.displayContent = true;
      }
    } else {
      this.displayContent = false;
    }
  }

  keepCurrentTemplate() {
    this.keepTemplate = true;
    this.wizard.goToNextStep();
  }

  createNewTemplate() {
    this.keepTemplate = false;
    this.wizard.goToNextStep();
  }

  checkTemplateTag() {
    const _templateTag = this.getTag();

    if (this.isEditMode == true) {
      let t = this.orginalTemplateTag;
      if (this.orginalTemplateTag.split(':').length > 1) {
        t = this.orginalTemplateTag.split(':')[1];
      }

      if (t != _templateTag) {
        this.templateService.isTemplateAlreadyExist(_templateTag).subscribe((res) => {
          if (res == true) {
            NotifyAppComponent.displayToast('warning', 'Error', 'Label should be unique');
            this.isDuplicateTag = true;
          } else if (res == false) {
            this.wizard.goToNextStep();
            this.isDuplicateTag = false;
          }
        });
      } else {
        this.wizard.goToNextStep();
        this.isDuplicateTag = false;
      }
    } else {
      this.templateService.isTemplateAlreadyExist(_templateTag).subscribe((res) => {
        if (res == true) {
          NotifyAppComponent.displayToast('warning', 'Error', 'Label should be unique');
          this.isDuplicateTag = true;
        } else if (res == false) {
          this.wizard.goToNextStep();
          this.isDuplicateTag = false;
        }
      });
    }
  }

  onCompleteAll(fileID) {
    if (fileID && fileID[0]) {
      this.template.templatePictureID = fileID[0];
      this.changeLogoImage();
    }
  }

  changeLogoImage() {
    this.utilsService.getFileDetails(this.template.templatePictureID).subscribe((res) => {
      if (res) {
        if (res.contentType == 'image/png' || res.contentType == 'image/jpg' || res.contentType == 'image/jpeg') {
          this.isTemplatePic = true;
          this.picSrc = `${environment.nodeUrl}/files/file-view/${this.template.templatePictureID}`;
        } else {
          NotifyAppComponent.displayToast(
            'warning',
            'Error during operation',
            'Format of image should be png/jpg/jpeg'
          );
        }
      }
    });
  }

  getPicture() {
    this.templatePicLink = null;

    if (this.templateID) {
      this.templatePicLink = this.templateService.getTemplatePictureStreamLink(this.templateID);
    }
  }

  viewTemplateEdit() {
    if (this.templateID) {
      const ref = RootAppComponent.dialog.open(TemplateReviewComponent, {
        data: {
          templateID: this.templateID,
          isPrint: false,
          isProceed: false,
          isDownload: true,
        },
        width: '700px',
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewTemplate() {
    if (this.createdObject) {
      const ref = RootAppComponent.dialog.open(TemplateReviewComponent, {
        data: {
          templateID: this.createdObject.ID,
          isPrint: false,
          isProceed: false,
          isDownload: false,
        },
        width: '700px',
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
    }
  }

  getTag() {
    let _templateTag;
    const _templateLabel = this.template.templateTag.replace(/ /g, '-');

    if (this.template.templatePurpose.Label == 'Campaign') {
      if (this.template.templateFor == 'Promoter') {
        _templateTag = `campaign-P-${this.template.templateTarget}-${_templateLabel}`;
      } else if (this.template.templateFor == 'Merchant') {
        _templateTag = `campaign-M-${this.template.templateTarget}-${_templateLabel}`;
      }
    } else {
      _templateTag = `${this.template.templateFor}-${this.template.templateTarget}-${_templateLabel}`;
    }
    return _templateTag;
  }

  saveDesign(isEdit = false) {
    const _templateTag = this.getTag();

    const payload: any = {
      merchantID: this.merchantID,
      name: this.template.templateLabel,
      purposeCode: this.template.templatePurpose.ID,
      tag: _templateTag,

      subject: this.template.templateSubject,
      structure: null,
      emailBody: null,
      logoFileID: this.template.templatePictureID,
      landingPageCode: this.template.templateLandingPageCode,
      landingPageURL: this.template.templateLandingPageURL,
      isDefault: this.isDefault,
      isActive: this.isDefault,
      isBusiness: !this.marketingTemplate,
      supportSMS: this.template.supportSMS || false,
      smsBody: this.template.smsBody,
      actionCallDescription: this.template.actionCallDescription,
      actionCallLabel: this.template.actionCallLabel,
      isFinance: this.metaDataFinance,
    };

    const metadata = {};

    if (this.metaDataFinance == true) {
      metadata['Amount'] = {
        Label: 'Treatment Price',
        Type: 'R',
        Mandatory: '1',
      };
    }
    if (this.metaDataComment == true) {
      metadata['Comment'] = {
        Label: 'Personal message to the recipient',
        Type: 'T',
        Mandatory: '0',
      };
    }
    if (this.metaDataBestContactTime == true) {
      metadata['ContactTime'] = {
        Label: 'Preferred contact time',
        Type: 'T',
        Mandatory: '0',
        Lookup: {
          Table: 'CodeLookup',
          Category: 'ContactTime',
          CodeField: 'Code',
          LabelField: 'Label',
        },
      };
    }
    if (this.metaDataProductGroup == true) {
      metadata['ProductGroup'] = {
        Label: 'Financed Offered',
        Type: 'T',
        Mandatory: '1',
        Lookup: {
          Table: 'ProductGroup',
          CodeField: 'Group.Code',
          LabelField: 'Group.Label',
          FilterField: 'Available',
          FilterValue: '1',
        },
      };
    }
    if (this.metaDataSalutation == true) {
      metadata['Salutation'] = {
        Label: 'Salutation',
        Type: 'T',
        Mandatory: '1',
        Lookup: {
          Table: 'SimpleLookup',
          Category: 'Salutation',
          CodeField: 'Code',
          LabelField: 'Label',
        },
      };
    }

    if (metadata) {
      payload.metaData = metadata;
    }

    if (this.selectedTags && this.selectedTags.length > 0) {
      payload.tags = this.selectedTags.join(',');
    }

    if (this.keepTemplate == true && isEdit == true && this.templateID) {
      this.templateService.modifyTemplate(this.templateID, payload, this.isPromoterOrAdmin).subscribe((res) => {
        if (res) {
          this.createdObject = res;

          NotifyAppComponent.displayToast('success', 'Edit Template', 'Templated has been successfully modified');
          this.wizard.goToNextStep();
        }
      });
    } else if (this.inputType == 'editor') {
      if (this.emailEditor) {
        this.emailEditor.exportHtml((data) => {
          if (data['html'] && data['design']) {
            this.template.templateBodyHtml = btoa(data['html']);
            this.template.templateStructure = data['design'];
          }

          payload.structure = [this.template.templateStructure];
          payload.emailBody = this.template.templateBodyHtml;

          if (isEdit == true && this.templateID) {
            this.templateService.modifyTemplate(this.templateID, payload, this.isPromoterOrAdmin).subscribe((res) => {
              if (res) {
                this.createdObject = res;

                NotifyAppComponent.displayToast('success', 'Edit Template', 'Templated has been successfully modified');
                this.wizard.goToNextStep();
              }
            });
          } else {
            this.templateService.createTemplate(payload, this.isPromoterOrAdmin).subscribe((res) => {
              if (res) {
                this.createdObject = res;

                NotifyAppComponent.displayToast('success', 'Create Template', 'New templated created successfully');
                this.wizard.goToNextStep();
              }
            });
          }
        });
      } else {
        payload.emailBody = this.content;

        if (isEdit == true && this.templateID) {
          this.templateService.modifyTemplate(this.templateID, payload, this.isPromoterOrAdmin).subscribe((res) => {
            if (res) {
              this.createdObject = res;

              NotifyAppComponent.displayToast('success', 'Edit Template', 'Templated has been successfully modified');
              this.wizard.goToNextStep();
            }
          });
        } else {
          this.templateService.createTemplate(payload, this.isPromoterOrAdmin).subscribe((res) => {
            if (res) {
              this.createdObject = res;

              NotifyAppComponent.displayToast('success', 'Create Template', 'New templated created successfully');
              this.wizard.goToNextStep();
            }
          });
        }
      }
    } else {
      if (this.inputType == 'upload') {
        payload.emailBodyFileID = this.emailBodyFileID;
      } else {
        payload.emailBody = this.content;
      }

      if (isEdit == true && this.templateID) {
        this.templateService.modifyTemplate(this.templateID, payload, this.isPromoterOrAdmin).subscribe((res) => {
          if (res) {
            this.createdObject = res;

            NotifyAppComponent.displayToast('success', 'Edit Template', 'Templated has been successfully modified');
            this.wizard.goToNextStep();
          }
        });
      } else {
        this.templateService.createTemplate(payload, this.isPromoterOrAdmin).subscribe((res) => {
          if (res) {
            this.createdObject = res;

            NotifyAppComponent.displayToast('success', 'Create Template', 'New templated created successfully');
            this.wizard.goToNextStep();
          }
        });
      }
    }
  }

  createNewType() {
    const ref = RootAppComponent.dialog.open(TemplatePurposeCreateEditComponent, {
      data: {
        isModal: true,
      },
      width: '650px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        this.templatePurposeList.push(res);
        ref.close();
      }
    });
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);

    const reader = new FileReader();
    reader.readAsText(this.fileToUpload, 'UTF-8');

    reader.onload = (e: any) => {
      const temp = JSON.parse(e.target.result);

      this.emailEditor.loadDesign(temp);
    };
  }

  onCompleteAllHTML(fileID) {
    if (fileID && fileID[0]) {
      this.emailBodyFileID = fileID[0];
    }
  }

  createNewTag() {
    const ref = RootAppComponent.dialog.open(TagCreateEditComponent, {
      data: {},

      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res && res.Label) {
        if (
          (!this.tags || (this.tags && this.tags.length <= 0)) &&
          (!this.defaultSelectedTags || (this.defaultSelectedTags && this.defaultSelectedTags.length <= 0))
        ) {
          this.defaultSelectedTags = JSON.parse(JSON.stringify([res.Label]));
          this.tags = JSON.parse(JSON.stringify([res.Label]));
        } else {
          if (!this.tags || (this.tags && this.tags.length <= 0)) {
            this.tags.push(res.Label);
          }
          this.addTag.emit(res.Label);
        }
        ref.close();
      }
    });
  }

  getRemovedTag(e) {
    if (e && this.orginalTemplateTag) {
      const confirmDialog = new ConfirmDialog(
        'delete',
        'Remove tag from template',
        '<p>Are you sure ?</p>',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              removeTemplateTag: this.orginalTemplateTag,
            };
            this.tagService.addTagsToItemByLabel(e, payload, this.sessionType).subscribe((res) => {
              if (res) {
                this.removetag.emit(e);
                NotifyAppComponent.displayToast('success', 'Success!', 'Tag is removed.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  getSelectedTags(e) {
    this.selectedTags = e || [];
  }

  addPersonalisationlink(link) {
    if (link) {
      this.template.templateLandingPageURL = link;
    }
  }

  addPersonalisationSubject(content) {
    const l = content.length + 2;
    if (!this.template.templateSubject) {
      this.template.templateSubject = '';
    }
    if (this.cursorPosSubject > 0) {
      this.template.templateSubject =
        this.template.templateSubject.slice(0, this.cursorPosSubject) +
        ' ' +
        content +
        ' ' +
        this.template.templateSubject.slice(this.cursorPosSubject);
      this.cursorPosSubject = Number(this.cursorPosSubject + l);
      this.subjectBody.nativeElement.selectionStart = this.cursorPosSubject;
    } else {
      this.template.templateSubject = content + ' ' + this.template.templateSubject;
      this.cursorPosSubject = Number(this.cursorPosSubject + l);
      this.subjectBody.nativeElement.selectionStart = this.cursorPosSubject;
    }
  }

  addPersonalisationSMS(content) {
    const l = content.length + 2;
    if (!this.template.smsBody) {
      this.template.smsBody = '';
    }
    if (this.cursorPosSMS > 0) {
      this.template.smsBody =
        this.template.smsBody.slice(0, this.cursorPosSMS) +
        ' ' +
        content +
        ' ' +
        this.template.smsBody.slice(this.cursorPosSMS);

      this.cursorPosSMS = Number(this.cursorPosSMS + l);
      this.smsBody.nativeElement.selectionStart = this.cursorPosSMS;
    } else {
      this.template.smsBody = content + ' ' + this.template.smsBody;

      this.cursorPosSMS = Number(this.cursorPosSMS + l);
      this.smsBody.nativeElement.selectionStart = this.cursorPosSMS;
    }
  }

  getcursorPosSubject() {
    this.cursorPosSubject = this.subjectBody.nativeElement.selectionStart;
  }

  getcursorPosSMS() {
    this.cursorPosSMS = this.smsBody.nativeElement.selectionStart;
  }
}
