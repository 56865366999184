import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerListArchivesComponent } from './customer-list-archives/customer-list-archives.component';
import { CustomerListViewComponent } from './customer-list-view/customer-list-view.component';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { CustomerSearchComponent } from './customer-search/customer-search.component';
import { CustomerSummaryComponent } from './customer-summary/customer-summary.component';
import { CustomerViewComponent } from './customer-view/customer-view.component';
import { CustomerRoutingModule } from './shared/customer-routing.module';

import { CdkTableModule } from '@angular/cdk/table';
import { MatSortModule } from '@angular/material/sort';
import { ArchwizardModule } from 'angular-archwizard';
import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '../../shared/shared.module';
import { CustomerCreateComponent } from './customer-create/customer-create.component';
import { CustomerEditComponent } from './customer-edit/customer-edit.component';
import { CustomerImportComponent } from './customer-import/customer-import.component';
import { CustomerListIntroModalComponent } from './customer-list-intro-modal/customer-list-intro-modal.component';
import { CustomerPrintViewComponent } from './customer-print-view/customer-print-view.component';
import { CustomerViewPictureComponent } from './customer-view-picture/customer-view-picture.component';
import { CustomerService } from './shared/customer.service';
import { InvitationActionsComponent } from '../invitation/invitation-actions/invitation-actions.component';

@NgModule({
  imports: [
    NgPipesModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CdkTableModule,
    ArchwizardModule,
    CustomerRoutingModule,
    MatSortModule,
  ],
  declarations: [
    CustomerListComponent,
    CustomerListViewComponent,
    CustomerListArchivesComponent,
    CustomerViewComponent,
    CustomerEditComponent,
    CustomerSummaryComponent,
    CustomerSearchComponent,
    CustomerCreateComponent,
    CustomerImportComponent,
    CustomerListIntroModalComponent,
    CustomerViewPictureComponent,
    CustomerPrintViewComponent,
    InvitationActionsComponent,
  ],
  exports: [
    CustomerListComponent,
    CustomerListViewComponent,
    CustomerListArchivesComponent,
    CustomerViewComponent,
    CustomerEditComponent,
    CustomerSummaryComponent,
    CustomerSearchComponent,
    CustomerCreateComponent,
    CustomerImportComponent,
    CustomerListIntroModalComponent,
    CustomerViewPictureComponent,
    CustomerPrintViewComponent,
    InvitationActionsComponent,
  ],
  providers: [CustomerService],
})
export class CustomerModule {}
