<div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="listDB.data.length == 0">
  <div class="row clearfix">
    <h1 class="dataHeader rm-m pull-left">
      {{ title }}
      <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
    </h1>

    <div class="pull-right rel buttonrow" *ngIf="isPromoterOrAdmin == true && listDB.data.length > 0">
      <button class="clearfix pull-right" (click)="createNew()" mat-raised-button color="primary">
        <mat-icon>add</mat-icon>
        Create new Medication
      </button>
    </div>

    <div class="pull-right selection-items" @ngIfAnimation>
      <button
        class="animated"
        *ngIf="isPromoterOrAdmin == true"
        (click)="selectAll()"
        mat-stroked-button
        color=""
        matTooltip="Select All"
      >
        <mat-icon>border_all</mat-icon>
      </button>

      <button
        class="animated"
        *ngIf="selectedIDs && selectedIDs.length > 0"
        [matMenuTriggerFor]="ActionBtnmenu"
        mat-stroked-button
        matTooltip="Options"
      >
        <mat-icon class="fas fa-cog"></mat-icon>  <span *ngIf="selectedIDs && selectedIDs.length>0"
          class="selected-number-item  accent-background">{{selectedIDs.length}}</span>
      </button>
      <mat-menu #ActionBtnmenu="matMenu">
        <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
          <mat-icon>select_all</mat-icon> Deselect
        </button>

        <button class="row select-btn" (click)="disableGroup()" mat-menu-item color="">
          <mat-icon>cancel</mat-icon>
          Disable
        </button>

        <button class="row select-btn" (click)="enableGroup()" mat-menu-item color="">
          <mat-icon>check_circle</mat-icon>
          Enable
        </button>

        <button
          class="row select-btn"
          *ngIf="selectedIDs && selectedIDs.length > 0"
          (click)="removeGroup()"
          mat-menu-item
          color=""
        >
          <mat-icon>delete</mat-icon>
          Remove
        </button>
      </mat-menu>
    </div>
  </div>

  <div class="row clearfix">
    <mat-card class="list-card">
      <div class="row clearfix mb searchArea primary-gradient-img">
        <div class="row clearfix flex">
          <div class="full-width flex animated fadeInDown">
            <mat-icon class="mt mr">search</mat-icon>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Filter by Name, Type, Distributor, Manufacturer.</mat-label>
              <input class="clearfix" (input)="setFilter($event.target.value, 'fullLabel')" name="Labels" matInput/>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- Table -->
      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <ng-container cdkColumnDef="selectCheckBox" sticky>
          <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div class="margin-btm pull-left">
              <mat-checkbox
                [checked]="isSelected(row['ID'])"
                (change)="select(row['ID'], $event.checked)"
                color="accent"
              >
              </mat-checkbox>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="thumbnail">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header></mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTreatment(row['ID'])">
            <app-drugs-thumbnail
              class="supplierIcon2 text-center pull-left"
              *ngIf="row"
              [hideZoomButton]="true"
              [drugID]="row['ID']"
            >
            </app-drugs-thumbnail>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Name">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Name</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTreatment(row['ID'])" matTooltip="{{ row['Name'] }}">
            {{ row['Name'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Date">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Created</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="viewTreatment(row['ID'])"
            matTooltip="{{ row['DateTimeCreated'] | customDateTime }}"
          >
            <span> {{ row['DateTimeCreated'] | TimeLabelPast }} ago</span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="type">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Type</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTreatment(row['ID'])">
            <span>{{ row['DrugType.Label'] }}</span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Distributor">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Distributor</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTreatment(row['ID'])">
            <span>{{ row['Distributor.Namel'] }}</span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Manufacturer">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Manufacturer</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTreatment(row['ID'])">
            <span>{{ row['Manufacturer.Namel'] }}</span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="IsActive">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Active</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTreatment(row['ID'])">
            <span
              class="list-label smaller"
              *ngIf="row['IsActive'] == true"
              matTooltip="Active"
              style="background-color: rgb(90, 186, 71)"
            >
              <mat-icon>check</mat-icon>
            </span>
            <span
              class="list-label smaller"
              *ngIf="row['IsActive'] != true"
              matTooltip="Inactive"
              style="background-color: rgb(239, 71, 39)"
            >
              <mat-icon>close</mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button (click)="viewTreatment(row['ID'])" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span>View Medication</span>
                </button>

                <button *ngIf="row && isPromoterOrAdmin == true" (click)="editTreatment(row['ID'])" mat-menu-item>
                  <mat-icon>edit</mat-icon>
                  <span>Edit Medication</span>
                </button>

                <button *ngIf="row['Manufacturer_key']" (click)="viewSupplier(row['Manufacturer_key'])" mat-menu-item>
                  <mat-icon class="fas fa-industry mr"></mat-icon>
                  <span>View Manufacturer</span>
                </button>

                <button *ngIf="row['Distributor_key']" (click)="viewSupplier(row['Distributor_key'])" mat-menu-item>
                  <mat-icon class="fas fa-truck mr"></mat-icon>
                  <span>View Distributor</span>
                </button>

                <button
                  *ngIf="row['IsActive'] == '1' && isPromoterOrAdmin == true"
                  (click)="disable(row['ID'])"
                  mat-menu-item
                >
                  <mat-icon>cancel</mat-icon>
                  <span>Disable</span>
                </button>

                <button
                  *ngIf="row['IsActive'] == '0' && isPromoterOrAdmin == true"
                  (click)="enable(row['ID'])"
                  mat-menu-item
                >
                  <mat-icon>check_circle</mat-icon>
                  <span>Enable</span>
                </button>

                <button *ngIf="row && isPromoterOrAdmin == true" (click)="remove(row['ID'])" mat-menu-item>
                  <mat-icon>delete</mat-icon>
                  <span>Remove</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginator
        [length]="dataSource.filteredData.length"
        [pageIndex]="0"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
      >
      </mat-paginator>
    </mat-card>
  </div>
</div>
<div *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <app-empty-list-message
    *ngIf="isPromoterOrAdmin == true"
    [actionIcon]="'add'"
    [isAction]="true"
    [actionLabel]="'Create new Medication'"
    (actionEvent)="createNew()"
  ></app-empty-list-message>

  <app-empty-list-message *ngIf="isPromoterOrAdmin != true"></app-empty-list-message>
</div>
