<div class="row clearfix flex funnel">
  <div class="step step-1">
    <div class="row clearfix text-center">
      <mat-icon class="fas fa-envelope-open-text"></mat-icon>
      <h5 class="sr-title rm-m">{{ invitationTitle }}</h5>

      <hr/>
    </div>
    <mat-list>
      <mat-list-item>
        <p matLine>
          <span class="title">Created:</span>
          <span class="value">{{ invitation['DateTimeCreated'] | customDate }}</span>
        </p>
      </mat-list-item>
      <mat-list-item *ngIf="invitation && invitation['Campaign_Key'] == ''">
        <p matLine>
          <span class="title">Sent:</span>
          <span class="value">{{ invitation['DateToBeSent'] | customDate }}</span>
        </p>
      </mat-list-item>
      <mat-list-item>
        <p class="" matLine>
          <span class="title">Email status:</span>
          <span class="value" *ngIf="invitation && invitation['Campaign_Key'] == ''">
            <mat-icon class="red" *ngIf="invitation['Status.Email.Short_NE'] == 'Rejected'">error</mat-icon>
            <mat-icon class="green" *ngIf="invitation['Status.Email.Short_NE'] == 'Delivered'">check_circle</mat-icon>
            <mat-icon
              [hidden]="
                invitation['Status.Email.Short_NE'] == 'Rejected' || invitation['Status.Email.Short_NE'] == 'Delivered'
              "
            >
              help</mat-icon
            >
            {{ invitation['Status.Email.Short_NE'] }}
          </span>

          <span class="value" *ngIf="invitation && invitation['Campaign_Key']">
            <mat-icon class="green">check_circle</mat-icon>

            Campaign
          </span>
        </p>
      </mat-list-item>
      <mat-list-item *ngIf="invitation && invitation['Campaign_Key']">
        <p class="" matLine>
          <span class="title" (click)="openCampaign(invitation['Campaign_Key'])" matTooltip="View Campaign"
          >Campaign label:</span
          >

          <span class="value view" (click)="openCampaign(invitation['Campaign_Key'])" matTooltip="View Campaign">
            <mat-icon>visibility</mat-icon>
            {{ invitation['Campaign_Label'] }}
          </span>
        </p>
      </mat-list-item>
      <mat-list-item *ngIf="invitation && invitation['Campaign_Key']">
        <p class="" matLine>
          <span class="title">Campaign sent:</span>

          <span class="value">
            {{ invitation['Campaign_WhenToSend'] | customDate }}
          </span>
        </p>
      </mat-list-item>

      <mat-list-item *ngIf="invitation && invitation['Campaign_Key'] == ''">
        <p
          class="small"
          [style.border-color]="util.daysPastColor(invitation['DateTimeCreated'])"
          [style.color]="util.daysPastColor(invitation['DateTimeCreated'])"
          matLine
        >
          Created {{ invitation['DateTimeCreated'] | TimeLabelPast }} ago
          <span class="backgroundColor" [style.background]="util.daysPastColor(invitation['DateTimeCreated'])"></span>
        </p>
      </mat-list-item>

      <mat-list-item *ngIf="eventLogs.emailViewed && invitation && invitation['Campaign_Key'] == ''">
        <p
          class="small"
          [style.border-color]="util.daysPastColor(invitation['DateTimeCreated'])"
          [style.color]="util.daysPastColor(invitation['DateTimeCreated'])"
          matLine
        >
          Created {{ invitation['DateTimeCreated'] | TimeLabelPast }} ago
          <span class="backgroundColor" [style.background]="util.daysPastColor(invitation['DateTimeCreated'])"></span>
        </p>
      </mat-list-item>
    </mat-list>
  </div>

  <!-- information about patient card interaction -->
  <div class="step step-2" [class.disabled]="!invitation['Date.Viewed'] || invitation['Date.Viewed'] == '0000-00-00'">
    <div class="row clearfix text-center">
      <mat-icon class="fas fa-id-card"></mat-icon>
      <h5 class="sr-title rm-m">{{ patientCardTitle }}</h5>
      <hr/>
    </div>

    <p
      class="nothingToDisplay text-center"
      *ngIf="!invitation['Date.Viewed'] || invitation['Date.Viewed'] == '0000-00-00'"
    >
      No activity to display
    </p>

    <mat-list [hidden]="!invitation['Date.Viewed'] || invitation['Date.Viewed'] == '0000-00-00'">
      <mat-list-item>
        <p matLine>
          <span class="title">Viewed:</span>
          <span class="value" *ngIf="invitation['Status.Invitation.Short_NE'] != 'Not viewed'">{{
            invitation['Date.Viewed'] | customDate
            }}</span>
          <span class="value" *ngIf="invitation['Status.Invitation.Short_NE'] == 'Not viewed'">{{
            invitation['Status.Invitation.Short_NE']
            }}</span>
        </p>
      </mat-list-item>
      <mat-list-item>
        <p matLine>
          <span class="title">Expiry:</span>

          <span class="value" *ngIf="invitation['Date.Expiry'] && invitation['Date.Expiry'] != '0000-00-00'">{{
            invitation['Date.Expiry'] | customDate
            }}</span>
          <span class="value" *ngIf="!invitation['Date.Expiry'] || invitation['Date.Expiry'] == '0000-00-00'">...</span>
        </p>
      </mat-list-item>

      <mat-list-item *ngIf="invitation['CashDiscount.Offered.Amount']">
        <p matLine>
          <span class="title">Cash discount:</span>
          <span class="value point" matTooltip="Expires {{ invitation['CashDiscount.Expiry.Date'] | customDate }}">
            <mat-icon>info</mat-icon>
            {{ invitation['CashDiscount.Offered.Amount'] | customCurrency }}
          </span>
        </p>
      </mat-list-item>

      <mat-list-item *ngIf="eventLogs.emailViewed">
        <p
          class="small"
          [style.border-color]="util.daysPastColor(invitation['Date.Viewed'])"
          [style.color]="util.daysPastColor(invitation['Date.Viewed'])"
          matLine
        >
          Viewed {{ eventLogs.emailViewed | TimeLabelPast }} ago
          <span class="backgroundColor" [style.background]="util.daysPastColor(invitation['Date.Viewed'])"></span>
        </p>
      </mat-list-item>
    </mat-list>
  </div>

  <!-- Information about patient's selection -->
  <div
    class="step step-3"
    [class.disabled]="
      !invitation['Status.Invitation.Short_NE'] ||
      invitation['Status.Invitation.Short_NE'] == 'Not viewed' ||
      invitation['Status.Invitation.Short_NE'] == 'Viewed'
    "
  >
    <div class="row clearfix text-center">
      <mat-icon class="fas fa-comments"></mat-icon>
      <h5 class="sr-title rm-m">{{ patientResponseTitle }}</h5>
      <hr/>
    </div>

    <p
      class="nothingToDisplay text-center"
      *ngIf="
        !invitation['Status.Invitation.Short_NE'] ||
        invitation['Status.Invitation.Short_NE'] == 'Not viewed' ||
        invitation['Status.Invitation.Short_NE'] == 'Viewed'
      "
    >
      No activity to display
    </p>

    <mat-list
      [hidden]="
        !invitation['Status.Invitation.Short_NE'] ||
        invitation['Status.Invitation.Short_NE'] == 'Not viewed' ||
        invitation['Status.Invitation.Short_NE'] == 'Viewed'
      "
    >
      <mat-list-item>
        <p matLine>
          <span class="title">Response:</span>
          <span class="value" [hidden]="invitation['Status.Invitation.Short_NE'] == 'Viewed'">
            <mat-icon
              class="red"
              *ngIf="
                invitation['Status.Invitation.Short_NE'] == 'Not Proceeding' ||
                invitation['Status.Invitation.Short_NE'] == 'Discuss/Modify'
              "
            >
              error</mat-icon
            >
            <mat-icon
              class="green"
              *ngIf="
                invitation['Status.Invitation.Short_NE'] == 'Pay Cash/Card' ||
                invitation['Status.Invitation.Short_NE'] == 'Proceeded'
              "
            >
              check_circle</mat-icon
            >

            {{ invitation['Status.Invitation.Short_NE'] }}
          </span>
        </p>
      </mat-list-item>
      <mat-list-item>
        <p matLine>
          <span class="title">Date responded:</span>
          <span class="value" *ngIf="invitation['Date.Responded'] && invitation['Date.Responded'] != '0000-00-00'">{{
            invitation['Date.Responded'] | customDate
            }}</span>
          <span class="value" *ngIf="!invitation['Date.Responded'] || invitation['Date.Responded'] == '0000-00-00'"
          >...</span
          >
        </p>
      </mat-list-item>

      <mat-list-item>
        <p
          [hidden]="
            invitation['Status.Invitation.Short_NE'] == 'Not Proceeding' ||
            invitation['Status.Invitation.Short_NE'] == 'Discuss/Modify'
          "
          matLine
        >
          &nbsp;
        </p>

        <p *ngIf="invitation['Status.Invitation.Short_NE'] == 'Not Proceeding'" matLine>
          <span class="title">Reason:</span>
          <span
            class="value point sr-title"
            matTooltip="{{ invitation['PatientDeclineReasons.Labels'] }}... {{
              invitation['PatientDeclineReasons.Feedback']
            }}"
          >
            <mat-icon class="primary-color">help</mat-icon> View
          </span>
        </p>

        <p *ngIf="invitation['Status.Invitation.Short_NE'] == 'Discuss/Modify'" matLine>
          <span class="title">Changes:</span>
          <span class="value point sr-title" matTooltip="{{ invitation['PatientFeedback'] }}">
            <mat-icon class="primary-color">help</mat-icon> View
          </span>
        </p>
      </mat-list-item>

      <mat-list-item *ngIf="eventLogs.respondTime">
        <p
          class="small"
          [style.border-color]="util.daysPastColor(invitation['Date.Responded'])"
          [style.color]="util.daysPastColor(invitation['Date.Responded'])"
          matLine
        >
          Responded {{ eventLogs.respondTime | TimeLabelPast }} ago
          <span class="backgroundColor" [style.background]="util.daysPastColor(invitation['Date.Responded'])"></span>
        </p>
      </mat-list-item>
    </mat-list>
  </div>

  <!-- Information to display if patient proceeds with finance -->
  <div
    class="step step-4"
    [class.disabled]="!invitation['Status.Application.Short_NE'] || invitation['Status.Application.Short_NE'] == 'N/A'"
  >
    <div class="ribbon" *ngIf="invitation['Status.Contract.Short_NE'] == 'Active'">
      <span>ACTIVE</span>
    </div>

    <div class="row clearfix text-center">
      <mat-icon class="fas fa-hand-holding-usd"></mat-icon>
      <h5 class="sr-title rm-m">{{ fianceOptionsTitme }}</h5>
      <hr/>
    </div>

    <p
      class="nothingToDisplay text-center"
      *ngIf="!invitation['Status.Application.Short_NE'] || invitation['Status.Application.Short_NE'] == 'N/A'"
    >
      No activity to display
    </p>

    <mat-list
      [hidden]="!invitation['Status.Application.Short_NE'] || invitation['Status.Application.Short_NE'] == 'N/A'"
    >
      <mat-list-item>
        <p matLine>
          <span class="title">Commenced:</span>

          <span class="value" *ngIf="invitation['Date.Commenced'] && invitation['Date.Commenced'] != '0000-00-00'">{{
            invitation['Date.Commenced'] | customDate
            }}</span>
          <span class="value" *ngIf="!invitation['Date.Commenced'] || invitation['Date.Commenced'] == '0000-00-00'"
          >...</span
          >
        </p>
      </mat-list-item>
      <mat-list-item>
        <p matLine>
          <span class="title">Application:</span>
          <span class="value" *ngIf="invitation['Status.Application.Short_NE']">{{
            invitation['Status.Application.Short_NE']
            }}</span>
        </p>
      </mat-list-item>
      <mat-list-item>
        <p matLine>
          <span class="title">Contract:</span>
          <span class="value">{{ invitation['Status.Contract.Short_NE'] }}</span>
        </p>
      </mat-list-item>

      <mat-list-item
        *ngIf="
          invitation['Status.Contract.Short'] &&
          invitation['Status.Contract.Short']?.indexOf('Activ') != -1 &&
          invitation['Status.Contract.Short']?.indexOf('Submitted') == -1 &&
          invitation['Date.Activated'] &&
          invitation['Date.Activated'] != '0000-00-00'
        "
      >
        <p matLine>
          <span class="title">Activated date:</span>

          <span class="value" *ngIf="invitation['Date.Activated'] && invitation['Date.Activated'] != '0000-00-00'">{{
            invitation['Date.Activated'] | customDate
            }}</span>
          <span class="value" *ngIf="!invitation['Date.Activated'] || invitation['Date.Activated'] == '0000-00-00'"
          >...</span
          >
        </p>
      </mat-list-item>

      <mat-list-item
        *ngIf="
          invitation['Status.Contract.Short'] &&
          invitation['Status.Contract.Short']?.indexOf('Activ') == -1 &&
          invitation['Status.Contract.Short']?.indexOf('Submitted') == -1 &&
          invitation['Status.Application.Short']?.indexOf('Approved') != -1 &&
          invitation['Date.Approved'] &&
          invitation['Date.Approved'] != '0000-00-00'
        "
      >
        <p matLine>
          <span class="title">Approved:</span>

          <span class="value" *ngIf="invitation['Date.Approved'] && invitation['Date.Approved'] != '0000-00-00'">{{
            invitation['Date.Approved'] | customDate
            }}</span>
          <span class="value" *ngIf="!invitation['Date.Approved'] || invitation['Date.Approved'] == '0000-00-00'"
          >...</span
          >
        </p>
      </mat-list-item>
      <mat-list-item
        *ngIf="
          invitation['Status.Application.Short']?.indexOf('Reffered') != -1 &&
          invitation['Date.Referred'] &&
          invitation['Date.Referred'] != '0000-00-00'
        "
      >
        <p matLine>
          <span class="title">Referred:</span>

          <span class="value" *ngIf="invitation['Date.Referred'] && invitation['Date.Referred'] != '0000-00-00'">{{
            invitation['Date.Referred'] | customDate
            }}</span>
          <span class="value" *ngIf="!invitation['Date.Referred'] || invitation['Date.Referred'] == '0000-00-00'"
          >...</span
          >
        </p>
      </mat-list-item>
      <mat-list-item
        *ngIf="
          invitation['Status.Application.Short']?.indexOf('Declined') != -1 &&
          invitation['Date.Declined'] &&
          invitation['Date.Declined'] != '0000-00-00'
        "
      >
        <p matLine>
          <span class="title">Declined:</span>

          <span class="value" *ngIf="invitation['Date.Declined'] && invitation['Date.Declined'] != '0000-00-00'">{{
            invitation['Date.Declined'] | customDate
            }}</span>
          <span class="value" *ngIf="!invitation['Date.Declined'] || invitation['Date.Declined'] == '0000-00-00'"
          >...</span
          >
        </p>
      </mat-list-item>
    </mat-list>
  </div>
</div>
