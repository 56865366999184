import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerService } from '../shared/customer.service';

@Component({
  selector: 'app-customer-view-picture',
  templateUrl: './customer-view-picture.component.html',
  styleUrls: ['./customer-view-picture.component.css'],
})
export class CustomerViewPictureComponent implements OnInit {
  @Input() patientID;

  @Output() close = new EventEmitter();

  profileLink;

  constructor(
    private customerService: CustomerService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.patientID = data;
    }
  }

  ngOnInit() {
    if (this.patientID) {
      this.getProfilePicture();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.patientID.previousValue != changes.patientID.currentValue) {
      if (this.patientID) {
        this.getProfilePicture();
      } else {
        this.profileLink = null;
      }
    }
  }

  getProfilePicture() {
    this.profileLink = null;

    this.profileLink = this.customerService.getProfilePicStreamLink(this.patientID);
  }
  closeEvent() {
    this.close.emit(true);
  }
}
