import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-medical-history-progress-modal',
  templateUrl: './medical-history-progress-modal.component.html',
  styleUrls: ['./medical-history-progress-modal.component.css'],
})
export class MedicalHistoryProgressModalComponent implements OnInit {
  @Input()
  step;

  anim = false;

  @Output()
  closeModal = new EventEmitter();

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.step = data;
    }
  }

  ngOnInit() {
    setTimeout(() => {
      this.anim = true;
    }, 250);
  }

  close() {
    this.closeModal.emit(true);
  }
}
