import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-marketing-social-media-redirection',
  templateUrl: './marketing-social-media-redirection.component.html',
  styleUrls: ['./marketing-social-media-redirection.component.css'],
})
export class MarketingSocialMediaRedirectionComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    this.router.navigate(['/merchant', { outlets: { page: ['marketing-social-create'] } }], { replaceUrl: true });
  }
}
