import { Component, EventEmitter, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogSingleButton } from '../../types/confirm-dialog-single-button';

@Component({
  selector: 'app-confirm-dialog-single-button',
  templateUrl: './confirm-dialog-single-button.component.html',
  styleUrls: ['./confirm-dialog-single-button.component.css'],
})
export class ConfirmDialogSingleButtonComponent implements OnInit {
  @Input()
  confirmDialog = new ConfirmDialogSingleButton(
    'delete',
    'Are you sure?',
    '<p>You are about to remove this item.</p> ',
    'yes',
    false
  );

  onConfirm = new EventEmitter();
  onClick = new EventEmitter();
  close = new EventEmitter();

  dontShow = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.confirmDialog = data;
    }
  }

  ngOnInit() {}

  onClickEvent() {
    this.onClick.emit(this.dontShow);
    this.onConfirm.emit(true);
    this.close.emit(true);
  }

  isFontAwsome(icon) {
    if (icon) {
      if (icon.indexOf('fas ') !== -1 || icon.indexOf('fab-') !== -1) {
        return true;
      }

      return false;
    }

    return false;
  }
}
