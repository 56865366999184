import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

@Component({
  selector: 'app-wiki-redirection',
  templateUrl: './wiki-redirection.component.html',
  styleUrls: ['./wiki-redirection.component.css'],
})
export class WikiRedirectionComponent implements OnInit {
  @Input()
  link = 'wiki-create';

  @Input()
  param = null;

  sessionTypeParams;

  utils = new UtilsClass();

  constructor(private activeRoute: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    UtilsClass.startLoadingForce();

    const _res = this.utils.getSessionTypeParams(this.router.url);

    if (_res) {
      this.sessionTypeParams = _res;
    }

    this.activeRoute.params.subscribe((params) => {
      if (params['link']) {
        this.link = params['link'];
      }

      if (params['param']) {
        this.param = params['param'];
      }

      if (this.sessionTypeParams) {
        if (this.param) {
          UtilsClass.stopLoadingForce();

          this.router.navigate(
            [
              '/wiki-' + this.sessionTypeParams,
              {
                outlets: {
                  wikiPageRoute: [this.link, this.param],
                },
              },
            ],
            { replaceUrl: true }
          );
        } else {
          UtilsClass.stopLoadingForce();

          this.router.navigate(
            [
              '/wiki-' + this.sessionTypeParams,
              {
                outlets: {
                  wikiPageRoute: [this.link],
                },
              },
            ],
            { replaceUrl: true }
          );
        }
      } else {
        if (this.param) {
          UtilsClass.stopLoadingForce();
          this.router.navigate(
            [
              '/wiki',
              {
                outlets: {
                  wikiPageRoute: [this.link, this.param],
                },
              },
            ],
            { replaceUrl: true }
          );
        } else {
          UtilsClass.stopLoadingForce();
          this.router.navigate(
            [
              '/wiki',
              {
                outlets: {
                  wikiPageRoute: [this.link],
                },
              },
            ],
            { replaceUrl: true }
          );
        }
      }
    });
  }
}
