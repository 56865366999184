import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { TreatmentModule } from '../treatment/treatment.module';
import { TreatmentPlanRoutingModule } from './shared/treatment-plan-routing.module';
import { TreatmentChoiceAdvancedComponent } from './treatment-choice-advanced/treatment-choice-advanced.component';
import { TreatmentChoiceComponent } from './treatment-choice/treatment-choice.component';
import { TreatmentPlanCreateComponent } from './treatment-plan-create/treatment-plan-create.component';
import { TreatmentPlanViewComponent } from './treatment-plan-view/treatment-plan-view.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CdkTableModule,
    CommonModule,
    TreatmentModule,
    TreatmentPlanRoutingModule,
    SharedModule,
  ],
  declarations: [
    TreatmentChoiceComponent,
    TreatmentPlanCreateComponent,
    TreatmentPlanViewComponent,
    TreatmentChoiceAdvancedComponent,
  ],
  exports: [
    TreatmentChoiceComponent,
    TreatmentPlanCreateComponent,
    TreatmentPlanViewComponent,
    TreatmentChoiceAdvancedComponent,
  ],
})
export class TreatmentPlanModule {}
