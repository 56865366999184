import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ClipboardService } from 'ngx-clipboard';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { EditorComponentService } from '../shared/editor-component.service';

@Component({
  selector: 'app-editor-component-edit-modal',
  templateUrl: './editor-component-edit-modal.component.html',
  styleUrls: ['./editor-component-edit-modal.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.stacked-card-view', style({ opacity: 0 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.stacked-card-view', style({ opacity: 1 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class EditorComponentEditModalComponent implements OnInit {
  descriptionHTML = 'Choose the editor type to create HTML content,you can also change the view type.';
  descriptionCSSHTML =
    'Choose the editor type to create HTML content to <strong>preview your style</strong> ,you can also change the view type.';

  natureCode;

  title;
  description;
  defaultStyle;
  defaultContent;
  style;
  content;

  isEditStyle = true;

  editorComponent;
  editorComponentID;
  sessionType;
  @Output()
  close = new EventEmitter();
  @Output()
  getResult = new EventEmitter();
  @Output()
  getStyle = new EventEmitter();

  @Output()
  getContent = new EventEmitter();

  @Output()
  getNature = new EventEmitter();
  viewType = 'view3';

  isLoaded = false;
  preview;
  utils = new UtilsClass();

  isEdit = false;

  constructor(
    private _clipboardService: ClipboardService,
    private authenticationService: AuthenticationService,
    private editorComponentService: EditorComponentService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.editorComponentID != null) {
        this.editorComponentID = data.editorComponentID;
      }

      if (data.viewType != null) {
        this.viewType = data.viewType;
      }
    }
  }

  ngOnInit() {
    if (this.editorComponentID) {
      this.authenticationService.getSessionType().subscribe((r) => {
        this.sessionType = r;
        this.editorComponentService
          .getEditorComponentDetails(
            this.editorComponentID,
            {
              fields:
                'ID,DateTimeCreated,Merchant_key,Merchant_Trading_As,Label,Description,Is_Default,Type.Code,Type.Label,Nature.Code,Nature.Label,Is_Active,Style,Content',
            },
            this.sessionType
          )
          .subscribe((res) => {
            if (res && res.ID) {
              this.editorComponent = res;
              this.buildItem();
            }
          });
      });
    }
  }

  buildItem() {
    if (this.editorComponent && this.editorComponent.ID) {
      if (this.editorComponent.Label) {
        this.title = this.editorComponent.Label;
      }

      if (this.editorComponent['Nature.Code']) {
        this.natureCode = this.editorComponent['Nature.Code'];
      }

      if (this.editorComponent.Description) {
        this.description = this.editorComponent.Description;
      }

      if (this.editorComponent.Style) {
        this.defaultStyle = this.editorComponent.Style;
        this.style = this.editorComponent.Style;
      }

      if (this.editorComponent.Content) {
        this.defaultContent = this.editorComponent.Content;
        this.content = this.editorComponent.Content;
      }

      if (this.content && this.style) {
        let componentID = null;

        if (this.editorComponent['Nature.Code'] == 'Template') {
          componentID = this.editorComponent.ID;
        }

        this.preview = this.utils.getPreviewHtmlContent(this.content, this.style, componentID);
      } else if (this.content) {
        this.preview = this.content;
      }
    }

    this.isLoaded = true;
  }

  closeEvent() {
    this.close.emit(true);
  }

  Copy(d) {
    if (d) {
      this._clipboardService.copy(d);

      NotifyAppComponent.displayToast('success', 'success', 'Copied');
    }
  }

  getStyleEvent(e) {
    this.style = e;
  }

  getContentEvent(e) {
    this.content = e;
  }

  inserResult(isCopy = false, replace = false) {
    let type;

    if (this.natureCode === 'Style') {
      type = 'Style';
    } else if (this.natureCode === 'Template') {
      type = 'Component';
    } else if (this.natureCode === 'PageTem') {
      type = 'Template';
    }
    const commentStart = 'START ' + type + ' ' + this.title + ': ' + this.description;
    const commentEnd = 'END ' + type + ' ' + this.title;

    let content = this.content;

    if (this.natureCode === 'Template') {
      if (content) {
        content = `<section id="ID${this.editorComponentID}">\n${content}\n</section>`;
      }
    }

    const p = {
      style: '/* ' + commentStart + '*/\n' + this.style + '\n/* ' + commentEnd + '*/',
      natureCode: this.natureCode,
      content: '<!-- ' + commentStart + '-->\n' + content + '\n<!-- ' + commentEnd + '-->',
      isCopy,
      isReplace: replace,
    };

    if (this.style === null) {
      p.style = null;
    }
    if (this.natureCode === 'Style') {
      p.content = null;
    }

    if (isCopy === true) {
      if (this.natureCode === 'Template') {
        this.Copy(p.content);
        p.content = null;
      }
      if (this.natureCode === 'PageTem') {
        this.Copy(p.content);
        p.content = null;
      } else if (this.natureCode === 'Style') {
        this.Copy(p.style);
        p.content = null;
        p.style = null;
      }
      this.getResult.emit(p);
      this.getNature.emit(this.natureCode);
      this.getStyle.emit(p.style);
      this.getContent.emit(p.content);
    } else {
      this.getResult.emit(p);
      this.getNature.emit(this.natureCode);
      this.getStyle.emit(this.style);
      this.getContent.emit(this.content);
    }
  }

  replaceResult() {
    const confirmDialog = new ConfirmDialog(
      'info',
      'Are you sure you want to continue?',
      '<p>You are replacing you current content with this template</p>',

      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          this.inserResult(false, true);
        });
      } else {
        ref.close();
      }
    });
  }

  getIsEditStyleEvent(e) {
    this.isEditStyle = e;
  }

  inserResultOverride() {
    const confirmDialog = new ConfirmDialog(
      'info',
      'Are you sure you want to continue?',
      '<p>By inserting this Component, Certain Styling properties may overide your current logic.</p>',

      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          this.inserResult(false, false);
        });
      } else {
        ref.close();
      }
    });
  }

  view() {
    this.isEdit = false;
  }

  edit() {
    this.isEdit = true;
  }
}
