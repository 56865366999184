import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { Settings } from '../../../shared/types/settings';
import { ContractService } from '../../contract/shared/contract.service';
import { InvitationService } from '../shared/services/invitation.service';

@Component({
  selector: 'app-invitation-details-card',
  templateUrl: './invitation-details-card.component.html',
  styleUrls: ['./invitation-details-card.component.css'],
})
export class InvitationDetailsCardComponent implements OnInit {
  @Input()
  invitationID;

  @Input()
  membership = false;

  chartColors = Settings.global['chartColors'];

  unseenDocs;
  seenDocs;
  seenPercent;

  isModal = false;
  isPromoterOrAdmin = false;
  invitation;
  autoHide;
  contract;
  documents = [];
  chartData = [];

  profileLink;

  sessionType = 'guest';

  constructor(
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private invitationService: InvitationService,
    private contractService: ContractService,
    public snackBar: MatSnackBar,
    private route: Router,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.invitationID = data;
      this.isModal = true;
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['invitationId']) {
        this.invitationID = params['invitationID'];
      }
      this.authenticationService.getSessionType().subscribe((sessionType) => {
        if (sessionType) {
          this.sessionType = sessionType;
        }
        this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
          this.isPromoterOrAdmin = res;

          if (res == true) {
            this.invitationService
              .getInvitationDetails(this.invitationID, {}, this.sessionType, this.membership)
              .subscribe((res) => {
                if (res) {
                  this.invitation = res;
                  this.getPicture();

                  if (this.invitation['Contract_Key']) {
                    this.contractService
                      .fetchContractDetails$(this.invitation['Contract_Key'], null, this.isPromoterOrAdmin)
                      .subscribe((contract) => {
                        this.contract = contract;
                      });
                  }
                }
              });
          }
        });
      });
    });
  }

  toNumber(value) {
    return Number(value);
  }

  linktoContract(ID) {
    this.route.navigate(['/merchant', { outlets: { page: ['contract-details', ID] } }]);
  }

  //  copy to clipboard

  copy(val) {
    const selBox = document.createElement('textarea');

    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;

    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();

    document.execCommand('copy');
    this.displayCopiedSnack();
    document.body.removeChild(selBox);
  }

  displayCopiedSnack(message: string = null, action: string = null) {
    this.snackBar.open('Copied to clipboard', action, {
      duration: 2000,
    });
  }

  // go to url

  onNavigate(urlString) {
    window.open(urlString, '_blank', 'noopener');
  }

  getDocuments(value) {
    if (value) {
      this.documents = value;
    }
  }

  getViewedDocuments(e) {
    if (e) {
      this.chartData = [
        {
          name: 'Documents yet to view',
          value: this.documents.length - e.length,
        },
        {
          name: 'Viewed Documents',
          value: e.length,
        },
      ];
      this.unseenDocs = this.documents.length - e.length;
      this.seenDocs = e.length;

      this.seenPercent = e.length / this.documents.length;
    }
  }

  getPicture() {
    this.profileLink = null;

    if (this.invitation['Prospect_key']) {
      this.profileLink = this.invitationService.getProfilePicStreamLink(this.invitation['Prospect_key']);
    }
  }
}
