import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';

import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { HeaderService } from '../../../shared/services/header.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { Settings } from '../../../shared/types/settings';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { SettlementCustomerListGlobalComponent } from '../settlement-customer-list-global/settlement-customer-list-global.component';

/**
 * @title Feature-rich data table
 */
@Component({
  selector: 'app-settlement-customer-list-overview',
  templateUrl: './settlement-customer-list-overview.component.html',
  styleUrls: ['./settlement-customer-list-overview.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class SettlementCustomerListOverViewComponent implements OnInit {
  merchants = [];

  pageSize = Settings.global['listPageSize'] || 20;
  merchantID = null;
  activeContracts = true;
  isAdminOrPromoter = null;
  hideMerchantSelect = false;

  isModuleSettlementsActive = Settings.global['isModuleSettlementsActive'];

  constructor(
    private utilService: UtilsService,
    private AuthenticationService: AuthenticationService,
    private merchantService: MerchantService
  ) {}

  ngOnInit() {
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleSettlementsActive = access['isModuleSettlementsActive'];
      }

      SettlementCustomerListGlobalComponent.closeMerchantSelectEvent.subscribe((res) => {
        if (res == true) {
          this.hideMerchantSelect = true;
        } else {
          this.hideMerchantSelect = false;
        }
      });

      this.AuthenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;

        if (this.isAdminOrPromoter == true) {
          const payload = {
            fields: 'ID,TradingAs,CalculatedName,Status',
          };
          this.merchantService.getList(payload).subscribe((res) => {
            this.merchants = res;
          });

          const _mID = JSON.parse(localStorage.getItem('selectedMerchant'));
          if (_mID) {
            this.merchantID = _mID;
            // this.hideMerchantSelect = true;
          }

          HeaderService.getPromoterViewAsObject().subscribe((res) => {
            if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'promoter') {
              this.merchantID = null;
              this.hideMerchantSelect = false;
            } else if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'merchant') {
              this.merchantID = res['merchantID'];
              this.hideMerchantSelect = true;
            }
          });
        }
      });
    });
  }

  viewMerchant() {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: this.merchantID,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }
}
