<div class="icon-container" *ngIf="link && document && hasIcon == true">
  <mat-icon class="fab fa-css3" *ngIf="document.Extension == 'css'"></mat-icon>
  <mat-icon class="fab fa-js" *ngIf="document.Extension == 'js'"></mat-icon>
  <mat-icon class="fab fa-html5" *ngIf="document.Extension == 'html'"></mat-icon>
  <mat-icon class="fas fa-file-alt" *ngIf="document.Extension == 'pdf'"></mat-icon>

  <mat-icon
    class="fas fa-file-alt"
    *ngIf="
      document.Extension != 'pdf' &&
      document.Extension != 'css' &&
      document.Extension != 'js' &&
      document.Extension != 'html' &&
      type == 'document'
    "
  >
  </mat-icon>

  <mat-icon class="fas fa-image" *ngIf="type == 'image'"></mat-icon>
  <mat-icon class="fas fa-video" *ngIf="type == 'video'"></mat-icon>
  <mat-icon class="fas fa-file-csv" *ngIf="type == 'excel'"></mat-icon>
  <mat-icon class="fas fa-link" *ngIf="type == 'link'"></mat-icon>
</div>

<app-ep-document-view-image
  class="full-width thumbnail"
  *ngIf="link"
  [notChecked]="notChecked"
  [hideZoomButton]="hideZoomButton"
  [defaultPicture]="'url(' + defaultImg + ')'"
  [link]="link"
  (getClick)="clickEvent()"
>
</app-ep-document-view-image>
