import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { MessageService } from '../shared/message.service';
import { MAT_DIALOG_DATA } from '@angular/material';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import * as _ from 'lodash';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { CustomerProspectViewModelComponent } from '../../customer-prospect/customer-prospect-view-model/customer-prospect-view-model.component';
import { InstantOfferCreateComponent } from '../../instant-finance-offer/instant-offer-create/instant-offer-create.component';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsService } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-message-group-card-list',
  templateUrl: './message-group-card-list.component.html',
  styleUrls: ['./message-group-card-list.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: '*' }))),
    ]),
  ],
})
export class MessageGroupCardListComponent implements OnInit {
  @Input()
  isModal = false;
  @Input()
  cards = [];
  @Input()
  filterKeys = [];
  @Input()
  selectedIDs = [];
  @Input()
  excludedIDs = [];

  @Input()
  title = 'Preview Selection';

  filterData;
  @Input()
  tags = [];

  @Input()
  marketingConsent = true;

  @Input()
  showEmpty = true;
  @Input()
  itemsPerPage = 50;
  search;
  @Input()
  isEdit = false;

  currentPage = 1;
  orderByFilter = 'CalculatedName';

  hasMobile= true ;
  hasEmail = false ;
  serviceRef;
  isDestoyed = false;
  @Output()
  close = new EventEmitter();

  merchantID;
  selectAll = false;
  sessionType;
  isModuleSMSOfferActive = false;
  @Input()
  isRemove = false;
  @Output()
  getCards = new EventEmitter();
  @Output()
  getRemoved = new EventEmitter();
  @Output()
  getRemovedCard = new EventEmitter();
  @Input()
  isOneLine = false;

  @Input()
  hideHeader = false;

  @Input()
  isViewOnly = false;

  @Input()
  hideActions = false;

  @Input()
  removeTitle = 'Remove from this list';
  constructor(
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private customPhonePile: CustomPhonePipe,
    private utilService: UtilsService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.cards && data.cards.length > 0) {
        this.cards = _.uniqBy(data.cards, 'ID');
        this.isModal = true;
      }
      if (data.filterKeys && data.filterKeys.length > 0) {
        this.filterKeys = data.filterKeys;
        this.isModal = true;
      }
      if (data.selectedIDs && data.selectedIDs.length > 0) {
        this.selectedIDs = data.selectedIDs;
        this.isModal = true;
      }
      if (data.title) {
        this.title = data.title;
        this.isModal = true;
      }
      if (data.removeTitle) {
        this.removeTitle = data.removeTitle;
        this.isModal = true;
      }

      if (data.excludedIDs && data.excludedIDs.length > 0) {
        this.excludedIDs = data.excludedIDs;
        this.isModal = true;
      }

      if (data.tags && data.tags.length > 0) {
        this.tags = data.tags;
        this.isModal = true;
      }
      if (data.isOneLine === true) {
        this.isOneLine = true;
        this.isModal = true;
      } else if (data.isOneLine === false) {
        this.isOneLine = false;
        this.isModal = true;
      }
      if (data.marketingConsent === true) {
        this.marketingConsent = true;
        this.isModal = true;
      } else if (data.marketingConsent === false) {
        this.marketingConsent = false;
        this.isModal = true;
      }



      if (data.hasMobile === true) {
        this.hasMobile = true;
        this.isModal = true;
      } else if (data.hasMobile === false) {
        this.hasMobile = false;
        this.isModal = true;
      }


      if (data.hasEmail === true) {
        this.hasEmail = true;
        this.isModal = true;
      } else if (data.hasEmail === false) {
        this.hasEmail = false;
        this.isModal = true;
      }
      if (data.selectAll === true) {
        this.selectAll = true;
        this.isModal = true;
      } else if (data.selectAll === false) {
        this.selectAll = false;
        this.isModal = true;
      }

      if (data.isViewOnly === true) {
        this.isViewOnly = true;
        this.isModal = true;
      } else if (data.isViewOnly === false) {
        this.isViewOnly = false;
        this.isModal = true;
      }

      if (data.hideHeader === true) {
        this.hideHeader = true;
        this.isModal = true;
      } else if (data.hideHeader === false) {
        this.hideHeader = false;
        this.isModal = true;
      }

      if (data.hideActions === true) {
        this.hideActions = true;
        this.isModal = true;
      } else if (data.hideActions === false) {
        this.hideActions = false;
        this.isModal = true;
      }

      if (data.itemsPerPage) {
        this.itemsPerPage = data.itemsPerPag;
        this.isModal = true;
      }

      if (data.isRemove === true) {
        this.isRemove = true;
        this.isModal = true;
      } else if (data.isRemove === false) {
        this.isRemove = false;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.setAccess(access);
      }
    });

    this.authenticationService.getSessionType().subscribe((res) => {
      if (res) {
        this.sessionType = res;
      }

      this.setup();
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      this.selectedIDs = _.uniq(this.selectedIDs);
    }

    if (this.excludedIDs && this.excludedIDs.length > 0) {
      this.excludedIDs = _.uniq(this.excludedIDs);
    }

    if (this.cards && this.cards.length > 0) {
      this.cards = _.uniqBy(this.cards, 'ID');
    }
  }
  setAccess(res) {
    if (res) {
      this.isModuleSMSOfferActive = res['isModuleSMSOfferActive'];
    }
  }

  setup() {
    if (this.cards && this.cards.length > 0) {
      this.cards = _.uniqBy(this.cards, 'ID');
    } else
      (this.selectedIDs && this.selectedIDs.length > 0) ||
        this.selectAll == true ||
        (this.tags && this.tags.length > 0) ||
        (this.filterKeys && this.filterKeys.length > 0);
    {
      let p = {
        tags: this.tags.join(','),
        section: 1,
        selectedIDs: this.selectedIDs.join(','),
        selectAll: this.selectAll,
        excludedIDs: this.excludedIDs.join(','),
        filterKeys: this.filterKeys.join(','),
        merchantID: this.merchantID,
        marketingConsent: this.marketingConsent,
        limit: 200,
        fields: 'ID,CalculatedName,mobiles.Number,emails.Email,LastModified,DateTimeCreated',

        hasMobile: this.hasMobile,
        hasEmail: this.hasEmail,
      };

      this.serviceRef = this.messageService.getCustomerProspectMergedList(p, this.sessionType).subscribe((res) => {
        if (res && res.length > 0) {
          res = _.map(res, (item) => {
            if (item) {
              item.search = '';
              if (item.ID) {
                item.search = item.search + ' ' + item.ID;
              }
              if (item.CalculatedName) {
                item.search = item.search + ' ' + item.CalculatedName;
              }

              if (item['mobiles.Number']) {
                item.search =
                  item.search + ' ' + this.customPhonePile.transform(item['mobiles.Number']).replace(/\s/g, '');
              }
              if (item['emails.Email']) {
                item.search = item.search + ' ' + item['emails.Email'];
              }
            }
            return item;
          });

          this.cards = res;

          const innerFunction = (section) => {
            section = section + 1;
            p.section = section;

            this.serviceRef = this.messageService
              .getCustomerProspectMergedListHidden(p, this.sessionType)
              .subscribe((res) => {
                if (res.length > 0) {
                  res = _.map(res, (item) => {
                    if (item) {
                      item.search = '';
                      if (item.ID) {
                        item.search = item.search + ' ' + item.ID;
                      }
                      if (item.CalculatedName) {
                        item.search = item.search + ' ' + item.CalculatedName;
                      }

                      if (item['mobiles.Number']) {
                        item.search = item.search + ' ' + this.customPhonePile.transform(item['mobiles.Number']).trim();
                      }
                      if (item['emails.Email']) {
                        item.search = item.search + ' ' + item['emails.Email'];
                      }
                    }
                    return item;
                  });

                  this.cards = this.cards.concat(res);

                  if (this.isDestoyed != true) {
                    innerFunction(section);
                  }
                } else {
                  return true;
                }
              });
          };

          if (this.isDestoyed != true) {
            innerFunction(1);
          }
        }
      });
    }
  }

  orderBy(d) {
    if (d) {
      if (this.orderByFilter === d) {
        this.orderByFilter = '-' + d;
      } else {
        this.orderByFilter = d;
      }
    }
  }

  openPatientViewDialogContainer(id) {
    if (id && this.isViewOnly == true) {
      this.openPatientViewDialog(id);
    }
  }

  openPatientViewDialog(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(CustomerProspectViewModelComponent, {
        data: {
          patientID: id,
          isFullDetails: false,
        },
        width: '700px',
      });

      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  displayInstantOfferEvent(id) {
    if (id) {
      const ref2 = RootAppComponent.dialog.open(InstantOfferCreateComponent, {
        data: {
          cardID: id,
          merchantID: this.merchantID,
          isActive: true,
          sendSMS: true,
          sendEmail: true,
        },
        panelClass: 'noCard',
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });

      ref2.componentInstance.getResult.subscribe((r) => {
        if (r && r.ID) {
          NotifyAppComponent.displayToast(
            'success',
            'Successfull',
            'Instant Finance Offer is created and sent to the patient.'
          );

          ref2.close();
        }
      });
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
  changePage(e) {
    if (e) {
      this.currentPage = e;
    }
  }

  remove(card) {
    if (card && card.ID) {
      let index = this.cards.findIndex((item) => {
        if (item && item.ID && item.ID === card.ID) {
          return true;
        }

        return false;
      });

      if (index != -1) {
        this.cards.splice(index, 1);
        this.cards = JSON.parse(JSON.stringify(this.cards));
        this.cards = _.uniqBy(this.cards, 'ID');
        this.getCards.emit(this.cards);

        this.getRemoved.emit(card.ID);
        this.getRemovedCard.emit(card);
      }
    }
  }

  ngOnDestroy() {
    this.isDestoyed = true;
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}
