import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { DatepickerOptions } from 'ng2-datepicker';
import { map } from 'rxjs/operators';

import { MatPaginator } from '@angular/material/paginator';
import { LookupService } from '../../../shared/services/lookup.service';
import { LookupClass } from '../../../shared/types/lookupClass';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { DentistService } from '../../dentist/shared/dentist.service';
import { ProductService } from '../../product/shared/product.service';
import { InvitationViewModalComponent } from '../invitation-view-modal/invitation-view-modal.component';
import { InvitationService } from '../shared/services/invitation.service';

import { FormControl } from '@angular/forms';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { Settings } from '../../../shared/types/settings';
import { DentistViewComponent } from '../../dentist/dentist-view/dentist-view.component';
import { ExistingPatientSummaryComponent } from '../../existing-patient/existing-patient-summary/existing-patient-summary.component';

/**
 * @title Feature-rich data table
 */
@Component({
  selector: 'app-invitation-list-informed-consent',
  templateUrl: './invitation-list-informed-consent.component.html',
  styleUrls: ['./invitation-list-informed-consent.component.css'],
})
export class InvitationListInformedConsentComponent implements OnInit {
  dateFrom: any = {
    date: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDay() + 1,
    },
  };

  firstName;

  optionsFrom: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2030,
    displayFormat: 'MMM D[,] YYYY',
    barTitleFormat: 'MMMM YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    minDate: new Date(Date.now()), // Minimal selectable date
    barTitleIfEmpty: 'Click to select a date',
  };
  optionsTo: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2030,
    displayFormat: 'MMM D[,] YYYY',
    barTitleFormat: 'MMMM YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    minDate: this.dateFrom, // Minimal selectable date
    barTitleIfEmpty: 'Click to select a date',
  };
  dateTo: any;

  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];
  utils = new UtilsClass();
  lookup = new LookupClass();
  displayedColumns = [
    'Date',
    'Operator',
    'Customer',
    'Email',
    'Treatment',
    'Amount',
    'Product',
    'Source',
    'EmailStatus',
    'InvitationStatus',
    'ApplicationStatus',
    'ContractStatus',
    'Response',
    'Actions',
  ];
  searchVls = new FormControl();

  searchValList = [
    'Date',
    'Operator',
    'First Name',
    'Last Name',
    'Email',
    'Treatment',
    'Amount',
    'Product',
    'Email Status',
    'Application Status',
    'Contract Status',
    'Invitation Status',
  ];

  filters = [];
  selectedValue = '';
  selectedField: any = {
    field: '',
    table: '',
    category: '',
    value: '',
  };

  filteredSize = null;

  public invitationsDB: LoadInv | null;
  dataSource: InvitationDataSource | null;
  showFirstFilter = false;
  showSecondFilter = false;
  showDateFilter = false;
  showInput = true;

  destroyEvent = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private invitationService: InvitationService,
    private lookupService: LookupService,
    private productService: ProductService,
    private dentistService: DentistService,
    private dialog: MatDialog,
    private customDate: CustomDatePipe
  ) {}

  ngOnInit() {
    this.invitationsDB = new LoadInv(this.invitationService, this.destroyEvent);
    this.dataSource = new InvitationDataSource(this.invitationsDB, this.paginator, this.sort);
  }

  setFilter(event, field) {
    let filter;
    if (typeof event == 'object' && event != null) {
      const v = this.customDate.transform(event, Settings.global['dateFormat']);
      filter = {
        field,
        value: v,
      };
    } else if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  openInviteViewDialog() {
    const ref = this.dialog.open(InvitationViewModalComponent, {
      data: {
        invitationID: null,
        membership: false,
      },
      width: '900px',
    });
  }

  viewPatient(id) {
    const ref = RootAppComponent.dialog.open(ExistingPatientSummaryComponent, {
      data: id,
      width: '600px',
      panelClass: 'noCard',
    });
  }

  viewOperator(id) {
    const ref = RootAppComponent.dialog.open(DentistViewComponent, {
      data: id,
      width: '600px',
      panelClass: 'noCard',
    });
  }

  onClickMore() {}

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadInv implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public invitations: any[];
  public count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  isDestoyed = false;

  serviceRef;

  constructor(private invitationService: InvitationService, private destroyEvent) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });
    const payload = {
      Voided: 0,
      section: 0,
      fields:
        'ID,DateTimeCreated,DentistContactName,ExistingPatientID,Product_Key,' +
        'DentistContact_key,Email,' +
        'LastName,FirstName,Product.Label.Marketing,Status.Email.Short,' +
        'Status.Invitation.Short,PatientChoice.Label_LongThirdPerson,PatientChoice.Code' +
        ',Status.Contract.Short,Status.Application.Short,Service.Type,Service.Value,' +
        'Status.Application.Colour,Status.Invitation.Colour,Status.Email.Colour,Status.Contract.Colour',
    };

    this.serviceRef = this.invitationService.getInvitations(payload).subscribe((res) => {
      if (res.success) {
        this.count = res.count;
        this.invitations = res.data;
        this.dataChange.next(this.invitations.slice());
        this.firstLoad = true;
        const innerFunction = (section) => {
          section = section + 1;
          UtilsClass.loadingDataSection(section);
          payload.section = section;

          this.serviceRef = this.invitationService.getInvitations(payload).subscribe((res) => {
            if (res.success) {
              if (res.data.length > 0) {
                this.count = res.count;
                this.invitations = this.invitations.concat(res.data);

                this.dataChange.next(this.invitations);

                if (this.isDestoyed != true) {
                  innerFunction(section);
                }
              } else {
                return true;
              }
            } else {
              return false;
            }
          });
          UtilsClass.stopLoadingInterceptor();
        };

        if (this.isDestoyed != true) {
          innerFunction(1);
        }
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class InvitationDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';
  dateF = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this.dateF = item.dateFrom ? item.dateFrom : '';
    this._filterChange.next(item.value);
  }

  filteredData: any[] = [];

  constructor(private _invDatabase: any, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._invDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._invDatabase.data.slice().filter((item: any) => {
          let f = '';
          if (this.field == 'Name') {
            f = item['FirstName'] + ' ' + item['LastName'];
          } else if (this.field == 'Date') {
            if (this.filter == '') {
              return true;
            }
            f = item['DateTimeCreated'];
            const currDate = new Date(f);
            const fromDate = new Date(this.dateF);
            const toDate = new Date(this.filter);
            return currDate > fromDate && currDate < toDate;
          } else {
            f = item[this.field] || '';
          }
          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'Date':
          [propertyA, propertyB] = [a['DateTimeCreated'], b['DateTimeCreated']];
          break;
        case 'Operator':
          [propertyA, propertyB] = [a.DentistContactName, b.DentistContactName];
          break;
        case 'Customer':
          [propertyA, propertyB] = [a.FirstName, b.FirstName];
          break;
        case 'Email':
          [propertyA, propertyB] = [a['Service.Email'], b['Service.Email']];
          break;

        case 'Treatment':
          [propertyA, propertyB] = [a['Service.Type'], b['Service.Type']];
          break;
        case 'Amount':
          [propertyA, propertyB] = [a['Service.Value'], b['Service.Value']];
          break;
        case 'Product':
          [propertyA, propertyB] = [a['Product.Label.Marketing'], b['Product.Label.Marketing']];
          break;
        case 'Source':
          [propertyA, propertyB] = [a['PatientChoice.Code'], b['PatientChoice.Code']];
          break;
        case 'EmailStatus':
          [propertyA, propertyB] = [a['Status.Email.Short'], b['Status.Email.Short']];
          break;
        case 'InvitationStatus':
          [propertyA, propertyB] = [a['Status.Invitation.Short'], b['Status.Invitation.Short']];
          break;
        case 'ApplicationStatus':
          [propertyA, propertyB] = [a['Status.Application.Short'], b['Status.Application.Short']];
          break;
        case 'ContractStatus':
          [propertyA, propertyB] = [a['Status.Contract.Short'], b['Status.Contract.Short']];
          break;
        case 'Response':
          [propertyA, propertyB] = [a['PatientChoice.Label_LongThirdPerson'], b['PatientChoice.Label_LongThirdPerson']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
