<div class="clearfix" *ngIf="isAdminOrPromoter == true">
  <app-operator-filter
    class="dash"
    id="part1"
    *ngIf="isAdminOrPromoter == true"
    [setAutoSelectMerchant]="true"
    [selectDateRange]="selectedRange"
    [displayMerchant]="isDisplayMerchant"
    [displayDateFrom]="true"
    [displayDateTo]="true"
    [displayDentist]="false"
    (search)="applySearch($event)"
  >
  </app-operator-filter>

  <div class="row clearfix">
    <app-brand-list
      *ngIf="merchantID != 'none'"
      [pageSize]="pageSize"
      [dateFrom]="dateFrom"
      [dateTo]="dateTo"
      [isActive]="true"
      [merchantID]="merchantID"
    >
    </app-brand-list>
  </div>
  <div class="row clearfix">
    <div class="pleaseChoose" *ngIf="merchantID == 'none'" @ngIfAnimation>
      <p>
        <strong>No merchant selected</strong>
      </p>
      <img class="character_warning" />
      <p>
        Choose your desired merchant from the drop-down above to view their
        {{ "KEYWORD.patient" | translate }}s
      </p>
    </div>
  </div>
</div>

<div class="clearfix" *ngIf="isAdminOrPromoter == false">
  <app-operator-filter
    class="dash"
    id="part1"
    *ngIf="isAdminOrPromoter == true"
    [selectDateRange]="selectedRange"
    [displayMerchant]="false"
    [displayDateFrom]="true"
    [displayDateTo]="true"
    [displayTimePicker]="true"
    [displayDentist]="false"
    (search)="applySearch($event)"
  >
  </app-operator-filter>

  <div class="row clearfix">
    <app-brand-list [pageSize]="pageSize" [dateFrom]="dateFrom" [dateTo]="dateTo" [isActive]="true"></app-brand-list>
  </div>
</div>
