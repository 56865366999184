import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { SideMenuService } from '../../../shared/services/side-menu.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { LookupClass } from '../../../shared/types/lookupClass';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { CustomerProspectService } from '../../customer-prospect/shared/customerProspect.service';
import { DentistViewComponent } from '../../dentist/dentist-view/dentist-view.component';
import { MerchantAuthCodeModalComponent } from '../../merchant/merchant-auth-code-modal/merchant-auth-code-modal.component';
import { NotesModal, NotesModalConfig } from '../../notes/modals/notes-modal/notes.modal';
import { ProductService } from '../../product/shared/product.service';
import { ProductUtil } from '../../product/shared/productUtil.type';
import { InvitationCommunicationViewComponent } from '../invitation-communication-view/invitation-communication-view.component';
import { InvitationResendComponent } from '../invitation-resend/invitation-resend.component';
import { InvitationService } from '../shared/services/invitation.service';

/**
 * @title Feature-rich data table
 */
@Component({
  selector: 'app-invitation-communication-list',
  templateUrl: './invitation-communication-list.component.html',
  styleUrls: ['./invitation-communication-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class InvitationCommunicationListComponent implements OnInit {
  @Input()
  staffID;

  @Input()
  dateFrom;

  @Input()
  dateTo;

  @Input()
  purposeCode;

  @Input()
  voided = false;

  invitationID = null;
  public displayInvitationView = false;
  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  utils = new UtilsClass();

  lookup = new LookupClass();
  displayedColumns = [
    'Customer',
    'Date',
    'mobile',
    'email',
    'Operator',
    'EmailStatus',
    'InvitationStatus',
    'timeElapsed',
    'Actions',
  ];

  searchVals = new FormControl();

  searchValList = [
    'Invitation Status',
    'Dentist',
    'Amount',
    'Product',
    'Product Group',
    'Email Status',
    'Application Status',
    'Contract Status',
  ];

  treatmentValue = 0;

  filters = [];
  selectedValue = '';
  selectedField: any = {
    field: '',
    table: '',
    category: '',
    value: '',
  };
  subjectArray = [];

  stretchList = false;
  filteredSize = null;

  isAdminOrPromoter = false;

  public invitationsDB: LoadInv | null;
  dataSource: InvitationDataSource | null;
  showFirstFilter = false;
  showSecondFilter = false;
  showDateFilter = false;
  showInput = true;
  dateNow;
  selectedTreatmentValue;
  activeButton = false;

  isModuleNoteFeaturesActive = Settings.global['isModuleNoteFeaturesActive'];
  isModuleTreatmentTemplateActive = Settings.global['isModuleTreatmentTemplateActive'];
  isModulePatientPortalAccessActive = Settings.global['isModulePatientPortalAccessActive'];
  emailReputation = 'none';
  emailReputationColor;
  subAccountObj;

  financedProductGroup;
  productUtil = new ProductUtil();

  destroyEvent = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private invitationService: InvitationService,
    private router: Router,
    private customDate: CustomDatePipe,
    private authenticationService: AuthenticationService,
    private productService: ProductService,
    private utilService: UtilsService,
    private customerProspectService: CustomerProspectService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleNoteFeaturesActive = access['isModuleNoteFeaturesActive'];
        this.isModuleTreatmentTemplateActive = access['isModuleTreatmentTemplateActive'];
        this.isModulePatientPortalAccessActive = access['isModulePatientPortalAccessActive'];
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;
      });
      SideMenuService.goBack.subscribe((res) => {
        if (res == true) {
        }
      });

      this.utilService.getServerDate().subscribe((res) => {
        this.dateNow = res;
      });

      this.invitationsDB = new LoadInv(
        this.invitationService,
        this.destroyEvent,
        this.staffID,
        this.dateFrom,
        this.dateTo,
        this.purposeCode,
        this.voided
      );
      this.dataSource = new InvitationDataSource(this.invitationsDB, this.paginator, this.sort);

      const p1 = {
        isFinance: true,
      };

      this.productService.getMerchantProductGroup(p1).subscribe((res) => {
        this.financedProductGroup = res;
      });
    });

    // this.sumAmount();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.purposeCode == 'COM_UHH') {
      AuthenticationService.setTitle.emit('Health History Invitations');
    }
    if (this.purposeCode == 'COM_GGL') {
      AuthenticationService.setTitle.emit('Google Review Invitations');
    }
    this.utilService.getServerDate().subscribe((res) => {
      this.dateNow = res;
    });

    this.invitationsDB = new LoadInv(
      this.invitationService,
      this.destroyEvent,
      this.staffID,
      this.dateFrom,
      this.dateTo,
      this.purposeCode,
      this.voided
    );
    this.dataSource = new InvitationDataSource(this.invitationsDB, this.paginator, this.sort);
  }

  openInviteViewDetails(row) {
    if (row && row.ID && row['MedicalHistory_key'] && row['Prospect_key']) {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['customer-profile', row['Prospect_key'], 'health', row['MedicalHistory_key']],
          },
        },
      ]);
    } else if (row && row.ID) {
      const ref = RootAppComponent.dialog.open(InvitationCommunicationViewComponent, {
        data: {
          invitationID: row['ID'],
        },
        width: '700px',
      });
    }
  }

  createHealthHistoryInvitation() {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['medical-history', 'medical-history-invitation-create'],
        },
      },
    ]);
  }

  createGoogleReviewInvitation() {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['google-review-invitation-create'],
        },
      },
    ]);
  }

  openAuthCodeDialog(row) {
    if (row && row['ID'] && row['Prospect_key'] && row['Merchant_key']) {
      const ref = RootAppComponent.dialog.open(MerchantAuthCodeModalComponent, {
        data: {
          merchantID: row['Merchant_key'],
          type: 'healthHistory',
          patientID: row['Prospect_key'],
          medicalHistoryInvitationID: row['ID'],
        },
        width: '850px',
      });
      const sub = ref.componentInstance.close.subscribe((data) => {
        ref.close();
      });
    }
  }

  setFilter(event, field) {
    let filter;
    if (typeof event == 'object' && event != null) {
      const v = this.customDate.transform(event, Settings.global['dateFormat']);
      filter = {
        field,
        value: v,
      };
    } else if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  viewPatient(id) {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['customer-profile', id, 'patient'],
        },
      },
    ]);
  }

  contact(ID) {
    const data = {
      targetType: 'invitation-communication',
      targetID: ID,
      asGuest: false,
      asProfile: true,
      asPractice: true,
      subjectArray: this.subjectArray,
    };
    AuthenticationService.contactInputEvent.emit(data);
  }

  viewOperator(ID) {
    const ref = RootAppComponent.dialog.open(DentistViewComponent, {
      data: ID,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  createNote(row: any) {
    this.dialog.open<NotesModal, NotesModalConfig>(NotesModal, {
      data: {
        parentRoute: 'merchant',
        noteListMode: 'create',
        relationshipLink: {
          label: 'health history',
          route: `customer-profile/${row['Prospect_key']}/health/${row['MedicalHistory_key']}`,
          itemID: row['MedicalHistory_key'],
        },
      },
    });
  }

  viewNotes(patientID: string) {
    this.dialog.open<NotesModal, NotesModalConfig>(NotesModal, {
      data: {
        parentRoute: 'merchant',
        patientID,
      },
    });
  }

  linkToInvitation(p, patientID) {
    if (p && p['GroupCode']) {
      const payload = {
        productGroupCode: p['GroupCode'],
      };

      this.customerProspectService.linkProspectToInv(patientID, payload).subscribe((res) => {
        if (res.success) {
          this.router.navigate(['/merchant', { outlets: { page: ['invitation-create', res.data] } }]);
        }
      });
    }
  }

  expendItems(item) {
    this.dataSource.showAllItem = item;
  }

  resendInvitation(item) {
    const ref = RootAppComponent.dialog.open(InvitationResendComponent, {
      data: {
        invitationID: item['ID'],
        isTreatmentInvite: false,
      },
      width: '600px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });

    ref.componentInstance.getInvitation.subscribe((res) => {
      if (res) {
        // if (this.voided == true) {
        //   this.dataSource.removeItem = item['ID'];
        // }
        // else
        this.dataSource.replaceItem = res;
      }
    });
  }

  deleteInvitation(id) {
    const confirmDialog = new ConfirmDialog(
      'delete',
      'Archive Invitation?',
      '<p><strong>Are you sure?</strong></p><p class="noFurther">They will not recieve further notifications from the Smile Right system.</p>',
      'No',
      'Yes, archive'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        this.invitationService.deleteInvitationCommunication(id, this.isAdminOrPromoter).subscribe((res) => {
          if (res == true) {
            this.dataSource.removeItem = id;

            NotifyAppComponent.displayToast(
              'success',
              'Successful operation',
              'The invitation has been successfully archived'
            );
            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }

  convertMobile(val) {
    return '0061' + val.substring(1);
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadInv implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public invitations: any[];
  public count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  util = new UtilsClass();

  isDestoyed = false;
  serviceRef;

  constructor(
    private invitationService: InvitationService,
    private destroyEvent,
    private staffID,
    private dateFrom,
    private dateTo,
    private purposeCode,
    private voided
  ) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    const payload = {
      voided: '0',
      dateFrom: this.util.EPdateFormat(this.dateFrom),
      dateTo: this.util.EPdateFormat(this.dateTo),
      staffID: this.staffID,
      purposeCode: this.purposeCode,
      section: 0,
      // fields:
      //   'ID,DateTimeCreated,DentistContactName,ExistingPatientID,Product_Key,Prospect_key,WakandaID,' +
      //   'DentistContact_key,Email,DateTimeCreated,Product.ThisGroupOnly.Label,Product.ThisGroupOnly.Code,PatientDeclineReasons.Labels,PatientDeclineReasons.Feedback,PatientFeedback,' +
      //   'LastName,FirstName,Product.Label.Marketing,Status.Email.Short_NE,Voided,' +
      //   'Status.Invitation.Short_NE,PatientChoice.Label_LongThirdPerson,PatientChoice.Code,' +
      //   'Status.Contract.Short_NE,Status.Application.Short_NE,Service.Type,treatment.Value,Date.Responded,Date.Commenced,' +
      //   "Status.Application.Colour,Status.Invitation.Colour,Status.Email.Colour,Status.Contract.Colour,Mobile,SendInvite.ViaSMS," +
      //   'CashDiscount.Offered.YesNo,InformedConsent.Given.When,InformedConsent.Given.Flag'
    };

    if (this.voided == true) {
      payload.voided = '1';
    }

    this.serviceRef = this.invitationService.getInvitationsCommunication(payload).subscribe((res) => {
      // console.log(res);
      if (res.success) {
        this.count = res.count;
        this.invitations = res.data;
        this.dataChange.next(this.invitations);
        this.firstLoad = true;
        const innerFunction = (section) => {
          section = section + 1;
          UtilsClass.loadingDataSection(section);
          payload.section = section;

          this.serviceRef = this.invitationService.getInvitationsCommunication(payload).subscribe((res) => {
            if (res.success) {
              if (res.data.length > 0) {
                this.count = res.count;
                this.invitations = this.invitations.concat(res.data);

                this.dataChange.next(this.invitations);

                if (this.isDestoyed != true) {
                  innerFunction(section);
                }
              } else {
                return true;
              }
            } else {
              return false;
            }
          });
          UtilsClass.stopLoadingInterceptor();
        };

        if (this.isDestoyed != true) {
          innerFunction(1);
        }
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class InvitationDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';
  expandingItems = [];

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  set replaceItem(item) {
    const _index = this._invDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    this._invDatabase.data[_index] = item;
    this._invDatabase.dataChange.next(this._invDatabase.data);
  }

  set showAllItem(item) {
    this.expandingItems.push(item.Prospect_key);
    this._invDatabase.dataChange.next(this._invDatabase.data);
  }

  set removeItem(id) {
    const data = this._invDatabase.data.filter((row) => row.ID != id);
    this._invDatabase.dataChange.next(data);
  }

  filteredData: any[] = [];

  constructor(private _invDatabase: any, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._invDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        let data = this.getMergedData(this._invDatabase.data.slice());
        data = data.slice().filter((item: any) => {
          let f = '';
          f = item[this.field] || '';

          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        data = this.getSortedData(data.slice());
        this.filteredData = data.slice();
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  getMergedData(data: any[]) {
    const key = 'Prospect_key';

    const uniqueData = [...new Map(data.map((item) => [item[key], item])).values()];

    const finalData = [];
    uniqueData.forEach((it) => {
      if (it[key]) {
        const list = data.filter((o) => o[key] === it[key]) || [];
        const l = list.length;

        const isMerged = !this.expandingItems.includes(it[key]) && l > 1;
        if (isMerged && list[0][key]) {
          list[0].isMerged = true;
          list[0].mergedLength = l;

          finalData.push(list[0]);
        } else {
          it.isMerged = false;
          if (list && list.length > 0) {
            list.map((item) => {
              item.isMerged = false;
              item.mergedLength = null;

              return item;
            });
          }
          finalData.push(...list);
        }
      } else {
        it.isMerged = false;
        finalData.push(...data.filter((o) => o[key] === it[key]));
      }
    });

    return finalData;
  }

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'Date':
          [propertyA, propertyB] = [
            moment(a['DateTimeCreated']).toDate().getTime(),
            moment(b['DateTimeCreated']).toDate().getTime(),
          ];
          break;
        case 'Operator':
          [propertyA, propertyB] = [a.DentistContactName, b.DentistContactName];
          break;
        case 'Customer':
          [propertyA, propertyB] = [a['FirstName'], b['LastName']];
          break;
        case 'Email':
          [propertyA, propertyB] = [a['Service.Email'], b['Service.Email']];
          break;
        case 'Amount':
          [propertyA, propertyB] = [a['treatment.Value'], b['treatment.Value']];
          break;
        case 'Product':
          [propertyA, propertyB] = [a['Product.Label.Marketing'], b['Product.Label.Marketing']];
          break;
        case 'ProductGroup':
          [propertyA, propertyB] = [a['Product.ThisGroupOnly.Label'], b['Product.ThisGroupOnly.Label']];
          break;

        case 'InformedConsent':
          [propertyA, propertyB] = [a['InformedConsent.Given.Flag'], b['InformedConsent.Given.Flag']];
          break;

        case 'Source':
          [propertyA, propertyB] = [a['PatientChoice.Code'], b['PatientChoice.Code']];
          break;
        case 'EmailStatus':
          [propertyA, propertyB] = [a['Status.Email.Short'], b['Status.Email.Short']];
          break;
        case 'InvitationStatus':
          [propertyA, propertyB] = [a['Status.Invitation.Short'], b['Status.Invitation.Short']];
          break;
        case 'ApplicationStatus':
          [propertyA, propertyB] = [a['Status.Application.Short'], b['Status.Application.Short']];
          break;
        case 'ContractStatus':
          [propertyA, propertyB] = [a['Status.Contract.Short'], b['Status.Contract.Short']];
          break;
        case 'Response':
          [propertyA, propertyB] = [a['PatientChoice.Label_LongThirdPerson'], b['PatientChoice.Label_LongThirdPerson']];
          break;

        case 'timeElapsed':
          [propertyA, propertyB] = [
            moment(a['DateTimeCreated']).toDate().getTime(),
            moment(b['DateTimeCreated']).toDate().getTime(),
          ];
          break;

        case 'timeInvited':
          [propertyA, propertyB] = [
            moment(a['DateTimeCreated']).toDate().getTime(),
            moment(b['DateTimeCreated']).toDate().getTime(),
          ];
          break;
        case 'timeResponded':
          [propertyA, propertyB] = [
            moment(a['Date.Responded']).toDate().getTime(),
            moment(b['Date.Responded']).toDate().getTime(),
          ];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
