<ng-template #starTemplate let-fill="fill">
  <span class="star" [style.fontSize]="fontSize">
    <span class="half" [style.width.%]="fill" [ngStyle]="{ color: color }">&#9733;</span>&#9733;
  </span>
</ng-template>

<ngb-rating
  [rate]="rating"
  [starTemplate]="starTemplate"
  [readonly]="true"
  [max]="max"
  style="white-space: nowrap"
></ngb-rating>
