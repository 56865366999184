import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Location } from '@angular/common';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ExistingPatientService } from '../shared/existing-patient.service';

@Component({
  selector: 'app-existing-patient-view-details',
  templateUrl: './existing-patient-view-details.component.html',
  styleUrls: ['./existing-patient-view-details.component.css'],
})
export class ExistingPatientViewDetailsComponent implements OnInit {
  @Input() existingPatientID;
  existingPatient = {};
  isPromoterOrAdmin = false;
  constructor(
    private location: Location,
    private authenticationService: AuthenticationService,
    private existingPatientService: ExistingPatientService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.existingPatientID = data;
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      this.existingPatientService.getExistingPatientFullDetails(this.existingPatientID).subscribe((res) => {
        this.existingPatient = res;
      });
    });
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }
}
