<form #moduleForm="ngForm">
  <mat-card class="headercard">
    <div class="card-header primary-gradient-img clearfix moduleList">
      <div class="row clearfix">
        <div class="row titleArea clearfix">
          <mat-icon class="pull-left white fas fa-cubes"></mat-icon>
          <h2 class="summary-header rm-m">
            Dashboard Modules
            <span class="subLabel"
            >Below is a list of the system modules which are currently active on your dashboard</span
            >
          </h2>
        </div>
      </div>
    </div>
    <div class="row clearfix flex">
      <mat-form-field class="full-width clearfix" *ngIf="accessList && accessList.length > 0" appearance="outline">
        <mat-label>Filter by name or description</mat-label>

        <input
          class="full-width"
          [(ngModel)]="filterName"
          name="linkLabel"
          matInput
          placeholder="Filter by name or description"
        />
      </mat-form-field>

      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Active filter</mat-label>

        <mat-select class="" [(ngModel)]="isActiveFilter" name="isQctive" placeholder="Active  filter">
          <mat-option [value]="'*'">
            All
          </mat-option>

          <mat-option [value]="true">
            Active
          </mat-option>

          <mat-option [value]="false">
            Inactive
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-card>

  <div class="row clearfix">
    <div class="full-width">
      <div class="flex module-card-flex">
        <mat-card
          class="card module-card"
          id="module-card-tips"
          *ngFor="
            let a of accessList
              | FilterArrayMultipleValue: 'isAvailable':true
              | FilterArrayMultipleValue: ['isActive']:[isActiveFilter]
              | FilterArrayMultipleContainValue: ['Description', 'Label', 'moduleCode']:filterName;
            let index = index
          "
          [class.disabledModule]="
            (a.isActive == true && access[a['moduleCode']] == false) ||
            (a.isActive == false && access[a['moduleCode']] == false)
          "
          [hidden]="access[a['moduleCode']] == null"
        >
          <div class="row clearfix">
            <h3 class="sr-title sm-h3 rm-mt rm-mb">
              {{ a['Label'] }}

              <span class="chip chipBeta" *ngIf="a.isActive == false && a.isBeta == true">Beta</span>
            </h3>
            <label class=""
            >{{ a['Description'] }}
              <i>({{ a['moduleCode'] }})</i>
            </label>
          </div>
          <mat-card-actions class="text-center">
            <span class="chip2 chipGood" *ngIf="a.isActive == true && access[a['moduleCode']] != false">Active</span>

            <span class="chip2 chipBad" *ngIf="a.isActive == true && access[a['moduleCode']] == false">Inactive</span>

            <span class="chip2 chipGood" *ngIf="a.isActive == false && access[a['moduleCode']] == true">Active</span>

            <span class="chip2 chipBad" *ngIf="a.isActive == false && access[a['moduleCode']] == false">Inactive</span>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
</form>
