import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { CalendlyComponent } from '../../../shared/components/calendly/calendly.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { BeneficiaryService } from '../../beneficiary/shared/beneficiary.service';
import { ContractService } from '../../contract/shared/contract.service';
import { CustomerService } from '../../customer/shared/customer.service';
import { DentistService } from '../../dentist/shared/dentist.service';
import { ExistingPatientService } from '../../existing-patient/shared/existing-patient.service';
import { InvitationService } from '../../invitation/shared/services/invitation.service';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { OperatorService } from '../../operator/shared/operator.service';
import { ContactUsService } from '../shared/contact-us.service';

@Component({
  selector: 'app-contact-input',
  templateUrl: './contact-input.component.html',
  styleUrls: ['./contact-input.component.css'],
})
export class ContactInputComponent implements OnInit {
  thankLabel = 'Your message has been sent!';
  sendLabel = 'Send message';
  howLabel = 'Please choose who you want your message to come from';
  subjectLabel = 'Subject';
  messageLabel = 'Your Message';

  messageSent = false;
  isModal = false;
  defaultPic =
    'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png';
  profileLink = null;

  targetTreatmentTypeList = [
    'invitation',
    'customer',
    'prospect',
    'existingPerson',
    'merchant',
    'contact',
    'beneficiary',
    'us',
    'operator',
  ];

  target;

  filter = 'sendUS';
  senderType;
  recieverType;

  @Input()
  settings;

  @Input()
  targetType;

  @Input()
  targetID;

  @Input()
  simplePageCode;

  @Input()
  invitationID;

  @Input()
  subject;

  @Input()
  resendingInvitation = false;

  @Input()
  message;

  @Input()
  email;

  @Input()
  firstName;

  @Input()
  lastName;

  @Input()
  contactType = 'profile';

  @Input()
  subjectArray = [];

  @Input()
  asGuest = false;

  @Input()
  asPractice = true;

  @Input()
  asProfile = true;

  @Input()
  showBooking = false;

  asInvitation = false;

  @Output()
  _sendResult = new EventEmitter();

  @Output()
  closeModal = new EventEmitter();

  @Output()
  getEmail = new EventEmitter();

  @Output()
  getMessage = new EventEmitter();

  @Output()
  getSubject = new EventEmitter();

  @Output()
  getFirstName = new EventEmitter();

  @Output()
  getLastName = new EventEmitter();

  @Output()
  getFullName = new EventEmitter();

  @Output()
  getContacType = new EventEmitter();

  @Output()
  getSenderID = new EventEmitter();

  @Output()
  getResult = new EventEmitter();

  @Output()
  getContactType = new EventEmitter();

  brandName = Settings.global['brandName'];

  profile;
  invitation;
  practice;
  multiContactType = false;

  result;

  isPromoterOrAdmin = false;
  sessionType = 'guest';

  constructor(
    private authenticationService: AuthenticationService,
    private dentistService: DentistService,
    private beneficiaryService: BeneficiaryService,
    private merchantService: MerchantService,
    private invitationService: InvitationService,
    private customerService: CustomerService,
    private existingService: ExistingPatientService,
    private operatorService: OperatorService,
    private messageService: ContactUsService,
    private contractService: ContractService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.targetType) {
        this.targetType = data.targetType;
        this.isModal = true;
      } else {
        this.targetType = 'us';
        this.isModal = true;
      }

      if (data.targetID) {
        this.targetID = data.targetID;
        this.isModal = true;
      }

      if (data.subject) {
        this.subject = data.subject;
        this.isModal = true;
      }

      if (data.message) {
        this.message = data.message;
        this.isModal = true;
      }

      if (data.email) {
        this.email = data.email;
        this.isModal = true;
      }

      if (data.firstName) {
        this.firstName = data.firstName;
        this.isModal = true;
      }

      if (data.lastName) {
        this.lastName = data.lastName;
        this.isModal = true;
      }

      if (data.contactType) {
        this.contactType = data.contactType;
        this.isModal = true;
      }

      if (data.subjectArray) {
        this.subjectArray = data.subjectArray;
        this.isModal = true;
      }

      if (data.asGuest !== null) {
        this.asGuest = data.asGuest;
        this.isModal = true;
      }

      if (data.asPractice !== null) {
        this.asPractice = data.asPractice;
        this.isModal = true;
      }
      if (data.invitationID) {
        this.invitationID = data.invitationID;
        this.isModal = true;
      }

      if (data.asProfile !== null) {
        this.asProfile = data.asProfile;
        this.isModal = true;
      }

      if (data.showBooking !== null) {
        this.showBooking = data.showBooking || false;
        this.isModal = true;
      }
    } else {
      this.isModal = false;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      this.authenticationService.isLogin().subscribe((res) => {
        if (res == true) {
          this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
            this.isPromoterOrAdmin = res;

            this.authenticationService.getCurrentUser().subscribe((_profile) => {
              this.authenticationService.getCurrentPractice().subscribe((_practice) => {
                if (_profile && _profile.data) {
                  this.profile = _profile.data;
                  this.asProfile = true;
                } else {
                  this.asProfile = false;
                  this.asGuest = true;
                }

                if (_practice) {
                  this.practice = _practice;
                  this.asPractice = true;
                } else {
                  this.asPractice = false;
                }

                if (this.targetType == 'us') {
                  this.asGuest = true;
                }

                if (!this.invitationID) {
                  this.asInvitation = false;

                  if (this.asPractice == true && this.asProfile == true) {
                    this.contactType = 'profile';
                    this.multiContactType = true;
                  } else if (this.asGuest == true && this.asPractice == false && this.asProfile == false) {
                    this.contactType = 'guest';
                    this.multiContactType = false;
                  } else if (this.asGuest == false && this.asPractice == true && this.asProfile == false) {
                    this.contactType = 'profile';
                    this.multiContactType = false;
                  } else if (this.asGuest == false && this.asPractice == false && this.asProfile == true) {
                    this.contactType = 'practice';
                    this.multiContactType = false;
                  } else if (this.asProfile == true) {
                    this.contactType = 'profile';
                    this.multiContactType = true;
                  } else if (this.asPractice == true) {
                    this.contactType = 'practice';
                    this.multiContactType = true;
                  }
                } else {
                  this.invitationService.getInvitationByWakandaIDorID(this.invitationID).subscribe((_invitation) => {
                    if (_invitation) {
                      this.invitation = _invitation;
                      this.asInvitation = true;
                      this.asGuest = true;
                      this.invitationID = this.invitation['ID'];
                    } else {
                      this.asInvitation = false;
                    }

                    if (this.asPractice == true && this.asProfile == true) {
                      this.contactType = 'profile';
                      this.multiContactType = true;
                    } else if (
                      this.asGuest == true &&
                      this.asInvitation == true &&
                      this.asPractice == false &&
                      this.asProfile == false
                    ) {
                      this.contactType = 'invitation';
                      this.multiContactType = true;
                    }
                    if (
                      this.asGuest == true &&
                      this.asInvitation == false &&
                      this.asPractice == false &&
                      this.asProfile == false
                    ) {
                      this.contactType = 'guest';
                      this.multiContactType = false;
                    } else if (this.asGuest == false && this.asPractice == true && this.asProfile == false) {
                      this.contactType = 'profile';
                      this.multiContactType = false;
                    } else if (this.asGuest == false && this.asPractice == false && this.asProfile == true) {
                      this.contactType = 'practice';
                      this.multiContactType = false;
                    } else if (this.asProfile == true) {
                      this.contactType = 'profile';
                      this.multiContactType = true;
                    } else if (this.asPractice == true) {
                      this.contactType = 'practice';
                      this.multiContactType = true;
                    }
                  });
                }

                this.getTarget();
              });
            });
          });
        } else {
          this.asProfile = false;
          this.asPractice = false;
          this.asGuest = true;

          this.contactType = 'guest';

          if (!this.invitationID) {
            this.asInvitation = false;
          } else {
            this.invitationService.getInvitationByWakandaIDorID(this.invitationID).subscribe((_invitation) => {
              if (_invitation) {
                this.invitation = _invitation;
                this.asInvitation = true;
                this.invitationID = this.invitation['ID'];

                this.contactType = 'invitation';
              } else {
                this.asInvitation = false;
              }
            });
          }

          this.getTarget();
        }
      });
    });
  }

  close() {
    this.closeModal.emit(true);
  }

  getTarget() {
    if (this.targetType == 'invitation') {
      this.invitationService.getInvitationByWakandaIDorID(this.targetID).subscribe((res) => {
        if (res) {
          this.target = {
            id: res['ID'],
            firstName: res['FirstName'],
            lastName: res['LastName'],
            name: res['FirstName'] + ' ' + res['LastName'],
            email: res['Email'],
            mobile: res['Mobile'],
            phoneWork: res['Phone.Work'],
            phoneHome: res['Phone.Home'],
          };
          this.recieverType = 'invitation';
        } else {
          NotifyAppComponent.displayToast('error', 'Error', 'Please specify a reciver ');
        }
      });
    } else if (this.targetType == 'contract' && this.isPromoterOrAdmin == true) {
      this.contractService.fetchContractDetails$(this.targetID, {}, this.isPromoterOrAdmin).subscribe((res) => {
        if (res) {
          this.target = {
            id: res['Customer_key'],
            firstName: res['Customer.FirstName'],
            lastName: res['Customer.LastName'],
            name: res['Customer.FullName'],
            email: res['Customer.Email'],
            mobile: res['Customer.Mobile'],
          };
          this.recieverType = 'guest';

          this.targetID = res['Customer_key'];
        } else {
          NotifyAppComponent.displayToast('error', 'Error', 'Please specify a reciver ');
        }
      });
    } else if (this.targetType == 'invitation-communication') {
      this.invitationService.getOneInvitationCommunication(this.targetID, this.sessionType).subscribe((res) => {
        if (res) {
          this.target = {
            id: res['ID'],
            firstName: res['FirstName'],
            lastName: res['LastName'],
            name: res['FirstName'] + ' ' + res['LastName'],
            email: res['Email'],
            mobile: res['Mobile'],
            phoneWork: res['Phone.Work'],
            phoneHome: res['Phone.Home'],
          };
          this.recieverType = 'invitation';
        } else {
          NotifyAppComponent.displayToast('error', 'Error', 'Please specify a reciver ');
        }
      });
    } else if (this.targetType == 'customer') {
      this.customerService.getCustomerProspectDetails(this.targetID, {}, this.sessionType).subscribe((res) => {
        if (res) {
          this.target = {
            id: res['ID'],
            firstName: res['FirstName'],
            lastName: res['Name'],
            name: res['FirstName'] + ' ' + res['Name'],
            email: res['Email'],
            mobile: res['mobiles.Number'],
            phoneWork: res['Phone.Work.Number'],
            phoneHome: res['phones.Number'],
          };

          this.recieverType = 'guest';
        } else {
          NotifyAppComponent.displayToast('error', 'Error', 'Please specify a reciver ');
        }
      });
    } else if (this.targetType == 'prospect') {
      this.customerService.getCustomerProspectDetails(this.targetID, {}, this.sessionType).subscribe((res) => {
        if (res) {
          this.target = {
            id: res['ID'],
            firstName: res['FirstName'],
            lastName: res['Name'],
            name: res['FirstName'] + ' ' + res['Name'],
            email: res['emails.Email'],
            mobile: res['mobiles.Number'],
            phoneWork: res['Phone.Work.Number'],
            phoneHome: res['phones.Number'],
          };

          this.recieverType = 'guest';
        } else {
          NotifyAppComponent.displayToast('error', 'Error', 'Please specify a reciver ');
        }
      });
    }  else if (this.targetType == 'customerProspect') {
      this.customerService.getCustomerProspectDetails(this.targetID, {}, this.sessionType).subscribe((res) => {
        if (res) {
          this.target = {
            id: res['ID'],
            firstName: res['FirstName'],
            lastName: res['Name'],
            name: res['FirstName'] + ' ' + res['Name'],
            email: res['emails.Email'],
            mobile: res['mobiles.Number'],
            phoneWork: res['Phone.Work.Number'],
            phoneHome: res['phones.Number'],
          };

          this.recieverType = 'guest';
        } else {
          NotifyAppComponent.displayToast('error', 'Error', 'Please specify a reciver ');
        }
      });
    }  else if (this.targetType == 'existingPerson') {
      this.existingService.getExistingPatientDetails(this.targetID, {}, this.sessionType).subscribe((res) => {
        if (res) {
          this.target = {
            id: res['sourceID'],
            firstName: res['firstName'],
            lastName: res['lastName'],
            name: res['firstName'] + ' ' + res['lastName'],
            email: res['email'],
            mobile: res['mobile'],
            phoneWork: res['workPhone'],
            phoneHome: res['homePhone'],
          };

          this.targetID = res['sourceID'];
          this.recieverType = 'guest';
        } else {
          NotifyAppComponent.displayToast('error', 'Error', 'Please specify a reciver ');
        }
      });
    } else if (this.targetType == 'merchant') {
      this.merchantService.getMerchantDetails(this.targetID, {}, this.sessionType).subscribe((res) => {
        if (res) {
          this.target = {
            id: res['ID'],
            name: res['TradingAs'],
            email: res['emails.Email'],
            mobile: res['mobiles.Number'],
            phoneWork: null,
            phoneHome: res['phones.Number'],
          };

          this.profileLink = null;
          this.profileLink = this.merchantService.getPracticePicStreamLink(this.targetID);

          this.recieverType = 'user';
        } else {
          NotifyAppComponent.displayToast('error', 'Error', 'Please specify a reciver ');
        }
      });
    } else if (this.targetType == 'contact') {
      this.dentistService.getContactDetails(this.targetID, {}, this.sessionType).subscribe((res) => {
        if (res) {
          this.target = {
            id: res['ID'],
            name: res['CalculatedName'],
            email: res['emails.Email'],
            mobile: res['mobiles.Number'],
            phoneWork: null,
            phoneHome: res['phones.Number'],
          };

          this.profileLink = null;
          this.profileLink = this.dentistService.getProfilePicStreamLink(this.targetID);

          this.recieverType = 'user';
        } else {
          NotifyAppComponent.displayToast('error', 'Error', 'Please specify a reciver ');
        }
      });
    } else if (this.targetType == 'beneficiary') {
      this.beneficiaryService.getBeneficiaryDetails(this.targetID).subscribe((res) => {
        if (res) {
          this.target = {
            id: res['ID'],
            name: res['CalculatedName'],
            email: res['emails.Email'],
            mobile: res['mobiles.Number'],
            phoneWork: null,
            phoneHome: res['phones.Number'],
          };

          this.profileLink = null;
          this.profileLink = this.beneficiaryService.getProfilePicStreamLink(this.targetID);

          this.recieverType = 'user';
        } else {
          NotifyAppComponent.displayToast('error', 'Error', 'Please specify a reciver ');
        }
      });
    } else if (this.targetType == 'operator') {
      this.operatorService.getfullOperatorDetails(this.targetID).subscribe((res) => {
        if (res) {
          if (res['TableName'] == 'Merchant' || res['TableName'] == 'Promoter') {
            this.target = {
              id: res['ContactforCard_key'],
              name: res['TradingAs'],
              email: res['emails.Email'],
              mobile: res['mobiles.Number'],
              phoneWork: null,
              phoneHome: res['phones.Number'],
            };

            this.targetID = res['ContactforCard_key'];
          } else {
            this.target = {
              id: res['ContactforCard_key'],
              name: res['CalculatedName'],
              email: res['emails.Email'],
              mobile: res['mobiles.Number'],
              phoneWork: null,
              phoneHome: res['phones.Number'],
            };

            this.targetID = res['ContactforCard_key'];
          }

          this.profileLink = null;
          this.profileLink = this.operatorService.getProfilePicStreamLink(this.targetID);

          this.recieverType = 'user';
        } else {
          NotifyAppComponent.displayToast('error', 'Error', 'Please specify a reciver ');
        }
      });
    } else {
      this.recieverType = 'us';
    }
  }

  contactSend() {
    this.result = {
      id: this.targetID || null,
      email: null,
      firstName: null,
      lastName: null,
      fullName: null,
      contactType: null,
      subject: null,
      body: null,
      usePractice: false,
    };

    this.result.contactType = this.contactType;
    this.result.subject = this.subject;
    this.result.body = this.message;

    if (this.contactType == 'guest') {
      this.result.email = this.email;
      this.result.firstName = this.firstName;
      this.result.lastName = this.lastName;
      this.result.fullName = this.firstName + ' ' + this.lastName;
      this.result.usePractice = false;
      this.senderType = 'guest';
    } else if (this.contactType == 'profile') {
      this.result.email = this.profile['emails.Email'];
      this.result.firstName = this.profile['FirstName'];
      this.result.lastName = this.profile['LastName'];
      this.result.fullName = this.profile['CalculatedName'];
      this.result.usePractice = false;
      this.senderType = 'user';
    } else if (this.contactType == 'practice') {
      this.result.email = this.practice['emails.Email'];
      this.result.firstName = this.practice['FirstName'];
      this.result.lastName = this.practice['LastName'];
      this.result.fullName = this.practice['CalculatedName'];
      this.result.usePractice = true;
      this.senderType = 'user';
    } else if (this.contactType == 'invitation') {
      this.result.email = this.invitation['Email'];
      this.result.firstName = this.invitation['FirstName'];
      this.result.lastName = this.invitation['LastName'];
      this.result.fullName = this.invitation['FirstName'] + ' ' + this.invitation['LastName'];
      this.result.usePractice = false;
      this.senderType = 'invitation';
    }

    this.contact();
  }

  emitResult() {
    if (this.result && this.result.email) {
      this.getResult.emit(this.result);

      NotifyAppComponent.displayToast('success', 'Thanks', this.thankLabel);
    } else {
      this.getResult.emit(null);
    }
    this.getEmail.emit(this.result.email);
    this.getSenderID.emit(this.result.id);
    this.getFirstName.emit(this.result.firstName);
    this.getLastName.emit(this.result.lastName);
    this.getFullName.emit(this.result.fullName);
    this.getContactType.emit(this.result.contactType);
    this.getMessage.emit(this.result.message);
    this.getSubject.emit(this.result.subject);
    this.closeModal.emit(true);
  }

  contact() {
    if (this.senderType == 'user') {
      if (this.recieverType == 'user') {
        this.messageService.messageUserUser(this.result.id, this.result).subscribe((res) => {
          if (res) {
            this.messageSent = true;
            this.emitResult();
          }
        });
      } else if (this.recieverType == 'guest') {
        this.messageService.messageUserGuest(this.result).subscribe((res) => {
          if (res) {
            this.messageSent = true;
            this.emitResult();
          }
        });
      } else if (this.recieverType == 'invitation') {
        this.messageService
          .messageUserInvitation(this.result.id, this.result, this.isPromoterOrAdmin)
          .subscribe((res) => {
            if (res) {
              this.messageSent = true;
              this.emitResult();
            }
          });
      } else if (this.recieverType == 'us') {
        this.messageService.messageUserUs(this.result).subscribe((res) => {
          if (res) {
            this.messageSent = true;
            this.emitResult();
          }
        });
      }
    } else if (this.senderType == 'guest') {
      if (this.recieverType == 'user') {
        this.messageService.messageGuestUser(this.result.id, this.result).subscribe((res) => {
          if (res) {
            this.messageSent = true;
            this.emitResult();
          }
        });
      } else if (this.recieverType == 'us') {
        this.messageService.messageGuestUs(this.result).subscribe((res) => {
          if (res) {
            this.messageSent = true;
            this.emitResult();
          }
        });
      }
    } else if (this.senderType == 'invitation') {
      if (this.recieverType == 'user') {
        this.messageService.messageInvitationUser(this.invitationID, this.result).subscribe((res) => {
          if (res) {
            this.messageSent = true;
            this.emitResult();
          } else {
          }
        });
      } else if (this.recieverType == 'us') {
        this.messageService.messageInvitationUs(this.invitationID, this.result).subscribe((res) => {
          if (res) {
            this.messageSent = true;
            this.emitResult();
          }
        });
      }
    }
  }

  booking() {
    const ref = RootAppComponent.dialog.open(CalendlyComponent, {
      width: '650px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }
}
