/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-prospect-profile-tabs.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../customer-profile-tabs/customer-profile-tabs.component.ngfactory";
import * as i3 from "../customer-profile-tabs/customer-profile-tabs.component";
import * as i4 from "../../customer/shared/customer.service";
import * as i5 from "@angular/router";
import * as i6 from "../../../core/authentication/shared/authentication.service";
import * as i7 from "../../../shared/services/lookup.service";
import * as i8 from "../../../shared/services/utils.service";
import * as i9 from "../../medical-history/shared/medical-history.service";
import * as i10 from "../../dentist/shared/dentist.service";
import * as i11 from "../../contact/shared/contact.service";
import * as i12 from "../../customer-prospect/shared/customerProspect.service";
import * as i13 from "../../invitation/shared/services/invitation.service";
import * as i14 from "../../tags/shared/tags.service";
import * as i15 from "../prospect-profile-tabs/prospect-profile-tabs.component.ngfactory";
import * as i16 from "../prospect-profile-tabs/prospect-profile-tabs.component";
import * as i17 from "../../existing-patient/shared/existing-patient.service";
import * as i18 from "@angular/common";
import * as i19 from "./customer-prospect-profile-tabs.component";
var styles_CustomerProspectProfileTabsComponent = [i0.styles];
var RenderType_CustomerProspectProfileTabsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerProspectProfileTabsComponent, data: {} });
export { RenderType_CustomerProspectProfileTabsComponent as RenderType_CustomerProspectProfileTabsComponent };
function View_CustomerProspectProfileTabsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-profile-tabs", [], null, [[null, "goBack"], [null, "merchantID"], [null, "birthday"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("goBack" === en)) {
        var pd_0 = (_co.goBackEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("merchantID" === en)) {
        var pd_1 = (_co.getMerchantID($event) !== false);
        ad = (pd_1 && ad);
    } if (("birthday" === en)) {
        var pd_2 = (_co.getBirthday($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_CustomerProfileTabsComponent_0, i2.RenderType_CustomerProfileTabsComponent)), i1.ɵdid(1, 114688, null, 0, i3.CustomerProfileTabsComponent, [i4.CustomerService, i5.Router, i5.ActivatedRoute, i6.AuthenticationService, i7.LookupService, i8.UtilsService, i9.MedicalHistoryService, i10.DentistService, i11.ContactService, i12.CustomerProspectService, i13.InvitationService, i14.TagsService], { patientID: [0, "patientID"], isIntegrated: [1, "isIntegrated"], displayTabs: [2, "displayTabs"], tabs: [3, "tabs"], itemID: [4, "itemID"], selectedMerchant: [5, "selectedMerchant"], settings: [6, "settings"] }, { goBack: "goBack", merchantID: "merchantID", birthday: "birthday" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customerID; var currVal_1 = _co.isIntegrated; var currVal_2 = _co.displayTabs; var currVal_3 = _co.tabs; var currVal_4 = _co.itemID; var currVal_5 = _co.selectedMerchant; var currVal_6 = _co.settings; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_CustomerProspectProfileTabsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-prospect-profile-tabs", [], null, [[null, "goBack"], [null, "merchantID"], [null, "birthday"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("goBack" === en)) {
        var pd_0 = (_co.goBackEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("merchantID" === en)) {
        var pd_1 = (_co.getMerchantID($event) !== false);
        ad = (pd_1 && ad);
    } if (("birthday" === en)) {
        var pd_2 = (_co.getBirthday($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i15.View_ProspectProfileTabsComponent_0, i15.RenderType_ProspectProfileTabsComponent)), i1.ɵdid(1, 114688, null, 0, i16.ProspectProfileTabsComponent, [i17.ExistingPatientService, i5.ActivatedRoute, i6.AuthenticationService, i7.LookupService, i8.UtilsService, i5.Router, i9.MedicalHistoryService, i10.DentistService, i11.ContactService, i12.CustomerProspectService, i14.TagsService], { patientID: [0, "patientID"], isIntegrated: [1, "isIntegrated"], displayTabs: [2, "displayTabs"], tabs: [3, "tabs"], itemID: [4, "itemID"], settings: [5, "settings"], selectedMerchant: [6, "selectedMerchant"] }, { goBack: "goBack", merchantID: "merchantID", birthday: "birthday" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.prospectID; var currVal_1 = _co.isIntegrated; var currVal_2 = _co.displayTabs; var currVal_3 = _co.tabs; var currVal_4 = _co.itemID; var currVal_5 = _co.settings; var currVal_6 = _co.selectedMerchant; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_CustomerProspectProfileTabsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerProspectProfileTabsComponent_1)), i1.ɵdid(1, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerProspectProfileTabsComponent_2)), i1.ɵdid(3, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customerID; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.prospectID; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_CustomerProspectProfileTabsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-prospect-profile-tabs", [], null, null, null, View_CustomerProspectProfileTabsComponent_0, RenderType_CustomerProspectProfileTabsComponent)), i1.ɵdid(1, 638976, null, 0, i19.CustomerProspectProfileTabsComponent, [i6.AuthenticationService, i12.CustomerProspectService, i5.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerProspectProfileTabsComponentNgFactory = i1.ɵccf("app-customer-prospect-profile-tabs", i19.CustomerProspectProfileTabsComponent, View_CustomerProspectProfileTabsComponent_Host_0, { patientID: "patientID", tabs: "tabs", itemID: "itemID", appointmentID: "appointmentID", selectedMerchant: "selectedMerchant", isIntegrated: "isIntegrated", displayTabs: "displayTabs", settings: "settings" }, { merchantID: "merchantID", birthday: "birthday", goBack: "goBack" }, []);
export { CustomerProspectProfileTabsComponentNgFactory as CustomerProspectProfileTabsComponentNgFactory };
