import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'admin-logs',
  templateUrl: './admin-logs.component.html',
  styleUrls: ['./admin-logs.component.css'],
})
export class AdminLogsComponent implements OnInit {
  public constructor() {}

  public ngOnInit(): void {}
}
