<mat-card class="google-review-card">
  <mat-card-header>
    <img *ngIf="googleReview.reviewer.profilePhotoUrl" [src]="googleReview.reviewer.profilePhotoUrl" mat-card-avatar/>

    <mat-card-title>{{ googleReview.reviewer.displayName }}</mat-card-title>
    <mat-card-subtitle>
      {{ googleReview.updateTime | date: 'short' }}
    </mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <app-rating [rating]="googleReview.starRating"></app-rating>

    <div style="margin-top: 20px; padding-left: 4px; min-height: 225px; padding-bottom: 50px">
      <read-more [text]="googleReview.comment" [maxLength]="300"></read-more>
    </div>
  </mat-card-content>

  <mat-card-actions style="position: absolute; bottom: 12px; right: 23px">
    <a *ngIf="!googleReview.reviewReply" (click)="openReviewReplyModal(googleReview)" mat-button color="primary"
    >REPLY</a
    >
    <a *ngIf="googleReview.reviewReply" (click)="openReviewReplyModal(googleReview)" mat-button color="primary"
    >VIEW REPLY</a
    >
    <a
      mat-button
      color="accent"
      target="_blank"
      href="https://support.google.com/business/answer/4596773?co=GENIE.Platform%3DAndroid&hl=en"
    >RETRACT</a
    >
  </mat-card-actions>
</mat-card>
