<div class="row clearfix flex">
  <div class="icons-col">
    <mat-icon>phone</mat-icon>
  </div>
  <div class="row-content-col">
    <div class="row clearfix flex">
      <mat-form-field class="half-width" appearance="outline">
        <mat-label>Type</mat-label>
        <mat-select
          [(ngModel)]="phoneType"
          [disabled]="isDisabled"
          [ngClass]="{ removeArrow: isDisabled == true }"
          (selectionChange)="getPhoneByType(phoneType)"
          placeholder="Type"
          name="phoneType"
        >
          <mat-option *ngFor="let type of phoneTypes" [value]="type">
            <span *ngIf="convertToLowerCase(type) != convertToLowerCase(targetPhoneType)">{{ type | titlecase }}</span>
            <span *ngIf="convertToLowerCase(type) == convertToLowerCase(targetPhoneType)"
            >{{ type | titlecase }} - Primary</span
            >
          </mat-option>
          <!-- <hr>
          <mat-option (click)="createPhoneLabel()">
            Create new label
          </mat-option> -->
        </mat-select>
      </mat-form-field>
      <app-phone-input
        class="full-width"
        [isLandLine]="true"
        [label]="'Phone Number'"
        [required]="true"
        [phoneNumber]="myPhoneNumber"
        [isDisabled]="true"
      >
      </app-phone-input>

      <div class="clearfix" *ngIf="isDisabled == false">
        <!-- <button mat-icon-button matTooltip="Delete Phone" (click)="removePhoneByLabel(phoneType)" *ngIf="phoneType != targetPhoneType">
          <mat-icon>delete</mat-icon>
        </button> -->

        <button (click)="editPhoneByLabel(phoneType)" mat-icon-button matTooltip="Update Phone">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
