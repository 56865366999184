import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductInvoice } from '../../../shared/services/product-invoice.service';

export interface InvoiceFormModalConfig {
  industryType: string;
  formMode?: 'create' | 'update';
  productInvoice?: ProductInvoice;
}

@Component({
  selector: 'ipv-product-invoice-form.modal.ts',
  templateUrl: './product-invoice-form.modal.ts.component.html',
  styleUrls: ['./product-invoice-form.modal.ts.component.css'],
})
export class InvoiceProductFormModalComponent {
  title = 'New Invoice';
  formMode: 'create' | 'update' = 'create';
  industryType = 'dental';
  productInvoice: ProductInvoice = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: InvoiceFormModalConfig) {
    if (data) {
      this.formMode = data.formMode || 'create';
      this.title = data.formMode === 'update' ? 'Update Invoice' : this.title;
      this.industryType = data.industryType;
      this.productInvoice = data.productInvoice || null;

      if (this.formMode === 'update') {
        this.title = 'Update Invoice';
      }
    }
  }
}
