<div class="clearfix" *ngIf="isAdminOrPromoter == true">
  <!--  <mat-card class="row clearfix selectMerchant card inner">
    <div class="colour-stripe"></div>
    <div class="row clearfix">
      <h4 class="sm-h4 rm-mt rm-mb primary-color">
        Promoter Data filter</h4>
      <p class="small">Refine your dashboard view using the filters below</p>
      <hr>
    </div>
 -->
  <app-operator-filter
    id="part1"
    [sendNull]="true"
    [selectDateRange]="selectedRange"
    [displayMerchant]="isDisplayMerchant"
    [displayStatus]="false"
    [displayDateFrom]="true"
    [displayDateTo]="true"
    [displayProduct]="false"
    [displayDentist]="false"
    [setAutoSelectMerchant]="true"
    [selectedMerchant]="merchantID"
    (getMerchant)="getMerchant($event)"
    (getDateTo)="getDateTo($event)"
    (getDateFrom)="getDateFrom($event)"
    (search)="applySearch($event)"
  ></app-operator-filter>

  <!-- </mat-card> -->

  <div class="row clearfix">
    <app-invitation-list-draft [dateFrom]="dateFrom" [dateTo]="dateTo" [merchantID]="merchantID">
    </app-invitation-list-draft>
  </div>
  <div class="row clearfix">
    <div class="pleaseChoose" *ngIf="merchantID == 'none'" @ngIfAnimation>
      <p>
        <strong>No Merchant Selected</strong>
      </p>
      <img class="character_warning" />
      <p>Choose Desired Merchant</p>
    </div>
  </div>
</div>

<div class="clearfix" *ngIf="isAdminOrPromoter == false">
  <app-operator-filter
    [sendNull]="true"
    [selectDateRange]="selectedRange"
    [displayMerchant]="false"
    [displayStatus]="false"
    [displayDateFrom]="true"
    [displayDateTo]="true"
    [displayProduct]="false"
    [displayDentist]="false"
    (getMerchant)="getMerchant($event)"
    (getDateTo)="getDateTo($event)"
    (getDateFrom)="getDateFrom($event)"
    (search)="applySearch($event)"
  ></app-operator-filter>

  <app-invitation-list-draft [merchantID]="null" [dateFrom]="dateFrom" [dateTo]="dateTo"></app-invitation-list-draft>
</div>
