<div class="container">
  <div class="rel clearfix lookup">
    <form class="full-width text-center" #createForm="ngForm">
      <mat-card class="card clearfix">
        <div class="card-header primary-gradient-img clearfix inModal">
          <div class="row clearfix">
            <div class="row titleArea clearfix">
              <mat-icon class="pull-left">format_color_fill</mat-icon>
              <h2 class="summary-header rm-m white" *ngIf="themeID == null">New Theme</h2>
              <h2 class="summary-header rm-m white" *ngIf="themeID && theme && theme.name">
                Edit Theme : {{ theme.name }}
              </h2>
            </div>
          </div>
        </div>
        <div class="row clearfix">
          <div class="full-width flex mb">
            <mat-form-field class="full-width mr" appearance="outline">
              <mat-label>Theme Name</mat-label>
              <input
                [(ngModel)]="theme.name"
                (change)="changeThemeName()"
                name="name"
                matInput
                placeholder="Theme Name"
                required
              />
            </mat-form-field>
            <mat-form-field class="full-width ml" appearance="outline">
              <mat-label>Theme code ( no space)</mat-label>
              <input [(ngModel)]="theme.code" name="code" matInput placeholder="Theme code ( no space) " required/>
            </mat-form-field>
          </div>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Enter Theme Description</mat-label>
            <textarea
              [(ngModel)]="description"
              name="description"
              matInput
              placeholder="Enter Theme Description"
            ></textarea>
          </mat-form-field>
        </div>
        <div class="row clearfix mb">
          <mat-slide-toggle
            class="text-center mt mr ml mb"
            *ngIf="themeID"
            [(ngModel)]="overrideFile"
            name="overrideFile"
          >
            Modify SCSS/CSS core file
          </mat-slide-toggle>

          <div class="full-width" *ngIf="overrideFile == true || themeID == null">
            <div class="row clearfix">
              <h3 class="sr-title">Insert a file</h3>
              <mat-radio-group
                class="alternate-theme"
                [(ngModel)]="scssFileType"
                (change)="changeType($event)"
                name="scssFile"
              >
                <mat-radio-button [value]="'default'"> Use Default </mat-radio-button>
                <mat-radio-button [value]="'newSCSS'"> Upload SCSS </mat-radio-button>
                <mat-radio-button [value]="'newCSS'"> Upload CSS </mat-radio-button>
                <mat-radio-button *ngIf="themes.length > 0" [value]="'existed'"> Import from a Theme</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="full-width row clearfix" *ngIf="scssFileType == 'newSCSS'">
              <app-file-uploader
                class="clearfix"
                class="text-center"
                [title]="'Click to upload a file'"
                [limit]="1"
                [uploadDirectly]="true"
                [isDragable]="true"
                (onCompleteAll)="onCompleteAll($event)"
                (isUploaded)="isUploaded($event)"
                name="uploadFile"
              ></app-file-uploader>
            </div>
            <div class="full-width row clearfix" *ngIf="scssFileType == 'newCSS'">
              <app-file-uploader
                class="clearfix"
                class="text-center"
                [title]="'Click to upload a file'"
                [limit]="1"
                [uploadDirectly]="true"
                [isDragable]="true"
                (onCompleteAll)="onCompleteAll($event)"
                (isUploaded)="isUploaded($event)"
                name="uploadFile"
              >
              </app-file-uploader>
            </div>
            <div class="clearfix" *ngIf="scssFileType == 'existed' && themes.length > 0">
              <mat-select
                class="full-width"
                [(ngModel)]="selectedTheme"
                (ngModelChange)="selectTheme()"
                placeholder="Select Theme"
                name="theme"
                required
              >
                <mat-option *ngFor="let theme of themes" [value]="theme">
                  {{ theme.name }}
                </mat-option>
              </mat-select>
              <app-file-list class="text-center centered" [files]="themeScssFile" [canEdit]="false" [canAction]="false">
              </app-file-list>
            </div>
            <div class="clearfix" *ngIf="scssFileType == 'default' && themeScssFile.length > 0">
              <app-file-list class="text-center centered" [files]="themeScssFile" [canEdit]="false" [canAction]="false">
              </app-file-list>
            </div>

            <div class="clearfix" *ngIf="(scssFileType == 'newCSS' || scssFileType == 'newSCSS') && fileID">
              <app-file-list class="text-center centered" [files]="[fileID]" [canEdit]="false" [canAction]="false">
              </app-file-list>
            </div>
          </div>
        </div>

        <mat-tab-group class="row clearfix" dynamicHeight="true">
          <mat-tab label="Main Images*">
            <div class="full-width clearfix">
              <!-- Logo upload -->
              <div class="thrd-width">
                <h3 class="sr-title">Site Logo</h3>
                <div class="full-width text-center mb clearfix row">
                  <app-file-uploader
                    class="clearfix text-center"
                    [title]="'Click to upload a file'"
                    [allowedExtensionTypes]="allowedExtensionTypes"
                    [uploadDirectly]="true"
                    [isDragable]="true"
                    [limit]="1"
                    (onCompleteAll)="onCompletebranding($event, 'logo')"
                  ></app-file-uploader>
                </div>
                <div class="full-width text-center clearfix row" *ngIf="!branding.logo">
                  <div class="imageContainer">
                    <img class="imageDefault" [src]="getImageById('BrandingLogoImage')"/>
                  </div>
                </div>

                <div class="full-width mb text-center clearfix row" *ngIf="branding.logo">
                  <div class="imageContainer">
                    <img class="image" [src]="getFileLink(branding.logo)"/>
                  </div>
                </div>
                <p>This is your company's logo, in PNG format.</p>
              </div>
              <!-- Merchant Site Background -->
              <div class="thrd-width">
                <h3 class="sr-title">Merchant Background image</h3>
                <div class="full-width text-center mb clearfix row">
                  <app-file-uploader
                    class="clearfix text-center"
                    [title]="'Click to upload a file'"
                    [allowedExtensionTypes]="allowedExtensionTypes"
                    [uploadDirectly]="true"
                    [isDragable]="true"
                    [limit]="1"
                    (onCompleteAll)="onCompletebranding($event, 'background')"
                  ></app-file-uploader>
                </div>
                <div class="full-width mb text-center clearfix row" *ngIf="!branding.background">
                  <div class="imageContainer">
                    <img class="imageDefault" [src]="getImageById('BrandingBackgroundImageMerchant')"/>
                  </div>
                </div>

                <div class="full-width mb text-center clearfix row" *ngIf="branding.background">
                  <div class="imageContainer">
                    <img class="image" [src]="getFileLink(branding.background)"/>
                  </div>
                </div>
                <p>
                  This is your website's background image. It needs to be a JPG file no more than 4mb in size, and
                  minimum 1920x1080px.
                </p>
              </div>
              <!-- Site Favicon -->
              <div class="thrd-width">
                <h3 class="sr-title">Site Favicon</h3>
                <div class="full-width text-center mb clearfix row">
                  <app-file-uploader
                    class="clearfix clearfix text-center"
                    [title]="'Click to upload a file'"
                    [allowedExtensionTypes]="allowedExtensionTypes"
                    [uploadDirectly]="true"
                    [isDragable]="true"
                    [limit]="1"
                    (onCompleteAll)="onCompletebranding($event, 'icon')"
                  ></app-file-uploader>
                </div>
                <div class="full-width mb text-center clearfix row" *ngIf="!branding.icon">
                  <div class="imageContainer">
                    <img class="imageDefault" [src]="currentIconUrl"/>
                  </div>
                </div>

                <div class="full-width mb text-center clearfix row" *ngIf="branding.icon">
                  <div class="imageContainer">
                    <img class="image" [src]="getFileLink(branding.icon)"/>
                  </div>
                </div>
                <p>This is the small icon that appears next to your website's URL in the browser window.</p>
              </div>
            </div>

            <div class="full-width clearfix flex">
              <div class="full-width">
                <h3 class="sr-title">Consumer Background image</h3>
                <div class="full-width text-center mb clearfix row">
                  <app-file-uploader
                    class="clearfix clearfix text-center"
                    [title]="'Click to upload a file'"
                    [allowedExtensionTypes]="allowedExtensionTypes"
                    [uploadDirectly]="true"
                    [isDragable]="true"
                    [limit]="1"
                    (onCompleteAll)="onCompletebranding($event, 'backgroundConsumer')"
                  ></app-file-uploader>
                </div>
                <div class="full-width mb text-center clearfix row" *ngIf="!branding.backgroundConsumer">
                  <div class="imageContainer">
                    <img class="imageDefault" [src]="getImageById('BrandingBackgroundImageConsumer')"/>
                  </div>
                </div>

                <div class="full-width mb text-center clearfix row" *ngIf="branding.backgroundConsumer">
                  <div class="imageContainer">
                    <img class="image" [src]="getFileLink(branding.backgroundConsumer)"/>
                  </div>
                </div>
                <p>
                  This is your website's background image. It needs to be a JPG file no more than 4mb in size, and
                  minimum 1920x1080px.
                </p>
              </div>

              <div class="full-width">
                <h3 class="sr-title">Promoter Background image</h3>
                <div class="full-width text-center mb clearfix row">
                  <app-file-uploader
                    class="clearfix clearfix text-center"
                    [title]="'Click to upload a file'"
                    [allowedExtensionTypes]="allowedExtensionTypes"
                    [uploadDirectly]="true"
                    [isDragable]="true"
                    [limit]="1"
                    (onCompleteAll)="onCompletebranding($event, 'backgroundPromoter')"
                  ></app-file-uploader>
                </div>
                <div class="full-width mb text-center clearfix row" *ngIf="!branding.backgroundPromoter">
                  <div class="imageContainer">
                    <img class="imageDefault" [src]="getImageById('BrandingBackgroundImagePromoter')"/>
                  </div>
                </div>

                <div class="full-width mb text-center clearfix row" *ngIf="branding.backgroundPromoter">
                  <div class="imageContainer">
                    <img class="image" [src]="getFileLink(branding.backgroundPromoter)"/>
                  </div>
                </div>
                <p>
                  This is your website's background image. It needs to be a JPG file no more than 4mb in size, and
                  minimum 1920x1080px.
                </p>
              </div>

              <div class="full-width">
                <h3 class="sr-title">Funder Background image</h3>
                <div class="full-width text-center mb clearfix row">
                  <app-file-uploader
                    class="clearfix text-center"
                    [title]="'Click to upload a file'"
                    [allowedExtensionTypes]="allowedExtensionTypes"
                    [uploadDirectly]="true"
                    [isDragable]="true"
                    [limit]="1"
                    (onCompleteAll)="onCompletebranding($event, 'backgroundFunder')"
                  ></app-file-uploader>
                </div>
                <div class="full-width mb text-center clearfix row" *ngIf="!branding.backgroundFunder">
                  <div class="imageContainer">
                    <img class="imageDefault" [src]="getImageById('BrandingBackgroundImageFunder')"/>
                  </div>
                </div>

                <div class="full-width mb text-center clearfix row" *ngIf="branding.backgroundFunder">
                  <div class="imageContainer">
                    <img class="image" [src]="getFileLink(branding.backgroundFunder)"/>
                  </div>
                </div>
                <p>
                  This is your website's background image. It needs to be a JPG file no more than 4mb in size, and
                  minimum 1920x1080px.
                </p>
              </div>

              <div class="full-width">
                <h3 class="sr-title">Supplier Background image</h3>
                <div class="full-width text-center mb clearfix row">
                  <app-file-uploader
                    class="clearfix text-center"
                    [title]="'Click to upload a file'"
                    [allowedExtensionTypes]="allowedExtensionTypes"
                    [uploadDirectly]="true"
                    [isDragable]="true"
                    [limit]="1"
                    (onCompleteAll)="onCompletebranding($event, 'backgroundSupplier')"
                  ></app-file-uploader>
                </div>
                <div class="full-width mb text-center clearfix row" *ngIf="!branding.backgroundSupplier">
                  <div class="imageContainer">
                    <img class="imageDefault" [src]="getImageById('BrandingBackgroundImageSupplier')"/>
                  </div>
                </div>

                <div class="full-width mb text-center clearfix row" *ngIf="branding.backgroundSupplier">
                  <div class="imageContainer">
                    <img class="image" [src]="getFileLink(branding.backgroundSupplier)"/>
                  </div>
                </div>
                <p>
                  This is your website's background image. It needs to be a JPG file no more than 4mb in size, and
                  minimum 1920x1080px.
                </p>
              </div>
            </div>
          </mat-tab>
          <mat-tab
            *ngIf="
              scssFileType == 'default' || scssFileType == 'newSCSS' || (scssFileType == 'existed' && selectedTheme)
            "
            label="Color Variables* "
          >
            <div class="full-width" *ngIf="scssFileType == 'newSCSS' || (scssFileType == 'existed' && selectedTheme)">
              <h3 class="sr-title">Add New Color Variable</h3>
              <p>
                Define your SCSS variables in this syntax: <strong> <%= variableName %> </strong> (Javascript lodash
                template syntax) Example: <strong> $md-pioneerorange: <%= orange %> </strong>.
              </p>
              <div class="full-width row clearfix">
                <div class="thrd-width">
                  <div class="full-width">
                    <mat-form-field class="two-thrd-width">
                      <mat-label>Variable Name</mat-label>
                      <input
                        class="text-center"
                        [(ngModel)]="newVariable.name"
                        name="nameNewVariable"
                        matInput
                        placeholder="Variable Name"
                      />
                    </mat-form-field>
                    <mat-checkbox
                      class="pull-left"
                      [(ngModel)]="newVariable.isPalette"
                      name="displayPalette"
                      color="primary"
                    >
                      Use Color Pallete
                    </mat-checkbox>
                  </div>
                  <app-color-palette
                    class="full-width clearfix row"
                    [isPalette]="newVariable.isPalette"
                    [coreColor]="newVariable.color"
                    [displayPalette]="displayPalette"
                    (getCoreColor)="getNewCoreColor($event)"
                    (getSCSSPalette)="getNewColorPalette($event)"
                  ></app-color-palette>

                  <button
                    class="btn btn-primary pull-right mr mt"
                    [disabled]="!newVariable.color || !newVariable.name"
                    (click)="addNewVariable()"
                    mat-raised-button
                    type="submit"
                    color="primary"
                  >
                    <mat-icon>add</mat-icon>Add Color
                  </button>
                </div>
                <div class="two-thrd-width">
                  <div class="well clearfix extra-mb" *ngIf="colorVariables.length > 0">
                    <h3 class="sr-title">Variable List</h3>
                    <p>
                      You can Modify the core color , or the palette colors (you can modify each color of the palette)
                    </p>
                    <div class="row full-width" *ngFor="let c of colorVariables; let index = index">
                      <app-color-palette
                        class="half-width"
                        [isPalette]="c['isPalette']"
                        [coreColor]="c['color']"
                        (getCoreColor)="editCoreColor($event, index)"
                        (getSCSSPalette)="editColorPalette($event, index)"
                      >
                      </app-color-palette>
                      <div class="half-width">
                        <mat-form-field class="two-qtr-width">
                          <mat-label>Variable Name</mat-label>
                          <input
                            class="text-center"
                            [(ngModel)]="c['name']"
                            name="{{ 'color' + index }}"
                            matInput
                            placeholder="Variable Name"
                            required
                          />
                        </mat-form-field>
                        <mat-checkbox
                          class="qtr-width"
                          [(ngModel)]="c['isPalette']"
                          name="displayPalette"
                          color="primary"
                        >
                          Edit Pallete
                        </mat-checkbox>

                        <button class="pull-right" (click)="removeColor(index)" mat-mini-fab color="accent">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="full-width" *ngIf="scssFileType == 'default'">
              <h3 class="sr-title">Default Variable List</h3>

              <p>You can Modify the core color , or the palette colors (you can modify each color of the palette)</p>
              <div class="full-width row text-center clearfix">
                <div class="well clearfix text-center extra-pb" *ngIf="colorVariables.length > 0">
                  <div class="row full-width" *ngFor="let c of colorVariables; let index = index">
                    <app-color-palette
                      class="two-thrd-width"
                      [isPalette]="c['isPalette']"
                      [coreColor]="c['color']"
                      (getCoreColor)="editCoreColor($event, index)"
                      (getSCSSPalette)="editColorPalette($event, index)"
                    >
                    </app-color-palette>
                    <div class="thrd-width">
                      <mat-form-field class="half-width" appearance="outline">
                        <mat-label>Variable Name</mat-label>
                        <input
                          class="text-center"
                          [(ngModel)]="c['name']"
                          name="{{ 'color' + index }}"
                          matInput
                          placeholder="Variable Name"
                          required
                        />
                      </mat-form-field>
                      <mat-checkbox
                        class="half-width"
                        [(ngModel)]="c['isPalette']"
                        name="displayPalette"
                        color="primary"
                      >
                        Edit Pallete
                      </mat-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="Customized Images (Optional) ">
            <div class="full-width">
              <!-- Logo upload -->
              <div class="thrd-width" *ngFor="let c of characterArray; let index = index">
                <h3 class="sr-title">{{ c['title'] }}</h3>

                <div class="full-width mb text-center clearfix row">
                  <app-file-uploader
                    class="clearfix text-center"
                    [title]="'Click to upload a file'"
                    [allowedExtensionTypes]="allowedExtensionTypes"
                    [uploadDirectly]="true"
                    [isDragable]="true"
                    [limit]="1"
                    (onCompleteAll)="onCompleteCharacter($event, index)"
                  ></app-file-uploader>
                </div>

                <div class="full-width text-center clearfix row" *ngIf="!c.fileId">
                  <div class="imageContainer">
                    <img class="imageDefault" [src]="getImageById(c['id'])"/>
                  </div>
                </div>
                <div class="full-width text-center clearfix row" *ngIf="c.fileId">
                  <div class="imageContainer">
                    <img class="image" [src]="getFileLink(c.fileId)"/>
                  </div>
                </div>
                <p>
                  <i>class={{ c['selector'] }}</i>
                </p>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="Chart Color (Optional) ">
            <div class="full-width">
              <h3 class="sr-title">Customize your charts</h3>
              <p>
                Define your charts colors , it is recommended to insert at least 10 diffrent color that match your
                theme.
              </p>
              <p>You can Add/Modify the color list , click on <strong>CHECK RESULT</strong> to check the result .</p>

              <div class="qtr-width">
                <div class="column">
                  <h3 class="sr-title">Color List</h3>

                  <div class="row full-width flex">
                    <app-color-palette
                      class="full-width clearfix row"
                      [isPalette]="false"
                      [coreColor]="newChartVariable"
                      [displayPalette]="false"
                      (getCoreColor)="getNewChartsCoreColor($event)"
                    >
                    </app-color-palette>

                    <button class="pull-right" (click)="addNewChartVariable()" mat-mini-fab color="accent">
                      <mat-icon>add</mat-icon>
                    </button>
                  </div>

                  <div class="well full-width clearfix" *ngIf="tmpColorChartsVariables.length > 0">
                    <div class="row full-width">
                      <div class="row full-width flex" *ngFor="let c of tmpColorChartsVariables; let index = index">
                        <app-color-palette
                          [isPalette]="false"
                          [coreColor]="c"
                          (getCoreColor)="editChartCoreColor($event, index)"
                        ></app-color-palette>

                        <button
                          class="btn-close btn-clear mat-button"
                          (click)="removeCharColor(index)"
                          matTooltip="remove"
                        >
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>

                      <div class="clearfix full-width text-center" *ngIf="tmpColorChartsVariables.length > 0">
                        <button class="btn-large" (click)="ApplyChartColors()" mat-raised-button color="primary">
                          <mat-icon>bar_chart</mat-icon>Check Result
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="thr-qtr-width">
                <div class="row clearfix full-width">
                  <div class="half-width">
                    <div class="column">
                      <app-chart-input
                        class="row well"
                        [showGridLines]="showGridLines"
                        [chartHeight]="300"
                        [data]="simpleData"
                        [colorScheme]="colorChartsVariables"
                        [chartType]="'pie'"
                        [title]="'Pie chart'"
                      ></app-chart-input>

                      <app-chart-input
                        class="row well"
                        [showGridLines]="showGridLines"
                        [chartHeight]="300"
                        [data]="simpleData2"
                        [colorScheme]="colorChartsVariables"
                        [chartType]="'pieGrid'"
                        [title]="'Grid Pie chart'"
                      ></app-chart-input>
                      <!--
                    <app-chart-input class="row well " [chartHeight]="300" [data]="simpleData" [colorScheme]="colorChartsVariables" [chartType]="'pieAdvanced'"
                      [title]="'Advanced Pie chart'"></app-chart-input> -->
                    </div>
                  </div>
                  <div class="half-width">
                    <div class="column">
                      <app-chart-input
                        class="row well"
                        [showGridLines]="showGridLines"
                        [chartHeight]="300"
                        [data]="AdvancedData"
                        [colorScheme]="colorChartsVariables"
                        [chartType]="'line'"
                        [title]="'Line chart'"
                      ></app-chart-input>

                      <!--
                    <app-chart-input class="row  well " [chartHeight]="300" [data]="AdvancedData" [colorScheme]="colorChartsVariables"
                      [chartType]="'barVerticalNormalized'" [title]="'Vertical normalized bar chart'"></app-chart-input> -->

                      <app-chart-input
                        class="row well"
                        [showGridLines]="showGridLines"
                        [chartHeight]="300"
                        [data]="simpleData"
                        [colorScheme]="colorChartsVariables"
                        [chartType]="'barHorizontal'"
                        [title]="'Horizontal bar chart'"
                      ></app-chart-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>

        <hr class="mb"/>

        <div class="full-width text-center step-button-row" *ngIf="themeID == null">
          <button
            class="btn-large"
            [disabled]="
              ((scssFileType == 'newSCSS' || scssFileType == 'newCSS') && !fileID) || !theme.name || !theme.code
            "
            (click)="addTheme()"
            mat-raised-button
            color="accent"
          >
            Submit
          </button>
        </div>

        <div class="full-width text-center step-button-row" *ngIf="themeID">
          <button
            class="btn-large"
            [disabled]="
              ((scssFileType == 'newSCSS' || scssFileType == 'newCSS') && !fileID) || !theme.name || !theme.code
            "
            (click)="addExistedTheme()"
            mat-raised-button
            color="accent"
          >
            Submit
          </button>
        </div>
      </mat-card>
    </form>
  </div>
</div>
