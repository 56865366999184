<div class="rel text-center">
  <mat-card class="card">
    <div class="row clearfix">
      <div class="pull-right">
        <!-- Modal dismiss button -->
        <button class="btn-close btn-clear mat-button" (click)="doNotShow()" matDialogClose>
          <mat-icon>clear</mat-icon>
        </button>
      </div>
      <!-- <div class="pull-left">
        <h3 class="sr-title rm-mt rm-mb">
         Welcome to the Dashboard!
        </h3>
      </div> -->
    </div>
    <hr/>
    <div class="colour-stripe"></div>

    <p>
      <img class="animated zoomIn feature-img character_secrect"/>
    </p>

    <p class="lead">This is a list of all your {{ "KEYWORD.patient" | translate }}s..</p>
    <p>
      Here you can view or edit all the personal, contact,{{ "KEYWORD.treatment" | translate }} and billing information
      for your
      {{ "KEYWORD.patient" | translate }}s.
    </p>
    <mat-checkbox [(ngModel)]="showOnPatientListFirstVisit">Please don't show this message again</mat-checkbox>
  </mat-card>
  <button class="btn-large" (click)="doNotShow()" mat-raised-button color="accent" matDialogClose>
    Got It
  </button>
</div>
