import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { TasksService } from '../shared/tasks.service';

@Component({
  selector: 'app-tasks-create',
  templateUrl: './tasks-create.component.html',
  styleUrls: ['./tasks-create.component.css'],
})
export class TasksCreateComponent implements OnInit {
  @Input()
  assignedTaskID;

  name;
  description;
  type;
  action;

  @Output()
  getResult = new EventEmitter();

  @Output()
  close = new EventEmitter();

  isModal = false;
  isEdit = false;

  constructor(
    private tasksService: TasksService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.assignedTaskID) {
        this.assignedTaskID = data.assignedTaskID;
        this.isModal = true;
      }

      if (data.isModal) {
        this.isModal = data.isModal;
      }
    }
  }

  ngOnInit() {
    if (this.assignedTaskID) {
      this.tasksService.getAssignedTaskDetails(this.assignedTaskID).subscribe((res) => {
        if (res) {
          this.isEdit = true;
          this.name = res['Task.Name'];
          this.description = res['Task.Description'];
        }
      });
    }
  }

  createTasks() {
    const payload = {
      name: this.name,
      description: this.description,
    };
    this.tasksService.createTasks(payload).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast('success', 'Create Tasks', 'You have successfully created a new task.');
        this.getResult.emit(res);
      }
    });
  }

  editTask() {
    const payload = {
      name: this.name,
      description: this.description,
    };
    this.tasksService.editTasks(this.assignedTaskID, payload).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast('success', 'Edit Tasks', 'You have successfull modified the task.');
        this.getResult.emit(res);
      }
    });
  }

  closeEvent() {
    this.close.emit(true);
  }
}
