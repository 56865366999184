import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DentalArchCreateComponent } from '../dental-arch-create/dental-arch-create.component';
import { DentalArchModalComponent } from '../dental-arch-modal/dental-arch-modal.component';
import { DentalArchViewComponent } from '../dental-arch-view/dental-arch-view.component';
import { SelectedTeethViewComponent } from '../selected-teeth-view/selected-teeth-view.component';
import { ToothDeleteComponent } from '../tooth-delete/tooth-delete.component';
import { ToothEditComponent } from '../tooth-edit/tooth-edit.component';
import { ToothViewComponent } from '../tooth-view/tooth-view.component';
import { TreatmentCreateComponent } from '../treatment-create/treatment-create.component';
import { TreatmentInformedConsentCreateComponent } from '../treatment-informed-consent-create/treatment-informed-consent-create.component';

import { TreatmentPracticeOverheadEditComponent } from '../treatment-practice-overhead-edit/treatment-practice-overhead-edit.component';

import { TreatmentBrandCreateEditComponent } from '../treatment-brand-create-edit/treatment-brand-create-edit.component';
import { TreatmentBrandListGlobalComponent } from '../treatment-brand-list-global/treatment-brand-list-global.component';
import { TreatmentBrandListOverviewComponent } from '../treatment-brand-list-overview/treatment-brand-list-overview.component';
import { TreatmentBrandListComponent } from '../treatment-brand-list/treatment-brand-list.component';
import { TreatmentBrandViewComponent } from '../treatment-brand-view/treatment-brand-view.component';
import { TreatmentCategoryCreateEditComponent } from '../treatment-category-create-edit/treatment-category-create-edit.component';
import { TreatmentCategoryListComponent } from '../treatment-category-list/treatment-category-list.component';
import { TreatmentDocumentEditComponent } from '../treatment-document-edit/treatment-document-edit.component';
import { TreatmentDocumentGalleryComponent } from '../treatment-document-gallery/treatment-document-gallery.component';
import { TreatmentGroupNewButtonComponent } from '../treatment-group-new-button/treatment-group-new-button.component';
import { TreatmentInclusionModalComponent } from '../treatment-incusion-modal/treatment-incusion-modal.component';
import { TreatmentProductListGlobalComponent } from '../treatment-product-list-global/treatment-product-list-global.component';
import { TreatmentProductListOverviewComponent } from '../treatment-product-list-overview/treatment-product-list-overview.component';
import { TreatmentProductListComponent } from '../treatment-product-list/treatment-product-list.component';
import { TreatmentTypeCreateEditComponent } from '../treatment-type-create-edit/treatment-type-create-edit.component';
import { TreatmentTypeListComponent } from '../treatment-type-list/treatment-type-list.component';

import { TreatmentDocumentCreateComponent } from '../treatment-document-create/treatment-document-create.component';
import { TreatmentProductEditMerchantComponent } from '../treatment-product-edit-merchant/treatment-product-edit-merchant.component';
import { TreatmentProductViewComponent } from '../treatment-product-view/treatment-product-view.component';

import { BeneficiaryViewPictureComponent } from '../../beneficiary/beneficiary-view-picture/beneficiary-view-picture.component';
import { TreatmentCategoryViewLogoComponent } from '../treatment-category-view-logo/treatment-category-view-logo.component';
import { TreatmentCategoryViewComponent } from '../treatment-category-view/treatment-category-view.component';
import { TreatmentDocumentImportOverviewComponent } from '../treatment-document-import-overview/treatment-document-import-overview.component';
import { treatmentEditModalComponent } from '../treatment-edit-modal/treatment-edit-modal.component';
import { TreatmentGroupDocumentList } from '../treatment-group-document-list/treatment-group-document-list.component';
import { TreatmentInformedConsentViewComponent } from '../treatment-informed-consent-view/treatment-informed-consent-view.component';
import { TreatmentIntroModalComponent } from '../treatment-intro-modal/treatment-intro-modal.component';
import { TreatmentProductLookupComponent } from '../treatment-product-lookup/treatment-product-lookup.component';
import { TreatmentTypeViewLogoComponent } from '../treatment-type-view-logo/treatment-type-view-logo.component';
import { TreatmentTypeViewComponent } from '../treatment-type-view/treatment-type-view.component';

const routes: Routes = [
  {
    path: 'TreatmentTypeViewLogoComponent',
    component: TreatmentTypeViewLogoComponent,
  },
  {
    path: 'TreatmentCategoryViewLogoComponent',
    component: TreatmentCategoryViewLogoComponent,
  },
  {
    path: 'TreatmentIntroModalComponent',
    component: TreatmentIntroModalComponent,
  },
  {
    path: 'TreatmentCategoryViewComponent',
    component: TreatmentCategoryViewComponent,
  },
  {
    path: 'TreatmentGroupDocumentList',
    component: TreatmentGroupDocumentList,
  },
  {
    path: 'TreatmentProductLookupComponent',
    component: TreatmentProductLookupComponent,
  },
  {
    path: 'TreatmentDocumentEditComponent',
    component: TreatmentDocumentEditComponent,
  },
  {
    path: 'TreatmentDocumentGalleryComponent',
    component: TreatmentDocumentGalleryComponent,
  },
  {
    path: 'TreatmentProductViewComponent',
    component: TreatmentProductViewComponent,
  },
  {
    path: 'TreatmentDocumentEditComponent/:treatmentDocumentID',
    component: TreatmentDocumentEditComponent,
  },
  {
    path: 'TreatmentInformedConsentCreateComponent',
    component: TreatmentInformedConsentCreateComponent,
  },

  {
    path: 'treatmentEditModalComponent',
    component: treatmentEditModalComponent,
  },

  {
    path: 'treatmentDocumentModal',
    component: TreatmentDocumentImportOverviewComponent,
  },

  {
    path: 'create-treatment',
    component: TreatmentCreateComponent,
  },
  {
    path: 'tooth-delete',
    component: ToothDeleteComponent,
  },
  {
    path: 'dental-arch-create',
    component: DentalArchCreateComponent,
  },
  {
    path: 'selected-teeth-view',
    component: SelectedTeethViewComponent,
  },
  {
    path: 'dental-arch-modal',
    component: DentalArchModalComponent,
  },
  {
    path: 'dental-arch-view',
    component: DentalArchViewComponent,
  },
  {
    path: 'tooth-view',
    component: ToothViewComponent,
  },
  {
    path: 'tooth-edit',
    component: ToothEditComponent,
  },

  {
    path: 'treatment-practice-overhead-edit',
    component: TreatmentPracticeOverheadEditComponent,
  },
  {
    path: 'treatment-document-edit',
    component: TreatmentDocumentCreateComponent,
  },

  {
    path: 'treatment-inclusions',
    component: TreatmentInclusionModalComponent,
  },

  {
    path: 'TreatmentCategoryListComponent',
    component: TreatmentCategoryListComponent,
  },
  {
    path: 'TreatmentBrandListGlobalComponent',
    component: TreatmentBrandListGlobalComponent,
  },

  {
    path: 'TreatmentBrandListComponent',
    component: TreatmentBrandListComponent,
  },

  {
    path: 'TreatmentBrandListOverviewComponent',
    component: TreatmentBrandListOverviewComponent,
  },
  {
    path: 'TreatmentTypeListComponent',
    component: TreatmentTypeListComponent,
  },

  {
    path: 'TreatmentInformedConsentViewComponent',
    component: TreatmentInformedConsentViewComponent,
  },

  {
    path: 'TreatmentBrandListComponent',
    component: TreatmentBrandListComponent,
  },

  {
    path: 'TreatmentGroupNewButtonComponent',
    component: TreatmentGroupNewButtonComponent,
  },

  {
    path: 'TreatmentCategoryCreateEditComponent',
    component: TreatmentCategoryCreateEditComponent,
  },

  {
    path: 'TreatmentCategoryCreateEditComponent/:categoryID',
    component: TreatmentCategoryCreateEditComponent,
  },

  {
    path: 'TreatmentBrandViewComponent',
    component: TreatmentBrandViewComponent,
  },

  {
    path: 'TreatmentTypeViewComponent',
    component: TreatmentTypeViewComponent,
  },

  {
    path: 'TreatmentTypeCreateEditComponent',
    component: TreatmentTypeCreateEditComponent,
  },

  {
    path: 'TreatmentTypeCreateEditComponent/:typeID',
    component: TreatmentTypeCreateEditComponent,
  },

  {
    path: 'TreatmentBrandCreateEditComponent',
    component: TreatmentBrandCreateEditComponent,
  },

  {
    path: 'TreatmentBrandCreateEditComponent/:brandID',
    component: TreatmentBrandCreateEditComponent,
  },

  {
    path: 'treatment-dynamic-product-list',
    component: TreatmentProductListComponent,
  },
  {
    path: 'treatment-dynamic-product-list-global',
    component: TreatmentProductListGlobalComponent,
  },
  {
    path: 'treatment-dynamic-product-list-overview',
    component: TreatmentProductListOverviewComponent,
  },
  {
    path: 'treatment-product-edit',
    component: TreatmentProductEditMerchantComponent,
  },

  {
    path: 'treatment-product-edit/:brandID',
    component: TreatmentProductEditMerchantComponent,
  },

  {
    path: 'BeneficiaryViewPictureComponent',
    component: BeneficiaryViewPictureComponent,
  },

  {
    path: 'treatment-product-edit/:brandID/:merchantID',
    component: TreatmentProductEditMerchantComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TreatmentRoutingModule {}
