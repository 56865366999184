import { Component, EventEmitter, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { MerchantService } from '../shared/merchant.service';

@Component({
  selector: 'app-merchant-auth-code-modal',
  templateUrl: './merchant-auth-code-modal.component.html',
  styleUrls: ['./merchant-auth-code-modal.component.css'],
  animations: [
    trigger('onChangeAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([style({ opacity: 0, offset: 0, height: 0 }), style({ opacity: 1, offset: 1.0, height: '*' })])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MerchantAuthCodeModalComponent implements OnInit {
  isPromoterOrAdmin = false;
  isModal = false;

  code;
  numberOfUse = 0;

  close = new EventEmitter();

  @Input()
  invitationID;

  @Input()
  medicalHistoryInvitationID;

  @Input()
  merchantID;

  @Input()
  patientID;

  target = 'treatment plan';

  openInNewTable = false;

  @Input()
  type = 'invitation';
  invitation: any = {};
  settings = Settings.global;
  sessionType = 'merchant';
  frontEndSite;
  practiceLoginPage;

  constructor(
    private _clipboardService: ClipboardService,
    private merchantService: MerchantService,
    private router: Router,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.merchantID) {
        this.merchantID = data.merchantID;
      }

      if (data.invitationID) {
        this.invitationID = data.invitationID;
      }

      if (data.type) {
        this.type = data.type;
      }

      if (data.medicalHistoryInvitationID) {
        this.medicalHistoryInvitationID = data.medicalHistoryInvitationID;
      }

      if (data.patientID) {
        this.patientID = data.patientID;
      }

      if (data.openInNewTable != null) {
        this.openInNewTable = data.openInNewTable;
      }
    }
  }

  ngOnInit() {
    if (this.type === 'healthHistory') {
      this.target = 'Health history';
    }

    this.frontEndSite = this.settings['consumerFrontendLink'];
    if (!this.merchantID) {
      this.authenticationService.getCurrentPractice().subscribe((res) => {
        if (res && res.ID) {
          this.merchantID = res.ID;

          this.practiceLoginPage = this.frontEndSite + '/practice-auth/' + this.merchantID;
        }
      });
    } else {
      this.practiceLoginPage = this.frontEndSite + '/practice-auth/' + this.merchantID;
    }

    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        if (this.invitationID) {
          const invitationPayload = {
            fields: 'FirstName,LastName,ID',
          };

          this.merchantService
            .getInvitationDetails(this.invitationID, invitationPayload, this.sessionType, false)
            .subscribe((invite) => {
              if (invite) {
                this.invitation = {};
                this.invitation = invite;

                const p = {
                  invitationID: this.invitationID,
                  medicalHistoryInvitationID: this.medicalHistoryInvitationID,
                  patientID: this.patientID,
                  merchantID: this.merchantID,
                  type: this.type,
                  isActive: true,
                  limit: 1,
                };
                this.merchantService.getUniqueAuthenticationList(p, this.isPromoterOrAdmin).subscribe((res) => {
                  if (res && res[0] && res[0].code) {
                    this.code = res[0].code;
                    this.numberOfUse = res[0].numberOfUse;
                  }
                });
              }
            });
        } else if (this.patientID) {
          const invitationPayload = {
            fields: 'Name,FirstName,ID',
          };

          this.authenticationService
            .getCustomerProspectDetails(this.patientID, invitationPayload, this.sessionType)
            .subscribe((invite) => {
              if (invite) {
                this.invitation = {};
                if (invite.Name) {
                  this.invitation.LastName = invite.Name;
                }

                if (invite.FirstName) {
                  this.invitation.FirstName = invite.FirstName;
                }

                const p = {
                  invitationID: this.invitationID,
                  medicalHistoryInvitationID: this.medicalHistoryInvitationID,
                  patientID: this.patientID,
                  merchantID: this.merchantID,
                  type: this.type,
                  isActive: true,
                  limit: 1,
                };
                this.merchantService.getUniqueAuthenticationList(p, this.isPromoterOrAdmin).subscribe((res) => {
                  if (res && res[0] && res[0].code) {
                    this.code = res[0].code;
                    this.numberOfUse = res[0].numberOfUse;
                  }
                });
              }
            });
        }
      });
    });
  }

  newCode() {
    const confirm = new ConfirmDialog(
      'fas fa-info',
      'New Code',
      'Are you sure you want to create a new access Code ?',
      'No',
      'Yes'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
    });
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        const p = {
          invitationID: this.invitationID,
          medicalHistoryInvitationID: this.medicalHistoryInvitationID,
          patientID: this.patientID,
          type: this.type,
          merchantID: this.merchantID,
        };
        this.merchantService.createUniqueAuthentication(p, this.isPromoterOrAdmin).subscribe((res) => {
          if (res && res.code) {
            this.code = res.code;
            this.numberOfUse = res.numberOfUse;
            NotifyAppComponent.displayToast('success', 'Successful operation', 'New Practice code  is created');
            ref.close();
          }
        });
      }
    });
  }

  goToPracticeLogin() {
    if (this.practiceLoginPage) {
      const confirmDialog = new ConfirmDialog(
        'fas fa-info',
        'Log out?',
        'If you access to Practice login page you will be disconnected , do you want to proceed?',
        'No',
        'Yes'
      );
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        width: '300px',
      });
      ref.componentInstance.onConfirm.subscribe((res) => {
        if (res) {
          const url = this.practiceLoginPage;
          window.open(url, '_blank');
        }
      });
    }
  }

  copy(text: string) {
    if (text) {
      this._clipboardService.copyFromContent(text);
      NotifyAppComponent.displayToast('success', 'Copied!', '');
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  proceed() {
    if (this.openInNewTable === true) {
      if (this.type == 'invitation' && this.invitation && this.invitation['ID']) {
        const url = `${this.settings['merchantFrontendLink']}/merchant/(page:patient-card/${this.invitation['ID']})`;

        window.open(url, '_blank');
      } else if (this.type == 'healthHistory' && this.patientID) {
        const url = `${this.settings['merchantFrontendLink']}/merchant/(page:medical/${this.patientID})`;

        window.open(url, '_blank');
      }
    } else {
      if (this.type == 'invitation' && this.invitation && this.invitation['ID']) {
        this.router.navigate(['/merchant', { outlets: { page: ['patient-card', this.invitation['ID']] } }]);
      } else if (this.type == 'healthHistory' && this.patientID) {
        this.router.navigate(['/merchant', { outlets: { page: ['medical', this.patientID] } }]);
      }
    }

    this.closeEvent();
  }

  emailMe() {
    const confirmDialog = new ConfirmDialog(
      'fas fa-info',
      'Send  Link ',
      'Do you want to Practice Authentication Link to your Email?',
      'No',
      'Yes'
    );
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      width: '300px',
    });
    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res) {
        const payload = {
          merchantID: this.merchantID,
        };
        this.merchantService.sendLinkUniqueAuthentication(payload, this.isPromoterOrAdmin).subscribe((res) => {
          if (res) {
            NotifyAppComponent.displayToast('success', 'Link Sent!', '');

            ref.close();
          }
        });
      }
    });
  }
}
