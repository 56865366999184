<div class="card-header primary-gradient-img clearfix inModal saveModal">
  <div class="row clearfix">
    <!-- merchant calculated name -->
    <div class="row titleArea clearfix">
      <mat-icon class="pull-left fas fa-object-ungroup"></mat-icon>
      <h2 class="summary-header rm-m">
        Save template
        <span class="subLabel">Store a pre-defined invitation template for later use</span>
      </h2>
    </div>
    <!-- Modal dismiss button -->
    <button class="btn-close btn-clear mat-button white" (click)="close()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>

<mat-dialog-content>
  <form #editForm="ngForm">
    <p class="small clearfix">Give your template a Title and Description for your own reference</p>

    <div class="row mt clearfix">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Template title</mat-label>
        <input
          class="mat-block largeInput"
          [(ngModel)]="label"
          name="totalTreatmentValue"
          matInput
          placeholder="Template title"
          required
        />
      </mat-form-field>
    </div>

    <div class="row clearfix">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Description</mat-label>
        <textarea [(ngModel)]="description" name="description" matInput placeholder="Description"></textarea>
      </mat-form-field>
    </div>

    <p class="small clearfix">Select all or choose options from your invitation to be added to the new template</p>

    <div class="row mt clearfix">
      <mat-checkbox
        class="full-width"
        [(ngModel)]="selectAll"
        (ngModelChange)="selectAllEvent()"
        name="selectAll"
        color="accent"
      >
        <strong> Select all options</strong>
      </mat-checkbox>
    </div>

    <hr/>
    <div class="full-width flex mb mt selection-area">
      <div class="full-width">
        <div class="row full-width clearfix margin-btm">
          <mat-checkbox
            class="full-width"
            [(ngModel)]="selectTreatmentValue"
            [disabled]="selectAll == true"
            name="selectTreatmentValue"
            color="accent"
          >
            {{ "KEYWORD.treatment" | translate | titlecase }} Value
          </mat-checkbox>
        </div>

        <div class="row full-width clearfix margin-btm">
          <mat-checkbox
            class="full-width"
            [(ngModel)]="selectDocuments"
            [disabled]="selectAll == true"
            name="selectDocuments"
            color="accent"
          >
            {{ "KEYWORD.treatment" | translate | titlecase }} Documents
          </mat-checkbox>
        </div>

        <div class="row full-width clearfix margin-btm">
          <mat-checkbox
            class="full-width"
            [(ngModel)]="selectImages"
            [disabled]="selectAll == true"
            name="selectImages"
            color="accent"
          >
            {{ "KEYWORD.treatment" | translate | titlecase }} Images
          </mat-checkbox>
        </div>

        <div class="row full-width clearfix margin-btm">
          <mat-checkbox
            class="full-width"
            [(ngModel)]="selectTreatmentPlan"
            [disabled]="selectAll == true"
            name="selectTreatmentPlan"
            color="accent"
          >
            {{ "KEYWORD.treatment" | translate | titlecase }} Items
          </mat-checkbox>
        </div>
        <div class="row full-width clearfix margin-btm">
          <mat-checkbox
            class="full-width"
            [(ngModel)]="selectTags"
            [disabled]="selectAll == true"
            name="selectTags"
            color="accent"
          >
            Tags
          </mat-checkbox>
        </div>
      </div>
      <div class="full-width">
        <div class="row full-width clearfix margin-btm">
          <mat-checkbox
            class="full-width"
            [(ngModel)]="selectMessage"
            [disabled]="selectAll == true"
            name="selectMessage"
            color="accent"
          >
            Personal message to {{"KEYWORD.patient" | translate}}
          </mat-checkbox>
        </div>

        <div class="row full-widthclearfix margin-btm">
          <mat-checkbox
            class="full-width"
            [(ngModel)]="selectDiscount"
            [disabled]="selectAll == true"
            name="selectDiscount"
            color="accent"
          >
            Payment in full (Discount)
          </mat-checkbox>
        </div>

        <div class="row full-widthclearfix margin-btm">
          <mat-checkbox
            class="full-width"
            [(ngModel)]="selectHealthHistory"
            [disabled]="selectAll == true"
            name="selectHealthHistory"
            color="accent"
          >
            Health History
          </mat-checkbox>
        </div>

        <div class="row full-width clearfix margin-btm">
          <mat-checkbox
            class="full-width"
            [(ngModel)]="selectGoogleReview"
            [disabled]="selectAll == true"
            name="selectGoogleReview"
            color="accent"
          >
            Google Review
          </mat-checkbox>
        </div>
      </div>
    </div>

    <div class="row clearfix text-center">
      <button
        [disabled]="
          !editForm.form.valid ||
          (selectAll != true &&
            selectDocuments != true &&
            selectImages != true &&
            selectTreatmentPlan != true &&
            selectTags != true &&
            selectTreatmentValue != true &&
            selectMessage != true &&
            selectHealthHistory != true &&
            selectGoogleReview != true &&
            selectDiscount != true)
        "
        (click)="sendResult()"
        mat-raised-button
        color="accent"
      >
        Save
      </button>
    </div>
  </form>
</mat-dialog-content>
