<mat-card  class="rel">

  <div class="card-header primary-gradient-img clearfix inModal">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row clearfix">
        <div style="width: 70px;float: left;min-height: 44px;margin-top: 7px;">
          <mat-icon class="fas fa-file-contract pull-left  main-icon-brush "
            style=" font-size: 2.8rem;  width: 70px;">
          </mat-icon>



        </div>

        <h2 class="summary-header rm-m">
          <!-- {{ beneficiary.CalculatedName}} -->
          {{ title }}
          <span class="subLabel" *ngIf="description">{{description}}</span>
        </h2>
      </div>
    </div>
  </div>


  <mat-dialog-content>



    <app-inner-html-isolated  class="inner-html-container" *ngIf="content" [content]="content">
    </app-inner-html-isolated>


    <app-empty-list-message *ngIf="!content" [title]="'No Content Found'" [message]="'Nothing to display'">
    </app-empty-list-message>



  </mat-dialog-content>

  <div class="drop-button text-center full-width">
    <button class="mt" (click)="closeEvent()" mat-raised-button color="primary">
      Close
    </button>


  </div>
</mat-card >
