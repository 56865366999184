<div class="rel dash intro">
  <mat-dialog-content class="beta-dash" id="sub-plan-dialog-content">
    <div class="full-width beta-container">
      <div class="screen-background">
        <div class="full-width main-container">
          <div class="full-width text-center top-bar">
            <mat-icon class="pull-right smr fas fa-ellipsis-v main_icon"></mat-icon>

            <mat-icon class="pull-right smr main_icon">web</mat-icon>
            <mat-icon class="pull-right smr main_icon">extension</mat-icon>

            <!-- <p *ngIf="card" class="card-name full-width">{{card['FirstName']}}</p> -->

            <mat-icon class="pull-left sml fas fa-arrow-left main_icon"></mat-icon>
            <mat-icon class="pull-left sml fas fa-arrow-right main_icon"></mat-icon>
            <mat-icon class="pull-left sml fas fa-redo-alt main_icon"></mat-icon>

            <mat-form-field class="mr search-input-desktop sml" appearance="outline">
              <input [(ngModel)]="messageFilter" matInput name="Filter" placeholder="Search Google or type a URL"/>
              <mat-icon class="pull-right far fa-star main_icon" matSuffix></mat-icon>
              <mat-icon class="pull-right fas fa-search-plus main_icon smr" matSuffix></mat-icon>
              <mat-icon class="pull-right fas fa-map-marker-alt main_icon smr" matSuffix></mat-icon>
            </mat-form-field>
          </div>

          <div class="full-width main-content">
            <div class="main-banner blue-banner"></div>

            <div class="full-width clearfix top-header">
              <button class="btn mr smt contact-vtn pull-right" mat-raised-button>Contact Us</button>

              <h3 class="sr-title rm-m practice-title pull-left" *ngIf="merchant">{{ merchant.TradingAs }}</h3>
            </div>
            <div class="full-width clearfix">
              <mat-card class="card customized-card text-center">
                <app-card-picture
                  class="smb side-card-picture-preview-desktop"
                  *ngIf="card"
                  [hideZoomButton]="false"
                  [tableName]="card['Card_TableName']"
                  [LastModified]="card['LastModified']"
                  [cardID]="cardID"
                >
                </app-card-picture>

                <div class="full-width text-center smb" *ngIf="card">
                  <h3 class="sr-title rm-m primary-color text-center">
                    {{ card['FirstName'] }}
                    {{ card['Name'] }}
                  </h3>
                </div>
                <hr *ngIf="card"/>
                <div class="full-width smt smb" style="float: none !important">
                  <div
                    class="full-width message-body-main text-left"
                    [innerHTML]="URLify(message || '...') | safeHtml"
                    style="float: none !important"
                  ></div>
                </div>
                <p class="small mt time-now-text" *ngIf="timeNow">Today {{ timeNow }}</p>
              </mat-card>
            </div>
          </div>
        </div>

        <div class="screen-bottom"></div>
      </div>

      <button class="btn-large close-big-bottom" (click)="closeEvent()" mat-raised-button color="accent">Close</button>
    </div>
  </mat-dialog-content>
</div>
