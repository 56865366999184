import { Location } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { MarketingService } from '../../marketing/shared/marketing.service';

@Component({
  selector: 'app-marketing-filter-create',
  templateUrl: './marketing-filter-create.component.html',
  styleUrls: ['./marketing-filter-create.component.css'],
})
export class MarketingFilterCreateComponent implements OnInit {
  @Input()
  merchantID;

  @Input()
  standalone = true;

  @Output()
  getResult = new EventEmitter();

  @Output()
  close = new EventEmitter();

  isModal = false;

  filterName;
  filterDescription;

  filter = {};

  filterArray = [
    {
      filterSection: null,
      filterProperty: null,
    },
  ];

  resultArray = [];

  isPromoterOrAdmin = false;

  sectionConjunction = 'and';

  merchants = [];
  @Input()
  filterID;
  isEdit = false;

  constructor(
    private marketingService: MarketingService,
    private authenticationService: AuthenticationService,
    private merchantService: MerchantService,
    private location: Location,
    private activeRoute: ActivatedRoute,
    private router: Router,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.merchant != null) {
        this.merchantID = data.merchantID;
        this.isModal = true;
      }
      if (data.merchantID != null) {
        this.merchantID = data.merchantID;
        this.isModal = true;
      }

      if (data.filterID != null) {
        this.filterID = data.filterID;
        this.isModal = true;
      }
      if (data.standalone != null) {
        this.standalone = data.standalone;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['filterID']) {
        this.filterID = params['filterID'];
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        if (this.isPromoterOrAdmin == true) {
          const payload = {
            fields: 'ID,TradingAs,CalculatedName,Status',
          };

          this.setUp();
          this.merchantService.getList(payload, this.isPromoterOrAdmin).subscribe((res) => {
            this.merchants = res;
          });
        }else {
          this.setUp()
        }
      });
    });
  }

  setUp() {
    if (this.filterID) {
      this.isEdit = true;
      this.marketingService.getOneFilter(this.filterID, this.isPromoterOrAdmin).subscribe((res) => {
        if (res) {
          if (res['Label']) {
            this.filterName = res['Label'];
          }

          if (res['Description']) {
            this.filterDescription = res['Description'];
          }

          if (res['Query']) {
            this.sectionConjunction = res['Query'][0]['Conjunction']['ToPrevious'];
            this.filterArray = res['Query'];
          }
        }
      });
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  removeSection(index) {
    if (this.filterArray.length == 1) {
      this.filterArray = [
        {
          filterSection: null,
          filterProperty: null,
        },
      ];
      this.resultArray = [];
    } else {
      this.filterArray.splice(index, 1);
      this.resultArray.splice(index, 1);
    }
  }

  clearSection() {
    this.filterArray = [
      {
        filterSection: null,
        filterProperty: null,
      },
    ];
    this.resultArray = [];
  }

  addSection() {
    this.filterArray.push({
      filterSection: null,
      filterProperty: null,
    });
  }

  getFilter(e, index) {
    if (this.resultArray.length == 0) {
      this.resultArray.push(e);
    } else {
      this.resultArray.splice(index, 1, e);
    }
  }

  createFilter() {
    const payload = {
      label: this.filterName,
      description: this.filterDescription,
      joins: this.sectionConjunction,
      query: [],
    };

    if (this.isPromoterOrAdmin == true && this.merchantID) {
      payload['merchantID'] = this.merchantID;
    }

    if (this.resultArray.length > 0) {
      for (let i = 0; i < this.resultArray.length; i++) {
        this.resultArray[i]['Conjunction']['ToPrevious'] = this.sectionConjunction;
      }

      payload.query = this.resultArray;

      if (this.filterID) {
        this.marketingService.modifyFilter(this.filterID, payload, this.isPromoterOrAdmin).subscribe((res) => {
          if (res) {
            NotifyAppComponent.displayToast('success', 'Update filter', 'You have successfully updated a new filter');

            this.getResult.emit(res);

            if (this.isModal != true) {
              this.router.navigate(['/merchant', { outlets: { page: ['marketing-filter-list-overview'] } }]);
            }
          }
        });
      } else {
        this.marketingService.createFilter(payload, this.isPromoterOrAdmin).subscribe((res) => {
          if (res) {
            NotifyAppComponent.displayToast('success', 'Create filter', 'You have successfully created a new filter');

            this.getResult.emit(res);
            if (this.isModal != true) {
              this.router.navigate(['/merchant', { outlets: { page: ['marketing-filter-list-overview'] } }]);
            }
          }
        });
      }
    }
  }

  getSectionConjunction(e) {
    this.sectionConjunction = e;
  }

  getPropertyConjunctionEvent(e, index) {
    if (this.resultArray.length > 0 && e) {
      this.resultArray[index]['Conjunction']['BetweenLines'] = e;
    }
  }

  goback() {
    this.location.back();
  }
}
