<ipv-title-modal
  [title]="'Setup Google Reviews'"
  [customClass]="'info'"
  [icon]="'blank'"
  [hideAllDefaultButtons]="true"
>
  <div content>
    <ng-container *ngIf="isServiceReady === true; else spinner">
      <div style="display: flex; justify-content: space-between">
        <p style="max-width: 300px">Connect to your Google My Business account by signing in.</p>
        <div class="google-btn" (click)="signIn()">
          <div class="google-icon-wrapper">
            <img
              class="google-icon"
              src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
            />
          </div>
          <p class="btn-text">Sign in with Google</p>
        </div>
      </div>

      <h3 class="text-center primary sr-title">Don't have an account?</h3>

      <div style="display: flex; justify-content: space-between">
        <p style="max-width: 300px">
          Create your free Google My Business account now by clicking the Create Account button.
        </p>
        <a
          mat-raised-button
          color="accent"
          href="https://www.google.com/business/"
          target="_blank"
          style="display: block; height: 35px; margin-top: 20px; width: 188px"
        >
          Create Account
        </a>
      </div>
    </ng-container>

    <ng-template #spinner>
      <p style="text-align: center; margin-top: 30px">
        {{ spinnerText }}
      </p>
    </ng-template>
  </div>
</ipv-title-modal>
