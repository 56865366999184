import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { ActionLogViewComponent } from '../action-log-view/action-log-view.component';
import { ActionLogService } from '../shared/action-log.service';
import { ActionLogUtilClass } from '../shared/actionLogUtil';

@Component({
  selector: 'app-action-log-list',
  templateUrl: './action-log-list.component.html',
  styleUrls: ['./action-log-list.component.css'],
  animations: [
    trigger('fade', [transition(':enter', [style({ opacity: 0 }), animate('.6s ease')])]),
    trigger('stagger', [transition(':enter', [query(':enter', stagger('.3s', [animateChild()]), { optional: true })])]),
  ],
})
export class ActionLogListComponent implements OnInit {
  @Input()
  targetID = '';

  @Input()
  itemID = '';

  @Input()
  withoutAutoAction = false;

  @Input()
  withoutInternalAction = false;

  @Output()
  closeModal = new EventEmitter();

  isModal = false;
  isPromoterOrAdmin = false;
  settings = Settings.global;

  actionLogs;
  _actionLogs;
  eventLogs;
  combinedLogs;

  util = new UtilsClass();
  actionLogUtil = new ActionLogUtilClass();

  typeFilter = 'none';
  sessionType;

  constructor(
    private authenticationService: AuthenticationService,
    private actionlogService: ActionLogService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.targetID) {
        this.targetID = data.targetID;
      }

      if (data.itemID) {
        this.itemID = data.itemID;
      }

      if (data.withoutAutoAction) {
        this.withoutAutoAction = data.withoutAutoAction || false;
      }

      if (data.withoutInternalAction) {
        this.withoutInternalAction = data.withoutInternalAction || false;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        const payload = {
          targetID: this.targetID,
          itemID: this.itemID,
          withoutAutoAction: this.withoutAutoAction,
          withoutInternalAction: this.withoutInternalAction,
        };

        this.actionlogService.getActionLogList(payload, this.sessionType).subscribe((res) => {
          this.actionLogs = res;
          this._actionLogs = res;
        });
      });
    });
  }

  closeEvent() {
    this.closeModal.emit(true);
  }

  viewLog(id) {
    const ref = RootAppComponent.dialog.open(ActionLogViewComponent, {
      data: id,
      width: '650px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  setFilter() {
    if (this.typeFilter) {
      if (this.typeFilter == 'auto') {
        this.actionLogs = this._actionLogs.filter((x) => x['Action'] == 'Auto');
      } else if (this.typeFilter == 'internal') {
        this.actionLogs = this._actionLogs.filter((x) => x['Action'] == 'Internal Message');
      } else if (this.typeFilter == 'manual') {
        this.actionLogs = this._actionLogs.filter((x) => x['Action'] != 'Internal Message' && x['Action'] != 'Auto');
      } else {
        this.actionLogs = this._actionLogs;
      }
    } else {
      this.actionLogs = this._actionLogs;
    }
  }
}
