import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { EPDocumentCreateEditComponent } from '../../../shared/components/ep-document-create-edit/ep-document-create-edit.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { PatientDocumentCreateModalComponent } from '../../customer-prospect/patient-document-create-modal/patient-document-create-modal.component';
import { CustomerProspectService } from '../../customer-prospect/shared/customerProspect.service';
import { InvitationLookupComponent } from '../../invitation/invitation-lookup/invitation-lookup.component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { TreatmentService } from '../shared/treatment.service';
import { treatmentUtil } from '../shared/treatmentUtil';
import { TreatmentBrandViewComponent } from '../treatment-brand-view/treatment-brand-view.component';
import { TreatmentCategoryViewComponent } from '../treatment-category-view/treatment-category-view.component';
import { TreatmentDocumentCreateComponent } from '../treatment-document-create/treatment-document-create.component';
import { TreatmentProductViewComponent } from '../treatment-product-view/treatment-product-view.component';
import { TreatmentTypeViewComponent } from '../treatment-type-view/treatment-type-view.component';

import * as _ from 'lodash';
import { SmsConversationComponent } from '../../message/sms-conversation/sms-conversation.component';
import { SmsPromoterComponent } from '../../message/sms-promoter/sms-promoter.component';

@Component({
  selector: 'app-treatment-document-import-overview',
  templateUrl: './treatment-document-import-overview.component.html',
  styleUrls: ['./treatment-document-import-overview.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class TreatmentDocumentImportOverviewComponent implements OnInit {
  @Input()
  brandID;
  brandIDSearch;
  @Input()
  productID;
  productIDSearch;
  @Input()
  categoryID;
  categoryIDSearch;
  @Input()
  typeID;
  typeIDSearch;

  addTitle = 'Add to  treatment plan';
  @Input()
  itemCode;
  itemCodeSearch;
  @Input()
  merchantID;
  merchantIDSearch;
  @Input()
  patientID;
  patientIDSearch;
  @Input()
  ICLabel;
  ICLabelSearch;
  @Input()
  title = 'Practice Document & Image Gallery';
  @Input()
  description = 'Select the documents you wish to add to your treatment plan';
  isDownload = false;
  isRedirection = false;
  isPrint = false;

  selectAnotherPatient = true;

  @Input()
  userDocumentType: any;

  @Input()
  patientDocumentType: any;

  @Input()
  ICDocumentType: any;

  _userDocumentType;
  _patientDocumentType;
  _ICDocumentType;

  _userDocumentTypeSearch;
  _patientDocumentTypeSearch;
  _ICDocumentTypeSearch;

  @Input()
  Operators;
  @Input()
  documents: any = [];
  @Input()
  isDisplayMerchant = true;
  @Input()
  loadUserDocument = false;
  @Input()
  loadPatientDocument = false;
  @Input()
  loadICDocument = false;

  @Input()
  displayPatientDocuments = true;
  @Input()
  displayUserDocuments = true;
  @Input()
  displayICDocuments = true;

  @Input()
  displaySelecteDocuments = true;
  @Input()
  actionLabel = 'Search';
  @Input()
  readonly = false;
  @Input()
  isUniqueDocument = true;
  @Input()
  includeInherited = true;
  @Input()
  selectedView = 'user';

  @Input()
  selectPatient = true;
  @Input()
  isFullsizeClick = true;

  @Input()
  isGallery = null;

  @Input()
  _isGallery = true;
  _isGallerySearch = true;
  @Input()
  patientOrderBy = '-DateTimeCreated';
  patientOrderBySearch = '-DateTimeCreated';
  @Input()
  userOrderBy = '-DateTimeCreated';
  userOrderBySearch = '-DateTimeCreated';
  @Input()
  ICOrderBy = '-DateTimeCreated';
  ICOrderBySearch = '-DateTimeCreated';

  userDocumentFirstLoad = false;
  patientDocumentFirstLoad = false;
  ICDocumentFirstLoad = false;
  canCheck = true;
  ICDocumentLoading = false;
  userDocumentLoading = false;
  userDocumentLabel;
  userDocumentLabelSearch;
  patientDocumentLoading = false;
  patientDocumentLabel;
  patientDocumentLabelSearch;
  isAdminOrPromoter = false;
  merchants = [];
  userDocuments = [];
  patientDocuments = [];
  ICDocuments = [];

  userDocumentsDefault = [];
  patientDocumentsDefault = [];
  ICDocumentsDefault = [];

  userDocumentsLength = 0;
  patientDocumentsLength = 0;
  ICDocumentsLength = 0;

  categories;
  types;
  brands;
  products;
  @Output() getDocuments = new EventEmitter();
  @Output() getCheckedDocument = new EventEmitter();
  @Output() getUncheckedDocument = new EventEmitter();

  deleteUserDocumentEvent = new EventEmitter();
  deletePatientDocumentEvent = new EventEmitter();
  deleteICDocumentEvent = new EventEmitter();

  isModal = true;
  @Output()
  closeModal = new EventEmitter();
  treatmentUtil = new treatmentUtil();
  selectedDocumentFilter;
  userDocumentFilter;
  patientDocumentFilter;
  ICDocumentFilter;
  @Input()
  isUserSideMenu = true;
  @Input()
  isICSideMenu = true;
  @Input()
  isPatientSideMenu = true;
  @Input()
  buttonLabel = 'Documents';
  @Input()
  displayUserDocumentType = true;
  @Input()
  displayPatientDocumentType = true;
  @Input()
  displayOrderBy = true;

  @Input()
  displayICDocumentType = true;

  @Input()
  userDocumentTypes = [
    {
      label: 'Procedure Images',
      code: 'SRVSTC',
    },
    {
      label: 'Treatment Plan',
      code: 'TRP',
    },
    {
      label: 'Treatment Videos',
      code: 'IGVIDEO',
    },
  ];
  @Input()
  ICDocumentTypes = [
    {
      label: 'Post Op Documents',
      code: 'SRVPST',
    },
    {
      label: 'Pre Op Documents',
      code: 'SRVPRE',
    },
    {
      label: 'Procedure Images',
      code: 'SRVSTC',
    },
    {
      label: 'Marketing Documents',
      code: 'SRVMKT',
    },
    {
      label: 'Supplier Documents',
      code: 'SRVSPL',
    },
    {
      label: 'Treatment Info',
      code: 'SRV',
    },
    {
      label: 'Treatment Videos',
      code: 'IGVIDEO',
    },
  ];

  @Input()
  patientDocumentTypes = [
    {
      label: 'Videos',
      code: 'MEDR_V',
    },
    {
      label: 'Xrays',
      code: 'MEDR_X',
    },
    {
      label: 'Treatment Plans',
      code: 'MEDR_T',
    },
    {
      label: 'Images',
      code: 'MEDR_I',
    },
    {
      label: 'Referrals',
      code: 'MEDR_R',
    },
    {
      label: 'Correspondences',
      code: 'MEDR_C',
    },
    {
      label: 'Treatment Videos',
      code: 'IGVIDEO',
    },
  ];

  @Input()
  natures = [
    {
      label: 'Videos',
      code: 'video',
    },
    {
      label: 'Images',
      code: 'image',
    },
    {
      label: 'Documents',
      code: 'document',
    },
  ];

  @Input()
  naturePatientDocument: any = ['video', 'image', 'document'];

  @Input()
  natureUserDocument: any = ['video', 'image', 'document'];

  @Input()
  natureICDocument: any = ['video', 'image', 'document'];

  _naturePatientDocument;
  _natureUserDocument;
  _natureICDocument;

  _naturePatientDocumentSearch;
  _natureUserDocumentSearch;
  _natureICDocumentSearch;
  @Input()
  displayNature = true;

  canRemove = true;

  profileLink;
  util = new UtilsClass();

  dateTo;
  dateFrom;

  dateToSearch;
  dateFromSearch;

  DefauldateFrom = null;
  DefauldateTo = null;

  onlyView = false;

  @Input()
  limit = 20;

  @Input()
  limitIC = 20;
  @Input()
  userDocumentSection = 1;
  @Input()
  patientDocumentSection = 1;
  @Input()
  ICDocumentSection = 1;

  loadMoreUserDocument = false;
  loadMorePatientDocument = false;
  loadMoreICDocument = false;
  addUserFiles = new EventEmitter();
  sessionType = 'guest';
  addICFiles = new EventEmitter();
  addPatientFiles = new EventEmitter();

  isModulePatientDocumentActive = Settings.global['isModulePatientDocumentActive'];

  patient;
  selectedPatient;
  selectedPatientSearch;

  constructor(
    private customerProspectService: CustomerProspectService,
    private treatmentService: TreatmentService,
    private authenticationService: AuthenticationService,
    private utilService: UtilsService,
    private merchantService: MerchantService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.documents && data.documents.length > 0) {
        this.documents = JSON.parse(JSON.stringify(data.documents));
        this.isModal = true;
      }
      if (data.productID) {
        this.productID = data.productID;
        this.isModal = true;
      }
      if (data.addTitle) {
        this.addTitle = data.addTitle;
        this.isModal = true;
      }

      if (data.categoryID) {
        this.categoryID = data.categoryID;
        this.isModal = true;
      }
      if (data.displayNature != null) {
        this.displayNature = data.displayNature;
        this.isModal = true;
      }

      if (data.userOrderBy != null) {
        this.userOrderBy = data.userOrderBy;
        this.isModal = true;
      }

      if (data.ICOrderBy != null) {
        this.ICOrderBy = data.ICOrderBy;
        this.isModal = true;
      }

      if (data.patientOrderBy != null) {
        this.patientOrderBy = data.patientOrderBy;
        this.isModal = true;
      }
      if (data.selectAnotherPatient != null) {
        this.selectAnotherPatient = data.selectAnotherPatient;
        this.isModal = true;
      }

      if (data.patientID) {
        this.patientID = data.patientID;
        this.isModal = true;
      }

      if (data.naturePatientDocument) {
        this.naturePatientDocument = data.naturePatientDocument;
        this.isModal = true;
      }

      if (data.natureUserDocument) {
        this.natureUserDocument = data.natureUserDocument;
        this.isModal = true;
      }
      if (data.natureICDocument) {
        this.natureICDocument = data.natureICDocument;
        this.isModal = true;
      }

      if (data.nature) {
        this.naturePatientDocument = data.nature;
        this.natureUserDocument = data.nature;
        this.natureICDocument = data.nature;

        this.isModal = true;
      }

      if (data.typeID) {
        this.typeID = data.typeID;
        this.isModal = true;
      }
      if (data.itemCode) {
        this.itemCode = data.itemCode;
        this.isModal = true;
      }
      if (data.merchantID) {
        this.merchantID = data.merchantID;
        this.isModal = true;
      }
      if (data.ICLabel) {
        this.ICLabel = data.ICLabel;
        this.isModal = true;
      }
      if (data.limit) {
        this.limit = data.limit;
        this.isModal = true;
      }

      if (data.title) {
        this.title = data.title;
        this.isModal = true;
      }
      if (data.description) {
        this.description = data.description;
        this.isModal = true;
      }
      if (data.userDocumentType) {
        this.userDocumentType = data.userDocumentType;
        this.isModal = true;
      }
      if (data.patientDocumentType) {
        this.patientDocumentType = data.patientDocumentType;
        this.isModal = true;
      }
      if (data.ICDocumentType) {
        this.ICDocumentType = data.ICDocumentType;
        this.isModal = true;
      }

      if (data.documentType) {
        this.userDocumentType = data.documentType;
        this.patientDocumentType = data.documentType;
        this.ICDocumentType = data.documentType;
        this.isModal = true;
      }

      if (data.isDisplayMerchant != null) {
        this.isDisplayMerchant = data.isDisplayMerchant;
        this.isModal = true;
      }
      if (data.loadUserDocument != null) {
        this.loadUserDocument = data.loadUserDocument;
        this.isModal = true;
      }
      if (data.loadPatientDocument != null) {
        this.loadPatientDocument = data.loadPatientDocument;
        this.isModal = true;
      }

      if (data.selectPatient != null) {
        this.selectPatient = data.selectPatient;
        this.isModal = true;
      }

      if (data.loadPatientDocument != null) {
        this.loadPatientDocument = data.loadPatientDocument;
        this.isModal = true;
      }
      if (data.loadICDocument != null) {
        this.loadICDocument = data.loadICDocument;
        this.isModal = true;
      }

      if (data.displayUserDocuments != null) {
        this.displayUserDocuments = data.displayUserDocuments;
        this.isModal = true;
      }

      if (data.displayPatientDocuments != null) {
        this.displayPatientDocuments = data.displayPatientDocuments;
        this.isModal = true;
      }

      if (data.displayICDocuments != null) {
        this.displayICDocuments = data.displayICDocuments;
        this.isModal = true;
      }
      if (data.readonly != null) {
        this.readonly = data.readonly;
        this.isModal = true;
      }
      if (data.isUniqueDocument != null) {
        this.isUniqueDocument = data.isUniqueDocument;
        this.isModal = true;
      }
      if (data.includeInherited != null) {
        this.includeInherited = data.includeInherited;
        this.isModal = true;
      }
      if (data.selectedView) {
        this.selectedView = data.selectedView;
        this.isModal = true;
      }
      if (data.displaySelecteDocuments != null) {
        this.displaySelecteDocuments = data.displaySelecteDocuments;
        this.isModal = true;
      }
      if (data.actionLabel) {
        this.actionLabel = data.actionLabel;
        this.isModal = true;
      }
      if (data.isUserSideMenu != null) {
        this.isUserSideMenu = data.isUserSideMenu;
        this.isModal = true;
      }
      if (data.isPatientSideMenu != null) {
        this.isPatientSideMenu = data.isPatientSideMenu;
        this.isModal = true;
      }
      if (data.isICSideMenu != null) {
        this.isICSideMenu = data.isICSideMenu;
        this.isModal = true;
      }
      if (data.displayUserDocumentType != null) {
        this.displayUserDocumentType = data.displayUserDocumentType;
        this.isModal = true;
      }
      if (data.displayPatientDocumentType != null) {
        this.displayPatientDocumentType = data.displayPatientDocumentType;
        this.isModal = true;
      }
      if (data.displayICDocumentType != null) {
        this.displayICDocumentType = data.displayICDocumentType;
        this.isModal = true;
      }
      if (data.userDocumentTypes && data.userDocumentTypes.length > 0) {
        this.userDocumentTypes = data.userDocumentTypes;
        this.isModal = true;
      }
      if (data.patientDocumentTypes && data.patientDocumentTypes.length > 0) {
        this.patientDocumentTypes = data.patientDocumentTypes;
        this.isModal = true;
      }
      if (data.ICDocumentTypes && data.ICDocumentTypes.length > 0) {
        this.ICDocumentTypes = data.ICDocumentTypes;
        this.isModal = true;
      }

      if (data.userDocumentSection) {
        this.userDocumentSection = data.userDocumentSection;
        this.isModal = true;
      }
      if (data.patientDocumentSection) {
        this.patientDocumentSection = data.patientDocumentSection;
        this.isModal = true;
      }
      if (data.buttonLabel) {
        this.buttonLabel = data.buttonLabel;
      }

      if (data.ICDocumentSection) {
        this.ICDocumentSection = data.ICDocumentSection;
        this.isModal = true;
      }

      if (
        data.isGallery != null &&
        (!this.userDocumentType || this.userDocumentType == 'TRP' || this.userDocumentType == 'SRVSTC')
      ) {
        this.isGallery = data.isGallery;
        this._isGallery = this.isGallery;

        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModulePatientDocumentActive = access['isModulePatientDocumentActive'];

        this.authenticationService.getSessionType().subscribe((sessionType) => {
          this.sessionType = sessionType;

          if (this.patientID) {
            const __p = {
              fields: 'ID,CalculatedName,FirstName,MiddleName,Name',
            };
            this.customerProspectService
              .getCustomerProspectDetails(this.patientID, __p, this.sessionType)
              .subscribe((patient) => {
                if (patient) {
                  this.patient = patient;
                  this.selectedPatient = patient;
                  this.selectedPatientSearch = patient;
                  this.selectedPatientSearch['firstName'] = patient['FirstName'];
                  this.selectedPatientSearch['lastName'] = patient['Name'];
                  this.profileLink = this.customerProspectService.getProfilePicStreamLink(this.selectedPatient['ID']);
                }
              });
          }

          this.checkTitle();

          this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
            this.isAdminOrPromoter = res;
            if (this.isAdminOrPromoter == true) {
              this.isPrint = true;
              this.isDownload = true;
              this.isRedirection = true;
            }
            if (this.isDisplayMerchant == true && this.isAdminOrPromoter == true) {
              const payload = {
                fields: 'ID,TradingAs,CalculatedName,Status',
              };
              this.merchantService.getList(payload).subscribe((res) => {
                if (res && res.length > 0) {
                  this.merchants = res;
                }
              });
            }
            if (this.readonly == true) {
              this.isFullsizeClick = false;
              this.canCheck = false;
              this.displaySelecteDocuments = false;
            }
            this.setup();
          });
        });
      }
    });
  }

  setup() {
    if (this.patientDocumentType && typeof this.patientDocumentType == 'string') {
      this._patientDocumentType = this.patientDocumentType.split(',');
    } else if (
      this.patientDocumentType &&
      Array.isArray(this.patientDocumentType) &&
      this.patientDocumentType.length > 0
    ) {
      this._patientDocumentType = this.patientDocumentType;
    }

    if (this.userDocumentType && typeof this.userDocumentType == 'string') {
      this._userDocumentType = this.userDocumentType.split(',');
    } else if (this.userDocumentType && Array.isArray(this.userDocumentType) && this.userDocumentType.length > 0) {
      this._userDocumentType = this.userDocumentType;
    }

    if (this.ICDocumentType && typeof this.ICDocumentType == 'string') {
      this._ICDocumentType = this.ICDocumentType.split(',');
    } else if (this.ICDocumentType && Array.isArray(this.ICDocumentType) && this.ICDocumentType.length > 0) {
      this._ICDocumentType = this.ICDocumentType;
    }

    if (this.naturePatientDocument && typeof this.naturePatientDocument == 'string') {
      this._naturePatientDocument = this.naturePatientDocument.split(',');
    } else if (
      this.naturePatientDocument &&
      Array.isArray(this.naturePatientDocument) &&
      this.naturePatientDocument.length > 0
    ) {
      this._naturePatientDocument = this.naturePatientDocument;
    }

    if (this.natureUserDocument && typeof this.natureUserDocument == 'string') {
      this._natureUserDocument = this.natureUserDocument.split(',');
    } else if (
      this.natureUserDocument &&
      Array.isArray(this.natureUserDocument) &&
      this.natureUserDocument.length > 0
    ) {
      this._natureUserDocument = this.natureUserDocument;
    }

    if (this.natureICDocument && typeof this.natureICDocument == 'string') {
      this._natureICDocument = this.natureICDocument.split(',');
    } else if (this.natureICDocument && Array.isArray(this.natureICDocument) && this.natureICDocument.length > 0) {
      this._natureICDocument = this.natureICDocument;
    }

    if (this.loadUserDocument == true) {
      this.checkUserDocuments();
    }

    if (this.loadICDocument == true) {
      this.checkICDocuments();
    }

    if (this.loadPatientDocument == true) {
      this.checkPatientDocuments();
    }

    if (this.displayUserDocumentType == true && this.isAdminOrPromoter == true) {
      const payload = {
        programAreaCode: null,
      };
      this.userDocumentTypes = [];
      this.utilService.getDocumentTypeList(payload).subscribe((res) => {
        if (res && res.length > 0) {
          for (let i = 0; i < res.length; i++) {
            if (res[i] && res[i]['Label'] && res[i]['TypeCode']) {
              let o;
              if (res[i]['TypeCode'] == 'SRVPST') {
                o = {
                  code: 'SRVPST',
                  label: 'Post Op Documents',
                };
              } else if (res[i]['TypeCode'] == 'SRVPRE') {
                o = {
                  code: 'SRVPRE',
                  label: 'Pre Op Documents',
                };
              } else if (res[i]['TypeCode'] == 'SRVSTC') {
                o = {
                  code: 'SRVSTC',
                  label: 'Procedure Images',
                };
              } else if (res[i]['TypeCode'] == 'SRVMKT') {
                o = {
                  code: 'SRVMKT',
                  label: 'Marketing Documents',
                };
              } else if (res[i]['TypeCode'] == 'SRVSPL') {
                o = {
                  code: 'SRVSPL',
                  label: 'Supplier Documents',
                };
              } else if (res[i]['TypeCode'] == 'SRV') {
                o = {
                  code: 'SRV',
                  label: 'Treatment Info',
                };
              } else {
                o = {
                  code: res[i]['TypeCode'],
                  label: res[i]['Label'],
                };
              }

              this.userDocumentTypes.push(o);
            }
          }
        }
      });
    }

    this.syncAll();

    if (this.displayICDocuments == true) {
      const payload = {
        merchant: this.merchantID,
        fields: 'ID,Label,NumberOfDocuments',
      };
      this.treatmentService.getTreatmentCategoryList(payload, this.isAdminOrPromoter).subscribe((res) => {
        this.categories = res;
        if (this.categoryIDSearch) {
          this.onSelectSimpleCategory(this.categoryIDSearch, true);
        }
      });
    }
  }

  clearPatientGroup() {
    this._patientDocumentType = null;
    this.patientDocumentType = null;
    this.patientDocumentLabel = null;
    this.dateFrom = null;
    this.dateTo = null;
    this.naturePatientDocument = null;
    this._naturePatientDocument = null;
  }

  clearUserGroup() {
    this._userDocumentType = null;
    this.userDocumentType = null;
    this.userDocumentLabel = null;
    this.dateFrom = null;
    this.dateTo = null;
    this.natureUserDocument = null;
    this._natureUserDocument = null;
  }

  clearGroup() {
    this._ICDocumentType = null;
    this.ICDocumentType = null;
    this.ICLabel = null;
    this.categoryID = null;
    this.brandID = null;
    this.productID = null;
    this.itemCode = null;
    this.brands = [];
    this.products = [];
    this.types = [];
    this.natureICDocument = null;
    this._natureICDocument = null;
  }

  syncUserParams() {
    this.userDocumentLabelSearch = this.userDocumentLabel || null;
    this.dateToSearch = this.dateTo || null;
    this.dateFromSearch = this.dateFrom || null;
    this.merchantIDSearch = this.merchantID || null;
    this.userOrderBySearch = this.userOrderBy || null;
    this._natureUserDocumentSearch = this._natureUserDocument || null;
    this._userDocumentTypeSearch = this._userDocumentType || null;
    this._isGallerySearch = this._isGallery || null;
  }

  syncICParams() {
    this.itemCodeSearch = this.itemCode || null;
    this.merchantIDSearch = this.merchantID || null;
    this.ICOrderBySearch = this.ICOrderBy || null;
    this.ICLabelSearch = this.ICLabel || null;
    this.productIDSearch = this.productID || null;
    this.typeIDSearch = this.typeID || null;
    this.categoryIDSearch = this.categoryID || null;
    this.brandIDSearch = this.brandID || null;
    this._natureICDocumentSearch = this._natureICDocument || null;
    this._ICDocumentTypeSearch = this._ICDocumentType || null;
  }

  syncPatientParams() {
    this.patientDocumentLabelSearch = this.patientDocumentLabel || null;
    this.dateToSearch = this.dateTo || null;
    this.dateFromSearch = this.dateFrom || null;
    this.selectedPatientSearch = this.selectedPatient || null;
    this.patientOrderBySearch = this.patientOrderBy || null;
    this._naturePatientDocumentSearch = this._naturePatientDocument || null;
    this._patientDocumentTypeSearch = this._patientDocumentType || null;
  }

  syncAll() {
    this.syncUserParams();
    this.syncICParams();
    this.syncPatientParams();
  }

  checkUserDocuments(loadMore = false) {
    if (loadMore == false) {
      this.userDocuments = [];
      this.userDocumentsDefault = [];
      this.userDocumentSection = 1;
    }

    let types;

    if (this._userDocumentType && this._userDocumentType.length > 0) {
      types = this._userDocumentType.join(',');
    }

    let natures;

    if (this._natureUserDocument && this._natureUserDocument.length > 0) {
      natures = this._natureUserDocument.join(',');
    }

    const payload = {
      section: this.userDocumentSection,
      limit: this.limit,
      partLabel: this.userDocumentLabel,
      dateTo: this.dateTo,
      dateFrom: this.dateFrom,
      merchantID: this.merchantID,
      orderBy: this.userOrderBy,
      operators: this.Operators,
      isGallery: this._isGallery,
      fields: 'ID',

      types,

      natures,
    };
    if (loadMore != true) {
      this.userDocumentLoading = true;
    }
    this.utilService.getDocumentList(payload, this.sessionType).subscribe((res) => {
      if (res && res.length > 0) {
        const _userDocuments = [];
        if (res.length >= this.limit) {
          this.loadMoreUserDocument = true;
        } else {
          this.loadMoreUserDocument = false;
        }

        for (let i = 0; i < res.length; i++) {
          if (res[i] && res[i]['ID']) {
            _userDocuments.push(res[i]['ID']);
          }
        }

        if (loadMore == true) {
          this.userDocuments = this.userDocuments.concat(_userDocuments);
          this.addUserFiles.emit(_userDocuments);
        } else {
          this.userDocumentsDefault = _userDocuments;
          this.userDocuments = _userDocuments;
        }
      } else {
        this.loadMoreUserDocument = false;
      }
      this.userDocumentFirstLoad = true;
      this.userDocumentLoading = false;
    });
  }

  checkPatientDocumentsClick(loadMore = false) {
    if (this.selectedPatient && this.selectedPatient.ID) {
      this.patientID = this.selectedPatient.ID;
      this.patient = this.selectedPatient;
      this.checkPatientDocuments(loadMore);
    }
  }

  checkPatientDocuments(loadMore = false) {
    if (loadMore == false) {
      this.patientDocuments = [];
      this.patientDocumentsDefault = [];
      this.patientDocumentSection = 1;
    }

    let types;

    if (this._patientDocumentType && this._patientDocumentType.length > 0) {
      types = this._patientDocumentType.join(',');
    }
    let natures;

    if (this._naturePatientDocument && this._naturePatientDocument.length > 0) {
      natures = this._naturePatientDocument.join(',');
    }

    if (this.patientID) {
      const payload = {
        section: this.patientDocumentSection,
        limit: this.limit,
        partLabel: this.patientDocumentLabel,
        dateTo: this.dateTo,
        dateFrom: this.dateFrom,
        patientID: this.patientID,
        orderBy: this.patientOrderBy,
        fields: 'ID',
        types,
        operators: this.Operators,
        natures,
      };
      if (loadMore != true) {
        this.patientDocumentLoading = true;
      }
      this.customerProspectService.getPatientDocumentList(payload, this.sessionType).subscribe((res) => {
        if (res && res.length > 0) {
          const _patientDocuments = [];
          if (res.length >= this.limit) {
            this.loadMorePatientDocument = true;
          } else {
            this.loadMorePatientDocument = false;
          }

          for (let i = 0; i < res.length; i++) {
            if (res[i] && res[i]['ID']) {
              _patientDocuments.push(res[i]['ID']);
            }
          }

          if (loadMore == true) {
            this.patientDocuments = this.patientDocuments.concat(_patientDocuments);
            this.addPatientFiles.emit(_patientDocuments);
          } else {
            this.patientDocumentsDefault = _patientDocuments;
            this.patientDocuments = _patientDocuments;
          }
        } else {
          this.loadMorePatientDocument = false;
        }
        this.patientDocumentFirstLoad = true;
        this.patientDocumentLoading = false;
      });
    }
  }

  getDateRange(e) {
    this.dateToSearch = e.endDate || null;
    this.dateFromSearch = e.startDate || null;
  }

  changeDocumentType(e) {
    if (
      this._userDocumentTypeSearch &&
      (this._userDocumentTypeSearch == 'SRVSTC' || this._userDocumentTypeSearch == 'TRP')
    ) {
      this._isGallery = this.isGallery;
    } else if (this._userDocumentTypeSearch) {
      this._isGallery = null;
    }
  }

  loadMorePatientDocumentEvent() {
    this.patientDocumentSection = this.patientDocumentSection + 1;
    this.checkPatientDocuments(true);
  }

  loadMoreUserDocumentEvent() {
    this.userDocumentSection = this.userDocumentSection + 1;
    this.checkUserDocuments(true);
  }

  loadMoreICDocumentEvent() {
    this.ICDocumentSection = this.ICDocumentSection + 1;
    this.checkICDocuments(true);
  }

  checkICDocuments(loadMore = false) {
    if (loadMore == false) {
      this.ICDocuments = [];
      this.ICDocumentsDefault = [];
      this.ICDocumentSection = 1;
    }

    let types;

    if (this._ICDocumentType && this._ICDocumentType.length > 0) {
      types = this._ICDocumentType.join(',');
    }

    let natures;

    if (this._natureICDocument && this._natureICDocument.length > 0) {
      natures = this._natureICDocument.join(',');
    }

    const payload: any = {
      section: this.ICDocumentSection,
      includeOmmitted: false,
      itemCode: this.itemCode,
      limit: this.limitIC,
      merchant: this.merchantID,
      merchantID: this.merchantID,
      orderBy: this.ICOrderBy,
      fields: 'Document_key',
      documentTypes: types,
      partLabel: this.ICLabel,
      productID: this.productID,
      includeInherited: this.includeInherited,
      isActive: true,
      typeID: this.typeID,
      categoryID: this.categoryID,
      brandID: this.brandID,
      isUniqueDocument: this.isUniqueDocument,
      operators: this.Operators,
      natures,
    };

    if (loadMore != true) {
      this.ICDocumentLoading = true;
    }

    let notDocumentIDs = [];
    if (this.ICDocuments && this.ICDocuments.length > 0) {
      notDocumentIDs = _.uniq(this.ICDocuments);
    }

    payload.notDocumentIDs = notDocumentIDs;

    this.treatmentService.getTreatmentDocumentListPOST(payload, this.isAdminOrPromoter).subscribe((_res) => {
      const res = _res['data'];

      if (_res && _res.section) {
        this.ICDocumentSection = _res.section;
      }
      if (res && res.length > 0) {
        const _userDocuments = [];

        if (res.length >= this.limitIC) {
          this.loadMoreICDocument = true;
        } else {
          this.loadMoreICDocument = false;
        }

        for (let i = 0; i < res.length; i++) {
          if (res[i] && res[i]['Document_key']) {
            _userDocuments.push(res[i]['Document_key']);
          }
        }

        if (loadMore == true) {
          this.ICDocuments = this.ICDocuments.concat(_userDocuments);
          this.addICFiles.emit(_userDocuments);
        } else {
          this.ICDocumentsDefault = _userDocuments;
          this.ICDocuments = _userDocuments;
        }
      } else {
        this.loadMoreICDocument = false;
      }
      this.ICDocumentFirstLoad = true;
      this.ICDocumentLoading = false;
    });
  }

  onSelectSimpleCategory(simpleCategory, setup = false) {
    if (simpleCategory) {
      this.brands = [];
      this.types = [];
      this.products = [];
      this.productIDSearch = null;
      this.brandIDSearch = null;
      if (setup != true) {
        this.typeIDSearch = null;
      }
      const payload = {
        merchant: this.merchantIDSearch,
        fields: 'ID,Label,NumberOfDocuments',
        categoryID: simpleCategory,
      };
      this.treatmentService.getTreatmentTypeList(payload).subscribe((res) => {
        this.types = res;
        if (setup == true && this.typeIDSearch) {
          this.onSelectSimpleType(this.typeIDSearch, true);
        }
      });
    }
  }

  onSelectSimpleType(simpleType, setup = false) {
    if (simpleType) {
      this.brands = [];
      this.products = [];
      this.brandIDSearch = null;
      this.productIDSearch = null;
      let payload: any = {
        isActive: true,
        fields: 'ID,Label,NumberOfDocuments',
        typeID: simpleType,
        isNullProduct: false,
        isNullBrand: false,
      };
      if (this.isAdminOrPromoter == true) {
        payload = {
          merchant: this.merchantIDSearch,
          typeID: simpleType,
          fields: 'ID,Label,NumberOfDocuments',
          isNullProduct: false,
          isNullBrand: false,
        };
      }
      this.treatmentService.getTreatmentBrandList(payload, this.isAdminOrPromoter).subscribe((res) => {
        if (res && res.length > 0) {
          this.brands = res;
          if (setup == true && this.brandIDSearch) {
            this.onSelectSimpleBrand(this.brandIDSearch, true);
          }
        }
      });
    }
  }

  onSelectSimpleBrand(simpleBrand, setup = false) {
    if (simpleBrand) {
      this.products = [];
      this.productIDSearch = null;
      let payload: any = {
        isActive: true,
        fields: 'ID,Label,NumberOfDocuments',
        brandID: simpleBrand,
        isNullProduct: false,
        isNullBrand: false,
      };
      if (this.isAdminOrPromoter == true) {
        payload = {
          merchant: this.merchantIDSearch,
          fields: 'ID,Label,NumberOfDocuments',
          brandID: simpleBrand,
          isNullProduct: false,
          isNullBrand: false,
        };
      }
      this.treatmentService.getTreatmentProductList(payload, this.isAdminOrPromoter).subscribe((res) => {
        if (res && res.length > 0) {
          this.products = res;
        }
      });
    }
  }

  close() {
    this.closeModal.emit(true);
  }

  getCheckedDocumentEvent(e) {
    if (e && e.ID) {
      const index = this.documents.indexOf(e.ID);
      if (index == -1) {
        this.documents.push(e.ID);
      }
      this.getCheckedDocument.emit(e.ID);
    }
  }

  getUncheckedDocumentEvent(e) {
    if (e && e.ID) {
      const index = this.documents.indexOf(e.ID);
      if (index != -1) {
        this.documents.splice(index, 1);
      }
      this.getUncheckedDocument.emit(e.ID);
    }
  }

  SendResult() {
    this.getDocuments.emit(this.documents);
  }

  getDateTo(e) {
    this.dateTo = e;
  }

  getDateFrom(e) {
    this.dateFrom = e;
  }

  checkSelectedDocument() {
    this.selectedView = 'selected';
  }

  checkTitle() {
    if (this.selectedView == 'system') {
      this.title = 'Gallery';
      this.description =
        'View documents, images and videos supplied by Smile Right or uploaded by the practice which can be used in treatment planning';
    } else if (this.selectedView == 'patient') {
      this.title = 'Patient Documents';
      this.description = 'View Patient Documents ,Select the documents you wish to add to your treatment plan';
    } else {
      this.title = 'Practice, Patient Images & Documents';
      this.description = 'Select the documents you wish to add to your treatment plan';
    }
  }

  removeFromGallery(id) {
    if (id) {
      const payload = {
        fields: 'ID',
        isGallery: false,
      };

      this.utilService.editEpdocument(id, payload, this.isAdminOrPromoter).subscribe((res) => {
        if (res) {
          this.deleteUserDocumentEvent.emit(id);
          NotifyAppComponent.displayToast(
            'success',
            'Successful Operation',
            'You have successfully edited a document!'
          );
        }
      });
    }
  }

  removePatientDoc(id) {
    if (id) {
      this.customerProspectService.removePatientDocument(id, this.isAdminOrPromoter).subscribe((res) => {
        if (res) {
          this.deletePatientDocumentEvent.emit(id);
          NotifyAppComponent.displayToast(
            'success',
            'Successful Operation',
            'You have successfully removed the document!'
          );
        }
      });
    }
  }

  addUserDocument() {
    const ref = RootAppComponent.dialog.open(EPDocumentCreateEditComponent, {
      data: {
        type: this.userDocumentType,
        merchantID: this.merchantID,
        isGallery: true,
        hideCheckBoxes: true,
      },
      width: '750px',
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.result.subscribe((res) => {
      if (res && res.ID) {
        this.addUserFiles.emit([res.ID]);

        if (this.canCheck === true) {
          this.documents.push(res.ID);
        }
        ref.close();
      }
    });
  }

  addPatientDocument() {
    if (this.selectedPatient && this.selectedPatient['ID']) {
      const ref = RootAppComponent.dialog.open(PatientDocumentCreateModalComponent, {
        data: {
          nature: this.naturePatientDocument,
          patientID: this.selectedPatient['ID'],
        },
        width: '600px',
      });
      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.result.subscribe((res) => {
        if (res && res.ID) {
          this.addPatientFiles.emit([res.ID]);

          ref.close();
        }
      });
    }
  }

  canPushIC = function (d) {
    if (this.productID && d['TreatmentProduct_key'].indexOf(this.productID) === -1) {
      return false;
    }
    if (this.typeID && d['TreatmentType_key'].indexOf(this.typeID) === -1) {
      return false;
    }
    if (this.brandID && d['TreatmentBrand_key'].indexOf(this.brandID) === -1) {
      return false;
    }
    if (this.categoryID && d['TreatmentCategory_key'].indexOf(this.categoryID) === -1) {
      return false;
    }

    if (this.ICDocumentType && d['Document.Type.Code'] != this.ICDocumentType) {
      return false;
    }

    return true;
  };

  addICDocument() {
    const ref = RootAppComponent.dialog.open(TreatmentDocumentCreateComponent, {
      width: '750px',
      data: {
        productID: this.productID,
        typeID: this.typeID,
        categoryID: this.categoryID,
        brandID: this.brandID,
        group: this.ICDocumentType,
      },
    });

    ref.componentInstance.create.subscribe((res) => {
      if (res && res.Document_key) {
        const canAdd = this.canPushIC(res);

        if (canAdd == true) {
          this.addICFiles.emit([res.Document_key]);
        }

        ref.close();
      }
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  changeItemCode() {
    if (this.itemCode) {
      const payload = {
        itemCode: this.itemCode,
        limit: 2,
        merchantID: this.merchantID,
        fields: 'Category_key,Type_key,Brand_key,ID,Brand.Label,Type.Label,Category.Label,Category.Colour,Label',
        isNullProduct: true,
      };

      this.treatmentService.getTreatmentProductList(payload, this.isAdminOrPromoter).subscribe((res) => {
        if (res && res.length == 1) {
          if (res[0]) {
            const _ids = res[0]['ID'].split(':');

            let _merchantID;

            if (_ids && _ids.length > 1) {
              _merchantID = _ids[1];
            }
            this.categoryID = res[0]['Category_key'];
            this.typeID = res[0]['Type_key'];

            if (_merchantID) {
              this.brandID = res[0]['Brand_key'] + ':' + _merchantID;
            } else {
              this.brandID = res[0]['Brand_key'];
            }

            this.productID = res[0]['ID'];

            this.onSelectSimpleCategory(this.categoryID, true);
          }
        }
        if (res && res.length == 2) {
          if (res[0]) {
            const _ids = res[0]['ID'].split(':');

            let _merchantID;

            if (_ids && _ids.length > 1) {
              _merchantID = _ids[1];
            }
            this.categoryID = res[0]['Category_key'];
            this.typeID = res[0]['Type_key'];

            this.brandID = null;

            this.productID = null;

            this.onSelectSimpleCategory(this.categoryID, true);
          }
        } else if (!res || res.length <= 0) {
          NotifyAppComponent.displayToast('warning', 'Not Found', 'Product Code Not found');

          this.itemCode = null;
          this.typeID = null;
          this.brandID = null;
          this.productID = null;
        }
      });
    }
  }

  patientLookup() {
    const ref = RootAppComponent.dialog.open(InvitationLookupComponent, {
      data: {
        merchantID: this.merchantID,
        useStrict: true,
      },
      width: '600px',
    });

    ref.componentInstance.patientChat.subscribe((res) => {
      if (res && res.cardID) {
        if (res.isPromoterOrAdmin == true) {
          const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true,
            },
            panelClass: 'noCard',
            width: '500px',
          });
          ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });

          ref2.componentInstance.getResult.subscribe((data) => {
            if (data) {
              const ref3 = RootAppComponent.dialog.open(SmsConversationComponent, {
                data,
                panelClass: 'bigger-screen',
                width: '70%',
                height: '95vh',
              });
              const sub = ref3.componentInstance.close.subscribe((data) => {
                ref3.close();
              });
            }

            ref2.close();
          });
        } else {
          const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true,
            },
            panelClass: 'bigger-screen',
            width: '70%',
            height: '95vh',
          });
          const sub = ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });
        }
      }
    });
    ref.componentInstance.getResult.subscribe((res) => {
      if (res && res.ID) {
        this.selectedPatientSearch = res;
        this.profileLink = this.customerProspectService.getProfilePicStreamLink(this.selectedPatientSearch['ID']);

        this.checkPatientDocumentsClickSearch();
        ref.close();
      }
    });
    ref.componentInstance.detailedView.subscribe((res) => {
      ref.close();
      ref.afterClosed().subscribe((r) => {
        this.close();
      });
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  viewType(id) {
    const ref = RootAppComponent.dialog.open(TreatmentTypeViewComponent, {
      data: {
        typeID: id,
        isGlobal: true,
      },

      width: '700px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewBrand(id) {
    const ref = RootAppComponent.dialog.open(TreatmentBrandViewComponent, {
      data: {
        brandID: id,
        isGlobal: true,
      },

      width: '700px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewCategory(id) {
    const ref = RootAppComponent.dialog.open(TreatmentCategoryViewComponent, {
      data: {
        categoryID: id,
      },

      width: '700px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewProduct(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(TreatmentProductViewComponent, {
        data: {
          productID: id,
          isGlobal: true,
        },

        width: '800px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  getUserDocumentsLength(d) {
    this.userDocumentsLength = d || 0;
  }

  getPatientDocumentsLength(d) {
    this.patientDocumentsLength = d || 0;
  }

  getICDocumentsLength(d) {
    this.ICDocumentsLength = d || 0;
  }

  checkUserDocumentsSearch() {
    this.userDocumentLabel = this.userDocumentLabelSearch || null;
    this.dateTo = this.dateToSearch || null;
    this.dateFrom = this.dateFromSearch || null;
    this.merchantID = this.merchantIDSearch || null;
    this.userOrderBy = this.userOrderBySearch || null;
    this._natureUserDocument = this._natureUserDocumentSearch || null;
    this._userDocumentType = this._userDocumentTypeSearch || null;

    this._isGallery = this._isGallerySearch || null;

    this.checkUserDocuments();
  }

  checkICDocumentsSearch() {
    this.itemCode = this.itemCodeSearch || null;
    this.merchantID = this.merchantIDSearch || null;
    this.ICOrderBy = this.ICOrderBySearch || null;
    this.ICLabel = this.ICLabelSearch || null;
    this.productID = this.productIDSearch || null;
    this.typeID = this.typeIDSearch || null;
    this.categoryID = this.categoryIDSearch || null;
    this.brandID = this.brandIDSearch || null;
    this._natureICDocument = this._natureICDocumentSearch || null;
    this._ICDocumentType = this._ICDocumentTypeSearch || null;

    this._isGallery = this._isGallerySearch || null;

    this.checkICDocuments();
  }

  checkPatientDocumentsClickSearch() {
    this.patientDocumentLabel = this.patientDocumentLabelSearch || null;
    this.dateTo = this.dateToSearch || null;
    this.dateFrom = this.dateFromSearch || null;
    this.selectedPatient = this.selectedPatientSearch || null;
    this.patientOrderBy = this.patientOrderBySearch || null;
    this._naturePatientDocument = this._naturePatientDocumentSearch || null;
    this._patientDocumentType = this._patientDocumentTypeSearch || null;

    this._isGallery = this._isGallerySearch || null;
    this.checkPatientDocumentsClick();
  }
}
