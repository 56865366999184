import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PhysicalTemplateListComponent } from '../physical-template-list/physical-template-list.component';
import { TemplateCreateEditComponent } from '../template-create-edit/template-create-edit.component';
import { TemplateListComponent } from '../template-list/template-list.component';
import { TemplatePictureViewComponent } from '../template-picture-view/template-picture-view.component';
import { TemplatePurposeCreateEditComponent } from '../template-purpose-create-edit/template-purpose-create-edit.component';

const routes: Routes = [
  {
    path: 'template-picture-view',
    component: TemplatePictureViewComponent,
  },
  {
    path: 'template-list',
    component: TemplateListComponent,
  },
  {
    path: 'physical-template-list',
    component: PhysicalTemplateListComponent,
  },
  {
    path: 'template-create-edit',
    component: TemplateCreateEditComponent,
  },

  {
    path: 'template-purpose-create-edit',
    component: TemplatePurposeCreateEditComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TemplateRoutingModule {}
