import { NgModule } from '@angular/core';
import { HelperModule } from '../../core/helper/helper.module';
import { SharedModule } from '../../shared/shared.module';
import { OperatorModule } from '../operator/operator.module';
import { TreatmentModule } from '../treatment/treatment.module';
import { DrugListOverviewComponent } from './drug-list-overview/drug-list-overview.component';
import { DrugsListComponent } from './drug-list/drug-list.component';
import { DrugLookupComponent } from './drug-lookup/drug-lookup.component';
import { DrugsCreateEditCustomerComponent } from './drugs-create-edit-customer/drugs-create-edit-customer.component';
import { DrugsCreateEditComponent } from './drugs-create-edit/drugs-create-edit.component';
import { DrugsCustomerViewComponent } from './drugs-customer-view/drugs-customer-view.component';
import { DrugsImageComponent } from './drugs-image/drugs-image.component';
import { DrugsThumbnailComponent } from './drugs-thumbnail/drugs-thumbnail.component';
import { DrugsViewComponent } from './drugs-view/drugs-view.component';
import { MedicationCreateCustomerComponent } from './medication-create-customer/medication-create-customer.component';
import { MedicationListComponent } from './medication-list/medication-list.component';
import { MedicationViewComponent } from './medication-view/medication-view.component';
import { DrugsRoutingModule } from './shared/drugs-routing.module';
import { DrugsService } from './shared/drugs.service';

@NgModule({
  imports: [SharedModule, TreatmentModule, OperatorModule, HelperModule, DrugsRoutingModule],
  providers: [DrugsService],
  declarations: [
    DrugsListComponent,
    MedicationCreateCustomerComponent,
    DrugsCustomerViewComponent,
    DrugsThumbnailComponent,
    DrugListOverviewComponent,
    DrugsImageComponent,
    DrugsViewComponent,
    DrugsCreateEditCustomerComponent,
    DrugsCreateEditComponent,
    DrugLookupComponent,
    MedicationViewComponent,
    MedicationListComponent,
  ],
  exports: [
    DrugsListComponent,
    MedicationCreateCustomerComponent,
    DrugsCustomerViewComponent,
    DrugsThumbnailComponent,
    DrugListOverviewComponent,
    DrugsImageComponent,
    DrugsViewComponent,
    DrugsCreateEditCustomerComponent,
    DrugsCreateEditComponent,
    DrugLookupComponent,
    MedicationViewComponent,
    MedicationListComponent,
  ],
})
export class DrugsModule {}
