import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, Inject, Input, OnInit, Optional, ViewContainerRef } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-details-card-verification',
  templateUrl: './details-card-verification.component.html',
  styleUrls: ['./details-card-verification.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('mat-card', style({ opacity: 0 }), { optional: true }),
        query(
          'mat-card',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: '0' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('mat-card', style({ opacity: 1 }), { optional: true }),
        query(
          'mat-card',
          stagger('100ms', [
            animate(
              '0.2s ease-out',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: '0' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class DetailsCardVerificationComponent implements OnInit {
  @Input() customerID;

  displayedColumnsCC = ['InitialDate', 'FinalDate', 'InitialResult', 'FinalResult', 'Product', 'ContractID', 'Actions'];
  displayedColumnsCCC = [
    'InitialDate',
    'FinalDate',
    'InitialResult',
    'FinalResult',
    'Product',
    'ContractID',
    'Actions',
  ];
  displayedColumnsIDC = [
    'InitialDate',
    'FinalDate',
    'InitialResult',
    'FinalResult',
    'Product',
    'ContractID',
    'Actions',
  ];
  displayedColumnsID = ['IDType', 'Current', 'Locked', 'IDNumber', 'Issued', 'Expiry', 'DMS', 'Actions'];
  constructor(
    public viewContainerRef: ViewContainerRef,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.customerID) {
        this.customerID = data.customerID;
      }
    }
  }

  ngOnInit() {}
}
