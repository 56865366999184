/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tags-view.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./tags-view.component";
import * as i8 from "../shared/tags.service";
import * as i9 from "@angular/router";
import * as i10 from "../../../core/authentication/shared/authentication.service";
var styles_TagsViewComponent = [i0.styles];
var RenderType_TagsViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TagsViewComponent, data: {} });
export { RenderType_TagsViewComponent as RenderType_TagsViewComponent };
function View_TagsViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [["class", "mt"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Tag Description:"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tag.Description; _ck(_v, 4, 0, currVal_0); }); }
function View_TagsViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "well tag"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Tag Label:"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h3", [["class", "sr-title rm-mb"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagsViewComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "full-width mt"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["class", "pull-left"], ["color", "primary"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goback() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Back"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.tag.Description; _ck(_v, 7, 0, currVal_1); var currVal_4 = "primary"; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tag.Label; _ck(_v, 5, 0, currVal_0); var currVal_2 = (i1.ɵnov(_v, 10).disabled || null); var currVal_3 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_2, currVal_3); }); }
export function View_TagsViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagsViewComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tag; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TagsViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tags-view", [], null, null, null, View_TagsViewComponent_0, RenderType_TagsViewComponent)), i1.ɵdid(1, 114688, null, 0, i7.TagsViewComponent, [i8.TagsService, i9.ActivatedRoute, i10.AuthenticationService, i2.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TagsViewComponentNgFactory = i1.ɵccf("app-tags-view", i7.TagsViewComponent, View_TagsViewComponent_Host_0, { tagID: "tagID" }, {}, []);
export { TagsViewComponentNgFactory as TagsViewComponentNgFactory };
