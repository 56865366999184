<div class="row clearfix flex full-width" *ngIf="contract && !capacityDetails && isDisplayFull == false&& loading==false">


  <app-empty-list-message [title]="'Capacity Check'" style=" margin: auto !important;"
  [message]="'   There is no data available to create a capacity check.'">
</app-empty-list-message>
</div>

<div class="row clearfix" *ngIf="contract && capacityDetails && uploadedPCIDetails && isDisplayFull == false&& loading==false">
  <div class="row clearfix flex">
    <mat-card class="clearfix full-width">
      <div class="colour-stripe"></div>

      <h3 class="sr-title rm-m">Assessment Figures (Weekly)</h3>
      <hr/>

      <!-- header labels if operator modified -->
      <div class="row clearfix flex" *ngIf="isModified == true">
        <div class="row flex labelMargin full-width border-bottom">
          <p class="small valueMargin full-width">&nbsp;</p>
          <div class="modification-col">
            <p class="small valueMargin">
              {{ "KEYWORD.patient" | translate | titlecase }}
            </p>
            <p class="small valueMargin">Operator</p>
          </div>
        </div>
      </div>

      <div class="row clearfix flex">
        <div class="row clearfix full-width">
          <div class="row flex labelMargin">
            <p class="bold full-width">Income</p>

            <div class="modification-col">
              <!-- patient data -->
              <p
                class="valueMargin"
                [class.red]="capacityDetails['Input.IncomePerWeek'] <= 0"
                [class.green]="capacityDetails['Input.IncomePerWeek'] > 0"
              >
                {{ capacityDetails['Input.IncomePerWeek'] | customCurrency }}
              </p>

              <!-- operator modification -->
              <p
                class="valueMargin"
                *ngIf="
                  isModifiedData(capacityDetails['Input.IncomePerWeek'], uploadedPCIDetails['IncomePerWeek']) == true
                "
              >
                {{ uploadedPCIDetails['IncomePerWeek'] | customCurrency }}
              </p>
              <p
                class="valueMargin"
                *ngIf="
                  isModifiedData(capacityDetails['Input.IncomePerWeek'], uploadedPCIDetails['IncomePerWeek']) == false
                "
              >
                $0.00
              </p>
            </div>
          </div>
          <div class="row flex labelMargin">
            <p class="bold full-width"><span class="italic op06 chip">Less</span> Expenses Used</p>
            <div class="modification-col">
              <!-- patient data -->
              <p class="valueMargin" [class.red]="expensesUsed < 0">{{ expensesUsed | customCurrency }}</p>

              <!-- operator modification -->
              <p
                class="valueMargin"
                *ngIf="isModifiedData(expensesUsed, uploadedPCIDetails['ExpensesPerWeek']) == true"
              >
                {{ uploadedPCIDetails['ExpensesPerWeek'] | customCurrency }}
              </p>
              <p
                class="valueMargin"
                *ngIf="isModifiedData(expensesUsed, uploadedPCIDetails['ExpensesPerWeek']) == false"
              >
                $0.00
              </p>
            </div>
          </div>
          <div class="row flex labelMargin">
            <p class="bold full-width">Net Income</p>
            <div class="modification-col">
              <!-- patient data -->
              <p class="valueMargin" [class.red]="netIncome < 0" [class.green]="netIncome > 0">
                {{ netIncome | customCurrency }}
              </p>

              <!-- operator modification -->
              <p class="valueMargin" *ngIf="isModifiedData(netIncome, pciNetIncome) == true">
                {{ pciNetIncome | customCurrency }}
              </p>
              <p class="valueMargin" *ngIf="isModifiedData(netIncome, pciNetIncome) == false">$0.00</p>
            </div>
          </div>
          <div class="row flex labelMargin rm-bm">
            <p class="bold full-width"><span class="italic op06 chip">Less</span> Payments Proposed</p>
            <div class="modification-col">
              <!-- patient data -->
              <p class="valueMargin" [class.red]="capacityDetails['Input.ProposedPayPerWeek'] < 0">
                {{ capacityDetails['Input.ProposedPayPerWeek'] | customCurrency }}
              </p>

              <!-- operator modification -->
              <p class="valueMargin" *ngIf="isModified == true">$0.00</p>
            </div>
          </div>
          <div class="row flex labelMargin grey">
            <div class="full-width">
              <p class="bold larger rm-mb">Remaining Income</p>
              <p class="small rm-mt mb">Per week</p>
            </div>

            <div class="modification-col">
              <!-- patient data -->
              <p class="valueMargin bold larger" [class.red]="remainingIncome < 0" [class.green]="remainingIncome > 0">
                {{ remainingIncome | customCurrency }}
              </p>

              <!-- operator modification -->
              <p class="valueMargin" *ngIf="isModifiedData(remainingIncome, pciRemainingIncome) == true">
                {{ pciRemainingIncome | customCurrency }}
              </p>
              <p class="valueMargin" *ngIf="isModifiedData(remainingIncome, pciRemainingIncome) == false">$0.00</p>
            </div>
          </div>
          <div class="row flex labelMargin">
            <p class="bold full-width">Surplus Factor</p>
            <div class="modification-col">
              <!-- patient data -->
              <p
                class="valueMargin"
                [class.red]="capacityDetails['SurplusFactor'] < 0"
                [class.green]="capacityDetails['SurplusFactor'] > 0"
              >
                {{ capacityDetails['SurplusFactor'] | number }}
              </p>

              <!-- operator modification -->
              <p class="valueMargin" *ngIf="isModified == true">$0.00</p>
            </div>
          </div>
          <div class="row flex labelMargin">
            <p class="bold full-width">Debt Vs Income Ratio</p>
            <div class="modification-col">
              <!-- patient data -->
              <p
                class="valueMargin"
                [class.red]="capacityDetails['DebtVsIncomeRatio'] < 0"
                [class.green]="capacityDetails['DebtVsIncomeRatio'] > 0"
              >
                {{ capacityDetails['DebtVsIncomeRatio'] | number }}
              </p>

              <!-- operator modification -->
              <p class="valueMargin" *ngIf="isModified == true">$0.00</p>
            </div>
          </div>
          <div class="row flex labelMargin">
            <p class="bold full-width">Annual Income</p>
            <div class="modification-col">
              <!-- patient data -->
              <p class="valueMargin" [class.red]="annualIncome <= 0" [class.green]="annualIncome > 0">
                {{ annualIncome | customCurrency }}
              </p>

              <!-- operator modification -->
              <p class="valueMargin" *ngIf="isModifiedData(annualIncome, pciAnnualIncome) == true">
                {{ pciAnnualIncome | customCurrency }}
              </p>
              <p class="valueMargin" *ngIf="isModifiedData(annualIncome, pciAnnualIncome) == false">$0.00</p>
            </div>
          </div>
        </div>

        <!-- column if modified -->
        <!-- <div class="row clearfix modified-column" *ngIf="isModified">

          <div class="row flex labelMargin">
            <p class="valueMargin" [class.red]="capacityDetails['Input.IncomePerWeek'] <= 0"
              [class.green]="capacityDetails['Input.IncomePerWeek'] > 0">
              {{capacityDetails['Input.IncomePerWeek'] | customCurrency}}</p>

          </div>
          <div class="row flex labelMargin">
            <p class="valueMargin"
              [class.red]="(toNumber(capacityDetails['Input.ExpensesPerWeek.Used']) + toNumber(capacityDetails['OtherExpenses'])) < 0">{{(toNumber(capacityDetails['Input.ExpensesPerWeek.Used']) + toNumber(capacityDetails['OtherExpenses']))
                  | customCurrency}}</p>

          </div>
          <div class="row flex labelMargin">

            <p class="valueMargin" [class.red]="(toNumber(capacityDetails['Input.IncomePerWeek']) - (toNumber(capacityDetails['Input.ExpensesPerWeek.Used'])
            + toNumber(capacityDetails['OtherExpenses']))) < 0" [class.green]="(toNumber(capacityDetails['Input.IncomePerWeek']) - (toNumber(capacityDetails['Input.ExpensesPerWeek.Used'])
            + toNumber(capacityDetails['OtherExpenses']))) > 0">{{toNumber(capacityDetails['Input.IncomePerWeek']) - (toNumber(capacityDetails['Input.ExpensesPerWeek.Used'])
                  + toNumber(capacityDetails['OtherExpenses'])) | customCurrency}}</p>

          </div>

          <div class="row flex labelMargin rm-bm">
            <p class="valueMargin" [class.red]="capacityDetails['Input.ProposedPayPerWeek'] < 0">
              {{capacityDetails['Input.ProposedPayPerWeek'] | customCurrency}}</p>
          </div>

          <div class="row flex labelMargin grey">
            <p class="valueMargin bold larger" [class.red]="(toNumber(capacityDetails['Input.IncomePerWeek']) - (toNumber(capacityDetails['Input.ExpensesPerWeek.Used'])
            + toNumber(capacityDetails['OtherExpenses'])) - toNumber(capacityDetails['Input.ProposedPayPerWeek'])) < 0"
              [class.green]="(toNumber(capacityDetails['Input.IncomePerWeek']) - (toNumber(capacityDetails['Input.ExpensesPerWeek.Used'])
            + toNumber(capacityDetails['OtherExpenses'])) - toNumber(capacityDetails['Input.ProposedPayPerWeek'])) > 0">
              {{toNumber(capacityDetails['Input.IncomePerWeek']) - (toNumber(capacityDetails['Input.ExpensesPerWeek.Used'])
									+ toNumber(capacityDetails['OtherExpenses'])) - toNumber(capacityDetails['Input.ProposedPayPerWeek']) | customCurrency}}
            </p>
          </div>

          <div class="row flex labelMargin">
            <p class="valueMargin" [class.red]="capacityDetails['SurplusFactor'] < 0"
              [class.green]="capacityDetails['SurplusFactor'] > 0">
              {{capacityDetails['SurplusFactor'] | number}}</p>
          </div>

          <div class="row flex labelMargin">
            <p class="valueMargin" [class.red]="capacityDetails['DebtVsIncomeRatio'] < 0"
              [class.green]="capacityDetails['DebtVsIncomeRatio'] > 0">
              {{capacityDetails['DebtVsIncomeRatio'] | number}}</p>
          </div>

          <div class="row flex labelMargin">

            <p class="valueMargin" [class.red]="(capacityDetails['Input.IncomePerWeek'] * 52) <= 0"
              [class.green]="(capacityDetails['Input.IncomePerWeek'] * 52) > 0">
              {{capacityDetails['Input.IncomePerWeek'] * 52 | customCurrency}}</p>

          </div>

        </div> -->
      </div>
    </mat-card>

    <mat-card class="clearfix full-width">
      <div class="colour-stripe"></div>

      <h3 class="sr-title rm-m">Expenses Figures (Weekly)</h3>
      <hr/>

      <!-- header labels if operator modified -->
      <div class="row clearfix flex" *ngIf="isModified == true">
        <div class="row flex labelMargin full-width border-bottom">
          <p class="small valueMargin full-width">&nbsp;</p>
          <div class="modification-col">
            <p class="small valueMargin">
              {{ "KEYWORD.patient" | translate | titlecase }}
            </p>
            <p class="small valueMargin">Operator</p>
          </div>
        </div>
      </div>

      <div class="row clearfix">
        <div class="row flex labelMargin">
          <p class="bold full-width">Living Expenses Declared</p>
          <div class="modification-col">
            <!-- patient data -->
            <p class="valueMargin">{{ capacityDetails['Input.ExpensesPerWeek.Declared'] | customCurrency }}</p>

            <!-- operator modification -->
            <p class="valueMargin" *ngIf="isModified == true">$0.00</p>
          </div>
        </div>

        <div class="row flex labelMargin">
          <p class="bold full-width">HEM Living Expenses</p>
          <div class="modification-col">
            <!-- patient data -->
            <p class="valueMargin">{{ capacityDetails['Input.ExpensesPerWeek.HEM'] | customCurrency }}</p>

            <!-- operator modification -->
            <p class="valueMargin" *ngIf="isModified == true">$0.00</p>
          </div>
        </div>

        <div class="row flex labelMargin">
          <p class="bold full-width">Living Expenses Used</p>
          <div class="modification-col">
            <!-- patient data -->
            <p class="valueMargin">{{ capacityDetails['Input.ExpensesPerWeek.Used'] | customCurrency }}</p>

            <!-- operator modification -->
            <p class="valueMargin" *ngIf="isModified == true">$0.00</p>
          </div>
        </div>

        <div class="row flex labelMargin rm-bm">
          <p class="bold full-width">Other Expenses</p>
          <div class="modification-col">
            <!-- patient data -->
            <p class="valueMargin">{{ capacityDetails['OtherExpenses'] | customCurrency }}</p>

            <!-- operator modification -->
            <p class="valueMargin" *ngIf="isModified == true">$0.00</p>
          </div>
        </div>

        <div class="row flex labelMargin grey">
          <div class="full-width">
            <p class="bold larger rm-mb">Total Expenses</p>
            <p class="small rm-mt mb">Per week</p>
          </div>
          <div class="modification-col">
            <!-- patient data -->
            <p class="valueMargin larger bold">
              {{
              toNumber(capacityDetails['Input.ExpensesPerWeek.Used']) + toNumber(capacityDetails['OtherExpenses'])
                | customCurrency
              }}
            </p>

            <!-- operator modification -->
            <p class="valueMargin" *ngIf="isModifiedData(expensesUsed, uploadedPCIDetails['ExpensesPerWeek']) == true">
              {{ uploadedPCIDetails['ExpensesPerWeek'] | customCurrency }}
            </p>
            <p class="valueMargin" *ngIf="isModifiedData(expensesUsed, uploadedPCIDetails['ExpensesPerWeek']) == false">
              $0.00
            </p>
          </div>
        </div>

        <div class="row flex labelMargin">
          <p class="bold full-width">Annual Expenses (declared)</p>
          <div class="modification-col">
            <!-- patient data -->
            <p class="valueMargin" [class.red]="capacityDetails['Input.ExpensesPerWeek.Declared'] * 52 <= 0">
              {{ capacityDetails['Input.ExpensesPerWeek.Declared'] * 52 | customCurrency }}
            </p>

            <!-- operator modification -->
            <p class="valueMargin" *ngIf="isModified == true">$0.00</p>
          </div>
        </div>

        <div class="row flex labelMargin">
          <p class="bold full-width">Annual Expenses (used)</p>
          <div class="modification-col">
            <!-- patient data -->
            <p class="valueMargin" [class.red]="capacityDetails['AnnualExpensesUsed'] <= 0">
              {{ capacityDetails['AnnualExpensesUsed'] | customCurrency }}
            </p>

            <!-- operator modification -->
            <p
              class="valueMargin"
              *ngIf="isModifiedData(capacityDetails['AnnualExpensesUsed'], pciAnnualExpenses) == true"
            >
              {{ pciAnnualExpenses | customCurrency }}
            </p>
            <p
              class="valueMargin"
              *ngIf="isModifiedData(capacityDetails['AnnualExpensesUsed'], pciAnnualExpenses) == false"
            >
              $0.00
            </p>
          </div>
        </div>

        <div class="row flex labelMargin">
          <p class="bold full-width">Family Type</p>
          <div class="modification-col">
            <!-- patient data -->
            <p class="valueMargin">{{ capacityDetails['Input.FamilyType.Label'] }}</p>

            <!-- operator modification -->
            <p class="valueMargin" *ngIf="isModified == true">Single no children</p>
          </div>
        </div>

        <div class="row flex labelMargin">
          <p class="bold full-width">Location</p>
          <div class="modification-col">
            <!-- patient data -->
            <p class="valueMargin">{{ capacityDetails['Input.Location'] }}</p>

            <!-- operator modification -->
            <p class="valueMargin" *ngIf="isModified == true">Perth</p>
          </div>
        </div>
      </div>
    </mat-card>
  </div>

  <div class="row clearfix flex">
    <mat-card class="clearfix full-width mt mb">
      <div class="card-header primary-gradient-img clearfix inModal">
        <div class="row clearfix">
          <div class="row titleArea clearfix full-width pull-left">
            <h2 class="summary-header rm-m white">Capacity Check Result</h2>
          </div>

          <button
            class="pull-right btn-large"
            *ngIf="checkstatus != 'Accept'"
            (click)="makeDecision()"
            mat-raised-button
            color="accent"
          >
            <mat-icon class="fas fa-clipboard-check"></mat-icon> Decide
          </button>
        </div>
      </div>

      <div class="row flex">
        <div class="full-width col-left">
          <div class="row flex labelMargin">
            <p class="bold sr-title">Initial Result</p>
            <p class="valueMargin">
              <span class="chip" [ngClass]="capacityDetails['Result.Initial']">{{
                capacityDetails['Result.Initial']
                }}</span>
            </p>
          </div>
          <div class="row flex labelMargin">
            <p class="bold">Date</p>
            <p class="valueMargin">{{ capacityDetails['Date.Initial'] | customDate }}</p>
          </div>
          <div class="row flex labelMargin">
            <p class="bold">Time</p>
            <p class="valueMargin">{{ capacityDetails['Time.Initial'] }}</p>
          </div>
          <div class="row flex labelMargin">
            <p class="bold">Operator</p>
            <p class="valueMargin">{{ capacityDetails['InitialOperator'] }}</p>
          </div>
        </div>
        <div class="full-width" [class.col-left]="capacityDetails['Result.Reason']">
          <ng-container *ngIf="!capacityDetails['Result.Final']">
            <h4 class="sr-title reduce-marg mt">Final Result</h4>
            <div class="row flex labelMargin">
              <p class="">Final result not yet determined</p>
            </div>
          </ng-container>

          <ng-container *ngIf="capacityDetails['Result.Final']">
            <div class="row flex labelMargin">
              <p class="bold sr-title">Final Result</p>
              <p class="valueMargin">
                <span class="chip" [ngClass]="capacityDetails['Result.Final']">{{
                  capacityDetails['Result.Final']
                  }}</span>
              </p>
            </div>
            <div class="row flex labelMargin">
              <p class="bold">Date</p>
              <p class="valueMargin">{{ capacityDetails['Date.Final'] | customDate }}</p>
            </div>
            <div class="row flex labelMargin">
              <p class="bold">Time</p>
              <p class="valueMargin">{{ capacityDetails['Time.Final'] }}</p>
            </div>
            <div class="row flex labelMargin">
              <p class="bold">Operator</p>
              <p class="valueMargin">{{ capacityDetails['FinalOperator'] }}</p>
            </div>
          </ng-container>
        </div>

        <ng-container *ngIf="capacityDetails['Result.Reason']">
          <div class="full-width">
            <h4 class="sr-title reduce-marg mt">Reason for Final Decision</h4>

            <div class="well full-height">
              <p [innerHTML]="capacityDetails['Result.Reason'] | safeHtml"></p>
            </div>
          </div>
        </ng-container>
      </div>

      <!-- <div class="row clearfix">

        <div class="row clearfix mt">
          <button mat-raised-button color="accent" class="pull-right" (click)="makeDecision()"
            *ngIf="checkstatus!='Accept'">
            <mat-icon class="fas fa-clipboard-check"></mat-icon> Decide
          </button>
        </div>

      </div> -->
    </mat-card>
  </div>

  <div class="row clearfix flex">
    <mat-card class="clearfix full-width">
      <div class="colour-stripe"></div>

      <h3 class="sr-title rm-m">Finance</h3>
      <hr/>

      <!-- header labels if operator modified -->
      <div class="row clearfix flex" *ngIf="isModified == true">
        <div class="row flex labelMargin full-width border-bottom">
          <p class="small valueMargin full-width">&nbsp;</p>
          <div class="modification-col">
            <p class="small valueMargin">
              {{ "KEYWORD.patient" | translate | titlecase }}
            </p>
            <p class="small valueMargin">Operator</p>
          </div>
        </div>
      </div>

      <div class="row clearfix">
        <div class="row flex labelMargin">
          <p class="bold full-width">Original Amount</p>

          <div class="modification-col">
            <!-- patient data -->
            <p class="valueMargin">{{ contract['Amount.FinancedBeforeDeposit'] | customCurrency }}</p>

            <!-- operator modification -->
            <p class="valueMargin" *ngIf="isModified == true">$0.00</p>
          </div>
        </div>
      </div>

      <h4 class="sr-title reduce-marg mt">Borrower Contribution</h4>
      <div class="row flex labelMargin">
        <p class="bold full-width">Personal Contribution</p>

        <div class="modification-col">
          <!-- patient data -->
          <p class="valueMargin">{{ contract['Amount.Contribution'] | customCurrency }}</p>

          <!-- operator modification -->
          <p class="valueMargin" *ngIf="isModified == true">$0.00</p>
        </div>
      </div>

      <div class="row flex labelMargin">
        <p class="bold full-width">Lending Deposit</p>

        <div class="modification-col">
          <!-- patient data -->
          <p class="valueMargin">{{ contract['Deposit'] | customCurrency }}</p>

          <!-- operator modification -->
          <p class="valueMargin" *ngIf="isModified == true">$0.00</p>
        </div>
      </div>

      <div class="row flex labelMargin">
        <p class="bold full-width"><strong>Amount Financed</strong></p>

        <div class="modification-col">
          <!-- patient data -->
          <p class="valueMargin">{{ contract['Amount.Financed'] | customCurrency }}</p>

          <!-- operator modification -->
          <p class="valueMargin" *ngIf="isModified == true">$0.00</p>
        </div>
      </div>
      <!-- </div> -->
    </mat-card>

    <mat-card class="clearfix full-width">
      <div class="colour-stripe"></div>

      <h3 class="sr-title rm-m">Asset Test</h3>
      <hr/>

      <!-- header labels if operator modified -->
      <div class="row clearfix flex" *ngIf="isModified == true">
        <div class="row flex labelMargin full-width border-bottom">
          <p class="small valueMargin full-width">&nbsp;</p>
          <div class="modification-col">
            <p class="small valueMargin">
              {{ "KEYWORD.patient" | translate | titlecase }}
            </p>
            <p class="small valueMargin">Operator</p>
          </div>
        </div>
      </div>

      <div class="row clearfix">
        <div class="row flex labelMargin">
          <p class="bold full-width">Total Assets</p>

          <div class="modification-col">
            <!-- patient data -->
            <p class="valueMargin">{{ capacityDetails['TotalAssets'] | customCurrency }}</p>
            <!-- operator modification -->
            <p
              class="valueMargin"
              *ngIf="isModifiedData(capacityDetails['TotalAssets'], uploadedPCIDetails['Assets.Total']) == true"
            >
              {{ uploadedPCIDetails['Assets.Total'] | customCurrency }}
            </p>
            <p
              class="valueMargin"
              *ngIf="isModifiedData(capacityDetails['TotalAssets'], uploadedPCIDetails['Assets.Total']) == false"
            >
              $0.00
            </p>
          </div>
        </div>
        <div class="row flex labelMargin">
          <p class="bold full-width">Total Liabilities</p>

          <div class="modification-col">
            <!-- patient data -->
            <p class="valueMargin">{{ capacityDetails['TotalLiabilities'] | customCurrency }}</p>
            <!-- operator modification -->
            <p
              class="valueMargin"
              *ngIf="
                isModifiedData(capacityDetails['TotalLiabilities'], uploadedPCIDetails['Liabilities.Total']) == true
              "
            >
              {{ uploadedPCIDetails['Liabilities.Total'] | customCurrency }}
            </p>
            <p
              class="valueMargin"
              *ngIf="
                isModifiedData(capacityDetails['TotalLiabilities'], uploadedPCIDetails['Liabilities.Total']) == false
              "
            >
              $0.00
            </p>
          </div>
        </div>
        <div class="row flex labelMargin">
          <p class="bold full-width">Net Assets</p>

          <div class="modification-col">
            <!-- patient data -->
            <p class="valueMargin">{{ netAssets | customCurrency }}</p>
            <!-- operator modification -->
            <p class="valueMargin" *ngIf="isModifiedData(netAssets, pciNetAssets) == true">
              {{ pciNetAssets | customCurrency }}
            </p>
            <p class="valueMargin" *ngIf="isModifiedData(netAssets, pciNetAssets) == false">$0.00</p>
          </div>
        </div>
      </div>

      <h4 class="sr-title reduce-marg mt">Asset Stress Test</h4>

      <div class="row clearfix">
        <div class="row flex labelMargin">
          <p class="bold full-width">Net Assets</p>

          <div class="modification-col">
            <!-- patient data -->
            <p class="valueMargin">{{ netAssets | customCurrency }}</p>
            <!-- operator modification -->
            <p class="valueMargin" *ngIf="isModifiedData(netAssets, pciNetAssets) == true">
              {{ pciNetAssets | customCurrency }}
            </p>
            <p class="valueMargin" *ngIf="isModifiedData(netAssets, pciNetAssets) == false">$0.00</p>
          </div>
        </div>
        <div class="row flex labelMargin">
          <p class="bold full-width">Net vs Gross Assets</p>

          <div class="modification-col">
            <!-- patient data -->
            <p class="valueMargin" *ngIf="capacityDetails['TotalAssets'] != 0">
              {{ netGrossAssetsRate | percent: '2.0-0' }}
            </p>
            <p class="valueMargin" *ngIf="capacityDetails['TotalAssets'] == 0">0</p>
            <!-- operator modification -->
            <p class="valueMargin">{{ pciNetGrossAssetsRate | percent: '2.0-0' }}</p>
            <!-- <p class="valueMargin" *ngIf="isModifiedData(netGrossAssetsRate, pciNetGrossAssetsRate)==false">0.00</p> -->
          </div>
        </div>
      </div>
    </mat-card>
  </div>

  <div class="row clearfix flex">
    <mat-card class="clearfix full-width">
      <div class="colour-stripe"></div>
      <h3 class="sr-title rm-m">Repayments Stress Test</h3>
      <hr/>

      <!-- header labels if operator modified -->
      <div class="row clearfix flex" *ngIf="isModified == true">
        <div class="row flex labelMargin full-width border-bottom">
          <p class="small valueMargin full-width">&nbsp;</p>
          <div class="modification-col">
            <p class="small valueMargin">
              {{ "KEYWORD.patient" | translate | titlecase }}
            </p>
            <p class="small valueMargin">Operator</p>
          </div>
        </div>
      </div>

      <div class="row clearfix">
        <div class="row flex labelMargin">
          <p class="bold full-width">Stress Test Buffer</p>

          <div class="modification-col">
            <!-- patient data -->
            <p class="valueMargin">{{ capacityDetails['StressTestBuffer'] }}</p>
            <!-- operator modification -->
            <p class="valueMargin" *ngIf="isModified == true">$0.00</p>
          </div>
        </div>
        <div class="row flex labelMargin">
          <p class="bold full-width">Repayments (Stress Test)</p>

          <div class="modification-col">
            <!-- patient data -->
            <p class="valueMargin">{{ capacityDetails['RepaymentsStressTest'] | customCurrency }}</p>
            <!-- operator modification -->
            <p class="valueMargin" *ngIf="isModified == true">$0.00</p>
          </div>
        </div>
        <div class="row flex labelMargin">
          <p class="bold full-width">Repayments Quoted</p>

          <div class="modification-col">
            <!-- patient data -->
            <p class="valueMargin">
              {{ capacityDetails['RepaymentsQuoted'] | customCurrency }}
              {{ capacityDetails['RepaymentsQuotedFrequency'] }}
            </p>
            <!-- operator modification -->
            <p class="valueMargin" *ngIf="isModified == true">$0.00</p>
          </div>
        </div>
      </div>
    </mat-card>

    <mat-card class="clearfix full-width">
      <div class="colour-stripe"></div>

      <h3 class="sr-title rm-m">Credit Card Stress Test</h3>
      <hr/>

      <!-- header labels if operator modified -->
      <div class="row clearfix flex" *ngIf="isModified == true">
        <div class="row flex labelMargin full-width border-bottom">
          <p class="small valueMargin full-width">&nbsp;</p>
          <div class="modification-col">
            <p class="small valueMargin">
              {{ "KEYWORD.patient" | translate | titlecase }}
            </p>
            <p class="small valueMargin">Operator</p>
          </div>
        </div>
      </div>

      <div class="row clearfix">
        <div class="row flex labelMargin">
          <p class="bold full-width">Servicability Rate Used</p>

          <div class="modification-col">
            <!-- patient data -->
            <p class="valueMargin">{{ capacityDetails['ServicabilityRateUsed'] }}%</p>
            <!-- operator modification -->
            <p class="valueMargin" *ngIf="isModified == true">$0.00</p>
          </div>
        </div>
        <div class="row flex labelMargin">
          <p class="bold full-width">Declared Repayments</p>

          <div class="modification-col">
            <!-- patient data -->
            <p class="valueMargin">{{ capacityDetails['DeclaredRepayments'] | customCurrency }}</p>
            <!-- operator modification -->
            <p class="valueMargin" *ngIf="isModified == true">$0.00</p>
          </div>
        </div>
        <div class="row flex labelMargin">
          <p class="bold full-width">Deemed Repayments</p>

          <div class="modification-col">
            <!-- patient data -->
            <p class="valueMargin">{{ capacityDetails['DeemedRepayments'] | customCurrency }}</p>
            <!-- operator modification -->
            <p class="valueMargin" *ngIf="isModified == true">$0.00</p>
          </div>
        </div>
        <div class="row flex labelMargin">
          <p class="bold full-width">Used For Assessment</p>

          <div class="modification-col">
            <!-- patient data -->
            <p class="valueMargin">{{ capacityDetails['UsedForAssessment'] | customCurrency }}</p>
            <!-- operator modification -->
            <p class="valueMargin" *ngIf="isModified == true">$0.00</p>
          </div>
        </div>
      </div>
    </mat-card>

    <!-- <mat-card class="clearfix full-width">
      <div class="colour-stripe "></div>

      <h3 class="sr-title rm-m">Asset Stress Test</h3>
      <hr>
      <div class="row clearfix">
        <div class="row flex labelMargin">
          <p class="bold">Net Assets</p>
          <p class="valueMargin">{{toNumber(capacityDetails['TotalAssets']) - toNumber(capacityDetails['TotalLiabilities'])
								| customCurrency}}</p>
        </div>
        <div class="row flex labelMargin">
          <p class="bold">Net vs Gross Assets</p>
          <p class="valueMargin" *ngIf="capacityDetails['TotalAssets']!=0">{{(toNumber(capacityDetails['TotalAssets']) - toNumber(capacityDetails['TotalLiabilities']))
								/ toNumber(capacityDetails['TotalAssets']) | percent:'2.0-0'}}</p>
          <p class="valueMargin" *ngIf="capacityDetails['TotalAssets']==0">0</p>
        </div>
      </div>

    </mat-card> -->
  </div>
</div>

<mat-dialog-content *ngIf="isDisplayFull == true&& loading==false">
  <div [hidden]="displayPersonalCir == true">
    <div class="" *ngIf="contract && capacityDetails">
      <div class="row clearfix">
        <h2 class="sr-title ndge rm-mt pull-left">Detailed Capacity Check View</h2>
      </div>
      <mat-card class="heading">
        <div class="card-header primary-gradient-img clearfix inModal">
          <div class="row clearfix">
            <div class="row titleArea clearfix">
              <app-ep-document-view-image
                class="patientView userIconView pull-left"
                [defaultPicture]="
                  'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
                "
              ></app-ep-document-view-image>

              <h2 class="summary-header rm-m">
                Capacity Check Summary
                <span class="subLabel">{{ contract['Customer.FullName'] }}</span>
              </h2>
            </div>
          </div>
        </div>
      </mat-card>

      <div class="row clearfix">
        <mat-tab-group dynamicHeight="true">
          <mat-tab>
            <ng-template mat-tab-label> Results</ng-template>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label> Documents</ng-template>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label> HEM Data</ng-template>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label> Action log</ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>

  <div *ngIf="personalCirID != null && displayPersonalCir == true">
    <app-personal-circumstances [personalCirID]="personalCirID"></app-personal-circumstances>
    <div class="full-width text-center">
      <button class="mb mt pull-left" (click)="hidePersonalCir()" mat-raised-button color="primary">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Back to Capacity Check
      </button>
    </div>
  </div>
</mat-dialog-content>
