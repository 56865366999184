<div class="container">
  <div class="row clearfix flex">
    <form #addForm="ngForm">
      <mat-card class="card full-width">
        <div class="card-header primary-gradient-img clearfix">
          <div class="row clearfix">
            <!-- Modal dismiss button -->

            <button
              class="btn-close btn-clear white pull-right mat-button"
              *ngIf="isModal == true"
              (click)="closeEvent()"
            >
              <mat-icon>clear</mat-icon>
            </button>

            <!-- merchant calculated name -->
            <div class="row titleArea clearfix">
              <h2 class="message-title summary-header rm-m"> {{"KEYWORD.Treatment" | translate}} Edit</h2>
            </div>
          </div>
        </div>

        <mat-dialog-content>
          <div class="full-width mt clearfix flexcenter">
            <mat-form-field class="full-width clearfix" appearance="outline">
              <mat-label>Item Code</mat-label>
              <input [(ngModel)]="code" name="ItemCode" matInput placeholder="Item Code" required/>
            </mat-form-field>
          </div>
        </mat-dialog-content>

        <mat-dialog-actions class="button-row row mt flexcenter">
          <button
            class="btn btn-primary"
            *ngIf="isModal == true"
            (click)="closeEvent()"
            mat-raised-button
            color="accent"
          >
            Close
          </button>

          <button
            class="btn btn-primary"
            class="pull-left"
            [disabled]="!addForm.form.valid"
            (click)="update()"
            mat-raised-button
            color="primary"
          >
            Save
          </button>
        </mat-dialog-actions>
      </mat-card>
    </form>
  </div>
</div>
