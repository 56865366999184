import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { BeneficiaryViewComponent } from '../../beneficiary/beneficiary-view/beneficiary-view.component';
import { TreatmentService } from '../shared/treatment.service';
import { treatmentUtil } from '../shared/treatmentUtil';
import { TreatmentBrandViewComponent } from '../treatment-brand-view/treatment-brand-view.component';
import { TreatmentCategoryViewComponent } from '../treatment-category-view/treatment-category-view.component';
import { TreatmentProductViewComponent } from '../treatment-product-view/treatment-product-view.component';
import { TreatmentTypeViewComponent } from '../treatment-type-view/treatment-type-view.component';

@Component({
  selector: 'app-product-create-edit',
  templateUrl: './treatment-product-create-edit.component.html',
  styleUrls: ['./treatment-product-create-edit.component.css'],
})
export class TreatmentProductCreateEditComponent implements OnInit {
  @Input()
  productID;

  product = {
    categoryID: null,
    typeID: null,
    brandID: null,
    manufacturerID: null,
    distributorID: null,

    label: null,
    categoryNote: null,
    labelNote: null,

    diagnosisNote: null,
    treatmentNote: null,

    sellPrice: null,
    costPrice: null,

    isActive: null,
    isNullProduct: false,
    description: null,
    logoID: null,
    catalogueID: null,

    itemCode: null,
  };

  @Output()
  closeModal = new EventEmitter();

  @Output()
  result = new EventEmitter();

  isModal = false;

  isEditMode = false;

  isDone = false;

  isAdminOrPromoter = false;

  categories = [];

  types = [];

  brands = [];

  suppliers = [];

  manufacturers = [];
  distributors = [];

  isLogoFile = false;
  isCatalogueFile = false;
  treatmentUtil = new treatmentUtil();

  logoLink;
  catalogueLink;

  loadingLogo = false;
  loadingCatalogue = false;

  logoSrc;
  catalogueSrc;

  constructor(
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private utilsService: UtilsService,
    private router: Router,
    private treatmentService: TreatmentService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.productID) {
        this.productID = data.productID;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['productID']) {
        this.productID = params['productID'];
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;
        this.setup();
      });
    });
  }

  changeItemCode() {
    if (this.product.itemCode) {
      const payload = {
        itemCode: this.product.itemCode,
        fields: 'Category_key,Type_key',
        isNullProduct: true,
      };

      this.treatmentService.getTreatmentProductList(payload, this.isAdminOrPromoter).subscribe((res) => {
        if (res && res.length > 0) {
          this.product.categoryID = res[0]['Category_key'];
          this.product.typeID = res[0]['Type_key'];

          this.onSelectSimpleCategory(this.product.categoryID, true);
        } else {
          NotifyAppComponent.displayToast('warning', 'Not Found', 'Product Code Not found');
          this.product.categoryID = null;
          this.product.typeID = null;
        }
      });
    }
  }

  setup() {
    if (this.productID) {
      this.treatmentService.getTreatmentProductDetailsGlobal(this.productID).subscribe((res) => {
        if (res) {
          this.logoLink = null;
          this.logoLink = this.treatmentService.getProductLogoStreamLink(this.productID, res['LastModified']);

          this.catalogueLink = null;
          this.catalogueLink = this.treatmentService.getProductCatalogueStreamLink(this.productID, res['LastModified']);

          this.isEditMode = true;

          this.fillData(res);

          this.getSuppliers();

          this.getTreatmentCategoryList();
        }
      });
    } else {
      this.isEditMode = false;

      this.getTreatmentCategoryList();

      this.getSuppliers();
    }
  }

  viewSupplier(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(BeneficiaryViewComponent, {
        data: id,
        width: '600px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  fillData(res) {
    if (res) {
      if (res['Label']) {
        this.product.label = res['Label'];
      }

      if (res['Active'] == '1') {
        this.product.isActive = true;
      }

      if (res['Active'] == '0') {
        this.product.isActive = false;
      }

      if (res['DefaultNotes.Category.Overridden']) {
        this.product.categoryNote = res['DefaultNotes.Category.Overridden'];
      }

      if (res['DefaultNotes.Label.Overridden']) {
        this.product.labelNote = res['DefaultNotes.Label.Overridden'];
      }

      if (res['DefaultNotes.Category.Overridden']) {
        this.product.diagnosisNote = res['DefaultNotes.Content.Diagnostic.Overridden'];
      }
      if (res['DefaultNotes.Category.Overridden']) {
        this.product.treatmentNote = res['DefaultNotes.Content.Treatment.Overridden'];
      }

      if (res['DefaultPrice.Sell']) {
        this.product.sellPrice = res['DefaultPrice.Sell'];
      }

      if (res['DefaultPrice.Cost.Item']) {
        this.product.costPrice = res['DefaultPrice.Cost.Item'];
      }

      if (res['Category_key']) {
        this.product.categoryID = res['Category_key'];
      }

      if (res['Type_key']) {
        this.product.typeID = res['Type_key'];
      }

      if (res['Brand_key']) {
        this.product.brandID = res['Brand_key'];
      }

      if (res['Manufacturer_Key.Effective']) {
        this.product.manufacturerID = res['Manufacturer_Key.Effective'];
      }

      if (res['Distributor_Key.Effective']) {
        this.product.distributorID = res['Distributor_Key.Effective'];
      }

      if (res['IsNullProduct'] == '1') {
        this.product.isNullProduct = true;
      }

      if (res['IsNullProduct'] == '0') {
        this.product.isNullProduct = false;
      }

      if (res['Description']) {
        this.product.description = res['Description'];
      }

      if (res['Item_Code.Effective']) {
        this.product.itemCode = res['Item_Code.Effective'];
      }
    }
  }

  onCompleteLogoAll(fileID) {
    if (fileID && fileID[0]) {
      this.product.logoID = fileID[0];
      this.changeLogoImage();
    }
  }

  onCompleteCatalogueAll(fileID) {
    if (fileID && fileID[0]) {
      this.product.catalogueID = fileID[0];
      this.changeCatalogueImage();
    }
  }

  viewProduct(id) {
    const ref = RootAppComponent.dialog.open(TreatmentProductViewComponent, {
      data: {
        productID: id,
        isGlobal: true,
      },

      width: '800px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  changeLogoImage() {
    this.utilsService.getFileDetails(this.product.logoID).subscribe((res) => {
      if (res) {
        if (res.contentType == 'image/png' || res.contentType == 'image/jpg' || res.contentType == 'image/jpeg') {
          this.isLogoFile = true;
          this.logoSrc = `${environment.nodeUrl}/files/file-view/${this.product.logoID}`;
        } else {
          NotifyAppComponent.displayToast(
            'warning',
            'Error during operation',
            'Format of image should be png/jpg/jpeg'
          );
        }
      }
    });
  }

  changeCatalogueImage() {
    this.utilsService.getFileDetails(this.product.catalogueID).subscribe((res) => {
      if (res) {
        if (res.contentType == 'image/png' || res.contentType == 'image/jpg' || res.contentType == 'image/jpeg') {
          this.isCatalogueFile = true;
          this.catalogueSrc = `${environment.nodeUrl}/files/file-view/${this.product.catalogueID}`;
        } else {
          NotifyAppComponent.displayToast(
            'warning',
            'Error during operation',
            'Format of image should be png/jpg/jpeg'
          );
        }
      }
    });
  }

  getSuppliers() {
    const payload = {
      typeCode: 'MANU',
      orderBy: 'CalculatedName',
      fields: 'ID,Name',
      isActive: true,
    };

    this.treatmentService.getSupplierList(payload, this.isAdminOrPromoter).subscribe((res) => {
      this.manufacturers = res;
    });

    const payload2 = {
      typeCode: 'DISTR',
      orderBy: 'CalculatedName',
      fields: 'ID,Name',
      isActive: true,
    };
    this.treatmentService.getSupplierList(payload2, this.isAdminOrPromoter).subscribe((res) => {
      this.distributors = res;
    });
  }

  getTreatmentCategoryList() {
    const payload = {
      fields: 'ID,Label',
      isActive: true,
    };

    this.treatmentService.getCategoryList(payload, true).subscribe((res) => {
      this.categories = res;

      if (this.product.categoryID) {
        this.onSelectSimpleCategory(this.product.categoryID, true);
      }
    });
  }

  onSelectSimpleCategory(simpleCategory, setup = false) {
    if (simpleCategory) {
      const payload = {
        fields: 'ID,Label,Dafault_Item_Code',
        isActive: true,
        isNullType: false,
        categoryID: simpleCategory,
      };

      this.treatmentService.getTreatmentTypeList(payload).subscribe((res) => {
        this.types = res;

        if (setup == true && this.product.typeID) {
          this.onSelectSimpleType(this.product.typeID, true);
        }
      });
    }
  }

  onSelectSimpleType(simpleType, setup = false) {
    if (simpleType) {
      const payload = {
        isActive: true,
        typeID: simpleType,
        fields: 'ID,Label',
        isNullProduct: false,
        isNullBrand: false,
      };

      this.treatmentService.getTreatmentBrandListGlobal(payload).subscribe((res) => {
        if (res && res.length > 0) {
          this.brands = res;
        }
      });
    }
  }

  onSelectSimpleBrand(simpleBrand) {
    if (simpleBrand) {
      const payload = {
        fields: 'ID,Distributor_Key,Manufacturer_Key',
        isNullProduct: false,
        isNullBrand: false,
      };

      this.treatmentService.getTreatmentBrandDetailsGlobal(simpleBrand, payload).subscribe((res) => {
        if (res && res.Manufacturer_Key) {
          this.product.manufacturerID = res.Manufacturer_Key;
        }

        if (res && res.Distributor_Key) {
          this.product.distributorID = res.Distributor_Key;
        }
      });
    }
  }

  setCode(code) {
    if (code) {
      this.product.itemCode = code;
    }
  }

  closeEvent() {
    this.closeModal.emit(true);
  }

  edit() {
    if (this.product) {
      this.treatmentService.editTreatmentProductGlobal(this.productID, this.product).subscribe((res) => {
        if (res) {
          NotifyAppComponent.displayToast('success', 'Successful operation', 'Product has been successfully updated');
          this.isDone = true;
          this.result.emit(res);
        }
      });
    }
  }

  save() {
    if (this.product) {
      this.treatmentService.createTreatmentProductGlobal(this.product).subscribe((res) => {
        if (res) {
          if (res['ID']) {
            this.productID = res['ID'];
          }

          NotifyAppComponent.displayToast('success', 'Successful operation', 'Product has been successfully added');

          this.isDone = true;

          this.result.emit(res);
        }
      });
    }
  }

  list() {
    this.router.navigate(['/merchant', { outlets: { page: ['treatment-dynamic-product-list-global'] } }]);
  }

  viewType(id) {
    const ref = RootAppComponent.dialog.open(TreatmentTypeViewComponent, {
      data: {
        typeID: id,
        isGlobal: true,
      },

      width: '700px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewCategory(id) {
    const ref = RootAppComponent.dialog.open(TreatmentCategoryViewComponent, {
      data: {
        categoryID: id,
      },

      width: '700px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewBrand(id) {
    const ref = RootAppComponent.dialog.open(TreatmentBrandViewComponent, {
      data: {
        brandID: id,
        isGlobal: true,
      },

      width: '700px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }
}
