import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { QuotationService } from '../shared/quotation.service';

@Component({
  selector: 'app-quotation-decision-modal',
  templateUrl: './quotation-decision-modal.component.html',
  styleUrls: ['./quotation-decision-modal.component.css'],
})
export class QuotationDecisionModalComponent implements OnInit {
  @Input()
  checkType;

  @Input()
  quotationID;

  @Input()
  title = 'Credit Check';

  @Output()
  getDecision = new EventEmitter();

  @Output()
  getDeclineReason = new EventEmitter();

  @Output()
  getResult = new EventEmitter();

  closeModal = new EventEmitter();

  isModal = false;

  reason;
  decision;

  documentIssues = [
    'The document attached contains different information as borrower declared',
    'The document attached is blank',
  ];

  reasons = [];

  constructor(
    private quotationService: QuotationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.checkType != null) {
        this.checkType = data.checkType;
      }

      if (data.title != null) {
        this.title = data.title;
      }

      if (data.quotationID) {
        this.quotationID = data.quotationID;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    if (this.checkType) {
      if (this.checkType == 'Credit') {
        this.title = 'Credit Check';
      } else if (this.checkType == 'Capacity') {
        this.title = 'Capacity Check';
      } else if (this.checkType == 'Identity') {
        this.title = 'Identity Check';
      } else if (this.checkType == 'document') {
        this.title = 'Supporting Check';
      }
    }
  }

  close() {
    this.closeModal.emit(true);
  }

  makeDecision() {
    if (this.checkType == 'document') {
      this.getDecision.emit(this.decision);

      if (this.decision == 'Decline') {
        let _reasons = '';
        if (this.reasons.length > 0) {
          if (this.reason) {
            this.reasons.push(this.reason);
            _reasons = this.reasons.join(',');
          } else {
            _reasons = this.reasons.join(',');
          }
        } else {
          _reasons = this.reason;
        }

        this.getDeclineReason.emit(_reasons);
      }
    } else {
      const payload = {
        finalResutl: this.decision,
        reason: this.reason,
      };

      this.quotationService.decideCheck(this.quotationID, payload, this.checkType).subscribe((res) => {
        if (res) {
          NotifyAppComponent.displayToast(
            'success',
            `Decide ${this.title}`,
            'You have successfully maked your decision'
          );

          this.getResult.emit(res);
        }
      });
    }
  }

  selectReason(reason, _response) {
    const response = _response.checked;
    const index = this.reasons.indexOf(reason);

    if ((!this.reasons.length || index == -1) && response == true) {
      this.reasons.push(reason);
    } else {
      if (response == false && index != -1) {
        this.reasons.splice(index, 1);
      }
    }
  }
}
