<div class="rel lookup white-background">
  <div class="full-width white-background">
    <div>
      <div class="card-header primary-gradient-img clearfix inModal">
        <div class="row clearfix">
          <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeEvent()">
            <mat-icon>clear</mat-icon>
          </button>
          <div class="row titleArea clearfix">
            <mat-icon class="fas fa-bullhorn" style="font-size: 2.8rem; width: 80px"></mat-icon>
            <h2 class="summary-header rm-m">
              {{ title }}
              <span class="subLabel">{{ description }}</span>
            </h2>
          </div>
        </div>
      </div>

      <div class="clearfix flex flex-center">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Filter by Label,description,template...etc</mat-label>
          <input [(ngModel)]="filterData" matInput name="search" tabIndex="1"/>
        </mat-form-field>

        <button class="actionMenuToggle ml mr" [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon class="fas fa-sort"></mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <button (click)="orderBy(['Label'])" mat-menu-item>
            <span>Label</span>
          </button>
          <button (click)="orderBy(['Template.Label'])" mat-menu-item>
            <span>Template</span>
          </button>
          <button (click)="orderBy(['DateTimeCreated'])" mat-menu-item>
            <span>Date created</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <div class="patientContainer">
      <div
        class="patientRecord row clearfix"
        *ngFor="
          let campaign of campaigns
            | FilterArrayMultipleOrContain: ['Label', 'Description', 'Template.Label', 'Template.Tag']:filterData
            | OrderArrayObject: orderByFilter;
          let index = index
        "
        [ngClass]="{ pair: index % 2 == 1 }"
      >
        <div class="flex truncate mb">
          <div
            class="profile-result full-width"
            (click)="SelectRecordClickEvent(campaign)"
            matTooltip="Click to choose {{ campaign['Label'] }} "
          >
            <!-- avatar image -->
            <div class="full-width flex">
              <app-marketing-view-logo
                class="patientIcon campaignLookup mr"
                [hideZoomButton]="true"
                [marketingID]="campaign['ID']"
                [LastModified]="campaign['LastModified']"
              >
              </app-marketing-view-logo>
              <!-- profile information -->
              <div class="full-width">
                <h4 class="sr-title rm-m sm-h4">{{ campaign['Label'] }}</h4>

                <div class="full-width flex">
                  <p class="small rm-m thrd-width" matLine>
                    <strong>Template:</strong>
                  </p>

                  <p class="small rm-m full-width" matLine>
                    {{ campaign['Template.Label'] }}
                  </p>
                </div>

                <div class="full-width flex">
                  <p class="small rm-m thrd-width" matLine>
                    <strong>Type:</strong>
                  </p>

                  <p class="small rm-m full-width" matLine>
                    <span *ngIf="campaign['Social_Media_Only'] == '1'">Only social media</span>
                    <span *ngIf="campaign['Social_Media_Only'] != '1'">Email, SMS and social media</span>
                  </p>
                </div>

                <div class="full-width flex" *ngIf="campaign['Tag.Label']">
                  <p class="small rm-m thrd-width" matLine>
                    <strong>Template:</strong>
                  </p>

                  <p class="small rm-m full-width" matLine>
                    {{ campaign['Template.Label'] }}
                  </p>
                </div>

                <div class="full-width flex">
                  <p class="small rm-m thrd-width" matLine>
                    <strong>Created at:</strong>
                  </p>

                  <p class="small rm-m full-width" matLine>
                    {{ campaign.DateTimeCreated | customDate }}
                  </p>
                </div>
                <div class="full-width" *ngIf="mergeTags(campaign)">
                  <mat-chip-list>
                    <mat-chip *ngFor="let t of mergeTags(campaign) | splitArray: '|'">{{ t }}</mat-chip>
                  </mat-chip-list>
                </div>
              </div>
            </div>
          </div>

          <button
            class=""
            *ngIf="campaign && campaign['ID']"
            (click)="view(campaign['ID'])"
            color="accent"
            mat-icon-button
            matTooltip="View campaign Details"
          >
            <mat-icon>visibility</mat-icon>
          </button>
        </div>
        <mat-divider></mat-divider>
      </div>
    </div>
    <br/>
    <br/>

    <div class="drop-button">
      <button
        class="inline btn-new-invite selectExistingPatient pull-left"
        *ngIf="isModal == true"
        (click)="closeEvent()"
        mat-raised-button
        color="primary"
      >
        Close
      </button>

      <ng-container *ngIf="isCreate == true">
        <button
          class="inline btn-new-invite selectExistingPatient pull-right"
          (click)="createEvent()"
          mat-raised-button
          color="accent"
        >
          Create new Campaign
        </button>
      </ng-container>
    </div>
  </div>
</div>
