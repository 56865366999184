import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { CustomerViewComponent } from '../../customer/customer-view/customer-view.component';
import { InvitationViewModalComponent } from '../../invitation/invitation-view-modal/invitation-view-modal.component';
import { ProductViewModalComponent } from '../../product/product-view-modal/product-view-modal.component';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { SideMenuService } from '../../../shared/services/side-menu.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { ContractService } from '../../contract/shared/contract.service';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';

@Component({
  selector: 'app-settlement-customer-list-global',
  templateUrl: './settlement-customer-list-global.component.html',
  styleUrls: ['./settlement-customer-list-global.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class SettlementCustomerListGlobalComponent implements OnInit {
  static closeMerchantSelectEvent = new EventEmitter();
  @Input()
  merchantID;

  @Input()
  dateFrom;

  @Input()
  dateTo;

  @Output()
  createdSettlement = new EventEmitter();

  items = [
    'settlement',
    'contract',
    'invitation',
    'customer',
    'dentist',
    'merchant',
    'instantOffer',
    'appointment',
    'campaign',
    'landingPage',
    'note',
  ];

  customerID = null;
  isModuleSettlementsActive = Settings.global['isModuleSettlementsActive'];
  isPromoterOrAdmin = false;

  displayContractView = false;
  displayInvitationView = false;
  displayNewSettlement = false;
  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  utils = new UtilsClass();

  displayedColumns = [
    'patientPicture',
    'CustomerName',
    'CustomerMobile',
    'MerchantName',
    'LoanType',
    'TreatmentValue',
    'SettlementStatus',
    'Actions',
  ];
  filters = [];

  searchVals = new FormControl();

  searchValList = ['Full Name', 'Email', 'Mobile'];
  expandedRow: number;
  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  filteredSize = null;

  destroyEvent = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private utilService: UtilsService,
    private dialog: MatDialog,
    private router: Router,
    private authenticationService: AuthenticationService,
    private contractService: ContractService,
    private customPhonePipe: CustomPhonePipe
  ) {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });
  }

  ngOnInit() {
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleSettlementsActive = access['isModuleSettlementsActive'];
      }

      SideMenuService.goBack.subscribe((res) => {
        if (res == true) {
          this.hideViewDetails();
        }
      });

      // this.listDB = new LoadRecords(this.contractService, this.destroyEvent, this.merchantID, this.customPhonePipe);
      // this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.listDB = new LoadRecords(this.contractService, this.destroyEvent, this.merchantID, this.customPhonePipe);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  newSettlement() {
    AuthenticationService.newSettlement.emit({});
  }
  setFilter(event, field) {
    let filter;

    if (field == 'Settlement_Status') {
      let _event;
      if (event == true) {
        _event = 'Complete';
      } else {
        _event = 'Remaining';
      }
      filter = {
        field,
        value: _event,
      };
    } else if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  openQuickViewDialog(ID) {
    this.router.navigate(['/merchant', { outlets: { page: ['contract-view', ID] } }]);
  }

  openDetailContractDialog(ID) {
    this.customerID = ID;
    this.displayContractView = true;
    this.displayInvitationView = false;
    this.displayNewSettlement = false;
  }

  openInviteViewDetails(ID) {
    this.customerID = null;
    this.displayInvitationView = true;
    this.displayInvitationView = false;
    this.displayNewSettlement = false;
  }

  createSettlement(ID) {
    SettlementCustomerListGlobalComponent.closeMerchantSelectEvent.emit(true);
    this.customerID = ID;
    this.displayInvitationView = false;
    this.displayInvitationView = false;
    this.displayNewSettlement = true;
  }

  hideViewDetails() {
    this.customerID = null;
    this.displayContractView = false;
    this.displayInvitationView = false;
    this.displayNewSettlement = false;
    SettlementCustomerListGlobalComponent.closeMerchantSelectEvent.emit(false);
  }

  openInvitationView(id) {
    const ref = this.dialog.open(InvitationViewModalComponent, {
      data: {
        invitationID: id,
        membership: false,
      },
      width: '900px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  openProductView(id) {
    const ref = this.dialog.open(ProductViewModalComponent, {
      data: id,
      width: '750px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  toNumber(n) {
    if (n) {
      return Number(n);
    } else {
      return 0;
    }
  }

  openCustomerView(id) {
    const ref = this.dialog.open(CustomerViewComponent, {
      data: id,
      width: '600px',
      panelClass: 'noCard',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  isDestoyed = false;

  serviceRef;

  constructor(private contractService: ContractService, private destroyEvent, private merchantID,
    private customPhonePipe: CustomPhonePipe) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    const payload = {
      section: 0,
      merchantID: this.merchantID,
      onlyActive: true,
      fields:
      'ID,MerchantName,Customer.FullName,Customer.Mobile,ProductName,Customer_key,Settlements.NotYetDrawn,Amount.FinancedBeforeDeposit,Product_key,Status,Status.Code,'+
      'Customer.Email,Customer.FirstName,Customer.LastName,Merchant_key,Invitation_key,Quotation.Status.Code,Quotation.Status.Label,Product.Label.Internal,Product.Label.Marketing,'+
      'FunderName,LastEvent.EventDescription,LastEvent.EventCode,Product.Label.Web',
    };

    this.serviceRef = this.contractService.getContractListGlobal(payload).subscribe((res) => {
      this.count = res.length;
      this.items = res;

      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i]['Settlements.NotYetDrawn'] == '0') {
          this.items[i]['Settlement_Status'] = 'Complete';
        } else {
          this.items[i]['Settlement_Status'] = 'Remaining';
        }

        this.buildRecord(i);

      }
      this.dataChange.next(this.items.slice());
      this.firstLoad = true;
      const innerFunction = (section) => {
        const length = this.items.length;
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.contractService.getContractListGlobal(payload).subscribe((res) => {
          if (res.length > 0) {
            this.count = res.count;
            this.items = this.items.concat(res);

            for (let i = length; i < this.items.length; i++) {
              if (this.items[i]['Settlements.NotYetDrawn'] == '0') {
                this.items[i]['Settlement_Status'] = 'Complete';
              } else {
                this.items[i]['Settlement_Status'] = 'Remaining';
              }

              this.buildRecord(i);
            }

            this.dataChange.next(this.items.slice());
            if (this.isDestoyed != true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };

      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  buildRecord(i) {
    if (this.items[i]) {
      this.items[i]['fullName'] = '';

      if (this.items[i]['ID']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['ID'];
      }
      if (this.items[i]['MerchantName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['MerchantName'];
      }

      if (this.items[i]['Status']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Status'];
      }

      if (this.items[i]['Status.Code']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Status.Code'];
      }

      if (this.items[i]['Quotation.Status.Code']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Quotation.Status.Code'];
      }

      if (this.items[i]['Quotation.Status.Label']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Quotation.Status.Label'];
      }

      if (this.items[i]['Customer.FullName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Customer.FullName'];
      }
      if (this.items[i]['Customer.Email']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Customer.Email'];
      }
      if (this.items[i]['ProductName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['ProductName'];
      }
      if (this.items[i]['Invitation_key']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Invitation_key'];
      }
      if (this.items[i]['Merchant_key']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Merchant_key'];
      }

      if (this.items[i]['Product.Label.Internal']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Product.Label.Internal'];
      }
      if (this.items[i]['Product.Label.Marketing']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Product.Label.Marketing'];
      }
      if (this.items[i]['Product.Label.Web']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Product.Label.Web'];
      }

      if (this.items[i]['Customer.Mobile']) {
        this.items[i]['fullName'] =
          this.items[i]['fullName'] +
          ' ' +
          this.customPhonePipe.transform(this.items[i]['Customer.Mobile'], 'none').replace(/ /g, '');
      }
      if (this.items[i]['Customer.Mobile']) {
        this.items[i]['fullName'] =
          this.items[i]['fullName'] +
          ' ' +
          this.customPhonePipe.transform(this.items[i]['Customer.Mobile'], 'none');
      }
      if (this.items[i]['FunderName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['FunderName'];
      }
      if (this.items[i]['LastEvent.EventDescription']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['LastEvent.EventDescription'];
      }
      if (this.items[i]['LastEvent.EventCode']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['LastEvent.EventCode'];
      }
    }
  }
  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
          let f = '';

          f = item[this.field] || '';

          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'CustomerName':
          [propertyA, propertyB] = [a['Customer.FullName'], b['Customer.FullName']];
          break;
        case 'CustomerMobile':
          [propertyA, propertyB] = [a['Customer.Mobile'], b['Customer.Mobile']];
          break;
        case 'MerchantName':
          [propertyA, propertyB] = [a['MerchantName'], b['MerchantName']];
          break;
        case 'LoanType':
          [propertyA, propertyB] = [a['ProductName'], b['ProductName']];
          break;
        case 'TreatmentValue':
          [propertyA, propertyB] = [a['Amount.FinancedBeforeDeposit'], b['Amount.FinancedBeforeDeposit']];
          break;
        case 'SettlementStatus':
          [propertyA, propertyB] = [a['Settlement_Status'], b['Settlement_Status']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
