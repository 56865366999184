/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./booking-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../calendly/calendly.component.ngfactory";
import * as i6 from "../calendly/calendly.component";
import * as i7 from "@angular/platform-browser";
import * as i8 from "./booking-modal.component";
var styles_BookingModalComponent = [i0.styles];
var RenderType_BookingModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BookingModalComponent, data: {} });
export { RenderType_BookingModalComponent as RenderType_BookingModalComponent };
export function View_BookingModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "row clearfix inner-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "card-header primary-gradient-img clearfix inModal"]], [[4, "background", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "button", [["class", "btn-close btn-clear mat-button white"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(5, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["clear"])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "row titleArea clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "h2", [["class", "summary-header rm-m"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Book a demonstration "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "subLabel"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Use the calendar below to select the best time for us to arrange a guided tour of our platform."])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "mat-dialog-content", [["class", "content-demo mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(13, 16384, null, 0, i4.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "app-component-calendly", [], null, [[null, "getLink"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("getLink" === en)) {
        var pd_0 = (_co.getLink($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_CalendlyComponent_0, i5.RenderType_CalendlyComponent)), i1.ɵdid(15, 114688, null, 0, i6.CalendlyComponent, [i7.DomSanitizer, [2, i4.MAT_DIALOG_DATA]], { type: [0, "type"] }, { getLink: "getLink" })], function (_ck, _v) { var _co = _v.component; _ck(_v, 5, 0); var currVal_3 = _co.type; _ck(_v, 15, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.headerBackground; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 5).inline; var currVal_2 = (((i1.ɵnov(_v, 5).color !== "primary") && (i1.ɵnov(_v, 5).color !== "accent")) && (i1.ɵnov(_v, 5).color !== "warn")); _ck(_v, 4, 0, currVal_1, currVal_2); }); }
export function View_BookingModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-booking-modal", [], null, null, null, View_BookingModalComponent_0, RenderType_BookingModalComponent)), i1.ɵdid(1, 114688, null, 0, i8.BookingModalComponent, [[2, i4.MAT_DIALOG_DATA]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BookingModalComponentNgFactory = i1.ɵccf("app-booking-modal", i8.BookingModalComponent, View_BookingModalComponent_Host_0, { type: "type" }, {}, []);
export { BookingModalComponentNgFactory as BookingModalComponentNgFactory };
