<mat-card class="rel supplier-card clearfix mb" *ngIf="beneficiary" @ngIfAnimation>
  <!-- is a modal -->
  <mat-dialog-content class="stacked-card-view" *ngIf="isModal == true">
    <div class="card-header primary-gradient-img clearfix stacked">
      <div class="row clearfix">
        <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeEvent()">
          <mat-icon>clear</mat-icon>
        </button>
        <div class="row titleArea clearfix">
          <app-ep-document-view-image
            class="supplierIcon pull-left"
            [defaultPicture]="
              'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/default-corporate-image.png)'
            "
            [hideZoomButton]="false"
            [link]="profileLink"
          >
          </app-ep-document-view-image>
          <h2 class="summary-header rm-m">
            <!-- {{ beneficiary.CalculatedName}} -->
            {{ beneficiary['Name'] }}
            <span class="subLabel" *ngIf="beneficiary['TradingAs']">Trading as: {{ beneficiary['TradingAs'] }}</span>
            <span class="subLabel" *ngIf="beneficiary['TrusteeFor']">Trustee for: {{ beneficiary['TrusteeFor'] }}</span>
          </h2>
          <p class="clearfix small rm-mb" *ngIf="beneficiary['Active'] == '1' && isPromoterOrAdmin == true">
            <span class="chip-enable">Activated</span>
          </p>
          <p class="clearfix small rm-mb" *ngIf="beneficiary['Active'] == '0' && isPromoterOrAdmin == true">
            <span class="chip-disable">Not Activated</span>
          </p>
        </div>
      </div>
      <div class="action-button">
        <button class="actionMenuToggle" [matMenuTriggerFor]="btnPatMenu" mat-raised-button>
          Actions <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #btnPatMenu="matMenu">
          <button *ngIf="isPromoterOrAdmin == false" (click)="viewSupplier()" mat-menu-item>
            <mat-icon>search</mat-icon>
            <span>View Details</span>
          </button>

          <button (click)="openCatelogueVIew()" mat-menu-item>
            <mat-icon class="fas fa-newspaper"></mat-icon>
            <span>View Catalogue</span>
          </button>

          <button *ngIf="isPromoterOrAdmin == true" (click)="editSupplier()" mat-menu-item>
            <mat-icon>edit</mat-icon>
            <span>Edit Supplier</span>
          </button>

          <button *ngIf="isPromoterOrAdmin == true" (click)="viewBankAccounts()" mat-menu-item>
            <mat-icon class="fas fa-wallet"></mat-icon>
            <span>View Bank Accounts</span>
          </button>
          <button
            class=""
            *ngIf="beneficiary['emails.Email']"
            (click)="openMailTo()"
            mat-menu-item
            color="accent"
            matTooltip="Contact {{ beneficiary.CalculatedName }}"
          >
            <mat-icon>contact_mail</mat-icon>
            <span>Email Supplier</span>
          </button>

          <button
            *ngIf="beneficiary['addresses.Calculated']"
            (click)="displayMap()"
            mat-menu-item
            color="accent"
            matTooltip="View on map"
          >
            <mat-icon>place</mat-icon>
            <span>View on map</span>
          </button>
          <button *ngIf="beneficiary['Active'] == '1' && isPromoterOrAdmin == true" (click)="disable()" mat-menu-item>
            <mat-icon>cancel</mat-icon>
            <span>Disable</span>
          </button>
          <button *ngIf="beneficiary['Active'] == '0' && isPromoterOrAdmin == true" (click)="enable()" mat-menu-item>
            <mat-icon>check_circle</mat-icon>
            <span>Enable</span>
          </button>
        </mat-menu>
      </div>
    </div>

    <div
      class="grey-row upper mb"
      *ngIf="beneficiary['Facebook'] || beneficiary['Twitter'] || beneficiary['LinkedIn'] || beneficiary['Instagram']"
    >
      <div class="row clearfix socials">
        <!-- open social links in new tab -->
        <button
          *ngIf="beneficiary['Facebook']"
          (click)="openSocialLink(beneficiary['Facebook'])"
          mat-icon-button
          matTooltip="Visit Facebook page"
        >
          <mat-icon class="fab fa-facebook"></mat-icon>
        </button>
        <button
          *ngIf="beneficiary['Twitter']"
          (click)="openSocialLink(beneficiary['Twitter'])"
          mat-icon-button
          matTooltip="Visit Twitter page"
        >
          <mat-icon class="fab fa-twitter"></mat-icon>
        </button>
        <button
          *ngIf="beneficiary['LinkedIn']"
          (click)="openSocialLink(beneficiary['LinkedIn'])"
          mat-icon-button
          matTooltip="Visit LinkedIn page"
        >
          <mat-icon class="fab fa-linkedin"></mat-icon>
        </button>
        <button
          *ngIf="beneficiary['Instagram']"
          (click)="openSocialLink(beneficiary['Instagram'])"
          mat-icon-button
          matTooltip="Visit Instagram page"
        >
          <mat-icon class="fab fa-instagram"></mat-icon>
        </button>
      </div>
    </div>

    <div class="row clearfix details flex" *ngIf="beneficiary['addresses.Calculated']">
      <mat-icon class="ico">room</mat-icon>
      <div class="row-content clicktext" (click)="displayMap()" matTooltip="Click to view on map">
        <h4 class="rm-m">{{ beneficiary['addresses.Calculated'] }}</h4>
        <p class="small">Address</p>
      </div>
    </div>

    <div class="mb clearfix row flex wrapped">
      <div class="row clearfix details flex" *ngIf="beneficiary['Phone.Work.Number']">
        <mat-icon class="ico">phone</mat-icon>
        <div class="row-content">
          <h4 class="rm-m">{{ beneficiary['Phone.Work.Number'] | customPhone: 'landLine' }}</h4>
          <p class="small">Business Phone</p>
        </div>
      </div>

      <div class="row clearfix details flex" *ngIf="beneficiary['mobiles.Number']">
        <mat-icon class="ico">phone_android</mat-icon>
        <div class="row-content">
          <h4 class="rm-m">{{ beneficiary['mobiles.Number'] | customPhone: 'landLine' }}</h4>
          <p class="small">Mobile</p>
        </div>
      </div>

      <div class="row clearfix details flex" *ngIf="beneficiary['emails.Email']">
        <mat-icon class="ico">email</mat-icon>
        <div class="row-content clicktext" (click)="openMailTo()" matTooltip="Click to create email">
          <h4 class="rm-m">{{ beneficiary['emails.Email'] }}</h4>
          <p class="small">Email</p>
        </div>
      </div>

      <div class="row clearfix details flex" *ngIf="beneficiary['URL']">
        <mat-icon class="ico">devices</mat-icon>
        <div class="row-content clicktext">
          <h4 class="rm-m" (click)="openSocialLink(beneficiary['URL'])" matTooltip="Click to visit website">
            {{ beneficiary['URL'] }}
          </h4>
          <p class="small">Website</p>
        </div>
      </div>

      <!-- <div *ngIf="beneficiary['Occupation'] && isPromoterOrAdmin==true" class="row clearfix details flex">
        <mat-icon class="ico">store</mat-icon>
        <div class="row-content">
          <h4 class="rm-m">{{beneficiary['Occupation'] }}</h4>
          <p class="small">{{ "Occupation" | translate }}</p>
        </div>
      </div>

      <div *ngIf="beneficiary['CompanyType'] && isPromoterOrAdmin==true" class="row clearfix details flex">
        <mat-icon class="ico">business_center</mat-icon>
        <div class="row-content">
          <h4 class="rm-m">{{beneficiary['CompanyType'] }}</h4>
          <p class="small">{{ "Company Type" | translate }}</p>
        </div>
      </div> -->
    </div>

    <div class="grey-row" *ngIf="abnDetails == true && isPromoterOrAdmin == true">
      <p class="small rm-m">
        <span class="baseLabel" *ngIf="beneficiary['Type_Code'] == 'BENE'">
          <mat-icon class="fas fa-parachute-box"></mat-icon> Supplier
        </span>
        <span class="baseLabel" *ngIf="beneficiary['Type_Code'] == 'MANU'">
          <mat-icon class="fas fa-industry"></mat-icon> Manufacturer
        </span>
        <span class="baseLabel" *ngIf="beneficiary['Type_Code'] == 'DISTR'">
          <mat-icon class="fas fa-truck"></mat-icon> Distributor
        </span>
        : {{ beneficiary.CalculatedName }}
      </p>
      <hr *ngIf="beneficiary['ACN'] || beneficiary['ABN']"/>
      <div class="row clearfix flex">
        <div class="row clearfix full-width text-center" *ngIf="beneficiary['ACN']">
          <p class="small rm-m">
            ACN: <strong>{{ beneficiary['ACN'] }}</strong>
          </p>
        </div>
        <div class="row clearfix full-width text-center" *ngIf="beneficiary['ABN']">
          <p class="small rm-m">
            ABN: <strong>{{ beneficiary['ABN'] }}</strong>
          </p>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <!-- is not a modal -->

  <div class="stacked-card-view not-modal" *ngIf="isModal == false">
    <div class="card-header primary-gradient-img clearfix stacked">
      <div class="row clearfix">
        <div class="row titleArea clearfix">
          <app-ep-document-view-image
            class="supplierIcon pull-left"
            [defaultPicture]="
              'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/default-corporate-image.png)'
            "
            [hideZoomButton]="false"
            [link]="profileLink"
          >
          </app-ep-document-view-image>
          <h2 class="summary-header rm-m">
            <!-- {{ beneficiary.CalculatedName}} -->
            {{ beneficiary['Name'] }}
            <span class="subLabel" *ngIf="beneficiary['TradingAs']">Trading as: {{ beneficiary['TradingAs'] }}</span>
            <span class="subLabel" *ngIf="beneficiary['TrusteeFor']">Trustee for: {{ beneficiary['TrusteeFor'] }}</span>
          </h2>
          <p class="clearfix small rm-mb" *ngIf="beneficiary['Active'] == '1' && isPromoterOrAdmin == true">
            <span class="chip-enable">Activated</span>
          </p>
          <p class="clearfix small rm-mb" *ngIf="beneficiary['Active'] == '0' && isPromoterOrAdmin == true">
            <span class="chip-disable">Not Activated</span>
          </p>
        </div>
      </div>
      <div class="action-button">
        <button class="actionMenuToggle" [matMenuTriggerFor]="btnPatMenu" mat-raised-button>
          Actions <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #btnPatMenu="matMenu">
          <button *ngIf="isPromoterOrAdmin == false" (click)="viewSupplier()" mat-menu-item>
            <mat-icon>search</mat-icon>
            <span>View Details</span>
          </button>

          <button (click)="openCatelogueVIew()" mat-menu-item>
            <mat-icon class="fas fa-newspaper"></mat-icon>
            <span>View  Catalogue</span>
          </button>

          <button *ngIf="isPromoterOrAdmin == true" (click)="editSupplier()" mat-menu-item>
            <mat-icon>edit</mat-icon>
            <span>Edit Supplier</span>
          </button>

          <button *ngIf="isPromoterOrAdmin == true" (click)="viewBankAccounts()" mat-menu-item>
            <mat-icon class="fas fa-wallet"></mat-icon>
            <span>View Bank Accounts</span>
          </button>
          <button
            class=""
            *ngIf="beneficiary['emails.Email']"
            (click)="openMailTo()"
            mat-menu-item
            color="accent"
            matTooltip="Contact {{ beneficiary.CalculatedName }}"

          >
            <mat-icon>contact_mail</mat-icon>
            <span>Email Supplier</span>
          </button>

          <button
            *ngIf="beneficiary['addresses.Calculated']"
            (click)="displayMap()"
            mat-menu-item
            color="accent"
            matTooltip="View on map"
          >
            <mat-icon>place</mat-icon>
            <span>View on map</span>
          </button>
          <button *ngIf="beneficiary['Active'] == '1' && isPromoterOrAdmin == true" (click)="disable()" mat-menu-item>
            <mat-icon>cancel</mat-icon>
            <span>Disable</span>
          </button>
          <button *ngIf="beneficiary['Active'] == '0' && isPromoterOrAdmin == true" (click)="enable()" mat-menu-item>
            <mat-icon>check_circle</mat-icon>
            <span>Enable</span>
          </button>
        </mat-menu>
      </div>
    </div>

    <div
      class="grey-row upper mb"
      *ngIf="beneficiary['Facebook'] || beneficiary['Twitter'] || beneficiary['LinkedIn'] || beneficiary['Instagram']"
    >
      <div class="row clearfix socials">
        <!-- open social links in new tab -->
        <button
          *ngIf="beneficiary['Facebook']"
          (click)="openSocialLink(beneficiary['Facebook'])"
          mat-icon-button
          matTooltip="Visit Facebook page"
        >
          <mat-icon class="fab fa-facebook"></mat-icon>
        </button>
        <button
          *ngIf="beneficiary['Twitter']"
          (click)="openSocialLink(beneficiary['Twitter'])"
          mat-icon-button
          matTooltip="Visit Twitter page"
        >
          <mat-icon class="fab fa-twitter"></mat-icon>
        </button>
        <button
          *ngIf="beneficiary['LinkedIn']"
          (click)="openSocialLink(beneficiary['LinkedIn'])"
          mat-icon-button
          matTooltip="Visit LinkedIn page"
        >
          <mat-icon class="fab fa-linkedin"></mat-icon>
        </button>
        <button
          *ngIf="beneficiary['Instagram']"
          (click)="openSocialLink(beneficiary['Instagram'])"
          mat-icon-button
          matTooltip="Visit Instagram page"
        >
          <mat-icon class="fab fa-instagram"></mat-icon>
        </button>
      </div>
    </div>

    <div class="row clearfix details flex" *ngIf="beneficiary['addresses.Calculated']">
      <mat-icon class="ico">room</mat-icon>
      <div class="row-content clicktext" (click)="displayMap()" matTooltip="Click to view on map">
        <h4 class="rm-m">{{ beneficiary['addresses.Calculated'] }}</h4>
        <p class="small">Address</p>
      </div>
    </div>

    <div class="mb clearfix row flex wrapped">
      <div class="row clearfix details flex" *ngIf="beneficiary['Phone.Work.Number']">
        <mat-icon class="ico">phone</mat-icon>
        <div class="row-content">
          <h4 class="rm-m">{{ beneficiary['Phone.Work.Number'] | customPhone: 'landLine' }}</h4>
          <p class="small">Business Phone</p>
        </div>
      </div>

      <div class="row clearfix details flex" *ngIf="beneficiary['mobiles.Number']">
        <mat-icon class="ico">phone_android</mat-icon>
        <div class="row-content">
          <h4 class="rm-m">{{ beneficiary['mobiles.Number'] | customPhone: 'landLine' }}</h4>
          <p class="small">Mobile</p>
        </div>
      </div>

      <div class="row clearfix details flex" *ngIf="beneficiary['emails.Email']">
        <mat-icon class="ico">email</mat-icon>
        <div class="row-content clicktext" (click)="openMailTo()" matTooltip="Click to create email">
          <h4 class="rm-m">{{ beneficiary['emails.Email'] }}</h4>
          <p class="small">Email</p>
        </div>
      </div>

      <div class="row clearfix details flex" *ngIf="beneficiary['URL']">
        <mat-icon class="ico">devices</mat-icon>
        <div class="row-content clicktext">
          <h4 class="rm-m" (click)="openSocialLink(beneficiary['URL'])" matTooltip="Click to visit website">
            {{ beneficiary['URL'] }}
          </h4>
          <p class="small">Website</p>
        </div>
      </div>

      <!-- <div *ngIf="beneficiary['Occupation'] && isPromoterOrAdmin==true" class="row clearfix details flex">
      <mat-icon class="ico">store</mat-icon>
      <div class="row-content">
        <h4 class="rm-m">{{beneficiary['Occupation'] }}</h4>
        <p class="small">{{ "Occupation" | translate }}</p>
      </div>
    </div>

    <div *ngIf="beneficiary['CompanyType'] && isPromoterOrAdmin==true" class="row clearfix details flex">
      <mat-icon class="ico">business_center</mat-icon>
      <div class="row-content">
        <h4 class="rm-m">{{beneficiary['CompanyType'] }}</h4>
        <p class="small">{{ "Company Type" | translate }}</p>
      </div>
    </div> -->
    </div>

    <div class="grey-row" *ngIf="abnDetails == true && isPromoterOrAdmin == true">
      <p class="small rm-m">
        <span class="baseLabel" *ngIf="beneficiary['Type_Code'] == 'BENE'">
          <mat-icon class="fas fa-parachute-box"></mat-icon> Supplier
        </span>
        <span class="baseLabel" *ngIf="beneficiary['Type_Code'] == 'MANU'">
          <mat-icon class="fas fa-industry"></mat-icon> Manufacturer
        </span>
        <span class="baseLabel" *ngIf="beneficiary['Type_Code'] == 'DISTR'">
          <mat-icon class="fas fa-truck"></mat-icon> Distributor
        </span>
        : {{ beneficiary.CalculatedName }}
      </p>
      <hr *ngIf="beneficiary['ACN'] || beneficiary['ABN']"/>
      <div class="row clearfix flex">
        <div class="row clearfix full-width text-center" *ngIf="beneficiary['ACN']">
          <p class="small rm-m">
            ACN: <strong>{{ beneficiary['ACN'] }}</strong>
          </p>
        </div>
        <div class="row clearfix full-width text-center" *ngIf="beneficiary['ABN']">
          <p class="small rm-m">
            ABN: <strong>{{ beneficiary['ABN'] }}</strong>
          </p>
        </div>
      </div>
    </div>
  </div>
</mat-card>
