import { Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-reset-user-name',
  templateUrl: './reset-user-name.component.html',
  styleUrls: ['./reset-user-name.component.css'],
})
export class ResetUserNameComponent implements OnInit {
  closeModal = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
