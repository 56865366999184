import { Component, OnInit, EventEmitter, Input, Optional, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { BeneficiaryService } from '../../beneficiary/shared/beneficiary.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { BeneficiaryViewComponent } from '../../beneficiary/beneficiary-view/beneficiary-view.component';

@Component({
  selector: 'app-settlement-thirdparty-modal',
  templateUrl: './settlement-thirdparty-modal.component.html',
  styleUrls: ['./settlement-thirdparty-modal.component.css']
})
export class SettlementThirdPartyModalComponent implements OnInit {

  multipleSuppliers = [] 

  beneficiaryID;
  bankAccountID;

  beneficiary;
  bankAccount;

  closeModal = new EventEmitter();
  totalAmountEvnet = new EventEmitter();

  thirdpartyList = ['The Ortho Partners', 'The A Partners', 'The B Partners'];
  isSlide = false;

  @Input()
  maxAmount;

  @Input()
  amount;


  totalAmount;

  isPromoterOrAdmin = false;

  beneficiaries = [];
  bankAccounts = [];

  bankAccountListDescription = "Please click on a bank account from the following list to select it ";
  bankAccountListTitle = "Select a bank account";

  @Output()
  getBankAccount = new EventEmitter();


  @Output()
  getBeneficiary = new EventEmitter();



  @Output()
  getResult = new EventEmitter();

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private beneficiaryService: BeneficiaryService,
    private authenticationService: AuthenticationService) {
    if (data) {
      if (data.beneficiaryID) {
        this.beneficiaryID = data.beneficiaryID;
      }
      if (data.bankAccountID) {
        this.bankAccountID = data.bankAccountID;
      }

      if (data.maxAmount) {
        this.maxAmount = data.maxAmount;
      }



      if (data.amount) {
        this.amount = data.amount;
      }



    }

  }

  ngOnInit() {

    this.authenticationService.isPromoterOrAdmin().subscribe(pOrA => {
      this.isPromoterOrAdmin = pOrA;

      let payload = {
        active: true,
        hasLabel: true,
        isBeneficiary: true,
        orderBy: 'Label'
      }

      this.beneficiaryService.getBeneficiaryList(payload, this.isPromoterOrAdmin).subscribe(res => {

        if (res && res.length > 0) {

          this.beneficiaries = res;

          if (this.beneficiaryID) {

            for (var i = 0; i < this.beneficiaries.length; i++) {

              if (this.beneficiaries[i]['ID'] == this.beneficiaryID) {
                this.beneficiary = this.beneficiaries[i];
                break;
              }
            }
          }


          this.sum();
        }

      })



    })



  }






  close() {
    this.closeModal.emit(true);
  }



  addSupplier() {
    // let item = {
    //   name: "",
    //   amount: "",
    //   bsbNumber: "001-001",
    //   accountNumber: "123456"
    // };

    // this.multipleSuppliers.push(item);
    // this.sum();
  }

  removeSupplier(index) {
    // this.multipleSuppliers.splice(index, 1);
    // this.sum();
  }

 

  toNumber(n) {
    if (n)
      return Number(n)
    else

      return 0
  }


  sum() {
    // this.totalAmount = this.multipleSuppliers.map(x => x.amount).reduce((a, b) => a + b, 0)
  }

  valuechange(e) {
    this.sum();
  }


  getActionEvent(a) {
    if (a) {

      // this.getBankAccount.emit(a);
      this.bankAccountID = a.ID;
      this.bankAccount = a;
    }
  }

  beneficiaryChange() {
    if (this.beneficiary) {
      // this.getBeneficiary.emit(this.beneficiary)
      this.beneficiaryID = this.beneficiary['ID']
    }

  }

  sendFundAmountEvent() {
    this.getBeneficiary.emit(this.beneficiary)
    this.getBankAccount.emit(this.bankAccount);

    let p = {
      beneficiary: null,
      bankAccount: null,
      amount: 0
    };

    if (this.beneficiary) {
      p.beneficiary = this.beneficiary;
    }


    if (this.bankAccount) {
      p.bankAccount = this.bankAccount;
    }

    p.amount = this.amount || 0;

    this.getResult.emit(p)



  }

  viewBeneficiary() {


    let ref = RootAppComponent.dialog.open(BeneficiaryViewComponent, {
      data: this.beneficiary['ID'],
      width: "600px"
    });

    ref.componentInstance.close.subscribe(res => {

      ref.close()

    })

  }



}
