import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CardRoutingModule } from './shared/card-routing.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { OperatorModule } from '../operator/operator.module';
import { SharedModule } from '../../shared/shared.module';
import { NgxGaugeModule } from 'ngx-gauge';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { MatSortModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArchwizardModule } from 'angular-archwizard';
import { ClipboardModule } from 'ngx-clipboard';
import { CdkTableModule } from '@angular/cdk/table';
import { MomentModule } from 'ngx-moment';
import { NgPipesModule } from 'ngx-pipes';
import { CardPictureComponent } from './card-picture/card-picture.component';
import { CardService } from './shared/card.service';
import { InvitationCustomerProspectViewPictureComponent } from './invitation-customer-prospect-view-picture/invitation-customer-prospect-view-picture.component';


@NgModule({
  declarations: [
    CardPictureComponent,
    InvitationCustomerProspectViewPictureComponent

  ],
  imports: [
    CommonModule,
    NgPipesModule,
    MomentModule,
    CdkTableModule,
    ClipboardModule,
    FormsModule,
    ArchwizardModule,
    ReactiveFormsModule,
    MatSortModule,
    PinchZoomModule,
    NgxGaugeModule,
    SharedModule,
    OperatorModule,
    EditorModule,
    CardRoutingModule,
  ],
  exports:[

    CardPictureComponent,
    InvitationCustomerProspectViewPictureComponent
  ],
  providers: [
    CardService
  ]
})
export class CardModule { }
