<button [matMenuTriggerFor]="menuInvitationActions" *ngIf="buttonType=='action'" mat-icon-button>
  <mat-icon>more_vert</mat-icon>
</button>

<button [matMenuTriggerFor]="menuInvitationActions" color="accent" class="btn invitation-action-button-list"
  *ngIf="buttonType=='buttonAccent'" mat-raised-button>
  {{buttonLabel}} <mat-icon class="sml">more_vert</mat-icon>
</button>

<button [matMenuTriggerFor]="menuInvitationActions" color="primary" class="btn invitation-action-button-list"
  *ngIf="buttonType=='buttonPrimary'" mat-raised-button>
  {{buttonLabel}} <mat-icon class="sml">more_vert</mat-icon>
</button>

<mat-menu class="actionMenu" #menuInvitationActions="matMenu">

  <div *ngFor="let item of items">


    <button [matMenuTriggerFor]="epVerMenu4800989898"
      *ngIf="isPromoterOrAdmin == true && contractID && item=='contract' " mat-menu-item>
      <mat-icon class="smr">description</mat-icon>
      Contract
    </button>
    <mat-menu #epVerMenu4800989898="matMenu">


      <button *ngIf="contractID" (click)="quickViewContract(contractID)" mat-menu-item>

        <mat-icon class="smr">search</mat-icon>
        <span> Quick View </span>
      </button>

      <button *ngIf="contractID && displayContractDetails==true" (click)="openDetailsViewEPContract(contractID)"
        mat-menu-item>

        <mat-icon class="smr">description</mat-icon>
        <span> Detailed view </span>
      </button>

      <mat-divider></mat-divider>


      <button *ngIf="productID" (click)="openProductView(productID)" mat-menu-item>
        <mat-icon class="smr">business_center</mat-icon>
        <span>View Product</span>
      </button>


      <mat-divider *ngIf="productID"></mat-divider>
      <button (click)="viewContractLogs(contractID)" mat-menu-item>
        <mat-icon class="fa fa-list-alt smr"></mat-icon>
        <span>View Logs</span>
      </button>


      <mat-divider></mat-divider>

      <button (click)="viewMessagesContract(contractID)" mat-menu-item>
        <mat-icon class="fas fa-directions smr"></mat-icon>
        <span>View Events</span>
      </button>

    </mat-menu>

    <mat-divider *ngIf="isPromoterOrAdmin == true && contractID && item=='contract' "></mat-divider>
    <button [matMenuTriggerFor]="messageMenuInvitation4800989898" *ngIf="invitationID  && item=='invitation'  "
      mat-menu-item>
      <mat-icon class="fas fa-envelope smr"></mat-icon>
      {{"Invitation" | translate}}
    </button>

    <mat-menu #messageMenuInvitation4800989898="matMenu">

      <button *ngIf="invitationID" (click)="openInvitationView(invitationID)" mat-menu-item>

        <mat-icon class="smr">search</mat-icon>
        <span> Quick View </span>
      </button>
      <button
        *ngIf="customerID && customerID!='00000000000000000000000000000000' && displayInvitationDetails==true && customerID!='20202020202020202020202020202020'"
        (click)="fullInvitationDetails(invitationID , customerID)" mat-menu-item>
        <mat-icon class="smr">description</mat-icon>
        <span> Detailed view </span>
      </button>



      <mat-divider *ngIf=" isPromoterOrAdmin==true"></mat-divider>


      <button *ngIf="invitationID && isPromoterOrAdmin==true" (click)="wakandaFiles(invitationID)" mat-menu-item>
        <mat-icon class="smr">attach_file</mat-icon>
        <span>Wakanda Files</span>
      </button>

      <button *ngIf="(invitationID || (invitation && invitation.ID))  && isPromoterOrAdmin==true" (click)="redirect()"
        mat-menu-item>
        <mat-icon class="smr">link</mat-icon>
        <span>Invitation link</span>
      </button>

      <mat-divider></mat-divider>

      <button *ngIf=" invitation &&
         invitation['Status.Invitation.Short_NE'] != 'Expired' &&
         invitation['WakandaID'] &&

    isModuleTreatmentTemplateActive == true
  " (click)="createTemplate(invitationID)" mat-menu-item>
        <mat-icon class="smr">assignment</mat-icon>
        <span>Create {{"KEYWORD.Treatment" | translate}} Template</span>
      </button>


      <button *ngIf="invitationID" (click)="resendInvitation(invitationID)" mat-menu-item>
        <mat-icon class="smr">refresh</mat-icon>
        <span>Resend Invitation</span>
      </button>


      <button
        *ngIf="customerID && customerID!='00000000000000000000000000000000' && customerID!='20202020202020202020202020202020'"
        (click)="openCloneInvitationModal(invitationID, customerID)" mat-menu-item>
        <mat-icon class="smr">file_copy</mat-icon>
        <span>Clone</span>
      </button>




      <mat-divider></mat-divider>
      <button (click)="viewMessagesInvitation(invitationID)" mat-menu-item>
        <mat-icon class="fas fa-directions smr"></mat-icon>
        <span>View Events</span>
      </button>



    </mat-menu>





    <div *ngIf="invitation && invitation.ID &&  item=='invitation' && isPromoterOrAdmin!=true"
      [hidden]="invitation['Date.Commenced'] != '0000-00-00'">
      <button [matMenuTriggerFor]="submenuMerchant" mat-menu-item>
        <mat-icon>help_outline</mat-icon>
        Set {{'Invitation' | translate}} Status
      </button>
      <mat-menu #submenuMerchant="matMenu">
        <button *ngIf="
            context != 'retail' &&
            invitation['Voided'] == '0' &&
            invitation['Status.Invitation.Short_NE'] != 'Discuss/Modify' &&
            invitation['Status.Invitation.Short_NE'] != 'Submitted' &&
            invitation['Status.Invitation.Short_NE'] != 'Proceeded' &&
            invitation['Status.Invitation.Short_NE'] != 'Pay Cash/Card' &&
            invitation['Status.Invitation.Short_NE'] != 'Expired' &&
            invitation['Status.Invitation.Short_NE'] != 'Not Proceeding' &&
            invitation['Status.Invitation.Short_NE'] != 'Intends to Proceed' &&
            invitation['Status.Invitation.Short_NE'] != 'Via Web' &&
            invitation['Status.Invitation.Short_NE'] != 'Proceed - Cash/Card-Discount' &&
            invitation['Status.Invitation.Short_NE'] != 'Proceed - Cash/Card-NoDiscount'
          " (click)="payCashInvitationMerchant(invitation['ID'])" mat-menu-item>
          <mat-icon>thumb_up</mat-icon>
          Proceeding with Cash
        </button>
        <button *ngIf="
            context != 'retail' &&
            invitation['CashDiscount.Offered.YesNo'] == '1' &&
            invitation['Voided'] == '0' &&
            invitation['Status.Invitation.Short_NE'] != 'Discuss/Modify' &&
            invitation['Status.Invitation.Short_NE'] != 'Submitted' &&
            invitation['Status.Invitation.Short_NE'] != 'Proceeded' &&
            invitation['Status.Invitation.Short_NE'] != 'Pay Cash/Card' &&
            invitation['Status.Invitation.Short_NE'] != 'Expired' &&
            invitation['Status.Invitation.Short_NE'] != 'Not Proceeding' &&
            invitation['Status.Invitation.Short_NE'] != 'Intends to Proceed' &&
            invitation['Status.Invitation.Short_NE'] != 'Via Web' &&
            invitation['Status.Invitation.Short_NE'] != 'Proceed - Cash/Card-Discount' &&
            invitation['Status.Invitation.Short_NE'] != 'Proceed - Cash/Card-NoDiscount'
          " (click)="payCashInvitationWithDiscountMerchant(invitation['ID'])" mat-menu-item
          matTooltip="Proceeding with Cash plus Discount">
          <mat-icon>thumb_up</mat-icon>
          Proceeding with Cash plus Discount
        </button>
        <button *ngIf="
            context != 'retail' &&
            invitation['Voided'] == '0' &&
            invitation['Status.Invitation.Short_NE'] != 'Discuss/Modify' &&
            invitation['Status.Invitation.Short_NE'] != 'Submitted' &&
            invitation['Status.Invitation.Short_NE'] != 'Proceeded' &&
            invitation['Status.Invitation.Short_NE'] != 'Pay Cash/Card' &&
            invitation['Status.Invitation.Short_NE'] != 'Expired' &&
            invitation['Status.Invitation.Short_NE'] != 'Not Proceeding' &&
            invitation['Status.Invitation.Short_NE'] != 'Intends to Proceed' &&
            invitation['Status.Invitation.Short_NE'] != 'Via Web' &&
            invitation['Status.Invitation.Short_NE'] != 'Proceed - Cash/Card-Discount' &&
            invitation['Status.Invitation.Short_NE'] != 'Proceed - Cash/Card-NoDiscount'
          " (click)="deferInvitationMerchant(invitation['ID'])" mat-menu-item>
          <mat-icon>alarm</mat-icon>
          Deferring
        </button>
        <button *ngIf="
            context != 'retail' &&
            invitation['Voided'] == '0' &&
            invitation['Status.Invitation.Short_NE'] != 'Discuss/Modify' &&
            invitation['Status.Invitation.Short_NE'] != 'Submitted' &&
            invitation['Status.Invitation.Short_NE'] != 'Proceeded' &&
            invitation['Status.Invitation.Short_NE'] != 'Pay Cash/Card' &&
            invitation['Status.Invitation.Short_NE'] != 'Expired' &&
            invitation['Status.Invitation.Short_NE'] != 'Not Proceeding' &&
            invitation['Status.Invitation.Short_NE'] != 'Intends to Proceed' &&
            invitation['Status.Invitation.Short_NE'] != 'Via Web' &&
            invitation['Status.Invitation.Short_NE'] != 'Proceed - Cash/Card-Discount' &&
            invitation['Status.Invitation.Short_NE'] != 'Proceed - Cash/Card-NoDiscount'
          " (click)="changesToInvitationMerchant(invitation['ID'])" mat-menu-item>
          <mat-icon>edit</mat-icon>
          Requires Changes
        </button>
        <button *ngIf="
            context != 'retail' &&
            invitation['Voided'] == '0' &&
            invitation['Status.Invitation.Short_NE'] != 'Discuss/Modify' &&
            invitation['Status.Invitation.Short_NE'] != 'Submitted' &&
            invitation['Status.Invitation.Short_NE'] != 'Proceeded' &&
            invitation['Status.Invitation.Short_NE'] != 'Pay Cash/Card' &&
            invitation['Status.Invitation.Short_NE'] != 'Expired' &&
            invitation['Status.Invitation.Short_NE'] != 'Not Proceeding' &&
            invitation['Status.Invitation.Short_NE'] != 'Intends to Proceed' &&
            invitation['Status.Invitation.Short_NE'] != 'Via Web' &&
            invitation['Status.Invitation.Short_NE'] != 'Proceed - Cash/Card-Discount' &&
            invitation['Status.Invitation.Short_NE'] != 'Proceed - Cash/Card-NoDiscount'
          " (click)="notProceedingInvitationMerchant(invitation['ID'])" mat-menu-item>
          <mat-icon>report_problem</mat-icon>
          Not Proceeding
        </button>
        <button *ngIf="
            (context != 'retail' &&
              (invitation['Status.Invitation.Short_NE'] == 'Discuss/Modify' ||
                invitation['Status.Invitation.Short_NE'] == 'Not Proceeding' ||
                invitation['Status.Invitation.Short_NE'] == 'Pay Cash/Card')) ||
            invitation['Status.Invitation.Short_NE'] == 'Proceed - Cash/Card-Discount' ||
            invitation['Status.Invitation.Short_NE'] == 'Proceed - Cash/Card-NoDiscount'
          " (click)="resetPatientChoiceMerchant(invitation['ID'], invitation['Voided'], invitation['Prospect_key'])"
          mat-menu-item>
          <mat-icon>settings_backup_restore</mat-icon>
          Reset {{ "KEYWORD.patient" | translate | titlecase }} Decision
        </button>
        <mat-divider></mat-divider>
        <button *ngIf="

            displayDeleteInvitation == true &&
            invitation['Voided'] == '0' &&
            invitation['Status.Invitation.Short_NE'] != 'Submitted' &&
            invitation['Status.Application.Short_NE']?.indexOf('Contract -') == -1 &&
            invitation['Status.Invitation.Short_NE'] != 'Proceeded' &&
            invitation['Status.Invitation.Short_NE'] != 'Pay Cash/Card'
          " (click)="deleteInvitationMerchant(invitation['ID'])" mat-menu-item>
          <mat-icon class="warn-color">delete</mat-icon>
          <span class="warn-color">Archive</span>
        </button>
      </mat-menu>
    </div>




    <div *ngIf="invitation && invitation.ID &&  item=='invitation' && isPromoterOrAdmin==true"
      [hidden]="invitation['Date.Commenced'] != '0000-00-00' || invitation['Voided'] != '0'">
      <button [matMenuTriggerFor]="submenu100" mat-menu-item>
        <mat-icon>help_outline</mat-icon>
        Set status
      </button>
      <mat-menu #submenu100="matMenu">
        <button *ngIf="context != 'retail'" (click)="payCashInvitation(invitation['ID'])" mat-menu-item>
          <mat-icon>thumb_up</mat-icon>
          Proceeding with Cash
        </button>
        <button *ngIf="context != 'retail' && invitation['CashDiscount.Offered.YesNo'] == '1'"
          (click)="payCashInvitationWithDiscount(invitation['ID'])" mat-menu-item
          matTooltip="Proceeding with Cash plus Discount">
          <mat-icon>thumb_up</mat-icon>
          Proceeding with Cash plus Discount
        </button>
        <button *ngIf="context != 'retail'" (click)="deferInvitation(invitation['ID'])" mat-menu-item>
          <mat-icon>alarm</mat-icon>
          Deferring
        </button>
        <button *ngIf="context != 'retail'" (click)="changesToInvitation(invitation['ID'])" mat-menu-item>
          <mat-icon>edit</mat-icon>
          Requires Changes
        </button>
        <button (click)="notProceedingInvitation(invitation['ID'])" mat-menu-item>
          <mat-icon>report_problem</mat-icon>
          Not Proceeding
        </button>
        <button *ngIf="
            (context != 'retail' &&
              (invitation['Status.Invitation.Short_NE'] == 'Discuss/Modify' ||
                invitation['Status.Invitation.Short_NE'] == 'Not Proceeding' ||
                invitation['Status.Invitation.Short_NE'] == 'Pay Cash/Card')) ||
            invitation['Status.Invitation.Short_NE'] == 'Proceed - Cash/Card-Discount' ||
            invitation['Status.Invitation.Short_NE'] == 'Proceed - Cash/Card-NoDiscount'
          " (click)="resetPatientChoice(invitation['ID'], invitation['Voided'], invitation['Prospect_key'])"
          mat-menu-item>
          <mat-icon>settings_backup_restore</mat-icon>
          Reset {{ "KEYWORD.patient" | translate | titlecase }} Decision
        </button>
        <mat-divider></mat-divider>
        <button *ngIf="
            invitation['Voided'] == '0' &&
            invitation['Status.Invitation.Short_NE'] != 'Submitted' &&
            invitation['Status.Application.Short_NE']?.indexOf('Contract -') == -1 &&
            invitation['Status.Invitation.Short_NE'] != 'Proceeded' &&
            invitation['Status.Invitation.Short_NE'] != 'Pay Cash/Card'
          " (click)="deleteInvitation(invitation['ID'])" mat-menu-item>
          <mat-icon class="warn-color">delete</mat-icon>
          <span class="warn-color">Archive</span>
        </button>
      </mat-menu>
    </div>

    <button
      *ngIf="isModulePatientPortalAccessActive == true && invitationID && item=='invitation' &&  customerID && customerID!='00000000000000000000000000000000' && customerID!='20202020202020202020202020202020' "
      (click)="openAuthCodeDialog(invitationID, customerID, merchantID)" mat-menu-item>
      <mat-icon>vpn_key</mat-icon>
      <span>{{"KEYWORD.patient" | translate | titlecase}} Portal Access</span>
    </button>


    <mat-divider
      *ngIf="isModulePatientPortalAccessActive == true && (invitationID ||(invitation && invitation.ID))  && item=='invitation' ">
    </mat-divider>



    <button [matMenuTriggerFor]="messageMenuCustomer4800989898"
      *ngIf="customerID  &&  customerID && customerID!='00000000000000000000000000000000' && customerID!='20202020202020202020202020202020' && item=='customer' "
      mat-menu-item>
      <mat-icon class="fas fa-user smr"></mat-icon>
      {{'Patient' | translate}}
    </button>
    <mat-menu #messageMenuCustomer4800989898="matMenu">

      <button *ngIf="customerID" (click)="quickView(customerID)" mat-menu-item>
        <mat-icon class="smr">search</mat-icon>
        <span> Quick view</span>
      </button>

      <button *ngIf="customerID" (click)="openCustomerView(customerID)" mat-menu-item>
        <mat-icon class="smr">description</mat-icon>
        <span> Detailed view </span>
      </button>



      <mat-divider></mat-divider>

      <button
        *ngIf="isPromoterOrAdmin==true && isModulePatientSMSActive == true && customerID  &&  customerID && customerID!='00000000000000000000000000000000' && customerID!='20202020202020202020202020202020'"
        (click)="sendSMS(customerID,merchantID)" mat-menu-item>
        <mat-icon class="far fa-comment-dots smr"></mat-icon>
        <span>Send SMS</span>
      </button>


      <button
        *ngIf="isPromoterOrAdmin!=true && isModulePatientSMSActive == true && customerID  &&  customerID && customerID!='00000000000000000000000000000000' && customerID!='20202020202020202020202020202020'"
        (click)="sendSMSMerchant(customerID)" mat-menu-item>
        <mat-icon class="far fa-comment-dots smr"></mat-icon>
        <span>Send SMS</span>
      </button>

      <button
        *ngIf=" customerID  &&  customerID && customerID!='00000000000000000000000000000000' && customerID!='20202020202020202020202020202020'"
        (click)="sendEmail(customerID)" mat-menu-item>
        <mat-icon class="fas fa-at smr"></mat-icon>
        <span>Send Email</span>
      </button>


      <mat-divider></mat-divider>

      <button (click)="viewMessages(customerID)" mat-menu-item>
        <mat-icon class="fas fa-directions smr"></mat-icon>
        <span>View Events</span>
      </button>


    </mat-menu>


    <mat-divider
      *ngIf="customerID  &&  customerID && customerID!='00000000000000000000000000000000' && customerID!='20202020202020202020202020202020' && item=='customer' ">
    </mat-divider>

    <button [matMenuTriggerFor]="messageMenuDesntist4800989898" *ngIf="dentistID && item=='dentist'  " mat-menu-item>
      <mat-icon class="       {{'fas fa-tooth' | translate }} smr"></mat-icon>
      {{'Dentist' | translate}}
    </button>
    <mat-menu #messageMenuDesntist4800989898="matMenu">

      <button *ngIf="dentistID" (click)="viewDentist(dentistID)" mat-menu-item>
        <mat-icon class="smr">search</mat-icon>
        <span> Quick view</span>
      </button>

      <mat-divider></mat-divider>

      <button *ngIf="isModulePatientSMSActive == true && dentistID" (click)="contactSMSDentist(dentistID)"
        mat-menu-item>
        <mat-icon class="far fa-comment-dots smr"></mat-icon>
        <span>Send SMS</span>
      </button>



      <button *ngIf=" dentistID" (click)="contactDentist(dentistID)" mat-menu-item>
        <mat-icon class="fas fa-at smr"></mat-icon>
        <span>Send Email</span>
      </button>


    </mat-menu>



    <mat-divider *ngIf="dentistID && item=='dentist' "></mat-divider>


    <button *ngIf="merchantID  && item=='merchant' && isPromoterOrAdmin==true " (click)="viewMerchant(merchantID)"
      mat-menu-item>
      <mat-icon class="fas fa-building smr"></mat-icon>
      View {{'merchant' | translate}}
    </button>



    <mat-divider *ngIf="merchantID  && item=='merchant' && isPromoterOrAdmin==true "></mat-divider>

    <button *ngIf="instantOfferID  && item=='instantOffer' " (click)="viewInstantOffer(instantOfferID)" mat-menu-item>
      <mat-icon class="fas fa-dollar-sign"></mat-icon>
      <span>View Finance Offer</span>
    </button>

    <mat-divider *ngIf="instantOfferID  && item=='instantOffer' "></mat-divider>

    <button color="accent"
      *ngIf="invitationID && isModuleAppointmentActive == true  && item=='appointment'   && (isAppointmentDisabled != true || isPromoterOrAdmin==true)"
      (click)="requestAppointment(invitationID)" mat-menu-item>
      <mat-icon>alarm_add</mat-icon>
      <span>New appointment</span>
    </button>


    <mat-divider
      *ngIf="invitationID && isModuleAppointmentActive == true  && item=='appointment'   && (isAppointmentDisabled != true || isPromoterOrAdmin==true)">
    </mat-divider>

    <button *ngIf=" campaignID && item=='campaign'  " (click)="openCampaign(campaignID)" mat-menu-item>
      <mat-icon>language</mat-icon>
      <span>View Campaign</span>
    </button>

    <button *ngIf="landingPageID && item=='landingPage' " (click)="openDocumentLandingPage(landingPageID)"
      mat-menu-item>
      <mat-icon class="fas fa-folder-open"></mat-icon>
      <span>View Landing Page</span>
    </button>



    <mat-divider *ngIf="landingPageID && item=='landingPage' "></mat-divider>


    <button
      *ngIf=" isPromoterOrAdmin==true && displayNewSettlement == true &&  contract && contract.ID && (  contract['Status.Code'] === 'ACTIVE' ||
      contract['Status.Code'] === 'COMPLETE' ||
      contract['Status.Code'] === 'ARRGMNT' ||
      contract['Status.Code'] === 'RECOVERY') && toNumber(contract['Settlements.NotYetDrawn']) > 0 && item=='settlement'  "
      (click)="createSettlement(contract.ID)" mat-menu-item>
      <mat-icon class="fas fa-file-invoice-dollar smr" aria-hidden="true"></mat-icon>
      <span>New Settlement</span>
    </button>


    <button
      *ngIf=" isPromoterOrAdmin!=true && displayNewSettlement == true  && contract && contract.ID && (     contract['Status.Code'] === 'ACTIVE' ||
      contract['Status.Code'] === 'COMPLETE' ||
      contract['Status.Code'] === 'ARRGMNT') && toNumber(contract['Settlements.NotYetDrawn']) > 0 && item=='settlement'  "
      (click)="createSettlement(contract.ID)" mat-menu-item>
      <mat-icon class="fas fa-file-invoice-dollar smr" aria-hidden="true"></mat-icon>
      <span>New Settlement</span>
    </button>


    <button *ngIf="contract && contract.ID && item=='settlement'   " (click)="settlementListOverview(contract.ID)"
      mat-menu-item>
      <mat-icon class="fas fa-piggy-bank smr" aria-hidden="true"></mat-icon>
      <span>Settlement List</span>
    </button>




    <button [matMenuTriggerFor]="messageMenuSettlement4800989898"
      *ngIf="settlement && settlement.ID  && item=='settlement' " mat-menu-item>
      <mat-icon class="       {{'fas fa-piggy-bank' | translate }} smr"></mat-icon>
      {{'Settlement' | translate}}
    </button>
    <mat-menu #messageMenuSettlement4800989898="matMenu">




      <button *ngIf="settlement && settlement.ID" (click)="openSettlementView( settlement.ID)" mat-menu-item>

        <mat-icon class="smr">search</mat-icon>
        <span> Quick View </span>
      </button>



      <button *ngIf="contractID" (click)="settlementDetails( contractID)" mat-menu-item>

        <mat-icon class="smr">description</mat-icon>
        <span> Detailed view</span>
      </button>





      <mat-divider
        *ngIf="settlement && settlement.ID && settlement['Status.Code'] == 'PEND' && displaySettlementActions==true && isPromoterOrAdmin==true  && item=='settlement' && settlementOnList!=false">
      </mat-divider>

      <button
        *ngIf="settlement && settlement.ID && settlement['Status.Code'] == 'REQU'   && displaySettlementActions==true  && isPromoterOrAdmin==true  && item=='settlement'  && settlementOnList!=false"
        (click)="decideSettlment(settlement)" mat-menu-item>
        <mat-icon class=" fas fa-stamp   smr"></mat-icon>
        <span>Approve/Decline Settlement</span>
      </button>


      <div
        *ngIf="settlement && settlement.ID  && displaySettlementActions==true && (settlement['Status.Code'] == 'PEND' || settlement['Status.Code'] == 'REQU') && item=='settlement' && settlementOnList!=false">
        <mat-divider *ngIf="settlement && settlement.ID && settlement['Status.Code'] == 'PEND'">
        </mat-divider>





        <button *ngIf="settlement  && displaySettlementActions==true && settlement.ID && settlement['Status.Code'] == 'PEND'"
          (click)="requestSettlement(settlement['ID'])" mat-menu-item>
          <mat-icon class=" fas fa-edit   smr"></mat-icon>
          <span>Continue Settlement request</span>
        </button>

        <button
          *ngIf="settlement  && displaySettlementActions==true &&  settlement.ID && (settlement['Status.Code'] == 'PEND' || settlement['Status.Code'] == 'REQU')"
          (click)="withdrawSettlement(settlement['ID'], settlement['DrawDownGroupID'])" mat-menu-item>
          <mat-icon class="  smr">block</mat-icon>
          <span>Withdraw Settlement </span>
        </button>

      </div>




      <mat-divider></mat-divider>

      <button (click)="viewSettlementLogs(settlement.ID)" mat-menu-item>
        <mat-icon class="fa fa-list-alt smr"></mat-icon>
        <span>View Logs</span>
      </button>





    </mat-menu>


    <mat-divider
      *ngIf="settlement && settlement.ID  && displaySettlementActions==true && settlement['Status.Code'] == 'PEND' && isPromoterOrAdmin==true  && item=='settlement' && settlementOnList==false">
    </mat-divider>

    <button
      *ngIf="settlement && settlement.ID  && displaySettlementActions==true && settlement['Status.Code'] == 'REQU'  && isPromoterOrAdmin==true   && item=='settlement' && settlementOnList==false"
      (click)="decideSettlment(settlement)" mat-menu-item>
      <mat-icon class=" fas fa-stamp  accent-color smr"></mat-icon>
      <span>Approve/Decline Settlement</span>
    </button>




    <button
      *ngIf="settlement && settlement.ID   && displaySettlementActions==true&& settlement['Status.Code'] == 'PEND' && item=='settlement' && settlementOnList==false"
      (click)="requestSettlement(settlement['ID'])" mat-menu-item>
      <mat-icon class=" fas fa-edit  accent-color smr"></mat-icon>
      <span>Continue Settlement request</span>
    </button>

    <button
      *ngIf="settlement && settlement.ID  && displaySettlementActions==true && (settlement['Status.Code'] == 'PEND' || settlement['Status.Code'] == 'REQU') && item=='settlement' && settlementOnList==false"
      (click)="withdrawSettlement(settlement['ID'], settlement['DrawDownGroupID'])" mat-menu-item>
      <mat-icon class="  accent-color smr">block</mat-icon>
      <span>Withdraw Settlement </span>
    </button>






    <mat-divider
      *ngIf="((settlement && settlement.ID ) || (contract && contract.ID && contract['Status.Code'] == 'ACTIVE' && toNumber(contract['Settlements.NotYetDrawn']) > 0))  && item=='settlement'">
    </mat-divider>


    <button [matMenuTriggerFor]="messageALoogMenuInvitation4800989898"
      *ngIf="customerID && contractID  && item=='note' " mat-menu-item>
      <mat-icon class="smr">note_add</mat-icon>
      Notes
    </button>

    <mat-menu #messageALoogMenuInvitation4800989898="matMenu">


      <button (click)="createNote(customerID, contractID)" mat-menu-item>
        <mat-icon class="smr">note_add</mat-icon>
        <span>Create Note</span>
      </button>

      <button (click)="viewNotes(customerID)" mat-menu-item>
        <mat-icon class="fas fa-sticky-note smr"></mat-icon>
        <span>View Notes</span>
      </button>


    </mat-menu>
    <mat-divider
      *ngIf="customerID && contractID  && customerID!='00000000000000000000000000000000' && customerID!='20202020202020202020202020202020' && item=='note'">
    </mat-divider>

  </div>
</mat-menu>
