import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { WizardComponent } from 'angular-archwizard';
import { UtilsService } from '../../../shared/services/utils.service';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-settlement-refund-intro-modal',
  templateUrl: './settlement-refund-intro-modal.component.html',
  styleUrls: ['./settlement-refund-intro-modal.component.css'],
})
export class SettlementRefundIntroModalComponent implements OnInit {
  showOnSettlementCreate = JSON.parse(localStorage.getItem('showSettlementCreate'));

  closeModal = new EventEmitter();

  @ViewChild(WizardComponent, { static: false })
  public wizard: WizardComponent;

  isModuleTutorialsActive = Settings.global['isModuleTutorialsActive'];

  step = 1;

  constructor(private utilService: UtilsService) {}

  ngOnInit() {
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleTutorialsActive = access['isModuleTutorialsActive'];
      }
    });
  }

  setStep(e) {
    this.step = e;
  }

  nextStep() {
    this.wizard.goToNextStep();
  }

  prevStep() {
    this.wizard.goToPreviousStep();
  }

  doNotShow() {
    localStorage.setItem('showSettlementCreate', JSON.stringify(this.showOnSettlementCreate));

    this.closeModal.emit(this.showOnSettlementCreate);
  }
}
