<button class="pull-right" (click)="createTask()" mat-raised-button color="accent">Create New Task</button>

<div class="rel" *ngIf="listDB.firstLoadEvent == false">
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>
<div [hidden]="listDB.data.length == 0">
  <div>
    <!-- <button mat-raised-button class="pull-right" color="accent" (click)="createTask()">Create New Task</button> -->
    <h1 class="dataHeader">
      Tasks
      <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
    </h1>
    <mat-card class="list-card">
      <!-- Search -->
      <div class="row clearfix mb searchArea primary-gradient-img">
        <div class="row clearfix">
          <div class="half-width" id="search">
            <mat-icon class="schIcon">search</mat-icon>
            <!-- <mat-form-field class="schSel" appearance="outline">
                <mat-label>Add Filters</mat-label>
                <mat-select class="" matInput placeholder="Add Filters" [formControl]="searchVals" multiple>
                  <mat-select-trigger>
                    {{searchVals.value ? searchVals.value[0] : ''}}
                    <span *ngIf="searchVals.value?.length > 1" class="additional-selection">
                      (+{{searchVals.value.length - 1}} others)
                    </span>
                  </mat-select-trigger>
                  <mat-option *ngFor="let searchVal of searchValList" [value]="searchVal">{{searchVal}}</mat-option>
                </mat-select>
              </mat-form-field> -->
            <mat-form-field class="schSel" appearance="outline">
              <mat-label>Title</mat-label>
              <input
                class="clearfix"
                (input)="setFilter($event.target.value, 'Task.Name')"
                name="Name"
                placeholder="Title"
                matInput
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row clearfix flex well miniWell animated fadeInDown" *ngIf="searchVals.value?.length > 0"></div>
      </div>
      <!-- Table -->
      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->

        <ng-container cdkColumnDef="Name">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Title</mat-header-cell>
          <mat-cell *cdkCellDef="let row" matTooltip="{{ row['Task.Name'] }}"> {{ row['Task.Name'] }} </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Description">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Description</mat-header-cell>
          <mat-cell *cdkCellDef="let row" matTooltip="{{ row['Task.Description'] }}">
            {{ row['Task.Description'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Completed">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Completed</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <span
              class="list-label smaller"
              *ngIf="row['IsCompleted'] == '1'"
              matTooltip="Active"
              style="background-color: rgb(90, 186, 71)"
            >
              <mat-icon>check</mat-icon>
            </span>
            <span
              class="list-label smaller"
              *ngIf="row['IsCompleted'] != '1'"
              matTooltip="De-Active"
              style="background-color: rgb(239, 71, 39)"
            >
              <mat-icon>close</mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button *ngIf="row['ID']" (click)="editTask(row['ID'])" mat-menu-item>
                  <mat-icon>edit</mat-icon>
                  <span>Edit Task</span>
                </button>
                <button *ngIf="row['IsCompleted'] == '0'" (click)="setTaskCompleted(row['ID'])" mat-menu-item>
                  <mat-icon>check_circle</mat-icon>
                  <span>Set Completed</span>
                </button>

                <button *ngIf="row['IsCompleted'] == '1'" (click)="setTaskUncompleted(row['ID'])" mat-menu-item>
                  <mat-icon>remove_circle</mat-icon>
                  <span>Set Not Completed</span>
                </button>
                <button *ngIf="row['Task_key']" (click)="deleteTask(row['Task_key'])" mat-menu-item>
                  <mat-icon>delete</mat-icon>
                  <span>Delete Task</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginator
        [length]="dataSource.filteredData.length"
        [pageIndex]="0"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
      >
      </mat-paginator>
    </mat-card>
  </div>
</div>
<div class="clearfix full-width" *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <app-empty-list-message></app-empty-list-message>
</div>
