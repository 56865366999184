import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { WikiService } from '../shared/wiki.service';
import { WikiGroupEditComponent } from '../wiki-group-edit/wiki-group-edit.component';
import { WikiLeftMenuComponent } from '../wiki-left-menu/wiki-left-menu.component';

@Component({
  selector: 'app-wiki-group-list',
  templateUrl: './wiki-group-list.component.html',
  styleUrls: ['./wiki-group-list.component.css'],
  providers: [CustomDatePipe],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.5s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.5s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class WikiGroupListComponent implements OnInit {
  @Input()
  isActive;

  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  utils = new UtilsClass();

  isPromoterOrAdmin = true;

  displayedColumns = ['selectCheckBox', 'switchers', 'title', 'roles', 'isMenuDisplayed', 'IsActive', 'Actions'];
  filters = [];

  selectedIDs = [];
  searchVals = new FormControl();

  searchValList = ['Label'];

  expandedRow: number;
  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;
  isActiveFilter;
  ShowPagesList = false;
  filteredSize = null;
  selectGroupID;
  pageTitle;

  destroyEvent = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private wikiService: WikiService,
    private dialog: MatDialog,
    private router: Router,
    private customDate: CustomDatePipe,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });

    const params = {
      isActive: this.isActive,
    };

    this.listDB = new LoadRecords(this.wikiService, this.destroyEvent, this.isPromoterOrAdmin, params);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  ngOnChanges(changes: SimpleChanges) {
    const params = {
      isActive: this.isActive,
    };
    this.listDB = new LoadRecords(this.wikiService, this.destroyEvent, this.isPromoterOrAdmin, params);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  setFilter(event, field) {
    let filter;

    if (field == 'ApprovedOrActive') {
      let _event;
      if (event == true) {
        _event = '1';
      } else {
        _event = '0';
      }
      filter = {
        field,
        value: _event,
      };
    } else if (typeof event == 'object' && event != null) {
      const v = this.customDate.transform(event, Settings.global['dateFormat']);
      filter = {
        field,
        value: v,
      };
    } else if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  viewPages(id, label) {
    this.ShowPagesList = true;
    this.selectGroupID = id;
    this.pageTitle = 'Wiki Articles of: ' + label;
  }

  hideViewDetails() {
    this.ShowPagesList = false;
  }

  select(id, isSelected) {
    if (isSelected == true && this.selectedIDs.indexOf(id) == -1) {
      this.selectedIDs.push(id);
    } else {
      const _index = this.selectedIDs.indexOf(id);
      this.selectedIDs.splice(_index, 1);
    }
  }

  selectAll() {
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      const o = this.dataSource.filteredData[i];

      if (o && o['_id'] && this.selectedIDs.indexOf(o['_id']) == -1) {
        this.selectedIDs.push(o['_id']);
      }
    }
  }

  edit(id) {
    const ref = RootAppComponent.dialog.open(WikiGroupEditComponent, {
      data: {
        groupID: id,
      },

      width: '800px',
      panelClass: 'noCard',
    });

    ref.componentInstance.result.subscribe((res) => {
      if (res) {
        this.dataSource.replaceItem = res;
        WikiLeftMenuComponent.updateHeader.emit(true);
      }
      ref.close();
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  moveToGroup(id) {
    if (id) {
      this.router.navigate([
        '/wiki',
        {
          outlets: {
            wikiPageRoute: ['group', id],
          },
        },
      ]);
    }
  }

  selectPage() {
    const index = this.dataSource['_paginator']['index'] || 0;
    const pageSize = this.dataSource['_paginator']['pageSize'] || 0;

    for (let i = 0; i < (index + 1) * pageSize; i++) {
      const o = this.dataSource.filteredData[index * pageSize + i];

      if (o && o['_id'] && this.selectedIDs.indexOf(o['_id']) == -1) {
        this.selectedIDs.push(o['_id']);
      }
    }
  }

  unselect() {
    this.selectedIDs = [];
  }

  isSelected(id) {
    if (!this.selectedIDs || this.selectedIDs.length <= 0) {
      return false;
    } else {
      if (this.selectedIDs.indexOf(id) != -1) {
        return true;
      }
    }

    return false;
  }

  enableGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialog = new ConfirmDialog(
        'check_circle',
        'Enable Selected Categories',
        '<p>Once the Category is enabled  all sub pages will  appear for the user',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              isActive: true,
              ids: this.selectedIDs.join(','),
            };
            this.wikiService.updateWikiGroupAsGroup(payload).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedIDs = [];
                WikiLeftMenuComponent.updateHeader.emit(true);

                NotifyAppComponent.displayToast('success', 'Success!', 'Categories are enabled.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  disableGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialog = new ConfirmDialog(
        'cancel',
        'Disable Selected Categories',
        '<p>Once the Category is disabled it all sub pages will not appear for the user',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              isActive: false,
              ids: this.selectedIDs.join(','),
            };
            this.wikiService.updateWikiGroupAsGroup(payload).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedIDs = [];
                WikiLeftMenuComponent.updateHeader.emit(true);

                NotifyAppComponent.displayToast('success', 'Success!', 'Categories are disabled.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  moveWiki(id, direction) {
    if (id) {
      const p = {
        direction,
      };
      this.wikiService.switchPriorityWikiGroup(id, p).subscribe((res) => {
        if (res) {
          if (res && res.source) {
            this.dataSource.replaceItem = res.source;
          }
          if (res && res.target) {
            this.dataSource.replaceItem = res.target;
          }
          WikiLeftMenuComponent.updateHeader.emit(true);
          NotifyAppComponent.displayToast('success', 'Success!', 'Category is updated');
        }
      });
    }
  }

  removeGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to remove these categories , All sub pages will be removed too ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.wikiService.removeWikiGroupAsGroup(this.selectedIDs).subscribe((res) => {
            if (res && res.length > 0) {
              for (let i = 0; i < res.length; i++) {
                this.dataSource.removeItem = res[i];
              }

              this.selectedIDs = [];
            }
            WikiLeftMenuComponent.updateHeader.emit(true);
            NotifyAppComponent.displayToast('success', 'Success!', 'Categories are removed ');

            ref.close();
          });
        }
      });
    }
  }

  remove(id) {
    if (id) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to remove this Category , All sub pages will be removed too  ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.wikiService.removeWikiGroup(id).subscribe((res) => {
            if (res) {
              const p = {
                _id: id,
              };
              this.dataSource.removeItem = p;

              WikiLeftMenuComponent.updateHeader.emit(true);
            }
            ref.close();
          });
        }
      });
    }
  }

  getActiveLabel(a) {
    if (a == '1') {
      return 'True';
    }

    if (a == '0') {
      return 'False';
    }
  }

  enable(ID) {
    const confirmDialog = new ConfirmDialog(
      'check_circle',
      'Enable Category',
      '<p>Once the Category is enabled it will  appear for  the user</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            isActive: true,
          };
          this.wikiService.updateWikiGroup(ID, payload).subscribe((res) => {
            if (res) {
              this.dataSource.replaceItem = res;
              WikiLeftMenuComponent.updateHeader.emit(true);
              NotifyAppComponent.displayToast('success', 'Success!', 'Category is enabled.');
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  disable(ID) {
    const confirmDialog = new ConfirmDialog(
      'cancel',
      'Disable Category',
      '<p>Once the Category is disabled All sub pages will not  appear for  the user</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            isActive: false,
          };
          this.wikiService.updateWikiGroup(ID, payload).subscribe((res) => {
            if (res) {
              this.dataSource.replaceItem = res;

              WikiLeftMenuComponent.updateHeader.emit(true);

              NotifyAppComponent.displayToast('success', 'Success!', 'Category is disabled.');
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  toNumber(n) {
    if (n) {
      return Number(n);
    } else {
      return 0;
    }
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  isDestoyed = false;

  serviceRef;

  constructor(private WikiService: WikiService, private destroyEvent, private isPromoterOrAdmin, params) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    const payload = {
      section: 0,
      orderBy: 'priority',
      fields: '_id,roles,title,priority,menu,isActive',
    };

    if (params.isActive != null) {
      payload['isActive'] = params.isActive;
    }

    this.serviceRef = this.WikiService.getWikiGroupList(payload, 'admin').subscribe((res) => {
      this.count = res.length;
      this.items = res;

      if (this.items && this.items.length > 0) {
        for (let i = 0; i < this.items.length; i++) {
          this.addExtraDetails(i);
        }
      }

      this.dataChange.next(this.items.slice());
      this.firstLoad = true;
      const innerFunction = (section) => {
        const length = this.items.length;
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.WikiService.getWikiGroupList(payload, 'admin').subscribe((res) => {
          if (res.length > 0) {
            this.count = res.count;
            this.items = this.items.concat(res);

            if (this.items && this.items.length > length) {
              for (let i = length; i < this.items.length; i++) {
                this.addExtraDetails(i);
              }
            }

            this.dataChange.next(this.items.slice());

            if (this.isDestoyed != true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };

      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  addExtraDetails(i) {
    if (this.items && this.items[i]) {
      if (this.items[i]['roles'] && this.items[i]['roles'].length > 0) {
        this.items[i]['fullLabel'] = this.items[i]['title'] + ' ' + this.items[i]['roles'].join(',');
      } else {
        this.items[i]['fullLabel'] = this.items[i]['title'];
      }

      if (this.items[i]['isActive'] == true) {
        this.items[i]['active'] = '1';
      } else {
        this.items[i]['active'] = '0';
      }
    }
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  set removeItem(id) {
    const data = this._tableDatabase.data.filter((row) => row._id != id);
    this._tableDatabase.dataChange.next(data);
  }

  set addItem(res) {
    this._tableDatabase.data.unshift(res);

    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  set replaceItem(item) {
    const _index = this._tableDatabase.data.findIndex((_obj) => _obj._id == item._id);
    this._tableDatabase.data[_index] = item;
    this._tableDatabase.dataChange.next(this._tableDatabase.data);
    this._sort.active = 'priority';
    this._sort.direction = 'asc';

    this.getSortedData(this.filteredData.slice());
  }

  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
          let f = '';

          f = item[this.field] || '';

          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'title':
          [propertyA, propertyB] = [a['title'], b['title']];
          break;

        case 'priority':
          [propertyA, propertyB] = [a['priority'], b['priority']];
          break;

        case 'IsActive':
          [propertyA, propertyB] = [a['isActive'], b['isActive']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
