import { Component, Input, OnInit } from '@angular/core';

export class EmailStatusElement {
  name: string;
  description: string;
  symbol: string;
}

const ELEMENT_DATA_1: EmailStatusElement[] = [
  {
    name: 'Bounced',
    description:
      "The email message could not be delivered because the patient's address is invalid. This is known as a 'hard bounce' and might occur because the domain name doesn't exist or because the patient's email is unknown to the mail server. Once an email bounces we stop attempting to deliver the message and you will need to reconfirm email address with the patient and resend.",
    symbol: 'arrow-alt-circle-left',
  },
  {
    name: 'Clicked',
    description: 'The patient has read the email message and clicked on a link, button or image in the email message.',
    symbol: 'mouse-pointer',
  },
  {
    name: 'Delivered',
    description: "The email has been successfully delivered to the patient's inbox.",
    symbol: 'sign-in-alt',
  },
  {
    name: 'Delayed',
    description:
      'The email message has been sent, but the patients receiving server is busy and has delayed the delivery of the message. The status should change after a short period of time.',
    symbol: 'hourglass-half',
  },
  {
    name: 'Error',
    description:
      'The email failed to be delivered due to a technical fault. If this appears please contact our help desk.',
    symbol: 'exclamation-triangle',
  },
  { name: 'Opened', description: 'The patient has opened the email.', symbol: 'envelope-open' },
  {
    name: 'Rejected',
    description:
      'This is a technical error between mail servers and your email message was rejected. If this appears please contact our help desk.',
    symbol: 'times-circle',
  },
  { name: 'Sent', description: 'The email message has been sent successfully to the patient.', symbol: 'paper-plane' },
  {
    name: 'Soft-Bounced',
    description:
      "A soft-bounce is an email message that gets as far as the patient's mail server but is undelivered before it gets to the patient. A soft bounce might occur because the patient's inbox is full. Once an email bounces we stop attempting to deliver the message and you will need to reconfirm email address with the patient and resend.",
    symbol: 'times',
  },
  {
    name: 'Spam',
    description:
      "The patient's email software has marked this message as spam. You might need to make the patient aware that you're sending them an email as it may have ended up in the 'junk' folder.",
    symbol: 'trash',
  },
  {
    name: 'Unsubscribed',
    description:
      "The patient clicked unsubscribe on the email message. This may indicate they no longer wish to receive emails from you. You might need to make the patient aware that you're attempting to send them an email message and the purpose of the message.",
    symbol: 'hand-paper',
  },
];

export class InvitationStatusElement {
  name: string;
  description: string;
}

const ELEMENT_DATA_2: InvitationStatusElement[] = [
  {
    name: 'Applied for Finance ',
    description: 'The patient is applying for finance and has commenced the application.',
  },
  {
    name: 'Cash/Card ',
    description:
      'The patient has elected to proceed with treatment and will settle as normal with the practice at the time of appointment.',
  },
  {
    name: 'Cash/Card (Discount) ',
    description:
      'The patient has elected to proceed with treatment and will settle the FULL AMOUNT of treatment with the practice on the first appointment to claim the cash discount.',
  },
  {
    name: 'Deferred X weeks ',
    description:
      'The patient has elected to defer their decision on treatment for X weeks (1-4). Reminder messaging is suspended during this time.',
  },
  {
    name: 'Deferred X weeks (passed) ',
    description:
      'The requested period for the patient to defer their decision has passed. Reminder messages automatically will commence.',
  },
  {
    name: 'Discuss/Modify ',
    description:
      'The patient has requested changes or wishes to discuss the proposed treatment - You need to contact the patient.',
  },
  {
    name: 'Expired ',
    description:
      'The patient has not interacted with their invitation and has passed the expiry time set (Default 60 days).',
  },
  {
    name: 'Intends to Finance ',
    description: 'The patient has indicated that they will apply for finance but has yet to commence the application.',
  },
  { name: 'Not Proceeding ', description: 'The patient has elected not to proceed with the proposed treatment.' },
  { name: 'Not Viewed ', description: 'The patient is yet to look at the offer for treatment.' },
  {
    name: 'Via Web ',
    description:
      'The patient is responding to an offer via a webpage, landing page or marketing campaign and is applying for finance.',
  },
  { name: 'Viewed ', description: 'The patient has seen the proposal for treatment, however, yet to make a decision.' },
];

export class ApplicationStatusElement {
  name: string;
  description: string;
}

const ELEMENT_DATA_3: ApplicationStatusElement[] = [
  { name: 'Commenced ', description: 'The patient has commenced the application for finance.' },
  {
    name: 'Complete',
    description: 'The patient has completed the application for finance, however yet to accept the contract.',
  },
  {
    name: 'Conditionally Approved',
    description:
      'The patient has been conditionally approved for finance, however, they are yet to sign and accept the contract.',
  },
  {
    name: 'Declined ',
    description:
      'The patient was declined for finance Discontinued - The patient has elected to discontinue the application for finance.',
  },
  { name: 'Discontinued ', description: 'The patient has elected to discontinue with the application for finance.' },
  {
    name: 'Expired ',
    description:
      'The patients application for finance was not completed on time and has now has expired. To proceed the patient must reapply.',
  },
  {
    name: 'Referred ',
    description: 'The patients application have been referred to our credit team for further assessment.',
  },
  {
    name: 'Submitted ',
    description: 'The patient has completed the application and submitted to our credit team to finalise.',
  },
  { name: 'N/A  ', description: 'Patient yet to commence or has not applied for finance.' },
];

export class ContractStatusElement {
  name: string;
  description: string;
}

const ELEMENT_DATA_4: ContractStatusElement[] = [
  {
    name: 'Active ',
    description: 'The patient has completed everything and the loan contract is active. Nothing more to do.',
  },
  { name: 'Arrangement ', description: 'The patients loan contract is in a payment arrangement.' },
  { name: 'Approved ', description: 'Checks have been completed and the loan is approved awaiting activation.' },
  { name: 'Cancelled ', description: 'The patients loan contract has been cancelled.' },
  { name: 'Complete ', description: 'The patient has completed/repaid their loan contract.' },
  {
    name: 'Conditionally Approved ',
    description: 'The patient is conditionally approved to finalise the loan contract.',
  },
  { name: 'Declined ', description: 'The patients loan contract was declined upon a final review.' },
  {
    name: 'Discontinued ',
    description: 'The patient has elected to discontinue with the loan contract submission process.',
  },
  { name: 'Fully Recovered ', description: 'The patients loan contract was fully recovered.' },
  { name: 'In Recovery ', description: '- The patients loan contract is in recovery.' },
  { name: 'Partially Recovered', description: 'The patients loan contract was partially recovered.' },
  { name: 'Re-Quoted ', description: 'The loan contract has been re-quoted.' },
  { name: 'Suspended ', description: 'The patients loan contract has been suspended.' },
  {
    name: 'Submitted  ',
    description: 'The patient has electronically signed all loan agreements and submitted for approval.',
  },
  { name: 'Voided ', description: 'The patients approved loan contract has been voided.' },
  { name: 'Written Off', description: 'The patients loan contract has been written off.' },
];

export class OtherSourceStatusElement {
  name: string;
  description: string;
  symbol: string;
}

const ELEMENT_DATA_5: OtherSourceStatusElement[] = [
  { name: 'Email', description: 'The Patient received an email.', symbol: 'envelope-open-text' },
  { name: 'SMS', description: 'The Patient received an SMS.', symbol: 'mobile-alt' },
  {
    name: 'Treatment Invitation',
    description: 'The Patient received an offer for treatment created in the APP.',
    symbol: 'paper-plane',
  },
  {
    name: 'Treatment Invitation 3rd Party App',
    description:
      'The Patient received an offer for treatment created via a 3rd party APP (e.g. Practice Management Software).',
    symbol: 'project-diagram',
  },

  {
    name: 'Marketing Campaign',
    description:
      'The Patient has applied themselves via the web from a landing page as part of a marketing campaign for a payment plan.',
    symbol: 'bullhorn',
  },
  {
    name: 'Practice Assisted',
    description: 'A team Member at the practice assisted the Patient in applying for a payment plan using the APP.',
    symbol: 'building',
  },
  {
    name: 'Practice Assisted - 3rd Party App',
    description:
      'A team Member at the practice assisted the Patient in applying for a payment plan via a 3rd party APP (e.g. Practice Management Software).',
    symbol: 'network-wired',
  },
  {
    name: 'Direct via Web',
    description: 'The Patient has applied themselves via the web for a payment plan.',
    symbol: 'globe',
  },

  {
    name: 'Treatment Documents',
    description:
      'The Patient received supporting documentation (Videos, brochures, fact sheets etc.) attached to the Treatment Invitation.',
    symbol: 'paperclip',
  },
];

export class OtherConsentStatusElement {
  name: string;
  description: string;
  symbol: string;
}

const ELEMENT_DATA_6: OtherConsentStatusElement[] = [
  {
    name: 'Consented ',
    description: 'Your patient has digitally signed consent for the proposed treatment.',
    symbol: 'check',
  },
];

export class OtherTimeStatusElement {
  name: string;
  description: string;
}

const ELEMENT_DATA_7: OtherTimeStatusElement[] = [
  {
    name: 'XX Days ',
    description: 'This is the time since a proposal for treatment was communicated with your patient. ',
  },
];

@Component({
  selector: 'app-message-response-legend',
  templateUrl: './message-response-legend.component.html',
  styleUrls: ['./message-response-legend.component.css'],
})
export class MessageResponseLegendComponent implements OnInit {
  @Input() showEmail = true;
  @Input() showInvite = true;
  @Input() showApp = true;
  @Input() showCont = true;
  @Input() showOther = true;

  displayedColumns1: string[] = ['name', 'description'];

  dataSourceEmail = ELEMENT_DATA_1;

  displayedColumns2: string[] = ['name', 'description'];
  dataSourceInvitation = ELEMENT_DATA_2;

  dataSourceApplication = ELEMENT_DATA_3;

  dataSourceContract = ELEMENT_DATA_4;

  dataSourceOther1 = ELEMENT_DATA_5;

  dataSourceOther2 = ELEMENT_DATA_6;

  dataSourceOther3 = ELEMENT_DATA_7;

  displayedColumns3: string[] = ['symbol', 'name', 'description'];

  constructor() {}

  ngOnInit() {}
}
