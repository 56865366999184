import { UtilsClass } from '../../../../shared/types/utils/utils.class';

import * as moment from 'moment';

export class InvitationUtilClass {
  utils = new UtilsClass();

  status = {
    all: [
      'viewed',
      'notViewed',
      'intendProceed',
      'changed',
      'notProceeding',
      'proceedingCash',
      'proceedingCashDis',
      'expired',
      'active',
      'submitted',
      'approved',
      // 'completed',

      'commenced',
      'deferred',
      'notFinanced',
      'cold',
      'appliedFinance',
      'referred',
      'declined',
    ],
    allInvitation: [
      'viewed',
      'notViewed',
      'intendProceed',
      'changed',
      'notProceeding',
      'proceedingCash',
      'proceedingCashDis',
      'expired',
      'deferred',
      'notFinanced',
      'appliedFinance',
    ],
    won: ['proceedingCash', 'proceedingCashDis', 'intendProceed', 'appliedFinance'],
    lost: ['notProceeding', 'expired'],
    maybe: [
      'viewed',
      'changed',
      'notViewed',
      'deferred',
      // 'notFinanced'
    ],
    contract: [
      'active',
      'submitted',
      'approved',
      // 'completed',
      'referred',
      'expiredApplication',
      'notProceedingApplicaiton',
      'declined',
    ],
    contractWon: [
      'active',
      'approved',
      'submitted',
      // 'completed',
    ],
    contractLost: ['declined'],
    contractMaybe: ['referred'],
  };

  constructor() {}

  ngOnInit() {}

  getStatusLabel(code) {
    if (!code) {
      return 'undefined';
    }

    const labelObject = {
      viewed: 'Viewed',
      notViewed: 'Yet to view patient card',
      intendProceed: 'Intends to apply for finance',
      changed: 'Requested changes to treatment',
      notProceeding: 'Patient not proceeding',
      proceedingCash: 'Paying cash or card',
      proceedingCashDis: 'Paying cash or card with discount',
      expired: 'No response - Expired',
      referred: 'Await Smile Right to review and decide',
      declined: 'Finance has been declined',
      approved: 'Awaiting patient to finalise contract',
      commenced: 'Commenced',
      completed: 'Completed',
      submitted: 'Application complete and awaiting Smile Right to review',
      deferred: 'Deferred decision',
      notFinanced: 'Not proceeding with Finance',
      appliedFinance: 'Applied for finance',
      active: 'Proceed with treatment',
      cold: 'Cold application',
      Read: 'Read',
      Lost: 'Lost/Expired',
      Waiting: 'Unread',
      Clicked: 'Clicked',
      Responded: 'Responded',
      patientResponse: 'Patient responsed',
      expiredApplication: 'Application for finance has expired',
      notProceedingApplicaiton: 'Elected not to proceed with finance',
    };

    if (labelObject[code]) {
      return labelObject[code];
    } else {
      return code;
    }
  }

  getApplicationTitle(code) {
    if (!code) {
      return 'undefined';
    }

    const labelObject = {
      referredApplication: 'Referred',
      yetCommencedApplication: 'Yet to commenced',
      commencedApplication: 'Commenced',
      otherApplication: 'Other',
    };

    if (labelObject[code]) {
      return labelObject[code];
    } else {
      return code;
    }
  }

  getStatusTitle(code) {
    if (!code) {
      return 'undefined';
    }

    const labelObject = {
      viewed: 'Viewed',
      notViewed: 'Not Viewed',
      intendProceed: 'Intends to apply',
      changed: 'Changed',
      notProceeding: 'Not Proceeding',
      // proceedingCash: 'Paying cash or card',
      proceedingCash: 'Cash or card',
      proceedingCashDis: 'Cash or card with discount',
      expired: 'Expired',
      referred: 'On-Hold',
      declined: 'Declined',
      submitted: 'Submitted',
      approved: 'Approved',
      commenced: 'Commenced',

      // completed: 'completed',
      deferred: 'Deferred',
      notFinanced: 'Not Financed',
      // appliedFinance: 'Applied for finance',
      appliedFinance: 'Applied',
      active: 'Active',
      cold: 'Cold',
      Read: 'Read',
      Lost: 'Lost/Expired',
      Waiting: 'Waiting',
      Clicked: 'Clicked',
      Responded: 'Responded',
      patientResponse: 'Patient responsed',
      expiredApplication: 'Application expired',
      notProceedingApplicaiton: 'Application not proceeding',
      referredApplication: 'Application Referred',
      yetCommencedApplication: 'Application yet to commenced',
      commencedApplication: 'Application commenced',
      otherApplication: 'Other Applications',
    };

    if (labelObject[code]) {
      return labelObject[code];
    } else {
      return code;
    }
  }

  getInvitationStatus(invitation) {
    let status;

    if (invitation) {
      if (invitation && invitation['PatientChoice.Code'] && invitation['PatientChoice.Code'] === 'P_APP') {
        if (invitation['Date.Activated'] !== '0000-00-00') {
          status = 'activated';
        } else if (invitation['Date.Completed'] !== '0000-00-00') {
          status = 'completed';
        } else if (invitation['Date.FinalApproval'] !== '0000-00-00') {
          status = 'finalApproval';
        } else if (invitation['Date.Approved'] !== '0000-00-00') {
          status = 'approved';
        } else if (invitation['Date.Declined'] !== '0000-00-00') {
          status = 'declined';
        } else if (invitation['Date.Referred'] !== '0000-00-00') {
          status = 'referred';
        } else if (invitation['Date.Commenced'] !== '0000-00-00') {
          status = 'commenced';
        } else if (invitation['Date.PlanningToCommence'] !== '0000-00-00') {
          status = 'palningToCommence';
        } else {
          status = 'unknown';
        }
      } else if (invitation) {
        if (invitation.Expired == '1') {
          status = 'expired';
        } else if (invitation.Voided_Really === '1') {
          status = 'removed';
        } else if (invitation['PatientChoice.Code'] && invitation['PatientChoice.Code'] === 'DSMO') {
          status = 'applicationChanged';
        } else if (invitation['PatientChoice.Code'] && invitation['PatientChoice.Code'] === 'NOT') {
          status = 'notProceed';
        } else if (invitation['PatientChoice.Code'] && invitation['PatientChoice.Code'] === 'T_OTH') {
          status = 'proceedCash';
        } else if (invitation['PatientChoice.Code'] && invitation['PatientChoice.Code'] === 'T_OTH_D') {
          status = 'proceedCash';
        } else if (invitation['PatientChoice.Code'] && invitation['PatientChoice.Code'] === 'T_OTH_G') {
          status = 'proceedCash';
        } else if (invitation['PatientChoice.Code'] && invitation['PatientChoice.Code'] === 'DISC') {
          status = 'proceedCash';
        } else if (
          invitation['PatientChoice.Code'] &&
          (invitation['PatientChoice.Code'] === 'P_L1x' ||
            invitation['PatientChoice.Code'] === 'P_L2x' ||
            invitation['PatientChoice.Code'] === 'P_L3x' ||
            invitation['PatientChoice.Code'] === 'P_L4x')
        ) {
          let weeks = 1;

          if (invitation['PatientChoice.Code'] === 'P_L2x') {
            weeks = 2;
          } else if (invitation['PatientChoice.Code'] === 'P_L3x') {
            weeks = 3;
          } else if (invitation['PatientChoice.Code'] === 'P_L4x') {
            weeks = 4;
          }

          const deferDate = this.utils.getPastTime(new Date(), weeks * 7, 'day');

          const isBefore = moment().isBefore(deferDate);

          if (isBefore == true) {
            status = 'defered';
          }
        } else if (invitation['PatientChoice.Code'] == '' || !invitation['PatientChoice.Code']) {
          status = 'current';
        } else {
          status = 'unknown';
        }
      } else {
        status = 'unknown';
      }
    } else {
      status = 'unknown';
    }

    return status;
  }
}
