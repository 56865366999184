<div class="full-width" @ngIfAnimation>
  <div *ngIf="patientCard != true && status == 'current'">
    <div class="info_object">
      <p class="rm-mt larger">This {{ "KEYWORD.treatment" | translate }} is awaiting your decision.</p>
      <p>The {{ "KEYWORD.treatment" | translate }} invitation will expire on {{ invitation['Date.Expiry'] | customDate
        }}</p>
      <p *ngIf="invitation['PatientChoice.Label_LongFirstPerson']">
        {{ invitation['PatientChoice.Label_LongFirstPerson'] }}
      </p>
      <!-- show cash discount info if not before expiry date -->
      <ng-container *ngIf="invitation['CashDiscount.Expiry.Date'] <= (todayDate | customDateTime)">
        <p *ngIf="invitation['CashDiscount.Offered.YesNo'] == 1">
          If you proceed with this {{"KEYWORD.treatment" | translate}}
          <strong>before {{ invitation['CashDiscount.Expiry.Date'] | customDate }}</strong>, you can
          <strong class="accent-color">save {{ invitation['CashDiscount.Offered.Amount'] | customCurrency }}</strong>
        </p>
      </ng-container>
    </div>
  </div>
  <div *ngIf="closeAction != true && status == 'expired'">
    <mat-card class="treatment secondary-gradient bad-gradient rel animate first botton mb"
              *ngIf="patientCard == true && canView == true && closeAction != true && status == 'expired'"
              @ngIfAnimation
              [matRippleColor]="'rgba(255,255,255,0.14)'" matRipple>
      <button class="btn-close btn-clear mat-button ml pull-right" *ngIf="closeEvent != false" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row clearfix flex valueLabels" (click)="requestNewInvitation()">
        <div class="col-left full-width clearfix">
          <h2 class="summary-header sr-title rm-m mt">{{ "PHRASE.treatment plan" | translate | titlecase}} has Expired.
          </h2>
          <p class="rm-mt">
            <span>Your {{ "PHRASE.treatment plan" | translate }} expired on {{ invitation['Date.Expiry'] | customDate
              }}. Would you like to request a
              new {{ "KEYWORD.treatment" | translate }}?
            </span>
          </p>
        </div>
        <div class="clearfix">
          <button class="full-width full-height btn-outline pop" mat-button>
            <span class="mobHide"> Request New invitation <mat-icon>chevron_right</mat-icon> </span>
          </button>
        </div>
      </div>
    </mat-card>
    <div class="info_object" *ngIf="patientCard != true && closeAction != true && status == 'expired'">
      <p class="rm-mt larger">{{ "PHRASE.treatment plan" | translate | titlecase}} has Expired.</p>

      <p class="full-width">
        Your {{ "PHRASE.treatment plan" | translate }} expired on {{ invitation['Date.Expiry'] | customDate }}. Would
        you like to request a new
        treatment?
      </p>

      <div class="clearfix">
        <button class="btn-large gradient green" (click)="requestNewInvitation()" mat-raised-button color="primary">
          Request New invitation <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="closeAction != true && status == 'completed'">
    <mat-card class="treatment secondary-gradient good-gradient rel animate first botton mb"
              *ngIf="patientCard == true && canView == true && closeAction != true && status == 'completed'"
              @ngIfAnimation
              [matRippleColor]="'rgba(255,255,255,0.14)'" matRipple>
      <button class="btn-close btn-clear mat-button ml pull-right" *ngIf="closeEvent != false" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row clearfix flex valueLabels">
        <div class="col-left full-width clearfix">
          <h2 class="summary-header sr-title rm-m mt">
            {{ "KEYWORD.treatment" | translate | titlecase }} financed with {{ "BRANDING.Brand Name" | translate |
            titlecase }}.
          </h2>
          <p class="rm-mt">
            <span>
              You financed this {{ "KEYWORD.treatment" | translate }} with {{ "BRANDING.Brand Name" | translate |
              titlecase }}, and
              your loan/payment plan is
              now Complete.
            </span>
          </p>
        </div>
      </div>
    </mat-card>
    <div class="info_object" *ngIf="patientCard != true && closeAction != true && status == 'completed'">
      <p class="rm-mt larger"> {{"KEYWORD.Treatment" | translate}} financed with {{ "BRANDING.Brand Name" | translate |
        titlecase }}
        .</p>
      <p>
        You financed this {{ "KEYWORD.treatment" | translate }} with {{ "BRANDING.Brand Name" | translate | titlecase
        }}, and your
        loan/payment plan is now
        Complete.
      </p>
    </div>
  </div>
  <div *ngIf="closeAction != true && status == 'approved'">
    <mat-card class="treatment secondary-gradient good-gradient rel animate first botton mb"
              *ngIf="patientCard == true && canView == true && closeAction != true && status == 'approved'"
              @ngIfAnimation
              [matRippleColor]="'rgba(255,255,255,0.14)'" matRipple>
      <button class="btn-close btn-clear mat-button ml pull-right" *ngIf="closeEvent != false" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row clearfix flex valueLabels" (click)="goToWakanda()">
        <div class="col-left full-width clearfix">
          <h2 class="summary-header sr-title rm-m mt">Congratulations you're conditionally approved.</h2>
          <p class="rm-mt">
            <span>
              Your application with {{ "BRANDING.Brand Name" | translate | titlecase }} is now Conditional Approved.
              Proceed to
              finalise your application.
            </span>
          </p>
        </div>
        <div class="clearfix">
          <button class="full-width full-height btn-outline pop" mat-button>
            <span class="mobHide"> Finalise Application. <mat-icon>chevron_right</mat-icon> </span>
          </button>
        </div>
      </div>
    </mat-card>
    <div class="info_object" *ngIf="patientCard != true && closeAction != true && status == 'approved'">
      <p class="rm-mt larger">Congratulations you're conditionally approved.</p>

      <p class="full-width">
        Your application with {{ "BRANDING.Brand Name" | translate | titlecase }} is now Conditional Approved. Proceed
        to finalise
        your application.
      </p>
      <div class="clearfix">
        <button class="btn-large gradient green" (click)="goToWakanda()" mat-raised-button color="primary">
          Finalise Application <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="closeAction != true && status == 'referred'">
    <mat-card class="treatment secondary-gradient warning-gradient rel animate first botton mb"
              *ngIf="patientCard == true && canView == true && closeAction != true && status == 'referred'"
              @ngIfAnimation
              [matRippleColor]="'rgba(255,255,255,0.14)'" matRipple>
      <button class="btn-close btn-clear mat-button ml pull-right" *ngIf="closeEvent != false" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row clearfix flex valueLabels">
        <div class="col-left full-width clearfix">
          <h2 class="summary-header sr-title rm-m mt">Assessment of your application is currently underway.</h2>
          <p class="rm-mt">
            <span> Not much longer now and we will let you know via email when you can continue your application </span>
          </p>
        </div>
      </div>
    </mat-card>
    <div class="info_object" *ngIf="patientCard != true && closeAction != true && status == 'referred'">
      <p class="rm-mt larger">Assessment of your application is currently underway.</p>
      <p>Not much longer now and we will let you know via email when you can continue your application</p>
    </div>
  </div>
  <div *ngIf="closeAction != true && status == 'declined'">
    <mat-card class="treatment secondary-gradient bad-gradient rel animate first botton mb"
              *ngIf="patientCard == true && canView == true && closeAction != true && status == 'declined'"
              @ngIfAnimation
              [matRippleColor]="'rgba(255,255,255,0.14)'" matRipple>
      <button class="btn-close btn-clear mat-button ml pull-right" *ngIf="closeEvent != false" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row clearfix flex valueLabels" (click)="requestNewInvitation()">
        <div class="col-left full-width clearfix">
          <h2 class="summary-header sr-title rm-m mt">Your application for finance was Unsuccessful.</h2>
          <p class="rm-mt">
            <span>Unfortunately, this time, your application was unsuccessful. Would you like to proceed still with
              {{ "KEYWORD.treatment" | translate }}(cash/card)?
            </span>
          </p>
        </div>
        <div class="clearfix">
          <button class="full-width full-height btn-outline pop" mat-button>
            <span class="mobHide"> Request a new invitation <mat-icon>chevron_right</mat-icon> </span>
          </button>
        </div>
      </div>
    </mat-card>
    <div class="info_object" *ngIf="patientCard != true && closeAction != true && status == 'declined'">
      <p class="rm-mt larger">Your application for finance was Unsuccessful.</p>

      <p class="full-width">
        Unfortunately, this time, your application was unsuccessful. Would you like to proceed still with treatment
        (cash/card)?
      </p>

      <div class="clearfix">
        <button class="btn-large gradient green" (click)="requestNewInvitation()" mat-raised-button color="primary">
          Request a new invitation <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="closeAction != true && status == 'activated'">
    <mat-card class="treatment secondary-gradient good-gradient rel animate first botton mb"
              *ngIf="patientCard == true && canView == true && closeAction != true && status == 'activated'"
              @ngIfAnimation
              [matRippleColor]="'rgba(255,255,255,0.14)'" matRipple>
      <button class="btn-close btn-clear mat-button ml pull-right" *ngIf="closeEvent != false" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row clearfix flex valueLabels">
        <div class="col-left full-width clearfix">
          <h2 class="summary-header sr-title rm-m mt">Your loan/payment plan is now Active.</h2>
          <p class="rm-mt">
            <span> You can now proceed with {{ "KEYWORD.treatment" | translate }} with your {{ "KEYWORD.dentist" |
              translate }}. </span>
          </p>
        </div>
      </div>
    </mat-card>
    <div class="info_object" *ngIf="patientCard != true && closeAction != true && status == 'activated'">
      <p class="rm-mt larger">Your loan/payment plan is now Active.</p>
      <p>You can now proceed with {{ "KEYWORD.treatment" | translate }} with your {{ "KEYWORD.dentist" | translate }}.
      </p>
    </div>
  </div>
  <div *ngIf="closeAction != true && status == 'palningToCommence'">
    <mat-card class="treatment secondary-gradient good-gradient rel animate first botton mb"
      *ngIf="patientCard == true && canView == true && closeAction != true && status == 'palningToCommence'"
              @ngIfAnimation [matRippleColor]="'rgba(255,255,255,0.14)'" matRipple>
      <button class="btn-close btn-clear mat-button ml pull-right" *ngIf="closeEvent != false" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row clearfix flex valueLabels" (click)="goToWakanda()">
        <div class="col-left full-width clearfix">
          <h2 class="summary-header sr-title mt">Yet to start your application.</h2>
          <p class="rm-mt">
            <span>
              You previously indicated you wish to finance but are yet to start the application. It is not too late to
              get started.
            </span>
          </p>
        </div>
        <div class="clearfix">
          <button class="full-width full-height btn-outline pop" mat-button>
            <span class="mobHide"> Begin Application <mat-icon>chevron_right</mat-icon> </span>
          </button>
        </div>
      </div>
    </mat-card>
    <div class="info_object" *ngIf="patientCard != true && closeAction != true && status == 'palningToCommence'">
      <p class="rm-mt larger">Yet to start your application...</p>

      <p class="full-width">
        You previously indicated you wish to finance but are yet to start the application. It is not too late to get
        started.
      </p>

      <div class="clearfix">
        <button class="btn-large gradient green" (click)="goToWakanda()" mat-raised-button color="primary">
          Begin Application <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="closeAction != true && status == 'finalApproval'">
    <mat-card class="treatment secondary-gradient good-gradient rel animate first botton mb"
              *ngIf="patientCard == true && canView == true && closeAction != true && status == 'finalApproval'"
              @ngIfAnimation
              [matRippleColor]="'rgba(255,255,255,0.14)'" matRipple>
      <button class="btn-close btn-clear mat-button ml pull-right" *ngIf="closeEvent != false" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row clearfix flex valueLabels">
        <div class="col-left full-width clearfix">
          <h2 class="summary-header sr-title rm-m mt">Your application is submitted.</h2>
          <p class="rm-mt">
            <span>
              Not much longer, {{ "BRANDING.Brand Name" | translate | titlecase }} will notify you shortly via email to
              begin
              {{"KEYWORD.treatment" | translate}}.
            </span>
          </p>
        </div>
      </div>
    </mat-card>
    <div class="info_object" *ngIf="patientCard != true && closeAction != true && status == 'finalApproval'">
      <p class="rm-mt larger">Your application is submitted.</p>
      <p>
        Not much longer, {{ "BRANDING.Brand Name" | translate | titlecase }} will notify you shortly via email to
        begin {{"KEYWORD.treatment" | translate}}.
      </p>
    </div>
  </div>
  <div *ngIf="closeAction != true && status == 'removed'">
    <mat-card class="treatment secondary-gradient bad-gradient rel animate first botton mb"
              *ngIf="patientCard == true && canView == true && closeAction != true && status == 'removed'"
              @ngIfAnimation
              [matRippleColor]="'rgba(255,255,255,0.14)'" matRipple>
      <button class="btn-close btn-clear mat-button ml pull-right" *ngIf="closeEvent != false" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row clearfix flex valueLabels" (click)="requestNewInvitation()">
        <div class="col-left full-width clearfix">
          <h2 class="summary-header sr-title mt">This link is no longer active.</h2>
          <p class="rm-mt">
            <span>It seems the link is no longer active. You can always request a new link from us. </span>
          </p>
        </div>
        <div class="clearfix">
          <button class="full-width full-height btn-outline pop" mat-button>
            <span class="mobHide"> Request New Link <mat-icon>chevron_right</mat-icon> </span>
          </button>
        </div>
      </div>
    </mat-card>
    <div class="info_object" *ngIf="patientCard != true && closeAction != true && status == 'removed'">
      <p class="rm-mt larger">This link is no longer active.</p>

      <p class="full-width">It seems the link is no longer active. You can always request a new link from us.</p>

      <div class="clearfix">
        <button class="btn-large gradient green" (click)="requestNewInvitation()" mat-raised-button color="primary">
          Request New Link <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="closeAction != true && status == 'applicationChanged'">
    <mat-card class="treatment secondary-gradient info-gradient rel animate first botton mb"
      *ngIf="patientCard == true && canView == true && closeAction != true && status == 'applicationChanged'"
              @ngIfAnimation [matRippleColor]="'rgba(255,255,255,0.14)'" matRipple>
      <button class="btn-close btn-clear mat-button ml pull-right" *ngIf="closeEvent != false" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row clearfix flex valueLabels" (click)="contactUs()">
        <div class="col-left full-width clearfix">
          <h2 class="summary-header sr-title mt">You have requested your {{ "PHRASE.treatment plan" | translate }} be
            modified.</h2>
          <p class="rm-mt">
            <span>You have requested we change your {{ "PHRASE.treatment plan" | translate }} . We will send you a new
              link soon. </span>
          </p>
        </div>
        <div class="clearfix">
          <button class="full-width full-height btn-outline pop" mat-button>
            <span class="mobHide"> Contact us <mat-icon>chevron_right</mat-icon> </span>
          </button>
        </div>
      </div>
    </mat-card>

    <div class="info_object" *ngIf="patientCard != true && closeAction != true && status == 'applicationChanged'">
      <p class="rm-mt larger">You have requested your {{ "PHRASE.treatment plan" | translate }} be modified .</p>

      <p class="full-width">You have requested we change your {{ "PHRASE.treatment plan" | translate }} . We will send
        you a new link soon.</p>

      <div class="clearfix">
        <button class="btn-large" (click)="contactUs()" mat-stroked-button color="primary">
          Contact us <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="closeAction != true && status == 'notProceed'">
    <mat-card class="treatment secondary-gradient info-gradient rel animate first botton mb"
              *ngIf="patientCard == true && canView == true && closeAction != true && status == 'notProceed'"
              @ngIfAnimation
              [matRippleColor]="'rgba(255,255,255,0.14)'" matRipple>
      <button class="btn-close btn-clear mat-button ml pull-right" *ngIf="closeEvent != false" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row clearfix flex valueLabels" (click)="contactUs()">
        <div class="col-left full-width clearfix">
          <h2 class="summary-header sr-title mt">Not proceeding with {{"KEYWORD.treatment" | translate}}.</h2>
          <p class="rm-mt">
            <span>
              You have told us you will not be proceeding with the {{ "KEYWORD.treatment" | translate }} proposed. Have
              you changed your mind?
            </span>
          </p>
        </div>
        <div class="col-right full-width clearfix valueLabels text-right">
          <button class="full-width full-height btn-outline pop" mat-button>
            <span class="mobHide"> Contact us <mat-icon>chevron_right</mat-icon> </span>
          </button>
        </div>
      </div>
    </mat-card>
    <div class="info_object" *ngIf="patientCard != true && closeAction != true && status == 'notProceed'">
      <p class="rm-mt larger">Not proceeding with {{"KEYWORD.treatment" | translate}}.</p>

      <p class="full-width">
        You have told us you will not be proceeding with the {{ "KEYWORD.treatment" | translate }} proposed. Have you
        changed your mind?
      </p>

      <div class="clearfix">
        <button class="btn-large" (click)="contactUs()" mat-stroked-button color="primary">
          Contact us <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="closeAction != true && status == 'proceedCash'">
    <mat-card class="treatment secondary-gradient good-gradient rel animate first botton mb"
              *ngIf="patientCard == true && canView == true && closeAction != true && status == 'proceedCash'"
              @ngIfAnimation
              [matRippleColor]="'rgba(255,255,255,0.14)'" matRipple>
      <button class="btn-close btn-clear mat-button ml pull-right" *ngIf="closeEvent != false" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row clearfix flex valueLabels" (click)="contactUs()">
        <div class="col-left full-width clearfix">
          <h2 class="summary-header sr-title mt">Proceeding with {{"KEYWORD.treatment" | translate}}.</h2>
          <p class="rm-mt">
            <span class="PatientChoice.Code =!'DISC'">
              You have told us you will be proceeding with the {{ "KEYWORD.treatment" | translate }} proposed and pay us
              on the day. Something
              changed or have questions?
            </span>

            <span class="PatientChoice.Code == 'DISC'">
              You have told us you will be proceeding with the {{ "KEYWORD.treatment" | translate }} proposed, paying on
              the day in full to receive
              a discount of {{ discountValue | currency }} . Something changed or have questions?
            </span>
          </p>
        </div>
        <div class="clearfix">
          <button class="full-width full-height btn-outline pop" mat-button>
            <span class="mobHide"> Contact us <mat-icon>chevron_right</mat-icon> </span>
          </button>
        </div>
      </div>
    </mat-card>
    <div class="info_object" *ngIf="patientCard != true && closeAction != true && status == 'proceedCash'">
      <p class="rm-mt larger">Proceeding with {{"KEYWORD.treatment" | translate}}.</p>

      <p class="full-width">
        <span class="PatientChoice.Code =!'DISC'">
          You have told us you will be proceeding with the {{ "KEYWORD.treatment" | translate }} proposed and pay us on
          the day. Something changed
          or have questions?</span>

        <span class="PatientChoice.Code == 'DISC'">
          You have told us you will be proceeding with the {{ "KEYWORD.treatment" | translate }} proposed, paying on the
          day in full to receive a
          discount of {{ discountValue | currency }} . Something changed or have questions?
        </span>
      </p>

      <div class="clearfix">
        <button class="btn-large" (click)="contactUs()" mat-stroked-button color="primary">
          Contact us <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="closeAction != true && status == 'defered'">
    <mat-card class="treatment secondary-gradient info-gradient rel animate first botton mb"
              *ngIf="patientCard == true && canView == true && closeAction != true && status == 'defered'"
              @ngIfAnimation
              [matRippleColor]="'rgba(255,255,255,0.14)'" matRipple>
      <button class="btn-close btn-clear mat-button ml pull-right" *ngIf="closeEvent != false" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row clearfix flex valueLabels">
        <div class="col-left full-width clearfix">
          <h2 class="summary-header sr-title rm-m mt">Deferred your decision till {{ deferDate | customDate }}.</h2>
          <p class="rm-mt">
            <span> You have advised you need more time to decide on {{"KEYWORD.treatment" | translate}}. Changed your
              mind? </span>
          </p>
        </div>
      </div>
    </mat-card>
    <div class="info_object" *ngIf="patientCard != true && closeAction != true && status == 'defered'">
      <p class="rm-mt larger">Deferred your decision till {{ deferDate | customDate }}.</p>
      <p>You have advised you need more time to decide on {{"KEYWORD.treatment" | translate}}. Changed your mind?</p>
    </div>
  </div>
</div>
