import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class AppointmentService {
  constructor(private http: HttpClient) {}

  getAppointmentList(payload, sessionType) {
    let url = '';
    switch (sessionType) {
      case 'promoter':
      case 'admin':
        url = 'appointment/global';
        break;
      case 'customer':
        url = 'appointment/customer';
        break;
      case 'consumer':
        url = 'appointment/customer';
        break;

      default:
        url = 'appointment';
        break;
    }

    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    return this.http.get(`${environment.nodeUrl}/${url}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  getAppointmentDetails(id, sessionType) {
    let url = '';
    switch (sessionType) {
      case 'promoter':
      case 'admin':
        url = 'appointment/global';
        break;
      case 'customer':
        url = 'appointment/customer';
        break;
      case 'consumer':
        url = 'appointment/customer';
        break;
      case 'guest':
        url = 'appointment/public';
        break;
      default:
        url = 'appointment';
        break;
    }

    return this.http.get(`${environment.nodeUrl}/${url}/${id}`, {}).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  /**
   *
   * @param id ID of the appointment but calls on group API when null
   * @param payload
   * @param sessionType
   */
  editAppointment(id, payload, sessionType = 'merchant') {
    let url = 'appointment';

    if (!id) {
      url += '/group';
    }

    switch (sessionType) {
      case 'promoter':
      case 'admin':
        url += '/global';
        break;
      case 'customer':
        url += '/customer';
        break;
      case 'consumer':
        url += '/customer';
        break;
      case 'guest':
        url += '/public';
        break;
      default:
        break;
    }

    if (id) {
      url += `/${id}`;
    }

    return this.http.put(`${environment.nodeUrl}/${url}`, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  /**
   *
   * @param id a single appointment ID or can be an array of appointment IDs
   * @param sessionType
   */
  removeAppointment(id, sessionType) {
    let url = 'appointment';

    if (Array.isArray(id)) {
      url += '/group';
    }

    switch (sessionType) {
      case 'promoter':
      case 'admin':
        url += '/global';
        break;
      case 'customer':
      case 'consumer':
        url += '/customer';
        break;
      default:
        break;
    }

    if (Array.isArray(id)) {
      url += `/${id.join(',')}`;
    } else {
      url += `/${id}`;
    }

    return this.http.delete(`${environment.nodeUrl}/${url}`).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  createForInvitation(invitationID, payload) {
    return this.http.post(`${environment.nodeUrl}/appointment/invitation/${invitationID}`, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  createForCustomer(payload) {
    return this.http.post(`${environment.nodeUrl}/appointment/customer`, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  createForMerchantWithPatient(payload, sessionType) {
    let url;
    if (sessionType === 'admin' || sessionType === 'promoter') {
      url = `appointment/book/global`;
    } else if (sessionType === 'merchant' || sessionType === 'merchant-admin') {
      url = `appointment/book`;
    } else {
      throw new Error('Only admin or merchant can book an appointment');
    }
    return this.http.post(`${environment.nodeUrl}/${url} `, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
}
