import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable()
export class UserDataService {
  private userDataUrl: string = environment.nodeUrl + '/userData';

  @Output()
  userDataEvent: EventEmitter<any> = new EventEmitter();
  params: HttpParams;

  constructor(private http: HttpClient) {}

  getUserData(invitationID, sessionType) {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(this.userDataUrl + '/global/' + invitationID)
        .pipe(
          catchError((error: any): Observable<any> => {
            return throwError(error);
          })
        )
        .subscribe((data: any) => {
          this.userDataEvent.emit(data.data);
        });
    } else {
      return this.http
        .get(this.userDataUrl + '/' + invitationID)
        .pipe(
          catchError((error: any): Observable<any> => {
            return throwError(error);
          })
        )
        .subscribe((data: any) => {
          this.userDataEvent.emit(data.data);
        });
    }
  }

  getUserDataByField(invitationID, fields, sessionType) {
    this.setParams({
      fields: fields || null,
    });
    let options = {};

    options = {
      params: this.params,
    };

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http.get(this.userDataUrl + '/global/' + invitationID, options).pipe(
        map((res: HttpResponse<any>) => {
          if (res['data'] && res['data'].content) {
            return res['data'].content;
          } else {
            return null;
          }
        }),
        catchError((error: any): Observable<any> => {
          return throwError(error);
        })
      );
    } else {
      return this.http.get(this.userDataUrl + '/' + invitationID, options).pipe(
        map((res: HttpResponse<any>) => {
          if (res['data'] && res['data'].content) {
            return res['data'].content;
          } else {
            return null;
          }
        }),
        catchError((error: any): Observable<any> => {
          return throwError(error);
        })
      );
    }
  }

  setParams(param) {
    this.params = new HttpParams();
    for (const key in param) {
      if (param.hasOwnProperty(key)) {
        const val = param[key];
        this.params = this.params.append(key, val);
      }
    }
  }
  setUserData(invitationID, body, sessionType): Observable<any> {
    // let params = { userData: true };
    let params: HttpParams = new HttpParams();
    params = params.set('userData', 'true');

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http.post(this.userDataUrl + '/global/' + invitationID, body, { params }).pipe(
        map((res: HttpResponse<any>) => {
          if (res && res['data'] && res['data']['content']) {
            this.userDataEvent.emit(res['data']);
          }
          return res;
        }),
        catchError((error: any) => throwError(error))
      );
    } else {
      return this.http.post(this.userDataUrl + '/' + invitationID, body, { params }).pipe(
        map((res: HttpResponse<any>) => {
          if (res && res['data'] && res['data']['content']) {
            this.userDataEvent.emit(res['data']);
          }
          return res;
        }),
        catchError((error: any) => throwError(error))
      );
    }
  }
  setUserDataHidden(invitationID, body, sessionType): Observable<any> {
    // let params = { userData: true };
    let params: HttpParams = new HttpParams();
    params = params.set('userData', 'true');

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http.post(this.userDataUrl + '/hidden/global/' + invitationID, body, { params }).pipe(
        map((res: HttpResponse<any>) => {
          if (res && res['data']) {
            this.userDataEvent.emit(res['data']);
          }
          return res;
        })
      );
    } else {
      return this.http.post(this.userDataUrl + '/hidden/' + invitationID, body, { params }).pipe(
        map((res: HttpResponse<any>) => {
          if (res && res['data']) {
            this.userDataEvent.emit(res['data']);
          }
          return res;
        })
      );
    }
  }

  setUserDataArray(invitationID, body, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('userData', 'true');
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http.post(this.userDataUrl + '/array/global/' + invitationID, body, { params }).pipe(
        map((res: HttpResponse<any>) => {
          if (res && res['data'] && res['data']['content']) {
            this.userDataEvent.emit(res['data']);
          }

          return res;
        }),
        catchError((error: any) => throwError(error))
      );
    } else {
      return this.http.post(this.userDataUrl + '/array/' + invitationID, body, { params }).pipe(
        map((res: HttpResponse<any>) => {
          if (res && res['data'] && res['data']['content']) {
            this.userDataEvent.emit(res['data']);
          }

          return res;
        }),
        catchError((error: any) => throwError(error))
      );
    }
  }

  setUserDataArrayForce(invitationID, body, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('userData', 'true');
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http.post(this.userDataUrl + '/array/force/global/' + invitationID, body, { params }).pipe(
        map((res: HttpResponse<any>) => {
          if (res && res['data'] && res['data']['content']) {
            this.userDataEvent.emit(res['data']);
          }
          return res;
        }),
        catchError((error: any) => throwError(error))
      );
    } else {
      return this.http.post(this.userDataUrl + '/array/force/' + invitationID, body, { params }).pipe(
        map((res: HttpResponse<any>) => {
          if (res && res['data'] && res['data']['content']) {
            this.userDataEvent.emit(res['data']);
          }
          return res;
        }),
        catchError((error: any) => throwError(error))
      );
    }
  }

  getUserDataByFields(id, fields = null, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    params = params.set('fields', fields);

    options = {
      params,
    };
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + `/invitation/userData/global/${id}`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + `/invitation/userData/${id}`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
}
