import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SocialMediaService } from '../shared/social-media.service';

@Component({
  selector: 'app-social-media-thumbnail-view',
  templateUrl: './social-media-thumbnail-view.component.html',
  styleUrls: ['./social-media-thumbnail-view.component.css'],
})
export class SocialMediaThumbnailViewComponent implements OnInit {
  @Input() socialMediaID;

  @Input() LastModified = null;

  @Input() hideZoomButton = false;
  @Output() close = new EventEmitter();

  profileLink;

  constructor(
    private templateService: SocialMediaService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.socialMediaID = data;
    }
  }

  ngOnInit() {
    this.getPicture();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.socialMediaID && changes.socialMediaID.previousValue != changes.socialMediaID.currentValue) {
      if (this.socialMediaID) {
        this.profileLink = null;

        this.profileLink = this.templateService.getThumbnailImageStreamLink(this.socialMediaID, this.LastModified);
      } else {
        this.profileLink = null;
      }
    }
  }

  getPicture() {
    this.profileLink = null;

    if (this.socialMediaID) {
      this.profileLink = this.templateService.getThumbnailImageStreamLink(this.socialMediaID, this.LastModified);
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
}
