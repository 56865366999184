<div class="full-width">
  <form #noteContentForm="ngForm">
    <div class="full-width">
      <div class="full-width clearfix">
        <mat-form-field class="full-width animate" appearance="outline">
          <textarea
            class="smb mat-block note-text-control"
            #noteTextArea
            [(ngModel)]="text"
            [ngClass]="{
              'note-text-control-smaller': size === 'small',
              'note-text-control-larger': size === 'large'
            }"
            [disabled]="disabled"
            (ngModelChange)="updateText()"
            name="note-text-area"
            required
            matInput
            placeholder="{{ label }}"
          ></textarea>
        </mat-form-field>

        <button class="clear-button pull-left" (click)="clearNote()" mat-icon-button matTooltip="Clear Note">
          <mat-icon class="fas fa-trash"></mat-icon>
        </button>
      </div>

      <div class="full-width mb" *ngIf="viewModel$ | async as viewModel" style="margin-top: -10px">
        <button
          class="emoji-button smr icon-cust pull-left"
          [matMenuTriggerFor]="emojiMenu"
          mat-icon-button
          matTooltip="Emoji"
        >
          <mat-icon class="far fa-laugh"></mat-icon>
        </button>

        <button
          class="template-button smr pull-left icon-cust btn"
          *ngIf="isModuleCustomMessagesActive == true && !hideTemplateSelectButton"
          (click)="openNoteTemplateList()"
          mat-icon-button
          matTooltip="Note Templates"
        >
          <mat-icon class="fas fa-envelope-open-text"></mat-icon>
        </button>

        <button
          class="personalization-user-button smr pull-left icon-cust btn"
          *ngIf="patientPersonalizationMenu && patientPersonalizationMenu.length > 0 && patient.ID"
          [matTooltip]="('KEYWORD.patient' | translate | titlecase) + ' Customization'"
          [matMenuTriggerFor]="personalizationPatientMenu"
          mat-icon-button
        >
          <mat-icon class="fas fa-user"></mat-icon>
        </button>

        <button
          class="personalization-dentist-button smr pull-left icon-cust btn"
          *ngIf="dentistPersonalizationMenu && dentistPersonalizationMenu.length > 0"
          [matMenuTriggerFor]="personalizationDentistMenu"
          mat-icon-button
          matTooltip="Sender Customization"
        >
          <mat-icon class="fas fa-address-card"></mat-icon>
        </button>

        <button
          class="personalization-practice-button smr pull-left icon-cust btn"
          *ngIf="practicePersonalizationMenu && practicePersonalizationMenu.length > 0"
          [matTooltip]="('KEYWORD.practice' | translate | titlecase) + ' Customization'"
          [matMenuTriggerFor]="personalizationPracticeMenu"
          mat-icon-button
        >
          <mat-icon class="fas fa-building"></mat-icon>
        </button>

        <button
          class="insert-link-button csmr pull-left icon-cust btn"
          (click)="insertLink()"
          mat-icon-button
          matTooltip="Insert a Link"
        >
          <mat-icon class="fas fa-link"></mat-icon>
        </button>

        <button
          class="landing-page-link-button smr pull-left icon-cust btn"
          (click)="documentLinkLookup()"
          matTooltip="Landing Page Lookup"
          mat-icon-button
        >
          <mat-icon class="fas fa-folder-open"></mat-icon>
        </button>

        <button
          class="expand-button icon-cust btn pull-right"
          *ngIf="!hideExpandButton"
          (click)="expandNoteText()"
          mat-icon-button
        >
          <mat-icon class="fas fa-expand"></mat-icon>
        </button>

        <button
          class="smr pull-left icon-cust btn"
          *ngIf="!hideTemplateSaveButton"
          [disabled]="!text"
          (click)="openNoteTemplateFormModal()"
          mat-icon-button
          matTooltip="Save this note As Template"
        >
          <mat-icon class="fas fa-save" [ngClass]="{ 'primary-color': text }"></mat-icon>
        </button>

        <ng-container>
          <mat-menu class="emoji-container" #emojiMenu="matMenu" [class]="'emojiContainer'" yPosition="above">
            <emoji-mart [showPreview]="true" [isNative]="false" (emojiClick)="insertEmoji($event)"></emoji-mart>
          </mat-menu>

          <mat-menu
            class="emoji-container"
            #personalizationPatientMenu="matMenu"
            [class]="'customizationPanelContain'"
            yPosition="above"
          >
            <div class="main-customization">
              <h3 class="sr-title inner-title smt smb text-center">{{ "KEYWORD.patient" | translate | titlecase }}
                Details</h3>

              <div *ngFor="let group of groups; let index = index">
                <div
                  class="mb inner"
                  *ngIf="(patientPersonalizationMenu | FilterArrayMultipleOrContain: 'group':group | lengthOfArray) > 0"
                >
                  <button
                    class="sml smr smt pull-left btn main-customization-btn"
                    *ngFor="
                      let rule of patientPersonalizationMenu
                        | FilterArrayMultipleOrContain: 'group':group
                        | OrderArrayObject: ['label']
                    "
                    (click)="insertTextAtCursor(rule.code)"
                    mat-raised-button
                  >
                    <mat-icon class="{{ rule.icon }} smr" *ngIf="rule.icon"></mat-icon>
                    {{ rule.label }}
                  </button>
                </div>

                <hr
                  class="full-width"
                  *ngIf="(patientPersonalizationMenu | FilterArrayMultipleOrContain: 'group':group | lengthOfArray) > 0"
                />
              </div>
            </div>
          </mat-menu>

          <mat-menu
            class="emoji-container"
            #personalizationDentistMenu="matMenu"
            [class]="'customizationPanelContain'"
            yPosition="above"
          >
            <div class="main-customization">
              <h3 class="sr-title inner-title smt smb text-center">Sender Details</h3>

              <div *ngFor="let group of groups; let index = index">
                <div
                  class="mb inner"
                  *ngIf="(dentistPersonalizationMenu | FilterArrayMultipleOrContain: 'group':group | lengthOfArray) > 0"
                >
                  <button
                    class="sml smr smt pull-left btn main-customization-btn"
                    *ngFor="
                      let rule of dentistPersonalizationMenu
                        | FilterArrayMultipleOrContain: 'group':group
                        | OrderArrayObject: ['label']
                    "
                    (click)="insertTextAtCursor(rule.code)"
                    mat-raised-button
                  >
                    <mat-icon class="{{ rule.icon }} smr" *ngIf="rule.icon"></mat-icon>
                    {{ rule.label }}
                  </button>
                </div>

                <hr
                  class="full-width"
                  *ngIf="(dentistPersonalizationMenu | FilterArrayMultipleOrContain: 'group':group | lengthOfArray) > 0"
                />
              </div>
            </div>
          </mat-menu>

          <mat-menu
            class="emoji-container"
            #personalizationPracticeMenu="matMenu"
            [class]="'customizationPanelContain'"
            yPosition="above"
          >
            <div class="main-customization">
              <h3 class="sr-title inner-title smt smb text-center">{{ "KEYWORD.practice" | translate | titlecase}}
                Details</h3>

              <div *ngFor="let group of groups; let index = index">
                <div
                  class="mb inner"
                  *ngIf="
                    (practicePersonalizationMenu | FilterArrayMultipleOrContain: 'group':group | lengthOfArray) > 0
                  "
                >
                  <button
                    class="sml smr smt pull-left btn main-customization-btn"
                    *ngFor="
                      let rule of practicePersonalizationMenu
                        | FilterArrayMultipleOrContain: 'group':group
                        | OrderArrayObject: ['label']
                    "
                    (click)="insertTextAtCursor(rule.code)"
                    mat-raised-button
                  >
                    <mat-icon class="{{ rule.icon }} smr" *ngIf="rule.icon"></mat-icon>
                    {{ rule.label }}
                  </button>
                </div>

                <hr
                  class="full-width"
                  *ngIf="
                    (practicePersonalizationMenu | FilterArrayMultipleOrContain: 'group':group | lengthOfArray) > 0
                  "
                />
              </div>
            </div>
          </mat-menu>
        </ng-container>
      </div>
    </div>
  </form>
</div>
