import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminMainComponent } from '../../../../common/core/admin/pages/admin-main/admin-main.component';
import { ChangeLogComponent } from '../../../../common/core/helper/change-log/change-log.component';
import { GettingStartedCoordinatorComponent } from '../../../../common/core/helper/getting-started-coordinator/getting-started-coordinator.component';
import { GettingStartedDentalComponent } from '../../../../common/core/helper/getting-started-dental/getting-started-dental.component';
import { GettingStartedHomeComponent } from '../../../../common/core/helper/getting-started-home/getting-started-home.component';
import { GettingStartedMarketingComponent } from '../../../../common/core/helper/getting-started-marketing/getting-started-marketing.component';
import { GettingStartedMerchantComponent } from '../../../../common/core/helper/getting-started-merchant/getting-started-merchant.component';
import { GettingStartedMerchantStep1Component } from '../../../../common/core/helper/getting-started-merchant/step1/step1.component';
import { GettingStartedMerchantStep2Component } from '../../../../common/core/helper/getting-started-merchant/step2/step2.component';
import { GettingStartedMerchantStep3Component } from '../../../../common/core/helper/getting-started-merchant/step3/step3.component';
import { GettingStartedPracticeManagerComponent } from '../../../../common/core/helper/getting-started-practice-manager/getting-started-practice-manager.component';
import { MediaGalleryTemplatesListComponent } from '../../../../common/core/helper/media-gallery-templates-list/media-gallery-templates-list.component';
import { MediaGalleryTemplatesComponent } from '../../../../common/core/helper/media-gallery-templates/media-gallery-templates.component';
import { MediaGalleryComponent } from '../../../../common/core/helper/media-gallery/media-gallery.component';
import { TemplateMarketingCreateEditComponent } from '../../../../common/core/helper/template-marketing-create-edit/template-marketing-create-edit.component';
import { TemplateReviewComponent } from '../../../../common/core/helper/template-review/template-review.component';
import { ModuleEditCreateComponent } from '../../../../common/core/module/module-edit-create/module-edit-create.component';
import { ModuleListComponent } from '../../../../common/core/module/module-list/module-list.component';
import { NotificationListComponent } from '../../../../common/core/notification/notification-list/notification-list.component';
import { ActiveSessionsComponent } from '../../../../common/core/session/active-sessions/active-sessions.component';
import { ConnectionHistoryComponent } from '../../../../common/core/session/connection-history/connection-history.component';
import { GeneralSettingsComponent } from '../../../../common/core/settings/general-settings/general-settings.component';
import { FeatureMainLabelCreateEditComponent } from '../../../../common/core/subscription-plan/feature-main-label-create-edit/feature-main-label-create-edit.component';
import { FeatureMainLabelListComponent } from '../../../../common/core/subscription-plan/feature-main-label-list/feature-main-label-list.component';
import { FeatureSubLabelCreateEditComponent } from '../../../../common/core/subscription-plan/feature-sub-label-create-edit/feature-sub-label-create-edit.component';
import { FeatureSubLabelCreateRedirectComponent } from '../../../../common/core/subscription-plan/feature-sub-label-create-redirect/feature-sub-label-create-redirect.component';
import { FeatureSubLabelListComponent } from '../../../../common/core/subscription-plan/feature-sub-label-list/feature-sub-label-list.component';
import { FeatureSubLabelViewComponent } from '../../../../common/core/subscription-plan/feature-sub-label-view/feature-sub-label-view.component';
import { SubscriptionPlanCardListGlobalComponent } from '../../../../common/core/subscription-plan/subscription-plan-card-list-global/subscription-plan-card-list-global.component';
import { SubscriptionPlanCardListComponent } from '../../../../common/core/subscription-plan/subscription-plan-card-list/subscription-plan-card-list.component';
import { SubscriptionPlanCreateEditComponent } from '../../../../common/core/subscription-plan/subscription-plan-create-edit/subscription-plan-create-edit.component';
import { SubscriptionPlanListOverviewComponent } from '../../../../common/core/subscription-plan/subscription-plan-list-overview/subscription-plan-list-overview.component';
import { SubscriptionPlanUserListComponent } from '../../../../common/core/subscription-plan/subscription-plan-user-list/subscription-plan-user-list.component';
import { SubscriptionPlanViewComponent } from '../../../../common/core/subscription-plan/subscription-plan-view/subscription-plan-view.component';
import { ThirdPartyAccessClientOverviewComponent } from '../../../../common/core/third-party/third-party-access-client-overview/third-party-access-client-overview.component';
import { ThirdPartyAccessListOverviewComponent } from '../../../../common/core/third-party/third-party-access-list-overview/third-party-access-list-overview.component';
import { ThirdPartyAccessListComponent } from '../../../../common/core/third-party/third-party-access-list/third-party-access-list.component';
import { ThirdPartyClientCreateEditComponent } from '../../../../common/core/third-party/third-party-client-create-edit/third-party-client-create-edit.component';
import { ThirdPartyClientListComponent } from '../../../../common/core/third-party/third-party-client-list/third-party-client-list.component';
import { ActivityLogListOverviewComponent } from '../../../../common/feature/activity-log/activity-log-list-overview/activity-log-list-overview.component';
import { AiScanCreateComponent } from '../../../../common/feature/ai-scans/ai-scan-create/ai-scan-create.component';
import { AiScanReportViewComponent } from '../../../../common/feature/ai-scans/ai-scan-report-view/ai-scan-report-view.component';
import { AiScansListOverViewComponent } from '../../../../common/feature/ai-scans/ai-scans-list-overview/ai-scans-list-overview.component';
import { AppointmentArchiveComponent } from '../../../../common/feature/appointment/appointment-archive/appointment-archive.component';
import { AppointmentAvailabilityListComponent } from '../../../../common/feature/appointment/appointment-availability-list/appointment-availability-list.component';
import { AppointmentCreateMerchantComponent } from '../../../../common/feature/appointment/appointment-create-merchant/appointment-create-merchant.component';
import { AppointmentCreateComponent } from '../../../../common/feature/appointment/appointment-create/appointment-create.component';
import { AppointmentLandingCreateComponent } from '../../../../common/feature/appointment/appointment-landing-create/appointment-landing-create.component';
import { AppointmentListCalendarOverviewComponent } from '../../../../common/feature/appointment/appointment-list-calendar-overview/appointment-list-calendar-overview.component';
import { AppointmentListOverviewComponent } from '../../../../common/feature/appointment/appointment-list-overview/appointment-list-overview.component';
import { AppointmentListComponent } from '../../../../common/feature/appointment/appointment-list/appointment-list.component';
import { AppointmentPublicOperationComponent } from '../../../../common/feature/appointment/appointment-public-operation/appointment-public-operation.component';
import { BeneficiaryCatalogueViewComponent } from '../../../../common/feature/beneficiary/beneficiary-catalogue-view/beneficiary-catalogue-view.component';
import { BeneficiaryCreateEditComponent } from '../../../../common/feature/beneficiary/beneficiary-create-edit/beneficiary-create-edit.component';
import { beneficiaryDocumentGalleryOverviewComponent } from '../../../../common/feature/beneficiary/beneficiary-document-gallery-overview/beneficiary-document-gallery-overview.component';
import { BeneficiaryListGlobalComponent } from '../../../../common/feature/beneficiary/beneficiary-list-global/beneficiary-list-global.component';
import { BeneficiaryListMapComponent } from '../../../../common/feature/beneficiary/beneficiary-list-map/beneficiary-list-map.component';
import { BenficiaryListOverview } from '../../../../common/feature/beneficiary/beneficiary-list-overview/beneficiary-list-overview.component';
import { BeneficiaryListComponent } from '../../../../common/feature/beneficiary/beneficiary-list/beneficiary-list.component';
import { BeneficiarySubsidiaryCreateEditComponent } from '../../../../common/feature/beneficiary/beneficiary-subsidiary-create-edit/beneficiary-subsidiary-create-edit.component';
import {BeneficiaryPage} from '../../../../common/feature/beneficiary/beneficiary.page';
import { UnsubscribeComponent } from '../../../../common/feature/contact-us/unsubscribe/unsubscribe.component';
import { ContactCreateEditComponent } from '../../../../common/feature/contact/contact-create-edit/contact-create-edit.component';
import { ContractArrearsListComponent } from '../../../../common/feature/contract/contract-arrears-list/contract-arrears-list.component';
import { ContractDetailComponent } from '../../../../common/feature/contract/contract-detail/contract-detail.component';
import { ContractListActiveComponent } from '../../../../common/feature/contract/contract-list-active/contract-list-active.component';
import { ContractListOverviewComponent } from '../../../../common/feature/contract/contract-list-overview/contract-list-overview.component';
import { ContractListComponent } from '../../../../common/feature/contract/contract-list/contract-list.component';
import { ContractOverviewComponent } from '../../../../common/feature/contract/contract-overview/contract-overview.component';
import { ContractViewComponent } from '../../../../common/feature/contract/contract-view/contract-view.component';
import { ContractsViewComponent } from '../../../../common/feature/contract/contracts-view/contracts-view.component';
import { CustomerProspectBlankComponent } from '../../../../common/feature/customer-prospect/customer-prospect-blank/customer-prospect-blank.component';
import { CustomerProspectListArchivesComponent } from '../../../../common/feature/customer-prospect/customer-prospect-list-archives/customer-prospect-list-archives.component';
import { CustomerProspectListOverViewComponent } from '../../../../common/feature/customer-prospect/customer-prospect-list-overview/customer-prospect-list-overview.component';
import { CustomerProspectListServerComponent } from '../../../../common/feature/customer-prospect/customer-prospect-list-server/customer-prospect-list-server.component';
import { CustomerProspectMapOverviewComponent } from '../../../../common/feature/customer-prospect/customer-prospect-map-overview/customer-prospect-map-overview.component';
import { CustomerProspectTagViewComponent } from '../../../../common/feature/customer-prospect/customer-prospect-tag-view/customer-prospect-tag-view.component';
import { CustomerCreateComponent } from '../../../../common/feature/customer/customer-create/customer-create.component';
import { CustomerEditComponent } from '../../../../common/feature/customer/customer-edit/customer-edit.component';
import { CustomerImportComponent } from '../../../../common/feature/customer/customer-import/customer-import.component';
import { CustomerListArchivesComponent } from '../../../../common/feature/customer/customer-list-archives/customer-list-archives.component';
import { CustomerListComponent } from '../../../../common/feature/customer/customer-list/customer-list.component';
import { CustomerSummaryComponent } from '../../../../common/feature/customer/customer-summary/customer-summary.component';
import { CustomerViewComponent } from '../../../../common/feature/customer/customer-view/customer-view.component';
import { DefaultMessageListOverviewComponent } from '../../../../common/feature/default-message/default-message-list-overview/default-message-list-overview.component';
import { DefaultSmsSettingsComponent } from '../../../../common/feature/default-message/default-sms-settings/default-sms-settings.component';
import { DentistInviteCreateComponent } from '../../../../common/feature/dentist/dentist-invite-create/dentist-invite-create.component';
import { DentistListOverViewComponent } from '../../../../common/feature/dentist/dentist-list-overview/dentist-list-overview.component';
import { DentistListComponent } from '../../../../common/feature/dentist/dentist-list/dentist-list.component';
import { DentistMapOverViewComponent } from '../../../../common/feature/dentist/dentist-map-overview/dentist-map-overview.component';
import { DentistSettingsComponent } from '../../../../common/feature/dentist/dentist-settings/dentist-settings.component';
import { DentistViewComponent } from '../../../../common/feature/dentist/dentist-view/dentist-view.component';
import { DentistryTypesOverviewComponent } from '../../../../common/feature/dentistry-types/dentistry-types-overview/dentistry-types-overview.component';
import { DocumentLinkCreateComponent } from '../../../../common/feature/document/document-link-create/document-link-create.component';
import { DocumentLinkDetailedViewRedirectionComponent } from '../../../../common/feature/document/document-link-detailed-view-redirection/document-link-detailed-view-redirection.component';
import { DocumentLinkDetailedViewComponent } from '../../../../common/feature/document/document-link-detailed-view/document-link-detailed-view.component';
import { DocumentListThumbnailComponent } from '../../../../common/feature/document/document-list-thumbnail/document-list-thumbnail.component';
import { SelfServiceLandingPageComponent } from '../../../../common/feature/document/self-service-landing-page/self-service-landing-page.component';
import { DrugListOverviewComponent } from '../../../../common/feature/drugs/drug-list-overview/drug-list-overview.component';
import { DrugsListComponent } from '../../../../common/feature/drugs/drug-list/drug-list.component';
import { DrugsCreateEditComponent } from '../../../../common/feature/drugs/drugs-create-edit/drugs-create-edit.component';
import { DrugsViewComponent } from '../../../../common/feature/drugs/drugs-view/drugs-view.component';
import { EditorComponentCreateEditComponent } from '../../../../common/feature/editor-component/editor-component-create-edit/editor-component-create-edit.component';
import { EditorComponentListDefaultComponent } from '../../../../common/feature/editor-component/editor-component-list-default/editor-component-list-default.component';
import { EditorComponentListOverviewComponent } from '../../../../common/feature/editor-component/editor-component-list-overview/editor-component-list-overview.component';
import { EditorComponentListComponent } from '../../../../common/feature/editor-component/editor-component-list/editor-component-list.component';
import { EditorComponentLookupComponent } from '../../../../common/feature/editor-component/editor-component-lookup/editor-component-lookup.component';
import { EditorComponentRedirectionComponent } from '../../../../common/feature/editor-component/editor-component-redirection/editor-component-redirection.component';
import { EditorComponentViewComponent } from '../../../../common/feature/editor-component/editor-component-view/editor-component-view.component';
import { ExistingPatientCreateComponent } from '../../../../common/feature/existing-patient/existing-patient-create/existing-patient-create.component';
import { ExistingPatientImportComponent } from '../../../../common/feature/existing-patient/existing-patient-import/existing-patient-import.component';
import { ExistingPatientListArchivesComponent } from '../../../../common/feature/existing-patient/existing-patient-list-archives/existing-patient-list-archives.component';
import { ExistingPatientListMapComponent } from '../../../../common/feature/existing-patient/existing-patient-list-map/existing-patient-list-map.component';
import { ExistingPatientListOverViewComponent } from '../../../../common/feature/existing-patient/existing-patient-list-overview/existing-patient-list-overview.component';
import { ExistingPatientListComponent } from '../../../../common/feature/existing-patient/existing-patient-list/existing-patient-list.component';
import { ExistingPatientMapOverViewComponent } from '../../../../common/feature/existing-patient/existing-patient-map-overview/existing-patient-map-overview.component';
import { ExistingPatientSummaryComponent } from '../../../../common/feature/existing-patient/existing-patient-summary/existing-patient-summary.component';
import { ExistingPatientViewComponent } from '../../../../common/feature/existing-patient/existing-patient-view/existing-patient-view.component';
import { ProspectEditComponent } from '../../../../common/feature/existing-patient/prospect-edit/prospect-edit.component';
import { ProspectViewComponent } from '../../../../common/feature/existing-patient/prospect-view/prospect-view.component';
import { GoogleReviewListComponent } from '../../../../common/feature/google-reviews/google-review-list/google-review-list.component';
import { InformedConsentTreatmentPresentedPage } from '../../../../common/feature/informed-consent/informed-consent-treatment-presented/informed-consent-treatment-presented.page';
import { InstantOfferListOverviewComponent } from '../../../../common/feature/instant-finance-offer/instant-offer-list-overview/instant-offer-list-overview.component';
import { InstantOfferListComponent } from '../../../../common/feature/instant-finance-offer/instant-offer-list/instant-offer-list.component';
import { InstantOfferSettingsOverviewComponent } from '../../../../common/feature/instant-finance-offer/instant-offer-settings-overview/instant-offer-settings-overview.component';
import { InstantOfferSettingsComponent } from '../../../../common/feature/instant-finance-offer/instant-offer-settings/instant-offer-settings.component';
import { InstantOfferViewComponent } from '../../../../common/feature/instant-finance-offer/instant-offer-view/instant-offer-view.component';
import { InvitationTemplateCreateEditComponent } from '../../../../common/feature/invitation-template/invitation-template-create-edit/invitation-template-create-edit.component';
import { InvitationTemplateListOverviewComponent } from '../../../../common/feature/invitation-template/invitation-template-list-overview/invitation-template-list-overview.component';
import { InvitationTemplateViewComponent } from '../../../../common/feature/invitation-template/invitation-template-view/invitation-template-view.component';
import { InvitationCommunicationCreate } from '../../../../common/feature/invitation/invitation-communication-create/invitation-communication-create.component';
import { InvitationCommunicationListOverViewComponent } from '../../../../common/feature/invitation/invitation-communication-list-overview/invitation-communication-list-overview.component';
import { InvitationCreateComponent } from '../../../../common/feature/invitation/invitation-create/invitation-create.component';
import { InvitationEmergencyComponent } from '../../../../common/feature/invitation/invitation-emergency/invitation-emergency.component';
import { InvitationListArchivesComponent } from '../../../../common/feature/invitation/invitation-list-archives/invitation-list-archives.component';
import { InvitationListDraftOverViewComponent } from '../../../../common/feature/invitation/invitation-list-draft-overview/invitation-list-draft-overview.component';
import { InvitationListInformedConsentComponent } from '../../../../common/feature/invitation/invitation-list-informed-consent/invitation-list-informed-consent.component';
import { InvitationListOverViewComponent } from '../../../../common/feature/invitation/invitation-list-overview/invitation-list-overview.component';
import { InvitationOverviewComponent } from '../../../../common/feature/invitation/invitation-overview/invitation-overview.component';
import { InvitationPerformanceComponent } from '../../../../common/feature/invitation/invitation-performance/invitation-performance.component';
import { InvitationRedirectionComponent } from '../../../../common/feature/invitation/invitation-redirection/invitation-redirection.component';
import { InvitationViewComponent } from '../../../../common/feature/invitation/invitation-view/invitation-view.component';
import { LendingInvitationCreateComponent } from '../../../../common/feature/invitation/lending-invitation-create/lending-invitation-create.component';
import { QuickInvitationComponent } from '../../../../common/feature/invitation/quick-invitation/quick-invitation.component';
import { WakandaFilesComponent } from '../../../../common/feature/invitation/wakanda-files-view/wakanda-files-view.component';
import { MarketingCampaignCreateEditOverviewComponent } from '../../../../common/feature/marketing/marketing-campaign-create-edit-overview/marketing-campaign-create-edit-overview.component';
import { MarketingCampaignCreateEditComponent } from '../../../../common/feature/marketing/marketing-campaign-create-edit/marketing-campaign-create-edit.component';
import { MarketingCampaignMessageListOverviewComponent } from '../../../../common/feature/marketing/marketing-campaign-message-list-overview/marketing-campaign-message-list-overview.component';
import { MarketingCampaignReviewComponent } from '../../../../common/feature/marketing/marketing-campaign-review/marketing-campaign-review.component';
import { MarketingCampaignSocialMediaCreateComponent } from '../../../../common/feature/marketing/marketing-campaign-social-media-create/marketing-campaign-social-media-create.component';
import { MarketingCampaignViewComponent } from '../../../../common/feature/marketing/marketing-campaign-view/marketing-campaign-view.component';

import { MarketingGoogleReviewInvitationCreate } from '../../../../common/feature/marketing/marketing-google-review-invitation-create/marketing-google-review-invitation-create.component';
import { MarketingOverViewComponent } from '../../../../common/feature/marketing/marketing-over-view/marketing-over-view.component';
import { MarketingSocialMediaRedirectionComponent } from '../../../../common/feature/marketing/marketing-social-media-redirection/marketing-social-media-redirection.component';
import { MarketingTemplateGalleryComponent } from '../../../../common/feature/marketing/marketing-template-gallery/marketing-template-gallery.component';
import { MedicalHistoryCreateEditComponent } from '../../../../common/feature/medical-history/medical-history-create-edit/medical-history-create-edit.component';
import { MedicalHistoryInvitationCreate } from '../../../../common/feature/medical-history/medical-history-invitation-create/medical-history-invitation-create.component';
import { MedicalHistoryInvitationRedirectionComponent } from '../../../../common/feature/medical-history/medical-history-invitation-redirection/medical-history-invitation-redirection.component';
import { MedicalHistoryListOverViewComponent } from '../../../../common/feature/medical-history/medical-history-list-overview/medical-history-list-overview.component';
import { MerchantHealthFundsOverviewComponent } from '../../../../common/feature/merchant-health-funds/merchant-health-funds-overview/merchant-health-funds-overview.component';
import { MerchantProspectListComponent } from '../../../../common/feature/merchant-prospect/merchant-prospect-list/merchant-prospect-list.component';
import { MerchantServicesOverviewComponent } from '../../../../common/feature/merchant-services/merchant-services-overview/merchant-services-overview.component';
import { DiscountSettingComponent } from '../../../../common/feature/merchant/discount-setting/discount-setting.component';
import { MerchantAccessListComponent } from '../../../../common/feature/merchant/merchant-access-list/merchant-access-list.component';
import { MerchantCreateComponent } from '../../../../common/feature/merchant/merchant-create/merchant-create.component';
import { MerchantInformedConsentComponent } from '../../../../common/feature/merchant/merchant-informed-consent/merchant-informed-consent.component';
import { MerchantInviteComponent } from '../../../../common/feature/merchant/merchant-invite/merchant-invite.component';
import { MerchantListMapComponent } from '../../../../common/feature/merchant/merchant-list-map/merchant-list-map.component';
import { MerchantListNotActiveComponent } from '../../../../common/feature/merchant/merchant-list-not-active/merchant-list-not-active.component';
import { MerchantListComponent } from '../../../../common/feature/merchant/merchant-list/merchant-list.component';
import { MerchantProductAssistantComponent } from '../../../../common/feature/merchant/merchant-product-assistant/merchant-product-assistant.component';
import { MerchantProductAssitantOverviewComponent } from '../../../../common/feature/merchant/merchant-product-assitant-overview/merchant-product-assitant-overview.component';
import { MerchantVisitorComponent } from '../../../../common/feature/merchant/merchant-visitor/merchant-visitor.component';
import { StaffPortalComponent } from '../../../../common/feature/merchant/staff-portal/staff-portal.component';
import { MessageListOverviewComponent } from '../../../../common/feature/message/message-list-overview/message-list-overview.component';
import { SmsConversationComponent } from '../../../../common/feature/message/sms-conversation/sms-conversation.component';
import { NotesSettingsPage } from '../../../../common/feature/notes/components/notes-settings/notes-settings.page';
import { OperatorListOverviewComponent } from '../../../../common/feature/operator/operator-list-overview/operator-list-overview.component';
import { OperatorListComponent } from '../../../../common/feature/operator/operator-list/operator-list.component';
import { OperatorSessionOverviewComponent } from '../../../../common/feature/operator/operator-session-overview/operator-session-overview.component';
import { MerchantMainCardComponent } from '../../../../common/feature/patient/merchant-main-card/merchant-main-card.component';
import { PatientProfileMerchantComponent } from '../../../../common/feature/patient/patient-profile-merchant/patient-profile-merchant.component';
import { PerformanceDashboardPromoterToolsComponent } from '../../../../common/feature/performance/performance-dashboard-promoter-tools/performance-dashboard-promoter-tools.component';
import { PerformanceIntroModalComponent } from '../../../../common/feature/performance/performance-intro-modal/performance-intro-modal.component';
import { PerformanceMerchantComponent } from '../../../../common/feature/performance/performance-merchant/performance-merchant.component';
import { PerformancePromoterComponent } from '../../../../common/feature/performance/performance-promoter/performance-promoter.component';
import { FinanceCalculatorComponent } from '../../../../common/feature/product/finance-calculator/finance-calculator.component';
import { ProductDocumentLibraryComponent } from '../../../../common/feature/product/product-document-library/product-document-library.component';
import { ProductListComponent } from '../../../../common/feature/product/product-list/product-list.component';
import { CustomerDetailsCardEditComponent } from '../../../../common/feature/profils/customer-details-card-edit/customer-details-card-edit.component';
import { CustomerProspectProfileComponent } from '../../../../common/feature/profils/customer-prospect-profile/customer-prospect-profile.component';
import { DentistProfileComponent } from '../../../../common/feature/profils/dentist-profile/dentist-profile.component';
import { InvitationSettingsComponent } from '../../../../common/feature/profils/invitation-settings/invitation-settings.component';
import { MarketingSettingsComponent } from '../../../../common/feature/profils/marketing-settings/marketing-settings.component';
import { MerchantProfileComponent } from '../../../../common/feature/profils/merchant-profile/merchant-profile.component';
import { MerchantProspectProfileComponent } from '../../../../common/feature/profils/merchant-prospect-profile/merchant-prospect-profile.component';
import { ProfileSessionEditComponent } from '../../../../common/feature/profils/profile-session-edit/profile-session-edit.component';
import { ProfileSessionOverViewComponent } from '../../../../common/feature/profils/profile-session-over-view/profile-session-over-view.component';
import { ProspectDetailsCardEditComponent } from '../../../../common/feature/profils/prospect-details-card-edit/prospect-details-card-edit.component';
import { QuotationContractViewComponent } from '../../../../common/feature/quotation/quotation-contract-view/quotation-contract-view.component';
import { QuotationInvitationCreateComponent } from '../../../../common/feature/quotation/quotation-invitation-create/quotation-invitation-create.component';
import { QuotationListOverviewComponent } from '../../../../common/feature/quotation/quotation-list-overview/quotation-list-overview.component';
import { ReferralCreateComponent } from '../../../../common/feature/referral/referral-create/referral-create.component';
import { ReferralListComponent } from '../../../../common/feature/referral/referral-list/referral-list.component';
import { ReferralViewComponent } from '../../../../common/feature/referral/referral-view/referral-view.component';
import { SettlementArchiveComponent } from '../../../../common/feature/settlement/settlement-archive/settlement-archive.component';
import { SettlementContractListComponent } from '../../../../common/feature/settlement/settlement-contract-list/settlement-contract-list.component';
import { SettlementCreateComponent } from '../../../../common/feature/settlement/settlement-create/settlement-create.component';
import { SettlementCustomerListOverViewComponent } from '../../../../common/feature/settlement/settlement-customer-list-overview/settlement-customer-list-overview.component';
import { SettlementIntroModalComponent } from '../../../../common/feature/settlement/settlement-intro-modal/settlement-intro-modal.component';
import { SettlementListOverViewComponent } from '../../../../common/feature/settlement/settlement-list-overview/settlement-list-overview.component';
import { SettlementOverviewComponent } from '../../../../common/feature/settlement/settlement-overview/settlement-overview.component';
import { SettlementPaymentsReportPage } from '../../../../common/feature/settlement/settlement-payments-report/settlement-payments-report.page';
import { SettlementRefundCreateComponent } from '../../../../common/feature/settlement/settlement-refund-create/settlement-refund-create.component';
import { SettlementRequestComponent } from '../../../../common/feature/settlement/settlement-request/settlement-request.component';
import { SettlementSummaryListComponent } from '../../../../common/feature/settlement/settlement-summary-list/settlement-summary-list.component';
import { SettlementViewGroupComponent } from '../../../../common/feature/settlement/settlement-view-group/settlement-view-group.component';
import { SettlementViewComponent } from '../../../../common/feature/settlement/settlement-view/settlement-view.component';
import { SmileMakeoverManagerComponent } from '../../../../common/feature/smile-makeover-manager/smile-makeover-manager.component';
import { SocialMediaCreateEditComponent } from '../../../../common/feature/social-media/social-media-create-edit/social-media-create-edit.component';
import { SocialMediaGalleryListComponent } from '../../../../common/feature/social-media/social-media-gallery-list/social-media-gallery-list.component';
import { SocialMediaListComponent } from '../../../../common/feature/social-media/social-media-list/social-media-list.component';
import { TagsListOverviewComponent } from '../../../../common/feature/tags/tags-list-overview/tags-list-overview.component';
import { TagsViewComponent } from '../../../../common/feature/tags/tags-view/tags-view.component';
import { TaskManagerListComponent } from '../../../../common/feature/tasks/task-manager-list/task-manager-list.component';
import { TasksCreateComponent } from '../../../../common/feature/tasks/tasks-create/tasks-create.component';
import { TasksListOverviewComponent } from '../../../../common/feature/tasks/tasks-list-overview/tasks-list-overview.component';
import { TasksSummaryListComponent } from '../../../../common/feature/tasks/tasks-summary-list/tasks-summary-list.component';
import { TemplateCreateEditComponent } from '../../../../common/feature/template/template-create-edit/template-create-edit.component';
import { TemplateListOverviewComponent } from '../../../../common/feature/template/template-list-overview/template-list-overview.component';
import { TemplatePurposeListComponent } from '../../../../common/feature/template/template-purpose-list/template-purpose-list.component';
import { TestimonyListComponent } from '../../../../common/feature/testimony/testimony-list/testimony-list.component';
import { TipOverviewComponent } from '../../../../common/feature/tip/tip-overview/tip-overview.component';

import { TreatmentChoiceAdvancedComponent } from '../../../../common/feature/treatment-plan/treatment-choice-advanced/treatment-choice-advanced.component';
import {
  DynamicProductCustomerListComponent
} from '../../../../common/feature/product/dynamic-product-customer-list/dynamic-product-customer-list.component';
import {
  DynamicProductInvoiceListComponent
} from '../../../../common/feature/product/dynamic-product-invoice-list/dynamic-product-invoice-list.component';
import { TreatmentBrandCreateEditComponent } from '../../../../common/feature/treatment/treatment-brand-create-edit/treatment-brand-create-edit.component';
import { TreatmentBrandListGlobalComponent } from '../../../../common/feature/treatment/treatment-brand-list-global/treatment-brand-list-global.component';
import { TreatmentBrandListOverviewComponent } from '../../../../common/feature/treatment/treatment-brand-list-overview/treatment-brand-list-overview.component';
import { TreatmentCategoryCreateEditComponent } from '../../../../common/feature/treatment/treatment-category-create-edit/treatment-category-create-edit.component';
import { TreatmentCategoryListComponent } from '../../../../common/feature/treatment/treatment-category-list/treatment-category-list.component';
import { TreatmentDocumentCreateComponent } from '../../../../common/feature/treatment/treatment-document-create/treatment-document-create.component';
import { TreatmentDocumentGalleryOverviewComponent } from '../../../../common/feature/treatment/treatment-document-gallery-overview/treatment-document-gallery-overview.component';
import { TreatmentProductCreateEditComponent } from '../../../../common/feature/treatment/treatment-product-create-edit/treatment-product-create-edit.component';
import { TreatmentProductListGlobalComponent } from '../../../../common/feature/treatment/treatment-product-list-global/treatment-product-list-global.component';
import { TreatmentProductListOverviewComponent } from '../../../../common/feature/treatment/treatment-product-list-overview/treatment-product-list-overview.component';
import { TreatmentTypeCreateEditComponent } from '../../../../common/feature/treatment/treatment-type-create-edit/treatment-type-create-edit.component';
import { TreatmentTypeListComponent } from '../../../../common/feature/treatment/treatment-type-list/treatment-type-list.component';
import { WishlistPage } from '../../../../common/feature/wishlist/wishlist.page';
import { TextEditorInputComponent } from '../../../../common/shared/components/text-editor-input/text-editor-input.component';
import { UserAccessViewComponent } from '../../../../common/shared/components/user-access-view/user-access-view.component';
import { IntegrationComponent } from '../integration/integration.component';
import { LoginMerchantComponent } from '../login/login.component';
import { MainRedirectionComponent } from '../main-redirection/main-redirection.component';
import { MainMerchantComponent } from '../main/main.component';
import { DashbordOverViewComponent } from '../pages/dashboard/dashbord-over-view/dashbord-over-view.component';
import { ConsumerRedirectionComponent } from '../pages/helper/consumer-redirection/consumer-redirection.component';
import { MerchantAuthGuard } from './guard.service';
import {
  DynamicProductListComponent
} from "../../../../common/feature/product/dynamic-product-list/dynamic-product-list.component";
import { TranslationPage } from '../../../../common/feature/translation-management/translation-management-create/translation-management-create.component';
import { AdminThemeComponent } from '../../../../common/core/admin/features/admin-theme/admin-theme.component';
import { PasswordResetEmptyPageComponent } from '../../../../common/core/helper/password-reset-empty-page/password-reset-empty-page.component';
import { MessageGroupCreateComponent } from '../../../../common/feature/message/message-group-create/message-group-create.component';
import { MarketingFilterViewComponent } from '../../../../common/feature/marketing-filter/marketing-filter-view/marketing-filter-view.component';
import { MarketingFilterListOverViewComponent } from '../../../../common/feature/marketing-filter/marketing-filter-list-overview/marketing-filter-list-overview.component';
import { MarketingFilterCreateComponent } from '../../../../common/feature/marketing-filter/marketing-filter-create/marketing-filter-create.component';
import { MessageGroupViewComponent } from '../../../../common/feature/message/message-group-view/message-group-view.component';
import { MessageGroupListComponent } from '../../../../common/feature/message/message-group-list/message-group-list.component';
import { MessageGroupListOverviewComponent } from '../../../../common/feature/message/message-group-list-overview/message-group-list-overview.component';
import { DocumentLinkListOverviewComponent } from '../../../../common/feature/document/document-link-list-overview/document-link-list-overview.component';
import { ContractOverviewViewComponent } from '../../../../common/feature/contract/contract-overview-view/contract-overview-view.component';
import { PatientPersonalSMSComponent } from '../../../../common/feature/message/patient-personal-sms/patient-personal-sms.component';
import { ContractListServerComponent } from '../../../../common/feature/contract/contract-list-server/contract-list-server.component';

const routes: Routes = [
  // DIRTY FIX
  {
    path: 'consumer/invitation-card/:consumerID',
    component: ConsumerRedirectionComponent,
  },
  {
    path: 'appointment-decision/:appointmentID',
    component: AppointmentPublicOperationComponent,
  },

  {
    path: 'unsubscribe/:patientID/:merchantID',
    component: UnsubscribeComponent,
  },
  {
    path: 'main',
    component: MainRedirectionComponent,
    children: [{ path: '**', component: MainRedirectionComponent, outlet: 'page' }],
  },
  {
    path: 'promoter',
    component: MainRedirectionComponent,
    children: [{ path: '**', component: MainRedirectionComponent, outlet: 'page' }],
  },
  {
    path: 'funder',
    component: MainRedirectionComponent,
    children: [{ path: '**', component: MainRedirectionComponent, outlet: 'page' }],
  },
  {
    path: 'supplier',
    component: MainRedirectionComponent,
    children: [{ path: '**', component: MainRedirectionComponent, outlet: 'page' }],
  },
  {
    path: 'tmp',
    component: MainRedirectionComponent,
    children: [{ path: '**', component: MainRedirectionComponent, outlet: 'page' }],
  },
  {
    path: 'consumer',
    component: ConsumerRedirectionComponent,
    children: [
      { path: 'patient-card/:consumerID', component: ConsumerRedirectionComponent, outlet: 'page' },
      { path: 'invitation-card/:consumerID', component: ConsumerRedirectionComponent, outlet: 'page' },
      { path: 'invitation-card/:consumerID/:action', component: ConsumerRedirectionComponent, outlet: 'page' },
      { path: 'patient-card-quotation/:consumerID', component: ConsumerRedirectionComponent, outlet: 'page' },
      { path: 'apply', component: ConsumerRedirectionComponent, data: { title: 'Apply today' }, outlet: 'page' },
      { path: 'referral-card', component: ConsumerRedirectionComponent, outlet: 'page' },
      { path: 'referral', component: ConsumerRedirectionComponent, outlet: 'page' },
      {
        path: 'patient-history',
        component: ConsumerRedirectionComponent,
        data: { title: 'Update your details' },
        outlet: 'page',
      },
      { path: 'practice-auth', component: ConsumerRedirectionComponent, outlet: 'page' },
      { path: 'practice-auth/:merchantID', component: ConsumerRedirectionComponent, outlet: 'page' },

      {
        path: 'profile-login/:patientID/:merchantID',
        component: ConsumerRedirectionComponent,
        data: { title: 'Please confirm your details' },
        outlet: 'page',
      },
    ],
  },
  {
    path: 'consumer-app',
    component: ConsumerRedirectionComponent,
    children: [
      {
        path: '',
        component: ConsumerRedirectionComponent,
        data: { title: '' },
        outlet: 'page',
      },
      {
        path: 'home',
        component: ConsumerRedirectionComponent,
        data: { title: '' },
        outlet: 'page',
      },
      {
        path: 'home/:patientID/:merchantID',
        component: ConsumerRedirectionComponent,
        data: { title: '' },
        outlet: 'page',
      },
      {
        path: 'profile',
        component: ConsumerRedirectionComponent,
        data: { title: ' - My profile' },
        outlet: 'page',
      },
      {
        path: 'profile/:patientID',
        component: ConsumerRedirectionComponent,
        data: { title: ' - My profile' },
        outlet: 'page',
      },
      {
        path: 'my-dentist',
        component: ConsumerRedirectionComponent,
        data: { title: ' - My dentist' },
        outlet: 'page',
      },
      {
        path: 'my-treatment',
        component: ConsumerRedirectionComponent,
        data: { title: ' - My treatments' },
        outlet: 'page',
      },
      {
        path: 'my-treatment/:patientID',
        component: ConsumerRedirectionComponent,
        data: { title: ' - My treatments' },
        outlet: 'page',
      },
      {
        path: 'about',
        component: ConsumerRedirectionComponent,
        data: { title: ' - About Smile Right' },
        outlet: 'page',
      },
      {
        path: 'account-queries',
        component: ConsumerRedirectionComponent,
        data: { title: ' - Account queries' },
        outlet: 'page',
      },
      {
        path: 'billing',
        component: ConsumerRedirectionComponent,
        data: { title: ' - Update billing details' },
        outlet: 'page',
      },
      {
        path: 'payment-help',
        component: ConsumerRedirectionComponent,
        data: { title: ' - Payment help' },
        outlet: 'page',
      },
      {
        path: 'help',
        component: ConsumerRedirectionComponent,
        data: { title: ' - Frequently asked questions' },
        outlet: 'page',
      },
    ],
  },
  {
    path: 'customer',
    component: ConsumerRedirectionComponent,
    children: [
      {
        path: 'customer-prospect-current',
        component: ConsumerRedirectionComponent,
        data: { title: 'View Profile' },
        outlet: 'page',
      },
      {
        path: 'medical/invitation/:invitationID',
        component: ConsumerRedirectionComponent,
        data: { title: ' - Your dental history' },
        outlet: 'page',
      },
      {
        path: 'medical/medical-invitation/:medicalInvitationID',
        component: ConsumerRedirectionComponent,
        data: { title: ' - Your dental history' },
        outlet: 'page',
      },
      {
        path: 'medical/:customerID',
        component: ConsumerRedirectionComponent,
        data: { title: ' - Your dental history' },
        outlet: 'page',
      },
      {
        path: 'medical/:customerID/:merchantID',
        component: ConsumerRedirectionComponent,
        data: { title: ' - Your dental history' },
        outlet: 'page',
      },
    ],
  },
  {
    path: 'customer-app',
    component: ConsumerRedirectionComponent,
    children: [
      {
        path: 'medical',
        component: ConsumerRedirectionComponent,
        data: { title: ' - Update your health history' },
        outlet: 'page',
      },
      {
        path: 'medical/invitation/:invitationID',
        component: ConsumerRedirectionComponent,
        data: { title: ' - Update your health history' },
        outlet: 'page',
      },
      {
        path: 'medical/medical-invitation/:medicalInvitationID',
        component: ConsumerRedirectionComponent,
        data: { title: ' - Update your health history' },
        outlet: 'page',
      },
      {
        path: 'medical/patient/:customerID',
        component: ConsumerRedirectionComponent,
        data: { title: ' - Update your health history' },
        outlet: 'page',
      },
      {
        path: 'medical/:customerID/:merchantID',
        component: ConsumerRedirectionComponent,
        data: { title: ' - Update your health history' },
        outlet: 'page',
      },
      {
        path: 'fee-funding-invitation-card',
        component: ConsumerRedirectionComponent,
        data: { title: 'New invitation' },
        outlet: 'page',
      },
      {
        path: 'consumer-contact-inbox',
        component: ConsumerRedirectionComponent,
        data: { title: 'Consumer Contact' },
        outlet: 'page',
      },
      {
        path: 'consumer-reply-message',
        component: ConsumerRedirectionComponent,
        data: { title: 'Consumer reply message' },
        outlet: 'page',
      },
      {
        path: '',
        component: ConsumerRedirectionComponent,
        data: { title: 'Consumer Dashboard' },
        outlet: 'page',
      },
      {
        path: 'home',
        component: ConsumerRedirectionComponent,
        data: { title: 'Consumer Dashboard' },
        outlet: 'page',
      },
      {
        path: 'help',
        component: ConsumerRedirectionComponent,
        data: { title: 'Consumer Help' },
        outlet: 'page',
      },
      {
        path: 'settings',
        component: ConsumerRedirectionComponent,
        data: { title: 'Consumer Settings' },
        outlet: 'page',
      },
    ],
  },

  {
    path: 'connect/:transactionToken',
    component: IntegrationComponent,
  },
  {
    path: 'login-admin',
    component: LoginMerchantComponent,
  },

  {
    path: 'login',
    component: LoginMerchantComponent,
    canActivate: [MerchantAuthGuard],
  },

  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },

  {
    path: 'login/:redirection',
    component: LoginMerchantComponent,
    canActivate: [MerchantAuthGuard],
  },

  {
    path: 'merchant',
    component: MainMerchantComponent,
    canActivate: [MerchantAuthGuard],
    children: [
      {
        path: '',
        component: DashbordOverViewComponent,
        data: { title: 'Dashboard', tutorial: 'DashbordOverView', openDialog: 'home' },
        outlet: 'page',
      },
      {
        path: 'invitation-list-draft',
        component: InvitationListDraftOverViewComponent,
        data: { title: 'Draft Invitations', tutorial: 'InvitationListDraftOverView' },
        outlet: 'page',
      },
      {
        path: 'treatment-saved-template',
        component: InvitationTemplateListOverviewComponent,
        data: { title: 'Treatment Templates' },
        outlet: 'page',
      },
      {
        path: 'treatment-template-view/:invitationTemplateID',
        component: InvitationTemplateViewComponent,
        data: { title: 'View Invitation Template' },
        outlet: 'page',
      },
      {
        path: 'home',
        component: DashbordOverViewComponent,
        data: {
          title: 'Dashboard',
          tutorial: 'DashbordOverView',
          openDialog: 'home',
        },
        outlet: 'page',
      },
      {
        path: 'home/:redirection',
        component: DashbordOverViewComponent,
        data: {
          title: 'Dashboard',
          tutorial: 'DashbordOverView',
          openDialog: 'home',
        },
        outlet: 'page',
      },
      {
        path: 'dashboard-video/:videoID',
        component: DashbordOverViewComponent,
        data: {
          title: 'Dashboard',
          tutorial: 'DashbordOverView',
          openDialog: 'home',
        },
        outlet: 'page',
      },
      { path: 'admin', component: AdminMainComponent, outlet: 'page' },
      {
        path: 'new-invitation',
        component: InvitationRedirectionComponent,
        data: { title: 'New invitation' },
        outlet: 'page',
      },
      {
        path: 'new-invitation/:productGroup',
        component: InvitationRedirectionComponent,
        data: { title: 'New invitation' },
        outlet: 'page',
      },
      {
        path: 'new-invitation/:productGroup/:amount',
        component: InvitationRedirectionComponent,
        data: { title: 'New invitation' },
        outlet: 'page',
      },
      {
        path: 'invitation-create/:invitationID',
        component: InvitationCreateComponent,
        data: {
          title: 'New Invitation',
          tutorial: 'InvitationCreate',
        },
        outlet: 'page',
      },
      {
        path: 'invitation-communication-list-overview/:purpose',
        component: InvitationCommunicationListOverViewComponent,
        data: {
          title: 'Invitations',
        },
        outlet: 'page',
      },

      {
        path: 'invitation-communication-create/:purpose',
        component: InvitationCommunicationCreate,
        data: {
          title: 'New Communication Invitation',
        },
        outlet: 'page',
      },
      {
        path: 'invitation-communication-create/:purpose/:patientID',
        component: InvitationCommunicationCreate,
        data: {
          title: 'New Communication Invitation',
        },
        outlet: 'page',
      },
      {
        path: 'medical-history-invitation-redirection/:patientID',
        component: MedicalHistoryInvitationRedirectionComponent,
        data: {
          title: 'New Health History Invitation',
          tutorial: 'InvitationCreate',
        },
        outlet: 'page',
      },
      {
        path: 'medical-history-invitation-redirection',
        component: MedicalHistoryInvitationRedirectionComponent,
        data: {
          title: 'New Health History Invitation',
          tutorial: 'InvitationCreate',
        },
        outlet: 'page',
      },
      {
        path: 'medical-history-invitation-create/:patientID',
        component: MedicalHistoryInvitationCreate,
        data: {
          title: 'New Health History Invitation',
          tutorial: 'InvitationCreate',
        },
        outlet: 'page',
      },
      {
        path: 'medical-history-invitation-create',
        component: MedicalHistoryInvitationCreate,
        data: {
          title: 'New Health History Invitation',
          tutorial: 'InvitationCreate',
        },
        outlet: 'page',
      },
      {
        path: 'google-review-invitation-create',
        component: MarketingGoogleReviewInvitationCreate,
        data: {
          title: 'New Google Review Invitation',
        },
        outlet: 'page',
      },
      {
        path: 'lending-invitation-create/:invitationID',
        component: LendingInvitationCreateComponent,
        data: { title: 'New Invitation' },
        outlet: 'page',
      },
      {
        path: 'invitation-view/:id',
        component: InvitationViewComponent,
        data: { title: 'Detailed invitation view' },
        outlet: 'page',
      },
      {
        path: 'invitation-overview',
        component: InvitationOverviewComponent,
        data: { title: 'Invitation overview', tutorial: 'InvitationOverview' },
        outlet: 'page',
      },
      {
        path: 'invitation-list-overview',
        component: InvitationListOverViewComponent,
        data: { title: 'Finance Invitations', tutorial: 'InvitationListOverView' },
        outlet: 'page',
      },
      {
        path: 'invitation-list-overview/:status',
        component: InvitationListOverViewComponent,
        data: { title: 'Finance Invitations', tutorial: 'InvitationListOverView' },
        outlet: 'page',
      },
      {
        path: 'invitation-list-archives',
        component: InvitationListArchivesComponent,
        data: { title: 'Archives', tutorial: 'InvitationListArchives' },
        outlet: 'page',
      },

      {
        path: 'invitation-list-archives',
        component: InvitationListArchivesComponent,
        data: { title: 'Archives Invitations' },
        outlet: 'page',
      },
      {
        path: 'invitation-list-informed-consent',
        component: InvitationListInformedConsentComponent,
        outlet: 'page',
      },
      {
        path: 'lending-invitation-create',
        component: LendingInvitationCreateComponent,
        outlet: 'page',
      },
      {
        path: 'notification-list',
        component: NotificationListComponent,
        outlet: 'page',
      },
      {
        path: 'customer-list',
        component: CustomerListComponent,
        data: { title: 'Customer list', tutorial: 'CustomerList' },
        outlet: 'page',
      },

      {
        path: 'customer-view/:customerID',
        component: CustomerViewComponent,
        data: { title: 'Customer view' },
        outlet: 'page',
      },
      {
        path: 'settlement-contract-view/:contractID',
        component: SettlementContractListComponent,
        data: { title: 'Settlement list' },
        outlet: 'page',
      },

      {
        path: 'prospect-view/:prospectID',
        component: ProspectViewComponent,
        data: { title: 'Prospect view' },
        outlet: 'page',
      },

      {
        path: 'prospect-edit/:prospectID',
        component: ProspectEditComponent,
        data: { title: 'Prospect Edit' },
        outlet: 'page',
      },
      {
        path: 'existing-invitation-view',
        component: ProspectViewComponent,
        data: { title: 'Invitation view' },
        outlet: 'page',
      },
      {
        path: 'existing-invitation-view/:invitationID',
        component: ProspectViewComponent,
        data: { title: 'Invitation view' },
        outlet: 'page',
      },

      {
        path: 'customer-edit/:customerID',
        component: CustomerEditComponent,
        data: { title: 'Customer Edit' },
        outlet: 'page',
      },
      {
        path: 'customer-list-archives',
        component: CustomerListArchivesComponent,
        data: { title: 'Customer list archives' },
        outlet: 'page',
      },
      {
        path: 'customer-create',
        component: CustomerCreateComponent,
        data: { title: 'Customer create' },
        outlet: 'page',
      },
      {
        path: 'customer-import',
        component: CustomerImportComponent,
        data: { title: 'Customer import' },
        outlet: 'page',
      },
      {
        path: 'customer-summary',
        component: CustomerSummaryComponent,
        outlet: 'page',
      },
      {
        path: 'existing-patient-list-map-overview',
        component: ExistingPatientMapOverViewComponent,
        data: { title: 'Customers Map' },
        outlet: 'page',
      },
      {
        path: 'existing-patient-list-map',
        component: ExistingPatientListMapComponent,
        data: { title: 'Customers Map' },
        outlet: 'page',
      },
      {
        path: 'existing-patient-list',
        component: ExistingPatientListComponent,
        data: { title: 'Customers' },
        outlet: 'page',
      },
      {
        path: 'existing-patient-list-overview',
        component: ExistingPatientListOverViewComponent,
        data: { title: 'Customers', tutorial: 'ExistingPatientListOverView' },
        outlet: 'page',
      },
      {
        path: 'existing-patient-view',
        component: ExistingPatientViewComponent,
        data: { title: 'Existing customer view' },
        outlet: 'page',
      },
      {
        path: 'existing-patient-list-archives',
        component: ExistingPatientListArchivesComponent,
        data: { title: 'Existing customer list archives' },
        outlet: 'page',
      },
      {
        path: 'existing-patient-create',
        component: ExistingPatientCreateComponent,
        data: { title: 'New patient' },
        outlet: 'page',
      },
      {
        path: 'existing-patient-import',
        component: ExistingPatientImportComponent,
        outlet: 'page',
      },
      {
        path: 'existing-patient-summary',
        component: ExistingPatientSummaryComponent,
        outlet: 'page',
      },

      {
        path: 'financial-calculator',
        component: FinanceCalculatorComponent,
        data: { title: 'Financial calcultor' },
        outlet: 'page',
      },

      {
        path: 'product-list',
        component: ProductListComponent,
        data: { title: 'Product list' },
        outlet: 'page',
      },
      {
        path: 'product-customers',
        component: DynamicProductCustomerListComponent,
        data: { title: 'Serialised Products' },
        outlet: 'page',
      },
      {
        path: 'product-catalogue',
        component: DynamicProductListComponent,
        data: { title: 'Products' },
        outlet: 'page',
      },
      {
        path: 'product-invoices',
        component: DynamicProductInvoiceListComponent,
        data: { title: 'Invoices' },
        outlet: 'page',
      },
      {
        path: 'treatment-product-list',
        component: TreatmentProductListOverviewComponent,
        data: { title: 'Price List' },
        outlet: 'page',
      },
      {
        path: 'document-library',
        component: ProductDocumentLibraryComponent,
        outlet: 'page',
      },

      {
        path: 'appointment-create',
        component: AppointmentCreateComponent,
        outlet: 'page',
      },
      {
        path: 'appointment-create-merchant',
        component: AppointmentCreateMerchantComponent,
        outlet: 'page',
      },
      {
        path: 'appointment-create-merchant/invitation/:invitationID',
        component: AppointmentCreateMerchantComponent,
        outlet: 'page',
      },
      {
        path: 'appointment-create-merchant/customer/:customerID',
        component: AppointmentCreateMerchantComponent,
        outlet: 'page',
      },

      {
        path: 'appointment-list',
        component: AppointmentListComponent,
        outlet: 'page',
      },

      {
        path: 'appointment-archive',
        component: AppointmentArchiveComponent,
        outlet: 'page',
      },
      {
        path: 'settlement-overview',
        data: { title: 'Settlements' },
        component: SettlementOverviewComponent,
        outlet: 'page',
      },
      {
        path: 'settlement-summary-list',
        data: { title: 'Settlement Summary' },
        component: SettlementSummaryListComponent,
        outlet: 'page',
      },
      {
        path: 'settlement-customer-list-overview',
        data: { title: 'Settlements' },
        component: SettlementCustomerListOverViewComponent,
        outlet: 'page',
      },

      {
        path: 'appointment-availability-staff',
        component: AppointmentAvailabilityListComponent,
        data: { title: 'Staff appointment availability' },
        outlet: 'page',
      },

      {
        path: 'appointment-availability-staff/:merchantID',
        component: AppointmentAvailabilityListComponent,
        data: { title: 'Staff appointment availability' },
        outlet: 'page',
      },

      {
        path: 'settlement-list-overview',
        data: { title: 'Settlements' },
        component: SettlementListOverViewComponent,
        outlet: 'page',
      },
      {
        path: 'settlement-list-overview/:status',
        data: { title: 'Settlements' },
        component: SettlementListOverViewComponent,
        outlet: 'page',
      },
      {
        path: 'settlement-list-overview-filter/:statusCodes',
        data: { title: 'Settlements' },
        component: SettlementListOverViewComponent,
        outlet: 'page',
      },
      {
        path: 'settlement-view',
        data: { title: 'Settlement details' },
        component: SettlementViewComponent,
        outlet: 'page',
      },
      {
        path: 'settlement-group/:settlementGroupID',
        data: { title: 'Settlement group details' },
        component: SettlementViewGroupComponent,
        outlet: 'page',
      },
      {
        path: 'settlement-view/:settlementID',
        data: { title: 'Settlement details' },
        component: SettlementViewComponent,
        outlet: 'page',
      },

      {
        path: 'appointment-landing-page',
        data: { title: 'Practice appointment page ' },
        component: AppointmentLandingCreateComponent,
        outlet: 'page',
      },
      {
        path: 'settlement-archive',
        data: { title: 'Settlements' },
        component: SettlementArchiveComponent,
        outlet: 'page',
      },
      {
        path: 'settlement-intro-modal',
        component: SettlementIntroModalComponent,
        outlet: 'page',
      },

      {
        path: 'settlement-create',
        component: SettlementCreateComponent,
        data: { title: 'New Finance Settlement' },
        outlet: 'page',
      },
      {
        path: 'settlement-create/:contractKey',
        component: SettlementCreateComponent,
        data: { title: 'New Finance Settlement' },
        outlet: 'page',
      },
      {
        path: 'settlement-create/contract/:contractKey',
        component: SettlementCreateComponent,
        data: { title: 'New Finance Settlement' },
        outlet: 'page',
      },
      {
        path: 'settlement-create/contract/:contractKey/:settlementTransactionToken',
        component: SettlementCreateComponent,
        data: { title: 'New Finance Settlement' },
        outlet: 'page',
      },
      {
        path: 'settlement-create/customer/:customerKey',
        component: SettlementCreateComponent,
        data: { title: 'New Finance Settlement' },
        outlet: 'page',
      },


      {
        path: 'settlement-create-material-request/:materialRequest',
        component: SettlementCreateComponent,
        data: { title: 'New Material Advance' },
        outlet: 'page',
      },
      {
        path: 'settlement-create-material-request/:contractKey/:materialRequest',
        component: SettlementCreateComponent,
        data: { title: 'New Material Advance' },
        outlet: 'page',
      },
      {
        path: 'settlement-create-material-request/contract/:contractKey/:materialRequest',
        component: SettlementCreateComponent,
        data: { title: 'New Material Advance' },
        outlet: 'page',
      },

      {
        path: 'settlement-create-material-request/contract/:contractKey/:settlementTransactionToken/:materialRequest',
        component: SettlementCreateComponent,
        data: { title: 'New Material Advance' },
        outlet: 'page',
      },
      {
        path: 'settlement-create-material-request/customer/:customerKey/:materialRequest',
        component: SettlementCreateComponent,
        data: { title: 'New Material Advance' },
        outlet: 'page',
      },

      {
        path: 'settlement-refund-create',
        component: SettlementRefundCreateComponent,
        data: { title: 'New Refund Settlement' },
        outlet: 'page',
      },
      {
        path: 'settlement-payments-report',
        component: SettlementPaymentsReportPage,
        data: {
          title: 'Payments',
          initialDateMode: 'lastFridayOrTodayIsFriday',
        },
        outlet: 'page',
      },
      {
        path: 'settlement-request/:settlementID',
        component: SettlementRequestComponent,
        data: { title: 'Request settlement' },
        outlet: 'page',
      },
      {
        path: 'promoter-performance-overview',
        component: PerformanceDashboardPromoterToolsComponent,
        outlet: 'page',
      },
      {
        path: 'invitation-performance',
        data: { title: 'Invitation Performance' },
        component: InvitationPerformanceComponent,
        outlet: 'page',
      },
      {
        path: 'performance-intro-modal',
        component: PerformanceIntroModalComponent,
        outlet: 'page',
      },
      {
        path: 'performance-promoter',
        component: PerformancePromoterComponent,
        outlet: 'page',
      },
      {
        path: 'performance-merchant',
        component: PerformanceMerchantComponent,
        outlet: 'page',
      },
      {
        path: 'TextEditorInputComponent',
        component: TextEditorInputComponent,
        outlet: 'page',
      },
      {
        path: 'settings',
        component: ProfileSessionEditComponent,
        data: { title: 'User Settings', tutorial: 'SessionEditProfile' },
        outlet: 'page',
      },

      {
        path: 'settings/:settings',
        component: ProfileSessionEditComponent,
        data: { title: 'User Settings', tutorial: 'SessionEditProfile' },
        outlet: 'page',
      },

      {
        path: 'dentist-overview',
        component: ProfileSessionOverViewComponent,
        data: { title: 'Dentist overview', tutorial: 'DentistOverview' },
        outlet: 'page',
      },
      {
        path: 'dentist-overview/:settings',
        component: ProfileSessionOverViewComponent,
        data: { title: 'Dentist overview', tutorial: 'DentistOverview' },
        outlet: 'page',
      },
      {
        path: 'staff-list',
        component: DentistListComponent,
        data: { title: 'Staff members list' },
        outlet: 'page',
      },
      {
        path: 'dentist-list',
        component: DentistListComponent,
        data: { title: 'Dentist List', tutorial: 'DentistList' },
        outlet: 'page',
      },
      { path: 'dentist-view', component: DentistViewComponent, outlet: 'page' },
      {
        path: 'dentist-invite-create',
        component: DentistInviteCreateComponent,
        outlet: 'page',
      },
      {
        path: 'dentist-invite-create/:dentistID',
        component: DentistInviteCreateComponent,
        outlet: 'page',
      },
      {
        path: 'dentist-settings',
        component: DentistSettingsComponent,
        outlet: 'page',
      },
      {
        path: 'dentist-profile/:dentistID',
        component: DentistProfileComponent,
        outlet: 'page',
      },

      {
        path: 'invitation-settings',
        component: InvitationSettingsComponent,
        data: { title: 'Treatment Invitation Settings' },
        outlet: 'page',
      },

      {
        path: 'discount-settings',
        component: DiscountSettingComponent,
        data: { title: 'Cash Discount Settings', tutorial: 'SessionEditProfile' },
        outlet: 'page',
      },
      {
        path: 'merchant-list',
        component: MerchantListComponent,
        data: { title: 'Merchant List' },
        outlet: 'page',
      },

      {
        path: 'merchant-list-not-active',
        component: MerchantListNotActiveComponent,
        data: { title: 'Merchant List - Not Active' },
        outlet: 'page',
      },

      {
        path: 'merchant-list/:status',
        component: MerchantListComponent,
        data: { title: 'Merchant List' },
        outlet: 'page',
      },
      {
        path: 'merchant-profile/:merchantID',
        component: MerchantProfileComponent,
        data: { title: 'Merchant Profile' },
        outlet: 'page',
      },
      {
        path: 'merchant-profile/:merchantID/:settings',
        component: MerchantProfileComponent,
        data: { title: 'Merchant Profile' },
        outlet: 'page',
      },
      {
        path: 'merchant-profile/:merchantID/:settings/:itemID',
        component: MerchantProfileComponent,
        data: { title: 'Merchant Profile' },
        outlet: 'page',
      },
      {
        path: 'merchant-list-map',
        component: MerchantListMapComponent,
        data: { title: 'Merchant Map' },
        outlet: 'page',
      },
      {
        path: 'merchant-prospect-list',
        component: MerchantProspectListComponent,
        data: { title: 'Merchant Prospect List' },
        outlet: 'page',
      },
      {
        path: 'merchant-prospect-list/:status',
        component: MerchantProspectListComponent,
        data: { title: 'Merchant Prospect List' },
        outlet: 'page',
      },
      {
        path: 'merchant-prospect-profile/:merchantProspectID',
        component: MerchantProspectProfileComponent,
        data: { title: 'Merchant Prospect Profile' },
        outlet: 'page',
      },
      {
        path: 'treatment-category-list',
        component: TreatmentCategoryListComponent,
        data: { title: 'Treatment', openDialog: 'treatment-category-list' },
        outlet: 'page',
      },
      {
        path: 'treatment-category-create',
        component: TreatmentCategoryCreateEditComponent,
        data: { title: 'Create Treatment Category ' },
        outlet: 'page',
      },
      {
        path: 'treatment-category-edit/:categoryID',
        component: TreatmentCategoryCreateEditComponent,
        data: { title: 'Edit Treatment Category ' },
        outlet: 'page',
      },
      {
        path: 'treatment-type-list',
        component: TreatmentTypeListComponent,
        data: { title: 'Treatment', openDialog: 'treatment-type-list' },
        outlet: 'page',
      },
      {
        path: 'treatment-type-create',
        component: TreatmentTypeCreateEditComponent,
        data: { title: 'Create Treatment Type ' },
        outlet: 'page',
      },
      {
        path: 'treatment-type-edit/:typeID',
        component: TreatmentTypeCreateEditComponent,
        data: { title: 'Edit Treatment Type ' },
        outlet: 'page',
      },
      {
        path: 'treatment-brand-create',
        component: TreatmentBrandCreateEditComponent,
        data: { title: 'Create Treatment Brand ' },
        outlet: 'page',
      },
      {
        path: 'treatment-product-create',
        component: TreatmentProductCreateEditComponent,
        data: { title: 'Create Treatment Product ' },
        outlet: 'page',
      },
      {
        path: 'treatment-brand-edit/:brandID',
        component: TreatmentBrandCreateEditComponent,
        data: { title: 'Edit Treatment Brand ' },
        outlet: 'page',
      },
      {
        path: 'treatment-product-list',
        component: TreatmentProductListOverviewComponent,
        data: { title: 'Price List ', openDialog: 'treatment-product-list' },
        outlet: 'page',
      },
      {
        path: 'treatment-product-list-global',
        component: TreatmentProductListGlobalComponent,
        data: { title: 'Products ' },
        outlet: 'page',
      },
      {
        path: 'treatment-brand-list',
        component: TreatmentBrandListOverviewComponent,
        data: { title: 'Treatment', openDialog: 'treatment-brand-list' },
        outlet: 'page',
      },
      {
        path: 'treatment-brand-list-global',
        component: TreatmentBrandListGlobalComponent,
        data: { title: 'Treatment' },
        outlet: 'page',
      },
      {
        path: 'staff-list-overview/:merchantID',
        component: DentistListOverViewComponent,
        data: { title: 'Staff List' },
        outlet: 'page',
      },
      {
        path: 'staff-list-overview',
        component: DentistListOverViewComponent,
        data: { title: 'Staff List' },
        outlet: 'page',
      },
      {
        path: 'module-user-access/:moduleCode',
        component: MerchantAccessListComponent,
        data: { title: 'Module user access' },
        outlet: 'page',
      },

      {
        path: 'patient-portal-assistance/:merchantID',
        component: MerchantProductAssistantComponent,
        data: { title: 'Patient portal assistance' },
        outlet: 'page',
      },

      {
        path: 'patient-portal-assistance-overview',
        component: MerchantProductAssitantOverviewComponent,
        data: { title: 'Patient portal assistance' },
        outlet: 'page',
      },

      {
        path: 'user-access',
        component: UserAccessViewComponent,
        data: { title: 'Your modules' },
        outlet: 'page',
      },
      {
        path: 'staff-list-map-overview',
        component: DentistMapOverViewComponent,
        data: { title: 'Staff Map' },
        outlet: 'page',
      },
      {
        path: 'merchant-create',
        component: MerchantCreateComponent,
        data: { title: 'Merchant create' },
        outlet: 'page',
      },
      {
        path: 'merchant-invite',
        component: MerchantInviteComponent,
        data: { title: 'Merchant invite' },
        outlet: 'page',
      },
      {
        path: 'patient-profile-full',
        component: PatientProfileMerchantComponent,
        data: { title: 'Customer profile' },
        outlet: 'page',
      },

      {
        path: 'treatment-choice-advanced',
        component: TreatmentChoiceAdvancedComponent,
        outlet: 'page',
      },
      {
        path: 'contract-overview',
        component: ContractOverviewComponent,
        data: { title: 'Contract overview' },
        outlet: 'page',
      },
      {
        path: 'contract-list',
        component: ContractListComponent,
        data: { title: 'Contract list' },
        outlet: 'page',
      },
      {
        path: 'contract-list-overview',
        component: ContractListOverviewComponent,
        data: { title: 'Contract list' },
        outlet: 'page',
      },
      {
        path: 'contract-list-service',
        component: ContractListServerComponent,
        data: { title: 'Contract lookup' },
        outlet: 'page',
      },

      {
        path: 'contract-list-overview/:status',
        component: ContractListOverviewComponent,
        data: { title: 'Contract list' },
        outlet: 'page',
      },
      {
        path: 'contract-list-active',
        component: ContractListActiveComponent,
        data: { title: 'Conrtact list active' },
        outlet: 'page',
      },
      {
        path: 'contract-arrears-list',
        component: ContractArrearsListComponent,
        data: { title: 'Contract Arrears List' },
        outlet: 'page',
      },
      {
        path: 'contract-view/:contractID',
        component: ContractViewComponent,
        data: { title: 'View contract' },
        outlet: 'page',
      },
      {
        path: 'contracts-view/:customerID',
        component: ContractsViewComponent,
        data: { title: 'View Contracts' },
        outlet: 'page',
      },
      {
        path: 'contract-details/:contractID',
        component: ContractDetailComponent,
        data: { title: 'Contract Details' },
        outlet: 'page',
      },
      {
        path: 'tasks-list-overview',
        component: TasksListOverviewComponent,
        data: { title: 'Onboarding Tasks' },
        outlet: 'page',
      },
      {
        path: 'tasks-summary-list',
        component: TasksSummaryListComponent,
        data: { title: 'Onboarding Tasks' },
        outlet: 'page',
      },
      {
        path: 'tasks-create',
        component: TasksCreateComponent,
        data: { title: 'Onboarding Tasks' },
        outlet: 'page',
      },
      {
        path: 'invitation-emergency',
        component: InvitationEmergencyComponent,
        data: { title: 'Emergency Invitation' },
        outlet: 'page',
      },

      {
        path: 'marketing-over-view',
        component: MarketingOverViewComponent,
        data: { title: 'Marketing Overview' },
        outlet: 'page',
      },
      {
        path: 'tip-over-view',
        component: TipOverviewComponent,
        data: { title: 'tip Overview' },
        outlet: 'page',
      },
      {
        path: 'merchant-services-over-view',
        component: MerchantServicesOverviewComponent,
        data: { title: 'Merchant Services Overview' },
        outlet: 'page',
      },
      {
        path: 'dentistry-types-over-view',
        component: DentistryTypesOverviewComponent,
        data: { title: 'Dentistry Types Overview' },
        outlet: 'page',
      },
      {
        path: 'merchant-health-funds-over-view',
        component: MerchantHealthFundsOverviewComponent,
        data: { title: 'Health Funds Overview' },
        outlet: 'page',
      },
      {
        path: 'smile-makeover-manager',
        component: SmileMakeoverManagerComponent,
        data: { title: 'Smile Makeovers' },
        outlet: 'page',
      },
      {
        path: 'wishlist',
        component: WishlistPage,
        data: { title: 'Wishlist Management' },
        outlet: 'page',
      },
      {
        path: 'translation',
        component: TranslationPage,
        data: { title: 'Translation Management' },
        outlet: 'page',
      },
      {
        path: 'site-settings',
        component: GeneralSettingsComponent,
        data: { title: '' },
        outlet: 'page',
      },
      {
        path: 'site-settings/:features',
        component: GeneralSettingsComponent,
        data: { title: '' },
        outlet: 'page',
      },
      {
        path: 'marketing-campaign-create',
        component: MarketingCampaignCreateEditComponent,
        data: { title: 'Campaign Create' },
        outlet: 'page',
      },
      {
        path: 'marketing-campaign-create-overview',
        component: MarketingCampaignCreateEditOverviewComponent,
        data: { title: 'Campaign Create' },
        outlet: 'page',
      },

      {
        path: 'marketing-campaign-create/:templateID',
        component: MarketingCampaignCreateEditComponent,
        data: { title: 'Campaign Create' },
        outlet: 'page',
      },

      {
        path: 'marketing-campaign-edit/:campaignID',
        component: MarketingCampaignCreateEditComponent,
        data: { title: 'Campaign Edit' },
        outlet: 'page',
      },
      {
        path: 'marketing-campaign-preview',
        component: MarketingTemplateGalleryComponent,
        data: { title: 'Campaign Template Gallery' },
        outlet: 'page',
      },
      {
        path: 'marketing-social-campaign-preview',
        component: SocialMediaGalleryListComponent,
        data: { title: 'Social Campaign Gallery' },
        outlet: 'page',
      },
      {
        path: 'google-reviews',
        component: GoogleReviewListComponent,
        data: { title: 'Google Reviews' },
        outlet: 'page',
      },
      {
        path: 'template-list-overview',
        component: TemplateListOverviewComponent,
        data: { title: 'Template List' },
        outlet: 'page',
      },
      {
        path: 'template-purpose-list',
        component: TemplatePurposeListComponent,
        data: { title: 'Template Purpose List' },
        outlet: 'page',
      },
      {
        path: 'media-gallery-list',
        component: MediaGalleryTemplatesListComponent,
        data: { title: 'Template Gallery' },
        outlet: 'page',
      },
      {
        path: 'media-gallery',
        component: MediaGalleryComponent,
        data: { title: 'Media Gallery' },
        outlet: 'page',
      },
      {
        path: 'marketing-campaign-view',
        component: MarketingCampaignViewComponent,
        data: { title: 'Campaign View' },
        outlet: 'page',
      },
      {
        path: 'marketing-campaign-view/:campaignID',
        component: MarketingCampaignViewComponent,
        data: { title: 'Campaign View' },
        outlet: 'page',
      },
      {
        path: 'marketing-campaign-review',
        component: MarketingCampaignReviewComponent,
        data: { title: 'Campaign Review' },
        outlet: 'page',
      },
      {
        path: 'marketing-social-create',
        component: MarketingCampaignSocialMediaCreateComponent,
        data: { title: 'New Social Media Campaign' },
        outlet: 'page',
      },
      {
        path: 'marketing-social-create-redirection',
        component: MarketingSocialMediaRedirectionComponent,
        data: { title: 'New Social Media Campaign' },
        outlet: 'page',
      },

      {
        path: 'wakanda-files/invitation/:invitationID',
        component: WakandaFilesComponent,
        data: { title: 'Wakanda files by Invitation ID' },
        outlet: 'page',
      },
      {
        path: 'wakanda-files/wakanda/:wakandaID',
        component: WakandaFilesComponent,
        data: { title: 'Wakanda files by Wakanda ID' },
        outlet: 'page',
      },
      {
        path: 'wakanda-files/contract/:contractID',
        component: WakandaFilesComponent,
        data: { title: 'Wakanda files by Contract ID' },
        outlet: 'page',
      },
      {
        path: 'template-create',
        component: TemplateCreateEditComponent,
        data: { title: 'New Template' },
        outlet: 'page',
      },
      {
        path: 'template-edit/:templateID',
        component: TemplateCreateEditComponent,
        data: { title: 'Edit Template' },
        outlet: 'page',
      },
      {
        path: 'customer-prospect-tag-view/:tagID',
        component: CustomerProspectTagViewComponent,
        data: { title: 'Tag view' },
        outlet: 'page',
      },

      {
        path: 'campaign-message-list/:campaignID/:status',
        component: MarketingCampaignMessageListOverviewComponent,
        data: { title: 'Campaign Message List' },
        outlet: 'page',
      },

      {
        path: 'change-log',
        component: ChangeLogComponent,
        data: { title: 'Changelog' },
        outlet: 'page',
      },
      {
        path: 'media-gallery',
        component: MediaGalleryComponent,
        data: { title: 'Media Gallery' },
        outlet: 'page',
      },
      {
        path: 'template-gallery',
        component: MediaGalleryTemplatesComponent,
        data: { title: 'Template Gallery' },
        outlet: 'page',
      },
      {
        path: 'template-gallery-list',
        component: MediaGalleryTemplatesListComponent,
        data: { title: 'Template Gallery List' },
        outlet: 'page',
      },
      {
        path: 'prospect-details-card-edit/:prospectID',
        component: ProspectDetailsCardEditComponent,
        data: { title: 'Edit Details' },
        outlet: 'page',
      },
      {
        path: 'customer-details-card-edit/:customerID',
        component: CustomerDetailsCardEditComponent,
        data: { title: 'Edit Details' },
        outlet: 'page',
      },
      // {
      //   path: 'details-card-edit/customer/id',
      //   component: DetailsCardEditComponent,
      //   outlet: 'page'
      // },
      // {
      //   path: 'details-card-edit/:existingPatientID',
      //   component: DetailsCardEditComponent,
      //   data: {
      //     title: 'View/Edit Details',
      //   },
      //   outlet: 'page'
      // },
      {
        path: 'beneficiary-list',
        component: BeneficiaryListComponent,
        data: { title: 'Supplier List' },
        outlet: 'page',
      },
      {
        path: 'beneficiary-list-overview',
        component: BenficiaryListOverview,
        data: { title: 'Supplier List' },
        outlet: 'page',
      },
      {
        path: 'beneficiary',
        component: BeneficiaryPage,
        data: { title: 'Supplier List' },
        outlet: 'page',
      },
      {
        path: 'beneficiary-list-global',
        component: BeneficiaryListGlobalComponent,
        data: { title: 'Supplier List' },
        outlet: 'page',
      },
      {
        path: 'beneficiary-create',
        component: BeneficiaryCreateEditComponent,
        data: { title: 'New Supplier' },
        outlet: 'page',
      },
      {
        path: 'beneficiary-edit/:beneficiaryID',
        component: BeneficiaryCreateEditComponent,
        data: { title: 'Edit Supplier' },
        outlet: 'page',
      },
      {
        path: 'beneficiary-view/:beneficiaryID',
        component: BeneficiaryCreateEditComponent,
        data: { title: 'View Supplier' },
        outlet: 'page',
      },
      {
        path: 'subsidiary-create/:parentID/:typeCode',
        component: BeneficiaryCreateEditComponent,
        data: { title: 'New Subsidiary' },
        outlet: 'page',
      },
      {
        path: 'subsidiary-edit/:beneficiaryID',
        component: BeneficiaryCreateEditComponent,
        data: { title: 'Edit Subsidiary' },
        outlet: 'page',
      },
      {
        path: 'beneficiary-list-map',
        component: BeneficiaryListMapComponent,
        data: { title: 'Supplier Map' },
        outlet: 'page',
      },
      {
        path: 'beneficiary-catalogue-view/:supplierID',
        component: BeneficiaryCatalogueViewComponent,
        data: { title: 'View Supplier' },
        outlet: 'page',
      },

      {
        path: 'testimony-list',
        component: TestimonyListComponent,
        data: { title: 'Testimonials List' },
        outlet: 'page',
      },

      {
        path: 'ai-scans-list-overview',
        component: AiScansListOverViewComponent,
        data: { title: 'AI Scans' },
        outlet: 'page',
      },

      {
        path: 'ai-scan-report-view/:id',
        component: AiScanReportViewComponent,
        data: { title: 'AI Scan Report' },
        outlet: 'page',
      },

      {
        path: 'ai-scans-list-overview',
        component: AiScansListOverViewComponent,
        data: { title: 'AI Scans' },
        outlet: 'page',
      },

      {
        path: 'ai-scan-create',
        component: AiScanCreateComponent,
        data: { title: 'New AI scan' },
        outlet: 'page',
      },

      {
        path: 'customer-prospect-list-overview',
        component: CustomerProspectListOverViewComponent,
        data: { title: 'Patients' },
        outlet: 'page',
      },

      {
        path: 'customer-prospect-map-overview',
        component: CustomerProspectMapOverviewComponent,
        data: { title: 'Patients Map' },
        outlet: 'page',
      },
      {
        path: 'customer-prospect-list-archives',
        component: CustomerProspectListArchivesComponent,
        data: { title: 'Archived Patients' },
        outlet: 'page',
      },
      // {
      //   path: 'treatment-item-list',
      //   component: TreatmentItemListComponent,
      //   data: { title: 'Treatment Items' },
      //   outlet: 'page'
      // }
      //  experimental
      {
        path: 'quotation-create',
        component: QuotationInvitationCreateComponent,
        data: { title: 'Create new Treatment Quotation' },
        outlet: 'page',
      },
      {
        path: 'quotation-list-overview',
        component: QuotationListOverviewComponent,
        data: { title: 'Quotation List' },
        outlet: 'page',
      },
      {
        path: 'quotation-list-overview/:status',
        component: QuotationListOverviewComponent,
        data: { title: 'Applications' },
        outlet: 'page',
      },
      {
        path: 'quotation-contract-view/:quotationID',
        component: QuotationContractViewComponent,
        data: { title: 'View Contract' },
        outlet: 'page',
      },
      {
        path: 'quotation-referred-view/:quotationID',
        component: QuotationContractViewComponent,
        data: { title: 'Referred Application' },
        outlet: 'page',
      },
      {
        path: 'treatment-document-centre',
        component: TreatmentDocumentGalleryOverviewComponent,
        data: { title: 'Documents' },
        outlet: 'page',
      },
      {
        path: 'treatment-document-centre-all',
        component: beneficiaryDocumentGalleryOverviewComponent,
        data: { title: 'Documents' },
        outlet: 'page',
      },
      {
        path: 'treatment-document-create',
        component: TreatmentDocumentCreateComponent,
        data: { title: 'Documents' },
        outlet: 'page',
      },
      {
        path: 'treatment-catalogue-view',
        component: BeneficiaryCatalogueViewComponent,
        data: { title: 'View Supplier' },
        outlet: 'page',
      },
      {
        path: 'subsidiary-create',
        component: BeneficiarySubsidiaryCreateEditComponent,
        data: { title: 'New Subsidiary' },
        outlet: 'page',
      },
      {
        path: 'subsidiary-view/:beneficiaryID',
        component: BeneficiarySubsidiaryCreateEditComponent,
        data: { title: 'View Subsidiary' },
        outlet: 'page',
      },
      {
        path: 'subsidiary-edit/:beneficiaryID',
        component: BeneficiarySubsidiaryCreateEditComponent,
        data: { title: 'Edit Subsidiary' },
        outlet: 'page',
      },
      // {
      //   path: 'contact-create/:cardID/:title',
      //   component: ContactCreateEditComponent,
      //   data: { title: 'Create Contact' },
      //   outlet: 'page'
      // },
      {
        path: 'contact-create/:cardID',
        component: ContactCreateEditComponent,
        data: { title: 'Create Contact' },
        outlet: 'page',
      },
      {
        path: 'contact-edit/:contactID/:title',
        component: ContactCreateEditComponent,
        data: { title: 'Edit Contact' },
        outlet: 'page',
      },
      {
        path: 'referral-create',
        component: ReferralCreateComponent,
        data: { title: 'Create new referral' },
        outlet: 'page',
      },
      {
        path: 'referral-list',
        component: ReferralListComponent,
        data: { title: 'Referral list' },
        outlet: 'page',
      },
      {
        path: 'referral-view',
        component: ReferralViewComponent,
        data: { title: 'Referral view' },
        outlet: 'page',
      },
      {
        path: 'invitation-template-create',
        component: InvitationTemplateCreateEditComponent,
        data: { title: 'Create Invitation Template' },
        outlet: 'page',
      },
      {
        path: 'invitation-template-edit/:templateID',
        component: InvitationTemplateCreateEditComponent,
        data: { title: 'Edit Invitation Template' },
        outlet: 'page',
      },
      {
        path: 'medical-history-overview',
        component: MedicalHistoryListOverViewComponent,
        data: { title: 'Health History List' },
        outlet: 'page',
      },
      {
        path: 'customer-profile',
        component: CustomerProspectProfileComponent,
        data: { title: 'Patient details' },
        outlet: 'page',
      },
      {
        path: 'customer-profile/:patientID',
        component: CustomerProspectProfileComponent,
        data: { title: 'Patient details' },
        outlet: 'page',
      },
      {
        path: 'customer-profile/:patientID/:tabs',
        component: CustomerProspectProfileComponent,
        data: { title: 'Patient details' },
        outlet: 'page',
      },
      {
        path: 'customer-profile/:patientID/:tabs/:itemID',
        component: CustomerProspectProfileComponent,
        data: { title: 'Patient details' },
        outlet: 'page',
      },
      {
        path: 'customer-prospect-blank',
        component: CustomerProspectBlankComponent,
        outlet: 'page',
      },
      {
        path: 'activity-log-list-overview',
        component: ActivityLogListOverviewComponent,
        data: { title: 'Activities' },
        outlet: 'page',
      },
      {
        path: 'operator-list',
        component: OperatorListComponent,
        data: { title: 'Operator' },
        outlet: 'page',
      },
      {
        path: 'operator-list-overview',
        component: OperatorListOverviewComponent,
        data: { title: 'Operators' },
        outlet: 'page',
      },

      {
        path: 'appointment-list-calendar-overview',
        component: AppointmentListCalendarOverviewComponent,
        data: { title: 'Appointment Calendar' },
        outlet: 'page',
      },
      {
        path: 'appointment-list-overview',
        component: AppointmentListOverviewComponent,
        data: { title: 'Appointment list' },
        outlet: 'page',
      },
      {
        path: 'drugs-list-overview',
        component: DrugListOverviewComponent,
        data: { title: 'Medications' },
        outlet: 'page',
      },
      {
        path: 'drugs-list',
        component: DrugsListComponent,
        data: { title: 'Medications' },
        outlet: 'page',
      },
      {
        path: 'message-list-overview',
        component: MessageListOverviewComponent,
        data: { title: 'Communications' },
        outlet: 'page',
      },
      {
        path: 'template-view/:templateID',
        component: TemplateReviewComponent,
        data: { title: 'view Template' },
        outlet: 'page',
      },
      {
        path: 'template-view/tag/:templateTag',
        component: TemplateReviewComponent,
        data: { title: 'view Template' },
        outlet: 'page',
      },

      {
        path: 'marketing-social-media-template-edit/:templateRawID',
        component: TemplateMarketingCreateEditComponent,
        data: { title: 'Edit Social Media Template' },
        outlet: 'page',
      },
      {
        path: 'marketing-template-edit/:templateRawID',
        component: TemplateMarketingCreateEditComponent,
        data: { title: 'Edit Template' },
        outlet: 'page',
      },

      {
        path: 'marketing-filter-list-overview',
        component: MarketingFilterListOverViewComponent,
        data: { title: 'Marketing filter' },
        outlet: 'page',
      },

      {
        path: 'marketing-filter-view/:filterID',
        component: MarketingFilterViewComponent,
        data: { title: 'Marketing filter' },
        outlet: 'page',
      },

      {
        path: 'new-filter',
        component: MarketingFilterCreateComponent,
        data: { title: 'Create filter' },
        outlet: 'page',
      },

      {
        path: 'edit-filter/:filterID',
        component: MarketingFilterCreateComponent,
        data: { title: 'Edit filter' },
        outlet: 'page',
      },

      {
        path: 'drug-create',
        component: DrugsCreateEditComponent,
        data: { title: 'New Medication' },
        outlet: 'page',
      },
      {
        path: 'drug-view/:drugID',
        component: DrugsViewComponent,
        data: { title: 'View Medication Details' },
        outlet: 'page',
      },
      {
        path: 'drug-edit/:drugID',
        component: DrugsCreateEditComponent,
        data: { title: 'Edit Medication Details' },
        outlet: 'page',
      },
      {
        path: 'drug-list',
        component: DrugsListComponent,
        data: { title: 'Medication List' },
        outlet: 'page',
      },
      {
        path: 'audiences-settings',
        component: MarketingSettingsComponent,
        data: { title: 'Audiences Settings' },
        outlet: 'page',
      },

      {
        path: 'marketing-settings',
        component: MarketingSettingsComponent,
        data: { title: 'Audiences Settings' },
        outlet: 'page',
      },
      {
        path: 'tag-list',
        component: TagsListOverviewComponent,
        data: { title: 'Tag List' },
        outlet: 'page',
      },
      {
        path: 'tag-view/:tagID',
        component: TagsViewComponent,
        data: { title: 'Tag Details' },
        outlet: 'page',
      },

      {
        path: 'active-sessions',
        component: ActiveSessionsComponent,
        data: { title: 'Connected users' },
        outlet: 'page',
      },

      {
        path: 'connection-history',
        component: ConnectionHistoryComponent,
        data: { title: 'Connection History' },
        outlet: 'page',
      },
      {
        path: 'operator-session-overview/:operatorID',
        component: OperatorSessionOverviewComponent,
        data: { title: 'Operator Session Overview' },
        outlet: 'page',
      },
      {
        path: 'patient-card/:invitationID',
        component: MerchantMainCardComponent,
        data: { title: 'Patient Card' },
        outlet: 'page',
      },
      {
        path: 'getting-started',
        component: GettingStartedMerchantComponent,
        data: { title: 'Getting started' },
        outlet: 'page',
      },
      {
        path: 'setting-up',
        component: GettingStartedMerchantStep1Component,
        data: { title: 'Setting Up' },
        outlet: 'page',
      },
      {
        path: 'sending-treatments-plans',
        component: GettingStartedMerchantStep2Component,
        data: { title: 'Sending Treatment Plans' },
        outlet: 'page',
      },
      {
        path: 'getting-paid',
        component: GettingStartedMerchantStep3Component,
        data: { title: 'Getting Paid' },
        outlet: 'page',
      },
      {
        path: 'medical/:customerID',
        component: MedicalHistoryCreateEditComponent,
        data: { title: 'Patient health history' },
        outlet: 'page',
      },

      {
        path: 'medical',
        component: MedicalHistoryCreateEditComponent,
        data: { title: 'Patient health history' },
        outlet: 'page',
      },

      {
        path: 'medical/:customerID/step/:step',
        component: MedicalHistoryCreateEditComponent,
        data: { title: 'Patient health history' },
        outlet: 'page',
      },

      {
        path: 'medical/step/:step',
        component: MedicalHistoryCreateEditComponent,
        data: { title: 'Patient health history' },
        outlet: 'page',
      },

      {
        path: 'getting-started-practice-manager',
        component: GettingStartedPracticeManagerComponent,
        data: { title: 'Getting Started: Practice manager' },
        outlet: 'page',
      },
      {
        path: 'getting-started-marketing',
        component: GettingStartedMarketingComponent,
        data: { title: 'Getting Started: Marketing' },
        outlet: 'page',
      },
      {
        path: 'getting-started-home',
        component: GettingStartedHomeComponent,
        data: { title: 'Getting Started: Home' },
        outlet: 'page',
      },
      {
        path: 'getting-started-dental',
        component: GettingStartedDentalComponent,
        data: { title: 'Getting Started: Dental' },
        outlet: 'page',
      },
      {
        path: 'getting-started-coordinator',
        component: GettingStartedCoordinatorComponent,
        data: { title: 'Getting Started: Coordinator' },
        outlet: 'page',
      },

      {
        path: 'social-media-template-list',
        component: SocialMediaListComponent,
        data: { title: 'Social Media templates' },
        outlet: 'page',
      },

      {
        path: 'template-social-media-create',
        component: SocialMediaCreateEditComponent,
        data: { title: 'Create social media template' },
        outlet: 'page',
      },

      {
        path: 'template-social-media-edit/:socialMediaID',
        component: SocialMediaCreateEditComponent,
        data: { title: 'Edit social media template' },
        outlet: 'page',
      },
      {
        path: 'landing-redirect/:target',
        component: MerchantVisitorComponent,
        data: { title: 'Preview your landing page' },
        outlet: 'page',
      },

      {
        path: 'landing/:target',
        component: MerchantVisitorComponent,
        data: { title: 'Preview your landing page' },
        outlet: 'page',
      },
      {
        path: 'module-list',
        component: ModuleListComponent,
        data: { title: 'Modules list' },
        outlet: 'page',
      },
      {
        path: 'module-list/:featureType',
        component: ModuleListComponent,
        data: { title: 'Modules list' },
        outlet: 'page',
      },

      {
        path: 'module-edit/:moduleID',
        component: ModuleEditCreateComponent,
        data: { title: 'Edit Module' },
        outlet: 'page',
      },
      {
        path: 'main-feature-edit-create/:mainFeatureLabelID',
        component: FeatureMainLabelCreateEditComponent,
        data: { title: 'Edit Main Feature' },
        outlet: 'page',
      },
      {
        path: 'main-feature-edit-create',
        component: FeatureMainLabelCreateEditComponent,
        data: { title: 'Create new Main Feature' },
        outlet: 'page',
      },

      {
        path: 'main-feature-list/:featureType',
        component: FeatureMainLabelListComponent,
        data: { title: 'Features list' },
        outlet: 'page',
      },

      {
        path: 'main-feature-list',
        component: FeatureMainLabelListComponent,
        data: { title: 'Features list' },
        outlet: 'page',
      },
      {
        path: 'subscription-plan-create-edit',
        component: SubscriptionPlanCreateEditComponent,
        data: { title: 'Create Subscription Plan' },
        outlet: 'page',
      },
      {
        path: 'subscription-plan-create-edit/:subscriptionPlanID',
        component: SubscriptionPlanCreateEditComponent,
        data: { title: 'Edit Subscription Plan' },
        outlet: 'page',
      },

      {
        path: 'subscription-plan-list-card',
        component: SubscriptionPlanCardListComponent,
        data: { title: 'Pricing' },
        outlet: 'page',
      },

      {
        path: 'subscription-plan-list-card/:featureType',
        component: SubscriptionPlanCardListComponent,
        data: { title: 'Pricing' },
        outlet: 'page',
      },
      {
        path: 'subscription-plan-view-current/:subscriptionPlanID',
        component: SubscriptionPlanViewComponent,
        data: { title: 'My Subscription' },
        outlet: 'page',
      },
      {
        path: 'subscription-plan-view-current/:subscriptionPlanID/:featureType',
        component: SubscriptionPlanViewComponent,
        data: { title: 'My Subscription' },
        outlet: 'page',
      },
      {
        path: 'subscription-plan-view-current/:subscriptionPlanID/:ids/:featureType',
        component: SubscriptionPlanViewComponent,
        data: { title: 'My Subscription' },
        outlet: 'page',
      },
      {
        path: 'subscription-plan-view/:subscriptionPlanID/:featureType',
        component: SubscriptionPlanViewComponent,
        data: { title: 'Subscription plan details' },
        outlet: 'page',
      },
      {
        path: 'subscription-plan-view/:subscriptionPlanID/:ids/:featureType',
        component: SubscriptionPlanViewComponent,
        data: { title: 'Subscription plan details' },
        outlet: 'page',
      },
      {
        path: 'subscription-plan-management',
        component: SubscriptionPlanCardListGlobalComponent,
        data: { title: 'Subscription plans Management' },
        outlet: 'page',
      },
      {
        path: 'subscription-plan-management',
        component: SubscriptionPlanCardListGlobalComponent,
        data: { title: 'Subscription plans Management' },
        outlet: 'page',
      },
      {
        path: 'subscription-plan-management/:type',
        component: SubscriptionPlanCardListGlobalComponent,
        data: { title: 'Subscription plans Management' },
        outlet: 'page',
      },
      {
        path: 'main-feature-view-code/:mainCode',
        component: FeatureSubLabelListComponent,
        data: { title: 'Feature' },
        outlet: 'page',
      },

      {
        path: 'main-feature-view-code/:mainCode/:featureType',
        component: FeatureSubLabelListComponent,
        data: { title: 'Feature' },
        outlet: 'page',
      },
      {
        path: 'main-feature-view/:mainID',
        component: FeatureSubLabelListComponent,
        data: { title: 'Feature' },
        outlet: 'page',
      },

      {
        path: 'main-feature-view/:mainID/:featureType',
        component: FeatureSubLabelListComponent,
        data: { title: 'Feature' },
        outlet: 'page',
      },
      {
        path: 'subscription-plan-overview-all',
        component: SubscriptionPlanListOverviewComponent,
        data: { title: 'Subscription plans Overview' },
        outlet: 'page',
      },
      {
        path: 'subscription-plan-overview-all/:featureType',
        component: SubscriptionPlanListOverviewComponent,
        data: { title: 'Subscription plans Overview' },
        outlet: 'page',
      },
      {
        path: 'subscription-plan-overview/:ids',
        component: SubscriptionPlanListOverviewComponent,
        data: { title: 'Subscription plans Overview' },
        outlet: 'page',
      },
      {
        path: 'subscription-plan-overview/:ids/:featureType',
        component: SubscriptionPlanListOverviewComponent,
        data: { title: 'Subscription plans Overview' },
        outlet: 'page',
      },
      {
        path: 'subscription-plan-users',
        component: SubscriptionPlanUserListComponent,
        data: { title: 'User subscriptions' },
        outlet: 'page',
      },

      {
        path: 'informed-consent-treatment-presented',
        component: InformedConsentTreatmentPresentedPage,
        data: {
          title: 'Treatment Presented',
        },
        outlet: 'page',
      },

      {
        path: 'subscription-plan-users/:planID',
        component: SubscriptionPlanUserListComponent,
        data: { title: 'User subscriptions' },
        outlet: 'page',
      },
      {
        path: 'sub-feature-edit-create/:mainFeatureLabelID',
        component: FeatureSubLabelCreateEditComponent,
        data: { title: 'Create sub feature' },
        outlet: 'page',
      },

      {
        path: 'sub-feature-edit-create/:mainFeatureLabelID/:subFeatureLabelID',
        component: FeatureSubLabelCreateEditComponent,
        data: { title: 'Edit sub feature' },
        outlet: 'page',
      },

      {
        path: 'sub-feature-edit-create-redirect/:mainFeatureLabelID',
        component: FeatureSubLabelCreateRedirectComponent,
        data: { title: 'Create sub feature' },
        outlet: 'page',
      },

      {
        path: 'main-view-redirect/:mainViewID/:featureType',
        component: FeatureSubLabelCreateRedirectComponent,
        data: { title: 'Feature' },
        outlet: 'page',
      },

      {
        path: 'main-view-redirect/:mainViewID',
        component: FeatureSubLabelCreateRedirectComponent,
        data: { title: 'Feature' },
        outlet: 'page',
      },
      {
        path: 'main-sub-redirect/:subViewID/:featureType',
        component: FeatureSubLabelCreateRedirectComponent,
        data: { title: 'Feature' },
        outlet: 'page',
      },
      {
        path: 'sub-feature-code/:subFeatureCode/:featureType',
        component: FeatureSubLabelViewComponent,
        data: { title: '' },
        outlet: 'page',
      },

      {
        path: 'sub-feature/:subFeatureID/:featureType',
        component: FeatureSubLabelViewComponent,
        data: { title: '' },
        outlet: 'page',
      },

      {
        path: 'third-party-client-list',
        component: ThirdPartyClientListComponent,
        data: { title: 'Third-Party Application List' },
        outlet: 'page',
      },

      {
        path: 'third-party-access-list',
        component: ThirdPartyAccessListComponent,
        data: { title: 'API Key List' },
        outlet: 'page',
      },

      {
        path: 'third-party-access-client-list',
        component: ThirdPartyAccessClientOverviewComponent,
        data: { title: 'Third-Party List' },
        outlet: 'page',
      },

      {
        path: 'third-party-access-client-list/:templateID',
        component: ThirdPartyAccessClientOverviewComponent,
        data: { title: 'Third-Party List' },
        outlet: 'page',
      },

      {
        path: 'third-party-access-client-list/:templateID/:type',
        component: ThirdPartyAccessClientOverviewComponent,
        data: { title: 'Third-Party List' },
        outlet: 'page',
      },

      {
        path: 'third-party-access-list-overview',
        component: ThirdPartyAccessListOverviewComponent,
        data: { title: 'API Keys Overview' },
        outlet: 'page',
      },

      {
        path: 'third-party-client-create-edit',
        component: ThirdPartyClientCreateEditComponent,
        data: { title: 'Create Third-Party Application' },
        outlet: 'page',
      },

      {
        path: 'third-party-client-create-edit/:thirdPartyClientID',
        component: ThirdPartyClientCreateEditComponent,
        data: { title: 'Edit Third-Party Application' },
        outlet: 'page',
      },
      {
        path: 'EditorComponentLookupComponent',
        component: EditorComponentLookupComponent,
        data: { title: 'Editor Component Lookup' },
        outlet: 'page',
      },
      {
        path: 'editor-component-create',
        component: EditorComponentCreateEditComponent,
        data: { title: 'Create Editor Component' },
        outlet: 'page',
      },
      {
        path: 'editor-component-create-redirect',
        component: EditorComponentRedirectionComponent,
        data: { title: 'Create Editor Component' },
        outlet: 'page',
      },

      {
        path: 'editor-component-edit/:editorComponentID',
        component: EditorComponentCreateEditComponent,
        data: { title: 'Edit Editor Component' },
        outlet: 'page',
      },

      {
        path: 'editor-component-list-default',
        component: EditorComponentListDefaultComponent,
        data: { title: 'Editor Component Default Templates' },
        outlet: 'page',
      },
      {
        path: 'editor-component-list',
        component: EditorComponentListComponent,
        data: { title: 'Editor Component  Template List' },
        outlet: 'page',
      },

      {
        path: 'editor-component-list-overview',
        component: EditorComponentListOverviewComponent,
        data: { title: 'Editor Component List' },
        outlet: 'page',
      },
      {
        path: 'editor-component-view/:editorComponentID',
        component: EditorComponentViewComponent,
        data: { title: 'View Editor Component ' },
        outlet: 'page',
      },
      {
        path: 'task-manager-list',
        component: TaskManagerListComponent,
        data: { title: 'Merchant Task Manager' },
        outlet: 'page',
      },
      {
        path: 'document-link-list',
        component: DocumentListThumbnailComponent,
        data: { title: 'Landing Pages' },
        outlet: 'page',
      },
      {
        path: 'document-link-list/:isDefault',
        component: DocumentListThumbnailComponent,
        data: { title: 'Landing Pages' },
        outlet: 'page',
      },

      {
        path: 'quick-invitation',
        component: QuickInvitationComponent,
        data: { title: 'Patient Application' },
        outlet: 'page',
      },

      {
        path: 'quick-invitation/:productGroup',
        component: QuickInvitationComponent,
        data: { title: 'Patient Application' },
        outlet: 'page',
      },

      {
        path: 'quick-invitation-club/:productGroup',
        component: QuickInvitationComponent,
        data: { title: 'Patient Application' },
        outlet: 'page',
      },
      {
        path: 'quick-invitation-club/:productGroup',
        component: QuickInvitationComponent,
        data: { title: 'Patient Application' },
        outlet: 'page',
      },

      {
        path: 'template-default-message',
        component: DefaultMessageListOverviewComponent,
        data: { title: 'Message Template' },
        outlet: 'page',
      },

      {
        path: 'template-default-message/:messageMode',
        component: DefaultMessageListOverviewComponent,
        data: { title: 'Message Template' },
        outlet: 'page',
      },
      {
        path: 'instant-offer-list',
        component: InstantOfferListComponent,
        data: { title: 'Offers For Finance' },
        outlet: 'page',
      },

      {
        path: 'instant-offer-list-overview',
        component: InstantOfferListOverviewComponent,
        data: { title: 'Offers For Finance' },
        outlet: 'page',
      },

      {
        path: 'instant-offer-settings',
        component: InstantOfferSettingsComponent,
        data: { title: 'Instant Finance Offer Settings' },
        outlet: 'page',
      },

      {
        path: 'instant-offer-view/:instantOfferID',
        component: InstantOfferViewComponent,
        data: { title: 'View Instant Finance Offer' },
        outlet: 'page',
      },

      {
        path: 'finance-offer-view/:instantOfferID',
        component: InstantOfferViewComponent,
        data: { title: 'View Instant Finance Offer' },
        outlet: 'page',
      },
      {
        path: 'landing-page-overview/:landingPageID',
        component: DocumentLinkDetailedViewComponent,
        data: { title: 'Landing Page Overview' },
        outlet: 'page',
      },
      {
        path: 'landing-page-overview',
        component: DocumentLinkDetailedViewComponent,
        data: { title: 'Landing Page Overview' },
        outlet: 'page',
      },

      {
        path: 'landing-page-overview-redirect/:landingPageID',
        component: DocumentLinkDetailedViewRedirectionComponent,
        data: { title: 'Landing Page Overview' },
        outlet: 'page',
      },
      {
        path: 'landing-page-overview-redirect',
        component: DocumentLinkDetailedViewRedirectionComponent,
        data: { title: 'Landing Page Overview' },
        outlet: 'page',
      },
      {
        path: 'message-template',
        component: DefaultMessageListOverviewComponent,
        data: { title: 'Personal Messages' },
        outlet: 'page',
      },

      {
        path: 'message-template/:type',
        component: DefaultMessageListOverviewComponent,
        data: { title: 'Personal Messages' },
        outlet: 'page',
      },


      {
        path: 'informed-consent-template',
        component: MerchantInformedConsentComponent,
        data: { title: 'Informed Consent Template' },
        outlet: 'page',
      },


      {
        path: 'note-template',
        component: NotesSettingsPage,
        data: { title: 'Note Templates' },
        outlet: 'page',
      },


      {
        path: 'sms-template',
        component: DefaultSmsSettingsComponent,
        data: { title: 'sms Templates' },
        outlet: 'page',
      },

      {
        path: 'instant-offer-settings-overview',
        component: InstantOfferSettingsOverviewComponent,
        data: { title: 'Instant Offer Settings' },
        outlet: 'page',
      },
      {
        path: 'self-service-landing-page',
        component: SelfServiceLandingPageComponent,
        data: { title: 'Landing Page' },
        outlet: 'page',
      },


      {
        path: 'landing-page-create',
        component: DocumentLinkCreateComponent,
        data: { title: 'Landing Page Template' },
        outlet: 'page',
      },
      {
        path: 'landing-page-create/:type',
        component: DocumentLinkCreateComponent,
        data: { title: 'Landing Page Template' },
        outlet: 'page',
      },
      {
        path: 'landing-page-create2/:type/:addToStore',
        component: DocumentLinkCreateComponent,
        data: { title: 'Landing Page Template' },
        outlet: 'page',
      },
      {
        path: 'landing-page-create/:type/:merchantID',
        component: DocumentLinkCreateComponent,
        data: { title: 'Landing Page Template' },
        outlet: 'page',
      },
      {
        path: 'landing-page-create/:type/:merchantID/:addToStore',
        component: DocumentLinkCreateComponent,
        data: { title: 'Landing Page Template' },
        outlet: 'page',
      },

      {
        path: 'landing-page-edit/:linkID',
        component: DocumentLinkCreateComponent,
        data: { title: 'Landing Page Template' },
        outlet: 'page',
      },
      {
        path: 'landing-page-edit2/:linkID/:addToStore',
        component: DocumentLinkCreateComponent,
        data: { title: 'Landing Page Template' },
        outlet: 'page',
      },
      {
        path: 'landing-page-edit/:linkID/:merchantID',
        component: DocumentLinkCreateComponent,
        data: { title: 'Landing Page Template' },
        outlet: 'page',
      },
      {
        path: 'landing-page-edit/:linkID/:merchantID/:addToStore',
        component: DocumentLinkCreateComponent,
        data: { title: 'Landing Page Template' },
        outlet: 'page',
      },


      {
        path: 'landing-page-clone/:cloneID',
        component: DocumentLinkCreateComponent,
        data: { title: 'Landing Page Template' },
        outlet: 'page',
      },
      {
        path: 'landing-page-clone/:cloneID/:addToStore',
        component: DocumentLinkCreateComponent,
        data: { title: 'Landing Page Template' },
        outlet: 'page',
      },
      {
        path: 'landing-page-clone/:cloneID/:merchantID',
        component: DocumentLinkCreateComponent,
        data: { title: 'Landing Page Template' },
        outlet: 'page',
      },
      {
        path: 'landing-page-clone/:cloneID/:merchantID/:addToStore',
        component: DocumentLinkCreateComponent,
        data: { title: 'Landing Page Template' },
        outlet: 'page',
      },


      {
        path: 'staff-portal',
        component: StaffPortalComponent,
        data: { title: 'Staff Portal' },
        outlet: 'page',
      },

      {
        path: 'sms-conversation-promoter',
        component: SmsConversationComponent,
        data: { title: 'SMS Conversation' },
        outlet: 'page',
      },
      {
        path: 'sms-conversation-personal/:messageType',
        component: SmsConversationComponent,
        data: { title: 'SMS Conversation' },
        outlet: 'page',
      },
      {
        path: 'sms-conversation-promoter/:data',
        component: SmsConversationComponent,
        data: { title: 'SMS Conversation' },
        outlet: 'page',
      },
      {
        path: 'sms-conversation-promoter-type/:type',
        component: SmsConversationComponent,
        data: { title: 'SMS Conversation' },
        outlet: 'page',
      },

      {
        path: 'sms-conversation-promoter-type/:type/:data',
        component: SmsConversationComponent,
        data: { title: 'SMS Conversation' },
        outlet: 'page',
      },

      {
        path: 'sms-conversation',
        component: SmsConversationComponent,
        data: { title: 'SMS Conversation' },
        outlet: 'page',
      },
      {
        path: 'sms-conversation/:data',
        component: SmsConversationComponent,
        data: { title: 'SMS Conversation' },
        outlet: 'page',
      },
      {
        path: 'sms-conversation-type/:type',
        component: SmsConversationComponent,
        data: { title: 'Staff - SMS Conversation' },
        outlet: 'page',
      },
      {
        path: 'customer-list-server',
        component: CustomerProspectListServerComponent,
        data: { title: 'Customer List' },
        outlet: 'page',
      },



      {
        path: 'theme',
        component: AdminThemeComponent,
        data: { title: 'Theme' },
        outlet: 'page',
      },
      {
        path: 'password-reset-required',
        component: PasswordResetEmptyPageComponent,
        data: { title: '' },
        outlet: 'page',
      },
      {
        path: 'message-group-create',
        component: MessageGroupCreateComponent,
        data: { title: '' },
        outlet: 'page',
      },
      {
        path: 'message-group-create-new',
        component: MessageGroupCreateComponent,
        data: { title: '' },
        outlet: 'page',
      },
      {
        path: 'message-group-draft/:messageGroupDraftID',
        component: MessageGroupCreateComponent,
        data: { title: '' },
        outlet: 'page',
      },
      {
        path: 'message-group-clone/:messageGroupCloneID',
        component: MessageGroupCreateComponent,
        data: { title: '' },
        outlet: 'page',
      },
      {
        path: 'message-group-view',
        component: MessageGroupViewComponent,
        data: { title: '' },
        outlet: 'page',
      },
      {
        path: 'message-group-view/:messageGroupID',
        component: MessageGroupViewComponent,
        data: { title: '' },
        outlet: 'page',
      },
      {
        path: 'message-list-group',
        component: MessageGroupListComponent,
        data: { title: '' },
        outlet: 'page',
      },
      {
        path: 'message-list-group-overview',
        component: MessageGroupListOverviewComponent,
        data: { title: '' },
        outlet: 'page',
      },

      {
        path: 'DocumentLinkListOverviewComponent',
        component: DocumentLinkListOverviewComponent,
        data: { title: '' },
        outlet: 'page',
      },


      {
        path: 'message-group-list-overview',
        component: MessageGroupListOverviewComponent,
        data: { title: 'Bulk SMSs Overview' },
        outlet: 'page',
      },


      {
        path: 'message-group-list-overview-email',
        component: MessageGroupListOverviewComponent,
        data: { title: 'Bulk Emails Overview' },
        outlet: 'page',
      },
      {
        path: 'contract-overview-details/:contractID',
        component: ContractOverviewViewComponent,
        data: { title: 'Contract overview' },
        outlet: 'page',
      },
      {
        path: 'contract-overview-details/:contractID/:tab',
        component: ContractOverviewViewComponent,
        data: { title: 'Contract overview' },
        outlet: 'page',
      },
      {
        path: 'PatientPersonalSMSComponent/:contractID',
        component: PatientPersonalSMSComponent,
        data: { title: 'Contract overview' },
        outlet: 'page',
      },


    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppMerchantRoutingModule { }
