<ipv-container-modal>
  <div banner>
    <div class="flex" style="justify-content: space-between">
      <div class="flex full-width">
        <div style="display: flex; flex-direction: column">
          <h2 class="summary-header rm-m heading-1">{{'View Serialised Product' | titlecase}}</h2>
          <div class="subLabel" style="padding-left: 10px" *ngIf="productCustomer['SerialNumber']">
            Serial Number: {{productCustomer['SerialNumber']}}
          </div>
        </div>
      </div>

      <button class="btn-close btn-clear mat-button white" (click)="close()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>

  <div content>
    <ng-container [ngSwitch]="industryType">
      <ipv-retail-view-customer-product
        *ngSwitchCase="'retail'"
        [productCustomer]="productCustomer"
      ></ipv-retail-view-customer-product>
      <ipv-retail-view-customer-product
        *ngSwitchCase="'breeze'"
        [productCustomer]="productCustomer"
      ></ipv-retail-view-customer-product>
      <ipv-dental-view-customer-product
        *ngSwitchCase="'dental'"
        [productCustomer]="productCustomer"
      ></ipv-dental-view-customer-product>
    </ng-container>
  </div>
</ipv-container-modal>
