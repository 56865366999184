/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dynamic-product-invoice-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./components/retail-product-invoice-list/retail-product-invoice-list.component.ngfactory";
import * as i3 from "./components/retail-product-invoice-list/retail-product-invoice-list.component";
import * as i4 from "../shared/services/product-invoice.service";
import * as i5 from "@angular/material/dialog";
import * as i6 from "../../../core/app-state/app-state.service";
import * as i7 from "./components/dental-product-invoice-list/dental-product-invoice-list.component.ngfactory";
import * as i8 from "../../../shared/pipes/binary-bool/binary-bool.pipe";
import * as i9 from "./components/dental-product-invoice-list/dental-product-invoice-list.component";
import * as i10 from "../../treatment/shared/treatment.service";
import * as i11 from "@angular/common";
import * as i12 from "./dynamic-product-invoice-list.component";
var styles_DynamicProductInvoiceListComponent = [i0.styles];
var RenderType_DynamicProductInvoiceListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DynamicProductInvoiceListComponent, data: {} });
export { RenderType_DynamicProductInvoiceListComponent as RenderType_DynamicProductInvoiceListComponent };
function View_DynamicProductInvoiceListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-retail-product-invoice-list", [["class", "list-filter-card clearfix full-width"]], null, null, null, i2.View_RetailProductInvoiceListComponent_0, i2.RenderType_RetailProductInvoiceListComponent)), i1.ɵdid(1, 49152, null, 0, i3.RetailProductInvoiceListComponent, [i4.ProductInvoiceService, i5.MatDialog, i6.AppStateService], null, null)], null, null); }
function View_DynamicProductInvoiceListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-retail-product-invoice-list", [["class", "list-filter-card clearfix full-width"]], null, null, null, i2.View_RetailProductInvoiceListComponent_0, i2.RenderType_RetailProductInvoiceListComponent)), i1.ɵdid(1, 49152, null, 0, i3.RetailProductInvoiceListComponent, [i4.ProductInvoiceService, i5.MatDialog, i6.AppStateService], null, null)], null, null); }
function View_DynamicProductInvoiceListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ipv-dental-product-invoice-list", [["class", "list-filter-card clearfix full-width"]], null, null, null, i7.View_DentalProductInvoiceListComponent_0, i7.RenderType_DentalProductInvoiceListComponent)), i1.ɵprd(512, null, i8.BinaryBoolPipe, i8.BinaryBoolPipe, []), i1.ɵdid(2, 49152, null, 0, i9.DentalProductInvoiceListComponent, [i10.TreatmentService, i5.MatDialog, i6.AppStateService, i8.BinaryBoolPipe], null, null)], null, null); }
function View_DynamicProductInvoiceListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, null, null, null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i11.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicProductInvoiceListComponent_2)), i1.ɵdid(4, 278528, null, 0, i11.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i11.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicProductInvoiceListComponent_3)), i1.ɵdid(6, 278528, null, 0, i11.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i11.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicProductInvoiceListComponent_4)), i1.ɵdid(8, 278528, null, 0, i11.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i11.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 2, 0, currVal_0); var currVal_1 = "breeze"; _ck(_v, 4, 0, currVal_1); var currVal_2 = "retail"; _ck(_v, 6, 0, currVal_2); var currVal_3 = "dental"; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_DynamicProductInvoiceListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_DynamicProductInvoiceListComponent_1)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i11.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.industryType$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DynamicProductInvoiceListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-dynamic-product-invoice-list", [], null, null, null, View_DynamicProductInvoiceListComponent_0, RenderType_DynamicProductInvoiceListComponent)), i1.ɵdid(1, 49152, null, 0, i12.DynamicProductInvoiceListComponent, [i6.AppStateService], null, null)], null, null); }
var DynamicProductInvoiceListComponentNgFactory = i1.ɵccf("ipv-dynamic-product-invoice-list", i12.DynamicProductInvoiceListComponent, View_DynamicProductInvoiceListComponent_Host_0, { pageSize: "pageSize" }, {}, []);
export { DynamicProductInvoiceListComponentNgFactory as DynamicProductInvoiceListComponentNgFactory };
