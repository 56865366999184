<div class="masonry" *ngIf="contract">
  <div class="item full-width">
    <mat-card class="clearfix">
      <div class="colour-stripe"></div>

      <mat-card-header>
        <mat-card-title>
          <h3 class="sr-title sm-h3 rm-m">Funding History</h3>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <app-empty-list-message></app-empty-list-message>
      </mat-card-content>
    </mat-card>
  </div>
</div>
