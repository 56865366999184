import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-help-topics-contents',
  templateUrl: './help-topics-contents.component.html',
  styleUrls: ['./help-topics-contents.component.css'],
})
export class HelpTopicsContentsComponent implements OnInit {
  @Input()
  tutorialHeader;

  category = [
    {
      name: 'Dashboard',
      labels: [
        {
          name: 'Dashboard Overview',
          pages: ['Dashboard Filter', 'Dashboard Chart'],
          isOpen: false,
        },
      ],
      isOpen: false,
    },
    {
      name: 'Invitations',
      labels: [
        {
          name: 'Invitations Overview',
          pages: ['Invitations Overview Charts', 'Invitations Overview Figures'],
          isOpen: false,
        },
        {
          name: 'Invitations List',
          pages: ['Invitations List Filter', 'Invitations List Details'],
          isOpen: false,
        },
      ],
      isOpen: false,
    },
    {
      name: 'Contract',
      labels: [
        {
          name: 'Contract Overview',
          pages: ['Contract Overview Filter'],
          isOpen: false,
        },
        {
          name: 'Contract List',
          pages: ['Contract List Filter', 'Contract List Details'],
          isOpen: false,
        },
      ],
      isOpen: false,
    },
    {
      name: 'Memberships',
      labels: [
        {
          name: 'Memberships Overview',
          pages: ['Memberships Overview Details'],
          isOpen: false,
        },
      ],
      isOpen: false,
    },
    {
      name: 'Settlement',
      labels: [
        {
          name: 'Settlement Overview',
          pages: ['Settlement Overview Description', 'Make new Settlement'],
          isOpen: false,
        },
        {
          name: 'Settlement List',
          pages: ['Settlement List Details', 'Settlement Quick View', 'Contrats Details', 'Make New Settlements'],
          isOpen: false,
        },
      ],
      isOpen: false,
    },
    {
      name: 'Patients',
      labels: [
        {
          name: 'Patients List',
          pages: ['Types of Patients', 'Patients List Details'],
          isOpen: false,
        },
      ],
      isOpen: false,
    },
    {
      name: 'Marketing',
      labels: [
        {
          name: 'Marketing Overview',
          pages: ['Marketing Overview Details', 'Marketing List', 'Create New Marketing'],
          isOpen: false,
        },
      ],
      isOpen: false,
    },
  ];

  showPage = false;

  @Output()
  close = new EventEmitter();

  tutorial;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.tutorialHeader = data;
    }
  }

  ngOnInit() {
    // this.tutorial = new Tutorials(this.tutorialHeader)
  }

  showPageContent(e) {
    this.showPage = e;
  }

  cancelPageContent() {
    this.showPage = false;
  }

  closeModal() {
    this.close.emit(true);
  }
}
