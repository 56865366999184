<mat-card class="rel supplier-card clearfix mb" *ngIf="thirdPartyClient" @ngIfAnimation>
  <div class="card-header primary-gradient-img clearfix stacked">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <app-third-party-client-picture class="profile-picture-view" [thirdPartyClientID]="thirdPartyClientID">
        </app-third-party-client-picture>
        <h2 class="summary-header rm-m">
          {{ thirdPartyClient['Label'] }}
        </h2>
        <p class="clearfix small rm-mb" *ngIf="sessionType == 'promoter' || sessionType == 'admin'">
          <span
            [ngClass]="{
              'chip-enable': thirdPartyClient.Is_Active === '1',
              'chip-disable': !thirdPartyClient.Is_Active === '0'
            }"
          >{{ thirdPartyClient.Is_Active == '0' ? 'Not Active' : 'Active' }}</span
          >
        </p>
      </div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="full-width flex">
      <div
        class="full-width text-center secret-container mb"
        *ngIf="thirdPartyClient['Secrect_ID'] || thirdPartyClient['Secrect_Key']"
      >
        <div class="full-width text-left smb" *ngIf="thirdPartyClient['Secrect_ID']">
          <p class="full-width text-left rm-m">
            <button
              class="pull-right"
              (click)="copy(thirdPartyClient['Secrect_ID'])"
              mat-button
              mat-icon-button

            >
              <mat-icon>file_copy</mat-icon>
            </button>

            <strong>Secret ID: </strong> {{ thirdPartyClient['Secrect_ID'] }}
          </p>
        </div>
        <div class="full-width text-left" *ngIf="thirdPartyClient['Secrect_Key']">
          <p class="full-width text-left rm-m">
            <button
              class="pull-right"
              (click)="copy(thirdPartyClient['Secrect_Key'])"
              mat-button
              mat-icon-button

            >
              <mat-icon>file_copy</mat-icon>
            </button>

            <strong>Secret Key: </strong> {{ thirdPartyClient['Secrect_Key'] }}
          </p>
        </div>
      </div>
    </div>

    <div class="full-width mb clearfix row">
      <div
        class="row full-width clearfix details flex"
        *ngIf="thirdPartyClient['Template_Label'] && thirdPartyClient['Template_Key']"
      >
        <mat-icon class="ico">brush</mat-icon>
        <div class="row-content">
          <p class="rm-mb rm-mt">
            {{ thirdPartyClient['Template_Label'] }}
            <button
              class="ml smr pull-right btn"
              *ngIf="isViewTemplate == true"
              (click)="viewTemplate(thirdPartyClient['Template_Key'])"
              style="height: 20px; width: 20px"
              mat-button
              mat-icon-button

            >
              <mat-icon>visibility</mat-icon>
            </button>
          </p>
          <p class="small">Default Template</p>
        </div>
      </div>

      <div class="row full-width clearfix details flex" *ngIf="thirdPartyClient['Description']">
        <mat-icon class="ico">description</mat-icon>
        <div class="row-content">
          <p class="rm-mb rm-mt small">{{ thirdPartyClient['Description'] }}</p>
          <p class="small">Description</p>
        </div>
      </div>

      <div class="row full-width clearfix details flex" *ngIf="thirdPartyClient['Email']">
        <mat-icon class="ico">email</mat-icon>
        <div class="row-content">
          <p class="rm-mb rm-mt">{{ thirdPartyClient['Email'] }}</p>
          <p class="small">Email</p>
        </div>
      </div>
      <div class="full-width row clearfix details flex" *ngIf="thirdPartyClient['Phone']">
        <mat-icon class="ico">phone</mat-icon>
        <div class="row-content clearfix">
          <p class="rm-mb rm-mt">
            {{ thirdPartyClient['Phone'] | customPhone: 'landLine' }}
          </p>
          <p class="small">Phone</p>
        </div>
      </div>

      <div class="full-width row clearfix details flex" *ngIf="thirdPartyClient['Address.Calculated']">
        <mat-icon class="ico">location_on</mat-icon>
        <div class="row-content clearfix">
          <p class="rm-mb rm-mt">
            {{ thirdPartyClient['Address.Calculated'] }}
          </p>
          <p class="small">Address</p>
        </div>
      </div>

      <hr/>

      <div
        class="full-width text-center mb"
        *ngIf="
          thirdPartyClient['Website'] ||
          thirdPartyClient['Facebook'] ||
          thirdPartyClient['Instagram'] ||
          thirdPartyClient['Twitter'] ||
          thirdPartyClient['LinkedIn'] ||
          thirdPartyClient['Pinterest'] ||
          thirdPartyClient['Tumblr'] ||
          thirdPartyClient['Vimeo'] ||
          thirdPartyClient['YouTube']
        "
      >
        <button
          class="connect btn-small smr"
          *ngIf="thirdPartyClient['Website']"
          (click)="openLink(thirdPartyClient['Website'])"
          mat-mini-fab
          color="accent"
          matTooltip="Website"
        >
          <mat-icon class="fas fa-globe"></mat-icon>
        </button>

        <button
          class="connect btn-small smr"
          *ngIf="thirdPartyClient['Facebook']"
          (click)="openLink(thirdPartyClient['Facebook'])"
          mat-mini-fab
          color="accent"
          matTooltip="Facebook"
        >
          <mat-icon class="fab fa-facebook-f" style="color: #4267b2 !important"></mat-icon>
        </button>

        <button
          class="connect btn-small smr"
          *ngIf="thirdPartyClient['Instagram']"
          (click)="openLink(thirdPartyClient['Instagram'])"
          mat-mini-fab
          color="accent"
          matTooltip="Instagram"
        >
          <mat-icon class="fab fa-instagram" style="color: #e1306c !important"></mat-icon>
        </button>

        <button
          class="connect btn-small smr"
          *ngIf="thirdPartyClient['Twitter']"
          (click)="openLink(thirdPartyClient['Twitter'])"
          mat-mini-fab
          color="accent"
          matTooltip="Twitter"
        >
          <mat-icon class="fab fa-twitter" style="color: #00acee !important"></mat-icon>
        </button>

        <button
          class="connect btn-small smr"
          *ngIf="thirdPartyClient['LinkedIn']"
          (click)="openLink(thirdPartyClient['LinkedIn'])"
          mat-mini-fab
          color="accent"
          matTooltip="LinkedIn"
        >
          <mat-icon class="fab fa-linkedin" style="color: #0072b1 !important"></mat-icon>
        </button>

        <button
          class="connect btn-small smr"
          *ngIf="thirdPartyClient['Pinterest']"
          (click)="openLink(thirdPartyClient['Pinterest'])"
          mat-mini-fab
          color="accent"
          matTooltip="Pinterest"
        >
          <mat-icon class="fab fa-pinterest" style="color: #e60023 !important"></mat-icon>
        </button>

        <button
          class="connect btn-small smr"
          *ngIf="thirdPartyClient['Tumblr']"
          (click)="openLink(thirdPartyClient['Tumblr'])"
          mat-mini-fab
          color="accent"
          matTooltip="Tumblr"
        >
          <mat-icon class="fab fa-tumblr" style="color: #36465d !important"></mat-icon>
        </button>

        <button
          class="connect btn-small smr"
          *ngIf="thirdPartyClient['Vimeo']"
          (click)="openLink(thirdPartyClient['Vimeo'])"
          mat-mini-fab
          color="accent"
          matTooltip="Vimeo"
        >
          <mat-icon class="fab fa-vimeo" style="color: #1ab7ea !important"></mat-icon>
        </button>

        <button
          class="connect btn-small smr"
          *ngIf="thirdPartyClient['YouTube']"
          (click)="openLink(thirdPartyClient['YouTube'])"
          mat-mini-fab
          color="accent"
          matTooltip="YouTube"
        >
          <mat-icon class="fab fa-youtube" style="color: #ff0000 !important"></mat-icon>
        </button>
      </div>
    </div>
  </mat-dialog-content>
</mat-card>
