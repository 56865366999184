import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material';
import { NgPipesModule } from 'ngx-pipes';
import { QuillModule } from 'ngx-quill';
import { SharedModule } from '../../shared/shared.module';
import { SessionModule } from '../session/session.module';
import { LogDeleteComponent } from './log-delete/log-delete.component';
import { LogListComponent } from './log-list/log-list.component';
import { LogUserListComponent } from './log-user-list/log-user-list.component';
import { LogViewComponent } from './log-view/log-view.component';
import { LogRoutingModule } from './shared/log-routing.module';
import { LogService } from './shared/log.service';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CdkTableModule,
    MatSortModule,
    NgPipesModule,
    CommonModule,
    LogRoutingModule,
    SharedModule,
    QuillModule,
    SessionModule,
  ],
  declarations: [LogDeleteComponent, LogListComponent, LogUserListComponent, LogViewComponent],
  exports: [LogDeleteComponent, LogListComponent, LogUserListComponent, LogViewComponent],
  entryComponents: [LogViewComponent],
  providers: [LogService],
})
export class LogModule {}
