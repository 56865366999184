import { Component, EventEmitter, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { IframeViewComponent } from '../../../shared/components/iframe-view/iframe-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { EpDocumentWakandaCreateComponent } from '../ep-document-wakanda-create/ep-document-wakanda-create.component';
import { InvitationService } from '../shared/services/invitation.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-wakanda-files-view',
  templateUrl: './wakanda-files-view.component.html',
  styleUrls: ['./wakanda-files-view.component.css'],
})
export class WakandaFilesComponent implements OnInit {
  closeEvent = new EventEmitter();

  isModal = false;

  @Input()
  id;
  @Input()
  type = 'invitation';

  isPromoterOrAdmin = false;

  wakandaFiles = [];
  epFiles = [];
  loaded = false;

  contractID;
  invitationID;
  customerID;
  merchantID;
  customerName;
  utils = new UtilsClass();

  getResult = new EventEmitter();

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private authenticationService: AuthenticationService,
    private invitationService: InvitationService,
    private activeRoute: ActivatedRoute
  ) {
    if (data) {
      if (data.wakandaID) {
        this.id = data.wakandaID;
        this.type = 'wakanda';
        this.isModal = true;
      }
      if (data.invitationID) {
        this.id = data.invitationID;
        this.type = 'invitation';
        this.isModal = true;
      }
      if (data.contractID) {
        this.id = data.contractID;
        this.type = 'contract';
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      this.activeRoute.params.subscribe((params) => {
        if (params['wakandaID']) {
          this.id = params['wakandaID'];
          this.type = 'wakanda';
        }

        if (params['invitationID']) {
          this.id = params['invitationID'];
          this.type = 'invitation';
        }

        if (params['contractID']) {
          this.id = params['contractID'];
          this.type = 'contract';
        }

        this.setup();
      });
    });
  }

  setup() {
    if (this.id) {
      if (this.type == 'invitation') {
        this.invitationService.wakandaFilesByInvitationID(this.id).subscribe((res) => {
          if (res) {
            // if (res.ep && res.ep.length > 0) {
            //   this.epFiles = res.ep;
            // }
            if (res.wakanda && res.wakanda.length > 0) {
              this.wakandaFiles = res.wakanda;
            }

            if (res.invitationID) {
              this.invitationID = res.invitationID;
            }

            if (res.merchantID) {
              this.merchantID = res.merchantID;
            }

            if (res.contractID) {
              this.contractID = res.contractID;
            }

            if (res.customerID) {
              this.customerID = res.customerID;
            }
            if (res.customerName) {
              this.customerName = res.customerName;
            }
          }

          this.loaded = true;
        });
      } else if (this.type == 'wakanda') {
        this.invitationService.wakandaFilesByWakandaID(this.id).subscribe((res) => {
          if (res) {
            // if (res.ep && res.ep.length > 0) {
            //   this.epFiles = res.ep;
            // }
            if (res.wakanda && res.wakanda.length > 0) {
              this.wakandaFiles = res.wakanda;
            }

            if (res.invitationID) {
              this.invitationID = res.invitationID;
            }

            if (res.merchantID) {
              this.merchantID = res.merchantID;
            }

            if (res.contractID) {
              this.contractID = res.contractID;
            }

            if (res.customerID) {
              this.customerID = res.customerID;
            }
            if (res.customerName) {
              this.customerName = res.customerName;
            }
          }
          this.loaded = true;
        });
      } else if (this.type == 'contract') {
        this.invitationService.wakandaFilesByContractID(this.id).subscribe((res) => {
          if (res) {
            // if (res.ep && res.ep.length > 0) {
            //   this.epFiles = res.ep;
            // }
            if (res.wakanda && res.wakanda.length > 0) {
              this.wakandaFiles = res.wakanda;
            }

            if (res.invitationID) {
              this.invitationID = res.invitationID;
            }

            if (res.merchantID) {
              this.merchantID = res.merchantID;
            }

            if (res.contractID) {
              this.contractID = res.contractID;
            }

            if (res.customerID) {
              this.customerID = res.customerID;
            }

            if (res.customerName) {
              this.customerName = res.customerName;
            }
          }
          this.loaded = true;
        });
      }
    }
  }

  closeModal() {
    this.closeEvent.emit(true);
  }

  moveToPage(f) {
    if (f) {
      window.open(f, '_blank', 'noopener');
    }
  }

  convertHEIC(e) {
    if (e && this.utils.getFileExtensionByFileName(e) === 'heic') {
      return `${environment.nodeUrl}/files/heic-convert?link=${e}`;
    }

    return e;
  }
  documentClick(e) {
    if (e) {
      const extension = e.split('.').pop();

      if (e) {
        const ref2 = RootAppComponent.dialog.open(IframeViewComponent, {
          data: {
            link: this.convertHEIC(e),
            isDownload: true,
            isAction: true,
            title: 'Document Preview',
            actionLabel: 'Upload to EP',
            extension,
          },
          width: '950px',
          panelClass: 'noCard',
        });

        ref2.componentInstance.close.subscribe((res) => {
          ref2.close();
        });

        ref2.componentInstance.getAction.subscribe((res) => {
          const ref = RootAppComponent.dialog.open(EpDocumentWakandaCreateComponent, {
            data: {
              link: e,
              customerID: this.customerID,
              invitationID: this.invitationID,
              merchantID: this.merchantID,
              contractID: this.contractID,
              customerName: this.customerName,
              extension,
            },
            width: '600px',
          });

          ref.componentInstance.close.subscribe((res) => {
            ref.close();
          });

          ref.componentInstance.getResult.subscribe((res) => {
            ref.close();
            ref.afterClosed().subscribe((r) => {
              ref2.close();

              if (res && res.ID) {
                this.getResult.emit(res);
              }
            });
          });
        });
      }
    }
  }

  getImageLink(f) {
    let link = 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/file.png';
    if (f) {
      const extension = f.split('.').pop();
      const type = this.utils.getDocumentTypeFromExtension(extension);

      if (type == 'image') {
        return this.convertHEIC(f);
      } else if (extension) {
        link = this.utils.getDefaultPic(null, extension);
      }
    }

    return link;
  }
}
