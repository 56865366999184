<div class="file-container">
  <mat-card class="card uploadsCard">
    <div class="search-table-header">
      <h2 class="sr-title sm-h2">
        {{ title }}

        <span class="badge" *ngIf="templates && templates.length"> {{ templates.length }}</span>
      </h2>

      <!-- search -->
      <mat-icon class="schIcon">search</mat-icon>
      <mat-form-field class="anim">
        <mat-label>{{ 'Filter' | translate }}</mat-label>
        <input [(ngModel)]="filter" matInput name="name" placeholder="{{ 'Filter' | translate }}" tabindex="1"/>
      </mat-form-field>
    </div>

    <div class="file-content-area row clearfix">
      <div class="well flex clearfix">
        <mat-card
          class="template-preview file animate"
          *ngFor="let t of templates | FilterArrayMultipleContainValue: ['Label', 'Tag']:filter"
        >
          <div class="template-header mb">
            <h4 class="rm-m mb truncate" mat-line>{{ t['Label'] }}</h4>
            <label mat-line
            >Tags:
              <span class="chip" *ngFor="let subItem of getTags(t)">{{ subItem }}</span>
            </label>
          </div>

          <img
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png"
            mat-card-image
          />

          <mat-card-actions>
            <p class="small pull-left">Updated: {{ t['DateTimeCreated'] | customDateTime }}</p>

            <button class="pull-right actionMenuToggle" [matMenuTriggerFor]="menu" mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
              <button (click)="actionEvent(t)" mat-menu-item>
                <mat-icon>assignment</mat-icon>
                <span>Create New Campaign</span>
              </button>

              <button (click)="previewTemplate(t)" mat-menu-item>
                <mat-icon>email</mat-icon>
                <span>View Email/SMS</span>
              </button>
            </mat-menu>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </mat-card>
</div>
