import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Location } from '@angular/common';
import { ExistingPatientService } from '../shared/existing-patient.service';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';

@Component({
  selector: 'app-existing-patient-invitation-view',
  templateUrl: './existing-patient-invitation-view.component.html',
  styleUrls: ['./existing-patient-invitation-view.component.css'],
  animations: [
    trigger('onChangeAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-10%)', offset: 0, height: 0, margin: 0 }),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*', margin: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*', margin: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(5%)', offset: 1.0, height: 0, margin: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ExistingPatientInvitationViewComponent implements OnInit {
  @Input() invitationID;
  @Input() invitedPatientObject;

  subjectArray = [];
  invitedPatient = {};
  isModal = false;
  constructor(
    private location: Location,
    private activeRoute: ActivatedRoute,
    private ExistingPatientService: ExistingPatientService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.invitationID = data;
      this.isModal = true;
    }
  }
  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['invitationID']) {
        this.invitationID = params['invitationID'];
      }
      if (this.invitedPatientObject != null && typeof this.invitedPatientObject == 'object') {
        this.invitedPatient = this.invitedPatientObject;
        this.invitationID = this.invitedPatientObject['ID'];
      } else if (this.invitationID) {
        this.ExistingPatientService.getPatientInvitationDetails(this.invitationID).subscribe((res) => {
          this.invitedPatient = res;
        });
      }
    });
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  contact() {
    let data = {
      targetType: 'invitation',
      targetID: this.invitationID,
      asGuest: false,
      asProfile: true,
      asPractice: true,
      subjectArray: this.subjectArray,
    };
    AuthenticationService.contactInputEvent.emit(data);
  }
}
