import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TipService } from '../shared/tip.service';

@Component({
  selector: 'app-tip-view',
  templateUrl: './tip-view.component.html',
  styleUrls: ['./tip-view.component.css'],
})
export class TipViewComponent implements OnInit {
  tip;

  @Input()
  tipID;

  @Output() close = new EventEmitter();

  isModal = false;

  constructor(
    private tipService: TipService,
    private activeRouter: ActivatedRoute,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.tipID) {
        this.tipID = data.tipID;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.activeRouter.params.subscribe((params) => {
      if (params['tipID']) {
        this.tipID = params['tipID'];
      }

      if (this.tipID) {
        this.tipService.getTipDetails(this.tipID).subscribe((res) => {
          if (res) {
            this.tip = res;
          }
        });
      }
    });
  }

  closeModalEvent() {
    this.close.emit(true);
  }
}
