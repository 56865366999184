import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InformedConsentTreatmentPresentedPage } from '../informed-consent-treatment-presented/informed-consent-treatment-presented.page';

const routes: Routes = [
  {
    path: 'informed-consent-treatment-presented',
    component: InformedConsentTreatmentPresentedPage,
    data: {
      title: ' ',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InformedConsentRoutingModule {}
