import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TemplateService {
  constructor(private http: HttpClient) {}

  createTemplate(payload, isAdminOrPromoter = false): Observable<any> {
    if (payload && payload.emailBody) {
      payload.emailBody = payload.emailBody.replace(/"/g, '\\"');
      payload.emailBody = payload.emailBody.replace(/'/g, "\\'");
    }

    if (isAdminOrPromoter == true) {
      const urlPatient = environment.nodeUrl + '/templateMerchant/global';
      return this.http.post(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      const urlPatient = environment.nodeUrl + '/templateMerchant';
      return this.http.post(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  templateList(payload, isAdminOrPromoter = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isAdminOrPromoter == true) {
      return this.http
        .get(environment.nodeUrl + '/templateMerchant/global', options)
        .pipe(map((res: Response) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/templateMerchant/', options)
        .pipe(map((res: Response) => res['data']));
    }
  }

  isTemplateAlreadyExist(tag): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/templateMerchant/isAlreadyExist/' + tag, {})
      .pipe(map((res: Response) => res['data']));
  }

  getTemplatePicture(ID, payload = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.http
      .get(`${environment.nodeUrl}/templateMerchant/logo-picture/` + ID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getTemplatePictureStreamLink(ID, LastModified = null) {
    if (LastModified) {
      return `${environment.nodeUrl}/template/picture-stream-cached/${LastModified}/` + ID;
    } else if (ID) {
      if (ID) {
        return `${environment.nodeUrl}/template/picture-stream/` + ID;
      }
    }
    return null;
  }

  getOneTemplate(ID, payload = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.http
      .get(`${environment.nodeUrl}/templateMerchant/` + ID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  modifyTemplate(ID, payload, isAdminOrPromoter = false): Observable<any> {
    if (payload && payload.emailBody) {
      payload.emailBody = payload.emailBody.replace(/"/g, '\\"');
      payload.emailBody = payload.emailBody.replace(/'/g, "\\'");
    }

    if (isAdminOrPromoter == true) {
      return this.http
        .put(`${environment.nodeUrl}/templateMerchant/global/` + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(`${environment.nodeUrl}/templateMerchant/` + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getPhysicalTemplateList(payload, isAdminOrPromoter = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (isAdminOrPromoter == true) {
      return this.http
        .get(`${environment.nodeUrl}/template/global`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(`${environment.nodeUrl}/template/`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getPhysicalTemplatePicture(ID) {
    return this.http
      .get(`${environment.nodeUrl}/template/picture/` + ID, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPhysicalTemplatePictureStreamLink(ID) {
    if (ID) {
      return `${environment.nodeUrl}/templateMerchant/picture-stream/` + ID;
    }

    return null;
  }

  getTemplatePurposeList(payload = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(`${environment.nodeUrl}/template-purpose/`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  modifyPurpose(ID, payload): Observable<any> {
    return this.http
      .put(`${environment.nodeUrl}/template-purpose/` + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createPurpose(payload): Observable<any> {
    return this.http
      .post(`${environment.nodeUrl}/template-purpose/`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getTemplatePurposeDetails(ID): Observable<any> {
    return this.http
      .get(`${environment.nodeUrl}/template-purpose/` + ID, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  removePurpose(ID): Observable<any> {
    return this.http
      .delete(`${environment.nodeUrl}/template-purpose/` + ID, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  removeTemplate(ID): Observable<any> {
    return this.http
      .delete(`${environment.nodeUrl}/template/` + ID, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  modifyPromoterTemplateGroup(payload): Observable<any> {
    return this.http.put(`${environment.nodeUrl}/template/group`, payload).pipe(map((res: Response) => res['data']));
  }

  deletePromoterTemplateGroup(ids): Observable<any> {
    let _ids;
    if (Array.isArray(ids) == true) {
      _ids = ids.join(',');
    } else if (typeof ids == 'string') {
      _ids = ids;
    }

    if (_ids) {
      return this.http
        .delete(environment.nodeUrl + '/template/group/' + _ids)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
}
