<ng-container *ngIf="industryType$ | async as industryType">
  <ng-container [ngSwitch]="industryType">
    <ipv-retail-product-invoice-list class="list-filter-card clearfix full-width" *ngSwitchCase="'breeze'">
    </ipv-retail-product-invoice-list>

    <ipv-retail-product-invoice-list class="list-filter-card clearfix full-width" *ngSwitchCase="'retail'">
    </ipv-retail-product-invoice-list>

    <ipv-dental-product-invoice-list class="list-filter-card clearfix full-width" *ngSwitchCase="'dental'">
    </ipv-dental-product-invoice-list>
  </ng-container>
</ng-container>
