<div class="container unsub-page" *ngIf="patient && merchant">
  <h1 class="white text-center">Mail settings</h1>
  <mat-card class="text-center clearfix">
    <div class="card-header primary-gradient-img clearfix inModal">
      <div class="row clearfix text-left">
        <div class="row titleArea clearfix full-width">
          <mat-icon class="fas fa-envelope pull-left"></mat-icon>
          <h2 class="summary-header rm-m white">
            {{ merchant.TradingAs }}
            <span class="subLabel">Unsubscribe from marketing emails</span>
          </h2>
        </div>
      </div>
    </div>

    <img
      class="unsub"
      src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/character/unsubscribe.png"
    />

    <!-- user is not unsubscribed -->
    <div class="row clearfix column mb" *ngIf="isUnsubscribed == false">
      <p class="lead">
        {{ patient.FirstName }}, are you sure you want to unsubscribe? It won't be the same without you!
      </p>
      <p>
        If you'd like to stop seeing marketing emails from {{ merchant.TradingAs }}, hit the 'Unsubscribe me' button
        below.
      </p>
      <!-- trigger unsubscribe -->
      <button class="mt" (click)="unsubscribe()" mat-raised-button color="accent">
        Unsubscribe me!
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>

    <!-- user is unsubscribed -->
    <div class="row clearfix column mt mb" *ngIf="isUnsubscribed == true">
      <p class="success">
        <mat-icon class="fas fa-check-circle"></mat-icon>
        You've been successfully unsubscribed from these kinds of marketing emails from {{ merchant.TradingAs }}. Please
        be aware that you may still receive other emails regarding your {{ "KEYWORD.treatment" | translate }} or other
        important information.
      </p>
      <p>If you think you'd like to receive marketing emails again, click below.</p>
      <!-- trigger re-subscribe -->
      <button class="" (click)="subscribe()" mat-raised-button color="primary">
        I'd like to subscribe again
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>

    <div
      class="greyBar"
      *ngIf="
        merchant['URL'] ||
        merchant['Facebook'] ||
        merchant['Twitter'] ||
        merchant['LinkedIn'] ||
        merchant['Instagram'] ||
        merchant['Pinterest'] ||
        merchant['Tumblr'] ||
        merchant['Vimeo']
      "
    >
      <div class="row clearfix">
        <p>
          If you'd still like to keep track of the latest news and offers from <strong>{{ merchant.TradingAs }}</strong
        >, not to worry! You can follow us on social media or visit our website below.
        </p>
      </div>
      <div class="row clearfix socials">
        <!-- open social links in new tab -->
        <button
          *ngIf="merchant['URL']"
          (click)="openSocialLink(merchant['URL'])"
          mat-icon-button
          matTooltip="Visit URL page"
        >
          <mat-icon class="fas fa-globe"></mat-icon>
        </button>
        <button
          *ngIf="merchant['Facebook']"
          (click)="openSocialLink(merchant['Facebook'])"
          mat-icon-button
          matTooltip="Visit Facebook page"
        >
          <mat-icon class="fab fa-facebook"></mat-icon>
        </button>
        <button
          *ngIf="merchant['Twitter']"
          (click)="openSocialLink(merchant['Twitter'])"
          mat-icon-button
          matTooltip="Visit Twitter page"
        >
          <mat-icon class="fab fa-twitter"></mat-icon>
        </button>
        <button
          *ngIf="merchant['LinkedIn']"
          (click)="openSocialLink(merchant['LinkedIn'])"
          mat-icon-button
          matTooltip="Visit LinkedIn page"
        >
          <mat-icon class="fab fa-linkedin"></mat-icon>
        </button>
        <button
          *ngIf="merchant['Instagram']"
          (click)="openSocialLink(merchant['Instagram'])"
          mat-icon-button
          matTooltip="Visit Instagram page"
        >
          <mat-icon class="fab fa-instagram"></mat-icon>
        </button>
        <button
          *ngIf="merchant['Pinterest']"
          (click)="openSocialLink(merchant['Pinterest'])"
          mat-icon-button
          matTooltip="Visit Pinterest page"
        >
          <mat-icon class="fab fa-pinterest"></mat-icon>
        </button>
        <button
          *ngIf="merchant['Tumblr']"
          (click)="openSocialLink(merchant['Tumblr'])"
          mat-icon-button
          matTooltip="Visit Tumblr page"
        >
          <mat-icon class="fab fa-tumblr"></mat-icon>
        </button>
        <button
          *ngIf="merchant['Vimeo']"
          (click)="openSocialLink(merchant['Vimeo'])"
          mat-icon-button
          matTooltip="Visit Vimeo page"
        >
          <mat-icon class="fab fa-vimeo"></mat-icon>
        </button>
      </div>
    </div>
  </mat-card>
</div>
