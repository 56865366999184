import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

@Component({
  selector: 'app-agent-details',
  templateUrl: './agent-details.component.html',
  styleUrls: ['./agent-details.component.css'],
})
export class AgentDetailsComponent implements OnInit {
  @Input()
  agents = [
    {
      browser: 'chrome',
      browser_version: '63.0.3239.84',
      device: 'unknown',
      os: 'windows',
      os_version: 'windows-8-1',
    },
  ];

  util = new UtilsClass();

  @Output()
  close = new EventEmitter();

  @Input() label = '';
  @Input() sessionID;
  @Input() cnhID;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.label = data.label;
      this.agents = data.agents;
      this.sessionID = data.sessionID;
      this.cnhID = data.cnhID;
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  ngOnInit() {}
}
