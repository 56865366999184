import { Component, EventEmitter, Inject, OnInit, Optional } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from '../shared/message.service';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialogSingleButtonComponent } from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { DentistViewComponent } from '../../dentist/dentist-view/dentist-view.component';
import { MessageGroupCardListComponent } from '../message-group-card-list/message-group-card-list.component';
import { MessageUtil } from '../shared/messageUtil.type';

import * as _ from 'lodash';
import { InvitationLookupComponent } from '../../invitation/invitation-lookup/invitation-lookup.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsService } from '../../../shared/services/utils.service';
import { MessageGroupLocalListComponent } from '../message-group-local-list/message-group-local-list.component';

@Component({
  selector: 'app-message-group-view',
  templateUrl: './message-group-view.component.html',
  styleUrls: ['./message-group-view.component.css'],
})
export class MessageGroupViewComponent implements OnInit {
  messageGroupID;

  sessionType;
  messageLabel = 'SMS';
  isPromoterOrAdmin;

  messageGroup;

  contact;
  merchant;

  promoter;

  card: any = {
    ID: '00000000000000000000',
    Salutation: 'Mr',
    FirstName: 'John',
    MiddleName: 'Al',
    Name: 'Doe',
    'mobiles.Number': '0061101010101',
    'phones.Number': '0062202020202',
    'emails.Email': 'john.doe@smileright.com.au',
    'addresses.Calculated': 'PO Box 7795 Cloisters Square 6850 Western Australia',
  };

  message;

  isModuleBulkSMS = Settings.global['isModuleBulkSMSActive'];

  messageUtil = new MessageUtil();
  statusColors = this.messageUtil.statusColors;

  messageStatusColors = this.messageUtil.messageStatusColors;

  statusMessageArray1 = this.messageUtil.statusMessageArray1;
  statusMessageArray2 = this.messageUtil.statusMessageArray2;
  statusMessageArray3 = this.messageUtil.statusMessageArray3;

  messageGroupStatus = this.messageUtil.messageGroupStatus;
  statusArray = this.messageUtil.statusArray;

  filterKeys = [];
  totalMessages = 0;
  chartVisitorData = [];
  colorScheme = [];

  totalMessagesLocal = 0;
  chartVisitorDataLocal = [];
  colorSchemeLocal = [];

  progress = 0;
  progressClass = 'progress-bar-message-0';

  isSMS = true;


  marketingConsent = true;

  hasMobile = true;
  hasEmail = false;
  isQuickView = false;
  isModal = false;

  noAction = false;

  getRemove = new EventEmitter();
  close = new EventEmitter();
  getLastVersion = new EventEmitter();
  constructor(
    private authenticationService: AuthenticationService,
    private messageService: MessageService,

    private utilService: UtilsService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.messageGroupID) {
        this.isModal = true;
        this.isQuickView = true;
        this.messageGroupID = data.messageGroupID;
      }

      if (data.noAction === true) {
        this.noAction = true;
      }
    }
  }

  ngOnInit() {
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleBulkSMS = access['isModuleBulkSMSActive'];
      }

      if (this.isModuleBulkSMS === false) {
        this.router.navigate(['404']);
      }

      this.activeRoute.params.subscribe((params) => {
        if (params['messageGroupID']) {
          this.messageGroupID = params['messageGroupID'];
        }
        this.authenticationService.getPromoter().subscribe((promoter) => {
          if (promoter && promoter.data) {
            this.promoter = promoter.data;
          } else if (promoter) {
            this.promoter = promoter;
          }

          if (this.isSMS == true) {
            this.statusMessageArray1 = this.messageUtil.statusMessageArray1SMS;
            this.statusMessageArray2 = this.messageUtil.statusMessageArray2SMS;
            this.statusMessageArray3 = this.messageUtil.statusMessageArray3SMS;
          }

          this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
            this.isPromoterOrAdmin = isPromoterOrAdmin;

            this.authenticationService.getSessionType().subscribe((r) => {
              this.sessionType = r;
              this.setup();
              this.messageService.getMessageGroupStatusColors().subscribe((colors) => {
                if (colors) {
                  this.statusColors = colors || {};

                  this.messageUtil.setStatusColors(this.statusColors);
                }
              });

              this.messageService.getMessageGroupmessageStatusColors().subscribe((messageStatusColors) => {
                if (messageStatusColors) {
                  this.messageStatusColors = messageStatusColors || {};

                  this.messageUtil.setMessageStatusColors(this.messageStatusColors);
                }
              });
            });
          });
        });
      });
    });
  }

  setup() {
    this.messageGroup = null;
    if (this.messageGroupID) {
      this.messageService
        .getMessageGroupDetails(this.messageGroupID, { selectContact: true, selectMerchant: true }, this.sessionType)
        .subscribe((r) => {
          if (r && r.ID) {
            this.messageGroupID = r.ID;
            let messageGroup = r;

            if (r && r.contact && r.contact.ID) {
              this.contact = r.contact;
            }

            if (r && r.merchant && r.merchant.ID) {
              this.merchant = r.merchant;
            }

            if (messageGroup['Tags']) {
              messageGroup['tags'] = messageGroup['Tags'].split('|');
            }

            if (messageGroup['Group_Filter_keys']) {
              messageGroup['filterKeysIDs'] = messageGroup['Group_Filter_keys'].split('|');
            }
            if (messageGroup['Selected_Card_IDs']) {
              messageGroup['selectedIDs'] = messageGroup['Selected_Card_IDs'].split('|');
            }

            if (messageGroup['Excluded_Card_IDs']) {
              messageGroup['ExcludedIDs'] = messageGroup['Excluded_Card_IDs'].split('|');
            }

            if (messageGroup['filterKeysIDs'] && messageGroup['filterKeysIDs'].length > 0) {
              const __p = {
                merchantID: messageGroup['Merchant_key'],
                ids: messageGroup['filterKeysIDs'].join(','),
                selectQuery: false,
              };
              this.messageService.getFilterList(__p, this.isPromoterOrAdmin).subscribe((filter) => {
                if (filter && filter.length > 0) {
                  messageGroup['filterKeys'] = filter;
                }
              });
            }

            this.messageGroup = messageGroup;

            this.getLastVersion.emit(this.messageGroup);
            this.addChart();
            this.calculateProgress();
            this.converMessage();
          } else {
            this.list();
          }
        });
    }
  }

  addChart() {
    if (this.messageGroup) {
      this.totalMessages = 0;
      this.chartVisitorData = [];

      this.totalMessagesLocal = 0;
      this.chartVisitorDataLocal = [];
      let messageStatus = [];

      this.colorScheme = [];
      this.colorSchemeLocal = [];

      messageStatus = messageStatus.concat(this.statusMessageArray1);
      messageStatus = messageStatus.concat(this.statusMessageArray2);
      messageStatus = messageStatus.concat(this.statusMessageArray3);

      messageStatus.map((item) => {
        if (item) {
          if (this.messageGroup[item + '_Message_Total'] && Number(this.messageGroup[item + '_Message_Total']) > 0) {
            this.colorScheme.push(this.messageUtil.getStatusColor(item, false));
            this.totalMessages = this.totalMessages + Number(this.messageGroup[item + '_Message_Total']);
          }
        }
      });

      messageStatus.map((item) => {
        if (item) {
          if (this.messageGroup[item + '_Message_Total'] && Number(this.messageGroup[item + '_Message_Total']) > 0) {
            this.chartVisitorData.push({
              code: item,
              name: `${item} - ${Math.trunc(
                (Number(this.messageGroup[item + '_Message_Total']) / Number(this.totalMessages)) * 100
              )}%`,
              value: Number(Number(this.messageGroup[item + '_Message_Total'])),
            });
          }
        }
      });

      if (this.messageGroup.statusStatistics && this.messageGroup.statusStatistics.length > 0) {
        this.messageGroup.statusStatistics.map((item) => {
          if (item) {
            if (item.status !== 'Total' && item.count > 0) {
              this.colorSchemeLocal.push(this.messageUtil.getStatusColor(item.status, true));
              this.totalMessagesLocal = this.totalMessagesLocal + Number(item.count || 0);
            }
          }
        });

        this.messageGroup.statusStatistics.map((item) => {
          if (item) {
            if (item.status !== 'Total' && item.count > 0) {
              this.chartVisitorDataLocal.push({
                code: item.status,
                name: `${item.status} - ${Math.trunc(
                  (Number(item.count || 0) / Number(this.totalMessagesLocal)) * 100
                )}%`,
                value: Number(item.count || 0),
              });
            }
          }
        });
      }
    }
    this.chartVisitorDataLocal = JSON.parse(JSON.stringify(this.chartVisitorDataLocal));
    this.chartVisitorData = JSON.parse(JSON.stringify(this.chartVisitorData));
  }

  converMessage() {
    this.message = null;

    if (
      this.card &&
      this.card.ID &&
      this.promoter &&
      this.promoter.ID &&
      this.contact &&
      this.contact.ID &&
      this.merchant &&
      this.merchant.ID &&
      this.messageGroup &&
      this.messageGroup['Message']
    ) {
      let p = {
        card: this.card,
        merchant: this.merchant,
        promoter: this.promoter,
        contact: this.contact,
        message: this.messageGroup['Message'],
      };

      this.messageService.convertMessage(p).subscribe((d) => {
        if (d) {
          this.message = d;
        }
      });
    }
  }

  calculateProgress() {
    this.progress = 0;
    let total = 0;
    let pending = 0;
    let cancel = 0;
    let failed = 0;
    let pause = 0;
    if (this.messageGroup && this.messageGroup.statusStatistics && this.messageGroup.statusStatistics.length > 0) {
      this.messageGroup.statusStatistics.map((item) => {
        if (item && item.status === 'Total') {
          total = item.count;
        } else if (item && item.status === 'Pending') {
          pending = item.count;
        } else if (item && item.status === 'Paused') {
          pause = item.count;
        } else if (item && item.status === 'Canceled') {
          cancel = item.count;
        } else if (item && item.status === 'Failed') {
          failed = item.count;
        }
      });
    }
    if (this.messageGroup['Status.Label'] === 'Pending') {
      this.progressClass = 'progress-bar-message-0';
      this.progress = 0;
    } else if (this.messageGroup['Status.Label'] === 'Canceled') {
      this.progressClass = 'progress-bar-message-error';
      let _progress = 100 - ((pending + cancel) / total) * 100;
      this.progress = Math.floor(_progress);
    } else if (this.messageGroup['Status.Label'] === 'Failed') {
      this.progressClass = 'progress-bar-message-error';
      let _progress = 100 - ((pending + failed) / total) * 100;
      this.progress = Math.floor(_progress);
    } else if (
      this.messageGroup['Status.Label'] === 'Draft' ||
      this.messageGroup['Status.Label'] === 'Paused' ||
      this.messageGroup['Status.Label'] === 'Scheduled'
    ) {
      this.progressClass = 'progress-bar-message-0';

      if (this.messageGroup['Status.Label'] === 'Paused') {
        let _progress = 100 - ((pending + pause) / total) * 100;
        this.progress = Math.floor(_progress);
      }
    } else if (this.messageGroup['Status.Label'] === 'Complete') {
      this.progressClass = 'progress-bar-message-100';
      this.progress = 100;
    } else {
      if (total == 0) {
        this.progress = 0;
        this.progressClass = 'progress-bar-message-0';
      } else {
        let _progress = 0;
        if (pending === total) {
          _progress = 0;
          this.progress = 0;
        } else {
          _progress = 100 - (pending / total) * 100;

          this.progress = Math.floor(_progress);
        }

        if (this.messageGroup['Status.Label'] === 'Canceled' || this.messageGroup['Status.Label'] === 'Failed') {
          this.progressClass = 'progress-bar-message-10';
        } else if (_progress > 0 && _progress <= 10) {
          this.progressClass = 'progress-bar-message-0';
        } else if (_progress > 10 && _progress <= 20) {
          this.progressClass = 'progress-bar-message-10';
        } else if (_progress > 20 && _progress <= 30) {
          this.progressClass = 'progress-bar-message-20';
        } else if (_progress > 30 && _progress <= 40) {
          this.progressClass = 'progress-bar-message-30';
        } else if (_progress > 40 && _progress <= 50) {
          this.progressClass = 'progress-bar-message-40';
        } else if (_progress > 50 && _progress <= 60) {
          this.progressClass = 'progress-bar-message-50';
        } else if (_progress > 60 && _progress <= 70) {
          this.progressClass = 'progress-bar-message-60';
        } else if (_progress > 70 && _progress <= 80) {
          this.progressClass = 'progress-bar-message-70';
        } else if (_progress > 80 && _progress <= 90) {
          this.progressClass = 'progress-bar-message-80';
        } else if (_progress > 90 && _progress <= 100) {
          this.progressClass = 'progress-bar-message-90';
        } else {
          this.progress = 100;
          this.progressClass = 'progress-bar-message-100';
        }
      }
    }
  }

  displayStatusDescription(s) {
    if (s && this.messageUtil.getMessageDescription(s)) {
      const confirmDialog2 = new ConfirmDialogSingleButton(
        this.messageUtil.getMessageStatusIcon(s),
        s,
        '<p>' + this.messageUtil.getMessageDescription(s) + '</p>',
        'OKAY',
        false,
        this.messageUtil.getStatusColor(s, false)
      );
      const ref2 = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
        data: confirmDialog2,
        width: '450px',
      });
      ref2.componentInstance.onConfirm.subscribe((res) => {
        ref2.close();
      });
    }
  }
  remove() {
    if (this.messageGroup && this.messageGroup.ID) {
      const confirm = new ConfirmDialog('fas fa-trash', '', 'Are you sure you want to remove Campaign?', 'No', 'Yes');

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.messageService.deleteMessageGroup(this.messageGroup.ID, this.sessionType).subscribe((res) => {
            if (res) {
              NotifyAppComponent.displayToast(
                'success',
                'Successful operation',
                'The Bulk ' + this.messageLabel + ' has been successfully removed'
              );
            }
            ref.close();

            if (this.isModal) {
              this.getRemove.emit(this.messageGroup.ID);
            } else {
              this.router.navigate(['/merchant', { outlets: { page: ['message-group-list-overview'] } }]);
            }
          });
        }
      });
    }
  }

  list() {
    this.router.navigate(['/merchant', { outlets: { page: ['message-group-list-overview'] } }]);
  }
  viewDentist(ID) {
    if (ID) {
      const ref = RootAppComponent.dialog.open(DentistViewComponent, {
        data: ID,
        width: '600px',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewMessageList(status = null, messageStatus = null) {
    if (status || messageStatus) {
      const ref = RootAppComponent.dialog.open(MessageGroupLocalListComponent, {
        data: {
          merchantID: this.messageGroup['Merchant_key'],
          status: status,
          messageStatus: messageStatus,
          messageGroupID: this.messageGroup.ID,
        },
        width: '900px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.getRefresh.subscribe((res) => {
        ref.close();
        this.refreshStatistics();
      });
    }
  }

  displayStatusGroupDescription(s) {
    if (s && this.messageUtil.getMessageGroupDescription(s)) {
      const confirmDialog2 = new ConfirmDialogSingleButton(
        this.messageUtil.getStatusIcon(s),
        s,
        '<p>' + this.messageUtil.getMessageGroupDescription(s) + '</p>',
        'OKAY',
        false,
        this.messageUtil.getStatusColor(s, true)
      );
      const ref2 = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
        data: confirmDialog2,
        width: '450px',
      });
      ref2.componentInstance.onConfirm.subscribe((res) => {
        ref2.close();
      });
    }
  }

  preview(type) {
    if (type === 'manual' && this.messageGroup['selectedIDs'] && this.messageGroup['selectedIDs'].length > 0) {
      const ref2 = RootAppComponent.dialog.open(MessageGroupCardListComponent, {
        data: {
          selectedIDs: this.messageGroup['selectedIDs'],
          title: 'Preview manual selection',
          isRemove: false,
          isViewOnly: true,
          hasMobile: this.hasMobile,
          hasEmail: this.hasEmail,
          marketingConsent:this.messageGroup.marketingConsent
        },

        width: '700px',
        panelClass: 'noCard',
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });
    } else if (type == 'excluded' && this.messageGroup['ExcludedIDs'] && this.messageGroup['ExcludedIDs'].length > 0) {
      const ref2 = RootAppComponent.dialog.open(MessageGroupCardListComponent, {
        data: {
          selectedIDs: this.messageGroup['ExcludedIDs'],
          title: 'Preview excluded records',
          isRemove: false,
          isViewOnly: true,
          hasMobile: this.hasMobile,
          hasEmail: this.hasEmail,
          marketingConsent:this.messageGroup.marketingConsent
        },

        width: '700px',
        panelClass: 'noCard',
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });

      ref2.backdropClick().subscribe((res) => {
        ref2.close();
      });
    }
  }

  refreshStatistics() {
    this.messageService
      .getMessageGroupStatisticsHidden(this.messageGroupID, { modifyRecord: true }, this.sessionType)
      .subscribe((r) => {
        if (r && r.ID) {
          this.messageGroup = _.merge({}, this.messageGroup, r);
          this.addChart();

          this.calculateProgress();
        }
      });
  }

  selectPatient() {
    const ref = RootAppComponent.dialog.open(InvitationLookupComponent, {
      data: {
        merchantID: this.messageGroup['Merchant_key'],
        onlyMyAccount: true,
        displayChatButton: false,
        useStrict: true,
      },
      width: '600px',
    });
    ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });

    ref.componentInstance.getSelectedPatient.subscribe((u) => {
      if (u && u.ID) {
        const tableName = u['TableName'];

        this.messageService.getCardDetails(u.ID, tableName, {}, this.sessionType).subscribe((res) => {
          if (res && res.ID) {
            this.card = res;

            this.converMessage();
          }

          ref.close();
        });
      }
    });

    ref.componentInstance.detailedView.subscribe((res) => {
      ref.close();
    });
  }

  canApplyStatus(s) {
    if (
      this.messageGroup &&
      this.messageGroup.ID &&
      this.messageGroup.AllowedStatusUpdates &&
      this.messageGroup.AllowedStatusUpdates.length > 0 &&
      this.messageGroup.AllowedStatusUpdates.indexOf(s) !== -1
    ) {
      return true;
    }
    return false;
  }

  changeStatus(s) {
    let confirm = new ConfirmDialog(
      'fas fa-bad',
      '',
      'Are you sure you want to <strong>Cancel</strong> Campaign ?',
      'No',
      'Yes'
    );

    if (s === 'Canceled') {
      confirm = new ConfirmDialog(
        this.messageUtil.statusIcons[s],
        '',
        'Are you sure you want to <strong>Cancel</strong> Campaign ?',
        'No',
        'Yes'
      );
    } else if (s === 'Paused') {
      confirm = new ConfirmDialog(
        this.messageUtil.statusIcons[s],
        '',
        'Are you sure you want to <strong>Pause</strong> Campaign ?',
        'No',
        'Yes'
      );
    } else if (s === 'Complete') {
      confirm = new ConfirmDialog(
        this.messageUtil.statusIcons[s],
        '',
        'Are you sure you want to mark this Campaign as  <strong>Complete</strong> ?<br><br> Any Pending, Scheduled or Paused Messages will be canceled.',
        'No',
        'Yes'
      );
    } else if (s === 'Sending') {
      confirm = new ConfirmDialog(
        this.messageUtil.statusIcons[s],
        '',
        'Are you sure you want to <strong>Resume</strong> Campaign ?',
        'No',
        'Yes'
      );
    }

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
    });
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        this.messageService.modifyMessageGroup(this.messageGroupID, { status: s }, this.sessionType).subscribe((r) => {
          if (r && r.ID) {
            ref.close();

            NotifyAppComponent.displayToast('success', 'Success!', '');
            this.setup();
          }
        });
      }
    });
  }

  clone() {
    if (this.messageGroup && this.messageGroup.ID) {
      let confirm = new ConfirmDialog(
        'fas fa-clone',
        '',
        'Are you sure you want to <strong>Clone</strong> Campaign ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          ref.close();
          this.closeEvent();
          this.router.navigate(['/merchant', { outlets: { page: ['message-group-clone', this.messageGroup.ID] } }]);
        }
      });
    }
  }

  continue() {
    if (this.messageGroup && this.messageGroup.ID) {
      let confirm = new ConfirmDialog(
        'fas fa-edit',
        '',
        'You want to  <strong>Continue</strong> editing Campaign ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          ref.close();

          this.closeEvent();
          this.router.navigate(['/merchant', { outlets: { page: ['message-group-draft', this.messageGroup.ID] } }]);
        }
      });
    }
  }

  refreshMessageGroup() {
    if (this.messageGroup && this.messageGroup.ID) {
      this.setup();
    }
  }

  splitCampaginStatusArray(s) {
    if (s == 1) {
      return this.messageGroupStatus.slice(0, 4);
    } else {
      return this.messageGroupStatus.slice(4, 9);
    }
  }
  closeEvent() {
    this.close.emit(true);
  }
  convertNumber(d) {
    return Number(d || 0);
  }

  hardRefresh() {
    if (this.messageGroup && this.messageGroup.ID) {
      const confirm = new ConfirmDialog(
        'fas fa-redo-alt',
        '',
        '<p><strong> Hard Refresh </strong> will force the update of the status of all sent SMS messages. do you wish to continue?</p>',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.messageService
            .messageGroupHardRefresh({ messageGroupID: this.messageGroup.ID }, this.sessionType)
            .subscribe((res) => {
              if (res) {
                this.setup();

                NotifyAppComponent.displayToast('success', 'Successful operation', '');
              }
              ref.close();
            });
        }
      });
    }
  }
}
