import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';

import { MatTableDataSource } from '@angular/material';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { MerchantServiceCreateComponent } from '../merchant-service-create/merchant-service-create.component';
import { MerchantServiceEditComponent } from '../merchant-service-edit/merchant-service-edit.component';

@Component({
  selector: 'app-merchant-service-list',
  templateUrl: './merchant-service-list.component.html',
  styleUrls: ['./merchant-service-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MerchantServiceListComponent implements OnInit {
  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  selectedServiceGroupKey;

  displayedColumns = ['Label', 'Description', 'Actions'];

  merchantServicesGroups = [];
  merchantServicesItems = [];

  datasource: MatTableDataSource<any>;

  firstLoadEvent = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(private merchantService: MerchantService) {}

  ngOnInit() {
    this.merchantService.getMerchantServicesGroupList({}).subscribe((data) => {
      this.merchantServicesGroups = data.sort((a, b) => (a.Label > b.Label ? 1 : -1));

      this.merchantService
        .getMerchantServicesItemsList({ ServiceGroup_key: this.merchantServicesGroups[0].ID })
        .subscribe((merchantServiceItemsData) => {
          this.selectedServiceGroupKey = this.merchantServicesGroups[0].ID;
          this.merchantServicesItems = merchantServiceItemsData.sort((a, b) => (a.Label > b.Label ? 1 : -1));
          this.firstLoadEvent = true;
          this.datasource = new MatTableDataSource(this.merchantServicesItems);
          this.datasource.paginator = this.paginator;
        });
    });
  }

  openCreateMerchantServiceItemDialog() {
    const ref = RootAppComponent.dialog.open(MerchantServiceCreateComponent, { width: '600px' });

    ref.componentInstance.create.subscribe((merchantCreateServiceItem) => {
      merchantCreateServiceItem.ServiceGroup_key = this.selectedServiceGroupKey;
      this.merchantService.createMerchantServicesItems(merchantCreateServiceItem).subscribe((result) => {
        if (result) {
          this.merchantServicesItems.push(...result);

          const merchantServicesItemsSorted = this.merchantServicesItems.sort((a, b) => (a.Label > b.Label ? 1 : -1));

          this.datasource.data = [...merchantServicesItemsSorted];

          NotifyAppComponent.displayToast(
            'success',
            'Successful operation',
            'The service has been successfully created'
          );

          ref.close();
        }
      });
    });
  }

  openEditMerchantServiceItemDialog(merchantServicesItem) {
    const ref = RootAppComponent.dialog.open(MerchantServiceEditComponent, {
      data: {
        ID: merchantServicesItem.ID,
        Label: merchantServicesItem.Label,
        Description: merchantServicesItem.Description,
      },
      width: '600px',
    });

    ref.componentInstance.update.subscribe((merchantServicesItemResult) => {
      this.merchantService.updateMerchantServicesItems(merchantServicesItemResult).subscribe((result) => {
        if (result) {
          const updatedIndex = this.merchantServicesItems.findIndex(
            (oldMerchantServiceGroup) => oldMerchantServiceGroup.ID === merchantServicesItemResult.ID
          );
          if (updatedIndex > -1) {
            this.merchantServicesItems[updatedIndex] = merchantServicesItemResult;

            const merchantServicesItemSorted = this.merchantServicesItems.sort((a, b) => (a.Label > b.Label ? 1 : -1));

            this.datasource.data = [...merchantServicesItemSorted];
          }

          NotifyAppComponent.displayToast(
            'success',
            'Successful operation',
            'The service has been successfully edited'
          );

          ref.close();
        }
      });
    });
  }

  openDeleteMerchantServiceItemDialog(id) {
    const confirm = new ConfirmDialog('delete', '', 'Are you sure you want to delete this service?', 'No', 'Yes');

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, { data: confirm });

    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        this.merchantService.deleteMerchantServicesItems(id).subscribe((result) => {
          if (result) {
            const updatedIndex = this.merchantServicesItems.findIndex(
              (oldMerchantServiceGroup) => oldMerchantServiceGroup.ID === id
            );

            if (updatedIndex > -1) {
              this.merchantServicesItems.splice(updatedIndex, 1);

              this.datasource.data = [...this.merchantServicesItems];
            }

            NotifyAppComponent.displayToast(
              'success',
              'Successful operation',
              'The service has been successfully deleted'
            );

            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }

  changeServiceGroup() {
    this.merchantService
      .getMerchantServicesItemsList({ ServiceGroup_key: this.selectedServiceGroupKey })
      .subscribe((merchantServiceItemsData) => {
        const newData = merchantServiceItemsData.sort((a, b) => (a.Label > b.Label ? 1 : -1));
        this.merchantServicesItems = [...newData];
        this.datasource.data = this.merchantServicesItems;
      });
  }
}
