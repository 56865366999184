import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/table';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map as _map, uniqBy } from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { MerchantViewComponent } from '../../../feature/merchant/merchant-view/merchant-view.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { SideMenuService } from '../../../shared/services/side-menu.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { SubscriptionPlanService } from '../shared/subscription-plan.service';
import { SubscriptionPlanUserEditComponent } from '../subscription-plan-user-edit/subscription-plan-user-edit.component';
import { SubscriptionPlanViewModalComponent } from '../subscription-plan-view-modal/subscription-plan-view-modal.component';

@Component({
  selector: 'app-subscription-plan-user-list',
  templateUrl: './subscription-plan-user-list.component.html',
  styleUrls: ['./subscription-plan-user-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.5s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.5s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class SubscriptionPlanUserListComponent implements OnInit, OnChanges, OnDestroy {
  isPromoterOrAdmin = false;
  root = '/merchant';
  selectedTypeID;

  typeFilter;

  searchInput = '';

  @Input()
  title = 'Practices';

  @Input()
  merchantID;

  isActiveFilter;

  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  utils = new UtilsClass();

  displayedColumns = [
    'selectCheckBox',
    'UserImage',
    'Practice',
    'SubscriptionPlanImage',
    'plan',
    'price',
    'status',
    'Date',
    'isDefault',
    'freeSMS',
    'Actions',
  ];
  filters = [];

  selectedIDs = [];

  searchLabel;

  searchVals = new FormControl();

  searchValList = ['Label'];

  expandedRow: number;
  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  merchantTags = [];
  filteredSize = null;

  plans = [];

  totalCount = 0;
  planFilter;

  planID;
  customColorScheme = [];
  destroyEvent = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private subscriptionPlanService: SubscriptionPlanService,
    private dialog: MatDialog,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private customDate: CustomDatePipe,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['planID']) {
        this.planID = params['planID'];
      }

      if (this.planID) {
        this.changePlan(this.planID);
      }
    });

    const params = {};

    SideMenuService.goBack.subscribe((res) => {
      if (res === true) {
        this.hideViewDetails();
      }
    });

    this.listDB = new LoadRecords(this.subscriptionPlanService, this.destroyEvent, params);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);

    this.listDB.dataChange.subscribe((res) => {
      this.calculState(res);
    });
  }

  changePlanGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const ref = RootAppComponent.dialog.open(SubscriptionPlanUserEditComponent, {
        data: {
          merchantIDs: this.selectedIDs,
        },
        width: '650px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res) {
          ref.close();
        }
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res && res.length > 0) {
          for (let i = 0; i < res.length; i++) {
            if (
              res[i]['StartDate'] &&
              (moment().format('YYYY-MM-DD') === res[i]['StartDate'] ||
                moment().isBefore(moment(res[i]['StartDate'], 'YYYY-MM-DD')) === false)
            ) {
              this.dataSource.replaceItem = res[i];
            }
          }

          ref.close();
        }
      });
    }
  }

  changePlan(merchantPlanID = null, merchantID = null, subscriptionPlanID = null) {
    const ref = RootAppComponent.dialog.open(SubscriptionPlanUserEditComponent, {
      data: {
        merchantPlanID,
        merchantID,
        subscriptionPlanID,
      },
      width: '650px',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res) {
        ref.close();
      }
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        if (
          res['StartDate'] &&
          (moment().format('YYYY-MM-DD') === res['StartDate'] ||
            moment().isBefore(moment(res['StartDate'], 'YYYY-MM-DD')) === false)
        ) {
          this.dataSource.replaceItem = res;
        }

        ref.close();
      }
    });
  }

  changePlanFilter(d) {
    this.planFilter = d;
  }

  sync(isForceSync) {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const payload = {
        isForceSync,
        merchantIDs: this.selectedIDs,
      };
      let confirm = new ConfirmDialog(
        'sync',
        '',
        'Are you sure you want to Synchronize the selected merchants local data ?',
        'No',
        'Yes'
      );

      if (isForceSync === true) {
        confirm = new ConfirmDialog(
          'refresh',
          '',
          'Are you sure you want to SYNC the selected merchants local data ?',
          'No',
          'Yes'
        );
      }
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.subscriptionPlanService.sync(payload).subscribe((r) => {

          NotifyAppComponent.displayToast('success', 'Success!', 'Synchronizing in the background');
          ref.close();
        });
        }
      });
    }
  }

  syncMerchant(isForceSync, merchantID) {
    if (merchantID) {
      const payload = {
        isForceSync,
        merchantIDs: [merchantID],
      };

      let confirm = new ConfirmDialog(
        'sync',
        '',
        'Are you sure you want to Synchronize merchant local data ?',
        'No',
        'Yes'
      );

      if (isForceSync === true) {
        confirm = new ConfirmDialog('refresh', '', 'Are you sure you want to Reset merchant local data ?', 'No', 'Yes');
      }
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.subscriptionPlanService.syncHidden(payload).subscribe((r) => {});

          NotifyAppComponent.displayToast('success', 'Success!', 'Synchronizing in the background');
          ref.close();
        }
      });
    }
  }

  calculState(res: any[]) {
    this.totalCount = 0;
    if (res && res.length > 0) {
      let plans = uniqBy(res, 'SubscriptionPlan_key');

      if (plans && plans.length > 0) {
        this.customColorScheme = _map(plans, (item) => {
          const result: any = {};
          if (item) {
            result.name = item.SubscriptionPlan_Label;
            result.value = item.color;
          }

          return result;
        });
        plans = _map(plans, (item) => {
          if (item) {
            const l = filter(res, (e) => {
              if (e && e.SubscriptionPlan_key && e.SubscriptionPlan_key === item.SubscriptionPlan_key) {
                return true;
              }

              return false;
            });

            if (l && l.length > 0) {
              item.count = Number(l.length);
            } else {
              item.count = 0;
            }

            this.totalCount = Number(this.totalCount) + Number(item.count);
          }

          return item;
        });
      }

      this.plans = JSON.parse(JSON.stringify(plans));
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const params = {};

    this.listDB = new LoadRecords(this.subscriptionPlanService, this.destroyEvent, params);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  viewSubPlan(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(SubscriptionPlanViewModalComponent, {
        data: {
          subscriptionPlanID: id,
          displaySubscribeButton: false,
        },
        width: '1000px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res) {
          ref.close();
        }
      });
    }
  }

  getSubscriptionPlanImage(id) {
    return this.subscriptionPlanService.getPromoterPlanThumbnailImageStreamLink(id);
  }

  getUserImage(id) {
    return this.subscriptionPlanService.getPracticePicStreamLink(id);
  }

  setFilter(event, field) {
    let filter;

    if (event === true && typeof event === 'boolean') {
      filter = {
        field,
        value: '1',
      };
    } else if (event === false && typeof event === 'boolean') {
      filter = {
        field,
        value: '',
      };
    } else if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  indexOfTag(e) {
    if (this.searchLabel && Array.isArray(this.searchLabel) && e) {
      if (this.searchLabel.indexOf(e) !== -1) {
        return true;
      }
    }

    return false;
  }

  selectAll() {
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      const o = this.dataSource.filteredData[i];
      if (o && o['Merchant_key'] && this.selectedIDs.indexOf(o['Merchant_key']) === -1) {
        this.selectedIDs.push(o['Merchant_key']);
      }
    }
  }

  viewPlan(id) {
    if (id) {
      this.router.navigate([
        this.root,
        {
          outlets: {
            page: ['subscription-plan-view', id],
          },
        },
      ]);
    }
  }

  selectPage() {
    const index = this.dataSource['_paginator']['index'] || 0;
    const pageSize = this.dataSource['_paginator']['pageSize'] || 0;

    for (let i = 0; i < (index + 1) * pageSize; i++) {
      const o = this.dataSource.filteredData[index * pageSize + i];

      if (o && o['Merchant_key'] && this.selectedIDs.indexOf(o['Merchant_key']) === -1) {
        this.selectedIDs.push(o['Merchant_key']);
      }
    }
  }

  select(id, isSelected) {
    if (isSelected === true && this.selectedIDs.indexOf(id) === -1) {
      this.selectedIDs.push(id);
    } else {
      const _index = this.selectedIDs.indexOf(id);
      this.selectedIDs.splice(_index, 1);
    }
  }

  unselect() {
    this.selectedIDs = [];
  }

  isSelected(id) {
    if (!this.selectedIDs || this.selectedIDs.length <= 0) {
      return false;
    } else {
      if (this.selectedIDs.indexOf(id) !== -1) {
        return true;
      }
    }

    return false;
  }

  viewMerchant(id) {
    const ref = this.dialog.open(MerchantViewComponent, {
      data: id,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  toLowerCase(e) {
    if (e) {
      return String(e).toLocaleLowerCase();
    }

    return e;
  }

  toNumber(n) {
    if (n) {
      return Number(n);
    } else {
      return 0;
    }
  }

  isNew(date) {
    if (date) {
      const days = this.utils.daysPast(date);

      if (days < 15) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  syncSubPlans() {
    let payload = {
      isForceSync: true,
      selection: "all"
    };

    let confirm = new ConfirmDialog(
      "refresh",
      "",
      "Are you sure you want to SYNC the selected merchants local data ?",
      "No",
      "Yes"
    );

    var ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm
    });
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        this.subscriptionPlanService.sync(payload).subscribe((r) => {


          NotifyAppComponent.displayToast("success", "Success!", "Synchronizing in the background");
          ref.close();
        });
      }
    });
  }
  joinBySpace(tags) {
    if (tags && tags.length > 0) {
      return tags.join(', ');
    }
  }

  hideViewDetails() {}

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit, OnDestroy {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public dataChangeInit: BehaviorSubject<any> = new BehaviorSubject<any[]>(null);

  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  isDestoyed = false;
  serviceRef;

  constructor(private subscriptionPlanService: SubscriptionPlanService, private destroyEvent, params) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    const payload = {
      section: 0,
    };

    this.serviceRef = this.subscriptionPlanService
      .getSubscriptionPlanUserList(payload)
      .subscribe((subscriptionPlanUserList) => {
        if (subscriptionPlanUserList) {
          for (let i = 0; i < subscriptionPlanUserList.length; i++) {
            subscriptionPlanUserList[i]['fullName'] = '';
            if (subscriptionPlanUserList[i] && subscriptionPlanUserList[i]['SubscriptionPlan_Label']) {
              subscriptionPlanUserList[i]['fullName'] = String(
                subscriptionPlanUserList[i]['fullName'] + subscriptionPlanUserList[i]['SubscriptionPlan_Label']
              ).toLocaleLowerCase();
            }

            if (subscriptionPlanUserList[i] && subscriptionPlanUserList[i]['emails.Email']) {
              subscriptionPlanUserList[i]['fullName'] = String(
                subscriptionPlanUserList[i]['fullName'] + subscriptionPlanUserList[i]['emails.Email']
              ).toLocaleLowerCase();
            }
            if (subscriptionPlanUserList[i] && subscriptionPlanUserList[i]['TradingAs']) {
              subscriptionPlanUserList[i]['fullName'] = String(
                subscriptionPlanUserList[i]['fullName'] + subscriptionPlanUserList[i]['TradingAs']
              ).toLocaleLowerCase();
            }
            if (subscriptionPlanUserList[i] && subscriptionPlanUserList[i]['CalculatedName']) {
              subscriptionPlanUserList[i]['fullName'] = String(
                subscriptionPlanUserList[i]['fullName'] + subscriptionPlanUserList[i]['CalculatedName']
              ).toLocaleLowerCase();
            }

            if (subscriptionPlanUserList[i] && subscriptionPlanUserList[i]['mobiles.Number']) {
              subscriptionPlanUserList[i]['fullName'] = String(
                subscriptionPlanUserList[i]['fullName'] + subscriptionPlanUserList[i]['mobiles.Number']
              ).toLocaleLowerCase();
            }

            if (subscriptionPlanUserList[i] && subscriptionPlanUserList[i]['phones.Number']) {
              subscriptionPlanUserList[i]['fullName'] = String(
                subscriptionPlanUserList[i]['fullName'] + subscriptionPlanUserList[i]['phones.Number']
              ).toLocaleLowerCase();
            }
          }
        }
        this.count = subscriptionPlanUserList.length;
        this.items = subscriptionPlanUserList;

        this.dataChange.next(this.items.slice());
        this.firstLoad = true;

        const innerFunction = (section) => {
          const length = this.items.length;
          section = section + 1;
          UtilsClass.loadingDataSection(section);
          payload.section = section;

          this.serviceRef = this.subscriptionPlanService.getSubscriptionPlanUserList(payload).subscribe((res) => {
            if (res.length > 0) {
              for (let i = 0; i < res.length; i++) {
                res[i]['fullName'] = '';
                if (res[i] && res[i]['SubscriptionPlan_Label']) {
                  res[i]['fullName'] = String(
                    res[i]['fullName'] + res[i]['SubscriptionPlan_Label']
                  ).toLocaleLowerCase();
                }
                if (res[i] && res[i]['emails.Email']) {
                  res[i]['fullName'] = String(res[i]['fullName'] + res[i]['emails.Email']).toLocaleLowerCase();
                }
                if (res[i] && res[i]['TradingAs']) {
                  res[i]['fullName'] = String(res[i]['fullName'] + res[i]['TradingAs']).toLocaleLowerCase();
                }
                if (res[i] && res[i]['CalculatedName']) {
                  res[i]['fullName'] = String(res[i]['fullName'] + res[i]['CalculatedName']).toLocaleLowerCase();
                }

                if (res[i] && res[i]['mobiles.Number']) {
                  res[i]['fullName'] = String(res[i]['fullName'] + res[i]['mobiles.Number']).toLocaleLowerCase();
                }

                if (res[i] && res[i]['phones.Number']) {
                  res[i]['fullName'] = String(res[i]['fullName'] + res[i]['phones.Number']).toLocaleLowerCase();
                }
              }

              this.count = res.count;
              this.items = this.items.concat(res);

              this.dataChange.next(this.items.slice());
              if (this.isDestoyed !== true) {
                innerFunction(section);
              }
            } else {
              return true;
            }
          });
          UtilsClass.stopLoadingInterceptor();
        };

        if (this.isDestoyed !== true) {
          innerFunction(1);
        }
      });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';
  secondField;
  secondValue;

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this.secondField = item.secondField;
    this.secondValue = item.secondValue;
    this._filterChange.next(item.value);
  }

  set removeItem(id) {
    const data = this._tableDatabase.data.filter((row) => row.Merchant_key !== id);
    this._tableDatabase.dataChange.next(data);
  }

  set addItem(res) {
    this._tableDatabase.data.unshift(res);

    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  set replaceItem(item) {
    const _item = item;
    const _index = this._tableDatabase.data.findIndex((_obj) => _obj.Merchant_key === item.Merchant_key);

    if (_item.Color) {
      if (_item.Color.split('|')[0]) {
        _item.color = _item.Color.split('|')[0];
      }

      if (_item.Color.split('|')[1]) {
        _item.colorSecond = _item.Color.split('|')[1];
      }
    } else {
      _item.color = this._tableDatabase.data[_index].colorSecond;
      _item.colorSecond = this._tableDatabase.data[_index].colorSecond;
    }

    _item.TradingAs = this._tableDatabase.data[_index].TradingAs;
    _item['emails.Email'] = this._tableDatabase.data[_index]['emails.Email'];
    _item['phones.Number'] = this._tableDatabase.data[_index]['phones.Number'];
    _item['mobiles.Number'] = this._tableDatabase.data[_index]['mobiles.Number'];
    _item.CalculatedName = this._tableDatabase.data[_index].CalculatedName;

    this._tableDatabase.data[_index] = _item;
    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
          if (this.secondField === undefined) {
            if (this.field === 'Tags') {
              let f = '';
              f = item[this.field] || '';

              if (this.filter && this.filter.length > 0) {
                for (let j = 0; j < this.filter.length; j++) {
                  if (f.indexOf(this.filter[j]) === -1) {
                    return false;
                  }
                }

                return true;
              } else {
                return f;
              }
            } else {
              let f = '';
              f = item[this.field] || '';

              const searchStr = f.toLowerCase();

              return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
            }
          } else {
            let f = '';
            let f2 = null;

            f = item[this.field] || '';
            f2 = item[this.secondField] || null;

            const searchStr = f.toLowerCase();
            const searchStr2 = f2.toLowerCase();

            if (this.secondValue === null) {
              return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
            } else {
              return searchStr.indexOf(this.filter.toLowerCase()) !== -1 && searchStr2 === this.secondValue;
            }
          }
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'type':
          [propertyA, propertyB] = [a['type'], b['type']];
          break;

        case 'Label':
          [propertyA, propertyB] = [a['Label'], b['Label']];
          break;

        case 'Date':
          [propertyA, propertyB] = [new Date(a['created_at']).getTime(), new Date(b['created_at']).getTime()];
          break;

        case 'IsActive':
          [propertyA, propertyB] = [a['Active'], b['Active']];
          break;

        case 'applyCount':
          [propertyA, propertyB] = [a['applyCount'], b['applyCount']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
  }


  
}
