<div class="row clearfix inner-container">
  <div class="card-header primary-gradient-img clearfix inModal" [style.background]="headerBackground">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" (click)="close()">
        <mat-icon>clear</mat-icon>
      </button>

      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m">
          Book a demonstration

          <span class="subLabel"
          >Use the calendar below to select the best time for us to arrange a guided tour of our platform.</span
          >
        </h2>
      </div>
    </div>
  </div>

  <mat-dialog-content class="content-demo">
    <app-component-calendly [type]="type" (getLink)="getLink($event)"></app-component-calendly>
  </mat-dialog-content>
</div>
