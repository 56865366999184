import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

@Component({
  selector: 'app-invitation-conversion-funnel',
  templateUrl: './invitation-conversion-funnel.component.html',
  styleUrls: ['./invitation-conversion-funnel.component.css'],
})
export class InvitationConversionFunnelComponent implements OnInit {
  @Input()
  invitation;

  @Input()
  eventLogs;

  @Input()
  dateNow;

  util = new UtilsClass();

  invitationTitle = 'Invitation';
  patientCardTitle = 'Patient Card';
  patientResponseTitle = 'Patient response';
  fianceOptionsTitme = 'Finance Options';

  sessionType = 'guest';

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private UtilsService: UtilsService
  ) {
    this.authenticationService.getSessionType().subscribe((res) => {
      this.sessionType = res;

      if (this.sessionType == 'customer') {
        this.invitationTitle = 'Your Invitation';
        this.patientCardTitle = 'Your Patient Card';
        this.patientResponseTitle = 'Your  response';
        this.fianceOptionsTitme = 'Your Finance Options';
      }
    });
  }

  ngOnInit() {}

  // helpEmailStatus(){
  //   // open modal with help info

  //   let ref = RootAppComponent.dialog.open(WikiViewComponent, {
  //     data: {

  //     },
  //     width: '800px'
  //   });

  //   ref.componentInstance.closeModal.subscribe(res => {
  //     ref.close();
  //   })
  // }
  openCampaign(id) {
    this.router.navigate(['/merchant', { outlets: { page: ['marketing-campaign-view', id] } }]);
  }
}
