<div class="card-header primary-gradient-img clearfix inModal">
  <div class="row clearfix">
    <!-- merchant calculated name -->
    <div class="row text-left clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear mat-button white" (click)="close()">
        <mat-icon>clear</mat-icon>
      </button>
      <h2 class="summary-header rm-m">
        Add payment
        <span class="subLabel">Direct payment - To account for a payment received directly, outside of ePremium</span>
      </h2>
    </div>
  </div>
</div>

<mat-dialog-content>
  <div class="row clearfix">
    <div class="row clearfix flex pad-top">
      <!-- payment date - date to apply payment (default to today)  -->
      <app-date-input class="full-width" [label]="'Payment date'"></app-date-input>

      <!-- method select - - Direct Deposit, Credit Card, Direct Debit -->
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Method</mat-label>
        <mat-select placeholder="Method" name="method">
          <mat-option> Please select</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="row clearfix flex">
      <!-- account - selectable from bank accounts on file -->
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Account</mat-label>
        <mat-select placeholder="Account" name="account">
          <mat-option> Please select</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Optional record number or reference -->
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Reference </mat-label>
        <input matInput placeholder="Reference "/>
      </mat-form-field>
    </div>

    <!-- If a credit card a MSF fee will apply so you need to calculate the fee based on the card type and rate from EP then add this to the total paid. -->
    <div class="row clearfix flex">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Fee</mat-label>
        <input matInput placeholder="Fee"/>
      </mat-form-field>

      <!-- This will default to the deposit and first payment amount -->
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Amount</mat-label>
        <input matInput placeholder="Amount"/>
      </mat-form-field>
    </div>

    <div class="row clearfix flex">
      <div class="full-width">
        <p class="small">The payment total is calculated from the payment amount and any fees applicable.</p>
      </div>

      <!-- Total - Amount + Fee -->
      <mat-form-field class="full-width add-ml" appearance="outline">
        <mat-label>Total</mat-label>
        <input matInput placeholder="Total"/>
      </mat-form-field>
    </div>
  </div>

  <hr/>

  <div class="row clearfix button-row">
    <button (click)="close()" mat-raised-button color="primary">Cancel</button>
    <button mat-raised-button color="accent">Confirm</button>
  </div>
</mat-dialog-content>
