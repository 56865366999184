import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reverseArray',
})
export class reversePipe implements PipeTransform {
  constructor() {}

  transform(expression: any) {
    try {
      const result = [];

      if (Array.isArray(expression) ) {


        return expression.reverse();
      } else {
        return expression;
      }
    } catch (e) {
      return expression;
    }
  }
}
