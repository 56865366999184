<mat-card class="rel clearfix">
  <mat-dialog-content>
    <mat-card class="rel supplier-card clearfix mb" *ngIf="customer">
      <mat-dialog-content class="stacked-card-view">
        <form class="form-container contact-form" #difApplyForm="ngForm">
          <div class="card-header primary-gradient-img clearfix stacked">
            <div class="row clearfix">
              <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
                <mat-icon>clear</mat-icon>
              </button>
              <div class="row titleArea clearfix">
                <app-customer-prospect-view-picture
                  class="app-create"
                  [patientID]="customerID"
                  [LastModified]="LastModified"
                >
                </app-customer-prospect-view-picture>
                <h2 class="summary-header rm-m">
                  {{ title }} {{ firstName }} {{ lastName }}
                  <span class="subLabel">{{ description }}</span>
                </h2>
              </div>
            </div>
          </div>

          <div class="full-width mb clearfix row">
            <div class="clearfix full-width mb">
              <app-date-input
                class="full-width"
                [isTime]="true"
                [minDate]="minDate"
                (selectedDate)="selectDateTime($event)"
                label="Book Date"
              >
              </app-date-input>
            </div>
            <div class="row clearfix" *ngIf="types && types.length > 0">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Reason for visiting</mat-label>
                <mat-select [(ngModel)]="type" (ngModelChange)="addSubType()" name="bestContactTimetypess" required>
                  <mat-option class="label2" *ngFor="let b of types" [value]="b">
                    {{ b.Label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <p class="clearfix full-width small no-margin" *ngIf="type && type.Label2">
                {{ replaceAllString(type.Label2) }}
              </p>
            </div>
            <div class="row clearfix" *ngIf="subTypes && subTypes.length > 0">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>More details</mat-label>
                <mat-select
                  [(ngModel)]="subType"
                  (ngModelChange)="subTypeSelect()"
                  name="bestContactTimetypesubTypes"
                  required
                >
                  <mat-option class="label2" *ngFor="let b of subTypes" [value]="b">
                    {{ b.Label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <p class="clearfix full-width small no-margin" *ngIf="subType && subType.Label2">
                {{ replaceAllString(subType.Label2) }}
              </p>
            </div>

            <div class="row clearfix flex">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Best appointment time for the {{"KEYWORD.patient" | translate}}</mat-label>
                <mat-select [(ngModel)]="bestContactTime" name="bestContactTime">
                  <mat-option *ngFor="let b of bestContactTimes" [value]="b.Code">
                    {{ b.Label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="clearfix full-width mb mt text-center flex">
              <app-date-input
                class="full-width"
                [isTime]="true"
                [minDate]="minDate"
                (selectedDate)="selectDateTime($event)"
                label="Book Date and Time"
              >
              </app-date-input>
            </div>

            <div class="clearfix full-width mb mt text-center">
              <app-date-input
                class="full-width"
                [defaultDate]="defaultBookEndDate"
                [defaultTime]="defaultBookEndTime"
                [isTime]="true"
                [minDate]="minDate"
                (selectedDate)="selectDateTimeSecond($event)"
                label="Appointment end at"
              >
              </app-date-input>
            </div>

            <div class="clearfix full-width mb flex">
              <mat-slide-toggle class="full-width" [(ngModel)]="smsConfirmAppointment" name="smsConfirmAppointment">
                Send SMS to confirm the appointment
              </mat-slide-toggle>

              <mat-slide-toggle class="full-width" [(ngModel)]="smsAppointmentReminder" name="smsAppointmentReminder">
                {{"PARAGRAPH.Feature Appointment Create SMS Reminder" | translate}}
              </mat-slide-toggle>
            </div>

            <hr class="mb" />

            <div class="row clearfix" *ngIf="dentists && dentists.length > 0">
              <mat-form-field class="clearfix full-width mb text-input" appearance="outline">
                <mat-label>Assign a {{"KEYWORD.dentist" | translate}} to this appointment</mat-label>

                <mat-select
                  class="full-width"
                  [(ngModel)]="dentist"
                  name="optionValue"
                  [placeholder]="'Assign a ' + ('KEYWORD.dentist' | translate) + ' to this appointment'"
                >
                  <mat-option *ngFor="let o of dentists | OrderArrayObject: 'CalculatedName'" [value]="o.ID">
                    {{ o['CalculatedName'] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="row clearfix">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label> A note to be sent to the {{"KEYWORD.patient" | translate}} </mat-label>
                <textarea
                  [(ngModel)]="comment"
                  name="comments"
                  matInput
                  placeholder="Please enter your thoughts or comments here..."
                ></textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="drop-buttons">
            <button class="mr btn-large" (click)="closeEvent()" mat-raised-button>Cancel</button>
            <button
              class="btn-large"
              [disabled]="difApplyForm.invalid || !bookDate"
              (click)="book()"
              color="accent"
              mat-raised-button
            >
              Submit
              <mat-icon>chevron_right </mat-icon>
            </button>
          </div>
        </form>
      </mat-dialog-content>
    </mat-card>
  </mat-dialog-content>
</mat-card>
