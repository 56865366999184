/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./invitation-modification.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./invitation-modification.component";
var styles_InvitationModificationComponent = [i0.styles];
var RenderType_InvitationModificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InvitationModificationComponent, data: {} });
export { RenderType_InvitationModificationComponent as RenderType_InvitationModificationComponent };
export function View_InvitationModificationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["invitation-modification works!"]))], null, null); }
export function View_InvitationModificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-invitation-modification", [], null, null, null, View_InvitationModificationComponent_0, RenderType_InvitationModificationComponent)), i1.ɵdid(1, 114688, null, 0, i2.InvitationModificationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InvitationModificationComponentNgFactory = i1.ɵccf("app-invitation-modification", i2.InvitationModificationComponent, View_InvitationModificationComponent_Host_0, {}, {}, []);
export { InvitationModificationComponentNgFactory as InvitationModificationComponentNgFactory };
