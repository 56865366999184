import { NgModule } from '@angular/core';
import { EditorModule } from '@tinymce/tinymce-angular';
import { SharedModule } from '../../shared/shared.module';
import { OperatorModule } from '../operator/operator.module';
import { DesktopPreviewComponent } from './desktop-preview/desktop-preview.component';
import { MessageInputComponent } from './message-input/message-input.component';
import { MessageListGlobalComponent } from './message-list-global/message-list-global.component';
import { MessageListOverviewComponent } from './message-list-overview/message-list-overview.component';
import { MessageListComponent } from './message-list/message-list.component';
import { MessageTimelineComponent } from './message-timeline/message-timeline.component';
import { MessageViewComponent } from './message-view/message-view.component';
import { MessageRoutingModule } from './shared/message-routing.module';
import { MessageService } from './shared/message.service';
import { SmsConversationComponent } from './sms-conversation/sms-conversation.component';
import { SmsPreviewComponent } from './sms-preview/sms-preview.component';
import { SmsPromoterComponent } from './sms-promoter/sms-promoter.component';
import { SmsSendComponent } from './sms-send/sms-send.component';
import { SmsStatisticsComponent } from './sms-statistics/sms-statistics.component';
import { TextMessageCreateComponent } from './text-message-create/text-message-create.component';
import { MessageGroupCreateComponent } from './message-group-create/message-group-create.component';
import { MarketingFilterModule } from '../marketing-filter/marketing-filter.module';
import { CommonModule } from '@angular/common';
import { NgPipesModule } from 'ngx-pipes';
import { MomentModule } from 'ngx-moment';
import { CdkTableModule } from '@angular/cdk/table';
import { ClipboardModule } from 'ngx-clipboard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArchwizardModule } from 'angular-archwizard';

import { MatSortModule } from '@angular/material';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { NgxGaugeModule } from 'ngx-gauge';
import { MessageGroupCardListComponent } from './message-group-card-list/message-group-card-list.component';
import { MessageGroupViewComponent } from './message-group-view/message-group-view.component';
import { MessageGroupLocalListComponent } from './message-group-local-list/message-group-local-list.component';
import { MessageGroupListComponent } from './message-group-list/message-group-list.component';
import { MessageGroupListOverviewComponent } from './message-group-list-overview/message-group-list-overview.component';
import { CardModule } from '../card/card.module';
import { PatientPersonalSMSComponent } from './patient-personal-sms/patient-personal-sms.component';

@NgModule({
  imports: [
    CommonModule,
    NgPipesModule,
    MomentModule,
    CdkTableModule,
    ClipboardModule,
    FormsModule,
    ArchwizardModule,
    ReactiveFormsModule,
    MatSortModule,
    PinchZoomModule,
    NgxGaugeModule,
    SharedModule,
    OperatorModule,
    EditorModule,
    MessageRoutingModule,
    MarketingFilterModule,
    CardModule
  ],
  declarations: [
    MessageTimelineComponent,
    MessageViewComponent,
    TextMessageCreateComponent,
    MessageListComponent,
    MessageListGlobalComponent,
    MessageListOverviewComponent,
    SmsConversationComponent,
    SmsPreviewComponent,
    SmsSendComponent,
    SmsStatisticsComponent,
    SmsPromoterComponent,
    MessageInputComponent,
    DesktopPreviewComponent,
    MessageGroupCreateComponent,
    MessageGroupCardListComponent,
    MessageGroupViewComponent,
    MessageGroupLocalListComponent,
    MessageGroupListComponent,
    MessageGroupListOverviewComponent,
    PatientPersonalSMSComponent
  ],
  exports: [
    MessageTimelineComponent,
    MessageViewComponent,
    TextMessageCreateComponent,
    SmsConversationComponent,
    SmsPreviewComponent,
    SmsSendComponent,
    SmsStatisticsComponent,
    SmsPromoterComponent,
    MessageInputComponent,
    DesktopPreviewComponent,
    MessageGroupCreateComponent,
    MessageGroupCardListComponent,
    MessageGroupViewComponent,
    MessageGroupLocalListComponent,
    MessageGroupListComponent,
    MessageGroupListOverviewComponent,
    PatientPersonalSMSComponent
  ],
  entryComponents: [],
  providers: [MessageService],
})
export class MessageModule {}
