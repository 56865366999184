import { animate, group, keyframes, query, stagger, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import hexRgb from 'hex-rgb';
import * as _ from 'lodash';
import { ClipboardService } from 'ngx-clipboard';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ColorPalette } from '../../../shared/types/color-palette';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { FeatureMainLabelQuickEditComponent } from '../feature-main-label-quick-edit/feature-main-label-quick-edit.component';
import { FeatureSubLabelQuickEditComponent } from '../feature-sub-label-quick-edit/feature-sub-label-quick-edit.component';
import { SubscriptionPlanService } from '../shared/subscription-plan.service';

@Component({
  selector: 'app-feature-sub-label-list',
  templateUrl: './feature-sub-label-list.component.html',
  styleUrls: ['./feature-sub-label-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 0 })),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),

        group([animate(300, style({ height: 0 })), animate('200ms ease-in-out', style({ opacity: '0' }))]),
      ]),
      transition(':enter', [
        style({ height: '0', opacity: 0 }),

        group([animate(300, style({ height: '*' })), animate('400ms ease-in-out', style({ opacity: '1' }))]),
      ]),
    ]),
  ],
})
export class FeatureSubLabelListComponent implements OnInit {
  @Input()
  subFeatureLabels = [];
  @Input()
  mainFeatureLabelID;
  @Input()
  mainFeatureLabel;
  @Input()
  readOnly = false;
  @Input()
  canDragAndDrop = true;
  @Input()
  canSelect = false;
  @Input()
  displayOwn = false;
  @Input()
  windowControl = true;
  @Input()
  maxText = 500;

  @Input()
  displayFilter = true;
  @Input()
  canSelectMain = false;
  @Input()
  displayHeader = true;
  @Input()
  displayEmpty = true;
  @Input()
  color = Settings.global['primaryColor'] || '#1e88e5';
  @Input()
  colorSecond;
  sessionType;
  @Input()
  icon;
  @Input()
  defaultSelectedIDs = [];
  @Input()
  defaultSelectedLimitedIDs = [];
  @Input()
  mainAction = true;
  @Input()
  hideMoreButton = false;
  @Input()
  accessOnTitle = 'Included';
  @Input()
  accessLimitTitle = 'Limited';
  @Input()
  accessOffTitle = 'Not Included';

  @Input()
  accessOnTooltip = 'This feature is Inluded in this subscription plan';
  @Input()
  accessLimitTooltip = 'This feature is Inluded with Limited access in this subscription plan';
  @Input()
  accessOffTooltip = 'This feature is NOT Inluded in this subscription plan';

  @Input()
  displayCover = true;

  @Input()
  canHideDisplay = true;

  @Input()
  bottomNavigation = false;

  @Input()
  allView = true;

  @Input()
  hideDisplayCheck = true;
  selectedLimitedIDs = [];
  selectedIDs = [];
  selectMainCheck;
  @Output()
  getSelectedIDs = new EventEmitter();
  @Output()
  getSelectedLimitedIDs = new EventEmitter();
  @Output()
  getRemove = new EventEmitter();

  @Output()
  getSaveOrder = new EventEmitter();

  @Input()
  selectMain = new EventEmitter();

  @Input()
  displaySaveButton = true;
  root = '/merchant';
  @Input()
  saveOrder = new EventEmitter();

  @Input()
  colapse = new EventEmitter();
  postPictureThumbnail;
  mainActive = true;

  loading = true;
  searchFilter = null;
  mainLabel;
  mainDescription;
  disableSaveButton = true;
  @Input()
  isCollapsed = true;

  isImageError = false;
  postCover;
  postCoverURL;

  isModalView = false;
  @Output()
  close = new EventEmitter();

  @Output()
  getIsSelectionChange = new EventEmitter();

  isChanged = false;

  utils = new UtilsClass();

  isConsumer = null;
  isMerchant = null;

  @Input()
  featureType = 'all';

  openedSubFeature = {};

  selectPrevious;
  selectNext;

  nextFeature;
  standAlone = false;
  previousFeature;
  context = Settings.global['context']
  itemsFooter = ['plans-overview', 'features', 'free', 'demo'];
  htmlContentObject = {};
  htmlContentLoader = {};
  @Input()
  openFirst = false;
  isPromoterOrAdmin = false;
  mainFeatureLabelCode;
  controlCollapse = null;
  isMainPrivate = false;
  isMainConsumer = false;
  isMainSideMenu = false;
  isMainMerchant = false;
  isMarketingOnly = false;

  isEmbeded = true;
  settings = Settings.global;
  publicSiteLink;
  mainAppLink;

  constructor(
    private subscriptionPlanService: SubscriptionPlanService,
    private authenticationService: AuthenticationService,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private _clipboardService: ClipboardService,
    private activeRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.mainFeatureLabelID) {
        this.mainFeatureLabelID = data.mainFeatureLabelID;
        this.isModalView = true;
      }
    }
  }

  ngOnInit() {
    if (this.settings && this.settings['publicSiteFrontendLink']) {
      this.publicSiteLink = this.settings['publicSiteFrontendLink'];
    }
    if (this.settings && this.settings['merchantFrontendLink']) {
      this.mainAppLink = this.settings['merchantFrontendLink'];
    }
    this.authenticationService.isPromoterOrAdmin().subscribe((r) => {
      this.isPromoterOrAdmin = r || false;
    });

    this.activeRoute.params.subscribe((params) => {
      if (params['featureType']) {
        this.featureType = params['featureType'];
      }

      if (params['mainCode']) {
        this.mainFeatureLabelCode = params['mainCode'];
        this.isCollapsed = false;
        this.canHideDisplay = false;
        this.canSelect = false;
        this.canSelectMain = false;
        this.allView = false;
        this.canDragAndDrop = false;
        this.bottomNavigation = true;
        this.hideMoreButton = true;
        this.selectPrevious = true;
        this.isEmbeded = false;
        this.selectNext = true;
        this.displayEmpty = false;
        this.openFirst = true;
        this.standAlone = true;
        UtilsClass.scrollUp();
      } else if (params['mainID']) {
        this.mainFeatureLabelID = params['mainID'];
        this.isCollapsed = false;
        this.canHideDisplay = false;
        this.canSelect = false;
        this.canSelectMain = false;
        this.allView = false;
        this.canDragAndDrop = false;
        this.bottomNavigation = true;
        this.hideMoreButton = true;
        this.selectPrevious = true;
        this.isEmbeded = false;
        this.selectNext = true;
        this.displayEmpty = false;
        this.openFirst = true;
        this.standAlone = true;
        UtilsClass.scrollUp();
      }

      if (this.isModalView == true) {
        this.canSelect = false;
        this.canSelectMain = false;
        this.canDragAndDrop = false;
        this.readOnly = true;
        this.canHideDisplay = false;
        this.isCollapsed = false;
        this.displayHeader = true;
      }
      this.authenticationService.getSessionType().subscribe((st) => {
        this.sessionType = st;

        if (this.sessionType != 'admin' && this.sessionType != 'promoter') {
          this.readOnly = true;
        }
        this.setup();
      });

      this.colapse.subscribe((res) => {
        this.hideDisplayCheck = res;
      });
      this.saveOrder.subscribe((res) => {
        if (this.displaySaveButton != true) {
          this.saveOrderEvent();
        } else {
          this.getSaveOrder.emit(true);
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.subFeatureLabels &&
      JSON.stringify(changes.subFeatureLabels.previousValue) != JSON.stringify(changes.subFeatureLabels.currentValue)
    ) {
      this.authenticationService.getSessionType().subscribe((st) => {
        this.sessionType = st;

        if (this.sessionType != 'admin' && this.sessionType != 'promoter') {
          this.readOnly = true;
        }
        this.setup();
      });
    } else if (
      changes.mainFeatureLabel &&
      JSON.stringify(changes.mainFeatureLabel.previousValue) != JSON.stringify(changes.mainFeatureLabel.currentValue)
    ) {
      this.authenticationService.getSessionType().subscribe((st) => {
        this.sessionType = st;

        if (this.sessionType != 'admin' && this.sessionType != 'promoter') {
          this.readOnly = true;
        }
        this.setup();
      });
    } else if (
      changes.mainFeatureLabelID &&
      JSON.stringify(changes.mainFeatureLabelID.previousValue) !=
        JSON.stringify(changes.mainFeatureLabelID.currentValue)
    ) {
      this.authenticationService.getSessionType().subscribe((st) => {
        this.sessionType = st;

        if (this.sessionType != 'admin' && this.sessionType != 'promoter') {
          this.readOnly = true;
        }
        this.setup();
      });
    }
    if (
      changes.defaultSelectedIDs &&
      JSON.stringify(changes.defaultSelectedIDs.previousValue) !=
        JSON.stringify(changes.defaultSelectedIDs.currentValue)
    ) {
      this.buildSelectedItems();
    }

    if (
      changes.defaultSelectedLimitedIDs &&
      JSON.stringify(changes.defaultSelectedLimitedIDs.previousValue) !=
        JSON.stringify(changes.defaultSelectedLimitedIDs.currentValue)
    ) {
      this.buildSelectedItems();
    }
  }

  onLoad() {
    this.isImageError = false;
  }

  onError() {
    this.isImageError = true;
  }

  setup() {
    if (this.featureType === 'consumer') {
      this.root = '/consumer';
    } else {
      this.root = '/merchant';
    }
    if (this.featureType === 'consumer') {
      this.isConsumer = true;
      this.isMerchant = null;
    } else if (this.featureType === 'merchant') {
      this.isConsumer = null;
      this.isMerchant = true;
    } else {
      this.isConsumer = null;
      this.isMerchant = null;
    }

    this.loading = false;
    if (this.mainFeatureLabelCode && !this.mainFeatureLabel) {
      this.subscriptionPlanService
        .getFeatureMainLabelDetailsByCode(
          this.mainFeatureLabelCode,
          {
            isConsumer: this.isConsumer,
            isMerchant: this.isMerchant,
            selectNext: this.selectNext,
            selectPrevious: this.selectPrevious,
          },
          this.sessionType
        )
        .subscribe((res) => {
          if (res && res.ID) {
            this.mainFeatureLabel = res;
            this.mainFeatureLabelID = res.ID;
            this.buildMainItem();
            this.buildSelectedItems();

            if (
              this.mainFeatureLabelID &&
              (!this.subFeatureLabels || (this.subFeatureLabels && this.subFeatureLabels.length <= 0))
            ) {
              const p = {
                mainFeatureLabelID: this.mainFeatureLabelID,
                isConsumer: this.isConsumer,
                isMerchant: this.isMerchant,
              };
              this.subscriptionPlanService.getFeatureSubLabelList(p, this.sessionType).subscribe((res) => {
                if (res) {
                  this.subFeatureLabels = res;
                }
                this.buildSelectedItems();
                this.loading = true;
              });
            }
          }
        });
    } else {
      if (this.mainFeatureLabelID && !this.mainFeatureLabel) {
        this.subscriptionPlanService
          .getFeatureMainLabelDetails(
            this.mainFeatureLabelID,
            {
              isConsumer: this.isConsumer,
              isMerchant: this.isMerchant,
              selectNext: this.selectNext,
              selectPrevious: this.selectPrevious,
            },
            this.sessionType
          )
          .subscribe((res) => {
            this.mainFeatureLabel = res;

            this.buildMainItem();
            this.buildSelectedItems();
          });
      } else if (this.mainFeatureLabel && this.mainFeatureLabel.ID) {
        this.buildMainItem();
        this.buildSelectedItems();
      } else {
        if (this.color && !this.colorSecond) {
          this.onColorPickerClose(this.color);
        }
      }
      if (
        this.mainFeatureLabelID &&
        (!this.subFeatureLabels || (this.subFeatureLabels && this.subFeatureLabels.length <= 0))
      ) {
        const p = {
          mainFeatureLabelID: this.mainFeatureLabelID,
          isConsumer: this.isConsumer,
          isMerchant: this.isMerchant,
        };
        this.subscriptionPlanService.getFeatureSubLabelList(p, this.sessionType).subscribe((res) => {
          if (res) {
            this.subFeatureLabels = res;
          }
          this.buildSelectedItems();
          this.loading = true;
        });
      } else if (
        this.mainFeatureLabel &&
        this.mainFeatureLabel.ID &&
        (!this.subFeatureLabels || (this.subFeatureLabels && this.subFeatureLabels.length <= 0))
      ) {
        const p = {
          mainFeatureLabelID: this.mainFeatureLabel.ID,
          isConsumer: this.isConsumer,
          isMerchant: this.isMerchant,
        };
        this.subscriptionPlanService.getFeatureSubLabelList(p, this.sessionType).subscribe((res) => {
          if (res) {
            this.subFeatureLabels = res;
          }
          this.buildSelectedItems();
          this.loading = true;
        });
      } else if (this.subFeatureLabels && this.subFeatureLabels.length > 0) {
        this.buildSelectedItems();
        this.loading = true;
      } else {
        this.loading = true;
      }
    }
  }

  collapseEvent(d) {
    this.controlCollapse = d;
  }

  buildMainItem() {
    if (this.mainFeatureLabel && this.mainFeatureLabel.ID) {
      this.postPictureThumbnail = this.subscriptionPlanService.getFeatureMainLabelThumbnailImageStreamLink(
        this.mainFeatureLabel.ID,
        this.mainFeatureLabel.LastModified
      );
    }
    if (this.mainFeatureLabel['Icon']) {
      this.icon = this.mainFeatureLabel['Icon'];
    }
    if (this.mainFeatureLabel['Is_Deactivated'] == '1') {
      this.mainActive = false;
    } else {
      this.mainActive = true;
    }

    if (this.mainFeatureLabel['next']) {
      this.nextFeature = this.mainFeatureLabel['next'];
    }

    if (this.mainFeatureLabel['Is_Consumer_Feature'] == '1') {
      this.isMainConsumer = true;
    } else {
      this.isMainConsumer = false;
    }

    if (this.mainFeatureLabel['Is_Merchant_Feature'] == '1') {
      this.isMainMerchant = true;
    } else {
      this.isMainMerchant = false;
    }

    if (this.mainFeatureLabel['Is_Private'] == '1') {
      this.isMainPrivate = true;
    } else {
      this.isMainPrivate = false;
    }

    if (this.mainFeatureLabel['Is_Side_Menu'] == '1') {
      this.isMainSideMenu = true;
    } else {
      this.isMainSideMenu = false;
    }

    if (this.mainFeatureLabel['Is_Marketing_Only'] == '1') {
      this.isMarketingOnly = true;
    } else {
      this.isMarketingOnly = false;
    }

    if (this.mainFeatureLabel['previous']) {
      this.previousFeature = this.mainFeatureLabel['previous'];
    }
    if (this.mainFeatureLabel['MainLabel']) {
      this.mainLabel = this.mainFeatureLabel['MainLabel'];
    }
    if (this.mainFeatureLabel['Description']) {
      this.mainDescription = this.mainFeatureLabel['Description'];
    }
    if (this.mainFeatureLabel['Color']) {
      const split = this.mainFeatureLabel['Color'].split('|');
      if (split[0]) {
        this.color = split[0];
      }
      if (split[1]) {
        this.colorSecond = split[1] || null;
      }
      if (this.color && !this.colorSecond) {
        this.onColorPickerClose(this.color);
      }
    }

    this.postCover = this.subscriptionPlanService.getFeatureMainLabelCoverImageStreamLink(
      this.mainFeatureLabel.ID,
      this.mainFeatureLabel.LastModified
    );

    this.postCoverURL = this.domSanitizer.bypassSecurityTrustStyle('url(' + this.postCover + ')');
  }

  delete(id) {
    if (id) {
      const confirm = new ConfirmDialog(
        'delete',
        '',
        'Are you sure you want to delete this sub feature label ?',
        'No',
        'Yes'
      );
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.subscriptionPlanService.deleteFeatureSubLabel(id).subscribe((res) => {
            if (res) {
              const index = this.subFeatureLabels.findIndex((item) => {
                if (item && item.ID && id && item.ID == id) {
                  return true;
                }
                return false;
              });
              if (index != -1) {
                this.subFeatureLabels.splice(index, 1);
                this.subFeatureLabels = JSON.parse(JSON.stringify(this.subFeatureLabels));
              }
              NotifyAppComponent.displayToast('success', 'Success!', 'Sub feature label is removed ');
              ref.close();
            }
          });
        }
      });
    }
  }

  buildMainFeature() {
    if (this.mainFeatureLabel) {
      if (this.mainFeatureLabel['Color']) {
        this.color = this.mainFeatureLabel.Color;
        this.onColorPickerClose(this.color);
      }
      if (this.mainFeatureLabel['Icon']) {
        this.icon = this.mainFeatureLabel.Icon;
      }
    }
  }

  onColorPickerClose(color: string): void {
    if (color) {
      this.color = color;

      const v = new ColorPalette(color);
      const palette = v.getPalette();

      if (palette && palette.length > 0) {
        const index = palette.findIndex((item) => {
          if (item && item.name == 800) {
            return true;
          } else {
            return false;
          }
        });

        if (palette[index] && palette[index]['hex']) {
          this.colorSecond = palette[index]['hex'];
        }
      }
    }
  }

  edit(id) {
    if (this.mainFeatureLabel && this.mainFeatureLabel.ID && id) {
      this.router.navigate([
        this.root,
        { outlets: { page: ['sub-feature-edit-create', this.mainFeatureLabel.ID, id] } },
      ]);
    }
  }

  create() {
    if (this.mainFeatureLabel && this.mainFeatureLabel.ID) {
      this.router.navigate([this.root, { outlets: { page: ['sub-feature-edit-create', this.mainFeatureLabel.ID] } }]);
    }
  }

  selectSubLabel(id) {
    this.isChanged = true;
    if (this.canSelect == true) {
      if (id && this.selectedIDs && this.selectedIDs.length > 0 && this.selectedIDs.indexOf(id) === -1) {
        this.selectedIDs.push(id);
        this.selectedIDs = _.uniq(this.selectedIDs);
      } else if (id && this.selectedIDs && this.selectedIDs.length > 0 && this.selectedIDs.indexOf(id) !== -1) {
        const index = this.selectedIDs.indexOf(id);
        const indexLimited = this.selectedLimitedIDs.indexOf(id);

        if (index != -1) {
          this.selectedIDs.splice(index, 1);
          this.selectedIDs = _.uniq(this.selectedIDs);
        }

        if (indexLimited != -1) {
          this.selectedLimitedIDs.splice(indexLimited, 1);
          this.selectedLimitedIDs = _.uniq(this.selectedLimitedIDs);
        }
      } else if (id && this.selectedIDs && this.selectedIDs.length <= 0) {
        this.selectedIDs.push(id);
        this.selectedIDs = _.uniq(this.selectedIDs);
      }
      let allIDs = _.map(this.subFeatureLabels, (item) => {
        if (item && item.ID) {
          return item.ID;
        }
        return null;
      });
      allIDs = _.filter(allIDs, (item) => {
        if (item) {
          return true;
        }
        return false;
      });
      allIDs = _.uniq(allIDs);
      if (allIDs && this.selectedIDs && allIDs.length == this.selectedIDs.length) {
        this.selectMainCheck = true;
      } else {
        this.selectMainCheck = false;
      }
    }
    this.selectResult();
  }

  selectSubLabelLimited(id) {
    this.isChanged = true;
    if (this.canSelect == true) {
      if (
        id &&
        this.selectedLimitedIDs &&
        this.selectedLimitedIDs.length > 0 &&
        this.selectedLimitedIDs.indexOf(id) === -1
      ) {
        this.selectedLimitedIDs.push(id);
        this.selectedLimitedIDs = _.uniq(this.selectedLimitedIDs);
      } else if (
        id &&
        this.selectedLimitedIDs &&
        this.selectedLimitedIDs.length > 0 &&
        this.selectedLimitedIDs.indexOf(id) !== -1
      ) {
        const index = this.selectedLimitedIDs.indexOf(id);
        this.selectedLimitedIDs.splice(index, 1);
        this.selectedLimitedIDs = _.uniq(this.selectedLimitedIDs);
      } else if (id && this.selectedLimitedIDs && this.selectedLimitedIDs.length <= 0) {
        this.selectedLimitedIDs.push(id);
        this.selectedLimitedIDs = _.uniq(this.selectedLimitedIDs);
      }
      let allIDs = _.map(this.subFeatureLabels, (item) => {
        if (item && item.ID) {
          return item.ID;
        }
        return null;
      });
      allIDs = _.filter(allIDs, (item) => {
        if (item) {
          return true;
        }
        return false;
      });
      allIDs = _.uniq(allIDs);
    }
    this.selectResult();
  }

  isSelected(id) {
    if (id && this.selectedIDs && this.selectedIDs.length > 0 && this.selectedIDs.indexOf(id) !== -1) {
      return true;
    }
    return false;
  }

  isSelectedLimited(id) {
    if (
      id &&
      this.selectedLimitedIDs &&
      this.selectedLimitedIDs.length > 0 &&
      this.selectedLimitedIDs.indexOf(id) !== -1
    ) {
      return true;
    }
    return false;
  }

  isIndexOf(s, attribute) {
    if (s && attribute && s.indexOf(attribute) != -1) {
      return true;
    }
    return false;
  }

  selectAll() {
    this.isChanged = true;
    if (this.subFeatureLabels && this.subFeatureLabels.length > 0) {
      let selectIDs = _.map(this.subFeatureLabels, (item) => {
        if (item && item.ID) {
          return item.ID;
        }
        return null;
      });
      selectIDs = _.filter(selectIDs, (item) => {
        if (item) {
          return true;
        }
        return false;
      });
      selectIDs = _.uniq(selectIDs);
      this.selectedIDs = selectIDs;
    }
    this.selectMainCheck = true;
    this.selectResult();
  }

  unselectAll() {
    this.isChanged = true;
    this.selectedIDs = [];
    this.selectMainCheck = false;
    this.selectResult();
  }

  canHideDisplayEvent(d) {
    this.hideDisplayCheck = d;
  }

  buildSelectedItems() {
    if (this.subFeatureLabels && this.subFeatureLabels.length > 0) {
      _.map(this.subFeatureLabels, (item) => {
        if (item && item.ID && item.Html_Content) {
          this.htmlContentObject[item.ID] = item.Html_Content;
        } else {
          this.htmlContentObject[item.ID] = null;
        }
      });

      if (this.defaultSelectedIDs && this.defaultSelectedIDs.length > 0) {
        let _defaultSelectedIDs = JSON.parse(JSON.stringify(this.defaultSelectedIDs || []));
        let _defaultSelectedLimitedIDs = JSON.parse(JSON.stringify(this.defaultSelectedLimitedIDs || []));

        let _ids = _.map(this.subFeatureLabels, (item) => {
          if (item && item.ID) {
            return item.ID;
          }
          return null;
        });
        _ids = _.filter(_ids, (item) => {
          if (item) {
            return true;
          }
          return false;
        });

        _defaultSelectedIDs = _.filter(_defaultSelectedIDs, (item) => {
          if (item && _ids.indexOf(item) !== -1) {
            return true;
          }
          return false;
        });

        _defaultSelectedLimitedIDs = _.filter(_defaultSelectedLimitedIDs, (item) => {
          if (item && _ids.indexOf(item) !== -1) {
            return true;
          }
          return false;
        });

        if (_defaultSelectedIDs && _defaultSelectedIDs.length > 0) {
          this.selectedIDs = _defaultSelectedIDs;
        }
        if (_defaultSelectedLimitedIDs && _defaultSelectedLimitedIDs.length > 0) {
          this.selectedLimitedIDs = _defaultSelectedLimitedIDs;
        }

        if (_ids && this.selectedIDs && _ids.length == this.selectedIDs.length) {
          this.selectMainCheck = true;
        } else {
          this.selectMainCheck = false;
        }
      }

      this.selectResult();
    }
  }

  selectMainEvent(selectMainCheck) {
    this.selectMainCheck = selectMainCheck;
    if (this.canSelectMain == true) {
      if (this.selectMainCheck == true) {
        this.selectAll();
      } else if (this.selectMainCheck == false) {
        this.unselectAll();
      }
    }
  }

  drop(event) {
    if (this.subFeatureLabels && this.subFeatureLabels.length > 0) {
      const currentList = _.orderBy(this.subFeatureLabels, ['Display_Order']);
      const currentIndex = event.currentIndex;
      const previousIndex = event.previousIndex;
      const item = currentList[previousIndex];
      currentList.splice(previousIndex, 1);
      currentList.splice(currentIndex, 0, item);
      for (let i = 0; i < currentList.length; i++) {
        currentList[i]['Display_Order'] = i + 1;
      }
      this.subFeatureLabels = JSON.parse(JSON.stringify(currentList));
      this.disableSaveButton = false;
    }
  }

  saveOrderEvent() {
    if (this.disableSaveButton == true) {
      this.getSaveOrder.emit(true);
    } else {
      this.getSaveOrder.emit(false);
    }
    if (this.subFeatureLabels && this.subFeatureLabels.length > 0 && this.disableSaveButton != true) {
      const p = _.map(this.subFeatureLabels, (item) => {
        if (item && item.ID) {
          return {
            id: item.ID,
            displayOrder: item.Display_Order,
          };
        }
      });
      this.subscriptionPlanService.editFeatureSubLabelGroup(p).subscribe((res) => {
        if (res && res.length > 0) {
          this.subFeatureLabels = JSON.parse(JSON.stringify(res));
          this.disableSaveButton = true;

          if (this.displaySaveButton == true) {
            NotifyAppComponent.displayToast('success', 'Success!', 'Sort order is modified ');
          }
        }
        this.getSaveOrder.emit(true);
      });
    }
  }

  selectResult() {
    this.getSelectedIDs.emit(this.selectedIDs || []);

    this.selectedLimitedIDs = _.filter(this.selectedLimitedIDs, (item) => {
      if (item && this.selectedIDs.indexOf(item) != -1) {
        return true;
      }
      return false;
    });
    this.getSelectedLimitedIDs.emit(this.selectedLimitedIDs || []);

    this.getIsSelectionChange.emit(this.isChanged);
  }

  buildShadowBox(color) {
    if (color) {
      const rbg = hexRgb(color);

      if (rbg) {
        return ` 0px 2px 1px -1px rgb(${rbg.red}  ${rbg.green} ${rbg.blue} / 20%), 0px 1px 1px 0px rgb(${rbg.red}  ${rbg.green} ${rbg.blue} / 17%), 0px 1px 3px 0px rgb(${rbg.red}  ${rbg.green} ${rbg.blue} / 14%)`;
      } else {
        return null;
      }
    }
  }

  buildBorderleft(color, mainColor, v) {
    const _color = this.getSubFeature(color, mainColor);
    if (_color) {
      const rbg = hexRgb(_color);

      if (rbg) {
        if (v === true) {
          return `rgb(${rbg.red}  ${rbg.green} ${rbg.blue})`;
        } else {
          return `rgb(${rbg.red}  ${rbg.green} ${rbg.blue} / 50%)`;
        }
      } else {
        return _color;
      }
    }
  }

  deleteMain(id) {
    if (id) {
      const confirm = new ConfirmDialog(
        'delete',
        '',
        'Are you sure you want to delete this Main feature?',
        'No',
        'Yes'
      );
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.subscriptionPlanService.deleteFeatureMainLabel(id).subscribe((res) => {
            if (res) {
              this.getRemove.emit(res);
              this.mainFeatureLabelID = null;
              this.mainFeatureLabel = null;
              NotifyAppComponent.displayToast('success', 'Success!', 'Sub feature label is removed ');
              ref.close();
            }
          });
        }
      });
    }
  }

  editMain(id) {
    this.router.navigate([this.root, { outlets: { page: ['main-feature-edit-create', id] } }]);
  }

  viewMain(id) {
    this.router.navigate([this.root, { outlets: { page: ['main-feature-view', id, this.featureType] } }]);
  }

  quickEdit(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(FeatureSubLabelQuickEditComponent, {
        data: {
          subFeatureID: id,
        },
        width: '950px',
        panelClass: 'noCard',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res) {
          ref.close();
        }
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res && res.ID) {
          const index = this.subFeatureLabels.findIndex((item) => {
            if (item && item.ID && item.ID == id) {
              return true;
            }
            return false;
          });

          if (index != -1) {
            this.subFeatureLabels[index] = res;

            this.subFeatureLabels = JSON.parse(JSON.stringify(this.subFeatureLabels));
          }
          ref.close();
        }
      });
    }
  }

  quickEditMain(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(FeatureMainLabelQuickEditComponent, {
        data: {
          mainFeatureID: id,
        },
        width: '950px',
        panelClass: 'noCard',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res) {
          ref.close();
        }
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res && res.ID) {
          this.mainFeatureLabel = res;

          this.buildMainItem();
          ref.close();
        }
      });
    }
  }

  viewMainRedirect(id) {
    if (id) {
      this.router.navigate([this.root, { outlets: { page: ['main-view-redirect', id, this.featureType] } }]);
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  isNew(date) {
    if (date) {
      const days = this.utils.daysPast(date);

      if (days < 30) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  getSubFeature(c, mainColor) {
    if (c && c.split('|')[0]) {
      return c.split('|')[0];
    }

    return mainColor;
  }

  toggleCollapse() {
    if (this.canHideDisplay == true) {
      this.hideDisplayCheck = !this.hideDisplayCheck;
    }
  }

  goMain(id) {
    if (id) {
      this.router.navigate([this.root, { outlets: { page: ['main-feature-view', id, this.featureType] } }]);
    }
  }

  copy(d) {
    if (d) {
      this._clipboardService.copy(d);
      NotifyAppComponent.displayToast('success', 'Success', 'Copied');
    }
  }

  featureList() {
    this.router.navigate([this.root, { outlets: { page: ['main-feature-list', this.featureType] } }]);
  }

  planList() {
    this.router.navigate([this.root, { outlets: { page: ['subscription-plan-overview-all', this.featureType] } }]);
  }

  openSubFeatre(v, id, description, date) {
    if (v === false) {
      this.openedSubFeature[id] = true;

      if (!this.htmlContentObject[id]) {
        if (this.htmlContentLoader[id] !== true) {
          this.htmlContentLoader[id] = true;
          this.changeDetectorRef.detectChanges();
          this.subscriptionPlanService.getSubFeatureHTMLContentHidden(id, this.sessionType, date).subscribe((res) => {
            if (res) {
              this.htmlContentObject[id] = res;
            } else {
              this.htmlContentObject[id] = description;
            }
            this.htmlContentLoader[id] = false;
          });
        }
      }
    } else {
      this.openedSubFeature[id] = false;
    }
  }

  openSubFeatreOut(id) {
    if (id) {
      this.router.navigate([this.root, { outlets: { page: ['sub-feature', id, this.featureType] } }]);
    }
  }
}
