<div class="rel" *ngIf="invitationsDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="invitationsDB.data.length == 0">
  <p class="pull-right" *ngIf="dateFrom != null || dateTo != null">
    <span *ngIf="dateFrom"
    >From:
      <strong>{{ dateFrom | customDateTime }} </strong>
    </span>

    <span *ngIf="dateTo">
      To:
      <strong>{{ dateTo | customDateTime }}</strong>
    </span>
  </p>

  <h1 class="dataHeader">
    Draft Invitations:
    <span class="badge" id="totalDraft">
      <mat-icon>email</mat-icon>
      <span [endVal]="dataSource.filteredData.length" countUp></span>
    </span>
  </h1>

  <mat-card class="list-card">
    <!-- data table -->
    <mat-table class="list-table no-quick-click" #table [dataSource]="dataSource" matSort>
      <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->
      <!-- ID Column -->
      <ng-container cdkColumnDef="Date">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header>
          Date
        </mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="continue(row['invitation_id'])">
          <span *ngIf="row && row.created_at">{{ row.created_at | TimeLabelPast }} Ago</span>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="Merchant">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header>
          Merchant
        </mat-header-cell>
        <mat-cell
          *cdkCellDef="let row"
          (click)="continue(row['invitation_id'])"
          matTooltip="{{ row?.content?.merchantIDLabel }}"
        >
          {{ row?.content?.merchantIDLabel }}</mat-cell
        >
      </ng-container>

      <ng-container cdkColumnDef="Customer">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header>
          Customer
        </mat-header-cell>
        <mat-cell
          *cdkCellDef="let row"
          (click)="continue(row['invitation_id'])"
          matTooltip="{{ row?.content?.patient?.firstName | lowercase | ucfirst }} {{
            row?.content?.patient?.lastName | lowercase | ucfirst
          }}"
        >
          {{ row?.content?.patient?.firstName | lowercase | ucfirst }}
          {{ row?.content?.patient?.lastName | lowercase | ucfirst }}
        </mat-cell>
      </ng-container>
      <ng-container cdkColumnDef="Email">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header>
          Email
        </mat-header-cell>
        <mat-cell
          *cdkCellDef="let row"
          (click)="continue(row['invitation_id'])"
          matTooltip="{{ row?.content?.patient?.email }}"
        >
          {{ row?.content?.patient?.email }}
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="Mobile">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header>
          Mobile
        </mat-header-cell>
        <mat-cell
          *cdkCellDef="let row"
          (click)="continue(row['invitation_id'])"
          matTooltip="{{ row?.content?.patient?.mobile }}"
        >
          {{ row?.content?.patient?.mobile | customPhone }}
        </mat-cell>
      </ng-container>

      <!-- Color Column -->

      <ng-container cdkColumnDef="Amount">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header>
          Amount
        </mat-header-cell>
        <mat-cell
          *cdkCellDef="let row"
          (click)="continue(row['invitation_id'])"
          matTooltip="{{ row?.content?.totalTreatmentValue | customCurrency }}"
        >
          {{ row?.content?.totalTreatmentValue | customCurrency }}
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="Actions">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header>
          Actions
        </mat-header-cell>
        <mat-cell *cdkCellDef="let row">
          <div style="float: right">
            <button [matMenuTriggerFor]="menu" mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button (click)="continueInvitation(row['invitation_id'])" mat-menu-item>
                <mat-icon>forward</mat-icon>
                <span>Contine</span>
              </button>
              <button (click)="deleteInvitation(row['_id'])" mat-menu-item>
                <mat-icon>delete</mat-icon>
                <span>delete</span>
              </button>
            </mat-menu>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      #paginator
      [length]="dataSource.filteredData.length"
      [pageIndex]="0"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
    >
    </mat-paginator>
  </mat-card>
</div>
<div *ngIf="invitationsDB.data.length == 0 && invitationsDB.firstLoadEvent != false">
  <app-empty-list-message></app-empty-list-message>
</div>
