<div class="container error-page">
  <mat-card class="error text-center">
    <div class="card-header error-gradient clearfix inModal">
      <div class="row clearfix text-left">
        <div class="row titleArea clearfix full-width">
          <mat-icon class="fas fa-exclamation-triangle pull-left"></mat-icon>
          <h2 class="summary-header rm-m white">
            {{ errorTitle }}
            <span class="subLabel">Error code: <strong>404</strong></span>
          </h2>
        </div>
      </div>
    </div>

    <img class="bannerImg"
         src="https://email-graphics-smileright.s3-ap-southeast-2.amazonaws.com/error-modal-banner.png"/>

    <div class="errorContent mb">
      <p class="lead">{{ errorContent }}</p>
      <p>Please try again in a few minutes and we should have this problem resolved.</p>
      <hr/>
    </div>

    <div class="button-row full-width text-center">
      <button class="pull-left" *ngIf="type != 'raw'" [routerLink]="['']" mat-mini-fab color="accent"
              matTooltip="Return Home">
        <mat-icon>home</mat-icon>
      </button>

      <button class="mr ml" (click)="contactUs()" mat-raised-button color="warn">Contact us</button>
      <button class="" (click)="cancel()" mat-raised-button color="accent">Go back</button>
    </div>
  </mat-card>

  <p class="small information">
    If you continue to experience problems visiting {{ "BRANDING.Brand Name" | translate | titlecase }}, you might just
    need to
    clear your browser cache. You can find out how to do this on
    <a href="https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DDesktop&hl=en"
       target="_blank">Chrome</a>
    or <a href="https://support.apple.com/en-au/guide/safari/sfri47acf5d6/mac" target="_blank">Safari</a>
  </p>
</div>
