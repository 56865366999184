import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { DataSource } from '@angular/cdk/table';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { TemplateReviewComponent } from '../../../core/helper/template-review/template-review.component';
import { TemplateSocialMediaViewComponent } from '../../../core/helper/template-social-media-view/template-social-media-view.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { LookupClass } from '../../../shared/types/lookupClass';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { SocialMediaGalleryListComponent } from '../../social-media/social-media-gallery-list/social-media-gallery-list.component';
import { MarketingCampaginEditModalComponent } from '../marketing-campagin-edit-modal/marketing-campagin-edit-modal.component';
import { MarketingCampaignViewComponent } from '../marketing-campaign-view/marketing-campaign-view.component';
import { MarketingResendModalComponent } from '../marketing-resend-modal/marketing-resend-modal.component';
import { MarketingService } from '../shared/marketing.service';
import { MarketingUtilClass } from '../shared/marketingUtil';

@Component({
  selector: 'app-marketing-list-global',
  templateUrl: './marketing-list-global.component.html',
  styleUrls: ['./marketing-list-global.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-35%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MarketingListGlobalComponent implements OnChanges, OnDestroy, OnInit {
  @Input()
  dateFrom;

  @Input()
  dateTo;

  @Input()
  isExpired;

  @Input()
  isTest = false;

  @Input()
  status;

  @Input()
  merchantID;

  @Input()
  pageSize = Settings.global['listPageSize'] || 20;

  @Output()
  getCount = new EventEmitter();

  @Output()
  getCountInner = new EventEmitter();

  @Output()
  campaignID = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;
  @ViewChild('searchField', { static: false }) searchField: ElementRef;
  @ViewChild('merchantSelector', { static: false }) merchantSelector: MatSelect;
  @ViewChild('socialSelector', { static: false }) socialSelector: MatSelect;
  @ViewChild('statusSelector', { static: false }) statusSelector: MatSelect;
  @ViewChild('targetSelector', { static: false }) targetSelector: MatSelect;
  @ViewChild('templateSelector', { static: false }) templateSelector: MatSelect;

  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  utils = new UtilsClass();
  lookup = new LookupClass();
  displayedColumns = [
    'ProfileImage',
    'Merchant',
    'Name',
    'Create',
    'ScheduledSend',
    'Type',
    'Target',
    'Template',
    'SocialMedia',
    'Tags',
    'Status',
    'Actions',
  ];
  role;

  selectedCompaignID;

  isPromoterOrAdmin = false;

  filters = [];

  searchVals = new FormControl();

  searchValList = ['Merchant', 'Name', 'CreatedDate', 'ScheduledSend', 'Target', 'Template', 'Status'];

  isModuleMarketingSocialMediaActive = Settings.global['isModuleMarketingSocialMediaActive'];

  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  typeLabel;

  filteredSize = null;
  subjectArray = [];

  utilMarketing = new MarketingUtilClass();

  destroyEvent = new EventEmitter();

  constructor(
    private marketingService: MarketingService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private utilService: UtilsService,
    private customDate: CustomDatePipe
  ) {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });
  }

  ngOnInit() {
    if (!this.status) {
      this.displayedColumns = [
        'ProfileImage',
        'Merchant',
        'Name',
        'Create',
        'ScheduledSend',
        'Type',
        'Target',
        'Template',
        'SocialMedia',
        'Tags',
        'Status',
        'Actions',
      ];
      this.searchValList = ['Merchant', 'Name', 'CreatedDate', 'ScheduledSend', 'Target', 'Template'];
    } else if (this.status && (this.status == 'CMPL' || this.status == 'ACTV')) {
      this.displayedColumns = [
        'ProfileImage',
        'Merchant',
        'Name',
        'Create',
        'ScheduledSend',
        'Type',
        'Target',
        'Template',
        'SocialMedia',
        'Tags',
        'Status',
        'practicePage',
        'Actions',
      ];
    } else {
      this.displayedColumns = [
        'ProfileImage',
        'Name',
        'Create',
        'ScheduledSend',
        'Type',
        'Target',
        'Template',
        'SocialMedia',
        'Tags',
        'Status',
        'Actions',
      ];
    }
    this.getCountInner.subscribe((res) => {
      this.getCount.emit(res);
    });
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleMarketingSocialMediaActive = access['isModuleMarketingSocialMediaActive'];
      }
    });
    this.listDB = new LoadRecords(
      this.marketingService,
      this.destroyEvent,
      this.isPromoterOrAdmin,
      this.dateFrom,
      this.dateTo,
      this.status,
      this.merchantID,
      this.isExpired,
      this.isTest,
      this.getCountInner
    );
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const changeProp in changes) {
      if (changeProp === 'status') {
        this.searchField.nativeElement.value = null;
        this.merchantSelector.value = null;
        this.statusSelector.value = null;
        this.socialSelector.value = null;
        this.targetSelector.value = null;
        this.templateSelector.value = null;
      }
    }
    if (!this.status) {
      this.displayedColumns = [
        'ProfileImage',
        'Merchant',
        'Name',
        'Create',
        'ScheduledSend',
        'Type',
        'Target',
        'Template',
        'SocialMedia',
        'Tags',
        'Status',
        'Actions',
      ];
      this.searchValList = ['Merchant', 'Name', 'CreatedDate', 'ScheduledSend', 'Target', 'Template'];
    } else if (this.status && (this.status == 'CMPL' || this.status == 'ACTV')) {
      this.displayedColumns = [
        'ProfileImage',
        'Merchant',
        'Name',
        'Create',
        'ScheduledSend',
        'Type',
        'Target',
        'Template',
        'SocialMedia',
        'Tags',
        'Status',
        'practicePage',
        'Actions',
      ];
    } else {
      this.displayedColumns = [
        'ProfileImage',
        'Name',
        'Create',
        'ScheduledSend',
        'Type',
        'Target',
        'Template',
        'SocialMedia',
        'Tags',
        'Status',
        'Actions',
      ];
    }
    this.listDB = new LoadRecords(
      this.marketingService,
      this.destroyEvent,
      this.isPromoterOrAdmin,
      this.dateFrom,
      this.dateTo,
      this.status,
      this.merchantID,
      this.isExpired,
      this.isTest,
      this.getCountInner
    );
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  setNameFilter(event, field) {
    let filter;
    if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }
    this.dataSource.filterName = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  setMerchantNameFilter(event, field) {
    let filter;
    if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }
    this.dataSource.filterMerchantName = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  setTargetFilter(event, field, extra = null) {
    let filter;
    if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }
    this.dataSource.filterTarget = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  setTemplateFilter(event, field) {
    const filter = {
      field,
      value: event,
    };
    this.dataSource.filterTemplate = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  setSocialFilter(event, field) {
    const filter = {
      field,
      value: event,
    };
    this.dataSource.filterSocial = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  setStatusFilter(event, field) {
    const filter = {
      field,
      value: event,
    };
    this.dataSource.filterStatus = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  setTagFilter(event, field) {
    const filter = {
      field,
      value: typeof event === 'string' ? [event] : event,
    };
    this.dataSource.filterTags = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  getColorFromSocial(type) {
    if (type === 'Pinterest') {
      return '#c8232c';
    } else if (type === 'Facebook') {
      return '#4267B2';
    } else if (type === 'Instagram') {
      return '#E1306C';
    } else if (type === 'LinkedIn') {
      return '#2867B2';
    } else if (type === 'Twitter') {
      return '#1DA1F2';
    }
    return '#4267B2';
  }

  openCampaignViewDialog(id) {
    if (id) {
      this.selectedCompaignID = id;
      this.campaignID.emit(id);
    }
  }

  viewSocialMedia(row) {
    if (row.ID) {
      const ref = RootAppComponent.dialog.open(SocialMediaGalleryListComponent, {
        data: {
          campaignID: row.ID,
        },
        width: '1200px',
        panelClass: ['noCard', 'top-modal'],
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
      ref.componentInstance.confirmShare.subscribe((res) => {
        if (res) {
          this.dataSource.replaceItem = res;
        }
        ref.close();
      });
    }
  }

  campaignView(id, templateID) {
    if (id) {
      const ref = RootAppComponent.dialog.open(MarketingCampaignViewComponent, {
        data: {
          campaignID: id,
          templateID,
        },
        width: '1200px',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  openEmailPreviewDialog(template) {
    if (template) {
      const ref = RootAppComponent.dialog.open(TemplateReviewComponent, {
        data: {
          templateTag: template,
          isPrint: false,
          isProceed: false,
          isDownload: false,
          proceedLabel: null,
        },
        width: '700px',
      });
      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
    }
  }

  editCampaignQuick(campaignID) {
    if (campaignID) {
      const ref = RootAppComponent.dialog.open(MarketingCampaginEditModalComponent, {
        data: {
          campaignID,
        },
        width: '900px',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
      ref.componentInstance.getResult.subscribe((res) => {
        if (res && res.ID) {
          this.dataSource.replaceItem = res;
        }
        ref.close();
      });
    }
  }

  addToPracticePage(id, isPublic) {
    if (id) {
      if (isPublic == true) {
        const confirm = new ConfirmDialog(
          'visibility',
          '',
          'Are you sure you want to add this campaign to your Offers Page ?',
          'No',
          'Yes'
        );
        const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
          data: confirm,
        });
        ref.componentInstance.onConfirm.subscribe((confirmation) => {
          if (confirmation === false) {
            ref.close();
          } else {
            const p = {
              isPublicCampaign: true,
              date: false,
            };
            this.marketingService.addCampaignToPracticeLandingPage(id, p, this.isPromoterOrAdmin).subscribe((res) => {
              if (res) {
                this.dataSource.replaceItem = res;
                NotifyAppComponent.displayToast(
                  'success',
                  'Successful operation',
                  'The campaign has been added to your Offers Page'
                );
              }
              ref.close();
            });
          }
        });
      } else {
        const confirm = new ConfirmDialog(
          'visibility_off',
          '',
          'Are you sure you want to remove this campaign from your Offers Page ?',
          'No',
          'Yes'
        );
        const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
          data: confirm,
        });
        ref.componentInstance.onConfirm.subscribe((confirmation) => {
          if (confirmation === false) {
            ref.close();
          } else {
            const p = {
              isPublicCampaign: false,
              date: false,
            };
            this.marketingService.addCampaignToPracticeLandingPage(id, p, this.isPromoterOrAdmin).subscribe((res) => {
              if (res) {
                this.dataSource.replaceItem = res;
                NotifyAppComponent.displayToast(
                  'success',
                  'Successful operation',
                  'The campaign has been removed from your Offers Page'
                );
              }
              ref.close();
            });
          }
        });
      }
    }
  }

  viewSocialMediaPosts(template) {
    if (template) {
      const ref = RootAppComponent.dialog.open(TemplateSocialMediaViewComponent, {
        data: {
          templateTag: template,
        },
        width: '800px',
        panelClass: 'noCard',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  cancelCampaign(id) {
    const confirm = new ConfirmDialog(
      'cancel',
      'Cancel/End campaign',
      'If you cancel a <strong>complete</strong> Campaign it will be marked as <strong>Exipred</strong>, you can <strong>Quick Edit</strong> it latter if you want to activate it agait ' +
        '<br>Are you sure you want to proceed?',
      'No',
      'Yes'
    );
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
    });
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        this.marketingService.cancelCompaign(id, this.isPromoterOrAdmin).subscribe((res) => {
          if (res) {
            if (this.status) {
              this.dataSource.removeItem = id;
            } else {
              this.dataSource.replaceItem = res;
            }
            NotifyAppComponent.displayToast(
              'success',
              'Successful operation',
              'Campaign has been successfully canceled'
            );
          }
          ref.close();
        });
      }
    });
  }

  socialMedia(id) {
    if (id) {
      this.router.navigate(['/merchant', { outlets: { page: ['marketing-social-create', id] } }]);
    }
  }

  editCampaign(id) {
    if (id) {
      this.router.navigate(['/merchant', { outlets: { page: ['marketing-campaign-edit', id] } }]);
    }
  }

  viewMerchant(id) {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: id,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  resendCampaign(id, _status) {
    const ref = RootAppComponent.dialog.open(MarketingResendModalComponent, {
      width: '650px',
      data: {
        campaignID: id,
      },
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.updatedMarketing.subscribe((res) => {
      if (res) {
        ref.close();
        if (_status == 'PEND') {
          this.dataSource.replaceItem = res;
        } else if (_status == 'TIMD' || _status == 'ACTV') {
          this.dataSource.removeItem = id;
          this.dataSource.addItem = res;
        }
      }
    });
  }

  activeCampaign(id) {
    const confirm = new ConfirmDialog(
      'done_outline',
      '',
      'Are you sure you want to activate this compaign?',
      'No',
      'Yes'
    );
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
    });
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        this.marketingService.activateCompaign(id, this.isPromoterOrAdmin).subscribe((res) => {
          if (res) {
            if (this.status) {
              this.dataSource.removeItem = id;
            } else {
              this.dataSource.replaceItem = res;
            }
            NotifyAppComponent.displayToast(
              'success',
              'Successful operation',
              'Campaign has been successfully activated'
            );
          }
          ref.close();
        });
      }
    });
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;
  utilMarketing = new MarketingUtilClass();

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  serviceRef;
  isDestoyed = false;

  util = new UtilsClass();
  allTags;

  constructor(
    private marketingService: MarketingService,
    private destroyEvent,
    private isPromoterOrAdmin,
    private dateFrom,
    private dateTo,
    private status,
    private merchantID,
    private isExpired,
    private isTest,
    private getCountInner
  ) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });
    const payload = {
      section: 0,
      status: this.status,
      fields:
        'ID,DateTimeCreated,Merchant_key,Merchant.Name,Label,LastModified,' +
        'Template.Tag,Social_Media_Only,' +
        'Template.Label,Facebook_Set_Date,LinkedIn_Set_Date,Twitter_Set_Date,Pinterest_Set_Date,Instagram_Set_Date,CampaignStatus,' +
        'Targets.TableName,Tag.Label,Tag.Description,' +
        'WhenToSend,Is_Public,' +
        'Status.Code,Status.Label,StaticList.Label',
      dateFrom: this.util.EPdateFormat(this.dateFrom),
      dateTo: this.util.EPdateFormat(this.dateTo),
      merchantID: this.merchantID,
      isExpired: this.isExpired,
      isTest: this.isTest,
    };
    this.allTags = [];
    this.serviceRef = this.marketingService.getList(payload, this.isPromoterOrAdmin).subscribe((res) => {
      this.items = res.map((data) => {
        let tagString = '';
        let tags = [];
        data =
          ((data['tags'] = []),
          (data['tags'] =
            ((tagString = data['Tag.Label'].trim()),
            (tags = tagString.length > 0 ? tagString.split('|') : []),
            (tagString = data['StaticList.Label'].trim()),
            tags.concat(tags, tagString.length > 0 ? tagString.split('|') : []),
            tags)),
          data);
        this.allTags = [...new Set([...this.allTags, ...tags])].sort((a, b) => {
          const a1 = a.toLowerCase();
          const b1 = b.toLowerCase();
          return a1 === b1 ? 0 : a1 > b1 ? 1 : -1;
        });
        return data;
      });
      this.dataChange.next(this.items.slice());

      this.getCountInner.emit(this.items.length || 0);
      this.firstLoad = true;
      const innerFunction = (section) => {
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;
        this.serviceRef = this.marketingService.getList(payload, this.isPromoterOrAdmin).subscribe((res1) => {
          if (res1.length > 0) {
            this.count = res1.count;
            this.items = this.items.concat(res1);
            this.getCountInner.emit(this.items.length || 0);
            this.items = this.items.concat(
              res1.map((data) => {
                let tagString = '';
                let tags = [];
                data =
                  ((data['tags'] = []),
                  (data['tags'] =
                    ((tagString = data['Tag.Label'].trim()),
                    (tags = tagString.length > 0 ? tagString.split('|') : []),
                    (tagString = data['StaticList.Label'].trim()),
                    tags.concat(tags, tagString.length > 0 ? tagString.split('|') : []),
                    tags)),
                  data);
                this.allTags = [...new Set([...this.allTags, ...tags])].sort((a, b) => {
                  const a1 = a.toLowerCase();
                  const b1 = b.toLowerCase();
                  return a1 === b1 ? 0 : a1 > b1 ? 1 : -1;
                });
                return data;
              })
            );
            this.dataChange.next(this.items.slice());
            if (this.isDestoyed !== true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };
      if (this.isDestoyed !== true) {
        innerFunction(1);
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }

  get data(): any[] {
    return this.dataChange.value;
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterNameChange = new BehaviorSubject('');
  _filterMerchantNameChange = new BehaviorSubject('');
  _filterSocialChange = new BehaviorSubject('');
  _filterStatusChange = new BehaviorSubject('');
  _filterTagsChange = new BehaviorSubject('');
  _filterTargetChange = new BehaviorSubject('');
  _filterTemplateChange = new BehaviorSubject('');

  field = {
    name: '',
    merchantName: '',
    target: '',
    template: '',
    tags: '',
    social: '',
    status: '',
  };

  filteredData: any[] = [];
  utilMarketing = new MarketingUtilClass();

  constructor(private _custDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterMerchantNameChange.subscribe(() => (this._paginator.pageIndex = 0));
    this._filterNameChange.subscribe(() => (this._paginator.pageIndex = 0));
    this._filterSocialChange.subscribe(() => (this._paginator.pageIndex = 0));
    this._filterStatusChange.subscribe(() => (this._paginator.pageIndex = 0));
    this._filterTargetChange.subscribe(() => (this._paginator.pageIndex = 0));
    this._filterTemplateChange.subscribe(() => (this._paginator.pageIndex = 0));
    this._filterTagsChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  get filterName(): any {
    return this._filterNameChange.value;
  }

  set filterName(item: any) {
    this.field.name = item.value !== null ? item.value : '';
    this._filterNameChange.next(this.field.name);
  }

  get filterMerchantName(): any {
    return this._filterMerchantNameChange.value;
  }

  set filterMerchantName(item: any) {
    this.field.merchantName = item.value !== null ? item.value : '';
    this._filterMerchantNameChange.next(this.field.merchantName);
  }

  get filterTarget(): any {
    const value = this._filterTargetChange.value;
    return value;
  }

  set filterTarget(item: any) {
    this.field.target = item.value !== null ? item.value : '';
    this._filterTargetChange.next(this.field.target);
  }

  get filterTemplate(): any {
    return this._filterTemplateChange.value;
  }

  set filterTemplate(item: any) {
    this.field.template = item.value !== null ? item.value : '';
    this._filterTemplateChange.next(this.field.template);
  }

  get filterSocial(): any {
    return this._filterSocialChange.value;
  }

  set filterSocial(item: any) {
    this.field.social = item.value !== null ? item.value : '';
    this._filterSocialChange.next(this.field.social);
  }

  get filterStatus(): any {
    return this._filterStatusChange.value;
  }

  set filterStatus(item: any) {
    this.field.status = item.value !== null ? item.value : '';
    this._filterStatusChange.next(this.field.status);
  }

  get filterTags(): any {
    return this._filterTagsChange.value;
  }

  set filterTags(item: any) {
    this.field.tags = item.value !== null ? item.value : [];
    this._filterTagsChange.next(this.field.tags);
  }

  set addItem(res) {
    this._custDatabase.data.unshift(res);
    this._custDatabase.dataChange.next(this._custDatabase.data);
  }

  set removeItem(id) {
    const data = this._custDatabase.data.filter((row) => row.ID != id);
    this._custDatabase.dataChange.next(data);
  }

  set replaceItem(item) {
    const _index = this._custDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    this._custDatabase.data[_index] = item;
    this._custDatabase.dataChange.next(this._custDatabase.data);
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._custDatabase.dataChange,
      this._filterMerchantNameChange,
      this._filterNameChange,
      this._filterSocialChange,
      this._filterStatusChange,
      this._filterTagsChange,
      this._filterTargetChange,
      this._filterTemplateChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    const result = observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._custDatabase.data.slice().filter((item: any) => {
          let result1 = true;
          const name = (item['Label'] || '').toLowerCase();
          const merchantName = (item['Merchant.Name'] || '').toLowerCase();
          const target = (item['Targets.TableName'] || '').toLowerCase();
          const template = (item['Template.Label'] || '').toLowerCase();
          const tags = (item['tags'] || []).map((d) => d.toLowerCase());
          const status = (item['Status.Label'] || '').toLowerCase();
          const social = (item['SocialMediaType.Code'] || []).map((d) => d.toLowerCase());
          if (
            ''.concat(name, merchantName, target, template, status) === '' &&
            tags.length === 0 &&
            social.length === 0
          ) {
            return true;
          } else {
            const filterName = this.filterName.toLowerCase();
            const filterMerchantName = this.filterMerchantName.toLowerCase();
            const filterTarget = this.filterTarget.toLowerCase();
            const filterTemplate = this.filterTemplate.toLowerCase();
            const filterTags = (this.filterTags || []).map((d) => d.toLowerCase());
            const filterSocial = this.filterSocial || [];
            const filterStatus = this.filterStatus.toLowerCase();
            result1 =
              result1 && (filterName === '' || (filterName !== '' && name !== '' && name.indexOf(filterName) !== -1));
            result1 =
              result1 &&
              (filterMerchantName === '' ||
                (filterMerchantName !== '' && merchantName !== '' && merchantName === filterMerchantName));
            result1 =
              result1 && (filterTarget === '' || (filterTarget !== '' && target !== '' && target === filterTarget));
            result1 =
              result1 &&
              (filterTemplate === '' || (filterTemplate !== '' && template !== '' && template === filterTemplate));
            result1 = result1 && (filterTags.length === 0 || (tags.length !== 0 && tags.length >= filterTags.length));
            if (result1) {
              if (filterTags.length > 0 && tags.length > 0) {
                for (let j = 0; j < tags.length && result1; j++) {
                  result1 = tags.indexOf(filterTags[j]) !== -1;
                }
              }
            }
            if (result1 && filterSocial.length > 0) {
              for (let j = 0; j < filterSocial.length && result1; j++) {
                result1 = result1 && item[filterSocial[j] + '_Set_Date'] !== '0000-00-00';
              }
            }
            result1 =
              result1 && (filterStatus === '' || (filterStatus !== '' && status !== '' && status === filterStatus));
          }
          return result1;
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
    return result;
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }
    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'Merchant':
          [propertyA, propertyB] = [a['Merchant.Name'], b['Merchant.Name']];
          break;

        case 'Name':
          [propertyA, propertyB] = [a['Label'], b['Label']];
          break;

        case 'Create':
          [propertyA, propertyB] = [
            moment(a['DateTimeCreated']).toDate().getTime(),
            moment(b['DateTimeCreated']).toDate().getTime(),
          ];
          break;

        case 'ScheduledSend':
          [propertyA, propertyB] = [
            moment(a['WhenToSend']).toDate().getTime(),
            moment(b['WhenToSend']).toDate().getTime(),
          ];
          break;

        case 'Target':
          [propertyA, propertyB] = [
            this.utilMarketing.getTargetLabel(a['Targets.TableName']),
            this.utilMarketing.getTargetLabel(b['Targets.TableName']),
          ];
          break;

        case 'Template':
          [propertyA, propertyB] = [a['Template.Label'], b['Template.Label']];
          break;

        case 'Tag':
          [propertyA, propertyB] = [a['Tag.Label'].toLowerCase(), b['Tag.Label'].toLowerCase()];
          break;

        case 'Status':
          [propertyA, propertyB] = [a['Status.Label'], b['Status.Label']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
