import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelperService } from '../shared/helper.service';

@Component({
  selector: 'app-help-page-view',
  templateUrl: './help-page-view.component.html',
  styleUrls: ['./help-page-view.component.css'],
})
export class HelpPageViewComponent implements OnInit {
  @Input() simplePageCode;

  @Input()
  simplePage;

  constructor(private helperService: HelperService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.simplePageCode = data;
  }

  ngOnInit() {
    // if (!this.simplePage) {
    this.helperService.getSimplePage(this.simplePageCode).subscribe((res) => {
      this.simplePage = res;
    });
    // }
  }
}
