<div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="listDB.data === undefined || listDB.data.length === 0">
  <div>
    <mat-card class="list-card mat-elevation-z0">
      <div class="row clearfix searchArea-wht">
        <div class="row flex flex-row">
          <h1 class="dataHeader" *ngIf="!status">
            Campaigns
            <span class="badge">{{ dataSource.filteredData.length }}</span>
          </h1>
          <h2 class="dataHeader" *ngIf="status">
            {{ utilMarketing.getStatusLabel(status) }} Campaigns
            <span class="badge">{{ dataSource.filteredData.length }}</span>
          </h2>
        </div>

        <!-- Search -->
        <div class="row clearfix searchArea primary-gradient-img">
          <div class="full-width clearfix flex animated fadeInDown">
            <mat-icon class="mt mr">search</mat-icon>
            <mat-form-field class="ml full-width" appearance="outline">
              <mat-label>Name</mat-label>
              <input
                class="clearfix"
                #searchField
                (input)="setNameFilter($event.target.value, 'Label')"
                matInput
                name="Name"
                placeholder="Name"
              />
            </mat-form-field>
            <mat-form-field class="ml half-width" appearance="outline">
              <mat-label>Merchant</mat-label>
              <mat-select
                #merchantSelector
                (selectionChange)="setMerchantNameFilter($event.value, 'Merchant.Name')"
                name="MerchantName"
                placeholder="Merchant"
              >
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option
                  *ngFor="
                    let a of listDB.data | objectPropertyValue: 'Merchant.Name' | unique | without: ['', null] | orderBy
                  "
                  [value]="a"
                >
                  {{ a }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="ml half-width" appearance="outline">
              <mat-label>Target</mat-label>
              <mat-select
                #targetSelector
                (selectionChange)="setTargetFilter($event.value, 'Targets.TableName')"
                name="Target"
                placeholder="Target"
              >
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option
                  *ngFor="
                    let a of listDB.data
                      | objectPropertyValue: 'Targets.TableName'
                      | unique
                      | without: ['', null]
                      | orderBy
                  "
                  [value]="a"
                >
                  {{ utilMarketing.getTargetLabel(a) }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="ml half-width" appearance="outline">
              <mat-label>Template</mat-label>
              <mat-select
                #templateSelector
                (selectionChange)="setTemplateFilter($event.value, 'Template.Label')"
                name="Template"
                placeholder="Template"
              >
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option
                  *ngFor="
                    let a of listDB.data
                      | objectPropertyValue: 'Template.Label'
                      | unique
                      | without: ['', null]
                      | orderBy
                  "
                  [value]="a"
                >
                  {{ utilMarketing.getTemplateLabel(a) }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="half-width" appearance="outline">
              <mat-label>Social Media</mat-label>
              <mat-select
                class="margin-btm"
                (selectionChange)="setSocialFilter($event.value, 'SocialMediaType.Code')"
                name="Type"
                placeholder="Social Media"
                multiple
              >
                <mat-option [value]="'Facebook'">
                  <mat-icon class="fab fa-facebook" [style.color]="getColorFromSocial('Facebook')"></mat-icon>
                  <span [style.color]="getColorFromSocial('Facebook')">Facebook</span>
                </mat-option>
                <mat-option [value]="'LinkedIn'">
                  <mat-icon class="fab fa-linkedin" [style.color]="getColorFromSocial('LinkedIn')"></mat-icon>
                  <span [style.color]="getColorFromSocial('LinkedIn')">LinkedIn</span>
                </mat-option>
                <mat-option [value]="'Twitter'">
                  <mat-icon class="fab fa-twitter" [style.color]="getColorFromSocial('Twitter')"></mat-icon>
                  <span [style.color]="getColorFromSocial('Twitter')">Twitter</span>
                </mat-option>
                <mat-option [value]="'Instagram'">
                  <mat-icon class="fab fa-instagram" [style.color]="getColorFromSocial('Instagram')"></mat-icon>
                  <span [style.color]="getColorFromSocial('Instagram')">Instagram</span>
                </mat-option>
                <mat-option [value]="'Pinterest'">
                  <mat-icon class="fab fa-pinterest" [style.color]="getColorFromSocial('Pinterest')"></mat-icon>
                  <span [style.color]="getColorFromSocial('Pinterest')">Pinterest</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="ml half-width" appearance="outline">
              <mat-label>Tags ({{ listDB.allTags.length }})</mat-label>
              <mat-select
                #tagsField
                (selectionChange)="setTagFilter($event.value, 'tags')"
                name="Tags"
                placeholder="Tags"
                multiple
              >
                <mat-option *ngFor="let tag of listDB.allTags | without: ['', null] | orderBy" [value]="tag">
                  {{ tag }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="ml half-width" appearance="outline">
              <mat-label>Status</mat-label>
              <mat-select
                #statusSelector
                (selectionChange)="setStatusFilter($event.value, 'Status.Label')"
                name="Status"
                placeholder="Status"
              >
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option *ngFor="let a of listDB.allTags | orderBy" [value]="a">
                  {{ a }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <ng-container cdkColumnDef="ProfileImage" sticky>
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Logo</mat-header-cell>
          <mat-cell class="text-center" *cdkCellDef="let row">
            <app-marketing-view-logo
              class="supplierIcon2 text-center pull-left"
              *ngIf="row"
              [hideZoomButton]="false"
              [marketingID]="row['ID']"
              [LastModified]="row['LastModified']"
            >
            </app-marketing-view-logo>
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Merchant">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Merchant</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            [ngClass]="row && row['ID'] && row['ID'] == selectedCompaignID ? 'selected-row' : ''"
            (click)="openCampaignViewDialog(row['ID'])"
            matTooltip="{{ row['Merchant.Name'] }}"
          >
            {{ row['Merchant.Name'] }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Name">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Name</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            [ngClass]="row && row['ID'] && row['ID'] == selectedCompaignID ? 'selected-row' : ''"
            (click)="openCampaignViewDialog(row['ID'])"
            matTooltip="{{ row['Label'] }}"
          >
            <span class="ellipsis2">{{ row['Label'] }}</span>
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Create">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Created Date</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            [ngClass]="row && row['ID'] && row['ID'] == selectedCompaignID ? 'selected-row' : ''"
            (click)="openCampaignViewDialog(row['ID'])"
            matTooltip="{{ row['DateTimeCreated'] | customDate }}"
          >
            {{ row['DateTimeCreated'] | customDate }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="ScheduledSend">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Scheduled Send</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            [ngClass]="row && row['ID'] && row['ID'] == selectedCompaignID ? 'selected-row' : ''"
            (click)="openCampaignViewDialog(row['ID'])"
            matTooltip="{{ row['WhenToSend'] | customDate }}"
          >
            {{ row['WhenToSend'] | customDateTime }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="practicePage">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>
            {{ "KEYWORD.practice" | translate | titlecase}} Page
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openCampaignViewDialog(row['ID'])">
            <span
              class="list-label smaller"
              *ngIf="row['Is_Public'] == '1'"
              matTooltip="Added to the Offers Page"
              style="background-color: rgb(90, 186, 71)"
            >
              <mat-icon>check</mat-icon>
            </span>
            <span
              class="list-label smaller"
              *ngIf="row['Is_Public'] != '1'"
              [matTooltip]="'Not published in the ' + ('KEYWORD.practice' | translate | titlecase) + ' Page'"
              style="background-color: rgb(239, 71, 39)"
            >
              <mat-icon>close</mat-icon>
            </span>
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Target">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Target</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            [ngClass]="row && row['ID'] && row['ID'] == selectedCompaignID ? 'selected-row' : ''"
            (click)="openCampaignViewDialog(row['ID'])"
            matTooltip="{{ utilMarketing.getTargetLabel(row['Targets.TableName']) }}"
          >
            {{ utilMarketing.getTargetLabel(row['Targets.TableName']) }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Type">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Type </mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            [ngClass]="row && row['ID'] && row['ID'] == selectedCompaignID ? 'selected-row' : ''"
            (click)="openCampaignViewDialog(row['ID'])"
          >
            <span *ngIf="row['Social_Media_Only'] == '1'">Only social media</span>
            <span *ngIf="row['Social_Media_Only'] != '1'">Email, SMS and social media</span>
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Template">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Template</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            [ngClass]="row && row['ID'] && row['ID'] == selectedCompaignID ? 'selected-row' : ''"
            (click)="openCampaignViewDialog(row['ID'])"
            matTooltip="{{ row['Template.Label'] }}"
          >
            {{ row['Template.Label'] }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="SocialMedia">
          <mat-header-cell *cdkHeaderCellDef> Social Media</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            [ngClass]="row && row['ID'] && row['ID'] == selectedCompaignID ? 'selected-row' : ''"
            (click)="openCampaignViewDialog(row['ID'])"
          >
            <mat-icon
              class="fab fa-facebook ml"
              *ngIf="row['Facebook_Set_Date'] != '0000-00-00'"
              [style.color]="'#4267B2'"
              matTooltip="Posted on Facebook at: {{ row['Facebook_Set_Date'] | customDate }}"
            ></mat-icon>
            <mat-icon
              class="fab fa-linkedin ml"
              *ngIf="row['LinkedIn_Set_Date'] != '0000-00-00'"
              [style.color]="'#2867B2'"
              matTooltip="Posted on LinkedIn at: {{ row['LinkedIn_Set_Date'] | customDate }}"
            >
            </mat-icon>
            <mat-icon
              class="fab fa-twitter ml"
              *ngIf="row['Twitter_Set_Date'] != '0000-00-00'"
              [style.color]="'#1DA1F2'"
              matTooltip="Posted on Twitter at: {{ row['Twitter_Set_Date'] | customDate }}"
            >
            </mat-icon>
            <mat-icon
              class="fab fa-instagram ml"
              *ngIf="row['Instagram_Set_Date'] != '0000-00-00'"
              [style.color]="'#E1306C'"
              matTooltip="Posted on Instagram at: {{ row['Instagram_Set_Date'] | customDate }}"
            ></mat-icon>
            <mat-icon
              class="fab fa-pinterest ml"
              *ngIf="row['Pinterest_Set_Date'] != '0000-00-00'"
              [style.color]="'#c8232c'"
              matTooltip="Posted on Pinterest at: {{ row['Pinterest_Set_Date'] | customDate }}"
            ></mat-icon>
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Tags">
          <mat-header-cell *matHeaderCellDef>Tags</mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            (click)="openCampaignViewDialog(row['ID'])"
            matTooltip="{{ row['Tag.Label'] }}"
          >
            {{ row['Tag.Label'] }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Status">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Status</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            [ngClass]="row && row['ID'] && row['ID'] == selectedCompaignID ? 'selected-row' : ''"
            (click)="openCampaignViewDialog(row['ID'])"
            matTooltip="{{ row['Status.Label'] }}"
          >
            {{ row['Status.Label'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button
                  *ngIf="row['ID'] && row['Status.Code'] == 'PEND'"
                  (click)="editCampaign(row['ID'])"
                  mat-menu-item
                >
                  <mat-icon>edit</mat-icon>
                  <span>Continue campaign</span>
                </button>
                <button
                  *ngIf="
                    row['ID'] &&
                    row['Status.Code'] != 'CNCL' &&
                    row['Status.Code'] != 'ABRT' &&
                    row['Status.Code'] != 'TIMD' &&
                    row['Status.Code'] != 'PEND'
                  "
                  (click)="editCampaignQuick(row['ID'])"
                  mat-menu-item
                >
                  <mat-icon>edit</mat-icon>
                  <span>Quick Edit</span>
                </button>
                <mat-divider></mat-divider>
                <button *ngIf="row['ID']" (click)="openCampaignViewDialog(row['ID'])" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span>View Campaign</span>
                </button>
                <button *ngIf="row['Template.Tag']" (click)="openEmailPreviewDialog(row['Template.Tag'])" mat-menu-item>
                  <mat-icon>chrome_reader_mode</mat-icon>
                  <span>View Template</span>
                </button>
                <mat-divider></mat-divider>
                <button
                  *ngIf="
                    (row['Status.Code'] == 'ACTV' || row['Status.Code'] == 'CMPL') &&
                    row['Is_Public'] == '0' &&
                    row['CampaignStatus'] == 'Active'
                  "
                  (click)="addToPracticePage(row['ID'], true)"
                  mat-menu-item
                >
                  <mat-icon>visibility</mat-icon>
                  <span>Add to the Offers Page</span>
                </button>
                <button
                  *ngIf="
                    (row['Status.Code'] == 'ACTV' || row['Status.Code'] == 'CMPL') &&
                    row['Is_Public'] == '1' &&
                    row['CampaignStatus'] == 'Active'
                  "
                  (click)="addToPracticePage(row['ID'], false)"
                  mat-menu-item
                >
                  <mat-icon>visibility_off</mat-icon>
                  <span>Remove from the Offers Page</span>
                </button>
                <button
                  *ngIf="
                    (row['Status.Code'] == 'TIMD' || row['Status.Code'] == 'ACTV' || row['Status.Code'] == 'CMPL') &&
                    isModuleMarketingSocialMediaActive == true
                  "
                  (click)="viewSocialMedia(row)"
                  mat-menu-item
                >
                  <mat-icon>share</mat-icon>
                  <span>Share Campaign</span>
                </button>
                <mat-divider></mat-divider>
                <button
                  *ngIf="
                    (row['Status.Code'] == 'PEND' ||
                      row['Status.Code'] == 'TIMD' ||
                      row['Status.Code'] == 'ACTV' ||
                      row['Status.Code'] == 'CMPL') &&
                    row['CampaignStatus'] == 'Active'
                  "
                  (click)="cancelCampaign(row['ID'])"
                  mat-menu-item
                >
                  <mat-icon *ngIf="row['Status.Code'] != 'CMPL'">cancel</mat-icon>
                  <span *ngIf="row['Status.Code'] != 'CMPL'">Cancel Campaign</span>
                  <mat-icon *ngIf="row['Status.Code'] == 'CMPL'">cancel</mat-icon>
                  <span *ngIf="row['Status.Code'] == 'CMPL'">End Campaign</span>
                </button>
                <!-- <button mat-menu-item *ngIf="row['Social_Media_Only']!='1' &&  row['Status.Code']=='PEND'"
                (click)="activeCampaign(row['ID'])">
                <mat-icon>done_outline</mat-icon>
                <span>Active Campaign</span>
              </button> -->
                <button
                  *ngIf="
                    row['Social_Media_Only'] != '1' && (row['Status.Code'] == 'TIMD' || row['Status.Code'] == 'ACTV')
                  "
                  (click)="resendCampaign(row['ID'], row['Status.Code'])"
                  mat-menu-item
                >
                  <mat-icon>refresh</mat-icon>
                  <span>Reschedule Campaign</span>
                </button>
                <button
                  *ngIf="row['Social_Media_Only'] != '1' && row['Status.Code'] == 'CMPL'"
                  (click)="resendCampaign(row['ID'], row['Status.Code'])"
                  mat-menu-item
                >
                  <mat-icon>refresh</mat-icon>
                  <span>Resend Campaign</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginator
        [length]="dataSource.filteredData.length"
        [pageIndex]="0"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
      >
      </mat-paginator>
    </mat-card>
  </div>
</div>

<div *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <app-empty-list-message></app-empty-list-message>
</div>
