<app-customer-view class="" *ngIf="customerID" [customerID]="customerID" (closeModal)="closeEvent($event)">
</app-customer-view>
<app-prospect-view class="" *ngIf="prospectID" [prospectID]="prospectID" (closeModal)="closeEvent($event)">
</app-prospect-view>
<!-- printing -->

<app-customer-print-view class="" *ngIf="printMode == true && customerID" [customerID]="customerID">
</app-customer-print-view>
<app-prospect-print-view class="" *ngIf="printMode == true && prospectID" [prospectID]="prospectID">
</app-prospect-print-view>
