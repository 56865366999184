<mat-card class="settlement-card clearfix" *ngIf="Settlement || groupSettlements">
  <div class="card-header header-card-customized clearfix inModal" [ngStyle]="{ background: color }">
    <div class="row clearfix">
      <button class="btn-close btn-clear pull-right mat-button" *ngIf="isModal == true" (click)="closeModalEvent()">
        <mat-icon>clear</mat-icon>
      </button>

      <div class="row titleArea clearfix" *ngIf="isRefund != true">
        <mat-icon class="pull-left fas fa-hand-holding-usd"></mat-icon>
        <!-- <h2 class="summary-header rm-m" *ngIf="Settlement && Settlement.Is_Materials_Request=='1'">
          Material Advance Amount
          <strong>
            <span *ngIf="isGroupSettlements == false && Settlement">{{
              Settlement['Settlement.Amount'] | customCurrency
              }}</span>
            <span *ngIf="isGroupSettlements == true">{{ groupSettlementsAmount | customCurrency }}</span>
          </strong>

          <span class="subLabel" *ngIf="Settlement">Settlement to {{ Settlement['MerchantName'] }}</span>
        </h2> -->

        <h2 class="summary-header rm-m">
          Settlement Amount
          <strong>
            <span *ngIf="isGroupSettlements == false && Settlement">{{
              Settlement['Settlement.Amount'] | customCurrency
              }}</span>
            <span *ngIf="isGroupSettlements == true">{{ groupSettlementsAmount | customCurrency }}</span>
          </strong>

          <span class="subLabel" *ngIf="Settlement">Settlement to {{ Settlement['MerchantName'] }}</span>
        </h2>
      </div>

      <div class="row titleArea clearfix" *ngIf="isRefund == true">
        <mat-icon class="pull-left fas fa-hand-holding-usd"></mat-icon>
        <h2 class="summary-header rm-m">
          Refund Amount
          <strong>
            <span *ngIf="isGroupSettlements == false && Settlement">{{
              Settlement['Settlement.Amount'] | customCurrency
              }}</span>
            <span *ngIf="isGroupSettlements == true">{{ groupSettlementsAmount | customCurrency }}</span>
          </strong>

          <span class="subLabel" *ngIf="Settlement">Refund to {{ Settlement['SettleTo.Name'] }}</span>
        </h2>
      </div>
    </div>

    <!-- button if within modal -->
    <div class="action-button" *ngIf="isModal == true">
      <button class="actionMenuToggle pull-right"
        *ngIf="Settlement && (Settlement['Status.Code'] == 'REQU' || Settlement['Status.Code'] == 'PEND')"
        [matMenuTriggerFor]="menu" color="" mat-raised-button>
        Actions <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button *ngIf="Settlement && Settlement['Status.Code'] == 'PEND'" (click)="requestSettlement()" mat-menu-item
          color="warn">
          <mat-icon>description</mat-icon>
          <span>Continue settlement request</span>
        </button>

        <button *ngIf="Settlement && (Settlement['Status.Code'] == 'PEND' || Settlement['Status.Code'] == 'REQU')"
          (click)="withdrawSettlement()" mat-menu-item color="warn">
          <mat-icon>block</mat-icon>
          <span>Withdraw settlement </span>
        </button>

        <!-- <button mat-menu-item color="primary" *ngIf="  isPromoterOrAdmin == true  && Settlement['Status.Code']=='REQU'"
          (click)="approveSettlement()">
          <mat-icon>thumb_up_alt</mat-icon>
          <span>Approve settlement </span>
        </button>

        <button mat-menu-item color="accent" *ngIf="isPromoterOrAdmin == true  && Settlement['Status.Code']=='REQU'"
          (click)="declineSettlement()">
          <mat-icon>thumb_down_alt</mat-icon>
          <span>Decline settlement </span>
        </button> -->

        <button *ngIf="false && (Settlement['Status.Code'] == 'REQU' || Settlement['Status.Code'] == 'PEND')"
          (click)="voidSettlement()" mat-menu-item>
          <mat-icon>delete</mat-icon>
          <span>Archive settlement </span>
        </button>

        <button *ngIf="isPromoterOrAdmin == true && Settlement && Settlement['ID']"
          (click)="viewSettlementLogs(Settlement['ID'])" mat-menu-item>
          <mat-icon class="fa fa-list-alt"></mat-icon>
          <span>View Settlement logs</span>
        </button>
      </mat-menu>
    </div>

    <!-- button if standalone -->
    <div class="action-button" *ngIf="isModal == false && false">
      <button class="pull-left" (click)="contractDetails()" mat-raised-button color="primary">
        <mat-icon>keyboard_arrow_left</mat-icon>
        <span class="mobHide">Contract Details</span>
      </button>

      <button (click)="openMerchantDialog()" mat-raised-button color="primary">
        <mat-icon>person</mat-icon>
        <span class="mobHide">View Merchant</span>
      </button>
      <button (click)="openContractDialog()" mat-raised-button color="primary">
        <mat-icon>assignment</mat-icon>
        <span class="mobHide">View Contract</span>
      </button>

      <button class="actionMenuToggle pull-right"
        *ngIf="(Settlement && Settlement['Status.Code'] == 'REQU') || Settlement['Status.Code'] == 'PEND'"
        [matMenuTriggerFor]="menu" color="accent" mat-raised-button>
        Actions <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button *ngIf="Settlement && Settlement['Status.Code'] == 'PEND'" (click)="requestSettlement()" mat-menu-item
          color="warn">
          <mat-icon>description</mat-icon>
          <span>Request Settlement</span>
        </button>

        <button *ngIf="Settlement && (Settlement['Status.Code'] == 'PEND' || Settlement['Status.Code'] == 'REQU')"
          (click)="withdrawSettlement()" mat-menu-item color="warn">
          <mat-icon>block</mat-icon>
          <span>Withdraw settlement </span>
        </button>

        <button *ngIf="Settlement && isPromoterOrAdmin == true && Settlement['Status.Code'] == 'REQU'"
          (click)="approveSettlement()" mat-menu-item color="primary">
          <mat-icon>thumb_up_alt</mat-icon>
          <span>Approve settlement </span>
        </button>

        <button *ngIf="Settlement && isPromoterOrAdmin == true && Settlement['Status.Code'] == 'REQU'"
          (click)="declineSettlement()" mat-menu-item color="accent">
          <mat-icon>thumb_down_alt</mat-icon>
          <span>Decline settlement </span>
        </button>

        <button *ngIf="false && (Settlement['Status.Code'] == 'REQU' || Settlement['Status.Code'] == 'PEND')"
          (click)="voidSettlement()" mat-menu-item>
          <mat-icon>delete</mat-icon>
          <span>Archive settlement </span>
        </button>

        <button *ngIf="Settlement && isPromoterOrAdmin == true && Settlement['ID']"
          (click)="viewSettlementLogs(Settlement['ID'])" mat-menu-item>
          <mat-icon class="fa fa-list-alt"></mat-icon>
          <span>View Settlement logs</span>
        </button>
      </mat-menu>
    </div>
  </div>

  <mat-dialog-content>
    <div class="grey-row">
      <div class="row clearfix flex">
        <div class="block full-width" *ngIf="Settlement && Settlement['Invoice.Date']">
          <p class="small rm-m">Invoice Date</p>
          <p class="rm-mt bold sr-title" *ngIf="Settlement">{{ Settlement['Invoice.Date'] | customDate }}</p>
        </div>

        <!-- Need to re-visit if the payments contains more than one item -->
        <div class="block full-width" *ngIf="Settlement && Settlement['Status.Code'] == 'APPR'">
          <p class="small rm-m">Settlement Date</p>
          <p class="rm-mt bold sr-title" *ngIf="
              Settlement && Settlement['Payments'] && Settlement['Payments'][0] && Settlement['Payments'][0]['Date']
            ">
            {{ Settlement['Payments'][0]['Date'] | customDate }}
          </p>
        </div>

        <div class="block full-width">
          <p class="small rm-m">Invoice Amount</p>
          <p class="rm-mt bold sr-title">
            <span *ngIf="isGroupSettlements == false && Settlement">{{
              Settlement['Invoice.Amount'] | customCurrency
              }}</span>
            <span *ngIf="isGroupSettlements == true">{{ groupSettlementsInvoiceAmount | customCurrency }}</span>
          </p>
        </div>

        <div class="block full-width" *ngIf="Settlement && Settlement['Invoice.Reference']">
          <p class="small rm-m">Invoice Reference</p>
          <p class="rm-mt bold sr-title" *ngIf="Settlement">{{ Settlement['Invoice.Reference'] || '...' }}</p>
        </div>
      </div>
    </div>

    <!-- settlement overview -->
    <div class="row clearfix flex" [class.add-mb]="isPromoterOrAdmin == false">
      <div class="thrd-width">
        <div class="row clearfix rel chart-card">
          <div class="total anim">
            <span class="totLab" *ngIf="isRefund != true">Settlement</span>
            <span class="totLab" *ngIf="isRefund == true">Settlement</span>
            <span class="balance" *ngIf="isGroupSettlements == false && Settlement"
              [endVal]="toNumber(Settlement['Settlement.Amount'])" countUp></span>
            <span class="balance" *ngIf="isGroupSettlements == true" [endVal]="groupSettlementsAmount" countUp></span>
          </div>
          <app-chart-input class="fullHeight" [titleIsTop]="true" [showLegend]="false" [showLabel]="false"
            [chartType]="'pie'" [title]="'Settlement'" [data]="chartData | orderBy: ['-name']"
            [dataNameAttribute]="'name'" [dataValueAttribute]="'value'" [customColorScheme]="customChartColor">
          </app-chart-input>
        </div>
      </div>
      <div class="full-width">
        <div class="row clearfix legendary" *ngIf="customChartColor && customChartColor.length > 0 && chartData">
          <label class="rm-mt num anim statusList">
            <span class="wide splitLabel" *ngIf="isRefund != true">
              <strong class="greyble smb" [style.color]="customChartColor[0].value">Settlement Amount</strong>
              To {{ "KEYWORD.practice" | translate | titlecase}}
            </span>
            <span class="wide splitLabel" *ngIf="isRefund == true">
              <strong class="greyble smb" [style.color]="customChartColor[0].value">Refund Amount</strong>
              To {{ "KEYWORD.patient" | translate | titlecase }}
            </span>
            <strong class="pill mt" [style.background]="customChartColor[0].value">{{
              chartData[0].value | customCurrency
              }}</strong>
          </label>
          <hr *ngIf="Settlement && Settlement['Insurance.Provider']" />
          <label class="rm-mt num anim statusList" *ngIf="Settlement && Settlement['Insurance.Provider']">
            <span class="wide splitLabel">
              <strong class="greyble smb" [style.color]="customChartColor[1].value">Settlement Amount</strong>
              To Insurance
            </span>
            <strong class="pill mt" [style.background]="customChartColor[1].value">{{
              chartData[1].value | customCurrency
              }}</strong>
          </label>
          <hr *ngIf="Settlement && Settlement['DrawDownGroupID']" />
          <label class="rm-mt num anim statusList" *ngIf="Settlement && Settlement['DrawDownGroupID']">
            <span class="wide splitLabel">
              <strong class="greyble smb" [style.color]="customChartColor[2].value">Settlement Amount</strong>
              To Supplier
            </span>
            <strong class="pill mt" [style.background]="customChartColor[2].value">{{
              chartData[2].value | customCurrency
              }}</strong>
          </label>
          <hr />
        </div>

        <div class="row clearfix flex mt">
          <!-- <div class="block full-width">
            <label class="small">{{ "ConsumerContribution" | translate }}</label>
            <p class="rm-mt">{{Settlement["ConsumerContribution"] | customCurrency }} </p>
          </div> -->




          <div class="block full-width" *ngIf="Settlement">
            <label class="small smb">Request status</label>
            <p class="rm-m small">
              <span class="chip" [class.approved]="Settlement['Status.Code'] == 'APPR'"
                [class.cancelled]="Settlement['Status.Code'] == 'WDRN'"
                [class.declined]="Settlement['Status.Code'] == 'DECL'"
                [class.requested]="Settlement['Status.Code'] == 'REQU'"
                [class.pending]="Settlement['Status.Code'] == 'PEND'"
                [class.awaiting]="Settlement['Status.Code'] == 'AWTR'">
                <mat-icon class="{{Settlement['Status.Icon']}} smr" style="font-size: 0.8rem !important;"></mat-icon>
                {{ Settlement['Status.Label'] }}
              </span>
            </p>
          </div>
          <div class="block full-width" *ngIf="Settlement && Settlement.Is_Materials_Request!='1'">
            <label class="small smb">Type</label>
            <p class="rm-m small">
              <span class="chip approved">
                Settlement
              </span>
            </p>
          </div>

          <div class="block full-width" *ngIf="Settlement && Settlement.Is_Materials_Request=='1'">
            <label class="small smb">Type</label>
            <p class="rm-m small">
              <span class="chip materialRequest">
                Material Advance
              </span>
            </p>

          </div>


          <div class="block full-width" *ngIf="Settlement">
            <label class="small  smb">Payment status</label>
            <p class="rm-mt small">
              <span class="chip" [class.approved]="Settlement['PaymentStatus.Label'] == 'Paid'"
                [class.declined]="Settlement['PaymentStatus.Label'] == 'Blocked'"
                [class.requested]="Settlement['PaymentStatus.Label'] == 'Suspended'"
                [class.pending]="Settlement['PaymentStatus.Label'] == 'Pending'">
                {{ Settlement['PaymentStatus.Label'] || '...' }}
              </span>
            </p>
          </div>
        </div>
        <div class="block full-width  "
          *ngIf="Settlement && Settlement['Status.Code']==='DECL' && Settlement.declinedReasons && Settlement.declinedReasons.length>0">
          <hr />
          <label class=" smb"><strong> Declined Reasons </strong></label>
          <p class="rm-m smb  full-width" *ngFor="let s of Settlement.declinedReasons">
            <mat-icon class="fas fa-circle "
              style="font-size: 0.5rem !important;color:gray; height: 18px !important; line-height:  18px !important; width: 0.7rem !important;">
            </mat-icon> {{s}}
          </p>

          <!-- <p class="full-width text-right small " *ngIf="isPromoterOrAdmin==true && Settlement['Declined.By_Name']">Declined by:  <strong>{{Settlement['Declined.By_Name']}}</strong></p> -->

        </div>
        <div class="block full-width  "
          *ngIf="Settlement && Settlement['Invoice.Description'] && Settlement['Invoice.Description']!='Other'">
          <hr />
          <label class=" smb"><strong> {{ 'InvoiceDescription' | translate }} </strong></label>
          <p class="rm-mt smt" *ngIf="Settlement">{{ Settlement['Invoice.Description'] || '...' }}</p>
        </div>
      </div>
    </div>

    <!-- <hr> -->

    <!-- full finance overview -->
    <div class="row clearfix flex grey-row" *ngIf="isPromoterOrAdmin == true">
      <div class="thrd-width">
        <div class="row clearfix rel chart-card">
          <div class="total anim">
            <span class="totLab">Invoice</span>
            <span class="balance" *ngIf="contract" [endVal]="toNumber(contract[' Amount.Invoice'])" countUp></span>
          </div>
          <app-chart-input class="fullHeight" [titleIsTop]="true" [showLegend]="false" [showLabel]="false"
            [chartType]="'pie'" [title]="'Summary'" [data]="summaryChartData | orderBy: ['-name']"
            [dataNameAttribute]="'name'" [dataValueAttribute]="'value'" [customColorScheme]="customSummaryChartColor">
          </app-chart-input>
        </div>
      </div>
      <div class="full-width">
        <div class="row clearfix legendary"
          *ngIf="customSummaryChartColor && customSummaryChartColor.length > 0 && summaryChartData">
          <label class="rm-mt num anim statusList">
            <span class="wide splitLabel">
              <strong class="greyble smb" [style.color]="customSummaryChartColor[0].value">Total Approved
                Amount</strong>
              To {{ "KEYWORD.practice" | translate | titlecase}}
            </span>
            <strong class="pill mt" [style.background]="customSummaryChartColor[0].value">{{
              summaryChartData[0].value | customCurrency
              }}</strong>
          </label>
          <hr />
          <label class="rm-mt num anim statusList">
            <span class="wide splitLabel">
              <strong class="greyble smb" [style.color]="customSummaryChartColor[1].value">Total Approved
                Amount</strong>
              To Insurance
            </span>
            <strong class="pill mt" [style.background]="customSummaryChartColor[1].value">{{
              summaryChartData[1].value | customCurrency
              }}</strong>
          </label>
          <hr />
          <label class="rm-mt num anim statusList">
            <span class="wide splitLabel">
              <strong class="greyble smb" [style.color]="customSummaryChartColor[2].value">Total Approved
                Amount</strong>
              To Supplier
            </span>
            <strong class="pill mt" [style.background]="customSummaryChartColor[2].value">{{
              summaryChartData[2].value | customCurrency
              }}</strong>
          </label>
          <hr />
          <label class="rm-mt num anim statusList">
            <span class="wide splitLabel">
              <strong class="greyble smb" [style.color]="customSummaryChartColor[3].value">Total Available
                Amount</strong>
              Not Drawn Yet
            </span>
            <strong class="pill mt" [style.background]="customSummaryChartColor[3].value">{{
              summaryChartData[3].value | customCurrency
              }}</strong>
          </label>
        </div>
      </div>
    </div>

    <mat-accordion class="settlement-review">
      <!-- dont know what this is -->
      <!-- <mat-expansion-panel>
        <mat-expansion-panel-header>
          <h3 class="sr-title ">Request/Pending Amounts</h3>
        </mat-expansion-panel-header>

        <div class="row clearfix flex">
          <div class="row clearfix legendary full-width">


            <label class="rm-mt num anim statusList" *ngIf="statsData">
              <span class="wide splitLabel">
                <strong class="greyble smb">Total</strong>
                Total amount settled to date
              </span>
              <strong class="pill mt">{{ (statsData | FilterArrayMultipleValue: 'ID': ['PEND', 'REQU'] |
                    objectPropertyValue :'Sum(Settlement.Amount)' | toNumberArray ) | customCurrency}}</strong>
            </label>
            <mat-divider *ngIf="statsData"></mat-divider>

            <label class="rm-mt num anim statusList" *ngIf="statsData">
              <span class="wide splitLabel">
                <strong class="greyble smb">Settle to Insurance:</strong>
                Total settled to insurers to date
              </span>
              <strong class="pill mt">{{ (statsData | FilterArrayMultipleValue: 'ID': ['PEND', 'REQU'] |
                      objectPropertyValue :'Sum(Insurance.Contribution)' | toNumberArray ) | customCurrency}}</strong>
            </label>
            <mat-divider *ngIf="statsData"></mat-divider>


            <label class="rm-mt num anim statusList" *ngIf="settleToSupplier">
              <span class="wide splitLabel">
                <strong class="greyble smb">Settle to Supplier:</strong>
                Total settled to suppliers to date
              </span>
              <strong class="pill mt">{{ toNumber(settleToSupplier | FilterArrayMultipleValue: 'Status.Code': ['PEND', 'REQU'] |
                        objectPropertyValue: 'Settlement.Amount') | toNumberArray  | customCurrency}}</strong>
            </label>

          </div>
        </div>

      </mat-expansion-panel> -->

      <!-- hicaps settlement tab -->
      <mat-expansion-panel class="mb" *ngIf="Settlement && Settlement['Insurance.Provider']">
        <mat-expansion-panel-header>
          <h3 class="sr-title">Settlement to insurer</h3>
        </mat-expansion-panel-header>

        <div class="row clearfix">
          <div class="clearfix flex">
            <div class="full-width">
              <label class="small">Insurance Provider</label>
              <p class="rm-mt" *ngIf="Settlement">{{ Settlement['Insurance.Provider'] }}</p>
            </div>
            <div class="full-width">
              <label class="small">Insurance Contribution</label>
              <p class="rm-mt" *ngIf="Settlement">{{ Settlement['Insurance.Contribution'] | customCurrency }}</p>
            </div>
            <div class="full-width">
              <label class="small">Insurance Reference</label>
              <p class="rm-mt" *ngIf="Settlement">{{ Settlement['Insurance.Reference'] }}</p>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <!-- supplier settlement tab -->
      <mat-expansion-panel *ngIf="isGroupSettlements == true" class="mb">
        <mat-expansion-panel-header>
          <h3 class="sr-title">Settlement to supplier(s)</h3>
        </mat-expansion-panel-header>

        <div class="row clearfix">
          <div class="clearfix" *ngFor="let s of groupSettlements">
            <div class="clearfix" *ngIf="s['SettleTo.TableName'] == 'Supplier'">
              <div class="row clearfix flex">
                <div class="full-width">
                  <label class="small">Supplier Name</label>
                  <p class="rm-mt">{{ s['SettleTo.Name'] }}</p>
                </div>
                <div class="full-width">
                  <label class="small">Settlement Amount</label>
                  <p class="rm-mt">{{ s['Settlement.Amount'] | customCurrency }}</p>
                </div>

                <div class="full-width">
                  <label class="small">
                    <span *ngIf="s['SettleTo.Account.AccountNumber']">Bank Account</span>
                    <span *ngIf="s['SettleTo.Account.CardNumber']">Credit Card</span>
                  </label>
                  <p class="rm-mt">
                    <span *ngIf="s['SettleTo.Account.AccountNumber']">{{ s['SettleTo.Account.AccountNumber'] }}</span>
                    <span *ngIf="s['SettleTo.Account.CardNumber']">{{ s['SettleTo.Account.CardNumber'] }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel class="mb" *ngIf="settelmentDocuments && settelmentDocuments.length > 0">
        <mat-expansion-panel-header>
          <h3 class="sr-title">Documentation</h3>
        </mat-expansion-panel-header>

        <app-ep-document-list [title]="'Settlement documents'" [documents]="settelmentDocuments"></app-ep-document-list>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-dialog-content>
</mat-card>
