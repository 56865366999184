import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-sub-account-statistical-report',
  templateUrl: './sub-account-statistical-report.component.html',
  styleUrls: ['./sub-account-statistical-report.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class SubAccountStatisticalReportComponent implements OnInit {
  @Input()
  reportObj;

  @Output()
  close = new EventEmitter();

  isPromoterOrAdmin = false;
  loadingLatest = false;

  deliverability;

  isModal = false;

  gaugeType = 'arch';
  gaugeValue = 0;
  gaugeLabel;

  subAccountObj;
  emailReputation;
  emailReputationColor;

  moreIsLess = {
    '0': { color: '#db291b' },
    '1': { color: '#ff5722' },
    '2': { color: '#ff9800' },
    '3': { color: '#ffc107' },
    '4': { color: '#ffeb3b' },
    '5': { color: '#8bc34a' },
  };

  constructor(
    private authentication: AuthenticationService,
    private utilService: UtilsService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.reportObj = data;
      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authentication.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      this.deliverability =
        (Number(this.reportObj['Last_30_days.Sent']) -
          Number(this.reportObj['Last_30_days.Hard_bounces']) -
          Number(this.reportObj['Last_30_days.Soft_bounces']) -
          Number(this.reportObj['Last_30_days.Rejects']) -
          Number(this.reportObj['Last_30_days.Complaints']) -
          Number(this.reportObj['Last_30_days.Unsubs'])) /
        Number(this.reportObj['Last_30_days.Sent']);

      this.deliverability = Math.round(this.deliverability * 100);
    });

    this.utilService.getSubAccountStatisticalReport().subscribe((res) => {
      if (res && res.length > 0 && res[0].Reputation) {
        this.subAccountObj = res[0];
        const _reputationValue = Number(res[0].Reputation);
        if (_reputationValue >= 90) {
          this.emailReputation = 'Excellent';
          this.emailReputationColor = '4caf50';
        } else if (_reputationValue >= 75 && _reputationValue < 90) {
          this.emailReputation = 'Good';
          this.emailReputationColor = 'cddc39';
        } else if (_reputationValue >= 50 && _reputationValue < 75) {
          this.emailReputation = 'Fair';
          this.emailReputationColor = 'ff9800';
        } else {
          this.emailReputation = 'Poor';
          this.emailReputationColor = 'f44336';
        }
      } else {
        this.subAccountObj = null;
        this.emailReputation = 'none';
        this.emailReputationColor = 'e3e3e3';
      }
    });
  }

  closeEvent() {
    this.close.emit(true);
  }

  toNumber(n) {
    if (n) {
      return Number(n);
    } else {
      return 0;
    }
  }

  latestData() {
    this.loadingLatest = true;

    const p = {
      isEmail: true,
    };
    this.utilService.getSubAccountStatisticalData(p, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        this.loadingLatest = false;

        this.reportObj = res;

        this.reportObj.DateTimeCreated = new Date();
      }
    });
  }

  openWikiLink(link) {
    let url = '';
    if (!/^http[s]?:\/\//.test(link)) {
      url += 'https://';
    }
    url += link;
    window.open(url, '_blank', 'noopener');
  }
}
