import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { InvitationTemplateService } from '../shared/invitation-template.service';

import { map as _map } from 'lodash';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { InvitationTemplateCloneComponent } from '../invitation-template-clone/invitation-template-clone.component';
import { InvitationTemplateMergerComponent } from '../invitation-template-merger/invitation-template-merger.component';

@Component({
  selector: 'app-invitation-template-lookup',
  templateUrl: './invitation-template-lookup.component.html',
  styleUrls: ['./invitation-template-lookup.component.css'],
})
export class InvitationTemplateLookupComponent implements OnInit {
  @Input()
  isActive = true;

  typeFilter;
  @Input()
  merchantID;

  @Input()
  hideTreatmentValue = false;

  @Input()
  defaultTreatmentAmount = null;

  @Input()
  viewOnClick = true;

  @Input()
  productGroup = 'all';

  @Input()
  type;

  @Output()
  getResult = new EventEmitter();

  @Output()
  getTemplateID = new EventEmitter();

  @Output()
  getTemplate = new EventEmitter();

  showNextBtn = false;

  filterData = '';
  selectedPatient: any;
  utilsClass = new UtilsClass();

  isPromoterOrAdmin = true;

  @Output()
  close = new EventEmitter();

  templates = [];
  isModal = false;

  orderByFilter = 'label';
  selectAll;
  template;
  viewOnly = true;
  @Input()
  title = 'Select your template';

  isInvitationContext = true;

  @Input()
  description =
    "Below is a list of templates available. Select the one you wish to apply to this invitation, or if you are feeling creative press the 'create your own' button.";
  serviceRef;
  isDestoyed = false;
  isLoaded = false;
  itemsPerPage = 20;

  utils = new UtilsClass();
  currentPage;
  currentPageDefault = 1;
  currentPageUser = 1;
  currentPageSM = 1;

  selectTreatmentValue = true;

  isHeader = false;
  selectedResult;

  constructor(
    private authenticationService: AuthenticationService,
    private invitationTemplateService: InvitationTemplateService,
    private router: Router,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.merchantID) {
        this.merchantID = data.merchantID;
        this.isModal = true;
      }

      if (data.hideTreatmentValue !== null && data.hideTreatmentValue !== undefined) {
        this.hideTreatmentValue = data.hideTreatmentValue;
        this.isModal = true;
      }

      if (data.isInvitationContext !== null && data.isInvitationContext !== undefined) {
        this.isInvitationContext = data.isInvitationContext;
        this.isModal = true;
      }
      if (data.isHeader != null) {
        this.isHeader = data.isHeader;
        this.isModal = true;
      }

      if (data.defaultTreatmentAmount !== null && data.defaultTreatmentAmount !== undefined) {
        this.defaultTreatmentAmount = data.defaultTreatmentAmount;
        this.isModal = true;
      }
      if (data.selectTreatmentValue != null) {
        this.selectTreatmentValue = data.selectTreatmentValue;
      }

      if (data.productGroup != null) {
        this.productGroup = data.productGroup;
      }

      if (data.viewOnly != null) {
        this.viewOnly = data.viewOnly;
        this.isModal = true;
      }
      if (data.isActive != null) {
        this.isActive = data.isActive;
        this.isModal = true;
      }
      if (data.viewOnClick != null) {
        this.viewOnClick = data.viewOnClick;
        this.isModal = true;
      }
      if (data.selectAll != null) {
        this.selectAll = data.selectAll;
        this.isModal = true;
      }

      if (data.type) {
        this.type = data.type;
        this.isModal = true;
      }

      if (data.isModal != null) {
        this.isModal = data.isModal;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      this.setup();
    });
  }

  isNew(date) {
    if (date) {
      const days = this.utils.daysPast(date);

      if (days < 15) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  setup() {
    this.typeFilter = null;

    const payload = {
      orderBy: 'label',
      isActive: true,
      merchantID: this.merchantID,
      limit: 500,
      section: 1,
      type: this.type,
      productGroup: this.productGroup,
    };

    this.serviceRef = this.invitationTemplateService
      .getInvitationTemplateList(payload, this.isPromoterOrAdmin)
      .subscribe((res) => {
        this.isLoaded = true;
        if (res && res.length > 0) {
          res = _map(res, (item) => {
            if (item) {
              item = this.buildItem(item);
            }
            return item;
          });

          this.templates = res;

          const userTemplatesCount = this.templates.filter((template) => template.type === 'user').length;

          if (userTemplatesCount > 0) {
            this.typeFilter = 'user';
          } else {
            this.typeFilter = 'default';
          }

          const innerFunction = (section) => {
            section = section + 1;
            payload.section = section;

            this.serviceRef = this.invitationTemplateService
              .getInvitationTemplateList(payload, this.isPromoterOrAdmin)
              .subscribe((res) => {
                if (res.length > 0) {
                  res = _map(res, (item) => {
                    item = this.buildItem(item);
                    return item;
                  });

                  this.templates = this.templates.concat(res);

                  if (this.typeFilter != 'user') {
                    const _userTemplatesCount = this.templates.filter((template) => template.type === 'user').length;

                    if (_userTemplatesCount > 0) {
                      this.typeFilter = 'user';
                    } else {
                      this.typeFilter = 'default';
                    }
                  }

                  if (this.isDestoyed != true) {
                    innerFunction(section);
                  }
                } else {
                  return true;
                }
              });
          };

          if (this.isDestoyed != true) {
            innerFunction(1);
          }
        }
      });
  }

  buildItem(_r) {
    const item = _r;
    if (item) {
      item.search = '';
      if (item.label) {
        item.search = item.search + ' ' + item.label;
      }
      if (item.productGroup) {
        item.productGroup = item.productGroup + ' ' + item.productGroup;
      }
      if (item.productGroupLabel) {
        item.search = item.search + ' ' + item.productGroupLabel;
      }
      if (item.description) {
        item.search = item.search + ' ' + item.description;
      }
      if (item && item.content && item.content.totalTreatmentValue) {
        item.search = item.search + ' ' + String(item.content.totalTreatmentValue);
      }

      if (item && item.content && item.content.tags && item.content.tags.length > 0) {
        item.search = item.search + ' ' + item.content.tags.join(',');
      }
    }
    return item;
  }

  selectTemplate(template) {
    if (template && template._id) {
      if (this.viewOnClick == true) {
        this.template = template;

        let w = '900px';
        if (this.viewOnly != true) {
          w = '1000px';
        }

        const ref = RootAppComponent.dialog.open(InvitationTemplateMergerComponent, {
          data: {
            templateID: template._id,
            viewOnly: this.viewOnly,
            defaultTreatmentAmount: this.defaultTreatmentAmount,
            selectAll: this.selectAll,
            hideTreatmentValue: this.hideTreatmentValue,
            selectTreatmentValue: this.selectTreatmentValue,
          },
          width: '1200px',
        });
        ref.componentInstance.close.subscribe((res) => {
          if (res === 'all') {
            ref.close();
            ref.afterClosed().subscribe((res) => {
              this.closeEvent();
            });
          } else {
            ref.close();
          }
        });
        ref.componentInstance.getResult.subscribe((res) => {
          if (res) {
            this.selectedResult = res;

            this.sendResult();
            ref.close();
          }
        });
      } else {
        this.template = template;

        this.sendResult();
      }
    }
  }

  ngOnDestroy() {
    this.isDestoyed = true;
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }

  sendResult() {
    if (this.selectedResult) {
      this.getResult.emit(this.selectedResult);
    }

    if (this.template && this.template._id) {
      this.getTemplate.emit(this.template);
      this.getTemplateID.emit(this.template._id);
    } else {
      this.getResult.emit(null);
    }
  }

  backToOne() {
    this.currentPage = 1;
    this.currentPageDefault = 1;
    this.currentPageSM = 1;
    this.currentPageUser = 1;
  }

  changePage(e) {
    if (e) {
      if (this.typeFilter == 'user') {
        this.currentPageUser = e;
        this.currentPage = e;
      } else if (this.typeFilter == 'default') {
        this.currentPageDefault = e;
        this.currentPage = e;
      } else {
        this.currentPageSM = e;
        this.currentPage = e;
      }
    }
  }

  changeTab() {
    if (this.typeFilter == 'user') {
      this.currentPage = this.currentPageUser;
    } else if (this.typeFilter == 'default') {
      this.currentPage = this.currentPageDefault;
    } else {
      this.currentPage = this.currentPageSM;
    }
  }

  viewTreatment(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(InvitationTemplateMergerComponent, {
        data: {
          templateID: id,
          viewOnly: true,
          hideTreatmentValue: this.hideTreatmentValue,

          selectTreatmentValue: this.selectTreatmentValue,
        },
        width: '1200px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res === 'all') {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            this.closeEvent();
          });
        } else {
          ref.close();
        }
      });
    }
  }

  orderBy(d) {
    if (d) {
      let isUpOrder = true;

      if (d && d[0] == '-') {
        isUpOrder = false;
      }

      if (this.orderByFilter === d) {
        if (isUpOrder == true) {
          this.orderByFilter = '-' + d;
        } else {
          this.orderByFilter = d.replace('-', '');
        }
      } else {
        this.orderByFilter = d;
      }
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  gotoNewTemplate() {
    let confirm = new ConfirmDialog(
      'fas fa-info',
      'Leave Invitation',
      'You are about to leave this invitation, a copy will be now saved to draft invitations to resume later.',
      'Cancel',
      'Okay'
    );

    if (this.isInvitationContext != true) {
      confirm = new ConfirmDialog(
        'fas fa-info',
        'You want to continue?',
        'You are about to leave this page.',
        'Cancel',
        'Okay'
      );
    }

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
      width: '500px',
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        this.router.navigate(['/merchant', { outlets: { page: ['treatment-saved-template'] } }]);
        ref.close();
        this.close.emit(true);
      } else {
        ref.close();
      }
    });
  }

  clone(t) {
    if (t && t._id) {
      let isDefault = false;

      if (this.isPromoterOrAdmin == true && t.type == 'default') {
        isDefault = true;
      }

      const ref = RootAppComponent.dialog.open(InvitationTemplateCloneComponent, {
        data: {
          merchantID: this.merchantID,
          invitationTemplateID: t._id,
          isDefault,
        },
        width: '600px',
      });
      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
      ref.componentInstance.closeAndEdit.subscribe((res) => {
        ref.close();
        ref.afterClosed().subscribe((r) => {
          this.closeEvent();
        });
      });

      ref.componentInstance.getResult.subscribe((item) => {
        if (item && item._id) {
          item = this.buildItem(item);
          this.template = item;

          this.templates.push(item);

          this.templates = JSON.parse(JSON.stringify(this.templates));

          this.backToOne();

          this.filterData = item.label;
          this.typeFilter = null;
        }
        ref.close();
        ref.afterClosed().subscribe((r) => {
          this.sendResult();
        });
      });
    }
  }

  edittemplate(id) {
    const confirm = new ConfirmDialog(
      'fas fa-info',
      '',
      'You are about to close this modal, you want to continue?',
      'No',
      'Yes'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
    });
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        this.router.navigate(['/merchant', { outlets: { page: ['invitation-template-edit', id] } }]);
        ref.close();
        ref.afterClosed().subscribe((r) => {
          this.closeEvent();
        });
      }
    });
  }

  delete(id) {
    const confirm = new ConfirmDialog(
      'fas fa-trash',
      '',
      'Are you sure you want to remove this template ?',
      'No',
      'Yes'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
    });
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        this.invitationTemplateService.deleteInvitationTemplate(id, this.isPromoterOrAdmin).subscribe((r) => {
          if (r) {
            const index = this.templates.findIndex((e) => {
              if (e && e._id && e._id == id) {
                return true;
              }

              return false;
            });

            if (index !== -1) {
              this.templates.splice(index, 1);

              this.templates = JSON.parse(JSON.stringify(this.templates));
            }

            NotifyAppComponent.displayToast('success', 'Successfull', 'Message Template is removed');
            ref.close();
          }
        });
      }
    });
  }

  list() {
    this.closeEvent();
    this.router.navigate(['/merchant', { outlets: { page: ['treatment-saved-template'] } }]);
  }
}
