<div class="rel text-center">
  <div class="card-header primary-gradient-img clearfix schedule inModal">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear mat-button white" (click)="doNotShow()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m">
          <img class="inverse"
               src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/brandLogos/ADA-Fed-Logo.png"/>
          ADA Schedule of Service
        </h2>
      </div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="row clearfix">
      <div class="two-thrd-width text-left">
        <div class="col-left">
          <p class="larger">
            {{ "BRANDING.Brand Name" | translate | titlecase }}, in conjunction with the ADA has now digitised the
            Schedule of
            Services and extending the functionality to now include all your favourite brands, products and services in
            one spot.
          </p>
          <p class="larger">
            Search by item codes to quickly access curated content including Marketing material (Brochures/Videos),
            Safety Data Sheets, Training videos and much more...
          </p>
        </div>
      </div>
      <div class="thrd-width">
        <div class="col-right">
          <img class="full-width mat-elevation-z4"
               src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/ada-schedule.jpg"/>
        </div>
      </div>
    </div>

    <hr class="mt"/>

    <div class="row clearfix">
      <mat-checkbox class="pull-left" [(ngModel)]="showOnTreatPageLogin">Please don't show this message again
      </mat-checkbox>

      <button class="pull-right" (click)="doNotShow()" mat-raised-button color="accent">
        Close
      </button>
    </div>
  </mat-dialog-content>
</div>
