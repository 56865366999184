<div class="rel dash invit">
  <div class="card-header primary-gradient-img inModal">
    <div class="row titleArea clearfix full-width">
      <img class="animated zoomIn feature-img dashboard character_Pair"/>
      <h2 class="summary-header rm-m white">
        Invitation Templates
      </h2>
    </div>
    <button class="btn-close btn-clear mat-button" (click)="doNotShow()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <app-video-tutorials-modal [defaultSelectedTopic]="'428497337'" [autoplay]="false"></app-video-tutorials-modal>

    <div class="row clearfix mb">
      <!--
         <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb text-center">Before you get started here are some important things you should know:</h3>
           -->
    </div>
  </mat-dialog-content>
  <hr/>
  <mat-checkbox [(ngModel)]="showBeforeStartingInvitationTemplate">Please don't show this message again</mat-checkbox>
  <button class="ml pull-right" (click)="doNotShow()" mat-raised-button color="accent">
    Got It!
  </button>
</div>
