<div class="rel lookup" *ngIf="template && template.content">
  <div class="card-header primary-gradient-img clearfix inModal">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m">
          {{ template['label'] }}
          <button class="btn-close btn-clear mat-button white ml info-template" *ngIf="template['description']"
                  (click)="showComment()" matTooltip="Check template description">
            <mat-icon class="fas fa-info-circle"></mat-icon>
          </button>

          <span class="value chip-new pull-right" *ngIf="isNew(template['created_at']) == true">NEW</span>
          <span class="subLabel" *ngIf="template['productGroupLabel']">{{ template['productGroupLabel'] }}</span>
        </h2>
      </div>
    </div>
    <div class="date-chip-area">
      <p class="clearfix small rm-m" *ngIf="template['content'] && template['content']['templates']">
        <span class="chip-enable mr mat-elevation-z1" *ngFor="let t of template['content']['templates']"
              matTooltip="The template {{ t.label }} has been applied">
          <mat-icon class="fas fa-brush"></mat-icon>
          {{ t.label }}
        </span>
      </p>

      <p class="clearfix small rm-m mr" *ngIf="template['isActive'] == '1'">
        <span class="chip-enable mat-elevation-z1">Active</span>
      </p>
      <p class="clearfix small rm-m mr" *ngIf="template['isActive'] == '0'">
        <span class="chip-disable mat-elevation-z1">Not active</span>
      </p>
      <p class="clearfix small rm-m mr" *ngIf="template['type']">
        <span class="chip-practice mat-elevation-z1" [class.blue]="getTypeLabel(template['type']) == 'Smile Right'">
          <mat-icon class="fas {{'fa-tooth' | translate}}" *ngIf="getTypeLabel(template['type']) == 'Smile Right'"></mat-icon>
          <mat-icon class="fas fa-user" *ngIf="getTypeLabel(template['type']) != 'Smile Right'"></mat-icon>
          {{ getTypeLabel(template['type']) | ucfirst }} Template
        </span>
      </p>

      <p class="clearfix small rm-m mr" *ngIf="template['apiKeyClient'] > 0 && isPromoterOrAdmin == true"
         matTooltip="{{ template['apiKeyClient'] }} Third-Party Application(s) are linked to  this template.">
        <span class="chip-3rd-party mat-elevation-z1">3rd Party Apps ({{ template['apiKeyClient'] }})</span>
      </p>

      <p class="clearfix small rm-m mr" *ngIf="template['apiKeyAccess'] > 0"
         matTooltip=" {{ template['apiKeyAccess'] }} Third-Party API Key(s) are linked to  this template.">
        <span class="chip-3rd-party mat-elevation-z1">API Keys ({{ template['apiKeyAccess'] }})</span>
      </p>

      <p class="rm-m mr" *ngIf="template.applyCount >= 0"
         matTooltip="This Template has been applied {{ template.applyCount }} Time(s)">
        <mat-icon class="fas fa-chart-line"></mat-icon>
        <span class="badge mat-elevation-z1" [endVal]="template.applyCount || 0" countUp></span>
      </p>

      <p class="small rm-m mr" *ngIf="template.created_at"
         matTooltip="Created at {{ template.created_at | customDateTime }}">
        <mat-icon class="fas fa-clock"></mat-icon>
        {{ template.created_at | TimeLabelPast }} ago
      </p>
    </div>
    <div class="action-button pull-right">
      <button class="pull-right action-button-template"
        *ngIf="template && (template.type != 'default' || (template.type == 'default' && isPromoterOrAdmin == true))"
              [matMenuTriggerFor]="menu" mat-mini-fab color="accent">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button
          *ngIf="template && (template.type != 'default' || (template.type == 'default' && isPromoterOrAdmin == true))"
          (click)="edit()" mat-menu-item>
          <mat-icon>edit</mat-icon>
          <span>Edit Template</span>
        </button>
        <button *ngIf="template.userID && isPromoterOrAdmin == true" (click)="viewMerchant(template.userID)"
                mat-menu-item>
          <mat-icon>person</mat-icon>
          <span>View Merchant</span>
        </button>

        <button *ngIf="template['apiKeyClient'] > 0 || template['apiKeyAccess'] > 0" (click)="viewAllThirdParty()"
                mat-menu-item>
          <mat-icon class="fas fa-user-shield"></mat-icon>
          <span>Third Party Links</span>
        </button>

        <hr/>
        <button *ngIf="
            template['isActive'] == true &&
            (template.type != 'default' || (template.type == 'default' && isPromoterOrAdmin == true))
          " (click)="disable()" mat-menu-item>
          <mat-icon>cancel</mat-icon>
          <span>Disable</span>
        </button>
        <button *ngIf="
            template['isActive'] != true &&
            (template.type != 'default' || (template.type == 'default' && isPromoterOrAdmin == true))
          " (click)="enable()" mat-menu-item>
          <mat-icon>check_circle</mat-icon>
          <span>Enable</span>
        </button>

        <button *ngIf="template.type != 'default' || (template.type == 'default' && isPromoterOrAdmin == true)"
                (click)="remove()" mat-menu-item>
          <mat-icon>delete</mat-icon>
          <span>Remove</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <mat-dialog-content class="no-scroll">
    <div class="full-width flex gallery-display-area" *ngIf="
        template &&
        template.content &&
        (displaySelectDocuments == true ||
          displaySelectImages == true ||
          displaySelectTreatmentPlan == true ||
          displaySelectTags == true ||
          displaySelectTreatmentValue == true ||
          displaySelectMessage == true ||
          displaySelectDiscount == true ||
          displaySelectHealthHistory == true ||
          displaySelectGoogleReview == true)
      ">
      <div class="full-width preview-pane-content-parent">
        <div class="full-width flex clearfix">
          <div class="preview-pane-content preview-pane-content-all full-width flex">
            <div class="full-width mt mr ml" *ngIf="template && template.content">
              <div class="full-width health-hist-accord clearfix" *ngIf="
                  displaySelectTreatmentValue == true ||
                  displaySelectHealthHistory == true ||
                  displaySelectGoogleReview == true
                ">
                <div class="row clearfix health-items">
                  <div class="block wide" *ngIf="displaySelectTreatmentValue == true && hideTreatmentValue != true"
                    [ngClass]="{
                      'clickable-item': viewOnly != true,
                      added: selectTreatmentValue == true && (viewOnly != true || displaySelected == true)
                    }" (click)="selectTreatmentValueChange()">
                    <mat-icon class="fas fa-check-circle added-icon"
                      *ngIf="selectTreatmentValue == true && (viewOnly != true || displaySelected == true)"
                              style="color: rgb(90, 186, 71)"></mat-icon>
                    <mat-icon class="fas fa-dollar-sign"></mat-icon>
                    <hr/>
                    <h4 class="primary-color"> {{"KEYWORD.Treatment" | translate}} Value</h4>
                    <h3 class="rm-mt">{{ treatmentAmount | customCurrency }}</h3>
                  </div>

                  <div class="block yes" *ngIf="displaySelectHealthHistory == true" [ngClass]="{
                      'clickable-item': viewOnly != true,
                      added: selectHealthHistory == true && (viewOnly != true || displaySelected == true)
                    }" (click)="selectHealthHistoryChange()">
                    <mat-icon class="fas fa-check-circle added-icon"
                      *ngIf="selectHealthHistory == true && (viewOnly != true || displaySelected == true)"
                              style="color: rgb(90, 186, 71)"></mat-icon>
                    <mat-icon class="fas fa-heartbeat"></mat-icon>
                    <hr/>
                    <h4 class="primary-color">Health History</h4>
                    <h3 class="rm-mt" *ngIf="template.content.medicalHistory == true">Yes</h3>
                    <h3 class="accent-color rm-mt" *ngIf="template.content.medicalHistory != true">No</h3>
                  </div>
                  <div class="block yes" *ngIf="displaySelectGoogleReview == true" [ngClass]="{
                      'clickable-item': viewOnly != true,
                      added: selectGoogleReview == true && (viewOnly != true || displaySelected == true)
                    }" (click)="selectGoogleReviewChange()">
                    <mat-icon class="fas fa-check-circle added-icon"
                      *ngIf="selectGoogleReview == true && (viewOnly != true || displaySelected == true)"
                              style="color: rgb(90, 186, 71)"></mat-icon>
                    <mat-icon class="fas fa-star"></mat-icon>
                    <hr/>
                    <h4 class="primary-color">Google Review</h4>
                    <h3 class="rm-mt" *ngIf="template.content.googleReviewPrompt == true">Yes</h3>
                    <h3 class="accent-color rm-mt" *ngIf="template.content.googleReviewPrompt != true">No</h3>
                  </div>
                </div>
              </div>
              <div class="full-width health-hist-accord clearfix"
                   *ngIf="displaySelectMessage == true || displaySelectDiscount == true">
                <div class="row clearfix health-items flex">
                  <div class="block yes" *ngIf="displaySelectDiscount == true" [ngClass]="{
                      wide: template && template.content && template.content.tags && template.content.tags.length > 4,
                      'clickable-item': viewOnly != true,
                      added: selectDiscount == true && (viewOnly != true || displaySelected == true)
                    }" (click)="selectDiscountChange()">
                    <mat-icon class="fas fa-check-circle added-icon"
                      *ngIf="selectDiscount == true && (viewOnly != true || displaySelected == true)"
                              style="color: rgb(90, 186, 71)"></mat-icon>
                    <mat-icon class="fas fa-money-bill-wave"></mat-icon>
                    <hr/>

                    <div class="full-width">
                      <h4 class="primary-color rm-mb">Discount offered</h4>
                      <h3 class="rm-m" *ngIf="template.content.cashDiscountOffered == true">Yes</h3>
                      <h3 class="accent-color rm-m" *ngIf="template.content.cashDiscountOffered != true">No</h3>
                    </div>
                    <div class="full-width" [ngClass]="{
                        flex: template && template.content && template.content.tags && template.content.tags.length > 4
                      }">
                      <div class="full-width"
                           *ngIf="template.content.cashDiscountPercent && template.content.cashDiscountOffered == true">
                        <h4 class="primary-color rm-mb">Discount amount</h4>
                        <h3 class="rm-m">{{ template.content.cashDiscountPercent }}%</h3>
                      </div>
                      <div class="full-width"
                           *ngIf="template.content.cashDiscountExpiredDays && template.content.cashDiscountOffered == true">
                        <h4 class="primary-color rm-mb">Expire in</h4>
                        <h3 class="rm-mt">{{ template.content.cashDiscountExpiredDays }} day(s)</h3>
                      </div>
                    </div>
                  </div>

                  <div class="block yes" *ngIf="displaySelectTags == true" [ngClass]="{
                      wide: template && template.content && template.content.tags && template.content.tags.length > 4,
                      'clickable-item': viewOnly != true,
                      added: selectTags == true && (viewOnly != true || displaySelected == true)
                    }" (click)="selectTagsChange()">
                    <mat-icon class="fas fa-check-circle added-icon"
                      *ngIf="selectTags == true && (viewOnly != true || displaySelected == true)"
                              style="color: rgb(90, 186, 71)"></mat-icon>
                    <mat-icon class="fas fa-tags"></mat-icon>
                    <hr/>
                    <h4 class="primary-color">
                      Tags
                      <span class="badge mat-elevation-z1" [endVal]="template?.content?.tags?.length || 0"
                            countUp></span>
                    </h4>

                    <div class="full-width flex mb">
                      <app-tag-list class="full-width tag-list-lookup ml mr" [selectedTags]="template.content.tags"
                                    [tagFormatIsObject]="false" [title]="''" [canRemove]="false" [canCreate]="false"
                                    [directRemoveDefault]="true" [directRemove]="true" [hasFilter]="false"
                                    [canSelect]="false">
                      </app-tag-list>
                    </div>
                  </div>

                  <div class="block wide" *ngIf="displaySelectMessage == true" [ngClass]="{
                      'clickable-item': viewOnly != true,
                      added: selectMessage == true && (viewOnly != true || displaySelected == true)
                    }" (click)="selectMessageChange()">
                    <mat-icon class="fas fa-check-circle added-icon"
                      *ngIf="selectMessage == true && (viewOnly != true || displaySelected == true)"
                              style="color: rgb(90, 186, 71)"></mat-icon>
                    <mat-icon class="fas fa-comment-dots"></mat-icon>
                    <hr/>
                    <div class="full-width mb row">
                      <div class="full-width">
                        <h4 class="primary-color rm-mb">Message to {{"KEYWORD.patient" | translate}}</h4>
                        <div class="full-width flex">
                          <p class="ml mr full-width text-center" [ngClass]="{
                              small:
                                template &&
                                template.content &&
                                (!template.content.tags || (template.content.tags && template.content.tags.length <= 4))
                            }" [innerHTML]="message | safeHtml"></p>
                        </div>
                      </div>
                      <div class="full-width flex">
                        <div class="full-width" *ngIf="template.content.message.label">
                          <h4 class="primary-color rm-mb">Message template</h4>
                          <p class="rm-m">{{ template.content.message.label }}</p>
                        </div>
                        <div class="full-width">
                          <h4 class="primary-color rm-mb">Send SMS</h4>
                          <h3 class="rm-m" *ngIf="template.content.message.viaSMS == true">Yes</h3>
                          <h3 class="accent-color rm-m" *ngIf="template.content.message.viaSMS != true">No</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="full-width health-hist-accord clearfix" *ngIf="displaySelectDocuments == true">
                <div class="row clearfix health-items">
                  <div class="block wide" [ngClass]="{
                      'clickable-item': viewOnly != true,
                      added: selectDocuments == true && (viewOnly != true || displaySelected == true)
                    }" (click)="selectDocumentsChange()">
                    <mat-icon class="fas fa-check-circle added-icon"
                      *ngIf="selectDocuments == true && (viewOnly != true || displaySelected == true)"
                              style="color: rgb(90, 186, 71)"></mat-icon>
                    <mat-icon class="fas fa-file-alt"></mat-icon>
                    <hr/>
                    <h3 class="primary-color">
                      {{ "KEYWORD.treatment" | translate | titlecase }} Documents
                      <span class="badge mat-elevation-z1" [endVal]="
                          (template?.content?.treatmentDocuments?.length || 0) +
                            template?.content?.EPTreatmentDocuments?.length || 0
                        " countUp></span>
                    </h3>
                    <div class="full-width clearfix to-front mt mb">
                      <app-file-list class="clearfix file-list-invitation-template full-width"
                                     [files]="template.content.treatmentDocuments" [canEdit]="false"
                                     [justify]="'center'"
                                     [canAction]="false" [title]="null" [isModal]="false">
                      </app-file-list>
                      <app-ep-document-list class="file-list-invitation-template"
                                            [documents]="template.content.EPTreatmentDocuments" [canEdit]="false"
                                            [justify]="'center'"
                                            [canAction]="false" [title]="null" [canCheck]="false"
                                            [listViewType]="'thumbnails'"
                                            [isDownload]="isDownload" [isPrint]="isPrint" [defaultCheck]="true"
                                            [isRedirection]="isRedirection" [isModal]="false" [isPreviewModal]="false">
                      </app-ep-document-list>
                    </div>
                  </div>
                </div>
              </div>
              <div class="full-width health-hist-accord clearfix" *ngIf="displaySelectImages == true">
                <div class="row clearfix health-items">
                  <div class="block wide" [ngClass]="{
                      'clickable-item': viewOnly != true,
                      added: selectImages == true && (viewOnly != true || displaySelected == true)
                    }" (click)="selectImagesChange()">
                    <mat-icon class="fas fa-check-circle added-icon"
                      *ngIf="selectImages == true && (viewOnly != true || displaySelected == true)"
                              style="color: rgb(90, 186, 71)"></mat-icon>
                    <mat-icon class="fas fa-image"></mat-icon>
                    <hr/>
                    <h3 class="primary-color">
                      {{ "KEYWORD.treatment" | translate | titlecase }} Images
                      <span class="badge mat-elevation-z1" [endVal]="
                          (template?.content?.EPstockArtDocuments?.length || 0) +
                            template?.content?.stockArtDocuments?.length || 0
                        " countUp></span>
                    </h3>
                    <div class="full-width clearfix to-front mt mb">
                      <app-file-list class="clearfix file-list-invitation-template full-width"
                                     [files]="template.content.stockArtDocuments" [canEdit]="false" [justify]="'center'"
                                     [canAction]="false" [title]="null" [isModal]="false">
                      </app-file-list>
                      <app-ep-document-list class="file-list-invitation-template"
                                            [documents]="template.content.EPstockArtDocuments" [canEdit]="false"
                                            [justify]="'center'"
                                            [canAction]="false" [title]="null" [canCheck]="false"
                                            [listViewType]="'thumbnails'"
                                            [isDownload]="isDownload" [isPrint]="isPrint" [defaultCheck]="true"
                                            [isRedirection]="isRedirection" [isModal]="false" [isPreviewModal]="false">
                      </app-ep-document-list>
                    </div>
                  </div>
                </div>
              </div>
              <div class="full-width health-hist-accord clearfix" *ngIf="displaySelectTreatmentPlan == true">
                <div class="row clearfix health-items">
                  <div class="block wide" [ngClass]="{
                      'clickable-item': viewOnly != true,
                      added: selectTreatmentPlan == true && (viewOnly != true || displaySelected == true)
                    }" (click)="selectTreatmentPlanChange()">
                    <mat-icon class="fas fa-check-circle added-icon"
                      *ngIf="selectTreatmentPlan == true && (viewOnly != true || displaySelected == true)"
                              style="color: rgb(90, 186, 71)"></mat-icon>
                    <mat-icon class="fas fa-notes-medical"></mat-icon>
                    <hr/>
                    <h3 class="primary-color">
                      {{ "KEYWORD.treatment" | translate | titlecase }} Items
                      <span class="badge mat-elevation-z1" [endVal]="template?.content?.treatmentPlan?.length || 0"
                            countUp></span>
                    </h3>
                    <div class="full-width clearfix to-front treatment-item-parent mb">
                      <div class="treatment-item flex" *ngFor="let t of template.content.treatmentPlan">
                        <mat-card class="card full-width mt mb ml mr">
                          <div class="colour-stripe"></div>
                          <app-treatment-informed-consent-view [justSelected]="true" [isMiniList]="false"
                                                               [justify]="'center'" [productID]="t.productID"
                                                               [merchantID]="merchantID"
                                                               [preSelectedDocuments]="t.preDocuments"
                                                               [displayStockArtDocuments]="false"
                                                               [DocumentlistViewType]="'default'"
                                                               [postSelectedDocuments]="t.postDocuments"
                                                               [marketingSelectedDocuments]="t.marketingDocuments"
                                                               [isModal]="false"
                                                               [isPreviewModal]="false">
                          </app-treatment-informed-consent-view>
                        </mat-card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="left-side preview-pane-content-all" *ngIf="viewOnly != true">
        <div class="merge-options-pane-content">
          <div class="add-section full-width flex"
               *ngIf="displaySelectTreatmentValue == true && defaultTreatmentAmount !== null && hideTreatmentValue != true">
            <div class="full-width mr">
              <h3 class="sr-title rm-m accent-color">
                <mat-icon>attach_money</mat-icon>Calculator

                <mat-icon class="fas fa-undo reset-value pull-right primary-color" *ngIf="
                    template &&
                    template.content &&
                    selectTreatmentValue == true &&
                    template.content.totalTreatmentValue &&
                    template.content.totalTreatmentValue != treatmentAmount
                  " (click)="resetTreatmentValue()" style="font-size: 1rem; margin-top: 5px; margin-right: -10px"
                          matTooltip="Reset {{ 'KEYWORD.treatment' | translate | titlecase }} value"></mat-icon>
              </h3>
              <p class="small rm-mt">Calculate new {{ "KEYWORD.treatment" | translate }} value</p>

              <div class="row full-width clearfix margin-btm small-margin-b"
                   *ngIf="displaySelectTreatmentValue == true">
                <mat-checkbox [(ngModel)]="selectTreatmentValue" (ngModelChange)="changeAddReplace()"
                              name="selectTreatmentValue">
                  <span>{{ "KEYWORD.treatment" | translate | titlecase }} Value </span>
                </mat-checkbox>
              </div>

              <h4 class="full-width text-right rm-m">
                <span class="pull-left">Current</span>

                <span class="main-value"> {{ defaultTreatmentAmount | customCurrency }} </span>
              </h4>
              <h4 class="full-width text-right add-title-value rm-m"
                  [ngClass]="{ 'no-selecting-value': selectTreatmentValue != true }">
                <span class="pull-left">Add</span>
                <mat-form-field class="pull-right add-value" appearance="outline">
                  <mat-icon class="fas fa-plus small-margin-r" style="font-size: 0.8rem" matSuffix></mat-icon>

                  <input class="mat-block add-value-container" [(ngModel)]="treatmentAmount"
                         [disabled]="selectTreatmentValue != true" matInput name="treatmentAmount" placeholder="''"
                         currencyMask/>
                </mat-form-field>
              </h4>

              <hr class="full-width total-div pull-right clearfix theme-background-color-primary"/>
              <h4 class="full-width text-right rm-m primary-color" *ngIf="selectTreatmentValue == true">
                <span class="pull-left">Total</span>
                <mat-icon class="fas fa-hand-point-right small-margin-r" style="font-size: 0.8rem"></mat-icon>
                <span class="main-value">
                  {{ toNumber(defaultTreatmentAmount) + toNumber(treatmentAmount) | customCurrency }}
                </span>
              </h4>

              <h4 class="full-width text-right rm-m primary-color" *ngIf="selectTreatmentValue != true">
                <span class="pull-left">Total</span>
                <mat-icon class="fas fa-hand-point-right small-margin-r" style="font-size: 0.8rem"></mat-icon>
                <span class="main-value"> {{ toNumber(defaultTreatmentAmount) | customCurrency }} </span>
              </h4>
            </div>
          </div>

          <div class="add-section add-section2 full-width flex mb clearfix" *ngIf="
              displaySelectTags === true ||
              displaySelectTreatmentPlan === true ||
              displaySelectImages === true ||
              displaySelectDocuments == true
            ">
            <div class="full-width mr">
              <h3 class="sr-title accent-color rm-m">
                <mat-icon>add</mat-icon>
                Add
              </h3>
              <p class="small rm-mt">The following will be ADDED to your {{ "PHRASE.treatment plan" | translate }} </p>
              <div class="row full-width clearfix margin-btm" *ngIf="
                  displaySelectTreatmentValue == true && defaultTreatmentAmount === null && hideTreatmentValue != true
                ">
                <mat-checkbox [(ngModel)]="selectTreatmentValue" (ngModelChange)="changeAddReplace()"
                              name="selectTreatmentValue">
                  <span class="checkbox-black">{{ "KEYWORD.treatment" | translate | titlecase }} Value </span>
                </mat-checkbox>
              </div>
              <div class="row full-width clearfix margin-btm" *ngIf="displaySelectDocuments == true">
                <mat-checkbox [(ngModel)]="selectDocuments" (ngModelChange)="changeAddReplace()" name="selectDocuments">
                  <span class="checkbox-black">{{ "KEYWORD.treatment" | translate | titlecase }} Documents </span>
                </mat-checkbox>
              </div>
              <div class="row full-width clearfix margin-btm" *ngIf="displaySelectImages == true">
                <mat-checkbox [(ngModel)]="selectImages" (ngModelChange)="changeAddReplace()" name="selectImages">
                  <span class="checkbox-black">{{ "KEYWORD.treatment" | translate | titlecase }} Images </span>
                </mat-checkbox>
              </div>
              <div class="row full-width clearfix margin-btm" *ngIf="displaySelectTreatmentPlan == true">
                <mat-checkbox [(ngModel)]="selectTreatmentPlan" (ngModelChange)="changeAddReplace()"
                              name="selectTreatmentPlan">
                  <span class="checkbox-black">{{ "KEYWORD.treatment" | translate | titlecase }} Items </span>
                </mat-checkbox>
              </div>
              <div class="row full-width clearfix margin-btm" *ngIf="displaySelectTags == true">
                <mat-checkbox [(ngModel)]="selectTags" (ngModelChange)="changeAddReplace()" name="selectTags">
                  <span class="checkbox-black"> Tags </span>
                </mat-checkbox>
              </div>

              <div class="row full-width small-margin clearfix margin-btm">
                <mat-checkbox [(ngModel)]="checkboxAddAll" (ngModelChange)="allAddChecked($event)"
                              name="checkboxAddAll">
                  <strong style="color: black"> Select All </strong>
                </mat-checkbox>
              </div>
            </div>
          </div>
          <div class="override-section flex clearfix" *ngIf="
              displaySelectMessage === true ||
              displaySelectTreatmentValue == true ||
              displaySelectDiscount === true ||
              displaySelectHealthHistory == true ||
              displaySelectGoogleReview == true
            ">
            <div class="full-width mr">
              <h3 class="sr-title accent-color rm-m">
                <mat-icon>find_replace</mat-icon>
                Replace
              </h3>
              <p class="small rm-mt">The following will be REPLACED in your {{ "PHRASE.treatment plan" | translate }}
              </p>

              <div class="row full-width clearfix margin-btm" *ngIf="displaySelectMessage == true">
                <mat-checkbox [(ngModel)]="selectMessage" (ngModelChange)="changeAddReplace()" name="selectMessage">
                  <span class="checkbox-grey"> Personal message</span>
                </mat-checkbox>
              </div>
              <div class="row full-width clearfix margin-btm" *ngIf="displaySelectDiscount == true">
                <mat-checkbox [(ngModel)]="selectDiscount" (ngModelChange)="changeAddReplace()" name="selectDiscount">
                  <span class="checkbox-grey"> Discount configuration </span>
                </mat-checkbox>
              </div>
              <div class="row full-width clearfix margin-btm" *ngIf="displaySelectHealthHistory == true">
                <mat-checkbox [(ngModel)]="selectHealthHistory" (ngModelChange)="changeAddReplace()"
                              name="selectHealthHistory">
                  <span class="checkbox-grey"> Health History </span>
                </mat-checkbox>
              </div>
              <div class="row full-width clearfix margin-btm" *ngIf="displaySelectGoogleReview == true">
                <mat-checkbox *ngIf="merchant && merchant['Google_Review_Place_ID']" [(ngModel)]="selectGoogleReview"
                              (ngModelChange)="changeAddReplace()" name="selectGoogleReview">
                  <span class="checkbox-grey"> Google Review </span>
                </mat-checkbox>
              </div>
              <div class="row full-width clearfix small-margin margin-btm">
                <mat-checkbox [(ngModel)]="checkboxOverrideAll" (ngModelChange)="allOverrideChecked($event)"
                              name="checkboxOverrideAll">
                  <strong style="color: black"> Select All </strong>
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="button-row text-center" style="flex-direction: row-reverse">
    <div class="full-width smt" *ngIf="viewOnly != true">
      <button class="btn pull-left" (click)="closeModalEvent()" mat-raised-button color="primary">Close</button>
      <button class="btn pull-right" [disabled]="
          checkboxAddAll != true &&
          selectDocuments != true &&
          selectImages != true &&
          selectTreatmentPlan != true &&
          selectTags != true &&
          checkboxOverrideAll != true &&
          selectMessage != true &&
          selectTreatmentValue != true &&
          selectDiscount != true &&
          selectHealthHistory != true &&
          selectGoogleReview != true
        " (click)="sendResult()" mat-raised-button color="accent">
        Apply Template
      </button>
    </div>
    <div class="full-width text-center smt" *ngIf="viewOnly == true">
      <button class="btn" (click)="closeModalEvent()" mat-raised-button color="primary">Close</button>
    </div>
  </mat-dialog-actions>
</div>
