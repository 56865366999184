import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { ArchwizardModule } from 'angular-archwizard';
import { NgxGaugeModule } from 'ngx-gauge';
// import { SignaturePadModule } from 'angular2-signaturepad';
import { MomentModule } from 'ngx-moment';
import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '../../shared/shared.module';
import { ActionLogModule } from '../action-log/action-log.module';
import { BankAccountModule } from '../bank-account/bank-account.module';
import { ContractModule } from '../contract/contract.module';
import { DentistModule } from '../dentist/dentist.module';
import { MessageModule } from '../message/message.module';
import { OperatorModule } from '../operator/operator.module';
import { TreatmentModule } from '../treatment/treatment.module';
import { QuotationContractViewComponent } from './quotation-contract-view/quotation-contract-view.component';
import { QuotationCreateItemComponent } from './quotation-create-item/quotation-create-item.component';
import { QuotationDecisionModalComponent } from './quotation-decision-modal/quotation-decision-modal.component';
import { QuotationDetailViewComponent } from './quotation-detail-view/quotation-detail-view.component';
import { QuotationDocumentCreateComponent } from './quotation-document-create/quotation-document-create.component';
import { QuotationInvitationCreateComponent } from './quotation-invitation-create/quotation-invitation-create.component';
import { QuotationListGlobalComponent } from './quotation-list-global/quotation-list-global.component';
import { QuotationListOverviewComponent } from './quotation-list-overview/quotation-list-overview.component';
import { QuotationListComponent } from './quotation-list/quotation-list.component';
import { QuotationRoutingModule } from './shared/quotation-routing.module';
import { QuotationService } from './shared/quotation.service';
import { CardModule } from '../card/card.module';
import { CustomerModule } from '../customer/customer.module';

@NgModule({
  imports: [
    NgPipesModule,
    CommonModule,
    MomentModule,
    FormsModule,
    ReactiveFormsModule,
    CdkTableModule,
    ArchwizardModule,
    // SignaturePadModule,
    SharedModule,
    CustomerModule,
    OperatorModule,
    QuotationRoutingModule,
    ContractModule,
    ActionLogModule,
    DentistModule,
    TreatmentModule,
    MatSortModule,
    NgxGaugeModule,
    BankAccountModule,
    MessageModule,
    CardModule
  ],
  declarations: [
    QuotationListOverviewComponent,
    QuotationListComponent,
    QuotationListGlobalComponent,
    QuotationDetailViewComponent,
    QuotationInvitationCreateComponent,
    QuotationCreateItemComponent,
    QuotationContractViewComponent,
    QuotationDocumentCreateComponent,
    QuotationDecisionModalComponent,
  ],
  exports: [
    QuotationListOverviewComponent,
    QuotationListComponent,
    QuotationListGlobalComponent,
    QuotationDetailViewComponent,
    QuotationInvitationCreateComponent,
    QuotationCreateItemComponent,
    QuotationContractViewComponent,
    QuotationDocumentCreateComponent,
    QuotationDecisionModalComponent,
  ],
  providers: [QuotationService],
})
export class QuotationModule {}
