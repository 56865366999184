import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UtilsClass } from '../../../../shared/types/utils/utils.class';

@Injectable()
export class InvitationService {
  private baseUrl = environment.nodeUrl;

  constructor(private httpClient: HttpClient) {}

  getSuitableProduct(payload, isCold = false) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    if (isCold == true) {
      return this.httpClient
        .get(environment.nodeUrl + '/suitableProduct/cold/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/suitableProduct/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  cancelSettlement(id, isAdminOrPromoter = false, applyGroup = false): Observable<any> {
    const p = {
      applyGroup,
    };

    if (isAdminOrPromoter === true) {
      return this.httpClient
        .put(`${environment.nodeUrl}/drawDown/cancel/global/${id}`, p)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .put(`${environment.nodeUrl}/drawDown/cancel/${id}`, p)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }


  getCustomerProspectProfilePicStreamLink(ID, LastModified = null) {
    if (
      LastModified &&
      LastModified != '' &&
      LastModified != undefined &&
      LastModified != 'null' &&
      LastModified != 'undefined '
    ) {
      return `${environment.nodeUrl}/customer-prospect/profile-picture-stream-cached/${LastModified}/` + ID;
    } else if (ID) {
      return `${environment.nodeUrl}/customer-prospect/profile-picture-stream/` + ID;
    }
    return null;
  }

  getGUID(isMembership = false) {
    if (isMembership == true) {
      return this.httpClient.get(environment.nodeUrl + '/invitation-membership/guid').pipe(
        map((res) => {
          return res['data'];
        })
      );
    } else {
      return this.httpClient.get(environment.nodeUrl + '/invitation/guid').pipe(
        map((res) => {
          return res['data'];
        })
      );
    }
  }

  requestProspectCode(id): Observable<any> {
    return this.httpClient
      .post(environment.nodeUrl + `/invitation/request-code-prospect/${id}`, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  validateProspectCode(id, body): Observable<any> {
    return this.httpClient
      .post(environment.nodeUrl + `/invitation/validate-code-prospect/${id}`, body)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  cloneInvitation(id, isPromoterOrAdmin = false, isMembership = false) {
    if (isPromoterOrAdmin == true) {
      if (isMembership == true) {
        return this.httpClient.put(environment.nodeUrl + '/invitation-membership/global/clone/' + id, {}).pipe(
          map((res) => {
            return res['data'];
          })
        );
      } else {
        return this.httpClient.put(environment.nodeUrl + '/invitation/global/clone/' + id, {}).pipe(
          map((res) => {
            return res['data'];
          })
        );
      }
    } else {
      if (isMembership == true) {
        return this.httpClient.put(environment.nodeUrl + '/invitation-membership/clone/' + id, {}).pipe(
          map((res) => {
            return res['data'];
          })
        );
      } else {
        return this.httpClient.put(environment.nodeUrl + '/invitation/clone/' + id, {}).pipe(
          map((res) => {
            return res['data'];
          })
        );
      }
    }
  }

  clearPatientDetails(id, isPromoterOrAdmin = false) {
    if (isPromoterOrAdmin == true) {
      return this.httpClient.delete(environment.nodeUrl + '/invitation/patient/global/' + id, {}).pipe(
        map((res) => {
          return res['data'];
        })
      );
    } else {
      return this.httpClient.delete(environment.nodeUrl + '/invitation/patient/' + id, {}).pipe(
        map((res) => {
          return res['data'];
        })
      );
    }
  }

  markAsViewed(id, isMembership = false) {
    if (isMembership == true) {
      return this.httpClient.put(environment.nodeUrl + '/invitation-membership/view/' + id, {}).pipe(
        map((res) => {
          return res['data'];
        })
      );
    } else {
      return this.httpClient.put(environment.nodeUrl + '/invitation/view/' + id, {}).pipe(
        map((res) => {
          return res['data'];
        })
      );
    }
  }

  getOverviewStats(payload = {}, isPromoterOrAdmin = false, isMembership = false) {
    if (isPromoterOrAdmin == true) {
      let params: HttpParams = new HttpParams();
      let options = {};

      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };

      if (isMembership == true) {
        return this.httpClient.get(environment.nodeUrl + '/invitation-overview/global', options).pipe(
          map((res) => {
            return res['data'];
          })
        );
      } else {
        return this.httpClient.get(environment.nodeUrl + '/invitation-overview/global', options).pipe(
          map((res) => {
            return res['data'];
          })
        );
      }
    } else {
      if (isMembership == true) {
        return this.httpClient.get(environment.nodeUrl + '/invitation-overview').pipe(
          map((res) => {
            return res['data'];
          })
        );
      } else {
        return this.httpClient.get(environment.nodeUrl + '/invitation-overview').pipe(
          map((res) => {
            return res['data'];
          })
        );
      }
    }
  }

  getStatistics(body, isPromoterOrAdmin = false, isMembership = false, isHidden = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };
    if (isHidden == true) {
      if (isPromoterOrAdmin == true) {
        if (isMembership == true) {
          return this.httpClient
            .get(environment.nodeUrl + '/invitation-membership/statistics-hidden/global', options)
            .pipe(map((res: HttpResponse<any>) => res['data']));
        } else {
          return this.httpClient
            .get(environment.nodeUrl + '/invitation/statistics-hidden/global', options)
            .pipe(map((res: HttpResponse<any>) => res['data']));
        }
      } else {
        if (isMembership == true) {
          return this.httpClient
            .get(environment.nodeUrl + '/invitation-membership/statistics-hidden', options)
            .pipe(map((res: HttpResponse<any>) => res['data']));
        } else {
          return this.httpClient
            .get(environment.nodeUrl + '/invitation/statistics-hidden', options)
            .pipe(map((res: HttpResponse<any>) => res['data']));
        }
      }
    } else {
      if (isPromoterOrAdmin == true) {
        if (isMembership == true) {
          return this.httpClient
            .get(environment.nodeUrl + '/invitation-membership/statistics/global', options)
            .pipe(map((res: HttpResponse<any>) => res['data']));
        } else {
          return this.httpClient
            .get(environment.nodeUrl + '/invitation/statistics/global', options)
            .pipe(map((res: HttpResponse<any>) => res['data']));
        }
      } else {
        if (isMembership == true) {
          return this.httpClient
            .get(environment.nodeUrl + '/invitation-membership/statistics', options)
            .pipe(map((res: HttpResponse<any>) => res['data']));
        } else {
          return this.httpClient
            .get(environment.nodeUrl + '/invitation/statistics', options)
            .pipe(map((res: HttpResponse<any>) => res['data']));
        }
      }
    }
  }

  getInvitations(body, isPromoterOrAdmin = false, isMembership = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };
    if (isPromoterOrAdmin == true) {
      if (isMembership == true) {
        return this.httpClient.get(environment.nodeUrl + '/invitation-membership/global', options);
      } else {
        return this.httpClient.get(environment.nodeUrl + '/invitation/global', options);
      }
    } else {
      if (isMembership == true) {
        return this.httpClient.get(environment.nodeUrl + '/invitation-membership/', options);
      } else {
        return this.httpClient.get(environment.nodeUrl + '/invitation/', options);
      }
    }
  }

  getInvitationList(body, isPromoterOrAdmin = false, isMembership = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };
    if (isPromoterOrAdmin == true) {
      if (isMembership == true) {
        return this.httpClient
          .get(environment.nodeUrl + '/invitation-membership/global', options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .get(environment.nodeUrl + '/invitation/global', options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (isMembership == true) {
        return this.httpClient
          .get(environment.nodeUrl + '/invitation-membership/', options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .get(environment.nodeUrl + '/invitation/', options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  getDraftInvitations(body, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };
    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .get(environment.nodeUrl + '/userData/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/userData/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getDashbordStatistic(body, isPromoterOrAdmin = false, isMembership = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };
    if (isPromoterOrAdmin == true) {
      if (isMembership == true) {
        return this.httpClient
          .get(environment.nodeUrl + '/invitation-membership/dashboard/global', options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .get(environment.nodeUrl + '/invitation/dashboard/global', options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (isMembership == true) {
        return this.httpClient
          .get(environment.nodeUrl + '/invitation-membership/dashboard', options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .get(environment.nodeUrl + '/invitation/dashboard', options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  deleteInvitation(id, isPromoterOrAdmin = false, isMembership = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      if (isMembership == true) {
        return this.httpClient
          .delete(environment.nodeUrl + '/invitation-membership/global/' + id)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .delete(environment.nodeUrl + '/invitation/global/' + id)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (isMembership == true) {
        return this.httpClient
          .delete(environment.nodeUrl + '/invitation-membership/' + id)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .delete(environment.nodeUrl + '/invitation/' + id)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  delteUserData(id): Observable<any> {
    return this.httpClient
      .delete(environment.nodeUrl + '/userData/' + id)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getListExistingID(existingPatientId, body, isMembership = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };
    if (isMembership == true) {
      return this.httpClient
        .get(environment.nodeUrl + '/invitation-membership/existing-patient/' + existingPatientId, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/invitation/existing-patient/' + existingPatientId, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getGlobalListExistingID(existingPatientId, body, isMembership = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };
    if (isMembership == true) {
      return this.httpClient
        .get(environment.nodeUrl + '/invitation-membership/existing-patient/global/' + existingPatientId, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/invitation/existing-patient/global/' + existingPatientId, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getInvitationsConsumer(body = {}, isMembership = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };

    if (isMembership == true) {
      return this.httpClient
        .get(environment.nodeUrl + '/invitation-membership/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/invitation/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getInvitationsGlobal(body, isMembership = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };

    if (isMembership == true) {
      return this.httpClient.get(environment.nodeUrl + '/invitation-membership/global', options);
    } else {
      return this.httpClient.get(environment.nodeUrl + '/invitation/global', options);
    }
  }

  /**
   * Description
   * Get Invitations details
   */
  getInvitationDetails(ID, payload = {}, sessionType, isMembership = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (isMembership == true) {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.httpClient
          .get(environment.nodeUrl + '/invitation-membership/global/' + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
        return this.httpClient
          .get(environment.nodeUrl + '/invitation-membership/' + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .get(environment.nodeUrl + '/invitation-membership/public/' + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.httpClient
          .get(environment.nodeUrl + '/invitation/global/' + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
        return this.httpClient
          .get(environment.nodeUrl + '/invitation/' + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .get(environment.nodeUrl + '/invitation/public/' + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  getInvitationPatient(ID, payload = {}, isMembership = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    if (isMembership == true) {
      return this.httpClient
        .get(environment.nodeUrl + '/invitation-membership/patient/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/invitation/patient/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getInvitationByWakandaIDorID(ID, payload = {}, isMembership = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (isMembership == true) {
      return this.httpClient
        .get(environment.nodeUrl + '/invitation-menbership/wakandaID-ID/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/invitation/wakandaID-ID/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  /**
   * Description
   * Get Invitations statistics
   */
  getInvStatistics(isMembership = false): Observable<any> {
    if (isMembership == true) {
      return this.httpClient.get(environment.nodeUrl + '/invitation-membership/statistic');
    } else {
      return this.httpClient.get(environment.nodeUrl + '/invitation/statistic');
    }
  }

  getProfilePic(ID, payload = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.httpClient
      .get(`${environment.nodeUrl}/customer-prospect/profile-picture/` + ID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getProfilePicStreamLink(ID) {
    if (ID) {
      return `${environment.nodeUrl}/customer-prospect/profile-picture-stream/` + ID;
    }
    return null;
  }

  getTreatmentPlanCost(id) {
    return this.httpClient
      .get(environment.nodeUrl + `/treatment/userData/totalTreamentCost/${id}`)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  invitationUserDataSync(body = {}): Observable<any> {
    return this.httpClient
      .post(environment.nodeUrl + `/invitation/sync`, body)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createByUserDataID(id, body = {}, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .post(environment.nodeUrl + `/invitation/userData/global/${id}`, body)
        .pipe(map((res: Response) => res['data']));
    } else {
      return this.httpClient
        .post(environment.nodeUrl + `/invitation/userData/${id}`, null)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getProgress(id): Observable<any> {
    return this.httpClient
      .get(environment.nodeUrl + `/invitation/progress/${id}`)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  setProgress(id, step): Observable<any> {
    const payload = {
      step: step || null,
    };
    UtilsClass.savingData();
    return this.httpClient
      .put(environment.nodeUrl + `/invitation/progress/${id}`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  proceedRetail(id, body, isMembership = false): Observable<any> {
    UtilsClass.savingData();
    if (isMembership == true) {
      return this.httpClient
        .put(environment.nodeUrl + `/invitation-membership/proceed/${id}`, body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .put(environment.nodeUrl + `/invitation/proceed/retail/${id}`, body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  requestInvitationCode(id): Observable<any> {
    return this.httpClient
      .post(environment.nodeUrl + `/invitation/request-code/${id}`, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  validateInvitationCode(id, body): Observable<any> {
    return this.httpClient
      .post(environment.nodeUrl + `/invitation/validate-code/${id}`, body)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  proceedDental(id, body, isMembership = false, sessionType = 'guest'): Observable<any> {
    UtilsClass.savingData();
    if (isMembership == true) {
      return this.httpClient
        .put(environment.nodeUrl + `/invitation-membership/proceed/${id}`, body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.httpClient
          .put(environment.nodeUrl + `/invitation/proceed/dental/global/${id}`, body)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
        return this.httpClient
          .put(environment.nodeUrl + `/invitation/proceed/dental-merchant/${id}`, body)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .put(environment.nodeUrl + `/invitation/proceed/dental/${id}`, body)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  resend(id, payload, isMembership = false): Observable<any> {
    UtilsClass.savingData();

    if (isMembership == true) {
      return this.httpClient
        .put(environment.nodeUrl + `/invitation-membership/resend/${id}`, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .put(environment.nodeUrl + `/invitation/resend/${id}`, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getUserData(id, fields = null, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};
    if (fields) {
      params = params.set('fields', fields);
    }

    options = {
      params,
    };
    if (sessionType === 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .get(environment.nodeUrl + `/invitation/userData/global/${id}`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + `/invitation/userData/${id}`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getTreatmentDocument(id, payload = {}, role = 'merchant'): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (role == 'merchant') {
      return this.httpClient
        .get(environment.nodeUrl + `/invitation/treatment-document/${id}`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin') {
      return this.httpClient
        .get(environment.nodeUrl + `/invitation/treatment-document/global/${id}`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'promoter') {
      return this.httpClient
        .get(environment.nodeUrl + `/invitation/treatment-document/global/${id}`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.httpClient
        .get(environment.nodeUrl + `/invitation/treatment-document/customer/${id}`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + `/invitation/treatment-document/customer/${id}`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getTreatmentSotckArtDocument(id, payload = {}, role = 'merchant'): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (role == 'merchant') {
      return this.httpClient
        .get(environment.nodeUrl + `/invitation/treatment-stockart-document/${id}`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'admin') {
      return this.httpClient
        .get(environment.nodeUrl + `/invitation/treatment-stockart-document/global/${id}`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'promoter') {
      return this.httpClient
        .get(environment.nodeUrl + `/invitation/treatment-stockart-document/global/${id}`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (role == 'customer') {
      return this.httpClient
        .get(environment.nodeUrl + `/invitation/treatment-stockart-document/customer/${id}`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + `/invitation/treatment-stockart-document/customer/${id}`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  createColdApplication(payload) {
    return this.httpClient
      .post(environment.nodeUrl + `/invitation/cold/`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createInvitationTreatmentTemplate(invitationID, body, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .post(environment.nodeUrl + '/invitation/treatment-template/global/' + invitationID, body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .post(environment.nodeUrl + '/invitation/treatment-template/' + invitationID, body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }



  updateInvitationTreatmentTemplate(invitationID, body, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .put(environment.nodeUrl + '/invitation/treatment-template/global/' + invitationID, body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .put(environment.nodeUrl + '/invitation/treatment-template/' + invitationID, body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  clearInvitationTreatmentTemplate(invitationID, payload, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .post(environment.nodeUrl + '/invitation/treatment-template/clear/global/' + invitationID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .post(environment.nodeUrl + '/invitation/treatment-template/clear/' + invitationID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  adjustInvitationTreatmentTemplate(invitationID, payload, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .post(environment.nodeUrl + '/invitation/treatment-template/adjust/global/' + invitationID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .post(environment.nodeUrl + '/invitation/treatment-template/adjust/' + invitationID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getInformedConsent(payload, sessionType: string): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .get(environment.nodeUrl + '/informed-consent/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.httpClient
        .get(environment.nodeUrl + '/informed-consent', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer') {
      return this.httpClient
        .get(environment.nodeUrl + '/informed-consent/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  linkToInv(existingPatientID, invitationID = null): Observable<any> {
    const payload = {
      invitationID,
    };
    return this.httpClient.post(`${environment.nodeUrl}/customer-prospect/invitation/${existingPatientID}`, payload);
  }

  invitationLookup(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .get(environment.nodeUrl + '/customer-prospect/lookup/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/customer-prospect/lookup', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getInvitationCustomerProspectDetails(ID, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .get(environment.nodeUrl + '/customer-prospect/global/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.httpClient
        .get(environment.nodeUrl + '/customer-prospect/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/customer-prospect/public/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  wakandaFilesByInvitationID(ID): Observable<any> {
    const params: HttpParams = new HttpParams();
    const options = {};

    return this.httpClient
      .get(environment.nodeUrl + '/invitation/wakanda-files/invitation/' + ID, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  wakandaFilesByWakandaID(ID): Observable<any> {
    const params: HttpParams = new HttpParams();
    const options = {};

    return this.httpClient
      .get(environment.nodeUrl + '/invitation/wakanda-files/wakanda/' + ID, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  wakandaFilesByContractID(ID): Observable<any> {
    const params: HttpParams = new HttpParams();
    const options = {};

    return this.httpClient
      .get(environment.nodeUrl + '/invitation/wakanda-files/contract/' + ID, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createInvitationCommunication(body, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .post(environment.nodeUrl + '/invitation-communication/global', body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .post(environment.nodeUrl + '/invitation-communication', body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getInvitationsCommunication(body, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.httpClient.get(environment.nodeUrl + '/invitation-communication/global', options);
    } else {
      return this.httpClient.get(environment.nodeUrl + '/invitation-communication', options);
    }
  }

  getOneInvitationCommunication(ID, sessionType: string): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .get(environment.nodeUrl + '/invitation-communication/global/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.httpClient
        .get(environment.nodeUrl + '/invitation-communication/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer') {
      return this.httpClient
        .get(environment.nodeUrl + '/invitation-communication/customer/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/invitation-communication/public' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  resendInvitationCommunication(id, payload): Observable<any> {
    UtilsClass.savingData();

    return this.httpClient
      .put(environment.nodeUrl + `/invitation-communication/resend/${id}`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  deleteInvitationCommunication(id, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .delete(environment.nodeUrl + '/invitation-communication/global/' + id)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .delete(environment.nodeUrl + '/invitation-communication/' + id)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  hasAppointmentBooked(id) {
    return this.httpClient
      .get(`${environment.nodeUrl}/invitation/appointment/${id}`)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  calculateDiscount(payload, sessionType: string): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .post(environment.nodeUrl + `/invitation/discount/global`, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .post(environment.nodeUrl + `/invitation/discount`, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  createProspect(payload, sessionType: string): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .post(environment.nodeUrl + `/invitation/prospect/global`, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .post(environment.nodeUrl + `/invitation/prospect`, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  triggerWebhook(id, code, transactionToken = null, transactionWebhookURL = null): Observable<any> {
    const payload = {
      code,
      transactionToken,
      transactionWebhookURL,
    };
    return this.httpClient.post(`${environment.nodeUrl}/invitation/web-hook/${id}`, payload);
  }

  getInvitationByExternalID(ID, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .get(environment.nodeUrl + '/invitation/external/global/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/invitation/external/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  createQuickInvitation(payload, sessionType: string): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .post(environment.nodeUrl + `/invitation/quick/global`, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .post(environment.nodeUrl + `/invitation/quick`, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
}
