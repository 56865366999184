import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-getting-started-step3-merchant',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.scss'],
})
export class GettingStartedMerchantStep3Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
