<div class="profile-content" *ngIf="patient">
  <div class="toggle-card top clearfix" *ngIf="displayTabs == true">
    <mat-button-toggle-group class="btn-group session-profile rm-m mat-elevation-z4" [(ngModel)]="settings">
      <mat-button-toggle class="full-width text-center" [value]="'patient'">
        <mat-icon class="fas fa-user-edit"></mat-icon>
        {{ "KEYWORD.patient" | translate | titlecase }} Details
      </mat-button-toggle>

      <mat-button-toggle class="full-width text-center" [value]="'treatment'">
        <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
        {{ "KEYWORD.treatment" | translate | titlecase }} Details
      </mat-button-toggle>

      <mat-button-toggle class="full-width text-center" *ngIf="isModulePatientHistoryActive == true && context!='p4p' " [value]="'health'">
        <mat-icon class="fas fa-heartbeat"></mat-icon>
        Health History
      </mat-button-toggle>

      <!-- <mat-button-toggle class="full-width text-center" [value]="'finance'">
        <mat-icon class="fas fa-hand-holding-usd"></mat-icon> {{"Settlements" | translate}}
      </mat-button-toggle> -->

      <mat-button-toggle class="full-width text-center"
                         *ngIf="isModuleAppointmentActive == true && isAppointmentDisabled != true && context!='p4p'"
                         [value]="'appointments'">
        <mat-icon class="fas fa-calendar-check"></mat-icon>
        {{ "KEYWORD.patient" | translate | titlecase }} Appointments
      </mat-button-toggle>

      <mat-button-toggle class="full-width text-center" *ngIf="isModulePatientDocumentActive == true"
                         [value]="'documents'">
        <mat-icon class="fas fa-file-medical"></mat-icon>
        {{ "KEYWORD.patient" | translate | titlecase }} Documents
      </mat-button-toggle>

      <mat-button-toggle class="full-width text-center" [value]="'contact'">
        <mat-icon class="fas fa-phone"></mat-icon>
        {{ "KEYWORD.patient" | translate | titlecase }} Contacts
      </mat-button-toggle>

      <mat-button-toggle class="full-width text-center" *ngIf="isModuleNoteFeaturesActive == true"
                         [value]="'activity-log'">
        <mat-icon class="fas fa-clipboard-list"></mat-icon>
        Audit History
      </mat-button-toggle>
    </mat-button-toggle-group>

    <button class="pull-right" *ngIf="isPromoterOrAdmin == true" [matMenuTriggerFor]="actionsMenu" mat-button>
      More
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #actionsMenu="matMenu">
      <button (click)="settings = 'message'" mat-menu-item>
        <mat-icon class="fas fa-comments"></mat-icon>
        Messages
      </button>
      <button (click)="settings = 'document'" mat-menu-item>
        <mat-icon class="fas fa-file-alt"></mat-icon>
        Documents
      </button>
    </mat-menu>
  </div>

  <div class="tab-content clearfix mt" *ngIf="settings == 'patient'">
    <!-- name and personal info -->
    <mat-card class="card full-width two-third">
      <div class="colour-stripe"></div>
      <h3 class="sr-title rm-m sm-h3">Personal Details</h3>
      <hr class="mb"/>
      <div class="row clearfix">
        <div class="row clearfix flex">
          <div class="icons-col">
            <mat-icon>person</mat-icon>
          </div>
          <div class="row-content-col">
            <div class="row clearfix flex inputFlex">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Title</mat-label>
                <mat-select class="full-width" [(ngModel)]="patient['Salutation']" placeholder="Title" name="title">
                  <mat-option *ngFor="let title of titles" [value]="title.Label">
                    {{ title.Label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Gender</mat-label>
                <mat-select class="full-width" [(ngModel)]="patient['Gender.Code']" placeholder="Gender" name="gender">
                  <mat-option *ngFor="let gender of genders" [value]="gender.Code">
                    {{ gender.Label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row clearfix flex inputFlex">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>First name</mat-label>
                <input [(ngModel)]="patient['FirstName']" name="firstname" matInput placeholder="First Name" required/>
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Middle Name</mat-label>
                <input [(ngModel)]="patient['MiddleName']" name="middlename" matInput placeholder="Middle Name"/>
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Surname</mat-label>
                <input [(ngModel)]="patient['Name']" name="lastname" matInput placeholder="Surname" required/>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </mat-card>

    <mat-card class="card full-width third">
      <div class="colour-stripe"></div>
      <h3 class="sr-title rm-m sm-h3">Upload profile picture</h3>
      <hr class="mb"/>
      <div class="row clearfix text-center mt">
        <div class="upload-container">
          <app-file-uploader class="text-center clearfix full-width noLowerPad avatarUploader"
                             [allowedExtensionTypes]="['image']" [limit]="1" [uploadDirectly]="true" [isDragable]="true"
                             [keepOriginal]="false" [isFixedAspectRatio]="true" [resizeToHeight]="400"
                             [resizeToWidth]="400"
                             (onCompleteAll)="onCompleteAll($event)">
          </app-file-uploader>
          <app-ep-document-view-image class="profile-lead-image sml" [defaultPicture]="
              'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
            " [link]="profileLink">
          </app-ep-document-view-image>
        </div>
        <p class="small">
          To upload a profile picture, please drag a <strong>.jpg or .png file</strong> into the grey area.
        </p>
      </div>
    </mat-card>

    <mat-card class="card full-width third">
      <div class="colour-stripe"></div>
      <button class="pull-right edit-button" *ngIf="myMedicare.number"
              (click)="removeHealthcare(myMedicare, 'Medicare Card')" mat-flat-button>
        Remove
        <mat-icon>clear</mat-icon>
      </button>
      <button class="pull-right" *ngIf="myMedicare.number" (click)="modifyHealthcare(myMedicare, 'AU_IDM')"
              mat-icon-button>
        <mat-icon>edit</mat-icon>
      </button>
      <h3 class="sr-title rm-m sm-h3">Medicare details</h3>
      <hr class="mb"/>

      <button *ngIf="!myMedicare.number" (click)="modifyHealthcare(myMedicare, 'AU_IDM')" mat-raised-button
              color="primary">
        Add medicare details
      </button>
      <!-- display medicare card -->
      <app-credit-card-view *ngIf="myMedicare.number" [cardType]="'health'" [cardBrand]="'medicare'" [flippable]="false"
                            [name]="patient['CalculatedName']" [number]="myMedicare.number"
                            [expiry]="myMedicare.validTo | customDate"
                            [reference]="myMedicare.referenceNumber"></app-credit-card-view>
    </mat-card>

    <mat-card class="card full-width third">
      <div class="colour-stripe"></div>
      <button class="pull-right edit-button" *ngIf="myPrivateHealthFund.number"
              (click)="removeHealthcare(myPrivateHealthFund, 'Private Health Insurance Card')" mat-flat-button>
        Remove
        <mat-icon>clear</mat-icon>
      </button>
      <button class="pull-right" *ngIf="myPrivateHealthFund.number"
              (click)="modifyHealthcare(myPrivateHealthFund, 'AU_PHF')" mat-icon-button>
        <mat-icon>edit</mat-icon>
      </button>
      <h3 class="sr-title rm-m sm-h3">Private Health Insurance details</h3>
      <hr class="mb"/>

      <button *ngIf="!myPrivateHealthFund.number" (click)="modifyHealthcare(myPrivateHealthFund, 'AU_PHF')"
              mat-raised-button color="primary">
        Add health fund details
      </button>
      <!-- display health card -->
      <app-credit-card-view *ngIf="myPrivateHealthFund.number" [cardType]="'health'"
                            [cardBrand]="myPrivateHealthFund.issuerCode" [flippable]="false"
                            [name]="patient['CalculatedName']"
                            [number]="myPrivateHealthFund.number" [expiry]="''"
                            [reference]="myPrivateHealthFund.referenceNumber">
      </app-credit-card-view>

      <p class="small text-center" *ngIf="myPrivateHealthFund.issuerName && myPrivateHealthFund.issuerCode != 'OTHRS'">
        <strong>Fund name:</strong>
        {{ myPrivateHealthFund.issuerName }}
      </p>

      <p class="small text-center"
         *ngIf="myPrivateHealthFund.issuerNameOther && myPrivateHealthFund.issuerCode == 'OTHRS'">
        <strong>Fund name:</strong>
        {{ myPrivateHealthFund.issuerNameOther }}
      </p>
    </mat-card>

    <!-- additional -->
    <mat-card class="card full-width third">
      <div class="colour-stripe"></div>
      <h3 class="sr-title rm-m sm-h3">Additional Information</h3>
      <hr class="mb"/>
      <div class="clearfix flex">
        <app-date-input class="full-width" [startView]="'multi-year'" [label]="'Date Of Birth'"
                        [defaultDate]="patient['DateOfBirth']" (selectedDate)="getDate($event)"
                        name="DateOfBirth"></app-date-input>
      </div>
      <mat-form-field class="full-width" *ngIf="isPromoterOrAdmin == true" appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select class="full-width" [(ngModel)]="patient['Status']" name="status" placeholder="Status">
          <mat-option *ngFor="let status of statusList" [value]="status.Code">
            {{ status.Label }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="full-width" *ngIf="isPromoterOrAdmin == true" appearance="outline">
        <mat-label>Account Manager</mat-label>
        <mat-select class="full-width" [(ngModel)]="patient['AccountManager_key']" name="accountManager"
                    placeholder="Account Manager">
          <mat-option *ngFor="let item of accountManagers" [value]="item.ID">
            {{ item.CalculatedName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Relationship status</mat-label>
        <mat-select class="full-width" [(ngModel)]="patient['RelationshipStatus.Code']" name="relationshipStatus"
                    placeholder="Relationship Status">
          <mat-option *ngFor="let r of relationshipList" [value]="r.Code">
            {{ r.Label }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <app-occupation-input class="full-width" [isRequired]="false" [occupationLabel]="patient['Occupation']"
                            (validate)="validateOccupationEvent($event)" (getOccupation)="getOccupation($event)">
      </app-occupation-input>

      <!-- <mat-checkbox name="referredCustomer">Referred from another practice
        </mat-checkbox> -->
    </mat-card>

    <mat-card class="card full-width two-third">
      <div class="colour-stripe"></div>
      <h3 class="sr-title rm-m sm-h3">Address details</h3>
      <hr class="mb"/>
      <app-address-input-advanced [type]="'patient'" [targetID]="patient['ID']" [targetAddressType]="addressType"
                                  [addressTypes]="addressTypes" [myPostCode]="myPostCode" [myState]="myState"
                                  [mySuburb]="mySuburb"
                                  [myStreetNumber]="myStreetNumber" [myStreetName]="myStreetName"
                                  [myUnitNumber]="myUnitNumber"
                                  [myStreetType]="myStreetType" [myLatitude]="myLatitude" [myLongitude]="myLongitude">
      </app-address-input-advanced>
    </mat-card>

    <!-- phone numbers and email -->

    <div class="full-width card contain">
      <mat-card class="card full-width">
        <div class="colour-stripe"></div>
        <h3 class="sr-title rm-m sm-h3">Contact Details</h3>
        <hr class="mb"/>
        <div class="row clearfix">
          <div class="row clearfix">
            <app-phone-input-advanced [type]="'patient'" [targetID]="patient['ID']" [targetPhoneType]="phoneType"
                                      [phoneTypes]="phoneTypes"
                                      [myPhoneNumber]="myPhoneNumber"></app-phone-input-advanced>
          </div>
          <div class="row clearfix">
            <app-mobile-input-advanced [type]="'patient'" [targetID]="patient['ID']" [targetMobileType]="mobileType"
                                       [mobileTypes]="mobileTypes" [myMobile]="myMobile"></app-mobile-input-advanced>
          </div>
          <div class="row clearfix">
            <app-email-input-advanced [type]="'patient'" [targetID]="patient['ID']" [targetEmailType]="emailType"
                                      [emailTypes]="emailTypes" [myEmail]="myEmail"></app-email-input-advanced>
          </div>
        </div>
      </mat-card>

      <mat-card class="card full-width" [hidden]="authenticationMethodNotFound == true">
        <div class="colour-stripe"></div>

        <app-authentication-method-view [cardID]="patient['ID']" [title]="'Authentication Methods'"
                                        (notFound)="notFoundAuthenticationMethod($event)">
        </app-authentication-method-view>
      </mat-card>

      <mat-card class="card full-width rmmb">
        <div class="colour-stripe"></div>
        <!--  filter tags -->
        <button class="addDocument pull-right mb" (click)="createTag()" mat-raised-button color="primary"
                matTooltip="Add Tag(s) to this patient">
          <mat-icon>edit</mat-icon>
          Edit Tags
        </button>

        <h3 class="sr-title rm-m sm-h3">Filter Tags</h3>
        <hr class="mb"/>
        <p class="small">
          Use tags to make it easier to categorize and find {{ "KEYWORD.patient" | translate }}s when sending
          invitations and marketing campaigns.
        </p>
        <div class="row full-widthclearfix">
          <mat-chip-list class="full-width clearfix" #chipList name="tags-patient">
            <mat-chip *ngFor="let tag of patientTags" [selectable]="false" [removable]="false">
              {{ tag }}
            </mat-chip>
          </mat-chip-list>

          <div class="full-width flex">
            <div class="full-width mt well" *ngIf="!patientTags || (patientTags && patientTags.length <= 0)">
              <p class="full-width text-center">No Tag is added to this {{"KEYWORD.patient" | translate}}</p>
            </div>
          </div>
        </div>
        <!-- end filter tags -->
      </mat-card>
    </div>

    <mat-card class="card full-width">
      <div class="colour-stripe"></div>
      <h3 class="sr-title rm-m sm-h3">Preferences</h3>
      <hr class="mb"/>
      <div class="row clearfix">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Contact Method</mat-label>
          <mat-select [(ngModel)]="patient['PreferredContactMethod.Code']" name="contactMethod"
                      placeholder="Contact Method">
            <mat-option *ngFor="let contactMethod of preferredContactMethodOptions" [value]="contactMethod.Code">
              {{ contactMethod.Label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Contact Time</mat-label>
          <mat-select [(ngModel)]="patient['PreferredContactTime.Code']" name="contactTime" placeholder="Contact Time">
            <mat-option *ngFor="let contactTime of preferredContactTimeOptions" [value]="contactTime.Code">
              {{ contactTime.Label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Message Type</mat-label>
          <mat-select [(ngModel)]="patient['PreferredMessageType.Code']" name="messageType" placeholder="Message Type">
            <mat-option *ngFor="let messageType of preferredMessageTypeOptions" [value]="messageType.Code">
              {{ messageType.Label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Referral Source</mat-label>
          <mat-select [(ngModel)]="patient['ReferralSource.Category']" (ngModelChange)="getReferralSpecific($event)"
                      name="referralSource" placeholder="Referral Source">
            <mat-option *ngFor="let source of referralCategories" [value]="source">
              {{ source }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Referral Specifics</mat-label>
          <mat-select [(ngModel)]="patient['ReferralSource.Specific']" name="referralSpecifics"
                      placeholder="Referral Specifics">
            <mat-option *ngFor="let specific of referralSpecifics" [value]="specific">
              {{ specific }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="consent">
          <ng-container *ngIf="isPromoterOrAdmin == true">
            <hr/>
            <div class="row clearfix flex">
              <mat-checkbox class="full-width mt" [(ngModel)]="myElectronicCommsConsent" [disableRipple]="true"
                            [labelPosition]="end" (click)="$event.preventDefault()" name="electronic">
                Electronic Communication
              </mat-checkbox>
              <p class="full-width" *ngIf="myElectronicCommsConsent == true">
                {{ patient['ElectronicCommsConsent.When'] | customDateTime }}
              </p>
            </div>

            <div class="row clearfix flex">
              <mat-checkbox class="full-width mt" [(ngModel)]="myDataConsent" [disableRipple]="true"
                            [labelPosition]="end" (click)="$event.preventDefault()" name="electronic">
                Data Storage
              </mat-checkbox>
              <p class="full-width" *ngIf="myDataConsent == true">{{ patient['DataConsent.When'] | customDateTime }}</p>
            </div>

            <div class="row clearfix flex">
              <mat-checkbox class="full-width mt" [(ngModel)]="myPrivacyConsent" [disableRipple]="true"
                            [labelPosition]="end" (click)="$event.preventDefault()" name="electronic">
                Privacy Consent
              </mat-checkbox>
              <p class="full-width" *ngIf="myPrivacyConsent == true">
                {{ patient['PrivacyConsent.When'] | customDateTime }}
              </p>
            </div>
          </ng-container>
          <hr>
          <p class="small">Check this box if your Customer wishes to receive marketing
            emails or sms from your {{ "KEYWORD.Practice" | translate}}.</p>




          <div class="row clearfix flex">
            <mat-checkbox class="full-width mt"  name="electronic"
              [(ngModel)]="marketingConsent">
              Marketing Material - SMS
            </mat-checkbox>
            <p *ngIf="marketingConsent==true" class="full-width">
              {{patient['MarketingConsent.When'] | customDateTime}}</p>
          </div>

          <div class="row clearfix flex">
            <mat-checkbox  class="full-width mt"  name="marketingConsentEmail"
              [(ngModel)]="marketingConsentEmail">
              Marketing Material - Email
            </mat-checkbox>
            <p *ngIf="marketingConsentEmail==true && patient['MarketingConsent_Email.When']" class="full-width">
              {{patient['MarketingConsent_Email.When'] | customDateTime}}</p>
          </div>
        </div>
      </div>
    </mat-card>

    <div class="row clearfix text-center full-width" *ngIf="isIntegrated == true">
      <button class="pull-left text-center btn-large" (click)="goBackEvent()" mat-raised-button color="primary">
        <mat-icon>chevron_left</mat-icon>
        Go back
      </button>

      <button class="text-center btn-large save-button-patient" (click)="savePatientDetails()" mat-raised-button
              color="accent">
        <mat-icon>save</mat-icon>
        Save
      </button>
    </div>
    <div class="row clearfix text-center full-width" *ngIf="isIntegrated != true">
      <button class="pull-right saveBtn save-button-patient" (click)="savePatientDetails()" mat-raised-button
              color="accent">
        <mat-icon>save</mat-icon>
        Save
      </button>
    </div>
  </div>

  <!-- documents -->
  <div class="tab-content clearfix mt text-center" *ngIf="settings == 'documents'">
    <app-patient-document-create [patientID]="patient['ID']"></app-patient-document-create>
  </div>

  <div class="tab-content clearfix mt text-center" *ngIf="settings == 'appointments'">
    <app-appointment-list class="full-width clearfix mt" [appointmentID]="appointmentID"
                          [title]="patient?.FirstName + ' \'s Appointments'"
                          [patientID]="patient['ID']"></app-appointment-list>
  </div>

  <div class="tab-content clearfix" *ngIf="settings == 'treatment'">
    <div class="row clearfix full-width" *ngIf="!myInvitationID">
      <app-invitation-list-global *ngIf="isPromoterOrAdmin == true" [existingPatientID]="patient['ExistingPerson_key']"
                                  [showInvDetailInList]="true">
      </app-invitation-list-global>
      <app-invitation-list *ngIf="isPromoterOrAdmin == false" [existingPatientID]="patient['ExistingPerson_key']"
                           [showInvDetailInList]="true">
      </app-invitation-list>
    </div>
    <div class="row clearfix full-width detailInv" *ngIf="myInvitationID">
      <app-invitation-view [invitationID]="myInvitationID"></app-invitation-view>
      <button class="absPos botPos" (click)="displayInvitationList()" mat-raised-button color="accent">
        <mat-icon>chevron_left</mat-icon>
        Show this {{ "KEYWORD.patient" | translate }}'s invitations
      </button>
    </div>
  </div>

  <div class="tab-content noMarg clearfix" *ngIf="settings == 'finance'">
    <div class="full-width toggle-card contract mb clearfix">
      <mat-button-toggle-group class="btn-group session-profile rm-m mat-elevation-z4"
                               (change)="setSubSettingView($event.value)">
        <!-- show for quotation detail view -->
        <mat-button-toggle class="full-width text-center mat-button-toggle-already-checked"
                           *ngIf="isPromoterOrAdmin == true && myQuotationID" [value]="'quotation'"
                           [matMenuTriggerFor]="applicationSubMenu">
          <mat-icon class="fas fa-hand-holding-usd"></mat-icon>
          Application Details
          <mat-icon class="fas fa-chevron-down"></mat-icon>
        </mat-button-toggle>
        <!-- show for quotation list view -->
        <mat-button-toggle class="full-width text-center" *ngIf="isPromoterOrAdmin == true && !myQuotationID"
                           [value]="'quotation'">
          <mat-icon class="fas fa-hand-holding-usd"></mat-icon>
          Application List
        </mat-button-toggle>

        <!-- show for contract detail view -->
        <mat-button-toggle class="full-width text-center mat-button-toggle-already-checked"
                           *ngIf="isPromoterOrAdmin == true && myContractID" [value]="'contract'"
                           [matMenuTriggerFor]="contractSubMenu">
          <mat-icon class="fas fa-hand-holding-usd"></mat-icon>
          Contract Details
          <mat-icon class="fas fa-chevron-down"></mat-icon>
        </mat-button-toggle>
        <!-- show for contract list view -->
        <mat-button-toggle class="full-width text-center" *ngIf="isPromoterOrAdmin == true && !myContractID"
                           [value]="'contract'">
          <mat-icon class="fas fa-hand-holding-usd"></mat-icon>
          Contract List
        </mat-button-toggle>

        <mat-button-toggle class="full-width text-center" *ngIf="isPromoterOrAdmin == true" [value]="'payment'">
          <mat-icon class="fas fa-hand-holding-usd"></mat-icon>
          Payment Methods
        </mat-button-toggle>
        <mat-button-toggle class="full-width text-center" [value]="'settlement'">
          <mat-icon class="fas fa-hand-holding-usd"></mat-icon>
          Settlement
        </mat-button-toggle>
        <mat-button-toggle class="full-width text-center" [value]="'referred'">
          <mat-icon class="fas fa-hand-holding-usd"></mat-icon>
          Referred
        </mat-button-toggle>
      </mat-button-toggle-group>

      <!-- drop menus for contract and applications -->
      <mat-menu #contractSubMenu="matMenu">
        <button [class.active]="setContractTab == 'overview'" (click)="setContractTab = 'overview'" mat-menu-item>
          Overview
        </button>
        <button [class.active]="setContractTab == 'repayment' || setContractTab == 'invoice'"
                [matMenuTriggerFor]="payments" mat-menu-item>
          Payments
        </button>
        <button [class.active]="setContractTab == 'notes'" (click)="setContractTab = 'notes'" mat-menu-item>
          Notes
        </button>
        <button [class.active]="setContractTab == 'compliance'" (click)="setContractTab = 'compliance'" mat-menu-item>
          Compliance Tasks
        </button>
        <button [class.active]="setContractTab == 'documents'" (click)="setContractTab = 'documents'" mat-menu-item>
          Documents
        </button>
        <mat-divider></mat-divider>
        <button (click)="displayContractList()" mat-menu-item>Contract List</button>
      </mat-menu>

      <mat-menu #payments="matMenu">
        <button [class.active]="setContractTab == 'repayment'" (click)="setContractTab = 'repayment'" mat-menu-item>
          Borrower Repayments
        </button>
        <button [class.active]="setContractTab == 'invoice'" (click)="setContractTab = 'invoice'" mat-menu-item>
          Invoices &amp; Settlements
        </button>
      </mat-menu>

      <mat-menu #applicationSubMenu="matMenu">
        <button [class.active]="setApplicationsTab == 0" (click)="setApplicationsTab = 0" mat-menu-item>
          Overview
        </button>
        <button [class.active]="setApplicationsTab == 1" (click)="setApplicationsTab = 1" mat-menu-item>
          Payments
        </button>
        <button [class.active]="setApplicationsTab == 2" (click)="setApplicationsTab = 2" mat-menu-item>
          Compliance Tasks
        </button>
        <button [class.active]="setApplicationsTab == 3" (click)="setApplicationsTab = 3" mat-menu-item>Notes</button>
        <button [class.active]="setApplicationsTab == 4" (click)="setApplicationsTab = 4" mat-menu-item>
          Documents
        </button>
        <mat-divider></mat-divider>
        <button (click)="displayQuotationList()" mat-menu-item>Applications List</button>
      </mat-menu>
    </div>

    <div class="full-width clearfix" *ngIf="subSettings == 'quotation' && isPromoterOrAdmin == true">
      <div class="row clearfix column" *ngIf="!myQuotationID">
        <app-quotation-list-global class="full-width" [customerID]="patient['ID']" [showDetailInList]="true"
                                   (myQuotationID)="setMyQuotationID($event)">
        </app-quotation-list-global>
      </div>

      <div class="row clearfix column" *ngIf="myQuotationID">
        <app-quotation-detail-view [quotationID]="myQuotationID" [setApplicationsTab]="setApplicationsTab">
        </app-quotation-detail-view>
      </div>
    </div>

    <div class="full-width clearfix" *ngIf="subSettings == 'referred' && isPromoterOrAdmin == true">
      <div class="row clearfix column">
        <app-quotation-contract-view [patientID]="patient['ID']" [quotationID]="myQuotationID"
                                     [setApplicationsTab]="setApplicationsTab">
        </app-quotation-contract-view>
      </div>
    </div>

    <div class="full-width clearfix" *ngIf="subSettings == 'contract' && isPromoterOrAdmin == true">
      <div class="row clearfix column" *ngIf="!myContractID">
        <app-contract-list-global class="full-width" [customerID]="patient['ID']" [showDetailInList]="true"
                                  (myContractID)="setMyContractID($event)">
        </app-contract-list-global>
      </div>

      <div class="row clearfix column" *ngIf="myContractID">
        <div class="row clearfix">
          <app-contract-detail [contractID]="myContractID" [setContractTab]="setContractTab"></app-contract-detail>
        </div>
      </div>
    </div>

    <div class="row clearfix column full-width" *ngIf="subSettings == 'payment' && isPromoterOrAdmin == true">
      <mat-card class="clearfix">
        <app-bank-account-list-user class="full-width" [userID]="patient['ID']"></app-bank-account-list-user>
      </mat-card>
    </div>

    <div class="row clearfix column full-width" *ngIf="subSettings == 'settlement'">
      <div class="row clearfix column" *ngIf="!mySettlementID">
        <app-settlement-list-global class="full-width" *ngIf="isPromoterOrAdmin == true" [customerID]="patient['ID']">
        </app-settlement-list-global>
        <app-settlement-list class="full-width" *ngIf="isPromoterOrAdmin == false" [customerID]="patient['ID']">
        </app-settlement-list>
      </div>
      <div class="row clearfix column" *ngIf="mySettlementID">
        <app-settlement-view [settlementID]="mySettlementID"></app-settlement-view>
      </div>
    </div>
  </div>

  <div class="tab-content clearfix mt"
       *ngIf="settings == 'health' && isModulePatientHistoryActive == true && healthHistoryActive == true">
    <div class="contain-medical">
      <div class="row flex flex-center full-width justify-between mb">
        <h2 class="sr-title pull-left">Detailed Health History</h2>

        <!-- <button mat-raised-button class="pull-right" color="warn" (click)="inviteMedicalHistory()"
          *ngIf="patient && patient['ID']">
          <mat-icon>add</mat-icon> New invitation
        </button> -->

        <div class="row half-width text-center clearfix" *ngIf="medicalHistoryList && medicalHistoryList.length > 0">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Previous Health History

              <span class="badge" [endVal]="medicalHistoryList.length" countUp></span>
            </mat-label>
            <mat-select class="full-width" [(ngModel)]="myMedicalHistoryID" (ngModelChange)="changeMedicalID()"
                        name="title">
              <mat-option *ngFor="let m of medicalHistoryList; let index = index" [value]="m.ID">
                {{ m['Date.Activated'] | customDateTime }} <i>({{ m['Date.Activated'] | TimeLabelPast }} ago)</i>

                <span class="ml chip mat-elevation-z2 pull-right" *ngIf="m && m['Status'] == 'Complete'"
                      [style.background]="'#00d07a'" matTooltip="Completed at: {{ m['Date.Completed'] | customDate }}">
                  {{ m['Status'] }}
                </span>

                <span class="ml chip mat-elevation-z2 pull-right" *ngIf="m && m['Status'] != 'Complete'"
                      [style.background]="'#ff5722'">
                  {{ m['Status'] }}
                </span>

                <span class="ml chip mat-elevation-z2 pull-right" *ngIf="index == 0"
                      [ngStyle]="{ 'background-color': 'rgb(90, 186, 71)' }">
                  Recent</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <mat-card class="card full-width" *ngIf="medicalHistoryInvitation && medicalHistoryInvitation.ID">
        <div class="colour-stripe"></div>

        <div class="row clearfix">
          <app-medical-history-invitation-view class="clearfix full-width" [title]="'Recent  health history invitation'"
                                               [invitation]="medicalHistoryInvitation">
          </app-medical-history-invitation-view>
        </div>
      </mat-card>

      <div class="row full-width mt clearfix"
           *ngIf="(!medicalHistoryList || (medicalHistoryList && medicalHistoryList.length <= 0)) && firstLoad == true">
        <app-empty-list-message [title]="'No Medical History'"
                                [message]="'Your ' + ('KEYWORD.patient' | translate | titlecase) + ' has no  health history'">
        </app-empty-list-message>
      </div>

      <div class="row clearfix mt full-width" *ngIf="medicalHistoryID">
        <app-medical-history-view [title]="''" [medicalHistoryID]="medicalHistoryID"></app-medical-history-view>
      </div>
    </div>
  </div>

  <div class="clearfix" *ngIf="settings == 'contact'">
    <div class="tab-content split" [hidden]="showContactCreate == true || isEditContact == true">
      <div class="card mr full-width">
        <h1 class="pos">Contact Special Setup</h1>

        <mat-card class="card">
          <div class="card-header primary-gradient-img clearfix inModal">
            <div class="row clearfix">
              <div class="row titleArea clearfix">
                <mat-icon class="pull-left">face</mat-icon>
                <h2 class="summary-header rm-m">Contacts For {{ patient['FirstName'] }}</h2>
              </div>
            </div>
          </div>
          <div class="row clearfix">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Guardian</mat-label>
              <mat-select [(ngModel)]="patient['Gardian_key']" placeholder="Guardian">
                <mat-option [value]="'none'">None</mat-option>
                <mat-option *ngFor="let contact of contactList" [value]="contact.ID">
                  {{ contact.CalculatedName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row clearfix">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Carer</mat-label>
              <mat-select [(ngModel)]="patient['Carer_key']" placeholder="Carer">
                <mat-option [value]="'none'">None</mat-option>
                <mat-option *ngFor="let contact of contactList" [value]="contact.ID">
                  {{ contact.CalculatedName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row clearfix">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Emergency Contact</mat-label>
              <mat-select [(ngModel)]="patient['Emergency_key']" placeholder="Emergency Contact">
                <mat-option [value]="'none'">None</mat-option>
                <mat-option *ngFor="let contact of contactList" [value]="contact.ID">
                  {{ contact.CalculatedName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row clearfix">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>GP Doctor</mat-label>
              <mat-select [(ngModel)]="patient['GP_key']" placeholder="GP Doctor">
                <mat-option [value]="'none'">None</mat-option>
                <mat-option *ngFor="let contact of contactList" [value]="contact.ID">
                  {{ contact.CalculatedName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row clearfix">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>{{ "PHRASE.general dentist" | translate | titlecase}} - Referred</mat-label>
              <mat-select [(ngModel)]="patient['GeneralDentist_key']"
                          [placeholder]="('PHRASE.general dentist' | translate | titlecase) + ' - Referred'">
                <mat-option [value]="'none'">None</mat-option>
                <mat-option *ngFor="let contact of contactList" [value]="contact.ID">
                  {{ contact.CalculatedName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row clearfix">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Responsible Party For Fees</mat-label>
              <mat-select [(ngModel)]="patient['ResponsiblePartyForFees_key']" placeholder="Responsible Party For Fees">
                <mat-option [value]="'none'">None</mat-option>
                <mat-option *ngFor="let contact of contactList" [value]="contact.ID">
                  {{ contact.CalculatedName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <hr class="mt"/>
          <div class="row clearfix">
            <button class="pull-left" [hidden]="showContactCreate == true" (click)="createNewContact()"
                    mat-raised-button color="primary">
              <mat-icon>group_add</mat-icon>
              New Contact
            </button>

            <button class="pull-right" (click)="modifySpecialContact()" mat-raised-button color="accent">
              <mat-icon>save</mat-icon>
              Update
            </button>
          </div>
        </mat-card>
      </div>

      <!-- <mat-card> -->
      <app-contact-list class="card full-width" *ngIf="refreshContactList == true" [cardID]="patient['ID']"
                        [type]="'patient'" [title]="'Contact list'" [simpleList]="true"
                        (openContactEdit)="openContactEditEvent($event)"></app-contact-list>
      <!-- </mat-card> -->
    </div>

    <div class="noOverflow" *ngIf="showContactCreate == true">
      <div class="row clearfix full-width contactCreate">
        <app-contact-create-edit class="full-width" [cardID]="patient['ID']" [title]="patient['CalculatedName']"
                                 [possibleTypes]="patient['PossibleContactTypes']">
        </app-contact-create-edit>
        <div class="row clearfix text-center mt">
          <button class="btn-pos mb" (click)="hideContactCreate()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>
            {{ "KEYWORD.patient" | translate | titlecase }} Detail
          </button>
        </div>
      </div>
    </div>

    <div class="noOverflow" *ngIf="isEditContact == true && selectedContactID">
      <div class="row clearfix full-width contactCreate">
        <app-contact-create-edit [contactID]="selectedContactID" [title]="patient['CalculatedName']">
        </app-contact-create-edit>

        <div class="row clearfix text-center mt">
          <button class="btn-pos mb" (click)="hideContactEdit()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>
            {{ "KEYWORD.patient" | translate | titlecase }} Detail
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="tab-content clearfix" *ngIf="settings == 'activity-log'">
    <div class="row clearfix full-width mt">
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <!-- <app-action-log-list [targetID]="patient['ID']" [withoutAutoAction]="true" [withoutInternalAction]="true">
        </app-action-log-list> -->

        <app-activity-log-list *ngIf="isPromoterOrAdmin == false" [targetID]="patient['ID']"
                               [shadow]="false"></app-activity-log-list>
        <app-activity-log-list-global *ngIf="isPromoterOrAdmin == true" [targetID]="patient['ID']" [shadow]="false">
        </app-activity-log-list-global>
      </mat-card>
    </div>
  </div>

  <div class="tab-content clearfix" *ngIf="settings == 'message' && isPromoterOrAdmin == true">
    <div class="row clearfix full-width mt">
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <app-message-timeline [cardID]="patient['ID']"></app-message-timeline>
      </mat-card>
    </div>
  </div>

  <div class="tab-content clearfix" *ngIf="settings == 'document' && isPromoterOrAdmin == true">
    <div class="row clearfix full-width">
      <app-document-list-mini [cardID]="patient['ID']"></app-document-list-mini>
    </div>
  </div>
</div>
