<h3 class="sr-title sm-h3 rm-mt">Personal Details</h3>
<form #patientForm="ngForm">
  <div class="row clearfix">
    <div class="column">
      <div class="icons-col">
        <mat-icon>person</mat-icon>
      </div>
      <div class="row-content-col">
        <div class="row clearfix">
          <div class="half-width">
            <div class="col-left">
              <!-- Gender Select -->
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Please select a gender</mat-label>
                <mat-select
                  class="full-width"
                  [(ngModel)]="patient.gender"
                  (selectionChange)="onBlurMethod(patientForm.form.valid)"
                  placeholder="Please select a gender"
                  name="gender"
                  required
                >
                  <mat-option *ngFor="let gender of genders" [value]="gender.Code">
                    {{ gender.Label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="half-width">
            <div class="col-right">
              <!-- Title Select -->
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Title</mat-label>
                <mat-select
                  class="full-width"
                  [(ngModel)]="patient.title"
                  (selectionChange)="onBlurMethod(patientForm.form.valid)"
                  placeholder="Title"
                  name="title"
                >
                  <mat-option *ngFor="let title of titles" [value]="title.Category">
                    {{ title.Label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row clearfix flex">
          <!-- first name -->
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>First Name</mat-label>
            <input
              [(ngModel)]="patient.firstname"
              (change)="onBlurMethod(patientForm.form.valid)"
              matInput
              placeholder="First Name"
              value=""
              name="firstname"
              required
            />
          </mat-form-field>
          <!-- middle name -->
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Middle Name</mat-label>
            <input
              [(ngModel)]="patient.middlename"
              matInput
              placeholder="Middle Name"
              name="middlename"
              name="middlename"
              value=""
            />
          </mat-form-field>
          <!-- surname -->
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Surname</mat-label>
            <input
              [(ngModel)]="patient.surename"
              (change)="onBlurMethod(patientForm.form.valid)"
              matInput
              placeholder="Surname"
              name="surename"
              required
            />
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="row clearfix">
    <div class="column">
      <div class="icons-col">
        <mat-icon>cake</mat-icon>
      </div>
      <div class="row-content-col">
        <div class="row clearfix">
          <!-- Date of birth -->
          <label>Date of birth</label>

          <app-date-input [minAge]="18" [maxAge]="120"></app-date-input>
        </div>
      </div>
    </div>
  </div>
  <div class="row clearfix" *ngIf="displayRelationship">
    <div class="column">
      <div class="icons-col">
        <mat-icon>group</mat-icon>
      </div>
      <div class="row-content-col">
        <div class="half-width">
          <div class="col-left">
            <div class="row clearfix">
              <!-- Relationship Status -->
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Relationship Status</mat-label>

                <mat-select
                  class="full-width"
                  [(ngModel)]="patient.relationStatus"
                  (selectionChange)="onBlurMethod(patientForm.form.valid)"
                  placeholder="Relationship Status"
                  name="relationStatus"
                  required
                >
                  <mat-option *ngFor="let relationStatus of relationshipStatus" [value]="relationStatus.Code">
                    {{ relationStatus.Label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="half-width">
          <div class="row clearfix">
            <div class="col-right">
              <!-- Dependants -->
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Number of dependants</mat-label>
                <mat-select
                  class="full-width"
                  [(ngModel)]="patient.numOfDependant"
                  (selectionChange)="onBlurMethod(patientForm.form.valid)"
                  placeholder="Number of dependants"
                  name="numOfDependant"
                  required
                >
                  <mat-option *ngFor="let num of numbers" [value]="num">
                    {{ num }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
