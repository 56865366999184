/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./referral-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./referral-list.component";
var styles_ReferralListComponent = [i0.styles];
var RenderType_ReferralListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReferralListComponent, data: { "animation": [{ type: 7, name: "fade", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: null, timings: ".6s ease" }], options: null }], options: {} }, { type: 7, name: "stagger", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 11, selector: ":enter", animation: { type: 12, timings: ".3s", animation: [{ type: 9, options: null }] }, options: { optional: true } }], options: null }], options: {} }] } });
export { RenderType_ReferralListComponent as RenderType_ReferralListComponent };
export function View_ReferralListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Referral list component"]))], null, null); }
export function View_ReferralListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-referral-list", [], null, null, null, View_ReferralListComponent_0, RenderType_ReferralListComponent)), i1.ɵdid(1, 114688, null, 0, i2.ReferralListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReferralListComponentNgFactory = i1.ɵccf("app-referral-list", i2.ReferralListComponent, View_ReferralListComponent_Host_0, {}, {}, []);
export { ReferralListComponentNgFactory as ReferralListComponentNgFactory };
