<div class="full-width modalClass">
  <button class="btn-close btn-clear mat-button pull-right" (click)="close()">
    <mat-icon>clear</mat-icon>
  </button>

  <ipv-note-input
    [hideExpandButton]="true"
    [size]="'large'"
    [practice]="practice"
    [dentist]="dentist"
    [patient]="patient"
    [text]="noteText"
    (textUpdated)="setNoteValue($event)"
  >
  </ipv-note-input>

  <div class="drop-button text-center white-background">
    <hr/>

    <button class="pull-left action-button btn-large mr" (click)="close()" mat-raised-button color="primary">
      Close
    </button>

    <button class="pull-right action-button btn-large" (click)="done()" mat-raised-button color="accent">
      <span>Save</span>
    </button>
  </div>
</div>
