import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PatientActionLogComponent } from '../patient-action-log/patient-action-log.component';

import { PatientContactsComponent } from '../patient-contacts/patient-contacts.component';
import { PatientMessageListComponent } from '../patient-message-list/patient-message-list.component';
import { PatientOverviewComponent } from '../patient-overview/patient-overview.component';

import { ExisitingPersonViewPictureComponent } from '../../existing-patient/existing-patient-view-picture/existing-patient-view-picture.component';
import { InformedConsentComponent } from '../informed-consent/informed-consent.component';
import { InvitationCardComponent } from '../invitation-card/invitation-card.component';
import { MainCardComponent } from '../main-card/main-card.component';
import { PatientCardChangesModalComponent } from '../patient-card-changes-modal/patient-card-changes-modal.component';
import { PatientCardDeferModalComponent } from '../patient-card-defer-modal/patient-card-defer-modal.component';
import { DiscountInfoModalComponent } from '../patient-card-discount-info-modal/patient-card-discount-info-modal.component';
import { PatientCardStopModalComponent } from '../patient-card-stop-modal/patient-card-stop-modal.component';
import { PatientCardComponent } from '../patient-card/patient-card.component';
import { PatientContactDetailsCreateComponent } from '../patient-contact-details-create/patient-contact-details-create.component';
import { PatientContactDetailsViewComponent } from '../patient-contact-details-view/patient-contact-details-view.component';
import { PatientCreatePersonalComponent } from '../patient-create-personal/patient-create-personal.component';
import { PatientIdentificationCreateComponent } from '../patient-identification-create/patient-identification-create.component';
import { PatientIdentificationViewComponent } from '../patient-identification-view/patient-identification-view.component';
import { PatientIntroModalComponent } from '../patient-intro-modal/patient-intro-modal.component';
import { PatientPersonalDetailsCreateComponent } from '../patient-personal-details-create/patient-personal-details-create.component';
import { PatientPersonalDetailsViewComponent } from '../patient-personal-details-view/patient-personal-details-view.component';
import { PatientProfileMerchantComponent } from '../patient-profile-merchant/patient-profile-merchant.component';
import { PatientRecordDetailsComponent } from '../patient-record-details/patient-record-details.component';
import { PatientTreatmentViewComponent } from '../patient-treatment-view/patient-treatment-view.component';
import { PatientUpdateDetailsComponent } from '../patient-update-details/patient-update-details.component';

const routes: Routes = [
  {
    path: 'informed-consent',
    component: InformedConsentComponent,
  },

  {
    path: 'invitation-card',
    component: InvitationCardComponent,
  },

  {
    path: 'invitation-card/:consumerID',
    component: MainCardComponent,
  },

  {
    path: 'patient-card/:invitationID',
    component: PatientCardComponent,
  },

  {
    path: 'PatientTreatmentViewComponent',
    component: PatientTreatmentViewComponent,
  },
  {
    path: 'ExisitingPersonViewPictureComponent',
    component: ExisitingPersonViewPictureComponent,
  },
  {
    path: 'PatientCreatePersonalComponent',
    component: PatientCreatePersonalComponent,
  },

  {
    path: 'patient-action',
    component: PatientActionLogComponent,
  },

  {
    path: 'patient-contacts',
    component: PatientContactsComponent,
  },
  {
    path: 'patient-update',
    component: PatientUpdateDetailsComponent,
  },

  {
    path: 'patient-message-list',
    component: PatientMessageListComponent,
  },
  {
    path: 'patient-overview',
    component: PatientOverviewComponent,
  },
  {
    path: 'Patient-profile-merchant',
    component: PatientProfileMerchantComponent,
  },

  {
    path: 'patient-card-changes-modal',
    component: PatientCardChangesModalComponent,
  },
  {
    path: 'patient-card-defer-modal',
    component: PatientCardDeferModalComponent,
  },
  {
    path: 'patient-card-stop-modal',
    component: PatientCardStopModalComponent,
  },

  {
    path: 'patient-personal-details-create',
    component: PatientPersonalDetailsCreateComponent,
  },
  {
    path: 'patient-personal-details-view',
    component: PatientPersonalDetailsViewComponent,
  },
  {
    path: 'patient-contact-details-create',
    component: PatientContactDetailsCreateComponent,
  },
  {
    path: 'patient-contact-details-view',
    component: PatientContactDetailsViewComponent,
  },
  {
    path: 'patient-identification-create',
    component: PatientIdentificationCreateComponent,
  },
  {
    path: 'patient-identification-view',
    component: PatientIdentificationViewComponent,
  },
  {
    path: 'patient-record-details',
    component: PatientRecordDetailsComponent,
  },
  {
    path: 'patient-intro-modal',
    component: PatientIntroModalComponent,
  },
  {
    path: 'discount-info-modal',
    component: DiscountInfoModalComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PatientRoutingModule {}
