import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-default-sms-settings',
  templateUrl: './default-sms-settings.component.html',
  styleUrls: ['./default-sms-settings.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.tab-content-inner', style({ opacity: 0, height: 0 }), { optional: true }),
        query(
          '.tab-content-inner',
          stagger('250ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 0, height: 0, offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, height: '*', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.tab-content-inner', style({ opacity: 1, height: '*' }), { optional: true }),
        query(
          '.tab-content-inner',
          stagger('250ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 1, height: '*', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, height: 0, offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class DefaultSmsSettingsComponent implements OnInit {
  @Input()
  merchantID;

  @Input()
  hideFilter = false;

  isModuleNoteFeaturesActive = Settings.global['isModuleNoteFeaturesActive'];

  isAdminOrPromoter = false;

  purposeCodes = 'MerInv,MerInv_Mem,MerInv_Chk,MarCust,MarMer,MarMerPros,Contacts,MerSMS,MerPUInv';

  msgList = [];

  settings = 'templates';

  constructor(
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private utilService: UtilsService
  ) {}

  ngOnInit() {
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleNoteFeaturesActive = access['isModuleNoteFeaturesActive'];
      }
    });

    this.activeRoute.params.subscribe((params) => {
      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;
      });
    });
  }
}
