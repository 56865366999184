import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { AuthenticationService } from '../shared/authentication.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  password;
  verifiedPassword;

  hide = true;
  reHide = true;

  operatorID;

  token;
  isError = false;

  errorTitle = 'The page you are looking for can not be found';
  errorContent = 'The reset password link either has Expired or invalid';

  passwordChanged;

  constructor(
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['operatorID']) {
        this.operatorID = params['operatorID'];
      }

      if (params['token']) {
        this.token = params['token'];
      }
    });

    const payload = {
      operatorID: this.operatorID,
      token: this.token,
    };

    this.authenticationService.checkToken(payload).subscribe((res) => {
      if (res != true) {
        this.router.navigate(['/404/' + this.errorTitle + '/' + this.errorContent]);
      }
    });
  }

  resetPassword() {
    const payload = {
      operatorID: this.operatorID,
      token: this.token,
      password: this.password,
      verifiedPassword: this.verifiedPassword,
    };
    this.authenticationService.resetPassword(payload).subscribe((res) => {
      if (res == true) {
        NotifyAppComponent.displayToast('success', 'Reset Password', 'You have successfully reset your password!');
        this.router.navigate(['/login']);
      }
    });
  }
}
