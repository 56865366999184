import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { SharedModule } from '../../shared/shared.module';

import { MerchantHealthFundListComponent } from './merchant-health-fund-list/merchant-health-fund-list.component';
import { MerchantHealthFundsOverviewComponent } from './merchant-health-funds-overview/merchant-health-funds-overview.component';
import { MerchantHealthFundsRoutingModule } from './shared/merchant-health-funds-routing.module';

@NgModule({
  imports: [CommonModule, MerchantHealthFundsRoutingModule, CdkTableModule, SharedModule, MatSortModule],
  declarations: [MerchantHealthFundListComponent, MerchantHealthFundsOverviewComponent],
  exports: [MerchantHealthFundListComponent, MerchantHealthFundsOverviewComponent],
  providers: [],
})
export class MerchantHealthFundsModule {}
