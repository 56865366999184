import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Treatment } from '../../treatment/shared/treatment.type';

@Component({
  selector: 'app-treatment-plan-create',
  templateUrl: './treatment-plan-create.component.html',
  styleUrls: ['./treatment-plan-create.component.css'],
})
export class TreatmentPlanCreateComponent implements OnInit {
  @Input() invitationID;

  @Input() patientID;
  @Input() selectedTab = 0;

  @Input()
  refreshTreatment = new EventEmitter();

  @Output()
  templateSelectAll = new EventEmitter();

  @Input()
  disableTreatmentValue = false;

  @Input()
  merchantID;

  @Input()
  invitationTemplateID;

  @Input()
  invitationTemplateLabel;

  @Output() validate = new EventEmitter();
  @Output()
  refreshTotalCost = new EventEmitter();
  @Output()
  ForceRefreshTotalCost = new EventEmitter();
  treatments: Treatment[] = [];
  simpleTreatment;
  canEditTotalValue = true;
  validateSimpleTreatment = false;
  validateAdvancedTreatment = false;

  @Output()
  clearTemplate = new EventEmitter();

  @Input()
  treatmentDocumentLabel;

  @Input()
  treatmentDocumentDescription;

  @Input() totalTreatmentValue = 0;

  @Output() informedConsentUpdated = new EventEmitter();
  @Output() simpleTreatmentDocumentsUpdated = new EventEmitter();
  @Output() EPTreatmentDocumentsUpdated = new EventEmitter();
  @Output() stockArtDocumentsUpdated = new EventEmitter();
  @Output() EPstockArtDocumentsUpdated = new EventEmitter();

  @Input() stockArtDocuments = [];
  @Input() EPstockArtDocuments = [];
  @Input() simpleTreatmentDocuments = [];
  @Input() EPTreatmentDocuments = [];

  simpleTreatmentDocumentsDetails: any = [];
  stockArtDocumentsDetails: any = [];

  @Input() informedConsent = [
    {
      categoryID: null,
      itemCode: null,
      typeID: null,
      brandID: null,
      productID: null,
      showStockArtDocumentsModal: true,
      preDocuments: [],
      stockArtDocuments: [],
      postDocuments: [],
      marketingDocuments: [],
    },
  ];

  constructor() {}

  ngOnInit() {}

  createTreatment(data) {
    this.treatments = data;
    this.validateTreatList();
  }

  onValidateList(res) {
    this.validateAdvancedTreatment = res;
    this.canEditTotalValue = !this.validateAdvancedTreatment;
    this.ForceRefreshTotalCost.emit(true);
  }

  onValidate(res) {
    this.validateSimpleTreatment = res;
    this.canEditTotalValue = !this.validateAdvancedTreatment;
    this.ForceRefreshTotalCost.emit(true);

    this.validate.emit(this.validateSimpleTreatment || this.validateAdvancedTreatment);
  }

  refreshTotalCostEvent(data) {
    this.refreshTotalCost.emit(data);
  }

  setSimpleTreatmentDocuments(documents) {
    this.simpleTreatmentDocuments = documents;
    this.simpleTreatmentDocumentsUpdated.emit(documents);
  }

  setEpTreatmentDocuments(epDocuments) {
    this.EPTreatmentDocuments = epDocuments;
    this.EPTreatmentDocumentsUpdated.emit(epDocuments);
  }

  setStockArtDocuments(stockArts) {
    this.stockArtDocuments = stockArts;
    this.stockArtDocumentsUpdated.emit(stockArts);
  }

  setEPStockartDocuments(EPStockArts) {
    this.EPstockArtDocuments = EPStockArts;
    this.EPstockArtDocumentsUpdated.emit(EPStockArts);
  }

  validateTreatList() {
    this.validateAdvancedTreatment = false;
    if (this.treatments.length !== 0) {
      this.treatments.forEach((item) => {
        if (Object.keys(item.document).length !== 0) {
          this.validateAdvancedTreatment = true;
          return;
        } else {
          this.validateAdvancedTreatment = false;
        }
      });
    } else {
      this.validateAdvancedTreatment = false;
    }

    this.canEditTotalValue = !this.validateAdvancedTreatment;
    this.ForceRefreshTotalCost.emit(true);

    this.validate.emit(this.validateSimpleTreatment || this.validateAdvancedTreatment);
  }

  clearTemplateEvent(e) {
    this.clearTemplate.emit(e);
  }

  templateSelectAllEvent(e) {
    this.templateSelectAll.emit(e);
  }

  setInformedConsent(informedConsent) {
    this.informedConsentUpdated.emit(informedConsent);
    this.informedConsent = informedConsent;
  }
}
