import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MarketingFilterCreateComponent } from '../marketing-filter-create/marketing-filter-create.component';
import { MarketingFilterCreateSectionComponent } from '../marketing-filter-create-section/marketing-filter-create-section.component';
import { MarketingFilterCreatePropertyComponent } from '../marketing-filter-create-property/marketing-filter-create-property.component';
import { MarketingFilterViewComponent } from '../marketing-filter-view/marketing-filter-view.component';


const routes: Routes = [
  {
    path: 'marketing-filter-create',
    component: MarketingFilterCreateComponent,
  },
  {
    path: 'marketing-filter-create-section',
    component: MarketingFilterCreateSectionComponent,
  },
  {
    path: 'marketing-filter-create-property',
    component: MarketingFilterCreatePropertyComponent,
  },
  {
    path: 'marketing-filter-view',
    component: MarketingFilterViewComponent,
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MarketingFilterRoutingModule { }
