<mat-card class="rel full-width clearfix">
  <div class="colour-stripe"></div>
  <div class="full-width clearfix">
    <div class="full-width clearfix mt">
      <div class="full-width clearfix">
        <div class="toggle-card row full-width clearfix">
          <mat-button-toggle-group class="btn-group row clearfix flex full-width" [(ngModel)]="status">
            <mat-button-toggle class="text-center full-width clearfix rel" [value]="'Archived'">
              Archived
            </mat-button-toggle>
            <mat-button-toggle class="text-center full-width clearfix rel" [value]="'Cancelled'">
              Cancelled
            </mat-button-toggle>

            <mat-button-toggle class="text-center full-width clearfix rel" [value]="'Suspended'">
              Suspended
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <div class="full-width clearfix" *ngIf="status == 'Archived'">
        <app-merchant-list class="full-width clearfix mt mb" [status]="'Archived'"></app-merchant-list>
      </div>

      <div class="full-width clearfix" class="full-width clearfix" *ngIf="status == 'Cancelled'">
        <app-merchant-list class="full-width clearfix mt mb" [status]="'Cancelled'"></app-merchant-list>
      </div>

      <div class="full-width clearfix" *ngIf="status == 'Suspended'">
        <app-merchant-list class="full-width clearfix mt mb" [status]="'Suspended'"></app-merchant-list>
      </div>
    </div>
  </div>
</mat-card>
