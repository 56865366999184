<div class="row clearfix full-width tabArea" *ngIf="currentUser && currentUser.ID">
  <div class="qtr-width">
    <div class="col-left">
      <div class="pictureBlock mat-card">
        <div class="row clearfix image">
          <app-ep-document-view-image
            class="clearfix full-width display-image profile"
            [defaultPicture]="
              'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
            "
            [link]="profileLink"
          >
          </app-ep-document-view-image>
        </div>
        <div class="row clearfix">
          <h4 class="sr-title sm-h4">My Profile</h4>
          <p class="larger rm-mb">{{ currentUser.CalculatedName }}</p>
          <p class="rm-mt role">
            <em>{{ currentUser.Role }}</em>
          </p>
        </div>

        <div class="row clearfix">
          <h4 class="sr-title sm-h4 rm-mb">Upload Avatar</h4>
          <p class="small rm-m">To upload a new avatar image, please drag your file into the grey area below.</p>
          <app-file-uploader
            class="text-center clearfix full-width"
            [allowedExtensionTypes]="['image']"
            [isFixedAspectRatio]="true"
            [isImageCropping]="true"
            [canSkip]="false"
            [limit]="1"
            [uploadDirectly]="true"
            [isDragable]="true"
            (onCompleteAll)="onCompleteAll($event)"
          >
          </app-file-uploader>
        </div>
      </div>
    </div>
  </div>

  <div class="thr-qtr-width">
    <app-customer-prospect-view class="mt clearfix" [patientID]="currentUser.ID"></app-customer-prospect-view>
  </div>
</div>
