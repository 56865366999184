import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MovingDirection, WizardComponent } from 'angular-archwizard';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { HeaderService } from '../../../shared/services/header.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { ContactService } from '../../contact/shared/contact.service';
import { CustomerProspectService } from '../../customer-prospect/shared/customerProspect.service';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { IllnessCreateEditComponent } from '../illness-create-edit/illness-create-edit.component';
import { MedicalHistoryAcknowledgementComponent } from '../medical-history-acknowledgement/medical-history-acknowledgement.component';
import { MedicalHistoryLanguageModalComponent } from '../medical-history-language-modal/medical-history-language-modal.component';
import { MedicalHistoryPrivacyModalComponent } from '../medical-history-privacy-modal/medical-history-privacy-modal.component';
import { MedicalHistoryProgressModalComponent } from '../medical-history-progress-modal/medical-history-progress-modal.component';
import { MedicalHistoryService } from '../shared/medical-history.service';

@Component({
  selector: 'app-medical-history-create-edit',
  templateUrl: './medical-history-create-edit.component.html',
  styleUrls: ['./medical-history-create-edit.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('200ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('200ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
    trigger('ngIfAnimationFast', [
      transition('void => *', [
        query('.anime', style({ opacity: 0 }), { optional: true }),
        query(
          '.anime',
          stagger('50ms', [
            animate(
              '0.2s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.anime', style({ opacity: 1 }), { optional: true }),
        query(
          '.anime',
          stagger('50ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MedicalHistoryCreateEditComponent implements OnInit {
  @Input()
  merchantID;

  @Input()
  step = null;
  wakandaID;

  @Input()
  invitationID;

  @Input()
  medicalInvitationID;

  @Input()
  patientID;

  invitation;
  createdObject = {
    ID: '262DC7B73DEEFD45A7A03CE37DF2D9D1',
    Card_key: '405315A7FD5AA942AE9109B6DA903FDF',
  };
  genders = [];
  titles = [];
  illnessList = [];
  myIllnessCount = 0;
  medicalInvitation;

  merchant: any = {};
  dentist: any = {};

  merchantClass = '';
  merchantClass2 = '';
  firstLoad = false;
  patient = {
    ID: null,
    firstname: null,
    title: null,
    lastname: null,
    middlename: null,
    gender: null,
    mobile: null,
    email: null,
    unitNumber: null,
    streetNumber: null,
    streetName: null,
    streetType: null,
    suburb: null,
    state: null,
    postCode: null,
    latitude: null,
    longitude: null,
    address: null,
    phone: null,
    dateOfBirth: null,
  };

  isPatientEmailValid = false;

  isNameCorrect = null;

  _readsWritesEnglish = null;

  sensitiveArray = {
    hot: null,
    cold: null,
    chew: null,
    sweet: null,
  };
  lastCheckUpDate = null; // new Date();
  medicalHistory = {
    step: null,
    readsWritesEnglish: null,
    needsHelpWithForm: false,
    speakInPrivateToStaff: false,
    hasDentalProblem: null,
    dentalProblemDescription: null,
    sensitiveTeethWith: null,
    foodCaughtInTeeth: null,
    gumsBleed: null,
    hasBadBreath: null,
    cleansHowOften: null,
    doesClenchOrGrind: null,
    hasClickingLockingJaw: null,
    hasSoreMuscles: null,
    wearsNightPlate: null,
    consciousOfColourAppearance: null,
    needToImprove: null,
    improveWhat: null,
    terrifiedLevel: null,
    hasHeadacheOrJawOrFacialPain: null,
    isSmoking: null,
    smokingTimes: null,
    drinkWine: null,
    drinkingTimes: null,
    isPregnant: null,
    pregnancyMonths: null,
    isBreastFeeding: null,
    formFilledOnBehalf: null,
    doesBiteCheeks: null,
    hurtsWhenBiteHard: null,
    hasPeridontalTreatment: null,
    hasBiteAdjusted: null,
    familyStressDescription: null,
    hasFamilyStress: null,
    sensitiveTeethWithSweet: null,
    sensitiveTeethWithChew: null,
    sensitiveTeethWithHot: null,
    sensitiveTeethWithCold: null,
    lastCheckUpDate: null, // new Date(),
    complete: false,
  };

  responseForSettlement = true;

  isLivedWithYou = false;
  changeDateCheckup = false;
  sensitiveOptions = [
    {
      label: 'Hot',
      icon: 'whatshot',
      value: null,
    },
    {
      label: 'Cold',
      icon: 'ac_unit',
      value: null,
    },
    {
      label: 'Chewing',
      icon: 'fastfood',
      value: null,
    },
    {
      label: 'Sweet',
      icon: 'cake',
      value: null,
    },
  ];

  subjectRequestInvitation = 'Request Health History Invitation';

  showIllnessDescription = false;

  possibleContactTypes = [];
  patientName;
  _sensitiveTeethWith = [];

  guardianObject = {
    gender: null,
    title: null,
    firstname: null,
    middlename: null,
    lastname: null,
    email: null,
    mobile: null,
    relativeTypeCode: null,
  };

  isGuardianEmailValid = false;
  isGuardianMobileValid = false;

  isEdit = false;

  medicalHistoryID;
  selectedillnessObjectList = [];
  isSelectedIlless = false;
  _selectedillnessLabelList = [];
  hasOtherIllness = false;
  isEmptyIllnessList = false;
  createdMedicalHistoryID;
  isAddIllness = false;
  isReviewIllness = false;

  relativeTypes = [];
  isModifyGuardian = false;
  guardianID;

  stepCounter = 0;
  isConsciousOfColourAppearance = null;
  isHasBadBreath = null;

  hasGuardian = false;
  hasMedicare = null;
  existingMedicare = false;
  medicareID;
  medicareExpiry;
  medicareExpiryDefaultDate;
  medicareNumber;
  medicareReferenceNumber;

  hasHealthInsurance = null;
  existingHealthInsurance = false;
  healthFundName;
  healthFundNumber;
  healthFundNameOther;
  healthFundID;
  healthFundReferenceNumber;

  hasGpDoctor = true;
  existingGpDoctor = false;
  gpDoctor = {
    practiceName: null,
    firstname: null,
    middlename: null,
    lastname: null,
    mobile: null,
    email: null,
    ID: null,
  };

  isGpDoctorEmailValid = false;
  isGpDoctorMobileValid = false;
  dentistID;
  util = new UtilsClass();

  healthfunds = [];
  sessionType = 'merchant';

  isPromoterOrAdmin = false;

  medicalHistoryFull;

  @ViewChild('wizard', { static: true }) wizard: WizardComponent;

  currentUser;

  currentIllnesses = [];

  isEmailValid = true;
  isMobileValid = true;
  isPhoneValid = true;
  isAddressValid = true;
  dateNow = new Date();

  enterslide = '';

  anim = false;

  constructor(
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private lookupService: LookupService,
    private location: Location,
    private customerProspectService: CustomerProspectService,
    private medicalHistoryService: MedicalHistoryService,
    private _changeDetectionRef: ChangeDetectorRef,
    private merchantService: MerchantService,
    private contactService: ContactService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe((user) => {
      if (user) {
        this.currentUser = user;
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        this.authenticationService.getSessionType().subscribe((sessionType) => {
          this.sessionType = sessionType;

          this.activeRoute.params.subscribe((params) => {
            if (params['step']) {
              this.step = params['step'];
            }

            if (params['customerID']) {
              this.patientID = params['customerID'];
            }

            if (params['patientID']) {
              this.patientID = params['patientID'];
            }

            this.addMerchantClass();

            if (this.sessionType === 'customer' || this.sessionType === 'consumer') {
              if (this.currentUser && this.currentUser.data && this.currentUser.data.customerID) {
                this.patientID = this.currentUser.data.customerID;

                if (this.step == null) {
                  window.history.replaceState({}, '', `/consumer-app/(page:medical)`);
                } else {
                  window.history.replaceState({}, '', `/consumer-app/(page:medical/step/${this.step})`);
                }
              }
            }
            if (this.patientID) {
              this.medicalHistoryService
                .getMedicalHistoryPatientID(this.patientID, { selectInvitation: true }, this.sessionType)
                .subscribe((res) => {
                  if (res && res.medicalHistory) {
                    this.medicalHistoryFull = res;

                    this.setup();
                  } else {
                    this.medicalInvitation = {
                      status: 'notExist',
                      isValid: false,
                      canProceed: false,
                    };
                  }

                  this.firstLoad = true;
                });
            } else {
              this.medicalInvitation = {
                status: 'notExist',
                isValid: false,
                canProceed: false,
              };

              this.firstLoad = true;
            }
          });
        });
      });
    });
  }

  ngAfterViewInit(): void {
    // Force another change detection in order to fix an occuring ExpressionChangedAfterItHasBeenCheckedError
    this._changeDetectionRef.detectChanges();
    // console.log(this.stepCounter);
  }

  setStepCounter(e) {
    this.stepCounter = e;
  }

  /**
   *  Pre-populated the patient details
   */

  setup() {
    if (this.medicalHistoryFull) {
      if (this.medicalHistoryFull.invitation && this.medicalHistoryFull.invitation.ID) {
        this.invitationID = this.medicalHistoryFull.invitation.ID;
        if (this.medicalHistoryFull.invitation.WakandaID) {
          this.wakandaID = this.medicalHistoryFull.invitation.WakandaID;
        }
        this.invitation = this.medicalHistoryFull.invitation;

        if (this.invitationID && this.invitation['Date.Viewed'] == '0000-00-00') {
          this.medicalHistoryService.viewMedicalInvitation(this.invitationID, this.sessionType).subscribe((res) => {});
        }
      }
      if (this.medicalHistoryFull.patient && this.medicalHistoryFull.patient.ID) {
        this.patientID = this.medicalHistoryFull.patient.ID;

        this.buildPatient(this.medicalHistoryFull.patient);
      }

      if (this.medicalHistoryFull.illness && this.medicalHistoryFull.illness.length > 0) {
        this.buildCurrentIllnessCode(this.medicalHistoryFull.illness);
      }

      if (this.medicalHistoryFull.merchant && this.medicalHistoryFull.merchant.ID) {
        this.merchantID = this.medicalHistoryFull.merchant.ID;
        this.merchant = this.medicalHistoryFull.merchant;
        HeaderService.MerchantName.emit(this.merchant['TradingAs']);
      }

      if (this.medicalHistoryFull.dentist && this.medicalHistoryFull.dentist.ID) {
        this.dentistID = this.medicalHistoryFull.dentist.ID;
        this.dentist = this.medicalHistoryFull.dentist;
      }

      this.buildGuardian(this.medicalHistoryFull.guardian);
      this.buildDoctor(this.medicalHistoryFull.doctor);
      this.buildHealthFund(this.medicalHistoryFull.healthFund);
      this.buildMediCare(this.medicalHistoryFull.mediCare);

      this.lookupService.getLookup('CodeLookup', 'RelativeType').subscribe((res) => {
        this.possibleContactTypes = res;
      });

      this.lookupService.getLookup('CodeLookup', 'Gender').subscribe((res) => {
        this.genders = res;
      });
      this.lookupService.getLookup('SimpleLookup', 'Salutation').subscribe((res) => {
        this.titles = res;
      });

      this.lookupService.getLookup('CodeLookup', 'Illness').subscribe((res) => {
        this.illnessList = res;
        this.illnessList.map((x) => (x['showIllnessDescription'] = false));
      });

      this.lookupService.getLookup('CodeLookup', 'RelativeType').subscribe((res) => {
        this.relativeTypes = res;
      });

      this.lookupService.getLookup('CodeLookup', 'PrivateInsurance').subscribe((res) => {
        this.healthfunds = res;
      });
      if (this.medicalHistoryFull.medicalHistory && this.medicalHistoryFull.medicalHistory.ID) {
        this.medicalHistoryID = this.medicalHistoryFull.medicalHistory.ID;

        this.buildMedicalHistory(this.medicalHistoryFull.medicalHistory);
        this.isEdit = true;
      }
      this.updateStep();
    }
  }

  buildPatient(res) {
    if (res) {
      if (res['ID']) {
        this.patient.ID = res['ID'];
      } else {
        this.patient.ID = null;
      }

      if (res['FirstName']) {
        this.patient.firstname = res['FirstName'];
      } else {
        this.patient.firstname = null;
      }

      if (res['Salutation']) {
        this.patient.title = res['Salutation'];
      } else {
        this.patient.title = null;
      }

      if (res['Name']) {
        this.patient.lastname = res['Name'];
      } else {
        this.patient.lastname = null;
      }

      if (res['Gender.Code']) {
        this.patient.gender = res['Gender.Code'];
      } else {
        this.patient.gender = null;
      }

      if (res['mobiles.Number']) {
        this.patient.mobile = res['mobiles.Number'];
      } else {
        this.patient.mobile = null;
      }

      if (res['emails.Email']) {
        this.patient.email = res['emails.Email'];
      } else {
        this.patient.email = null;
      }

      if (res['addresses.Unit']) {
        this.patient.unitNumber = res['addresses.Unit'];
      } else {
        this.patient.unitNumber = null;
      }

      if (res['addresses.Street Nr']) {
        this.patient.streetNumber = res['addresses.Street Nr'];
      } else {
        this.patient.streetNumber = null;
      }

      if (res['addresses.Street Name']) {
        this.patient.streetName = res['addresses.Street Name'];
      } else {
        this.patient.streetName = null;
      }

      if (res['addresses.Street Type']) {
        this.patient.streetType = res['addresses.Street Type'];
      } else {
        this.patient.streetType = null;
      }

      if (res['addresses.Suburb']) {
        this.patient.suburb = res['addresses.Suburb'];
      } else {
        this.patient.suburb = null;
      }

      if (res['addresses.State']) {
        this.patient.state = res['addresses.State'];
      } else {
        this.patient.state = null;
      }

      if (res['addresses.Postcode']) {
        this.patient.postCode = res['addresses.PostCode'];
      } else {
        this.patient.postCode = null;
      }

      if (res['addresses.Calculated']) {
        this.patient.address = res['addresses.Calculated'];
      } else {
        this.patient.address = null;
      }

      if (res['phones.Number']) {
        this.patient.phone = res['phones.Number'];
      } else {
        this.patient.phone = null;
      }

      if (res['DateOfBirth'] && res['DateOfBirth'] != '000-00-00') {
        this.patient.dateOfBirth = res['DateOfBirth'];
      } else {
        this.patient.dateOfBirth = null;
      }

      if (res['CalculatedName']) {
        this.patientName = res['CalculatedName'];
      } else {
        this.patientName = null;
      }
    }
  }

  validateMobile(e) {
    this.isMobileValid = e;
  }

  validateHomePhone(e) {
    this.isPhoneValid = e;
  }

  validatePatientEmailEvent(e) {
    this.isPatientEmailValid = e;
  }

  isAdressProfileValidEvent(e) {
    this.isAddressValid = e;
  }

  buildGuardian(res) {
    if (res && res.ID) {
      this.hasGuardian = true;
      this.guardianID = res['ID'];
      this.guardianObject = {
        gender: res['Gender.Code'],
        title: res['Salutation'],
        firstname: res['FirstName'],
        middlename: res['MiddleName'],
        lastname: res['Name'],
        email: res['emails.Email'],
        mobile: res['mobiles.Number'],
        relativeTypeCode: res['RelativeType.Code'],
      };
    } else {
      this.hasGuardian = false;
    }
  }

  buildDoctor(res) {
    if (res && res.ID) {
      this.hasGpDoctor = true;
      this.gpDoctor = {
        practiceName: res['TradingAs'],
        firstname: res['FirstName'],
        middlename: res['MiddleName'],
        lastname: res['Name'],
        mobile: res['mobiles.Number'],
        email: res['emails.Email'],
        ID: res['ID'],
      };
      this.existingGpDoctor = true;
    } else {
      this.hasGpDoctor = false;
      this.existingGpDoctor = false;
    }
  }

  buildHealthFund(res) {
    if (res && res.ID) {
      this.hasHealthInsurance = true;
      this.healthFundName = res['Issuer.Code'];
      this.healthFundNameOther = res['issuer.LabelOther'];
      this.healthFundNumber = res['Number'];
      this.healthFundID = res['ID'];
      this.existingHealthInsurance = true;
    } else {
      this.hasHealthInsurance = false;
    }
  }

  buildMediCare(res) {
    if (res) {
      this.hasMedicare = true;
      if (res && res.ID) {
        this.medicareID = res.ID;
      }
      this.medicareNumber = res['Number'];
      this.medicareReferenceNumber = res['Text1'];
      if (res && res['Date.Expiry'] != '0000-00-00') {
        this.medicareExpiry = res['Date.Expiry'];
        this.medicareExpiryDefaultDate = res['Date.Expiry'];
      } else {
        this.medicareExpiry = null;
        this.medicareExpiryDefaultDate = null;
      }

      this.existingMedicare = true;
    } else {
      this.hasMedicare = false;
    }
  }

  buildMedicalHistory(res) {
    if (res) {
      this.medicalHistory.doesBiteCheeks = res['DoesBiteCheeks'] == '1' ? true : false;
      this.medicalHistory.doesClenchOrGrind = res['DoesClenchOrGrind'] == '1' ? true : false;
      this.medicalHistory.drinkWine = res['DrinkWine'] == '1' ? true : false;
      this.medicalHistory.foodCaughtInTeeth = res['FoodCaughtInTeeth'] == '1' ? true : false;
      this.medicalHistory.formFilledOnBehalf = res['FormFilledOnBehalf'] == '1' ? true : false;
      this.medicalHistory.gumsBleed = res['GumsBleed'] == '1' ? true : false;
      this.medicalHistory.hasBiteAdjusted = res['HasBiteAdjusted'] == '1' ? true : false;
      this.medicalHistory.hasClickingLockingJaw = res['HasClickingLockingJaw'] == '1' ? true : false;
      this.medicalHistory.hasDentalProblem = res['HasDentalProblem'] == '1' ? true : false;
      this.medicalHistory.hasPeridontalTreatment = res['HasPeridontalTreatment'] == '1' ? true : false;
      this.medicalHistory.hasSoreMuscles = res['HasSoreMuscles'] == '1' ? true : false;
      this.medicalHistory.hurtsWhenBiteHard = res['HurtsWhenBiteHard'] == '1' ? true : false;
      this.medicalHistory.isBreastFeeding = res['IsBreastFeeding'] == '1' ? true : false;
      this.medicalHistory.isPregnant = res['IsPregnant'] == '1' ? true : false;
      this.medicalHistory.isSmoking = res['IsSmoking'] == '1' ? true : false;
      this.medicalHistory.needToImprove = res['NeedToImprove'] == '1' ? true : false;
      this.medicalHistory.needsHelpWithForm = res['NeedsHelpWithForm'] == '1' ? true : false;
      this.medicalHistory.readsWritesEnglish = res['ReadsWritesEnglish'] == '1' ? true : false;
      this.medicalHistory.speakInPrivateToStaff = res['SpeakInPrivateToStaff'] == '1' ? true : false;
      this.medicalHistory.wearsNightPlate = res['WearsNightPlate'] == '1' ? true : false;
      this.medicalHistory.hasFamilyStress = res['HasFamilyStress'] == '1' ? true : false;
      this.medicalHistory.hasHeadacheOrJawOrFacialPain = res['HasHeadacheOrJawOrFacialPain'] == '1' ? true : false;

      if (res['ConsciousOfColourAppearance']) {
        this.medicalHistory.consciousOfColourAppearance = res['ConsciousOfColourAppearance'];

        if (res['ConsciousOfColourAppearance'] != '0') {
          this.isConsciousOfColourAppearance = true;
        } else {
          this.isConsciousOfColourAppearance = false;
        }
      }

      if (res['HasBadBreath']) {
        this.medicalHistory.hasBadBreath = res['HasBadBreath'];

        if (res['HasBadBreath'] != '0') {
          this.isHasBadBreath = true;
        } else {
          this.isHasBadBreath = false;
        }
      }

      if (res['FamilyStressDescription']) {
        this.medicalHistory.familyStressDescription = res['FamilyStressDescription'];
      }

      if (res['CleansHowOften']) {
        this.medicalHistory.cleansHowOften = Number(res['CleansHowOften']);
      }

      if (res['DentalProblemDescription']) {
        this.medicalHistory.dentalProblemDescription = res['DentalProblemDescription'];
      }

      if (res['ImproveWhat']) {
        this.medicalHistory.improveWhat = res['ImproveWhat'];
      }

      if (res['PregnancyMonths']) {
        this.medicalHistory.pregnancyMonths = res['PregnancyMonths'];
      }

      if (res['SensitiveTeethWithCold']) {
        this.medicalHistory.sensitiveTeethWithCold = res['SensitiveTeethWithCold'];
        if (res['SensitiveTeethWithCold'] != '0') {
          this.sensitiveArray.cold = true;
        } else {
          this.sensitiveArray.cold = false;
        }
      }
      if (res['SensitiveTeethWithChew']) {
        this.medicalHistory.sensitiveTeethWithChew = res['SensitiveTeethWithChew'];
        if (res['SensitiveTeethWithChew'] != '0') {
          this.sensitiveArray.chew = true;
        } else {
          this.sensitiveArray.chew = false;
        }
      }
      if (res['SensitiveTeethWithHot']) {
        this.medicalHistory.sensitiveTeethWithHot = res['SensitiveTeethWithHot'];
        if (res['SensitiveTeethWithHot'] != '0') {
          this.sensitiveArray.hot = true;
        } else {
          this.sensitiveArray.hot = false;
        }
      }
      if (res['SensitiveTeethWithSweet']) {
        this.medicalHistory.sensitiveTeethWithSweet = res['SensitiveTeethWithSweet'];
        if (res['SensitiveTeethWithSweet'] != '0') {
          this.sensitiveArray.sweet = true;
        } else {
          this.sensitiveArray.sweet = false;
        }
      }

      if (res['SmokingTimes']) {
        this.medicalHistory.smokingTimes = Number(res['SmokingTimes']);
      }

      if (res['TerrifiedLevel']) {
        this.medicalHistory.terrifiedLevel = Number(res['TerrifiedLevel']);
      }

      if (res['DrinkingTimes']) {
        this.medicalHistory.drinkingTimes = res['DrinkingTimes'];
      }

      if (res['Date.LastCheckup'] && res['Date.LastCheckup'] != '0000-00-00') {
        this.lastCheckUpDate = moment(res['Date.LastCheckup'], 'YYYY-MM-DD').toDate();
      }

      if (res['Step']) {
        this.step = res['Step'];
      }
    }
  }

  updateStep() {
    if (this.step) {
      this.rewriteStep(this.step);

      this.wizard.goToStep(Number(this.step));
    }
  }

  addMerchantClass() {
    const url = window.location.href;

    if (url && url.indexOf('/merchant/') != -1) {
      this.merchantClass = 'health-history-merchant';
      this.merchantClass2 = 'background-patterned';
    } else if (url && url.indexOf('/promoter/') != -1) {
      this.merchantClass = 'health-history-merchant';
      this.merchantClass2 = 'background-patterned';
    } else {
      this.merchantClass = '';
      this.merchantClass2 = '';
    }
  }

  rewriteStep(step) {
    const url = window.location.href;

    if (step == null) {
      if (url && url.indexOf('/consumer-app/') != -1) {
        window.history.replaceState({}, '', `/consumer-app/(page:medical)`);
      } else if (url && url.indexOf('/merchant/') != -1) {
        window.history.replaceState({}, '', `/merchant/(page:medical)`);
      } else if (url && url.indexOf('/promoter/') != -1) {
        window.history.replaceState({}, '', `/promoter/(page:medical)`);
      } else {
        window.history.replaceState({}, '', `/consumer-app/(page:medical)`);
      }
    } else {
      if (url && url.indexOf('/consumer-app/') != -1) {
        window.history.replaceState({}, '', `/consumer-app/(page:medical/step/${step})`);
      } else if (url && url.indexOf('/merchant/') != -1 && this.patientID) {
        window.history.replaceState({}, '', `/merchant/(page:medical/${this.patientID}/step/${step})`);
      } else if (url && url.indexOf('/promoter/') != -1 && this.patientID) {
        window.history.replaceState({}, '', `/promoter/(page:medical/${this.patientID}/step/${step})`);
      } else {
        window.history.replaceState({}, '', `/consumer-app/(page:medical/step/${step})`);
      }
    }
  }

  isEmptyListEvent(value) {
    this.isEmptyIllnessList = value;
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  selectIllness(item) {
    const _index = this._selectedillnessLabelList.indexOf(item.Code);

    if (item['showIllnessDescription'] == true) {
      const ref = RootAppComponent.dialog.open(IllnessCreateEditComponent, {
        data: {
          medicalHistoryID: this.medicalHistoryID,
          illness: item,
        },
        width: '650px',
      });

      ref.componentInstance.getEdited.subscribe((res) => {
        if (res && res.ID && res['Type.Code']) {
          if (
            this.currentIllnesses &&
            this.currentIllnesses.length > 0 &&
            this.currentIllnesses.indexOf(res['Type.Code']) === -1
          ) {
            this.currentIllnesses.push(res['Type.Code']);
          } else if (this.currentIllnesses && this.currentIllnesses.length <= 0) {
            this.currentIllnesses.push(res['Type.Code']);
          }
          ref.close();
          item['showIllnessDescription'] = false;

          this.isReviewIllness = true;
        } else {
          ref.close();
          item['showIllnessDescription'] = false;
        }
      });

      ref.componentInstance.getCreated.subscribe((res) => {
        if (res && res.ID && res['Type.Code']) {
          if (
            this.currentIllnesses &&
            this.currentIllnesses.length > 0 &&
            this.currentIllnesses.indexOf(res['Type.Code']) === -1
          ) {
            this.currentIllnesses.push(res['Type.Code']);
          } else if (this.currentIllnesses && this.currentIllnesses.length <= 0) {
            this.currentIllnesses.push(res['Type.Code']);
          }
          ref.close();
          item['showIllnessDescription'] = false;

          this.isReviewIllness = true;
        } else {
          ref.close();
          item['showIllnessDescription'] = false;
        }
      });

      ref.backdropClick().subscribe(() => {
        ref.close();
        item['showIllnessDescription'] = false;
      });
    }
  }

  /**
   * Add new illness from the list which excluded the existing illness.
   */
  addNewIllness() {
    window.scrollTo(0, 0);
    this.isReviewIllness = false;
    let _list;
    const _illnesslist = this.illnessList;
    const payload = {
      medicalHistoryID: this.medicalHistoryID,
      fields: 'Type.Code',
    };
    this.medicalHistoryService.getIllnessList(payload, this.sessionType).subscribe((res) => {
      if (res) {
        _list = res.map((x) => x['Type.Code']);

        this.illnessList = _illnesslist.filter((item) => _list.indexOf(item['Code']) === -1);

        this.isAddIllness = true;
      }
    });
  }

  reviewIllnesses() {
    window.scrollTo(0, 0);
    this.isReviewIllness = true;
  }

  /**
   *  sensitive selection
   */
  getSensitiveOption(item) {
    if (item && item.value == true) {
      this._sensitiveTeethWith.push(item.label);
    } else if (item.value == false) {
      const i = this._sensitiveTeethWith.indexOf(item.label);
      this._sensitiveTeethWith.splice(i, 1);
    }

    this.medicalHistory.sensitiveTeethWith = this._sensitiveTeethWith.join(';');
  }

  getPatientEmail(e) {
    this.patient.email = e;
  }

  getAdress(adr) {
    if (adr) {
      this.patient.suburb = adr.suburb;
      this.patient.state = adr.state;
      this.patient.postCode = adr.postCode;
      this.patient.streetNumber = adr.streetNumber;
      this.patient.streetName = adr.streetName;
      this.patient.unitNumber = adr.unitNumber;
      this.patient.streetType = adr.streetType;
      this.patient.latitude = adr.latitude;
      this.patient.longitude = adr.longitude;
    }
  }

  getMobile(p) {
    this.patient.mobile = p;
  }

  getHomePhone(p) {
    this.patient.phone = p;
  }

  getGuardianEmail(e) {
    this.guardianObject.email = e;
  }

  getGuardianMobile(e) {
    this.guardianObject.mobile = e;
  }

  validateGuardianEmailEvent(e) {
    this.isGuardianEmailValid = e;
  }

  validateGuardianMobileEvent(e) {
    this.isGuardianMobileValid = e;
  }

  getGpDoctorEmail(e) {
    this.gpDoctor.email = e;
  }

  getGpDoctorMobile(e) {
    this.gpDoctor.mobile = e;
  }

  validateGpDoctorEmailEvent(e) {
    this.isGpDoctorEmailValid = e;
  }

  validateGpDoctorMobileEvent(e) {
    this.isGpDoctorMobileValid = e;
  }

  createGPDoctor() {
    if (this.existingGpDoctor == false) {
      const payload = {
        cardID: this.patientID,
        tradingAs: this.gpDoctor.practiceName,
        firstName: this.gpDoctor.firstname,
        midleName: this.gpDoctor.middlename,
        lastName: this.gpDoctor.lastname,
        email: this.gpDoctor.email,
        mobile: this.gpDoctor.mobile,
        contactTypeCode: 'GP',
        type: 'gpDoctor',
      };
      this.contactService.createContact(payload, this.sessionType).subscribe((res) => {
        if (res) {
          this.buildDoctor(res);

          NotifyAppComponent.displayToast('success', 'Create GP Doctor', 'A GP doctor has been successfully created!');
          this.saveMedicalHistory();
        }
      });
    } else if (this.gpDoctor && this.gpDoctor.ID) {
      const payload = {
        cardID: this.patientID,
        tradingAs: this.gpDoctor.practiceName,
        firstName: this.gpDoctor.firstname,
        midleName: this.gpDoctor.middlename,
        lastName: this.gpDoctor.lastname,
        email: this.gpDoctor.email,
        mobile: this.gpDoctor.mobile,
        contactTypeCode: 'GP',
        type: 'gpDoctor',
      };
      this.contactService.modifyContact(this.gpDoctor.ID, payload, this.sessionType).subscribe((res) => {
        if (res) {
          this.buildDoctor(res);

          NotifyAppComponent.displayToast('success', 'Create GP Doctor', 'A GP doctor has been successfully created!');
          this.saveMedicalHistory();
        }
      });
    } else {
      this.selectedNextStep();
    }
  }

  editPatientDetails() {
    this.medicalHistory.formFilledOnBehalf = false;
    this.isModifyGuardian = false;

    this.updateHealthHistoryNotComplete('step');
  }

  guardianCheck() {
    this.medicalHistory.formFilledOnBehalf = true;
    this.isModifyGuardian = false;
  }

  getLastCheckUpDate(e) {
    this.medicalHistory.lastCheckUpDate = e;
  }

  saveGuardian() {
    if (this.medicalHistory.formFilledOnBehalf == true) {
      if (this.isModifyGuardian == true && this.hasGuardian == true && this.guardianID) {
        const payload = {
          firstName: this.guardianObject.firstname,
          midleName: this.guardianObject.middlename,
          lastName: this.guardianObject.lastname,
          email: this.guardianObject.email,
          mobile: this.guardianObject.mobile,
          gender: this.guardianObject.gender,
          title: this.guardianObject.title,
          relativeTypeCode: this.guardianObject.relativeTypeCode,
          contactTypeCode: 'GUAR',
        };
        this.contactService.modifyContact(this.guardianID, payload, this.sessionType).subscribe((res) => {
          if (res) {
            this.buildGuardian(res);
            NotifyAppComponent.displayToast(
              'success',
              'Update Guardian Details',
              "The guardian's details has been successfully updated!"
            );

            this.updateHealthHistoryNotComplete('step');
            this.isModifyGuardian = false;
          }
        });
      } else if (this.hasGuardian == false) {
        this.createGuardian();
        this.updateHealthHistoryNotComplete('step');
      } else {
        this.updateHealthHistoryNotComplete('step');
      }
    } else {
    }
  }

  createGuardian() {
    const payload = {
      cardID: this.patientID,
      firstName: this.guardianObject.firstname,
      midleName: this.guardianObject.middlename,
      lastName: this.guardianObject.lastname,
      email: this.guardianObject.email,
      mobile: this.guardianObject.mobile,
      gender: this.guardianObject.gender,
      title: this.guardianObject.title,
      relativeTypeCode: this.guardianObject.relativeTypeCode,
      contactTypeCode: 'GUAR',
      type: 'guardian',
    };
    this.contactService.createContact(payload, this.sessionType).subscribe((res) => {
      if (res) {
        this.guardianID = res['ID'];

        this.buildGuardian(res);
        NotifyAppComponent.displayToast('success', 'Create Guardian', 'A guardian has been successfully created!');
        this.hasGuardian = true;
        this.medicalHistory.formFilledOnBehalf = true;
        this.medicalHistoryService
          .modifyMedicalHistory(this.medicalHistoryID, this.medicalHistory, this.sessionType)
          .subscribe((res) => {
            if (res) {
              NotifyAppComponent.displayToast(
                'success',
                'Successful operation',
                'You have successfully updated your health history'
              );
            }
          });
      }
    });
  }

  removeGuardian() {
    this.medicalHistoryService.removeGuardian(this.guardianID).subscribe((res) => {
      if (res) {
        this.guardianObject = {
          gender: null,
          title: null,
          firstname: null,
          middlename: null,
          lastname: null,
          email: null,
          mobile: null,
          relativeTypeCode: null,
        };
        this.hasGuardian = false;
        this.medicalHistory.formFilledOnBehalf = false;
        this.medicalHistoryService
          .modifyMedicalHistory(this.medicalHistoryID, this.medicalHistory, this.sessionType)
          .subscribe((res) => {
            if (res) {
              NotifyAppComponent.displayToast(
                'success',
                'Successful operation',
                'You have successfully updated your health history'
              );
            }
          });
      }
    });
  }

  contactPractice(subject: any) {
    if (this.patientID && this.merchant && this.merchant.ID) {
      const data = {
        cardID: this.patientID,
        targetType: 'merchant',
        targetID: this.merchant.ID,
        asGuest: true,
        asProfile: false,
        asPractice: false,
        subject: subject || null,
      };
      AuthenticationService.contactInputEvent.emit(data);

      if (subject.includes('help')) {
        this.medicalHistory.needsHelpWithForm = true;
      }

      if (subject.includes('staff')) {
        this.medicalHistory.speakInPrivateToStaff = true;
      }
    }
  }

  openContentDialogComplete() {
    const ref1 = this.dialog.open(MedicalHistoryAcknowledgementComponent, {
      panelClass: 'infConsent',
    });
    ref1.componentInstance.agreement.subscribe((data) => {
      if (data === true) {
        this.medicalHistory.complete = true;
        this.medicalHistoryService
          .modifyMedicalHistory(this.medicalHistoryID, this.medicalHistory, this.sessionType)
          .subscribe((res) => {
            if (res) {
              this.createdObject = res;
              AuthenticationService.updateHealthHistory.emit();
              NotifyAppComponent.displayToast(
                'success',
                'Successful operation',
                'You have successfully updated your health history'
              );
              ref1.close();
              this.selectedNextStepForward();
            }
          });
      }
    });
  }

  updateHealthHistoryNotComplete(type = 'forward', start = 4) {
    this.medicalHistory.complete = false;

    this.medicalHistoryService
      .modifyMedicalHistory(this.medicalHistoryID, this.medicalHistory, this.sessionType)
      .subscribe((res) => {
        if (res) {
          if (type == 'forward') {
            this.selectedNextStepForward();
          } else if (type == 'step') {
            this.selectedNextStep();
          } else if (type == 'proceed' && start) {
            this.proceedAndShowStar(start);
          } else {
            this.selectedNextStepForward();
          }
        }
      });
  }

  openPrivacyDialog() {
    const ref = RootAppComponent.dialog.open(MedicalHistoryPrivacyModalComponent, {
      width: '600px',
      data: this.merchantID,
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  openLanguageDialog() {
    const ref = RootAppComponent.dialog.open(MedicalHistoryLanguageModalComponent, {
      width: '600px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  proceedAndShowStar(e: any) {
    const ref = RootAppComponent.dialog.open(MedicalHistoryProgressModalComponent, {
      data: e,
      width: '550px',
      disableClose: true,
    });

    ref.backdropClick().subscribe((res) => {
      ref.close();
      this.selectedNextStepForward();
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
        this.selectedNextStepForward();
      }
    });

    if (e == 1) {
      this.stepCounter = 0;
    } else if (e == 2) {
      this.stepCounter = 100;
    } else if (e == 3) {
      this.stepCounter = 100;
    } else if (e == 4) {
      this.stepCounter = 100;
    }
  }

  triggerAnim(e) {
    this.anim = false;
    if (e == true) {
      setTimeout(() => {
        this.anim = true;
      }, 500);
    } else {
      this.anim = false;
    }
  }

  modifyMedicalHistory() {
    this.medicalHistoryService
      .modifyMedicalHistory(this.medicalHistoryID, this.medicalHistory, this.sessionType)
      .subscribe((res) => {
        if (res) {
          NotifyAppComponent.displayToast(
            'success',
            'Successful operation',
            'You have successfully updated your health history'
          );
        }
      });
  }

  saveMedicalHistory() {
    if (this.isEdit == false && this.patientID && this.merchantID) {
      this.medicalHistory['cardID'] = this.patientID;
      this.medicalHistory['merchantID'] = this.merchantID;
      this.medicalHistoryService.createMedicalHistory(this.medicalHistory, this.sessionType).subscribe((res) => {
        if (res) {
          this.medicalHistoryID = res['ID'];
          this.isEdit = true;

          this.selectedNextStep();
        }
      });
    } else if (this.isEdit == true) {
      this.selectedNextStep();
    }
  }

  saveMedicalHistoryAndProceed(e) {
    this.changeDateCheckup = false;
    this.updateHealthHistoryNotComplete('proceed', e);
    this.isModifyGuardian = false;
  }

  createMedicare() {
    if (this.existingMedicare == false) {
      if (this.medicareExpiry && this.medicareNumber) {
        const payload = {
          ownerID: this.patientID,
          typeCode: 'AU_IDM',
          number: this.medicareNumber,
          cardFirstName: this.patient.firstname,
          cardMiddleName: this.patient.middlename,
          cardLastName: this.patient.lastname,
          expireDate: this.medicareExpiry,
          referenceNumber: this.medicareReferenceNumber,
        };
        this.medicalHistoryService.createMedicare(payload, this.sessionType).subscribe((res) => {
          if (res) {
            this.buildMediCare(res);

            NotifyAppComponent.displayToast(
              'success',
              'Create Medicare Card',
              'A medicare card has been successfully created!'
            );
            this.selectedNextStep();
          }
        });
      }
    } else if (this.medicareID) {
      if (this.medicareExpiry && this.medicareNumber) {
        const payload = {
          ownerID: this.patientID,
          typeCode: 'AU_IDM',
          number: this.medicareNumber,
          cardFirstName: this.patient.firstname,
          cardMiddleName: this.patient.middlename,
          cardLastName: this.patient.lastname,
          expireDate: this.medicareExpiry,
          referenceNumber: this.medicareReferenceNumber,
        };
        this.medicalHistoryService.modifyMedicare(this.medicareID, payload, this.sessionType).subscribe((res) => {
          if (res) {
            this.buildMediCare(res);
            NotifyAppComponent.displayToast(
              'success',
              'Create Medicare Card',
              'A medicare card has been successfully created!'
            );
            this.selectedNextStep();
          }
        });
      }
    } else {
      this.selectedNextStep();
    }
  }

  selectDateToFilter(e) {
    this.patient.dateOfBirth = e;
  }

  getMedicareDate(e) {
    this.medicareExpiry = e;
  }

  createHealthFund() {
    if (this.existingHealthInsurance == false) {
      if (this.healthFundName && this.healthFundNumber) {
        const payload = {
          ownerID: this.patientID,
          typeCode: 'AU_PHF',
          number: this.healthFundNumber,
          cardFirstName: this.patient.firstname,
          cardMiddleName: this.patient.middlename,
          cardLastName: this.patient.lastname,
          issuerCode: this.healthFundName,
          issuerLabelOther: this.healthFundNameOther,
        };
        this.medicalHistoryService.createHealthFund(payload, this.sessionType).subscribe((res) => {
          if (res) {
            this.buildHealthFund(res);

            NotifyAppComponent.displayToast(
              'success',
              'Create Private Health Insurance',
              'A private health insurance has been successfully created!'
            );
            this.proceedAndShowStar(3);
          }
        });
      }
    } else if (this.healthFundID) {
      if (this.healthFundName && this.healthFundNumber) {
        const payload = {
          ownerID: this.patientID,
          typeCode: 'AU_PHF',
          number: this.healthFundNumber,
          cardFirstName: this.patient.firstname,
          cardMiddleName: this.patient.middlename,
          cardLastName: this.patient.lastname,
          issuerCode: this.healthFundName,
          issuerLabelOther: this.healthFundNameOther,
        };
        this.medicalHistoryService.modifyMedicare(this.healthFundID, payload, this.sessionType).subscribe((res) => {
          if (res) {
            this.buildHealthFund(res);
            NotifyAppComponent.displayToast(
              'success',
              'Create Private Health Insurance',
              'A private health insurance has been successfully created!'
            );
            this.proceedAndShowStar(3);
          }
        });
      }
    } else {
      // this.selectedNextStep();
      this.proceedAndShowStar(3);
    }
  }

  stepEntered(direction: MovingDirection): void {
    // let step = this.wizard.currentStepIndex;
    if (direction == 0) {
      this.enterslide = 'fadeInRight';
    } else if (direction == 1) {
      this.enterslide = 'fadeInLeft';
    } else if (direction == 2) {
      this.enterslide = '';
    }
  }

  // proceed, no delay

  letGetStarted() {
    if (this.medicalHistoryID) {
      this.medicalHistoryService.commenceMedicalHistory(this.medicalHistoryID, this.sessionType).subscribe((res) => {
        if (res) {
          this.enterslide = 'fadeOutLeft';
          setTimeout(() => {
            window.scrollTo(0, 0);
            this.wizard.goToNextStep();
            this.medicationHistoryStepUpdate();
          }, 350);
        } else {
          this.enterslide = 'fadeOutLeft';
          setTimeout(() => {
            window.scrollTo(0, 0);
            this.wizard.goToNextStep();
            this.medicationHistoryStepUpdate();
          }, 350);
        }
      });
    } else {
      this.enterslide = 'fadeOutLeft';
      setTimeout(() => {
        window.scrollTo(0, 0);
        this.wizard.goToNextStep();
        this.medicationHistoryStepUpdate();
      }, 350);
    }
  }

  medicationHistoryStepUpdate(isBack = false) {
    if (!this.step) {
      this.step = 1;
    }
    if (isBack == true) {
      this.step = this.step - 1;
    } else {
      this.step = Number(this.wizard.currentStepIndex) + 1;
    }

    this.medicalHistoryService.modifyStep(this.medicalHistoryID, this.step, this.sessionType).subscribe((res) => {
      this.rewriteStep(this.step);
    });
  }

  selectedNextStep() {
    this.enterslide = 'fadeOutLeft';
    setTimeout(() => {
      window.scrollTo(0, 0);
      this.wizard.goToNextStep();
      this.medicationHistoryStepUpdate();
    }, 350);
  }

  // proceed, delay to show button change
  selectedNextStepForward() {
    setTimeout(() => {
      this.enterslide = 'fadeOutLeft';
    }, 150);
    setTimeout(() => {
      window.scrollTo(0, 0);
      this.wizard.goToNextStep();
      this.medicationHistoryStepUpdate();
    }, 350);
  }

  // go back, delay for animation
  selectedNextStepBackward() {
    this.enterslide = 'fadeOutRight';
    setTimeout(() => {
      window.scrollTo(0, 0);
      this.wizard.goToPreviousStep();
      this.medicationHistoryStepUpdate(true);
    }, 350);
  }

  triggerTheAnimation() {
    setTimeout(() => {
      this.anim = true;
    }, 500);
  }

  endTheAnimation() {
    this.anim = true;
  }

  backToPatientCard() {
    const url = window.location.href;

    if (this.createdObject && this.createdObject.ID && this.createdObject.Card_key) {
      if (url && url.indexOf('/consumer-app/') != -1) {
        this.router.navigate([
          '/consumer-app',
          { outlets: { page: ['my-health-history-list', this.createdObject.ID] } },
        ]);
      } else if (url && url.indexOf('/merchant/') != -1) {
        this.router.navigate([
          '/merchant',
          {
            outlets: {
              page: ['customer-profile', this.createdObject.Card_key, 'health', this.createdObject.ID],
            },
          },
        ]);
      } else if (url && url.indexOf('/promoter/') != -1) {
        this.router.navigate([
          'merchant',
          {
            outlets: {
              page: ['customer-profile', this.createdObject.Card_key, 'health', this.createdObject.ID],
            },
          },
        ]);
      } else {
        this.router.navigate(['/consumer-app', { outlets: { page: ['my-health-history-list'] } }]);
      }
    } else {
      if (url && url.indexOf('/consumer-app/') != -1) {
        this.router.navigate(['/consumer-app', { outlets: { page: ['my-health-history-list'] } }]);
      } else if (url && url.indexOf('/merchant/') != -1) {
        this.router.navigate([
          '/merchant',
          {
            outlets: {
              page: ['medical-history-overview'],
            },
          },
        ]);
      } else if (url && url.indexOf('/promoter/') != -1) {
        this.router.navigate([
          '/merchant',
          {
            outlets: {
              page: ['medical-history-overview'],
            },
          },
        ]);
      } else {
        this.router.navigate(['/consumer-app', { outlets: { page: ['my-health-history-list'] } }]);
      }
    }
  }

  contactDentist(id) {
    if (id) {
      const data = {
        targetType: 'contact',
        targetID: id,
        asGuest: false,
        asProfile: true,
        asPractice: true,
      };
      AuthenticationService.contactInputEvent.emit(data);
    }
  }

  getNumberOfIllness(e) {
    this.myIllnessCount = e;
  }

  updatePatient() {
    this.customerProspectService
      .modifyCustomerProspect(this.patientID, this.patient, this.sessionType)
      .subscribe((res) => {
        if (res && res.ID) {
          NotifyAppComponent.displayToast(
            'success',
            'Updated Patient Details',
            'The patient has been successfully updated!'
          );
          this.buildPatient(res);
          this.selectedNextStep();
          this.isNameCorrect = true;
        }
      });
  }

  selectedDateChanged(data) {
    this.medicalHistory.lastCheckUpDate = data;
  }

  buildCurrentIllnessCode(arr) {
    if (arr && arr.length > 0) {
      const r = _.map(arr, (item) => {
        if (item) {
          return item['Type.Code'];
        }

        return false;
      });

      this.currentIllnesses = _.uniq(r);
    } else {
      this.currentIllnesses = [];
    }
  }
}
