import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'FilterArrayMultipleOrContain',
  pure: false,
})
@Injectable()
export class FilterArrayMultipleOrContain implements PipeTransform {
  transform(expression: any, format: any, value: any) {
    if (format && typeof format === 'string' && value) {
      const _value = String(value).toLocaleLowerCase();

      return expression.filter((item) => {
        if (item && item[format] && String(item[format]).toLocaleLowerCase().indexOf(_value) !== -1) {
          return true;
        } else {
          return false;
        }
      });
    } else if (format && Array.isArray(format) === true && value) {
      const _value = String(value).toLocaleLowerCase();

      return expression.filter((item) => {
        for (let index = 0; index < format.length; index++) {
          if (item && item[format[index]] && String(item[format[index]]).toLocaleLowerCase().indexOf(_value) !== -1) {
            return true;
          }
        }

        return false;
      });
    }

    return expression;
  }
}
