import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { WikiService } from '../shared/wiki.service';
import { WikiGroupEditComponent } from '../wiki-group-edit/wiki-group-edit.component';
import { WikiLeftMenuComponent } from '../wiki-left-menu/wiki-left-menu.component';

@Component({
  selector: 'app-wiki-group-view',
  templateUrl: './wiki-group-view.component.html',
  styleUrls: ['./wiki-group-view.component.css'],
})
export class WikiGroupViewComponent implements OnInit {
  @Input()
  groupID;

  @Input()
  code;

  pages;
  loading = false;
  group;

  utils = new UtilsClass();
  orderBy = 'priority';
  sessionType;

  sessionTypeParams;
  isPromoterOrAdmin = false;

  constructor(
    public authenticationService: AuthenticationService,
    private router: Router,
    private wikiService: WikiService,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.loading = false;

    const _res = this.utils.getSessionTypeParams(this.router.url);

    if (_res) {
      this.sessionTypeParams = _res;
    }

    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });

    this.activeRoute.params.subscribe((params) => {
      if (params['groupID']) {
        this.groupID = params['groupID'];
      }

      if (params['code']) {
        this.code = params['code'];
      }

      this.authenticationService.getSessionType().subscribe((res) => {
        if (res && this.sessionTypeParams && (res == 'admin' || res == 'promoter')) {
          this.sessionType = this.sessionTypeParams;
        } else if (res) {
          this.sessionType = res;
          this.sessionTypeParams = null;
        }

        this.setup();
      });
    });
  }

  setup() {
    this.loading = false;

    if (this.groupID || this.code) {
      this.wikiService.getWikiGroupDetails(this.groupID, this.code, {}, this.sessionType).subscribe((res3) => {
        if (res3) {
          if (res3 && res3.orderBy) {
            this.orderBy = res3.orderBy;
          }

          this.group = res3;

          this.loading = true;
        }
      });
    }
  }

  isNew(date) {
    if (date) {
      const days = this.utils.daysPast(date);

      if (days < 15) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  getSrc(id) {
    if (id) {
      return `${environment.nodeUrl}/files/file-view/${id}`;
    }
  }

  isAwsome(icon) {
    if (icon && icon.indexOf('fa-') != -1) {
      return true;
    }

    return false;
  }

  enable() {
    if (this.group) {
      const confirm = new ConfirmDialog(
        'check_circle',
        '',
        'Are you sure you want to enable this Category?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          const p = {
            isActive: true,
          };

          this.wikiService.updateWikiGroup(this.group._id, p).subscribe((res) => {
            if (res) {
              ref.close();

              this.group = res;
              NotifyAppComponent.displayToast('success', 'Successfull operation', 'Wiki Category is Updated ');
              WikiLeftMenuComponent.updateHeader.emit(true);
            }
          });
        }
      });
    }
  }

  disable() {
    if (this.group) {
      const confirm = new ConfirmDialog('cancel', '', 'Are you sure you want to disable this Category?', 'No', 'Yes');

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          const p = {
            isActive: false,
          };

          this.wikiService.updateWikiGroup(this.group._id, p).subscribe((res) => {
            if (res) {
              ref.close();

              this.group = res;
              NotifyAppComponent.displayToast('success', 'Successfull operation', 'Wiki Category is Updated ');
              WikiLeftMenuComponent.updateHeader.emit(true);
            }
          });
        }
      });
    }
  }

  remove() {
    if (this.group) {
      const confirm = new ConfirmDialog('delete', '', 'Are you sure you want to Remove this Category?', 'No', 'Yes');

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.wikiService.removeWikiGroup(this.group._id).subscribe((res) => {
            if (res) {
              ref.close();
              NotifyAppComponent.displayToast('success', 'Successfull operation', 'Wiki Category is Removed ');

              this.router.navigate(['/wiki/']);
              WikiLeftMenuComponent.updateHeader.emit(true);
            }
          });
        }
      });
    }
  }

  edit() {
    if (this.group) {
      const ref = RootAppComponent.dialog.open(WikiGroupEditComponent, {
        data: {
          groupID: this.group._id,
        },

        width: '800px',
        panelClass: 'noCard',
      });

      ref.componentInstance.result.subscribe((res) => {
        if (res) {
          this.group = res;
          WikiLeftMenuComponent.updateHeader.emit(true);
        }
        ref.close();
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
    }
  }

  firstLetterUpperCase(r) {
    if (r && r[0]) {
      return String(r[0]).toUpperCase();
    }
  }

  login() {
    const activeURL = window.location.pathname;
    if (activeURL) {
      const __url = activeURL.replace(/\//g, '$');

      this.router.navigate(['/login/' + __url]);
    } else {
      this.router.navigate(['/login/']);
    }
  }
}
