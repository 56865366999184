<div class="full-width clearfix">
  <div *ngFor="let m of _items; let index = index">
    <div class="clearfix container-inner-parent" *ngIf="m == 'plans'"
      [attr.style]="'background:' + getBackgroundColor(index) + ' !important' | safeStyle" data-aos="fade-right"
      data-aos-delay="500" data-aos-easing="ease-in-sine" data-aos-once="true">
      <div class="clearfix container-inner mb mt">
        <div class="full-width clearfix text-center container-inner-info" data-aos="fade-right" data-aos-delay="400">
          <h2 class="getting-started-page primary-color">
            Your competitors understand the importance of {{ "KEYWORD.practice" | translate }}s building a digital
            presence through consistent email marketing and social media.
          </h2>
          <p class="getting-started-page">
            With the average {{ "KEYWORD.practice" | translate }} spending a minimum of $1000 per month on
            {{ "KEYWORD.patient" | translate }} communication and marketing and some spending significantly more, how
            does your {{ "KEYWORD.practice" | translate }} compare?
          </p>
          <button class="btn-large clearfix btn-main-info"
            [attr.style]="'background-color:' + color + ' !important' | safeStyle" (click)="comparePlans()"
            mat-raised-button color="primary">
            Compare All Plans
            <mat-icon class="fas fa-chevron-right sml smr"></mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="clearfix container-inner-parent" *ngIf="m == 'plans-overview' || m == '-plans-overview'"
      [attr.style]="'background:' + getBackgroundColor(index) + ' !important' | safeStyle" data-aos="fade-right"
      data-aos-delay="500" data-aos-easing="ease-in-sine" data-aos-once="true">
      <div class="clearfix container-inner mt">
        <div class="guid flex clearfix mt full-width" [ngClass]="{ 'guid-right': m === 'plans-overview' }">
          <div class="container guid-main">
            <div class="half-width img-box-left" *ngIf="m == 'plans-overview'">
              <div class="guid-imgbox guid-imgbox-right">
                <img *ngIf="context!=='p4p'" class="img"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/campaign/price/Asset_4.png" />

                <img *ngIf="context=='p4p'" class="img" style="max-width:100%"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/declined-application-header-cus.png" />
              </div>
            </div>

            <div class="half-width img-box-left img-box-left-first" *ngIf="m == '-plans-overview'">
              <div class="guid-imgbox guid-imgbox-left">
                <img class="img" *ngIf="context!=='p4p'"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/campaign/price/Asset_4.png" />

                <img *ngIf="context=='p4p'" class="img" style="max-width:100%"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/declined-application-header-cus.png" />
              </div>
            </div>

            <div class="half-width txt-box flex">
              <div class="full-width mr ml txt">
                <h2 class="getting-started-page accent-color">It’s not about the price, its about the value</h2>
                <p class="getting-started-page">
                  When considering your options, it’s easy to compare dollars and cents, but without reviewing all the
                  details you could end up with a bus ticket instead of a boarding pass.
                </p>
                <button class="btn-large btn-main-info" (click)="comparePlans()" mat-raised-button color="accent">
                  Compare subscription plans
                  <mat-icon class="fas fa-chevron-right sml smr"></mat-icon>
                </button>
              </div>
            </div>

            <div class="half-width img-box-left img-box-left-second" *ngIf="m == '-plans-overview'">
              <div class="guid-imgbox guid-imgbox-left">
                <img class="img" *ngIf="context!=='p4p'"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/campaign/price/Asset_4.png" />
                <img *ngIf="context=='p4p'" class="img" style="max-width:100%"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/declined-application-header-cus.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="clearfix container-inner-parent" *ngIf="m == 'plans-price' || m == '-plans-price'"
      [attr.style]="'background:' + getBackgroundColor(index) + ' !important' | safeStyle" data-aos="fade-right"
      data-aos-delay="500" data-aos-easing="ease-in-sine" data-aos-once="true">
      <div class="clearfix container-inner mt">
        <div class="guid flex clearfix mt full-width" [ngClass]="{ 'guid-right': m === 'plans-price' }">
          <div class="container guid-main">
            <div class="half-width img-box-left" *ngIf="m == 'plans-price'">
              <div class="guid-imgbox guid-imgbox-right">
                <img class="img" style="max-width: 100%" *ngIf="context!=='p4p'"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/campaign/price/Asset_1.png" />

                <img class="img" style="max-width: 40%;  margin-bottom: -40px;" *ngIf="context=='p4p'"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/p4p/vet-duke.png" />

              </div>
            </div>

            <div class="half-width img-box-left img-box-left-first" *ngIf="m == '-plans-price'">
              <div class="guid-imgbox guid-imgbox-left"> *ngIf="context!=='p4p'"
                <img class="img" style="max-width: 100%"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/campaign/price/Asset_1.png" />

                <img class="img" style="max-width: 40%;  margin-bottom: -40px;" *ngIf="context=='p4p'"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/p4p/vet-duke.png" />
              </div>
            </div>

            <div class="half-width txt-box flex">
              <div class="full-width mr ml txt">
                <h2 class="getting-started-page accent-color">Find a plan that’s right for you!</h2>
                <p class="getting-started-page">
                  Whether you want better {{"KEYWORD.patient" | translate}} communication and finance options, or the
                  whole package deal - {{"BRANDING.Brand Name" | translate}} has a plan to suit all your needs.
                </p>
                <button class="btn-large btn-main-info" (click)="comparePlansPrice()" mat-raised-button color="accent">
                  Check our subscription plans
                  <mat-icon class="fas fa-chevron-right sml smr"></mat-icon>
                </button>
              </div>
            </div>

            <div class="half-width img-box-left img-box-left-second" *ngIf="m == '-plans-price'">
              <div class="guid-imgbox guid-imgbox-left">
                <img class="img" style="max-width: 100%" *ngIf="context!=='p4p'"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/campaign/price/Asset_1.png" />

                <img class="img" style="max-width: 40%;  margin-bottom: -40px;" *ngIf="context=='p4p'"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/p4p/vet-duke.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="clearfix container-inner-parent" *ngIf="m == 'features' || m == '-features'"
      [attr.style]="'background:' + getBackgroundColor(index) + ' !important' | safeStyle" data-aos="fade-right"
      data-aos-delay="500" data-aos-easing="ease-in-sine" data-aos-once="true">
      <div class="clearfix container-inner mt">
        <div class="guid flex clearfix full-width" [ngClass]="{ 'guid-right': m === 'features' }">
          <div class="container guid-main">
            <div class="half-width img-box-left" *ngIf="m == 'features'">
              <div class="guid-imgbox guid-imgbox-right">
                <img class="img" style="max-width: 100%" *ngIf="context!=='p4p'"
                  src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/public/img/group-shot.png" />

                <img class="img" style="max-width: 100%" *ngIf="context=='p4p'"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/p4p/duke-racer.png" />

              </div>
            </div>

            <div class="half-width img-box-left img-box-left-first" *ngIf="m == '-features'">
              <div class="guid-imgbox guid-imgbox-left">
                <img class="img" style="max-width: 100%" *ngIf="context!=='p4p'"
                  src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/public/img/group-shot.png" />

                <img class="img" style="max-width: 100%" *ngIf="context=='p4p'"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/p4p/duke-racer.png" />
              </div>
            </div>

            <div class="half-width txt-box flex">
              <div class="full-width mr ml txt">
                <h2 class="getting-started-page primary-color">
                  We’re built for growing your {{ "KEYWORD.practice" | translate }}
                </h2>
                <p class="getting-started-page">
                  We provide world-class technology and support to help your {{ "KEYWORD.practice" | translate }} grow.
                  See how we can help with the many features our platform offers below.
                </p>
                <button class="btn-large btn-main-info" (click)="Features()" mat-raised-button color="primary">
                  Check our features!
                  <mat-icon class="fas fa-chevron-right sml smr"></mat-icon>
                </button>
              </div>
            </div>

            <div class="half-width img-box-left img-box-left-second" *ngIf="m == '-features'">
              <div class="guid-imgbox guid-imgbox-left">
                <img class="img" style="max-width: 100%" *ngIf="context!=='p4p'"
                  src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/public/img/group-shot.png" />

                <img class="img" style="max-width: 100%" *ngIf="context=='p4p'"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/p4p/duke-racer.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="clearfix container-inner-parent" *ngIf="m == 'free' || m == '-free'"
      [attr.style]="'background:' + getBackgroundColor(index) + ' !important' | safeStyle" data-aos="fade-right"
      data-aos-delay="500" data-aos-easing="ease-in-sine" data-aos-once="true">
      <div class="clearfix container-inner mt">
        <div class="guid flex clearfix mt full-width" [ngClass]="{ 'guid-right': m === 'free' }">
          <div class="container guid-main">
            <div class="half-width img-box-left" *ngIf="m == 'free'">
              <div class="guid-imgbox guid-imgbox-right">
                <img class="img" style="max-width: 100%" *ngIf="context!=='p4p'"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/campaign/price/Asset_2.png" />

                <img class="img" style="max-width: 100%" *ngIf="context=='p4p'"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/p4p/cashbag-sm.png" />

              </div>
            </div>

            <div class="half-width img-box-left img-box-left-first" *ngIf="m == '-free'">
              <div class="guid-imgbox guid-imgbox-left">
                <img class="img" style="max-width: 100%" *ngIf="context!=='p4p'"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/campaign/price/Asset_2.png" />

                <img class="img" style="max-width: 100%" *ngIf="context=='p4p'"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/p4p/cashbag-sm.png" />
              </div>
            </div>

            <div class="half-width txt-box flex">
              <div class="full-width mr ml txt">
                <h2 class="getting-started-page accent-color">
                  Want to subscribe for Standard or Premium packages and pay no monthly fees?
                </h2>
                <p class="getting-started-page">
                  Yes, you are reading that right - FREE. Click on the button below and we will show you how!
                </p>
                <button class="btn-large btn-main-info" (click)="freeAccount()" mat-raised-button color="accent">
                  Free? Show Me How
                  <mat-icon class="fas fa-chevron-right sml smr"></mat-icon>
                </button>
              </div>
            </div>

            <div class="half-width img-box-left img-box-left-second" *ngIf="m == '-free'">
              <div class="guid-imgbox guid-imgbox-left">
                <img class="img" style="max-width: 100%" *ngIf="context!=='p4p'"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/campaign/price/Asset_2.png" />

                <img class="img" style="max-width: 100%" *ngIf="context=='p4p'"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/p4p/cashbag-sm.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix container-inner-parent" *ngIf="m == 'demo' || m == '-demo'"
      [attr.style]="'background:' + getBackgroundColor(index) + ' !important' | safeStyle" data-aos="fade-right"
      data-aos-delay="500" data-aos-easing="ease-in-sine" data-aos-once="true">
      <div class="clearfix container-inner mt">
        <div class="guid flex clearfix full-width" [ngClass]="{ 'guid-right': m === 'demo' }">
          <div class="container guid-main">
            <div class="half-width img-box-left" *ngIf="m == 'demo'">
              <div class="guid-imgbox guid-imgbox-right">
                <img class="img" style="max-width: 100%"  *ngIf="context!=='p4p'"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/campaign/price/Asset_3.png" />

                <img class="img" style="max-width: 100%"  *ngIf="context=='p4p'"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/p4p/dentist_payment_schedule.png" />

              </div>
            </div>

            <div class="half-width img-box-left img-box-left-first" *ngIf="m == '-demo'">
              <div class="guid-imgbox guid-imgbox-left">
                <img class="img" style="max-width: 100%"  *ngIf="context!=='p4p'"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/campaign/price/Asset_3.png" />

                <img class="img" style="max-width: 100%"  *ngIf="context=='p4p'"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/p4p/dentist_payment_schedule.png" />
              </div>
            </div>

            <div class="half-width txt-box flex">
              <div class="full-width mr ml txt">
                <h2 class="getting-started-page primary-color">Not 100% convinced?</h2>
                <p class="getting-started-page">
                  Book a FREE online demonstration with one of our consultants for
                  you and the team!
                </p>
                <button class="btn-large btn-main-info" (click)="booking()" mat-raised-button color="primary">
                  Book an online demonstration!
                  <mat-icon class="fas fa-chevron-right sml smr"></mat-icon>
                </button>
              </div>
            </div>

            <div class="half-width img-box-left img-box-left-second" *ngIf="m == '-demo'">
              <div class="guid-imgbox guid-imgbox-left">
                <img class="img" style="max-width: 100%"  *ngIf="context!=='p4p'"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/campaign/price/Asset_3.png" />

                <img class="img" style="max-width: 100%"  *ngIf="context=='p4p'"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/p4p/dentist_payment_schedule.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
