<div class="container" *ngIf="!invitationOverViewStatistics && !invitationStatistics" @ngIfAnimation>
  <mat-card class="card dataCard minHeight clearfix">
    <!-- css loader -->
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <!-- /css loader -->
  </mat-card>
</div>

<div class="container" *ngIf="invitationOverViewStatistics && invitationStatistics" @ngIfAnimation>
  <app-operator-filter
    class="dash clearfix"
    *ngIf="isPromoterOrAdmin == true"
    [displayMerchant]="true"
    [displayDateFrom]="true"
    [displayDateTo]="true"
    [displayProduct]="false"
    [displayDentist]="false"
    (getMerchant)="getMerchant($event)"
    (getDateTo)="getDateTo($event)"
    (getDateFrom)="getDateFrom($event)"
    (search)="getResult($event)"
    tabindex="-1"
  ></app-operator-filter>

  <app-operator-filter
    class="dash clearfix"
    *ngIf="isPromoterOrAdmin == false"
    [displayMerchant]="false"
    [displayDateFrom]="true"
    [displayDateTo]="true"
    [displayProduct]="false"
    [displayDentist]="false"
    (getMerchant)="getMerchant($event)"
    (getDateTo)="getDateTo($event)"
    (getDateFrom)="getDateFrom($event)"
    (search)="getResult($event)"
    tabindex="-1"
  ></app-operator-filter>

  <div
    class="row clearfix full-width flex-row"
    id="tool2"
    *ngIf="false && invitationStatistics && invitationStatistics.global && displayInvitationList == false"
  >
    <mat-card class="flex-block staticsCard good" *ngIf="invitationStatistics.global['Count(*)']" tabindex="0">
      <h3>Total Invitations</h3>
      <mat-divider></mat-divider>
      <span> {{ invitationStatistics.global['Count(*)'] }} </span>
    </mat-card>
    <mat-card
      class="flex-block staticsCard bad"
      *ngIf="invitationStatistics.global['Sum(treatment.Value)']"
      tabindex="0"
    >
      <h3>Total {{ "KEYWORD.treatment" | translate | titlecase }} Amount</h3>
      <mat-divider></mat-divider>
      <span> {{ invitationStatistics.global['Sum(treatment.Value)'] | customCurrency }} </span>
    </mat-card>
    <mat-card class="flex-block staticsCard" *ngIf="invitationStatistics.global['Avg(treatment.Value)']" tabindex="0">
      <h3>Average Invitation Value</h3>
      <mat-divider></mat-divider>
      <span> {{ invitationStatistics.global['Avg(treatment.Value)'] | customCurrency }} </span>
    </mat-card>
    <mat-card
      class="flex-block staticsCard yellow"
      *ngIf="invitationStatistics.global['Min(treatment.Value)']"
      tabindex="0"
    >
      <h3>Min {{ "KEYWORD.treatment" | translate | titlecase }} $</h3>
      <mat-divider></mat-divider>
      <span> {{ invitationStatistics.global['Min(treatment.Value)'] | customCurrency }} </span>
    </mat-card>
    <mat-card
      class="flex-block staticsCard green"
      *ngIf="invitationStatistics.global['Max(treatment.Value)']"
      tabindex="0"
    >
      <h3>Max {{ "KEYWORD.treatment" | translate | titlecase }}$</h3>
      <mat-divider></mat-divider>
      <span> {{ invitationStatistics.global['Max(treatment.Value)'] | customCurrency }} </span>
    </mat-card>
  </div>

  <div class="row clearfix full-width flex" *ngIf="displayInvitationList == false">
    <!-- <div class="half-width"> -->

    <!-- </div> -->

    <mat-card
      class="card dataCard"
      *ngIf="invitationStatistics && invitationStatistics.status"
      [class.mat-elevation-z5]="mouseOvered3"
      (mouseover)="mouseOvered3 = true"
      (mouseout)="mouseOvered3 = false"
    >
      <div class="row clearfix">
        <p class="pull-right" *ngIf="dateFrom != null || dateTo != null">
          <span *ngIf="dateFrom"
          >From:
            <strong>{{ dateFrom | customDateTime }} </strong>
          </span>

          <span *ngIf="dateTo">
            To:
            <strong>{{ dateTo | customDateTime }}</strong>
          </span>
        </p>
        <h2 class="lg-h2 rm-m sr-title" tabindex="0"> {{"KEYWORD.Treatment" | translate}} Invitation Decisions &amp;
          Acceptance</h2>
        <label class="subtitle" tabindex="0">{{"KEYWORD.patient" | translate | titlecase}} card response
          and {{ "KEYWORD.treatment" | translate }} invitation acceptance</label>
        <hr/>
      </div>

      <div class="row clearfix">
        <div class="half-width">
          <div class="col-left">
            <div class="colour-stripe"></div>

            <div class="row clearfix full-width">
              <app-chart-input
                class="row"
                [data]="
                  invitationStatistics.status
                    | FilterArrayMultipleValue: 'ID':invitationUtil.status.allInvitation
                    | OrderArrayObject: ['name']
                "
                [chartType]="'pie'"
                [titleIsTop]="false"
                [title]="''"
                [dataNameAttribute]="'name'"
                [dataValueAttribute]="'Count(*)'"
                tabindex="-1"
              ></app-chart-input>
            </div>

            <div class="row clearfix" tabindex="-1">
              <div class="half-width">
                <h3 class="sr-title sm-h3 rm-m">
                  {{ "KEYWORD.treatment" | translate | titlecase }}  Invitation Decision
                  <button class="btn-small" mat-button></button>
                </h3>
              </div>
              <div class="qtr-width">
                <p class="small">Units</p>
              </div>
              <div class="qtr-width">
                <p class="small">Value</p>
              </div>
            </div>
            <div
              *ngFor="
                let s of invitationStatistics.status
                  | FilterArrayMultipleValue: 'ID':invitationUtil.status.allInvitation
                  | OrderArrayObject: ['name'];
                let i = index
              "
              @labelAnimation
              tabindex="-1"
            >
              <mat-divider *ngIf="s.ID && s.ID != 'All'"></mat-divider>

              <div class="row clearfix" *ngIf="s.ID && s.ID != 'All'">
                <div class="half-width">
                  <p>{{ invitationUtil.getStatusLabel(s.name) }}</p>
                </div>
                <div class="qtr-width">
                  <p class="value">{{ s['Count(*)'] }}</p>
                </div>
                <div class="qtr-width">
                  <p class="value">
                    {{ s['Sum(treatment.Value)'] | customCurrency }}

                    <mat-icon
                      class="pull-right pos"
                      *ngIf="
                        arrowDirection(
                          invitationStatistics.status
                            | FilterArrayMultipleValue: 'ID':invitationUtil.status.allInvitation
                            | OrderArrayObject: ['name'],
                          i,
                          'Sum(treatment.Value)'
                        ) == true
                      "
                    >arrow_drop_up
                    </mat-icon>
                    <mat-icon
                      class="pull-right neg"
                      *ngIf="
                        arrowDirection(
                          invitationStatistics.status
                            | FilterArrayMultipleValue: 'ID':invitationUtil.status.allInvitation
                            | OrderArrayObject: ['name'],
                          i,
                          'Sum(treatment.Value)'
                        ) == false
                      "
                    >arrow_drop_down
                    </mat-icon>

                    <button
                      class="btn-small list-button pull-right mat-button"
                      *ngIf="s['Count(*)'] > 0"
                      (click)="displayList(dateFrom, dateTo, merchantID, dentistID, productID, s['ID'], status)"
                      matTooltip="View_InvitationList: {{ s['name'] }}"
                    >
                      <mat-icon>list_alt</mat-icon>
                    </button>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <!-- <mat-divider></mat-divider> -->

              <div class="row clearfix all-container primary-bg" tabindex="-1">
                <div class="half-width">
                  <p><strong>All</strong></p>
                </div>
                <div class="qtr-width">
                  <p class="value">
                    <strong>{{
                      invitationStatistics.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.allInvitation
                        | OrderArrayObject: ['name']
                        | objectPropertyValue: 'Count(*)'
                        | toNumberArray
                        | sum
                      }}</strong>
                  </p>
                </div>
                <div class="qtr-width">
                  <p class="value">
                    <strong>{{
                      invitationStatistics.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.allInvitation
                        | OrderArrayObject: ['name']
                        | objectPropertyValue: 'Sum(treatment.Value)'
                        | toNumberArray
                        | sum
                        | customCurrency
                      }}</strong>
                  </p>
                </div>
              </div>
            </div>

            <div class="greyRow" tabindex="-1">
              <div class="row clearfix">
                <div class="qtr-width">
                  <h3 class="sr-title sm-h3 rm-m">
                    Conversions
                    <button class="btn-small" mat-button></button>
                  </h3>
                </div>
                <div class="qtr-width">
                  <p class="small">Percentage</p>
                </div>
                <div class="qtr-width">
                  <p class="small">Value</p>
                </div>
                <div class="qtr-width">
                  <p class="small">Average</p>
                </div>
              </div>
              <mat-divider></mat-divider>
              <div class="row clearfix">
                <div class="qtr-width">
                  <p>Won</p>
                </div>
                <div class="qtr-width">
                  <p
                    class="value"
                    *ngIf="
                      (invitationStatistics.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.allInvitation
                        | OrderArrayObject: ['name']
                        | objectPropertyValue: 'Count(*)'
                        | toNumberArray
                        | sum) != 0
                    "
                  >
                    {{
                    (invitationStatistics.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.won
                      | objectPropertyValue: 'Count(*)'
                      | toNumberArray
                      | sum) /
                    (invitationStatistics.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.allInvitation
                      | OrderArrayObject: ['name']
                      | objectPropertyValue: 'Count(*)'
                      | toNumberArray
                      | sum) | percent: '2.1-1'
                    }}
                  </p>

                  <p
                    class="value"
                    *ngIf="
                      (invitationStatistics.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.allInvitation
                        | OrderArrayObject: ['name']
                        | objectPropertyValue: 'Count(*)'
                        | toNumberArray
                        | sum) == 0
                    "
                  >
                    0%
                  </p>
                </div>
                <div class="qtr-width">
                  <p class="value">
                    {{
                    invitationStatistics.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.won
                      | objectPropertyValue: 'Sum(treatment.Value)'
                      | toNumberArray
                      | sum
                      | customCurrency
                    }}
                  </p>
                </div>
                <div class="qtr-width">
                  <p class="value">
                    {{
                    invitationStatistics.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.won
                      | objectPropertyValue: 'Avg(treatment.Value)'
                      | toNumberArray
                      | sum
                      | customCurrency
                    }}
                  </p>
                </div>
              </div>
              <mat-divider></mat-divider>
              <div class="row clearfix">
                <div class="qtr-width">
                  <p>
                    Maybe
                  </p>
                </div>
                <div class="qtr-width">
                  <p
                    class="value"
                    *ngIf="
                      (invitationStatistics.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.allInvitation
                        | OrderArrayObject: ['name']
                        | objectPropertyValue: 'Count(*)'
                        | toNumberArray
                        | sum) != 0
                    "
                  >
                    {{
                    (invitationStatistics.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.maybe
                      | objectPropertyValue: 'Count(*)'
                      | toNumberArray
                      | sum) /
                    (invitationStatistics.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.allInvitation
                      | OrderArrayObject: ['name']
                      | objectPropertyValue: 'Count(*)'
                      | toNumberArray
                      | sum) | percent: '2.1-1'
                    }}
                  </p>

                  <p
                    class="value"
                    *ngIf="
                      (invitationStatistics.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.allInvitation
                        | OrderArrayObject: ['name']
                        | objectPropertyValue: 'Count(*)'
                        | toNumberArray
                        | sum) == 0
                    "
                  >
                    0%
                  </p>
                </div>
                <div class="qtr-width">
                  <p class="value">
                    {{
                    invitationStatistics.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.maybe
                      | objectPropertyValue: 'Sum(treatment.Value)'
                      | toNumberArray
                      | sum
                      | customCurrency
                    }}
                  </p>
                </div>
                <div class="qtr-width">
                  <p class="value">
                    {{
                    invitationStatistics.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.maybe
                      | objectPropertyValue: 'Avg(treatment.Value)'
                      | toNumberArray
                      | sum
                      | customCurrency
                    }}
                  </p>
                </div>
              </div>

              <mat-divider></mat-divider>
              <div class="row clearfix">
                <div class="qtr-width">
                  <p>
                    Lost
                  </p>
                </div>
                <div class="qtr-width">
                  <p
                    class="value"
                    *ngIf="
                      (invitationStatistics.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.allInvitation
                        | OrderArrayObject: ['name']
                        | objectPropertyValue: 'Count(*)'
                        | toNumberArray
                        | sum) != 0
                    "
                  >
                    {{
                    (invitationStatistics.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.lost
                      | objectPropertyValue: 'Count(*)'
                      | toNumberArray
                      | sum) /
                    (invitationStatistics.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.allInvitation
                      | OrderArrayObject: ['name']
                      | objectPropertyValue: 'Count(*)'
                      | toNumberArray
                      | sum) | percent: '2.1-1'
                    }}
                  </p>

                  <p
                    class="value"
                    *ngIf="
                      (invitationStatistics.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.allInvitation
                        | OrderArrayObject: ['name']
                        | objectPropertyValue: 'Count(*)'
                        | toNumberArray
                        | sum) == 0
                    "
                  >
                    0%
                  </p>
                </div>
                <div class="qtr-width">
                  <p class="value">
                    {{
                    invitationStatistics.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.lost
                      | objectPropertyValue: 'Sum(treatment.Value)'
                      | toNumberArray
                      | sum
                      | customCurrency
                    }}
                  </p>
                </div>
                <div class="qtr-width">
                  <p class="value">
                    {{
                    invitationStatistics.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.lost
                      | objectPropertyValue: 'Avg(treatment.Value)'
                      | toNumberArray
                      | sum
                      | customCurrency
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- </mat-card> -->

        <!-- <mat-card class="card dataCard" *ngIf="invitationStatistics && invitationStatistics.status " -->
        <!-- [class.mat-elevation-z5]="mouseOvered4" (mouseover)="mouseOvered4=true" (mouseout)="mouseOvered4=false"> -->

        <!-- <div class="colour-stripe"></div> -->

        <div class="half-width">
          <div class="col-right">
            <div class="row clearfix full-width">
              <app-chart-input
                class="row"
                [data]="
                  invitationStatistics.status
                    | FilterArrayMultipleValue: 'ID':invitationUtil.status.contract
                    | OrderArrayObject: ['name']
                "
                [chartType]="'pie'"
                [titleIsTop]="false"
                [title]="''"
                [dataNameAttribute]="'name'"
                [dataValueAttribute]="'Count(*)'"
                tabindex="-1"
              ></app-chart-input>
            </div>

            <div class="row clearfix" tabindex="-1">
              <div class="half-width">
                <h3 class="sr-title sm-h3 rm-m">{{ "KEYWORD.treatment" | translate | titlecase }} Invitation
                  Acceptance</h3>
              </div>
              <div class="qtr-width">
                <p class="small">Units</p>
              </div>
              <div class="qtr-width">
                <p class="small">Value</p>
              </div>
            </div>
            <div
              *ngFor="
                let s of invitationStatistics.status
                  | FilterArrayMultipleValue: 'ID':invitationUtil.status.contract
                  | OrderArrayObject: ['name'];
                let i = index
              "
              @labelAnimation
              tabindex="-1"
            >
              <mat-divider *ngIf="s.ID && s.ID != 'All'"></mat-divider>

              <div class="row clearfix" *ngIf="s.ID && s.ID != 'All'">
                <div class="half-width">
                  <p>{{ invitationUtil.getStatusLabel(s.name) }}</p>
                </div>
                <div class="qtr-width">
                  <p class="value">{{ s['Count(*)'] }}</p>
                </div>
                <div class="qtr-width">
                  <p class="value">
                    {{ s['Sum(treatment.Value)'] | customCurrency }}

                    <mat-icon
                      class="pull-right pos"
                      *ngIf="
                        arrowDirection(
                          invitationStatistics.status
                            | FilterArrayMultipleValue: 'ID':invitationUtil.status.contract
                            | OrderArrayObject: ['name'],
                          i,
                          'Sum(treatment.Value)'
                        ) == true
                      "
                    >arrow_drop_up
                    </mat-icon>
                    <mat-icon
                      class="pull-right neg"
                      *ngIf="
                        arrowDirection(
                          invitationStatistics.status
                            | FilterArrayMultipleValue: 'ID':invitationUtil.status.contract
                            | OrderArrayObject: ['name'],
                          i,
                          'Sum(treatment.Value)'
                        ) == false
                      "
                    >arrow_drop_down
                    </mat-icon>

                    <button
                      class="btn-small list-button pull-right mat-button"
                      *ngIf="s['Count(*)'] > 0"
                      (click)="displayList(dateFrom, dateTo, merchantID, dentistID, productID, s['ID'], status)"
                      matTooltip="View InvitationList: {{ s['name'] }}"
                    >
                      <mat-icon>list_alt</mat-icon>
                    </button>
                  </p>
                </div>
              </div>
            </div>
            <div tabindex="-1">
              <!-- <mat-divider></mat-divider> -->

              <div class="row clearfix all-container primary-bg">
                <div class="half-width">
                  <p><strong>All</strong></p>
                </div>
                <div class="qtr-width">
                  <p class="value">
                    <strong>{{
                      invitationStatistics.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.contract
                        | OrderArrayObject: ['name']
                        | objectPropertyValue: 'Count(*)'
                        | toNumberArray
                        | sum
                      }}</strong>
                  </p>
                </div>
                <div class="qtr-width">
                  <p class="value">
                    <strong>{{
                      invitationStatistics.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.contract
                        | OrderArrayObject: ['name']
                        | objectPropertyValue: 'Sum(treatment.Value)'
                        | toNumberArray
                        | sum
                        | customCurrency
                      }}</strong>
                  </p>
                </div>
              </div>
            </div>

            <div class="greyRow">
              <div class="row clearfix">
                <div class="qtr-width">
                  <h3 class="sr-title sm-h3 rm-m">Conversions</h3>
                </div>
                <div class="qtr-width">
                  <p class="small">Percentage</p>
                </div>
                <div class="qtr-width">
                  <p class="small">Value</p>
                </div>
                <div class="qtr-width">
                  <p class="small">Average</p>
                </div>
              </div>

              <mat-divider></mat-divider>
              <div class="row clearfix">
                <div class="qtr-width">
                  <p>
                    Won
                  </p>
                </div>
                <div class="qtr-width">
                  <p
                    class="value"
                    *ngIf="
                      (invitationStatistics.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.contract
                        | OrderArrayObject: ['name']
                        | objectPropertyValue: 'Count(*)'
                        | toNumberArray
                        | sum) != 0
                    "
                  >
                    {{
                    (invitationStatistics.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.contractWon
                      | objectPropertyValue: 'Count(*)'
                      | toNumberArray
                      | sum) /
                    (invitationStatistics.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.contract
                      | OrderArrayObject: ['name']
                      | objectPropertyValue: 'Count(*)'
                      | toNumberArray
                      | sum) | percent: '2.1-1'
                    }}
                  </p>

                  <p
                    class="value"
                    *ngIf="
                      (invitationStatistics.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.contract
                        | OrderArrayObject: ['name']
                        | objectPropertyValue: 'Count(*)'
                        | toNumberArray
                        | sum) == 0
                    "
                  >
                    0%
                  </p>
                </div>
                <div class="qtr-width">
                  <p class="value">
                    {{
                    invitationStatistics.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.contractWon
                      | objectPropertyValue: 'Sum(treatment.Value)'
                      | toNumberArray
                      | sum
                      | customCurrency
                    }}
                  </p>
                </div>
                <div class="qtr-width">
                  <p class="value">
                    {{
                    invitationStatistics.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.contractWon
                      | objectPropertyValue: 'Avg(treatment.Value)'
                      | toNumberArray
                      | sum
                      | customCurrency
                    }}
                  </p>
                </div>
              </div>

              <mat-divider></mat-divider>

              <div class="row clearfix">
                <div class="qtr-width">
                  <p>
                    Maybe
                  </p>
                </div>
                <div class="qtr-width">
                  <p
                    class="value"
                    *ngIf="
                      (invitationStatistics.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.contract
                        | OrderArrayObject: ['name']
                        | objectPropertyValue: 'Count(*)'
                        | toNumberArray
                        | sum) != 0
                    "
                  >
                    {{
                    (invitationStatistics.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.contractMaybe
                      | objectPropertyValue: 'Count(*)'
                      | toNumberArray
                      | sum) /
                    (invitationStatistics.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.contract
                      | OrderArrayObject: ['name']
                      | objectPropertyValue: 'Count(*)'
                      | toNumberArray
                      | sum) | percent: '2.1-1'
                    }}
                  </p>

                  <p
                    class="value"
                    *ngIf="
                      (invitationStatistics.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.contract
                        | OrderArrayObject: ['name']
                        | objectPropertyValue: 'Count(*)'
                        | toNumberArray
                        | sum) == 0
                    "
                  >
                    0%
                  </p>
                </div>
                <div class="qtr-width">
                  <p class="value">
                    {{
                    invitationStatistics.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.contractMaybe
                      | objectPropertyValue: 'Sum(treatment.Value)'
                      | toNumberArray
                      | sum
                      | customCurrency
                    }}
                  </p>
                </div>
                <div class="qtr-width">
                  <p class="value">
                    {{
                    invitationStatistics.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.contractMaybe
                      | objectPropertyValue: 'Avg(treatment.Value)'
                      | toNumberArray
                      | sum
                      | customCurrency
                    }}
                  </p>
                </div>
              </div>

              <mat-divider></mat-divider>
              <div class="row clearfix">
                <div class="qtr-width">
                  <p>
                    Lost
                  </p>
                </div>
                <div class="qtr-width">
                  <p
                    class="value"
                    *ngIf="
                      (invitationStatistics.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.contract
                        | OrderArrayObject: ['name']
                        | objectPropertyValue: 'Count(*)'
                        | toNumberArray
                        | sum) != 0
                    "
                  >
                    {{
                    (invitationStatistics.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.contractLost
                      | objectPropertyValue: 'Count(*)'
                      | toNumberArray
                      | sum) /
                    (invitationStatistics.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.contract
                      | OrderArrayObject: ['name']
                      | objectPropertyValue: 'Count(*)'
                      | toNumberArray
                      | sum) | percent: '2.1-1'
                    }}
                  </p>

                  <p
                    class="value"
                    *ngIf="
                      (invitationStatistics.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.contract
                        | OrderArrayObject: ['name']
                        | objectPropertyValue: 'Count(*)'
                        | toNumberArray
                        | sum) == 0
                    "
                  >
                    0%
                  </p>
                </div>
                <div class="qtr-width">
                  <p class="value">
                    {{
                    invitationStatistics.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.contractLost
                      | objectPropertyValue: 'Sum(treatment.Value)'
                      | toNumberArray
                      | sum
                      | customCurrency
                    }}
                  </p>
                </div>
                <div class="qtr-width">
                  <p class="value">
                    {{
                    invitationStatistics.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.contractLost
                      | objectPropertyValue: 'Avg(treatment.Value)'
                      | toNumberArray
                      | sum
                      | customCurrency
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
  <hr/>
  <div class="row clearfix" *ngIf="displayInvitationList == false" tabindex="-1">
    <h2 class="lg-h2 rm-m sr-title">Communication Delivery</h2>
    <label class="subtitle">Emails &amp; SMS communication statistics from all time</label>
  </div>

  <div class="row clearfix full-width" *ngIf="displayInvitationList == false" tabindex="-1">
    <div class="subStatcontainer" *ngIf="invitationOverViewStatistics && invitationOverViewStatistics.OnceSent">
      <div class="row clearfix">
        <div class="thrd-width">
          <app-chart-input
            class="row"
            [data]="invitationOverViewStatistics.OnceSent | WithoutArrayMultipleValue: 'ID':['All']"
            [chartType]="'pie'"
            [titleIsTop]="false"
            [title]="'Emails & SMS'"
            [dataNameAttribute]="'name'"
            [dataValueAttribute]="'count'"
            [showLegend]="false"
          ></app-chart-input>
        </div>
        <div class="two-thrd-width">
          <div class="row clearfix">
            <div class="half-width">
              <h3 class="sr-title sm-h3 rm-m">
                Email &amp; SMS Interactions
                <!-- <button mat-button class="btn-small">

                    </button> -->
              </h3>
            </div>
            <div class="qtr-width">
              <p class="small">Units</p>
            </div>
            <div class="qtr-width">
              <p class="small">Value</p>
            </div>
          </div>

          <div
            *ngFor="
              let s of invitationOverViewStatistics.OnceSent | WithoutArrayMultipleValue: 'ID':['All'];
              let i = index
            "
            @labelAnimation
          >
            <mat-divider *ngIf="s.ID && s.ID != 'All'"></mat-divider>

            <div class="row clearfix" *ngIf="s.ID && s.ID != 'All'">
              <div class="half-width">
                <p>{{ s.name }}</p>
              </div>
              <div class="qtr-width">
                <p class="value">{{ s.count }}</p>
              </div>
              <div class="qtr-width">
                <p class="value">
                  {{ s.Sum | customCurrency }}

                  <mat-icon
                    class="pull-right pos"
                    *ngIf="
                      arrowDirection(
                        invitationOverViewStatistics.OnceSent | WithoutArrayMultipleValue: 'ID':['All'],
                        i,
                        'Sum'
                      ) == true
                    "
                  >arrow_drop_up
                  </mat-icon>
                  <mat-icon
                    class="pull-right neg"
                    *ngIf="
                      arrowDirection(
                        invitationOverViewStatistics.OnceSent | WithoutArrayMultipleValue: 'ID':['All'],
                        i,
                        'Sum'
                      ) == false
                    "
                  >arrow_drop_down
                  </mat-icon>
                </p>
              </div>
            </div>
          </div>

          <div>
            <div class="row clearfix all-container primary-bg">
              <div class="half-width">
                <p><strong>All</strong></p>
              </div>
              <div class="qtr-width">
                <p class="value">
                  <strong>
                    {{
                    invitationOverViewStatistics.OnceSent
                      | WithoutArrayMultipleValue: 'ID':['All']
                      | objectPropertyValue: 'count'
                      | toNumberArray
                      | sum
                    }}</strong
                  >
                </p>
              </div>
              <div class="qtr-width">
                <p class="value">
                  <strong>{{
                    invitationOverViewStatistics.OnceSent
                      | WithoutArrayMultipleValue: 'ID':['All']
                      | objectPropertyValue: 'Sum'
                      | toNumberArray
                      | sum
                      | customCurrency
                    }}</strong>
                </p>
              </div>
            </div>
          </div>

          <div class="greyRow">
            <div class="row clearfix">
              <div class="half-width">
                <h3 class="sr-title sm-h3 rm-m">
                  Conversions
                  <button class="btn-small" mat-button></button>
                </h3>
              </div>
              <div class="qtr-width">
                <p class="small">Percentage</p>
              </div>
              <div class="qtr-width">
                <p class="small">Average</p>
              </div>
            </div>
            <mat-divider></mat-divider>
            <div class="row clearfix">
              <div class="half-width">
                <p>Viewed</p>
              </div>
              <div class="qtr-width">
                <p
                  class="value"
                  *ngIf="
                    (invitationOverViewStatistics.OnceSent
                      | WithoutArrayMultipleValue: 'ID':['All']
                      | objectPropertyValue: 'count'
                      | toNumberArray
                      | sum) != 0
                  "
                >
                  {{
                  (invitationOverViewStatistics.OnceSent
                    | FilterArrayMultipleValue: 'ID':['Read']
                    | objectPropertyValue: 'count'
                    | toNumberArray
                    | sum) /
                  (invitationOverViewStatistics.OnceSent
                    | WithoutArrayMultipleValue: 'ID':['All']
                    | objectPropertyValue: 'count'
                    | toNumberArray
                    | sum) | percent: '2.1-1'
                  }}
                </p>

                <p
                  class="value"
                  *ngIf="
                    (invitationOverViewStatistics.OnceSent
                      | WithoutArrayMultipleValue: 'ID':['All']
                      | objectPropertyValue: 'count'
                      | toNumberArray
                      | sum) == 0
                  "
                >
                  0%
                </p>
              </div>
              <div class="qtr-width">
                <p class="value">
                  {{
                  invitationOverViewStatistics.OnceSent
                    | FilterArrayMultipleValue: 'ID':['Read']
                    | objectPropertyValue: 'Avg'
                    | sum
                    | customCurrency
                  }}
                </p>
              </div>
            </div>

            <mat-divider></mat-divider>
            <div class="row clearfix">
              <div class="half-width">
                <p>Unread</p>
              </div>
              <div class="qtr-width">
                <p
                  class="value"
                  *ngIf="
                    (invitationOverViewStatistics.OnceSent
                      | WithoutArrayMultipleValue: 'ID':['All']
                      | objectPropertyValue: 'count'
                      | toNumberArray
                      | sum) != 0
                  "
                >
                  {{
                  (invitationOverViewStatistics.OnceSent
                    | FilterArrayMultipleValue: 'ID':['Waiting']
                    | objectPropertyValue: 'count'
                    | toNumberArray
                    | sum) /
                  (invitationOverViewStatistics.OnceSent
                    | WithoutArrayMultipleValue: 'ID':['All']
                    | objectPropertyValue: 'count'
                    | toNumberArray
                    | sum) | percent: '2.1-1'
                  }}
                </p>

                <p
                  class="value"
                  *ngIf="
                    (invitationOverViewStatistics.OnceSent
                      | WithoutArrayMultipleValue: 'ID':['All']
                      | objectPropertyValue: 'count'
                      | toNumberArray
                      | sum) == 0
                  "
                >
                  0%
                </p>
              </div>

              <div class="qtr-width">
                <p class="value">
                  {{
                  invitationOverViewStatistics.OnceSent
                    | FilterArrayMultipleValue: 'ID':['Waiting']
                    | objectPropertyValue: 'Avg'
                    | sum
                    | customCurrency
                  }}
                </p>
              </div>
            </div>

            <mat-divider></mat-divider>
            <div class="row clearfix">
              <div class="half-width">
                <p>Lost</p>
              </div>
              <div class="qtr-width">
                <p
                  class="value"
                  *ngIf="
                    (invitationOverViewStatistics.OnceSent
                      | WithoutArrayMultipleValue: 'ID':['All']
                      | objectPropertyValue: 'count'
                      | toNumberArray
                      | sum) != 0
                  "
                >
                  {{
                  (invitationOverViewStatistics.OnceSent
                    | FilterArrayMultipleValue: 'ID':['Lost']
                    | objectPropertyValue: 'count'
                    | toNumberArray
                    | sum) /
                  (invitationOverViewStatistics.OnceSent
                    | WithoutArrayMultipleValue: 'ID':['All']
                    | objectPropertyValue: 'count'
                    | toNumberArray
                    | sum) | percent: '2.1-1'
                  }}
                </p>

                <p
                  class="value"
                  *ngIf="
                    (invitationOverViewStatistics.OnceSent
                      | WithoutArrayMultipleValue: 'ID':['All']
                      | objectPropertyValue: 'count'
                      | toNumberArray
                      | sum) == 0
                  "
                >
                  0%
                </p>
              </div>

              <div class="qtr-width">
                <p class="value">
                  {{
                  invitationOverViewStatistics.OnceSent
                    | FilterArrayMultipleValue: 'ID':['Lost']
                    | objectPropertyValue: 'Avg'
                    | sum
                    | customCurrency
                  }}
                </p>
              </div>
            </div>
          </div>

          <div class="row clearfix">
            <div class="qtr-width">
              <h3 class="sr-title sm-h3 rm-m">
                Time
                <button class="btn-small" mat-button></button>
              </h3>
            </div>
            <div class="qtr-width">
              <p class="small">Unit</p>
            </div>
            <div class="qtr-width">
              <p class="small">Total</p>
            </div>
            <div class="qtr-width">
              <p class="small">Average</p>
            </div>
          </div>

          <div
            *ngFor="
              let s of invitationOverViewStatistics.OnceSent | FilterArrayMultipleValue: 'hasTime':[true];
              let i = index
            "
            @labelAnimation
          >
            <mat-divider *ngIf="s.hasTime && s.hasTime == true"></mat-divider>
            <div class="row clearfix">
              <div class="qtr-width">
                <p>{{ s.name }}</p>
              </div>
              <div class="qtr-width">
                <p class="value">{{ s.count | toNumberArray | sum }}</p>
              </div>

              <div class="qtr-width">
                <p class="value">{{ s.TotalSeconds | toNumberArray | sum | TimeLabelPast }}</p>
              </div>
              <div class="qtr-width">
                <p class="value">
                  {{ s.AvgTotalSeconds | toNumberArray | sum | TimeLabelPast }}

                  <mat-icon
                    class="pull-right pos"
                    *ngIf="
                      arrowDirection(
                        invitationOverViewStatistics.OnceSent | FilterArrayMultipleValue: 'ID':['Read'],
                        i,
                        'AvgTotalSeconds'
                      ) == true
                    "
                  >arrow_drop_up
                  </mat-icon>
                  <mat-icon
                    class="pull-right neg"
                    *ngIf="
                      arrowDirection(
                        invitationOverViewStatistics.OnceSent | FilterArrayMultipleValue: 'ID':['Read'],
                        i,
                        'AvgTotalSeconds'
                      ) == false
                    "
                  >arrow_drop_down
                  </mat-icon>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--
      <mat-card class="card dataCard" *ngIf="invitationOverViewStatistics  && invitationOverViewStatistics.OnceSent "
      [class.mat-elevation-z5]="mouseOvered1" (mouseover)="mouseOvered1=true" (mouseout)="mouseOvered1=false">




    </mat-card> -->

    <div class="subStatcontainer" *ngIf="invitationOverViewStatistics && invitationOverViewStatistics.OnceRead">
      <div class="row clearfix">
        <div class="thrd-width">
          <app-chart-input
            class="row"
            [data]="invitationOverViewStatistics.OnceRead | WithoutArrayMultipleValue: 'ID':['All']"
            [chartType]="'pie'"
            [titleIsTop]="false"
            [title]="('KEYWORD.patient' | translate | titlecase) + ' Card Interactions'"
            [showLegend]="false"
            [dataNameAttribute]="'name'"
            [dataValueAttribute]="'count'"
          ></app-chart-input>
        </div>

        <div class="two-thrd-width">
          <div class="row clearfix">
            <div class="half-width">
              <h3 class="sr-title sm-h3 rm-m">{{"KEYWORD.patient" | translate | titlecase}} Card Interactions</h3>
            </div>
            <div class="qtr-width">
              <p class="small">Units</p>
            </div>
            <div class="qtr-width">
              <p class="small">Value</p>
            </div>
          </div>

          <div
            *ngFor="
              let s of invitationOverViewStatistics.OnceRead | WithoutArrayMultipleValue: 'ID':['All'];
              let i = index
            "
            @labelAnimation
          >
            <mat-divider *ngIf="s.ID && s.ID != 'All'"></mat-divider>

            <div class="row clearfix" *ngIf="s.ID && s.ID != 'All'">
              <div class="half-width">
                <p>{{ s.name }}</p>
              </div>
              <div class="qtr-width">
                <p class="value">{{ s.count }}</p>
              </div>
              <div class="qtr-width">
                <p class="value">
                  {{ s.Sum | customCurrency }}

                  <mat-icon
                    class="pull-right pos"
                    *ngIf="
                      arrowDirection(
                        invitationOverViewStatistics.OnceRead | WithoutArrayMultipleValue: 'ID':['All'],
                        i,
                        'Sum'
                      ) == true
                    "
                  >arrow_drop_up
                  </mat-icon>
                  <mat-icon
                    class="pull-right neg"
                    *ngIf="
                      arrowDirection(
                        invitationOverViewStatistics.OnceRead | WithoutArrayMultipleValue: 'ID':['All'],
                        i,
                        'Sum'
                      ) == false
                    "
                  >arrow_drop_down
                  </mat-icon>
                </p>
              </div>
            </div>
          </div>

          <div>
            <div class="row clearfix all-container primary-bg">
              <div class="half-width">
                <p><strong>All</strong></p>
              </div>
              <div class="qtr-width">
                <p class="value">
                  <strong>{{
                    invitationOverViewStatistics.OnceRead
                      | WithoutArrayMultipleValue: 'ID':['All']
                      | objectPropertyValue: 'count'
                      | toNumberArray
                      | sum
                    }}</strong>
                </p>
              </div>
              <div class="qtr-width">
                <p class="value">
                  <strong>{{
                    invitationOverViewStatistics.OnceRead
                      | WithoutArrayMultipleValue: 'ID':['All']
                      | objectPropertyValue: 'Sum'
                      | toNumberArray
                      | sum
                      | customCurrency
                    }}</strong>
                </p>
              </div>
            </div>
          </div>
          <div class="greyRow">
            <div class="row clearfix">
              <div class="half-width">
                <h3 class="sr-title sm-h3 rm-m">Conversions</h3>
              </div>
              <div class="qtr-width">
                <p class="small">Percentage</p>
              </div>
              <div class="qtr-width">
                <p class="small">Average</p>
              </div>
            </div>
            <mat-divider></mat-divider>
            <div class="row clearfix">
              <div class="half-width">
                <p>Viewed</p>
              </div>
              <div class="qtr-width">
                <p
                  class="value"
                  *ngIf="
                    (invitationOverViewStatistics.OnceRead
                      | WithoutArrayMultipleValue: 'ID':['All']
                      | objectPropertyValue: 'count'
                      | toNumberArray
                      | sum) != 0
                  "
                >
                  {{
                  (invitationOverViewStatistics.OnceRead
                    | FilterArrayMultipleValue: 'ID':['Responded', 'Clicked']
                    | objectPropertyValue: 'count'
                    | toNumberArray
                    | sum) /
                  (invitationOverViewStatistics.OnceRead
                    | WithoutArrayMultipleValue: 'ID':['All']
                    | objectPropertyValue: 'count'
                    | toNumberArray
                    | sum) | percent: '2.1-1'
                  }}
                </p>

                <p
                  class="value"
                  *ngIf="
                    (invitationOverViewStatistics.OnceRead
                      | WithoutArrayMultipleValue: 'ID':['All']
                      | objectPropertyValue: 'count'
                      | toNumberArray
                      | sum) == 0
                  "
                >
                  0%
                </p>
              </div>
              <div class="qtr-width">
                <p class="value">
                  {{
                  invitationOverViewStatistics.OnceRead
                    | FilterArrayMultipleValue: 'ID':['Responded', 'Clicked']
                    | objectPropertyValue: 'Avg'
                    | sum
                    | customCurrency
                  }}
                </p>
              </div>
            </div>

            <mat-divider></mat-divider>
            <div class="row clearfix">
              <div class="half-width">
                <p>Unread</p>
              </div>
              <div class="qtr-width">
                <p
                  class="value"
                  *ngIf="
                    (invitationOverViewStatistics.OnceRead
                      | WithoutArrayMultipleValue: 'ID':['All']
                      | objectPropertyValue: 'count'
                      | toNumberArray
                      | sum) != 0
                  "
                >
                  {{
                  (invitationOverViewStatistics.OnceRead
                    | FilterArrayMultipleValue: 'ID':['Waiting']
                    | objectPropertyValue: 'count'
                    | toNumberArray
                    | sum) /
                  (invitationOverViewStatistics.OnceRead
                    | WithoutArrayMultipleValue: 'ID':['All']
                    | objectPropertyValue: 'count'
                    | toNumberArray
                    | sum) | percent: '2.1-1'
                  }}
                </p>

                <p
                  class="value"
                  *ngIf="
                    (invitationOverViewStatistics.OnceRead
                      | WithoutArrayMultipleValue: 'ID':['All']
                      | objectPropertyValue: 'count'
                      | toNumberArray
                      | sum) == 0
                  "
                >
                  0%
                </p>
              </div>
              <div class="qtr-width">
                <p class="value">
                  {{
                  invitationOverViewStatistics.OnceRead
                    | FilterArrayMultipleValue: 'ID':['Waiting']
                    | objectPropertyValue: 'Avg'
                    | sum
                    | customCurrency
                  }}
                </p>
              </div>
            </div>

            <mat-divider></mat-divider>
            <div class="row clearfix">
              <div class="half-width">
                <p>Lost</p>
              </div>
              <div class="qtr-width">
                <p
                  class="value"
                  *ngIf="
                    (invitationOverViewStatistics.OnceRead
                      | WithoutArrayMultipleValue: 'ID':['All']
                      | objectPropertyValue: 'count'
                      | toNumberArray
                      | sum) != 0
                  "
                >
                  {{
                  (invitationOverViewStatistics.OnceRead
                    | FilterArrayMultipleValue: 'ID':['Lost']
                    | objectPropertyValue: 'count'
                    | toNumberArray
                    | sum) /
                  (invitationOverViewStatistics.OnceRead
                    | WithoutArrayMultipleValue: 'ID':['All']
                    | objectPropertyValue: 'count'
                    | toNumberArray
                    | sum) | percent: '2.1-1'
                  }}
                </p>

                <p
                  class="value"
                  *ngIf="
                    (invitationOverViewStatistics.OnceRead
                      | WithoutArrayMultipleValue: 'ID':['All']
                      | objectPropertyValue: 'count'
                      | toNumberArray
                      | sum) == 0
                  "
                >
                  0%
                </p>
              </div>
              <div class="qtr-width">
                <p class="value">
                  {{
                  invitationOverViewStatistics.OnceRead
                    | FilterArrayMultipleValue: 'ID':['Lost']
                    | objectPropertyValue: 'Avg'
                    | sum
                    | customCurrency
                  }}
                </p>
              </div>
            </div>
          </div>

          <div class="row clearfix">
            <div class="half-width">
              <h3 class="sr-title sm-h3 rm-m">Time</h3>
            </div>
            <div class="qtr-width">
              <p class="small">Time (total)</p>
            </div>
            <div class="qtr-width">
              <p class="small">Average</p>
            </div>
          </div>

          <div
            *ngFor="
              let s of invitationOverViewStatistics.OnceRead | FilterArrayMultipleValue: 'hasTime':[true];
              let i = index
            "
            @labelAnimation
          >
            <mat-divider *ngIf="s.hasTime && s.hasTime == true"></mat-divider>
            <div class="row clearfix">
              <div class="qtr-width">
                <p>{{ s.name }}</p>
              </div>
              <div class="qtr-width">
                <p class="value">{{ s.count | toNumberArray | sum }}</p>
              </div>

              <div class="qtr-width">
                <p class="value">{{ s.TotalSeconds | toNumberArray | sum | TimeLabelPast }}</p>
              </div>
              <div class="qtr-width">
                <p class="value">
                  {{ s.AvgTotalSeconds | toNumberArray | sum | TimeLabelPast }}

                  <mat-icon
                    class="pull-right pos"
                    *ngIf="
                      arrowDirection(
                        invitationOverViewStatistics.OnceRead | FilterArrayMultipleValue: 'ID':['Responded', 'Clicked'],
                        i,
                        'AvgTotalSeconds'
                      ) == true
                    "
                  >arrow_drop_up
                  </mat-icon>
                  <mat-icon
                    class="pull-right neg"
                    *ngIf="
                      arrowDirection(
                        invitationOverViewStatistics.OnceRead | FilterArrayMultipleValue: 'ID':['Responded', 'Clicked'],
                        i,
                        'AvgTotalSeconds'
                      ) == false
                    "
                  >arrow_drop_down
                  </mat-icon>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row clearfix full-width" *ngIf="displayInvitationList == true" tabindex="-1">
    <app-invitation-list-global
      *ngIf="merchantID != 'none' && isPromoterOrAdmin == true"
      [dateFrom]="dateFromInvitation"
      [dateTo]="dateToInvitation"
      [merchantID]="merchantIDInvitation"
      [productID]="productIDInvitation"
      [staffID]="dentistIDInvitation"
      [status]="statusInvitation"
    ></app-invitation-list-global>
    <app-invitation-list
      *ngIf="isPromoterOrAdmin == false"
      [dateFrom]="dateFromInvitation"
      [dateTo]="dateToInvitation"
      [productID]="productIDInvitation"
      [staffID]="dentistIDInvitation"
      [status]="statusInvitation"
    >
    </app-invitation-list>
  </div>

  <div class="row full-width clearfix text-center">
    <button class="" *ngIf="displayInvitationList == true" (click)="goBack()" mat-raised-button color="primary">
      &larr; Overview
    </button>
    <button (click)="performance()" mat-raised-button color="primary" tabindex="0">
      <mat-icon>insert_chart</mat-icon>
      Detailed Performance
    </button>
  </div>
</div>
