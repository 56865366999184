<div class="row clearfix" *ngIf="customInv">
  <div class="card-header primary-gradient-img clearfix inModal rel">
    <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModal()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="row titleArea clearfix text-left">
      <app-ep-document-view-image
        class="profile-lead-image sml"
        [defaultPicture]="
          'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
        "
        [link]="profileLink"
      >
      </app-ep-document-view-image>
      <h2 class="summary-header rm-m">
        {{ customInv.FirstName }} {{ customInv.LastName }}
        <span class="subLabel">Review your {{"KEYWORD.treatment" | translate}}</span>
      </h2>
    </div>
  </div>
  <mat-dialog-content>
    <div class="row clearfix flex full-width mt">
      <mat-card class="full-width payment-cta totalValues animate">
        <div class="row clearfix flex">
          <div class="full-width text-left clearfix validUntil">
            <p class="small rm-m">This proposal is valid until</p>
            <h2 class="rm-m">
              {{ customInv['Date.Expiry'] | customDate }}
            </h2>
          </div>
          <div class="full-width text-left clearfix">
            <div class="text-right pull-right clearfix">
              <p class="small rm-m">Total {{ "KEYWORD.treatment" | translate }} value:</p>
              <h2 class="rm-m">
                {{ customInv['treatment.Value'] | customCurrency }}
              </h2>
            </div>
          </div>
        </div>
      </mat-card>

      <mat-card class="full-width payment-cta rel botton animate" *ngIf="minAmount">
        <div class="row clearfix">
          <h3 class="rm-m">Smooth out your {{ "KEYWORD.treatment" | translate }} cost</h3>
          <hr/>
          <h3 class="rm-m">
            Payment options from <span class="valuePerDay">{{ minAmount | customCurrency }}/day!</span>
          </h3>
        </div>
      </mat-card>
    </div>

    <app-treatment-document
      class="clearfix"
      [canView]="true"
      [invitationID]="invitationID"
      [isFilter]="false"
      [view]="'consumer'"
    >
    </app-treatment-document>
  </mat-dialog-content>
</div>
