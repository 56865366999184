import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { BeneficiaryViewComponent } from '../../beneficiary/beneficiary-view/beneficiary-view.component';
import { TreatmentService } from '../shared/treatment.service';
import { treatmentUtil } from '../shared/treatmentUtil';
import { TreatmentCategoryViewComponent } from '../treatment-category-view/treatment-category-view.component';

@Component({
  selector: 'app-type-view',
  templateUrl: './treatment-type-view.component.html',
  styleUrls: ['./treatment-type-view.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.stacked-card-view', style({ opacity: 0 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.stacked-card-view', style({ opacity: 1 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class TreatmentTypeViewComponent implements OnInit {
  @Input()
  typeID;

  @Output()
  close = new EventEmitter();

  isModal = false;

  treatment;
  isActive = false;

  useDefault = false;

  treatmentUtil = new treatmentUtil();

  isPromoterOrAdmin = false;

  diagnosticNote;
  treatmentNote;

  constructor(
    private authenticationServer: AuthenticationService,
    private TreatmentService: TreatmentService,
    private activeRoute: ActivatedRoute,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.typeID) {
        this.typeID = data.typeID;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationServer.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      this.activeRoute.params.subscribe((params) => {
        if (params['typeID']) {
          this.typeID = params['typeID'];
        }

        this.setup();
      });
    });
  }

  viewCategory(id) {
    const ref = RootAppComponent.dialog.open(TreatmentCategoryViewComponent, {
      data: {
        categoryID: id,
      },

      width: '700px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewSupplier(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(BeneficiaryViewComponent, {
        data: id,
        width: '600px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  setup() {
    if (this.typeID) {
      this.TreatmentService.getTreatmentTypeDetails(this.typeID).subscribe((res) => {
        if (res) {
          this.treatment = res;
        }
      });
    }
  }

  closeModal() {
    this.close.emit(true);
  }

  toNumber(value) {
    return Number(value);
  }
}
