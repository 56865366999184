import { NgModule } from '@angular/core';
import { DentistryTypesModule } from '../../feature/dentistry-types/dentistry-types.module';
import { MerchantHealthFundsModule } from '../../feature/merchant-health-funds/merchant-health-funds.module';
import { MerchantServicesModule } from '../../feature/merchant-services/merchant-services.module';
import { OperatorModule } from '../../feature/operator/operator.module';
import { TipModule } from '../../feature/tip/tip.module';
import { SharedModule } from '../../shared/shared.module';
import { AuthenticationModule } from '../authentication/authentication.module';
import { EconnectModule } from '../econnect/econnect.module';
import { HelperModule } from '../helper/helper.module';
import { LanguageModule } from '../language/language.module';
import { LogModule } from '../log/log.module';
import { SessionModule } from '../session/session.module';
import { SettingsModule } from '../settings/settings.module';
import { ThemeModule } from '../theme/theme.module';
import { AdminCommunicationSettingsComponent } from './features/admin-communication-settings/admin-communication-settings.component';
import { AdminDocumentsCenterComponent } from './features/admin-documents-center/admin-documents-center.component';
import { AdminFooterComponent } from './features/admin-footer/admin-footer.component';
import { AdminGeneralSettingsComponent } from './features/admin-general-settings/admin-general-settings.component';
import { AdminHeaderComponent } from './features/admin-header/admin-header.component';
import { AdminHelpDocumentsCenterComponent } from './features/admin-help-documents-center/admin-help-documents-center.component';
import { AdminLanguagesComponent } from './features/admin-languages/admin-languages.component';
import { AdminLeftMenuComponent } from './features/admin-left-menu/admin-left-menu.component';
import { AdminLogsComponent } from './features/admin-logs/admin-logs.component';
import { AdminSystemSettingsComponent } from './features/admin-system-settings/admin-system-settings.component';
import { AdminThemeComponent } from './features/admin-theme/admin-theme.component';
import { AdminTipComponent } from './features/admin-tip/admin-tip.component';
import { AdminUsersComponent } from './features/admin-users/admin-users.component';
import { AdminMainComponent } from './pages/admin-main/admin-main.component';
import { AuthAdminGuard } from './shared/admin-guard.service';
import { AdminRoutingModule } from './shared/admin-routing.module';

@NgModule({
  declarations: [
    AdminLeftMenuComponent,
    AdminHeaderComponent,
    AdminGeneralSettingsComponent,
    AdminUsersComponent,
    AdminLogsComponent,
    AdminDocumentsCenterComponent,
    AdminCommunicationSettingsComponent,
    AdminSystemSettingsComponent,
    AdminFooterComponent,
    AdminMainComponent,
    AdminTipComponent,
    AdminLanguagesComponent,
    AdminThemeComponent,
    AdminHelpDocumentsCenterComponent,
  ],
  imports: [
    SharedModule,
    HelperModule,
    OperatorModule,
    TipModule,
    MerchantServicesModule,
    DentistryTypesModule,
    MerchantHealthFundsModule,
    LogModule,
    AuthenticationModule,
    SettingsModule,
    SessionModule,
    LanguageModule,
    ThemeModule,
    EconnectModule,
    AdminRoutingModule,
  ],
  exports: [
    AdminLeftMenuComponent,
    AdminHeaderComponent,
    AdminGeneralSettingsComponent,
    AdminUsersComponent,
    AdminLogsComponent,
    AdminDocumentsCenterComponent,
    AdminCommunicationSettingsComponent,
    AdminSystemSettingsComponent,
    AdminFooterComponent,
    AdminMainComponent,
    AdminTipComponent,
    AdminLanguagesComponent,
    AdminThemeComponent,
    AdminHelpDocumentsCenterComponent,
  ],
  providers: [AuthAdminGuard],
})
export class AdminModule {}
