import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { MarketingService } from '../shared/marketing.service';

@Component({
  selector: 'app-marketing-campaign-review',
  templateUrl: './marketing-campaign-review.component.html',
  styleUrls: ['./marketing-campaign-review.component.css'],
})
export class MarketingCampaignReviewComponent implements OnInit {
  @Input()
  campaignID;

  dentists = [
    { dentistID: 'd1', dentistName: 'Hamza' },
    { dentistID: 'd2', dentistName: 'Toothless' },
    { dentistID: 'd3', dentistName: 'Tooth Fairy' },
  ];

  selectDentist = 'Tooth Fairy';

  templates = [
    { templateID: 'template1', templateName: 'X-mas Special Holiday' },
    { templateID: 'template2', templateName: 'Halloween Special Event' },
    { templateID: 'template3', templateName: 'General Marketing Template' },
  ];

  selectTemplate = 'Halloween Special Event';

  myDescription =
    'Ut soleat gloriatur mei, cu decore civibus aliquando eam. Nam ex impedit eleifend delicatissimi, equidem quaerendum cu qui. In tempor vocent repudiandae pri. Ei mei corpora fabellas oportere, aliquid adversarium ei mea, ei sit agam idque legendos. Cum ad postulant constituto. ';

  subjectLine = 'Lorem ipsum dolor sit amet';
  subjectDescription =
    'Ut soleat gloriatur mei, cu decore civibus aliquando eam. Nam ex impedit eleifend delicatissimi, equidem quaerendum cu qui. In tempor vocent repudiandae pri. Ei mei corpora fabellas oportere, aliquid adversarium ei mea, ei sit agam idque legendos. Cum ad postulant constituto. Ut sed odio aliquid pertinax, id enim meis graeci vix, populo graeci timeam sea ne. Eam sapientem disputando no. Pro et habeo postulant. Eu qui prima paulo vulputate. Cum doming reprimique id, pro id nobis expetenda. Rebum mundi ponderum ex vel, aliquip oblique quaeque in usu, ne vis solet munere. Vix copiosae conclusionemque no, alii apeirian his te. No sea dicam voluptua molestiae, est te integre veritus voluptatum, tantas adipisci per cu. Ad his quodsi referrentur, quo cu labore vocent phaedrum. Eripuit commune posidonium an mei. Debet aliquid ad vel, pro elit mediocritatem no, te sea timeam inimicus reformidans.';

  templateMetaInfo = 'Marketing Template 1';

  recipients = [
    { id: 1, surname: 'doe', firstName: 'john', mobile: '0401234567', consent: 'yes', prevCampaigns: 2, select: '[X]' },
    { id: 2, surname: 'dont', firstName: 'jane', mobile: '0401234567', consent: 'no', prevCampaigns: 0, select: '[X]' },
    { id: 3, surname: 'doe', firstName: 'john', mobile: '0401234567', consent: 'yes', prevCampaigns: 2, select: '[X]' },
    { id: 4, surname: 'doe', firstName: 'john', mobile: '0401234567', consent: 'yes', prevCampaigns: 2, select: '[X]' },
    { id: 5, surname: 'dont', firstName: 'jane', mobile: '0401234567', consent: 'no', prevCampaigns: 0, select: '[X]' },
    { id: 6, surname: 'doe', firstName: 'john', mobile: '0401234567', consent: 'yes', prevCampaigns: 2, select: '[X]' },
    { id: 7, surname: 'doe', firstName: 'john', mobile: '0401234567', consent: 'yes', prevCampaigns: 2, select: '[X]' },
    { id: 8, surname: 'doe', firstName: 'john', mobile: '0401234567', consent: 'yes', prevCampaigns: 2, select: '[X]' },
    { id: 9, surname: 'dont', firstName: 'jane', mobile: '0401234567', consent: 'no', prevCampaigns: 0, select: '[X]' },
    {
      id: 10,
      surname: 'doe',
      firstName: 'john',
      mobile: '0401234567',
      consent: 'yes',
      prevCampaigns: 2,
      select: '[X]',
    },
  ];

  sendDate = new Date();
  sendTime = new Date();
  scheduledTime = '01/02/2018: 10:00';

  numRecipients = 10;

  totalEmails = 231;
  totalSMSs = 200;
  totalRecipients: number = this.totalEmails + this.totalSMSs;
  SessionType = 'merchant';
  simpleData: any = [
    {
      name: 'Emails',
      value: this.totalEmails,
    },
    {
      name: 'SMS',
      value: this.totalSMSs,
    },
  ];
  campaign;

  constructor(private authenticationService: AuthenticationService, private marketingService: MarketingService) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((SessionType) => {
      this.SessionType = SessionType;

      this.marketingService.getMarketingDetails(this.campaignID, {}, this.SessionType).subscribe((res) => {
        this.campaign = res;
        this.selectTemplate = res['Label'];
        this.myDescription = res['Description'];
        this.scheduledTime = res['WhenToSend'];
      });
    });
  }
}
