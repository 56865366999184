<div class="rel">
  <div class="card-header primary-gradient-img clearfix inModal" *ngIf="isModal == true">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row clearfix">
        <mat-icon class="pull-left mr">lock</mat-icon>
        <h3 class="summary-header rm-m">Two-factor authentication</h3>

        <h2 class="summary-header rm-m text-center" *ngIf="name">
          For <strong>{{ name }}</strong>
        </h2>
      </div>
    </div>
  </div>

  <mat-dialog-content class="text-center">
    <div class="row clearfix mb text-center">
      <img
        class="auth-img"
        src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/lock.gif"
      />
    </div>

    <div class="row clearfix mb" *ngIf="!description">
      <p class="bold rm-m text-center sr-title" *ngIf="isModal == false && name">Hi {{ name }}</p>

      <p>
        When you're ready to continue, click the button below and a secure code will be sent to your mobile ending in
        <span class="bold" *ngIf="mobileNumber"> : {{ cutIt(mobileNumber) }} </span>.
      </p>
      <p>Once you have the code, you'll be asked to enter it on the next screen.</p>
    </div>

    <div class="row clearfix mb" *ngIf="description" [innerHTML]="description | safeHtml"></div>
    <!-- <hr> -->
  </mat-dialog-content>
  <div class="button-row mt text-center">
    <button class="btn-large clearfix mb" (click)="onYes()" mat-raised-button color="accent">
      Send code!
      <mat-icon>sms</mat-icon>
    </button>
  </div>
</div>
