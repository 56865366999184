import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'cleanString' })
export class CleanStringPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(s) {
    if (s) {
      return String(s).replace(/�/g, "'");
    }
    return '';
  }
}
