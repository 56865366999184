/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../payment-view/payment-view.component.ngfactory";
import * as i3 from "../payment-view/payment-view.component";
import * as i4 from "../shared/bank-account.service";
import * as i5 from "@angular/router";
import * as i6 from "../../../core/authentication/shared/authentication.service";
import * as i7 from "@angular/material/dialog";
import * as i8 from "@angular/common";
import * as i9 from "../../../shared/components/empty-list-message/empty-list-message.component.ngfactory";
import * as i10 from "../../../shared/components/empty-list-message/empty-list-message.component";
import * as i11 from "../../../shared/pipes/order-array-object.pipe";
import * as i12 from "./payment-list.component";
var styles_PaymentListComponent = [i0.styles];
var RenderType_PaymentListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentListComponent, data: { "animation": [{ type: 7, name: "fade", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: null, timings: ".6s ease" }], options: null }], options: {} }, { type: 7, name: "stagger", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 11, selector: ":enter", animation: { type: 12, timings: ".3s", animation: [{ type: 9, options: null }] }, options: { optional: true } }], options: null }], options: {} }, { type: 7, name: "ngIfAnimation", definitions: [{ type: 1, expr: "void => *", animation: [{ type: 11, selector: ".row", animation: { type: 6, styles: { opacity: 0 }, offset: null }, options: { optional: true } }, { type: 11, selector: ".row", animation: { type: 12, timings: "100ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 0, transform: "translateY(-75%)", offset: 0, height: "0" }, offset: null }, { type: 6, styles: { opacity: 1, transform: "translateY(0)", offset: 1, height: "*" }, offset: null }] }, timings: "0.8s ease-out" }] }, options: { optional: true } }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 11, selector: ".row", animation: { type: 6, styles: { opacity: 1 }, offset: null }, options: { optional: true } }, { type: 11, selector: ".row", animation: { type: 12, timings: "100ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 1, transform: "translateY(0)", offset: 0, height: "*" }, offset: null }, { type: 6, styles: { opacity: 0, transform: "translateY(-75%)", offset: 1, height: "0" }, offset: null }] }, timings: "0.8s ease-in" }] }, options: { optional: true } }], options: null }], options: {} }] } });
export { RenderType_PaymentListComponent as RenderType_PaymentListComponent };
function View_PaymentListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "rel"]], [[24, "@ngIfAnimation", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "row clearfix padded rel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "dataHeader rm-mt transparent"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Fetching data..."]))], null, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
function View_PaymentListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payment-view", [], null, null, null, i2.View_PaymentViewComponent_0, i2.RenderType_PaymentViewComponent)), i1.ɵdid(1, 114688, null, 0, i3.PaymentViewComponent, [i4.BankAccountService, i5.ActivatedRoute, i6.AuthenticationService, [2, i7.MAT_DIALOG_DATA]], { paymentObject: [0, "paymentObject"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_PaymentListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PaymentListComponent_3)), i1.ɵdid(2, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵppd(3, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _co.payments, "Dates.Due")); _ck(_v, 2, 0, currVal_0); }, null); }
function View_PaymentListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-empty-list-message", [], null, null, null, i9.View_EmptyListMessageComponent_0, i9.RenderType_EmptyListMessageComponent)), i1.ɵdid(2, 114688, null, 0, i10.EmptyListMessageComponent, [], { title: [0, "title"], message: [1, "message"] }, null)], function (_ck, _v) { var currVal_0 = "No payment found"; var currVal_1 = "There is no payment to show"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_PaymentListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i11.OrderArrayObject, []), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentListComponent_1)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentListComponent_2)), i1.ɵdid(5, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentListComponent_4)), i1.ɵdid(7, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.firstLoad == false); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.payments && (_co.firstLoad == true)); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.emptyList == true); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.emptyList == true); _ck(_v, 1, 0, currVal_0); }); }
export function View_PaymentListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payment-list", [], null, null, null, View_PaymentListComponent_0, RenderType_PaymentListComponent)), i1.ɵdid(1, 770048, null, 0, i12.PaymentListComponent, [i4.BankAccountService, i5.ActivatedRoute, i6.AuthenticationService, [2, i7.MAT_DIALOG_DATA]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentListComponentNgFactory = i1.ɵccf("app-payment-list", i12.PaymentListComponent, View_PaymentListComponent_Host_0, { contractID: "contractID", merchantID: "merchantID", customerID: "customerID", dateFrom: "dateFrom", dateTo: "dateTo", status: "status", isConsolidated: "isConsolidated", isOutstandingOnly: "isOutstandingOnly", isPending: "isPending" }, { totalObject: "totalObject" }, []);
export { PaymentListComponentNgFactory as PaymentListComponentNgFactory };
