<div class="white-background clearfix">
  <div class="row clearfix cardheader primary-gradient-img inModal mb">
    <!-- close button -->
    <button class="btn-close btn-clear white pull-right mat-button" (click)="closeEvent()" mat-dialog-close>
      <mat-icon>clear</mat-icon>
    </button>
    <div class="row titleArea clearfix">
      <h2 class="summary-header rm-m white">
        Insert Document
        <span class="subLabel"> You can Copy and Paste the result on your HTML content</span>
      </h2>
    </div>
  </div>

  <mat-dialog-content>
    <div class="full-width toggle-card mb clearfix">
      <mat-button-toggle-group
        class="btn-group text-center full-width with-icons flex"
        #groupB="matButtonToggleGroup"
        [(ngModel)]="type"
        name="view"
      >
        <mat-button-toggle class="text-center full-width" [value]="'document'" matTooltip="Intert HTML Content ">
          <mat-icon class="fas fa-file-code"></mat-icon> EP Document ID
        </mat-button-toggle>

        <mat-button-toggle class="text-center full-width" [value]="'video'" matTooltip="Use WYIWYG editor ">
          <mat-icon class="fas fa-file-video"></mat-icon> Video
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="full-width clear" *ngIf="type == 'video'">
      <p class="small">Please insert a valid Vimeo Or Youtube url</p>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Video Url</mat-label>
        <input
          class="md-block"
          [(ngModel)]="sourceVideo"
          (change)="changeSource($event)"
          matInput
          placeholder="Video Url"
        />
      </mat-form-field>

      <div class="full-width min-height clearfix" *ngIf="resultVideo">
        <ngx-monaco-editor [(ngModel)]="resultVideo" [options]="editorOptions"></ngx-monaco-editor>
      </div>
    </div>

    <div class="full-width clear" *ngIf="type == 'document'">
      <p class="small">Please insert EP Document ID</p>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Document ID</mat-label>
        <input
          class="md-block"
          [(ngModel)]="sourceDocument"
          (change)="changeSource($event)"
          matInput
          placeholder="Document ID"
        />
      </mat-form-field>

      <div class="clearfix full-width mb" *ngIf="docs && docs.length && docs.length == 1">
        <app-ep-document-list
          [isDownload]="isDownload"
          [isPrint]="isPrint"
          [isFilter]="false"
          [defaultCheck]="false"
          [isRedirection]="isRedirection"
          [documents]="docs"
          [title]="''"
          [canCheck]="false"
        >
        </app-ep-document-list>
      </div>

      <div class="margin-btm" *ngIf="resultDocument">
        <h3 class="sm-h4 sr-title">Link</h3>
      </div>

      <div class="full-width min-height clearfix" *ngIf="resultDocument">
        <ngx-monaco-editor [(ngModel)]="resultDocument" [options]="editorOptions"></ngx-monaco-editor>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="button-row text-center">
    <div class="clearfix full-width text-center mt">
      <button class="btn-large" (click)="closeEvent()" mat-raised-button color="primary">Close</button>
    </div>
  </mat-dialog-actions>
</div>
