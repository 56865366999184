<div class="rel clearfix lookup">
  <div class="card-header primary-gradient-img clearfix inModal">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <mat-icon class="fas fa-brush pull-left main-icon-brush"
                  style="font-size: 2.8rem; margin-top: 10px; width: 70px"></mat-icon>
        <h2 class="summary-header rm-m">
          {{ title }}
          <span class="subLabel" [innerHTML]="description | safeHtml"></span>
        </h2>
      </div>
    </div>
  </div>

  <div class="clearfix flex flex-center">
    <mat-form-field class="full-width mr" *ngIf="templates.length > 3" appearance="outline">
      <mat-label>Search by Title, Description, {{ "KEYWORD.treatment" | translate | titlecase }} value And Item
        Number
      </mat-label>
      <input [(ngModel)]="filterData" (ngModelChange)="backToOne()" matInput name="search"
             placeholder="Search by Title, Description, {{ 'KEYWORD.treatment' | translate | titlecase }} Value And Item
        Number" tabIndex="1"/>
    </mat-form-field>

    <mat-button-toggle-group class="btn-group rm-m selectType full-width flex tab" [(ngModel)]="typeFilter"
                             (ngModelChange)="changeTab()" value="null">
      <mat-button-toggle class="full-width" *ngIf="!type" [value]="null" matTooltip=" All Templates">
        <span class="badge master" *ngIf="templates" [endVal]="
            (templates
              | FilterArrayMultipleOrContain: 'search':filterData
              | FilterArrayMultipleValue: ['type']:null
              | lengthOfArray) || 0
          " countUp></span>
        All
      </mat-button-toggle>

      <mat-button-toggle class="full-width" *ngIf="!type || type == 'user'" [value]="'user'"
                         [matTooltip]="('KEYWORD.practice' | translate | titlecase) + ' Templates'">
        <span class="badge master" *ngIf="templates" [endVal]="
            (templates
              | FilterArrayMultipleOrContain: 'search':filterData
              | FilterArrayMultipleValue: ['type']:'user'
              | lengthOfArray) || 0
          " countUp></span>
        <span class="main-title-tab">{{ "KEYWORD.practice" | translate | titlecase}}</span>
        <mat-icon class="fas fa-user-alt"></mat-icon>
      </mat-button-toggle>

      <mat-button-toggle class="full-width" *ngIf="!type || type == 'default'" [value]="'default'"
                         matTooltip="{{ 'BRANDING.Brand Name' | translate }} Templates">
        <span class="badge master" *ngIf="templates" [endVal]="
            (templates
              | FilterArrayMultipleOrContain: 'search':filterData
              | FilterArrayMultipleValue: ['type']:'default'
              | lengthOfArray) || 0
          " countUp></span>
        <span class="main-title-tab">{{ "BRANDING.Brand Name" | translate | titlecase }} </span>
        <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>

    <button class="actionMenuToggle" *ngIf="templates.length > 3" [matMenuTriggerFor]="menu" mat-icon-button>
      <mat-icon class="fas fa-sort"></mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button [ngClass]="{ 'accent-color': orderByFilter === 'label' || orderByFilter === '-label' }"
              (click)="orderBy('label')" mat-menu-item>
        <span>Name</span>
        <span *ngIf="orderByFilter === 'label' || orderByFilter === '-label'">
          <mat-icon class="accent-color" *ngIf="orderByFilter === '-label'">arrow_drop_up</mat-icon>
          <mat-icon class="accent-color" *ngIf="orderByFilter !== '-label'">arrow_drop_down</mat-icon>
        </span>
      </button>

      <button [ngClass]="{ 'accent-color': orderByFilter === 'created_at' || orderByFilter === '-created_at' }"
              (click)="orderBy('-created_at')" mat-menu-item>
        <span>Date</span>
        <span *ngIf="orderByFilter === 'created_at' || orderByFilter === '-created_at'">
          <mat-icon class="accent-color" *ngIf="orderByFilter === '-created_at'">arrow_drop_down</mat-icon>
          <mat-icon class="accent-color" *ngIf="orderByFilter !== '-created_at'"> arrow_drop_up</mat-icon>
        </span>
      </button>

      <button [ngClass]="{ 'accent-color': orderByFilter === 'type' || orderByFilter === '-type' }"
              (click)="orderBy('type')" mat-menu-item>
        <span>Type</span>
        <span *ngIf="orderByFilter === 'type' || orderByFilter === '-type'">
          <mat-icon class="accent-color" *ngIf="orderByFilter === '-type'">arrow_drop_up</mat-icon>
          <mat-icon class="accent-color" *ngIf="orderByFilter !== '-type'">arrow_drop_down</mat-icon>
        </span>
      </button>

      <button [ngClass]="{ 'accent-color': orderByFilter === 'applyCount' || orderByFilter === '-applyCount' }"
              (click)="orderBy('-applyCount')" mat-menu-item>
        <span>Used times</span>
        <span *ngIf="orderByFilter === 'applyCount' || orderByFilter === '-applyCount'">
          <mat-icon class="accent-color" *ngIf="orderByFilter === '-applyCount'">arrow_drop_up</mat-icon>
          <mat-icon class="accent-color" *ngIf="orderByFilter !== '-applyCount'">arrow_drop_down</mat-icon>
        </span>
      </button>
    </mat-menu>
  </div>

  <mat-dialog-content class="lookup-template" [ngClass]="{ 'no-modal-height': isModal != true }">
    <div class="patientContainer">
      <div class="patientRecord row clearfix" *ngFor="
          let template of templates
            | FilterArrayMultipleOrContain: 'search':filterData
            | FilterArrayMultipleValue: ['type']:typeFilter
            | OrderArrayObject: [orderByFilter]
            | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }
        ">
        <div class="full-width clearfix expend-container">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="profile-result full-width flex" (click)="selectTemplate(template)">
                    <!-- avatar image -->

                    <span class="chipPromoter pull-left primary-bg mr"
                          *ngIf="template && template.type && template.type == 'default'"
                          matTooltip="{{ 'BRANDING.Brand Name' | translate }} Template">
                      <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
                    </span>

                    <span class="chipUser pull-left mr" *ngIf="template && template.type && template.type != 'default'"
                          [matTooltip]="('KEYWORD.practice' | translate | titlecase) + ' Template'">
                      <mat-icon class="fas fa-user-alt"></mat-icon>
                    </span>

                    <div class="pull-left full-width truncate-normal">
                      <div class="full-width">
                        <h4 class="sr-title rm-m mr pull-left"
                            [ngClass]="{ 'title-practice': template && template.type && template.type == 'user' }">
                          <strong> {{ template['label'] }}</strong>

                          <span class="badge theme-background-color-primary"
                            [ngClass]="{ 'badge-practice': template && template.type && template.type == 'user' }"
                                matTooltip="This Template has been applied {{ template.applyCount || 0 }} time(s)">
                            {{ template.applyCount || 0 }}</span>

                          <span class="value chip-new pull-right"
                                *ngIf="isNew(template['created_at']) == true">NEW</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </mat-panel-title>

                <mat-panel-description>
                  <div class="full-width text-right">
                    <h4 class="sr-title rm-m pull-right" *ngIf="
                        template &&
                        template.content &&
                        template['content']['totalTreatmentValue'] &&
                        hideTreatmentValue == false
                      " [ngClass]="{ 'title-practice': template && template.type && template.type == 'user' }"
                        (click)="selectTemplate(template)">
                      {{ template['content']['totalTreatmentValue'] | customCurrency }}
                    </h4>
                  </div>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="flex">
                <div class="profile-result full-width flex">
                  <div class="pull-left full-width truncate-normal patientRecord-inner"
                       (click)="selectTemplate(template)">
                    <p class="reduce-marg small-margin-bottom" matLine>
                      {{ template['description'] }}
                    </p>

                    <div class="full-width clearfix small-margin-bottom"
                         *ngIf="template && template.content && template.content.tags && template.content.tags.length > 0">
                      <app-tag-list class="full-width ship tag-list-lookup" [selectedTags]="template.content.tags"
                                    [tagFormatIsObject]="false" [title]="''" [canRemove]="false" [canCreate]="false"
                                    [directRemoveDefault]="true" [directRemove]="true" [hasFilter]="false"
                                    [canSelect]="false">
                      </app-tag-list>
                    </div>

                    <p class="reduce-marg small-margin-bottom small" *ngIf="
                        isPromoterOrAdmin == true && template['productGroupLabel'] && template['productGroup'] != 'all'
                      " matLine>
                      <strong> {{ template['productGroupLabel'] }}.</strong>
                    </p>
                    <div class="full-width clearfix">
                      <p class="small rm-m date_label grey pull-right" *ngIf="template.created_at">
                        <mat-icon class="fas fa-clock"></mat-icon>
                        {{ template.created_at | TimeLabelPast }} ago
                      </p>

                      <div class="pull-left text-left">
                        <div class="check-template text-center"
                             [ngClass]="{ 'hide-option': template.displaySelectTreatmentValue !== true }"
                             matTooltip="{{'KEYWORD.treatment' | translate | titlecase}} value {{
                            template.displaySelectTreatmentValue == true ? 'is active' : 'is not active'
                          }}">
                          <mat-icon class="fas fa-dollar-sign"></mat-icon>
                        </div>
                        <div class="check-template text-center"
                             [ngClass]="{ 'hide-option': template.displaySelectDocuments !== true }"
                             matTooltip="{{'KEYWORD.treatment' | translate | titlecase}} Documents {{
                            template.displaySelectDocuments == true ? 'are active' : 'are not active'
                          }}">
                          <mat-icon class="fas fa-file-alt"></mat-icon>
                        </div>
                        <div class="check-template text-center"
                             [ngClass]="{ 'hide-option': template.displaySelectImages !== true }"
                             matTooltip="{{'KEYWORD.treatment' | translate | titlecase}} Images {{
                            template.displaySelectImages == true ? 'are active' : 'are not active'
                          }}">
                          <mat-icon class="fas fa-image"></mat-icon>
                        </div>
                        <div class="check-template text-center"
                             [ngClass]="{ 'hide-option': template.displaySelectTreatmentPlan !== true }"
                             matTooltip="{{'KEYWORD.treatment' | translate | titlecase}} Items {{
                            template.displaySelectTreatmentPlan == true ? 'are active' : 'are not active'
                          }}">
                          <mat-icon class="fas fa-notes-medical"></mat-icon>
                        </div>
                        <div class="check-template text-center"
                          [ngClass]="{ 'hide-option': template.displaySelectTags !== true }"
                             matTooltip="Tags {{ template.displaySelectTags == true ? 'are active' : 'are not active' }}">
                          <mat-icon class="fas fa-tags"></mat-icon>
                        </div>

                        <div class="check-template text-center"
                             [ngClass]="{ 'hide-option': template.displaySelectMessage !== true }" matTooltip="'Personal message to ' + ('KEYWORD.patient' | translate) + ' ' +
                            (template.displaySelectMessage == true ? 'is active' : 'is not active')">
                          <mat-icon class="fas fa-comment-dots"></mat-icon>
                        </div>
                        <div class="check-template text-center"
                             [ngClass]="{ 'hide-option': template.displaySelectDiscount !== true }"
                             matTooltip="Discount  configuration {{
                            template.displaySelectDiscount == true ? 'is active' : 'is not active'
                          }}">
                          <mat-icon class="fas fa-money-bill-wave"></mat-icon>
                        </div>
                        <div class="check-template text-center"
                             [ngClass]="{ 'hide-option': template.displaySelectHealthHistory !== true }"
                             matTooltip=" Health History {{
                            template.displaySelectHealthHistory == true ? 'is active' : 'is not active'
                          }}">
                          <mat-icon class="fas fa-heartbeat"></mat-icon>
                        </div>
                        <div class="check-template text-center"
                             [ngClass]="{ 'hide-option': template.displaySelectGoogleReview !== true }"
                             matTooltip="Google Review {{
                            template.displaySelectGoogleReview == true ? 'is active' : 'is not active'
                          }}">
                          <mat-icon class="fas fa-star"></mat-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button class="template-view-button" *ngIf="template && template['_id'] && viewOnClick != true"
                          (click)="viewTreatment(template['_id'])" color="primary" mat-icon-button
                          matTooltip="View Template Details">
                    <mat-icon>visibility</mat-icon>
                  </button>

                  <button class="template-view-button" *ngIf="template && template['_id']"
                          [matMenuTriggerFor]="previewMenu" (click)="$event.stopPropagation()" color="primary"
                          mat-icon-button
                          matTooltip="View Template Details">
                    <mat-icon>more_vert</mat-icon>
                  </button>

                  <mat-menu #previewMenu="matMenu">
                    <button (click)="viewTreatment(template['_id'])" mat-menu-item>
                      <mat-icon>visibility</mat-icon>
                      <span>View</span>
                    </button>

                    <button *ngIf="
                        isHeader == true &&
                        template &&
                        (template.type != 'default' || (template.type == 'default' && isPromoterOrAdmin == true))
                      " (click)="edittemplate(template['_id'])" mat-menu-item>
                      <mat-icon>edit</mat-icon>
                      <span>Edit</span>
                    </button>

                    <button *ngIf="isHeader == true" (click)="clone(template)" mat-menu-item>
                      <mat-icon>content_copy</mat-icon>
                      <span>Clone</span>
                    </button>

                    <button *ngIf="
                        template &&
                        (template.type != 'default' || (template.type == 'default' && isPromoterOrAdmin == true))
                      " (click)="delete(template['_id'])" mat-menu-item>
                      <mat-icon>delete</mat-icon>
                      <span>Delete</span>
                    </button>
                  </mat-menu>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div class="flex full-width clearfix mt mb normal-container">
          <div class="profile-result full-width flex">
            <!-- avatar image -->

            <span class="chipPromoter pull-left primary-bg mr"
                  *ngIf="template && template.type && template.type == 'default'" (click)="selectTemplate(template)"
                  matTooltip=" {{'BRANDING.Brand Name' | translate }} Template">
              <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
            </span>

            <span class="chipUser pull-left mr" *ngIf="template && template.type && template.type != 'default'"
              (click)="selectTemplate(template)"
                  [matTooltip]="('KEYWORD.practice' | translate | titlecase) + ' Template'">
              <mat-icon class="fas fa-user-alt"></mat-icon>
            </span>

            <!-- profile information -->
            <div class="pull-left full-width truncate-normal" (click)="selectTemplate(template)">
              <div class="full-width mb">
                <h3 class="sr-title rm-m pull-left"
                    [ngClass]="{ 'title-practice': template && template.type && template.type == 'user' }">
                  <strong> {{ template['label'] }}</strong>

                  <span class="badge theme-background-color-primary"
                    [ngClass]="{ 'badge-practice': template && template.type && template.type == 'user' }"
                        matTooltip="This Template has been applied {{ template.applyCount || 0 }} time(s)">
                    {{ template.applyCount || 0 }}</span>

                  <span class="value chip-new pull-right" *ngIf="isNew(template['created_at']) == true">NEW</span>
                </h3>
              </div>

              <p class="reduce-marg small-margin-bottom" matLine>
                {{ template['description'] }}
              </p>

              <div class="full-width clearfix small-margin-bottom"
                   *ngIf="template && template.content && template.content.tags && template.content.tags.length > 0">
                <app-tag-list class="full-width ship tag-list-lookup" [selectedTags]="template.content.tags"
                              [tagFormatIsObject]="false" [title]="''" [canRemove]="false" [canCreate]="false"
                              [directRemoveDefault]="true" [directRemove]="true" [hasFilter]="false"
                              [canSelect]="false">
                </app-tag-list>
              </div>
              <p class="reduce-marg small-margin-bottom small"
                *ngIf="isPromoterOrAdmin == true && template['productGroupLabel'] && template['productGroup'] != 'all'"
                 matLine>
                <strong> {{ template['productGroupLabel'] }}.</strong>
              </p>
              <div class="full-width clearfix">
                <div class="pull-left text-left">
                  <div class="check-template text-center"
                       [ngClass]="{ 'hide-option': template.displaySelectTreatmentValue !== true }"
                       matTooltip="{{'KEYWORD.treatment' | translate | titlecase}} value {{
                      template.displaySelectTreatmentValue == true ? 'is active' : 'is not active'
                    }}">
                    <mat-icon class="fas fa-dollar-sign"></mat-icon>
                  </div>
                  <div class="check-template text-center"
                       [ngClass]="{ 'hide-option': template.displaySelectDocuments !== true }"
                       matTooltip="{{'KEYWORD.treatment' | translate | titlecase}} Documents {{
                      template.displaySelectDocuments == true ? 'are active' : 'are not active'
                    }}">
                    <mat-icon class="fas fa-file-alt"></mat-icon>
                  </div>
                  <div class="check-template text-center"
                       [ngClass]="{ 'hide-option': template.displaySelectImages !== true }"
                       matTooltip="{{'KEYWORD.treatment' | translate | titlecase}} Images {{
                      template.displaySelectImages == true ? 'are active' : 'are not active'
                    }}">
                    <mat-icon class="fas fa-image"></mat-icon>
                  </div>
                  <div class="check-template text-center"
                       [ngClass]="{ 'hide-option': template.displaySelectTreatmentPlan !== true }"
                       matTooltip="{{'KEYWORD.treatment' | translate | titlecase}} Items {{
                      template.displaySelectTreatmentPlan == true ? 'are active' : 'are not active'
                    }}">
                    <mat-icon class="fas fa-notes-medical"></mat-icon>
                  </div>
                  <div class="check-template text-center"
                    [ngClass]="{ 'hide-option': template.displaySelectTags !== true }"
                       matTooltip="Tags {{ template.displaySelectTags == true ? 'are active' : 'are not active' }}">
                    <mat-icon class="fas fa-tags"></mat-icon>
                  </div>

                  <div class="check-template text-center"
                    [ngClass]="{ 'hide-option': template.displaySelectMessage !== true }"
                       matTooltip="'Personal message to ' + ('KEYWORD.patient' | translate) + ' ' + (template.displaySelectMessage == true ? 'is active' : 'is not active')">
                    <mat-icon class="fas fa-comment-dots"></mat-icon>
                  </div>
                  <div class="check-template text-center"
                       [ngClass]="{ 'hide-option': template.displaySelectDiscount !== true }"
                       matTooltip="Discount  configuration {{
                      template.displaySelectDiscount == true ? 'is active' : 'is not active'
                    }}">
                    <mat-icon class="fas fa-money-bill-wave"></mat-icon>
                  </div>
                  <div class="check-template text-center"
                       [ngClass]="{ 'hide-option': template.displaySelectHealthHistory !== true }"
                       matTooltip=" Health History {{
                      template.displaySelectHealthHistory == true ? 'is active' : 'is not active'
                    }}">
                    <mat-icon class="fas fa-heartbeat"></mat-icon>
                  </div>
                  <div class="check-template text-center"
                       [ngClass]="{ 'hide-option': template.displaySelectGoogleReview !== true }"
                       matTooltip="Google Review {{
                      template.displaySelectGoogleReview == true ? 'is active' : 'is not active'
                    }}">
                    <mat-icon class="fas fa-star"></mat-icon>
                  </div>
                </div>

                <div class="full-width clearfix mt">
                  <p class="small rm-m mr date_label grey pull-right" *ngIf="template.created_at"
                     [ngClass]="{ noTreatmentValue: hideTreatmentValue !== false }">
                    {{ template.created_at | TimeLabelPast }} ago
                  </p>

                  <h3 class="sr-title rm-m pull-left" *ngIf="
                      template &&
                      template.content &&
                      template['content']['totalTreatmentValue'] &&
                      hideTreatmentValue == false
                    " [ngClass]="{ 'title-practice': template && template.type && template.type == 'user' }">
                    {{ template['content']['totalTreatmentValue'] | customCurrency }}
                  </h3>
                </div>
              </div>
            </div>

            <button class="template-view-button" *ngIf="template && template['_id'] && viewOnClick != true"
                    (click)="viewTreatment(template['_id'])" color="primary" mat-icon-button
                    matTooltip="View Template Details">
              <mat-icon>visibility</mat-icon>
            </button>

            <button class="template-view-button" *ngIf="template && template['_id']" [matMenuTriggerFor]="previewMenu"
                    (click)="$event.stopPropagation()" color="primary" mat-icon-button
                    matTooltip="View Template Details">
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #previewMenu="matMenu">
              <button (click)="viewTreatment(template['_id'])" mat-menu-item>
                <mat-icon>visibility</mat-icon>
                <span>View</span>
              </button>

              <button *ngIf="
                  isHeader == true &&
                  template &&
                  (template.type != 'default' || (template.type == 'default' && isPromoterOrAdmin == true))
                " (click)="edittemplate(template['_id'])" mat-menu-item>
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>

              <button *ngIf="isHeader == true" (click)="clone(template)" mat-menu-item>
                <mat-icon>content_copy</mat-icon>
                <span>Clone</span>
              </button>

              <button *ngIf="
                  template && (template.type != 'default' || (template.type == 'default' && isPromoterOrAdmin == true))
                " (click)="delete(template['_id'])" mat-menu-item>
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </div>
        </div>

        <mat-divider></mat-divider>
      </div>

      <div class="full-width" *ngIf="
          (templates
            | FilterArrayMultipleOrContain: 'search':filterData
            | FilterArrayMultipleValue: ['type']:typeFilter
            | OrderArrayObject: [orderByFilter]
            | lengthOfArray) <= 0 && isLoaded == true
        ">
        <app-empty-list-message [message]="'No Template found'"></app-empty-list-message>
      </div>
    </div>

    <div class="full-width text-center page-container flex" *ngIf="
        (templates
          | FilterArrayMultipleOrContain: 'search':filterData
          | FilterArrayMultipleValue: ['type']:typeFilter
          | OrderArrayObject: [orderByFilter]
          | lengthOfArray) > 20
      ">
      <div class="ml qtr-width flex pull-left">
        <mat-label class="line-h">Page size</mat-label>
        <mat-select class="page-size-in line-h pull-right" [(ngModel)]="itemsPerPage" placeholder="Page size">
          <mat-option [value]="20"> 20</mat-option>
          <mat-option [value]="30"> 30</mat-option>
          <mat-option [value]="40"> 40</mat-option>
          <mat-option [value]="50"> 50</mat-option>
        </mat-select>
      </div>
      <pagination-controls class="full-width clearfix" (pageChange)="changePage($event)" responsive="true">
      </pagination-controls>
    </div>
    <br/>
    <br/>
  </mat-dialog-content>
  <hr class="template-bottom-divider" *ngIf="isModal == true"/>
  <div class="drop-button full-width pull-right clearfix" *ngIf="isModal == true">
    <button class="inline btn-new-invite selectExistingPatient pull-right btn" (click)="gotoNewTemplate()"
            mat-raised-button color="accent">
      <mat-icon>add</mat-icon>Create your own
    </button>

    <button class="inline btn-new-invite selectExistingPatient pull-left btn" (click)="closeEvent()" mat-raised-button
            color="primary">
      Close
    </button>

    <button class="inline ml btn-new-invite selectExistingPatient pull-left btn" *ngIf="isHeader == true"
            (click)="list()" mat-raised-button color="primary">
      Templates List
    </button>
  </div>

  <div class="drop-button" *ngIf="showNextBtn == true">
    <button class="inline btn btn-new-invite selectExistingPatient" (click)="noMatch()" mat-raised-button
            color="accent">
      Add New {{ "KEYWORD.patient" | translate | titlecase }} Record
    </button>
  </div>
</div>
