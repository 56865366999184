import { Location } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { TagsService } from '../shared/tags.service';

@Component({
  selector: 'app-tags-view',
  templateUrl: './tags-view-modal.component.html',
  styleUrls: ['./tags-view-modal.component.css'],
})
export class TagsViewModalComponent implements OnInit {
  @Input()
  tagID;

  tag;

  close = new EventEmitter();

  sessionType;

  constructor(
    private tagsService: TagsService,
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private location: Location,
    private router: Router,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    this.tagID = data;
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((st) => {
      this.sessionType = st;

      if (this.tagID) {
        this.tagsService.getOneTag(this.tagID, {}, this.sessionType).subscribe((res) => {
          if (res) {
            this.tag = res;
          }
        });
      }
    });
  }

  viewTag() {
    this.close.emit();
    this.router.navigate(['/merchant', { outlets: { page: ['customer-prospect-tag-view', this.tagID] } }]);
  }

  closeEvent() {
    this.close.emit(true);
  }
}
