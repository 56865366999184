import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LogService {
  constructor(private http: HttpClient) {}

  getLogs(fields): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const i in fields) {
      if (fields[i]) {
        params = params.set(i, fields[i]);
      }
    }

    options = {
      params,
    };
    return this.http.get(`${environment.nodeUrl}/logs/`, options);
  }

  deleteLogs(body): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (let key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };

    return this.http.delete(environment.nodeUrl + '/logs/', options).pipe(map((res: HttpResponse<any>) => res['data']));
  }
}
