import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthenticationService } from '../shared/authentication.service';

@Component({
  selector: 'app-authentication-method-view',
  templateUrl: './authentication-method-view.component.html',
  styleUrls: ['./authentication-method-view.component.css'],
})
export class AuthenticationMethodViewComponent implements OnInit {
  @Input()
  cardID;

  @Input()
  invitationID;

  @Input()
  title = 'Authentication Methods';

  @Output()
  notFound = new EventEmitter();

  sessionType = 'merchant';

  authenticationMethod;

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((res) => {
      if (res) {
        this.sessionType = res;

        if (this.cardID || this.invitationID) {
          this.authenticationService
            .getAuthenticationMethod(this.cardID, this.invitationID, this.sessionType)
            .subscribe((method) => {
              if (
                method &&
                method.hasAccount == true &&
                (method.hasFacebookID == true ||
                  method.hasGoogleID == true ||
                  method.hasTwitterID == true ||
                  method.hasUsername == true)
              ) {
                this.authenticationMethod = method;
              } else {
                this.notFound.emit(true);
              }
            });
        } else {
          this.notFound.emit(true);
        }
      }
    });
  }
}
