import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { UtilsService } from '../../../shared/services/utils.service';
import { MerchantService } from '../../merchant/shared/merchant.service';

import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { ContactCreateEditModalComponent } from '../../contact/contact-create-edit-modal/contact-create-edit-modal.component';
import { ActivatedRoute, Router } from '@angular/router';

/**
 * @title Feature-rich data table
 */
@Component({
  selector: 'app-dentist-list-overview',
  templateUrl: './dentist-list-overview.component.html',
  styleUrls: ['./dentist-list-overview.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class DentistListOverViewComponent implements OnInit {
  merchants = [];

  merchantID = 'none';

  sessionType;
  hideExtraComponents = false;
  isAdminOrPromoter = false;
  isMerchantAdmin = false;
  constructor(
    private utilService: UtilsService,
    private AuthenticationService: AuthenticationService,
    private merchantService: MerchantService,

    private activeRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['merchantID']) {
        this.merchantID = params['merchantID'];
      }

      this.AuthenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;
        this.AuthenticationService.isMerchantAdmin().subscribe((isMerchantAdmin) => {
          this.isMerchantAdmin = isMerchantAdmin;

          this.AuthenticationService.getSessionType().subscribe((sessionType) => {
            this.sessionType = sessionType;
            if (this.isAdminOrPromoter == true) {
              const payload = {
                fields: 'ID,TradingAs,CalculatedName,Status',
              };

              this.merchantService.getList(payload, this.isAdminOrPromoter).subscribe((res) => {
                this.merchants = res;

              });
            }
          });
        });
      });
    });
  }
  createNewContact() {
    const ref2 = RootAppComponent.dialog.open(ContactCreateEditModalComponent, {
      width: '850px',
    });

    ref2.componentInstance.close.subscribe((res) => {
      ref2.close();
    });
    ref2.componentInstance.getResult.subscribe((res) => {
      if (res && res.ID) {
        ref2.close();
        ref2.afterClosed().subscribe((r) => {
          this.router.navigate([
            '/merchant',
            {
              outlets: {
                page: ['dentist-profile', res.ID],
              },
            },
          ]);
        });
      }
    });
  }
  viewDetails(e) {
    this.hideExtraComponents = e;
  }

  viewMerchant() {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: this.merchantID,
      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }
}
