import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WikiCreateEditComponent } from '../wiki-create-edit/wiki-create-edit.component';
import { WikiFooterComponent } from '../wiki-footer/wiki-footer.component';
import { WikiGroupEditComponent } from '../wiki-group-edit/wiki-group-edit.component';
import { WikiGroupListComponent } from '../wiki-group-list/wiki-group-list.component';
import { WikiGroupViewListComponent } from '../wiki-group-view-list/wiki-group-view-list.component';
import { WikiGroupViewComponent } from '../wiki-group-view/wiki-group-view.component';
import { WikiHeaderComponent } from '../wiki-header/wiki-header.component';
import { WikiIntroductionComponent } from '../wiki-introduction/wiki-introduction.component';
import { WikiLeftMenuComponent } from '../wiki-left-menu/wiki-left-menu.component';
import { WikiListComponent } from '../wiki-list/wiki-list.component';
import { WikiMainComponent } from '../wiki-main/wiki-main.component';
import { WikiRedirectionComponent } from '../wiki-redirection/wiki-redirection.component';
import { WikiSearchComponent } from '../wiki-search/wiki-search.component';
import { WikiViewComponent } from '../wiki-view/wiki-view.component';

const routes: Routes = [
  {
    path: 'wiki',
    component: WikiMainComponent,
    children: [
      { path: '', component: WikiIntroductionComponent, outlet: 'wikiPageRoute' },
      { path: 'home', component: WikiIntroductionComponent, outlet: 'wikiPageRoute' },
      { path: 'create', component: WikiCreateEditComponent, outlet: 'wikiPageRoute' },
      { path: 'group-list', component: WikiGroupListComponent, outlet: 'wikiPageRoute' },
      { path: 'page-list', component: WikiListComponent, outlet: 'wikiPageRoute' },
      { path: 'edit/:wikiID', component: WikiCreateEditComponent, outlet: 'wikiPageRoute' },
      { path: 'edit-code/:code', component: WikiCreateEditComponent, outlet: 'wikiPageRoute' },
      { path: 'view-code/:code', component: WikiViewComponent, outlet: 'wikiPageRoute' },
      { path: 'view/:wikiID', component: WikiViewComponent, outlet: 'wikiPageRoute' },
      { path: 'search/:search', component: WikiSearchComponent, outlet: 'wikiPageRoute' },
      { path: 'tag/:tag', component: WikiSearchComponent, outlet: 'wikiPageRoute' },
      { path: 'group/:groupID', component: WikiGroupViewComponent, outlet: 'wikiPageRoute' },
      { path: 'group-code/:code', component: WikiGroupViewComponent, outlet: 'wikiPageRoute' },
      { path: 'redirection', component: WikiRedirectionComponent, outlet: 'wikiPageRoute' },
      { path: 'redirection/:link', component: WikiRedirectionComponent, outlet: 'wikiPageRoute' },
      { path: 'redirection/:link/:param', component: WikiRedirectionComponent, outlet: 'wikiPageRoute' },
    ],
  },

  {
    path: 'wiki-customer',
    component: WikiMainComponent,
    children: [
      { path: '', component: WikiIntroductionComponent, outlet: 'wikiPageRoute' },
      { path: 'home', component: WikiIntroductionComponent, outlet: 'wikiPageRoute' },
      { path: 'create', component: WikiCreateEditComponent, outlet: 'wikiPageRoute' },
      { path: 'group-list', component: WikiGroupListComponent, outlet: 'wikiPageRoute' },
      { path: 'page-list', component: WikiListComponent, outlet: 'wikiPageRoute' },
      { path: 'edit/:wikiID', component: WikiCreateEditComponent, outlet: 'wikiPageRoute' },
      { path: 'edit-code/:code', component: WikiCreateEditComponent, outlet: 'wikiPageRoute' },
      { path: 'view-code/:code', component: WikiViewComponent, outlet: 'wikiPageRoute' },
      { path: 'view/:wikiID', component: WikiViewComponent, outlet: 'wikiPageRoute' },
      { path: 'search/:search', component: WikiSearchComponent, outlet: 'wikiPageRoute' },
      { path: 'tag/:tag', component: WikiSearchComponent, outlet: 'wikiPageRoute' },
      { path: 'group/:groupID', component: WikiGroupViewComponent, outlet: 'wikiPageRoute' },
      { path: 'group-code/:code', component: WikiGroupViewComponent, outlet: 'wikiPageRoute' },
      { path: 'redirection', component: WikiRedirectionComponent, outlet: 'wikiPageRoute' },
      { path: 'redirection/:link', component: WikiRedirectionComponent, outlet: 'wikiPageRoute' },
      { path: 'redirection/:link/:param', component: WikiRedirectionComponent, outlet: 'wikiPageRoute' },
    ],
  },

  {
    path: 'wiki-merchant',
    component: WikiMainComponent,
    children: [
      { path: '', component: WikiIntroductionComponent, outlet: 'wikiPageRoute' },
      { path: 'home', component: WikiIntroductionComponent, outlet: 'wikiPageRoute' },
      { path: 'create', component: WikiCreateEditComponent, outlet: 'wikiPageRoute' },
      { path: 'group-list', component: WikiGroupListComponent, outlet: 'wikiPageRoute' },
      { path: 'page-list', component: WikiListComponent, outlet: 'wikiPageRoute' },
      { path: 'edit/:wikiID', component: WikiCreateEditComponent, outlet: 'wikiPageRoute' },
      { path: 'edit-code/:code', component: WikiCreateEditComponent, outlet: 'wikiPageRoute' },
      { path: 'view-code/:code', component: WikiViewComponent, outlet: 'wikiPageRoute' },
      { path: 'view/:wikiID', component: WikiViewComponent, outlet: 'wikiPageRoute' },
      { path: 'search/:search', component: WikiSearchComponent, outlet: 'wikiPageRoute' },
      { path: 'tag/:tag', component: WikiSearchComponent, outlet: 'wikiPageRoute' },
      { path: 'group/:groupID', component: WikiGroupViewComponent, outlet: 'wikiPageRoute' },
      { path: 'group-code/:code', component: WikiGroupViewComponent, outlet: 'wikiPageRoute' },
      { path: 'redirection', component: WikiRedirectionComponent, outlet: 'wikiPageRoute' },
      { path: 'redirection/:link', component: WikiRedirectionComponent, outlet: 'wikiPageRoute' },
      { path: 'redirection/:link/:param', component: WikiRedirectionComponent, outlet: 'wikiPageRoute' },
    ],
  },

  {
    path: 'wiki-funder',
    component: WikiMainComponent,
    children: [
      { path: '', component: WikiIntroductionComponent, outlet: 'wikiPageRoute' },
      { path: 'home', component: WikiIntroductionComponent, outlet: 'wikiPageRoute' },
      { path: 'create', component: WikiCreateEditComponent, outlet: 'wikiPageRoute' },
      { path: 'group-list', component: WikiGroupListComponent, outlet: 'wikiPageRoute' },
      { path: 'page-list', component: WikiListComponent, outlet: 'wikiPageRoute' },
      { path: 'edit/:wikiID', component: WikiCreateEditComponent, outlet: 'wikiPageRoute' },
      { path: 'edit-code/:code', component: WikiCreateEditComponent, outlet: 'wikiPageRoute' },
      { path: 'view-code/:code', component: WikiViewComponent, outlet: 'wikiPageRoute' },
      { path: 'view/:wikiID', component: WikiViewComponent, outlet: 'wikiPageRoute' },
      { path: 'search/:search', component: WikiSearchComponent, outlet: 'wikiPageRoute' },
      { path: 'tag/:tag', component: WikiSearchComponent, outlet: 'wikiPageRoute' },
      { path: 'group/:groupID', component: WikiGroupViewComponent, outlet: 'wikiPageRoute' },
      { path: 'group-code/:code', component: WikiGroupViewComponent, outlet: 'wikiPageRoute' },
      { path: 'redirection', component: WikiRedirectionComponent, outlet: 'wikiPageRoute' },
      { path: 'redirection/:link', component: WikiRedirectionComponent, outlet: 'wikiPageRoute' },
      { path: 'redirection/:link/:param', component: WikiRedirectionComponent, outlet: 'wikiPageRoute' },
    ],
  },

  {
    path: 'wiki-promoter',
    component: WikiMainComponent,
    children: [
      { path: '', component: WikiIntroductionComponent, outlet: 'wikiPageRoute' },
      { path: 'home', component: WikiIntroductionComponent, outlet: 'wikiPageRoute' },
      { path: 'create', component: WikiCreateEditComponent, outlet: 'wikiPageRoute' },
      { path: 'group-list', component: WikiGroupListComponent, outlet: 'wikiPageRoute' },
      { path: 'page-list', component: WikiListComponent, outlet: 'wikiPageRoute' },
      { path: 'edit/:wikiID', component: WikiCreateEditComponent, outlet: 'wikiPageRoute' },
      { path: 'edit-code/:code', component: WikiCreateEditComponent, outlet: 'wikiPageRoute' },
      { path: 'view-code/:code', component: WikiViewComponent, outlet: 'wikiPageRoute' },
      { path: 'view/:wikiID', component: WikiViewComponent, outlet: 'wikiPageRoute' },
      { path: 'search/:search', component: WikiSearchComponent, outlet: 'wikiPageRoute' },
      { path: 'tag/:tag', component: WikiSearchComponent, outlet: 'wikiPageRoute' },
      { path: 'group/:groupID', component: WikiGroupViewComponent, outlet: 'wikiPageRoute' },
      { path: 'group-code/:code', component: WikiGroupViewComponent, outlet: 'wikiPageRoute' },
      { path: 'redirection', component: WikiRedirectionComponent, outlet: 'wikiPageRoute' },
      { path: 'redirection/:link', component: WikiRedirectionComponent, outlet: 'wikiPageRoute' },
      { path: 'redirection/:link/:param', component: WikiRedirectionComponent, outlet: 'wikiPageRoute' },
    ],
  },

  {
    path: 'wiki-supplier',
    component: WikiMainComponent,
    children: [
      { path: '', component: WikiIntroductionComponent, outlet: 'wikiPageRoute' },
      { path: 'home', component: WikiIntroductionComponent, outlet: 'wikiPageRoute' },
      { path: 'create', component: WikiCreateEditComponent, outlet: 'wikiPageRoute' },
      { path: 'group-list', component: WikiGroupListComponent, outlet: 'wikiPageRoute' },
      { path: 'page-list', component: WikiListComponent, outlet: 'wikiPageRoute' },
      { path: 'edit/:wikiID', component: WikiCreateEditComponent, outlet: 'wikiPageRoute' },
      { path: 'edit-code/:code', component: WikiCreateEditComponent, outlet: 'wikiPageRoute' },
      { path: 'view-code/:code', component: WikiViewComponent, outlet: 'wikiPageRoute' },
      { path: 'view/:wikiID', component: WikiViewComponent, outlet: 'wikiPageRoute' },
      { path: 'search/:search', component: WikiSearchComponent, outlet: 'wikiPageRoute' },
      { path: 'tag/:tag', component: WikiSearchComponent, outlet: 'wikiPageRoute' },
      { path: 'group/:groupID', component: WikiGroupViewComponent, outlet: 'wikiPageRoute' },
      { path: 'group-code/:code', component: WikiGroupViewComponent, outlet: 'wikiPageRoute' },
      { path: 'redirection', component: WikiRedirectionComponent, outlet: 'wikiPageRoute' },
      { path: 'redirection/:link', component: WikiRedirectionComponent, outlet: 'wikiPageRoute' },
      { path: 'redirection/:link/:param', component: WikiRedirectionComponent, outlet: 'wikiPageRoute' },
    ],
  },

  {
    path: 'wiki-guest',
    component: WikiMainComponent,
    children: [
      { path: '', component: WikiIntroductionComponent, outlet: 'wikiPageRoute' },
      { path: 'home', component: WikiIntroductionComponent, outlet: 'wikiPageRoute' },
      { path: 'create', component: WikiCreateEditComponent, outlet: 'wikiPageRoute' },
      { path: 'group-list', component: WikiGroupListComponent, outlet: 'wikiPageRoute' },
      { path: 'page-list', component: WikiListComponent, outlet: 'wikiPageRoute' },
      { path: 'edit/:wikiID', component: WikiCreateEditComponent, outlet: 'wikiPageRoute' },
      { path: 'edit-code/:code', component: WikiCreateEditComponent, outlet: 'wikiPageRoute' },
      { path: 'view-code/:code', component: WikiViewComponent, outlet: 'wikiPageRoute' },
      { path: 'view/:wikiID', component: WikiViewComponent, outlet: 'wikiPageRoute' },
      { path: 'search/:search', component: WikiSearchComponent, outlet: 'wikiPageRoute' },
      { path: 'tag/:tag', component: WikiSearchComponent, outlet: 'wikiPageRoute' },
      { path: 'group/:groupID', component: WikiGroupViewComponent, outlet: 'wikiPageRoute' },
      { path: 'group-code/:code', component: WikiGroupViewComponent, outlet: 'wikiPageRoute' },
      { path: 'redirection', component: WikiRedirectionComponent, outlet: 'wikiPageRoute' },
      { path: 'redirection/:link', component: WikiRedirectionComponent, outlet: 'wikiPageRoute' },
      { path: 'redirection/:link/:param', component: WikiRedirectionComponent, outlet: 'wikiPageRoute' },
    ],
  },
  {
    path: 'WikiGroupEditComponent',
    component: WikiGroupEditComponent,
  },

  {
    path: 'WikiGroupViewListComponent',
    component: WikiGroupViewListComponent,
  },

  {
    path: 'WikiViewComponent',
    component: WikiViewComponent,
  },

  {
    path: 'WikiCreateEditComponent/:wikiID',
    component: WikiCreateEditComponent,
  },

  {
    path: 'WikiGroupListComponent',
    component: WikiGroupListComponent,
  },

  {
    path: 'WikiCreateEditComponent/code/:code',
    component: WikiCreateEditComponent,
  },
  {
    path: 'WikiViewComponent/:wikiID',
    component: WikiViewComponent,
  },

  {
    path: 'WikiViewComponent/code/:code',
    component: WikiViewComponent,
  },
  {
    path: 'WikiCreateEditComponent',
    component: WikiCreateEditComponent,
  },
  {
    path: 'WikiListComponent',
    component: WikiListComponent,
  },
  {
    path: 'WikiFooterComponent',
    component: WikiFooterComponent,
  },

  {
    path: 'WikiHeaderComponent',
    component: WikiHeaderComponent,
  },
  {
    path: 'WikiLeftMenuComponent',
    component: WikiLeftMenuComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WikiRoutingModule {}
