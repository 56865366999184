import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { TreatmentService } from '../shared/treatment.service';

import { environment } from 'environments/environment';
import { EpDocumentViewComponent } from '../../../shared/components/ep-document-view/ep-document-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { TreatmentBrandViewComponent } from '../treatment-brand-view/treatment-brand-view.component';
import { TreatmentCategoryViewComponent } from '../treatment-category-view/treatment-category-view.component';
import { TreatmentProductViewComponent } from '../treatment-product-view/treatment-product-view.component';
import { TreatmentTypeViewComponent } from '../treatment-type-view/treatment-type-view.component';

@Component({
  selector: 'app-treatment-document-edit',
  templateUrl: './treatment-document-edit.component.html',
  styleUrls: ['./treatment-document-edit.component.css'],
})
export class TreatmentDocumentEditComponent implements OnInit {
  @Input()
  treatmentDocumentID;

  treatmentDocument;

  @Output()
  close = new EventEmitter();

  @Output()
  result = new EventEmitter();

  categoryID;
  typeID;
  brandID;
  productID;

  categories;
  types;
  brands;
  products;

  documentID;

  documentLabel;
  documentDescription;

  isModal = false;
  isAdminOrPromoter = false;

  isRequired = false;

  thumbnailFileID;
  newThumbnailPicture;

  constructor(
    private authenticationService: AuthenticationService,
    private treatmentService: TreatmentService,
    private activeRoute: ActivatedRoute,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.treatmentDocumentID) {
        this.treatmentDocumentID = data.treatmentDocumentID;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['treatmentDocumentID']) {
        this.treatmentDocumentID = params['treatmentDocumentID'];
      }
      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;

        this.setup();
      });
    });
  }

  setup() {
    if (this.treatmentDocumentID) {
      this.treatmentService.getTreatmentDocumentDetail(this.treatmentDocumentID).subscribe((res) => {
        if (res) {
          this.treatmentDocument = res;

          const payload = {
            fields: 'ID,Label',
            isActive: true,
          };

          this.treatmentService.getTreatmentCategoryList(payload, this.isAdminOrPromoter).subscribe((categories) => {
            this.categories = categories;

            this.documentDescription = this.treatmentDocument['Document.Description'];
            this.documentLabel = this.treatmentDocument['Document.Label'];
            this.brandID = this.treatmentDocument['TreatmentBrand_key'];
            this.productID = this.treatmentDocument['TreatmentProduct_key'];

            this.documentID = this.treatmentDocument['Document_key'];

            if (this.treatmentDocument['Mandatory.PromoterDefault'] === '1') {
              this.isRequired = true;
            } else {
              this.isRequired = false;
            }

            if (this.productID) {
              const payload = {
                fields: 'Type_key,Brand_key,Category_key',
                isActive: true,
              };
              this.treatmentService.getTreatmentProductDetailsGlobal(this.productID, payload).subscribe((res) => {
                if (res) {
                  this.categoryID = res['Category_key'];
                  this.typeID = res['Type_key'];
                  this.brandID = res['Brand_key'];

                  if (this.categoryID) {
                    this.onSelectSimpleCategory(this.categoryID, true);
                  }
                }
              });
            } else if (this.brandID) {
              const payload = {
                fields: 'Type_key,Category_key',
                isActive: true,
              };
              this.treatmentService.getTreatmentBrandDetailsGlobal(this.brandID, payload).subscribe((res) => {
                if (res) {
                  this.categoryID = res['Category_key'];
                  this.typeID = res['Type_key'];

                  if (this.categoryID) {
                    this.onSelectSimpleCategory(this.categoryID, true);
                  }
                }
              });
            }
          });
        }
      });
    }
  }

  onSelectSimpleCategory(simpleCategory, setup = false) {
    if (simpleCategory) {
      const payload = {
        fields: 'ID,Label',
        isActive: true,
        categoryID: simpleCategory,
      };

      this.treatmentService.getTreatmentTypeList(payload).subscribe((res) => {
        this.types = res;

        if (setup == true && this.typeID) {
          this.onSelectSimpleType(this.typeID, true);
        }
      });
    }
  }

  onSelectSimpleType(simpleType, setup = false) {
    if (simpleType) {
      let payload: any = {
        isActive: true,
        fields: 'ID,Label',
        typeID: simpleType,
        isNullProduct: false,
        isNullBrand: false,
      };

      if (this.isAdminOrPromoter == true) {
        payload = {
          isActive: true,
          typeID: simpleType,
          fields: 'ID,Label',
          isNullProduct: false,
          isNullBrand: false,
        };
      }

      this.treatmentService.getTreatmentBrandListGlobal(payload).subscribe((res) => {
        if (res && res.length > 0) {
          this.brands = res;

          if (setup == true && this.brandID) {
            this.onSelectSimpleBrand(this.brandID, true);
          }
        }
      });
    }
  }

  onSelectSimpleBrand(simpleBrand, setup = false) {
    if (simpleBrand) {
      let payload: any = {
        isActive: true,
        fields: 'ID,Label',
        brandID: simpleBrand,
        isNullProduct: false,
        isNullBrand: false,
      };

      if (this.isAdminOrPromoter == true) {
        payload = {
          isActive: true,
          fields: 'ID,Label',
          brandID: simpleBrand,
          isNullProduct: false,
          isNullBrand: false,
        };
      }

      this.treatmentService.getTreatmentProductList(payload, this.isAdminOrPromoter).subscribe((res) => {
        if (res && res.length > 0) {
          this.products = res.map((item) => {
            let _id = item.ID;
            if (_id.indexOf(':') != -1) {
              _id = _id.substring(0, _id.indexOf(':'));
            }
            const _item = {
              ID: _id,
              Label: item.Label,
            };
            return _item;
          });
        }
      });
    }
  }

  viewType(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(TreatmentTypeViewComponent, {
        data: {
          typeID: id,
          isGlobal: true,
        },

        width: '700px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewCategory(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(TreatmentCategoryViewComponent, {
        data: {
          categoryID: id,
        },

        width: '700px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewBrand(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(TreatmentBrandViewComponent, {
        data: {
          brandID: id,
          isGlobal: true,
        },

        width: '700px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewProduct(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(TreatmentProductViewComponent, {
        data: {
          productID: id,
          isGlobal: true,
        },

        width: '800px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  closeModal() {
    this.close.emit(true);
  }

  viewDocument() {
    const ref = RootAppComponent.dialog.open(EpDocumentViewComponent, {
      data: {
        documentID: this.documentID,
      },
      width: '800px',
      panelClass: 'noCard',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  editDocument() {
    const payload = {
      label: this.documentLabel,
      description: this.documentDescription,
      brandID: this.brandID,
      productID: this.productID,
      thumbnailFileID: this.thumbnailFileID,
      isRequired: this.isRequired,
    };

    this.treatmentService.editTreatmentDocumentGlobal(this.treatmentDocumentID, payload).subscribe((res) => {
      if (res) {
        this.result.emit(res);

        NotifyAppComponent.displayToast('success', 'Successful operation', 'Document has been successfully added');
      }
    });
  }

  onCompleteAllFile(e) {
    if (e && e[0]) {
      this.thumbnailFileID = e[0];
      this.newThumbnailPicture = 'url(' + environment.nodeUrl + '/files/file-view/' + this.thumbnailFileID + ')';
    }
  }

  removeNewTreatmentDocument(e) {
    if (e && e._id == this.thumbnailFileID) {
      this.thumbnailFileID = null;
    }
  }
}
