import { Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-invitation-clone-info',
  templateUrl: './invitation-clone-info.component.html',
  styleUrls: ['./invitation-clone-info.component.css'],
})
export class InvitationCloneInfoComponent implements OnInit {
  InvitationCloneInfo = JSON.parse(localStorage.getItem('InvitationCloneInfo'));

  closeModal = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  doNotShow() {
    localStorage.setItem('InvitationCloneInfo', JSON.stringify(this.InvitationCloneInfo));

    this.closeModal.emit(true);
  }

  close() {
    this.closeModal.emit(true);
  }
}
