import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { LookupService } from '../../../shared/services/lookup.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { BeneficiaryViewComponent } from '../../beneficiary/beneficiary-view/beneficiary-view.component';
import { TreatmentService } from '../../treatment/shared/treatment.service';
import { DrugsViewComponent } from '../drugs-view/drugs-view.component';
import { DrugsService } from '../shared/drugs.service';

@Component({
  selector: 'drugs-create-edit',
  templateUrl: './drugs-create-edit.component.html',
  styleUrls: ['./drugs-create-edit.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class DrugsCreateEditComponent implements OnInit {
  closeModal = new EventEmitter();
  manufacturers = [];
  distributors = [];
  @Input()
  drugID = '';
  drug = {
    name: null,
    drugTypeCode: null,
    doseTypeCode: null,
    sideEffects: [],
    doseUnitCode: null,
    illnesses: [],
    allergies: [],
    manufacturerID: null,
    distributorID: null,
    description: null,
    generalInformation: null,
    warnings: null,
    ingredients: null,
    directions: null,
    imageID: null,
    dosageOptions: null,
    thumbnailID: null,
    isActive: true,
    DateTimeCreated: null,
  };
  isEditMode;
  isDone;
  isModal = false;
  isAdminOrPromoter = false;
  imageLink;
  thumbnailLink;
  imageSrc;
  thumbnailSrc;
  isImageFile = false;
  isThumbnailFile = false;
  drugTypes = [];
  doseTypes = [];
  doseUnitTypes = [];
  allergyTypes = [];
  illnessTypes = [];
  drugSideEffects = [];
  sessionType;

  constructor(
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private lookupService: LookupService,
    private utilsService: UtilsService,
    private router: Router,
    private drugService: DrugsService,
    private treatmentService: TreatmentService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.drugID) {
        this.drugID = data.drugID;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['drugID']) {
        this.drugID = params['drugID'];
      }
      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;
        this.authenticationService.getSessionType().subscribe((s) => {
          this.sessionType = s;
          this.setup();
        });
      });
    });
  }

  setup() {
    if (this.drugID) {
      this.isEditMode = true;

      this.getImage();
      this.getThumbnail();
      this.drugService.getDrugDetails(this.drugID).subscribe((res) => {
        if (res) {
          this.fillData(res);
          this.getSuppliers();
          this.getlookups(true);
        }
      });
    } else {
      this.getSuppliers();
      this.getlookups();
    }
  }

  getlookups(isEdit = false) {
    this.lookupService.getLookup('CodeLookup', 'DrugType').subscribe((res) => {
      this.drugTypes = res;
    });
    this.lookupService.getLookup('CodeLookup', 'DoseType').subscribe((res) => {
      this.doseTypes = res;
    });
    this.lookupService.getLookup('CodeLookup', 'DoseUnit').subscribe((res) => {
      this.doseUnitTypes = res;
    });
    if (isEdit == false) {
      this.lookupService.getLookup('CodeLookup', 'Illness').subscribe((res) => {
        this.illnessTypes = res;
      });
      this.lookupService.getLookup('CodeLookup', 'DrugsSideEffect').subscribe((res) => {
        this.drugSideEffects = res;
      });
    }
    this.drugService.getAllergyCategoryList().subscribe((res) => {
      this.allergyTypes = res;
    });
  }

  fillData(res) {
    if (this.drugID && res) {
      if (res['IsActive'] == '1') {
        this.drug.isActive = true;
      }
      if (res['IsActive'] == '0') {
        this.drug.isActive = false;
      }
      if (res['Name']) {
        this.drug.name = res['Name'];
      }
      if (res['Description']) {
        this.drug.description = res['Description'];
      }
      if (res['Manufacturer_Key']) {
        this.drug.manufacturerID = res['Manufacturer_Key'];
      }
      if (res['DateTimeCreated']) {
        this.drug.DateTimeCreated = res['DateTimeCreated'];
      }

      if (res['Distributor_Key']) {
        this.drug.distributorID = res['Distributor_Key'];
      }
      if (res['DrugType.Code']) {
        this.drug.drugTypeCode = res['DrugType.Code'];
      }
      if (res['DoseType.Code']) {
        this.drug.doseTypeCode = res['DoseType.Code'];
      }
      if (res['DoseUnit.Code']) {
        this.drug.doseUnitCode = res['DoseUnit.Code'];
      }
      if (res['GeneralInformation']) {
        this.drug.generalInformation = res['GeneralInformation'];
      }
      if (res['Warnings']) {
        this.drug.warnings = res['Warnings'];
      }
      if (res['Ingredients']) {
        this.drug.ingredients = res['Ingredients'];
      }
      if (res['Directions']) {
        this.drug.directions = res['Directions'];
      }
      if (res['DosageOptions']) {
        this.drug.dosageOptions = res['DosageOptions'];
      }
      const drugSideEffectPayload = {
        drugID: this.drugID,
        fields: 'Usage.Code,IllnessOrAllergy',
      };

      this.drugService.getDrugUsage(drugSideEffectPayload).subscribe((res) => {
        if (res) {
          for (let i = 0; i < res.length; i++) {
            if (res[i] && res[i]['Usage.Code'] && res[i]['IllnessOrAllergy'] == 'A') {
              this.drug.allergies.push(res[i]['Usage.Code']);
            } else if (res[i] && res[i]['Usage.Code'] && res[i]['IllnessOrAllergy'] == 'I') {
              this.drug.illnesses.push(res[i]['Usage.Code']);
            }
          }
        }

        this.lookupService.getLookup('CodeLookup', 'Illness').subscribe((res) => {
          this.illnessTypes = res;
        });
      });
      const drugUsagePayload = {
        fields: 'SideEffect.Code',
        drugID: this.drugID,
      };

      this.drugService.getDrugSideEffect(drugUsagePayload).subscribe((res) => {
        if (res) {
          for (let i = 0; i < res.length; i++) {
            if (res[i] && res[i]['SideEffect.Code']) {
              this.drug.sideEffects.push(res[i]['SideEffect.Code']);
            }
          }
        }
        this.lookupService.getLookup('CodeLookup', 'DrugsSideEffect').subscribe((DrugsSideEffect) => {
          this.drugSideEffects = DrugsSideEffect;
        });
      });
    }
  }

  selectedArray(array, code) {
    if (array && array.length > 0 && code) {
      if (array.indexOf(code) != -1) {
        return true;
      }
    }

    return false;
  }

  consoleLog(d) {
    if (d) {
      console.log(d);
    }
  }

  getSuppliers() {
    const payload = {
      typeCode: 'MANU',
      orderBy: 'CalculatedName',
      fields: 'ID,Name',
      isActive: true,
    };
    this.treatmentService.getSupplierList(payload, this.isAdminOrPromoter).subscribe((res) => {
      this.manufacturers = res;
    });
    const payload2 = {
      typeCode: 'DISTR',
      orderBy: 'CalculatedName',
      fields: 'ID,Name',
      isActive: true,
    };
    this.treatmentService.getSupplierList(payload2, this.isAdminOrPromoter).subscribe((res) => {
      this.distributors = res;
    });
  }

  changeImageImage() {
    this.utilsService.getFileDetails(this.drug.imageID).subscribe((res) => {
      if (res) {
        if (res.contentType == 'image/png' || res.contentType == 'image/jpg' || res.contentType == 'image/jpeg') {
          this.isImageFile = true;
          this.imageSrc = `${environment.nodeUrl}/files/file-view/${this.drug.imageID}`;
        } else {
          NotifyAppComponent.displayToast(
            'warning',
            'Error during operation',
            'Format of image should be png/jpg/jpeg'
          );
        }
      }
    });
  }

  changeThumbnail() {
    this.utilsService.getFileDetails(this.drug.thumbnailID).subscribe((res) => {
      if (res) {
        if (res.contentType == 'image/png' || res.contentType == 'image/jpg' || res.contentType == 'image/jpeg') {
          this.isThumbnailFile = true;
          this.thumbnailSrc = `${environment.nodeUrl}/files/file-view/${this.drug.thumbnailID}`;
        } else {
          NotifyAppComponent.displayToast(
            'warning',
            'Error during operation',
            'Format of image should be png/jpg/jpeg'
          );
        }
      }
    });
  }

  onCompleteImageAll(fileID) {
    if (fileID && fileID[0]) {
      this.drug.imageID = fileID[0];
      this.changeImageImage();
    }
  }

  onCompleteThumbnailAll(fileID) {
    if (fileID && fileID[0]) {
      this.drug.thumbnailID = fileID[0];
      this.changeThumbnail();
    }
  }

  viewSupplier(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(BeneficiaryViewComponent, {
        data: id,
        width: '600px',
        panelClass: 'noCard',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  save() {
    if (this.drug) {
      const payload = {
        name: null,
        drugTypeCode: null,
        doseTypeCode: null,
        dosageOptions: null,
        doseUnitCode: null,
        sideEffects: null,
        illnesses: null,
        allergies: null,
        isActive: null,
        manufacturerID: null,
        distributorID: null,
        description: null,
        generalInformation: null,
        warnings: null,
        ingredients: null,
        directions: null,
        imageID: null,
        thumbnailID: null,
      };
      if (this.drug.name) {
        payload.name = this.drug.name;
      }
      if (this.drug.isActive != null) {
        payload.isActive = this.drug.isActive;
      }
      if (this.drug.drugTypeCode) {
        payload.drugTypeCode = this.drug.drugTypeCode;
      }
      if (this.drug.doseTypeCode) {
        payload.doseTypeCode = this.drug.doseTypeCode;
      }
      if (this.drug.doseUnitCode) {
        payload.doseUnitCode = this.drug.doseUnitCode;
      }
      if (this.drug.dosageOptions) {
        payload.dosageOptions = this.drug.dosageOptions;
      }
      if (this.drug.sideEffects && this.drug.sideEffects.length > 0) {
        payload.sideEffects = this.drug.sideEffects.join(',');
      }
      if (this.drug.illnesses && this.drug.illnesses.length > 0) {
        payload.illnesses = this.drug.illnesses.join(',');
      }
      if (this.drug.allergies && this.drug.allergies.length > 0) {
        payload.allergies = this.drug.allergies.join(',');
      }
      if (this.drug.manufacturerID) {
        payload.manufacturerID = this.drug.manufacturerID;
      }
      if (this.drug.distributorID) {
        payload.distributorID = this.drug.distributorID;
      }
      if (this.drug.description) {
        payload.description = this.drug.description;
      }
      if (this.drug.generalInformation) {
        payload.generalInformation = this.drug.generalInformation;
      }
      if (this.drug.warnings) {
        payload.warnings = this.drug.warnings;
      }
      if (this.drug.ingredients) {
        payload.ingredients = this.drug.ingredients;
      }
      if (this.drug.directions) {
        payload.directions = this.drug.directions;
      }
      if (this.drug.imageID) {
        payload.imageID = this.drug.imageID;
      }
      if (this.drug.doseUnitCode) {
        payload.doseUnitCode = this.drug.doseUnitCode;
      }
      if (this.drug.thumbnailID) {
        payload.thumbnailID = this.drug.thumbnailID;
      }
      if (this.drugID) {
        this.drugService.editDrug(this.drugID, payload).subscribe((res) => {
          if (res && res.ID) {
            this.drugID = res.ID;
            this.isDone = true;
            NotifyAppComponent.displayToast('success', 'Successful operation', 'Medication has been updated');
          }
        });
      } else {
        this.drugService.createDrug(payload, this.sessionType).subscribe((res) => {
          if (res && res.ID) {
            this.drugID = res.ID;
            this.isDone = true;
            if (this.sessionType != 'promoter' && this.sessionType != 'admin') {
              NotifyAppComponent.displayToast(
                'success',
                'Successful operation',
                'Your request has been sent and it will be reviewed'
              );
            } else {
              NotifyAppComponent.displayToast('success', 'Successful operation', 'Medication has been created');
            }
          }
        });
      }
    }
  }

  // viewPage() {
  //   if (this.drugID) {
  //     this.router.navigate([
  //       "/merchant",
  //       { outlets: { 'page': ['drugs', "drug-view", this.drugID] } }
  //     ]);
  //   }
  // }

  viewPage() {
    if (this.drugID) {
      const ref = RootAppComponent.dialog.open(DrugsViewComponent, {
        data: {
          drugID: this.drugID,
        },
        width: '800px',
      });
      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
    }
  }

  closeEvent() {
    this.closeModal.emit(true);
  }

  list() {
    this.router.navigate(['/merchant', { outlets: { page: ['drugs', 'drug-list'] } }]);
  }

  getImage() {
    this.imageLink = null;

    this.imageLink = this.drugService.getDrugImageStreamLink(this.drugID);
  }

  getThumbnail() {
    this.thumbnailLink = null;

    this.thumbnailLink = this.drugService.getDrugThumbnailStreamLink(this.drugID);
  }
}
