import { Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-intro-modal',
  templateUrl: './patient-intro-modal.component.html',
  styleUrls: ['./patient-intro-modal.component.css'],
})
export class PatientIntroModalComponent implements OnInit {
  showOnPatientFirstVisit = JSON.parse(localStorage.getItem('showOnPatientFirstVisit'));

  closeModal = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  doNotShow() {
    localStorage.setItem('showOnPatientFirstVisit', JSON.stringify(this.showOnPatientFirstVisit));

    this.closeModal.emit(this.showOnPatientFirstVisit);
  }
}
