import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export class ContainerModalConfig {
  customClass?: string;
  enableDragging?: boolean;
}

@Component({
  selector: 'ipv-container-modal',
  templateUrl: './container-modal.component.html',
  styleUrls: ['./container-modal.component.css'],
})
export class ContainerModalComponent implements OnInit {
  @Output() closed: EventEmitter<void> = new EventEmitter();

  @Input() customClass: null | string = null;
  classNames = {};

  @Input() enableDragging = false;

  constructor(@Inject(MAT_DIALOG_DATA) private data: ContainerModalConfig) {
    if (this.data) {
      this.customClass = this.data.customClass ? this.data.customClass : this.customClass;
      this.enableDragging = this.data.enableDragging ? this.data.enableDragging : this.enableDragging;
    }

    const classNamesString = `{"${this.customClass}":true}`;

    this.classNames = JSON.parse(classNamesString);
  }

  ngOnInit() {}

  close() {
    this.closed.emit();
  }
}
