<div class="full-width clearfix flex row">
  <div class="row ml setting-container mr">
    <instant-offer-settings
      [submitLabel]="'Save & Preview'"
      (getResult)="getResult($event)"
      (getTheme)="getTheme($event)"
    ></instant-offer-settings>
  </div>
  <div class="iframe-container page-container row">
    <div class="row clearfix full-width flex full-height templateViewArea">
      <iframe
        class="full-width preview-iframe"
        *ngIf="iFrameSrcSecured && hideIframe != true"
        [src]="iFrameSrcSecured"
        (load)="onFrameLoad($event)"
      ></iframe>

      <div class="full-width open-new-tab text-center">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Preview {{ "KEYWORD.treatment" | translate | titlecase }} Value</mat-label>
          <input
            [(ngModel)]="treatmentAmountPreview"
            name="treatmentAmount"
            matInput
            placeholder="Please enter a value "
            currencyMask
          />
        </mat-form-field>

        <hr/>
        <div class="full-width text-center">
          <button class="btn-large pull-right smt" (click)="refresh()" mat-raised-button color="accent">Refresh</button>

          <button
            class="btn-large pull-left smt"
            [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
            (click)="openNewTab()"
            mat-raised-button
            color="accent"
          >
            Open in new tab
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
