import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

import { ProductService } from '../shared/product.service';

@Component({
  selector: 'app-product-view-modal',
  templateUrl: './product-view-modal.component.html',
  styleUrls: ['./product-view-modal.component.css'],
})
export class ProductViewModalComponent implements OnInit {
  util = new UtilsClass();

  @Input() product;
  @Input() productID;

  @Output()
  close: EventEmitter<any> = new EventEmitter();

  step;
  descriptions = [];
  isModal = false;
  //  product = {

  //   ID : "A079981E4C734617AF49472E3B56EDFB"
  //  };
  constructor(
    private productService: ProductService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (typeof data == 'object') {
        this.product = data;
      } else {
        this.productID = data;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    if (this.productID) {
      this.productService.getProdDetails(this.productID).subscribe((res) => {
        this.product = res;
        this.descriptions = this.product['Description'].split('- ').slice(3);
      });
    } else {
      this.productService.getProdDetails(this.product.ID).subscribe((res) => {
        this.product = res;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.productID) {
      this.productService.getProdDetails(this.productID).subscribe((res) => {
        this.product = res;
        this.descriptions = this.product['Description'].split('- ').slice(3);
      });
    } else {
      this.productService.getProdDetails(this.product.ID).subscribe((res) => {
        this.product = res;
      });
    }
  }

  formatTermsAndConditions2(text) {
    let formattedText = '';

    formattedText = this.util.formatTermsAndConditions(text);
    return formattedText;
  }

  closeEvent() {
    this.close.emit(true);
  }

  setStep(index) {
    this.step = index;
  }
}
