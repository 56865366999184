import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EditorModule } from '@tinymce/tinymce-angular';
import { SharedModule } from '../../shared/shared.module';
import { CustomerProspectService } from '../customer-prospect/shared/customerProspect.service';
import { MessageModule } from '../message/message.module';
import { OperatorModule } from '../operator/operator.module';
import { OperatorService } from '../operator/shared/operator.service';
import { NoteTypeFormModal } from './components/notes-settings/modals/note-type-form/note-type-form.modal';
import { NotesSettingsPage } from './components/notes-settings/notes-settings.page';
import { NotesModal } from './modals/notes-modal/notes.modal';
import { NoteFormComponent } from './modals/notes-modal/shared/components/note-form/note-form.component';
import { NoteInputComponent } from './modals/notes-modal/shared/components/note-form/shared/components/note-input/note-input.component';
import { NoteTemplateFormModalComponent } from './modals/notes-modal/shared/components/note-form/shared/components/note-input/shared/modals/note-template-form-modal.component';
import { NoteTemplateModalComponent } from './modals/notes-modal/shared/components/note-form/shared/components/note-input/shared/modals/note-template-modal.component';
import { NoteTextModalComponent } from './modals/notes-modal/shared/components/note-form/shared/components/note-input/shared/modals/note-text-modal.component';
import { NoteTimerComponent } from './modals/notes-modal/shared/components/note-form/shared/components/note-timer/note-timer.component';
import { NoteListHeaderComponent } from './modals/notes-modal/shared/components/note-list-header/note-list-header.component';
import { NoteListComponent } from './modals/notes-modal/shared/components/note-list/note-list.component';
import { NotePatientListComponent } from './modals/notes-modal/shared/components/note-patient-list/note-patient-list.component';
import { NoteTemplatesComponent } from './modals/notes-modal/shared/components/note-templates/note-templates.component';
import { NotesService } from './shared/services/notes.service';
import { CardModule } from '../card/card.module';

@NgModule({
  imports: [CommonModule, SharedModule, MessageModule, EditorModule, OperatorModule ,
    CardModule],
  declarations: [
    NotesModal,
    NoteInputComponent,
    NotePatientListComponent,
    NoteListHeaderComponent,
    NoteListComponent,
    NoteFormComponent,
    NoteTextModalComponent,
    NoteTemplateModalComponent,
    NoteTimerComponent,
    NoteTemplateFormModalComponent,
    NotesSettingsPage,
    NoteTypeFormModal,
    NoteTemplatesComponent,
  ],
  entryComponents: [
    NotesModal,
    NoteTextModalComponent,
    NoteTemplateModalComponent,
    NoteTemplateFormModalComponent,
    NoteTypeFormModal,
  ],
  providers: [NotesService, OperatorService, CustomerProspectService],
  exports: [NotesSettingsPage],
})
export class NotesModule {}
