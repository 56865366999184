import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ClipboardService } from 'ngx-clipboard';
import { DentistViewModalComponent } from '../../../feature/dentist/dentist-view-modal/dentist-view-modal.component';
import { MerchantViewComponent } from '../../../feature/merchant/merchant-view/merchant-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { ThirdPartyService } from '../shared/third-party.service';

@Component({
  selector: 'app-third-party-access-view',
  templateUrl: './third-party-access-view.component.html',
  styleUrls: ['./third-party-access-view.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.stacked-card-view', style({ opacity: 0 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.stacked-card-view', style({ opacity: 1 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ThirdPartyAccessViewComponent implements OnInit {
  @Input()
  thirdPartyAccessID;

  @Input()
  thirdPartyAccess;
  @Output() close = new EventEmitter();
  sessionType;
  @Output() getViewTemplate = new EventEmitter();
  isViewTemplate = false;

  constructor(
    private _clipboardService: ClipboardService,
    private authenticationService: AuthenticationService,
    private ThirdPartyService: ThirdPartyService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.thirdPartyAccessID) {
        this.thirdPartyAccessID = data.thirdPartyAccessID;
      }
      if (data.isViewTemplate !== null) {
        this.isViewTemplate = data.isViewTemplate;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((s) => {
      this.sessionType = s;

      if (this.thirdPartyAccessID) {
        this.ThirdPartyService.getThirdPartyAccessDetails(this.thirdPartyAccessID, {}, this.sessionType).subscribe(
          (res) => {
            if (res) {
              this.thirdPartyAccess = res;
            }
          }
        );
      }
    });
  }

  closeEvent() {
    this.close.emit(true);
  }

  openLink(d) {
    if (d) {
      if (d.indexOf('http') === -1) {
        const _d = 'https://' + d;
        window.open(_d, '_blank');
      } else {
        window.open(d, '_blank');
      }
    }
  }

  copy(d) {
    if (d) {
      this._clipboardService.copy(d);
      NotifyAppComponent.displayToast('success', 'Success', 'Copied');
    }
  }

  viewTemplate(id) {
    if (id) {
      this.getViewTemplate.emit(id);
    }
  }

  viewMerchant(ID) {
    if (ID) {
      const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
        data: ID,
        width: '600px',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewDentist(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(DentistViewModalComponent, {
        data: id,
        width: '550px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }
}
