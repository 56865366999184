<div class="row clearfix">
  <div class="pull-left">
    <h3 class="sr-title sm-h3 rm-mt rm-mb">{{ "KEYWORD.treatment" | translate | titlecase }} Item Category</h3>
    <p class="rm-mt">View documents</p>
  </div>
  <div class="pull-right">
    <!-- Modal dismiss button -->
    <button class="btn-close btn-clear mat-button" matDialogClose>
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>
<hr/>
<!--{{ "KEYWORD.treatment" | translate | titlecase }} Category -->
<div class="row clearfix flex info">
  <label
  >Type: <strong>Type</strong></label
  >
  <label
  >Category <strong>Category</strong></label
  >
  <label
  >Brand: <strong>Brand</strong></label
  >
</div>
<hr class="mb"/>
<div class="row clearfix">
  <div class="icons-col">
    <mat-icon>description</mat-icon>
  </div>
  <div class="row-content-col row clearfix">
    <label>Documents</label>
    <!-- DISPLAY ANY DEFAULT DOC FOR PRODUCT IN EMBED BELOW, SAME METHOD FOR CONTRACT ETC MODAL IN APPLICATION -->
    <embed />
  </div>
</div>
