import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { LookupService } from '../../../shared/services/lookup.service';
import { DrugsCustomerViewComponent } from '../drugs-customer-view/drugs-customer-view.component';
import { DrugsViewComponent } from '../drugs-view/drugs-view.component';
import { DrugsService } from '../shared/drugs.service';

@Component({
  selector: 'drugs-create-edit-customer',
  templateUrl: './drugs-create-edit-customer.component.html',
  styleUrls: ['./drugs-create-edit-customer.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class DrugsCreateEditCustomerComponent implements OnInit {
  @Input()
  illnessID;

  @Input()
  medicalHistoryID;

  isModal = false;

  drugs = [];
  drugTypes = [];
  doseTypes = [];
  doseUnitTypes = [];
  drugFrequencies = [];

  sessionType;

  isPromoterOrAdmin = false;

  manualEntry = false;
  serviceRef;

  selectedDrugDoseType;
  selectedDrugDoseAmount;
  selectedDrugType;
  selectedDrug;
  drugsSearchControl;

  drug = {
    id: null,
    name: null,
    drugType: null,
    drugTypeOther: null,
    doseType: null,
    doseTypeOther: null,
    doseUnit: null,
    doseUnitOther: null,
    doseAmount: null,
    drugFrequency: null,
    note: null,
    drugName: null,
    drugID: null,
    startDate: null,
    endDate: null,
  };
  drugName;
  medications = [];

  constructor(
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private lookupService: LookupService,
    private drugService: DrugsService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.illnessID) {
        this.illnessID = data.illnessID;
        this.isModal = true;
      }

      if (data.medicalHistoryID) {
        this.medicalHistoryID = data.medicalHistoryID;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
        if (isPromoterOrAdmin) {
          this.isPromoterOrAdmin = isPromoterOrAdmin;
        }

        this.setup();
      });
    });
  }

  setup() {
    const payload = {
      fields: 'ID,Name,DrugType.Code',
      limit: 300,
      section: 0,
    };

    this.serviceRef = this.drugService.getDrugList(payload, this.sessionType).subscribe((res) => {
      if (res.length > 0) {
        this.drugs = this.drugs.concat(res);
      }
      const innerFunction = (section) => {
        section = section + 1;
        payload.section = section;

        this.serviceRef = this.drugService.getDrugList(payload, this.sessionType).subscribe((res) => {
          if (res.length > 0) {
            this.drugs = this.drugs.concat(res);

            if (res.length >= 400) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
      };

      innerFunction(1);
    });

    this.getlookups();
  }

  getlookups() {
    this.lookupService.getLookup('CodeLookup', 'DrugType').subscribe((res) => {
      this.drugTypes = res;
    });
    this.lookupService.getLookup('CodeLookup', 'DoseType').subscribe((res) => {
      this.doseTypes = res;
    });
    this.lookupService.getLookup('CodeLookup', 'DoseUnit').subscribe((res) => {
      this.doseUnitTypes = res;
    });

    this.lookupService.getLookup('CodeLookup', 'MedicationFrequency').subscribe((res) => {
      this.drugFrequencies = res;
    });
  }

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }

  selectDrug() {
    if (this.selectedDrug && this.selectedDrug.ID) {
      this.drugService.getDrugDetails(this.selectedDrug.ID).subscribe((res) => {
        if (res) {
          if (res.ID) {
            this.drug.drugID = res.ID;
          }

          if (res['Name']) {
            this.drug.name = res['Name'];
          }
          if (res['DrugType.Code']) {
            this.drug.drugType = res['DrugType.Code'];
          }
          if (res['DoseType.Code']) {
            this.drug.doseType = res['DoseType.Code'];
          }
          if (res['DoseUnit.Code']) {
            this.drug.doseUnit = res['DoseUnit.Code'];
          }
        }
      });
    }
  }

  addDrugToList() {}

  removeDrugFromList(id) {
    if (id) {
    }
  }

  openDrugViewDialog(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(DrugsCustomerViewComponent, {
        data: {
          drugID: id,
        },
        width: '600px',
      });
      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
    }
  }

  clearDrug() {
    this.drug = {
      id: null,
      name: null,
      drugType: null,
      drugTypeOther: null,
      doseType: null,
      doseTypeOther: null,
      doseUnit: null,
      doseUnitOther: null,
      doseAmount: null,
      drugFrequency: null,
      note: null,
      drugName: null,
      drugID: null,
      startDate: null,
      endDate: null,
    };
  }

  getOptionText(option) {
    if (option && option.Name) {
      return option.Name;
    }
  }

  addMedication() {
    if (this.drug) {
      this.drugService.createMedication(this.drug, this.sessionType).subscribe((res) => {
        if (res) {
          this.medications.push(res);

          this.clearDrug();
        }
      });
    }
  }

  viewDrug() {
    if (this.drug && this.drug.drugID) {
      const ref = RootAppComponent.dialog.open(DrugsViewComponent, {
        data: {
          drugID: this.drug.drugID,
        },
        width: '800px',
      });
      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
    }
  }

  getDateResult(e) {
    if (e) {
    }
  }
}
