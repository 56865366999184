import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class DentalXrService {
  constructor(private httpClient: HttpClient) {}

  analyzeAdmin(payload) {
    return this.httpClient.post(`${environment.nodeUrl}/dental-scanning/analyze-admin`, payload);
  }
}
