import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatSlideToggleChange, MatSort } from '@angular/material';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { AppointmentLookupCreateComponent } from '../appointment-lookup-create/appointment-lookup-create.component';
import {
  AppointmentLookupEditComponent,
  AppointmentLookupEditComponentDialogData,
} from '../appointment-lookup-edit/appointment-lookup-edit.component';
import { AppointmentService } from '../shared/appointment.service';
import { AppointmentLookupService } from '../shared/appointmentLookup.service';
import { AppointmentUtilClass } from '../shared/appointmentUtil';

@Component({
  selector: 'app-appointment-lookup-list',
  templateUrl: './appointment-lookup-list.component.html',
  styleUrls: ['./appointment-lookup-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('10ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-10%)', offset: 0 }),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('10ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                style({
                  opacity: 0,
                  transform: 'translateY(-10%)',
                  offset: 1.0,
                }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class AppointmentLookupListComponent implements OnInit {
  @Input() merchant;
  @Input() lookupType = 'AppointmentTypes';
  @Input() code = '';
  @Input() category;
  @Input() isActive: boolean;

  @Input() merchantID;

  @Output()
  selectCategory = new EventEmitter();

  title = 'Appointment Lookup';
  rows = [];
  displayedColumns = [
    'selectCheckBox',
    'Label',
    'Description',
    'Category',
    'Code',
    'Buffers_Pre',
    'Buffers_Post',
    'DefaultDuration',
    'DateTimeCreated',
    'Is_Active',
    'Actions',
  ];
  selectedIDs = [];
  isPromoterOrAdmin = false;
  sessionType;

  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;
  public listDB: LoadRecords | null;
  dataSource: ListDataSource | null;
  @ViewChild('input', { static: false }) input;
  destroyEvent = new EventEmitter();
  util = new UtilsClass();
  // runAction = new EventEmitter();
  appointmentUtil = new AppointmentUtilClass();
  settings = Settings.global;

  constructor(
    private appointmentService: AppointmentService,
    private appointmentLookupService: AppointmentLookupService,
    private authenticationService: AuthenticationService,
    private merchantService: MerchantService,
    private router: Router
  ) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      this.authenticationService.getSessionType().subscribe((sessionType) => {
        if (sessionType) {
          this.sessionType = sessionType;
        }

        this.fetchMerchant(this.isPromoterOrAdmin, this.merchantID, this.sessionType);
      });
    });
  }

  ngOnChanges() {
    if (this.sessionType && this.isPromoterOrAdmin !== null) {
      this.fetchMerchant(this.isPromoterOrAdmin, this.merchantID, this.sessionType);
    }
  }

  setFilter(event, field) {
    let filter;
    if (event !== null && event != undefined) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
  }

  onToggleIsActiveFilter(change: MatSlideToggleChange) {
    this.setFilter(change.checked ? '1' : '0', 'Is_Active');
  }

  isSelected(id) {
    if (!this.selectedIDs || this.selectedIDs.length <= 0) {
      return false;
    } else {
      if (this.selectedIDs.indexOf(id) != -1) {
        return true;
      }
    }

    return false;
  }

  subType(c) {
    if (c) {
      this.selectCategory.emit(c);
    }
  }

  select(id, isSelected) {
    if (isSelected == true && this.selectedIDs.indexOf(id) == -1) {
      this.selectedIDs.push(id);
    } else {
      const _index = this.selectedIDs.indexOf(id);
      this.selectedIDs.splice(_index, 1);
    }
  }

  selectAll() {
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      const o = this.dataSource.filteredData[i];

      if (o && o['ID'] && this.selectedIDs.indexOf(o['ID']) == -1) {
        this.selectedIDs.push(o['ID']);
      }
    }
  }

  selectPage() {
    const index = this.dataSource['_paginator']['index'] || 0;
    const pageSize = this.dataSource['_paginator']['pageSize'] || 0;

    for (let i = 0; i < (index + 1) * pageSize; i++) {
      const o = this.dataSource.filteredData[index * pageSize + i];

      if (o && o['ID'] && this.selectedIDs.indexOf(o['ID']) == -1) {
        this.selectedIDs.push(o['ID']);
      }
    }
  }

  unselect() {
    this.selectedIDs = [];
  }

  publicPage(key) {
    if (key && this.settings && this.settings['consumerFrontendLink']) {
      window.open(
        this.settings['consumerFrontendLink'] + '/consumer/(page:appointment-card/' + key + ')' + key,
        '_blank'
      );
    }
  }

  openQuickViewDialog(key) {}

  editAppointmentLookup(id) {
    const dialogData: AppointmentLookupEditComponentDialogData = {
      appointmentLookupID: id,
    };

    const ref = RootAppComponent.dialog.open(AppointmentLookupEditComponent, {
      data: dialogData,
      width: '700px',
    });

    ref.componentInstance.sendResult.subscribe((res) => {
      if (res) {
        this.getActionResult(res);
        ref.close();
      }
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  enableAppointmentLookup(item, isGroup = false) {
    const confirmDialogParams = {
      data: new ConfirmDialog(
        'done',
        'Are you sure?',
        '<p>Once the appointment is enable the patient will  be able to access to it and change it </p>',
        'No',
        'Yes'
      ),
    };
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, confirmDialogParams);
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        const payload: any = {
          isActive: true,
        };
        const itemID = isGroup === false ? item.ID : null;

        if (isGroup) {
          payload.ids = item;
        }

        this.appointmentLookupService.edit(itemID, payload, this.sessionType).subscribe((res) => {
          if (res) {
            this.getActionResult(res);
            if (isGroup) {
              if (res.length > 0) {
                NotifyAppComponent.displayToast('success', 'Enable appointments', 'Appointments are now enabled');
              } else {
                NotifyAppComponent.displayToast(
                  'warning',
                  'Enable appointments',
                  'None of the selected appointments can be enabled'
                );
              }
            } else {
              NotifyAppComponent.displayToast('success', 'Enable appointment', 'Appointment is now enabled');
            }
            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }

  disableAppointmentLookup(item, isGroup = false) {
    const confirmDialogParams = {
      data: new ConfirmDialog(
        'block',
        'Are you sure?',
        '<p>Once the appointment is disabled the patient will not be able to access to it or change it </p>',
        'No',
        'Yes'
      ),
    };
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, confirmDialogParams);
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        const payload: any = {
          isActive: false,
        };
        const itemID = isGroup === false ? item.ID : null;

        if (isGroup) {
          payload.ids = item;
        }
        this.appointmentLookupService.edit(itemID, payload, this.sessionType).subscribe((res) => {
          if (res) {
            this.getActionResult(res);
            if (isGroup) {
              if (res.length > 0) {
                NotifyAppComponent.displayToast('success', 'Disable appointments', 'Appointments are now disabled');
              } else {
                NotifyAppComponent.displayToast(
                  'warning',
                  'Disable appointments',
                  'None of the selected appointments can be disabled'
                );
              }
            } else {
              NotifyAppComponent.displayToast('success', 'Disable appointment', 'Appointment is now disabled');
            }
            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }

  removeAppointmentLookup(item, isGroup = false) {
    const id = isGroup ? item : item.ID;
    const confirmDialogParams = {
      data: new ConfirmDialog(
        'fas fa-info',
        'Are you sure?',
        '<p>Once an appointment type is removed it can not be used in appointments</p>',
        'No',
        'Yes'
      ),
    };
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, confirmDialogParams);
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        this.appointmentLookupService.delete(id).subscribe((res) => {
          if (res) {
            this.getActionResult(res);
            if (isGroup) {
              if (res.length > 0) {
                NotifyAppComponent.displayToast(
                  'success',
                  'Remove appointment types',
                  'Appointment types are now removed'
                );
              } else {
                NotifyAppComponent.displayToast(
                  'warning',
                  'Remove appointment types',
                  'None of the selected appointment types can be removed'
                );
              }
            } else {
              NotifyAppComponent.displayToast('success', 'Remove appointment type', 'Appointment type is now removed');
            }

            // if (item && item.Category == 'AppointmentTypes' && this.selectedAppointmentType && item.Code === this.selectedAppointmentType.Code) {

            //   this.subTypes = [];
            // }

            ref.close();
          }
        });
        ref.close();
      } else {
        ref.close();
      }
    });
  }

  createNewAppointmentLookup() {
    const dialogData: any = {
      lookupType: this.lookupType,
      categoryID: this.category,
    };

    if (this.category && this.category != 'AppointmentTypes' && this.category != 'BestAppointmentTime') {
      dialogData.type = 'subType';
    }

    if (this.category == 'AppointmentTypes' || this.category == 'BestAppointmentTime') {
      dialogData.lookupType = this.category;
    }

    const ref = RootAppComponent.dialog.open(AppointmentLookupCreateComponent, {
      data: dialogData,
      width: '900px',
    });

    ref.componentInstance.sendResult.subscribe((res) => {
      if (res) {
        // this.getActionResult(res);
        this.dataSource.addItem = res;
        this.unselect();
        ref.close();
      }
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  getActionResult(r) {
    if (r && Array.isArray(r) && r.length > 0) {
      for (const item of r) {
        if (typeof item === 'string') {
          this.dataSource.removeItem = item;
        } else {
          this.dataSource.replaceItem = item;
        }
      }

      this.unselect();
    } else if (r && r.ID) {
      this.dataSource.replaceItem = r;
    } else if (r && typeof r === 'string') {
      this.dataSource.removeItem = r;
    }
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }

  private fetchMerchant(isPromoterOrAdmin: boolean, merchantID: string, sessionType: any): void {
    // Update the title
    if (this.lookupType === 'AppointmentTypes') {
      this.title = 'Reasons for visiting';
    } else if (this.lookupType === 'BestAppointmentTime') {
      this.title = 'Best  contact times';
    } else if (this.category === 'AppointmentTypes') {
      this.title = 'Reasons for visiting';
    } else if (this.category && this.category !== 'AppointmentTypes') {
      this.title = `More details of ${this.category}`;
    } else {
      this.title = 'Reasons for visiting';
    }

    // Hiding Columns
    if (this.lookupType === 'AppointmentTypes') {
      // display all columns
      this.displayedColumns = [
        'selectCheckBox',
        'Label',
        'Description',
        'Category',
        'Code',
        'Buffers_Pre',
        'Buffers_Post',
        'DefaultDuration',
        'DateTimeCreated',
        'Is_Active',
        'Actions',
      ];
    } else if (this.lookupType === 'BestAppointmentTime') {
      this.displayedColumns = [
        'selectCheckBox',
        'Label',
        'Description',
        // 'Category',
        // 'Code',
        // 'Buffers_Pre',
        // 'Buffers_Post',
        // 'DefaultDuration',
        'DateTimeCreated',
        'Is_Active',
        'Actions',
      ];
    } else if (this.category) {
      this.displayedColumns = [
        'selectCheckBox',
        'Label',
        'Description',
        // 'Category',
        'Code',
        'Buffers_Pre',
        'Buffers_Post',
        'DefaultDuration',
        'DateTimeCreated',
        'Is_Active',
        'Actions',
      ];
    }

    // Loading MerchantID and Merchant
    if (isPromoterOrAdmin == true && merchantID) {
      this.merchantService.getMerchantDetails(merchantID, {}, sessionType).subscribe((merchant) => {
        if (merchant && merchant.ID) {
          this.updateListDBAndListSource(merchant, merchant.ID);
        }
      });
    } else {
      this.authenticationService.getCurrentPractice().subscribe((merchant) => {
        if (merchant && merchant.ID) {
          this.updateListDBAndListSource(merchant, merchant.ID);
        }
      });
    }
  }

  private updateListDBAndListSource(merchant: any, merchantID: any): void {
    this.merchant = merchant;
    this.merchantID = merchantID;

    const payload = {
      merchantID: this.merchantID,
      isActive: this.isActive,
      lookupType: this.lookupType,
      category: this.category,
      code: this.code,
    };

    this.listDB = new LoadRecords(this.appointmentLookupService, this.destroyEvent, payload, this.sessionType);
    this.dataSource = new ListDataSource(this.listDB, this.paginator, this.sort);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public records: any[];
  public count: any;
  maxSection = 20;
  sessionType;
  serviceRef;

  payload;

  get data(): any[] {
    return this.dataChange.value;
  }

  get firstLoadEvent() {
    return this.firstLoad;
  }

  firstLoad = false;
  isDestoyed = false;

  constructor(private appointmentLookupService: AppointmentLookupService, private destroyEvent, payload, sessionType) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    this.payload = payload;
    this.sessionType = sessionType;

    this.serviceRef = this.appointmentLookupService.list(payload, sessionType).subscribe((res) => {
      this.count = res.length;
      this.records = res;
      this.dataChange.next(this.records.slice());
      this.firstLoad = true;
      const innerFunction = (section) => {
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.appointmentLookupService.list(payload, sessionType).subscribe((res) => {
          if (res.length > 0) {
            this.count = res.count;
            this.records = this.records.concat(res);
            this.dataChange.next(this.records.slice());
            if (this.isDestoyed != true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };

      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class ListDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  set replaceItem(item) {
    const _index = this._listDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    this._listDatabase.data[_index] = item;
    this._listDatabase.dataChange.next(this._listDatabase.data);
  }

  set removeItem(id) {
    const data = this._listDatabase.data.filter((row) => row.ID != id);
    this._listDatabase.dataChange.next(data);
  }

  set addItem(res) {
    this._listDatabase.data.unshift(res);

    this._listDatabase.dataChange.next(this._listDatabase.data);
  }

  filteredData: any[] = [];
  utils = new UtilsClass();

  constructor(private _listDatabase: any, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._listDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._listDatabase.data.slice().filter((item: any) => {
          if (Array.isArray(this.field)) {
            let res = false;
            this.field.forEach((col) => {
              let f = '';
              f = item[col] || '';

              const searchStr = f.toLowerCase();
              res = res || searchStr.indexOf(this.filter.toLowerCase()) != -1;
            });
            return res;
          } else {
            let f = '';
            f = item[this.field] || '';

            const searchStr = f.toLowerCase();
            return searchStr.indexOf(this.filter.toLowerCase()) != -1;
          }
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'Label':
          [propertyA, propertyB] = [a['Label'], b['Label']];
          break;
        case 'Description':
          [propertyA, propertyB] = [a['Description'], b['Description']];
          break;

        case 'Lookup_Type':
          [propertyA, propertyB] = [a['Lookup_Type'], b['Lookup_Type']];
          break;

        case 'Category':
          [propertyA, propertyB] = [a['Category'], b['Category']];
          break;

        case 'Code':
          [propertyA, propertyB] = [a['Code'], b['Code']];
          break;

        case 'Buffers_Pre':
          [propertyA, propertyB] = [a['Buffers_Pre'], b['Buffers_Pre']];
          break;

        case 'Buffers_Post':
          [propertyA, propertyB] = [a['Buffers_Post'], b['Buffers_Post']];
          break;

        case 'DateTimeCreated':
          [propertyA, propertyB] = [
            moment(a['DateTimeCreated']).toDate().getTime(),
            moment(b['DateTimeCreated']).toDate().getTime(),
          ];
          break;

        case 'active':
          [propertyA, propertyB] = [a['Active'], b['Active']];
          break;
      }

      const valueA = isNaN(+propertyA) || propertyA === '' ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) || propertyB === '' ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
  }
}
