import { AuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider, } from 'angularx-social-login';
import { environment } from 'environments/environment';
const ɵ0 = GoogleLoginProvider.PROVIDER_ID, ɵ1 = FacebookLoginProvider.PROVIDER_ID;
const config = new AuthServiceConfig([
    {
        id: ɵ0,
        provider: new GoogleLoginProvider(environment.googleID),
    },
    {
        id: ɵ1,
        provider: new FacebookLoginProvider(environment.facebookID),
    },
]);
export function provideConfig() {
    return config;
}
export class AuthenticationModule {
}
export { ɵ0, ɵ1 };
