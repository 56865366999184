<div class="row full-width clearfix" *ngIf="contracts.length > 0">
  <div class="card-header primary-gradient-img clearfix inModal">
    <div class="row clearfix">
      <button class="btn-close btn-clear pull-right mat-button" *ngIf="isModal == true" (click)="closeModalEvent()">
        <mat-icon>clear</mat-icon>
      </button>

      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m">Please select a contract from the list below</h2>
      </div>
    </div>
  </div>

  <div
    class="customerRecord contract"
    *ngFor="let contract of contracts"
    @ngIfAnimation
    [ngClass]="{ activeExistingcustomer: selectedContract && selectedContract['ID'] == contract['ID'] }"
    (click)="selectContract(contract)"
  >
    <div class="row clearfix anim">
      <div class="row clearfix">
        <div class="half-width">
          <h3 class="sr-title rm-mb">{{ contract['ProductName'] }}</h3>
          <p class="small rm-m">
            <span class="labelName">
              Applied:
            </span>
            <span class="value">
              {{ contract['ApplicationDate'] | customDate }}
            </span>
          </p>
        </div>
        <div class="half-width text-right mt">
          <label class="labelName rm-mt">
            Available balance:
          </label>
          <p class="value rm-m" [ngStyle]="{ color: contract['Settlements.NotYetDrawn'] > 0 ? '#1ca90c' : 'red' }">
            <strong>{{ contract['Settlements.NotYetDrawn'] | customCurrency }}</strong>
          </p>
        </div>
      </div>

      <span *ngIf="selectedContract && selectedContract['ID'] == contract['ID']" @ngIfGrow>
        <hr class="animG"/>
        <div class="row clearfix animG">
          <div class="half-width">
            <p class="small rm-mt">
              <span class="labelName">
                Total amount financed:
              </span>
              <span class="value">
                {{ contract['Amount.Invoice'] | customCurrency }}
              </span>
            </p>
          </div>
          <div class="half-width">
            <div class="col-right">
              <p class="small rm-mt">
                <span class="labelName">
                  Paid/Approved for payment:
                </span>
                <span class="value">
                  {{ contract['Settlements.Drawn'] | customCurrency }}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="row clearfix full-width animG">
          <div class="half-width">
            <p class="small rm-mt">
              <span class="labelName">
                Settlement deadline:
              </span>
              <span
                class="value"
                *ngIf="contract['NextDrawDown.Before'] && contract['NextDrawDown.Before'] !== '0000-00-00'"
              >
                {{ contract['NextDrawDown.Before'] | customCurrency }}
              </span>
            </p>
          </div>
        </div>
      </span>
    </div>
  </div>

  <div class="mt text-center">
    <button class="drop-button" (click)="contractView()" mat-raised-button color="accent">View</button>
  </div>
</div>
