/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./appointment-archive.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./appointment-archive.component";
var styles_AppointmentArchiveComponent = [i0.styles];
var RenderType_AppointmentArchiveComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppointmentArchiveComponent, data: {} });
export { RenderType_AppointmentArchiveComponent as RenderType_AppointmentArchiveComponent };
export function View_AppointmentArchiveComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["appointment-archive works!"]))], null, null); }
export function View_AppointmentArchiveComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-appointment-archive", [], null, null, null, View_AppointmentArchiveComponent_0, RenderType_AppointmentArchiveComponent)), i1.ɵdid(1, 114688, null, 0, i2.AppointmentArchiveComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppointmentArchiveComponentNgFactory = i1.ɵccf("app-appointment-archive", i2.AppointmentArchiveComponent, View_AppointmentArchiveComponent_Host_0, {}, {}, []);
export { AppointmentArchiveComponentNgFactory as AppointmentArchiveComponentNgFactory };
