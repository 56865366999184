import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompleterData } from 'ng2-completer';
// teeth picker
import { TeethShape } from '../../treatment/shared/teeth-shape';

@Component({
  selector: 'app-quotation-create-item',
  templateUrl: './quotation-create-item.component.html',
  styleUrls: ['./quotation-create-item.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class QuotationCreateItemComponent implements OnInit {
  treatmentPanels: Treatment[] = [{ value: '0', treatmentLabelValue: '', canDelete: false }];

  @Input() selectedTeeth = [];
  @Output() select = new EventEmitter();
  isAdult = true;
  teethBottom = [];
  teethTop = [];
  options = [];
  selectedValue: any;
  dataService: CompleterData;
  isTopSelected = false;
  isBottomSelected = false;
  isAllSelected = false;
  selectQ1;
  selectQ2;
  selectQ3;
  selectQ4;
  teeth = [];

  type = false;

  @Output() clearData = new EventEmitter();

  step = 0;

  constructor() {}

  ngOnInit() {
    //  teeth

    TeethShape.getTopTeethNumbers(this.isAdult).forEach((num) => {
      this.teethTop.push({
        hideNumber: false,
        toothNumber: num,
        selected: this.selectedTeeth.indexOf(num) !== -1 ? true : false,
      });
      this.options.push(num);
    });
    TeethShape.getBottomTeethNumbers(this.isAdult).forEach((num) => {
      this.teethBottom.push({
        hideNumber: false,
        toothNumber: num,
        selected: this.selectedTeeth.indexOf(num) !== -1 ? true : false,
      });
      this.options.push(num);
    });
    this.selectedTeeth.forEach((tooth) => {
      this.getSelectedteeth(tooth);
    });
  }

  selectBottom() {
    this.isBottomSelected = !this.isBottomSelected;
    this.teethBottom.forEach((tooth) => {
      let t = {
        selected: this.isBottomSelected,
        toothNumber: tooth.toothNumber,
      };
      tooth.selected = t.selected;
      if (tooth.selected === true) {
        this.selectedTeeth.push(tooth);
      } else {
        this.selectedTeeth.forEach((selectedtooth) => {
          if (t.toothNumber === selectedtooth.toothNumber) {
            selectedtooth.selected = t.selected;
          }
        });
        this.selectedTeeth = this.selectedTeeth.filter((tooth) => {
          return tooth.selected;
        });
      }
    });
    this.change();
  }

  deselectAll() {}
  selectAll() {
    this.isBottomSelected = !this.isBottomSelected;
    this.teethBottom.forEach((tooth) => {
      let t = {
        selected: this.isBottomSelected,
        toothNumber: tooth.toothNumber,
      };
      tooth.selected = t.selected;
      if (tooth.selected === true) {
        this.selectedTeeth.push(tooth);
      } else {
        this.selectedTeeth.forEach((selectedtooth) => {
          if (t.toothNumber === selectedtooth.toothNumber) {
            selectedtooth.selected = t.selected;
          }
        });
        this.selectedTeeth = this.selectedTeeth.filter((tooth) => {
          return tooth.selected;
        });
      }
    });

    this.isTopSelected = !this.isTopSelected;
    this.teethTop.forEach((tooth) => {
      let t = {
        selected: this.isTopSelected,
        toothNumber: tooth.toothNumber,
      };
      tooth.selected = t.selected;
      if (tooth.selected === true) {
        this.selectedTeeth.push(tooth);
      } else {
        this.selectedTeeth.forEach((selectedtooth) => {
          if (t.toothNumber === selectedtooth.toothNumber) {
            selectedtooth.selected = t.selected;
          }
        });
        this.selectedTeeth = this.selectedTeeth.filter((tooth) => {
          return tooth.selected;
        });
      }
    });
    this.change();
  }

  selectTop() {
    this.isTopSelected = !this.isTopSelected;
    this.teethTop.forEach((tooth) => {
      let t = {
        selected: this.isTopSelected,
        toothNumber: tooth.toothNumber,
      };
      tooth.selected = t.selected;
      if (tooth.selected === true) {
        this.selectedTeeth.push(tooth);
      } else {
        this.selectedTeeth.forEach((selectedtooth) => {
          if (t.toothNumber === selectedtooth.toothNumber) {
            selectedtooth.selected = t.selected;
          }
        });
        this.selectedTeeth = this.selectedTeeth.filter((tooth) => {
          return tooth.selected;
        });
      }
    });
    this.change();
  }
  selectTooth(t) {
    if (
      t.toothNumber >= TeethShape.getTopTeethNumbers(this.isAdult)[7] &&
      t.toothNumber <=
        TeethShape.getTopTeethNumbers(this.isAdult)[TeethShape.getTopTeethNumbers(this.isAdult).length - 1]
    ) {
      this.teethTop.forEach((tooth) => {
        if (t.toothNumber === tooth.toothNumber) {
          tooth.selected = t.selected;
          if (tooth.selected === true) {
            this.selectedTeeth.push(tooth);
          } else {
            this.selectedTeeth.forEach((selectedtooth) => {
              if (t.toothNumber === selectedtooth.toothNumber) {
                selectedtooth.selected = t.selected;
              }
            });
            this.selectedTeeth = this.selectedTeeth.filter((tooth) => {
              return tooth.selected;
            });
          }
        }
      });
    } else {
      this.teethBottom.forEach((tooth) => {
        if (t.toothNumber === tooth.toothNumber) {
          tooth.selected = t.selected;
          if (tooth.selected === true) {
            this.selectedTeeth.push(tooth);
          } else {
            this.selectedTeeth.forEach((selectedtooth) => {
              if (t.toothNumber === selectedtooth.toothNumber) {
                selectedtooth.selected = t.selected;
              }
            });
            this.selectedTeeth = this.selectedTeeth.filter((tooth) => {
              return tooth.selected;
            });
          }
        }
      });
    }
    this.change();
  }
  change() {
    this.select.emit({
      selectedTeeth: this.selectedTeeth,
      isAdult: this.isAdult,
    });
  }
  getSelectedteeth(t) {
    if (
      t.toothNumber >= TeethShape.getTopTeethNumbers(this.isAdult)[7] &&
      t.toothNumber <=
        TeethShape.getTopTeethNumbers(this.isAdult)[TeethShape.getTopTeethNumbers(this.isAdult).length - 1]
    ) {
      this.teethTop.forEach((tooth) => {
        if (t.toothNumber === tooth.toothNumber) {
          tooth.selected = t.selected;
        }
      });
    } else {
      this.teethBottom.forEach((tooth) => {
        if (t.toothNumber === tooth.toothNumber) {
          tooth.selected = t.selected;
        }
      });
    }
  }
  teethSelection(result) {
    this.isAdult = result.isAdult;
    this.teeth = result.selectedTeeth;
  }

  // fake adding and removing treatment row
  newTreatmentRow() {
    this.treatmentPanels.push(new Treatment('3', 'Item Name', true));

    this.nextStep();
  }

  removeTreatmentRow(i) {
    // this.treatmentPanels.splice(index, 1);

    const index: number = this.treatmentPanels.indexOf(i);
    if (index !== -1) {
      this.treatmentPanels.splice(index, 1);
    }

    this.prevStep();
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
}

// fake treatment array
export class Treatment {
  constructor(public value: string, public treatmentLabelValue: string, public canDelete: boolean) {}
}
