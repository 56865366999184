<div class="rel text-center">
  <mat-icon class="large-modal-icon">schedule</mat-icon>
  <h3 class="sr-title sm-h3 no-pad">You seem to be inactive</h3>

  <p class="lead rm-mt rm-mb">Hey! We've noticed that you've been a little quiet.</p>
  <p class="" *ngIf="timedOut != true">
    For security reasons, we will log you out of your current session in <span class="bold">{{ timer }}</span> seconds.
    If you are still working, press the continue button below to avoid being signed out.
  </p>

  <p *ngIf="timedOut == true">You have been automatically logged out for security reasons.</p>

  <br/>

  <div class="drop-buttons" *ngIf="timedOut != true">
    <button class="btn-large" (click)="refreshSession()" mat-raised-button color="accent">Continue</button>
  </div>

  <div class="drop-buttons" *ngIf="timedOut == true">
    <button class="btn-large" (click)="closeEvent()" mat-raised-button color="accent">Close</button>
  </div>
</div>
