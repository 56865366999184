<div class="rel" *ngIf="listDB.firstLoadEvent == false">
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>
<div [hidden]="listDB.data.length == 0">
  <div>
    <div class="head-content clearfix">
      <button class="pull-right mr" (click)="createTag()" mat-raised-button color="accent">
        Create New {{ nature }}
      </button>

      <div class="pull-right selection-items" @ngIfAnimation>
        <button
          class="animated mr"
          (click)="selectAll()"
          mat-stroked-button
          color=""
          matTooltip="Select All"
        >
          <mat-icon>border_all</mat-icon>
        </button>
        <button
          class="animated mr"
          (click)="selectPage()"
          mat-stroked-button
          color=""
          matTooltip="Select Page"
        >
          <mat-icon>border_outer</mat-icon>
        </button>

        <button
          class="animated mr"
          *ngIf="selectedIDs && selectedIDs.length > 0"
          [matMenuTriggerFor]="ActionBtnmenu"
          mat-stroked-button
          matTooltip="Options"
        >
          <mat-icon class="fas fa-cog"></mat-icon>  <span *ngIf="selectedIDs && selectedIDs.length>0"
            class="selected-number-item  accent-background">{{selectedIDs.length}}</span>
        </button>
        <mat-menu #ActionBtnmenu="matMenu">
          <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
            <mat-icon>select_all</mat-icon> Deselect
          </button>

          <hr/>

          <button
            class="row select-btn"
            *ngIf="selectedIDs && selectedIDs.length > 0"
            (click)="removeGroup()"
            mat-menu-item
            color=""
          >
            <mat-icon>delete</mat-icon>
            Remove
          </button>
        </mat-menu>
      </div>

      <!-- <button mat-raised-button class="pull-right" color="accent" (click)="createTask()">Create New Task</button> -->
      <h1 class="dataHeader rm-m pull-left">
        {{ nature + 's' }}
        <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
      </h1>
    </div>
    <mat-card class="list-card no-box-card">
      <!-- Search -->
      <div class="row clearfix mb searchArea primary-gradient-img">
        <div class="row clearfix flex">
          <div class="full-width flex animated fadeInDown">
            <mat-form-field class="white full-width" appearance="outline">
              <mat-label>Label</mat-label>
              <input
                class="clearfix"
                (input)="setFilter($event.target.value, 'Label')"
                name="Name"
                placeholder="Label"
                matInput
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row clearfix flex well miniWell animated fadeInDown" *ngIf="searchVals.value?.length > 0"></div>
      </div>
      <!-- Table -->
      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->
        <ng-container cdkColumnDef="selectCheckBox" sticky>
          <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div class="margin-btm pull-left">
              <mat-checkbox
                [checked]="isSelected(row['ID'])"
                (change)="select(row['ID'], $event.checked)"
                color="accent"
              >
              </mat-checkbox>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Date">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Created Date</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="viewTag(row['ID'])"
            matTooltip="{{ row['DateTimeCreated'] | customDateTime }}"
          >
            {{ row.DateTimeCreated | TimeLabelPast }} ago
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Name">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Title</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTag(row['ID'])" matTooltip="{{ row['Label'] }}">
            <h3>{{ row['Label'] }}</h3>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Description">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Description</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTag(row['ID'])" matTooltip="{{ row['Description'] }}">
            {{ row['Description'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Type">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Type</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTag(row['ID'])" matTooltip="{{ row['Type'] }}">
            {{ row['Type'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button *ngIf="row['ID']" (click)="Edit(row['ID'])" mat-menu-item>
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>

                <button *ngIf="row['ID']" (click)="delete(row['ID'])" mat-menu-item>
                  <mat-icon>delete</mat-icon>
                  <span>delete</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginator
        [length]="dataSource.filteredData.length"
        [pageIndex]="0"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
      >
      </mat-paginator>
    </mat-card>
  </div>
</div>
<div class="clearfix full-width" *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <app-empty-list-message
    [actionIcon]="'add'"
    [isAction]="true"
    [actionLabel]="'New ' + nature"
    (actionEvent)="createTag()"
  >
  </app-empty-list-message>
</div>
