import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-getting-started-step1-merchant',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.scss'],
})
export class GettingStartedMerchantStep1Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
