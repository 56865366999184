<div class="treatment-block" *ngIf="role && (role == 'admin' || role == 'promoter')">
  <div class="clearfix">
    <div>
      <mat-tab-group (selectedIndexChange)="loadList($event)" dynamicHeight="true">
        <mat-tab>
          <ng-template class="hidden" mat-tab-label> All {{ "KEYWORD.patient" | translate | titlecase}}s</ng-template>

          <div class="row clearfix well" *ngIf="hideMerchantSelect == false">
            <mat-select
              class="margin-btm half-width"
              [(ngModel)]="merchantAll"
              placeholder="Please select merchant"
              name="MerchantName"
            >
              <mat-option [value]="'none'"> Please select merchant</mat-option>
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m['ID']">
                <span
                  class="list-label pull-right"
                  *ngIf="m['Status'] && m['Status'] == 'Active'"
                  [style.background]="'#00d07a'"
                >
                  {{ m['Status'] }}
                </span>

                <span
                  class="list-label pull-right"
                  *ngIf="m['Status'] && m['Status'] != 'Active'"
                  [style.background]="'#ff5722'"
                >
                  {{ m['Status'] }}
                </span>
                {{ m['TradingAs'] || m['CalculatedName'] }}
              </mat-option>
            </mat-select>
            <button
              class="pull-right merchBtn"
              *ngIf="merchantAll && merchantAll != 'none'"
              (click)="viewMerchant(1)"
              mat-raised-button
              color="primary"
            >
              <mat-icon>person</mat-icon>
              <span class="mobHide">View Merchant</span>
            </button>
          </div>
          <div class="row clearfix">
            <app-existing-patient-list-global
              *ngIf="merchantAll != 'none' && allList"
              [type]="'all'"
              [merchantID]="merchantAll"
            ></app-existing-patient-list-global>
          </div>
          <div class="row clearfix" *ngIf="hideMerchantSelect == false">
            <div class="pleaseChoose" *ngIf="merchantAll == 'none'" @ngIfAnimation>
              <p>
                <strong>No merchant selected</strong>
              </p>
              <img class="character_warning" />
              <p>
                Choose your desired merchant from the drop-down above to view their
                {{ "KEYWORD.patient" | translate }}s
              </p>
            </div>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            Customer
          </ng-template>

          <div class="row clearfix well" *ngIf="hideMerchantSelect == false">
            <mat-select
              class="margin-btm half-width"
              [(ngModel)]="merchantCustomer"
              placeholder="Please select merchant"
              name="MerchantName2"
            >
              <mat-option [value]="'none'"> Please select merchant</mat-option>
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m['ID']">
                <span
                  class="list-label pull-right"
                  *ngIf="m['Status'] && m['Status'] == 'Active'"
                  [style.background]="'#00d07a'"
                >
                  {{ m['Status'] }}
                </span>

                <span
                  class="list-label pull-right"
                  *ngIf="m['Status'] && m['Status'] != 'Active'"
                  [style.background]="'#ff5722'"
                >
                  {{ m['Status'] }}
                </span>
                {{ m['TradingAs'] || m['CalculatedName'] }}
              </mat-option>
            </mat-select>
            <button
              class="pull-right merchBtn"
              *ngIf="merchantCustomer && merchantCustomer != 'none'"
              (click)="viewMerchant(2)"
              mat-raised-button
              color="primary"
            >
              <mat-icon>person</mat-icon>
              <span class="mobHide">View Merchant</span>
            </button>
          </div>
          <div class="row clearfix">
            <app-existing-patient-list-global
              *ngIf="merchantCustomer != 'none' && customerList"
              [type]="'customer'"
              [merchantID]="merchantCustomer"
            >
            </app-existing-patient-list-global>
          </div>
          <div class="row clearfix" *ngIf="hideMerchantSelect == false">
            <div class="animated fadeInDown pleaseChoose" *ngIf="merchantCustomer == 'none'">
              <p>
                <strong>No merchant selected</strong>
              </p>
              <img class="character_warning" />
              <p>
                Choose your desired merchant from the drop-down above to view their
                {{ "KEYWORD.patient" | translate }}s
              </p>
            </div>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            Prospects
          </ng-template>

          <div class="row clearfix well" *ngIf="hideMerchantSelect == false">
            <mat-select
              class="margin-btm half-width"
              [(ngModel)]="merchantProspect"
              placeholder="Please select merchant"
              name="MerchantName3"
            >
              <mat-option [value]="'none'"> Please select merchant</mat-option>
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m['ID']">
                <span
                  class="list-label pull-right"
                  *ngIf="m['Status'] && m['Status'] == 'Active'"
                  [style.background]="'#00d07a'"
                >
                  {{ m['Status'] }}
                </span>

                <span
                  class="list-label pull-right"
                  *ngIf="m['Status'] && m['Status'] != 'Active'"
                  [style.background]="'#ff5722'"
                >
                  {{ m['Status'] }}
                </span>
                {{ m['TradingAs'] || m['CalculatedName'] }}
              </mat-option>
            </mat-select>
            <button
              class="pull-right merchBtn"
              *ngIf="merchantProspect && merchantProspect != 'none'"
              (click)="viewMerchant(3)"
              mat-raised-button
              color="primary"
            >
              <mat-icon>person</mat-icon>
              <span class="mobHide">View Merchant</span>
            </button>
          </div>
          <div class="row clearfix">
            <app-existing-patient-list-global
              *ngIf="merchantProspect != 'none' && prospectList"
              [type]="'prospect'"
              [merchantID]="merchantProspect"
            >
            </app-existing-patient-list-global>
          </div>
          <div class="row clearfix" *ngIf="hideMerchantSelect == false">
            <div class="animated fadeInDown pleaseChoose" *ngIf="merchantProspect == 'none'">
              <p>
                <strong>No merchant selected</strong>
              </p>
              <img class="character_warning" />
              <p>
                Choose your desired merchant from the drop-down above to view their
                {{ "KEYWORD.patient" | translate }}s
              </p>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

<app-existing-patient-list *ngIf="role && (role == 'merchant' || role == 'merchant-admin')">
</app-existing-patient-list>
