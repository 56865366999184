<div class="rel lookup">
  <div *ngIf="!hideFilter">
    <div class="clearfix">
      <!-- <mat-icon class="large-modal-icon">account_circle</mat-icon>
      <h3 class="sr-title sm-h3"></h3>
      <p class="rm-mt"></p>
 -->

      <div class="card-header primary-gradient-img clearfix inModal">
        <div class="row clearfix">
          <button
            class="btn-close btn-clear mat-button white"
            *ngIf="isModal == true"
            (click)="closeEvent()"
            tabIndex="-1"
          >
            <mat-icon>clear</mat-icon>
          </button>
          <div class="row titleArea clearfix">
            <mat-icon class="pull-left patient-main-icon">account_circle</mat-icon>
            <h2 class="summary-header rm-m">
              <!-- {{ beneficiary.CalculatedName}} -->
              Existing {{ "KEYWORD.patient" | translate | titlecase }} s" | translate }} Lookup

              <span class="subLabel" *ngIf="hideFilter != true" [innerHTML]="title | safeHtml"></span>
              <span class="subLabel" *ngIf="hideFilter == true" [innerHTML]="title2 | safeHtml"></span>
            </h2>
          </div>
          <!-- <button class="btn-close btn-clear mat-button white" matDialogClose>
            <mat-icon>clear</mat-icon>
          </button> -->
        </div>
      </div>

      <div class="clearfix full-width previous-search" *ngIf="patients && patients.length > 0">
        <button class="clearfix pull-right" (click)="goForward()" mat-raised-button color="primary">
          {{ patients.length }} {{ "KEYWORD.patient" | translate | titlecase }}(s)
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
        <p class="rm-m mr line-height">View the result of your previous search</p>
      </div>

      <hr class="mb" *ngIf="patients && patients.length > 0"/>

      <form class="lookup-form" #lookupForm="ngForm">
        <div class="full-width clearfix mt flex">
          <mat-form-field class="full-width" *ngIf="useStrict != true" appearance="outline">
            <mat-label>Name</mat-label>
            <input [(ngModel)]="filterName" matInput name="name" placeholder="Name" tabIndex="1"/>
          </mat-form-field>

          <mat-form-field class="full-width" *ngIf="useStrict == true" appearance="outline">
            <mat-label>First name, Surname, Middle name, Full name...</mat-label>
            <input
              [(ngModel)]="calculatedName"
              matInput
              name="calculatedName"
              placeholder="First name, Surname, Full name"
              tabIndex="1"
            />
          </mat-form-field>

          <!-- <mat-form-field class="full-width ml" *ngIf="useStrict==true" appearance="outline">
            <mat-label>Surname</mat-label>
            <input matInput name="lastName" placeholder="Surname" tabIndex="2" [(ngModel)]="lastName">
          </mat-form-field> -->
        </div>
        <div class="full-width clearfix flex">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Mobile</mat-label>
            <input [(ngModel)]="mobile" matInput name="mobile" placeholder="Mobile" tabIndex="3"/>
          </mat-form-field>

          <mat-form-field class="full-width ml" appearance="outline">
            <mat-label>Phone</mat-label>
            <input [(ngModel)]="filterPhone" matInput name="phone" placeholder="Phone" tabIndex="3"/>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Email</mat-label>
          <input
            [(ngModel)]="filterEmail"
            matInput
            name="email"
            type="email"
            tabIndex="4"
            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
            placeholder="Email"
          />
        </mat-form-field>

        <app-date-input
          class="full-width"
          [defaultDate]="null"
          [isTime]="false"
          [startView]="'multi-year'"
          [maxDate]="'toDay'"
          (selectedDate)="selectDateToFilter($event)"
          label="Date of birth"
        >
        </app-date-input>
        <div class="full-width clearfix mb" *ngIf="tags && tags.length > 0">
          <app-tag-list
            class="full-width mb"
            [tags]="tags"
            [selectedTags]="selectedTags"
            [openPosition]="'above'"
            [tagFormatIsObject]="true"
            [title]="'Tags'"
            [canRemove]="true"
            [canCreate]="false"
            [directRemoveDefault]="true"
            [directRemove]="true"
            [hasFilter]="true"
            [canSelect]="false"
            (getSelectedTags)="getSelectedTags($event)"
          >
          </app-tag-list>
        </div>

        <div class="drop-buttons">
          <button
            class="inline btn-new-invite btn-large"
            [disabled]="
              !lookupForm.form.valid ||
              ((dateOfBirth == '' || dateOfBirth == null) &&
                !calculatedName &&
                !mobile &&
                !firstName &&
                !lastName &&
                !filterName &&
                !filterPhone &&
                !filterEmail &&
                (!selectedTags || (selectedTags && selectedTags.length <= 0)))
            "
            (click)="getPatients()"
            mat-raised-button
            color="accent"
            tabIndex="4"
          >
            Search
          </button>
        </div>
      </form>
    </div>
  </div>

  <div *ngIf="hideFilter">
    <div>
      <div class="card-header primary-gradient-img clearfix inModal">
        <div class="row clearfix">
          <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeEvent()">
            <mat-icon>clear</mat-icon>
          </button>
          <div class="row titleArea clearfix">
            <mat-icon class="pull-left">account_circle</mat-icon>
            <h2 class="summary-header rm-m">
              <!-- {{ beneficiary.CalculatedName}} -->
              Possible Match
              <span class="subLabel">
                You detected a possible matching {{ "KEYWORD.patient" }} records in our database. <br/>
                Please confirm if any of the matches below are are the same records as the one you are entering.
              </span>
            </h2>
          </div>
        </div>
      </div>
      <h1 class="sr-title sm-h1 rm-mt">
        {{ "KEYWORD.patient" | translate | titlecase}}s

        <span
          class="badge master"
          *ngIf="patients"
          [endVal]="
            (patients
              | FilterArrayMultipleOrContain
                : ['Name', 'FirstName', 'emails.Email', 'phone.Number', 'mobiles.Number']
                : filterData
              | lengthOfArray) || 0
          "
          countUp
        ></span>
      </h1>
      <div class="clearfix flex flex-center">
        <mat-form-field class="full-width" *ngIf="patients.length > 3" appearance="outline">
          <mat-label>Search results by Name, email or phone number</mat-label>
          <input [(ngModel)]="filterData" matInput name="search" tabIndex="1"/>
        </mat-form-field>

        <button class="actionMenuToggle ml mr" *ngIf="patients.length > 3" [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon class="fas fa-sort"></mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <button
            [ngClass]="{ 'accent-color': orderByFilter === 'CalculatedName' || orderByFilter === '-CalculatedName' }"
            (click)="orderBy('CalculatedName')"
            mat-menu-item
          >
            <span>Name</span>
            <span *ngIf="orderByFilter === 'CalculatedName' || orderByFilter === '-CalculatedName'">
              <mat-icon class="accent-color" *ngIf="orderByFilter === '-CalculatedName'">arrow_drop_up</mat-icon>
              <mat-icon class="accent-color" *ngIf="orderByFilter !== '-CalculatedName'">arrow_drop_down</mat-icon>
            </span>
          </button>

          <button
            [ngClass]="{ 'accent-color': orderByFilter === 'DateTimeCreated' || orderByFilter === '-DateTimeCreated' }"
            (click)="orderBy('DateTimeCreated')"
            mat-menu-item
          >
            <span>Join Date</span>
            <span *ngIf="orderByFilter === 'DateTimeCreated' || orderByFilter === '-DateTimeCreated'">
              <mat-icon class="accent-color" *ngIf="orderByFilter === '-DateTimeCreated'">arrow_drop_down</mat-icon>
              <mat-icon class="accent-color" *ngIf="orderByFilter !== '-DateTimeCreated'"> arrow_drop_up</mat-icon>
            </span>
          </button>

          <button
            [ngClass]="{ 'accent-color': orderByFilter === 'emails.Email' || orderByFilter === '-emails.Email' }"
            (click)="orderBy('emails.Email')"
            mat-menu-item
          >
            <span>Email</span>
            <span *ngIf="orderByFilter === 'emails.Email' || orderByFilter === '-emails.Email'">
              <mat-icon class="accent-color" *ngIf="orderByFilter === '-emails.Email'">arrow_drop_up</mat-icon>
              <mat-icon class="accent-color" *ngIf="orderByFilter !== '-emails.Email'">arrow_drop_down</mat-icon>
            </span>
          </button>
        </mat-menu>
      </div>
    </div>

    <div class="patientContainer">
      <div
        class="patientRecord row clearfix"
        *ngFor="
          let patient of patients
            | FilterArrayMultipleOrContain: ['Name', 'FirstName', 'emails.Email', 'mobiles.Number']:filterData
            | OrderArrayObject: [orderByFilter]
            | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }
        "
        [ngClass]="{ activeExistingPatient: selectedPatient == patient }"
      >
        <div class="pull-right upper-side">
          <button
            class=""
            *ngIf="patient && patient['ID'] && patient['TableName']"
            [matTooltip]="'View ' + ('KEYWORD.patient' | translate | titlecase) + ' Details'"
            (click)="openPatientViewDialog(patient['ID'], patient['TableName'])"
            color="accent"
            mat-icon-button
          >
            <mat-icon>visibility</mat-icon>
          </button>
        </div>

        <div
          class="flex truncate"
          (click)="SelectRecordClickEvent(patient)"
          matTooltip="Click to choose {{ patient['FirstName'] }} {{ patient['Name'] }}"
        >
          <div class="profile-result">
            <!-- avatar image -->
            <app-patient-lookup-view-picture
              class="pull-left patientIcon invitationLookup mr"
              [patientID]="patient['ID']"
              [modifiedDate]="patient['LastModified']"
            >
            </app-patient-lookup-view-picture>
            <!-- profile information -->
            <div class="pull-left">
              <h3 class="sr-title rm-m">{{ patient['FirstName'] }} {{ patient['Name'] }}</h3>
              <p class="small rm-m" *ngIf="patient['mobiles.Number']" matLine>
                <!-- <mat-icon class="fas fa-mobile-alt small-icon"></mat-icon> -->
                {{ patient['mobiles.Number'] | customPhone }}
              </p>
              <!-- <p class="small rm-m" *ngIf="patient['phones.Number']" matLine>
                <mat-icon class="fas fa-phone-alt small-icon"></mat-icon>
                 {{ patient['phones.Number']  | customPhone }}
              </p> -->
              <p class="small rm-m" matLine>
                <!-- <mat-icon class="fas fa-envelope small-icon"></mat-icon>  -->

                {{ patient['emails.Email'] }}
              </p>
              <p class="small rm-m" *ngIf="patient.DateOfBirth" matLine>
                Age:
                <ng-container *ngIf="utilsClass.calculateAge(patient.DateOfBirth)">
                  {{ utilsClass.calculateAge(patient.DateOfBirth) }}
                </ng-container>
                <ng-container *ngIf="!utilsClass.calculateAge(patient.DateOfBirth)"> ...</ng-container>
              </p>
              <p
                class="small rm-mt"
                *ngIf="patient.DateTimeCreated"
                matLine
                matTooltip="{{ patient.DateTimeCreated | customDate }}"
              >
                Join Date: {{ patient.DateTimeCreated | TimeLabelPast }} ago
              </p>
            </div>
          </div>
        </div>

        <mat-divider></mat-divider>
      </div>

      <div
        class="full-width"
        *ngIf="
          (patients
            | FilterArrayMultipleOrContain
              : ['Name', 'FirstName', 'emails.Email', 'phone.Number', 'mobiles.Number']
              : filterData
            | lengthOfArray) <= 0
        "
      >
        <app-empty-list-message [message]="'No Record found'"></app-empty-list-message>
      </div>
    </div>

    <div
      class="full-width text-center page-container mt flex mb"
      *ngIf="
        (patients
          | FilterArrayMultipleOrContain
            : ['Name', 'FirstName', 'emails.Email', 'phone.Number', 'mobiles.Number']
            : filterData
          | lengthOfArray) > 10
      "
    >
      <pagination-controls class="full-width clearfix" (pageChange)="changePage($event)" responsive="true">
      </pagination-controls>
    </div>

    <div class="drop-button text-center clearfix full-width" *ngIf="isModal == true">
      <ng-container *ngIf="showBackButton == true">
        <button
          class="inline btn-new-invite selectExistingPatient pull-left"
          (click)="goBack()"
          mat-raised-button
          color="accent"
        >
          <mat-icon>keyboard_arrow_left</mat-icon> Back
        </button>
      </ng-container>

      <button
        class="inline btn-new-invite selectExistingPatient"
        [ngClass]="{ 'pull-right': showNextBtn != true, 'pull-left': showBackButton != true }"
        (click)="closeEvent()"
        mat-raised-button
        color="primary"
      >
        Close
      </button>

      <ng-container *ngIf="showNextBtn == true">
        <button
          class="inline btn-new-invite selectExistingPatient pull-right"
          (click)="noMatch()"
          mat-raised-button
          color="accent"
        >
          Add New {{ "KEYWORD.patient" | translate | titlecase }} Record
          <mat-icon>add</mat-icon>
        </button>
      </ng-container>
    </div>
  </div>
</div>
