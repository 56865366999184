<div class="clearfix select-row">
  <div class="row clearfix flex full-width">
    <div class="full-width">
      <ng2-completer
        class="tooth-search search-main full-w-dth"
        [(ngModel)]="selectedValue"
        [datasource]="options"
        [minSearchLength]="0"
        (selected)="selectToothFromInput()"
        (keyup)="checkKeyUp($event)"
        placeholder="Enter tooth number or click 'choose teeth'"
      >
      </ng2-completer>
    </div>
    <div class="half-width text-right">
      <button
        class="sel-teeth"
        [disabled]="selectedTeeth.length == 0"
        (click)="clearData()"
        mat-button
        color="warn"
        matTooltip="Clear selected teeth"
      >
        Clear selection <mat-icon>delete</mat-icon>
      </button>
      <button
        class="sel-teeth"
        (click)="openToothLookUpDialog()"
        mat-raised-button
        color="primary"
        matTooltip="Select Teeth"
      >
        Choose teeth <mat-icon>search</mat-icon>
      </button>
    </div>
  </div>
</div>
<div class="clearfix teeth-button-array text-center" *ngIf="selectedTeeth.length >= 1" @ngIfAnimation>
  <!--  BUTTON ARRAY -->
  <div class="row well anim">
    <mat-button-toggle *ngFor="let tooth of selectedTeeth" val="11">
      <app-tooth-delete [tooth]="tooth" [isAdult]="isAdult" (select)="selectTooth($event)"></app-tooth-delete>
    </mat-button-toggle>
  </div>
</div>
