import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-appointment-list-calendar-overview',
  templateUrl: './appointment-list-calendar-overview.component.html',
  styleUrls: ['./appointment-list-calendar-overview.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class AppointmentListCalendarOverviewComponent implements OnInit {
  merchantID = 'none';
  dateTo;
  dateFrom;
  statusCodes = 'BOOKED,CANCELED,ATTENDED,MISSED,REQUESTRESCHEDULE,REQUESTDATECHANGE,WAITINGCONFIRMATION';

  selectedRange = 'days90';
  isPromoterOrAdmin = false;

  constructor(private authenticationService: AuthenticationService, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      this.authenticationService.getCurrentPractice().subscribe((m) => {
        if (m && m.ID) {
          this.merchantID = m.ID;
        }
      });
    });
  }

  applySearch(result) {
    this.merchantID = result.merchantID;
    this.dateFrom = result.dateFrom;
    this.dateTo = result.dateTo;
    this.changeDetectorRef.detectChanges();
  }
}
