import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { ActionLogListComponent } from '../../action-log/action-log-list/action-log-list.component';
import { MerchantAccessCreateComponent } from '../merchant-access-create/merchant-access-create.component';
import { MerchantViewComponent } from '../merchant-view/merchant-view.component';
import { MerchantService } from '../shared/merchant.service';

@Component({
  selector: 'app-merchant-access-list',
  templateUrl: './merchant-access-list.component.html',
  styleUrls: ['./merchant-access-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MerchantAccessListComponent implements OnInit {
  @Input()
  moduleCode;

  isModal = false;

  @Output()
  close = new EventEmitter();

  module = null;
  moduleAccessList = [];

  moduleAccessListMerged = [];

  isLoaded = false;

  isPromoterOrAdmin = false;

  utils = new UtilsClass();

  merchants = [];
  filterName;

  currentPractive;

  constructor(
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private utilService: UtilsService,
    private merchantService: MerchantService,
    private activeRoute: ActivatedRoute,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.moduleCode = data;
    }
  }

  ngOnInit() {
    this.authenticationService.getCurrentPractice().subscribe((currentPractive) => {
      if (currentPractive) {
        this.currentPractive = currentPractive;
      }
      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        this.activeRoute.params.subscribe((params) => {
          if (params['moduleCode']) {
            this.moduleCode = params['moduleCode'];
          }
          if (params['moduleCode']) {
            this.utilService.getModuleAccessDetails(this.moduleCode).subscribe((res) => {
              const payload2 = {
                fields: 'ID,CalculatedName,Status',
              };

              if (res) {
                this.module = res;

                this.merchantService.getList(payload2, this.isPromoterOrAdmin).subscribe((_m) => {
                  if (_m && _m.length) {
                    this.merchants = _m;

                    if (this.module) {
                      const payload = {
                        moduleCode: this.moduleCode,
                        tableName: 'Merchant',
                        fields: 'ID,AccessLevel,Card_key',
                      };
                      this.utilService.getGlobalEPAccess(payload).subscribe((res) => {
                        this.moduleAccessList = res || [];
                        const _moduleAccessListMerged = [];

                        for (let i = 0; i < this.merchants.length; i++) {
                          const o = this.moduleAccessList.filter((e) => {
                            if (e['Card_key'] == this.merchants[i]['ID']) {
                              return true;
                            }
                            return false;
                          });

                          if (o && o.length > 0) {
                            const access = {
                              Name: this.merchants[i]['CalculatedName'],
                              ID: this.merchants[i]['ID'],
                              isActive: o[0]['isActive'],
                            };

                            _moduleAccessListMerged.push(access);
                          } else {
                            const access = {
                              Name: this.merchants[i]['CalculatedName'],
                              ID: this.merchants[i]['ID'],
                              isActive: this.module['isActive'],
                            };

                            _moduleAccessListMerged.push(access);
                          }
                        }

                        this.moduleAccessListMerged = _moduleAccessListMerged;

                        this.isLoaded = true;
                      });
                    } else {
                      this.isLoaded = true;
                    }
                  } else {
                    this.isLoaded = true;
                  }
                });
              } else {
                this.isLoaded = true;
              }
            });
          }
        });
      });
    });
  }

  closeEvent() {
    this.close.emit(true);
  }

  contact(id) {
    const data = {
      targetType: 'merchant',
      targetID: id,
      asGuest: false,
      asProfile: true,
      asPractice: true,
    };
    AuthenticationService.contactInputEvent.emit(data);
  }

  sliderChange(value, cardID) {
    if (value && value.checked == true) {
      this.utilService.addAccessUser(this.module['Code'], cardID).subscribe((res) => {
        if (res) {
          if (cardID && this.currentPractive && this.currentPractive['ID'] == cardID) {
            UtilsService.currentAcccess = null;
            UtilsClass.updateSideMenu.emit(true);
          }

          NotifyAppComponent.displayToast('success', 'Successfull', 'Successful operation');
        }
      });
    } else if (value && value.checked == false) {
      this.utilService.removeAccessUser(this.module['Code'], cardID).subscribe((res) => {
        if (res) {
          if (cardID && this.currentPractive && this.currentPractive['ID'] == cardID) {
            UtilsService.currentAcccess = null;
            UtilsClass.updateSideMenu.emit(true);
          }

          NotifyAppComponent.displayToast('success', 'Successfull', 'Successful operation');
        }
      });
    }
  }

  openQuickViewDialog(ID) {
    const ref = this.dialog.open(MerchantViewComponent, {
      data: ID,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  merchantAccess(ID) {
    const ref = this.dialog.open(MerchantAccessCreateComponent, {
      data: ID,
      width: '800px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  viewMerchantLogs(ID) {
    const ref = this.dialog.open(ActionLogListComponent, {
      data: {
        merchantID: ID,
        target: 'merchant',
        targetID: ID,
      },
      width: '900px',
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  toNumber(n) {
    if (n) {
      return Number(n);
    } else {
      return 0;
    }
  }
}
