<div class="masonry" *ngIf="contract">
  <div class="item row clearfix">
    <mat-tab-group dynamicHeight="true">
      <mat-tab *ngIf="quotedKPISummary">
        <ng-template mat-tab-label> Summary - As Quoted</ng-template>

        <div class="item row clearfix flex">
          <div class="item full-width">
            <mat-card class="clearfix">
              <div class="colour-stripe"></div>
              <mat-card-header>
                <mat-card-title>
                  <h3 class="sr-title sm-h3 rm-m">Customer Summary</h3>
                </mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="full-width">
                  <div class="half-width noWarp">
                    <p>Term</p>
                    <p>Amount per Payment</p>
                    <p>Repayment Frequency</p>
                  </div>
                  <div class="half-width text-right">
                    <p>{{ contract['LoanInstalments'] }} {{ contract['RepaymentFrequency.Noun.Plural'] }}</p>
                    <p>{{ contract['Amount.PerPayment'] | customCurrency }}</p>
                    <p>{{ contract['RepaymentFrequency.Adverb'] }}</p>
                  </div>
                </div>
                <div class="full-width">
                  <h4 class="moreGreyBackgroud">Interest Rates</h4>
                  <div class="half-width">
                    <p>&emsp;Loan</p>
                    <p>&emsp;Penalty</p>
                    <p>&emsp;Effective APR</p>
                    <p>&emsp;Effective APY</p>
                  </div>
                  <div class="half-width text-right">
                    <p>{{ quotedKPISummary.Summary.Customer.InterestRates.Loan }}</p>
                    <p>{{ quotedKPISummary.Summary.Customer.InterestRates.Penalty }}%</p>
                    <p>{{ quotedKPISummary.KPI.APR.Customer | number: '1.0-2' }}%</p>
                    <p>{{ quotedKPISummary.KPI.APY.Customer | number: '1.0-2' }}%</p>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="item full-width">
            <mat-card class="clearfix">
              <div class="colour-stripe"></div>
              <mat-card-header>
                <mat-card-title>
                  <h3 class="sr-title sm-h3 rm-m">Merchant Summary</h3>
                </mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="full-width">
                  <h4 class="half-width moreGreyBackgroud">Net Settlements</h4>
                  <h4 class="half-width moreGreyBackgroud text-right">
                    {{ contract['TotalNetSettlements'] | customCurrency }}
                  </h4>
                </div>
                <div class="full-width">
                  <h4 class="half-width moreGreyBackgroud">Drawdowns</h4>
                  <h4 class="half-width moreGreyBackgroud text-right">
                    {{ contract['Settlements.Drawn'] | customCurrency }}
                  </h4>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="item full-width">
            <mat-card class="clearfix">
              <div class="colour-stripe"></div>
              <mat-card-header>
                <mat-card-title>
                  <h3 class="sr-title sm-h3 rm-m">Performance Indicators</h3>
                </mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="full-width">
                  <h4 class="half-width moreGreyBackgroud">Net Present Value (NPV)</h4>
                  <h4 class="half-width moreGreyBackgroud text-right">
                    {{ quotedKPISummary.KPI.NPV | customCurrency }}
                  </h4>
                </div>
                <div class="full-width">
                  <h4 class="moreGreyBackgroud">Rates</h4>
                  <div class="half-width noWarp">
                    <p>&emsp;COF</p>
                    <p>&emsp;Internal Rate of Return (IRR)</p>
                    <p>&emsp;Effective APR</p>
                    <p>&emsp;Effective APY</p>
                  </div>
                  <div class="half-width text-right">
                    <p>{{ quotedKPISummary.COF.Rate | number: '1.0-2' }}%</p>
                    <p>{{ quotedKPISummary.KPI.IRR | number: '1.0-2' }}%</p>
                    <p>{{ quotedKPISummary.KPI.APR.Funder | number: '1.0-2' }}%</p>
                    <p>{{ quotedKPISummary.KPI.APY.Funder | number: '1.0-2' }}%</p>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div class="item row clearfix flex">
          <div class="item full-width">
            <mat-card class="clearfix">
              <div class="colour-stripe"></div>
              <mat-card-header>
                <mat-card-title>
                  <h3 class="sr-title sm-h3 rm-m">Loan</h3>
                </mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <table>
                  <tr class="moreGreyBackgroud">
                    <th>&nbsp;</th>
                    <th>Borrower</th>
                    <th>Merchant</th>
                    <th>Totals</th>
                  </tr>
                  <tr class="greyBackgroud">
                    <td>Invoice Amount</td>
                    <td>{{ contract['Amount.FinancedBeforeDeposit'] | customCurrency }}</td>
                    <td>&nbsp;</td>
                    <td>{{ contract['Amount.FinancedBeforeDeposit'] | customCurrency }}</td>
                  </tr>
                  <tr>
                    <td>&emsp;- less {{"KEYWORD.patient" | translate}} contribution</td>
                    <td>{{ contract['Amount.Contribution'] | customCurrency }}</td>
                    <td>&nbsp;</td>
                    <td>{{ contract['Amount.Contribution'] | customCurrency }}</td>
                  </tr>
                  <tr>
                    <td>&emsp;- less lending deposit</td>
                    <td>{{ contract['Deposit'] | customCurrency }}</td>
                    <td>&nbsp;</td>
                    <td>{{ contract['Deposit'] | customCurrency }}</td>
                  </tr>
                  <tr class="greyBackgroud">
                    <td>Amount Financed</td>
                    <td>{{ contract['Amount.Financed'] | customCurrency }}</td>
                    <td></td>
                    <td>{{ contract['Amount.Financed'] | customCurrency }}</td>
                  </tr>
                  <br/>
                  <tr class="greyBackgroud">
                    <td>Interest</td>
                    <td>{{ quotedKPISummary.Interest.Customer.Loan | customCurrency }}</td>
                    <td>{{ quotedKPISummary.Interest.Merchant.Loan | customCurrency }}</td>
                    <td>
                      {{
                      quotedKPISummary.Interest.Customer.Loan + quotedKPISummary.Interest.Merchant.Loan
                        | customCurrency
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>&emsp;Loan</td>
                    <td>{{ quotedKPISummary.Interest.Customer.Loan | customCurrency }}</td>
                    <td>{{ quotedKPISummary.Interest.Merchant.Loan | customCurrency }}</td>
                    <td>
                      {{
                      quotedKPISummary.Interest.Customer.Loan + quotedKPISummary.Interest.Merchant.Loan
                        | customCurrency
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>&emsp;Penalty</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr class="greyBackgroud">
                    <td>Fees</td>
                    <td>{{ quotedKPISummary.Fees.Totals.Customer | customCurrency }}</td>
                    <td>{{ quotedKPISummary.Fees.Totals.Merchant | customCurrency }}</td>
                    <td>
                      {{
                      quotedKPISummary.Fees.Totals.Customer + quotedKPISummary.Fees.Totals.Merchant | customCurrency
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>&emsp;Account Keeping</td>
                    <td>{{ quotedKPISummary.Fees.Customer[0] | customCurrency }}</td>
                    <td>{{ quotedKPISummary.Fees.Merchant[0] | customCurrency }}</td>
                    <td>
                      {{ quotedKPISummary.Fees.Customer[0] + quotedKPISummary.Fees.Merchant[0] | customCurrency }}
                    </td>
                  </tr>
                  <tr>
                    <td>&emsp;Loan Estabishment</td>
                    <td>{{ quotedKPISummary.Fees.Customer[1] | customCurrency }}</td>
                    <td>{{ quotedKPISummary.Fees.Merchant[1] | customCurrency }}</td>
                    <td>
                      {{ quotedKPISummary.Fees.Customer[1] + quotedKPISummary.Fees.Merchant[1] | customCurrency }}
                    </td>
                  </tr>
                  <tr>
                    <td>&emsp;Recourse Release MSF</td>
                    <td>{{ quotedKPISummary.Fees.Customer[2] | customCurrency }}</td>
                    <td>{{ quotedKPISummary.Fees.Merchant[2] | customCurrency }}</td>
                    <td>
                      {{ quotedKPISummary.Fees.Customer[2] + quotedKPISummary.Fees.Merchant[2] | customCurrency }}
                    </td>
                  </tr>
                  <tr class="greyBackgroud">
                    <td>Commisions Collected</td>
                    <td>{{ quotedKPISummary.Commissions.WeCollect.Customer | customCurrency }}</td>
                    <td>{{ quotedKPISummary.Commissions.WeCollect.Merchant | customCurrency }}</td>
                    <td>
                      {{
                      quotedKPISummary.Commissions.WeCollect.Customer +
                      quotedKPISummary.Commissions.WeCollect.Merchant | customCurrency
                      }}
                    </td>
                  </tr>
                  <tr class="moreGreyBackgroud">
                    <td>Total Income</td>
                    <td>
                      {{
                      quotedKPISummary.Interest.Customer.Loan +
                      quotedKPISummary.Fees.Totals.Customer +
                      quotedKPISummary.Commissions.WeCollect.Customer | customCurrency
                      }}
                    </td>
                    <td>
                      {{
                      quotedKPISummary.Interest.Merchant.Loan +
                      quotedKPISummary.Fees.Totals.Merchant +
                      quotedKPISummary.Commissions.WeCollect.Merchant | customCurrency
                      }}
                    </td>
                    <td>{{ quotedKPISummary.Income.Total | customCurrency }}</td>
                  </tr>
                </table>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="item full-width">
            <mat-card class="clearfix">
              <div class="colour-stripe"></div>
              <mat-card-header>
                <mat-card-title>
                  <h3 class="sr-title sm-h3 rm-m">Cost & Profits</h3>
                </mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <table>
                  <tr class="moreGreyBackgroud">
                    <th></th>
                    <th>Amounts</th>
                    <th>Percent</th>
                  </tr>
                  <tr class="greyBackgroud">
                    <td>Gross Profit</td>
                    <td>{{ quotedKPISummary.Income.Total | customCurrency }}</td>
                    <td>MISSING</td>
                  </tr>
                  <tr>
                    <td>&emsp;Borrower</td>
                    <td></td>
                    <td>MISSING</td>
                  </tr>
                  <tr>
                    <td>&emsp;Merchant</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr class="greyBackgroud">
                    <td>Cost of Sales - Interest</td>
                    <td>{{ quotedKPISummary.Cost.Interest | customCurrency }}</td>
                    <td>-</td>
                  </tr>
                  <tr class="greyBackgroud">
                    <td>Cost of Sales - Fees</td>
                    <td>{{ quotedKPISummary.Cost.Fees | customCurrency }}</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>&emsp;Account Keeping</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>&emsp;Loan Establishment</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr class="greyBackgroud">
                    <td>Commisions Paid</td>
                    <td>{{ quotedKPISummary.Cost.Commissions | customCurrency }}</td>
                    <td>-</td>
                  </tr>
                  <tr class="moreGreyBackgroud">
                    <td>Total Cost</td>
                    <td>{{ quotedKPISummary.Cost.Total | customCurrency }}</td>
                    <td></td>
                  </tr>
                  <tr class="moreGreyBackgroud">
                    <td>Net Profit</td>
                    <td>{{ quotedKPISummary.NetProfit | customCurrency }}</td>
                    <td>MISSING</td>
                  </tr>
                </table>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </mat-tab>
      <mat-tab
        *ngIf="
          currentKPISummary &&
          (contract['Status.Code'] == 'ACTIVE' || contract['Status.Code'] == 'COMPLETE') &&
          (currentKPISummary['NetProfit'] != '0' ||
            currentKPISummary['Cost']['Total'] != '0' ||
            currentKPISummary['Income']['Total'] != '0')
        "
      >
        <ng-template mat-tab-label> Summary - Current</ng-template>
        <div class="row clearfix">
          <div class="item row clearfix flex">
            <div class="item full-width">
              <mat-card class="clearfix">
                <div class="colour-stripe"></div>
                <mat-card-header>
                  <mat-card-title>
                    <h3 class="sr-title sm-h3 rm-m">Customer Summary</h3>
                  </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="full-width">
                    <div class="half-width noWarp">
                      <p>Term</p>
                      <p>Amount per Payment</p>
                      <p>Repayment Frequency</p>
                    </div>
                    <div class="half-width text-right">
                      <p>{{ contract['LoanInstalments'] }} {{ contract['RepaymentFrequency.Noun.Plural'] }}</p>
                      <p>{{ contract['Amount.PerPayment'] | customCurrency }}</p>
                      <p>{{ contract['RepaymentFrequency.Adverb'] }}</p>
                    </div>
                  </div>
                  <div class="full-width">
                    <h4 class="moreGreyBackgroud">Interest Rates</h4>
                    <div class="half-width">
                      <p>&emsp;Loan</p>
                      <p>&emsp;Penalty</p>
                      <p>&emsp;Effective APR</p>
                      <p>&emsp;Effective APY</p>
                    </div>
                    <div class="half-width text-right">
                      <p>{{ currentKPISummary.Summary.Customer.InterestRates.Loan }}</p>
                      <p>{{ currentKPISummary.Summary.Customer.InterestRates.Penalty }}%</p>
                      <p>{{ currentKPISummary.KPI.APR.Customer | number: '1.0-2' }}%</p>
                      <p>{{ currentKPISummary.KPI.APY.Customer | number: '1.0-2' }}%</p>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="item full-width">
              <mat-card class="clearfix">
                <div class="colour-stripe"></div>
                <mat-card-header>
                  <mat-card-title>
                    <h3 class="sr-title sm-h3 rm-m">Merchant Summary</h3>
                  </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="full-width">
                    <h4 class="half-width moreGreyBackgroud">Net Settlements</h4>
                    <h4 class="half-width moreGreyBackgroud text-right">
                      {{ contract['TotalNetSettlements'] | customCurrency }}
                    </h4>
                  </div>
                  <div class="full-width">
                    <h4 class="half-width moreGreyBackgroud">Drawdowns</h4>
                    <h4 class="half-width moreGreyBackgroud text-right">
                      {{ contract['Settlements.Drawn'] | customCurrency }}
                    </h4>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="item full-width">
              <mat-card class="clearfix">
                <div class="colour-stripe"></div>
                <mat-card-header>
                  <mat-card-title>
                    <h3 class="sr-title sm-h3 rm-m">Performance Indicators</h3>
                  </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="full-width">
                    <h4 class="half-width moreGreyBackgroud">Net Present Value (NPV)</h4>
                    <h4 class="half-width moreGreyBackgroud text-right">
                      {{ currentKPISummary.KPI.NPV | customCurrency }}
                    </h4>
                  </div>
                  <div class="full-width">
                    <h4 class="moreGreyBackgroud">Rates</h4>
                    <div class="half-width noWarp">
                      <p>&emsp;COF</p>
                      <p>&emsp;Internal Rate of Return (IRR)</p>
                      <p>&emsp;Effective APR</p>
                      <p>&emsp;Effective APY</p>
                    </div>
                    <div class="half-width text-right">
                      <p>{{ currentKPISummary.COF.Rate | number: '1.0-2' }}%</p>
                      <p>{{ currentKPISummary.KPI.IRR | number: '1.0-2' }}%</p>
                      <p>{{ currentKPISummary.KPI.APR.Funder | number: '1.0-2' }}%</p>
                      <p>{{ currentKPISummary.KPI.APY.Funder | number: '1.0-2' }}%</p>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
          <div class="item row clearfix flex">
            <div class="item full-width">
              <mat-card class="clearfix">
                <div class="colour-stripe"></div>
                <mat-card-header>
                  <mat-card-title>
                    <h3 class="sr-title sm-h3 rm-m">Loan</h3>
                  </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <table>
                    <tr class="moreGreyBackgroud">
                      <th>&nbsp;</th>
                      <th>Borrower</th>
                      <th>Merchant</th>
                      <th>Totals</th>
                    </tr>
                    <tr class="greyBackgroud">
                      <td>Invoice Amount</td>
                      <td>{{ contract['Amount.FinancedBeforeDeposit'] | customCurrency }}</td>
                      <td>&nbsp;</td>
                      <td>{{ contract['Amount.FinancedBeforeDeposit'] | customCurrency }}</td>
                    </tr>
                    <tr>
                      <td>&emsp;- less {{"KEYWORD.patient" | translate}} contribution</td>
                      <td>{{ contract['Amount.Contribution'] | customCurrency }}</td>
                      <td>&nbsp;</td>
                      <td>{{ contract['Amount.Contribution'] | customCurrency }}</td>
                    </tr>
                    <tr>
                      <td>&emsp;- less lending deposit</td>
                      <td>{{ contract['Deposit'] | customCurrency }}</td>
                      <td>&nbsp;</td>
                      <td>{{ contract['Deposit'] | customCurrency }}</td>
                    </tr>
                    <tr class="greyBackgroud">
                      <td>Amount Financed</td>
                      <td>{{ contract['Amount.Financed'] | customCurrency }}</td>
                      <td></td>
                      <td>{{ contract['Amount.Financed'] | customCurrency }}</td>
                    </tr>
                    <br/>
                    <tr class="greyBackgroud">
                      <td>Interest</td>
                      <td>{{ currentKPISummary.Interest.Customer.Loan | customCurrency }}</td>
                      <td>{{ currentKPISummary.Interest.Merchant.Loan | customCurrency }}</td>
                      <td>
                        {{
                        currentKPISummary.Interest.Customer.Loan + currentKPISummary.Interest.Merchant.Loan
                          | customCurrency
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>&emsp;Loan</td>
                      <td>{{ currentKPISummary.Interest.Customer.Loan | customCurrency }}</td>
                      <td>{{ currentKPISummary.Interest.Merchant.Loan | customCurrency }}</td>
                      <td>
                        {{
                        currentKPISummary.Interest.Customer.Loan + currentKPISummary.Interest.Merchant.Loan
                          | customCurrency
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>&emsp;Penalty</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="greyBackgroud">
                      <td>Fees</td>
                      <td>{{ currentKPISummary.Fees.Totals.Customer | customCurrency }}</td>
                      <td>{{ currentKPISummary.Fees.Totals.Merchant | customCurrency }}</td>
                      <td>
                        {{
                        currentKPISummary.Fees.Totals.Customer + currentKPISummary.Fees.Totals.Merchant
                          | customCurrency
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>&emsp;Account Keeping</td>
                      <td>{{ currentKPISummary.Fees.Customer[0] | customCurrency }}</td>
                      <td>{{ currentKPISummary.Fees.Merchant[0] | customCurrency }}</td>
                      <td>
                        {{ currentKPISummary.Fees.Customer[0] + currentKPISummary.Fees.Merchant[0] | customCurrency }}
                      </td>
                    </tr>
                    <tr>
                      <td>&emsp;Loan Estabishment</td>
                      <td>{{ currentKPISummary.Fees.Customer[1] | customCurrency }}</td>
                      <td>{{ currentKPISummary.Fees.Merchant[1] | customCurrency }}</td>
                      <td>
                        {{ currentKPISummary.Fees.Customer[1] + currentKPISummary.Fees.Merchant[1] | customCurrency }}
                      </td>
                    </tr>
                    <tr>
                      <td>&emsp;Recourse Release MSF</td>
                      <td>{{ currentKPISummary.Fees.Customer[2] | customCurrency }}</td>
                      <td>{{ currentKPISummary.Fees.Merchant[2] | customCurrency }}</td>
                      <td>
                        {{ currentKPISummary.Fees.Customer[2] + currentKPISummary.Fees.Merchant[2] | customCurrency }}
                      </td>
                    </tr>
                    <tr class="greyBackgroud">
                      <td>Commisions Collected</td>
                      <td>{{ currentKPISummary.Commissions.WeCollect.Customer | customCurrency }}</td>
                      <td>{{ currentKPISummary.Commissions.WeCollect.Merchant | customCurrency }}</td>
                      <td>
                        {{
                        currentKPISummary.Commissions.WeCollect.Customer +
                        currentKPISummary.Commissions.WeCollect.Merchant | customCurrency
                        }}
                      </td>
                    </tr>
                    <tr class="moreGreyBackgroud">
                      <td>Total Income</td>
                      <td>
                        {{
                        currentKPISummary.Interest.Customer.Loan +
                        currentKPISummary.Fees.Totals.Customer +
                        currentKPISummary.Commissions.WeCollect.Customer | customCurrency
                        }}
                      </td>
                      <td>
                        {{
                        currentKPISummary.Interest.Merchant.Loan +
                        currentKPISummary.Fees.Totals.Merchant +
                        currentKPISummary.Commissions.WeCollect.Merchant | customCurrency
                        }}
                      </td>
                      <td>{{ currentKPISummary.Income.Total | customCurrency }}</td>
                    </tr>
                  </table>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="item full-width">
              <mat-card class="clearfix">
                <div class="colour-stripe"></div>
                <mat-card-header>
                  <mat-card-title>
                    <h3 class="sr-title sm-h3 rm-m">Cost & Profits</h3>
                  </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <table>
                    <tr class="moreGreyBackgroud">
                      <th></th>
                      <th>Amounts</th>
                      <th>Percent</th>
                    </tr>
                    <tr class="greyBackgroud">
                      <td>Gross Profit</td>
                      <td>{{ currentKPISummary.Income.Total | customCurrency }}</td>
                      <td>MISSING</td>
                    </tr>
                    <tr>
                      <td>&emsp;Borrower</td>
                      <td></td>
                      <td>MISSING</td>
                    </tr>
                    <tr>
                      <td>&emsp;Merchant</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="greyBackgroud">
                      <td>Cost of Sales - Interest</td>
                      <td>{{ currentKPISummary.Cost.Interest | customCurrency }}</td>
                      <td>-</td>
                    </tr>
                    <tr class="greyBackgroud">
                      <td>Cost of Sales - Fees</td>
                      <td>{{ currentKPISummary.Cost.Fees | customCurrency }}</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>&emsp;Account Keeping</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>&emsp;Loan Establishment</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="greyBackgroud">
                      <td>Commisions Paid</td>
                      <td>{{ currentKPISummary.Cost.Commissions | customCurrency }}</td>
                      <td>-</td>
                    </tr>
                    <tr class="moreGreyBackgroud">
                      <td>Total Cost</td>
                      <td>{{ currentKPISummary.Cost.Total | customCurrency }}</td>
                      <td></td>
                    </tr>
                    <tr class="moreGreyBackgroud">
                      <td>Net Profit</td>
                      <td>{{ currentKPISummary.NetProfit | customCurrency }}</td>
                      <td>MISSING</td>
                    </tr>
                  </table>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label> Income Accrued</ng-template>

        <p>Release Soon</p>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label> Income Actual</ng-template>
        <p>Release Soon</p>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
