import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-contract-detail-documents',
  templateUrl: './contract-detail-documents.component.html',
  styleUrls: ['./contract-detail-documents.component.css'],
})
export class ContractDetailDocumentsComponent implements OnInit {
  @Input()
  contractObject;

  contract;

  constructor() {}

  ngOnInit() {
    if (this.contractObject && typeof this.contractObject == 'object') {
      this.contract = this.contractObject;
    }
  }
}
