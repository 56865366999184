<ng-container *ngIf="viewModel$ | async as viewModel">
  <div class="row clearfix" @onChangeAnimation>
    <mat-card class="card clearfix">
      <div class="colour-stripe"></div>

      <div class="row full-width clearfix">
        <div class="clearfix rel">
          <div class="row white-background full-width clearfix" @onChangeAnimation>
            <div class="animate">
              <div class="mt mb clearfix">
                <div style="display: flex; justify-content: space-between">
                  <ipv-data-header [title]="'Revenue Report'"></ipv-data-header>
                </div>

                <div style="display: flex; justify-content: flex-end; width: 100%; margin-bottom: 30px">
                  <button
                    color="accent"
                    mat-raised-button
                    style="margin-left: 30px; height: 36px"
                    (click)="updateRevenueReport()"
                  >
                    <mat-icon class="fas fa-search"></mat-icon>
                    Request Report
                  </button>

                  <!-- Print Button -->
                  <button
                    color="accent"
                    mat-raised-button
                    style="margin-left: 30px; height: 36px"
                    [disabled]="!viewModel.revenueReport || selectedTabIndex === 0"
                    (click)="openTablePrint()"
                  >
                    <mat-icon class="fas fa-print"></mat-icon>
                    Print Report
                  </button>
                </div>

                <ipv-data-table-control-container>
                  <!-- Start Date -->
                  <app-date-input
                    [defaultDate]="initialStartDate"
                    (selectedDate)="setStartDate($event)"
                    [label]="'Start Date'"
                  ></app-date-input>

                  <!-- End Date -->
                  <app-date-input
                    [defaultDate]="initialEndDate"
                    (selectedDate)="setEndDate($event)"
                    [label]="'End Date'"
                  ></app-date-input>

                  <!-- Borrower -->
                  <mat-form-field appearance="outline">
                    <mat-label>Borrower</mat-label>

                    <mat-select
                      name="customerSelect"
                      placeholder="Select Borrower"
                      [(ngModel)]="customerControlValue"
                      [compareWith]="compareCustomer"
                      (selectionChange)="setCustomer($event.value, viewModel.contracts)"
                      (openedChange)="fetchContracts$()"
                    >
                      <mat-option [value]="allCustomersOption">
                        <div>{{allCustomersOption["Customer.FullName"]}}</div>
                        <div style="font-size: small">{{allCustomersOption["Customer.Email"]}}</div>
                      </mat-option>

                      <mat-option
                        *ngFor="let contract of viewModel.contracts
                         | pickObjectProperties: ['Customer.FullName', 'Customer.Email', 'Customer_key']
                         | uniqueBy: 'Customer.FullName'
                         | without: ['',null]
                         | orderBy"
                        [value]="contract"
                      >
                        <div>{{contract['Customer.FullName']}}</div>
                        <div style="font-size: small">{{contract['Customer.Email']}}</div>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <!-- Merchant -->
                  <mat-form-field appearance="outline">
                    <mat-label> Merchant</mat-label>

                    <mat-select
                      name="merchant"
                      placeholder="Select Merchant"
                      [(ngModel)]="merchantControlValue"
                      (selectionChange)="setMerchant($event.value)"
                      [compareWith]="compareMerchants"
                      (openedChange)="fetchMerchants$()"
                    >
                      <mat-option [value]="allPracticesOptionValue">
                        <span> All </span>
                      </mat-option>

                      <mat-option
                        *ngFor="let merchant of viewModel.merchants  | orderBy : ['TradingAs', 'CalculatedName']"
                        [value]="merchant"
                      >
                        <span
                          class="list-label pull-right"
                          *ngIf="merchant['Status'] && merchant['Status']=='Active'"
                          [style.background]="'#00d07a'"
                        >
                          {{merchant['Status']}}
                        </span>

                        <span
                          class="list-label pull-right"
                          *ngIf="merchant['Status'] && merchant['Status']!='Active'"
                          [style.background]="'#ff5722'"
                        >
                          {{merchant['Status']}}
                        </span>

                        {{merchant['TradingAs'] || merchant['CalculatedName'] }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <!-- Merchant Group -->
                  <mat-form-field appearance="outline">
                    <mat-label>Merchant Group</mat-label>

                    <mat-select
                      name="merchantGroupsSelect"
                      placeholder="Select Merchant Groups"
                      multiple
                      [(ngModel)]="merchantGroupControlValues"
                      [compareWith]="compareMerchant"
                      (selectionChange)="setMerchantGroups($event.value)"
                      (openedChange)="fetchMerchantGroups$()"
                    >
                      <mat-select-trigger>
                        <span>
                          {{ merchantGroupControlValues && merchantGroupControlValues.length > 0 ?
                          merchantGroupControlValues[0].Label : 'All'}}
                        </span>

                        <span *ngIf="merchantGroupControlValues?.length > 1">
                          (+{{merchantGroupControlValues.length - 1}} {{ merchantGroupControlValues.length === 2 ?
                          'other' : 'others' }} )
                        </span>
                      </mat-select-trigger>

                      <!-- All -->
                      <mat-option [value]="allMerchantGroupsOption[0]" [disabled]="hasAllMerchantsOption">
                        {{allMerchantGroupsOption[0].Label}}
                      </mat-option>

                      <mat-option *ngFor="let merchantGroup of viewModel.merchantGroups" [value]="merchantGroup">
                        {{merchantGroup.Label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <!-- Product -->
                  <mat-form-field appearance="outline">
                    <mat-label>Product</mat-label>

                    <mat-select
                      class="full-width"
                      name="productsSelect"
                      placeholder="Select Products"
                      multiple
                      [(ngModel)]="productControlValues"
                      (selectionChange)="setProducts($event.value)"
                      [compareWith]="compareProduct"
                      (openedChange)="fetchContracts$()"
                    >
                      <mat-select-trigger>
                        <span>
                          {{ productControlValues && productControlValues.length > 0 ?
                          productControlValues[0]["Product.Label.Marketing"] : 'All'}}
                        </span>

                        <span *ngIf="productControlValues?.length > 1">
                          (+{{productControlValues.length - 1}} {{ productControlValues.length === 2 ? 'other' :
                          'others' }} )
                        </span>
                      </mat-select-trigger>

                      <mat-option [value]="allProductsOption[0]" [disabled]="hasAllProductsOption">
                        {{allProductsOption[0]["Product.Label.Marketing"]}}
                      </mat-option>
                      <mat-option
                        *ngFor="let contract of viewModel.contracts
                        | pickObjectProperties: ['Product.Label.Marketing', 'Product_key']
                        | uniqueBy:'Product.Label.Marketing'
                        | without: ['',null]
                        | orderBy "
                        [value]="contract"
                      >
                        {{contract['Product.Label.Marketing']}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <!-- Funder -->
                  <mat-form-field appearance="outline">
                    <mat-label>Funder</mat-label>
                    <mat-select
                      class="full-width"
                      name="Funder"
                      placeholder="Select Funders"
                      multiple
                      [(ngModel)]="funderControlValues"
                      [compareWith]="compareFunder"
                      (selectionChange)="setFunders($event.value)"
                      (openedChange)="fetchContracts$()"
                    >
                      <mat-select-trigger>
                        <span>
                          {{ funderControlValues && funderControlValues.length > 0 ?
                          funderControlValues[0]["FunderName"] : 'All'}}
                        </span>

                        <span *ngIf="funderControlValues?.length > 1">
                          (+{{funderControlValues.length - 1}} {{ funderControlValues.length === 2 ? 'other' : 'others'
                          }} )
                        </span>
                      </mat-select-trigger>

                      <!-- All -->
                      <mat-option [value]="allFundersOption[0]" [disabled]="hasAllFundersOption">
                        {{allFundersOption[0].FunderName}}
                      </mat-option>

                      <mat-option
                        *ngFor="let contract of viewModel.contracts
                        | pickObjectProperties: ['FunderName', 'Funder_key']
                        | uniqueBy: 'FunderName'
                        | without: ['',null]
                        | orderBy"
                        [value]="contract"
                      >
                        {{contract.FunderName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <!-- Total Label-->
                  <ng-container *ngIf="viewModel.revenueReport">
                    <h2
                      style="margin-left: auto; margin-top: 8px; color: white; font-weight: bold"
                      *ngIf="selectedTabIndex === 1 && viewModel.revenueReport.TotalRevenue_Actual[0].Actual_Net > 0"
                    >
                      Total Actual: {{ viewModel.revenueReport.TotalRevenue_Actual[0].Actual_Net | customCurrency }}
                    </h2>

                    <h2
                      style="margin-left: auto; margin-top: 8px; color: white; font-weight: bold"
                      *ngIf="selectedTabIndex === 2 && viewModel.revenueReport.TotalRevenue_Accrued[0].Accrued_Net > 0"
                    >
                      Total Accrued: {{ viewModel.revenueReport.TotalRevenue_Accrued[0].Accrued_Net | customCurrency }}
                    </h2>
                  </ng-container>
                </ipv-data-table-control-container>

                <mat-tab-group mat-stretch-tabs (selectedTabChange)="setCurrentTab($event.index)">
                  <mat-tab label="Summary">
                    <ipv-funders-revenue-report-summary
                      [revenueReport]="viewModel.revenueReport"
                    ></ipv-funders-revenue-report-summary>
                  </mat-tab>

                  <mat-tab label="Actual">
                    <ipv-funders-revenue-report-actual
                      [revenueReport]="viewModel.revenueReport"
                    ></ipv-funders-revenue-report-actual>
                  </mat-tab>

                  <mat-tab label="Accrued">
                    <ipv-funders-revenue-report-accrued
                      [revenueReport]="viewModel.revenueReport"
                    ></ipv-funders-revenue-report-accrued>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</ng-container>
