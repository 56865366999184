import { Component, OnInit } from '@angular/core';
import { RootAppComponent } from '../../../../shared/components/root-component/root-component.component';
import { ThemeCreateComponent } from '../../../theme/theme-create/theme-create.component';
import { AuthenticationService } from '../../../authentication/shared/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-theme',
  templateUrl: './admin-theme.component.html',
  styleUrls: ['./admin-theme.component.css'],
})
export class AdminThemeComponent implements OnInit {

  isAdmin=false;
  constructor(
    private authenticationService:AuthenticationService,

    private router: Router,
    ) { }

  ngOnInit() {

    this.authenticationService.isPromoterOrAdmin().subscribe(r=>{

      this.isAdmin = r;
      if(r!=true)
      {
        this.router.navigate([
          '/merchant',
          { outlets: { page: ['home'] } },
        ]);
      }
    })


  }


  addTheme() {
    const ref = RootAppComponent.dialog.open(ThemeCreateComponent, {
      height: '500px',
      width: '400px',
    });
  }
}
