import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { CheckPasswordComponent } from '../check-password/check-password.component';
import { SecurityCodeCurrentUserComponent } from '../security-code-current-user/security-code-current-user.component';
import { AuthenticationService } from '../shared/authentication.service';

@Component({
  selector: 'app-change-social-media',
  templateUrl: './change-social-media.component.html',
  styleUrls: ['./change-social-media.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class ChangeSocialMediaComponent implements OnInit {
  directLogin = true;
  facebookStatus = null;
  googleStatus = null;

  facebookStatusLabel = '';
  googleStatusLabel = '';

  currentUser;
  hasProfilePicture = false;

  hasFacebookID;
  hasGoogleID;
  hasUsername;

  isLogin = false;
  securityID;
  securityCode;
  password;

  googleID;
  facebookID;
  profilePicture;

  facebookLabel;
  googleLabel;

  facebookButtonLabel = 'Integrate Facebook';
  googleButtonLabel = 'Integrate Google';

  facebookDate = null;
  googleDate = null;

  utils = new UtilsClass();

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.isLogin().subscribe((res) => {
      this.isLogin = res;

      if (this.isLogin == true) {
        this.authenticationService.getCurrentUser().subscribe((res) => {
          if (res && res.data) {
            this.currentUser = res.data;

            this.authenticationService.getAuthenticationMethod().subscribe((authentication) => {
              if (authentication) {
                if (authentication.hasFacebookID == true) {
                  this.hasFacebookID = true;
                } else {
                  this.hasFacebookID = false;
                }

                if (authentication.hasGoogleID == true) {
                  this.hasGoogleID = true;
                } else {
                  this.hasGoogleID = false;
                }

                if (authentication.hasUsername == true) {
                  this.hasUsername = true;
                } else {
                  this.hasUsername = false;
                }
              }
              this.buildStatus();
            });
          }
        });

        this.authenticationService.hasProfilePicture().subscribe((res) => {
          this.hasProfilePicture = res;
        });
      }
    });
  }

  buildStatus() {
    if (this.hasFacebookID == true) {
      this.facebookStatus = 'valid';
      this.facebookStatusLabel = 'Your Facebook Account is Integrated';
    } else {
      this.facebookStatus = 'warning';
      this.facebookStatusLabel = 'Your Facebook Account is not Integrated';
    }

    if (this.hasGoogleID == true) {
      this.googleStatus = 'valid';
      this.googleStatusLabel = 'Your Google Account is Integrated';
    } else {
      this.googleStatus = 'warning';
      this.googleStatusLabel = 'Your Google Account is not Integrated';
    }
  }

  getResultSocialMedia(e) {
    if (e) {
      let type;
      let token;
      if (e.facebookID) {
        this.facebookID = e.facebookID;
        this.googleID = null;
        type = 'facebook';
        token = e.facebookID;

        if (e.facebookLabel) {
          this.facebookLabel = e.facebookLabel;
        }
      } else if (e.googleID) {
        this.googleID = e.googleID;
        this.facebookID = null;

        type = 'google';
        token = e.googleID;

        if (e.googleLabel) {
          this.googleLabel = e.googleLabel;
        }
      }

      if (e.profilePicture) {
        this.profilePicture = e.profilePicture;
      }

      if (type && token) {
        this.authenticationService.socialMediaExist(type, token).subscribe((res) => {
          if (res != true) {
            this.validateAccount(false);
          } else {
            let error = 'This ' + this.upperCaseFirstLetter(type) + ' Account already linked to another user account,';

            error = error + ' Please make sure u are signin to the the correct Account and try again';
            UtilsClass.displayErrorModal(error, this.upperCaseFirstLetter(type) + ' account is already used', null);
          }
        });
      }
    }
  }

  upperCaseFirstLetter(s) {
    let result = '';
    if (s) {
      result = String(s);
      result = String(result[0]).toUpperCase() + result.substr(1);
    }
    return result;
  }

  validateAccount(remove = false) {
    if (this.hasUsername == true) {
      const ref = RootAppComponent.dialog.open(CheckPasswordComponent, {
        data: {},

        width: '500px',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res) {
          this.securityID = null;
          this.securityCode = null;
          if (res.password) {
            this.password = res.password;
          }
          if (remove == true) {
            this.changeSocialMedia(ref, false);
          } else {
            this.changeSocialMedia(ref, true);
          }
        }
      });
    } else {
      const ref = RootAppComponent.dialog.open(SecurityCodeCurrentUserComponent, {
        data: {},

        width: '600px',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res) {
          this.password = null;
          if (res.securityID) {
            this.securityID = res.securityID;
          }
          if (res.securityCode) {
            this.securityCode = res.securityCode;
          }

          if (remove == true) {
            this.changeSocialMedia(ref, false);
          } else {
            this.changeSocialMedia(ref, true);
          }
        }
      });
    }
  }

  changeSocialMedia(ref = null, editPicture = true) {
    let p = {
      securityID: null,
      securityCode: null,
      token: null,
      tokenType: null,
      tokenLabel: null,
      password: null,
    };

    if (this.securityID) {
      p.securityID = this.securityID;
    }

    if (this.securityCode) {
      p.securityCode = this.securityCode;
    }

    if (this.password) {
      p.password = this.password;
    }

    if (this.facebookID) {
      p.token = this.facebookID;
      p.tokenType = 'facebook';
      if (this.facebookLabel) {
        p.tokenLabel = this.facebookLabel;
      }
    } else if (this.googleID) {
      p.token = this.googleID;
      p.tokenType = 'google';
      if (this.googleLabel) {
        p.tokenLabel = this.googleLabel;
      }
    }

    this.authenticationService.changeSocialMedia(p).subscribe((res) => {
      if (res) {
        if (res.hasFacebookID == true) {
          this.hasFacebookID = true;

          if (res.facebookDate) {
            this.facebookDate = res.facebookDate;
          } else {
            this.facebookDate = null;
          }

          if (res.facebookLabel) {
            this.facebookLabel = res.facebookLabel;
          } else {
            this.facebookLabel = null;
          }
        } else {
          this.hasFacebookID = false;
          this.facebookDate = null;
          this.facebookLabel = null;
        }

        if (res.hasGoogleID == true) {
          this.hasGoogleID = true;

          if (res.googleDate) {
            this.googleDate = res.googleDate;
          } else {
            this.googleDate = null;
          }

          if (res.googleLabel) {
            this.googleLabel = res.googleLabel;
          } else {
            this.googleLabel = null;
          }
        } else {
          this.hasGoogleID = false;
          this.googleDate = null;
          this.googleLabel = null;
        }

        if (res.hasUsername == true) {
          this.hasUsername = true;
        } else {
          this.hasUsername = false;
        }

        if (p.tokenType == 'facebook') {
          NotifyAppComponent.displayToast('success', 'Successful ', 'Facebook link added to Authentication method');
        } else if (p.tokenType == 'google') {
          NotifyAppComponent.displayToast('success', 'Successful ', 'Google link added to Authentication method');
        }

        if (this.profilePicture && editPicture != false) {
          const confirm = new ConfirmDialog(
            'fas fa-info',
            'Change profile picture',
            'Do you want to use this picture as your profile picture ?',
            'No',
            'Yes',
            this.profilePicture
          );

          const ref3 = RootAppComponent.dialog.open(ConfirmDialogComponent, {
            data: confirm,
          });
          ref3.componentInstance.onConfirm.subscribe((confirmation) => {
            if (confirmation === false) {
              ref3.close();
            } else {
              const payload = {
                fileLink: this.profilePicture,
              };
              this.authenticationService.uploadProfilePicByLink(payload).subscribe((res) => {
                if (res) {
                  NotifyAppComponent.displayToast('success', 'Successful ', 'Profile picture is updated');

                  ref3.close();
                }
              });
            }
          });
        }

        this.buildStatus();

        p = {
          securityID: null,
          securityCode: null,
          token: null,
          tokenType: null,
          tokenLabel: null,
          password: null,
        };

        if (ref) {
          ref.close();
        }
      }
    });
  }

  getRemoveEvent(type) {
    if (type) {
      const confirm = new ConfirmDialog(
        'delete',
        'Remove ' + this.upperCaseFirstLetter(type) + ' invitation',
        'Do you want to ' + this.upperCaseFirstLetter(type) + ' integration ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          if (type == 'facebook') {
            this.facebookID = 'void';
            this.googleID = null;
          } else if (type == 'google') {
            this.facebookID = null;
            this.googleID = 'void';
          }
          ref.close();
          ref.afterClosed().subscribe((res) => {
            this.validateAccount(true);
          });
        }
      });
    }
  }
}
