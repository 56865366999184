import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'merchant-services-overview',
  templateUrl: './merchant-services-overview.component.html',
  styleUrls: ['./merchant-services-overview.component.css'],
})
export class MerchantServicesOverviewComponent implements OnInit {
  tip: any;
  constructor() {}

  ngOnInit() {}

  createTip(tip) {
    this.tip = tip;
  }
}
