import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '../../shared/shared.module';

import { ArchwizardModule } from 'angular-archwizard';
import { NgDatepickerModule } from 'ng2-datepicker';
import { BankAccountCreateEditComponent } from './bank-account-create-edit/bank-account-create-edit.component';
import { BankAccountListComponent } from './bank-account-list/bank-account-list.component';
import { BankAccountListUserComponent } from './bank-account-user/BankAccountListUserComponent';
import { BankAccountViewComponent } from './bank-account-view/bank-account-view.component';
import { PaymentListComponent } from './payment-list/payment-list.component';
import { PaymentViewComponent } from './payment-view/payment-view.component';
import { BankAccountRoutingModule } from './shared/bank-account-routing.module';
import { BankAccountService } from './shared/bank-account.service';

@NgModule({
  imports: [
    CommonModule,
    NgPipesModule,
    FormsModule,
    ReactiveFormsModule,
    CreditCardDirectivesModule,
    // SelectModule,
    NgDatepickerModule,
    SharedModule,
    ArchwizardModule,
    BankAccountRoutingModule,
  ],
  declarations: [
    BankAccountViewComponent,
    BankAccountCreateEditComponent,
    BankAccountListComponent,
    BankAccountListUserComponent,
    PaymentListComponent,
    PaymentViewComponent,
  ],
  exports: [
    BankAccountViewComponent,
    BankAccountCreateEditComponent,
    BankAccountListComponent,
    BankAccountListUserComponent,
    PaymentListComponent,
    PaymentViewComponent,
  ],
  providers: [BankAccountService],
})
export class BankAccountModule {}
