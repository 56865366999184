import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { TimeLabelPast } from './time-label-past.pipe';

@Pipe({
  name: 'timeLeft',
})
export class TimeLeftPipe implements PipeTransform {
  constructor(private timeLabelPast: TimeLabelPast) {}

  transform(expression: any, toDate = null, _format = null, withAgo = true, onlyOne = false): string {
    let format = ['days', 'mins', 'hours'];

    try {
      if (!expression) {
        return null;
      } else if (expression == '0000-00-00') {
        return null;
      } else if (expression == '0000-00-00 00:00') {
        return null;
      }
      if (_format) {
        if (Array.isArray(_format) && _format.length > 0) {
          format = _format;
        } else if (typeof _format == 'string') {
          format = _format.split(',');
        }
      }

      const raw = moment(expression);
      let todayRaw = moment();
      if (toDate && toDate != '0000-00-00' && toDate != '0000-00-00 00:00') {
        todayRaw = moment(toDate);
      }

      const result = [];

      const isPast = raw.toDate() < todayRaw.toDate();

      const diff = Math.abs(raw.valueOf() - todayRaw.valueOf());
      const years = Math.floor(Number(Math.abs(moment(todayRaw).diff(raw, 'months', true))) / 12);
      const months = Math.floor(Number(Math.abs(moment(todayRaw).diff(raw, 'months', true)))) % 12;
      const weeks = Number(Number(Math.abs(moment(todayRaw).diff(raw, 'weeks', true))).toFixed(0)) % 4;
      let days = Math.floor(diff / 1000 / 60 / 60 / 24);

      if (format && format.indexOf('weeks') != -1) {
        days = days % 365;
      } else if (format && format.indexOf('months') != -1) {
        days = days % 30;
      } else if (format && format.indexOf('years') != -1) {
        days = days % 7;
      }

      const hours = Math.floor(diff / 1000 / 60 / 60) % 24;
      const mins = Math.floor(diff / 1000 / 60) % 60;

      if (years > 0 && format && format.indexOf('years') != -1) {
        result.push(`${years} year(s)`);
      }

      if (months > 0 && format && format.indexOf('months') != -1) {
        result.push(`${months} month(s)`);
      }

      if (weeks > 0 && format && format.indexOf('weeks') != -1) {
        result.push(`${weeks} week(s)`);
      }

      if (days > 0 && format && format.indexOf('days') != -1) {
        result.push(`${days} day(s)`);
      }

      if (hours > 0 && format && format.indexOf('hours') != -1) {
        result.push(`${hours} hour(s)`);
      }

      if (mins > 0 && format && format.indexOf('mins') != -1) {
        result.push(`${mins} min(s)`);
      }

      if (!result || (result && result.length <= 0)) {
        if (format.indexOf('hours') != -1) {
          result.push('few mins');

          if (isPast && withAgo == true) {
            result.push('Ago');
          }
        } else if (format.indexOf('days') != -1) {
          result.push('few hours');

          if (isPast && withAgo == true) {
            result.push('Ago');
          }
        } else if (format.indexOf('weeks') != -1) {
          result.push('few days');
          if (isPast && withAgo == true) {
            result.push('Ago');
          }
        } else if (format.indexOf('months') != -1) {
          result.push('few weeks');

          if (isPast && withAgo == true) {
            result.push('Ago');
          }
        } else if (format.indexOf('year') != -1) {
          result.push('few months');

          if (isPast && withAgo == true) {
            result.push('Ago');
          }
        } else {
          result.push('recently');
        }
      } else {
        if (isPast && withAgo == true) {
          result.push('Ago');
        }
      }

      if (onlyOne === true && result[0]) {
        return result[0];
      } else {
        return result.join(' ');
      }
    } catch (e) {
      return null;
    }
  }
}
