import { NgModule } from '@angular/core';
import { AuthenticationService } from '../../core/authentication/shared/authentication.service';
import { SharedModule } from '../../shared/shared.module';
import { InformedConsentTreatmentPresentedPage } from './informed-consent-treatment-presented/informed-consent-treatment-presented.page';
import { InformedConsentTreatmentPresentedComponent } from './informed-consent-treatment-presented/shared/components/informed-consent-treatment-presented.component';
import { InformedConsentRoutingModule } from './shared/informed-consent-routing.module';
import { InformedConsentService } from './shared/informed-consent.service';

@NgModule({
  imports: [SharedModule, InformedConsentRoutingModule],
  declarations: [InformedConsentTreatmentPresentedPage, InformedConsentTreatmentPresentedComponent],
  exports: [InformedConsentTreatmentPresentedPage, InformedConsentTreatmentPresentedComponent],
  providers: [AuthenticationService, InformedConsentService],
})
export class InformedConsentModule {}
