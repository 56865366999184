import 'hammerjs';
import { environment } from 'environments/environment';
import { ServiceFactoryService } from '../../../common/shared/services/service-factory.service';
import { NgGapiClientConfig } from 'ng-gapi';
// GLOBAL STATE
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { NgxMonacoEditorConfig } from 'ngx-monaco-editor';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { httpFactory } from '../../../common/shared/types/http.factory';
// CONFIG
const gapiClientConfig = {
    client_id: environment.client_id,
    discoveryDocs: environment.discoveryDocs,
    scope: environment.scope,
};
const monacoConfig = { baseUrl: '/assets', defaultOptions: { scrollBeyondLastLine: false } };
const customCurrencyMaskConfig = {
    align: 'right',
    allowNegative: false,
    allowZero: true,
    decimal: '.',
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    nullable: true,
    min: null,
    max: null,
    inputMode: CurrencyMaskInputMode.NATURAL,
};
const DEFAULT_SWIPER_CONFIG = { direction: 'horizontal', slidesPerView: 'auto' };
export function initFunction(config) {
    return () => config.getSettings();
}
// AoT requires an exported function for factories
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http, environment.nodeUrl + '/translate/cached/', '.json');
}
const ɵ0 = gapiClientConfig, ɵ1 = adapterFactory, ɵ2 = {}, ɵ3 = DEFAULT_SWIPER_CONFIG, ɵ4 = { useUtc: true }, ɵ5 = httpFactory;
export class AppMerchantModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
