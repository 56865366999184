import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ngIfRowInAnimation, ngIfRowOutAnimation } from '../../animations/ngIf/ngIfRow.animation';

const animations = [
  trigger('ngIfAnimation', [
    transition('void => *', [
      useAnimation(ngIfRowInAnimation, {
        params: {
          timings: '0.8s',
        },
      }),
    ]),
    transition('* => void', [
      useAnimation(ngIfRowOutAnimation, {
        params: {
          timings: '0.8s',
        },
      }),
    ]),
  ]),
];

@Component({
  selector: 'ipv-fetching-list-message',
  templateUrl: './fetching-list-message.component.html',
  styleUrls: ['./fetching-list-message.component.css'],
  animations,
})
export class FetchingListMessageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
