import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LookupService } from '../../../shared/services/lookup.service';

@Component({
  selector: 'app-patient-profile-merchant',
  templateUrl: './patient-profile-merchant.component.html',
  styleUrls: ['./patient-profile-merchant.component.css'],
})
export class PatientProfileMerchantComponent implements OnInit {
  @Input() existingPatientID;
  @Input() patient = {};
  existingPatient;

  idAdressValide = false;
  mySuburb;
  myState;
  myPostCode;
  myStreetNumber;
  myStreetName;
  myUnitNumber;
  myStreetType;
  myLatitude;
  myLongitude;

  statuss = [
    { value: 'Inactive', viewValue: 'Inactive' },
    { value: 'Active', viewValue: 'Active' },
  ];

  consTypes = [
    { value: '0', viewValue: 'Prospect' },
    { value: '1', viewValue: 'Lead' },
  ];

  genders = [];

  types = [
    { Code: 'Consumer - Individual', Label: 'Consumer - Individual' },
    { Code: 'Merchant', Label: 'Merchant' },
  ];

  contactTypes = [
    { value: '0', viewValue: 'Home' },
    { value: '1', viewValue: 'Work' },
  ];

  socialContactTypes = [
    { value: '0', viewValue: 'Gmail' },
    { value: '1', viewValue: 'Facebook' },
    { value: '2', viewValue: 'Skype' },
  ];

  countryNames = [
    { value: 'au', viewValue: 'Australia' },
    { value: 'nz', viewValue: 'New Zealand' },
  ];

  addressTypes = [
    { value: '0', viewValue: 'Physical' },
    { value: '1', viewValue: 'PO Box' },
  ];

  occupations = [
    { value: '0', viewValue: 'Occupation Name' },
    { value: '1', viewValue: 'Occupation Name 2' },
    { value: '2', viewValue: 'Occupation Name 3' },
    { value: '3', viewValue: 'Occupation Name 4' },
  ];

  relationships = [
    { value: '0', viewValue: 'Single' },
    { value: '1', viewValue: 'DeFacto' },
    { value: '2', viewValue: 'Married' },
    { value: '3', viewValue: 'Divorced' },
  ];

  constructor(
    private lookupService: LookupService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.patient = data;
    }
  }

  ngOnInit() {
    this.lookupService.getLookup('CodeLookup', 'Gender').subscribe((data) => {
      this.genders = data;
    });

    this.mySuburb = this.patient['addresses.Suburb'];
    this.myState = this.patient['addresses.State'];
    this.myPostCode = this.patient['addresses.Postcode'];
    this.myStreetNumber = this.patient['addresses.Street Nr'];
    this.myStreetName = this.patient['addresses.Street Name'];
    this.myUnitNumber = this.patient['addresses.Unit'];
    this.myStreetType = this.patient['addresses.Street Type'];
    this.myLatitude = this.patient['Address.Latitude'];
    this.myLongitude = this.patient['Address.Longitude'];
  }

  getAdress(adr) {
    if (adr) {
      this.mySuburb = adr.suburb;
      this.myState = adr.state;
      this.myPostCode = adr.postCode;
      this.myStreetNumber = adr.streetNumber;
      this.myStreetName = adr.streetName;
      this.myUnitNumber = adr.unitNumber;
      this.myStreetType = adr.streetType;
      this.myLatitude = adr.latitude;
      this.myLongitude = adr.longitude;
    }
  }

  isAdressValid(v) {
    this.idAdressValide = v || false;
  }
}
