/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./merchant-map-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/map-view/map-view.component.ngfactory";
import * as i3 from "../../../shared/components/map-view/map-view.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../../core/authentication/shared/authentication.service";
import * as i6 from "@agm/core";
import * as i7 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i8 from "@angular/material/icon";
import * as i9 from "@angular/common";
import * as i10 from "./merchant-map-modal.component";
import * as i11 from "../shared/merchant.service";
import * as i12 from "../../../shared/pipes/custom-phone.pipe";
import * as i13 from "@angular/router";
var styles_MerchantMapModalComponent = [i0.styles];
var RenderType_MerchantMapModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MerchantMapModalComponent, data: { "animation": [{ type: 7, name: "onChangeAnimation", definitions: [{ type: 1, expr: "void => *", animation: [{ type: 11, selector: ".animate", animation: { type: 6, styles: { opacity: 0 }, offset: null }, options: { optional: true } }, { type: 11, selector: ".animate", animation: { type: 12, timings: "100ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 0, offset: 0, height: 0 }, offset: null }, { type: 6, styles: { opacity: 1, offset: 1, height: "*" }, offset: null }] }, timings: "0.15s ease-in-out" }] }, options: { optional: true } }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 11, selector: ".animate", animation: { type: 6, styles: { opacity: 1 }, offset: null }, options: { optional: true } }, { type: 11, selector: ".animate", animation: { type: 12, timings: "100ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 1, offset: 0, height: "*" }, offset: null }, { type: 6, styles: { opacity: 0, offset: 1, height: 0 }, offset: null }] }, timings: "0.15s ease-in-out" }] }, options: { optional: true } }], options: null }], options: {} }] } });
export { RenderType_MerchantMapModalComponent as RenderType_MerchantMapModalComponent };
function View_MerchantMapModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "subLabel"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.merchant["addresses.Calculated"]; _ck(_v, 1, 0, currVal_0); }); }
function View_MerchantMapModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-map-view", [["class", ""]], null, [[null, "notFoundEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("notFoundEvent" === en)) {
        var pd_0 = (_co.notFoundEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MapViewComponent_0, i2.RenderType_MapViewComponent)), i1.ɵdid(1, 114688, null, 0, i3.MapViewComponent, [[2, i4.MAT_DIALOG_DATA], i5.AuthenticationService, i6.MapsAPILoader, i6.GoogleMapsAPIWrapper], { forceGettingAddress: [0, "forceGettingAddress"], streetName: [1, "streetName"], streetNumber: [2, "streetNumber"], latitude: [3, "latitude"], longitude: [4, "longitude"], unitNumber: [5, "unitNumber"], canClose: [6, "canClose"], canClick: [7, "canClick"], zoom: [8, "zoom"], state: [9, "state"], icon: [10, "icon"], suburb: [11, "suburb"], streetType: [12, "streetType"], showDetails: [13, "showDetails"], postCode: [14, "postCode"] }, { notFoundEvent: "notFoundEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = _co.streetName; var currVal_2 = _co.streetNumber; var currVal_3 = _co.merchant["Address.Latitude"]; var currVal_4 = _co.longitude; var currVal_5 = _co.unitNumber; var currVal_6 = false; var currVal_7 = false; var currVal_8 = 17; var currVal_9 = _co.state; var currVal_10 = _co.iconAddres; var currVal_11 = _co.suburb; var currVal_12 = _co.streetType; var currVal_13 = false; var currVal_14 = _co.postCode; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14]); }, null); }
function View_MerchantMapModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "rel dentist-card"]], [[24, "@onChangeAnimation", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "animate"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 15, "mat-dialog-content", [["class", "DentistMapProfile mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i4.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "card-header primary-gradient-img clearfix stacked"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "button", [["class", "btn-close btn-clear mat-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(7, 9158656, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null], [2, i8.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["clear"])), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "row titleArea clearfix text-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "h2", [["class", "summary-header rm-m"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MerchantMapModalComponent_2)), i1.ɵdid(14, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MerchantMapModalComponent_3)), i1.ɵdid(17, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 7, 0); var currVal_4 = _co.merchant["addresses.Calculated"]; _ck(_v, 14, 0, currVal_4); var currVal_5 = (_co.addressLabel && (_co.displayMapFlag == true)); _ck(_v, 17, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 7).inline; var currVal_2 = (((i1.ɵnov(_v, 7).color !== "primary") && (i1.ɵnov(_v, 7).color !== "accent")) && (i1.ɵnov(_v, 7).color !== "warn")); _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = _co.merchant["TradingAs"]; _ck(_v, 12, 0, currVal_3); }); }
export function View_MerchantMapModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MerchantMapModalComponent_1)), i1.ɵdid(1, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.merchant; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MerchantMapModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-merchant-map-modal", [], null, null, null, View_MerchantMapModalComponent_0, RenderType_MerchantMapModalComponent)), i1.ɵdid(1, 638976, null, 0, i10.MerchantMapModalComponent, [i11.MerchantService, i5.AuthenticationService, i12.CustomPhonePipe, i13.ActivatedRoute, [2, i4.MAT_DIALOG_DATA]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MerchantMapModalComponentNgFactory = i1.ɵccf("app-merchant-map-modal", i10.MerchantMapModalComponent, View_MerchantMapModalComponent_Host_0, { merchantID: "merchantID", invitationID: "invitationID", addressLine: "addressLine", canClose: "canClose", positions: "positions", postCode: "postCode", unitNumber: "unitNumber", suburb: "suburb", state: "state", streetName: "streetName", streetNumber: "streetNumber", streetType: "streetType", zoom: "zoom", icon: "icon", country: "country", moreInfo: "moreInfo", addressLabel: "addressLabel", latitude: "latitude", longitude: "longitude" }, { close: "close", loading: "loading" }, []);
export { MerchantMapModalComponentNgFactory as MerchantMapModalComponentNgFactory };
