<app-customer-edit
  class=""
  *ngIf="customerID"
  [customerID]="customerID"
  [isConcumer]="isConcumer"
  (close)="closeEvent($event)"
>
</app-customer-edit>
<app-prospect-edit
  class=""
  *ngIf="prospectID"
  [prospectID]="prospectID"
  [isConcumer]="isConcumer"
  (close)="closeEvent($event)"
>
</app-prospect-edit>
