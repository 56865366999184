import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmDialogSingleButtonComponent } from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { LookupService } from '../../../shared/services/lookup.service';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { MedicalHistoryService } from '../../medical-history/shared/medical-history.service';

@Component({
  selector: 'app-marketing-filter-create-section',
  templateUrl: './marketing-filter-create-section.component.html',
  styleUrls: ['./marketing-filter-create-section.component.css'],
})
export class MarketingFilterCreateSectionComponent implements OnInit {
  @Input()
  merchantID;

  @Input()
  filter;

  @Input()
  isEdit;

  @Output()
  getFilter = new EventEmitter();

  @Output()
  getPropertyConjunction = new EventEmitter();

  isInitial = true;

  sections = [];

  filterProperties = [];

  selectedSection;

  hasMedicalHistory = false;
  propertyConjunction = 'and';
  showConjunction = false;

  resultArray = [];

  singleFilterID;

  constructor(
    private lookupService: LookupService,
    private medicalHistoryService: MedicalHistoryService
  ) {}

  ngOnInit() {
    this.lookupService.getLookup('CodeLookup', 'NamedFilterSection_MED').subscribe((med) => {
      if (med) {
        this.lookupService.getLookup('CodeLookup', 'NamedFilterSection_TRT').subscribe((trt) => {
          if (trt) {
            const _listTemp = med.concat(trt);

            const order = ['CRD', 'MH', 'AL', 'IL', 'INV', 'CNT', 'PRD', 'BRN', 'TYP', 'CAT'];

            this.sections = _listTemp.sort((a, b) => {
              return order.indexOf(a['Code']) - order.indexOf(b['Code']);
            });

            if (this.filter) {
              this.selectedSection = this.sections.filter((x) => x['Code'] == this.filter['TypeCode'])[0];

              if (this.filter['Conjunction']) {
                this.propertyConjunction = this.filter['Conjunction']['BetweenLines'];
              }
              if (this.filter['Lines']) {
                this.filterProperties = this.filter['Lines'];
              }

              if (this.filter['ID']) {
                this.singleFilterID = this.filter['ID'];
              }
            }
          }
        });
      }
    });

    this.medicalHistoryService.getMedicalHistoryList(null, 'merchant').subscribe((res) => {
      if (res && res.length > 0) {
        this.hasMedicalHistory = true;
      }
    });
  }

  getSection(section) {
    if (section) {
      this.selectedSection = section;
      this.filterProperties = [
        {
          filterProperty: null,
          filterValue: null,
        },
      ];
      if (
        this.hasMedicalHistory == false &&
        (section['Code'] == 'AL' || section['Code'] == 'IL' || section['Code'] == 'MH')
      ) {
        const confirm = new ConfirmDialogSingleButton(
          'fas fa-info',
          'No Health History',
          'You have not got any health history data to use',
          'GOT IT'
        );

        const ref = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
          data: confirm,
          width: '650px',
        });

        ref.componentInstance.onConfirm.subscribe((res) => {
          ref.close();
        });
      }

      const result = {
        TypeCode: section['Code'],
        Label: section['Label'],
        Conjunction: {
          ToPrevious: null,
          BetweenLines: null,
        },
      };

      this.getFilter.emit(result);
    }
  }

  removeProperty(index) {
    if (this.filterProperties.length == 1) {
      this.filterProperties = [
        {
          filterProperty: null,
          filterValue: null,
        },
      ];
      this.resultArray = [];
    } else {
      this.filterProperties.splice(index, 1);
      this.filterProperties.splice(index, 1);
    }
  }

  clearProperty() {
    this.filterProperties = [
      {
        filterProperty: null,
        filterValue: null,
      },
    ];
    this.resultArray = [];
  }

  addProperty() {
    this.filterProperties.push({
      filterProperty: null,
      filterValue: null,
    });
  }

  getConjunction(e) {
    this.propertyConjunction = e;
    this.getPropertyConjunction.emit(e);
  }

  getPropertyEvent(e, index) {
    if (this.resultArray.length == 0) {
      if (e && e.filterSection && e.filterSection['Code']) {
        const sectionCode = e.filterSection['Code'];

        if (sectionCode == 'AL') {
          if (e.allergyCategory || e.allergyType) {
            this.resultArray.push(e);
            this.getFilter.emit(this.getAllergySection(this.resultArray));
          }
        } else if (sectionCode == 'IL') {
          if (e.illnessType) {
            this.resultArray.push(e);
            this.getFilter.emit(this.getIllnessSection(this.resultArray));
          }
        } else {
          if (e.filterValue || e.filterMinValue || e.filterMaxValu) {
            this.resultArray.push(e);
            this.getFilter.emit(this.getGeneralSection(sectionCode, this.resultArray));
          }
        }
      }
    } else {
      if (e && e.filterSection && e.filterSection['Code']) {
        const sectionCode = e.filterSection['Code'];

        if (sectionCode == 'AL') {
          if (e.allergyCategory || e.allergyType) {
            this.resultArray.splice(index, 1, e);
            this.getFilter.emit(this.getAllergySection(this.resultArray));
          }
        } else if (sectionCode == 'IL') {
          if (e.illnessType) {
            this.resultArray.splice(index, 1, e);
            this.getFilter.emit(this.getIllnessSection(this.resultArray));
          }
        } else {
          if (e.filterValue || e.filterMinValue || e.filterMaxValue) {
            this.resultArray.splice(index, 1, e);
            this.getFilter.emit(this.getGeneralSection(sectionCode, this.resultArray));
          }
        }
      }
    }
  }

  getAllergySection(list) {
    const allergyPart = {
      TypeCode: 'AL',
      Label: 'Health History - Allergies',
      Conjunction: {
        ToPrevious: null,
        BetweenLines: this.propertyConjunction,
      },
      Lines: [],
    };

    if (list.length > 0) {
      for (let i = 0; i < list.length; i++) {
        if (list[i]['allergyCategory'] && !list[i]['allergyType']) {
          allergyPart.Lines.push({
            Category: {
              Code: list[i]['allergyCategory']['Category'],
              Label: list[i]['allergyCategory']['DisplayLabel'],
            },
            Properties: [
              {
                Code: list[i]['filterProperty']['PropertyCode'],
                Label: list[i]['filterProperty']['PropertyLabel'],
                Value: list[i]['filterValue'],
              },
            ],
          });
        } else if (list[i]['allergyCategory'] && list[i]['allergyType']) {
          allergyPart.Lines.push({
            Category: {
              Code: list[i]['allergyCategory']['Category'],
              Label: list[i]['allergyCategory']['DisplayLabel'],
            },
            Type: {
              Code: list[i]['allergyType']['Code'],
              Label: list[i]['allergyType']['Label'],
            },
            Properties: [
              {
                Code: list[i]['filterProperty']['PropertyCode'],
                Label: list[i]['filterProperty']['PropertyLabel'],
                Value: list[i]['filterValue'],
              },
            ],
          });
        }
      }

      return allergyPart;
    }

    return null;
  }

  getIllnessSection(list) {
    const illnessPart = {
      TypeCode: 'IL',
      Label: 'Health History - Illnesses',
      Conjunction: {
        ToPrevious: null,
        BetweenLines: this.propertyConjunction,
      },
      Lines: [],
    };

    if (list.length > 0) {
      for (let i = 0; i < list.length; i++) {
        illnessPart.Lines.push({
          Type: {
            Code: list[i]['illnessType']['Code'],
            Label: list[i]['illnessType']['Label'],
          },
          Properties: [
            {
              Code: list[i]['filterProperty']['PropertyCode'],
              Label: list[i]['filterProperty']['PropertyLabel'],
              Value: list[i]['filterValue'],
            },
          ],
        });
      }

      return illnessPart;
    }
    return null;
  }

  getGeneralSection(sectionCode, list) {
    let sectionLabel;
    if (sectionCode == 'MH') {
      sectionLabel = 'Health History - Other Survey Data';
    } else if (sectionCode == 'CRD') {
      sectionLabel = 'Personal Details';
    } else if (sectionCode == 'INV') {
      sectionLabel = 'Treatment - Invitations';
    } else if (sectionCode == 'CNT') {
      sectionLabel = 'Treatment - Contracts';
    } else if (sectionCode == 'PRD') {
      sectionLabel = 'Treatment - Products';
    } else if (sectionCode == 'BRN') {
      sectionLabel = 'Treatment - Brands';
    } else if (sectionCode == 'TYP') {
      sectionLabel = 'Treatment - Types';
    } else if (sectionCode == 'CAT') {
      sectionLabel = 'Treatment - Categories';
    }

    const result = {
      TypeCode: sectionCode,
      Label: sectionLabel,
      Conjunction: {
        ToPrevious: null,
        BetweenLines: this.propertyConjunction,
      },
      Lines: [],
    };

    if (this.singleFilterID) {
      result['singleFilterID'] = this.singleFilterID;
    }

    if (list.length > 0) {
      for (let i = 0; i < list.length; i++) {
        if (list[i]['filterMinValue'] && list[i]['filterMaxValue']) {
          result.Lines.push({
            Properties: [
              {
                Code: list[i]['filterProperty']['PropertyCode'],
                Label: list[i]['filterProperty']['PropertyLabel'],
                ValueMin: list[i]['filterMinValue'],
                ValueMax: list[i]['filterMaxValue'],
              },
            ],
          });
        } else if (list[i]['filterMinValue'] && !list[i]['filterMaxValue']) {
          result.Lines.push({
            Properties: [
              {
                Code: list[i]['filterProperty']['PropertyCode'],
                Label: list[i]['filterProperty']['PropertyLabel'],
                ValueMin: list[i]['filterMinValue'],
              },
            ],
          });
        } else if (!list[i]['filterMinValue'] && list[i]['filterMaxValue']) {
          result.Lines.push({
            Properties: [
              {
                Code: list[i]['filterProperty']['PropertyCode'],
                Label: list[i]['filterProperty']['PropertyLabel'],
                ValueMax: list[i]['filterMaxValue'],
              },
            ],
          });
        } else {
          result.Lines.push({
            Properties: [
              {
                Code: list[i]['filterProperty']['PropertyCode'],
                Label: list[i]['filterProperty']['PropertyLabel'],
                Value: list[i]['filterValue'],
              },
            ],
          });
        }
      }

      return result;
    }

    return null;
  }
}
