import { Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-existing-patient-list-intro-modal',
  templateUrl: './existing-patient-list-intro-modal.component.html',
  styleUrls: ['./existing-patient-list-intro-modal.component.css'],
})
export class ExistingPatientListIntroModalComponent implements OnInit {
  showOnPatientListFirstVisit = JSON.parse(localStorage.getItem('showOnPatientListFirstVisit'));
  closeModal = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  doNotShow() {
    localStorage.setItem('showOnPatientListFirstVisit', JSON.stringify(this.showOnPatientListFirstVisit));

    this.closeModal.emit(this.showOnPatientListFirstVisit);
  }
}
