import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { SideMenuService } from '../../../shared/services/side-menu.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { EconnectFieldViewComponent } from '../econnect-field-view/econnect-field-view.component';
import { EconnectService } from '../shared/econnect.service';

@Component({
  selector: 'app-econnect-field-list',
  templateUrl: './econnect-field-list.component.html',
  styleUrls: ['./econnect-field-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class EconnectFieldListComponent implements OnInit {
  operations;
  isPromoterOrAdmin = false;

  @Input()
  pageSize = 100;
  pageSizeOptions = [10, 25, 50, 100, 200];

  @Input()
  tableID = 'Invitation_finance';
  selectedIDs = [];
  utils = new UtilsClass();
  displayedColumns = [
    'selectCheckBox',
    'copy',
    'name',
    'Type',
    'IsMandatory',
    'CanGet',
    'CanSetOnce',
    'CanModify',
    'CanQuery',
    'CanSort',
    'CanQueryWithIN',
    'QueryComparitors',
  ];

  filteredSize = null;
  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;
  fieldID;

  destroyEvent = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private econnectService: EconnectService,
    private dialog: MatDialog,
    private _clipboardService: ClipboardService,
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      this.activeRoute.params.subscribe((params) => {
        if (params['fieldID']) {
          this.fieldID = params['fieldID'];
        }

        if (this.fieldID) {
          this.econnectService.getFieldDetails(this.fieldID).subscribe((field) => {
            if (field) {
              this.openQuickViewDialog(field);
            }
          });
        }
      });
    });

    SideMenuService.goBack.subscribe((res) => {
      if (res == true) {
        this.hideViewDetails();
      }
    });
    const payload = {};
    this.listDB = new LoadRecords(
      this.econnectService,
      this.destroyEvent,
      this.tableID,
      payload,
      this.isPromoterOrAdmin
    );
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.selectedIDs = [];
    const payload = {};
    this.listDB = new LoadRecords(
      this.econnectService,
      this.destroyEvent,
      this.tableID,
      payload,
      this.isPromoterOrAdmin
    );
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  getType(r) {
    if (r && r.Type) {
      const a = r.Type;

      if (r.LookupInfo) {
        return 'Lookup';
      } else if (a == 'B') {
        return 'Boolean';
      } else if (a == 'T') {
        return 'String';
      } else if (a == 'D') {
        return 'Date';
      } else if (a == 'R') {
        return 'Number';
      } else if (a == 'L') {
        return 'String';
      } else if (a == 'JA') {
        return 'Json Array';
      } else {
        return 'String';
      }
    }
  }

  select(FieldName, isSelected) {
    if (isSelected == true && this.selectedIDs.indexOf(FieldName) == -1) {
      this.selectedIDs.push(FieldName);
    } else {
      const _index = this.selectedIDs.indexOf(FieldName);
      this.selectedIDs.splice(_index, 1);
    }
  }

  selectAll() {
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      const o = this.dataSource.filteredData[i];

      if (o && o['FieldName'] && this.selectedIDs.indexOf(o['FieldName']) == -1) {
        this.selectedIDs.push(o['FieldName']);
      }
    }
  }

  selectPage() {
    const index = this.dataSource['_paginator']['index'] || 0;
    const pageSize = this.dataSource['_paginator']['pageSize'] || 0;

    for (let i = 0; i < (index + 1) * pageSize; i++) {
      const o = this.dataSource.filteredData[index * pageSize + i];

      if (o && o['FieldName'] && this.selectedIDs.indexOf(o['FieldName']) == -1) {
        this.selectedIDs.push(o['FieldName']);
      }
    }
  }

  unselect() {
    this.selectedIDs = [];
  }

  isSelected(FieldName) {
    if (!this.selectedIDs || this.selectedIDs.length <= 0) {
      return false;
    } else {
      if (this.selectedIDs.indexOf(FieldName) != -1) {
        return true;
      }
    }

    return false;
  }

  setFilter(event, field) {
    let filter;

    if (event === true && typeof event === 'boolean') {
      filter = {
        field,
        value: '1',
      };
    } else if (event === false && typeof event === 'boolean') {
      filter = {
        field,
        value: '',
      };
    } else if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  copyString() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      this._clipboardService.copy("'" + this.selectedIDs.join(',') + "'");
      NotifyAppComponent.displayToast('success', 'Field name copied', 'Field name has been copied to your clipboard');
    }
  }

  copyArray() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      this._clipboardService.copy(JSON.stringify(this.selectedIDs));
      NotifyAppComponent.displayToast(
        'success',
        'Field names copied',
        'Field names have been copied to your clipboard'
      );
    }
  }

  copy(ID) {
    if (ID) {
      this.fieldID = ID;
      this._clipboardService.copy("'" + ID + "'");
      window.history.replaceState({}, '', `/admin/(adminPageRoute:econnect-documentation/${this.tableID}/${ID})`);
      NotifyAppComponent.displayToast('success', 'Field name copied', 'Field name has been copied to your clipboard');
    }
  }

  openQuickViewDialog(row) {
    if (row && row.ID) {
      this.fieldID = row.ID;
      window.history.replaceState({}, '', `/admin/(adminPageRoute:econnect-documentation/${this.tableID}/${row.ID})`);

      const ref = this.dialog.open(EconnectFieldViewComponent, {
        data: {
          field: row,
        },
        width: '640px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  toNumber(n) {
    if (n) {
      return Number(n);
    } else {
      return 0;
    }
  }

  hideViewDetails() {}

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  isDestoyed = false;
  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  serviceRef;

  constructor(
    private econnectService: EconnectService,
    private destroyEvent,
    tableID,
    params,
    private isPromoterOrAdmin
  ) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    if (tableID) {
      const payload = params || {};

      payload.orderBy = 'LastModified_Human';
      payload.section = 0;
      payload.limit = 500;
      payload.fields =
        'ID,TableName,FieldName,DepreciatedFieldName,Type,AlphaLength,Comments,IsMandatory,LookupInfo,CanGet,CanQuery,CanSetOnce,CanModify,CanSort,CanQueryWithIN,QueryComparitors';

      this.serviceRef = this.econnectService.getFieldList(tableID, payload).subscribe((res) => {
        this.count = res.length;
        this.items = res;

        this.dataChange.next(this.items.slice());
        this.firstLoad = true;
        const innerFunction = (section) => {
          section = section + 1;
          UtilsClass.loadingDataSection(section);
          payload.section = section;

          this.serviceRef = this.econnectService.getFieldList(tableID, payload).subscribe((res) => {
            if (res.length > 0) {
              this.count = res.count;
              this.items = this.items.concat(res);

              this.dataChange.next(this.items.slice());
              if (this.isDestoyed != true) {
                innerFunction(section);
              }
            } else {
              return true;
            }
          });
          UtilsClass.stopLoadingInterceptor();
        };

        if (this.isDestoyed != true) {
          innerFunction(1);
        }
      });
    }
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  set removeItem(id) {
    const data = this._tableDatabase.data.filter((row) => row.ID != id);
    this._tableDatabase.dataChange.next(data);
  }

  set replaceItem(item) {
    const _index = this._tableDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    this._tableDatabase.data[_index] = item;
    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
          let f = '';

          f = item[this.field] || '';

          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  getType(r) {
    if (r && r.Type) {
      const a = r.Type;

      if (r.LookupInfo) {
        return 'Lookup';
      } else if (a == 'B') {
        return 'Boolean';
      } else if (a == 'T') {
        return 'String';
      } else if (a == 'D') {
        return 'Date';
      } else if (a == 'R') {
        return 'Number';
      } else if (a == 'L') {
        return 'String';
      } else if (a == 'JA') {
        return 'Json Array';
      } else {
        return 'String';
      }
    }
  }

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'name':
          [propertyA, propertyB] = [a['FieldName'], b['FieldName']];
          break;
        case 'QueryComparitors':
          [propertyA, propertyB] = [a['QueryComparitors'], b['QueryComparitors']];
          break;

        case 'Type':
          [propertyA, propertyB] = [this.getType(a), this.getType(b)];
          break;
        case 'IsMandatory':
          [propertyA, propertyB] = [a['IsMandatory'], b['IsMandatory']];
          break;
        case 'CanGet':
          [propertyA, propertyB] = [a['CanGet'], b['CanGet']];
          break;

        case 'CanQueryWithIN':
          [propertyA, propertyB] = [a['CanQueryWithIN'], b['CanQueryWithIN']];
          break;

        case 'CanSetOnce':
          [propertyA, propertyB] = [a['CanSetOnce'], b['CanSetOnce']];
          break;
        case 'CanModify':
          [propertyA, propertyB] = [a['CanModify'], b['CanModify']];
          break;
        case 'CanQuery':
          [propertyA, propertyB] = [a['CanQuery'], b['CanQuery']];
          break;

        case 'CanSort':
          [propertyA, propertyB] = [a['CanSort'], b['CanSort']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
