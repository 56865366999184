var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OperatorContactComponent } from '../../../feature/operator/operator-contact/operator-contact.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { LogUserListComponent } from '../../log/log-user-list/log-user-list.component';
import { AgentDetailsComponent } from '../agent-details/agent-details.component';
import { SessionsService } from '../shared/sessions.service';
export class ConnectionHistoryViewComponent {
    constructor(customPhone, sessionService, activeRoute, data) {
        this.customPhone = customPhone;
        this.sessionService = sessionService;
        this.activeRoute = activeRoute;
        this.data = data;
        this.cnhID = null;
        this.close = new EventEmitter();
        this.isModal = true;
        if (data && typeof data == 'object') {
            this.cnhID = data.ID;
            this.isModal = true;
        }
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.cnhID) {
                const params = yield this.activeRoute.params.toPromise();
                if (params && params['cnhID ']) {
                    this.cnhID = params['cnhID '];
                }
            }
            if (this.cnhID) {
                this.sessionService.getOneConnectionHistory(this.cnhID).subscribe((res) => {
                    this.cnHistory = res;
                });
            }
        });
    }
    displayContact() {
        RootAppComponent.dialog.open(OperatorContactComponent, {
            width: '600px',
            data: this.cnHistory.id_4d,
        });
    }
    viewUserLogs() {
        const ref = RootAppComponent.dialog.open(LogUserListComponent, {
            data: this.cnHistory.id_4d,
            width: '90%',
            height: '80%',
        });
        ref.componentInstance.close.subscribe((res) => {
            if (res == true) {
                ref.close();
            }
        });
    }
    displayAgentDetails() {
        let label = 'Browser Details';
        if (this.cnHistory && this.cnHistory.name) {
            label = label + ': ' + this.cnHistory.name;
        }
        const ref = RootAppComponent.dialog.open(AgentDetailsComponent, {
            data: {
                label,
                agents: this.cnHistory.clientInfoHistory,
                cnhID: this.cnhID,
            },
        });
        ref.componentInstance.close.subscribe((res) => {
            if (res == true) {
                ref.close();
            }
        });
    }
    closeEvent() {
        this.close.emit(true);
    }
}
