import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-detect-browser',
  templateUrl: './detect-browser.component.html',
  styleUrls: ['./detect-browser.component.css'],
})
export class DetectBrowserComponent implements OnInit {
  dontShowBrowserWarning = JSON.parse(localStorage.getItem('dontShowBrowserWarning'));

  @Output()
  close = new EventEmitter();

  constructor() {}

  ngOnInit() {}
  onClose() {
    localStorage.setItem('dontShowBrowserWarning', JSON.stringify(this.dontShowBrowserWarning));

    this.close.emit(true);
  }
}
