<div class="rel">
  <button class="btn-close btn-clear mat-button" *ngIf="isModal == true" (click)="closeEvent()" tabIndex="-1">
    <mat-icon>clear</mat-icon>
  </button>

  <aw-wizard #wizard [disableNavigationBar]="true" navBarLayout="large-filled-symbols">
    <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Personalised message">
      <div class="clearfix" *ngIf="isAdminOrPromoter == true">
        <p class="help-label">
          To send an invitation on behalf of a merchant select a
          merchant below, otherwise, the invitation will be sent by the promoter.
        </p>
        <div class="row clearfix selectMerchant">
          <div class="icons-col">
            <mat-icon class="fas fa-store-alt"></mat-icon>
          </div>
          <div class="row-content-col">
            <mat-form-field class="margin-btm half-width" appearance="outline">
              <mat-label>Please Select a Merchant</mat-label>
              <mat-select
                class=""
                [(ngModel)]="merchant"
                (ngModelChange)="changeMerchant($event)"
                name="MerchantName"
                placeholder="Merchant"
              >
                <mat-option [value]="null"> My Account</mat-option>
                <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m">
                  <span
                    class="list-label pull-right"
                    *ngIf="m['Status'] && m['Status'] == 'Active'"
                    [style.background]="'#00d07a'"
                  >
                    {{ m['Status'] }}
                  </span>

                  <span
                    class="list-label pull-right"
                    *ngIf="m['Status'] && m['Status'] != 'Active'"
                    [style.background]="'#ff5722'"
                  >
                    {{ m['Status'] }}
                  </span>
                  {{ m['TradingAs'] || m['CalculatedName'] }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button
              class="pull-right merchBtn"
              *ngIf="merchant"
              (click)="viewMerchant()"
              mat-raised-button
              color="primary"
            >
              <mat-icon>person</mat-icon>
              <span class="mobHide">View Merchant</span>
            </button>
          </div>
        </div>
        <hr class="mt mb"/>
      </div>

      <div class="row clearfix">
        <div class="column clearfix">
          <div class="icons-col">
            <mat-icon>help</mat-icon>
          </div>
          <div class="row-content-col">
            <h3 class="sr-title" *ngIf="modalType && modalType == 'profile'">Profile Update Invitation</h3>
            <h3 class="sr-title" *ngIf="modalType && modalType == 'health'">Health History Invitation</h3>

            <p class="">
              You're about to send a bulk invitation. Add a personalised message yto
              your {{"KEYWORD.patient" | translate}}, choose who you'd like
              your invitation to come from, and then press Send!
            </p>
          </div>
        </div>
      </div>
      <!--  Step 2  -->
      <div class="" id="step-3">
        <div class="row clearfix">
          <!-- <mat-card class="rm-bp">
            <div class="colour-stripe "></div> -->
          <!-- normal invitation -->
          <div class="row clearfix">
            <div class="column clearfix">
              <div class="icons-col">
                <mat-icon>email</mat-icon>
              </div>
              <div class="row-content-col" id="defaultMes">
                <div class="row clearfix">
                  <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb pull-left">Your personal message</h3>
                  <!-- DEFAULT MSG -->
                  <div class="defaultMsgArea" *ngIf="defaultMessageLists.length <= 0 && isModuleCustomMessages == true">
                    <button
                      class="pull-right"
                      (click)="viewVideoInfo('Default messages', 'vimeo.com/358964874/19331ac58f')"
                      mat-icon-button
                      color="primary"
                      matTooltip="What's this?"
                    >
                      <mat-icon class="fas fa-info-circle mat-primary"></mat-icon>
                    </button>
                    <mat-form-field class="defaultMsg full-width" appearance="outline">
                      <mat-label>Add Default Message</mat-label>
                      <mat-select class="" name="messageOptionNull" placeholder="Add Default Message">
                        <mat-option (click)="addNewMessage()">
                          <p class="small rm-mt rm-mb">
                            <mat-icon class="addnew">add</mat-icon>
                            Create new
                          </p>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div
                    class="defaultMsgArea"
                    *ngIf="isModuleCustomMessages == true && defaultMessageLists && defaultMessageLists.length > 0"
                  >
                    <!-- <mat-icon *ngIf="defaultMessageLists && defaultMessageLists.length>0" class="pull-left">chat
                      </mat-icon> -->
                    <button
                      class="pull-right"
                      (click)="viewVideoInfo('Default messages', 'vimeo.com/358964874/19331ac58f')"
                      mat-icon-button
                      color="primary"
                      mdTooltip="What's this?"
                    >
                      <mat-icon class="fas fa-info-circle mat-primary"></mat-icon>
                    </button>

                    <mat-form-field
                      class="defaultMsg full-width"
                      *ngIf="defaultMessageLists && defaultMessageLists.length > 0"
                      appearance="outline"
                    >
                      <mat-label>Add Default Message</mat-label>
                      <mat-select
                        [(ngModel)]="messageOption"
                        (selectionChange)="defaultMessageChange(messageOption)"
                        name="messageOption"
                        placeholder="Add Default Message"
                      >
                        <mat-option *ngFor="let option of defaultMessageLists" [value]="option">
                          <p class="small rm-mt rm-mb">{{ option.label }}</p>
                        </mat-option>
                        <mat-divider></mat-divider>
                        <mat-option (click)="addNewMessage()">
                          <p class="small rm-mt rm-mb">
                            <mat-icon class="addnew">add</mat-icon>
                            Create new
                          </p>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <mat-form-field class="full-width" appearance="outline">
                  <textarea
                    class="dentMsg increase-input-field"
                    [(ngModel)]="message"
                    name="message"
                    matInput
                    placeholder="Personal message to appear with invitation*"
                  ></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="grey">
            <div class="row clearfix" id="senderName">
              <div class="column clearfix mb">
                <div class="icons-col">
                  <mat-icon>face</mat-icon>
                </div>
                <div class="row-content-col">
                  <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb">Senders name to appear with message</h3>
                  <div class="clearfix flex flex-center">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Sender</mat-label>
                      <mat-select
                        class=""
                        [(ngModel)]="contact"
                        (ngModelChange)="changeSender()"
                        name="ContactName"
                        placeholder="Contact"
                      >
                        <mat-option *ngFor="let m of contacts | orderBy: ['TradingAs', 'CalculatedName']" [value]="m">
                          {{ m['TradingAs'] || m['CalculatedName'] }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button
                      class="ml merchBtn"
                      *ngIf="contact"
                      (click)="viewDentist()"
                      mat-raised-button
                      color="primary"
                      matTooltip="View profile"
                    >
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- </mat-card> -->
        </div>
        <div class="row clearfix">
          <div class="full-width step-button-row text-center">
            <button
              class="step-send"
              class="btn-large"
              [disabled]="!message || contact == null"
              (click)="sendResult()"
              mat-raised-button
              color="accent"

            >
              Send
              <mat-icon>send</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <!--  / Step 3 -->
    </aw-wizard-step>
    <aw-wizard-completion-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Send">
      <div class="" id="step-4">
        <div class="card successful airplaneCard" *ngIf="complete == true" @simpleFadeAnimation>
          <div class="row clearfix anim">
            <div class="full-width text-center">
              <div class="column row clearfix">
                <h1 class="sm-h1 sr-title rm-mt">Invitations sent!</h1>
              </div>
              <div class="row clearfix mb">
                <app-plane-animation [flyAway]="flyAway"></app-plane-animation>
              </div>
              <div class="row finishBtns flex text-center">
                <button
                  class="step-back btn-small"
                  (click)="exitToDash()"
                  mat-raised-button
                  color="primary"

                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </aw-wizard-completion-step>
  </aw-wizard>
</div>
