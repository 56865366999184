<div class="rel clearfix lookup">
  <div class="card-header primary-gradient-img clearfix inModal">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <mat-icon class="pull-left fas fa-handshake mr"></mat-icon>
        <h2 class="summary-header rm-m">
          {{ title }}
          <span class="subLabel" [innerHTML]="description | safeHtml"></span>
        </h2>
      </div>
    </div>
  </div>

  <div class="clearfix flex flex-center">
    <mat-form-field class="full-width mr" appearance="outline">
      <mat-label>Search by label, description, features...</mat-label>
      <input
        [(ngModel)]="filterData"
        (ngModelChange)="backToOne()"
        matInput
        name="search"
        placeholder="Search by Title, Description,{{ " KEYWORD.Treatment" | translate }} Value And Item Number"
        tabIndex="1"
      />
    </mat-form-field>

    <button class="actionMenuToggle" [matMenuTriggerFor]="menu" mat-icon-button>
      <mat-icon class="fas fa-sort"></mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button (click)="orderBy('Web_Label')" mat-menu-item>
        <span>Label</span>
        <span *ngIf="orderByFilter === 'Web_Label' || orderByFilter === '-Web_Label'">
          <mat-icon class="accent-color" *ngIf="orderByFilter === '-Web_Label'">arrow_drop_up</mat-icon>
          <mat-icon class="accent-color" *ngIf="orderByFilter !== '-Web_Label'">arrow_drop_down</mat-icon>
        </span>
      </button>
      <button (click)="orderBy('Price')" mat-menu-item>
        <span>Price</span>
        <span *ngIf="orderByFilter === 'Price' || orderByFilter === '-Price'">
          <mat-icon class="accent-color" *ngIf="orderByFilter === '-Price'">arrow_drop_up</mat-icon>
          <mat-icon class="accent-color" *ngIf="orderByFilter !== '-Price'">arrow_drop_down</mat-icon>
        </span>
      </button>

      <button
        [ngClass]="{ 'accent-color': orderByFilter === 'DateTimeCreated' || orderByFilter === '-DateTimeCreated' }"
        (click)="orderBy('-created_at')"
        mat-menu-item
      >
        <span>Date</span>
        <span *ngIf="orderByFilter === 'DateTimeCreated' || orderByFilter === '-DateTimeCreated'">
          <mat-icon class="accent-color" *ngIf="orderByFilter === '-DateTimeCreated'">arrow_drop_down</mat-icon>
          <mat-icon class="accent-color" *ngIf="orderByFilter !== '-DateTimeCreated'"> arrow_drop_up</mat-icon>
        </span>
      </button>

      <button (click)="orderBy('Is_Public')" mat-menu-item>
        <span>Public</span>
        <span *ngIf="orderByFilter === 'Is_Public' || orderByFilter === '-Is_Public'">
          <mat-icon class="accent-color" *ngIf="orderByFilter === '-Is_Public'">arrow_drop_up</mat-icon>
          <mat-icon class="accent-color" *ngIf="orderByFilter !== '-Is_Public'">arrow_drop_down</mat-icon>
        </span>
      </button>

      <button (click)="orderBy('Is_SMS_Free')" mat-menu-item>
        <span>Free SMS</span>
        <span *ngIf="orderByFilter === 'Is_SMS_Free' || orderByFilter === '-Is_SMS_Free'">
          <mat-icon class="accent-color" *ngIf="orderByFilter === '-Is_SMS_Free'">arrow_drop_up</mat-icon>
          <mat-icon class="accent-color" *ngIf="orderByFilter !== '-Is_SMS_Free'">arrow_drop_down</mat-icon>
        </span>
      </button>
    </mat-menu>
  </div>

  <mat-dialog-content class="lookup-template" [ngClass]="{ 'no-modal-height': isModal != true }">
    <div class="patientContainer mt">
      <div
        class="patientRecord row clearfix"
        *ngFor="
          let plan of subscriptionPlans
            | FilterArrayMultipleOrContain: 'search':filterData
            | OrderArrayObject: [orderByFilter]
            | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }
        "
      >
        <div class="flex full-width clearfix mt mb normal-container">
          <div class="profile-result full-width flex">
            <!-- avatar image -->

            <app-ep-document-view-image
              class="subscription-plan-List-sub mr"
              [hideZoomButton]="true"
              [defaultPicture]="
                'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/light_icon2.png)'
              "
              [link]="getSubscriptionPlanImage(plan['SubscriptionPlan_key'], plan['LastModified'])"
            >
            </app-ep-document-view-image>

            <!-- profile information -->
            <div class="pull-left full-width truncate-normal" (click)="selectPlan(plan)">
              <div class="full-width mb">
                <h2 class="sr-title rm-m pull-left" [attr.style]="'color:' + plan.color + ' !important' | safeStyle">
                  <strong> {{ plan['Web_Label'] }}</strong>
                </h2>
                <p class="small rm-m mr date_label grey pull-right" *ngIf="plan.DateTimeCreated">
                  <mat-icon class="fas fa-clock"></mat-icon>
                  {{ plan.DateTimeCreated | TimeLabelPast }} ago
                </p>
              </div>
              <p class="reduce-marg small-margin-bottom" matLine>
                {{ plan['Web_Features'] }}
              </p>

              <div class="full-width clearfix">
                <h2
                  class="sr-title rm-m pull-right mr"
                  [attr.style]="'color:' + plan.color + ' !important' | safeStyle"
                >
                  <strong> {{ plan['Price'] | customCurrency: '1.0-3' }}</strong>
                </h2>
                <div class="pull-left text-left">
                  <span
                    class="value chip-new pull-left mr"
                    *ngIf="plan['Recommended_Plan'] === 'Yes'"
                    [attr.style]="'background:' + plan.color + ' !important' | safeStyle"
                  >Recommended</span
                  >

                  <span
                    class="value chip-new pull-left mr"
                    *ngIf="plan['Is_Default'] === '1'"
                    [attr.style]="'background:' + plan.color + ' !important' | safeStyle"
                  >Default</span
                  >

                  <span
                    class="value chip-new pull-left mr"
                    *ngIf="plan['Is_Public'] === '0'"
                    [attr.style]="'background:' + plan.color + ' !important' | safeStyle"
                  >Customized</span
                  >

                  <span
                    class="value chip-new pull-left mr"
                    *ngIf="plan['Is_Public'] === '1'"
                    [attr.style]="'background:' + plan.color + ' !important' | safeStyle"
                  >Public</span
                  >

                  <span
                    class="value chip-new pull-left mr"
                    *ngIf="plan['Is_SMS_Free'] === '1'"
                    [attr.style]="'background:' + plan.color + ' !important' | safeStyle"
                  >Free SMS</span
                  >
                </div>
              </div>
            </div>

            <button
              class="template-view-button"
              *ngIf="plan && plan['ID']"
              (click)="viewSubPlan(plan['ID'])"
              color="primary"
              mat-icon-button
              matTooltip="View Subscription Plan Details"
            >
              <mat-icon>visibility</mat-icon>
            </button>
          </div>
        </div>

        <mat-divider></mat-divider>
      </div>

      <div
        class="full-width"
        *ngIf="
          (subscriptionPlans
            | FilterArrayMultipleOrContain: 'search':filterData
            | OrderArrayObject: [orderByFilter]
            | lengthOfArray) <= 0 && isLoaded == true
        "
      >
        <app-empty-list-message [message]="'No Subscription plan found'"></app-empty-list-message>
      </div>
    </div>

    <div
      class="full-width text-center page-container mt flex mb"
      *ngIf="
        (subscriptionPlans
          | FilterArrayMultipleOrContain: 'search':filterData
          | OrderArrayObject: [orderByFilter]
          | lengthOfArray) > 20
      "
    >
      <div class="ml qtr-width flex pull-left">
        <mat-label class="line-h">Page size</mat-label>
        <mat-select class="page-size-in line-h pull-right" [(ngModel)]="itemsPerPage" placeholder="Page size">
          <mat-option [value]="20"> 20</mat-option>
          <mat-option [value]="30"> 30</mat-option>
          <mat-option [value]="40"> 40</mat-option>
          <mat-option [value]="50"> 50</mat-option>
        </mat-select>
      </div>
      <pagination-controls class="full-width clearfix" (pageChange)="changePage($event)" responsive="true">
      </pagination-controls>
    </div>
    <br/>
    <br/>
  </mat-dialog-content>

  <div class="drop-button full-width pull-right clearfix">
    <button
      class="inline btn-new-invite selectExistingPatient"
      (click)="closeEvent()"
      mat-raised-button
      color="primary"
    >
      Close
    </button>
  </div>
</div>
