<aw-wizard #wizard navBarLayout="large-filled-symbols">
  <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Personal Information">
    <!-- Step 1  -->

    <div class="container" id="step-1">
      <div class="row clearfix">
        <div class="full-width rel">
          <div class="text-center">
            <h2 class="sr-title inline-block pull-left">Add new {{ "KEYWORD.patient" | translate }} to
              your {{"KEYWORD.practice" | translate}}</h2>
          </div>
        </div>
      </div>

      <form #createForm="ngForm">
        <mat-card class="card rm-mb clearfix">
          <div class="colour-stripe"></div>
          <div class="clearfix" *ngIf="isPromoterOrAdmin == true && hideMerchantSelection == false">
            <p class="help-label">
              To add a {{ "KEYWORD.patient" | translate | titlecase }} on behalf of a merchant select a
              merchant below, otherwise, the {{ "KEYWORD.patient" | translate | titlecase }} will be
              created by the promoter.
            </p>
            <div class="row clearfix well">
              <mat-form-field class="margin-btm half-width" appearance="outline">
                <mat-label>Please Select a Merchant</mat-label>
                <mat-select class="" [(ngModel)]="merchantID" name="MerchantName" placeholder="">
                  <mat-option [value]="null"> My Account</mat-option>
                  <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m['ID']">
                    <span
                      class="list-label pull-right"
                      *ngIf="m['Status'] && m['Status'] == 'Active'"
                      [style.background]="'#00d07a'"
                    >
                      {{ m['Status'] }}
                    </span>

                    <span
                      class="list-label pull-right"
                      *ngIf="m['Status'] && m['Status'] != 'Active'"
                      [style.background]="'#ff5722'"
                    >
                      {{ m['Status'] }}
                    </span>
                    {{ m['TradingAs'] || m['CalculatedName'] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <button
                class="pull-right merchBtn"
                *ngIf="merchantID && merchantID != 'none'"
                (click)="viewMerchant()"
                mat-raised-button
                color="primary"

              >
                <mat-icon>person</mat-icon>
                <span class="mobHide">View Merchant</span>
              </button>
            </div>
          </div>

          <hr class="mt mb" *ngIf="isPromoterOrAdmin == true"/>

          <p class="help-label">
            Please start by entering the details of your {{"KEYWORD.patient" | translate}}, if we found any match you
            can make a selection to edit
            an existing {{"KEYWORD.patient" | translate}}
          </p>
          <div class="row clearfix">
            <div class="half-width">
              <div class="col-left">
                <div class="icons-col">
                  <mat-icon>person</mat-icon>
                </div>
                <div class="row-content-col">
                  <div class="clearfix">
                    <div class="half-width">
                      <div class="col-left">
                        <!-- Gender Select -->
                        <mat-form-field class="full-width" appearance="outline">
                          <mat-label>Gender</mat-label>
                          <mat-select
                            class=""
                            [(ngModel)]="existingPatient.gender"
                            [matTooltip]="
                              'Please select from the following list the ' +
                              ('KEYWORD.patient\'s' | translate) +
                              ' gender.  This field is required.'
                            "
                            name="gender"
                            placeholder="Gender"
                            required
                          >
                            <mat-option *ngFor="let gender of genders" [value]="gender.Code">
                              {{ gender.Label }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="half-width">
                      <div class="col-right">
                        <!-- Title Select -->
                        <mat-form-field class="full-width" appearance="outline">
                          <mat-label>Title</mat-label>
                          <mat-select
                            class=""
                            [(ngModel)]="existingPatient.title"
                            [matTooltip]="
                              'Please select from the following list the ' +
                              ('KEYWORD.patient\'s' | translate) +
                              ' title.  This field is required.'
                            "
                            name="title"
                            placeholder="Title"
                            required
                          >
                            <mat-option *ngFor="let title of titles" [value]="title.Label">
                              {{ title.Label }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <!-- First Name -->
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>First Name</mat-label>
                    <input
                      class="mat-block"
                      [(ngModel)]="existingPatient.firstName"
                      [matTooltip]="
                        'Please input ' + ('KEYWORD.patient\'s' | translate) + ' first name. This field is required'
                      "
                      name="firstname"
                      matInput
                      placeholder="First Name "
                      required
                    />
                  </mat-form-field>
                  <!-- Middle Name -->
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Middle Name</mat-label>
                    <input
                      class="mat-block"
                      [(ngModel)]="existingPatient.midleName"
                      [matTooltip]="'Please input ' + ('KEYWORD.patient\'s' | translate) + ' middle name.'"
                      name="middlename"
                      matInput
                      placeholder="Middle Name"
                    />
                  </mat-form-field>
                  <!-- Surname -->
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Surname</mat-label>
                    <input
                      class="mat-block"
                      [(ngModel)]="existingPatient.lastName"
                      [matTooltip]="
                        'Please input ' + ('KEYWORD.patient\'s' | translate) + ' last name. This field is required'
                      "
                      name="lastname"
                      matInput
                      placeholder="Surname "
                      required
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="half-width">
              <div class="col-right">
                <div class="row clearfix">
                  <div class="icons-col">
                    <mat-icon>phone</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <!-- Mobile Number -->
                    <app-phone-input
                      class="full-width"
                      [phoneNumber]="existingPatient.mobile"
                      [isLandLine]="false"
                      [label]="'Mobile'"
                      [required]="true"
                      (validate)="validateMobileEvent($event)"
                      (getPhone)="getMobile($event)"
                    >
                    </app-phone-input>
                    <app-phone-input
                      class="full-width"
                      [phoneNumber]="existingPatient.homePhone"
                      [isLandLine]="true"
                      [label]="'Phone Number'"
                      [required]="false"
                      (validate)="validatePhoneEvent($event)"
                      (getPhone)="getPhone($event)"
                    >
                    </app-phone-input>

                    <app-phone-input
                      class="full-width"
                      [phoneNumber]="existingPatient.workPhone"
                      [isLandLine]="true"
                      [label]="'Work Number'"
                      [required]="false"
                      (validate)="validateWorkPhoneEvent($event)"
                      (getPhone)="getWorkPhone($event)"
                    >
                    </app-phone-input>
                  </div>
                </div>
                <div class="row clearfix">
                  <div class="icons-col">
                    <mat-icon>email</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <!-- Email Address -->

                    <app-email-input
                      class="full-width"
                      [email]="existingPatient.email"
                      [label]="'Email'"
                      [required]="true"
                      (getEmail)="getEmail($event)"
                      (validate)="validateEmailEvent($event)"
                    >
                    </app-email-input>
                  </div>
                </div>

                <div class="row clearfix">
                  <div class="icons-col">
                    <mat-icon>calendar_today</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <app-date-input
                      class="full-width"
                      [defaultDate]="null"
                      [isTime]="false"
                      [startView]="'multi-year'"
                      [maxDate]="'toDay'"
                      (selectedDate)="selectDateToFilter($event)"
                      label="Date of birth"
                    >
                    </app-date-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- referral -->
          <div class="row clearfix full-width">
            <div class="col-left">
              <div class="row clearfix">
                <div class="icons-col">
                  <mat-icon class="noTop">record_voice_over</mat-icon>
                </div>
                <div class="row-content-col">
                  <div class="row clearfix">
                    <mat-checkbox class="referral" [(ngModel)]="isReffered" name="isConsumerLogin" color="primary">
                      Referred {{ "KEYWORD.patient" | translate }}?
                    </mat-checkbox>
                    <p class="small">Tick this if the {{ "KEYWORD.patient" | translate }} has been referred to
                      your {{"KEYWORD.practice" | translate}} by another {{ "KEYWORD.dentist" | translate }}.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr class="mt mb"/>

          <div class="row clearfix full-width">
            <app-address-input
              [displayMap]="true"
              [isRequired]="false"
              [postCode]="existingPatient.postCode"
              [state]="existingPatient.state"
              [suburb]="existingPatient.suburb"
              [streetNumber]="existingPatient.streetNumber"
              [streetName]="existingPatient.streetName"
              [unitNumber]="existingPatient.unitNumber"
              [streetType]="existingPatient.streetTypeCode"
              [latitude]="existingPatient.latitude"
              [longitude]="existingPatient.longitude"
              (getAdress)="getAdress($event)"
              (validate)="isAdressValidEvent($event)"
            ></app-address-input>
          </div>
        </mat-card>
        <div class="row clearfix">
          <div class="full-width step-button-row text-center">
            <button
              class="step-back"
              (click)="cancel()"
              mat-mini-fab
              color="primary"

            >
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <button
              class="step-next"
              class="btn-large"
              [disabled]="
                !createForm.form.valid ||
                isAdressValid == false ||
                validateEmail == false ||
                validatePhone == false ||
                validateWorkPhone == false ||
                validateMobile == false
              "
              [matTooltip]="'Click here to submit ' + ('KEYWORD.patient\'s' | translate) + ' details to the database'"
              (click)="createExistingPatient()"
              mat-raised-button
              color="accent"
            >
              Next
            </button>
          </div>
        </div>
      </form>
    </div>
  </aw-wizard-step>

  <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Submit">
    <!-- Step 1  -->
    <div class="container" id="step-1">
      <div class="row clearfix">
        <mat-card class="card successful">
          <div class="row clearfix">
            <div class="full-width text-center">
              <h1 class="sm-h1 sr-title">Well Done!</h1>
              <h3 class="sm-h3 rm-mt">
                {{ "KEYWORD.patient" | translate | titlecase }} record has been added Waiting to be Approved
              </h3>

              <div class="row clearfix">
                <div class="svg-box">
                  <svg class="circular green-stroke">
                    <circle class="path" cx="75" cy="75" r="50" fill="none" stroke-width="5" stroke-miterlimit="10" />
                  </svg>
                  <svg class="checkmark green-stroke">
                    <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                      <path class="checkmark__check" fill="none" d="M616.306,283.025L634.087,300.805L673.361,261.53" />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
      <div class="row clearfix">
        <div class="full-width text-center step-button-row">
          <button
            class="full-back"
            [routerLink]="['/merchant/', { outlets: { page: ['customer-prospect-list-overview'] } }]"
            mat-raised-button
            color="primary"

          >
            <mat-icon>chevron_left</mat-icon>
            {{ "KEYWORD.patient" | translate | titlecase }} List
          </button>

          <button
            class="full-back ml"
            *ngIf="patientID"
            [matTooltip]="'Click here to view the ' + ('KEYWORD.patient' | translate | titlecase) + '\'s' + ' details'"
            (click)="viewPatient()"
            mat-raised-button
            color="accent"
          >
            View Customer
          </button>
        </div>
      </div>
    </div>
  </aw-wizard-step>
</aw-wizard>
