<div class="row clearfix pContainer">
  <!-- blue heading -->
  <div class="card-header primary-gradient-img clearfix" *ngIf="isModal == true">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear pull-right mat-button" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <!-- merchant calculated name -->
      <div class="row titleArea clearfix">
        <mat-icon class="far fa-calendar-check pull-left"></mat-icon>
        <h2 class="rm-m">
          <span>Edit Appointment Lookup</span>
        </h2>
      </div>
    </div>
  </div>

  <div *ngIf="appointmentLookup">
    <form #aForm="ngForm">
      <!--  mat dialog scrolling area -->
      <mat-dialog-content>
        <!-- Label -->
        <div class="row clearfix mt">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Label</mat-label>
            <input class="mat-block" [(ngModel)]="appointmentLookup.Label" name="Label" matInput required/>
          </mat-form-field>
        </div>

        <!-- Description -->
        <div class="row clearfix">
          <div>
            <mat-form-field class="full-width messageContent" appearance="outline">
              <mat-label>Description</mat-label>
              <textarea
                class="dentMsg increase-input-field"
                [(ngModel)]="appointmentLookup.Description"
                name="Description"
                matInput
                placeholder="Enter Description"
                cols="30"
                rows="50"
                required
              ></textarea>
            </mat-form-field>
          </div>
        </div>

        <!-- Buffers and Duration -->
        <div class="row clearfix full-width" *ngIf="appointmentLookup.Lookup_Type !== 'BestAppointmentTime'">
          <p class="clearfix small">
            Insert a default duration and buffers , this can be changed when creating an appointment
          </p>

          <div class="full-width flex">
            <mat-form-field class="full-width mr" appearance="outline">
              <mat-label>Duration</mat-label>
              <mat-select
                class="full-width"
                [(ngModel)]="appointmentLookup.DefaultDuration"
                name="DefaultDuration"
                placeholder="Duration"
                required
              >
                <mat-option *ngFor="let p of durations" [value]="p.code">
                  {{ p.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!-- Buffers_Pre -->
            <mat-form-field class="full-width mr ml" appearance="outline">
              <mat-label>Buffers before appointment</mat-label>
              <mat-select
                class="full-width"
                [(ngModel)]="appointmentLookup.Buffers_Pre"
                name="Buffers_Pre"
                placeholder="Buffers Before Appointment"
                required
              >
                <mat-option *ngFor="let p of buffers" [value]="p.code">
                  {{ p.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!-- Buffers_Post -->
            <mat-form-field class="full-width ml" appearance="outline">
              <mat-label>Buffers after appointment</mat-label>
              <mat-select
                class="full-width"
                [(ngModel)]="appointmentLookup.Buffers_Post"
                name="Buffers_Post"
                placeholder="Buffers After Appointment"
                required
              >
                <mat-option *ngFor="let p of buffers" [value]="p.code">
                  {{ p.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!-- Duration -->
          </div>
        </div>

        <!-- Is Active -->
        <div class="row clearfix full-width">
          <mat-slide-toggle [(ngModel)]="appointmentLookup.Is_Active" name="Is_Active">Is Active</mat-slide-toggle>
        </div>
      </mat-dialog-content>
      <!-- fixed action buttons -->
      <mat-dialog-actions>
        <div class="row clearfix">
          <button class="pull-right" [disabled]="!aForm.form.valid" (click)="onSave()" mat-raised-button color="accent">
            Save
          </button>
        </div>
      </mat-dialog-actions>
    </form>
  </div>
</div>
