import { Component, EventEmitter, OnInit } from '@angular/core';
import { UtilsService } from '../../../shared/services/utils.service';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-settlement-create-intro-modal',
  templateUrl: './settlement-create-intro-modal.component.html',
  styleUrls: ['./settlement-create-intro-modal.component.css'],
})
export class SettlementCreateIntroModalComponent implements OnInit {
  showOnSettlementCreate = JSON.parse(localStorage.getItem('showSettlementCreate'));

  closeModal = new EventEmitter();

  isModuleTutorialsActive = Settings.global['isModuleTutorialsActive'];

  constructor(private utilService: UtilsService) {}

  ngOnInit() {
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleTutorialsActive = access['isModuleTutorialsActive'];
      }
    });
  }

  doNotShow() {
    localStorage.setItem('showSettlementCreate', JSON.stringify(this.showOnSettlementCreate));

    this.closeModal.emit(this.showOnSettlementCreate);
  }
}
