import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ContactUsService {
  constructor(private http: HttpClient) {}

  contactus(data) {
    return this.http.post(`${environment.nodeUrl}/message/us`, data);
  }

  messageUserUs(data) {
    return this.http.post(`${environment.nodeUrl}/message/user/us`, data);
  }

  messageUserUser(id, data) {
    return this.http.post(`${environment.nodeUrl}/message/user/user/${id}`, data);
  }

  messageUserInvitation(id, data, isAdmin = false) {
    if (isAdmin == true) {
      return this.http.post(`${environment.nodeUrl}/message/user/invitation/global/${id}`, data);
    } else {
      return this.http.post(`${environment.nodeUrl}/message/user/invitation/${id}`, data);
    }
  }

  messageUserGuest(data) {
    return this.http.post(`${environment.nodeUrl}/message/user/guest`, data);
  }

  messageGuestUs(data) {
    return this.http.post(`${environment.nodeUrl}/message/guest/us`, data);
  }

  messageGuestUser(id, data) {
    return this.http.post(`${environment.nodeUrl}/message/guest/user/${id}`, data);
  }

  messageInvitationUs(id, data) {
    return this.http.post(`${environment.nodeUrl}/message/invitation/us/${id}`, data);
  }

  messageInvitationUser(id, data) {
    return this.http.post(`${environment.nodeUrl}/message/invitation/user/${id}`, data);
  }

  checkUnsubscribe(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/listed-contacts/unsubscribe', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  unsubscribe(body): Observable<any> {
    return this.http
      .post(environment.nodeUrl + '/updateListedContacts/unsubscribe', body)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  subscribe(body): Observable<any> {
    return this.http
      .post(environment.nodeUrl + '/updateListedContacts/subscribe', body)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }
}
