<div class="full-width white-background">
  <h2 class="lg-h2 sr-title ml full-width" color="primary">Appointment types personalisation</h2>
  <p class="full-width ml">
    Drag and drop to sort your appointment types , click on
    <mat-icon>list</mat-icon>
    for 'more details' sections, click on action button to display operations, click on
    <button mat-raised-button color="accent">Save</button>
    to submit changes.
  </p>

  <div class="rel" *ngIf="isLoadingInit; else content" @ngIfAnimation>
    <div class="row clearfix padded rel">
      <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
    </div>
  </div>

  <ng-template #content>
    <div class="row full-width flex clearfix">
      <mat-card class="card full-width ml">
        <div class="colour-stripe"></div>

        <h3 class="sr-title sm-h3 rm-m full-width">
          Best Appointment Times

          <button class="pull-right" (click)="addBestContactTime()" mat-mini-fab color="accent">
            <mat-icon>add</mat-icon>
          </button>
        </h3>
        <div class="list-container mt full-width flex">
          <app-empty-list-message
            class="full-width"
            *ngIf="bestAppointmentTimes && bestAppointmentTimes.length === 0"
            [actionIcon]="'add'"
            [isAction]="true"
            [actionLabel]="'New Item'"
            (actionEvent)="addBestContactTime()"
          >
          </app-empty-list-message>

          <div
            class="list full-width ml mr"
            (cdkDropListDropped)="drop(bestAppointmentTimes, $event, 'bestContactTime')"
            cdkDropList
          >
            <div
              class="list-box"
              *ngFor="let item of bestAppointmentTimes; let idx = index"
              cdkDragLockAxis="y"
              cdkDrag
            >
              <mat-icon style="color: #ccc">drag_indicator</mat-icon>
              <div class="item-placeholder" *cdkDragPlaceholder></div>

              <div class="item-label" [matTooltip]="item.Description" matTooltipPosition="right">
                {{ item.Label }}
              </div>
              <span
                class="list-label smaller pull-right"
                *ngIf="item['Is_Active'] == '1'"
                matTooltip="Active"
                style="background-color: rgb(90, 186, 71)"
              >
                <mat-icon>check</mat-icon>
              </span>
              <span
                class="list-label smaller pull-right"
                *ngIf="item['Is_Active'] != '1'"
                matTooltip="Inactive"
                style="background-color: rgb(239, 71, 39)"
              >
                <mat-icon>close</mat-icon>
              </span>
              <ng-container
                *ngTemplateOutlet="
                  listMenuButtons;
                  context: {
                    $implicit: { list: bestAppointmentTimes, item: item }
                  }
                "
              ></ng-container>
            </div>
          </div>
        </div>
        <button
          class="mt btn-large"
          [disabled]="disableSaveBestContactTime"
          (click)="save(bestAppointmentTimes, 'bestContactTime')"
          mat-raised-button
          color="accent"
        >
          Save
        </button>
      </mat-card>

      <mat-card class="card full-width ml mr">
        <div class="colour-stripe"></div>
        <h3 class="sr-title sm-h3 rm-m full-width">
          Reason for visiting

          <button class="pull-right" (click)="addCategory()" mat-mini-fab color="accent">
            <mat-icon>add</mat-icon>
          </button>
        </h3>
        <div class="list-container mt full-width flex">
          <app-empty-list-message
            class="full-width"
            *ngIf="appointmentTypes && appointmentTypes.length === 0"
            [actionIcon]="'add'"
            [isAction]="true"
            [actionLabel]="'New Item'"
            (actionEvent)="addCategory()"
          >
          </app-empty-list-message>

          <div class="list full-width ml mr" (cdkDropListDropped)="drop(appointmentTypes, $event, 'type')" cdkDropList>
            <div
              class="list-box"
              *ngFor="let item of appointmentTypes; let idx = index"
              [ngClass]="{ active: item === selectedAppointmentType }"
              cdkDragLockAxis="y"
              cdkDrag
            >
              <mat-icon style="color: #ccc">drag_indicator</mat-icon>
              <div class="item-placeholder" *cdkDragPlaceholder></div>

              <div class="item-label" [matTooltip]="item.Description" matTooltipPosition="right">
                {{ item.Label }}
              </div>

              <button
                class="pull-right mr"
                (click)="selectAppointmentType(item)"
                mat-icon-button
                matTooltip="Show more details"
                color="primary"
              >
                <mat-icon>list</mat-icon>
              </button>

              <span
                class="list-label smaller pull-right"
                *ngIf="item['Is_Active'] == '1'"
                matTooltip="Active"
                style="background-color: rgb(90, 186, 71)"
              >
                <mat-icon>check</mat-icon>
              </span>
              <span
                class="list-label smaller pull-right"
                *ngIf="item['Is_Active'] != '1'"
                matTooltip="Inactive"
                style="background-color: rgb(239, 71, 39)"
              >
                <mat-icon>close</mat-icon>
              </span>
              <ng-container
                *ngTemplateOutlet="listMenuButtons; context: { $implicit: { list: appointmentTypes, item: item } }"
              ></ng-container>
            </div>
          </div>
        </div>
        <button
          class="mt btn-large"
          [disabled]="disableSaveType"
          (click)="save(appointmentTypes, 'type')"
          mat-raised-button
          color="accent"
        >
          Save
        </button>
      </mat-card>

      <mat-card class="card full-width ml">
        <div class="colour-stripe"></div>

        <h3 class="sr-title sm-h3 rm-m full-width">
          More details<span *ngIf="selectedAppointmentType && selectedAppointmentType.Label"
        >: {{ selectedAppointmentType.Label }}</span
        >

          <button
            class="pull-right"
            *ngIf="!isLoadingSubType && selectedAppointmentType && selectedAppointmentType.Label"
            (click)="addSubType()"
            mat-mini-fab
            color="accent"
          >
            <mat-icon>add</mat-icon>
          </button>
        </h3>
        <div class="list-container mt full-width flex">
          <app-empty-list-message
            class="full-width"
            *ngIf="!selectedAppointmentType"
            title="Make a selection"
            message="Please select a reason for visiting record to display more details"
          >
          </app-empty-list-message>
          <app-empty-list-message
            class="full-width"
            *ngIf="!isLoadingSubType && selectedAppointmentType && subTypes.length === 0"
            [actionIcon]="'add'"
            [isAction]="true"
            [actionLabel]="'New Item'"
            (actionEvent)="addSubType()"
            title="Nothing to show"
            message="No records under {{ selectedAppointmentType.Label }}"
          >
          </app-empty-list-message>
          <div
            class="list full-width ml mr"
            *ngIf="
              !isLoadingSubType &&
              selectedAppointmentType &&
              selectedAppointmentType.Code &&
              subTypes &&
              subTypes.length > 0
            "
            (cdkDropListDropped)="drop(subTypes, $event, 'subType')"
            cdkDropList
          >
            <div class="list-box" *ngFor="let item of subTypes; let idx = index" cdkDragLockAxis="y" cdkDrag>
              <mat-icon style="color: #ccc">drag_indicator</mat-icon>
              <div class="item-placeholder" *cdkDragPlaceholder></div>

              <div class="item-label" [matTooltip]="item.Description" matTooltipPosition="right">
                {{ item.Label }}
              </div>
              <span
                class="list-label smaller pull-right"
                *ngIf="item['Is_Active'] == '1'"
                matTooltip="Active"
                style="background-color: rgb(90, 186, 71)"
              >
                <mat-icon>check</mat-icon>
              </span>
              <span
                class="list-label smaller pull-right"
                *ngIf="item['Is_Active'] != '1'"
                matTooltip="Inactive"
                style="background-color: rgb(239, 71, 39)"
              >
                <mat-icon>close</mat-icon>
              </span>
              <ng-container
                *ngTemplateOutlet="listMenuButtons; context: { $implicit: { list: subTypes, item: item } }"
              ></ng-container>
            </div>
          </div>
        </div>
        <button
          class="mt btn-large"
          *ngIf="subTypes.length > 0"
          [disabled]="disableSaveSubType"
          (click)="save(subTypes, 'subType')"
          mat-raised-button
          color="accent"
        >
          Save
        </button>
      </mat-card>
    </div>
  </ng-template>

  <ng-template #listMenuButtons let-data>
    <button [matMenuTriggerFor]="menu" mat-icon-button>
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <div>
        <button (click)="edit(data.list, data.item)" mat-menu-item>
          <mat-icon>edit</mat-icon>
          <span>Edit</span>
        </button>

        <button *ngIf="data.item.Is_Active === '1'" (click)="disable(data.list, data.item)" mat-menu-item>
          <mat-icon>block</mat-icon>
          <span>Disable</span>
        </button>

        <button *ngIf="data.item.Is_Active === '0'" (click)="enable(data.list, data.item)" mat-menu-item>
          <mat-icon>check_circle_outline</mat-icon>
          <span>Enable</span>
        </button>

        <button (click)="remove(data.list, data.item)" mat-menu-item>
          <mat-icon>remove_circle</mat-icon>
          <span>Remove Appointment</span>
        </button>
      </div>
    </mat-menu>
  </ng-template>

  <p class="clearfix mb mt"></p>
</div>
