<div class="settlement-card" *ngIf="contract" @onChangeAnimation>
  <div class="animate">
    <mat-dialog-content>
      <div class="row clearfix contract-details-container animate">
        <div class="well clearfix simpleView">
          <div class="thrd-width">
            <div class="col-left">
              <label class="small">Amount available</label>
              <p class="largeValue">
                <span
                  *ngIf="contract['Settlements.NotYetDrawn']"
                  [ngClass]="toNumber(contract['Settlements.NotYetDrawn'] > 0) ? 'isAvailable' : 'noAvailable'"
                >
                  {{ contract['Settlements.NotYetDrawn'] | customCurrency }}</span
                >
                <span *ngIf="!contract['Settlements.NotYetDrawn']">...</span>
              </p>

              <hr/>

              <label class="small">{{"KEYWORD.Treatment" | translate}} value</label>
              <p class="rm-mt larger">{{ contract['Amount.Invoice'] | customCurrency }}</p>

              <label class="small">Creation date</label>
              <p class="rm-mt larger">{{ contract['ContractDate'] | customDate }}</p>
            </div>
          </div>
          <div class="thrd-width">
            <div class="col-left">
              <label class="small"
              >Settlements Requested
                <span class="chip">
                  {{
                  (contract.stats
                    | FilterArrayMultipleValue: 'ID':requestStatus
                    | objectPropertyValue: 'Sum(Settlement.Amount)'
                    | toNumberArray
                    | sum) / contract['Amount.Invoice'] | percent: '1.0-0'
                  }}
                </span>
              </label>
              <p class="largeValue">
                {{
                contract.stats
                  | FilterArrayMultipleValue: 'ID':requestStatus
                  | objectPropertyValue: 'Sum(Settlement.Amount)'
                  | toNumberArray
                  | sum
                  | customCurrency
                }}
              </p>
              <hr/>
              <label class="small"
              >Approved settlements
                <span class="chip">
                  {{
                  (contract.stats
                    | FilterArrayMultipleValue: 'ID':approveStatus
                    | objectPropertyValue: 'Sum(Settlement.Amount)'
                    | toNumberArray
                    | sum) / contract['Amount.Invoice'] | percent: '1.0-0'
                  }}
                </span>
              </label>
              <p class="rm-mt larger">
                {{
                contract.stats
                  | FilterArrayMultipleValue: 'ID':approveStatus
                  | objectPropertyValue: 'Sum(Settlement.Amount)'
                  | toNumberArray
                  | sum
                  | customCurrency
                }}
              </p>
              <label class="small"
              >Declined settlements
                <span class="chip">
                  {{
                  (contract.stats
                    | FilterArrayMultipleValue: 'ID':declinedStatus
                    | objectPropertyValue: 'Sum(Settlement.Amount)'
                    | toNumberArray
                    | sum) / contract['Amount.Invoice'] | percent: '1.0-0'
                  }}
                </span>
              </label>
              <p class="rm-mt larger">
                {{
                contract.stats
                  | FilterArrayMultipleValue: 'ID':declinedStatus
                  | objectPropertyValue: 'Sum(Settlement.Amount)'
                  | toNumberArray
                  | sum
                  | customCurrency
                }}
              </p>
            </div>
          </div>
          <div class="thrd-width">
            <label class="small"
            >Paid to {{"KEYWORD.practice" | translate}}
              <span class="chip">{{
                toNumber(contract['Settlements.Drawn']) / toNumber(contract['Amount.Invoice']) | percent: '1.0-0'
                }}</span></label
            >
            <p
              class="largeValue"
              [ngClass]="{
                isAvailable: toNumber(contract['Settlements.Drawn']) == toNumber(contract['Amount.Invoice'])
              }"
            >
              {{ contract['Settlements.Drawn'] | customCurrency }}
            </p>
            <hr/>
            <label class="small"
            >MSF
              <button class="pull-right" (click)="openConfirmDialog()" mat-button matTooltip="What does MSF mean?">
                <mat-icon>help</mat-icon>
              </button>
            </label>
            <p class="rm-mt larger">{{ contract['Merchant_Service_Fee'] || '0.00' | customCurrency }}</p>
            <label class="small">Contract expiry</label>
            <p class="rm-mt larger" [ngStyle]="{ color: canDrawDown() == true ? '' : 'red' }">
              {{ contract['NextDrawDown.Before'] | customDate }}
            </p>
          </div>
        </div>

        <div class="row clearfix flex btns">
          <button
            class="text-center btn-small full-width"
            (click)="viewCustomer()"
            mat-raised-button
            matDialogClose
            color="primary"
          >
            <mat-icon>person</mat-icon>
            View Customer
          </button>
          <button
            class="text-center btn-small full-width"
            (click)="viewInvitation()"
            mat-raised-button
            matDialogClose
            color="primary"
          >
            <mat-icon>description</mat-icon>
            View Invitation
          </button>
          <button
            class="btn-small text-center full-width"
            *ngIf="contract['Product_key'] && contract['Product_key'] != '00000000000000000000000000000000'"
            (click)="viewProduct()"
            color="primary"
            mat-raised-button
            matDialogClose
          >
            <mat-icon>business_center</mat-icon>
            View Product
          </button>
          <button
            class="text-center btn-small full-width gradient green"
            *ngIf="
              canDrawDown() == true &&
              contract['Status.Code'] == 'ACTIVE' &&
              toNumber(contract['Settlements.NotYetDrawn']) > 0
            "
            (click)="createSettlement()"
            mat-raised-button
            color="primary"
            matDialogClose
          >
            <mat-icon class="fas fa-file-contract" aria-hidden="true"></mat-icon>
            <span> New Settlement </span>
          </button>
        </div>
      </div>
    </mat-dialog-content>
  </div>
</div>
