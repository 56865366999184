import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExistingPatientService } from '../shared/existing-patient.service';

@Component({
  selector: 'app-existing-patient-view-picture',
  templateUrl: './existing-patient-view-picture.component.html',
  styleUrls: ['./existing-patient-view-picture.component.css'],
})
export class ExisitingPersonViewPictureComponent implements OnInit {
  @Input() existingPersonID;

  @Output() close = new EventEmitter();

  profileLink;

  constructor(
    private existingPersonFeature: ExistingPatientService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.existingPersonID = data;
    }
  }

  ngOnInit() {
    if (this.existingPersonID) {
      this.getProfilePicture();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.existingPersonID.previousValue != changes.existingPersonID.currentValue) {
      if (this.existingPersonID) {
        this.getProfilePicture();
      } else {
        this.profileLink = null;
      }
    }
  }

  getProfilePicture() {
    this.profileLink = null;

    this.profileLink = this.existingPersonFeature.getProfilePicStreamLink(this.existingPersonID);
  }

  closeEvent() {
    this.close.emit(true);
  }
}
