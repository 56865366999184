import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { LookupClass } from '../../../shared/types/lookupClass';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { InvitationExistingPatientListComponent } from '../../invitation/invitation-existing-patient-list/invitation-existing-patient-list.component';
import { ExistingPatientListIntroModalComponent } from '../existing-patient-list-intro-modal/existing-patient-list-intro-modal.component';
import { ExistingPatientSummaryComponent } from '../existing-patient-summary/existing-patient-summary.component';
import { ExistingPatientService } from '../shared/existing-patient.service';

import { FormControl } from '@angular/forms';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';

@Component({
  selector: 'app-existing-patient-list',
  templateUrl: './existing-patient-list.component.html',
  styleUrls: ['./existing-patient-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ExistingPatientListComponent implements OnInit {
  static closeMerchantSelectEvent = new EventEmitter();
  @Input()
  type = 'customerProspect';

  @Input()
  dateFrom;

  @Input()
  dateTo;

  @Input()
  customerID;

  displayDetailCard = false;

  isModuleCustomerActive = Settings.global['isModuleCustomerActive'];
  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  utils = new UtilsClass();
  lookup = new LookupClass();
  displayedColumns = ['LastName', 'FirstName', 'DateOfBirth', 'mobile', 'email', 'Actions'];

  role;

  filters = [];
  searchVals = new FormControl();

  searchValList = [
    'Date Of Birth',
    'First Name',
    'Last Name',
    'Email',
    'Mobile',
    'Invitations',
    'Customer',
    'Prospect',
  ];

  public existingPatientsDB: LoadExistingPatients | null;
  dataSource: ExistingPatientDataSource | null;

  typeLabel = 'Patients';
  subjectArray = [];
  filteredSize = null;

  destroyEvent = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private utilService: UtilsService,
    private authenticationService: AuthenticationService,
    private existingPatientService: ExistingPatientService,
    private dialog: MatDialog,
    private router: Router,
    private customDate: CustomDatePipe
  ) {}

  ngOnInit() {
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleCustomerActive = access['isModuleCustomerActive'];
      }

      if (this.type == 'customer') {
        this.typeLabel = 'Customers';
      } else if (this.type == 'prospect') {
        this.typeLabel = 'Prospects';
      } else if (this.type == 'invitation') {
        this.typeLabel = 'Invitations';
      } else {
        this.typeLabel = 'Patients';
      }

      this.authenticationService.getCurrentUser().subscribe((res) => {
        if (res && res.success && res.data['Role'] && res.data['Role']) {
          this.role = res.data['Role'];
        }
      });

      const ref = this.dialog.open(ExistingPatientListIntroModalComponent, {
        width: '800px',
        height: '650px',
        panelClass: 'noCard',
      });
      if (ref.componentInstance.showOnPatientListFirstVisit == true) {
        ref.close();
      } else {
        const sub = ref.componentInstance.closeModal.subscribe((data) => {
          ref.close();
        });
      }
      this.existingPatientsDB = new LoadExistingPatients(
        this.existingPatientService,
        this.destroyEvent,
        this.type,
        this.dateFrom,
        this.dateTo
      );
      this.dataSource = new ExistingPatientDataSource(this.existingPatientsDB, this.paginator, this.sort);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.type == 'customer') {
      this.typeLabel = 'Customers';
    } else if (this.type == 'prospect') {
      this.typeLabel = 'Prospects';
    } else if (this.type == 'invitation') {
      this.typeLabel = 'Invitations';
    } else {
      this.typeLabel = 'Patients';
    }

    this.existingPatientsDB = new LoadExistingPatients(
      this.existingPatientService,
      this.destroyEvent,
      this.type,
      this.dateFrom,
      this.dateTo
    );
    this.dataSource = new ExistingPatientDataSource(this.existingPatientsDB, this.paginator, this.sort);
  }

  setFilter(event, field) {
    let filter;
    if (typeof event == 'object' && event != null) {
      const v = this.customDate.transform(event, Settings.global['dateFormat']);
      filter = {
        field,
        value: v,
      };
    } else if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  openQuickViewDialog(id) {
    const ref = this.dialog.open(ExistingPatientSummaryComponent, {
      data: id,
      width: '600px',
      panelClass: 'noCard',
    });
  }

  // linkToInvitation(existingPatient) {
  //   this.existingPatientService.linkToInv(existingPatient.ID).subscribe(res => {
  //     if (res.success) {
  //       this.router.navigate([
  //         "/merchant",
  //         { outlets: { page: ["invitation-create", res.data] } }
  //       ]);
  //     }
  //   });
  // }

  //   populateDetailCard(existingPatient) {
  //     this.existingPatientService.getExistingPatientFullDetails(existingPatient.ID).subscribe(res => {
  //       console.log(res)
  //       if (res) {
  //     this.router.navigate([
  //       "/merchant",
  //       { outlets: { page: ["details-card-edit", res.data]}}
  //     ]);
  //   }
  // });
  //   }

  patientInvitation(id) {
    const ref = this.dialog.open(InvitationExistingPatientListComponent, {
      data: id,
      width: '80%',
      panelClass: 'noCard',
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  populateDetailCard(ID) {
    this.existingPatientService.getExistingPatientFullDetails(ID).subscribe((res) => {
      if (res['TableName'] == 'Customer') {
        this.router.navigate(['/merchant', { outlets: { page: ['customer-details-card-edit', res['ID']] } }]);
      } else if (res['TableName'] == 'Prospect') {
        this.router.navigate(['/merchant', { outlets: { page: ['prospect-details-card-edit', res['ID']] } }]);
      } else {
        NotifyAppComponent.displayToast(
          'error',
          'This is an invitation Patient!',
          'Needs to be removed from this list.'
        );
      }
    });
  }

  contact(ID) {
    const data = {
      targetType: 'existingPerson',
      targetID: ID,
      asGuest: false,
      asProfile: true,
      asPractice: true,
      subjectArray: this.subjectArray,
    };
    AuthenticationService.contactInputEvent.emit(data);
  }

  linkToInvitation(existingPatient) {
    this.existingPatientService.linkToInv(existingPatient.ID).subscribe((res) => {
      if (res.success) {
        this.router.navigate(['/merchant', { outlets: { page: ['invitation-create', res.data] } }]);
      }
    });
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadExistingPatients implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  existingPatients: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  isDestoyed = false;

  util = new UtilsClass();
  serviceRef;

  constructor(
    private existingPatientService: ExistingPatientService,
    private destroyEvent,
    private type,
    private dateFrom,
    private dateTo
  ) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });
    const payload = {
      type: this.type,
      dateFrom: this.util.EPdateFormat(this.dateFrom),
      dateTo: this.util.EPdateFormat(this.dateTo),
      Voided: 0,
      section: 0,
      fields: 'DateOfBirth,Email,FirstName,ID,LastName,Mobile,AsInvitation,AsCustomer,AsProspect',
    };

    this.serviceRef = this.existingPatientService.getExistingPatientsList(payload).subscribe((res) => {
      this.count = res.length;
      this.existingPatients = res;

      this.dataChange.next(this.existingPatients.slice());
      this.firstLoad = true;
      const innerFunction = (section) => {
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.existingPatientService.getExistingPatientsList(payload).subscribe((res) => {
          if (res.length > 0) {
            this.count = res.count;
            this.existingPatients = this.existingPatients.concat(res);

            this.dataChange.next(this.existingPatients);

            if (this.isDestoyed != true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };

      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class ExistingPatientDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  filteredData: any[] = [];

  constructor(private _custDatabase: LoadExistingPatients, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._custDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._custDatabase.data.slice().filter((item: any) => {
          let f = '';

          f = item[this.field] || '';

          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'LastName':
          [propertyA, propertyB] = [a.CalculatedName, b.CalculatedName];
          break;

        case 'FirstName':
          [propertyA, propertyB] = [a.CalculatedName, b.CalculatedName];
          break;

        case 'DateOfBirth':
          [propertyA, propertyB] = [
            moment(a['DateOfBirth']).toDate().getTime(),
            moment(b['DateOfBirth']).toDate().getTime(),
          ];
          break;

        case 'mobile':
          [propertyA, propertyB] = [a['Mobile'], b['Mobile']];
          break;
        case 'email':
          [propertyA, propertyB] = [a['Email'], b['Email']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
