<div class="row clearfix flex">
  <div class="icons-col">
    <mat-icon class="fas fa-mobile"></mat-icon>
  </div>
  <div class="row-content-col">
    <div class="row clearfix flex">
      <mat-form-field class="half-width" appearance="outline">
        <mat-label>Type</mat-label>
        <mat-select
          [(ngModel)]="mobileType"
          [disabled]="isDisabled"
          [ngClass]="{ removeArrow: isDisabled == true }"
          (selectionChange)="getMobileByType(mobileType)"
          placeholder="Type"
          name="mobileType"
        >
          <mat-option *ngFor="let type of mobileTypes" [value]="type">
            <span *ngIf="convertToLowerCase(type) != convertToLowerCase(targetMobileType)">{{ type | titlecase }}</span>
            <span *ngIf="convertToLowerCase(type) == convertToLowerCase(targetMobileType)"
            >{{ type | titlecase }} - Primary</span
            >
          </mat-option>
          <!-- <hr>
            <mat-option (click)="createPhoneLabel()">
              Create new label
            </mat-option> -->
        </mat-select>
      </mat-form-field>
      <app-phone-input
        class="full-width"
        [isLandLine]="true"
        [label]="'Mobile Number'"
        [required]="true"
        [phoneNumber]="myMobile"
        [isDisabled]="true"
      >
      </app-phone-input>
      <div class="clearfix" *ngIf="isDisabled == false">
        <!-- <button mat-icon-button matTooltip="Delete Mobile" (click)="removePhoneByLabel(mobileType)" *ngIf="mobileType != targetMobileType">
            <mat-icon>delete</mat-icon>
          </button> -->

        <button (click)="editPhoneByLabel(mobileType)" mat-icon-button matTooltip="Update Mobile">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
