/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./merchant-services-overview.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../merchant-service-group-list/merchant-service-group-list.component.ngfactory";
import * as i3 from "../merchant-service-group-list/merchant-service-group-list.component";
import * as i4 from "../../merchant/shared/merchant.service";
import * as i5 from "../merchant-service-list/merchant-service-list.component.ngfactory";
import * as i6 from "../merchant-service-list/merchant-service-list.component";
import * as i7 from "../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i8 from "@angular/material/tabs";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "./merchant-services-overview.component";
var styles_MerchantServicesOverviewComponent = [i0.styles];
var RenderType_MerchantServicesOverviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MerchantServicesOverviewComponent, data: {} });
export { RenderType_MerchantServicesOverviewComponent as RenderType_MerchantServicesOverviewComponent };
function View_MerchantServicesOverviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-merchant-service-group-list", [["class", "full-width clearfix"]], null, null, null, i2.View_MerchantServiceGroupListComponent_0, i2.RenderType_MerchantServiceGroupListComponent)), i1.ɵdid(1, 114688, null, 0, i3.MerchantServiceGroupListComponent, [i4.MerchantService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_MerchantServicesOverviewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-merchant-service-list", [["class", "full-width clearfix"]], null, null, null, i5.View_MerchantServiceListComponent_0, i5.RenderType_MerchantServiceListComponent)), i1.ɵdid(1, 114688, null, 0, i6.MerchantServiceListComponent, [i4.MerchantService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_MerchantServicesOverviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "mat-tab-group", [["class", "mat-tab-group"], ["dynamicHeight", "true"]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], null, null, i7.View_MatTabGroup_0, i7.RenderType_MatTabGroup)), i1.ɵdid(1, 3325952, null, 1, i8.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i8.MAT_TABS_CONFIG], [2, i9.ANIMATION_MODULE_TYPE]], { dynamicHeight: [0, "dynamicHeight"] }, null), i1.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i1.ɵeld(3, 16777216, null, null, 5, "mat-tab", [["label", "Service Groups"]], null, null, null, i7.View_MatTab_0, i7.RenderType_MatTab)), i1.ɵdid(4, 770048, [[1, 4]], 2, i8.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(603979776, 2, { templateLabel: 0 }), i1.ɵqud(335544320, 3, { _explicitContent: 0 }), (_l()(), i1.ɵand(0, [[3, 2]], 0, 1, null, View_MerchantServicesOverviewComponent_1)), i1.ɵdid(8, 16384, null, 0, i8.MatTabContent, [i1.TemplateRef], null, null), (_l()(), i1.ɵeld(9, 16777216, null, null, 5, "mat-tab", [["label", "Services"]], null, null, null, i7.View_MatTab_0, i7.RenderType_MatTab)), i1.ɵdid(10, 770048, [[1, 4]], 2, i8.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(603979776, 4, { templateLabel: 0 }), i1.ɵqud(335544320, 5, { _explicitContent: 0 }), (_l()(), i1.ɵand(0, [[5, 2]], 0, 1, null, View_MerchantServicesOverviewComponent_2)), i1.ɵdid(14, 16384, null, 0, i8.MatTabContent, [i1.TemplateRef], null, null)], function (_ck, _v) { var currVal_2 = "true"; _ck(_v, 1, 0, currVal_2); var currVal_3 = "Service Groups"; _ck(_v, 4, 0, currVal_3); var currVal_4 = "Services"; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).dynamicHeight; var currVal_1 = (i1.ɵnov(_v, 1).headerPosition === "below"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_MerchantServicesOverviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "merchant-services-overview", [], null, null, null, View_MerchantServicesOverviewComponent_0, RenderType_MerchantServicesOverviewComponent)), i1.ɵdid(1, 114688, null, 0, i10.MerchantServicesOverviewComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MerchantServicesOverviewComponentNgFactory = i1.ɵccf("merchant-services-overview", i10.MerchantServicesOverviewComponent, View_MerchantServicesOverviewComponent_Host_0, {}, {}, []);
export { MerchantServicesOverviewComponentNgFactory as MerchantServicesOverviewComponentNgFactory };
