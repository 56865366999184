<ipv-title-modal [title]="title" [hideAllDefaultButtons]="true" [icon]="null" [classIcon]="null">
  <div style="padding-top: 10px" content>
    <ng-container [ngSwitch]="industryType">
      <ipv-retail-product-form
        *ngSwitchCase="'retail'"
        [formMode]="formMode"
        [existingProduct]="existingProduct"
        [selectedSupplierKey]="selectedSupplierKey"
      ></ipv-retail-product-form>
      <ipv-retail-product-form
        *ngSwitchCase="'breeze'"
        [formMode]="formMode"
        [existingProduct]="existingProduct"
        [selectedSupplierKey]="selectedSupplierKey"
      ></ipv-retail-product-form>
      <ipv-dental-product-form *ngSwitchCase="'dental'"></ipv-dental-product-form>
    </ng-container>
  </div>
</ipv-title-modal>
