import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DentistService } from '../shared/dentist.service';

@Component({
  selector: 'app-dentist-view-picture',
  templateUrl: './dentist-view-picture.component.html',
  styleUrls: ['./dentist-view-picture.component.css'],
})
export class DentistViewPictureComponent implements OnInit {
  @Input() dentistID;

  @Output() close = new EventEmitter();

  @Input()
  hideZoomButton = true;

  profileLink;

  constructor(
    private dentistService: DentistService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.dentistID = data;
    }
  }

  ngOnInit() {
    if (this.dentistID) {
      this.getProfilePicture();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dentistID.previousValue != changes.dentistID.currentValue) {
      if (this.dentistID) {
        this.getProfilePicture();
      } else {
        this.profileLink = null;
      }
    }
  }

  getProfilePicture() {
    this.profileLink = null;

    this.profileLink = this.dentistService.getProfilePicStreamLink(this.dentistID);
  }

  closeEvent() {
    this.close.emit(true);
  }
}
