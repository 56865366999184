import { Component, EventEmitter, Inject, Input, OnInit, Optional } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { SettingsService } from '../shared/settings.service';

@Component({
  selector: 'app-module-edit',
  templateUrl: './module-edit.component.html',
  styleUrls: ['./module-edit.component.css'],
})
export class ModuleEditComponent implements OnInit {
  @Input()
  code;

  @Input()
  icon;

  @Input()
  label;

  @Input()
  description;

  @Input()
  isDeprecated;

  notExist = false;

  closeModal = new EventEmitter();
  isModal = false;

  getResult = new EventEmitter();

  getResultRaw = new EventEmitter();

  constructor(
    private utilService: UtilsService,
    private settingService: SettingsService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.code) {
        this.code = data.code;

        this.isModal = true;
      }
      if (data.label) {
        this.label = data.label;

        this.isModal = true;
      }

      if (data.icon) {
        this.icon = data.icon;

        this.isModal = true;
      }

      if (data.description) {
        this.description = data.description;

        this.isModal = true;
      }

      if (data.isDeprecated != null) {
        this.isDeprecated = data.isDeprecated;

        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    if (this.code) {
      this.utilService.getModuleAccessDetails(this.code).subscribe((res) => {
        if (res) {
          this.notExist = false;
        } else {
          this.notExist = true;
        }
      });
    }
  }

  closeEvent(e) {
    this.closeModal.emit(e);
  }

  save() {
    const payload = {
      code: this.code,
      description: this.description,
      label: this.label,
      isDeprecated: this.isDeprecated,
    };

    if (payload) {
      this.settingService.saveModule(payload).subscribe((res) => {
        if (res) {
          NotifyAppComponent.displayToast('success', 'Successful operation', 'Module has been updated on EP');

          this.getResult.emit(res);
          this.getResultRaw.emit(payload);
        }
      });
    }
  }
}
