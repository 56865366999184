import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';

@Component({
  selector: 'profile-session-edit',
  templateUrl: './profile-session-edit.component.html',
  styleUrls: ['./profile-session-edit.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ProfileSessionEditComponent implements OnInit {
  merchantID;

  tab = 'profile';

  titles = [];
  isMerchantAdmin = false;
  isPromoterOrAdmin = false;
  constructor(private activeRoute: ActivatedRoute, private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['settings'] && params['settings'] == 'practice') {
        this.tab = 'practice';
      } else if (params['settings'] && params['settings'] == 'staff') {
        this.tab = 'staff';
      } else {
        this.tab = 'profile';
      }
    });

    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
      if (res == true) {
        this.isMerchantAdmin = true;
        this.authenticationService.getCurrentPractice().subscribe((res1) => {
          this.merchantID = res1['ID'];
        });
      }
    });
  }
}
