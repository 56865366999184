import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';

@Component({
  selector: 'app-treatment-edit-modal',
  templateUrl: './treatment-edit-modal.component.html',
  styleUrls: ['./treatment-edit-modal.component.css'],
})
export class treatmentEditModalComponent implements OnInit {
  @Input()
  code;

  sessionType;

  @Output() closeModal = new EventEmitter();

  @Output() result = new EventEmitter();

  isPromoterOrAdmin = false;
  isModal = false;
  group;

  constructor(public authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });
  }

  closeEvent() {
    this.closeModal.emit(true);
  }

  update() {
    const payload = {
      itemCode: this.code,
    };

    this.result.emit(payload);
  }
}
