import { Component, Input, OnInit } from '@angular/core';
import { ProductCatalogueItem } from '../../../../../shared/services/product-catalogue.service';

@Component({
  selector: 'ipv-dental-view-product',
  templateUrl: './dental-view-product.component.html',
  styleUrls: ['./dental-view-product.component.css'],
})
export class DentalViewProductComponent implements OnInit {
  @Input() customerAppliance: ProductCatalogueItem = null;

  constructor() {}

  ngOnInit() {}
}
