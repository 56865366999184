import { animate, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogSingleButtonComponent } from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { ProductService } from '../shared/product.service';
import { ProductUtil } from '../shared/productUtil.type';

@Component({
  selector: 'app-finance-calculator',
  templateUrl: './finance-calculator.component.html',
  styleUrls: ['./finance-calculator.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class FinanceCalculatorComponent implements OnInit {
  selectedProduct = null;
  suitableProductAmount = null;
  calculate = false;
  parentSubject: Subject<any> = new Subject();
  amount = 1000;
  patientContribution: number;
  accountKeepingFee = true;
  establishmentFee = true;
  productLst = [];
  depositInc = false;
  promoCode = null;
  showOption = false;
  regulatPayment = null;
  frequencys = [];
  displayEstimation = false;
  hideProductList = false;

  toggle = true;
  productUtil = new ProductUtil();

  utils = new UtilsClass();
  financedProductGroup = [];
  membershipProductGroup = [];

  productGroup = [
    {
      Available: '1',
      DateTimeCreated: '2018-12-21 11:36:05',
      GroupCode: 'GPF',
      GroupLabel: 'General Practitioners - Finance',
    },
  ];

  isPromoterOrAdmin = false;
  merchants;
  merchantID;
  hasProductGroup = true;

  selectedGroup;

  productType;
  isModuleMembershipActive = Settings.global['isModuleMembershipActive'];
  isModuleTreatmentPlanActive = Settings.global['isModuleTreatmentPlanActive'];
  close = new EventEmitter();

  maxLoanAmount;

  constructor(
    private utilService: UtilsService,
    private location: Location,
    private productService: ProductService,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private merchantService: MerchantService
  ) {}

  ngOnInit() {
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleMembershipActive = access['isModuleMembershipActive'];
        this.isModuleTreatmentPlanActive = access['isModuleTreatmentPlanActive'];
      }
      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        if (this.isPromoterOrAdmin == true) {
          const payload = {
            fields: 'ID,TradingAs,CalculatedName,Status',
          };
          this.merchantService.getList(payload).subscribe((res) => {
            this.merchants = res;

            this.getProductGroup();
          });
        } else {
          this.getProductGroup();
        }
      });
    });
  }

  selectAmount(amount) {
    this.amount = amount;
  }

  getLabelFromProductGroup(p) {
    return this.utils.getLabelFromProductGroup(p);
  }

  selectProduct(product) {
    if (product != null) {
      this.selectedProduct = product.ID;

      this.hideProductList = true;
    } else {
      this.showOption = false;
      this.selectedProduct = null;
      this.hideProductList = false;
      const confirmDialog = new ConfirmDialogSingleButton(
        'warning',
        'No products found',
        'Please contact Smile Right for more details.',
        'Go Back'
      );

      const ref = this.dialog.open(ConfirmDialogSingleButtonComponent, {
        data: confirmDialog,
      });

      ref.componentInstance.onConfirm.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  flipClose(): void {
    this.showOption = false;
    this.displayEstimation = false;
    this.selectedProduct = null;
  }

  getSuibaleProduct() {
    if (this.patientContribution && this.depositInc == true) {
      this.suitableProductAmount = Number(this.amount) - Number(this.patientContribution);
    } else {
      this.suitableProductAmount = this.amount;
    }

    this.showOption = true;
    this.hideProductList = false;
  }

  onButtonGroupClick($event) {
    const clickedElement = $event.target || $event.srcElement;

    if (clickedElement.nodeName === 'BUTTON') {
      const isCertainButtonAlreadyActive = clickedElement.parentElement.querySelector('.active');
      // if a Button already has Class: .active
      if (isCertainButtonAlreadyActive) {
        isCertainButtonAlreadyActive.classList.remove('active');
      }

      clickedElement.className += ' active';
    }
  }

  showProductsAgain() {
    this.hideProductList = false;
  }

  toNumber(s) {
    if (s) {
      return Number(s);
    } else {
      return 0;
    }
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  closeFinanceCalculator(e) {
    if (e && e == true) {
      this.close.emit(true);
    }
  }

  closeModal() {
    this.close.emit(true);
  }

  getProductGroup(ID = null) {
    if (this.isModuleTreatmentPlanActive == true) {
      const p1 = {
        merchantID: ID,
        isFinance: true,
      };

      this.productService.getMerchantProductGroup(p1, this.isPromoterOrAdmin).subscribe((res) => {
        if (res) {
          this.financedProductGroup = res;

          if (this.financedProductGroup.length <= 0) {
            this.hasProductGroup = false;

            const confirmDialog = new ConfirmDialogSingleButton(
              'warning',
              'No Product Licenses Found',
              'Please contact Smile Right for more information',
              'Okay'
            );
            const ref = this.dialog.open(ConfirmDialogSingleButtonComponent, {
              data: confirmDialog,
              width: '650px',
            });
            ref.componentInstance.onConfirm.subscribe((res) => {
              ref.close();
              this.close.emit(true);
            });
          } else {
            this.selectedGroup = this.financedProductGroup[0]['GroupCode'];
            this.hasProductGroup = true;
            this.getMaxLoanAmount(this.selectedGroup);
          }
        }
      });
    }

    if (this.isModuleMembershipActive == true) {
      const p2 = {
        merchantID: ID,
        isFinance: false,
      };

      this.productService.getMerchantProductGroup(p2, this.isPromoterOrAdmin).subscribe((res) => {
        if (res) {
          this.membershipProductGroup = res;

          if (this.membershipProductGroup.length <= 0) {
            this.hasProductGroup = false;
          } else {
            this.hasProductGroup = true;
          }
        }
      });
    }
  }

  viewMerchant() {
    const ref = this.dialog.open(MerchantViewComponent, {
      data: this.merchantID,
      width: '600px',
    });
  }

  getMaxLoanAmount(productCode) {
    if (productCode) {
      const payload = {
        groupCode: productCode,
        fields: 'Parameter.MaxLoanValue',
      };
      this.productService.getList(payload, this.isPromoterOrAdmin).subscribe((res) => {
        if (res && res.length > 0) {
          const temp = res.map((x) => Number(x['Parameter.MaxLoanValue']));
          this.maxLoanAmount = Math.max(...temp);
        }
      });
    }
  }
}
