import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EditorComponentCloneComponent } from '../editor-component-clone/editor-component-clone.component';
import { EditorComponentCreateEditComponent } from '../editor-component-create-edit/editor-component-create-edit.component';
import { EditorComponentDocumentViewComponent } from '../editor-component-document-view/editor-component-document-view.component';
import { EditorComponentEditModalComponent } from '../editor-component-edit-modal/editor-component-edit-modal.component';
import { EditorComponentListOverviewComponent } from '../editor-component-list-overview/editor-component-list-overview.component';
import { EditorComponentListComponent } from '../editor-component-list/editor-component-list.component';
import { EditorComponentLookupComponent } from '../editor-component-lookup/editor-component-lookup.component';
import { EditorComponentViewComponent } from '../editor-component-view/editor-component-view.component';
import { EditorDocumentCreateComponent } from '../editor-document-create/editor-document-create.component';
import { EditorDocumentGalleryComponent } from '../editor-document-gallery/editor-document-gallery.component';

const routes: Routes = [
  {
    path: 'EditorComponentLookupComponent',
    component: EditorComponentLookupComponent,
  },
  {
    path: 'EditorComponentCreateEditComponent',
    component: EditorComponentCreateEditComponent,
  },

  {
    path: 'EditorComponentDocumentViewComponent',
    component: EditorComponentDocumentViewComponent,
  },
  {
    path: 'EditorDocumentCreateComponent',
    component: EditorDocumentCreateComponent,
  },
  {
    path: 'EditorDocumentGalleryComponent',
    component: EditorDocumentGalleryComponent,
  },

  {
    path: 'EditorComponentEditModalComponent',
    component: EditorComponentEditModalComponent,
  },
  {
    path: 'EditorComponentCloneComponent',
    component: EditorComponentCloneComponent,
  },

  {
    path: 'EditorComponentListComponent',
    component: EditorComponentListComponent,
  },
  {
    path: 'EditorComponentListOverviewComponent',
    component: EditorComponentListOverviewComponent,
  },
  {
    path: 'EditorComponentViewComponent',
    component: EditorComponentViewComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EditorComponentRoutingModule {}
