<div class="rel" *ngIf="isPromoterOrAdmin">
  <form #resetForm="ngForm">
    <div class="card-header primary-gradient-img clearfix confirmModal">
      <div class="row clearfix">
        <!-- Modal dismiss button -->
        <button class="btn-close btn-clear mat-button white" (click)="closeModal()">
          <mat-icon>clear</mat-icon>
        </button>
        <div class="row titleArea clearfix">
          <mat-icon class="white pull-left">attach_file</mat-icon>
          <h2 class="summary-header rm-m">
            Wakanda file List

            <span class="subLabel"
            >Find the invitation and contract files stored on Wakanda server or sent to EP from PP and LA</span
            >
          </h2>
        </div>
      </div>
    </div>

    <mat-dialog-content class="text-center">
      <mat-form-field class="full-width mt clearfix" *ngIf="types && types.length > 0" appearance="outline">
        <mat-label>Document Type</mat-label>
        <mat-select
          class=" "
          [(ngModel)]="type"
          (ngModelChange)="changeType()"
          name="DocumentType"
          placeholder="Document Type"
          required
        >
          <mat-option *ngFor="let type of types | orderBy: ['Label']" [value]="type['TypeCode']">
            {{ type.Label  | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="full-width clearfix" appearance="outline">
        <mat-label>Document Name</mat-label>
        <input
          class="mat-block"
          [(ngModel)]="label"
          matInput
          name="label"
          placeholder="Document Name"
          required
        />
      </mat-form-field>

      <mat-form-field class="full-width clearfix" appearance="outline">
        <mat-label>Description</mat-label>
        <textarea
          class="dentMsg increase-input-field"
          [(ngModel)]="description"
          name="groupDescription"
          matInput
          placeholder="Description"
          style="min-height: 70px"
        ></textarea>
      </mat-form-field>

      <!-- <hr> -->
    </mat-dialog-content>
    <mat-dialog-actions class="button-row text-center">
      <div class="full-width">
        <button class="pull-right" [disabled]="!resetForm.form.valid" (click)="save()" mat-raised-button color="accent">
          Submit
        </button>

        <button class="pull-left" (click)="closeModal()" mat-raised-button color="primary">Close</button>
      </div>
    </mat-dialog-actions>
  </form>
</div>
