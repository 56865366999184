import { animate, group, keyframes, query, stagger, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import hexRgb from 'hex-rgb';
import * as _ from 'lodash';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ColorPalette } from '../../../shared/types/color-palette';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { ModuleViewComponent } from '../module-view/module-view.component';
import { ModuleService } from '../shared/module.service';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-module-list',
  templateUrl: './module-list.component.html',
  styleUrls: ['./module-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 0 })),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),

        group([animate(300, style({ height: 0 })), animate('200ms ease-in-out', style({ opacity: '0' }))]),
      ]),
      transition(':enter', [
        style({ height: '0', opacity: 0 }),

        group([animate(300, style({ height: '*' })), animate('400ms ease-in-out', style({ opacity: '1' }))]),
      ]),
    ]),
  ],
})
export class ModuleListComponent implements OnInit {
  @Input()
  readOnly = false;
  @Input()
  canSelect = false;
  @Input()
  displayHeader = true;
  @Input()
  displayFilter = true;
  @Input()
  displayOwn = true;
  @Input()
  status = ['EnableForAll', 'Beta', 'DisableForAll'];
  @Input()
  moduleCodes = [];
  @Input()
  maxText = 100;
  @Input()
  color;
  @Input()
  colorSecond;
  @Input()
  displayTwoCardRow = false;

  @Input()
  isListView = false;
  modules = [];
  moduleFilter = [];
  sessionType;
  statusArray = [
    {
      code: 'EnableForAll',
      value: 'Enable for all',
    },
    {
      code: 'Beta',
      value: 'Beta',
    },

    {
      code: 'DisableForAll',
      value: 'Disable for all',
    },
  ];
  @Input()
  defaultSelectedIDs = [];
  @Input()
  defaultSelectedCodes = [];
  statusDivider = ['EnableForAll', 'Beta', 'DisableForAll'];
  selectedIDs = [];
  selectedCodes = [];
  @Output()
  getSelectedCodes = new EventEmitter();
  @Output()
  getSelectedIDs = new EventEmitter();

  @Input()
  canDragAndDrop = true;
  @Input()
  selectCurrent = true;
  @Input()
  featureType = 'all';

  @Input()
  accessOnTooltip = 'Inluded in your subscription plan';
  @Input()
  accessOffTooltip = 'NOT Inluded in your subscription plan';

  currentModules;

  disableSaveButton = true;
  isOrderCheck = false;

  context = Settings.global['context']

  constructor(
    private moduleService: ModuleService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['featureType']) {
        this.featureType = params['featureType'];
      }

      this.authenticationService.getSessionType().subscribe((st) => {
        this.sessionType = st;

        if (this.sessionType != 'admin' && this.sessionType != 'promoter') {
          this.readOnly = true;
          this.canDragAndDrop = false;
        }
        this.setup();

        if (this.selectCurrent === true) {
          this.authenticationService.getCurrentAccess().subscribe((res) => {
            if (res) {
              this.currentModules = res;
            }
          });
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.defaultSelectedCodes &&
      JSON.stringify(changes.defaultSelectedCodes.previousValue) !=
        JSON.stringify(changes.defaultSelectedCodes.currentValue)
    ) {
      this.buildSelectedItems();
    } else if (
      changes.defaultSelectedIDs &&
      JSON.stringify(changes.defaultSelectedIDs.previousValue) !=
        JSON.stringify(changes.defaultSelectedIDs.currentValue)
    ) {
      this.buildSelectedItems();
    }
    if (changes.status && JSON.stringify(changes.status.previousValue) != JSON.stringify(changes.status.currentValue)) {
      this.setup();
    } else if (
      changes.codes &&
      JSON.stringify(changes.codes.previousValue) != JSON.stringify(changes.codes.currentValue)
    ) {
      this.setup();
    }
  }

  setup() {
    let statusCodes;
    let codes;
    if (this.status && Array.isArray(this.status)) {
      statusCodes = this.status.join(',');
    } else if (this.status && typeof this.status === 'string') {
      statusCodes = this.status;
    }
    if (this.moduleCodes && Array.isArray(this.moduleCodes)) {
      codes = this.moduleCodes.join(',');
    } else if (this.moduleCodes && typeof this.moduleCodes === 'string') {
      codes = this.moduleCodes;
    }
    const payload = {
      fields:
        'ID,Code,SortOrder,Label,Description,Status.Code,Status.Label,Icon,Isdepreciated,Wiki_URL,Is_Deactivated,SortOrder',
      orderBy: 'SortOrder',
      isActive: true,
      statusCodes: statusCodes || null,
      codes: codes || null,
    };
    if (this.sessionType !== 'admin' && this.sessionType != 'promoter') {
      this.readOnly = true;
    }
    if (this.color && !this.colorSecond) {
      this.onColorPickerClose(this.color);
    }
    this.moduleService.getModuleList(payload, this.sessionType).subscribe((res) => {
      if (res) {
        this.modules = res;
        this.buildSelectedItems();
      }
    });
  }

  buildSelectedItems() {
    if (this.modules && this.modules.length > 0) {
      if (this.defaultSelectedIDs && this.defaultSelectedIDs.length > 0) {
        let _ids = _.map(this.modules, (item) => {
          if (item && item.ID) {
            return item.ID;
          }
          return null;
        });
        _ids = _.filter(_ids, (item) => {
          if (item) {
            return true;
          }
          return false;
        });
        this.defaultSelectedIDs = _.filter(this.defaultSelectedIDs, (item) => {
          if (item && _ids.indexOf(item) !== -1) {
            return true;
          }
          return false;
        });
        this.defaultSelectedIDs = _.filter(this.defaultSelectedIDs, (item) => {
          if (item) {
            return true;
          }
          return false;
        });
        if (this.defaultSelectedIDs && this.defaultSelectedIDs.length > 0) {
          this.selectedIDs = this.defaultSelectedIDs;
        }
      }
      if (this.defaultSelectedCodes && this.defaultSelectedCodes.length > 0) {
        let _codes = _.map(this.modules, (item) => {
          if (item && item.Code) {
            return item.Code;
          }
          return null;
        });
        _codes = _.filter(_codes, (item) => {
          if (item) {
            return true;
          }
          return false;
        });
        this.defaultSelectedCodes = _.filter(this.defaultSelectedCodes, (item) => {
          if (item && _codes.indexOf(item) !== -1) {
            return true;
          }
          return false;
        });
        this.defaultSelectedCodes = _.filter(this.defaultSelectedCodes, (item) => {
          if (item) {
            return true;
          }
          return false;
        });
        if (this.defaultSelectedCodes && this.defaultSelectedCodes.length > 0) {
          this.selectedCodes = this.defaultSelectedCodes;
        }
      }
    }
  }

  isIndexOf(s, attribute) {
    if (s && attribute && s.indexOf(attribute) != -1) {
      return true;
    }
    return false;
  }

  sync(isForced = false) {
    if (isForced == true) {
      const confirm = new ConfirmDialog(
        'settings',
        'Are you sure  ?',
        'Resetting Modules will reset icons, labels , descriptions and status',
        'No',
        'Yes'
      );
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.moduleService.sync({ isForced: true }).subscribe((res) => {
            if (res && res.length > 0) {
              this.modules = JSON.parse(JSON.stringify(res));
              UtilsClass.updateSideMenuAllModule.emit(res);
            }
            NotifyAppComponent.displayToast('success', 'Success!', 'Modules are synchronized ');
            ref.close();
          });
        }
      });
    } else {
      const confirm = new ConfirmDialog(
        'settings',
        'Are you sure ?',
        'synchronizing modules will add new created modules and remove disabled ones',
        'No',
        'Yes'
      );
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.moduleService.sync({ isForced: false }).subscribe((res) => {
            if (res && res.length > 0) {
              this.modules = JSON.parse(JSON.stringify(res));
            }
            NotifyAppComponent.displayToast('success', 'Success!', 'Modules are synchronized ');
            ref.close();
          });
        }
      });
    }
  }

  onColorPickerClose(color: string): void {
    if (color) {
      this.color = color;
      const v = new ColorPalette(color);
      const palette = v.getPalette();
      if (palette && palette.length > 0 && palette[palette.length - 1] && palette[palette.length - 1]['hex']) {
        this.colorSecond = palette[palette.length - 1]['hex'];
      }
    } else {
      this.colorSecond = null;
    }
  }

  modifyRecord(record) {
    const index = this.modules.findIndex((item) => {
      if (item && item.ID && record && record.ID && record.ID == item.ID) {
        return true;
      }
      return false;
    });
    if (index != -1) {
      this.modules[index] = record;
      this.modules = JSON.parse(JSON.stringify(this.modules));
    }
  }

  updateModule(id, status) {
    if (id && status) {
      this.moduleService.editModule(id, { status }).subscribe((res) => {
        if (res && res.ID) {
          this.modifyRecord(res);
          UtilsClass.updateSideMenuOneModule.emit(res);
          NotifyAppComponent.displayToast('success', 'Success!', 'Module is modified');
        }
      });
    }
  }

  edit(id) {
    this.router.navigate(['/merchant', { outlets: { page: ['module-edit', id] } }]);
  }

  delete(id) {
    if (id) {
      const confirm = new ConfirmDialog('delete', '', 'Are you sure you want to delete this module ?', 'No', 'Yes');
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.moduleService.deleteModule(id).subscribe((res) => {
            if (res) {
              const index = this.modules.findIndex((item) => {
                if (item && item.ID && id && item.ID == id) {
                  return true;
                }
                return false;
              });
              if (index != -1) {
                this.modules.splice(index, 1);
                this.modules = JSON.parse(JSON.stringify(this.modules));
              }
              NotifyAppComponent.displayToast('success', 'Success!', 'Module is removed ');
              ref.close();
            }
          });
        }
      });
    }
  }

  viewModule(ID) {
    if (ID) {
      const ref = RootAppComponent.dialog.open(ModuleViewComponent, {
        data: {
          moduleID: ID,
          displaySubscribeButton: false,
        },
        width: '1000px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res) {
          ref.close();
        }
      });
    }
  }

  buildBorderleft(color) {
    if (color) {
      const rbg = hexRgb(color);

      if (rbg) {
        return `rgb(${rbg.red}  ${rbg.green} ${rbg.blue} / 30%)`;
      } else {
        return color;
      }
    }
  }

  getColor(m) {
    if (this.colorSecond && m !== 'DisableForAll') {
      return this.colorSecond;
    } else if (this.color && m !== 'DisableForAll') {
      return this.color;
    } else {
      if (m === 'EnableForAll') {
        return '#40B5AD';
      } else if (m === 'DisableForAll') {
        return '#969696';
      } else if (m === 'Beta') {
        return '#4981CE';
      }
      return '#40B5AD';
    }
  }

  getColorIcon(m) {
    if (this.color && m !== 'DisableForAll') {
      return this.color;
    } else {
      if (m === 'EnableForAll') {
        return '#40B5AD';
      } else if (m === 'DisableForAll') {
        return '#969696';
      } else if (m === 'Beta') {
        return '#4981CE';
      }
      return '#40B5AD';
    }
  }

  getBotderColor(m) {
    if (this.color && m !== 'DisableForAll') {
      return this.buildBorderleft(this.color);
    } else {
      if (m === 'EnableForAll') {
        return this.buildBorderleft('#40B5AD');
      } else if (m === 'DisableForAll') {
        return this.buildBorderleft('#969696');
      } else if (m === 'Beta') {
        return this.buildBorderleft('#4981CE');
      }
      return this.buildBorderleft('#40B5AD');
    }
  }

  isSelected(id, code) {
    if (code && this.selectedCodes && this.selectedCodes.length > 0 && this.selectedCodes.indexOf(code) !== -1) {
      return true;
    } else if (id && this.selectedIDs && this.selectedIDs.length > 0 && this.selectedIDs.indexOf(id) !== -1) {
      return true;
    }
    return false;
  }

  selectModule(id, code) {
    if (this.canSelect == true) {
      if (code && this.selectedCodes && this.selectedCodes.length > 0 && this.selectedCodes.indexOf(code) === -1) {
        this.selectedCodes.push(code);
        this.selectedCodes = _.uniq(this.selectedCodes);
      } else if (
        code &&
        this.selectedCodes &&
        this.selectedCodes.length > 0 &&
        this.selectedCodes.indexOf(code) !== -1
      ) {
        const index = this.selectedCodes.indexOf(code);
        this.selectedCodes.splice(index, 1);
        this.selectedCodes = _.uniq(this.selectedCodes);
      } else if (code && this.selectedCodes && this.selectedCodes.length <= 0) {
        this.selectedCodes.push(code);
        this.selectedCodes = _.uniq(this.selectedCodes);
      }
      if (id && this.selectedIDs && this.selectedIDs.length > 0 && this.selectedIDs.indexOf(id) === -1) {
        this.selectedIDs.push(id);
        this.selectedIDs = _.uniq(this.selectedIDs);
      } else if (id && this.selectedIDs && this.selectedIDs.length > 0 && this.selectedIDs.indexOf(id) !== -1) {
        const index = this.selectedIDs.indexOf(id);
        this.selectedIDs.splice(index, 1);
        this.selectedIDs = _.uniq(this.selectedIDs);
      } else if (id && this.selectedIDs && this.selectedIDs.length <= 0) {
        this.selectedIDs.push(id);
        this.selectedIDs = _.uniq(this.selectedIDs);
      }
    }
    this.selectResult();
  }

  selectEvent(type, select = true) {
    if (this.modules && this.modules.length) {
      let _selectedIDs = [];
      let _selectedCodes = [];
      if (type == 'all') {
        _selectedIDs = _.map(this.modules, (item) => {
          if (
            item &&
            item.ID &&
            (item['Status.Code'] == 'EnableForAll' ||
              item['Status.Code'] == 'DisableForAll' ||
              item['Status.Code'] == 'Beta')
          ) {
            return item.ID;
          }
          return null;
        });
        _selectedCodes = _.map(this.modules, (item) => {
          if (
            item &&
            item.Code &&
            (item['Status.Code'] == 'EnableForAll' ||
              item['Status.Code'] == 'DisableForAll' ||
              item['Status.Code'] == 'Beta')
          ) {
            return item.Code;
          }
          return null;
        });
      } else if (type == 'enabled') {
        _selectedIDs = _.map(this.modules, (item) => {
          if (item && item.ID && item['Status.Code'] && item['Status.Code'] == 'EnableForAll') {
            return item.ID;
          }
          return null;
        });
        _selectedCodes = _.map(this.modules, (item) => {
          if (item && item.Code && item['Status.Code'] && item['Status.Code'] == 'EnableForAll') {
            return item.Code;
          }
          return null;
        });
      } else if (type == 'disabled') {
        _selectedIDs = _.map(this.modules, (item) => {
          if (item && item.ID && item['Status.Code'] && item['Status.Code'] == 'DisableForAll') {
            return item.ID;
          }
          return null;
        });
        _selectedCodes = _.map(this.modules, (item) => {
          if (item && item.Code && item['Status.Code'] && item['Status.Code'] == 'DisableForAll') {
            return item.Code;
          }
          return null;
        });
      } else if (type == 'beta') {
        _selectedIDs = _.map(this.modules, (item) => {
          if (item && item.ID && item['Status.Code'] && item['Status.Code'] == 'Beta') {
            return item.ID;
          }
          return null;
        });
        _selectedCodes = _.map(this.modules, (item) => {
          if (item && item.Code && item['Status.Code'] && item['Status.Code'] == 'Beta') {
            return item.Code;
          }
          return null;
        });
      }
      if (select === true) {
        if (_selectedCodes && _selectedCodes.length > 0) {
          _selectedCodes = _.filter(_selectedCodes, (item) => {
            if (item) {
              return true;
            }
            return false;
          });
          _selectedCodes = this.selectedCodes.concat(_selectedCodes);
          _selectedCodes = _.uniq(_selectedCodes);
          this.selectedCodes = _selectedCodes;
        }
        if (_selectedIDs && _selectedIDs.length > 0) {
          _selectedIDs = _.filter(_selectedIDs, (item) => {
            if (item) {
              return true;
            }
            return false;
          });
          _selectedIDs = this.selectedIDs.concat(_selectedIDs);
          _selectedIDs = _.uniq(_selectedIDs);
          this.selectedIDs = _selectedIDs;
        }
      } else {
        if (_selectedCodes && _selectedCodes.length > 0) {
          _selectedCodes = _.filter(_selectedCodes, (item) => {
            if (item) {
              return true;
            }
            return false;
          });
          _selectedCodes = _.uniq(_selectedCodes);
          this.selectedCodes = _.filter(this.selectedCodes, (item) => {
            if (item && _selectedCodes.indexOf(item) !== -1) {
              return false;
            }
            return true;
          });
          this.selectedCodes = _.uniq(this.selectedCodes);
        }
        if (_selectedIDs && _selectedIDs.length > 0) {
          _selectedIDs = _.filter(_selectedIDs, (item) => {
            if (item) {
              return true;
            }
            return false;
          });
          _selectedIDs = _.uniq(_selectedIDs);
          this.selectedIDs = _.filter(this.selectedIDs, (item) => {
            if (item && _selectedIDs.indexOf(item) !== -1) {
              return false;
            }
            return true;
          });
          this.selectedIDs = _.uniq(this.selectedIDs);
        }
      }
    }
    this.selectResult();
  }

  selectResult() {
    this.getSelectedCodes.emit(this.selectedCodes || []);
    this.getSelectedIDs.emit(this.selectedIDs || []);
  }

  isOwned(code) {
    if (this.currentModules && code) {
      if (this.currentModules['isModule' + code + 'Active'] == true) {
        return true;
      }

      return false;
    }

    return false;
  }

  drop(status, event) {
    if (status && this.modules && this.modules.length > 0) {
      let currentList = _.filter(this.modules, (item) => {
        if (item && item['Status.Code'] && item['Status.Code'] == status) {
          return true;
        }
        return false;
      });

      if (currentList && currentList.length > 0) {
        currentList = _.orderBy(currentList, ['SortOrder']);
        const currentIndex = event.currentIndex;
        const previousIndex = event.previousIndex;
        const item = currentList[previousIndex];
        currentList.splice(previousIndex, 1);
        currentList.splice(currentIndex, 0, item);
        for (let i = 0; i < currentList.length; i++) {
          currentList[i]['SortOrder'] = i + 1;
        }

        let leftList = _.filter(this.modules, (item) => {
          if (item && item['Status.Code'] && item['Status.Code'] == status) {
            return false;
          }
          return true;
        });
        if (leftList && leftList.length > 0) {
          leftList = leftList.concat(currentList || []);
        } else {
          leftList = [];
          leftList = leftList.concat(currentList || []);
        }

        this.modules = JSON.parse(JSON.stringify(leftList));
        this.disableSaveButton = false;
      }
    }
  }

  sortOrderView(e) {
    this.isOrderCheck = e;
    this.isListView = e;
    this.moduleFilter = null;
  }

  listViewApply() {
    this.isOrderCheck = false;
    this.isListView = true;
    this.moduleFilter = null;
  }

  saveOrder() {
    if (this.modules && this.modules.length > 0) {
      const p = _.map(this.modules, (item) => {
        if (item && item.ID) {
          return {
            id: item.ID,
            sortOrder: item.SortOrder,
            status: item['Status.Code'] || 'DisableForAll',
          };
        }
      });
      this.moduleService.editModuleGroup(p).subscribe((res) => {
        if (res && res.length > 0) {
          this.modules = JSON.parse(JSON.stringify(res));
          this.disableSaveButton = true;
          NotifyAppComponent.displayToast('success', 'Success!', 'Sort order is modified ');
        }
      });
    }
  }

  syncSubPlans() {
    const payload = {
      isForceSync: true,
      selection: 'all',
    };

    let confirm = new ConfirmDialog(
      "refresh",
      "",
      "Are you sure you want to SYNC the selected merchants local data ?",
      "No",
      "Yes"
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
    });
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        this.moduleService.syncSubPlanMerchant(payload).subscribe((r) => {


          NotifyAppComponent.displayToast("success", "Success!", "Synchronizing in the background");
          ref.close();
        });
      }
    });
  }
}
