import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslationPage } from '../../translation-management-create/translation-management-create.component';

const routes: Routes = [
  {
    path: 'translation-management',
    component: TranslationPage,
    data: { title: 'Translation Management' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TranslationManagementRoutingModule {}
