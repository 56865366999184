import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UserDataService } from '../../../shared/services/user-data.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { InvitationService } from '../shared/services/invitation.service';

@Component({
  selector: 'app-invitation-redirection',
  templateUrl: './invitation-redirection.component.html',
  styleUrls: ['./invitation-redirection.component.css'],
})
export class InvitationRedirectionComponent implements OnInit {
  productGroupCode;
  amount = 0;
  context = Settings.global['context'];
  guid: any;
  sessionType;

  link = 'invitation-create';

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private userDataService: UserDataService,
    private invitationService: InvitationService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    if (this.context == 'retail') {
      this.link = 'lending-invitation-create';
    }
    UtilsClass.startLoadingForce();
    this.activeRoute.params.subscribe((params) => {
      if (!params['productGroup']) {
        NotifyAppComponent.displayToast('Warning', 'You can not proceed', 'No Product group is selected');

        this.router.navigate(['/merchant/']);
      } else {
        this.productGroupCode = params['productGroup'];

        if (params['amount']) {
          this.amount = Number(params['amount']);
        }

        this.invitationService.getGUID().subscribe((res) => {
          this.guid = res;
          this.router.navigated = false;

          let payload = {};

          payload['productGroupCode'] = this.productGroupCode;

          payload['totalTreatmentValue'] = this.amount;

          this.authenticationService.getSessionType().subscribe((r) => {
            this.userDataService.setUserDataArrayForce(this.guid, payload, r).subscribe(() => {
              UtilsClass.stopLoadingForce();
              this.router.navigate(['/merchant', { outlets: { page: [this.link, this.guid] } }], { replaceUrl: true });
            });
          });
        });
      }
    });
  }
}
