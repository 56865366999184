import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

@Component({
  selector: 'app-dentist-settings',
  templateUrl: './dentist-settings.component.html',
  styleUrls: ['./dentist-settings.component.css'],
})
export class DentistSettingsComponent implements OnInit {
  practice;
  idAdressValide = false;
  mySuburb;
  myState;
  myPostCode;
  myStreetNumber;
  myStreetName;
  myUnitNumber;
  myStreetType;
  myLatitude;
  myLongitude;

  isModuleMembershipActive = Settings.global['isModuleMembershipActive'];

  constructor(
    private utilService: UtilsService,
    private location: Location,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleMembershipActive = access['isModuleMembershipActive'];
      }

      this.setup();
    });
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  setup() {
    UtilsClass.startLoading();
    this.authenticationService.getCurrentPractice().subscribe((res) => {
      this.practice = res;

      this.mySuburb = this.practice['addresses.Suburb'];
      this.myState = this.practice['addresses.State'];
      this.myPostCode = this.practice['addresses.Postcode'];
      this.myStreetNumber = this.practice['addresses.Street Nr'];
      this.myStreetName = this.practice['addresses.Street Name'];
      this.myUnitNumber = this.practice['addresses.Unit'];
      this.myStreetType = this.practice['addresses.Street Type'];

      this.myLatitude = this.practice['Address.Latitude'];
      this.myLongitude = this.practice['Address.Longitude'];

      UtilsClass.stopLoading();
    });
  }

  getAdress(adr) {
    if (adr) {
      this.mySuburb = adr.suburb;
      this.myState = adr.state;
      this.myPostCode = adr.postCode;
      this.myStreetNumber = adr.streetNumber;
      this.myStreetName = adr.streetName;
      this.myUnitNumber = adr.unitNumber;
      this.myStreetType = adr.streetType;
      this.myLatitude = adr.latitude;
      this.myLongitude = adr.longitude;
    }
  }

  isAdressValid(v) {
    this.idAdressValide = v || false;
  }
}
