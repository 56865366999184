<div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>
<div [hidden]="listDB.data.length == 0">
  <div>
    <h1 class="dataHeader" *ngIf="hideTitle == false">
      {{ title }}
      <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
    </h1>
    <mat-card class="list-card">
      <div class="row clearfix searchArea primary-gradient-img">
        <!-- Search -->

        <div class="row clearfix flex">
          <div class="qtr-width flex" id="search">
            <mat-icon class="schIcon mt mr">search</mat-icon>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Add Filters</mat-label>

              <mat-select
                class="schSel full-width"
                [formControl]="searchVals"
                matInput
                placeholder="Add Filters"
                multiple
              >
                <mat-select-trigger>
                  {{ searchVals.value ? searchVals.value[0] : '' }}
                  <span class="additional-selection" *ngIf="searchVals.value?.length > 1">
                    (+{{ searchVals.value.length - 1 }} others)
                  </span>
                </mat-select-trigger>
                <mat-option *ngFor="let searchVal of searchValList" [value]="searchVal">{{ searchVal }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="full-width flex animated fadeInDown clearfix" *ngIf="searchVals.value?.length > 0">
            <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Name') !== -1" appearance="outline">
              <mat-label>Name</mat-label>
              <input
                class="clearfix"
                (input)="setFilter($event.target.value, 'CalculatedName')"
                name="contactName"
                placeholder="Name"
                matInput
              />
            </mat-form-field>
            <mat-form-field
              class="full-width"
              *ngIf="searchVals.value?.indexOf('Contact Of') !== -1"
              appearance="outline"
            >
              <mat-label>Contact Of</mat-label>
              <mat-select
                class=""
                (change)="setFilter($event.value, 'ContactForCardName')"
                name="ContactOf"
                placeholder="Contact Of"
              >
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option
                  *ngFor="
                    let a of dataSource.filteredData
                      | objectPropertyValue: 'ContactForCardName'
                      | unique
                      | without: ['', null]
                      | orderBy
                  "
                  [value]="a"
                >
                  {{ a }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Email') !== -1" appearance="outline">
              <mat-label>Email</mat-label>
              <input
                class="clearfix"
                (input)="setFilter($event.target.value, 'emails.Email')"
                name="contactEmail"
                placeholder="Email"
                matInput
              />
            </mat-form-field>
            <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Mobile') !== -1" appearance="outline">
              <mat-label>Mobile</mat-label>
              <input
                class="clearfix"
                (input)="setFilter($event.target.value, 'mobiles.Number')"
                name="contactMobile"
                placeholder="Mobile"
                matInput
              />
            </mat-form-field>
            <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Phone') !== -1" appearance="outline">
              <mat-label>Phone</mat-label>
              <input
                class="clearfix"
                (input)="setFilter($event.target.value, 'phones.Number')"
                name="contactPhone"
                placeholder="Phone"
                matInput
              />
            </mat-form-field>
            <mat-form-field
              class="full-width"
              *ngIf="searchVals.value?.indexOf('Contact Type') !== -1"
              appearance="outline"
            >
              <mat-label>Contact Type</mat-label>
              <mat-select
                class="margin-btm qtr-width"
                (change)="setFilter($event.value, 'ContactType.Label')"
                name="contactType"
                placeholder="Contact Type"
              >
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option
                  *ngFor="
                    let a of dataSource.filteredData
                      | objectPropertyValue: 'ContactType.Label'
                      | unique
                      | without: ['', null]
                      | orderBy
                  "
                  [value]="a"
                >
                  {{ a }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- Table -->
      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
        <!-- ID Column -->
        <ng-container cdkColumnDef="ProfileImage" sticky>
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Profile Picture</mat-header-cell>
          <mat-cell class="text-center" *cdkCellDef="let row" (click)="openQuickViewDialog(row['ID'])">
            <app-contact-view-picture class="supplierIcon2 text-center" *ngIf="row" [contactID]="row['ID']" [LastModified]="row['LastModified']">
            </app-contact-view-picture>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Name">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Name</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openQuickViewDialog(row['ID'])"
            matTooltip="{{ row['CalculatedName'] }}"
          >
            {{ row['CalculatedName'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="ContactOf">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Contact Of</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openQuickViewDialog(row['ID'])"
            matTooltip="{{ row['ContactForCardName'] }}"
          >
            {{ row['ContactForCardName'] }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Phone">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Phone</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openQuickViewDialog(row['ID'])"
            matTooltip="{{ row['phones.Number'] | customPhone: 'landLine' }}"
          >
            {{ row['phones.Number'] | customPhone: 'landLine' }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Mobile">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Mobile</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openQuickViewDialog(row['ID'])"
            matTooltip="{{ row['mobiles.Number'] | customPhone }}"
          >
            {{ row['mobiles.Number'] | customPhone }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Email">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Email</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openQuickViewDialog(row['ID'])"
            matTooltip="{{ row['emails.Email'] }}"
          >
            {{ row['emails.Email'] }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="ContactType">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Contact type</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openQuickViewDialog(row['ID'])"
            matTooltip="{{ row['ContactType.Label'] }}"
          >
            {{ row['ContactType.Label'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button *ngIf="row['ID']" (click)="openQuickViewDialog(row['ID'])" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span> Quick View </span>
                </button>
                <button *ngIf="row['ID']" (click)="openContactInputDialog(row['ID'])" mat-menu-item>
                  <mat-icon>message</mat-icon>
                  <span>Contact {{ row['CalculatedName'] }}</span>
                </button>
                <button
                  *ngIf="row['ID'] && (type == 'patient' || isPromoterOrAdmin == true)"
                  (click)="editContact(row['ID'])"
                  mat-menu-item
                >
                  <mat-icon>message</mat-icon>
                  <span>Edit Contact</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginator
        [length]="dataSource.filteredData.length"
        [pageIndex]="0"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
      >
      </mat-paginator>
    </mat-card>
  </div>
</div>
<div class="clearfix full-width" *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <app-empty-list-message></app-empty-list-message>
</div>
