<mat-card class="rel clearfix card-container-list" *ngIf=" isLoaded == true;">
  <div class="card-header primary-gradient-img clearfix stacked" *ngIf="hideHeader!=true">
    <button class="btn-close btn-clear mat-button smt white custom-close" *ngIf="isModal == true"
      (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="row clearfix">
      <div class="row titleArea clearfix">
        <h2 class="summary-header text-left rm-m">{{title | translate}}</h2>
        <span class="subLabel" *ngIf="description">{{description | translate}}</span>
      </div>
    </div>
  </div>
  <mat-dialog-content id="messageGroupCreate" [ngClass]="{ 'no-modal-height': isModal != true }">
    <div class="full-width text-center" @simpleFadeAnimation>

      <div class="full-width">


        <button class=" btn ml  pull-right" (click)="cancelGroup()"
          *ngIf="multipSelect == true && selectedIDs && selectedIDs.length>0 && messages && messages.length>0"
          mat-raised-button color="accent">
          <mat-icon *ngIf="statusIcons && statusIcons['Canceled']" class="{{statusIcons['Canceled']}} smr"
            style="font-size:1rem !important"></mat-icon>
          Cancel <strong class="smr">({{selectedIDs.length}})</strong>
        </button>

        <button class=" btn    pull-right" matTooltip="Refresh the list " *ngIf="isModal!=true "
          (click)="refreshEvent()" mat-icon-button color="primary">
          <mat-icon class="fas fa-sync-alt primary-color"></mat-icon>

        </button>

        <button class=" btn    pull-right mr " matTooltip="Attach Tags " mat-icon-button color="primary"
          [matMenuTriggerFor]="menu1100">
          <mat-icon class="fas fa-tags primary-color"></mat-icon>

        </button>

        <mat-menu #menu1100="matMenu">

          <button *ngIf="selectedIDs && selectedIDs.length>0" (click)="attachTagSelectedIDs()" mat-menu-item>
            Attach Tags to <strong class="smr"> selected {{'Patient' | translate}}s</strong> ({{selectedIDs.length}})
          </button>



          <mat-divider *ngIf="selectedIDs && selectedIDs.length>0"></mat-divider>

          <button [matMenuTriggerFor]="statusMessage1200" *ngIf="filterStatusArray && filterStatusArray.length>0"
            mat-menu-item>
            Attach Tags By <strong>Queue Status</strong>
          </button>

          <mat-menu #statusMessage1200="matMenu">

            <button *ngFor="let filter of filterStatusArray" (click)="attachTagByStatus(filter)" mat-menu-item>

              <mat-icon [ngStyle]="{ 'color':  getStatusColor(filter,true)  }" style="font-size: 1rem !important;"
                class=" {{messageUtil.getStatusIcon(filter)}} mr"></mat-icon>
              <span [ngStyle]="{ 'color':  getStatusColor(filter,true)  }">{{filter}}</span>


            </button>

          </mat-menu>


          <mat-divider *ngIf="filterStatusArray && filterStatusArray.length>0"></mat-divider>

          <button [matMenuTriggerFor]="statusMessage12008"
            *ngIf="filterMessageStatusArray && filterMessageStatusArray.length>0" mat-menu-item>
            Attach Tags By <strong>Message Status</strong>
          </button>

          <mat-menu #statusMessage12008="matMenu">

            <button *ngFor="let filter of filterMessageStatusArray" (click)="attachTagByMessageStatus(filter)"
              mat-menu-item>

              <mat-icon [ngStyle]="{ 'color':  getStatusColor(filter,false)  }" style="font-size: 1rem !important;"
                class=" {{messageUtil.getMessageStatusIcon(filter)}} mr"></mat-icon>
              <span [ngStyle]="{ 'color':  getStatusColor(filter,false)  }">{{filter}}</span>


            </button>

          </mat-menu>



          <mat-divider *ngIf="filterMessageStatusArray && filterMessageStatusArray.length>0"></mat-divider>
          <button (click)="attachTagAll()" mat-menu-item>
            Attach Tags to <strong> All {{'Patient' | translate}}s</strong>
          </button>




        </mat-menu>









        <button class=" btn  mr  pull-right" matTooltip="Unselect All" *ngIf=" messages && messages.length>0"
          (click)="diselectAll()" mat-icon-button color="primary">
          <mat-icon class="far fa-square primary-color">
          </mat-icon>
        </button>


        <button class=" btn  smr  pull-right" matTooltip="Select All" *ngIf=" messages && messages.length>0"
          (click)="selectAll()" mat-icon-button color="primary">
          <mat-icon class="far fa-check-square primary-color"></mat-icon>
        </button>




        <h3 class="sr-title text-left  pull-left text-center    rm-mt smt smb">

          Messages
          <span class="badge master" *ngIf="messages" [endVal]="
                (messages
                | FilterArrayMultipleOrContain :'search' : filterData |  FilterArrayMultipleOrContain :['status'] : filterStatus | FilterArrayMultipleOrContain :['messageStatus'] : filterMessageStatus
                  | lengthOfArray) || 0
              " countUp></span>
        </h3>


      </div>

      <div class="clearfix flex full-width flex-center">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Search ...</mat-label>
          <input [(ngModel)]="filterData" (ngModelChange)="pageOne()" matInput name="search" tabIndex="1" />
        </mat-form-field>
        <mat-form-field class="half-width ml" *ngIf="filterStatusArray && filterStatusArray.length>0"
          appearance="outline">
          <mat-label> Queue list status</mat-label>
          <mat-select name="filterStatusArray" [(ngModel)]="filterStatus"  (ngModelChange)="pageOne()">
            <mat-option [value]="null">
              All
            </mat-option>
            <mat-option *ngFor="let filter of filterStatusArray" [value]="filter">
              <span [ngStyle]="{ 'background-color':  getStatusColor(filter,true)  }" class="status-container">
                <mat-icon class="{{messageUtil.getStatusIcon(filter)}} smr "
                  style="font-size: 0.9rem; width: 0.9rem; height: 0.9rem;">
                </mat-icon> {{filter}}
              </span>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="half-width ml" *ngIf="filterMessageStatusArray && filterMessageStatusArray.length>0"
          appearance="outline">
          <mat-label> Message status</mat-label>
          <mat-select name="filterMessageStatusArray" [(ngModel)]="filterMessageStatus" (ngModelChange)="pageOne()">
            <mat-option [value]="null">
              All
            </mat-option>
            <mat-option *ngFor="let filter of filterMessageStatusArray" [value]="filter">
              <span class="status-container" [ngStyle]="{ 'background-color':  getStatusColor(filter,false)  }">
                <mat-icon class="{{messageUtil.getMessageStatusIcon(filter)}} smr "
                  style="font-size: 0.9rem;width: 0.9rem;height: 0.9rem;">
                </mat-icon> {{filter}}
              </span>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button class="actionMenuToggle ml mr" *ngIf="messages.length > 3" [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon class="fas fa-sort"></mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button [ngClass]="{ 'accent-color': orderByFilter === 'cardName' || orderByFilter === '-cardName' }"
            (click)="orderBy('cardName')" mat-menu-item>
            <span>Name</span>
            <span *ngIf="orderByFilter === 'cardName' || orderByFilter === '-cardName'">
              <mat-icon class="accent-color" *ngIf="orderByFilter === '-cardName'">arrow_drop_up</mat-icon>
              <mat-icon class="accent-color" *ngIf="orderByFilter !== '-cardName'">arrow_drop_down</mat-icon>
            </span>
          </button>
          <button [ngClass]="{ 'accent-color': orderByFilter === 'status' || orderByFilter === '-status' }"
            (click)="orderBy('cardName')" mat-menu-item>
            <span>Status</span>
            <span *ngIf="orderByFilter === 'status' || orderByFilter === '-status'">
              <mat-icon class="accent-color" *ngIf="orderByFilter === '-status'">arrow_drop_up</mat-icon>
              <mat-icon class="accent-color" *ngIf="orderByFilter !== '-status'">arrow_drop_down</mat-icon>
            </span>
          </button>
          <button
            [ngClass]="{ 'accent-color': orderByFilter === 'messageStatus' || orderByFilter === '-messageStatus' }"
            (click)="orderBy('cardName')" mat-menu-item>
            <span>Message status</span>
            <span *ngIf="orderByFilter === 'messageStatus' || orderByFilter === '-messageStatus'">
              <mat-icon class="accent-color" *ngIf="orderByFilter === '-messageStatus'">arrow_drop_up</mat-icon>
              <mat-icon class="accent-color" *ngIf="orderByFilter !== '-messageStatus'">arrow_drop_down</mat-icon>
            </span>
          </button>
          <button [ngClass]="{ 'accent-color': orderByFilter === 'lastUpdate' || orderByFilter === '-lastUpdate' }"
            (click)="orderBy('lastUpdate')" mat-menu-item>
            <span>Last update</span>
            <span *ngIf="orderByFilter === 'lastUpdate' || orderByFilter === '-lastUpdate'">
              <mat-icon class="accent-color" *ngIf="orderByFilter === '-lastUpdate'">arrow_drop_down</mat-icon>
              <mat-icon class="accent-color" *ngIf="orderByFilter !== '-lastUpdate'"> arrow_drop_up</mat-icon>
            </span>
          </button>
          <button
            [ngClass]="{ 'accent-color': orderByFilter === 'submittedDate' || orderByFilter === '-submittedDate' }"
            (click)="orderBy('submittedDate')" mat-menu-item>
            <span>Submitted at</span>
            <span *ngIf="orderByFilter === 'submittedDate' || orderByFilter === '-submittedDate'">
              <mat-icon class="accent-color" *ngIf="orderByFilter === '-submittedDate'">arrow_drop_down</mat-icon>
              <mat-icon class="accent-color" *ngIf="orderByFilter !== '-submittedDate'"> arrow_drop_up</mat-icon>
            </span>
          </button>
        </mat-menu>
      </div>
      <hr class="card-devider">
      <div class="full-width  message-message-report-patient" [ngClass]=" { 'one-line' :  isOneLine==true  }">
        <app-empty-list-message *ngIf="(messages
          | FilterArrayMultipleOrContain :'search' : filterData |  FilterArrayMultipleOrContain :['status'] : filterStatus | FilterArrayMultipleOrContain :['messageStatus'] : filterMessageStatus
            | lengthOfArray) <= 0" [title]="''" [message]="'No messages found in the Queue'">
        </app-empty-list-message>
        <div class="full-width  message-message-report-container flex " *ngFor="let card of messages  | FilterArrayMultipleOrContain :'search' : filterData  |  FilterArrayMultipleOrContain :['status'] : filterStatus | FilterArrayMultipleOrContain :['messageStatus'] : filterMessageStatus  | OrderArrayObject: [orderByFilter] |
            paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage } ; let index=index"
          (click)="$event.stopPropagation();openPatientViewDialogContainer(card['ID']);"
          [ngClass]="{'pair': index % 2 == 1, 'pointer': isViewOnly==true }">
          <div class="pull-right upper-side">
            <button class="" *ngIf="isOneLine!=true && isViewOnly!=true" (click)="$event.stopPropagation();"
              [matMenuTriggerFor]="menu11" color="accent" mat-icon-button matTooltip="View  Details">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu11="matMenu">
              <button *ngIf="card && card.message" (click)="$event.stopPropagation();preview(card)" mat-menu-item>
                <mat-icon class="fas fa-mobile  smr"></mat-icon>
                Preview
              </button>
              <button (click)="$event.stopPropagation();openPatientViewDialog(card['cardID'])" mat-menu-item>
                <mat-icon class="smr">visibility</mat-icon>
                View {{'Patient' | translate}}
              </button>
              <hr *ngIf="isCancel == true && card['canCancel']===true" />
              <button *ngIf="isCancel == true&& card['canCancel']===true"
                (click)="$event.stopPropagation();cancel(card)" mat-menu-item color="accent">
                <mat-icon class="{{statusIcons['Canceled']}} smr" style=" color:red; margin-top: 11px !important;
                display: block;
                float: left;"></mat-icon>
                <span style="color:red;"> Cancel</span>
              </button>
            </mat-menu>
          </div>
          <mat-checkbox class="select-group-message-one-line mr " *ngIf=" card['id']  && isOneLine==true"
            [checked]="isSelected(card['id'])" (click)="$event.stopPropagation()" (change)="selectMessage(card['id'])">
          </mat-checkbox>
          <mat-checkbox class="select-group-message-not-one-line " *ngIf=" card['id']  && isOneLine==false"
            [checked]="isSelected(card['id'])" (click)="$event.stopPropagation()" (change)="selectMessage(card['id'])">
          </mat-checkbox>
          <app-card-picture *ngIf=" card && isOneLine==true" class="side-message-report-picture-preview-bulk-sms2"
            [hideZoomButton]="false" [tableName]="'customer'" [cardID]="card.cardID">
          </app-card-picture>
          <app-card-picture *ngIf="card && isOneLine!=true" class="side-message-report-picture-preview-bulk-sms"
            [hideZoomButton]="false" [tableName]="'customer'" [cardID]="card.cardID">
          </app-card-picture>
          <div class="full-width ml">
            <div class="full-width flex" *ngIf="isOneLine!=true">
              <h3 class="sr-title text-left full-width  rm-m">
                {{card['cardName']}}
              </h3>
              <p *ngIf="card['submittedDate']" class="text-right  full-width   small rm-m"
                style="  color: rgb(155, 155, 155); margin-right: 60px; "
                matTooltip="{{ card['submittedDate'] | customDate }}">
                Sent : {{  card.submittedDate | TimeLabelPast }} ago</p>
            </div>
            <div *ngIf="isOneLine==true" class="full-width flex">
              <h4 class="sr-title text-left full-width  rm-m smt mr " style="width:200% !important">
                <span *ngIf="card['cardName']">{{card['cardName']}}</span>
              </h4>
              <p class="text-left  full-width mr small rm-m smt ">
                <span *ngIf="card['cardMobile']"> {{card['cardMobile'] |  customPhone}}</span>
              </p>
              <p class="text-left  full-width  mr small rm-m smt " *ngIf="isSMS!=true">
                <span *ngIf="card['cardEmail']">{{card['cardEmail']}}</span>
              </p>





              <p class="text-left   mr small rm-m smt " style="width:80px !important"
                matTooltip="Submit at: {{ card['submittedDate'] |  customDateTime: 'YYYY-MM-DD HH:mm:ss'}} ">
                <mat-icon *ngIf="card['status']" matTooltip=" {{card['status']}}"
                  class="{{messageUtil.getStatusIcon(card['status'])}}  smr"
                  [ngStyle]="{ 'color':  card['statusColor']  }">
                </mat-icon>



              </p>

              <p class="text-left  full-width  mr small rm-m smt "
                matTooltip="Last update: {{ card['lastUpdate'] |  TimeLabelPast}} ago">
                <span>{{card['lastUpdate'] |  customDateTime: 'YYYY-MM-DD HH:mm:ss'}} </span>
              </p>

              <p class="text-left  full-width  mr small rm-m smt ">
                <span *ngIf="card['messageStatus']" class="status-container"
                  [ngStyle]="{ 'background-color':  card['messageStatusColor']  }">
                  <mat-icon class="{{messageUtil.getMessageStatusIcon(card['messageStatus'])}} smr "
                    style="font-size: 0.9rem;width: 0.9rem;height: 0.9rem;">
                  </mat-icon>
                  {{card['messageStatus']}}
                </span>
              </p>
              <!-- <p *ngIf="card['submittedDate']" class="text-right  mr  full-width   small rm-m smt "
                style="    margin-right: 30px !important;     color: rgb(155, 155, 155); "
                matTooltip="{{ card['submittedDate'] | customDate }}">
                Sent : {{  card.submittedDate | TimeLabelPast }} ago</p>
              <p *ngIf="!card['submittedDate']" class="text-right  mr  full-width   small rm-m smt "
                style="    margin-right: 30px !important;     color: rgb(155, 155, 155); ">...</p> -->
              <button class=" mr" *ngIf="isViewOnly!=true" (click)="$event.stopPropagation();"
                style="margin-top: 10px !important;" [matMenuTriggerFor]="menu2222" color="accent" mat-icon-button
                matTooltip="View  Details">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu2222="matMenu">
                <button *ngIf="card && card.message" (click)="$event.stopPropagation();preview(card)" mat-menu-item>
                  <mat-icon class="fas fa-mobile smr"></mat-icon>
                  Message Preview
                </button>

                <mat-divider *ngIf="card && card.message"></mat-divider>
                <button *ngIf="card && card.cardID"
                  (click)="$event.stopPropagation();openPatientViewDialog(card['cardID'])" mat-menu-item>
                  <mat-icon class="smr">search</mat-icon>
                  <strong> {{'Patient' | translate}} </strong> Quick View
                </button>

                <button *ngIf="card && card.cardID"
                  (click)="$event.stopPropagation();patientDetailedView(card['cardID'])" mat-menu-item>
                  <mat-icon class="smr">description</mat-icon>
                  <strong>{{'Patient' | translate}} </strong> Detailed View
                </button>

                <button *ngIf="card && card.cardID &&  card.merchantID &&  isPromoterOrAdmin==true"
                  (click)="$event.stopPropagation();sendSMS(card['cardID'],card['merchantID'])" mat-menu-item>
                  <mat-icon class="fas fa-sms smr"></mat-icon>
                  SMS Conversation
                </button>



                <button *ngIf="card && card.cardID &&  isPromoterOrAdmin!=true"
                  (click)="$event.stopPropagation();sendSMSMerchant(card['cardID'])" mat-menu-item>
                  <mat-icon class="fas fa-sms smr"></mat-icon>
                  SMS Conversation
                </button>



                <hr  *ngIf="card && card.messageID " />

                <button *ngIf="card && card.messageID "
                  (click)="$event.stopPropagation();hardRefresh(card['messageID'])" mat-menu-item>
                  <mat-icon class="fas fa-redo-alt smr"></mat-icon>
                  Hard Refresh
                </button>




                <hr *ngIf="card && card.cardID" />
                <button *ngIf="isCancel == true && card['canCancel']===true"
                  (click)="$event.stopPropagation();cancel(card)" mat-menu-item color="accent">
                  <mat-icon class="{{statusIcons['Canceled']}} smr" style=" color:red; margin-top: 11px !important;
                  display: block;
                  float: left;"></mat-icon>
                  <span style="color:red;"> Cancel</span>
                </button>
              </mat-menu>
            </div>
            <div *ngIf="isOneLine!=true">
              <p *ngIf="card['cardMobile']" class="text-left  full-width small rm-m">
                {{card['cardMobile'] |  customPhone}}
              </p>
              <div class="  full-width smb  flex">
                <p *ngIf="card['cardEmail'] && isSMS!==true" class="text-left  full-width  small rm-m">
                  {{card['cardEmail']}}</p>
              </div>
              <div class="  full-width  flex">
                <p *ngIf="card['status']" class="text-left  full-width small rm-m">

                  <mat-icon *ngIf="card['status']" matTooltip=" {{card['status']}}"
                    class="{{messageUtil.getStatusIcon(card['status'])}}  smr"
                    [ngStyle]="{ 'color':  card['statusColor']  }">
                  </mat-icon>

                </p>
                <p *ngIf="card['messageStatus']" class="text-left  full-width small rm-m">
                  <span class="status-container" *ngIf="card['messageStatus']"
                    [ngStyle]="{ 'background':card['messageStatusColor'] +' !important;' }">
                    <mat-icon class=" {{messageUtil.getMessageStatusIcon(card['messageStatus'])}}  smr "
                      style="font-size: 0.9rem;width: 0.9rem;height: 0.9rem;">
                    </mat-icon>
                    {{card['messageStatus']}}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <div class="full-width text-center page-container  page-container-custom  flex ">

    <div class="full-width" *ngIf="(messages | FilterArrayMultipleOrContain :'search' : filterData |  FilterArrayMultipleOrContain :['status'] : filterStatus | FilterArrayMultipleOrContain :['messageStatus'] : filterMessageStatus
    | lengthOfArray) > itemsPerPage">
      <pagination-controls class="full-width clearfix" (pageChange)="changePage($event)" responsive="true">
      </pagination-controls>

    </div>
  </div>
  <div class="drop-button text-center clearfix  full-width" *ngIf="isModal == true ">
    <hr>
    <button class=" btn smt pull-left" (click)="closeEvent()" mat-raised-button color="primary">
      Close
    </button>
    <button class=" btn smt pull-right" (click)="refreshEvent()" mat-raised-button color="accent">
      <mat-icon class="fas fa-sync-alt mr"></mat-icon> Refresh
    </button>
  </div>
</mat-card>
