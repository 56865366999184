import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of as observableOf, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Dentist } from '../../feature/dentist/shared/dentist.type';
import { CustomerAndProspect } from '../../feature/invitation/shared/types/invitation.type';
import { Merchant } from '../../feature/merchant/shared/types/merchant.type';
import { Settings } from '../types/settings';

@Injectable()
export class UtilsService {
  static currentAcccess = null;
  private baseUrl = environment.nodeUrl;

  constructor(private httpClient: HttpClient) {}

  getFileDetails(id): Observable<any> {
    return this.httpClient.get(environment.nodeUrl + '/files/' + id).pipe(map((res: HttpResponse<any>) => res['data']));
  }

  /**
   * @description
   * Delete treatment document
   * @param data
   */
  removeDocument(data) {
    let options = {};
    if (data && data.payload) {
      const payload = data.payload;
      let params: HttpParams = new HttpParams();
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = { params };
    }
    return this.httpClient.delete(`${environment.nodeUrl}/document/invitation/${data.document_id}`, options);
  }

  getTagsList(body = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }
    options = {
      params,
    };
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .get(environment.nodeUrl + '/tag-merchant/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.httpClient
        .get(environment.nodeUrl + '/tag-merchant', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/tag-merchant', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMerchantList(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .get(environment.nodeUrl + '/merchant/global/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/merchant/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getDocumentTypeList(body = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };
    return this.httpClient
      .get(environment.nodeUrl + '/document-type'+'/cached/'+Settings.global['lookupHash'], options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  addAccessUser(code, cardID): Observable<any> {
    const payload = {
      moduleCode: code,
      userID: cardID,
    };

    return this.httpClient.post(environment.nodeUrl + '/module-user-access/access', payload).pipe(
      map((res: HttpResponse<any>) => {
        const result = res['data'] || null;

        return result;
      }),
      catchError((error: any): Observable<any> => {
        return null;
      })
    );
  }

  removeAccessUser(code, cardID): Observable<any> {
    const payload = {
      moduleCode: code,
      userID: cardID,
    };

    return this.httpClient.put(environment.nodeUrl + '/module-user-access/access', payload).pipe(
      map((res: HttpResponse<any>) => {
        const result = res['data'] || null;

        return result;
      }),
      catchError((error: any): Observable<any> => {
        return null;
      })
    );
  }

  getAccessInvitationList(id): Observable<any> {
    return this.httpClient.get(environment.nodeUrl + '/invitation/access/' + id).pipe(
      map((res: HttpResponse<any>) => {
        const result = res['data'] || null;

        return result;
      }),
      catchError((error: any): Observable<any> => {
        return null;
      })
    );
  }

  getAccessAppointmentList(id): Observable<any> {
    return this.httpClient.get(environment.nodeUrl + '/appointment/access/' + id).pipe(
      map((res: HttpResponse<any>) => {
        const result = res['data'] || null;

        return result;
      }),
      catchError((error: any): Observable<any> => {
        return null;
      })
    );
  }

  getAccessMedicalHistoryList(id): Observable<any> {
    return this.httpClient.get(environment.nodeUrl + '/medical-history/access/' + id).pipe(
      map((res: HttpResponse<any>) => {
        const result = res['data'] || null;

        return result;
      }),
      catchError((error: any): Observable<any> => {
        return null;
      })
    );
  }

  getAccessList(): Observable<any> {
    return this.httpClient.get(environment.nodeUrl + '/module-access').pipe(
      map((res: HttpResponse<any>) => {
        const result = res['data'] || null;

        return result;
      }),
      catchError((error: any): Observable<any> => {
        return null;
      })
    );
  }

  getGlobalAccess(userID): Observable<any> {
    return this.httpClient.get(environment.nodeUrl + '/module-user-access/access/global/' + userID).pipe(
      map((res: HttpResponse<any>) => {
        const result = res['data'] || null;

        return result;
      }),
      catchError((error: any): Observable<any> => {
        return null;
      })
    );
  }

  getGlobalEPAccess(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    return this.httpClient.get(environment.nodeUrl + '/module-user-access/global', options).pipe(
      map((res: HttpResponse<any>) => {
        const result = res['data'] || null;
        return result;
      }),
      catchError((error: any): Observable<any> => {
        return null;
      })
    );
  }

  getApplyCurrentAccess(): Observable<any> {
    return this.httpClient.get(environment.nodeUrl + '/module-user-access/access/current').pipe(
      map((res: HttpResponse<any>) => {
        const result = res['data'] || null;
        return result;
      }),
      catchError((error: any): Observable<any> => {
        return null;
      })
    );
  }

  getModuleAccessDetails(id): Observable<any> {
    return this.httpClient.get(environment.nodeUrl + '/module-access/code/' + id).pipe(
      map((res: HttpResponse<any>) => {
        const result = res['data'] || null;
        return result;
      }),
      catchError((error: any): Observable<any> => {
        return null;
      })
    );
  }

  getUserAccessList(moduleCode, payload, isAdminOrPromoter = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (moduleCode) {
      params = params.set('moduleCode', moduleCode);
    }

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    if (isAdminOrPromoter == true) {
      return this.httpClient.get(environment.nodeUrl + '/module-user-access/global', options).pipe(
        map((res: HttpResponse<any>) => {
          const result = res['data'] || null;
          return result;
        }),
        catchError((error: any): Observable<any> => {
          return null;
        })
      );
    } else {
      return this.httpClient.get(environment.nodeUrl + '/module-user-access', options).pipe(
        map((res: HttpResponse<any>) => {
          const result = res['data'] || null;
          return result;
        }),
        catchError((error: any): Observable<any> => {
          return null;
        })
      );
    }
  }

  getCurrentAccess(): Observable<any> {
    if (UtilsService.currentAcccess && UtilsService.currentAcccess.type == 'user') {
      return observableOf(UtilsService.currentAcccess);
    } else {
      return this.httpClient.get(environment.nodeUrl + '/module-user-access/access/current').pipe(
        map((res: HttpResponse<any>) => {
          const result = res['data'] || null;

          if (result && result.type == 'user') {
            UtilsService.currentAcccess = result;
          }
          return result;
        }),
        catchError((error: any): Observable<any> => {
          return null;
        })
      );
    }
  }

  getProductGroup(payload = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.httpClient
      .get(environment.nodeUrl + '/product-group/', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  searchByBsb(bsb) {
    return this.httpClient
      .get(`${environment.nodeUrl}/bankBranch/search/bsb/${bsb}`)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getBankListByName(name) {
    return this.httpClient
      .get(`${environment.nodeUrl}/bank/` + name)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getBankList() {
    return this.httpClient.get(`${environment.nodeUrl}/bank/`).pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getBankDetails(id) {
    return this.httpClient
      .get(`${environment.nodeUrl}/bankBranch/search/code/${id}`)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getBankBysuburb(code, suburb) {
    return this.httpClient
      .get(`${environment.nodeUrl}/bankBranch/search/code/${code}/suburb/${suburb}`)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  previewMessageByTemplate(template, payload = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.httpClient
      .get(`${environment.nodeUrl}/message-preview/${template}`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getTemplateList(payload = {}, isAdminOrPromoter = false) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    if (isAdminOrPromoter == true) {
      return this.httpClient
        .get(`${environment.nodeUrl}/template/global`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(`${environment.nodeUrl}/template`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getTemplateMerchantList(payload = {}, isAdminOrPromoter = false) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    if (isAdminOrPromoter == true) {
      return this.httpClient
        .get(`${environment.nodeUrl}/templateMerchant/global`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(`${environment.nodeUrl}/templateMerchant`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getTemplateImage(id) {
    return this.httpClient
      .get(`${environment.nodeUrl}/template/picture/${id}`)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getTemplateImageStreamLink(ID, LastModified = null) {
    if (
      LastModified &&
      LastModified !== '' &&
      LastModified !== undefined &&
      LastModified !== 'null' &&
      LastModified !== 'undefined '
    ) {
      return `${environment.nodeUrl}/template/picture-stream-cached/${LastModified}/` + ID;
    } else if (ID) {
      if (ID) {
        return `${environment.nodeUrl}/template/picture-stream/` + ID;
      }
    }
    return null;
  }

  getLocallyPersonalizedText(
    text: string,
    patient: Partial<CustomerAndProspect>,
    dentist: Partial<Dentist>,
    practice: Partial<Merchant>
  ) {
    const personalizations = text.match(/{{[^{}]*}}/gm);
    let result = text;

    if (personalizations) {
      const patientPersonalizations = personalizations.filter((personalization) => personalization.includes('Patient'));
      const dentistPersonalizations = personalizations.filter((personalization) => personalization.includes('Dentist'));
      const practicePersonalizations = personalizations.filter((personalization) =>
        personalization.includes('Practice')
      );

      if (patientPersonalizations.length > 0) {
        patientPersonalizations.forEach((personalization) => {
          const property = personalization.replace('{{Patient', '').replace('}}', '');
          if (patient[property]) {
            result = result.replace(personalization, patient[property]);
          } else {
            result = result.replace(personalization, '');
          }
        });
      }

      if (dentistPersonalizations.length > 0) {
        dentistPersonalizations.forEach((personalization) => {
          const property = personalization.replace('{{Dentist', '').replace('}}', '');
          if (dentist[property]) {
            result = result.replace(personalization, '');
          } else {
            result = result.replace(personalization, dentist[property]);
          }
        });
      }

      if (practicePersonalizations.length > 0) {
        practicePersonalizations.forEach((personalization) => {
          const property = personalization.replace('{{Practice', '').replace('}}', '');
          if (practice[property]) {
            result = result.replace(personalization, practice[property]);
          } else {
            result = result.replace(personalization, '');
          }
        });
      }
    }

    return result;
  }

  getTemplateTag(id, payload = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.httpClient
      .get(`${environment.nodeUrl}/template/tag/${id}`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getTemplateDetails(id) {
    return this.httpClient
      .get(`${environment.nodeUrl}/template/${id}`)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getServerDate() {
    const endpoint = 'settings/server-date';
    const url = `${this.baseUrl}/${endpoint}`;

    return this.httpClient.get<string>(url).pipe(map((response) => response['data']));
  }

  getDocumentVideoLogo(url) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (url) {
      params = params.set('url', url);
      options = {
        params,
      };
    }
    return this.httpClient.get(`${environment.nodeUrl}/document/video-logo`, options);
  }

  getEpdocumentCached(id, payload = {}, accountType = 'merchant') {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }
    options = {
      params,
    };
    if (accountType === 'admin') {
      return this.httpClient
        .get(`${environment.nodeUrl}/EPDocument/cached/global/` + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (accountType === 'merchant' || accountType === 'consumer') {
      return this.httpClient
        .get(`${environment.nodeUrl}/EPDocument/cached/` + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(`${environment.nodeUrl}/EPDocument/cached/guest/` + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getEpdocumentMiniDetails(id) {
    return this.httpClient
      .get(`${environment.nodeUrl}/EPDocument/mini-details/` + id)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  generateFileDate() {
    return new Date().toLocaleString().replace(/\//g, '-').replace(/,/g, '_').replace(/\s/g, '').replace(/:/g, '-');
  }

  getEpdocumentMiniThumbnailDetails(id) {
    return this.httpClient
      .get(`${environment.nodeUrl}/EPDocument/mini-details-thumbnail/` + id)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getDocumentStreamLink(ID) {
    if (ID) {
      return `${environment.nodeUrl}/EPDocument/stream/` + ID;
    }
    return null;
  }

  getDocumentThumbnailStreamLink(ID) {
    if (ID) {
      return `${environment.nodeUrl}/EPDocument/stream-thumbnail/` + ID;
    }
    return null;
  }

  getEpdocument(id, payload = {}, accountType = 'merchant') {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }
    options = {
      params,
    };
    if (accountType === 'admin') {
      return this.httpClient
        .get(`${environment.nodeUrl}/EPDocument/global/` + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (accountType === 'merchant' || accountType === 'consumer') {
      return this.httpClient
        .get(`${environment.nodeUrl}/EPDocument/` + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(`${environment.nodeUrl}/EPDocument/guest/` + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  uploadPublicDocument(payload): Observable<any> {
    return this.httpClient
      .post(environment.nodeUrl + '/document-link/document', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createShortcut(link): Observable<any> {
    return this.httpClient
      .post(environment.nodeUrl + '/l', { link })
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createEpdocument(body = {}, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .post(environment.nodeUrl + '/EPDocument/global', body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .post(environment.nodeUrl + '/EPDocument', body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  editEpdocument(id, payload = {}, isAdminOrPromoter = false) {
    if (isAdminOrPromoter === true) {
      return this.httpClient
        .put(`${environment.nodeUrl}/EPDocument/global/` + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .put(`${environment.nodeUrl}/EPDocument/` + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  uploadFile(file) {
    const endpoint = `${environment.nodeUrl}/files`;
    const formData: FormData = new FormData();
    formData.append('fileKey', file);
    return this.httpClient.post(endpoint, formData).pipe(map((res: HttpResponse<any>) => res['data']));
  }

  requestEPDocument(id, payload = {}) {
    return this.httpClient
      .post(`${environment.nodeUrl}/EPDocument/request-code/` + id, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getVimeoIframe(url) {
    if (url) {
      return this.httpClient.get('https://vimeo.com/api/oembed.json?url=' + url).pipe(
        map((res: HttpResponse<any>) => {
          if (res) {
            if (res['html']) {
              return res['html'];
            }
            return null;
          }
        })
      );
    }
    return null;
  }

  getEpdocumentThumbnail(id) {
    return this.httpClient
      .get(`${environment.nodeUrl}/EPDocument/thumbnail-picture/` + id)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getIP(): Observable<any[]> {
    return this.httpClient.get('https://api.ipify.org?format=jsonp&callback=?').pipe(
      map((res: HttpResponse<any>) => res['ip']),
      catchError((error: any) => throwError(error.error || 'Server error'))
    );
  }

  getEpdocumentArray(id, payload = {}) {
    const _payload: any = payload || {};
    _payload.id = id;
    _payload.ids = id;
    return this.httpClient
      .post(`${environment.nodeUrl}/EPDocument/array`, _payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getAddressess(term): Observable<any> {
    return this.httpClient.get(`${environment.nodeUrl}/kleber/address/search/${term}`).pipe(map((res) => res['data']));
  }

  getAddressDetails(recordID): Observable<any> {
    return this.httpClient.get(`${environment.nodeUrl}/kleber/address/${recordID}`).pipe(map((res) => res['data']));
  }

  getThemeCSSFile(id) {
    return this.httpClient.get(`${environment.nodeUrl}/theme/${id}/css`).pipe(map((res) => res['data']));
  }

  verifyPhone(prefix, phoneNumber) {
    return this.httpClient.get(`${environment.nodeUrl}/kleber/phone/verify/${prefix}/${phoneNumber}`);
  }

  verifyEmail(email) {
    return this.httpClient.get(`${environment.nodeUrl}/kleber/email/verify/${email}`);
  }

  getCodeLookup(category: string, payload = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }
    params = params.append('Category', category);
    options = {
      params,
    };
    return this.httpClient.get(environment.nodeUrl + '/codeLookup'+'/cached/'+Settings.global['lookupHash'], options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  getSimpleLookup(category: string, payload = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }
    if (category) {
      params = params.set('Category', category);
    }
    options = {
      params,
    };
    return this.httpClient
      .get(environment.nodeUrl + '/simpleLookup'+'/cached/'+Settings.global['lookupHash'], options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  public getSuburbByPostCode(postCode, body = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };

    return this.httpClient
      .get(`${environment.nodeUrl}/suburb/postCode/${postCode}`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  public getSuburbByStateByPostCode(name, state, postCode, body = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }
    options = {
      params,
    };
    return this.httpClient
      .get(`${environment.nodeUrl}/suburb/${name}/${state}/${postCode}`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  public getStateList() {
    let options = {};

    options = {};
    return this.httpClient
      .get(`${environment.nodeUrl}/suburb/state-list`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  public getSuburb(name, body = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }
    options = {
      params,
    };
    return this.httpClient
      .get(`${environment.nodeUrl}/suburb/${name}`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  public getSuburbByState(state, body = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };
    return this.httpClient
      .get(`${environment.nodeUrl}/suburb/sate/${state}`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getDocumentList(payload, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .get(environment.nodeUrl + '/EPDocument/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.httpClient
        .get(environment.nodeUrl + '/EPDocument', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer') {
      return this.httpClient
        .get(environment.nodeUrl + '/EPDocument/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/EPDocument/public', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getReferralSource(payload = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.httpClient
      .get(environment.nodeUrl + '/referralSource', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createAddressByLabel(payload = {}, type, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .post(environment.nodeUrl + '/card-address/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      if (type == 'patient') {
        payload['customerID'] = payload['ownerID'];
      } else if (type == 'contact') {
        payload['contactID'] = payload['ownerID'];
      }

      return this.httpClient
        .post(environment.nodeUrl + '/card-address', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer') {
      if (type == 'contact') {
        payload['contactID'] = payload['ownerID'];
      }

      return this.httpClient
        .post(environment.nodeUrl + '/card-address/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      if (type == 'contact') {
        payload['contactID'] = payload['ownerID'];
      }

      return this.httpClient
        .post(environment.nodeUrl + '/card-address/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  modifyAddressByLabel(ownerID, label, payload, type, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .put(environment.nodeUrl + '/card-address/' + ownerID + '/' + label + '/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      if (type == 'patient') {
        return this.httpClient
          .put(environment.nodeUrl + '/card-address/patient/' + ownerID + '/' + label, payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else if (type == 'contact') {
        return this.httpClient
          .put(environment.nodeUrl + '/card-address/contact/' + ownerID + '/' + label, payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .put(environment.nodeUrl + '/card-address/' + ownerID + '/' + label, payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else if (sessionType == 'customer') {
      if (type == 'contact') {
        return this.httpClient
          .put(environment.nodeUrl + '/card-address/contact/' + ownerID + '/' + label + '/customer', payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .put(environment.nodeUrl + '/card-address/' + ownerID + '/' + label + '/customer', payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (type == 'contact') {
        return this.httpClient
          .put(environment.nodeUrl + '/card-address/contact/' + ownerID + '/' + label + '/customer', payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .put(environment.nodeUrl + '/card-address/' + ownerID + '/' + label + '/customer', payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  deleteAddressByLabel(ownerID, label, type, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .delete(environment.nodeUrl + '/card-address/' + ownerID + '/' + label + '/global')
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      if (type == 'patient') {
        return this.httpClient
          .delete(environment.nodeUrl + '/card-address/patient/' + ownerID + '/' + label)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else if (type == 'contact') {
        return this.httpClient
          .delete(environment.nodeUrl + '/card-address/contact/' + ownerID + '/' + label)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .delete(environment.nodeUrl + '/card-address/' + ownerID + '/' + label)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else if (sessionType == 'customer') {
      if (type == 'contact') {
        return this.httpClient
          .delete(environment.nodeUrl + '/card-address/contact/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .delete(environment.nodeUrl + '/card-address/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (type == 'contact') {
        return this.httpClient
          .delete(environment.nodeUrl + '/card-address/contact/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .delete(environment.nodeUrl + '/card-address/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  getAddressByLabel(ownerID, label, type, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .get(environment.nodeUrl + '/card-address/' + ownerID + '/' + label + '/global')
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      if (type == 'patient') {
        return this.httpClient
          .get(environment.nodeUrl + '/card-address/patient/' + ownerID + '/' + label)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else if (type == 'contact') {
        return this.httpClient
          .get(environment.nodeUrl + '/card-address/contact/' + ownerID + '/' + label)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .get(environment.nodeUrl + '/card-address/' + ownerID + '/' + label)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else if (sessionType == 'customer') {
      if (type == 'contact') {
        return this.httpClient
          .get(environment.nodeUrl + '/card-address/contact/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .get(environment.nodeUrl + '/card-address/patient/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (type == 'contact') {
        return this.httpClient
          .get(environment.nodeUrl + '/card-address/contact/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .get(environment.nodeUrl + '/card-address/patient/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  createEmailByLabel(payload = {}, type, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .post(environment.nodeUrl + '/card-email/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      if (type == 'patient') {
        payload['customerID'] = payload['ownerID'];
      } else if (type == 'contact') {
        payload['contactID'] = payload['ownerID'];
      }

      return this.httpClient
        .post(environment.nodeUrl + '/card-email', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer') {
      if (type == 'contact') {
        payload['contactID'] = payload['ownerID'];
      }

      return this.httpClient
        .post(environment.nodeUrl + '/card-email/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      if (type == 'contact') {
        payload['contactID'] = payload['ownerID'];
      }

      return this.httpClient
        .post(environment.nodeUrl + '/card-email/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  modifyEmailByLabel(ownerID, label, payload, type, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .put(environment.nodeUrl + '/card-email/' + ownerID + '/' + label + '/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      if (type == 'patient') {
        return this.httpClient
          .put(environment.nodeUrl + '/card-email/patient/' + ownerID + '/' + label, payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else if (type == 'contact') {
        return this.httpClient
          .put(environment.nodeUrl + '/card-email/contact/' + ownerID + '/' + label, payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .put(environment.nodeUrl + '/card-email/' + ownerID + '/' + label, payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else if (sessionType == 'customer') {
      if (type == 'contact') {
        return this.httpClient
          .put(environment.nodeUrl + '/card-email/contact/' + ownerID + '/' + label + '/customer', payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .put(environment.nodeUrl + '/card-email/' + ownerID + '/' + label + '/customer', payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (type == 'contact') {
        return this.httpClient
          .put(environment.nodeUrl + '/card-email/contact/' + ownerID + '/' + label + '/customer', payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .put(environment.nodeUrl + '/card-email/' + ownerID + '/' + label + '/customer', payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  deleteEmailByLabel(ownerID, label, type, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .delete(environment.nodeUrl + '/card-email/' + ownerID + '/' + label + '/global')
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      if (type == 'patient') {
        return this.httpClient
          .delete(environment.nodeUrl + '/card-email/patient/' + ownerID + '/' + label)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else if (type == 'contact') {
        return this.httpClient
          .delete(environment.nodeUrl + '/card-email/contact/' + ownerID + '/' + label)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .delete(environment.nodeUrl + '/card-email/' + ownerID + '/' + label)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else if (sessionType == 'customer') {
      if (type == 'contact') {
        return this.httpClient
          .delete(environment.nodeUrl + '/card-email/contact/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .delete(environment.nodeUrl + '/card-email/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (type == 'contact') {
        return this.httpClient
          .delete(environment.nodeUrl + '/card-email/contact/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .delete(environment.nodeUrl + '/card-email/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  getEmailByLabel(ownerID, label, type, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .get(environment.nodeUrl + '/card-email/' + ownerID + '/' + label + '/global')
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      if (type == 'patient') {
        return this.httpClient
          .get(environment.nodeUrl + '/card-email/patient/' + ownerID + '/' + label)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else if (type == 'contact') {
        return this.httpClient
          .get(environment.nodeUrl + '/card-email/contact/' + ownerID + '/' + label)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .get(environment.nodeUrl + '/card-email/' + ownerID + '/' + label)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else if (sessionType == 'customer') {
      if (type == 'contact') {
        return this.httpClient
          .get(environment.nodeUrl + '/card-email/contact/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .get(environment.nodeUrl + '/card-email/patient/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (type == 'contact') {
        return this.httpClient
          .get(environment.nodeUrl + '/card-email/contact/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .get(environment.nodeUrl + '/card-email/patient/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  createMobileByLabel(payload = {}, type, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .post(environment.nodeUrl + '/card-mobile/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      if (type == 'patient') {
        payload['customerID'] = payload['ownerID'];
      } else if (type == 'contact') {
        payload['contactID'] = payload['ownerID'];
      }

      return this.httpClient
        .post(environment.nodeUrl + '/card-mobile', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer') {
      if (type == 'contact') {
        payload['contactID'] = payload['ownerID'];
      }

      return this.httpClient
        .post(environment.nodeUrl + '/card-mobile/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      if (type == 'contact') {
        payload['contactID'] = payload['ownerID'];
      }

      return this.httpClient
        .post(environment.nodeUrl + '/card-mobile/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  modifyMobileByLabel(ownerID, label, payload, type, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .put(environment.nodeUrl + '/card-mobile/' + ownerID + '/' + label + '/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      if (type == 'patient') {
        return this.httpClient
          .put(environment.nodeUrl + '/card-mobile/patient/' + ownerID + '/' + label, payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else if (type == 'contact') {
        return this.httpClient
          .put(environment.nodeUrl + '/card-mobile/contact/' + ownerID + '/' + label, payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .put(environment.nodeUrl + '/card-mobile/' + ownerID + '/' + label, payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else if (sessionType == 'customer') {
      if (type == 'contact') {
        return this.httpClient
          .put(environment.nodeUrl + '/card-mobile/contact/' + ownerID + '/' + label + '/customer', payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .put(environment.nodeUrl + '/card-mobile/' + ownerID + '/' + label + '/customer', payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (type == 'contact') {
        return this.httpClient
          .put(environment.nodeUrl + '/card-mobile/contact/' + ownerID + '/' + label + '/customer', payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .put(environment.nodeUrl + '/card-mobile/' + ownerID + '/' + label + '/customer', payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  deleteMobileByLabel(ownerID, label, type, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .delete(environment.nodeUrl + '/card-mobile/' + ownerID + '/' + label + '/global')
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      if (type == 'patient') {
        return this.httpClient
          .delete(environment.nodeUrl + '/card-mobile/patient/' + ownerID + '/' + label)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else if (type == 'contact') {
        return this.httpClient
          .delete(environment.nodeUrl + '/card-mobile/contact/' + ownerID + '/' + label)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .delete(environment.nodeUrl + '/card-mobile/' + ownerID + '/' + label)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else if (sessionType == 'customer') {
      if (type == 'contact') {
        return this.httpClient
          .delete(environment.nodeUrl + '/card-mobile/contact/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .delete(environment.nodeUrl + '/card-mobile/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (type == 'contact') {
        return this.httpClient
          .delete(environment.nodeUrl + '/card-mobile/contact/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .delete(environment.nodeUrl + '/card-mobile/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  getMobileByLabel(ownerID, label, type, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .get(environment.nodeUrl + '/card-mobile/' + ownerID + '/' + label + '/global')
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      if (type == 'patient') {
        return this.httpClient
          .get(environment.nodeUrl + '/card-mobile/patient/' + ownerID + '/' + label)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else if (type == 'contact') {
        return this.httpClient
          .get(environment.nodeUrl + '/card-mobile/contact/' + ownerID + '/' + label)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .get(environment.nodeUrl + '/card-mobile/' + ownerID + '/' + label)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else if (sessionType == 'customer') {
      if (type == 'contact') {
        return this.httpClient
          .get(environment.nodeUrl + '/card-mobile/contact/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .get(environment.nodeUrl + '/card-mobile/patient/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (type == 'contact') {
        return this.httpClient
          .get(environment.nodeUrl + '/card-mobile/contact/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .get(environment.nodeUrl + '/card-mobile/patient/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  createPhoneByLabel(payload = {}, type, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .post(environment.nodeUrl + '/card-phone/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      if (type == 'patient') {
        payload['customerID'] = payload['ownerID'];
      } else if (type == 'contact') {
        payload['contactID'] = payload['ownerID'];
      }

      return this.httpClient
        .post(environment.nodeUrl + '/card-phone', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer') {
      if (type == 'contact') {
        payload['contactID'] = payload['ownerID'];
      }

      return this.httpClient
        .post(environment.nodeUrl + '/card-phone/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      if (type == 'contact') {
        payload['contactID'] = payload['ownerID'];
      }

      return this.httpClient
        .post(environment.nodeUrl + '/card-phone/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  modifyPhoneByLabel(ownerID, label, payload, type, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .put(environment.nodeUrl + '/card-phone/' + ownerID + '/' + label + '/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      if (type == 'patient') {
        return this.httpClient
          .put(environment.nodeUrl + '/card-phone/patient/' + ownerID + '/' + label, payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else if (type == 'contact') {
        return this.httpClient
          .put(environment.nodeUrl + '/card-phone/contact/' + ownerID + '/' + label, payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .put(environment.nodeUrl + '/card-phone/' + ownerID + '/' + label, payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else if (sessionType == 'customer') {
      if (type == 'contact') {
        return this.httpClient
          .put(environment.nodeUrl + '/card-phone/contact/' + ownerID + '/' + label + '/customer', payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .put(environment.nodeUrl + '/card-phone/' + ownerID + '/' + label + '/customer', payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (type == 'contact') {
        return this.httpClient
          .put(environment.nodeUrl + '/card-phone/contact/' + ownerID + '/' + label + '/customer', payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .put(environment.nodeUrl + '/card-phone/' + ownerID + '/' + label + '/customer', payload)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  deletePhoneByLabel(ownerID, label, type, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .delete(environment.nodeUrl + '/card-phone/' + ownerID + '/' + label + '/global')
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      if (type == 'patient') {
        return this.httpClient
          .delete(environment.nodeUrl + '/card-phone/patient/' + ownerID + '/' + label)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else if (type == 'contact') {
        return this.httpClient
          .delete(environment.nodeUrl + '/card-phone/contact/' + ownerID + '/' + label)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .delete(environment.nodeUrl + '/card-phone/' + ownerID + '/' + label)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else if (sessionType == 'customer') {
      if (type == 'contact') {
        return this.httpClient
          .delete(environment.nodeUrl + '/card-phone/contact/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .delete(environment.nodeUrl + '/card-phone/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (type == 'contact') {
        return this.httpClient
          .delete(environment.nodeUrl + '/card-phone/contact/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .delete(environment.nodeUrl + '/card-phone/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  getPhoneByLabel(ownerID, label, type, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .get(environment.nodeUrl + '/card-phone/' + ownerID + '/' + label + '/global')
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      if (type == 'patient') {
        return this.httpClient
          .get(environment.nodeUrl + '/card-phone/patient/' + ownerID + '/' + label)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else if (type == 'contact') {
        return this.httpClient
          .get(environment.nodeUrl + '/card-phone/contact/' + ownerID + '/' + label)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .get(environment.nodeUrl + '/card-phone/' + ownerID + '/' + label)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else if (sessionType == 'customer') {
      if (type == 'contact') {
        return this.httpClient
          .get(environment.nodeUrl + '/card-phone/contact/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .get(environment.nodeUrl + '/card-phone/patient/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (type == 'contact') {
        return this.httpClient
          .get(environment.nodeUrl + '/card-phone/contact/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .get(environment.nodeUrl + '/card-phone/patient/' + ownerID + '/' + label + '/customer')
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  getSubAccountStatisticalData(payload, isAdminOrPromoter = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isAdminOrPromoter == true) {
      return this.httpClient
        .get(environment.nodeUrl + '/subaccount-statistical-data/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/subaccount-statistical-data', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getSubAccountStatisticalReport(payload = {}, isAdminOrPromoter = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isAdminOrPromoter == true) {
      return this.httpClient
        .get(environment.nodeUrl + '/subaccount-statistical-report/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/subaccount-statistical-report', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  createLinkParamsCached(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };



    return this.httpClient
      .get(environment.nodeUrl + '/link-params/cached', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }
  createLinkParams(params): Observable<any> {
    return this.httpClient
      .post(environment.nodeUrl + '/link-params', params)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getlinkParams(id) {
    return this.httpClient
      .get(`${environment.nodeUrl}/link-params/` + id, {})
      .pipe(map((res: Response) => res['data']));
  }

  getShortcutLink(id) {
    return this.httpClient.get(`${environment.nodeUrl}/shortcut/` + id, {}).pipe(map((res: Response) => res['data']));
  }

  getShortcutLinkByLink(link) {
    const payload = {
      link,
    };

    return this.httpClient
      .put(`${environment.nodeUrl}/shortcut/link`, payload)
      .pipe(map((res: Response) => res['data']));
  }

  createShortcutLink(link) {
    const payload = {
      link,
    };

    const urlPatient = environment.nodeUrl + '/shortcut';
    return this.httpClient.post(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
  }

  testTemplate(tag, payload = {}) {
    return this.httpClient
      .put(`${environment.nodeUrl}/template/send-test-email/${tag}`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getHttpParamsFromPayload(payload: object): HttpParams {
    const modifiedPayload = payload === undefined ? {} : payload;

    let params: HttpParams = new HttpParams();

    for (const key in modifiedPayload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, modifiedPayload[key]);
      }
    }

    return params;
  }

  getNextFriday(date = new Date()) {
    const dateValue = new Date(date.getTime());
    return new Date(dateValue.setDate(dateValue.getDate() + ((7 - dateValue.getDay() + 5) % 7 || 7)));
  }

  getLastFriday(date = new Date()) {
    const dateValue = new Date(date.getTime());
    return new Date(dateValue.setDate(dateValue.getDate() - ((dateValue.getDay() + 2) % 7)));
  }

  getFlattenedObject(data: object) {
    const result = {};

    function recursivelyFlatten(current, property) {
      if (Object(current) !== current) {
        result[property] = current;
      } else if (Array.isArray(current)) {
        for (let i = 0, l = current.length; i < l; i++) {
          recursivelyFlatten(current[i], property ? property + '.' + i : '' + i);
        }
      } else {
        let isEmpty = true;
        for (const p in current) {
          isEmpty = false;
          recursivelyFlatten(current[p], property ? property + '.' + p : p);
        }
        if (isEmpty) {
          result[property] = {};
        }
      }
    }
    recursivelyFlatten(data, '');
    return result;
  }

  getUnFlattenedObject(data) {
    if (Object(data) !== data || Array.isArray(data)) {
      return data;
    }
    let result = {},
      cur,
      prop,
      idx,
      last,
      temp;
    for (const p in data) {
      (cur = result), (prop = ''), (last = 0);
      do {
        idx = p.indexOf('.', last);
        temp = p.substring(last, idx !== -1 ? idx : undefined);
        cur = cur[prop] || (cur[prop] = !isNaN(parseInt(temp)) ? [] : {});
        prop = temp;
        last = idx + 1;
      } while (idx >= 0);
      cur[prop] = data[p];
    }
    return result[''];
  }

  isFontAwesomeIcon(icon: string) {
    if (icon) {
      if (icon.indexOf('fas ') !== -1 || icon.indexOf('fab-') !== -1) {
        return true;
      }
      return false;
    }
    return false;
  }

  getToolTip(): Observable<any> {

    return this.httpClient
      .get(environment.nodeUrl + "/tooltip", {}).pipe(
        map((res: HttpResponse<any>) => res['data']));
  }



  getDocumentTypeByCode(code, payload = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.httpClient
      .get(`${environment.nodeUrl}/document-type/code/${code}`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }


}
