import { Inject, Injectable } from '@angular/core';

import { Countries } from 'countries-data';
import { COUNTRIES_DATA } from '../modules/provider.module';

@Injectable()
export class CountryService {
  constructor(@Inject(COUNTRIES_DATA) private _countriesData: Countries) {}

  getCountries(): any {
    return this._countriesData;
  }

  getCountry(alpha2: string) {
    return this._countriesData[alpha2];
  }

  getCountryByPrefix(value) {
    const keys = Object.keys(this._countriesData);

    for (let i = 0; i < keys.length; i++) {
      const o = this._countriesData[keys[i]];
      if (
        o &&
        o['dialling'] &&
        o['dialling']['calling_code'] &&
        o['dialling']['calling_code'][0] &&
        o['dialling']['calling_code'][0] == value
      ) {
        return keys[i];
      }
    }

    return null;
  }
}
