import { Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-contract-intro-modal',
  templateUrl: './contract-intro-modal.component.html',
  styleUrls: ['./contract-intro-modal.component.css'],
})
export class ContractIntroModalComponent implements OnInit {
  showOnContractFirstVisit = JSON.parse(localStorage.getItem('showOnPatientListFirstVisit'));

  closeModal = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  doNotShow() {
    localStorage.setItem('showOnPatientListFirstVisit', JSON.stringify(this.showOnContractFirstVisit));

    this.closeModal.emit(this.showOnContractFirstVisit);
  }
}
