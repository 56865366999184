import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { TreatmentService } from '../shared/treatment.service';
import { TreatmentCategoryViewComponent } from '../treatment-category-view/treatment-category-view.component';

@Component({
  selector: 'app-type-create-edit',
  templateUrl: './treatment-type-create-edit.component.html',
  styleUrls: ['./treatment-type-create-edit.component.css'],
})
export class TreatmentTypeCreateEditComponent implements OnInit {
  @Input()
  typeID;

  type = {
    categoryID: null,
    label: null,
    isActive: true,
    fileID: null,
    logoID: null,
    description: null,
    itemCode: null,
  };

  isLogoFile = false;
  logoSrc;
  logoLink;

  @Output()
  closeModal = new EventEmitter();

  @Output()
  result = new EventEmitter();

  isModal = false;

  isEditMode = false;

  isDone = false;

  isAdminOrPromoter = false;

  categories = [];

  constructor(
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private utilsService: UtilsService,
    private router: Router,
    private treatmentService: TreatmentService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.typeID) {
        this.typeID = data.typeID;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      this.authenticationService.initCurrUser();
      if (params['typeID']) {
        this.typeID = params['typeID'];
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;
        this.setup();
      });
    });
  }

  setup() {
    if (this.typeID) {
      this.treatmentService.getTypeDetails(this.typeID).subscribe((res) => {
        if (res) {
          this.isEditMode = true;

          if (res['Label']) {
            this.type.label = res['Label'];
          }

          if (res['Active'] == '1') {
            this.type.isActive = true;
          }

          if (res['Active'] == '0') {
            this.type.isActive = false;
          }

          this.logoLink = null;
          this.logoLink = this.treatmentService.getProductLogoStreamLink(this.typeID, res['LastModified']);

          if (res['Category_key']) {
            this.type.categoryID = res['Category_key'];
          }

          if (res['Description']) {
            this.type.description = res['Description'];
          }

          if (res['Dafault_Item_Code']) {
            this.type.itemCode = res['Dafault_Item_Code'];
          }

          this.getTreatmentCategoryList();
        }
      });
    } else {
      this.isEditMode = false;
      this.getTreatmentCategoryList();
    }
  }

  getTreatmentCategoryList() {
    const payload = {
      fields: 'ID,Label',
      isActive: true,
    };

    this.treatmentService.getCategoryList(payload, this.isAdminOrPromoter).subscribe((res) => {
      this.categories = res;
    });
  }

  closeEvent() {
    this.closeModal.emit(true);
  }

  save() {
    if (this.type) {
      this.treatmentService.createTypeGlobal(this.type).subscribe((res) => {
        if (res) {
          NotifyAppComponent.displayToast('success', 'Successful operation', 'Type has been successfully added');

          this.isDone = true;

          this.result.emit(res);
        }
      });
    }
  }

  edit() {
    if (this.type) {
      this.treatmentService.editTypeGlobal(this.typeID, this.type).subscribe((res) => {
        if (res) {
          NotifyAppComponent.displayToast('success', 'Successful operation', 'Type has been successfully updated');
          this.isDone = true;
          this.result.emit(res);
        }
      });
    }
  }

  list() {
    this.router.navigate(['/merchant', { outlets: { page: ['treatment-type-list'] } }]);
  }

  changeLogoImage() {
    this.utilsService.getFileDetails(this.type.logoID).subscribe((res) => {
      if (res) {
        if (res.contentType == 'image/png' || res.contentType == 'image/jpg' || res.contentType == 'image/jpeg') {
          this.isLogoFile = true;
          this.logoSrc = `${environment.nodeUrl}/files/file-view/${this.type.logoID}`;
        } else {
          NotifyAppComponent.displayToast(
            'warning',
            'Error during operation',
            'Format of image should be png/jpg/jpeg'
          );
        }
      }
    });
  }

  onCompleteLogoAll(fileID) {
    if (fileID && fileID[0]) {
      this.type.logoID = fileID[0];
      this.changeLogoImage();
    }
  }

  viewCategory(id) {
    const ref = RootAppComponent.dialog.open(TreatmentCategoryViewComponent, {
      data: {
        categoryID: id,
      },

      width: '700px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }
}
