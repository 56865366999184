/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dentistry-types-overview.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../dentistry-types-list/dentistry-types-list.component.ngfactory";
import * as i3 from "../dentistry-types-list/dentistry-types-list.component";
import * as i4 from "../../merchant/shared/merchant.service";
import * as i5 from "./dentistry-types-overview.component";
var styles_DentistryTypesOverviewComponent = [i0.styles];
var RenderType_DentistryTypesOverviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DentistryTypesOverviewComponent, data: {} });
export { RenderType_DentistryTypesOverviewComponent as RenderType_DentistryTypesOverviewComponent };
export function View_DentistryTypesOverviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dentistry-types-list", [["class", "full-width clearfix"]], null, null, null, i2.View_DentistryTypesListComponent_0, i2.RenderType_DentistryTypesListComponent)), i1.ɵdid(1, 114688, null, 0, i3.DentistryTypesListComponent, [i4.MerchantService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_DentistryTypesOverviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dentistry-types-overview", [], null, null, null, View_DentistryTypesOverviewComponent_0, RenderType_DentistryTypesOverviewComponent)), i1.ɵdid(1, 114688, null, 0, i5.DentistryTypesOverviewComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DentistryTypesOverviewComponentNgFactory = i1.ɵccf("dentistry-types-overview", i5.DentistryTypesOverviewComponent, View_DentistryTypesOverviewComponent_Host_0, {}, {}, []);
export { DentistryTypesOverviewComponentNgFactory as DentistryTypesOverviewComponentNgFactory };
