<mat-card class="rel clearfix">
  <form #resetForm="ngForm">
    <div
      class="card-header primary-gradient-img clearfix stacked"
      [attr.style]="'background:' + theme.background + ' !important' | safeStyle"
    >
      <button class="btn-close btn-clear mat-button smt white custom-close" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>

      <div class="row clearfix">
        <div class="row titleArea clearfix">
          <h2 class="summary-header text-left rm-m" *ngIf="isInstantFinanceLink == true">Create Customized Link</h2>
          <h2 class="summary-header text-left rm-m" *ngIf="isInstantFinanceLink != true">
            Create Instant Finance Offer
          </h2>
        </div>
      </div>
    </div>

    <mat-dialog-content id="instantOffercreate">
      <div class="full-width text-center step-3" @simpleFadeAnimation [hidden]="step != 1">
        <div class="full-width">
          <h3
            class="sr-title text-left full-width text-center rm-mt smb"
            [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
          >
            Finance
          </h3>

          <div class="clearfix text-center smt full-width">
            <div class="clearfix full-width flex">
              <mat-form-field class="full-width smr" appearance="outline">
                <mat-label>Choose a Sender*</mat-label>
                <mat-select
                  class="full-width"
                  [(ngModel)]="contactID"
                  (ngModelChange)="changeDentist()"
                  placeholder=""
                  name="detnistID"
                >
                  <mat-option *ngIf="currentDentistID" [value]="currentDentistID">
                    <strong>My account</strong>
                  </mat-option>

                  <hr *ngIf="currentDentistID"/>

                  <mat-option *ngFor="let p of dentists" [value]="p['ID']">
                    {{ p.CalculatedName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="full-width smr" appearance="outline">
                <mat-label>Choose product group*</mat-label>
                <mat-select
                  class="full-width"
                  [(ngModel)]="productGroup"
                  (ngModelChange)="getLoanAmount(productGroup)"
                  placeholder=""
                  name="productGroup"
                >
                  <mat-option *ngFor="let p of financedProductGroup" [value]="p['GroupCode']">
                    <mat-icon class="fas {{ productUtil.getProductGroupIcon(p['GroupCode']) }} mr iconproductgroup ">
                    </mat-icon>

                    {{ productUtil.getLabel(p['GroupCode']) }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                class="full-width"
                *ngIf="settings && settings['context'] != 'breeze' "
                appearance="outline"
              >
                <mat-label>{{"KEYWORD.Treatment" | translate}} Value ($)*</mat-label>
                <input
                  [(ngModel)]="treatmentAmount"
                  (blur)="changeAmount()"
                  name="treatmentAmount"
                  matInput
                  placeholder="Please enter a value"
                  currencyMask
                />
              </mat-form-field>

              <mat-form-field
                class="full-width"
                *ngIf="settings && settings['context'] == 'breeze' "
                appearance="outline"
              >
                <mat-label>Amount to Finance ($)*</mat-label>
                <input
                  [(ngModel)]="treatmentAmount"
                  (blur)="changeAmount()"
                  name="treatmentAmount"
                  matInput
                  placeholder="Please enter a value"
                  currencyMask
                />
              </mat-form-field>
            </div>
            <p
              class="small text-left rm-m"
              *ngIf="
                minLoanAmount != null &&
                maxLoanAmount != null &&
                settings &&
                settings['context'] != 'breeze'
              "
              [ngClass]="{
                'error-treatment':
                  treatmentAmount && (treatmentAmount < minLoanAmount || treatmentAmount > maxLoanAmount)
              }"
            >
              {{ "KEYWORD.treatment" | translate | titlecase }} Value should be between
              <strong> {{ minLoanAmount | customCurrency }}</strong> and
              <strong> {{ maxLoanAmount | customCurrency }} </strong>
            </p>
            <p
              class="small text-left rm-m"
              *ngIf="
                minLoanAmount != null &&
                maxLoanAmount != null &&
                settings &&
                settings['context'] == 'breeze'            "
              [ngClass]="{
                'error-treatment':
                  treatmentAmount && (treatmentAmount < minLoanAmount || treatmentAmount > maxLoanAmount)
              }"
            >
              Amount to Finance should be between <strong> {{ minLoanAmount | customCurrency }}</strong> and
              <strong> {{ maxLoanAmount | customCurrency }} </strong>
            </p>
            <h3
              class="sr-title smt text-left full-width text-center smb"

              [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
            >
              Tags
            </h3>
            <div class="full-width">
              <app-tag-list
                class="full-width"
                [tags]="tags"
                [selectedTags]="defaultSelectedTags"
                [add]="addTag"
                [tagFormatIsObject]="false"
                [title]="'Attach tags to this landing page'"
                [canRemove]="true"
                [canCreate]="true"
                [directRemove]="true"
                [directRemoveDefault]="true"
                [hasFilter]="true"
                [canSelect]="false"
                (getCreate)="createNewTag()"
                (getSelectedTags)="getSelectedTags($event)"
              >
              </app-tag-list>
            </div>
          </div>
        </div>

        <div class="full-width smb" *ngIf="isInstantFinanceLink == true">
          <h3
            class="sr-title text-left full-width text-center mt rm-m"
            [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
          >
            Product Details
          </h3>
          <message-input
            class="full-width message-input-component260 message-input-component4"
            *ngIf="
              loadMessage == true &&
              isInstantFinanceLink == true &&
              loadPromoter == true &&
              loadContact == true &&
              loadMerchant == true
            "
            [isTiny]="true"
            [isModal]="false"
            [messageMode]="messageMode"
            [label]="'Please insert a  value'"
            [message]="_message"
            [showAllCustomization]="true"
            [card]="card"
            [textLength]="0"
            [displayEmoji]="false"
            [displayMessageTemplate]="true"
            [contact]="contact"
            [displayPatient]="displayPatient"
            [displayContact]="true"
            [displayMerchant]="true"
            [displayPromoter]="true"
            [displayMarketing]="true"
            [displayExpend]="false"
            [displayDentalOffers]="true"
            [displayDocument]="true"
            [saveAsTemplate]="false"
            [isSMSPreview]="true"
            [isDesktopPreview]="true"
            [displaySaveAsTemplate]="true"
            [encode]="false"
            [tableName]="tableName"
            (getResult)="getResultMessage($event)"
          >
          </message-input>
        </div>

        <h3
          class="sr-title text-left full-width text-center mt smb"
          [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
        >
          Upload new files <span *ngIf="fileIDs && fileIDs.length > 0"> ({{ fileIDs.length }})</span>
        </h3>
        <div class="full-width mb">
          <app-file-uploader
            class="text-center"
            [documentType]="'Additional Document'"
            [fileName]="'Shared Document'"
            [isGallery]="false"
            [isDragable]="true"
            (onCompleteAll)="onCompleFile($event)"
          >
          </app-file-uploader>
        </div>
        <div class="full-width" *ngIf="fileIDs && fileIDs.length > 0">
          <app-file-list
            class=""
            [defaultLabel]="'Shared Document'"
            [documentTypes]="documentTypes"
            [files]="fileIDs"
            [addFile]="addFile"
            [canPatientDocument]="false"
            [canGallery]="false"
            [canPrivateDocument]="false"
            [canEdit]="true"
            [canAction]="false"
            (remove)="removeNewFile($event)"
          >
          </app-file-list>
        </div>

        <h3
          class="sr-title text-left full-width text-center mt smb"
          *ngIf="isInstantFinanceLink == true"
          [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
        >
          Extra Links
        </h3>
        <h3
          class="sr-title text-left full-width text-center mt smb"
          *ngIf="
            isInstantFinanceLink != true && settings && settings['context'] != 'breeze'
          "
          [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
        >
          Email & Extra Links
        </h3>

        <div class="full-width flex smb">
          <mat-checkbox
            class="full-width text-left smt mr"
            *ngIf="
              isInstantFinanceLink != true &&
              settings &&
              settings['context'] != 'breeze'
            "
            [(ngModel)]="sendEmail"
            color="accent"
            name="sendEmail"
          >
            Notify {{ "KEYWORD.patient" | translate | titlecase }} By Email
          </mat-checkbox>

          <mat-checkbox
            class="full-width text-left smt mr"
            *ngIf="
              isInstantFinanceLink != true &&
              settings &&
              (settings['context'] == 'breeze' )
            "
            [(ngModel)]="sendEmail"
            color="accent"
            name="sendEmail"
          >
            Notify Customer By Email
          </mat-checkbox>
          <mat-checkbox
            class="full-width text-left smt mr"
            *ngIf="settings && settings['context'] != 'breeze' "
            [(ngModel)]="displayAppointmentPage"
            color="accent"
            name="displayAppointmentPage"
          >
            Appointment Request Link
          </mat-checkbox>

          <mat-checkbox
            class="full-width text-left smt mr"
            *ngIf="settings && settings['context'] != 'breeze' "
            [(ngModel)]="displayTipsAndOffersPage"
            color="accent"
            name="displayTipsAndOffersPage"
          >
            Tips and Offers Link
          </mat-checkbox>

          <mat-checkbox
            class="full-width text-left smt mr"
            *ngIf="settings && settings['context'] != 'breeze' "
            [(ngModel)]="displayGoingGreenPage"
            color="accent"
            name="displayGoingGreenPage"
          >
            Going Green Link
          </mat-checkbox>
        </div>
      </div>

      <div class="full-width text-center step-1" @simpleFadeAnimation [hidden]="step != 2">
        <div class="full-width flex">
          <div class="full-width">
            <div class="full-width flex">
              <div class="full-width mr">
                <div class="full-width text-center flex mt mb">
                  <div
                    class="theme-container"
                    *ngFor="let t of themes"
                    [attr.style]="'background:' + t.background + ' !important' | safeStyle"
                    (click)="selectTheme(t)"
                  >
                    <mat-icon
                      class="fas fa-check-circle"
                      *ngIf="t && t.code && theme && theme.code && t.code == theme.code"
                    >
                    </mat-icon>
                  </div>
                </div>

                <div class="full-width mt">
                  <h3
                    class="sr-title text-left full-width rm-mt"
                    [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
                  >
                    Title
                  </h3>

                  <mat-form-field class="clearfix full-width" style="margin-bottom: -10px" appearance="outline">
                    <mat-label>Landing Page Title</mat-label>
                    <input [(ngModel)]="title" name="Title" matInput placeholder="Landing Page Title" required/>
                  </mat-form-field>
                </div>
              </div>

              <mat-card
                class="editor-card-preview2 ml row file animate mat-elevation-z4 full-width"
                [ngClass]="isHeaderImage != true ? 'no-image' : ''"
              >
                <div class="full-width landing-page-create-gallery2-container">
                  <h3
                    class="sr-title full-width text-center rm-m smb"
                    *ngIf="isHeaderImage == true"
                    [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
                  >
                    Header Image
                  </h3>

                  <h3
                    class="sr-title full-width text-center rm-m smb"
                    *ngIf="isHeaderImage != true"
                    [attr.style]="'color:grey  !important' | safeStyle"
                  >
                    Header Image
                  </h3>
                  <app-ep-document-view-image
                    class="landing-page-create-gallery2"
                    *ngIf="isHeaderImage != true"
                    [imageLink]="
                      'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/no-image.jpg'
                    "
                    [hideZoomButton]="true"
                  >
                  </app-ep-document-view-image>

                  <app-ep-document-view-image
                    class="landing-page-create-gallery2"
                    *ngIf="headerImage != 'merchant' && isHeaderImage == true"
                    [ngClass]="getCircle(headerImage) == true ? 'round-circle' : ''"
                    [imageLink]="headerImage"
                    [hideZoomButton]="false"
                  >
                  </app-ep-document-view-image>

                  <app-ep-document-view-image
                    class="landing-page-create-gallery2 round-circle"
                    *ngIf="headerImage == 'merchant' && merchantID && isHeaderImage == true"
                    [imageLink]="getMerchantLink()"
                    [hideZoomButton]="false"
                  >
                  </app-ep-document-view-image>
                </div>

                <mat-checkbox
                  class="text-left no-image-checkbox"
                  [(ngModel)]="isHeaderImage"
                  matTooltip="Add Header Image"
                  color="accent"
                  name="isHeaderImage"
                >
                </mat-checkbox>

                <div class="full-width text-center card-image-footer">
                  <button
                    class="btn pull-right mr"
                    *ngIf="isHeaderImage != true"
                    [disabled]="true"
                    mat-icon-button
                    color="accent"
                  >
                    <mat-icon class="fas fa-upload"></mat-icon>
                  </button>
                  <button
                    class="btn pull-left ml"
                    *ngIf="isHeaderImage != true"
                    [disabled]="true"
                    mat-raised-button
                    color="accent"
                  >
                    <mat-icon class="fas fa-images smr"></mat-icon> Gallery
                  </button>

                  <button
                    class="btn pull-right mr"
                    *ngIf="isHeaderImage == true"
                    (click)="upload()"
                    mat-icon-button
                    color="accent"
                    matTooltip="Upload New Image"
                  >
                    <mat-icon class="fas fa-upload" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
                    </mat-icon>
                  </button>

                  <button
                    class="btn pull-left ml"
                    *ngIf="isHeaderImage == true"
                    [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
                    (click)="galleryHeader()"
                    mat-raised-button
                    color="accent"
                  >
                    <mat-icon class="fas fa-images smr"></mat-icon> Gallery
                  </button>
                </div>
              </mat-card>
            </div>

            <div class="full-width flex">
              <div class="full-width mr">
                <h3
                  class="sr-title text-left full-width rm-mt"
                  [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
                >
                  Description
                </h3>

                <message-input
                  class="full-width message-input-component"
                  *ngIf="
                    loadMessage == true &&
                    loadPromoter == true &&
                    (loadCard == true || isInstantFinanceLink == true) &&
                    loadContact == true &&
                    loadMerchant == true
                  "
                  [isTiny]="true"
                  [isModal]="false"
                  [ngClass]="
                    isModal == true
                      ? 'message-input-component3-description'
                      : 'message-input-component-not-modal-description'
                  "
                  [messageMode]="messageMode"
                  [label]="'Please insert a description'"
                  [message]="_description"
                  [showAllCustomization]="true"
                  [card]="card"
                  [textLength]="0"
                  [displayEmoji]="false"
                  [displayMessageTemplate]="true"
                  [contact]="contact"
                  [displayPatient]="displayPatient"
                  [displayContact]="true"
                  [displayMerchant]="true"
                  [displayPromoter]="true"
                  [displayMarketing]="true"
                  [displayExpend]="false"
                  [displayDentalOffers]="true"
                  [displayDocument]="true"
                  [saveAsTemplate]="false"
                  [isSMSPreview]="true"
                  [isDesktopPreview]="true"
                  [displaySaveAsTemplate]="true"
                  [encode]="false"
                  [tableName]="tableName"
                  (getResult)="getResultDescription($event)"
                >
                </message-input>
              </div>
              <div class="full-width ml">
                <h3
                  class="sr-title text-left full-width rm-mt"
                  [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
                >
                  Preview
                </h3>
                <div class="full-width flex">
                  <mat-card
                    class="full-width"
                    [ngClass]="isModal == true ? 'modal-card-description' : 'not-modal-card-description'"
                    [attr.style]="
                      'background:' + theme.background + ' !important;margin-top: 0px !important;' | safeStyle
                    "
                  >
                    <h1 class="white-header header-title text-left">{{ title }}</h1>

                    <div
                      class="white full-width header-description"
                      id="header-description"
                      [innerHTML]="URLifyDescription(description || '...') | safeHtml"
                    ></div>
                  </mat-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="full-width text-center step-1" @simpleFadeAnimation [hidden]="step != 3">
        <div class="full-width flex">
          <div class="full-width">
            <div class="full-width flex">
              <div class="full-width mr">
                <h3
                  class="sr-title text-left full-width text-center rm-mt"
                  *ngIf="isInstantFinanceLink != true"
                  [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
                >
                  Personal Message
                </h3>

                <message-input
                  class="full-width message-input-component"
                  *ngIf="
                    loadMessage == true &&
                    isInstantFinanceLink != true &&
                    loadPromoter == true &&
                    loadCard == true &&
                    loadContact == true &&
                    loadMerchant == true
                  "
                  [isTiny]="true"
                  [ngClass]="
                    isModal == true ? 'message-input-component3-message' : 'message-input-component-not-modal-message'
                  "
                  [isModal]="false"
                  [messageMode]="messageMode"
                  [label]="'Please insert a  message'"
                  [message]="_message"
                  [showAllCustomization]="true"
                  [card]="card"
                  [textLength]="0"
                  [displayEmoji]="false"
                  [displayMessageTemplate]="true"
                  [contact]="contact"
                  [displayPatient]="displayPatient"
                  [displayContact]="true"
                  [displayMerchant]="true"
                  [displayPromoter]="true"
                  [displayMarketing]="true"
                  [displayExpend]="false"
                  [displayDentalOffers]="true"
                  [displayDocument]="true"
                  [saveAsTemplate]="false"
                  [isSMSPreview]="true"
                  [isDesktopPreview]="true"
                  [displaySaveAsTemplate]="true"
                  [encode]="false"
                  [tableName]="tableName"
                  (getResult)="getResultMessage($event)"
                >
                </message-input>
              </div>
              <div class="full-width ml">
                <h3
                  class="sr-title text-left full-width rm-mt"
                  [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
                >
                  Preview
                </h3>
                <div class="full-width flex">
                  <mat-card
                    class="full-width"
                    [ngClass]="isModal == true ? 'modal-card-message' : 'not-modal-card-message'"
                    style="margin-top: 0px !important"
                  >
                    <h3
                      class="sr-title full-width text-center"
                      *ngIf="merchant"
                      [attr.style]="'color:' + theme.color + ' !important;' | safeStyle"
                    >
                      Message From {{ merchant['TradingAs'] }}
                    </h3>

                    <p class="full-width message-text" [innerHTML]="URLify(message || '...') | safeHtml"></p>
                  </mat-card>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="full-with"
          *ngIf="
            loadMessage == true &&
            isInstantFinanceLink != true &&
            loadPromoter == true &&
            loadCard == true &&
            loadContact == true &&
            loadMerchant == true
          "
        >
          <h3
            class="sr-title text-left full-width rm-mt"
            [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
          >
            Disclaimer Message
          </h3>

          <mat-checkbox
            class="full-width text-left disclaimer-message-check mb"
            [(ngModel)]="displayDisclaimerMessage"
            color="accent"
            name="displayDisclaimerMessage"
          >
            If turned on, the message below will appear before proceeding with a finance application, and the
            Customer must acknowledge it.
          </mat-checkbox>

          <message-input
            class="full-width message-input-component message-disclaimer-component3-message"
            *ngIf="displayDisclaimerMessage == true"
            [isTiny]="true"
            [isModal]="false"
            [messageMode]="messageMode"
            [label]="'Please insert a  message'"
            [message]="_disclaimerMessage"
            [showAllCustomization]="true"
            [card]="card"
            [textLength]="0"
            [displayEmoji]="false"
            [displayMessageTemplate]="true"
            [contact]="contact"
            [displayPatient]="displayPatient"
            [promoter]="promoter"
            [displayContact]="true"
            [displayMerchant]="true"
            [displayPromoter]="true"
            [displayMarketing]="true"
            [displayExpend]="false"
            [displayDentalOffers]="true"
            [displayDocument]="true"
            [saveAsTemplate]="false"
            [isSMSPreview]="true"
            [isDesktopPreview]="true"
            [displaySaveAsTemplate]="true"
            [isCustomTreatmentAmount]="true"
            [displayPreview]="true"
            [encode]="false"
            [tableName]="tableName"
            (getResult)="getResultMessageDisclaimer($event)"
          >
          </message-input>
        </div>
      </div>
    </mat-dialog-content>
    <div class="drop-button text-center white-background" *ngIf="step == 1 && messageRaw && descriptionRaw">
      <hr/>

      <button
        class="action-button btn-large pull-right smt"
        *ngIf="
          resetForm.form.valid &&
          messageRaw &&
          descriptionRaw &&
          theme.code !== 'blue' &&
          treatmentAmount > 0 &&
          productGroup &&
          treatmentAmount &&
          treatmentAmount >= minLoanAmount &&
          treatmentAmount <= maxLoanAmount
        "
        [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
        (click)="submit()"
        mat-raised-button
        color="accent"
      >
        Submit <mat-icon class="fas fa-paper-plane sml"></mat-icon>
      </button>
      <button
        class="action-button btn-large pull-right smt"
        *ngIf="
          resetForm.form.valid &&
          messageRaw &&
          descriptionRaw &&
          theme.code == 'blue' &&
          treatmentAmount > 0 &&
          productGroup &&
          treatmentAmount &&
          treatmentAmount >= minLoanAmount &&
          treatmentAmount <= maxLoanAmount
        "
        (click)="submit()"
        mat-raised-button
        color="accent"
      >
        Submit <mat-icon class="fas fa-paper-plane sml"></mat-icon>
      </button>

      <button
        class="action-button btn-large pull-right smt"
        *ngIf="
          !resetForm.form.valid ||
          !messageRaw ||
          !descriptionRaw ||
          !treatmentAmount ||
          treatmentAmount <= 0 ||
          !productGroup ||
          treatmentAmount < minLoanAmount ||
          treatmentAmount > maxLoanAmount
        "
        [disabled]="true"
        (click)="submit()"
        mat-raised-button
        color="accent"
      >
        Submit <mat-icon class="fas fa-paper-plane sml"></mat-icon>
      </button>

      <button
        class="action-button btn-large pull-right smt mr"
        *ngIf="
          resetForm.form.valid &&
          theme.code !== 'blue' &&
          treatmentAmount > 0 &&
          productGroup &&
          treatmentAmount &&
          treatmentAmount >= minLoanAmount &&
          treatmentAmount <= maxLoanAmount
        "
        (click)="next1()"
        mat-raised-button
        color="primary"
      >
        Customized <mat-icon class="sml">keyboard_arrow_right</mat-icon>
      </button>
      <button
        class="action-button btn-large pull-right smt mr"
        *ngIf="
          resetForm.form.valid &&
          theme.code == 'blue' &&
          treatmentAmount > 0 &&
          productGroup &&
          treatmentAmount &&
          treatmentAmount >= minLoanAmount &&
          treatmentAmount <= maxLoanAmount
        "
        (click)="next1()"
        mat-raised-button
        color="primary"
      >
        Customized <mat-icon class="sml">keyboard_arrow_right</mat-icon>
      </button>

      <button
        class="action-button btn-large pull-right smt mr"
        *ngIf="
          !resetForm.form.valid ||
          !treatmentAmount ||
          treatmentAmount <= 0 ||
          !productGroup ||
          treatmentAmount < minLoanAmount ||
          treatmentAmount > maxLoanAmount
        "
        [disabled]="true"
        (click)="next1()"
        mat-raised-button
        color="primary"
      >
        Customized <mat-icon class="sml">keyboard_arrow_right</mat-icon>
      </button>

      <button
        class="action-button btn-large pull-left smt"
        [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
        (click)="closeEvent()"
        mat-raised-button
        color="accent"
      >
        Close
      </button>
    </div>

    <div class="drop-button text-center white-background" *ngIf="step == 1 && (!messageRaw || !descriptionRaw)">
      <hr/>

      <button
        class="action-button btn-large pull-right smt"
        *ngIf="
          resetForm.form.valid &&
          theme.code !== 'blue' &&
          treatmentAmount > 0 &&
          productGroup &&
          treatmentAmount &&
          treatmentAmount >= minLoanAmount &&
          treatmentAmount <= maxLoanAmount
        "
        [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
        (click)="next1()"
        mat-raised-button
        color="accent"
      >
        Next <mat-icon class="sml">keyboard_arrow_right</mat-icon>
      </button>
      <button
        class="action-button btn-large pull-right smt"
        *ngIf="
          resetForm.form.valid &&
          theme.code == 'blue' &&
          treatmentAmount > 0 &&
          productGroup &&
          treatmentAmount &&
          treatmentAmount >= minLoanAmount &&
          treatmentAmount <= maxLoanAmount
        "
        (click)="next1()"
        mat-raised-button
        color="accent"
      >
        Next <mat-icon class="sml">keyboard_arrow_right</mat-icon>
      </button>

      <button
        class="action-button btn-large pull-right smt"
        *ngIf="
          !resetForm.form.valid ||
          !treatmentAmount ||
          treatmentAmount <= 0 ||
          !productGroup ||
          treatmentAmount < minLoanAmount ||
          treatmentAmount > maxLoanAmount
        "
        [disabled]="true"
        (click)="next1()"
        mat-raised-button
        color="accent"
      >
        Next <mat-icon class="sml">keyboard_arrow_right</mat-icon>
      </button>

      <button
        class="action-button btn-large pull-left smt"
        [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
        (click)="closeEvent()"
        mat-raised-button
        color="accent"
      >
        Close
      </button>
    </div>

    <div class="drop-button text-center white-background" *ngIf="step == 2">
      <hr/>

      <button
        class="action-button btn-large pull-right smt"
        *ngIf="
          resetForm.form.valid &&
          descriptionRaw &&
          theme.code !== 'blue' &&
          treatmentAmount > 0 &&
          productGroup &&
          treatmentAmount &&
          treatmentAmount >= minLoanAmount &&
          treatmentAmount <= maxLoanAmount
        "
        [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
        (click)="next2()"
        mat-raised-button
        color="accent"
      >
        Next <mat-icon class="sml">keyboard_arrow_right</mat-icon>
      </button>
      <button
        class="action-button btn-large pull-right smt"
        *ngIf="
          resetForm.form.valid &&
          descriptionRaw &&
          theme.code == 'blue' &&
          treatmentAmount > 0 &&
          productGroup &&
          treatmentAmount &&
          treatmentAmount >= minLoanAmount &&
          treatmentAmount <= maxLoanAmount
        "
        (click)="next2()"
        mat-raised-button
        color="accent"
      >
        Next <mat-icon class="sml">keyboard_arrow_right</mat-icon>
      </button>

      <button
        class="action-button btn-large pull-right smt"
        *ngIf="
          !resetForm.form.valid ||
          !descriptionRaw ||
          !treatmentAmount ||
          treatmentAmount <= 0 ||
          !productGroup ||
          treatmentAmount < minLoanAmount ||
          treatmentAmount > maxLoanAmount
        "
        [disabled]="true"
        (click)="next2()"
        mat-raised-button
        color="accent"
      >
        Next <mat-icon class="sml">keyboard_arrow_right</mat-icon>
      </button>

      <button
        class="action-button btn-large pull-left smt"
        [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
        (click)="preview1()"
        mat-raised-button
        color="accent"
      >
        <mat-icon class="smr">keyboard_arrow_left</mat-icon> Previous
      </button>
    </div>

    <div class="drop-button text-center white-background" *ngIf="isInstantFinanceLink != true && step == 3">
      <hr/>
      <button
        class="action-button btn-large pull-right smt"
        *ngIf="
          resetForm.form.valid &&
          messageRaw &&
          descriptionRaw &&
          theme.code !== 'blue' &&
          treatmentAmount > 0 &&
          productGroup &&
          treatmentAmount &&
          treatmentAmount >= minLoanAmount &&
          treatmentAmount <= maxLoanAmount
        "
        [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
        (click)="submit()"
        mat-raised-button
        color="accent"
      >
        Submit
      </button>
      <button
        class="action-button btn-large pull-right smt"
        *ngIf="
          resetForm.form.valid &&
          messageRaw &&
          descriptionRaw &&
          theme.code == 'blue' &&
          treatmentAmount > 0 &&
          productGroup &&
          treatmentAmount &&
          treatmentAmount >= minLoanAmount &&
          treatmentAmount <= maxLoanAmount
        "
        (click)="submit()"
        mat-raised-button
        color="accent"
      >
        Submit
      </button>

      <button
        class="action-button btn-large pull-right smt"
        *ngIf="
          !resetForm.form.valid ||
          !messageRaw ||
          !descriptionRaw ||
          !treatmentAmount ||
          treatmentAmount <= 0 ||
          !productGroup ||
          treatmentAmount < minLoanAmount ||
          treatmentAmount > maxLoanAmount
        "
        [disabled]="true"
        (click)="submit()"
        mat-raised-button
        color="accent"
      >
        Submit
      </button>

      <button
        class="action-button btn-large pull-left smt"
        [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
        (click)="preview2()"
        mat-raised-button
        color="accent"
      >
        <mat-icon class="smr">keyboard_arrow_left</mat-icon> Previous
      </button>
    </div>
    <div class="drop-button text-center white-background" *ngIf="isInstantFinanceLink == true && step == 3">
      <hr/>
      <button
        class="action-button btn-large pull-right smt"
        *ngIf="
          resetForm.form.valid &&
          descriptionRaw &&
          theme.code !== 'blue' &&
          treatmentAmount > 0 &&
          productGroup &&
          treatmentAmount &&
          treatmentAmount >= minLoanAmount &&
          treatmentAmount <= maxLoanAmount
        "
        [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
        (click)="submit()"
        mat-raised-button
        color="accent"
      >
        Submit
      </button>
      <button
        class="action-button btn-large pull-right smt"
        *ngIf="
          resetForm.form.valid &&
          descriptionRaw &&
          theme.code == 'blue' &&
          treatmentAmount > 0 &&
          productGroup &&
          treatmentAmount &&
          treatmentAmount >= minLoanAmount &&
          treatmentAmount <= maxLoanAmount
        "
        (click)="submit()"
        mat-raised-button
        color="accent"
      >
        Submit
      </button>

      <button
        class="action-button btn-large pull-right smt"
        *ngIf="
          !resetForm.form.valid ||
          !descriptionRaw ||
          !treatmentAmount ||
          treatmentAmount <= 0 ||
          !productGroup ||
          treatmentAmount < minLoanAmount ||
          treatmentAmount > maxLoanAmount
        "
        [disabled]="true"
        (click)="submit()"
        mat-raised-button
        color="accent"
      >
        Submit
      </button>

      <button
        class="action-button btn-large pull-left smt"
        [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
        (click)="preview2()"
        mat-raised-button
        color="accent"
      >
        Close
      </button>
    </div>
  </form>
</mat-card>
