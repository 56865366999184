<mat-card class="rel supplier-card clearfix mb" *ngIf="operator" @ngIfAnimation>
  <div class="card-header primary-gradient-img clearfix stacked">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" *ngIf="isModal" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <app-operator-view-picture class="profile-picture-view" [operatorID]="operatorID"></app-operator-view-picture>
        <h2 class="summary-header rm-m">
          {{ operator['CalculatedName'] }}
          <span class="subLabel" *ngIf="operator['TradingAs']">Trading as: {{ operator['TradingAs'] }}</span>
        </h2>
        <p class="clearfix small rm-mb">
          <span class="chip-enable">{{ operator['Role'] }}</span>
          <span
            [ngClass]="{
              'chip-enable': operator.enabled,
              'chip-disable': !operator.enabled
            }"
          >{{ operator.enabled ? 'enabled' : 'disabled' }}</span
          >
        </p>
      </div>
    </div>
    <div class="action-button">
      <button class="white" class="actionMenuToggle" [matMenuTriggerFor]="btnPatMenu" mat-mini-fab color="accent">
        <mat-icon class="white">more_vert</mat-icon>
      </button>

      <mat-menu #btnPatMenu="matMenu">
        <button (click)="contactUser()" mat-menu-item>
          <mat-icon>contact_mail</mat-icon>
          <span>Contact {{ operator['CalculatedName'] }}</span>
        </button>

        <button (click)="changePassword()" mat-menu-item>
          <mat-icon>vpn_key </mat-icon>
          <span>Change Password</span>
        </button>

        <button *ngIf="operator['enabled']" (click)="disableLogin()" mat-menu-item>
          <mat-icon>block</mat-icon>
          <span>Disable Login</span>
        </button>

        <button *ngIf="!operator['enabled']" (click)="enableLogin()" mat-menu-item>
          <mat-icon>verified_user</mat-icon>
          <span>Enable Login</span>
        </button>
      </mat-menu>
    </div>
  </div>

  <mat-dialog-content class="stacked-card-view" [ngClass]="{ 'no-modal-height': isModal != true }">
    <div class="full-width mb clearfix row">
      <div class="row full-width clearfix details flex" *ngIf="operator['UserName']">
        <mat-icon class="ico">person</mat-icon>
        <div class="row-content clearfix">
          <p class="rm-mb rm-mt">
            {{ operator['UserName'] }}
          </p>
          <p class="small">User name</p>
        </div>
      </div>

      <div class="row full-width clearfix details flex" *ngIf="operator['emails.Email']">
        <mat-icon class="ico">email</mat-icon>
        <div class="row-content">
          <p class="rm-mb rm-mt">{{ operator['emails.Email'] }}</p>
          <p class="small">Email</p>
        </div>
      </div>
      <div class="full-width flex row clearfix details">
        <div class="full-width mr row clearfix details flex" *ngIf="operator['phones.Number']">
          <mat-icon class="ico">phone</mat-icon>
          <div class="row-content clearfix">
            <p class="rm-mb rm-mt">
              {{ operator['phones.Number'] | customPhone: 'landLine' }}
            </p>
            <p class="small">Phone Home</p>
          </div>
        </div>

        <div class="full-width row clearfix details flex" *ngIf="operator['mobiles.Number']">
          <mat-icon class="ico">phone</mat-icon>
          <div class="row-content clearfix">
            <p class="rm-mb rm-mt">
              {{ operator['mobiles.Number'] | customPhone }}
            </p>
            <p class="small">Phone/Mobile</p>
          </div>
        </div>
      </div>
      <div class="row full-width clearfix details flex" *ngIf="operator['addresses.Calculated']">
        <mat-icon class="ico">location_on</mat-icon>
        <div class="row-content clearfix">
          <p class="rm-mb rm-mt">
            {{ operator['addresses.Calculated'] }}
          </p>
          <p class="small">Address</p>
        </div>
      </div>

      <div class="row full-width clearfix details flex" *ngIf="operator['URL']">
        <mat-icon class="ico">devices</mat-icon>
        <div class="row-content clearfix">
          <p class="rm-mb rm-mt">
            {{ operator['URL'] }}
          </p>
          <p class="small">Website</p>
        </div>
      </div>

      <ng-container *ngIf="operator['UltimateTableName'] === 'Merchant' && operator.practiceObject">
        <div class="row full-width clearfix details flex" *ngIf="operator.practiceObject['phones.Number']">
          <mat-icon class="ico">phone</mat-icon>
          <div class="row-content clearfix">
            <p class="rm-mb rm-mt">
              {{ operator.practiceObject['phones.Number'] | customPhone: 'landLine' }}
            </p>
            <p class="small">{{ "KEYWORD.practice" | translate | titlecase}} Phone</p>
          </div>
        </div>

        <div class="row full-width clearfix details flex" *ngIf="operator.practiceObject['emails.Email']">
          <mat-icon class="ico">email</mat-icon>
          <div class="row-content clearfix">
            <p class="rm-mb rm-mt">
              {{ operator.practiceObject['emails.Email'] }}
            </p>
            <p class="small">{{ "KEYWORD.practice" | translate | titlecase}} Email</p>
          </div>
        </div>

        <div class="row full-width clearfix details flex" *ngIf="operator.practiceObject['addresses.Calculated']">
          <mat-icon class="ico">email</mat-icon>
          <div class="row-content clearfix">
            <p class="rm-mb rm-mt">
              {{ operator.practiceObject['addresses.Calculated'] }}
            </p>
            <p class="small">{{ "KEYWORD.practice" | translate | titlecase}} Address</p>
          </div>
        </div>
      </ng-container>
      <h3 class="sr-title rm-m sm-h3 text-center mb" *ngIf="operator">Authentication methods</h3>

      <div class="full-width text-center" *ngIf="operator">
        <button
          class="connect-fb btn-small mr"
          [matTooltip]="getFacebookLabel()"
          [disabled]="!operator.authenticationMethod.hasFacebookID"
          mat-mini-fab
        >
          <mat-icon class="fab fa-facebook-f"></mat-icon>
        </button>

        <button
          class="connect-goog btn-small mr"
          [matTooltip]="getGoogleLabel()"
          [disabled]="!operator.authenticationMethod.hasGoogleID"
          mat-mini-fab
        >
          <mat-icon class="fab fa-google"></mat-icon>
        </button>

        <button
          class="connect-user btn-small"
          [matTooltip]="'Has UserName/Password'"
          [disabled]="!operator.authenticationMethod.hasUsername"
          mat-mini-fab
        >
          <mat-icon class="fas fa-key"></mat-icon>
        </button>
      </div>
    </div>
  </mat-dialog-content>

  <div class="drop-button text-center mt full-width" *ngIf="isModal == true">
    <button class="mr ml" (click)="overview()" mat-raised-button color="primary">Overview</button>
    <button class="mr ml" (click)="displayInfo()" mat-raised-button color="primary">
      <mat-icon>visibility</mat-icon>
      View profile
    </button>

    <button class="mr ml" (click)="loginAs(operator['contactKey'])" mat-raised-button color="accent">
      <mat-icon class="fas fa-user-shield mr"></mat-icon>
      Login as user
    </button>
  </div>
</mat-card>
