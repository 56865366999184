<div class="row clearfix full-height">
  <div *ngIf="hideMarkup == false">
    <app-empty-list-message
      class="full-width row clearfix"
      *ngIf="(!content || !templateID) && !campaign"
      [title]="''"
      [message]="'Please select a template to preview '"
    ></app-empty-list-message>
  </div>
  <div
    class="row clearfix flex flexCol full-height"
    *ngIf="
      (templateID && template && content) ||
      (campaign &&
        campaign.ID &&
        (campaign['Status.Code'] == 'PEND' || campaign['Status.Code'] == 'CMPL' || campaign['Status.Code'] == 'ACTV') &&
        campaign['LandingPageCode'])
    "
  >
    <div class="clearfix full-width row primary-gradient-img heading">
      <div class="inline pull-left" *ngIf="template && template.ID">
        <app-ep-document-view-image
          class="pull-left display-image-inner"
          [defaultPicture]="
            'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/marketing-place-holder.png)'
          "
          [link]="getImageLink(template['ID'], template['LastModified'])"
        >
        </app-ep-document-view-image>
      </div>

      <div class="titleArea review ml pull-left" *ngIf="template && template.ID && content">
        <h2 class="sr-title sm-h2 rm-m white">{{ template['Label'] }}</h2>
        <p class="rm-m" *ngIf="content['Email.Subject'] && hideSubject == false">
          {{ content['Email.Subject'] }}
        </p>
      </div>

      <div class="titleArea review ml pull-left" *ngIf="!template && campaign && campaign.ID && !content">
        <h2 class="sr-title sm-h2 rm-m white">Landing page</h2>
      </div>

      <!-- Action Buttons -->
      <div
        class="clearfix pull-right"
        *ngIf="
          template && template.ID && (template['LandingPageURL'] || template['LandingPageCode']) && hideOptions == false
        "
      >
        <button class="white" [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button (click)="openLandingPage()" mat-menu-item>
            <mat-icon class="fas fa-external-link-alt"></mat-icon>
            <span>Go to Landing Page</span>
          </button>
        </mat-menu>
      </div>

      <button class="btn-close btn-clear absolute mat-button" *ngIf="isModal == true" (click)="closeModalEvent()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>

    <div class="row clearfix full-width" *ngIf="hideMarkup == false">
      <mat-button-toggle-group
        class="btn-group row clearfix flex full-width text-center"
        *ngIf="tabs && tabs.length > 1"
        [(ngModel)]="preview"
      >
        <mat-button-toggle class="text-center full-width" *ngFor="let t of uniquteTab()" [value]="t.value">
          <mat-icon>{{ t.icon }}</mat-icon>
          <span>{{ t.label }}</span>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div
      class="row clearfix full-width flex full-height templateViewArea"
      *ngIf="content && content['Email.HTML'] && preview == 'email'"
    >
      <!-- html email preview -->
      <app-blob-view
        class="row clearfix template-review-blob flex flexCol full-width full-height"
        *ngIf="hideMarkup == false"
        [isDownload]="isDownload"
        [isProceed]="isProceed"
        [isPrint]="isPrint"
        [canProceed]="true"
        [labelProceed]="proceedLabel"
        [content]="content['Email.HTML']"
        [extension]="'html'"
        [label]="emailLabel"
        (proceed)="proceedEvent($event)"
      ></app-blob-view>
    </div>

    <div
      class="row clearfix full-width flex full-height templateViewArea"
      *ngIf="
        campaign &&
        campaign.ID &&
        (campaign['Status.Code'] == 'PEND' || campaign['Status.Code'] == 'CMPL' || campaign['Status.Code'] == 'ACTV') &&
        campaign['LandingPageCode'] &&
        preview == 'landingPage'
      "
    >
      <iframe
        class="preview-iframe"
        *ngIf="iFrameSrcSecured"
        [src]="iFrameSrcSecured"
        (load)="onFrameLoad($event)"
      ></iframe>
    </div>

    <div class="row clearfix full-width" *ngIf="content && content['SMS.Body'] && preview == 'sms'">
      <mat-dialog-content>
        <div class="marvel-device iphone8">
          <div class="top-bar"></div>
          <div class="sleep"></div>
          <div class="volume"></div>
          <div class="camera"></div>
          <div class="sensor"></div>
          <div class="speaker"></div>
          <div class="screen">
            <span class="messages">
              <p class="speech-bubble">{{ content['SMS.Body'] }}</p>
            </span>
          </div>

          <div class="home"></div>
          <div class="bottom-bar"></div>
        </div>
      </mat-dialog-content>
      <mat-divider></mat-divider>
      <div class="row clearfix full-width text-center" *ngIf="isProceed == true">
        <button
          class="pull-right"
          *ngIf="isProceed"
          (click)="proceedEvent(true)"
          mat-raised-button
          color="accent"
          matTooltip="{{ proceedLabel }}"
        >
          {{ proceedLabel }}
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
