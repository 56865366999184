/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dental-product-form.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./dental-product-form.component";
var styles_DentalProductFormComponent = [i0.styles];
var RenderType_DentalProductFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DentalProductFormComponent, data: {} });
export { RenderType_DentalProductFormComponent as RenderType_DentalProductFormComponent };
export function View_DentalProductFormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["dental-product-form works!"]))], null, null); }
export function View_DentalProductFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-dental-product-form", [], null, null, null, View_DentalProductFormComponent_0, RenderType_DentalProductFormComponent)), i1.ɵdid(1, 114688, null, 0, i2.DentalProductFormComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DentalProductFormComponentNgFactory = i1.ɵccf("ipv-dental-product-form", i2.DentalProductFormComponent, View_DentalProductFormComponent_Host_0, {}, {}, []);
export { DentalProductFormComponentNgFactory as DentalProductFormComponentNgFactory };
