import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { ContractService } from '../shared/contract.service';

@Component({
  selector: 'app-personal-circumstances-view',
  templateUrl: './personal-circumstances-view.component.html',
  styleUrls: ['./personal-circumstances-view.component.css'],
})
export class PersonalCircumstancesViewComponent implements OnInit {
  @Input()
  pciEntryID;

  @Input()
  pciEntryObj;

  @Input()
  title;

  @Input()
  category;

  @Output()
  close = new EventEmitter();

  @Output()
  getResult = new EventEmitter();

  isModal = false;

  pcDetail;

  isPromoterOrAdmin = false;

  // title = "";

  mySuburb;
  myState;
  myPostCode;
  myStreetNumber;
  myStreetName;
  myUnitNumber;
  myStreetType;
  myLatitude;
  myLongitude;

  livingExpensesType;
  livingExpenses;

  employmentStatus = [];

  persCircumUpdReasons = [];

  myPhoneNumber;
  myEmail;

  constructor(
    private authenticationService: AuthenticationService,
    private activeRouter: ActivatedRoute,
    private contractService: ContractService,
    private lookupService: LookupService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.category) {
        this.category = data.category;
      }

      if (data.pciEntryID) {
        this.pciEntryID = data.pciEntryID;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.activeRouter.params.subscribe((params) => {
      if (params['pciEntryID']) {
        this.pciEntryID = params['pciEntryID'];
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;
        if (this.isPromoterOrAdmin == true) {
          if (this.pciEntryID && typeof this.pciEntryID == 'string' && this.category) {
            this.getDetails();
          }

          this.lookupService.getLookup('CodeLookup', 'PersCircumUpdReason').subscribe((res) => {
            if (res) {
              this.persCircumUpdReasons = res;
            }
          });

          if (this.category == 'employmentAndBenefits') {
            this.lookupService.getLookup('CodeLookup', 'EmploymentStatus').subscribe((res) => {
              if (res) {
                this.employmentStatus = res;
              }
            });
          }
        }
      });
    });
  }

  submit() {
    const payload = {
      updatedReason: this.pcDetail['Updating_Reason.Code'],
      updatedComment: this.pcDetail['Updating_Reason.Comment'],
      incomeValue: this.pcDetail['Income.Value'],
      incomeFrequency: this.pcDetail['Income.Frequency'],
      startYear: this.pcDetail['Info.Employment.Start.Year'],
      startMonth: this.pcDetail['Info.Employment.Start.Month'],
      timeInRoleYears: this.pcDetail['Info.Employment.TimeInRole.Years'],
      timeInRoleMonths: this.pcDetail['Info.Employment.TimeInRole.Months'],
      nextPayDate: this.pcDetail['Info.Employment.NextPayDate'],
      employmentTypeCode: this.pcDetail['Info.Employment.Type.Code'],
      employmentRole: this.pcDetail['Info.Employment.Role'],
      employmentHours: this.pcDetail['Info.Employment.Hours'],
      employerName: this.pcDetail['Info.Employment.Employer.Name'],
      employerContactName: this.pcDetail['Info.Employment.Employer.ContactName'],
      employerNumber: this.myPhoneNumber,
      employerEmail: this.myEmail,
      isOnProbation: this.pcDetail['Info.Employment.OnProbation'],
      addressUnit: this.pcDetail['Address.Unit'],
      addressStreetNumber: this.pcDetail['Address.StreetNr'],
      addressStreetName: this.pcDetail['Address.StreetName'],
      addressStreetType: this.pcDetail['Address.StreetType.Abbreviated'],
      addressStreetSuburb: this.pcDetail['Address.Suburb'],
      addressStreetPostCode: this.pcDetail['Address.Postcode'],
      addressStreetState: this.pcDetail['Address.State'],
    };
  }

  closeEvent() {
    this.close.emit(true);
  }

  convertMonth(value) {
    const _value = Number(value);
    if (_value >= 0) {
      const months = [
        '',
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];

      return months[_value];
    }
  }

  convertFrequency(value) {
    let result;
    if (value && value == '1') {
      result = 'Weekly';
    } else if (value && value == '2') {
      result = 'Fortnightly';
    } else if (value && value == '4') {
      result = 'Monthly';
    } else if (value && value == '12') {
      result = 'Quarterly';
    } else if (value && value == '26') {
      result = 'Half Yearly';
    } else if (value && value == '48') {
      result = 'Yearly';
    } else {
      result = '';
    }

    return result;
  }

  getDetails() {
    if (this.pciEntryObj) {
      this.pcDetail = this.pciEntryObj;

      this.mySuburb = this.pcDetail['Address.Suburb'];
      this.myState = this.pcDetail['Address.State'];
      this.myPostCode = this.pcDetail['Address.Postcode'];
      this.myStreetNumber = this.pcDetail['Address.StreetNr'];
      this.myStreetName = this.pcDetail['Address.StreetName'];
      this.myUnitNumber = this.pcDetail['Address.Unit'];
      this.myStreetType = this.pcDetail['Address.StreetType.Abbreviated'];

      this.myPhoneNumber = this.pcDetail['Info.Employment.Employer.Number'];
      this.myEmail = this.pcDetail['Info.Employment.Employer.Email'];
    }
  }

  getinvestmentPropertyAddress(address) {
    if (address) {
      this.myUnitNumber = address.unitNumber;
      this.myStreetNumber = address.streetNumber;
      this.myStreetName = address.streetName;
      this.myStreetType = address.streetType;
      this.mySuburb = address.suburb;
      this.myState = address.state;
      this.myPostCode = address.postCode;
    }
  }

  getResidenceAddress(address) {
    if (address) {
      this.myUnitNumber = address.unitNumber;
      this.myStreetNumber = address.streetNumber;
      this.myStreetName = address.streetName;
      this.myStreetType = address.streetType;
      this.mySuburb = address.suburb;
      this.myState = address.state;
      this.myPostCode = address.postCode;
    }
  }

  getEmployerAddress(address) {
    if (address) {
      this.myUnitNumber = address.unitNumber;
      this.myStreetNumber = address.streetNumber;
      this.myStreetName = address.streetName;
      this.myStreetType = address.streetType;
      this.mySuburb = address.suburb;
      this.myState = address.state;
      this.myPostCode = address.postCode;
    }
  }

  isEmployerAdressValidEvent(v) {}

  getEmployerEmail(e) {
    this.myEmail = e;
  }

  validateEmployerEmailEvent(v) {}

  validateEmployerPhoneEvent(v) {}
  getEmployerPhone(e) {
    this.myPhoneNumber = e;
  }
}
