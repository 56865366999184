<div>
  <div class="card-header primary-gradient-img clearfix inModal">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear mat-button white" matDialogClose>
        <mat-icon>clear</mat-icon>
      </button>
      <!-- merchant calculated name -->
      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m">
          Edit {{ "KEYWORD.treatment" | translate | titlecase }} Item
          <span class="subLabel"> Add/Edit {{ "KEYWORD.treatment" | translate | titlecase }} Item </span>
        </h2>
      </div>
    </div>
    <div class="action-button">
      <button class="actionMenuToggle" [matMenuTriggerFor]="btnPatMenu" mat-raised-button>
        Actions <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #btnPatMenu="matMenu">
        <!-- <button mat-menu-item color="accent" (click)="openCustomerEdit()">
                  <mat-icon>person</mat-icon> View/Edit Profile
                </button> -->
      </mat-menu>
    </div>
  </div>

  <div class="grey-row">
    <p class="lead price">$1000.00</p>
    <hr/>
    <div class="row clearfix flex info">
      <label
      >Category
        <strong>
          ...
          <!-- {{priceService['ServiceCategory']}} -->
        </strong>
      </label>

      <label
      >Type:
        <strong>
          ...
          <!-- {{priceService['ServiceType']}} -->
        </strong>
      </label>

      <label
      >Brand:
        <strong>
          ...
          <!-- {{priceService['Brand']}} -->
        </strong>
      </label>
    </div>
  </div>
  <!--{{ "KEYWORD.treatment" | translate | titlecase }} Category -->

  <form #priceServiceForm="ngForm">
    <div class="row clearfix">
      <div class="icons-col">
        <mat-icon>info_outline</mat-icon>
      </div>
      <div class="row-content-col row clearfix flex diag">
        <!-- diagnosis description -->
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Description of diagnosis</mat-label>

          <textarea
            class="desc-txt"
            matInput
            name="diagnosisDescription"
            placeholder="Description of diagnosis"
            required
          ></textarea>
        </mat-form-field>
        <!--{{ "KEYWORD.treatment" | translate }} description -->
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Description of {{"KEYWORD.treatment" | translate}}</mat-label>

          <textarea
            class="desc-txt"
            matInput
            name="treatmentDescription"
            required
          ></textarea>
        </mat-form-field>
      </div>
    </div>
    <hr class="mb"/>
    <div class="row clearfix">
      <div class="half-width">
        <div class="icons-col">
          <mat-icon>description</mat-icon>
        </div>
        <div class="row-content-col row clearfix">
          <label>Documents</label>
          <!-- DISPLAY ANY DEFAULT DOCS -->
          <!-- <app-file-list class="text-center centered" (remove)="getSimpleTreatmentDocuments()" [files]="simpleTreatmentDocuments" [type]="'treatmentSimple'" [invitationID]="invitationID"
            [canEdit]="true">
          </app-file-list> -->
        </div>
      </div>
      <div class="half-width">
        <div class="icons-col">
          <mat-icon>local_atm</mat-icon>
        </div>
        <div class="row-content-col row clearfix">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label> {{"KEYWORD.Treatment" | translate}} Value</mat-label>

            <!-- <input type='number' name="treat_value" placeholder="Treatment Value" [(ngModel)]="priceService['Price.Effective.Cost']"
              matInput class="mat-block" currencyMask required> -->
            <input
              class="mat-block"
              type="number"
              name="treat_value"
              [placeholder]="('KEYWORD.treatment' | translate | titlecase) + ' Value'"
              matInput
              currencyMask
              required
            />
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>{{"KEYWORD.Practice" | translate}} Cost</mat-label>
            <!-- <input type='number' name="treat_value" placeholder="Practice Cost" [(ngModel)]="priceService['Price.Effective.Sale']" matInput
              class="mat-block" currencyMask required> -->
            <input
              class="mat-block"
              type="number"
              name="treat_value"
              [placeholder]="('KEYWORD.practice' | translate | titlecase) + ' Cost'"
              matInput
              currencyMask
              required
            />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row clearfix">
      <div class="column">
        <button class="pull-right" [disabled]="!priceServiceForm.form.valid" mat-raised-button color="accent">
          Save
        </button>
      </div>
    </div>
  </form>
</div>
