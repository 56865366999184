import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { CustomerProspectService } from '../../customer-prospect/shared/customerProspect.service';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { ContactUsService } from '../shared/contact-us.service';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.css'],
})
export class UnsubscribeComponent implements OnInit {
  isUnsubscribed = false;

  @Input()
  patientID;

  @Input()
  merchantID;

  merchant;
  patient;
  sessionType = 'guest';

  constructor(
    private activeRoute: ActivatedRoute,
    private customerProspectService: CustomerProspectService,
    private merchantService: MerchantService,
    private authenticationService: AuthenticationService,
    private helperService: ContactUsService
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['patientID']) {
        this.patientID = params['patientID'];
      }
      if (params['merchantID']) {
        this.merchantID = params['merchantID'];
      }

      this.authenticationService.getSessionType().subscribe((sessionType) => {
        if (sessionType) {
          this.sessionType = sessionType;
        }

        AuthenticationService.setDrift.emit('unsubscribeDrift');
        if (this.patientID) {
          const patientPayload = {
            fields: 'CalculatedName,FirstName,emails.Email',
          };

          this.customerProspectService
            .getCustomerProspectDetails(this.patientID, patientPayload, this.sessionType)
            .subscribe((res) => {
              if (res) {
                this.patient = res;

                if (this.merchantID) {
                  const merchantPayload = {
                    fields:
                      'TradingAs,Facebook,Instagram,Twitter,LinkedIn,YouTube,Pinterest,Tumblr,Vimeo,URL,ThirdPartyBooking_URL',
                  };
                  this.merchantService
                    .getMerchantDetails(this.merchantID, merchantPayload, this.sessionType)
                    .subscribe((res) => {
                      if (res) {
                        this.merchant = res;

                        const p = {
                          email: this.patient['emails.Email'],
                          merchantID: this.merchantID,
                        };

                        this.helperService.checkUnsubscribe(p).subscribe((res) => {
                          if (res == true) {
                            this.isUnsubscribed = true;
                          } else {
                            this.isUnsubscribed = false;
                          }
                        });
                      }
                    });
                }
              }
            });
        }
      });
    });
  }

  openSocialLink(link) {
    // check if link has http or https and add it if not
    let url = '';
    if (!/^http[s]?:\/\//.test(link)) {
      url += 'https://';
    }
    url += link;
    window.open(url, '_blank', 'noopener');
  }

  unsubscribe() {
    const payload = {
      contact: this.patient['emails.Email'],
      merchantID: this.merchantID,
    };

    this.helperService.unsubscribe(payload).subscribe((res) => {
      if (res && res.Result && res.Result == 'Succeed') {
        NotifyAppComponent.displayToast('success', 'Unsubscribe', 'You have successfully unsubscribe.');
        this.isUnsubscribed = true;
      }
    });
  }

  subscribe() {
    const payload = {
      contact: this.patient['emails.Email'],
      merchantID: this.merchantID,
    };

    this.helperService.subscribe(payload).subscribe((res) => {
      if (res && res.Result && res.Result == 'Succeed') {
        NotifyAppComponent.displayToast('success', 'subscribe', 'You have successfully subscribe.');
        this.isUnsubscribed = false;
      }
    });
  }
}
