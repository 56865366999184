import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { DrugsService } from '../shared/drugs.service';

import { animate, state, style, transition, trigger } from '@angular/animations';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogMessageComponent } from '../../../shared/components/confirm-dialog-message/confirm-dialog-message.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { DrugLookupComponent } from '../drug-lookup/drug-lookup.component';
import { DrugsViewComponent } from '../drugs-view/drugs-view.component';

@Component({
  selector: 'app-medication-create-customer',
  templateUrl: './medication-create-customer.component.html',
  styleUrls: ['./medication-create-customer.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class MedicationCreateCustomerComponent implements OnInit {
  @Input()
  medicationID;

  @Input()
  illnessID;

  @Input()
  allergyID;

  @Input()
  isCreate = false;
  @Output()
  close = new EventEmitter();
  @Output()
  getMedication = new EventEmitter();
  @Output()
  getLocalMedication = new EventEmitter();
  drugName;

  drugs;
  @Input()
  drugID;

  @Input()
  showStopDate = true;

  @Input()
  showStartDate = true;

  drug = {
    drugID: null,
    name: null,
    drugType: null,
    doseUnit: null,
    doseType: null,
    drugFrequency: null,

    drugTypeOther: null,
    doseUnitOther: null,
    doseTypeOther: null,
    drugFrequencyOther: null,
    note: null,
    doseAmount: null,
    startDate: null,
    stopDate: null,
    moreDetails: false,
  };

  startDate;
  stopDate;

  drugTypes = [];
  doseUnits = [];
  doseTypes = [];
  doseFrequencies = [];

  sessionType = 'Merchant';

  title = 'Add your medication';
  description = 'Please insert your medication details';

  constructor(
    private drugsService: DrugsService,
    private authenticationService: AuthenticationService,
    private lookupService: LookupService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.title) {
        this.title = data.title;
      }

      if (data.description) {
        this.description = data.description;
      }

      if (data.medicationID) {
        this.medicationID = data.medicationID;
      }

      if (data.illnessID) {
        this.illnessID = data.illnessID;
      }

      if (data.drugID) {
        this.drugID = data.drugID;
      }

      if (data.allergyID) {
        this.allergyID = data.allergyID;
      }

      if (data.showStopDate != null) {
        this.showStopDate = data.showStopDate;
      }

      if (data.showStartDate != null) {
        this.showStartDate = data.showStartDate;
      }

      if (data.isCreate != null) {
        this.isCreate = data.isCreate;
      }

      if (data.startDate && data.startDate != null && data.startDate != '0000-00-00') {
        this.startDate = data.startDate;
      }

      if (data.stopDate && data.stopDate != null && data.stopDate != '0000-00-00') {
        this.stopDate = data.stopDate;
      }

      if (data.drugLocal && data.drugLocal.name) {
        this.drug = data.drugLocal;

        if (this.drug.stopDate && this.drug.stopDate != null && this.drug.stopDate != '0000-00-00') {
          this.stopDate = this.drug.stopDate;
        }

        if (this.drug.startDate && this.drug.startDate != null && this.drug.startDate != '0000-00-00') {
          this.startDate = this.drug.startDate;
        }
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((st) => {
      this.sessionType = st;

      this.setup();
    });
  }

  setup() {
    this.lookupService.getLookup('CodeLookup', 'DrugType,DoseUnit,DoseType,MedicationFrequency').subscribe((res) => {
      if (res) {
        if (res['DrugType']) {
          this.drugTypes = res['DrugType'];
        }

        if (res['DoseUnit']) {
          this.doseUnits = res['DoseUnit'];
        }
        if (res['DoseType']) {
          this.doseTypes = res['DoseType'];
        }
        if (res['MedicationFrequency']) {
          this.doseFrequencies = res['MedicationFrequency'];
        }

        if (this.medicationID) {
          this.drugsService.getMedicationDetails(this.medicationID, {}, this.sessionType).subscribe((d) => {
            if (d) {
              this.buildMedication(d);
            }
          });
        }
        if (this.drugID) {
          this.drugsService.getDrugDetails(this.drugID, {}).subscribe((drug) => {
            if (drug) {
              this.drugID = drug.ID;
              this.drug.drugID = drug.ID;
              this.buildDrug(drug);
            }
          });
        }
      }
    });
  }

  searchDrug(drugName, displayWarning = false, refSource = null) {
    if (drugName) {
      const payload = {
        partLabel: drugName,
        isActive: true,
        fields:
          'Name,Description,DoseUnit.Code,DoseUnit.Label,DrugType.Code,DrugType.Label,DoseType.Code,DoseType.Label,GeneralInformation,ID',
      };
      this.drugsService.getDrugList(payload, this.sessionType).subscribe((res) => {
        if (res) {
          if (res.length > 0) {
            if (refSource) {
              refSource.close();
              refSource.afterClosed().subscribe((r) => {
                const ref = RootAppComponent.dialog.open(DrugLookupComponent, {
                  data: {
                    drugs: res,
                  },

                  width: '800px',
                });

                ref.componentInstance.getDrug.subscribe((drug) => {
                  if (drug) {
                    this.drugName = drugName;
                    this.drugID = drug.ID;
                    this.drug.drugID = drug.ID;
                    this.buildDrug(drug);
                    ref.close();
                    NotifyAppComponent.displayToast('success', 'Successfull operation', 'Medication is selected');
                  }
                });

                ref.componentInstance.close.subscribe((res) => {
                  ref.close();
                });
              });
            } else {
              const ref = RootAppComponent.dialog.open(DrugLookupComponent, {
                data: {
                  drugs: res,
                },

                width: '800px',
              });

              ref.componentInstance.getDrug.subscribe((drug) => {
                if (drug) {
                  this.drugName = drugName;
                  this.drugID = drug.ID;
                  this.drug.drugID = drug.ID;
                  this.buildDrug(drug);
                  ref.close();
                  NotifyAppComponent.displayToast('success', 'Successfull operation', 'Medication is selected');
                }
              });

              ref.componentInstance.close.subscribe((res) => {
                ref.close();
              });
            }
          } else {
            if (displayWarning == true) {
              NotifyAppComponent.displayToast(
                'warning',
                'Sorry!',
                'We could not find any match in our data base, please insert your medication manually '
              );
            } else {
              this.drugName = null;
              this.drugID = null;
              this.drug.drugID = null;
              this.drug.doseType = null;
              this.drug.doseTypeOther = null;
              this.drug.doseUnit = null;
              this.drug.doseUnitOther = null;
              this.drug.drugType = null;
              this.drug.drugTypeOther = null;
            }
          }
        }
      });
    }
  }

  openSearch() {
    const ref = RootAppComponent.dialog.open(ConfirmDialogMessageComponent, {
      data: {
        icon: 'fas fa-pills',
        title: 'Search for your medication',
        yesLabel: 'Search',
        noLabel: 'Close',
        description: 'You can use our search feature to look for a match in our database',
        subTitle: 'Insert name of you medication and click on  search',
        isDateTimeInput: false,
        isDateInput: false,
        isTextArea: false,
        isTextInput: true,
        isPolicy: false,
        isCheckbox: false,
        displayNo: true,
        isAmountInput: false,
        isAmountInputRequired: false,
        textLabel: 'Medication name',
        isTextInputRequired: true,
        text: this.drugName,
      },
      width: '565px',
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res && res.confirm == true && res.text) {
        this.searchDrug(res.text, true, ref);
      } else {
        ref.close();
      }
    });
  }

  buildDrug(drug) {
    if (drug && drug['ID']) {
      this.drug.drugID = drug['ID'];

      if (drug['Name']) {
        this.drug.name = drug['Name'];
      }

      if (drug['DrugType.Code']) {
        const index = this.drugTypes.findIndex((e) => {
          if (e && e['Code'] && e['Code'] == drug['DrugType.Code']) {
            return true;
          }
          return false;
        });

        if (index != -1) {
          this.drug.drugType = drug['DrugType.Code'];
          this.drug.drugTypeOther = drug['DrugType.Code'];
        } else {
          this.drug.drugType = 'other';
          this.drug.drugTypeOther = drug['DrugType.Code'];
        }
      }

      if (drug['DoseType.Code']) {
        const index = this.doseTypes.findIndex((e) => {
          if (e && e['Code'] && e['Code'] == drug['DoseType.Code']) {
            return true;
          }
          return false;
        });

        if (index != -1) {
          this.drug.doseType = drug['DoseType.Code'];
          this.drug.doseTypeOther = drug['DoseType.Code'];
        } else {
          this.drug.doseType = 'other';
          this.drug.doseTypeOther = drug['DoseType.Code'];
        }
      }

      if (drug['DoseUnit.Code']) {
        const index = this.doseUnits.findIndex((e) => {
          if (e && e['Code'] && e['Code'] == drug['DoseUnit.Code']) {
            return true;
          }
          return false;
        });

        if (index != -1) {
          this.drug.doseUnit = drug['DoseUnit.Code'];
          this.drug.doseUnitOther = drug['DoseUnit.Code'];
        } else {
          this.drug.doseUnit = 'other';
          this.drug.doseUnitOther = drug['DoseUnit.Code'];
        }
      }
    }
  }

  buildMedication(m) {
    if (m) {
      if (m['IllnessOrAllergy'] === 'I' && m['IllnessOrAllergy_key']) {
        this.illnessID = m['IllnessOrAllergy_key'];
      } else if (m['IllnessOrAllergy'] === 'A' && m['IllnessOrAllergy_key']) {
        this.allergyID = m['IllnessOrAllergy_key'];
      }

      if (m['Drug.Type']) {
        const index = this.drugTypes.findIndex((e) => {
          if (e && e['Code'] && e['Code'] == m['Drug.Type']) {
            return true;
          }
          return false;
        });

        if (index != -1) {
          this.drug.drugType = m['Drug.Type'];
          this.drug.drugTypeOther = m['Drug.Type'];
        } else {
          this.drug.drugType = 'other';
          this.drug.drugTypeOther = m['Drug.Type'];
        }
      }

      if (m['Dosage.Type']) {
        const index = this.doseTypes.findIndex((e) => {
          if (e && e['Code'] && e['Code'] == m['Dosage.Type']) {
            return true;
          }
          return false;
        });

        if (index != -1) {
          this.drug.doseType = m['Dosage.Type'];
          this.drug.doseTypeOther = m['Dosage.Type'];
        } else {
          this.drug.doseType = 'other';
          this.drug.doseTypeOther = m['Dosage.Type'];
        }
      }

      if (m['Dosage.Unit']) {
        const index = this.doseUnits.findIndex((e) => {
          if (e && e['Code'] && e['Code'] == m['Dosage.Unit']) {
            return true;
          }
          return false;
        });

        if (index != -1) {
          this.drug.doseUnit = m['Dosage.Unit'];
          this.drug.doseUnitOther = m['Dosage.Unit'];
        } else {
          this.drug.doseUnit = 'other';
          this.drug.doseUnitOther = m['Dosage.Unit'];
        }
      }

      if (m['DosageFrequency.Code']) {
        this.drug.drugFrequency = m['DosageFrequency.Code'];
      }

      if (m['Dosage.Amount']) {
        this.drug.doseAmount = m['Dosage.Amount'];
      }
      if (m['Note']) {
        this.drug.note = m['Note'];
      }
      if (m['Drug_key']) {
        this.drug.drugID = m['Drug_key'];
        this.drugID = m['Drug_key'];
      }

      if (m['startDate'] && m['startDate'] != '0000-00-00') {
        this.drug.startDate = m['startDate'];
        this.startDate = m['startDate'];
      }

      if (m['stopDate'] && m['stopDate'] != '0000-00-00') {
        this.drug.stopDate = m['stopDate'];
        this.stopDate = m['stopDate'];
      }

      if (m['Drug.Name']) {
        this.drug.name = m['Drug.Name'];
      }
    }
  }

  clearDrug() {
    this.drug = {
      drugID: null,
      name: null,
      drugType: null,
      doseUnit: null,
      doseType: null,
      drugFrequency: null,

      drugTypeOther: null,
      doseUnitOther: null,
      doseTypeOther: null,
      drugFrequencyOther: null,
      note: null,
      doseAmount: null,
      startDate: null,
      stopDate: null,

      moreDetails: false,
    };

    this.startDate = null;
    this.stopDate = null;
  }

  viewDrug(id) {
    const ref = RootAppComponent.dialog.open(DrugsViewComponent, {
      data: {
        drugID: id,
      },

      width: '800px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  selectDateToFilter(e, key) {
    this.drug[key] = e;
  }

  closeModal() {
    this.close.emit(true);
  }

  buildPayload(drug) {
    const medication: any = {};
    if (drug) {
      if (drug.drugID) {
        medication.drugID = drug.drugID;
      }

      if (drug.name) {
        medication.name = drug.name;
      }
      if (drug.note) {
        medication.note = drug.note;
      }

      if (drug.doseAmount) {
        medication.doseAmount = drug.doseAmount;
      } else {
        medication.doseAmount = 'void';
      }

      if (drug.startDate) {
        medication.startDate = drug.startDate;
      } else {
        medication.startDate = 'void';
      }

      if (drug.stopDate) {
        medication.stopDate = drug.stopDate;
      } else {
        medication.stopDate = 'void';
      }

      if (drug.drugType) {
        if (drug.drugType == 'other' && drug.drugTypeOther) {
          medication.drugTypeOther = drug.drugTypeOther;
          medication.drugType = 'void';
        } else if (drug.drugType == 'other') {
          medication.drugType = 'void';
        } else {
          medication.drugType = drug.drugType;
        }
      }

      if (drug.doseType) {
        if (drug.doseType == 'other' && drug.doseTypeOther) {
          medication.doseTypeOther = drug.doseTypeOther;
          medication.doseType = 'void';
        } else if (drug.doseType == 'other') {
          medication.doseType = 'void';
        } else {
          medication.doseType = drug.doseType;
        }
      }

      if (drug.doseUnit) {
        if (drug.doseUnit == 'other' && drug.doseUnitOther) {
          medication.doseUnitOther = drug.doseUnitOther;
          medication.doseUnit = 'void';
        } else if (drug.doseUnit == 'other') {
          medication.doseUnit = 'void';
        } else {
          medication.doseUnit = drug.doseUnit;
        }
      }

      if (drug.drugFrequency) {
        if (drug.drugFrequency == 'other' && drug.drugFrequencyOther) {
          medication.drugFrequencyOther = drug.drugFrequencyOther;
          medication.drugFrequency = 'void';
        } else if (drug.drugFrequency == 'other') {
          medication.drugFrequency = 'void';
        } else {
          medication.drugFrequency = drug.drugFrequency;
        }
      }

      if (this.allergyID) {
        medication.allergyID = this.allergyID;
      } else if (this.illnessID) {
        medication.illnessID = this.illnessID;
      }
    }

    return medication;
  }

  addMedication() {
    if (this.drug) {
      const medication: any = this.buildPayload(this.drug);

      if (this.medicationID) {
        this.drugsService.modifyMedication(this.medicationID, medication, this.sessionType).subscribe((res) => {
          if (res) {
            this.getMedication.emit(res);

            NotifyAppComponent.displayToast('success', 'Successed !', 'The medication is edited ');
          }
        });
      } else if (this.isCreate == true) {
        {
          this.drugsService.createMedication(medication, this.sessionType).subscribe((res) => {
            if (res) {
              this.getMedication.emit(res);

              NotifyAppComponent.displayToast('success', 'Successed !', 'The medication is created ');
            }
          });
        }
      } else {
        this.getLocalMedication.emit(this.drug);
      }
    }
  }
}
