<div class="white-background" *ngIf="product">
  <div class="row full-width primary-gradient-img rel clearfix">
    <!-- Modal dismiss button -->
    <button class="btn-close btn-clear mat-button abs-right" *ngIf="isModal == true" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>

    <div class="text-center">
      <h1 class="sr-title header-title-product sm-h1 rm-mb">{{ product.Label }}</h1>
      <label class="mb header-title-product">
        <p class="rm-m" *ngFor="let d of descriptions">{{ d }}</p>
      </label>
    </div>
  </div>
  <div class="container">
    <mat-dialog-content>
      <div class="row clearfix">
        <div class="row clearfix padded">
          <h1 class="sm-h1 sr-title rm-mb featureTitle">
            <mat-icon>start</mat-icon>
            Essentials
          </h1>
          <div *ngIf="product['TandCs.Essentials']">
            <div [innerHTML]="formatTermsAndConditions2(product['TandCs.Essentials']) | safeHtml"></div>
          </div>
        </div>
        <div class="row clearfix">
          <mat-accordion>
            <mat-expansion-panel *ngIf="product['TandCs.Features']" [expanded]="step === 0" (opened)="setStep(0)">
              <mat-expansion-panel-header>
                <mat-panel-title class="primary-color">
                  <mat-icon>new_releases</mat-icon>
                  Features
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel-content">
                <div>
                  <div [innerHTML]="formatTermsAndConditions2(product['TandCs.Features']) | safeHtml"></div>
                </div>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel *ngIf="product['TandCs.Deposit']" [expanded]="step === 1" (opened)="setStep(1)">
              <mat-expansion-panel-header>
                <mat-panel-title class="primary-color">
                  <mat-icon class="fas fa-donate"></mat-icon>
                  Deposit
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel-content">
                <div>
                  <div [innerHTML]="formatTermsAndConditions2(product['TandCs.Deposit']) | safeHtml"></div>
                </div>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel *ngIf="product['TandCs.Fees']" [expanded]="step === 2" (opened)="setStep(2)">
              <mat-expansion-panel-header>
                <mat-panel-title class="primary-color">
                  <mat-icon>attach_money</mat-icon>
                  Fees
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel-content">
                <div class="feeTable" [innerHTML]="formatTermsAndConditions2(product['TandCs.Fees']) | safeHtml"></div>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel *ngIf="product['TandCs.Rates']" [expanded]="step === 3" (opened)="setStep(3)">
              <mat-expansion-panel-header>
                <mat-panel-title class="primary-color">
                  <mat-icon>assessment</mat-icon>
                  Rates
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel-content">
                <div
                  class="rateTable"
                  [innerHTML]="formatTermsAndConditions2(product['TandCs.Rates']) | safeHtml"
                ></div>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel
              *ngIf="product['TandCs.ThingsToConsider']"
              [expanded]="step === 4"
              (opened)="setStep(4)"
            >
              <mat-expansion-panel-header>
                <mat-panel-title class="primary-color">
                  <mat-icon>error_outline</mat-icon> Things to consider
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel-content">
                <div>
                  <p [innerHTML]="formatTermsAndConditions2(product['TandCs.ThingsToConsider']) | safeHtml"></p>
                </div>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel *ngIf="product['TandCs.Eligibility']" [expanded]="step === 5" (opened)="setStep(5)">
              <mat-expansion-panel-header>
                <mat-panel-title class="primary-color">
                  <mat-icon>supervisor_account</mat-icon> Are you eligible?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel-content">
                <div>
                  <p [innerHTML]="formatTermsAndConditions2(product['TandCs.Eligibility']) | safeHtml"></p>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </mat-dialog-content>
    <!--
  <div class="clearfix text-center mt mb" >
    <button mat-raised-button class="" color="primary"  (click)="closeEvent()">{{"Close" | translate}}</button>
  </div> -->
  </div>
</div>
