import { Component, EventEmitter, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ExistingPatientSummaryComponent } from '../existing-patient-summary/existing-patient-summary.component';

@Component({
  selector: 'app-existing-patient-create-lookup',
  templateUrl: './existing-patient-create-lookup.component.html',
  styleUrls: ['./existing-patient-create-lookup.component.css'],
})
export class ExistingPatientCreateLookupComponent implements OnInit {
  @Input()
  patients;

  filterData = '';
  selectedPatient: any;
  utilsClass = new UtilsClass();

  chooseCreate = new EventEmitter();
  close = new EventEmitter();

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.patients = data;
    }
  }

  ngOnInit() {}

  selectPatient(p) {
    this.selectedPatient = p;
  }

  editSelectedPatient() {
    this.chooseCreate.emit(false);
  }

  createSelectedPatient() {
    this.chooseCreate.emit(true);
  }

  openPatientDialog(id) {
    const ref = RootAppComponent.dialog.open(ExistingPatientSummaryComponent, {
      data: id,
      width: '650px',
      panelClass: 'noCard',
    });
  }
}
