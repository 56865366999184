<div class="full-width white-background clearfix">
  <div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
    <div class="row clearfix padded rel">
      <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
    </div>
  </div>

  <div [hidden]="listDB.data.length == 0">
    <div class="row clearfix">
      <div class="row flex ctl pull-right mr" @ngIfAnimation>
        <button
          class="row select-btn"
          (click)="selectAll()"
          mat-stroked-button
          color=""
          matTooltip="Select All"
        >
          <mat-icon>border_all</mat-icon>
        </button>
        <button
          class="row select-btn"
          (click)="selectPage()"
          mat-stroked-button
          color=""
          matTooltip="Select Page"
        >
          <mat-icon>border_outer</mat-icon>
        </button>
        <button
          class="animated"
          *ngIf="selectedIDs && selectedIDs.length > 0"
          [matMenuTriggerFor]="ActionBtnmenu"
          mat-stroked-button
          matTooltip="Options"
        >
          <mat-icon class="fas fa-cog"></mat-icon>  <span *ngIf="selectedIDs && selectedIDs.length>0"
            class="selected-number-item  accent-background">{{selectedIDs.length}}</span>
        </button>
        <mat-menu #ActionBtnmenu="matMenu">
          <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
            <mat-icon>select_all</mat-icon> Deselect
          </button>

          <button
            *ngFor="let action of appointmentUtil.getOperationsBySessionType(sessionType)"
            (click)="runActionEvent(selectedIDs, action.code, true)"
            mat-menu-item
          >
            <mat-icon>{{ action.icon }}</mat-icon>
            <span>{{ action.label }}</span>
          </button>

          <button (click)="removeAppointment(selectedIDs)" mat-menu-item>
            <mat-icon>remove_circle</mat-icon>
            <span>Remove Appointment</span>
          </button>
        </mat-menu>

        <button
          class="pull-right ml"
          [routerLink]="['/merchant/', { outlets: { page: ['appointment', 'appointment-create-merchant'] } }]"
          [routerLinkActive]="['active']"
          mat-raised-button
          color="primary"
        >
          <mat-icon>alarm_add</mat-icon> New Appointment
        </button>
      </div>

      <h2 class="dataHeader rm-mt mr ml text-left">
        {{ title }}
        <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>

        <p class="small mr">Manage your {{"KEYWORD.practice" | translate}} appointments, click on Row to see more
          details and your available actions</p>
      </h2>
    </div>
    <div class="row clearfix mb searchArea primary-gradient-img">
      <div class="row clearfix flex">
        <div class="full-width flex animated fadeInDown">
          <mat-icon class="mt">search</mat-icon>

          <mat-form-field class="ml full-width" appearance="outline">
            <mat-label>Name, Email, Mobile</mat-label>
            <input
              class="clearfix thrd-width"
              (input)="
                setFilter($event.target.value, [
                  'CustomerOrProspect_CalculatedName',
                  'CustomerOrProspect_Email',
                  'CustomerOrProspect_Mobile',
                  'Merchant_CalculatedName',
                  'MerchantContact_CalculatedName'
                ])
              "
              name="NameEmailMobile"
              placeholder=""
              matInput
            />
          </mat-form-field>
          <mat-form-field class="qtr-width" appearance="outline">
            <mat-label>{{ "KEYWORD.practitioner" | translate | titlecase }}</mat-label>
            <mat-select
              class=""
              (selectionChange)="setFilter($event.value, 'MerchantContact_CalculatedName')"
              name="Dentist"
              [placeholder]="('KEYWORD.practitioner' | translate | titlecase)"
            >
              <mat-option [value]="null">
                All
              </mat-option>

              <mat-option
                *ngFor="
                  let a of dataSource.filteredData
                    | objectPropertyValue: 'MerchantContact_CalculatedName'
                    | unique
                    | orderBy
                "
                [value]="a"
              >
                {{ a }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="qtr-width" appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select
              class=""
              (selectionChange)="setFilter($event.value, 'Status.Label')"
              name="status"
              placeholder="Status"
            >
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option
                *ngFor="let a of dataSource.filteredData | objectPropertyValue: 'Status.Label' | unique | orderBy"
                [value]="a"
              >
                <span>{{ a }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>

          <app-date-input
            class="qtr-width"
            [label]="'Date From'"
            [isClear]="true"
            [defaultDate]="null"
            (selectedDate)="setFilter($event, 'fromDate')"
            name="fromDate"
          ></app-date-input>
          <app-date-input
            class="qtr-width"
            [label]="'Date To'"
            [isClear]="true"
            [defaultDate]="null"
            (selectedDate)="setFilter($event, 'toDate')"
            name="toDate"
          ></app-date-input>
          <app-date-input
            class="qtr-width"
            [label]="'Date Booking From'"
            [isClear]="true"
            [defaultDate]="null"
            (selectedDate)="setFilter($event, 'fromBookingDate')"
            name="fromBookingDate"
          ></app-date-input>
          <app-date-input
            class="qtr-width"
            [label]="'Date Booking To'"
            [isClear]="true"
            [defaultDate]="null"
            (selectedDate)="setFilter($event, 'toBookingDate')"
            name="toBookingDate"
          ></app-date-input>
        </div>
      </div>
    </div>

    <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
      <ng-container cdkColumnDef="selectCheckBox" sticky>
        <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
        <mat-cell *cdkCellDef="let row">
          <div class="margin-btm pull-left">
            <mat-checkbox [checked]="isSelected(row['ID'])" (change)="select(row['ID'], $event.checked)" color="accent">
            </mat-checkbox>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="dateTimeCreated">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Requested</mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row['ID'])">
          <span class="list-label" [style.background]="util.daysPastColor(row['DateTimeCreated'])">
            {{ row.DateTimeCreated | customDate: 'DD/MM/YYYY-hh:mm A' }}</span
          >
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="profilePicture" sticky>
        <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
        <mat-cell class="text-center" *cdkCellDef="let row">
          <app-customer-prospect-view-picture
            class="supplierIconAppointment"
            [patientID]="row['CustomerOrProspect_Key']"
          >
          </app-customer-prospect-view-picture>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="merchantProfilePicture" sticky>
        <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
        <mat-cell class="text-center" *cdkCellDef="let row">
          <app-merchant-view-picture class="supplierIconAppointment" [merchantID]="row['Merchant_Key']">
          </app-merchant-view-picture>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="dentistProfilePicture" sticky>
        <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
        <mat-cell class="text-center" *cdkCellDef="let row">
          <app-dentist-view-picture class="supplierIconAppointment" [dentistID]="row['MerchantContact_key']">
          </app-dentist-view-picture>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="customerName" sticky>
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Name</mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row['ID'])">
          {{ row.CustomerOrProspect_CalculatedName }}
        </mat-cell>
      </ng-container>
      <ng-container cdkColumnDef="customerEmail" sticky>
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Email</mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row['ID'])">
          {{ row.CustomerOrProspect_Email }}
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="customerMobile" sticky>
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Mobile</mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row['ID'])">
          {{ row.CustomerOrProspect_Mobile | customPhone }}
        </mat-cell>
      </ng-container>
      <ng-container cdkColumnDef="source" sticky>
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Source</mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row['ID'])">
          {{ row['Source.Label'] }}
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="merchantName">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Merchant</mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row['ID'])">
          <app-merchant-view-picture
            class="supplierIconAppointment2"
            *ngIf="row['Merchant_CalculatedName']"
            [merchantID]="row['Merchant_key']"
          >
          </app-merchant-view-picture>
          {{ row['Merchant_CalculatedName'] }}
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="dentistName">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header>
          {{ "KEYWORD.practitioner" | translate | titlecase }}
        </mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row['ID'])">
          <app-dentist-view-picture
            class="supplierIconAppointment2"
            *ngIf="row['MerchantContact_CalculatedName'] != 'Unassigned'"
            [dentistID]="row['MerchantContact_key']"
          >
          </app-dentist-view-picture>
          <span
            *ngIf="row['MerchantContact_CalculatedName'] != 'Unassigned'"
            matTooltip="{{ row['MerchantContact_CalculatedName'] }}"
          >{{ row['MerchantContact_CalculatedName'] }}</span
          >
          <span
            class="status-label ml"
            *ngIf="row['MerchantContact_CalculatedName'] == 'Unassigned'"
            [style.background]="'#a0a0a0'"
            matTooltip="Unassigned"
          >Unassigned</span
          >
        </mat-cell>
      </ng-container>

      <ng-container class="statusLabelApp" cdkColumnDef="statusLabelApp">
        <mat-header-cell class="statusLabelApp" *cdkHeaderCellDef mat-sort-header> Status</mat-header-cell>
        <mat-cell
          class="statusLabelApp"
          *cdkCellDef="let row"
          (click)="openQuickViewDialog(row['ID'])"
          matTooltip="{{ row['Status.Label'] }}"
        >
          <span class="status-label" [style.background]="appointmentUtil.getStatusColor(row)"
          >{{ row['Status.Label'] }}

            <span
              *ngIf="
                (row['Status.Code'] === 'BOOKED' && appointmentUtil.isDatePass(row) == true) ||
                (row['Status.Code'] === 'WAITINGCONFIRMATION' && appointmentUtil.isDatePass(row) == true)
              "
            >
              (Passed)
            </span>
          </span>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="appointmentDate">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Appointment Date</mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row['ID'])">
          <span *ngIf="row && row.Date && row.Date != '0000-00-00'">
            {{ row.Date + ' ' + row.Time | customDateTime: 'DD-MM-YYYY hh:mm A' }}</span
          >
          <span *ngIf="row && row.Date && row.Date == '0000-00-00'"> ...</span>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="appointmentDateEnd">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Ends At</mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row['ID'])">
          <span *ngIf="row && row.Date_End && row.Date_End != '0000-00-00'">
            {{ row.Date_End + ' ' + row.Time_End | customDateTime: 'DD-MM-YYYY hh:mm A' }}</span
          >
          <span *ngIf="row && row.Date_End && row.Date_End == '0000-00-00'"> ...</span>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="appointmentType">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Visting for</mat-header-cell>
        <mat-cell
          *cdkCellDef="let row"
          (click)="openQuickViewDialog(row['ID'])"
          matTooltip="  {{ row['Type.Label'] || '...' }}"
        >
          {{ row['Type.Label'] || '...' }}
        </mat-cell>
      </ng-container>
      <ng-container cdkColumnDef="dateTimeUpdated">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Updated at</mat-header-cell>
        <mat-cell
          *cdkCellDef="let row"
          (click)="openQuickViewDialog(row['ID'])"
          matTooltip="{{ row.LastModified_Human | customDateTime }}"
        >
          <span class="list-label" [style.background]="util.daysPastColor(row['LastModified_Human'])">
            {{ row.LastModified_Human | TimeLabelPast }} ago</span
          >
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="active">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Active</mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row['ID'])">
          <span
            class="list-label smaller"
            *ngIf="row['Active'] == '1'"
            matTooltip="Active"
            style="background-color: rgb(90, 186, 71)"
          >
            <mat-icon>check</mat-icon>
          </span>
          <span
            class="list-label smaller"
            *ngIf="row['Active'] != '1'"
            matTooltip="Inactive"
            style="background-color: rgb(239, 71, 39)"
          >
            <mat-icon>close</mat-icon>
          </span>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="Actions">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
        <mat-cell *cdkCellDef="let row">
          <div style="float: right">
            <button [matMenuTriggerFor]="menu" mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <div>
                <button (click)="openQuickViewDialog(row['ID'])" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span>Quick View</span>
                </button>

                <button
                  *ngIf="
                    row['Invitation_key'] &&
                    (isPromoterOrAdmin || sessionType === 'merchant' || sessionType === 'merchant-admin')
                  "
                  (click)="openInvitationViewDialog(row['Invitation_key'])"
                  mat-menu-item
                >
                  <mat-icon>email</mat-icon>
                  <span>View {{ "KEYWORD.treatment" | translate | titlecase }}</span>
                </button>

                <button *ngIf="row['Merchant_key']" (click)="openMerchantViewDialog(row['Merchant_key'])" mat-menu-item>
                  <mat-icon>person</mat-icon>
                  <span>View Merchant</span>
                </button>

                <button
                  *ngIf="row['merchantContact_key']"
                  (click)="openDentistViewDialog(row['merchantContact_key'])"
                  mat-menu-item
                >
                  <mat-icon>account_box</mat-icon>
                  <span> View {{ "KEYWORD.practitioner" | translate | titlecase }}</span>
                </button>

                <button
                  *ngIf="
                    row['CustomerOrProspect_Key'] &&
                    (isPromoterOrAdmin || sessionType === 'merchant' || sessionType === 'merchant-admin')
                  "
                  (click)="gotoCustomerPage(row['CustomerOrProspect_Key'])"
                  mat-menu-item
                >
                  <mat-icon>person</mat-icon>
                  <span>View Customer</span>
                </button>

                <button
                  *ngIf="
                    row['Campaign_key'] &&
                    (isPromoterOrAdmin || sessionType === 'merchant' || sessionType === 'merchant-admin')
                  "
                  (click)="gotoCampaignPage(row['Campaign_key'])"
                  mat-menu-item
                >
                  <mat-icon>insert_chart</mat-icon>
                  <span>View Campaign</span>
                </button>
              </div>

              <mat-divider></mat-divider>

              <div>
                <button *ngIf="isPromoterOrAdmin" (click)="publicPage(row['ID'])" mat-menu-item>
                  <mat-icon>link</mat-icon>
                  <span>Public page</span>
                </button>

                <button
                  *ngFor="let a of appointmentUtil.getOperations(row, sessionType)"
                  (click)="runActionEvent(row, a.code, false)"
                  mat-menu-item
                >
                  <mat-icon>{{ a.icon }}</mat-icon>
                  <span>{{ a.label }}</span>
                </button>

                <button
                  *ngIf="
                    row['MerchantContact_key'] &&
                    (isPromoterOrAdmin || sessionType === 'merchant' || sessionType === 'merchant-admin')
                  "
                  (click)="runActionEvent(row, 'assignDentist', false)"
                  mat-menu-item
                >
                  <mat-icon>person_pin</mat-icon>
                  <span>Assign {{ "KEYWORD.dentist" | translate }}</span>
                </button>

                <button
                  *ngIf="
                    !row['MerchantContact_key'] &&
                    (isPromoterOrAdmin || sessionType === 'merchant' || sessionType === 'merchant-admin')
                  "
                  (click)="runActionEvent(row, 'assignDentist', false)"
                  mat-menu-item
                >
                  <mat-icon>person_pin</mat-icon>
                  <span>Assign {{ "KEYWORD.dentist" | translate }}</span>
                </button>
              </div>

              <mat-divider></mat-divider>

              <div>
                <button
                  *ngIf="
                    isPromoterOrAdmin ||
                    sessionType === 'merchant' ||
                    sessionType === 'merchant-admin' ||
                    (sessionType == 'customer' && row['Status.Code'] === 'REQUESTED')
                  "
                  (click)="removeAppointment(row)"
                  mat-menu-item
                >
                  <mat-icon>remove_circle</mat-icon>
                  <span>Remove Appointment</span>
                </button>
              </div>
            </mat-menu>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      #paginator
      [length]="dataSource.filteredData.length"
      [pageIndex]="0"
      [pageSize]="25"
      [pageSizeOptions]="[5, 10, 25, 100]"
    >
    </mat-paginator>
  </div>

  <div *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
    <app-empty-list-message
      [actionIcon]="'alarm_add'"
      [isAction]="true"
      [actionLabel]="'New Appointment'"
      (actionEvent)="newAppointmentNavigate()"
    ></app-empty-list-message>
  </div>

  <app-appointment-action-hidden [runAction]="runAction" [merchantID]="merchantID" (result)="getActionResult($event)">
  </app-appointment-action-hidden>
</div>
