/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin-general-settings.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../settings/general-settings/general-settings.component.ngfactory";
import * as i3 from "../../../settings/general-settings/general-settings.component";
import * as i4 from "@angular/router";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../authentication/shared/authentication.service";
import * as i7 from "../../../settings/shared/settings.service";
import * as i8 from "@angular/platform-browser";
import * as i9 from "@angular/material/dialog";
import * as i10 from "@angular/common";
import * as i11 from "./admin-general-settings.component";
var styles_AdminGeneralSettingsComponent = [i0.styles];
var RenderType_AdminGeneralSettingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminGeneralSettingsComponent, data: {} });
export { RenderType_AdminGeneralSettingsComponent as RenderType_AdminGeneralSettingsComponent };
function View_AdminGeneralSettingsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-general-settings", [], null, null, null, i2.View_GeneralSettingsComponent_0, i2.RenderType_GeneralSettingsComponent)), i1.ɵdid(1, 114688, null, 0, i3.GeneralSettingsComponent, [i4.Router, i4.ActivatedRoute, i5.TranslateService, i6.AuthenticationService, i7.SettingsService, i8.DomSanitizer, i9.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AdminGeneralSettingsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminGeneralSettingsComponent_1)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isAdmin == true); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AdminGeneralSettingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "admin-general-settings", [], null, null, null, View_AdminGeneralSettingsComponent_0, RenderType_AdminGeneralSettingsComponent)), i1.ɵdid(1, 114688, null, 0, i11.AdminGeneralSettingsComponent, [i6.AuthenticationService, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminGeneralSettingsComponentNgFactory = i1.ɵccf("admin-general-settings", i11.AdminGeneralSettingsComponent, View_AdminGeneralSettingsComponent_Host_0, {}, {}, []);
export { AdminGeneralSettingsComponentNgFactory as AdminGeneralSettingsComponentNgFactory };
