<div class="card-header primary-gradient-img clearfix inModal">
  <div class="row clearfix">
    <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModal()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="row titleArea clearfix hasIcon">
      <mat-icon class="white pull-left fas fa-file-medical"></mat-icon>
      <h2 class="summary-header rm-m">Add New Document</h2>
    </div>
  </div>
</div>

<mat-dialog-content class="doc-create-edit">
  <form #createTreatmentDoc="ngForm">
    <div class="row clearfix mt">
      <div class="icons-col">
        <mat-icon class="fas fa-pen-alt"></mat-icon>
      </div>
      <div class="row-content-col row clearfix">
        <h3 class="sr-title rm-mt">Document Group</h3>

        <div class="row clearfix flex">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Document group</mat-label>
            <mat-select
              class="full-width"
              [(ngModel)]="group"
              (change)="changeGroup()"
              name="docGroup"
              placeholder="Document group"
              required
            >
              <mat-option *ngFor="let dg of documentGroups" [value]="dg.TypeCode">
                {{ dg.Label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row clearfix mt" *ngIf="cards && cards.length > 0">
      <div class="icons-col">
        <mat-icon class="fas fa-pen-alt"></mat-icon>
      </div>
      <div class="row-content-col row clearfix">
        <h3 class="sr-title rm-mt">
          {{ cardTitle }}
        </h3>

        <div class="row clearfix flex">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Name</mat-label>
            <mat-select
              class="full-width"
              [(ngModel)]="cardID"
              name="cardOwner"
              placeholder="Name"
              required
            >
              <mat-option *ngFor="let c of cards" [value]="c.ID">
                {{ c.CalculatedName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row clearfix mt">
      <div class="icons-col">
        <mat-icon class="fas fa-pen-alt"></mat-icon>
      </div>
      <div class="row-content-col row clearfix">
        <h3 class="sr-title rm-mt">Document Settings</h3>
        <div class="row clearfix">
          <mat-form-field class="full-width primary-color" appearance="outline">
            <mat-label>Enter name of document</mat-label>
            <input
              class="mat-block"
              [(ngModel)]="label"
              name="documentName"
              placeholder="Enter name of document"
              matInput
              required
            />
          </mat-form-field>
        </div>

        <div class="row clearfix">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Description</mat-label>
            <textarea
              class="dentMsg increase-input-field"
              [(ngModel)]="description"
              name="dentMsg"
              matInput
              placeholder="Description"
            ></textarea>
          </mat-form-field>
        </div>
        <div class="row clearfix">
          <mat-form-field class="full-width primary-color" appearance="outline">
            <mat-label>Enter some tags to define this document</mat-label>
            <input
              class="mat-block"
              [(ngModel)]="tags"
              name="documentTags"
              placeholder="Enter some tags to define this document"
              matInput
            />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row clearfix">
      <div class="icons-col">
        <mat-icon class="fas fa-pen-alt"></mat-icon>
      </div>
      <div class="row-content-col row clearfix">
        <h3 class="sr-title rm-mt">Document Link/Upload</h3>
        <div class="row clearfix">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Document Type</mat-label>
            <mat-select
              class="full-width"
              [(ngModel)]="type"
              name="docType"
              placeholder="Document Type"
              required
            >
              <mat-option *ngFor="let dt of documentTypes" [value]="dt.code">
                {{ dt.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- video link -->
        <div class="row clearfix mt mb" *ngIf="type == 'video'">
          <mat-form-field class="full-width primary-color" appearance="outline">
            <mat-label>Description</mat-label>
            <input
              class="mat-block"
              [(ngModel)]="url"
              name="documentVideoLonk"
              placeholder="Enter Youtube/Vimeo URL"
              matInput
              required
            />
          </mat-form-field>
        </div>
        <!-- external link -->
        <div class="row clearfix mt mb" *ngIf="type == 'url'">
          <mat-form-field class="full-width primary-color" appearance="outline">
            <mat-label>Enter external page URL</mat-label>
            <input
              class="mat-block"
              [(ngModel)]="url"
              name="documentPageLonk"
              placeholder="Enter external page URL"
              matInput
              required
            />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row clearfix" *ngIf="type == 'fileID'">
      <app-file-uploader
        class="text-center"
        [isDragable]="true"
        [limit]="1"
        (onCompleteAll)="onCompleteAllFile($event)"
        (isUploaded)="checkUploadCompleted($event)"
      ></app-file-uploader>

      <app-file-list
        [hasExtraInfo]="false"
        [files]="fileID"
        [canEdit]="true"
        [canAction]="false"
        (remove)="removeNewTreatmentDocument($event)"
      >
      </app-file-list>
    </div>

    <div class="row clearfix mt" *ngIf="type">
      <div class="icons-col">
        <mat-icon class="fas fa-image"></mat-icon>
      </div>
      <div class="row-content-col row clearfix">
        <h3 class="sr-title rm-mt">Customized Thumbnail</h3>
        <p class="small mb">If you leave this field empty, the default image will be used as a thumbnail.</p>
      </div>
    </div>
    <div class="row clearfix" *ngIf="type">
      <app-file-uploader
        class="text-center"
        [isFixedAspectRatio]="true"
        [isDragable]="true"
        [limit]="1"
        (onCompleteAll)="onCompleteAllFileThumbnail($event)"
      ></app-file-uploader>

      <app-file-list
        [hasExtraInfo]="false"
        [files]="thumbnailFileID"
        [canEdit]="true"
        [canAction]="false"
        (remove)="removeDocumentThumbnail($event)"
      >
      </app-file-list>
    </div>

    <div class="button-row row text-center clearfix mt">
      <button
        class="text-center"
        [disabled]="!createTreatmentDoc.form.valid || (isUploadCompleted == false && type == 'Document')"
        (click)="createNewDoc()"
        mat-raised-button
        color="primary"
      >
        Save
      </button>
    </div>
  </form>
</mat-dialog-content>
