<div class="row clearfix pContainer">
  <!-- blue heading -->
  <div class="card-header primary-gradient-img clearfix" *ngIf="isModal == true">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear pull-right mat-button" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <!-- merchant calculated name -->
      <div class="row titleArea clearfix">
        <mat-icon class="far fa-calendar-check pull-left"></mat-icon>
        <h2 class="rm-m">
          <span>Appointment Settings</span>
        </h2>
      </div>
    </div>
  </div>
  <!-- end blue heading -->

  <h4 class="sm-h4 sr-title" *ngIf="isModal == false">Appointment Settings</h4>

  <div>
    <form #aForm="ngForm">
      <!--  mat dialog scrolling area -->
      <mat-dialog-content>
        <!-- Future scheduling -->
        <div class="row clearfix">
          <h3 class="sr-title sm-h3 rm-m mt">Future scheduling</h3>
          <p class="clearfix small">Choose how far into the future appointments can be scheduled</p>
          <mat-radio-group [(ngModel)]="appointmentLimitToDaysRadio" name="appointmentLimitToDaysRadio" required>
            <mat-radio-button [value]="'1'">Indefinitely into the future</mat-radio-button>
            <mat-radio-button [value]="'2'"
            >Limit to {{ appointmentLimitToDaysInput || '-' }} days into the future
            </mat-radio-button>
          </mat-radio-group>
          <mat-form-field class="full-width mt" *ngIf="appointmentLimitToDaysRadio === '2'" appearance="outline">
            <mat-label>Days into the future.</mat-label>
            <input
              class="mat-block"
              [(ngModel)]="appointmentLimitToDaysInput"
              name="appointmentLimitToDaysInput"
              type="number"
              matInput
              required
            />
          </mat-form-field>
        </div>

        <!-- Start times -->
        <div class="row clearfix">
          <h3 class="sr-title sm-h3 rm-m mt">Start times</h3>
          <div class="full-width">
            <p class="pull-left small mr">Show available start times in increments of :</p>

            <mat-form-field class="pull-left ml" appearance="outline">
              <mat-label>Time step</mat-label>
              <mat-select
                class="full-width"
                [(ngModel)]="appointmentTimeStep"
                name="appointmentTimeStep"
                placeholder="Time step"
                required
              >
                <mat-option *ngFor="let startTime of startTimes" [value]="startTime"> {{ startTime }} mins</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <!-- Buffers -->
        <div class="row clearfix mb">
          <h3 class="sr-title sm-h3 rm-m">Buffers</h3>
          <p class="clearfix small">
            Allocate time before or after the appointment, you can use to prepare for the
            current/next {{"KEYWORD.patient" | translate}}.
          </p>

          <div class="full-width flex">
            <mat-form-field class="full-width mr" appearance="outline">
              <mat-label>Buffers Before Appointment</mat-label>
              <mat-select
                class="full-width"
                [(ngModel)]="defaultBuffersPre"
                name="Buffers_Pre"
                placeholder="Buffers Before Appointment"
                required
              >
                <mat-option *ngFor="let p of buffers" [value]="p.code">
                  {{ p.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Buffers_Post -->
            <mat-form-field class="full-width ml" appearance="outline">
              <mat-label>Buffers After Appointment</mat-label>
              <mat-select
                class="full-width"
                [(ngModel)]="defaultBuffersPost"
                name="Buffers_Post"
                placeholder="Buffers After Appointment"
                required
              >
                <mat-option *ngFor="let p of buffers" [value]="p.code">
                  {{ p.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <!-- Appointment availability list -->
      </mat-dialog-content>
      <!-- fixed action buttons -->
      <mat-dialog-actions>
        <div class="row clearfix text-center">
          <button class="btn-large" [disabled]="!aForm.form.valid" (click)="onSave()" mat-raised-button color="accent">
            Save
          </button>
        </div>
      </mat-dialog-actions>
    </form>
  </div>
</div>
