<div class="row clearfix cardheader primary-gradient-img inModal" *ngIf="isModal == true">
  <!-- close button -->
  <button class="btn-close btn-clear white pull-right mat-button" (click)="closeEvent()" mat-dialog-close>
    <mat-icon>clear</mat-icon>
  </button>
  <div class="row titleArea clearfix">
    <h2 class="summary-header rm-m white">
      {{ title }}
      <span class="subLabel">{{ description }}</span>
    </h2>
  </div>
</div>

<div class="row clearfix">
  <div class="pull-left" *ngIf="showLabels == true">
    <label class="uploaded" *ngIf="_documents && _documents.length > 0 && isModal != true && title">{{ title }}</label>
    <p class="detail-p" *ngIf="description && isModal != true && isPreviewModal != true">
      {{ description }}
    </p>
  </div>
  <div class="pull-right" *ngIf="isButtonFilter == true">
    <mat-button-toggle-group [(ngModel)]="filter" (change)="filterEvent()" name="buttonFilter">
      <mat-button-toggle [value]=""> All</mat-button-toggle>
      <mat-button-toggle [value]="'pdf'"> Documents</mat-button-toggle>
      <mat-button-toggle [value]="'video/webm'"> Videos</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>

<mat-form-field class="full-width clearfix" *ngIf="sideFilter == false && isFilter == true" appearance="outline">
  <mat-label>Filter by: Label, Description, ID, Extension, URL, or Tags</mat-label>
  <input class="mat-block" [(ngModel)]="filter" (input)="filterEvent()" matInput name="filter"
    placeholder="Filter by: Label, Description, ID, Extension, URL, or Tags" />
</mat-form-field>

<ng-container *ngIf="listViewType == 'default'">
  <!--  default list styling -->
  <mat-dialog-content class="include-buttons isModal default-list-type-style" *ngIf="isModal == true">
    <div class="flex clearfix full-width" *ngIf="_documents && _documents.length > 0" [ngClass]="{
        uploadedContainer: !isPreviewModal,
        uploadedContainerModal: isPreviewModal
      }" [class.justify-left]="justify == 'left'">
      <div class="full-width" *ngFor="let document of _documents; let i = index">
        <div class="animated fadeInDown file-container file-container-default"
          *ngIf="document && isRemoved(document.ID) != true"
          [ngClass]="{ 'low-opacity': canCheck == true && document.checked != true }">
          <div class="inputs" [ngClass]="{ 'low-opacity': canCheck == true && document.checked != true }">
            <div class="row clearfix">
              <!-- document information -->
              <div class="pull-left thr-qtr-width">
                <p class="detail-p full-width" *ngIf="document.Label">
                  <strong>{{ document.Label }} </strong>
                  <span class="chip" *ngIf="document.required == true">Required</span>
                </p>

                <p class="detail-p date-container full-width" *ngIf="document.DateTimeCreated"
                  matTooltip="{{ document.DateTimeCreated | customDateTime }}">

                  {{ document.DateTimeCreated | TimeLabelPast }} ago
                </p>
                <p class="detail-p full-width" *ngIf="isVerified(document)">
                  <span class="chip smt approved"> Verified </span>
                </p>

                <p class="detail-p full-width" *ngIf="isRejected(document)">
                  <span class="chip smt declined"> Rejected </span>
                </p>


                <!-- <p class="detail-p full-width" *ngIf="document['Card.Name'] && isAdminOrPromoter==true ">
                  <mat-icon class="fas fa-user-alt"></mat-icon>
                  {{document['Card.Name'] }}
                </p> -->
              </div>

              <!-- control buttons -->
              <div class="buttons pull-right text-right">
                <button class="fullsize-thumb-click" *ngIf="canRemove || canEdit" [matMenuTriggerFor]="menu"
                  mat-mini-fab>
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button (click)="viewDocument(i)" mat-menu-item>
                    <mat-icon>search</mat-icon>
                    <span>View</span>
                  </button>

                  <button *ngIf="
                      canEdit &&
                      (isAdminOrPromoter == true || (currentPracticeID && currentPracticeID == document.Merchant_key))
                    " (click)="editDocument(document)" mat-menu-item>
                    <mat-icon>edit</mat-icon>
                    <span>Edit</span>
                  </button>

                  <button *ngIf="canRemove" (click)="removeFile(document)" mat-menu-item>
                    <mat-icon>delete</mat-icon>
                    <span>{{ removeLabel }}</span>
                  </button>
                </mat-menu>

                <div class="row clearfix" *ngIf="!canRemove && !canEdit && displayViewButton == true">
                  <button class="delete-button" (click)="viewDocument(i)" mat-button color="primary"
                    matTooltip="View document">
                    View <mat-icon>visibility</mat-icon>
                  </button>
                </div>

                <div class="row clearfix">
                  <button class="btn-small" *ngIf="canAction" (click)="sendFile(document)" mat-raised-button
                    type="submit" color="accent">
                    {{ actionLabel }}
                  </button>
                </div>
                <div class="row clearfix" *ngIf="canCheck == true">
                  <mat-slide-toggle class="text-center" *ngIf="document.required != true" [(ngModel)]="document.checked"
                    (change)="sendCheckedFile($event, document)" labelPosition="before" matTooltip="{{ checkLabel }}">
                    Include
                  </mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
          <!-- display file icon -->
          <div class="img view-img" [ngClass]="{ 'low-opacity': canCheck == true && document.checked != true }">
            <app-ep-document-thumbnail-view class="ep-list-document-image-thumbnails" [document]="document"
              [notChecked]="!document.checked" [hideZoomButton]="true" (getClick)="viewDocument(i)">
            </app-ep-document-thumbnail-view>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="isModal == true || canAction == true">
    <div class="clearfix full-width text-center">
      <button class="mr" *ngIf="isModal == true" (click)="closeEvent()" mat-raised-button color="primary">Close</button>

      <button class="" *ngIf="canActionGlobal == true" (click)="actionEventGlobal()" mat-raised-button color="accent">
        {{ actionLabelGlobal }}
      </button>
    </div>
  </mat-dialog-actions>

  <div class="row clearfix">
    <div class="flex clearfix full-width" *ngIf="_documents && _documents.length > 0 && isModal != true" [ngClass]="{
        uploadedContainer: !isPreviewModal,
        uploadedContainerModal: isPreviewModal
      }" [class.justify-left]="justify == 'left'">
      <div class="full-width" *ngFor="let document of _documents; let i = index">
        <div class="animated fadeInDown file-container file-container-default"
          *ngIf="document && isRemoved(document.ID) != true"
          [ngClass]="{ 'low-opacity': canCheck == true && document.checked != true }">
          <div class="inputs" [ngClass]="{ 'low-opacity': canCheck == true && document.checked != true }">
            {{ filter }}
            <div class="row clearfix">
              <!-- document information -->
              <div class="pull-left thr-qtr-width">
                <p class="detail-p full-width" *ngIf="document.Label">
                  <strong>{{ document.Label }} </strong>
                  <span class="chip" *ngIf="document.required == true">Required</span>
                </p>

                <p class="detail-p date-container full-width" *ngIf="document.DateTimeCreated"
                  matTooltip="{{ document.DateTimeCreated | customDateTime }}">

                  {{ document.DateTimeCreated | TimeLabelPast }} ago
                </p>

                <p class="detail-p full-width" *ngIf="isVerified(document)">
                  <span class="chip smt approved"> Verified </span>
                </p>

                <p class="detail-p full-width" *ngIf="isRejected(document)">
                  <span class="chip smt declined"> Rejected </span>
                </p>

                <!-- <p class="detail-p full-width" *ngIf="document['Card.Name'] && isAdminOrPromoter==true ">
                  <mat-icon class="fas fa-user-alt"></mat-icon>
                  {{document['Card.Name'] }}
                </p> -->
              </div>

              <!-- control buttons -->

              <div class="buttons pull-right text-right">
                <button *ngIf="canRemove || canEdit" [matMenuTriggerFor]="menu" mat-icon-button>
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button (click)="viewDocument(i)" mat-menu-item>
                    <mat-icon>search</mat-icon>
                    <span>View</span>
                  </button>

                  <button *ngIf="
                      canEdit &&
                      (isAdminOrPromoter == true || (currentPracticeID && currentPracticeID == document.Merchant_key))
                    " (click)="editDocument(document)" mat-menu-item>
                    <mat-icon>edit</mat-icon>
                    <span>Edit</span>
                  </button>

                  <button *ngIf="canRemove" (click)="removeFile(document)" mat-menu-item>
                    <mat-icon>delete</mat-icon>
                    <span>{{ removeLabel }}</span>
                  </button>
                </mat-menu>

                <div class="row clearfix" *ngIf="!canRemove && !canEdit && displayViewButton == true">
                  <button class="delete-button" (click)="viewDocument(i)" mat-button color="primary"
                    matTooltip="View document">
                    View <mat-icon>visibility</mat-icon>
                  </button>
                </div>

                <div class="row clearfix">
                  <button class="btn-small" *ngIf="canAction" (click)="sendFile(document)" mat-raised-button
                    type="submit" color="accent">
                    {{ actionLabel }}
                  </button>
                </div>
                <div class="row clearfix" *ngIf="canCheck == true">
                  <mat-slide-toggle class="text-center" *ngIf="document.required != true" [(ngModel)]="document.checked"
                    (change)="sendCheckedFile($event, document)" labelPosition="before" matTooltip="{{ checkLabel }}">
                    Include
                  </mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
          <!-- display file icon -->
          <div class="img view-img" [ngClass]="{ 'low-opacity': canCheck == true && document.checked != true }">
            <app-ep-document-thumbnail-view class="ep-list-document-image-thumbnails" [document]="document"
              [notChecked]="!document.checked" [hideZoomButton]="true" (getClick)="viewDocument(i)">
            </app-ep-document-thumbnail-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- document thumbnail view styling -->
<ng-container *ngIf="listViewType == 'thumbnails'">
  <!-- add class for fullsize checkbox -->
  <div class="row clearfix thumb-view document-thumbnail-view"
    *ngIf="_documents && _documents.length > 0 && isModal != true" [class.fs-checkbox]="fullsizeClick == true"
    [class.fs-filter]="sideFilter == true">
    <ng-container *ngIf="sideFilter == false">
      <p class="small text-center" *ngIf="isFilter == true">{{ filter }}</p>
    </ng-container>

    <div class="flex clearfix full-width" [ngClass]="{
        uploadedContainer: !isPreviewModal,
        uploadedContainerModal: isPreviewModal
      }" [class.justify-left]="justify == 'left'">
      <ng-container *ngFor="let document of _documents; let i = index">
        <mat-card class="card-ep-list-container" *ngIf="document && isRemoved(document.ID) != true" @simpleFadeAnimation
          [ngClass]="{ 'low-opacity': canCheck == true && document.checked != true }">
          <!-- show if fullsize checkbox -->
          <ng-container *ngIf="fullsizeClick == true">
            <div class="rel thumb-image" mat-card-image>
              <app-ep-document-thumbnail-view class="ep-list-document-image" [document]="document"
                [notChecked]="!document.checked" [hideZoomButton]="true" [hasIcon]="true">
              </app-ep-document-thumbnail-view>
            </div>

            <button class="fullsize-thumb-click" *ngIf="onlyView == true" (click)="viewDocument(i)" mat-mini-fab
              color="accent" matTooltip="Preview image">
              <mat-icon>search</mat-icon>
            </button>

            <button class="fullsize-thumb-click" *ngIf="onlyView != true && (canAction || canRemove || canEdit)"
              [matMenuTriggerFor]="menu" mat-mini-fab>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button (click)="viewDocument(i)" mat-menu-item>
                <mat-icon>search</mat-icon>
                <span>View</span>
              </button>

              <button *ngIf="
                  canEdit &&
                  (isAdminOrPromoter == true || (currentPracticeID && currentPracticeID == document.Merchant_key))
                " (click)="editDocument(document)" mat-menu-item>
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>

              <button *ngIf="canRemove" (click)="removeFile(document)" mat-menu-item>
                <mat-icon>delete</mat-icon>
                <span>{{ removeLabel }}</span>
              </button>

              <button *ngIf="canAction" (click)="sendFile(document)" mat-menu-item>
                <mat-icon>chevron_right</mat-icon>
                <span>{{ actionLabel }}</span>
              </button>
            </mat-menu>
          </ng-container>

          <!-- show if normal checkbox -->
          <div class="rel thumb-image" *ngIf="fullsizeClick == false"
            [ngClass]="{ 'low-opacity': canCheck == true && document.checked != true }" mat-card-image>
            <app-ep-document-thumbnail-view class="ep-list-document-image" [document]="document"
              [notChecked]="!document.checked" [hideZoomButton]="true" [hasIcon]="true" (getClick)="viewDocument(i)">
            </app-ep-document-thumbnail-view>
          </div>

          <div class="action-fab-buttons" *ngIf="fullsizeClick != true && (canAction || canRemove || canEdit)">
            <button class="action" [matMenuTriggerFor]="menu" mat-mini-fab>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button (click)="viewDocument(i)" mat-menu-item>
                <mat-icon>search</mat-icon>
                <span>View</span>
              </button>

              <button *ngIf="
                  canEdit &&
                  (isAdminOrPromoter == true || (currentPracticeID && currentPracticeID == document.Merchant_key))
                " (click)="editDocument(document)" mat-menu-item>
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>

              <button *ngIf="canRemove" (click)="removeFile(document)" mat-menu-item>
                <mat-icon>delete</mat-icon>
                <span>{{ removeLabel }}</span>
              </button>

              <button *ngIf="canAction" (click)="sendFile(document)" mat-menu-item>
                <mat-icon>chevron_right</mat-icon>
                <span>{{ actionLabel }}</span>
              </button>
            </mat-menu>
          </div>

          <div class="row clearfix text-center document-label-container mt">
            <!-- document information -->
            <div class="row clearfix" [ngClass]="{ 'low-opacity': canCheck == true && document.checked != true }">
              <p class="detail-p full-width" *ngIf="document.Label">
                <strong>{{ document.Label }} </strong>
                <span class="chip" *ngIf="document.required == true">Required</span>
              </p>

              <p class="small date-container full-width rm-mt" *ngIf="document.DateTimeCreated"
                matTooltip="{{ document.DateTimeCreated | customDateTime }}">
                <mat-icon class="fas fa-clock"></mat-icon>
                {{ document.DateTimeCreated | TimeLabelPast }} ago
              </p>
              <!-- <p class="small full-width rm-mt" *ngIf="document['Card.Name'] && isAdminOrPromoter==true ">
                <mat-icon class="fas fa-user-alt"></mat-icon>
                {{document['Card.Name'] }}
              </p> -->
            </div>

            <!-- control buttons -->
            <div class="row clearfix">
              <!-- <div class="row clearfix">
              <button mat-button color="primary" matTooltip="View document" class="delete-button"
                (click)="viewDocument(i)">
                View <mat-icon>visibility</mat-icon>
              </button>
            </div> -->

              <div class="row clearfix" *ngIf="canCheck == true">
                <mat-checkbox class="text-center" *ngIf="document.required != true" [(ngModel)]="document.checked"
                  (change)="sendCheckedFile($event, document)" labelPosition="before" matTooltip="{{ checkLabel }}">
                  <ng-container *ngIf="fullsizeClick == false">Include</ng-container>
                </mat-checkbox>
              </div>
            </div>
          </div>
        </mat-card>
      </ng-container>
    </div>
  </div>

  <!--  default list styling -->
  <mat-dialog-content class="include-buttons isModal default-list-style" *ngIf="isModal == true">
    <div class="flex clearfix full-width" *ngIf="_documents && _documents.length > 0" [ngClass]="{
        uploadedContainer: !isPreviewModal,
        uploadedContainerModal: isPreviewModal
      }" [class.justify-left]="justify == 'left'">
      <p class="small text-center">{{ filter }}</p>
      <div class="row clearfix thumb-view full-width">
        <div class="flex clearfix full-width" [ngClass]="{
            uploadedContainer: !isPreviewModal,
            uploadedContainerModal: isPreviewModal
          }" [class.justify-left]="justify == 'left'">
          <ng-container *ngFor="let document of _documents; let i = index">
            <mat-card class="" *ngIf="document && isRemoved(document.ID) != true"
              [ngClass]="{ 'low-opacity': canCheck == true && document.checked != true }">
              <div class="rel thumb-image" [ngClass]="{ 'low-opacity': canCheck == true && document.checked != true }"
                mat-card-image matTooltip="View {{ document.Label }}">
                <!-- <app-ep-document-thumbnail-view [document]="document" [notChecked]="!document.checked" [hideZoomButton]="true"
                (getClick)="viewDocument(i)">
              </app-ep-document-thumbnail-view> -->
                <app-ep-document-thumbnail-view class="ep-list-document-image" [document]="document"
                  [hideZoomButton]="true" [hasIcon]="true" (getClick)="viewDocument(i)">
                </app-ep-document-thumbnail-view>
              </div>
              <div class="row clearfix text-center">
                <!-- document information -->
                <div class="row clearfix" [ngClass]="{ 'low-opacity': canCheck == true && document.checked != true }">
                  <p class="detail-p full-width rm-mt" *ngIf="document.Label">
                    <strong>{{ document.Label }} </strong>
                    <span class="chip" *ngIf="document.required == true">Required</span>
                  </p>

                  <p class="small date-container full-width rm-mt" *ngIf="document.DateTimeCreated"
                    matTooltip="{{ document.DateTimeCreated | customDateTime }}">
                    <mat-icon class="fas fa-clock"></mat-icon>
                    {{ document.DateTimeCreated | TimeLabelPast }} ago
                  </p>

                  <!-- <p class="small full-width rm-mt" *ngIf="document['Card.Name'] && isAdminOrPromoter==true ">
                    <mat-icon class="fas fa-user-alt"></mat-icon>
                    {{document['Card.Name'] }}
                  </p> -->
                </div>

                <!-- control buttons -->
                <div class="row clearfix">
                  <!-- <div class="row clearfix">
                  <button mat-button color="primary" matTooltip="View document" class="delete-button"
                    (click)="viewDocument(i)">
                    View <mat-icon>visibility</mat-icon>
                  </button>
                </div> -->
                  <div class="row clearfix">
                    <button class="btn-small" *ngIf="canAction" (click)="sendFile(document)" mat-raised-button
                      type="submit" color="accent">
                      {{ actionLabel }}
                    </button>
                  </div>
                  <div class="row clearfix" *ngIf="canCheck == true">
                    <mat-checkbox class="text-center" *ngIf="document.required != true" [(ngModel)]="document.checked"
                      (change)="sendCheckedFile($event, document)" labelPosition="before" matTooltip="{{ checkLabel }}">
                      Include
                    </mat-checkbox>
                  </div>

                  <div class="row clearfix" *ngIf="canRemove == true">
                    <button class="delete-button" (click)="removeFile(document)" mat-button matTooltip="Remove file"
                      color="accent">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </mat-card>
          </ng-container>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="isModal == true || canAction == true">
    <div class="clearfix full-width text-center">
      <button class="mr" *ngIf="isModal == true" (click)="closeEvent()" mat-raised-button color="primary">Close</button>

      <button class="" *ngIf="canActionGlobal == true" (click)="actionEventGlobal()" mat-raised-button color="accent">
        {{ actionLabelGlobal }}
      </button>
    </div>
  </mat-dialog-actions>
</ng-container>
