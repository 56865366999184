import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { SessionsService } from '../../../core/session/shared/sessions.service';

@Component({
  selector: 'app-merchant-update-website-modal',
  templateUrl: './merchant-update-website-modal.component.html',
  styleUrls: ['./merchant-update-website-modal.component.css'],
})
export class MerchantUpdateWebsiteComponent implements OnInit {
  @Input()
  merchantID;

  fileID;

  close = new EventEmitter();

  onConfirm = new EventEmitter();

  isModal = true;
  type = 'standard';

  constructor(
    private sessionService: SessionsService,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.type = data.type;

      this.isModal = true;
    }
  }

  ngOnInit() {}

  nowConfirm() {
    this.onConfirm.emit(true);
  }

  // code packs
  mediaFinanceLink(): void {
    this.nowConfirm();
    if (isPlatformBrowser(this.platformId)) {
      window.open('https://s3-ap-southeast-2.amazonaws.com/email-graphics-smileright/embeds/smile-right-finance.zip');
    }
  }

  mediaFullLink(): void {
    this.nowConfirm();
    if (isPlatformBrowser(this.platformId)) {
      window.open(
        'https://s3-ap-southeast-2.amazonaws.com/email-graphics-smileright/embeds/smile-right-full-suite.zip'
      );
    }
  }

  mediaMemberLink(): void {
    this.nowConfirm();
    if (isPlatformBrowser(this.platformId)) {
      window.open(
        'https://s3-ap-southeast-2.amazonaws.com/email-graphics-smileright/docs-17/smile-right-membership.zip'
      );
    }
  }

  // code packs
  previewFinanceLink(): void {
    this.nowConfirm();
    if (isPlatformBrowser(this.platformId)) {
      window.open('https://email-graphics-smileright.s3-ap-southeast-2.amazonaws.com/embeds/index-finance.html');
    }
  }

  previewFullLink(): void {
    this.nowConfirm();
    if (isPlatformBrowser(this.platformId)) {
      window.open('https://email-graphics-smileright.s3-ap-southeast-2.amazonaws.com/embeds/index-full-suite.html');
    }
  }

  previewMemberLink(): void {
    this.nowConfirm();
    if (isPlatformBrowser(this.platformId)) {
      window.open('https://email-graphics-smileright.s3-ap-southeast-2.amazonaws.com/embeds/index-membership.html');
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
}
