<div class="masonry" *ngIf="contract">
  <div class="row clearfix full-width">
    <!-- change tabs to containers -->
    <ng-container *ngIf="setContractTab == 'repayment'">
      <div class="row clearfix flex blocks">
        <mat-card class="full-width white primary-gradient-img">
          <div class="row clearfix text-center">
            <mat-icon class="fas fa-hand-holding-usd op07"></mat-icon>
            <label class="">Repayment Amount</label>
            <h3 class="reduce-marg sr-title">{{ contract['Amount.PerPayment'] | customCurrency }}</h3>
            <label class=""
            >To be paid <strong>{{ contract['RepaymentFrequency.Adverb'] }}</strong></label
            >
          </div>
        </mat-card>

        <mat-card class="full-width">
          <div class="colour-stripe"></div>
          <div class="row clearfix text-center">
            <mat-icon class="fas fa-donate"></mat-icon>
            <label class="">Number of installments</label>
            <h3 class="reduce-marg sr-title">{{ contract['LoanInstalments'] }}</h3>
            <label class=""
            >First on <strong>{{ contract['LoanStartDate'] | customDate }}</strong></label
            >
          </div>
        </mat-card>

        <mat-card class="full-width">
          <div class="colour-stripe"></div>

          <button class="abPos" [matMenuTriggerFor]="menu" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button (click)="showBankList()" mat-menu-item>
              <mat-icon>view_list</mat-icon>
              <span>View All</span>
            </button>
            <button (click)="createPaymentMethod()" mat-menu-item>
              <mat-icon>add_circle_outline</mat-icon>
              <span>Add Payment Method</span>
            </button>
            <button (click)="modifyDefaultPaymentMethod()" mat-menu-item>
              <mat-icon>edit</mat-icon>
              <span>Select Default</span>
            </button>
          </mat-menu>

          <div class="row clearfix text-center">
            <mat-icon class="fas fa-credit-card" *ngIf="bankDetails['AccountType.Code'] == 'CC'"></mat-icon>
            <mat-icon class="fas fa-university" *ngIf="bankDetails['AccountType.Code'] != 'CC'"></mat-icon>
            <label class="">Default Payment Method</label>
            <h3 class="reduce-marg sr-title" *ngIf="bankDetails['AccountType.Code'] == 'CC'">Credit Card</h3>
            <h3 class="reduce-marg sr-title" *ngIf="bankDetails['AccountType.Code'] != 'CC'">Bank Account</h3>
          </div>

          <div class="row clearfix flex text-center" *ngIf="bankDetails['AccountType.Code'] != 'CC'">
            <label class=""
            >{{ bankDetails['BankName'] }} account
              <strong>ending in {{ bankDetails['AccountNumber.Obscured'] }}</strong></label
            >
          </div>

          <div class="row clearfix text-center flex" *ngIf="bankDetails['AccountType.Code'] == 'CC'">
            <label class=""
            >{{ bankDetails['Card.Type.Label'] }} card
              <strong>ending in {{ bankDetails['Card.Number.Obscured'] }} </strong></label
            >
          </div>
        </mat-card>
      </div>

      <div class="item row clearfix flex"></div>

      <div class="item row clearfix">
        <mat-card class="cleafix">
          <div class="colour-stripe"></div>

          <div class="row clearfix full-width">
            <div class="row flex flex-center">
              <h3 class="sr-title sm-h3 rm-m pull-left">Payments</h3>
              <div class="row clearfix pull-right" *ngIf="totalObject">
                <mat-checkbox
                  class="checkboxPadding"
                  [(ngModel)]="isOutstandingOnly"
                  [labelPosition]="end"
                  (click)="clickOutstanding()"
                >Outstanding Only
                </mat-checkbox>
                <mat-checkbox
                  class="checkboxPadding"
                  [(ngModel)]="isConsolidated"
                  [labelPosition]="end"
                  (click)="clickConsolidated()"
                >Consolidated
                </mat-checkbox>
                <mat-checkbox
                  class="checkboxPadding"
                  [(ngModel)]="isPending"
                  [labelPosition]="end"
                  (click)="clickPending()"
                >Pending Only
                </mat-checkbox>

                <button class="ml" (click)="openAddPaymentDialog()" mat-raised-button color="accent">
                  <mat-icon>add</mat-icon>
                  Add Payment
                </button>
              </div>
            </div>
            <hr/>
            <div class="row cleaerfix flex mt" *ngIf="totalObject">
              <div class="row clearfix full-width text-center">
                <label class="small">Total Amount</label>
                <p class="rm-mb">
                  <strong>{{ totalObject['amountTotal'] | customCurrency }}</strong>
                </p>
              </div>
              <div class="row clearfix full-width text-center">
                <label class="small">Total Due Amount</label>
                <p class="rm-mb">
                  <strong>{{ totalObject['dueAmountTotal'] | customCurrency }}</strong>
                </p>
              </div>
              <div class="row clearfix full-width text-center">
                <label class="small">Total Paid Directly</label>
                <p class="rm-mb">
                  <strong>{{ totalObject['paidDirectlyTotal'] | customCurrency }}</strong>
                </p>
              </div>
              <div class="row clearfix full-width text-center">
                <label class="small">Total Waived Amount</label>
                <p class="rm-mb">
                  <strong>{{ totalObject['waivedTotal'] | customCurrency }}</strong>
                </p>
              </div>
              <div class="row clearfix full-width text-center">
                <label class="small">Total Insurance Amount</label>
                <p class="rm-mb">
                  <strong>{{ totalObject['insuranceTotal'] | customCurrency }}</strong>
                </p>
              </div>
              <div class="row clearfix full-width text-center">
                <label class="small">Total Rescheduled Amount</label>
                <p class="rm-mb">
                  <strong>{{ totalObject['rescheduledTotal'] | customCurrency }}</strong>
                </p>
              </div>
            </div>
          </div>
        </mat-card>
      </div>

      <div class="row clearfix">
        <app-payment-list
          [contractID]="contract['ID']"
          [customerID]="contract['Customer_key']"
          [isOutstandingOnly]="isOutstandingOnly"
          [isConsolidated]="isConsolidated"
          [isPending]="isPending"
          (totalObject)="getTotal($event)"
        ></app-payment-list>
      </div>
    </ng-container>

    <!-- change tabs to containers -->
    <ng-container *ngIf="setContractTab == 'invoice'">
      <div class="item full-width">
        <mat-card class="cleafix">
          <div class="colour-stripe"></div>
          <mat-card-header>
            <mat-card-title>
              <h3 class="sr-title sm-h3 rm-m">Drawn-Down Details</h3>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <app-settlement-list-global [contractID]="contract['ID']"></app-settlement-list-global>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="item full-width">
        <mat-card class="cleafix">
          <div class="colour-stripe"></div>
          <mat-card-header>
            <mat-card-title>
              <h3 class="sr-title sm-h3 rm-m">
                Payment To {{ "KEYWORD.practitioner" | translate | titlecase }} and/or Beneficiaries
              </h3>
            </mat-card-title>
          </mat-card-header>
        </mat-card>
      </div>
    </ng-container>
  </div>
</div>
