import { Injectable } from '@angular/core';

import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable, of as observableOf, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class PersonalCircumstancesService {
  private settingsUrl: string = environment.nodeUrl + '/version';
  private bankList: any;
  private creditCardTypes: any;

  constructor(private http: HttpClient) {}

  getBankList(payload = {}): Observable<any> {
    if (this.bankList != null) {
      return observableOf(this.bankList);
    } else {
      let params: HttpParams = new HttpParams();
      let options = {};

      if (payload != null) {
        for (let key in payload) {
          if (payload.hasOwnProperty(key)) {
            params = params.set(key, payload[key]);
          }
        }

        options = {
          params,
        };
      }

      return this.http.get(environment.nodeUrl + '/bank', options).pipe(
        map((res: HttpResponse<any>) => {
          this.bankList = res['data'];

          return this.bankList;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
    }
  }

  getCreditCardTypes(payload = {}): Observable<any> {
    if (this.creditCardTypes != null) {
      return observableOf(this.creditCardTypes);
    } else {
      let params: HttpParams = new HttpParams();
      let options = {};

      if (payload != null) {
        for (let key in payload) {
          if (payload.hasOwnProperty(key)) {
            params = params.set(key, payload[key]);
          }
        }

        options = {
          params,
        };
      }

      return this.http.get(environment.nodeUrl + '/creditCardType', options).pipe(
        map((res: HttpResponse<any>) => {
          this.creditCardTypes = res['data'];

          return this.creditCardTypes;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
    }
  }

  getContractPossibleStatus(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload != null) {
      for (let key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(environment.nodeUrl + '/contract-status-change-possible', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editPCIEmploymentBenefit(ID, payload) {
    return this.http
      .put(environment.nodeUrl + '/pci-employment-and-benefit/' + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createPCIEmploymentBenefit(payload) {
    return this.http
      .post(environment.nodeUrl + '/pci-employment-and-benefit', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editPCICars(ID, payload) {
    return this.http
      .put(environment.nodeUrl + '/pci-car/' + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createPCICars(payload) {
    return this.http.post(environment.nodeUrl + '/pci-car', payload).pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editPCICreditCard(ID, payload) {
    return this.http
      .put(environment.nodeUrl + '/pci-creditCard/' + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createPCICreditCard(payload) {
    return this.http
      .post(environment.nodeUrl + '/pci-creditCard', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editPCIFurnitureHousehold(ID, payload) {
    return this.http
      .put(environment.nodeUrl + '/pci-furniture-household-goods/' + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createPCIFurnitureHousehold(payload) {
    return this.http
      .post(environment.nodeUrl + '/pci-furniture-household-goods', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editPCIHouseResidence(ID, payload) {
    return this.http
      .put(environment.nodeUrl + '/pci-house-of-residence/' + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createPCIHouseResidence(payload) {
    return this.http
      .post(environment.nodeUrl + '/pci-house-of-residence', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editPCIInterestIncome(ID, payload) {
    return this.http
      .put(environment.nodeUrl + '/pci-interest-as-income/' + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createPCIInterestIncome(payload) {
    return this.http
      .post(environment.nodeUrl + '/pci-interest-as-income', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editPCIInvestmentLoans(ID, payload) {
    return this.http
      .put(environment.nodeUrl + '/pci-investment-and-loans/' + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createPCIInvestmentLoans(payload) {
    return this.http
      .post(environment.nodeUrl + '/pci-investment-and-loans', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editPCIInvestmentProperty(ID, payload) {
    return this.http
      .put(environment.nodeUrl + '/pci-investment-property/' + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createPCIInvestmentProperty(payload) {
    return this.http
      .post(environment.nodeUrl + '/pci-investment-property', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editPCILivingExpense(ID, payload) {
    return this.http
      .put(environment.nodeUrl + '/pci-living-expenses/' + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createPCILivingExpense(payload) {
    return this.http
      .post(environment.nodeUrl + '/pci-living-expenses', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editPCIOtherAsset(ID, payload) {
    return this.http
      .put(environment.nodeUrl + '/pci-other-assets/' + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createPCIOtherAsset(payload) {
    return this.http
      .post(environment.nodeUrl + '/pci-other-assets', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editPCIOtherIncome(ID, payload) {
    return this.http
      .put(environment.nodeUrl + '/pci-other-income/' + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createPCIOtherIncome(payload) {
    return this.http
      .post(environment.nodeUrl + '/pci-other-income', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editPCIOtherVehicle(ID, payload) {
    return this.http
      .put(environment.nodeUrl + '/pci-other-vehicle/' + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createPCIOtherVehicle(payload) {
    return this.http
      .post(environment.nodeUrl + '/pci-other-vehicle', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editPCIPersonalLoan(ID, payload) {
    return this.http
      .put(environment.nodeUrl + '/pci-personal-loan/' + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createPCIPersonalLoan(payload) {
    return this.http
      .post(environment.nodeUrl + '/pci-personal-loan', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editPCIShares(ID, payload) {
    return this.http
      .put(environment.nodeUrl + '/pci-shares/' + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createPCIShares(payload) {
    return this.http
      .post(environment.nodeUrl + '/pci-shares', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editPCITaxDebt(ID, payload) {
    return this.http
      .put(environment.nodeUrl + '/pci-tax-and-debt/' + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createPCITaxDebt(payload) {
    return this.http
      .post(environment.nodeUrl + '/pci-tax-and-debt', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPCIDocument(ID, payload = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (let key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/personal-circumstances/document/' + ID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  uploadPCIDocument(ID, payload) {
    return this.http
      .put(`${environment.nodeUrl}/personal-circumstances/document/` + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }
}
