import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { ActivatedRoute, Router } from '@angular/router';

import { Observable, of, throwError, timer } from 'rxjs';
import { combineLatest, Subscription } from 'rxjs';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { labelAnimation } from '../../../shared/animations/label.animation';
import { onChangeAnimation } from '../../../shared/animations/onChange.animation';
import { ConfirmDialogSingleButtonComponent } from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { ChartColor } from '../../../shared/types/chart-color.type';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { EventLogListComponent } from '../../action-log/event-log-list/event-log-list.component';
import { CustomerViewComponent } from '../../customer/customer-view/customer-view.component';
import { InvitationViewModalComponent } from '../../invitation/invitation-view-modal/invitation-view-modal.component';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { ProductViewModalComponent } from '../../product/product-view-modal/product-view-modal.component';
import { SettlementService } from '../../settlement/shared/settlement.service';
import { Settlement, SettlementQuery } from '../../settlement/shared/types/settlement.type';
import { ContractService } from '../shared/contract.service';
import { Contract } from '../shared/types/contract.type';
import { Settings } from '../../../shared/types/settings';

export class ContractViewModalConfig {
  contractID: string;
  isSimple: boolean;
}

@Component({
  selector: 'app-contract-view',
  templateUrl: './contract-view.component.html',
  styleUrls: ['./contract-view.component.css'],
  providers: [CustomPhonePipe],
  animations: [labelAnimation, onChangeAnimation],
})
export class ContractViewComponent implements OnDestroy {
  private subscriptions = new Subscription();

  @Output()
  closeModal = new EventEmitter();

  @Output()
  goBack = new EventEmitter();

  @Input()
  isModal = false;
  @Input()
  newSettlementButton = true;

  @Input()
  isIntegrated = false;

  @Input()
  isSimple = false;
  @Input()
  hideCapacity = false;
  @Input()
  hideHeader = false;

  @Input()
  hideMainBody = false;
  @Input()
  chartOnly = false;
  @Input()
  isMiniSettlementExpanded = false;
  @Input()
  displayNoSettlement = false;

  @Input()
  contractID;

  promoter;
  settings = Settings.global;
  settlementNotFound = false;
  isParameter = false;
  isPromoterOrAdmin = false;
  @Input()
  isShowFull = false;
  allowContractStatus = false;

  availableData;

  approveStatus = ['APPR'];
  declineStatus = ['DECL', 'WDRN'];
  requestStatus = ['PEND', 'REQU'];

  items = [
    'contract',
    'settlement',
    'invitation',
    'customer',
    'dentist',
    'merchant',
    'instantOffer',
    'appointment',
    'campaign',
    'landingPage',
    'note',
  ];

  customSummaryChartColor = CUSTOM_SUMMARY_CHART_COLOR;

  @Input()
  contract;

  contract$ = combineLatest([this.activatedRoute.params, this.authenticationService.isPromoterOrAdmin()]).pipe(
    mergeMap(([params, isPromoterOrAdmin]) => {
      const contractIDParameter = params['contractID'];

      this.isParameter = !!contractIDParameter; // double bang checks if parameter has truthy or falsy value.

      this.contractID = this.isParameter ? contractIDParameter : this.contractID;

      this.isPromoterOrAdmin = isPromoterOrAdmin;

      return combineLatest([this.initializeContract$(), this.initializeSettlements$()]);
    }),

    switchMap(() => {
      return this.contractService.getContract$();
    })
  );

  settlements$ = this.settlementService.getSettlements$().pipe(
    tap((settlements) => {
      if (settlements) {
        this.setSummaryChartData(settlements);
      }
    })
  );

  statsData;
  summaryChartData;

  constructor(
    private location: Location,
    private dialog: MatDialog,
    private contractService: ContractService,
    private settlementService: SettlementService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    private data: ContractViewModalConfig,
    private customPhonePipe: CustomPhonePipe,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    if (data) {
      if (data.contractID) {
        this.contractID = data.contractID;
        this.isModal = true;
      }

      this.isSimple = data.isSimple || null;
    }
  }

  initializeContract$() {
    const payload: any = { selectStats: true, selectMaterialRequest: true, selectSettlements: true };
    let c = null;

    if (this.contract && this.contract.ID) {
      this.contractID = this.contract.ID;
      c = this.contract;
    }

    return this.contractService.fetchContract$(this.contractID, payload, c).pipe(
      tap((contract) => {
        this.contractService.setContract(contract);

        if (this.isPromoterOrAdmin === true) {
          if (
            contract['Status.Code'] === 'ACTIVE' ||
            contract['Status.Code'] === 'COMPLETE' ||
            contract['Status.Code'] === 'ARRGMNT' ||
            contract['Status.Code'] === 'RECOVERY'
          ) {
            this.allowContractStatus = true;
          }
        } else {
          if (
            contract['Status.Code'] === 'ACTIVE' ||
            contract['Status.Code'] === 'COMPLETE' ||
            contract['Status.Code'] === 'ARRGMNT'
          ) {
            this.allowContractStatus = true;
          }
        }

        this.contract = contract;

        this.statsData = contract.stats;

        this.availableData = Number(contract['Settlements.NotYetDrawn']);
      })
    );
  }

  settlementListOverview(contractID, status) {
    if (contractID && status) {
      AuthenticationService.settlementList.emit({ contractID: contractID, statusCodes: [status],pageSize:100 });
    } else {
      AuthenticationService.settlementList.emit({ contractID: contractID ,pageSize:100});
    }
  }

  initializeSettlements$() {
    const query: SettlementQuery = { payload: { contractID: this.contractID } };

    return this.settlementService.fetchSettlements$(query.payload).pipe(
      tap((settlements) => {
        this.settlementService.setSettlements(settlements);
      })
    );
  }

  setSummaryChartData(settlements: Settlement[] | Partial<Settlement>[]) {
    const approvedData = this.statsData.filter((elem) => elem['ID'] === 'APPR')[0];

    const supplierSettlements = settlements.filter((settlement) => settlement['SettleTo.TableName'] === 'Supplier');

    let settledToPractice = approvedData['Sum(Settlement.Amount)'] - approvedData['Sum(Insurance.Contribution)'];
    let settledToSupplier = 0;

    if (supplierSettlements.length > 0 && supplierSettlements.some((elem) => elem['Status.Code'] === 'APPR') === true) {
      settledToSupplier = parseInt(
        supplierSettlements
          .filter((elem) => elem['Status.Code'] === 'APPR')
          .map((x) => x['Settlement.Amount'])
          .reduce((a, b) => Number(a) + Number(b).toString()),
        10
      );

      settledToPractice = settledToPractice - settledToSupplier;
    }

    const settledToInsurance = approvedData['Sum(Insurance.Contribution)'];

    const availableBalance = this.availableData;

    this.summaryChartData = [
      {
        name: 'Settled to Practice',
        value: settledToPractice || 0,
      },
      {
        name: 'Settled to Insurance',
        value: settledToInsurance || 0,
      },
      {
        name: 'Settled to Supplier',
        value: settledToSupplier || 0,
      },
      {
        name: 'Available Balance',
        value: availableBalance || 0,
      },
    ];

    if (
      this.contract &&
      this.contract['Amount.Invoice'] &&
      availableBalance == 0 &&
      settledToSupplier == 0 &&
      settledToInsurance == 0 &&
      settledToPractice == 0
    ) {
      this.summaryChartData = [
        {
          name: 'Original Loan',
          value: Number(this.contract['Amount.Invoice']) || 0,
        },
      ];
    }
  }

  showFullDetails() {
    this.isShowFull = !this.isShowFull;
  }

  openCustomerDetailsDialog(contract: Contract) {
    const customerViewModal = this.dialog.open(CustomerViewComponent, {
      data: contract.Customer_key,
      width: '600px',
      panelClass: 'noCard',
    });

    customerViewModal.componentInstance.closeModal.subscribe((res) => {
      customerViewModal.close();
    });
  }

  closeEvent() {
    this.closeModal.emit(true);
  }
  openInviteViewDialog(contract: Contract) {
    const payload = { fields: 'ID' };

    this.contractService.onInvitation(contract['ID'], payload).subscribe((contractInvitationMembership) => {
      if (contractInvitationMembership && contractInvitationMembership['ID']) {
        const invitationViewModal = this.dialog.open(InvitationViewModalComponent, {
          data: {
            invitationID: contractInvitationMembership['ID'],
            membership: contractInvitationMembership.membership,
          },

          width: '900px',
        });

        this.subscriptions.add(
          invitationViewModal.componentInstance.close.subscribe((res) => {
            invitationViewModal.close();
          })
        );

        this.subscriptions.add(
          invitationViewModal.componentInstance.openDetails.subscribe((id) => {
            invitationViewModal.close();
            invitationViewModal.afterClosed().subscribe((result) => {
              const page = result.membership ? 'membership-view' : 'invitation-view';

              this.router.navigate(['/merchant', { outlets: { page: [page, id] } }]);
            });
          })
        );
      }
    });
  }

  viewMerchant(contract: Contract) {
    const merchantViewModal = this.dialog.open(MerchantViewComponent, {
      data: contract.Merchant_key,
      width: '600px',
    });

    merchantViewModal.componentInstance.close.subscribe((res) => {
      merchantViewModal.close();
    });
  }

  viewProduct(contract: Contract) {
    const productViewModal = this.dialog.open(ProductViewModalComponent, {
      data: contract.Product_key,
      width: '750px',
      panelClass: 'noCard',
    });

    productViewModal.componentInstance.close.subscribe((res) => {
      productViewModal.close();
    });
  }

  contact(contract: Contract) {
    const data = {
      targetType: 'customer',
      targetID: contract.Customer_key,
      asGuest: false,
      asProfile: true,
      asPractice: true,
    };

    AuthenticationService.contactInputEvent.emit(data);
  }

  newSettlement() {
    this.closeModal.emit(true);

    let p = { contractID: this.contractID };

    AuthenticationService.newSettlement.emit(p);
    // const page = 'settlement-create';

    // this.router.navigate(['/merchant', { outlets: { page: [page, this.contractID] } }]);
  }

  viewEventLog(contract: Contract) {
    const eventLogList = this.dialog.open(EventLogListComponent, {
      data: {
        type: 'contract',
        contractID: contract.ID,
        customerID: contract.Customer_key,
      },
      width: '1250px',
      panelClass: 'darkCard',
    });

    eventLogList.componentInstance.closeModal.subscribe((result) => {
      if (result === true) {
        eventLogList.close();
      }
    });
  }

  setSettlementNotFound(settlementNotFound: boolean) {
    this.settlementNotFound = settlementNotFound;
    this.changeDetectorRef.detectChanges();
  }

  backClicked() {
    if (this.isIntegrated === true) {
      this.goBack.emit(true);
    }
  }

  settlementModal() {
    if (this.promoter && this.promoter.ID) {
      let message = `<div style="text-align:center"><br>Settlements cannot be processed against this contract at this time.<br><br> For further information please contact <strong> ${
        this.promoter['Name']
      } </strong> on <strong> ${this.customPhonePipe.transform(this.promoter['phones.Number'])}</strong>.</div> `;

      if (this.isPromoterOrAdmin == true) {
        message = `<div style="text-align:center"><br>Settlements cannot be processed against this contract at this time.<br><h2> Contract Status: ${this.contract['Status']}</h2>`;
      }

      const confirmDialog2 = new ConfirmDialogSingleButton('fas fa-info', 'Settlement Request ', message, 'OKAY');
      const ref2 = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
        data: confirmDialog2,
        width: '650px',
      });
      ref2.componentInstance.onConfirm.subscribe((res) => {
        ref2.close();
      });
    } else {
      this.authenticationService.getPromoter().subscribe((r) => {
        if (r && r.data && r.data.ID) {
          this.promoter = r.data;
          let message = `<div style="text-align:center"><br>Settlements cannot be processed against this contract at this time.<br><br> For further information please contact <strong> ${
            this.promoter['Name']
          } </strong> on <strong> ${this.customPhonePipe.transform(this.promoter['phones.Number'])}</strong>.</div> `;

          if (this.isPromoterOrAdmin == true) {
            message = `<div style="text-align:center"><br>Settlements cannot be processed against this contract at this time.<br><h2> Contract Status: ${this.contract['Status']}</h2>`;
          }
          const confirmDialog2 = new ConfirmDialogSingleButton('fas fa-info', 'Settlement Request ', message, 'OKAY');
          const ref2 = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
            data: confirmDialog2,
            width: '650px',
          });
          ref2.componentInstance.onConfirm.subscribe((res) => {
            ref2.close();
          });
        }
      });
    }
  }

  getTotalFee() {
    let result = 0;

    if (this.contract) {
      if (this.contract['Recourse_Release_MSF'] && Number(this.contract['Recourse_Release_MSF']) > 0) {
        result = result + Number(this.contract['Recourse_Release_MSF']);
      }
      if (this.contract['Merchant_Service_Fee'] && Number(this.contract['Merchant_Service_Fee']) > 0) {
        result = result + Number(this.contract['Merchant_Service_Fee']);
      }
      if (this.contract['Merchant_Membership_Fee'] && Number(this.contract['Merchant_Membership_Fee']) > 0) {
        result = result + Number(this.contract['Merchant_Membership_Fee']);
      }
      if (this.contract['Merchant_Risk_Fee'] && Number(this.contract['Merchant_Risk_Fee']) > 0) {
        result = result + Number(this.contract['Merchant_Risk_Fee']);
      }
      if (this.contract['Credit_Charges_Merchant'] && Number(this.contract['Credit_Charges_Merchant']) > 0) {
        result = result + Number(this.contract['Credit_Charges_Merchant']);
      }
      if (this.contract['CustomerFeesAbsorbed'] && Number(this.contract['RecourseCustomerFeesAbsorbed_Release_MSF']) > 0) {
        result = result + Number(this.contract['CustomerFeesAbsorbed']);
      }
      if (this.contract['Insurance_Contributions'] && Number(this.contract['Insurance_Contributions']) > 0) {
        result = result + Number(this.contract['Insurance_Contributions']);
      }
      if (this.contract['RefundsForUndrawn'] && Number(this.contract['RefundsForUndrawn']) > 0) {
        result = result + Number(this.contract['RefundsForUndrawn']);
      }
      if (this.contract['Borrower_Risk_Fee'] && Number(this.contract['Borrower_Risk_Fee']) > 0) {
        result = result + Number(this.contract['Borrower_Risk_Fee']);
      }
      if (this.contract['Merchant_HoldBack_Fee'] && Number(this.contract['Merchant_HoldBack_Fee']) > 0) {
        result = result + Number(this.contract['Merchant_HoldBack_Fee']);
      }
    }

    return result;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}

const CUSTOM_SUMMARY_CHART_COLOR: ChartColor[] = [
  {
    name: 'Settled to Practice',
    value: '#1b8bdd',
  },
  {
    name: 'Settled to Insurance',
    value: '#ffbc00',
  },
  {
    name: 'Settled to Supplier',
    value: '#dd1b1b',
  },
  {
    name: 'Available Balance',
    value: '#00d07a ',
  },
  {
    name: 'Original Loan',
    value: '#858585 ',
  },
];
