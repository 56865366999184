import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { DocumentService } from '../shared/document.service';

import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import * as $ from 'jquery';
import * as _ from 'lodash';
import * as moment from 'moment';
import { FileInsertionComponent } from '../../../shared/components/file-insertion/file-insertion.component';
import { IframeViewComponent } from '../../../shared/components/iframe-view/iframe-view.component';
import { LookupService } from '../../../shared/services/lookup.service';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { DentistService } from '../../dentist/shared/dentist.service';
import { ProductService } from '../../product/shared/product.service';
import { ProductUtil } from '../../product/shared/productUtil.type';
import { TagCreateEditComponent } from '../../tags/tag-create-edit/tag-create-edit.component';
import { TreatmentDocumentImportOverviewComponent } from '../../treatment/treatment-document-import-overview/treatment-document-import-overview.component';
import { LandingPageHeaderLookupComponent } from '../landing-page-header-lookup/landing-page-header-lookup.component';

@Component({
  selector: 'app-document-link-create',
  templateUrl: './document-link-create.component.html',
  styleUrls: ['./document-link-create.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: '*' }))),
    ]),
  ],
})
export class DocumentLinkCreateComponent implements OnInit {
  addToStore = false;
  patientID;
  merchantID;
  label;
  documentIDs = [];
  fileIDs = [];
  getLink = new EventEmitter();
  getResult = new EventEmitter();
  close = new EventEmitter();
  addFile = new EventEmitter();
  sessionType;
  link;

  title;

  _description;
  description;
  descriptionRaw;
  message;
  messageRaw;
  _message;
  theme = {
    code: 'blue',
    background: 'linear-gradient(135deg, #0092eb 0%, #0dbad3 100%)',
    color: '#1b8bdd',
  };
  documentTypes = ['Treatment Plan', 'Additional Document'];
  linkID;

  themes;

  visitorsType;
  isHeaderImage = true;

  productUtil = new ProductUtil();

  treatmentAmount;
  createdForPreview = false;

  messageMode = 'all';

  settings = Settings.global;

  addGalleryDocument = true;
  addNewDocument = true;
  isActive = true;
  isPublic = false;
  isDefault = false;
  isStored = false;
  tags = [];
  selectedTags = [];
  defaultSelectedTags = [];
  isPromoterOrAdmin;
  addTag = new EventEmitter();
  maxLoanAmount;
  minLoanAmount;
  defaultLink;
  isEditMode = false;
  isExpired = false;

  displayDisclaimerMessage;

  _disclaimerMessage;
  disclaimerMessage;
  disclaimerMessageRaw;

  expireDate;
  expireDateType = 'never';
  expireDateTypes = [
    {
      code: '1days',
      label: '1 Day',
      days: 1,
    },

    {
      code: '3days',
      label: '3 Days',
      days: 3,
    },
    {
      code: 'week',
      label: '1 Week',
      days: 7,
    },
    {
      code: '2weeks',
      label: '2 Weeks',
      days: 15,
    },
    {
      code: 'month',
      label: '1 Month',
      days: 30,
    },
    {
      code: 'month',
      label: '3 Months',
      days: 90,
    },
    {
      code: 'year',
      label: '1 Year',
      days: 365,
    },
    {
      code: 'setDate',
      label: 'Set Date',
      days: 10,
    },
    {
      code: 'never',
      label: 'Never',
    },
  ];
  expireDateDefault;

  cardID;
  merchant;
  promoter;
  contact;
  contactID;

  step = 1;
  displayPatient = false;
  tableName;
  card: any = {
    ID: '00000000000000000000',
    Salutation: 'Mr',
    FirstName: 'John',
    MiddleName: 'Al',
    Name: 'Doe',
    'mobiles.Number': '0061101010101',
    'phones.Number': '0062202020202',
    'emails.Email': 'john.doe@smileright.com.au',
    'addresses.Calculated': 'PO Box 7795 Cloisters Square 6850 Western Australia',
  };
  loadMessage = false;
  loadContact = false;
  loadMerchant = false;
  loadPromoter = false;
  financedProductGroup = [];

  productGroup;

  dentists;

  headerImage =
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/Asset_6.png?type=b';
  displayType = true;

  types = [
    {
      Code: 'FLandPg',
      Label: 'Finance Landing Page',
      Icon: 'fas fa-search-dollar',
    },
    {
      Code: 'PLandPg',
      Label: 'Product Landing Page',
      Icon: 'fas fa-images',
    },
  ];
  type = 'FLandPg';
  displayAppointmentPage = true;
  displayTipsAndOffersPage = true;
  displayGoingGreenPage = true;
  currentDentist;

  isModal = false;

  operationType = 'create';
  cloneID;
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private lookupService: LookupService,
    private dentistService: DentistService,
    private productService: ProductService,
    private authenticationService: AuthenticationService,
    private documentService: DocumentService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data && data.cardID) {
        this.cardID = data.cardID;
        this.isModal = true;
      }

      if (data && data.visitorsType) {
        this.visitorsType = data.visitorsType;
        this.isModal = true;
      }
      if (data && data.card) {
        this.card = data.card;
        this.isModal = true;
      }
      if (data && data.merchant) {
        this.merchant = data.merchant;
        this.isModal = true;
      }
      if (data && data.messageMode) {
        this.messageMode = data.messageMode;
        this.isModal = true;
      }

      if (data && data.promoter) {
        this.promoter = data.promoter;
        this.isModal = true;
      }
      if (data && data.contact) {
        this.contact = data.contact;
        this.isModal = true;
      }
      if (data && data.contactID) {
        this.contactID = data.contactID;
        this.isModal = true;
      }

      if (data && data.tableName) {
        this.tableName = data.tableName;
        this.isModal = true;
      }

      if (data && data.merchantID) {
        this.merchantID = data.merchantID;
        this.isModal = true;
      }
      if (data && data.patientID) {
        this.patientID = data.patientID;
        this.isModal = true;
      }

      if (data && data.linkID) {
        this.linkID = data.linkID;
        this.isModal = true;
      }
      if (data && data.link) {
        this.defaultLink = data.link;
        this.isModal = true;
      }
      if (data && data.documentIDs && data.documentIDs.length > 0) {
        this.documentIDs = data.documentIDs;
        this.isModal = true;
      }
      if (data && data.title) {
        this.title = data.title;
        this.isModal = true;
      }
      if (data && data.description) {
        this.description = data.description;
        this.isModal = true;
      }
      if (data && data.addGalleryDocument != null) {
        this.addGalleryDocument = data.addGalleryDocument;
        this.isModal = true;
      }
      if (data && data.addNewDocument != null) {
        this.addNewDocument = data.addNewDocument;
        this.isModal = true;
      }
      if (data && data.addToStore != null) {
        this.addToStore = data.addToStore;
        this.isModal = true;
      }

      if (data && data.isFinance != null) {
        if (data.isFinance === true) {
          this.type = 'FLandPg';
          this.displayType = false;
          this.isModal = true;
        } else if (data.isFinance === false) {
          this.type = 'PLandPg';
          this.displayType = false;
          this.isModal = true;
        }
      }
      if (data && data.type) {
        this.type = data.type;
        this.isModal = true;

        this.displayType = false;
      }

      if (data && data.displayType != null) {
        this.displayType = data.displayType;
        this.isModal = true;
      }

      if (data.message) {
        this._message = data._message;
        this.isModal = true;
      }

      if (data.description) {
        this._description = data.description;
        this.isModal = true;
      }

      if (data.title) {
        this.title = data.title;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['merchantID'] && params['merchantID'] != 'null') {
        this.merchantID = params['merchantID'];
      }

      if (params['type']) {
        this.type = params['type'];
        this.displayType = false;
      }

      if (params['linkID']) {
        this.linkID = params['linkID'];
        this.operationType = 'edit';
      }
      if (params['cloneID']) {
        this.cloneID = params['cloneID'];
        this.operationType = 'clone';
      }

      if (params['addToStore'] === 'true' || params['addToStore'] === true) {
        this.addToStore = true;
      } else if (params['addToStore'] === 'false' || params['addToStore'] === false) {
        this.addToStore = false;
      }

      if (Settings.global['context'] === 'p4p') {
        this.theme = {
          code: 'ee350b',
          background: ' linear-gradient(-135deg, #1caaa0 -10.31%, #7462ef  97.05%)',
          color: '#7462ef',
        };
      }

      if (this.settings && this.settings['context'] == 'breeze') {
        this.documentTypes = ['Additional Document'];

        this.headerImage =
          'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/MSSS-3-600x600.jpg?type=c';
      } else if (this.settings && this.settings['context'] == 'p4p') {
        this.documentTypes = ['Additional Document'];

        this.headerImage =
          'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/duke-contact+-+Copy.png?type=b';
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((r) => {
        this.isPromoterOrAdmin = r;

        this.dentistService
          .getList({ merchantID: this.merchantID, fields: 'ID,CalculatedName' }, this.isPromoterOrAdmin)
          .subscribe((res) => {
            if (res && res.length > 0) {
              this.dentists = res;
            }
          });
        if (this.card && this.card.ID === '00000000000000000000') {
          this.authenticationService.getCurrentDentist().subscribe((r) => {
            if (r) {
              this.currentDentist = r;

              if (r && r['Gender.Code'] && r['Gender.Code'] === 'F') {
                this.card.FirstName = 'Jane';
                this.card['emails.Email'] = 'jane.doe@smileright.com.au';
                if (r && r['Salutation']) {
                  this.card['Salutation'] = r['Salutation'];
                } else {
                  this.card['Salutation'] = 'Miss';
                }
              } else if (r && r['Salutation']) {
                this.card['Salutation'] = r['Salutation'];
              }
            }
            this.init();
          });
        } else {
          this.init();
        }
      });
    });
  }
  goToList(isTemplate = false) {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['document-link-list'],
        },
      },
    ]);
  }
  init() {
    const p1 = {
      merchantID: this.merchantID,
      isFinance: true,
    };

    this.productService.getMerchantProductGroup(p1, this.isPromoterOrAdmin).subscribe((res) => {
      if (res && res.length > 0) {
        const index = res.findIndex((item) => {
          if (item && item['GroupCode'] === 'GPF') {
            return true;
          }
          return false;
        });
        if (index != -1) {
          this.financedProductGroup = res;
          this.productGroup = 'GPF';
        } else {
          this.financedProductGroup = res;
          if (res && res[0] && res[0]['GroupCode']) {
            this.productGroup = res[0]['GroupCode'];
          }
        }
      }

      if ((this.card && this.card.ID && this.card.ID !== '00000000000000000000') || this.cardID) {
        this.displayPatient = true;
      }

      this.loadMessage = true;

      const pTag = {
        isPromoter: false,
        merchantID: this.merchantID,
        fields: 'Label',
      };

      this.documentService.getTagsList(pTag, this.sessionType).subscribe((res) => {
        if (res) {
          this.tags = _.map(res, (item) => {
            if (item && item.Label) {
              return item.Label;
            }

            return null;
          });
        }
      });

      if (this.addToStore === true) {
        this.isStored = true;
      }

      this.authenticationService.getSessionType().subscribe((r) => {
        this.sessionType = r;

        if (!this.merchantID) {
          this.authenticationService.getCurrentPractice().subscribe((r) => {
            if (r && r.ID) {
              this.merchantID = r.ID;
            }

            this.documentService.documentLinkListTheme().subscribe((t) => {
              if (t && t.length > 0) {
                this.themes = t;
              }

              if (this.linkID) {
                this.createdForPreview = false;
                this.documentService.getDocumentLink(this.linkID, {}, this.sessionType).subscribe((doc) => {
                  if (doc && doc.ID) {
                    this.buildItem(doc);
                  } else {
                    this.setup();
                  }
                });
              } else if (this.defaultLink && this.defaultLink.ID) {
                this.buildItem(this.defaultLink);
              } else if (this.cloneID) {
                this.documentService.getDocumentLink(this.cloneID, {}, this.sessionType).subscribe((doc) => {
                  if (doc && doc.ID) {
                    this.buildItem(doc);
                  } else {
                    this.setup();
                  }
                });
              } else if (this.productGroup) {
                this.setup();
                this.getLoanAmount(this.productGroup);
              }
            });
          });
        } else {
          this.documentService.documentLinkListTheme().subscribe((t) => {
            if (t && t.length > 0) {
              this.themes = t;
            }

            if (this.linkID) {
              this.createdForPreview = false;
              this.documentService.getDocumentLink(this.linkID, {}, this.sessionType).subscribe((doc) => {
                if (doc && doc.ID) {
                  this.buildItem(doc);
                } else {
                  this.setup();
                }
              });
            } else if (this.defaultLink && this.defaultLink.ID) {
              this.buildItem(this.defaultLink);
            } else if (this.cloneID) {
              this.documentService.getDocumentLink(this.cloneID, {}, this.sessionType).subscribe((doc) => {
                if (doc && doc.ID) {
                  this.buildItem(doc);
                } else {
                  this.setup();
                }
              });
            } else if (this.productGroup) {
              this.setup();
              this.getLoanAmount(this.productGroup);
            }
          });
        }
      });
    });
  }
  changeDentist() {
    if (this.contactID) {
      this.loadContact = false;
      this.authenticationService.getCardDetails(this.contactID, 'Contact', {}, this.sessionType).subscribe((r) => {
        if (r && r.ID) {
          this._description = this.descriptionRaw;

          this._disclaimerMessage = this.disclaimerMessageRaw;

          this._message = this.messageRaw;
          this.contact = r;
          this.contactID = r.ID;
        }
        this.loadContact = true;
      });
    }
  }
  setup() {
    if (!this.promoter) {
      this.authenticationService.getPromoter().subscribe((r) => {
        if (r) {
          this.promoter = r;
        }
        this.loadPromoter = true;
      });
    } else {
      this.loadPromoter = true;
    }

    if (!this.contactID && !this.contact) {
      this.authenticationService.getCurrentDentist().subscribe((r) => {
        if (r) {
          this.contact = r;
          this.contactID = r.ID;
        }
        this.loadContact = true;
      });
    } else if (this.contact && this.contact.ID) {
      this.contactID = this.contact.ID;
      this.loadContact = true;
    } else if (this.contactID) {
      this.authenticationService.getCardDetails(this.contactID, 'Contact', {}, this.sessionType).subscribe((r) => {
        if (r && r.ID) {
          this.contact = r;
          this.contactID = r.ID;
        }
        this.loadContact = true;
      });
    }

    if (!this.merchantID && !this.merchant) {
      this.authenticationService.getCurrentPractice().subscribe((r) => {
        if (r) {
          this.merchant = r;

          this.buildMerchant();
        }
        this.loadMerchant = true;
      });
    } else if (this.merchant && this.merchant.ID) {
      this.merchantID = this.merchant.ID;
      this.loadMerchant = true;
    } else if (this.merchantID) {
      this.authenticationService.getCardDetails(this.merchantID, 'Merchant', {}, this.sessionType).subscribe((r) => {
        if (r && r.ID) {
          this.merchant = r;

          this.buildMerchant();
          this.merchantID = r.ID;
        }
        this.loadMerchant = true;
      });
    }

    if (this.treatmentAmount) {
      this.card.AmountToFinance = this.treatmentAmount;
    }
  }

  changeAmount() {
    if (this.treatmentAmount) {
      this.card.AmountToFinance = this.treatmentAmount;
    }
  }

  buildMerchant() {
    if (this.merchant && this.merchant.ID) {
      if (!this.disclaimerMessage) {
        if (this.merchant['Hide_Disclaimer_Message'] === '1') {
          this.displayDisclaimerMessage = false;
        } else if (this.merchant['Hide_Disclaimer_Message'] === '0') {
          this.displayDisclaimerMessage = true;
        }

        if (this.merchant['Disclaimer_Message']) {
          this.disclaimerMessage = this.merchant['Disclaimer_Message'];
          this._disclaimerMessage = this.merchant['Disclaimer_Message'];
        }
      }
    }
  }
  createNewTag() {
    const ref = RootAppComponent.dialog.open(TagCreateEditComponent, {
      data: {
        merchantID: this.merchantID,
      },

      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res && res.Label) {

        if (
          (!this.tags || (this.tags && this.tags.length <= 0)) &&
          (!this.defaultSelectedTags || (this.defaultSelectedTags && this.defaultSelectedTags.length <= 0))
        ) {
          this.defaultSelectedTags = JSON.parse(JSON.stringify([res.Label]));
          this.tags = JSON.parse(JSON.stringify([res.Label]));
        } else {
          if (!this.tags || (this.tags && this.tags.length <= 0)) {
            this.tags.push(res.Label);
          }
          this.addTag.emit(res.Label);
        }
        ref.close();
      }
    });
  }
  selectDateToFilter(d) {
    this.expireDate = d;
  }

  getSelectedTags(e) {
    this.selectedTags = e || [];
  }

  showExpired() {
    if (
      moment(this.expireDate).isBefore(moment()) &&
      moment(this.expireDate).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD') &&
      this.expireDateType !== 'never'
    ) {
      return true;
    }

    return false;
  }

  removeEPDocument(e) {
    if (e) {
      const index = this.documentIDs.findIndex((item) => {
        if (item && item === e) {
          return true;
        }
        return false;
      });

      if (index !== -1) {
        this.documentIDs.splice(index, 1);
      }
    }
  }

  removeNewFile(e) {
    if (e) {
      const index = this.fileIDs.findIndex((item) => {
        if (item && item === e) {
          return true;
        }
        return false;
      });

      if (index !== -1) {
        this.fileIDs.splice(index, 1);
      }
    }
  }
  setExpireDate() {
    if (this.expireDateType && this.expireDateType !== 'never') {
      const index = this.expireDateTypes.findIndex((item) => {
        if (item && item.code && item.code === this.expireDateType) {
          return true;
        }
        return false;
      });

      if (index != -1) {
        const item = this.expireDateTypes[index];

        if (item && item.days) {
          this.expireDateDefault = moment().add(item.days, 'd').toDate();
        }
      }
    }

    this.isExpired = false;
  }
  buildItem(res) {
    if (res) {
      this.isEditMode = true;

      if (res['Merchant_key']) {
        this.merchantID = res['Merchant_key'];
      }

      if (!this.cloneID) {
        if (res['Contact_key']) {
          this.contactID = res['Contact_key'];
        }
      }
      if (res['Label']) {
        this.label = res['Label'];
      }

      if (this.cloneID) {
        this.label = this.label + '(Cloned)';
      }

      if (res['Header_Image'] && res['Header_Image'] != 'none') {
        this.headerImage = res['Header_Image'];
      } else if (res['Header_Image'] && res['Header_Image'] == 'none') {
        this.isHeaderImage = false;
      } else {
        this.isHeaderImage = true;
      }

      if (res['Title']) {
        this.title = res['Title'];
      }
      if (res['Description']) {
        this.description = res['Description'];
        this._description = res['Description'];
      }

      if (res['Message']) {
        this.message = res['Message'];
        this._message = res['Message'];
      }

      if (res['isExpired'] === true) {
        this.expireDateType = 'setDate';
        this.expireDateDefault = moment(res['Date.Expire'], 'YYYY-MM-DD').toDate();
        this.isExpired = true;
      } else {
        if (res['Date.Expire'] == '0000-00-00' || res['isExpired'] == '2200-01-01') {
          this.expireDateType = 'never';
          this.expireDateDefault = moment().add(30, 'd').toDate();
        }
        this.isExpired = false;
      }

      if (res['Theme']) {
        if (this.themes && this.themes.length > 0) {
          const index = this.themes.findIndex((item) => {
            if (item && item.code == res['Theme']) {
              return true;
            }

            return false;
          });

          if (index != -1) {
            this.theme = this.themes[index];
          }
        }
      }
      if (res['documentIDs'] && res['documentIDs'].length > 0) {
        this.documentIDs = JSON.parse(JSON.stringify(res['documentIDs']));
      }
      if (res['tags'] && res['tags'].length > 0) {
        this.defaultSelectedTags = JSON.parse(JSON.stringify(res['tags']));

        this.selectedTags = JSON.parse(JSON.stringify(res['tags']));
      }
      if (res['IsActive'] === '1') {
        this.isActive = true;
      } else if (res['IsActive'] === '0') {
        this.isActive = false;
      }
      if (res['IsDefault'] === '1') {
        this.isDefault = true;
      } else if (res['IsDefault'] === '0') {
        this.isDefault = false;
      }
      if (res['IsPublic'] === '1') {
        this.isPublic = true;
      } else if (res['IsPublic'] === '0') {
        this.isPublic = false;
      }
      if (res['Is_Stored'] === '1') {
        this.isStored = true;
      } else if (res['Is_Stored'] === '0') {
        this.isStored = false;
      }

      if (res['Type.Code']) {
        this.type = res['Type.Code'];
      }
      if (res['Treatment_Amount']) {
        this.treatmentAmount = Number(res['Treatment_Amount']);
      }

      if (res['Display_Going_Green_Page'] === '1') {
        this.displayGoingGreenPage = true;
      } else if (res['Display_Going_Green_Page'] === '0') {
        this.displayGoingGreenPage = false;
      }

      if (res['Display_Tips_Offers_Page'] === '1') {
        this.displayTipsAndOffersPage = true;
      } else if (res['Display_Tips_Offers_Page'] === '0') {
        this.displayTipsAndOffersPage = false;
      }

      if (res['Display_Appointment_Page'] === '1') {
        this.displayAppointmentPage = true;
      } else if (res['Display_Appointment_Page'] === '0') {
        this.displayAppointmentPage = false;
      }

      if (res['Hide_Disclaimer_Message'] === '1') {
        this.displayDisclaimerMessage = false;
      } else if (res['Hide_Disclaimer_Message'] === '0') {
        this.displayDisclaimerMessage = true;
      }

      if (res['Disclaimer_Message']) {
        this.disclaimerMessage = res['Disclaimer_Message'];
        this._disclaimerMessage = res['Disclaimer_Message'];
      }
    }

    this.setup();

    if (this.productGroup) {
      this.getLoanAmount(this.productGroup);
    }
  }
  onCompleFile(f) {
    if (f && f[0]) {
      this.addFile.emit(f[0]);
      this.fileIDs.push(f[0]);
    }
  }
  selectTheme(d) {
    if (d && d.code) {
      this.theme = d;
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  submit(sendResult = true) {
    const p: any = {
      merchantID: this.merchantID,
      theme: this.theme.code,
      label: this.label,
      title: this.title,
      description: this.descriptionRaw,
      documentIDs: this.documentIDs,
      tags: this.selectedTags,
      message: this.messageRaw,
      isActive: this.isActive,
      isPublic: this.isPublic,
      isDefault: this.isDefault,
      isStored: this.isStored,
      fileIDs: this.fileIDs,
      canExpire: false,
      expireDate: null,
      type: this.type,
      headerImage: this.headerImage,
      displayAppointmentPage: this.displayAppointmentPage,
      displayTipsAndOffersPage: this.displayTipsAndOffersPage,
      displayGoingGreenPage: this.displayGoingGreenPage,

      disclaimerMessage: null,
      displayDisclaimerMessage: false,

      contactID: this.contactID,
    };

    if (this.isHeaderImage != true) {
      p.headerImage = 'none';
    }

    if (this.type == 'FLandPg') {
      p.treatmentAmount = this.treatmentAmount;
      p.productGroupCode = this.productGroup;
      p.disclaimerMessage = this.disclaimerMessageRaw;
      p.displayDisclaimerMessage = this.displayDisclaimerMessage;
    }
    if (this.expireDateType != 'never') {
      p.canExpire = true;
      p.expireDate = this.expireDate;
    }
    if (this.createdForPreview == true && sendResult === false) {
      p.isActive = false;
      p.isPublic = false;
      p.isDefault = false;
      p.isStored = false;
    } else if (sendResult != false) {
      this.createdForPreview = false;
    }

    if (this.linkID) {
      if (!this.documentIDs || (this.documentIDs && this.documentIDs.length <= 0)) {
        p.documentIDs = 'none';
      }
      this.documentService.modifyDocumentLink(this.linkID, p, this.sessionType).subscribe((r) => {
        if (r && r.ID) {
          this.link = this.documentService.getLinkByVisitorType(r.ID, this.visitorsType);
          if (sendResult === true) {
            this.getResult.emit(r);
            this.getLink.emit(this.link);

            if (this.isModal != true) {
              this.router.navigate([
                '/merchant',
                {
                  outlets: {
                    page: ['landing-page-overview', r.ID],
                  },
                },
              ]);
            }
          } else {
            this.view(r.ID);
          }
        }
      });
    } else {
      if (sendResult === false) {
        p.isActive = false;
        p.isPublic = false;
        p.isDefault = false;
        p.isStored = false;
      }
      this.documentService.createDocumentLink(p, this.sessionType).subscribe((r) => {
        if (r && r.ID) {
          if (sendResult == false) {
            this.createdForPreview = true;
          }
          this.linkID = r.ID;
          this.link = `${Settings.global['publicSiteFrontendLink']}/doc/${r.ID}`;
          if (sendResult === true) {
            this.getResult.emit(r);

            this.getLink.emit(this.link);

            if (this.isModal != true) {
              this.router.navigate([
                '/merchant',
                {
                  outlets: {
                    page: ['landing-page-overview', r.ID],
                  },
                },
              ]);
            }
          } else {
            this.view(r.ID);
          }
        }
      });
    }
  }
  view(id) {
    if (id) {
      const link = `${Settings.global['publicSiteFrontendLink']}/doc/${id}`;

      const ref2 = RootAppComponent.dialog.open(IframeViewComponent, {
        data: {
          link,
        },
        width: '80%',
        height: '92vh',
        panelClass: 'noCard',
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });
    }
  }
  addDocumentFromGallery() {
    const payload = {
      merchantID: this.merchantID,
      readonly: false,
      isGallery: true,
      loadUserDocument: true,
      loadICDocument: true,
      loadPatientDocument: true,
      patientID: this.patientID,
      selectedView: 'system',
      documents: this.documentIDs,
      userDocumentType: ['SRVSTC', 'TRP', 'IGVIDEO'],
      ICDocumentType: ['SRVSTC', 'TRP', 'IGVIDEO'],
      displayUserDocumentType: true,
      displayICDocumentType: true,
      displayPatientDocumentType: true,
      isICSideMenu: true,
      isUserSideMenu: true,
      isPatientSideMenu: true,
      buttonLabel: 'Documents',
      displayPatientDocuments: true,
      nature: ['document', 'image', 'video'],
      natureICDocument: ['document', 'image', 'video'],
      natureUserDocument: ['document', 'image', 'video'],
      naturePatientDocument: ['document', 'image', 'video'],
      addTitle: 'Insert Documents',
      selectAnotherPatient: true,
      descriptionRaw: this.descriptionRaw,
      messageRaw: this.messageRaw,
    };
    if (!this.patientID) {
      payload.patientID = null;
      payload.loadPatientDocument = false;
      payload.displayPatientDocumentType = false;
      payload.isPatientSideMenu = false;
      payload.naturePatientDocument = [];
    }

    const ref = RootAppComponent.dialog.open(TreatmentDocumentImportOverviewComponent, {
      data: payload,
      width: '90vw',
    });
    ref.backdropClick().subscribe((data) => {
      ref.close();
    });
    ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
    ref.componentInstance.getDocuments.subscribe((res) => {
      if (res && res.length > 0) {
        this.documentIDs = JSON.parse(JSON.stringify(res));

        setTimeout(() => {
          $('#documentlinkcreate').animate({ scrollTop: '1000000' });
        }, 1500);

        ref.close();
      } else {
        ref.close();
      }
    });
  }
  getResultDescription(e) {
    if (e) {
      this.description = e.convertedMessage;
      this.descriptionRaw = e.message;
    } else {
      this.description = null;
      this.descriptionRaw = null;
    }
  }
  getResultMessage(e) {
    if (e) {
      this.message = e.convertedMessage;

      this.messageRaw = e.message;
    } else {
      this.message = null;
      this.messageRaw = null;
    }
  }

  getResultMessageDisclaimer(e) {
    if (e) {
      this.disclaimerMessage = e.convertedMessage;

      this.disclaimerMessageRaw = e.message;
    } else {
      this.disclaimerMessage = null;
      this.disclaimerMessageRaw = null;
    }
  }
  previous() {
    this.step = 1;
    if (this.isModal != true) {
      UtilsClass.scrollUp();
    }
  }
  previous2() {
    this.step = 2;

    if (this.isModal != true) {
      UtilsClass.scrollUp();
    }
  }
  next() {
    this.step = 2;

    if (this.isModal != true) {
      UtilsClass.scrollUp();
    }
  }
  next2() {
    this.step = 3;

    if (this.isModal != true) {
      UtilsClass.scrollUp();
    }
  }

  getLoanAmount(productCode) {
    if (productCode) {
      const payload = {
        groupCode: productCode,
        merchantID: this.merchantID,
        fields: 'Parameter.MaxLoanValue,Parameter.MinLoanValue',
      };
      this.productService.getList(payload, this.isPromoterOrAdmin).subscribe((res) => {
        if (res && res.length > 0) {
          const temp = res.map((x) => Number(x['Parameter.MaxLoanValue']));
          this.maxLoanAmount = Math.max(...temp) || 0;

          const temp2 = res.map((x) => Number(x['Parameter.MinLoanValue']));
          this.minLoanAmount = Math.min(...temp2) || 0;
        }
      });
    }
  }

  upload() {
    const ref = RootAppComponent.dialog.open(FileInsertionComponent, {
      data: {
        documentType: 'LP_HI',
        displayGallery: false,
        isGallery: true,
        isFixedAspectRatio: true,
        roundCropper: true,
        aspectRatioType: 'full',
        documentNature: 'image',
        keepOriginal: false,
        maxFileSize: 1,
      },
      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.getResult.subscribe((document) => {
      if (document && document.AWS_Bucket_Name && document.AWS_URL) {
        this.headerImage = `${Settings.global['AWSS3Prefix']}/${document.AWS_Bucket_Name}${document.AWS_URL}?type=c`;

        ref.close();
      }
    });
  }
  getMerchantLink() {
    return environment.nodeUrl + '/merchant/practice-picture-stream/' + this.merchantID;
  }

  getCircle(headerImage) {
    if (headerImage && headerImage.indexOf('?type=c') != -1) {
      return true;
    }

    return false;
  }
  galleryHeader() {
    const ref2 = RootAppComponent.dialog.open(LandingPageHeaderLookupComponent, {
      width: '900px',
    });

    ref2.componentInstance.close.subscribe((res) => {
      ref2.close();
    });

    ref2.componentInstance.getResult.subscribe((res) => {
      if (res) {
        this.headerImage = res;
        ref2.close();
      }
    });
  }
  URLifyDescription(s) {
    return this.documentService.URLifyDescription(s);
  }

  URLify(d) {
    return this.documentService.URLify(d);
  }
}
