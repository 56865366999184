import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TreatmentService } from '../shared/treatment.service';

@Component({
  selector: 'app-category-view-logo',
  templateUrl: './treatment-category-view-logo.component.html',
  styleUrls: ['./treatment-category-view-logo.component.css'],
})
export class TreatmentCategoryViewLogoComponent implements OnInit {
  @Input() categoryID;

  @Input() hideZoomButton = false;
  @Output() close = new EventEmitter();

  profileLink;
  @Input()
  LastModified = null;

  constructor(
    private TreatmentService: TreatmentService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.categoryID = data;
    }
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.categoryID && changes.categoryID.previousValue != changes.categoryID.currentValue) {
      if (this.categoryID) {
        this.getPicture();
      } else {
        this.profileLink = null;
      }
    }
  }

  getPicture() {
    this.profileLink = null;

    if (this.categoryID) {
      this.profileLink = this.TreatmentService.getCategoryLogoStreamLink(this.categoryID, this.LastModified);
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
}
