<mat-card class="settlement-card clearfix req-set" *ngIf="Settlement">
  <div class="card-header primary-gradient-img clearfix inModal">
    <div class="row clearfix">
      <button class="btn-close btn-clear pull-right mat-button" *ngIf="isModal == true" (click)="closeModalEvent()">
        <mat-icon>clear</mat-icon>
      </button>

      <div class="row titleArea clearfix">
        <mat-icon class="pull-left fas fa-comment-dollar"
          style=" font-size: 3rem;  width: 3rem;  height: 3rem;   line-height: 3rem;  margin-right: 20px;"></mat-icon>
        <h2 class="summary-header rm-m">
          Settlement Amount
          <strong>{{ Settlement['Settlement.Amount'] | customCurrency }}</strong>

          <span class="subLabel">
            <span class="chip" [class.approved]="Settlement['Status.Code'] == 'APPR'"
              [class.cancelled]="Settlement['Status.Code'] == 'WDRN'"
              [class.declined]="Settlement['Status.Code'] == 'DECL'"
              [class.requested]="Settlement['Status.Code'] == 'REQU'"
              [class.pending]="Settlement['Status.Code'] == 'PEND'"
              [class.awaiting]="Settlement['Status.Code'] == 'AWTR'">
              {{ Settlement['Status.Label'] }}
            </span>
          </span>
        </h2>
      </div>
    </div>

    <!-- button if within modal -->
    <div class="action-button" *ngIf="isModal == false">
      <button (click)="openMerchantDialog()" mat-raised-button>
        <mat-icon class="primary-color">person</mat-icon>
        <span class="mobHide primary-color">View Merchant</span>
      </button>

      <button class="mr" (click)="openContractDialog()" mat-raised-button>
        <mat-icon class="primary-color">assignment</mat-icon>
        <span class="mobHide primary-color">View Contract</span>
      </button>
    </div>
  </div>

  <mat-dialog-content  style="   padding: 0 18px !important; ">
    <div class="full-width flex">
      <div class="row clearfix full-width" *ngIf="isNeedAuth == true">
        <div class="row clearfix" *ngIf="!option">
          <div class="row clearfix flex align-top">
            <img class="leftImg mr"
              *ngIf="settings && settings['context'] && settings['context'] != 'p4p' && settings['context'] != 'breeze'"
              src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/character/pen-and-phone-dentist.png" />
            <img class="leftImg mr" *ngIf="settings && settings['context'] && settings['context'] == 'p4p'"
              src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/p4p/commence-application-header.png" />
            <img class="leftImg mr" *ngIf="settings && settings['context'] && settings['context'] == 'breeze'"
              src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/s-phone-sms-re.jpg" />

            <!-- <hr class="rm-mt mb"> -->

            <p class="larger rm-mb">
              To authorise this request, your {{ "KEYWORD.patient" | translate }} can choose to receive a SMS code to
              their mobile and quote it back
              to you to, or you can print a physical form to sign.
            </p>
          </div>
          <hr />
          <div class="row clearfix text-center buttons">
            <!-- print a form -->
            <button class="increase-margin" (click)="proceedWithForm()" mat-raised-button color="accent">
              <mat-icon class="fas fa-print"></mat-icon>&nbsp; Get Form
              <mat-icon>chevron_right</mat-icon>
            </button>
            <!-- send a sms -->
            <button class="increase-margin" (click)="proceedWithCode()" mat-raised-button color="accent">
              <mat-icon class="fas fa-mobile-alt"></mat-icon>&nbsp; Send Code
              <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </div>

        <div class="row clearfix" *ngIf="option">
          <div class="container animated fadeInDown clearfix" id="step-2">
            <form #smsForm="ngForm">
              <div class="row clearfix text-center">
                <h2 class="sr-title inline-block rm-mt">Request authorisation</h2>
              </div>
              <div class="row clearfix" *ngIf="option == 'securityCode'">
                <mat-card>
                  <div class="cardheader primary-gradient-img">
                    <p class="lead rm-mt"><strong>1.</strong> Authorise settlement request</p>

                    <p>
                      An Authorisation code has been sent to your customer via
                      <strong *ngIf="emailSent == false && smsSent == true">SMS</strong>
                      <strong *ngIf="emailSent == true && smsSent == false">and Email</strong>

                      <strong *ngIf="emailSent == true && smsSent == true"> SMS and Email</strong>
                      , please get them to recite the code and enter it here.
                    </p>

                    <p>The code will be valid for {{ settings['securityCodeLifeTime'] || 60 }} min(s)</p>
                  </div>
                  <div class="clearfix">
                    <div class="well clearfix">
                      <mat-form-field class="full-width large-input rm-mb">
                        <mat-label>Enter authorisation code here</mat-label>
                        <input class="mat-block" [(ngModel)]="securityCode" matInput
                          placeholder="Enter authorisation code here" name="smsCode" required />
                      </mat-form-field>
                    </div>
                    <div class="full-width text-center">
                      <p class="small small">
                        Didn't recieve your code? Wait <strong>{{ timeLeft }}</strong> seconds and try sending again.
                      </p>
                      <button class="btn-small clearfix" [disabled]="timeLeft > 0" (click)="sendCodeAgain()"
                        mat-raised-button color="accent">
                        Send Authorisation Code Again
                      </button>
                    </div>
                  </div>
                </mat-card>
              </div>

              <div class="row clearfix" *ngIf="option == 'document'">
                <mat-card class="clearfix">
                  <div class="cardheader primary-gradient-img">
                    <p class="lead rm-mt"><strong>1.</strong> Authorise settlement request</p>

                    <p>
                      If you have given your customer an Authorisation form to sign, please scan it in and then upload
                      it
                      using the uploader below.
                    </p>
                  </div>
                  <div class="clearfix full-width">
                    <!-- <app-file-uploader></app-file-uploader> -->
                    <!--

                      <app-blob-view *ngIf="authorisationFormContent" class="full-width" [content]="authorisationFormContent"
                        [label]="'Authorisation Formfor '+selectedContract['Customer.FullName']" [extension]="'pdf'"></app-blob-view> -->

                    <label class="mb clearfix">Please upload the signed {{ "KEYWORD.patient" | translate }}
                      authorisation
                      form in the field below to
                      continue.
                    </label>

                    <app-file-uploader class="text-center clearfix" class="text-center" [uploadDirectly]="true"
                      [isDragable]="true" [limit]="1" (onCompleteAll)="onCompleteAllForm($event)"></app-file-uploader>

                    <app-file-list [hasExtraInfo]="false" [canEdit]="true" [canAction]="false"
                      [files]="[settlementfileID]" (remove)="removeAuthorisationForm($event)"></app-file-list>
                  </div>
                </mat-card>
              </div>

              <div class="row clearfix">
                <div class="full-width step-button-row text-center">
                  <button class="step-back" (click)="goBackChooseAuth()" mat-mini-fab color="primary">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                  </button>
                  <div class="pull-right">
                    <button class="step-next" class="btn-large" *ngIf="option == 'securityCode'"
                      [disabled]="!smsForm.valid" (click)="validateSettlementCode()" mat-raised-button color="accent">
                      Authorise Settlement
                      <mat-icon>chevron_right</mat-icon>
                    </button>

                    <button class="step-next" class="btn-large" *ngIf="option == 'document'"
                      [disabled]="!settlementfileID" (click)="requestSettlementByForm()" mat-raised-button
                      color="accent">
                      Authorise Settlement
                      <mat-icon>chevron_right</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <div class="row clearfix animated fadeInDown" id="step-3" *ngIf="isNeedAuth == false">
    <form #invoiceForm="ngForm">
      <mat-dialog-content style="   padding: 0 18px !important; ">
        <div class="full-width flex">
          <div class="full-width">
            <div class="rowclearfix" *ngIf="Settlement && Settlement['ID'] && Settlement['Status.Code'] != 'AWTR'">
              <div class="row clearfix" *ngIf="isPreTreatment == true">
                <h3 class="sr-title">Enter Settlement Details</h3>
                <p>Select the type of service to confirm.</p>
                <p>This will change the settlement status from <strong>Pending</strong> to <strong>Requested</strong>
                </p>
                <hr />
              </div>
              <div class="row clearfix" *ngIf="isPreTreatment != true">
                <h3 class="sr-title">Enter Settlement Details</h3>
                <p>
                  Enter the invoice reference number, select the type of service, date, and upload a copy of the invoice
                  document to confirm.
                </p>
                <p>This will change the settlement status from <strong>Pending</strong> to <strong>Requested</strong>
                </p>
                <hr />
              </div>
              <div class="clearfix full-width">
                <div class="row clearfix mt">
                  <mat-slide-toggle class="mb full-width" [color]="warn" [checked]="isPreTreatment"
                    [labelPosition]="before" (change)="isPreTreatmentToggle($event.checked)">
                    Is this settlement to cover the purchase of materials prior to treatment?
                  </mat-slide-toggle>
                </div>

                <div class="row clearfix flex">
                  <!-- Invoice Number -->
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Invoice Reference No</mat-label>
                    <input class="" [(ngModel)]="invoiceReference" [readonly]="isPreTreatment"
                      name="invoiceDReferenceon" matInput placeholder="" required />
                  </mat-form-field>

                  <mat-form-field class="full-width adjustSelect"
                    *ngIf="settings && settings['context'] &&  settings['context']!='p4p' && settings['context']!='breeze'"
                    appearance="outline">
                    <mat-label>{{ 'Type of service' | translate }}</mat-label>
                    <mat-select class="adjustSelect" [(ngModel)]="serviceType" placeholder="" name="serviceType">
                      <mat-option *ngFor="let s of serviceTypes | objectPropertyValue: 'Label'" [value]="s">
                        {{ s }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <!-- Invoice Date -->
                <!-- <label>Invoice date</label> -->
                <app-date-input class="thin full-width clearfix" [label]="'Invoice date:'" [minDate]="'today'"
                  (selectedDate)="selectInvoiceDate($event)" name="toDate"></app-date-input>
                <!-- <hr class="mb mt"> -->

                <div class="clearfix" *ngIf="isPreTreatment == false">
                  <h3 class="rm-mt sr-title">Invoice upload</h3>

                  <div class="row clearfix well">
                    <app-file-uploader class="text-center clearfix" class="text-center" [uploadDirectly]="true"
                      [isDragable]="true" [limit]="1" (onCompleteAll)="onCompleteAllInvoice($event)">
                    </app-file-uploader>
                  </div>
                  <div class="row clearfix">
                    <app-file-list class="compact" [hasExtraInfo]="false" [canEdit]="true" [canAction]="false"
                      [files]="[invoiceFileID]" (remove)="removeInvoiceFile($event)"></app-file-list>
                  </div>
                </div>
              </div>
            </div>

            <div class="row clearfix" *ngIf="Settlement && Settlement['ID'] && Settlement['Status.Code'] == 'AWTR'">
              <div class="row clearfix">
                <h3 class="sr-title">Re-upload Invoice Document</h3>
                <hr />
              </div>

              <div class="row clearfix" *ngIf="invoiceDoc">
                <h3 class="sr-title">Current invalid Document</h3>
                <app-ep-document-list [title]="'Settlement documents'" [documents]="[invoiceDoc]" [isDownload]="false"
                  [isPrint]="false"></app-ep-document-list>
                <hr />
              </div>

              <div class="row clearfix">
                <div class="clearfix" *ngIf="isPreTreatment == false">
                  <h3 class="rm-mt sr-title">Invoice upload</h3>

                  <div class="row clearfix well">
                    <app-file-uploader class="text-center clearfix" class="text-center" [uploadDirectly]="true"
                      [isDragable]="true" [limit]="1" (onCompleteAll)="onCompleteAllInvoice($event)">
                    </app-file-uploader>
                  </div>
                  <div class="row clearfix">
                    <app-file-list class="compact" [hasExtraInfo]="false" [canEdit]="true" [canAction]="false"
                      [files]="[invoiceFileID]" (remove)="removeInvoiceFile($event)"></app-file-list>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-dialog-content>
      <div class="row clearfix">
        <div class="full-width step-button row text-center">
          <!-- <button class="step-back" mat-mini-fab color="primary" awPreviousStep>
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button> -->
          <div class="pull-left" *ngIf="Settlement.ConsentedViaPaperForm == '0' && !Settlement.SecurityCode_key">
            <button class="step-next" class="" (click)="updateLater()" mat-raised-button color="accent">
              Enter Invoice later
            </button>
          </div>
          <div class="pull-right">
            <button class="step-next" class="" *ngIf="Settlement['Status.Code'] != 'AWTR'"
              [disabled]="!invoiceForm.valid || (!invoiceFileID && isPreTreatment == false)" (click)="createInvoice()"
              mat-raised-button color="accent">
              Confirm

              <mat-icon>chevron_right</mat-icon>
            </button>

            <button class="step-next" class="" *ngIf="Settlement['Status.Code'] == 'AWTR'" [disabled]="!invoiceFileID"
              (click)="reMarkRequest()" mat-raised-button color="accent">
              Confirm

              <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="full-width text-center clearfix mb row" *ngIf="isModal == false && Settlement['Status.Code'] != 'AWTR'">
    <button (click)="openSettlementDialog()" mat-raised-button color="primary">
      <mat-icon>search</mat-icon>
      <span class="mobHide">View Settlements</span>
    </button>

    <button (click)="openMerchantDialog()" mat-raised-button color="primary">
      <mat-icon>person</mat-icon>
      <span class="mobHide">View Merchant</span>
    </button>
    <button (click)="openContractDialog()" mat-raised-button color="primary">
      <mat-icon>assignment</mat-icon>
      <span class="mobHide">View Contract</span>
    </button>
  </div>
</mat-card>
