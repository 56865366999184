<div *ngIf="isPromoterOrAdmin == true">
  <div [hidden]="hideExtraComponents">
    <app-operator-filter
      class="dash"
      id="part2"
      [selectDefaultAccount]="true"
      [setAutoSelectMerchant]="true"
      [sendNull]="true"
      [selectDateRange]="selectedRange"
      [displayMerchant]="true"
      [displayDateFrom]="false"
      [displayDateTo]="false"
      [displayDentist]="false"
      [dateLabel]="'Requesting Date'"
      (getMerchant)="getMerchant($event)"
      (getDateTo)="getDateTo($event)"
      (getDateFrom)="getDateFrom($event)"
      (search)="applySearch($event)"
    ></app-operator-filter>
  </div>

  <div class="row clearfix padded" *ngIf="isPromoterOrAdmin && !_merchantID">
    <div class="pleaseChoose">
      <p>
        <strong>No Selected Merchant</strong>
      </p>
      <img class="character_warning" />
      <p>Choose Desired Merchant</p>
    </div>
  </div>

  <div class="row clearfix" *ngIf="!isPromoterOrAdmin || (isPromoterOrAdmin && _merchantID)">
    <div class="reduce-padding mb clearfix mat-elevation-z1 full-width white-background">
      <div class="toggle-card row clearfix full-width mb">
        <mat-button-toggle-group class="btn-group row clearfix flex full-width text-center" [(ngModel)]="statusGroup">
          <mat-button-toggle class="text-center full-width" [value]="'pending'">
            <mat-icon>calendar_today</mat-icon> Requested
          </mat-button-toggle>
          <mat-button-toggle class="text-center full-width" [value]="'waitingForConfirmation'">
            <mat-icon>alarm_off</mat-icon> Waiting for confirmation
          </mat-button-toggle>

          <mat-button-toggle class="text-center full-width" [value]="'booked'">
            <mat-icon>add_alarm</mat-icon> Booked
          </mat-button-toggle>
          <mat-button-toggle class="text-center full-width" [value]="'attended'">
            <mat-icon>check_circle</mat-icon> Attended
          </mat-button-toggle>
          <mat-button-toggle class="text-center full-width" [value]="'missed'">
            <mat-icon>av_timer</mat-icon> Missed
          </mat-button-toggle>
          <mat-button-toggle class="text-center full-width" [value]="'canceled'">
            <mat-icon>cancel</mat-icon> Canceled
          </mat-button-toggle>
          <mat-button-toggle class="text-center full-width" [value]="'all'">
            <mat-icon>list_alt</mat-icon> All
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div class="row clearfix full-width tabArea mt white-background">
        <app-appointment-list
          class="row clearfix animate"
          *ngIf="merchantID != 'none' && searchApplied == true && statusGroup == 'pending'"
          [statusGroup]="'pending'"
          [dentistID]="dentistID"
          [merchantID]="merchantID"
          [dateFrom]="dateFrom"
          [dateTo]="dateTo"
        >
        </app-appointment-list>

        <app-appointment-list
          class="row clearfix animate"
          *ngIf="merchantID != 'none' && searchApplied == true && statusGroup == 'waitingForConfirmation'"
          [statusGroup]="'waitingForConfirmation'"
          [dentistID]="dentistID"
          [merchantID]="merchantID"
          [dateFrom]="dateFrom"
          [dateTo]="dateTo"
        >
        </app-appointment-list>

        <app-appointment-list
          class="row clearfix animate"
          *ngIf="merchantID != 'none' && searchApplied == true && statusGroup == 'booked'"
          [statusGroup]="'booked'"
          [dentistID]="dentistID"
          [merchantID]="merchantID"
          [dateFrom]="dateFrom"
          [dateTo]="dateTo"
        >
        </app-appointment-list>

        <app-appointment-list
          class="row clearfix animate"
          *ngIf="merchantID != 'none' && searchApplied == true && statusGroup == 'attended'"
          [statusGroup]="'attended'"
          [dentistID]="dentistID"
          [merchantID]="merchantID"
          [dateFrom]="dateFrom"
          [dateTo]="dateTo"
        >
        </app-appointment-list>

        <app-appointment-list
          class="row clearfix animate"
          *ngIf="merchantID != 'none' && searchApplied == true && statusGroup == 'missed'"
          [statusGroup]="'missed'"
          [dentistID]="dentistID"
          [merchantID]="merchantID"
          [dateFrom]="dateFrom"
          [dateTo]="dateTo"
        >
        </app-appointment-list>

        <app-appointment-list
          class="row clearfix animate"
          *ngIf="merchantID != 'none' && searchApplied == true && statusGroup == 'canceled'"
          [statusGroup]="'canceled'"
          [dentistID]="dentistID"
          [merchantID]="merchantID"
          [dateFrom]="dateFrom"
          [dateTo]="dateTo"
        >
        </app-appointment-list>

        <app-appointment-list
          class="row clearfix animate"
          *ngIf="merchantID != 'none' && searchApplied == true && statusGroup == 'all'"
          [statusGroup]="null"
          [dentistID]="dentistID"
          [merchantID]="merchantID"
          [dateFrom]="dateFrom"
          [dateTo]="dateTo"
        >
        </app-appointment-list>
      </div>
    </div>
  </div>
  <div class="row clearfix">
    <div class="pleaseChoose" *ngIf="merchantID == 'none'" @ngIfAnimation>
      <p>
        <strong>No Merchant Selected</strong>
      </p>
      <img class="character_warning" />
      <p>
        Choose your desired merchant from the drop-down above to view their Appointments
      </p>
    </div>
  </div>
</div>

<div class="row full-width clearfix" *ngIf="isPromoterOrAdmin == false">
  <div [hidden]="hideExtraComponents">
    <app-operator-filter
      class="dash"
      id="part1"
      [setAutoSelectMerchant]="true"
      [sendNull]="true"
      [selectDateRange]="selectedRange"
      [displayMerchant]="false"
      [displayDateFrom]="false"
      [displayDateTo]="false"
      [displayDentist]="false"
      [dateLabel]="'Requesting Date'"
      (getMerchant)="getMerchant($event)"
      (getDateTo)="getDateTo($event)"
      (getDateFrom)="getDateFrom($event)"
      (search)="applySearch($event)"
    ></app-operator-filter>
  </div>

  <div class="row clearfix">
    <div class="reduce-padding mb clearfix mat-elevation-z1 full-width white-background">
      <div class="toggle-card row clearfix full-width mb">
        <mat-button-toggle-group class="btn-group row clearfix flex full-width text-center" [(ngModel)]="statusGroup">
          <mat-button-toggle class="text-center full-width" [value]="'pending'">
            <mat-icon>calendar_today</mat-icon> Requested
          </mat-button-toggle>
          <mat-button-toggle class="text-center full-width" [value]="'waitingForConfirmation'">
            <mat-icon>alarm_off</mat-icon> Waiting for confirmation
          </mat-button-toggle>

          <mat-button-toggle class="text-center full-width" [value]="'booked'">
            <mat-icon>add_alarm</mat-icon> Booked
          </mat-button-toggle>
          <mat-button-toggle class="text-center full-width" [value]="'attended'">
            <mat-icon>check_circle</mat-icon> Attended
          </mat-button-toggle>
          <mat-button-toggle class="text-center full-width" [value]="'missed'">
            <mat-icon>av_timer</mat-icon> Missed
          </mat-button-toggle>
          <mat-button-toggle class="text-center full-width" [value]="'canceled'">
            <mat-icon>cancel</mat-icon> Canceled
          </mat-button-toggle>
          <mat-button-toggle class="text-center full-width" [value]="'all'">
            <mat-icon>list_alt</mat-icon> All
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div class="row clearfix full-width tabArea mt white-background">
        <app-appointment-list
          class="row clearfix animate"
          *ngIf="searchApplied == true && statusGroup == 'pending'"
          [statusGroup]="'pending'"
          [dentistID]="dentistID"
          [dateFrom]="dateFrom"
          [dateTo]="dateTo"
        >
        </app-appointment-list>
        <app-appointment-list
          class="row clearfix animate"
          *ngIf="searchApplied == true && statusGroup == 'waitingForConfirmation'"
          [statusGroup]="'waitingForConfirmation'"
          [dentistID]="dentistID"
          [dateFrom]="dateFrom"
          [dateTo]="dateTo"
        >
        </app-appointment-list>

        <app-appointment-list
          class="row clearfix animate"
          *ngIf="searchApplied == true && statusGroup == 'booked'"
          [statusGroup]="'booked'"
          [dentistID]="dentistID"
          [dateFrom]="dateFrom"
          [dateTo]="dateTo"
        >
        </app-appointment-list>

        <app-appointment-list
          class="row clearfix animate"
          *ngIf="searchApplied == true && statusGroup == 'attended'"
          [statusGroup]="'attended'"
          [dentistID]="dentistID"
          [dateFrom]="dateFrom"
          [dateTo]="dateTo"
        >
        </app-appointment-list>

        <app-appointment-list
          class="row clearfix animate"
          *ngIf="searchApplied == true && statusGroup == 'missed'"
          [statusGroup]="'missed'"
          [dentistID]="dentistID"
          [dateFrom]="dateFrom"
          [dateTo]="dateTo"
        >
        </app-appointment-list>

        <app-appointment-list
          class="row clearfix animate"
          *ngIf="searchApplied == true && statusGroup == 'canceled'"
          [statusGroup]="'canceled'"
          [dentistID]="dentistID"
          [dateFrom]="dateFrom"
          [dateTo]="dateTo"
        >
        </app-appointment-list>

        <app-appointment-list
          class="row clearfix animate"
          *ngIf="searchApplied == true && statusGroup == 'all'"
          [statusGroup]="null"
          [dentistID]="dentistID"
          [dateFrom]="dateFrom"
          [dateTo]="dateTo"
        >
        </app-appointment-list>
      </div>
    </div>
  </div>
</div>
