<mat-card class="card full-width" *ngIf="tip">
  <div class="colour-stripe"></div>
  <button
    class="btn-close btn-clear white pull-right mat-button"
    *ngIf="isModal == true"
    (click)="closeModalEvent()"
    matDialogClose
  >
    <mat-icon>clear</mat-icon>
  </button>
  <div class="row clearfix">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Title</mat-label>
      <input class="text-center" id="cc-name" [(ngModel)]="tip.title" type="text" placeholder="Title" matInput/>
    </mat-form-field>

    <hr class="mb"/>
  </div>
  <div class="row clearfix mb text-center">
    <app-text-editor-input
      class="clearfix"
      [content]="content"
      [title]="null"
      [isAction]="false"
      [isPreview]="false"
      (getContent)="getContent($event)"
      name="editor"
    >
    </app-text-editor-input>
  </div>

  <mat-slide-toggle class="text-center" class="pull-left" [(ngModel)]="tip.isActive" labelPosition="before">
    Active
  </mat-slide-toggle>
  <p class="small rm-m pull-right">{{ tip.updated_at | TimeLabelPast }} ago</p>

  <div class="full-width text-center clearfix">
    <button
      class="btn btn-primary mt mb clearfix text-center"
      (click)="updateTip()"
      mat-raised-button
      type="button"
      color="primary"
    >
      Update
    </button>
  </div>
</mat-card>
