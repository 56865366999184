import { Component, Input, OnChanges, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { EMPTY_REPORT } from '../../../../../../shared/components/data-table/shared/data/data';
import { DataTablePrintModalComponent } from '../../../../../../shared/components/data-table/shared/modals/data-table-print-modal/data-table-print-modal.component';
import { DataTablePrintModalConfig } from '../../../../../../shared/components/data-table/shared/types/data-table.type';
import { CustomDatePipe } from '../../../../../../shared/pipes/custom-date.pipe';
import { SettlementDueSettlementsReportService } from '../../services/settlement-due-settlements-report.service';
import { DueSettlementsRecord, DueSettlementsReport } from '../../types/due-settlements-report.type';

@Component({
  selector: 'ipv-settlement-due-settlements-report-main',
  templateUrl: './settlement-due-settlements-report-main.component.html',
  styleUrls: ['./settlement-due-settlements-report-main.component.css'],
  providers: [CustomDatePipe],
})
export class SettlementDueSettlementsReportMainComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('treatmentValue', { static: false }) public treatmentValue: TemplateRef<any>;
  @ViewChild('dueAmount', { static: false }) public dueAmount: TemplateRef<any>;
  @ViewChild('typeCode', { static: false }) public typeCode: TemplateRef<any>;

  @Input() dueSettlementsReport: DueSettlementsReport;
  @Input() startDate: string;
  @Input() endDate: string;

  summaryReport: {
    fullTable: DueSettlementsRecord[];
    table: DueSettlementsRecord[];
    totalRecord: DueSettlementsRecord;
  } = { fullTable: [], table: [], totalRecord: null };

  constructor(
    private dialog: MatDialog,
    private customDatePipe: CustomDatePipe,
    private settlementDueSettlementsReportService: SettlementDueSettlementsReportService
  ) {}

  ngOnInit() {
    this.settlementDueSettlementsReportService
      .getPrintButtonClicked()
      .pipe(untilDestroyed(this))
      .subscribe((clicked) => {
        if (clicked) {
          this.openTablePrint();
        }
      });
  }

  ngOnChanges() {
    this.summaryReport =
      this.dueSettlementsReport && this.dueSettlementsReport.Date_Due && this.dueSettlementsReport.Date_Due.length > 0
        ? this.getDueSettlementsTables(this.dueSettlementsReport)
        : EMPTY_REPORT;

    const recordsCount = this.summaryReport.table.length;
  }

  ngOnDestroy() {
    // required for untilDestroyed();
  }

  getDueSettlementsTables(dueSettlementsReport: DueSettlementsReport): {
    fullTable: DueSettlementsRecord[];
    table: DueSettlementsRecord[];
    totalRecord: DueSettlementsRecord;
  } {
    const table: DueSettlementsRecord[] = [];

    const totalDueAmount = dueSettlementsReport.Total_Due_Amount;

    const recordsLength = dueSettlementsReport.Date_Due.length;

    for (let index = 0; index < recordsLength; index++) {
      const record: DueSettlementsRecord = {
        date: dueSettlementsReport.Date_Due[index],
        merchant: dueSettlementsReport.Merchant_Name[index],
        patient: dueSettlementsReport.Patient_Name[index],
        product: dueSettlementsReport.Product_Label[index],
        treatmentValue: dueSettlementsReport.Treatment_Value[index],
        typeCode: dueSettlementsReport.Type_Code[index],
        dueAmount: dueSettlementsReport.Due_Amount[index],
      };

      table.push(record);
    }

    table.sort((first, second) => (first.date > second.date ? 1 : second.date > first.date ? -1 : 0));

    const totalRecord: DueSettlementsRecord = {
      date: 'Total',
      merchant: '',
      patient: '',
      product: '',
      treatmentValue: 0,
      typeCode: '',
      dueAmount: totalDueAmount,
    };

    return {
      fullTable: table.concat(totalRecord),
      table,
      totalRecord,
    };
  }

  openTablePrint() {
    this.dialog.open<DataTablePrintModalComponent, DataTablePrintModalConfig>(DataTablePrintModalComponent, {
      data: {
        table: this.summaryReport.fullTable,
        customComponents: {
          treatmentValue: this.treatmentValue,
          dueAmount: this.dueAmount,
          typeCode: this.typeCode,
        },
        title:
          'Settlements Due Report from ' + this.formatDate(this.startDate) + ' to ' + this.formatDate(this.endDate),
        formatHeaders: {
          treatmentValue: 'right',
          dueAmount: 'right',
          typeCode: 'right',
        },
        rename: {
          treatmentValue: 'Treatment Value',
          dueAmount: 'Amount Due',
          typeCode: 'Code',
        },
      },
      width: '800px',
    });
  }

  formatDate(date: string) {
    return this.customDatePipe.transform(date);
  }
}
