<div class="rel">
  <!-- <div class="float-close"> -->
  <!-- Modal dismiss button -->
  <!-- <button class="btn-close btn-clear mat-button" matDialogClose>
      <mat-icon>clear</mat-icon>
    </button>
  </div> -->
  <app-existing-patient-view-details [existingPatientID]="existingPatientID"></app-existing-patient-view-details>
  <!-- <app-existing-patient-view [existingPatientID]="existingPatientID"></app-existing-patient-view> -->
</div>
