import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { AppointmentViewComponent } from '../appointment-view/appointment-view.component';
import { AppointmentUtilClass } from '../shared/appointmentUtil';

@Component({
  selector: 'app-schedule-appointment-time-card',
  templateUrl: './schedule-appointment-time-card.component.html',
  styleUrls: ['./schedule-appointment-time-card.component.css'],
})
export class ScheduleAppointmentTimeCardComponent implements OnInit {
  @Input() appointment;
  @Output() cancelMockAppointment = new EventEmitter();

  appointmentDuration;
  appointmentStart: moment.Moment;
  appointmentEnd: moment.Moment;
  appointmentBufferBefore = 0;
  appointmentBufferAfter = 0;
  totalBuffer;
  sessionType;
  appointmentUtil = new AppointmentUtilClass();
  runAction = new EventEmitter();

  constructor(private elRef: ElementRef, private authenticationService: AuthenticationService) {}

  async ngOnInit() {
    this.sessionType = await this.authenticationService.getSessionType().toPromise();
    this.appointmentStart = moment(`${this.appointment.Date_Booked} ${this.appointment.Time}`, 'YYYY-MM-DD h:mma');
    this.appointmentEnd = moment(`${this.appointment.Date_Booked} ${this.appointment.Time_End}`, 'YYYY-MM-DD h:mma');
    this.appointmentDuration = this.appointmentEnd.diff(this.appointmentStart, 'minute');

    this.appointmentBufferBefore = Number(this.appointment.Buffers_Pre);
    this.appointmentBufferAfter = Number(this.appointment.Buffers_Post);

    this.totalBuffer = this.appointmentBufferBefore + this.appointmentBufferAfter;

    // scroll into view for mocked appointment
    if (this.appointment.ID === 'mock') {
      this.elRef.nativeElement.parentNode.scrollTop = this.elRef.nativeElement.offsetTop - 12;
    }
  }

  runActionEvent(item, operation, isGroup = false) {
    if (item) {
      const p = {
        item,
        operation,
        isGroup,
      };
      this.runAction.emit(p);
    }
  }

  getActionResult(r) {
    if (r && r.ID) {
      this.appointment = r;
      // this.updateAppointment.emit(r);
    }
  }

  openQuickViewDialog() {
    const ref = RootAppComponent.dialog.open(AppointmentViewComponent, {
      data: {
        appointmentID: this.appointment.ID,
      },
      width: '900px',
      height: '90%',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }
}
