<ipv-container-modal>
  <div banner>
    <div class="flex" style="justify-content: space-between">
      <div class="flex full-width">
        <div style="display: flex; flex-direction: column">
          <h2 class="summary-header rm-m heading-1">{{'View Invoice'}}</h2>
          <div class="subLabel" style="padding-left: 10px">Invoice: {{productInvoice['Invoice_Number']}}</div>
        </div>
      </div>

      <button class="btn-close btn-clear mat-button white" (click)="close()">
        <mat-icon style="position: relative; top: -25px">clear</mat-icon>
      </button>
    </div>
    <div class="flex full-width" style="justify-content: flex-end"></div>
  </div>

  <div content>
    <ng-container [ngSwitch]="industryType">
      <ipv-retail-view-product-invoice
        *ngSwitchCase="'retail'"
        [invoiceAppliance]="productInvoice"
      ></ipv-retail-view-product-invoice>
      <ipv-retail-view-product-invoice
        *ngSwitchCase="'breeze'"
        [invoiceAppliance]="productInvoice"
      ></ipv-retail-view-product-invoice>
      <ipv-dental-view-product-invoice
        *ngSwitchCase="'dental'"
        [invoiceAppliance]="productInvoice"
      ></ipv-dental-view-product-invoice>
    </ng-container>
  </div>
</ipv-container-modal>
