import { Component, EventEmitter, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';

@Component({
  selector: 'app-ep-document-wakanda-create',
  templateUrl: './ep-document-wakanda-create.component.html',
  styleUrls: ['./ep-document-wakanda-create.component.css'],
})
export class EpDocumentWakandaCreateComponent implements OnInit {
  close = new EventEmitter();

  getResult = new EventEmitter();
  contractID;
  merchantID;
  invitationID;
  customerID;
  customerName;
  label;
  description;
  extension;
  link;
  isPromoterOrAdmin = false;
  type;
  types = [
    { Label: 'Card - Images', TypeCode: 'CrdImage' },
    { Label: 'Customer Statement', TypeCode: 'CSTA' },
    { Label: 'Customer Statement', TypeCode: 'CSTA' },
    { Label: 'DDR', TypeCode: 'DDA' },
    { Label: 'Document Gallery Video', TypeCode: 'DGVIDEO' },
    { Label: 'Drivers License', TypeCode: 'IDL' },
    { Label: 'Executed Loan Contract', TypeCode: 'CLN' },
    { Label: 'Executed Payment Plan Contract', TypeCode: 'CPP' },
    { Label: 'ID Card', TypeCode: 'IDC' },
    { Label: 'Image Gallery Video', TypeCode: 'IGVIDEO' },
    { Label: 'Images', TypeCode: 'APT' },
    { Label: 'Invoices', TypeCode: 'INV' },
    { Label: 'MW-CC-BatchFile', TypeCode: 'MWCC_S' },
    { Label: 'Medical Record - Correspondenc', TypeCode: 'MEDR_C' },
    { Label: 'Medical Record - Images', TypeCode: 'MEDR_I' },
    { Label: 'Medical Record - Referrals', TypeCode: 'MEDR_R' },
    { Label: 'Medical Record - Treatment Pla', TypeCode: 'MEDR_T' },
    { Label: 'Medical Record - Videos', TypeCode: 'MEDR_V' },
    { Label: 'Medical Record - Xrays', TypeCode: 'MEDR_X' },
    { Label: 'Medicare Card', TypeCode: 'IDM' },
    { Label: 'Merchant - Settlement', TypeCode: 'MSttlmt' },
    { Label: 'Merchant Note - Document', TypeCode: 'MERN_D' },
    { Label: 'Merchant Note - Image', TypeCode: 'MERN_I' },
    { Label: 'Merchant Note - PDF', TypeCode: 'MERN_P' },
    { Label: 'Merchant Note - Spreadsheet', TypeCode: 'MERN_S' },
    { Label: 'Merchant Note - Videos', TypeCode: 'MERN_V' },
    { Label: 'Passport', TypeCode: 'IDP' },
    { Label: 'Patient Acknowledgement', TypeCode: 'CUSACK' },
    { Label: 'Personal Circumstances', TypeCode: 'PRCCRC' },
    { Label: 'Personal Circumstances', TypeCode: 'PRCCRC' },
    { Label: 'Proceedure Gallery', TypeCode: 'SRVSTC' },
    { Label: 'Promoter Note - Document', TypeCode: 'PRMN_D' },
    { Label: 'Promoter Note - Image', TypeCode: 'PRMN_I' },
    { Label: 'Promoter Note - PDF', TypeCode: 'PRMN_P' },
    { Label: 'Promoter Note - Spreadsheet', TypeCode: 'PRMN_S' },
    { Label: 'Promoter Note - Videos', TypeCode: 'PRMN_V' },
    { Label: 'Proof Of Employment', TypeCode: 'POE' },
    { Label: 'Proof Of Identity', TypeCode: 'PID' },
    { Label: 'Proof of Residential Address', TypeCode: 'PRA' },
    { Label: 'Rescheduling Audit', TypeCode: 'RschAudit' },
    { Label: 'Settlement Request', TypeCode: 'STLAUT' },
    { Label: 'Signed Informed Consent', TypeCode: 'CUS_SIC' },
    { Label: 'Treatment Info', TypeCode: 'SRV' },
    { Label: 'Treatment Plan', TypeCode: 'TRP' },
    { Label: 'Video', TypeCode: 'VIDEO' },
  ];

  sessionType;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private authenticationService: AuthenticationService,
    private utilService: UtilsService
  ) {
    if (data) {
      if (data.contractID) {
        this.contractID = data.contractID;
      }
      if (data.link) {
        this.link = data.link;
      }
      if (data.merchantID) {
        this.merchantID = data.merchantID;
      }

      if (data.invitationID) {
        this.invitationID = data.invitationID;
      }

      if (data.customerID) {
        this.customerID = data.customerID;
      }

      if (data.customerName) {
        this.customerName = data.customerName;
      }
      if (data.extension) {
        this.extension = data.extension;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
      this.isPromoterOrAdmin = isPromoterOrAdmin;
      this.authenticationService.getSessionType().subscribe((r) => {
        this.sessionType = r;
      });
    });
  }

  closeModal() {
    this.close.emit(true);
  }

  changeType() {
    if (this.type) {
      const index = this.types.findIndex((item) => {
        if (item && item['TypeCode'] == this.type) {
          return true;
        }

        return false;
      });

      if (index != -1) {
        const o = this.types[index]['Label'];

        if (o && this.customerName) {
          this.label = o + ' - ' + this.customerName;
        } else if (o) {
          this.label = o;
        }
      }
    }
  }

  save() {
    if (this.label && this.type) {
      let payload = {};

      payload = {
        fields:
          'AWS_URL,AWS_Bucket_Name,DocumentType_Code,DateAdded,DateTimeCreated,Description,Extension,ID,Label,URL.Complete,Tags,ContainsSensitiveData,IsGallery',
        label: this.label,
        description: this.description,
        type: this.type,
        contractID: this.contractID,
        invitationID: this.invitationID,
        customerID: this.customerID,
        merchantID: this.merchantID,
        cardID: this.customerID,
        extension: this.extension,
        fileLink: this.link,
      };

      this.utilService.createEpdocument(payload, true).subscribe((res) => {
        if (res) {
          this.getResult.emit(res);
          NotifyAppComponent.displayToast(
            'success',
            'Successful Operation',
            'You have successfully created a document!'
          );
        }
      });
    }
  }
}
