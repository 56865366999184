import { Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { filter } from 'lodash';
import { FileService } from '../../../shared/services/file.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { ThemeService } from '../shared/theme.service';

@Component({
  selector: 'app-theme-create',
  templateUrl: './theme-create.component.html',
  styleUrls: ['./theme-create.component.css'],
})
export class ThemeCreateComponent implements OnInit, OnChanges {
  @Input()
  themeID = null;

  theme = {
    name: null,
    code: null,
    file: null,
  };
  fileLimit = 1;
  themeScssFile = [];
  uploadAll = new EventEmitter();
  close = new EventEmitter();
  themes = [];
  selectedTheme = null;
  themeScssFileId = null;
  scssFileType = 'default';
  displayPalette = true;
  colorVariables = [];
  fileUploaded = false;
  description = null;
  branding = {
    background: null,

    backgroundConsumer: null,
    backgroundFunder: null,
    backgroundSupplier: null,
    backgroundPromoter: null,

    logo: null,
    icon: null,
    loadingGid: null,
    userDataLoadingGif: null,
  };

  allowedExtensionTypes = ['image'];
  defaultBranding = {
    background: null,

    backgroundConsumer: null,
    backgroundFunder: null,
    backgroundSupplier: null,
    backgroundPromoter: null,

    logo: null,
    icon: null,
    loadingGid: null,
    userDataLoadingGif: null,
  };

  isFileUploaded = false;
  newVariable = {
    isPalette: true,
    name: null,
    palette: null,
    color: '#000',
  };
  variableNameLabel = '<%= variableName %>';
  newChartVariable = '#000';
  colorChartsVariables = Settings.global['chartColors'];
  tmpColorChartsVariables = Settings.global['chartColors'] || [];
  showGridLines = false;

  overrideFile = false;
  fileUrl;
  fileID;
  simpleData2 = [
    {
      name: 'Germany',
      value: 40632,
    },
    {
      name: 'United States',
      value: 49737,
    },
    {
      name: 'France',
      value: 36745,
    },
    {
      name: 'United Kingdom',
      value: 36240,
    },
  ];

  simpleData = [
    {
      name: 'Germany',
      value: 40632,
    },
    {
      name: 'United States',
      value: 49737,
    },
    {
      name: 'France',
      value: 36745,
    },
    {
      name: 'United Kingdom',
      value: 36240,
    },
    {
      name: 'Spain',
      value: 33000,
    },
    {
      name: 'Italy',
      value: 35800,
    },
    {
      name: 'Australia',
      value: 47000,
    },
    {
      name: 'Morocco',
      value: 21000,
    },
    {
      name: 'Poland',
      value: 39150,
    },
    {
      name: 'Russia',
      value: 42920,
    },
    {
      name: 'China',
      value: 90000,
    },
    {
      name: 'Egypt',
      value: 43000,
    },
    {
      name: 'Spain',
      value: 42920,
    },
  ];

  AdvancedData = [
    {
      name: 'Germany',
      series: [
        {
          name: '2010',
          value: 40632,
        },
        {
          name: '2000',
          value: 36953,
        },
        {
          name: '1990',
          value: 31476,
        },
        {
          name: '1980',
          value: 22500,
        },
        {
          name: '1970',
          value: 34000,
        },
        {
          name: '1960',
          value: 15000,
        },
      ],
    },
    {
      name: 'United States',
      series: [
        {
          name: '2010',
          value: 49737,
        },
        {
          name: '2000',
          value: 45986,
        },
        {
          name: '1990',
          value: 30060,
        },
        {
          name: '1980',
          value: 33500,
        },
        {
          name: '1970',
          value: 14000,
        },
        {
          name: '1960',
          value: 19000,
        },
      ],
    },
    {
      name: 'France',
      series: [
        {
          name: '2010',
          value: 36745,
        },
        {
          name: '2000',
          value: 34774,
        },
        {
          name: '1990',
          value: 21476,
        },
        {
          name: '1980',
          value: 12500,
        },
        {
          name: '1970',
          value: 24000,
        },
        {
          name: '1960',
          value: 19500,
        },
      ],
    },
    {
      name: 'United Kingdom',
      series: [
        {
          name: '2010',
          value: 36240,
        },
        {
          name: '2000',
          value: 32543,
        },
        {
          name: '1990',
          value: 20424,
        },
        {
          name: '1980',
          value: 20500,
        },
        {
          name: '1970',
          value: 31000,
        },
        {
          name: '1960',
          value: 18000,
        },
      ],
    },
  ];

  currentIconUrl;
  imageArray = [];
  characterArray = [];
  settings = Settings.global;

  constructor(
    private themeService: ThemeService,
    private activeRoute: ActivatedRoute,
    private fileService: FileService,
    private router: Router
  ) {}

  ngOnInit() {
    const without = [
      'BrandingBackgroundImageMerchant',
      'BrandingBackgroundImageConsumer',
      'BrandingBackgroundImagePromoter',
      'BrandingBackgroundImageSupplier',
      'BrandingBackgroundImageFunder',
      'BrandingLogoImage',
    ];

    if (Settings) {
      this.currentIconUrl = Settings.getIcon();
      this.imageArray = Settings.getImagesArray();
      this.characterArray = Settings.getCharacterArray();

      this.characterArray = filter(this.characterArray, (item) => {
        if (item && item.id && without.indexOf(item.id) !== -1) {
          return false;
        }

        return true;
      });
    }

    this.activeRoute.params.subscribe((params) => {
      if (params['id']) {
        this.themeID = params['id'] || null;
      }

      this.setup();
    });
  }

  getImageById(id) {
    for (let i = 0; i < this.imageArray.length; i++) {
      if (this.imageArray[i]['id'] == id) {
        return this.imageArray[i]['source'];
      }
    }

    return null;
  }

  setup() {
    this.themeScssFile = [this.settings['ThemeDefaultScssFile']];

    this.colorVariables = this.settings['themeDefaultScssColors'];

    this.themeScssFileId = this.settings['ThemeDefaultScssFile'];

    this.fileID = this.settings['ThemeDefaultScssFile'];

    this.themeService.getThemeSourceList().subscribe((res) => {
      if (res && res.length > 0) {
        this.themes = res;
      }
    });

    if (this.themeID) {
      this.themeService.getThemeDetails(this.themeID).subscribe((res) => {
        if (res) {
          this.scssFileType == 'existed';
          this.selectedTheme = res;
          this.tmpColorChartsVariables = res['chartColors'];
          this.theme.name = res.name;
          this.theme.code = res.code;
          this.description = res.description;
          this.selectTheme();
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {}

  selectTheme() {
    if (this.selectedTheme && this.selectedTheme['scssColors'] && this.selectedTheme['scssFileId']) {
      if (this.selectedTheme['scssFileId']) {
        this.colorVariables = JSON.parse(JSON.stringify(this.selectedTheme['scssColors']));
        this.themeScssFile = [this.selectedTheme['scssFileId']];
        this.themeScssFileId = JSON.parse(JSON.stringify(this.selectedTheme['scssFileId']));
        this.fileID = JSON.parse(JSON.stringify(this.selectedTheme['scssFileId']));
        this.branding.background = this.selectedTheme['backgroundId'];

        if (this.selectedTheme['backgroundConsumerId']) {
          this.branding.backgroundConsumer = this.selectedTheme['backgroundConsumerId'];
        } else if (this.selectedTheme['backgroundId']) {
          this.branding.backgroundConsumer = this.selectedTheme['backgroundId'];
        }

        if (this.selectedTheme['backgroundFunderId']) {
          this.branding.backgroundFunder = this.selectedTheme['backgroundFunderId'];
        } else if (this.selectedTheme['backgroundId']) {
          this.branding.backgroundFunder = this.selectedTheme['backgroundId'];
        }

        if (this.selectedTheme['backgroundSupplierId']) {
          this.branding.backgroundSupplier = this.selectedTheme['backgroundSupplierId'];
        } else if (this.selectedTheme['backgroundId']) {
          this.branding.backgroundSupplier = this.selectedTheme['backgroundId'];
        }

        if (this.selectedTheme['backgroundPromoterId']) {
          this.branding.backgroundPromoter = this.selectedTheme['backgroundPromoterId'];
        } else if (this.selectedTheme['backgroundId']) {
          this.branding.backgroundPromoter = this.selectedTheme['backgroundId'];
        }

        this.branding.icon = this.selectedTheme['iconId'];
        this.branding.logo = this.selectedTheme['logoId'];

        for (let i = 0; i < this.characterArray.length; i++) {
          if (this.selectedTheme[this.characterArray[i]['id']]) {
            this.characterArray[i]['fileId'] = this.selectedTheme[this.characterArray[i]['id']];
          }
        }
      } else {
        NotifyAppComponent.displayToast('error', 'Error', 'theme does not have a SCSS file');
      }
    } else {
      NotifyAppComponent.displayToast('error', 'Error', 'theme does not have a SCSS file');
    }
  }

  changeType(v) {
    if (v == 'default') {
      this.themeScssFile = [this.settings['ThemeDefaultScssFile']];
      this.themeScssFileId = this.settings['ThemeDefaultScssFile'];
      this.fileID = this.settings['ThemeDefaultScssFile'];

      this.colorVariables = this.settings['themeDefaultScssColors'];
    }
  }

  addTheme() {
    const palette = {};
    let createdObject = {};

    if (this.scssFileType == 'newSCSS') {
      for (let i = 0; i < this.colorVariables.length; i++) {
        palette[this.colorVariables[i]['name']] = this.colorVariables[i]['palette'];
      }

      createdObject = {
        description: this.description,
        chartColors: this.colorChartsVariables,
        name: this.theme.name,
        code: this.theme.code,
        fileId: this.fileID || null,
        scssVariables: palette,
        scssColors: this.colorVariables,
        backgroundId: this.branding.background,

        backgroundSupplierId: this.branding.backgroundSupplier,
        backgroundFunderId: this.branding.backgroundFunder,
        backgroundConsumerId: this.branding.backgroundConsumer,
        backgroundPromoterId: this.branding.backgroundPromoter,

        iconId: this.branding.icon,
        logoId: this.branding.logo,
      };

      for (let i = 0; i < this.characterArray.length; i++) {
        if (this.characterArray[i]['fileId']) {
          createdObject[this.characterArray[i]['id']] = this.characterArray[i]['fileId'];
        }
      }
    } else {
      createdObject = {
        description: this.description,
        chartColors: this.colorChartsVariables,
        name: this.theme.name,
        code: this.theme.code,
        fileId: this.fileID || null,
        backgroundId: this.branding.background,

        backgroundSupplierId: this.branding.backgroundSupplier,
        backgroundFunderId: this.branding.backgroundFunder,
        backgroundConsumerId: this.branding.backgroundConsumer,
        backgroundPromoterId: this.branding.backgroundPromoter,

        iconId: this.branding.icon,
        logoId: this.branding.logo,
      };
    }

    for (let i = 0; i < this.characterArray.length; i++) {
      if (this.characterArray[i]['fileId']) {
        createdObject[this.characterArray[i]['id']] = this.characterArray[i]['fileId'];
      }
    }

    this.themeService.addTheme(createdObject).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast('success', 'Successed', 'theme is successfully added!');

        this.theme = {
          name: null,
          code: null,
          file: null,
        };

        this.selectedTheme = null;
        this.themeScssFileId = null;
        this.colorVariables = [];
        this.fileUploaded = false;
        this.scssFileType = 'newSCSS';
        this.isFileUploaded = false;
        this.newVariable = {
          isPalette: true,
          name: null,
          palette: null,
          color: '#000',
        };
        this.router.navigate(['/admin', { outlets: { adminPageRoute: ['theme'] } }]);
        this.close.emit(true);
      }
    });
  }

  removeColor(index) {
    if (this.colorVariables[index]) {
      this.colorVariables.splice(index, 1);
    }
  }

  removeCharColor(index) {
    if (this.tmpColorChartsVariables[index]) {
      this.tmpColorChartsVariables.splice(index, 1);
      this.showGridLines = !this.showGridLines;
    }
  }

  getNewCoreColor(color) {
    this.newVariable.color = color;
  }

  getNewColorPalette(data) {
    this.newVariable.palette = data;
  }

  addNewVariable() {
    if (this.newVariable.color && this.newVariable.name) {
      this.colorVariables.push(JSON.parse(JSON.stringify(this.newVariable)));
      this.newVariable.name = null;
    }
  }

  editCoreColor(color, index) {
    if (this.colorVariables[index] && this.colorVariables[index].color) {
      this.colorVariables[index].color = color;
    }
  }

  editColorPalette(data, index) {
    if (this.colorVariables[index] && data) {
      this.colorVariables[index].palette = data;
    }
  }

  onCompleteAll(file) {
    if (file && file[0]) {
      this.fileID = file[0];
    }
  }

  addExistedTheme() {
    const palette = {};
    let createdObject: any = {};

    for (let i = 0; i < this.colorVariables.length; i++) {
      palette[this.colorVariables[i]['name']] = this.colorVariables[i]['palette'];
    }

    createdObject = {
      description: this.description,
      chartColors: this.colorChartsVariables,
      name: this.theme.name,
      code: this.theme.code,
      fileId: this.fileID || null,
      scssVariables: palette,
      scssColors: this.colorVariables,
      backgroundId: this.branding.background,

      backgroundSupplierId: this.branding.backgroundSupplier,
      backgroundFunderId: this.branding.backgroundFunder,
      backgroundConsumerId: this.branding.backgroundConsumer,
      backgroundPromoterId: this.branding.backgroundPromoter,

      iconId: this.branding.icon,
      logoId: this.branding.logo,
    };

    for (let i = 0; i < this.characterArray.length; i++) {
      if (this.characterArray[i]['fileId']) {
        createdObject[this.characterArray[i]['id']] = this.characterArray[i]['fileId'];
      }
    }

    if (this.overrideFile !== true) {
      createdObject.fileId = null;
    }

    if (this.themeID == null) {
      this.themeService.addTheme(createdObject).subscribe((res) => {
        if (res) {
          NotifyAppComponent.displayToast('success', 'Successed', 'theme is successfully added!');

          this.router.navigate(['/admin', { outlets: { adminPageRoute: ['theme'] } }]);
        }
      });
    } else {
      createdObject.oldThemeID = this.themeID;

      this.themeService.addTheme(createdObject).subscribe((res) => {
        if (res) {
          NotifyAppComponent.displayToast('success', 'Successed', 'theme is successfully updated!');

          this.router.navigate(['/admin', { outlets: { adminPageRoute: ['theme'] } }]);
        }
      });
    }
  }

  onCompleteCharacter(file, index) {
    if (file[0]) {
      this.characterArray[index]['fileId'] = file[0];
    }
  }

  onCompletebranding(file, attribute) {
    if (file[0]) {
      this.branding[attribute] = file[0];
    }
  }

  getFileLink(fileID) {
    return environment.nodeUrl + '/files/file-view/' + fileID;
  }

  isUploaded(res) {
    this.fileUploaded = res;
  }

  fileQueued(n) {
    if (!n || n <= 0) {
      this.isFileUploaded = false;
    } else {
      this.isFileUploaded = true;
    }
  }

  editChartCoreColor(color, index) {
    if (this.tmpColorChartsVariables[index]) {
      this.tmpColorChartsVariables[index] = color;
    }
  }

  getNewChartsCoreColor(color) {
    this.newChartVariable = color;
  }

  ApplyChartColors() {
    this.colorChartsVariables = this.tmpColorChartsVariables;
    this.showGridLines = !this.showGridLines;
  }

  addNewChartVariable() {
    if (this.newChartVariable) {
      this.tmpColorChartsVariables.push(this.newChartVariable);
    }
  }

  changeThemeName() {
    if (this.theme && this.theme.name) {
      this.theme.code = this.theme.name.replace(/ /g, '_');
    }
  }
}
