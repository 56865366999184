import { of } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { catchError, map } from 'rxjs/operators';

import { AuthenticationService } from '../../../../common/core/authentication/shared/authentication.service';

@Injectable()
export class MerchantAuthGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let activatedRouteSnapshot = route;
    while (activatedRouteSnapshot.firstChild) {
      activatedRouteSnapshot = activatedRouteSnapshot.firstChild;
    }

    let url = null;

    if (state && state.url) {
      url = state.url;
    }
    return this.authService.getCurrentUser().pipe(
      map((user) => {
        if (user && user.success) {
          let role = null;

          let hasAccess = true;

          if (user && user.data && user.data.Role) {
            role = user.data.Role;
          }

          if (user && user.data && user.data.Revoke_Access_Main_App === '1') {
            hasAccess = false;
          }

          if (
            (role === 'merchant-admin' || role === 'merchant' || role === 'admin' || role === 'promoter') &&
            hasAccess == true
          ) {
            if (state && state.url && state.url.indexOf('/login') !== -1) {
              this.router.navigate(['/merchant/']).then();
            } else {
              return true;
            }
          } else {
            if (
              state &&
              state.url &&
              (state.url.indexOf('/login') !== -1 || state.url.indexOf('/login-admin') !== -1)
            ) {
              return true;
            } else {
              if (url) {
                const finalUrl = url.replace(/\//g, '$');

                this.router.navigate(['/login/' + finalUrl]).then();
              } else {
                this.router.navigate(['/login']).then();
              }
            }
          }
        } else {
          if (state && state.url && (state.url.indexOf('/login') !== -1 || state.url.indexOf('/login-admin') !== -1)) {
            return true;
          } else {
            if (url) {
              const finalUrl = url.replace(/\//g, '$');

              this.router.navigate(['/login/' + finalUrl]).then();
            } else {
              this.router.navigate(['/login']).then();
            }
          }
        }
      }),
      catchError(() => {
        if (state.url.indexOf('/login') !== -1 || state.url.indexOf('/login-admin') !== -1) {
          return of(true);
        } else {
          if (url) {
            const finalUrl = url.replace(/\//g, '$');

            this.router.navigate(['/login/' + finalUrl]).then();
            return of(false);
          } else {
            this.router.navigate(['/login']).then();
            return of(false);
          }
        }
      })
    );
  }
}
