import { BehaviorSubject, merge as observableMerge, Observable, Subject } from 'rxjs';

import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';
import { map } from 'rxjs/operators';

import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { MapViewComponent } from '../../../shared/components/map-view/map-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { OperatorChangePasswordComponent } from '../operator-change-password/operator-change-password.component';
import { OperatorViewComponent } from '../operator-view/operator-view.component';
import { OperatorService } from '../shared/operator.service';
import { PasswordPromptComponent } from '../password-prompt/password-prompt.component';

@Component({
  selector: 'app-operator-list',
  templateUrl: './operator-list.component.html',
  styleUrls: ['./operator-list.component.css'],
})
export class OperatorListComponent implements OnInit {
  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  @Input()
  utimateTables;

  rows = [];
  subjectArray = [];
  displayedColumns = [
    'ProfileImage',
    'CalculatedName',
    'UserName',
    // "TableName",
    'UltimateTableName',
    // "Role",
    // "Card_key",
    'IsAdministrator',
    'Enabled',
    'Actions',
  ];

  filteredSize = null;
  sessionType;
  currentUser;

  destroyEvent = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;
  public sessionDB: LoadOperators | null;
  dataSource: OperatorDataSource | null;
  @ViewChild('input', { static: false }) input;
  priority = new Subject<any>();

  constructor(
    private operatorService: OperatorService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((st) => {
      this.sessionType = st;

      const p = {
        utimateTables: this.utimateTables,
      };

      this.authenticationService.getCurrentUser().subscribe((res) => {
        if (res) {
          this.currentUser = res.data;
        }
        this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
          if (isPromoterOrAdmin == true) {
            this.sessionDB = new LoadOperators(this.operatorService, this.destroyEvent, p);
            this.dataSource = new OperatorDataSource(this.sessionDB, this.paginator, this.sort);
          } else {
            this.router.navigate(['/merchant']);
          }
        });
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const p = {
      utimateTables: this.utimateTables,
    };

    this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
      if (isPromoterOrAdmin == true) {
        this.sessionDB = new LoadOperators(this.operatorService, this.destroyEvent, p);
        this.dataSource = new OperatorDataSource(this.sessionDB, this.paginator, this.sort);
      } else {
        this.router.navigate(['/merchant']);
      }
    });
  }

  setFilter(event, field) {
    let filter;

    if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  displayMap(geolocation) {
    const ref = RootAppComponent.dialog.open(MapViewComponent, {
      data: geolocation,

      panelClass: 'modal-map',
      width: '800px',
      height: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  openQuickViewDialog(item) {
    const ref = RootAppComponent.dialog.open(OperatorViewComponent, {
      data: item.ID,
      width: '600px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  displayContact(item) {
    let operatorType;
    let cardID = item['Card_key'];
    if (item['UltimateTableName'] == 'MerchantProspect') {
      operatorType = 'operator';
      cardID = item['ID'];
    } else if (item['UltimateTableName'] == 'Promoter' || item['UltimateTableName'] == 'Merchant') {
      operatorType = 'contact';
    }

    const data = {
      targetType: operatorType,
      targetID: cardID,
      asGuest: false,
      asProfile: true,
      asPractice: true,
      subjectArray: this.subjectArray,
    };
    AuthenticationService.contactInputEvent.emit(data);
  }

  displayInfo(item) {
    if (item['UltimateTableName'] == 'MerchantProspect') {
      this.router.navigate(['/merchant', { outlets: { page: ['merchant-prospect-profile', item['cardKey']] } }]);
    } else if (item['UltimateTableName'] == 'Promoter') {
      this.authenticationService.getCurrentUser().subscribe((res) => {
        this.router.navigate([
          '/merchant',
          { outlets: { page: ['merchant-profile', res.data['merchantKey'], 'staff', item['contactKey']] } },
        ]);
      });
    } else if (item['UltimateTableName'] == 'Merchant') {
      this.router.navigate([
        '/merchant',
        { outlets: { page: ['merchant-profile', item['merchantKey'], 'staff', item['contactKey']] } },
      ]);
    }
  }

  changePassword(id) {
    const ref = RootAppComponent.dialog.open(OperatorChangePasswordComponent, {
      data: id,
      width: '600px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  passwordUpdate(id) {
   if(id)
   {

    const ref = RootAppComponent.dialog.open(PasswordPromptComponent, {
      data: {
        operatorID:id
      },
      width: '650px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.getResult.subscribe((res) => {
      ref.close();
    });
   }
  }

  disableLogin(id) {
    const confirmDialogParams = {
      data: new ConfirmDialog(
        'fas fa-info',
        'Are you sure?',
        '<p>Disabling the operator, the user will be disconnected</p>',
        'No',
        'Yes'
      ),
    };
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, confirmDialogParams);
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        this.operatorService.disableLogin(id, this.sessionType).subscribe((res) => {
          if (res) {
            const temp = this.getPicture(res);
            this.dataSource.replaceItemWithNewID = temp;

            NotifyAppComponent.displayToast('success', 'Disable Login', 'This operator is not able to login');
            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }

  enableLogin(id) {
    this.operatorService.enableLogin(id, this.sessionType).subscribe((res) => {
      if (res) {
        const temp = this.getPicture(res);
        this.dataSource.replaceItemWithNewID = temp;

        NotifyAppComponent.displayToast('success', 'Enable Login', 'This operator is able to login');
      }
    });
  }

  unsetMerchantAdmin(id) {
    const confirmDialogParams = {
      data: new ConfirmDialog(
        'fas fa-info',
        'Are you sure?',
        '<p>Unsetting merchant admin role, the operator will not be merchant admin</p>',
        'No',
        'Yes'
      ),
    };
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, confirmDialogParams);
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        this.operatorService.unsetMerchantAdmin(id).subscribe((res) => {
          if (res) {
            const temp = this.getPicture(res);
            this.dataSource.replaceItemWithNewID = temp;

            NotifyAppComponent.displayToast('success', 'Set Role', 'This operator role is merchant');
            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }

  setMerchantAdmin(id) {
    this.operatorService.setMerchantAdmin(id).subscribe((res) => {
      if (res) {
        const temp = this.getPicture(res);
        this.dataSource.replaceItemWithNewID = temp;

        NotifyAppComponent.displayToast('success', 'Set Role', 'This operator role is merchant-admin');
      }
    });
  }

  getPicture(item) {
    let result;
    result = item;
    result['profileLink'] = null;

    if (result) {
      result['profileLink'] = this.operatorService.getProfilePicStreamLink(result['ID']);
    }
    return result;
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadOperators implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  isDestoyed = false;

  serviceRef;

  constructor(private operatorService: OperatorService, private destroyEvent, private p) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });
    const payload: any = {
      section: 0,
      orderBy: 'UserName',
      fields: 'ID,UserName,CalculatedName,TableName,UltimateTableName,Role,Card_key,IsAdministrator,Enabled',
    };

    if (this.p && this.p.utimateTables && Array.isArray(this.p.utimateTables) && this.p.utimateTables.length > 0) {
      payload.ultimateTableNames = this.p.utimateTables.join(',');
    } else if (this.p && this.p.utimateTables && typeof this.p.utimateTables == 'string') {
      payload.ultimateTableNames = this.p.utimateTables;
    }
    this.serviceRef = this.operatorService.getOperatorList(payload).subscribe((res) => {
      this.firstLoad = true;
      this.items = res;
      this.count = res.length;
      this.dataChange.next(this.items.slice());

      for (let i = 0; i < this.items.length; i++) {
        this.getPicture(i);
        this.filterOperator(i);
      }

      const innerFunction = (section) => {
        const length = this.items.length;
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.operatorService.getOperatorList(payload).subscribe((res) => {
          if (res.length > 0) {
            this.count = res.count;
            this.items = this.items.concat(res);

            for (let i = length; i < this.items.length; i++) {
              this.getPicture(i);
              this.filterOperator(i);
            }

            this.dataChange.next(this.items.slice());
            if (this.isDestoyed != true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };

      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  getPicture(i) {
    this.items[i]['profileLink'] = null;

    if (this.items[i]) {
      this.items[i]['profileLink'] = this.operatorService.getProfilePicStreamLink(this.items[i]['ID']);
    }
  }

  filterOperator(i) {
    if (this.items[i]['UltimateTableName'] == 'MerchantProspect') {
      this.items[i]['access'] = 'public';
    } else if (this.items[i]['UltimateTableName'] == 'Promoter' || this.items[i]['UltimateTableName'] == 'Merchant') {
      this.items[i]['access'] = 'main';
    }

    if (this.items[i]['UltimateTableName'] == 'MerchantProspect') {
      this.items[i]['type'] = 'Mer-Prospect';
    } else if (this.items[i]['UltimateTableName'] == 'Merchant') {
      this.items[i]['type'] = 'Merchant';
    } else if (this.items[i]['UltimateTableName'] == 'Customer') {
      this.items[i]['type'] = 'Patient';
    } else if (this.items[i]['UltimateTableName'] == 'Prospect') {
      this.items[i]['type'] = 'Patient';
    } else if (this.items[i]['UltimateTableName'] == 'CustomersAndProspects') {
      this.items[i]['type'] = 'Patient';
    } else if (this.items[i]['UltimateTableName'] == 'Beneficiary') {
      this.items[i]['type'] = 'Supplier';
    } else if (this.items[i]['UltimateTableName'] == 'Promoter') {
      this.items[i]['type'] = 'Promoter';
    } else if (this.items[i]['UltimateTableName'] == 'Funder') {
      this.items[i]['type'] = 'Funder';
    } else {
      this.items[i]['type'] = 'Unknown';
    }
    this.items[i]['search'] =''
    if(this.items[i]['Role'] )
    {
      this.items[i]['search'] =this.items[i]['search'] +' ' +this.items[i]['Role']
    }
    if(this.items[i]['Merchant_Name'] )
    {
      this.items[i]['search'] =this.items[i]['search'] +' '  +this.items[i]['Merchant_Name']
    }
    if(this.items[i]['UserName'] )
    {
      this.items[i]['search'] =this.items[i]['search'] +' '  +this.items[i]['UserName']
    }
    if(this.items[i]['CalculatedName'] )
    {
      this.items[i]['search'] =this.items[i]['search']  +' ' +this.items[i]['CalculatedName']
    }
    if(this.items[i]['MerchantProspect_Name'] )
    {
      this.items[i]['search'] =this.items[i]['search']  +' ' +this.items[i]['MerchantProspect_Name']
    }
    if(this.items[i]['type'] )
    {
      this.items[i]['search'] =this.items[i]['search']  +' ' +this.items[i]['type']
    }
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class OperatorDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';
  dateF = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this.dateF = item.dateFrom ? item.dateFrom : '';
    this._filterChange.next(item.value);
  }

  set replaceItemWithNewID(item) {
    let _index = this._tableDatabase.data.findIndex((_obj) => _obj.ID == item.oldID);

    if (_index < 0) {
      _index = this._tableDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    }

    this._tableDatabase.data[_index] = item;

    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  set replaceItem(item) {
    const _index = this._tableDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    this._tableDatabase.data[_index] = item;
    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  filteredData: any[] = [];

  constructor(private _tableDatabase: any, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
          let f = '';
          f = item[this.field] || '';
          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'CalculatedName':
          [propertyA, propertyB] = [a.CalculatedName, b.CalculatedName];
          break;
        case 'UserName':
          [propertyA, propertyB] = [a.UserName, b.UserName];
          break;
        case 'TableName':
          [propertyA, propertyB] = [a.TableName, b.TableName];
          break;
        case 'UltimateTableName':
          [propertyA, propertyB] = [a.UltimateTableName, b.UltimateTableName];
          break;
        case 'Role':
          [propertyA, propertyB] = [a.Role, b.Role];
          break;
        case 'Card_key':
          [propertyA, propertyB] = [a.Card_key, b.Card_key];
          break;
        case 'IsAdministrator':
          [propertyA, propertyB] = [a.IsAdministrator, b.IsAdministrator];
          break;
        case 'Enabled':
          [propertyA, propertyB] = [a.Enabled, b.Enabled];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
  }
}
