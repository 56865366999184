<div class="row clearfix defineMessage">
  <!-- blue heading -->
  <div class="card-header primary-gradient-img clearfix" *ngIf="isModal == true">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear pull-right mat-button" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <!-- merchant calculated name -->
      <div class="row titleArea clearfix">
        <mat-icon class="fas fa-file-contract pull-left"></mat-icon>
        <h2 class="message-title summary-header rm-m">
          <span>Personalise appointment page look and feel</span>
        </h2>
      </div>
    </div>
  </div>
  <!-- end blue heading -->

  <h4 class="sm-h4 sr-title" *ngIf="isModal == false">Personalise appointment page look and feel</h4>

  <div class="create-well">
    <form #treatmentGroup="ngForm">
      <!--  mat dialog scrolling area -->
      <mat-dialog-content>
        <div class="row clearfix full-width flex">
          <!-- Left Content -->
          <div class="row full-width mr">
            <div class="row clearfix mt">
              <div class="row clearfix">
                <h3 class="sm-h4 sr-title">Title & personal message</h3>
                <p class="small"></p>
                <div class="card row clearfix mt">
                  <div class="row clearfix">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Title</mat-label>

                      <input class="mat-block" [(ngModel)]="title" matInput name="label" required placeholder="Label"/>
                    </mat-form-field>
                  </div>
                  <div class="row clearfix">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Description</mat-label>
                      <textarea
                        class="dentMsg increase-input-field"
                        [(ngModel)]="description"
                        name="description"
                        placeholder="Description"
                        matInput
                        cols="30"
                        rows="50"
                        required
                      ></textarea>
                    </mat-form-field>
                  </div>

                  <h3 class="sm-h4 sr-title">Background Colours</h3>

                  <div class="row clearfix mb text-center full-width">
                    <mat-radio-group class="alternate-theme ml" [(ngModel)]="backgroundCode" name="scssFile">
                      <mat-radio-button [value]="'default'">Default</mat-radio-button>
                      <mat-radio-button [value]="'color'">Static Color</mat-radio-button>
                      <mat-radio-button [value]="'customizedImage'">Customized Image</mat-radio-button>
                      <!-- <mat-radio-button [value]="'customizedStyle'"
                        >Customized CSS</mat-radio-button
                      > -->
                    </mat-radio-group>
                  </div>

                  <div class="row clearfix" *ngIf="backgroundCode == 'customizedStyle'">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>For example: rgba(0, 128, 0, 0.3) center;</mat-label>
                      <textarea
                        class="dentMsg increase-input-field"
                        [(ngModel)]="backgroundStyle"
                        name="CustomizedStyle"
                        placeholder="For example: rgba(0, 128, 0, 0.3)  center; "
                        matInput
                        cols="30"
                        rows="50"
                        required
                      ></textarea>
                    </mat-form-field>
                  </div>

                  <div class="row clearfix text-center" *ngIf="backgroundCode == 'color'">
                    <input
                      class="color-input-background"
                      [(colorPicker)]="backgroundColor"
                      [value]="backgroundColor"
                      [cpPositionOffset]="-250"
                      [style.background]="backgroundColor"
                      [cpOutputFormat]="'hex'"
                      name="colour"
                    />
                  </div>

                  <div class="uploadBlock2 left half-width" *ngIf="backgroundCode == 'customizedImage'">
                    <div class="row clearfix logo-img image" *ngIf="backgroundImage">
                      <div class="notUploaded mt">
                        <app-ep-document-view-image
                          class="clearfix full-width profile-pic-container"
                          [defaultPicture]="
                            'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/category_placeholder.png)'
                          "
                          [link]="backgroundImage"
                          [hideZoomButton]="false"
                        >
                        </app-ep-document-view-image>
                      </div>
                    </div>
                    <div class="row clearfix">
                      <h4 class="sr-title sm-h4 rm-mb">Appointment Cover Image</h4>
                      <app-file-uploader
                        class="text-center clearfix full-width"
                        [allowedExtensionTypes]="['image']"
                        [isFixedAspectRatio]="false"
                        [isImageCropping]="true"
                        [canSkip]="false"
                        [limit]="1"
                        [uploadDirectly]="true"
                        [isDragable]="true"
                        [title]="'CLICK HERE to upload'"
                        (onCompleteAll)="onCompleteAll($event)"
                      >
                      </app-file-uploader>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Right Content -->
          <div class="row full-width ml mr">
            <div class="full-width">
              <h3 class="sm-h4 sr-title">{{ "KEYWORD.practice" | translate | titlecase}} Appointment Cover</h3>

              <div class="row clearfix text-center upload-area practice">
                <div class="upload-container practice-image">
                  <app-file-uploader
                    class="text-center clearfix practiceImageUploader"
                    *ngIf="disableEditPractice == false"
                    [allowedExtensionTypes]="allowedExtensionTypes"
                    [limit]="1"
                    [uploadDirectly]="true"
                    [isDragable]="true"
                    [keepOriginal]="false"
                    [isCover]="true"
                    (onCompleteAll)="onCompleteAllPracticeBackground($event)"
                  >
                  </app-file-uploader>
                  <app-ep-document-view-image
                    class="profile-practice-image lrg"
                    [hideZoomButton]="true"
                    [defaultPicture]="
                      'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/consumer/dentist-portal-header.png)'
                    "
                    [link]="practiceCoverLink"
                  >
                  </app-ep-document-view-image>
                </div>
                <!-- end practice cover image -->
              </div>

              <p class="small" *ngIf="disableEditPractice == false">
                To upload a picture, please drag a
                <strong>.jpg or .png file</strong> into the grey area. Best size for image is 1024px x 512px.
              </p>
              <mat-slide-toggle class="full-width mr mb" [(ngModel)]="showHeader" name="showHeader">
                Display Cover and Profile image
              </mat-slide-toggle>

              <mat-slide-toggle class="full-width mr mb" [(ngModel)]="showPracticeName" name="showPracticeName">
                Display {{"KEYWORD.Practice" | translate}} Name
              </mat-slide-toggle>
            </div>
          </div>
        </div>

        <div class="row full-width clearfix mb">
          <h3 class="sm-h4 sr-title">Footer</h3>
          <div id="row clearfix full-width">
            <div class="rel" *ngIf="loadFooter != true" @ngIfAnimation>
              <div class="row clearfix padded rel">
                <h1 class="dataHeader rm-mt transparent">Loading footer...</h1>
              </div>
            </div>

            <iframe
              class="iframe-style full-width row clearfix"
              *ngIf="iframeFooterSrc"
              [src]="iframeFooterSrc"
              (load)="onFrameLoad($event)"
              style="border: none !important"
            ></iframe>
          </div>

          <div class="row clearfix full-width">
            <div class="row clearfix flex mb">
              <mat-slide-toggle class="full-width mr show-contact" [(ngModel)]="showContact" name="showContact">
                Display Footer
              </mat-slide-toggle>

              <div class="full-width mr text-center" *ngIf="showContact == true">
                <label class="label-color">Background Color</label>

                <input
                  class="color-input"
                  [(colorPicker)]="contactBackgroundColor"
                  [value]="contactBackgroundColor"
                  [cpPositionOffset]="-250"
                  [style.background]="contactBackgroundColor"
                  [cpOutputFormat]="'hex'"
                  (colorPickerClose)="updateFooterPreview()"
                  name="contactBackgroundColor"
                  name="colour"
                />
              </div>
              <div class="full-width mr text-center" *ngIf="showContact == true">
                <label class="label-color">Font Color</label>

                <input
                  class="color-input"
                  [(colorPicker)]="contactColor"
                  [value]="contactColor"
                  [cpPositionOffset]="-250"
                  [style.background]="contactColor"
                  [cpOutputFormat]="'hex'"
                  (colorPickerClose)="updateFooterPreview()"
                  name="contactColor"
                  name="colour"
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
      </mat-dialog-content>
      <!-- fixed action buttons -->
      <mat-dialog-actions>
        <div class="row clearfix text-center">
          <button class="btn-large mr" (click)="onReset()" mat-raised-button color="primary">Reset</button>

          <button
            class="btn-large ml"
            [disabled]="!treatmentGroup.form.valid"
            (click)="onSave()"
            mat-raised-button
            color="accent"
          >
            Save
          </button>
        </div>
      </mat-dialog-actions>
    </form>
  </div>
</div>
