<div class="row clearfix">
  <div class="row clearfix mb searchArea primary-gradient-img flex">
    <div class="row clearfix full-width flex" id="search">
      <!-- <h3 class="pull-left white rm-m">Supplier List <span class="badge" countUp [endVal]="beneficiaries.length"></span></h3> -->

      <mat-form-field class="half-width clearfix" appearance="outline">
        <mat-label>Search a {{ "KEYWORD.patient" | translate }} by name</mat-label>
        <input class="clearfix" [(ngModel)]="filter" name="filter" placeholder="Filter" matInput/>

        <button
          class="white position"
          *ngIf="filter"
          (click)="filter = undefined; selectedItem = ''; $event.stopPropagation()"
          mat-button
          matSuffix
          mat-icon-button

        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="full-width">
      <!-- map interface -->

      <button class="pull-right white map-toggle" (click)="resetMap()" mat-stroked-button>
        <mat-icon class="fas fa-history"></mat-icon> Reset
      </button>

      <button class="pull-right white map-toggle" (click)="myLocation()" mat-stroked-button>
        <mat-icon class="fas fa-map-marker-alt"></mat-icon> My Location
      </button>

      <button class="pull-right white map-toggle" (click)="practiceLocation()" mat-stroked-button>
        <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
        {{ "KEYWORD.practice" | translate | titlecase}} Location
      </button>

      <button class="pull-right white map-toggle" (click)="hasMapSearch = !hasMapSearch" mat-stroked-button>
        <mat-icon class="fas fa-map-marker"></mat-icon> Search Address
      </button>
    </div>
  </div>
</div>

<div class="row clearfix flex min-height white-bg">
  <div class="thrd-width flex flex-col flex-1" *ngIf="filter">
    <div class="row clearfix full-height flex flex-col flex-1 rel" *ngIf="selectedItem" @simpleFadeAnimation>
      <button class="close-arrow white" (click)="closeSelectedItem()" mat-button matTooltip="Close">
        <mat-icon>keyboard_backspace</mat-icon> Back to list
      </button>

      <app-beneficiary-view class="animate" [beneficiaryID]="selectedItem['ID']"></app-beneficiary-view>
    </div>
    <div
      class="row clearfix full-height flex flex-col flex-1"
      *ngIf="beneficiaries && beneficiaries.length > 0 && !selectedItem"
      @simpleFadeAnimation
    >
      <mat-card class="card listCardView listCardSide flex flex-col flex-1" [class.push]="!selectedItem">
        <div class="row clearfix flex flex-col flex-1">
          <mat-list class="supList" [class.shrink]="selectedItem">
            <mat-list-item
              *ngFor="
                let b of beneficiaries
                  | FilterArrayMultipleContainValue: ['CalculatedName']:filter
                  | paginate: { itemsPerPage: 10, currentPage: p };
                let i = index
              "
              [class.highlightRow]="selectedItem && selectedItem.ID && selectedItem.ID == b['ID']"
              (click)="selectItem(b, i, p)"
            >
              <app-beneficiary-view-picture class="supplierIcon text-center" *ngIf="b" [beneficiaryID]="b['ID']">
              </app-beneficiary-view-picture>
              <p>{{ b.CalculatedName }}</p>
            </mat-list-item>
          </mat-list>
        </div>
        <div class="text-center">
          <mat-divider></mat-divider>
          <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
        </div>
      </mat-card>
    </div>
  </div>

  <div class="full-width flex">
    <app-map-multiple-view
      class="flex flex-col full-width"
      [displayHeaderBar]="false"
      [streetNameAttribute]="'addresses.Street Name'"
      [streetNumberAttribute]="'addresses.Street Nr'"
      [unitNumberAttribute]="'addresses.Unit'"
      [stateAttribute]="'addresses.State'"
      [suburbAttribute]="'addresses.Suburb'"
      [streetTypeAttribute]="'addresses.Street Type'"
      [countryAttribute]="'addresses.Country.Code'"
      [postCodeAttribute]="'addresses.Postcode'"
      [markerTitleAttribute]="'CalculatedName'"
      [markerDescriptionAttribute]="'addresses.Calculated'"
      [latitudeAttribute]="'Address.Latitude'"
      [longitudeAttribute]="'Address.Longitude'"
      [addMarker]="addMarkerEvent"
      [title]="title"
      [description]="description"
      [isAction]="true"
      [actionIcon]="'format_list_bulleted'"
      [actionLabel]="'Supplier List'"
      [selectedItem]="selectedItemEvent"
      [isLightMode]="true"
      [showCustomAction]="false"
      [displayActions]="false"
      [hasMapSearch]="hasMapSearch"
      (getSelected)="getSelected($event)"
      (addCoordinate)="addCoordinateEvent($event)"
      (getAction)="getActionEvent($event)"
    ></app-map-multiple-view>
  </div>
</div>

<div class="full-width clearfix row" *ngIf="(!beneficiaries || beneficiaries.length <= 0) && isLoaded == true">
  <app-empty-list-message></app-empty-list-message>
</div>
