var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EventEmitter, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { TreatmentService } from '../shared/treatment.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { treatmentUtil } from '../shared/treatmentUtil';
import { TreatmentBrandViewComponent } from '../treatment-brand-view/treatment-brand-view.component';
import { TreatmentProductViewComponent } from '../treatment-product-view/treatment-product-view.component';
export class TreatmentProductLookupComponent {
    constructor(treatmentService, authenticationService, data) {
        this.treatmentService = treatmentService;
        this.authenticationService = authenticationService;
        this.data = data;
        this.products = [];
        this._products = [];
        this.filterData = '';
        this.treatmentUtil = new treatmentUtil();
        this.isPromoterOrAdmin = false;
        this.getProduct = new EventEmitter();
        this.close = new EventEmitter();
        this.isClickImage = false;
        this.mergeProducts = true;
        this.expandingItems = [];
        if (data) {
            if (data.products && data.products.length > 0) {
                this._products = data.products;
            }
            if (data.merchantID) {
                this.merchantID = data.merchantID;
            }
            if (data.itemCode) {
                this.itemCode = data.itemCode;
            }
            if (data.isGlobal != null) {
                this.isGlobal = data.isGlobal;
            }
            if (data.mergeProducts !== null) {
                this.mergeProducts = data.mergeProducts;
            }
        }
    }
    ngOnInit() {
        this.authenticationService.isPromoterOrAdmin().subscribe((res) => __awaiter(this, void 0, void 0, function* () {
            this.isPromoterOrAdmin = res;
            if ((!this.products || this.products.length <= 0) && this.itemCode) {
                const payload = {
                    itemCode: this.itemCode,
                    fields: 'LastModified,Category_key,Type_key,Brand_key,ID,Brand.Label,Type.Label,Category.Label,Category.Colour,Label,Item_Code.Effective',
                    merchantID: this.merchantID,
                    isNullBrand: true,
                    isNullProduct: true,
                    isNullType: true,
                };
                let treatmentPromise;
                if (this.isGlobal == true) {
                    treatmentPromise = this.treatmentService.getTreatmentProductListGlobal(payload).toPromise();
                }
                else {
                    treatmentPromise = this.treatmentService.getTreatmentProductList(payload, this.isPromoterOrAdmin).toPromise();
                }
                this._products = yield treatmentPromise;
            }
            this._products = this._products.map((product) => {
                if (product['Label'] != 'N/A') {
                    product.name = this.treatmentUtil.getLabel(product['Label']);
                }
                else if (product['Label'] == 'N/A' && product['Brand.Label'] != 'N/A') {
                    product.name = this.treatmentUtil.getLabel(product['Brand.Label']);
                }
                else if (product['Label'] == 'N/A' && product['Brand.Label'] == 'N/A' && product['Type.Label'] != 'N/A') {
                    product.name = this.treatmentUtil.getLabel(product['Type.Label']);
                }
                else if (product['Label'] == 'N/A' &&
                    product['Brand.Label'] == 'N/A' &&
                    product['Type.Label'] == 'N/A' &&
                    product['Category.Label'] != 'N/A') {
                    product.name = this.treatmentUtil.getLabel(product['Category.Label']);
                }
                return product;
            });
            this.products = this.mergeProducts ? this.getMergedData(this._products) : this._products;
        }));
    }
    getMergedData(data) {
        const key = 'name';
        const uniqueData = [...new Map(data.map((item) => [item[key], item])).values()];
        const finalData = [];
        uniqueData.forEach((it) => {
            const l = data.filter((o) => o[key] === it[key]).length;
            const isMerged = !this.expandingItems.includes(it[key]) && l > 1;
            if (isMerged) {
                it.isMerged = true;
                it.mergedLength = l;
                finalData.push(it);
            }
            else {
                it.isMerged = false;
                finalData.push(...data.filter((o) => o[key] === it[key]));
            }
        });
        return finalData;
    }
    viewProduct(id) {
        const ref = RootAppComponent.dialog.open(TreatmentProductViewComponent, {
            data: {
                productID: id,
                isGlobal: this.isGlobal || false,
            },
            width: '800px',
            panelClass: 'noCard',
        });
        ref.componentInstance.close.subscribe((res) => {
            ref.close();
        });
    }
    viewBrand(id) {
        const ref = RootAppComponent.dialog.open(TreatmentBrandViewComponent, {
            data: {
                brandID: id,
                isGlobal: this.isGlobal || false,
            },
            width: '800px',
            panelClass: 'noCard',
        });
        ref.componentInstance.close.subscribe((res) => {
            ref.close();
        });
    }
    selectProduct(p) {
        if (p && this.isClickImage != true) {
            this.getProduct.emit(p);
        }
    }
    closeEvent() {
        this.close.emit(true);
    }
    clickImageEvent(e) {
        this.isClickImage = e;
    }
    expandProduct(item) {
        this.expandingItems.push(item.name);
        this.products = this.getMergedData(this._products);
    }
}
