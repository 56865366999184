import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { ActionLogViewComponent } from '../../action-log/action-log-view/action-log-view.component';
import { ActionLogUtilClass } from '../../action-log/shared/actionLogUtil';
import { ActivityLogService } from '../shared/activity-log.service';

import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { HeaderService } from '../../../shared/services/header.service';
import { SideMenuService } from '../../../shared/services/side-menu.service';

@Component({
  selector: 'app-activity-log-list-global',
  templateUrl: './activity-log-list-global.component.html',
  styleUrls: ['./activity-log-list-global.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ActivityLogListGlobalComponent implements OnInit {
  @Input()
  merchantID;

  @Input()
  dateFrom;

  @Input()
  dateTo;

  @Input()
  targetID;

  @Input()
  itemID;

  @Input()
  type;

  @Input()
  shadow = true;

  @Input()
  groupFilter;

  @Input()
  displayToggleSwitch = false;

  @Output()
  groupFilterEvent = new EventEmitter();

  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  utils = new UtilsClass();

  displayedColumns = [
    'Icon',
    'Date',
    'PatientName',
    'RelatedTo',
    'OperatorName',
    'MerchantName',
    'Type',
    'Subject',
    'Result',
    'Description',
    'Actions',
    'fieldColor',
  ];

  filters = [];

  searchVals = new FormControl();

  searchValList = ['Patient Name', 'Operator Name', 'Merchant Name', 'Type'];
  expandedRow: number;
  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  filteredSize = null;

  isPromoterOrAdmin = false;

  destroyEvent = new EventEmitter();

  actionLogUtil = new ActionLogUtilClass();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private noteService: ActivityLogService,
    private authenticationService: AuthenticationService,
    private customDate: CustomDatePipe,
    private router: Router
  ) {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });
  }

  ngOnInit() {
    SideMenuService.goBack.subscribe((res) => {
      if (res == true) {
      }
    });

    const params = {
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      targetID: this.targetID,
      itemID: this.itemID,
      type: this.type,
      groupFilter: this.groupFilter,
      merchantID: this.merchantID,
    };

    this.listDB = new LoadRecords(this.noteService, this.destroyEvent, this.isPromoterOrAdmin, params);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);

    this.getDisplayColumn();
  }

  ngOnChanges(changes: SimpleChanges) {
    const params = {
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      targetID: this.targetID,
      itemID: this.itemID,
      type: this.type,
      groupFilter: this.groupFilter,
      merchantID: this.merchantID,
    };

    this.listDB = new LoadRecords(this.noteService, this.destroyEvent, this.isPromoterOrAdmin, params);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);

    this.getDisplayColumn();
  }

  getDisplayColumn() {
    if (this.targetID) {
      this.displayedColumns = [
        'Icon',
        'Date',
        // "PatientName",
        'RelatedTo',
        'OperatorName',
        'MerchantName',
        'Type',
        'Subject',
        'Result',
        'Description',
        'Actions',
        'fieldColor',
      ];
    }
  }

  setFilter(event, field) {
    let filter;

    if (typeof event == 'object' && event != null) {
      const v = this.customDate.transform(event, Settings.global['dateFormat']);
      filter = {
        field,
        value: v,
      };
    } else if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  changeGroupFilter(e) {
    this.groupFilterEvent.emit(e);
  }

  openNoteView(ID) {
    const ref = RootAppComponent.dialog.open(ActionLogViewComponent, {
      data: ID,
      width: '650px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  createNote(pID) {
    const _result = {
      targetID: pID,
      noteTarget: 'patient',
    };

    HeaderService.setNoteObject(_result);
  }

  openPatientView(ID) {
    // let ref = RootAppComponent.dialog.open(CustomerProspectViewComponent, {
    //   data: ID,
    //   width: '650px',
    //   panelClass: 'noCard'
    // });

    // ref.componentInstance.closeModal.subscribe(res => {
    //   if (res==true) {
    //     ref.close();
    //   }
    // })
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['customer-profile', ID, 'patient'],
        },
      },
    ]);
  }

  displayDescription(value) {
    if (value) {
      return value.replace('<BR>', '; ');
    }

    return '';
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  util = new UtilsClass();

  isDestoyed = false;

  serviceRef;

  constructor(
    private noteService: ActivityLogService,
    private destroyEvent,
    private isPromoterOrAdmin,
    private params
  ) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    const payload = {
      section: 0,
      merchantID: this.params.merchantID,
      dateFrom: this.util.EPdateFormat(this.params.dateFrom),
      dateTo: this.util.EPdateFormat(this.params.dateTo),
      targetID: this.params.targetID,
      itemID: this.params.itemID,
      withoutAutoAction: true,
      withoutInternalAction: true,
      fields:
        'ID,DateTimeCreated,Who.Name,Operator_Name,MerchantName,Action,Subject,Result,Merchant_key,Who.key,Who.Table,Description,' +
        'What.key,What.Table',
    };

    if (this.params.groupFilter == 'all') {
      payload['withoutAutoAction'] = false;
      payload['withoutInternalAction'] = false;
    } else if (this.params.groupFilter == 'merchant') {
      payload['withoutAutoAction'] = true;
      payload['withoutInternalAction'] = true;
    }

    this.serviceRef = this.noteService.getNoteList(payload, this.isPromoterOrAdmin).subscribe((res) => {
      this.count = res.length;
      this.items = res;
      this.dataChange.next(this.items.slice());
      this.firstLoad = true;
      const innerFunction = (section) => {
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.noteService.getNoteList(payload, this.isPromoterOrAdmin).subscribe((res) => {
          if (res.length > 0) {
            this.count = res.count;
            this.items = this.items.concat(res);
            this.dataChange.next(this.items.slice());
            if (this.isDestoyed != true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };

      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
          let f = '';

          f = item[this.field] || '';

          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'Date':
          [propertyA, propertyB] = [
            moment(a['DateTimeCreated']).toDate().getTime(),
            moment(b['DateTimeCreated']).toDate().getTime(),
          ];
          break;
        case 'PatientName':
          [propertyA, propertyB] = [a['Who.Name'], b['Who.Name']];
          break;
        case 'Type':
          [propertyA, propertyB] = [a['Action'], b['Action']];
          break;
        case 'Subject':
          [propertyA, propertyB] = [a['Subject'], b['Subject']];
          break;
        case 'Result':
          [propertyA, propertyB] = [a['Result'], b['Result']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
