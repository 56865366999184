<div class="rel">
  <div class="card-header primary-gradient-img clearfix confirmModal">
    <button class="btn-close btn-clear mat-button smt white custom-close" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="full-width flex main-title-container">
      <div class="row full-width title-container clearfix">
        <div class="title-container-sub">
          <h2 class="text-left title-header rm-m">Editor Assets Gallery</h2>
        </div>
      </div>
    </div>
  </div>
  <div class="full-width flex">
    <mat-form-field class="full-width mr" appearance="outline">
      <mat-label>Label</mat-label>
      <input [(ngModel)]="partLabelSearch" matInput placeholder="Label" name="Label"/>
    </mat-form-field>
    <mat-form-field class="third-width mr" appearance="outline">
      <mat-label>Type</mat-label>
      <mat-select [(ngModel)]="typeSearch" name="type" placeholder="Type">
        <div *ngFor="let m of types">
          <mat-option [value]="m.code">
            <mat-icon class="{{ m.icon }} mr" style="color: rgba(0, 0, 0, 0.4)"></mat-icon>
            {{ m.label }}
          </mat-option>
          <hr class="option-divider"/>
        </div>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="third-width mr" appearance="outline">
      <mat-label>Sort By</mat-label>
      <mat-select [(ngModel)]="orderBySearch" name="orderBy" placeholder="Sort By">
        <mat-option [value]="'-DateTimeCreated'">
          Date
          <mat-icon class="fas fa-sort-down sml" style="line-height: 30px; margin-top: -20px"></mat-icon>
        </mat-option>
        <mat-option [value]="'DateTimeCreated'">
          Date
          <mat-icon class="fas fa-sort-up sml" style="line-height: 30px"></mat-icon>
        </mat-option>
        <mat-option [value]="'-Label'">
          Label
          <mat-icon class="fas fa-sort-down sml" style="line-height: 30px; margin-top: -20px"></mat-icon>
        </mat-option>
        <mat-option [value]="'-Label'">
          Label
          <mat-icon class="fas fa-sort-up sml" style="line-height: 30px"></mat-icon>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button class="btn-large search-button" (click)="search()" mat-raised-button color="primary">
      Search <mat-icon class="fas fa-search sml"></mat-icon>
    </button>
  </div>

  <div class="full-width smb">
    <div class="toggle-card row clearfix">
      <mat-button-toggle-group
        class="btn-group row clearfix flex half-width pull-left"
        [(ngModel)]="galleryType"
        (ngModelChange)="changeGalleryChange()"
        name="galleryType"
      >
        <mat-button-toggle class="text-center full-width rel" [value]="'default'"> Default</mat-button-toggle>
        <mat-button-toggle class="text-center full-width rel"
                           [value]="'practice'">{{ "KEYWORD.practice" | translate | titlecase}}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <mat-dialog-content style="max-height: 58vh !important">
    <div class="full-width all-container" *ngIf="searchApplied != true">
      <app-empty-list-message
        [title]="'Nothing Yet To Display '"
        [message]="'Choose search criteria and Gallery type, Then click on Search'"
        [isAction]="true"
        [actionIcon]="''"
        [actionLabel]="'Search'"
        (actionEvent)="search()"

      >
      </app-empty-list-message>
    </div>

    <div class="full-width all-container" *ngIf="searchApplied == true">
      <div class="full-width document-container-gallery" [hidden]="galleryType == 'practice'">
        <mat-card
          class="editor-card-preview row file animate mat-elevation-z4 pull-left"
          *ngFor="let d of defaultDocuments"
        >
          <button class="action-button-gallery btn" [matMenuTriggerFor]="menuInner10" mat-raised-button color="accent">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menuInner10="matMenu">
            <button
              *ngIf="isEmbedOption(d) == false && isHeader(d) == true"
              (click)="copyContent(d, false)"
              mat-menu-item
            >
              <mat-icon class="fas fa-copy mr"></mat-icon> Copy Content
            </button>
            <button
              *ngIf="isEmbedOption(d) == false && isHeader(d) == true"
              (click)="insertContent(d, false)"
              mat-menu-item
            >
              <mat-icon class="fas fa-plus-circle mr"></mat-icon> Insert Content
            </button>

            <button
              *ngIf="isEmbedOption(d) == false && isHeader(d) == false"
              (click)="insertCopyContent(d, false)"
              mat-menu-item
            >
              <mat-icon class="fas fa-laptop-code mr"></mat-icon> Insert-Copy Content
            </button>

            <button *ngIf="isEmbedOption(d) == true" (click)="insertCopyContent(d, true)" mat-menu-item>
              <mat-icon class="fas fa-laptop-code mr"></mat-icon> Insert-Copy As Embedded
            </button>
            <button *ngIf="isEmbedOption(d) == true" (click)="insertCopyContent(d, false)" mat-menu-item>
              <mat-icon class="fas fa-directions mr"></mat-icon> Insert-Copy As External Link
            </button>

            <button (click)="copyLink(d)" mat-menu-item>
              <mat-icon class="fas fa-link mr"></mat-icon>
              Copy Link
            </button>
            <hr/>
            <button (click)="view(d)" mat-menu-item>
              <mat-icon class="fas fa-eye mr"></mat-icon>
              View
            </button>
            <button (click)="preview(d)" mat-menu-item>
              <mat-icon class="fas fa-search mr"></mat-icon>
              Preview
            </button>

            <hr/>
            <button (click)="delete(d.ID)" mat-menu-item>
              <mat-icon class="fas fa-trash mr"></mat-icon>
              Delete
            </button>
          </mat-menu>
          <div class="gallery-banner">
            <p>
              <span class="pull-left sml">.{{ d.ExtensionOrMimeType }}</span>
              {{ d.DateTimeCreated | TimeLabelPast }} ago
              <mat-icon class="fas fa-clock"></mat-icon>
            </p>
          </div>
          <div class="full-width gallery-card-support-container" (click)="view(d)">
            <app-ep-document-thumbnail-view class="gallery-card-support" [documentID]="d.ID" [hideZoomButton]="true">
            </app-ep-document-thumbnail-view>
          </div>
          <p class="text-center full-width editor-description">{{ d.Label }}</p>
        </mat-card>
        <div class="full-width text-center" *ngIf="isDefaultLoaded == true && defaultDocuments.length <= 0">
          <app-empty-list-message [title]="'Nothing To Display'" [message]="'No document found in the gallery'">
          </app-empty-list-message>
        </div>
      </div>
      <div class="full-width" [hidden]="galleryType == 'default'">
        <mat-card
          class="editor-card-preview row file animate mat-elevation-z4 pull-left"
          *ngFor="let d of practiceDocuments"
        >
          <button class="action-button-gallery btn" [matMenuTriggerFor]="menuInner14" mat-raised-button color="accent">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menuInner14="matMenu">
            <button
              *ngIf="isEmbedOption(d) == false && isHeader(d) == true"
              (click)="copyContent(d, false)"
              mat-menu-item
            >
              <mat-icon class="fas fa-copy mr"></mat-icon> Copy Content
            </button>
            <button
              *ngIf="isEmbedOption(d) == false && isHeader(d) == true"
              (click)="insertContent(d, false)"
              mat-menu-item
            >
              <mat-icon class="fas fa-plus-circle mr"></mat-icon> Insert Content
            </button>

            <button
              *ngIf="isEmbedOption(d) == false && isHeader(d) == false"
              (click)="insertCopyContent(d, false)"
              mat-menu-item
            >
              <mat-icon class="fas fa-laptop-code mr"></mat-icon> Insert-Copy Content
            </button>

            <button *ngIf="isEmbedOption(d) == true" (click)="insertCopyContent(d, true)" mat-menu-item>
              <mat-icon class="fas fa-laptop-code mr"></mat-icon> Insert-Copy As Embedded
            </button>
            <button *ngIf="isEmbedOption(d) == true" (click)="insertCopyContent(d, false)" mat-menu-item>
              <mat-icon class="fas fa-directions mr"></mat-icon> Insert-Copy As External Link
            </button>

            <button (click)="copyLink(d)" mat-menu-item>
              <mat-icon class="fas fa-link mr"></mat-icon>
              Copy Link
            </button>
            <hr/>
            <button (click)="view(d)" mat-menu-item>
              <mat-icon class="fas fa-eye mr"></mat-icon>
              View
            </button>
            <button (click)="preview(d)" mat-menu-item>
              <mat-icon class="fas fa-search mr"></mat-icon>
              Preview
            </button>

            <hr/>
            <button (click)="delete(d.ID)" mat-menu-item>
              <mat-icon class="fas fa-trash mr"></mat-icon>
              Delete
            </button>
          </mat-menu>
          <div class="gallery-banner">
            <p>
              <span class="pull-left sml">.{{ d.ExtensionOrMimeType }}</span>
              {{ d.DateTimeCreated | TimeLabelPast }} ago
              <mat-icon class="fas fa-clock"></mat-icon>
            </p>
          </div>
          <div class="full-width gallery-card-support-container" (click)="view(d)">
            <app-ep-document-thumbnail-view class="gallery-card-support" [documentID]="d.ID" [hideZoomButton]="true">
            </app-ep-document-thumbnail-view>
          </div>
          <p class="text-center full-width editor-description">{{ d.Label }}</p>
        </mat-card>
        <div class="full-width text-center" *ngIf="isPracticeLoaded == true && practiceDocuments.length <= 0">
          <app-empty-list-message [title]="'Nothing To Display'" [message]="'No document found in the gallery'">
          </app-empty-list-message>
        </div>
      </div>
    </div>

    <div
      class="text-center full-width mt clearfix place-holder-result"
      *ngIf="galleryType == 'default' && displayMoreDefault == true"
    ></div>
    <div
      class="text-center full-width mt clearfix place-holder-result"
      *ngIf="galleryType == 'practice' && displayMorePractice == true"
    ></div>
  </mat-dialog-content>

  <div class="rel" *ngIf="galleryType == 'default' && displayMoreDefault == true">
    <button class="text-center btn-large load-more-button" (click)="loadMore()" mat-raised-button color="primary">
      Load More Documents <mat-icon>more_horiz</mat-icon>
    </button>
  </div>
  <div class="rel" *ngIf="galleryType == 'practice' && displayMorePractice == true">
    <button class="text-center btn-large load-more-button" (click)="loadMore()" mat-raised-button color="primary">
      Load More Documents <mat-icon>more_horiz</mat-icon>
    </button>
  </div>
  <hr/>
  <div class="drop-button text-center mt full-width">
    <button class="pull-right btn-large" (click)="create()" mat-raised-button color="accent">Create</button>
    <button class="pull-left btn-large" (click)="closeEvent()" mat-raised-button color="primary">close</button>
  </div>
</div>
