import { QueryParameters } from '../../../../shared/types/query-parameters.type';

export class Contract {
  [key: string]: any;

  'ID': string;
  'DateTimeCreated': string;
  'LastModified': string;
  'LastModified_Human': string;
  'Customer_key': string;
  'Customer.FirstName': string;
  'Customer.LastName': string;
  'Customer.FullName': string;
  'Customer.Mobile': string;
  'Customer.Email': string;
  'Merchant_key': string;
  'MerchantName': string;
  'Product_key': string;
  'Contract.Status.Code': string;
  'Contract.Status.Label': string;
  'ApprovedOrActive': '0' | '1';
  'FinishDate': string;
  'AllowedStatusValues': string;
  'ContractDate': string;
  'LoanPurpose': string;
  'Product.Label.Internal': string;
  'Product.Label.Marketing': string;
  'Product.Label.Web': string;
  'Funder_key': string;
  'FunderName': string;
  'Is_FinalisationNotice_Sent': '0' | '1';
  'Who_Sent_FinalisationNotice': string;
  'Email_FinalisationNotice_Sent': string;
  'Mobile_FinalisationNotice_Sent': string;
  'When_FinalisationNotice_Sent': string;
  'Webhook_URL': string;
  'Quotation.Status.Code': string;
  'Quotation.Status.Label': string;
  'Quotation.Date': string;
  'Quotation.Expiry': string;
  'Done': string;
  'ToDo': string;
  'InterestRate.Annual': number;
  'InterestRate.True': number;
  'Promotion': string;
  'Merchant.Interest': number;
  'Merchant.Commission': number;
  'Merchant.EstablishmentFee': number;
  'Merchant.AccountKeepingFees': number;
  'Merchant.ContributionsSubtotal': number;
  'NetToMerchant': number;
  'Num_Doc_Proof_Identity': string;
  'Num_Doc_Proof_Employment': string;
  'Num_Doc_Proof_Residency': string;
  'Num_Doc_Proof_Address': string;
  'EstablishmentFee': number;
  'AccountKeepingFees': number;
  'Amount.Contribution': number;
  'Amount.FinancedBeforeDeposit': number;
  'Amount.Invoice': string;
  'Amount.Financed': number;
  'Amount.PerPayment': number;
  'Amount.StampDuty': number;
  'Amount.Loan.Original': number;
  'Amount.Loan.Current': number;
  'Amount.Received': number;
  'Amount.Waived': number;
  'Amount.Outstanding': number;
  'Amount.WrittenOff': number;
  'Amount.Original': number;
  'CapacityCheck.Status': string;
  'CapacityCheck': number;
  'CreditCheck.Status': string;
  'CreditCheck': number;
  'ComplianceFlags.Done': string;
  'ComplianceFlags.Required': string;
  'Payment.CalculationData': { [key: string]: any };
  'Invitation_key': string;
  'ExistingPerson_key': string;
  'ApplicationDate': string;
  'LoanStartDate': string;
  'ApplicationExpiryDate': string;
  'FirstDrawDown.Before': string;
  'FirstDrawDown.WarningDate': string;
  'LastDrawDown.Before': string;
  'LastDrawDown.WarningDate': string;
  'NextDrawDown.Before': string;
  'NextDrawDown.WarningDate': string;
  'DefaultPaymentMethod_key': string;
  'InterestRate': number;
  'RepaymentsInAdvance': '0' | '1';
  'LoanInstalments': string;
  'RepaymentFrequency.Code': string;
  'RepaymentFrequency.Adverb': string;
  'RepaymentFrequency.Noun.Singular': string;
  'RepaymentFrequency.Noun.Plural': string;
  'Deposit': number;
  'Settlements.Drawn': string;
  'Settlements.NotYetDrawn': string;
  'Amounts.InsuranceContributions': number;
  'TreatmentValue': number;
  'TreatmentContribution': number;
  'Promotion_key': string;
  'Promotion.Code': string;
  'Promotion.Name': string;
  'InterestFreePeriod': string;
  'ServiceProvider': string;
  'Nickname': string;
  'OriginalLoanAmount': number;
  'OriginalInterest': number;
  'OriginalFees': number;
  'CustomerCommission': number;
  'AddedInterest': number;
  'AddedFees': number;
  'CurrentInterest': number;
  'CurrentFees': number;
  'RefundsForUndrawn': number;
  'CurrentTotalExDeposit': number;
  'PaidViaDDRs': number;
  'PaidDirectly': number;
  'Credits': number;
  'Amount.DebtCollector': number;
  'PaymentStatus': string;
  'Arrears.CalculationDate': string;
  'Arrears.Amount': number;
  'Arrears.days': string;
  'Arrears.AmountOverdue': number;
  'Arrears.DaysOverdue': number;
  'Recourse_Release_MSF': number;
  'Merchant_Service_Fee': number;
  'Credit_Charges_Merchant': number;
  'CustomerFeesAbsorbed': number;
  'TotalNetSettlements': number;
  'CurrentNetSettlements': number;
  'AccountManagerName': number;
  'ProductTypeLabel': number;
  'ContractType': string;
  'IdentityCheckStatus': string;
  'IdentityCheck': number;
  'FunderReference': string;
  'KPI.Stats.AsQuoted': string;
  'KPI.Stats.Current': string;
  'LastEvent.DateTimeCreated': string;
  'LastEvent.EventCode': string;
  'LastEvent.EventDescription': string;

  payment: {
    [key: string]: any;
    'Count(*)': string;
    'Sum(amounts.Due)': string;
    'Sum(amounts.PaidDirect)': '0' | '1';
    'Sum(amounts.PaidViaSystem)': string;
    'Sum(amounts.Rescheduled)': '0' | '1';
    'Sum(amounts.Total)': string;
    'Sum(amounts.Waived)': '0' | '1';
    'Sum(amounts.WrittenOff)': '0' | '1';
  };
  stats: {
    [key: string]: any;
    'Count(*)': number;
    ID: string;
    'Sum(Insurance.Contribution)': number;
    'Sum(Settlement.Amount)': number;
    'Sum(Settlements.Due)': number;
    'Sum(Settlements.Paid)': number;
    'Sum(Settlements.Suspended)': number;
    'Sum(Settlements.Unpaid)': number;
    name: string;
  }[];



}

export class ContractQuery {
  payload: Partial<Contract> & QueryParameters & { propertyNameHere?: null };
}




export class ContractInvitationMembership {
  ID: string;
  membership: boolean;
}

export class ContractSelect extends (Contract as new () => Pick<
  Contract,
  'Product.Label.Marketing' | 'ApplicationDate' | 'ID' | 'Customer_key'
>) {}

export class ContractFunderSelect extends (Contract as new () => Pick<Contract, 'FunderName' | 'Funder_key'>) {}

export class ContractProductSelect extends (Contract as new () => Pick<
  Contract,
  'Product.Label.Marketing' | 'Product_key'
>) {}

export class ContractCustomerSelect extends (Contract as new () => Pick<
  Contract,
  'Customer.FullName' | 'Customer.Email' | 'Customer_key'
>) {}
