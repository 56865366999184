import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { EmailEditorComponent } from 'angular-email-editor';
import * as FileSaver from 'file-saver';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ClearTextPipe } from '../../pipes/clear-text.pipe';
import { Settings } from '../../types/settings';

import { ClipboardService } from 'ngx-clipboard';
import { ConfirmDialog } from '../../types/confirm-dialog';
import { NotifyAppComponent } from '../../types/notify-app-component';
import { UtilsClass } from '../../types/utils/utils.class';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { CodeHTMLEditComponent } from '../html-edit/html-edit.component';
import { CodeHTMLViewComponent } from '../html-view/html-view.component';
import { RootAppComponent } from '../root-component/root-component.component';

@Component({
  selector: 'app-html-input',
  templateUrl: './html-input.component.html',
  styleUrls: ['./html-input.component.css'],
  providers: [ClearTextPipe],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class CodeHTMLInputComponent implements OnInit {
  @Input()
  result = new EventEmitter();

  @Output()
  getResult = new EventEmitter();
  @Output()
  getStructure = new EventEmitter();

  @Output()
  getContent = new EventEmitter();

  @Input()
  title = 'Marketing Html Content';
  @Input()
  displayEditorOptions = true;

  @Input()
  description = 'Choose how you want to add your HTML Content';

  @Input()
  descriptionDocument = 'Upload file , only accept .html .css';

  allowedExtensions = ['html', 'css'];
  @Input()
  descriptionRaw =
    'Use this component to create your HTML/CSS Content , you can preview the result in real time on the  panel  in the right ';

  @Input()
  descriptionEditor =
    'Use this component to create your HTML Content, drag and drop modals from the  panel  in the right';

  @Input()
  descriptionContent = 'Use this component to create your HTML Content';

  @Input()
  label = 'Content';

  @Input()
  fileID;

  @Input()
  EPDocumentID;

  @Input()
  displayUploader = true;

  @Input()
  settings = Settings.global;

  @Input()
  structure;

  @Input()
  inputType = 'TinyEditor';

  @Input()
  isCss = false;
  @Input()
  apiTinyKey = Settings.global['TinyAPIKey'];

  @ViewChild(EmailEditorComponent, { static: false })
  private emailEditor: EmailEditorComponent;
  @Input()
  isFroalaEditorActive = Settings.global['isFroalaEditorActive'];
  @Input()
  isUnlayerActive = Settings.global['isUnlayerActive'];
  @Input()
  isTinyEditorActive = Settings.global['isTinyEditorActive'];

  @Input()
  isRawPreview = true;

  @Input()
  isRawActive = true;

  @Input()
  isComponent = true;
  @Input()
  componentID = 'ID' + btoa(Math.random().toString()).substring(2, 20);

  @Input()
  isTextActive = true;
  @Input()
  editorId = 'htmlinputeditor';
  isHalfView = true;

  utils = new UtilsClass();
  @Input()
  content;
  contentInnerTiny;
  contentInnerRaw;
  contentInnerText;

  isTinyExist = false;
  isRawExist = false;
  isTextExist = false;
  isContentExist = false;
  editorOptions = {
    fontSize: 12,
    formatOnType: true,
    formatOnPaste: true,
    language: 'html',
    autoIndent: true,
    autoClosingBrackets: 'always',
    dragAndDrop: true,
    fixedOverflowWidgets: true,
    theme: 'vs-dark',
    automaticLayout: true,
  };
  editorOptionsCss = {
    fontSize: 12,
    formatOnType: true,
    formatOnPaste: true,
    language: 'css',
    autoIndent: true,
    autoClosingBrackets: 'always',
    dragAndDrop: true,
    fixedOverflowWidgets: true,
    theme: 'vs-dark',
    automaticLayout: true,
  };
  fileToUpload: File = null;

  froalaOptions = {
    key: Settings.global['FroalaAPIKey'],
  };
  tools = {
    button: {
      enabled: true,
    },
    divider: {
      enabled: true,
    },
    form: {
      enabled: true,
    },
    heading: {
      enabled: true,
    },
    image: {
      enabled: true,
    },
    menu: {
      enabled: true,
    },
    social: {
      enabled: true,
    },
    text: {
      enabled: true,
    },
    timer: {
      enabled: true,
    },
    video: {
      enabled: true,
    },
    html: {
      enabled: true,
    },
  };

  TinyMCEOptions = {
    plugins:
      'fullpage advcode table advtable casechange lists checklist media mediaembed export formatpainter  pageembed permanentpen powerpaste  lists advlist anchor autolink  autosave  charmap code codesample directionality emoticons   hr image image imagetools importcss   insertdatetime  link lists media nonbreaking pagebreak  preview print save searchreplace  table template textpattern toc visualblocks visualchars',
    toolbar1:
      ' undo redo |  styles  | bold underline italic emoticons  | formatselect fontselect fontfamily   fontsize  fontsizeselect  | forecolor backcolor | hr | alignleft aligncenter alignright alignjustify autoresize | bullist numlist |  indent outdent ',
    toolbar2:
      ' link  media  image editimage   | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol  | print  export | preview  ',
    default_link_target: '_blank',
    browser_spellcheck: true,
    extended_valid_elements:
      'a[class|name|href|target|title|hreflang|ping|referrerpolicy|charset|onclick|rel|id|download|media|type|width|height],button[class|style|autofocus|autocomplete|name|onclick|disable|id|type|value|width|formtarget|formnovalidate|formmethod|formenctype|formaction|form|height]',
  };

  payload = {};
  templates = [];

  images = [];

  contentChangedRaw = false;
  contentChangedTiny = false;
  contentChangedText = false;

  constructor(
    private clearTextPipe: ClearTextPipe,
    private _clipboardService: ClipboardService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.result.subscribe((res) => {
      this.resultEvent();
    });

    this.setup();

    setInterval(() => {
      if (this.inputType === 'Editor' && this.emailEditor && this.isUnlayerActive == true) {
        this.emailEditor.exportHtml((data) => {
          if (data) {
            this.getStructure.emit(data['design'] || '');
            this.getContent.emit(data['html'] || '');
          }
        });
      }
    }, 200);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setup();
  }

  setup() {
    this.contentInnerTiny = this.content;

    this.contentInnerRaw = this.content;

    this.contentInnerText = this.content;

    if (this.content) {
      this.isTinyExist = true;
      this.isRawExist = true;
      this.isTextExist = true;
      this.isContentExist = true;
    }

    if (this.displayUploader != true && this.inputType == 'Document') {
      this.inputType = 'TinyEditor';
    } else if (this.inputType != 'Document' && this.EPDocumentID) {
      this.inputType = 'Document';
    } else if (this.isContentExist == true && !this.structure && this.inputType != 'Raw' && this.inputType != 'Text') {
      this.inputType = 'TinyEditor';
    }
    if (this.inputType === 'TinyEditor' && this.isTinyEditorActive != true) {
      this.inputType = 'Raw';
    } else if (this.inputType === 'Editor' && this.isUnlayerActive != true) {
      this.inputType = 'TinyEditor';
    }
  }

  onCompleteAllHTML(fileID) {
    if (fileID && fileID[0]) {
      this.fileID = fileID[0];
    }
  }

  editorLoaded() {
    if (this.emailEditor && this.structure && this.structure.body) {
      this.emailEditor.loadDesign(this.structure);
    }
  }

  downloadHtmlContent(c) {
    if (c) {
      const blob = new Blob([this.clearTextPipe.transform(c)], { type: 'text/html' });

      if (this.label) {
        if (this.isCss === true) {
          FileSaver.saveAs(blob, `${this.label}.css`);
        } else {
          FileSaver.saveAs(blob, `${this.label}.html`);
        }
      } else {
        if (this.isCss === true) {
          FileSaver.saveAs(blob, `style.css`);
        } else {
          FileSaver.saveAs(blob, `content.html`);
        }
      }
    }
  }

  EditSourceCode(c, type = 'Raw') {
    const ref2 = RootAppComponent.dialog.open(CodeHTMLEditComponent, {
      data: {
        content: c || '',
        isCSS: this.isCss,
      },
      width: '80%',
    });
    ref2.componentInstance.getResult.subscribe((res) => {
      if (type === 'Raw') {
        this.contentInnerRaw = res;
        this.contentChangedRaw = true;
        this.getStructure.emit(null);
        this.getContent.emit(res);
      } else if (type === 'Tiny') {
        this.contentInnerTiny = res;
        this.contentChangedTiny = true;
        this.getStructure.emit(null);
        this.getContent.emit(res);
      } else if (type === 'Text') {
        this.contentInnerText = res;
        this.contentChangedText = true;
        this.getStructure.emit(null);
        this.getContent.emit(res);
      }
      ref2.close();
    });

    ref2.componentInstance.close.subscribe((res) => {
      ref2.close();
    });
  }

  ViewHtmlContent(c) {
    if (c) {
      let isPreview = true;
      const isCode = true;

      if (this.isCss == true) {
        isPreview = false;
      }

      const ref2 = RootAppComponent.dialog.open(CodeHTMLViewComponent, {
        data: {
          icons: 'fas fa-eye',
          title: 'preview',
          content: c,
          isPreview,
          isCode,
        },
        width: '1000px',
      });
      ref2.componentInstance.onConfirm.subscribe((res) => {
        ref2.close();
      });
    }
  }

  ViewHtmlContentEditor() {
    if (this.emailEditor) {
      this.emailEditor.exportHtml((data) => {
        if (data && data['html']) {
          const ref2 = RootAppComponent.dialog.open(CodeHTMLViewComponent, {
            data: {
              icons: 'fas fa-eye',
              title: 'preview',
              content: data['html'],
              isPreview: true,
              isCode: true,
            },
            width: '1000px',
          });
          ref2.componentInstance.onConfirm.subscribe((res) => {
            ref2.close();
          });
        }
      });
    }
  }

  exportHtml() {
    if (this.emailEditor) {
      this.emailEditor.exportHtml((data) => {
        if (data && data['html']) {
          const blob = new Blob([data['html']], { type: 'text/html' });

          if (this.label) {
            FileSaver.saveAs(blob, `${this.label}.html`);
          } else {
            FileSaver.saveAs(blob, `Content.html`);
          }
        }
      });
    }
  }

  exportJson() {
    if (this.emailEditor) {
      this.emailEditor.exportHtml((data) => {
        const blob = new Blob([JSON.stringify(data['design'])], { type: 'application/json;charset=utf-8' });

        if (this.label) {
          FileSaver.saveAs(blob, `${this.label}.json`);
        } else {
          FileSaver.saveAs(blob, `Design.json`);
        }
      });
    }
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);

    const reader = new FileReader();
    reader.readAsText(this.fileToUpload, 'UTF-8');

    reader.onload = (e: any) => {
      const temp = JSON.parse(e.target.result);
      if (this.emailEditor) {
        this.emailEditor.loadDesign(temp);
      }
    };
  }

  changeView(d) {
    this.isHalfView = d;
  }

  reset(type) {
    const confirm = new ConfirmDialog(
      'fas fa-undo',
      'Are you sure ?',
      'Resetting the content to it default value, this will erase any new changes you made',
      'No',
      'Yes'
    );
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
    });
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        if (type == 'Text') {
          this.contentInnerText = this.content;
          ref.close();
        } else if (type == 'Raw') {
          this.contentInnerRaw = this.content;
          ref.close();
        } else if (type == 'Tiny') {
          this.contentInnerTiny = this.content;
          ref.close();
        } else if (type == 'Editor') {
          this.contentInnerTiny = this.content;
          if (this.emailEditor && this.structure && this.structure.body) {
            this.emailEditor.loadDesign(this.structure);

            ref.close();
          }
        }
      }
    });
  }

  resultEvent() {
    const payload = {
      fileID: null,
      htmlContent: null,
      htmlContentBlob: null,
      structure: null,
      inputType: this.inputType,
    };

    if (this.inputType === 'Raw') {
      this.content = this.contentInnerRaw;
      payload.htmlContent = this.content || null;
      payload['htmlContentBlob'] = btoa(this.content) || null;
      this.getResult.emit(payload);
    } else if (this.inputType === 'Text') {
      this.content = this.contentInnerText;
      payload.htmlContent = this.content || null;
      this.getResult.emit(payload);
    } else if (this.inputType === 'TinyEditor') {
      this.content = this.contentInnerTiny;
      payload.htmlContent = this.content || null;
      this.getResult.emit(payload);
    } else if (this.inputType === 'Document') {
      payload.fileID = this.fileID || null;
      this.getResult.emit(payload);
    } else if (this.inputType === 'Editor' && this.emailEditor) {
      this.emailEditor.exportHtml((data) => {
        if (data['html'] && data['design']) {
          payload['htmlContent'] = data['html'] || null;
          this.content = data['html'];
          payload['htmlContentBlob'] = btoa(data['html']) || null;
          payload.structure = data['design'] || null;
          this.getResult.emit(payload);
        }
      });
    } else {
      this.getResult.emit(payload);
    }
  }

  options() {
    return {
      projectId: 4036,

      features: {
        undoRedo: true,
        textEditor: {
          cleanPaste: true,
          spellChecker: true,
          tables: true,
          emojis: true,
        },
        preview: true,
        pageAnchors: true,
        imageEditor: true,
        userUploads: true,
        stockImages: {
          enabled: true,
          safeSearch: true,
          defaultSearchTerm: 'people',
        },
        audit: true,
        preheaderText: true,
      },
    };
  }

  sendContent(d, type = 'Raw') {
    if (type == 'Raw') {
      this.contentChangedRaw = true;

      if (d) {
        this.isRawExist = true;
      } else {
        this.isRawExist = false;
      }
      this.getStructure.emit(null);
    } else if (type == 'Text') {
      this.contentChangedText = true;
      this.contentInnerText = d;

      if (d) {
        this.isTextExist = true;
      } else {
        this.isTextExist = false;
      }
      this.getStructure.emit(null);
    } else if (type == 'Tiny') {
      this.contentChangedTiny = true;

      if (d) {
        this.isTinyExist = true;
      } else {
        this.isTinyExist = false;
      }
      this.getStructure.emit(null);
    }

    this.getContent.emit(d);
  }

  buildTitle(title, description) {
    let result = '';

    if (title) {
      result = result + '<strong class="title-header">' + this.title + '</strong> ';
    }

    if (description) {
      result = result + description;
    }

    return result;
  }

  copyID() {
    if (this.componentID) {
      this._clipboardService.copy(`#${this.componentID} `);

      NotifyAppComponent.displayToast('success', 'success', 'Copied');
    }
  }
}
