import { Routes } from '@angular/router';
import { FundersRevenueReportPage } from './funders-revenue-report/funders-revenue-report.page';
const ɵ0 = {
    title: 'Revenue Report',
};
const routes = [
    {
        path: 'revenue-report',
        component: FundersRevenueReportPage,
        data: ɵ0,
        outlet: 'page',
    },
];
export class FundersRoutingModule {
}
export { ɵ0 };
