<div class="row clearfix onboarding">
  <div class="row clearfix">
    <p class="small rm-mb pull-left">Progress...</p>
    <p class="small rm-mb pull-right">{{ value | percent }}</p>
  </div>
  <div class="row clearfix mb">
    <mat-progress-bar [value]="prograssValue" mode="determinate"></mat-progress-bar>
  </div>

  <mat-list>
    <mat-list-item class="instruction">
      <mat-icon class="fas fa-info-circle"></mat-icon>
      <div class="text full-width">
        <p class="small rm-m">Complete these tasks to start using your new tools.</p>
        <p class="small smaller">Takes about 2 minutes.</p>
      </div>
    </mat-list-item>
    <hr/>
    <mat-list-item *ngFor="let t of tasks; let i = index">
      <mat-icon class="fas fa-check-circle" [class.complete]="t['IsCompleted'] == '1'"></mat-icon>

      <p class="contentlabel" [class.complete]="t['IsCompleted'] == '1'">{{ t['Task.Description'] }}</p>

      <button
        class="btn-small"
        *ngIf="t['Task.Name'] != 'Send Invite'"
        [disabled]="t['IsCompleted'] == '1'"
        (click)="actions(t)"
        mat-raised-button
        color="accent"
      >
        {{ t['Task.Name'] }}
      </button>

      <button
        class="btn-small"
        *ngIf="t['Task.Name'] == 'Send Invite'"
        [matMenuTriggerFor]="extendedInviteMenu"
        [disabled]="t['IsCompleted'] == '1'"
        mat-raised-button
        color="accent"
      >
        {{ t['Task.Name'] }}
      </button>

      <mat-menu #extendedInviteMenu="matMenu">
        <div *ngIf="isModuleTreatmentPlanActive == true">
          <div *ngFor="let p of financedProductGroup">
            <button (click)="newPatientInv(p)" mat-menu-item>
              New <strong>{{ getLabelFromProductGroup(p) }}</strong> Invitation
              <mat-icon class="position fas {{ getProductIcon(p) }} "></mat-icon>
            </button>
            <mat-divider></mat-divider>
          </div>
        </div>
      </mat-menu>
    </mat-list-item>
  </mat-list>
</div>
