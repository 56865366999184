<div class="clearfix rel row primary-gradient-img heading" *ngIf="isModal == true && campaign">
  <button class="btn-close btn-clear mat-button white pull-right" *ngIf="isModal == true" (click)="closeModal()">
    <mat-icon>clear</mat-icon>
  </button>
  <div class="titleArea review ml pull-left">
    <h2 class="sr-title sm-h2 rm-m white">{{ campaign['Label'] }}</h2>
    <p class="rm-m">
      Status <span class="chip">{{ campaign['Status.Label'] }}</span>
    </p>
  </div>
  <!-- Action Buttons -->
  <div class="clearfix button-actions">
    <button class="pull-right" [matMenuTriggerFor]="menu" mat-raised-button>
      <span class="mobHide">Actions</span>
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button
        *ngIf="campaign['ID'] && campaign['Status.Code'] == 'PEND'"
        (click)="editCampaign(row['ID'])"
        mat-menu-item
      >
        <mat-icon>edit</mat-icon>
        <span>Continue campaign</span>
      </button>

      <button
        *ngIf="
          campaign['ID'] &&
          campaign['Status.Code'] != 'CNCL' &&
          campaign['Status.Code'] != 'ABRT' &&
          campaign['Status.Code'] != 'TIMD' &&
          campaign['Status.Code'] != 'PEND'
        "
        (click)="editCampaignQuick(campaign['ID'])"
        mat-menu-item
      >
        <mat-icon>edit</mat-icon>
        <span>Quick Edit</span>
      </button>

      <mat-divider
        *ngIf="
          campaign['ID'] &&
          campaign['Status.Code'] != 'CNCL' &&
          campaign['Status.Code'] != 'ABRT' &&
          campaign['Status.Code'] != 'TIMD' &&
          campaign['Status.Code'] != 'PEND'
        "
      >
      </mat-divider>
      <button
        *ngIf="isPromoterOrAdmin == true && campaign['Merchant_key']"
        (click)="openMerchantDialog()"
        mat-menu-item
      >
        <mat-icon>person</mat-icon>
        <span class="mobHide">View Merchant</span>
      </button>
      <button *ngIf="campaign['Contact_key']" (click)="openDentistDialog()" mat-menu-item>
        <mat-icon>person</mat-icon>
        <span class="mobHide">View {{ "KEYWORD.dentist" | translate | titlecase }}</span>
      </button>
      <button
        *ngIf="isPromoterOrAdmin == true && campaign['Merchant_key']"
        (click)="contactMerchantDialog()"
        mat-menu-item
      >
        <mat-icon>contact_mail</mat-icon>
        <span class="mobHide">Contact Merchant</span>
      </button>

      <button
        *ngIf="campaign['Social_Media_Only'] != '1' && campaign['Status.Code'] == 'CMPL'"
        (click)="viewNonReachedList()"
        mat-menu-item
      >
        <mat-icon>speaker_notes_off</mat-icon>
        <span class="mobHide">Targets not reached</span>
      </button>

      <mat-divider></mat-divider>

      <button
        *ngIf="
          (campaign['Status.Code'] == 'ACTV' || campaign['Status.Code'] == 'CMPL') &&
          campaign['Is_Public'] == '0' &&
          campaign['CampaignStatus'] == 'Active'
        "
        (click)="addToPracticePage(campaign['ID'], true)"
        mat-menu-item
      >
        <mat-icon>visibility</mat-icon>
        <span>Add to the Offers Page</span>
      </button>

      <button
        *ngIf="
          (campaign['Status.Code'] == 'ACTV' || campaign['Status.Code'] == 'CMPL') &&
          campaign['Is_Public'] == '1' &&
          campaign['CampaignStatus'] == 'Active'
        "
        (click)="addToPracticePage(campaign['ID'], false)"
        mat-menu-item
      >
        <mat-icon>visibility_off</mat-icon>
        <span>Remove from the Offers Page</span>
      </button>

      <button
        *ngIf="campaign['Status.Code'] == 'CMPL' || campaign['Status.Code'] == 'ACTV'"
        (click)="viewSocialMedia(campaign['ID'])"
        mat-menu-item
      >
        <mat-icon>share</mat-icon>

        <span>Share Campaign</span>
      </button>

      <mat-divider></mat-divider>

      <button
        *ngIf="
          (campaign['Status.Code'] == 'PEND' ||
            campaign['Status.Code'] == 'TIMD' ||
            campaign['Status.Code'] == 'ACTV' ||
            campaign['Status.Code'] == 'CMPL') &&
          campaign['CampaignStatus'] == 'Active'
        "
        (click)="cancelCampaign()"
        mat-menu-item
      >
        <mat-icon *ngIf="campaign['Status.Code'] != 'CMPL'">cancel</mat-icon>
        <span *ngIf="campaign['Status.Code'] != 'CMPL'">Cancel Campaign</span>

        <mat-icon *ngIf="campaign['Status.Code'] == 'CMPL'">cancel</mat-icon>
        <span *ngIf="campaign['Status.Code'] == 'CMPL'">End Campaign</span>
      </button>

      <button
        *ngIf="
          campaign['Social_Media_Only'] != '1' &&
          (campaign['Status.Code'] == 'TIMD' || campaign['Status.Code'] == 'ACTV')
        "
        (click)="resendCampaign()"
        mat-menu-item
      >
        <mat-icon>refresh</mat-icon>
        <span class="mobHide">Resend Campaign</span>
      </button>
      <button
        *ngIf="campaign['Social_Media_Only'] != '1' && campaign['Status.Code'] == 'CMPL'"
        (click)="resendCampaign()"
        mat-menu-item
      >
        <mat-icon>refresh</mat-icon>
        <span class="mobHide">Reschedule Campaign</span>
      </button>
      <!-- <button mat-menu-item (click)="activeCampaign()"
        *ngIf="campaign['Social_Media_Only']!='1' &&  campaign['Status.Code']=='PEND'">
        <mat-icon>done_outline</mat-icon>
        <span class="mobHide">Active Campaign</span>
      </button> -->
    </mat-menu>
  </div>
</div>
<mat-dialog-content [ngClass]="{ 'no-modal-height': isModal != true }">
  <div
    class="row clearfix white-bg campaignResults"
    *ngIf="campaign && displayCampaignMessage == false && displayNonReachedList == false"
  >
    <div class="row clearfix">
      <mat-card class="card mat-elevation-z3">
        <div class="clearfix rel row primary-gradient-img heading" *ngIf="isModal != true">
          <div class="titleArea review ml pull-left">
            <h2 class="sr-title sm-h2 rm-m white">{{ campaign['Label'] }}</h2>
            <p class="rm-m">
              Status <span class="chip">{{ campaign['Status.Label'] }}</span>
            </p>
          </div>
          <!-- Action Buttons -->
          <div class="clearfix button-actions">
            <button class="pull-right" [matMenuTriggerFor]="menu" mat-raised-button>
              <span class="mobHide">Actions</span>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button
                *ngIf="campaign['ID'] && campaign['Status.Code'] == 'PEND'"
                (click)="editCampaign(row['ID'])"
                mat-menu-item
              >
                <mat-icon>edit</mat-icon>
                <span>Continue campaign</span>
              </button>

              <button
                *ngIf="
                  campaign['ID'] &&
                  campaign['Status.Code'] != 'CNCL' &&
                  campaign['Status.Code'] != 'ABRT' &&
                  campaign['Status.Code'] != 'TIMD' &&
                  campaign['Status.Code'] != 'PEND'
                "
                (click)="editCampaignQuick(campaign['ID'])"
                mat-menu-item
              >
                <mat-icon>edit</mat-icon>
                <span>Quick Edit</span>
              </button>

              <mat-divider
                *ngIf="
                  campaign['ID'] &&
                  campaign['Status.Code'] != 'CNCL' &&
                  campaign['Status.Code'] != 'ABRT' &&
                  campaign['Status.Code'] != 'TIMD' &&
                  campaign['Status.Code'] != 'PEND'
                "
              >
              </mat-divider>
              <button
                *ngIf="isPromoterOrAdmin == true && campaign['Merchant_key']"
                (click)="openMerchantDialog()"
                mat-menu-item
              >
                <mat-icon>person</mat-icon>
                <span class="mobHide">View Merchant</span>
              </button>
              <button *ngIf="campaign['Contact_key']" (click)="openDentistDialog()" mat-menu-item>
                <mat-icon>person</mat-icon>
                <span class="mobHide">View {{ "KEYWORD.dentist" | translate | titlecase }}</span>
              </button>
              <button
                *ngIf="isPromoterOrAdmin == true && campaign['Merchant_key']"
                (click)="contactMerchantDialog()"
                mat-menu-item
              >
                <mat-icon>contact_mail</mat-icon>
                <span class="mobHide">Contact Merchant</span>
              </button>

              <button
                *ngIf="campaign['Social_Media_Only'] != '1' && campaign['Status.Code'] == 'CMPL'"
                (click)="viewNonReachedList()"
                mat-menu-item
              >
                <mat-icon>speaker_notes_off</mat-icon>
                <span class="mobHide">Targets not reached</span>
              </button>

              <mat-divider></mat-divider>

              <button
                *ngIf="
                  (campaign['Status.Code'] == 'ACTV' || campaign['Status.Code'] == 'CMPL') &&
                  campaign['Is_Public'] == '0' &&
                  campaign['CampaignStatus'] == 'Active'
                "
                (click)="addToPracticePage(campaign['ID'], true)"
                mat-menu-item
              >
                <mat-icon>visibility</mat-icon>
                <span>Add to the Offers Page</span>
              </button>

              <button
                *ngIf="
                  (campaign['Status.Code'] == 'ACTV' || campaign['Status.Code'] == 'CMPL') &&
                  campaign['Is_Public'] == '1' &&
                  campaign['CampaignStatus'] == 'Active'
                "
                (click)="addToPracticePage(campaign['ID'], false)"
                mat-menu-item
              >
                <mat-icon>visibility_off</mat-icon>
                <span>Remove from the Offers Page</span>
              </button>

              <button
                *ngIf="campaign['Status.Code'] == 'CMPL' || campaign['Status.Code'] == 'ACTV'"
                (click)="viewSocialMedia(campaign['ID'])"
                mat-menu-item
              >
                <mat-icon>share</mat-icon>

                <span>Share Campaign</span>
              </button>

              <mat-divider></mat-divider>

              <button
                *ngIf="
                  (campaign['Status.Code'] == 'PEND' ||
                    campaign['Status.Code'] == 'TIMD' ||
                    campaign['Status.Code'] == 'ACTV' ||
                    campaign['Status.Code'] == 'CMPL') &&
                  campaign['CampaignStatus'] == 'Active'
                "
                (click)="cancelCampaign()"
                mat-menu-item
              >
                <mat-icon *ngIf="campaign['Status.Code'] != 'CMPL'">cancel</mat-icon>
                <span *ngIf="campaign['Status.Code'] != 'CMPL'">Cancel Campaign</span>

                <mat-icon *ngIf="campaign['Status.Code'] == 'CMPL'">cancel</mat-icon>
                <span *ngIf="campaign['Status.Code'] == 'CMPL'">End Campaign</span>
              </button>

              <button
                *ngIf="
                  campaign['Social_Media_Only'] != '1' &&
                  (campaign['Status.Code'] == 'TIMD' || campaign['Status.Code'] == 'ACTV')
                "
                (click)="resendCampaign()"
                mat-menu-item
              >
                <mat-icon>refresh</mat-icon>
                <span class="mobHide">Resend Campaign</span>
              </button>
              <button
                *ngIf="campaign['Social_Media_Only'] != '1' && campaign['Status.Code'] == 'CMPL'"
                (click)="resendCampaign()"
                mat-menu-item
              >
                <mat-icon>refresh</mat-icon>
                <span class="mobHide">Reschedule Campaign</span>
              </button>
              <!-- <button mat-menu-item (click)="activeCampaign()"
              *ngIf="campaign['Social_Media_Only']!='1' &&  campaign['Status.Code']=='PEND'">
              <mat-icon>done_outline</mat-icon>
              <span class="mobHide">Active Campaign</span>
            </button> -->
            </mat-menu>
          </div>
        </div>
        <div class="row clearfix flex">
          <div class="full-width mr row">
            <div class="row clearfix mt">
              <div class="row clearfix mt">
                <div class="well status-height text-center" *ngIf="campaign['Status.Label'] == 'Pending'">
                  <h3 class="sm-h3">Campaign has been saved as a draft...</h3>
                </div>
                <div class="well status-height text-center" *ngIf="campaign['Status.Label'] == 'Active'">
                  <h3 class="sm-h3">Campaign has not been delivered yet...</h3>
                </div>
                <div class="well status-height text-center" *ngIf="campaign['Status.Label'] == 'Timed'">
                  <h3 class="sm-h3">Campaign is pending delivery...</h3>
                </div>
                <div class="well status-height text-center" *ngIf="campaign['Status.Label'] == 'Aborted'">
                  <h3 class="sm-h3 accent-color">Campaign was canceled before delivery.</h3>
                </div>

                <div class="well status-height text-center" *ngIf="campaign['Status.Label'] == 'Expired'">
                  <h3 class="sm-h3 accent-color">Campaign is Expired.</h3>
                </div>
              </div>

              <div class="row clearfix flex">
                <div class="full-width">
                  <h3 class="sm-h3 sr-title clearfix rm-m reduce-marg">Campaign Details</h3>
                  <div class="row clearfix">
                    <div class="row clearfix">
                      <div class="icons-col">
                        <mat-icon>person</mat-icon>
                      </div>
                      <div class="row-content-col">
                        <label class="small">Merchant Name</label>
                        <p class="rm-mt">{{ campaign['Merchant.Name'] }}</p>
                      </div>
                    </div>

                    <div class="row clearfix" *ngIf="campaign && campaign['filterName']">
                      <div class="icons-col">
                        <mat-icon>supervised_user_circle</mat-icon>
                      </div>
                      <div class="row-content-col">
                        <label class="small">Filter Label</label>
                        <p class="rm-mt">{{ campaign['filterName'] }}</p>
                      </div>
                    </div>
                    <div class="row clearfix">
                      <div class="icons-col">
                        <mat-icon>pageview</mat-icon>
                      </div>
                      <div class="row-content-col">
                        <label class="small">Campaign type</label>
                        <p class="rm-mt" *ngIf="campaign['Social_Media_Only'] != '1'">Email, SMS and social media</p>
                        <p class="rm-mt" *ngIf="campaign['Social_Media_Only'] == '1'">Only social media</p>
                      </div>
                    </div>

                    <div class="row clearfix">
                      <div class="icons-col">
                        <mat-icon *ngIf="campaign['Is_Public'] == '1'">visibility</mat-icon>
                        <mat-icon *ngIf="campaign['Is_Public'] == '0'">visibility_off</mat-icon>
                      </div>
                      <div class="row-content-col">
                        <label class="small">Added to the Offers Page</label>
                        <p class="rm-mt" *ngIf="campaign['Is_Public'] == '1'">Yes</p>
                        <p class="rm-mt" *ngIf="campaign['Is_Public'] != '1'">No</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="full-width" *ngIf="userData">
                  <h3 class="sm-h3 sr-title clearfix rm-m reduce-marg">Extra details</h3>

                  <div class="row clearfix">
                    <div class="clearfix" *ngFor="let m of metaData">
                      <div class="" *ngIf="m['ID'] == 'Amount'">
                        <label class="small">{{ getLabel(m['Label']) }}</label>
                        <p class="rm-mt">{{ userData[m['ID']] | customCurrency }}</p>
                      </div>
                      <div class="" *ngIf="m['ID'] == 'ProductGroup'">
                        <label class="small">{{ getLabel(m['Label']) }}</label>
                        <p class="rm-mt">{{ productGroupLabel }}</p>
                      </div>
                      <div class="" *ngIf="m['ID'] != 'Amount' && m['ID'] != 'ProductGroup'">
                        <label class="small">{{ getLabel(m['Label']) }}</label>
                        <p class="rm-mt">{{ userData[m['ID']] }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="full-width">
                  <h3 class="sm-h3 sr-title clearfix rm-m reduce-marg">Dates</h3>
                  <div class="row clearfix">
                    <div class="row clearfix">
                      <div class="icons-col">
                        <mat-icon>alarm_add</mat-icon>
                      </div>
                      <div class="row-content-col">
                        <label class="small">Created</label>
                        <p class="rm-mt">{{ campaign['DateTimeCreated'] | customDateTime: 'DD/MM/YYYY hh:mm A' }}</p>
                      </div>
                    </div>
                    <div
                      class="row clearfix"
                      *ngIf="
                        campaign['Status.Code'] == 'CMPL' &&
                        campaign['Finished.Email'] &&
                        campaign['Finished.Email'] != '0000-00-00 00:00:00'
                      "
                    >
                      <div class="icons-col">
                        <mat-icon>alarm_on</mat-icon>
                      </div>
                      <div class="row-content-col">
                        <label class="small">Delivered</label>
                        <p class="rm-mt">{{ campaign['Finished.Email'] | customDateTime: 'DD/MM/YYYY hh:mm A' }}</p>
                      </div>
                    </div>
                    <div class="row clearfix" *ngIf="campaign['WhenToExpire']">
                      <div class="icons-col">
                        <mat-icon>alarm_off</mat-icon>
                      </div>
                      <div class="row-content-col">
                        <label class="small">Expired</label>
                        <p class="rm-mt">{{ campaign['WhenToExpire'] | customDateTime: 'DD/MM/YYYY hh:mm A' }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="full-width row clearfix mt" *ngIf="campaign['StaticList.Label']">
                <div class="row clearfix">
                  <div class="icons-col">
                    <mat-icon>flag</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <label class="small">Tags</label>
                    <div class="clearfix mt mb">
                      <app-tag-list
                        [selectedTags]="campaign['StaticList.Label']"
                        [tagFormatIsObject]="false"
                        [canRemove]="false"
                        [canCreate]="false"
                        [hasFilter]="false"
                        [canSelect]="false"
                      >
                      </app-tag-list>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="full-width ml row"
            *ngIf="
              campaign &&
              (campaign['ActionCall.Label'] ||
                campaign['ActionCall.Description'] ||
                campaign['Facebook_Set_Date'] != '0000-00-00' ||
                campaign['Pinterest_Set_Date'] != '0000-00-00' ||
                campaign['LinkedIn_Set_Date'] != '0000-00-00' ||
                campaign['Twitter_Set_Date'] != '0000-00-00' ||
                campaign['Instagram_Set_Date'] != '0000-00-00')
            "
          >
            <div class="row clearfix mt">
              <div class="full-width clearfix" *ngIf="campaign && campaign['ActionCall.Label']">
                <h3 class="sm-h3 sr-title clearfix rm-m reduce-marg">Call to Action (CTA)</h3>

                <div class="clearfix">
                  <p class="rm-mt">{{ campaign['ActionCall.Label'] }}</p>
                </div>
              </div>
            </div>

            <div class="row clearfix">
              <div class="full-width clearfix" *ngIf="campaign && campaign['ActionCall.Description']">
                <h3 class="sm-h3 sr-title clearfix rm-m reduce-marg">Description/Preview</h3>

                <div class="clearfix">
                  <p class="rm-mt">{{ campaign['ActionCall.Description'] }}</p>
                </div>
              </div>
            </div>

            <div
              class="row clearfix"
              *ngIf="
                campaign['Facebook_Set_Date'] != '0000-00-00' ||
                campaign['LinkedIn_Set_Date'] != '0000-00-00' ||
                campaign['Twitter_Set_Date'] != '0000-00-00' ||
                campaign['Instagram_Set_Date'] != '0000-00-00'
              "
            >
              <h3 class="sm-h3 sr-title clearfix rm-m reduce-marg">Social Media</h3>
              <div class="row clearfix socials">
                <div
                  class="mr rm-mt social-media-tag flex pull-left"
                  *ngIf="campaign['Facebook_Set_Date'] != '0000-00-00'"
                  [style.background-color]="'#4267B2'"
                  matTooltip="Posted on Facebook at: {{ campaign['Facebook_Set_Date'] | customDate }}"
                >
                  <mat-icon class="fab fa-facebook-f"></mat-icon>
                  <p class="mr rm-mt rm-mb">
                    {{ campaign['Facebook_Set_Date'] | customDate }}
                  </p>

                  <div
                    class="mr rm-mt social-media-tag flex pull-left"
                    *ngIf="campaign['Pinterest_Set_Date'] != '0000-00-00'"
                    [style.background-color]="'#4267B2'"
                    matTooltip="Posted on Pinterest at: {{ campaign['Pinterest_Set_Date'] | customDate }}"
                  >
                    <mat-icon class="fab fa-pinterest"></mat-icon>
                    <p class="mr rm-mt rm-mb">
                      {{ campaign['Pinterest_Set_Date'] | customDate }}
                    </p>
                  </div>
                </div>
                <div
                  class="mr rm-mt social-media-tag flex pull-left"
                  *ngIf="campaign['Twitter_Set_Date'] != '0000-00-00'"
                  [style.background-color]="'#1DA1F2'"
                  matTooltip="Posted on LinkedIn at: {{ campaign['Twitter_Set_Date'] | customDate }}"
                >
                  <mat-icon class="fab fa-twitter"></mat-icon>
                  <p class="mr rm-mt rm-mb">
                    {{ campaign['Twitter_Set_Date'] | customDate }}
                  </p>
                </div>
                <div
                  class="mr rm-mt social-media-tag flex pull-left"
                  *ngIf="campaign['LinkedIn_Set_Date'] != '0000-00-00'"
                  [style.background-color]="'#2867B2'"
                  matTooltip="Posted on LinkedIn at: {{ campaign['LinkedIn_Set_Date'] | customDate }}"
                >
                  <mat-icon class="fab fa-linkedin-in"></mat-icon>
                  <p class="mr rm-mt rm-mb">
                    {{ campaign['LinkedIn_Set_Date'] | customDate }}
                  </p>
                </div>
                <div
                  class="mr rm-mt social-media-tag flex pull-left"
                  *ngIf="campaign['Instagram_Set_Date'] != '0000-00-00'"
                  [style.background-color]="'#E1306C'"
                  matTooltip="Posted on Instagram at: {{ campaign['Instagram_Set_Date'] | customDate }}"
                >
                  <mat-icon class="fab fa-instagram"></mat-icon>

                  <p class="mr rm-mt rm-mb">
                    {{ campaign['Instagram_Set_Date'] | customDate }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row clearfix grey" *ngIf="false">
          <div class="row clearfix">
            <div class="half-width">
              <div class="row clearfix">
                <div class="icons-col">
                  <mat-icon>email</mat-icon>
                </div>
                <div class="row-content-col">
                  <label class="small">Email </label>
                  <p class="rm-mt">
                    <span class="primary-color">
                      Campaign has sent <span class="accent-color">{{ successedEmail }}</span>
                      Emails
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="half-width" *ngIf="successedSMS.length > 0">
              <div class="row clearfix">
                <div class="icons-col">
                  <mat-icon>comment</mat-icon>
                </div>
                <div class="row-content-col">
                  <label class="small">SMS </label>
                  <p class="rm-mt">
                    <span class="primary-color">
                      Campaign has sent <span class="accent-color">{{ successedSMS }}</span>
                      SMS
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <div class="row clearfix">
            <div class="half-width">
              <div class="row clearfix">
                <div class="icons-col">
                  <mat-icon>call_made</mat-icon>
                </div>
                <div class="row-content-col">
                  <label class="small">Reached </label>
                  <p class="rm-mt">
                    <span class="primary-color" *ngIf="allTarget != 0"
                    >This campaign has reached
                      <span class="accent-color">{{ successedTarget / allTarget | percent: '2.1-1' }}</span> of
                      potential targets
                    </span>
                  </p>
                  <p class="rm-mt">
                    <span class="primary-color" *ngIf="allTarget == 0"
                    >This campaign has reached
                      <span class="accent-color">
                        {{ 0 | percent: '2.1-1' }}
                      </span>
                      of potential targets
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="row clearfix flex">
      <div class="half-width flex flexCol">
        <mat-card
          class="full-width mat-elevation-z3"
          *ngIf="campaign['Social_Media_Only'] != '1'"
          [class.mb]="isModuleBoostReachActive == false"
        >
          <div class="row clearfix">
            <h3 class="sr-title pull-left rm-m">{{"KEYWORD.patient" | translate | titlecase}} data</h3>
            <button
              class="btn-small pull-right"
              (click)="patientDataChart = !patientDataChart"
              mat-raised-button
              color="primary"
            >
              <mat-icon class="fas fa-chart-pie"></mat-icon> Chart breakdown
            </button>
          </div>
          <hr/>
          <!-- <hr> -->
          <div class="chart-view" *ngIf="patientDataChart == true" @simpleFadeAnimation>
            <div class="base-chart-margin rel mr" *ngIf="campaign['Status.Label'] == 'Complete'">
              <div class="total anim totalPosition">
                <span [endVal]="totalPatient" countUp></span>
                <span class="totSub">Total</span>
              </div>
              <app-chart-input
                class="fullHeight mb clearfix"
                [titleIsTop]="false"
                [showLegend]="true"
                [legendPosition]="'bottom'"
                [showLabel]="false"
                [chartType]="'pie'"
                [title]=""
                [data]="chartPatientData | orderBy: ['-name']"
                [dataNameAttribute]="'name'"
                [dataNameAttribute]="'value'"
              ></app-chart-input>
            </div>
          </div>
          <div class="list-view" *ngIf="patientDataChart == false" @simpleFadeAnimation>
            <mat-list>
              <mat-list-item>
                <div class="row clearfix full-width" matLine>
                  <p class="reduce-marg bold pull-left">Total {{ "KEYWORD.patient" | translate | titlecase}}s</p>
                  <p class="reduce-marg bold pull-right">{{ totalPatient }}</p>
                </div>
                <div class="row clearfix full-width" matLine>
                  <p class="small">
                    <mat-icon class="fas fa-minus-circle"></mat-icon>
                    <em>Less</em>
                  </p>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>
              <mat-list-item *ngIf="campaign['Targets.NamedFilter_FK÷']">
                <div class="row flex-row">
                  <div class="pull-left">
                    <p class="reduce-marg pull-left">Don't match filter applied</p>
                  </div>
                  <div class="pull-right">
                    <button
                      class="btn-small pull-right ml"
                      *ngIf="patientFilter == true"
                      color="primary"
                      mat-stroked-button
                    >
                      <mat-icon>filter_list</mat-icon> Filter
                    </button>
                    <button class="btn-small pull-left ml" (click)="viewNonReachedList('missData')" mat-stroked-button>
                      <mat-icon>search</mat-icon> View
                    </button>
                    <ngx-gauge
                      class="no-title"
                      [size]="40"
                      [type]="'arch'"
                      [value]="missDataPatient"
                      [label]="''"
                      [max]="totalPatient"
                      [thresholds]="moreIsWorse"
                      [thick]="6"
                      [cap]="'round'"
                    >
                    </ngx-gauge>
                    <p class="reduce-marg pull-right">{{ campaign['Filtered_Patient'] }}</p>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>
              <mat-list-item>
                <div class="row flex-row">
                  <div class="pull-left">
                    <p class="reduce-marg pull-left">Missing Data (Email or Phone)</p>
                  </div>
                  <div class="pull-right">
                    <button
                      class="btn-small ml pull-right"
                      *ngIf="isModuleBoostReachActive == true"
                      (click)="openBoostDialog()"
                      mat-flat-button
                      color="accent"
                    >
                      <mat-icon class="fas fa-rocket"></mat-icon> Boost
                    </button>
                    <button class="btn-small pull-left ml" (click)="viewNonReachedList('missData')" mat-stroked-button>
                      <mat-icon>search</mat-icon> View
                    </button>
                    <ngx-gauge
                      class="no-title"
                      [size]="40"
                      [type]="'arch'"
                      [value]="missDataPatient"
                      [label]="''"
                      [max]="totalPatient"
                      [thresholds]="moreIsWorse"
                      [thick]="6"
                      [cap]="'round'"
                    >
                    </ngx-gauge>
                    <p class="reduce-marg pull-right">{{ missDataPatient }}</p>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>
              <mat-list-item>
                <div class="row flex-row">
                  <p class="reduce-marg">Duplicated Data (Email or Phone)</p>
                  <div class="pull-right">
                    <button
                      class="btn-small pull-left ml"
                      (click)="viewNonReachedList('DublicateContact')"
                      mat-stroked-button
                    >
                      <mat-icon>search</mat-icon> View
                    </button>
                    <ngx-gauge
                      class="no-title"
                      [size]="40"
                      [type]="'arch'"
                      [value]="allDuplicates"
                      [label]="''"
                      [max]="totalPatient"
                      [thresholds]="moreIsWorse"
                      [thick]="6"
                      [cap]="'round'"
                    >
                    </ngx-gauge>
                    <p class="reduce-marg pull-right">{{ allDuplicates }}</p>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>
              <mat-list-item>
                <div class="row flex-row">
                  <p class="reduce-marg">Blacklisted</p>
                  <div class="pull-right">
                    <button
                      class="btn-small pull-left ml"
                      (click)="viewNonReachedList('Blacklisted')"
                      mat-stroked-button
                    >
                      <mat-icon>search</mat-icon> View
                    </button>
                    <ngx-gauge
                      class="no-title"
                      [size]="40"
                      [type]="'arch'"
                      [value]="allBlocked"
                      [label]="''"
                      [max]="totalPatient"
                      [thresholds]="moreIsWorse"
                      [thick]="6"
                      [cap]="'round'"
                    >
                    </ngx-gauge>
                    <p class="reduce-marg pull-right">{{ allBlocked }}</p>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>

              <mat-list-item>
                <div class="row flex-row">
                  <p class="reduce-marg">No Marketing Consent given</p>
                  <div class="pull-right">
                    <button
                      class="btn-small pull-left ml"
                      (click)="viewNonReachedList('NoMarketConsent')"
                      mat-stroked-button
                    >
                      <mat-icon>search</mat-icon> View
                    </button>
                    <ngx-gauge
                      class="no-title"
                      [size]="40"
                      [type]="'arch'"
                      [value]="noMarketConsentPatient"
                      [label]="''"
                      [max]="totalPatient"
                      [thresholds]="moreIsWorse"
                      [thick]="6"
                      [cap]="'round'"
                    >
                    </ngx-gauge>
                    <p class="reduce-marg pull-right">{{ noMarketConsentPatient }}</p>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>

              <mat-list-item *ngIf="campaign['filterName']">
                <div class="row flex-row">
                  <p class="reduce-marg">
                    Excluded by Audience Filter
                    <br/>
                    <span> ( {{ campaign['filterName'] }} </span>)
                  </p>
                  <div class="pull-right">
                    <button
                      class="btn-small pull-left ml"
                      (click)="openFilterView(campaign['Targets.NamedFilter_FK'])"
                      mat-stroked-button
                    >
                      <mat-icon>search</mat-icon> View
                    </button>
                    <ngx-gauge
                      class="no-title"
                      [size]="40"
                      [type]="'arch'"
                      [value]="totalExcluded"
                      [label]="''"
                      [max]="totalPatient"
                      [thresholds]="moreIsWorse"
                      [thick]="6"
                      [cap]="'round'"
                    >
                    </ngx-gauge>
                    <p class="reduce-marg pull-right">{{ totalExcluded }}</p>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>

              <mat-list-item *ngIf="campaign['Tag.Label']">
                <div class="row flex-row">
                  <p class="reduce-marg">
                    Excluded by Audience Filter
                    <br/>
                    <span> ( {{ campaign['Tag.Label'] }} </span>)
                  </p>
                  <div class="pull-right">
                    <button class="btn-small pull-left ml" (click)="viewTag(tagId)" mat-stroked-button>
                      <mat-icon>search</mat-icon> View
                    </button>
                    <ngx-gauge
                      class="no-title"
                      [size]="40"
                      [type]="'arch'"
                      [value]="totalExcluded"
                      [label]="''"
                      [max]="totalPatient"
                      [thresholds]="moreIsWorse"
                      [thick]="6"
                      [cap]="'round'"
                    >
                    </ngx-gauge>
                    <p class="reduce-marg pull-right">{{ totalExcluded }}</p>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>

              <mat-list-item class="cltotal-row">
                <div class="row flex-row" matLine>
                  <p class="small rm-mb">
                    <mat-icon class="rotated">pause_circle_filled</mat-icon>
                    <em>Equals</em>
                  </p>
                </div>
                <div class="row flex-row" matLine>
                  <h3 class="green reduce-marg bold pull-left">Available {{ "KEYWORD.patient" | translate }}s</h3>
                  <div class="pull-right">
                    <button
                      class="btn-small pull-left ml"
                      (click)="viewCampaignMessage(null, 'sent')"
                      mat-stroked-button
                    >
                      <mat-icon>search</mat-icon> View
                    </button>
                    <ngx-gauge
                      class="no-title"
                      [size]="40"
                      [type]="'arch'"
                      [value]="successedTarget"
                      [label]="''"
                      [max]="totalPatient"
                      [thresholds]="moreIsBetter"
                      [thick]="6"
                      [cap]="'round'"
                    >
                    </ngx-gauge>
                    <h3 class="green reduce-marg pull-right bold">{{ successedTarget }}</h3>
                  </div>
                </div>
              </mat-list-item>
            </mat-list>
          </div>
        </mat-card>
        <mat-card class="card full-width mat-elevation-z3" *ngIf="isModuleBoostReachActive == true">
          <div class="row clearfix">
            <h3 class="sr-title pull-left rm-m">Boost Data</h3>
            <button
              class="btn-small pull-right"
              (click)="boostDataChart = !boostDataChart"
              mat-raised-button
              color="primary"
            >
              <mat-icon class="fas fa-chart-pie"></mat-icon> Chart breakdown
            </button>
          </div>
          <!-- <hr> -->
          <hr/>
          <div class="chart-view" *ngIf="boostDataChart == true" @simpleFadeAnimation>
            <!-- CHART -->
            <div class="base-chart-margin rel mr" *ngIf="campaign['Status.Label'] == 'Complete'">
              <div class="total anim totalPosition">
                <!-- <span class="totLab">Sent</span> -->
                <span [endVal]="allTarget" countUp></span>
                <span class="totSub">Total</span>
              </div>
              <app-chart-input
                class="fullHeight mb clearfix"
                [titleIsTop]="false"
                [showLegend]="true"
                [legendPosition]="'bottom'"
                [showLabel]="false"
                [chartType]="'pie'"
                [title]=""
                [data]="chartPatientData | orderBy: ['-name']"
                [dataNameAttribute]="'name'"
                [dataNameAttribute]="'value'"
              ></app-chart-input>
            </div>
            <!-- END CHART -->
          </div>
          <div class="list-view" *ngIf="boostDataChart == false" @simpleFadeAnimation>
            <mat-list>
              <mat-list-item>
                <div class="row flex-row" matLine>
                  <p class="reduce-marg bold">Available {{ "KEYWORD.patient" | translate }}s:</p>
                  <div class="pull-right">
                    <!-- <button class="btn-small pull-left ml" mat-stroked-button>
                    <mat-icon>search</mat-icon> View
                  </button> -->
                    <!-- <ngx-gauge class="no-title" [size]="40" [type]="'arch'"
                    [value]="0" [label]="''" [max]="100"
                    [thresholds]="moreIsBetter" [thick]="6" [cap]="'round'">
                  </ngx-gauge> -->
                    <p class="reduce-marg pull-right bold">{{ successedTarget }}</p>
                  </div>
                </div>
                <div class="row clearfix full-width" matLine>
                  <p class="small">
                    <mat-icon class="fas fa-minus-circle"></mat-icon>
                    <em>Less</em>
                  </p>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>
              <mat-list-item>
                <div class="row flex-row" matLine>
                  <p class="reduce-marg">{{ "KEYWORD.patient" | translate | titlecase}}s requested:</p>
                  <div class="pull-right">
                    <button
                      class="btn-small pull-left ml"
                      *ngIf="patientFilter == true"
                      color="primary"
                      mat-stroked-button
                    >
                      <mat-icon>filter_list</mat-icon> Filter
                    </button>
                    <button class="btn-small pull-left ml" mat-stroked-button>
                      <mat-icon>search</mat-icon>
                      View
                    </button>
                    <ngx-gauge
                      class="no-title"
                      [size]="40"
                      [type]="'arch'"
                      [value]="0"
                      [label]="''"
                      [max]="successedTarget"
                      [thresholds]="moreIsBetter"
                      [thick]="6"
                      [cap]="'round'"
                    >
                    </ngx-gauge>
                    <p class="reduce-marg pull-right">0</p>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>
              <mat-list-item>
                <div class="row flex-row">
                  <p class="reduce-marg">Blocked:</p>
                  <div class="pull-right">
                    <button
                      class="btn-small pull-left ml"
                      *ngIf="patientFilter == true"
                      color="primary"
                      mat-stroked-button
                    >
                      <mat-icon>filter_list</mat-icon> Filter
                    </button>
                    <button class="btn-small pull-left ml" mat-stroked-button>
                      <mat-icon>search</mat-icon>
                      View
                    </button>
                    <ngx-gauge
                      class="no-title"
                      [size]="40"
                      [type]="'arch'"
                      [value]="0"
                      [label]="''"
                      [max]="successedTarget"
                      [thresholds]="moreIsBetter"
                      [thick]="6"
                      [cap]="'round'"
                    >
                    </ngx-gauge>
                    <p class="reduce-marg pull-right">0</p>
                  </div>
                </div>
              </mat-list-item>
              <mat-list-item class="cltotal-row">
                <div class="row flex-row" matLine>
                  <p class="small rm-mb">
                    <mat-icon class="rotated">pause_circle_filled</mat-icon>
                    <em>Equals</em>
                  </p>
                </div>
                <div class="row flex-row" matLine>
                  <h3 class="green reduce-marg bold">Successfully delivered</h3>
                  <div class="pull-right">
                    <button class="btn-small pull-left ml" mat-stroked-button>
                      <mat-icon>search</mat-icon>
                      View
                    </button>
                    <ngx-gauge
                      class="no-title"
                      [size]="40"
                      [type]="'arch'"
                      [value]="successedTarget"
                      [label]="''"
                      [max]="successedTarget"
                      [thresholds]="moreIsBetter"
                      [thick]="6"
                      [cap]="'round'"
                    >
                    </ngx-gauge>
                    <h3 class="green reduce-marg pull-right bold">0</h3>
                  </div>
                </div>
              </mat-list-item>
            </mat-list>
          </div>
        </mat-card>
        <mat-card class="full-width mat-elevation-z3 mb" *ngIf="campaign['Social_Media_Only'] != '1'">
          <div class="row clearfix">
            <div class="pull-left">
              <h3 class="sr-title rm-m">Campaign Data</h3>
              <p class="small grey rm-mt" *ngIf="isModuleBoostReachActive == true">
                ({{"KEYWORD.patient" | translate | titlecase}}
                and Boost Data)</p>
              <p class="small grey rm-mt" *ngIf="isModuleBoostReachActive != true">
                ({{"KEYWORD.patient" | translate | titlecase}}
                Data)</p>
            </div>
            <button
              class="btn-small pull-right"
              (click)="campaignDataChart = !campaignDataChart"
              mat-raised-button
              color="primary"
            >
              <mat-icon class="fas fa-chart-pie"></mat-icon> Chart breakdown
            </button>
          </div>
          <hr/>
          <div class="chart-view" *ngIf="campaignDataChart == true" @simpleFadeAnimation>
            <!-- CHART -->
            <div class="base-chart-margin rel mr" *ngIf="campaign['Status.Label'] == 'Complete'">
              <div class="total anim totalPosition">
                <!-- <span class="totLab">Sent</span> -->
                <span [endVal]="successedTarget" countUp></span>
                <span class="totSub">Total</span>
              </div>
              <app-chart-input
                class="fullHeight mb clearfix"
                [titleIsTop]="false"
                [showLegend]="true"
                [legendPosition]="'bottom'"
                [showLabel]="false"
                [chartType]="'pie'"
                [title]=""
                [data]="chartCampaignDate | orderBy: ['-name']"
                [dataNameAttribute]="'name'"
                [dataNameAttribute]="'value'"
              ></app-chart-input>
            </div>
            <!-- END CHART -->
          </div>
          <div class="list-view" *ngIf="campaignDataChart == false" @simpleFadeAnimation>
            <mat-list>
              <mat-list-item>
                <div class="row flex-row" matLine>
                  <p class="reduce-marg bold">{{ "KEYWORD.patient" | translate | titlecase}}s scheduled to receive
                    message:</p>
                  <div class="pull-right">
                    <button
                      class="btn-small pull-left ml"
                      (click)="viewCampaignMessage(null, 'sent')"
                      mat-stroked-button
                    >
                      <mat-icon>search</mat-icon> View
                    </button>
                    <ngx-gauge
                      class="no-title"
                      [size]="40"
                      [type]="'arch'"
                      [value]="0"
                      [label]="''"
                      [max]="100"
                      [thresholds]="moreIsBetter"
                      [thick]="6"
                      [cap]="'round'"
                    >
                    </ngx-gauge>
                    <p class="reduce-marg pull-right bold">{{ successedTarget }}</p>
                  </div>
                </div>
                <div class="row clearfix full-width" matLine>
                  <p class="small">
                    <mat-icon class="fas fa-minus-circle"></mat-icon>
                    <em>Less</em>
                  </p>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>
              <mat-list-item>
                <div class="row flex-row">
                  <p class="reduce-marg">Soft bounces:</p>
                  <div class="pull-right">
                    <button
                      class="btn-small pull-left ml"
                      (click)="
                        openWikiLink(
                          'https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/email-reputation:-high-bounce-rate)'
                        )
                      "
                      mat-button
                    >
                      <mat-icon>help</mat-icon> What's this?
                    </button>
                    <button
                      class="btn-small pull-left ml"
                      (click)="viewCampaignMessage(null, 'soft-bounced')"
                      mat-stroked-button
                    >
                      <mat-icon>search</mat-icon> View
                    </button>
                    <ngx-gauge
                      class="no-title"
                      [size]="40"
                      [type]="'arch'"
                      [value]="campaign['SBounced.Email']"
                      [label]="''"
                      [max]="successedTarget"
                      [thresholds]="moreIsBetter"
                      [thick]="6"
                      [cap]="'round'"
                    >
                    </ngx-gauge>
                    <p class="reduce-marg pull-right">{{ campaign['SBounced.Email'] }}</p>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>
              <mat-list-item>
                <div class="row flex-row">
                  <p class="reduce-marg">Hard bounces:</p>
                  <div class="pull-right">
                    <button
                      class="btn-small pull-left ml"
                      (click)="
                        openWikiLink(
                          'https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/email-reputation:-high-bounce-rate)'
                        )
                      "
                      mat-button
                    >
                      <mat-icon>help</mat-icon> What's this?
                    </button>
                    <button
                      class="btn-small pull-left ml"
                      (click)="viewCampaignMessage(null, 'bounces')"
                      mat-stroked-button
                    >
                      <mat-icon>search</mat-icon> View
                    </button>
                    <ngx-gauge
                      class="no-title"
                      [size]="40"
                      [type]="'arch'"
                      [value]="campaign['HBounced.Email']"
                      [label]="''"
                      [max]="successedTarget"
                      [thresholds]="moreIsBetter"
                      [thick]="6"
                      [cap]="'round'"
                    >
                    </ngx-gauge>
                    <p class="reduce-marg pull-right">{{ campaign['HBounced.Email'] }}</p>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>
              <mat-list-item>
                <div class="row flex-row">
                  <p class="reduce-marg">Unsubscribed</p>
                  <div class="pull-right">
                    <button
                      class="btn-small pull-left ml"
                      (click)="
                        openWikiLink(
                          'https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/email-reputation:-high-unsubscribe-rate)'
                        )
                      "
                      mat-button
                    >
                      <mat-icon>help</mat-icon> What's this?
                    </button>
                    <button
                      class="btn-small pull-left ml"
                      (click)="viewNonReachedList('Unsubscribed')"
                      mat-stroked-button
                    >
                      <mat-icon>search</mat-icon> View
                    </button>
                    <ngx-gauge
                      class="no-title"
                      [size]="40"
                      [type]="'arch'"
                      [value]="0"
                      [label]="''"
                      [max]="successedTarget"
                      [thresholds]="moreIsBetter"
                      [thick]="6"
                      [cap]="'round'"
                    >
                    </ngx-gauge>
                    <p class="reduce-marg pull-right">{{ campaign['Unsubscribed'] }}</p>
                  </div>
                </div>
              </mat-list-item>
              <mat-list-item class="cltotal-row mb">
                <div class="row flex-row" matLine>
                  <p class="small rm-mb">
                    <mat-icon class="rotated">pause_circle_filled</mat-icon>
                    <em>Equals</em>
                  </p>
                </div>
                <div class="row flex-row" matLine>
                  <h3 class="green reduce-marg bold">Successfully delivered</h3>
                  <div class="pull-right">
                    <button
                      class="btn-small pull-left ml"
                      (click)="viewCampaignMessage(null, 'sent')"
                      mat-stroked-button
                    >
                      <mat-icon>search</mat-icon> View
                    </button>
                    <ngx-gauge
                      class="no-title"
                      [size]="40"
                      [type]="'arch'"
                      [value]="successedTarget"
                      [label]="''"
                      [max]="successedTarget"
                      [thresholds]="moreIsBetter"
                      [thick]="6"
                      [cap]="'round'"
                    >
                    </ngx-gauge>
                    <h3 class="green reduce-marg pull-right bold">{{ successedTarget }}</h3>
                  </div>
                </div>
              </mat-list-item>
            </mat-list>
            <h3 class="sr-title rm-m">Messages</h3>
            <hr/>
            <mat-list>
              <mat-list-item>
                <div class="row flex-row">
                  <p class="reduce-marg">Emails Opened:</p>
                  <div class="pull-right">
                    <button
                      class="btn-small pull-left ml"
                      (click)="viewCampaignMessage('READ', null)"
                      mat-stroked-button
                    >
                      <mat-icon>search</mat-icon> View
                    </button>
                    <ngx-gauge
                      class="no-title"
                      [size]="40"
                      [type]="'arch'"
                      [value]="campaign['Read.Email']"
                      [label]="''"
                      [max]="successedTarget"
                      [thresholds]="moreIsBetter"
                      [thick]="6"
                      [cap]="'round'"
                    >
                    </ngx-gauge>
                    <p class="reduce-marg pull-right">{{ campaign['Read.Email'] }}</p>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>
              <mat-list-item>
                <div class="row flex-row">
                  <p class="reduce-marg">Emails Clicked:</p>
                  <div class="pull-right">
                    <button
                      class="btn-small pull-left ml"
                      (click)="viewCampaignMessage('CLICKD', null)"
                      mat-stroked-button
                    >
                      <mat-icon>search</mat-icon> View
                    </button>
                    <ngx-gauge
                      class="no-title"
                      [size]="40"
                      [type]="'arch'"
                      [value]="campaign['Clicked.Email']"
                      [label]="''"
                      [max]="successedTarget"
                      [thresholds]="moreIsBetter"
                      [thick]="6"
                      [cap]="'round'"
                    >
                    </ngx-gauge>
                    <p class="reduce-marg pull-right">{{ campaign['Clicked.Email'] }}</p>
                  </div>
                </div>
              </mat-list-item>
              <mat-list-item>
                <div class="row flex-row">
                  <p class="reduce-marg">SMS's Delivered:</p>
                  <div class="pull-right">
                    <button
                      class="btn-small pull-left ml"
                      (click)="viewCampaignMessage('READ', null)"
                      mat-stroked-button
                    >
                      <mat-icon>search</mat-icon> View
                    </button>
                    <ngx-gauge
                      class="no-title"
                      [size]="40"
                      [type]="'arch'"
                      [value]="campaign['Finished.SMS']"
                      [label]="''"
                      [max]="successedTarget"
                      [thresholds]="moreIsBetter"
                      [thick]="6"
                      [cap]="'round'"
                    >
                    </ngx-gauge>
                    <p class="reduce-marg pull-right">{{ campaign['Sent.SMS'] }}</p>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>
            </mat-list>
          </div>
        </mat-card>
        <mat-card class="full-width mat-elevation-z3 mb">
          <div class="row clearfix">
            <h3 class="sr-title pull-left rm-m">Unique Visits</h3>
            <button
              class="btn-small pull-right"
              *ngIf="totalVisitor > 0"
              (click)="visitorChart = !visitorChart"
              mat-raised-button
              color="primary"
            >
              <mat-icon class="fas fa-chart-pie"></mat-icon> Chart breakdown
            </button>
          </div>
          <hr/>
          <!-- <hr> -->
          <div class="chart-view" *ngIf="visitorChart == true" @simpleFadeAnimation>
            <div
              class="base-chart-margin base-chart-margin-container rel mr"
              *ngIf="campaign['Status.Label'] == 'Complete'"
            >
              <div class="total anim totalPosition">
                <span [endVal]="totalVisitor" countUp></span>
                <span class="totSub">Total</span>
              </div>
              <app-chart-input
                class="fullHeight visitor-charts mb clearfix"
                [titleIsTop]="false"
                [showLegend]="true"
                [legendPosition]="'bottom'"
                [showLabel]="false"
                [chartType]="'pie'"
                [title]=""
                [data]="chartVisitorData | orderBy: ['-name']"
                [dataNameAttribute]="'name'"
                [dataNameAttribute]="'value'"
              ></app-chart-input>
            </div>
          </div>
          <div class="list-view" *ngIf="visitorChart == false" @simpleFadeAnimation>
            <mat-list>
              <mat-list-item *ngIf="campaign['Social_Media_Only'] != '1'">
                <div class="row flex-row">
                  <p class="reduce-marg">
                    <mat-icon class="fas fa-envelope mr" [style.color]="'#8e8e8e'"></mat-icon>
                    Email
                  </p>
                  <div class="pull-right">
                    <p class="reduce-marg pull-right">{{ campaign['Email_Visitors'] || 0 }}</p>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>
              <mat-list-item>
                <div class="row flex-row">
                  <p class="reduce-marg">
                    <mat-icon class="fas fa-mobile-alt mr" [style.color]="'#8e8e8e'"></mat-icon>
                    SMS
                  </p>
                  <div class="pull-right">
                    <p class="reduce-marg pull-right">{{ campaign['SMS_Visitors'] || 0 }}</p>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>
              <mat-list-item>
                <div class="row flex-row">
                  <p class="reduce-marg">
                    <mat-icon class="fas fa-hospital mr" [style.color]="'#8e8e8e'"></mat-icon>
                    {{ "KEYWORD.practice" | translate | titlecase}} Page
                  </p>
                  <div class="pull-right">
                    <p class="reduce-marg pull-right">{{ campaign['Practice_Visitors'] || 0 }}</p>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>
              <mat-list-item>
                <div class="row flex-row">
                  <p class="reduce-marg">
                    <mat-icon class="fas {{'fa-tooth' | translate}} mr" [style.color]="'#8e8e8e'"></mat-icon>
                    {{ "KEYWORD.dental" | translate | titlecase }} Tips and Offers page
                  </p>
                  <div class="pull-right">
                    <p class="reduce-marg pull-right">{{ campaign['Dental_Visitors'] || 0 }}</p>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>
              <mat-list-item>
                <div class="row flex-row">
                  <p class="reduce-marg">
                    <mat-icon class="fab fa-facebook mr" [style.color]="'#8e8e8e'"></mat-icon>
                    Facebook
                  </p>
                  <div class="pull-right">
                    <p class="reduce-marg pull-right">{{ campaign['Facebook_Visitors'] || 0 }}</p>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>
              <mat-list-item>
                <div class="row flex-row">
                  <p class="reduce-marg">
                    <mat-icon class="fab fa-linkedin mr" [style.color]="'#8e8e8e'"></mat-icon>
                    Linkedin
                  </p>
                  <div class="pull-right">
                    <p class="reduce-marg pull-right">{{ campaign['Linkedin_Visitors'] || 0 }}</p>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>
              <mat-list-item>
                <div class="row flex-row">
                  <p class="reduce-marg">
                    <mat-icon class="fab fa-twitter mr" [style.color]="'#8e8e8e'"></mat-icon>
                    Twitter
                  </p>
                  <div class="pull-right">
                    <p class="reduce-marg pull-right">{{ campaign['Twitter_Visitors'] || 0 }}</p>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>
              <mat-list-item>
                <div class="row flex-row">
                  <p class="reduce-marg">
                    <mat-icon class="fab fa-instagram mr" [style.color]="'#8e8e8e'"></mat-icon>
                    Instagram
                  </p>
                  <div class="pull-right">
                    <p class="reduce-marg pull-right">{{ campaign['Instagram_Visitors'] || 0 }}</p>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>

              <mat-list-item>
                <div class="row flex-row">
                  <p class="reduce-marg">
                    <mat-icon class="fab fa-pinterest mr" [style.color]="'#8e8e8e'"></mat-icon>
                    Pinterest
                  </p>
                  <div class="pull-right">
                    <p class="reduce-marg pull-right">{{ campaign['Pinterest_Visitors'] || 0 }}</p>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>
              <mat-list-item>
                <div class="row flex-row">
                  <p class="reduce-marg">
                    <mat-icon class="fas fa-file mr" [style.color]="'#8e8e8e'"></mat-icon>
                    Other
                  </p>
                  <div class="pull-right">
                    <p class="reduce-marg pull-right">{{ campaign['Other_Visitors'] || 0 }}</p>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>
              <mat-list-item class="mt">
                <div class="row clearfix full-width" matLine>
                  <p class="reduce-marg bold pull-left">Total Visitors</p>
                  <p class="reduce-marg bold pull-right">{{ totalVisitor }}</p>
                </div>
              </mat-list-item>
            </mat-list>
          </div>
        </mat-card>
        <mat-card class="card full-width mat-elevation-z3" *ngIf="campaign['Social_Media_Only'] != '1'">
          <div class="row clearfix">
            <h3 class="sr-title pull-left rm-m">Reputation</h3>
          </div>
          <hr/>
          <div class="chart-view" *ngIf="reputationDataChart == true" @simpleFadeAnimation>
            <!-- CHART -->
            <div class="base-chart-margin rel mr" *ngIf="campaign['Status.Label'] == 'Complete'">
              <div class="total anim totalPosition">
                <!-- <span class="totLab">Sent</span> -->
                <span [endVal]="allTarget" countUp></span>
                <span class="totSub">Total</span>
              </div>
              <app-chart-input
                class="fullHeight mb clearfix"
                [titleIsTop]="false"
                [showLegend]="true"
                [legendPosition]="'bottom'"
                [showLabel]="false"
                [chartType]="'pie'"
                [title]=""
                [data]="chartPatientData | orderBy: ['-name']"
                [dataNameAttribute]="'name'"
                [dataNameAttribute]="'value'"
              ></app-chart-input>
            </div>
            <!-- END CHART -->
          </div>
          <div class="list-view" *ngIf="reputationDataChart == false" @simpleFadeAnimation>
            <mat-list></mat-list>
          </div>
          <p>
            Reputation is determined by the number of emails that are read by your {{ "KEYWORD.patient" | translate }}s,
            versus the number of
            'bounces' or unsubscribes. If you would like to learn more about how your sending reputation works,
            <strong
              class="block"
              (click)="
                openWikiLink(
                  'https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/email-reputation:-high-spam-complaint-rate)'
                )
              "
            >read more here.</strong
            >
          </p>
          <mat-list>
            <mat-list-item>
              <div class="row flex-row">
                <p class="reduce-marg bold">
                  Reputation:
                  <span
                    class="reputation-chip chip"
                    *ngIf="emailReputation"
                    [ngStyle]="{ 'background-color': '#' + emailReputationColor }"
                  >{{ emailReputation }}</span
                  >
                </p>
                <div class="pull-right">
                  <button class="btn-small pull-left ml" (click)="viewSubAccountStatisticalData()" mat-stroked-button>
                    <mat-icon>search</mat-icon> View
                  </button>
                  <ngx-gauge
                    class="no-title"
                    *ngIf="emailReputation"
                    [size]="40"
                    [type]="'arch'"
                    [value]="reputationValue"
                    [label]="''"
                    [max]="100"
                    [thresholds]="moreIsBetterPerc"
                    [thick]="6"
                    [cap]="'round'"
                  >
                  </ngx-gauge>
                  <p class="reduce-marg pull-right bold" [ngStyle]="{ color: '#' + emailReputationColor }">
                    {{ reputationValue }}%
                  </p>
                </div>
              </div>
              <mat-divider></mat-divider>
            </mat-list-item>
            <mat-list-item>
              <div class="row flex-row">
                <p class="reduce-marg">Hourly send rate</p>
                <div class="pull-right">
                  <p class="reduce-marg pull-right" *ngIf="subAccountObj && subAccountObj.Hourly_quota">
                    {{ subAccountObj['Hourly_quota'] }}
                  </p>
                </div>
              </div>
            </mat-list-item>
          </mat-list>
        </mat-card>
      </div>
      <div class="half-width page-container flex">
        <mat-card class="card card-mt full-width ml mat-elevation-z3">
          <!-- <h2 class="sm-h2 rm-mt">Your campaign</h2> -->
          <app-template-review
            class="noCard"
            *ngIf="templateID"
            [campaign]="campaign"
            [templateID]="selectedTemplateID"
          >
          </app-template-review>
          <app-template-review
            class="noCard"
            *ngIf="!templateID && selectedTemplateTag"
            [campaign]="campaign"
            [templateTag]="selectedTemplateTag"
          >
          </app-template-review>
        </mat-card>
      </div>
    </div>
    <button
      *ngIf="showBack == true && isModal != true"
      [routerLink]="['/merchant/', { outlets: { page: ['marketing-over-view'] } }]"
      mat-raised-button
      color="primary"
    >
      <mat-icon>chevron_left</mat-icon> Back to Marketing Overview
    </button>
  </div>
  <div class="row clearfix white-bg" *ngIf="displayNonReachedList == true && selectedNotReachedReason != null">
    <app-non-reached-targets-list [campaignID]="campaign.ID" [reasonCode]="selectedNotReachedReason">
    </app-non-reached-targets-list>
    <div class="row clearfix text-center">
      <button
        class="pull-left"
        *ngIf="isModal != true"
        (click)="cancelNonReachedList()"
        mat-raised-button
        color="accent"
      >
        Go Back
      </button>
    </div>
  </div>
  <div
    class="row clearfix white-bg"
    *ngIf="displayCampaignMessage == true && (selectedMessageStatus != null || selectedMessageStatusNative != null)"
  >
    <app-marketing-campaign-message-list-overview
      [status]="selectedMessageStatus"
      [statusNative]="selectedMessageStatusNative"
      [campaignID]="campaign.ID"
    >
    </app-marketing-campaign-message-list-overview>
    <div class="row clearfix text-center">
      <button
        class="pull-left"
        *ngIf="isModal != true"
        (click)="cancelCampaignMessageView()"
        mat-raised-button
        color="accent"
      >
        Go Back
      </button>
    </div>
  </div>
</mat-dialog-content>
<div class="drop-button mt text-center" *ngIf="isModal == true">
  <button class="text-center" (click)="closeModal()" mat-raised-button color="accent">Close</button>
</div>
