<nav id="header">
  <div class="row clearfix flex flexcenter">
    <button
      class="inline btn-new-invite mr"
      (click)="goHome()"
      mat-stroked-button
      matTooltip="Wiki Home"
      color="primary"
    >
      <mat-icon class="fas fa-home"></mat-icon>
    </button>

    <button
      class="inline mr"
      *ngIf="isAdmin == true"
      [matMenuTriggerFor]="menuView"
      mat-stroked-button
      matTooltip="View Wiki as"
      color="primary"
    >
      <mat-icon class="fas fa-user-secret"></mat-icon>
    </button>

    <mat-menu #menuView="matMenu">
      <button (click)="viewAs('all')" mat-button color="accent">
        <mat-icon class="fas fa-users-cog"></mat-icon>
        <span>Admin</span>
      </button>

      <button (click)="viewAs('promoter')" mat-menu-item>
        <mat-icon class="fas fa-universal-access"></mat-icon>
        <span>Promoter</span>
      </button>

      <button (click)="viewAs('funder')" mat-menu-item>
        <mat-icon class="fas fa-user-shield"></mat-icon>
        <span>Funder</span>
      </button>

      <button (click)="viewAs('merchant')" mat-menu-item>
        <mat-icon class="fas fa-user-tie"></mat-icon>
        <span>Merchant</span>
      </button>

      <button (click)="viewAs('supplier')" mat-menu-item>
        <mat-icon class="fas fa-user-tag"></mat-icon>
        <span>Supplier</span>
      </button>

      <button (click)="viewAs('customer')" mat-menu-item>
        <mat-icon class="fas fa-user"></mat-icon>
        <span>Customer</span>
      </button>

      <button (click)="viewAs('guest')" mat-button color="primary">
        <mat-icon class="fas fa-user-astronaut"></mat-icon>
        <span>Guest</span>
      </button>
    </mat-menu>

    <button
      class="inline btn-new-invite mr"
      *ngIf="isAdmin == true"
      (click)="newPage()"
      mat-stroked-button
      matTooltip="Create Wiki Article"
      color="accent"
    >
      <mat-icon class="fas fa-plus"></mat-icon>
    </button>

    <div class="clearfix row full-width search">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Search for content</mat-label>
        <input
          [(ngModel)]="search"
          (keyup.enter)="searchEvent()"
          name="referenceLabel"
          matInput
          placeholder="Search for content"
        />

        <button class="" [disabled]="!search" (click)="searchEvent()" color="warning" mat-button matSuffix>
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="flex half-width buttons">
      <button class="actionMenuToggle ml mr" *ngIf="isAdmin == true" [matMenuTriggerFor]="menu" mat-stroked-button>
        <mat-icon class="fas fa-cog"></mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button [routerLink]="['/wiki/', { outlets: { wikiPageRoute: ['group-list'] } }]" mat-menu-item>
          <mat-icon class="fas fa-object-group"></mat-icon>
          <span>Wiki Categories Settings</span>
        </button>

        <button [routerLink]="['/wiki/', { outlets: { wikiPageRoute: ['page-list'] } }]" mat-menu-item>
          <mat-icon class="fas fa-columns"></mat-icon>
          <span>Wiki Articles Settings</span>
        </button>

        <button (click)="resetOrder()" mat-menu-item>
          <mat-icon class="fas fa-sort"></mat-icon>
          <span>Reset Article Order</span>
        </button>
      </mat-menu>

      <session-compact-menu [displayDashboard]="true" [displayAdmin]="true"></session-compact-menu>
    </div>
  </div>
</nav>
