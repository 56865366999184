<mat-card class="rel supplier-card brandView clearfix mb" *ngIf="treatment" @ngIfAnimation>
  <div class="card-header primary-gradient-img clearfix inModal rel">
    <div class="row clearfix content">
      <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModal()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <app-category-view-logo
          class="supplierIcon pull-left"
          [categoryID]="treatment['ID']"
          [LastModified]="treatment['LastModified']"
        >
        </app-category-view-logo>

        <h2 class="summary-header rm-m">
          {{ treatmentUtil.getLabel(treatment['Label']) }}
        </h2>

        <div class="date-chip-area">
          <p class="clearfix small rm-m" *ngIf="treatment['Active'] == '1' && isPromoterOrAdmin == true">
            <span class="chip-enable">Active</span>
          </p>
          <p class="clearfix small rm-m" *ngIf="treatment['Active'] == '0' && isPromoterOrAdmin == true">
            <span class="chip-disable">Not active</span>
          </p>
        </div>
      </div>
    </div>
    <div class="colourOverlay" [ngStyle]="{ background: treatment['Colour'] }"></div>
  </div>
  <mat-dialog-content class="stacked-card-view indOvf">
    <div class="row clearfix">
      <p class="clearfix" [innerHTML]="treatment['Description'] | safeHtml">
        <!-- treatment product desctiption text -->
      </p>
    </div>
  </mat-dialog-content>
</mat-card>
