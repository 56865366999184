<div class="container full-width flex profiles">
  <!-- ===== Details Page ===== -->

  <mat-card class="toggle-card mb clearfix mat-elevation-z6 full-width">
    <div class="row clearfix full-width">
      <mat-button-toggle-group class="btn-group row clearfix mb flex full-width text-center" [(ngModel)]="tab">
        <mat-button-toggle class="text-center full-width" [value]="'profile'">
          <mat-icon class="fas fa-user-circle"></mat-icon>
        </mat-button-toggle>

        <mat-button-toggle class="text-center full-width" [value]="'practice'">
          My {{"KEYWORD.practice" | translate}}
          <mat-icon class="fas fa-store-alt"></mat-icon>
        </mat-button-toggle>

        <mat-button-toggle class="text-center full-width" [value]="'module'">
          My modules
          <mat-icon class="fas fa-cubes"></mat-icon>
        </mat-button-toggle>

        <mat-button-toggle class="text-center full-width" [value]="'staff'">
          My staff members
          <mat-icon class="fas fa-users"></mat-icon>
        </mat-button-toggle>

        <mat-button-toggle class="text-center full-width" [value]="'password'">
          Change password & Social Media
          <mat-icon class="fas fa-unlock-alt"></mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <session-edit-profile *ngIf="tab == 'profil'" [tab]="'profil'"></session-edit-profile>

    <session-edit-profile *ngIf="tab == 'practice'" [tab]="'practice'"></session-edit-profile>

    <div class="row clearfix full-width tabArea" *ngIf="tab == 'module'">
      <h3 class="sr-title">Your active modules</h3>
      <p class="small rm-mt">
        Below is a list of all the active modules for your {{ "BRANDING.Brand Name" | translate | titlecase }} account.
      </p>

      <app-user-access-view [cardID]="merchantID"></app-user-access-view>
    </div>

    <div class="row clearfix full-width tabArea" *ngIf="tab == 'staff'">
      <app-dentist-list class="noEmbedCard" [merchantID]="merchantID"></app-dentist-list>
    </div>

    <div class="row clearfix full-width tabArea flex" *ngIf="tab == 'password'">
      <mat-card class="card full-width mr">
        <div class="colour-stripe"></div>
        <h3 class="sr-title rm-m sm-h3">Account password</h3>
        <hr class="mb"/>
        <div class="row clearfix text-center mt">
          <app-password-change></app-password-change>
        </div>
      </mat-card>

      <mat-card class="card full-width">
        <div class="colour-stripe"></div>
        <h3 class="sr-title rm-m sm-h3">Connect social media</h3>
        <p class="small">Update your Social Media link to be able to use it to Login to the application.</p>
        <hr class="mb"/>
        <div class="row clearfix text-center mt">
          <app-change-social-media></app-change-social-media>
        </div>
      </mat-card>
    </div>
  </mat-card>
</div>
