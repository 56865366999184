<mat-card class="list-card">
  <p>
    Accepted Informed Consent:
    <span class="badge">{{ dataSource.filteredData.length }}</span>
  </p>
  <div class="row clearfix mb">
    <div class="row clearfix">
      <div class="half-width">
        <mat-icon class="schIcon">search</mat-icon>
        <mat-select class="schSel" [formControl]="searchVls" matInput placeholder="Add Filters" multiple>
          <mat-select-trigger>
            {{ searchVls.value ? searchVls.value[0] : '' }}
            <span class="additional-selection" *ngIf="searchVls.value?.length > 1">
              (+{{ searchVls.value.length - 1 }} others)
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let searchVal of searchValList" [value]="searchVal">{{ searchVal }}</mat-option>
        </mat-select>
      </div>
    </div>
    <div class="row clearfix flex well miniWell animated fadeInDown" *ngIf="searchVls.value?.length > 0">
      <app-date-input class="margin-btm" *ngIf="searchVls.value?.indexOf('Date') !== -1" [isClear]="true"
                      (selectedDate)="setFilter($event, 'DateTimeCreated')" name="DentistContactName"></app-date-input>
      <mat-select class="margin-btm" *ngIf="!merchantID" (selectionChange)="setFilter($event.value, 'MerchantName')"
                  name="MerchantName" placeholder="Merchant">
        <mat-option [value]="null">
          All
        </mat-option>
        <mat-option *ngFor="
            let a of invitationsDB.data | objectPropertyValue: 'MerchantName' | unique | without: ['', null] | orderBy
          " [value]="a">
          {{ a }}
        </mat-option>
      </mat-select>
      <mat-select class="margin-btm" *ngIf="searchVls.value?.indexOf('Operator') !== -1"
                  (selectionChange)="setFilter($event.value, 'DentistContactName')" name="DentistContactName"
                  placeholder="Operator">
        <mat-option [value]="null">
          All
        </mat-option>
        <mat-option *ngFor="
            let a of invitationsDB.data
              | objectPropertyValue: 'DentistContactName'
              | unique
              | without: ['', null]
              | orderBy
          " [value]="a">
          {{ a }}
        </mat-option>
      </mat-select>
      <mat-form-field class="margin-btm" *ngIf="searchVls.value?.indexOf('First Name') !== -1">
        <mat-label>First Name</mat-label>
        <input class="clearfix" (input)="setFilter($event.target.value, 'FirstName')" name="FirstName"
               placeholder="First Name" matInput/>
      </mat-form-field>
      <mat-form-field class="margin-btm" *ngIf="searchVls.value?.indexOf('Last Name') !== -1">
        <mat-label>Last Name</mat-label>
        <input class="clearfix" (input)="setFilter($event.target.value, 'FirstName')" name="LastName"
               placeholder="Last Name" matInput/>
      </mat-form-field>
      <mat-form-field class="margin-btm" *ngIf="searchVls.value?.indexOf('Email') !== -1">
        <mat-label>Email</mat-label>
        <input class="clearfix" (input)="setFilter($event.target.value, 'Email')" name="Email" placeholder="Email"
               matInput/>
      </mat-form-field>
      <mat-select class="margin-btm" *ngIf="searchVls.value?.indexOf('Treatment') !== -1"
                  (selectionChange)="setFilter($event.value, 'Service.Type')" name="Treatment"
                  [placeholder]="'KEYWORD.Treatment' | translate">
        <mat-option [value]="null">
          All
        </mat-option>
        <mat-option *ngFor="
            let a of invitationsDB.data | objectPropertyValue: 'Service.Type' | unique | without: ['', null] | orderBy
          " [value]="a">
          {{ a }}
        </mat-option>
      </mat-select>
      <mat-form-field class="margin-btm" *ngIf="searchVls.value?.indexOf('Amount') !== -1">
        <mat-label>Amount</mat-label>
        <input class="clearfix" (input)="setFilter($event.target.value, 'Service.Value')" name="Amount"
               placeholder="Amount" CurrenyMask matInput/>
      </mat-form-field>
      <mat-select class="margin-btm" *ngIf="searchVls.value?.indexOf('Product') !== -1"
                  (selectionChange)="setFilter($event.value, 'Product.Label.Marketing')" name="Product"
                  placeholder="Product">
        <mat-option [value]="null">
          All
        </mat-option>
        <mat-option *ngFor="
            let p of invitationsDB.data | pluck: 'Product.Label.Marketing' | unique | without: ['', null] | orderBy
          " [value]="p">
          {{ p }}
        </mat-option>
      </mat-select>
      <mat-select class="margin-btm" *ngIf="searchVls.value?.indexOf('Email Status') !== -1"
                  (selectionChange)="setFilter($event.value, 'Status.Email.Short')" name="emailStatus"
                  placeholder="Email Status">
        <mat-option [value]="null">
          All
        </mat-option>
        <mat-option *ngFor="
            let e of invitationsDB.data
              | objectPropertyValue: 'Status.Email.Short'
              | unique
              | without: ['', null]
              | orderBy
          " [value]="e">
          {{ e }}
        </mat-option>
      </mat-select>
      <mat-select class="margin-btm" *ngIf="searchVls.value?.indexOf('Application Status') !== -1"
                  (selectionChange)="setFilter($event.value, 'Status.Application.Short')" name="AppStatus"
                  placeholder="Application Status">
        <mat-option [value]="null">
          All
        </mat-option>
        <mat-option *ngFor="
            let a of invitationsDB.data
              | objectPropertyValue: 'Status.Application.Short'
              | unique
              | without: ['', null]
              | orderBy
          " [value]="a">
          {{ a }}
        </mat-option>
      </mat-select>
      <mat-select class="margin-btm" *ngIf="searchVls.value?.indexOf('Contract Status') !== -1"
                  (selectionChange)="setFilter($event.value, 'Status.Contract.Short')" c name="ContractStatus"
                  placeholder="Contract Status">
        <mat-option [value]="null">
          All
        </mat-option>
        <mat-option *ngFor="
            let c of invitationsDB.data
              | objectPropertyValue: 'Status.Contract.Short'
              | unique
              | without: ['', null]
              | orderBy
          " [value]="c">
          {{ c }}
        </mat-option>
      </mat-select>
      <mat-select class="margin-btm" *ngIf="searchVls.value?.indexOf('Invitation Status') !== -1"
                  (selectionChange)="setFilter($event.value, 'Status.Invitation.Short')" name="invitationStatus"
                  placeholder="Invitation Status">
        <mat-option [value]="null">
          All
        </mat-option>
        <mat-option *ngFor="
            let i of invitationsDB.data
              | objectPropertyValue: 'Status.Invitation.Short'
              | unique
              | without: ['', null]
              | orderBy
          " [value]="i">
          {{ i }}
        </mat-option>
      </mat-select>
    </div>
  </div>
  <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
    <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
    <!-- ID Column -->
    <ng-container cdkColumnDef="Date">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Date</mat-header-cell>
      <mat-cell *cdkCellDef="let row" matTooltip="{{ row['DateTimeCreated'] | customDate }}">
        {{ row['DateTimeCreated'] | customDate }}
      </mat-cell>
    </ng-container>
    <!-- Progress Column -->
    <ng-container cdkColumnDef="Operator">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Operator</mat-header-cell>
      <mat-cell *cdkCellDef="let row" matTooltip="{{ row.DentistContactName }}"> {{ row.DentistContactName }}</mat-cell>
    </ng-container>
    <!-- Name Column -->
    <ng-container cdkColumnDef="Customer">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>
        Customer
      </mat-header-cell>
      <mat-cell *cdkCellDef="let row" matTooltip="{{ row.FirstName }} {{ row.LastName }}">
        {{ row.FirstName }} {{ row.LastName }}
      </mat-cell>
    </ng-container>
    <ng-container cdkColumnDef="Email">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>
        Email
      </mat-header-cell>
      <mat-cell *cdkCellDef="let row" matTooltip="{{ row.Email }}"> {{ row.Email }} </mat-cell>
    </ng-container>
    <!-- Color Column -->
    <ng-container cdkColumnDef="Treatment">
      <mat-header-cell *cdkHeaderCellDef
                       mat-sort-header> {{ "KEYWORD.treatment" | translate | titlecase }} </mat-header-cell>
      <mat-cell *cdkCellDef="let row" matTooltip="{{ row['Service.Type'] }}"> {{ row['Service.Type'] }} </mat-cell>
    </ng-container>
    <!-- Color Column -->
    <ng-container cdkColumnDef="Amount">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Amount</mat-header-cell>
      <mat-cell *cdkCellDef="let row" matTooltip="{{ row['Service.Value'] }}"> {{ row['Service.Value'] }} </mat-cell>
    </ng-container>
    <!-- Color Column -->
    <ng-container cdkColumnDef="Product">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Product</mat-header-cell>
      <mat-cell *cdkCellDef="let row" matTooltip="{{ row['Product.Label.Marketing'] }}">
        {{ row['Product.Label.Marketing'] }}
      </mat-cell>
    </ng-container>
    <!-- Color Column -->
    <ng-container cdkColumnDef="Source">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Source</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <span *ngIf="row['PatientChoice.Code'] == 'COLD'">Via Web</span>
        <span *ngIf="row['PatientChoice.Code'] != 'COLD'">Invitation</span>
      </mat-cell>
    </ng-container>
    <!-- Email Status Column -->
    <ng-container cdkColumnDef="EmailStatus">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Email status </mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <span class="list-label" [style.background]="row['Status.Invitation.Colour']"
              matTooltip="{{ row['Status.Email.Short'] }}">{{ row['Status.Email.Short'] }}</span>
      </mat-cell>
      -->
    </ng-container>
    <!-- Invitation Status Column -->
    <ng-container cdkColumnDef="InvitationStatus">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Invitation status </mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <span class="list-label" [style.background]="row['Status.Invitation.Colour']"
              matTooltip="{{ row['Status.Invitation.Short'] }}">{{ row['Status.Invitation.Short'] }}
        </span>
      </mat-cell>
    </ng-container>
    <ng-container cdkColumnDef="ApplicationStatus">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Application status </mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <span class="list-label" [style.background]="row['Status.Application.Colour']"
              matTooltip="{{ row['Status.Application.Short'] }}">{{ row['Status.Application.Short'] }}
        </span>
      </mat-cell>
    </ng-container>
    <ng-container cdkColumnDef="ContractStatus">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Contract Status </mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <span class="list-label" [style.background]="row['Status.Contract.Colour']"
              matTooltip="{{ row['Status.Contract.Short'] }}">{{ row['Status.Contract.Short'] }}</span>
      </mat-cell>
    </ng-container>
    <!-- Response Column -->
    <ng-container cdkColumnDef="Response">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Response</mat-header-cell>
      <mat-cell *cdkCellDef="let row" matTooltip="{{ row['PatientChoice.Label_LongThirdPerson'] }}">
        {{ row['PatientChoice.Label_LongThirdPerson'] }}
      </mat-cell>
    </ng-container>
    <!-- Action Button Column -->
    <ng-container cdkColumnDef="Actions">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <div style="float: right">
          <button [matMenuTriggerFor]="menu" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button (click)="openInviteViewDetails(row['ID'])" mat-menu-item>
              <mat-icon>folder_shared</mat-icon>
              <span>View Invitation</span>
            </button>
            <button *ngIf="row['ExistingPatientID'] && row['ExistingPatientID'] != '00000000000000000000000000000000'"
                    (click)="viewPatient(row['ExistingPatientID'])" mat-menu-item>
              <mat-icon>portrait</mat-icon>
              <span>{{ "KEYWORD.patient" | translate | titlecase }} Profile</span>
            </button>
            <button *ngIf="row['DentistContact_key'] && row['DentistContact_key'] != '00000000000000000000000000000000'"
                    (click)="viewOperator(row['DentistContact_key'])" mat-menu-item>
              <mat-icon>portrait</mat-icon>
              <span>Operator Profile</span>
            </button>
          </mat-menu>
        </div>
      </mat-cell>
    </ng-container>
    <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="pageSize"
                 [pageSizeOptions]="pageSizeOptions">
  </mat-paginator>
</mat-card>
