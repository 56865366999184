<div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="listDB.data.length == 0 || showDocList == true || showProductList == true">
  <div class="row clearfix">
    <div class="row clearfix">
      <h2 class="dataHeader rm-mt">
        <img
          src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/brandLogos/ADA-Fed-Logo.png"/>

        {{ treatmentUtil.getLabel(title) }}
        <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
      </h2>
    </div>

    <div class="row clearfix flex ctl mb" @ngIfAnimation>
      <div class="full-width flex">
        <button (click)="add()" mat-raised-button color="primary">
          <mat-icon>add</mat-icon>
          Add Brand
        </button>

        <button (click)="goToGuide()" mat-raised-button color="accent">
          Download Guide <mat-icon class="fas fa-file-download"></mat-icon>
        </button>
        <button (click)="goToShop()" mat-raised-button color="primary">
          ADA Shop <mat-icon class="fas fa-shopping-basket"></mat-icon>
        </button>
      </div>

      <mat-button-toggle-group class="btn-group rm-m half-width" *ngIf="isPromoterOrAdmin == true"
                               [(ngModel)]="isActiveFilter" (change)="setFilter($event.value, 'Active')">
        <mat-button-toggle class="full-width text-center" [value]="null">
          All
        </mat-button-toggle>

        <mat-button-toggle class="full-width text-center" [value]="'1'">
          Active
        </mat-button-toggle>
        <mat-button-toggle class="full-width text-center" [value]="'0'">
          Not Active
        </mat-button-toggle>
      </mat-button-toggle-group>

      <button class="row select-btn" *ngIf="isPromoterOrAdmin == true" (click)="selectAll()" mat-stroked-button color=""
              matTooltip="Select All">
        <mat-icon>border_all</mat-icon>
      </button>

      <button class="row select-btn" *ngIf="isPromoterOrAdmin == true" (click)="selectPage()" mat-stroked-button
              color="" matTooltip="Select Page">
        <mat-icon>border_outer</mat-icon>
      </button>

      <button class="animated" *ngIf="isPromoterOrAdmin == true && selectedIDs && selectedIDs.length > 0"
              [matMenuTriggerFor]="ActionBtnmenu" mat-stroked-button matTooltip="Options">
        <mat-icon class="fas fa-cog"></mat-icon>  <span *ngIf="selectedIDs && selectedIDs.length>0"
          class="selected-number-item  accent-background">{{selectedIDs.length}}</span>
      </button>
      <mat-menu #ActionBtnmenu="matMenu">
        <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
          <mat-icon>select_all</mat-icon> Deselect
        </button>

        <button class="row select-btn" (click)="disableGroup()" mat-menu-item color="">
          <mat-icon>cancel</mat-icon>
          Disable
        </button>

        <button class="row select-btn" (click)="enableGroup()" mat-menu-item color="">
          <mat-icon>check_circle</mat-icon>
          Enable
        </button>

        <button class="row select-btn" (click)="addDocumentGroup()" mat-menu-item color="">
          <mat-icon>add_circle</mat-icon>
          Add document
        </button>

        <button class="row select-btn" (click)="importDocumentGroup()" mat-menu-item color="">
          <mat-icon>import_export</mat-icon>
          Import document
        </button>
      </mat-menu>
    </div>
  </div>

  <div class="row clearfix">
    <mat-card class="list-card">
      <div class="row clearfix mb searchArea primary-gradient-img">
        <div class="row clearfix flex">
          <div class="full-width flex animated fadeInDown">
            <mat-icon class="mt">search</mat-icon>

            <mat-form-field class="ml full-width" appearance="outline">
              <mat-label>Label, Item Code</mat-label>
              <input class="clearfix" (input)="setFilter($event.target.value, 'Label')" name="Labels" placeholder=""
                     matInput/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Category</mat-label>
              <mat-select class="" (selectionChange)="setFilter($event.value, 'Category.Label')" name="categoryLabel"
                          placeholder="">
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option *ngFor="
                    let a of dataSource.filteredData
                      | objectPropertyValue: 'Category.Label'
                      | unique
                      | without: ['', null]
                      | orderBy
                  " [value]="a">
                  {{ treatmentUtil.getLabel(a) }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Item code</mat-label>
              <mat-select class="" (selectionChange)="setFilter($event.value, 'Type.Label')" name="TypeLabel"
                          placeholder="Item code">
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option *ngFor="
                    let a of dataSource.filteredData
                      | objectPropertyValue: 'Type.Label'
                      | unique
                      | without: ['', null]
                      | orderBy
                  " [value]="a">
                  {{ treatmentUtil.getLabel(a) }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- Table -->
      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <ng-container cdkColumnDef="selectCheckBox" sticky>
          <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div class="margin-btm pull-left">
              <mat-checkbox [checked]="isSelected(row['ID'])" (change)="select(row['ID'], $event.checked)"
                            color="accent">
              </mat-checkbox>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="ProfileImage" sticky>
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Logo</mat-header-cell>
          <mat-cell class="text-center" *cdkCellDef="let row" (click)="viewTreatment(row['ID'])">
            <app-brand-view-logo class="supplierIcon2 text-center" *ngIf="row" [hideZoomButton]="true"
                                 [brandID]="row['ID']" [LastModified]="row['LastModified']">
            </app-brand-view-logo>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Label" sticky>
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Brand</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTreatment(row['ID'])"
                    matTooltip="{{ treatmentUtil.getLabel(row['Label']) }}">
            <span>{{ treatmentUtil.getLabel(row['Label']) }}</span>
            <span class="value chip new" *ngIf="isNew(row['DateTimeCreated']) == true">NEW</span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Type">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Type</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTreatment(row['ID'])"
                    matTooltip="{{ treatmentUtil.getLabel(row['Type.Label']) }}">
            {{ treatmentUtil.getLabel(row['Type.Label']) }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Category">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Category</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTreatment(row['ID'])"
                    matTooltip="{{ treatmentUtil.getLabel(row['Category.Label']) }}">
            <strong [ngStyle]="{ color: row['Category.Colour'] }">{{
              treatmentUtil.getLabel(row['Category.Label'])
              }}</strong>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Manufacturer">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Manufacturer</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTreatment(row['ID'])"
                    matTooltip="{{ row['Manufacturer.Name'] }}">
            {{ row['Manufacturer.Name'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Distributor">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Distributor</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTreatment(row['ID'])" matTooltip="{{ row['Distributor.Name'] }}">
            {{ row['Distributor.Name'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Date">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Created Date </mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTreatment(row['ID'])"
                    matTooltip="{{ row['DateTimeCreated'] | customDateTime }}">
            {{ row['DateTimeCreated'] | customDate }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Products">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Products</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <button class="btn-small" (click)="viewProducts(row['ID'], row['Label'])" matTooltip="View Brand Products"
                    mat-button color="warning">
              {{ row['NumberOfProducts'] || '...' }}
            </button>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Documents">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Documents</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <button class="btn-small" (click)="viewDocuments(row['ID'], row['Label'])"
                    matTooltip=" View Brand Documents" mat-button color="warning">
              {{ row['NumberOfDocuments'] || '...' }}
            </button>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="IsActive">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Is Active</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTreatment(row['ID'])">
            <span class="list-label smaller" *ngIf="row['Active'] == '1'" matTooltip="Active"
                  style="background-color: rgb(90, 186, 71)">
              <mat-icon>check</mat-icon>
            </span>
            <span class="list-label smaller" *ngIf="row['Active'] != '1'" matTooltip="Inactive"
                  style="background-color: rgb(239, 71, 39)">
              <mat-icon>close</mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="list">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> </mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <button (click)="viewProducts(row['ID'], row['Label'])" mat-icon-button matTooltip="View Product List">
              <mat-icon>list</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button (click)="viewTreatment(row['ID'])" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span>View Brand</span>
                </button>

                <button *ngIf="row['Manufacturer_Key']" (click)="viewSupplier(row['Manufacturer_Key'])" mat-menu-item>
                  <mat-icon class="fas fa-industry mr"></mat-icon>
                  <span>Manufacturer {{ row['Manufacturer.Name'] }}</span>
                </button>

                <button *ngIf="row['Distributor_Key']" (click)="viewSupplier(row['Distributor_Key'])" mat-menu-item>
                  <mat-icon class="fas fa-truck mr"></mat-icon>
                  <span>Distributor {{ row['Distributor.Name'] }}</span>
                </button>

                <button *ngIf="row['ID'] && isPromoterOrAdmin == true" (click)="edit(row['ID'])" mat-menu-item>
                  <mat-icon>edit</mat-icon>
                  <span>Edit Brand</span>
                </button>

                <button *ngIf="row['ID']" (click)="addDocument(row['ID'])" mat-menu-item>
                  <mat-icon>add_circle</mat-icon>
                  <span>Add Document</span>
                </button>

                <button *ngIf="row['ID']" (click)="importDocument(row['ID'])" mat-menu-item>
                  <mat-icon>import_export</mat-icon>
                  <span>Import Document</span>
                </button>

                <button *ngIf="row['ID']" (click)="viewDocuments(row['ID'], row['Label'])" mat-menu-item>
                  <mat-icon>book</mat-icon>
                  <span>View Documents</span>
                </button>

                <button *ngIf="row['ID']" (click)="viewProducts(row['ID'], row['Label'])" mat-menu-item>
                  <mat-icon class="fas fa-box-open"></mat-icon>
                  <span>View Products</span>
                </button>

                <button *ngIf="row['Active'] == '1' && isPromoterOrAdmin == true" (click)="disable(row['ID'])"
                        mat-menu-item>
                  <mat-icon>cancel</mat-icon>
                  <span>Disable</span>
                </button>

                <button *ngIf="row['Active'] == '0' && isPromoterOrAdmin == true" (click)="enable(row['ID'])"
                        mat-menu-item>
                  <mat-icon>check_circle</mat-icon>
                  <span>Enable</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="fieldColor">
          <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
          <mat-cell *cdkCellDef="let row" [ngStyle]="{ background: row['Category.Colour'] }"></mat-cell>
        </ng-container>

        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row class="listRow separatedRow" *cdkRowDef="let row; columns: displayedColumns"
                 [ngStyle]="{ 'border-top': '2px solid ' + row['Category.Colour'] }"></mat-row>
      </mat-table>
      <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="pageSize"
                     [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>
    </mat-card>
  </div>
</div>
<div *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <app-empty-list-message></app-empty-list-message>
</div>

<div class="showDocumentList" *ngIf="showDocList == true">
  <div class="row clearfix">
    <app-treatment-document-gallery-container class="clearfix" [brandID]="selectedBrandID" [title]="docListTitle">
    </app-treatment-document-gallery-container>
  </div>
  <div class="row clearfix text-center mt">
    <button (click)="hideViewDetails()" mat-raised-button color="accent">
      <mat-icon>chevron_left</mat-icon> Brands List
    </button>
  </div>
</div>

<div class="showDocumentList" *ngIf="showProductList == true">
  <div class="row clearfix">
    <app-treatment-product-list-global class="clearfix" [brandID]="selectedBrandID" [title]="productListTitle">
    </app-treatment-product-list-global>
  </div>
  <div class="row clearfix text-center mt">
    <button (click)="hideViewDetails()" mat-raised-button color="accent">
      <mat-icon>chevron_left</mat-icon> Brands List
    </button>
  </div>
</div>
