/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin-tip.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../feature/tip/tip-create/tip-create.component.ngfactory";
import * as i3 from "../../../../feature/tip/tip-create/tip-create.component";
import * as i4 from "../../../../feature/tip/shared/tip.service";
import * as i5 from "../../../../feature/tip/tip-list/tip-list.component.ngfactory";
import * as i6 from "../../../../feature/tip/tip-list/tip-list.component";
import * as i7 from "./admin-tip.component";
var styles_AdminTipComponent = [i0.styles];
var RenderType_AdminTipComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminTipComponent, data: {} });
export { RenderType_AdminTipComponent as RenderType_AdminTipComponent };
export function View_AdminTipComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tip-create", [], null, [[null, "add"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("add" === en)) {
        var pd_0 = (_co.createTip($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TipCreateComponent_0, i2.RenderType_TipCreateComponent)), i1.ɵdid(1, 114688, null, 0, i3.TipCreateComponent, [i4.TipService], null, { add: "add" }), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-tip-list", [], null, null, null, i5.View_TipListComponent_0, i5.RenderType_TipListComponent)), i1.ɵdid(5, 114688, null, 0, i6.TipListComponent, [i4.TipService], { tip: [0, "tip"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.tip; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_AdminTipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "admin-tip", [], null, null, null, View_AdminTipComponent_0, RenderType_AdminTipComponent)), i1.ɵdid(1, 114688, null, 0, i7.AdminTipComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminTipComponentNgFactory = i1.ɵccf("admin-tip", i7.AdminTipComponent, View_AdminTipComponent_Host_0, {}, {}, []);
export { AdminTipComponentNgFactory as AdminTipComponentNgFactory };
