import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../../../core/authentication/shared/authentication.service';
import { GoogleReviewsService } from '../../shared/google-reviews.service';

@Component({
  selector: 'sr-google-reviews-sign-in-dialog',
  templateUrl: './google-reviews-sign-in-dialog.component.html',
  styleUrls: ['./google-reviews-sign-in-dialog.component.css'],
})
export class GoogleReviewsSignInDialogComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  isServiceReady = false;
  spinnerText = 'Preparing Google My Business Service...';
  merchantID: string;
  sessionType = 'guest';

  constructor(
    private dialogRef: MatDialogRef<GoogleReviewsSignInDialogComponent>,
    private googleReviewsService: GoogleReviewsService,
    private ngZone: NgZone,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      this.subscriptions.add(
        this.authenticationService.getCurrentPractice().subscribe((merchant) => {
          this.merchantID = merchant['ID'];

          this.googleReviewsService.onLoad().subscribe(() => {
            this.isServiceReady = true;
            this.spinnerText = 'Connecting Account...';
          });
        })
      );
    });
  }

  signIn() {
    this.isServiceReady = false;
    this.subscriptions.add(
      this.googleReviewsService
        .openOAuthDialog()
        .pipe(
          mergeMap((response) => {
            const Google_Review_Code = response.code;
            return this.googleReviewsService
              .connectGoogleReviewAccount(this.merchantID, this.sessionType, Google_Review_Code)
              .pipe(
                tap(() => {
                  this.ngZone.run(() => {
                    this.dialogRef.close(Google_Review_Code);
                  });
                })
              );
          })
        )
        .subscribe(
          () => {},
          (response) => {
            this.isServiceReady = true;
            const error = response.error;
            if (error !== 'popup_closed_by_user') {
            }
          }
        )
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
