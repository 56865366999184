import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TreatmentService } from '../shared/treatment.service';

@Component({
  selector: 'app-brand-view-logo',
  templateUrl: './treatment-brand-view-logo.component.html',
  styleUrls: ['./treatment-brand-view-logo.component.css'],
})
export class TreatmentBrandViewLogoComponent implements OnInit {
  @Input() brandID;

  @Input() hideZoomButton = false;
  @Input()
  LastModified = null;
  @Output() close = new EventEmitter();
  profileLink;

  constructor(
    private TreatmentService: TreatmentService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.brandID = data;
    }
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.brandID && changes.brandID.previousValue != changes.brandID.currentValue) {
      if (this.brandID) {
        this.getPicture();
      } else {
        this.profileLink = null;
      }
    }
  }

  getPicture() {
    this.profileLink = null;

    if (this.brandID) {
      this.profileLink = this.TreatmentService.getBrandLogoStreamLink(this.brandID, this.LastModified);
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
}
