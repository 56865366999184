<!-- both visibility:hidden and display:none can be used,
     but the former can be used in CSS animations -->
<div
  id="{{ uploaderID + divID }}-container"
  [class]="'row clearfix rel main-container-uploader text-center drop-container' + divID"
  [ngClass]="{ 'two-thrd-width': isTwoThirdWidth }"
  [ngStyle]="{ height: hasResponsiveHeight ? '100%' : '100px' }"
>
  <div
    class="dropzone"
    id="{{ 'dropzone' + divID }}"
    [ngStyle]="{ height: hasResponsiveHeight ? '100%' : '100px' }"
    style="opacity: 0"
  >
    <div class="textnode primary-color animated infinite pulse">Drop your file here to upload.</div>
  </div>
  <div class="" *ngIf="uploader.queue.length < limit" [ngStyle]="{ height: hasResponsiveHeight ? '100%' : '100px' }">
    <div
      class="ng2FileDrop btn-file-input btn-large btn btn-large mat-raised-button btn-inverse"
      id="{{ uploaderID + divID }}"
      [uploader]="uploader"
      [attr.style]="
        'background-image: ' +
          backgroundImageUrl +
          ' !important;' +
          'height:' +
          (hasResponsiveHeight ? '100%' : '100px') | safeStyle
      "
      (onFileDrop)="hideDropZone()"
      ng2FileDrop
    >
      <input
        class="btn-small"
        id="{{ uploaderID + divID }}-input-multi"
        *ngIf="limit > 1"
        [disabled]="isDisabled"
        (change)="fileChange($event)"
        type="file"
        multiple
      />
      <input
        class="btn-small"
        id="{{ uploaderID + divID }}-input"
        *ngIf="limit == 1"
        [disabled]="isDisabled"
        (change)="fileChange($event)"
        type="file"
      />

      <p class="icon-drop rm-mb">
        <mat-icon class="main-icon">backup</mat-icon>
      </p>
      <p class="reduce-marg" *ngIf="!hasCustomTitleText" [innerHTML]="title | safeHtml"></p>
    </div>
    <div
      class="well my-drop-zone"
      id="{{ uploaderID + divID }}-drag"
      *ngIf="isDragable"
      [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
      [uploader]="uploader"
      (onFileDrop)="hideDropZone()"
      ng2FileDrop
    ></div>
  </div>
</div>
