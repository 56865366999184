import { Component, Inject, Input, OnInit, Optional } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-customer-summary',
  templateUrl: './customer-summary.component.html',
  styleUrls: ['./customer-summary.component.css'],
})
export class CustomerSummaryComponent implements OnInit {
  @Input() customerID;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.customerID = data;
    }
  }

  ngOnInit() {
    // this.customerService.getCustomerDetails(4).subscribe(res => {
    //   this.customer = res;
    // });
  }
}
