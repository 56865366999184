import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'validateArrayLength',
})
export class ValidateArrayLengthPipe implements PipeTransform {
  transform(value: any[]): boolean {
    const isValidArray = value && Array(value) && value.length > 0;
    return isValidArray;
  }
}
