<div class="row clearfix">
  <form #form="ngForm">
    <div class="card-header primary-gradient-img clearfix inModal testimonial" [class.push]="profilePic != null">
      <div class="row clearfix">
        <!-- Modal dismiss button -->
        <button class="btn-close btn-clear mat-button white" (click)="closeModalEvent()" mat-button tabIndex="-1">
          <mat-icon>clear</mat-icon>
        </button>
        <!-- merchant calculated name -->
        <div class="row titleArea clearfix">
          <div class="clearfix avtarView" *ngIf="profilePic != null">
            <app-ep-document-view-image class="clearfix full-width display-image profile" [defaultPicture]="
                'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
              " [document]="profilePic">
            </app-ep-document-view-image>
          </div>
          <h2 class="summary-header rm-m">
            How did we do?
            <span class="subLabel">Submit a review...</span>
          </h2>
        </div>
      </div>
    </div>

    <mat-dialog-content>
      <div class="row clearfix">
        <div class="clearfix" *ngIf="profilePic == null">
          <div class="row clearfix text-center">
            <div class="avContain">
              <img class="selectedAvatar" src="{{ selectedAvatar }}"/>
            </div>
          </div>
          <div class="row clearfix">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Select an avatar you'd like to display</mat-label>

              <mat-select class="two-thrd-width centered" [(ngModel)]="selectedAvatar" name="avatar"
                          placeholder="Select an avatar you'd like to display">
                <mat-option class="avatarListItem" *ngFor="let avatar of avatarImages" [value]="avatar.src">
                  <img class="listAvatar" src="{{ avatar.src }}"/> {{ avatar.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <!-- <mat-form-field class="full-width" appearance="outline">
        <input matInput type="text" name="title" [(ngModel)]="title" placeholder="Please enter the title" required>
      </mat-form-field> -->
        <div class="row clearfix">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Please enter your review</mat-label>

            <textarea class="increase-input-field" [(ngModel)]="content" matInput name="content"
                      placeholder="Please enter your review" cols="30" rows="50" required tabIndex="1"></textarea>
          </mat-form-field>
        </div>
        <div class="text-center row clearfix">
          <p class="rm-mb">How did you rate your service with {{ "BRANDING.Brand Name" | translate | titlecase }}?</p>
          <app-star-rating-create [rating]="rating" (setRating)="getRating($event)"></app-star-rating-create>
        </div>
        <div class="row clearfix">
          <button class="centered" [disabled]="!form.form.valid" (click)="createTestimony()" mat-raised-button
                  type="submit" color="accent">
            Submit
          </button>
        </div>
      </div>
    </mat-dialog-content>
  </form>
</div>
