import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { TreatmentService } from '../shared/treatment.service';

@Component({
  selector: 'app-category-create-edit',
  templateUrl: './treatment-category-create-edit.component.html',
  styleUrls: ['./treatment-category-create-edit.component.css'],
})
export class TreatmentCategoryCreateEditComponent implements OnInit {
  @Input()
  categoryID;

  category = {
    label: null,
    description: null,
    colour: '#008fe2',
    fileID: null,
    isActive: true,
    logoID: null,
  };

  isLogoFile = false;
  logoSrc;
  logoLink;

  @Output()
  closeModal = new EventEmitter();

  @Output()
  result = new EventEmitter();

  isModal = false;

  isEditMode = false;

  isDone = false;

  constructor(
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private utilsService: UtilsService,
    private router: Router,
    private treatmentService: TreatmentService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.categoryID) {
        this.categoryID = data.categoryID;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      this.authenticationService.initCurrUser();
      if (params['categoryID']) {
        this.categoryID = params['categoryID'];
      }

      this.setup();
    });
  }

  setup() {
    if (this.categoryID) {
      this.treatmentService.getCategoryDetails(this.categoryID).subscribe((res) => {
        if (res) {
          this.isEditMode = true;

          if (res['Label']) {
            this.category.label = res['Label'];
          }

          if (res['Description']) {
            this.category.description = res['Description'];
          }

          if (res['Colour']) {
            this.category.colour = res['Colour'];
          }

          if (res['Active'] == '1') {
            this.category.isActive = true;
          }

          if (res['Active'] == '0') {
            this.category.isActive = false;
          }

          this.logoLink = null;

          this.logoLink = this.treatmentService.getCategoryLogoStreamLink(this.categoryID, res['LastModified']);
        }
      });
    } else {
      this.isEditMode = false;
    }
  }

  closeEvent() {
    this.closeModal.emit(true);
  }

  save() {
    if (this.category) {
      this.treatmentService.createTreatmentCategoryGlobal(this.category).subscribe((res) => {
        if (res) {
          NotifyAppComponent.displayToast('success', 'Successful operation', 'Category has been successfully added');

          this.isDone = true;

          this.result.emit(res);
        }
      });
    }
  }

  edit() {
    if (this.category) {
      this.treatmentService.editTreatmentCategoryGlobal(this.categoryID, this.category).subscribe((res) => {
        if (res) {
          NotifyAppComponent.displayToast('success', 'Successful operation', 'Category has been successfully updated');
          this.isDone = true;
          this.result.emit(res);
        }
      });
    }
  }

  list() {
    this.router.navigate(['/merchant', { outlets: { page: ['treatment-category-list'] } }]);
  }

  changeLogoImage() {
    this.utilsService.getFileDetails(this.category.logoID).subscribe((res) => {
      if (res) {
        if (res.contentType == 'image/png' || res.contentType == 'image/jpg' || res.contentType == 'image/jpeg') {
          this.isLogoFile = true;
          this.logoSrc = `${environment.nodeUrl}/files/file-view/${this.category.logoID}`;
        } else {
          NotifyAppComponent.displayToast(
            'warning',
            'Error during operation',
            'Format of image should be png/jpg/jpeg'
          );
        }
      }
    });
  }

  onCompleteLogoAll(fileID) {
    if (fileID && fileID[0]) {
      this.category.logoID = fileID[0];
      this.changeLogoImage();
    }
  }
}
