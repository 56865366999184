import { Component, ElementRef, EventEmitter, Inject, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { OperatorViewComponent } from '../operator-view/operator-view.component';
import { OperatorService } from '../shared/operator.service';

@Component({
  selector: 'app-operator-lookup',
  templateUrl: './operator-lookup.component.html',
  styleUrls: ['./operator-lookup.component.css'],
})
export class OperatorLookupComponent implements OnInit {
  @Output()
  getResult = new EventEmitter();

  close = new EventEmitter();
  filterData;
  isPromoterOrAdmin = false;

  @ViewChild('firstInput', { static: false }) firstInput: ElementRef;

  name;
  email;
  mobile;
  phone;
  tradingAs;
  calculatedName;
  username;
  contactForID;
  type = 'merchant';
  serviceRef;
  isDestoyed = false;
  itemsPerPage = 10;
  displayOnClick = false;
  loginAsUserOnClick = false;
  currentPage;
  isMobileValid = true;
  isPhoneValid = true;
  isEmailValid = true;

  hideFilter = false;

  isModal = false;
  operators = [];
  selectedOperator;
  operatorTypes = [
    { text: 'Merchant', value: 'merchant' },
    { text: 'Patient', value: 'patient' },
    // { text: "Funder", value: "Funder" },
    { text: 'Promotor', value: 'promoter' },
    { text: 'Supplier', value: 'supplier' },
  ];
  orderByFilter = 'CalculatedName';
  merchants = [];
  suppliers = [];

  displayMerchantList = false;
  displaySupplierList = false;

  constructor(
    private authenticationService: AuthenticationService,
    private operatorService: OperatorService,
    private router: Router,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.isModal) {
        this.isModal = true;
      }

      if (data.displayOnClick != null) {
        this.displayOnClick = data.displayOnClick;
      }

      if (data.loginAsUserOnClick != null) {
        this.loginAsUserOnClick = data.loginAsUserOnClick;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      this.changeType();
    });
  }

  changeMerchant() {
    this.name = null;
    this.email = null;
    this.mobile = null;
    this.phone = null;
    this.tradingAs = null;
    this.calculatedName = null;
    this.username = null;
    this.contactForID = null;
    this.type = null;
  }

  changeType() {
    this.displayMerchantList = false;
    this.displaySupplierList = false;
    if (this.type == 'merchant' || this.type == 'patient') {
      if (this.merchants && this.merchants.length > 0) {
        this.displayMerchantList = true;
      } else {
        this.operatorService
          .getMerchantList(
            {
              fields: 'ID,TradingAs,LastModified',
              orderBy: 'TradingAs',
            },
            this.isPromoterOrAdmin
          )
          .subscribe((res) => {
            if (res && res.length > 0) {
              this.merchants = res;
              this.displayMerchantList = true;
            }
          });
      }
    } else if (this.type == 'supplier') {
      if (this.suppliers && this.suppliers.length > 0) {
        this.displaySupplierList = true;
      } else {
        this.operatorService
          .getSupplierList(
            {
              fields: 'ID,TradingAs',
              orderBy: 'TradingAs',
            },
            this.isPromoterOrAdmin
          )
          .subscribe((res) => {
            if (res && res.length > 0) {
              this.suppliers = res;
              this.displaySupplierList = true;
            }
          });
      }
    }

    this.contactForID = null;
  }

  getOperators() {
    this.currentPage = 1;
    const payload = {
      fields:
        'ID,UserName,Role,CalculatedName,IsAdministrator,DateTimeCreated,Enabled,UltimateTableName,Merchant_key,Card_key',
      name: this.name,
      email: this.email,
      mobile: this.mobile,
      phone: this.phone,
      tradingAs: this.tradingAs,
      calculatedName: this.calculatedName,
      username: this.username,
      contactForID: this.contactForID,
      type: this.type,
      section: 1,
    };
    this.serviceRef = this.operatorService.operatorLookup(payload).subscribe((res) => {
      if (res && res.length > 0) {
        this.operators = res;
        this.hideFilter = true;

        const innerFunction = (section) => {
          section = section + 1;
          payload.section = section;

          this.serviceRef = this.operatorService.operatorLookup(payload).subscribe((res1) => {
            if (res1.length > 0) {
              this.operators = this.operators.concat(res1);

              if (this.isDestoyed != true) {
                innerFunction(section);
              }
            } else {
              return true;
            }
          });
        };

        if (this.isDestoyed != true) {
          innerFunction(1);
        }
      } else {
        NotifyAppComponent.displayToast('warning', 'Sorry!', 'We did not any match  in our database.');
      }
    });
  }

  ngOnDestroy() {
    this.isDestoyed = true;
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }

  SelectRecordClickEvent(item) {
    if (item) {
      if (item && item['Card_key'] && this.loginAsUserOnClick == true) {
        const confirm = new ConfirmDialog(
          'fas fa-info',
          'You need to logout',
          'In order to change the connected user you need to logout',
          'Cancel',
          'Logout and Proceed'
        );

        const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
          data: confirm,
          disableClose: true,
        });
        ref.componentInstance.onConfirm.subscribe((confirmation) => {
          if (confirmation === false) {
            ref.close();
          } else {
            const body = {
              cardID: item['Card_key'],
            };
            this.authenticationService.loginAsUser(body).subscribe((res) => {
              if (res) {
                this.authenticationService.getCurrentUserForce().subscribe((getCurrentUserForce) => {
                  NotifyAppComponent.displayToast('success', 'success', 'Successful operation');

                  if (getCurrentUserForce && getCurrentUserForce.data) {
                    if (
                      getCurrentUserForce.data.SessionType == 'admin' ||
                      getCurrentUserForce.data.SessionType == 'merchant' ||
                      getCurrentUserForce.data.SessionType == 'merchant-admin' ||
                      getCurrentUserForce.data.SessionType == 'promoter'
                    ) {
                      UtilsClass.startLoading();
                      window.location.href = Settings.global['merchantFrontendLink'] + '/merchant';
                    } else if (getCurrentUserForce.data.SessionType == 'customer') {
                      UtilsClass.startLoading();
                      window.location.href = Settings.global['consumerFrontendLink'] + '/consumer-app/(page:home)';
                    }
                  }
                });
              }
            });
          }
        });
      } else if (this.displayOnClick == true || this.loginAsUserOnClick == true) {
        this.openQuickViewDialog(item);
      }
      this.getResult.emit(item);
    }
  }

  openQuickViewDialog(operator) {
    const ref = RootAppComponent.dialog.open(OperatorViewComponent, {
      data: operator.ID,
      width: '600px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewProfile(operator) {
    if (operator.UltimateTableName === 'Merchant' || operator.UltimateTableName === 'Promoter') {
      this.router.navigate(['/merchant', { outlets: { page: ['merchant-profile', operator.Merchant_key] } }]);
    } else if (operator.UltimateTableName === 'Beneficiary' || operator.UltimateTableName === 'Supplier') {
      this.router.navigate(['/merchant', { outlets: { page: ['beneficiary-catalogue-view', operator.Card_key] } }]);
    } else if (
      operator.UltimateTableName === 'Customer' ||
      operator.UltimateTableName === 'Prospect' ||
      operator.UltimateTableName === 'CustomersAndProspects'
    ) {
      this.router.navigate(['/merchant', { outlets: { page: ['customer-profile', operator.Card_key, 'patient'] } }]);
    }
    this.closeEvent();
  }

  viewSession(operator) {
    this.router.navigate(['/merchant', { outlets: { page: ['operator-session-overview', operator.ID] } }]);
    this.closeEvent();
  }

  goBack() {
    this.hideFilter = false;
  }

  goForward() {
    this.hideFilter = true;
  }

  orderBy(d) {
    if (d) {
      if (this.orderByFilter === d) {
        this.orderByFilter = '-' + d;
      } else {
        this.orderByFilter = d;
      }
    }
  }

  changePage(e) {
    if (e) {
      this.currentPage = e;
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
}
