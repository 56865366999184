<div class="rel">
  <div>
    <mat-icon class="large-modal-icon">account_circle</mat-icon>
    <h3 class="sr-title sm-h3 mobhide">Hold the bus - we have found a possible match.</h3>
    <p class="rm-mt mobhide">
      When comparing what we have in the database with what you entered, we have found the following records with
      similar data. <br/>Can you confirm if any of the possible matches below are the same as the one you are entering?
    </p>
    <h3 class="sr-title sm-h3 rm-mb">Search Results</h3>
    <div class="clearfix">
      <mat-form-field class="full-width" *ngIf="patients.length > 3">
        <mat-label>Search by Name, email or phone number</mat-label>
        <input [(ngModel)]="filterData" matInput name="search" placeholder="e.g. John Doe, johndoe@gmail.com"/>
      </mat-form-field>
    </div>
  </div>
  <div class="patientContainer">
    <div
      class="patientRecord"
      *ngFor="
        let patient of patients | FilterArrayMultipleOrContain: ['LastName', 'FirstName', 'Email', 'Mobile']:filterData
      "
      [ngClass]="{ activeExistingPatient: selectedPatient == patient }"
      (click)="selectPatient(patient)"
    >
      <button
        class="pull-right mat-button btn-close"
        (click)="openPatientDialog(patient['ID'])"
        matTooltip="View Profile: {{ patient.FirstName }} {{ patient.LastName }}"
      >
        <mat-icon>visibility</mat-icon>
      </button>
      <h3 class="sr-title rm-mb">{{ patient.FirstName }} {{ patient.LastName }}</h3>

      <p>
        {{ patient.Mobile | customPhone }} | {{ patient.Email }} | Age:
        <span *ngIf="utilsClass.calculateAge(patient.DateOfBirth)">
          {{ utilsClass.calculateAge(patient.DateOfBirth) }}
        </span>
        <span *ngIf="!utilsClass.calculateAge(patient.DateOfBirth)"> ...</span>
      </p>
    </div>
  </div>
  <br/>

  <div class="button-row text-center">
    <button (click)="editSelectedPatient()" mat-raised-button color="primary">Back</button>
    <!-- Can optionally provide a result for the closing dialog. -->
    <button (click)="createSelectedPatient()" mat-raised-button color="accent">Create Anyway</button>
  </div>
</div>
