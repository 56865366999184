import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageViewComponent } from '../../../feature/message/message-view/message-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { TextEditorInputComponent } from '../../../shared/components/text-editor-input/text-editor-input.component';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { AuthenticationService } from '../../authentication/shared/authentication.service';

@Component({
  selector: 'app-practice-appointment-cancellation-policy',
  templateUrl: './practice-appointment-cancellation-policy.component.html',
  styleUrls: ['./practice-appointment-cancellation-policy.component.css'],
})
export class PracticeAppointmentCancellationPolicyComponent implements OnInit, OnDestroy {
  appointmentCancellingHours: number;
  appointmentCancellingFee: number;
  smsConfirmAppointment = false;
  smsAppointmentReminder = false;
  appointmentCancellingPolicy = '';

  wordCounter = 0;

  isModal = false;
  cursorPos: number;

  persData = [
    { id: 'Practice Name', value: '{{PracticeName}}' },
    { id: 'Fee', value: '{{Fee}}' },
    { id: 'Hours', value: '{{Hours}}' },
    { id: 'Description', value: '{{Description}}' },
    { id: 'Practice Email', value: '{{PracticeEmail}}' },
    { id: 'Practice Phone', value: '{{PracticePhone}}' },
  ];

  @Output()
  closeModal = new EventEmitter();

  @Output()
  editedAppointmentCancellingPolicy = new EventEmitter();

  @ViewChild('textEditor', { static: true })
  textEditor: TextEditorInputComponent;

  // Private
  private _unsubscribeAll = new Subject<any>();

  @ViewChild('messageBodyID', { static: false })
  messageBody: ElementRef<HTMLInputElement>;

  constructor(
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.isModal = true;
    }
  }

  ngOnInit(): void {
    this.authenticationService
      .getCurrentPractice()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.setupFieldsWithCurrentPractice(res);
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  closeEvent(): void {
    this.closeModal.emit(true);
  }

  onSave(): void {
    const payload = {
      appointmentCancellingHours: this.appointmentCancellingHours,
      appointmentCancellingFee: this.appointmentCancellingFee,
      smsConfirmAppointment: this.smsConfirmAppointment,
      smsAppointmentReminder: this.smsAppointmentReminder,
      appointmentCancellingPolicy: this.appointmentCancellingPolicy,
    };

    this.authenticationService
      .editCurrentPractice(payload)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        if (res) {
          this.editedAppointmentCancellingPolicy.emit(res);
          NotifyAppComponent.displayToast(
            'success',
            'Successful Operation',
            'You have successfully edited Appointment Cancelling Policy!'
          );
          this.closeModal.emit(true);
        }
      });
  }

  onUpdateContent(c): void {
    this.appointmentCancellingPolicy = c;
  }

  addPersonalisation(p: string): void {
    // if (this.cursorPos > 0) {
    //   this.appointmentCancellingPolicy =
    //     this.appointmentCancellingPolicy.slice(0, this.cursorPos) +
    //     " " +
    //     p.value +
    //     " " +
    //     this.appointmentCancellingPolicy.slice(this.cursorPos);
    // } else {
    //   this.appointmentCancellingPolicy =
    //     p.value + " " + this.appointmentCancellingPolicy;
    // }
    // // this.appointmentCancellingPolicy.content = this.appointmentCancellingPolicy.content + " " + p.value;
    // this.onKey(this.appointmentCancellingPolicy);
    this.textEditor.insertText(this.cursorPos, p);
  }

  onKey(value): void {
    if (value) {
      this.wordCounter = value.match(/\S+/g).length;
    } else {
      this.wordCounter = 0;
    }
  }

  wordCount(str: string): number {
    return str.split(' ').filter((n) => {
      return n != '';
    }).length;
  }

  updateCursorPos(pos: number): void {
    // this.cursorPos = this.messageBody.nativeElement.selectionStart;
    this.cursorPos = pos;
  }

  private setupFieldsWithCurrentPractice(currentPractice): void {
    this.appointmentCancellingHours = currentPractice['Appointment_Cancelling_Hours'];
    this.appointmentCancellingFee = currentPractice['Appointment_Cancelling_Fee'];
    this.smsConfirmAppointment = currentPractice['SMS_ConfirmAppointment'] === '1';
    this.smsAppointmentReminder = currentPractice['SMS_AppointmentReminder'] === '1';
    this.appointmentCancellingPolicy = currentPractice['Appointment_Cancelling_Policy'] || '';
    this.wordCounter = this.wordCount(this.appointmentCancellingPolicy);
  }

  previewSMS() {
    const ref = RootAppComponent.dialog.open(MessageViewComponent, {
      data: {
        messageType: 'S',
      },
      width: '750px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }
}
