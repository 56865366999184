import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { LookupService } from '../../../shared/services/lookup.service';
import { LookupClass } from '../../../shared/types/lookupClass';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { CustomerListIntroModalComponent } from '../customer-list-intro-modal/customer-list-intro-modal.component';
import { CustomerSummaryComponent } from '../customer-summary/customer-summary.component';
import { CustomerService } from '../shared/customer.service';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css'],
  providers: [CustomDatePipe],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class CustomerListComponent implements OnInit {
  isModuleCustomerActive = false;

  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  utils = new UtilsClass();
  lookup = new LookupClass();
  displayedColumns = ['FirstName', 'Name', 'DateOfBirth', 'mobile', 'email', 'Actions'];

  searchVals = new FormControl();

  searchValList = ['First Name', 'Last Name', 'Email', 'Date Of Birth', 'Mobile'];

  filters = [];

  public customersDB: LoadCustomers | null;
  dataSource: CustomerDataSource | null;

  filteredSize = null;

  destroyEvent = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private lookupService: LookupService,
    private customerService: CustomerService,
    private dialog: MatDialog,
    private router: Router,
    private customDate: CustomDatePipe
  ) {}

  ngOnInit() {
    const ref = this.dialog.open(CustomerListIntroModalComponent, {
      width: '800px',
      panelClass: 'noCard',
    });
    if (ref.componentInstance.showOnPatientListFirstVisit == true) {
      ref.close();
    } else {
      const sub = ref.componentInstance.closeModal.subscribe((data) => {
        ref.close();
      });
    }

    this.customersDB = new LoadCustomers(this.customerService, this.destroyEvent);
    this.dataSource = new CustomerDataSource(this.customersDB, this.paginator, this.sort);
  }

  setFilter(event, field) {
    let filter;
    if (typeof event == 'object' && event != null) {
      const v = this.customDate.transform(event, Settings.global['dateFormat']);
      filter = {
        field,
        value: v,
      };
    } else if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  openQuickViewDialog(id) {
    const ref = this.dialog.open(CustomerSummaryComponent, {
      data: id,
      width: '600px',
      panelClass: 'noCard',
    });
  }

  linkToInvitation(customer) {
    this.customerService.linkToInv(customer.ID).subscribe((res) => {
      if (res.success) {
        this.router.navigate(['/merchant', { outlets: { page: ['invitation-create', res.data] } }]);
      }
    });
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadCustomers implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  customers: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  isDestoyed = false;

  serviceRef;

  constructor(private customerService: CustomerService, private destroyEvent) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });
    const payload = {
      Voided: 0,
      section: 0,
      fields: 'ID,FirstName,Name,DateOfBirth,addresses.Calculated,mobiles.Number,emails.Email',
    };

    this.serviceRef = this.customerService.getCustomersList(payload).subscribe((res) => {
      this.count = res.length;
      this.customers = res;

      this.dataChange.next(this.customers.slice());
      this.firstLoad = true;
      const innerFunction = (section) => {
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.customerService.getCustomersList(payload).subscribe((res) => {
          if (res.length > 0) {
            this.count = res.count;
            this.customers = this.customers.concat(res);

            this.dataChange.next(this.customers);

            if (this.isDestoyed != true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };

      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class CustomerDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  filteredData: any[] = [];

  constructor(private _custDatabase: LoadCustomers, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._custDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._custDatabase.data.slice().filter((item: any) => {
          let f = '';

          f = item[this.field] || '';

          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'FirstName':
          [propertyA, propertyB] = [a.FirstName, b.FirstName];
          break;
        case 'Name':
          [propertyA, propertyB] = [a.Name, b.Name];
          break;
        case 'DateOfBirth':
          [propertyA, propertyB] = [
            moment(a['DateOfBirth']).toDate().getTime(),
            moment(b['DateOfBirth']).toDate().getTime(),
          ];
          break;

        case 'mobile':
          [propertyA, propertyB] = [a['mobiles.Number'], b['mobiles.Number']];
          break;
        case 'email':
          [propertyA, propertyB] = [a['emails.Email'], b['emails.Email']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
