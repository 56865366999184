import { Component, EventEmitter, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { FileInsertionComponent } from '../../../shared/components/file-insertion/file-insertion.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { Settings } from '../../../shared/types/settings';
import { DentistService } from '../../dentist/shared/dentist.service';
import { LandingPageHeaderLookupComponent } from '../../document/landing-page-header-lookup/landing-page-header-lookup.component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { ProductService } from '../../product/shared/product.service';
import { ProductUtil } from '../../product/shared/productUtil.type';
import { InstantOfferService } from '../shared/instant-finance-offer.service';

import * as _ from 'lodash';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { TagCreateEditComponent } from '../../tags/tag-create-edit/tag-create-edit.component';
import { InstantOfferExternalLinkViewComponent } from '../instant-offer-external-link-view/instant-offer-external-link-view.component';

@Component({
  selector: 'instant-offer-external-link',
  templateUrl: './instant-offer-external-link.component.html',
  styleUrls: ['./instant-offer-external-link.component.css'],
})
export class InstantOfferExternalLinkComponent implements OnInit {
  titlePage = 'Create Customized Link';
  descriptionPage = 'Choose Product details and picture';

  merchantID;

  treatmentAmount = 5000;

  productGroup = 'DPG';

  productGroups = [];

  productDetails;
  productImage;

  merchant;
  contact;
  promoter;

  maxLoanAmount;
  minLoanAmount;
  loadMerchant = false;
  loadContact = false;
  loadPromoter = false;
  title;
  _description = `<p>At {{PracticeTradingAs}} , we pride ourselves on providing our patients with straightforward, open, and transparent solutions.  <br><br>
So with Smile Right {{PatientFirstName}} , we are giving you 3 easy to understand options for a range of dental procedures.</p>`;
  _message = `<p>Hi {{PatientFirstName}},<br>
As discussed, we provide payment options for your next procedure. Your new Smile is just three steps away.
<ul>
<li> <strong>Step 1:</strong> Choose the product right for you, and our simple and guided application process takes as little as 5 minutes to complete (depending on the selected product).</li>
<li> <strong>Step 2:</strong> Smile Right will notify us once approved, and you are free to commence treatment.
Smile Right settles your bill with us as the procedure is completed - Hassle-free.</li>
<li> <strong>Step 3:</strong> Smile and enjoy - {{PatientFirstName}} now you can confidently continue your treatment, knowing Smile Right has removed the financial burden. <br>
If you have any further queries on which plan suits your needs, feel free to call the team at Smile Right on {{PromoterPhoneNumber}}. </li>
</ul>
<br>
Kind Regards<br>
{{DentistFirstName}} <br>
{{PracticeTradingAs}} <br>
{{PracticePhoneNumber}}</p>`;
  contactID;
  messageMode;

  theme = {
    code: 'blue',
    background: 'linear-gradient(135deg, #0092eb 0%, #0dbad3 100%)',
    color: '#1b8bdd',
  };
  themes;
  getResult = new EventEmitter();
  close = new EventEmitter();
  sessionType;
  isPromoterOrAdmin = false;

  headerImage =
    'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/character/dentist_hero_pair.png';
  productUtil = new ProductUtil();
  loadMessage = false;
  displayAppointmentPage = true;
  displayTipsAndOffersPage = true;
  displayGoingGreenPage = true;
  isHeaderImage = true;
  description;
  descriptionRaw;
  messageRaw;
  message;
  financedProductGroup = [];

  dentists = [];
  documentTypes = ['Overview', 'Additional Document'];

  fileIDs = [];

  addFile = new EventEmitter();

  tags = [];
  selectedTags = [];
  defaultSelectedTags = [];

  addTag = new EventEmitter();

  constructor(
    private dentistService: DentistService,
    private productService: ProductService,
    private instantOfferService: InstantOfferService,
    private merchantService: MerchantService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data && data.messageMode) {
        this.messageMode = data.messageMode;
      }

      if (data && data.contactID) {
        this.contactID = data.contactID;
      }

      if (data && data.merchantID) {
        this.merchantID = data.merchantID;
      }
      if (data && data.contact) {
        this.contact = data.contact;
      }

      if (data && data.merchant) {
        this.merchant = data.merchant;
      }

      if (data && data.promoter) {
        this.promoter = data.promoter;
      }
    }
  }

  ngOnInit() {
    if (Settings.global['context'] === 'p4p') {
      this.theme = {
        code: 'ee350b',
        background: ' linear-gradient(-135deg, #1caaa0 -10.31%, #7462ef  97.05%)',
        color: '#7462ef',
      };
    }

    this.instantOfferService.instantOfferListTheme().subscribe((t) => {
      if (t && t.length > 0) {
        this.themes = t;
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((isAdmin) => {
        this.isPromoterOrAdmin = isAdmin;

        this.authenticationService.getSessionType().subscribe((st) => {
          this.sessionType = st;
          this.dentistService
            .getList({ merchantID: this.merchantID, fields: 'ID,CalculatedName' }, this.isPromoterOrAdmin)
            .subscribe((res) => {
              if (res && res.length > 0) {
                this.dentists = res;
              }
            });

          this.init();
        });
      });
    });
  }

  init() {
    const pTag = {
      isPromoter: false,
      merchantID: this.merchantID,
      fields: 'Label',
    };

    this.instantOfferService.getTagsList(pTag, this.sessionType).subscribe((res) => {
      if (res) {
        this.tags = _.map(res, (item) => {
          if (item && item.Label) {
            return item.Label;
          }

          return null;
        });
      }
    });

    const p1 = {
      merchantID: this.merchantID,
      isFinance: true,
    };

    this.productService.getMerchantProductGroup(p1, this.isPromoterOrAdmin).subscribe((res) => {
      if (res && res.length > 0) {
        const index = res.findIndex((item) => {
          if (item && item['GroupCode'] === 'GPF') {
            return true;
          }
          return false;
        });
        if (index != -1 && !this.productGroup) {
          this.financedProductGroup = res;
          this.productGroup = 'GPF';
        } else if (!this.productGroup) {
          this.financedProductGroup = res;
          if (res && res[0] && res[0]['GroupCode']) {
            this.productGroup = res[0]['GroupCode'];
          }
        } else {
          this.financedProductGroup = res;
        }
      }

      this.loadMessage = true;
      if (this.productGroup) {
        this.getLoanAmount(this.productGroup);
      }

      if (this.merchantID && this.isPromoterOrAdmin == true) {
        this.authenticationService.getMerchantDetails(this.merchantID, {}, this.sessionType).subscribe((r) => {
          if (r && r.ID) {
            this.merchant = r;

            this.buildMerchant(this.merchant);
            this.loadMerchant = true;
          }
        });
      } else {
        this.authenticationService.getCurrentPractice().subscribe((r) => {
          if (r && r.ID) {
            this.merchant = r;

            this.buildMerchant(this.merchant);
            this.loadMerchant = true;
          }
        });
      }

      if (this.contactID) {
        this.authenticationService.getCardDetails(this.contactID, 'Contact', {}, this.sessionType).subscribe((r) => {
          if (r && r.ID) {
            this.contact = r;
            this.loadContact = true;
          }
        });
      } else {
        this.authenticationService.getCurrentDentist().subscribe((r) => {
          if (r && r.ID) {
            this.contact = r;
            this.loadContact = true;
          }
        });
      }

      this.authenticationService.getPromoter().subscribe((r) => {
        if (r && r.data) {
          this.promoter = r.data;
          this.loadPromoter = true;
        } else if (r) {
          this.promoter = r;
          this.loadPromoter = true;
        }
      });
    });
  }

  galleryHeader() {
    const ref2 = RootAppComponent.dialog.open(LandingPageHeaderLookupComponent, {
      data: {
        merchantID: this.merchantID,
      },
      width: '900px',
    });

    ref2.componentInstance.close.subscribe((res) => {
      ref2.close();
    });

    ref2.componentInstance.getResult.subscribe((res) => {
      if (res) {
        this.headerImage = res;
        ref2.close();
      }
    });
  }
  upload() {
    const ref = RootAppComponent.dialog.open(FileInsertionComponent, {
      data: {
        documentType: 'LP_HI',
        displayGallery: false,
        isGallery: true,
        isFixedAspectRatio: true,
        roundCropper: true,
        aspectRatioType: 'full',
        documentNature: 'image',
        keepOriginal: false,
        maxFileSize: 1,
      },
      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.getResult.subscribe((document) => {
      if (document && document.AWS_Bucket_Name && document.AWS_URL) {
        this.headerImage = `${Settings.global['AWSS3Prefix']}/${document.AWS_Bucket_Name}${document.AWS_URL}?type=c`;

        ref.close();
      }
    });
  }

  getMerchantLink() {
    return environment.nodeUrl + '/merchant/practice-picture-stream/' + this.merchantID;
  }

  getCircle(headerImage) {
    if (headerImage && headerImage.indexOf('?type=c') != -1) {
      return true;
    }

    return false;
  }

  getLoanAmount(productCode) {
    if (productCode) {
      const payload = {
        groupCode: productCode,
        merchantID: this.merchantID,
        fields: 'Parameter.MaxLoanValue,Parameter.MinLoanValue',
      };
      this.productService.getList(payload, this.isPromoterOrAdmin).subscribe((res) => {
        if (res && res.length > 0) {
          const temp = res.map((x) => Number(x['Parameter.MaxLoanValue']));
          this.maxLoanAmount = Math.max(...temp) || 0;

          const temp2 = res.map((x) => Number(x['Parameter.MinLoanValue']));
          this.minLoanAmount = Math.min(...temp2) || 0;
        }
      });
    }
  }
  buildMerchant(merchant) {
    if (merchant) {
      if (merchant['Hide_Landing_Page_HdThumbnail'] === '1') {
        this.isHeaderImage = false;
      } else {
        this.isHeaderImage = true;
      }

      if (merchant['Hide_Landing_Page_Appointment'] === '1') {
        this.displayAppointmentPage = false;
      } else {
        this.displayAppointmentPage = true;
      }

      if (merchant['Hide_Landing_Page_Going_Green'] === '1') {
        this.displayGoingGreenPage = false;
      } else {
        this.displayGoingGreenPage = true;
      }

      if (merchant['Hide_Landing_Page_Tips'] === '1') {
        this.displayTipsAndOffersPage = false;
      } else {
        this.displayTipsAndOffersPage = true;
      }

      if (merchant['Landing_Page_Default_Label']) {
        this.title = merchant['Landing_Page_Default_Label'];
      }

      if (merchant['Landing_Page_Default_Descrpt']) {
        this.description = merchant['Landing_Page_Default_Descrpt'];
        this._description = merchant['Landing_Page_Default_Descrpt'];
        this.descriptionRaw = merchant['Landing_Page_Default_Descrpt'];
      }

      if (merchant['Landing_Page_Default_Message']) {
        this.message = merchant['Landing_Page_Default_Message'];
        this._message = merchant['Landing_Page_Default_Message'];
        this.messageRaw = merchant['Landing_Page_Default_Message'];
      }

      if (merchant['Landing_Page_Default_Sender_key']) {
        this.contactID = merchant['Landing_Page_Default_Sender_key'];
      }

      if (merchant['Landing_Page_Default_Thumbnail']) {
        this.headerImage = merchant['Landing_Page_Default_Thumbnail'];
      }
      if (merchant['Landing_Page_Default_Product_Group']) {
        this.productGroup = merchant['Landing_Page_Default_Product_Group'];
      }

      if (merchant['Landing_Page_Default_Theme'] && this.themes && this.themes.length > 0) {
        const index = this.themes.findIndex((e) => {
          if (e && e.code && e.code === merchant['Landing_Page_Default_Theme']) {
            return true;
          }

          return false;
        });

        if (index !== -1) {
          this.theme = this.themes[index];
        }
      }
    }
  }

  selectTheme(d) {
    if (d && d.code) {
      this.theme = d;
    }
  }

  getResultDescription(e) {
    if (e) {
      this.description = e.convertedMessage;
      this.descriptionRaw = e.message;
    } else {
      this.description = null;
      this.descriptionRaw = null;
    }
  }
  getResultMessage(e) {
    if (e) {
      this.message = e.convertedMessage;

      this.messageRaw = e.message;
    } else {
      this.message = null;
      this.messageRaw = null;
    }
  }

  getSelectedTags(e) {
    this.selectedTags = e || [];
  }
  onCompleFile(f) {
    if (f && f[0]) {
      this.addFile.emit(f[0]);
      this.fileIDs.push(f[0]);
    }
  }
  removeNewFile(e) {
    if (e) {
      const index = this.fileIDs.findIndex((item) => {
        if (item && item === e) {
          return true;
        }
        return false;
      });

      if (index !== -1) {
        this.fileIDs.splice(index, 1);
      }
    }
  }
  createNewTag() {
    const ref = RootAppComponent.dialog.open(TagCreateEditComponent, {
      data: {
        merchantID: this.merchantID,
      },

      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res && res.Label) {
        if (
          (!this.tags || (this.tags && this.tags.length <= 0)) &&
          (!this.defaultSelectedTags || (this.defaultSelectedTags && this.defaultSelectedTags.length <= 0))
        ) {
          this.defaultSelectedTags = JSON.parse(JSON.stringify([res.Label]));
          this.tags = JSON.parse(JSON.stringify([res.Label]));
        } else {
          if (!this.tags || (this.tags && this.tags.length <= 0)) {
            this.tags.push(res.Label);
          }
          this.addTag.emit(res.Label);
        }
        ref.close();
      }
    });
  }

  closeEvent() {
    this.close.emit(true);
  }
  submit() {
    const payload = {
      title: this.title,
      description: this.description,
      productDetails: this.productDetails,
      displayAppointmentPage: this.displayAppointmentPage,
      displayGoingGreenPage: this.displayGoingGreenPage,
      displayTipsAndOffersPage: this.displayTipsAndOffersPage,
      fileIDs: this.fileIDs,
      tags: this.tags,
      headerImage: this.headerImage,
      senderID: this.contactID,
      merchantID: this.merchantID,
      productGroup: this.productGroup,
    };

    this.instantOfferService.createInstantOfferLink(payload, this.sessionType).subscribe((r) => {
      if (r && r.ID) {
        NotifyAppComponent.displayToast('success', 'Successfull', ``);

        const ref2 = RootAppComponent.dialog.open(InstantOfferExternalLinkViewComponent, {
          data: {
            id: r.ID,
          },
          width: '900px',
        });

        ref2.componentInstance.close.subscribe((res) => {
          ref2.close();
        });
      }
    });
  }

  changeDentist() {
    if (this.contactID) {
      this.loadContact = false;
      this.authenticationService.getCardDetails(this.contactID, 'Contact', {}, this.sessionType).subscribe((r) => {
        if (r && r.ID) {
          this._description = this.descriptionRaw;
          this._message = this.messageRaw;
          this.contact = r;
          this.contactID = r.ID;
        }
        this.loadContact = true;
      });
    }
  }
}
