import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddressLabelInputComponent } from '../../../shared/components/address-label-input/address-label-input.component';
import { BlobViewComponent } from '../../../shared/components/blob-view/blob-view.component';
import { BsbLookupComponent } from '../../../shared/components/bsb-lookup/bsb-lookup.component';
import { CalendlyComponent } from '../../../shared/components/calendly/calendly.component';
import { ConfirmDialogMessageComponent } from '../../../shared/components/confirm-dialog-message/confirm-dialog-message.component';
import { ConfirmDialogMultipleComponent } from '../../../shared/components/confirm-dialog-multiple/confirm-dialog-multiple.component';
import { DdrModalComponent } from '../../../shared/components/ddr-modal/ddr-modal.component';
import { DetectBrowserComponent } from '../../../shared/components/detect-browser/detect-browser.component';
import { DocumentUrlViewComponent } from '../../../shared/components/document-url-view/document-url-view.component';
import { EmailLabelInputComponent } from '../../../shared/components/email-label-input/email-label-input.component';
import { EPDocumentCreateEditComponent } from '../../../shared/components/ep-document-create-edit/ep-document-create-edit.component';
import { EpDocumentListComponent } from '../../../shared/components/ep-document-list/ep-document-list.component';
import { EpDocumentRejectModalComponent } from '../../../shared/components/ep-document-reject-modal/ep-document-reject-modal.component';
import { EpDocumentViewImageComponent } from '../../../shared/components/ep-document-view-image/ep-document-view-image.component';
import { EpDocumentViewShortComponent } from '../../../shared/components/ep-document-view-short/ep-document-view-short.component';
import { EpDocumentViewComponent } from '../../../shared/components/ep-document-view/ep-document-view.component';
import { FileContentComponent } from '../../../shared/components/file-content/file-content.component';
import { FileImageCropperComponent } from '../../../shared/components/file-image-cropper/file-image-cropper.component';
import { FileInsertionComponent } from '../../../shared/components/file-insertion/file-insertion.component';
import { FileViewComponent } from '../../../shared/components/file-view/file-view.component';
import { IframeViewComponent } from '../../../shared/components/iframe-view/iframe-view.component';
import { ImageViewListComponent } from '../../../shared/components/image-view-list/image-view-list.component';
import { LinkInputComponent } from '../../../shared/components/link-input/link-input.component';
import { MapCreateComponent } from '../../../shared/components/map-create/map-create.component';
import { MapMultipleViewComponent } from '../../../shared/components/map-multiple-view/map-multiple-view.component';
import { MapViewComponent } from '../../../shared/components/map-view/map-view.component';
import { MobileLabelInputComponent } from '../../../shared/components/mobile-label-input/mobile-label-input.component';
import { PhoneLabelInputComponent } from '../../../shared/components/phone-label-input/phone-label-input.component';
import { TextEditorInsertHtmlComponent } from '../../../shared/components/text-editor-insert-html/text-editor-insert-html.component';
import { TextEditorViewComponent } from '../../../shared/components/text-editor-view/text-editor-view.component';
import { VideoTutorialsModalComponent } from '../../../shared/components/video-tutorials-modal/video-tutorials-modal.component';
import { ViewImageSimpleComponent } from '../../../shared/components/view-image-simple/view-image-simple.component';
import { AgentDetailsComponent } from '../../session/agent-details/agent-details.component';
import { ChangeSocialMediaComponent } from '../change-social-media/change-social-media.component';
import { CheckPasswordComponent } from '../check-password/check-password.component';
import { ErrorModalAuthenticationComponent } from '../error-modal/error-modal.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { PasswordChangeComponent } from '../password-change/password-change.component';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { ResetUserNameComponent } from '../reset-user-name/reset-user-name.component';
import { SecurityCodeCurrentUserComponent } from '../security-code-current-user/security-code-current-user.component';
import { SecurityCodeInfoComponent } from '../security-code-info/security-code-info.component';
import { SecurityCodeInput } from '../security-code-input/security-code-input.component';
import { SocialMediaInputComponent } from '../social-media-input/social-media-input.component';

const routes: Routes = [
  {
    path: 'EPDocumentCreateEditComponent',
    component: EPDocumentCreateEditComponent,
  },

  {
    path: 'CalendlyComponent',
    component: CalendlyComponent,
  },

  {
    path: 'TextEditorViewComponent',
    component: TextEditorViewComponent,
  },
  {
    path: 'TextEditorInsertHtmlComponent',
    component: TextEditorInsertHtmlComponent,
  },
  {
    path: 'DocumentUrlViewComponent',
    component: DocumentUrlViewComponent,
  },
  {
    path: 'ViewImageSimpleComponent',
    component: ViewImageSimpleComponent,
  },
  {
    path: 'ddr-modal',
    component: DdrModalComponent,
  },
  {
    path: 'BsbLookupComponent',
    component: BsbLookupComponent,
  },
  {
    path: 'EpDocumentViewComponent',
    component: EpDocumentViewComponent,
  },

  {
    path: 'FileContentComponent',
    component: FileContentComponent,
  },
  {
    path: 'ConfirmDialogMessageComponent',
    component: ConfirmDialogMessageComponent,
  },
  {
    path: 'MapMultipleViewComponent',
    component: MapMultipleViewComponent,
  },
  {
    path: 'ConfirmDialogMultipleComponent',
    component: ConfirmDialogMultipleComponent,
  },
  {
    path: 'EpDocumentListComponent',
    component: EpDocumentListComponent,
  },
  {
    path: 'BlobViewComponent',
    component: BlobViewComponent,
  },
  {
    path: 'detect-browser',
    component: DetectBrowserComponent,
  },
  {
    path: 'EpDocumentViewImageComponent',
    component: EpDocumentViewImageComponent,
  },
  {
    path: 'FileImageCropperComponent',
    component: FileImageCropperComponent,
  },
  {
    path: 'file-view',
    component: FileViewComponent,
  },
  {
    path: 'map-view',
    component: MapViewComponent,
  },

  {
    path: 'ep-document-view',
    component: EpDocumentViewShortComponent,
  },
  {
    path: 'agent-details',
    component: AgentDetailsComponent,
  },
  {
    path: 'FileInsertionComponent',
    component: FileInsertionComponent,
  },
  {
    path: 'LinkInputComponent',
    component: LinkInputComponent,
  },
  {
    path: 'map-create',
    component: MapCreateComponent,
  },
  {
    path: 'address-label-input',
    component: AddressLabelInputComponent,
  },
  {
    path: 'email-label-input',
    component: EmailLabelInputComponent,
  },
  {
    path: 'mobile-label-input',
    component: MobileLabelInputComponent,
  },
  {
    path: 'phone-label-input',
    component: PhoneLabelInputComponent,
  },
  {
    path: 'ep-document-reject-modal',
    component: EpDocumentRejectModalComponent,
  },
  {
    path: 'app-video-tutorials-modal',
    component: VideoTutorialsModalComponent,
  },
  {
    path: 'app-image-view-list',
    component: ImageViewListComponent,
  },
  {
    path: 'IframeViewComponent',
    component: IframeViewComponent,
  },

  {
    path: 'SocialMediaInputComponent',
    component: SocialMediaInputComponent,
  },

  {
    path: 'ChangeSocialMediaComponent',
    component: ChangeSocialMediaComponent,
  },

  {
    path: 'SecurityCodeCurrentUserComponent',
    component: SecurityCodeCurrentUserComponent,
  },

  {
    path: 'CheckPasswordComponent',
    component: CheckPasswordComponent,
  },

  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'reset-password/:operatorID/:token',
    component: ResetPasswordComponent,
  },

  {
    path: 'SecurityCodeInfoComponent',
    component: SecurityCodeInfoComponent,
  },

  {
    path: 'SecurityCodeInput',
    component: SecurityCodeInput,
  },

  {
    path: 'forgot-password/',
    component: ForgotPasswordComponent,
  },

  {
    path: 'change-password',
    component: PasswordChangeComponent,
  },

  {
    path: 'reset-user-name',
    component: ResetUserNameComponent,
  },
  {
    path: 'error-modal',
    component: ErrorModalAuthenticationComponent,
  },
  // ,
  // {
  //   path: 'admin-login',
  //   component: AdminLoginComponent
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
