<div class="container rel" @ngIfAnimation (mousemove)="$event.stopPropagation()">
  <mat-tab-group class="mobNavi" [(selectedIndex)]="selectedTab" (selectedIndexChange)="tabClick($event)">
    <!--  introduction tab  -->
    <mat-tab [label]="'KEYWORD.Treatment' | translate">
      <ng-template mat-tab-label>
        <div class="button-content">
          <mat-icon class="fas fa-id-card"></mat-icon>
          <p class="icon-text">{{ "KEYWORD.patient" | translate | titlecase }} card</p>
        </div>
      </ng-template>
      <div class="showOverflow" *ngIf="selectedTab == 0" @ngIfTab>
        <mat-card class="intro-card animate">
          <div class="intro-text animate main row clearfix">
            <div class="textBlock">
              <h1 class="patientcard-title rm-mb">
                <span>Hello</span> <span class="patientName">{{ customInv.FirstName }}</span>
              </h1>
              <p class="sub add-mb rm-mt lead">Your {{ 'PHRASE.treatment plan' | translate }} is ready to view!</p>
            </div>
          </div>

          <div class="card-header-image-area" mat-card-image>
            <!-- <div appParallax [ratio]="-0.2" id="dentImg">
            </div>
            <div appParallax [ratio]="0.2" id="dentBg">
            </div> -->
          </div>
        </mat-card>

        <mat-card class="dentist-message-card animate">
          <div class="row clearfix dentInfo" *ngIf="customInv.MessageFromMerchant">
            <h2 class="summary-header sr-title rm-mt text-center">
              A message from your {{ "KEYWORD.practitioner" | translate | titlecase }}...
            </h2>
            <hr/>

            <div class="dentistMessage text-center rm-m">
              <!-- <p class="rm-m" [innerHTML]="customInv.MessageFromMerchant"></p> -->
              <read-more [text]="customInv.MessageFromMerchant" [maxLength]="180"></read-more>
            </div>
          </div>
        </mat-card>

        <!--{{ "KEYWORD.treatment" | translate }} cta -->
        <mat-card class="treatment primary-gradient-img rel animate first botton mat-elevation-z7"
                  (click)="changeTab()">
          <div class="row clearfix flex valueLabels">
            <div class="col-left full-width clearfix">
              <h2 class="summary-header sr-title rm-m mt">Your treatment:</h2>
              <p class="rm-mt">
                <span *ngIf="treatmentCategorySet == false">See attached {{ "PHRASE.treatment plan" | translate }}
                  document</span>
                <span *ngIf="treatmentCategorySet == true">Category - Type - Brand</span>
              </p>
            </div>
            <div class="col-right full-width clearfix valueLabels text-right">
              <button class="full-width full-height btn-outline pop" mat-button>
                <span class="mobHide"> Get started! <mat-icon>chevron_right</mat-icon> </span>
                <span class="mobShow"> Go! <mat-icon class="fas fa-hand-point-right"></mat-icon> </span>
              </button>
            </div>
          </div>
          <mat-icon class="background fas {{'fa-tooth' | translate}}"></mat-icon>
        </mat-card>
        <!-- instruction card -->
        <mat-card class="instruction-card animate">
          <div class="row clearfix">
            <div class="row clearfix">
              <h2 class="summary-header sr-title rm-mt text-center">How does this work?</h2>
              <hr/>
            </div>
            <div class="row clearfix">
              <div class="half-width">
                <div class="col-left">
                  <p class="rm-mt">
                    We have prepared a {{ "PHRASE.treatment plan" | translate }} to get your teeth looking and feeling
                    better, along with some
                    payment options to help smooth things out. We like to give you all your options and let you decide
                    what is right for you and your budget and needs.
                  </p>
                  <p>Take you time to study each option on offer before making your selection.</p>
                  <p class="amount-time small rm-mb">
                    <em>This proposal is valid until <span>{{ customInv['Date.Expiry'] | customDate }}</span>.</em>
                  </p>
                </div>
              </div>
              <div class="half-width">
                <div class="col-right counter">
                  <div class="row clearfix">
                    <div class="step-counter-icon complete">
                      <span class="number">1</span>
                      <p>Visit {{ "KEYWORD.patient" | translate }} card</p>
                      <div class="circle">
                        <mat-icon>
                          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
                            <path
                              d="M0 12.116l2.053-1.897c2.401 1.162 3.924 2.045 6.622 3.969 5.073-5.757 8.426-8.678 14.657-12.555l.668 1.536c-5.139 4.484-8.902 9.479-14.321 19.198-3.343-3.936-5.574-6.446-9.679-10.251z"/>
                          </svg>
                        </mat-icon>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="step-counter-icon" [class.complete]="stepTwoVisited == true">
                      <span class="number">2</span>
                      <p>View {{ "PHRASE.treatment plan" | translate }}</p>
                      <div class="circle">
                        <mat-icon *ngIf="stepTwoVisited == true">
                          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
                            <path
                              d="M0 12.116l2.053-1.897c2.401 1.162 3.924 2.045 6.622 3.969 5.073-5.757 8.426-8.678 14.657-12.555l.668 1.536c-5.139 4.484-8.902 9.479-14.321 19.198-3.343-3.936-5.574-6.446-9.679-10.251z"/>
                          </svg>
                        </mat-icon>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="step-counter-icon" [class.complete]="stepThreeVisited == true">
                      <span class="number">3</span>
                      <p>See payment options</p>
                      <div class="circle">
                        <mat-icon *ngIf="stepThreeVisited == true">
                          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
                            <path
                              d="M0 12.116l2.053-1.897c2.401 1.162 3.924 2.045 6.622 3.969 5.073-5.757 8.426-8.678 14.657-12.555l.668 1.536c-5.139 4.484-8.902 9.479-14.321 19.198-3.343-3.936-5.574-6.446-9.679-10.251z"/>
                          </svg>
                        </mat-icon>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="step-counter-icon" [class.complete]="stepFourVisited == true">
                      <span class="number">4</span>
                      <p>Confirm with {{ "KEYWORD.dentist" | translate }}</p>
                      <div class="circle">
                        <mat-icon *ngIf="stepFourVisited == true">
                          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
                            <path
                              d="M0 12.116l2.053-1.897c2.401 1.162 3.924 2.045 6.622 3.969 5.073-5.757 8.426-8.678 14.657-12.555l.668 1.536c-5.139 4.484-8.902 9.479-14.321 19.198-3.343-3.936-5.574-6.446-9.679-10.251z"/>
                          </svg>
                        </mat-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card>

        <!-- dentist view -->
        <mat-card class="dentInfo animate">
          <app-dentist-view-consumer class="dentist-info" *ngIf="customInv['MerchantContact_Key']"
                                     [dentistID]="customInv['MerchantContact_Key']">
          </app-dentist-view-consumer>
        </mat-card>

        <!--{{ "KEYWORD.treatment" | translate }} cta -->
        <mat-card class="treatment primary-gradient-img rel animate first botton mat-elevation-z7 add-mb lower mobShow"
                  (click)="changeTab()">
          <div class="row clearfix text-center mt mb">
            Get started <mat-icon class="fas fa-hand-point-right"></mat-icon>
          </div>
          <mat-icon class="background fas {{'fa-tooth' | translate}}"></mat-icon>
        </mat-card>
      </div>
    </mat-tab>

    <!-- {{ "KEYWORD.treatment" | translate }} details tab  -->
    <mat-tab (onEnter)="stepTwoVisited = true" label="Tab Two">
      <ng-template mat-tab-label>
        <div class="button-content">
          <div class="documentIndicator" *ngIf="documents && documents.length > 0">{{ documents.length }}</div>
          <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
          <p class="icon-text">{{ "KEYWORD.treatment" | translate | titlecase }}</p>
        </div>
      </ng-template>

      <div class="showOverflow" *ngIf="selectedTab == 1" @ngIfTab>
        <div class="intro animate">
          <h2 class="patientcard-title add-mb">Your {{ "PHRASE.treatment plan" | translate }}</h2>
        </div>

        <div class="row clearfix flex">
          <mat-card class="treatment primary-gradient-img rel animate full-width botton animate" (click)="changeTab()">
            <div class="row clearfix flex valueLabels">
              <div class="full-width clearfix valueLabels">
                <button class="full-width full-height btn-outline pop" mat-button>
                  Review your accepted {{ "KEYWORD.treatment" | translate }}
                  <mat-icon>chevron_right</mat-icon>
                </button>
              </div>
            </div>
            <mat-icon class="background fas {{'fa-tooth' | translate}}"></mat-icon>
          </mat-card>
        </div>

        <div class="row clearfix desc flex">
          <mat-card class="full-width payment-cta rel botton animate" *ngIf="minAmount" (click)="changeTab()">
            <div class="row clearfix">
              <h3 class="rm-m">Smooth out your {{ "KEYWORD.treatment" | translate }} cost</h3>
              <hr/>
              <button class="payment-options-cta" mat-button>
                Payment options from
                <span class="valuePerDay">{{ minAmount | customCurrency }}/day! <mat-icon
                  class="fas fa-hand-point-right"></mat-icon></span>
              </button>
            </div>
            <mat-icon class="background fas fa-dollar-sign"></mat-icon>
          </mat-card>
        </div>
        <!-- health record cta -->
        <mat-card class="health-record animate" *ngIf="isModulePatientHistoryActive == true">
          <div class="card-header-image-area" mat-card-image>
            <!-- <div appParallax [ratio]="-0.2" id="dentHistoryHandImg">
            </div>
            <div appParallax [ratio]="-0.1" id="dentHistoryImg">
            </div>
            <div appParallax [ratio]="0.05" id="dentHistoryBg">
            </div> -->
          </div>
          <div class="white-wrap position">
            <h3 class="sr-title rm-mb">
              {{ customInv.FirstName }}, is your {{ 'KEYWORD.dental' | translate }} history current?
            </h3>
            <p class="mb">
              Streamline your appointments by providing {{ customInv.MerchantName }} with an updated record of your
              {{ 'KEYWORD.dental' | translate }} {{"PARAGRAPH.and health history" | translate}}.
            </p>
          </div>
          <div class="row clearfix details flex position" *ngIf="customInv.Mobile">
            <mat-icon class="ico">phone</mat-icon>
            <div class="row-content">
              <p class="small">Your phone number</p>
              <p class="rm-mt larger">{{ customInv.Mobile }}</p>
            </div>
          </div>
          <div class="row clearfix details flex" *ngIf="customInv.Email">
            <mat-icon class="ico">email</mat-icon>
            <div class="row-content">
              <p class="small">Your email address</p>
              <p class="rm-mt larger">{{ customInv.Email }}</p>
            </div>
          </div>
          <div class="white-wrap">
            <div class="row clearfix">
              <div class="lastModified pull-left">
                <p class="small">Last updated:</p>
                <p class="small notYet">Not yet updated</p>
              </div>
              <button class="pull-right" [routerLink]="['/consumer-app/', { outlets: { page: ['patient-history'] } }]"
                      mat-raised-button color="primary">
                Update now
                <mat-icon>chevron_right</mat-icon>
              </button>
            </div>
          </div>
        </mat-card>

        <div class="row clearfix backBtn animate mb">
          <button class="pull-left btn-large" (click)="backTab()" mat-raised-button color="accent">
            <mat-icon>chevron_left</mat-icon> Back
          </button>
        </div>
      </div>
    </mat-tab>

    <mat-tab (onEnter)="stepTwoVisited = true; displayInclusions()" label="Tab Two">
      <ng-template mat-tab-label>
        <div class="button-content">
          <mat-icon class="fas fa-user-check"></mat-icon>
          <p class="icon-text">Review</p>
        </div>
      </ng-template>

      <div class="showOverflow" *ngIf="selectedTab == 2" @ngIfTab>
        <div class="intro animate">
          <h2 class="patientcard-title add-mb">Review your treatment</h2>
        </div>

        <div class="row clearfix flex">
          <mat-card class="instruction-card animate full-width">
            <div class="card-header primary-gradient-img clearfix inModal">
              <div class="row clearfix">
                <!-- merchant calculated name -->
                <div class="row titleArea clearfix">
                  <div class="pull-left">
                    <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
                  </div>
                  <h2 class="summary-header rm-m">Your treatment plan</h2>
                </div>
              </div>
            </div>

            <div class="list-heading flex">
              <p class="small">Include</p>
              <p class="small full-width">Item</p>
              <p class="small">Price</p>
            </div>
            <mat-list class="item-review">
              <mat-list-item *ngFor="let t of demoTreatments; let i = index">
                <mat-checkbox [checked]="t.checked" matListIcon></mat-checkbox>
                <p class="rm-m sr-title" mat-line>
                  <strong>
                    {{ t.treatmentType }}
                  </strong>
                </p>
                <p class="small" mat-line>
                  {{ t.selectedTeeth.length }}
                  <span *ngIf="t.selectedTeeth.length > 1">teeth</span>
                  <span *ngIf="t.selectedTeeth.length == 1">tooth</span>
                  , treatment {{ i + 1 }} of {{ demoTreatments?.length }}
                </p>

                <p class="cost">{{ t.treatmentValue | customCurrency }}</p>
              </mat-list-item>
            </mat-list>
          </mat-card>
        </div>

        <div class="row clearfix flex">
          <mat-card class="full-width payment-cta totalValues animate" *ngIf="treatment">
            <div class="row clearfix mt mb">
              <div class="full-width text-left clearfix">
                <div class="text-left pull-left clearfix">
                  <p class="small rm-m"> {{"KEYWORD.Treatment" | translate}} items selected</p>
                  <h2 class="rm-m">
                    {{ demoTreatments?.length }}
                  </h2>
                </div>

                <div class="text-right pull-right clearfix">
                  <p class="small rm-m">Total {{ "KEYWORD.treatment" | translate }} value:</p>
                  <h2 class="rm-m">
                    {{ treatment.totalTreatmentValue | customCurrency }}
                    <!-- <strong *ngIf="treatment.treatmentCategory && treatment.treatmentCategory.label"> - {{ "Category" | translate }}: {{treatment.treatmentCategory?.label}}</strong>
                    <strong *ngIf="treatment.treatmentBrand && treatment.treatmentBrand.label" > - {{ "Brand" | translate }}: {{treatment.treatmentBrand?.label}}</strong> -->
                  </h2>
                </div>
              </div>
              <div class="full-width text-center clearfix validUntil">
                <p class="small rm-m">This proposal is valid until</p>
                <h3 class="rm-m">
                  {{ customInv['Date.Expiry'] | customDate }}
                </h3>
              </div>
            </div>
          </mat-card>
          <!-- appointment cta -->
          <mat-card class="full-width treatment appointment primary-gradient rel animate"
                    *ngIf="isModuleAppointmentOldActive == true">
            <div class="row clearfix valueLabels text-center">
              <div class="copy">
                <h2 class="summary-header rm-m">Your next appointment:</h2>
                <p class="lead rm-mt">Tuesday, <strong>18th February</strong> at <strong>9.30am</strong></p>
              </div>
              <hr/>
              <button mat-button>
                <mat-icon>event_available</mat-icon>
                Add to calendar
              </button>
            </div>
            <mat-icon class="background fas fa-calendar-check"></mat-icon>
          </mat-card>
        </div>
        <!-- whats next -->
        <div class="row clearfix flex">
          <mat-card class="treatment secondary-gradient rel animate full-width botton animate" (click)="changeTab()">
            <div class="row clearfix flex valueLabels">
              <div class="col-left full-width clearfix">
                <h2 class="summary-header sr-title rm-m">What's next?</h2>
              </div>
              <div class="col-right full-width clearfix valueLabels text-right">
                <button class="full-width full-height btn-outline pop" mat-button>
                  See your options! <mat-icon>chevron_right</mat-icon>
                </button>
              </div>
            </div>
            <mat-icon class="background fas {{'fa-tooth' | translate}}"></mat-icon>
          </mat-card>
        </div>

        <div class="row clearfix backBtn animate mb">
          <button class="pull-left btn-large" (click)="backTab()" mat-raised-button color="accent">
            <mat-icon>chevron_left</mat-icon> Back
          </button>
        </div>
      </div>
    </mat-tab>

    <!--  proceed tab  -->
    <mat-tab label="Tab Three ">
      <ng-template mat-tab-label>
        <div class="button-content">
          <mat-icon class="fas fa-hand-point-right"></mat-icon>
          <p class="icon-text">What's next?</p>
        </div>
      </ng-template>

      <div class="showOverflow" *ngIf="selectedTab == 3" @ngIfTab>
        <div class="intro animate">
          <h2 class="patientcard-title add-mb">How would you like to proceed?</h2>
        </div>
        <!-- payment product list -->
        <app-suitable-product-compact-list class="product-selection" *ngIf="customInv['treatment.Value']"
                                           [frequency]="'1'" [groupCode]="customInv['Product.ThisGroupOnly.Code']"
                                           [merchantKey]="customInv['Merchant_Key']"
                                           [amount]="customInv['treatment.Value']"
                                           (select)="selectProduct($event)"></app-suitable-product-compact-list>

        <!-- pay cash if no discount set -->

        <div class="prodItem cashDiscount animate" *ngIf="
            isModuleDiscountsActive == true && customInv['CashDiscount.Offered.YesNo'] == '1' && displayDiscount == true
          ">
          <mat-card class="full-width prodCard rel mat-elevation-z7" (click)="confirmNotFincanceWithDiscount()">
            <div class="ribbon">
              <span>SAVINGS</span>
            </div>
            <h2 class="lending-prod-compact-h2">Pay on the day and save!</h2>
            <div class="value text-center">
              <h3 class="white from">With full payment upfront, you'll save</h3>
              <p class="mainValue green-text rm-m">{{ customInv['CashDiscount.Offered.Amount'] | customCurrency }}</p>
              <h3 class="white small per-day">off your treatment!</h3>

              <h4 class="small white">
                <span
                  *ngIf="customInv['CashDiscount.Expiry.Date'] && customInv['CashDiscount.Expiry.Date'] != '0000-00-00'">
                  This offer is only valid for

                  <span *ngIf="cashDiscountDateCounter && cashDiscountDateCounter.d > 0">
                    {{ cashDiscountDateCounter.d | number: '2.0-0' }} day(s)</span>

                  <span *ngIf="cashDiscountDateCounter">{{ cashDiscountDateCounter.h | number: '2.0-0' }} Hour(s)
                    {{ cashDiscountDateCounter.m | number: '2.0-0' }} Min(s)
                    <!-- {{cashDiscountDateCounter.s| number: '2.0-0'}}
                Left-->
                  </span>
                </span>
              </h4>
            </div>
            <div class="deepBg"></div>
            <div class="buttons-content">
              <!-- <button mat-raised-button class="btn-outline full-width" tabindex="0">
              {{ "Details" | translate }}
              <mat-icon>search</mat-icon>
            </button> -->
              <button class="btn-outline full-width" mat-raised-button color="" aria-hidden="true" tabindex="-1">
                Select &amp; Proceed
                <mat-icon class="fas fa-hand-point-right"></mat-icon>
              </button>
            </div>
          </mat-card>
        </div>

        <mat-card class="product-selection discount primary-gradient-img botton animate" *ngIf="
            isModuleDiscountsActive == false ||
            customInv['CashDiscount.Offered.YesNo'] != '1' ||
            displayDiscount == false
          " (click)="confirmNotFincance()">
          <div class="text-center noDisc">
            <h3 class="lending-prod-compact-h2 rm-mt">
              Prefer to pay with
              <br class="mobshow"/>
              cash or card?
            </h3>
            <p>
              If you wish to proceed with this {{ "KEYWORD.treatment" | translate }} and settle with cash or card at the
              {{ "KEYWORD.practice" | translate }} on the day - click below.
            </p>
            <button class="btn-outline payProceed" mat-raised-button color="primary">
              Proceed and pay on the day
              <mat-icon aria-hidden="true">chevron_right</mat-icon>
            </button>
          </div>
        </mat-card>
        <!-- changes cta -->
        <div class="minorPad">
          <mat-card class="changes-card rel botton animate" (click)="changeTab()">
            <div class="row clearfix">
              <h2 class="accent rm-m">Not what you expected?</h2>
              <p class="mb">
                If there's something wrong with the proposed {{ "KEYWORD.treatment" | translate }} or you're not ready
                to proceed, please let your
                {{"KEYWORD.dentist" | translate}} know...
              </p>
              <button class="btn-outline" mat-raised-button>See my options <mat-icon>chevron_right</mat-icon></button>
            </div>
            <mat-icon class="background fas fa-grimace"></mat-icon>
          </mat-card>
          <div class="row clearfix backBtn">
            <button class="pull-left btn-large" (click)="backTab()" mat-raised-button color="accent">
              <mat-icon>chevron_left</mat-icon> Back
            </button>
          </div>
        </div>
      </div>
    </mat-tab>

    <!--  more options tab  -->
    <mat-tab label="More Options">
      <ng-template mat-tab-label>
        <div class="button-content">
          <mat-icon class="fas fa-ellipsis-v"></mat-icon>
          <p class="icon-text">More</p>
        </div>
      </ng-template>

      <div class="showOverflow" *ngIf="selectedTab == 4" @ngIfTab>
        <div class="intro animate">
          <h2 class="patientcard-title">Need to change something?</h2>
        </div>
        <!-- request changes -->
        <mat-card class="alt-option botton animate changes" (click)="openPatientCardChangesModal()">
          <h2 class="rm-mb">Request Changes</h2>
          <p class="mb">Request modifications to your {{ 'PHRASE.treatment plan' | translate }} before proceeding.</p>

          <button class="cool" mat-button>
            <mat-icon aria-hidden="true">edit</mat-icon>
            Request Changes
          </button>
          <mat-icon class="background fas fa-pencil-alt"></mat-icon>
        </mat-card>
        <!-- request deferral -->
        <mat-card class="alt-option botton animate hold" (click)="openPatientCardDeferModal()">
          <h2 class="rm-mb">Hold off for now</h2>
          <p class="mb">
            If you need to defer your {{ "KEYWORD.treatment" | translate }}, we can rain check to a later date.
          </p>

          <button class="warm" mat-button>
            <mat-icon aria-hidden="true">access_time</mat-icon>
            Hold Off
          </button>
          <mat-icon class="background fas fa-stopwatch"></mat-icon>
        </mat-card>
        <!-- request dont proceed -->
        <mat-card class="alt-option botton animate cancel botton" (click)="openPatientCardStopModal()">
          <h2 class="rm-mb">Don't proceed</h2>
          <p class="mb">
            If you don't want to go ahead with your {{ "KEYWORD.treatment" | translate }}, please let us know.
          </p>

          <button class="hot" mat-button>
            <mat-icon aria-hidden="true">report</mat-icon>
            Don't proceed
          </button>
          <mat-icon class="background fas fa-hand-paper"></mat-icon>
        </mat-card>
        <div class="row clearfix backBtn">
          <button class="pull-left btn-large" (click)="backTab()" mat-raised-button color="accent">
            <mat-icon>chevron_left</mat-icon> Back
          </button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
