<div class="row clearfix">
  <div class="row clearfix">
    <h3 class="sr-title sm-h3 pull-left rm-m">View event</h3>
    <div class="pull-right" *ngIf="isModal == true">
      <button class="btn-close btn-clear pull-right mat-button" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>
  <!-- Invitation Eevet Log -->
  <mat-divider class="mb"></mat-divider>

  <mat-dialog-content>
    <div class="row clearfix" *ngIf="type == 'invitation' && invitationEventLog">
      <div class="row clearfix">
        <h2 class="sr-title pull-left rm-m prodTitle">
          {{ invitationEventLog['Event.Label'] }}
        </h2>
      </div>
      <div class="row clearfix" *ngIf="invitationEventLog['PreviousEvent.Label']">
        <label class="small">Previous Event</label>
        <p class="rm-mt small">{{ invitationEventLog['PreviousEvent.Label'] }}</p>
      </div>
      <div class="row clearfix mt">
        <div class="half-width">
          <label class="small">{{"KEYWORD.patient" | translate | titlecase}} Name</label>
          <p class="rm-mt larger">
            {{ invitationEventLog['Invitation.FirstName'] }} {{ invitationEventLog['Invitation.LastName'] }}
          </p>

          <label class="small">Merchant Name</label>
          <p class="rm-mt larger">{{ invitationEventLog['Merchant.Name'] }}</p>

          <label class="small">Invitation Status</label>
          <p class="rm-mt larger">{{ invitationEventLog['Invitation.CustomerChoice.Label'] || '...' }}</p>
        </div>

        <div class="half-width">
          <label class="small">Event Occurred Time</label>
          <p class="rm-mt larger">{{ invitationEventLog['DateTime_ISO'] | customDateTime }}</p>

          <label class="small">This Event taken time</label>
          <p class="rm-mt larger">{{ invitationEventLog['ElapsedSince.PreviousEvent.Total'] }} seconds</p>

          <label class="small">Total Events taken Time</label>
          <p class="rm-mt larger">{{ invitationEventLog['ElapsedSince.Milestone1.Total'] }} seconds</p>
        </div>
      </div>
    </div>

    <!-- Contract Event Log -->

    <div class="row clearfix" *ngIf="type == 'contract' && contractEventLog">
      <div class="row clearfix">
        <h2 class="sr-title pull-left rm-m prodTitle">
          {{ contractEventLog['Event.Label'] }}
        </h2>
      </div>
      <div class="row clearfix" *ngIf="contractEventLog['PreviousEvent.Label']">
        <label class="small">Previous Event</label>
        <p class="rm-mt small">{{ contractEventLog['PreviousEvent.Label'] }}</p>
      </div>
      <div class="row clearfix mt">
        <div class="half-width">
          <label class="small">{{"KEYWORD.patient" | translate | titlecase}} Name</label>
          <p class="rm-mt larger">{{ contractEventLog['Contract.Customer.Name'] }}</p>

          <label class="small">Merchant Name</label>
          <p class="rm-mt larger">{{ contractEventLog['Merchant.Name'] }}</p>

          <label class="small">Contract Status</label>
          <p class="rm-mt larger">{{ contractEventLog['Contract.Status.Label'] }}</p>
        </div>

        <div class="half-width">
          <label class="small">Event Occurred Time</label>
          <p class="rm-mt larger">{{ contractEventLog['DateTime_ISO'] | customDateTime }}</p>

          <label class="small">This Event taken time</label>
          <p class="rm-mt larger">{{ contractEventLog['ElapsedSince.PreviousEvent.Total'] }} seconds</p>

          <label class="small">Total Events taken Time</label>
          <p class="rm-mt larger">{{ contractEventLog['ElapsedSince.Milestone1.Total'] }} seconds</p>
        </div>
      </div>
    </div>

    <!-- Message Event Log -->

    <div class="row clearfix" *ngIf="type == 'message' && messageEventLog">
      <div class="row clearfix">
        <h2 class="sr-title pull-left rm-m prodTitle">
          {{ messageEventLog['Event.Label'] }}
        </h2>
      </div>
      <div class="row clearfix" *ngIf="messageEventLog['PreviousEvent.Label']">
        <label class="small">Previous Event</label>
        <p class="rm-mt small">{{ messageEventLog['PreviousEvent.Label'] }}</p>
      </div>
      <div class="row clearfix mt">
        <div class="half-width">
          <label class="small">Merchant Name</label>
          <p class="rm-mt larger">{{ messageEventLog['Merchant.Name'] }}</p>

          <label class="small">Message Description</label>
          <p class="rm-mt larger">{{ messageEventLog['Message.Description'] }}</p>

          <label class="small">Message to</label>
          <p class="rm-mt larger">{{ messageEventLog['Message.EmailOrMobile'] }}</p>
        </div>

        <div class="half-width">
          <label class="small">Event Occurred Time</label>
          <p class="rm-mt larger">{{ messageEventLog['DateTime_ISO'] | customDateTime }}</p>

          <label class="small">This Event taken time</label>
          <p class="rm-mt larger">{{ messageEventLog['ElapsedSince.PreviousEvent.Total'] }} seconds</p>

          <label class="small">Total Events taken Time</label>
          <p class="rm-mt larger">{{ messageEventLog['ElapsedSince.Milestone1.Total'] }} seconds</p>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
