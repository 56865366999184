import { animate, keyframes, query, stagger, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { OperatorService } from '../shared/operator.service';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-operator-login-access',
  templateUrl: './operator-login-access.component.html',
  styleUrls: ['./operator-login-access.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.stacked-card-view', style({ opacity: 0 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.stacked-card-view', style({ opacity: 1 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class OperatorLoginAccessComponent implements OnInit {
  @Input() operatorID;
  @Output() close = new EventEmitter();
  operator;
  isPromoterOrAdmin;
  @Output() getResult = new EventEmitter();
  publicSiteActive = true;
  mainAppActive = true;

  sessionType;
  settings = Settings.global;
  constructor(
    private operatorService: OperatorService,
    private AuthenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && data.operatorID) {
      this.operatorID = data.operatorID;
    }

    this.AuthenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });
  }

  ngOnInit() {
    this.AuthenticationService.getSessionType().subscribe((r) => {
      this.sessionType = r;

      this.operatorService.getOperatorDetails(this.operatorID, {}, this.sessionType).subscribe((res) => {
        this.operator = res;

        if (this.operator['Revoke_Access_Public_Site'] === '1') {
          this.publicSiteActive = false;
        }

        if (this.operator['Revoke_Access_Main_App'] === '1') {
          this.mainAppActive = false;
        }
      });
    });
  }

  save() {
    if (this.operatorID) {
      let p = {
        publicSiteActive: this.publicSiteActive,
        mainAppActive: this.mainAppActive,
      };

      this.operatorService.modifyPortalAccess(this.operatorID, p, this.sessionType).subscribe((t) => {
        if (t && t.ID) {
          this.getResult.emit(t);
        }
      });
    } else {
      this.getResult.emit({
        publicSiteActive: this.publicSiteActive,
        mainAppActive: this.mainAppActive,
      });
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
}
