<div class="full-width clearfix">
  <div class="main-banner blue-banner" *ngIf="isEmbeded != true && featureType != 'consumer'"></div>
  <div class="row clearfix container mt ml mr">
    <div class="clearfix container-inner-parent" *ngIf="isEmbeded != true">
      <div class="clearfix container-inner mt">
        <div class="container guid-main">
          <div class="guid guid-right flex clearfix full-width">
            <div class="full-width">
              <div class="half-width txt-box">
                <div class="txt">
                  <div class="row clearfix breadcrumbs">
                    <ul>
                      <li class="link" [routerLink]="[root, { outlets: { page: ['main-feature-list', featureType] } }]">
                        Features <mat-icon class="fas fa-chevron-right"></mat-icon>
                      </li>

                      <li>{{ mainLabel }}</li>
                    </ul>
                  </div>

                  <h1 class="text-left header-title-sub-list">{{ mainLabel }}</h1>
                  <p class="text-left header-description-sub-list">
                    {{ mainDescription }}
                  </p>

                  <div class="full-width smt" style="margin-top: -35px !important; padding-top: 30px">
                    <span class="chip-module smr pull-left chip-disabled mat-elevation-z1" *ngIf="mainActive != true"
                    >Not Active</span
                    >

                    <span
                      class="chip-module chip-enable chip-consumer smr pull-left mat-elevation-z1"
                      *ngIf="readOnly != true && isPromoterOrAdmin == true && isMainConsumer == true"
                      matTooltip="Consumer Feature"
                    >Consumer</span
                    >

                    <span
                      class="chip-module chip-enable smr pull-left mat-elevation-z1"
                      *ngIf="readOnly != true && isPromoterOrAdmin == true && isMainMerchant == true"
                      matTooltip="Merchant Feature"
                    >Merchant</span
                    >

                    <span
                      class="chip-module chip-beta smr pull-left mat-elevation-z1"
                      *ngIf="readOnly != true && isPromoterOrAdmin == true && isMainSideMenu == true"
                      matTooltip="Added to side menu"
                    >Side Menu</span
                    >

                    <span
                      class="chip-module chip-disabled smr pull-left mat-elevation-z1"
                      *ngIf="readOnly != true && isPromoterOrAdmin == true && isMainPrivate == true"
                    >Not Public</span
                    >
                  </div>
                </div>
              </div>
              <div class="half-width img-box-left">
                <div class="guid-imgbox full-width guid-imgbox-left">
                  <app-ep-document-view-image  *ngIf="context!='p4p'"
                    class="icon-on-papper"
                    [hideZoomButton]="false"
                    [defaultPicture]="
                      'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/bell_icon.png)'
                    "
                    [link]="postPictureThumbnail"
                  >
                  </app-ep-document-view-image>

                  <app-ep-document-view-image  *ngIf="context=='p4p'"
                    class="icon-on-papper"
                    [hideZoomButton]="false"
                    [defaultPicture]="
                      'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/bell_icon.png)'
                    "
                    style="

                    bottom: 33%;
                    right: 4%;"
                    [link]="postPictureThumbnail"
                  >
                  </app-ep-document-view-image>






                  <img *ngIf="context!='p4p'"
                    class="full-width"
                    src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/public/img/dentist-faq-new.png"
                  />

                  <img *ngIf="context=='p4p'"
                    class="full-width"
                    src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dentist_hero_pair.png"
                  />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row clearfix container">
      <mat-card
        class="rel clearfix card-container"
        *ngIf="mainFeatureLabelID || (mainFeatureLabel && mainFeatureLabel.ID)"
        [class.moretopmargin]="standAlone == true && isEmbeded == true"
        [class.nomoretopmargin]="isEmbeded != true"
        [class.collapseHeaderCard]="canHideDisplay == true && hideDisplayCheck == false"
        [class.contextcardModal]="isModalView == true"
        [attr.style]="'box-shadow:' + buildShadowBox(color) | safeStyle"
      >
        <div
          class="card-header primary-gradient-img clearfix inModal rel"
          *ngIf="displayHeader == true"
          [class.pointerCard]="canHideDisplay == true"
          [class.collapseHeader]="canHideDisplay == true && hideDisplayCheck == false"
          [attr.style]="
            'background:linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
          "
          [attr.style]="
            'background:-moz-linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
          "
          [attr.style]="
            'background:-webkit-linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important'
              | safeStyle
          "
          (click)="toggleCollapse()"
        >
          <button
            class="btn-close btn-clear mat-button close-module"
            *ngIf="isModalView == true"
            (click)="closeEvent()"
          >
            <mat-icon>clear</mat-icon>
          </button>

          <button
            class="btn all-about-feature-top"
            *ngIf="allView == true && hideDisplayCheck == false && mainFeatureLabel && mainFeatureLabel.ID"
            (click)="$event.stopPropagation(); goMain(mainFeatureLabel.ID)"
            mat-raised-button
            color="accent"
          >
            <span
            >View all about <strong>{{ mainLabel }}</strong></span
            >
            <mat-icon class="fas fa-chevron-right sml"></mat-icon>
          </button>

          <button
            class="btn hideDisplayButtonClass"
            *ngIf="canHideDisplay == true && hideDisplayCheck == false"
            (click)="$event.stopPropagation(); canHideDisplayEvent(true)"
            mat-raised-button
            color="accent"
          >
            <span class="smr">Find out more</span>
            <mat-icon class="fas s fa-angle-double-down"></mat-icon>
          </button>

          <button
            class="pull-right action-button-embded action-button"
            *ngIf="
              readOnly != true && mainAction == true && mainFeatureLabel && mainFeatureLabel.ID && isEmbeded != true
            "
            [matMenuTriggerFor]="ActionBtnmenu"
            (click)="$event.stopPropagation()"
            mat-icon-button
            color="primary"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #ActionBtnmenu="matMenu">
            <button (click)="$event.stopPropagation(); viewMain(mainFeatureLabel.ID)" mat-menu-item color="">
              <mat-icon>visibility</mat-icon> View
            </button>
            <hr/>
            <button (click)="$event.stopPropagation(); quickEditMain(mainFeatureLabel['ID'])" mat-menu-item color="">
              <mat-icon>save</mat-icon> Quick Edit
            </button>
            <button (click)="$event.stopPropagation(); editMain(mainFeatureLabel.ID)" mat-menu-item color="">
              <mat-icon>edit</mat-icon> Edit
            </button>
            <hr/>
            <button (click)="$event.stopPropagation(); create()" mat-menu-item color="">
              <mat-icon>add</mat-icon> Add sub feature
            </button>
            <button
              *ngIf="mainFeatureLabel && mainFeatureLabel.FE_Code && mainFeatureLabel.Is_Deactivated !== '1'"
              [matMenuTriggerFor]="links"
              mat-menu-item
            >
              <mat-icon>share</mat-icon> Copy Links
            </button>
            <mat-menu #links="matMenu">
              <button
                *ngIf="
                  publicSiteLink &&
                  mainFeatureLabel &&
                  mainFeatureLabel.Is_Private !== '1' &&
                  mainFeatureLabel.Is_Consumer_Feature === '1'
                "
                (click)="
                  $event.stopPropagation();
                  copy(
                    publicSiteLink + '/consumer/(page:main-feature-view-code/' + mainFeatureLabel.FE_Code + '/consumer)'
                  )
                "
                mat-menu-item
              >
                Public Site: <strong>Consumer</strong>
              </button>

              <button
                *ngIf="
                  publicSiteLink &&
                  mainFeatureLabel &&
                  mainFeatureLabel.Is_Private !== '1' &&
                  mainFeatureLabel.Is_Merchant_Feature === '1'
                "
                (click)="
                  $event.stopPropagation();
                  copy(
                    publicSiteLink + '/partners/(page:main-feature-view-code/' + mainFeatureLabel.FE_Code + '/merchant)'
                  )
                "
                mat-menu-item
              >
                Public Site: <strong>Partner</strong>
              </button>

              <hr/>
              <button
                *ngIf="mainAppLink && mainFeatureLabel && mainFeatureLabel.Is_Consumer_Feature === '1'"
                (click)="
                  $event.stopPropagation();
                  copy(
                    mainAppLink + '/consumer/(page:main-feature-view-code/' + mainFeatureLabel.FE_Code + '/consumer)'
                  )
                "
                mat-menu-item
              >
                Main Application: <strong>Consumer</strong>
              </button>

              <button
                *ngIf="mainAppLink && mainFeatureLabel && mainFeatureLabel.Is_Merchant_Feature === '1'"
                (click)="
                  $event.stopPropagation();
                  copy(
                    mainAppLink + '/merchant/(page:main-feature-view-code/' + mainFeatureLabel.FE_Code + '/merchant)'
                  )
                "
                mat-menu-item
              >
                Main Application: <strong>Merchant</strong>
              </button>
            </mat-menu>
            <hr/>
            <button (click)="$event.stopPropagation(); deleteMain(mainFeatureLabel.ID)" mat-menu-item color="">
              <mat-icon>delete</mat-icon> Remove
            </button>
          </mat-menu>

          <div class="row clearfix content" *ngIf="isEmbeded == true">
            <button
              class="pull-right action-button"
              *ngIf="readOnly != true && mainAction == true && mainFeatureLabel && mainFeatureLabel.ID"
              [matMenuTriggerFor]="ActionBtnmenu"
              (click)="$event.stopPropagation()"
              mat-icon-button
              color="primary"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #ActionBtnmenu="matMenu">
              <button (click)="$event.stopPropagation(); viewMain(mainFeatureLabel.ID)" mat-menu-item color="">
                <mat-icon>visibility</mat-icon> View
              </button>
              <hr/>
              <button (click)="$event.stopPropagation(); quickEditMain(mainFeatureLabel['ID'])" mat-menu-item color="">
                <mat-icon>save</mat-icon> Quick Edit
              </button>
              <button (click)="$event.stopPropagation(); editMain(mainFeatureLabel.ID)" mat-menu-item color="">
                <mat-icon>edit</mat-icon> Edit
              </button>
              <hr/>
              <button (click)="$event.stopPropagation(); create()" mat-menu-item color="">
                <mat-icon>add</mat-icon> Add sub feature
              </button>
              <button
                *ngIf="mainFeatureLabel && mainFeatureLabel.FE_Code && mainFeatureLabel.Is_Deactivated !== '1'"
                [matMenuTriggerFor]="links"
                mat-menu-item
              >
                <mat-icon>share</mat-icon> Copy Links
              </button>
              <mat-menu #links="matMenu">
                <button
                  *ngIf="
                    publicSiteLink &&
                    mainFeatureLabel &&
                    mainFeatureLabel.Is_Private !== '1' &&
                    mainFeatureLabel.Is_Consumer_Feature === '1'
                  "
                  (click)="
                    $event.stopPropagation();
                    copy(
                      publicSiteLink +
                        '/consumer/(page:main-feature-view-code/' +
                        mainFeatureLabel.FE_Code +
                        '/consumer)'
                    )
                  "
                  mat-menu-item
                >
                  Public Site: <strong>Consumer</strong>
                </button>

                <button
                  *ngIf="
                    publicSiteLink &&
                    mainFeatureLabel &&
                    mainFeatureLabel.Is_Private !== '1' &&
                    mainFeatureLabel.Is_Merchant_Feature === '1'
                  "
                  (click)="
                    $event.stopPropagation();
                    copy(
                      publicSiteLink +
                        '/partners/(page:main-feature-view-code/' +
                        mainFeatureLabel.FE_Code +
                        '/merchant)'
                    )
                  "
                  mat-menu-item
                >
                  Public Site: <strong>Partner</strong>
                </button>

                <button
                  *ngIf="mainAppLink && mainFeatureLabel && mainFeatureLabel.Is_Consumer_Feature === '1'"
                  (click)="
                    $event.stopPropagation();
                    copy(
                      mainAppLink + '/consumer/(page:main-feature-view-code/' + mainFeatureLabel.FE_Code + '/consumer)'
                    )
                  "
                  mat-menu-item
                >
                  Main Application: <strong>Consumer</strong>
                </button>

                <button
                  *ngIf="mainAppLink && mainFeatureLabel && mainFeatureLabel.Is_Merchant_Feature === '1'"
                  (click)="
                    $event.stopPropagation();
                    copy(
                      mainAppLink + '/merchant/(page:main-feature-view-code/' + mainFeatureLabel.FE_Code + '/merchant)'
                    )
                  "
                  mat-menu-item
                >
                  Main Application: <strong>Merchant</strong>
                </button>
              </mat-menu>
              <hr/>
              <button (click)="$event.stopPropagation(); deleteMain(mainFeatureLabel.ID)" mat-menu-item color="">
                <mat-icon>delete</mat-icon> Remove
              </button>
            </mat-menu>

            <app-ep-document-view-image
              class="square-img-feature pull-left module-sub-feature"
              [hideZoomButton]="false"
              [defaultPicture]="
                'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/bell_icon.png)'
              "
              [link]="postPictureThumbnail"
            >
            </app-ep-document-view-image>

            <div class="row titleArea clearfix">
              <h2 class="summary-header rm-m" *ngIf="mainLabel">
                <mat-checkbox
                  class="checkbox-module smr"
                  *ngIf="canSelectMain == true"
                  [(ngModel)]="selectMainCheck"
                  (click)="$event.stopPropagation()"
                  (change)="selectMainEvent(selectMainCheck)"
                >
                </mat-checkbox>
                {{ mainLabel }}
                <span class="subLabel smt" *ngIf="mainDescription">{{ mainDescription }} </span>
              </h2>
            </div>

            <div class="full-width smt" style="margin-left: 80px">
              <span class="chip-module smr pull-left chip-disabled mat-elevation-z1" *ngIf="mainActive != true"
              >Not Active</span
              >

              <span
                class="chip-module chip-enable chip-subPlan smr pull-left mat-elevation-z1"
                *ngIf="readOnly != true && isPromoterOrAdmin == true && isMarketingOnly != true"
                matTooltip="Subscription Plan Feature"
              >Subscription Plan</span
              >

              <span
                class="chip-module chip-enable chip-consumer smr pull-left mat-elevation-z1"
                *ngIf="readOnly != true && isPromoterOrAdmin == true && isMainConsumer == true"
                matTooltip="Consumer Feature"
              >Consumer</span
              >

              <span
                class="chip-module chip-enable smr pull-left mat-elevation-z1"
                *ngIf="readOnly != true && isPromoterOrAdmin == true && isMainMerchant == true"
                matTooltip="Merchant Feature"
              >Merchant</span
              >

              <span
                class="chip-module chip-beta smr pull-left mat-elevation-z1"
                *ngIf="readOnly != true && isPromoterOrAdmin == true && isMainSideMenu == true"
                matTooltip="Added to side menu"
              >Side Menu</span
              >

              <span
                class="chip-module chip-disabled smr pull-left mat-elevation-z1"
                *ngIf="readOnly != true && isPromoterOrAdmin == true && isMainPrivate == true"
              >Not Public</span
              >
            </div>
          </div>

          <div *ngIf="displayCover == true" [hidden]="true">
            <img [src]="postCover" (error)="onError()" (load)="onLoad()"/>
          </div>

          <div
            class="sub-label-view-header full-width"
            *ngIf="displayCover == true"
            [class.negativeMargin]="isEmbeded != true"
            [ngClass]="{ 'default-background-image': isImageError != false }"
            [style.background-image]="postCoverURL"
          ></div>
        </div>

        <mat-dialog-content
          [class.subfeaturedialog]="isModalView == true"
          [ngClass]="{ 'no-modal-height': isModalView != true }"
        >
          <div class="full-width flex" *ngIf="canHideDisplay != true || hideDisplayCheck == true" [@slideInOut]>
            <div
              class="full-width text-center ml mr mb mt"
              *ngIf="
                (!subFeatureLabels || (subFeatureLabels && subFeatureLabels.length <= 0)) &&
                loading == true &&
                isPromoterOrAdmin == true
              "
            >
              <app-empty-list-message
                class="clearfix text-center"
                [title]="'No record found'"
                [message]="'This main feature label does not have any sub feature labels'"
                [isAction]="true"
                [actionIcon]="'add'"
                [actionLabel]="'Add'"
                (actionEvent)="create()"
              >
              </app-empty-list-message>
            </div>

            <div class="full-width ml mr mb" *ngIf="subFeatureLabels && subFeatureLabels.length > 0">
              <div class="full-width flex" *ngIf="isModalView != true">
                <mat-form-field
                  class="full-width search-label-description clearfix"
                  *ngIf="displayFilter == true"
                  appearance="outline"
                >
                  <mat-label>Filter by Label or Description</mat-label>
                  <input
                    class="full-width"
                    [(ngModel)]="searchFilter"
                    name="linkLabel"
                    matInput
                    placeholder="Filter by module name , description or Code"
                  />
                </mat-form-field>

                <button
                  class="btn pull-right sml select-button-top"
                  *ngIf="canSelectMain != true && canSelect == true"
                  (click)="selectAll()"
                  mat-raised-button
                  matTooltip="Select All"
                >
                  <mat-icon>border_all</mat-icon>
                </button>

                <button
                  class="btn pull-right sml select-button-top"
                  *ngIf="canSelectMain != true && canSelect == true"
                  (click)="unselectAll()"
                  mat-raised-button
                  matTooltip="Unselect All"
                >
                  <mat-icon>border_clear</mat-icon>
                </button>

                <button
                  class="btn pull-right sml select-button-top"
                  *ngIf="windowControl == true"
                  [style.background]="'#fff'"
                  (click)="collapseEvent(true)"
                  mat-icon-button
                  matTooltip="Close all"
                >
                  <mat-icon class="fas fa-window-minimize" [attr.style]="'color:' + color + ' !important' | safeStyle">
                  </mat-icon>
                </button>

                <button
                  class="btn pull-right sml select-button-top"
                  *ngIf="windowControl == true"
                  [style.background]="'#fff'"
                  (click)="collapseEvent(false)"
                  mat-icon-button
                  matTooltip="Open all"
                >
                  <mat-icon class="fas fa-window-maximize" [attr.style]="'color:' + color + ' !important' | safeStyle">
                  </mat-icon>
                </button>

                <button
                  class="btn pull-right sml mr select-button-top"
                  *ngIf="
                    canDragAndDrop == true &&
                    subFeatureLabels &&
                    subFeatureLabels.length > 1 &&
                    readOnly != true &&
                    displaySaveButton == true
                  "
                  [disabled]="disableSaveButton"
                  (click)="saveOrderEvent()"
                  mat-raised-button
                  color="accent"
                  matTooltip="Save new order"
                >
                  <mat-icon>save</mat-icon>
                </button>

                <button
                  class="btn pull-right ml select-button-top"
                  *ngIf="readOnly != true"
                  [attr.style]="'background-color:' + colorSecond + ' !important' | safeStyle"
                  (click)="create()"
                  mat-raised-button
                  color="accent"
                  matTooltip="Add new Sub feature"
                >
                  <mat-icon class="fas fa-plus"></mat-icon>
                </button>
              </div>
              <div
                class="container-sub-feature-module full-width list-drag"
                [cdkDropListDisabled]="!(canDragAndDrop == true && !searchFilter && readOnly != true)"
                (cdkDropListDropped)="drop($event)"
                cdkDropList
              >
                <div
                  class="full-width list-box row"
                  *ngFor="
                    let m of subFeatureLabels
                      | FilterArrayMultipleOrContain: ['SubLabel', 'Description']:searchFilter
                      | OrderArrayObject: 'Display_Order';
                    let index = index
                  "
                  cdkDrag
                >
                  <div class="item-placeholder full-width" *cdkDragPlaceholder></div>
                  <div class="full-width flex row">
                    <mat-card
                      class="card sub-feature-card full-width sub-feature-label-card row"
                      [class.subfeaturecardopen]="openedSubFeature[m['ID']] === true"
                      [class.pointerCard]="canDragAndDrop == true && !searchFilter && readOnly != true"
                      [ngClass]="
                        canDragAndDrop == true && !searchFilter && readOnly != true
                          ? 'left-card-border-drag'
                          : 'left-card-border-no-drag'
                      "
                      [attr.style]="
                        'border-color: ' +
                          buildBorderleft(m.Color, colorSecond, openedSubFeature[m['ID']]) +
                          ' !important' | safeStyle
                      "
                    >
                      <div class="full-width">
                        <button
                          class="pull-right action-button3"
                          *ngIf="readOnly != true"
                          [matMenuTriggerFor]="ActionBtnmenu"
                          (click)="$event.stopPropagation()"
                          mat-icon-button
                          color="primary"
                        >
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #ActionBtnmenu="matMenu">
                          <button (click)="$event.stopPropagation(); quickEdit(m['ID'])" mat-menu-item color="">
                            <mat-icon>save</mat-icon> Quick Edit
                          </button>

                          <button (click)="$event.stopPropagation(); edit(m['ID'])" mat-menu-item color="">
                            <mat-icon>edit</mat-icon> Edit
                          </button>

                          <button
                            *ngIf="m && m.FE_Code && m.Is_Deactivated !== '1'"
                            [matMenuTriggerFor]="links"
                            mat-menu-item
                          >
                            <mat-icon>share</mat-icon> Copy Links
                          </button>
                          <mat-menu #links="matMenu">
                            <button
                              *ngIf="publicSiteLink && m && m.Is_Private !== '1' && m.Is_Consumer_Feature === '1'"
                              (click)="
                                $event.stopPropagation();
                                copy(publicSiteLink + '/consumer/(page:sub-feature-code/' + m.FE_Code + '/consumer)')
                              "
                              mat-menu-item
                            >
                              Public Site: <strong>Consumer</strong>
                            </button>

                            <button
                              *ngIf="publicSiteLink && m && m.Is_Private !== '1' && m.Is_Merchant_Feature === '1'"
                              (click)="
                                $event.stopPropagation();
                                copy(publicSiteLink + '/partners/(page:sub-feature-code/' + m.FE_Code + '/merchant)')
                              "
                              mat-menu-item
                            >
                              Public Site: <strong>Partner</strong>
                            </button>

                            <hr/>
                            <button
                              *ngIf="mainAppLink && m && m.Is_Consumer_Feature === '1'"
                              (click)="
                                $event.stopPropagation();
                                copy(mainAppLink + '/consumer/(page:sub-feature-code/' + m.FE_Code + '/consumer)')
                              "
                              mat-menu-item
                            >
                              Main Application: <strong>Consumer</strong>
                            </button>

                            <button
                              *ngIf="mainAppLink && m && m.Is_Merchant_Feature === '1'"
                              (click)="
                                $event.stopPropagation();
                                copy(mainAppLink + '/merchant/(page:sub-feature-code/' + m.FE_Code + '/merchant)')
                              "
                              mat-menu-item
                            >
                              Main Application: <strong>Merchant</strong>
                            </button>
                          </mat-menu>

                          <hr/>

                          <button (click)="$event.stopPropagation(); delete(m['ID'])" mat-menu-item color="">
                            <mat-icon>delete</mat-icon> Remove
                          </button>
                        </mat-menu>

                        <div class="full-width clearfix">
                          <h2
                            class="rm-mt sub-feature-main-title full-width clearfix"
                            [attr.style]="
                              'color:' +
                                getSubFeature(m.Color, color) +
                                ' !important; border-color: ' +
                                buildBorderleft(m.Color, colorSecond, openedSubFeature[m['ID']]) +
                                ' !important;' | safeStyle
                            "
                          >
                            <mat-checkbox
                              class="checkbox-module pull-left mr"
                              *ngIf="canSelect == true"
                              [checked]="isSelected(m['ID'])"
                              (click)="$event.stopPropagation()"
                              (change)="selectSubLabel(m['ID'])"
                            >
                            </mat-checkbox>

                            <mat-checkbox
                              class="checkbox-module-limit pull-left mr"
                              *ngIf="canSelect == true && isSelected(m['ID'])"
                              [checked]="isSelectedLimited(m['ID'])"
                              (click)="$event.stopPropagation()"
                              (change)="selectSubLabelLimited(m['ID'])"
                            >Limited
                            </mat-checkbox>

                            <span class="pull-left">{{ m.SubLabel }}</span>

                            <button
                              class="pull-left zoom-button"
                              (click)="openSubFeatreOut(m['ID'])"
                              mat-icon-button
                              matTooltip="Expend {{ m.SubLabel }}  view"
                            >
                              <mat-icon
                                class="fas fa-angle-double-right"
                                [attr.style]="'color:' + getSubFeature(m.Color, color) + ' !important' | safeStyle"
                              >
                              </mat-icon>
                            </button>

                            <span
                              class="value pull-left ml chip-new"
                              *ngIf="m['Is_NewFeature'] == '1' && isNew(m['DateTimeCreated'])"
                              matTooltip="New  feature"
                            >NEW</span
                            >

                            <div class="mr" *ngIf="readOnly != true && isPromoterOrAdmin == true">
                              <span
                                class="value pull-right chip-new2 chip-newbeta smr"
                                *ngIf="m['Is_Side_Menu'] == '1' && m['Is_Main_Side_Menu'] != '1'"
                                [ngClass]="{ 'over-line': isMainSideMenu != true && m['Is_Main_Side_Menu'] != '1' }"
                                matTooltip="Added to side menu"
                              >Side Menu</span
                              >

                              <span
                                class="value pull-right chip-new2 chip-newbeta2 smr"
                                *ngIf="m['Is_Main_Side_Menu'] == '1'"
                                matTooltip="Added to side menu as stand alone item"
                              >Stand Alone</span
                              >

                              <span
                                class="value pull-right smr chip-new2 chip-consumer"
                                *ngIf="m['Is_Consumer_Feature'] == '1'"
                                [ngClass]="{ 'over-line': isMainConsumer != true }"
                                matTooltip="Consumer Feature"
                              >Consumer</span
                              >

                              <span
                                class="value pull-right smr chip-new2"
                                *ngIf="m['Is_Merchant_Feature'] == '1'"
                                [ngClass]="{ 'over-line': isMainMerchant != true }"
                                matTooltip="Merchant Feature"
                              >Merchant</span
                              >

                              <span class="value pull-right smr chip-new3" *ngIf="m['Is_Private'] == '1'"
                              >Not Public</span
                              >

                              <span
                                class="value pull-right chip-new2 chip-subPlan smr"
                                *ngIf="m['Is_Marketing_Only'] == '0'"
                                [ngClass]="{ 'over-line': isMarketingOnly == true }"
                                matTooltip="Subscription Plan Feature"
                              >Subscription Plan</span
                              >

                              <span class="value pull-right smr chip-new3" *ngIf="m['Is_Deactivated'] == '1'"
                              >Not Active</span
                              >
                            </div>
                          </h2>

                          <div class="full-width clearfix" *ngIf="controlCollapse !== null && m['Description']">
                            <read-more
                              class="full-width clearfix"
                              [header]="m['Description']"
                              [text]="htmlContentObject[m['ID']]"
                              [loading]="htmlContentLoader[m['ID']]"
                              [design]="'mode2'"
                              [hideButton]="hideMoreButton"
                              [isCollapsed]="controlCollapse"
                              [maxLength]="maxText"
                              (getCollapse)="openSubFeatre($event, m['ID'], m['Description'], m['LastModified'])"
                            >
                            </read-more>
                          </div>

                          <div class="full-width clearfix" *ngIf="controlCollapse === null && m['Description']">
                            <read-more
                              class="full-width clearfix"
                              *ngIf="openFirst != true || (openFirst == true && index !== 0)"
                              [header]="m['Description']"
                              [text]="htmlContentObject[m['ID']]"
                              [loading]="htmlContentLoader[m['ID']]"
                              [design]="'mode2'"
                              [hideButton]="hideMoreButton"
                              [isCollapsed]="true"
                              [maxLength]="maxText"
                              (getCollapse)="openSubFeatre($event, m['ID'], m['Description'], m['LastModified'])"
                            >
                            </read-more>

                            <read-more
                              class="full-width clearfix"
                              *ngIf="openFirst == true && index === 0"
                              [header]="m['Description']"
                              [text]="htmlContentObject[m['ID']]"
                              [loading]="htmlContentLoader[m['ID']]"
                              [design]="'mode2'"
                              [hideButton]="hideMoreButton"
                              [isCollapsed]="false"
                              [maxLength]="maxText"
                              (getCollapse)="openSubFeatre($event, m['ID'], m['Description'], m['LastModified'])"
                            >
                            </read-more>
                          </div>

                          <div class="full-width">
                            <span
                              class="chip-module-access pull-left mat-elevation-z1"
                              *ngIf="
                                displayOwn === true && isSelected(m['ID']) == true && isSelectedLimited(m['ID']) != true
                              "
                              [style.background-color]="'#4BB543'"
                              matTooltip="{{ accessOnTooltip }}"
                            >
                              <mat-icon
                                [style.color]="'#ffffff'"
                                style="font-size: 1.3rem !important; vertical-align: bottom; margin-bottom: -2px"
                              >power
                              </mat-icon>
                              {{ accessOnTitle }}
                            </span>
                            <span
                              class="chip-module-access pull-left mat-elevation-z1"
                              *ngIf="
                                displayOwn === true && isSelected(m['ID']) == true && isSelectedLimited(m['ID']) == true
                              "
                              [style.background-color]="'#FFA500'"
                              matTooltip="{{ accessLimitTooltip }}"
                            >
                              <mat-icon class="fas fa-unlock-alt" [style.color]="'#ffffff'"></mat-icon>
                              {{ accessLimitTitle }}
                            </span>
                            <span
                              class="chip-module-access pull-left mat-elevation-z1"
                              *ngIf="displayOwn === true && isSelected(m['ID']) != true"
                              [style.background-color]="'#ff0000'"
                              matTooltip="{{ accessOffTooltip }}"
                            >
                              <mat-icon
                                [style.color]="'#ffffff'"
                                style="font-size: 1.3rem !important; vertical-align: bottom; margin-bottom: -2px"
                              >
                                power_off
                              </mat-icon>
                              {{ accessOffTitle }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </mat-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="full-width clearfix">
            <button
              class="btn close-panel primary-color pull-left"
              *ngIf="canHideDisplay == true && hideDisplayCheck == true"
              [attr.style]="'color:' + colorSecond + ' !important' | safeStyle"
              (click)="canHideDisplayEvent(false)"
              mat-raised-button
              color="accent"
            >
              <span class="smr">Close</span>
              <mat-icon class="fas fa-angle-double-up"></mat-icon>
            </button>

            <button
              class="btn close-panel primary-color pull-right"
              *ngIf="allView == true && hideDisplayCheck == true"
              [attr.style]="'color:' + colorSecond + ' !important' | safeStyle"
              (click)="goMain(mainFeatureLabel.ID)"
              mat-raised-button
              color="accent"
            >
              <span
              >View all about <strong>{{ mainLabel }}</strong></span
              >
              <mat-icon class="fas fa-chevron-right sml"></mat-icon>
            </button>
          </div>
        </mat-dialog-content>

        <hr *ngIf="isModalView == true"/>

        <div class="drop-button text-center full-width" *ngIf="isModalView == true">
          <button
            class="pull-right"
            [attr.style]="'background-color:' + colorSecond + ' !important' | safeStyle"
            (click)="closeEvent()"
            mat-raised-button
            color="primary"
          >
            Close
          </button>
        </div>
      </mat-card>

      <div class="full-width mt mb" *ngIf="bottomNavigation == true">
        <button
          class="btn-large pull-left"
          *ngIf="previousFeature && previousFeature.ID"
          (click)="viewMainRedirect(previousFeature.ID)"
          mat-raised-button
          color="accent"
        >
          <mat-icon class="fas fa-chevron-left smr"></mat-icon>

          <span>{{ previousFeature.MainLabel }} </span>
        </button>
        <button
          class="btn-large pull-right"
          *ngIf="nextFeature && nextFeature.ID"
          (click)="viewMainRedirect(nextFeature.ID)"
          mat-raised-button
          color="accent"
        >
          <span> {{ nextFeature.MainLabel }} </span>
          <mat-icon class="fas fa-chevron-right sml"></mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<app-card-footer
  *ngIf="bottomNavigation == true && featureType != 'consumer'"
  [firstBackgroundWhite]="false"
  [items]="['features', 'plans-price', 'demo']"
  [featureType]="featureType"
></app-card-footer>
