/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./simple-time-out.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "./simple-time-out.component";
import * as i9 from "@angular/common";
import * as i10 from "@angular/router";
var styles_SimpleTimeOutComponent = [i0.styles];
var RenderType_SimpleTimeOutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SimpleTimeOutComponent, data: {} });
export { RenderType_SimpleTimeOutComponent as RenderType_SimpleTimeOutComponent };
export function View_SimpleTimeOutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "container error-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "mat-card", [["class", "error text-center mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(2, 49152, null, 0, i3.MatCard, [[2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 0, "img", [["class", "character_time_error"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, 0, 0, "div", [["class", "circle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "h1", [["class", "sm-h2 accent-color"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Session timeout"])), (_l()(), i1.ɵeld(7, 0, null, 0, 0, "hr", [["class", "rm-m"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["We noticed you have been inactive for while."])), (_l()(), i1.ɵeld(10, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You have been automatically logged out for security reasons."])), (_l()(), i1.ɵeld(12, 0, null, 0, 3, "div", [["class", "button-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["class", "btn-large"], ["color", "accent"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.login() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(14, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["login Again"]))], function (_ck, _v) { var currVal_3 = "accent"; _ck(_v, 14, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 14).disabled || null); var currVal_2 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 13, 0, currVal_1, currVal_2); }); }
export function View_SimpleTimeOutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-simple-time-out", [], null, null, null, View_SimpleTimeOutComponent_0, RenderType_SimpleTimeOutComponent)), i1.ɵdid(1, 114688, null, 0, i8.SimpleTimeOutComponent, [i9.Location, i10.Router, i10.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SimpleTimeOutComponentNgFactory = i1.ɵccf("app-simple-time-out", i8.SimpleTimeOutComponent, View_SimpleTimeOutComponent_Host_0, {}, {}, []);
export { SimpleTimeOutComponentNgFactory as SimpleTimeOutComponentNgFactory };
