import { fromEvent as observableFromEvent } from 'rxjs';

import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import * as FileSaver from 'file-saver';
import { debounceTime } from 'rxjs/operators';
import { Epdocument } from '../../types/epdocument';

@Component({
  selector: 'app-ep-document-view-short',
  templateUrl: './ep-document-view-short.component.html',
  styleUrls: ['./ep-document-view-short.component.css'],
})
export class EpDocumentViewShortComponent implements OnInit {
  @Input() title = 'ep';
  @Input() docs: Epdocument[] = [];
  @Input() extraInfoDisplay = true;

  @Output() download = new EventEmitter();
  @Output() readed = new EventEmitter();
  doc: Epdocument;
  fileURL: any;
  fileIndex = 1;
  scroll$: any;
  @ViewChild('iframe', { static: false }) iframe: ElementRef;

  constructor(
    private domSanitizer: DomSanitizer,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    @Inject(NgZone) private zone: NgZone
  ) {}

  ngOnInit() {
    this.title = this.data.title;
    this.docs = this.data.docs;

    this.doc = this.data.doc ? this.data.doc : this.docs[0];

    this.fileIndex = this.docs.indexOf(this.doc) + 1;
    if (this.doc) {
      this.displayDoc();
    }
  }

  downloadFile() {
    if (this.doc && this.doc.extension == 'pdf') {
      const blob = this.base64toBlob(this.doc.blob, 'application/pdf');
      FileSaver.saveAs(blob, this.doc.label + '.pdf');
      this.download.emit(true);
    } else if (this.doc && this.doc.extension == 'png') {
      const blob = this.base64toBlob(this.doc.blob, 'image/png');
      FileSaver.saveAs(blob, this.doc.label + '.png');
      this.download.emit(true);
    }else if (this.doc && this.doc.extension == 'heic') {
      const blob = this.base64toBlob(this.doc.blob, 'image/heic');
      FileSaver.saveAs(blob, this.doc.label + '.heic');
      this.download.emit(true);
    }  else if (this.doc && this.doc.extension == 'jpeg') {
      const blob = this.base64toBlob(this.doc.blob, 'image/jpeg');
      FileSaver.saveAs(blob, this.doc.label + '.jpeg');
      this.download.emit(true);
    }




  }

  displayDoc() {




    if (this.doc && this.doc.extension == 'pdf') {
      const file = this.base64toBlob(this.doc.blob, 'application/pdf');
      this.fileURL = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
    } else if (this.doc && this.doc.extension == 'png') {
      const file = this.base64toBlob(this.doc.blob, 'image/png');
      this.fileURL = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
    } else if (this.doc && this.doc.extension == 'heic') {
      const file = this.base64toBlob(this.doc.blob, 'image/heic');
      this.fileURL = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
    }   else if (this.doc && this.doc.extension == 'jpeg') {
      const file = this.base64toBlob(this.doc.blob, 'image/jpeg');
      this.fileURL = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
    } else if (this.doc && this.doc.url !== '') {
      if (this.doc['url'].indexOf('youtube') != -1) {
        this.doc['url'] = this.doc['url'].replace('watch?v=', 'embed/');
      }
      this.fileURL = this.domSanitizer.bypassSecurityTrustResourceUrl(this.doc['url']);
    }
  }

  openLink() {
    window.open(this.doc.url, '_blank');
  }

  getNextFile() {
    if (this.docs[this.fileIndex]) {
      this.doc = this.docs[this.fileIndex];
      this.displayDoc();
    }
    this.fileIndex += 1;
  }

  getPreviousFile() {
    if (this.docs[this.fileIndex - 2]) {
      this.doc = this.docs[this.fileIndex - 2];
      this.displayDoc();
    }
    this.fileIndex -= 1;
  }

  onFrameLoad(evt) {
    if (this.iframe && this.iframe.nativeElement && this.iframe.nativeElement.blobWindow) {
      this.iframe.nativeElement.blobWindow.addEventListener(
        'onscroll',
        function (event) {
          alert('scroll!!');
          this.zone.run(() => {
            alert('scroll!!');
          });
        },
        false
      );

      this.scroll$ = observableFromEvent(this.iframe.nativeElement.blobWindow, 'scroll')
        .pipe(debounceTime(200))
        .subscribe((evt: any) => {
          if (this.iframe.nativeElement.contentWindow.scrollY > this.iframe.nativeElement.contentWindow.innerHeight) {
            alert('scroll bottom');
            this.zone.run(() => {
              alert('scroll!!');
            });
          }
        });
    }
  }

  base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }
}
