import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';

import { MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { TemplateReviewComponent } from '../../../core/helper/template-review/template-review.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { DentistService } from '../../dentist/shared/dentist.service';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { SocialMediaViewComponent } from '../../social-media/social-media-view/social-media-view.component';
import { MarketingService } from '../shared/marketing.service';

@Component({
  selector: 'app-marketing-campaign-social-media-create',
  templateUrl: './marketing-campaign-social-media-create.component.html',
  styleUrls: ['./marketing-campaign-social-media-create.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MarketingCampaignSocialMediaCreateComponent implements OnInit {
  @ViewChild(WizardComponent, { static: false }) public wizard: WizardComponent;

  campaignName;
  contactID;
  campaignDescription;

  actionCallLabel;
  actionCallDescription;
  facebookPostID;
  twitterPostID;
  instagramPostID;
  linkedInPostID;

  isPublicCampaign = true;
  createdCampaign;
  userData;
  icon = 'fas fa-hashtag';

  title = 'New social media campaign';
  description = "We've prepared everything you need to create social media posts in simple  steps.";

  defaultExpirationDate = moment().add(1, 'months').toDate();

  validateUserData = false;

  isManualCreatedOrDefault = Settings.global['MarketingTemplateMasterAndManual'];
  isManualCreated = Settings.global['MarketingManual'];
  isDefault = Settings.global['MarketingTemplateDefault'];
  isCampaign = Settings.global['MarketingIsCampaign'];
  util = new UtilsClass();
  selectedTemplateID;
  selectedTemplate;
  socialMediaType = 'Facebook';

  hasExpirationDate = false;
  exprirationDate;

  contacts = [];
  merchants = [];
  merchantID;

  isPromoterOrAdmin = false;
  displayMerchantSelect = true;

  SessionType = 'merchant';

  currentUser;
  sessionType;

  templates;

  isModal;

  campaignID;
  @Output()
  close = new EventEmitter();
  @Output()
  getCampaign = new EventEmitter();
  @Output()
  getCampaignID = new EventEmitter();

  @Output()
  noTemplateFound = new EventEmitter();

  constructor(
    private router: Router,
    private merchantService: MerchantService,
    private authenticationService: AuthenticationService,
    private marketingService: MarketingService,
    private dentistService: DentistService,
    private activeRoute: ActivatedRoute,
    private utilService: UtilsService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.facebookPostID) {
        this.facebookPostID = data.facebookPostID;
        this.isModal = true;
      } else if (data.linkedInPostID) {
        this.linkedInPostID = data.linkedInPostID;
        this.isModal = true;
      } else if (data.instagramPostID) {
        this.instagramPostID = data.instagramPostID;
        this.isModal = true;
      } else if (data.twitterPostID) {
        this.twitterPostID = data.twitterPostID;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    if (this.facebookPostID) {
      this.socialMediaType = 'Facebook';
    } else if (this.linkedInPostID) {
      this.socialMediaType = 'LinkedIn';
    } else if (this.instagramPostID) {
      this.socialMediaType = 'Instagram';
    } else if (this.twitterPostID) {
      this.socialMediaType = 'Twitter';
    }
    this.activeRoute.params.subscribe((params) => {
      if (params['campaignID']) {
        this.campaignID = params['campaignID'];
      }
      this.authenticationService.getCurrentUser().subscribe((u) => {
        if (u && u.data) {
          this.currentUser = u.data;
        }
      });

      this.authenticationService.getSessionType().subscribe((sessionType) => {
        this.sessionType = sessionType;
      });

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;
      });
    });
  }

  selectTemplateEvent(t) {
    if (t && t['Tag'] && t['ID']) {
      this.selectedTemplate = t;
      this.selectedTemplateID = t['ID'];

      this.wizard.goToNextStep();

      this.buildTemplate();
    }
  }

  getImageLink(id, LastModified) {
    return this.utilService.getTemplateImageStreamLink(id, LastModified);
  }

  previewTemplateModal() {
    if (this.selectedTemplate && this.selectedTemplate['ID']) {
      const ref = RootAppComponent.dialog.open(TemplateReviewComponent, {
        data: {
          templateID: this.selectedTemplate['ID'],
          isPrint: false,
          isProceed: false,
          isDownload: false,
        },
        width: '700px',
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
    }
  }

  selectProceed(t) {
    if (t && t['Tag'] && t['ID']) {
      if (t && t.MetaData && t.MetaData['Meta_Data)']) {
        t.MetaData = t.MetaData['Meta_Data)'];
      }

      this.selectedTemplate = t;
      this.selectedTemplateID = t['ID'];
    }
  }

  backToTemplate() {
    this.wizard.goToStep(0);
  }

  buildTemplate() {
    if (!this.actionCallLabel && this.selectedTemplate['ActionCall.Label']) {
      this.actionCallLabel = this.selectedTemplate['ActionCall.Label'];
    }
    if (!this.actionCallDescription && this.selectedTemplate['ActionCall.Description']) {
      this.actionCallDescription = this.selectedTemplate['ActionCall.Description'];
    }

    this.contactID = null;

    if (this.selectedTemplate && this.selectedTemplate['MetaData'] && this.selectedTemplate['MetaData']['Amount']) {
      const patload = {
        fields: 'ID,CalculatedName,ContactForCard_key',
        merchantID: this.merchantID,
      };

      this.dentistService.getFiltersContact(patload, this.isPromoterOrAdmin).subscribe((res) => {
        if (res) {
          this.contacts = res;
        }
      });
    }
  }

  changeMerchant() {
    if (this.merchantID) {
      this.buildTemplate();
    }
  }

  viewMerchant() {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: this.merchantID,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  selectedExpirationDate(e) {
    this.exprirationDate = e;
  }

  createCampaignPending() {
    let payload: any = {};

    payload = {
      templateID: this.selectedTemplate['ID'],
      merchantID: this.merchantID,
      label: this.campaignName,
      actionCallDescription: this.actionCallDescription,
      actionCallLabel: this.actionCallLabel,
      description: this.campaignDescription,
      template: this.selectedTemplate['Tag'],
      sendSms: false,
      sendEmail: false,
      userData: this.userData,
      contactID: this.contactID,
      isPublicCampaign: this.isPublicCampaign,
    };

    if (this.hasExpirationDate == true) {
      payload.expirationDate = this.exprirationDate;
    }

    this.marketingService.createSocialMediaMarketing(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        if (res.ID) {
          this.getCampaignID.emit(res.ID);
          this.getCampaign.emit(res);
        }

        this.createdCampaign = res;

        this.wizard.goToNextStep();

        NotifyAppComponent.displayToast('success', 'Successful operation', 'Social Media Campaign is created');
      }
    });
  }

  toNumber(n) {
    if (n) {
      return Number(n);
    } else {
      return 0;
    }
  }

  viewCampaign() {
    this.router.navigate(['/merchant', { outlets: { page: ['marketing-campaign-view', this.createdCampaign['ID']] } }]);
  }

  getUserData(e) {
    if (e) {
      this.userData = e;
    }
  }

  viewSocialMedia(campaignID, socialMediaType = 'Facebook') {
    if (campaignID && socialMediaType) {
      const ref = RootAppComponent.dialog.open(SocialMediaViewComponent, {
        data: {
          campaignID,
          socialMediaType,
        },
        width: '900px',
        panelClass: ['noCard', 'full-big-screen'],
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.confirmShare.subscribe((res) => {
        ref.close();
      });
    }
  }

  validateUserDataEvent(e) {
    this.validateUserData = e || false;
  }

  validateMataData(obj) {
    if (!obj) {
      return true;
    } else {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    }
  }
}
