<div class="full-width clearfix">
  <mat-card class="treatment treatment-shadow-box" *ngIf="treatmentDocuments && treatmentDocuments.length > 0">
    <app-ep-document-list-mini
      class="white-title"
      [cardID]="cardID"
      [viewedDocuments]="viewedDocuments"
      [instructionLine]="treatmentInstructionLine"
      [displayStatus]="true"
      [title]="treatmentDocumentTitle"
      [displayDate]="false"
      [documents]="treatmentDocuments"
      [isDownload]="isDownload"
      [displayViewedDate]="isDisplayViewedDate"
      [isFilter]="isFilter"
      [view]="view"
      (getViewedDocument)="getViewedDocumentEvent($event)"
    >
    </app-ep-document-list-mini>
  </mat-card>

  <mat-card
    class="treatment treatment-shadow-box"
    *ngIf="stockArtTreatmentDocuments && stockArtTreatmentDocuments.length > 0"
  >
    <app-ep-document-list-mini
      class="white-title"
      [viewedDocuments]="viewedDocuments"
      [displayStatus]="true"
      [title]="stockArtTreatmentDocumentTitle"
      [displayDate]="false"
      [instructionLine]="stockArtTreatmentInstructionLine"
      [documents]="stockArtTreatmentDocuments"
      [isPrint]="isPrint"
      [isRedirection]="false"
      [isAction]="false"
      [isAction]="false"
      [displayViewedDate]="isDisplayViewedDate"
      [isFilter]="isFilter"
      [view]="view"
      (getViewedDocument)="getViewedDocumentEvent($event)"
    >
    </app-ep-document-list-mini>
  </mat-card>

  <mat-card class="treatment treatment-shadow-box" *ngIf="preTreatmentDocuments && preTreatmentDocuments.length > 0">
    <app-ep-document-list-mini
      class="white-title"
      [cardID]="cardID"
      [viewedDocuments]="viewedDocuments"
      [instructionLine]="preTreatmentInstructionLine"
      [displayStatus]="true"
      [title]="preTreatmentDocumentTitle"
      [displayDate]="false"
      [documents]="preTreatmentDocuments"
      [isRedirection]="false"
      [isDownload]="isDownload"
      [isPrint]="isPrint"
      [isAction]="false"
      [displayViewedDate]="isDisplayViewedDate"
      [isFilter]="isFilter"
      [view]="view"
      (getViewedDocument)="getViewedDocumentEvent($event)"
    >
    </app-ep-document-list-mini>
  </mat-card>

  <mat-card class="treatment treatment-shadow-box" *ngIf="postTreatmentDocuments && postTreatmentDocuments.length > 0">
    <app-ep-document-list-mini
      class="white-title"
      [cardID]="cardID"
      [viewedDocuments]="viewedDocuments"
      [instructionLine]="postTreatmentInstructionLine"
      [displayStatus]="true"
      [title]="postTreatmentDocumentTitle"
      [displayDate]="false"
      [documents]="postTreatmentDocuments"
      [isRedirection]="false"
      [isDownload]="isDownload"
      [isPrint]="isPrint"
      [isAction]="false"
      [displayViewedDate]="isDisplayViewedDate"
      [isFilter]="isFilter"
      [view]="view"
      (getViewedDocument)="getViewedDocumentEvent($event)"
    >
    </app-ep-document-list-mini>
  </mat-card>

  <mat-card
    class="treatment treatment-shadow-box"
    *ngIf="marketingTreatmentDocuments && marketingTreatmentDocuments.length > 0"
  >
    <app-ep-document-list-mini
      class="white-title"
      [viewedDocuments]="viewedDocuments"
      [displayStatus]="true"
      [title]="marketingTreatmentDocumentTitle"
      [displayDate]="false"
      [instructionLine]="marketingTreatmentInstructionLine"
      [documents]="marketingTreatmentDocuments"
      [isPrint]="isPrint"
      [isRedirection]="false"
      [isAction]="false"
      [isAction]="false"
      [displayViewedDate]="isDisplayViewedDate"
      [isFilter]="isFilter"
      [view]="view"
      (getViewedDocument)="getViewedDocumentEvent($event)"
    >
    </app-ep-document-list-mini>
  </mat-card>
</div>
