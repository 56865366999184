import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';

export class AppointmentIframeViewComponentDialogData {
  iFrameSrc: string;
}

@Component({
  selector: 'app-appointment-iframe-view',
  templateUrl: './appointment-iframe-view.component.html',
  styleUrls: ['./appointment-iframe-view.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class AppointmentIframeViewComponent implements OnInit {
  iFrameSrc;
  iFrameSrcSecured;
  loadIFrame = false;

  constructor(
    private _sanitizer: DomSanitizer,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: AppointmentIframeViewComponentDialogData
  ) {
    if (data) {
      if (data.iFrameSrc) {
        this.iFrameSrc = data.iFrameSrc;
      }
    }
  }

  ngOnInit() {
    if (this.iFrameSrc) {
      this.updateIFrameSrc(this.iFrameSrc);
    }
  }

  updateIFrameSrc(src: string): void {
    this.iFrameSrcSecured = this._sanitizer.bypassSecurityTrustResourceUrl(src);
  }

  onFrameLoad(e) {
    this.loadIFrame = true;
  }
}
