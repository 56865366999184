<mat-card class="rel clearfix">
  <div
    class="card-header primary-gradient-img clearfix stacked"
    [attr.style]="'background:' + theme.background + ' !important' | safeStyle"
  >
    <button class="btn-close btn-clear mat-button smt white custom-close" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>

    <div class="row clearfix">
      <div class="row titleArea clearfix">
        <h2 class="summary-header text-left rm-m">Landing Page Integration</h2>
      </div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="full-width text-center">
      <h3 class="sr-title text-left full-width rm-mt" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
        Iframe Content
      </h3>

      <mat-slide-toggle class="full-width mb" [(ngModel)]="displayHeader" (ngModelChange)="buildIframe()">
        Display Header: {{ "KEYWORD.practice" | translate | titlecase}} name and Contact Us button.
      </mat-slide-toggle>

      <mat-slide-toggle class="full-width mb" [(ngModel)]="displayFooter" (ngModelChange)="buildIframe()">
        Display Footer: {{ "KEYWORD.practice" | translate | titlecase}} hours and Footer content.
      </mat-slide-toggle>

      <mat-slide-toggle
        class="full-width mb"
        *ngIf="type === 'practice' || type === 'goingGreen'"
        [(ngModel)]="displayOffers"
        (ngModelChange)="buildIframe()"
      >
        Display Offers: Offers Slider and links to {{"KEYWORD.dental" | translate}} tips & offers.
      </mat-slide-toggle>

      <h3 class="sr-title text-left full-width rm-mt" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
        Iframe Dimension
      </h3>

      <div class="clearfix full-width flex">
        <mat-form-field class="full-width mr" appearance="outline">
          <mat-label>Width Type</mat-label>
          <mat-select
            class="full-width"
            [(ngModel)]="widthType"
            (ngModelChange)="buildIframe()"
            placeholder="Enter a value"
            name="widthType"
            required
          >
            <mat-option *ngFor="let p of widthTypes" [value]="p['code']">
              {{ p.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Value</mat-label>
          <span matSuffix>
            {{ getUnit(widthType, true) }}
          </span>

          <input
            [(ngModel)]="width"
            (ngModelChange)="buildIframe()"
            name="withss"
            type="number"
            matInput
            placeholder="Enter a value"
          />
        </mat-form-field>
      </div>

      <div class="clearfix full-width flex">
        <mat-form-field class="full-width mr" appearance="outline">
          <mat-label>Height Type</mat-label>
          <mat-select
            class="full-width"
            [(ngModel)]="heightType"
            (ngModelChange)="buildIframe()"
            placeholder="Enter a value"
            name="HeightType"
            required
          >
            <mat-option *ngFor="let p of heightTypes" [value]="p['code']">
              {{ p.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Value</mat-label>
          <span matSuffix>
            {{ getUnit(heightType, true) }}
          </span>

          <input
            [(ngModel)]="height"
            (ngModelChange)="buildIframe()"
            name="heightss"
            type="number"
            matInput
            placeholder="Enter a value"
          />
        </mat-form-field>
      </div>

      <h3 class="sr-title text-left full-width rm-mt" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
        Code Result
      </h3>

      <p class="clearfix text-left">
        If you want to provide a smoother experience for {{ "KEYWORD.patient" | translate | titlecase }}'s, you can set
        up an
        "iframe" on
        your webpage. Rather than {{ "KEYWORD.patient" | translate | titlecase }}'s been routed to a new webpage.
      </p>
      <div class="row clearfix">
        <pre class="iframe-code-source" *ngIf="iframeCode">{{ iframeCode }}</pre>
      </div>
    </div>
  </mat-dialog-content>

  <div class="drop-button text-center white-background">
    <hr/>

    <button
      class="action-button btn-large pull-left smt"
      [attr.style]="'background:' + theme.color2 + ' !important' | safeStyle"
      (click)="closeEvent()"
      mat-raised-button
      color="primary"
    >
      Close
    </button>

    <button
      class="action-button btn-large pull-right smt"
      *ngIf="iframeCode"
      [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
      (click)="copy(iframeCode)"
      mat-raised-button
      color="accent"
    >
      Copy Code
      <mat-icon class="fas fa-copy sml"></mat-icon>
    </button>

    <button
      class="action-button btn-large mr pull-right smt"
      *ngIf="link"
      [attr.style]="'background:' + theme.color2 + ' !important' | safeStyle"
      (click)="openIframe()"
      mat-raised-button
      color="primary"
    >
      Preview
      <mat-icon class="fas fa-desktop sml"></mat-icon>
    </button>
  </div>
</mat-card>
