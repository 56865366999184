import { RevenueReportAccruedRecord } from '../../../types/revenue-report.type';

export const BORROWER_FEES_ACCRUED_DIVIDER: RevenueReportAccruedRecord = {
  label: `Borrower Fees`,
  code: '',
  accrued: null,
  waviersOrRebates: null,
  accruedNet: null,
  accruedPercentage: null,
};

export const BORROWER_INTEREST_ACCRUED_DIVIDER: RevenueReportAccruedRecord = {
  label: `Borrower Interest`,
  code: '',
  accrued: null,
  waviersOrRebates: null,
  accruedNet: null,
  accruedPercentage: null,
};

export const MERCHANT_FEES_ACCRUED_DIVIDER: RevenueReportAccruedRecord = {
  label: `Merchant Fees`,
  code: '',
  accrued: null,
  waviersOrRebates: null,
  accruedNet: null,
  accruedPercentage: null,
};
