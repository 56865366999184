<mat-expansion-panel class="sub-panel" (opened)="label.isOpen = true" (closed)="label.isOpen = false">
  <mat-expansion-panel-header>
    <mat-icon class="closed fas fa-folder" *ngIf="label.isOpen == false" aria-hidden="true"></mat-icon>
    <mat-icon class="open fas fa-folder-open" *ngIf="label.isOpen == true" aria-hidden="true"></mat-icon>
    <span class="text">{{ label.name }}</span>
  </mat-expansion-panel-header>
  <div class="panel-content">
    <mat-list class="sub-menu offset" *ngFor="let p of label.pages">
      <mat-list-item class="help-item" (click)="help()">
        <p class="rm-m">{{ p }}</p>
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
  </div>
</mat-expansion-panel>
