import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-inner-html-isolated',
  templateUrl: './inner-html-isolated.component.html',
  styleUrls: ['./inner-html-isolated.component.css'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class InnerHtmlIsolatedComponent implements OnInit {
  @Input()
  content;


  constructor(
  ) {

  }

  ngOnInit() {}

}
