import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { CustomerProspectService } from '../shared/customerProspect.service';

@Component({
  selector: 'app-patient-document-create-modal',
  templateUrl: './patient-document-create-modal.component.html',
  styleUrls: ['./patient-document-create-modal.component.css'],
})
export class PatientDocumentCreateModalComponent implements OnInit {
  @Input()
  patientID;

  @Input()
  title;

  @Input()
  description;

  @Input()
  label;
  sessionType;

  @Output()
  closeModal = new EventEmitter();

  @Output()
  result = new EventEmitter();

  isModal = false;

  allowedExtensionTypes = ['pdf', 'image', 'document'];
  videoExtensionType = ['video', 'audio'];
  imageExtensionType = ['image'];
  otherExtensionType = ['pdf', 'image', 'document'];
  documentTypes = [];
  @Input()
  documentType;
  patient;
  fileID;

  nature;

  displayDocumentType = true;

  constructor(
    private authenticationService: AuthenticationService,
    private utilService: UtilsService,
    private customerProspectService: CustomerProspectService,
    private activeRoute: ActivatedRoute,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.patientID) {
        this.patientID = data.patientID;
        this.isModal = true;
      }

      if (data.documentType) {
        this.documentType = data.documentType;
        this.isModal = true;
      }

      if (data.title) {
        this.title = data.title;
        this.isModal = true;
      }

      if (data.nature) {
        this.nature = data.nature;
        this.isModal = true;
      }

      if (data.documentTypes && data.documentTypes.length > 0) {
        this.documentTypes = data.documentTypes;

        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    if (this.nature) {
      if (this.nature == 'image') {
        this.documentType = 'MEDR_I';
        this.changeDocumentType();
        this.displayDocumentType = false;
      } else if (this.nature == 'video') {
        this.documentType = 'MEDR_V';
        this.changeDocumentType();
        this.displayDocumentType = false;
      }
    }

    this.authenticationService.getSessionType().subscribe((res) => {
      this.sessionType = res;

      if (this.patientID) {
        const p = {
          fields: 'FirstName,Name',
        };
        this.customerProspectService
          .getCustomerProspectDetails(this.patientID, p, this.sessionType)
          .subscribe((patient) => {
            if (patient) {
              this.patient = patient;
            }
          });
      }

      const _p = {
        fields: 'ID,Label,TypeCode',
        programAreaCode: 'MEDRD',
      };
      this.utilService.getDocumentTypeList(_p).subscribe((documentTypes) => {
        if (documentTypes && documentTypes.length > 0) {
          this.documentTypes = documentTypes;
        }
      });
    });
  }

  closeEvent() {
    this.closeModal.emit(true);
  }

  changeDocumentType() {
    if (this.documentType) {
      if (this.documentType == 'MEDR_V') {
        this.allowedExtensionTypes = this.videoExtensionType;
      } else if (this.documentType == 'MEDR_I') {
        this.allowedExtensionTypes = this.imageExtensionType;
      } else {
        this.allowedExtensionTypes = this.otherExtensionType;
      }
    }
  }

  getLabelByCode(code, label = null) {
    if (code) {
      if (code == 'MEDR_V') {
        return 'Videos';
      } else if (code == 'MEDR_X') {
        return 'Xrays';
      } else if (code == 'MEDR_T') {
        return 'Treatment Plans';
      } else if (code == 'MEDR_I') {
        return 'Images';
      } else if (code == 'MEDR_R') {
        return 'Referrals';
      } else if (code == 'MEDR_C') {
        return 'Correspondence';
      } else if (label) {
        return label;
      } else {
        return 'Documents';
      }
    } else if (label) {
      return label;
    } else {
      return 'Documents';
    }
  }

  getDocumentLabelByCode(code, label = null) {
    if (code) {
      if (code == 'MEDR_V') {
        return 'Video';
      } else if (code == 'MEDR_X') {
        return 'Xray';
      } else if (code == 'MEDR_T') {
        return 'Treatment Plan';
      } else if (code == 'MEDR_I') {
        return 'Image';
      } else if (code == 'MEDR_R') {
        return 'Referral';
      } else if (code == 'MEDR_C') {
        return 'Correspondence';
      } else if (label) {
        return label;
      } else {
        return 'Document';
      }
    } else if (label) {
      return label;
    } else {
      return 'Document';
    }
  }

  onCompleteAll(fileID) {
    if (fileID && fileID[0]) {
      this.fileID = fileID[0];
    }
  }

  save() {
    if (this.fileID && this.documentType && this.label && this.patientID) {
      const payload = {
        fileID: this.fileID,
        type: this.documentType,
        label: this.label,
        description: this.description,
        patientID: this.patientID,
      };

      this.customerProspectService.createPatientDocument(payload, this.sessionType).subscribe((res) => {
        if (res && res.ID) {
          this.result.emit(res);
          NotifyAppComponent.displayToast(
            'success',
            'Successful Operation',
            'You have successfully edited a document!'
          );
        }
      });
    }
  }
}
