<mat-card class="card rm-mb">
  <div class="card-header">
    <button class="btn-close btn-clear mat-button pull-right" *ngIf="isModal == true" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>
    <h3 class="rm-mt rm-mb sr-title sm-h3">Create new onboarding task</h3>
    <p class="rm-mt">
      Onboarding tasks are specific actions a  {{ 'KEYWORD.practice' | translate }} needs to complete to fully activate their account.
    </p>
  </div>

  <mat-form-field class="full-width" appearance="outline">
    <mat-label>Task title</mat-label>
    <input
      class="text-center"
      id="task-name"
      [(ngModel)]="name"
      type="text"
      name="name"
      placeholder="Task title"
      matInput
    />
  </mat-form-field>

  <mat-form-field class="full-width" appearance="outline">
    <mat-label>Task description (What merchants see)</mat-label>
    <input
      class="text-center"
      id="task-desc"
      [(ngModel)]="description"
      type="text"
      name="description"
      placeholder="Task description"
      matInput
    />
  </mat-form-field>

  <!-- <mat-form-field class="full-width" appearance="outline">
    <mat-label>Task type</mat-label>
    <input type="text" id="task-type" name="type" class="text-center" placeholder="Task type" [(ngModel)]="type"
      matInput>
  </mat-form-field>

  <mat-form-field class="full-width" appearance="outline">
    <mat-label>Task action</mat-label>
    <input type="text" id="task-action" name="action" class="text-center" placeholder="Task action" [(ngModel)]="action"
      matInput>
  </mat-form-field> -->

  <button
    class="btn btn-primary mt mb"
    *ngIf="isEdit == false"
    (click)="createTasks()"
    mat-raised-button
    type="button"
    color="primary"
  >
    Add task
  </button>

  <button
    class="btn btn-primary mt mb"
    *ngIf="isEdit == true"
    (click)="editTask()"
    mat-raised-button
    type="button"
    color="primary"
  >
    Edit Task
  </button>
</mat-card>
