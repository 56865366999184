import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-treatment-document-gallery-container',
  templateUrl: './treatment-document-gallery-container.component.html',
  styleUrls: ['./treatment-document-gallery-container.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({
                  opacity: 0,
                  transform: 'translateY(-75%)',
                  offset: 0,
                  height: 0,
                }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({
                  opacity: 1,
                  transform: 'translateY(0)',
                  offset: 1.0,
                  height: '*',
                }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({
                  opacity: 1,
                  transform: 'translateY(0)',
                  offset: 0,
                  height: '*',
                }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({
                  opacity: 0,
                  transform: 'translateY(-75%)',
                  offset: 1.0,
                  height: 0,
                }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class TreatmentDocumentGalleryContainerComponent implements OnInit {
  @Input()
  isActive;

  @Input()
  merchantID;

  @Input()
  manufacturerID;

  @Input()
  distributorID;

  @Input()
  supplierID;

  @Input()
  documentTypes;

  @Input()
  categoryID;

  @Input()
  brandID;

  @Input()
  typeID;

  @Input()
  dateFrom;

  @Input()
  dateTo;

  @Input()
  productID;

  @Input()
  title = 'Documents';

  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  @Input()
  includeInherited = true;

  @Input() isDownload = true;
  @Input() isRedirection = true;
  @Input() isPrint = true;

  displayedColumnsDentistDocs = ['selectCheckBox', 'mediaThumbnail', 'documentLabel', 'mediaMedium', 'Actions'];

  isPromoterOrAdmin = false;

  groupFilter = 'all';

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      if (res == false) {
        this.displayedColumnsDentistDocs = ['mediaThumbnail', 'documentLabel', 'mediaMedium', 'Actions'];
      }
    });
  }

  onGroupFilterEvent(event) {
    this.groupFilter = event;
  }
}
