<div class="row clearfix defineMessage">
  <mat-card class="card-centered">
    <!-- blue heading -->
    <div class="card-header primary-gradient-img clearfix">
      <div class="row clearfix">
        <!-- Modal dismiss button -->
        <button class="btn-close btn-clear pull-right mat-button" *ngIf="isModal == true" (click)="closeEvent()">
          <mat-icon>clear</mat-icon>
        </button>

        <div class="pic-area pull-left mr">
          <div class="displayUploadImg text-center" *ngIf="isLogoFile == false && isEditMode == true">
            <app-ep-document-view-image
              class="clearfix full-width profile-pic-container"
              [defaultPicture]="
                'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/type_placeholder.png)'
              "
              [link]="logoLink"
              [hideZoomButton]="false"
            >
            </app-ep-document-view-image>
          </div>

          <div class="displayUploadImg" *ngIf="isLogoFile == true">
            <img class="display-image-uploaded" [src]="logoSrc"/>
          </div>
        </div>

        <!-- merchant calculated name -->
        <div class="row titleArea clearfix">
          <h2 class="message-title summary-header rm-m" *ngIf="isEditMode == false">
            New {{ "KEYWORD.treatment" | translate | titlecase }} Type</h2>

          <h2 class="message-title summary-header rm-m" *ngIf="isEditMode == true">
            Edit {{ "KEYWORD.treatment" | translate | titlecase }} Type <span
            class="subLabel">{{ type['label'] }}</span>
          </h2>
        </div>
      </div>
    </div>

    <div class="row clearfix">
      <div class="row clearfix mt">
        <form #treatmentGroup="ngForm">
          <mat-dialog-content *ngIf="isDone == false" [ngClass]="{ 'no-modal-height': isModal != true }">
            <div class="clearfix">
              <div class="row full-width clearfix mt" *ngIf="isEditMode == true">
                <mat-slide-toggle [(ngModel)]="type.isActive" name="consentPatient"> Is Active?</mat-slide-toggle>
              </div>
            </div>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Label</mat-label>
              <input class="mat-block" [(ngModel)]="type.label" matInput name="label" required placeholder="Label"/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Category</mat-label>
              <mat-select
                class=""
                [(ngModel)]="type.categoryID"
                name="category"
                placeholder="Category"
                placeholder="Category"
                required
              >
                <mat-option *ngFor="let c of categories" [value]="c.ID">
                  {{ c.Label }}
                </mat-option>
              </mat-select>

              <button
                class=""
                *ngIf="type.categoryID"
                (click)="$event.stopPropagation(); viewCategory(type.categoryID)"
                matTooltip="View Category"
                color="accent"
                mat-button
                matSuffix
              >
                <mat-icon>visibility</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Item Code</mat-label>
              <input class="mat-block" [(ngModel)]="type.itemCode" matInput name="itemCode" placeholder="Item Code"/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Description</mat-label>
              <textarea [(ngModel)]="type.description" matInput placeholder="description" name="Description"></textarea>
            </mat-form-field>

            <div class="row clearfix flex full-width mb">
              <!--  Logo Upload -->
              <div class="uploadBlock left full-width" *ngIf="isDone != true">
                <div class="row clearfix logo-img image">
                  <div class="notUploaded" *ngIf="isLogoFile == false && isEditMode == false">
                    <app-ep-document-view-image
                      class="clearfix notYetUploaded"
                      [defaultPicture]="
                        'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cloud-upload-alt-solid.png)'
                      "
                    >
                    </app-ep-document-view-image>
                  </div>
                  <div class="displayUploadImg text-center" *ngIf="isLogoFile == false && isEditMode == true">
                    <app-ep-document-view-image
                      class="clearfix full-width profile-pic-container"
                      [defaultPicture]="
                        'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cloud-upload-alt-solid.png)'
                      "
                      [link]="logoLink"
                      [hideZoomButton]="false"
                    >
                    </app-ep-document-view-image>
                  </div>
                  <div class="displayUploadImg mt" *ngIf="isLogoFile == true">
                    <img class="display-image-uploaded" [src]="logoSrc"/>
                  </div>
                </div>
                <div class="row clearfix">
                  <h4 class="sr-title sm-h4 rm-mb">Upload Type logo</h4>
                  <app-file-uploader
                    class="text-center clearfix full-width"
                    [allowedExtensionTypes]="['image']"
                    [isFixedAspectRatio]="true"
                    [isImageCropping]="true"
                    [canSkip]="false"
                    [limit]="1"
                    [uploadDirectly]="true"
                    [isDragable]="true"
                    [title]="'CLICK HERE to upload'"
                    (onCompleteAll)="onCompleteLogoAll($event)"
                  >
                  </app-file-uploader>
                </div>
              </div>
            </div>

            <hr class="mt" />
          </mat-dialog-content>
          <!-- fixed action buttons -->
          <mat-dialog-actions *ngIf="isDone == false">
            <div class="row clearfix text-center full-width">
              <button class="" *ngIf="isModal == true" (click)="closeEvent()" mat-raised-button color="primary">
                Close
              </button>

              <button
                class=""
                *ngIf="isEditMode != true"
                [disabled]="!treatmentGroup.form.valid"
                (click)="save()"
                mat-raised-button
                color="accent"
              >
                Save
                <mat-icon>check</mat-icon>
              </button>
              <button
                class=""
                *ngIf="isEditMode == true"
                [disabled]="!treatmentGroup.form.valid"
                (click)="edit()"
                mat-raised-button
                color="accent"
              >
                Save
                <mat-icon>check</mat-icon>
              </button>
            </div>
          </mat-dialog-actions>

          <mat-dialog-content *ngIf="isDone == true" [ngClass]="{ 'no-modal-height': isModal != true }">
            <div class="container well-done animated fadeInDown" id="step-4">
              <div class="row clearfix text-center">
                <h2 class="sr-title inline-block rm-mt" tabindex="0">
                  <span *ngIf="isEditMode == false">Type created!</span>
                  <span *ngIf="isEditMode == true">Type details updated!</span>
                </h2>
              </div>
              <div class="row clearfix">
                <div class="full-width text-center">
                  <hr class="mt"/>
                  <div class="svg-box">
                    <svg class="circular green-stroke">
                      <circle class="path" cx="75" cy="75" r="50" fill="none" stroke-width="5" stroke-miterlimit="10" />
                    </svg>
                    <svg class="checkmark green-stroke">
                      <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                        <path
                          class="checkmark__check"
                          fill="none"
                          d="M616.306,283.025L634.087,300.805L673.361,261.53"
                        />
                      </g>
                    </svg>
                  </div>

                  <div class="row clearfix">
                    <div class="full-width text-center step-button-row">
                      <button
                        class="step-next"
                        *ngIf="isModal == true"
                        (click)="closeEvent()"
                        mat-raised-button
                        color="primary"
                      >
                        Close
                      </button>

                      <button
                        class="step-next"
                        *ngIf="isModal != true"
                        (click)="list()"
                        mat-raised-button
                        color="accent"
                      >
                        <mat-icon>list</mat-icon> View Type list
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-dialog-content>
        </form>
      </div>
    </div>
  </mat-card>
</div>
