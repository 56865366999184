<div class="rel">
  <div class="card-header primary-gradient-img clearfix confirmModal">
    <button class="btn-close btn-clear mat-button smt white custom-close" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="full-width flex main-title-container">
      <div class="row full-width title-container clearfix">
        <div class="title-container-sub">
          <h2 class="text-left title-header rm-m">
            Header Image
            <span class="subLabel">Explore our Gallery to Customize your landing page or upload your own images.</span>
          </h2>
        </div>
      </div>
    </div>
  </div>
  <div class="clearfix smt smb flex flex-center">
    <mat-button-toggle-group class="btn-group rm-m selectType full-width flex tab"
                             *ngIf="practiceLinks && practiceLinks.length > 0" [(ngModel)]="typeFilter">
      <mat-button-toggle class="full-width" [value]="'default'">
        <span class="badge master" [endVal]="(defaultLinks | lengthOfArray) || 0" countUp></span>
        {{ "BRANDING.Brand Name" | translate | titlecase }}
        <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
      </mat-button-toggle>
      <mat-button-toggle class="full-width" [value]="'user'">
        <span class="badge master" [endVal]="(practiceLinks | lengthOfArray) || 0" countUp></span>
        {{ "KEYWORD.practice" | translate | titlecase}}
        <mat-icon class="fas fa-user-alt"></mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <mat-dialog-content style="height: 60vh !important">
    <div class="full-width" [hidden]="typeFilter != 'default'">
      <div class="pull-left mb flex thrd-width text-center" *ngFor="let d of defaultLinks">
        <mat-card class="editor-card-preview row file animate mat-elevation-z4 full-width" (click)="submitOrPreview(d)">
          <button class="action-button-gallery btn" *ngIf="viewOnly != true"
                  (click)="$event.stopPropagation(); preview(d)" mat-mini-fab color="primary" matTooltip="Preview">
            <mat-icon>visibility</mat-icon>
          </button>

          <div class="full-width landing-page-header-gallery-container">
            <app-ep-document-view-image class="landing-page-header-gallery" [imageLink]="d"
                                        [hideZoomButton]="!viewOnly">
            </app-ep-document-view-image>
          </div>
        </mat-card>
      </div>
    </div>
    <div class="full-width" [hidden]="typeFilter != 'user'">
      <div class="pull-left mb flex thrd-width text-center" *ngFor="let d of practiceLinks">
        <mat-card class="editor-card-preview row file animate mat-elevation-z4 full-width" *ngIf="d.link != 'merchant'"
                  (click)="submitOrPreview(d.link)">
          <button class="action-button-gallery btn" *ngIf="viewOnly != true"
                  (click)="$event.stopPropagation(); preview(d.link)" mat-mini-fab color="primary" matTooltip="Preview">
            <mat-icon>visibility</mat-icon>
          </button>
          <button class="action-button-gallery-remove btn" (click)="$event.stopPropagation(); delete(d.ID)" mat-mini-fab
                  color="accent" matTooltip="Remove">
            <mat-icon>delete</mat-icon>
          </button>

          <div class="full-width landing-page-header-gallery-container">
            <app-ep-document-view-image class="landing-page-header-gallery round-circle" [imageLink]="d.link"
                                        [hideZoomButton]="!viewOnly">
            </app-ep-document-view-image>
          </div>
        </mat-card>

        <mat-card class="editor-card-preview row file animate mat-elevation-z4 full-width"
                  *ngIf="d.link == 'merchant' && merchantID" (click)="submitOrPreview(d.link)">
          <button class="action-button-gallery btn" *ngIf="viewOnly != true"
                  (click)="$event.stopPropagation(); preview(getMerchantLink())" mat-mini-fab color="primary"
                  matTooltip="Preview">
            <mat-icon>visibility</mat-icon>
          </button>

          <div class="full-width landing-page-header-gallery-container">
            <app-ep-document-view-image class="landing-page-header-gallery round-circle" [imageLink]="getMerchantLink()"
                                        [hideZoomButton]="!viewOnly">
            </app-ep-document-view-image>
          </div>
        </mat-card>
      </div>
    </div>
  </mat-dialog-content>
  <div class="drop-button text-center mt full-width">
    <hr/>
    <button class="btn-large pull-right" (click)="upload()" mat-raised-button color="accent">Upload</button>
    <button class="btn-large pull-left" (click)="closeEvent()" mat-raised-button color="primary">close</button>
  </div>
</div>
