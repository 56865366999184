import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export class MessageModalConfig {
  icon?: string;
  classIcon?: string;
  title: string;
  message: string;
  customClass?: string;
  hideAllDefaultButtons?: boolean;
  hideDefaultAcceptButton?: boolean;
  hideDefaultRejectButton?: boolean;
  hideDefaultExtraButton1?: boolean;
  enableDragging?: boolean;
}

@Component({
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.css'],
})
export class MessageModalComponent implements OnInit {
  @Output() acceptButtonClicked: EventEmitter<void> = new EventEmitter();
  @Output() rejectButtonClicked: EventEmitter<void> = new EventEmitter();
  @Output() extra1ButtonClicked: EventEmitter<void> = new EventEmitter();

  @Input() icon = 'info';
  @Input() classIcon = '';
  @Input() title = 'Modal Title';

  @Input() hideCloseButton = false;

  @Input() customClass: null | string = null;
  classNames = {};

  @Input() message: null | string = null;
  @Input() hideAllDefaultButtons = false;
  @Input() enableDragging = false;

  @Input() hideDefaultAcceptButton = false;
  @Input() hideDefaultRejectButton = false;
  @Input() hideDefaultExtraButton1 = true;

  @Input() acceptButtonText = 'Accept';
  @Input() rejectButtonText = 'Reject';
  @Input() extra1ButtonText = 'Extra 1';

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: MessageModalConfig,
    private dialogRef: MatDialogRef<MessageModalComponent>
  ) {
    if (this.data) {
      this.icon = this.data.icon ? this.data.icon : this.icon;
      this.classIcon = this.data.classIcon ? this.data.classIcon : this.classIcon;
      this.title = this.data.title ? this.data.title : this.title;
      this.message = this.data.message ? this.data.message : this.message;
      this.customClass = this.data.customClass ? this.data.customClass : this.customClass;
      this.hideAllDefaultButtons = this.data.hideAllDefaultButtons
        ? this.data.hideAllDefaultButtons
        : this.hideAllDefaultButtons;
      this.hideDefaultAcceptButton = this.data.hideDefaultAcceptButton
        ? this.data.hideDefaultAcceptButton
        : this.hideDefaultAcceptButton;
      this.hideDefaultRejectButton = this.data.hideDefaultRejectButton
        ? this.data.hideDefaultRejectButton
        : this.hideDefaultRejectButton;
      this.hideDefaultExtraButton1 = this.data.hideDefaultExtraButton1
        ? this.data.hideDefaultExtraButton1
        : this.hideDefaultExtraButton1;
      this.enableDragging = this.data.enableDragging ? this.data.enableDragging : this.enableDragging;
    }

    if (this.customClass === null) {
      this.customClass = this.icon;
    }

    const classNamesString = `{"${this.customClass}":true}`;

    this.classNames = JSON.parse(classNamesString);
  }

  ngOnInit() {}

  close() {
    this.dialogRef.close();
  }

  acceptButtonClick() {
    this.acceptButtonClicked.emit();
  }

  rejectButtonClick() {
    this.rejectButtonClicked.emit();
  }

  extra1ButtonClick() {
    this.extra1ButtonClicked.emit();
  }
}
