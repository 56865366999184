/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./root-component.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./root-component.component";
import * as i3 from "@angular/material/dialog";
import * as i4 from "ngx-toastr";
import * as i5 from "ngx-device-detector";
import * as i6 from "@ngx-translate/core";
import * as i7 from "@angular/router";
import * as i8 from "../../../core/settings/shared/settings.service";
import * as i9 from "../../../core/authentication/shared/authentication.service";
import * as i10 from "@agm/core";
import * as i11 from "ng2-cookies/cookie";
import * as i12 from "../../services/facebook-pixel/facebook-pixel.service";
var styles_RootAppComponent = [i0.styles];
var RenderType_RootAppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RootAppComponent, data: {} });
export { RenderType_RootAppComponent as RenderType_RootAppComponent };
export function View_RootAppComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_RootAppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root-component", [], null, null, null, View_RootAppComponent_0, RenderType_RootAppComponent)), i1.ɵdid(1, 2211840, null, 0, i2.RootAppComponent, [i3.MatDialog, i4.ToastrService, i5.DeviceDetectorService, i4.OverlayContainer, i6.TranslateService, i7.Router, i7.ActivatedRoute, i8.SettingsService, i9.AuthenticationService, i10.MapsAPILoader, i11.CookieService, i12.FacebookPixelService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RootAppComponentNgFactory = i1.ɵccf("app-root-component", i2.RootAppComponent, View_RootAppComponent_Host_0, { drift: "drift" }, {}, []);
export { RootAppComponentNgFactory as RootAppComponentNgFactory };
