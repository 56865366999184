import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProductCatalogueItem } from '../../../shared/services/product-catalogue.service';

export interface ViewProductModalConfig {
  industryType: string;
  product: ProductCatalogueItem;
}

@Component({
  selector: 'ipv-view-product',
  templateUrl: './view-product.modal.html',
  styleUrls: ['./view-product.modal.css'],
})
export class ViewProductModalComponent {
  industryType = 'dental';
  product = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ViewProductModalConfig,
    private dialogRef: MatDialogRef<ViewProductModalComponent>
  ) {
    if (data) {
      console.log(data.product);
      this.industryType = data.industryType;
      this.product = data.product;
    }
  }

  close() {
    this.dialogRef.close();
  }
}
