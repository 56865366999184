<div class="note-list-container__note-header-container full-width flex" *ngIf="selectedPatient">
  <div class="note-header-container full-width primary-gradient-img clearfix stacked">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white mr" (click)="close()">
        <mat-icon>clear</mat-icon>
      </button>

      <div class="full-width flex">
        <app-card-picture
          class="note-header-container__profile-picture pull-left mr"
          [hideZoomButton]="false"
          [tableName]="selectedPatient['TableName']"
          [LastModified]="selectedPatient['LastModified']"
          [cardID]="selectedPatient['ID']"
        >
        </app-card-picture>

        <div class="note-header-container__details full-width ml">
          <h2
            class="summary-header patientName text-left rm-m hover-text"
            *ngIf="
              selectedPatient.TableName === 'Prospect' ||
              selectedPatient.TableName === 'CustomersAndProspects' ||
              selectedPatient.TableName === 'Customer'
            "
            [matTooltip]="'View ' + ('KEYWORD.patient' | translate | titlecase) + ' Profile'"
            [matTooltipPosition]="'above'"
            (click)="openPatientProfile(selectedPatient['ID'])"
            style="color: white; cursor: pointer; width: fit-content"
          >
            <strong class="mr"> {{ selectedPatient['CalculatedName'] }} </strong>

            <mat-icon
              class="fas fa-user-secret sml"
              *ngIf="selectedPatient['MarketingConsent.Given'] == '1'"
              style="font-size: 1rem; color: white"
              [matTooltip]="'The ' + ('KEYWORD.patient' | translate | titlecase) + ' consents to receive marketing communication'"
            >
            </mat-icon>

            <mat-icon
              class="fas fa-user-md sml"
              *ngIf="selectedPatient['ReferredCustomer'] == '1'"
              style="font-size: 1rem; color: white"
              [matTooltip]="'This ' + ('KEYWORD.patient' | translate | titlecase) + ' was referred'"
            >
            </mat-icon>
          </h2>

          <h2
            class="summary-header patientName text-left rm-m"
            *ngIf="
              !(
                selectedPatient.TableName === 'Prospect' ||
                selectedPatient.TableName === 'CustomersAndProspects' ||
                selectedPatient.TableName === 'Customer'
              )
            "
          >
            <strong> {{ selectedPatient['CalculatedName'] }}</strong>
          </h2>

          <div class="contacts-container full-width flex">
            <p class="full-width small text-left rm-m" *ngIf="selectedPatient['mobiles.Number']">
              <mat-icon class="fas fa-mobile-alt smr"></mat-icon>
              {{ selectedPatient['mobiles.Number'] | customPhone }}
            </p>

            <p class="full-width small text-left rm-m" *ngIf="selectedPatient['emails.Email']">
              <mat-icon class="fas fa-envelope smr"></mat-icon>
              {{ selectedPatient['emails.Email'] }}
            </p>
          </div>

          <div class="address-container full-width flex" *ngIf="selectedPatient['addresses.Calculated']">
            <p
              class="full-width small address text-left rm-m"
              *ngIf="selectedPatient['addresses.Calculated']"
              [matTooltipPosition]="'after'"
              (click)="displayMap(selectedPatient)"
              matTooltip="View Address"
            >
              <mat-icon class="fas fa-map-marker-alt smr"></mat-icon>
              {{ selectedPatient['addresses.Calculated'] }}
            </p>

            <p
              class="full-width small address text-left rm-m"
              *ngIf="distanceAway"
              [matTooltipPosition]="'before'"
              (click)="displayMap(selectedPatient)"
              matTooltip="View Address"
            >
              <mat-icon class="fas fa-map-marked-alt smr"></mat-icon>
              {{ distanceAway }} km away
            </p>
          </div>

          <div
            class="tag-list full-width"
            *ngIf="
              (selectedPatient['DateOfBirth'] && selectedPatient['DateOfBirth'] !== '0000-00-00') ||
              (tags && tags.length > 0)
            "
          >
            <mat-chip-list class="prospect2-tags pull-left mr" *ngIf="selectedPatient && tags && tags.length > 0">
              <mat-chip *ngFor="let tag of tags | orderBy">
                {{ tag }}
              </mat-chip>

              <mat-chip (click)="openPatientProfile(selectedPatient['ID'])"> ...</mat-chip>
            </mat-chip-list>

            <p
              class="pull-left small text-left rm-m"
              *ngIf="selectedPatient['DateOfBirth'] && selectedPatient['DateOfBirth'] !== '0000-00-00'"
              style="line-height: 22px"
            >
              {{ selectedPatient['DateOfBirth'] }} ({{ selectedPatient['DateOfBirth'] | age }})
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
