<div class="rel">
  <form #resetForm="ngForm">
    <div class="card-header primary-gradient-img clearfix confirmModal">
      <button class="btn-close btn-clear mat-button smt white custom-close" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="full-width flex main-title-container">
        <div class="row  full-width  flex r clearfix">

          <div style="width: 100px ">
            <mat-icon class="fas fa-user-plus pull-left mr main-icon-brush "
              style=" font-size: 2.8rem; margin-top: 10px; width: 70px;">
            </mat-icon>



          </div>

          <div class="title-container-sub  full-width " *ngIf="contactID ">
            <h2 class=" text-left title-header rm-m">
              Modify Staff Member

              <span class="subLabel">Enter the details of your Staff.</span>
            </h2>
          </div>

          <div class="title-container-sub  full-width " *ngIf="!contactID">
            <h2 class=" text-left title-header rm-m">
              New Staff Member

              <span class="subLabel">Enter the details of your Staff, and if we find any
                match, you
                can use that
                profile or create a new one.</span>
            </h2>
          </div>
        </div>
      </div>
    </div>

    <mat-dialog-content>

      <div class="full-width smt " *ngIf="isLoaded==true">

        <mat-form-field class="  full-width " *ngIf="isPromoterOrAdmin==true && !contactID" appearance="outline">
          <mat-label>Practice</mat-label>


          <mat-select [(ngModel)]="merchantID" name="linkFontSize"
            [placeholder]="('KEYWORD.practice' | translate | titlecase)">
            <mat-option *ngFor="let m of merchants" [value]="m.ID">
              {{ m.TradingAs }}
            </mat-option>
          </mat-select>
        </mat-form-field>






        <div class="full-width flex ">
          <div class="full-width mr ">
            <h3 class="sr-title   text-left  full-width  smt smb  "> Details</h3>
            <div class="full-width flex">
              <mat-form-field class=" full-width  mr" appearance="outline">
                <mat-label>First name</mat-label>
                <input [(ngModel)]="firstName" name="Label1" matInput placeholder="First name" required />
              </mat-form-field>

              <mat-form-field class="full-width mr" appearance="outline">
                <mat-label>Middle name</mat-label>
                <input [(ngModel)]="middleName" name="Label2" matInput placeholder="Middle name" />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Last name</mat-label>
                <input [(ngModel)]="lastName" name="Label3" matInput placeholder="Last name" required />
              </mat-form-field>
            </div>

            <mat-form-field class=" full-width  " appearance="outline">
              <mat-label>Type of employment</mat-label>
              <mat-select class="full-width " [(ngModel)]="contactType" name="contactType" placeholder="Type of employment">
                <mat-option *ngFor="let contactType of contactTypes" [value]="contactType.Code">
                  {{ contactType.Label }}
                </mat-option>
              </mat-select>
            </mat-form-field>


            <div class="full-width flex">
              <mat-form-field class=" full-width  mr" appearance="outline">
                <mat-label>Gender</mat-label>
                <mat-select class="full-width mr" [(ngModel)]="gender" name="gender" placeholder="Gender">
                  <mat-option *ngFor="let gender of genders" [value]="gender.Code">
                    {{ gender.Label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Title</mat-label>
                <mat-select class="full-width" [(ngModel)]="title" name="title" placeholder="Title">
                  <mat-option *ngFor="let title of titles" [value]="title.Label">
                    {{ title.Label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>


          </div>

          <mat-card class="qtr-width row blob-for-sub-plain-container-thumbnail-contact sml smr"
            style="         margin-top: 0px;     padding: 10px;       background: #f1f1f1;   ">
            <div class="full-width">
              <app-ep-document-view-image class="squareImg squareImg-social-contact"
                *ngIf="!postPictureLinkThumbnail && postPictureThumbnail" [hideZoomButton]="false" [defaultPicture]="
                  'url(https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/user_149071.png)'
                " [link]="postPictureThumbnail">
              </app-ep-document-view-image>
              <app-ep-document-view-image class="squareImg squareImg-social-contact"
                *ngIf="postPictureLinkThumbnail && !postPictureThumbnail" [hideZoomButton]="false" [defaultPicture]="
                  'url(https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/user_149071.png)'
                " [link]="postPictureLinkThumbnail">
              </app-ep-document-view-image>
              <app-ep-document-view-image class="squareImg squareImg-social-contact"
                *ngIf="!postPictureLinkThumbnail && !postPictureThumbnail" [hideZoomButton]="false" [defaultPicture]="
                  'url(https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/user_149071.png)'
                ">
              </app-ep-document-view-image>
            </div>
            <div class="clearfix full-width">
              <app-file-uploader class="text-center uploader-contact-id clearfix full-width"
                [allowedExtensions]="allowExtensions" style="            margin-top: 0px;        "
                [aspectRatioType]="'full'" [maxFileSize]="2" [isImageCropping]="true" [canSkip]="true" [limit]="1"
                [uploadDirectly]="true" [isDragable]="true" [title]="'Upload picture'"
                (onCompleteAll)="onCompleteThumbnail($event)">
              </app-file-uploader>
            </div>
          </mat-card>

        </div>
        <h3 class="sr-title   text-left  full-width  smt smb  "> Contact</h3>
        <div class="full-width flex  ">

          <app-phone-input [clear]="false" class="full-width mr" [phoneNumber]="mobile" [label]="'Mobile'"
            [required]="isMobileRequired" (validate)="validateMobile($event)" (getPhone)="getMobile($event)">
          </app-phone-input>
          <app-phone-input class="full-width mr" [clear]="false" [phoneNumber]="homePhone" [isLandLine]="true"
            [label]="'Home Phone'" [required]="false" (validate)="validateHomePhone($event)"
            (getPhone)="getHomePhone($event)">
          </app-phone-input>
          <app-phone-input class="full-width" [clear]="false" [phoneNumber]="workPhone" [isLandLine]="true"
            [label]="'Work Phone'" [required]="false" (validate)="validateWorkPhone($event)"
            (getPhone)="getWorkPhone($event)">
          </app-phone-input>
        </div>

        <div class="full-width flex ">
          <app-email-input [clear]="false" class="full-width mr" [email]="email" [label]="'Email'" [required]="isEmailRequired"
            (validate)="validateEmail($event)" (getEmail)="getEmail($event)">
          </app-email-input>

        </div>





        <h3 class="sr-title   text-left  full-width  smt smb  "> Address</h3>

        <div class="row clearfix flex  full-width">

          <app-address-input class="full-width" [displayMap]="false" [canClose]="false" [noIcons]="true"
            (getAdress)="getAddress($event)" (validate)="isAddressValidEvent($event)" [postCode]="postCode"
            [state]="state" [suburb]="suburb" [streetNumber]="streetNumber" [streetName]="streetName"
            [unitNumber]="unitNumber" [streetType]="streetType" [latitude]="latitude" [longitude]="longitude"
            [isRequired]="false">
          </app-address-input>

        </div>
      </div>
    </mat-dialog-content>

    <hr>
    <div class="drop-button text-center mt  full-width">





      <button mat-raised-button (click)="save()"
        [disabled]="!resetForm.form.valid || isMobileValid!=true  || isAddressValid!=true || isEmailValid!=true || isWorkPhoneValid!=true || isHomePhoneValid!=true  "
        class="btn-large  pull-right" color="accent">Submit</button>

      <button mat-raised-button (click)="closeEvent()" class="btn-large  pull-left" color="primary">close</button>
    </div>
  </form>
</div>
