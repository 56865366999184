import { Component, EventEmitter, OnInit } from '@angular/core';
import { UtilsService } from '../../../shared/services/utils.service';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-healthfund-intro-modal',
  templateUrl: './healthfund-intro-modal.component.html',
  styleUrls: ['./healthfund-intro-modal.component.css'],
})
export class HealthfundIntroModalComponent implements OnInit {
  showOnHealthfund = JSON.parse(localStorage.getItem('showOnHealthfund'));

  closeModal = new EventEmitter();

  isModuleTutorialsActive = Settings.global['isModuleTutorialsActive'];

  constructor(private utilService: UtilsService) {}

  ngOnInit() {
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleTutorialsActive = access['isModuleTutorialsActive'];
      }
    });
  }

  doNotShow() {
    localStorage.setItem('showOnHealthfund', JSON.stringify(this.showOnHealthfund));

    this.closeModal.emit(true);
  }

  close() {
    this.closeModal.emit(true);
  }
}
