import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthenticationService } from '../../core/authentication/shared/authentication.service';
import { SharedModule } from '../../shared/shared.module';
import { FundersRevenueReportPage } from './funders-revenue-report/funders-revenue-report.page';
import { FundersRevenueReportAccruedComponent } from './funders-revenue-report/shared/components/funders-revenue-report-accrued/funders-revenue-report-accrued.component';
import { FundersRevenueReportActualComponent } from './funders-revenue-report/shared/components/funders-revenue-report-actual/funders-revenue-report-actual.component';
import { FundersRevenueReportSummaryComponent } from './funders-revenue-report/shared/components/funders-revenue-report-summary/funders-revenue-report-summary.component';
import { FundersRoutingModule } from './funders-routing.module';

@NgModule({
  imports: [CommonModule, FundersRoutingModule, SharedModule],
  declarations: [
    FundersRevenueReportPage,
    FundersRevenueReportActualComponent,
    FundersRevenueReportAccruedComponent,
    FundersRevenueReportSummaryComponent,
  ],
  exports: [FundersRevenueReportPage],
  providers: [AuthenticationService],
})
export class FundersModule {}
