import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { DentalXrayScanDetectionItem } from '../../../../../feature/ai-scans/shared/types/dental-xray.type';

@Component({
  selector: 'sr-tooth-detection-dialog',
  templateUrl: './tooth-detection-dialog.component.html',
  styleUrls: ['./tooth-detection-dialog.component.css'],
})
export class ToothDetectionDialogComponent implements OnInit {
  @Input() toothName: number;
  @Input() apicalDetections: DentalXrayScanDetectionItem[];
  @Input() cariesDetections: DentalXrayScanDetectionItem[];
  @Input() restorationsDetection: DentalXrayScanDetectionItem[];

  closeEvent = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  closeModal() {
    this.closeEvent.emit(true);
  }
}
