<mat-card class="card row clearfix">
  <div class="row clearfix cardheader primary-gradient-img inModal">
    <!-- close button -->
    <button
      class="btn-close btn-clear white pull-right mat-button"
      *ngIf="isModal == true"
      (click)="closeModalEvent()"
      matDialogClose
    >
      <mat-icon>clear</mat-icon>
    </button>

    <!-- title txt -->
    <div class="row titleArea clearfix">
      <h2 class="summary-header rm-m white">
        <span class="subLabel" *ngIf="title">{{ title }}</span>
      </h2>
    </div>
  </div>
  <mat-dialog-content class="full-width">
    <div class="full-width" *ngIf="!aspectRatioType">
      <image-cropper
        class="min-height"
        *ngIf="
          imageFileEvent && !imageFile && isFixedAspectRatio != true && isCover != true && isTemplateThumbnail == true
        "
        [roundCropper]="roundCropper"
        [backgroundColor]="backgroundColor"
        [imageFile]="imageFile"
        [imageChangedEvent]="imageFileEvent"
        [outputType]="'file'"
        [maintainAspectRatio]="true"
        [aspectRatio]="14 / 9"
        [onlyScaleDown]="onlyScaleDown"
        [resizeToWidth]="resizeToWidth"
        (imageCropped)="imageCroppedEvent($event)"
        (imageLoaded)="imageLoadedEvent($event)"
        (cropperReady)="cropperReadyEvent($event)"
        (loadImageFailed)="loadImageFailedEvent($event)"
        format="png"
      ></image-cropper>

      <image-cropper
        class="min-height"
        *ngIf="imageFile && isFixedAspectRatio != true && isCover != true && isTemplateThumbnail == true"
        [roundCropper]="roundCropper"
        [imageFile]="imageFile"
        [backgroundColor]="backgroundColor"
        [imageChangedEvent]="imageFileEvent"
        [outputType]="'file'"
        [maintainAspectRatio]="true"
        [aspectRatio]="14 / 9"
        [onlyScaleDown]="onlyScaleDown"
        [resizeToWidth]="resizeToWidth"
        (imageCropped)="imageCroppedEvent($event)"
        (imageLoaded)="imageLoadedEvent($event)"
        (cropperReady)="cropperReadyEvent($event)"
        (loadImageFailed)="loadImageFailedEvent($event)"
        format="png"
      ></image-cropper>

      <image-cropper
        class="min-height"
        *ngIf="
          imageFileEvent && !imageFile && isFixedAspectRatio != true && isCover == true && isTemplateThumbnail != true
        "
        [roundCropper]="roundCropper"
        [backgroundColor]="backgroundColor"
        [imageFile]="imageFile"
        [imageChangedEvent]="imageFileEvent"
        [outputType]="'file'"
        [maintainAspectRatio]="true"
        [aspectRatio]="2 / 1"
        [onlyScaleDown]="onlyScaleDown"
        [resizeToWidth]="resizeToWidth"
        (imageCropped)="imageCroppedEvent($event)"
        (imageLoaded)="imageLoadedEvent($event)"
        (cropperReady)="cropperReadyEvent($event)"
        (loadImageFailed)="loadImageFailedEvent($event)"
        format="png"
      ></image-cropper>

      <image-cropper
        class="min-height"
        *ngIf="imageFile && isFixedAspectRatio != true && isCover == true && isTemplateThumbnail != true"
        [roundCropper]="roundCropper"
        [imageFile]="imageFile"
        [backgroundColor]="backgroundColor"
        [imageChangedEvent]="imageFileEvent"
        [outputType]="'file'"
        [maintainAspectRatio]="true"
        [aspectRatio]="2 / 1"
        [onlyScaleDown]="onlyScaleDown"
        [resizeToWidth]="resizeToWidth"
        (imageCropped)="imageCroppedEvent($event)"
        (imageLoaded)="imageLoadedEvent($event)"
        (cropperReady)="cropperReadyEvent($event)"
        (loadImageFailed)="loadImageFailedEvent($event)"
        format="png"
      ></image-cropper>

      <image-cropper
        class="min-height"
        *ngIf="
          imageFileEvent && !imageFile && isFixedAspectRatio == true && isCover != true && isTemplateThumbnail != true
        "
        [roundCropper]="roundCropper"
        [backgroundColor]="backgroundColor"
        [imageFile]="imageFile"
        [imageChangedEvent]="imageFileEvent"
        [outputType]="'file'"
        [maintainAspectRatio]="true"
        [aspectRatio]="1 / 1"
        [onlyScaleDown]="onlyScaleDown"
        [resizeToWidth]="resizeToWidth"
        (imageCropped)="imageCroppedEvent($event)"
        (imageLoaded)="imageLoadedEvent($event)"
        (cropperReady)="cropperReadyEvent($event)"
        (loadImageFailed)="loadImageFailedEvent($event)"
        format="png"
      ></image-cropper>

      <image-cropper
        class="min-height"
        *ngIf="imageFile && isFixedAspectRatio == true && isCover != true && isTemplateThumbnail != true"
        [roundCropper]="roundCropper"
        [imageFile]="imageFile"
        [backgroundColor]="backgroundColor"
        [imageChangedEvent]="imageFileEvent"
        [outputType]="'file'"
        [maintainAspectRatio]="true"
        [aspectRatio]="1 / 1"
        [onlyScaleDown]="onlyScaleDown"
        [resizeToWidth]="resizeToWidth"
        (imageCropped)="imageCroppedEvent($event)"
        (imageLoaded)="imageLoadedEvent($event)"
        (cropperReady)="cropperReadyEvent($event)"
        (loadImageFailed)="loadImageFailedEvent($event)"
        format="png"
      ></image-cropper>

      <image-cropper
        class="min-height"
        *ngIf="
          imageFileEvent && !imageFile && isFixedAspectRatio != true && isCover != true && isTemplateThumbnail != true
        "
        [roundCropper]="roundCropper"
        [backgroundColor]="backgroundColor"
        [imageChangedEvent]="imageFileEvent"
        [outputType]="'file'"
        [imageFile]="imageFile"
        [maintainAspectRatio]="false"
        [onlyScaleDown]="onlyScaleDown"
        [resizeToWidth]="resizeToWidth"
        (imageCropped)="imageCroppedEvent($event)"
        (imageLoaded)="imageLoadedEvent($event)"
        (cropperReady)="cropperReadyEvent($event)"
        (loadImageFailed)="loadImageFailedEvent($event)"
        format="png"
      ></image-cropper>

      <image-cropper
        class="min-height"
        *ngIf="imageFile && isFixedAspectRatio != true && isCover != true && isTemplateThumbnail != true"
        [roundCropper]="roundCropper"
        [imageChangedEvent]="imageFileEvent"
        [backgroundColor]="backgroundColor"
        [outputType]="'file'"
        [imageFile]="imageFile"
        [maintainAspectRatio]="false"
        [onlyScaleDown]="onlyScaleDown"
        [resizeToWidth]="resizeToWidth"
        (imageCropped)="imageCroppedEvent($event)"
        (imageLoaded)="imageLoadedEvent($event)"
        (cropperReady)="cropperReadyEvent($event)"
        (loadImageFailed)="loadImageFailedEvent($event)"
        format="png"
      ></image-cropper>
    </div>

    <div class="full-width" *ngIf="aspectRatioType">
      <image-cropper
        class="min-height"
        *ngIf="imageFile && aspectRatioType == 'qtr'"
        [roundCropper]="roundCropper"
        [backgroundColor]="backgroundColor"
        [imageFile]="imageFile"
        [imageChangedEvent]="imageFileEvent"
        [outputType]="'file'"
        [maintainAspectRatio]="true"
        [aspectRatio]="4 / 1"
        [onlyScaleDown]="onlyScaleDown"
        [resizeToWidth]="resizeToWidth"
        (imageCropped)="imageCroppedEvent($event)"
        (imageLoaded)="imageLoadedEvent($event)"
        (cropperReady)="cropperReadyEvent($event)"
        (loadImageFailed)="loadImageFailedEvent($event)"
        format="png"
      ></image-cropper>

      <image-cropper
        class="min-height"
        *ngIf="imageFile && aspectRatioType == 'half'"
        [roundCropper]="roundCropper"
        [backgroundColor]="backgroundColor"
        [imageFile]="imageFile"
        [imageChangedEvent]="imageFileEvent"
        [outputType]="'file'"
        [maintainAspectRatio]="true"
        [aspectRatio]="2 / 1"
        [onlyScaleDown]="onlyScaleDown"
        [resizeToWidth]="resizeToWidth"
        (imageCropped)="imageCroppedEvent($event)"
        (imageLoaded)="imageLoadedEvent($event)"
        (cropperReady)="cropperReadyEvent($event)"
        (loadImageFailed)="loadImageFailedEvent($event)"
        format="png"
      ></image-cropper>

      <image-cropper
        class="min-height"
        *ngIf="imageFile && aspectRatioType == 'third'"
        [roundCropper]="roundCropper"
        [backgroundColor]="backgroundColor"
        [imageFile]="imageFile"
        [imageChangedEvent]="imageFileEvent"
        [outputType]="'file'"
        [maintainAspectRatio]="true"
        [aspectRatio]="3 / 1"
        [onlyScaleDown]="onlyScaleDown"
        [resizeToWidth]="resizeToWidth"
        (imageCropped)="imageCroppedEvent($event)"
        (imageLoaded)="imageLoadedEvent($event)"
        (cropperReady)="cropperReadyEvent($event)"
        (loadImageFailed)="loadImageFailedEvent($event)"
        format="png"
      ></image-cropper>

      <image-cropper
        class="min-height"
        *ngIf="imageFile && aspectRatioType == 'full'"
        [roundCropper]="roundCropper"
        [backgroundColor]="backgroundColor"
        [imageFile]="imageFile"
        [imageChangedEvent]="imageFileEvent"
        [outputType]="'file'"
        [maintainAspectRatio]="true"
        [aspectRatio]="1 / 1"
        [onlyScaleDown]="onlyScaleDown"
        [resizeToWidth]="resizeToWidth"
        (imageCropped)="imageCroppedEvent($event)"
        (imageLoaded)="imageLoadedEvent($event)"
        (cropperReady)="cropperReadyEvent($event)"
        (loadImageFailed)="loadImageFailedEvent($event)"
        format="png"
      ></image-cropper>

      <image-cropper
        class="min-height"
        *ngIf="imageFile && aspectRatioType == 'two-third'"
        [roundCropper]="roundCropper"
        [backgroundColor]="backgroundColor"
        [imageFile]="imageFile"
        [imageChangedEvent]="imageFileEvent"
        [outputType]="'file'"
        [maintainAspectRatio]="true"
        [aspectRatio]="3 / 2"
        [onlyScaleDown]="onlyScaleDown"
        [resizeToWidth]="resizeToWidth"
        (imageCropped)="imageCroppedEvent($event)"
        (imageLoaded)="imageLoadedEvent($event)"
        (cropperReady)="cropperReadyEvent($event)"
        (loadImageFailed)="loadImageFailedEvent($event)"
        format="png"
      ></image-cropper>
    </div>
  </mat-dialog-content>
  <div class="full-width text-right clearfix"></div>
  <div class="full-width clearfix">
    <hr/>

    <button class="pull-left" *ngIf="isModal == true" (click)="closeModalEvent()" mat-raised-button color="primary">
      Cancel
    </button>

    <div class="width-position pull-left ml flex" *ngIf="croppedWidth || croppedHeight">
      <mat-icon class="fas fa-crop"></mat-icon>
      <p>{{ croppedWidth }} x {{ croppedHeight }}</p>
    </div>

    <button class="pull-right mr" [disabled]="!file" (click)="sendResult()" mat-raised-button color="accent">
      Crop image
      <mat-icon>check</mat-icon>
    </button>

    <button
      class="pull-right mr"
      *ngIf="canSkip == true && imageFile && keepOriginal == true"
      (click)="skip()"
      mat-raised-button
      color="warn"
    >
      Keep original
      <mat-icon> fullscreen</mat-icon>
    </button>

    <button class="pull-right mr" (click)="rotateLeft()" mat-mini-fab color="primary" matTooltip="Rotate Left">
      <mat-icon>rotate_left</mat-icon>
    </button>

    <button class="pull-right mr" (click)="rotateRight()" mat-mini-fab color="primary" matTooltip="Rotate Right">
      <mat-icon>rotate_right</mat-icon>
    </button>

    <button class="pull-right mr" (click)="flipH()" mat-mini-fab color="primary" matTooltip="flip Horizontally">
      <mat-icon>text_rotation_none</mat-icon>
    </button>

    <button class="pull-right mr ml" (click)="flipV()" mat-mini-fab color="primary" matTooltip="flip Vertically">
      <mat-icon>text_rotate_up</mat-icon>
    </button>

    <!-- <button mat-mini-fab color="primary" class=" ml pull-right mr round-cropper " [disabled]="!roundCropper"
      matTooltip="Square Cropper" (click)="squareCropper()">
      <mat-icon>crop_square</mat-icon>
    </button>



    <button mat-mini-fab color="primary" class=" pull-right mr round-cropper " [disabled]="roundCropper"
      matTooltip="Round Cropper" (click)="circleCropper()">
      <mat-icon>panorama_fish_eye</mat-icon>
    </button> -->
  </div>
</mat-card>
