import { animate, state, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { DocumentUrlViewComponent } from '../../../shared/components/document-url-view/document-url-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { LookupService } from '../../../shared/services/lookup.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { CustomerProspectService } from '../../customer-prospect/shared/customerProspect.service';
import { MessageTemplateCreateEditComponent } from '../../default-message/message-template-create-edit/message-template-create-edit.component';
import { DefaultMessageService } from '../../default-message/shared/default-message.service';
import { DentistViewModalComponent } from '../../dentist/dentist-view-modal/dentist-view-modal.component';
import { DentistService } from '../../dentist/shared/dentist.service';
import { InvitationCustomerProspectEditComponent } from '../../invitation/invitation-customer-prospect-edit/invitation-customer-prospect-edit.component';
import { InvitationLookupComponent } from '../../invitation/invitation-lookup/invitation-lookup.component';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { SmsConversationComponent } from '../../message/sms-conversation/sms-conversation.component';
import { SmsPromoterComponent } from '../../message/sms-promoter/sms-promoter.component';
import { MarketingService } from '../shared/marketing.service';

@Component({
  selector: 'app-marketing-google-review-invitation-create',
  templateUrl: './marketing-google-review-invitation-create.component.html',
  styleUrls: ['./marketing-google-review-invitation-create.component.css'],
  providers: [CustomPhonePipe],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class MarketingGoogleReviewInvitationCreate implements OnInit {
  wizard: WizardComponent;

  @ViewChild('wizard', { static: false }) set wizardContent(content: WizardComponent) {
    if (content) {
      this.wizard = content;
    }
  }

  @Input() merchantID = localStorage.getItem('selectedMerchant');

  @Input()
  displayLookup = true;
  dateOfBirth;
  _dateOfBirth;
  lookupPage = true;

  @Input() title = 'An invitation will be sent to patient  ';

  @Output() close = new EventEmitter();

  @Output() getResult = new EventEmitter();

  @Input() patientID;
  invitationID;
  util = new UtilsClass();

  merchants = [];
  contacts = [];

  genders = [];
  titles = [];
  defaultMessageLists = [];

  isAdminOrPromoter = false;
  isFinDMatch = false;
  message;

  sendSMS = false;
  merchant;
  contact;

  isModal = false;
  selectedExistingPatientID;
  promoter;
  patient = {
    firstName: null,
    tableName: null,
    title: null,
    lastName: null,
    middleName: null,
    genderCode: null,
    mobile: null,
    homePhone: null,
    workPhone: null,
    email: null,
  };
  isMobileValid = false;
  isHomePhoneValid = false;
  isWorkPhoneValid = false;
  isEmailValid = false;

  isModuleCustomMessages = Settings.global['isModuleCustomMessagesActive'];

  complete = false;
  stopFlying = false;
  flyAway = false;

  selectedMerchant;
  selectedSender;

  rawMessage;
  sessionType = 'merchant';

  clearDate = new EventEmitter();
  clearEmail = new EventEmitter();
  clearMobile = new EventEmitter();
  clearHomePhone = new EventEmitter();
  clearWorkPhone = new EventEmitter();

  start = 1;

  constructor(
    private marketingService: MarketingService,
    private utilService: UtilsService,
    private merchantService: MerchantService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private dentistService: DentistService,
    private customerProspectService: CustomerProspectService,
    private authenticationService: AuthenticationService,
    private lookupService: LookupService,
    private customePhonePipe: CustomPhonePipe,
    private defaultMessageSerivce: DefaultMessageService,
    private location: Location,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.merchantID) {
        this.merchantID = data.merchantID;

        this.isModal = true;
      }
      if (data.patientID) {
        this.patientID = data.patientID;

        this.lookupPage = false;
        this.isModal = true;
      }

      if (data.title) {
        this.title = data.title;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['patientID']) {
        this.patientID = params['patientID'];

        this.lookupPage = false;
      }
      this.authenticationService.getPromoter().subscribe((promoter) => {
        if (promoter && promoter.data) {
          this.promoter = promoter.data;
        } else if (promoter) {
          this.promoter = promoter;
        }
      });
      this.authenticationService.getCurrentPractice().subscribe((currentPractice) => {
        if (currentPractice) {
          this.selectedMerchant = currentPractice;
          if (this.selectedMerchant && this.selectedMerchant.ID) {
            this.merchantID = this.selectedMerchant.ID;
          }
        }

        this.authenticationService.getSessionType().subscribe((sessionType) => {
          if (sessionType) {
            this.sessionType = sessionType;
          }

          this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
            this.isAdminOrPromoter = res;

            this.utilService.getCurrentAccess().subscribe((access) => {
              if (access) {
                this.isModuleCustomMessages = access['isModuleCustomMessagesActive'];

                this.lookupService.getLookup('CodeLookup', 'Gender').subscribe((data) => {
                  this.genders = data;
                });
                this.lookupService.getLookup('SimpleLookup', 'Salutation').subscribe((data) => {
                  this.titles = data;
                });

                if (this.isAdminOrPromoter == true) {
                  const p = {
                    fields: 'ID,Name,FirstName,TradingAs,CalculatedName,Status',
                  };

                  this.merchantService.getList(p, this.isAdminOrPromoter).subscribe((res) => {
                    if (res) {
                      this.merchants = res;

                      if (this.merchantID) {
                        for (let i = 0; i < this.merchants.length; i++) {
                          if (
                            this.merchants[i] &&
                            this.merchants[i]['ID'] &&
                            this.merchants[i]['ID'] == this.merchantID
                          ) {
                            this.merchant = this.merchants[i];
                          }
                        }
                      }
                    }
                  });
                }

                const __p = {
                  fields: 'ID,Name,FirstName,TradingAs,CalculatedName',
                  merchantID: this.merchantID,
                };

                this.dentistService.getList(__p, this.isAdminOrPromoter).subscribe((res) => {
                  if (res && res.length > 0) {
                    this.contacts = res;

                    this.authenticationService.getCurrentDentist().subscribe((res) => {
                      if (res) {
                        this.selectedSender = res;

                        for (let i = 0; i < this.contacts.length; i++) {
                          if (
                            this.contacts[i] &&
                            this.contacts[i]['ID'] &&
                            this.selectedSender &&
                            this.selectedSender['ID'] &&
                            this.contacts[i]['ID'] == this.selectedSender['ID']
                          ) {
                            this.contact = this.contacts[i];
                          }
                        }
                      }
                    });
                  }
                });

                this.getDefaultMessageList();

                if (this.patientID) {
                  const p = {
                    fields:
                      'TableName,ID,Name,FirstName,DateOfBirth,MiddleName,Name,CalculatedName,Salutation,Gender.Code,Gender.Label,phones.Number,mobiles.Number,emails.Email',
                  };

                  this.customerProspectService
                    .getCustomerProspectDetails(this.patientID, p, this.sessionType)
                    .subscribe((res) => {
                      this.displayLookup = false;
                      this.isFinDMatch = true;
                      this.buildPatient(res);

                      this.start = 0;
                    });
                }
              }
            });
          });
        });
      });
    });
  }

  buildPatient(patient) {
    if (patient['Gender.Code']) {
      this.patient.genderCode = patient['Gender.Code'];
    }

    if (patient['Salutation']) {
      this.patient.title = patient['Salutation'];
    }

    if (patient['FirstName']) {
      this.patient.firstName = patient['FirstName'];
    }

    if (patient['tableName']) {
      this.patient.tableName = patient['tableName'];
    }

    if (patient['TableName']) {
      this.patient.tableName = patient['TableName'];
    }

    if (patient['dateOfBirth'] && patient['dateOfBirth'] != '0000-00-00') {
      this.dateOfBirth = patient['dateOfBirth'];
      this._dateOfBirth = patient['dateOfBirth'];
    }

    if (patient['DateOfBirth'] && patient['DateOfBirth'] != '0000-00-00') {
      this.dateOfBirth = patient['DateOfBirth'];
      this._dateOfBirth = patient['DateOfBirth'];
    }

    if (patient['MiddleName']) {
      this.patient.middleName = patient['MiddleName'];
    }

    if (patient['Name']) {
      this.patient.lastName = patient['Name'];
    }

    if (patient['mobiles.Number']) {
      this.patient.mobile = patient['mobiles.Number'];
    }

    if (patient['phone.Home.Number']) {
      this.patient.homePhone = patient['phone.Home.Number'];
    }

    if (patient['phone.Work.Number']) {
      this.patient.workPhone = patient['phone.Work.Number'];
    }

    if (patient['emails.Email']) {
      this.patient.email = patient['emails.Email'];
    }

    if (patient['ID']) {
      this.patientID = patient['ID'];
    }
  }

  changeSender() {
    if (this.contact && this.contact.ID) {
      this.dentistService.getContactDetails(this.contact.ID, {}, this.sessionType).subscribe((res) => {
        if (res) {
          this.selectedSender = res;
          this.message = this.convertMessage();
        }
      });
    }
  }

  changeMerchant() {
    if (this.merchant && this.merchant.ID && this.isAdminOrPromoter == true) {
      this.merchantID = this.merchant.ID;

      this.merchantService.getMerchantDetails(this.merchantID, {}, this.sessionType).subscribe((res) => {
        if (res) {
          this.selectedMerchant = res;

          this.message = this.convertMessage();
        }
      });

      const p = {
        fields: 'ID,Name,FirstName,TradingAs,CalculatedName',
        merchantID: this.merchant.ID,
      };
      this.dentistService.getList(p, this.isAdminOrPromoter).subscribe((res) => {
        if (res) {
          this.contacts = res;
          this.contact = res[0];
        }
      });

      this.getDefaultMessageList();
    }
  }

  viewMerchant() {
    if (this.merchant && this.merchant['ID']) {
      const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
        data: this.merchant['ID'],
        width: '600px',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewDentist() {
    const ref = RootAppComponent.dialog.open(DentistViewModalComponent, {
      data: this.selectedSender['ID'],
      width: '550px',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  closeEvent() {
    this.close.emit(true);
  }

  getEmail(p) {
    this.patient['email'] = p;
  }

  getMobile(p) {
    this.patient['mobile'] = p;
  }

  getHomePhone(p) {
    this.patient['homePhone'] = p;
  }

  getWorkPhone(p) {
    this.patient['workPhone'] = p;
  }

  validateMobile(v) {
    this.isMobileValid = v;
  }

  validateHomePhone(v) {
    this.isHomePhoneValid = v;
  }

  validateWorkPhone(v) {
    this.isWorkPhoneValid = v;
  }

  validateEmail(v) {
    this.isEmailValid = v;
  }

  sendResult() {
    if (this.contact && this.contact.ID) {
      const p: any = {
        patientID: this.patientID,
        cardID: this.patientID,
        message: this.message,
        contactID: this.contact.ID,
        merchantID: this.merchantID,

        sendSMS: this.sendSMS,
      };
      if (this.patient) {
        if (this.patient.firstName) {
          p.firstName = this.patient.firstName;
        }

        if (this.patient.title) {
          p.title = this.patient.title;
        }

        if (this.patient.lastName) {
          p.lastName = this.patient.lastName;
        }

        if (this.patient.middleName) {
          p.middleName = this.patient.middleName;
        }

        if (this.patient.genderCode) {
          p.genderCode = this.patient.genderCode;
        }

        if (this.patient.mobile) {
          p.mobile = this.patient.mobile;
        }

        if (this.patient.homePhone) {
          p.homePhone = this.patient.homePhone;
        }

        if (this.patient.workPhone) {
          p.workPhone = this.patient.workPhone;
        }

        if (this.patient.email) {
          p.email = this.patient.email;
        }
      }

      this.marketingService.inviteGoogleReview(p, this.isAdminOrPromoter).subscribe((res) => {
        if (res) {
          this.getResult.emit(res);

          if (res && res.Prospect_key) {
            this.patientID = res.Prospect_key;
          }

          if (res.ID) {
            this.invitationID;
          }

          NotifyAppComponent.displayToast(
            'success',
            'Successful operation',
            'The patient has been successfully invited '
          );

          this.wizard.goToNextStep();
          this.complete = true;
        }
      });
    }
  }

  openPatientLookUpDialog() {
    const ref = RootAppComponent.dialog.open(InvitationLookupComponent, {
      data: {
        merchantID: this.merchantID,
        onlyMyAccount: true,
        useStrict: true,
      },
      width: '600px',
    });
    const sub = ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
    ref.componentInstance.detailedView.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.patientChat.subscribe((res) => {
      if (res && res.cardID) {
        if (res.isPromoterOrAdmin == true) {
          const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true,
            },
            panelClass: 'noCard',
            width: '500px',
          });
          ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });

          ref2.componentInstance.getResult.subscribe((data) => {
            if (data) {
              const ref3 = RootAppComponent.dialog.open(SmsConversationComponent, {
                data,
                panelClass: 'bigger-screen',
                width: '70%',
                height: '95vh',
              });
              const sub = ref3.componentInstance.close.subscribe((data) => {
                ref3.close();
              });
            }

            ref2.close();
          });
        } else {
          const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true,
            },
            panelClass: 'bigger-screen',
            width: '70%',
            height: '95vh',
          });
          const sub = ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });
        }
      }
    });
    ref.componentInstance.getSelectedPatient.subscribe((res) => {
      if (res) {
        if (res.ID) {
          this.patientID = res.ID;
          this.buildPatient(res);
          this.isFinDMatch = true;
          ref.close();
        }
      }
    });
  }

  getDefaultMessageList(merchantID = null) {
    if (this.isModuleCustomMessages == true) {
      if (this.isAdminOrPromoter == false || merchantID == null) {
        const payload = {
          purposeCode: 'GoogleRev',
          orderBy: 'Label',
          onlyActive: true,
        };
        this.defaultMessageSerivce.getDefaultMessageList(payload).subscribe((res) => {
          if (res && res.length > 0) {
            if (this.defaultMessageLists && this.defaultMessageLists.length > 0) {
              this.defaultMessageLists = [];
            }

            for (let i = 0; i < res.length; i++) {
              const r = {
                ID: res[i]['ID'],
                label: res[i]['Label'],
                value: res[i]['Content'],
              };

              this.defaultMessageLists.push(r);
            }
          }
        });
      } else {
        const payload = {
          onlyActive: true,
          purposeCode: 'GoogleRev',
          orderBy: 'Label',
          merchantID: this.merchantID,
        };
        this.defaultMessageSerivce.getDefaultMessageListGlobal(payload).subscribe((res) => {
          if (res && res.length > 0) {
            if (this.defaultMessageLists && this.defaultMessageLists.length > 0) {
              this.defaultMessageLists = [];
            }

            for (let i = 0; i < res.length; i++) {
              const r = {
                ID: res[i]['ID'],
                label: res[i]['Label'],
                value: res[i]['Content'],
              };

              this.defaultMessageLists.push(r);
            }
          }
        });
      }
    }
  }

  getPatientFromLookup(patient) {
    if (patient && patient.ID) {
      this.lookupPage = false;
      this.patientID = patient.ID;

      this.patient = patient;
      this.message = this.convertMessage();
    }
  }

  backToLookup() {
    this.patientID = null;
    this.patient = null;
    this.lookupPage = true;
  }

  addNewMessage() {
    const ref = RootAppComponent.dialog.open(MessageTemplateCreateEditComponent, {
      data: {
        merchantID: this.merchantID,
        purposeCode: 'MerMHInv',
        displayProductGroup: false,
        displayPurpose: false,
      },
      width: "900px",
      panelClass: 'noCard',
    });

    ref.componentInstance.createdMessage.subscribe((data) => {
      if (data) {
        const r = {
          ID: data['ID'],
          label: data['Label'],
          value: data['Content'],
        };

        this.defaultMessageLists.push(r);

        ref.close();
      }
    });

    const sub = ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
  }

  modifyProfile() {
    if (this.patient && this.patientID) {
      const ref = RootAppComponent.dialog.open(InvitationCustomerProspectEditComponent, {
        data: {
          patientID: this.patientID,
        },
        width: '650px',
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res && res.ID) {
          this.buildPatient(res);
          this.message = this.convertMessage();
          ref.close();
        }
      });
    }
  }

  viewVideoInfo(videoTitle, docUrl) {
    const ref = RootAppComponent.dialog.open(DocumentUrlViewComponent, {
      data: {
        title: videoTitle,
        description: '',
        documentUrl: docUrl,
      },
      width: '800px',
      panelClass: 'noCard',
    });
    const sub = ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
  }

  goBack() {
    this.location.back();
  }

  exitToDash() {
    this.flyAway = true;
    setTimeout(() => {
      this.router.navigate(['/merchant']);
    }, 1800);
  }

  convertMessage() {
    let result = this.message;

    const rule = {};

    if (this.patient && this.selectedMerchant && this.selectedSender && this.rawMessage) {
      if (this.patient['title']) {
        rule['PatientSalutation'] = this.patient['title'];
      } else {
        rule['PatientSalutation'] = '';
      }

      if (this.patient['lastName']) {
        rule['PatientLastName'] = this.patient['lastName'];
      } else {
        rule['PatientLastName'] = '';
      }

      if (this.patient['firstName']) {
        rule['PatientFirstName'] = this.patient['firstName'];
      } else {
        rule['PatientFirstName'] = '';
      }

      if (this.patient['middleName']) {
        rule['PatientMiddleName'] = this.patient['middleName'];
      } else {
        rule['PatientMiddleName'] = '';
      }

      if (this.patient['mobile']) {
        rule['PatientMobileNumber'] = this.customePhonePipe.transform(this.patient['mobile'], 'false');
      } else {
        rule['PatientMobileNumber'] = '';
      }

      if (this.patient['homePhone']) {
        rule['PatientHomePhoneNumber'] = this.customePhonePipe.transform(this.patient['homePhone'], 'true');
      } else {
        rule['PatientHomePhoneNumber'] = '';
      }

      if (this.patient['workPhone']) {
        rule['PatientWorkPhoneNumber'] = this.customePhonePipe.transform(this.patient['workPhone'], 'true');
      } else {
        rule['PatientWorkPhoneNumber'] = '';
      }

      if (this.patient['email']) {
        rule['PatientEmail'] = this.patient['email'];
      } else {
        rule['PatientEmail'] = '';
      }

      if (this.selectedSender['Salutation']) {
        rule['DentistSalutation'] = this.selectedSender['Salutation'];
      } else {
        rule['DentistSalutation'] = '';
      }

      if (this.selectedSender['FirstName']) {
        rule['DentistFirstName'] = this.selectedSender['FirstName'];
      } else {
        rule['DentistFirstName'] = '';
      }

      if (this.selectedSender['MiddleName']) {
        rule['DentistMiddleName'] = this.selectedSender['MiddleName'];
      } else {
        rule['DentistMiddleName'] = '';
      }

      if (this.selectedSender['Name']) {
        rule['DentistLastName'] = this.selectedSender['Name'];
      } else {
        rule['DentistLastName'] = '';
      }

      if (this.selectedSender['mobiles.Number']) {
        rule['DentistMobileNumber'] = this.customePhonePipe.transform(this.selectedSender['mobiles.Number'], 'false');
      } else {
        rule['DentistMobileNumber'] = '';
      }

      if (this.selectedSender['phones.Number']) {
        rule['DentistPhoneNumber'] = this.customePhonePipe.transform(this.selectedSender['phones.Number'], 'true');
      } else {
        rule['DentistPhoneNumber'] = '';
      }

      if (this.selectedSender['emails.Email']) {
        rule['DentistEmail'] = this.selectedSender['emails.Email'];
      } else {
        rule['DentistEmail'] = '';
      }

      if (this.selectedSender['addresses.Calculated']) {
        rule['DentistAddress'] = this.selectedSender['addresses.Calculated'];
      } else {
        rule['DentistAddress'] = '';
      }

      if (this.selectedMerchant['Name']) {
        rule['PracticeLegalName'] = this.selectedMerchant['Name'];
      } else {
        rule['PracticeLegalName'] = '';
      }

      if (this.selectedMerchant['Name']) {
        rule['PracticeLegalName'] = this.selectedMerchant['Name'];
      } else {
        rule['PracticeLegalName'] = '';
      }

      if (this.selectedMerchant['addresses.Calculated']) {
        rule['PracticeAddress'] = this.selectedMerchant['addresses.Calculated'];
      } else {
        rule['PracticeAddress'] = '';
      }

      if (this.selectedMerchant['TradingAs']) {
        rule['PracticeTradingAs'] = this.selectedMerchant['TradingAs'];
      } else {
        rule['PracticeTradingAs'] = '';
      }

      if (this.selectedMerchant['phones.Number']) {
        rule['PracticePhoneNumber'] = this.customePhonePipe.transform(this.selectedMerchant['phones.Number'], 'true');
      } else {
        rule['PracticePhoneNumber'] = '';
      }

      if (this.selectedMerchant['mobiles.Number']) {
        rule['PracticeMobileNumber'] = this.customePhonePipe.transform(
          this.selectedMerchant['mobiles.Number'],
          'false'
        );
      } else {
        rule['PracticeMobileNumber'] = '';
      }

      if (this.selectedMerchant['addresses.Calculated']) {
        rule['PracticeAddress'] = this.selectedMerchant['addresses.Calculated'];
      } else {
        rule['PracticeAddress'] = '';
      }

      if (this.selectedMerchant['ABN']) {
        rule['PracticeABN'] = this.selectedMerchant['ABN'];
      } else {
        rule['PracticeABN'] = '';
      }

      if (this.selectedMerchant['ACN']) {
        rule['PracticeACN'] = this.selectedMerchant['ACN'];
      } else {
        rule['PracticeACN'] = '';
      }

      if (this.selectedMerchant['emails.Email']) {
        rule['PracticeEmail'] = this.selectedMerchant['emails.Email'];
      } else {
        rule['PracticeEmail'] = '';
      }

      if (this.selectedMerchant['Facebook']) {
        rule['PracticeFacebook'] = this.selectedMerchant['Facebook'];
      } else {
        rule['PracticeFacebook'] = '';
      }

      if (this.selectedMerchant['Instagram']) {
        rule['PracticeInstagram'] = this.selectedMerchant['Instagram'];
      } else {
        rule['PracticeInstagram'] = '';
      }

      if (this.selectedMerchant['Twitter']) {
        rule['PracticeTwitter'] = this.selectedMerchant['Twitter'];
      } else {
        rule['PracticeTwitter'] = '';
      }

      if (this.selectedMerchant['LinkedIn']) {
        rule['PracticeLinkedIn'] = this.selectedMerchant['LinkedIn'];
      } else {
        rule['PracticeLinkedIn'] = '';
      }

      if (this.selectedMerchant['Pinterest']) {
        rule['PracticePinterest'] = this.selectedMerchant['Pinterest'];
      } else {
        rule['PracticePinterest'] = '';
      }

      if (this.selectedMerchant['Tumblr']) {
        rule['PracticeTumblr'] = this.selectedMerchant['Tumblr'];
      } else {
        rule['PracticeTumblr'] = '';
      }

      if (this.selectedMerchant['Vimeo']) {
        rule['PracticeVimeo'] = this.selectedMerchant['Vimeo'];
      } else {
        rule['PracticeVimeo'] = '';
      }

      if (this.selectedMerchant['YouTube']) {
        rule['PracticeYouTube'] = this.selectedMerchant['YouTube'];
      } else {
        rule['PracticeYouTube'] = '';
      }

      if (this.selectedMerchant['URL']) {
        rule['PracticeWebSite'] = this.selectedMerchant['URL'];
      } else {
        rule['PracticeWebSite'] = '';
      }

      if (this.merchant && this.merchant['Twilio_Number']) {
        rule['DedicatedSMSNumber'] = this.customePhonePipe.transform(this.merchant['Twilio_Number'], 'false');
      } else {
        rule['DedicatedSMSNumber'] = '';
      }
      if (this.merchant && this.merchant['Terminal_Code']) {
        rule['SelfServicePage'] = `${environment.nodeUrl}/s/${this.merchant['Terminal_Code']}?t=lp`;
      } else {
        rule['SelfServicePage'] = '';
      }
      if (this.selectedMerchant['Terminal_Code']) {
        rule[
          'PracitceAppointment'
        ] = `${Settings.global['publicSiteFrontendLink']}/a/${this.selectedMerchant['Terminal_Code']}`;
      } else {
        rule['PracitceAppointment'] = '';
      }

      if (this.selectedMerchant['Terminal_Code']) {
        rule[
          'PracticePage'
        ] = `${Settings.global['publicSiteFrontendLink']}/l/p/s/${this.selectedMerchant['Terminal_Code']}`;
      } else {
        rule['PracticePage'] = '';
      }

      if (this.selectedMerchant['Terminal_Code']) {
        rule[
          'TipsAndOffers'
        ] = `${Settings.global['publicSiteFrontendLink']}/l/d/s/${this.selectedMerchant['Terminal_Code']}`;
      } else {
        rule['TipsAndOffers'] = '';
      }

      if (this.promoter) {
        if (this.promoter['TradingAs']) {
          rule['PromoterTradingAs'] = this.promoter['TradingAs'];
        } else {
          rule['PromoterTradingAs'] = ' ';
        }

        if (this.promoter['FirstName']) {
          rule['PromoterFirstName'] = this.promoter['FirstName'];
        } else {
          rule['PromoterFirstName'] = ' ';
        }

        if (this.promoter['Name']) {
          rule['PromoterLegalName'] = this.promoter['Name'];
        } else {
          rule['PromoterLegalName'] = ' ';
        }

        if (this.promoter['mobiles.Number']) {
          rule['PromoterMobileNumber'] = this.customePhonePipe.transform(this.promoter['mobiles.Number'], 'false');
        } else {
          rule['PromoterMobileNumber'] = ' ';
        }

        if (this.promoter['phones.Number']) {
          rule['PromoterPhoneNumber'] = this.customePhonePipe.transform(this.promoter['phones.Number'], 'true');
        } else {
          rule['PromoterPhoneNumber'] = ' ';
        }

        if (this.promoter['emails.Email']) {
          rule['PromoterEmail'] = this.promoter['emails.Email'];
        } else {
          rule['PromoterEmail'] = ' ';
        }

        if (this.promoter['addresses.Calculated']) {
          rule['PromoterAddress'] = this.promoter['addresses.Calculated'];
        } else {
          rule['PromoterAddress'] = ' ';
        }

        if (this.promoter['Facebook']) {
          rule['PromoterFacebook'] = this.promoter['Facebook'];
        } else {
          rule['PromoterFacebook'] = ' ';
        }

        if (this.promoter['Instagram']) {
          rule['PromoterInstagram'] = this.promoter['Instagram'];
        } else {
          rule['PromoterInstagram'] = ' ';
        }
        if (this.promoter['Twitter']) {
          rule['PromoterTwitter'] = this.promoter['Twitter'];
        } else {
          rule['PromoterTwitter'] = ' ';
        }

        if (this.promoter['LinkedIn']) {
          rule['PromoterLinkedIn'] = this.promoter['LinkedIn'];
        } else {
          rule['PromoterLinkedIn'] = ' ';
        }

        if (this.promoter['Pinterest']) {
          rule['PromoterPinterest'] = this.promoter['Pinterest'];
        } else {
          rule['PromoterPinterest'] = ' ';
        }
        if (this.promoter['Tumblr']) {
          rule['PromoterTumblr'] = this.promoter['Tumblr'];
        } else {
          rule['PromoterTumblr'] = ' ';
        }

        if (this.promoter['Vimeo']) {
          rule['PromoterVimeo'] = this.promoter['Vimeo'];
        } else {
          rule['PromoterVimeo'] = ' ';
        }
        if (this.promoter['YouTube']) {
          rule['PromoterYouTube'] = this.promoter['YouTube'];
        } else {
          rule['PromoterYouTube'] = ' ';
        }
        if (this.promoter['URL']) {
          rule['PromoterWebSite'] = this.promoter['URL'];
        } else {
          rule['PromoterWebSite'] = ' ';
        }

        if (this.promoter['ABN']) {
          rule['PromoterABN'] = this.promoter['ABN'];
        } else {
          rule['PromoterABN'] = ' ';
        }
        if (this.promoter['ACN']) {
          rule['PromoterACN'] = this.promoter['ACN'];
        } else {
          rule['PromoterACN'] = ' ';
        }
      }
      result = this.util.convertMessage(this.rawMessage, rule);
    }
    return result;
  }

  defaultMessageChange(m) {
    if (m && m.value) {
      this.rawMessage = m.value;
      this.message = this.convertMessage();
    }
  }

  clearPatientDetails() {
    this.clearEmail.emit(true);
    this.clearMobile.emit(true);
    this.clearHomePhone.emit(true);
    this.clearWorkPhone.emit(true);

    this.patient = {
      firstName: '',
      title: '',
      lastName: '',
      middleName: '',
      genderCode: '',
      mobile: '',
      homePhone: '',
      workPhone: '',
      email: '',
      tableName: null,
    };

    this.patientID = null;
    this.isFinDMatch = false;
    this.dateOfBirth = null;
    this._dateOfBirth = null;
    this.clearDate.emit(true);
  }

  patientProfile() {
    if (this.patientID) {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['customer-profile', this.patientID, 'patient'],
          },
        },
      ]);
    }
  }

  step1() {
    if (!this.patientID) {
      const payload = {
        mobile: this.patient.mobile,
        phone: this.patient.homePhone,
        email: this.patient.email,
        firstName: this.patient.firstName,
        lastName: this.patient.lastName,
        dateOfBirth: this._dateOfBirth,
        merchantID: this.merchantID,
      };
      this.customerProspectService.patientLookup(payload, this.isAdminOrPromoter).subscribe((res) => {
        if (res && res.length > 0) {
          const patientLookup = RootAppComponent.dialog.open(InvitationLookupComponent, {
            data: {
              patients: res,
              hideFilter: true,
              showNextBtn: true,
            },
            width: '600px',
          });
          patientLookup.componentInstance.patientChat.subscribe((res) => {
            if (res && res.cardID) {
              if (res.isPromoterOrAdmin == true) {
                const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
                  data: {
                    merchantID: res.merchantID,
                    cardID: res.cardID,
                    singleChat: true,
                  },
                  panelClass: 'noCard',
                  width: '500px',
                });
                ref2.componentInstance.close.subscribe((data) => {
                  ref2.close();
                });

                ref2.componentInstance.getResult.subscribe((data) => {
                  if (data) {
                    const ref3 = RootAppComponent.dialog.open(SmsConversationComponent, {
                      data,
                      panelClass: 'bigger-screen',
                      width: '70%',
                      height: '95vh',
                    });
                    const sub = ref3.componentInstance.close.subscribe((data) => {
                      ref3.close();
                    });
                  }

                  ref2.close();
                });
              } else {
                const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
                  data: {
                    merchantID: res.merchantID,
                    cardID: res.cardID,
                    singleChat: true,
                  },
                  panelClass: 'bigger-screen',
                  width: '70%',
                  height: '95vh',
                });
                const sub = ref2.componentInstance.close.subscribe((data) => {
                  ref2.close();
                });
              }
            }
          });
          patientLookup.componentInstance.closeModal.subscribe((res) => {
            patientLookup.close();
          });
          patientLookup.componentInstance.detailedView.subscribe((res) => {
            patientLookup.close();
          });
          patientLookup.componentInstance.getSelectedPatient.subscribe((res) => {
            if (res) {
              if (res.ID) {
                this.patientID = res.ID;
                this.buildPatient(res);
                this.isFinDMatch = true;
                patientLookup.close();
                this.wizard.goToNextStep();
              }
            }
          });

          patientLookup.componentInstance.createNew.subscribe((res) => {
            patientLookup.close();
            this.wizard.goToNextStep();
          });
        } else {
          this.wizard.goToNextStep();
        }
      });
    } else {
      this.wizard.goToNextStep();
    }
  }

  selectDateToFilter(dateTo) {
    this._dateOfBirth = dateTo;
  }
}
