import 'hammerjs';

import { environment } from 'environments/environment';

// ANGULAR
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

import { GestureConfig, MatProgressBarModule } from '@angular/material';

// CORE
import { CoreModule } from '../../../common/core/core.module';
import { SharedModule } from '../../../common/shared/shared.module';
import { AppMerchantRoutingModule } from './shared/app-routing.module';

import { ServiceFactoryService } from '../../../common/shared/services/service-factory.service';

// 3RD PARTY
import { AgmCoreModule } from '@agm/core';
import { StoreModule } from '@ngrx/store';
import { FormlyModule } from '@ngx-formly/core';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { GoogleApiModule, NG_GAPI_CONFIG, NgGapiClientConfig } from 'ng-gapi';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';

// PROJECT
import { AppMerchantComponent } from './app.component';
import { FooterMerchantComponent } from './footer/footer.component';
import { HeaderMerchantComponent } from './header/header.component';
import { IntegrationComponent } from './integration/integration.component';
import { LoginMerchantComponent } from './login/login.component';
import { MainRedirectionComponent } from './main-redirection/main-redirection.component';
import { MainMerchantComponent } from './main/main.component';
import { PagesMerchantModule } from './pages/pages.module';
import { MerchantAuthGuard } from './shared/guard.service';
import { SideMerchantMenuComponent } from './side-menu/side-menu.component';

// FEATURE
import { ActionLogModule } from '../../../common/feature/action-log/action-log.module';
import { BeneficiaryModule } from '../../../common/feature/beneficiary/beneficiary.module';
import { ContactUsModule } from '../../../common/feature/contact-us/contact-us.module';
import { ContactModule } from '../../../common/feature/contact/contact.module';
import { ContractModule } from '../../../common/feature/contract/contract.module';
import { CustomerProspectModule } from '../../../common/feature/customer-prospect/customerProspect.module';
import { CustomerModule } from '../../../common/feature/customer/customer.module';
import { DefaultMessageModule } from '../../../common/feature/default-message/default-message.module';
import { DentistModule } from '../../../common/feature/dentist/dentist.module';
import { DocumentModule } from '../../../common/feature/document/document.module';
import { EditorComponentModule } from '../../../common/feature/editor-component/editor-component.module';
import { ExistingPatientModule } from '../../../common/feature/existing-patient/existing-patient.module';
import { FundersModule } from '../../../common/feature/funders/funders.module';
import { InstantFinanceOfferModule } from '../../../common/feature/instant-finance-offer/instant-finance-offer.module';
import { InvitationModule } from '../../../common/feature/invitation/invitation.module';
import { MarketingModule } from '../../../common/feature/marketing/marketing.module';
import { MerchantLinkModule } from '../../../common/feature/merchant-link/merchant-link.module';
import { MerchantProspectModule } from '../../../common/feature/merchant-prospect/merchant-prospect.module';
import { MerchantModule } from '../../../common/feature/merchant/merchant.module';
import { NotesModule } from '../../../common/feature/notes/notes.module';
import { OperatorModule } from '../../../common/feature/operator/operator.module';
import { PerformanceModule } from '../../../common/feature/performance/performance.module';
import { PriceServiceModule } from '../../../common/feature/price-service/price-service.module';
import { ProductModule } from '../../../common/feature/product/product.module';
import { ProfilsModule } from '../../../common/feature/profils/profils.module';
import { ReferralModule } from '../../../common/feature/referral/referral.module';
import { SettlementModule } from '../../../common/feature/settlement/settlement.module';
import { SmileMakeoverManagerModule } from '../../../common/feature/smile-makeover-manager/smile-makeover-manager.module';
import { SocialMediaModule } from '../../../common/feature/social-media/social-media.module';
import { TagsModule } from '../../../common/feature/tags/tags.module';
import { TasksModule } from '../../../common/feature/tasks/tasks.module';
import { TemplateModule } from '../../../common/feature/template/template.module';
import { TestimonyModule } from '../../../common/feature/testimony/testimony.module';
import { TreatmentPlanModule } from '../../../common/feature/treatment-plan/treatment-plan.module';
import { TreatmentModule } from '../../../common/feature/treatment/treatment.module';
import { WishlistModule } from '../../../common/feature/wishlist/wishlist.module';
import { NgPipesModule } from 'ngx-pipes';
import {FormlyMaterialModule} from '@ngx-formly/material';
// GLOBAL STATE
import { HttpClient, HttpClientModule, HttpHandler } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor';
import { QuillModule } from 'ngx-quill';
import { SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import {
  currentDentistReducer,
  currentIsPromoterOrAdminReducer,
  currentPracticeReducer,
  currentPublicModulesReducer,
  currentPublicSettingsReducer,
  currentSessionTypeReducer,
  currentUserReducer,
} from '../../../common/core/app-state/app-state.reducers';
import { httpFactory } from '../../../common/shared/types/http.factory';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminModule } from '../../../common/core/admin/admin.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AiScansModule } from '../../../common/feature/ai-scans/ai-scans.module';
import { SessionModule } from '../../../common/core/session/session.module';
import { AuthenticationModule } from '../../../common/core/authentication/authentication.module';
import { CommonModule } from '@angular/common';
import { MessageModule } from '../../../common/feature/message/message.module';
import { MedicalHistoryModule } from '../../../common/feature/medical-history/medical-history.module';
import { DrugsModule } from '../../../common/feature/drugs/drugs.module';
import { InformedConsentModule } from '../../../common/feature/informed-consent/informed-consent.module';
import { ActivityLogModule } from '../../../common/feature/activity-log/activity-log.module';
import { TranslationManagementModule } from '../../../common/feature/translation-management/translation-management.module';
import { AppointmentModule } from '../../../common/feature/appointment/appointment.module';

import { HelperModule } from '../../../common/core/helper/helper.module';
import { MarketingFilterModule } from '../../../common/feature/marketing-filter/marketing-filter.module';
import { HelpTopicsModule } from '../../../common/core/help-topics/help-topics.module';
import { ModalTopRoutingModule } from './shared/modal-top-routing.module';
import { CardModule } from '../../../common/feature/card/card.module';

// CONFIG
const gapiClientConfig: NgGapiClientConfig = {
  client_id: environment.client_id,
  discoveryDocs: environment.discoveryDocs,
  scope: environment.scope,
};

const monacoConfig: NgxMonacoEditorConfig = { baseUrl: '/assets', defaultOptions: { scrollBeyondLastLine: false } };

const customCurrencyMaskConfig = {
  align: 'right',
  allowNegative: false,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '$',
  suffix: '',
  thousands: ',',
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.NATURAL,
};

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = { direction: 'horizontal', slidesPerView: 'auto' };

export function initFunction(config: ServiceFactoryService) {
  return () => config.getSettings();
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.nodeUrl + '/translate/cached/', '.json');
}

@NgModule({
  declarations: [
    AppMerchantComponent,
    MainMerchantComponent,
    HeaderMerchantComponent,
    SideMerchantMenuComponent,
    FooterMerchantComponent,
    LoginMerchantComponent,
    MainRedirectionComponent,
    IntegrationComponent,
  ],
  imports: [
    // CORE
    CardModule,
    CoreModule,
    SharedModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    FormlyMaterialModule,
    ReactiveFormsModule,
    AiScansModule,
    SessionModule,
    AuthenticationModule,
    HelpTopicsModule,
    HelperModule,
    CommonModule,
    NgPipesModule,
    MatProgressBarModule,
    AppointmentModule,
    MessageModule,
    MedicalHistoryModule,
    DrugsModule,
    InformedConsentModule,
    ActivityLogModule,
    TranslationManagementModule,
    AdminModule,
    // ROOT
    DeviceDetectorModule.forRoot(),
    ToastrModule.forRoot(),
    QuillModule.forRoot(),
    NgxImageZoomModule.forRoot(),
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    AgmCoreModule.forRoot({ apiKey: environment.googleAPIKey, libraries: ['places', 'geometry'] }),
    FormlyModule.forRoot({ extras: { lazyRender: true } }),
    GoogleApiModule.forRoot({ provide: NG_GAPI_CONFIG, useValue: gapiClientConfig }),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    MonacoEditorModule.forRoot(monacoConfig),
    AgmCoreModule.forRoot({
      apiKey: environment.googleAPIKey,
      libraries: ['places', 'geometry'],
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxDaterangepickerMd.forRoot({
      format: 'DD/MM/YYYY',
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    StoreModule.forRoot({
      practice: currentPracticeReducer,
      user: currentUserReducer,
      sessionType: currentSessionTypeReducer,
      isPromoterOrAdmin: currentIsPromoterOrAdminReducer,
      dentist: currentDentistReducer,
      publicSettings: currentPublicSettingsReducer,
      publicModules: currentPublicModulesReducer,
    }),
    // 3RD PARTY
    PasswordStrengthMeterModule,
    // FEATURES
    ProfilsModule,
    ActionLogModule,
    DentistModule,
    OperatorModule,
    PriceServiceModule,
    TreatmentModule,
    TreatmentPlanModule,
    CustomerModule,
    ProductModule,
    ExistingPatientModule,
    SettlementModule,
    MerchantModule,
    PerformanceModule,
    InvitationModule,
    ContractModule,
    MarketingModule,
    BeneficiaryModule,
    TestimonyModule,
    DefaultMessageModule,
    CustomerProspectModule,
    ContactModule,
    ReferralModule,
    DocumentModule,
    TasksModule,
    TemplateModule,
    MerchantProspectModule,
    TagsModule,
    ContactUsModule,
    PagesMerchantModule,
    SocialMediaModule,
    EditorComponentModule,
    WishlistModule,
    SmileMakeoverManagerModule,
    NotesModule,
    FundersModule,
    InstantFinanceOfferModule,
    MerchantLinkModule,
    AppMerchantRoutingModule,
    ModalTopRoutingModule,
    MarketingFilterModule

  ],
  providers: [
    MerchantAuthGuard,
    ServiceFactoryService,
    { provide: APP_INITIALIZER, useFactory: initFunction, deps: [ServiceFactoryService], multi: true },
    { provide: LOCALE_ID, useValue: 'en-AU' },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: {} },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    { provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG },
    { provide: HttpClient, useValue: { useUtc: true }, useFactory: httpFactory, deps: [HttpHandler] },
  ],
  bootstrap: [AppMerchantComponent],
  exports: [MainRedirectionComponent, IntegrationComponent],
})
export class AppMerchantModule {}
