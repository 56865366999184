import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { AppStateService } from '../../core/app-state/app-state.service';

@Directive({
  selector: '[ipvIsPromoterOrAdmin]',
})
export class IsPromoterOrAdminDirective implements OnInit, OnDestroy {
  @Input() ipvIsPromoterOrAdmin = true;

  isVisible = false;

  constructor(
    private appStateService: AppStateService,
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) {}

  ngOnInit() {
    this.appStateService
      .getAppState$()
      .pipe(untilDestroyed(this))
      .subscribe((appState) => {
        if (this.ipvIsPromoterOrAdmin === null) {
          this.ipvIsPromoterOrAdmin = true;
        }

        if (appState.isPromoterOrAdmin === this.ipvIsPromoterOrAdmin) {
          if (!this.isVisible) {
            this.isVisible = true;
            this.viewContainerRef.createEmbeddedView(this.templateRef);
          }
        } else {
          this.viewContainerRef.clear();
        }
      });
  }

  ngOnDestroy() {
    /** required for untilDestroyed (Replace with @UntilDestroyed() Decorator in angular 9 and delete ngOnDestroy()) */
  }
}
