import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/';
import * as moment from 'moment';
import { ConfirmDialogSingleButtonComponent } from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomCurrencyPipe } from '../../../shared/pipes/custom-currency.pipe';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { InvitationService } from '../../invitation/shared/services/invitation.service';
import { PatientCardChangesModalComponent } from '../../patient/patient-card-changes-modal/patient-card-changes-modal.component';
import { PatientCardDeferModalComponent } from '../../patient/patient-card-defer-modal/patient-card-defer-modal.component';
import { PatientCardStopModalComponent } from '../../patient/patient-card-stop-modal/patient-card-stop-modal.component';
import { PatientService } from '../../patient/shared/patient.service';
import { ProductCalculatorComponent } from '../../product/product-calculator/product-calculator.component';
import { ProductService } from '../../product/shared/product.service';
import { TreatmentService } from '../../treatment/shared/treatment.service';
import { TreatmentInclusionModalComponent } from '../../treatment/treatment-incusion-modal/treatment-incusion-modal.component';
import { InformedConsentComponent } from '../informed-consent/informed-consent.component';

@Component({
  selector: 'app-patient-card-quotation',
  templateUrl: './patient-card-quotation.component.html',
  styleUrls: ['./patient-card-quotation.component.css'],
  providers: [CustomCurrencyPipe, CustomDatePipe],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),

    trigger('ngIfTab', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class PatientCardQuotationComponent implements OnInit {
  headPos = 'below';

  step = 0;
  selectedTab = 0;
  moreText = false;

  treatment = {};

  reasons = [];
  feedback = [];

  treatmentCategorySet = false;

  stepTwoVisited = false;
  stepThreeVisited = false;
  stepFourVisited = false;

  isModuleAppointmentOldActive = Settings.global['isModuleAppointmentOldActive'];
  isModuleDiscountsActive = Settings.global['isModuleDiscountsActive'];
  isModulePatientHistoryActive = Settings.global['isModulePatientHistoryActive'];

  @Input()
  invitationID;

  @Input('customerInvitation')
  customInv = null;

  @Output()
  consumerChoice = new EventEmitter();

  @Output()
  deferedWeek = new EventEmitter();

  @Output() isExist: EventEmitter<any> = new EventEmitter();

  diagString1 =
    'When gingivitis is present, gingival redness, inflammation and bleeding are usually observed. If bleeding exists, it is necessary to visit the dentist to rule out possible complications and so that he/she can recommend the most appropriate treatment. Periodontitis is characterized by gingival redness, inflammation, bleeding and recession, tooth mobility and the loss of tooth-supporting alveolar bone.';

  @Input()
  viewOnly = false;

  settings = Settings.global;
  context = Settings.global['context'];

  // discount
  canHasDiscount = true;

  documents = [];
  minAmount;

  cashDiscountDate;

  displayDiscount = false;

  cashDiscountDateCounter;

  totalTreatmentValue;

  utils = new UtilsClass();

  isItemisedTreatmentPlan = true;

  // demo array
  demoTreatments = [
    {
      checked: true,
      treatmentQty: '1',
      treatmentValue: '4000',
      treatmentCategory: 'Replace',
      treatmentType: 'Bridge Only',
      treatmentBrand: 'Gold',
      treatmentProduct: '...',
      numberOfDocs: '1',
      treatmentImages: [
        {
          id: '10001',
          label: 'Before photo',
          url: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/treatment/white_fillings_before.png',
        },
        {
          id: '10002',
          label: 'After photo',
          url: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/treatment/white_fillings_after.png',
        },
      ],
      severityRating: 5,
      severityStar: [false, false, false, false, false],
      selectedTeeth: ['25', '26', '27'],
      diagnosisText:
        'A bridge is a fixed dental restoration used to replace one or more missing teeth by joining an artificial tooth definitively to adjacent teeth or dental implants.',
      treatmentText:
        "A dental bridge is a false tooth (called a pontic) that is held in place by the abutment teeth on either side of the gap. Although pontics can be made from a variety of materials such as gold, typically they're made from porcelain to aesthetically blend in with your natural teeth.",
    },
    {
      checked: true,
      treatmentQty: '1',
      treatmentValue: '500',
      treatmentCategory: 'Restore',
      treatmentType: 'Filling',
      treatmentBrand: 'Ceramic (Porcelain) Generic',
      treatmentProduct: '...',
      numberOfDocs: '2',
      treatmentImages: [
        {
          id: '10005',
          label: 'Before photo',
          url: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/treatment/blemish_before.png',
        },
        {
          id: '10006',
          label: 'After photo',
          url: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/treatment/blemish_after.png',
        },
      ],
      severityRating: 4,
      severityStar: [false, false, false, false, true],
      selectedTeeth: ['26'],
      diagnosisText:
        'Fillings may be required if tooth structure has been lost due to decay or trauma. During a regular check-up, your dentist will examine your teeth for these defects, and using X-rays to pinpoint the location and extent of decay, will then decide on the best method to restore the integrity of your tooth, which may include fillings.',
      treatmentText:
        'When a filling is required there is a variety of materials that can be used, all of which have unique properties and advantages. Some factors which influence the type of material to be used will be the type of tooth, the strength requirements and the way your teeth bite together. Your dentist will give you advice on what is appropriate for your situation.',
    },
    {
      checked: true,
      treatmentQty: '1',
      treatmentValue: '2000',
      treatmentCategory: 'Whole of mouth',
      treatmentType: 'Whitening',
      treatmentBrand: 'Phillips',
      treatmentProduct: '...',
      numberOfDocs: '4',
      treatmentImages: [
        {
          id: '10005',
          label: 'Before photo',
          url: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/treatment/whiteningbefore2.png',
        },
        {
          id: '10006',
          label: 'After photo',
          url: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/treatment/whiteningafter2.png',
        },
      ],
      severityRating: 2,
      severityStar: [false, false, true, true, true],
      selectedTeeth: [
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '21',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
        '31',
        '32',
        '33',
        '34',
        '35',
        '36',
        '37',
        '38',
        '41',
        '42',
        '43',
        '44',
        '45',
        '46',
        '47',
        '48',
      ],
      diagnosisText:
        'There are many causes of teeth discolouration. Some of the most common include the consumption of highly coloured foods and drinks (berries, curry, coffee, tea, red wine). Some medications, aging, smoking and trauma can also cause teeth discolouration.',
      treatmentText:
        'With any teeth whitening treatment done in our practice, we recommend a professional clean and polish appointment before the whitening procedure to remove any build-up that can affect the whitening outcome. Whitening works best when your teeth have been professionally cleaned. The clean is optional but is highly recommended if you have not had a professional clean in the last 3-6 months.',
    },
    {
      checked: true,
      treatmentQty: '1',
      treatmentValue: '2000',
      treatmentCategory: 'Whole of Mouth',
      treatmentType: 'Clear Aligners',
      treatmentBrand: 'Invisalign',
      treatmentProduct: '...',
      numberOfDocs: '1',
      treatmentImages: [
        {
          id: '10007',
          label: 'Before photo',
          url: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/treatment/before_crowns.png',
        },
        {
          id: '10008',
          label: 'After photo',
          url: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/treatment/after_crowns.png',
        },
      ],
      severityRating: 2,
      severityStar: [false, false, true, true, true],
      selectedTeeth: ['25', '26', '27'],
      diagnosisText:
        'A bridge is a fixed dental restoration used to replace one or more missing teeth by joining an artificial tooth definitively to adjacent teeth or dental implants.',
      treatmentText:
        "A dental bridge is a false tooth (called a pontic) that is held in place by the abutment teeth on either side of the gap. Although pontics can be made from a variety of materials such as gold, typically they're made from porcelain to aesthetically blend in with your natural teeth.",
    },
    {
      checked: true,
      treatmentQty: '2',
      treatmentValue: '500',
      treatmentCategory: 'Restore',
      treatmentType: 'Filling',
      treatmentBrand: 'Gold',
      treatmentProduct: '...',
      numberOfDocs: '3',
      treatmentImages: [
        {
          id: '10009',
          label: 'Before photo',
          url: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/treatment/white_fillings_before.png',
        },
        {
          id: '10010',
          label: 'After photo',
          url: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/treatment/white_fillings_after.png',
        },
      ],
      severityRating: 4,
      severityStar: [false, false, false, false, true],
      selectedTeeth: ['26'],
      diagnosisText:
        'Fillings may be required if tooth structure has been lost due to decay or trauma. During a regular check-up, your dentist will examine your teeth for these defects, and using X-rays to pinpoint the location and extent of decay, will then decide on the best method to restore the integrity of your tooth, which may include fillings.',
      treatmentText:
        'When a filling is required there is a variety of materials that can be used, all of which have unique properties and advantages. Some factors which influence the type of material to be used will be the type of tooth, the strength requirements and the way your teeth bite together. Your dentist will give you advice on what is appropriate for your situation.',
    },
    {
      checked: true,
      treatmentQty: '1',
      treatmentValue: '2000',
      treatmentCategory: 'Remove',
      treatmentType: 'Single Tooth',
      treatmentBrand: 'Extraction',
      treatmentProduct: '...',
      numberOfDocs: '2',
      treatmentImages: [
        {
          id: '10011',
          label: 'Before photo',
          url: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/treatment/mini_crowns_before.png',
        },
        {
          id: '10012',
          label: 'After photo',
          url: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/treatment/mini_crowns_after.png',
        },
      ],
      severityRating: 2,
      severityStar: [false, false, true, true, true],
      selectedTeeth: ['24'],
      diagnosisText:
        'There are many causes of teeth discolouration. Some of the most common include the consumption of highly coloured foods and drinks (berries, curry, coffee, tea, red wine). Some medications, aging, smoking and trauma can also cause teeth discolouration.',
      treatmentText:
        'With any teeth whitening treatment done in our practice, we recommend a professional clean and polish appointment before the whitening procedure to remove any build-up that can affect the whitening outcome. Whitening works best when your teeth have been professionally cleaned. The clean is optional but is highly recommended if you have not had a professional clean in the last 3-6 months.',
    },
  ];

  interval;

  expireTill;

  treatPlan = false;
  payOpts = true;

  constructor(
    private utilService: UtilsService,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
    private productService: ProductService,
    private invitationService: InvitationService,
    private patientService: PatientService,
    private treatmentService: TreatmentService,
    private dataPipe: CustomDatePipe,
    private currencyPipe: CustomCurrencyPipe
  ) {}

  ngOnInit() {
    if (this.customInv) {
      this.utilService.getAccessInvitationList(this.customInv['ID']).subscribe((access) => {
        if (access) {
          this.isModuleAppointmentOldActive = access['isModuleAppointmentOldActive'];
          this.isModuleDiscountsActive = access['isModuleDiscountsActive'];
          this.isModulePatientHistoryActive = access['isModulePatientHistoryActive'];
        }

        if (window.innerWidth < 1023) {
          this.headPos = 'below';
        }

        if (window.innerWidth > 1023) {
          this.headPos = 'above';
        }

        this.invitationService.markAsViewed(this.customInv['ID']).subscribe((res) => {});

        this.treatmentService.getSimpleTreatment(this.invitationID).subscribe((res) => {
          this.treatment = res;

          if (!this.treatment || !this.treatment['totalTreatmentValue'] || this.treatment['isAdvanced'] == true) {
            this.isExist.emit(false);
          } else {
            this.isExist.emit(true);
          }
        });

        if (
          this.customInv &&
          this.customInv['Product.ThisGroupOnly.Code'] &&
          this.customInv['Merchant_Key'] &&
          this.customInv['treatment.Value']
        ) {
          const payload = {
            merchantKey: this.customInv['Merchant_Key'],
            amount: this.customInv['treatment.Value'],
            groupCode: this.customInv['Product.ThisGroupOnly.Code'],
            fields: 'Min(BasedOnMaxDuration.Repayments.Weekly)',
          };

          this.productService.getSuitableProductMinValue(payload).subscribe((res) => {
            if (res) {
              this.minAmount = Number(Number(res['Min(BasedOnMaxDuration.Repayments.Weekly)']) / 7);
            }
          });
        }

        if (this.customInv && this.customInv['CashDiscount.Expiry.Date'] != '0000-00-00') {
          this.displayDiscount = moment().isBefore(moment(this.customInv['CashDiscount.Expiry.Date'], 'YYYY-MM-DD'));

          const _now = new Date().getTime();

          const target = moment(this.customInv['CashDiscount.Expiry.Date'], 'YYYY-MM-DD').toDate().getTime();

          this.expireTill = target - _now;

          if (this.displayDiscount == true) {
            this.countDown();
          }
        }
      });
    }
  }

  countDown() {
    this.interval = setInterval(() => {
      this.expireTill = this.expireTill - 1000;

      if (this.expireTill > 0) {
        this.cashDiscountDateCounter = this.utils.convertDateObject(this.expireTill / 1000);
      } else {
        this.displayDiscount = false;
        this.cashDiscountDateCounter = null;
        clearInterval(this.interval);
      }
    }, 1000);
  }

  whatIsUrgency() {
    const confirmDialog = new ConfirmDialogSingleButton(
      'fas fa-info',
      'Treatment importance',
      'These stars have been set by your Dentist to indicate the severity of your diagnosis, and gives you an idea of how important it is to have this problem seen to.',
      'Okay'
    );
    const ref = this.dialog.open(ConfirmDialogSingleButtonComponent, {
      data: confirmDialog,
      width: '650px',
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      ref.close();
    });
  }

  getDocuments(e) {
    if (e) {
      this.documents = e;
    }
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  tabClick(tab) {
    if (tab == 1) {
      this.stepTwoVisited = true;
    }

    if (tab == 2) {
      this.stepThreeVisited = true;
      this.displayInclusions();
    }
  }

  changeTab() {
    this.selectedTab += 1;
    if (this.selectedTab >= 4) {
      this.selectedTab = 0;
    }
    window.scrollTo(0, 0);

    if (this.selectedTab == 1) {
      this.stepTwoVisited = true;
    }

    if (this.selectedTab == 2) {
      this.stepThreeVisited = true;
    }

    // console.log('variable is ' + this.canEditProfile);
  }

  backTab() {
    this.selectedTab -= 1;
    window.scrollTo(0, 0);
  }

  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
          <style>
          html, body {
              height: 100%;
          }
          body, html, .mat-button, .mat-raised-button, .mat-icon-button, .mat-card, .mat-list-item, mat-card, .sr-title, div[class*=' mat-'], .mat-table, text, h1, h2, h3, h4, p, table, .table-striped, .dataTable {
              font-family: 'Open Sans', 'Helvetica Neue', sans-serif !important;
          }
          .clearfix:after {
              display: block;
              content: "";
              clear: both;
          }
          .print-only {
              display: block;
            }
            .sr-title, .half-width .lead {
                color: #1b8bdd;
            }
            .toothView {
                width: 30px;
                float: left;
                margin-top: 5px;
            }
            .pull-left {
              float: left;
            }
            .pull-right {
                float: right;
            }
            div.teethShap p {
                color: black;
                margin: 0;
                width: 100%;
                text-align: center;
                font-size: 14px;
                margin: 1px;
            }
            .sm-h3 {
                font-weight: 200;
                font-size: 1.2rem;
                line-height: 1.5rem;
                padding: 1rem 0;
            }
            p {
                font-weight: 300;
            }
            p.lead {
                font-size: 1.5rem;
                font-weight: 400;
                line-height: 1.85rem;
            }
            h2.summary-header.sr-title.rm-mt {
                font-weight: normal;
            }
            .material-icons {
                font-family: 'Material Icons';
                font-weight: normal;
                font-style: normal;
                font-size: 24px;
                line-height: 1;
                letter-spacing: normal;
                text-transform: none;
                display: inline-block;
                white-space: nowrap;
                word-wrap: normal;
                direction: ltr;
                -webkit-font-feature-settings: 'liga';
                -webkit-font-smoothing: antialiased;
            }
            .mat-icon {
                background-repeat: no-repeat;
                display: inline-block;
                fill: currentColor;
                height: 24px;
                width: 24px;
            }
            p.small {
                font-size: .8rem;
                font-style: italic;
                color: #777;
                margin-top: 2px;
            }
            .details mat-icon {
                color: #c5c5c5;
                margin-right: 10px;
                top: -0.8rem;
                position: relative;
             }
            h3.wide-header {
                padding: 10px !important;
                border-radius: 3px;
                color: #fff;
                text-shadow: 0 1px 6px rgba(0, 39, 64, 0.3);
            }
            .primary-light-bg {
                background-color: #bbdefb;
            }
            .description {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -ms-flex-direction: row;
                flex-direction: row;
            }
            .description .qtr-width {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
            }
            .half-width {
                width: 50% !important;
                float: left;
            }
            .qtr-width {
                width: 25% !important;
                float: left;
            }
            .full-width: {
              width: 100%;
            }
            .clearfix::after {
                clear: both;
                display: block;
                content: "";
                height: 0;
            }
            .details.flex {
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: start;
                -ms-flex-pack: start;
                justify-content: flex-start;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -ms-flex-direction: row;
                flex-direction: row;
            }
            .rm-mt {
                margin-top: 0 !important;
                padding-top: 0 !important;
            }
            .rm-mb {
                margin-bottom: 0 !important;
                padding-bottom: 0 !important;
            }
            button {
              display: none;
            }
            app-treatment-list-consumer h2 {
               font-size: 1.2rem;
               margin-top: 0;
            }
            h4.subtitle-h4 {
                margin-top: 5px;
                font-weight: normal;
            }
            .qtr-width.clearfix.flex {
                display: none;
            }
            app-dental-arch-view {
                display: flex;
                margin-bottom: 20px;
            }
            app-dental-arch-view p.tot {
                margin-right: 20px;
            }
            p {
                font-size: 0.9rem;
                color: 585858;
            }
            .text-center {
              text-align: center;
            }
            h2 app-gravatar {
                top: 15px !important;
                position: relative;
                margin-right: 5px;
            }
            img.grav {
                border-radius: 50%;
            }
            hr {
                border: none;
                border-bottom: 2px solid #d4d4d4;
            }
            .printFooter {
              position: absolute;
              bottom: 0;
              background: #fdfdfd;
              width: 100%;
              display: block;
              left:0;
              right: 0;
            }
            .printFooter .mt {
              margin-top: 5px;
            }
            .printFooter p.small {
                font-size: .6rem;
                font-style: italic;
                color: #777;
                margin-top: 2px;
            }
             .printFooter img {
                max-width: 50px;
                float: right;
                margin-top: 10px;
            }
          </style>
        </head>
         <body onload="window.print();window.close()">${printContents}</body>
      </html>`);
    popupWin.document.close();
  }

  openPatientCardChangesModal() {
    const ref = this.dialog.open(PatientCardChangesModalComponent, {
      data: {
        invitationID: this.customInv['ID'],
        force: false,
      },
      width: '500px',
    });

    const sub = ref.componentInstance.done.subscribe((data) => {
      ref.close();
      this.consumerChoice.emit('DSMO');
    });
  }

  displayInclusions() {
    const ref = this.dialog.open(TreatmentInclusionModalComponent, {
      data: {
        // componentTitle: 'Would you like to add any additional treatment?'
      },
      width: '650px',
      panelClass: 'inclusionsCard',
    });

    const sub = ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
  }

  openPatientCardDeferModal() {
    const ref = this.dialog.open(PatientCardDeferModalComponent, {
      data: {
        invitationID: this.customInv['ID'],
        force: false,
      },
      width: '350px',
    });

    const sub = ref.componentInstance.done.subscribe((data) => {
      ref.close();
      this.consumerChoice.emit('DEFER');
      this.deferedWeek.emit(data);
    });
  }

  openPatientCardStopModal() {
    const ref = this.dialog.open(PatientCardStopModalComponent, {
      data: {
        invitationID: this.customInv['ID'],
        force: false,
      },

      width: '500px',
      panelClass: 'flex',
    });

    const sub = ref.componentInstance.done.subscribe((data) => {
      this.consumerChoice.emit('NOT');

      ref.close();
    });
  }

  showTreatCard() {
    this.treatPlan = false;
    this.payOpts = true;
    window.scrollTo(0, 0);
  }

  showPaymentCard() {
    this.treatPlan = true;
    this.payOpts = false;
    window.scrollTo(0, 0);
  }

  confirmNotFincance() {
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: new ConfirmDialog(
        'check_circle',
        'Proceed, and pay at the practice',
        'You are selecting to proceed with your treatment, arranging to settle your bill at the practice using cash or credit card. Are you sure you wish to proceed?',
        'Let me see my options again',
        'Yes, Proceed'
      ),
      width: '500px',
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const ref1 = this.dialog.open(InformedConsentComponent, {
            panelClass: 'infConsent',
          });

          ref1.componentInstance.agreement.subscribe((data) => {
            if (data == true) {
              this.patientService.notFinanceInivation(this.customInv['ID'], false).subscribe((res) => {
                if (res) {
                  NotifyAppComponent.displayToast(
                    'success',
                    'Successful operation',
                    'Your can now proceed and pay at practice'
                  );

                  this.consumerChoice.emit('DISC');

                  ref1.close();
                }
              });
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  // discount

  confirmNotFincanceWithDiscount() {
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: new ConfirmDialog(
        'check_circle',
        'Proceed, and pay at the practice',
        'By selecting to proceed with your treatment and arranging to settle your bill at the practice using cash or credit card before ' +
          this.dataPipe.transform(this.customInv['CashDiscount.Expiry.Date'], null) +
          ', you are eligible for a discount of ' +
          this.currencyPipe.transform(this.customInv['CashDiscount.Offered.Amount'], null, null, null) +
          ' off your treatment. Do you wish to continue?',
        'Let me see my options again',
        'Yes, Proceed'
      ),
      width: '500px',
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const ref1 = this.dialog.open(InformedConsentComponent, {
            panelClass: 'infConsent',
          });

          ref1.componentInstance.agreement.subscribe((data) => {
            if (data == true) {
              this.patientService.notFinanceInivation(this.customInv['ID'], false).subscribe((res) => {
                if (res) {
                  NotifyAppComponent.displayToast(
                    'success',
                    'Successful operation',
                    'Your can now proceed and pay at practice'
                  );

                  this.consumerChoice.emit('DISC');

                  ref1.close();
                }
              });
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  selectProduct(product) {
    const refProduct = this.dialog.open(ProductCalculatorComponent, {
      data: {
        isTop: true,
        dentalInfo: true,
        isProceedButton: true,
        moreInfo: false,
        ProductID: product.ID,
        dynamicAmount: false,
        amount: this.customInv['Treatment.Value'],
      },
      width: '850px',
    });

    refProduct.componentInstance.close.subscribe((res) => {
      refProduct.close();
    });

    refProduct.componentInstance.proceedEvent.subscribe((estimation) => {
      const payload = {
        frequency: Number(estimation['frequency']),
        productID: product.ID,
        duration: Number(estimation['duration']),
        patientContribution: Number(estimation['patientContribution']) || null,
      };

      refProduct.close();
      refProduct.afterClosed().subscribe((res) => {
        const ref = this.dialog.open(InformedConsentComponent, {
          panelClass: 'infConsent',
        });

        ref.componentInstance.agreement.subscribe((data) => {
          if (data == true) {
            ref.close();

            this.invitationService.proceedDental(this.invitationID, payload).subscribe((res) => {
              if (res) {
                NotifyAppComponent.displayToast(
                  'success',
                  'Successfull selected',
                  'You will be redirecting to a page to finish you application '
                );

                if (Settings.global['isWakandaIntegrated'] && Settings.global['isWakandaIntegrated'] === true) {
                  if (product['SubType'] == 'Payment Plan') {
                    let ppLink = Settings.global['wakandaPPLink'];
                    ppLink = ppLink.replace('_id_', this.invitationID);
                    window.open(`${Settings.global['publicSiteFrontendLink']}/thank-you-pp/customer-card?link=${encodeURIComponent(ppLink)}`, '_parent').focus();
                  } else {
                    let laLink = Settings.global['wakandaLALink'];
                    laLink = laLink.replace('_id_', this.invitationID);
                    window.open(`${Settings.global['publicSiteFrontendLink']}/thank-you-la/customer-card?link=${encodeURIComponent(laLink)}`, '_parent').focus();
                  }
                } else {
                  if (product['SubType'] == 'Payment Plan') {
                    let ppLink = Settings.global['wakandaPPLink'];
                    ppLink = ppLink.replace('_id_', this.invitationID);
                    window.open(`${Settings.global['publicSiteFrontendLink']}/thank-you-pp/customer-card?link=${encodeURIComponent(ppLink)}`, '_parent').focus();
                  } else {
                    let laLink = Settings.global['wakandaLALink'];
                    laLink = laLink.replace('_id_', this.invitationID);
                    window.open(`${Settings.global['publicSiteFrontendLink']}/thank-you-la/customer-card?link=${encodeURIComponent(laLink)}`, '_parent').focus();
                  }
                }
              }
            });
          }
        });
      });
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 1023) {
      this.headPos = 'below';
    }
    if (event.target.innerWidth > 1023) {
      this.headPos = 'above';
    }
  }
}
