<div class="card-header primary-gradient-img clearfix inModal">
  <div class="row clearfix">
    <button class="btn-close btn-clear mat-button white" (click)="close()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="row titleArea clearfix">
      <mat-icon class="pull-left">file_copy</mat-icon>
      <h2 class="summary-header rm-m">Cloning an invitation</h2>
    </div>
  </div>
</div>
<mat-dialog-content>
  <div class="row clearfix">
    <img
      class="modal-image"
      src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/clone-invitation.png"
    />
  </div>
  <div class="row clearfix">
    <h3 class="sr-title">
      Choosing this option will duplicate the invitation based on what was previously sent and make a new one.
    </h3>
    <p>
      This powerful and timesaving feature copies your original {{ "KEYWORD.patient" | translate }}'s details, any
      {{ "KEYWORD.treatment" | translate }} items and documents you've selected, as well as the value and any
      personalised messages or settings you
      entered and inserts them into a fresh invitation.
    </p>
    <p>From there, those details can be quickly edited to suit your new invite.</p>
    <p>The original invitation is unaffected, and your new invitation can now be reviewed before sending out.</p>
  </div>

  <div class="row clearfix text-center">
    <mat-checkbox [(ngModel)]="InvitationCloneInfo">Please don't show this message again</mat-checkbox>
  </div>
  <hr class="mb"/>
  <div class="row clearfix text-center">
    <button (click)="doNotShow()" mat-raised-button color="primary">Got It!</button>
  </div>
</mat-dialog-content>
