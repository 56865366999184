import { Component, EventEmitter, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialog } from '../../types/confirm-dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css'],
})
export class ConfirmDialogComponent implements OnInit {
  @Input()
  confirmDialog: any = new ConfirmDialog(
    'delete',
    'Are you sure?',
    '<p>You are about to remove this item.</p> ',
    'No',
    'Yes, remove'
  );
  closeEvent = new EventEmitter();
  onConfirm = new EventEmitter();
  onInformation = new EventEmitter();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.confirmDialog = data;
    }
  }

  ngOnInit() {}

  onYes() {
    this.onConfirm.emit(true);
  }

  onNo() {
    this.onConfirm.emit(false);
  }

  onInformationEvent() {
    this.onInformation.emit(true);
  }

  closeModal() {
    this.closeEvent.emit(true);
  }

  isFontAwsome(icon) {
    if (icon) {
      if (icon.indexOf('fas ') !== -1 || icon.indexOf('fab-') !== -1) {
        return true;
      }

      return false;
    }

    return false;
  }
}
