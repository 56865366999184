import { HostListener } from '@angular/core';

export abstract class ComponentCanDeactivate {
  abstract canDeactivate(): boolean;

  abstract canDeactivateAdd(): boolean;

  abstract canDeactivateEmerg(): boolean;

  abstract canDeactivateContact(): boolean;

  abstract canDeactivatePref(): boolean;

  abstract canDeactivateAddress(): boolean;

  abstract canDeactivateID(): boolean;

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue = true;
    } else if (!this.canDeactivateAdd()) {
      $event.returnValue = true;
    } else if (!this.canDeactivateEmerg()) {
      $event.returnValue = true;
    } else if (!this.canDeactivateContact()) {
      $event.returnValue = true;
    } else if (!this.canDeactivatePref()) {
      $event.returnValue = true;
    } else if (!this.canDeactivateAddress()) {
      $event.returnValue = true;
    } else if (!this.canDeactivateID()) {
      $event.returnValue = true;
    }
  }
}
