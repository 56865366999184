import { animate, keyframes, query, stagger, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { CustomerProspectService } from '../../customer-prospect/shared/customerProspect.service';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { InvitationCustomerProspectEditComponent } from '../invitation-customer-prospect-edit/invitation-customer-prospect-edit.component';
import { InvitationLookupComponent } from '../invitation-lookup/invitation-lookup.component';

import { Location } from '@angular/common';
import { SecurityCodeInfoComponent } from '../../../core/authentication/security-code-info/security-code-info.component';
import { SecurityCodeInput } from '../../../core/authentication/security-code-input/security-code-input.component';
import { ErrorModalComponent } from '../../../core/helper/error-modal/error-modal.component';
import { ConfirmDialogSingleButtonComponent } from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { LookupService } from '../../../shared/services/lookup.service';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { DentistViewComponent } from '../../dentist/dentist-view/dentist-view.component';
import { DentistService } from '../../dentist/shared/dentist.service';
import { MerchantAuthCodeModalComponent } from '../../merchant/merchant-auth-code-modal/merchant-auth-code-modal.component';
import { SmsConversationComponent } from '../../message/sms-conversation/sms-conversation.component';
import { SmsPromoterComponent } from '../../message/sms-promoter/sms-promoter.component';
import { ProductCalculatorComponent } from '../../product/product-calculator/product-calculator.component';
import { ProductService } from '../../product/shared/product.service';
import { InvitationService } from '../shared/services/invitation.service';

@Component({
  selector: 'quick-invitation',
  templateUrl: './quick-invitation.component.html',
  styleUrls: ['./quick-invitation.component.css'],
  animations: [
    trigger('visibilityChanged', [
      state('shown', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0, height: '0' })),
      transition('shown => hidden', animate('600ms')),
      transition('hidden => shown', animate('300ms')),
    ]),
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.2s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class QuickInvitationComponent implements OnInit {
  wizard: WizardComponent;

  @ViewChild('wizard', { static: false }) set wizardContent(content: WizardComponent) {
    if (content) {
      this.wizard = content;
    }
  }

  invitationListExist = false;
  @Input() merchantID;

  context = Settings.global['context']

  wakandaID;
  EPinvitationID;
  isPromoterOrAdmin = false;
  tags = [];
  contactID;
  securityCodeID;
  securityCode;
  medicalHistory = false;
  patientContribution = null;
  @Input()
  displayLookup = true;
  dateOfBirth;
  _dateOfBirth;
  lookupPage = true;
  simpleTreatmentDocuments = [];
  @Input() title = 'An invitation will be sent to patient  ';

  displayList = false;

  @Input() patientID;
  invitationID;
  util = new UtilsClass();
  maxLoanAmount;
  minLoanAmount;
  merchants = [];
  contacts = [];

  genders = [];
  titles = [];
  defaultMessageLists = [];

  isFinDMatch = false;
  addFile = new EventEmitter();
  clearDate = new EventEmitter();
  sendSMS = Settings.global['defaultSendInvitationSMS'];

  isModulePatientHistoryActive = Settings.global['isModulePatientHistoryActive'];
  merchant;

  isModal = false;
  selectedExistingPatientID;

  isPaymentPlan = false;
  patient = {
    firstName: null,
    tableName: null,
    title: null,
    lastName: null,
    middleName: null,
    genderCode: null,
    mobile: null,
    homePhone: null,
    workPhone: null,
    email: null,
  };
  isMobileValid = false;
  isHomePhoneValid = false;
  isWorkPhoneValid = false;
  isEmailValid = false;

  healthHistoryActive = true;
  isModuleGoogleReviewActive = Settings.global['isModuleGoogleReviewActive'];
  isModulePatientPortalAccessActive = Settings.global['isModulePatientPortalAccessActive'];

  complete = false;
  stopFlying = false;
  flyAway = false;

  selectedMerchant;
  selectedSender;

  rawMessage;
  sessionType = 'merchant';

  clearEmail = new EventEmitter();
  clearMobile = new EventEmitter();
  clearHomePhone = new EventEmitter();
  clearWorkPhone = new EventEmitter();

  start = 1;
  totalTreatmentValue;
  productGroup = 'GPF';
  suitableProductAmount;
  showOption = false;
  hideProductList = true;
  documentTypes = ['Treatment Plan', 'Additional Document', 'Image', 'Scan / X-Ray'];

  @Input()
  treatmentDocumentLabel = 'Treatment Document';
  @Input()
  treatmentDocumentDescription;

  noProductFound = false;

  productID;
  frequency;
  duration;
  amount;
  currentUser;
  displayedColumns = [
    'Date',
    'Amount',
    'Product',
    'ProductGroup',
    'InformedConsent',
    'EmailStatus',
    'InvitationStatus',
    'ApplicationStatus',
    'ContractStatus',
    'timeElapsed',
    'Response',
    'Source',
    'Actions',
  ];

  constructor(
    private dentistService: DentistService,
    private customPhonePipe: CustomPhonePipe,
    private invitationService: InvitationService,
    private utilService: UtilsService,
    private merchantService: MerchantService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private lookupService: LookupService,
    private customerProspectService: CustomerProspectService,
    private authenticationService: AuthenticationService,
    private location: Location,
    private productService: ProductService
  ) {}

  ngOnInit() {
    AuthenticationService.healthHistoryAccess.subscribe((r) => {
      this.healthHistoryActive = r;
    });

    this.activeRoute.params.subscribe((params) => {
      if (params['productGroup']) {
        this.productGroup = params['productGroup'];
      }

      if (this.productGroup) {
        this.getLoanAmount(this.productGroup);
      }
      this.authenticationService.getCurrentPractice().subscribe((m) => {
        if (m && m.ID) {
          this.merchantID = m.ID;
        }

        this.lookupService.getLookup('SimpleLookup', 'Salutation').subscribe((res) => {
          this.titles = res;
        });

        this.lookupService.getLookup('CodeLookup', 'Gender').subscribe((res) => {
          this.genders = res;
        });

        this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
          this.isPromoterOrAdmin = isPromoterOrAdmin;

          if (this.isPromoterOrAdmin == true) {
            const payload = {
              fields: 'ID,TradingAs,CalculatedName,Status,HealthHistoryEnabled.Overridden',
            };
            this.merchantService.getList(payload, this.isPromoterOrAdmin).subscribe((res) => {
              this.merchants = res;
            });
          }
          this.utilService.getCurrentAccess().subscribe((access) => {
            if (access) {
              this.isModulePatientHistoryActive = access['isModulePatientHistoryActive'];
              this.isModulePatientPortalAccessActive = access['isModulePatientPortalAccessActive'];
              this.isModuleGoogleReviewActive = access['isModuleGoogleReviewActive'];
            }

            const p = {
              fields: 'ID,Name,FirstName,TradingAs,CalculatedName',
              merchantID: this.merchantID,
              limit: 200,
            };
            this.dentistService.getList(p, this.isPromoterOrAdmin).subscribe((res) => {
              if (res) {
                this.contacts = res;

                this.authenticationService.getCurrentUser().subscribe((res) => {
                  this.currentUser = res;

                  if (this.currentUser.data['contactKey'] && !this.contactID) {
                    this.contactID = this.currentUser.data['contactKey'];
                  }
                });
              }
            });

            this.authenticationService.getSessionType().subscribe((r) => {
              this.sessionType = r;

              this.authenticationService.getCurrentPractice().subscribe((currentPractice) => {
                if (currentPractice) {
                  if (currentPractice && currentPractice['HealthHistoryEnabled.Overridden'] == 'Y') {
                    this.healthHistoryActive = true;
                  } else {
                    this.healthHistoryActive = false;
                  }
                }
              });
            });
          });
        });
      });
    });
  }

  goBack() {
    this.location.back();
  }

  buildPatient(patient) {
    if (patient['Gender.Code']) {
      this.patient.genderCode = patient['Gender.Code'];
    }

    if (patient['Salutation']) {
      this.patient.title = patient['Salutation'];
    }

    if (patient['FirstName']) {
      this.patient.firstName = patient['FirstName'];
    }

    if (patient['tableName']) {
      this.patient.tableName = patient['tableName'];
    }

    if (patient['TableName']) {
      this.patient.tableName = patient['TableName'];
    }

    if (patient['dateOfBirth'] && patient['dateOfBirth'] != '0000-00-00') {
      this.dateOfBirth = patient['dateOfBirth'];
      this._dateOfBirth = patient['dateOfBirth'];
    }

    if (patient['DateOfBirth'] && patient['DateOfBirth'] != '0000-00-00') {
      this.dateOfBirth = patient['DateOfBirth'];
      this._dateOfBirth = patient['DateOfBirth'];
    }

    if (patient['MiddleName']) {
      this.patient.middleName = patient['MiddleName'];
    }

    if (patient['Name']) {
      this.patient.lastName = patient['Name'];
    }

    if (patient['mobiles.Number']) {
      this.patient.mobile = patient['mobiles.Number'];
    }

    if (patient['phone.Home.Number']) {
      this.patient.homePhone = patient['phone.Home.Number'];
    }

    if (patient['phone.Work.Number']) {
      this.patient.workPhone = patient['phone.Work.Number'];
    }

    if (patient['emails.Email']) {
      this.patient.email = patient['emails.Email'];
    }

    if (patient['ID']) {
      this.patientID = patient['ID'];
    }
  }

  validateAmount() {
    this.noProductFound = false;
  }

  changeAmount() {
    this.hideProductList = true;
    this.noProductFound = false;
    if (this.totalTreatmentValue > 0) {
      const payload = {
        amount: this.totalTreatmentValue,
        merchantKey: this.merchant,
        fields: 'ID',
        groupCode: this.productGroup || null,
        orderBy: '',
      };

      this.invitationService.getSuitableProduct(payload).subscribe((res) => {
        if (res && res.length > 0) {
          this.noProductFound = false;

          this.suitableProductAmount = this.totalTreatmentValue;

          UtilsClass.scrollUp();
          this.wizard.goToNextStep();
          this.showOption = true;
          this.hideProductList = false;
        } else {
          this.noProductFound = true;
        }
      });
    }
  }

  checkHH() {
    if (this.merchant && this.merchant['HealthHistoryEnabled.Overridden'] == 'Y') {
      this.healthHistoryActive = true;
    } else {
      this.healthHistoryActive = false;
    }
  }

  selectProduct(product) {
    this.duration = null;
    this.frequency = null;
    this.patientContribution = null;
    this.productID = null;

    if (product != null) {
      this.showCalculator(product);
    } else {
      this.showOption = false;
      this.hideProductList = true;
      const confirmDialog = new ConfirmDialogSingleButton(
        'warning',
        'No products found',
        'Please contact Smile Right for more details.',
        'Go Back'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
        data: confirmDialog,
      });

      ref.componentInstance.onConfirm.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  showCalculator(product) {
    if (product && product.ID) {
      if (product.SubType == 'Payment Plan') {
        this.isPaymentPlan = true;
      }
      const refProduct = RootAppComponent.dialog.open(ProductCalculatorComponent, {
        data: {
          isTop: true,
          dentalInfo: true,
          isProceedButton: true,
          moreInfo: false,
          ProductID: product.ID,
          dynamicAmount: false,
          amount: this.totalTreatmentValue,
        },
        width: '850px',
      });

      refProduct.componentInstance.close.subscribe((res) => {
        refProduct.close();
      });

      refProduct.componentInstance.proceedEvent.subscribe((estimation) => {
        if (this.isPromoterOrAdmin == true) {
          this.calculator(estimation);

          refProduct.close();
          refProduct.afterClosed().subscribe((res) => {
            this.create();
          });
        } else {
          this.merchantService
            .canAssitPatientProduct(this.merchantID, product.ID, this.sessionType)
            .subscribe((res) => {
              if (res) {
                this.calculator(estimation);

                refProduct.close();
                refProduct.afterClosed().subscribe((res) => {
                  this.create();
                });
              } else {
                const refError = RootAppComponent.dialog.open(ErrorModalComponent, {
                  data: {
                    content:
                      `<p><strong>      You do not have access to make this decision on behalf of your patient.</strong></p>` +
                      `<p style="margin:5px" >  Contact the help desk to request permission.</p>` +
                      `<p style="margin:5px" > Alternatively, your patient can proceed with an application for finance themselves by clicking on the links sent to them by email and SMS.
                </p>` +
                      "<p style='margin:5px' >Questions? Please contact us. </p>",
                    title: 'Sorry!',
                    displayContact: true,
                  },

                  width: '700px',
                });
                refError.componentInstance.closeModal.subscribe((data) => {
                  refError.close();
                });
              }
            });
        }
      });
    }
  }

  changeMerchant() {
    this.contactID = null;

    if (this.merchant && this.merchant.ID && this.isPromoterOrAdmin == true) {
      this.merchantID = this.merchant.ID;
      this.checkHH();
      this.merchantService.getMerchantDetails(this.merchantID, {}, this.sessionType).subscribe((res) => {
        if (res) {
          this.selectedMerchant = res;
        }
      });

      const p = {
        fields: 'ID,Name,FirstName,TradingAs,CalculatedName',
        merchantID: this.merchantID,
      };
      this.dentistService.getList(p, this.isPromoterOrAdmin).subscribe((res) => {
        if (res) {
          this.contacts = res;
        }
      });
    }
  }

  viewMerchant() {
    if (this.merchant && this.merchant['ID']) {
      const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
        data: this.merchant['ID'],
        width: '600px',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  getEmail(p) {
    this.patient['email'] = p;
  }

  getMobile(p) {
    this.patient['mobile'] = p;
  }

  getHomePhone(p) {
    this.patient['homePhone'] = p;
  }

  getWorkPhone(p) {
    this.patient['workPhone'] = p;
  }

  validateMobile(v) {
    this.isMobileValid = v;
  }

  validateHomePhone(v) {
    this.isHomePhoneValid = v;
  }

  validateWorkPhone(v) {
    this.isWorkPhoneValid = v;
  }

  validateEmail(v) {
    this.isEmailValid = v;
  }

  openPatientLookUpDialog() {
    const ref = RootAppComponent.dialog.open(InvitationLookupComponent, {
      data: {
        merchantID: this.merchantID,
        onlyMyAccount: true,
        useStrict: true,
      },
      width: '600px',
    });
    const sub = ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });

    ref.componentInstance.patientChat.subscribe((res) => {
      if (res && res.cardID) {
        if (res.isPromoterOrAdmin == true) {
          const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true,
            },
            panelClass: 'noCard',
            width: '500px',
          });
          ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });

          ref2.componentInstance.getResult.subscribe((data) => {
            if (data) {
              const ref3 = RootAppComponent.dialog.open(SmsConversationComponent, {
                data,
                panelClass: 'bigger-screen',
                width: '70%',
                height: '95vh',
              });
              const sub = ref3.componentInstance.close.subscribe((data) => {
                ref3.close();
              });
            }

            ref2.close();
          });
        } else {
          const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true,
            },
            panelClass: 'bigger-screen',
            width: '70%',
            height: '95vh',
          });
          const sub = ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });
        }
      }
    });
    ref.componentInstance.detailedView.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.getSelectedPatient.subscribe((res) => {
      if (res) {
        if (res.ID) {
          this.patientID = res.ID;
          this.buildPatient(res);
          this.isFinDMatch = true;
          ref.close();
        }
      }
    });
  }

  getPatientFromLookup(patient) {
    if (patient && patient.ID) {
      this.lookupPage = false;
      this.patientID = patient.ID;

      this.patient = patient;
    }
  }

  modifyProfile() {
    if (this.patient && this.patientID) {
      const ref = RootAppComponent.dialog.open(InvitationCustomerProspectEditComponent, {
        data: {
          patientID: this.patientID,
        },
        width: '650px',
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res && res.ID) {
          this.buildPatient(res);
          ref.close();
        }
      });
    }
  }

  clearPatientDetails() {
    this.clearEmail.emit(true);
    this.clearMobile.emit(true);
    this.clearHomePhone.emit(true);
    this.clearWorkPhone.emit(true);

    this.patient = {
      firstName: '',
      title: '',
      lastName: '',
      middleName: '',
      genderCode: '',
      mobile: '',
      homePhone: '',
      workPhone: '',
      email: '',
      tableName: null,
    };

    this.patientID = null;
    this.isFinDMatch = false;
    this.dateOfBirth = null;
    this._dateOfBirth = null;

    this.clearDate.emit(true);
  }

  patientProfile() {
    if (this.patientID) {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['customer-profile', this.patientID, 'patient'],
          },
        },
      ]);
    }
  }

  selectDateToFilter(dateTo) {
    this._dateOfBirth = dateTo;
  }

  step1() {
    this.displayList = false;
    this.invitationListExist = false;

    if (!this.patientID) {
      const payload = {
        mobile: this.patient.mobile,
        phone: this.patient.homePhone,
        email: this.patient.email,
        firstName: this.patient.firstName,
        lastName: this.patient.lastName,
        dateOfBirth: this._dateOfBirth,
        merchantID: this.merchantID,
      };
      this.customerProspectService.patientLookup(payload, this.isPromoterOrAdmin).subscribe((res) => {
        if (res && res.length > 0) {
          const patientLookup = RootAppComponent.dialog.open(InvitationLookupComponent, {
            data: {
              patients: res,
              hideFilter: true,
              showNextBtn: true,
            },
            width: '600px',
          });

          patientLookup.componentInstance.closeModal.subscribe((res) => {
            patientLookup.close();
          });
          patientLookup.componentInstance.patientChat.subscribe((res) => {
            if (res && res.cardID) {
              if (res.isPromoterOrAdmin == true) {
                const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
                  data: {
                    merchantID: res.merchantID,
                    cardID: res.cardID,
                    singleChat: true,
                  },
                  panelClass: 'noCard',
                  width: '500px',
                });
                ref2.componentInstance.close.subscribe((data) => {
                  ref2.close();
                });

                ref2.componentInstance.getResult.subscribe((data) => {
                  if (data) {
                    const ref3 = RootAppComponent.dialog.open(SmsConversationComponent, {
                      data,
                      panelClass: 'bigger-screen',
                      width: '70%',
                      height: '95vh',
                    });
                    const sub = ref3.componentInstance.close.subscribe((data) => {
                      ref3.close();
                    });
                  }

                  ref2.close();
                });
              } else {
                const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
                  data: {
                    merchantID: res.merchantID,
                    cardID: res.cardID,
                    singleChat: true,
                  },
                  panelClass: 'bigger-screen',
                  width: '70%',
                  height: '95vh',
                });
                const sub = ref2.componentInstance.close.subscribe((data) => {
                  ref2.close();
                });
              }
            }
          });
          patientLookup.componentInstance.detailedView.subscribe((res) => {
            patientLookup.close();
          });
          patientLookup.componentInstance.getSelectedPatient.subscribe((res) => {
            if (res) {
              if (res.ID) {
                this.patientID = res.ID;
                this.buildPatient(res);
                this.isFinDMatch = true;
                patientLookup.close();

                patientLookup.afterClosed().subscribe((r) => {
                  const payload = {
                    voided: '0',
                    isExpired: false,
                    patientID: this.patientID || null,
                    hasPatientChoice: false,
                    limit: 1,
                    section: 1,
                    merchantID: this.merchantID,
                    productGroupCode: this.productGroup,
                    fields: 'ID',
                  };

                  this.invitationService.getInvitationList(payload, this.isPromoterOrAdmin, false).subscribe((r) => {
                    if (r && r[0] && r[0]['ID']) {
                      this.invitationListExist = true;
                      this.displayList = true;
                      UtilsClass.scrollUp();
                      this.wizard.goToNextStep();
                    } else {
                      this.invitationListExist = false;
                      this.displayList = false;
                      UtilsClass.scrollUp();
                      this.wizard.goToNextStep();
                    }
                  });
                });
              }
            }
          });

          patientLookup.componentInstance.createNew.subscribe((res) => {
            this.invitationListExist = false;
            this.displayList = false;
            UtilsClass.scrollUp();
            patientLookup.close();
            this.wizard.goToNextStep();
          });
        } else {
          UtilsClass.scrollUp();
          this.wizard.goToNextStep();
        }
      });
    } else {
      const payload = {
        voided: '0',
        isExpired: false,
        patientID: this.patientID || null,
        hasPatientChoice: false,
        limit: 1,
        section: 1,
        merchantID: this.merchantID,
        productGroupCode: this.productGroup,
        fields: 'ID',
      };

      this.invitationService.getInvitationList(payload, this.isPromoterOrAdmin, false).subscribe((r) => {
        if (r && r[0] && r[0]['ID']) {
          this.invitationListExist = true;
          this.displayList = true;
          UtilsClass.scrollUp();
          this.wizard.goToNextStep();
        } else {
          this.invitationListExist = false;
          this.displayList = false;
          UtilsClass.scrollUp();
          this.wizard.goToNextStep();
        }
      });
    }
  }

  hideInvitationListEvent() {
    this.displayList = false;
    UtilsClass.scrollUp();
  }

  displayInvitationListEvent() {
    this.displayList = true;
    UtilsClass.scrollUp();
  }

  onCompleteAllSimple(data) {
    if (data && data[0]) {
      this.simpleTreatmentDocuments.push(data[0]);
      this.addFile.emit(data[0]);
    }
  }

  removeSimpleTreatmentDocument(id) {
    if (id) {
      const index = this.simpleTreatmentDocuments.findIndex((item) => {
        if (item && item == id) {
          return true;
        }

        return false;
      });

      if (index != -1) {
        this.simpleTreatmentDocuments.splice(index, 1);
      }
    }
  }

  calculator(d) {
    if (d) {
      if (d.ProductID) {
        this.productID = d.ProductID;
      }
      if (d.amount) {
        this.amount = d.amount;
      }
      if (d.frequency) {
        this.frequency = d.frequency;
      }
      if (d.duration) {
        this.duration = d.duration;
      }
    }
  }

  sendResult(ref = null) {
    const payload = {
      patient_gender: this.patient.genderCode,
      patient_title: this.patient.title,
      patient_first_name: this.patient.firstName,
      patient_middle_name: this.patient.middleName,
      patient_last_name: this.patient.lastName,
      patient_mobile: this.patient.mobile,
      patient_home_phone: this.patient.homePhone,
      patient_work_phone: this.patient.workPhone,
      patient_email: this.patient.email,
      patient_date_of_birth: this._dateOfBirth,
      treatment_value: this.totalTreatmentValue,
      productGroup: this.productGroup,
      dentistID: this.contactID,
      tags: this.tags,
      fileIDs: this.simpleTreatmentDocuments,
      productID: this.productID,
      duration: this.duration,
      frequency: this.frequency,
      patientContribution: this.patientContribution || null,
      securityCodeID: this.securityCodeID,
      securityCode: this.securityCode,
      patientID: this.patientID,
      medicalHistory: this.medicalHistory,
    };

    this.invitationService.createQuickInvitation(payload, this.sessionType).subscribe((res) => {
      if (res && res.ID && res.WakandaID) {
        if (ref) {
          ref.close();
        }
        this.wakandaID = res.WakandaID;
        this.EPinvitationID = res.ID;

        this.invitationPage();
        UtilsClass.scrollUp();
        this.wizard.goToNextStep();

        NotifyAppComponent.displayToast('success', 'Successfull', 'Application is  Created ');

        setTimeout(() => {
          this.flyAway = true;
        }, 1000);
      }
    });
  }

  exitToDash() {
    this.router.navigate(['/merchant']);
  }

  invitationPage() {
    if (this.wakandaID) {
      let invitationCard = Settings.global['wakandaLALink'];
      invitationCard = invitationCard.replace('_id_', this.wakandaID);

      if (this.isPaymentPlan == true) {
        invitationCard = Settings.global['wakandaPPLink'];
        invitationCard = invitationCard.replace('_id_', this.wakandaID);
      }

      window.open(invitationCard, '_blank');
    }
  }

  openInviteViewDetails() {
    if (this.patientID && this.EPinvitationID) {
      this.router.navigate([
        '/merchant',
        { outlets: { page: ['customer-profile', this.patientID, 'treatment', this.EPinvitationID] } },
      ]);
    } else if (this.EPinvitationID) {
      this.router.navigate(['/merchant', { outlets: { page: ['invitation-view', this.EPinvitationID] } }]);
    }
  }

  securityCreate() {
    const description =
      `<p>You need <strong>${this.patient['firstName']} ${this.patient['lastName']}</strong>'s   ` +
      ` consent to assist with the application process.</p>` +
      `<p>If your patient has requested your assistance,   ` +
      `click the button below and a secure code will be sent to your patient's mobile <strong> :  ` +
      `${this.customPhonePipe.transform(this.patient['mobile'])} </strong>. </p>` +
      `<p>Once you have the code, you'll need to enter it on the next screen. </p>` +
      `<p><strong>IMPORTANT - Only request a code if the patient has asked for your assistance.</strong></p>`;

    const ref2 = RootAppComponent.dialog.open(SecurityCodeInfoComponent, {
      data: {
        mobileNumber: this.patient['mobile'],
        description,
      },
      width: '700px',
    });

    ref2.componentInstance.closeModal.subscribe((res) => {
      ref2.close();
    });
    ref2.componentInstance.onConfirm.subscribe((res) => {
      ref2.close();
      ref2.afterClosed().subscribe((afterClosed) => {
        if (res == true) {
          let securityCodeID;
          let securityCode;
          this.invitationService.requestProspectCode(this.patientID).subscribe((res) => {
            if (res) {
              const smsSent = true;
              let emailSent = false;
              if (res && res['Send.Email'] == '1') {
                emailSent = true;
              }
              securityCodeID = res['ID'];
              const ref = RootAppComponent.dialog.open(SecurityCodeInput, {
                data: {
                  smsSent,
                  emailSent,
                },
                width: '600px',
                panelClass: 'noCard',
              });
              ref.componentInstance.closeModal.subscribe((res) => {
                ref.close();
              });
              ref.componentInstance.sendCode.subscribe((code) => {
                this.invitationService.requestProspectCode(this.patientID).subscribe((res) => {
                  securityCodeID = res['ID'];
                  NotifyAppComponent.displayToast('success', 'Successfull', 'Authorisation code is sent again');
                });
              });
              ref.componentInstance.getCode.subscribe((code) => {
                if (code) {
                  securityCode = code;

                  this.securityCode = securityCode;
                  this.securityCodeID = securityCodeID;

                  this.sendResult(ref);
                }
              });
            }
          });
        }
      });
    });
  }

  create() {
    if (this.isPromoterOrAdmin == true) {
      this.sendResult();
    } else {
      if (this.patientID) {
        this.securityCreate();
      } else {
        const p = {
          firstName: this.patient.firstName,
          midleName: this.patient.middleName,
          lastName: this.patient.lastName,
          email: this.patient.email,
          homePhone: this.patient.homePhone,
          workPhone: this.patient.workPhone,
          mobile: this.patient.mobile,
          gender: this.patient.genderCode,
          title: this.patient.title,
          dateOfBirth: this._dateOfBirth,
          merchantID: this.merchantID,
        };

        this.invitationService.createProspect(p, this.sessionType).subscribe((r) => {
          if (r && r.prospectID) {
            this.patientID = r.prospectID;
            this.securityCreate();
          }
        });
      }
    }
  }

  viewDentist() {
    if (this.contactID) {
      const ref = RootAppComponent.dialog.open(DentistViewComponent, {
        data: this.contactID,
        width: '600px',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  getTags(d) {
    this.tags = d || [];
  }

  selectInvitation(id) {
    if (id) {
      this.invitationService
        .getInvitationDetails(id, { field: 'ID,Prospect_key' }, this.sessionType, false)
        .subscribe((res) => {
          if (res && res.ID) {
            const ref = RootAppComponent.dialog.open(MerchantAuthCodeModalComponent, {
              data: {
                merchantID: this.merchantID,
                openInNewTable: false,
                type: 'invitation',
                invitationID: res['ID'],
                patientID: res['Prospect_key'],
              },
              width: '850px',
            });
            const sub = ref.componentInstance.close.subscribe((data) => {
              ref.close();
            });
          }
        });
    }
  }

  getLoanAmount(productCode) {
    if (productCode) {
      const payload = {
        groupCode: productCode,
        merchantID: this.merchantID,
        fields: 'Parameter.MaxLoanValue,Parameter.MinLoanValue',
      };
      this.productService.getList(payload, this.isPromoterOrAdmin).subscribe((res) => {
        if (res && res.length > 0) {
          const temp = res.map((x) => Number(x['Parameter.MaxLoanValue']));
          this.maxLoanAmount = Math.max(...temp) || 0;

          const temp2 = res.map((x) => Number(x['Parameter.MinLoanValue']));
          this.minLoanAmount = Math.min(...temp2) || 0;
        }
      });
    }
  }
}
