import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { TreatmentService } from '../shared/treatment.service';
import { TreatmentBrandViewComponent } from '../treatment-brand-view/treatment-brand-view.component';

import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { treatmentUtil } from '../shared/treatmentUtil';
import { TreatmentCategoryViewComponent } from '../treatment-category-view/treatment-category-view.component';
import { TreatmentProductViewComponent } from '../treatment-product-view/treatment-product-view.component';
import { TreatmentTypeViewComponent } from '../treatment-type-view/treatment-type-view.component';

@Component({
  selector: 'app-treatment-group-document-list',
  templateUrl: './treatment-group-document-list.component.html',
  styleUrls: ['./treatment-group-document-list.component.css'],
})
export class TreatmentGroupDocumentList implements OnInit {
  @Input()
  documentID;

  @Input()
  merchantID;

  @Input()
  title = 'Product List';

  @Input()
  @Input()
  isModal = false;

  @Output()
  close = new EventEmitter();

  treatmentUtil = new treatmentUtil();

  documents = [];
  isAdminOrPromoter = false;

  constructor(
    private authenticationService: AuthenticationService,
    private treatmentService: TreatmentService,
    private activeRoute: ActivatedRoute,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.documentID) {
        this.documentID = data.documentID;
        this.isModal = true;
      }

      if (data.merchantID) {
        this.merchantID = data.merchantID;
      }

      if (data.title) {
        this.title = data.title;
      }
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;

        this.setup();
      });
    });
  }

  setup() {
    const payload = {
      documentID: this.documentID,
      merchantID: this.merchantID,
    };

    this.treatmentService.getTreatmentDocumentList(payload, this.isAdminOrPromoter).subscribe((_res) => {
      const res = _res['data'];
      if (res) {
        this.documents = res;
      }
    });
  }

  viewType(id) {
    const ref = RootAppComponent.dialog.open(TreatmentTypeViewComponent, {
      data: {
        typeID: id,
        isGlobal: true,
      },

      width: '700px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewCategory(id) {
    const ref = RootAppComponent.dialog.open(TreatmentCategoryViewComponent, {
      data: {
        categoryID: id,
      },

      width: '700px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewBrand(id) {
    const ref = RootAppComponent.dialog.open(TreatmentBrandViewComponent, {
      data: {
        brandID: id,
        isGlobal: true,
      },

      width: '700px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewProduct(id) {
    const ref = RootAppComponent.dialog.open(TreatmentProductViewComponent, {
      data: {
        productID: id,
        isGlobal: true,
      },

      width: '800px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  closeModal() {
    this.close.emit(true);
  }
}
