import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

class EpDocumentViewImageState {
  hideZoomButton: boolean;
}

@Injectable()
export class EpDocumentViewImageService extends ComponentStore<EpDocumentViewImageState> {
  constructor() {
    super({ hideZoomButton: false });
  }

  setHideZoomButton(hideZoomButton: boolean) {
    this.patchState({ hideZoomButton });
  }

  getHideZoomButton$() {
    return this.select((state) => state.hideZoomButton);
  }
}
