import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-amount-slider-input',
  templateUrl: './amount-slider-input.component.html',
  styleUrls: ['./amount-slider-input.component.css'],
})
export class AmountSliderInputComponent implements OnInit {
  @Input() amount = 1000;
  @Input() minAmount = 100;
  @Input() maxAmount = 50000;
  @Output() selectAmount = new EventEmitter();

  @Input() step = 100;
  @Input() label = 'Loan Amount';

  @Output() selectPeriodChangeInput = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  changeAmountValue() {
    if (this.maxAmount && Number(this.amount) > this.maxAmount) {
      this.amount = this.maxAmount;
    } else if (this.minAmount && Number(this.amount) < this.minAmount) {
      this.amount = this.minAmount;
    }

    if (this.amount) {
      this.selectAmount.emit(this.amount);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.selectPeriodChangeInput.emit(this.amount);
  }

  updateAmountValue(evt) {
    if (evt.value) {
      this.amount = evt.value;
      this.selectAmount.emit(this.amount);
    }
  }
}
