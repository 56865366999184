<div>
  <aw-wizard class="settlementCreate" [disableNavigationBar]="true" navBarLayout="large-filled-symbols">
    <aw-wizard-step *ngIf="isSkipLookup == false" [navigationSymbol]="{ symbol: '1' }">
      <div class="container" id="step-0" *ngIf="hideLookup == false" @ngIfAnimation>
        <div class="row clearfix text-center">
          <h2 class="sr-title inline-block">{{ 'PatientContractLookup' | translate }}</h2>
        </div>
        <mat-card class="card clearfix full-width">
          <div
            class="row clearfix"
            *ngIf="displayContractList == false && displayCustomerList == false && !customerID && !contractID"
          >
            <div class="card-header error-gradient clearfix inModal mb">
              <div class="row clearfix">
                <!-- merchant calculated name -->
                <div class="row titleArea clearfix noPad">
                  <h2 class="summary-header rm-m">
                    {{ "KEYWORD.patient" | translate | titlecase }} LookUp
                    <span class="subLabel">
                      Search for your {{ "KEYWORD.patient" | translate }} that you wish to process a settlement request
                      for.
                    </span>
                  </h2>
                </div>
              </div>
            </div>

            <!-- <h3 class="sr-title sm-h3 rm-mt">Customer Lookup</h3> -->
            <div class="row clearfix" *ngIf="isPromoterOrAdmin == true && hideMerchantSelect == false">
              <p class="help-label">
                To send an invitation on behalf of a merchant select a
                merchant below, otherwise, the invitation will be sent by the promoter.
              </p>

              <div class="row clearfix selectMerchant flex">
                <mat-form-field class="full-width mb" appearance="outline">
                  <mat-label>Please Select a Merchant</mat-label>
                  <mat-select [(ngModel)]="merchantID" name="MerchantName">
                    <mat-option [value]="null"> My Account</mat-option>
                    <mat-option
                      *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']"
                      [value]="m['ID']"
                    >
                      <span
                        class="list-label pull-right"
                        *ngIf="m['Status'] && m['Status'] == 'Active'"
                        [style.background]="'#00d07a'"
                      >
                        {{ m['Status'] }}
                      </span>

                      <span
                        class="list-label pull-right"
                        *ngIf="m['Status'] && m['Status'] != 'Active'"
                        [style.background]="'#ff5722'"
                      >
                        {{ m['Status'] }}
                      </span>
                      {{ m['TradingAs'] || m['CalculatedName'] }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="full-width" *ngIf="merchantID && merchantID != 'none'">
                  <button class="pull-right merchBtn" (click)="viewMerchant()" mat-raised-button color="primary">
                    <mat-icon>person</mat-icon>
                    <span class="mobHide">View Merchant</span>
                  </button>
                </div>
              </div>
            </div>

            <form class="lookup-form" #lookupForm="ngForm">
              <mat-form-field class="full-width anim" appearance="outline">
                <mat-label>Search by name</mat-label>
                <input [(ngModel)]="filterName" (keyup.enter)="getCustomer()" matInput name="name" tabindex="1"/>
              </mat-form-field>
              <mat-form-field class="full-width anim" appearance="outline">
                <mat-label>Search by Phone</mat-label>
                <input
                  [(ngModel)]="filterPhone"
                  (keyup.enter)="getCustomer()"
                  matInput
                  name="phone"
                  pattern="([0-9]{10})"
                  tabindex="2"
                />
              </mat-form-field>
              <mat-form-field class="full-width anim add-mb" appearance="outline">
                <mat-label>Search by email</mat-label>
                <input
                  [(ngModel)]="filterEmail"
                  (keyup.enter)="getCustomer()"
                  matInput
                  name="email"
                  type="email"
                  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                  tabindex="3"
                />
              </mat-form-field>

              <div class="drop-buttons">
                <button
                  class="inline btn-new-invite btn-large"
                  [disabled]="!lookupForm.form.valid || (filterName == '' && filterPhone == '' && filterEmail == '')"
                  (click)="getCustomer()"
                  type="button"
                  mat-raised-button
                  color="accent"
                  tabindex="4"
                >
                  Search
                </button>
              </div>
            </form>
          </div>

          <div
            class="row clearfix full-width"
            *ngIf="displayCustomerList == true && displayContractList == false && !customerID && !contractID"
          >
            <div class="card-header error-gradient clearfix inModal mb">
              <div class="row clearfix">
                <!-- merchant calculated name -->
                <div class="row clearfix noPad">
                  <h2 class="summary-header rm-m">
                    Possible Match
                    <span class="subLabel">
                      Select a customer below that you intend to process a settlement request for.
                    </span>
                  </h2>
                </div>
              </div>
            </div>
            <div class="full-width clearfix row flex" *ngIf="customers && customers.length > 3">
              <mat-form-field class="full-width anim" appearance="outline">
                <mat-label>Filter: First name, Last Name, Email, phone.</mat-label>
                <input
                  [(ngModel)]="filterCustomer"
                  matInput
                  name="filterCustomer"
                  placeholder="Filter: First name, Last Name, Email, phone."
                />
              </mat-form-field>
            </div>

            <div class="customerContainer full-width clearfix" @ngIfAnimation>
              <!-- customer list item -->
              <div
                class="customerRecord noCard"
                *ngFor="
                  let customer of customers
                    | FilterArrayMultipleValue: ['FirstName', 'Name', 'mobiles.Number', 'emails.Email']:filterCustomer
                    | OrderArrayObject: ['FirstName', 'Name']
                    | unique: 'ID'
                "
                [ngClass]="{ activeExistingcustomer: selectedCustomer && selectedCustomer['ID'] == customer['ID'] }"
                (click)="selectCustomer(customer)"
              >
                <div class="row clearfix anim">
                  <div class="action-button pull-right">
                    <button class="actionMenuToggle" [matMenuTriggerFor]="btnPatMenu" mat-icon-button>
                      <mat-icon>more_vert</mat-icon>
                    </button>

                    <mat-menu #btnPatMenu="matMenu">
                      <button
                        class=""
                        (click)="openCustomerDetailsDialog(customer['ID'])"
                        mat-menu-item
                        color="accent"
                        matTooltip="View Profile: {{ customer['FirstName'] }} {{ customer['Name'] }} "
                      >
                        <mat-icon>visibility</mat-icon>
                        View {{ "KEYWORD.patient" | translate | titlecase }}
                      </button>
                    </mat-menu>
                  </div>

                  <app-customer-view-picture [patientID]="customer['ID']"></app-customer-view-picture>

                  <div class="pull-left">
                    <h3 class="sr-title rm-mb">{{ customer['FirstName'] }} {{ customer['Name'] }}</h3>
                    <div>
                      <p class="small reduce-m" mdLine>
                        <span class="labelName">
                          Phone:
                        </span>
                        <span class="value">
                          {{ customer['mobiles.Number'] | customPhone }}
                        </span>
                      </p>
                    </div>
                    <div>
                      <p class="small reduce-m" mdLine>
                        <span class="labelName">
                          Email:
                        </span>
                        <span class="value">
                          {{ customer['emails.Email'] }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="drop-buttons">
                <button
                  class="step-back pull-left position"
                  (click)="goBackCustomerLlookup()"
                  mat-mini-fab
                  color="primary"
                >
                  <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <button
                  class="inline btn-new-invite btn-large"
                  [disabled]="!selectedCustomer || !selectedCustomer['ID']"
                  (click)="contractLookup()"
                  mat-raised-button
                  color="accent"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <div
            class="row clearfix full-width disCust"
            *ngIf="displayCustomerList == false && displayContractList == true && customerID && !contractID"
          >
            <!-- Customer card header -->
            <div class="cardheader clearfix error-gradient text-left" *ngIf="selectedCustomer" @ngIfAnimation>
              <div class="row clearfix">
                <div class="pull-left">
                  <app-customer-view-picture [patientID]="selectedCustomer['ID']"></app-customer-view-picture>
                </div>
                <div class="pull-left">
                  <div class="row clearfix">
                    <h1 class="lg-h1 sr-title white-color rm-mt">
                      {{ selectedCustomer['FirstName'] }} {{ selectedCustomer['Name'] }}
                    </h1>
                    <div class="anim clearfix full-width">
                      <p class="pull-left rm-mt">
                        <mat-icon>phone</mat-icon>
                        {{ selectedCustomer['mobiles.Number'] | customPhone }}
                      </p>
                    </div>
                    <div class="anim clearfix full-width">
                      <p class="pull-left rm-mt">
                        <mat-icon>email</mat-icon>
                        {{ selectedCustomer['emails.Email'] }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="action-button">
                  <button class="actionMenuToggle" [matMenuTriggerFor]="btnPatMenu" mat-raised-button>
                    Actions
                    <mat-icon>more_vert</mat-icon>
                  </button>

                  <mat-menu #btnPatMenu="matMenu">
                    <button
                      class=""
                      (click)="openCustomerDetailsDialog(selectedCustomer['ID'])"
                      mat-menu-item
                      matTooltip="View Profile: {{ selectedCustomer['FirstName'] }} {{ selectedCustomer['Name'] }} "
                    >
                      <mat-icon>visibility</mat-icon>
                      View {{ "KEYWORD.patient" | translate | titlecase }}
                    </button>
                  </mat-menu>
                </div>
              </div>
            </div>

            <!-- selected customer contract list -->

            <div
              class="customerContainer full-width clearfix"
              *ngIf="!contracts || (contracts && contracts.length <= 0)"
            >
              <app-empty-list-message
                [title]="'No contract found'"
                [message]="'There no active contract for the selected customer'"
              ></app-empty-list-message>
            </div>

            <p class="rm-mt" *ngIf="contracts && contracts.length > 0">Please select a contract from the list below</p>

            <div class="customerContainer full-width clearfix" *ngIf="contracts && contracts.length > 0">
              <!-- contract block repeater -->
              <div
                class="customerRecord contract"
                *ngFor="let contract of contracts"
                @ngIfAnimation
                [ngClass]="{ activeExistingcustomer: selectedContract && selectedContract['ID'] == contract['ID'] }"
                (click)="selectContract(contract)"
              >
                <div class="row clearfix anim">
                  <div class="row clearfix">
                    <div class="half-width">
                      <h3 class="sr-title rm-mb">{{ contract['ProductName'] }}</h3>
                      <p class="small rm-m">
                        <span class="labelName">
                          Applied:
                        </span>
                        <span class="value">
                          {{ contract['ApplicationDate'] | customDate }}
                        </span>
                      </p>
                    </div>
                    <div class="half-width text-right">
                      <label class="labelName rm-mt">
                        Available balance:
                      </label>
                      <p
                        class="value rm-m"
                        [ngStyle]="{ color: contract['Settlements.NotYetDrawn'] > 0 ? '#1ca90c' : 'red' }"
                      >
                        <strong>{{ contract['Settlements.NotYetDrawn'] | customCurrency }}</strong>
                      </p>
                    </div>
                  </div>

                  <span *ngIf="selectedContract && selectedContract['ID'] == contract['ID']" @ngIfGrow>
                    <hr class="animG"/>
                    <div class="row clearfix animG">
                      <div class="half-width">
                        <p class="small rm-mt">
                          <span class="labelName">
                            Total amount financed:
                          </span>
                          <span class="value">
                            {{ contract['Amount.Invoice'] | customCurrency }}
                          </span>
                        </p>
                      </div>
                      <div class="half-width">
                        <div class="col-right">
                          <p class="small rm-mt">
                            <span class="labelName">
                              Paid/Approved for payment:
                            </span>
                            <span class="value">
                              {{ contract['Settlements.Drawn'] | customCurrency }}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row clearfix full-width animG">
                      <div class="half-width">
                        <p class="small rm-mt">
                          <span class="labelName">
                            Settlement deadline:
                          </span>
                          <span
                            class="value"
                            *ngIf="contract['NextDrawDown.Before'] && contract['NextDrawDown.Before'] !== '0000-00-00'"
                          >
                            {{ contract['NextDrawDown.Before'] | customCurrency }}
                          </span>
                        </p>
                      </div>
                      <div class="half-width">
                        <div class="col-right rel">
                          <div class="text-right" *ngIf="isPromoterOrAdmin == true">
                            <button class="actionMenuToggle btn-small" [matMenuTriggerFor]="btnPatMenu" mat-button>
                              Actions
                              <mat-icon>more_vert</mat-icon>
                            </button>

                            <mat-menu #btnPatMenu="matMenu">
                              <button
                                class="darkem"
                                (click)="openContractDetailsDialog(selectedContract['ID'])"
                                mat-menu-item
                              >
                                <mat-icon>visibility</mat-icon> View Contract
                              </button>
                            </mat-menu>
                          </div>
                          <!-- <p class="rm-mt">
                            <span class="labelName">
                              {{"Paid/Approved for payment:" | translate}}
                            </span>
                            <span class="value">
                              {{ contract['Settlements.Drawn'] | customCurrency}}
                            </span>
                          </p> -->
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div class="drop-buttons">
              <button
                class="step-back pull-left position"
                *ngIf="isSkipLookup == false && customers && customers.length > 0"
                (click)="goBackCustomerList()"
                mat-mini-fab
                color="primary"
              >
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button>

              <button
                class="inline btn-new-invite btn-large"
                [disabled]="!selectedContract || !selectedContract['ID']"
                (click)="requestSettlement()"
                mat-raised-button
                color="accent"
              >
                Create Refund Request
                <mat-icon>chevron_right</mat-icon>
              </button>
            </div>
          </div>
        </mat-card>
      </div>
      <div class="container" *ngIf="hideLookup == true">
        <div class="row clearfix add-mt text-center full-width">
          <!-- css loader -->
          <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <!-- /css loader -->
        </div>
      </div>
    </aw-wizard-step>
    <aw-wizard-step [navigationSymbol]="{ symbol: '2' }">
      <!-- Step 1  -->

      <div class="container" id="step-1" *ngIf="selectedContract">
        <div class="row clearfix text-center">
          <h2 class="accent-color inline-block">Create New Refund Request</h2>
        </div>

        <div class="row clearfix">
          <div class="thrd-width">
            <div class="col-left">
              <mat-card class="display">
                <div class="row clearfix rel">
                  <div class="total anim">
                    <span class="totLab">Available</span>
                    <span class="balance" [endVal]="balance" countUp></span>
                    <!-- <span class="totSub">Total</span> -->
                  </div>
                  <app-chart-input
                    class="row"
                    [colorScheme]="charColors"
                    [data]="settlementChart"
                    [chartType]="'pie'"
                    [title]="''"
                    [autoScale]="true"
                    [showLegend]="false"
                    [showLabel]="false"
                  ></app-chart-input>
                </div>
                <mat-divider></mat-divider>
                <mat-list>
                  <h4 class="sr-title rm-mt">Finance details</h4>
                  <mat-list-item class="totFin">
                    Total amount financed: <strong>{{ selectedContract['Amount.Invoice'] | customCurrency }}</strong>
                  </mat-list-item>
                  <mat-list-item class="less">
                    Paid to {{"KEYWORD.practice" | translate}}:
                    <strong matTooltip="Less">
                      <mat-icon>remove</mat-icon>
                      {{ toNumber(selectedContract['Amount.Invoice']) - toNumber(balance) | customCurrency }}
                    </strong>
                  </mat-list-item>

                  <mat-list-item class="avail">
                    Available balance: <strong [ngStyle]="{ color: '#1ca90c' }">{{ balance | customCurrency }}</strong>
                  </mat-list-item>

                  <mat-list-item class="less">
                    Requested/Pending:
                    <strong *ngIf="selectedContract && selectedContract.stats" matTooltip="Less">
                      <mat-icon>remove</mat-icon>

                      <span
                        [class.alertPendingPayment]="
                          toNumber(
                            selectedContract.stats
                              | FilterArrayMultipleValue: 'ID':settlementPendingStatus
                              | objectPropertyValue: 'Sum(Settlement.Amount)'
                              | sum
                          ) > toNumber(selectedContract['Settlements.NotYetDrawn'])
                        "
                      >
                        {{
                        selectedContract.stats
                          | FilterArrayMultipleValue: 'ID':settlementPendingStatus
                          | objectPropertyValue: 'Sum(Settlement.Amount)'
                          | sum
                          | customCurrency
                        }}
                      </span>
                    </strong>
                    <strong *ngIf="!selectedContract || !selectedContract.stats" matTooltip="Less">
                      <mat-icon>remove</mat-icon>

                      <span>
                        {{ 0 | customCurrency }}
                      </span>
                    </strong>
                  </mat-list-item>
                </mat-list>
                <div class="row clearfix" *ngIf="settlementAmount" @onChangeAnimation>
                  <hr class="animate"/>
                  <mat-list>
                    <h4 class="sr-title rm-mt">Request details</h4>
                    <mat-list-item class="animate totFin">
                      Refund Requested: <strong>{{ settlementAmount | customCurrency }}</strong>
                    </mat-list-item>
                  </mat-list>
                </div>
              </mat-card>
            </div>
          </div>
          <div class="two-thrd-width">
            <mat-card class="red-heading">
              <div class="card-header error-gradient clearfix inModal">
                <div class="row clearfix">
                  <!-- merchant calculated name -->
                  <div class="row titleArea clearfix">
                    <app-customer-view-picture [patientID]="selectedContract['Customer_key']">
                    </app-customer-view-picture>

                    <h2 class="summary-header rm-m">
                      Refund request for
                      <strong
                      ><span class="patientCalculatedName" *ngIf="contractID">{{
                        selectedContract['Customer.FullName']
                        }}</span></strong
                      >.
                    </h2>
                  </div>
                </div>
                <div class="action-button">
                  <button class="actionMenuToggle" [matMenuTriggerFor]="btnPatMenu" mat-raised-button>
                    Actions <mat-icon>more_vert</mat-icon>
                  </button>

                  <mat-menu #btnPatMenu="matMenu">
                    <button
                      *ngIf="customerID"
                      (click)="openCustomerDetailsDialog(customerID)"
                      mat-menu-item
                      matTooltip="View customer"
                      color="primary"
                    >
                      <mat-icon>person</mat-icon> Customer
                    </button>
                    <mat-divider></mat-divider>
                    <button
                      *ngIf="contractID"
                      (click)="openContractDetailsDialog(contractID)"
                      mat-menu-item
                      matTooltip="View contract"
                      color="primary"
                    >
                      <mat-icon>visibility</mat-icon> Contract
                    </button>
                    <mat-divider></mat-divider>
                    <button
                      *ngIf="
                        contractID &&
                        toNumber(
                          selectedContract.stats
                            | FilterArrayMultipleValue: 'ID':settlementPendingStatus
                            | objectPropertyValue: 'Count(*)'
                            | sum
                        ) > 0
                      "
                      (click)="settlementList(contractID, 'pending')"
                      matTooltip="Requested/Pending settlement list"
                      mat-menu-item
                      color="accent"
                    >
                      <mat-icon>list_alt</mat-icon>
                      Requested/Pending
                      <span class="count-settlement" color="accent"
                      >{{
                        toNumber(
                          selectedContract.stats
                            | FilterArrayMultipleValue: 'ID':settlementPendingStatus
                            | objectPropertyValue: 'Count(*)'
                            | sum
                        )
                        }}
                      </span>
                    </button>
                    <mat-divider
                      *ngIf="
                        contractID &&
                        toNumber(
                          selectedContract.stats
                            | FilterArrayMultipleValue: 'ID':settlementPendingStatus
                            | objectPropertyValue: 'Count(*)'
                            | sum
                        ) == 0 &&
                        toNumber(selectedContract.stats | objectPropertyValue: 'Count(*)' | sum) > 0
                      "
                    >
                    </mat-divider>
                    <button
                      *ngIf="
                        contractID &&
                        toNumber(
                          selectedContract.stats
                            | FilterArrayMultipleValue: 'ID':settlementPendingStatus
                            | objectPropertyValue: 'Count(*)'
                            | sum
                        ) == 0 &&
                        toNumber(selectedContract.stats | objectPropertyValue: 'Count(*)' | sum) > 0
                      "
                      (click)="settlementList(contractID, 'all')"
                      matTooltip="Settlementlist"
                      mat-menu-item
                      color="accent"
                    >
                      <mat-icon>list_alt</mat-icon>
                      Settlement(s)
                      <span class="count-settlement" color="accent">{{
                        toNumber(selectedContract.stats | objectPropertyValue: 'Count(*)' | sum)
                        }}</span>
                    </button>
                  </mat-menu>
                </div>
              </div>
            </mat-card>

            <!-- start accordion -->
            <form #checkForm="ngForm">
              <mat-accordion class="card">
                <!-- ACCORDION STEP ONE - DEFINE AMOUNT -->
                <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle="true">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <h3 class="sm-h3 sr-title accent-color">
                        <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon> Enter refund value
                      </h3>
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <div class="row clearfix contentPanel">
                    <p class="larger rm-mt">
                      To get started, enter the amount you wish to refund to
                      {{ selectedContract['Customer.FirstName'] }}.
                    </p>
                    <div class="row clearfix icon">
                      <mat-icon class="fas fa-hand-point-right accent-color pull-left bounceHoriz"></mat-icon>
                      <!-- Settlement Value -->
                      <mat-form-field class="full-width anim large-input pull-left" appearance="outline">
                        <mat-label>Refund amount</mat-label>

                        <span class="prefix-dollar inputPrefix" matPrefix>$ &nbsp;</span>
                        <input
                          [(ngModel)]="settlementAmount"
                          [formControl]="settlementAmountInput"
                          type="number"
                          matInput
                          name="settlementAmount"
                          placeholder="Please enter amount between 0 and {{
                            selectedContract['Settlements.NotYetDrawn']
                          }}"
                          required
                          color="accent"
                        />
                        <mat-error
                        >Please enter amount between 0 and
                          {{ selectedContract['Settlements.NotYetDrawn'] }}</mat-error
                        >
                      </mat-form-field>
                    </div>
                    <!-- <div class="row clearfix mt">

                        <div class="thrd-width">
                          <app-date-input class="thin" [label]="'Settlement date:'" [minDate]="minDate" [defaultDate]="minDate"></app-date-input>
                        </div>
                        <div class="two-thrd-width">
                          <div class="col-right">
                            <p class="small rm-m"><strong>Note:</strong> If you set the settlement date to a value other
                              than today's
                              date, it will schedule the settlement to be performed on the future date specified.</p>
                          </div>
                        </div>
                      </div> -->
                  </div>

                  <mat-action-row>
                    <button
                      class="btn-large pull-left position"
                      *ngIf="isSkipLookup == false && contracts && contracts.length > 0"
                      (click)="goBackContractList()"
                      mat-raised-button
                      color="primary"
                    >
                      <mat-icon>keyboard_arrow_left</mat-icon> Back
                    </button>

                    <button
                      class="btn-large"
                      [disabled]="!settlementAmount || settlementAmount > balance"
                      (click)="nextStep()"
                      mat-raised-button
                      color="accent"
                    >
                      Next
                      <mat-icon>chevron_right</mat-icon>
                    </button>
                  </mat-action-row>
                </mat-expansion-panel>

                <!-- ACCORDION STEP FOUR - CONFIRMATION SELECT -->
                <mat-expansion-panel
                  [expanded]="step === 1"
                  [disabled]="!settlementAmount || isConfirmed == false"
                  (opened)="setStep(1)"
                  hideToggle="true"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <h3 class="sm-h3 sr-title accent-color rm-p">
                        <mat-icon class="fas fa-user-check"></mat-icon> Confirm with your patient
                      </h3>
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <!-- disclaimer text -->
                  <div class="row clearfix contentPanel">
                    <div class="row clearfix">
                      <div class="row clearfix">
                        <img
                          class="leftImg"
                          src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/character/pen-and-phone-dentist.png"
                        />
                        <!-- <hr class="rm-mt mb"> -->
                        <div class="row clearfix flex align-top">
                          <!-- <button [disabled]=" ! settlementAmount  ||  settlementAmount<=0 ||  settlementAmount>balance"
                            mat-button class="help-btn" (click)="previewMessage()"
                            matTooltip="What will my customer see?">
                            <mat-icon>help_outline</mat-icon>
                          </button> -->
                          <p class="larger rm-m">
                            To authorise this request, your {{ "KEYWORD.patient" | translate }} can choose to receive a
                            SMS code to their mobile and quote it back to you to, or you can print a physical form to
                            sign.
                          </p>
                        </div>
                        <!-- <div class="row clearfix">
                          <button mat-raised-button class="mt pull-right" color="accent" (click)="saveDraft()" [disabled]="!contractID || !checkForm.valid  || ! settlementAmount  ||  settlementAmount<=0 ||  settlementAmount>balance">
                            <mat-icon class="fas fa-save"></mat-icon>Save as Draft
                          </button>
                        </div> -->
                      </div>
                    </div>
                  </div>

                  <mat-action-row>
                    <button class="" (click)="prevStep()" mat-raised-button color="primary">
                      <mat-icon>chevron_left</mat-icon> Previous
                    </button>
                    <!-- print a form -->
                    <button
                      class="pull-right increase-margin"
                      [disabled]="
                        !contractID ||
                        !checkForm.valid ||
                        !settlementAmount ||
                        settlementAmount <= 0 ||
                        settlementAmount > balance
                      "
                      (click)="proceedWithForm()"
                      mat-raised-button
                      color="accent"
                    >
                      <mat-icon class="fas fa-print"></mat-icon>&nbsp; Get Form
                      <mat-icon>chevron_right</mat-icon>
                    </button>

                    <!-- send a sms -->
                    <button
                      class="pull-right increase-margin"
                      [disabled]="
                        !contractID ||
                        !checkForm.valid ||
                        !settlementAmount ||
                        settlementAmount <= 0 ||
                        settlementAmount > balance
                      "
                      (click)="proceedWithCode()"
                      mat-raised-button
                      color="accent"
                    >
                      <mat-icon class="fas fa-mobile-alt"></mat-icon>&nbsp; Send Code <mat-icon>chevron_right</mat-icon>
                    </button>
                  </mat-action-row>
                </mat-expansion-panel>
              </mat-accordion>
            </form>
          </div>
        </div>
      </div>

      <!--  / Step 1  -->
    </aw-wizard-step>
    <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" awOptionalStep>
      <!--  Step 2 with sms -->
      <div class="container animated fadeInDown clearfix" id="step-2">
        <form #smsForm="ngForm">
          <div class="row clearfix text-center">
            <h2 class="sr-title inline-block rm-mt">Request authorisation</h2>
          </div>

          <div class="row clearfix" *ngIf="option == 'securityCode'">
            <div class="column">
              <mat-card>
                <div class="cardheader error-gradient">
                  <p class="lead rm-mt"><strong>1.</strong> Authorise Refund request</p>

                  <p>
                    An Authorisation code has been sent to your customer via
                    <Strong *ngIf="emailSent == false && smsSent == true">SMS</Strong>
                    <Strong *ngIf="emailSent == true && smsSent == false">and Email</Strong>

                    <Strong *ngIf="emailSent == true && smsSent == true"> SMS and Email</Strong>
                    , please get them to recite the code and enter it here.
                  </p>

                  <p>The code will be valid for {{ settings['securityCodeLifeTime'] || 60 }} min(s)</p>
                </div>
                <div class="clearfix">
                  <div class="clearfix">
                    <mat-form-field class="full-width large-input rm-mb" appearance="outline">
                      <mat-label>Enter authorisation code here</mat-label>
                      <input
                        class="mat-block"
                        [(ngModel)]="securityCode"
                        matInput
                        placeholder="Enter authorisation code here"
                        name="smsCode"
                        required
                        color="accent"
                      />
                    </mat-form-field>
                  </div>
                  <div class="full-width text-center">
                    <p class="small small">
                      Didn't recieve your code? Wait <strong>{{ timeLeft }}</strong> seconds and try sending again.
                    </p>
                    <button
                      class="btn-small clearfix"
                      [disabled]="timeLeft > 0"
                      (click)="sendCodeAgain()"
                      mat-raised-button
                      color="accent"
                    >
                      Send Authorisation Code Again
                    </button>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>

          <div class="row clearfix" *ngIf="option == 'document'">
            <div class="column">
              <mat-card class="clearfix">
                <div class="cardheader error-gradient">
                  <p class="lead rm-mt"><strong>1.</strong> Authorise refund request</p>

                  <p>
                    If you have given your customer an Authorisation form to sign, please scan it in and then upload it
                    using the uploader below.
                  </p>
                </div>
                <div class="clearfix full-width">
                  <!-- <app-file-uploader></app-file-uploader> -->
                  <!--

                    <app-blob-view *ngIf="authorisationFormContent" class="full-width" [content]="authorisationFormContent"
                      [label]="'Authorisation Formfor '+selectedContract['Customer.FullName']" [extension]="'pdf'"></app-blob-view> -->

                  <label class="mb clearfix"
                  >Please upload the signed {{ "KEYWORD.patient" | translate }} authorisation form in the field below to
                    continue.
                  </label>

                  <app-file-uploader
                    class="text-center clearfix"
                    class="text-center"
                    [uploadDirectly]="true"
                    [isDragable]="true"
                    [limit]="1"
                    (onCompleteAll)="onCompleteAllForm($event)"
                  ></app-file-uploader>

                  <app-file-list
                    class="compact"
                    [hasExtraInfo]="false"
                    [canEdit]="true"
                    [canAction]="false"
                    [files]="[settlementfileID]"
                    (remove)="removeAuthorisationForm($event)"
                  ></app-file-list>
                </div>
              </mat-card>
            </div>
          </div>

          <div class="row clearfix">
            <div class="column">
              <div class="full-width step-button-row text-center">
                <button class="step-back" (click)="goBackCreateSettlement()" mat-mini-fab color="primary">
                  <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <div class="pull-right">
                  <button
                    class="step-next"
                    class="btn-large"
                    *ngIf="option == 'securityCode'"
                    [disabled]="!smsForm.valid"
                    (click)="validateSettlementCode()"
                    mat-raised-button
                    color="accent"
                  >
                    Authorise Refund
                    <mat-icon>chevron_right</mat-icon>
                  </button>

                  <button
                    class="step-next"
                    class="btn-large"
                    *ngIf="option == 'document'"
                    [disabled]="!contractID || !settlementfileID"
                    (click)="createSettlement()"
                    mat-raised-button
                    color="accent"
                  >
                    Authorise Refund
                    <mat-icon>chevron_right</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </aw-wizard-step>
    <!-- The completion step -->
    <aw-wizard-completion-step [navigationSymbol]="{ symbol: '4' }">
      <!--  Step 4 - finished -->
      <div class="container animated fadeInDown" id="step-4" *ngIf="settlementObject">
        <div class="row clearfix">
          <div class="full-width text-center">
            <mat-card>
              <h1 class="sr-title sm-h1" *ngIf="settlementObject && settlementObject['Status.Code'] == 'REQU'">
                Refund request is being processed
              </h1>

              <h1 class="sr-title sm-h1" *ngIf="settlementObject && settlementObject['Status.Code'] == 'PEND'">
                Draft Refund created
              </h1>

              <hr class="mt"/>
              <div class="svg-box">
                <svg class="circular green-stroke">
                  <circle class="path" cx="75" cy="75" r="50" fill="none" stroke-width="5" stroke-miterlimit="10" />
                </svg>
                <svg class="checkmark green-stroke">
                  <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                    <path class="checkmark__check" fill="none" d="M616.306,283.025L634.087,300.805L673.361,261.53" />
                  </g>
                </svg>
              </div>

              <p *ngIf="settlementObject && settlementObject['Status.Code'] == 'PEND'">
                You can update the invoice details for this Refund anytime by clicking on
                <strong> Continue Settlement Request</strong> on <strong>Actions</strong> , or click on
                <strong>contract details </strong> for more options.
              </p>
              <p *ngIf="isPromoterOrAdmin == true && settlementObject['Status.Code'] == 'REQU'">
                As promoter you can Approve/Decline this settlement by clicking on <strong>Actions</strong>, or click on
                <strong>contract details</strong> for more options.
              </p>

              <mat-list class="small" *ngIf="settlementObject">
                <mat-list-item class="odd mb">
                  Status:
                  <span
                    class="chip"
                    [class.approved]="settlementObject['Status.Code'] == 'APPR'"
                    [class.cancelled]="settlementObject['Status.Code'] == 'WDRN'"
                    [class.declined]="settlementObject['Status.Code'] == 'DECL'"
                    [class.requested]="settlementObject['Status.Code'] == 'REQU'"
                    [class.pending]="settlementObject['Status.Code'] == 'PEND'"
                  >
                    {{ settlementObject['Status.Label'] }}
                  </span>
                </mat-list-item>

                <mat-list-item class="even">
                  Date of Request: <strong>{{ settlementObject['Settlement.Date'] | customDate }}</strong>
                </mat-list-item>
                <mat-list-item class="odd">
                  Invoice Number: <strong>{{ settlementObject['Invoice.Reference'] }}</strong>
                </mat-list-item>
                <mat-list-item class="even">
                  Invoice Amount: <strong>{{ settlementObject['Invoice.Amount'] | customCurrency }}</strong>
                </mat-list-item>
                <mat-list-item class="even">
                  Date of Invoice: <strong>{{ settlementObject['Invoice.Date'] | customDate }}</strong>
                </mat-list-item>
                <mat-list-item class="odd">
                  Available Balance:
                  <strong
                  >{{ toNumber(balance) - toNumber(settlementObject['Settlement.Amount']) | customCurrency }}
                  </strong>
                </mat-list-item>
                <mat-list-item
                  class="avail"
                  *ngIf="
                    (settlementObject && !settlementObject.groupArray) ||
                    (settlementObject && settlementObject.groupArray && settlementObject.groupArray.length <= 0)
                  "
                >
                  Refund Value: <strong>{{ settlementObject['Settlement.Amount'] | customCurrency }}</strong>
                </mat-list-item>

                <mat-list-item
                  class="avail"
                  *ngIf="settlementObject && settlementObject.groupArray && settlementObject.groupArray.length > 0"
                >
                  Refund Value:
                  <strong>{{
                    settlementObject.groupArray
                      | objectPropertyValue: 'Settlement.Amount'
                      | toNumberArray
                      | sum
                      | customCurrency
                    }}</strong>
                </mat-list-item>
              </mat-list>
              <hr class="mb"/>
              <p class="larger">You will recieve confirmation via email when the Refund is successfully processed.</p>
            </mat-card>
          </div>
        </div>
        <div class="row clearfix">
          <div class="full-width text-center step-button-row">
            <button
              class="step-next mr"
              *ngIf="settlementObject && settlementObject['ID']"
              (click)="viewSettlement()"
              mat-raised-button
              color="accent"
            >
              <mat-icon>visibility</mat-icon>
              View Refund
            </button>

            <button
              class="full-back ml"
              *ngIf="settlementObject && settlementObject['ID']"
              (click)="contractDetails()"
              mat-raised-button
              color="primary"
            >
              Contract Details
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <!--  / Step 3  -->
    </aw-wizard-completion-step>
  </aw-wizard>
</div>
