import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../authentication/shared/authentication.service';

@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.css'],
})
export class Page404Component implements OnInit {
  @Input()
  errorTitle = 'Error! The page you are looking for can not be found';

  @Input()
  errorContent = 'There might be a problem with the address you are looking for, or the page may have moved.';

  @Input()
  displayContact = true;

  type;

  constructor(private location: Location, private activeRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['errorTitle']) {
        this.errorTitle = params['errorTitle'];
      }

      if (params['errorContent']) {
        this.errorContent = params['errorContent'];
      }
      if (params['type']) {
        this.type = params['type'];
      }

      AuthenticationService.setDrift.emit('errorDrift');
    });
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  contactUs() {
    AuthenticationService.contactUSEvent.emit(true);
  }
}
