/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dental-customer-product-form.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./dental-customer-product-form.component";
var styles_DentalCustomerProductFormComponent = [i0.styles];
var RenderType_DentalCustomerProductFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DentalCustomerProductFormComponent, data: {} });
export { RenderType_DentalCustomerProductFormComponent as RenderType_DentalCustomerProductFormComponent };
export function View_DentalCustomerProductFormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["dental-product-form works!"]))], null, null); }
export function View_DentalCustomerProductFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-dental-customer-product-form", [], null, null, null, View_DentalCustomerProductFormComponent_0, RenderType_DentalCustomerProductFormComponent)), i1.ɵdid(1, 114688, null, 0, i2.DentalCustomerProductFormComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DentalCustomerProductFormComponentNgFactory = i1.ɵccf("ipv-dental-customer-product-form", i2.DentalCustomerProductFormComponent, View_DentalCustomerProductFormComponent_Host_0, {}, {}, []);
export { DentalCustomerProductFormComponentNgFactory as DentalCustomerProductFormComponentNgFactory };
