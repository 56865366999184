import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ContractService } from '../shared/contract.service';

@Component({
  selector: 'app-contract-detail-kpi',
  templateUrl: './contract-detail-kpi.component.html',
  styleUrls: ['./contract-detail-kpi.component.css'],
})
export class ContractDetailKPIComponent implements OnInit {
  @Input()
  contractObject;

  @Input()
  contractID;

  isAdminOrPromoter = false;
  contract;
  quotedKPISummary;
  currentKPISummary;

  constructor(
    private activeRoute: ActivatedRoute,
    private contractService: ContractService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['contractID']) {
        this.contractID = params['contractID'];
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;
        if (res == true) {
          if (this.contractObject && typeof this.contractObject == 'object') {
            this.contract = this.contractObject;
            this.setup();
          }

          if (this.contractID) {
            this.contractService.fetchContractDetails$(this.contractID, {}, this.isAdminOrPromoter).subscribe((res) => {
              this.contract = res;
              this.setup();
            });
          }
        }
      });
    });
  }

  setup() {
    this.contractService.getContractKPI(this.contract['ID']).subscribe((res) => {
      this.quotedKPISummary = res['KPI.Stats.AsQuoted']['AsQuoted'];
      this.currentKPISummary = res['KPI.Stats.Current']['AsOfNow'];
    });
  }

  toNumber(value) {
    return Number(value);
  }
}
