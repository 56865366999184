import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { environment } from 'environments/environment';
import { LookupService } from '../../../shared/services/lookup.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { BeneficiaryService } from '../shared/beneficiary.service';

import { Location } from '@angular/common';
import { BeneficiaryViewComponent } from '../beneficiary-view/beneficiary-view.component';

import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { SideMenuService } from '../../../shared/services/side-menu.service';

@Component({
  selector: 'app-beneficiary-subsidiary-create-edit',
  templateUrl: './beneficiary-subsidiary-create-edit.component.html',
  styleUrls: ['./beneficiary-subsidiary-create-edit.component.css'],
})
export class BeneficiarySubsidiaryCreateEditComponent implements OnInit {
  @Input()
  beneficiaryID;

  genders = [];
  titles = [];
  companyTypes = [];

  beneficiaryCreated;

  beneficiary = {
    abn: null,
    acn: null,
    mobile: null,
    phone: null,
    email: null,
    suburb: null,
    state: null,
    postCode: null,
    streetNumber: null,
    streetName: null,
    unitNumber: null,
    streetType: null,
    latitude: null,
    longitude: null,
    fileID: null,
    tradingAs: null,
    title: null,
    firstName: null,
    midleName: null,
    lastName: null,
    occupation: null,
    companyType: null,
    url: null,
    cardType: 'Commercial - Company',
    trusteeFor: null,
    supplierName: null,
    facebook: null,
    twitter: null,
    linkedIn: null,
    instagram: null,
  };

  isAdressValid = false;
  validateMobile = false;
  validateBank = false;
  validatePhone = false;
  validateHomePhone = false;
  validateOccupation = false;
  validateEmail = false;
  isFile = false;
  src;
  isEdit = false;
  supplierLabel;
  isCreateBank = false;
  loading = false;
  profileLink;

  isAdminOrPromoter = false;

  cardTypes = [
    'Consumer - Individual',
    'Commercial - Sole-Trader',
    'Commercial - Company',
    'Commercial - Partnership',
    'Commercial - Trust',
  ];
  @ViewChild(WizardComponent, { static: true }) public wizard: WizardComponent;

  constructor(
    private activeRouter: ActivatedRoute,
    private utilsService: UtilsService,
    private router: Router,
    private lookupService: LookupService,
    private beneficiaryService: BeneficiaryService,
    private authenticationService: AuthenticationService,
    private location: Location
  ) {}

  ngOnInit() {
    SideMenuService.goBack.subscribe((res) => {
      if (res == true) {
        this.wizard.goToStep(1);
      }
    });

    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isAdminOrPromoter = res;
    });

    this.activeRouter.params.subscribe((params) => {
      if (params['beneficiaryID']) {
        this.beneficiaryID = params['beneficiaryID'];

        this.isEdit = true;
      }

      if (this.beneficiaryID) {
        this.getProfilePicture();

        this.beneficiaryService.getBeneficiaryDetails(this.beneficiaryID).subscribe((res) => {
          if (res) {
            this.beneficiary = {
              abn: res['ABN'],
              acn: res['ACN'],
              mobile: res['mobiles.Number'],
              phone: res['Phone.Work.Number'],
              email: res['emails.Email'],
              suburb: res['addresses.Suburb'],
              state: res['addresses.State'],
              postCode: res['addresses.Postcode'],
              streetNumber: res['addresses.Street Nr'],
              streetName: res['addresses.Street Name'],
              unitNumber: res['addresses.Unit'],
              streetType: res['addresses.Street Type'],
              latitude: res['Address.Latitude'],
              longitude: res['Address.Longitude'],
              fileID: null,
              tradingAs: res['TradingAs'],
              title: res['Salutation'],
              firstName: res['FirstName'],
              midleName: res['MiddleName'],
              lastName: res['MaternalName'],
              occupation: res['Occupation'],
              companyType: res['CompanyType'],
              url: res['URL'],
              cardType: res['CardType'],
              trusteeFor: res['TrusteeFor'],
              supplierName: res['Name'],
              facebook: res['Facebook'],
              twitter: res['Twitter'],
              linkedIn: res['LinkedIn'],
              instagram: res['Instagram'],
            };

            if (res['Label']) {
              this.supplierLabel = res['Label'];
            }
          }
        });
      }

      this.lookupService.getLookup('SimpleLookup', 'Salutation').subscribe((res) => {
        this.titles = res;
      });

      this.lookupService.getLookup('CodeLookup', 'Gender').subscribe((res) => {
        this.genders = res;
      });

      this.lookupService.getLookup('CodeLookup', 'CompanyType').subscribe((res) => {
        this.companyTypes = res;
      });
    });
  }

  onCompleteAll(fileID) {
    if (fileID && fileID[0]) {
      this.beneficiary.fileID = fileID[0];
      this.changeImage();
    }
  }

  getMobile(m) {
    this.beneficiary.mobile = m;
  }

  getPhone(p) {
    this.beneficiary.phone = p;
  }

  getEmail(e) {
    this.beneficiary.email = e;
  }

  validateMobileEvent(v) {
    this.validateMobile = v;
  }

  validatePhoneEvent(v) {
    this.validatePhone = v;
  }

  validateOccupationEvent(v) {
    this.validateOccupation = v;
  }

  validateHomePhoneEvent(v) {
    this.validateHomePhone = v;
  }

  validateEmailEvent(v) {
    this.validateEmail = v;
  }

  getAdress(adr) {
    if (adr) {
      this.beneficiary.suburb = adr.suburb;
      this.beneficiary.state = adr.state;
      this.beneficiary.postCode = adr.postCode;
      this.beneficiary.streetNumber = adr.streetNumber;
      this.beneficiary.streetName = adr.streetName;
      this.beneficiary.unitNumber = adr.unitNumber;
      this.beneficiary.streetType = adr.streetType;
      this.beneficiary.latitude = adr.latitude;
      this.beneficiary.longitude = adr.longitude;
    }
  }

  getOccupation(o) {
    if (o && o['ID']) {
      this.beneficiary.occupation = o.Label;
    }
  }

  isAdressValidEvent(v) {
    this.isAdressValid = v || false;
  }

  changeImage() {
    this.utilsService.getFileDetails(this.beneficiary.fileID).subscribe((res) => {
      if (res) {
        if (res.contentType == 'image/png' || res.contentType == 'image/jpg' || res.contentType == 'image/jpeg') {
          this.isFile = true;
          this.src = `${environment.nodeUrl}/files/file-view/${this.beneficiary.fileID}`;

          NotifyAppComponent.displayToast('success', 'Successful operation', 'Profile has been successfully updated');
        } else {
          NotifyAppComponent.displayToast(
            'warning',
            'Error during operation',
            'Format of image should be png/jpg/jpeg'
          );
        }
      }
    });
  }

  editSupplier() {
    this.beneficiaryService.editBeneficiary(this.beneficiaryID, this.beneficiary).subscribe((res) => {
      if (res) {
        this.beneficiaryCreated = res;
        NotifyAppComponent.displayToast('success', 'Success!', 'Supplier details have been updated');

        this.wizard.goToNextStep();
      }
    });
  }

  sendApplication() {
    this.beneficiaryService.createBeneficiary(this.beneficiary).subscribe((res) => {
      if (res) {
        this.beneficiaryCreated = res;
        NotifyAppComponent.displayToast('success', 'Success!', 'Supplier has been added');

        this.wizard.goToNextStep();
      }
    });
  }

  viewBeneficiary() {
    if (this.beneficiaryCreated && this.beneficiaryCreated['ID']) {
      const ref = RootAppComponent.dialog.open(BeneficiaryViewComponent, {
        data: this.beneficiaryCreated['ID'],
        width: '600px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  addNewBankAccount() {
    if (this.beneficiaryCreated && this.beneficiaryCreated['ID']) {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['bank-account-create', this.beneficiaryCreated['ID'], this.beneficiaryCreated['Label']],
          },
        },
      ]);
    }
  }

  goBack() {
    this.location.back();
  }

  checkLabel() {
    const payload = {
      cardType: this.beneficiaryCreated['CardType'],
      label: this.supplierLabel,
    };
    this.beneficiaryService.editBeneficiary(this.beneficiaryCreated['ID'], payload).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast(
          'success',
          'No Duplicates',
          'The label is unique. Go ahead to create the bank account!'
        );
        this.supplierLabel = res['Label'];
        this.isCreateBank = true;
      } else {
        this.supplierLabel = null;
        this.isCreateBank = false;
      }
    });
  }

  getProfilePicture() {
    this.profileLink = null;

    this.profileLink = this.beneficiaryService.getProfilePicStreamLink(this.beneficiaryID);
  }
}
