<mat-card class="rel clearfix" style="margin: auto">
  <form #resetForm="ngForm">
    <div
      class="card-header primary-gradient-img clearfix confirmModal"
      [attr.style]="'background:' + theme.background + ' !important' | safeStyle"
    >
      <div class="full-width flex main-title-container">
        <div class="row full-width title-container clearfix">
          <div class="title-container-sub">
            <h2 class="text-left title-header rm-m smt">
              Instant Finance Offer

              <span class="subLabel">
                Personalise your finance landing page to suit you.<br/>
                Customers will see this landing page when you send offers via SMS, Finance Offer or direct sale.
              </span>
            </h2>
          </div>
        </div>
      </div>
    </div>

    <div class="full-width text-center flex mb">
      <div
        class="theme-container"
        *ngFor="let t of themes"
        [attr.style]="'background:' + t.background + ' !important' | safeStyle"
        (click)="selectTheme(t)"
      >
        <mat-icon class="fas fa-check-circle" *ngIf="t && t.code && theme && theme.code && t.code == theme.code">
        </mat-icon>
      </div>
    </div>

    <div class="full-width mt flex">
      <div class="full-width header-card-top">
        <div class="full-width flex">
          <mat-checkbox
            class="full-width text-left mt mr"
            [(ngModel)]="isLandingPagePublic"
            color="accent"
            name="isLandingPagePublic"
          >
            Allow External Requests
          </mat-checkbox>

          <mat-form-field class="full-width mr" *ngIf="isLandingPagePublic == true" appearance="outline">
            <mat-label>Max {{ "KEYWORD.treatment" | translate | titlecase }} Value ($) (Optional)</mat-label>
            <input
              [(ngModel)]="maxLandingPageAmount"
              name="maxLandingPageAmount"
              matInput
              placeholder="Please enter a value "
              currencyMask
            />
          </mat-form-field>
        </div>

        <div class="full-width smb">
          <h3
            class="sr-title text-left full-width text-center rm-m smb"
            [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
          >
            Sales Team
          </h3>

          <p *ngIf="isLandingPagePublic == true">
            Do you have third parties you want to give access to sending Instant Finance Offers or team members you want
            to limit access to a simple finance-only page? <br/><br/>
            Use the following link to access the landing page.
          </p>

          <a
            *ngIf="isLandingPagePublic == true"
            href="{{ settings.publicSiteFrontendLink + '/finance' }}"
            target="_blank"
          >{{ settings.publicSiteFrontendLink + '/finance' }}</a
          >
        </div>
        <div class="full-width flex mt">
          <mat-form-field class="full-width mr" appearance="outline">
            <mat-label>Default Sender*</mat-label>
            <mat-select
              class="full-width"
              [(ngModel)]="contactID"
              (ngModelChange)="changeDentist()"
              placeholder=""
              name="detnistID"
            >
              <mat-option *ngFor="let p of dentists" [value]="p['ID']">
                {{ p.CalculatedName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="full-width mr" appearance="outline">
            <mat-label>Default product group*</mat-label>
            <mat-select
              class="full-width"
              [(ngModel)]="productGroup"
              (ngModelChange)="getLoanAmount(productGroup)"
              placeholder=""
              name="productGroup"
            >
              <mat-option *ngFor="let p of financedProductGroup" [value]="p['GroupCode']">
                <mat-icon class="fas {{ productUtil.getProductGroupIcon(p['GroupCode']) }} mr iconproductgroup ">
                </mat-icon>

                {{ productUtil.getLabel(p['GroupCode']) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <mat-card
        class="editor-card-preview row file animate mat-elevation-z4 full-width"
        [ngClass]="isHeaderImage != true ? 'no-image' : ''"
      >
        <div class="full-width landing-page-create-gallery-container">
          <h3
            class="sr-title full-width text-center rm-m smb"
            *ngIf="isHeaderImage == true"
            [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
          >
            Header Image
          </h3>

          <h3
            class="sr-title full-width text-center rm-m smb"
            *ngIf="isHeaderImage != true"
            [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
          >
            Header Image
          </h3>
          <app-ep-document-view-image
            class="landing-page-create-gallery2"
            *ngIf="isHeaderImage != true"
            [imageLink]="
              'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/no-image.jpg'
            "
            [hideZoomButton]="true"
            style="margin-top: 60px !important"
          >
          </app-ep-document-view-image>

          <app-ep-document-view-image
            class="landing-page-create-gallery2"
            *ngIf="headerImage != 'merchant' && isHeaderImage == true"
            [ngClass]="getCircle(headerImage) == true ? 'round-circle' : ''"
            [imageLink]="headerImage"
            [hideZoomButton]="false"
            style="margin-top: 60px !important"
          >
          </app-ep-document-view-image>

          <app-ep-document-view-image
            class="landing-page-create-gallery2 round-circle"
            *ngIf="headerImage == 'merchant' && merchantID && isHeaderImage == true"
            [imageLink]="getMerchantLink()"
            [hideZoomButton]="false"
            style="margin-top: 60px !important"
          >
          </app-ep-document-view-image>
        </div>

        <mat-checkbox
          class="text-left no-image-checkbox"
          [(ngModel)]="isHeaderImage"
          matTooltip="Add Header Image"
          color="accent"
          name="isHeaderImage"
        >
        </mat-checkbox>

        <div class="full-width text-center card-image-footer">
          <button
            class="btn pull-right mr"
            *ngIf="isHeaderImage != true"
            [disabled]="true"
            mat-icon-button
            color="accent"
          >
            <mat-icon class="fas fa-upload"></mat-icon>
          </button>
          <button
            class="btn pull-left ml"
            *ngIf="isHeaderImage != true"
            [disabled]="true"
            [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
            mat-raised-button
            color="accent"
          >
            <mat-icon class="fas fa-images smr"></mat-icon> Gallery
          </button>

          <button
            class="btn pull-right mr"
            *ngIf="isHeaderImage == true"
            (click)="upload()"
            mat-icon-button
            color="accent"
            matTooltip="Upload New Image"
          >
            <mat-icon class="fas fa-upload"></mat-icon>
          </button>

          <button
            class="btn pull-left ml"
            *ngIf="isHeaderImage == true"
            [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
            (click)="galleryHeader()"
            mat-raised-button
            color="accent"
          >
            <mat-icon class="fas fa-images smr"></mat-icon> Gallery
          </button>
        </div>
      </mat-card>
    </div>

    <div class="clearfix text-center full-width">
      <h3
        class="sr-title text-left full-width text-center mt smb"
        *ngIf="settings && settings['context'] != 'breeze'"
        [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
      >
        Extra Links
      </h3>

      <div class="full-width flex smb">
        <mat-checkbox
          class="full-width text-left smt mr"
          *ngIf="settings && settings['context'] != 'breeze' "
          [(ngModel)]="displayAppointmentPage"
          color="accent"
          name="displayAppointmentPage"
        >
          Appointment Request Link
        </mat-checkbox>

        <mat-checkbox
          class="full-width text-left smt mr"
          *ngIf="settings && settings['context'] != 'breeze' "
          [(ngModel)]="displayTipsAndOffersPage"
          color="accent"
          name="displayTipsAndOffersPage"
        >
          Tips and Offers Link
        </mat-checkbox>

        <mat-checkbox
          class="full-width text-left smt mr"
          *ngIf="settings && settings['context'] != 'breeze' "
          [(ngModel)]="displayGoingGreenPage"
          color="accent"
          name="displayGoingGreenPage"
        >
          Going Green Link
        </mat-checkbox>
      </div>

      <h3
        class="sr-title text-left full-width text-center mt mb"
        [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
      >
        Title & Description
      </h3>
      <mat-form-field class="clearfix full-width" appearance="outline">
        <mat-label>Title</mat-label>
        <input [(ngModel)]="title" name="Title" matInput placeholder="Title" required/>
      </mat-form-field>

      <message-input
        class="full-width message-input-component200"
        *ngIf="loadPromoter == true && loadCard == true && loadContact == true && loadMerchant == true"
        [isTiny]="true"
        [isModal]="false"
        [messageMode]="messageMode"
        [label]="'Please insert a description'"
        [message]="_description"
        [showAllCustomization]="true"
        [card]="card"
        [textLength]="0"
        [displayEmoji]="false"
        [displayMessageTemplate]="true"
        [contact]="contact"
        [promoter]="promoter"
        [displayPatient]="displayPatient"
        [displayContact]="true"
        [displayMerchant]="true"
        [displayPromoter]="true"
        [displayMarketing]="true"
        [displayExpend]="false"
        [displayDentalOffers]="true"
        [displayDocument]="true"
        [saveAsTemplate]="false"
        [isSMSPreview]="true"
        [isDesktopPreview]="true"
        [displaySaveAsTemplate]="true"
        [encode]="false"
        [tableName]="tableName"
        (getResult)="getResultDescription($event)"
      >
      </message-input>

      <h3
        class="sr-title text-left full-width text-center rm-m mb"
        [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
      >
        Personal Message
      </h3>

      <message-input
        class="full-width message-input-component200"
        *ngIf="loadPromoter == true && loadCard == true && loadContact == true && loadMerchant == true"
        [isTiny]="true"
        [isModal]="false"
        [messageMode]="messageMode"
        [label]="'Please insert a  message'"
        [message]="_message"
        [showAllCustomization]="true"
        [card]="card"
        [textLength]="0"
        [displayEmoji]="false"
        [displayMessageTemplate]="true"
        [contact]="contact"
        [promoter]="promoter"
        [displayPatient]="displayPatient"
        [displayContact]="true"
        [displayMerchant]="true"
        [displayPromoter]="true"
        [displayMarketing]="true"
        [displayExpend]="false"
        [displayDentalOffers]="true"
        [displayDocument]="true"
        [saveAsTemplate]="false"
        [isSMSPreview]="true"
        [isDesktopPreview]="true"
        [displaySaveAsTemplate]="true"
        [encode]="false"
        [tableName]="tableName"
        (getResult)="getResultMessage($event)"
      >
      </message-input>

      <h3
        class="sr-title text-left full-width rm-mt"
        *ngIf="loadMessage == true && loadPromoter == true && loadContact == true && loadMerchant == true"
        [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
      >
        Disclaimer Message
      </h3>

      <mat-checkbox
        class="full-width text-left disclaimer-message-check mb"
        [(ngModel)]="displayDisclaimerMessage"
        color="accent"
        name="displayDisclaimerMessage"
      >
        If turned on, the message below will appear before proceeding with a finance application, and the
        Customer must acknowledge it.
      </mat-checkbox>

      <message-input
        class="full-width message-input-component message-disclaimer-component4-message"
        *ngIf="
          loadPromoter == true &&
          loadCard == true &&
          loadContact == true &&
          loadMerchant == true &&
          displayDisclaimerMessage == true
        "
        [isTiny]="true"
        [isModal]="false"
        [messageMode]="messageMode"
        [label]="'Please insert a  message'"
        [message]="_disclaimerMessage"
        [showAllCustomization]="true"
        [card]="card"
        [textLength]="0"
        [displayEmoji]="false"
        [displayMessageTemplate]="true"
        [contact]="contact"
        [displayPatient]="displayPatient"
        [promoter]="promoter"
        [displayContact]="true"
        [displayMerchant]="true"
        [displayPromoter]="true"
        [displayMarketing]="true"
        [displayExpend]="false"
        [displayDentalOffers]="true"
        [displayDocument]="true"
        [saveAsTemplate]="false"
        [isSMSPreview]="true"
        [isDesktopPreview]="true"
        [displaySaveAsTemplate]="true"
        [isCustomTreatmentAmount]="true"
        [displayPreview]="false"
        [encode]="false"
        [tableName]="tableName"
        (getResult)="getResultMessageDisclaimer($event)"
      >
      </message-input>
    </div>

    <hr/>
    <div class="full-width text-center">
      <button
        class="action-button btn-large smt"
        [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
        [disabled]="!resetForm.form.valid"
        (click)="submit()"
        mat-raised-button
        color="accent"
      >
        {{ submitLabel }}
      </button>
    </div>
  </form>
</mat-card>
