import { Component, EventEmitter, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-settlement-healthfund-modal',
  templateUrl: './settlement-healthfund-modal.component.html',
  styleUrls: ['./settlement-healthfund-modal.component.css'],
})
export class SettlementHealthFundModalComponent implements OnInit {
  selectedFund = {
    name: null,
    amount: null,
    reference: null,
    isAccepted: false,
  };

  maxAmount;

  closeModal = new EventEmitter();
  sendAmount = new EventEmitter();

  amountInput;
  fundOptions = ['HICAPS', 'Garrison', 'Medicare'];

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.name) {
        this.selectedFund.name = data.name;
      }
      if (data.amount) {
        this.selectedFund.amount = data.amount;
      }
      if (data.maxAmount) {
        this.maxAmount = data.maxAmount;
      }

      if (data.reference) {
        this.selectedFund.reference = data.reference;
      }

      if (data.isAccepted) {
        this.selectedFund = data.isAccepted;
      }
    }
    this.amountInput = new FormControl('', [Validators.max(this.maxAmount), Validators.min(0)]);
  }

  ngOnInit() {}

  close() {
    this.closeModal.emit(true);
  }

  sendFundAmountEvent() {
    this.sendAmount.emit(this.selectedFund);
  }
}
