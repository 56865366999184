import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { MapViewComponent } from '../../../shared/components/map-view/map-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { BankAccountListUserComponent } from '../../bank-account/bank-account-user/BankAccountListUserComponent';
import { BeneficiaryService } from '../shared/beneficiary.service';

@Component({
  selector: 'app-beneficiary-view',
  templateUrl: './beneficiary-view.component.html',
  styleUrls: ['./beneficiary-view.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.stacked-card-view', style({ opacity: 0 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.stacked-card-view', style({ opacity: 1 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class BeneficiaryViewComponent implements OnInit {
  @Input() beneficiaryID;
  beneficiary;

  @Input()
  abnDetails = true;

  @Output()
  close = new EventEmitter();

  @Output()
  loading = false;
  profileLink;
  subjectArray = [];

  isPromoterOrAdmin = false;
  isModal = false;

  constructor(
    private beneficiaryService: BeneficiaryService,
    private authenticationService: AuthenticationService,
    private customPhone: CustomPhonePipe,
    private router: Router,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.beneficiaryID = data;
      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      if (this.beneficiaryID) {
        this.beneficiaryService
          .getBeneficiaryDetails(this.beneficiaryID, null, this.isPromoterOrAdmin)
          .subscribe((res1) => {
            this.beneficiary = res1;

            this.getProfilePicture();
          });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.beneficiaryService
      .getBeneficiaryDetails(this.beneficiaryID, null, this.isPromoterOrAdmin)
      .subscribe((res1) => {
        this.beneficiary = res1;
        this.loading = true;

        this.getProfilePicture();
      });
  }

  getMoreInfo() {
    let phone = '';

    if (this.beneficiary['phones.Number']) {
      phone = this.customPhone.transform(String(this.beneficiary['phones.Number']), 'landLine');
    }

    const result =
      "<p><strong>Beneficiary's name: </strong>" +
      this.beneficiary['CalculatedName'] +
      '</p><p><strong>Phone: </strong>' +
      String(phone) +
      "</p><p><strong>Email: </strong> <a class='emailLink primary-color' href='mailto:" +
      String(this.beneficiary['emails.Email']) +
      "' title='Email Beneficiary' target='_blank'>" +
      this.beneficiary['emails.Email'] +
      '</a></p>';

    return result;
  }

  displayMap() {
    const location = {
      zoom: 17,
      postCode: this.beneficiary['addresses.Postcode'],
      state: this.beneficiary['addresses.State'],
      streetType: this.beneficiary['addresses.Street Type'],
      streetName: this.beneficiary['addresses.Street Name'],
      streetNumber: this.beneficiary['addresses.Street Nr'],
      suburb: this.beneficiary['addresses.Suburb'],
      country: this.beneficiary['addresses.Country.Code'],
      addressLabel: this.beneficiary['addresses.Calculated'],
      latitude: this.beneficiary['Address.Latitude'],
      longitude: this.beneficiary['Address.Longitude'],
      moreInfo: this.getMoreInfo(),
    };

    const ref = RootAppComponent.dialog.open(MapViewComponent, {
      data: location,
      panelClass: 'modal-map',
      width: '800px',
      height: '600px',
    });

    ref.componentInstance.addCoordinate.subscribe((res) => {
      if (res && res.latitude && res.longitude) {
        const payload = {
          latitude: res.latitude,
          longitude: res.longitude,
        };
        this.beneficiaryService.addCoordinate(this.beneficiaryID, payload).subscribe((res) => {});
      }
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  contact() {
    const data = {
      targetType: 'beneficiary',
      targetID: this.beneficiary.ID,
      asGuest: false,
      asProfile: true,
      asPractice: true,
      subjectArray: this.subjectArray,
    };
    AuthenticationService.contactInputEvent.emit(data);
  }

  closeEvent() {
    this.close.emit(true);
  }

  viewBankAccounts() {
    const ref = RootAppComponent.dialog.open(BankAccountListUserComponent, {
      data: {
        userID: this.beneficiaryID,
        embedded: false,
      },

      width: '650px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  enable() {
    const confirmDialog = new ConfirmDialog(
      'check_circle',
      'Enable Supplier',
      '<p>Once the Supplier is enabled it will  appear as an option for the user.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            active: true,
          };
          this.beneficiaryService.editBeneficiary(this.beneficiaryID, payload).subscribe((res) => {
            if (res) {
              this.beneficiary = res;

              NotifyAppComponent.displayToast('success', 'Success!', 'Supplier is enabled.');
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  disable() {
    const confirmDialog = new ConfirmDialog(
      'cancel',
      'Disable Supplier',
      '<p>Once the Supplier is disabled it will not appear as an option for the user.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            active: false,
          };
          this.beneficiaryService.editBeneficiary(this.beneficiaryID, payload).subscribe((res) => {
            if (res) {
              this.beneficiary = res;
              NotifyAppComponent.displayToast('success', 'Success!', 'Supplier is disabled.');
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  editSupplier() {
    if (this.beneficiary['ParentOrganisation_key']) {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['subsidiary-edit', this.beneficiaryID],
          },
        },
      ]);
    } else {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['beneficiary-edit', this.beneficiaryID],
          },
        },
      ]);
    }

    this.close.emit(true);
  }

  viewSupplier() {
    if (this.beneficiary['ParentOrganisation_key']) {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['subsidiary-view', this.beneficiaryID],
          },
        },
      ]);
    } else {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['beneficiary-view', this.beneficiaryID],
          },
        },
      ]);
    }

    this.close.emit(true);
  }

  openCatelogueVIew() {
    this.closeEvent();
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['beneficiary-catalogue-view', this.beneficiaryID],
        },
      },
    ]);
  }

  openMailTo() {
    window.location.href = 'mailto:' + this.beneficiary['emails.Email'];
  }

  openSocialLink(link) {
    // check if link has http or https and add it if not
    let url = '';
    if (!/^http[s]?:\/\//.test(link)) {
      url += 'https://';
    }
    url += link;
    window.open(url, '_blank', 'noopener');
  }

  getProfilePicture() {
    this.profileLink = null;

    this.profileLink = this.beneficiaryService.getProfilePicStreamLink(this.beneficiaryID);
  }
}
