<ipv-title-modal
  [disableAcceptButton]="!formGroup.valid"
  [hideDefaultRejectButton]="true"
  [title]="(mode | titlecase) + ' wishlist item'"
  (acceptButtonClicked)="save()"
  acceptButtonText="Submit"
  classIcon="fa fa-tag"
  icon=""
>
  <div content>
    <form [formGroup]="formGroup">
      <mat-form-field class="full-width" appearance="outline">
        <input formControlName="Label" matInput placeholder="Label" required type="text"/>

        <mat-icon
          class="error-icon-signup"
          *ngIf="Label?.hasError('exists'); else validLabel"
          matSuffix
          matTooltip="The label must be unique"
        >
          error
        </mat-icon>

        <ng-template #validLabel>
          <mat-icon class="fa fa-check" matSuffix matTooltip="Label is unique" style="color: #119c1d"></mat-icon>
        </ng-template>

        <mat-error *ngIf="Label.invalid && (Label.dirty || Label.touched)">
          <div *ngIf="Label?.hasError('required')" style="margin-top: 10px">Label is required.</div>

          <div *ngIf="Label?.hasError('minlength')" style="margin-top: 10px">Label must have 2 or more characters.</div>

          <div *ngIf="Label?.hasError('exists')" style="margin-top: 10px">Label must be unique.</div>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="full-width" appearance="outline" style="margin-top: 20px">
        <mat-label> Category</mat-label>
        <mat-select formControlName="Code">
          <mat-option *ngFor="let wishlistCategory of categories" [value]="wishlistCategory">
            {{ wishlistCategory | titlecase }}
          </mat-option>
        </mat-select>

        <mat-error *ngIf="Code.invalid && (Code.dirty || Code.touched)">
          <div *ngIf="Code?.hasError('required')" style="margin-top: 10px">Category is required.</div>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="full-width" appearance="outline" style="margin-top: 20px">
        <textarea
          formControlName="Description"
          matAutosizeMaxRows="20"
          matAutosizeMinRows="5"
          matInput
          matTextareaAutosize
          placeholder="Description"
          required
        >
        </textarea>

        <mat-error *ngIf="Description.invalid && (Description.dirty || Description.touched)">
          <div *ngIf="Description?.hasError('required')" style="margin-top: 10px">Description is required.</div>
        </mat-error>
      </mat-form-field>
    </form>
  </div>
</ipv-title-modal>
