<mat-sidenav-container class="profile-container" *ngIf="currentUser && myPractice">
  <mat-sidenav class="profile-sidebar" #sidenavProfile [mode]="navMode" [opened]="openFlag" [position]="'end'">
    <div class="sticky">
      <section class="profile-header primary-gradient-img rel">
        <app-ep-document-view-image
          class="profile-lead-image"
          [defaultPicture]="
            'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/practice-pic.png'
          "
          [link]="profileLink"
          tabindex="0"
        >
        </app-ep-document-view-image>

        <div class="profile-header-details">
          <h2>{{ currentUser['CalculatedName'] }}</h2>
          <p *ngIf="myPractice['TradingAs']">{{ myPractice['TradingAs'] }}</p>
          <p class="small" *ngIf="currentUser['Occupation']">
            {{ currentUser['Occupation'] }}
          </p>
        </div>
      </section>

      <div class="profile-sidebar-container">
        <div class="row clearfix">
          <button
            class="pull-right"
            *ngIf="isCreateTradingTime == false && disableEditPractice == false"
            (click)="setupTradingHours()"
            mat-icon-button
          >
            <mat-icon>create</mat-icon>
          </button>
          <h3 class="sr-title m-h2 rm-m">Business Hours</h3>
          <p class="small rm-mt">{{ "KEYWORD.practice" | translate | titlecase}} Timezone: {{ merchantTimezone }}</p>
          <div *ngIf="isCreateTradingTime != false && disableEditPractice == false">
            <p>Please Setup your trading hours</p>
            <button class="text-center" (click)="setupTradingHours()" mat-raised-button color="accent">Set up</button>
          </div>
          <div *ngIf="isCreateTradingTime != false && disableEditPractice == true">
            <p>Please contact the {{'merchant' | translate}} administrator to set up</p>
          </div>
          <div *ngIf="isCreateTradingTime == false">
            <mat-list>
              <mat-list-item class="flex flexList busHours" *ngFor="let t of tradingHours">
                <span class="small" *ngIf="t['NotOpen'] == '0'"
                ><span class="dayLabel">{{ t['DayOfWeek.Label.Singular'] }}</span>
                  <span class="tradingHour"
                  >{{ util.timeConvert(t['OpeningTime'].slice(0, 5)) }} -
                    {{ util.timeConvert(t['ClosingTime'].slice(0, 5)) }}
                  </span>
                </span>
                <span class="small" *ngIf="t['NotOpen'] == '1'"
                ><span class="dayLabel">{{ t['DayOfWeek.Label.Singular'] }}</span>
                  <span class="tradingHour">Close</span>
                </span>
              </mat-list-item>
            </mat-list>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav>

  <div class="profile-content">
    <div class="full-width toggle-card mb clearfix mat-elevation-z3">
      <mat-button-toggle-group
        class="btn-group session-profile rm-m mat-elevation-z4 full-width flex"
        [(ngModel)]="settings"
        (ngModelChange)="changeLink()"
      >
        <mat-button-toggle class="full-width text-center" [value]="'public'">
          <mat-icon class="fas fa-user-circle"></mat-icon>
          Overview
        </mat-button-toggle>

        <mat-button-toggle class="full-width text-center" [value]="'personal'">
          <mat-icon class="fas fa-id-badge"></mat-icon>
          My Profile
        </mat-button-toggle>

        <mat-button-toggle class="full-width text-center" [value]="'practice'">
          <mat-icon class="fas fa-store-alt"></mat-icon>
          {{ "KEYWORD.practice" | translate | titlecase}}
        </mat-button-toggle>

        <mat-button-toggle class="full-width text-center" [value]="'staff'">
          <mat-icon class="fas fa-users"></mat-icon>
          {{ "KEYWORD.practice" | translate | titlecase}} Staff
        </mat-button-toggle>

        <mat-button-toggle class="full-width text-center" *ngIf="context!='p4p' && context!='breeze'" [value]="'invitation'">
          <mat-icon class="fas fa-cogs"></mat-icon>
          Invitation
        </mat-button-toggle>
        <!--
        <mat-button-toggle class="full-width text-center" [value]="'notes'">
          <mat-icon class="fas fa-sticky-note"></mat-icon>
          {{ "Notes" | translate }}
        </mat-button-toggle>

        <mat-button-toggle class="full-width text-center" *ngIf="isModulePatientSMSActive==true" [value]="'sms'">
          <mat-icon class="fas fa-sms"></mat-icon>
          {{ "SMS" | translate }}
        </mat-button-toggle> -->

        <mat-button-toggle class="full-width text-center" *ngIf="isModuleMarketingActive" [value]="'marketing'">
          <mat-icon class="fas fa-screwdriver"></mat-icon>
          Marketing
        </mat-button-toggle>

        <mat-button-toggle class="full-width text-center" *ngIf="isModuleAppointmentActive && context!='p4p' && context!='breeze'" [value]="'appointment'">
          <mat-icon class="far fa-calendar-check"></mat-icon>
          Appointment
        </mat-button-toggle>

        <mat-button-toggle
          class="full-width text-center"
          *ngIf="isModulePatientHistoryActive  && context!='p4p' && context!='breeze'"
          [value]="'medicalHistory'"
        >
          <mat-icon class="fas fa-heartbeat"></mat-icon>
          Health History
        </mat-button-toggle>

        <!-- <mat-button-toggle class="full-width text-center" [value]="'patientAssistance'"
          *ngIf="isModulePatientPortalAccessActive">
          <mat-icon class="fas fa-hands-helping"></mat-icon> {{ "KEYWORD.patient" | translate | titlecase }} Assistance
        </mat-button-toggle> -->

        <mat-button-toggle class="full-width text-center" [value]="'module'">
          <mat-icon class="fas fa-cubes"></mat-icon>
          Modules
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <session-over-view *ngIf="settings == 'public'" [settings]="settings"></session-over-view>

    <session-over-view *ngIf="settings == 'personal'" [settings]="settings"></session-over-view>

    <session-over-view *ngIf="settings == 'practice'" [settings]="settings"></session-over-view>

    <div class="tab-content clearfix" *ngIf="settings == 'module'">
      <app-user-access-view class="full-width" [cardID]="myPractice['ID']"></app-user-access-view>
    </div>

    <div class="tab-content clearfix" *ngIf="settings == 'appointment'">
      <app-appointment-landing-create class="full-width"></app-appointment-landing-create>
    </div>

    <div class="tab-content clearfix" *ngIf="settings == 'medicalHistory'">
      <app-medical-history-setting class="full-width"></app-medical-history-setting>
    </div>

    <div class="tab-content clearfix" *ngIf="settings == 'patientAssistance'">
      <app-merchant-product-assistant class="full-width"></app-merchant-product-assistant>
    </div>

    <div class="tab-content clearfix" *ngIf="settings == 'staff'">
      <div class="full-width rel" *ngIf="disableEditPractice == false">
        <button
          class="absPos btn-back"
          [routerLink]="['/merchant/', { outlets: { page: ['dentist-invite-create'] } }]"
          [routerLinkActive]="['active']"
          mat-raised-button
          color="accent"
        >
          <mat-icon>person_add</mat-icon>
          Add a Staff Member
        </button>
      </div>
      <app-dentist-list class="noEmbedCard full-width" [merchantID]="myPractice['ID']"></app-dentist-list>
    </div>

    <div class="tab-content clearfix" *ngIf="settings == 'invitation'">
      <app-invitation-settings class="full-width"></app-invitation-settings>
    </div>

    <div class="tab-content clearfix" *ngIf="settings == 'notes'">
      <ipv-notes-settings class="full-width"></ipv-notes-settings>
    </div>

    <div class="tab-content clearfix" *ngIf="settings == 'sms'">
      <app-default-sms-settings class="full-width"></app-default-sms-settings>
    </div>

    <div class="tab-content clearfix" *ngIf="settings == 'marketing'">
      <app-marketing-settings class="full-width"></app-marketing-settings>
    </div>
  </div>
</mat-sidenav-container>
