<mat-form-field class="mr full-width" appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <input
    [(ngModel)]="timeZoneCode"
    [matAutocomplete]="auto"
    [disabled]="disabled"
    (input)="filterTimezone($event)"
    name="TimeZone"
    type="text"
    placeholder="{{ label }}"
    matInput
  />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="getSelectedTimezone($event.option.value)">
    <mat-option *ngFor="let tz of filteredTimeZones" [value]="tz">
      {{ getTZLabel(tz) }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
