<mat-form-field class="full-width" *ngIf="lookups && lookups.length > 0" appearance="outline">
  <mat-label>Please select</mat-label>
  <mat-select
    class=""
    [(ngModel)]="lookup"
    [required]="requiredLabel"
    (ngModelChange)="chooseLookup($event)"
    name="{{ category }}"
    placeholder=""
  >
    <mat-option *ngFor="let l of lookups" [value]="l">
      {{ l[lookupLabelAttribute] || l['Label'] }}
    </mat-option>
  </mat-select>
</mat-form-field>
