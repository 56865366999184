/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contract-detail-contacts.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "../../../shared/components/empty-list-message/empty-list-message.component.ngfactory";
import * as i6 from "../../../shared/components/empty-list-message/empty-list-message.component";
import * as i7 from "@angular/common";
import * as i8 from "./contract-detail-contacts.component";
var styles_ContractDetailContactsComponent = [i0.styles];
var RenderType_ContractDetailContactsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContractDetailContactsComponent, data: {} });
export { RenderType_ContractDetailContactsComponent as RenderType_ContractDetailContactsComponent };
function View_ContractDetailContactsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "masonry"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "item full-width"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "mat-card", [["class", "clearfix mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(3, 49152, null, 0, i3.MatCard, [[2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 0, "div", [["class", "colour-stripe"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, 0, 5, "mat-card-header", [["class", "mat-card-header"]], null, null, null, i2.View_MatCardHeader_0, i2.RenderType_MatCardHeader)), i1.ɵdid(6, 49152, null, 0, i3.MatCardHeader, [], null, null), (_l()(), i1.ɵeld(7, 0, null, 1, 3, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i3.MatCardTitle, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "h3", [["class", "sr-title sm-h3 rm-m"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Guarantors, Signatories, And Other"])), (_l()(), i1.ɵeld(11, 0, null, 0, 3, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(12, 16384, null, 0, i3.MatCardContent, [], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-empty-list-message", [], null, null, null, i5.View_EmptyListMessageComponent_0, i5.RenderType_EmptyListMessageComponent)), i1.ɵdid(14, 114688, null, 0, i6.EmptyListMessageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 14, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 2, 0, currVal_0); }); }
export function View_ContractDetailContactsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContractDetailContactsComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contract; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ContractDetailContactsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contract-detail-contacts", [], null, null, null, View_ContractDetailContactsComponent_0, RenderType_ContractDetailContactsComponent)), i1.ɵdid(1, 114688, null, 0, i8.ContractDetailContactsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContractDetailContactsComponentNgFactory = i1.ɵccf("app-contract-detail-contacts", i8.ContractDetailContactsComponent, View_ContractDetailContactsComponent_Host_0, { contractObject: "contractObject" }, {}, []);
export { ContractDetailContactsComponentNgFactory as ContractDetailContactsComponentNgFactory };
