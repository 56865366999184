<div class="clearfix" *ngIf="isAdminOrPromoter == true">
  <app-operator-filter
    class="dash"
    id="part1"
    *ngIf="hideFilter == false"
    [displayMerchant]="true"
    [displayDateRange]="false"
    [displayDateFrom]="false"
    [displayTimePicker]="false"
    [displayDateTo]="false"
    [displayDentist]="false"
    [setAutoSelectMerchant]="true"
    [selectedMerchant]="merchantID"
    (search)="applySearch($event)"
    (getMerchant)="getMerchant($event)"
  >
  </app-operator-filter>

  <div class="row clearfix">
    <app-third-party-access-list *ngIf="merchantID != 'none'" [pageSize]="pageSize" [merchantID]="merchantID">
    </app-third-party-access-list>
  </div>
  <div class="row clearfix">
    <div class="pleaseChoose" *ngIf="merchantID == 'none'" @ngIfAnimation>
      <p>
        <strong>No merchant selected</strong>
      </p>
      <img class="character_warning" />
      <p>
        Choose your desired merchant from the drop-down above to view their
        {{ "KEYWORD.patient" | translate }}s
      </p>
    </div>
  </div>
</div>

<div class="clearfix" *ngIf="isAdminOrPromoter == false">
  <div class="row clearfix">
    <app-third-party-access-list [pageSize]="pageSize"></app-third-party-access-list>
  </div>
</div>
