import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-text-editor-view',
  templateUrl: './text-editor-view.component.html',
  styleUrls: ['./text-editor-view.component.css'],
})
export class TextEditorViewComponent implements OnInit {
  @Input()
  title = 'Preview article';

  @Input()
  description;

  @Input()
  content;

  isModal = true;

  @Output() closeModal = new EventEmitter();

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.content) {
        this.content = data.content;
      }
      if (data.title) {
        this.title = data.title;
      }

      if (data.description) {
        this.description = data.description;
      }
    }
  }

  ngOnInit() {}

  closeEvent() {
    this.closeModal.emit(true);
  }
}
