import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilsService } from '../../services/utils.service';
import { UtilsClass } from '../../types/utils/utils.class';

@Component({
  selector: 'app-ep-document-thumbnail-view',
  templateUrl: './ep-document-thumbnail-view.component.html',
  styleUrls: ['./ep-document-thumbnail-view.component.css'],
})
export class EpDocumentThumbnailViewComponent implements OnInit {
  @Input()
  notChecked = false;

  @Input()
  hasIcon = true;

  @Input() documentID;

  @Input()
  document;

  @Input()
  link;

  type = 'document';
  loading = false;

  utils = new UtilsClass();

  @Input()
  hideZoomButton = false;

  @Input() defaultImg = 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/pdf.png';

  @Output() getClick = new EventEmitter();

  constructor(private utilService: UtilsService) {}

  ngOnInit() {
    if (this.documentID) {
      this.loading = true;

      this.utilService.getEpdocumentMiniThumbnailDetails(this.documentID).subscribe((res) => {
        if (res) {
          this.document = res;
          this.getDocumentType();

          this.defaultImg = this.utils.getDefaultPic(this.type, this.document.Extension);
          this.getDocumentThumbnail();
        }

        this.loading = false;
      });
    } else if (this.document) {
      if (this.document && this.document.ID) {
        this.documentID = this.document.ID;
      }

      this.getDocumentType();

      this.defaultImg = this.utils.getDefaultPic(this.type, this.document.Extension);
      this.getDocumentThumbnail();
    }
  }

  getDocumentThumbnail() {
    this.link = null;

    if (this.documentID) {
      this.link = this.utilService.getDocumentThumbnailStreamLink(this.documentID);
    }
  }

  getDocumentType() {
    this.type = this.utils.getDocumentType(this.document);
  }

  clickEvent() {
    this.getClick.emit(true);
  }
}
