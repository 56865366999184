<div class="rel dentist-card" *ngIf="merchants && merchants.length > 0" @simpleFadeAnimation>
  <div class="animate">
    <mat-dialog-content class="DentistProfile">
      <div class="card-header primary-gradient-img clearfix stacked">
        <button class="btn-close btn-clear mat-button" (click)="closeEvent()">
          <mat-icon>clear</mat-icon>
        </button>

        <div class="row clearfix">
          <div class="row titleArea clearfix">
            <h2 class="summary-header rm-m">Merchant List</h2>
          </div>
        </div>
      </div>

      <div class="row clearfix profile">
        <p class="small">{{ title }}</p>

        <mat-select class="" [(ngModel)]="merchant" name="MerchantName" placeholder="Merchant">
          <mat-option [value]="null"> My Account</mat-option>

          <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m">
            <span
              class="list-label pull-right"
              *ngIf="m['Status'] && m['Status'] == 'Active'"
              [style.background]="'#00d07a'"
            >
              {{ m['Status'] }}
            </span>

            <span
              class="list-label pull-right"
              *ngIf="m['Status'] && m['Status'] != 'Active'"
              [style.background]="'#ff5722'"
            >
              {{ m['Status'] }}
            </span>
            {{ m['TradingAs'] || m['CalculatedName'] }}
          </mat-option>
        </mat-select>

        <button class="pull-right merchBtn" *ngIf="merchant" (click)="viewMerchant()" mat-raised-button color="primary">
          <mat-icon>person</mat-icon>
          <span class="mobHide">View Merchant</span>
        </button>
      </div>
    </mat-dialog-content>

    <hr class="mt mb"/>
    <div class="clearfix full-width text-center">
      <button class="btn-large" (click)="selectMerchant()" mat-raised-button color="accent">
        Selected
      </button>
    </div>
  </div>
</div>
