import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-patient-personal-sms',
  templateUrl: './patient-personal-sms.component.html',
  styleUrls: ['./patient-personal-sms.component.css'],
})
export class PatientPersonalSMSComponent implements OnInit {

  @Output()
  confirmation: EventEmitter<any> = new EventEmitter();

  @Output()
  confirmationSecond: EventEmitter<any> = new EventEmitter();

  @Output()
  decline: EventEmitter<any> = new EventEmitter();

  @Output()
  information: EventEmitter<any> = new EventEmitter();

  isSecondConfirmation = true;
  isInformation = true;

  isNo = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {


    }
  }

  ngOnInit() {}

  isFontAwsome(icon) {
    if (icon) {
      if (icon.indexOf('fas ') !== -1 || icon.indexOf('fab-') !== -1) {
        return true;
      }

      return false;
    }

    return false;
  }
  onYes() {
    this.confirmation.emit(true);
  }

  onYesSecond() {
    this.confirmationSecond.emit(true);
  }

  onInformation() {
    this.information.emit(true);
  }

  onNo() {
    this.decline.emit(false);
  }
}
