import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ProductRoutingModule } from './shared/product-routing.module';

import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FinanceCalculatorComponent } from './finance-calculator/finance-calculator.component';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductService } from './shared/product.service';

import { MemberProductViewComponent } from './member-product-view/member-product-view.component';
import { MemberSuitableProductListComponent } from './member-suitable-product-list/member-suitable-product-list.component';
import { ProductListCompactMerchComponent } from './product-list-compact-merch/product-list-compact-merch.component';
import { ProductViewCompactMerchComponent } from './product-view-compact-merch/product-view-compact-merch.component';
import { ProductViewModalComponent } from './product-view-modal/product-view-modal.component';
import { SuitableProductCompactListComponent } from './suitable-product-compact-list/suitable-product-compact-list.component';
import { SuitableProductListComponent } from './suitable-product-list/suitable-product-list.component';

import { ProductDocumentLibraryCreateComponent } from './product-document-library-create/product-document-library-create.component';
import { ProductDocumentLibraryComponent } from './product-document-library/product-document-library.component';

import { MatSortModule } from '@angular/material/sort';
import { NgPipesModule } from 'ngx-pipes';
import { MessageModule } from '../message/message.module';
import { DentalProductCustomerListComponent } from './dynamic-product-customer-list/components/dental-customer-product-list/dental-product-customer-list.component';
import { RetailProductCustomerListComponent } from './dynamic-product-customer-list/components/retail-customer-product-list/retail-product-customer-list.component';
import { DynamicProductCustomerListComponent } from './dynamic-product-customer-list/dynamic-product-customer-list.component';
import { DentalCustomerProductFormComponent } from './dynamic-product-customer-list/modals/customer-product-form.modal/components/dental-customer-product-form/dental-customer-product-form.component';
import { RetailCustomerProductFormComponent } from './dynamic-product-customer-list/modals/customer-product-form.modal/components/retail-customer-product-form/retail-customer-product-form.component';
import { CustomerProductFormModalComponent } from './dynamic-product-customer-list/modals/customer-product-form.modal/customer-product-form.modal.ts.component';
import { DentalViewCustomerProductComponent } from './dynamic-product-customer-list/modals/view-customer-product.modal/components/dental-view-customer-product/dental-view-customer-product.component';
import { RetailViewCustomerProductComponent } from './dynamic-product-customer-list/modals/view-customer-product.modal/components/retail-view-customer-product/retail-view-customer-product.component';
import { ViewCustomerProductModalComponent } from './dynamic-product-customer-list/modals/view-customer-product.modal/view-customer-product.modal';
import { DentalProductInvoiceListComponent } from './dynamic-product-invoice-list/components/dental-product-invoice-list/dental-product-invoice-list.component';
import { RetailProductInvoiceListComponent } from './dynamic-product-invoice-list/components/retail-product-invoice-list/retail-product-invoice-list.component';
import { DynamicProductInvoiceListComponent } from './dynamic-product-invoice-list/dynamic-product-invoice-list.component';
import { DentalProductInvoiceFormComponent } from './dynamic-product-invoice-list/modals/product-invoice-form.modal.ts/components/dental-product-invoice-form/dental-product-invoice-form.component';
import { RetailProductInvoiceFormComponent } from './dynamic-product-invoice-list/modals/product-invoice-form.modal.ts/components/retail-product-invoice-form/retail-product-invoice-form.component';
import { InvoiceProductFormModalComponent } from './dynamic-product-invoice-list/modals/product-invoice-form.modal.ts/product-invoice-form.modal.ts.component';
import { DentalViewProductInvoiceComponent } from './dynamic-product-invoice-list/modals/view-product-invoice.modal/components/dental-view-product-invoice/dental-view-product-invoice.component';
import { RetailViewProductInvoiceComponent } from './dynamic-product-invoice-list/modals/view-product-invoice.modal/components/retail-view-product-invoice/retail-view-product-invoice.component';
import { ViewInvoiceProductModalComponent } from './dynamic-product-invoice-list/modals/view-product-invoice.modal/view-product-invoice.modal';
import { DentalProductListComponent } from './dynamic-product-list/components/dental-product-list/dental-product-list.component';
import { RetailProductListComponent } from './dynamic-product-list/components/retail-product-list/retail-product-list.component';
import { DynamicProductListComponent } from './dynamic-product-list/dynamic-product-list.component';
import { DentalProductFormComponent } from './dynamic-product-list/modals/product-form.modal.ts/components/dental-product-form/dental-product-form.component';
import { RetailProductFormComponent } from './dynamic-product-list/modals/product-form.modal.ts/components/retail-product-form/retail-product-form.component';
import { ProductFormModalComponent } from './dynamic-product-list/modals/product-form.modal.ts/product-form.modal.ts.component';
import { DentalViewProductComponent } from './dynamic-product-list/modals/view-product.modal/components/dental-view-product/dental-view-product.component';
import { RetailViewProductComponent } from './dynamic-product-list/modals/view-product.modal/components/retail-view-product/retail-view-product.component';
import { ViewProductModalComponent } from './dynamic-product-list/modals/view-product.modal/view-product.modal';
import { ProductCalculatorComponent } from './product-calculator/product-calculator.component';
import { ProductCatalogueService } from './shared/services/product-catalogue.service';
import { ProductCustomerService } from './shared/services/product-customer.service';
import { ProductInvoiceService } from './shared/services/product-invoice.service';
import { CardModule } from '../card/card.module';

@NgModule({
  imports: [
    NgPipesModule,
    CommonModule,

    CardModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    CdkTableModule,
    ProductRoutingModule,
    MatSortModule,
    MessageModule,
  ],
  declarations: [
    ProductCalculatorComponent,
    ProductListComponent,
    FinanceCalculatorComponent,
    ProductViewModalComponent,
    ProductViewCompactMerchComponent,
    ProductListCompactMerchComponent,
    SuitableProductCompactListComponent,
    SuitableProductListComponent,
    MemberProductViewComponent,
    MemberSuitableProductListComponent,
    ProductDocumentLibraryComponent,
    ProductDocumentLibraryCreateComponent,
    ProductFormModalComponent,
    ViewProductModalComponent,
    InvoiceProductFormModalComponent,
    ViewInvoiceProductModalComponent,
    CustomerProductFormModalComponent,
    ViewCustomerProductModalComponent,
    RetailProductListComponent,
    RetailProductFormComponent,
    DentalProductListComponent,
    DentalProductFormComponent,
    DentalViewProductComponent,
    RetailViewProductComponent,
    DynamicProductListComponent,
    DynamicProductInvoiceListComponent,
    RetailProductInvoiceListComponent,
    RetailProductInvoiceFormComponent,
    DentalProductInvoiceListComponent,
    DentalProductInvoiceFormComponent,
    DentalViewProductInvoiceComponent,
    RetailViewProductInvoiceComponent,
    InvoiceProductFormModalComponent,
    ViewInvoiceProductModalComponent,
    DynamicProductCustomerListComponent,
    RetailProductCustomerListComponent,
    RetailCustomerProductFormComponent,
    DentalProductCustomerListComponent,
    DentalCustomerProductFormComponent,
    DentalViewCustomerProductComponent,
    RetailViewCustomerProductComponent,
    CustomerProductFormModalComponent,
    ViewCustomerProductModalComponent,
  ],
  exports: [
    ProductCalculatorComponent,

    ProductListComponent,
    FinanceCalculatorComponent,
    ProductViewModalComponent,
    ProductViewCompactMerchComponent,
    ProductListCompactMerchComponent,
    SuitableProductCompactListComponent,
    SuitableProductListComponent,
    MemberProductViewComponent,
    MemberSuitableProductListComponent,
    ProductDocumentLibraryComponent,
    ProductDocumentLibraryCreateComponent,
    RetailProductListComponent,
    RetailProductFormComponent,
    DentalProductListComponent,
    DentalProductFormComponent,
    DentalViewProductComponent,
    RetailViewProductComponent,
    DynamicProductListComponent,
    DynamicProductInvoiceListComponent,
    RetailProductInvoiceListComponent,
    RetailProductInvoiceFormComponent,
    DentalProductInvoiceListComponent,
    DentalProductInvoiceFormComponent,
    DentalViewProductInvoiceComponent,
    RetailViewProductInvoiceComponent,
    InvoiceProductFormModalComponent,
    ViewInvoiceProductModalComponent,
    DynamicProductCustomerListComponent,
    RetailProductCustomerListComponent,
    RetailCustomerProductFormComponent,
    DentalProductCustomerListComponent,
    DentalCustomerProductFormComponent,
    DentalViewCustomerProductComponent,
    RetailViewCustomerProductComponent,
    CustomerProductFormModalComponent,
    ViewCustomerProductModalComponent,
  ],
  providers: [ProductService, ProductCatalogueService, ProductCustomerService, ProductInvoiceService],
  entryComponents: [
    ProductFormModalComponent,
    ViewProductModalComponent,
    InvoiceProductFormModalComponent,
    ViewInvoiceProductModalComponent,
    CustomerProductFormModalComponent,
    ViewCustomerProductModalComponent,
  ],
})
export class ProductModule {}
