import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CodeHTMLCssInputComponent } from '../../../shared/components/html-css-input/html-css-input.component';
import { CodeHTMLEditComponent } from '../../../shared/components/html-edit/html-edit.component';
import { HtmlIframeInputComponent } from '../../../shared/components/html-iframe-input/html-iframe-input.component';
import { CodeHTMLInputComponent } from '../../../shared/components/html-input/html-input.component';
import { HtmlPopupInputComponent } from '../../../shared/components/html-popup-input/html-popup-input.component';
import { HtmlSimpleLinkInputComponent } from '../../../shared/components/html-simple-link-input/html-simple-link-input.component';
import { CodeHTMLViewComponent } from '../../../shared/components/html-view/html-view.component';
import { FeatureMainLabelCreateEditComponent } from '../feature-main-label-create-edit/feature-main-label-create-edit.component';
import { FeatureMainLabelQuickEditComponent } from '../feature-main-label-quick-edit/feature-main-label-quick-edit.component';
import { FeatureSubLabelCreateEditComponent } from '../feature-sub-label-create-edit/feature-sub-label-create-edit.component';
import { FeatureSubLabelQuickEditComponent } from '../feature-sub-label-quick-edit/feature-sub-label-quick-edit.component';
import { SubscriptionPlanCalculatorComponent } from '../subscription-plan-calculator/subscription-plan-calculator.component';
import { SubscriptionPlanCardListGlobalComponent } from '../subscription-plan-card-list-global/subscription-plan-card-list-global.component';
import { SubscriptionPlanCongratulationComponent } from '../subscription-plan-congratulation/subscription-plan-congratulation.component';
import { SubscriptionPlanCreateEditComponent } from '../subscription-plan-create-edit/subscription-plan-create-edit.component';
import { SubscriptionPlanLookupComponent } from '../subscription-plan-lookup/subscription-plan-lookup.component';
import { SubscriptionPlanUpdatedComponent } from '../subscription-plan-updated/subscription-plan-updated.component';
import { SubscriptionPlanUserEditComponent } from '../subscription-plan-user-edit/subscription-plan-user-edit.component';
import { SubscriptionPlanViewModalComponent } from '../subscription-plan-view-modal/subscription-plan-view-modal.component';
import { SubscriptionPlanViewComponent } from '../subscription-plan-view/subscription-plan-view.component';
import { InnerHtmlIsolatedComponent } from '../../../shared/components/inner-html-isolated/inner-html-isolated.component';
import { InnerHtmlIsolatedModalComponent } from '../../../shared/components/inner-html-isolated-modal/inner-html-isolated-modal.component';

const routes: Routes = [


  {
    path: 'InnerHtmlIsolatedModalComponent',
    component: InnerHtmlIsolatedModalComponent,
  },
  {
    path: 'InnerHtmlIsolatedComponent',
    component: InnerHtmlIsolatedComponent,
  },
  {
    path: 'HtmlIframeInputComponent',
    component: HtmlIframeInputComponent,
  },
  {
    path: 'HtmlPopupInputComponent',
    component: HtmlPopupInputComponent,
  },

  {
    path: 'HtmlSimpleLinkInputComponent',
    component: HtmlSimpleLinkInputComponent,
  },
  {
    path: 'SubscriptionPlanUpdatedComponent',
    component: SubscriptionPlanUpdatedComponent,
  },

  {
    path: 'SubscriptionPlanCreateEditComponent',
    component: SubscriptionPlanCreateEditComponent,
  },

  {
    path: 'SubscriptionPlanUserEditComponent',
    component: SubscriptionPlanUserEditComponent,
  },

  {
    path: 'FeatureMainLabelQuickEditComponent',
    component: FeatureMainLabelQuickEditComponent,
  },

  {
    path: 'SubscriptionPlanLookupComponent',
    component: SubscriptionPlanLookupComponent,
  },
  {
    path: 'SubscriptionPlanCalculatorComponent',
    component: SubscriptionPlanCalculatorComponent,
  },
  {
    path: 'FeatureSubLabelQuickEditComponent',
    component: FeatureSubLabelQuickEditComponent,
  },

  {
    path: 'FeatureMainLabelCreateEditComponent',
    component: FeatureMainLabelCreateEditComponent,
  },
  {
    path: 'FeatureSubLabelCreateEditComponent',
    component: FeatureSubLabelCreateEditComponent,
  },

  {
    path: 'SubscriptionPlanCongratulationComponent',
    component: SubscriptionPlanCongratulationComponent,
  },


  {
    path: 'SubscriptionPlanViewComponent',
    component: SubscriptionPlanViewComponent,
  },
  {
    path: 'SubscriptionPlanCardListGlobalComponent',
    component: SubscriptionPlanCardListGlobalComponent,
  },
  {
    path: 'SubscriptionPlanViewModalComponent',
    component: SubscriptionPlanViewModalComponent,
  },

  {
    path: 'view-code-input-c',
    component: CodeHTMLInputComponent,
  },
  {
    path: 'edit-code-c',
    component: CodeHTMLEditComponent,
  },
  {
    path: 'view-code-c',
    component: CodeHTMLViewComponent,
  },
  {
    path: 'view-code-css',
    component: CodeHTMLCssInputComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SubscriptionPlanRoutingModule {}
