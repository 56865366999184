import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { switchMap } from 'rxjs/operators';
import { AppStateService } from '../../../../../../../core/app-state/app-state.service';
import { User } from '../../../../../../../core/authentication/shared/shared/types/authentication.type';
import { UtilsService } from '../../../../../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../../../../../shared/types/notify-app-component';
import { RelationshipLink } from '../../../../../../../shared/types/utils/utils.type';
import { Dentist } from '../../../../../../dentist/shared/dentist.type';
import { CustomerAndProspect } from '../../../../../../invitation/shared/types/invitation.type';
import { Merchant } from '../../../../../../merchant/shared/types/merchant.type';
import { NotesService } from '../../../../../shared/services/notes.service';
import { Note, NoteType } from '../../../../../shared/type/notes.type';
import { NoteModalMode } from '../../../notes.modal';
import { ADMIN_NOTE_SHARE_OPTIONS, PUBLIC_NOTE_SHARE_OPTIONS } from './shared/data/note-form';

@Component({
  selector: 'ipv-note-form',
  templateUrl: './note-form.component.html',
  styleUrls: ['./note-form.component.css'],
})
export class NoteFormComponent implements OnInit, OnDestroy, OnChanges {
  @Output() noteListModeSet: EventEmitter<'view' | 'create' | 'update' | 'list'> = new EventEmitter();
  @Output() closeModal: EventEmitter<void> = new EventEmitter();

  @Input() formMode: NoteModalMode = 'create';
  @Input() patient: Partial<CustomerAndProspect>;
  @Input() practice: Partial<Merchant>;
  @Input() dentist: Dentist;
  @Input() user: User;
  @Input() relationshipLink: RelationshipLink;
  @Input() selectedNote: Partial<Note>;
  @Input() noteTypes: Partial<NoteType>[];
  @Input() areNotesPrivate: boolean;
  @Input() parentRoute: string;
  @Input() prefillNoteAction: string;

  noteText = '';
  followUpText = '';

  startTime = '';
  endTime = '';
  duration: string;

  noteActions: string[];
  noteSubjects: string[];
  noteResults: string[];
  shareWithControlValue: string[] = ['the practice'];
  shareWithOptions: string[] = [];

  selectedNoteAction: string;
  selectedNoteSubject: string;
  selectedNoteResult: string;

  hasPrivateShareOption = false;

  enableFollowUp;

  noteDate: string;
  followUpDate = null;
  disabledOptions = false;
  disabledUpdate = false;
  selectedIndex = 0;

  recontactDone = true;

  timerDisabled = false;

  isPromoterOrAdmin = false;

  initialDate = { startDate: this.getNextWeek() };

  forceEndTimeNow = false;

  constructor(
    private appStateService: AppStateService,
    private notesService: NotesService,
    private utilService: UtilsService,
    private router: Router
  ) {}

  ngOnInit() {
    if (this.formMode === 'create') {
      this.followUpDate = this.getNextWeek();
    }

    this.getNoteActions();

    this.appStateService
      .getAppState$()
      .pipe(untilDestroyed(this))
      .subscribe((appState) => {
        this.isPromoterOrAdmin = appState.isPromoterOrAdmin;
        this.shareWithOptions = this.isPromoterOrAdmin === true ? ADMIN_NOTE_SHARE_OPTIONS : PUBLIC_NOTE_SHARE_OPTIONS;
      });

    if (!this.selectedNote && !this.relationshipLink) {
      this.relationshipLink = {
        itemID: null,
        route: null,
        label: null,
      };
    }

    if (this.formMode === 'update') {
      if (this.selectedNote && this.selectedNote.Relationship_Label) {
        this.relationshipLink = {
          itemID: this.selectedNote.Relationship_ID,
          route: this.selectedNote.Relationship_Route,
          label: this.selectedNote.Relationship_Label,
        };
      }
    }

    if (this.prefillNoteAction) {
      this.selectedNoteAction = this.prefillNoteAction;
    }
  }

  ngOnDestroy() {
    /** required for untilDestroyed (Replace with @UntilDestroyed() Decorator in angular 9 and delete ngOnDestroy()) */
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.formMode === 'update' || this.formMode === 'view') {
      if (changes.selectedNote) {
        this.disabledOptions = true;

        if (this.formMode === 'view') {
          this.disabledUpdate = true;
        }

        this.noteText = this.selectedNote.Description;
        this.followUpText = this.selectedNote.Followup_Comments;
        this.startTime = this.selectedNote['Start.Time'];
        this.endTime = this.selectedNote['Stop.Time'];
        this.duration = this.selectedNote['Duration'];
        this.timerDisabled = true;

        // Load selected note options in update mode
        this.selectedNoteAction = this.selectedNote['Action'];
        this.getNoteSubject();
        this.selectedNoteSubject = this.selectedNote['Subject'];
        this.getNoteResults();
        this.selectedNoteResult = this.selectedNote['Result'];

        this.enableFollowUp = true;
        this.recontactDone = this.selectedNote.RecontactDone === '1';
        if (!this.recontactDone && this.formMode !== 'view') {
          this.selectedIndex = 1;
          this.recontactDone = true;
        }

        // Load share options

        if (this.selectedNote.View_By_Merchant === '1') {
          this.shareWithControlValue.push('merchant');
        }

        if (this.selectedNote.View_By_Funder === '1') {
          this.shareWithControlValue.push('funder');
        }

        if (this.selectedNote.View_By_Patient === '1') {
          this.shareWithControlValue.push('patient');
        }

        if (this.selectedNote.View_By_Supplier === '1') {
          this.shareWithControlValue.push('supplier');
        }

        if (this.selectedNote.View_By_Private === '1') {
          this.hasPrivateShareOption = true;
        }
      }
    }

    if (this.formMode === 'create') {
      this.getNoteActions();
    }
  }

  setNoteListToListMode() {
    this.noteListModeSet.emit('list');
    this.prefillNoteAction = undefined;
  }

  setNoteDate(dateRange: { startDate: any }) {
    this.noteDate = dateRange.startDate;
  }

  setFollowUpNoteDate(dateRange: { startDate: any }) {
    this.followUpDate = dateRange.startDate;
  }

  getNoteActions() {
    if (this.noteTypes.length > 0) {
      this.noteActions = this.noteTypes.map((noteType) => noteType['ActionType']);
    }
  }

  getNoteSubject() {
    if (this.noteTypes.length > 0) {
      const selectedNoteAction = this.noteTypes.filter(
        (noteType) => noteType['ActionType'] === this.selectedNoteAction
      )[0];
      this.noteSubjects = selectedNoteAction['AllowedValues.Subject'].split(';');
    }
  }

  getNoteResults() {
    if (this.noteTypes.length) {
      const selectedNoteAction = this.noteTypes.filter(
        (noteType) => noteType['ActionType'] === this.selectedNoteAction
      )[0];
      this.noteResults = selectedNoteAction['AllowedValues.Result'].split(';');
    }
  }

  setPrivateShareOption() {
    const foundPrivateOption =
      this.shareWithControlValue.findIndex((shareOption) => shareOption === 'make private') > -1;
    if (foundPrivateOption) {
      this.shareWithControlValue = ['make private'];
    }
  }

  setPublicShareOption() {
    this.shareWithControlValue = this.shareWithControlValue.filter((shareOption) => shareOption !== 'make private');
  }

  setEmptyOption(shareOptions: string[]) {
    if (shareOptions.length === 0) {
      this.shareWithControlValue = ['the practice'];
    }
  }

  getIsPrivateShareOptionSelected() {
    return this.shareWithControlValue.findIndex((shareOption) => shareOption === 'make private') > -1;
  }

  setTimerValue(timerValues: { duration: string; startTime: string; endTime: string }) {
    this.startTime = timerValues.startTime;
    this.endTime = timerValues.endTime;
    this.duration = timerValues.duration;

    const viewedByPrivate = this.hasPrivateShareOption ? '1' : '0';
    const viewedByFunder = this.shareWithControlValue.findIndex((value) => value === 'the funder') !== -1 ? '1' : '0';
    const viewedBySupplier =
      this.shareWithControlValue.findIndex((value) => value === 'the supplier') !== -1 ? '1' : '0';
    const viewedByPatient = this.shareWithControlValue.findIndex((value) => value === 'the patient') !== -1 ? '1' : '0';
    const viewedByMerchant =
      this.shareWithControlValue.findIndex((value) => value === 'the practice') !== -1 ? '1' : '0';

    const recontactDone = this.enableFollowUp ? '0' : '1';

    const payload: Note = {
      Action: this.selectedNoteAction,
      View_By_Funder: viewedByFunder,
      View_By_Merchant: viewedByMerchant,
      View_By_Patient: viewedByPatient,
      View_By_Supplier: viewedBySupplier,
      View_By_Private: viewedByPrivate,
      View_By_EP: '1',
      View_By_Promoter: '1',
      Followup_Comments: this.followUpText,
      'Dates.Recontact': this.followUpDate,
      Description: this.utilService.getLocallyPersonalizedText(
        this.noteText,
        this.patient,
        this.dentist,
        this.practice
      ),
      Operator_key: this.user.operatorID,
      RecontactDone: recontactDone,
      Is_Archived: '0',
      Result: this.selectedNoteResult,
      'Start.Time': this.startTime,
      'Stop.Time': this.endTime,
      Subject: this.selectedNoteSubject,
      'Who.key': this.patient.ID,
      Relationship_Route: this.relationshipLink.route,
      Relationship_ID: this.relationshipLink.itemID,
      Relationship_Label: this.relationshipLink.label,
    };

    this.notesService
      .createNote$<Note>(payload)
      .pipe(
        switchMap((noteWithID) => {
          return this.notesService.addSelectedNote$(noteWithID, !this.isNoteSavedToCurrentTab(payload));
        })
      )
      .subscribe((note) => {
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Successfully created note');
        this.noteListModeSet.emit('list');
      });
  }

  setNoteValue(text: string) {
    this.noteText = text;
  }

  setFollowUpValue(text: string) {
    this.followUpText = text;
  }

  createNote() {
    this.forceEndTimeNow = true;
    // see "setTimerValue" function
  }

  updateNote() {
    const viewedByPrivate = this.hasPrivateShareOption ? '1' : '0';
    const viewedByFunder = this.shareWithControlValue.findIndex((value) => value === 'funder') !== -1 ? '1' : '0';
    const viewedBySupplier = this.shareWithControlValue.findIndex((value) => value === 'supplier') !== -1 ? '1' : '0';
    const viewedByPatient = this.shareWithControlValue.findIndex((value) => value === 'patient') !== -1 ? '1' : '0';
    const viewedByMerchant = this.shareWithControlValue.findIndex((value) => value === 'merchant') !== -1 ? '1' : '0';
    const recontactDone = this.followUpText.length > 0 ? '1' : '0';

    const payload: Partial<Note> = {
      View_By_Funder: viewedByFunder,
      View_By_Merchant: viewedByMerchant,
      View_By_Patient: viewedByPatient,
      View_By_Supplier: viewedBySupplier,
      View_By_Private: viewedByPrivate,
      View_By_EP: '1',
      View_By_Promoter: '1',
      Followup_Comments: this.utilService.getLocallyPersonalizedText(
        this.followUpText,
        this.patient,
        this.dentist,
        this.practice
      ),
      Description: this.utilService.getLocallyPersonalizedText(
        this.noteText,
        this.patient,
        this.dentist,
        this.practice
      ),
      RecontactDone: recontactDone,
    };

    this.notesService
      .updateNote$(this.selectedNote, payload)
      .pipe(
        switchMap((note) => {
          return this.notesService.editSelectedNotes$(note, !this.isNoteSavedToCurrentTab(payload));
        })
      )
      .subscribe((updatedNoteWithID) => {
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Successfully updated note');

        this.noteListModeSet.emit('list');
      });
  }

  isFormModeCreate() {
    return this.formMode === 'create';
  }

  isNoteSavedToCurrentTab(newNote: Partial<Note>) {
    const avoidPrivateTab = this.areNotesPrivate === true && newNote.View_By_Private === '0';
    const avoidSharedTab = this.areNotesPrivate === false && newNote.View_By_Private === '1';

    if (avoidPrivateTab || avoidSharedTab) {
      return true;
    } else {
      return false;
    }
  }

  getNextWeek() {
    const today = new Date();
    const nextWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
    return nextWeek;
  }

  compareAction(current: string, other: string) {
    return other && current === other;
  }

  compareSubjects(current: string, other: string) {
    return other && current === other;
  }

  compareResults(current: string, other: string) {
    return other && current === other;
  }

  openRelationship() {
    const route = this.selectedNote.Relationship_Route.split('/');

    if (this.parentRoute) {
      this.router
        .navigate([
          `/${this.parentRoute}`,
          {
            outlets: {
              page: route,
            },
          },
        ])
        .then(() => this.closeModal.emit());
    } else {
      this.router
        .navigate([
          {
            outlets: {
              page: route,
            },
          },
        ])
        .then(() => this.closeModal.emit());
    }
  }
}
