/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./personal-circumstance-document.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "../../../shared/components/ep-document-list-mini/ep-document-list-mini.component.ngfactory";
import * as i6 from "../../../shared/components/ep-document-list-mini/ep-document-list-mini.component";
import * as i7 from "@angular/material/dialog";
import * as i8 from "../../../shared/services/utils.service";
import * as i9 from "../../../shared/components/empty-list-message/empty-list-message.component.ngfactory";
import * as i10 from "../../../shared/components/empty-list-message/empty-list-message.component";
import * as i11 from "@angular/common";
import * as i12 from "./personal-circumstance-document.component";
import * as i13 from "../shared/personal-circumstances.service";
import * as i14 from "@angular/router";
import * as i15 from "../../contract/shared/contract.service";
var styles_PCIDocumentComponent = [i0.styles];
var RenderType_PCIDocumentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PCIDocumentComponent, data: { "animation": [{ type: 7, name: "onChangeAnimation", definitions: [{ type: 1, expr: "void => *", animation: [{ type: 11, selector: ".animate", animation: { type: 6, styles: { opacity: 0 }, offset: null }, options: { optional: true } }, { type: 11, selector: ".animate", animation: { type: 12, timings: "100ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 0, transform: "translateY(0)", offset: 0, height: 0, margin: 0 }, offset: null }, { type: 6, styles: { opacity: 1, transform: "translateY(0)", offset: 1, height: "*", margin: "*" }, offset: null }] }, timings: "0.15s ease-out" }] }, options: { optional: true } }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 11, selector: ".animate", animation: { type: 6, styles: { opacity: 1 }, offset: null }, options: { optional: true } }, { type: 11, selector: ".animate", animation: { type: 12, timings: "100ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 1, transform: "translateY(0)", offset: 0, height: "*", margin: "*" }, offset: null }, { type: 6, styles: { opacity: 0, transform: "translateY(0)", offset: 1, height: 0, margin: 0 }, offset: null }] }, timings: "0.15s ease-in" }] }, options: { optional: true } }], options: null }], options: {} }] } });
export { RenderType_PCIDocumentComponent as RenderType_PCIDocumentComponent };
function View_PCIDocumentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-card", [["class", "treatment mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [[2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "app-ep-document-list-mini", [], null, null, null, i5.View_EpDocumentListMiniComponent_0, i5.RenderType_EpDocumentListMiniComponent)), i1.ɵdid(3, 114688, null, 0, i6.EpDocumentListMiniComponent, [i7.MatDialog, i8.UtilsService], { documents: [0, "documents"], title: [1, "title"], isAction: [2, "isAction"], isReplace: [3, "isReplace"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.documents; var currVal_2 = "Personal Circumstance Documents"; var currVal_3 = false; var currVal_4 = true; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0); }); }
function View_PCIDocumentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-empty-list-message", [], null, null, null, i9.View_EmptyListMessageComponent_0, i9.RenderType_EmptyListMessageComponent)), i1.ɵdid(2, 114688, null, 0, i10.EmptyListMessageComponent, [], { title: [0, "title"], message: [1, "message"] }, null)], function (_ck, _v) { var currVal_0 = "No Documents Found"; var currVal_1 = "There is no documents to show"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_PCIDocumentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "full-width clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PCIDocumentComponent_1)), i1.ɵdid(2, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PCIDocumentComponent_2)), i1.ɵdid(4, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.documents && (_co.documents.length > 0)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.documents && (_co.documents.length <= 0)); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_PCIDocumentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pci-document", [], null, null, null, View_PCIDocumentComponent_0, RenderType_PCIDocumentComponent)), i1.ɵdid(1, 114688, null, 0, i12.PCIDocumentComponent, [i13.PersonalCircumstancesService, i14.ActivatedRoute, i15.ContractService, [2, i7.MAT_DIALOG_DATA]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PCIDocumentComponentNgFactory = i1.ɵccf("app-pci-document", i12.PCIDocumentComponent, View_PCIDocumentComponent_Host_0, { personalCirID: "personalCirID", contractID: "contractID" }, {}, []);
export { PCIDocumentComponentNgFactory as PCIDocumentComponentNgFactory };
