import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ThemeCreateComponent } from '../theme-create/theme-create.component';
import { ThemeListComponent } from '../theme-list/theme-list.component';
import { ThemeViewComponent } from '../theme-view/theme-view.component';

const routes: Routes = [
  {
    path: 'theme-view',
    component: ThemeViewComponent,
  },
  {
    path: 'theme-create',
    component: ThemeCreateComponent,
  },
  {
    path: 'theme-create/:id',
    component: ThemeCreateComponent,
  },
  {
    path: 'theme-list',
    component: ThemeListComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ThemeRoutingModule {}
