import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-merchant-service-edit',
  templateUrl: './merchant-service-edit.component.html',
  styleUrls: ['./merchant-service-edit.component.css'],
})
export class MerchantServiceEditComponent implements OnInit {
  merchantServicesItem = {
    ID: '',
    Label: '',
    Description: '',
    ServiceGroup_key: '',
  };

  @Output() update = new EventEmitter();
  @Output() close = new EventEmitter();

  isModal = true;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.merchantServicesItem = data;
    }
  }

  ngOnInit() {}

  updateMerchantServiceGroup() {
    if (this.merchantServicesItem.ID) {
      this.update.emit(this.merchantServicesItem);
    }
  }

  closeModalEvent() {
    this.close.emit(true);
  }

  getContent(c) {
    this.merchantServicesItem.Description = c;
  }
}
