import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TaskManagerCreateEditComponent } from '../task-manager-create-edit/task-manager-create-edit.component';
import { TasksCreateComponent } from '../tasks-create/tasks-create.component';
import { TasksListComponent } from '../tasks-list/tasks-list.component';
import { TasksViewComponent } from '../tasks-view/tasks-view.component';

const routes: Routes = [
  {
    path: 'tasks-list',
    component: TasksListComponent,
  },
  {
    path: 'tasks-create',
    component: TasksCreateComponent,
  },
  {
    path: 'tasks-view',
    component: TasksViewComponent,
  },
  {
    path: 'TaskManagerCreateEditComponent',
    component: TaskManagerCreateEditComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TasksRoutingModule {}
