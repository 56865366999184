import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MerchantLinkCreateEditComponent } from '../merchant-link-create-edit/merchant-link-create-edit.component';
import { MerchantLinkLookupComponent } from '../merchant-link-lookup/merchant-link-lookup.component';

const routes: Routes = [
  {
    path: 'MerchantLinkCreateEditComponent',
    component: MerchantLinkCreateEditComponent,
  },
  {
    path: 'MerchantLinkLookupComponent',
    component: MerchantLinkLookupComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MerchantLinkRoutingModule {}
