import { Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-before-starting',
  templateUrl: './before-starting.component.html',
  styleUrls: ['./before-starting.component.css'],
})
export class InvTreatBeforeStartingComponent implements OnInit {
  showBeforeStartingInvitationTemplate = JSON.parse(localStorage.getItem('showStartingDialogTreatmentTemplate'));
  closeModal = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  doNotShow() {
    localStorage.setItem(
      'showStartingDialogTreatmentTemplate',
      JSON.stringify(this.showBeforeStartingInvitationTemplate)
    );

    this.closeModal.emit(this.showBeforeStartingInvitationTemplate);
  }
}
