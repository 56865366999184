import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MerchantService } from '../../shared/merchant.service';

@Component({
  selector: 'sr-add-health-fund-profile-dialog',
  templateUrl: './add-health-fund-profile-dialog.component.html',
  styleUrls: ['./add-health-fund-profile-dialog.component.css'],
})
export class AddHealthFundProfileDialogComponent implements OnInit {
  @Output() addHealthFundProfile: EventEmitter<any> = new EventEmitter();

  merchantID;

  fullHealthFunds = [];
  currentHealthFunds = [];
  selectedHealthFunds = {};

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private merchantService: MerchantService) {
    if (this.data) {
      this.merchantID = data.merchantID;
    }
  }

  ngOnInit(): void {
    this.merchantService.getMerchantHealthFundList({}).subscribe((fullHealthFunds) => {
      this.fullHealthFunds = fullHealthFunds
        .sort((a, b) => (a.Label > b.Label ? 1 : -1))
        .map((healthFund) => {
          return { ...healthFund, Label: healthFund.Label.toLowerCase() };
        });

      this.merchantService
        .getMerchantProfileHealthFundsList({ Merchant_key: this.merchantID })
        .subscribe((currentHealthFunds) => {
          this.currentHealthFunds = currentHealthFunds;

          this.currentHealthFunds.forEach((currentService) => {
            const health_Fund_Label = currentService['HealthFund.Label'].toLowerCase();

            this.selectedHealthFunds[health_Fund_Label] = true;
          });
        });
    });
  }

  save() {
    const newItems = [];
    const removedItems = [];

    for (const key in this.selectedHealthFunds) {
      if (this.selectedHealthFunds[key]) {
        const itemExistsIndex = this.currentHealthFunds.findIndex((currentHealthFund) => {
          return currentHealthFund['HealthFund.Label'].toLowerCase() === key.toLowerCase();
        });

        if (itemExistsIndex < 0) {
          const itemDataIndex = this.fullHealthFunds.findIndex((fullHealthFund) => {
            return fullHealthFund['Label'].toLowerCase() === key.toLowerCase();
          });

          const fullItemData = this.fullHealthFunds[itemDataIndex];

          const newHealthFund = {
            Merchant_key: this.merchantID,
            'HealthFund.Code': fullItemData['Code'],
            'HealthFund.Label': fullItemData['Label'],
          };

          newItems.push(newHealthFund);
        }
      } else if (this.selectedHealthFunds[key] === false) {
        const itemExistsIndex = this.currentHealthFunds.findIndex((currentHealthFund) => {
          return currentHealthFund['HealthFund.Label'].toLowerCase() === key.toLowerCase();
        });

        if (itemExistsIndex > -1) {
          removedItems.push(this.currentHealthFunds[itemExistsIndex].ID);
        }
      }
    }

    this.addHealthFundProfile.emit({
      removedHealthFunds: removedItems,
      newHealthFunds: newItems,
    });
  }
}
