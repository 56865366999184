/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./merchant-main-card.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../main-card/main-card.component.ngfactory";
import * as i3 from "../main-card/main-card.component";
import * as i4 from "../../../shared/services/utils.service";
import * as i5 from "../../../core/authentication/shared/authentication.service";
import * as i6 from "@angular/router";
import * as i7 from "../shared/patient.service";
import * as i8 from "../../merchant/shared/merchant.service";
import * as i9 from "../../invitation/shared/services/invitation.service";
import * as i10 from "../../../shared/pipes/custom-phone.pipe";
import * as i11 from "@angular/common";
import * as i12 from "./merchant-main-card.component";
var styles_MerchantMainCardComponent = [i0.styles];
var RenderType_MerchantMainCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MerchantMainCardComponent, data: {} });
export { RenderType_MerchantMainCardComponent as RenderType_MerchantMainCardComponent };
function View_MerchantMainCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-main-card", [], null, null, null, i2.View_MainCardComponent_0, i2.RenderType_MainCardComponent)), i1.ɵdid(1, 245760, null, 0, i3.MainCardComponent, [i4.UtilsService, i5.AuthenticationService, i6.ActivatedRoute, i6.Router, i7.PatientService, i8.MerchantService, i9.InvitationService, i10.CustomPhonePipe], { invitationID: [0, "invitationID"], actions: [1, "actions"], accessByMerchant: [2, "accessByMerchant"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.wakandaID; var currVal_1 = _co.actions; var currVal_2 = _co.accessByMerchant; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_MerchantMainCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MerchantMainCardComponent_1)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.display == true); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MerchantMainCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-merchant-main-card", [], null, null, null, View_MerchantMainCardComponent_0, RenderType_MerchantMainCardComponent)), i1.ɵdid(1, 114688, null, 0, i12.MerchantMainCardComponent, [i9.InvitationService, i6.ActivatedRoute, i5.AuthenticationService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MerchantMainCardComponentNgFactory = i1.ɵccf("app-merchant-main-card", i12.MerchantMainCardComponent, View_MerchantMainCardComponent_Host_0, { invitationID: "invitationID", actions: "actions" }, {}, []);
export { MerchantMainCardComponentNgFactory as MerchantMainCardComponentNgFactory };
