<div class="container full-width contract" *ngIf="chartOnly==true && contract">
  <div class="row clearfix" *ngIf="contract$ | async as contract" @onChangeAnimation>
    <ng-container *ngIf="settlements$ | async as settlements">

      <div class="row clearfix flex contractView">
        <div class="full-width">
          <div class="row clearfix rel chart-card">
            <div class="total anim">
              <span class="totLab" [ngClass]="{ inModalTotLab: isModal == true }">Original Loan</span>
              <span class="balance"> {{contract['Amount.Invoice'] | customCurrency}}</span>
            </div>
            <app-chart-input class="fullHeight" [titleIsTop]="true" [showLegend]="false" [showLabel]="false"
              [chartType]="'pie'" [title]="''" [data]="summaryChartData | orderBy: ['-name']"
              [dataNameAttribute]="'name'" [dataValueAttribute]="'value'" [customColorScheme]="customSummaryChartColor">
            </app-chart-input>
          </div>
        </div>


      </div>


    </ng-container>
  </div>
</div>


<div class="container contract" *ngIf="chartOnly==false">
  <div class="row clearfix" *ngIf="contract$ | async as contract" @onChangeAnimation>
    <ng-container *ngIf="settlements$ | async as settlements">
      <mat-card class="animate clearfix contractViewMain" *ngIf="hideMainBody!=true">
        <div class="card-header primary-gradient-img clearfix inModal" *ngIf="hideHeader!=true">
          <div class="row clearfix">

            <button class="btn-close btn-clear mat-button" *ngIf="isModal == true" (click)="closeEvent()">
              <mat-icon>clear</mat-icon>
            </button>

            <div class="row titleArea clearfix">
              <mat-icon class="pull-left fas fa-hand-holding-usd"></mat-icon>
              <h2 class="summary-header rm-m">
                {{ contract['ProductName'] }}
                <span class="subLabel">Contract for {{ contract['Customer.FullName'] }} - Commenced
                  {{ contract.ContractDate | customDate }} - Contract ID - {{ contract.ID }}

                  <br *ngIf="isPromoterOrAdmin == true && contract && contract['Status']" />
                  <span *ngIf="isPromoterOrAdmin == true && contract && contract['Status']">Contract Status:
                    {{ contract['Status'] }}</span>
                </span>
              </h2>
            </div>
          </div>

          <div class="action-button" *ngIf="isIntegrated != true">
            <button class="gradient green mr"
              *ngIf="allowContractStatus == true && newSettlementButton==true && (contract['Settlements.NotYetDrawn'] | toNumber) > 0"
              (click)="newSettlement()" mat-raised-button color="primary"
              matTooltip="Create settlement for this contract">
              <mat-icon class="fas fa-comment-dollar" aria-hidden="true"></mat-icon>
              New Settlement
            </button>

            <button class="gradient green mr" *ngIf="allowContractStatus != true  && newSettlementButton==true"
              (click)="settlementModal()" mat-raised-button color="primary"
              matTooltip="Create settlement for this contract">
              <mat-icon class="fas fa-comment-dollar" aria-hidden="true"></mat-icon>
              New Settlement
            </button>


            <app-invitation-actions *ngIf="contract" [customerID]="contract['Customer_key']"
              [displayNewSettlement]="false" class="  contract-action-invitation   pull-right "
              [buttonType]="'buttonAccent'" [invitationID]="contract['Invitation_key']" [items]="items"
              [contractID]="contract['ID']" [merchantID]="contract['Merchant_key']"
              [dentistID]="contract['DentistContact_key']" [campaignID]="contract['Campaign_Key']" [contract]="contract"
              [productID]="contract['Product_key']">
            </app-invitation-actions>


            <!--
            <button class="actionMenuToggle" [matMenuTriggerFor]="menu" mat-raised-button>
              Actions
              <mat-icon>more_vert</mat-icon>
            </button> -->

            <mat-menu #menu="matMenu">
              <button *ngIf="contract['Customer_key']" (click)="openCustomerDetailsDialog(contract)" mat-menu-item>
                <mat-icon>portrait</mat-icon>
                <span>View customer </span>
              </button>

              <button *ngIf="contract['Customer_key']" (click)="contact(contract)" mat-menu-item>
                <mat-icon>contact_mail</mat-icon>
                <span>Contact customer </span>
              </button>

              <button *ngIf="contract['Product_key']" (click)="viewProduct(contract)" mat-menu-item>
                <mat-icon>business_center</mat-icon>
                <span>View product </span>
              </button>

              <button *ngIf="contract['Invitation_key']" (click)="openInviteViewDialog(contract)" mat-menu-item>
                <mat-icon>search</mat-icon>
                <span>View invitation </span>
              </button>

              <button *ngIf="contract['Merchant_key']" (click)="viewMerchant(contract)" mat-menu-item>
                <mat-icon>person</mat-icon>
                <span>View Merchant</span>
              </button>

              <button *ngIf="isPromoterOrAdmin == true" (click)="viewEventLog(contract)" mat-menu-item>
                <mat-icon>insert_chart_outlined</mat-icon>
                <span>View History</span>
              </button>

              <mat-divider *ngIf="isPromoterOrAdmin == true"></mat-divider>

              <button *ngIf="isPromoterOrAdmin == true" (click)="showFullDetails()" mat-menu-item>
                <span *ngIf="isShowFull == false">
                  <mat-icon class="">expand_more</mat-icon> More Details
                </span>
                <span *ngIf="isShowFull != false">
                  <mat-icon class="">expand_less</mat-icon> Less Details
                </span>
              </button>
            </mat-menu>
          </div>
        </div>

        <div class="row clearfix flex contractView">
          <div class="qtr-width mr ml chart-card-parent">
            <div class="row clearfix rel chart-card">
              <div class="total anim">
                <span class="totLab" [ngClass]="{ inModalTotLab: isModal == true }">Original Loan</span>
                <span class="balance"> {{contract['Amount.Invoice'] | customCurrency}}</span>
              </div>
              <app-chart-input class="fullHeight" [titleIsTop]="true" [showLegend]="false" [showLabel]="false"
                [chartType]="'pie'" [title]="''" [data]="summaryChartData | orderBy: ['-name']"
                [dataNameAttribute]="'name'" [dataValueAttribute]="'value'"
                [customColorScheme]="customSummaryChartColor">
              </app-chart-input>
            </div>
          </div>
          <div class="full-width ">
            <!-- <div class="full-width flex add-mt">

              <div class="thrd-width">
                <h3 class="sr-title">Settled</h3>
                <label class="small " style="margin-bottom:5px !important;">Paid </label>
                <h2 class="largeValue rm-m" style="color:rgb(27, 139, 221) !important;" [ngClass]="{
                  isAvailable: (contract['Settlements.Drawn'] | toNumber) == (contract['Amount.Invoice'] | toNumber)
                }">
                  {{
                (contract.stats
                  | FilterArrayMultipleValue: 'ID':approveStatus
                  | objectPropertyValue: 'Sum(Settlements.Paid)'
                  | toNumberArray
                  | sum) +
                (contract.stats
                  | FilterArrayMultipleValue: 'ID':approveStatus
                  | objectPropertyValue: 'Sum(Insurance.Contribution)'
                  | toNumberArray
                  | sum) | customCurrency
                }}
                </h2>
                <hr />

                <label class="small " style="margin-bottom:5px !important;">Available</label>
                <h2 class="largeValue rm-m">
                  <span *ngIf="contract['Settlements.NotYetDrawn']"
                    [ngClass]="(contract['Settlements.NotYetDrawn'] | toNumber) > 0 ? 'isAvailable' : 'noAvailable'">
                    {{ contract['Settlements.NotYetDrawn'] | customCurrency }}</span>
                  <span *ngIf="!contract['Settlements.NotYetDrawn']">...</span>
                </h2>
              </div>


              <div class="thrd-width">
                <div class="col-left">
                  <h3 class="sr-title">Requested</h3>

                  <label class="small " style="margin-bottom:5px !important;">Requested/Pending </label>
                  <h2 class="largeValue rm-m" [class.alertPendingPayment]="
                    (contract.stats
                      | FilterArrayMultipleValue: 'ID':requestStatus
                      | objectPropertyValue: 'Sum(Settlement.Amount)'
                      | toNumberArray
                      | sum) > (contract['Settlements.NotYetDrawn'] | toNumber)
                  ">
                    {{
                  contract.stats
                    | FilterArrayMultipleValue: 'ID':requestStatus
                    | objectPropertyValue: 'Sum(Settlement.Amount)'
                    | toNumberArray
                    | sum
                    | customCurrency
                  }}
                  </h2>

                  <hr />
                  <label class="small " style="margin-bottom:5px !important;">Scheduled </label>

                  <h2 class="largeValue rm-m">
                    {{
                  contract.stats
                    | FilterArrayMultipleValue: 'ID':approveStatus
                    | objectPropertyValue: 'Sum(Settlements.Unpaid)'
                    | toNumberArray
                    | sum
                    | customCurrency
                  }}
                  </h2>
                </div>
              </div>

              <div class="thrd-width">
                <div class="col-left">
                  <h3 class="sr-title">Approved/Declined</h3>

                  <label class="small " style="margin-bottom:5px !important;">Approved </label>
                  <h2 class="largeValue rm-m">
                    {{contract.stats
                    | FilterArrayMultipleValue: 'ID':approveStatus
                    | objectPropertyValue: 'Sum(Settlement.Amount)'
                    | toNumberArray
                    | sum | customCurrency
                  }}
                  </h2>



                  <hr />
                  <label class="small " style="margin-bottom:5px !important;">Declined </label>
                  <h2 class="largeValue rm-m" style="color:#d00e00 !important;">
                    {{
                  contract.stats
                    | FilterArrayMultipleValue: 'ID':declineStatus
                    | objectPropertyValue: 'Sum(Settlement.Amount)'
                    | toNumberArray
                    | sum
                    | customCurrency
                  }}
                  </h2>
                </div>
              </div>

            </div> -->


            <div class="full-width flex   ">

              <div class="full-width smr">
                <h3 class="sr-title ">
                  Total Settled</h3>

                <h2 class="largeValue rm-m" style="color:rgb(27, 139, 221) !important;" [ngClass]="{
                    isAvailable: (contract['Settlements.Drawn'] | toNumber) == (contract['Amount.Invoice'] | toNumber)
                  }">
                  {{
                  (contract.stats
                    | FilterArrayMultipleValue: 'ID':approveStatus
                    | objectPropertyValue: 'Sum(Settlements.Paid)'
                    | toNumberArray
                    | sum) +
                  (contract.stats
                    | FilterArrayMultipleValue: 'ID':approveStatus
                    | objectPropertyValue: 'Sum(Insurance.Contribution)'
                    | toNumberArray
                    | sum) | customCurrency
                  }}
                </h2>
                <hr>
              </div>

              <div class="full-width  smr">
                <h3 class="sr-title ">
                  Available</h3>

                <h2 class="largeValue rm-m">
                  <span *ngIf="contract['Settlements.NotYetDrawn']"
                    [ngClass]="(contract['Settlements.NotYetDrawn'] | toNumber) > 0 ? 'isAvailable' : 'noAvailable'">
                    {{ contract['Settlements.NotYetDrawn'] | customCurrency }}</span>
                  <span *ngIf="!contract['Settlements.NotYetDrawn']">...</span>
                </h2>
                <hr>
              </div>
              <div class="full-width  smr" *ngIf="settings.materialRequestActive ==true">
                <h3 class="sr-title ">
                  Material Advanced</h3>
                <h2 class="largeValue rm-m" *ngIf="contract && contract.materialRequest  ">
                  {{contract.materialRequest['Settlement.Amount'] | customCurrency  }}
                </h2>
                <h2 class="largeValue rm-m" *ngIf="contract && !contract.materialRequest  ">
                  {{0 | customCurrency }}
                </h2>

                <hr>
              </div>

              <div class="full-width  smr" *ngIf="(contract.stats
                    | FilterArrayMultipleValue: 'ID':approveStatus
                    | objectPropertyValue: 'Sum(Settlement.Amount)'
                    | toNumberArray
                    | sum)!=(   (contract.stats
                    | FilterArrayMultipleValue: 'ID':approveStatus
                    | objectPropertyValue: 'Sum(Settlements.Paid)'
                    | toNumberArray
                    | sum) +
                  (contract.stats
                    | FilterArrayMultipleValue: 'ID':approveStatus
                    | objectPropertyValue: 'Sum(Insurance.Contribution)'
                    | toNumberArray
                    | sum))">
                <h3 class="sr-title ">
                  Approved</h3>
                <h2 class="largeValue rm-m">
                  {{contract.stats
                    | FilterArrayMultipleValue: 'ID':approveStatus
                    | objectPropertyValue: 'Sum(Settlement.Amount)'
                    | toNumberArray
                    | sum | customCurrency
                  }}
                </h2>
                <hr>
              </div>




            </div>


            <div class="full-width flex  mb "
              *ngIf="contract && contract.settlementsStatistics && contract.settlementsStatistics.length>0">

              <div class="full-width">

                <h3 class="sr-title settlement-list-button-top" (click)="settlementListOverview(contract.ID,null)">
                  Settlements</h3>

                <div class="full-width flex">

                  <div class="full-width  smr" *ngFor="let s of contract.settlementsStatistics">

                    <label class="small " style="margin-bottom:5px !important;">{{s.label}}</label>
                    <h2 *ngIf="s.count>0" class="largeValue text-left rm-m settlement-list-button"
                      [ngStyle]="{ color: s.color }" (click)="settlementListOverview(contract.ID,s.code)">
                      <mat-icon class="{{s['icon']}} smr" [ngStyle]="{ color: s.color }"></mat-icon>
                      <span> {{s.count}}</span>
                    </h2>

                    <h2 *ngIf="s.count==0" class="largeValue  text-left rm-m" [ngStyle]="{ color: s.color }">
                      <mat-icon class="{{s['icon']}} smr" [ngStyle]="{ color: s.color }"></mat-icon>
                      {{s.count}}
                    </h2>

                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>

        <div class="row clearfix" *ngIf="isPromoterOrAdmin">
          <div class="information-container clearfix">





            <div class="full-width flex ">
              <div class="full-width mr">

                <div class="full-width smt">
                  <label class="small " style="margin-bottom:5px !important;">Creation date</label>
                  <p class="rm-m small">{{ contract['DateTimeCreated'] | customDateTime }}</p>
                </div>
                <div class="full-width smt">
                  <label class="small " style="margin-bottom:5px !important;">Recourse Release MSF </label>
                  <p class="rm-m small"
                    *ngIf="contract['Recourse_Release_MSF'] && contract['Recourse_Release_MSF']!='0'">
                    {{contract['Recourse_Release_MSF'] | customCurrency }}
                  </p>
                  <p class="rm-m small"
                    *ngIf="!contract['Recourse_Release_MSF'] ||  contract['Recourse_Release_MSF']=='0'">
                    ...
                  </p>
                </div>

                <div class="full-width  smt">
                  <label class="small " style="margin-bottom:5px !important;">Merchant Service Fee </label>
                  <p class="rm-m small"
                    *ngIf="contract['Merchant_Service_Fee']&& contract['Merchant_Service_Fee']!='0'">
                    {{contract['Merchant_Service_Fee'] | customCurrency }}
                  </p>
                  <p class="rm-m small"
                    *ngIf="!contract['Merchant_Service_Fee']||  contract['Merchant_Service_Fee']=='0'">
                    ...
                  </p>
                </div>


              </div>
              <div class="full-width mr ">

                <div class="full-width smt">
                  <label class="small " style="margin-bottom:5px !important;">Merchant Membership Fee </label>
                  <p class="rm-m small"
                    *ngIf="contract['Merchant_Membership_Fee']&& contract['Merchant_Membership_Fee']!='0'">
                    {{contract['Merchant_Membership_Fee'] | customCurrency }}
                  </p>
                  <p class="rm-m small"
                    *ngIf="!contract['Merchant_Membership_Fee']||  contract['Merchant_Membership_Fee']=='0'">
                    ...
                  </p>
                </div>

                <div class="full-width  smt">
                  <label class="small " style="margin-bottom:5px !important;">Merchant Risk Fee </label>
                  <p class="rm-m small" *ngIf="contract['Merchant_Risk_Fee']&& contract['Merchant_Risk_Fee']!='0'">
                    {{contract['Merchant_Risk_Fee'] | customCurrency }}
                  </p>
                  <p class="rm-m small" *ngIf="!contract['Merchant_Risk_Fee']||  contract['Merchant_Risk_Fee']=='0'">
                    ...
                  </p>
                </div>

                <div class="full-width  smt">
                  <label class="small " style="margin-bottom:5px !important;">Finance Contribution </label>
                  <p class="rm-m small"
                    *ngIf="contract['Credit_Charges_Merchant']&& contract['Credit_Charges_Merchant']!='0'">
                    {{contract['Credit_Charges_Merchant'] | customCurrency }}
                  </p>
                  <p class="rm-m small"
                    *ngIf="!contract['Credit_Charges_Merchant']||  contract['Credit_Charges_Merchant']=='0'">
                    ...
                  </p>
                </div>


              </div>
              <div class="full-width  mr ">
                <div class="full-width smt">
                  <label class="small " style="margin-bottom:5px !important;">Customer Fees Absorbed </label>
                  <p class="rm-m small"
                    *ngIf="contract['CustomerFeesAbsorbed']&& contract['CustomerFeesAbsorbed']!='0'">
                    {{contract['CustomerFeesAbsorbed'] | customCurrency }}
                  </p>
                  <p class="rm-m small"
                    *ngIf="!contract['CustomerFeesAbsorbed']||  contract['CustomerFeesAbsorbed']=='0'">
                    ...
                  </p>
                </div>

                <div class="full-width  smt">
                  <label class="small " style="margin-bottom:5px !important;">Claimed from Insurance </label>
                  <p class="rm-m small"
                    *ngIf="contract['Insurance_Contributions']&& contract['Insurance_Contributions']!='0'">
                    {{contract['Insurance_Contributions'] | customCurrency }}
                  </p>
                  <p class="rm-m small"
                    *ngIf="!contract['Insurance_Contributions']||  contract['Insurance_Contributions']=='0'">
                    ...
                  </p>
                </div>

                <div class="full-width  smt">
                  <label class="small " style="margin-bottom:5px !important;">Refunds</label>
                  <p class="rm-m small" *ngIf="contract['RefundsForUndrawn']&& contract['RefundsForUndrawn']!='0'">
                    {{contract['RefundsForUndrawn'] | customCurrency }}
                  </p>
                  <p class="rm-m small" *ngIf="!contract['RefundsForUndrawn']||  contract['RefundsForUndrawn']=='0'">
                    ...
                  </p>
                </div>


              </div>
              <div class="full-width  ">
                <div class="full-width smt">
                  <label class="small " style="margin-bottom:5px !important;">Borrower Risk Fee </label>
                  <p class="rm-m small" *ngIf="contract['Borrower_Risk_Fee']&& contract['Borrower_Risk_Fee']!='0'">
                    {{contract['Borrower_Risk_Fee'] | customCurrency }}
                  </p>
                  <p class="rm-m small" *ngIf="!contract['Borrower_Risk_Fee']||  contract['Borrower_Risk_Fee']=='0'">
                    ...
                  </p>
                </div>

                <div class="full-width  smt">
                  <label class="small " style="margin-bottom:5px !important;">Hold Back Fee </label>
                  <p class="rm-m small"
                    *ngIf="contract['Merchant_HoldBack_Fee']&& contract['Merchant_HoldBack_Fee']!='0'">
                    {{contract['Merchant_HoldBack_Fee'] | customCurrency }}
                  </p>
                  <p class="rm-m small"
                    *ngIf="!contract['Merchant_HoldBack_Fee']||  contract['Merchant_HoldBack_Fee']=='0'">
                    ...
                  </p>
                </div>
                <div class="full-width  smt">
                  <p class="rm-m ">
                    <strong>Total {{'Merchant' | translate}} fees: {{getTotalFee() | customCurrency}}</strong>
                  </p>

                </div>

              </div>
            </div>

          </div>
        </div>

        <div *ngIf="isPromoterOrAdmin == true && isShowFull == true" @labelAnimation>
          <div>
            <div class="row clearfix">
              <div class="qtr-width">
                <label class="small " style="margin-bottom:5px !important;">Financed</label>
                <p class="rm-mt bold-cust">{{ contract['Amount.Financed'] | customCurrency }}</p>
              </div>
              <div class="qtr-width">
                <label class="small " style="margin-bottom:5px !important;">Deposit</label>
                <p class="rm-mt bold-cust">{{ contract['Deposit'] | customCurrency }}</p>
              </div>
              <div class="qtr-width">
                <label class="small " style="margin-bottom:5px !important;">Original Loan</label>
                <p class="rm-mt bold-cust">{{ contract['Amount.Loan.Original'] | customCurrency }}</p>
              </div>
              <div class="qtr-width">
                <label class="small " style="margin-bottom:5px !important;">Current Loan</label>
                <p class="rm-mt bold-cust">{{ contract['Amount.Loan.Current'] | customCurrency }}</p>
              </div>
              <hr class="mb" />
            </div>
            <div class="row clearfix">
              <div class="qtr-width">
                <label class="small " style="margin-bottom:5px !important;">Original</label>
                <p class="rm-mt bold-cust">{{ contract['Amount.Original'] | customCurrency }}</p>
              </div>
              <div class="qtr-width">
                <label class="small " style="margin-bottom:5px !important;">Interest Rate</label>
                <p class="rm-mt bold-cust">{{ contract['InterestRate'] }}</p>
              </div>
              <div class="qtr-width">
                <label class="small " style="margin-bottom:5px !important;">Received</label>
                <p class="rm-mt bold-cust">{{ contract['Amount.Received'] | customCurrency }}</p>
              </div>
              <div class="qtr-width">
                <label class="small " style="margin-bottom:5px !important;">Waived</label>
                <p class="rm-mt bold-cust">{{ contract['Amount.Waived'] | customCurrency }}</p>
              </div>
              <hr class="mb" />
            </div>
            <div class="row clearfix">
              <div class="qtr-width">
                <label class="small " style="margin-bottom:5px !important;">Outstanding</label>
                <p class="rm-mt bold-cust">{{ contract['Amount.Outstanding'] | customCurrency }}</p>
              </div>
              <div class="qtr-width">
                <label class="small " style="margin-bottom:5px !important;">WrittenO ff</label>
                <p class="rm-mt bold-cust">{{ contract['Amount.WrittenOff'] | customCurrency }}</p>
              </div>
              <div class="qtr-width">
                <label class="small " style="margin-bottom:5px !important;">Customer Contribution</label>
                <p class="rm-mt bold-cust">{{ contract['Amount.Contribution'] | customCurrency }}</p>
              </div>
              <hr class="mb" />
            </div>
            <div class="row clearfix">
              <div class="qtr-width">
                <label class="small " style="margin-bottom:5px !important;">Product</label>
                <p class="rm-mt bold-cust">{{ contract['ProductName'] }}</p>
              </div>
              <div class="qtr-width">
                <label class="small " style="margin-bottom:5px !important;">Loan Purpose</label>
                <p class="rm-mt bold-cust">{{ contract['LoanPurpose'] }}</p>
              </div>
              <div class="qtr-width">
                <label class="small " style="margin-bottom:5px !important;">Status</label>
                <p class="rm-mt bold-cust">{{ contract['Status'] }}</p>
              </div>
              <div class="qtr-width">
                <label class="small " style="margin-bottom:5px !important;">Payment Frequency</label>
                <p class="rm-mt bold-cust">{{ contract['RepaymentFrequency.Adverb'] || '...' }}</p>
              </div>
              <hr class="mb" *ngIf="hideCapacity!=true" />
            </div>
            <div class="row clearfix" *ngIf="hideCapacity!=true">
              <div class="qtr-width">
                <label class="small " style="margin-bottom:5px !important;">Credit Check</label>
                <p class="rm-mt  bold-cust">{{ contract['CreditCheck.Status'] || '...' }}</p>
              </div>
              <div class="qtr-width">
                <label class="small " style="margin-bottom:5px !important;">Capacity Check</label>
                <p class="rm-mt bold-cust">{{ contract['CapacityCheck.Status'] || '...' }}</p>
              </div>
            </div>
          </div>
        </div>
      </mat-card>

      <mat-card class="card clearfix card-settlement-container" *ngIf="isSimple == false"
        [hidden]="settlementNotFound == true">
        <div class="colour-stripe"></div>
        <div class="row full-width clearfix">
          <app-settlement-contract-list [isMiniSettlementExpanded]="isMiniSettlementExpanded" [contractID]="contractID"
            [isContractApproved]="contract.ApprovedOrActive" [contractName]="contract['Customer.FullName']"
            [settlements]="settlements" (notFound)="setSettlementNotFound($event)">
          </app-settlement-contract-list>
        </div>
      </mat-card>

      <app-empty-list-message *ngIf="settlementNotFound == true && displayNoSettlement==true"
        [title]="'No Settlement Found'" [message]="'There is no Settlement to show for  this contract'">
      </app-empty-list-message>

    </ng-container>
  </div>
</div>

<div class="full-width text-center mt" *ngIf="isIntegrated == true">
  <button class="btn-large" (click)="backClicked()" mat-raised-button color="accent">
    <mat-icon>keyboard_arrow_left</mat-icon>
    Go Back
  </button>
</div>
