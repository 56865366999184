import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countStringIDs',
})
export class CountStringIdsPipe implements PipeTransform {
  transform(IDs: string, separator: string = '|'): any {
    if (IDs === '') {
      return 0;
    } else {
      return IDs.split(separator).length;
    }
  }
}
