<div class="full-width white-background clearfix">
  <div class="rel" *ngIf="listDB?.firstLoadEvent == false" @ngIfAnimation>
    <div class="row clearfix padded rel">
      <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
    </div>
  </div>

  <div [hidden]="!listDB || !listDB.data || listDB?.data?.length == 0">
    <div class="row clearfix">
      <div class="row flex ctl pull-right mr" @ngIfAnimation>
        <button
          class="row select-btn"
          (click)="selectAll()"
          mat-stroked-button
          color=""
          matTooltip="Select All"
        >
          <mat-icon>border_all</mat-icon>
        </button>
        <button
          class="row select-btn"
          (click)="selectPage()"
          mat-stroked-button
          color=""
          matTooltip="Select Page"
        >
          <mat-icon>border_outer</mat-icon>
        </button>
        <button
          class="animated"
          *ngIf="selectedIDs && selectedIDs.length > 0"
          [matMenuTriggerFor]="ActionBtnmenu"
          mat-stroked-button
          matTooltip="Options"
        >
          <mat-icon class="fas fa-cog"></mat-icon>  <span *ngIf="selectedIDs && selectedIDs.length>0"
            class="selected-number-item  accent-background">{{selectedIDs.length}}</span>
        </button>

        <mat-menu #ActionBtnmenu="matMenu">
          <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
            <mat-icon>select_all</mat-icon> Deselect
          </button>

          <button (click)="enableAppointmentLookup(selectedIDs, true)" mat-menu-item>
            <mat-icon>check_circle_outline</mat-icon>
            <span>Enable</span>
          </button>

          <button (click)="disableAppointmentLookup(selectedIDs, true)" mat-menu-item>
            <mat-icon>block</mat-icon>
            <span>Disable</span>
          </button>

          <button (click)="removeAppointmentLookup(selectedIDs, true)" mat-menu-item>
            <mat-icon>remove_circle</mat-icon>
            <span>Remove</span>
          </button>
        </mat-menu>
        <button class="pull-right ml" (click)="createNewAppointmentLookup()" mat-raised-button color="primary">
          <mat-icon>alarm_add</mat-icon> Create Appointment Lookup
        </button>
      </div>

      <h2 class="dataHeader rm-mt mr ml">
        {{ title }}
        <span class="badge" [endVal]="dataSource?.filteredData?.length" countUp></span>

        <!-- <p class="small" *ngIf="description">{{ description }}</p> -->
      </h2>
    </div>
    <div class="row clearfix mb searchArea primary-gradient-img">
      <div class="row clearfix flex">
        <div class="full-width flex animated fadeInDown">
          <mat-icon class="mt">search</mat-icon>

          <mat-form-field class="ml full-width" appearance="outline">
            <mat-label>Label, Description</mat-label>
            <input
              class="clearfix thrd-width"
              (input)="setFilter($event.target.value, ['Label', 'Description'])"
              name="LabelDescription"
              placeholder=""
              matInput
            />
          </mat-form-field>

          <mat-form-field class="qtr-width" appearance="outline">
            <mat-label>Reason for visiting</mat-label>
            <mat-select
              class=""
              (selectionChange)="setFilter($event.value, 'Category')"
              name="Category"
              placeholder="Category"
            >
              <mat-option [value]="null">
                All
              </mat-option>

              <mat-option
                *ngFor="let a of dataSource?.filteredData | objectPropertyValue: 'Category' | unique | orderBy"
                [value]="a"
              >
                {{ a }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="qtr-width" appearance="outline">
            <mat-label>Code</mat-label>
            <mat-select class="" (selectionChange)="setFilter($event.value, 'Code')" name="Code" placeholder="Code">
              <mat-option [value]="null">
                All
              </mat-option>

              <mat-option
                *ngFor="let a of dataSource?.filteredData | objectPropertyValue: 'Code' | unique | orderBy"
                [value]="a"
              >
                {{ a }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="qtr-width" appearance="outline">
            <mat-label>Is Active</mat-label>
            <mat-select
              class=""
              (selectionChange)="setFilter($event.value, 'Is_Active')"
              name="Is_Active"
              placeholder="Is_Active"
            >
              <mat-option [value]="null">
                All
              </mat-option>

              <mat-option
                *ngFor="let a of dataSource?.filteredData | objectPropertyValue: 'Is_Active' | unique | orderBy"
                [value]="a"
              >
                {{ a === '1' ? 'Active' : 'Inactive' }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <!-- TABLE -->
    <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
      <ng-container cdkColumnDef="selectCheckBox" sticky>
        <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
        <mat-cell *cdkCellDef="let row">
          <div class="margin-btm pull-left">
            <mat-checkbox [checked]="isSelected(row['ID'])" (change)="select(row['ID'], $event.checked)" color="accent">
            </mat-checkbox>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="Label" sticky>
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Label</mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="editAppointmentLookup(row['ID'])">
          {{ row.Label }}
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="Description">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Description</mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="editAppointmentLookup(row['ID'])">
          {{ row.Description }}
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="Category">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Reason for visiting</mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="editAppointmentLookup(row['ID'])">
          {{ row.Category }}
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="Code">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Code</mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="editAppointmentLookup(row['ID'])">
          {{ row.Code }}
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="Buffers_Pre">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Buffers Pre</mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="editAppointmentLookup(row['ID'])">
          {{ row.Buffers_Pre === '0' || row.Buffers_Pre === '' ? 'No Buffer' : row.Buffers_Pre + ' mins' }}
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="Buffers_Post">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Buffers Post</mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="editAppointmentLookup(row['ID'])">
          {{ row.Buffers_Post === '0' || row.Buffers_Post === '' ? 'No Buffer' : row.Buffers_Post + ' mins' }}
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="DefaultDuration">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Duration</mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="editAppointmentLookup(row['ID'])">
          {{ row.DefaultDuration === '0' || row.DefaultDuration === '' ? 'None' : row.DefaultDuration + ' mins' }}
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="DateTimeCreated">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Updated at</mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="editAppointmentLookup(row['ID'])">
          <span class="list-label" [style.background]="util.daysPastColor(row['DateTimeCreated'])">
            {{ row.DateTimeCreated | TimeLabelPast }} ago</span
          >
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="Is_Active">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Active</mat-header-cell>
        <mat-cell *cdkCellDef="let row">
          <span
            class="list-label smaller"
            *ngIf="row['Is_Active'] == '1'"
            matTooltip="Active"
            style="background-color: rgb(90, 186, 71)"
          >
            <mat-icon>check</mat-icon>
          </span>
          <span
            class="list-label smaller"
            *ngIf="row['Is_Active'] != '1'"
            matTooltip="Inactive"
            style="background-color: rgb(239, 71, 39)"
          >
            <mat-icon>close</mat-icon>
          </span>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="subType">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> </mat-header-cell>
        <mat-cell *cdkCellDef="let row">
          <button (click)="subType(row['Code'])" mat-icon-button matTooltip="Show more details" color="primary">
            <mat-icon>list</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="Actions">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
        <mat-cell *cdkCellDef="let row">
          <div style="float: right">
            <button [matMenuTriggerFor]="menu" mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <div>
                <button *ngIf="row['ID']" (click)="editAppointmentLookup(row['ID'])" mat-menu-item>
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>

                <button *ngIf="row['Category'] == 'AppointmentTypes'" (click)="subType(row['Code'])" mat-menu-item>
                  <mat-icon>list</mat-icon>
                  <span>View Sub types</span>
                </button>

                <button *ngIf="row.Is_Active === '1'" (click)="disableAppointmentLookup(row)" mat-menu-item>
                  <mat-icon>block</mat-icon>
                  <span>Disable</span>
                </button>

                <button *ngIf="row.Is_Active === '0'" (click)="enableAppointmentLookup(row)" mat-menu-item>
                  <mat-icon>check_circle_outline</mat-icon>
                  <span>Enable</span>
                </button>

                <button (click)="removeAppointmentLookup(row)" mat-menu-item>
                  <mat-icon>remove_circle</mat-icon>
                  <span>Remove Appointment</span>
                </button>
              </div>
            </mat-menu>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      #paginator
      [length]="dataSource?.filteredData?.length"
      [pageIndex]="0"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
    >
    </mat-paginator>
  </div>

  <div class="text-center full-width" *ngIf="listDB?.data?.length == 0 && listDB.firstLoadEvent != false">
    <app-empty-list-message
      class="full-width mt mb"
      [actionIcon]="'add'"
      [isAction]="true"
      [actionLabel]="'Add more details'"
      (actionEvent)="createNewAppointmentLookup()"
    ></app-empty-list-message>
  </div>
</div>
