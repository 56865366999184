import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-list-view',
  templateUrl: './customer-list-view.component.html',
  styleUrls: ['./customer-list-view.component.css'],
})
export class CustomerListViewComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
