import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { TwoFactorModalComponent } from '../../../core/helper/two-factor-modal/two-factor-modal.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { ProductViewModalComponent } from '../../product/product-view-modal/product-view-modal.component';
import { ProductService } from '../../product/shared/product.service';
import { InvitationService } from '../shared/services/invitation.service';

@Component({
  selector: 'app-cold-application',
  templateUrl: './cold-application.component.html',
  styleUrls: ['./cold-application.component.css'],
  animations: [
    trigger('ngIfEnter', [
      transition('void => *', [
        query('.onEnter', style({ opacity: 0 }), { optional: true }),
        query(
          '.onEnter',
          stagger('100ms', [
            animate(
              '0.9s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.onEnter', style({ opacity: 1 }), { optional: true }),
        query(
          '.onEnter',
          stagger('100ms', [
            animate(
              '0.9s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.anim', style({ opacity: 0 }), { optional: true }),
        query(
          '.anim',
          stagger('100ms', [
            animate(
              '0.5s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0, overflow: 'hidden' }),
                style({ opacity: 1, offset: 1.0, height: '*', overflow: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.anim', style({ opacity: 1 }), { optional: true }),
        query(
          '.anim',
          stagger('100ms', [
            animate(
              '0.5s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*', overflow: '*' }),
                style({ opacity: 0, offset: 1.0, height: 0, overflow: 'hidden' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ColdApplicationComponent implements OnInit {
  @Input()
  productID;

  // testing product ID
  // productID = "D47AB4884996436D8922D8E0B53C1196";

  @Input()
  applicantObject;

  @Input()
  amount;
  // 5900

  isInputProduct = false;
  showProductlist = false;
  isShowAmountPicker = false;
  isShowProductPicker = false;
  product;
  selectedProduct;
  isSelectedProduct = false;

  estimateObject;
  frequency;
  timeLabel;
  genders;
  titles;
  user = {
    firstname: '',
    title: '',
    lastname: '',
    middlename: '',
    gender: '',
    mobile: '',
    email: '',
  };
  isMobileValid = false;
  isEmailValid = false;
  types;
  serviceType;
  isPaymentPlan = false;
  invitationID;

  modifiedMobile;

  step = 0;

  @ViewChild(WizardComponent, { static: true })
  public wizard: WizardComponent;

  constructor(
    private productService: ProductService,
    private lookupService: LookupService,
    private invitationService: InvitationService,
    private activeRouter: ActivatedRoute
  ) {}

  ngOnInit() {
    this.scrollView();

    this.activeRouter.params.subscribe((params) => {
      if (params['productID']) {
        this.productID = params['productID'];
      }
    });

    if (this.productID) {
      this.isInputProduct = true;
      this.productService.getProdDetails(this.productID).subscribe((res) => {
        this.product = res;
        this.productID = this.product['ID'];
      });
    }

    this.lookupService.getLookup('CodeLookup', 'Gender').subscribe((data) => {
      this.genders = data;
    });
    this.lookupService.getLookup('SimpleLookup', 'Salutation').subscribe((data) => {
      this.titles = data;
    });
    this.lookupService.getLookup('SimpleLookup', 'LoanPurpose').subscribe((data) => {
      this.types = data;
    });
  }

  showPaymentCard() {}

  getAmount(e) {
    this.amount = e;
    this.showProductlist = true;
  }

  // when product picker is interacted with
  getProduct(e) {
    this.selectedProduct = e;
    this.productID = this.selectedProduct['ID'];

    this.isSelectedProduct = true;
    // scroll back to top
    this.scrollView();
    // hide picker again
    // this.isShowProductPicker = false;
    // this.wizard.goToNextStep();
  }

  chooseAnotherProduct() {
    this.isSelectedProduct = false;
    // scroll back to top
    this.scrollView();
  }

  getEstimation(e) {
    this.estimateObject = e;
    this.isPaymentPlan = this.estimateObject['isPaymentPlan'];
    if (this.estimateObject['frequency'] == '1') {
      this.frequency = 'weeks';
      this.timeLabel = 'Week';
    } else if (this.estimateObject['frequency'] == '2') {
      this.frequency = 'fortnights';
      this.timeLabel = 'Fortnight';
    } else if (this.estimateObject['frequency'] == '4') {
      this.frequency = 'months';
      this.timeLabel = 'Month';
    }
  }

  showAmountPicker() {
    this.isShowAmountPicker = true;
  }

  showProductPicker() {
    this.isShowProductPicker = true;
  }

  openProductViewModal() {
    this.setNextStep();
    const ref = RootAppComponent.dialog.open(ProductViewModalComponent, {
      data: this.productID,
      width: '650px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  getMobile(e) {
    this.user['mobile'] = e;
  }

  getEmail(e) {
    this.user['email'] = e;
  }

  validateMobileEvent(e) {
    this.isMobileValid = e;
  }

  validateEmail(e) {
    this.isEmailValid = e;
  }

  openTwoFactorModal() {
    const ref = RootAppComponent.dialog.open(TwoFactorModalComponent, {
      data: this.user['mobile'],
      width: '650px',
    });
    ref.componentInstance.codeVerfied.subscribe((res) => {
      if (res == true) {
        NotifyAppComponent.displayToast('success', 'Verfied successfully', 'You are verfied by us, please continue.');
        this.wizard.goToNextStep();
        this.setNextStep();
        ref.close();
      }
    });
  }

  // step counter

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  // scroll to top on interaction
  scrollView() {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  }

  // interactions and scroll back to top if step backwards
  setPreviousStep() {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    this.prevStep();
    // extra actions here
  }

  // interactions and scroll back to top if step forwards
  setNextStep() {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    this.nextStep();
    // extra actions here
  }

  proceed() {
    const payload = {
      productID: this.productID,
      frequency: Number(this.estimateObject['frequency']),
      duration: Number(this.estimateObject['duration']),
      amount: Number(this.amount),

      firstName: this.user['firstname'],
      middleName: this.user['middlename'],
      lastName: this.user['lastname'],
      gender: this.user['gender'],
      email: this.user['email'],
      mobile: this.user['mobile'],
      title: this.user['title'],
      serviceType: this.serviceType,
    };

    this.invitationService.createColdApplication(payload).subscribe((res) => {
      if (res) {
        this.invitationID = res['ID'];
        NotifyAppComponent.displayToast(
          'success',
          'Successfully operation',
          'You will be redirecting to a page to finish you application '
        );

        if (Settings.global['isWakandaIntegrated'] && Settings.global['isWakandaIntegrated'] === true) {
          if (this.isPaymentPlan) {
            let ppLink = Settings.global['wakandaPPLink'];
            ppLink = ppLink.replace('_id_', res['WakandaID']);
            window.open(`${Settings.global['publicSiteFrontendLink']}/thank-you-pp/customer-card?link=${encodeURIComponent(ppLink)}`, '_parent').focus();
          } else {
            let laLink = Settings.global['wakandaLALink'];
            laLink = laLink.replace('_id_', res['WakandaID']);
            window.open(`${Settings.global['publicSiteFrontendLink']}/thank-you-la/customer-card?link=${encodeURIComponent(laLink)}`, '_parent').focus();
          }
        } else {
          if (this.isPaymentPlan) {
            let ppLink = Settings.global['wakandaPPLink'];
            ppLink = ppLink.replace('_id_', res['WakandaID']);
            window.open(`${Settings.global['publicSiteFrontendLink']}/thank-you-pp/customer-card?link=${encodeURIComponent(ppLink)}`, '_parent').focus();
          } else {
            let laLink = Settings.global['wakandaLALink'];
            laLink = laLink.replace('_id_', res['WakandaID']);
            window.open(`${Settings.global['publicSiteFrontendLink']}/thank-you-la/customer-card?link=${encodeURIComponent(laLink)}`, '_parent').focus();
          }
        }
      }
    });
  }
}
