import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { DentistService } from '../../dentist/shared/dentist.service';
import { ExistingPatientService } from '../../existing-patient/shared/existing-patient.service';
import { MerchantService } from '../../merchant/shared/merchant.service';

export class MessageInformation {
  messageLength: number;
  charactersPerSegment: number;
  numberOfSegments: number;
  encodingType?: 'GSM7' | 'Unicode';
  message: string;
}

@Injectable()
export class MessageService {
  private settingsUrl: string = environment.nodeUrl + '/version';

  charactersPerGsm7Segment = 160;
  charactersPerUnicodeSegment = 70;

  constructor(
    private http: HttpClient,
    private existingPatientService: ExistingPatientService,
    private dentistService: DentistService,
    private merchantService: MerchantService
  ) {}

  getMarketingStaticTemplateList(payload, isAdminOrPromoter = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (isAdminOrPromoter == true) {
      return this.http
        .get(`${environment.nodeUrl}/template/global`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(`${environment.nodeUrl}/template/`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getCamaignList(payload, isAdminOrPromoter = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isAdminOrPromoter == true) {
      return this.http
        .get(environment.nodeUrl + '/marketing/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/marketing', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMessageSMSList(payload, sessionType: String, hidden = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (hidden == true) {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.http
          .get(environment.nodeUrl + '/message/external/sms-hidden/global', options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.http
          .get(environment.nodeUrl + '/message/external/sms-hidden', options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.http
          .get(environment.nodeUrl + '/message/external/sms/global', options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.http
          .get(environment.nodeUrl + '/message/external/sms', options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  getMessageList(payload, sessionType: String): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/message/external/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/message/external', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/message/external/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMessageUserList(payload, sessionType: String): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .post(environment.nodeUrl + '/message/external/user/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/message/external/user', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMessageUserStatisticsList(payload, sessionType: String): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/message/external/user-statistics/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/message/external/user-statistics', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMessageUserStatisticsListHidden(payload, sessionType: String): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/message/external/user-statistics-hidden/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/message/external/user-statistics-hidden', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  editRead(payload, sessionType: String): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .put(environment.nodeUrl + '/message/external/read/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/message/external/read', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  editReadHidden(payload, sessionType: String): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .put(environment.nodeUrl + '/message/external/read-hidden/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/message/external/read-hidden', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  editMessage(id, payload, sessionType: String): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .put(environment.nodeUrl + '/message/external/global/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/message/external/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  getMessageDetails(ID, payload = {}, sessionType: String, isHidden = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    if (isHidden === true) {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.http
          .get(environment.nodeUrl + '/message/external/hidden/global/' + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }

      if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
        return this.http
          .get(environment.nodeUrl + '/message/external/hidden/' + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }

      if (sessionType == 'customer') {
        return this.http
          .get(environment.nodeUrl + '/message/external/hidden/customer/' + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.http
          .get(environment.nodeUrl + '/message/external/global/' + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }

      if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
        return this.http
          .get(environment.nodeUrl + '/message/external/' + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }

      if (sessionType == 'customer') {
        return this.http
          .get(environment.nodeUrl + '/message/external/customer/' + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  getMessageDetailsHidden(ID, payload = {}, sessionType: String): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/message/external/hidden/global/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }

    if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/message/external/hidden/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }

    if (sessionType == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/message/external/hidden/customer/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  promoterSendMessage(payload): Observable<any> {
    return this.http
      .post(environment.nodeUrl + '/message/sms/global', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getMessageInformation(message: string, prefilledMessage?: boolean): MessageInformation {
    const sanitizedMessage = message ? message.replace(/{{.*?}}/g, '') : '';

    let messageLength = sanitizedMessage.length;
    let charactersPerSegment = this.charactersPerGsm7Segment;
    let encodingType: 'GSM7' | 'Unicode' = 'GSM7';
    const numberOfSegments = Math.ceil(messageLength / charactersPerSegment);

    if (this.containsUnicodeCharacter(sanitizedMessage)) {
      charactersPerSegment = this.charactersPerUnicodeSegment;
      encodingType = 'Unicode';
    }

    // GSM7 Extension Characters (counted as two characters)
    if (sanitizedMessage.includes('|')) {
      const count = sanitizedMessage.split('|').length - 1;

      for (let index = 0; index < count; index++) {
        messageLength = messageLength - 1 + 2;
      }
    }

    if (sanitizedMessage.includes('^')) {
      const count = sanitizedMessage.split('^').length - 1;

      for (let index = 0; index < count; index++) {
        messageLength = messageLength - 1 + 2;
      }
    }

    if (sanitizedMessage.includes('€')) {
      const count = sanitizedMessage.split('€').length - 1;

      for (let index = 0; index < count; index++) {
        messageLength = messageLength - 1 + 2;
      }
    }

    if (sanitizedMessage.includes('{')) {
      const count = sanitizedMessage.split('{').length - 1;

      for (let index = 0; index < count; index++) {
        messageLength = messageLength - 1 + 2;
      }
    }

    if (sanitizedMessage.includes('}')) {
      const count = sanitizedMessage.split('}').length - 1;

      for (let index = 0; index < count; index++) {
        messageLength = messageLength - 1 + 2;
      }
    }

    if (sanitizedMessage.includes('[')) {
      const count = sanitizedMessage.split('[').length - 1;

      for (let index = 0; index < count; index++) {
        messageLength = messageLength - 1 + 2;
      }
    }

    if (sanitizedMessage.includes(']')) {
      const count = sanitizedMessage.split(']').length - 1;

      for (let index = 0; index < count; index++) {
        messageLength = messageLength - 1 + 2;
      }
    }

    if (sanitizedMessage.includes('~')) {
      const count = sanitizedMessage.split('~').length - 1;

      for (let index = 0; index < count; index++) {
        messageLength = messageLength - 1 + 2;
      }
    }

    return {
      messageLength,
      charactersPerSegment,
      numberOfSegments,
      encodingType,
      message,
    };
  }

  getPreviewMessage(message: string, patient: string, merchantID: string, sessionType: string) {
    if (patient && message) {
      const patientTemplate = [];
      const dentistTemplate = [];
      const practiceTemplate = [];

      const templateDataArray = [];

      const regexMatchArray = message.match(/{{.*?}}/g);

      if (regexMatchArray) {
        regexMatchArray.forEach((match) => {
          const sanitizedMatch = match.replace('{{', '').replace('}}', '');
          const sanitizedMatchArray = sanitizedMatch.split(/(?=[A-Z])/);
          const personalizationType = sanitizedMatchArray[0];
          sanitizedMatchArray.shift();
          const finalMatch = sanitizedMatchArray.join('');

          switch (personalizationType) {
            case 'Patient':
              patientTemplate.push(finalMatch);
              break;

            case 'Dentist':
              dentistTemplate.push(finalMatch);
              break;

            case 'Practice':
              practiceTemplate.push(finalMatch);
              break;
          }
        });

        if (patientTemplate.length > 0) {
          templateDataArray.push(
            this.existingPatientService.getExistingPatientFullDetails(patient['existingPatientID'])
          );
        }

        if (dentistTemplate.length > 0) {
          templateDataArray.push(this.dentistService.getContactDetails(merchantID, {}, sessionType));
        }

        if (practiceTemplate.length > 0) {
          templateDataArray.push(this.merchantService.getMerchantDetails(merchantID, {}, sessionType));
        }

        return forkJoin(templateDataArray).pipe(
          mergeMap(([customerDetails, dentistContactDetails, practiceMerchantDetails]) => {
            patientTemplate.forEach((patientVariable) => {
              if (customerDetails[patientVariable]) {
                message = message.replace(`{{Patient${patientVariable}}}`, customerDetails[patientVariable]);
              }
            });

            dentistTemplate.forEach((dentistVariable) => {
              if (dentistContactDetails[dentistVariable]) {
                message = message.replace(`{{Dentist${dentistVariable}}}`, dentistContactDetails[dentistVariable]);
              }
            });

            practiceTemplate.forEach((practiceVariable) => {
              if (practiceMerchantDetails[practiceVariable]) {
                message = message.replace(`{{Practice${practiceVariable}}}`, practiceMerchantDetails[practiceVariable]);
              }
            });

            return of(this.getMessageInformation(message, true));
          })
        );
      } else {
        return of(this.getMessageInformation(message));
      }
    } else {
      return of(this.getMessageInformation(message));
    }
  }

  containsUnicodeCharacter(message) {
    for (let index = 0, messageLength = message.length; index < messageLength; index++) {
      if (message.charCodeAt(index) > 255) {
        return true;
      }
    }

    return false;
  }

  getProfilePicStreamLink(ID, tableName, LastModified = null) {
    if (tableName === 'Contact') {
      if (
        ID &&
        LastModified &&
        LastModified != '' &&
        LastModified != undefined &&
        LastModified != 'null' &&
        LastModified != 'undefined '
      ) {
        return `${environment.nodeUrl}/contact/profile-picture-stream-cached/${LastModified}/` + ID;
      } else if (ID) {
        return `${environment.nodeUrl}/contact/profile-picture-stream/` + ID;
      }
      return null;
    } else if (tableName === 'Merchant' || tableName === 'Promoter') {
      if (
        ID &&
        LastModified &&
        LastModified != '' &&
        LastModified != undefined &&
        LastModified != 'null' &&
        LastModified != 'undefined '
      ) {
        return `${environment.nodeUrl}/merchant/practice-picture-stream-cached/${LastModified}/` + ID;
      } else if (ID) {
        return `${environment.nodeUrl}/merchant/practice-picture-stream/` + ID;
      }
      return null;
    } else {
      if (
        ID &&
        LastModified &&
        LastModified != '' &&
        LastModified != undefined &&
        LastModified != 'null' &&
        LastModified != 'undefined '
      ) {
        return `${environment.nodeUrl}/customer-prospect/profile-picture-stream-cached/${LastModified}/` + ID;
      } else if (ID) {
        return `${environment.nodeUrl}/customer-prospect/profile-picture-stream/` + ID;
      }
      return null;
    }
  }

  addCoordinate(ID, body, tableName) {
    if (tableName === 'Contact') {
      return this.http
        .put(environment.nodeUrl + '/contact/coordinate/' + ID, body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (tableName === 'Merchant') {
      return this.http
        .put(environment.nodeUrl + '/merchant/coordinate/' + ID, body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (tableName === 'Customer') {
      return this.http
        .put(environment.nodeUrl + '/customer/coordinate/' + ID, body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (tableName === 'Prospect') {
      return this.http
        .put(environment.nodeUrl + '/prospect/coordinate/' + ID, body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/prospect/coordinate/' + ID, body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getCardDetails(ID, tableName, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (tableName === 'Contact') {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.http.get(environment.nodeUrl + '/contact/global/' + ID, options).pipe(
          map((_res: HttpResponse<any>) => {
            const res = _res;
            if (res && res['data']) {
              res['data']['TableName'] = tableName;
              res['data']['tableName'] = tableName;
            }

            return res['data'];
          })
        );
      } else {
        return this.http.get(environment.nodeUrl + '/contact/' + ID, options).pipe(
          map((_res: HttpResponse<any>) => {
            const res = _res;
            if (res && res['data']) {
              res['data']['TableName'] = tableName;
              res['data']['tableName'] = tableName;
            }

            return res['data'];
          })
        );
      }
    } else if (tableName === 'Merchant' || tableName === 'Promoter') {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.http.get(environment.nodeUrl + '/merchant/' + ID, options).pipe(
          map((_res: HttpResponse<any>) => {
            const res = _res;
            if (res && res['data']) {
              res['data']['TableName'] = tableName;
              res['data']['tableName'] = tableName;
            }

            return res['data'];
          })
        );
      } else {
        return this.http.get(environment.nodeUrl + '/merchant/public/' + ID, options).pipe(
          map((_res: HttpResponse<any>) => {
            const res = _res;
            if (res && res['data']) {
              res['data']['TableName'] = tableName;
              res['data']['tableName'] = tableName;
            }

            return res['data'];
          })
        );
      }
    } else {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.http.get(environment.nodeUrl + '/customer-prospect/global/' + ID, options).pipe(
          map((_res: HttpResponse<any>) => {
            const res = _res;
            if (res && res['data']) {
              res['data']['TableName'] = 'CustomersAndProspects';
              res['data']['tableName'] = 'CustomersAndProspects';
            }

            return res['data'];
          })
        );
      } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
        return this.http.get(environment.nodeUrl + '/customer-prospect/' + ID, options).pipe(
          map((_res: HttpResponse<any>) => {
            const res = _res;
            if (res && res['data']) {
              res['data']['TableName'] = 'CustomersAndProspects';
              res['data']['tableName'] = 'CustomersAndProspects';
            }

            return res['data'];
          })
        );
      } else {
        return this.http.get(environment.nodeUrl + '/customer-prospect/public/' + ID, options).pipe(
          map((_res: HttpResponse<any>) => {
            const res = _res;
            if (res && res['data']) {
              res['data']['TableName'] = tableName;
              res['data']['TableName'] = 'CustomersAndProspects';
              res['data']['tableName'] = 'CustomersAndProspects';
            }

            return res['data'];
          })
        );
      }
    }
  }

  getCardDetailsPublicHidden(ID, tableName, payload = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (tableName === 'Contact') {
      return this.http.get(environment.nodeUrl + '/contact/hidden/public/' + ID, options).pipe(
        map((_res: HttpResponse<any>) => {
          const res = _res;
          if (res && res['data']) {
            res['data']['TableName'] = tableName;
            res['data']['tableName'] = tableName;
          }

          return res['data'];
        })
      );
    } else if (tableName === 'Merchant' || tableName === 'Promoter') {
      return this.http.get(environment.nodeUrl + '/merchant/hidden/public/' + ID, options).pipe(
        map((_res: HttpResponse<any>) => {
          const res = _res;
          if (res && res['data']) {
            res['data']['TableName'] = tableName;
            res['data']['tableName'] = tableName;
          }

          return res['data'];
        })
      );
    } else {
      return this.http.get(environment.nodeUrl + '/customer-prospect/hidden/public/' + ID, options).pipe(
        map((_res: HttpResponse<any>) => {
          const res = _res;
          if (res && res['data']) {
            res['data']['TableName'] = tableName;
            res['data']['TableName'] = 'CustomersAndProspects';
            res['data']['tableName'] = 'CustomersAndProspects';
          }

          return res['data'];
        })
      );
    }
  }

  sendSMS(payload, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .post(environment.nodeUrl + '/message/sms/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/message/sms', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  createDocumentLink(payload, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .post(environment.nodeUrl + '/document-link/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/document-link', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getDocumentLink(ID, payload = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/document-link/' + ID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editMessageTemplate(id, payload, sessionType) {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      const urlPatient = environment.nodeUrl + '/merchant-personal-message/global/' + id;
      return this.http.put(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      const urlPatient = environment.nodeUrl + '/merchant-personal-message/' + id;
      return this.http.put(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  createMessageTemplate(payload, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .post(environment.nodeUrl + '/merchant-personal-message/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/merchant-personal-message', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  getMessageTemplate(ID, payload = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/merchant-personal-message/' + ID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getTagsList(body = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }
    options = {
      params,
    };
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/tag-merchant/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/tag-merchant', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/tag-merchant', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getFilterList(payload, isAdminOrPromoter = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (isAdminOrPromoter == true) {
      return this.http
        .get(`${environment.nodeUrl}/group-filters/global`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(`${environment.nodeUrl}/group-filters/`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getCustomerProspectMergedList(body = {}, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .post(environment.nodeUrl + '/customer-prospect/merged-list/global', body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/customer-prospect/merged-list', body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getCustomerProspectMergedListHidden(body = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }
    options = {
      params,
    };
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .post(environment.nodeUrl + '/customer-prospect/merged-list-hidden/global', body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/customer-prospect/merged-list-hidden', body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  createMessageGroup(body = {}, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .post(environment.nodeUrl + '/message-group/global', body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/message-group', body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  createMessageGroupDraft(body = {}, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .post(environment.nodeUrl + '/message-group/draft/global', body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/message-group/draft', body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMessageGroupList(body = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }
    options = {
      params,
    };
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/message-group/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/message-group', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  getMessageGroupDetails(ID, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/message-group/global/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/message-group/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMessageGroupLocalList(body = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }
    options = {
      params,
    };
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/message-group/local-group/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/message-group/local-group', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  getMessageGroupLocalListHidden(body = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }
    options = {
      params,
    };
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/message-group/local-group-hidden/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/message-group/local-group-hidden', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  getMessageGroupLocalDetails(ID, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/message-group/local-group/global/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/message-group/local-group/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMessageLocalDetails(ID, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/message-group/local/global/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/message-group/local/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  modifyMessageGroup(ID, payload = {}, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .put(environment.nodeUrl + '/message-group/global/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/message-group/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  modifyMessageLocal(ID, payload = {}, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .put(environment.nodeUrl + '/message-group/local/global/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/message-group/local/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  modifyMessageLocalGroup(payload = {}, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .put(environment.nodeUrl + '/message-group/local/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/message-group/local', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMessageGroupStatusColors(): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/message-group/status-colors', {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getMessageGroupmessageStatusColors(): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/message-group/message-status-colors', {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  convertMessage(payload): Observable<any> {
    return this.http
      .post(environment.nodeUrl + '/message/external/convert', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getMessageGroupStatistics(ID, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/message-group/statistics/global/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/message-group/statistics/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMessageGroupStatisticsHidden(ID, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/message-group/statistics-hidden/global/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/message-group/statistics-hidden/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  modifyMessageGroupGroup(payload, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .put(`${environment.nodeUrl}/message-group/group/global`, payload).pipe(
          map((res: Response) => res['data']));
    }
    else {
      return this.http
        .put(`${environment.nodeUrl}/message-group/group`, payload).pipe(
          map((res: Response) => res['data']));
    }
  }

  deleteMessageGroup(id, sessionType) {
    let url = "message-group";
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = "message-group/global";
    }
    return this.http
      .delete(`${environment.nodeUrl}/${url}/${id}`, {}).pipe(
        map((res: HttpResponse<any>) => {
          return res['data']
        }));
  }


  deleteMessageGroupGroup(ids, sessionType): Observable<any> {
    let _ids;
    if (Array.isArray(ids) == true) {
      _ids = ids.join(',')
    }
    else if (typeof ids == 'string') {
      _ids = ids;
    }
    if (_ids) {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.http
          .delete(environment.nodeUrl + '/message-group/group/global/' + _ids).pipe(
            map((res: HttpResponse<any>) => res['data']));
      }
      else {
        return this.http
          .delete(environment.nodeUrl + '/message-group/group/' + _ids).pipe(
            map((res: HttpResponse<any>) => res['data']));
      }
    }
  }
  modifyCustomerProspect(ID = null, payload, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .put(environment.nodeUrl + '/customer-prospect/global/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .put(environment.nodeUrl + '/customer-prospect/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer') {
      return this.http
        .put(environment.nodeUrl + '/customer-prospect/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/customer-prospect/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  getCampaignStatistics(payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/message-group/campaign-statistics/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/message-group/campaign-statistics', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }


  messageGroupHardRefresh(body = {}, sessionType): Observable<any> {

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .put(environment.nodeUrl + '/message-group/hard-refresh/global', body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/message-group/hard-refresh', body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
}
