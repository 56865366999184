import { Component, EventEmitter, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { MessageService } from '../shared/message.service';
@Component({
  selector: 'app-sms-preview',
  templateUrl: './sms-preview.component.html',
  styleUrls: ['./sms-preview.component.css'],
})
export class SmsPreviewComponent implements OnInit {
  @Input()
  message;
  @Input()
  messageFilter;
  @Input()
  segments;
  @Input()
  segmentLength = 160;
  @Input()
  cardID;

  sessionType;
  @Input()
  card;
  @Input()
  tableName;
  util = new UtilsClass();
  @Input()
  timeNow = moment().format('HH:MM');
  close = new EventEmitter();

  @Input()
  isModal = true;
  constructor(
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    @Optional()
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data && data.segmentLength) {
      this.segmentLength = data.segmentLength;
    }
    if (data && data.message) {
      this.message = data.message;
    }
    if (data && data.cardID) {
      this.cardID = data.cardID;
    }
    if (data && data.tableName) {
      this.tableName = data.tableName;
    }
    if (data && data.card) {
      this.card = data.card;
    }
    if (data && data.segmentLength) {
      this.segmentLength = data.segmentLength;
    }
  }
  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((r) => {
      this.sessionType = r;

      if (this.cardID && this.cardID != '00000000000000000000') {
        this.messageService
          .getCardDetails(this.cardID, this.tableName, { fields: 'ID,LastModified,FirstName' }, this.sessionType)
          .subscribe((res) => {
            if (res && res.ID) {
              this.card = res;
            }
          });
      }
    });

    if (!this.tableName && this.card && this.card['Card_TableName']) {
      this.tableName = this.card['Card_TableName'];
    }

  }



  chunkSubstr(str, size) {
    const numChunks = Math.ceil(str.length / size);
    const chunks = new Array(numChunks);

    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
      chunks[i] = str.substr(o, size);
    }

    return chunks;
  }

  URLify(_string) {
    if (_string && this.util.isContentHtml(_string) != true) {
      let string = _string;
      let urls = string.match(/(https?:\/\/[^\s]+)/g) || [];

      const localURLs = string.match(/(http?:\/\/[^\s]+)/g);

      if (localURLs && localURLs.length > 0) {
        urls = urls.concat(localURLs);
      }
      if (urls) {
        urls = _.uniq(urls);
        urls.forEach((url) => {
          const title = this.util.getLinkTitle(url);

          if (title) {
            string = string
              .split(url)
              .join(
                '<a target="_blank" style="color:white !important ;margin-right:10px !important; " title="' +
                  title +
                  '" class="inner-link" href="' +
                  url +
                  '"> ' +
                  title +
                  ' </a>'
              );
          } else {
            string = string
              .split(url)
              .join(
                '<a target="_blank" style="color:white !important ;margin-right:10px !important; " class="inner-link" href="' +
                  url +
                  '"> ' +
                  url +
                  ' </a>'
              );
          }
        });
      }

      string = string.replace(/\n/g, '<br>');
      string = string.replace(/   /g, '<br>');
      return string;
    } else if (_string && this.util.isContentHtml(_string) == true) {
      let string = _string;

      string = string.replace(/\n/g, '<br>');
      string = string.replace(/   /g, '<br>');
      return string;
    }

    return _string;
  }

  closeEvent() {
    this.close.emit(true);
  }
}
