<div class="wiki side-menu-container" flex>
  <!-- Top bar - logo -->
  <div class="logo-container">
    <div class="site_logo"></div>
    <h1 class="mat-h1 no-p brandName" [routerLink]="['/wiki/']" matRipple>
      <mat-icon class="left-menu-icon">menu_book</mat-icon> {{ WikiTitle }}
    </h1>
  </div>

  <nav id="side-menu" #sidenav1>
    <app-wiki-group-view-list class="mb"></app-wiki-group-view-list>

    <div
      class="home-link mt"
      *ngIf="sessionType == 'admin' || sessionType == 'promoter' || sessionType == 'merchant'"
      matRipple
      routerLink="/merchant/"
    >
      <mat-icon class="home-icon">home</mat-icon>
      <h4 class="rm header-link-class">Return to Dashboard</h4>
    </div>

    <div class="home-link mt" *ngIf="sessionType == 'funder'" matRipple routerLink="/funder/">
      <mat-icon class="home-icon">home</mat-icon>
      <h4 class="rm header-link-class">Return to Dashboard</h4>
    </div>

    <div class="home-link mt" *ngIf="sessionType == 'supplier'" matRipple routerLink="/supplier/">
      <mat-icon class="home-icon">home</mat-icon>
      <h4 class="rm header-link-class">Return to Dashboard</h4>
    </div>

    <div class="home-link mt" *ngIf="sessionType == 'customer'" matRipple routerLink="/customer-app/">
      <mat-icon class="home-icon">home</mat-icon>
      <h4 class="rm header-link-class">Return to your Portal</h4>
    </div>

    <div class="home-link mt" *ngIf="sessionType == 'guest'" (click)="login()" matRipple>
      <mat-icon class="home-icon">lock_open</mat-icon>
      <h4 class="rm header-link-class">Login</h4>
    </div>
  </nav>

  <div class="sidebar-background primary-gradient-img"></div>
</div>
