import { Component, Input } from '@angular/core';

@Component({
  selector: 'ipv-list-label',
  templateUrl: './list-label.component.html',
  styleUrls: ['./list-label.component.css'],
})
export class ListLabelComponent {
  @Input() color = '#6fb662';
}
