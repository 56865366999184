import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { ContractService } from '../../contract/shared/contract.service';
import { DentistService } from '../../dentist/shared/dentist.service';
import { InvitationService } from '../../invitation/shared/services/invitation.service';
import { MedicalHistoryService } from '../../medical-history/shared/medical-history.service';
import { TreatmentService } from '../../treatment/shared/treatment.service';
import { MarketingService } from '../../marketing/shared/marketing.service';

@Component({
  selector: 'app-marketing-filter-create-property',
  templateUrl: './marketing-filter-create-property.component.html',
  styleUrls: ['./marketing-filter-create-property.component.css'],
})
export class MarketingFilterCreatePropertyComponent implements OnInit {
  @Input()
  merchantID;

  @Input()
  section;

  @Input()
  inputProperty;

  @Input()
  isEdit;

  @Output()
  getFilter = new EventEmitter();

  property;

  properties = [];

  filterValue;
  filterMinValue;
  filterMaxValue;

  allergyCategories = [];
  allergyTypeList = [];
  illnessList = [];
  allergyCategoryCode;
  allergyType;
  illnessType;

  isPromoterOrAdmin = false;

  contacts = [];
  invitationChoice = [];
  contractStatus = [];
  quotationStatus = [];
  treatmentProducts = [];
  treatmentProductDistributors = [];
  treatmentProductManufacturers = [];
  treatmentBrands = [];
  treatmentBrandDistributors = [];
  treatmentBrandManufacturers = [];
  treatmentTypes = [];
  treatmentCategories = [];

  constructor(
    private lookupService: LookupService,
    private marketingService: MarketingService,
    private authenticationService: AuthenticationService,
    private medicalHistoryService: MedicalHistoryService,
    private dentistService: DentistService,
    private invitationService: InvitationService,
    private contractService: ContractService,
    private treatmentService: TreatmentService
  ) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      if (this.section) {
        let typeCode;
        if (this.section['Code'] == 'CRD') {
          typeCode = 'CRD';
        } else if (this.section['Code'] == 'AL' || this.section['Code'] == 'IL' || this.section['Code'] == 'MH') {
          typeCode = 'MED';
        } else if (
          this.section['Code'] == 'INV' ||
          this.section['Code'] == 'CNT' ||
          this.section['Code'] == 'PRD' ||
          this.section['Code'] == 'BRN' ||
          this.section['Code'] == 'TYP' ||
          this.section['Code'] == 'CAT'
        ) {
          typeCode = 'TRT';
        }
        const payload = {
          sectionCode: this.section['Code'],
          typeCode,
          orderBy: 'PropertyLabel',
        };

        this.marketingService.getFilterSections(payload).subscribe((res) => {
          if (res && res.length > 0) {
            this.properties = res;
          }

          if (this.isEdit == true) {
            if (this.inputProperty['Properties']) {
              this.property = this.properties.find(
                (x) => x['PropertyCode'] == this.inputProperty['Properties'][0]['Code']
              );

              this.setUpTreatmentProperty();

              if (this.inputProperty['Properties'][0]['Value']) {
                this.filterValue = this.inputProperty['Properties'][0]['Value'];
              }

              if (this.inputProperty['Properties'][0]['ValueMin']) {
                this.filterMinValue = this.inputProperty['Properties'][0]['ValueMin'];
              }

              if (this.inputProperty['Properties'][0]['ValueMax']) {
                this.filterMaxValue = this.inputProperty['Properties'][0]['ValueMax'];
              }
            }

            if (this.inputProperty['Category'] && this.section['Code'] == 'AL') {
              const p = {
                orderBy: 'DisplayLabel',
              };
              this.medicalHistoryService.getAllergyCategoryList(p).subscribe((res) => {
                if (res) {
                  this.allergyCategories = res;
                }

                this.allergyCategoryCode = this.allergyCategories.find(
                  (x) => x['Category'] == this.inputProperty['Category']['Code']
                );

                this.lookupService.getLookup('CodeLookup', this.allergyCategoryCode['Category']).subscribe((res) => {
                  this.allergyTypeList = res;

                  this.allergyType = this.allergyTypeList.find((x) => x['Code'] == this.inputProperty['Type']['Code']);

                  this.sendResult();
                });
              });
            } else if (this.inputProperty['Type'] && this.section['Code'] == 'IL') {
              this.lookupService.getLookup('CodeLookup', 'Illness').subscribe((res) => {
                if (res) {
                  this.illnessList = res;
                }

                this.illnessType = this.illnessList.find((x) => x['Code'] == this.inputProperty['Type']['Code']);

                this.sendResult();
              });
            }

            this.sendResult();
          } else {
            if (this.section['Code'] == 'AL') {
              const p = {
                orderBy: 'DisplayLabel',
              };
              this.medicalHistoryService.getAllergyCategoryList(p).subscribe((res) => {
                if (res) {
                  this.allergyCategories = res;
                }
              });

              const _property = this.properties.find((x) => x['PropertyCode'] == 'Is_Ongoing');

              this.getProperty(_property);
            } else if (this.section['Code'] == 'IL') {
              this.lookupService.getLookup('CodeLookup', 'Illness').subscribe((res) => {
                if (res) {
                  this.illnessList = res;
                }
              });

              const _property = this.properties.find((x) => x['PropertyCode'] == 'Is_Ongoing');

              this.getProperty(_property);
            }
          }
        });
      }
    });
  }

  setUpTreatmentProperty() {
    if (this.property['PropertyCode'] == 'Merchant_Contact_FK') {
      const __p = {
        fields: 'ID,CalculatedName',
        merchantID: this.merchantID,
      };

      this.dentistService.getList(__p, this.isPromoterOrAdmin).subscribe((res) => {
        if (res && res.length > 0) {
          this.contacts = res;
        }
      });
    } else if (this.property['PropertyCode'] == 'Customer_Choice') {
      const __p = {
        merchantID: this.merchantID,
        fields: 'PatientChoice.Code,PatientChoice.Label',
      };
      this.invitationService.getInvitations(__p, this.isPromoterOrAdmin).subscribe((res) => {
        if (res.data && res.data.length > 0) {
          this.invitationChoice = res.data.reduce((x, y) => {
            if (
              y['PatientChoice.Code'] != '' &&
              x.map((x) => x['PatientChoice.Code']).indexOf(y['PatientChoice.Code']) == -1
            ) {
              x.push(y);
            }
            return x;
          }, []);
        }
      });
    } else if (this.property['PropertyCode'] == 'Contract_Status') {
      const p = {
        merchantID: this.merchantID,
        fields: 'Status.Code,Status',
      };
      this.contractService.getContractList(p, this.isPromoterOrAdmin).subscribe((res) => {
        if (res && res.length > 0) {
          this.contractStatus = res.reduce((x, y) => {
            if (x.map((x) => x['Status.Code']).indexOf(y['Status.Code']) == -1) {
              x.push(y);
            }
            return x;
          }, []);
        }
      });
    } else if (this.property['PropertyCode'] == 'Quotation_Status') {
      const p = {
        merchantID: this.merchantID,
        fields: 'Quotation.Status.Code,Quotation.Status.Label',
      };
      this.contractService.getContractList(p, this.isPromoterOrAdmin).subscribe((res) => {
        if (res && res.length > 0) {
          this.quotationStatus = res.reduce((x, y) => {
            if (x.map((x) => x['Quotation.Status.Code']).indexOf(y['Quotation.Status.Code']) == -1) {
              x.push(y);
            }
            return x;
          }, []);
        }
      });
    } else if (this.property['PropertyCode'] == 'Service_Product_FK') {
      const __p = {
        merchantID: this.merchantID,
        fields: 'TreatmentProduct_key,Product.Label',
      };
      this.treatmentService.getTreatmentEPList(__p, this.isPromoterOrAdmin).subscribe((res) => {
        if (res && res.length > 0) {
          this.treatmentProducts = res.reduce((x, y) => {
            if (
              y['Product.Label'] != '' &&
              y['Product.Label'] != 'N/A' &&
              x.map((x) => x['TreatmentProduct_key']).indexOf(y['TreatmentProduct_key']) == -1
            ) {
              x.push(y);
            }
            return x;
          }, []);
        }
      });
    } else if (this.property['PropertyCode'] == 'Manufacturer_NFK' && this.section['Code'] == 'PRD') {
      const __p = {
        merchantID: this.merchantID,
        fields: 'Manufacturer_Key.Effective,Manufacturer.Name.Effective',
      };
      this.treatmentService.getTreatmentProductList(__p, this.isPromoterOrAdmin).subscribe((res) => {
        if (res && res.length > 0) {
          this.treatmentProductManufacturers = res.reduce((x, y) => {
            if (
              y['Manufacturer_Key.Effective'] != '' &&
              y['Manufacturer.Name.Effective'] != '' &&
              x.map((x) => x['Manufacturer_Key.Effective']).indexOf(y['Manufacturer_Key.Effective']) == -1
            ) {
              x.push(y);
            }
            return x;
          }, []);
        }
      });
    } else if (this.property['PropertyCode'] == 'Distributor_NFK' && this.section['Code'] == 'PRD') {
      const __p = {
        merchantID: this.merchantID,
        fields: 'Distributor_Key.Effective,Distributor.Name.Effective',
      };
      this.treatmentService.getTreatmentProductList(__p, this.isPromoterOrAdmin).subscribe((res) => {
        if (res && res.length > 0) {
          this.treatmentProductDistributors = res.reduce((x, y) => {
            if (
              y['Distributor_Key.Effective'] != '' &&
              y['Distributor.Name.Effective'] != '' &&
              x.map((x) => x['Distributor_Key.Effective']).indexOf(y['Distributor_Key.Effective']) == -1
            ) {
              x.push(y);
            }
            return x;
          }, []);
        }
      });
    } else if (this.property['PropertyCode'] == 'Brand_FK') {
      const __p = {
        merchantID: this.merchantID,
        fields: 'TreatmentBrand_key,TreatmentBrand.Label',
      };
      this.treatmentService.getTreatmentEPList(__p, this.isPromoterOrAdmin).subscribe((res) => {
        if (res && res.length > 0) {
          this.treatmentBrands = res.reduce((x, y) => {
            if (
              y['TreatmentBrand.Label'] != '' &&
              y['TreatmentBrand.Label'] != 'N/A' &&
              x.map((x) => x['TreatmentBrand_key']).indexOf(y['TreatmentBrand_key']) == -1
            ) {
              x.push(y);
            }
            return x;
          }, []);
        }
      });
    } else if (this.property['PropertyCode'] == 'Manufacturer_NFK' && this.section['Code'] == 'BRN') {
      const __p = {
        merchantID: this.merchantID,
        fields: 'Manufacturer_Key,Manufacturer.Name',
      };
      this.treatmentService.getTreatmentBrandList(__p, this.isPromoterOrAdmin).subscribe((res) => {
        if (res && res.length > 0) {
          this.treatmentBrandManufacturers = res.reduce((x, y) => {
            if (
              y['Manufacturer_Key'] != '' &&
              y['Manufacturer.Name'] != '' &&
              x.map((x) => x['Manufacturer_Key']).indexOf(y['Manufacturer_Key']) == -1
            ) {
              x.push(y);
            }
            return x;
          }, []);
        }
      });
    } else if (this.property['PropertyCode'] == 'Distributor_NFK' && this.section['Code'] == 'BRN') {
      const __p = {
        merchantID: this.merchantID,
        fields: 'Distributor_Key,Distributor.Name',
      };
      this.treatmentService.getTreatmentBrandList(__p, this.isPromoterOrAdmin).subscribe((res) => {
        if (res && res.length > 0) {
          this.treatmentBrandDistributors = res.reduce((x, y) => {
            if (
              y['Distributor_Key'] != '' &&
              y['Distributor.Name'] != '' &&
              x.map((x) => x['Distributor_Key']).indexOf(y['Distributor_Key']) == -1
            ) {
              x.push(y);
            }
            return x;
          }, []);
        }
      });
    } else if (this.property['PropertyCode'] == 'Type_FK') {
      const __p = {
        merchantID: this.merchantID,
        fields: 'TreatmentType_key,TreatmentType.Label',
      };
      this.treatmentService.getTreatmentEPList(__p, this.isPromoterOrAdmin).subscribe((res) => {
        if (res && res.length > 0) {
          this.treatmentTypes = res.reduce((x, y) => {
            if (
              y['TreatmentType.Label'] != '' &&
              y['TreatmentType.Label'] != 'N/A' &&
              x.map((x) => x['TreatmentType_key']).indexOf(y['TreatmentType_key']) == -1
            ) {
              x.push(y);
            }
            return x;
          }, []);
        }
      });
    } else if (this.property['PropertyCode'] == 'Category_FK') {
      const __p = {
        merchantID: this.merchantID,
        fields: 'TreatmentCategory_key,TreatmentCategory.Label',
      };
      this.treatmentService.getTreatmentEPList(__p, this.isPromoterOrAdmin).subscribe((res) => {
        if (res && res.length > 0) {
          this.treatmentCategories = res.reduce((x, y) => {
            if (
              y['TreatmentCategory.Label'] != '' &&
              y['TreatmentCategory.Label'] != 'N/A' &&
              x.map((x) => x['TreatmentCategory_key']).indexOf(y['TreatmentCategory_key']) == -1
            ) {
              x.push(y);
            }
            return x;
          }, []);
        }
      });
    }
  }

  getAllergies(allergyCategory) {
    this.allergyCategoryCode = allergyCategory;
    this.allergyType = null;
    if (allergyCategory) {
      this.lookupService.getLookup('CodeLookup', allergyCategory['Category']).subscribe((res) => {
        this.allergyTypeList = res;

        if (this.allergyTypeList.length == 0) {
          this.sendResult();
        }
      });
    }
  }

  getAllergyType(allergyType) {
    this.allergyType = allergyType;

    this.sendResult();
  }

  getIllnessType(illnessType) {
    this.illnessType = illnessType;

    this.sendResult();
  }

  getProperty(property) {
    this.property = property;
    this.filterValue = null;
    this.filterMaxValue = null;
    this.filterMinValue = null;

    if (this.property) {
      this.setUpTreatmentProperty();

      if (this.property['EConnectType'] == 'B') {
        this.filterValue = true;
      } else if (this.property['EConnectType'] == 'L' && this.section['Code'] == 'MH') {
        this.filterMinValue = 1;
      } else {
        this.filterValue = null;
      }

      this.sendResult();
    }
  }

  getValue(value) {
    this.filterValue = value;
    this.sendResult();
  }

  getMinValue(value) {
    this.filterMinValue = value;
    this.sendResult();
  }

  getMaxValue(value) {
    this.filterMaxValue = value;
    this.sendResult();
  }

  sendResult() {
    const filter = {
      filterSection: null,
      filterProperty: null,
      filterValue: null,
    };

    if (this.section && this.section['Code'] == 'AL') {
      if (this.allergyCategoryCode) {
        filter['allergyCategory'] = this.allergyCategoryCode;
      }

      if (this.allergyType) {
        filter['allergyType'] = this.allergyType;
      }
    } else if (this.section && this.section['Code'] == 'IL') {
      if (this.illnessType) {
        filter['illnessType'] = this.illnessType;
      }
    }

    if (this.section) {
      filter.filterSection = this.section;
    }

    if (this.property) {
      filter.filterProperty = this.property;
    }

    if (this.filterValue) {
      filter.filterValue = this.filterValue;
    }

    if (this.filterMinValue) {
      filter['filterMinValue'] = this.filterMinValue;
    }

    if (this.filterMaxValue) {
      filter['filterMaxValue'] = this.filterMaxValue;
    }

    this.getFilter.emit(filter);
  }
}
