import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-simple-time-out',
  templateUrl: './simple-time-out.component.html',
  styleUrls: ['./simple-time-out.component.css'],
})
export class SimpleTimeOutComponent implements OnInit {
  consumerID;

  constructor(private location: Location, private router: Router, private activeRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['consumerID']) {
        this.consumerID = params['consumerID'];
      }
    });
  }

  login() {
    if (this.consumerID) {
      this.router.navigate(['/login', this.consumerID]);
    } else {
      this.router.navigate(['/login']);
    }
  }
}
