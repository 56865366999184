import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from '../shared/helper.service';

@Component({
  selector: 'app-simple-page-standalone',
  templateUrl: './simple-page-standalone.component.html',
  styleUrls: ['./simple-page-standalone.component.css'],
})
export class SimplePageStandaloneComponent implements OnInit {
  type;
  content = {};
  simplePage = {};

  constructor(private helperService: HelperService, private router: Router, private activeRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (!params['type']) {
        this.router.navigate(['/404']);
      } else {
        this.type = params['type'];
        this.helperService.getSimplePage(this.type).subscribe((res) => {
          this.simplePage = res;
        });
      }
    });
  }
}
