/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product-invoice-form.modal.ts.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./components/retail-product-invoice-form/retail-product-invoice-form.component.ngfactory";
import * as i3 from "./components/retail-product-invoice-form/retail-product-invoice-form.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../../shared/services/product-invoice.service";
import * as i6 from "../../../../../core/app-state/app-state.service";
import * as i7 from "./components/dental-product-invoice-form/dental-product-invoice-form.component.ngfactory";
import * as i8 from "./components/dental-product-invoice-form/dental-product-invoice-form.component";
import * as i9 from "../../../../../shared/components/title-modal/title-modal.component.ngfactory";
import * as i10 from "../../../../../shared/components/title-modal/title-modal.component";
import * as i11 from "@angular/common";
import * as i12 from "./product-invoice-form.modal.ts.component";
var styles_InvoiceProductFormModalComponent = [i0.styles];
var RenderType_InvoiceProductFormModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InvoiceProductFormModalComponent, data: {} });
export { RenderType_InvoiceProductFormModalComponent as RenderType_InvoiceProductFormModalComponent };
function View_InvoiceProductFormModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-retail-product-invoice-form", [], null, null, null, i2.View_RetailProductInvoiceFormComponent_0, i2.RenderType_RetailProductInvoiceFormComponent)), i1.ɵdid(1, 245760, null, 0, i3.RetailProductInvoiceFormComponent, [i4.MatDialogRef, i5.ProductInvoiceService, i6.AppStateService], { existingProductInvoice: [0, "existingProductInvoice"], formMode: [1, "formMode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.productInvoice; var currVal_1 = _co.formMode; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_InvoiceProductFormModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-retail-product-invoice-form", [], null, null, null, i2.View_RetailProductInvoiceFormComponent_0, i2.RenderType_RetailProductInvoiceFormComponent)), i1.ɵdid(1, 245760, null, 0, i3.RetailProductInvoiceFormComponent, [i4.MatDialogRef, i5.ProductInvoiceService, i6.AppStateService], { existingProductInvoice: [0, "existingProductInvoice"], formMode: [1, "formMode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.productInvoice; var currVal_1 = _co.formMode; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_InvoiceProductFormModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-dental-product-invoice-form", [], null, null, null, i7.View_DentalProductInvoiceFormComponent_0, i7.RenderType_DentalProductInvoiceFormComponent)), i1.ɵdid(1, 114688, null, 0, i8.DentalProductInvoiceFormComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_InvoiceProductFormModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "ipv-title-modal", [], null, null, null, i9.View_TitleModalComponent_0, i9.RenderType_TitleModalComponent)), i1.ɵdid(1, 114688, null, 0, i10.TitleModalComponent, [i4.MAT_DIALOG_DATA], { icon: [0, "icon"], classIcon: [1, "classIcon"], title: [2, "title"], hideAllDefaultButtons: [3, "hideAllDefaultButtons"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 8, "div", [["content", ""], ["style", "padding-top: 10px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, null, null, null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i11.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceProductFormModalComponent_1)), i1.ɵdid(6, 278528, null, 0, i11.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i11.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceProductFormModalComponent_2)), i1.ɵdid(8, 278528, null, 0, i11.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i11.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceProductFormModalComponent_3)), i1.ɵdid(10, 278528, null, 0, i11.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i11.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = null; var currVal_1 = null; var currVal_2 = _co.title; var currVal_3 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.industryType; _ck(_v, 4, 0, currVal_4); var currVal_5 = "retail"; _ck(_v, 6, 0, currVal_5); var currVal_6 = "breeze"; _ck(_v, 8, 0, currVal_6); var currVal_7 = "dental"; _ck(_v, 10, 0, currVal_7); }, null); }
export function View_InvoiceProductFormModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-product-invoice-form", [["class", "modal ts"]], null, null, null, View_InvoiceProductFormModalComponent_0, RenderType_InvoiceProductFormModalComponent)), i1.ɵdid(1, 49152, null, 0, i12.InvoiceProductFormModalComponent, [i4.MAT_DIALOG_DATA], null, null)], null, null); }
var InvoiceProductFormModalComponentNgFactory = i1.ɵccf("ipv-product-invoice-form.modal.ts", i12.InvoiceProductFormModalComponent, View_InvoiceProductFormModalComponent_Host_0, {}, {}, []);
export { InvoiceProductFormModalComponentNgFactory as InvoiceProductFormModalComponentNgFactory };
