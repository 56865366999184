<div class="rel" *ngIf="listDB && listDB?.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="!listDB || (listDB && !listDB.data) || (listDB && listDB.data && listDB.data.length == 0)">
  <div class="row clearfix">
    <h1 class="dataHeader rm-m pull-left">
      {{ title }}
      <span class="badge" [endVal]="dataSource?.filteredData?.length" countUp></span>
    </h1>

    <div class="pull-right rel buttonrow">
      <button class="clearfix pull-right" (click)="createNew()" mat-raised-button color="accent">
        <mat-icon>add</mat-icon> New Landing Page
      </button>
    </div>

    <!--
    <mat-button-toggle-group class="btn-group pull-right rm-m  mr selectType" [(ngModel)]="typeFilter"
      (ngModelChange)="setFilter(typeFilter ,'IsDefault')">

      <mat-button-toggle [value]="null" class="">
        All
      </mat-button-toggle>

      <mat-button-toggle [value]="'0'" class="">
        <mat-icon class="fas fa-user"></mat-icon>
        {{ "KEYWORD.practice" | translate | titlecase}}


      </mat-button-toggle>

      <mat-button-toggle [value]="'1'" class="">
        <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
        {{"BRANDING.Brand Name" | translate}}
      </mat-button-toggle>

    </mat-button-toggle-group> -->

    <div class="pull-right selection-items" @ngIfAnimation>
      <button class="animated mr" (click)="selectAll()" mat-stroked-button color="" matTooltip="Select All">
        <mat-icon>border_all</mat-icon>
      </button>
      <button class="animated mr" (click)="selectPage()" mat-stroked-button color="" matTooltip="Select Page">
        <mat-icon>border_outer</mat-icon>
      </button>

      <button class="animated mr" *ngIf="selectedIDs && selectedIDs.length > 0" [matMenuTriggerFor]="ActionBtnmenu"
              mat-stroked-button matTooltip="Options">
        <mat-icon class="fas fa-cog"></mat-icon>  <span *ngIf="selectedIDs && selectedIDs.length>0"
          class="selected-number-item  accent-background">{{selectedIDs.length}}</span>
      </button>
      <mat-menu #ActionBtnmenu="matMenu">
        <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
          <mat-icon>select_all</mat-icon> Deselect
        </button>

        <hr/>

        <button class="row select-btn" *ngIf="selectedIDs && selectedIDs.length > 0" (click)="enableGroup()"
                mat-menu-item color="">
          <mat-icon>check_circle</mat-icon>
          Enable
        </button>

        <button class="row select-btn" *ngIf="selectedIDs && selectedIDs.length > 0" (click)="disableGroup()"
                mat-menu-item color="">
          <mat-icon>cancel</mat-icon>
          Disable
        </button>

        <button class="row select-btn" *ngIf="selectedIDs && selectedIDs.length > 0" (click)="removeGroup()"
                mat-menu-item color="">
          <mat-icon>delete</mat-icon>
          Remove
        </button>
      </mat-menu>
    </div>
  </div>

  <div class="row clearfix">
    <mat-card class="list-card">
      <div class="row clearfix mb searchArea primary-gradient-img">
        <div class="row clearfix flex">
          <div class="full-width flex animated fadeInDown">
            <mat-icon class="mt mr">search</mat-icon>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Filter by title,Label,Description...etc</mat-label>
              <input class="clearfix" (input)="setFilter($event.target.value, 'fullName')" name="tag" matInput/>
            </mat-form-field>

            <mat-form-field class="white half-width" *ngIf="merchantTags.length > 0" appearance="outline">
              <mat-label>Tags</mat-label>
              <mat-select class="" (selectionChange)="setFilter($event.value, 'Tags')" name="Tag" placeholder="Tag"
                          multiple>
                <mat-option *ngFor="let a of merchantTags | orderBy" [value]="a">
                  {{ a }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="half-width" appearance="outline">
              <mat-label>Active</mat-label>

              <mat-select class="margin-btm" (selectionChange)="setFilter($event.value, 'IsActive')" name="IsDefault"
                          placeholder="Active">
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option [value]="'1'"> Active</mat-option>

                <mat-option [value]="'1'"> Inactive</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="half-width" appearance="outline">
              <mat-label>Type</mat-label>

              <mat-select class="margin-btm" (selectionChange)="setFilter($event.value, 'Type.Code')"
                          name="ITypesDefault" placeholder="Type">
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option *ngFor="let type1 of types" [value]="type1.Code">
                  <mat-icon class="{{ type1.Icon }} smr"></mat-icon>
                  {{ type1.Label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="half-width" appearance="outline">
              <mat-label>Public</mat-label>

              <mat-select class="margin-btm" (selectionChange)="setFilter($event.value, 'IsPublic')" name="Public"
                          placeholder="Public">
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option [value]="'1'"> Public</mat-option>

                <mat-option [value]="'0'"> Not Public</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="half-width" appearance="outline">
              <mat-label>Expired</mat-label>

              <mat-select class="margin-btm" (selectionChange)="setFilter($event.value, 'isExpired')" name="Exipired"
                          placeholder="Exipired">
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option [value]="true"> Expired</mat-option>

                <mat-option [value]="false"> Not Expired</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- Table -->
      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <ng-container cdkColumnDef="selectCheckBox" sticky>
          <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div class="margin-btm pull-left">
              <mat-checkbox [checked]="isSelected(row['ID'])" (change)="select(row['ID'], $event.checked)"
                            color="accent">
              </mat-checkbox>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Theme" sticky>
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> </mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
            <landing-page-header-image [headerImage]="row['Header_Image']" [hideZoomButton]="true">
            </landing-page-header-image>

            <div class="theme-container" *ngIf="row['Theme']"
                 [attr.style]="'background:' + themeObject[row['Theme']] + ' !important' | safeStyle"></div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Label" sticky>
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Label</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])" matTooltip="{{ row['Label'] }}">
            <h3>{{ row['Label'] }}</h3>
            <span class="value chip-new ml pull-right" *ngIf="row['isNew'] == true">NEW</span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Title">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Title</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
            {{ row['Title'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="ExpireAt">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Expire At</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
            <span class="list-label" *ngIf="row['Date.Expire'] == '0000-00-00' || row['Date.Expire'] == '2200-01-01'"
                  [style.background]="'rgb(90, 186, 71)'">
              Never
            </span>
            <span class="list-label" *ngIf="row.isExpired == true" [style.background]="'rgb(239, 71, 39)'">
              Expired
            </span>

            <span class="list-label"
              *ngIf="row.isExpired != true && row['Date.Expire'] != '0000-00-00' && row['Date.Expire'] != '2200-01-01'"
                  [style.background]="'#818181'" matTooltip="Expires At {{ row['Date.Expire'] | customDate }}">{{
              row['Date.Expire'] | timeLeft: null:'years,months,days,hours':true:true }}</span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Visitors">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Visitors</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
            <span class="text-center"> {{ row['Visitors'] || 0 }}</span>
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Documents">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Documents</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
            <span class="text-center" *ngIf="row['DocumentFKs']"></span> {{ row['DocumentFKs'].length }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Description">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Description</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
            {{ row['Description'] }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Tags">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Tags</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
            <div class="offer-tags" *ngIf="row && row['Tags']">
              <mat-chip-list>
                <mat-chip *ngFor="let tag of row['Tags'] | orderBy" [ngClass]="{ 'default-chip': indexOfTag(tag) }">
                  {{ tag }}
                </mat-chip>
              </mat-chip-list>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Date">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Date Created</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])"
                    matTooltip="{{ row['DateTimeCreated'] | customDateTime }}">
            <span class="list-label"> {{ row['DateTimeCreated'] | TimeLabelPast }} ago </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="active">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Active</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
            <span class="list-label smaller" *ngIf="row['IsActive'] == '1'" matTooltip="Active"
                  style="background-color: rgb(90, 186, 71)">
              <mat-icon>check</mat-icon>
            </span>
            <span class="list-label smaller" *ngIf="row['IsActive'] != '1'" matTooltip="Inactive"
                  style="background-color: rgb(239, 71, 39)">
              <mat-icon>close</mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="type">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Type</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
            {{ row['Type.Label'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="finance">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Financed</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
            <span class="list-label smaller" *ngIf="row['Is_Finance'] == '1'" matTooltip="Financed Landing Page"
                  style="background-color: rgb(90, 186, 71)">
              <mat-icon>check</mat-icon>
            </span>
            <span class="list-label smaller" *ngIf="row['Is_Finance'] != '1'"
                  matTooltip="Landing Page with no Finance option" style="background-color: rgb(239, 71, 39)">
              <mat-icon>close</mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Amount">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Amount </mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
            <h3 *ngIf="row['Treatment_Amount'] != '0'">{{ row['Treatment_Amount'] | customCurrency }}</h3>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Public">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Public</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
            <span class="list-label smaller" *ngIf="row['IsPublic'] == '1'" matTooltip="Public"
                  style="background-color: rgb(90, 186, 71)">
              <mat-icon>check</mat-icon>
            </span>
            <span class="list-label smaller" *ngIf="row['IsPublic'] != '1'" matTooltip="Not Public"
                  style="background-color: rgb(239, 71, 39)">
              <mat-icon>close</mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Default">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>
            {{ "BRANDING.Brand Name" | translate | titlecase }}
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
            <span class="list-label smaller" *ngIf="row['IsDefault'] == '1'" matTooltip="Default"
                  style="background-color: rgb(90, 186, 71)">
              <mat-icon>check</mat-icon>
            </span>
            <span class="list-label smaller" *ngIf="row['IsDefault'] != '1'" matTooltip="Not Default"
                  style="background-color: rgb(239, 71, 39)">
              <mat-icon>close</mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button (click)="viewQuick(row['ID'])" mat-menu-item>
                  <mat-icon>visibility</mat-icon>
                  <span>Quick view</span>
                </button>

                <button (click)="view(row)" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span>View</span>
                </button>

                <button (click)="preview(row['ID'])" mat-menu-item>
                  <mat-icon>link</mat-icon>
                  <span>Open in new Tab</span>
                </button>

                <button *ngIf="row.IsDefault !== '1' || isPromoterOrAdmin == true" (click)="edit(row['ID'])"
                        mat-menu-item>
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>

                <button *ngIf="row.IsDefault !== '1' || isPromoterOrAdmin == true" (click)="share(row['ID'])"
                        mat-menu-item>
                  <mat-icon>share</mat-icon>
                  <span>Share</span>
                </button>

                <hr *ngIf="row.IsDefault !== '1' || isPromoterOrAdmin == true"/>

                <button *ngIf="row['IsActive'] == '1' && (row.IsDefault !== '1' || isPromoterOrAdmin == true)"
                        (click)="disable(row['ID'])" mat-menu-item>
                  <mat-icon>cancel</mat-icon>
                  <span>Disable</span>
                </button>

                <button *ngIf="row['IsActive'] != '1' && (row.IsDefault !== '1' || isPromoterOrAdmin == true)"
                        (click)="enable(row['ID'])" mat-menu-item>
                  <mat-icon>check_circle</mat-icon>
                  <span>Enable</span>
                </button>

                <hr/>
                <button (click)="clone(row)" mat-menu-item>
                  <mat-icon>content_copy</mat-icon>
                  <span>Clone</span>
                </button>

                <hr *ngIf="row.IsDefault !== '1' || isPromoterOrAdmin == true"/>

                <button *ngIf="row.IsDefault !== '1' || isPromoterOrAdmin == true" (click)="removed(row['ID'])"
                        mat-menu-item>
                  <mat-icon>remove_circle</mat-icon>
                  <span>Remove</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator #paginator [length]="dataSource?.filteredData?.length" [pageIndex]="0" [pageSize]="pageSize"
                     [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>
    </mat-card>
  </div>
</div>
<div *ngIf="listDB?.data?.length == 0 && listDB?.firstLoadEvent != false">
  <app-empty-list-message [message]="'No record found to display'" [isAction]="true" [actionLabel]="'Create new Link'"
                          (actionEvent)="createNew()"></app-empty-list-message>
</div>
