import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IllnessCreateEditComponent } from '../illness-create-edit/illness-create-edit.component';
import { IllnessListComponent } from '../illness-list/illness-list.component';
import { AllergyCreateEditComponent } from '../allergy-create-edit/allergy-create-edit.component';
import { AllergyListComponent } from '../allergy-list/allergy-list.component';
import { MedicalHistoryProgressModalComponent } from '../medical-history-progress-modal/medical-history-progress-modal.component';
import { MedicalHistoryLanguageModalComponent } from '../medical-history-language-modal/medical-history-language-modal.component';
import { MedicalHistoryListComponent } from '../medical-history-list/medical-history-list.component';
import { MedicalHistoryInvitationCreate } from '../medical-history-invitation-create/medical-history-invitation-create.component';
import { MedicalHistoryPrivacyModalComponent } from '../medical-history-privacy-modal/medical-history-privacy-modal.component';
import { MedicalHistoryCardComponent } from '../medical-history-card/medical-history-card.component';
import { MedicalHistoryCreateEditComponent } from '../medical-history-create-edit/medical-history-create-edit.component';
import { MedicalHistoryViewComponent } from '../medical-history-view/medical-history-view.component';
import { MedicalHistoryAcknowledgementComponent } from '../medical-history-acknowledgement/medical-history-acknowledgement.component';

const routes: Routes = [
  {
    path: "medical-history-create-edit",
    component: MedicalHistoryCreateEditComponent
  },
  {
    path: "IllnessCreateEditComponent",
    component: IllnessCreateEditComponent
  },
  {
    path: "MedicalHistoryInvitationCreate",
    component: MedicalHistoryInvitationCreate
  },
  {
    path: "MedicalHistoryViewComponent/:medicalHistoryID",
    component: MedicalHistoryViewComponent
  },

  {
    path: "MedicalHistoryAcknowledgementComponent",
    component: MedicalHistoryAcknowledgementComponent
  },




  {
    path: "IllnessListComponent",
    component: IllnessListComponent
  },

  {
    path: "illness-list",
    component: IllnessListComponent
  },
  {
    path: "AllergyCreateEditComponent",
    component: AllergyCreateEditComponent
  },
  {
    path: "AllergyListComponent",
    component: AllergyListComponent
  },
  {
    path: "medical-history-card",
    component: MedicalHistoryCardComponent
  },
  {
    path: "medical-history-progress",
    component: MedicalHistoryProgressModalComponent
  },
  {
    path: "medical-history-language-modal",
    component: MedicalHistoryLanguageModalComponent
  },
  {
    path: "medical-history-list",
    component: MedicalHistoryListComponent
  },
  {
    path: "medical-history-privacy-modal",
    component: MedicalHistoryPrivacyModalComponent
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MedicalHistoryRoutingModule { }
