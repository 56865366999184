import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-bsb-lookup',
  templateUrl: './bsb-lookup.component.html',
  styleUrls: ['./bsb-lookup.component.css'],
})
export class BsbLookupComponent implements OnInit {
  @Output()
  getBSB = new EventEmitter();

  bankVendorID;

  @Output()
  close = new EventEmitter();
  bankName;
  bankVendorName = null;
  banks: any[] = [];
  banksSuburb = [];
  selectedSuburb = null;
  bank = null;
  bankAddress = null;
  addresses = [];

  postCode;
  state;
  suburb;

  iconAddres = 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/bank-icon.png';

  constructor(private utilService: UtilsService) {}

  ngOnInit() {}

  getBankList(name) {
    this.utilService.getBankListByName(name).subscribe((res) => {
      this.banks = res;
    });
  }

  getBanks() {
    this.utilService.getBankList().subscribe((res) => {
      this.banks = res;
    });
  }

  getBankDetails(b) {
    if (b && b.ID && b.Name) {
      this.bankVendorID = b.ID;
      this.bankVendorName = b.Name;

      this.utilService.getBankDetails(this.bankVendorID).subscribe((res) => {
        if (res) {
          this.banksSuburb = res;
        }
      });
    }
  }

  getAddresss() {
    this.utilService.getBankBysuburb(this.bankVendorID, this.selectedSuburb).subscribe((res) => {
      if (res) {
        this.addresses = res;
      }
    });
  }

  selectAdress(bank) {
    if (bank.BSB) {
      this.bank = bank;
    }
  }

  closeModalEvent() {
    this.close.emit(true);
  }

  select() {
    this.getBSB.emit(this.bank);
  }
}
