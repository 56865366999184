<div class="full-width clearfix">
  <div class="main-banner blue-banner"></div>

  <div class="row clearfix container mt ml mr">
    <mat-card class="rel clearfix card-container" [attr.style]="'box-shadow:' + buildShadowBox(color) | safeStyle">
      <div class="full-width flex main-info-parent" *ngIf="subscriptionPlan && subscriptionPlan.ID">
        <div class="main-info">
          <h1 class="main-title text-left" [attr.style]="'color:' + color + ' !important' | safeStyle">
            {{ subscriptionPlan.Web_Label }}
          </h1>
          <div class="full-width title-header-div">
            <p class="text-left main-description mr" *ngIf="webDescription">
              {{ webDescription }}
            </p>

            <div class="text-center mt mb full-width text-center">
              <button
                class="btn all-plans pull-left header-button"
                *ngIf="ids && ids.length > 1"
                [attr.style]="'border-color:' + color + ' !important' | safeStyle"
                (click)="compare()"
                mat-raised-button
                color="accent"
              >
                <mat-icon
                  class="fas fa-chevron-left smr"
                  [attr.style]="'color:' + color + ' !important' | safeStyle"
                ></mat-icon>
                <span [attr.style]="'color:' + color + ' !important' | safeStyle"
                >Compare Plans ({{ ids.length }})</span
                >
              </button>

              <button
                class="btn all-plans pull-left header-button"
                *ngIf="!ids || (ids && ids.length <= 1)"
                [attr.style]="'border-color:' + color + ' !important' | safeStyle"
                (click)="compare()"
                mat-raised-button
                color="accent"
              >
                <mat-icon
                  class="fas fa-chevron-left smr"
                  [attr.style]="'color:' + color + ' !important' | safeStyle"
                ></mat-icon>
                <span [attr.style]="'color:' + color + ' !important' | safeStyle">All Plans</span>
              </button>
            </div>
          </div>
        </div>
        <div class="full-width ml">
          <div [hidden]="true">
            <img [src]="postCover" (error)="onError()" (load)="onLoad()"/>
          </div>

          <div
            class="sub-plan-view-header"
            [ngClass]="{ 'default-background-image': isImageError != false }"
            [style.background-image]="postCoverURL"
          ></div>
        </div>
      </div>

      <div class="full-width mt">
        <div class="text-center subscription-plan-card-right" id="subscription-plan-view-card-fixed">
          <div class="full-width flex mb">
            <app-subscription-plan-card
              class="full-width main-card-info-view ml mr"
              *ngIf="subscriptionPlan && subscriptionPlan.ID"
              [customizationOnly]="true"
              [isClosed]="isClosed"
              [isSubscriptionButton]="true"
              [isSelectButton]="false"
              [isViewButton]="false"
              [subscriptionPlan]="subscriptionPlan"
              (getRemove)="remove($event)"
            >
            </app-subscription-plan-card>
          </div>
        </div>

        <div class="subscription-plan-card-left" *ngIf="subscriptionPlan && subscriptionPlan.ID">
          <div class="full-width clearfix add-margin-right">
            <app-subscription-plan-list-overview
              class="plan-view-sub-plan-overview"
              [featureType]="featureType"
              [displayExtra]="false"
              [displayHeaderPlan]="false"
              [displaySubscribeButton]="true"
              [hideEmptyFeatures]="true"
              [maxLength]="300"
              [displayFeatureCover]="true"
              [subscriptionPlans]="[subscriptionPlan]"
            >
            </app-subscription-plan-list-overview>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>

<div class="full-width clearfix" id="view-sub-plan-footer">
  <app-card-footer
    *ngIf="isCurrentPlan != true"
    [firstBackgroundWhite]="false"
    [featureType]="featureType"
    [items]="['plans-overview', 'features', 'free', 'demo']"
  >
  </app-card-footer>
</div>
