export class medicalHistoryUtil {
  illnessIconList = [
    {
      Code: 'ANAEMIC',
      Label: 'Anaemia',
      Icon: 'AN',
    },
    {
      Code: 'ARTHITIS',
      Label: 'Arthiritis',
      Icon: 'AR',
    },
    {
      Code: 'HRT_VLV',
      Label: 'Artificial Heart Valve',
      Icon: 'HR',
    },
    {
      Code: 'ART_JN',
      Label: 'Artificial Joints - Hip, Knee etc.',
      Icon: 'AJ',
    },
    {
      Code: 'ASTHMA',
      Label: 'Asthma',
      Icon: 'AS',
    },
    {
      Code: 'BLD_DIS',
      Label: 'Blood Disorders',
      Icon: 'BD',
    },
    {
      Code: 'CANCER',
      Label: 'Cancer',
      Icon: 'CA',
    },
    {
      Code: 'DIABTS',
      Label: 'Diabetes',
      Icon: 'DI',
    },
    {
      Code: 'DIZZI',
      Label: 'Dizziness/Fainting',
      Icon: 'DI',
    },
    {
      Code: 'EPIL',
      Label: 'Epilepsy',
      Icon: 'EP',
    },
    {
      Code: 'EXSV_BL',
      Label: 'Excessive Bleeding',
      Icon: 'EB',
    },
    {
      Code: 'GLAUCOMA',
      Label: 'Glaucoma',
      Icon: 'GL',
    },
    {
      Code: 'HRT_DIS',
      Label: 'Heart Disease',
      Icon: 'HD',
    },
    {
      Code: 'HRT_MRM',
      Label: 'Heart Murmur',
      Icon: 'HM',
    },
    {
      Code: 'HEP_A',
      Label: 'Hepatitis A',
      Icon: 'HA',
    },
    {
      Code: 'HEP_B',
      Label: 'Hepatitis B',
      Icon: 'HB',
    },
    {
      Code: 'HEP_C',
      Label: 'Hepatitis C',
      Icon: 'HC',
    },
    {
      Code: 'BLD_PRS',
      Label: 'High/Low Blood Pressure',
      Icon: 'BP',
    },
    {
      Code: 'HIV',
      Label: 'HIV/AIDS',
      Icon: 'HI',
    },
    {
      Code: 'KIDN_DIS',
      Label: 'Kidney Disease',
      Icon: 'KD',
    },
    {
      Code: 'LIV_DIS',
      Label: 'Liver Disease',
      Icon: 'LD',
    },
    {
      Code: 'OSTEOP',
      Label: 'Osteoporosis',
      Icon: 'OS',
    },
    {
      Code: 'PACEMKR',
      Label: 'Pacemaker',
      Icon: 'PA',
    },
    {
      Code: 'PSYCHOL',
      Label: 'Psychological Disorders',
      Icon: 'PS',
    },
    {
      Code: 'RAD_THR',
      Label: "Radiation Therapy', ",
      Icon: 'RA',
    },
    {
      Code: 'RESPIR',
      Label: 'Respiratory Problems',
      Icon: 'RE',
    },
    {
      Code: 'RHEUM',
      Label: 'Rheumatic Fever',
      Icon: 'RH',
    },
    {
      Code: 'SINUS',
      Label: 'Sinus Problems',
      Icon: 'SI',
    },
    {
      Code: 'STROKE',
      Label: 'Stroke',
      Icon: 'ST',
    },
    {
      Code: 'TRANSPL',
      Label: 'Transplanted Organ',
      Icon: 'TR',
    },
    {
      Code: 'TB',
      Label: 'Tuberculosis',
      Icon: 'TB',
    },
    {
      Code: 'TUMOUR',
      Label: 'Tumours',
      Icon: 'TU',
    },
    {
      Code: 'OTHER',
      Label: 'Others',
      Icon: 'OT',
    },
  ];
  allergyIconList = [
    {
      Code: 'Eggs',
      Label: 'Eggs',
      Category: 'Food Allergy',
      Icon: 'EG',
    },
    {
      Code: 'Milk',
      Label: 'Milk',
      Category: 'Food Allergy',
      Icon: 'MI',
    },
    {
      Code: 'Peanuts',
      Label: 'Peanuts',
      Category: 'Food Allergy',
      Icon: 'PE',
    },
    {
      Code: 'TreeNuts',
      Label: 'Tree nuts',
      Category: 'Food Allergy',
      Icon: 'TR',
    },
    {
      Code: 'Fish',
      Label: 'Fish',
      Category: 'Food Allergy',
      Icon: 'FI',
    },
    {
      Code: 'Shellfish',
      Label: 'Shellfish',
      Category: 'Food Allergy',
      Icon: 'SH',
    },
    {
      Code: 'Wheat',
      Label: 'Wheat',
      Category: 'Food Allergy',
      Icon: 'WH',
    },
    {
      Code: 'Soy',
      Label: 'Soy',
      Category: 'Food Allergy',
      Icon: 'SO',
    },
    {
      Code: 'Pollens',
      Label: 'Fruit and vegetable pollen�',
      Category: 'Food Allergy',
      Icon: 'PO',
    },
    {
      Code: 'Eczema',
      Label: 'Eczema',
      Category: 'Skin Allergy',
      Icon: 'EC',
    },
    {
      Code: 'Hives',
      Label: 'Hives',
      Category: 'Skin Allergy',
      Icon: 'HI',
    },
    {
      Code: 'ContactDermatitis',
      Label: 'Contact dermatitis',
      Category: 'Skin Allergy',
      Icon: 'CO',
    },
    {
      Code: 'Dust mites',
      Label: 'Dust mites',
      Category: 'Dust Allergy',
      Icon: 'DU',
    },
    {
      Code: 'Cockroaches',
      Label: 'Cockroaches',
      Category: 'Dust Allergy',
      Icon: 'CO',
    },
    {
      Code: 'Mold',
      Label: 'Mold',
      Category: 'Dust Allergy',
      Icon: 'MO',
    },
    {
      Code: 'Pollen',
      Label: 'Pollen',
      Category: 'Dust Allergy',
      Icon: 'PO',
    },
    {
      Code: 'HairFurFeathers',
      Label: 'Pet hair, fur or feathers',
      Category: 'Dust Allergy',
      Icon: 'HA',
    },
    {
      Code: 'Insect_Sting_Allergy',
      Category: 'Insect Sting Allergy',
      Icon: 'IN',
    },
    {
      Code: 'Cat Allergy',
      Label: 'Cat Allergy',
      Category: 'Pet Allergy',
      Icon: 'CA',
    },
    {
      Code: 'Dog Allergy',
      Label: 'Dog Allergy',
      Category: 'Pet Allergy',
      Icon: 'DO',
    },
    {
      Code: 'Others',
      Label: 'Other',
      Category: 'Pet Allergy',
      Icon: 'OT',
    },
    {
      Code: 'Seasonal',
      Label: 'Seasonal Allergic Conjunctivitis (SAC)',
      Category: 'Eye Allergy',
      Icon: 'SE',
    },
    {
      Code: 'perennial',
      Label: 'Perennial Allergic Conjunctivitis (PAC)',
      Category: 'Eye Allergy',
      Icon: 'PE',
    },
    {
      Code: 'VernalKerato',
      Label: 'Vernal Keratoconjunctivitis',
      Category: 'Eye Allergy',
      Icon: 'VE',
    },
    {
      Code: 'AtopicKerato',
      Label: 'Atopic Keratoconjunctivitis',
      Category: 'Eye Allergy',
      Icon: 'AT',
    },
    {
      Code: 'ContactConj',
      Label: 'Contact allergic conjunctivitis',
      Category: 'Eye Allergy',
      Icon: 'CO',
    },
    {
      Code: 'GiantPapill',
      Label: 'Giant papillary conjunctivitis',
      Category: 'Eye Allergy',
      Icon: 'GI',
    },
    {
      Code: 'Penicillin',
      Label: 'Penicillin',
      Category: 'Drug Allergy',
      Icon: 'PE',
    },
    {
      Code: 'Sulfonamides',
      Label: 'Antibiotics containing sulfonamides (sulfa drugs)',
      Category: 'Drug Allergy',
      Icon: 'SU',
    },
    {
      Code: 'Anticonvulsants',
      Label: 'Anticonvulsants',
      Category: 'Drug Allergy',
      Icon: 'AN',
    },
    {
      Code: 'NSAIDs',
      Label: 'Aspirin, ibuprofen and other nonsteroidal anti-inflammatory drugs (NSAIDs)',
      Category: 'Drug Allergy',
      Icon: 'NS',
    },
    {
      Code: 'Chemotherapy',
      Label: 'Chemotherapy drugs',
      Category: 'Drug Allergy',
      Icon: 'CH',
    },
    {
      Code: 'Latex_Allergy',
      Category: 'Latex Allergy',
      Icon: 'LA',
    },
    {
      Code: 'Mold_Allergy',
      Category: 'Mold Allergy',
      Icon: 'MO',
    },
    {
      Code: 'Sinus_Infection_Allergy',
      Category: 'Sinus Infection Allergy',
      Icon: 'SI',
    },
    {
      Code: 'Cockroach_Allergy',
      Category: 'Cockroach Allergy',
      Icon: 'CO',
    },
    {
      Code: 'Seasonal',
      Label: 'Seasonal',
      Category: 'Rhinitis Allergy',
      Icon: 'SE',
    },
    {
      Code: 'Perennial',
      Label: 'Perennial',
      Category: 'Rhinitis Allergy',
      Icon: 'PE',
    },
  ];

  constructor() {}

  getIllnessIcon(illnessList) {
    let result;
    if (illnessList && Array.isArray(illnessList)) {
      const temp = illnessList.map((x) => x['Type.Code']);
      result = this.illnessIconList.filter((item) => temp.indexOf(item.Code) !== -1);

      return result;
    }
  }

  getAllergyIcon(allergyList) {
    let result;
    if (allergyList && Array.isArray(allergyList)) {
      const temp = allergyList.map((x) => {
        if (x['Type.Code']) {
          return x['Type.Code'];
        } else {
          return x['Category.Code'];
        }
      });

      result = this.allergyIconList.filter((item) => temp.indexOf(item.Code) !== -1);

      return result;
    }
  }
}
