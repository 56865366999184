import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { uniq } from 'lodash';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { UtilsService } from '../../../shared/services/utils.service';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { ProductService } from '../../product/shared/product.service';
import { AiScansUtilClass } from '../shared/ai-scansUtil';
import { AiScansService } from '../shared/services/ai-scans.service';

import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { SideMenuService } from '../../../shared/services/side-menu.service';
import { MarketingService } from '../../marketing/shared/marketing.service';
import { TagCreateEditCardComponent } from '../../tags/tag-create-edit-card/tag-create-edit-card.component';
import { TagPatientManagerComponent } from '../../tags/tag-patient-manager/tag-patient-manager.component';

@Component({
  selector: 'app-ai-scans-list-global',
  templateUrl: './ai-scans-list-global.component.html',
  styleUrls: ['./ai-scans-list-global.component.css'],
  providers: [CustomDatePipe],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class AiScansListGlobalComponent implements OnInit {
  @Input()
  voided = false;
  @Input()
  title = 'AI Scans';

  @Input()
  dateFrom;

  @Input()
  IDs;

  @Input()
  dateTo;

  @Input()
  merchantID;

  @Input()
  tableName;

  @Input()
  setPatients;

  @Input()
  filterID;

  @Input()
  filterIDs;

  @Output()
  getSetPatients = new EventEmitter();

  @Output()
  detailView = new EventEmitter();

  displayDetailCard = false;
  patientID;
  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  displayedColumns = ['FirstName', 'tags', 'Actions'];

  role;
  isPromoterOrAdmin = false;
  filters = [];

  searchVals = new FormControl();

  searchValList = ['First Name', 'Last Name'];

  public existingPatientsDB: LoadExistingPatients | null;
  dataSource: ExistingPatientDataSource | null;

  typeLabel;

  filteredSize = null;
  subjectArray = [];

  aiScansUtilClass = new AiScansUtilClass();
  existingPatientID;
  displayInvitations = false;

  financedProductGroup;
  healthHistoryActive = true;
  selectedProspectIDs = [];
  selectedCustomerIDs = [];
  merchantTags = [];
  marketingFilters = [];
  currentPractice;
  isAppointmentDisabled = false;

  destroyEvent = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private utilService: UtilsService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    private router: Router,
    private customDate: CustomDatePipe,
    private aiScansService: AiScansService,
    private marketingService: MarketingService,
    private productService: ProductService
  ) {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });
  }

  ngOnInit() {
    this.authenticationService.getCurrentPractice().subscribe((currentPractice) => {
      if (currentPractice) {
        this.currentPractice = currentPractice;
      }
    });

    this.utilService.getCurrentAccess().subscribe((access) => {
      SideMenuService.goBack.subscribe((res) => {
        if (res == true) {
          this.hideViewDetails();
        }
      });

      if (this.tableName == 'customer') {
        this.typeLabel = 'Customers';
      } else if (this.tableName == 'prospect') {
        this.typeLabel = 'Prospects';
      } else {
        this.typeLabel = 'Patients';
      }
      this.authenticationService.getCurrentUser().subscribe((res) => {
        if (res && res.success && res.data['Role'] && res.data['Role']) {
          this.role = res.data['Role'];

          const params = {
            IDs: this.IDs,
            filterID: this.filterID,
            filterIDs: this.filterIDs,
          };
          this.existingPatientsDB = new LoadExistingPatients(
            this.aiScansService,
            this.isPromoterOrAdmin,
            this.destroyEvent,
            this.merchantID,
            this.dateFrom,
            this.dateTo,
            this.tableName,
            this.voided,
            this.setPatients,
            params
          );
          this.dataSource = new ExistingPatientDataSource(this.existingPatientsDB, this.paginator, this.sort);
        }
      });

      this.getProductGroup();

      this.getMerchantTags();
      this.getMarketingFitler();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.tableName == 'customer') {
      this.typeLabel = 'Customers';
    } else if (this.tableName == 'prospect') {
      this.typeLabel = 'Prospects';
    } else {
      this.typeLabel = 'Patients';
    }

    const params = {
      IDs: this.IDs,
      filterID: this.filterID,
      filterIDs: this.filterIDs,
    };

    this.existingPatientsDB = new LoadExistingPatients(
      this.aiScansService,
      this.isPromoterOrAdmin,
      this.destroyEvent,
      this.merchantID,
      this.dateFrom,
      this.dateTo,
      this.tableName,
      this.voided,
      this.setPatients,
      params
    );
    this.dataSource = new ExistingPatientDataSource(this.existingPatientsDB, this.paginator, this.sort);
    this.getProductGroup();

    this.getMerchantTags();
  }

  getMerchantTags() {
    const p = {
      merchantID: this.merchantID,
      fields: 'Label',
    };
    this.aiScansService.getMerchantTag(p, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        this.merchantTags = res.map((x) => x['Label']);
      }
    });
  }

  getMarketingFitler() {
    const p = {
      merchantID: this.merchantID,
    };
    this.marketingService.getFilterList(p, this.isPromoterOrAdmin).subscribe((res) => {
      if (res && res.length) {
        this.marketingFilters = res;
      }
    });
  }

  changeFilter(e) {
    if (e && e.ID) {
      this.marketingService.getOneFilter(e.ID, true).subscribe((res) => {
        if (res && res.PatientIDs) {
          this.getSetPatients.emit(res.PatientIDs);
        }
      });
    } else {
      this.getSetPatients.emit(null);
    }
  }

  addNewFilter() {
    this.router.navigate(['/merchant', { outlets: { page: ['new-filter'] } }]);
  }

  getProductGroup() {
    const p1 = {
      isFinance: true,
    };
    if (this.isPromoterOrAdmin == true) {
      p1['merchantID'] = this.merchantID;
    }
    this.productService.getMerchantProductGroup(p1).subscribe((res) => {
      this.financedProductGroup = res;
    });
  }

  setFilter(event, field) {
    let filter;
    if (typeof event == 'object' && event != null) {
      const v = this.customDate.transform(event, Settings.global['dateFormat']);
      filter = {
        field,
        value: v,
      };
    } else if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  selectAll() {
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      const o = this.dataSource.filteredData[i];

      if (o && o['ID'] && o['TableName'] == 'Prospect' && this.selectedProspectIDs.indexOf(o['ID']) == -1) {
        this.selectedProspectIDs.push(o['ID']);
      } else if (o && o['ID'] && o['TableName'] == 'Customer' && this.selectedCustomerIDs.indexOf(o['ID']) == -1) {
        this.selectedCustomerIDs.push(o['ID']);
      }
    }
  }

  selectPage() {
    const index = this.dataSource['_paginator']['index'] || 0;
    const pageSize = this.dataSource['_paginator']['pageSize'] || 0;

    for (let i = 0; i < (index + 1) * pageSize; i++) {
      const o = this.dataSource.filteredData[index * pageSize + i];

      if (o && o['ID'] && o['TableName'] == 'Prospect' && this.selectedProspectIDs.indexOf(o['ID']) == -1) {
        this.selectedProspectIDs.push(o['ID']);
      } else if (o && o['ID'] && o['TableName'] == 'Customer' && this.selectedCustomerIDs.indexOf(o['ID']) == -1) {
        this.selectedCustomerIDs.push(o['ID']);
      }
    }
  }

  select(id, tablename, isSelected) {
    if (isSelected == true) {
      if (tablename == 'Prospect' && this.selectedProspectIDs.indexOf(id) == -1) {
        this.selectedProspectIDs.push(id);
      } else if (tablename == 'Customer' && this.selectedCustomerIDs.indexOf(id) == -1) {
        this.selectedCustomerIDs.push(id);
      }
    } else {
      if (tablename == 'Prospect') {
        this.selectedProspectIDs.splice(this.selectedProspectIDs.indexOf(id), 1);
      } else if (tablename == 'Customer') {
        this.selectedCustomerIDs.splice(this.selectedCustomerIDs.indexOf(id), 1);
      }
    }
  }

  unselect() {
    this.selectedCustomerIDs = [];
    this.selectedProspectIDs = [];
  }

  isSelected(id, tablename) {
    if (tablename == 'Prospect') {
      if (!this.selectedProspectIDs || this.selectedProspectIDs.length <= 0) {
        return false;
      } else {
        if (this.selectedProspectIDs.indexOf(id) != -1) {
          return true;
        }
      }
    } else if (tablename == 'Customer') {
      if (!this.selectedCustomerIDs || this.selectedCustomerIDs.length <= 0) {
        return false;
      } else {
        if (this.selectedCustomerIDs.indexOf(id) != -1) {
          return true;
        }
      }
    }

    return false;
  }

  createTag(row) {
    if (row && row.ID) {
      const ref = RootAppComponent.dialog.open(TagPatientManagerComponent, {
        data: {
          patientID: row.ID,
          patientTags: row.Tags || [],
        },
        width: '700px',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res && Array.isArray(res) && res.length > 0) {
          const item = {
            ID: row.ID,
            tags: res,
          };
          this.dataSource.setTagItem = item;
        } else {
          const item = {
            ID: row.ID,
            tags: [],
          };
          this.dataSource.setTagItem = item;
        }
      });
    }
  }

  createStaticFilterTag() {
    const ids = this.selectedProspectIDs.concat(this.selectedCustomerIDs);

    if (ids.length > 0) {
      const ref = RootAppComponent.dialog.open(TagCreateEditCardComponent, {
        data: {
          cardIDs: ids,
          type: 'StaticFilter',
        },
        width: '650px',
      });
      ref.componentInstance.newTag.subscribe((res) => {
        if (res && res.Label) {
          this.merchantTags.push(res.Label);

          this.merchantTags = uniq(this.merchantTags);
          this.merchantTags = JSON.parse(JSON.stringify(this.merchantTags));
        }
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res) {
          ref.close();

          if (Array.isArray(res) && res.length > 0) {
            for (let i = 0; i < res.length; i++) {
              const patientIDs = res[i].Cards_key.split('|');

              if (patientIDs && patientIDs.length > 0) {
                for (let j = 0; j < patientIDs.length; j++) {
                  const item = {
                    ID: patientIDs[j],
                    tag: res[i].Label,
                  };

                  this.dataSource.addTagItem = item;
                }
              }
            }
          } else {
            const patientIDs = res.Cards_key.split('|');

            if (patientIDs && patientIDs.length > 0) {
              for (let i = 0; i < patientIDs.length; i++) {
                const item = {
                  ID: patientIDs[i],
                  tag: res.Label,
                };

                this.dataSource.addTagItem = item;
              }
            }
          }
        }
      });
    }
  }

  removeStaticFilterTag() {
    const ids = this.selectedProspectIDs.concat(this.selectedCustomerIDs);

    if (ids.length > 0) {
      const ref = RootAppComponent.dialog.open(TagCreateEditCardComponent, {
        data: {
          cardIDs: ids,
          isRemoved: true,
          type: 'StaticFilter',
        },
        width: '650px',
      });
      ref.componentInstance.newTag.subscribe((res) => {
        if (res && res.Label) {
          this.merchantTags.push(res.Label);

          this.merchantTags = uniq(this.merchantTags);
          this.merchantTags = JSON.parse(JSON.stringify(this.merchantTags));
        }
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res) {
          ref.close();

          if (Array.isArray(res) && res.length > 0) {
            for (let i = 0; i < res.length; i++) {
              const patientIDs = ids;

              if (patientIDs && patientIDs.length > 0) {
                for (let j = 0; j < patientIDs.length; j++) {
                  const item = {
                    ID: patientIDs[j],
                    tag: res[i].Label,
                  };

                  this.dataSource.removeTagItem = item;
                }
              }
            }
          } else {
            const patientIDs = ids;

            if (patientIDs && patientIDs.length > 0) {
              for (let i = 0; i < patientIDs.length; i++) {
                const item = {
                  ID: patientIDs[i],
                  tag: res.Label,
                };

                this.dataSource.removeTagItem = item;
              }
            }
          }
        }
      });
    }
  }

  openNewScan() {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['ai-scan-create'],
        },
      },
    ]);
  }

  openDentalXrayReport(ID) {
    if (ID) {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['ai-scan-report-view', ID],
          },
        },
      ]);
    }
  }

  hideViewDetails() {
    this.displayDetailCard = false;
    this.patientID = null;
    this.detailView.emit(false);
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadExistingPatients implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  existingPatients: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  isDestoyed = false;

  util = new UtilsClass();
  serviceRef;

  constructor(
    private aiScanService: AiScansService,
    private isPromoterOrAdmin,
    private destroyEvent,
    private merchantID,
    private dateFrom,
    private dateTo,
    private tableName,
    private voided,
    private setPatients,
    private params
  ) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    const payload = {
      dateFrom: this.util.EPdateFormat(this.dateFrom),
      dateTo: this.util.EPdateFormat(this.dateTo),
      section: 0,
      voided: this.voided,
      merchantID: this.merchantID,
      tableName: this.tableName,
      IDs: null,
      filterID: null,
      filterIDs: null,
      fields:
        'ID,TableName,emails.Email,mobiles.Number,FirstName,Name,CalculatedName,addresses.Calculated,ExistingPerson_key,Status,ReferredCustomer,MarketingConsent.Given,' +
        'Tag.Label,Tag.Description,StaticList.Label,StaticList.Description',
    };

    if (this.params) {
      if (this.params.IDs && Array.isArray(this.params.IDs) && this.params.IDs.length > 0) {
        payload.IDs = this.params.IDs.join(',');
      } else if (this.params.IDs && typeof this.params.IDs === 'string' && this.params.IDs.indexOf('|') != -1) {
        const ids = this.params.IDs.split('|');
        payload.IDs = ids.join(',');
      } else if (this.params.IDs && typeof this.params.IDs === 'string' && this.params.IDs.indexOf(',') != -1) {
        payload.IDs = this.params.IDs;
      } else if (this.params.IDs && typeof this.params.IDs === 'string') {
        payload.IDs = [this.params.IDs];
      }
    }

    if (this.params.filterID) {
      payload.filterID = this.params.filterID;
    } else if (this.params.filterIDs) {
      if (this.params.filterIDs && Array.isArray(this.params.filterIDs) && this.params.filterIDs.length > 0) {
        payload.filterIDs = this.params.filterIDs.join(',');
      } else if (
        this.params.filterIDs &&
        typeof this.params.filterIDs === 'string' &&
        this.params.filterIDs.indexOf('|') != -1
      ) {
        const filterIDs = this.params.filterIDs.split('|');
        payload.filterIDs = filterIDs.join(',');
      } else if (
        this.params.filterIDs &&
        typeof this.params.filterIDs === 'string' &&
        this.params.filterIDs.indexOf(',') != -1
      ) {
        payload.filterIDs = this.params.filterIDs;
      } else if (this.params.filterIDs && typeof this.params.filterIDs === 'string') {
        payload.filterIDs = [this.params.filterIDs];
      }
    }

    if (this.setPatients) {
      payload['setPatients'] = this.setPatients;
    }

    this.serviceRef = this.aiScanService.getAiScansListGlobal(payload, this.isPromoterOrAdmin).subscribe((res) => {
      this.count = res.length;
      this.existingPatients = res;

      for (let i = 0; i < this.existingPatients.length; i++) {
        this.getTags(i);
      }

      this.dataChange.next(this.existingPatients.slice());
      this.firstLoad = true;

      const innerFunction = (section) => {
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.aiScanService.getAiScansListGlobal(payload, this.isPromoterOrAdmin).subscribe((res) => {
          if (res.length > 0) {
            this.count = res.count;
            this.existingPatients = this.existingPatients.concat(res);

            for (let i = 0; i < this.existingPatients.length; i++) {
              this.getTags(i);
            }

            this.dataChange.next(this.existingPatients);

            if (this.isDestoyed != true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };

      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  getTags(index) {
    if (this.existingPatients[index]) {
      let tags = [];
      let staticList = [];
      if (this.existingPatients[index]['Tag.Label']) {
        tags = this.existingPatients[index]['Tag.Label'].split('|');
      }
      if (this.existingPatients[index]['StaticList.Label']) {
        staticList = this.existingPatients[index]['StaticList.Label'].split('|');
      }

      this.existingPatients[index]['Tags'] = tags.concat(staticList);
    }
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class ExistingPatientDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  set removeItem(id) {
    const data = this._custDatabase.data.filter((row) => row.ID != id);
    this._custDatabase.dataChange.next(data);
  }

  set replaceItem(item) {
    const _index = this._custDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    this._custDatabase.data[_index] = item;
    this._custDatabase.dataChange.next(this._custDatabase.data);
  }

  set setTagItem(item) {
    const _index = this._custDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    if (item && item.tags && Array.isArray(item.tags)) {
      this._custDatabase.data[_index]['Tags'] = item.tags;
    }
    this._custDatabase.data[_index]['Tags'] = JSON.parse(JSON.stringify(uniq(this._custDatabase.data[_index]['Tags'])));
    this._custDatabase.dataChange.next(this._custDatabase.data);
  }

  set addTagItem(item) {
    const _index = this._custDatabase.data.findIndex((_obj) => _obj.ID == item.ID);

    if (_index != -1) {
      this._custDatabase.data[_index]['Tags'].push(item.tag);

      this._custDatabase.data[_index]['Tags'] = JSON.parse(
        JSON.stringify(uniq(this._custDatabase.data[_index]['Tags']))
      );
      this._custDatabase.dataChange.next(this._custDatabase.data);
    }
  }

  set removeTagItem(item) {
    const _index = this._custDatabase.data.findIndex((_obj) => _obj.ID == item.ID);

    if (_index != -1) {
      const indexOfTag = this._custDatabase.data[_index]['Tags'].indexOf(item.tag);
      if (indexOfTag != -1) {
        this._custDatabase.data[_index]['Tags'].splice(indexOfTag, 1);
      }

      this._custDatabase.data[_index]['Tags'] = JSON.parse(
        JSON.stringify(uniq(this._custDatabase.data[_index]['Tags']))
      );
      this._custDatabase.dataChange.next(this._custDatabase.data);
    }
  }

  filteredData: any[] = [];

  constructor(private _custDatabase: LoadExistingPatients, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._custDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._custDatabase.data.slice().filter((item: any) => {
          if (this.field == 'Tags') {
            let f = '';
            f = item[this.field] || '';

            if (this.filter) {
              return f.indexOf(this.filter) != -1;
            } else {
              return f;
            }
          } else {
            let f = '';
            f = item[this.field] || '';

            const searchStr = f.toLowerCase();

            return searchStr.indexOf(this.filter.toLowerCase()) != -1;
          }
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'LastName':
          [propertyA, propertyB] = [a.CalculatedName, b.CalculatedName];
          break;

        case 'FirstName':
          [propertyA, propertyB] = [a.CalculatedName, b.CalculatedName];
          break;

        case 'DateOfBirth':
          [propertyA, propertyB] = [
            moment(a['DateOfBirth']).toDate().getTime(),
            moment(b['DateOfBirth']).toDate().getTime(),
          ];
          break;

        case 'mobile':
          [propertyA, propertyB] = [a['mobiles.Number'], b['mobiles.Number']];
          break;
        case 'email':
          [propertyA, propertyB] = [a['emails.Email'], b['emails.Email']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
