/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-view.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i7 from "@angular/material/icon";
import * as i8 from "@angular/common";
import * as i9 from "./file-view.component";
import * as i10 from "@angular/material/dialog";
import * as i11 from "../../services/utils.service";
var styles_FileViewComponent = [i0.styles];
var RenderType_FileViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileViewComponent, data: {} });
export { RenderType_FileViewComponent as RenderType_FileViewComponent };
function View_FileViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "row clearfix flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "two-thrd-width flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["(", ")"])), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "thrd-width flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "button", [["class", "btn-small"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(8, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(9, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(10, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["visibility"]))], function (_ck, _v) { _ck(_v, 10, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.files.length; _ck(_v, 5, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 8).disabled || null); var currVal_3 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵnov(_v, 10).inline; var currVal_5 = (((i1.ɵnov(_v, 10).color !== "primary") && (i1.ɵnov(_v, 10).color !== "accent")) && (i1.ɵnov(_v, 10).color !== "warn")); _ck(_v, 9, 0, currVal_4, currVal_5); }); }
export function View_FileViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileViewComponent_1)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.files.length > 0); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FileViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-file-view", [], null, null, null, View_FileViewComponent_0, RenderType_FileViewComponent)), i1.ɵdid(1, 114688, null, 0, i9.FileViewComponent, [i10.MatDialog, i11.UtilsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileViewComponentNgFactory = i1.ɵccf("app-file-view", i9.FileViewComponent, View_FileViewComponent_Host_0, { files: "files", title: "title", canEdit: "canEdit", invitationID: "invitationID" }, { remove: "remove" }, []);
export { FileViewComponentNgFactory as FileViewComponentNgFactory };
