import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';

import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DdrModalComponent } from '../ddr-modal/ddr-modal.component';
import { RootAppComponent } from '../root-component/root-component.component';

@Component({
  selector: 'app-payment-method-input',
  templateUrl: './payment-method-input.component.html',
  styleUrls: ['./payment-method-input.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 0 })),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),

        group([animate(300, style({ height: 0 })), animate('200ms ease-in-out', style({ opacity: '0' }))]),
      ]),
      transition(':enter', [
        style({ height: '0', opacity: 0 }),

        group([animate(300, style({ height: '*' })), animate('400ms ease-in-out', style({ opacity: '1' }))]),
      ]),
    ]),
  ],
})
export class PaymentMethodInputComponent implements OnInit {
  @Input()
  title = 'Payment Method';

  @Input()
  description = 'Please choose payment method';

  @Input()
  cardNumber;

  @Input()
  cardName;

  @Input()
  cardExpireDate;

  @Input()
  cardCVS;

  @Input()
  bankBSB;

  @Input()
  bankName;

  @Input()
  bankBranch;

  @Input()
  accountName;

  @Input()
  accountNumber;

  @Input()
  accountType;

  @Input()
  actionLabel = 'Proceed';

  @Input()
  isAction = false;

  @Input()
  type = 'bank';

  @Input()
  isCreditCard = true;

  @Input()
  isBank = true;

  @Input()
  titleBank = 'Bank account details';

  @Input()
  titleCreditCard = 'Bank account details';

  @Input()
  isRequired = true;
  isValidate = false;

  @Output()
  validate = new EventEmitter();

  @Output()
  getBankAccount = new EventEmitter();

  @Output()
  getCreditCard = new EventEmitter();

  @Output()
  close = new EventEmitter();

  @Output()
  getResult = new EventEmitter();

  @Output()
  getProceed = new EventEmitter();

  isCreditCardValidate = false;
  isBankValidate = false;

  bankAccount;
  creditCard;

  isModal = false;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && data != null) {
      if (data.title) {
        this.title = data.title;
        this.isModal = true;
      }
      if (data.description) {
        this.description = data.description;
        this.isModal = true;
      }
      if (data.cardNumber) {
        this.cardNumber = data.cardNumber;
        this.isModal = true;
      }
      if (data.cardName) {
        this.cardName = data.cardName;
        this.isModal = true;
      }
      if (data.cardExpireDate) {
        this.cardExpireDate = data.cardExpireDate;
        this.isModal = true;
      }
      if (data.cardCVS) {
        this.cardCVS = data.cardCVS;
        this.isModal = true;
      }

      if (data.bankBSB) {
        this.bankBSB = data.bankBSB;
        this.isModal = true;
      }
      if (data.bankName) {
        this.bankName = data.bankName;
        this.isModal = true;
      }
      if (data.bankBranch) {
        this.bankBranch = data.bankBranch;
        this.isModal = true;
      }
      if (data.accountName) {
        this.accountName = data.accountName;
        this.isModal = true;
      }

      if (data.accountNumber) {
        this.accountNumber = data.accountNumber;
        this.isModal = true;
      }

      if (data.accountType) {
        this.accountType = data.accountType;
        this.isModal = true;
      }
      if (data.actionLabel) {
        this.actionLabel = data.actionLabel;
        this.isModal = true;
      }

      if (data.type) {
        this.type = data.type;
        this.isModal = true;
      }

      if (data.titleBank) {
        this.titleBank = data.titleBank;
        this.isModal = true;
      }

      if (data.isAction != null) {
        this.isAction = data.isAction;
        this.isModal = true;
      }

      if (data.isCreditCard != null) {
        this.isCreditCard = data.isCreditCard;
        this.isModal = true;
      }

      if (data.isBank != null) {
        this.isBank = data.isBank;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {}

  getCreditCardEvent(res) {
    if (res) {
      this.creditCard = res;

      this.getResultEvent();
    }
  }

  getBankAccountEvent(res) {
    if (res) {
      this.bankAccount = res;
      this.getBankAccount.emit(res);
      this.getResultEvent();
    }
  }

  getResultEvent() {
    if (this.type == 'bank' && this.bankAccount) {
      this.validate.emit(this.isBankValidate);

      const result = {
        expiredDate: null,
        securityCode: null,
        bsb: this.bankAccount.bankBSB,
        bankName: this.bankAccount.bankName,
        bankBranch: this.bankAccount.bankBranch,
        accountHolder: this.bankAccount.accountName,
        accountNumber: this.bankAccount.accountNumber,
        accountType: this.bankAccount.accountType,
        paymentType: this.bankAccount.paymentType,
      };

      this.getResult.emit(result);
      this.getBankAccount.emit(this.bankAccount);
      this.getCreditCard.emit(null);
    } else if (this.type == 'creditCard' && this.creditCard) {
      this.validate.emit(this.isCreditCardValidate);

      const result = {
        expiredDate: this.creditCard.cardExpireDate,
        securityCode: this.creditCard.cardCVS,
        bsb: null,
        bankName: null,
        bankBranch: null,
        accountHolder: this.creditCard.cardName,
        accountNumber: this.creditCard.cardNumber,
        accountType: this.creditCard.accountType,
        paymentType: this.creditCard.paymentType,
      };

      this.getResult.emit(result);
      this.getCreditCard.emit(this.creditCard);
      this.getBankAccount.emit(null);
    }
  }

  getActionEvent() {
    if (this.type == 'bank') {
      this.getProceed.emit(this.bankAccount);
    } else if (this.type == 'creditCard') {
      this.getProceed.emit(this.creditCard);
    }
  }

  validateEvent() {
    if (this.type == 'bank') {
      this.validate.emit(this.isBankValidate);
    } else if (this.type == 'creditCard') {
      this.validate.emit(this.isCreditCardValidate);
    }
  }
  validateBank(res) {
    this.isBankValidate = res;

    this.validateEvent();
  }

  validateCreditCard(res) {
    this.isCreditCardValidate = res;
    this.validateEvent();
  }

  closeEvent() {
    this.close.emit(true);
  }

  ddrModal() {
    const ref = RootAppComponent.dialog.open(DdrModalComponent, {
      width: '650px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }
}
