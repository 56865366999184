<ipv-page [showDataHeader]="false">
  <ipv-background-header [title]="'Translation Management'">
    <button class="btn pull-right header-button" (click)="import()" mat-raised-button color="accent">Import</button>
    <button class="btn pull-right header-button mr" (click)="export()" mat-raised-button color="accent">Export</button>
  </ipv-background-header>

  <div class="flex" style="display: flex; justify-content: space-between">
    <!-- Select Context -->
    <mat-form-field class="thrd-width mr" appearance="outline">
      <mat-label>Context</mat-label>
      <mat-select
        class="full-width alternate-theme"
        [(ngModel)]="context"
        (ngModelChange)="changeContext($event)"
        placeholder="Context"
        name="contextapp"
      >
        <mat-select-trigger>
          <div class="full-width" *ngIf="context && context.value && context.name">
            <span
              class="ml pull-right chip chipGood mr"
              *ngIf="context && context.value && settings && settings['context'] == context.value"
            >
              Current</span
            >

            <mat-icon class="core-icon-choice mr" *ngIf="isFontAwesomeIcon(context.icon) != true">
              {{ context.icon }}
            </mat-icon>
            <mat-icon class="{{ context.icon }} core-icon-choice mr" *ngIf="isFontAwesomeIcon(context.icon) == true">
            </mat-icon>
            {{ context.name }}
          </div>
        </mat-select-trigger>

        <mat-option *ngFor="let c of contexts" [value]="c">
          <span
            class="ml pull-right chip chipGood mr"
            *ngIf="c && c.value && settings && settings['context'] == c.value"
          >
            Current</span
          >

          <mat-icon class="core-icon-choice mr" *ngIf="isFontAwesomeIcon(c.icon) != true"> {{ c.icon }} </mat-icon>
          <mat-icon class="{{ c.icon }} core-icon-choice mr" *ngIf="isFontAwesomeIcon(c.icon) == true"></mat-icon>
          {{ c.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Search -->
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Search key/value</mat-label>
      <div style="display: flex">
        <input [(ngModel)]="filter" matInput placeholder="Search"/>
      </div>
    </mat-form-field>
  </div>

  <p
    class="text-left small pull-left full-width rm-mt"
    *ngIf="lastUpdateDate"
    matTooltip="{{ lastUpdateDate | customDateTime }}"
  >
    <mat-icon class="fas fa-history" style="font-size: 1rem; color: gray"></mat-icon>
    Last update: {{ lastUpdateDate | TimeLabelPast }} ago
  </p>

  <form #resetForm="ngForm">
    <div class="row clearfix" *ngIf="translationData && translationData.length > 0">
      <!-- <hr> -->
      <div class="flex full-width">
        <h3 class="sr-title full-width">Text to replace</h3>
        <h3 class="sr-title full-width mr">Replacement</h3>
      </div>

      <div
        class="flex full-width container-translate"
        *ngFor="let v of translationData | FilterArrayMultipleContainValue: ['key', 'value']:filter; let index = index"
        [ngClass]="{ 'back-odd': isODD(index) === true }"
      >
        <mat-form-field class="full-width mr container-translate-key" appearance="outline">
          <textarea class="text-area-translate" [(ngModel)]="v.key" matInput name="name222{{ index }}"></textarea>
        </mat-form-field>

        <mat-form-field class="full-width smr container-translate-value" appearance="outline">
          <textarea
            class="text-area-translate"
            [(ngModel)]="v.value"
            matInput
            name="namedadada222{{ index }}"
          ></textarea>
        </mat-form-field>

        <button class="smr smt" (click)="removeTranslationItems(v)" mat-icon-button>
          <mat-icon style="color: silver">delete</mat-icon>
        </button>
      </div>

      <div class="full-width mb">
        <button
          class="pull-right mat-stroked-mini-fab mat-elevation-z1"
          (click)="addTranslationItems()"
          mat-stroked-button
          color="accent"
          matTooltip="New key/value"
        >
          <mat-icon class="fas fa-plus"></mat-icon>
        </button>
      </div>
      <hr class="mb"/>

      <div class="clearfix full-width text-center">
        <button class="btn-large pull-right" (click)="saveTranslationServerOverview()" mat-raised-button color="accent">
          Save & Apply
        </button>

        <mat-checkbox
          class="smt smb ml pull-left"
          *ngIf="
            context &&
            context.name &&
            context.value &&
            settings &&
            settings['context'] &&
            settings['context'] != context.value
          "
          [(ngModel)]="setDefault"
          name="applyCheck"
          color="accent"
        >
          Set this <strong>{{ context.name }}</strong> as Default Context of the application
        </mat-checkbox>
      </div>
    </div>
  </form>
  <!-- <div class="row clearfix ">
    <form [formGroup]="viewModel.translationFormConfig['Other'].formGroup"
      (ngSubmit)="saveTranslation(viewModel.translationFormConfig, viewModel.context)">
      <formly-form [form]="viewModel.translationFormConfig['Other'].formGroup"
        [fields]="viewModel.translationFormConfig['Other'].fields"
        [model]="viewModel.translationFormConfig['Other'].model"></formly-form>

      <hr class="mb">
      <div class=" clearfix full-width text-center">
        <button mat-raised-button color="accent" class="btn-large">Save changes</button>
      </div>

    </form>
  </div> -->

  <!--
    <mat-tab-group class="row clearfix" [selectedIndex]="0" dynamicHeight="true">
        <mat-tab [label]="tab" *ngFor="let tab of tabs">

        </mat-tab>
      </mat-tab-group> -->

  <!--                  <div class=" clearfix full-width text-center">-->
  <!--                    <button mat-raised-button color="accent" class="btn-large" (click)="deleteTranslation(viewModel.context)">Delete</button>-->
  <!--                  </div>-->
</ipv-page>
