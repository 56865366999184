import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { MedicalHistoryService } from '../shared/medical-history.service';

@Component({
  selector: 'app-medical-history-invitation-view',
  templateUrl: './medical-history-invitation-view.component.html',
  styleUrls: ['./medical-history-invitation-view.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class MedicalHistoryInvitationView implements OnInit {
  @Input() merchantID;

  @Input() invitationID;

  @Input() patientID;

  @Output() close = new EventEmitter();
  @Input()
  invitation;

  util = new UtilsClass();
  isModal = false;
  @Input()
  title;

  colorInv = 'primary';
  modeInv = 'buffer';
  valueInv = 0;
  bufferValueInv = 0;

  sessionType = 'merchant';

  constructor(
    private activeRoute: ActivatedRoute,
    private medicalHistoryService: MedicalHistoryService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.merchantID) {
        this.merchantID = data.merchantID;

        this.isModal = true;
      }

      if (data.patientID) {
        this.patientID = data.patientID;

        this.isModal = true;
      }

      if (data.invitationID) {
        this.invitationID = data.invitationID;

        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['merchantID']) {
        this.merchantID = params['merchantID'];
      }

      if (params['patientID']) {
        this.patientID = params['patientID'];
      }

      if (params['invitationID']) {
        this.invitationID = params['invitationID'];
      }

      this.authenticationService.getSessionType().subscribe((res) => {
        this.sessionType = res;

        if (this.invitationID) {
          this.medicalHistoryService
            .getMedicalHistoryInvitation(this.invitationID, null, this.sessionType)
            .subscribe((res) => {
              if (res) {
                this.invitation = res;
                this.setup();
              }
            });
        } else if (this.invitation && this.invitation.ID) {
          this.setup();
        } else if (
          this.merchantID &&
          this.patientID &&
          (this.sessionType == 'admin' || this.sessionType == 'promoter')
        ) {
          this.medicalHistoryService
            .getMedicalHistoryInvitationByPatient(this.patientID, this.merchantID, null, this.sessionType)
            .subscribe((res) => {
              if (res) {
                this.invitation = res;
                this.setup();
              }
            });
        } else if (this.patientID) {
          this.medicalHistoryService
            .getMedicalHistoryInvitationByPatient(this.patientID, null, null, this.sessionType)
            .subscribe((res) => {
              if (res) {
                this.invitation = res;
                this.setup();
              }
            });
        }
      });
    });
  }

  setup() {
    if (this.invitation) {
      if (this.invitation.status == 'sent') {
        this.valueInv = 0;
      } else if (this.invitation.status == 'viewed') {
        this.valueInv = 40;
      } else if (this.invitation.status == 'commenced') {
        this.valueInv = 70;
      } else if (this.invitation.status == 'completed') {
        this.valueInv = 100;
      } else if (this.invitation.status == 'voided') {
        this.valueInv = 100;
        this.colorInv = 'accent';
      } else if (this.invitation.status == 'expired') {
        this.valueInv = 100;
        this.colorInv = 'accent';
      }
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
}
