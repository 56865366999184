import { Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-performance-intro-modal',
  templateUrl: './performance-intro-modal.component.html',
  styleUrls: ['./performance-intro-modal.component.css'],
})
export class PerformanceIntroModalComponent implements OnInit {
  showOnPerformanceFirstVisit = JSON.parse(localStorage.getItem('showOnPerformanceFirstVisit'));
  closeModal = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  doNotShow() {
    localStorage.setItem('showOnPerformanceFirstVisit', JSON.stringify(this.showOnPerformanceFirstVisit));

    this.closeModal.emit(this.showOnPerformanceFirstVisit);
  }
}
