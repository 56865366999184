<footer
  class="primary-bg"
  *ngIf="settings"
  [class.promoter]="promoterViewAs == 'promoter' && isAdminOrPromoter == true"
>
  <div class="container">
    <div class="clearfix">
      <!-- Left Buttons -->
      <div class="pull-left flex">
        <button class="btn-clear help-line btn-help" mat-button>
          <mat-icon>phone</mat-icon>
          <strong> Help Line: </strong>
          <span class="help-phone-number"> {{ settings.contactPhone | customPhone: 'landLine' }} </span>
        </button>
        <button class="btn-clear help-email btn-help" (click)="openMailTo()" mat-button>
          <mat-icon>email</mat-icon>
          <strong> Help Email: </strong>
          <span class="help-email-address"> {{ settings.contactEmail }} </span>
        </button>

        <button class="btn-clear help-email btn-help" *ngIf="settings.publicWebSite" (click)="openPublic()" mat-button>
          <mat-icon>public</mat-icon>
          <span class="help-email-address"> Public web site </span>
        </button>
      </div>
      <!-- Right buttons, logo -->
      <div class="pull-right flex mb">
        <button class="btn-outline inline" (click)="rateUs()" mat-raised-button>Rate Us</button>
        <button class="btn-outline inline" (click)="FAQ()" mat-raised-button>FAQ</button>
        <button class="btn-outline inline" (click)="aboutUs()" mat-raised-button>About Us</button>
        <button class="btn-outline inline" (click)="credits()" mat-raised-button>Credits</button>
        <button class="btn-outline inline" (click)="contactUs()" mat-raised-button>Contact Us</button>

        <div class="footer-logo inline" *ngIf="settings['context'] != 'breeze' && settings['context'] != 'p4p'">
          <img
            class="inverse"
            (click)="goToADA()"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/brandLogos/ADA-Fed-Logo.png"
          />
          <img
            class="inverse"
            (click)="goToADIA()"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/brandLogos/ADIA-member-w.png"
          />
          <img class="inverse" [src]="getFileLink(settings.BrandingLogoImage)" (click)="openPublic()"/>
        </div>
      </div>
    </div>
    <hr/>
    <!-- Disclaimer message -->
    <p class="small text-center disclaimer rm-mb" id="disclaimer" [innerHTML]="settings.snapLine | safeHtml"></p>
    <p class="small text-center disclaimer rm-mt" id="disclaimer" [innerHTML]="settings.copyRight | safeHtml"></p>
  </div>
</footer>
