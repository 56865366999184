var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AppointmentService } from '../shared/appointment.service';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { AppointmentLookupEditComponent, } from '../appointment-lookup-edit/appointment-lookup-edit.component';
import { AppointmentLookupService } from '../shared/appointmentLookup.service';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { AppointmentLookupCreateComponent } from '../appointment-lookup-create/appointment-lookup-create.component';
export class AppointmentLookupSortComponent {
    constructor(appointmentService, appointmentLookupService, authenticationService, merchantService, router) {
        this.appointmentService = appointmentService;
        this.appointmentLookupService = appointmentLookupService;
        this.authenticationService = authenticationService;
        this.merchantService = merchantService;
        this.router = router;
        this.bestAppointmentTimes = [];
        this.appointmentTypes = [];
        this.subTypes = [];
        this.isPromoterOrAdmin = false;
        this.destroyEvent = new EventEmitter();
        this.util = new UtilsClass();
        this.settings = Settings.global;
        this.disableSaveBestContactTime = true;
        this.disableSaveType = true;
        this.disableSaveSubType = true;
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.isLoadingInit = true;
            let res = yield Promise.all([
                this.authenticationService.isPromoterOrAdmin().toPromise(),
                this.authenticationService.getSessionType().toPromise(),
            ]);
            this.isPromoterOrAdmin = res[0];
            this.sessionType = res[1];
            res = yield Promise.all([
                this.appointmentLookupService
                    .list({
                    merchantID: this.merchantID,
                    lookupType: 'BestAppointmentTime',
                }, this.sessionType)
                    .toPromise(),
                this.appointmentLookupService
                    .list({
                    merchantID: this.merchantID,
                    lookupType: 'AppointmentTypes',
                    category: 'AppointmentTypes',
                }, this.sessionType)
                    .toPromise(),
            ]);
            this.bestAppointmentTimes = res[0];
            this.appointmentTypes = res[1];
            this.isLoadingInit = false;
        });
    }
    ngOnDestroy() {
        this.destroyEvent.emit(true);
    }
    drop(list, event, type) {
        if (type == 'bestContactTime') {
            this.disableSaveBestContactTime = false;
        }
        else if (type == 'type') {
            this.disableSaveType = false;
        }
        else if (type == 'subType') {
            this.disableSaveSubType = false;
        }
        moveItemInArray(list, event.previousIndex, event.currentIndex);
    }
    selectAppointmentType(item) {
        return __awaiter(this, void 0, void 0, function* () {
            this.isLoadingSubType = true;
            this.selectedAppointmentType = item;
            this.subTypes = [];
            this.subTypes = yield this.appointmentLookupService
                .list({
                merchantID: this.merchantID,
                lookupType: 'AppointmentTypes',
                category: this.selectedAppointmentType.Code,
            }, this.sessionType)
                .toPromise();
            this.isLoadingSubType = false;
        });
    }
    edit(list, item) {
        const dialogData = {
            appointmentLookupID: item['ID'],
        };
        const ref = RootAppComponent.dialog.open(AppointmentLookupEditComponent, {
            data: dialogData,
            width: '700px',
        });
        ref.componentInstance.sendResult.subscribe((res) => {
            if (res) {
                this.updateChanges(list, res);
                ref.close();
            }
        });
        ref.componentInstance.closeModal.subscribe((res) => {
            if (res == true) {
                ref.close();
            }
        });
    }
    enable(list, item) {
        const confirmDialogParams = {
            data: new ConfirmDialog('block', 'Are you sure?', '<p>Once the appointment type is enabled the user will be able to use it </p>', 'No', 'Yes'),
        };
        const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, confirmDialogParams);
        ref.componentInstance.onConfirm.subscribe((val) => {
            if (val == true) {
                const payload = {
                    isActive: true,
                };
                this.appointmentLookupService.edit(item.ID, payload, this.sessionType).subscribe((res) => {
                    if (res) {
                        this.updateChanges(list, res);
                        NotifyAppComponent.displayToast('success', 'Enable appointment type', 'Appointment type is now enabled');
                        ref.close();
                    }
                });
            }
            else {
                ref.close();
            }
        });
    }
    disable(list, item) {
        const confirmDialogParams = {
            data: new ConfirmDialog('block', 'Are you sure?', '<p>Once the appointment type is disabled the user will not be able to use it </p>', 'No', 'Yes'),
        };
        const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, confirmDialogParams);
        ref.componentInstance.onConfirm.subscribe((val) => {
            if (val == true) {
                const payload = {
                    isActive: false,
                };
                this.appointmentLookupService.edit(item.ID, payload, this.sessionType).subscribe((res) => {
                    if (res) {
                        this.updateChanges(list, res);
                        NotifyAppComponent.displayToast('success', 'Disable appointment type', 'Appointment type is now disabled');
                        ref.close();
                    }
                });
            }
            else {
                ref.close();
            }
        });
    }
    remove(list, item) {
        const confirmDialogParams = {
            data: new ConfirmDialog('fas fa-info', 'Are you sure?', '<p>Once the appointment type is removed it can not be used in appointments</p>', 'No', 'Yes'),
        };
        const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, confirmDialogParams);
        ref.componentInstance.onConfirm.subscribe((val) => {
            if (val) {
                this.appointmentLookupService.delete(item.ID).subscribe((res) => {
                    if (res) {
                        this.updateChanges(list, res);
                        NotifyAppComponent.displayToast('success', 'Remove appointment type', 'Appointment type is now removed');
                        if (item &&
                            item.Category == 'AppointmentTypes' &&
                            this.selectedAppointmentType &&
                            item.Code === this.selectedAppointmentType.Code) {
                            this.subTypes = [];
                        }
                        ref.close();
                    }
                });
            }
            else {
                ref.close();
            }
        });
    }
    save(list, type) {
        const payload = {
            itemObjects: list.map((item, idx) => {
                return {
                    ID: item.ID,
                    sortOrder: (idx + 1).toString(),
                };
            }),
        };
        this.appointmentLookupService.sort(payload, this.sessionType).subscribe((res) => {
            if (res) {
                if (type == 'bestContactTime') {
                    this.disableSaveBestContactTime = true;
                }
                else if (type == 'type') {
                    this.disableSaveType = true;
                }
                else if (type == 'subType') {
                    this.disableSaveSubType = true;
                }
                NotifyAppComponent.displayToast('success', 'Appointment types', 'Appointment types are now sorted accordingly');
            }
        });
    }
    updateChanges(list, item) {
        if (typeof item === 'string') {
            const idx = list.findIndex((it) => it.ID === item);
            if (idx !== -1) {
                list.splice(idx, 1);
            }
        }
        else {
            const idx = list.findIndex((it) => it.ID === item.ID);
            if (idx !== -1) {
                list[idx] = item;
            }
        }
    }
    addBestContactTime() {
        const ref = RootAppComponent.dialog.open(AppointmentLookupCreateComponent, {
            data: {
                type: 'type',
                lookupType: 'BestAppointmentTime',
            },
            width: '900px',
        });
        ref.componentInstance.sendResult.subscribe((res) => {
            if (res) {
                this.bestAppointmentTimes.push(res);
                this.bestAppointmentTimes = JSON.parse(JSON.stringify(this.bestAppointmentTimes));
                this.disableSaveBestContactTime = true;
                ref.close();
            }
        });
        ref.componentInstance.closeModal.subscribe((res) => {
            if (res == true) {
                ref.close();
            }
        });
    }
    addCategory() {
        const ref = RootAppComponent.dialog.open(AppointmentLookupCreateComponent, {
            data: {
                type: 'type',
                lookupType: 'AppointmentTypes',
            },
            width: '900px',
        });
        ref.componentInstance.sendResult.subscribe((res) => {
            if (res) {
                this.appointmentTypes.push(res);
                this.appointmentTypes = JSON.parse(JSON.stringify(this.appointmentTypes));
                this.disableSaveType = true;
                ref.close();
            }
        });
        ref.componentInstance.closeModal.subscribe((res) => {
            if (res == true) {
                ref.close();
            }
        });
    }
    addSubType() {
        if (this.selectedAppointmentType && this.selectedAppointmentType.Code) {
            const ref = RootAppComponent.dialog.open(AppointmentLookupCreateComponent, {
                data: {
                    type: 'subType',
                    lookupType: 'AppointmentTypes',
                    categoryID: this.selectedAppointmentType.Code,
                },
                width: '900px',
            });
            ref.componentInstance.sendResult.subscribe((res) => {
                if (res) {
                    if (res && res.Category === this.selectedAppointmentType.Code) {
                        this.subTypes.push(res);
                        this.subTypes = JSON.parse(JSON.stringify(this.subTypes));
                        this.disableSaveSubType = true;
                    }
                    ref.close();
                }
            });
            ref.componentInstance.closeModal.subscribe((res) => {
                if (res == true) {
                    ref.close();
                }
            });
        }
    }
}
