<!-- dynamic prefix -->
<mat-form-field
  class="qtr-width prefixPhoneContainer"
  *ngIf="isDynamicPrefix == true"
  [ngClass]="isValidate == false ? 'errorInput' : ''"
  appearance="outline"
>
  <mat-label>{{ prefixLabel }}</mat-label>
  <span matPrefix>+</span>
  <input
    class="mat-block"
    [(ngModel)]="selectedPrefix"
    [minlength]="1"
    [placeholder]="prefixLabel"
    [required]="required"
    [disabled]="isDisabled"
    (change)="sendResult()"
    type="text"
    matInput
    name="{{ random2 }}"
  />
</mat-form-field>

<!-- shown with dynamic prefix -->
<mat-form-field
  class="two-thrd-width"
  *ngIf="isDynamicPrefix == true"
  [ngClass]="isValidate == false ? 'errorInput' : ''"
  appearance="outline"
>
  <mat-label>{{ label }}</mat-label>
  <input
    class="mat-block"
    [(ngModel)]="selectedPhoneNumber"
    [placeholder]="label"
    [required]="required"
    [disabled]="isDisabled"
    (change)="sendResult()"
    type="text"
    matInput
    name="{{ random1 }}"
    pattern="([0-9]{10})"
  />
</mat-form-field>

<!-- no dynamic prefix -->
<mat-form-field
  class="full-width"
  *ngIf="isDynamicPrefix == false || !isDynamicPrefix"
  [ngClass]="isValidate == false ? 'errorInput' : ''"
  appearance="outline"
>
  <mat-label>{{ label }}</mat-label>
  <input
    class="mat-block"
    [(ngModel)]="selectedPhoneNumber"
    [placeholder]="label"
    [required]="required"
    [disabled]="isDisabled"
    (change)="sendResult()"
    matInput
    type="text"
    name="{{ random2 }}"
    pattern="([0-9]{10})"
  />

  <mat-error *ngIf="!selectedPhoneNumber">
    <span *ngIf="isLandLine == true">Phone number is required</span>
    <span *ngIf="isLandLine == false">Mobile number is required</span>
  </mat-error>
  <mat-hint *ngIf="isValidate == false && selectedPhoneNumber && changeOnce == true" [style.color]="'#f44336'"
  >Invalid Phone number format
  </mat-hint>
</mat-form-field>
