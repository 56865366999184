import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CdkTableModule } from '@angular/cdk/table';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgPipesModule } from 'ngx-pipes';
import { CustomerModule } from '../customer/customer.module';
import { ExistingPatientModule } from '../existing-patient/existing-patient.module';
import { OperatorModule } from '../operator/operator.module';
import { CustomerProspectListArchivesComponent } from './customer-prospect-list-archives/customer-prospect-list-archives.component';
import { CustomerProspectListGlobalComponent } from './customer-prospect-list-global/customer-prospect-list-global.component';
import { CustomerProspectListMapComponent } from './customer-prospect-list-map/customer-prospect-list-map.component';
import { CustomerProspectListOverViewComponent } from './customer-prospect-list-overview/customer-prospect-list-overview.component';
import { CustomerProspectMapOverviewComponent } from './customer-prospect-map-overview/customer-prospect-map-overview.component';
import { CustomerProspectViewComponent } from './customer-prospect-view/customer-prospect-view.component';
import { CustomerProspectRoutingModule } from './shared/customerProspect-routing.module';
import { CustomerProspectService } from './shared/customerProspect.service';

import { MatSortModule } from '@angular/material/sort';
import { DpDatePickerModule } from 'ng2-date-picker';
import { BankAccountModule } from '../bank-account/bank-account.module';
import { ContactModule } from '../contact/contact.module';
import { DocumentModule } from '../document/document.module';
import { MessageModule } from '../message/message.module';
import { CustomerProspectBlankComponent } from './customer-prospect-blank/customer-prospect-blank.component';
import { CustomerProspectCurrentProfileComponent } from './customer-prospect-current-profile/customer-prospect-current-profile.component';
import { CustomerProspectEditComponent } from './customer-prospect-edit/customer-prospect-edit.component';
import { PatientHealthcareComponent } from './patient-healthcare-create/patient-healthcare-create.component';

import { ArchwizardModule } from 'angular-archwizard';
import { SessionModule } from '../../core/session/session.module';
import { SharedModule } from '../../shared/shared.module';
import { TagsModule } from '../tags/tags.module';
import { CustomerProfileSummaryDialogComponent } from './customer-profile-summary-dialog/customer-profile-summary-dialog.component';
import { CustomerProspectBoostModalComponent } from './customer-prospect-boost-modal/customer-prospect-boost-modal.component';
import { CustomerProspectCreateComponent } from './customer-prospect-create/customer-prospect-create.component';
import { CustomerProspectListServerComponent } from './customer-prospect-list-server/customer-prospect-list-server.component';
import { CustomerProspectTagViewComponent } from './customer-prospect-tag-view/customer-prospect-tag-view.component';
import { CustomerProspectViewModelComponent } from './customer-prospect-view-model/customer-prospect-view-model.component';
import { GroupInviteModalComponent } from './group-invite-modal/group-invite-modal.component';
import { PatientDocumentConsumerViewComponent } from './patient-document-consumer-view/patient-document-consumer-view.component';
import { PatientDocumentCreateModalComponent } from './patient-document-create-modal/patient-document-create-modal.component';
import { PatientDocumentCreateComponent } from './patient-document-create/patient-document-create.component';
import { MarketingFilterModule } from '../marketing-filter/marketing-filter.module';
import { CardModule } from '../card/card.module';

@NgModule({
  imports: [
    NgPipesModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CardModule,
    CdkTableModule,
    OperatorModule,
    CustomerProspectRoutingModule,
    CustomerModule,
    ExistingPatientModule,
    NgxPaginationModule,
    ContactModule,
    BankAccountModule,
    DpDatePickerModule,
    MessageModule,
    MatSortModule,
    DocumentModule,
    SessionModule,
    ArchwizardModule,
    TagsModule,
    MarketingFilterModule
  ],
  declarations: [
    CustomerProspectListOverViewComponent,
    CustomerProspectListGlobalComponent,
    CustomerProspectMapOverviewComponent,
    CustomerProspectListMapComponent,
    CustomerProspectViewComponent,
    CustomerProspectListArchivesComponent,
    CustomerProspectEditComponent,
    GroupInviteModalComponent,
    PatientHealthcareComponent,
    CustomerProspectBlankComponent,
    CustomerProspectCurrentProfileComponent,
    CustomerProspectBoostModalComponent,
    PatientDocumentCreateComponent,
    PatientDocumentConsumerViewComponent,
    PatientDocumentCreateModalComponent,
    CustomerProspectTagViewComponent,
    CustomerProfileSummaryDialogComponent,
    CustomerProspectCreateComponent,
    CustomerProspectViewModelComponent,
    CustomerProspectListServerComponent,
  ],
  exports: [
    CustomerProfileSummaryDialogComponent,
    GroupInviteModalComponent,
    CustomerProspectListOverViewComponent,
    CustomerProspectListGlobalComponent,
    CustomerProspectMapOverviewComponent,
    CustomerProspectListMapComponent,
    CustomerProspectViewComponent,
    CustomerProspectListArchivesComponent,
    CustomerProspectEditComponent,
    PatientHealthcareComponent,
    CustomerProspectBlankComponent,
    CustomerProspectCurrentProfileComponent,
    CustomerProspectBoostModalComponent,
    PatientDocumentCreateComponent,
    PatientDocumentConsumerViewComponent,
    PatientDocumentCreateModalComponent,
    CustomerProspectTagViewComponent,
    CustomerProspectCreateComponent,
    CustomerProspectViewModelComponent,
    CustomerProspectListServerComponent,
    MarketingFilterModule
  ],
  entryComponents: [CustomerProfileSummaryDialogComponent],
  providers: [CustomerProspectService],
})
export class CustomerProspectModule {}
