<div class="container profiles">
  <mat-card class="card details noPad">
    <div class="row toggle-card clearfix">
      <mat-button-toggle-group class="btn-group rm-m clearfix full-width flex" [(ngModel)]="settings">
        <mat-button-toggle class="qtr-width text-center" [value]="'filter'"> Audiences</mat-button-toggle>
        <mat-button-toggle class="qtr-width text-center" [value]="'static'"> Static Audiences</mat-button-toggle>

        <mat-button-toggle class="qtr-width text-center" [value]="'tag'"> Tags</mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <!-- <div class="column"> -->
    <div class="tab-content clearfix" *ngIf="settings == 'filter'" @ngIfAnimation>
      <div class="tab-content-inner">
        <app-marketing-filter-list-overview
          class="clearfix ml mr"
          [inputMerchantID]="merchantID"
          [hideFilter]="hideFilter"
        >
        </app-marketing-filter-list-overview>
      </div>
    </div>

    <div class="tab-content clearfix" *ngIf="settings == 'static'" @ngIfAnimation>
      <div class="tab-content-inner">
        <app-tags-list-overview
          class="clearfix ml mr"
          [staticAudience]="true"
          [inputMerchantID]="merchantID"
          [hideFilter]="hideFilter"
        >
        </app-tags-list-overview>
      </div>
    </div>

    <div class="tab-content clearfix" *ngIf="settings == 'tag'" @ngIfAnimation>
      <div class="tab-content-inner">
        <app-tags-list-overview
          class="clearfix ml mr"
          [staticAudience]="false"
          [inputMerchantID]="merchantID"
          [hideFilter]="hideFilter"
        >
        </app-tags-list-overview>
      </div>
    </div>
    <!-- </div> -->
  </mat-card>
</div>
