<div class="padded" style="overflow: hidden">
  <ipv-data-table
    [table]="report.fullTable"
    [paginate]="false"
    [sectionColumn]="'label'"
    [sections]="[
      { value: 'Borrower Fees', color: '#CE066B', fontColor: 'white' },
      { value: 'Borrower Interest', color: '#CE066B', fontColor: 'white' },
      { value: 'Merchant Fees', color: '#168BDD', fontColor: 'white' },
      { value: 'Total Borrower Interest & Fees', color: '#E7E9EB', fontColor: 'black', customLayout: true },
      { value: 'Total Revenue', color: '#E7E9EB', fontColor: 'black', customLayout: true }
    ]"
    [rename]="{
      actualNet: 'Net',
      actualPercentage: 'Actual %'
    }"
    [formatHeaders]="{
      actual: 'right',
      refunds: 'right',
      actualNet: 'right',
      actualPercentage: 'right'
    }"
    [customComponents]="{
      label: label,
      actual: actual,
      refunds: refunds,
      actualNet: net,
      actualPercentage: actualPercentage
    }"
  >
  </ipv-data-table>

  <!--  CUSTOM COMPONENTS -->
  <ng-container>
    <ng-template #label let-record>
      <div [ngStyle]="{ 'font-weight': isTotalSection(record.label) ? 'bold' : 'normal' }">
        {{ record.label }}
      </div>
    </ng-template>

    <ng-template #actual let-record>
      <div [ngStyle]="{ 'font-weight': isTotalSection(record.label) ? 'bold' : 'normal' }" style="text-align: right">
        {{ record.actual | customCurrency }}
      </div>
    </ng-template>

    <ng-template #refunds let-record>
      <div
        *ngIf="record.refunds !== 0"
        [ngStyle]="{ 'font-weight': isTotalSection(record.label) ? 'bold' : 'normal' }"
        style="text-align: right; color: red"
      >
        {{ record.refunds | invert | customCurrency }}
      </div>

      <div
        *ngIf="record.refunds === 0"
        [ngStyle]="{ 'font-weight': isTotalSection(record.label) ? 'bold' : 'normal' }"
        style="text-align: right; color: red"
      >
        {{ record.refunds | customCurrency }}
      </div>
    </ng-template>

    <ng-template #net let-record>
      <div
        [ngStyle]="{ 'font-weight': isTotalSection(record.label) ? 'bold' : 'normal' }"
        style="text-align: right; color: #1b8bdd"
      >
        {{ record.actualNet | customCurrency }}
      </div>
    </ng-template>

    <ng-template #actualPercentage let-record>
      <div [ngStyle]="{ 'font-weight': isTotalSection(record.label) ? 'bold' : 'normal' }" style="text-align: right">
        {{ record.actualPercentage + '%' }}
      </div>
    </ng-template>
  </ng-container>
</div>
