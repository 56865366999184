import { UtilSchema } from '../../../../../../../../../../../../shared/types/utils/utils.type';

export const DENTIST_PERSONALIZATION_SCHEMA: UtilSchema[] = [
  {
    code: 'DentistSalutation',
    label: 'Salutation',
    field: 'Salutation',
    group: '1',
  },
  {
    code: 'DentistFirstName',
    label: 'FirstName',
    field: 'FirstName',
    group: '1',
  },
  {
    code: 'DentistMiddleName',
    label: 'Middle',
    field: 'MiddleName',
    group: '1',
  },
  {
    code: 'DentistLastName',
    label: 'LastName',
    field: 'Name',
    group: '1',
  },
  {
    code: 'DentistMobileNumber',
    label: 'Mobile',
    field: 'mobiles.Number',
    group: '2',
    icon: 'fas fa-mobile',
    isPhone: true,
    isLandLine: false,
  },
  {
    code: 'DentistPhoneNumber',
    label: 'Phone',
    field: 'phones.Number',
    group: '2',
    icon: 'fas fa-phone',
    isPhone: true,
    isLandLine: true,
  },
  {
    code: 'DentistEmail',
    label: 'Email',
    field: 'emails.Email',
    group: '2',
    icon: 'fas fa-at',
  },
  {
    code: 'DentistAddress',
    label: 'Address',
    field: 'addresses.Calculated',
    group: '2',
    icon: 'fas fa-map-marker-alt',
  },
];
