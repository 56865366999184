<div class="full-width white-background" *ngIf="isPromoterOrAdmin == true && displayAdmin == true">
  <div [hidden]="hideExtraComponents">
    <app-operator-filter
      class="dash"
      id="part2"
      [selectDefaultAccount]="true"
      [setAutoSelectMerchant]="true"
      [sendNull]="true"
      [displayMerchant]="true"
      [displayDateFrom]="false"
      [displayDateRange]="false"
      [displayDateTo]="false"
      [displayDentist]="false"
      (getMerchant)="getMerchant($event)"
      (search)="applySearch($event)"
    >
    </app-operator-filter>
  </div>

  <div class="row clearfix padded" *ngIf="isPromoterOrAdmin && !_merchantID">
    <div class="pleaseChoose">
      <p>
        <strong>No Merchant Selected</strong>
      </p>
      <img class="character_warning" />
      <p>Choose Desired Merchant</p>
    </div>
  </div>

  <div class="row clearfix" *ngIf="!isPromoterOrAdmin || (isPromoterOrAdmin && _merchantID)">
    <div class="reduce-padding mb clearfix mat-elevation-z1 full-width white-background">
      <div class="toggle-card row clearfix full-width mb">
        <mat-button-toggle-group class="btn-group row clearfix full-width text-center" [(ngModel)]="lookupType">
          <mat-button-toggle class="text-center qtr-width pull-left" [value]="'AppointmentTypes'">
            Reasons for visiting
          </mat-button-toggle>

          <mat-button-toggle class="text-center qtr-width pull-left" [value]="'BestAppointmentTime'">
            Best Appointment Time
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="row clearfix full-width tabArea mt white-background">
        <app-appointment-lookup-list
          class="row clearfix animate"
          *ngIf="categorySelected != true"
          [pageSize]="pageSize"
          [lookupType]="lookupType"
          [merchantID]="merchantID"
          (selectCategory)="selectCategory($event)"
        >
        </app-appointment-lookup-list>

        <div class="full-width clearfix" *ngIf="categorySelected == true && category">
          <app-appointment-lookup-list
            class="clearfix"
            class="row clearfix animate"
            [pageSize]="pageSize"
            [category]="category"
            [merchantID]="merchantID"
            [lookupType]="lookupType"
          >
          </app-appointment-lookup-list>
        </div>

        <div class="full-width clearfix text-center" *ngIf="categorySelected == true && category">
          <button class="btn-large mb mt" (click)="goBack()" mat-raised-button color="primary">
            <mat-icon>keyboard_arrow_left</mat-icon> Reasons for visiting
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row clearfix">
    <div class="pleaseChoose" *ngIf="merchantID == 'none'" @ngIfAnimation>
      <p>
        <strong>No Merchant Selected</strong>
      </p>
      <img class="character_warning" />
      <p>
        Choose your desired merchant from the drop-down above to view their Appointments
      </p>
    </div>
  </div>
</div>

<div class="row full-width clearfix white-background" *ngIf="isPromoterOrAdmin == false || displayAdmin != true">
  <div class="row clearfix">
    <div class="reduce-padding mb clearfix mat-elevation-z1 full-width white-background">
      <div class="toggle-card row clearfix full-width mb">
        <mat-button-toggle-group class="btn-group row clearfix flex full-width text-center" [(ngModel)]="lookupType">
          <mat-button-toggle class="text-center qtr-width pull-left" [value]="'AppointmentTypes'">
            Reasons for visiting
          </mat-button-toggle>

          <mat-button-toggle class="text-center qtr-width pull-left" [value]="'BestAppointmentTime'">
            Best Appointment Time
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div class="row clearfix full-width tabArea mt white-background">
        <app-appointment-lookup-list
          class="row clearfix animate"
          *ngIf="categorySelected != true"
          [pageSize]="pageSize"
          [lookupType]="lookupType"
          (selectCategory)="selectCategory($event)"
        >
        </app-appointment-lookup-list>

        <div class="full-width clearfix" *ngIf="categorySelected == true && category">
          <app-appointment-lookup-list
            class="clearfix"
            class="row clearfix animate"
            [pageSize]="pageSize"
            [category]="category"
            [lookupType]="lookupType"
          >
          </app-appointment-lookup-list>
        </div>

        <div class="full-width clearfix text-center" *ngIf="categorySelected == true && category">
          <button class="btn-large mb mt" (click)="goBack()" mat-raised-button color="primary">
            <mat-icon>keyboard_arrow_left</mat-icon> Reasons for visiting
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
