<div class="row clearfix">
  <div class="row clearfix mb" *ngIf="isAdminOrPromoter == true">
    <div class="quickLook row flex text-center" *ngIf="stats">
      <mat-card class="mat-elevation-z1 sml full-width">
        <h4 class="sr-title sm-h4 rm-m">Email</h4>
        <span class="bigNum" [endVal]="successedEmail || 0" countUp></span>
        <div class="row clearfix grey">
          <h5 class="sr-title sm-h4 rm-m" *ngIf="allEmail && allEmail != 0">
            {{ successedEmail / allEmail | percent: '2.1-1' }} success rate
          </h5>
          <h5 class="sr-title sm-h4 rm-m" *ngIf="!allEmail || allEmail == 0">
            {{ 0 | percent: '2.1-1' }} success rate
          </h5>
        </div>
      </mat-card>

      <mat-card class="mat-elevation-z1 sml full-width">
        <h4 class="sr-title sm-h4 rm-m">SMS</h4>
        <span class="bigNum" [endVal]="successedSMS || 0" countUp></span>
        <div class="row clearfix grey">
          <h5 class="sr-title sm-h4 rm-m" *ngIf="allSMS && allSMS != 0">
            {{ successedSMS / allSMS | percent: '2.1-1' }} success rate
          </h5>
          <h5 class="sr-title sm-h4 rm-m" *ngIf="!allSMS || allSMS == 0">{{ 0 | percent: '2.1-1' }} success rate</h5>
        </div>
      </mat-card>

      <mat-card class="mat-elevation-z1 sml full-width">
        <h4 class="sr-title sm-h4 rm-m">Targets Reached</h4>
        <span class="bigNum" [endVal]="successedTarget || 0" countUp></span>
        <div class="row clearfix grey">
          <h5 class="sr-title sm-h4 rm-m" *ngIf="allTarget && allTarget != 0">
            {{ successedTarget / allTarget | percent: '2.1-1' }} success rate
          </h5>
          <h5 class="sr-title sm-h4 rm-m" *ngIf="!allTarget || allTarget == 0">
            {{ 0 | percent: '2.1-1' }} success rate
          </h5>
        </div>
      </mat-card>

      <mat-card class="mat-elevation-z1 sml full-width">
        <h4 class="sr-title sm-h4 rm-m">Targets Not Reached</h4>
        <span class="bigNum" [endVal]="failedTarget || 0" countUp></span>
        <div class="row clearfix grey">
          <h5 class="sr-title sm-h4 rm-m" *ngIf="allTarget && allTarget != 0">
            {{ failedTarget / allTarget | percent: '2.1-1' }} success rate
          </h5>
          <h5 class="sr-title sm-h4 rm-m" *ngIf="!allTarget || allTarget == 0">
            {{ 0 | percent: '2.1-1' }} success rate
          </h5>
        </div>
      </mat-card>
    </div>
  </div>

  <div class="row clearfix" *ngIf="isAdminOrPromoter == true">
    <mat-card class="reduce-padding mb clearfix mat-elevation-z1">
      <div class="toggle-card row clearfix">
        <mat-button-toggle-group
          class="btn-group row clearfix flex full-width text-center"
          [(ngModel)]="status"
          (change)="getStats()"
        >
          <mat-button-toggle class="text-center full-width {{ getClass(s) }}" *ngFor="let s of statusArray" [value]="s">
            <span class="badge badge-header primary-color pull-left" *ngIf="count && s == status" color="primary">{{
              count
              }}</span>

            {{ marketingUtil.getStatusLabel(s) }}
            <mat-icon class="fas {{ marketingUtil.getIconClass(s) }} "></mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="row clearfix full-width tabArea" @ngIfAnimation>
        <app-marketing-list-global
          class="row clearfix animate marketing-settings-overview"
          id="part11"
          [isTest]="isTest"
          [merchantID]="merchantID"
          [pageSize]="8"
          [status]="status"
          [dateFrom]="dateFrom"
          [dateTo]="dateTo"
          (campaignID)="getID($event)"
          (getCount)="getCount($event)"
        >
        </app-marketing-list-global>
      </div>
    </mat-card>
  </div>

  <div class="row clearfix" *ngIf="isAdminOrPromoter == false">
    <mat-card class="reduce-padding mb clearfix mat-elevation-z1">
      <div class="toggle-card row clearfix">
        <mat-button-toggle-group
          class="btn-group row clearfix flex full-width text-center"
          [(ngModel)]="status"
          (change)="getStats()"
        >
          <mat-button-toggle
            class="text-center full-width rel"
            *ngFor="let s of statusArray; let sx = index"
            [ngClass]="status === s ? 'selectStatus' : ''"
            [value]="s"
          >
            {{ marketingUtil.getStatusLabel(s) }}
            <mat-icon class="fas {{ marketingUtil.getIconClass(s) }} "></mat-icon>

            <span class="badge badge-header primary-color pull-left" *ngIf="count && s === status" color="primary">{{
              count
              }}</span>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="row clearfix full-width tabArea" @ngIfAnimation>
        <app-marketing-list
          class="row clearfix animate marketing-settings-overview"
          id="part1"
          [isTest]="isTest"
          [pageSize]="8"
          [status]="status"
          [dateFrom]="dateFrom"
          [dateTo]="dateTo"
          (campaignID)="getID($event)"
          (getCount)="getCount($event)"
        >
        </app-marketing-list>
      </div>
    </mat-card>
  </div>
</div>
