import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../authentication/shared/authentication.service';

@Component({
  selector: 'app-faq-consumer-modal',
  templateUrl: './faq-modal-consumer.component.html',
  styleUrls: ['./faq-modal-consumer.component.css'],
})
export class FAQModalConsumerComponent implements OnInit {
  simplePageCode = 'Frequent Asked Questions';

  usertype;
  isLogin = false;
  panelOpenState = false;

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe((res) => {
      if (res) {
        this.isLogin = true;
        this.usertype = res.data['Role'];
      }
    });
  }
}
