import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomerProspectService } from '../../customer-prospect/shared/customerProspect.service';
import { PCICarComponent } from '../../personal-circumstance/car/car.component';
import { PCICreditCardComponent } from '../../personal-circumstance/credit-card/credit-card.component';
import { PCIEmploymentBenefitComponent } from '../../personal-circumstance/employment-benefit/employment-benefit.component';
import { PCIFurnitureHouseholdComponent } from '../../personal-circumstance/furniture-household/furniture-household.component';
import { PCIHouseResidenceComponent } from '../../personal-circumstance/house-residence/house-residence.component';
import { PCIInterestIncomeComponent } from '../../personal-circumstance/interest-income/interest-income.component';
import { PCIInvestmentLoansComponent } from '../../personal-circumstance/investment-loans/investment-loans.component';
import { PCIInvestmentPropertyComponent } from '../../personal-circumstance/investment-property/investment-property.component';
import { PCILivingExpenseComponent } from '../../personal-circumstance/living-expense/living-expense.component';
import { PCIOtherAssetsComponent } from '../../personal-circumstance/other-assets/other-assets.component';
import { PCIOtherIncomeComponent } from '../../personal-circumstance/other-income/other-income.component';
import { PCIOtherVehicleComponent } from '../../personal-circumstance/other-vehicle/other-vehicle.component';
import { PCIDocumentUploadComponent } from '../../personal-circumstance/personal-circumstance-document-upload/personal-circumstance-document-upload.component';
import { PCIPersonalLoanComponent } from '../../personal-circumstance/personal-loan/personal-loan.component';
import { PCISharesComponent } from '../../personal-circumstance/shares/shares.component';
import { PCITaxDebtComponent } from '../../personal-circumstance/tax-debt/tax-debt.component';
import { QuotationDecisionModalComponent } from '../../quotation/quotation-decision-modal/quotation-decision-modal.component';
import { PersonalCircumstancesViewComponent } from '../personal-circumstances-view/personal-circumstances-view.component';
import { ContractService } from '../shared/contract.service';

// personal circumstances

export class perCirc {
  name: string;
  amount: number;
  frequency: string;
  amountEach: number;
  reason: string;
  comment: string;
  file: string;
}

const PER_CIRC_DATA: perCirc[] = [
  { name: 'Motor Vehicle', amount: 1000, frequency: 'Weekly', amountEach: 700, reason: '', comment: '', file: '' },
  { name: 'Electricity', amount: 1000, frequency: 'Weekly', amountEach: 1000, reason: '', comment: '', file: '' },
];

@Component({
  selector: 'app-personal-circumstances',
  templateUrl: './personal-circumstances.component.html',
  styleUrls: ['./personal-circumstances.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: '*' }))),
    ]),
  ],
})
export class PersonalCircumstancesComponent implements OnInit {
  //  demo personal circumstances
  displayedPerCColumns: string[] = ['name', 'amount', 'frequency', 'amountEach', 'reason', 'comment', 'file'];
  dataPerCCheck = PER_CIRC_DATA;

  chart1 = 0;
  chart2 = 0;

  @Input()
  contractID;

  @Input()
  personalCirID;

  @Input()
  unlockPersonalCirID;

  @Input()
  checkstatus;

  isPromoterOrAdmin = false;

  personalCircumstances;
  personInfo;

  houseOfResidences;
  employmentAndBenefits;
  interestAsIncomes;
  furnitureHouseholdGoods;
  shares;
  livingExpenses;
  creditCards;
  cars;
  investmentAndLoans;
  otherAssets;
  investmentPropertys;
  otherIncomes;
  otherVehicles;
  personalLoans;
  taxAndDebts;

  totalIncomeEmployment;
  totalIncomeInvestmentPropertys;
  totalIncomeShares;
  totalIncomeInterest;
  totalIncomeOtherIncomes;

  totalExpenseHouse;
  totalExpenseInvestmentPropertys;
  totalExpenseLivingExpenses;
  totalExpensePersonalLoans;
  totalExpenseCreditCards;
  totalExpenseCars;
  totalExpenseOtherVehicles;
  totalExpenseTaxAndDebts;
  totalExpenseInvestmentAndLoans;
  totalExpenseShares;

  totalAssetHouse;
  totalAssetInvestmentPropertys;
  totalAssetShares;
  totalAssetInterest;
  totalAssetFurniture;
  totalAssetCars;
  totalAssetOtherVehicles;
  totalAssetOtherAssets;

  totalLiabilityHouse;
  totalLiabilityInvestmentPropertys;
  totalLiabilityPersonalLoans;
  totalLiabilityCreditCards;
  totalLiabilityCars;
  totalLiabilityOtherVehicles;
  totalLiabilityTaxAndDebts;
  totalLiabilityInvestmentAndLoans;
  totalLiabilityShares;

  chartIncomeExpenses = [];
  totalIncomePos;
  totalExpenses;
  netIncome;
  chartAssetsLiabilities = [];
  totalAssetsPos;
  netAssets;
  totalAssets;
  totalLiabilities;
  chartIncome = [];
  chartExpenses = [];
  chartAssets = [];
  chartLiabilities = [];
  sessionType = 'guest';

  step;

  personalCircumstanceVersions = [];

  incomeTabVisible = false;
  expenseTabVisible = false;
  assetTabVisible = false;
  liabilityTabVisible = false;
  documentTabVisible = false;

  displayFrequency = '52';
  displayFrequencyLabel = 'week';

  isLockPCI = true;

  loading = true;
  constructor(
    private authenticationService: AuthenticationService,
    private contractService: ContractService,
    private customerProspectService: CustomerProspectService,
    private dialog: MatDialog,
    private activeRouter: ActivatedRoute
  ) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      this.activeRouter.params.subscribe((params) => {
        if (params['contractID']) {
          this.contractID = params['contractID'];
        }

        if (params['personalCirID']) {
          this.personalCirID = params['personalCirID'];
        }

        this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
          this.isPromoterOrAdmin = res;

          if (this.isPromoterOrAdmin == true) {
            if (this.contractID) {
              this.contractService.getCapacityCheckDetails(this.contractID).subscribe((res) => {
                if (res) {
                  this.personalCirID = res['PersonalCircumstances_key'];

                  if (res['UnlockedPersonalCircum_key']) {
                    this.personalCirID = res['UnlockedPersonalCircum_key'];
                    this.isLockPCI = false;
                  }

                  this.personalCircumstanceVersions = [
                    {
                      name: 'Borrower Declaration',
                      value: res['PersonalCircumstances_key'],
                    },
                    {
                      name: 'Operator Modified',
                      value: res['UnlockedPersonalCircum_key'],
                    },
                  ];

                  this.setup();
                }
              });
            }
          }
        });
      });
    });
  }

  setStep(index) {
    this.step = index;
  }

  changePersonalCircumstance(e) {
    this.isLockPCI = true;
    this.personalCirID = e;

    if (this.personalCircumstanceVersions.filter((x) => x.value == e)[0]['name'] == 'Operator Modified') {
      this.isLockPCI = false;
    }

    this.setup();
  }

  setup() {
    this.loading = true;
    this.contractService.getPersonCircumstanceDetails(this.personalCirID).subscribe((res) => {


      if (res) {
        this.personalCircumstances = res;

        this.totalIncomePos = Math.round(Number(this.personalCircumstances['IncomePerWeek']) * 52);

        this.chartIncomeExpenses = [
          {
            name: 'Net Position',
            value:
              Math.round(Number(this.personalCircumstances['IncomePerWeek']) * 52) -
              Math.round(Number(this.personalCircumstances['ExpensesPerWeek']) * 52),
          },
          {
            name: 'Expenses',
            value: Math.round(Number(this.personalCircumstances['ExpensesPerWeek']) * 52),
          },
        ];

        this.netIncome =
          Math.round(Number(this.personalCircumstances['IncomePerWeek']) * 52) -
          Math.round(Number(this.personalCircumstances['ExpensesPerWeek']) * 52);

        this.totalExpenses = Math.round(Number(this.personalCircumstances['ExpensesPerWeek']) * 52);

        this.totalAssetsPos =
          Number(this.personalCircumstances['Assets.Total']) - Number(this.personalCircumstances['Liabilities.Total']);

        this.chartAssetsLiabilities = [
          {
            name: 'Net Assets',
            value:
              Number(this.personalCircumstances['Assets.Total']) -
              Number(this.personalCircumstances['Liabilities.Total']),
          },
          {
            name: 'Liabilities',
            value: Number(this.personalCircumstances['Liabilities.Total']),
          },
        ];

        this.totalAssets = Math.round(Number(this.personalCircumstances['Assets.Total']));

        this.totalLiabilities = Math.round(Number(this.personalCircumstances['Liabilities.Total']));

        // this.customerProspectService.getCustomerProspectDetails(this.personalCircumstances['Customer_key'], {}, this.sessionType).subscribe(res => {
        //   this.personInfo = res;
        // })
      }
      this.loading = false;
    });

    const payload = {
      personalCircumstanceID: this.personalCirID,
    };
    this.contractService.getPCICars(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        this.cars = res;

        this.summaryPCICar();
      }
    });

    this.contractService.getPCICreditCard(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        this.creditCards = res;
        this.summaryPCICreditCard();
      }
    });

    this.contractService.getPCIEmploymentAndBenefit(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        this.employmentAndBenefits = res;
        this.summaryPCIEmploymentAndBenefit();
      }
    });

    this.contractService.getPCIFurnitureHouseholdGoods(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        this.furnitureHouseholdGoods = res;
        this.summaryPCIFurnitureHouseholdGoods();
      }
    });

    this.contractService.getPCIHouseOfResidence(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        this.houseOfResidences = res;
        this.summaryPCIHouseOfResidence();
      }
    });

    this.contractService.getPCIInterestAsIncome(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        this.interestAsIncomes = res;
        this.summaryPCIInterestAsIncome();
      }
    });

    this.contractService.getPCIInvestmentAndLoans(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        this.investmentAndLoans = res;
        this.summaryPCIInvestmentAndLoans();
      }
    });

    this.contractService.getPCIInvestmentProperty(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        this.investmentPropertys = res;

        this.summaryPCIInvestmentProperty();
      }
    });

    this.contractService.getPCILivingExpenses(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        this.livingExpenses = res;
        this.summaryPCILivingExpenses();
      }
    });

    this.contractService.getPCIOtherAssets(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        this.otherAssets = res;
        this.summaryPCIOtherAssets();
      }
    });

    this.contractService.getPCIOtherIncome(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        this.otherIncomes = res;
        this.summaryPCIOtherIncome();
      }
    });

    this.contractService.getPCIOtherVehicle(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        this.otherVehicles = res;
        this.summaryPCIOtherVehicle();
      }
    });

    this.contractService.getPCIPersonalLoan(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        this.personalLoans = res;
        this.summaryPCIPersonalLoan();
      }
    });

    this.contractService.getPCIShares(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        this.shares = res;
        this.summaryPCIShares();
      }
    });

    this.contractService.getPCITaxAndDebt(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        this.taxAndDebts = res;
        this.summaryPCITaxAndDebt();
      }
    });
  }

  calculateSummary(obj, isIncome, isExpense, isAsset, isLiability) {
    if (obj) {
      if (isIncome == true) {
        this.totalIncomeShares = this.calculatedTotalAnnual(obj, 'income');
      }

      if (isExpense == true) {
        this.totalExpenseTaxAndDebts = this.calculatedTotalAnnual(obj, 'expense');
      }

      if (isAsset == true) {
        this.totalAssetShares = this.calculatedTotalAnnual(obj, 'asset');
      }

      if (isLiability == true) {
        this.totalLiabilityTaxAndDebts = this.calculatedTotalAnnual(obj, 'asset');
      }
    }
  }

  convertMonth(value) {
    const _value = Number(value);
    if (_value >= 0) {
      const months = [
        '',
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];

      return months[_value];
    }
  }

  calculateAnnualAmount(value, frequency) {
    let result;
    if (value && frequency == '1') {
      result = Number(value) * 52;
    } else if (value && frequency == '2') {
      result = Number(value) * 26;
    } else if (value && frequency == '4') {
      result = Number(value) * 12;
    } else if (value && frequency == '12') {
      result = Number(value) * 4;
    } else if (value && frequency == '24') {
      result = Number(value) * 2;
    } else if (value && frequency == '48') {
      result = Number(value);
    } else {
      result = Number('0');
    }

    return result;
  }

  calculatedTotalAnnual(varObj, type) {
    let result = 0;
    if (varObj) {
      for (let i = 0; i < varObj.length; i++) {
        if (type == 'income') {
          result = result + this.calculateAnnualAmount(varObj[i]['Income.Value'], varObj[i]['Income.Frequency']);
        } else if (type == 'expense') {
          result = result + this.calculateAnnualAmount(varObj[i]['Expense.Value'], varObj[i]['Expense.Frequency']);
        } else if (type == 'asset') {
          result = result + Number(varObj[i]['Asset.Value']);
        } else if (type == 'liability') {
          result += Number(varObj[i]['Liability.Value']);
        }
      }
    }
    return result;
  }

  calculateMoveOutTime(yearStart, monthStart, yearsAtAddress, monthsAtAddress) {
    let _moveOutMonth = Number(monthStart) + Number(monthsAtAddress);
    let _moveOutYear = Number(yearStart) + Number(yearsAtAddress);
    if (_moveOutMonth > 12) {
      _moveOutYear = _moveOutYear + 1;
      _moveOutMonth = _moveOutMonth % 12;
    }

    return { moveOutYear: _moveOutYear, moveOutMonth: this.convertMonth(_moveOutMonth) };
  }

  calculateEmploymentPeriod(yearsInRole, monthsInRole) {
    const _nowDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
    };
    if (yearsInRole && monthsInRole) {
      if (_nowDate.month - monthsInRole > 0) {
        return { year: _nowDate.year - yearsInRole, month: this.convertMonth(_nowDate.month - monthsInRole) };
      } else {
        return { year: _nowDate.year - yearsInRole - 1, month: this.convertMonth(_nowDate.month + 12 - monthsInRole) };
      }
    } else {
      return { year: _nowDate.year, month: this.convertMonth(_nowDate.month) };
    }
  }

  openDetailsView(category, ID) {
    const ref = this.dialog.open(PersonalCircumstancesViewComponent, {
      data: {
        category,
        pciEntryID: ID,
      },
      width: '650px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  // decision modal
  makeDecision() {
    const ref = this.dialog.open(QuotationDecisionModalComponent, {
      width: '600px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  changeDisplayFrequency(e) {
    this.displayFrequency = e;

    if (e == '1') {
      this.displayFrequencyLabel = 'year';
    } else if (e == '12') {
      this.displayFrequencyLabel = 'month';
    } else if (e == '26') {
      this.displayFrequencyLabel = 'fortnight';
    } else if (e == '52') {
      this.displayFrequencyLabel = 'week';
    }
  }

  calByFrequency(value) {
    return value / Number(this.displayFrequency);
  }

  calChartValue(chart) {
    let result = chart;

    result = result.map((x) => {
      return {
        name: x.name,
        value: x.value / Number(this.displayFrequency),
      };
    });

    return result;
  }

  openPCIEmploymentBenefitModal() {
    const ref = RootAppComponent.dialog.open(PCIEmploymentBenefitComponent, {
      data: {
        personalCirID: this.personalCirID,
      },
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        this.employmentAndBenefits.push(res);
        this.summaryPCIEmploymentAndBenefit();
        ref.close();
      }
    });
  }

  openPCICarModal() {
    const ref = RootAppComponent.dialog.open(PCICarComponent, {
      data: {
        personalCirID: this.personalCirID,
      },
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        this.cars.push(res);
        this.summaryPCICar();
        ref.close();
      }
    });
  }

  openPCICreditCardModal() {
    const ref = RootAppComponent.dialog.open(PCICreditCardComponent, {
      data: {
        personalCirID: this.personalCirID,
      },
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        this.creditCards.push(res);
        this.summaryPCICreditCard();
        ref.close();
      }
    });
  }

  openPCIFurnitureHouseholdGoodsModal() {
    const ref = RootAppComponent.dialog.open(PCIFurnitureHouseholdComponent, {
      data: {
        personalCirID: this.personalCirID,
      },
      width: '650px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        this.furnitureHouseholdGoods.push(res);
        this.summaryPCIFurnitureHouseholdGoods();
        ref.close();
      }
    });
  }

  openPCIHouseOfResidencesModal() {
    const ref = RootAppComponent.dialog.open(PCIHouseResidenceComponent, {
      data: {
        personalCirID: this.personalCirID,
      },
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        this.houseOfResidences.push(res);
        this.summaryPCIHouseOfResidence();
        ref.close();
      }
    });
  }

  openPCIInterestAsIncomesModal() {
    const ref = RootAppComponent.dialog.open(PCIInterestIncomeComponent, {
      data: {
        personalCirID: this.personalCirID,
      },
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        this.interestAsIncomes.push(res);
        this.summaryPCIInterestAsIncome();
        ref.close();
      }
    });
  }

  openPCIInvestmentAndLoansModal() {
    const ref = RootAppComponent.dialog.open(PCIInvestmentLoansComponent, {
      data: {
        personalCirID: this.personalCirID,
      },
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        this.investmentAndLoans.push(res);
        this.summaryPCIInvestmentAndLoans();
        ref.close();
      }
    });
  }

  openPCIInvestmentPropertysModal() {
    const ref = RootAppComponent.dialog.open(PCIInvestmentPropertyComponent, {
      data: {
        personalCirID: this.personalCirID,
      },
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        this.investmentPropertys.push(res);
        this.summaryPCIInvestmentProperty();
        ref.close();
      }
    });
  }

  openPCILivingExpensesModal() {
    const ref = RootAppComponent.dialog.open(PCILivingExpenseComponent, {
      data: {
        personalCirID: this.personalCirID,
      },
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        this.livingExpenses.push(res);
        this.summaryPCILivingExpenses();
        ref.close();
      }
    });
  }

  openPCIOtherAssetsModal() {
    const ref = RootAppComponent.dialog.open(PCIOtherAssetsComponent, {
      data: {
        personalCirID: this.personalCirID,
      },
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        this.otherAssets.push(res);
        this.summaryPCIOtherAssets();
        ref.close();
      }
    });
  }

  openPCIOtherIncomesModal() {
    const ref = RootAppComponent.dialog.open(PCIOtherIncomeComponent, {
      data: {
        personalCirID: this.personalCirID,
      },
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        this.otherIncomes.push(res);
        this.summaryPCIOtherIncome();
        ref.close();
      }
    });
  }

  openPCIOtherVehiclesModal() {
    const ref = RootAppComponent.dialog.open(PCIOtherVehicleComponent, {
      data: {
        personalCirID: this.personalCirID,
      },
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        this.otherVehicles.push(res);
        this.summaryPCIOtherVehicle();
        ref.close();
      }
    });
  }

  openPCIPersonalLoansModal() {
    const ref = RootAppComponent.dialog.open(PCIPersonalLoanComponent, {
      data: {
        personalCirID: this.personalCirID,
      },
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        this.personalLoans.push(res);
        this.summaryPCIPersonalLoan();
        ref.close();
      }
    });
  }

  openPCISharesModal() {
    const ref = RootAppComponent.dialog.open(PCISharesComponent, {
      data: {
        personalCirID: this.personalCirID,
      },
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        this.shares.push(res);
        this.summaryPCIShares();
        ref.close();
      }
    });
  }

  openPCITaxAndDebtsModal() {
    const ref = RootAppComponent.dialog.open(PCITaxDebtComponent, {
      data: {
        personalCirID: this.personalCirID,
      },
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        this.taxAndDebts.push(res);

        this.summaryPCITaxAndDebt();

        ref.close();
      }
    });
  }

  modifyPCICar(index, obj) {
    if (obj) {
      this.cars[index] = obj;
      this.cars[index]['opened'] = true;

      this.summaryPCICar();
    }
  }

  modifyPCICreditCard(index, obj) {
    if (obj) {
      this.creditCards[index] = obj;
      this.creditCards[index]['opened'] = true;

      this.summaryPCICreditCard();
    }
  }

  modifyPCIEmploymentAndBenefits(index, obj) {
    if (obj) {
      this.employmentAndBenefits[index] = obj;
      this.employmentAndBenefits[index]['opened'] = true;

      this.totalIncomeEmployment = this.calculatedTotalAnnual(this.employmentAndBenefits, 'income');

      this.summaryPCIEmploymentAndBenefit();
    }
  }

  modifyPCIFurnitureHouseholdGoods(index, obj) {
    if (obj) {
      this.furnitureHouseholdGoods[index] = obj;
      this.furnitureHouseholdGoods[index]['opened'] = true;

      this.summaryPCIFurnitureHouseholdGoods();
    }
  }

  modifyPCIHouseOfResidences(index, obj) {
    if (obj) {
      this.houseOfResidences[index] = obj;
      this.houseOfResidences[index]['opened'] = true;

      this.summaryPCIHouseOfResidence();
    }
  }

  modifyPCIInterestAsIncomes(index, obj) {
    if (obj) {
      this.interestAsIncomes[index] = obj;
      this.interestAsIncomes[index]['opened'] = true;

      this.summaryPCIInterestAsIncome();
    }
  }

  modifyInvestmentAndLoans(index, obj) {
    if (obj) {
      this.investmentAndLoans[index] = obj;
      this.investmentAndLoans[index]['opened'] = true;

      this.summaryPCIInvestmentAndLoans();
    }
  }

  modifyInvestmentProperty(index, obj) {
    if (obj) {
      this.investmentPropertys[index] = obj;
      this.investmentPropertys[index]['opened'] = true;

      this.summaryPCIInvestmentProperty();
    }
  }

  modifyLivingExpenses(index, obj) {
    if (obj) {
      this.livingExpenses[index] = obj;
      this.livingExpenses[index]['opened'] = true;

      this.summaryPCILivingExpenses();
    }
  }

  modifyOtherAssets(index, obj) {
    if (obj) {
      this.otherAssets[index] = obj;
      this.otherAssets[index]['opened'] = true;

      this.summaryPCIOtherAssets();
    }
  }

  modifyOtherIncomes(index, obj) {
    if (obj) {
      this.otherIncomes[index] = obj;
      this.otherIncomes[index]['opened'] = true;

      this.summaryPCIOtherIncome();
    }
  }

  modifyPCIOtherVehicle(index, obj) {
    if (obj) {
      this.otherVehicles[index] = obj;
      this.otherVehicles[index]['opened'] = true;

      this.summaryPCIOtherVehicle();
    }
  }

  modifyPCIPersonalLoan(index, obj) {
    if (obj) {
      this.personalLoans[index] = obj;
      this.personalLoans[index]['opened'] = true;

      this.summaryPCIPersonalLoan();
    }
  }

  modifyPCIShare(index, obj) {
    if (obj) {
      this.shares[index] = obj;
      this.shares[index]['opened'] = true;

      this.summaryPCIShares();
    }
  }

  modifyPCITaxAndDebts(index, obj) {
    if (obj) {
      this.taxAndDebts[index] = obj;
      this.taxAndDebts[index]['opened'] = true;

      this.summaryPCITaxAndDebt();
    }
  }

  summaryPCICar() {
    this.totalExpenseCars = this.calculatedTotalAnnual(this.cars, 'expense');
    this.totalAssetCars = this.calculatedTotalAnnual(this.cars, 'asset');
    this.totalLiabilityCars = this.calculatedTotalAnnual(this.cars, 'liability');

    this.summaryTotal();

    this.chartExpenses.push({
      name: 'Cars Expense',
      value: this.totalExpenseCars,
    });

    this.chartAssets.push({
      name: 'Cars Assets',
      value: this.totalAssetCars,
    });

    this.chartLiabilities.push({
      name: 'Cars Liability',
      value: this.totalLiabilityCars,
    });
  }

  summaryPCICreditCard() {
    this.totalExpenseCreditCards = this.calculatedTotalAnnual(this.creditCards, 'expense');
    this.totalLiabilityCreditCards = this.calculatedTotalAnnual(this.creditCards, 'liability');

    this.summaryTotal();

    this.chartExpenses.push({
      name: 'Credit Cards Expense',
      value: this.totalExpenseCreditCards,
    });

    this.chartLiabilities.push({
      name: 'Credit Cards Liability',
      value: this.totalLiabilityCreditCards,
    });
  }

  summaryPCIEmploymentAndBenefit() {
    this.totalIncomeEmployment = this.calculatedTotalAnnual(this.employmentAndBenefits, 'income');

    this.summaryTotal();

    this.chartIncome.push({
      name: 'Employment Income',
      value: this.totalIncomeEmployment,
    });
  }

  summaryPCIFurnitureHouseholdGoods() {
    this.totalAssetFurniture = this.calculatedTotalAnnual(this.furnitureHouseholdGoods, 'asset');

    this.summaryTotal();

    this.chartAssets.push({
      name: 'Furniture Assets',
      value: this.totalAssetFurniture,
    });
  }

  summaryPCIHouseOfResidence() {
    this.totalExpenseHouse = this.calculatedTotalAnnual(this.houseOfResidences, 'expense');
    this.totalAssetHouse = this.calculatedTotalAnnual(this.houseOfResidences, 'asset');
    this.totalLiabilityHouse = this.calculatedTotalAnnual(this.houseOfResidences, 'liability');

    this.summaryTotal();

    this.chartExpenses.push({
      name: 'House Expense',
      value: this.totalExpenseHouse,
    });

    this.chartAssets.push({
      name: 'House Assets',
      value: this.totalAssetHouse,
    });

    this.chartLiabilities.push({
      name: 'House Liability',
      value: this.totalLiabilityHouse,
    });
  }

  summaryPCIInterestAsIncome() {
    this.totalIncomeInterest = this.calculatedTotalAnnual(this.interestAsIncomes, 'income');
    this.totalAssetInterest = this.calculatedTotalAnnual(this.interestAsIncomes, 'asset');

    this.summaryTotal();

    this.chartIncome.push({
      name: 'Interest Income',
      value: this.totalIncomeInterest,
    });

    this.chartAssets.push({
      name: 'Interest Assets',
      value: this.totalAssetInterest,
    });
  }

  summaryPCIInvestmentAndLoans() {
    this.totalExpenseInvestmentAndLoans = this.calculatedTotalAnnual(this.investmentAndLoans, 'expense');
    this.totalLiabilityInvestmentAndLoans = this.calculatedTotalAnnual(this.investmentAndLoans, 'liability');

    this.summaryTotal();

    this.chartExpenses.push({
      name: 'Investment & Loans Expense',
      value: this.totalExpenseInvestmentAndLoans,
    });

    this.chartLiabilities.push({
      name: 'Investment & Loans Liability',
      value: this.totalLiabilityInvestmentAndLoans,
    });
  }

  summaryPCIInvestmentProperty() {
    this.totalIncomeInvestmentPropertys = this.calculatedTotalAnnual(this.investmentPropertys, 'income');
    this.totalExpenseInvestmentPropertys = this.calculatedTotalAnnual(this.investmentPropertys, 'expense');
    this.totalAssetInvestmentPropertys = this.calculatedTotalAnnual(this.investmentPropertys, 'asset');
    this.totalLiabilityInvestmentPropertys = this.calculatedTotalAnnual(this.investmentPropertys, 'liability');

    this.summaryTotal();

    this.chartIncome.push({
      name: 'Investment Properties Income',
      value: this.totalIncomeInvestmentPropertys,
    });

    this.chartExpenses.push({
      name: 'Investment Properties Expense',
      value: this.totalExpenseInvestmentPropertys,
    });

    this.chartAssets.push({
      name: 'Investment Properties Assets',
      value: this.totalAssetInvestmentPropertys,
    });

    this.chartLiabilities.push({
      name: 'Investment Properties Liability',
      value: this.totalLiabilityInvestmentPropertys,
    });
  }

  summaryPCILivingExpenses() {
    this.totalExpenseLivingExpenses = this.calculatedTotalAnnual(this.livingExpenses, 'expense');

    this.summaryTotal();

    this.chartExpenses.push({
      name: 'Living Expense',
      value: this.totalExpenseLivingExpenses,
    });
  }

  summaryPCIOtherAssets() {
    this.totalAssetOtherAssets = this.calculatedTotalAnnual(this.otherAssets, 'asset');

    this.summaryTotal();

    this.chartAssets.push({
      name: 'Other Assets',
      value: this.totalAssetOtherAssets,
    });
  }

  summaryPCIOtherIncome() {
    this.totalIncomeOtherIncomes = this.calculatedTotalAnnual(this.otherIncomes, 'income');

    this.summaryTotal();

    this.chartIncome.push({
      name: 'Other Income',
      value: this.totalIncomeOtherIncomes,
    });
  }

  summaryPCIOtherVehicle() {
    this.totalExpenseOtherVehicles = this.calculatedTotalAnnual(this.otherVehicles, 'expense');
    this.totalAssetOtherVehicles = this.calculatedTotalAnnual(this.otherVehicles, 'asset');
    this.totalLiabilityOtherVehicles = this.calculatedTotalAnnual(this.otherVehicles, 'liability');

    this.summaryTotal();

    this.chartExpenses.push({
      name: 'Other Vehicles Expense',
      value: this.totalExpenseOtherVehicles,
    });

    this.chartAssets.push({
      name: 'Other Vehicles Assets',
      value: this.totalAssetOtherVehicles,
    });

    this.chartLiabilities.push({
      name: 'Other Vehicles Liability',
      value: this.totalLiabilityOtherVehicles,
    });
  }

  summaryPCIPersonalLoan() {
    this.totalExpensePersonalLoans = this.calculatedTotalAnnual(this.personalLoans, 'expense');
    this.totalLiabilityPersonalLoans = this.calculatedTotalAnnual(this.personalLoans, 'liability');

    this.summaryTotal();

    this.chartExpenses.push({
      name: 'Personal Loans Expense',
      value: this.totalExpensePersonalLoans,
    });

    this.chartLiabilities.push({
      name: 'Personal Loans Liability',
      value: this.totalLiabilityPersonalLoans,
    });
  }

  summaryPCIShares() {
    this.totalExpenseShares = this.calculatedTotalAnnual(this.shares, 'expense');
    this.totalIncomeShares = this.calculatedTotalAnnual(this.shares, 'income');
    this.totalAssetShares = this.calculatedTotalAnnual(this.shares, 'asset');
    this.totalLiabilityShares = this.calculatedTotalAnnual(this.shares, 'liability');

    this.summaryTotal();

    this.chartIncome.push({
      name: 'Shares Income',
      value: this.totalIncomeShares,
    });

    this.chartExpenses.push({
      name: 'Shares Expense',
      value: this.totalExpenseShares,
    });

    this.chartAssets.push({
      name: 'Shares Assets',
      value: this.totalAssetShares,
    });

    this.chartLiabilities.push({
      name: 'Shares Liability',
      value: this.totalLiabilityShares,
    });
  }

  summaryPCITaxAndDebt() {
    this.totalExpenseTaxAndDebts = this.calculatedTotalAnnual(this.taxAndDebts, 'expense');
    this.totalLiabilityTaxAndDebts = this.calculatedTotalAnnual(this.taxAndDebts, 'liability');

    this.summaryTotal();

    this.chartExpenses.push({
      name: 'Tax & Debts Expense',
      value: this.totalExpenseTaxAndDebts,
    });

    this.chartLiabilities.push({
      name: 'Tax & Debts Liability',
      value: this.totalLiabilityTaxAndDebts,
    });
  }

  summaryTotal() {
    this.totalIncomePos =
      Number(this.totalIncomeEmployment) +
      Number(this.totalIncomeInterest) +
      Number(this.totalIncomeInvestmentPropertys) +
      Number(this.totalIncomeOtherIncomes) +
      Number(this.totalIncomeShares);

    this.totalExpenses =
      Number(this.totalExpenseCars) +
      Number(this.totalExpenseCreditCards) +
      Number(this.totalExpenseHouse) +
      Number(this.totalExpenseInvestmentAndLoans) +
      Number(this.totalExpenseInvestmentPropertys) +
      Number(this.totalExpenseLivingExpenses) +
      Number(this.totalExpenseOtherVehicles) +
      Number(this.totalExpensePersonalLoans) +
      Number(this.totalExpenseShares) +
      Number(this.totalExpenseTaxAndDebts);

    this.totalAssets =
      Number(this.totalAssetCars) +
      Number(this.totalAssetFurniture) +
      Number(this.totalAssetHouse) +
      Number(this.totalAssetInterest) +
      Number(this.totalAssetInvestmentPropertys) +
      Number(this.totalAssetOtherAssets) +
      Number(this.totalAssetOtherVehicles) +
      Number(this.totalAssetShares);

    this.totalLiabilities =
      Number(this.totalLiabilityCars) +
      Number(this.totalLiabilityCreditCards) +
      Number(this.totalLiabilityHouse) +
      Number(this.totalLiabilityInvestmentAndLoans) +
      Number(this.totalLiabilityInvestmentPropertys) +
      Number(this.totalLiabilityOtherVehicles) +
      Number(this.totalLiabilityPersonalLoans) +
      Number(this.totalLiabilityShares) +
      Number(this.totalLiabilityTaxAndDebts);

    this.netIncome = this.totalIncomePos - this.totalExpenses;

    this.netAssets = this.totalAssets - this.totalLiabilities;

    this.chartIncomeExpenses = [
      {
        name: 'Net Position',
        value: this.totalIncomePos - this.totalExpenses,
      },
      {
        name: 'Expenses',
        value: this.totalExpenses,
      },
    ];

    this.chartAssetsLiabilities = [
      {
        name: 'Net Assets',
        value: this.totalAssets - this.totalLiabilities,
      },
      {
        name: 'Liabilities',
        value: this.totalLiabilities,
      },
    ];
  }

  uploadDocument() {
    const ref = RootAppComponent.dialog.open(PCIDocumentUploadComponent, {
      data: {
        contractID: this.contractID,
      },
      width: '650px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();

      this.documentTabVisible = false;
    });
  }
}
