import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material';
import { NgPipesModule } from 'ngx-pipes';
import { AuthenticationModule } from '../../core/authentication/authentication.module';
import { SharedModule } from '../../shared/shared.module';
import { MerchantLinkCreateEditComponent } from './merchant-link-create-edit/merchant-link-create-edit.component';
import { MerchantLinkLookupComponent } from './merchant-link-lookup/merchant-link-lookup.component';
import { MerchantLinkRoutingModule } from './shared/merchant-link-routing.module';
import { MerchantLinkService } from './shared/merchant-link.service';

@NgModule({
  declarations: [MerchantLinkCreateEditComponent, MerchantLinkLookupComponent],
  imports: [
    NgPipesModule,
    CommonModule,
    FormsModule,
    CdkTableModule,
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    MatSortModule,
    AuthenticationModule,
    MerchantLinkRoutingModule,
  ],
  exports: [MerchantLinkCreateEditComponent, MerchantLinkLookupComponent],
  providers: [MerchantLinkService],
})
export class MerchantLinkModule {}
