<div class="clearfix" *ngIf="isAdminOrPromoter == true">
  <div [hidden]="hideExtraComponents">
    <app-operator-filter
      id="part1"
      [sendNull]="true"
      [selectDateRange]="selectedRange"
      [displayMerchant]="isDisplayMerchant"
      [displayDateFrom]="true"
      [setAutoSelectMerchant]="true"
      [displayDateTo]="true"
      [displayDentist]="true"
      [selectedMerchant]="merchantID"
      (getMerchant)="getMerchant($event)"
      (getDateTo)="getDateTo($event)"
      (getDateFrom)="getDateFrom($event)"
      (search)="applySearch($event)"
    ></app-operator-filter>
  </div>

  <div class="row clearfix">
    <app-invitation-communication-list-global
      *ngIf="merchantID != 'none' && searchApplied == true"
      [purposeCode]="purposeCode"
      [pageSize]="pageSize"
      [staffID]="dentistID"
      [dateFrom]="dateFrom"
      [dateTo]="dateTo"
      [merchantID]="merchantID"
    ></app-invitation-communication-list-global>
  </div>
  <div class="row clearfix" [hidden]="hideExtraComponents">
    <div class="pleaseChoose" *ngIf="merchantID == 'none'" @ngIfAnimation>
      <p>
        <strong>No merchant selected</strong>
      </p>
      <img class="character_warning" />
      <p>
        Choose your desired merchant from the drop-down above to view their
        {{ "KEYWORD.patient" | translate }}s
      </p>
    </div>
  </div>
</div>

<div class="clearfix" *ngIf="isAdminOrPromoter == false">
  <div [hidden]="hideExtraComponents">
    <app-operator-filter
      id="part1"
      [sendNull]="true"
      [selectDateRange]="selectedRange"
      [displayMerchant]="false"
      [displayDateFrom]="true"
      [displayDateTo]="true"
      [displayTimePicker]="true"
      [displayDentist]="false"
      (getMerchant)="getMerchant($event)"
      (getDateTo)="getDateTo($event)"
      (getDateFrom)="getDateFrom($event)"
      (search)="applySearch($event)"
    ></app-operator-filter>
  </div>
  <app-invitation-communication-list
    id="part1"
    [purposeCode]="purposeCode"
    [staffID]="dentistID"
    [pageSize]="pageSize"
    [dateFrom]="dateFrom"
    [dateTo]="dateTo"
  >
  </app-invitation-communication-list>
</div>
