<div class="card-header primary-gradient-img clearfix inModal">
  <div class="row clearfix">
    <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModal()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="row titleArea clearfix hasIcon">
      <mat-icon class="white pull-left fas fa-file-medical"></mat-icon>
      <h2 class="summary-header rm-m">
        {{ title }}
      </h2>
    </div>
  </div>
</div>

<mat-dialog-content class="doc-create-edit">
  <div class="row clearfix">
    <div class="full-width row clearfix">
      <div class="well clearfix" *ngFor="
          let treatment of documents
            | WithoutArrayMultipleValue: 'TreatmentCategory_key':['']
            | WithoutArrayMultipleValue: 'TreatmentType_key':['']
        ">
        <app-brand-view-logo class="supplierIcon2 prod text-center"
          *ngIf="treatment['TreatmentBrand_key'] && !treatment['TreatmentProduct_key']"
                             [brandID]="treatment['TreatmentBrand_key']" [LastModified]="treatment['LastModified']">
        </app-brand-view-logo>

        <app-product-view-logo class="supplierIcon2 prod text-center" *ngIf="treatment['TreatmentProduct_key']"
                               [productID]="treatment['TreatmentProduct_key']"
                               [LastModified]="treatment['LastModified']">
        </app-product-view-logo>

        <div class="row flex mt full-width">
          <div class="block clearfix full-width" *ngIf="treatment['TreatmentCategory_key']">
            <p class="small rm-m">Category</p>
            <h4 class="reduce-marg">{{ treatmentUtil.getLabel(treatment['TreatmentCategory.Label']) }}</h4>
          </div>

          <div class="block clearfix full-width" *ngIf="treatment['TreatmentType_key']">
            <p class="small rm-m">Type</p>
            <h4 class="reduce-marg">{{ treatmentUtil.getLabel(treatment['TreatmentType.Label']) }}</h4>
          </div>
        </div>
        <div class="block clearfix full-width" *ngIf="treatment['TreatmentBrand_key']">
          <div class="row-content clearfix clicktext" matTooltip="Click to view Brand details">
            <p class="small rm-m">Brand</p>
            <h4 class="reduce-marg" (click)="viewBrand(treatment['TreatmentBrand_key'])">
              {{ treatmentUtil.getLabel(treatment['TreatmentBrand.Label']) }}
            </h4>
          </div>
        </div>

        <div class="block clearfix full-width" *ngIf="treatment['TreatmentProduct_key']">
          <div class="row-content clearfix clicktext" matTooltip="Click to view Product details">
            <p class="small rm-m">Product</p>
            <h4 class="reduce-marg" (click)="viewProduct(treatment['TreatmentProduct_key'])">
              {{ treatmentUtil.getLabel(treatment['TreatmentProduct.Label']) }}
            </h4>
          </div>
        </div>
      </div>

      <div class="clearfix mt" *ngIf="
          (documents
            | WithoutArrayMultipleValue: 'TreatmentCategory_key':['']
            | WithoutArrayMultipleValue: 'TreatmentType_key':['']
            | lengthOfArray) == 0
        ">
        <app-empty-list-message [title]="'No records found'" [message]="'There are no records in the database'">
        </app-empty-list-message>
      </div>
    </div>
  </div>

  <div class="button-row row text-center clearfix mt" *ngIf="isModal == true">
    <button class="text-center" (click)="closeModal()" mat-raised-button color="primary">Close</button>
  </div>
</mat-dialog-content>
