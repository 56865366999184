import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable()
export class EditorComponentService {
  constructor(private http: HttpClient) {}
  getEditorComponentList(payload = null, sessionType) {
    let url = 'editor-component';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'editor-component/global';
    }
    let params: HttpParams = new HttpParams();
    let options = {};
    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = {
        params,
      };
    }
    return this.http.get(`${environment.nodeUrl}/${url}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  getEditorComponentDetails(id, payload = null, sessionType) {
    let url = 'editor-component';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'editor-component/global';
    }
    let params: HttpParams = new HttpParams();
    let options = {};
    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = {
        params,
      };
    }
    return this.http.get(`${environment.nodeUrl}/${url}/${id}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  modifyEditorComponent(id, payload = null, sessionType) {
    let url = 'editor-component';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'editor-component/global';
    }
    return this.http.put(`${environment.nodeUrl}/${url}/${id}`, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  createEditorComponentDocument(payload = {}, sessionType) {
    let url = 'editor-component/document';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'editor-component/document/global';
    }
    return this.http.post(`${environment.nodeUrl}/${url}`, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  getEditorComponentDocumentList(payload = null, sessionType) {
    let url = 'editor-component/document';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'editor-component/document/global';
    }
    let params: HttpParams = new HttpParams();
    let options = {};
    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = {
        params,
      };
    }
    return this.http.get(`${environment.nodeUrl}/${url}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  createEditorComponent(payload = {}, sessionType) {
    let url = 'editor-component';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'editor-component/global';
    }
    return this.http.post(`${environment.nodeUrl}/${url}`, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  cloneEditorComponent(id, payload = {}, sessionType) {
    let url = 'editor-component/clone';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'editor-component/clone/global';
    }
    return this.http.post(`${environment.nodeUrl}/${url}/${id}`, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  deleteEditorComponentDocument(id, sessionType) {
    let url = 'editor-component/document';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'editor-component/document/global';
    }
    return this.http.delete(`${environment.nodeUrl}/${url}/${id}`, {}).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  deleteEditorComponent(id, sessionType) {
    let url = 'editor-component';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'editor-component/global';
    }
    return this.http.delete(`${environment.nodeUrl}/${url}/${id}`, {}).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  getEditorComponentStyle(ID) {
    return this.http
      .get(`${environment.nodeUrl}/editor-component/style/` + ID, {})
      .pipe(map((res: Response) => res['data']));
  }
  getEditorComponentContent(ID, sessionType) {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(`${environment.nodeUrl}/editor-component/style/global/` + ID, {})
        .pipe(map((res: Response) => res['data']));
    } else {
      return this.http
        .get(`${environment.nodeUrl}/editor-component/style/` + ID, {})
        .pipe(map((res: Response) => res['data']));
    }
  }
  getEditorComponentStyleStreamLink(ID) {
    if (ID) {
      return `${environment.nodeUrl}/editor-component/style/stream/` + ID;
    }
    return null;
  }
  getEditorComponentContentStreamLink(ID, sessionType) {
    let url = 'editor-component/content/stream';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'editor-component/content/stream/global';
    }
    if (ID) {
      return `${environment.nodeUrl}/${url}/` + ID;
    }
    return null;
  }
  modifyEditorComponentGroup(payload, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .put(`${environment.nodeUrl}/editor-component/group/global`, payload)
        .pipe(map((res: Response) => res['data']));
    } else {
      return this.http
        .put(`${environment.nodeUrl}/editor-component/group`, payload)
        .pipe(map((res: Response) => res['data']));
    }
  }
  deleteEditorComponentGroup(ids, sessionType): Observable<any> {
    let _ids;
    if (Array.isArray(ids) == true) {
      _ids = ids.join(',');
    } else if (typeof ids == 'string') {
      _ids = ids;
    }
    if (_ids) {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.http
          .delete(environment.nodeUrl + '/editor-component/group/global/' + _ids)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.http
          .delete(environment.nodeUrl + '/editor-component/group/' + _ids)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }
}
