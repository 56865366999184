<mat-tab-group class="demo-tab-group">
  <mat-tab label="Quotations">
    <div class="demo-tab-content">
      <div class="row clearfix">
        <h1 class="dataHeader rm-mt rm-mb pull-left">
          Quotations:
          <span class="badge" countUp></span>
        </h1>
        <button class="btn-small pull-right" id="newMessage" mat-raised-button color="accent">
          <mat-icon>add</mat-icon>
          New Quotation
        </button>
      </div>
      <br/>

      <div class="row clearfix">
        <div class="full width">
          <mat-card class="list-card">
            <mat-table class="list-table" #table matSort>
              <!--- Note that these columns can be defined in any order.
                                      The actual rendered columns are set as a property on the row definition" -->
              <!-- ID Column -->

              <!-- Progress Column -->
              <ng-container cdkColumnDef="Date">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Date</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <ng-container cdkColumnDef="QuotationStatus">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Quotation Status</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>

              <!-- Name Column -->
              <ng-container cdkColumnDef="ContractStatus">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Contract Status</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <!-- Name Column -->

              <!-- Color Column -->
              <ng-container cdkColumnDef="LoanAmount">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Loan Amount</mat-header-cell>
                <mat-cell *cdkCellDef="let row">{{ 00.0 | customCurrency }} </mat-cell>
              </ng-container>
              <!-- Color Column -->
              <ng-container cdkColumnDef="Product">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Product</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <ng-container cdkColumnDef="ProductType">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>ProductType</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>

              <!-- Action Button Column -->
              <ng-container cdkColumnDef="Actions">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Actions</mat-header-cell>
                <mat-cell *cdkCellDef="let row">
                  <div style="float: right">
                    <button [matMenuTriggerFor]="menu" mat-icon-button>
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item>
                        <mat-icon>add</mat-icon>
                        <span>Add New Quotation</span>
                      </button>
                    </mat-menu>
                  </div>
                </mat-cell>
              </ng-container>
              <mat-header-row *cdkHeaderRowDef="displayedColumnsQ"></mat-header-row>
              <mat-row *cdkRowDef="let row; columns: displayedColumnsQ"></mat-row>
            </mat-table>
          </mat-card>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Contracts">
    <div class="demo-tab-content">
      <div class="row clearfix">
        <h1 class="dataHeader rm-mt rm-mb pull-left">
          Contracts:
          <span class="badge" countUp></span>
        </h1>
      </div>
      <br/>

      <div class="row clearfix">
        <div class="full width">
          <mat-card class="list-card">
            <mat-table class="list-table" #table matSort>
              <!--- Note that these columns can be defined in any order.
                                      The actual rendered columns are set as a property on the row definition" -->
              <!-- ID Column -->

              <!-- Progress Column -->
              <ng-container cdkColumnDef="Date">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Date</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <!-- Name Column -->
              <ng-container cdkColumnDef="ContractStatus">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Contract Status</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <!-- Name Column -->

              <!-- Color Column -->
              <ng-container cdkColumnDef="LoanAmount">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Loan Amount</mat-header-cell>
                <mat-cell *cdkCellDef="let row">{{ 00.0 | customCurrency }} </mat-cell>
              </ng-container>
              <!-- Color Column -->
              <ng-container cdkColumnDef="Product">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Product</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <ng-container cdkColumnDef="ProductType">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Product Type</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>

              <!-- Action Button Column -->
              <ng-container cdkColumnDef="Actions">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Actions</mat-header-cell>
                <mat-cell *cdkCellDef="let row">
                  <div style="float: right">
                    <button [matMenuTriggerFor]="menu" mat-icon-button>
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu"></mat-menu>
                  </div>
                </mat-cell>
              </ng-container>
              <mat-header-row *cdkHeaderRowDef="displayedColumnsC"></mat-header-row>
              <mat-row *cdkRowDef="let row; columns: displayedColumnsC"></mat-row>
            </mat-table>
          </mat-card>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Payment Methods">
    <div class="demo-tab-content">
      <div class="row clearfix">
        <h1 class="dataHeader rm-mt rm-mb pull-left">
          Payment Methods:
          <span class="badge" countUp></span>
        </h1>
        <button class="btn-small pull-right" id="newMessage" mat-raised-button color="accent">
          <mat-icon>add</mat-icon>
          New payment Method
        </button>
      </div>
      <br/>
      <div class="row clearfix">
        <div class="full width">
          <mat-card class="list-card">
            <mat-table class="list-table" #table matSort>
              <!--- Note that these columns can be defined in any order.
                                    The actual rendered columns are set as a property on the row definition" -->
              <!-- ID Column -->

              <!-- Progress Column -->
              <ng-container cdkColumnDef="CardType">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Card Type</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <!-- Name Column -->
              <ng-container cdkColumnDef="BSB">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>BSB</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <!-- Name Column -->

              <!-- Color Column -->
              <ng-container cdkColumnDef="AccountNumber">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Account Number</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <!-- Color Column -->
              <ng-container cdkColumnDef="Expiry">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Expiry</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <ng-container cdkColumnDef="AccountHolder">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Account Holder</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <ng-container cdkColumnDef="Active">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Active</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>

              <!-- Action Button Column -->
              <ng-container cdkColumnDef="Actions">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Actions</mat-header-cell>
                <mat-cell *cdkCellDef="let row">
                  <div style="float: right">
                    <button [matMenuTriggerFor]="menu" mat-icon-button>
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item>
                        <mat-icon>add</mat-icon>
                        <span>Add New Payment Method</span>
                      </button>
                      <button mat-menu-item>
                        <mat-icon>delete</mat-icon>
                        <span>Delete Payment Method</span>
                      </button>
                    </mat-menu>
                  </div>
                </mat-cell>
              </ng-container>
              <mat-header-row *cdkHeaderRowDef="displayedColumnsPM"></mat-header-row>
              <mat-row *cdkRowDef="let row; columns: displayedColumnsPM"></mat-row>
            </mat-table>
          </mat-card>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Payment History">
    <div class="demo-tab-content">
      <div class="row clearfix">
        <h1 class="dataHeader rm-mt rm-mb pull-left">
          Payments:
          <span class="badge" countUp></span>
        </h1>
      </div>
      <br/>

      <div class="row clearfix">
        <div class="full width">
          <mat-card class="list-card">
            <mat-table class="list-table" #table matSort>
              <!--- Note that these columns can be defined in any order.
                                        The actual rendered columns are set as a property on the row definition" -->
              <!-- ID Column -->

              <!-- Progress Column -->
              <ng-container cdkColumnDef="DueDate">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Due Date</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <!-- Name Column -->
              <ng-container cdkColumnDef="Paid">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Paid</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <!-- Name Column -->

              <!-- Color Column -->
              <ng-container cdkColumnDef="Amount">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Amount</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <!-- Color Column -->
              <ng-container cdkColumnDef="Status">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Status</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <ng-container cdkColumnDef="Rejected">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Rejected</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <ng-container cdkColumnDef="Type">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Type</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>

              <!-- Action Button Column -->
              <ng-container cdkColumnDef="Actions">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Actions</mat-header-cell>
                <mat-cell *cdkCellDef="let row">
                  <div style="float: right">
                    <button [matMenuTriggerFor]="menu" mat-icon-button>
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu"></mat-menu>
                  </div>
                </mat-cell>
              </ng-container>
              <mat-header-row *cdkHeaderRowDef="displayedColumnsPH"></mat-header-row>
              <mat-row *cdkRowDef="let row; columns: displayedColumnsPH"></mat-row>
            </mat-table>
          </mat-card>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
