<div class="rel" *ngIf="customersDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>
<div [hidden]="customersDB.data.length == 0">
  <h1 class="dataHeader">
    Existing {{ "KEYWORD.patient" | translate | titlecase }} s" | translate }} Lookup
    <span class="badge">{{ dataSource.filteredData.length }}</span>
  </h1>

  <mat-card class="list-card">
    <div class="row clearfix searchArea primary-gradient-img">
      <div class="row clearfix flex">
        <div class="qtr-width flex">
          <mat-icon class="schIcon mt mr">search</mat-icon>
          <mat-select class="schSel full-width" [formControl]="searchVals" matInput placeholder="Add Filters" multiple>
            <mat-select-trigger>
              {{ searchVals.value ? searchVals.value[0] : '' }}
              <span class="additional-selection" *ngIf="searchVals.value?.length > 1">
                (+{{ searchVals.value.length - 1 }} others)
              </span>
            </mat-select-trigger>
            <mat-option *ngFor="let searchVal of searchValList" [value]="searchVal">{{ searchVal }}</mat-option>
          </mat-select>
        </div>

        <div class="clearfix flex animated fadeInDown" *ngIf="searchVals.value?.length > 0">
          <div *ngIf="searchVals.value?.indexOf('Date Of Birth') !== -1">
            <app-date-input
              class="full-width"
              [isClear]="true"
              [startView]="'multi-year'"
              [label]="'Date Of Birth'"
              (selectedDate)="setFilter($event, 'DateOfBirth')"
              name="DateOfBirth"
            ></app-date-input>
          </div>
          <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('First Name') !== -1">
            <mat-label>First Name</mat-label>
            <input
              class="clearfix"
              (input)="setFilter($event.target.value, 'FirstName')"
              name="FirstName"
              placeholder="First Name"
              matInput
            />
          </mat-form-field>
          <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Last Name') !== -1">
            <mat-label>Last Name</mat-label>
            <input
              class="clearfix"
              (input)="setFilter($event.target.value, 'Name')"
              name="Name"
              placeholder="Last Name"
              matInput
            />
          </mat-form-field>

          <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Mobile') !== -1">
            <mat-label>Mobile</mat-label>
            <input
              class="clearfix"
              (input)="setFilter($event.target.value, 'mobiles.Number')"
              name="Mobile"
              placeholder="Mobile "
              matInput
            />
          </mat-form-field>
          <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Email') !== -1">
            <mat-label>Email</mat-label>
            <input
              class="clearfix"
              (input)="setFilter($event.target.value, 'emails.Email')"
              name="Email"
              placeholder="Email "
              matInput
            />
          </mat-form-field>
        </div>
        <button
          class="pull-right"
          *ngIf="isModuleCustomerActive == true"
          [routerLink]="['/merchant/', { outlets: { page: ['customer-list'] } }]"
          [routerLinkActive]="['active']"
          mat-raised-button
        >
          Show Financed {{ "KEYWORD.patient" | translate | titlecase}}s
        </button>
      </div>
    </div>

    <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
      <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->
      <!-- ID Column -->
      <ng-container cdkColumnDef="FirstName">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> First Name </mat-header-cell>
        <mat-cell
          *cdkCellDef="let row"
          (click)="openQuickViewDialog(row['ID'])"
          matTooltip="{{ row.FirstName | lowercase | ucfirst }}"
        >
          {{ row.FirstName | lowercase | ucfirst }}
        </mat-cell>
      </ng-container>
      <!-- Progress Column -->
      <ng-container cdkColumnDef="Name">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Last Name </mat-header-cell>
        <mat-cell
          *cdkCellDef="let row"
          (click)="openQuickViewDialog(row['ID'])"
          matTooltip="{{ row.Name | lowercase | ucfirst }}"
        >
          {{ row.Name | lowercase | ucfirst }}</mat-cell
        >
      </ng-container>
      <!-- Name Column -->
      <ng-container cdkColumnDef="DateOfBirth">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Birth Date</mat-header-cell>
        <mat-cell
          *cdkCellDef="let row"
          (click)="openQuickViewDialog(row['ID'])"
          matTooltip="{{ row.DateOfBirth | customDate }}"
        >
          {{ row.DateOfBirth | customDate }}
        </mat-cell>
      </ng-container>

      <!-- Color Column -->
      <ng-container cdkColumnDef="mobile">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Phone Number</mat-header-cell>
        <mat-cell
          *cdkCellDef="let row"
          (click)="openQuickViewDialog(row['ID'])"
          matTooltip="{{ row['mobiles.Number'] | customPhone }}"
        >
          {{ row['mobiles.Number'] | customPhone }}
        </mat-cell>
      </ng-container>
      <!-- Color Column -->
      <ng-container cdkColumnDef="email">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Email Address</mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row['ID'])" matTooltip="{{ row['emails.Email'] }}">
          {{ row['emails.Email'] }}
        </mat-cell>
      </ng-container>
      <!-- Action Button Column -->
      <ng-container cdkColumnDef="Actions">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
        <mat-cell *cdkCellDef="let row">
          <div style="float: right">
            <button [matMenuTriggerFor]="menu" mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button (click)="openQuickViewDialog(row['ID'])" mat-menu-item>
                <mat-icon>portrait</mat-icon>
                <span>Quick View</span>
              </button>
              <!-- IF INVITATION EXISTS -->
              <button (click)="linkToInvitation(row)" mat-menu-item>
                <mat-icon>email</mat-icon>
                <span>New Invitation</span>
              </button>
            </mat-menu>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      #paginator
      [length]="dataSource.filteredData.length"
      [pageIndex]="0"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
    >
    </mat-paginator>
  </mat-card>
</div>

<div *ngIf="customersDB.data.length == 0 && customersDB.firstLoadEvent != false">
  <app-empty-list-message></app-empty-list-message>
</div>
