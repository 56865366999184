import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { ContractService } from '../../contract/shared/contract.service';
import { DocumentService } from '../../document/shared/document.service';
import { PersonalCircumstancesService } from '../shared/personal-circumstances.service';

@Component({
  selector: 'app-pci-document-upload',
  templateUrl: './personal-circumstance-document-upload.component.html',
  styleUrls: ['./personal-circumstance-document-upload.component.css'],
})
export class PCIDocumentUploadComponent implements OnInit {
  @Input()
  contractID;

  fileID;

  type;

  close = new EventEmitter();

  isModal = false;

  isAdminOrPromoter = false;

  documentGroups = [];

  isUploadCompleted = false;

  group;
  label;
  description;
  tags;

  constructor(
    private authenticationService: AuthenticationService,
    private documentService: DocumentService,
    private pcService: PersonalCircumstancesService,
    private contractService: ContractService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.contractID != null) {
        this.contractID = data.contractID;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isAdminOrPromoter = res;

      this.setup();
    });
  }

  setup() {
    const payload = {
      orderBy: 'Label',
      fields: 'ID,Label,TypeCode,DefaultProgramArea',
      // programAreaCode: "MER"
    };
    this.documentService.getDocumentTypeList(payload).subscribe((res) => {
      if (res) {
        this.documentGroups = res;
      }
    });
  }

  onCompleteAllFile(e) {
    if (e && e[0]) {
      this.fileID = e[0];
    }
  }

  checkUploadCompleted(e) {
    this.isUploadCompleted = e;
  }

  removeNewTreatmentDocument(e) {
    if (e && e._id == this.fileID) {
      this.fileID = null;
    }
  }

  closeModal() {
    this.close.emit(true);
  }

  createNewDoc() {
    const payload = {
      label: this.label,
      description: this.description,
      tags: this.tags,
      type: this.group,
      fileID: this.fileID,
    };

    this.contractService.uploadContractDocument(this.contractID, payload).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast('success', 'Upload file', 'You have successfully uploaded the file.');
        this.close.emit(true);
      }
    });
  }

  changeGroup() {
    // needs function to build
  }
}
