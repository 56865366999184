<div class="full-width">
  <div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
    <div class="row clearfix padded rel">
      <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
    </div>
  </div>

  <div class="row clearfix flex full-width" [hidden]="listDB.data.length == 0">
    <div class="full-width">
      <div class="row clearfix">
        <h2 class="dataHeader rm-mt">
          Tables
          <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
        </h2>

        <!-- <mat-slide-toggle class="clearfix" (change)="sliderChange($event.checked)">
        Expand
      </mat-slide-toggle> -->
      </div>
      <div class="row flex ctl flexwrap mb" @ngIfAnimation>
        <mat-card class="list-card full-width">
          <div class="row clearfix mb searchArea primary-gradient-img">
            <div class="row clearfix flex">
              <div class="full-width animated fadeInDown">
                <mat-form-field class="full-width clearfix" appearance="outline">
                  <mat-label>Table name</mat-label>
                  <input
                    class="clearfix"
                    (input)="setFilter($event.target.value, 'ID')"
                    name="TableName"
                    placeholder="Table name"
                    matInput
                  />
                </mat-form-field>
              </div>
            </div>
          </div>

          <mat-table class="list-table supplies" #table [dataSource]="dataSource" mat-table matSort>
            <ng-container cdkColumnDef="copy" sticky>
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> </mat-header-cell>
              <mat-cell *cdkCellDef="let row" matTooltip="{{ row['ID'] }}">
                <button class="position" (click)="copy(row['ID'])" mat-button mat-icon-button>
                  <mat-icon>file_copy</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="view" sticky>
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> </mat-header-cell>
              <mat-cell *cdkCellDef="let row" matTooltip="View Table Details">
                <button
                  class="position"
                  (click)="openQuickViewDialog(row)"
                  mat-button
                  mat-icon-button

                >
                  <mat-icon>visibility</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="name" sticky>
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> Name </mat-header-cell>
              <mat-cell *cdkCellDef="let row" (click)="select(row['ID'])" matTooltip="{{ row['ID'] }}">
                {{ row['ID'] }}
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="Deprecated">
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> Deprecated Name </mat-header-cell>
              <mat-cell
                *cdkCellDef="let row"
                (click)="select(row['ID'])"
                matTooltip="{{ row['DepreciatedTableName'] }}"
              >
                {{ row['DepreciatedTableName'] }}
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="Tags">
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> Tags </mat-header-cell>
              <mat-cell
                class="list-label"
                *cdkCellDef="let row"
                (click)="select(row['ID'])"
                matTooltip="{{ row['Tags'] }}"
              >
                {{ row['Tags'] }}
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="TableRetrievalCost">
              <mat-header-cell *cdkHeaderCellDef mat-sort-header>Cost</mat-header-cell>
              <mat-cell
                class="list-label"
                *cdkCellDef="let row"
                (click)="select(row['ID'])"
                matTooltip="The Retrieval Cost of this table is {{ row['TableRetrievalCost'] }}"
              >
                {{ row['TableRetrievalCost'] }}
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="Description">
              <th *matHeaderCellDef mat-header-cell mat-sort-header>Description</th>
              <td *matCellDef="let row" (click)="select(row['ID'])" mat-cell>
                {{ row['Description'] }}
              </td>
            </ng-container>

            <ng-container cdkColumnDef="Comments">
              <th *matHeaderCellDef mat-header-cell mat-sort-header>Comments</th>
              <td *matCellDef="let row" (click)="select(row['ID'])" mat-cell>
                {{ row['Comments'] }}
              </td>
            </ng-container>

            <ng-container cdkColumnDef="CanCreate">
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> Create </mat-header-cell>
              <mat-cell *cdkCellDef="let row" (click)="select(row['ID'])">
                <span
                  class="list-label smaller"
                  *ngIf="row['CanCreate'] == '1'"
                  matTooltip="You can Create a new record"
                  style="background-color: rgb(90, 186, 71)"
                >
                  <mat-icon>check</mat-icon>
                </span>
                <span
                  class="list-label smaller"
                  *ngIf="row['CanCreate'] != '1'"
                  matTooltip="You cannot Create a new record"
                  style="background-color: rgb(239, 71, 39)"
                >
                  <mat-icon>close</mat-icon>
                </span>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="CanModify">
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> Modify </mat-header-cell>
              <mat-cell *cdkCellDef="let row" (click)="select(row['ID'])">
                <span
                  class="list-label smaller"
                  *ngIf="row['CanModify'] == '1'"
                  matTooltip="You can Modify the records "
                  style="background-color: rgb(90, 186, 71)"
                >
                  <mat-icon>check</mat-icon>
                </span>
                <span
                  class="list-label smaller"
                  *ngIf="row['CanModify'] != '1'"
                  matTooltip="You cannot Modify the records"
                  style="background-color: rgb(239, 71, 39)"
                >
                  <mat-icon>close</mat-icon>
                </span>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="CanQuery">
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> Query </mat-header-cell>
              <mat-cell *cdkCellDef="let row" (click)="select(row['ID'])">
                <span
                  class="list-label smaller"
                  *ngIf="row['CanQuery'] == '1'"
                  matTooltip="You can Query this Table"
                  style="background-color: rgb(90, 186, 71)"
                >
                  <mat-icon>check</mat-icon>
                </span>
                <span
                  class="list-label smaller"
                  *ngIf="row['CanQuery'] != '1'"
                  matTooltip="You cannot Modify this Table"
                  style="background-color: rgb(239, 71, 39)"
                >
                  <mat-icon>close</mat-icon>
                </span>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="CanDelete">
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> Delete </mat-header-cell>
              <mat-cell *cdkCellDef="let row" (click)="select(row['ID'])">
                <span
                  class="list-label smaller"
                  *ngIf="row['CanDelete'] == '1'"
                  matTooltip="You can Delete a record "
                  style="background-color: rgb(90, 186, 71)"
                >
                  <mat-icon>check</mat-icon>
                </span>
                <span
                  class="list-label smaller"
                  *ngIf="row['CanDelete'] != '1'"
                  matTooltip="You cannot Delete a record "
                  style="background-color: rgb(239, 71, 39)"
                >
                  <mat-icon>close</mat-icon>
                </span>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="CanSort">
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> Sort</mat-header-cell>
              <mat-cell *cdkCellDef="let row" (click)="select(row['ID'])">
                <span
                  class="list-label smaller"
                  *ngIf="row['CanSort'] == '1'"
                  matTooltip="You can Sort records of this table"
                  style="background-color: rgb(90, 186, 71)"
                >
                  <mat-icon>check</mat-icon>
                </span>
                <span
                  class="list-label smaller"
                  *ngIf="row['CanSort'] != '1'"
                  matTooltip="You cannot Sort records of this"
                  style="background-color: rgb(239, 71, 39)"
                >
                  <mat-icon>close</mat-icon>
                </span>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="IsFunction">
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> Type </mat-header-cell>
              <mat-cell *cdkCellDef="let row" (click)="select(row['ID'])">
                <span *ngIf="row['IsFunction'] == '1'"> Function </span>
                <span *ngIf="row['IsFunction'] != '1'"> Table </span>
              </mat-cell>
            </ng-container>

            <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row
              *cdkRowDef="let row; columns: displayedColumns"
              [class.selected-row]="row && row.ID && row.ID == tableID"
            ></mat-row>
          </mat-table>
          <mat-paginator
            #paginator
            [length]="dataSource.filteredData.length"
            [pageIndex]="0"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
          >
          </mat-paginator>
        </mat-card>
      </div>
    </div>
    <div *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
      <app-empty-list-message></app-empty-list-message>
    </div>
  </div>
</div>
