/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./log-delete.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../../shared/components/date-input/date-input.component.ngfactory";
import * as i6 from "@angular/material/core";
import * as i7 from "../../../shared/types/data-adapter";
import * as i8 from "@angular/cdk/platform";
import * as i9 from "../../../shared/components/date-input/date-input.component";
import * as i10 from "@angular/router";
import * as i11 from "../../../shared/services/user-data.service";
import * as i12 from "../../authentication/shared/authentication.service";
import * as i13 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i14 from "@angular/material/button";
import * as i15 from "@angular/cdk/a11y";
import * as i16 from "@angular/platform-browser/animations";
import * as i17 from "./log-delete.component";
import * as i18 from "../shared/log.service";
var styles_LogDeleteComponent = [i0.styles];
var RenderType_LogDeleteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogDeleteComponent, data: {} });
export { RenderType_LogDeleteComponent as RenderType_LogDeleteComponent };
export function View_LogDeleteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 35, "div", [["class", "rel text-center cdia"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "xlarge-modal-icon accent-color mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["delete"])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "pull-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "button", [["class", "btn-close btn-clear mat-button abs-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(7, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["clear"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "h2", [["class", "sr-title rm-mb mt mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(10, 81920, null, 0, i4.MatDialogTitle, [[2, i4.MatDialogRef], i1.ElementRef, i4.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Clear logs"])), (_l()(), i1.ɵeld(12, 0, null, null, 14, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(13, 16384, null, 0, i4.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "p", [["class", "small"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Choose a data range to be cleared"])), (_l()(), i1.ɵeld(17, 0, null, null, 8, "div", [["class", "row clearfix flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 3, "app-date-input", [["class", "full-width margin-btm-large"], ["name", "fromDate"]], null, [[null, "selectedDate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedDate" === en)) {
        var pd_0 = (_co.selectDateFrom($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_DateInputComponent_0, i5.RenderType_DateInputComponent)), i1.ɵprd(4608, null, i6.DateAdapter, i7.AppDateAdapter, [[2, i6.MAT_DATE_LOCALE], i8.Platform]), i1.ɵdid(20, 638976, null, 0, i9.DateInputComponent, [i10.ActivatedRoute, i11.UserDataService, i12.AuthenticationService], { label: [0, "label"], maxDate: [1, "maxDate"], isClear: [2, "isClear"], date: [3, "date"] }, { selectedDate: "selectedDate" }), i1.ɵprd(256, null, i6.MAT_DATE_FORMATS, i9.ɵ0, []), (_l()(), i1.ɵeld(22, 0, null, null, 3, "app-date-input", [["class", "full-width margin-btm-large"], ["name", "toDate"]], null, [[null, "selectedDate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedDate" === en)) {
        var pd_0 = (_co.selectDateTo($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_DateInputComponent_0, i5.RenderType_DateInputComponent)), i1.ɵprd(4608, null, i6.DateAdapter, i7.AppDateAdapter, [[2, i6.MAT_DATE_LOCALE], i8.Platform]), i1.ɵdid(24, 638976, null, 0, i9.DateInputComponent, [i10.ActivatedRoute, i11.UserDataService, i12.AuthenticationService], { label: [0, "label"], maxDate: [1, "maxDate"], isClear: [2, "isClear"], date: [3, "date"] }, { selectedDate: "selectedDate" }), i1.ɵprd(256, null, i6.MAT_DATE_FORMATS, i9.ɵ0, []), (_l()(), i1.ɵeld(26, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 8, "mat-dialog-actions", [["class", "button-row text-center mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(28, 16384, null, 0, i4.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(29, 0, null, null, 3, "button", [["mat-raised-button", ""], ["matDialogClose", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 31).dialogRef.close(i1.ɵnov(_v, 31).dialogResult) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.closeEvent() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i13.View_MatButton_0, i13.RenderType_MatButton)), i1.ɵdid(30, 180224, null, 0, i14.MatButton, [i1.ElementRef, i15.FocusMonitor, [2, i16.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(31, 606208, null, 0, i4.MatDialogClose, [[2, i4.MatDialogRef], i1.ElementRef, i4.MatDialog], { _matDialogClose: [0, "_matDialogClose"] }, null), (_l()(), i1.ɵted(-1, 0, ["Close"])), (_l()(), i1.ɵeld(33, 0, null, null, 2, "button", [["color", "accent"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteLogs() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_MatButton_0, i13.RenderType_MatButton)), i1.ɵdid(34, 180224, null, 0, i14.MatButton, [i1.ElementRef, i15.FocusMonitor, [2, i16.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Remove"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); _ck(_v, 7, 0); _ck(_v, 10, 0); var currVal_5 = "Log from"; var currVal_6 = _co.dateTo; var currVal_7 = true; var currVal_8 = null; _ck(_v, 20, 0, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_9 = "Log to"; var currVal_10 = _co.maxDate; var currVal_11 = true; var currVal_12 = _co.dateToDefault; _ck(_v, 24, 0, currVal_9, currVal_10, currVal_11, currVal_12); var currVal_17 = ""; _ck(_v, 31, 0, currVal_17); var currVal_20 = "accent"; _ck(_v, 34, 0, currVal_20); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 7).inline; var currVal_3 = (((i1.ɵnov(_v, 7).color !== "primary") && (i1.ɵnov(_v, 7).color !== "accent")) && (i1.ɵnov(_v, 7).color !== "warn")); _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵnov(_v, 10).id; _ck(_v, 9, 0, currVal_4); var currVal_13 = (i1.ɵnov(_v, 30).disabled || null); var currVal_14 = (i1.ɵnov(_v, 30)._animationMode === "NoopAnimations"); var currVal_15 = (i1.ɵnov(_v, 31).ariaLabel || null); var currVal_16 = i1.ɵnov(_v, 31).type; _ck(_v, 29, 0, currVal_13, currVal_14, currVal_15, currVal_16); var currVal_18 = (i1.ɵnov(_v, 34).disabled || null); var currVal_19 = (i1.ɵnov(_v, 34)._animationMode === "NoopAnimations"); _ck(_v, 33, 0, currVal_18, currVal_19); }); }
export function View_LogDeleteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-log-delete", [], null, null, null, View_LogDeleteComponent_0, RenderType_LogDeleteComponent)), i1.ɵdid(1, 114688, null, 0, i17.LogDeleteComponent, [i18.LogService, [2, i4.MAT_DIALOG_DATA]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogDeleteComponentNgFactory = i1.ɵccf("app-log-delete", i17.LogDeleteComponent, View_LogDeleteComponent_Host_0, {}, { close: "close" }, []);
export { LogDeleteComponentNgFactory as LogDeleteComponentNgFactory };
