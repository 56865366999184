<div class="full-width clearfix row">
  <div
    class="clearfix paymentWell"
    *ngIf="payment"
    [class.paid]="payment['Status.Code'] == '1'"
    [class.pending]="payment['Status.Code'] == '0'"
    [class.rejected]="payment['RejectCount'] != '0'"
  >
    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <div class="row flex">
          <h4 class="sm-h4 rm-m">
            <mat-icon class="fas fa-money-bill-wave mr"></mat-icon>
            <strong>{{ payment['amounts.Total'] | customCurrency }}</strong> Due on
            {{ payment['Dates.Due'] | customDate }}
          </h4>
          <span class="chip">{{ payment['Status.Label'] }}</span>
        </div>
      </mat-expansion-panel-header>

      <div class="row clearfix full-width">
        <div class="row clearfix flex grey">
          <div class="row clearfix full-width">
            <div class="col-left">
              <label class="small">Processed</label>
              <p class="small rm-mt">
                <strong>{{ payment['Dates.Processed'] | customDate }}</strong>
              </p>
            </div>
          </div>
          <div class="row clearfix full-width">
            <div class="col-left">
              <label class="small">Type</label>
              <p class="small rm-mt">
                <strong>{{ payment['Type.Description'] }}</strong>
              </p>
            </div>
          </div>

          <div class="row clearfix full-width">
            <div class="col-left">
              <label class="small">Rejections</label>
              <p class="small rm-mt">
                <strong>{{ payment['RejectCount'] }}</strong>
              </p>
            </div>
          </div>
          <div class="row clearfix full-width">
            <div class="col-left">
              <label class="small">Method Type</label>
              <p class="small rm-mt">
                <strong>{{ payment['PaymentMethod.Type.Label'] }}</strong>
              </p>
            </div>
          </div>
          <div class="row clearfix full-width">
            <div class="col-left">
              <label class="small">Payment Method</label>
              <p class="small rm-mt">
                <strong>{{ payment['PaymentMethod.AccountNumber'] || payment['PaymentMethod.CardNumber'] }}</strong>
              </p>
            </div>
          </div>
        </div>
        <div class="row clearfix flex grey">
          <div class="row clearfix full-width">
            <div class="col-left">
              <label class="small">Amount Due</label>
              <p class="small rm-mt">
                <strong>{{ payment['amounts.Due'] | customCurrency }}</strong>
              </p>
            </div>
          </div>

          <div class="row clearfix full-width">
            <div class="col-left">
              <label class="small">Paid Directly</label>
              <p class="small rm-mt">
                <strong>{{ payment['amounts.PaidDirect'] | customCurrency }}</strong>
              </p>
            </div>
          </div>
          <div class="row clearfix full-width">
            <div class="col-left">
              <label class="small">Waived</label>
              <p class="small rm-mt">
                <strong>{{ payment['amounts.Waived'] | customCurrency }}</strong>
              </p>
            </div>
          </div>
          <div class="row clearfix full-width">
            <div class="col-left">
              <label class="small">Insurance</label>
              <p class="small rm-mt">
                <strong>{{ payment['Amounts.InsuranceContribution'] | customCurrency }}</strong>
              </p>
            </div>
          </div>
          <div class="row clearfix full-width">
            <div class="col-left">
              <label class="small">Rescheduled</label>
              <p class="small rm-mt">
                <strong>{{ payment['amounts.Rescheduled'] | customCurrency }}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
</div>
