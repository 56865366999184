<aw-wizard #wizard navBarLayout="large-filled-symbols">
  <aw-wizard-step stepTitle="Personal Information" navigationSymbol="1">
    <!-- Step 1  -->

    <div class="container" id="step-1">
      <form #createForm="ngForm">
        <mat-card class="card rm-mb">
          <h3 class="sm-h3 sr-title rm-mt">Add {{"KEYWORD.patient" | translate | titlecase}} Database Title</h3>
          <div class="row clearfix">
            <div class="half-width">
              <div class="column">
                <div class="icons-col">
                  <mat-icon>person</mat-icon>
                </div>
                <div class="row-content-col">
                  <div class="clearfix">
                    <div class="half-width">
                      <div class="col-left">
                        <!-- Gender Select -->
                        <mat-select
                          class="full-width"
                          [(ngModel)]="customer.gender"
                          name="gender"
                          placeholder="Gender"
                          required
                        >
                          <mat-option *ngFor="let gender of genders" [value]="gender.Code">
                            {{ gender.Label }}
                          </mat-option>
                        </mat-select>
                      </div>
                    </div>
                    <div class="half-width">
                      <div class="col-right">
                        <!-- Title Select -->
                        <mat-select
                          class="full-width"
                          [(ngModel)]="customer.title"
                          name="title"
                          placeholder="Title"
                          required
                        >
                          <mat-option *ngFor="let title of titles" [value]="title.Label">
                            {{ title.Label }}
                          </mat-option>
                        </mat-select>
                      </div>
                    </div>
                  </div>
                  <!-- First Name -->
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>First Name</mat-label>
                    <input
                      class="mat-block"
                      [(ngModel)]="customer.firstName"
                      name="firstname"
                      matInput
                      placeholder="First Name "
                      required
                    />
                  </mat-form-field>
                  <!-- Middle Name -->
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Middle Name</mat-label>
                    <input
                      class="mat-block"
                      [(ngModel)]="customer.midleName"
                      name="middlename"
                      matInput
                      placeholder="Middle Name"
                    />
                  </mat-form-field>
                  <!-- Surname -->
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Surname</mat-label>
                    <input
                      class="mat-block"
                      [(ngModel)]="customer.lastName"
                      name="lastname"
                      matInput
                      placeholder="Surname "
                      required
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="half-width">
              <div class="column">
                <div class="row">
                  <div class="icons-col">
                    <mat-icon>phone</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <!-- Mobile Number -->
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Mobile</mat-label>
                      <input
                        class="mat-block"
                        [(ngModel)]="customer.mobile"
                        name="mobile"
                        matInput
                        placeholder="Mobile "
                        pattern="([0-9]{10})"
                        required
                      />
                    </mat-form-field>
                    <!-- Home Phone -->
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Home Phone</mat-label>
                      <input
                        class="mat-block"
                        [(ngModel)]="customer.homePhone"
                        name="homePhone"
                        matInput
                        placeholder="Home Phone"
                        pattern="([0-9]{10})"
                      />
                    </mat-form-field>
                    <!-- Work Phone -->
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Work Phone</mat-label>
                      <input
                        class="mat-block"
                        [(ngModel)]="customer.workPhone"
                        name="workPhone"
                        matInput
                        placeholder="Work Phone"
                        pattern="([0-9]{10})"
                      />
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="icons-col">
                    <mat-icon>email</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <!-- Email Address -->
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Email</mat-label>
                      <input
                        class="mat-block"
                        [(ngModel)]="customer.email"
                        name="email"
                        matInput
                        placeholder="Email"
                        pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                        required
                      />
                    </mat-form-field>
                  </div>
                </div>
                <!-- referral -->
                <div class="row clearfix">
                  <div class="icons-col">
                    <mat-icon>record_voice_over</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <div class="row clearfix">
                      <div class="icons-col">
                        <mat-icon class="noTop">record_voice_over</mat-icon>
                      </div>
                      <div class="row-content-col">
                        <div class="row clearfix flex">
                          <mat-checkbox class="referral" [(ngModel)]="isReffered" name="isConsumerLogin" color="primary"
                          >Referred {{"KEYWORD.patient" | translate}} ?
                          </mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
        <div class="row clearfix">
          <div class="full-width step-button-row text-center">
            <button class="step-back" (click)="cancel()" mat-mini-fab color="primary">
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <button
              class="step-next"
              class="btn-large"
              [disabled]="!createForm.form.valid"
              (click)="createCustomer()"
              mat-raised-button
              color="accent"
            >
              Next
            </button>
          </div>
        </div>
      </form>
    </div>
  </aw-wizard-step>

  <aw-wizard-step stepTitle="Submit" navigationSymbol="2">
    <!-- Step 1  -->
    <div class="container" id="step-1">
      <div class="row clearfix">
        <mat-card class="card successful">
          <div class="row clearfix">
            <div class="full-width text-center">
              <h1 class="sm-h1 sr-title">Well Done</h1>
              <h3 class="sm-h3 rm-mt">{{ "KEYWORD.patient" | translate | titlecase }} record has been added"</h3>

              <div class="row clearfix">
                <div class="svg-box">
                  <svg class="circular green-stroke">
                    <circle class="path" cx="75" cy="75" r="50" fill="none" stroke-width="5" stroke-miterlimit="10" />
                  </svg>
                  <svg class="checkmark green-stroke">
                    <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                      <path class="checkmark__check" fill="none" d="M616.306,283.025L634.087,300.805L673.361,261.53" />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
      <div class="row clearfix">
        <div class="full-width text-center step-button-row">
          <button
            class="full-back"
            [routerLink]="['/merchant/', { outlets: { page: ['customer-list'] } }]"
            mat-raised-button
            color="primary"
          >
            <mat-icon>keyboard_arrow_left</mat-icon>
            {{"KEYWORD.patient" | translate | titlecase}} Overview
          </button>
        </div>
      </div>
    </div>
  </aw-wizard-step>
</aw-wizard>
