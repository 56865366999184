<div class="full-width" *ngIf="isLoaded==true">
  <mat-dialog-content [ngClass]="isModal === true ? 'max-content-fix' : 'no-modal-height'">
    <div *ngIf="isAdminOrPromoter == true">
      <div class="row clearfix">
        <app-settlement-list-global [merchantID]="merchantID" [contractID]="contractID" [viewOnly]="viewOnly"
          [isMaterialRequest]="isMaterialRequest" [statusCodes]="statusCodes" [status]="status" [pageSize]="pageSize"
          (hideMerchantSelection)="hideDetails($event)">
        </app-settlement-list-global>
      </div>
      <div class="row clearfix">
        <div class="pleaseChoose" *ngIf="merchantID == 'none'" @ngIfAnimation>
          <p>
            <strong>No {{ 'KEYWORD.practice' | translate }} Selected</strong>
          </p>
          <img class="character_warning" />
          <p>Choose Desired {{ 'KEYWORD.practice' | translate }}</p>
        </div>
      </div>
    </div>

    <div class="row full-width clearfix" *ngIf="isAdminOrPromoter == false">
      <app-settlement-list [status]="status" [pageSize]="pageSize" [_contractID]="contractID"
        [isMaterialRequest]="isMaterialRequest" [statusCodes]="statusCodes" [viewOnly]="viewOnly">
      </app-settlement-list>
    </div>

  </mat-dialog-content>

  <div class="full-width text-center" *ngIf="isModal==true">
    <hr>
    <button class="text-center btn-large " (click)="closeEvent()" mat-raised-button color="primary">
      Close
    </button>
  </div>
</div>
