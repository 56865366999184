import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { trigger, style, transition, animate, keyframes, query, stagger, state } from '@angular/animations';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { MAT_DIALOG_DATA, MatAutocomplete, MatAutocompleteSelectedEvent, MatChipInputEvent } from '@angular/material';
import { TagsService } from '../../tags/shared/tags.service';
import { CustomerProspectService } from '../shared/customerProspect.service';
import { Router } from '@angular/router';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomerProspectCreateComponent } from '../customer-prospect-create/customer-prospect-create.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { MessageGroupCardListComponent } from '../../message/message-group-card-list/message-group-card-list.component';

import * as _ from 'lodash';
@Component({
  selector: 'app-customer-prospect-list-server',
  templateUrl: './customer-prospect-list-server.component.html',
  styleUrls: ['./customer-prospect-list-server.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, width: '*' })),
      transition(':enter', [style({ opacity: 0, width: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, width: 0 }))),
    ]),
  ],
})
export class CustomerProspectListServerComponent implements OnInit {
  id;
  sessionType;
  merchantID;
  merchants;
  partEmail;
  partFirstName;
  partLastName;
  partMobile;
  partPhone;
  email;
  birthDay;
  currentMerchant;

  selectedRange = 'all';
  partIDFromMerchant;
  merchant;
  @ViewChild('tagInput', { static: false }) tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
  filtered = false;

  dateFrom: string;
  dateTo: string;
  util = new UtilsClass();
  tags = [];
  selectedTags = [];
  selectedTagsDefault = [];
  tag;
  isPromoterOrAdmin = false;
  panelOpenState = false;
  @Input()
  backLabel = 'Back';

  @Input()
  title = "Patient's Search";
  @Input()
  description = "You can search for Patient's using any (or all) of the field below:";

  _selectedTags = null;
  maxRecords = null;
  addressType = 'state';
  recordLength = ['10', '20', '50', '100', '200', '300', '400'];

  postCodeFrom;
  postCodeTo;

  suburb;
  state;
  states = [];
  suburbs = [];

  @Input()
  marketingConsent = null;


  @Input()
  marketingConsentEmail = null;

  @Input()
  manualSelection = false;

  @Input()
  displayEmailMobile = true;



  @Input()
  displayMarketingConsent = true;

  @Input()
  hasEmail = null;

  @Input()
  hasMobile = null;

  close = new EventEmitter();

  getRemoved = new EventEmitter();

  @Input()
  cards = [];

  @Input()
  currentCards = [];

  totalCards = [];

  isModal = false;

  @Output()
  getSelectedCards = new EventEmitter();

  constructor(
    private AuthenticationService: AuthenticationService,
    private tagsService: TagsService,

    private utilsService: UtilsService,
    private router: Router,
    private customerProspectService: CustomerProspectService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.merchantID) {
        this.merchantID = data.merchantID;

        this.isModal = true;
      }

      if (data.title) {
        this.title = data.title;

        this.isModal = true;
      }

      if (data.description) {
        this.description = data.description;

        this.isModal = true;
      }

      if (data.backLabel) {
        this.backLabel = data.backLabel;

        this.isModal = true;
      }

      if (data.manualSelection === true) {
        this.manualSelection = true;
        this.isModal = true;
      } else if (data.manualSelection === false) {
        this.manualSelection = false;
        this.isModal = true;
      }

      if (data.displayEmailMobile === true) {
        this.displayEmailMobile = true;
        this.isModal = true;
      } else if (data.displayEmailMobile === false) {
        this.displayEmailMobile = false;
        this.isModal = true;
      }
      if (data.marketingConsent === true) {
        this.marketingConsent = true;
        this.isModal = true;
      } else if (data.marketingConsent === false) {
        this.marketingConsent = false;
        this.isModal = true;
      }

      if (data.marketingConsentEmail === true) {
        this.marketingConsentEmail = true;
        this.isModal = true;
      } else if (data.marketingConsentEmail === false) {
        this.marketingConsentEmail = false;
        this.isModal = true;
      }



      if (data.displayMarketingConsent === true) {
        this.displayMarketingConsent = true;
        this.isModal = true;
      } else if (data.displayMarketingConsent === false) {
        this.displayMarketingConsent = false;
        this.isModal = true;
      }


      if (data.hasEmail === true) {
        this.hasEmail = true;
        this.isModal = true;
      } else if (data.hasEmail === false) {
        this.hasEmail = false;
        this.isModal = true;
      }

      if (data.hasMobile === true) {
        this.hasMobile = true;
        this.isModal = true;
      } else if (data.hasMobile === false) {
        this.hasMobile = false;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.AuthenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      this.utilsService.getStateList().subscribe((sl) => {
        if (sl) {
          this.states = sl;
        }
      });

      if (this.isPromoterOrAdmin == true) {
        this.AuthenticationService.getMerchantList({ fields: 'ID,TradingAs' }, this.isPromoterOrAdmin).subscribe(
          (r) => {
            if (r && r.length > 0) {
              this.merchants = r;
            }

            this.AuthenticationService.getCurrentPractice().subscribe((d) => {
              if (d && d.ID) {
                this.currentMerchant = d;
                this.merchant = d;
                this.merchantID = d.ID;

                this.tagsService
                  .getTagsList(
                    {
                      fields: 'Label',
                      staticAudience: false,
                      merchantID: this.merchantID,
                      orderBy: 'Label',
                    },
                    this.sessionType
                  )
                  .subscribe((res) => {
                    if (res) {
                      this.tags = res;
                    }
                  });
              }
            });
          }
        );
      } else {
        this.AuthenticationService.getCurrentPractice().subscribe((d) => {
          if (d && d.ID) {
            this.currentMerchant = d;
            this.merchantID = d.ID;

            this.merchant = d;

            this.tagsService
              .getTagsList(
                {
                  fields: 'Label',
                  staticAudience: false,
                  merchantID: this.merchantID,
                  orderBy: 'Label',
                },
                this.sessionType
              )
              .subscribe((res) => {
                if (res) {
                  this.tags = res;
                }
              });
          }
        });
      }
    });
  }

  changeMerchant() {
    if (this.merchantID) {
      const v = this.merchants.findIndex((item) => {
        if (item && item.ID && item.ID == this.merchantID) {
          return true;
        }

        return false;
      });

      if (v !== -1) {
        this.merchant = this.merchants[v];
      }

      this.tagsService
        .getTagsList(
          {
            fields: 'Label',
            staticAudience: false,
            merchantID: this.merchantID,
            orderBy: 'Label',
          },
          this.sessionType
        )
        .subscribe((res) => {
          if (res) {
            this.tags = res;
          }
        });
    }
  }

  selectDateToFilter(d) {
    this.birthDay = d;
  }

  getSelectedTags(e) {
    this.selectedTags = e || [];
  }

  removeTag(t: string): void {
    const index = this.selectedTags.indexOf(t);

    if (index >= 0) {
      this.selectedTags.splice(index, 1);
    }
  }

  tagChange(t) {
    if (t) {
      const index = this.selectedTags.indexOf(t);

      if (index == -1 && typeof t == 'string') {
        this.selectedTags.push(t);
      }

      this.tag = null;
    }
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    let value = event.value;

    if (value) {
      value = value.trim();
      const index = this.selectedTags.indexOf(value);

      if (index == -1 && typeof value == 'string') {
        this.selectedTags.push(value);
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.tag = null;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (event && event.option && event.option.viewValue) {
      const v = event.option.viewValue;
      const index = this.selectedTags.indexOf(v);

      if (index == -1 && typeof v == 'string') {
        this.selectedTags.push(v);
      }
      this.tagInput.nativeElement.value = '';
      this.tag = null;
    }
  }

  goToPatientList() {
    if (this.isModal == true) {
      this.filtered = true;
    } else {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['customer-prospect-list-overview'],
          },
        },
      ]);
    }
  }

  apply() {
    this._selectedTags = null;

    if (this.selectedTags && this.selectedTags.length > 0) {
      this._selectedTags = this.selectedTags.join(',');
    }

    if (this.panelOpenState === false) {
      this.dateFrom = null;
      this.dateTo = null;
      this.maxRecords = null;
      this.selectedRange = 'all';

      this.suburb = null;
      this.postCodeFrom = null;
      this.postCodeTo = null;
      this.marketingConsent = null;
      this.marketingConsentEmail = null;
      this.id = null;
      this.state = null;
      this.hasEmail = null;

      this.hasMobile = null;
    }

    if (this.addressType === 'suburb') {
      this.postCodeFrom = null;
      this.postCodeTo = null;
      this.state = null;
    } else if (this.addressType === 'state') {
      this.postCodeFrom = null;
      this.postCodeTo = null;
      this.suburb = null;
    } else if (this.addressType === 'postCodeRange') {
      this.state = null;
      this.suburb = null;
    } else {
      this.postCodeFrom = null;
      this.postCodeTo = null;
      this.state = null;
      this.suburb = null;
    }

    this.filtered = true;

    this.suburb = null;

    this.selectedTagsDefault = this.selectedTags || [];
  }
  setDateRangeFilter(range: { startDate: string; endDate: string }) {
    if (range && range.startDate && range.startDate.indexOf('1973') === -1) {
      this.dateFrom = this.util.EPdateFormat(range.startDate);
      this.dateTo = this.util.EPdateFormat(range.endDate);
    } else {
      this.dateFrom = null;
      this.dateTo = null;
    }
  }

  backSearch() {
    this._selectedTags = null;

    if (this.selectedTags && this.selectedTags.length > 0) {
      this._selectedTags = this.selectedTags.join(',');
    }
    this.filtered = false;
  }
  backSearch2() {
    this._selectedTags = null;

    if (this.selectedTags && this.selectedTags.length > 0) {
      this._selectedTags = this.selectedTags.join(',');
    }
    this.filtered = false;
    if (this.manualSelection == true) {
      this.cards = JSON.parse(JSON.stringify(this.currentCards));
      this.currentCards = [];
    }
  }

  openedPanel() {
    this.panelOpenState = true;
  }

  closedPanel() {
    this.panelOpenState = false;
  }

  getAddress(e) {
    if (e && e.suburb) {
      this.suburb = e.suburb;
    }
  }
  viewMerchant(id) {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: id,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }
  newPatientQuick() {
    const ref2 = RootAppComponent.dialog.open(CustomerProspectCreateComponent, {
      data: {
        merchantID: this.merchantID,
        isEmailRequired: true,
      },
      width: '850px',
    });

    ref2.componentInstance.close.subscribe((res) => {
      ref2.close();
    });
    ref2.componentInstance.getResult.subscribe((res) => {
      if (res && res.ID) {
        ref2.close();

        this.router.navigate([
          '/merchant',
          {
            outlets: {
              page: ['customer-profile', res.ID, 'patient'],
            },
          },
        ]);
      }
    });
  }

  closeEvent() {
    this.close.emit(true);
  }

  getSelectedCardsEvent(d) {
    this.currentCards = d;

    this.totalCards = this.cards.concat(this.currentCards);

    this.totalCards = _.uniqBy(this.totalCards, 'ID');
  }

  submit() {
    this.getSelectedCards.emit(this.totalCards);
  }

  getDetailedView(d) {
    this.closeEvent();
  }

  viewCards() {
    if (this.totalCards && this.totalCards.length > 0) {
      const ref2 = RootAppComponent.dialog.open(MessageGroupCardListComponent, {
        data: {
          merchantID: this.merchantID,
          cards: this.totalCards,
          isRemove: true,
        },

        width: '700px',
        panelClass: 'noCard',
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });
      ref2.componentInstance.getRemoved.subscribe((id) => {
        if (id) {
          let index = this.cards.findIndex((item) => {
            if (item && item.ID && item.ID == id) {
              return true;
            }
          });

          if (index != -1) {
            this.cards.splice(index, 1);
          }

          index = this.currentCards.findIndex((item) => {
            if (item && item.ID && item.ID == id) {
              return true;
            }
          });

          if (index != -1) {
            this.currentCards.splice(index, 1);
          }

          this.totalCards = this.cards.concat(this.currentCards);

          this.getRemoved.emit(id);
        }
      });
    }
  }
}
