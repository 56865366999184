import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { BlobViewComponent } from '../../../shared/components/blob-view/blob-view.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { LookupService } from '../../../shared/services/lookup.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { ContractViewComponent } from '../../contract/contract-view/contract-view.component';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { TreatmentService } from '../../treatment/shared/treatment.service';
import { SettlementService } from '../shared/settlement.service';

@Component({
  selector: 'app-settlement-request',
  templateUrl: './settlement-request.component.html',
  styleUrls: ['./settlement-request.component.css'],
})
export class SettlementRequestComponent implements OnInit {
  Settlement = null;
  @Input()
  settlementID = null;

  settlementAmount = null;

  @Output() close = new EventEmitter();
  @Output() result = new EventEmitter();
  @Output() updateItem = new EventEmitter();

  isModal = false;

  invoiceReference;
  invoiceDescription;
  invoiceFileID;
  invoiceDate = new Date();
  serviceType;
  serviceTypes = [];

  isNeedAuth = true;

  option = null;

  authorisationFormContent;

  authorisationFormTitle = 'Authorisation form unsigned';
  authorisationFormDescription = 'To proceed you must either download or print this form for the customer to sign';

  settings = Settings.global;
  timeLeft: number;
  interval;

  securityCodeID;
  securityCode;
  smsSent = false;
  emailSent = false;

  settlementfileID;

  isPreTreatment = false;

  isPromoterOrAdmin = false;

  invoiceDoc;

  constructor(
    private SettlementService: SettlementService,
    private activeRoute: ActivatedRoute,
    private lookupService: LookupService,
    private router: Router,
    private settlementService: SettlementService,
    private dialog: MatDialog,
    private treatmentService: TreatmentService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.settlementID = data;
      this.isModal = true;
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['settlementID']) {
        this.settlementID = params['settlementID'];
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        const payload = {
          selectDocument: true,
        };

        this.SettlementService.getSettlementDetails(this.settlementID, payload).subscribe((res) => {
          if (res) {
            this.Settlement = res;

            const docs = this.Settlement.document;

            if (docs && docs.length > 0) {
              this.invoiceDoc = docs.find((item) => item['Description'] == 'Settlement invoice details');
            }

            // this.lookupService.getLookup('simpleLookup', 'LoanPurpose').subscribe(res1 => {
            //   this.serviceTypes = res1;

            // });
            const payload = {
              fields: 'ID,Label',
              isActive: true,
            };
            this.treatmentService.getCategoryList(payload, this.isPromoterOrAdmin).subscribe((res) => {
              this.serviceTypes = res;
            });

            if (this.Settlement['SecurityCode_key'] || this.Settlement['ConsentedViaPaperForm'] == '1') {
              this.isNeedAuth = false;
            }
            this.settlementAmount = this.Settlement['Invoice.Amount'];

            if (this.Settlement['DrawDownGroupID']) {
              const payload = {
                groupID: this.Settlement['DrawDownGroupID'],
                fields: 'Invoice.Amount',
              };

              this.settlementService.getSettlementList(payload).subscribe((res) => {
                if (res) {
                  this.settlementAmount = res
                    .map((x) => x['Invoice.Amount'])
                    .reduce((a, b) => (Number(a) + Number(b)).toString());
                }
              });
            }
          }
        });
      });
    });
  }

  onCompleteAllInvoice(e) {
    if (e && e[0]) {
      this.invoiceFileID = e[0];
    }
  }

  removeInvoiceFile(e) {
    if (e && e == this.invoiceFileID) {
      this.invoiceFileID = null;
    }
  }

  selectInvoiceDate(e) {
    if (e) {
      this.invoiceDate = e;
    }
  }

  closeModalEvent() {
    this.close.emit(true);
  }

  openSettlementDialog() {
    this.close.emit(true);
    this.router.navigate(['/merchant', { outlets: { page: ['settlement-view', this.Settlement['ID']] } }]);
  }

  contractDetails() {
    this.close.emit(true);
    this.router.navigate(['/merchant', { outlets: { page: ['contract-view', this.Settlement['Contract_key']] } }]);
  }

  openMerchantDialog() {
    const ref = this.dialog.open(MerchantViewComponent, {
      data: this.Settlement['Merchant_key'],
      width: '650px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  openContractDialog() {
    const ref = this.dialog.open(ContractViewComponent, {
      data: {
        contractID: this.Settlement['Contract_key'],
        isSimple: true,
      },
      width: '900px',
      panelClass: 'noCard',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  createInvoice() {
    if (this.Settlement && this.Settlement['ID']) {
      const payload = {
        invoiceDate: this.invoiceDate,
        invoiceReference: this.invoiceReference,
        invoiceDescription: this.serviceType,
        fileID: this.invoiceFileID,
        isPreTreatment: this.isPreTreatment,
      };

      this.settlementService.createInvoiceSettlement(this.Settlement['ID'], payload).subscribe((res) => {
        if (res) {
          this.Settlement = res;

          NotifyAppComponent.displayToast('success', 'Success!', 'Your Settlement has been updated');

          this.result.emit(res);
        }
      });
    }
  }

  proceedWithForm() {
    const payload = {
      settlementAmount: this.settlementAmount,
    };

    if (this.Settlement['Insurance.Contribution']) {
      payload['healthFundAmount'] = this.Settlement['Insurance.Contribution'];
    }

    if (this.Settlement['Insurance.Provider']) {
      payload['healthFundName'] = this.Settlement['Insurance.Provider'];
    }

    if (this.Settlement['Insurance.Reference']) {
      payload['healthFundReference'] = this.Settlement['Insurance.Reference'];
    }

    this.settlementService.getAuthorisationForm(this.Settlement['Contract_key'], payload).subscribe((res) => {
      if (res) {
        this.authorisationFormContent = res;
        const ref = RootAppComponent.dialog.open(BlobViewComponent, {
          data: {
            labelProceed: 'Proceed',
            labelPrint: 'Print',
            labelDownload: 'Download',
            content: this.authorisationFormContent,
            title: this.authorisationFormTitle,
            description: this.authorisationFormDescription,
            isDownload: true,
            isPrint: true,
            isProceed: true,
            label: 'Authorisation form for ' + this.Settlement['Customer.CalculatedName'],
          },
          width: '800px',
          panelClass: 'ScrollNoCard',
        });

        ref.componentInstance.closeModal.subscribe((res) => {
          ref.close();
        });

        ref.componentInstance.proceed.subscribe((res) => {
          if (res == true) {
            ref.close();
            this.option = 'document';
          }
        });
      }
    });
  }

  proceedWithCode() {
    this.option = 'securityCode';

    const payload = {
      customerID: this.Settlement['Customer_key'],
      settlementID: this.Settlement['ID'],
      contractID: this.Settlement['Contract_key'],
    };

    this.settlementService.requestSettlementCode(payload).subscribe((res) => {
      if (res) {
        this.countDown();
        this.smsSent = true;

        this.emailSent = false;
        if (res && res['IsValid'] == '1') {
          this.emailSent = true;
        }

        this.securityCodeID = res.ID;
      }
    });
  }

  goBackChooseAuth() {
    this.option = null;
  }

  countDown() {
    this.timeLeft = 60;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  sendCodeAgain() {
    const payload = {
      customerID: this.Settlement['Customer_key'],
      settlementID: this.Settlement['ID'],
      contractID: this.Settlement['Contract_key'],
    };
    this.settlementService.requestSettlementCode(payload).subscribe((res) => {
      if (res) {
        this.countDown();
        this.smsSent = true;

        this.emailSent = false;
        if (res && res['Send.Email'] == '1') {
          this.emailSent = true;
        }
        this.securityCodeID = res.ID;
        NotifyAppComponent.displayToast('success', 'Successful operation', 'The new code has been sent to you');
      }
    });
  }

  validateSettlementCode() {
    if (this.Settlement && this.securityCodeID && this.securityCode) {
      const payload = {
        settlementID: this.Settlement['ID'],
        securityCodeID: this.securityCodeID,
        securityCode: this.securityCode,
        cardID: this.Settlement['Customer_key'],
      };

      this.settlementService.validateSettlementCode(payload).subscribe((res) => {
        if (res) {
          this.isNeedAuth = false;
          NotifyAppComponent.displayToast(
            'success',
            'Successful operation',
            'The settlement has been authorised by security code'
          );
          this.updateItem.emit(res);
        }
      });
    }
  }

  updateLater() {
    if (this.Settlement && this.Settlement['ID']) {
      const confirm = new ConfirmDialog(
        'warning',
        'Are you sure ?',
        'The settlement will be saved as a draft until you enter the invoice details.<br><br>No Invoice information will be saved if choose <strong>Save as Draft</strong>',
        'Continue settlement request',
        'Save as draft'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation == true) {
          ref.close();
          this.close.emit(true);
        } else {
          ref.close();
        }
      });
    }
  }

  onCompleteAllForm(e) {
    if (e && e[0]) {
      this.settlementfileID = e[0];
    }
  }

  removeAuthorisationForm(e) {
    if (e && e == this.settlementfileID) {
      this.settlementfileID = null;
    }
  }

  requestSettlementByForm() {
    if (this.settlementfileID) {
      const payload = {
        fileID: this.settlementfileID,
      };

      this.settlementService.requestSettlementByForm(this.Settlement.ID, payload).subscribe((res) => {
        if (res) {
          this.isNeedAuth = false;
          NotifyAppComponent.displayToast(
            'success',
            'Successful operation',
            'The settlment has been authorised by form'
          );
          this.updateItem.emit(res);
        }
      });
    }
  }

  isPreTreatmentToggle(e) {
    this.isPreTreatment = e;
    if (e == true) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        'Information',
        'By selecting this option you are confirming this settlement will be to pay for materials or ' +
          'services prior to treatment taking place and an invoice being produced for the patient. <br><br> If you ' +
          'have an invoice for the settlement select <strong>No</strong>, otherwise select <strong>Yes</strong> to continue.',
        'No',
        'Yes'
      );

      const ref = this.dialog.open(ConfirmDialogComponent, {
        data: confirm,
        width: '650px',
      });

      ref.componentInstance.onConfirm.subscribe((res) => {
        if (res == true) {
          this.invoiceReference = 'PreTreatment';
          this.invoiceFileID = null;

          ref.close();
        } else {
          ref.close();
          this.isPreTreatment = false;
        }
      });

      ref.componentInstance.closeEvent.subscribe((res) => {
        ref.close();
        this.isPreTreatment = false;
      });

      ref.backdropClick().subscribe((res) => {
        ref.close();
        this.isPreTreatment = false;
      });
    } else {
      this.invoiceReference = null;
    }
  }

  reMarkRequest() {
    if (this.invoiceDoc) {
      const payload = {
        documentID: this.invoiceDoc.ID,
        fileID: this.invoiceFileID,
      };

      this.settlementService.reMarkRequest(this.Settlement.ID, this.isPromoterOrAdmin, payload).subscribe((res) => {
        if (res) {
          NotifyAppComponent.displayToast(
            'success',
            'Re-upload document',
            'Your document has been sccussfully uploaded'
          );

          this.router.navigate(['/merchant', { outlets: { page: ['settlement-customer-list-overview'] } }]);
        }
      });
    }
  }
}
