<form #smsForm="ngForm">
  <div class="card-header primary-gradient-img clearfix inModal" *ngIf="isModal == true">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row clearfix">
        <mat-icon class="pull-left lock-icon-button">lock</mat-icon>
        <h2 class="summary-header rm-m">
          <!-- {{ beneficiary.CalculatedName}} -->
          {{ title }}
          <span class="subLabel"></span>
        </h2>
        <p>{{ subTitle }}</p>
      </div>
    </div>
  </div>

  <mat-dialog-content class="white-background">
    <div class="row clearfix text-center">
      <p *ngIf="isModal == false">{{ subTitle }}</p>
      <p class="small">The code will be valid for {{ validMinutes || 5 }} min(s)</p>

      <div class="clearfix">
        <mat-form-field class="full-width large-input rm-mb" appearance="outline">
          <mat-label>Enter authorisation code here</mat-label>
          <input class="mat-block" id="settlement-input" [(ngModel)]="securityCode" matInput name="smsCode" required/>
        </mat-form-field>
      </div>
      <div class="row clearfix text-center">
        <p class="small rm-mb">Didn't receive a message?</p>

        <p class="small rm-mt mb" *ngIf="timeLeft != 0">
          Wait <strong>{{ timeLeft }}</strong> seconds and try again.
        </p>

        <button class="mb sm-mt" *ngIf="timeLeft <= 0" (click)="sendCodeEvent()" mat-stroked-button>
          Send code again
        </button>
      </div>

      <div class="row clearfix text-center">
        <mat-checkbox
          class="clearfix"
          *ngIf="displayRememberMe == true"
          [(ngModel)]="rememberMe"
          (change)="changeRememberMe($event)"
          name="rememberMe"
        >Remember me
        </mat-checkbox>
      </div>
    </div>

    <div class="text-center mt clearfix mb">
      <button
        class="btn-large clearfix ml mr"
        *ngIf="isModal == true"
        (click)="closeEvent()"
        mat-raised-button
        color="primary"
      >
        Close
      </button>

      <button
        class="btn-large clearfix ml mr"
        [disabled]="!securityCode"
        (click)="submitEvent()"
        mat-raised-button
        color="accent"
      >
        Submit
      </button>
    </div>
  </mat-dialog-content>
</form>
