import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { map } from 'rxjs/operators';
import { LookupClass } from '../../../shared/types/lookupClass';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { InvitationService } from '../shared/services/invitation.service';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';

/**
 * @title Feature-rich data table
 */
@Component({
  selector: 'app-invitation-list-draft',
  templateUrl: './invitation-list-draft.component.html',
  styleUrls: ['./invitation-list-draft.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class InvitationListDraftComponent implements OnInit {
  @Input()
  dateFrom;

  @Input()
  merchantID;

  @Input()
  dateTo;

  invitationID = null;
  public displayInvitationView = false;
  @Input()
  pageSize = 200;
  pageSizeOptions = [50, 100, 150, 200];

  utils = new UtilsClass();

  lookup = new LookupClass();
  displayedColumns = ['Customer', 'Date', 'Email', 'Mobile', 'Amount', 'Actions'];

  searchVals = new FormControl();

  searchValList = ['Date', 'First Name', 'Last Name', 'Email', 'Mobile'];

  filters = [];
  selectedValue = '';
  selectedField: any = {
    field: '',
    table: '',
    category: '',
    value: '',
  };

  filteredSize = null;

  context = Settings.global['context'];

  isAdminOrPromoter = false;

  public invitationsDB: LoadInv | null;
  dataSource: InvitationDataSource | null;
  showFirstFilter = false;
  showSecondFilter = false;
  showDateFilter = false;
  showInput = true;
  dateNow;

  destroyEvent = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private invitationService: InvitationService,
    private UtilsService: UtilsService,
    private customDate: CustomDatePipe
  ) {}

  ngOnInit() {
    this.UtilsService.getServerDate().subscribe((res) => {
      this.dateNow = res;
    });

    this.invitationsDB = new LoadInv(
      this.invitationService,
      this.destroyEvent,
      this.authenticationService,
      this.merchantID,
      this.dateFrom,
      this.dateTo
    );
    this.dataSource = new InvitationDataSource(this.invitationsDB, this.paginator, this.sort);
  }

  displayCulomn(index) {
    if (this.displayedColumns.indexOf(index) != -1) {
      return true;
    } else {
      return false;
    }
  }

  continue(id) {
    const confirm = new ConfirmDialog(
      'fas fa-info',
      'Continue Invitation',
      'You are about to continue this invitation',
      'Abort',
      'Confirm'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
      width: '650px',
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        this.continueInvitation(id);
        ref.close();
      } else {
        ref.close();
      }
    });
  }

  continueInvitation(id) {
    if (this.context == 'retail') {
      this.router.navigate(['/merchant', { outlets: { page: ['lending-invitation-create', id] } }]);
    } else {
      this.router.navigate(['/merchant', { outlets: { page: ['invitation-create', id] } }]);
    }
  }

  deleteInvitation(id) {
    const confirmDialog = new ConfirmDialog(
      'delete',
      'Remove Invitation?',
      '<p>You are about to remove this invitation.</p> ',
      'No',
      'Yes, archive'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        this.invitationService.delteUserData(id).subscribe((res) => {
          if (res == true) {
            this.dataSource.removeItem = id;

            NotifyAppComponent.displayToast(
              'success',
              'Successfull operation',
              'The file has been successfully removed'
            );
            ref.close();
          }
        });

        ref.close();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.UtilsService.getServerDate().subscribe((res) => {
      this.dateNow = res;
    });

    this.invitationsDB = new LoadInv(
      this.invitationService,
      this.destroyEvent,
      this.authenticationService,
      this.merchantID,
      this.dateFrom,
      this.dateTo
    );
    this.dataSource = new InvitationDataSource(this.invitationsDB, this.paginator, this.sort);
  }

  setFilter(event, field) {
    let filter;
    if (typeof event == 'object' && event != null) {
      const v = this.customDate.transform(event, Settings.global['dateFormat']);
      filter = {
        field,
        value: v,
      };
    } else if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  onClickMore() {}

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadInv implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public invitations: any[];
  isAdminOrPromoter = false;
  public count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  isDestoyed = false;

  util = new UtilsClass();
  serviceRef;

  constructor(
    private invitationService: InvitationService,
    private destroyEvent,
    private authenticationService: AuthenticationService,
    private merchantID,
    private dateFrom,
    private dateTo
  ) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });
    const payload = {
      merchantID: this.merchantID,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      section: 0,
    };

    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isAdminOrPromoter = res;

      this.serviceRef = this.invitationService
        .getDraftInvitations(payload, this.isAdminOrPromoter)
        .subscribe((res1) => {
          if (res1) {
            this.count = res1.length;

            this.invitations = res1;
            this.dataChange.next(this.invitations.slice());
            this.firstLoad = true;
            const innerFunction = (section) => {
              section = section + 1;
              UtilsClass.loadingDataSection(section);
              payload.section = section;

              this.serviceRef = this.invitationService
                .getDraftInvitations(payload, this.isAdminOrPromoter)
                .subscribe((res) => {
                  if (res) {
                    if (res.length > 0) {
                      this.count = res.length;
                      this.invitations = this.invitations.concat(res);

                      this.dataChange.next(this.invitations);

                      if (this.isDestoyed != true) {
                        innerFunction(section);
                      }
                    } else {
                      return true;
                    }
                  } else {
                    return false;
                  }
                });
              UtilsClass.stopLoading();
            };

            if (this.isDestoyed != true) {
              innerFunction(1);
            }
          }
        });
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class InvitationDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  set removeItem(id) {
    const data = this._invDatabase.data.filter((row) => row._id != id);
    this._invDatabase.dataChange.next(data);
  }

  filteredData: any[] = [];

  constructor(private _invDatabase: any, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._invDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._invDatabase.data.slice().filter((item: any) => {
          let f = '';

          f = item[this.field] || '';

          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'Date':
          [propertyA, propertyB] = [
            moment(a.content.user.dateCreated).toDate().getTime(),
            moment(b.content.user.dateCreated).toDate().getTime(),
          ];
          break;

        case 'Merchant':
          [propertyA, propertyB] = [a.content.merchantIDLabel, b.content.merchantIDLabel];
          break;

        case 'Customer':
          [propertyA, propertyB] = [a.content.patient.firstName, b.content.patient.firstName];
          break;
        case 'Email':
          [propertyA, propertyB] = [a.content.patient.email, b.content.patient.email];
          break;

        case 'Mobile':
          [propertyA, propertyB] = [a.content.patient.mobile, b.content.patient.mobile];
          break;

        case 'Amount':
          [propertyA, propertyB] = [a.content.totalTreatmentValue || 0, b.content.totalTreatmentValue || 0];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
