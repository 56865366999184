/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./document-list-mini.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "../../../shared/components/ep-document-list-mini/ep-document-list-mini.component.ngfactory";
import * as i6 from "../../../shared/components/ep-document-list-mini/ep-document-list-mini.component";
import * as i7 from "@angular/material/dialog";
import * as i8 from "../../../shared/services/utils.service";
import * as i9 from "../../../shared/components/empty-list-message/empty-list-message.component.ngfactory";
import * as i10 from "../../../shared/components/empty-list-message/empty-list-message.component";
import * as i11 from "@angular/common";
import * as i12 from "./document-list-mini.component";
import * as i13 from "../../../core/authentication/shared/authentication.service";
var styles_DocumentListMiniComponent = [i0.styles];
var RenderType_DocumentListMiniComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DocumentListMiniComponent, data: {} });
export { RenderType_DocumentListMiniComponent as RenderType_DocumentListMiniComponent };
function View_DocumentListMiniComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-card", [["class", "treatment mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [[2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "app-ep-document-list-mini", [], null, null, null, i5.View_EpDocumentListMiniComponent_0, i5.RenderType_EpDocumentListMiniComponent)), i1.ɵdid(3, 114688, null, 0, i6.EpDocumentListMiniComponent, [i7.MatDialog, i8.UtilsService], { documents: [0, "documents"], title: [1, "title"], isAction: [2, "isAction"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.documents; var currVal_2 = _co.title; var currVal_3 = false; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0); }); }
function View_DocumentListMiniComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-empty-list-message", [], null, null, null, i9.View_EmptyListMessageComponent_0, i9.RenderType_EmptyListMessageComponent)), i1.ɵdid(2, 114688, null, 0, i10.EmptyListMessageComponent, [], { title: [0, "title"], message: [1, "message"] }, null)], function (_ck, _v) { var currVal_0 = "No Documents Found"; var currVal_1 = "There is no documents to show"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_DocumentListMiniComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "full-width clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentListMiniComponent_1)), i1.ɵdid(2, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentListMiniComponent_2)), i1.ɵdid(4, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.documents && (_co.documents.length > 0)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.documents && (_co.documents.length <= 0)); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_DocumentListMiniComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-document-list-mini", [], null, null, null, View_DocumentListMiniComponent_0, RenderType_DocumentListMiniComponent)), i1.ɵdid(1, 114688, null, 0, i12.DocumentListMiniComponent, [i8.UtilsService, i13.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DocumentListMiniComponentNgFactory = i1.ɵccf("app-document-list-mini", i12.DocumentListMiniComponent, View_DocumentListMiniComponent_Host_0, { cardID: "cardID", title: "title" }, {}, []);
export { DocumentListMiniComponentNgFactory as DocumentListMiniComponentNgFactory };
