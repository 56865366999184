import { Component, EventEmitter, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

import { TasksService } from '../shared/tasks.service';

@Component({
  selector: 'app-task-manager-create-edit',
  templateUrl: './task-manager-create-edit.component.html',
  styleUrls: ['./task-manager-create-edit.component.css'],
})
export class TaskManagerCreateEditComponent implements OnInit {
  isModal = true;
  close = new EventEmitter();

  getResult = new EventEmitter();

  isPromoterOrAdmin = false;

  sessionType;
  label;
  description;
  task;
  taskID;
  type;
  thumbnailFileID;
  action;
  title = 'File';

  marketingDescriptions = [null];
  _marketingDescriptions = [null];

  allowedImageExtensions = ['jpeg', 'jpg', 'png','heic' , 'gif', 'tiff', 'bmp', 'ico'];
  utils = new UtilsClass();
  fileLink;
  thumbnailLink;

  priorities = [];

  priorityCode;

  actionCode;
  actions = [
    {
      code: 'others',
      label: 'Others',
    },
  ];

  constructor(
    private lookupService: LookupService,
    private TasksService: TasksService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.taskID) {
        this.taskID = data.taskID;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((r) => {
      this.sessionType = r;

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        this.setup();
      });
    });
  }

  setup() {
    this.lookupService.getLookup('CodeLookup', 'Task_Priority').subscribe((res) => {
      this.priorities = res;
    });

    if (this.taskID) {
      this.TasksService.getPracticeTaskDetails(this.taskID).subscribe((r) => {
        if (r) {
          this.task = r;
          this.buildItem();
        }
      });
    }
  }

  buildItem() {
    if (this.task && this.task.ID) {
      this.taskID = this.task.ID;

      if (this.task['Name']) {
        this.label = this.task['Name'];
      }
      if (this.task['Type']) {
        this.type = this.task['Type'];
      }
      if (this.task['Action']) {
        const index = this.actions.findIndex((item) => {
          if (item && item.code && item.code === this.task['Action']) {
            return true;
          }
          return false;
        });

        if (index !== 1) {
          this.action = this.task['Action'];
          this.actionCode = this.task['Action'];
        } else {
          this.actionCode = 'others';
          this.action = this.task['Action'];
        }
      }

      if (this.task['Description']) {
        this.description = this.task['Description'];
      }

      if (this.task['Priority_Code']) {
        this.priorityCode = this.task['Priority_Code'];
      }
      if (this.task['Marketing_Description']) {
        this.marketingDescriptions = this.task['Marketing_Description'].split('||');
        this._marketingDescriptions = this.task['Marketing_Description'].split('||');
      }

      if (this.task['Document_key']) {
        this.thumbnailLink = this.TasksService.getPracticeTaskPicture(this.taskID);
      }
    }
  }

  addMarketingDescription() {
    this.marketingDescriptions.push(null);
    this._marketingDescriptions.push(null);
  }

  removeMarketingDescription(index) {
    this.marketingDescriptions.splice(index, 1);
    this._marketingDescriptions.splice(index, 1);
  }

  changeMarketing(index, value) {
    this._marketingDescriptions[index] = value;
  }

  onCompleteThumbnail(fileID) {
    if (fileID && fileID[0]) {
      this.thumbnailFileID = fileID[0];

      this.thumbnailLink = null;
      setTimeout(() => {
        this.thumbnailLink = this.utils.getFileLink(this.thumbnailFileID);
      }, 200);
    } else {
      this.thumbnailLink = null;
    }
  }

  save() {
    const p = {
      thumbnailFileID: this.thumbnailFileID,
      type: this.type,
      name: this.label,
      description: this.description,
      marketingDescriptions: this.marketingDescriptions,
      action: this.action,
    };

    if (this.taskID) {
      this.TasksService.editTasks(this.taskID, p).subscribe((r) => {
        if (r) {
          NotifyAppComponent.displayToast('success', 'success', 'Task is created');

          this.getResult.emit(r);
        }
      });
    } else {
      this.TasksService.createTasks(p).subscribe((r) => {
        if (r) {
          NotifyAppComponent.displayToast('success', 'success', 'Task is modified');

          this.getResult.emit(r);
        }
      });
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
}
