<div class="ba-area mt full-width clearfix row">
  <form [formGroup]="paymentForm">
    <span class="title" *ngIf="title">{{ title }}</span>
    <div class="row clearfix">
      <mat-form-field class="full-width" appearance="outline">
        <input
          id="cc-name"
          [required]="requiredLabel"
          (change)="sendResult()"
          type="text"
          placeholder="Name on Card"
          formControlName="cardName"
          matInput
        />
      </mat-form-field>
    </div>
    <div class="row clearfix">
      <mat-form-field class="full-width payCreditCard" appearance="outline">
        <mat-label>Credit Card Number</mat-label>

        <mat-icon matPrefix>credit_card</mat-icon>

        <input
          id="cc-number"
          [required]="requiredLabel"
          (change)="sendResult()"
          type="tel"
          autocomplete="cc-number"
          placeholder="Credit Card Number"
          formControlName="cardNumber"
          matInput
          ccNumber
        />
      </mat-form-field>
    </div>
    <div class="row clearfix flex">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Expiry Date</mat-label>

        <input
          id="cc-exp-date"
          [required]="requiredLabel"
          (change)="sendResult()"
          type="tel"
          autocomplete="cc-exp"
          placeholder="Expiry Date"
          formControlName="cardExpireDate"
          matInput
          ccExp
        />
      </mat-form-field>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>What's this?</mat-label>
        <button (click)="changeShowInfo()" mat-button matSuffix matTooltip="What's this?">
          <mat-icon>help</mat-icon>
        </button>

        <input
          id="cc-cvc"
          [required]="requiredLabel"
          (change)="sendResult()"
          type="tel"
          autocomplete="cc-cvc"
          placeholder="CVC Number"
          formControlName="cardCVS"
          matInput
          ccCVC
        />
      </mat-form-field>
    </div>
    <div class="row clearfix animded fadeInDown" *ngIf="showCvvInfo === true">
      <div class="half-width">&nbsp;</div>
      <div class="half-width cvvInfo">
        <img
          src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/card-verification-value.png"
        />
        <p>
          <strong>What's this?</strong>
        </p>
        <p>
          CVV is an anti-fraud security feature to help verify that you are in possession of your credit card. For
          Visa/Mastercard, the three-digit CVV number is printed on the signature panel on the back of the card
          immediately after the card's account number.
        </p>
      </div>
    </div>
  </form>
</div>

<!-- <hr>
<p class="small securePay pull-right">
  <mat-icon>lock</mat-icon> Secure Payments with
  <strong>
    <em>ePremium</em>&trade;</strong>
</p> -->
