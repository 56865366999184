<!-- filter header -->
<div class="row clearfix mt flex header">
  <h3 class="sr-title sm-h3 rm-m full-width">Audience filters</h3>
  <div class="pull-right qtr-width">
    <mat-form-field
      class="full-width"
      *ngIf="filterListSelection && filterListSelection.includes('pd-')"
      appearance="outline"
    >
      <mat-select class="full-width" [(ngModel)]="Comparitor" placeholder="Match filters">
        <mat-option value="0">Any of</mat-option>
        <mat-option value="1">All of</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div class="audience-filter row clearfix">
  <!-- open filter div -->
  <div class="row clearfix flex filterArea">
    <mat-form-field class="qtr-width mb" appearance="outline">
      <mat-select
        class=""
        [(ngModel)]="masterfilterListSelection"
        placeholder="Select type of filter"
        name="masterfilterList"
      >
        <mat-option *ngFor="let mf of masterFilter" [value]="mf.value">
          {{ mf.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="qtr-width mb" appearance="outline">
      <mat-select class="" [(ngModel)]="filterListSelection" placeholder="Select type of filter" name="filterList">
        <mat-optgroup *ngFor="let group of filterGroups" [label]="group.name" [disabled]="group.disabled">
          <mat-option *ngFor="let filter of group.filter" [value]="filter.value">
            {{ filter.viewValue }}
          </mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>
    <!-- open selected filter area -->


    <div class="filter patientData" *ngIf="filterListSelection && filterListSelection.includes('pd-')">
      <!-- gender -->
      <div class="row clearfix flex" *ngIf="filterListSelection == 'pd-0'">
        <mat-select class="qtr-width" placeholder="Gender"></mat-select>
      </div>

      <!-- age -->
      <div class="row clearfix flex" *ngIf="filterListSelection == 'pd-1'">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Age (Min)</mat-label>
          <input matInput placeholder="Age (Min)" required/>
        </mat-form-field>
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Age (Max)</mat-label>
          <input matInput placeholder="Age (Max)" required/>
        </mat-form-field>
      </div>

      <!-- postcode -->
      <div class="row clearfix flex" *ngIf="filterListSelection == 'pd-2'">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Postcode (Min)</mat-label>
          <input matInput placeholder="Postcode (Min)" required/>
        </mat-form-field>
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Postcode (Max)</mat-label>
          <input matInput placeholder="Postcode (Max)" required/>
        </mat-form-field>
      </div>

      <!-- date added -->
      <div class="row clearfix flex" *ngIf="filterListSelection == 'pd-3'">
        <mat-form-field class="full-width" appearance="outline">
          <mat-select class="" placeholder="Was added">
            <mat-option>On</mat-option>
            <mat-option>Before</mat-option>
            <mat-option>After</mat-option>
          </mat-select>
        </mat-form-field>
        <app-date-input class="full-width"></app-date-input>
      </div>

      <!-- treated -->
      <div class="row clearfix flex mt mb" *ngIf="filterListSelection == 'pd-4'">
        <mat-checkbox class="full-width">Invited previously</mat-checkbox>
        <mat-checkbox class="full-width">Treated previously</mat-checkbox>
      </div>
    </div>

    <!-- product lookup -->
    <div class="filter treatmentProductData" *ngIf="filterListSelection == 'tr-0'">
      <app-treatment-informed-consent-create></app-treatment-informed-consent-create>
    </div>

    <!-- LENDING DETAILS FILTERS -->
    <div class="filter lendingData" *ngIf="filterListSelection && filterListSelection.includes('ld-')">
      LENDING FILTERS {{ filterListSelection }}
    </div>

    <!-- DENTAL HISTORY FILTERS -->
    <div class="filter dentalData" *ngIf="filterListSelection && filterListSelection.includes('dh-')">
      <!-- Immediate problems -->
      <div class="row clearfix flex" *ngIf="filterListSelection == 'dh-0'">
        <mat-form-field class="full-width" appearance="outline">
          <mat-select class="" placeholder="Immediate problem">
            <mat-option>Yes</mat-option>
            <mat-option>No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Wanting to improve -->
      <div class="row clearfix flex" *ngIf="filterListSelection == 'dh-1'">
        <mat-form-field class="full-width" appearance="outline">
          <mat-select class="" placeholder="Wants to improve">
            <mat-option>Yes</mat-option>
            <mat-option>No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Sensitivity -->
      <div class="row clearfix flex" *ngIf="filterListSelection == 'dh-2'">
        <mat-form-field class="full-width" appearance="outline">
          <mat-select class="" placeholder="Has sensitivity">
            <mat-option>Yes</mat-option>
            <mat-option>No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Pain/Soreness -->
      <div class="row clearfix flex" *ngIf="filterListSelection == 'dh-3'">
        <mat-form-field class="full-width" appearance="outline">
          <mat-select class="" placeholder="Pain or Soreness">
            <mat-option>Yes</mat-option>
            <mat-option>No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Bite problems -->
      <div class="row clearfix flex" *ngIf="filterListSelection == 'dh-4'">
        <mat-form-field class="full-width" appearance="outline">
          <mat-select class="" placeholder="Bite problems">
            <mat-option>Yes</mat-option>
            <mat-option>No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Apprehension -->
      <div class="row clearfix flex" *ngIf="filterListSelection == 'dh-5'">
        <mat-form-field class="full-width" appearance="outline">
          <mat-select class="" placeholder="Apprehension">
            <mat-option>Yes</mat-option>
            <mat-option>No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Personal info -->
      <div class="row clearfix flex" *ngIf="filterListSelection == 'dh-6'">6</div>
    </div>

    <!-- MEDICAL HISTORY FILTERS -->
    <div class="filter medicalData" *ngIf="filterListSelection && filterListSelection.includes('mh-')">
      <!-- Private health -->
      <div class="row clearfix flex" *ngIf="filterListSelection == 'mh-0'">
        <mat-checkbox class="full-width">Private health insurance</mat-checkbox>
      </div>

      <!-- Smoking -->
      <div class="row clearfix flex" *ngIf="filterListSelection == 'mh-1'">
        <mat-checkbox class="full-width">Smokes</mat-checkbox>
      </div>

      <!-- Drinking -->
      <div class="row clearfix flex" *ngIf="filterListSelection == 'mh-2'">
        <mat-checkbox class="full-width">Drinks alcohol</mat-checkbox>
      </div>

      <!-- Pregnancy -->
      <div class="row clearfix flex" *ngIf="filterListSelection == 'mh-3'">
        <mat-checkbox class="full-width">Pregnant</mat-checkbox>
        <mat-checkbox class="full-width">Breastfeeding</mat-checkbox>
      </div>

      <!-- Stress -->
      <div class="row clearfix flex" *ngIf="filterListSelection == 'mh-4'">
        <mat-checkbox class="full-width">Stress</mat-checkbox>
      </div>
    </div>

    <!-- ILLNESS FILTERS -->
    <div class="filter illnessData" *ngIf="filterListSelection && filterListSelection.includes('il-')">
      <!-- Illness type -->

      <!-- Ongoing illnesses -->

      <mat-select class="half-width" placeholder="Select illness">
        <mat-option *ngFor="let ill of illnessList" [value]="ill">{{ ill }}</mat-option>
      </mat-select>
    </div>

    <!-- ALLERGIES FILTERS -->
    <div class="filter allergyData" *ngIf="filterListSelection && filterListSelection.includes('al-')">
      <!-- Allergy type -->

      <!-- Ongoing allergy -->
      <div class="row clearfix flex">
        <mat-select class="half-width" [(ngModel)]="allergySelection" placeholder="Allergy group">
          <mat-option *ngFor="let al of allergyList" [value]="al">{{ al }}</mat-option>
        </mat-select>

        <mat-select class="full-width" *ngIf="allergySelection" placeholder="Allergy type">
          <mat-option *ngFor="let a of allergyList" [value]="a">{{ a }}</mat-option>
        </mat-select>
      </div>
    </div>

    <!-- close selected filter area -->

    <!-- close filter div -->
  </div>

  <!-- add another filter btn -->
  <div class="row clearfix mt mb" *ngIf="filterListSelection" @ngIfAnimation>
    <button class="pull-left animate" (click)="newVisitRow()" mat-mini-fab color="primary" matTooltip="Add filter">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>

<!-- display results -->
<div class="audience-result row clearfix mt" *ngIf="filterPanels.length >= 1">
  <!-- Loop displays the filters which have been set -->
  <div class="defined-filter" *ngFor="let fP of filterPanels; let i = index" @ngIfAnimation>
    <hr *ngIf="i >= 1"/>
    <div class="row clearfix">
      <!-- is it filtering ALL or ANY -->
      <h5 class="rm-m" *ngIf="i >= 1 && Comparitor == 0">OR</h5>
      <h5 class="rm-m" *ngIf="i >= 1 && Comparitor == 1">AND</h5>
    </div>
    <div class="row clearfix flex">
      <!-- remove line item -->
      <button
        class="remove-line-item"
        (click)="removeFilter(i)"
        mat-icon-button
        color="accent"
        matTooltip="Remove filter"
      >
        <mat-icon>block</mat-icon>
      </button>
      <div class="group">
        <!-- filter output -->
        <p class="animate">
          <strong>{{ i + 1 }}:</strong>
          <!-- demo outputs for filters selected -->
          <span *ngIf="fP == 'pd-0'"> Filter by gender: <strong>Male</strong> </span>
          <span *ngIf="fP == 'pd-1'"> Filter by age: <strong>10</strong> to <strong>50</strong> </span>
          <span *ngIf="fP == 'pd-2'"> Filter by post code: <strong>6000</strong> to <strong>6999</strong> </span>
          <span *ngIf="fP == 'pd-3'"> Filter by added: <strong>before</strong> <strong>01/01/2019</strong> </span>
          <span *ngIf="fP == 'pd-4'"> Filter by invited: <strong>previously invited</strong> </span>
          <!-- demo output if other things are chosen -->
          <span *ngIf="fP != 'pd-0' && fP != 'pd-1' && fP != 'pd-2' && fP != 'pd-3' && fP != 'pd-4'"
          >Filter by <strong>{{ fP }}</strong
          >: <strong>Value1</strong> to <strong>Value2</strong></span
          >
        </p>
      </div>
    </div>
  </div>

  <!-- display calculation of number of filters, resulting number of {{ "KEYWORD.patient" | translate }}s -->
  <div class="row clearfix result mt">
    <div class="block pull-left animate">
      <p class="rm-mb">Filters selected</p>
      <h2 class="sr-title sm-h2 rm-mt">{{ filterPanels.length }}</h2>
    </div>
    <div class="block pull-right text-right animate">
      <p class="rm-mb">{{ "KEYWORD.patient" | translate | titlecase}}s in audience</p>
      <h2 class="sr-title sm-h2 rm-mt">{{ 1000 / filterPanels.length | number: '1.0-0' }}</h2>
    </div>
  </div>
</div>
