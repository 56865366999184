<div class="container">
  <mat-card class="card details">
    <ng-container *ngIf="(viewModel$ | async) as viewModel">
      <mat-tab-group class="button-group-tabs" mat-stretch-tabs animationDuration="0ms">
        <mat-tab label="Types">
          <div class="note-type_filter flex">
            <mat-icon class="mt" [color]="'primary'">search</mat-icon>

            <mat-form-field class="ml" style="width: 90%" appearance="outline">
              <mat-label> Note Type</mat-label>
              <input
                class="clearfix"
                name="noteType"
                placeholder="Note Type"
                matInput
                [(ngModel)]="noteTypeFilter"
                (ngModelChange)="setNoteTypeFilter($event)"
              />

              <ipv-clear-text-button
                *ngIf="noteTypeFilter.length > 0"
                (click)="clearNoteTypes()"
              ></ipv-clear-text-button>
            </mat-form-field>

            <button class="ml new-note-type-button" mat-raised-button color="accent" (click)="createNoteType()">
              <mat-icon>add</mat-icon>
              New Type
            </button>
          </div>

          <mat-card class="card" *ngFor="let noteType of viewModel.noteTypes">
            <div class="colour-stripe"></div>

            <div class="row clearfix flex">
              <div class="full-width">
                <label>Note Type:</label>
                <p class="rm-mt">{{noteType['ActionType']}}</p>
              </div>

              <div class="full-width">
                <label>Note Subject:</label>
                <div class="rm-mt" *ngFor="let subject of getStringArray(noteType['AllowedValues.Subject'])">
                  {{ subject }}
                </div>
              </div>

              <div class="full-width">
                <label> Note Result:</label>
                <div class="rm-mt" *ngFor="let result of getStringArray(noteType['AllowedValues.Result'])">
                  {{ result }}
                </div>
              </div>

              <div class="full-width ml" *ngIf="noteType['Merchant_key'] !== ''">
                <div style="display: block; float: right">
                  <button
                    class="position-del"
                    mat-icon-button
                    color="primary"
                    matTooltip="Edit"
                    (click)="updateNoteType(noteType)"
                  >
                    <mat-icon>edit</mat-icon>
                  </button>

                  <button
                    class="position-del ml"
                    mat-icon-button
                    color="primary"
                    matTooltip="Delete"
                    (click)="deleteNoteType(noteType.ID)"
                  >
                    <mat-icon>delete_forever</mat-icon>
                  </button>
                </div>
              </div>

              <div class="ml" style="width: 90px">
                <div class="float-right" *ngIf="noteType.Merchant_key === viewModel.user.merchantKey">
                  <div style="cursor: pointer" (click)="toggleNoteType(noteType)">
                    <label> Enabled:</label>
                    <mat-slide-toggle
                      [checked]="noteType['Disabled'] === '0'"
                      [disabled]="true"
                      style="margin-top: 10px"
                    ></mat-slide-toggle>
                  </div>
                </div>
              </div>
            </div>
          </mat-card>
        </mat-tab>

        <mat-tab label="Templates">
          <div style="height: 100%; max-height: 700px">
            <ipv-note-templates
              [dentist]="viewModel.dentist"
              [practice]="viewModel.practice"
              [patient]="viewModel.user"
            ></ipv-note-templates>
          </div>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
  </mat-card>
</div>
