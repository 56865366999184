<div class="rel text-center cdia">
  <mat-icon class="xlarge-modal-icon accent-color">delete</mat-icon>

  <div class="pull-right">
    <button class="btn-close btn-clear mat-button abs-right" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <h2 class="sr-title rm-mb mt" mat-dialog-title>Clear logs</h2>
  <mat-dialog-content>
    <div class="row clearfix">
      <p class="small">Choose a data range to be cleared</p>
    </div>
    <div class="row clearfix flex">
      <app-date-input
        class="full-width margin-btm-large"
        [maxDate]="dateTo"
        [label]="'Log from'"
        [defaultDate]="null"
        [isClear]="true"
        (selectedDate)="selectDateFrom($event)"
        name="fromDate"
      ></app-date-input>

      <app-date-input
        class="full-width margin-btm-large"
        [maxDate]="maxDate"
        [defaultDate]="dateToDefault"
        [label]="'Log to'"
        [isClear]="true"
        (selectedDate)="selectDateTo($event)"
        name="toDate"
      ></app-date-input>
    </div>

    <hr/>
  </mat-dialog-content>
  <mat-dialog-actions class="button-row text-center">
    <button (click)="closeEvent()" mat-raised-button matDialogClose>Close</button>
    <!-- Can optionally provide a result for the closing dialog. -->
    <button (click)="deleteLogs()" mat-raised-button color="accent">Remove</button>
  </mat-dialog-actions>
</div>
