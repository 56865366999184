import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { ClientDetails } from '../../../shared/types/client-details';

@Pipe({
  name: 'TimeLabelPast',
})
export class TimeLabelPast implements PipeTransform {
  constructor() {}

  transform(expression: any, timeZone = true) {
    try {
      let result;

      const d = ClientDetails.convertTimeZone(expression, timeZone);

      if (moment.isDate(expression)) {
        result = moment(d).toNow(true);

        return result;
      } else if (Number.isInteger(expression)) {
        const _tmp = Number(expression);

        if (!Number.isNaN(_tmp)) {
          if (_tmp <= 0) {
            return null;
          } else if (_tmp <= 60) {
            return 'Just Now';
          } else if (_tmp > 60 && _tmp < 3600) {
            const r = Number(_tmp / 60).toFixed(0);
            return r + ' min(s)';
          } else if (_tmp > 3600 && _tmp < 3600 * 24) {
            const r = Number(_tmp / (60 * 60)).toFixed(0);
            return r + ' hour(s)';
          }

          const r = Number(_tmp / (60 * 60 * 24)).toFixed(0);
          return r + ' day(s)';
        }
      } else if (typeof expression == 'string') {
        result = moment(d).toNow(true);

        return result;
      }

      return expression;
    } catch (e) {
      return expression;
    }
  }
}
