import { Component, EventEmitter, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
@Component({
  selector: 'app-booking-modal',
  templateUrl: './booking-modal.component.html',
  styleUrls: ['./booking-modal.component.css'],
})
export class BookingModalComponent implements OnInit {
  closeModal = new EventEmitter();

  headerBackground = '#2eacc1';

  @Input()
  type = 'partner';

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.type) {
        this.type = data.type;
      }
    }
  }

  public ngOnInit() {}
  close() {
    this.closeModal.emit(true);
  }
  getLink(e) {
    if (e) {
      this.closeModal.emit(true);
    }
  }
}
