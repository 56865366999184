import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { ClipboardService } from 'ngx-clipboard';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { LookupService } from '../../../shared/services/lookup.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { DentistService } from '../../dentist/shared/dentist.service';
import { LandingPageShareComponent } from '../../merchant/landing-page-share/landing-page-share.component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { ProductService } from '../../product/shared/product.service';
import { ProductUtil } from '../../product/shared/productUtil.type';
import { DocumentService } from '../shared/document.service';

@Component({
  selector: 'self-service-landing-page',
  templateUrl: './self-service-landing-page.component.html',
  styleUrls: ['./self-service-landing-page.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class SelfServiceLandingPageComponent implements OnInit {
  financedProductGroup = [];

  productGroup;

  dentists;
  isLoaded = true;
  productUtil = new ProductUtil();

  themes = [];
  theme = {
    code: 'blue',
    background: 'linear-gradient(135deg, #0092eb 0%, #0dbad3 100%)',
    color: '#1b8bdd',
    color2: '#0dbad3',
  };
  targetCode = 'SlfSrvcLnd';
  @Input()
  merchantID;

  sessionType;

  visitors;
  contactID;
  isPromoterOrAdmin = false;

  merchant;

  terminalCode;
  practiceUniqueCode;
  practiceUniqueCodeOriginal;
  practiceUniqueCodeError = false;
  link;

  urlQR;
  urlQRShort;
  publicLink;

  setting = Settings.global;
  totalVisitor = 0;
  iFrameSrc;
  iFrameSrcSecured;
  loadIFrame = false;

  chartVisitorData = [];
  settings = Settings.global;
  openLink;

  selfServiceLandingPage;
  label;
  title;
  description;

  isModuleGoogleReviewActive = Settings.global['isModuleGoogleReviewActive'];
  downloadQRCodeDental = new EventEmitter();

  currentDentist;

  isActive = true;
  selectedTheme;
  modifyMerchant = false;
  constructor(
    private documentService: DocumentService,
    private lookupService: LookupService,
    private dentistService: DentistService,
    private productService: ProductService,
    private utilService: UtilsService,
    private _clipboardService: ClipboardService,
    private _sanitizer: DomSanitizer,
    private merchantService: MerchantService,
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (Settings.global['context'] === 'p4p') {
      this.theme = {
        code: 'ee350b',
        background: ' linear-gradient(-135deg, #1caaa0 -10.31%, #7462ef  97.05%)',
        color: '#7462ef',
        color2: '#1caaa0',
      };
    }

    this.activeRoute.params.subscribe((params) => {
      this.utilService.getCurrentAccess().subscribe((access) => {
        if (access) {
          this.isModuleGoogleReviewActive = access['isModuleGoogleReviewActive'];
        }

        this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
          this.isPromoterOrAdmin = isPromoterOrAdmin;
          this.authenticationService.getSessionType().subscribe((res) => {
            this.sessionType = res;
            this.authenticationService.getCurrentDentist().subscribe((cd) => {
              if (cd) {
                this.currentDentist = cd;
                this.authenticationService.getCurrentPractice().subscribe((merchant) => {
                  if (merchant) {
                    this.merchant = merchant;
                    if (this.merchant['Terminal_Code']) {
                      this.terminalCode = this.merchant['Terminal_Code'];
                    }

                    if (this.merchant['ID']) {
                      this.merchantID = this.merchant['ID'];
                    }
                    this.setup();
                  }
                });
              }
            });
          });
        });
      });
    });
  }

  setup() {
    this.dentistService
      .getList({ merchantID: this.merchantID, fields: 'ID,CalculatedName' }, this.isPromoterOrAdmin)
      .subscribe((res) => {
        if (res && res.length > 0) {
          this.dentists = res;
        }

        const p1 = {
          merchantID: this.merchantID,
          isFinance: true,
        };

        this.productService.getMerchantProductGroup(p1, this.isPromoterOrAdmin).subscribe((res) => {
          if (res && res.length > 0) {
            this.financedProductGroup = res;

            const index = res.findIndex((item) => {
              if (item && item['GroupCode'] === 'GPF') {
                return true;
              }
              return false;
            });
            if (index != -1) {
              this.productGroup = 'GPF';
            } else {
              if (res && res[0] && res[0]['GroupCode']) {
                this.productGroup = res[0]['GroupCode'];
              }
            }
          }

          this.documentService.documentLinkListTheme().subscribe((t) => {
            if (t && t.length > 0) {
              this.themes = t;
            }

            this.buildMerchant();
          });
        });
      });
    this.merchantService.practiceLandingPageOneByMerchant(this.merchantID, { fields: 'Code' }).subscribe((res) => {
      this.practiceUniqueCode = res.Code;
      this.practiceUniqueCodeOriginal = res.Code;

      this.addLinks();
      if (this.targetCode) {
        this.merchantService.getMerchantVisitorsByTarget(this.targetCode, {}, this.sessionType).subscribe((res) => {
          if (res) {
            this.visitors = res;
            this.addChart();
          }
        });
      }
    });
  }

  buildMerchant() {
    if (this.merchant && this.merchant.ID) {
      if (this.merchant['Self_Service_Disabled'] === '1') {
        this.isActive = false;
      } else {
        this.isActive = true;
      }

      if (this.merchant['Self_Service_Default_Theme']) {
        this.selectedTheme = this.merchant['Self_Service_Default_Theme'];
      } else {
        this.selectedTheme = 'blue';
      }
      if (this.merchant['Self_Service_Sender_key']) {
        this.contactID = this.merchant['Self_Service_Sender_key'];
      } else if (this.currentDentist && this.currentDentist.ID) {
        this.contactID = this.currentDentist.ID;
        this.modifyMerchant = true;
      } else {
        this.modifyMerchant = true;
      }

      if (this.merchant['Self_Service_Default_ProdGroup.Code']) {
        this.productGroup = this.merchant['Self_Service_Default_ProdGroup.Code'];
      } else {
        if (this.merchant['Landing_Page_Default_Product_Group']) {
          this.productGroup = this.merchant['Landing_Page_Default_Product_Group'];
        } else if (this.financedProductGroup && this.financedProductGroup.length > 0) {
          let index = this.financedProductGroup.findIndex((item) => {
            if (item && item['GroupCode'] === 'GPF') {
              return true;
            }
            return false;
          });
          if (index != -1) {
            this.productGroup = 'GPF';
          } else {
            index = this.financedProductGroup.findIndex((item) => {
              if (item && item['GroupCode'] === 'FIN') {
                return true;
              }
              return false;
            });
            if (index != -1) {
              this.productGroup = 'FIN';
            } else if (
              this.financedProductGroup &&
              this.financedProductGroup[0] &&
              this.financedProductGroup[0]['GroupCode']
            ) {
              this.productGroup = this.financedProductGroup[0]['GroupCode'];
            }
          }
        }

        this.modifyMerchant = true;
      }

      if (this.modifyMerchant === true) {
        const p = {
          selfServiceProductGroup: this.productGroup || null,
          selfServiceSender: this.contactID || null,
          selfServiceTheme: this.selectedTheme || null,
        };

        this.authenticationService.editCurrentPractice(p).subscribe((r) => {});
      }

      if (this.selectedTheme && this.themes && this.themes.length > 0) {
        const index = this.themes.findIndex((e) => {
          if (e && e.code && e.code === this.selectedTheme) {
            return true;
          }

          return false;
        });

        if (index !== -1) {
          this.theme = this.themes[index];
        }
      }
    }
  }
  addLinks(updateIframe = true) {
    this.label = 'Self Service Landing Page';
    this.link = this.setting['publicSiteFrontendLink'] + '/self-service/' + this.merchantID;
    this.urlQR = `${environment.nodeUrl}/r/self-service/${this.merchantID}/qr?t=qr`;
    this.urlQRShort = `${environment.nodeUrl}/s/${this.practiceUniqueCode}?t=qr`;
    this.publicLink = '/s/';

    this.utilService.createShortcut(this.urlQR).subscribe((r) => {
      if (r && r.shortLink) {
        this.urlQRShort = r.shortLink;
      }
    });

    this.openLink = `${this.settings['publicSiteFrontendLink']}/self-service/${this.merchantID}`;

    if (this.practiceUniqueCode) {
      this.selfServiceLandingPage = `${environment.nodeUrl}/s/${this.practiceUniqueCode}?t=lp`;
    } else if (this.merchantID) {
      this.selfServiceLandingPage = `${environment.nodeUrl}/s/${this.merchantID}?t=lp`;
    }
    if (updateIframe == true) {
      this.updateIFrameSrc(this.link);
    }

    this.title = 'Self Service Landing Page';
    this.description =
      'On this page, patients can create a treatment invitation by uploading the treatment document you provided after their visit.';
  }

  addChart() {
    if (this.visitors) {
      this.totalVisitor = 0;
      this.chartVisitorData = [];

      if (this.visitors['Facebook_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.visitors['Facebook_Visitors']);
      }
      if (this.visitors['Linkedin_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.visitors['Linkedin_Visitors']);
      }
      if (this.visitors['Instagram_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.visitors['Instagram_Visitors']);
      }

      if (this.visitors['Pinterest_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.visitors['Pinterest_Visitors']);
      }
      if (this.visitors['Twitter_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.visitors['Twitter_Visitors']);
      }
      if (this.visitors['Email_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.visitors['Email_Visitors']);
      }

      if (this.visitors['Practice_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.visitors['Practice_Visitors']);
      }

      if (this.visitors['Dental_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.visitors['Dental_Visitors']);
      }

      if (this.visitors['QR_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.visitors['QR_Visitors']);
      }
      if (this.visitors['SMS_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.visitors['SMS_Visitors']);
      }

      if (this.visitors['Other_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.visitors['Other_Visitors']);
      }

      if (this.visitors['LandingPage_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.visitors['LandingPage_Visitors']);
      }

      this.chartVisitorData.push({
        name: `Email ${Math.trunc((Number(this.visitors['Email_Visitors']) / Number(this.totalVisitor)) * 100)}%`,
        value: Number(this.visitors['Email_Visitors']),
      });

      this.chartVisitorData.push({
        name: `SMS ${Math.trunc((Number(this.visitors['SMS_Visitors']) / Number(this.totalVisitor)) * 100)}%`,
        value: Number(this.visitors['SMS_Visitors']),
      });

      this.chartVisitorData.push({
        name: `Practice Page ${Math.trunc(
          (Number(this.visitors['Practice_Visitors']) / Number(this.totalVisitor)) * 100
        )}%`,
        value: Number(this.visitors['Practice_Visitors']),
      });

      this.chartVisitorData.push({
        name: `Dental Tips and Offers page ${Math.trunc(
          (Number(this.visitors['Dental_Visitors']) / Number(this.totalVisitor)) * 100
        )}%`,
        value: Number(this.visitors['Dental_Visitors']),
      });

      this.chartVisitorData.push({
        name: `QR Scan ${Math.trunc((Number(this.visitors['QR_Visitors']) / Number(this.totalVisitor)) * 100)}%`,
        value: Number(this.visitors['QR_Visitors']),
      });

      this.chartVisitorData.push({
        name: `Campaign landing page ${Math.trunc(
          (Number(this.visitors['LandingPage_Visitors']) / Number(this.totalVisitor)) * 100
        )}%`,
        value: Number(this.visitors['LandingPage_Visitors']),
      });

      this.chartVisitorData.push({
        name: `Facebook ${Math.trunc((Number(this.visitors['Facebook_Visitors']) / Number(this.totalVisitor)) * 100)}%`,
        value: Number(this.visitors['Facebook_Visitors']),
      });
      this.chartVisitorData.push({
        name: `Linkedin ${Math.trunc((Number(this.visitors['Linkedin_Visitors']) / Number(this.totalVisitor)) * 100)}%`,
        value: Number(this.visitors['Linkedin_Visitors']),
      });
      this.chartVisitorData.push({
        name: `Twitter ${Math.trunc((Number(this.visitors['Twitter_Visitors']) / Number(this.totalVisitor)) * 100)}%`,
        value: Number(this.visitors['Twitter_Visitors']),
      });
      this.chartVisitorData.push({
        name: `Instagram ${Math.trunc(
          (Number(this.visitors['Instagram_Visitors']) / Number(this.totalVisitor)) * 100
        )}%`,
        value: Number(this.visitors['Instagram_Visitors']),
      });

      this.chartVisitorData.push({
        name: `Pinterest ${Math.trunc(
          (Number(this.visitors['Pinterest_Visitors']) / Number(this.totalVisitor)) * 100
        )}%`,
        value: Number(this.visitors['Pinterest_Visitors']),
      });
      this.chartVisitorData.push({
        name: `Other ${Math.trunc((Number(this.visitors['Other_Visitors']) / Number(this.totalVisitor)) * 100)}%`,
        value: Number(this.visitors['Other_Visitors']),
      });
    }
  }

  updateIFrameSrc(src: string): void {
    this.iFrameSrcSecured = this._sanitizer.bypassSecurityTrustResourceUrl(src);
  }

  onFrameLoad(e) {
    if (!this.loadIFrame) {
      this.loadIFrame = true;
      this.changeDetectorRef.detectChanges();
    } else {
      this.loadIFrame = true;
    }
  }

  previewPage(url) {
    window.open(url, '_blank');
  }

  savePracticeUniqueCode() {
    if (this.practiceUniqueCode) {
      const payload = {
        code: this.practiceUniqueCode,
      };
      this.merchantService.practiceLandingPageCreate(payload, this.isPromoterOrAdmin).subscribe((res) => {
        if (res) {
          NotifyAppComponent.displayToast('success', 'Success', 'Your Practice Page code has been updated');
          this.practiceUniqueCodeOriginal = this.practiceUniqueCode;
          this.practiceUniqueCodeError = false;

          this.addLinks(false);
        } else {
          NotifyAppComponent.displayToast(
            'error',
            'Success',
            'This key word is already exist, please choose another one'
          );

          this.practiceUniqueCodeError = true;
        }
      });
    } else {
      if (this.practiceUniqueCodeOriginal) {
        this.practiceUniqueCode = this.practiceUniqueCodeOriginal;

        this.practiceUniqueCodeError = false;
        this.addLinks(false);
      }
    }
  }

  linkCopy(url) {
    if (url) {
      this._clipboardService.copyFromContent(url);
      NotifyAppComponent.displayToast('success', 'Copied!', '');
    }
  }

  copy() {
    if (this.selfServiceLandingPage) {
      this._clipboardService.copyFromContent(this.selfServiceLandingPage);
      NotifyAppComponent.displayToast('success', 'Copied!', '');
    }
  }

  modify() {
    const p = {
      selfServiceProductGroup: this.productGroup || null,
      selfServiceSender: this.contactID || null,
      selfServiceTheme: this.theme.code || null,
      selfServiceActive: this.isActive || null,
    };

    this.authenticationService.editCurrentPractice(p).subscribe((r) => {
      if (r && r.ID) {
        NotifyAppComponent.displayToast('success', 'Success', 'Self Service Landing Page is updated!');

        UtilsClass.scrollUp();
        this.isLoaded = false;

        setTimeout(() => {
          this.isLoaded = true;
        }, 500);
      }
    });
  }

  selectTheme(d) {
    if (d && d.code) {
      this.theme = d;
    }
  }
  share() {
    const ref = RootAppComponent.dialog.open(LandingPageShareComponent, {
      data: {
        type: 'selfService',
      },
      width: '80vw',
      panelClass: 'max-width-panel-1400',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  open() {
    if (this.openLink) {
      window.open(this.openLink, '_blank');
    }
  }
}
