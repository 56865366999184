import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { FacebookPixelEventName } from './types/facebook-pixel-event-name.type';
import { FacebookPixelEventProperties } from './types/facebook-pixel-event-properties.type';

declare const fbq: any;

@Injectable()
export class FacebookPixelService {
  private document: Document;
  private renderer2: Renderer2;
  private pixelId: string;
  private isTrackerEnabled: boolean;

  constructor(
    @Inject(DOCUMENT) private injectedDocument: any,
    @Inject(PLATFORM_ID) private platformId: object,
    @Optional() private router: Router,
    private rendererFactory: RendererFactory2
  ) {
    // DOCUMENT cannot be injected directly as Document type, see https://github.com/angular/angular/issues/20351
    // It is therefore injected as any and then cast to Document
    this.document = injectedDocument as Document;
    this.renderer2 = rendererFactory.createRenderer(null, null);

    if (router) {
      // Log page views after router navigation ends
      router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
        if (this.isLoaded()) {
          this.track('PageView');
        }
      });
    }
  }

  startTracking(pixelId: string) {
    this.verifyPixelId(pixelId).then(() => {
      this.initialize(pixelId);
    });
  }

  /**
   * - Checks if Pixel was initialized
   * @param pixelId Pixel ID to verify
   */
  private verifyPixelId(pixelId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      if (pixelId === null || pixelId === undefined || pixelId.length === 0) {
        return reject(new Error('Invalid Facebook Pixel ID'));
      } else {
        return resolve(pixelId);
      }
    });
  }

  /** Checks if the script element is present */
  private isLoaded(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const pixelElement = this.document.getElementById('pixel-script');
      return !!pixelElement;
    }
    return false;
  }

  /**
   * Initialize the Pixel tracking script
   * - Adds the script to page's head
   * - Tracks first page view
   */
  private initialize(pixelId: string): void {
    this.pixelId = pixelId;
    if (this.isLoaded()) {
      console.warn(
        'Tried to initialize a Pixel instance while another is already active. Please call `remove()` before initializing a new instance.'
      );
      return;
    }
    this.isTrackerEnabled = true;
    this.addPixelScript(pixelId);
  }

  /** Remove the Pixel tracking script */
  remove(): void {
    this.isTrackerEnabled = false;
    this.removePixelScript();
  }

  /** Remove Facebook Pixel tracking script from the application */
  private removePixelScript(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const pixelElement = this.document.getElementById('pixel-script');
    if (pixelElement) {
      pixelElement.remove();
    }
  }

  /**
   * Adds the Facebook Pixel tracking script to the application
   * @param pixelId The Facebook Pixel ID to use
   */
  private addPixelScript(pixelId: string): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const pixelCode = `
      var pixelCode = function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${pixelId}');
      fbq('track', 'PageView');`;

    const scriptElement = this.renderer2.createElement('script');
    this.renderer2.setAttribute(scriptElement, 'id', 'pixel-script');
    this.renderer2.setAttribute(scriptElement, 'type', 'text/javascript');
    this.renderer2.setProperty(scriptElement, 'innerHTML', pixelCode);
    this.renderer2.appendChild(this.document.head, scriptElement);
  }

  /**
   * Track a Standard Event as predefined by Facebook
   *
   * See {@link https://developers.facebook.com/docs/facebook-pixel/reference Facebook Pixel docs - reference}
   * @param eventName The name of the event that is being tracked
   * @param properties Optional properties of the event
   */
  track(eventName: FacebookPixelEventName, properties?: FacebookPixelEventProperties): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (!this.isLoaded()) {
      console.warn('Tried to track an event without initializing a Pixel instance. Call `initialize()` first.');
      return;
    }

    if (properties) {
      fbq('track', eventName, properties);
    } else {
      fbq('track', eventName);
    }
  }

  /**
   * Track a custom Event
   *
   * See {@link https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-conversions Facebook Pixel docs - custom conversions}
   * @param eventName The name of the event that is being tracked
   * @param properties Optional properties of the event
   */
  trackCustom(eventName: string, properties?: object): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (!this.isLoaded()) {
      console.warn('Tried to track an event without initializing a Pixel instance. Call `initialize()` first.');
      return;
    }

    if (properties) {
      fbq('trackCustom', eventName, properties);
    } else {
      fbq('trackCustom', eventName);
    }
  }
}
