<div class="row clearfix full-width tabArea" *ngIf="tab == 'profile'">
  <div class="qtr-width">
    <div class="col-left">
      <div class="pictureBlock mat-card">
        <div class="row clearfix image">
          <app-ep-document-view-image
            class="clearfix full-width display-image profile"
            [defaultPicture]="
              'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
            "
            [link]="profileLink"
          >
          </app-ep-document-view-image>
        </div>
        <div class="row clearfix">
          <h4 class="sr-title sm-h4">My Profile</h4>
          <p class="larger rm-mb">{{ userName }}</p>
          <p class="rm-mt role">
            <em>{{ userrole }}</em>
          </p>
        </div>

        <div class="row clearfix">
          <h4 class="sr-title sm-h4 rm-mb">Upload Avatar</h4>
          <p class="small rm-m">To upload a new avatar image, please drag your file into the grey area below.</p>
          <app-file-uploader
            class="text-center clearfix full-width"
            [allowedExtensionTypes]="allowedExtensionTypes"
            [isFixedAspectRatio]="true"
            [isImageCropping]="true"
            [canSkip]="false"
            [limit]="1"
            [uploadDirectly]="true"
            [isDragable]="true"
            (onCompleteAll)="onCompleteAll($event)"
          >
          </app-file-uploader>
        </div>
      </div>
    </div>
  </div>

  <div class="thr-qtr-width">
    <div class="col-right">
      <form #personalDetails="ngForm" *ngIf="myProfile">
        <div class="row clearfix flex full-width">
          <div class="icons-col">&nbsp;</div>
          <div class="row-content-col flex">
            <h3 class="sr-title add-mt">Main profile information</h3>
          </div>
        </div>
        <div class="row clearfix flex full-width">
          <div class="icons-col">
            <mat-icon>person</mat-icon>
          </div>
          <div class="row-content-col flex">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>First Name </mat-label>
              <input [(ngModel)]="myProfile.FirstName" name="snap" matInput placeholder="First Name" required/>
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Middle Name</mat-label>
              <input [(ngModel)]="myProfile.MiddleName" name="MiddleName" matInput placeholder="Middle Name"/>
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Last Name</mat-label>
              <input [(ngModel)]="myProfile.Name" name="Last" matInput placeholder="Last Name" required/>
            </mat-form-field>
          </div>
        </div>

        <div class="row clearfix flex full-width">
          <div class="icons-col">
            <mat-icon>settings_phone</mat-icon>
          </div>
          <div class="row-content-col flex">
            <app-phone-input
              class="full-width"
              [phoneNumber]="myProfile['mobiles.Number']"
              [isLandLine]="false"
              [label]="'Mobile'"
              [required]="true"
              (validate)="validateMobileProfileEvent($event)"
              (getPhone)="getMobileProfile($event)"
            >
            </app-phone-input>
            <app-phone-input
              class="full-width"
              [phoneNumber]="myProfile['phones.Number']"
              [isLandLine]="true"
              [label]="'Phone Number'"
              [required]="true"
              (validate)="validatePhoneProfileEvent($event)"
              (getPhone)="getPhoneProfile($event)"
            >
            </app-phone-input>
          </div>
        </div>
        <div class="row clearfix">
          <div class="icons-col">
            <mat-icon>email</mat-icon>
          </div>
          <div class="row-content-col">
            <app-email-input
              class="full-width"
              [email]="myProfile['emails.Email']"
              [label]="'Email'"
              [required]="true"
              (getEmail)="getEmailProfile($event)"
              (validate)="validateEmailProfileEvent($event)"
            >
            </app-email-input>
          </div>
        </div>

        <div class="row clearfix flex full-width">
          <div class="icons-col">&nbsp;</div>
          <div class="row-content-col flex">
            <h3 class="sr-title add-mt">Address Information</h3>
          </div>
        </div>

        <app-address-input
          class="full-width"
          [icon]="profileMarker"
          [canClose]="false"
          [postCode]="profilePostCode"
          [state]="profileState"
          [suburb]="profileSuburb"
          [streetNumber]="profileStreetNumber"
          [streetName]="profileStreetName"
          [unitNumber]="profileUnitNumber"
          [streetType]="profileStreetType"
          [latitude]="profileLatitude"
          [longitude]="profileLongitude"
          [icon]="profileMarker"
          (getAdress)="getAdressProfile($event)"
          (validate)="isAdressProfileValidEvent($event)"
        ></app-address-input>

        <div class="full-width text-center">
          <button
            id="updateBtn"
            [disabled]="
              !personalDetails.form.valid ||
              isAdressProfileValid == false ||
              validateEmailProfile == false ||
              validatePhoneProfile == false ||
              validateMobileProfile == false
            "
            (click)="editProfile()"
            mat-raised-button
            color="accent"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="row clearfix full-width tabArea" *ngIf="tab == 'practice'">
  <div class="qtr-width">
    <div class="col-left">
      <div class="pictureBlock mat-card">
        <div class="row clearfix image">
          <app-ep-document-view-image
            class="clearfix full-width display-image profile"
            [defaultPicture]="
              'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/practice-pic.png'
            "
            [link]="practiceLink"
          >
          </app-ep-document-view-image>
        </div>
        <div class="row clearfix">
          <h4 class="sr-title sm-h4 rm-mb">Upload {{ "KEYWORD.practice" | translate | titlecase}} Image</h4>
          <p class="small rm-m">
            To upload a new {{ "KEYWORD.practice" | translate }} image, please drag your file into the grey area below.
          </p>
          <app-file-uploader
            class="text-center clearfix full-width"
            [allowedExtensionTypes]="allowedExtensionTypes"
            [isFixedAspectRatio]="true"
            [isImageCropping]="true"
            [canSkip]="false"
            [limit]="1"
            [uploadDirectly]="true"
            [isDragable]="true"
            (onCompleteAll)="onCompleteAllPractice($event)"
          >
          </app-file-uploader>
        </div>
      </div>

      <mat-card class="busHrsBlock clearfix">
        <button class="pull-right" *ngIf="isCreateTradingTime == false" (click)="setupTradingHours()" mat-icon-button>
          <mat-icon>create</mat-icon>
        </button>
        <h3 class="sr-title m-h2 rm-mt">Business Hours</h3>
        <p class="small">Merchant Timezone: {{ merchantTimezone }}</p>
        <div *ngIf="isCreateTradingTime != false">
          <p>Please Setup your trading hours</p>
          <button class="text-center" (click)="setupTradingHours()" mat-raised-button color="accent">Set up</button>
        </div>
        <div *ngIf="isCreateTradingTime == false">
          <mat-list>
            <mat-list-item class="flex flexList busHours" *ngFor="let t of tradingHours">
              <span class="small" *ngIf="t['NotOpen'] == '0'"
              ><span class="dayLabel">{{ t['DayOfWeek.Label.Singular'] }}</span>
                <span class="tradingHour"
                >{{ util.timeConvert(t['OpeningTime'].slice(0, 5)) }} -
                  {{ util.timeConvert(t['ClosingTime'].slice(0, 5)) }}</span
                >
              </span>
              <span class="small" *ngIf="t['NotOpen'] == '1'"
              ><span class="dayLabel">{{ t['DayOfWeek.Label.Singular'] }}</span>
                <span class="tradingHour">Close</span>
              </span>
            </mat-list-item>
          </mat-list>
        </div>
      </mat-card>
    </div>
  </div>

  <div class="thr-qtr-width">
    <div class="col-right">
      <form #PracticeDetails="ngForm" *ngIf="myPractice">
        <div class="row clearfix flex full-width">
          <div class="icons-col">&nbsp;</div>
          <div class="row-content-col flex">
            <h3 class="sr-title">Main {{ "KEYWORD.practice" | translate }} details</h3>
          </div>
        </div>

        <div class="row clearfix flex full-width">
          <div class="icons-col">
            <mat-icon>store_mall_directory</mat-icon>
          </div>
          <div class="row-content-col flex">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Trading as</mat-label>
              <input
                [(ngModel)]="myPractice['TradingAs']"
                [disabled]="isMerchantAdmin != true"
                name="Trading"
                matInput
                placeholder="Trading as"
                required
              />
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Legal entity name </mat-label>
              <input
                [(ngModel)]="myPractice['Name']"
                [disabled]="isMerchantAdmin != true"
                name="entity"
                matInput
                placeholder="Legal entity name"
                required
              />
            </mat-form-field>
          </div>
        </div>

        <div class="row clearfix flex full-width">
          <div class="icons-col">
            <mat-icon>av_timer</mat-icon>
          </div>
          <div class="row-content-col">
            <app-time-zone-list
              [timeZoneCode]="myPractice['TimeZoneCode']"
              [disabled]="disableEditPractice"
              (getResult)="getResultTimeZoneMerchant($event)"
            >
            </app-time-zone-list>
          </div>
        </div>

        <div class="row clearfix flex full-width">
          <div class="icons-col">
            <mat-icon>domain</mat-icon>
          </div>
          <div class="row-content-col flex">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>ABN</mat-label>
              <input
                [(ngModel)]="myPractice['ABN']"
                [disabled]="isMerchantAdmin != true"
                name="ABN"
                matInput
                placeholder="ABN"
              />
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>ACN</mat-label>
              <input
                [(ngModel)]="myPractice['ACN']"
                [disabled]="isMerchantAdmin != true"
                name="ACN"
                matInput
                placeholder="ACN"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="row clearfix flex full-width">
          <div class="icons-col">&nbsp;</div>
          <div class="row-content-col flex">
            <h3 class="sr-title">{{ "KEYWORD.practice" | translate | titlecase}} contact information</h3>
          </div>
        </div>

        <div class="row clearfix flex full-width">
          <div class="icons-col">
            <mat-icon>settings_phone</mat-icon>
          </div>
          <div class="row-content-col flex">
            <app-phone-input
              class="full-width"
              [phoneNumber]="myPractice['mobiles.Number']"
              [isLandLine]="false"
              [label]="'Mobile (Optional)'"
              [required]="false"
              (validate)="validateMobilePracticeEvent($event)"
              (getPhone)="getMobilePractice($event)"
            >
            </app-phone-input>
            <app-phone-input
              class="full-width"
              [phoneNumber]="myPractice['phones.Number']"
              [isLandLine]="true"
              [label]="'Phone Number'"
              [required]="true"
              (validate)="validatePhonePracticeEvent($event)"
              (getPhone)="getPhonePractice($event)"
            >
            </app-phone-input>
          </div>
        </div>
        <div class="row clearfix">
          <div class="icons-col">
            <mat-icon>email</mat-icon>
          </div>
          <div class="row-content-col">
            <app-email-input
              class="full-width"
              [email]="myPractice['emails.Email']"
              [label]="'Email'"
              [required]="true"
              (getEmail)="getEmailPractice($event)"
              (validate)="validateEmailPracticeEvent($event)"
            >
            </app-email-input>
          </div>
        </div>

        <div class="row clearfix flex full-width">
          <div class="icons-col">&nbsp;</div>
          <div class="row-content-col flex">
            <h3 class="sr-title">{{ "KEYWORD.patient" | translate | titlecase }} communication</h3>
          </div>
        </div>

        <div class="row clearfix">
          <div class="icons-col">
            <mat-icon class="fas fa-reply-all"></mat-icon>
          </div>
          <div class="row-content-col">
            <app-email-input
              class="full-width"
              [email]="myPractice['email.ReplyTo']"
              [label]="'Reply-to address'"
              [required]="true"
              (getEmail)="getEmailPractice($event)"
              (validate)="validateEmailPracticeEvent($event)"
            >
            </app-email-input>

            <p class="small">
              Enter an email address here and we will automatically forward you any
              {{ "KEYWORD.patient" | translate }} responses to communication sent via the platform.
            </p>
          </div>
        </div>

        <div class="row clearfix flex full-width">
          <div class="icons-col">&nbsp;</div>
          <div class="row-content-col flex">
            <h3 class="sr-title">{{ 'Thirdpartybooking' | translate }}</h3>
          </div>
        </div>

        <div class="row clearfix">
          <div class="icons-col">
            <mat-icon class="fas fa-calendar-check"></mat-icon>
          </div>
          <div class="row-content-col">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Enter URL</mat-label>
              <input
                [(ngModel)]="myPractice.ThirdPartyBooking_URL"
                name="thirdPartyUrl"
                matInput
                placeholder="Enter URL"
              />
            </mat-form-field>

            <p class="small">
              At the end of the invitation process you can route your {{ "KEYWORD.patient" | translate }}s to your
              third-party booking engine by entering the URL here.

              <br/>(Be sure to add the entire address, <strong>including</strong> the 'https' part of the URL.
            </p>
          </div>
        </div>

        <div class="row clearfix flex full-width">
          <div class="icons-col">&nbsp;</div>
          <div class="row-content-col flex">
            <h3 class="sr-title add-mt">Address Information</h3>
          </div>
        </div>

        <app-address-input
          class="full-width"
          [canClose]="false"
          [postCode]="practicePostCode"
          [state]="practiceState"
          [suburb]="practiceSuburb"
          [streetNumber]="practiceStreetNumber"
          [streetName]="practiceStreetName"
          [unitNumber]="practiceUnitNumber"
          [streetType]="practiceStreetType"
          [latitude]="practiceLatitude"
          [longitude]="practiceLongitude"
          [icon]="practiceMarker"
          (getAdress)="getAdressPractice($event)"
          (validate)="isAdressPracticeValidEvent($event)"
        >
        </app-address-input>

        <div class="full-width text-center">
          <!-- <button  id="updateBtn" mat-raised-button (click)="editPractice()"
                color="accent" [disabled]="!PracticeDetails.form.valid || validateEmailPractice==false || validatePhonePractice==false  || isAdressPracticeValid==false   || validateMobilePractice==false  ">Save</button> -->

          <button id="updateBtn" (click)="editPractice()" mat-raised-button color="accent">Save</button>
        </div>
      </form>
    </div>
  </div>
</div>
