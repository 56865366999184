<div class="rel customerModal" *ngIf="customer">
  <div class="row clearfix">
    <div class="row titleArea clearfix full-width">
      <app-ep-document-view-image
        class="customer patientView userIconView pull-left"
        [defaultPicture]="
          'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
        "
        [document]="profilePic"
      >
      </app-ep-document-view-image>
      <h2 class="summary-header rm-m white">
        {{ customer.Salutation }} {{ customer.CalculatedName }}
        <span class="subLabel">{{ "KEYWORD.patient" | translate | titlecase }}</span>
      </h2>
    </div>
  </div>

  <div class="row clearfix">
    <p class="small">Gender</p>
    <p class="rm-m">{{ customer['Gender.Label'] }}</p>

    <p class="small">Name</p>
    <p class="rm-m">
      {{ customer['Salutation'] }} {{ customer['FirstName'] }} {{ customer['MiddleName'] }} {{ customer['Name'] }}
    </p>
  </div>

  <div class="row clearfix" *ngIf="customer['DateOfBirth'] && customer['DateOfBirth'] != '0000-00-00'">
    <p class="small">Date of birth</p>
    <p class="rm-m">{{ customer['DateOfBirth'] | customDate }}</p>
  </div>

  <div class="row clearfix">
    <p class="small">Phone/Mobile</p>
    <p class="rm-m">{{ customer['mobiles.Number'] | customPhone }}</p>

    <p class="small" *ngIf="customer['phones.Number'] && customer['phones.Number'] != 'undefined'">
      Phone
    </p>
    <p class="rm-m" *ngIf="customer['phones.Number'] && customer['phones.Number'] != 'undefined'">
      {{ customer['phones.Number'] | customPhone: 'landLine' }}
    </p>
  </div>

  <!-- Email Addresses -->
  <div class="row clearfix">
    <p class="small">Primary Email</p>
    <p class="rm-m">{{ customer['emails.Email'] }}</p>
  </div>

  <!-- Location Information -->
  <div class="row clearfix" *ngIf="customer['addresses.Calculated']">
    <p class="small">Home Address</p>
    <p class="rm-m">{{ customer['addresses.Calculated'] }}</p>
  </div>

  <p *ngIf="isReferral == true">
    <span class="status-box pull-right">Referral </span>
  </p>
</div>
