import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { SubscriptionPlanService } from '../shared/subscription-plan.service';

import * as $ from 'jquery';
@Component({
  selector: 'app-feature-sub-label-cover-view',
  templateUrl: './feature-sub-label-cover-view.component.html',
  styleUrls: ['./feature-sub-label-cover-view.component.css'],
})
export class FeatureSubLabelCoverViewComponent implements OnInit {
  @Input() subFeatureLabelID;

  @Input() LastModified = null;

  @Output() close = new EventEmitter();
  postCoverURL;

  postCover;

  isImageError = false;

  constructor(
    private subscriptionPlanService: SubscriptionPlanService,

    private domSanitizer: DomSanitizer,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.subFeatureLabelID = data;
    }
  }

  ngOnInit() {
    if (this.subFeatureLabelID) {
      this.getProfilePicture();
    }
    this.setHight();
    $(window).resize(() => {
      this.setHight();
    });
  }

  setHight() {
    const width = $('.sub-label-view-header-modal').width();

    if (width) {
      const height = Number(Number(width) / 4).toFixed(0);

      $('.sub-label-view-header-modal').css('height', height);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.subFeatureLabelID.previousValue != changes.subFeatureLabelID.currentValue) {
      if (this.subFeatureLabelID) {
        this.getProfilePicture();
      } else {
        this.postCover = null;
      }

      this.setHight();
    }
  }

  getProfilePicture() {
    this.postCover = null;

    this.postCover = this.subscriptionPlanService.getFeatureSubLabelCoverImageStreamLink(
      this.subFeatureLabelID,
      this.LastModified
    );

    this.postCoverURL = this.domSanitizer.bypassSecurityTrustStyle('url(' + this.postCover + ')');
  }

  closeEvent() {
    this.close.emit(true);
  }

  onLoad() {
    this.isImageError = false;
  }

  onError() {
    this.isImageError = true;
  }
}
