import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { SideMenuService } from '../../../shared/services/side-menu.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { CustomerViewComponent } from '../../customer/customer-view/customer-view.component';
import { SettlementRequestComponent } from '../settlement-request/settlement-request.component';
import { SettlementViewComponent } from '../settlement-view/settlement-view.component';
import { SettlementService } from '../shared/settlement.service';

@Component({
  selector: 'app-settlement-list',
  templateUrl: './settlement-list.component.html',
  styleUrls: ['./settlement-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class SettlementListComponent implements OnInit {
  @Input()
  dateFrom;

  @Input()
  dateTo;

  @Input()
  customerID;

  @Input()
  status;

  @Input()
  _contractID;

  @Input()
  isMaterialRequest;

  @Input()
  statusCodes;

  @Output()
  createdSettlement = new EventEmitter();

  displayContractView = false;
  contractID;

  selectedIDs = [];
  @Input()
  viewOnly = false;
  isPromoterOrAdmin = false;

  isModuleSettlementsActive = Settings.global['isModuleSettlementsActive'];

  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  utils = new UtilsClass();

  displayedColumns = [
    'selectCheckBox',
    'Date',
    'patientPicture',
    'PatientName',
    // "InvoiceAmount",
    // "InvoiceType",
    'SettlementStatus',
    'PaymentStatus',
    'SettlementAmount',
    'InvoiceReference',
    'MaterialRequest',
    'Actions',
    'fieldColor',
  ];
  filters = [];
  items = [
    'settlement',
    'contract',
    'invitation',
    'customer',
    'dentist',
    'merchant',
    'instantOffer',
    'appointment',
    'campaign',
    'landingPage',
    'note',
  ];
  searchVals = new FormControl();

  searchValList = ['First Name', 'Last Name', 'Settlement Status', 'Invoice Type', 'Payment Status'];
  expandedRow: number;
  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  filteredSize = null;

  destroyEvent = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private utilService: UtilsService,
    private settlementService: SettlementService,
    private dialog: MatDialog,
    private customDate: CustomDatePipe,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });
  }

  ngOnInit() {
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleSettlementsActive = access['isModuleSettlementsActive'];
      }

      SideMenuService.goBack.subscribe((res) => {
        if (res == true) {
          this.hideContractView();
        }
      });

      this.listDB = new LoadRecords(
        this.settlementService,
        this.destroyEvent,
        this.customerID,
        this._contractID,
        this.dateFrom,
        this.dateTo,
        this.status,
        this.statusCodes,
        this.isMaterialRequest
      );
      this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.listDB = new LoadRecords(
      this.settlementService,
      this.destroyEvent,
      this.customerID,
      this._contractID,
      this.dateFrom,
      this.dateTo,
      this.status,
      this.statusCodes,
      this.isMaterialRequest
    );
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  setFilter(event, field) {
    let filter;

    if (typeof event == 'object' && event != null) {
      const v = this.customDate.transform(event, Settings.global['dateFormat']);
      filter = {
        field,
        value: v,
      };
    } else if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  openSettlementView(ID) {
    const ref = this.dialog.open(SettlementViewComponent, {
      data: ID,
      width: '900px',
      panelClass: 'noCard',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  openContractView(item) {
    // this.contractID = ID;
    // this.displayContractView = true;
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['customer-profile', item['Customer_key'], 'settlement', item['ID']],
        },
      },
    ]);
  }
  selectPage() {
    const index = this.dataSource['_paginator']['index'] || 0;
    const pageSize = this.dataSource['_paginator']['pageSize'] || 0;

    for (let i = 0; i < (index + 1) * pageSize; i++) {
      const o = this.dataSource.filteredData[index * pageSize + i];

      if (o && o['ID'] && this.selectedIDs.indexOf(o['ID']) == -1) {
        this.selectedIDs.push(o['ID']);
      }
    }
  }

  select(id, isSelected) {
    if (isSelected == true && this.selectedIDs.indexOf(id) == -1) {
      this.selectedIDs.push(id);
    } else {
      const _index = this.selectedIDs.indexOf(id);
      this.selectedIDs.splice(_index, 1);
    }
  }

  unselect() {
    this.selectedIDs = [];
  }

  isSelected(id) {
    if (!this.selectedIDs || this.selectedIDs.length <= 0) {
      return false;
    } else {
      if (this.selectedIDs.indexOf(id) != -1) {
        return true;
      }
    }

    return false;
  }

  selectAll() {
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      const o = this.dataSource.filteredData[i];

      if (o && o['ID'] && this.selectedIDs.indexOf(o['ID']) == -1) {
        this.selectedIDs.push(o['ID']);
      }
    }
  }

  removeGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirm = new ConfirmDialog(
        'fas fa-trash',
        '',
        'Are you sure you want to void these settlements  ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.settlementService.deleteSettlementGroup(this.selectedIDs, 'merchant').subscribe((res) => {
            if (res && res.length > 0) {
              for (let i = 0; i < res.length; i++) {
                this.dataSource.removeItem = res[i];
              }

              this.selectedIDs = [];
            }
            ref.close();

            NotifyAppComponent.displayToast('success', 'Success!', '');
          });
        }
      });
    }
  }
  cancelGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirm = new ConfirmDialog(
        'cancel',
        '',
        'Are you sure you want to Withdraw these settlements  ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.settlementService.cancelSettlementGroup({ ids: this.selectedIDs }, 'merchant').subscribe((res) => {
            if (res && res.length > 0) {
              for (let i = 0; i < res.length; i++) {
                this.dataSource.removeItem = res[i];
              }

              this.selectedIDs = [];
            }
            ref.close();

            NotifyAppComponent.displayToast('success', 'Success!', '');
          });
        }
      });
    }
  }

  hideContractView() {
    this.contractID = null;
    this.displayContractView = false;
  }

  toNumber(n) {
    if (n) {
      return Number(n);
    } else {
      return 0;
    }
  }

  getSettlementUpdate(d) {
    if (d && d.ID) {
      this.dataSource.replaceItem = d;
    }
  }

  openCustomerView(ID) {
    const ref = this.dialog.open(CustomerViewComponent, {
      data: ID,
      width: '600px',
      panelClass: 'noCard',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  viewPatient(id) {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['customer-profile', id, 'patient'],
        },
      },
    ]);
  }

  requestSettlement(id) {
    const ref = RootAppComponent.dialog.open(SettlementRequestComponent, {
      data: id,
      width: '800px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.result.subscribe((res) => {
      if (res) {
        this.dataSource.replaceItem = res;
        ref.close();
      }
    });

    ref.componentInstance.updateItem.subscribe((res) => {
      if (res) {
        if (res['ConsentedViaPaperForm'] == '1' && !res['SecurityCode_key']) {
          res['Status.Label'] = 'Pending-Authorised by Form';
        } else if (res['ConsentedViaPaperForm'] == '0' && res['SecurityCode_key']) {
          res['Status.Label'] = 'Pending-Authorised by Security Code';
        }
        this.dataSource.replaceItem = res;
      }
    });
  }

  withdrawSettlement(id, groupID) {
    if (id && groupID == '') {
      const confirm = new ConfirmDialog('block', '', 'Are you sure you want to withdraw this settlement?', 'No', 'Yes');

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation == true) {
          this.settlementService.cancel(id, this.isPromoterOrAdmin).subscribe((res) => {
            if (res) {
              this.dataSource.replaceItem = res;
              NotifyAppComponent.displayToast('success', 'Success!', 'Your Settlement has been withdrawn');

              ref.close();
            }
          });
        } else {
          ref.close();
        }
      });
    } else if (id && groupID != '') {
      const confirm = new ConfirmDialog('block', '', 'Are you sure you want to withdraw this settlement?', 'No', 'Yes');

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation == true) {
          this.settlementService.cancel(id, this.isPromoterOrAdmin, true).subscribe((res) => {
            if (res) {
              if (res.groupArray && res.groupArray.length > 0) {
                for (let i = 0; i < res.groupArray.length; i++) {
                  this.dataSource.replaceItem = res.groupArray[i];
                }
              }
              NotifyAppComponent.displayToast('success', 'Success!', 'Your Settlement has been withdrawn');
              ref.close();
            }
          });
        } else {
          ref.close();
        }
      });
    }
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  isDestoyed = false;

  serviceRef;

  constructor(
    private settlementService: SettlementService,
    private destroyEvent,
    private customerID,
    private contractID,
    private dateFrom,
    private dateTo,
    private status,

    private statusCodes,
    private isMaterialRequest
  ) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    const payload = {
      section: 0,
      dateFrom: this.dateFrom || null,
      dateTo: this.dateTo || null,
      contractID: this.contractID,
      customerID: this.customerID,
      status: this.status,
      statusCodes: this.statusCodes,
      isMaterialRequest: this.isMaterialRequest,
      fields:
        'ID,DateTimeCreated,MerchantName,External_ID,Transaction_ID,Merchant_key,Invoice.Reference,Settlement.Amount,Is_Materials_Request,Status.Label,Status.Code,Invoice.Description,SecurityCode_key,' +
        'Contract_key,Invoice.Amount,Customer_key,DrawDownGroupID,Customer.CalculatedName,Customer.FirstName,Customer.LastName,ConsentedViaPaperForm,' +
        'PaymentStatus.Label',
    };

    this.serviceRef = this.settlementService.getSettlementList(payload).subscribe((res) => {
      this.count = res.length;
      this.items = res;

      for (let i = 0; i < this.items.length; i++) {
        this.buildRecord(i);
      }

      this.groupSettlement();
      this.checkAuthoriseStatus();
      this.dataChange.next(this.items.slice());
      this.firstLoad = true;
      const innerFunction = (section) => {
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.settlementService.getSettlementList(payload).subscribe((res: any) => {
          if (res.length > 0) {
            this.count = res.count;
            this.items = this.items.concat(res);
            for (let i = length; i < this.items.length; i++) {
              this.buildRecord(i);
            }

            this.groupSettlement();
            this.checkAuthoriseStatus();
            this.dataChange.next(this.items.slice());
            if (this.isDestoyed != true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };

      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  buildRecord(i) {
    if (this.items[i]) {
      this.items[i]['fullName'] = '';

      if (this.items[i]['Status.Label']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Status.Label'];
      }
      if (this.items[i]['Status.Code']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Status.Code'];
      }
      if (this.items[i]['SecurityCode_key']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['SecurityCode_key'];
      }
      if (this.items[i]['Contract_key']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Contract_key'];
      }
      if (this.items[i]['Customer_key']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Customer_key'];
      }
      if (this.items[i]['DrawDownGroupID']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['DrawDownGroupID'];
      }
      if (this.items[i]['Customer.FirstName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Customer.FirstName'];
      }
      if (this.items[i]['Customer.LastName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Customer.LastName'];
      }
      if (this.items[i]['Customer.CalculatedName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Customer.CalculatedName'];
      }

      if (this.items[i]['PaymentStatus.Label']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['PaymentStatus.Label'];
      }
      if (this.items[i]['Merchant_key']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Merchant_key'];
      }
      if (this.items[i]['MerchantName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['MerchantName'];
      }
      if (this.items[i]['External_ID']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['External_ID'];
      }
      if (this.items[i]['Transaction_ID']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Transaction_ID'];
      }
    }
  }

  groupSettlement() {
    const _temp = this.items.reduce((prev, curr) => {
      if (curr['DrawDownGroupID']) {
        let index;

        if ((index = prev.findIndex((elem) => elem['DrawDownGroupID'] == curr['DrawDownGroupID'])) > -1) {
          prev[index]['Settlement.Amount'] = String(
            Number(prev[index]['Settlement.Amount']) + Number(curr['Settlement.Amount'])
          );
        } else {
          prev.push(curr);
        }
      } else {
        prev.push(curr);
      }
      return prev;
    }, []);
    this.items = _temp;
  }

  checkAuthoriseStatus() {
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i]['Status.Code'] == 'PEND') {
        if (this.items[i]['SecurityCode_key']) {
          this.items[i]['Status.Label'] = 'Pending-Authorised by Security Code';
        } else if (this.items[i]['ConsentedViaPaperForm'] == '1') {
          this.items[i]['Status.Label'] = 'Pending-Authorised by Form';
        } else {
          this.items[i]['Status.Label'] = 'Pending-Unauthorised';
        }
      }
    }
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }
  set removeItem(id) {
    const data = this._tableDatabase.data.filter((row) => row.ID != id);
    this._tableDatabase.dataChange.next(data);
  }
  set replaceItem(_item) {
    let item = _item;

    if (item) {
      item['fullName'] = '';

      if (item['Status.Label']) {
        item['fullName'] = item['fullName'] + ' ' + item['Status.Label'];
      }
      if (item['Status.Code']) {
        item['fullName'] = item['fullName'] + ' ' + item['Status.Code'];
      }
      if (item['SecurityCode_key']) {
        item['fullName'] = item['fullName'] + ' ' + item['SecurityCode_key'];
      }
      if (item['Contract_key']) {
        item['fullName'] = item['fullName'] + ' ' + item['Contract_key'];
      }
      if (item['Customer_key']) {
        item['fullName'] = item['fullName'] + ' ' + item['Customer_key'];
      }
      if (item['DrawDownGroupID']) {
        item['fullName'] = item['fullName'] + ' ' + item['DrawDownGroupID'];
      }
      if (item['Customer.FirstName']) {
        item['fullName'] = item['fullName'] + ' ' + item['Customer.FirstName'];
      }
      if (item['Customer.LastName']) {
        item['fullName'] = item['fullName'] + ' ' + item['Customer.LastName'];
      }
      if (item['PaymentStatus.Label']) {
        item['fullName'] = item['fullName'] + ' ' + item['PaymentStatus.Label'];
      }
      if (item['Customer.CalculatedName']) {
        item['fullName'] = item['fullName'] + ' ' + item['Customer.CalculatedName'];
      }

      if (item['Merchant_key']) {
        item['fullName'] = item['fullName'] + ' ' + item['Merchant_key'];
      }
      if (item['MerchantName']) {
        item['fullName'] = item['fullName'] + ' ' + item['MerchantName'];
      }
      if (item['External_ID']) {
        item['fullName'] = item['fullName'] + ' ' + item['External_ID'];
      }
      if (item['Transaction_ID']) {
        item['fullName'] = item['fullName'] + ' ' + item['Transaction_ID'];
      }
    }
    const _index = this._tableDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    this._tableDatabase.data[_index] = item;
    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
          let f = '';

          f = item[this.field] || '';

          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'Date':
          [propertyA, propertyB] = [moment(a['Date']).toDate().getTime(), moment(b['Date']).toDate().getTime()];
          break;

        case 'SettlementAmount':
          [propertyA, propertyB] = [a['Settlement.Amount'], b['Settlement.Amount']];
          break;
        case 'PatientName':
          [propertyA, propertyB] = [
            a['Customer.FirstName'] + ' ' + a['Customer.LastName'],
            b['Customer.FirstName'] + ' ' + b['Customer.LastName'],
          ];
          break;
        case 'InvoiceAmount':
          [propertyA, propertyB] = [a['Invoice.Amount'], b['Invoice.Amount']];
          break;
        case 'InvoiceType':
          [propertyA, propertyB] = [a['Invoice.Description'], b['Invoice.Description']];
          break;
        case 'SettlementStatus':
          [propertyA, propertyB] = [a['Status.Label'], b['Status.Label']];
          break;
        case 'PaymentStatus':
          [propertyA, propertyB] = [a['PaymentStatus.Label'], b['PaymentStatus.Label']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
