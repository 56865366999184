<div class="row clearfix">
  <div class="row clearfix" *ngIf="isModal == true">
    <button class="btn-close btn-clear mat-button pull-right" (click)="closeModal()">
      <mat-icon>clear</mat-icon>
    </button>
    <h2 class="mat-h2 sr-title">{{ title }}</h2>
  </div>

  <div class="row clearfix flex" *ngIf="isPromoterOrAdmin == true">
    <div class="full-width">
      <mat-select
        class="margin-btm half-width"
        [(ngModel)]="selectedMerchantID"
        placeholder="Please select merchant"
        (ngModelChange)="getOperators($event)"
        name="MerchantName"
      >
        <mat-option [value]="'none'"> Please select merchant</mat-option>
        <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m['ID']">
          <span
            class="list-label pull-right"
            *ngIf="m['Status'] && m['Status'] == 'Active'"
            [style.background]="'#00d07a'"
          >
            {{ m['Status'] }}
          </span>

          <span
            class="list-label pull-right"
            *ngIf="m['Status'] && m['Status'] != 'Active'"
            [style.background]="'#ff5722'"
          >
            {{ m['Status'] }}
          </span>
          {{ m['TradingAs'] || m['CalculatedName'] }}
        </mat-option>
      </mat-select>
    </div>

    <div class="full-width" *ngIf="selectedMerchantID">
      <mat-select
        class="margin-btm half-width"
        [(ngModel)]="selectedOperator"
        name="StaffName"
        placeholder="please select operator"
      >
        <mat-option *ngFor="let staff of operators | orderBy: ['CalculatedName']" [value]="staff">
          {{ staff['CalculatedName'] }}
        </mat-option>
      </mat-select>
    </div>
  </div>

  <div class="row clearfix" *ngIf="!selectedPatient">
    {{ "KEYWORD.patient" | translate | titlecase }} Lookup

    <button (click)="patientLookup()" mat-raised-button color="primary">
      Search {{ "KEYWORD.patient" | translate | titlecase }}</button>
  </div>

  <mat-card class="card" *ngIf="selectedPatient && selectedOperator">
    <form #actionForm="ngForm" [hidden]="isLookup == true">
      <div class="row clearfix">
        <div class="row clearfix">
          <app-stopwatch
            [start]="false"
            [showTimerControls]="true"
            (getStartTime)="getStartTimeEvent($event)"
            (getDuration)="getDurationEvent($event)"
          ></app-stopwatch>
        </div>

        <div class="row clearfix flex">
          <div class="full-width">
            <label>Start Time</label>
            <p>{{ startTime }}</p>
          </div>

          <div class="full-width">
            <label>Stop Time</label>
            <p>{{ stopTime }}</p>
          </div>
        </div>

        <div class="row clearfix flex">
          <div class="full-width">
            <label>Opeartor</label>
            <p>{{ selectedOperator['CalculatedName'] }}</p>
          </div>

          <div class="full-width">
            <label>{{ "KEYWORD.patient" | translate | titlecase }}</label>
            <p>{{ selectedPatient['CalculatedName'] }}</p>
          </div>
        </div>

        <hr/>
        <div class="row clearfix flex">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Action</mat-label>
            <input class="" [(ngModel)]="action" matInput name="action" placeholder="Action"/>
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Subject</mat-label>
            <input class="" [(ngModel)]="subject" matInput name="subject" placeholder="Subject"/>
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Result</mat-label>
            <input class="" [(ngModel)]="result" matInput name="result" placeholder="Result"/>
          </mat-form-field>
        </div>

        <div class="row clearfix">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Description</mat-label>
            <textarea
              class="full-width"
              [(ngModel)]="description"
              name="content"
              placeholder="Description"
              cols="30"
              rows="10"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card>
</div>
