import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { DEFAULT_PRACTICE_OPTION } from '../../../core/authentication/shared/shared/data/authentication';
import { labelAnimation } from '../../../shared/animations/label.animation';
import { onChangeAnimation } from '../../../shared/animations/onChange.animation';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { UtilsService } from '../../../shared/services/utils.service';
import { InitialDateMode } from '../../../shared/types/initial-date-mode.type';
import { DEFAULT_MERCHANT_OPTION } from '../../merchant/shared/data/merchant';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { Merchant } from '../../merchant/shared/types/merchant.type';
import { SettlementService } from '../shared/settlement.service';
import { SettlementPaymentsReportService } from './shared/services/settlement-payments-report.service';
import {
  MerchantFeesReportRequest,
  MerchantFeesReportRequestOptionals,
  PatientPaymentReportRequest,
  PatientPaymentReportRequestOptionals,
} from './shared/types/settlement-payments-report.type';

@Component({
  selector: 'ipv-settlement-payments-report',
  templateUrl: './settlement-payments-report.page.html',
  styleUrls: ['./settlement-payments-report.page.css'],
  animations: [labelAnimation, onChangeAnimation],
  providers: [CustomDatePipe, SettlementPaymentsReportService],
})
export class SettlementPaymentsReportPage implements OnInit, OnDestroy {
  viewModel$ = this.authenticationService.isPromoterOrAdmin().pipe(
    mergeMap((isPromoterOrAdmin) => {
      this.isPromoterOrAdmin = isPromoterOrAdmin;
      if (!this.startWithAllMerchants) {
        return this.authenticationService.getCurrentPractice().pipe(
          tap((currentPractice) => {
            this.merchant = currentPractice;
          })
        );
      } else {
        this.merchant = DEFAULT_PRACTICE_OPTION;
        return of(true);
      }
    }),
    mergeMap(() => {
      const payload = { fields: 'ID,TradingAs,CalculatedName,Status,addresses.Calculated,phones.Number' };

      if (this.isPromoterOrAdmin) {
        return this.merchantService.fetchMerchants$(payload).pipe(
          tap((merchants) => {
            this.merchantService.setMerchants(merchants);
          })
        );
      } else {
        return of(true);
      }
    })
  );

  merchants$ = this.merchantService.getMerchants$();

  patientPaymentReport$ = this.settlementService.getPatientPaymentReport$().pipe(
    tap((patientPaymentReport) => {
      if (patientPaymentReport && patientPaymentReport.Date_Processed) {
        this.patientPaymentReportCount = patientPaymentReport.Date_Processed.length;
        this.patientPaymentReportTotal = 0;

        patientPaymentReport.Settled_Amount.forEach((settledAmount) => {
          this.patientPaymentReportTotal = this.patientPaymentReportTotal + settledAmount;
        });
      }
    })
  );

  patientPaymentReportCount = 0;
  patientPaymentReportTotal = 0;

  merchantFeeReportCount = 0;
  merchantFeeReportTotal = 0;

  merchantFeeReport$ = this.settlementService.getMerchantFeeReport$().pipe(
    tap((merchantFeeReport) => {
      if (merchantFeeReport && merchantFeeReport.Date_Processed) {
        this.merchantFeeReportCount = merchantFeeReport.Date_Processed.length;
        this.merchantFeeReportTotal = 0;

        merchantFeeReport.Fees_Amount.forEach((settledAmount) => {
          this.merchantFeeReportTotal = this.merchantFeeReportTotal + settledAmount;
        });
      }
    })
  );

  merchant: Partial<Merchant> = null;
  allMerchantsOptionValue = DEFAULT_MERCHANT_OPTION;

  isPromoterOrAdmin = false;

  startDate: string;
  endDate: string;

  initialDateMode: InitialDateMode = 'today';
  showEndDate = true;
  startWithAllMerchants = false;

  initialStartDate = moment().add(-1, 'd').toDate();
  initialEndDate = null;

  selectedTabIndex = 0;

  constructor(
    private router: Router,
    private settlementService: SettlementService,
    private authenticationService: AuthenticationService,
    private merchantService: MerchantService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private utilsService: UtilsService,
    private settlementPaymentsReportService: SettlementPaymentsReportService
  ) {
    const initialDateMode = this.activatedRoute.snapshot.data['initialDateMode'] as InitialDateMode;
    const showEndDate = this.activatedRoute.snapshot.data['showEndDate'] as boolean;
    const startWithAllMerchants = this.activatedRoute.snapshot.data['startWithAllMerchants'] as boolean;

    this.initialDateMode = initialDateMode ? initialDateMode : this.initialDateMode;
    this.showEndDate = showEndDate ? showEndDate : this.showEndDate;
    this.startWithAllMerchants = startWithAllMerchants ? startWithAllMerchants : this.startWithAllMerchants;
  }

  ngOnInit() {
    switch (this.initialDateMode) {
      case 'nextFriday':
        this.initialStartDate = this.utilsService.getNextFriday();
        this.initialEndDate = this.utilsService.getNextFriday();
        break;

      case 'lastFriday':
        this.initialStartDate = this.utilsService.getLastFriday();
        this.initialEndDate = this.utilsService.getLastFriday();
        break;

      case 'lastFridayOrTodayIsFriday':
        if (new Date().getDay() !== 5) {
          this.initialStartDate = this.utilsService.getLastFriday();
          this.initialEndDate = this.utilsService.getLastFriday();
        }
        break;

      default:
        break;
    }
  }

  ngOnDestroy() {
    // required for untilDestroyed();
  }

  setStartDate(startDate: string) {
    if (startDate) {
      this.startDate = startDate;
      this.initialStartDate = new Date(this.startDate);
      this.updatePatientPaymentReport();
      this.updateMerchantFeeReport();
    }
  }

  setEndDate(endDate: string) {
    if (endDate) {
      this.endDate = endDate;
      this.initialEndDate = new Date(this.endDate);
      this.updatePatientPaymentReport();
      this.updateMerchantFeeReport();
    }
  }

  setMerchant() {
    this.updatePatientPaymentReport();
    this.updateMerchantFeeReport();
  }

  /**
   * Check that the payload has the right combination of properties to be accepted by the server
   */
  isValidPayload(payload: PatientPaymentReportRequest | MerchantFeesReportRequest) {
    if (payload.StartDate) {
      return true;
    } else if (payload.StartDate && payload.EndDate) {
      return true;
    } else {
      return false;
    }
  }

  updatePatientPaymentReport() {
    const merchantID = this.merchant.ID ? this.merchant.ID : null;

    let optional: PatientPaymentReportRequestOptionals = {};

    optional = this.endDate ? { ...optional, endDate: this.endDate } : optional;

    if (this.isPromoterOrAdmin) {
      optional = merchantID ? { ...optional, merchantKey: merchantID } : optional;
    }

    const payload = new PatientPaymentReportRequest(this.startDate, optional);

    if (this.isValidPayload(payload)) {
      this.settlementService
        .fetchPatientPaymentReport$(payload, this.isPromoterOrAdmin)
        .pipe(
          untilDestroyed(this),
          tap((patientPaymentReport) => {
            this.settlementService.setPatientPaymentReport(patientPaymentReport);
          })
        )
        .subscribe();
    }
  }

  updateMerchantFeeReport() {
    const merchantID = this.merchant.ID ? this.merchant.ID : null;

    let optional: MerchantFeesReportRequestOptionals = {};

    optional = this.endDate ? { ...optional, endDate: this.endDate } : optional;

    if (this.isPromoterOrAdmin) {
      optional = merchantID ? { ...optional, merchantKey: merchantID } : optional;
    }

    const payload = new MerchantFeesReportRequest(this.startDate, optional);
    if (this.isValidPayload(payload)) {
      this.settlementService
        .fetchMerchantFeeReport$(payload, this.isPromoterOrAdmin)
        .pipe(
          untilDestroyed(this),
          tap((merchantFeeReport) => {
            this.settlementService.setMerchantFeeReport(merchantFeeReport);
          })
        )
        .subscribe();
    }
  }

  matchMerchants(merchantFromList: Partial<Merchant>, selectedMerchant: Partial<Merchant>): boolean {
    return merchantFromList.ID === selectedMerchant.ID;
  }

  openTablePrint() {
    switch (this.selectedTabIndex) {
      case 0:
        this.settlementPaymentsReportService.setPrintButtonClicked('patient-payments');
        break;

      case 1:
        this.settlementPaymentsReportService.setPrintButtonClicked('merchant-fees');
        break;
    }
  }
}
