<div class="row clearfix" [ngClass]="{ defineMessage: isModal == true }">
  <div class="card-header primary-gradient-img clearfix" *ngIf="isModal == true">
    <div class="row clearfix">
      <button class="btn-close btn-clear pull-right mat-button" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <mat-icon class="fas fa-tag pull-left"></mat-icon>
        <h2 class="message-title summary-header rm-m" *ngIf="!title">Select {{ nature }}(s)</h2>

        <h2 class="message-title summary-header rm-m" *ngIf="title">
          {{ title }}
        </h2>
      </div>
    </div>
  </div>
  <mat-dialog-content [ngClass]="{ 'no-modal-height': isModal != true }">
    <p class="text-center" *ngIf="!description && isModal == true && tags && tags.length > 0">
      Choose a {{ nature }} or multiple ones - {{ nature }} are applied to {{ "KEYWORD.patient" | translate }}s and used
      in marketing to create audiences and query {{ "KEYWORD.patient" | translate }}s.
    </p>
    <p class="text-center" *ngIf="description && isModal == true && tags && tags.length > 0">{{ description }}</p>

    <div class="full-width mb">
      <div
        class="full-width text-left"
        *ngIf="canCreate == true && (!tags || (tags && tags.length <= 0)) && loadTag == true"
      >
        <button class="pull-right merchBtn mt" (click)="createNewTag()" mat-raised-button color="primary">
          <mat-icon>add</mat-icon>
          New {{ nature }}
        </button>
        <p>
          No {{ nature }} s are found - {{ nature }} are applied to {{ "KEYWORD.patient" | translate }}s and used in
          marketing to create audiences and query {{ "KEYWORD.patient" | translate }}s.
        </p>
      </div>
      <div class="full-width flex" [ngClass]="{ mt: isModal == true }">
        <app-tag-list
          class="full-width"
          *ngIf="(tags && tags.length > 0) || (defaultSelectedTags && defaultSelectedTags.length > 0)"
          [ngClass]="{ 'tag-list-lookup': onlyGreyTags == true, ml: isModal == true, mr: isModal == true }"
          [openPosition]="openPosition"
          [tags]="tags"
          [selectedTags]="defaultSelectedTags"
          [tagFormatIsObject]="tagFormatIsObject"
          [add]="addTag"
          [title]="selectTitle"
          [canRemove]="canRemove"
          [remove]="removeTag"
          [canCreate]="canCreate"
          [directRemoveDefault]="directRemoveDefault"
          [directRemove]="directRemove"
          [hasFilter]="hasFilter"
          [canSelect]="canSelect"
          (getSelectedTag)="getSelectedTagEvent($event)"
          (getRemovedTag)="getRemovedTagEvent($event)"
          (getRemovedAddedTag)="getRemovedAddedTagEvent($event)"
          (getCreate)="getCreateEvent($event)"
          (getCreate)="createNewTag()"
          (getSelectedTags)="getSelectedTagsEvent($event)"
        >
        </app-tag-list>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="isModal == true">
    <div class="row clearfix text-center mt">
      <button class="pull-left ml" (click)="closeEvent()" mat-raised-button color="primary">Close</button>
      <button
        class="pull-right mr"
        [disabled]="!selectedTags || (selectedTags && selectedTags.length <= 0)"
        (click)="sendResultEvent()"
        mat-raised-button
        color="accent"
      >
        Save
        <mat-icon>check</mat-icon>
      </button>
    </div>
  </mat-dialog-actions>
</div>
