import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-file-image-cropper',
  templateUrl: './file-image-cropper.component.html',
  styleUrls: ['./file-image-cropper.component.css'],
})
export class FileImageCropperComponent implements OnInit {
  @Input()
  backgroundColor = '#FFFFFF';
  @Input()
  isTemplateThumbnail = false;
  @Input()
  isFixedAspectRatio = true;

  blobUtil;
  @Input()
  roundCropper = false;

  @Input()
  aspectRatioType;

  @Input()
  title = "Adjust your image, then click on 'Crop Image' to save.";

  @Input()
  imageFileEvent;

  @Input()
  keepOriginal = true;

  @Input()
  imageFile;

  @Input()
  onlyScaleDown = true;

  @Input()
  canSkip = true;

  @Input()
  resizeToWidth = 0;

  @Input()
  resizeToHeight = 0;

  @Input()
  isCover = false;

  @Input()
  maintainAspectRatio = true;

  @Output()
  result = new EventEmitter();

  @Output()
  getFileBlob = new EventEmitter();

  @Output()
  getFile = new EventEmitter();

  @Output()
  get64Base = new EventEmitter();

  @Output()
  close: any = new EventEmitter();

  @Output()
  validate: any = new EventEmitter();

  @Output()
  imageLoaded: any = new EventEmitter();

  file;

  file64;

  isModal = false;

  croppedWidth;
  croppedHeight;
  @ViewChild(ImageCropperComponent, { static: false }) imageCropper: ImageCropperComponent;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.imageFileEvent) {
        this.imageFileEvent = data.imageFileEvent;
        this.isModal = true;
      }
      if (data.backgroundColor) {
        this.backgroundColor = data.backgroundColor;
        this.isModal = true;
      }
      if (data.imageFile) {
        this.imageFile = data.imageFile;
        this.isModal = true;
      }
      if (data.aspectRatioType) {
        this.aspectRatioType = data.aspectRatioType;
        this.isModal = true;
      }

      if (data.roundCropper != null) {
        this.roundCropper = data.roundCropper;
      }
      if (data.isTemplateThumbnail != null) {
        this.isTemplateThumbnail = data.isTemplateThumbnail;
      }

      if (data.isCover != null) {
        this.isCover = data.isCover;
      }

      if (data.canSkip != null) {
        this.canSkip = data.canSkip;
      }

      if (data.resizeToWidth != 0) {
        this.resizeToWidth = data.resizeToWidth;
      }

      if (data.resizeToHeight != 0) {
        this.resizeToHeight = data.resizeToHeight;
      }

      if (data.keepOriginal != true) {
        this.keepOriginal = data.keepOriginal;
      }

      if (data.isFixedAspectRatio != null) {
        this.isFixedAspectRatio = data.isFixedAspectRatio;
      }

      if (data.title) {
        this.title = data.title;
      }

      if (data.onlyScaleDown != null) {
        this.onlyScaleDown = data.onlyScaleDown;
      }
    }
  }

  ngOnInit() {
    if (this.isCover == true) {
      this.isFixedAspectRatio = false;
    }
  }

  closeModalEvent() {
    this.close.emit(true);
  }

  imageCroppedEvent(event: ImageCroppedEvent) {
    if (event && event.file) {
      this.file = event.file;
    }

    if (event && (event.width || event.height)) {
      this.croppedHeight = event.height;
      this.croppedWidth = event.width;
    }
  }

  imageLoadedEvent(e) {
    if (!e) {
      this.imageLoaded.emit(true);
    }
  }

  cropperReadyEvent(e) {
    if (!e) {
      this.imageLoaded.emit(true);
    }
  }

  loadImageFailedEvent(e) {
    if (e) {
      this.validate.emit(false);

      if (this.file) {
        this.result.emit(this.file);
        this.getFile.emit(this.file);
      }
    }
  }

  skip() {
    if (this.imageFile) {
      this.result.emit(this.imageFile);
    }

    if (this.imageFile) {
      this.getFile.emit(this.imageFile);
    }
  }

  sendResult() {
    if (this.file) {
      this.result.emit(this.file);
    }

    if (this.file && this.file) {
      this.get64Base.emit(this.file);
    }

    if (this.file) {
      this.getFileBlob.emit(this.file);
    }

    if (this.file) {
      this.getFile.emit(this.file);
    }
  }

  blobToUint8Array(b) {
    let uri = URL.createObjectURL(b),
      xhr = new XMLHttpRequest(),
      i,
      ui8;
    xhr.open('GET', uri, false);
    xhr.send();

    URL.revokeObjectURL(uri);

    ui8 = new Uint8Array(xhr.response.length);

    for (i = 0; i < xhr.response.length; ++i) {
      ui8[i] = xhr.response.charCodeAt(i);
    }

    return ui8;
  }

  rotateLeft() {
    this.imageCropper.rotateLeft();
  }

  rotateRight() {
    this.imageCropper.rotateRight();
  }

  flipV() {
    this.imageCropper.flipVertical();
  }

  flipH() {
    this.imageCropper.flipHorizontal();
  }

  squareCropper() {
    this.roundCropper = false;
  }

  circleCropper() {
    this.roundCropper = true;
  }
}
