<div class="card-header primary-gradient-img clearfix inModal confirm">
  <div class="row clearfix">
    <!-- Modal dismiss button -->
    <button class="btn-close btn-clear mat-button white" matDialogClose>
      <mat-icon>clear</mat-icon>
    </button>
    <div class="row titleArea clearfix">
      <mat-icon class="fas fa-user-plus pull-left"></mat-icon>
      <h2 class="summary-header rm-m">Confirm referral to Jim's Orthodontics</h2>
    </div>
  </div>
</div>
<mat-dialog-content>
  <div class="row clearfix">
    <p class="lead">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
      magna aliqua.
    </p>

    <p>
      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
      aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
      occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    </p>

    <p>
      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem
      aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo
      enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui
      ratione voluptatem sequi nesciunt.
    </p>
  </div>
  <hr/>
  <div class="row clearfix text-center">
    <div class="full-width row clearfix text-center">
      <mat-slide-toggle class="slide-toggle-large alternate-theme" [(ngModel)]="isAgree">
        I understand and confirm my referral
      </mat-slide-toggle>
    </div>
    <div class="full-width row clearfix text-center mt">
      <button class="proceed btn-large" [disabled]="!isAgree" mat-raised-button color="accent" matDialogClose>
        Confirm
      </button>
    </div>
  </div>
</mat-dialog-content>
