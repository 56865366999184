export class DeviceDetector {
  static deviceInfo = null;

  static getDeviceInfo() {
    return this.deviceInfo;
  }

  static setDeviceInfo(info) {
    this.deviceInfo = info;
  }

  constructor() {}
}
