import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UserDataService } from '../../services/user-data.service';
import { UtilsService } from '../../services/utils.service';
import { ConfirmDialog } from '../../types/confirm-dialog';
import { NotifyAppComponent } from '../../types/notify-app-component';
import { Settings } from '../../types/settings';
import { UtilsClass } from '../../types/utils/utils.class';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-email-input',
  templateUrl: './email-input.component.html',
  styleUrls: ['./email-input.component.css'],
})
export class EmailInputComponent implements OnInit {
  util = new UtilsClass();

  @Input()
  label = 'Email';

  @Input()
  required = true;

  @Input()
  email = null;

  @Input() isDisabled = false;

  @Input()
  userDataName = null;

  invitationID = null;

  changeOnce = false;
  @Output()
  validate = new EventEmitter();

  @Output()
  getEmail = new EventEmitter();

  @Input()
  clear = new EventEmitter();

  random2 = 'email' + String(Math.random()).substr(3, 9);

  isValidate;

  isStrictMode = Settings.global['isKleberEmailStrictMode'];

  validator = Settings.global['emailValidator'];

  sessionType;

  constructor(
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private userDataService: UserDataService,
    private utilSevice: UtilsService
  ) {}

  ngOnInit() {
    if (this.clear) {
      this.clear.subscribe((res) => {
        this.email = null;
        this.sendResult();
      });
    }

    this.authenticationService.getSessionType().subscribe((r) => {
      this.sessionType = r;

      if (this.userDataName) {
        this.activeRoute.params.subscribe((params) => {
          if (params['consumerID']) {
            this.invitationID = params['consumerID'];
          } else if (params['invitationID']) {
            this.invitationID = params['invitationID'];
          }

          if (this.invitationID) {
            this.userDataService
              .getUserDataByFields(this.invitationID, [this.userDataName], this.sessionType)
              .subscribe((res) => {
                if (res) {
                  this.email = res[this.userDataName];
                }

                this.isInputValidate(false).then((res1) => {
                  if (res1 == true) {
                    this.isValidate = true;
                    this.validate.emit(true);
                    if (this.email) {
                      this.getEmail.emit(this.email);
                    } else {
                      this.getEmail.emit(null);
                    }
                  } else {
                    this.isValidate = false;
                    this.validate.emit(false);
                  }
                });
              });

            this.userDataService.userDataEvent.subscribe((data) => {
              if (data && data.content && this.util.selectObjectByString(data.content, this.userDataName)) {
                this.email = this.util.selectObjectByString(data.content, this.userDataName);

                this.sendResult(false, false);
              } else if (
                data &&
                data.content &&
                this.util.selectObjectByString(data.content, this.userDataName) === null
              ) {
                this.email = null;
                this.sendResult(false, false);
              }
            });
          }
        });
      } else {
        this.isInputValidate(false).then((res) => {
          if (res == true) {
            this.isValidate = true;
            this.validate.emit(true);
            if (this.email) {
              this.getEmail.emit(this.email);
            } else {
              this.getEmail.emit(null);
            }
          } else {
            this.isValidate = false;
            this.validate.emit(false);
          }
        });
      }
    });
  }

  ngOnChanges() {
    this.authenticationService.getSessionType().subscribe((r) => {
      this.sessionType = r;

      this.isInputValidate(false).then((res) => {
        if (res == true) {
          this.isValidate = true;
          this.validate.emit(true);
          if (this.email) {
            this.getEmail.emit(this.email);
          } else {
            this.getEmail.emit(null);
          }
        } else {
          this.isValidate = false;
          this.validate.emit(false);
        }
      });
    });
  }

  isInputValidate(validateKleber = true): Promise<any> {
    return new Promise((resolve) => {
      try {
        if (this.required) {
          if (!this.email) {
            throw false;
          }
        }

        if (this.email && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email) == false) {
          throw false;
        }

        if (this.email && this.validator == 'kleber' && validateKleber == true) {
          this.utilSevice.verifyEmail(this.email).subscribe((res) => {
            if (res['success'] == true && res['data'].Valid == true) {
              NotifyAppComponent.displayToast('success', '+' + this.email + ' is Valid', res['data'].message);

              resolve(true);
            } else {
              if (this.isStrictMode == false) {
                let confirm = new ConfirmDialog(
                  'report',
                  '',
                  'We Were Unable to verify Your Email ' + this.email + ' Do you want to continue? ',
                  'No',
                  'Yes'
                );

                let ref = this.dialog.open(ConfirmDialogComponent, {
                  data: confirm,
                });
                ref.componentInstance.onConfirm.subscribe((confirmation) => {
                  if (confirmation === false) {
                    ref.close();
                    throw false;
                  } else {
                    ref.close();
                    resolve(true);
                  }
                });
              } else {
                resolve(false);
              }
            }
          });
        } else {
          resolve(true);
        }
      } catch (e) {
        resolve(false);
      }
    });
  }

  sendResult(isKleber = true, saveUserData = true) {
    this.isValidate = false;
    this.validate.emit(false);
    this.changeOnce = true;

    this.isInputValidate(isKleber).then((res) => {
      if (res == false) {
        this.isValidate = false;
        this.validate.emit(false);
        this.getEmail.emit(null);
      } else {
        if (this.invitationID && this.userDataName && saveUserData == true) {
          UtilsClass.savingData();
          let saveDate = {
            attribute: this.userDataName,
            value: this.email,
          };

          this.userDataService.setUserData(this.invitationID, saveDate, this.sessionType).subscribe((res) => {
            this.isValidate = true;
            this.validate.emit(true);
            this.getEmail.emit(this.email);
          });
        } else {
          this.isValidate = true;
          this.validate.emit(true);
          this.getEmail.emit(this.email);
        }
      }
    });
  }
}
