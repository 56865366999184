import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MedicalHistoryService } from '../shared/medical-history.service';

import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { AllergyCreateEditComponent } from '../allergy-create-edit/allergy-create-edit.component';

@Component({
  selector: 'app-allergy-list',
  templateUrl: './allergy-list.component.html',
  styleUrls: ['./allergy-list.component.css'],
})
export class AllergyListComponent implements OnInit {
  @Input()
  printMode = false;

  // @Input()
  // medicalHistoryID = "262DC7B73DEEFD45A7A03CE37DF2D9D1";
  @Input()
  medicalHistoryID;
  @Input()
  allergyListInput;

  @Input()
  disableModify = false;

  @Input()
  isConsumer = false;

  @Output()
  isEmptyList = new EventEmitter();

  @Output()
  getNumberOfAllergy = new EventEmitter();

  isLoaded = false;

  @Input()
  allergyList = [];
  sessionType = 'merchant';
  medicationExist = false;

  constructor(
    private authenticationService: AuthenticationService,
    private medicalHistoryService: MedicalHistoryService
  ) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      if (!this.allergyList || (this.allergyList && this.allergyList.length <= 0)) {
        this.getAllergyList();
      } else {
        this.isLoaded = true;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes.medicalHistoryID &&
      changes.medicalHistoryID.previousValue != changes.medicalHistoryID.currentValue
    ) {
      this.authenticationService.getSessionType().subscribe((sessionType) => {
        this.sessionType = sessionType;

        this.getAllergyList();
      });
    }
  }

  getAllergyList() {
    if (this.medicalHistoryID && typeof this.medicalHistoryID == 'string') {
      const payload = {
        medicalHistoryID: this.medicalHistoryID,
        selectMedication: true,
      };
      this.medicalHistoryService.getAllergyList(payload, this.sessionType).subscribe((res) => {
        if (res) {
          this.allergyList = res;
          this.getNumberOfAllergy.emit(this.allergyList.length);
        }

        this.isLoaded = true;
      });
    } else if (this.allergyListInput && Array.isArray(this.allergyListInput) && this.allergyListInput.length > 0) {
      this.allergyList = this.allergyListInput;
      this.getNumberOfAllergy.emit(this.allergyList.length);
      this.isLoaded = true;
    } else {
      this.isLoaded = true;
    }
  }

  modifyAllergy(ID) {
    if (ID) {
      const ref = RootAppComponent.dialog.open(AllergyCreateEditComponent, {
        data: {
          allergyID: ID,
        },
        width: '650px',
      });

      ref.componentInstance.getCreated.subscribe((res) => {
        if (res && res.ID) {
          this.allergyList.push(res);
          ref.close();
        }
      });
      ref.componentInstance.getEdited.subscribe((res) => {
        if (res && res.ID) {
          if (this.allergyList && this.allergyList.length > 0) {
            const index = this.allergyList.findIndex((item) => {
              if (item && item.ID && res.ID == item.ID) {
                return true;
              }
              return false;
            });

            if (index != -1) {
              this.allergyList[index] = res;
            } else {
              this.allergyList.push(res);
            }
          } else {
            this.allergyList.push(res);
          }
          ref.close();
        }
      });
      ref.componentInstance.closeEvent.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  formatDate(date) {
    return moment(date).format('MMMM, YYYY');
  }

  createNewAllergy() {
    const ref = RootAppComponent.dialog.open(AllergyCreateEditComponent, {
      data: {
        medicalHistoryID: this.medicalHistoryID,
      },
      width: '650px',
    });

    ref.componentInstance.getCreated.subscribe((res) => {
      if (res && res.ID) {
        this.allergyList.push(res);
        ref.close();
      }
    });

    ref.componentInstance.closeEvent.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  getMedicationExist(e) {
    this.medicationExist = e;
  }

  stringToBoolean(s) {
    if (s == '1') {
      return true;
    }

    return false;
  }
}
