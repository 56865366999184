import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { Settings } from '../../../shared/types/settings';
import { MAT_DIALOG_DATA } from '@angular/material';
import { SettlementService } from '../shared/settlement.service';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialogSingleButtonComponent } from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogMultipleComponent } from '../../../shared/components/confirm-dialog-multiple/confirm-dialog-multiple.component';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import * as _ from 'lodash';

@Component({
  selector: 'app-settlement-new-modal',
  templateUrl: './settlement-new-modal.component.html',
  styleUrls: ['./settlement-new-modal.component.css'],
})
export class SettlementNewModalComponent implements OnInit {
  @Output()
  close = new EventEmitter();

  isPromoterOrAdmin = false;

  getMaterialRequest = new EventEmitter();

  settings = Settings.global;

  context = Settings.global['context'];

  disable = true;
  proceedType = 'none';
  error;
  warning;

  contractID;
  settlementID;
  merchantID;
  customerID;
  sessionType;
  settlementTransactionToken;

  materialRequestValid = false;
  settlementList = [];

  redirect = true;
  constructor(
    private router: Router,
    private settlementService: SettlementService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.contractID) {
        this.contractID = data.contractID;
      }

      if (data.merchantID) {
        this.merchantID = data.merchantID;
      }

      if (data.settlementID) {
        this.settlementID = data.settlementID;
      }

      if (data.settlementTransactionToken) {
        this.settlementTransactionToken = data.settlementTransactionToken;
      }
      if (data.customerID) {
        this.customerID = data.customerID;
      }

      if (data.redirect === false) {
        this.redirect = data.redirect;
      } else {
        this.redirect = true;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      this.authenticationService.getSessionType().subscribe((sessionType) => {
        this.sessionType = sessionType;

        this.setup();
      });
    });
  }

  setup() {
    let p = {
      merchantID: this.merchantID,
      settlementID: this.settlementID,
      contractID: this.contractID,
      customerID: this.customerID,
    };

    this.settlementService.verifyMaterialRequest(p, this.sessionType).subscribe((r) => {
      if (r) {
        this.error = r.errorHTML;
        this.proceedType = r.proceedType;

        this.settlementList = r.settlementList || [];

        this.materialRequestValid = r.valid;

        if (r.valid !== true && this.error && this.proceedType === 'none') {
          this.disable = true;
        } else {
          this.disable = false;
        }
      }
    });
  }

  sendType(t) {
    if (t === 'material') {
      if (this.materialRequestValid !== false) {
        this.redirectEvent(t);
      } else {
        if (this.error && this.proceedType === 'cancel') {
          const ref2 = RootAppComponent.dialog.open(ConfirmDialogMultipleComponent, {
            data: {
              icon: 'fas fa-exclamation-triangle',
              description: `<p>${this.error}</p> <p>In order to proceed, you will need to withdraw other requests.</p>`,
              yesLabel: 'Yes, proceed.',
              noLabel: 'Cancel',
              isSecondConfirmation: false,
              informationLabel: 'View Pending/Request list',
              title: 'Do you wish to continue?',
            },
            width: '500px',
          });

          ref2.componentInstance.confirmation.subscribe((confirmation) => {
            if (confirmation == true) {
              if (this.settlementList && this.settlementList.length > 0) {
                let ids = _.map(this.settlementList, (item) => {
                  return item.ID;
                });

                this.settlementService.cancelSettlementGroup({ ids: ids }, this.sessionType).subscribe((res) => {
                  if (res) {
                    NotifyAppComponent.displayToast('success', 'Success!', '');

                    ref2.close();
                    ref2.afterClosed().subscribe((r) => {
                      this.redirectEvent(t);
                    });
                  }
                });
              } else {
                NotifyAppComponent.displayToast('success', 'Success!', '');

                ref2.close();
                ref2.afterClosed().subscribe((r) => {
                  this.redirectEvent(t);
                });
              }
            }
          });
          ref2.componentInstance.information.subscribe((information) => {
            if (information == true) {
              let data = {
                contractID: this.contractID,
                statusCodes: ['REQU', 'PEND'],
                pageSize: 100,
              };
              AuthenticationService.settlementList.emit(data);
            }
          });
        } else if (this.error && this.proceedType === 'approve') {
          const confirm = new ConfirmDialog(
            'fas fa-exclamation-triangle',
            'You can not request a material advance.',
            `<p>${this.error}</p>`,
            'Close',
            'View Application Settlements'
          );
          const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
            data: confirm,
            width: '600px',
          });
          ref.componentInstance.onConfirm.subscribe((confirmation) => {
            if (confirmation === false) {
              ref.close();
            } else {
              let data = {
                contractID: this.contractID,
                pageSize: 100,
              };
              AuthenticationService.settlementList.emit(data);
            }
          });
        }
      }
    } else {
      this.redirectEvent(t);
    }
  }

  viewContractSettlements() {
    let data = {
      contractID: this.contractID,
      pageSize: 100,
    };
    AuthenticationService.settlementList.emit(data);
  }

  redirectEvent(t) {
    if (t == 'material') {
      if (this.customerID) {
        this.router.navigate([
          '/merchant',
          { outlets: { page: ['settlement-create-material-request/customer/', this.customerID, 'material'] } },
        ]);
      } else if (this.contractID) {
        if (this.settlementTransactionToken) {
          this.router.navigate([
            '/merchant',
            {
              outlets: {
                page: [
                  'settlement-create-material-request/contract',
                  this.contractID,
                  this.settlementTransactionToken,
                  'material',
                ],
              },
            },
          ]);
        } else {
          this.router.navigate([
            '/merchant',
            { outlets: { page: ['settlement-create-material-request', this.contractID, 'material'] } },
          ]);
        }
      } else {
        this.router.navigate(['/merchant', { outlets: { page: ['settlement-create-material-request', 'material'] } }]);
      }

      this.closeEvent();
    } else {
      if (this.customerID) {
        this.router.navigate(['/merchant', { outlets: { page: ['settlement-create/customer/', this.customerID] } }]);
      } else if (this.contractID) {
        if (this.settlementTransactionToken) {
          this.router.navigate([
            '/merchant',
            { outlets: { page: ['settlement-create/contract', this.contractID, this.settlementTransactionToken] } },
          ]);
        } else {
          this.router.navigate(['/merchant', { outlets: { page: ['settlement-create', this.contractID] } }]);
        }
      } else {
        this.router.navigate(['/merchant', { outlets: { page: ['settlement-create'] } }]);
      }

      this.closeEvent();
    }
  }
  closeEvent() {
    this.close.emit(true);
  }

  displayError() {
    if (this.error) {
      const confirmDialog2 = new ConfirmDialogSingleButton(
        'fas fa-exclamation',
        'Materials Advance is not available',
        `<p style='text-align:center;width:100%';>${this.error}</p>`,
        'OKAY',
        false,
        'rgb(199, 0, 0)'
      );
      const ref2 = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
        data: confirmDialog2,
        width: '600px',
      });
      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });
    }
  }
}
