import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BankAccountCreateEditComponent } from '../bank-account-create-edit/bank-account-create-edit.component';
import { BankAccountListComponent } from '../bank-account-list/bank-account-list.component';
import { BankAccountListUserComponent } from '../bank-account-user/BankAccountListUserComponent';
import { BankAccountViewComponent } from '../bank-account-view/bank-account-view.component';
import { PaymentListComponent } from '../payment-list/payment-list.component';
import { PaymentViewComponent } from '../payment-view/payment-view.component';

const routes: Routes = [
  {
    path: 'BankAccountListUserComponent',
    component: BankAccountListUserComponent,
  },
  {
    path: 'BankAccountListComponent',
    component: BankAccountListComponent,
  },
  {
    path: 'bank-account-create',
    component: BankAccountCreateEditComponent,
  },
  {
    path: 'bank-account-view',
    component: BankAccountViewComponent,
  },
  {
    path: 'payment-list',
    component: PaymentListComponent,
  },
  {
    path: 'payment-view',
    component: PaymentViewComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BankAccountRoutingModule {}
