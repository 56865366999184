import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogSingleButtonComponent } from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { ScheduleAppointmentViewComponent } from '../schedule-appointment-view/schedule-appointment-view.component';
import { AppointmentService } from '../shared/appointment.service';
import { AppointmentUtilClass } from '../shared/appointmentUtil';

@Component({
  selector: 'app-request-appointment-view',
  templateUrl: './request-appointment-view.component.html',
  styleUrls: ['./request-appointment-view.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.stacked-card-view', style({ opacity: 0 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.3s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.stacked-card-view', style({ opacity: 1 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class RequestAppointmentViewComponent implements OnInit {
  @Input() appointmentID;
  appointment;

  @Output()
  close = new EventEmitter();

  @Output()
  confirm = new EventEmitter();

  sessionType;
  dateNow = new Date();
  runAction = new EventEmitter();
  appointmentUtil = new AppointmentUtilClass();
  util = new UtilsClass();
  scheduleAppointmentViewRef: MatDialogRef<ScheduleAppointmentViewComponent>;

  constructor(
    private appointmentService: AppointmentService,
    private authenticationService: AuthenticationService,
    private router: Router,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.appointmentID = data.appointmentID;
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;

        if (this.appointmentID) {
          this.appointmentService.getAppointmentDetails(this.appointmentID, sessionType).subscribe((res) => {
            this.appointment = res;
          });
        }
      }
    });
  }

  runActionEvent(item, operation, isGroup = false) {
    if (item) {
      const p = {
        item,
        operation,
        isGroup,
      };
      this.runAction.emit(p);
    }
  }

  getActionResult(r) {
    this.appointment = r;
  }

  scheduleAppointment() {
    this.scheduleAppointmentViewRef = RootAppComponent.dialog.open(ScheduleAppointmentViewComponent, {
      data: {
        appointmentID: this.appointmentID,
      },
      width: '900px',
      height: '100%',
      panelClass: 'noCard',
    });

    this.scheduleAppointmentViewRef.componentInstance.close.subscribe((res) => {
      if (res == true) {
        this.scheduleAppointmentViewRef.close();
      }
    });

    this.scheduleAppointmentViewRef.componentInstance.confirm.subscribe((res) => {
      if (res) {
        this.confirm.emit(res);
      }
    });
  }

  gotoCustomerPage() {
    this.closeEvent();
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['customer-profile', this.appointment.CustomerOrProspect_Key, 'patient'],
        },
      },
    ]);
  }

  showComment(title, comment) {
    if (title && comment) {
      const confirmDialog2 = new ConfirmDialogSingleButton('comment', title, comment, 'OKAY');

      const ref2 = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
        data: confirmDialog2,
      });

      ref2.componentInstance.onConfirm.subscribe((res) => {
        ref2.close();
      });
    }
  }
}
