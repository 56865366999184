<div class="row clearfix masonry" *ngIf="quotation && patient">
  <div class="row clearfix">
    <h2 class="sr-title ndge rm-mt pull-left">Application details</h2>
  </div>
  <mat-card
    class="mb clearfix"
    *ngIf="
      quotation['Quotation.Status.Code'] == 'COMPLETE' ||
      quotation['Quotation.Status.Code'] == 'WON' ||
      quotation['Quotation.Status.Code'] == 'LOST' ||
      quotation['Quotation.Status.Code'] == 'DECLINED'
    "
  >
    <div class="card-header primary-gradient-img row flex flex-center">
      <div class="full-width">
        <div class="row clearfix">
          <app-ep-document-view-image
            class="patientView userIconView pull-left mr"
            [loading]="loading"
            [defaultPicture]="
              'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
            "
          ></app-ep-document-view-image>

          <h2 class="summary-header rm-m white">
            {{ patient['Salutation'] }} {{ patient['CalculatedName'] }}
            <span class="subLabel">
              Quote Date: <span class="value">{{ quotation['Quotation.Date'] | customDate }}</span></span
            >
          </h2>
        </div>
      </div>

      <div class="full-width ref-container">
        <div class="row clearfix text-right" *ngIf="quotation['Quotation.Status.Code'] == 'COMPLETE'">
          <p class="white reduce-marg">
            At this stage only the repayment frequency can be modified (but not the overall duration)
            <button class="reduce-marg" (click)="openContractDetail(quotation['ID'])" mat-raised-button color="accent">
              <mat-icon class="fas fa-file-signature"></mat-icon> View Contract
            </button>
          </p>
        </div>
        <div class="row clearfix text-right" *ngIf="quotation['Quotation.Status.Code'] == 'WON'">
          <p class="white reduce-marg">This quotation has already been converted to a Contract</p>
          <button class="reduce-marg" (click)="openContractDetail(quotation['ID'])" mat-raised-button color="accent">
            <mat-icon class="fas fa-file-signature"></mat-icon> View Contract
          </button>
        </div>

        <div
          class="row clearfix text-right"
          *ngIf="quotation['Quotation.Status.Code'] == 'LOST' || quotation['Quotation.Status.Code'] == 'DECLINED'"
        >
          <p class="white reduce-marg">
            You can no longer modify loan parameters, because the quotation has been marked as
            <span class="chip bad">{{ quotation['Quotation.Status.Code'] }}</span>
          </p>
        </div>
      </div>
    </div>

    <div class="row clearfix flex mt">
      <div class="row clearfix full-width">
        <div class="block">
          <mat-icon class="fas fa-user"></mat-icon>
          <label>Name</label>
          <h4 class="sr-title rm-mt">{{ quotation['Customer.FullName'] }}</h4>
        </div>

        <div class="block">
          <mat-icon class="fas fa-mobile"></mat-icon>
          <label>Mobile</label>
          <h4 class="sr-title rm-mt">{{ quotation['Customer.Mobile'] | customPhone }}</h4>
        </div>
      </div>
      <div class="row clearfix full-width">
        <div class="block">
          <mat-icon class="fas fa-envelope"></mat-icon>
          <label>Email</label>
          <h4 class="sr-title rm-mt">{{ quotation['Customer.Email'] }}</h4>
        </div>

        <div class="block">
          <mat-icon class="fas fa-map-marked-alt"></mat-icon>
          <label>Address</label>
          <h4 class="sr-title rm-mt">{{ patient['addresses.Calculated'] }}</h4>
        </div>
      </div>

      <!-- </mat-card>

  <mat-card class="clearfix full-width"> -->
      <!-- <div class="colour-stripe "></div> -->
      <!-- <h3 class="sr-title sm-h3 rm-m">Funding Details</h3>
    <hr> -->

      <div class="row clearfix full-width">
        <!-- <div class="block">

        <app-dentist-view-picture class=" headNavView userIconView">
        </app-dentist-view-picture>
        <label>Funder</label>
        <h4 class="sr-title rm-mt">{{quotation['FunderName']}}
        </h4>
      </div> -->

        <div class="block">
          <mat-icon class="fas fa-hand-holding-heart"></mat-icon>
          <label>Product</label>
          <h4 class="sr-title rm-mt">{{ quotation['ProductName'] }}</h4>
        </div>
        <!-- </div>
    <div class="row clearfix"> -->
        <!-- <div class="block">
        <mat-icon class="fas fa-question-circle"></mat-icon>
        <label>Product Type</label>
        <h4 class="sr-title rm-mt">{{quotation['ProductTypeLabel']}}
        </h4>
      </div> -->

        <div class="block">
          <app-dentist-view-picture class="headNavView userIconView"></app-dentist-view-picture>
          <label>{{ "KEYWORD.practitioner" | translate | titlecase }}</label>
          <h4 class="sr-title rm-mt">{{ quotation['MerchantName'] }}</h4>
        </div>
      </div>
    </div>
  </mat-card>

  <!-- <div class="full-width"> -->
  <mat-tab-group class="tab-container" [(selectedIndex)]="setApplicationsTab" (selectedIndexChange)="subTabChange()">
    <mat-tab #overview label="Overview">
      <ng-template matTabContent>
        <ng-container @simpleFadeAnimation>
          <!-- <mat-card class="clearfix mb">
        <div class="colour-stripe "></div>

        <h3 class="sr-title sm-h3 rm-m">Overview</h3>
        <hr>
        <div class="row clearfix">

          <app-ep-document-view-image [loading]="loading" class="patientView userIconView pull-left mr"
            [defaultPicture]="'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'"></app-ep-document-view-image>

          <h2 class="summary-header rm-m">
            Quotation
            <span class="subLabel">{{patient['Salutation']}} {{patient['CalculatedName']}}
              {{quotation['ProductName']}}</span>
          </h2>
        </div> -->
          <!-- <div class="row clearfix flex">
          <div class="full-width">
            <p>Quote Date: <span class="value">{{quotation['Quotation.Date'] | customDate}}</span></p> -->
          <!-- <p>Quote Expiry: <span class="value">{{quotation['Quotation.Expiry'] | customDate}}</span></p>
            <p>Account Manager: <span class="value">{{quotation['AccountManagerName']}}</span></p>
            <p>Nickname: <span class="value">{{quotation['ProductName']}}</span></p> -->
          <!-- </div> -->
          <!-- <div class="full-width">
            <p>Status: <span class="value">{{quotation['Quotation.Status.Label']}}</span></p>
            <p>Done: <span class="value">{{quotation['Done']}}</span></p>
            <p>To-Do: <span class="value">{{quotation['ToDo']}}</span></p>
          </div> -->
          <!-- <div class="full-width">
            <p>Quote ID: <span class="value">{{quotation['ID']}}</span></p>
            <p>Contract Status: <span class="value">{{quotation['Status']}}</span></p>
          </div> -->
          <!-- </div>
      </mat-card> -->

          <!-- <h3>
      <mat-icon class="fas fa-calculator"></mat-icon> Calculator
    </h3> -->

          <div class="row clearfix">
            <div class="row clearfix flex">
              <mat-card class="clearfix full-width">
                <div class="colour-stripe"></div>
                <h3 class="sr-title sm-h3 rm-m">Invioce Details as Anticipated</h3>
                <hr/>

                <mat-list class="values-list calculation">
                  <mat-list-item class="total accent-color">
                    <p class="bold accent-color">Invoice Amount:</p>
                    <span class="value accent-color">{{ quotation['Amount.Invoice'] | customCurrency }}</span>
                  </mat-list-item>
                  <mat-list-item>
                    <p>
                      <mat-icon class="fas fa-minus-circle"></mat-icon>
                      {{ "KEYWORD.patient" | translate | titlecase }} Contribution:
                    </p>
                    <span class="value">{{ quotation['Amount.Contribution'] | customCurrency }}</span>
                  </mat-list-item>
                  <mat-list-item class="">
                    <p class="bold primary-color">
                      <mat-icon class="fas fa-equals"></mat-icon>
                      Loan Amount:
                    </p>
                    <span class="primary-color value">{{
                      quotation['Amount.FinancedBeforeDeposit'] | customCurrency
                      }}</span>
                  </mat-list-item>
                  <mat-list-item>
                    <!-- <hr> -->
                    <p>
                      <mat-icon class="fas fa-minus-circle"></mat-icon>
                      Deposit:
                    </p>
                    <span class="value">{{ quotation['Deposit'] | customCurrency }}</span>
                  </mat-list-item>
                  <mat-list-item>
                    <p class="bold primary-color">
                      <mat-icon class="fas fa-equals"></mat-icon>
                      Amount Financed:
                    </p>
                    <span class="primary-color value">{{ quotation['Amount.Financed'] | customCurrency }}</span>
                  </mat-list-item>
                  <mat-list-item>
                    <!-- <hr> -->
                    <p>
                      <mat-icon class="fas fa-plus-circle"></mat-icon>
                      Establishment Fee:
                    </p>
                    <span class="value">{{ quotation['EstablishmentFee'] | customCurrency }}</span>
                  </mat-list-item>
                  <mat-list-item>
                    <p>
                      <mat-icon class="fas fa-plus-circle"></mat-icon>
                      Interest:
                    </p>
                    <span class="value">{{ quotation['OriginalInterest'] | customCurrency }}</span>
                  </mat-list-item>
                  <mat-list-item>
                    <p>
                      <mat-icon class="fas fa-plus-circle"></mat-icon>
                      Account Keeping Fees:
                    </p>
                    <span class="value">{{ quotation['AccountKeepingFees'] | customCurrency }}</span>
                  </mat-list-item>
                  <mat-list-item>
                    <p>
                      <mat-icon class="fas fa-plus-circle"></mat-icon>
                      Commission:
                    </p>
                    <span class="value">{{ quotation['CustomerCommission'] | customCurrency }}</span>
                  </mat-list-item>
                  <mat-list-item>
                    <p class="primary-color">
                      <mat-icon class="fas fa-equals"></mat-icon>
                      Sub Total:
                    </p>
                    <span class="primary-color value">
                      {{
                      toNumber(quotation['EstablishmentFee']) +
                      toNumber(quotation['OriginalInterest']) +
                      toNumber(quotation['AccountKeepingFees']) +
                      toNumber(quotation['CustomerCommission']) | customCurrency
                      }}
                    </span>
                  </mat-list-item>
                  <mat-list-item class="primary-bg total">
                    <!-- <hr> -->
                    <p class="bold">Total ex. Deposit:</p>
                    <span class="value white">{{ quotation['Amount.Loan.Original'] | customCurrency }}</span>
                  </mat-list-item>
                  <mat-list-item>
                    <p class="primary-color"><strong>Total inc. Deposit:</strong></p>
                    <span class="primary-color value">{{ quotation['Amount.Original'] | customCurrency }}</span>
                  </mat-list-item>
                </mat-list>
              </mat-card>

              <div class="full-width card-column">
                <mat-card class="clearfix">
                  <div class="colour-stripe"></div>
                  <h3 class="sr-title sm-h3 rm-m">Payment Details</h3>
                  <hr/>
                  <div class="row clearfix">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Repayment Duration</mat-label>
                      <input
                        [(ngModel)]="selectedDuration"
                        [disabled]="quotation['Quotation.Status.Code'] != 'PENDING'"
                        (change)="changeDuration($event.target.value)"
                        matInput
                        name="duration"
                        placeholder="Repayment Duration"
                      />
                    </mat-form-field>
                  </div>
                  <div class="row clearfix">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Repayment Frequency</mat-label>

                      <mat-select
                        class="full-width"
                        [(ngModel)]="selectedFrequency"
                        [disabled]="
                          quotation['Quotation.Status.Code'] != 'PENDING' &&
                          quotation['Quotation.Status.Code'] != 'COMPLETE'
                        "
                        (ngModelChange)="changeFrequency($event)"
                        placeholder="Repayment Frequency"
                      >
                        <mat-option *ngFor="let f of frequency" [value]="f['Code']">{{ f['Label'] }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div
                    class="row clearfix text-center"
                    *ngIf="
                      quotation['Quotation.Status.Code'] == 'PENDING' ||
                      quotation['Quotation.Status.Code'] == 'COMPLETE'
                    "
                  >
                    <button
                      class="btn-small text-center"
                      [disabled]="enableSubmitBtn == false"
                      (click)="submit()"
                      mat-raised-button
                      color="primary"
                    >
                      Change Frequency
                    </button>
                  </div>
                </mat-card>

                <!-- <mat-card class="clearfix">
              <div class="colour-stripe "></div>
              <div class="row clearfix">
                <h3 class="sr-title sm-h3 rm-m">Interest Rate(flat)</h3>
                <hr>
                <div class="row clearfix">
                  <div class="half-width text-center">
                    <p class="lessmb">Annual</p>
                    <h3 class="primary-color rm-m">{{quotation['InterestRate.Annual']}}%</h3>
                  </div>
                  <div class="half-width text-center">
                    <p class="lessmb">True</p>
                    <h3 class="primary-color rm-m">{{quotation['InterestRate.True']}}%</h3>
                  </div>
                </div>
              </div>
            </mat-card> -->

                <!-- <mat-card class="clearfix">
              <div class="colour-stripe "></div>
              <div class="row clearfix">
                <h3 class="sr-title sm-h3 rm-m">Promotion</h3>
                <hr>
                <mat-list class="values-list">
                  <mat-list-item *ngIf="quotation['PromoCode']">
                    <p>Code:</p>
                    <span class="value">{{quotation['PromoCode']}}</span>
                  </mat-list-item>
                  <mat-list-item>
                    <p>Interest Free Period:</p>
                    <span class="value">MISSING</span>
                  </mat-list-item>
                </mat-list>
              </div>
            </mat-card> -->
              </div>

              <!-- <mat-card class="clearfix full-width">
            <div class="colour-stripe "></div>
            <h3 class="sr-title sm-h3 rm-m">{{ "KEYWORD.practitioner" | translate | titlecase }}</h3>
            <hr>
            <mat-list class="values-list calculation">
              <mat-list-item class="total primary-color">
                <p><strong>Draw Downs:</strong></p>
                <span class="value"><strong>{{quotation['TreatmentValue'] | customCurrency}}</strong></span>
              </mat-list-item>
              <mat-list-item>
                <p><em>Less {{ "KEYWORD.practitioner" | translate | titlecase }} Contribution</em></p>
              </mat-list-item>
              <mat-list-item>
                <p>Interest:</p>
                <span class="value">{{quotation['Merchant.Interest'] | customCurrency}}</span>
              </mat-list-item>
              <mat-list-item>
                <p>
                  <mat-icon class="fas fa-plus-circle"></mat-icon> Commission:
                </p>
                <span class="value">{{quotation['Merchant.Commission'] | customCurrency}}</span>
              </mat-list-item>
              <mat-list-item>
                <p>
                  <mat-icon class="fas fa-plus-circle"></mat-icon> Establishment Fee:
                </p>
                <span class="value">{{quotation['Merchant.EstablishmentFee'] | customCurrency}}</span>
              </mat-list-item>
              <mat-list-item>
                <p>
                  <mat-icon class="fas fa-plus-circle"></mat-icon> Account Keeping Fees:
                </p>
                <span class="value">{{quotation['Merchant.AccountKeepingFees'] | customCurrency}}</span>
              </mat-list-item>
              <mat-list-item>
                <p class="primary-color bold">
                  <mat-icon class="fas fa-equals"></mat-icon> <strong>Sub Total</strong>:
                </p>
                <span
                  class="primary-color value"><strong>{{quotation['Merchant.ContributionsSubtotal'] | customCurrency}}</strong></span>
              </mat-list-item>
              <mat-list-item>
                <p><em>Less {{ "KEYWORD.practitioner" | translate | titlecase }} Fees</em></p>
              </mat-list-item>
              <mat-list-item>
                <p>Recourse Release MSF: </p>
                <span class="value">{{quotation['Recourse_Release_MSF'] | customCurrency}}</span>
              </mat-list-item>
              <mat-list-item>
                <p>
                  <mat-icon class="fas fa-plus-circle"></mat-icon> Merchant Service Fee:
                </p>
                <span class="value">{{quotation['Merchant_Service_Fee'] | customCurrency}}</span>
              </mat-list-item>
              <mat-list-item>
                <p class="primary-color bold">
                  <mat-icon class="fas fa-equals"></mat-icon> <strong>Sub Total</strong>:
                </p>
                <span class="primary-color value">
                  <strong>{{(toNumber(quotation['Recourse_Release_MSF']) + toNumber(quotation['Merchant_Service_Fee']) )| customCurrency}}</strong>
                </span>
              </mat-list-item>
              <mat-list-item class="primary-bg total">
                <p><strong>Net to Merchant</strong>:</p>
                <span class="value"><strong>{{quotation['NetToMerchant'] | customCurrency}}</strong></span>
              </mat-list-item>
            </mat-list>

          </mat-card> -->
            </div>
          </div>
        </ng-container>
      </ng-template>
    </mat-tab>

    <mat-tab label="Payments">
      <ng-template #payments matTabContent>
        <ng-container @simpleFadeAnimation>
          <!-- <h3>Payments</h3> -->

          <mat-card class="nudge-btm">
            <div class="colour-stripe"></div>
            <h3 class="sr-title sm-h3 rm-m">Payment details</h3>
            <label>Scroll down for more results</label>
            <hr/>

            <div class="static-table">
              <div class="row clearfix flex">
                <h4 class="full-width duePadding">Due</h4>
                <h4 class="full-width">Borrower Repayment</h4>
                <h4 class="full-width" *ngIf="quotationProductSubType == 'With Interest'">Loan Establishment Fee</h4>
                <h4 class="full-width" *ngIf="quotationProductSubType == 'With Interest'">
                  Interest on Establishment Fee
                </h4>
                <h4 class="full-width" *ngIf="quotationProductSubType == 'With Interest'">Account Keeping Fee</h4>
                <h4 class="full-width" *ngIf="quotation['Deposit'] != 0">Deposit</h4>
                <h4 class="full-width">Sub Total</h4>
              </div>
              <div class="scrollPane">
                <!-- scrolling area within table -->
                <div
                  class="row clearfix"
                  *ngFor="let payment of paymentList; let i = index"
                  [class.greyRow]="i % 2 == 0"
                >
                  <div class="clearfix flex" *ngIf="payment['offsetHowMany'] == '0'">
                    <p class="full-width duePadding">Acceptance Date</p>
                    <p class="full-width">{{ payment['repayments'] | customCurrency }}</p>
                    <p class="full-width" *ngIf="quotationProductSubType == 'With Interest'">
                      {{ payment['fee'] | customCurrency }}
                    </p>
                    <p class="full-width" *ngIf="quotationProductSubType == 'With Interest'">
                      {{ payment['interest'] | customCurrency }}
                    </p>
                    <p class="full-width" *ngIf="quotationProductSubType == 'With Interest'">&nbsp;</p>
                    <p class="full-width" *ngIf="quotation['Deposit'] != 0">
                      {{ quotation['Deposit'] | customCurrency }}
                    </p>
                    <p class="full-width">
                      <span *ngIf="quotationProductSubType == 'With Interest'">
                        {{
                        toNumber(payment['repayments']) +
                        toNumber(payment['fee']) +
                        toNumber(payment['interest']) +
                        toNumber(quotation['Deposit']) | customCurrency
                        }}
                      </span>
                      <span *ngIf="quotationProductSubType != 'With Interest'">
                        {{ toNumber(payment['repayments']) + toNumber(quotation['Deposit']) | customCurrency }}
                      </span>
                    </p>
                  </div>

                  <div class="clearfix flex" *ngIf="payment['offsetHowMany'] != '0'">
                    <p class="full-width duePadding">
                      Acceptance Date + {{ payment['offsetHowMany'] }}
                      <span *ngIf="payment['offsetCode']"
                      >{{ getOffsetCodeLabel(payment['offsetCode'])
                        }}<span *ngIf="payment['offsetHowMany'] != 1">s</span></span
                      >
                      <span *ngIf="!payment['offsetCode']"
                      >Month<span *ngIf="payment['offsetHowMany'] != 1">s</span></span
                      >
                    </p>
                    <p class="full-width">{{ payment['repayments'] | customCurrency }}</p>
                    <p class="full-width" *ngIf="quotationProductSubType == 'With Interest'">
                      {{ payment['fee'] | customCurrency }}
                    </p>
                    <p class="full-width" *ngIf="quotationProductSubType == 'With Interest'">
                      {{ payment['interest'] | customCurrency }}
                    </p>
                    <p class="full-width" *ngIf="quotationProductSubType == 'With Interest'">
                      {{ payment['akf'] | customCurrency }}
                    </p>
                    <p class="full-width" *ngIf="quotation['Deposit'] != 0">&nbsp;</p>
                    <p
                      class="full-width"
                      *ngIf="quotation['RepaymentFrequency.Code'] != '4' && quotationProductSubType == 'With Interest'"
                    >
                      <span *ngIf="!payment['akf']">
                        {{
                        toNumber(payment['repayments']) + toNumber(payment['fee']) + toNumber(payment['interest'])
                          | customCurrency
                        }}
                      </span>
                      <span *ngIf="payment['akf']">{{ payment['akf'] | customCurrency }}</span>
                    </p>
                    <p
                      class="full-width"
                      *ngIf="quotation['RepaymentFrequency.Code'] == '4' && quotationProductSubType == 'With Interest'"
                    >
                      {{
                      toNumber(payment['repayments']) +
                      toNumber(payment['fee']) +
                      toNumber(payment['interest']) +
                      toNumber(payment['akf']) | customCurrency
                      }}
                    </p>
                    <p
                      class="full-width"
                      *ngIf="quotation['RepaymentFrequency.Code'] != '4' && quotationProductSubType != 'With Interest'"
                    >
                      {{ payment['repayments'] | customCurrency }}
                    </p>
                  </div>
                </div>
              </div>
              <!-- lower totals area -->
              <div class="row clearfix flex totals-area primary-bg">
                <h4 class="full-width duePadding">Total</h4>
                <h4 class="full-width">{{ totalRepayment | customCurrency }}</h4>
                <h4 class="full-width" *ngIf="quotationProductSubType == 'With Interest'">
                  {{ quotation['EstablishmentFee'] | customCurrency }}
                </h4>
                <h4 class="full-width" *ngIf="quotationProductSubType == 'With Interest'">
                  {{ totalInterest | customCurrency }}
                </h4>
                <h4 class="full-width" *ngIf="quotationProductSubType == 'With Interest'">
                  {{ quotation['AccountKeepingFees'] | customCurrency }}
                </h4>
                <h4 class="full-width" *ngIf="quotation['Deposit'] != 0">
                  {{ quotation['Deposit'] | customCurrency }}
                </h4>
                <h4 class="full-width">{{ quotation['Amount.Original'] | customCurrency }}</h4>
              </div>
            </div>
          </mat-card>
        </ng-container>
      </ng-template>
    </mat-tab>

    <mat-tab label="Compliance">
      <ng-template matTabContent>
        <ng-container @simpleFadeAnimation>
          <mat-card class="clearfix">
            <app-compliance-task [contractID]="quotation['ID']" [largeHeading]="false"></app-compliance-task>
          </mat-card>
        </ng-container>
      </ng-template>
    </mat-tab>

    <mat-tab label="Notes">
      <ng-template matTabContent>
        <ng-container @simpleFadeAnimation>
          <mat-card class="clearfix">
            <app-event-log-list [largeHeading]="false" [type]="'contract'" [contractID]="quotation['ID']">
            </app-event-log-list>
          </mat-card>
        </ng-container>
      </ng-template>
    </mat-tab>

    <mat-tab label="Documents">
      <ng-template matTabContent>
        <ng-container @simpleFadeAnimation>
          <!-- <h3>Documents</h3> -->
          <app-contract-document [contractID]="quotation['ID']"></app-contract-document>
        </ng-container>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <!-- <mat-tab-group dynamicHeight="true" (selectedIndexChange)="tabChange($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="fas fa-calculator"></mat-icon> Calculator
        </ng-template>
        <div class="row clearfix">
          <div class="row clearfix flex">
            <mat-card class="clearfix full-width">
              <div class="colour-stripe "></div>

              <h3 class="sr-title sm-h3 rm-m">{{ "KEYWORD.patient" | translate | titlecase }}</h3>
              <hr>

              <div class="row clearfix">
                <div class="half-width block">
                  <mat-icon class="fas fa-user"></mat-icon>
                  <label>Name</label>
                  <h4 class="sr-title rm-mt">{{quotation['Customer.FullName']}}
                  </h4>
                </div>

                <div class="half-width block">
                  <mat-icon class="fas fa-mobile"></mat-icon>
                  <label>Mobile</label>
                  <h4 class="sr-title rm-mt">{{quotation['Customer.Mobile'] | customPhone}}
                  </h4>
                </div>
              </div>
              <div class="row clearfix">
                <div class="half-width block">
                  <mat-icon class="fas fa-envelope"></mat-icon>
                  <label>Email</label>
                  <h4 class="sr-title rm-mt">{{quotation['Customer.Email']}}
                  </h4>
                </div>

                <div class="half-width block">
                  <mat-icon class="fas fa-map-marked-alt"></mat-icon>
                  <label>Address</label>
                  <h4 class="sr-title rm-mt">{{patient['addresses.Calculated']}}
                  </h4>
                </div>
              </div>

            </mat-card>

            <mat-card class="clearfix full-width">
              <div class="colour-stripe "></div>
              <h3 class="sr-title sm-h3 rm-m">Funding Details</h3>
              <hr>

              <div class="row clearfix">
                <div class="half-width block">

                  <app-dentist-view-picture class=" headNavView userIconView">
                  </app-dentist-view-picture>
                  <label>Funder</label>
                  <h4 class="sr-title rm-mt">{{quotation['FunderName']}}
                  </h4>
                </div>

                <div class="half-width block">
                  <mat-icon class="fas fa-hand-holding-heart"></mat-icon>
                  <label>Product</label>
                  <h4 class="sr-title rm-mt">{{quotation['ProductName']}}
                  </h4>
                </div>
              </div>
              <div class="row clearfix">
                <div class="half-width block">
                  <mat-icon class="fas fa-question-circle"></mat-icon>
                  <label>Product Type</label>
                  <h4 class="sr-title rm-mt">{{quotation['ProductTypeLabel']}}
                  </h4>
                </div>

                <div class="half-width block">
                  <app-dentist-view-picture class=" headNavView userIconView">
                  </app-dentist-view-picture>
                  <label>{{ "KEYWORD.practitioner" | translate | titlecase }}</label>
                  <h4 class="sr-title rm-mt">{{quotation['MerchantName']}}
                  </h4>
                </div>
              </div>

            </mat-card>
          </div>

          <div class="row clearfix flex">
         <mat-card class="clearfix full-width">
              <div class="colour-stripe "></div>
              <h3 class="sr-title sm-h3 rm-m"></h3>
            </mat-card>
            <h2 class="sr-title">
              Invioce Details as Anticipated
            </h2>
          </div>

          <div class="row clearfix flex">
            <mat-card class="clearfix full-width">
              <div class="colour-stripe "></div>
              <h3 class="sr-title sm-h3 rm-m">{{ "KEYWORD.patient" | translate | titlecase }}</h3>
              <hr>


              <mat-list class="values-list calculation">
                <mat-list-item class="total accent-color">
                  <p class="bold accent-color">Invoice Amount:</p>
                  <span class="value accent-color">{{quotation['Amount.Invoice'] | customCurrency}}</span>
                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-minus-circle"></mat-icon> {{ "KEYWORD.patient" | translate | titlecase }} Contribution:
                  </p>
                  <span class="value">{{quotation['Amount.Contribution'] | customCurrency}}</span>
                </mat-list-item>
                <mat-list-item class="">
                  <p class="bold primary-color">
                    <mat-icon class="fas fa-equals"></mat-icon> Loan Amount:
                  </p>
                  <span
                    class=" primary-color value">{{quotation['Amount.FinancedBeforeDeposit'] | customCurrency}}</span>
                </mat-list-item>
                <mat-list-item>

                  <p>
                    <mat-icon class="fas fa-minus-circle"></mat-icon> Deposit:
                  </p>
                  <span class="value">{{quotation['Deposit'] | customCurrency}}</span>
                </mat-list-item>
                <mat-list-item>
                  <p class="bold primary-color">
                    <mat-icon class="fas fa-equals"></mat-icon> Amount Financed:
                  </p>
                  <span class=" primary-color value">{{quotation['Amount.Financed'] | customCurrency}}</span>
                </mat-list-item>
                <mat-list-item>

                  <p>
                    <mat-icon class="fas fa-plus-circle"></mat-icon> Establishment Fee:
                  </p>
                  <span class="value">{{quotation['EstablishmentFee'] | customCurrency}}</span>

                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-plus-circle"></mat-icon> Interest:
                  </p>
                  <span class="value">{{quotation['OriginalInterest'] | customCurrency}}</span>
                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-plus-circle"></mat-icon> Account Keeping Fees:
                  </p>
                  <span class="value">{{quotation['AccountKeepingFees'] | customCurrency}}</span>

                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-plus-circle"></mat-icon> Commission:
                  </p>
                  <span class="value">{{quotation['CustomerCommission'] | customCurrency}}</span>
                </mat-list-item>
                <mat-list-item>
                  <p class="primary-color">
                    <mat-icon class="fas fa-equals"></mat-icon> Sub Total:
                  </p>
                  <span class="primary-color value">
                    {{(toNumber(quotation['EstablishmentFee']) + toNumber(quotation['OriginalInterest']) + toNumber(quotation['AccountKeepingFees'])
                + toNumber(quotation['CustomerCommission'])) | customCurrency}}
                  </span>
                </mat-list-item>
                <mat-list-item class="primary-bg total">

                  <p class="bold">Total ex. Deposit:</p>
                  <span class="value">{{quotation['Amount.Loan.Original'] | customCurrency}}</span>

                </mat-list-item>
                <mat-list-item>
                  <p class="primary-color"><strong>Total inc. Deposit:</strong> </p>
                  <span class="primary-color value">{{quotation['Amount.Original'] | customCurrency}}</span>

                </mat-list-item>
              </mat-list>

            </mat-card>

            <div class="full-width card-column">

              <mat-card class="clearfix">
                <div class="colour-stripe "></div>
                <h3 class="sr-title sm-h3 rm-m">Payment Details</h3>
                <hr>
                <div class="row clearfix">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Repayment Duration</mat-label>
                    <input matInput name="duration" placeholder="Repayment Duration" [(ngModel)]="selectedDuration"
                      (change)="changeDuration($event.target.value)"
                      [disabled]="quotation['Quotation.Status.Code']!='PENDING'">
                  </mat-form-field>
                </div>
                <div class="row clearfix">

                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Repayment Frequency</mat-label>

                    <mat-select class="full-width" placeholder="Repayment Frequency" [(ngModel)]="selectedFrequency"
                      (ngModelChange)="changeFrequency($event)"
                      [disabled]="quotation['Quotation.Status.Code']!='PENDING' && quotation['Quotation.Status.Code']!='COMPLETE'">
                      <mat-option *ngFor="let f of frequency" [value]="f['Code']">{{f['Label']}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="row clearfix text-center"
                  *ngIf="quotation['Quotation.Status.Code']=='PENDING' || quotation['Quotation.Status.Code']=='COMPLETE'">
                  <button mat-raised-button color="primary" class="btn-small text-center"
                    [disabled]="enableSubmitBtn==false" (click)="submit()">
                    {{"ChangeFrequency" | translate}}
                  </button>
                </div>
              </mat-card>

              <mat-card class="clearfix">
                <div class="colour-stripe "></div>
                <div class="row clearfix">
                  <h3 class="sr-title sm-h3 rm-m">Interest Rate(flat)</h3>
                  <hr>
                  <div class="row clearfix">
                    <div class="half-width text-center">
                      <p class="lessmb">Annual</p>
                      <h3 class="primary-color rm-m">{{quotation['InterestRate.Annual']}}%</h3>
                    </div>
                    <div class="half-width text-center">
                      <p class="lessmb">True</p>
                      <h3 class="primary-color rm-m">{{quotation['InterestRate.True']}}%</h3>
                    </div>
                  </div>
                </div>
              </mat-card>

              <mat-card class="clearfix">
                <div class="colour-stripe "></div>
                <div class="row clearfix">
                  <h3 class="sr-title sm-h3 rm-m">Promotion</h3>
                  <hr>
                  <mat-list class="values-list">
                    <mat-list-item *ngIf="quotation['PromoCode']">
                      <p>Code:</p>
                      <span class="value">{{quotation['PromoCode']}}</span>
                    </mat-list-item>
                    <mat-list-item>
                      <p>Interest Free Period:</p>
                      <span class="value">MISSING</span>
                    </mat-list-item>
                  </mat-list>
                </div>
              </mat-card>

            </div>

            <mat-card class="clearfix full-width">
              <div class="colour-stripe "></div>
              <h3 class="sr-title sm-h3 rm-m">{{ "KEYWORD.practitioner" | translate | titlecase }}</h3>
              <hr>
              <mat-list class="values-list calculation">
                <mat-list-item class="total primary-color">
                  <p><strong>Draw Downs:</strong></p>
                  <span class="value"><strong>{{quotation['TreatmentValue'] | customCurrency}}</strong></span>
                </mat-list-item>
                <mat-list-item>
                  <p><em>Less {{ "KEYWORD.practitioner" | translate | titlecase }} Contribution</em></p>
                </mat-list-item>
                <mat-list-item>
                  <p>Interest:</p>
                  <span class="value">{{quotation['Merchant.Interest'] | customCurrency}}</span>
                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-plus-circle"></mat-icon> Commission:
                  </p>
                  <span class="value">{{quotation['Merchant.Commission'] | customCurrency}}</span>
                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-plus-circle"></mat-icon> Establishment Fee:
                  </p>
                  <span class="value">{{quotation['Merchant.EstablishmentFee'] | customCurrency}}</span>
                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-plus-circle"></mat-icon> Account Keeping Fees:
                  </p>
                  <span class="value">{{quotation['Merchant.AccountKeepingFees'] | customCurrency}}</span>
                </mat-list-item>
                <mat-list-item>
                  <p class="primary-color bold">
                    <mat-icon class="fas fa-equals"></mat-icon> <strong>Sub Total</strong>:
                  </p>
                  <span
                    class="primary-color value"><strong>{{quotation['Merchant.ContributionsSubtotal'] | customCurrency}}</strong></span>
                </mat-list-item>
                <mat-list-item>
                  <p><em>Less {{ "KEYWORD.practitioner" | translate | titlecase }} Fees</em></p>
                </mat-list-item>
                <mat-list-item>
                  <p>Recourse Release MSF: </p>
                  <span class="value">{{quotation['Recourse_Release_MSF'] | customCurrency}}</span>
                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-plus-circle"></mat-icon> Merchant Service Fee:
                  </p>
                  <span class="value">{{quotation['Merchant_Service_Fee'] | customCurrency}}</span>
                </mat-list-item>
                <mat-list-item>
                  <p class="primary-color bold">
                    <mat-icon class="fas fa-equals"></mat-icon> <strong>Sub Total</strong>:
                  </p>
                  <span class="primary-color value">
                    <strong>{{(toNumber(quotation['Recourse_Release_MSF']) + toNumber(quotation['Merchant_Service_Fee']) )| customCurrency}}</strong>
                  </span>
                </mat-list-item>
                <mat-list-item class="primary-bg total">
                  <p><strong>Net to Merchant</strong>:</p>
                  <span class="value"><strong>{{quotation['NetToMerchant'] | customCurrency}}</strong></span>
                </mat-list-item>
              </mat-list>

            </mat-card>


          </div>

        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="fas fa-hand-holding-usd"></mat-icon> Payments
        </ng-template>


        <mat-card class="nudge-btm" *ngIf="paymentsTab==true">
          <div class="colour-stripe "></div>
          <h3 class="sr-title sm-h3 rm-m">Payment details</h3>
          <label>Scroll down for more results</label>
          <hr>


          <div class="static-table">

            <div class="row clearfix flex">
              <h4 class="full-width duePadding">Due</h4>
              <h4 class="full-width">Borrower Repayment</h4>
              <h4 class="full-width" *ngIf="quotationProductSubType=='With Interest'">
                Loan Establishment Fee</h4>
              <h4 class="full-width" *ngIf="quotationProductSubType=='With Interest'">
                Interest on Establishment Fee</h4>
              <h4 class="full-width" *ngIf="quotationProductSubType=='With Interest'">
                Account Keeping Fee</h4>
              <h4 class="full-width" *ngIf="quotation['Deposit']!=0">Deposit</h4>
              <h4 class="full-width">Sub Total</h4>
            </div>
            <div class="scrollPane">

              <div class="row clearfix" *ngFor="let payment of paymentList; let i=index" [class.greyRow]="i%2==0">
                <div class="clearfix flex" *ngIf="payment['offsetHowMany']=='0'">
                  <p class="full-width duePadding">Acceptance Date</p>
                  <p class="full-width">{{payment['repayments'] | customCurrency}}</p>
                  <p class="full-width" *ngIf="quotationProductSubType=='With Interest'">
                    {{payment['fee'] | customCurrency}}</p>
                  <p class="full-width" *ngIf="quotationProductSubType=='With Interest'">
                    {{payment['interest'] | customCurrency}}</p>
                  <p class="full-width" *ngIf="quotationProductSubType=='With Interest'">&nbsp;</p>
                  <p class="full-width" *ngIf="quotation['Deposit']!=0">{{quotation['Deposit'] | customCurrency}}</p>
                  <p class="full-width">
                    <span *ngIf="quotationProductSubType=='With Interest'">
                      {{(toNumber(payment['repayments']) + toNumber(payment['fee']) +
                      toNumber(payment['interest']) + toNumber(quotation['Deposit'])) | customCurrency}}
                    </span>
                    <span *ngIf="quotationProductSubType!='With Interest'">
                      {{(toNumber(payment['repayments']) + toNumber(quotation['Deposit'])) | customCurrency}}
                    </span>
                  </p>
                </div>

                <div class="clearfix flex" *ngIf="payment['offsetHowMany']!='0'">
                  <p class="full-width duePadding">Acceptance Date + {{payment['offsetHowMany']}}
                    <span *ngIf="payment['offsetCode']">{{getOffsetCodeLabel(payment['offsetCode'])}}<span
                        *ngIf="payment['offsetHowMany']!=1">s</span></span>
                    <span *ngIf="!payment['offsetCode']">Month<span *ngIf="payment['offsetHowMany']!=1">s</span></span>
                  </p>
                  <p class="full-width">{{payment['repayments'] | customCurrency}}</p>
                  <p class="full-width" *ngIf="quotationProductSubType=='With Interest'">
                    {{payment['fee'] | customCurrency}}</p>
                  <p class="full-width" *ngIf="quotationProductSubType=='With Interest'">
                    {{payment['interest'] | customCurrency}}</p>
                  <p class="full-width" *ngIf="quotationProductSubType=='With Interest'">
                    {{payment['akf'] | customCurrency}}</p>
                  <p class="full-width" *ngIf="quotation['Deposit']!=0">&nbsp;</p>
                  <p class="full-width"
                    *ngIf="quotation['RepaymentFrequency.Code']!='4' && quotationProductSubType=='With Interest'">
                    <span *ngIf="!payment['akf']">
                      {{(toNumber(payment['repayments']) + toNumber(payment['fee']) +
                    toNumber(payment['interest'])) | customCurrency}}
                    </span>
                    <span *ngIf="payment['akf']">{{payment['akf'] | customCurrency}}</span>
                  </p>
                  <p class="full-width"
                    *ngIf="quotation['RepaymentFrequency.Code']=='4' && quotationProductSubType=='With Interest'">
                    {{(toNumber(payment['repayments']) + toNumber(payment['fee']) +
                  toNumber(payment['interest']) + toNumber(payment['akf'])) | customCurrency}}
                  </p>
                  <p class="full-width"
                    *ngIf="quotation['RepaymentFrequency.Code']!='4' && quotationProductSubType!='With Interest'">
                    {{payment['repayments'] | customCurrency}}
                  </p>
                </div>

              </div>

            </div>

            <div class="row clearfix flex totals-area primary-bg">
              <h4 class="full-width duePadding">Total</h4>
              <h4 class="full-width">{{totalRepayment | customCurrency}}</h4>
              <h4 class="full-width" *ngIf="quotationProductSubType=='With Interest'">
                {{quotation['EstablishmentFee'] | customCurrency}}</h4>
              <h4 class="full-width" *ngIf="quotationProductSubType=='With Interest'">
                {{totalInterest | customCurrency}}</h4>
              <h4 class="full-width" *ngIf="quotationProductSubType=='With Interest'">
                {{quotation['AccountKeepingFees'] | customCurrency}}</h4>
              <h4 class="full-width" *ngIf="quotation['Deposit']!=0">{{quotation['Deposit'] | customCurrency}}</h4>
              <h4 class="full-width">{{quotation['Amount.Original'] | customCurrency}}</h4>
            </div>

          </div>


        </mat-card>


      </mat-tab>

   <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="fas fa-check-circle"></mat-icon> Compliance Tasks
        </ng-template>
        <div class="row clearfix" *ngIf="complianceTasksTab==true">
          <mat-card class="nudge-btm">
            <div class="colour-stripe "></div>
            <app-compliance-task [contractID]="quotation['ID']" [largeHeading]="false"></app-compliance-task>
          </mat-card>
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="fas fa-clipboard-list"></mat-icon> Action Log
        </ng-template>
        <div class="row clearfix">
          <mat-card class="nudge-btm" *ngIf="actionLogTab==true">
            <div class="colour-stripe "></div>

          </mat-card>
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="fas fa-file-alt"></mat-icon>
        </ng-template>
        <div class="row clearfix" *ngIf="documentsTab==true">

        </div>
      </mat-tab>
    </mat-tab-group> -->
  <!-- </div> -->
</div>
