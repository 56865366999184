import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { TreatmentService } from '../shared/treatment.service';
import { TreatmentBrandViewComponent } from '../treatment-brand-view/treatment-brand-view.component';

import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { BeneficiaryViewComponent } from '../../beneficiary/beneficiary-view/beneficiary-view.component';
import { treatmentUtil } from '../shared/treatmentUtil';
import { TreatmentCategoryViewComponent } from '../treatment-category-view/treatment-category-view.component';
import { TreatmentProductViewComponent } from '../treatment-product-view/treatment-product-view.component';
import { TreatmentTypeViewComponent } from '../treatment-type-view/treatment-type-view.component';

@Component({
  selector: 'app-treatment-document-create',
  templateUrl: './treatment-document-create.component.html',
  styleUrls: ['./treatment-document-create.component.css'],
})
export class TreatmentDocumentCreateComponent implements OnInit {
  treatmentUtil = new treatmentUtil();

  @Input()
  productID;

  @Input()
  brandID;

  @Input()
  merchantID;

  @Input()
  categoryID;

  @Output()
  close = new EventEmitter();

  @Output()
  create = new EventEmitter();

  @Output()
  getDocument = new EventEmitter();

  @Input()
  typeID;

  @Input()
  multiCreate = false;
  @Input()
  multiCreateSupplier = false;

  @Input()
  displayDocumentAssignment = true;

  _groupFilter = 'treatment';

  categories;
  types;
  brands;
  products;

  description;

  thumbnailFileID;

  isRequired = false;

  documentTypes = [
    { name: 'Document', icon: 'fa-file-alt', code: 'fileID' },
    { name: 'External Page', icon: 'fa-link', code: 'url' },
    { name: 'Video', icon: 'fa-file-video', code: 'video' },
  ];

  isModal = false;
  isAdminOrPromoter = false;

  isUploadCompleted = false;
  documentGroups = [
    { name: 'Pre Op Documents', code: 'SRVPRE' },
    { name: 'Post Op Documents', code: 'SRVPST' },
    { name: 'Marketing Documents', code: 'SRVMKT' },
    { name: 'Supplier Documents', code: 'SRVSPL' },
    { name: 'Procedure Images', code: 'SRVSTC' },
  ];

  fileID;
  url;
  label;
  deccription;
  group;

  disableGroup = false;

  tags;
  type;
  supplierID;

  document;
  hideSupplier = false;
  hideCategory = false;
  hideType = false;
  hideBrand = false;
  hideProduct = false;

  productLabel;
  brandLabel;
  treatment;

  distributorID;
  distributorName;
  manufacturerID;
  manufacturerName;

  supplierName;

  suppliers = [];

  constructor(
    private authenticationService: AuthenticationService,
    private treatmentService: TreatmentService,
    private activeRoute: ActivatedRoute,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.brandID) {
        this.brandID = data.brandID;
      }

      if (data.merchantID) {
        this.merchantID = data.merchantID;
      }

      if (data.categoryID) {
        this.categoryID = data.categoryID;
      }

      if (data.typeID) {
        this.typeID = data.typeID;
      }

      if (data.group) {
        this.group = data.group;
      }

      if (data.supplierID) {
        this.supplierID = data.supplierID;
      }

      if (data.productID) {
        this.productID = data.productID;
      }

      if (data._groupFilter) {
        this._groupFilter = data._groupFilter;
      }

      if (data.multiCreate != null) {
        this.multiCreate = data.multiCreate;
      }

      if (data.multiCreateSupplier != null) {
        this.multiCreateSupplier = data.multiCreateSupplier;
      }

      if (data.displayDocumentAssignment != null) {
        this.displayDocumentAssignment = data.displayDocumentAssignment;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;

        this.setup();
      });
    });
  }

  setup() {
    if (this.multiCreate == false && !this.brandID && !this.productID && !this.supplierID) {
      const payload = {
        fields: 'ID,Label',
        isActive: true,
      };

      this.treatmentService.getTreatmentCategoryList(payload, this.isAdminOrPromoter).subscribe((res) => {
        this.categories = res;

        if (this.categoryID) {
          this.onSelectSimpleCategory(this.categoryID, true);
        }
      });
    }

    if (this.multiCreateSupplier == true && !this.brandID && !this.productID && !this.supplierID) {
      this.group = 'SRVSPL';
      this.disableGroup = true;

      const payload = {
        fields: 'ID,Label',
      };
      this.treatmentService.getSupplierList(payload).subscribe((res) => {
        if (res) {
          this.suppliers = res;
        }
      });
    } else if (
      this.multiCreate == false &&
      this.multiCreateSupplier == false &&
      (this.brandID || this.productID || this.supplierID)
    ) {
      if (this.supplierID) {
        const _payload = {
          fields: 'CalculatedName,ID',
        };

        this.treatmentService.getSupplierDetails(this.supplierID, _payload).subscribe((res) => {
          if (res) {
            if (res['CalculatedName']) {
              this.supplierName = res['CalculatedName'];
            }
            this.group = 'SRVSPL';
            this.disableGroup = true;
            this.hideSupplier = true;
            this.hideCategory = true;
            this.hideType = true;
            this.hideBrand = true;
            this.hideProduct = true;
          }
        });
      } else if (this.productID) {
        this.treatmentService.getTreatmentProductDetailsGlobal(this.productID).subscribe((res) => {
          if (res) {
            this.treatment = res;

            if (this.treatment['Label']) {
              this.productLabel = this.treatment['Label'];
            }

            if (this.treatment['Brand.Label']) {
              this.brandLabel = this.treatment['Brand.Label'];
            }

            if (this.treatment['Brand_key']) {
              this.brandID = this.treatment['Brand_key'];
            }

            if (this.treatment['Manufacturer_Key'] && this.treatment['Manufacturer.Name']) {
              this.manufacturerID = this.treatment['Manufacturer_Key'];
              this.manufacturerName = this.treatment['Manufacturer.Name'];
            } else if (this.treatment['Manufacturer_Key.Effective'] && this.treatment['Manufacturer.Name.Effective']) {
              this.manufacturerID = this.treatment['Manufacturer_Key.Effective'];
              this.manufacturerName = this.treatment['Manufacturer.Name.Effective'];
            }

            if (this.treatment['Distributor_Key'] && this.treatment['Distributor.Name']) {
              this.distributorID = this.treatment['Distributor_Key'];
              this.distributorName = this.treatment['Distributor.Name'];
            } else if (this.treatment['Distributor_Key.Effective'] && this.treatment['Distributor.Name.Effective']) {
              this.distributorID = this.treatment['Distributor_Key.Effective'];
              this.distributorName = this.treatment['Distributor.Name.Effective'];
            }

            this.hideCategory = true;
            this.hideType = true;
            this.hideBrand = true;
            this.hideProduct = true;
          }
        });
      } else if (this.brandID) {
        this.treatmentService.getTreatmentBrandDetailsGlobal(this.brandID).subscribe((res) => {
          if (res) {
            this.treatment = res;

            if (this.treatment['Label']) {
              this.brandLabel = this.treatment['Label'];
            }

            if (this.treatment['Manufacturer_Key'] && this.treatment['Manufacturer.Name']) {
              this.manufacturerID = this.treatment['Manufacturer_Key'];
              this.manufacturerName = this.treatment['Manufacturer.Name'];
            }

            if (this.treatment['Distributor_Key'] && this.treatment['Distributor.Name']) {
              this.distributorID = this.treatment['Distributor_Key'];
              this.distributorName = this.treatment['Distributor.Name'];
            }

            this.hideCategory = true;
            this.hideType = true;
            this.hideBrand = true;
            this.hideProduct = true;
          }
        });
      }
    }
  }

  changeDocumentAssignment(s) {
    if (s && s == 'supplier' && this.suppliers.length <= 0) {
      this.group = 'SRVSPL';
      this.disableGroup = true;

      const payload = {
        fields: 'ID,Label',
      };
      this.treatmentService.getSupplierList(payload).subscribe((res) => {
        if (res) {
          this.suppliers = res;
        }
      });
    } else if (s == 'supplier') {
      this.group = 'SRVSPL';
      this.disableGroup = true;
    } else {
      this.disableGroup = false;
      this.group = null;
    }
  }

  viewSupplier(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(BeneficiaryViewComponent, {
        data: id,
        width: '600px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewType(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(TreatmentTypeViewComponent, {
        data: {
          typeID: id,
          isGlobal: true,
        },

        width: '700px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewCategory(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(TreatmentCategoryViewComponent, {
        data: {
          categoryID: id,
        },

        width: '700px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewBrand(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(TreatmentBrandViewComponent, {
        data: {
          brandID: id,
          isGlobal: true,
        },

        width: '700px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewProduct(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(TreatmentProductViewComponent, {
        data: {
          productID: id,
          isGlobal: true,
        },

        width: '800px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  setSupplierFromBrand() {
    if (this.brandID && this.brands && this.brands.length > 0) {
      const o = this.brands.filter((item) => {
        if (item.ID === this.brandID) {
          return true;
        }

        return false;
      });

      if (o && o[0]) {
        if (o[0]['Manufacturer_Key'] && o[0]['Manufacturer.Name']) {
          this.manufacturerID = o[0]['Manufacturer_Key'];
          this.manufacturerName = o[0]['Manufacturer.Name'];
        }

        if (o[0]['Distributor_Key'] && o[0]['Distributor.Name']) {
          this.distributorID = o[0]['Distributor_Key'];
          this.distributorName = o[0]['Distributor.Name'];
        }
      }
    }
  }

  setSupplierFromProduct() {
    if (this.productID && this.products && this.products.length > 0) {
      const o = this.products.filter((item) => {
        if (item.ID === this.productID) {
          return true;
        }

        return false;
      });

      if (o && o[0]) {
        if (o[0]['Manufacturer_Key'] && o[0]['Manufacturer.Name']) {
          this.manufacturerID = o[0]['Manufacturer_Key'];
          this.manufacturerName = o[0]['Manufacturer.Name'];
        } else if (o[0]['Manufacturer_Key.Effective'] && o[0]['Manufacturer.Name.Effective']) {
          this.manufacturerID = o[0]['Manufacturer_Key.Effective'];
          this.manufacturerName = o[0]['Manufacturer.Name.Effective'];
        }

        if (o[0]['Distributor_Key'] && o[0]['Distributor.Name']) {
          this.distributorID = o[0]['Distributor_Key'];
          this.distributorName = o[0]['Distributor.Name'];
        } else if (o[0]['Distributor_Key.Effective'] && o[0]['Distributor.Name.Effective']) {
          this.distributorID = o[0]['Distributor_Key.Effective'];
          this.distributorName = o[0]['Distributor.Name.Effective'];
        }
      }
    }
  }

  onSelectSimpleCategory(simpleCategory, setup = false) {
    if (simpleCategory) {
      this.brands = [];
      this.types = [];
      this.products = [];

      this.productID = null;
      this.brandID = null;

      if (setup != true) {
        this.typeID = null;
      }

      this.manufacturerID = null;
      this.manufacturerName = null;

      this.distributorID = null;
      this.distributorName = null;

      const payload = {
        fields: 'ID,Label',
        isActive: true,
        categoryID: simpleCategory,
      };

      this.treatmentService.getTreatmentTypeList(payload).subscribe((res) => {
        this.types = res;

        if (setup == true && this.typeID) {
          this.onSelectSimpleType(this.typeID, true);
        }
      });
    }
  }

  onSelectSimpleType(simpleType, setup = false) {
    if (simpleType) {
      this.brands = [];
      this.products = [];

      this.brandID = null;
      this.productID = null;

      this.manufacturerID = null;
      this.manufacturerName = null;

      this.distributorID = null;
      this.distributorName = null;

      let payload: any = {
        isActive: true,
        fields: 'ID,Label,Distributor_Key,Distributor.Name,Manufacturer_Key,Manufacturer.Name',
        typeID: simpleType,
        isNullProduct: false,
        isNullBrand: false,
      };

      if (this.isAdminOrPromoter == true) {
        payload = {
          isActive: true,
          typeID: simpleType,
          fields: 'ID,Label,Distributor_Key,Distributor.Name,Manufacturer_Key,Manufacturer.Name',
          merchantID: this.merchantID,
          isNullProduct: false,
          isNullBrand: false,
        };
      }

      this.treatmentService.getTreatmentBrandList(payload, this.isAdminOrPromoter).subscribe((res) => {
        if (res && res.length > 0) {
          this.brands = res;

          if (setup == true && this.brandID) {
            this.onSelectSimpleBrand(this.brandID, true);
          }
        }
      });
    }
  }

  onSelectSimpleBrand(simpleBrand, setup = false) {
    if (simpleBrand) {
      this.products = [];

      this.productID = null;

      this.manufacturerID = null;
      this.manufacturerName = null;

      this.distributorID = null;
      this.distributorName = null;

      let payload: any = {
        isActive: true,
        fields:
          'ID,Label,Distributor_Key.Effective,Distributor.Name.Effective,Manufacturer_Key.Effective,Manufacturer.Name.Effective',
        brandID: simpleBrand,
        isNullProduct: false,
        isNullBrand: false,
      };

      if (this.isAdminOrPromoter == true) {
        payload = {
          isActive: true,
          fields:
            'ID,Label,Distributor_Key.Effective,Distributor.Name.Effective,Manufacturer_Key.Effective,Manufacturer.Name.Effective',
          brandID: simpleBrand,
          merchantID: this.merchantID,
          isNullProduct: false,
          isNullBrand: false,
        };
      }

      this.treatmentService.getTreatmentProductList(payload, this.isAdminOrPromoter).subscribe((res) => {
        if (res && res.length > 0) {
          this.products = res;
        }
      });
    }
  }

  onCompleteAllFile(e) {
    if (e && e[0]) {
      this.fileID = e[0];
    }
  }

  checkUploadCompleted(e) {
    this.isUploadCompleted = e;
  }

  removeNewTreatmentDocument(e) {
    if (e && e._id == this.fileID) {
      this.fileID = null;
    }
  }

  closeModal() {
    this.close.emit(true);
  }

  createNewDoc() {
    if (this.multiCreate == false && this.multiCreateSupplier == false) {
      const payload = {
        categoryID: this.categoryID,
        typeID: this.typeID,
        brandID: this.brandID,
        productID: this.productID,
        label: this.label,
        deccription: this.deccription,
        tags: this.tags,
        type: this.group,
        url: this.url,
        fileID: this.fileID,
        isRequired: this.isRequired,
        thumbnailFileID: this.thumbnailFileID,
        supplierID: this.supplierID,
      };

      this.treatmentService.createTreatmentDocument(payload).subscribe((res) => {
        if (res) {
          this.create.emit(res);

          NotifyAppComponent.displayToast('success', 'Successful operation', 'Document has been successfully added');
        }
      });
    } else {
      const payload = {
        label: this.label,
        deccription: this.deccription,
        tags: this.tags,
        type: this.group,
        url: this.url,
        fileID: this.fileID,
        isRequired: this.isRequired,
        thumbnailFileID: this.thumbnailFileID,
      };

      this.getDocument.emit(payload);
    }
  }

  onCompleteAllFileThumbnail(e) {
    if (e && e[0]) {
      this.thumbnailFileID = e[0];
    }
  }

  removeNewTreatmentDocumentThumbnail(e) {
    if (e && e._id == this.thumbnailFileID) {
      this.thumbnailFileID = null;
    }
  }
}
