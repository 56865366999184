import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { Settings } from '../../../shared/types/settings';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { environment } from 'environments/environment';
import { orderBy, uniq } from 'lodash';
import * as moment from 'moment';
import { TemplateReviewComponent } from '../../../core/helper/template-review/template-review.component';
import { ConfirmDialogMessageComponent } from '../../../shared/components/confirm-dialog-message/confirm-dialog-message.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { SideMenuService } from '../../../shared/services/side-menu.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { TemplateService } from '../shared/template-service';
import { TemplateUtilClass } from '../shared/templateUtils';

@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.css'],
  providers: [CustomDatePipe],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.5s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.5s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class TemplateListComponent implements OnInit {
  @Input()
  isManuallyCreated;

  @Input()
  isPromoter;

  isPromoterOrAdmin = false;

  currentUser;

  selectedTypeID;

  typeFilter;
  typeFilter2;

  @Input()
  title = 'Templates';

  @Input()
  merchantID;

  isActiveFilter;

  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  utils = new UtilsClass();

  displayedColumns = [
    'selectCheckBox',
    'Picture',
    'Label',
    'Purpose',
    'Owner',
    'Target',
    'Date',
    'Tags',
    'supportSMS',
    'IsDefault',
    'Editable',
    'Actions',
  ];
  filters = [];

  selectedIDs = [];

  searchVals = new FormControl();

  searchValList = ['Label'];

  expandedRow: number;
  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;
  templateType;
  settings = Settings.global;

  filteredSize = null;
  currentPractice;
  templateUtil = new TemplateUtilClass();
  searchLabel;
  merchantTags = [];

  destroyEvent = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private router: Router,
    private customDate: CustomDatePipe,
    private authenticationService: AuthenticationService,
    private templateService: TemplateService,
    private utilService: UtilsService
  ) {}

  ngOnInit() {
    if (this.settings['MarketingTemplateMasterAndManual'] === true) {
      this.templateType = 'masterAndManual';
    } else if (this.settings['MarketingManual'] === true) {
      this.templateType = 'manual';
    } else {
      this.templateType = 'master';
    }
    this.authenticationService.getCurrentPractice().subscribe((currentPractice) => {
      this.currentPractice = currentPractice;
    });
    this.authenticationService.getCurrentUser().subscribe((u) => {
      if (u && u.data) {
        this.currentUser = u.data;
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;
      });
    });

    const params = {
      merchantID: this.merchantID,
      isManuallyCreated: this.isManuallyCreated,
      templateType: this.templateType,
      isPromoter: this.isPromoter,
    };

    SideMenuService.goBack.subscribe((res) => {
      if (res == true) {
        this.hideViewDetails();
      }
    });

    this.listDB = new LoadRecords(this.templateService, this.destroyEvent, params, this.isPromoterOrAdmin);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  ngOnChanges(changes: SimpleChanges) {
    const params = {
      merchantID: this.merchantID,
      isManuallyCreated: this.isManuallyCreated,
      templateType: this.templateType,
      isPromoter: this.isPromoter,
    };

    this.listDB = new LoadRecords(this.templateService, this.destroyEvent, params, this.isPromoterOrAdmin);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
    this.listDB.getMerchantTags.subscribe((tags) => {
      this.merchantTags = JSON.parse(JSON.stringify(tags || []));
    });
  }

  hideViewDetails() {}

  setFilter(event, field) {
    let filter;

    if (field == 'ApprovedOrActive') {
      let _event;
      if (event == true) {
        _event = '1';
      } else {
        _event = '0';
      }
      filter = {
        field,
        value: _event,
      };

      this.searchLabel = _event;
    } else if (field == 'Tags' && event && event.length > 0) {
      let e = event;
      if (typeof e === 'string') {
        e = [event];
      }

      filter = {
        field,
        value: e,
      };

      this.searchLabel = event;
    } else if (typeof event == 'object' && event != null) {
      const v = this.customDate.transform(event, Settings.global['dateFormat']);
      filter = {
        field,
        value: v,
      };
    } else if (event) {
      filter = {
        field,
        value: event,
      };

      this.searchLabel = event;
    } else {
      filter = {
        field,
        value: '',
      };

      this.searchLabel = null;
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  indexOfTag(e) {
    if (this.searchLabel && Array.isArray(this.searchLabel) && e) {
      if (this.searchLabel.indexOf(e) != -1) {
        return true;
      }
    }

    return false;
  }

  toLowerCase(e) {
    if (e) {
      return String(e).toLocaleLowerCase();
    }

    return e;
  }

  removed(id) {
    if (id) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to remove this Template ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.templateService.removeTemplate(id).subscribe((res) => {
            if (res) {
              this.dataSource.removeItem = id;

              NotifyAppComponent.displayToast(
                'success',
                'Successful operation',
                'The template has been successfully removed'
              );
            }
            ref.close();
          });
        }
      });
    }
  }

  testCampaign(tag) {
    if (tag) {
      const title = 'Send Test Campaign';
      const description = `<p>To send a test Campaign, we will enable the marketing consent for you. </p>`;

      let subTitle;
      if (this.currentUser['emails.Email']) {
        subTitle = ` The message will be sent to: ${this.currentUser['emails.Email']}`;
      }

      this.utilService.getTemplateTag(tag, { fields: 'MetaData' }).subscribe((template) => {
        if (template) {
          if (template && template['MetaData'] && template['MetaData']['Amount']) {
            let label = 'Total Treatment Price';

            if (template['MetaData']['Amount']['Label']) {
              label = template['MetaData']['Amount']['Label'];
            } else {
              label = 'Treatment value';
            }

            const ref = RootAppComponent.dialog.open(ConfirmDialogMessageComponent, {
              data: {
                icon: 'message',
                title,
                yesLabel: 'Send',
                noLabel: 'Close',
                description,
                subTitle,
                isDateTimeInput: false,
                isDateInput: false,
                isTextArea: false,
                isTextInput: false,
                isPolicy: false,
                isCheckbox: false,
                displayNo: true,
                amount: 5000,
                amountLabel: label,
                isAmountInput: true,
                isAmountInputRequired: true,
              },
              width: '565px',
            });
            ref.componentInstance.closeModal.subscribe((res) => {
              ref.close();
            });
            ref.componentInstance.onConfirm.subscribe((res) => {
              if (res && res.confirm == true) {
                const p = {
                  amount: res.amount,
                };
                this.utilService.testTemplate(tag, p).subscribe((ress) => {
                  if (ress) {
                    ref.close();
                    NotifyAppComponent.displayToast(
                      'success',
                      'Successful Operation',
                      'You have successfully sent a test campaign to yourself, please check your email.'
                    );
                  }
                });
              }
            });
          } else {
            const title = 'Send Test Campaign';
            let description = `<p>To send a test Campaign, we will enable the marketing consent for you.<br><br>`;

            if (this.currentUser['emails.Email']) {
              description =
                description + ` The message will be sent to:  <strong>${this.currentUser['emails.Email']}</strong></p>`;
            } else {
              description = description + `</p>`;
            }
            const confirm = new ConfirmDialog('fas fa-info', title, description, 'No', 'Yes');

            const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
              data: confirm,
              width: '650px',
            });

            ref.componentInstance.onConfirm.subscribe((res) => {
              if (res == false) {
                ref.close();
              } else {
                this.utilService.testTemplate(tag).subscribe((res) => {
                  if (res) {
                    ref.close();
                    NotifyAppComponent.displayToast(
                      'success',
                      'Successful Operation',
                      'You have successfully sent a test campaign to yourself, please check your email.'
                    );
                  }
                });
              }
            });
          }
        }
      });
    }
  }

  enableGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialog = new ConfirmDialog(
        'cancel',
        'Enable Selected Templates',
        '<p>Once the Template is enabled it will not appear as an option for the user.</p>',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              isActive: true,
              ids: this.selectedIDs.join(','),
            };
            this.templateService.modifyPromoterTemplateGroup(payload).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  res[i]['Is_ManuallyCreated'] = '1';
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Templates are enabled.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  disableGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialog = new ConfirmDialog(
        'cancel',
        'Disable Selected Templates',
        '<p>Once the template is disabled it will not appear as an option for the user.</p>',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              isActive: false,
              ids: this.selectedIDs.join(','),
            };
            this.templateService.modifyPromoterTemplateGroup(payload).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  res[i]['Is_ManuallyCreated'] = '1';

                  if (this.templateType == 'master' && res[i]['Is_ManuallyCreated'] == '0') {
                    res[i]['isPublished'] = '1';
                  } else if (
                    this.templateType == 'manual' &&
                    res[i]['Is_ManuallyCreated'] == '1' &&
                    res[i]['IsDefault'] == '1' &&
                    res[i]['Deactivated'] == '0'
                  ) {
                    res[i]['isPublished'] = '1';
                  } else if (this.templateType == 'masterAndManual') {
                    if (res[i]['Is_ManuallyCreated'] == '0') {
                      res[i]['isPublished'] = '1';
                    } else if (
                      res[i]['Is_ManuallyCreated'] == '1' &&
                      res[i]['IsDefault'] == '1' &&
                      res[i]['Deactivated'] == '0'
                    ) {
                      res[i]['isPublished'] = '1';
                    } else {
                      res[i]['isPublished'] = '0';
                    }
                  } else {
                    res[i]['isPublished'] = '0';
                  }

                  this.dataSource.replaceItem = res[i];
                }

                this.selectedIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Templates are disabled.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  setDefaultGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialog = new ConfirmDialog(
        'visibility',
        'Publish the Selected Templates ',
        '<p>Once the Template is  published it will  appear as an option for all users.</p>',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              isDefault: true,
              isActive: true,
              ids: this.selectedIDs.join(','),
            };
            this.templateService.modifyPromoterTemplateGroup(payload).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  res[i]['Is_ManuallyCreated'] = '1';
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Templates are enabled.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  setNotDefaultGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialog = new ConfirmDialog(
        'visibility_off',
        'Hide the Selected Templates',
        '<p>Once the template is hidden it will not appear as an option for  users.</p>',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              isDefault: false,
              isActive: false,
              ids: this.selectedIDs.join(','),
            };
            this.templateService.modifyPromoterTemplateGroup(payload).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  res[i]['Is_ManuallyCreated'] = '1';

                  if (this.templateType == 'master' && res[i]['Is_ManuallyCreated'] == '0') {
                    res[i]['isPublished'] = '1';
                  } else if (
                    this.templateType == 'manual' &&
                    res[i]['Is_ManuallyCreated'] == '1' &&
                    res[i]['IsDefault'] == '1' &&
                    res[i]['Deactivated'] == '0'
                  ) {
                    res[i]['isPublished'] = '1';
                  } else if (this.templateType == 'masterAndManual') {
                    if (res[i]['Is_ManuallyCreated'] == '0') {
                      res[i]['isPublished'] = '1';
                    } else if (
                      res[i]['Is_ManuallyCreated'] == '1' &&
                      res[i]['IsDefault'] == '1' &&
                      res[i]['Deactivated'] == '0'
                    ) {
                      res[i]['isPublished'] = '1';
                    } else {
                      res[i]['isPublished'] = '0';
                    }
                  } else {
                    res[i]['isPublished'] = '0';
                  }

                  this.dataSource.replaceItem = res[i];
                }

                this.selectedIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Templates are disabled.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  selectPage() {
    const index = this.dataSource['_paginator']['index'] || 0;
    const pageSize = this.dataSource['_paginator']['pageSize'] || 0;

    for (let i = 0; i < (index + 1) * pageSize; i++) {
      const o = this.dataSource.filteredData[index * pageSize + i];

      if (o && o['ID'] && this.selectedIDs.indexOf(o['ID']) == -1) {
        this.selectedIDs.push(o['ID']);
      }
    }
  }

  select(id, isSelected) {
    if (isSelected == true && this.selectedIDs.indexOf(id) == -1) {
      this.selectedIDs.push(id);
    } else {
      const _index = this.selectedIDs.indexOf(id);
      this.selectedIDs.splice(_index, 1);
    }
  }

  unselect() {
    this.selectedIDs = [];
  }

  isSelected(id) {
    if (!this.selectedIDs || this.selectedIDs.length <= 0) {
      return false;
    } else {
      if (this.selectedIDs.indexOf(id) != -1) {
        return true;
      }
    }

    return false;
  }

  selectAll() {
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      const o = this.dataSource.filteredData[i];

      if (o && o['ID'] && this.selectedIDs.indexOf(o['ID']) == -1) {
        this.selectedIDs.push(o['ID']);
      }
    }
  }

  removeGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to remove these Templates  ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.templateService.deletePromoterTemplateGroup(this.selectedIDs).subscribe((res) => {
            if (res && res.length > 0) {
              for (let i = 0; i < res.length; i++) {
                this.dataSource.removeItem = res[i];
              }

              this.selectedIDs = [];
            }
            ref.close();

            NotifyAppComponent.displayToast('success', 'Success!', 'Templates are removed.');
          });
        }
      });
    }
  }

  createNew() {
    this.router.navigate(['/merchant', { outlets: { page: ['template-create'] } }]);
  }

  viewTemplate(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(TemplateReviewComponent, {
        data: {
          templateID: id,
          isPrint: false,
          isProceed: false,
          isDownload: false,
        },
        width: '700px',
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
    }
  }

  editTemplate(id) {
    this.router.navigate(['/merchant', { outlets: { page: ['template-edit', id] } }]);
  }

  enable(ID) {
    const confirmDialog = new ConfirmDialog(
      'check_circle',
      'Enable Template',
      '<p>Once the Template is enabled it will  appear as an option for the user.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            isActive: true,
          };
          this.templateService.modifyTemplate(ID, payload, this.isPromoterOrAdmin).subscribe((res) => {
            if (res) {
              res.ID = ID;
              res['Is_ManuallyCreated'] = '1';
              this.dataSource.replaceItem = res;

              NotifyAppComponent.displayToast('success', 'Success!', 'Template is enabled.');
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  disable(ID) {
    const confirmDialog = new ConfirmDialog(
      'cancel',
      'Disable Template',
      '<p>Once the Template is disabled it will not appear as an option for the user.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            isActive: false,
          };
          this.templateService.modifyTemplate(ID, payload, this.isPromoterOrAdmin).subscribe((res) => {
            if (res) {
              res.ID = ID;
              res['Is_ManuallyCreated'] = '1';
              this.dataSource.replaceItem = res;

              NotifyAppComponent.displayToast('success', 'Success!', 'Template is disabled.');
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  markAsDefault(ID) {
    const confirmDialog = new ConfirmDialog(
      'visibility',
      ' Publish the Template',
      '<p>Once the Template is Published it will  appear as an option for All  users.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            isDefault: true,
            isActive: true,
          };
          this.templateService.modifyTemplate(ID, payload, this.isPromoterOrAdmin).subscribe((res) => {
            if (res) {
              res.ID = ID;
              res['Is_ManuallyCreated'] = '1';

              if (this.templateType == 'master' && res['Is_ManuallyCreated'] == '0') {
                res['isPublished'] = '1';
              } else if (
                this.templateType == 'manual' &&
                res['Is_ManuallyCreated'] == '1' &&
                res['IsDefault'] == '1' &&
                res['Deactivated'] == '0'
              ) {
                res['isPublished'] = '1';
              } else if (this.templateType == 'masterAndManual') {
                if (res['Is_ManuallyCreated'] == '0') {
                  res['isPublished'] = '1';
                } else if (res['Is_ManuallyCreated'] == '1' && res['IsDefault'] == '1' && res['Deactivated'] == '0') {
                  res['isPublished'] = '1';
                } else {
                  res['isPublished'] = '0';
                }
              } else {
                res['isPublished'] = '0';
              }
              this.dataSource.replaceItem = res;

              NotifyAppComponent.displayToast('success', 'Success!', 'Template is marked as Published.');
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  openLandingPage(t) {
    if (t && t['LandingPageURL']) {
      if (
        t['LandingPageURL'].indexOf('{{SITE_URL_Public}}') !== -1 ||
        t['LandingPageURL'].indexOf('{{SITE_URL_MERCHANT}}') !== -1
      ) {
        let nodeServer = environment.nodeUrl;
        if (nodeServer && nodeServer.indexOf('localhost') != -1) {
          nodeServer = 'https://sandbox.smileright.com.au/api';
        }

        let link = t['LandingPageURL'];

        link = link.replace('{{MERCHANT_CardKey}}', this.currentPractice.ID);
        link = link.replace('{{MERCHANT_TerminalCode}}', this.currentPractice.Terminal_Code);
        link = link.replace('{{SITE_URL_Public}}', this.settings['publicSiteFrontendLink']);

        link = link.replace('{{SITE_URL_MERCHANT}}/api', nodeServer);
        window.open(link, '_blank');
      } else {
        const url = t['LandingPageURL'];
        window.open(url, '_blank');
      }
    } else if (t && t['LandingPageCode']) {
      const url = `${this.settings['publicSiteFrontendLink']}/product/(page:main/${t['LandingPageCode']})`;

      window.open(url, '_blank');
    }
  }

  markAsNotDefault(ID) {
    const confirmDialog = new ConfirmDialog(
      'visibility_off',
      'Hide the Template',
      '<p>Once the Template is hidden it will not appear as an option for  users.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            isDefault: false,
            isActive: false,
          };
          this.templateService.modifyTemplate(ID, payload, this.isPromoterOrAdmin).subscribe((res) => {
            if (res) {
              res.ID = ID;
              res['Is_ManuallyCreated'] = '1';

              if (this.templateType == 'master' && res['Is_ManuallyCreated'] == '0') {
                res['isPublished'] = '1';
              } else if (
                this.templateType == 'manual' &&
                res['Is_ManuallyCreated'] == '1' &&
                res['IsDefault'] == '1' &&
                res['Deactivated'] == '0'
              ) {
                res['isPublished'] = '1';
              } else if (this.templateType == 'masterAndManual') {
                if (res['Is_ManuallyCreated'] == '0') {
                  res['isPublished'] = '1';
                } else if (res['Is_ManuallyCreated'] == '1' && res['IsDefault'] == '1' && res['Deactivated'] == '0') {
                  res['isPublished'] = '1';
                } else {
                  res['isPublished'] = '0';
                }
              } else {
                res['isPublished'] = '0';
              }

              this.dataSource.replaceItem = res;

              NotifyAppComponent.displayToast('success', 'Success!', 'Template is marked hidden.');
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  isDestoyed = false;
  serviceRef;

  templateUtil = new TemplateUtilClass();
  public getMerchantTags = new EventEmitter();
  merchantTags = [];

  constructor(private templateService: TemplateService, private destroyEvent, params, private isPromoterOrAdmin) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    const payload: any = {
      section: 0,
    };

    if (params.isPromoter != null) {
      payload.isPromoter = params.isPromoter;
    }

    if (params.isManuallyCreated != null) {
      payload.isManualCreated = params.isManuallyCreated;
    }

    if (params && params.merchantID) {
      payload['merchantID'] = params.merchantID;
    }

    const templateType = params.templateType;

    this.serviceRef = this.templateService.getPhysicalTemplateList(payload, this.isPromoterOrAdmin).subscribe((res) => {
      for (let i = 0; i < res.length; i++) {
        res[i]['Tags'] = [];

        res[i]['Owner'] = this.templateUtil.getTemplageOwner(res[i]['Tag']);
        res[i]['Target'] = this.templateUtil.getTemplateTarget(res[i]['Tag']);
        res[i]['realPurpose'] = this.templateUtil.getPurpose(res[i]['Tag']);
        if (res[i]['Is_ManuallyCreated'] != '1') {
          res[i]['isDefaultReal'] = '1';
          res[i]['isActiveReal'] = '1';
        } else if (res[i]['Is_ManuallyCreated'] == '1') {
          if (res[i]['IsDefault'] == '1') {
            res[i]['isDefaultReal'] = '1';
          } else {
            res[i]['isDefaultReal'] = '0';
          }
          if (res[i]['Deactivated'] != '1') {
            res[i]['isActiveReal'] = '1';
          } else {
            res[i]['isActiveReal'] = '1';
          }
        }

        if (res[i]['Tag.Label'] && res[i]['StaticList.Label']) {
          res[i]['StaticList.Label'] = res[i]['Tag.Label'] + '|' + res[i]['StaticList.Label'];
        } else if (res[i]['Tag.Label']) {
          res[i]['StaticList.Label'] = res[i]['Tag.Label'];
        }

        if (res && res[i] && res[i]['StaticList.Label']) {
          res[i]['Tags'] = res[i]['StaticList.Label'].split('|');
          res[i]['Tags'] = uniq(res[i]['Tags']);
          this.merchantTags = this.merchantTags.concat(res[i]['Tags']);
        } else {
          res[i]['Tags'] = [];
          this.merchantTags = this.merchantTags.concat(res[i]['Tags']);
        }

        if (templateType == 'master' && res[i]['Is_ManuallyCreated'] == '0') {
          res[i]['isPublished'] = '1';
        } else if (
          templateType == 'manual' &&
          res[i]['Is_ManuallyCreated'] == '1' &&
          res[i]['IsDefault'] == '1' &&
          res[i]['Deactivated'] == '0'
        ) {
          res[i]['isPublished'] = '1';
        } else if (templateType == 'masterAndManual') {
          if (res[i]['Is_ManuallyCreated'] == '0') {
            res[i]['isPublished'] = '1';
          } else if (
            res[i]['Is_ManuallyCreated'] == '1' &&
            res[i]['IsDefault'] == '1' &&
            res[i]['Deactivated'] == '0'
          ) {
            res[i]['isPublished'] = '1';
          } else {
            res[i]['isPublished'] = '0';
          }
        } else {
          res[i]['isPublished'] = '0';
        }
      }

      this.merchantTags = uniq(this.merchantTags);
      this.merchantTags = orderBy(this.merchantTags);
      this.getMerchantTags.emit(this.merchantTags);
      this.count = res.length;
      this.items = res;

      this.dataChange.next(this.items.slice());
      this.firstLoad = true;

      const innerFunction = (section) => {
        const length = this.items.length;
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.templateService
          .getPhysicalTemplateList(payload, this.isPromoterOrAdmin)
          .subscribe((res) => {
            if (res.length > 0) {
              for (let i = 0; i < res.length; i++) {
                res[i]['Tags'] = [];

                res[i]['Owner'] = this.templateUtil.getTemplageOwner(res[i]['Tag']);
                res[i]['Target'] = this.templateUtil.getTemplateTarget(res[i]['Tag']);
                res[i]['realPurpose'] = this.templateUtil.getPurpose(res[i]['Tag']);
                if (res[i]['Is_ManuallyCreated'] != '1') {
                  res[i]['isDefaultReal'] = '1';
                  res[i]['isActiveReal'] = '1';
                } else if (res[i]['Is_ManuallyCreated'] == '1') {
                  if (res[i]['IsDefault'] == '1') {
                    res[i]['isDefaultReal'] = '1';
                  } else {
                    res[i]['isDefaultReal'] = '0';
                  }
                  if (res[i]['Deactivated'] != '1') {
                    res[i]['isActiveReal'] = '1';
                  } else {
                    res[i]['isActiveReal'] = '1';
                  }
                }

                if (res[i]['Tag.Label'] && res[i]['StaticList.Label']) {
                  res[i]['StaticList.Label'] = res[i]['Tag.Label'] + '|' + res[i]['StaticList.Label'];
                } else if (res[i]['Tag.Label']) {
                  res[i]['StaticList.Label'] = res[i]['Tag.Label'];
                }

                if (res && res[i] && res[i]['StaticList.Label']) {
                  res[i]['Tags'] = res[i]['StaticList.Label'].split('|');
                  res[i]['Tags'] = uniq(res[i]['Tags']);
                  this.merchantTags = this.merchantTags.concat(res[i]['Tags']);
                } else {
                  res[i]['Tags'] = [];
                  this.merchantTags = this.merchantTags.concat(res[i]['Tags']);
                }

                if (templateType == 'master' && res[i]['Is_ManuallyCreated'] == '0') {
                  res[i]['isPublished'] = '1';
                } else if (
                  templateType == 'manual' &&
                  res[i]['Is_ManuallyCreated'] == '1' &&
                  res[i]['IsDefault'] == '1' &&
                  res[i]['Deactivated'] == '0'
                ) {
                  res[i]['isPublished'] = '1';
                } else if (templateType == 'masterAndManual') {
                  if (res[i]['Is_ManuallyCreated'] == '0') {
                    res[i]['isPublished'] = '1';
                  } else if (
                    res[i]['Is_ManuallyCreated'] == '1' &&
                    res[i]['IsDefault'] == '1' &&
                    res[i]['Deactivated'] == '0'
                  ) {
                    res[i]['isPublished'] = '1';
                  } else {
                    res[i]['isPublished'] = '0';
                  }
                } else {
                  res[i]['isPublished'] = '0';
                }
              }

              this.merchantTags = uniq(this.merchantTags);
              this.merchantTags = orderBy(this.merchantTags);
              this.getMerchantTags.emit(this.merchantTags);
              this.count = res.count;
              this.items = this.items.concat(res);

              this.dataChange.next(this.items.slice());
              if (this.isDestoyed != true) {
                innerFunction(section);
              }
            } else {
              return true;
            }
          });
        UtilsClass.stopLoadingInterceptor();
      };

      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  templateUtil = new TemplateUtilClass();

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  set removeItem(id) {
    const data = this._tableDatabase.data.filter((row) => row.ID != id);
    this._tableDatabase.dataChange.next(data);
  }

  set addItem(res) {
    this._tableDatabase.data.unshift(res);

    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  set replaceItem(item) {
    const _index = this._tableDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    this._tableDatabase.data[_index] = item;
    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
          if (this.field == 'Tags') {
            let f = '';
            f = item[this.field] || '';

            if (this.filter && this.filter.length > 0) {
              for (let j = 0; j < this.filter.length; j++) {
                if (f.indexOf(this.filter[j]) == -1) {
                  return false;
                }
              }
              return true;
            } else {
              return f;
            }
          } else {
            let f = '';
            f = item[this.field] || '';

            const searchStr = f.toLowerCase();

            return searchStr.indexOf(this.filter.toLowerCase()) != -1;
          }
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'Tag':
          [propertyA, propertyB] = [a['Tag'], b['Tag']];
          break;

        case 'Label':
          [propertyA, propertyB] = [a['TemplateName'], b['TemplateName']];
          break;

        case 'Purpose':
          [propertyA, propertyB] = [a['Purpose_Label'], b['Purpose_Label']];
          break;

        case 'Target':
          [propertyA, propertyB] = [
            this.templateUtil.getTemplateTarget(a['Tag']),
            this.templateUtil.getTemplateTarget(b['Tag']),
          ];
          break;

        case 'Owner':
          [propertyA, propertyB] = [
            this.templateUtil.getTemplageOwner(a['Tag']),
            this.templateUtil.getTemplageOwner(b['Tag']),
          ];
          break;

        case 'Date':
          [propertyA, propertyB] = [moment(a['Date']).toDate().getTime(), moment(b['Date']).toDate().getTime()];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
