<div class="row clearfix">
  <h1 class="pull-left">
    Prices List :
    <span class="badge">{{ dataSource.filteredData.length }}</span>
  </h1>
  <!-- <div class="pull-right">
  <button mat-raised-button (click)="openQuickViewDialog()">View modal</button>
  <button mat-raised-button (click)="openPriceListModal()">Edit modal</button>
</div> -->
</div>

<button class="pull-right" (click)="editModal()">Launch edit modal</button>

<mat-card class="list-card">
  <mat-expansion-panel class="filterCardless" hideToggle="false">
    <mat-expansion-panel-header>
      <h2 class="lending-prod-compact-h2">
        Add Filters
        <span class="badge" *ngIf="filteredSize != null">{{ filteredSize }}</span>
      </h2>
      <mat-icon>search</mat-icon>
    </mat-expansion-panel-header>

    <mat-select
      class="margin-btm"
      (change)="setFilter($event.value, 'ServiceCategory')"
      name="ServiceCategory"
      placeholder="Service Category"
    >
      <mat-option [value]="null">
        All
      </mat-option>
      <mat-option
        *ngFor="let c of listDB.data | objectPropertyValue: 'ServiceCategory' | unique | without: ['', null] | orderBy"
        [value]="c"
      >
        {{ c }}
      </mat-option>
    </mat-select>

    <mat-select
      class="margin-btm"
      (change)="setFilter($event.value, 'ServiceType')"
      name="ServiceType"
      placeholder="Service Type"
    >
      <mat-option [value]="null">
        All
      </mat-option>
      <mat-option
        *ngFor="let c of listDB.data | objectPropertyValue: 'ServiceType' | unique | without: ['', null] | orderBy"
        [value]="c"
      >
        {{ c }}
      </mat-option>
    </mat-select>

    <mat-select class="margin-btm" (change)="setFilter($event.value, 'Brand')" c name="Brand" placeholder="Brand">
      <mat-option [value]="null">
        All
      </mat-option>
      <mat-option
        *ngFor="let c of listDB.data | objectPropertyValue: 'Brand' | unique | without: ['', null] | orderBy"
        [value]="c"
      >
        {{ c }}
      </mat-option>
    </mat-select>

    <mat-form-field class="margin-btm">
      <input
        class="clearfix"
        (input)="setFilter($event.target.value, 'PriceEffectiveCost')"
        type="number"
        name="PriceEffectiveCost"
        [placeholder]="('KEYWORD.practice' | translate | titlecase) + ' Cost'"
        matInput
      />
    </mat-form-field>

    <mat-form-field class="margin-btm">
      <input
        class="clearfix"
        (input)="setFilter($event.target.value, 'PriceEffectiveSale')"
        type="number"
        name="PriceEffectiveSale"
        [placeholder]="('KEYWORD.treatment' | translate | titlecase) + ' Value'"
        matInput
      />
    </mat-form-field>

    <!-- <mat-form-field class=" margin-btm">
      <input name="descriptionDiagnosis" class="clearfix " placeholder="Diagnosis Description" matInput (input)="setFilter($event.target.value ,'Invoice.Description')">
    </mat-form-field>


    <mat-form-field class=" margin-btm">
      <input type="number" name="descriptionTreatment" class="clearfix " placeholder="Treatment Description" matInput (input)="setFilter($event.target.value ,'Consumer.Contribution')">
    </mat-form-field> -->
  </mat-expansion-panel>
  <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
    <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
    <!-- ID Column -->
    <ng-container cdkColumnDef="ServiceCategory">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>Category</mat-header-cell>
      <mat-cell *cdkCellDef="let row"> {{ row['ServiceCategory'] }} </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="ServiceType">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>Type</mat-header-cell>
      <mat-cell *cdkCellDef="let row"> {{ row['ServiceType'] }} </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="Brand">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>Brand</mat-header-cell>
      <mat-cell *cdkCellDef="let row"> {{ row['Brand'] }} </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="PriceEffectiveSale">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> {{ "KEYWORD.treatment" | translate | titlecase }}Value
      </mat-header-cell>
      <mat-cell *cdkCellDef="let row"> {{ row['Price.Effective.Sale'] | customCurrency }} </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="PriceEffectiveCost">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> {{ "KEYWORD.practice" | translate | titlecase}}Cost
      </mat-header-cell>
      <mat-cell *cdkCellDef="let row"> {{ row['Price.Effective.Cost'] | customCurrency }}</mat-cell>
    </ng-container>

    <!-- <ng-container cdkColumnDef="descriptionDiagnosis">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> {{ "DiagnosisDescription" | translate }}</mat-header-cell>
      <mat-cell *cdkCellDef="let row"> </mat-cell>
    </ng-container>


    <ng-container cdkColumnDef="descriptionTreatment">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> {{ "TreatmentDescriptions" | translate }}</mat-header-cell>
      <mat-cell *cdkCellDef="let row"> </mat-cell>
    </ng-container> -->

    <ng-container cdkColumnDef="Actions">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <div style="float: right">
          <button [matMenuTriggerFor]="menu" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button (click)="openQuickViewDialog(row)" mat-menu-item>
              <mat-icon>portrait</mat-icon>
              <span>Quick View</span>
            </button>

            <button (click)="openPriceListModal(row)" mat-menu-item>
              <mat-icon>folder_shared</mat-icon>
              <span>View/Modify Item</span>
            </button>

            <!-- <button mat-menu-item (click)="linkToInvitation(row['Contract_key'])">
                <mat-icon>portrait</mat-icon>
                <span>Quick Contract View</span>
              </button> -->
          </mat-menu>
        </div>
      </mat-cell>
    </ng-container>
    <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator
    #paginator
    [length]="dataSource.filteredData.length"
    [pageIndex]="0"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
  >
  </mat-paginator>
</mat-card>
