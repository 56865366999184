<div class="row clearfix flex">
  <!-- start pick illness -->
  <mat-form-field class="full-width" *ngIf="section && section['Code'] == 'IL'" appearance="outline">
    <mat-label>Illness</mat-label>
    <mat-select
      class="full-width"
      [(ngModel)]="illnessType"
      (selectionChange)="getIllnessType($event.value)"
      placeholder="Illness"
      name="illness"
    >
      <mat-option *ngFor="let i of illnessList" [value]="i">{{ i.Label }} </mat-option>
    </mat-select>
  </mat-form-field>
  <!-- end pick illness -->

  <!-- start pick allergy -->
  <mat-form-field class="full-width" *ngIf="section && section['Code'] == 'AL'" appearance="outline">
    <mat-label>Allergy Category</mat-label>
    <mat-select
      class=""
      [(ngModel)]="allergyCategoryCode"
      (selectionChange)="getAllergies($event.value)"
      name="allergyCategory"
      placeholder="Allergy Category"
    >
      <mat-option *ngFor="let c of allergyCategories" [value]="c">{{ c.DisplayLabel }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    class="full-width"
    *ngIf="section && section['Code'] == 'AL' && allergyTypeList.length > 0"
    appearance="outline"
  >
    <mat-label>Allergy Type</mat-label>
    <mat-select
      class=""
      [(ngModel)]="allergyType"
      (selectionChange)="getAllergyType($event.value)"
      name="allergyType"
      placeholder="Allergy Type"
    >
      <mat-option *ngFor="let a of allergyTypeList" [value]="a">{{ a.Label }}</mat-option>
    </mat-select>
  </mat-form-field>
  <!-- end pick allergy -->

  <mat-form-field
    class="full-width"
    *ngIf="section && section['Code'] != 'AL' && section['Code'] != 'IL'"
    appearance="outline"
  >
    <mat-label>Property</mat-label>
    <mat-select
      class="full-width"
      [(ngModel)]="property"
      (selectionChange)="getProperty($event.value)"
      placeholder="Property"
      name="property"
    >
      <mat-option *ngFor="let p of properties" [value]="p">
        <span *ngIf="p['PropertyCode'] == 'Merchant_Contact_FK'">{{"KEYWORD.Practice" | translate}} Staff</span>
        <span *ngIf="p['PropertyCode'] != 'Merchant_Contact_FK'">{{ p.PropertyLabel }}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- <div class="row clearfix full-width" *ngIf="property"> -->

  <div class="full-width" *ngIf="property && property['EConnectType'] == 'D'">
    <app-date-input></app-date-input>
  </div>

  <div
    class="full-width flex"
    *ngIf="property && property['SupportsRange'] == '1' && (section['Code'] == 'CRD' || section['Code'] == 'CNT')"
  >
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Min value</mat-label>
      <input
        [(ngModel)]="filterMinValue"
        (keyup.enter)="getMinValue(filterMinValue)"
        (blur)="getMinValue(filterMinValue)"
        matInput
        type="number"
        name="filterValue"
      />
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Max value</mat-label>
      <input
        [(ngModel)]="filterMaxValue"
        (keyup.enter)="getMaxValue(filterMaxValue)"
        (blur)="getMaxValue(filterMaxValue)"
        matInput
        type="number"
        name="filterValue"
      />
    </mat-form-field>
  </div>

  <div class="full-width flex" *ngIf="property && property['PropertyCode'] == 'Gender'">
    <mat-form-field class="full-width" appearance="outline">
      <mat-select [(ngModel)]="filterValue" (selectionChange)="getValue($event.value)">
        <mat-option value="F">Female</mat-option>
        <mat-option value="M">Male</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div
    class="full-width"
    *ngIf="
      property && property['PropertyCode'] != 'Gender' && property['EConnectType'] == 'T' && section['Code'] == 'CRD'
    "
  >
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Value</mat-label>
      <input
        [(ngModel)]="filterValue"
        (keyup.enter)="getValue(filterValue)"
        (blur)="getValue(filterValue)"
        matInput
        placeholder="Value"
        name="filterValue"
      />
    </mat-form-field>
  </div>

  <!--{{ "KEYWORD.treatment" | translate | titlecase }} Invitation -->
  <div
    class="full-width"
    *ngIf="section['Code'] == 'INV' && property && property['PropertyCode'] == 'Merchant_Contact_FK'"
  >
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>{{"KEYWORD.Practice" | translate}} Staff</mat-label>
      <mat-select class="full-width" [(ngModel)]="filterValue" (selectionChange)="getValue($event.value)" name="staff">
        <mat-option *ngFor="let c of contacts" [value]="c['ID']">{{ c['CalculatedName'] }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="full-width" *ngIf="section['Code'] == 'INV' && property && property['PropertyCode'] == 'Customer_Choice'">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Customer Choice</mat-label>
      <mat-select
        class="full-width"
        [(ngModel)]="filterValue"
        (selectionChange)="getValue($event.value)"
        name="invitationChoice"
      >
        <mat-option *ngFor="let c of invitationChoice" [value]="c['PatientChoice.Code']">{{
          c['PatientChoice.Label']
          }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!--Treatment Contract -->
  <div class="full-width" *ngIf="section['Code'] == 'CNT' && property && property['PropertyCode'] == 'Contract_Status'">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Contract Status</mat-label>
      <mat-select
        class="full-width"
        [(ngModel)]="filterValue"
        (selectionChange)="getValue($event.value)"
        name="contractStatus"
      >
        <mat-option *ngFor="let c of contractStatus" [value]="c['Status.Code']">{{ c['Status'] }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div
    class="full-width"
    *ngIf="section['Code'] == 'CNT' && property && property['PropertyCode'] == 'Quotation_Status'"
  >
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Quotation Status</mat-label>
      <mat-select
        class="full-width"
        [(ngModel)]="filterValue"
        (selectionChange)="getValue($event.value)"
        name="quotationStatus"
      >
        <mat-option *ngFor="let c of quotationStatus" [value]="c['Quotation.Status.Code']">{{
          c['Quotation.Status.Label']
          }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!--Treatment Product -->
  <div
    class="full-width"
    *ngIf="section['Code'] == 'PRD' && property && property['PropertyCode'] == 'Service_Product_FK'"
  >
    <mat-form-field class="full-width" appearance="outline">
      <mat-label> {{"KEYWORD.Treatment" | translate}} Product</mat-label>
      <mat-select
        class="full-width"
        [(ngModel)]="filterValue"
        (selectionChange)="getValue($event.value)"
        name="treatmentProduct"
      >
        <mat-option *ngFor="let c of treatmentProducts" [value]="c['TreatmentProduct_key']">{{
          c['Product.Label']
          }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="full-width" *ngIf="section['Code'] == 'PRD' && property && property['PropertyCode'] == 'Distributor_NFK'">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label> {{"KEYWORD.Treatment" | translate}} Product Distributor</mat-label>
      <mat-select
        class="full-width"
        [(ngModel)]="filterValue"
        (selectionChange)="getValue($event.value)"
        name="ProductDistributors"
      >
        <mat-option *ngFor="let c of treatmentProductDistributors" [value]="c['Distributor_Key.Effective']">{{
          c['Distributor.Name.Effective']
          }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div
    class="full-width"
    *ngIf="section['Code'] == 'PRD' && property && property['PropertyCode'] == 'Manufacturer_NFK'"
  >
    <mat-form-field class="full-width" appearance="outline">
      <mat-label> {{"KEYWORD.Treatment" | translate}} Product Manufacturer</mat-label>
      <mat-select
        class="full-width"
        [(ngModel)]="filterValue"
        (selectionChange)="getValue($event.value)"
        name="ProductManufacturers"
      >
        <mat-option *ngFor="let c of treatmentProductManufacturers" [value]="c['Manufacturer_Key.Effective']">{{
          c['Manufacturer.Name.Effective']
          }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!--Treatment Brand -->
  <div class="full-width" *ngIf="section['Code'] == 'BRN' && property && property['PropertyCode'] == 'Brand_FK'">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label> {{"KEYWORD.Treatment" | translate}} Brand</mat-label>
      <mat-select
        class="full-width"
        [(ngModel)]="filterValue"
        (selectionChange)="getValue($event.value)"
        name="treatmentBrand"
      >
        <mat-option *ngFor="let c of treatmentBrands" [value]="c['TreatmentBrand_key']">{{
          c['TreatmentBrand.Label']
          }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="full-width" *ngIf="section['Code'] == 'BRN' && property && property['PropertyCode'] == 'Distributor_NFK'">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label> {{"KEYWORD.Treatment" | translate}} Brand Distributor</mat-label>
      <mat-select
        class="full-width"
        [(ngModel)]="filterValue"
        (selectionChange)="getValue($event.value)"
        name="BrandDistributors"
      >
        <mat-option *ngFor="let c of treatmentBrandDistributors" [value]="c['Distributor_Key']">{{
          c['Distributor.Name']
          }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div
    class="full-width"
    *ngIf="section['Code'] == 'BRN' && property && property['PropertyCode'] == 'Manufacturer_NFK'"
  >
    <mat-form-field class="full-width" appearance="outline">
      <mat-label> {{"KEYWORD.Treatment" | translate}} Brand Manufacturer</mat-label>
      <mat-select
        class="full-width"
        [(ngModel)]="filterValue"
        (selectionChange)="getValue($event.value)"
        name="BrandManufacturers"
      >
        <mat-option *ngFor="let c of treatmentBrandManufacturers" [value]="c['Manufacturer_Key']">{{
          c['Manufacturer.Name']
          }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!--{{ "KEYWORD.treatment" | translate | titlecase }} Type -->
  <div class="full-width" *ngIf="section['Code'] == 'TYP' && property && property['PropertyCode'] == 'Type_FK'">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label> {{"KEYWORD.Treatment" | translate}} Type</mat-label>
      <mat-select
        class="full-width"
        [(ngModel)]="filterValue"
        (selectionChange)="getValue($event.value)"
        name="treatmentType"
      >
        <mat-option *ngFor="let c of treatmentTypes" [value]="c['TreatmentType_key']">{{
          c['TreatmentType.Label']
          }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!--{{ "KEYWORD.treatment" | translate | titlecase }} Category -->
  <div class="full-width" *ngIf="section['Code'] == 'CAT' && property && property['PropertyCode'] == 'Category_FK'">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label> {{"KEYWORD.Treatment" | translate}} Category</mat-label>
      <mat-select
        class="full-width"
        [(ngModel)]="filterValue"
        (selectionChange)="getValue($event.value)"
        name="treatmentCategory"
      >
        <mat-option *ngFor="let c of treatmentCategories" [value]="c['TreatmentCategory_key']">{{
          c['TreatmentCategory.Label']
          }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
