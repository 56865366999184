<!-- user interface to select prescription drugs -->
<div class="row clearfix drugs-input-container">
  <form>
    <div class="row clearfix flex">
      <!-- searchable autofill for drug name -->
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Search for medication by name</mat-label>
        <input
          class="mat-block"
          [(ngModel)]="drugName"
          matInput
          placeholder="Enter the medication name"
          name="otherDrugNameInput"
          required
        />
      </mat-form-field>

      <!-- utility buttons -->
      <div class="button-container">
        <!-- show manual entry form -->
        <button (click)="manualEntry = !manualEntry" mat-stroked-button color="primary">
          <mat-icon>search</mat-icon> Manual entry
        </button>
      </div>
    </div>

    <!-- show when manual select is chosen -->
    <ng-container>
      <div class="row clearfix flex" @simpleFadeAnimation>
        <!-- Drug type -->
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Type of medication</mat-label>
          <mat-select
            class="full-width animate"
            [(ngModel)]="drug.drugType"
            placeholder="Select type of medication"
            name="drugType"
          >
            <mat-option [value]="'others'"> Others</mat-option>

            <mat-option *ngFor="let DrugType of drugTypes | OrderArrayObject: 'Label'" [value]="DrugType.Code">
              {{ DrugType.Label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="full-width animate"
          *ngIf="drug.drugType == 'others'"
          appearance="outline"
        >
          <mat-label>Enter Type of medication</mat-label>
          <input
            class="mat-block"
            [(ngModel)]="drug.drugTypeOther"
            matInput
            placeholder="Enter Type of medication"
            name="drugTypeOther"
          />
        </mat-form-field>

        <div class="full-width flex">
          <mat-form-field
            class="full-width animate"
            *ngIf="(drug && !drug.drugID) || !drug"
            appearance="outline"
          >
            <mat-label>Name of medication</mat-label>
            <input
              class="mat-block"
              [(ngModel)]="drug.name"
              matInput
              placeholder="Name of medication"
              name="drugname1"
            />
          </mat-form-field>

          <mat-form-field
            class="full-width animate"
            *ngIf="drug && drug.drugID"
            appearance="outline"
          >
            <mat-label>Name of medication</mat-label>
            <input
              class="mat-block"
              [disabled]="true"
              [value]="drug.name"
              matInput
              placeholder="Name of medication"
              name="drugname2"
            />
          </mat-form-field>

          <button
            *ngIf="drug && drug.drugID"
            (click)="viewDrug()"
            mat-icon-button
            color="primary"
            matTooltip="View information"
          >
            <mat-icon class="primary-color">visibility_on</mat-icon>
          </button>
        </div>
      </div>
    </ng-container>

    <!-- shown when type and name is selected -->
    <ng-container>
      <div class="row clearfix flex" @simpleFadeAnimation>
        <!-- drug dose type/method -->
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>How is it taken?</mat-label>
          <mat-select
            class="full-width animate"
            [(ngModel)]="drug.doseType"
            placeholder="Select how your dose is taken"
            name="drugTakenType"
          >
            <mat-option [value]="'others'"> Others</mat-option>

            <mat-option *ngFor="let DrugDoseType of doseTypes | OrderArrayObject: 'Label'" [value]="DrugDoseType.Code">
              {{ DrugDoseType.Label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="full-width animate"
          *ngIf="drug.doseType == 'others'"
          appearance="outline"
        >
          <mat-label>Enter the dose type</mat-label>
          <input
            class="mat-block"
            [(ngModel)]="drug.doseTypeOther"
            matInput
            placeholder="Enter the dose type"
            name="doseTypeOther"
          />
        </mat-form-field>

        <!-- drug dosage amount -->
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>How is dosage measured?</mat-label>
          <mat-select
            class="full-width animate"
            [(ngModel)]="drug.doseUnit"
            placeholder="Select the dose you're prescribed"
            name="drugTakenMeasurement"
          >
            <mat-option [value]="'others'"> Others</mat-option>

            <mat-option
              *ngFor="let DrugDoseMeasurement of doseUnitTypes | OrderArrayObject: 'Label'"
              [value]="DrugDoseMeasurement.Code"
            >
              {{ DrugDoseMeasurement.Label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="full-width animate"
          *ngIf="drug.doseUnit == 'others'"
          appearance="outline"
        >
          <mat-label>Enter the dose unit</mat-label>
          <input
            class="mat-block"
            [(ngModel)]="drug.doseUnitOther"
            matInput
            placeholder="Enter the dose unit"
            name="doseUnitOther"
          />
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container>
      <div class="row clearfix flex text-center full-width" @simpleFadeAnimation>
        <app-operator-filter
          class="full-width animate"
          [selectDateRange]="'days30'"
          [dateLabel]="'When Did you start Taking the Medication'"
          [sendNull]="true"
          [selectDateRange]=""
          [displayMerchant]="false"
          [displayDateFrom]="false"
          [displayDateTo]="false"
          [displayProduct]="false"
          [displayDentist]="false"
          (search)="getDateResult($event)"
        >
        </app-operator-filter>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Enter the Medication Frequency</mat-label>
          <mat-select
            class="full-width animate"
            [(ngModel)]="drug.drugFrequency"
            placeholder="Enter the Medication Frequency"
            name="drugTakenMeasurement"
          >
            <mat-option *ngFor="let f of drugFrequencies | OrderArrayObject: 'Label'" [value]="f.Code">
              {{ f.Label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="full-width animate" appearance="outline">
          <mat-label>How many times a day?</mat-label>
          <input class="mat-block" matInput placeholder="Frequency of dosage per day?" name="drugOtherDose"/>
        </mat-form-field>
      </div>
      <div class="row clearfix flex text-center full-width" @simpleFadeAnimation>
        <textarea
          class="mat-block"
          [(ngModel)]="drug.note"
          matInput
          placeholder="do you have something to mention"
          name="templatefacebookPost"
        ></textarea>
      </div>
    </ng-container>
  </form>
</div>

<!-- form controller for adding or clearing selection -->
<ng-container>
  <div class="row clearfix drugs-control-container mb text-center" @simpleFadeAnimation>
    <button class="mr" (click)="clearDrug()" mat-raised-button color="primary">
      <mat-icon>clear</mat-icon> Clear
    </button>

    <button
      class="ml"
      (click)="addMedication()"
      mat-raised-button
      color="accent"
    >
      <mat-icon>add</mat-icon> Add medication
    </button>
  </div>
</ng-container>

<!-- display list of selected drugs -->
<ng-container *ngIf="medications && medications.length > 0">
  <div class="row clearfix drugs-list-container add-mb" @simpleFadeAnimation>
    <mat-list class="drugs-list">
      <h3 mat-subheader>Prescription medications selected</h3>
      <mat-list-item *ngFor="let m of medications | OrderArrayObject: 'DateTimeCreated'">
        <app-drugs-thumbnail class="thumbnailDrug text-center mr" [drugID]="drug.drugID"></app-drugs-thumbnail>

        <h4 class="bold" mat-line>{{ m['Drug.Name'] }}</h4>

        <p mat-line>
          <span class="drug-type">{{ m['Drug.Name'] }}</span> -
          <span class="drug-dose-amount">{{ m['Drug.Name'] }}</span>
        </p>
        <div class="list-control-buttons">
          <button (click)="openDrugViewDialog(m['ID'])" mat-icon-button color="primary" matTooltip="View information">
            <mat-icon class="primary-color">visibility_on</mat-icon>
          </button>
          <button (click)="removeDrugFromList()" mat-icon-button color="accent" matTooltip="Remove selection">
            <mat-icon class="accent-color">clear</mat-icon>
          </button>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</ng-container>
