<mat-card class="rel clearfix" *ngIf="iframeSrc" [ngClass]="!title ? 'standAlone' : ''">
  <div
    class="card-header primary-gradient-img clearfix stacked"
    *ngIf="title && background"
    [attr.style]="'background:' + background + ' !important' | safeStyle"
  >
    <button class="btn-close btn-clear mat-button smt white custom-close" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>

    <div class="row clearfix">
      <div class="row titleArea clearfix">
        <h2 class="summary-header text-left rm-m">
          {{ title }}
        </h2>
      </div>
    </div>
  </div>
  <div
    class="card-header primary-gradient-img clearfix stacked"
    *ngIf="title && !background"
    [attr.style]="'background:' + background + ' !important' | safeStyle"
  >
    <button class="btn-close btn-clear mat-button smt white custom-close" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>

    <div class="row clearfix">
      <div class="row titleArea clearfix">
        <h2 class="summary-header text-left rm-m">
          {{ title }}
        </h2>
      </div>
    </div>
  </div>

  <mat-dialog-content [ngClass]="!isFile ? 'iframe-document-link-dialog' : 'iframe-document-link-dialog-no-padding'">
    <div class="full-width text-center" *ngIf="link && documentType == 'video' && extension" @simpleFadeAnimation>
      <video class="full-width" controls="true" playsinline autoplay controlsList="nodownload" width="100%">
        <source src="{{ link }}" type="{{ mimeDocumentType }}"/>

        <app-empty-list-message
          class="clearfix full-width"
          [title]="'Sorry!'"
          [message]="'Your browser does not support the video tag.'"
          [isAction]="true"
          [actionIcon]="'cloud_download'"
          [actionLabel]="'Download'"
          (actionEvent)="downloadFile()"
        >
        </app-empty-list-message>
      </video>
    </div>

    <div class="full-width text-center" *ngIf="link && documentType == 'image' && extension" @simpleFadeAnimation>
      <pinch-zoom
        class="full-height pinchZoom pinchZoom-blob"
        [limit-zoom]="25"
        [wheel]="true"
        [wheelZoomFactor]="0.5"
        [double-tap]="true"
        [properties]="pinchZoomProperties"
      >
        <img [src]="iframeSrc" />
      </pinch-zoom>
    </div>

    <div class="full-width text-center" *ngIf="link && documentType == 'pdf' && extension" @simpleFadeAnimation>
      <div *ngIf="link" lass="full-width text-center clearfix mb mt  ">
        <ng2-pdfjs-viewer class="min-height" [pdfSrc]="link" [openFile]="false"></ng2-pdfjs-viewer>
      </div>
    </div>

    <div
      class="full-width text-center"
      *ngIf="
        documentType &&
        documentType !== 'pdf' &&
        documentType != 'html' &&
        documentType != 'video' &&
        documentType != 'image'
      "
      @simpleFadeAnimation
    >
      <app-empty-list-message
        class="clearfix full-width"
        [title]="''"
        [message]="'We can not display this file type'"
        [isAction]="true"
        [actionIcon]="'cloud_download'"
        [actionLabel]="'Download'"
        (actionEvent)="downloadFile()"
      >
      </app-empty-list-message>
    </div>
    <div class="full-width text-center" *ngIf="!documentType || documentType == 'html'" @simpleFadeAnimation>
      <iframe class="iframe-document-link full-width row clearfix" [src]="iframeSrc"></iframe>
    </div>
  </mat-dialog-content>
  <div class="drop-button text-center white-background" *ngIf="extraDetails == true">
    <hr/>

    <button
      class="action-button btn-large ml pull-right smt"
      *ngIf="isSubmit == true"
      (click)="submitEvent()"
      mat-raised-button
      color="accent"
    >
      {{ SubmitLabel }}
    </button>

    <button
      class="action-button btn-large ml pull-right smt"
      *ngIf="isAction == true"
      (click)="getActionEvent()"
      mat-raised-button
      color="accent"
    >
      {{ actionLabel }}
    </button>

    <button
      class="action-button btn-large ml pull-right smt"
      *ngIf="isOpenNewTab == true"
      (click)="preview()"
      mat-raised-button
      color="accent"
    >
      Open in New Tab
    </button>

    <button
      class="action-button btn-large ml pull-right smt"
      *ngIf="true == true"
      (click)="copy()"
      mat-raised-button
      color="primary"
    >
      Copy Link
    </button>

    <button
      class="action-button btn-large pull-right smt"
      *ngIf="isDownload == true"
      (click)="downloadFile()"
      mat-raised-button
      color="primary"
    >
      Download
    </button>

    <button class="action-button btn-large pull-left smt" (click)="closeEvent()" mat-raised-button color="primary">
      Close
    </button>
  </div>
  <div class="drop-button text-center white-background" *ngIf="extraDetails != true">
    <hr/>

    <button class="action-button btn-large smt" (click)="closeEvent()" mat-raised-button color="primary">Close</button>
  </div>
</mat-card>
