import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { NotifyAppComponent } from '../../types/notify-app-component';
import { Settings } from '../../types/settings';
import { CodeHTMLViewComponent } from '../html-view/html-view.component';
import { RootAppComponent } from '../root-component/root-component.component';

@Component({
  selector: 'app-html-css-input-backup',
  templateUrl: './html-css-input-backup.component.html',
  styleUrls: ['./html-css-input-backup.component.css'],
})
export class HtmlCssInputBackupComponent implements OnInit {
  @Input()
  displayEditorOptions = true;

  @Input()
  isEditStyle = true;

  @Input()
  descriptionHTML = 'Choose the editor type to create HTML content,you can also change the view type.';
  @Input()
  descriptionCSS = 'Use this Component to add your CSS content.';

  @Input()
  descriptionDocument = 'Upload file , only accept .html .css';
  @Input()
  descriptionRaw =
    'Use this component to create your HTML Content , you can preview the result in real time on the  panel  in the right ';
  @Input()
  descriptionEditor =
    'Use this component to create your HTML Content, drag and drop modals from the  panel  in the right';

  @Input()
  descriptionPreview = 'This is result of applying styling  with HTML  Preview content.';

  isFullScreen = false;
  updatingContent = false;
  @Input()
  label = 'Content';
  @Input()
  fileID;
  @Input()
  EPDocumentID;
  @Input()
  displayUploader = true;
  @Input()
  structure;
  @Input()
  inputType = 'TinyEditor';
  @Input()
  isFroalaEditorActive = Settings.global['isFroalaEditorActive'];
  @Input()
  isUnlayerActive = Settings.global['isUnlayerActive'];
  @Input()
  isTinyEditorActive = Settings.global['isTinyEditorActive'];
  @Input()
  isRawPreview = true;
  @Input()
  isCss = false;
  @Input()
  isRawActive = true;
  @Input()
  isTextActive = true;
  @Input()
  content;

  @Input()
  style;
  @Input()
  isComponent = false;
  @Input()
  componentID;

  @Output()
  getContent = new EventEmitter();

  @Output()
  getPreview = new EventEmitter();

  @Output()
  getStructure = new EventEmitter();

  @Output()
  getStyle = new EventEmitter();
  @Output()
  getResult = new EventEmitter();
  @Output()
  getComponentID = new EventEmitter();
  @Output()
  getIsEditStyle = new EventEmitter();
  _content = '';
  _style = '';
  _preview = '';
  _structure;

  _contentDefault = '';
  _styleDefault = '';

  load = true;
  isHidden = false;
  @Input()
  viewType = 'view1';

  settings = Settings.global;

  isContentExist = false;
  isStyleExist = false;

  merchantID;
  practice;
  terminalCode;
  staticContent = [];

  constructor(private authenticaitonService: AuthenticationService, private _clipboardService: ClipboardService) {}

  ngOnInit() {
    this.authenticaitonService.getCurrentPractice().subscribe((r) => {
      if (r && r.ID) {
        this.practice = r;
        this.merchantID = r.ID;

        if (this.merchantID) {
          this.authenticaitonService
            .practiceLandingPageOneByMerchant(this.merchantID, { fields: 'Code' })
            .subscribe((c) => {
              if (c && c.Code) {
                this.terminalCode = c.Code;
              }

              this.setup();
            });
        } else {
          this.setup();
        }
      } else {
        this.setup();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.merchantID) {
      this.authenticaitonService.getCurrentPractice().subscribe((r) => {
        if (r && r.ID) {
          this.practice = r;
          this.merchantID = r.ID;

          if (this.merchantID) {
            this.authenticaitonService
              .practiceLandingPageOneByMerchant(this.merchantID, { fields: 'Code' })
              .subscribe((c) => {
                if (c && c.Code) {
                  this.terminalCode = c.Code;
                }

                this.setup();
              });
          } else {
            this.setup();
          }
        } else {
          this.setup();
        }
      });
    } else {
      this.setup();
    }
  }

  setup() {
    if (this.merchantID && this.terminalCode) {
      this.staticContent = [
        {
          label: 'Practice Picture',
          content: `<!--START: Practice Picture-->\n<img  style="max-width:100%" src="${this.settings['nodeJSServer']}/merchant/practice-picture-stream/${this.merchantID}" />\n<!--END: Practice Picture-->`,
        },
        {
          label: 'Landing Page Link',
          content: `<!--START: Landing Page Link-->\n<a  href='${this.settings['publicSiteFrontendLink']}/home/${this.terminalCode}' target="_blank">${this.settings['publicSiteFrontendLink']}/home/${this.terminalCode}</a>\n<!--END: Landing Page Link-->`,
        },
        {
          label: 'Offer Page Link',
          content: `<!--START: Offer Page Link-->\n<a  href='${this.settings['publicSiteFrontendLink']}/news/${this.terminalCode}' target="_blank">${this.settings['publicSiteFrontendLink']}/news/${this.terminalCode}</a>\n<!--END: Offer Page Link-->`,
        },
        {
          label: 'Appointement Page Link',
          content: `<!--START: Appointement Page Link-->\n<a  href='${this.settings['publicSiteFrontendLink']}/appointment-request/${this.terminalCode}' target="_blank">${this.settings['publicSiteFrontendLink']}/appointment-request/${this.terminalCode}</a>\n<!--END: Appointement Page Link-->`,
        },
      ];
    }
    if (!this.componentID) {
      this.componentID = 'ID' + btoa(Math.random().toString()).substring(2, 20);
    }
    if (this.componentID && this.isComponent == true) {
      this.descriptionCSS =
        "We generated an <strong>Container ID</strong> for you, please add wrap your CSS properties under the ID, Clicks on <strong><i class='fas fa-copy' style='color:#33455a;margin-right:5px; margin-left:5px;'></i></strong> to get the Generated.";
    }

    if (this.content) {
      this._content = this.content;
      this._contentDefault = this.content;
      this.isContentExist = true;
    }

    if (this.style) {
      this._style = this.style;
      this._styleDefault = this.style;
      this.isStyleExist = true;
    }

    if (this.structure) {
      this._structure = this.structure;
    }
    if (this.componentID && !this._style && this.isComponent == true) {
      this._style = `#${this.componentID} {\n\n\n}`;
    }

    if (this.isContentExist == true || this.isStyleExist == true) {
      this.getContentEvent(null, false);
    }
  }

  getStructureEvent(s) {
    this._structure = s;
    this.getStructure.emit(this._structure);
  }

  getStyleEvent(s) {
    this._style = s;

    this.getContentEvent(null, false);
  }

  getContentEvent(d = null, updateContent = true) {
    if (d && updateContent === true) {
      this._content = d;
    }

    if (this.componentID && this.isComponent == true) {
      let addSection = true;

      const _IDindex = this._content.indexOf(this.componentID);

      if (_IDindex !== -1) {
        addSection = false;
      }

      setTimeout(() => {
        const index = this._content.indexOf('</head>');

        if (index === -1) {
          if (addSection === true) {
            this._preview = `<!DOCTYPE html>\n<section id="${this.componentID}">\n<html>\n<head>\n<style>\n${this._style}\n</style>\n</head>\n<body>${this._content}</body>\n</html>\n</section>`;
          } else {
            this._preview = `<!DOCTYPE html>\n<html>\n<head>\n<style>\n${this._style}\n</style>\n</head>\n<body>\n${this._content}\n</body>\n</html>`;
          }
        } else {
          this._preview = this._content.replace('</head>', `<style>\n${this._style}\n</style>\n</head>`);
          if (addSection === true) {
            this._preview = `<section id="${this.componentID}">\n${this._content}\n</section>`;
          }
        }

        this.sendResult();
      }, 200);

      this.sendResult();
    } else {
      if (this._content) {
        setTimeout(() => {
          const index = this._content.indexOf('</head>');

          if (index === -1) {
            this._preview = `<!DOCTYPE html>\n<html>\n<head>\n<style>\n${this._style}\n</style>\n</head>\n<body>\n${this._content}\n</body>\n</html>`;
          } else {
            this._preview = this._content.replace('</head>', `<style>\n${this._style}\n</style>\n</head>`);
          }

          this.sendResult();
        }, 200);
      } else {
        this._preview = '';
      }

      this.sendResult();
    }
  }

  buildContent() {}

  sendResult() {
    const r = {
      content: this._content,
      style: this._style,
      componentID: this.componentID,
    };
    if (this.isEditStyle != true) {
      r.style = null;
      this.getIsEditStyle.emit(this.isEditStyle);
      this.getResult.emit(r);
      this.getContent.emit(this._content);
      this.getStyle.emit(null);
      this.getComponentID.emit(this.componentID);
      this.getPreview.emit(this._preview);
    } else {
      this.getIsEditStyle.emit(this.isEditStyle);
      this.getResult.emit(r);
      this.getContent.emit(this._content);
      this.getStyle.emit(this._style);
      this.getComponentID.emit(this.componentID);
      this.getPreview.emit(this._preview);
    }
  }

  copyID() {
    if (this.componentID) {
      this._clipboardService.copy(`#${this.componentID} `);

      NotifyAppComponent.displayToast('success', 'success', 'Copied');
    }
  }

  copy(d) {
    if (d) {
      this._clipboardService.copy(d);

      NotifyAppComponent.displayToast('success', 'success', 'Copied');
    }
  }

  changeEditorType() {
    this._styleDefault = this._style;
    this._contentDefault = this._content;
    this._structure = this._structure;
  }

  ViewHtmlContent() {
    if (this._preview) {
      const isPreview = true;
      const isCode = true;

      const ref2 = RootAppComponent.dialog.open(CodeHTMLViewComponent, {
        data: {
          icons: 'fas fa-eye',
          title: 'preview',
          content: this._preview,
          style: this._style,
          isPreview,
          isCode,
        },
        width: '1000px',
      });
      ref2.componentInstance.onConfirm.subscribe((res) => {
        ref2.close();
      });
    }
  }

  changeViewType(e) {
    this.viewType = e;

    this.load = false;
    this.isHidden = true;

    setTimeout(() => {
      this.isHidden = false;
      this.load = true;
    }, 100);
  }

  changeCSSInput() {
    this.sendResult();

    this.viewType = 'view1';

    this.load = false;
    this.isHidden = true;

    setTimeout(() => {
      this.isHidden = false;
      this.load = true;
    }, 100);
  }

  switchFullScreen(d) {
    this.isFullScreen = d;

    this.load = false;
    this.isHidden = true;

    setTimeout(() => {
      this.isHidden = false;
      this.load = true;
    }, 100);
  }
}
