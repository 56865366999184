<div class="full-width flex smb">
  <h1 class="sr-title full-width  rm-mt ">Arrears on {{getTitle()}}</h1>

  <div class="row clearfix half-width ">
    <mat-button-toggle-group class="btn-group text-center full-width with-icons" [(ngModel)]="isPositive"
      (ngModelChange)="isPositiveEvent($event)">
      <mat-button-toggle class="text-center full-width" [value]="true"> Overdue</mat-button-toggle>
      <mat-button-toggle class="text-center full-width" [value]="false"> In Credit</mat-button-toggle>
    </mat-button-toggle-group>
  </div>

</div>
<div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div class="full-width" *ngIf="listDB.firstLoadEvent != false">

  <div class="row clearfix   flex mb" *ngIf="listDB && listDB.data && isPositive==true">

    <div class="full-width  flex" *ngFor="        let p of arrearDaysTOP"
      [ngClass]="{'good-total':listDB.data.length==0 }">
      <mat-card class="good-total-container dollarValue full-width totalDollarValue" id="totalAmount"
        *ngIf="!p.minValue && !p.maxValue" [ngClass]="{'selected-arrear': selectedArrearID==p.id }"
        (click)="setFilter(p.id, 'arrears')" [class.introjs-button]="listDB.data.length > 0">
        <h5 class="reduce-marg">Arrears total</h5>
        <div class="info-area">
          <mat-icon class="fas fa-thumbs-up" *ngIf="listDB.data.length == 0"></mat-icon>
          <mat-icon class="fas fa-exclamation-triangle" *ngIf="listDB.data.length > 0"></mat-icon>
          <p class="reduce-marg">
            There are <strong>{{ listDB.data.length }} contracts</strong> in arrears
          </p>
        </div>
        <div class="value-area row clearfix">
          <h3 class="reduce-marg" [class.accent-color]="listDB.data.length > 0">
            $
            <span [endVal]="listDB.data | objectPropertyValue: 'Arrears.Amount' | toNumberArray | sum" countUp></span>
          </h3>
        </div>
      </mat-card>

      <mat-card class="dollarValue sml full-width" *ngIf="p.minValue || p.maxValue"
        [ngClass]="{'selected-arrear': selectedArrearID==p.id }" (click)="setFilter(p.id, 'arrears')">
        <h5 class="reduce-marg">{{p.label}} <span class="bubble background-primary sml" [endVal]="
          listDB.data
          | FilterArrayMultipleValueInRange: 'Arrears.days':[p.minValue, p.maxValue]
           | lengthOfArray
          " countUp></span> </h5>
        <ngx-gauge class="" [size]="120" [type]="gaugeType" [value]="
        ((listDB.data
          | FilterArrayMultipleValueInRange: 'Arrears.days':[p.minValue, p.maxValue]
          | objectPropertyValue: 'Arrears.Amount'
          | toNumberArray
          | sum) /
          (listDB.data | objectPropertyValue: 'Arrears.Amount' | toNumberArray | sum)) *
          100 | number: '1.0-1'
      " [label]="''" [append]="'%'" [max]="100" [thresholds]="lessIsMore" [thick]="12" [cap]="'round'">
        </ngx-gauge>
        <div class="value-area row clearfix">
          <h3 class="reduce-marg" [class.accent-color]="
          (listDB.data
            | FilterArrayMultipleValueInRange: 'Arrears.days':[p.minValue, p.maxValue]
            | objectPropertyValue: 'Arrears.Amount'
            | toNumberArray
            | sum) > 1000
        ">
            $
            <span [endVal]="
            listDB.data
              | FilterArrayMultipleValueInRange: 'Arrears.days':[p.minValue, p.maxValue]
              | objectPropertyValue: 'Arrears.Amount'
              | toNumberArray
              | sum
          " countUp></span>
          </h3>
        </div>
      </mat-card>
    </div>
  </div>

  <hr>
</div>

<div class="row clearfix">

  <div class="full-width" [hidden]="listDB.data.length == 0">
    <div>







      <h2 class="dataHeader">
        Contracts
        <!-- <span class="badge">{{listDB.data.length}}</span> -->

        <span class="badge" id="totalContracts">
          <span [endVal]="dataSource.filteredData.length" countUp></span>
        </span>
      </h2>


      <!-- <div class="row clearfix flex">




        <mat-card class="dollarValue" id="totalAmount" *ngIf="listDB && listDB.data">
          <h5 class="reduce-marg">Arrears 7 days</h5>
          <ngx-gauge class="" [size]="120" [type]="gaugeType" [value]="
              ((listDB.data
                | FilterArrayMultipleValueInRange: 'Arrears.days':[0, 7]
                | objectPropertyValue: 'Arrears.Amount'
                | toNumberArray
                | sum) /
                (listDB.data | objectPropertyValue: 'Arrears.Amount' | toNumberArray | sum)) *
                100 | number: '1.0-1'
            " [label]="''" [append]="'%'" [max]="100" [thresholds]="lessIsMore" [thick]="12" [cap]="'round'">
          </ngx-gauge>
          <div class="value-area row clearfix">
            <h3 class="reduce-marg" [class.accent-color]="
                (listDB.data
                  | FilterArrayMultipleValueInRange: 'Arrears.days':[0, 7]
                  | objectPropertyValue: 'Arrears.Amount'
                  | toNumberArray
                  | sum) > 1000
              ">
              $
              <span [endVal]="
                  listDB.data
                    | FilterArrayMultipleValueInRange: 'Arrears.days':[0, 7]
                    | objectPropertyValue: 'Arrears.Amount'
                    | toNumberArray
                    | sum
                " countUp></span>
            </h3>
          </div>
        </mat-card>

        <mat-card class="dollarValue" id="totalAmount" *ngIf="listDB && listDB.data">
          <h5 class="reduce-marg">Arrears 14 days</h5>
          <ngx-gauge class="" [size]="120" [type]="gaugeType" [value]="
              ((listDB.data
                | FilterArrayMultipleValueInRange: 'Arrears.days':[7, 14]
                | objectPropertyValue: 'Arrears.Amount'
                | toNumberArray
                | sum) /
                (listDB.data | objectPropertyValue: 'Arrears.Amount' | toNumberArray | sum)) *
                100 | number: '1.0-1'
            " [label]="''" [append]="'%'" [max]="100" [thresholds]="lessIsMore" [thick]="12" [cap]="'round'">
          </ngx-gauge>
          <div class="value-area row clearfix">
            <h3 class="reduce-marg" [class.accent-color]="
                (listDB.data
                  | FilterArrayMultipleValueInRange: 'Arrears.days':[7, 14]
                  | objectPropertyValue: 'Arrears.Amount'
                  | toNumberArray
                  | sum) > 1000
              ">
              $
              <span [endVal]="
                  listDB.data
                    | FilterArrayMultipleValueInRange: 'Arrears.days':[7, 14]
                    | objectPropertyValue: 'Arrears.Amount'
                    | toNumberArray
                    | sum
                " countUp></span>
            </h3>
          </div>
        </mat-card>

        <mat-card class="dollarValue" id="totalAmount" *ngIf="listDB && listDB.data">
          <h5 class="reduce-marg">Arrears 30 days</h5>
          <ngx-gauge class="" [size]="120" [type]="gaugeType" [value]="
              ((listDB.data
                | FilterArrayMultipleValueInRange: 'Arrears.days':[14, 30]
                | objectPropertyValue: 'Arrears.Amount'
                | toNumberArray
                | sum) /
                (listDB.data | objectPropertyValue: 'Arrears.Amount' | toNumberArray | sum)) *
                100 | number: '1.0-1'
            " [label]="''" [append]="'%'" [max]="100" [thresholds]="lessIsMore" [thick]="12" [cap]="'round'">
          </ngx-gauge>
          <div class="value-area row clearfix">
            <h3 class="reduce-marg" [class.accent-color]="
                (listDB.data
                  | FilterArrayMultipleValueInRange: 'Arrears.days':[14, 30]
                  | objectPropertyValue: 'Arrears.Amount'
                  | toNumberArray
                  | sum) > 1000
              ">
              $
              <span [endVal]="
                  listDB.data
                    | FilterArrayMultipleValueInRange: 'Arrears.days':[14, 30]
                    | objectPropertyValue: 'Arrears.Amount'
                    | toNumberArray
                    | sum
                " countUp></span>
            </h3>
          </div>
        </mat-card>

        <mat-card class="dollarValue" id="totalAmount" *ngIf="listDB && listDB.data">
          <h5 class="reduce-marg">Arrears 60 days</h5>
          <ngx-gauge class="" [size]="120" [type]="gaugeType" [value]="
              ((listDB.data
                | FilterArrayMultipleValueInRange: 'Arrears.days':[30, 60]
                | objectPropertyValue: 'Arrears.Amount'
                | toNumberArray
                | sum) /
                (listDB.data | objectPropertyValue: 'Arrears.Amount' | toNumberArray | sum)) *
                100 | number: '1.0-1'
            " [label]="''" [append]="'%'" [max]="100" [thresholds]="lessIsMore" [thick]="12" [cap]="'round'">
          </ngx-gauge>
          <div class="value-area row clearfix">
            <h3 class="reduce-marg" [class.accent-color]="
                (listDB.data
                  | FilterArrayMultipleValueInRange: 'Arrears.days':[30, 60]
                  | objectPropertyValue: 'Arrears.Amount'
                  | toNumberArray
                  | sum) > 1000
              ">
              $
              <span [endVal]="
                  listDB.data
                    | FilterArrayMultipleValueInRange: 'Arrears.days':[30, 60]
                    | objectPropertyValue: 'Arrears.Amount'
                    | toNumberArray
                    | sum
                " countUp></span>
            </h3>
          </div>
        </mat-card>

        <mat-card class="dollarValue" id="totalAmount" *ngIf="listDB && listDB.data">
          <h5 class="reduce-marg">Arrears 90 days</h5>
          <ngx-gauge class="" [size]="120" [type]="gaugeType" [value]="
              ((listDB.data
                | FilterArrayMultipleValueInRange: 'Arrears.days':[60, 90]
                | objectPropertyValue: 'Arrears.Amount'
                | toNumberArray
                | sum) /
                (listDB.data | objectPropertyValue: 'Arrears.Amount' | toNumberArray | sum)) *
                100 | number: '1.0-1'
            " [label]="''" [append]="'%'" [max]="100" [thresholds]="lessIsMore" [thick]="12" [cap]="'round'">
          </ngx-gauge>
          <div class="value-area row clearfix">
            <h3 class="reduce-marg" [class.accent-color]="
                (listDB.data
                  | FilterArrayMultipleValueInRange: 'Arrears.days':[60, 90]
                  | objectPropertyValue: 'Arrears.Amount'
                  | toNumberArray
                  | sum) > 1000
              ">
              $
              <span [endVal]="
                  listDB.data
                    | FilterArrayMultipleValueInRange: 'Arrears.days':[60, 90]
                    | objectPropertyValue: 'Arrears.Amount'
                    | toNumberArray
                    | sum
                " countUp></span>
            </h3>
          </div>
        </mat-card>

        <mat-card class="dollarValue" id="totalAmount" *ngIf="listDB && listDB.data">
          <h5 class="reduce-marg">Arrears 90+ days</h5>
          <ngx-gauge class="" [size]="120" [type]="gaugeType" [value]="
              ((listDB.data
                | FilterArrayMultipleValueInRange: 'Arrears.days':[90]
                | objectPropertyValue: 'Arrears.Amount'
                | toNumberArray
                | sum) /
                (listDB.data | objectPropertyValue: 'Arrears.Amount' | toNumberArray | sum)) *
                100 | number: '1.0-1'
            " [label]="''" [append]="'%'" [max]="100" [thresholds]="lessIsMore" [thick]="12" [cap]="'round'">
          </ngx-gauge>
          <div class="value-area row clearfix">
            <h3 class="reduce-marg" [class.accent-color]="
                (listDB.data
                  | FilterArrayMultipleValueInRange: 'Arrears.days':[90]
                  | objectPropertyValue: 'Arrears.Amount'
                  | toNumberArray
                  | sum) > 1000
              ">
              $
              <span [endVal]="
                  listDB.data
                    | FilterArrayMultipleValueInRange: 'Arrears.days':[90]
                    | objectPropertyValue: 'Arrears.Amount'
                    | toNumberArray
                    | sum
                " countUp></span>
            </h3>
          </div>
        </mat-card>

        <mat-card class="dollarValue totalDollarValue" id="totalAmount" *ngIf="listDB && listDB.data"
          [class.introjs-button]="dataSource.filteredData.length > 0">
          <h5 class="reduce-marg">Arrears total</h5>
          <div class="info-area">
            <mat-icon class="fas fa-thumbs-up" *ngIf="dataSource.filteredData.length == 0"></mat-icon>
            <mat-icon class="fas fa-exclamation-triangle" *ngIf="dataSource.filteredData.length > 0"></mat-icon>
            <p class="reduce-marg">
              There are <strong>{{ dataSource.filteredData.length }} contracts</strong> in arrears
            </p>
          </div>
          <div class="value-area row clearfix">
            <h3 class="reduce-marg" [class.accent-color]="dataSource.filteredData.length > 0">
              $
              <span [endVal]="listDB.data | objectPropertyValue: 'Arrears.Amount' | toNumberArray | sum" countUp></span>
            </h3>
          </div>
        </mat-card>
      </div> -->

      <mat-card class="list-card">
        <!-- Filters -->
        <div class="row clearfix searchArea primary-gradient-img">
          <div class="row flex justify">
            <div class="full-width clearfix flex animated fadeInDown">
              <mat-icon class="schIcon mt mr">search</mat-icon>


              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Search by name, mobile, email, contract ID, invitation ID ...</mat-label>
                <input class="clearfix" (input)="setFilter($event.target.value, 'fullName')" name="CustomerFullName"
                  placeholder="Search by name, mobile, email, contract ID, invitation ID ..." matInput />
              </mat-form-field>

              <!-- <mat-form-field class="ml half-width" appearance="outline">
                <mat-label>Merchant Name</mat-label>
                <mat-select class="full-width" (selectionChange)="setFilter($event.value, 'MerchantName')"
                  name="MerchantName" placeholder="Merchant Name">
                  <mat-option [value]="null">
                    All
                  </mat-option>
                  <mat-option *ngFor="
                      let a of listDB.data
                        | objectPropertyValue: 'MerchantName'
                        | unique
                        | without: ['', null]
                        | orderBy
                    " [value]="a">
                    {{ a }}
                  </mat-option>
                </mat-select>
              </mat-form-field> -->

              <!-- <mat-form-field class="ml half-width" appearance="outline">
                <mat-label>Product Name</mat-label>
                <mat-select class="full-width" (selectionChange)="setFilter($event.value, 'ProductName')"
                  name="ProductName" placeholder="Product Name">
                  <mat-option [value]="null">
                    All
                  </mat-option>
                  <mat-option *ngFor="
                      let a of listDB.data | objectPropertyValue: 'ProductName' | unique | without: ['', null] | orderBy
                    " [value]="a">
                    {{ a }}
                  </mat-option>
                </mat-select>
              </mat-form-field> -->

              <mat-form-field class="ml half-width" appearance="outline">
                <mat-label>Funder Name</mat-label>
                <mat-select class="full-width" (selectionChange)="setFilter($event.value, 'FunderName')"
                  name="FunderName" placeholder="Funder Name">
                  <mat-option [value]="null">
                    All
                  </mat-option>
                  <mat-option *ngFor="
                      let a of listDB.data | objectPropertyValue: 'FunderName' | unique | without: ['', null] | orderBy
                    " [value]="a">
                    {{ a }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="ml half-width" appearance="outline"
                *ngIf="merchantsFilter && merchantsFilter.length>1">
                <mat-label>{{'Merchant' | translate}}</mat-label>
                <mat-select class="full-width" (selectionChange)="setFilter($event.value, 'Merchant_key')"
                  name="ContractStatussss" placeholder="{{'Merchant' | translate}}">
                  <mat-option [value]="null">
                    All
                  </mat-option>
                  <mat-option *ngFor="let a of merchantsFilter" [value]="a.ID">

                    <app-card-picture class="sms-merchant-pic-settlement text-center  mr " [hideZoomButton]="true"
                      [cardID]="a.ID" [hideZoomButton]="false" [tableName]="'Merchant'">
                    </app-card-picture> <span style="height:50px; line-height: 50px;">{{a.Label}}</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>


              <mat-form-field class="ml half-width" appearance="outline"
                *ngIf="statusCodesFilter && statusCodesFilter.length>1">
                <mat-label>Contract Status</mat-label>
                <mat-select class="full-width" (selectionChange)="setFilter($event.value, 'Status.Code')"
                  name="ContractStaasatus" placeholder="Contract Status">
                  <mat-option [value]="null">
                    All
                  </mat-option>
                  <mat-option *ngFor="let a of  statusCodesFilter" [value]="a.code">
                    <strong class="chip chip-enable" [style.background]="a['color']">
                      <mat-icon class="{{a['icon']}} smr" style="font-size: 1rem !important;"></mat-icon>
                      {{a['label']}}
                    </strong>
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </div>

          </div>
        </div>

        <table class="list-table" #table [dataSource]="dataSource" mat-table matSort>
          <ng-container matColumnDef="Merchant">
            <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header>Merchant</th>
            <td class="pointer" *matCellDef="let row" (click)="viewContract(row['ID'], row['Customer_key'])" mat-cell
              matTooltip="{{ row['MerchantName'] }}">
              {{ row['MerchantName'] }}
            </td>
            <td *matFooterCellDef mat-footer-cell></td>
          </ng-container>


          <ng-container cdkColumnDef="status">
            <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header>Cotract Status</th>
            <td class="pointer" *matCellDef="let row" (click)="viewContract(row['ID'], row['Customer_key'])" mat-cell>
              <strong class="chip chip-enable" [style.background]="row['Status.Color']">
                <mat-icon class="{{row['Status.Icon']}} smr" style="font-size: 1rem !important;"></mat-icon>
                {{row['Status']}}
              </strong>

            </td>
            <td *matFooterCellDef mat-footer-cell></td>
          </ng-container>


          <ng-container cdkColumnDef="BorrowerName">
            <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header>Name</th>
            <td class="pointer" *matCellDef="let row" (click)="viewContract(row['ID'], row['Customer_key'])" mat-cell
              matTooltip="{{ row['Customer.FullName'] }}">
              <strong>{{ row['Customer.FullName'] }}</strong>
            </td>
            <td *matFooterCellDef mat-footer-cell></td>
          </ng-container>

          <ng-container cdkColumnDef="AmountFinanced">
            <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header>Financed</th>
            <td class="pointer" *matCellDef="let row" (click)="viewContract(row['ID'], row['Customer_key'])" mat-cell
              matTooltip="{{ row['Amount.Financed'] | customCurrency }}">
              {{ row['Amount.Financed'] | customCurrency }}
            </td>
            <td class="largeFont" *matFooterCellDef mat-footer-cell>
              {{ getTotalColumn('Amount.Financed') | customCurrency }}
            </td>
          </ng-container>

          <ng-container cdkColumnDef="Outstanding">
            <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header>Outstanding</th>
            <td class="pointer" *matCellDef="let row" (click)="viewContract(row['ID'], row['Customer_key'])" mat-cell
              matTooltip="{{ row['Amount.Outstanding'] | customCurrency }}">
              {{ row['Amount.Outstanding'] | customCurrency }}
            </td>
            <td class="largeFont" *matFooterCellDef mat-footer-cell>
              {{ getTotalColumn('Amount.Outstanding') | customCurrency }}
            </td>
          </ng-container>

          <ng-container cdkColumnDef="ArrearsAmount">
            <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header>
              Arrears Amount
            </th>
            <td class="pointer" *matCellDef="let row" (click)="viewContract(row['ID'], row['Customer_key'])" mat-cell
              matTooltip="{{ row['Arrears.Amount'] | customCurrency }}">
              {{ row['Arrears.Amount'] | customCurrency }}
            </td>
            <td class="largeFont" *matFooterCellDef mat-footer-cell>
              {{ getTotalColumn('Arrears.Amount') | customCurrency }}
            </td>
          </ng-container>

          <ng-container matColumnDef="merchantPicture">
            <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header></th>
            <td *matCellDef="let row" (click)="viewContract(row)" mat-cell>

              <app-card-picture class="sms-merchant-pic-settlement text-center pull-left " [hideZoomButton]="true"
                [cardID]="row.Merchant_key" [hideZoomButton]="false" [tableName]="'Merchant'">
              </app-card-picture>

            </td>
            <td class="largeFont" *matFooterCellDef mat-footer-cell></td>
          </ng-container>

          <ng-container matColumnDef="patientPicture">
            <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header></th>
            <td *matCellDef="let row" (click)="viewContract(row)" mat-cell>


              <app-card-picture class="sms-merchant-pic-settlement text-center pull-left " [hideZoomButton]="true"
                [cardID]="row.Customer_key" [hideZoomButton]="false" [tableName]="'Customer'">
              </app-card-picture>

            </td>
            <td class="largeFont" *matFooterCellDef mat-footer-cell></td>
          </ng-container>

          <ng-container cdkColumnDef="ArrearsDays">
            <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header>
              Arrears Days (days)
            </th>
            <td class="pointer" *matCellDef="let row" (click)="viewContract(row['ID'], row['Customer_key'])" mat-cell
              matTooltip="{{ row['Arrears.days'] }}">
              {{ row['Arrears.days'] }}
            </td>
            <td class="largeFont" *matFooterCellDef mat-footer-cell>Totals</td>
          </ng-container>

          <ng-container cdkColumnDef="Actions">
            <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header>Actions</th>
            <td *matCellDef="let row" mat-cell>
              <div style="float: right">
                <app-invitation-actions *ngIf="row" [customerID]="row['Customer_key']"
                  [invitationID]="row['Invitation_key']" [items]="items" [contractID]="row['ID']"
                  [merchantID]="row['Merchant_key']" [dentistID]="row['DentistContact_key']"
                  [campaignID]="row['Campaign_Key']" [contract]="row" [productID]="row['Product_key']">
                </app-invitation-actions>

                <!--
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button> -->
                <mat-menu #menu="matMenu">
                  <button (click)="createNote(row['ID'], row['Customer_key'])" mat-menu-item>
                    <mat-icon class="fas fa-sticky-note"></mat-icon>
                    <span>Create note</span>
                  </button>

                  <button (click)="viewNotes(row['ID'])" mat-menu-item>
                    <mat-icon class="fas fa-list-alt"></mat-icon>
                    <span>View note</span>
                  </button>

                  <button (click)="sendSMS(row['Customer_key'])" mat-menu-item>
                    <mat-icon class="far fa-comment-dots"></mat-icon>
                    Send SMS
                  </button>

                  <button (click)="viewContract(row['ID'], row['Customer_key'])" mat-menu-item>
                    <mat-icon class="fas fa-file-invoice-dollar"></mat-icon>
                    View Contract
                  </button>
                </mat-menu>
              </div>
            </td>
            <td *matFooterCellDef mat-footer-cell></td>
          </ng-container>

          <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
          <tr *matFooterRowDef="displayedColumns; sticky: true" mat-footer-row></tr>
        </table>

        <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions">
        </mat-paginator>
      </mat-card>
    </div>
  </div>

  <div *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
    <app-empty-list-message [title]="'No contracts found'" [message]="'There are no contracts found in the database'">
    </app-empty-list-message>
  </div>
</div>
