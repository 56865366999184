<div class="row clearfix">
  <div class="pull-right" *ngIf="isModal == true">
    <button class="btn-close btn-clear pull-right mat-button" (click)="closeModal()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <div class="row clearfix" *ngIf="complianceTask">
    <div class="row clearfix flex">
      <div class="row clearfix full-width">
        <div class="colour-stripe"></div>
        <h3 class="sr-title sm-h3 rm-m">
          Compliance Tasks
          <span class="badge">
            <span [endVal]="complianceTask.length" countUp></span>
          </span>
        </h3>
        <!-- <label>Results:</label> -->
        <hr/>
      </div>

      <!-- large heading for standalone usage -->
      <!-- <div class="row clearfix full-width" *ngIf="largeHeading==true">
        <h1 class="dataHeader rm-mt">Compliance Tasks
          <span class="badge">
            <mat-icon>new_releases</mat-icon>
            <span countUp [endVal]="complianceTask.length"></span>
          </span>
        </h1>
      </div> -->
      <!-- small coloured heading for use within utility pages -->
      <!-- <div class="row clearfix full-width" *ngIf="largeHeading==false">
        <h3 class="sm-h3 sr-title rm-m">Compliance Tasks

        </h3>
        <span class="badge inline ml">
          <mat-icon>new_releases</mat-icon>
          <span countUp [endVal]="complianceTask.length"></span>
        </span>
      </div> -->

      <div class="row clearfix flex-center flex clearfix full-width">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Filter</mat-label>
          <input name="filter" matInput placeholder="Filter by date, type or label"/>
        </mat-form-field>
      </div>
    </div>
    <mat-dialog-content *ngIf="emptyList != true" [class.taskContent]="isStaticHeight == true">
      <vertical-timeline [@stagger]="complianceTask.length">
        <vertical-timeline-card
          class=""
          *ngFor="let c of complianceTask"
          [@fade]
          [dateValue]="c['DateTimeCreated'] | convertDate"
          [timeString]="c['DateTimeCreated'] | customDateTime: 'HH:mm'"
        >
          <!-- <p>Operator: <span>{{getOperator(c['Operator_key'])}}</span></p> -->
          <p *ngFor="let note of rearrangeNote(c['Note'])">{{ note }}</p>
        </vertical-timeline-card>
      </vertical-timeline>
    </mat-dialog-content>
  </div>
</div>
