<ipv-title-modal [title]="title" [hideAllDefaultButtons]="true" [classIcon]="'fa fa-sticky-note'">
  <div content>
    <div class="row clearfix">
      <h3 class="sr-title mr text-left">Label</h3>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Label</mat-label>
        <input
          matInput
          name="type"
          [(ngModel)]="noteType"
          placeholder="Type"
          (ngModelChange)="setNoteType()"
          required
        />

        <ipv-clear-text-button (click)="clear()"></ipv-clear-text-button>

        <mat-icon class="fas fa-check" style="color: green" matSuffix *ngIf="isNoteTypeUnique === true"></mat-icon>

        <mat-icon class="fas fa-times" style="color: red" matSuffix *ngIf="isNoteTypeUnique === false"></mat-icon>
      </mat-form-field>

      <div *ngIf="isNoteTypeUnique === false" style="margin-top: 10px; font-size: small; color: red; text-align: left">
        Note type already exists
      </div>
    </div>

    <hr />
    <div class="row clearfix mt">
      <h3 class="sr-title rm-m pull-left">Subjects</h3>
      <button class="btn-small pull-right" mat-raised-button [color]="'primary'" (click)="addSubject()">
        <mat-icon>add</mat-icon>
        Add Subject
      </button>
    </div>

    <div class="row clearfix" *ngFor="let subject of subjects; let i=index; trackBy:trackByItem">
      <div class="row clearfix flex">
        <button
          class="pull-left mt"
          mat-icon-button
          color="accent"
          (click)="removeSubject(i)"
          *ngIf="subjects.length>1"
        >
          <mat-icon>delete_forever</mat-icon>
        </button>

        <div class="full-width">
          <mat-form-field class="mt full-width" appearance="outline">
            <mat-label>Subject</mat-label>
            <input
              matInput
              name="subject"
              [disabled]="noteType.length === 0 || isNoteTypeUnique === false || isNoteTypeUnique === null"
              [value]="subject"
              (change)="setSubjectText(i, $event)"
              placeholder="Subject"
              required
            />
          </mat-form-field>
        </div>
      </div>
    </div>

    <hr />

    <div class="row clearfix mt">
      <h3 class="sr-title rm-m pull-left">Results</h3>
      <button class="btn-small pull-right" mat-raised-button [color]="'primary'" (click)="addResult()">
        <mat-icon>add</mat-icon>
        Add Result
      </button>
    </div>

    <div class="row clearfix" *ngFor="let result of results; let i=index; trackBy:trackByItem">
      <div class="row clearfix flex">
        <button class="pull-left mt" mat-icon-button color="accent" (click)="removeResult(i)" *ngIf="results.length>1">
          <mat-icon>delete_forever</mat-icon>
        </button>

        <mat-form-field class="full-width mt" appearance="outline">
          <mat-label>Result</mat-label>
          <input
            matInput
            name="result"
            [value]="result"
            [disabled]="noteType.length === 0 || isNoteTypeUnique === false || isNoteTypeUnique === null"
            (change)="setResultText(i, $event)"
            placeholder="Result"
            required
          />
        </mat-form-field>
      </div>
    </div>

    <hr />
  </div>
</ipv-title-modal>

<mat-dialog-actions style="flex-direction: row-reverse">
  <button
    class="button-save ml"
    mat-raised-button
    [color]="'primary'"
    [disabled]="!(getIsSubjectInvalid() && getIsResultInvalid()) && (noteType.length === 0 || isNoteTypeUnique === false || isNoteTypeUnique === null)"
    (click)="save()"
    *ngIf="formMode === 'create'"
  >
    Save
  </button>

  <button
    class="button-save ml"
    mat-raised-button
    [color]="'primary'"
    [disabled]="!(getIsSubjectInvalid() && getIsResultInvalid()) && (noteType.length === 0 || isNoteTypeUnique === false || isNoteTypeUnique === null)"
    (click)="update()"
    *ngIf="formMode === 'update'"
  >
    Update
  </button>

  <button class="button-save" mat-raised-button [color]="'accent'">Cancel</button>
</mat-dialog-actions>
