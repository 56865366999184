/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tooth-edit.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./tooth-edit.component";
var styles_ToothEditComponent = [i0.styles];
var RenderType_ToothEditComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToothEditComponent, data: {} });
export { RenderType_ToothEditComponent as RenderType_ToothEditComponent };
function View_ToothEditComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, ":svg:path", [], [[1, "d", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.class; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.d; _ck(_v, 0, 0, currVal_0); }); }
function View_ToothEditComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tooth.toothNumber; _ck(_v, 1, 0, currVal_0); }); }
export function View_ToothEditComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "teethShap"], ["val", "48"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectTooth() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { selected: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 7, ":svg:svg", [["data-name", "Layer 1"], ["id", "Layer_1"], ["viewBox", "0 0 150.58 247"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, ":svg:defs", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, ":svg:style", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" .cls-1 { fill: #fff; } "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, ":svg:title", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["teeth"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToothEditComponent_1)), i1.ɵdid(11, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToothEditComponent_2)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "teethShap"; var currVal_1 = _ck(_v, 3, 0, _co.tooth.selected); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.toothShape; _ck(_v, 11, 0, currVal_2); var currVal_3 = !_co.tooth.hideNumber; _ck(_v, 13, 0, currVal_3); }, null); }
export function View_ToothEditComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tooth-edit", [], null, null, null, View_ToothEditComponent_0, RenderType_ToothEditComponent)), i1.ɵdid(1, 114688, null, 0, i3.ToothEditComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToothEditComponentNgFactory = i1.ɵccf("app-tooth-edit", i3.ToothEditComponent, View_ToothEditComponent_Host_0, { tooth: "tooth", isAdult: "isAdult" }, { select: "select" }, []);
export { ToothEditComponentNgFactory as ToothEditComponentNgFactory };
