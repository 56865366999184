import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ModuleEditCreateComponent } from '../module-edit-create/module-edit-create.component';
import { ModuleListComponent } from '../module-list/module-list.component';
import { ModuleViewComponent } from '../module-view/module-view.component';
import { ContactCreateEditModalComponent } from '../../../feature/contact/contact-create-edit-modal/contact-create-edit-modal.component';
import { ContactLookupComponent } from '../../../feature/contact/contact-lookup/contact-lookup.component';
import { ContactCreateEditComponent } from '../../../feature/contact/contact-create-edit/contact-create-edit.component';
import { ContactViewComponent } from '../../../feature/contact/contact-view/contact-view.component';
import { SelfServiceLandingPageComponent } from '../../../feature/document/self-service-landing-page/self-service-landing-page.component';
import { DocumentLinkPdfCreateComponent } from '../../../feature/document/document-link-pdf-create/document-link-pdf-create.component';
import { LandingPageNewModalComponent } from '../../../feature/document/landing-page-new-modal/landing-page-new-modal.component';
import { DocumentLinkShareComponent } from '../../../feature/document/document-link-share/document-link-share.component';
import { DocumentListThumbnailComponent } from '../../../feature/document/document-list-thumbnail/document-list-thumbnail.component';
import { LandingPageHeaderLookupComponent } from '../../../feature/document/landing-page-header-lookup/landing-page-header-lookup.component';
import { DocumentLinkViewComponent } from '../../../feature/document/document-link-view/document-link-view.component';
import { DocumentCreateComponent } from '../../../feature/document/document-create/document-create.component';
import { DocumentLinkDetailedViewComponent } from '../../../feature/document/document-link-detailed-view/document-link-detailed-view.component';
import { DocumentLinkCreateComponent } from '../../../feature/document/document-link-create/document-link-create.component';
import { DocumentLinkLookupComponent } from '../../../feature/document/document-link-lookup/document-link-lookup.component';


const routes: Routes = [
  {
    path: 'ModuleListComponent',
    component: ModuleListComponent,
  },
  {
    path: 'ModuleEditCreateComponent',
    component: ModuleEditCreateComponent,
  },
  {
    path: 'ModuleViewComponent',
    component: ModuleViewComponent,
  },

  {
    path: 'ContactCreateEditModalComponent',
    component: ContactCreateEditModalComponent,
  },
  {
    path: 'ContactLookupComponent',
    component: ContactLookupComponent,
  },
  {
    path: 'cContactCreateEditComponent',
    component: ContactCreateEditComponent,
  },
  {
    path: 'ContactViewComponent',
    component: ContactViewComponent,
  },
  {
    path: 'LandingPageNewModalLandingPageNewModalComponent',
    component: LandingPageNewModalComponent,
  },
  {
    path: 'LandingPageHeaderLookupLandingPageHeaderLookupComponentComponent',
    component: LandingPageHeaderLookupComponent,
  },
  {
    path: 'SelfServiceLandingPageSelfServiceLandingPageComponent',
    component: SelfServiceLandingPageComponent,
  },
  {
    path: 'DocumentLinkPdfCreateDocumentLinkPdfCreateComponent',
    component: DocumentLinkPdfCreateComponent,
  },


  {
    path: 'DocumentLinkShareDocumentLinkShareComponentComponent',
    component: DocumentLinkShareComponent,
  },
  {
    path: 'DocumentListThumbnailDocumentListThumbnailComponentComponent',
    component: DocumentListThumbnailComponent,
  },

  {
    path: 'DocumentLinkViewDocumentLinkViewComponentComponent',
    component: DocumentLinkViewComponent,
  },
  {
    path: 'DocumentCreateDocumentCreateComponentComponent',
    component: DocumentCreateComponent,
  },
  {
    path: 'DocumentLinkDetailedViewDocumentLinkDetailedViewComponentComponent',
    component: DocumentLinkDetailedViewComponent,
  },

  {
    path: 'DocumentLinkCreateDocumentLinkCreateComponentComponent',
    component: DocumentLinkCreateComponent,
  },

  {
    path: 'DocumentLinkLookupDocumentLinkLookupComponentComponent',
    component: DocumentLinkLookupComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ModuleRoutingModule {}
