<div class="full-width clearfix">
  <div class="main-banner blue-banner" *ngIf="displayExtra == true"></div>

  <div class="row clearfix container mt ml mr">
    <div class="full-width clearfix">
      <div class="clearfix container-inner-parent" *ngIf="displayExtra == true">
        <div class="clearfix container-inner mt">
          <div class="guid guid-right flex clearfix full-width">
            <div class="container guid-main" data-aos="fade-right" data-aos-delay="400" data-aos-once="true">
              <div class="half-width txt-box">
                <div class="txt" data-aos="fade-left" data-aos-delay="400" data-aos-once="true">
                  <h1 class="text-left header-title-sub-list">
                    It’s not about the price,<br/>
                    its about the value
                  </h1>

                  <p class="text-left header-description-sub-list">
                    When considering your options, it’s easy to compare dollars and cents, but without reviewing all the
                    details you could end up with a bus ticket instead of a boarding pass.
                  </p>

                  <div class="full-width mt" *ngIf="ids && ids.length > 0">
                    <button
                      class="btn-large pull-left"
                      (click)="goBackAll()"
                      mat-raised-button
                      color="accent"
                      style="background: none; border: 3px solid #fff; font-weight: 500"
                    >
                      <mat-icon class="fas fa-chevron-left smr"></mat-icon>
                      All plans
                    </button>
                  </div>
                </div>
              </div>
              <div class="half-width img-box-left">
                <div class="guid-imgbox guid-imgbox-left">
                  <img *ngIf="context!=='p4p'"
                    class="top-img guid-imgbox-left"
                    src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/campaign/price/Asset_4.png"
                  />

                  <img *ngIf="context=='p4p'"
                  class="top-img guid-imgbox-left" style="max-width:100%"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/declined-application-header-cus.png"
                />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="subscription-plan-view-card-fixed" *ngIf="displayExtra == true || displayHeaderPlan == true">
        <div *ngIf="isLoaded == true">
          <div
            class="feature-module-box text-center row main-padding-box sub-plan-header pull-right"
            *ngFor="let s of subscriptionPlans | OrderArrayObject: ['-Price', '-ID']; let index = index"
            [ngClass]="{ 'negative-margin-right': index === 0 }"
          >
            <!-- <div class="own-item text-center" *ngIf="s.isPlanOwn==true"> -->
            <div class="own-item text-center" *ngIf="s.isPlanOwn == true">
              <span class="subscribe-word">SUBSCRIBED</span>
              <mat-icon class="fas fa-crown"></mat-icon>
            </div>

            <mat-icon
              class="fas fa-medal recommended-icon"
              *ngIf="s['Recommended_Plan'] == 'Yes'"
              matTooltip="Recommended"
            ></mat-icon>

            <h4 [attr.style]="'color:' + s.color + ' !important' | safeStyle">
              {{ s.Web_Label }}
            </h4>
            <h3>{{ s.Price | customCurrency: '1.0-3' }}</h3>
            <h6>*per month</h6>

            <div class="full-width text-center flex action-container-plan">
              <button
                class="btn ml mt mb mr full-width"
                [attr.style]="
                  'background:linear-gradient(135deg, ' + s.colorSecond + ' 0%, ' + s.color + ' 100%) !important'
                    | safeStyle
                "
                [attr.style]="
                  'background:-moz-linear-gradient(135deg, ' + s.colorSecond + ' 0%, ' + s.color + ' 100%) !important'
                    | safeStyle
                "
                [attr.style]="
                  'background:-webkit-linear-gradient(135deg, ' +
                    s.colorSecond +
                    ' 0%, ' +
                    s.color +
                    ' 100%) !important' | safeStyle
                "
                (click)="viewSubPlanModal(s['ID'])"
                mat-raised-button
                color="accent"
              >
                <span *ngIf="s.isPlanOwn == true">View</span>
                <span *ngIf="s.isPlanOwn != true">Subscribe</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="full-width clearfix" id="subscription-plan-view-card-content" *ngIf="displayExtra == true"></div>

      <div
        class="full-width row mb container-card-sub-plan"
        id="overview-features"
        *ngIf="isLoaded == true"
        data-aos="fade-right"
        data-aos-delay="500"
        data-aos-easing="ease-in-sine"
        data-aos-once="true"
      >
        <mat-card
          class="rel clearfix full-width card-container card-container-border"
          *ngFor="let main of features | OrderArrayObject: 'Display_Order'"
          [attr.style]="'box-shadow:' + buildShadowBox(main.color) | safeStyle"
        >
          <div
            class="card-header primary-gradient-img card-container-border clearfix inModal rel"
            [attr.style]="
              'background:linear-gradient(135deg, ' + main.colorSecond + ' 0%, ' + main.color + ' 100%) !important'
                | safeStyle
            "
            [attr.style]="
              'background:-moz-linear-gradient(135deg, ' + main.colorSecond + ' 0%, ' + main.color + ' 100%) !important'
                | safeStyle
            "
            [attr.style]="
              'background:-webkit-linear-gradient(135deg, ' +
                main.colorSecond +
                ' 0%, ' +
                main.color +
                ' 100%) !important' | safeStyle
            "
          >
            <div class="row clearfix content">
              <app-ep-document-view-image
                class="square-img-feature pull-left module-sub-feature"
                [hideZoomButton]="false"
                [defaultPicture]="
                  'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/bell_icon.png)'
                "
                [link]="getMainFeautePicture(main.ID, main.LastModified)"
              >
              </app-ep-document-view-image>

              <div class="row titleArea clearfix">
                <h2 class="summary-header rm-m">
                  {{ main.MainLabel }}
                  <span class="subLabel"> {{ main.Description }}</span>
                </h2>
              </div>
            </div>
          </div>
          <div class="full-width" *ngIf="displayFeatureCover == true">
            <app-feature-main-label-cover-view [mainFeatureLabelID]="main.ID" [LastModified]="main.LastModified">
            </app-feature-main-label-cover-view>
          </div>

          <div class="full-width flex" *ngIf="main && main.subFeatures && main.subFeatures.length > 0" [@slideInOut]>
            <div class="full-width">
              <div class="container-sub-feature-module full-width">
                <div
                  class="full-width sub-feature-card-container row"
                  *ngFor="let m of main.subFeatures | OrderArrayObject: 'Display_Order'; let index = index"
                >
                  <div
                    class="new-feature"
                    *ngIf="m['Is_NewFeature'] === '1' && isNew(m['DateTimeCreated'])"
                    [attr.style]="
                      'background:linear-gradient(135deg, ' +
                        main.colorSecond +
                        ' 0%, ' +
                        main.color +
                        ' 100%) !important' | safeStyle
                    "
                    [attr.style]="
                      'background:-moz-linear-gradient(135deg, ' +
                        main.colorSecond +
                        ' 0%, ' +
                        main.color +
                        ' 100%) !important' | safeStyle
                    "
                    [attr.style]="
                      'background:-webkit-linear-gradient(135deg, ' +
                        main.colorSecond +
                        ' 0%, ' +
                        main.color +
                        ' 100%) !important' | safeStyle
                    "
                  >
                    <span> NEW</span>
                  </div>

                  <div
                    class="full-width flex row main-feature-parent"
                    [attr.style]="'border-top-color:' + buildBorderColor(main.color) + ' !important' | safeStyle"
                  >
                    <div
                      class="full-width flex row main-padding-box main-sub-padding-box"
                      [attr.style]="'border-right-color: ' + buildBorderColor(main.color) + ' !important' | safeStyle"
                    >
                      <div
                        class="sub-feature-card full-width sub-feature-label-card left-card-border-no-drag row"
                        [attr.style]="
                          'border-left-color: ' + buildBorderleft(main.colorSecond) + ' !important' | safeStyle
                        "
                      >
                        <div class="full-width">
                          <div class="full-width clearfix">
                            <h2
                              class="item-title rm-mt"
                              [attr.style]="
                                'color:' + getSubFeature(m['Color'], main.color) + ' !important' | safeStyle
                              "
                            >
                              <span class="pull-left"> {{ m.SubLabel }}</span>

                              <button
                                class="pull-left zoom-button"
                                (click)="openSubFeatreOut(m['ID'])"
                                mat-icon-button
                                matTooltip="Expend {{ m.SubLabel }}  view"
                              >
                                <mat-icon
                                  class="fas fa-angle-double-right"
                                  [attr.style]="
                                    'color:' + getSubFeature(m['Color'], main.color) + ' !important' | safeStyle
                                  "
                                >
                                </mat-icon>
                              </button>
                            </h2>

                            <read-more
                              class="full-width smt clearfix"
                              *ngIf="m['Description']"
                              [header]="m['Description']"
                              [design]="'mode2'"
                              [isCollapsed]="true"
                              [hideButtonForce]="true"
                              [canCollapse]="false"
                              [hideButton]="true"
                              [maxLength]="10000"
                            >
                            </read-more>

                            <div class="full-width">
                              <button
                                class="btn-small readMoreBtn2 pull-right"
                                (click)="ViewHtmlContent(m['ID'], m.SubLabel, main.MainLabel, m.LastModified)"
                                mat-button
                              >
                                More...
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="feature-module-box text-center main-padding-box side-box"
                      *ngFor="let s of m.subscriptionPlans | OrderArrayObject: ['price', 'ID']"
                      [attr.style]="
                        'border-right:1px solid ' +
                          buildBorderColor(main.color) +
                          ' !important;background-color:' +
                          backgroundOwn(main.color, s.isPlanOwn) +
                          ' !important' | safeStyle
                      "
                    >
                      <mat-icon
                        class="fas fa-check feature-module-icon-box"
                        *ngIf="s.isActive == true && s.isLimited != true"
                        [style.color]="s.color"
                        matTooltip="Included"
                      ></mat-icon>
                      <mat-icon
                        class="fas fa-times feature-module-icon-box"
                        *ngIf="s.isActive != true"
                        [style.color]="s.color"
                        matTooltip="Not Included"
                      >
                      </mat-icon>

                      <span
                        class="limited-word"
                        *ngIf="s.isActive == true && s.isLimited == true"
                        [style.color]="s.color"
                        matTooltip="Limited"
                      >Limited</span
                      >
                    </div>
                  </div>
                  <div class="full-width side-full-box">
                    <div
                      class="full-width flex-hard"
                      *ngFor="let s of m.subscriptionPlans | OrderArrayObject: ['price', 'ID']"
                      [attr.style]="'background-color:' + backgroundFullBox(s.color) + ' !important' | safeStyle"
                    >
                      <div class="full-width side-full-box-active-icon">
                        <mat-icon
                          class="fas fa-check pull-left ml"
                          *ngIf="s.isActive == true && s.isLimited != true"
                          [style.color]="s.color"
                          matTooltip="Included"
                        ></mat-icon>
                        <mat-icon
                          class="fas fa-times pull-left ml"
                          *ngIf="s.isActive != true"
                          [style.color]="s.color"
                          matTooltip="Not Included"
                        >
                        </mat-icon>

                        <span
                          class="limited-word"
                          *ngIf="s.isActive == true && s.isLimited == true"
                          [style.color]="s.color"
                          matTooltip="Limited"
                        >Limited</span
                        >
                      </div>

                      <div class="full-width side-full-box-title">
                        <h4 class="pull-left" [attr.style]="'color:' + s.color + ' !important' | safeStyle">
                          {{ s.Web_Label }}
                        </h4>

                        <mat-icon
                          class="fas fa-medal side-box-recommend-icon pull-left ml"
                          *ngIf="s['Recommended_Plan'] == 'Yes'"
                          matTooltip="Recommended"
                        >
                        </mat-icon>
                      </div>
                      <div class="full-width text-center side-full-box-price">
                        <h3 class="rm-m">{{ s.Price | customCurrency: '1.0-3' }}</h3>
                        <h6 class="rm-m">*per month</h6>
                      </div>
                      <div class="half-width side-full-box-button">
                        <button
                          class="btn pull-right mr"
                          [attr.style]="
                            'background:linear-gradient(135deg, ' +
                              s.colorSecond +
                              ' 0%, ' +
                              s.color +
                              ' 100%) !important' | safeStyle
                          "
                          [attr.style]="
                            'background:-moz-linear-gradient(135deg, ' +
                              s.colorSecond +
                              ' 0%, ' +
                              s.color +
                              ' 100%) !important' | safeStyle
                          "
                          [attr.style]="
                            'background:-webkit-linear-gradient(135deg, ' +
                              s.colorSecond +
                              ' 0%, ' +
                              s.color +
                              ' 100%) !important' | safeStyle
                          "
                          (click)="viewSubPlanModal(s['ID'])"
                          mat-raised-button
                          color="accent"
                        >
                          <span *ngIf="s.isPlanOwn == true">View</span>
                          <span *ngIf="s.isPlanOwn != true">Select</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="full-width main-feature-devider">
            <button
              class="btn pull-right mt mb smr main-findout-button"
              [attr.style]="'color:' + main.color + ' !important' | safeStyle"
              (click)="goMain(main.ID)"
              mat-raised-button
              color="accent"
            >
              View all about <strong>{{ main.MainLabel }} </strong>
              <mat-icon class="fas fa-chevron-right"></mat-icon>
            </button>
          </div>
        </mat-card>

        <div class="text-left full-width clearfix">
          <p class="small">
            *Plus, merchant service fees (MSF) on any loans financed and SMS charges. Subject to our Fair Use
            Policy.<br/>
            *Plus GST
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="full-width clearfix" id="subscription-plan-view-card-fixed-hidden" *ngIf="displayExtra == true">
  <app-card-footer
    *ngIf="displayExtra == true"
    [firstBackgroundWhite]="false"
    [items]="['features', 'plans-price', 'free', 'demo']"
    [featureType]="featureType"
  ></app-card-footer>
</div>
