import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { MarketingService } from '../shared/marketing.service';

@Component({
  selector: 'app-marketing-resend-modal',
  templateUrl: './marketing-resend-modal.component.html',
  styleUrls: ['./marketing-resend-modal.component.css'],
})
export class MarketingResendModalComponent implements OnInit {
  result = {};

  resent = false;

  @Input()
  campaignID;

  @Output()
  closeModal = new EventEmitter();

  @Output()
  updatedMarketing = new EventEmitter();

  openView = true;

  sendEmail = true;
  sendSms = false;

  merchants = [];
  merchantID;
  minDateDefault = new Date();
  minDate = new Date();
  util = new UtilsClass();

  selectedDate = this.util.getPastTime(new Date(), 1, 'day');

  isPromoterOrAdmin = false;

  resend = new EventEmitter();
  SessionType = 'merchant';
  marketing;
  newMarketing;

  constructor(
    private merchantService: MerchantService,
    private marketingService: MarketingService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && data.campaignID) {
      if (data.campaignID) {
        this.campaignID = data.campaignID;
      }
      if (data.openView != null) {
        this.openView = data.openView;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((SessionType) => {
      this.SessionType = SessionType;

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        this.marketingService.getMarketingDetails(this.campaignID, {}, this.SessionType).subscribe((res2) => {
          this.marketing = res2;

          if (this.marketing['Send.Email'] == '1') {
            this.sendEmail = true;
          } else {
            this.sendEmail = false;
          }

          if (this.marketing['Send.SMS'] == '1') {
            this.sendSms = true;
          } else {
            this.sendSms = false;
          }

          if (
            this.marketing['WhenToSend'] &&
            this.marketing['WhenToSend'] != '0000-00-00' &&
            this.marketing['WhenToSend'] != '0000-00-00 00:00'
          ) {
            this.selectedDate = moment(this.marketing['WhenToSend'], 'YYYY-MM-DD hh:mm').toDate();
          }

          if (this.isPromoterOrAdmin == true) {
            const payload = {
              fields: 'ID,TradingAs,CalculatedName,Status',
            };
            this.merchantService.getList(payload, this.isPromoterOrAdmin).subscribe((res) => {
              this.merchants = res;
              this.merchantID = this.marketing['Merchant_key'];
            });
          }
        });
      });
    });
  }

  viewMerchant() {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: this.merchantID,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  selectDate(e) {
    this.selectedDate = e;
  }

  submit() {
    let payload;
    if (this.isPromoterOrAdmin == true) {
      payload = {
        date: this.selectedDate,
        sendEmail: this.sendEmail,
        sendSms: this.sendSms,
        merchantID: this.merchantID,
      };
    } else {
      payload = {
        date: this.selectedDate,
        sendEmail: this.sendEmail,
        sendSms: this.sendSms,
      };
    }

    this.marketingService.resendCompaign(this.campaignID, payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        this.resent = true;
        this.newMarketing = res;
        this.updatedMarketing.emit(this.newMarketing);
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Campaign has been successfully activated');
        this.closeModal.emit(true);
      }
    });
  }

  close() {
    this.closeModal.emit(true);
  }
}
