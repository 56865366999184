import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TeethShape } from '../shared/teeth-shape';

@Component({
  selector: 'app-tooth-edit',
  templateUrl: './tooth-edit.component.html',
  styleUrls: ['./tooth-edit.component.css'],
})
export class ToothEditComponent implements OnInit {
  @Input()
  tooth = {
    hideNumber: false,
    toothNumber: 42,
    selected: false,
  };
  @Input() isAdult: boolean;
  toothShape: any;
  @Output() select = new EventEmitter();
  constructor() {}

  ngOnInit() {
    this.toothShape = TeethShape.getToothShape(this.tooth.toothNumber, this.isAdult);
  }

  selectTooth() {
    this.tooth.selected = !this.tooth.selected;
    this.select.emit({
      toothNumber: this.tooth.toothNumber,
      selected: this.tooth.selected,
    });
  }
}
