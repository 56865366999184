<div class="rel prospectModal">
  <mat-card class="existing-patient-summary" *ngIf="prospect" @onChangeAnimation>
    <div class="animate">
      <div class="card-header primary-gradient-img clearfix inModal">
        <div class="row clearfix">
          <!-- Modal dismiss button -->
          <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="close()">
            <mat-icon>clear</mat-icon>
          </button>
          <!-- merchant calculated name -->
          <div class="row titleArea clearfix">
            <app-ep-document-view-image
              class="prospect patientView userIconView pull-left"
              [defaultPicture]="
                'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
              "
              [link]="profileLink"
            >
            </app-ep-document-view-image>
            <h2 class="summary-header rm-m">
              {{ prospect.Salutation }} {{ prospect.CalculatedName }}
              <span class="subLabel">{{ "KEYWORD.patient" | translate | titlecase }}</span>
            </h2>
          </div>
        </div>
        <div class="action-button">
          <button
            class="actionMenuToggle"
            *ngIf="isCustomer != true"
            [matMenuTriggerFor]="mdProsMenu"
            mat-raised-button
          >
            Actions
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #mdProsMenu="matMenu" *ngIf="isCustomer != true">
            <button
              (click)="editDetails(prospect['ID'])"
              mat-menu-item
              color="accent"
            >
              <mat-icon>edit</mat-icon>
              <span>View/Edit Details</span>
            </button>

            <button
              class=""
              *ngIf="prospect['emails.Email']"
              (click)="contact()"
              mat-menu-item
              color="accent"
            >
              <mat-icon>contact_mail</mat-icon>
              <span>Contact {{ prospect.CalculatedName }}</span>
            </button>

            <!-- <button mat-menu-item *ngIf="prospect['ExistingPerson_key']"
              (click)="patientInvitation()">
              <mat-icon>portrait</mat-icon>
              <span>View {{"KEYWORD.patient" | translate | titlecase}} Invitations</span>
            </button> -->

            <button [matMenuTriggerFor]="patientInvLink" [hidden]="prospect['Status'] == 'Archived'" mat-menu-item>
              <mat-icon>email</mat-icon>
              <span>New Invitation</span>
            </button>

            <mat-menu #patientInvLink="matMenu">
              <div *ngIf="isModuleTreatmentPlanActive == true && financedProductGroup">
                <div *ngFor="let p of financedProductGroup">
                  <button
                    (click)="linkToInvitation(p)"
                    mat-menu-item
                    matTooltip="New {{ productUtil.getLabel(p['GroupCode']) }} Invitation"
                  >
                    New <strong>{{ productUtil.getLabel(p['GroupCode']) }}</strong> Invitation
                    <mat-icon class="position fas {{ productUtil.getProductGroupIcon(p['GroupCode']) }} "></mat-icon>
                  </button>
                  <mat-divider></mat-divider>
                </div>
              </div>
            </mat-menu>

            <button *ngIf="prospect['Status'] != 'Archived'" (click)="archivePatient()" mat-menu-item>
              <mat-icon>archive</mat-icon>
              <span> Archive {{ "KEYWORD.patient" | translate }}</span>
            </button>
            <!-- <button *ngIf="prospect['addresses.Calculated']" mat-menu-item color="accent" matTooltip="View on map"
              (click)="displayMap()">
              <mat-icon>place</mat-icon>
              <span>View On Map</span>
            </button> -->

            <button
              *ngIf="isPromoterOrAdmin == true && prospect['ID']"
              (click)="viewProspectLogs(prospect['ID'])"
              mat-menu-item
            >
              <mat-icon class="fa fa-list-alt"></mat-icon>
              <span>View Prospect Logs</span>
            </button>
          </mat-menu>
        </div>
      </div>

      <mat-dialog-content>
        <div class="row clearfix">
          <div class="icons">
            <mat-icon>person</mat-icon>
          </div>
          <div class="row-content">
            <div class="row clearfix" *ngIf="prospect['Gender.Label']">
              <p class="rm-m">{{ prospect['Gender.Label'] }}</p>
              <p class="small">Gender</p>
            </div>
            <p class="rm-m">
              {{ prospect['Salutation'] }} {{ prospect['FirstName'] }} {{ prospect['MiddleName'] }}
              {{ prospect['Name'] }}
            </p>
            <p class="small">Name</p>
          </div>
        </div>

        <!-- <mat-divider></mat-divider> -->

        <div class="row clearfix" *ngIf="prospect['DateOfBirth'] && prospect['DateOfBirth'] != '0000-00-00'">
          <div class="icons">
            <mat-icon>event</mat-icon>
          </div>
          <div class="row-content">
            <p class="rm-m">{{ prospect['DateOfBirth'] | customDate }}</p>
            <p class="small">Date of birth</p>
          </div>
        </div>

        <!-- <mat-divider *ngIf="prospect['DateOfBirth'] && prospect['DateOfBirth']!='0000-00-00'"></mat-divider> -->

        <div class="row clearfix" *ngIf="prospect['phones.Number'] || prospect['mobiles.Number']">
          <div class="icons">
            <mat-icon>phone</mat-icon>
          </div>
          <!-- Phone numbers -->
          <div class="row-content">
            <div class="row clearfix" *ngIf="prospect['mobiles.Number']">
              <p class="rm-m">{{ prospect['mobiles.Number'] | customPhone }}</p>
              <p class="small">Phone/Mobile</p>
            </div>
            <!--  -->
            <div class="row clearfix" *ngIf="prospect['phones.Number']">
              <p class="rm-m">
                {{ prospect['phones.Number'] | customPhone: 'landLine' }}
              </p>
              <p class="small" *ngIf="prospect['phones.Number']">Phone</p>
            </div>
          </div>
        </div>

        <!-- <mat-divider *ngIf='prospect["phones.Number"] || prospect["mobiles.Number"]'></mat-divider> -->
        <!-- Email Addresses -->
        <div class="row clearfix" *ngIf="prospect['emails.Email']">
          <div class="icons">
            <mat-icon>email</mat-icon>
          </div>
          <div class="row-content">
            <p class="rm-m">{{ prospect['emails.Email'] }}</p>
            <p class="small">Primary Email</p>
          </div>
        </div>

        <!-- <mat-divider *ngIf="prospect['emails.Email']"></mat-divider> -->
        <!-- Location Information -->
        <div class="row clearfix" *ngIf="prospect['addresses.Calculated']">
          <div class="icons">
            <mat-icon>location_on</mat-icon>
          </div>
          <div class="row-content">
            <p class="rm-m">{{ prospect['addresses.Calculated'] }}</p>
            <p class="small">Home Address</p>
            <!--  -->
          </div>
        </div>

        <!-- <mat-divider *ngIf='prospect["addresses.Calculated"] '></mat-divider> -->

        <!-- <app-map-view *ngIf="prospect['addresses.Calculated']  && displayMapFlag==true" [icon]="iconAddres" class="mt mb" [forceGettingAddress]='true' [canClose]="canClose"
          [unitNumber]="prospect['addresses.Unit']" [canClick]="false" [streetType]="prospect['addresses.Street Type']" [streetName]="prospect['addresses.Street Name']"
          [latitude]="prospect['Address.Latitude']" [longitude]="prospect['Address.Longitude']" [streetNumber]="prospect['addresses.Street Nr']"
          (notFoundEvent)="notFoundEvent($event)" [zoom]="17" [postCode]="prospect['addresses.Postcode']" [app-state]="prospect['addresses.State']" [suburb]="prospect['addresses.Suburb']"
          [moreInfo]=""></app-map-view> -->
        <!-- Social Media Accounts -->

        <p *ngIf="isReferral == true">
          <span class="status-box pull-right">Referral </span>
        </p>
      </mat-dialog-content>
    </div>
  </mat-card>
</div>
