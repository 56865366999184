import { of as observableOf } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import 'rxjs/add/operator/map';
import { catchError, map } from 'rxjs/operators';
import { AuthenticationService } from '../../authentication/shared/authentication.service';

@Injectable()
export class AuthAdminGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let r = route;
    while (r.firstChild) {
      r = r.firstChild;
    }

    const rParams = r.params;

    let url = null;

    if (state && state.url) {
      url = state.url;
    }
    return this.authService.getCurrentUser().pipe(
      map((user) => {
        if (user && user.success) {
          let role = null;

          if (user && user.data && user.data.Role) {
            role = user.data.Role;
          }

          if (role == 'admin' || role == 'promoter') {
            if (state && state.url && state.url.indexOf('/login') != -1) {
              this.router.navigate(['/admin/']);
            } else {
              return true;
            }
          } else {
            if (state && state.url && (state.url.indexOf('/login') != -1 || state.url.indexOf('/login-admin') != -1)) {
              return true;
            } else {
              if (url) {
                const __url = url.replace(/\//g, '$');

                this.router.navigate(['/login/' + __url]);
              } else {
                this.router.navigate(['/login']);
              }
            }
          }
        } else {
          if (state && state.url && (state.url.indexOf('/login') != -1 || state.url.indexOf('/login-admin') != -1)) {
            return true;
          } else {
            if (url) {
              const __url = url.replace(/\//g, '$');

              this.router.navigate(['/login/' + __url]);
            } else {
              this.router.navigate(['/login']);
            }
          }
        }
      }),
      catchError(() => {
        if (state.url.indexOf('/login') != -1 || state.url.indexOf('/login-admin') != -1) {
          return observableOf(true);
        } else {
          if (url) {
            const __url = url.replace(/\//g, '$');

            this.router.navigate(['/login/' + __url]);
            return observableOf(false);
          } else {
            this.router.navigate(['/login']);
            return observableOf(false);
          }
        }
      })
    );
  }
}
