import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

import { DomSanitizer } from '@angular/platform-browser';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-component-calendly',
  templateUrl: './calendly.component.html',
  styleUrls: ['./calendly.component.css'],
})
export class CalendlyComponent implements OnInit {
  @ViewChild('container', { static: true }) container: ElementRef;
  myExternalPageLink =
    'https://calendly.com/smile-right/30min?hide_event_type_details=0&background_color=f7f7f7&text_color=585858&primary_color=f42938';

  @Input()
  type = 'partner';
  myTemplat;
  iframeSrc;
  @Output()
  getLink = new EventEmitter();

  settings: any = Settings.global || {};

  constructor(
    private sanitizer: DomSanitizer,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.type) {
        this.type = data.type;
      }
    }
  }

  public ngOnInit() {
    let link;

    if (this.type == 'consumer') {
      link = this.settings['calendarGuestBooking'];
    } else if (this.type == 'investor') {
      link = this.settings['calendarInvestorBooking'];
    } else if (this.type == 'partner') {
      link = this.settings['calendarPartnerBooking'];
    } else if (this.type == 'specialist') {
      link = this.settings['calendarSpecialistBooking'];
    }

    if (link && link.indexOf('calendly.com') != -1) {
      this.myExternalPageLink = link;
      this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.myExternalPageLink);
    } else {
      window.open(link, '_blank');
      this.getLink.emit(link);
    }
  }
}
