import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { ActionLogViewComponent } from '../action-log-view/action-log-view.component';
import { EventLogViewComponent } from '../event-log-view/event-log-view.component';
import { ActionLogService } from '../shared/action-log.service';
import { ActionLogUtilClass } from '../shared/actionLogUtil';

@Component({
  selector: 'app-event-log-list',
  templateUrl: './event-log-list.component.html',
  styleUrls: ['./event-log-list.component.css'],
  animations: [
    trigger('fade', [transition(':enter', [style({ opacity: 0 }), animate('.6s ease')])]),
    trigger('stagger', [transition(':enter', [query(':enter', stagger('.3s', [animateChild()]), { optional: true })])]),
  ],
})
export class EventLogListComponent implements OnInit {
  @Input()
  customerID;

  @Input()
  dentistID;

  @Input()
  invitationID;

  @Input()
  merchantID;

  @Input()
  contractID;

  @Input()
  messageID;

  @Input()
  merchantProspectID;

  @Input()
  largeHeading = true;

  @Input()
  target;

  @Input()
  withoutAutoAction = false;

  @Input()
  onlyAutoAction = false;

  @Input()
  isExpanded = false;

  @Input()
  type = 'invitation';

  types = ['invitation', 'contract', 'message'];

  @Input()
  eventCode;

  @Output()
  closeModal = new EventEmitter();

  isModal = false;
  isPromoterOrAdmin = false;
  settings = Settings.global;
  invitationEventLog = [];
  contractEventLog = [];
  messageEventLog = [];
  invCombinedLog = [];
  ctcCombinedLog = [];

  targetID;
  itemID;
  sessionType = 'guest';
  emptyList = false;
  util = new UtilsClass();
  actionLogUtil = new ActionLogUtilClass();

  constructor(
    private authenticationService: AuthenticationService,
    private actionlogService: ActionLogService,
    private activeRoute: ActivatedRoute,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.customerID = data.customerID;
      this.merchantID = data.merchantID;
      this.contractID = data.contractID;
      this.invitationID = data.invitationID;
      this.messageID = data.messageID;
      this.type = data.type;
      this.eventCode = data.eventCode;
      this.targetID = data.targetID;
      this.itemID = data.itemID;
      this.target = data.target;

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['merchantProspectID']) {
        this.merchantProspectID = params['merchantProspectID'];

        this.target = 'merchantProspect';

        this.type = 'invitation';
        this.targetID = this.merchantProspectID;
      }

      if (params['dentistID']) {
        this.dentistID = params['dentistID'];

        this.target = 'dentist';

        this.type = 'invitation';
        this.targetID = this.dentistID;
      }

      if (params['customerID']) {
        this.customerID = params['customerID'];

        this.target = 'customer';

        this.type = 'invitation';
        this.targetID = this.customerID;
      }

      if (params['merchantID']) {
        this.merchantID = params['merchantID'];
        this.target = 'merchant';

        this.targetID = this.merchantID;
        this.type = 'invitation';
      }

      if (params['contractID']) {
        this.contractID = params['contractID'];

        this.type = 'invitation';

        this.itemID = this.contractID;
      }

      if (params['invitationID']) {
        this.invitationID = params['invitationID'];
        this.itemID = this.invitationID;

        this.type = 'contract';
      }

      this.authenticationService.getSessionType().subscribe((sessionType) => {
        this.sessionType = sessionType;

        this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
          if (res == true) {
            this.isPromoterOrAdmin = res;

            this.setup();
          }
        });
      });
    });
  }

  setup() {
    this.emptyList = false;
    if (this.type && this.type == 'invitation' && this.invitationID) {
      const payload = {
        invitationID: this.invitationID,
        merchantID: this.merchantID,
        eventCode: this.eventCode,
      };

      this.actionlogService.getInvitationEvents(payload, this.sessionType).subscribe((resInvitation) => {
        this.invitationEventLog = resInvitation;
        const payloadAction = {
          itemID: this.invitationID,
          targetID: this.targetID,
        };
        this.actionlogService.getActionLogList(payloadAction, this.sessionType).subscribe((resInvAction) => {
          const invActionLog = resInvAction;
          this.invCombinedLog = this.invitationEventLog.concat(invActionLog);

          if (this.invCombinedLog && this.invCombinedLog.length > 0) {
            this.emptyList = false;
          } else {
            this.emptyList = true;
          }

          this.invCombinedLog.sort((b, a) => {
            return (a.DateTimeCreated || a.DateTime_ISO).localeCompare(b.DateTimeCreated || b.DateTime_ISO);
          });
        });
      });
    } else if (this.type && this.type == 'contract' && this.contractID) {
      const payload = {
        contractID: this.contractID,
        merchantID: this.merchantID,
        customerID: this.customerID,
        eventCode: this.eventCode,
      };
      this.actionlogService.getContractEvents(payload, this.sessionType).subscribe((resContract) => {
        this.contractEventLog = resContract;
        const payloadAction = {
          itemID: this.contractID,
          targetID: this.targetID,
          withoutAutoAction: this.withoutAutoAction,
          onlyAutoAction: this.onlyAutoAction,
        };
        this.actionlogService.getActionLogList(payloadAction, this.sessionType).subscribe((resContractAction) => {
          const contractActionLog = resContractAction;
          this.ctcCombinedLog = this.contractEventLog.concat(contractActionLog);
          if (this.ctcCombinedLog && this.ctcCombinedLog.length > 0) {
            this.emptyList = false;
          } else {
            this.emptyList = true;
          }

          this.ctcCombinedLog.sort((b, a) => {
            return (a.DateTimeCreated || a.DateTime_ISO).localeCompare(b.DateTimeCreated || b.DateTime_ISO);
          });
        });
      });
    } else if (this.type && this.type == 'message' && this.messageID) {
      const payload = {
        messageID: this.messageID,
        merchantID: this.merchantID,
        eventCode: this.eventCode,
      };
      this.actionlogService.getMessageEvents(payload, this.sessionType).subscribe((resMessage) => {
        this.messageEventLog = resMessage;

        if (this.messageEventLog && this.messageEventLog.length > 0) {
          this.emptyList = false;
        } else {
          this.emptyList = true;
        }
      });
    } else if (this.targetID) {
      // debugger;
      const payloadAction = {
        targetID: this.targetID,
      };
      this.actionlogService.getActionLogList(payloadAction, this.sessionType).subscribe((resContractAction) => {
        const contractActionLog = resContractAction;
        this.ctcCombinedLog = this.contractEventLog.concat(contractActionLog);
        if (this.ctcCombinedLog && this.ctcCombinedLog.length > 0) {
          this.emptyList = false;
        } else {
          this.emptyList = true;
        }

        this.ctcCombinedLog.sort((b, a) => {
          return (a.DateTimeCreated || a.DateTime_ISO).localeCompare(b.DateTimeCreated || b.DateTime_ISO);
        });
      });
    } else {
      this.emptyList = true;
    }
  }

  closeEvent() {
    this.closeModal.emit(true);
  }

  viewEventLog(id, eventLabel) {
    if (eventLabel != null) {
      const ref = RootAppComponent.dialog.open(EventLogViewComponent, {
        data: {
          eventID: id,
          type: this.type,
        },
        width: '650px',
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    } else {
      const ref = RootAppComponent.dialog.open(ActionLogViewComponent, {
        data: id,
        width: '650px',
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  convertDateForSafari(date) {
    if (date != null) {
      const formateDate = new Date(date.replace(/-/g, '/'));
      return formateDate;
    }
  }

  refresh() {
    this.ngOnInit();
  }
}
