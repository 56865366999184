<ipv-title-modal
  [hideDefaultRejectButton]="true"
  [title]="'Smile Makeover for ' + calculatedName"
  (acceptButtonClicked)="close()"
  acceptButtonText="Close"
  classIcon="fa fa-grin-beam"
  icon=""
>
  <div content>
    <p>Click to view images</p>

    <div class="gallery-container">
      <!--      <app-ep-document-list [documents]="galleryImages"></app-ep-document-list>-->
      <app-gallery [galleryImages]="galleryImages" [galleryOptions]="galleryOptions"></app-gallery>
    </div>

    <h2 class="primary-color" style="text-align: left"><b> Wishlist </b></h2>

    <mat-list>
      <mat-list-item
        *ngFor="let wishlistItem of wishlist"
        style="text-align: left; margin-bottom: 20px; max-width: 540px"
      >
        <h3 class="primary-color" mat-line style="margin-bottom: 8px">{{ wishlistItem.Label | titlecase }}</h3>
        <p mat-line style="margin-bottom: 5px; white-space: normal">{{ wishlistItem.Description }}</p>
        <mat-divider></mat-divider>
      </mat-list-item>
    </mat-list>

    <h2 class="primary-color" *ngIf="smileMakeover.Comment" style="text-align: left"><b> Comment </b></h2>

    <p style="text-align: left">
      {{ smileMakeover.Comment }}
    </p>
  </div>
</ipv-title-modal>
