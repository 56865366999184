import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { ArchwizardModule } from 'angular-archwizard';
import { NgDatepickerModule } from 'ng2-datepicker';
import { MomentModule } from 'ngx-moment';
import { NgPipesModule } from 'ngx-pipes';
import { NgxPrintModule } from 'ngx-print';
import { HelperModule } from '../../core/helper/helper.module';
import { SharedModule } from '../../shared/shared.module';
import { ActionLogModule } from '../action-log/action-log.module';
import { ActivityLogModule } from '../activity-log/activity-log.module';
import { ContractModule } from '../contract/contract.module';
import { CustomerModule } from '../customer/customer.module';
import { DefaultMessageModule } from '../default-message/default-message.module';
import { DentistModule } from '../dentist/dentist.module';
import { InvitationTemplateModule } from '../invitation-template/invitationTemplate.module';
import { MerchantModule } from '../merchant/merchant.module';
import { MessageModule } from '../message/message.module';
import { OperatorModule } from '../operator/operator.module';
import { PatientModule } from '../patient/patient.module';
import { PerformanceModule } from '../performance/performance.module';
import { ProductModule } from '../product/product.module';
import { QuotationModule } from '../quotation/quotation.module';
import { TagsModule } from '../tags/tags.module';
import { TreatmentPlanModule } from '../treatment-plan/treatment-plan.module';
import { TreatmentModule } from '../treatment/treatment.module';
import { BeforeStartingLendingComponent } from './before-starting-lending/before-starting-lending.component';
import { BeforeStartingComponent } from './before-starting/before-starting.component';
import { ColdApplicationComponent } from './cold-application/cold-application.component';
import { DiscountEditComponent } from './discount-edit/discount-edit.component';
import { InvitationCloneInfoComponent } from './invitation-clone-info/invitation-clone-info.component';
import { InvitationCommunicationCreate } from './invitation-communication-create/invitation-communication-create.component';
import { InvitationCommunicationListGlobalComponent } from './invitation-communication-list-global/invitation-communication-list-global.component';
import { InvitationCommunicationListOverViewComponent } from './invitation-communication-list-overview/invitation-communication-list-overview.component';
import { InvitationCommunicationListComponent } from './invitation-communication-list/invitation-communication-list.component';
import { InvitationCommunicationViewComponent } from './invitation-communication-view/invitation-communication-view.component';
import { InvitationConversionFunnelComponent } from './invitation-conversion-funnel/invitation-conversion-funnel.component';
import { InvitationCreateComponent } from './invitation-create/invitation-create.component';
import { InvitationCustomerProspectEditComponent } from './invitation-customer-prospect-edit/invitation-customer-prospect-edit.component';
import { InvitationDetailsCardComponent } from './invitation-details-card/invitation-details-card.component';
import { InvitationEmergencyComponent } from './invitation-emergency/invitation-emergency.component';
import { InvitationExistingPatientListComponent } from './invitation-existing-patient-list/invitation-existing-patient-list.component';
import { InvitationIntroModalComponent } from './invitation-intro-modal/invitation-intro-modal.component';
import { InvitationListArchivesComponent } from './invitation-list-archives/invitation-list-archives.component';
import { InvitationListDraftOverViewComponent } from './invitation-list-draft-overview/invitation-list-draft-overview.component';
import { InvitationListDraftComponent } from './invitation-list-draft/invitation-list-draft.component';
import { InvitationListGlobalComponent } from './invitation-list-global/invitation-list-global.component';
import { InvitationListInformedConsentComponent } from './invitation-list-informed-consent/invitation-list-informed-consent.component';
import { InvitationListOverViewComponent } from './invitation-list-overview/invitation-list-overview.component';
import { InvitationListViewComponent } from './invitation-list-view/invitation-list-view.component';
import { InvitationListComponent } from './invitation-list/invitation-list.component';
import { InvitationLookupComponent } from './invitation-lookup/invitation-lookup.component';
import { InvitationModificationComponent } from './invitation-modification/invitation-modification.component';
import { InvitationNewButtonComponent } from './invitation-new-button/invitation-new-button.component';
import { InvitationOverviewComponent } from './invitation-overview/invitation-overview.component';
import { InvitationPerformanceComponent } from './invitation-performance/invitation-performance.component';
import { InvitationProgressViewComponent } from './invitation-progress-view/invitation-progress-view.component';
import { InvitationRedirectionComponent } from './invitation-redirection/invitation-redirection.component';
import { InvitationResendComponent } from './invitation-resend/invitation-resend.component';
import { InvitationSummaryComponent } from './invitation-summary/invitation-summary.component';
import { InvitationViewModalComponent } from './invitation-view-modal/invitation-view-modal.component';
import { InvitationViewSmsComponent } from './invitation-view-sms/invitation-view-sms.component';
import { InvitationViewComponent } from './invitation-view/invitation-view.component';
import { LendingBeforeStartingComponent } from './lending-before-starting/lending-before-starting.component';
import { LendingInvitationCreateComponent } from './lending-invitation-create/lending-invitation-create.component';
import { InvitationRoutingModule } from './shared/invitation-routing.module';
import { InvitationService } from './shared/services/invitation.service';
import { WakandaFilesComponent } from './wakanda-files-view/wakanda-files-view.component';

import { NgxPaginationModule } from 'ngx-pagination';
import { EpDocumentWakandaCreateComponent } from './ep-document-wakanda-create/ep-document-wakanda-create.component';
import { InvitationLookupSecondComponent } from './invitation-lookup-second/invitation-lookup-second.component';
import { QuickInvitationComponent } from './quick-invitation/quick-invitation.component';
import { CardModule } from '../card/card.module';

@NgModule({
  imports: [
    NgPipesModule,
    MerchantModule,
    CommonModule,
    FormsModule,
    CdkTableModule,
    OperatorModule,
    MomentModule,
    CardModule,

    PerformanceModule,
    NgDatepickerModule,
    ReactiveFormsModule,
    InvitationRoutingModule,
    CdkTableModule,
    ArchwizardModule,
    SharedModule,
    MatSortModule,
    CustomerModule,
    TreatmentPlanModule,
    TreatmentModule,
    ProductModule,
    DentistModule,
    ContractModule,
    ActionLogModule,
    HelperModule,
    DefaultMessageModule,
    MessageModule,
    ActivityLogModule,
    QuotationModule,
    InvitationTemplateModule,
    NgxPrintModule,
    PatientModule,
    TagsModule,
    NgxPaginationModule,
  ],
  declarations: [
    InvitationNewButtonComponent,
    InvitationListDraftOverViewComponent,
    InvitationListDraftComponent,
    BeforeStartingLendingComponent,
    LendingInvitationCreateComponent,
    LendingBeforeStartingComponent,
    InvitationExistingPatientListComponent,
    InvitationResendComponent,
    InvitationViewComponent,
    InvitationListComponent,
    InvitationListGlobalComponent,
    InvitationCreateComponent,
    InvitationModificationComponent,
    InvitationOverviewComponent,
    InvitationPerformanceComponent,
    InvitationSummaryComponent,
    InvitationListViewComponent,
    InvitationListArchivesComponent,
    BeforeStartingComponent,
    InvitationRedirectionComponent,
    ColdApplicationComponent,
    InvitationViewModalComponent,
    InvitationProgressViewComponent,
    InvitationIntroModalComponent,
    InvitationViewSmsComponent,
    InvitationListInformedConsentComponent,
    InvitationListOverViewComponent,
    InvitationEmergencyComponent,
    DiscountEditComponent,
    InvitationDetailsCardComponent,
    InvitationCloneInfoComponent,
    InvitationConversionFunnelComponent,
    InvitationLookupComponent,
    InvitationCustomerProspectEditComponent,
    WakandaFilesComponent,
    InvitationCommunicationCreate,
    InvitationCommunicationListOverViewComponent,
    InvitationCommunicationListGlobalComponent,
    InvitationCommunicationListComponent,
    InvitationCommunicationViewComponent,
    EpDocumentWakandaCreateComponent,
    QuickInvitationComponent,
    InvitationLookupSecondComponent,
  ],
  exports: [
    InvitationProgressViewComponent,
    InvitationNewButtonComponent,
    InvitationListDraftOverViewComponent,
    InvitationListDraftComponent,
    BeforeStartingLendingComponent,
    LendingInvitationCreateComponent,
    LendingBeforeStartingComponent,
    InvitationExistingPatientListComponent,
    InvitationResendComponent,
    InvitationViewComponent,
    InvitationListComponent,
    InvitationListGlobalComponent,
    InvitationListViewComponent,
    InvitationCreateComponent,
    InvitationModificationComponent,
    InvitationOverviewComponent,
    InvitationPerformanceComponent,
    InvitationSummaryComponent,
    InvitationListArchivesComponent,
    BeforeStartingComponent,
    InvitationRedirectionComponent,
    ColdApplicationComponent,
    InvitationViewModalComponent,
    InvitationIntroModalComponent,
    InvitationViewSmsComponent,
    InvitationListInformedConsentComponent,
    InvitationListOverViewComponent,
    InvitationEmergencyComponent,
    DiscountEditComponent,
    InvitationDetailsCardComponent,
    InvitationCloneInfoComponent,
    InvitationConversionFunnelComponent,
    InvitationLookupComponent,
    InvitationCustomerProspectEditComponent,
    WakandaFilesComponent,
    InvitationCommunicationCreate,
    InvitationCommunicationListOverViewComponent,
    InvitationCommunicationListGlobalComponent,
    InvitationCommunicationListComponent,
    InvitationCommunicationViewComponent,
    EpDocumentWakandaCreateComponent,
    QuickInvitationComponent,
    InvitationLookupSecondComponent,
  ],
  entryComponents: [],
  providers: [InvitationService],
})
export class InvitationModule {}
