import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';

import { DatepickerOptions } from 'ng2-datepicker';

import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UserDataService } from '../../../shared/services/user-data.service';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

@Component({
  selector: 'app-patient-personal-details-create',
  templateUrl: './patient-personal-details-create.component.html',
  styleUrls: ['./patient-personal-details-create.component.css'],
})
export class PatientPersonalDetailsCreateComponent implements OnInit {
  displayRelationship;
  utils = new UtilsClass();
  @Input() invitationID = '';
  @Output() validate = new EventEmitter();
  patient = {
    gender: '',
    title: '',
    firstname: '',
    middlename: '',
    surename: '',
    dateOfbirth: null,
    relationStatus: '',
    numOfDependant: '',
  };
  relationshipStatus = [];
  sendDate = new Date();
  genders = [];
  titles = [];
  numbers = Array.from(Array(21).keys());
  options: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2030,
    displayFormat: 'MMM D[,] YYYY',
    barTitleFormat: 'MMMM YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    minDate: new Date(Date.now()), // Minimal selectable date
    barTitleIfEmpty: 'Click to select a date',
  };
  sessionType;

  constructor(
    private lookupService: LookupService,
    private userDataService: UserDataService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((r) => {
      this.sessionType = r;

      this.lookupService.getLookup('CodeLookup', 'Gender').subscribe((data) => {
        this.genders = data;
      });
      this.lookupService.getLookup('CodeLookup', 'RelationshipStatus').subscribe((data) => {
        this.relationshipStatus = data;
      });
      this.lookupService.getLookup('SimpleLookup', 'Salutation').subscribe((data) => {
        this.titles = data;
      });
    });
  }

  onDateChanged(event, formValid) {
    const birthDate = this.utils.calculateAge(event.jsdate);

    if (birthDate >= 18) {
      this.validate.emit(formValid);
    } else {
      NotifyAppComponent.displayToast('error', 'Invalid birthdate value', 'The age should be at least 18 years');
      this.validate.emit(false);
    }
  }

  onBlurMethod(isValid) {
    this.validate.emit(isValid);
  }

  datechange(date, attribute) {
    UtilsClass.savingData();
    let saveDate = {
      attribute,
      value: date,
    };
    this.userDataService.setUserData(this.invitationID, saveDate, this.sessionType).subscribe((res) => {});
  }
}
