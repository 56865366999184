import * as tinycolor from 'assets/scripts/tinycolor.js';

export class ColorPalette {
  color;

  constructor(color) {
    this.color = color;
  }

  getColorObject(value, name) {
    const c = tinycolor(value);

    return {
      name,
      hex: c.toHexString(),
      darkContrast: c.isLight(),
    };
  }

  multiply(rgb1, rgb2) {
    rgb1.b = Math.floor((rgb1.b * rgb2.b) / 255);
    rgb1.g = Math.floor((rgb1.g * rgb2.g) / 255);
    rgb1.r = Math.floor((rgb1.r * rgb2.r) / 255);
    return tinycolor('rgb ' + rgb1.r + ' ' + rgb1.g + ' ' + rgb1.b);
  }

  getPalette() {
    const hex = this.color;
    const baseLight = tinycolor('#ffffff');
    const baseDark = this.multiply(tinycolor(hex).toRgb(), tinycolor(hex).toRgb());
    const baseTriad = tinycolor(hex).tetrad();
    return [
      this.getColorObject(tinycolor.mix(baseLight, hex, 12), '50'),
      this.getColorObject(tinycolor.mix(baseLight, hex, 30), '100'),
      this.getColorObject(tinycolor.mix(baseLight, hex, 50), '200'),
      this.getColorObject(tinycolor.mix(baseLight, hex, 70), '300'),
      this.getColorObject(tinycolor.mix(baseLight, hex, 85), '400'),
      this.getColorObject(tinycolor.mix(baseLight, hex, 100), '500'),
      this.getColorObject(tinycolor.mix(baseDark, hex, 87), '600'),
      this.getColorObject(tinycolor.mix(baseDark, hex, 70), '700'),
      this.getColorObject(tinycolor.mix(baseDark, hex, 54), '800'),
      this.getColorObject(tinycolor.mix(baseDark, hex, 25), '900'),
      this.getColorObject(tinycolor.mix(baseDark, baseTriad[4], 15).saturate(80).lighten(65), 'A100'),
      this.getColorObject(tinycolor.mix(baseDark, baseTriad[4], 15).saturate(80).lighten(55), 'A200'),
      this.getColorObject(tinycolor.mix(baseDark, baseTriad[4], 15).saturate(100).lighten(45), 'A400'),
      this.getColorObject(tinycolor.mix(baseDark, baseTriad[4], 15).saturate(100).lighten(40), 'A700'),
    ];
  }
}
