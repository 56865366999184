<div class="full-width" [ngClass]="isModal == true ? 'modalClass' : ''">
  <form #userContactForm="ngForm">
    <button
      class="btn-close btn-clear mat-button pull-right"
      *ngIf="isModal == true && isCard != true"
      (click)="closeEvent()"
    >
      <mat-icon>clear</mat-icon>
    </button>

    <div
      class="card-header primary-gradient-img clearfix confirmModal"
      *ngIf="isCard == true && cardTitle"
      [attr.style]="'background:' + landingPage.theme.background + ' !important' | safeStyle"
    >
      <button class="btn-close btn-clear mat-button smt white custom-close" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="full-width flex main-title-container">
        <div class="row full-width title-container clearfix">
          <div class="title-container-sub">
            <h2 class="text-left title-header rm-m">
              {{ cardTitle }}

              <span class="subLabel" *ngIf="cardDescription">
                {{ cardDescription }}
              </span>
            </h2>
          </div>
        </div>
      </div>
    </div>

    <mat-dialog-content [ngClass]="{ 'no-modal-height': isModal != true }">
      <div class="full-width flex">
        <div class="full-width" *ngIf="isView == true">
          <h3
            class="sr-title text-left full-width rm-mt"
            *ngIf="inputTitle"
            [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
          >
            {{ inputTitle }}
          </h3>

          <div class="full-width" *ngIf="isMessageConverted != true">
            <p
              class="view-message-container"
              *ngIf="displayMore == false"
              [innerHTML]="util.URLify(message || '...', false) | safeHtml"
            ></p>

            <read-more
              class="text-left full-width read-more-module10 smb"
              *ngIf="displayMore == true"
              [animated]="animated"
              [text]="util.URLify(message || '...', false)"
              [design]="'noPropagation'"
              [hideButton]="false"
              [isCollapsed]="isCollapsed"
              [maxLength]="maxLength"
            >
            </read-more>
          </div>
          <div class="full-width" *ngIf="isMessageConverted == true">
            <p
              class="view-message-container"
              *ngIf="displayMore == false"
              [innerHTML]="util.URLify(convertMessage(true) || '...', false) | safeHtml"
            ></p>

            <read-more
              class="text-left full-width read-more-module10 read-more-message-input smb"
              *ngIf="displayMore == true"
              [animated]="animated"
              [text]="util.URLify(convertMessage(true) || '...', false)"
              [design]="'noPropagation'"
              [hideButton]="false"
              [isCollapsed]="isCollapsed"
              [maxLength]="maxLength"
            >
            </read-more>
          </div>

          <div class="full-width action-container mb">
            <button
              class="smr pull-right preview-message-desktop"
              *ngIf="isDesktopPreview == true"
              (click)="$event.stopPropagation(); previewWeb()"
              mat-icon-button
              matTooltip="Preview"
              color="primary"
            >
              <mat-icon class="fas fa-desktop primary-color"></mat-icon>
            </button>

            <button
              class="smr pull-right preview-message-mobile"
              *ngIf="isSMSPreview == true"
              (click)="$event.stopPropagation(); previewSMS()"
              mat-icon-button
              matTooltip="Preview SMS"
              color="primary"
            >
              <mat-icon class="fas fa-mobile-alt primary-color"></mat-icon>
            </button>
          </div>
        </div>
        <div class="full-width input-container" *ngIf="isView != true">
          <h3
            class="sr-title text-left full-width rm-mt"
            *ngIf="inputTitle"
            [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
          >
            {{ inputTitle }}
          </h3>

          <div
            class="full-width smb clearfix"
            *ngIf="isTiny == true"
            [ngClass]="isExpend == true ? 'max-height-activity-log-input' : 'min-height-activity-log-input'"
          >
            <editor
              class="full-width html-editor"
              id="main-sms-tiny-{{random}}"
              [(ngModel)]="message"
              [init]="TinyMCEOptions"
              (ngModelChange)="sendResult()"
              apiKey="{{ apiTinyKey }}"
              name="templatePost-{{random}}"
            >
            </editor>

            <button
              class="clear-button-tiny pull-left"
              (click)="clearMessage()"
              mat-icon-button
              matTooltip="Clear Message"
            >
              <mat-icon class="fas fa-trash"></mat-icon>
            </button>
          </div>
          <div class="full-width clearfix" *ngIf="isTiny != true">
            <mat-form-field class="full-width animate" appearance="outline">
              <textarea
                class="smb mat-block"
                id="main-sms-container-{{random}}"
                [(ngModel)]="message"
                [ngClass]="isExpend == true ? 'main-sms-container-send-expend' : 'main-sms-container-send'"
                (ngModelChange)="sendResult()"
                matInput
                placeholder="{{ label }}"
                name="templatefacebookPost-{{random}}"
                required
              ></textarea>
            </mat-form-field>

            <button class="clear-button pull-left" (click)="clearMessage()" mat-icon-button matTooltip="Clear Message">
              <mat-icon class="fas fa-trash"></mat-icon>
            </button>

            <p
              class="small text-segment full-width text-right"
              *ngIf="
                textLength &&
                textLength > 0 &&
                (!convertedMessage || (convertedMessage && convertedMessage.length <= 0))
              "
            >
              0 Segments <strong>0</strong>/{{ textLength }} Characters
            </p>
            <p class="small text-segment full-width text-right" *ngIf="convertedMessage && textLength > 0">
              {{ calculMathFloor(convertedMessage) }} Segments
              <strong>{{ convertedMessage.length % (textLength + 1) }}</strong
              >/{{ textLength }}
              Characters
            </p>
          </div>

          <div class="full-width mb" style="margin-top: -10px">
            <button
              class="icon-cust btn pull-right"
              *ngIf="isModal != true"
              (click)="getExpendEvent()"
              mat-icon-button
              matTooltip="Expand Screen"
            >
              <mat-icon class="fas fa-expand"></mat-icon>
            </button>

            <button
              class="smr icon-cust btn pull-right"
              *ngIf="isDesktopPreview == true || (isSMSPreview == true && isTiny == true)"
              (click)="previewWeb()"
              mat-icon-button
              matTooltip="Preview on Desktop"
            >
              <mat-icon class="fas fa-desktop"></mat-icon>
            </button>

            <button
              class="smr icon-cust btn pull-right"
              *ngIf="isSMSPreview == true && isTiny != true"
              (click)="previewSMS()"
              mat-icon-button
              matTooltip="Preview on Mobile"
            >
              <mat-icon class="fas fa-mobile-alt"></mat-icon>
            </button>

            <button
              class="icon-offer pull-left"
              *ngIf="displayInstantOffer == true && isModuleSMSOfferActive == true"
              (click)="displayInstantOfferEvent()"
              mat-mini-fab
              color="accent"
              matTooltip="New Instant Finance Offer "
            >
              <mat-icon class="fas fa-dollar-sign"></mat-icon>
            </button>

            <button
              class="smr icon-cust pull-left"
              *ngIf="displayEmoji == true || isTiny == true"
              [matMenuTriggerFor]="smsMenu"
              mat-icon-button
            >
              <mat-icon class="far fa-laugh"></mat-icon>
            </button>

            <button
              class="smr pull-left icon-cust btn"
              *ngIf="isModuleCustomMessagesActive == true && displayMessageTemplate == true"
              (click)="messageTemplateLookup()"
              mat-icon-button
              matTooltip="Message  Templates"
            >
              <mat-icon class="fas fa-envelope-open-text"></mat-icon>
            </button>

            <button
              class="smr pull-left icon-cust btn"
              *ngIf="cardRules && cardRules.length > 0 && displayPatient == true && cardID"
              [matMenuTriggerFor]="smsMenuPatient"
              mat-icon-button
              [matTooltip]="(keyWord | translate | titlecase) + ' Customization'"
            >
              <mat-icon class="fas fa-user"></mat-icon>
            </button>

            <button
              class="smr pull-left icon-cust btn"
              *ngIf="contactRules && contactRules.length > 0 && displayContact == true"
              [matMenuTriggerFor]="smsMenuSender"
              mat-icon-button
              matTooltip="Sender Customization"
            >
              <mat-icon class="fas fa-address-card"></mat-icon>
            </button>

            <button
              class="smr pull-left icon-cust btn"
              *ngIf="practiceRules && practiceRules.length > 0 && displayMerchant == true"
              [matMenuTriggerFor]="smsMenuPractice"
              mat-icon-button
              [matTooltip]="('KEYWORD.practice' | translate | titlecase) + ' Customization'"
            >
              <mat-icon class="fas fa-building"></mat-icon>
            </button>

            <button
              class="smr pull-left icon-cust btn"
              *ngIf="
                isPromoterOrAdmin === true &&
                promoterRules &&
                promoterRules.length > 0 &&
                displayPromoter == true &&
                promoterID
              "
              [matMenuTriggerFor]="smsMenuPromoter"
              mat-icon-button
              matTooltip="Promoter Customization"
            >
              <mat-icon class="fas fa-user-shield"></mat-icon>
            </button>

            <button
              class="smr pull-left icon-cust btn"
              *ngIf="displayMarketing == true"
              (click)="campaignLook()"
              mat-icon-button
              matTooltip="Campaign Lookup"
            >
              <mat-icon class="fas fa-bullhorn"></mat-icon>
            </button>

            <button
              class="smr pull-left icon-cust btn"
              *ngIf="displayDentalOffers == true"
              (click)="tipsAndOffers()"
              mat-icon-button
              [matTooltip]="('KEYWORD.dental' | translate | titlecase) + ' Tips'"
            >
              <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
            </button>

            <!--

          <button mat-icon-button class="smr  pull-left icon-cust btn"
            *ngIf="isModuleDocumentLinkActive==true && displayDocument" matTooltip="Insert Document From Gallery"
            (click)="insertDocument()">
            <mat-icon class="fas fa-image    "></mat-icon>
          </button> -->

            <button
              class="smr pull-left icon-cust btn"
              (click)="insertLink()"
              mat-icon-button
              matTooltip="Insert a Link"
            >
              <mat-icon class="fas fa-link"></mat-icon>
            </button>

            <button
              class="smr pull-left icon-cust btn"
              (click)="insertFile()"
              mat-icon-button
              matTooltip="Insert Document as a Link"
            >
              <mat-icon class="fas fa-upload"></mat-icon>
            </button>

            <button
              class="smr pull-left icon-cust btn"
              *ngIf="isModuleDocumentLinkActive == true && displayDocument"
              (click)="addDocument()"
              mat-icon-button
              matTooltip="New Landing Page"
            >
              <mat-icon class="fas fa-folder-plus"></mat-icon>
            </button>

            <button
              class="smr pull-left icon-cust btn"
              *ngIf="isModuleDocumentLinkActive == true && displayDocument"
              (click)="documentLinkLookup()"
              mat-icon-button
              matTooltip="Landing Page Lookup"
            >
              <mat-icon class="fas fa-folder-open"></mat-icon>
            </button>

            <button
              class="smr pull-left icon-cust btn"
              *ngIf="displaySaveAsTemplate == true && !messageTemplateID && isTiny != true"
              [disabled]="!message"
              (click)="saveAsTemplateEvent()"
              mat-icon-button
              matTooltip="Save this message As Template"
            >
              <mat-icon class="fas fa-save primary-color"></mat-icon>
            </button>

            <button
              class="smr pull-left icon-cust btn"
              *ngIf="displaySaveAsTemplate == true && messageTemplateID && isTiny != true"
              [matMenuTriggerFor]="menuSaveTemplate"
              [disabled]="!message"
              mat-icon-button
              matTooltip="Save  this message As Template"
            >
              <mat-icon class="fas fa-save primary-color"></mat-icon>
            </button>

            <mat-menu #menuSaveTemplate="matMenu">
              <button (click)="saveAsTemplateEvent()" mat-menu-item>
                <mat-icon class="fas fa-save"></mat-icon>
                <span>Create new Template</span>
              </button>
              <button (click)="saveAsTemplateEvent(messageTemplateID)" mat-menu-item>
                <mat-icon class="fas fa-edit"></mat-icon>
                <span>Edit template</span>
              </button>
            </mat-menu>

            <mat-menu class="emoji-container" #smsMenu="matMenu" [class]="'emojiContainer'" yPosition="above">
              <emoji-mart [showPreview]="true" [isNative]="false" (emojiClick)="selectEmoji($event)"></emoji-mart>
            </mat-menu>

            <mat-menu
              class="emoji-container"
              #smsMenuPatient="matMenu"
              [class]="'customizationPanelContain'"
              yPosition="above"
            >
              <div class="main-customization">
                <h3 class="sr-title inner-title smt smb text-center">{{keyWord | translate | titlecase}}
                  Details</h3>
                <div *ngFor="let group of groups; let index = index">
                  <div
                    class="mb inner"
                    *ngIf="(cardRules | FilterArrayMultipleOrContain: 'group':group | lengthOfArray) > 0"
                  >
                    <button
                      class="sml smr smt pull-left btn main-customization-btn"
                      *ngFor="
                        let rule of cardRules
                          | FilterArrayMultipleOrContain: 'group':group
                          | OrderArrayObject: ['label']
                      "
                      (click)="insertTextAtCursorCode(rule.code)"
                      mat-raised-button
                    >
                      <mat-icon class="{{ rule.icon }} smr" *ngIf="rule.icon"></mat-icon>
                      {{ rule.label }}
                    </button>
                  </div>
                  <hr
                    class="full-width"
                    *ngIf="(cardRules | FilterArrayMultipleOrContain: 'group':group | lengthOfArray) > 0"
                  />
                </div>
                <div *ngIf="isCustomTreatmentAmount == true">
                  <div class="mb inner">
                    <button
                      class="sml smr smt pull-left btn main-customization-btn"
                      (click)="insertTextAtCursorCode('AmountToFinance')"
                      mat-raised-button
                      style="width: 200px"
                    >
                      <mat-icon class="fas fa-dollar-sign smr" style="height: 14px"></mat-icon>
                      <span *ngIf="settings && settings['context'] == 'breeze' "
                      >Amount For Finance</span
                      >
                      <span *ngIf="settings && settings['context'] != 'breeze' "
                      > {{"KEYWORD.Treatment" | translate}} Amount</span
                      >
                    </button>
                  </div>
                  <hr class="full-width"/>
                </div>
              </div>
            </mat-menu>

            <mat-menu
              class="emoji-container"
              #smsMenuSender="matMenu"
              [class]="'customizationPanelContain'"
              yPosition="above"
            >
              <div class="main-customization">
                <h3 class="sr-title inner-title smt smb text-center">Sender Details</h3>
                <div *ngFor="let group of groups; let index = index">
                  <div
                    class="mb inner"
                    *ngIf="(contactRules | FilterArrayMultipleOrContain: 'group':group | lengthOfArray) > 0"
                  >
                    <button
                      class="sml smr smt pull-left btn main-customization-btn"
                      *ngFor="
                        let rule of contactRules
                          | FilterArrayMultipleOrContain: 'group':group
                          | OrderArrayObject: ['label']
                      "
                      (click)="insertTextAtCursorCode(rule.code)"
                      mat-raised-button
                    >
                      <mat-icon class="{{ rule.icon }} smr" *ngIf="rule.icon"></mat-icon>
                      {{ rule.label }}
                    </button>
                  </div>
                  <hr
                    class="full-width"
                    *ngIf="(contactRules | FilterArrayMultipleOrContain: 'group':group | lengthOfArray) > 0"
                  />
                </div>
              </div>
            </mat-menu>

            <mat-menu
              class="emoji-container"
              #smsMenuPractice="matMenu"
              [class]="'customizationPanelContain'"
              yPosition="above"
            >
              <div class="main-customization">
                <h3 class="sr-title inner-title smt smb text-center">{{"KEYWORD.Practice" | translate}} Details</h3>
                <div *ngFor="let group of groups; let index = index">
                  <div
                    class="mb inner"
                    *ngIf="(practiceRules | FilterArrayMultipleOrContain: 'group':group | lengthOfArray) > 0"
                  >
                    <button
                      class="sml smr smt pull-left btn main-customization-btn"
                      *ngFor="
                        let rule of practiceRules
                          | FilterArrayMultipleOrContain: 'group':group
                          | OrderArrayObject: ['label']
                      "
                      (click)="insertTextAtCursorCode(rule.code)"
                      mat-raised-button
                    >
                      <mat-icon class="{{ rule.icon }} smr" *ngIf="rule.icon"></mat-icon>
                      {{ rule.label }}
                    </button>
                  </div>
                  <hr
                    class="full-width"
                    *ngIf="(practiceRules | FilterArrayMultipleOrContain: 'group':group | lengthOfArray) > 0"
                  />
                </div>
              </div>
            </mat-menu>

            <mat-menu
              class="emoji-container"
              #smsMenuPromoter="matMenu"
              [class]="'customizationPanelContain'"
              yPosition="above"
            >
              <div class="main-customization">
                <h3 class="sr-title inner-title smt smb text-center">Promoter Details</h3>
                <div *ngFor="let group of groups; let index = index">
                  <div
                    class="mb inner"
                    *ngIf="(promoterRules | FilterArrayMultipleOrContain: 'group':group | lengthOfArray) > 0"
                  >
                    <button
                      class="sml smr smt pull-left btn main-customization-btn"
                      *ngFor="
                        let rule of promoterRules
                          | FilterArrayMultipleOrContain: 'group':group
                          | OrderArrayObject: ['label']
                      "
                      (click)="insertTextAtCursorCode(rule.value)"
                      mat-raised-button
                    >
                      <mat-icon class="{{ rule.icon }} smr" *ngIf="rule.icon"></mat-icon>
                      {{ rule.label }}
                    </button>
                  </div>
                  <hr
                    class="full-width"
                    *ngIf="(promoterRules | FilterArrayMultipleOrContain: 'group':group | lengthOfArray) > 0"
                  />
                </div>
              </div>
            </mat-menu>
          </div>
        </div>
        <div class="full-width ml flex preview-card-container" *ngIf="displayPreview == true">
          <div class="full-width">
            <h3
              class="sr-title text-left full-width rm-mt"
              *ngIf="previewTitle"
              [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
            >
              {{ previewTitle }}
            </h3>
            <div class="full-width flex">
              <mat-card class="full-width preview-card" style="margin-top: 0px !important">
                <div class="full-width message-text" [innerHTML]="convertMessage(true) | safeHtml"></div>
              </mat-card>
            </div>
          </div>
        </div>
      </div>

      <div class="full-width flex mt smb" *ngIf="displayUseDefault == true">
        <mat-checkbox class="full-width text-left mr" [(ngModel)]="useDefault" color="accent" name="displayUseDefault">
          {{ useDefaultMessage }}
        </mat-checkbox>
      </div>
    </mat-dialog-content>

    <div class="drop-button text-center white-background" *ngIf="isAction == true">
      <hr *ngIf="isModal == true"/>

      <button
        class="pull-left action-button btn-large mr"
        *ngIf="isModal == true"
        (click)="closeEvent()"
        mat-raised-button
        color="primary"
      >
        Close
      </button>

      <button
        class="pull-right action-button btn-large"
        [disabled]="userContactForm.invalid"
        (click)="sendAction()"
        mat-raised-button
        color="accent"
      >
        <span>Save</span>
      </button>
    </div>
  </form>
</div>
