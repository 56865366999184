<div class="row clearfix mt ml mr" *ngIf="visitors">
  <mat-card class="rel clearfix">
    <div
      class="card-header primary-gradient-img clearfix inModal rel"
      [attr.style]="'background:' + theme.background + ' !important;' | safeStyle"
    >
      <div class="row clearfix content">
        <div class="row titleArea clearfix">
          <h2 class="summary-header rm-m">
            {{ title }}
            <span class="subLabel">{{ description }} </span>
          </h2>
        </div>

        <div class="full-width">
          <button
            class="share-social-media-button pull-right"
            [attr.style]="
              'border: 1px solid #fff !important; background:none !important; color:#fff !important;' | safeStyle
            "
            (click)="share()"
            mat-raised-button
            color="accent"
          >
            <mat-icon class="smr">share</mat-icon> Share
          </button>

          <button
            class="share-social-media-button pull-right mr"
            [attr.style]="
              'border: 1px solid #fff !important; background:none !important; color:#fff !important;' | safeStyle
            "
            (click)="open()"
            mat-raised-button
            color="accent"
          >
            <mat-icon class="fas fa-external-link-square-alt smr"></mat-icon> Open Link
          </button>
        </div>
      </div>
    </div>

    <div class="full-width clearfix flex row">
      <div class="thrd-width row ml mr">
        <!-- <div *ngIf="isModuleGoogleReviewActive==true" class="full-width flex clearfix">
          <sr-google-reviews-config [hasFullWidth]="true"></sr-google-reviews-config>
        </div> -->

        <div class="full-width flex clearfix" *ngIf="urlQRShort">
          <mat-card class="full-width mat-elevation-z3 mb">
            <div class="row full-width clearfix">
              <h3 class="sr-title pull-left rm-m" [attr.style]="'color:' + theme.color + ' !important;' | safeStyle">
                QR code
              </h3>
            </div>
            <hr/>
            <div class="full-width flex clearfix">
              <div class="list-view" @simpleFadeAnimation>
                <p class="small full-width flex">
                  Unique QR codes: The code or URL will redirect your {{ "KEYWORD.patient" | translate | titlecase }}'s
                  to dynamic
                  landing
                  pages personalised to your {{"KEYWORD.practice" | translate}}. Use these in your marketing material,
                  such as brochures,
                  newsletters, email footers, etc.
                </p>

                <div class="row clearfix full-width mr">
                  <app-generate-qr


                  [downloadLabel]="title"
                    class="qtr-width row"
                    [source]="urlQRShort"
                    [size]="130"
                    [download]="downloadQRCodeDental"
                    style="width: 140px !important"
                  >
                  </app-generate-qr>

                  <span class="qrButtons">
                    <button
                      class="smt"
                      [attr.style]="
                        'background-color:#fff !important;border:1px solid' +
                          theme.color +
                          ' !important;color:' +
                          theme.color +
                          ' !important' | safeStyle
                      "
                      (click)="previewPage(urlQRShort)"
                      mat-raised-button
                      color="primary"
                    >
                      Preview
                    </button>
                    <button
                      [attr.style]="'background:' + theme.color + ' !important;' | safeStyle"
                      (click)="downloadQRCodeDental.emit()"
                      mat-raised-button
                      color="accent"
                    >
                      Download
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </mat-card>
        </div>

        <div class="full-width flex clearfix">
          <mat-card class="full-width mat-elevation-z3 mb" *ngIf="totalVisitor > 0">
            <div class="row clearfix">
              <h3 class="sr-title pull-left rm-m" [attr.style]="'color:' + theme.color + ' !important;' | safeStyle">
                Unique Visits chart
              </h3>
            </div>
            <hr/>
            <!-- <hr> -->
            <div class="chart-view" @simpleFadeAnimation>
              <div class="base-chart-margin base-chart-margin-container rel mr">
                <div class="total anim totalPosition">
                  <span [endVal]="totalVisitor" countUp></span>
                  <span class="totSub">Total</span>
                </div>
                <app-chart-input
                  class="fullHeight visitor-charts mb clearfix"
                  [titleIsTop]="false"
                  [showLegend]="true"
                  [legendPosition]="'bottom'"
                  [showLabel]="false"
                  [chartType]="'pie'"
                  [title]=""
                  [data]="chartVisitorData | orderBy: ['-name']"
                  [dataNameAttribute]="'name'"
                  [dataNameAttribute]="'value'"
                >
                </app-chart-input>
              </div>
            </div>
          </mat-card>
        </div>

        <div class="full-width flex clearfix">
          <mat-card class="full-width mat-elevation-z3 mb">
            <div class="row clearfix">
              <h3 class="sr-title pull-left rm-m" [attr.style]="'color:' + theme.color + ' !important;' | safeStyle">
                Unique Visits
              </h3>
            </div>
            <hr/>

            <div class="list-view" @simpleFadeAnimation>
              <mat-list>
                <mat-list-item>
                  <div class="row flex-row">
                    <p class="reduce-marg">
                      <mat-icon class="fas fa-envelope mr" [style.color]="'#8e8e8e'"></mat-icon>
                      Email
                    </p>
                    <div class="pull-right">
                      <p class="reduce-marg pull-right">{{ visitors['Email_Visitors'] || 0 }}</p>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>
                <mat-list-item>
                  <div class="row flex-row">
                    <p class="reduce-marg">
                      <mat-icon class="fas fa-mobile-alt mr" [style.color]="'#8e8e8e'"></mat-icon>
                      SMS
                    </p>
                    <div class="pull-right">
                      <p class="reduce-marg pull-right">{{ visitors['SMS_Visitors'] || 0 }}</p>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>
                <mat-list-item *ngIf="target != 'practice'">
                  <div class="row flex-row">
                    <p class="reduce-marg">
                      <mat-icon class="fas fa-hospital mr" [style.color]="'#8e8e8e'"></mat-icon>
                      {{ "KEYWORD.practice" | translate | titlecase}} Page
                    </p>
                    <div class="pull-right">
                      <p class="reduce-marg pull-right">{{ visitors['Practice_Visitors'] || 0 }}</p>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>

                <mat-list-item *ngIf="target != 'dental'">
                  <div class="row flex-row">
                    <p class="reduce-marg">
                      <mat-icon class="fas {{'fa-tooth' | translate}} mr" [style.color]="'#8e8e8e'"></mat-icon>
                      {{ "KEYWORD.dental" | translate | titlecase }} Tips and Offers page
                    </p>
                    <div class="pull-right">
                      <p class="reduce-marg pull-right">{{ visitors['Dental_Visitors'] || 0 }}</p>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>
                <mat-list-item>
                  <div class="row flex-row">
                    <p class="reduce-marg">
                      <mat-icon class="fas fa-qrcode mr" [style.color]="'#8e8e8e'"></mat-icon>
                      QR Code Scan
                    </p>
                    <div class="pull-right">
                      <p class="reduce-marg pull-right">{{ visitors['QR_Visitors'] || 0 }}</p>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>
                <mat-list-item>
                  <div class="row flex-row">
                    <p class="reduce-marg">
                      <mat-icon class="fas fa-bullhorn mr" [style.color]="'#8e8e8e'"></mat-icon>
                      Campaign landing page
                    </p>
                    <div class="pull-right">
                      <p class="reduce-marg pull-right">{{ visitors['LandingPage_Visitors'] || 0 }}</p>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>

                <mat-list-item *ngIf="target === 'practice' && merchant && merchant['Google_Review_Place_ID']">
                  <div class="row flex-row">
                    <p class="reduce-marg">
                      <mat-icon class="fab fa-google mr" [style.color]="'#8e8e8e'"></mat-icon>
                      Google Review Clicks
                    </p>
                    <div class="pull-right">
                      <p class="reduce-marg pull-right">{{ visitors['Google_Review_Visitors'] || 0 }}</p>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>

                <mat-list-item>
                  <div class="row flex-row">
                    <p class="reduce-marg">
                      <mat-icon class="fab fa-facebook mr" [style.color]="'#8e8e8e'"></mat-icon>
                      Facebook
                    </p>
                    <div class="pull-right">
                      <p class="reduce-marg pull-right">{{ visitors['Facebook_Visitors'] || 0 }}</p>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>
                <mat-list-item>
                  <div class="row flex-row">
                    <p class="reduce-marg">
                      <mat-icon class="fab fa-linkedin mr" [style.color]="'#8e8e8e'"></mat-icon>
                      Linkedin
                    </p>
                    <div class="pull-right">
                      <p class="reduce-marg pull-right">{{ visitors['Linkedin_Visitors'] || 0 }}</p>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>
                <mat-list-item>
                  <div class="row flex-row">
                    <p class="reduce-marg">
                      <mat-icon class="fab fa-twitter mr" [style.color]="'#8e8e8e'"></mat-icon>
                      Twitter
                    </p>
                    <div class="pull-right">
                      <p class="reduce-marg pull-right">{{ visitors['Twitter_Visitors'] || 0 }}</p>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>
                <mat-list-item>
                  <div class="row flex-row">
                    <p class="reduce-marg">
                      <mat-icon class="fab fa-instagram mr" [style.color]="'#8e8e8e'"></mat-icon>
                      Instagram
                    </p>
                    <div class="pull-right">
                      <p class="reduce-marg pull-right">{{ visitors['Instagram_Visitors'] || 0 }}</p>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>

                <mat-list-item>
                  <div class="row flex-row">
                    <p class="reduce-marg">
                      <mat-icon class="fab fa-pinterest mr" [style.color]="'#8e8e8e'"></mat-icon>
                      Pinterest
                    </p>
                    <div class="pull-right">
                      <p class="reduce-marg pull-right">{{ visitors['Pinterest_Visitors'] || 0 }}</p>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>
                <mat-list-item>
                  <div class="row flex-row">
                    <p class="reduce-marg">
                      <mat-icon class="fas fa-file mr" [style.color]="'#8e8e8e'"></mat-icon>
                      Other
                    </p>
                    <div class="pull-right">
                      <p class="reduce-marg pull-right">{{ visitors['Other_Visitors'] || 0 }}</p>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>
                <mat-list-item class="mt">
                  <div class="row clearfix full-width" matLine>
                    <p class="reduce-marg bold pull-left">Total Visitors</p>
                    <p class="reduce-marg bold pull-right">{{ totalVisitor }}</p>
                  </div>
                </mat-list-item>
              </mat-list>
            </div>
          </mat-card>
        </div>
      </div>

      <div class="full-width page-container row" *ngIf="link">
        <div class="row clearfix full-width full-height templateViewArea">
          <iframe
            class="preview-iframe"
            *ngIf="iFrameSrcSecured"
            [src]="iFrameSrcSecured"
            (load)="onFrameLoad($event)"
          ></iframe>
        </div>
      </div>
    </div>
  </mat-card>
</div>
