<div class="rel clearfix lookup">
  <div class="card-header primary-gradient-img clearfix inModal">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <mat-icon
          class="fas fa-folder-open pull-left mr main-icon-brush"
          style="font-size: 2.8rem; margin-top: 10px; width: 70px"
        ></mat-icon>
        <h2 class="summary-header rm-m">
          {{ title }}
          <span class="subLabel" [innerHTML]="description | safeHtml"></span>
        </h2>
      </div>
    </div>
  </div>

  <div class="clearfix">
    <div class="clearfix flex flex-center">
      <mat-form-field class="full-width mr" appearance="outline">
        <mat-label>Search...</mat-label>
        <input
          [(ngModel)]="filterData"
          (ngModelChange)="backToOne()"
          matInput
          name="search"
          placeholder="Search by Title, Description, Message"
          tabIndex="1"
        />
      </mat-form-field>

      <mat-form-field class="half-width mr" appearance="outline">
        <mat-label>Type</mat-label>
        <mat-select
          class="full-width"
          [(ngModel)]="financeFilter"
          (ngModelChange)="changeLandingPageType()"
          name="financeFilter"
          placeholder="Type"
        >
          <mat-option [value]="null"> All</mat-option>
          <mat-option *ngFor="let type1 of types" [value]="type1.Code">
            <mat-icon class="{{ type1.Icon }} smr"></mat-icon>
            {{ type1.Label }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="half-width mr" appearance="outline">
        <mat-label>Finance Type</mat-label>
        <mat-select class="full-width" [(ngModel)]="productGroup" placeholder="Finance Type" name="productGroup">
          <mat-option [value]="null"> All</mat-option>

          <mat-option *ngFor="let p of financedProductGroup" [value]="p['GroupCode']">
            <mat-icon class="fas {{ productUtil.getProductGroupIcon(p['GroupCode']) }} mr iconproductgroup ">
            </mat-icon>

            {{ productUtil.getLabel(p['GroupCode']) }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button
        class="sml actionMenuToggle"
        *ngIf="isCollapsed == true"
        (click)="collapse(false)"
        mat-icon-button
        matTooltip="Open all content "
      >
        <mat-icon class="fas fa-window-maximize"></mat-icon>
      </button>

      <button
        class="sml actionMenuToggle"
        *ngIf="isCollapsed != true"
        (click)="collapse(true)"
        mat-icon-button
        matTooltip="Close all  content"
      >
        <mat-icon class="fas fa-window-minimize"></mat-icon>
      </button>
      <button class="sml actionMenuToggle" [matMenuTriggerFor]="menu" mat-icon-button>
        <mat-icon class="fas fa-sort"></mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button
          [ngClass]="{ 'accent-color': orderByFilter === 'Label' || orderByFilter === '-Label' }"
          (click)="orderBy('Label')"
          mat-menu-item
        >
          <span>Label</span>
          <span *ngIf="orderByFilter === 'Label' || orderByFilter === '-Label'">
            <mat-icon class="accent-color" *ngIf="orderByFilter === '-Label'">arrow_drop_up</mat-icon>
            <mat-icon class="accent-color" *ngIf="orderByFilter !== '-Label'">arrow_drop_down</mat-icon>
          </span>
        </button>

        <button
          [ngClass]="{ 'accent-color': orderByFilter === 'DateTimeCreated' || orderByFilter === '-DateTimeCreated' }"
          (click)="orderBy('-DateTimeCreated')"
          mat-menu-item
        >
          <span>Date</span>
          <span *ngIf="orderByFilter === 'DateTimeCreated' || orderByFilter === '-DateTimeCreated'">
            <mat-icon class="accent-color" *ngIf="orderByFilter === '-DateTimeCreated'">arrow_drop_down</mat-icon>
            <mat-icon class="accent-color" *ngIf="orderByFilter !== '-DateTimeCreated'"> arrow_drop_up</mat-icon>
          </span>
        </button>

        <button
          [ngClass]="{ 'accent-color': orderByFilter === 'Visitors' || orderByFilter === '-Visitors' }"
          (click)="orderBy('-Visitors')"
          mat-menu-item
        >
          <span>Visitors</span>
          <span *ngIf="orderByFilter === 'Visitors' || orderByFilter === '-Visitors'">
            <mat-icon class="accent-color" *ngIf="orderByFilter === '-Visitors'">arrow_drop_up</mat-icon>
            <mat-icon class="accent-color" *ngIf="orderByFilter !== '-Visitors'">arrow_drop_down</mat-icon>
          </span>
        </button>

        <button
          [ngClass]="{ 'accent-color': orderByFilter === 'Treatment_Amount' || orderByFilter === '-Treatment_Amount' }"
          (click)="orderBy('-Treatment_Amount')"
          mat-menu-item
        >
          <span *ngIf="settings && settings['context'] != 'breeze' "
          >{{"KEYWORD.Treatment" | translate}} Amount</span
          >
          <span *ngIf="settings && settings['context'] == 'breeze' "
          >Amount to Finance</span
          >
          <span *ngIf="orderByFilter === 'Treatment_Amount' || orderByFilter === '-Treatment_Amount'">
            <mat-icon class="accent-color" *ngIf="orderByFilter === '-Treatment_Amount'">arrow_drop_up</mat-icon>
            <mat-icon class="accent-color" *ngIf="orderByFilter !== '-Treatment_Amount'">arrow_drop_down</mat-icon>
          </span>
        </button>
      </mat-menu>
    </div>
    <div class="clearfix smt smb flex flex-center">
      <mat-button-toggle-group
        class="btn-group rm-m selectType full-width flex tab"
        [(ngModel)]="typeFilter"
        (ngModelChange)="changeTab()"
        value="null"
      >
        <mat-button-toggle class="full-width" *ngIf="!type" [value]="null">
          <span
            class="badge master"
            *ngIf="documentLinks"
            [endVal]="
              (documentLinks
                | FilterArrayMultipleOrContain: 'search':filterData
                | FilterArrayMultipleOrContain: 'Type.Code':financeFilter
                | FilterArrayMultipleOrContain: 'productGroup':productGroup
                | FilterArrayMultipleValue: ['type']:null
                | lengthOfArray) || 0
            "
            countUp
          ></span>
          All
        </mat-button-toggle>

        <mat-button-toggle class="full-width" *ngIf="!type || type == 'user'" [value]="'user'">
          <span
            class="badge master"
            *ngIf="documentLinks"
            [endVal]="
              (documentLinks
                | FilterArrayMultipleOrContain: 'search':filterData
                | FilterArrayMultipleOrContain: 'Type.Code':financeFilter
                | FilterArrayMultipleOrContain: 'productGroup':productGroup
                | FilterArrayMultipleValue: ['type']:'user'
                | lengthOfArray) || 0
            "
            countUp
          ></span>
          {{ "KEYWORD.practice" | translate | titlecase}}
          <mat-icon class="fas fa-user-alt"></mat-icon>
        </mat-button-toggle>

        <mat-button-toggle class="full-width" *ngIf="!type || type == 'default'" [value]="'default'">
          <span
            class="badge master"
            *ngIf="documentLinks"
            [endVal]="
              (documentLinks
                | FilterArrayMultipleOrContain: 'search':filterData
                | FilterArrayMultipleOrContain: 'Type.Code':financeFilter
                | FilterArrayMultipleOrContain: 'productGroup':productGroup
                | FilterArrayMultipleOrContain: 'productGroup':productGroup
                | FilterArrayMultipleValue: ['type']:'default'
                | lengthOfArray) || 0
            "
            countUp
          ></span>
          {{ settings.brandName }}
          <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <mat-dialog-content class="lookup-documentLink" [ngClass]="{ 'no-modal-height': isModal != true }">
    <div class="patientContainer">
      <div
        class="patientRecord row clearfix"
        *ngFor="
          let documentLink of documentLinks
            | FilterArrayMultipleOrContain: 'search':filterData
            | FilterArrayMultipleOrContain: 'Type.Code':financeFilter
            | FilterArrayMultipleOrContain: 'productGroup':productGroup
            | FilterArrayMultipleValue: ['type']:typeFilter
            | OrderArrayObject: [orderByFilter]
            | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }
        "
      >
        <div class="full-width clearfix expend-container">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="profile-result full-width flex" (click)="selectDocumentLink(documentLink)">
                    <!-- avatar image -->
                    <div class="chip-holder" style="width: 70px">
                      <landing-page-header-image
                        class="pull-left doc-link-lookup"
                        [headerImage]="documentLink['Header_Image']"
                      >
                      </landing-page-header-image>

                      <span
                        class="chipFinance pull-right doc-link-lookup-chip"
                        *ngIf="documentLink.Is_Finance == '1'"
                        matTooltip="Finance"
                      >
                        <mat-icon class="fas fa-dollar-sign"></mat-icon>
                      </span>
                    </div>
                    <div class="pull-left full-width truncate-normal">
                      <div class="full-width">
                        <h4
                          class="sr-title rm-m mr smt pull-left"
                          [ngClass]="{
                            'title-practice': documentLink && documentLink.type && documentLink.type == 'user'
                          }"
                        >
                          <strong class="mr"> {{ documentLink['Label'] }}</strong>

                          <span class="value chip-new pull-right" *ngIf="isNew(documentLink['DateTimeCreated']) == true"
                          >NEW</span
                          >
                        </h4>
                      </div>
                    </div>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="flex">
                <div class="profile-result full-width flex">
                  <div
                    class="pull-left full-width truncate-normal patientRecord-inner"
                    (click)="selectDocumentLink(documentLink)"
                  >
                    <p class="pull-left full-width rm-m smb">
                      {{ documentLink.Title }}
                    </p>
                    <div class="full-width flex read-more-container smb">
                      <mat-icon
                        class="fas fa-comment read-more-icon smr"
                        *ngIf="
                          loadCard == true &&
                          loadMerchant == true &&
                          loadPromoter == true &&
                          loadContact == true &&
                          documentLink
                        "
                      ></mat-icon>
                      <p class="read-more-description full-width rm-m">Description</p>
                    </div>

                    <div
                      class="full-width flex"
                      *ngIf="
                        loadCard == true &&
                        loadMerchant == true &&
                        loadPromoter == true &&
                        loadContact == true &&
                        documentLink
                      "
                    >
                      <read-more
                        class="text-left full-width read-more-module110 read-more-message-input rm-m"
                        [animated]="false"
                        [text]="utilsClass.URLify(buildContentDescription(documentLink, true) || '...', false)"
                        [design]="'noPropagation'"
                        [hideButton]="false"
                        [isCollapsed]="isCollapsed"
                        [maxLength]="maxLength"
                      >
                      </read-more>
                    </div>
                    <div class="full-width flex read-more-container mt smb">
                      <mat-icon
                        class="fas fa-envelope read-more-icon smr"
                        *ngIf="
                          loadCard == true &&
                          loadMerchant == true &&
                          loadPromoter == true &&
                          loadContact == true &&
                          documentLink
                        "
                      ></mat-icon>
                      <p class="read-more-description full-width rm-m">Message</p>
                    </div>
                    <div
                      class="full-width flex"
                      *ngIf="
                        loadCard == true &&
                        loadMerchant == true &&
                        loadPromoter == true &&
                        loadContact == true &&
                        documentLink
                      "
                    >
                      <read-more
                        class="text-left full-width read-more-module110 read-more-message-input rm-m"
                        [animated]="false"
                        [text]="utilsClass.URLify(buildContentMessage(documentLink, true) || '...', false)"
                        [design]="'noPropagation'"
                        [hideButton]="false"
                        [isCollapsed]="isCollapsed"
                        [maxLength]="maxLength"
                      >
                      </read-more>
                    </div>

                    <div
                      class="full-width clearfix small-margin-bottom smt"
                      *ngIf="documentLink && documentLink.Tags && documentLink.Tags.length > 0"
                    >
                      <app-tag-list
                        class="full-width ship tag-list-lookup"
                        [selectedTags]="documentLink.Tags"
                        [tagFormatIsObject]="false"
                        [title]="''"
                        [canRemove]="false"
                        [canCreate]="false"
                        [directRemoveDefault]="true"
                        [directRemove]="true"
                        [hasFilter]="false"
                        [canSelect]="false"
                      >
                      </app-tag-list>
                    </div>

                    <div class="full-width clearfix">
                      <p class="small rm-m mr date_label grey pull-right" *ngIf="documentLink.DateTimeCreated">
                        {{ documentLink.DateTimeCreated | TimeLabelPast }} ago
                      </p>
                      <div class="pull-left text-left">
                        <div class="mr statistics pull-left" matTooltip="Attached Documents ">
                          <mat-icon class="fas fa-images count-number-icon smr"></mat-icon>

                          <span class="number-container accent-color">{{ documentLink.DocumentFKs.length || 0 }}</span>
                        </div>

                        <div class="mr statistics pull-left" matTooltip="Visitors ">
                          <mat-icon class="fas fa-users count-number-icon smr"></mat-icon>

                          <span class="number-container accent-color">{{ documentLink.Visitors || 0 }}</span>
                        </div>

                        <h3
                          class="sr-title rm-m pull-left"
                          *ngIf="documentLink && documentLink.Is_Finance == '1' && documentLink.Treatment_Amount"
                          [ngClass]="{ 'title-practice': documentLink.IsDefault !== '1' }"
                          style="margin-top: -2px !important"
                        >
                          <span *ngIf="documentLink['Product_Group.Code']"
                          >{{ productUtil.getLabel(documentLink['Product_Group.Code']) }}:
                          </span>
                          {{ documentLink.Treatment_Amount | customCurrency }}
                        </h3>

                        <div class="mr statistics pull-left" *ngIf="documentLink['Date.Expire'] !== '2200-01-01'">
                          <mat-icon class="fas fa-calendar-alt count-number-icon smr"></mat-icon>

                          <strong
                            class="count-number mr"
                            matTooltip="Expires At {{ documentLink['Date.Expire'] | customDate }}"
                          >
                            {{
                            documentLink['Date.Expire'] | timeLeft: null:'years,months,days,hours':true:true
                            }}</strong
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    class="documentLink-view-button"
                    *ngIf="documentLink && documentLink['ID']"
                    [matMenuTriggerFor]="previewMenu"
                    (click)="$event.stopPropagation()"
                    color="primary"
                    mat-icon-button
                    matTooltip="View Landing Page Details"
                  >
                    <mat-icon>more_vert</mat-icon>
                  </button>

                  <mat-menu #previewMenu="matMenu">
                    <button (click)="view(documentLink['ID'])" mat-menu-item>
                      <mat-icon>visibility</mat-icon>
                      <span>Preview</span>
                    </button>

                    <button (click)="openLandingPage(documentLink['ID'])" mat-menu-item>
                      <mat-icon>description</mat-icon>
                      <span>View Landing Page</span>
                    </button>

                    <button
                      *ngIf="documentLink.IsDefault !== '1' || isPromoterOrAdmin == true"
                      (click)="edit(documentLink['ID'])"
                      mat-menu-item
                    >
                      <mat-icon>edit</mat-icon>
                      <span>Edit</span>
                    </button>

                    <button (click)="clone(documentLink)" mat-menu-item>
                      <mat-icon>content_copy</mat-icon>
                      <span>Clone</span>
                    </button>
                    <button
                      *ngIf="documentLink.IsDefault !== '1' || isPromoterOrAdmin == true"
                      (click)="delete(documentLink['ID'])"
                      mat-menu-item
                    >
                      <mat-icon>delete</mat-icon>
                      <span>Delete</span>
                    </button>
                  </mat-menu>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div class="flex full-width clearfix mt mb normal-container">
          <div class="profile-result full-width flex">
            <!-- avatar image -->

            <div class="chip-holder">
              <landing-page-header-image class="pull-left mr" [headerImage]="documentLink['Header_Image']">
              </landing-page-header-image>

              <span class="chipFinance pull-right mt mr" *ngIf="documentLink.Is_Finance == '1'" matTooltip="Finance">
                <mat-icon class="fas fa-dollar-sign"></mat-icon>
              </span>
            </div>

            <!-- profile information -->
            <div class="pull-left full-width truncate-normal" (click)="selectDocumentLink(documentLink)">
              <div class="full-width mb">
                <h3
                  class="sr-title rm-m pull-left"
                  [ngClass]="{ 'title-practice': documentLink && documentLink.type && documentLink.type == 'user' }"
                >
                  <strong class="mr"> {{ documentLink['Label'] }}</strong>

                  <span class="value chip-new pull-right" *ngIf="isNew(documentLink['DateTimeCreated']) == true"
                  >NEW</span
                  >
                </h3>
              </div>

              <p class="pull-left full-width document-title rm-m smb">
                {{ documentLink.Title }}
              </p>
              <div class="full-width flex read-more-container smb">
                <mat-icon
                  class="fas fa-comment read-more-icon smr"
                  *ngIf="
                    loadCard == true &&
                    loadMerchant == true &&
                    loadPromoter == true &&
                    loadContact == true &&
                    documentLink
                  "
                ></mat-icon>
                <p class="read-more-description full-width rm-m">Description</p>
              </div>

              <div
                class="full-width flex"
                *ngIf="
                  loadCard == true &&
                  loadMerchant == true &&
                  loadPromoter == true &&
                  loadContact == true &&
                  documentLink
                "
              >
                <read-more
                  class="text-left full-width read-more-module110 read-more-message-input rm-m"
                  [animated]="false"
                  [text]="utilsClass.URLify(buildContentDescription(documentLink, true) || '...', false)"
                  [design]="'noPropagation'"
                  [hideButton]="false"
                  [isCollapsed]="isCollapsed"
                  [maxLength]="maxLength"
                >
                </read-more>
              </div>
              <div class="full-width flex read-more-container mt smb">
                <mat-icon
                  class="fas fa-envelope read-more-icon smr"
                  *ngIf="
                    loadCard == true &&
                    loadMerchant == true &&
                    loadPromoter == true &&
                    loadContact == true &&
                    documentLink
                  "
                ></mat-icon>
                <p class="read-more-description full-width rm-m">Message</p>
              </div>
              <div
                class="full-width flex"
                *ngIf="
                  loadCard == true &&
                  loadMerchant == true &&
                  loadPromoter == true &&
                  loadContact == true &&
                  documentLink
                "
              >
                <read-more
                  class="text-left full-width read-more-module110 read-more-message-input rm-m"
                  [animated]="false"
                  [text]="utilsClass.URLify(buildContentMessage(documentLink, true) || '...', false)"
                  [design]="'noPropagation'"
                  [hideButton]="false"
                  [isCollapsed]="isCollapsed"
                  [maxLength]="maxLength"
                >
                </read-more>
              </div>
              <div
                class="full-width clearfix small-margin-bottom smt"
                *ngIf="documentLink && documentLink.Tags && documentLink.Tags.length > 0"
              >
                <app-tag-list
                  class="full-width ship tag-list-lookup"
                  [selectedTags]="documentLink.Tags"
                  [tagFormatIsObject]="false"
                  [title]="''"
                  [canRemove]="false"
                  [canCreate]="false"
                  [directRemoveDefault]="true"
                  [directRemove]="true"
                  [hasFilter]="false"
                  [canSelect]="false"
                >
                </app-tag-list>
              </div>

              <div class="full-width clearfix smt">
                <p class="small rm-m mr date_label grey pull-right" *ngIf="documentLink.DateTimeCreated">
                  {{ documentLink.DateTimeCreated | TimeLabelPast }} ago
                </p>
                <div class="pull-left text-left">
                  <div class="mr statistics pull-left" matTooltip="Attached Documents ">
                    <mat-icon class="fas fa-images count-number-icon smr"></mat-icon>

                    <span class="number-container accent-color">{{ documentLink.DocumentFKs.length || 0 }}</span>
                  </div>

                  <div class="mr statistics pull-left" matTooltip="Visitors ">
                    <mat-icon class="fas fa-users count-number-icon smr"></mat-icon>

                    <span class="number-container accent-color">{{ documentLink.Visitors || 0 }}</span>
                  </div>
                  <h3
                    class="sr-title rm-m pull-left"
                    *ngIf="documentLink && documentLink.Is_Finance == '1' && documentLink.Treatment_Amount"
                    [ngClass]="{ 'title-practice': documentLink.IsDefault !== '1' }"
                    style="margin-top: -2px !important"
                  >
                    <span *ngIf="documentLink['Product_Group.Code']"
                    >{{ productUtil.getLabel(documentLink['Product_Group.Code']) }}:
                    </span>
                    {{ documentLink.Treatment_Amount | customCurrency }}
                  </h3>

                  <div class="mr statistics pull-left" *ngIf="documentLink['Date.Expire'] !== '2200-01-01'">
                    <mat-icon class="fas fa-calendar-alt count-number-icon smr"></mat-icon>

                    <strong
                      class="count-number mr"
                      matTooltip="Expires At {{ documentLink['Date.Expire'] | customDate }}"
                    >
                      {{ documentLink['Date.Expire'] | timeLeft: null:'years,months,days,hours':true:true }}</strong
                    >
                  </div>
                </div>
              </div>
            </div>

            <button
              class="documentLink-view-button"
              *ngIf="documentLink && documentLink['ID']"
              [matMenuTriggerFor]="previewMenu"
              (click)="$event.stopPropagation()"
              color="primary"
              mat-icon-button
              matTooltip="View Landing Page Details"
            >
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #previewMenu="matMenu">
              <button (click)="view(documentLink['ID'])" mat-menu-item>
                <mat-icon>visibility</mat-icon>
                <span>Preview</span>
              </button>
              <button (click)="openLandingPage(documentLink)" mat-menu-item>
                <mat-icon>description</mat-icon>
                <span>View Landing Page</span>
              </button>

              <button
                *ngIf="documentLink.IsDefault !== '1' || isPromoterOrAdmin == true"
                (click)="edit(documentLink['ID'])"
                mat-menu-item
              >
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>

              <button
                *ngIf="documentLink.IsDefault !== '1' || isPromoterOrAdmin == true"
                (click)="clone(documentLink)"
                mat-menu-item
              >
                <mat-icon>content_copy</mat-icon>
                <span>Clone</span>
              </button>

              <button
                *ngIf="documentLink.IsDefault !== '1' || isPromoterOrAdmin == true"
                (click)="delete(documentLink['ID'])"
                mat-menu-item
              >
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </div>
        </div>

        <mat-divider></mat-divider>
      </div>

      <div
        class="full-width"
        *ngIf="
          (documentLinks
            | FilterArrayMultipleOrContain: 'search':filterData
            | FilterArrayMultipleOrContain: 'Type.Code':financeFilter
            | FilterArrayMultipleOrContain: 'productGroup':productGroup
            | FilterArrayMultipleValue: ['type']:typeFilter
            | OrderArrayObject: [orderByFilter]
            | lengthOfArray) <= 0 && isLoaded == true
        "
      >
        <app-empty-list-message [message]="'No Landing Page found'"></app-empty-list-message>
      </div>
    </div>

    <div
      class="full-width text-center page-container flex"
      *ngIf="
        (documentLinks
          | FilterArrayMultipleOrContain: 'search':filterData
          | FilterArrayMultipleOrContain: 'Type.Code':financeFilter
          | FilterArrayMultipleOrContain: 'productGroup':productGroup
          | FilterArrayMultipleValue: ['type']:typeFilter
          | OrderArrayObject: [orderByFilter]
          | lengthOfArray) > 20
      "
    >
      <div class="ml qtr-width flex pull-left">
        <mat-label class="line-h">Page size</mat-label>
        <mat-select class="page-size-in line-h pull-right" [(ngModel)]="itemsPerPage" placeholder="Page size">
          <mat-option [value]="20"> 20</mat-option>
          <mat-option [value]="30"> 30</mat-option>
          <mat-option [value]="40"> 40</mat-option>
          <mat-option [value]="50"> 50</mat-option>
        </mat-select>
      </div>
      <pagination-controls class="full-width clearfix" (pageChange)="changePage($event)" responsive="true">
      </pagination-controls>
    </div>
    <br/>
    <br/>
  </mat-dialog-content>
  <hr class="documentLink-bottom-divider"/>
  <div class="drop-button full-width text-centerclearfix">
    <button
      class="inline btn-new-invite selectExistingPatient pull-right btn"
      *ngIf="createNew == true"
      (click)="gotoNewDocumentLink()"
      mat-raised-button
      color="accent"
    >
      <mat-icon>add</mat-icon> <span>Create your own</span>
    </button>

    <button
      class="inline btn-new-invite selectExistingPatient pull-left btn"
      *ngIf="createNew == true"
      (click)="closeEvent()"
      mat-raised-button
      color="primary"
    >
      Close
    </button>

    <button
      class="inline btn-new-invite selectExistingPatient btn"
      *ngIf="createNew != true"
      (click)="closeEvent()"
      mat-raised-button
      color="primary"
    >
      Close
    </button>

    <button
      class="inline ml btn-new-invite selectExistingPatient pull-left btn"
      *ngIf="isHeader == true"
      (click)="list()"
      mat-raised-button
      color="primary"
    >
      Landing Page List
    </button>

    <button
      class="inline ml btn-new-invite selectExistingPatient pull-left btn"
      *ngIf="isHeader == true"
      (click)="listTemplate()"
      mat-raised-button
      color="primary"
    >
      Templates
    </button>
  </div>
</div>
