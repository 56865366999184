import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { MapViewComponent } from '../../../shared/components/map-view/map-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { CustomerProspectService } from '../shared/customerProspect.service';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';

@Component({
  selector: 'customer-prospect-view-model',
  templateUrl: './customer-prospect-view-model.component.html',
  styleUrls: ['./customer-prospect-view-model.component.css'],
  animations: [
    trigger('onChangeAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0, margin: 0 }),
                style({ opacity: 1, offset: 1.0, height: '*', margin: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*', margin: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0, margin: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class CustomerProspectViewModelComponent implements OnInit {
  @Input() patientID;

  @Output() detailedView = new EventEmitter();

  @Output() close = new EventEmitter();
  patient;
  profilePic;
  loading = false;
  currentUser;
  operators;
  sessionType = 'merchant';

  isModal = true;

  isFullDetails = false;

  @Input()
  embedded = false;
  constructor(
    private router: Router,
    private customPhone: CustomPhonePipe,
    private customerProspectService: CustomerProspectService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.patientID) {
        this.patientID = data.patientID;

        this.isModal = true;
      }
      if (data.isFullDetails != null) {
        this.isFullDetails = data.isFullDetails;

        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((s) => {
      this.sessionType = s;

      this.setup();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.patientID.previousValue != changes.patientID.currentValue) {
      this.authenticationService.getSessionType().subscribe((s) => {
        this.sessionType = s;

        this.setup();
      });
    }
  }

  setup() {
    if (this.patientID) {
      this.authenticationService
        .getCardDetails(this.patientID, 'CustomersAndProspects', {}, this.sessionType)
        .subscribe((r) => {
          if (r && r.ID) {
            this.patient = r;

            if (this.patient['ReferredCustomer'] == '1') {
              this.patient.isReffered = true;
            } else {
              this.patient.isReffered = false;
            }

            if (this.patient['MarketingConsent.Given'] == '1') {
              this.patient.isMarketing = true;
            } else {
              this.patient.isMarketing = false;
            }
          }
        });
    }
  }
  getMoreInfo() {
    let phone = '';

    if (this.patient['phones.Number']) {
      phone = this.customPhone.transform(String(this.patient['phones.Number']), 'landLine');
    }

    const result =
      "<p><strong>Patient's name: </strong>" +
      this.patient['CalculatedName'] +
      '</p><p><strong>Phone: </strong>' +
      String(phone) +
      "</p><p><strong>Email: </strong> <a class='emailLink primary-color' href='mailto:" +
      String(this.patient['emails.Email']) +
      "' title='Email Dentist' target='_blank'>" +
      this.patient['emails.Email'] +
      '</a></p>';

    return result;
  }

  displayMap() {
    const location = {
      zoom: 17,
      postCode: this.patient['addresses.Postcode'],
      state: this.patient['addresses.State'],
      streetType: this.patient['addresses.Street Type'],
      streetName: this.patient['addresses.Street Name'],
      streetNumber: this.patient['addresses.Street Nr'],
      suburb: this.patient['addresses.Suburb'],
      country: this.patient['addresses.Country.Code'],
      addressLabel: this.patient['addresses.Calculated'],
      latitude: this.patient['Address.Latitude'],
      longitude: this.patient['Address.Longitude'],
      moreInfo: this.getMoreInfo(),
    };

    const ref = RootAppComponent.dialog.open(MapViewComponent, {
      data: location,
      panelClass: 'modal-map',
      width: '800px',
      height: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }
  openPatientDetails() {
    if (this.patient && this.patient.ID) {
      const confirm = new ConfirmDialog(
        'fas fa-info',

        'Do you want to continue?',
        'You are about to leave this page.',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
        width: '500px',
      });

      ref.componentInstance.onConfirm.subscribe((res) => {
        if (res == true) {
          ref.close();
          this.router.navigate([
            '/merchant',
            {
              outlets: {
                page: ['customer-profile', this.patient.ID],
              },
            },
          ]);

          this.detailedView.emit(true);
        } else {
          ref.close();
        }
      });
    }
  }
  closeModal() {
    this.close.emit(true);
  }
}
