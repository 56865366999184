<div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="listDB.data === undefined || listDB.data.length == 0">
  <div>
    <h1 class="dataHeader">
      {{ title }}
      <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
    </h1>

    <mat-card class="list-card">
      <!-- Search -->
      <div class="row clearfix searchArea primary-gradient-img">
        <div class="full-width clearfix flex animated fadeInDown">
          <mat-icon class="mt">search</mat-icon>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Filter by email, number, name, subject or description</mat-label>
            <input class="clearfix" #searchField (input)="setFilter($event.target.value, 'searchField')" matInput/>
          </mat-form-field>
          <mat-form-field class="ml full-width" appearance="outline">
            <mat-label>Message Type</mat-label>
            <mat-select
              class="full-width"
              #messageTypeSelector
              (selectionChange)="setTypeFilter($event.value, 'Type')"
              placeholder="Message Type"
            >
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option
                *ngFor="let e of listDB.data | objectPropertyValue: 'Type' | unique | without: ['', null] | orderBy"
                [value]="e"
              >
                <span *ngIf="e == 'E'">Email</span>
                <span *ngIf="e == 'S'">SMS</span>
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="ml full-width" appearance="outline">
            <mat-label>Message Status</mat-label>
            <mat-select
              class="full-width"
              #messageStatusSelector
              (selectionChange)="setStatusFilter($event.value, 'Status.Label')"
              placeholder="Message Status"
            >
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option
                *ngFor="
                  let e of listDB.data | objectPropertyValue: 'Status.Label' | unique | without: ['', null] | orderBy
                "
                [value]="e"
              >
                {{ e }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="ml full-width" *ngIf="!templateTypeID" appearance="outline">
            <mat-label>Context</mat-label>
            <mat-select
              class="full-width"
              #messageStatusSelector
              (selectionChange)="setStatusFilter($event.value, 'Template_Type.Label')"
              placeholder="Message Status"
            >
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option
                *ngFor="
                  let e of listDB.data
                    | objectPropertyValue: 'Template_Type.Label'
                    | unique
                    | without: ['', null]
                    | orderBy
                "
                [value]="e"
              >
                {{ e }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="ml full-width" appearance="outline">
            <mat-label>Target</mat-label>
            <mat-select
              class="full-width"
              #messageStatusSelector
              (selectionChange)="setStatusFilter($event.value, 'Target')"
              placeholder="Message Status"
            >
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option [value]="'Merchant'"> Merchant</mat-option>

              <mat-option [value]="'Operator'"> Operator</mat-option>

              <mat-option [value]="'Patient'">
                {{ "KEYWORD.patient" | translate | titlecase }}
              </mat-option>
              <mat-option [value]="'Promoter'"> Promoter</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- Table -->
      <table #table [dataSource]="dataSource" mat-table matSort>
        <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->
        <ng-container matColumnDef="Recipient" sticky>
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Recipient</th>
          <td
            class="rel name-cell"
            *matCellDef="let row"
            [ngStyle]="{ 'border-left': '8px solid ' + colour(row['Status.Label']) || '#b4bcc1' }"
            (click)="openMessageView(row['ID'], row['Type'])"
            mat-cell
            matTooltip="{{ row['Card_Name'] }}"
          >
            <span class="ellipsis2 content">{{ row['Card_Name'] }}</span>
            <span class="colour" [ngStyle]="{ background: colour(row['Status.Label']) || '#b4bcc140' }"></span>
          </td>
        </ng-container>
        <ng-container matColumnDef="Date">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Date</th>
          <td
            *matCellDef="let row"
            (click)="openMessageView(row['ID'], row['Type'])"
            mat-cell
            matTooltip="{{ row['DateTimeCreated'] | customDate }}"
          >
            {{ row['DateTimeCreated'] | customDate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Type">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Type</th>
          <td *matCellDef="let row" (click)="openMessageView(row['ID'], row['Type'])" mat-cell>
            <span *ngIf="row['Type'] == 'E'" matTooltip="Email"><i class="fas fa-envelope"></i></span>
            <span *ngIf="row['Type'] == 'S'" matTooltip="SMS"><i class="fas fa-mobile-alt"></i></span>
          </td>
        </ng-container>
        <ng-container matColumnDef="Direction">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>In/Out</th>
          <td *matCellDef="let row" (click)="openMessageView(row['ID'], row['Type'])" mat-cell>
            <span>{{ row['Direction'] }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="Context">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Context</th>
          <td *matCellDef="let row" (click)="openMessageView(row['ID'], row['Type'])" mat-cell>
            <span>{{ row['Template_Type.Label'] }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="Target">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Target</th>
          <td *matCellDef="let row" (click)="openMessageView(row['ID'], row['Type'])" mat-cell>
            <span>{{ row['Target'] }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="EmailNumber">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Email/Number</th>
          <td
            *matCellDef="let row"
            (click)="openMessageView(row['ID'], row['Type'])"
            mat-cell
            matTooltip="{{ row['Card_NumberOrAddress'] }}"
          >
            <span class="ellipsis">{{ row['Card_NumberOrAddress'] }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="Subject">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Subject</th>
          <td
            *matCellDef="let row"
            (click)="openMessageView(row['ID'], row['Type'])"
            mat-cell
            matTooltip="{{ row['Subject'] }}"
          >
            <span class="ellipsis">{{ row['Subject'] }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="Description">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Description</th>
          <td
            *matCellDef="let row"
            (click)="openMessageView(row['ID'], row['Type'])"
            mat-cell
            matTooltip="{{ row['Description'] }}"
          >
            <span class="ellipsis">{{ row['Description'] }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="TemplateTag">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Template Tag</th>
          <td
            *matCellDef="let row"
            (click)="openMessageView(row['ID'], row['Type'])"
            mat-cell
            matTooltip="{{ row['TemplateTag'] }}"
          >
            <span class="ellipsis">{{ row['TemplateTag'] }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="Status">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Status</th>
          <td
            *matCellDef="let row"
            (click)="openMessageView(row['ID'], row['Type'])"
            mat-cell
            matTooltip="{{ statusLabel(row['Status.Label']) }}"
          >
            <span class="list-label" [style.background]="colour(row['Status.Label']) || '#b4bcc1'">{{
              statusLabel(row['Status.Label'])
              }}</span>
            <span class="colour"></span>
          </td>
        </ng-container>
        <ng-container matColumnDef="Delivered">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Delivered</th>
          <td
            *matCellDef="let row"
            (click)="openMessageView(row['ID'], row['Type'])"
            mat-cell
            matTooltip=" {{ row['Date.sent'] | customDate }} {{ row['Time.sent'] }}"
          >
            {{ row['Date.sent'] | customDate }} {{ row['Time.sent'] }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Actions" stickyEnd>
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Actions</th>
          <td *matCellDef="let row" mat-cell>
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button *ngIf="row['ID']" (click)="openMessageView(row['ID'], row['Type'])" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span>Message View</span>
                </button>
              </mat-menu>
            </div>
          </td>
        </ng-container>
        <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
        <!--        <mat-row *matRowDef="let row; columns: displayedColumns;" [ngStyle]="{'border-top': '2px solid ' + actionLogUtil.getSubjectColor(row['Action'], row['Subject'])}"-->
        <!--          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="listRow separatedRow"></tr>-->
      </table>
      <mat-paginator
        #paginator
        [length]="dataSource.filteredData.length"
        [pageIndex]="0"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
      >
      </mat-paginator>
    </mat-card>
  </div>
</div>
<div *ngIf="listDB.data === undefined || (listDB.data.length == 0 && listDB.firstLoadEvent != false)">
  <app-empty-list-message></app-empty-list-message>
</div>
