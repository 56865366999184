import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toNumberArray',
  pure: false,
})
export class ToNumberArrayPipe implements PipeTransform {
  transform(expression: any, format?: string): any {
    try {
      if (Array.isArray(expression) && !format) {
        const result = [];
        for (let i = 0; i < expression.length; i++) {
          result.push(Number(expression[i]));
        }

        return result;
      } else if (Array.isArray(expression) && format) {
        const result = [];
        for (let i = 0; i < expression.length; i++) {
          if (expression[i] && expression[i][format]) {
            result.push(Number(expression[i][format]));
          }
        }

        return result;
      }
    } catch (e) {
      return expression;
    }
  }
}
