<div class="row clearfix consumer-slider">
  <div class="half-width">
    <div class="col-left row clearfix rel inlined-label flex">
      <label class="slider-label half-width inline accent-color">
        Repayment Period
        <!-- <span class="smallish">{{durationLabel()}}</span> -->
      </label>
      <mat-form-field class="inline half-width slider-inputs">
        <input
          [(ngModel)]="period"
          [min]="minAmount"
          [max]="maxAmount"
          (change)="RepayPeriod()"
          matInput
          name="amountRequired"
          type="number"
          placeholder=""
        />
      </mat-form-field>
    </div>
  </div>
  <div class="half-width">
    <div class="col-right">
      <div class="row clearfix rel">
        <!-- range slider for repayment period -->
        <mat-slider
          class="full-width"
          [(ngModel)]="period"
          [min]="minPeriod"
          [max]="maxPeriod"
          (change)="selectRepayPeriod($event)"
          name="amountRequiredd"
          step="1"
          thumbLabel
        ></mat-slider>
        <p class="min-amount small">{{ minPeriod }} {{ timeLabel() }}</p>
        <p class="max-amount small">{{ maxPeriod }} {{ timeLabel() }}</p>
      </div>
    </div>
  </div>
</div>
