<div class="container">
  <div class="row clearfix contact-header text-center">
    <p class="lead rm-mb shadow-text">Use the form below to get in touch with {{ brandName }} via. E-mail.</p>
    <p class="small shadow-text">Alternatively, our direct contact details can be found on the next tab</p>
    <div class="deep primary-gradient"></div>
    <div class="deep site_background"></div>
  </div>
  <form class="row clearfix" #contactForm="ngForm">
    <div class="row clearfix" *ngIf="messageSent == false">
      <div class="half-width">
        <div class="column">
          <div class="icons-col">
            <mat-icon>email</mat-icon>
          </div>
          <div class="row-content-col">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Email</mat-label>
              <input
                class="mat-block"
                [(ngModel)]="messageDetails.email"
                name="email"
                matInput
                placeholder="Email "
                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                required
              />
            </mat-form-field>
          </div>
          <div class="icons-col">
            <mat-icon>person</mat-icon>
          </div>
          <div class="row-content-col">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>First name</mat-label>
              <input
                class="mat-block"
                [(ngModel)]="messageDetails.firstName"
                name="firstname"
                matInput
                placeholder="First name "
                required
              />
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Last name</mat-label>
              <input
                class="mat-block"
                [(ngModel)]="messageDetails.lastName"
                name="lastname"
                matInput
                placeholder="Last name "
                required
              />
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>What is your enquiry about?</mat-label>
              <input
                class="mat-block"
                [(ngModel)]="messageDetails.subject"
                name="subject"
                matInput
                placeholder="What is your enquiry about?"
                required
              />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="half-width">
        <div class="column">
          <div class="icons-col">
            <mat-icon>announcement</mat-icon>
          </div>
          <div class="row-content-col">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Your Message</mat-label>
              <textarea
                class="messageArea"
                [(ngModel)]="messageDetails.body"
                name="message"
                matInput
                placeholder="Your Message"
                required
              ></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="row clearfix text-center animated fadeInDown" *ngIf="messageSent == true">
      <h2 class="sm-h2 sr-title">Thanks!</h2>
      <p>Your message has been sent... A representitive has been contacted and will get back to you shortly!</p>
    </div>
    <div class="full-width text-center step-button-row" *ngIf="messageSent == false">
      <button
        class="btn-large contact-us"
        [disabled]="!contactForm.form.valid"
        (click)="contactus()"
        mat-raised-button
        type="submit"
        color="accent"
        awNextStep
      >
        <mat-icon>email</mat-icon> Send message
      </button>
    </div>
  </form>
</div>
