import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { DocumentService } from '../../document/shared/document.service';

@Component({
  selector: 'app-quotation-document-create',
  templateUrl: './quotation-document-create.component.html',
  styleUrls: ['./quotation-document-create.component.css'],
})
export class QuotationDocumentCreateComponent implements OnInit {
  @Input()
  cardID;

  @Input()
  cardType;

  @Input()
  group;

  @Output()
  close = new EventEmitter();

  @Output()
  create = new EventEmitter();

  @Output()
  getDocument = new EventEmitter();

  @Input()
  multiCreate = false;

  description;

  thumbnailFileID;

  isRequired = false;

  documentTypes = [
    { name: 'Document', icon: 'fa-file-alt', code: 'fileID' },
    { name: 'External Page', icon: 'fa-link', code: 'link' },
    { name: 'Video', icon: 'fa-file-video', code: 'video' },
  ];

  isModal = false;
  isAdminOrPromoter = false;

  isUploadCompleted = false;
  documentGroups = [];

  cards = [];

  fileID;
  url;
  label;
  deccription;
  tags;
  type;

  document;

  hideCategory = false;
  hideType = false;
  hideBrand = false;
  hideProduct = false;

  productLabel;
  brandLabel;
  treatment;

  programAreaCode = 'MER';

  cardTitle = 'Document Owner ';

  constructor(
    private authenticationService: AuthenticationService,
    private documentService: DocumentService,
    private activeRoute: ActivatedRoute,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.cardID) {
        this.cardID = data.cardID;
      }

      if (data.programAreaCode) {
        this.programAreaCode = data.programAreaCode;
      }

      if (data.multiCreate != null) {
        this.multiCreate = data.multiCreate;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;

        this.setup();
      });
    });
  }

  setup() {
    const payload = {
      orderBy: 'Label',
      fields: 'ID,Label,TypeCode,DefaultProgramArea',
      // programAreaCode: "MER"
    };
    this.documentService.getDocumentTypeList(payload).subscribe((res) => {
      if (res) {
        this.documentGroups = res;
      }
    });
  }

  changeGroup() {
    const payload = {
      fields: 'ID,CalculatedName',
    };

    if (this.group) {
      if (this.group == 'SRVSPL') {
        this.documentService.getSupplierList(payload, this.isAdminOrPromoter).subscribe((res) => {
          if (res) {
            this.cards = res;

            this.cardTitle = 'Supplier';
          }
        });
      } else {
        this.documentService.getMerchantList(payload).subscribe((res) => {
          if (res) {
            this.cards = res;

            this.cardTitle = 'Merchant';
          }
        });
      }
    }
  }

  onCompleteAllFile(e) {
    if (e && e[0]) {
      this.fileID = e[0];
    }
  }

  checkUploadCompleted(e) {
    this.isUploadCompleted = e;
  }

  removeNewTreatmentDocument(e) {
    if (e && e._id == this.fileID) {
      this.fileID = null;
    }
  }

  closeModal() {
    this.close.emit(true);
  }

  createNewDoc() {
    if (this.multiCreate == false) {
      const payload = {
        cardID: this.cardID,
        label: this.label,
        deccription: this.deccription,
        tags: this.tags,
        type: this.group,
        url: this.url,
        fileID: this.fileID,
        thumbnailFileID: this.thumbnailFileID,
      };

      this.documentService.createDocument(payload, this.isAdminOrPromoter).subscribe((res) => {
        if (res) {
          this.create.emit(res);

          NotifyAppComponent.displayToast('success', 'Successful operation', 'Document has been successfully added');
        }
      });
    } else {
      const payload = {
        cardID: this.cardID,
        label: this.label,
        deccription: this.deccription,
        tags: this.tags,
        type: this.group,
        url: this.url,
        fileID: this.fileID,
        thumbnailFileID: this.thumbnailFileID,
      };

      this.getDocument.emit(payload);
    }
  }

  onCompleteAllFileThumbnail(e) {
    if (e && e[0]) {
      this.thumbnailFileID = e[0];
    }
  }

  removeNewTreatmentDocumentThumbnail(e) {
    if (e && e._id == this.thumbnailFileID) {
      this.thumbnailFileID = null;
    }
  }
}
