<div class="card-header primary-gradient-img clearfix inInclusion inModal">
  <div class="row clearfix">
    <!-- Modal dismiss button -->
    <button class="btn-close btn-clear mat-button white" matDialogClose>
      <mat-icon>clear</mat-icon>
    </button>
    <!-- merchant calculated name -->
    <div class="row titleArea clearfix">
      <mat-icon class="fas fa-cart-plus pull-left"></mat-icon>
      <h2 class="summary-header rm-m">
        {{ componentTitle }}
        <span class="subLabel">Add extra inclusions</span>
      </h2>
    </div>
  </div>
</div>
<div class="row clearfix">
  <app-swipe-cards (close)="closeModalEvent()"></app-swipe-cards>
</div>
