import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, HostBinding, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthenticationService } from '../../../common/core/authentication/shared/authentication.service';
import { ContactInputComponent } from '../../../common/feature/contact-us/contact-input/contact-input.component';
import { ContactUsComponent } from '../../../common/feature/contact-us/contact-us/contact-us.component';
import { DentistViewComponent } from '../../../common/feature/dentist/dentist-view/dentist-view.component';
import { MerchantViewComponent } from '../../../common/feature/merchant/merchant-view/merchant-view.component';
import { UserDataService } from '../../../common/shared/services/user-data.service';
import { SessionType } from '../../../common/shared/types/session-type.type';
import { MessageGroupViewComponent } from '../../../common/feature/message/message-group-view/message-group-view.component';
import { SmsConversationComponent } from '../../../common/feature/message/sms-conversation/sms-conversation.component';
import { Settings } from '../../../common/shared/types/settings';
import { SettlementNewModalComponent } from '../../../common/feature/settlement/settlement-new-modal/settlement-new-modal.component';
import { SettlementListOverViewComponent } from '../../../common/feature/settlement/settlement-list-overview/settlement-list-overview.component';

@Component({
  selector: 'app-merchant-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppMerchantComponent implements OnInit {
  @HostBinding('class') componentCssClass: string;

  showThemeSwitch = false;

  sessionType: SessionType;

  customPercent = 19;
  percent = 75;
  currentUser;
  isCurrentUser = false;
  isCurrentPractice = false;
  isCurrentAccess = false;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private userDataService: UserDataService,
    private activeRoute: ActivatedRoute,
    public overlayContainer: OverlayContainer,
    private authenticationService: AuthenticationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    let url = window.location.pathname;

    this.matIconRegistry.addSvgIcon(
      'ai-scans',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/ai.svg')
    );

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
      }
    });

    AuthenticationService.settlementList.subscribe((data) => {
      const ref = this.dialog.open(SettlementListOverViewComponent, {
        data: data,
        width: '90vw',
        panelClass: ['max-width-panel-90vw'],
      });

      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    });
    AuthenticationService.newSettlement.subscribe((data) => {
      if (Settings.global['materialRequestActive'] !== true) {
        if (data.customerID) {
          this.router.navigate(['/merchant', { outlets: { page: ['settlement-create/customer/', data.customerID] } }]);
        } else if (data.contractID) {
          if (data.settlementTransactionToken) {
            this.router.navigate([
              '/merchant',
              { outlets: { page: ['settlement-create/contract', data.contractID, data.settlementTransactionToken] } },
            ]);
          } else {
            this.router.navigate(['/merchant', { outlets: { page: ['settlement-create', data.contractID] } }]);
          }
        } else {
          this.router.navigate(['/merchant', { outlets: { page: ['settlement-create'] } }]);
        }
      } else {
        const ref2 = this.dialog.open(SettlementNewModalComponent, {
          data: data,
          width: '700px',
        });
        const sub = ref2.componentInstance.close.subscribe((data) => {
          ref2.close();
        });
      }
    });

    AuthenticationService.SMSConversation.subscribe((data) => {
      if (data) {
        const ref2 = this.dialog.open(SmsConversationComponent, {
          data: data,
          panelClass: 'bigger-screen',
          width: '70%',
          height: '95vh',
        });
        const sub = ref2.componentInstance.close.subscribe((data) => {
          ref2.close();
        });
      }
    });

    AuthenticationService.serverUnreachable.subscribe((responseCode) => {
      if (this.isCurrentPractice !== true && this.isCurrentAccess !== true && this.isCurrentUser !== true) {
        if (responseCode === '510') {
          setTimeout(() => {
            this.isCurrentPractice = true;
            this.isCurrentAccess = true;
            this.isCurrentUser = true;
          }, 500);
        } else {
          setTimeout(() => {
            this.isCurrentPractice = true;
            this.isCurrentAccess = true;
            this.isCurrentUser = true;
          }, 500);
        }
      }
    });

    this.loader();

    this.authenticationService.getCurrentPractice().subscribe(() => {
      this.percent = this.percent + 1;
      this.isCurrentPractice = true;

      this.displayPasswordReset();
    });

    this.authenticationService.getCurrentAccess().subscribe(() => {
      if (this.percent < 97) {
        this.percent = this.percent + 2;
        this.customPercent = 0;
      } else if (this.percent < 98) {
        this.percent = this.percent + 1;
        this.customPercent = 0;
      }

      this.isCurrentAccess = true;

      this.displayPasswordReset();
    });

    this.authenticationService.getCurrentUser().subscribe((res) => {
      this.percent = this.percent + 1;
      this.isCurrentUser = true;

      if (res && res.data) {
        this.currentUser = res.data;

        this.displayPasswordReset();
      }
    });

    this.authenticationService.getCurrentDentist().subscribe(() => {
      this.percent = this.percent + 1;
    });

    this.router.errorHandler = (err) => {
      try {
        if (url && this.isEncoded(url) === true) {
          url = decodeURI(url);
          this.router.navigateByUrl(url).then();
        } else {
          this.router.navigate(['404']).then();
        }
      } catch (e) {
        this.router.navigate(['404']).then();
      }
    };
  }

  isEncoded(text: string | undefined) {
    let result = false;

    try {
      if (typeof text === 'string' && decodeURI(text) !== text) {
        result = true;
      }

      return result;
    } catch (error) {
      return result;
    }
  }

  displayPasswordReset() {
    if (
      this.currentUser &&
      this.currentUser['operatorID'] &&
      this.isCurrentPractice == true &&
      this.isCurrentAccess == true &&
      this.isCurrentUser == true &&
      this.currentUser.PasswordsNeedsResetting === '1'
    ) {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['password-reset-required'],
          },
        },
      ]);
    }
  }

  generateRandomInteger(min: number, max: number) {
    return Math.floor(min + Math.random() * (max - min + 1));
  }

  innerLogic() {
    const random = Number(this.generateRandomInteger(1, 2));
    this.percent = this.percent + 1;
    this.customPercent = this.customPercent - 1;
    if (this.customPercent > 0 && this.percent < 99) {
      setTimeout(() => {
        this.innerLogic();
      }, random * 1000);
    } else {
      return null;
    }
  }

  loader() {
    this.innerLogic();
  }

  ngOnInit() {
    AuthenticationService.viewDentist.subscribe((data: any) => {
      const dentistView = this.dialog.open(DentistViewComponent, {
        width: '600px',
        data,
      });

      dentistView.componentInstance.close.pipe(take(1)).subscribe(() => {
        dentistView.close();
      });
    });

    AuthenticationService.viewMerchant.subscribe((data: any) => {
      const merchantView = this.dialog.open(MerchantViewComponent, {
        width: '600px',
        data,
      });

      merchantView.componentInstance.close.pipe(take(1)).subscribe(() => {
        merchantView.close();
      });
    });
    AuthenticationService.displayMessageGroup.subscribe((id) => {
      if (id) {
        const ref = this.dialog.open(MessageGroupViewComponent, {
          data: {
            messageGroupID: id,
            noAction: true,
          },
          panelClass: ['noCard', 'max-width-panel-1100'],
          width: '90vw',
        });
        ref.componentInstance.close.subscribe((res) => {
          ref.close();
        });
      }
    });
    AuthenticationService.contactInputEvent.subscribe((data: any) => {
      const contactInput = this.dialog.open(ContactInputComponent, {
        width: '650px',
        data,
      });

      contactInput.componentInstance.closeModal.pipe(take(1)).subscribe(() => {
        contactInput.close();
      });
    });

    AuthenticationService.contactUSEvent.subscribe(() => {
      this.dialog.open(ContactUsComponent, {
        width: '800px',
      });
    });

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (event.url) {
          let activatedRoute = this.activeRoute;

          while (activatedRoute.firstChild) {
            activatedRoute = activatedRoute.firstChild;
          }

          activatedRoute.params.subscribe((params) => {
            if (params.invitationID || params.consumerID) {
              let id;

              if (params.invitationID) {
                id = params.invitationID;
              } else if (params.consumerID) {
                id = params.consumerID;
              }

              this.authenticationService.getSessionType().subscribe((sessionType) => {
                this.sessionType = sessionType;
                this.userDataService.getUserData(id, this.sessionType);
              });
            }
          });
        }
      }
    });
  }

  onSetTheme(theme: string) {
    this.overlayContainer.getContainerElement().classList.add(theme);
    this.componentCssClass = theme;
  }
}
