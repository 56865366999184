import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppStateService } from '../../../core/app-state/app-state.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { DataResponse } from '../../../shared/types/data-response.type';
import {
  RevenueReport,
  RevenueReportRequest,
} from '../../funders/funders-revenue-report/shared/types/revenue-report.type';
import { ContractStatement, ContractStatementRequest } from './types/contract-statement.type';
import { DueSettlementsReport, DueSettlementsReportRequest } from './types/due-settlements-report.type';
import { MerchantFeeReport, MerchantFeesReportRequest } from './types/merchant-fees-report.type';
import { PatientPaymentReport, PatientPaymentReportRequest } from './types/patient-payment-report.type';
import { ScheduleOfSettlements, ScheduleOfSettlementsRequest } from './types/schedule-of-settlement.type';
import { Settlement, SettlementQuery } from './types/settlement.type';

class SettlementState {
  settlements: Partial<Settlement>[] | Settlement[];
  settlement: Partial<Settlement> | Settlement;
  contractStatement: ContractStatement;
  scheduleOfSettlements: ScheduleOfSettlements;
  patientPaymentReport: PatientPaymentReport;
  merchantFeeReport: MerchantFeeReport;
  dueSettlementsReport: DueSettlementsReport;
  revenueReport: RevenueReport;
}

@Injectable()
export class SettlementService extends ComponentStore<SettlementState> {
  private baseUrl = environment.nodeUrl;

  constructor(
    private httpClient: HttpClient,
    private utilService: UtilsService,
    private appStateService: AppStateService
  ) {
    super({
      settlements: [],
      settlement: null,
      contractStatement: null,
      scheduleOfSettlements: null,
      patientPaymentReport: null,
      merchantFeeReport: null,
      dueSettlementsReport: null,
      revenueReport: null,
    });
  }

  getSettlements$<T extends Settlement | Partial<Settlement>>() {
    return this.select((state) => state.settlements) as Observable<T[]>;
  }

  setSettlements(settlements: Partial<Settlement>[] | Settlement[]) {
    this.patchState({ settlements });
  }

  fetchSettlements$<T extends Settlement | Partial<Settlement>>(payload: SettlementQuery['payload']) {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const params = this.utilService.getHttpParamsFromPayload(payload);

        const options = { params };

        const endpoint = appState.isPromoterOrAdmin ? 'drawDown/global' : 'drawDown';
        const url = `${this.baseUrl}/${endpoint}`;

        return this.httpClient.get<DataResponse<T[]>>(url, options).pipe(map((response) => response['data']));
      })
    );
  }

  getPatientPaymentReport$() {
    return this.select((state) => state.patientPaymentReport);
  }

  setPatientPaymentReport(patientPaymentReport: PatientPaymentReport) {
    this.patchState({ patientPaymentReport });
  }

  fetchPatientPaymentReport$(payload: PatientPaymentReportRequest, isPromoterOrAdmin = false) {
    const endpoint = isPromoterOrAdmin ? 'drawDown/patient-payment-report/global' : 'drawDown/patient-payment-report';

    const url = `${this.baseUrl}/${endpoint}`;

    return this.httpClient
      .post<DataResponse<PatientPaymentReport>>(url, payload)
      .pipe(map((response) => response['data']));
  }

  getMerchantFeeReport$() {
    return this.select((state) => state.merchantFeeReport);
  }

  setMerchantFeeReport(merchantFeeReport: MerchantFeeReport) {
    this.patchState({ merchantFeeReport });
  }

  fetchMerchantFeeReport$(payload: MerchantFeesReportRequest, isPromoterOrAdmin = false) {
    const endpoint = isPromoterOrAdmin ? 'drawDown/merchant-fee-report/global' : 'drawDown/merchant-fee-report';

    const url = `${this.baseUrl}/${endpoint}`;

    return this.httpClient
      .post<DataResponse<MerchantFeeReport>>(url, payload)
      .pipe(map((response) => response['data']));
  }

  getDueSettlementsReport$() {
    return this.select((state) => state.dueSettlementsReport);
  }

  setDueSettlementsReport(dueSettlementsReport: DueSettlementsReport) {
    this.patchState({ dueSettlementsReport });
  }

  fetchDueSettlementsReport$(payload: DueSettlementsReportRequest) {
    const endpoint = 'drawDown/due-settlements-report/global';

    const url = `${this.baseUrl}/${endpoint}`;

    return this.httpClient
      .post<DataResponse<DueSettlementsReport>>(url, payload)
      .pipe(map((response) => response['data']));
  }

  getContractStatement$() {
    return this.select((state) => state.contractStatement);
  }

  setContractStatement(contractStatement: ContractStatement) {
    this.patchState({ contractStatement });
  }

  fetchContractStatement$(payload: ContractStatementRequest, isPromoterOrAdmin = false) {
    const endpoint = isPromoterOrAdmin ? 'drawDown/contract-statement/global' : 'drawDown/contract-statement';

    const url = `${this.baseUrl}/${endpoint}`;

    return this.httpClient
      .post<DataResponse<ContractStatement>>(url, payload)
      .pipe(map((response) => response['data']));
  }

  getScheduleOfSettlements$() {
    return this.select((state) => state.scheduleOfSettlements);
  }

  setScheduleOfSettlements(scheduleOfSettlements: ScheduleOfSettlements) {
    this.patchState({ scheduleOfSettlements });
  }

  fetchScheduleOfSettlements$(payload: ScheduleOfSettlementsRequest, isPromoterOrAdmin = false) {
    const endpoint = isPromoterOrAdmin
      ? 'drawDown/contract-settlement-schedule/global'
      : 'drawDown/contract-settlement-schedule';
    const url = `${this.baseUrl}/${endpoint}`;

    return this.httpClient
      .post(url, payload)
      .pipe(map((response: DataResponse<ScheduleOfSettlements>) => response['data']));
  }

  getRevenueReport$() {
    return this.select((state) => state.revenueReport);
  }

  setRevenueReport(revenueReport: RevenueReport) {
    this.patchState(() => ({ revenueReport }));
  }

  fetchRevenueReport$(payload: RevenueReportRequest) {
    const endpoint = 'drawDown/revenue-report/global';
    const url = `${this.baseUrl}/${endpoint}`;

    return this.httpClient.post(url, payload).pipe(map((response: DataResponse<RevenueReport>) => response['data']));
  }

  statistics(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = {
        params,
      };
    }

    return this.httpClient
      .get(environment.nodeUrl + '/drawDown/statistics/global', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getSettlementDetails(ID, payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.httpClient
      .get(environment.nodeUrl + '/drawDown/' + ID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getDocuments(ID, payload, isAdminOrPromoter = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    if (isAdminOrPromoter === true) {
      return this.httpClient
        .get(environment.nodeUrl + '/drawDown/document/global/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/drawDown/document/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  cancel(id, isAdminOrPromoter = false, applyGroup = false): Observable<any> {
    const p = {
      applyGroup,
    };

    if (isAdminOrPromoter === true) {
      return this.httpClient
        .put(`${environment.nodeUrl}/drawDown/cancel/global/${id}`, p)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .put(`${environment.nodeUrl}/drawDown/cancel/${id}`, p)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  void(id, isAdminOrPromoter = false): Observable<any> {
    if (isAdminOrPromoter === true) {
      return this.httpClient
        .put(`${environment.nodeUrl}/drawDown/void/global/${id}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .put(`${environment.nodeUrl}/drawDown/void/${id}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  awaitingMerchantResponse(id): Observable<any> {
    return this.httpClient
      .put(`${environment.nodeUrl}/drawDown/awaitingResponse/global/${id}`, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  reMarkRequest(id, isAdminOrPromoter = false, body = {}): Observable<any> {
    if (isAdminOrPromoter === true) {
      return this.httpClient
        .put(`${environment.nodeUrl}/drawDown/reMarkRequest/global/${id}`, body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .put(`${environment.nodeUrl}/drawDown/reMarkRequest/${id}`, body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  approve(id, applyGroup = false): Observable<any> {
    const p = {
      applyGroup,
    };

    return this.httpClient
      .put(`${environment.nodeUrl}/drawDown/approve/global/${id}`, p)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  decline(id, message, applyGroup = false): Observable<any> {
    const p = {
      reason: message,
      applyGroup,
    };
    return this.httpClient
      .put(`${environment.nodeUrl}/drawDown/decline/global/${id}`, p)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getSettlementList(payload, isAdminOrPromoter = false): Observable<Settlement[]> {
    const params = this.utilService.getHttpParamsFromPayload(payload);

    const options = { params };

    const endpoint = isAdminOrPromoter ? 'drawDown/global' : 'drawDown';
    const url = `${this.baseUrl}/${endpoint}`;

    return this.httpClient.get(url, options).pipe(map((res: HttpResponse<any>) => res['data']));
  }

  canDrawDown(contractID): Observable<any> {
    return this.httpClient
      .get(environment.nodeUrl + '/drawDown/can-draw-down/' + contractID)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getAuthorisationForm(contractID, payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    return this.httpClient
      .get(`${environment.nodeUrl}/drawDown/authorisation-form/${contractID}`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getSettlementListGlobal(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    return this.httpClient
      .get(`${environment.nodeUrl}/drawDown/global`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createSettlement(settlement: any, isAdminOrPromoter = false): Observable<any> {
    if (isAdminOrPromoter === true) {
      return this.httpClient
        .post(`${environment.nodeUrl}/drawDown/global`, settlement)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .post(`${environment.nodeUrl}/drawDown/`, settlement)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  createRefund(settlement: any, isAdminOrPromoter = false): Observable<any> {
    if (isAdminOrPromoter === true) {
      return this.httpClient
        .post(`${environment.nodeUrl}/drawDown/refund/global`, settlement)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .post(`${environment.nodeUrl}/drawDown/refund`, settlement)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  createInvoiceSettlement(id, settlement: any): Observable<any> {
    return this.httpClient
      .put(`${environment.nodeUrl}/drawDown/invoice/${id}`, settlement)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  requestSettlementCode(payload): Observable<any> {
    return this.httpClient
      .post(`${environment.nodeUrl}/drawDown/code`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  validateSettlementCode(payload): Observable<any> {
    return this.httpClient
      .put(`${environment.nodeUrl}/drawDown/code`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editSettlement(id, settlement: any): Observable<any> {
    return this.httpClient
      .put(`${environment.nodeUrl}/drawDown/${id}`, settlement)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  finalizeSettlement(id: any): Observable<any> {
    return this.httpClient
      .put(`${environment.nodeUrl}/drawDown/${id}/finalize`, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  requestSettlementByForm(id, body): Observable<any> {
    return this.httpClient
      .put(`${environment.nodeUrl}/drawDown/authorisation-form/${id}`, body)
      .map((res: HttpResponse<any>) => res['data']);
  }

  sendReuploadDocMessage(id, data) {
    return this.httpClient.post(`${environment.nodeUrl}/drawDown/re-upload-document/${id}`, data);
  }

  triggerWebhook(id, code, transactionToken = null, transactionWebhookURL = null): Observable<any> {
    const payload = {
      code,
      transactionToken,
      transactionWebhookURL,
    };
    return this.httpClient.post(`${environment.nodeUrl}/drawDown/web-hook/${id}`, payload);
  }

  getSettlementDataByToken(token, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin === true) {
      return this.httpClient
        .get(environment.nodeUrl + '/drawDown/data/token/global/' + token, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/drawDown/data/token/' + token, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  verifyMaterialRequest(payload, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (sessionType === 'admin' || sessionType === 'promoter') {
      return this.httpClient
        .get(environment.nodeUrl + '/drawDown/matrial-request-verify/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/drawDown/matrial-request-verify', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  cancelSettlementGroup(payload, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .put(`${environment.nodeUrl}/drawDown/cancel/group/global`, payload)
        .pipe(map((res: Response) => res['data']));
    } else {
      return this.httpClient
        .put(`${environment.nodeUrl}/drawDown/cancel/group`, payload)
        .pipe(map((res: Response) => res['data']));
    }
  }

  deleteSettlementGroup(ids, sessionType): Observable<any> {
    let _ids;
    if (Array.isArray(ids) == true) {
      _ids = ids.join(',');
    } else if (typeof ids == 'string') {
      _ids = ids;
    }
    if (_ids) {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.httpClient
          .delete(environment.nodeUrl + '/drawDown/group/global/' + _ids)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .delete(environment.nodeUrl + '/drawDown/group/' + _ids)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

}
