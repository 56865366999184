import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { MedicalHistoryService } from '../shared/medical-history.service';

@Component({
  selector: 'app-illness-create-edit',
  templateUrl: './illness-create-edit.component.html',
  styleUrls: ['./illness-create-edit.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('200ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('200ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class IllnessCreateEditComponent implements OnInit {
  // @Input()
  // medicalHistoryID = "262DC7B73DEEFD45A7A03CE37DF2D9D1";
  @Input()
  medicalHistoryID;
  @Input()
  illness;

  @Input()
  illnessID;

  @Output()
  closeEvent = new EventEmitter();

  @Output()
  getCreated = new EventEmitter();

  @Output()
  getEdited = new EventEmitter();
  @Output()
  getIllness = new EventEmitter();
  categories = [];
  illnessObject = {
    medicalHistoryID: null,
    typeCode: null,
    description: null,
    firstOccurDate: null,
    isOngoing: null,
    ceaseDate: null,
    medicationTaken: null,
    medicationFrequencyCode: null,
    medications: [],
  };
  isModuleMedicationActive = Settings.global['isModuleMedicationActive'];

  isModal = true;

  _medications = [];
  selectedDate;
  config = {
    format: 'MMMM, YYYY',
    monthFormat: 'MMMM, YYYY',
    drops: 'down',
  };
  title;
  isEdit = false;

  sessionType = 'merchant';
  medicationExist = false;
  medications = [];
  startDate;
  stopDate;
  isLoaded = false;

  constructor(
    private authenticationService: AuthenticationService,
    private medicalHistoryService: MedicalHistoryService,
    private utilService: UtilsService,
    private lookupService: LookupService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.medicalHistoryID) {
        this.medicalHistoryID = data.medicalHistoryID;
      }

      if (data.illness) {
        this.illness = data.illness;
      }

      if (data.illnessID) {
        this.illnessID = data.illnessID;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      this.utilService.getCurrentAccess().subscribe((access) => {
        if (access) {
          this.isModuleMedicationActive = access['isModuleMedicationActive'];
        }
      });

      this.utilService.getCodeLookup('Illness').subscribe((categories) => {
        if (categories) {
          this.categories = categories;
        }
      });

      if (this.illnessID) {
        this.medicalHistoryService
          .getIllnessObject(this.illnessID, { selectMedication: true }, this.sessionType)
          .subscribe((res) => {
            if (res) {
              this.isEdit = true;

              if (res['Type.Label']) {
                this.title = res['Type.Label'];
              }

              if (res && res.medications) {
                this._medications = res.medications;
              }

              if (res['Type.Code']) {
                this.illnessObject.typeCode = res['Type.Code'];
              }

              if (res['Description']) {
                this.illnessObject.description = res['Description'];
              }

              if (res['Description']) {
                this.illnessObject.description = res['Description'];
              }

              if (res['FirstOccurDate']) {
                this.illnessObject.firstOccurDate = moment(res['FirstOccurDate']);
                this.startDate = res['FirstOccurDate'];
              }

              if (res['CeaseDate'] && res['CeaseDate'] != '0000-00-00') {
                this.illnessObject.ceaseDate = moment(res['CeaseDate']);
                this.stopDate = res['CeaseDate'];
              }

              if (res['MedicationFrequency.Code']) {
                this.illnessObject.medicationFrequencyCode = res['MedicationFrequency.Code'];
              }

              if (res['MedicationTaken']) {
                this.illnessObject.medicationTaken = res['MedicationTaken'];
              }

              this.illnessObject.isOngoing = res['IsOngoing'] == '1' ? true : false;
            }

            this.isLoaded = true;
          });
      } else if (this.illness) {
        if (this.illness.Code) {
          this.illnessObject.typeCode = this.illness.Code;
        }

        if (this.illness.Label) {
          this.title = this.illness.Label;
        }

        this.isLoaded = true;
      } else {
        this.isLoaded = true;
      }
    });
  }

  createIllness() {
    this.illnessObject.medicalHistoryID = this.medicalHistoryID;
    this.illnessObject.typeCode = this.illness.Code;

    if (this.illnessObject.isOngoing == true) {
      this.illnessObject.medications = this.medications;
    }

    if (this.illnessObject.isOngoing == true) {
      this.illnessObject.ceaseDate = 'void';
    }

    this.medicalHistoryService.createIllness(this.illnessObject, this.sessionType).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast(
          'success',
          'Successful Operation',
          'You have successfully create your illness history!'
        );

        this.getCreated.emit(res);
      }
    });
  }

  changeTitle() {
    if (this.categories && this.categories.length > 0 && this.illnessObject && this.illnessObject.typeCode) {
      const index = this.categories.findIndex((item) => {
        if (item && item.Code && item.Code == this.illnessObject.typeCode) {
          return true;
        }

        return false;
      });

      if (index != -1 && this.categories[index] && this.categories[index]['Label']) {
        this.title = this.categories[index]['Label'];
      }

      this.illness = this.categories[index];
    }
  }

  modifyIllness() {
    if (this.illnessObject.isOngoing == true) {
      this.illnessObject.ceaseDate = 'void';
    }
    if (this.illnessObject.isOngoing == true) {
      this.illnessObject.medications = this.medications;
    }

    this.medicalHistoryService.modifyIllness(this.illnessID, this.illnessObject, this.sessionType).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast(
          'success',
          'Successful Operation',
          'You have successfully updated your illness history!'
        );
        this.getEdited.emit(res);
      }
    });
  }

  close() {
    this.closeEvent.emit(true);
  }

  getNewMedications(d = []) {
    this.medications = d;
  }

  getMedicationExist(e) {
    this.medicationExist = e;
  }

  selectDateToFilter(e, key) {
    this.illnessObject[key] = e;
  }
}
