<div class="rel">
  <div class="card-header primary-gradient-img clearfix confirmModal" *ngIf="isModal == true">
    <button
      class="btn-close btn-clear mat-button smt white custom-close"
      [ngClass]="!title ? 'no-title-close' : ''"
      (click)="closeEvent()"
    >
      <mat-icon>clear</mat-icon>
    </button>
    <div class="full-width flex main-title-container" [ngClass]="!title ? 'main-icon-container-no-title' : ''">
      <div class="main-icon-container text-center">
        <mat-icon class="main-icon accent-color {{ icon }}"></mat-icon>
      </div>
      <div class="row full-width title-container clearfix">
        <div class="title-container-sub">
          <h2 class="text-left title-header rm-m">
            {{ title }}
            <span class="subLabel" *ngIf="description">{{ description }}</span>
          </h2>
        </div>
      </div>
    </div>
  </div>

  <mat-dialog-content [ngClass]="{ 'no-modal-height': isModal != true }">
    <div class="full-width" *ngIf="isPromoterOrAdmin != true || isMerchant != true">
      <mat-form-field class="pull-left mr mt" style="width: 200px" appearance="outline">
        <mat-label>Integration Type</mat-label>
        <mat-select [(ngModel)]="integrationType" name="integrationType" placeholder="Integration Type">
          <mat-option [value]="'embeded'"> Embedded</mat-option>
          <mat-option [value]="'link'"> Link</mat-option>
          <mat-option [value]="'popup'"> Popup</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <h3 class="sr-title full-width smt" *ngIf="isPromoterOrAdmin == true && isMerchant == true">
      Type & {{ "KEYWORD.practice" | translate | titlecase}}
    </h3>

    <div class="full-width flex" *ngIf="isPromoterOrAdmin == true && isMerchant == true">
      <mat-form-field class="half-width mr" appearance="outline">
        <mat-label>Integration Type</mat-label>
        <mat-select [(ngModel)]="integrationType" name="integrationType" placeholder="Integration Type">
          <mat-option [value]="'embeded'"> Embedded</mat-option>
          <mat-option [value]="'link'"> Link</mat-option>
          <mat-option [value]="'popup'"> Popup</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="full-width" appearance="outline">
        <mat-label>{{ "KEYWORD.practice" | translate | titlecase}}</mat-label>

        <mat-select [(ngModel)]="merchantID" [placeholder]="'KEYWORD.Practice' | translate" name="linkFontSize">
          <mat-option *ngFor="let m of merchants" [value]="m.ID">
            {{ m.TradingAs }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <h3 class="sr-title full-width smt">Content Configuration</h3>

    <div class="full-width" *ngIf="inputType == 'calculator'">
      <div class="full-width smb">
        <mat-checkbox
          class="pull-left mt sml mr"
          [(ngModel)]="options.displaySlider"
          name="isMerchant"
          style="width: 300px"
        >
          Display Calculator Slider
        </mat-checkbox>

        <mat-checkbox
          class="pull-left mt mr sml"
          [(ngModel)]="options.addBackground"
          name="isMerchant"
          style="width: 300px"
        >
          Add Background Colour
        </mat-checkbox>

        <input
          class="color-input-sub pull-left"
          *ngIf="options.addBackground == true"
          [(colorPicker)]="options.background"
          [style.background]="options.background"
          [cpOutputFormat]="'hex'"
          name="addBackground"
        />
        <p class="mr rm-mb sml pull-left" *ngIf="options.addBackground == true">Background Colour</p>
      </div>

      <mat-form-field class="full-width smb" *ngIf="options.displaySlider == true" appearance="outline">
        <mat-label>Step 1 Text</mat-label>
        <input [(ngModel)]="options.step1Text" matInput placeholder="Step 1 Text" name="step1Text"/>
      </mat-form-field>

      <mat-form-field class="full-width" *ngIf="options.displaySlider == true" appearance="outline">
        <mat-label>Step 2 Text</mat-label>
        <input [(ngModel)]="options.step2Text" matInput placeholder="Step 2 Text" name="step2Text"/>
      </mat-form-field>

      <div class="full-width smb" *ngIf="options.displaySlider == true">
        <mat-form-field class="pull-left mr" style="width: 170px" appearance="outline">
          <mat-label>Text Font Size</mat-label>
          <mat-select [(ngModel)]="options.TextfontSize" name="buttonColor222" placeholder="Font Size">
            <mat-option *ngFor="let m of buttonFontSizepx" [value]="m">
              {{ m }}
            </mat-option>
            <hr/>
            <mat-option *ngFor="let m of buttonFontSizerem" [value]="m">
              {{ m }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <input
          class="color-input-sub pull-left smt"
          [(colorPicker)]="options.TextColor"
          [style.background]="options.TextColor"
          [cpOutputFormat]="'hex'"
          name="buttonColor2"
        />
        <p class="mr sml mt rm-mb pull-left">Text Colour</p>

        <input
          class="color-input-sub pull-left smt"
          *ngIf="integrationType != 'link'"
          [(colorPicker)]="options.headerColor"
          [style.background]="options.headerColor"
          [cpOutputFormat]="'hex'"
          name="headerColor"
        />

        <p class="mr sml mt rm-mb pull-left" *ngIf="integrationType != 'link'">Header Background</p>
      </div>

      <mat-form-field class="full-width" *ngIf="options.displaySlider != true" appearance="outline">
        <mat-label>Amount</mat-label>
        <input [(ngModel)]="options.amount" type="number" matInput placeholder="Amount" name="Amount"/>
      </mat-form-field>
    </div>

    <div class="full-width" *ngIf="inputType == 'contact' || inputType == 'appointment'">
      <div class="full-width smb">
        <mat-checkbox
          class="pull-left mt mr sml"
          [(ngModel)]="options.addBackground"
          name="isMerchant"
          style="width: 300px"
        >
          Add Background Colour
        </mat-checkbox>

        <input
          class="color-input-sub pull-left"
          *ngIf="options.addBackground == true"
          [(colorPicker)]="options.background"
          [style.background]="options.background"
          [cpOutputFormat]="'hex'"
          name="addBackground"
        />

        <p class="rm-mb sml pull-left" *ngIf="options.addBackground == true">Background Colour</p>
      </div>
    </div>

    <div class="full-width">
      <div class="full-width" *ngIf="integrationType == 'embeded'">
        <mat-form-field class="full-width smt" appearance="outline">
          <mat-label>Description</mat-label>
          <input [(ngModel)]="options.description" matInput placeholder="Description" name="Description"/>
        </mat-form-field>
      </div>

      <div class="full-width" *ngIf="integrationType == 'link'">
        <h3 class="sr-title full-width" *ngIf="inputType != 'contact'">Page Content</h3>

        <mat-form-field class="full-width" *ngIf="inputType != 'contact'" appearance="outline">
          <mat-label>Title</mat-label>
          <input [(ngModel)]="options.title" matInput placeholder="Title" name="Title"/>
        </mat-form-field>

        <mat-form-field class="full-width" *ngIf="inputType != 'contact'" appearance="outline">
          <mat-label>Description</mat-label>
          <input [(ngModel)]="options.description" matInput placeholder="Description" name="Description"/>
        </mat-form-field>

        <h3 class="sr-title full-width">Link Format</h3>

        <div class="full-width flex smb">
          <mat-form-field class="pull-left full-width mr" appearance="outline">
            <mat-label>Link Text</mat-label>
            <input [(ngModel)]="linkText" matInput placeholder="Link Text" name="LinkText"/>
          </mat-form-field>

          <mat-form-field class="pull-left mr" style="width: 170px" appearance="outline">
            <mat-label>Font Size</mat-label>

            <mat-select [(ngModel)]="linkFontSize" name="linkFontSize" placeholder="Font Size">
              <mat-option *ngFor="let m of buttonFontSizepx" [value]="m">
                {{ m }}
              </mat-option>
              <hr/>
              <mat-option *ngFor="let m of buttonFontSizerem" [value]="m">
                {{ m }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <input
            class="color-input-sub pull-left"
            [(colorPicker)]="linkColor"
            [style.background]="linkColor"
            [cpOutputFormat]="'hex'"
            name="linkColor"
          />

          <p class="mr rm-mb sml pull-left">Colour</p>
        </div>

        <div class="full-width mb" style="line-height: 40px">
          <p class="mr rm-mb rm-mt ml pull-left">Link Preview</p>

          <a
            class="preview-link"
            [attr.style]="'color:' + linkColor + ' !important; font-size:' + linkFontSize + ' !important;' | safeStyle"
          >{{ linkText }}</a
          >
        </div>
      </div>

      <div class="full-width" *ngIf="integrationType == 'popup'">
        <h3 class="sr-title full-width">Popup Content</h3>

        <mat-form-field class="full-width" *ngIf="inputType != 'contact'" appearance="outline">
          <mat-label>Title</mat-label>
          <input [(ngModel)]="options.title" matInput placeholder="Title" name="Title"/>
        </mat-form-field>

        <mat-form-field class="full-width" *ngIf="inputType != 'contact'" appearance="outline">
          <mat-label>Description</mat-label>
          <input [(ngModel)]="options.description" matInput placeholder="Description" name="Description"/>
        </mat-form-field>

        <div class="full-width smb">
          <input
            class="color-input-sub pull-left smt"
            *ngIf="inputType == 'contact' || inputType == 'appointment'"
            [(colorPicker)]="options.headerColor"
            [style.background]="options.headerColor"
            [cpOutputFormat]="'hex'"
            name="headerColor"
          />

          <p class="mr sml mt rm-mb pull-left" *ngIf="inputType == 'contact' || inputType == 'appointment'">
            Header Background
          </p>
        </div>

        <h3 class="sr-title full-width">Popup Button</h3>

        <div class="full-width smb">
          <mat-form-field class="pull-left mr" style="width: 300px" appearance="outline">
            <mat-label>Button Text</mat-label>
            <input [(ngModel)]="buttonText" matInput placeholder="Button Text" name="Button Text"/>
          </mat-form-field>

          <mat-form-field class="pull-left mr" style="width: 170px" appearance="outline">
            <mat-label>Font Size</mat-label>

            <mat-select [(ngModel)]="buttonFontSize" name="buttonFontSize" placeholder="Font Size">
              <mat-option *ngFor="let m of buttonFontSizepx" [value]="m">
                {{ m }}
              </mat-option>
              <hr/>
              <mat-option *ngFor="let m of buttonFontSizerem" [value]="m">
                {{ m }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-checkbox
            class="pull-left mt mr sml"
            [(ngModel)]="isLargeButton"
            name="isLargeButton"
            style="width: 150px"
          >
            Large Button
          </mat-checkbox>
        </div>

        <div class="full-width mb">
          <input
            class="color-input-sub pull-left"
            *ngIf="useDefaultColor != true"
            [(colorPicker)]="buttonBackground"
            [style.background]="buttonBackground"
            [cpOutputFormat]="'hex'"
            name="buttonBackground"
          />
          <p class="mr rm-mb sml pull-left">Background Colour</p>

          <input
            class="color-input-sub pull-left"
            *ngIf="useDefaultColor != true"
            [(colorPicker)]="buttonColor"
            [style.background]="buttonColor"
            [cpOutputFormat]="'hex'"
            name="buttonColor"
          />

          <p class="mr rm-mb sml pull-left">Color</p>

          <button
            class="ml pull-left btn-large"
            *ngIf="isLargeButton == true"
            [attr.style]="
              'color:' +
                buttonColor +
                ' !important; background:' +
                buttonBackground +
                ' !important; font-size:' +
                buttonFontSize +
                ' !important;' | safeStyle
            "
            (click)="previewBuild()"
            mat-raised-button
            color="primary"
          >
            {{ buttonText }}
          </button>

          <button
            class="ml pull-left btn"
            *ngIf="isLargeButton != true"
            [attr.style]="
              'color:' +
                buttonColor +
                ' !important; background:' +
                buttonBackground +
                ' !important; font-size:' +
                buttonFontSize +
                ' !important;' | safeStyle
            "
            (click)="previewBuild()"
            mat-raised-button
            color="primary"
          >
            {{ buttonText }}
          </button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <hr/>
  <div class="drop-button text-center mt full-width" *ngIf="isModal == true">
    <button class="pull-right btn-large" (click)="inserResult(false)" mat-raised-button color="accent">Insert</button>
    <button class="pull-right btn-large mr" (click)="inserResult(true)" mat-raised-button color="primary">Copy</button>

    <button
      class="pull-right btn-large mr"
      *ngIf="integrationType == 'popup'"
      (click)="copyClickEvent()"
      mat-raised-button
      color="primary"
    >
      Copy Click Event
    </button>

    <button class="pull-left btn-large" (click)="previewBuild()" mat-raised-button color="primary">Preview</button>
  </div>
</div>
