<div class="full-width clearfix">
  <div class="full-width clearfix flex" *ngIf="tags && tags.length > 0 && hasFilter == true">
    <mat-form-field class="full-width clearfix" *ngIf="tagFormatIsObject != true" appearance="outline">
      <mat-label>{{ title }}</mat-label>
      <input [disabled]="disabled" #tagInput #autoTrigger="matAutocompleteTrigger" [(ngModel)]="tag"
        [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matAutocompletePosition]="openPosition" [matChipInputAddOnBlur]="false" (change)="tagChange($event)"
        (matChipInputTokenEnd)="addTag($event)" matInput name="tags-wiki-model" placeholder="{{ title }}" />

      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let t of tags | FilterArrayMultipleContainValue: '*':tag | orderBy" [value]="t"
          (click)="openPanelEvent($event, autoTrigger)">
          <span class="selected-option-tag-top" *ngIf="isTagSelected(t) != true"> {{ t }}</span>
          <span class="selected-option-tag" *ngIf="isTagSelected(t) == true"> {{ t }}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="full-width clearfix" *ngIf="tagFormatIsObject == true" appearance="outline">
      <mat-label>{{ title }}</mat-label>
      <input [disabled]="disabled" #tagInput #autoTrigger="matAutocompleteTrigger" [(ngModel)]="tag"
        [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matAutocompletePosition]="openPosition" [matChipInputAddOnBlur]="false" (change)="tagChange($event)"
        (matChipInputTokenEnd)="addTag($event)" matInput name="tags-wiki-model2" placeholder="{{ title }}" />

      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option
          *ngFor="let t of tags | FilterArrayMultipleContainValue: '*':tag | OrderArrayObject: tagFormatAttribute"
          [value]="t[tagFormatAttribute]" (click)="openPanelEvent($event, autoTrigger)">
          <span class="selected-option-tag-top" *ngIf="isTagSelected(t[tagFormatAttribute]) != true">
            {{ t[tagFormatAttribute] }}</span>
          <span class="selected-option-tag" *ngIf="isTagSelected(t[tagFormatAttribute]) == true">
            {{ t[tagFormatAttribute] }}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <button class="ml no-mb pull-right create-button" *ngIf="canCreate == true && disabled!=true"
      (click)="createEvent()" matSuffix mat-icon-button color="accent" matTooltip="Click here to add new tag">
      <mat-icon class="fas fa-plus-circle accent-color"></mat-icon>
    </button>


    <button class="ml no-mb pull-right create-button" *ngIf="canCreate == true && disabled==true"
      (click)="createEvent()" matSuffix [disabled]="true" mat-icon-button color="accent">

      <mat-icon class="fas fa-plus-circle " style="color:#a7a7a7"></mat-icon>
    </button>
  </div>

  <div class="full-width clearfix flex" *ngIf="!tags ||(tags && tags.length <= 0)">

    <button class="pull-left btn" (click)="createEvent()" mat-raised-button color="accent">
      New tag <mat-icon class=" fas fa-tag sml" style="font-size:1rem !important;margin-top: 10px;"></mat-icon>
    </button>

  </div>

  <div class="clearfix">
    <mat-chip-list class="main-tag-list" #chipList name="tags-wiki">
      <mat-chip *ngFor="let tag of selectedTags" [selectable]="canSelect" [removable]="canRemove"
        [ngClass]="{ 'default-chip': isDefaultTag(tag) == true }" (removed)="removeTag(tag)" (click)="selectTag(tag)">
        {{ tag }}
        <mat-icon *ngIf="canRemove == true" matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>
</div>
