/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./help-page-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i3 from "@angular/material/tabs";
import * as i4 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i5 from "@angular/material/card";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../help-page-edit/help-page-edit.component.ngfactory";
import * as i8 from "../help-page-edit/help-page-edit.component";
import * as i9 from "../shared/helper.service";
import * as i10 from "@angular/common";
import * as i11 from "./help-page-list.component";
var styles_HelpPageListComponent = [i0.styles];
var RenderType_HelpPageListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HelpPageListComponent, data: {} });
export { RenderType_HelpPageListComponent as RenderType_HelpPageListComponent };
function View_HelpPageListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.title; _ck(_v, 0, 0, currVal_0); }); }
function View_HelpPageListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 9, "mat-tab", [["id", "simplePageTab"]], null, null, null, i2.View_MatTab_0, i2.RenderType_MatTab)), i1.ɵdid(1, 770048, [[1, 4]], 2, i3.MatTab, [i1.ViewContainerRef], null, null), i1.ɵqud(603979776, 2, { templateLabel: 0 }), i1.ɵqud(335544320, 3, { _explicitContent: 0 }), (_l()(), i1.ɵeld(4, 0, null, 0, 5, "mat-card", [["class", "mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i4.View_MatCard_0, i4.RenderType_MatCard)), i1.ɵdid(5, 49152, null, 0, i5.MatCard, [[2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_HelpPageListComponent_2)), i1.ɵdid(7, 16384, [[2, 4]], 0, i3.MatTabLabel, [i1.TemplateRef, i1.ViewContainerRef], null, null), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "app-help-page-edit", [], null, [[null, "deleted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("deleted" === en)) {
        var pd_0 = (_co.removePage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_HelpPageEditComponent_0, i7.RenderType_HelpPageEditComponent)), i1.ɵdid(9, 114688, null, 0, i8.HelpPageEditComponent, [i9.HelperService], { simplePage: [0, "simplePage"] }, { deleted: "deleted" }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_1 = _v.context.$implicit; _ck(_v, 9, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 5)._animationMode === "NoopAnimations"); _ck(_v, 4, 0, currVal_0); }); }
export function View_HelpPageListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "mat-tab-group", [["class", "mat-tab-group"], ["dynamicHeight", "true"]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], null, null, i2.View_MatTabGroup_0, i2.RenderType_MatTabGroup)), i1.ɵdid(1, 3325952, null, 1, i3.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i3.MAT_TABS_CONFIG], [2, i6.ANIMATION_MODULE_TYPE]], { dynamicHeight: [0, "dynamicHeight"] }, null), i1.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpPageListComponent_1)), i1.ɵdid(4, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "true"; _ck(_v, 1, 0, currVal_2); var currVal_3 = _co.simplepages; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).dynamicHeight; var currVal_1 = (i1.ɵnov(_v, 1).headerPosition === "below"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_HelpPageListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-help-page-list", [], null, null, null, View_HelpPageListComponent_0, RenderType_HelpPageListComponent)), i1.ɵdid(1, 114688, null, 0, i11.HelpPageListComponent, [i9.HelperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HelpPageListComponentNgFactory = i1.ɵccf("app-help-page-list", i11.HelpPageListComponent, View_HelpPageListComponent_Host_0, { simplePage: "simplePage" }, {}, []);
export { HelpPageListComponentNgFactory as HelpPageListComponentNgFactory };
