<div *ngIf="tradingHours">
  <h2 class="sr-title rm-m sm-h3 clearfix mb text-center" *ngIf="title">
    {{ title }}
  </h2>
  <form #hourForm="ngForm">
    <div class="clearfix mmb" *ngIf="tradingHours">
      <app-time-zone-list
        [timeZoneCode]="merchantTimezone"
        [label]="'Select your timezone'"
        (getResult)="getResultTimeZone($event)"
      >
      </app-time-zone-list>
    </div>

    <div class="clearfix flex tradingHoursRow animate" *ngFor="let t of tradingHours; let i = index">
      <p class="full-width">{{ t['DayOfWeek.Label.Singular'] }}</p>
      <mat-slide-toggle class="full-width" [checked]="openOrClose(t['NotOpen'])" (change)="onChange($event, i)">
        <span *ngIf="t['NotOpen'] == true">Close</span>
        <span *ngIf="t['NotOpen'] == false">Open</span>
      </mat-slide-toggle>
      <app-date-input
        class="full-width animate"
        *ngIf="openOrClose(t['NotOpen'])"
        @ngIfAnimation
        [isTime]="true"
        [isDate]="false"
        [defaultTime]="t['OpeningTime']"
        (getTime)="getOpenTimeEvent($event, i)"
      ></app-date-input>
      <app-date-input
        class="full-width animate"
        *ngIf="openOrClose(t['NotOpen'])"
        @ngIfAnimation
        [isTime]="true"
        [isDate]="false"
        [defaultTime]="t['ClosingTime']"
        (getTime)="getCloseTimeEvent($event, i)"
      ></app-date-input>
    </div>

    <hr/>
    <div class="text-right controls">
      <button class="ml" (click)="closeEvent()" mat-raised-button type="button">Cancel</button>
      <button
        class="ml"
        *ngIf="isSetup == true"
        [disabled]="!hourForm.form.valid || isValidTimezone == false"
        (click)="apply()"
        type="button"
        mat-raised-button
        color="primary"
      >
        Set Up
      </button>
      <button
        class="ml"
        *ngIf="isSetup != true"
        [disabled]="!hourForm.form.valid || isValidTimezone == false"
        (click)="apply()"
        type="button"
        mat-raised-button
        color="primary"
      >
        Modify
      </button>
    </div>
  </form>
</div>

<div *ngIf="!tradingHours">
  <!-- css loader -->
  <div class="lds-ellipsis">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  <!-- /css loader -->
</div>
