<div class="wrapper text-left" [class.mat-dialog-content]="isModal == true" [class.indOvf]="isModal == true">
  <div class="row clearfix">
    <div class="card-header primary-gradient-img clearfix inModal rel" *ngIf="isModal == true">
      <div class="row clearfix">
        <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModal()"
                tabIndex="-1">
          <mat-icon>clear</mat-icon>
        </button>
        <div class="row titleArea clearfix text-left">
          <svg class="pull-left" id="treatLookIcon" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 153.34 136.08">
            <defs>
              <style>
                .cls-1 {
                  fill: none;
                }
              </style>
            </defs>
            <title>treatment-lookup</title>
            <circle class="cls-1" cx="259.58" cy="109.88" r="6.38" transform="translate(-210.22 122.86) rotate(-45)" />
            <path
              d="M283.57,186.33a2.43,2.43,0,0,1-.6.1H268.08v14.88a2.12,2.12,0,0,1-2.12,2.12H253.2a2.13,2.13,0,0,1-2.13-2.12V186.43H236.19a2.14,2.14,0,0,1-2.13-2.13V171.54a2.13,2.13,0,0,1,2.13-2.12h14.88V154.53a2.13,2.13,0,0,1,2.13-2.12H258a46.33,46.33,0,0,1,.18-17h-22a2.14,2.14,0,0,1-2.13-2.13V129a2.14,2.14,0,0,1,2.13-2.13h24.56a47,47,0,0,1,15.56-20,17,17,0,0,0-33.74,3H221.31a12.76,12.76,0,0,0-12.76,12.76v93.55A12.76,12.76,0,0,0,221.31,229h76.54a12.77,12.77,0,0,0,12.76-12.76V190.56A48.73,48.73,0,0,1,304,191,46.37,46.37,0,0,1,283.57,186.33Zm-24-82.83a6.38,6.38,0,1,1-6.38,6.38A6.37,6.37,0,0,1,259.58,103.5Z"
              transform="translate(-208.55 -92.87)"/>
            <path
              d="M360.56,189.12l-19-19a4.57,4.57,0,0,0-3.24-1.34h-3.11a39.64,39.64,0,0,0-50.73-58.91,39.85,39.85,0,0,0-16.09,17,39.32,39.32,0,0,0-3,8.51,39.72,39.72,0,0,0-.23,17,39.15,39.15,0,0,0,2.94,8.83,39.83,39.83,0,0,0,17,18,39.66,39.66,0,0,0,43.27-3.61v3.11a4.6,4.6,0,0,0,1.33,3.24l19,19a4.6,4.6,0,0,0,3.24,1.34,4.52,4.52,0,0,0,3.22-1.34l5.4-5.4A4.59,4.59,0,0,0,360.56,189.12Zm-49.95-21.25a24.39,24.39,0,1,1,17.75-23.47A24.26,24.26,0,0,1,310.61,167.87Z"
              transform="translate(-208.55 -92.87)"/>
            <path
              d="M316.39,139.58h-7.74v-7.75a1.11,1.11,0,0,0-1.11-1.1H300.9a1.11,1.11,0,0,0-1.1,1.1v7.75h-7.74a1.11,1.11,0,0,0-1.11,1.1v6.64a1.11,1.11,0,0,0,1.11,1.1h7.74v7.75a1.11,1.11,0,0,0,1.1,1.1h6.64a1.11,1.11,0,0,0,1.11-1.1v-7.75h7.74a1.1,1.1,0,0,0,1.1-1.1v-6.64A1.1,1.1,0,0,0,316.39,139.58Z"
              transform="translate(-208.55 -92.87)"/>
          </svg>

          <h2 class="summary-header rm-m">
            {{ "KEYWORD.treatment" | translate | titlecase }} Lookup
          </h2>
        </div>
      </div>
    </div>
    <ng-container [ngSwitch]="step">
      <div *ngSwitchCase="1">
        <p class="text-left">
          You can search for {{"KEYWORD.procedure" | translate}}s, treatment, suppliers, brands and products in a number
          of ways. Choose one of
          the following options to perform the search.
        </p>
        <mat-radio-group class="selection-radio-group ml" [(ngModel)]="step1Selected">
          <ng-container *ngFor="let opt of step1Options">
            <mat-radio-button class="selection-radio-button" [value]="opt.value">
              {{ opt.label }}
            </mat-radio-button>
          </ng-container>
        </mat-radio-group>
        <button class="float-right" [ngClass]="{ mb: !isModal }" (click)="proceed()" color="accent" mat-raised-button>
          SEARCH
        </button>
      </div>
      <div *ngSwitchCase="2">
        <ng-container [ngSwitch]="step1Selected">
          <div *ngSwitchCase="'adaItemCode'">
            <div class="full-width">
              <h3 class="text-center sr-title">Search By - ADA Item Code</h3>
              <img class="float-right" style="width: 75px; margin-top: -67px"
                   src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/brandLogos/ADA-Fed-Logo.png"/>
            </div>
            <p class="text-left">
              Enter the ADA Item code, and the system will display a list of ALL available products and services for
              that code. Alternatively, find the code you require by clicking on the View Codes button.
            </p>
            <div class="full-width">
              <mat-form-field class="thrd-width" appearance="outline">
                <mat-label>Item Code</mat-label>
                <input class="mat-block" [(ngModel)]="itemCode" (change)="search()" matInput name="itemCode"
                       tabIndex="1"/>
              </mat-form-field>
              <a class="view-btn" [href]="sanitizer.bypassSecurityTrustUrl(viewCodesLink)" color="primary"
                 mat-raised-button target="_blank">
                VIEW CODES
              </a>
            </div>
          </div>

          <div *ngSwitchCase="'adaServiceCategory'">
            <div class="full-width">
              <h3 class="text-center sr-title">Search By - ADA Service Category</h3>
              <img class="float-right" style="width: 75px; margin-top: -67px"
                   src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/brandLogos/ADA-Fed-Logo.png"/>
            </div>
            <p class="text-left">
              Select the {{ "KEYWORD.dental" | translate | titlecase }} Service Category, and the system will display a
              list of ALL
              available products and services for that category. Alternatively, find the category you require by
              clicking on the View Categories button.
            </p>
            <mat-form-field class="drop-down-select" appearance="outline">
              <mat-label>Category</mat-label>
              <input [matAutocomplete]="autoCategory" (input)="filterCategory($event)" name="category" type="text"
                     matInput/>
              <mat-autocomplete #autoCategory="matAutocomplete" [displayWith]="getOptionText"
                                (optionSelected)="categorySelected($event.option.value)">
                <mat-option *ngFor="let op of filteredCategories" [value]="op">
                  <app-category-view-logo class="supplierIcon2-treatment-consent-category text-center pull-left"
                                          *ngIf="op" [hideZoomButton]="true" [categoryID]="op['ID']"
                                          [LastModified]="op['LastModified']">
                  </app-category-view-logo>
                  <span class="option-label">{{ treatmentUtil.getLabel(op.Label) }}</span>
                </mat-option>
              </mat-autocomplete>
              <button class="" *ngIf="categoryID" (click)="$event.stopPropagation(); viewCategory(categoryID)"
                      matTooltip="View Category" color="accent" mat-button matSuffix matTooltip="View Category">
                <mat-icon>visibility</mat-icon>
              </button>
            </mat-form-field>
            <a class="view-btn" [href]="sanitizer.bypassSecurityTrustUrl(viewServiceCategoryLink)" color="primary"
               mat-raised-button target="_blank">
              VIEW CATEGORIES
            </a>
          </div>

          <div *ngSwitchCase="'supplier'">
            <h3 class="text-center sr-title">Search By - Supplier</h3>
            <p class="text-left">
              Enter the supplier, and the system will display a list of ALL available products and services for that
              supplier. Alternatively, find the supplier you require by clicking on the View Suppliers button.
            </p>
            <mat-form-field class="drop-down-select" appearance="outline">
              <mat-label>Suppliers</mat-label>
              <input [matAutocomplete]="autoSupplier" (input)="filterSupplier($event)" name="supplier" type="text"
                     matInput/>
              <mat-autocomplete #autoSupplier="matAutocomplete" [displayWith]="getOptionText"
                                (optionSelected)="supplierSelected($event.option.value)">
                <mat-option *ngFor="let op of filteredSuppliers" [value]="op">
                  <app-beneficiary-view-picture class="supplierIcon2-treatment-consent text-center mr" *ngIf="op"
                                                [beneficiaryID]="op['ID']">
                  </app-beneficiary-view-picture>
                  <span class="option-label">{{ treatmentUtil.getLabel(op.Label) }}</span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <a class="view-btn" [href]="sanitizer.bypassSecurityTrustUrl(viewSuppliersLink)" color="primary"
               mat-raised-button target="_blank">
              VIEW SUPPLIERS
            </a>
          </div>

          <div *ngSwitchCase="'productOrBrand'">
            <h3 class="text-center sr-title">Search By - Product/Brand Name</h3>
            <p class="text-left">
              Enter the product or brand name, and the system will display a list of ALL available products and services
              for that product/brand. Alternatively, find the product/brand you require by clicking on the View
              Products/Brands button.
            </p>
            <div class="full-width clearfix mb">
              <mat-form-field class="pull-left half-width" appearance="outline">
                <mat-label>Product/Brand Label</mat-label>
                <input [(ngModel)]="productSearchPartLabel" (keyup.enter)="search()" name="productSearchPartLabel"
                       matInput placeholder="Insert product label and click on search" required minlength="1"/>
              </mat-form-field>

              <a class="view-btn pull-right" [href]="sanitizer.bypassSecurityTrustUrl(viewProductsOrBrandsLink)"
                 color="primary" mat-raised-button target="_blank">
                VIEW PRODUCTS/BRANDS
              </a>
            </div>
          </div>
        </ng-container>
        <div class="full-width clearfix mt">
          <button class="float-left" [ngClass]="{ mb: !isModal }" (click)="goBack()" color="primary" mat-raised-button>
            BACK
          </button>
          <button class="float-right" [ngClass]="{ mb: !isModal }" [disabled]="
              (step1Selected === 'adaItemCode' && !itemCode) ||
              (step1Selected === 'adaServiceCategory' && !categoryID) ||
              (step1Selected === 'supplier' && !supplierID) ||
              (step1Selected === 'productOrBrand' && !productSearchLabel && !productSearchPartLabel)
            " (click)="search()" color="accent" mat-raised-button>
            SEARCH
          </button>
        </div>
      </div>
      <div *ngSwitchCase="3">
        <div class="row clearfix flex">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Item Code (Optional)</mat-label>
            <input class="mat-block" [(ngModel)]="itemCode" [disabled]="true" matInput name="itemCode"
                   placeholder="Item code" tabIndex="1"/>

            <button class="" *ngIf="itemCode" (click)="changeItemCode(true)" matTooltip="Product Lookup" color="primary"
                    mat-button matSuffix [matTooltip]="('KEYWORD.treatment' | translate | titlecase) + ' Lookup'">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Category</mat-label>
            <mat-select class="" [(ngModel)]="categoryID" [disabled]="true"
                        (selectionChange)="onSelectSimpleCategory(categoryID)" name="category" placeholder="Category"
                        tabIndex="2">
              <mat-option *ngFor="let treat_simple_category of simpleCategories" [value]="treat_simple_category.ID">
                {{ treatmentUtil.getLabel(treat_simple_category.Label) }}
              </mat-option>
            </mat-select>

            <button class="" *ngIf="categoryID" (click)="$event.stopPropagation(); viewCategory(categoryID)"
                    matTooltip="View Category" color="accent" mat-button matSuffix matTooltip="View Category">
              <mat-icon>visibility</mat-icon>
            </button>
          </mat-form-field>
        </div>

        <div class="row clearfix flex">
          <!--{{ "KEYWORD.treatment" | translate | titlecase }} Type -->

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Type</mat-label>
            <mat-select class="" [(ngModel)]="typeID" [disabled]="true" (selectionChange)="onSelectSimpleType(typeID)"
                        name="type" placeholder="Type" tabIndex="3">
              <mat-option *ngFor="let treat_simple_type of simpleTypes" [value]="treat_simple_type.ID">
                {{ treatmentUtil.getLabel(treat_simple_type.Label) }}
              </mat-option>
            </mat-select>
            <button class="" *ngIf="typeID" (click)="$event.stopPropagation(); viewType(typeID)" matTooltip="View Type"
                    color="accent" mat-button matSuffix>
              <mat-icon>visibility</mat-icon>
            </button>
          </mat-form-field>
        </div>

        <div class="row clearfix flex catRow inf-cons-sel" *ngIf="typeID" @ngIfAnimation>
          <!--{{ "KEYWORD.treatment" | translate | titlecase }} Brand -->

          <mat-form-field class="full-width animate" appearance="outline">
            <mat-label>Brand</mat-label>
            <mat-select class="" [(ngModel)]="brandID" [disabled]="true"
                        (selectionChange)="onSelectSimpleBrand(brandID)" name="brand" placeholder="Brand" tabIndex="4">
              <mat-option *ngFor="let treat_simple_brand of simpleBrands" [value]="treat_simple_brand.ID">
                {{ treatmentUtil.getLabel(treat_simple_brand.Label) }}
              </mat-option>
            </mat-select>

            <button class="" *ngIf="brandID" (click)="$event.stopPropagation(); viewBrand(brandID)"
                    matTooltip="View Brand" color="accent" mat-button matSuffix>
              <mat-icon>visibility</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field class="full-width animate" appearance="outline">
            <mat-label>Product</mat-label>
            <mat-select class="" [(ngModel)]="productID" [disabled]="true"
                        (selectionChange)="onSelectSimpleProduct(productID, true)" name="product" placeholder="Product"
                        tabIndex="5">
              <mat-option *ngFor="let treat_simple_product of simpleProducts" [value]="treat_simple_product.ID">
                {{ treatmentUtil.getLabel(treat_simple_product.Label) }}
              </mat-option>
            </mat-select>
            <button class="" *ngIf="productID" (click)="$event.stopPropagation(); viewProduct(productID)" color="accent"
                    mat-button matSuffix matTooltip="View Product">
              <mat-icon>visibility</mat-icon>
            </button>
          </mat-form-field>
        </div>

        <button class="pull-right" (click)="step = 1; clearFields()" color="accent" mat-raised-button
                matTooltip="Clear">
          CLEAR
        </button>
      </div>
    </ng-container>
  </div>

  <div class="row clearfix">
    <button class="text-center clearfix mr mat-elevation-z1" *ngIf="
        stockArtAllDocuments &&
        stockArtAllDocuments.length > 0 &&
        displayStockArtDocuments != true &&
        selectStockArtDocuments == true
      " (click)="modalStockArt()" mat-stroked-button color="warning">
      Gallery
    </button>

    <button class="text-center clearfix mr mat-elevation-z1"
      *ngIf="preAllDocuments && preAllDocuments.length > 0 && displayPreDocuments != true && selectPreDocuments == true"
            (click)="modalPre()" mat-stroked-button color="warning">
      Pre Documents
    </button>

    <button class="text-center clearfix mr mat-elevation-z1" *ngIf="
        postAllDocuments && postAllDocuments.length > 0 && displayPostDocuments != true && selectPostDocuments == true
      " (click)="modalPost()" mat-stroked-button color="warning">
      Post Documents
    </button>

    <button class="text-center clearfix mr mat-elevation-z1" *ngIf="
        marketingAllDocuments &&
        marketingAllDocuments.length > 0 &&
        displayMarketingDocuments != true &&
        selectMarketingDocuments == true
      " (click)="modalMarketing()" mat-stroked-button color="warning">
      Marketing Documents
    </button>
  </div>

  <div class="row clearfix mb" *ngIf="
      (preAllDocuments.length > 0 ||
        postAllDocuments.length > 0 ||
        stockArtAllDocuments.length > 0 ||
        marketingAllDocuments.length > 0) &&
      isModal == false
    ">
    <p class="small rm-m-b">Please select the files you wish to include with this invitation</p>

    <div class="clearfix full-width" *ngIf="preAllDocuments.length > 0 && displayPreDocuments == true">
      <app-ep-document-list [listViewType]="DocumentlistViewType" [sendResultOnChange]="true" [isDownload]="isDownload"
                            [isPrint]="isPrint" [defaultCheck]="defaultCheck" [isRedirection]="isRedirection"
                            [documents]="preAllDocuments"
                            [requiredIDs]="preRequiredDocuments" [title]="'Pre documents'" [canCheck]="true"
                            [selectedIDs]="preSelectedDocuments"
                            (getselectedDocumentIDs)="getPreCheckedDocument($event)"
        (getCheckedDocument)="getCheckedPreDocumentEvent($event)"
                            (getUncheckedDocument)="getUncheckedPreDocumentEvent($event)">
      </app-ep-document-list>
    </div>
    <div class="clearfix full-width" *ngIf="postAllDocuments.length > 0 && displayPostDocuments == true">
      <app-ep-document-list [listViewType]="DocumentlistViewType" [sendResultOnChange]="true" [isDownload]="isDownload"
                            [isPrint]="isPrint" [defaultCheck]="defaultCheck" [isRedirection]="isRedirection"
                            [documents]="postAllDocuments"
                            [requiredIDs]="postRequiredDocuments" [title]="'Post documents'" [canCheck]="true"
                            [selectedIDs]="postSelectedDocuments"
                            (getselectedDocumentIDs)="getPostCheckedDocument($event)"
        (getCheckedDocument)="getCheckedPostDocumentEvent($event)"
                            (getUncheckedDocument)="getUncheckedPostDocumentEvent($event)">
      </app-ep-document-list>
    </div>

    <div class="clearfix full-width" *ngIf="marketingAllDocuments.length > 0 && displayMarketingDocuments == true">
      <app-ep-document-list [listViewType]="DocumentlistViewType" [sendResultOnChange]="true" [isDownload]="isDownload"
                            [isPrint]="isPrint" [defaultCheck]="defaultCheck" [isRedirection]="isRedirection"
                            [documents]="marketingAllDocuments" [requiredIDs]="marketingRequiredDocuments"
                            [title]="'Marketing documents'"
                            [canCheck]="true" [selectedIDs]="marketingSelectedDocuments"
        (getselectedDocumentIDs)="getMarketingCheckedDocument($event)"
        (getCheckedDocument)="getCheckedMarketingDocumentEvent($event)"
                            (getUncheckedDocument)="getUncheckedMarketingDocumentEvent($event)">
      </app-ep-document-list>
    </div>

    <div class="clearfix full-width" *ngIf="stockArtAllDocuments.length > 0 && displayStockArtDocuments == true">
      <app-ep-document-list [listViewType]="DocumentlistViewType" [sendResultOnChange]="true" [isDownload]="isDownload"
                            [isPrint]="isPrint" [defaultCheck]="defaultCheck" [isRedirection]="isRedirection"
                            [documents]="stockArtAllDocuments" [requiredIDs]="stockArtRequiredDocuments"
                            [title]="('KEYWORD.procedure' | translate | titlecase) +' Gallery'" [canCheck]="true"
                            [selectedIDs]="stockArtSelectedDocuments"
                            (getselectedDocumentIDs)="getStockArtCheckedDocument($event)"
        (getCheckedDocument)="getCheckedStockArtDocumentEvent($event)"
                            (getUncheckedDocument)="getUncheckedStockArtDocumentEvent($event)">
      </app-ep-document-list>
    </div>
  </div>
</div>
