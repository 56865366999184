<aw-wizard #wizard navBarLayout="large-filled-symbols">
  <aw-wizard-step [stepTitle]="('KEYWORD.patient' | translate | titlecase) + ' Information'" navigationSymbol="1">
    <!-- Step 1  -->
    <div class="container animated fadeInDown" id="step-1">
      <mat-card>
        <div class="card-header primary-gradient-img clearfix inModal">
          <div class="row clearfix">
            <div class="row titleArea clearfix">
              <mat-icon class="fas fa-file-invoice-dollar pull-left"></mat-icon>
              <h2 class="summary-header rm-m">
                <!-- {{ beneficiary.CalculatedName}} -->
                New {{ "KEYWORD.treatment" | translate | titlecase }} Quotation
                <span class="subLabel"></span>
              </h2>
            </div>
          </div>
          <div class="action-button">
            <button class="btn-small mt" id="searchPatient" (click)="openPatientLookUpDialog()" mat-raised-button
                    color="accent">
              {{ "KEYWORD.patient" | translate | titlecase }} Lookup
              <mat-icon class="">contacts</mat-icon>
            </button>
          </div>
        </div>

        <div class="clearfix" *ngIf="isPromoterOrAdmin == true">
          <p class="help-label">
            To send an invitation on behalf of a merchant select a
            merchant below, otherwise, the invitation will be sent by the promoter.
          </p>

          <div class="row clearfix well selectMerchant">
            <!-- <mat-select name="MerchantName" [userData]="'merchantID'" class=" margin-btm half-width " placeholder="Please Select a Merchant"
              [(ngModel)]="merchantID"> -->
            <mat-select class="margin-btm half-width" name="MerchantName" placeholder="Please Select a Merchant">
              <mat-option [value]="null"> My Account</mat-option>
              <!-- <mat-option *ngFor="let m of  merchants  | orderBy : ['TradingAs', 'CalculatedName']  " [value]="m['ID']" >
                {{m['TradingAs'] || m['CalculatedName'] }}
              </mat-option> -->
            </mat-select>
            <!-- <button mat-raised-button class="pull-right merchBtn" *ngIf="merchantID && merchantID!='none'" color="primary"
              (click)="viewMerchant()">
              <mat-icon>person</mat-icon> <span class="mobHide" >View Merchant</span>
            </button> -->
          </div>
        </div>

        <hr class="mt mb" *ngIf="isPromoterOrAdmin == true"/>
        <div class="row clearfix">
          <div class="icons-col">
            <mat-icon class="fas fa-user"></mat-icon>
          </div>
          <div class="row-content-col mb" id="defaultMes">
            <h3 class="sr-title sm-h3 rm-mt mt rm-mb">{{"KEYWORD.patient" | translate | titlecase}} details</h3>
            <label>Please start by entering the details of your {{ "KEYWORD.patient" | translate }}. You can use the '{{
              'KEYWORD.patient' | translate | titlecase
              }}
              Lookup' button to search your existing {{ "KEYWORD.patient" | translate }} records.</label>
          </div>
        </div>

        <div class="row clearfix">
          <form #createForm="ngForm" name="form">
            <div class="row clearfix">
              <div class="icons-col">
                <mat-icon>person</mat-icon>
              </div>
              <div class="row-content-col">
                <div class="clearfix">
                  <div class="half-width">
                    <div class="col-left">
                      <!-- Gender Select -->
                      <mat-select class="full-width" [(ngModel)]="user.gender" [userData]="'patient.gender'"
                                  name="gender" placeholder="Gender" required>
                        <mat-option *ngFor="let gender of genders" [value]="gender.Code">
                          {{ gender.Label }}
                        </mat-option>
                      </mat-select>
                    </div>
                  </div>

                  <div class="half-width">
                    <div class="col-right">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Title</mat-label>

                        <!-- Title Select -->
                        <mat-select class="full-width" [(ngModel)]="user.title" [userData]="'patient.title'"
                                    placeholder="Title" name="title">
                          <mat-option *ngFor="let title of titles" [value]="title.Label">
                            {{ title.Label }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="row clearfix flex">
                  <!-- First Name -->
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>First Name</mat-label>

                    <input class="mat-block" [(ngModel)]="user.firstname" [userData]="'patient.firstName'" matInput
                           placeholder="{{ 'PLACEHOLDER.First Name' | translate }} " name="firstname" required/>
                  </mat-form-field>

                  <!-- Middle Name -->
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{ 'PLACEHOLDER.Middle Name' | translate }} </mat-label>

                    <input class="mat-block" [(ngModel)]="user.middlename" [userData]="'patient.middleName'" matInput
                           placeholder="{{ 'PLACEHOLDER.Middle Name' | translate }} " name="middlename"/>
                  </mat-form-field>

                  <!-- Surname -->
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Surname</mat-label>

                    <input class="mat-block" [(ngModel)]="user.lastname" [userData]="'patient.lastName'" matInput
                           placeholder="{{ 'PLACEHOLDER.Surname' | translate }} " name="lastname" required/>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <hr class="mt mb"/>

            <div class="row clearfix">
              <div class="icons-col">
                <mat-icon>phone</mat-icon>
              </div>

              <div class="row-content-col">
                <div class="row clearfix flex">
                  <!-- Mobile Number -->
                  <!-- <app-phone-input class="full-width" [userDataName]="'patient.mobile'" [label]="'Mobile'" [required]="true"
                      (validate)="validateMobile($event)" > </app-phone-input>

                    <app-phone-input class="full-width" [userDataName]="'patient.homePhone'" [isLandLine]="true"
                      [label]="'Home'" [required]="false" (validate)="validateHomePhone($event)" >
                    </app-phone-input>

                    <app-phone-input class="full-width" [userDataName]="'patient.workPhone'" [isLandLine]="true"
                      [label]="'Work'" [required]="false" (validate)="validateWorkPhone($event)" >
                    </app-phone-input> -->
                  <app-phone-input class="full-width" [label]="'Mobile'" [required]="true"></app-phone-input>

                  <app-phone-input class="full-width" [isLandLine]="true" [label]="'Home'" [required]="false">
                  </app-phone-input>

                  <app-phone-input class="full-width" [isLandLine]="true" [label]="'Work'" [required]="false">
                  </app-phone-input>
                </div>
              </div>
            </div>

            <div class="row clearfix">
              <div class="icons-col">
                <mat-icon>email</mat-icon>
              </div>

              <div class="row-content-col">
                <!-- Email Address -->
                <!-- <app-email-input class="full-width" [userDataName]="'patient.email'" (getEmail)="consolePhone($event)"
                    [label]="'Email'" [required]="true" (validate)="validateEmail($event)" >
                  </app-email-input> -->

                <app-email-input class="full-width" [label]="'Email'" [required]="true"></app-email-input>
              </div>
            </div>
          </form>
        </div>
      </mat-card>

      <div class="row clearfix">
        <div class="full-width text-center step-button-row">
          <button class="step-back" (click)="cancel()" mat-mini-fab color="primary">
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <!-- <button mat-raised-button class="btn-large" type="submit" color="accent" [disabled]="!createForm.form.valid || !isEmailValid || !isMobileValid || !isHomePhoneValid || !isWorkPhoneValid"
          (click)="step1()">{{"Next" | translate}}</button> -->
          <button class="btn-large" mat-raised-button type="submit" color="accent" awNextStep>
            Next
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <!--  / Step 1  -->
  </aw-wizard-step>

  <aw-wizard-step [stepTitle]="'Create ' + ('PHRASE.treatment plan' | translate | titlecase)" navigationSymbol="2">
    <div class="container animated fadeInDown" id="step-2">
      <div class="row clearfix">
        <!-- <div class="text-center">
          <h2 class="sr-title"> {{"KEYWORD.Treatment" | translate}} Plan Builder</h2>
        </div> -->

        <mat-card class="card treatment-card card1" id="simpleInvitation">
          <div class="row clearfix tab-header-advanced primary-gradient-img">
            <h3 class="sm-h3 rm-m">Define {{ "KEYWORD.treatment" | translate }}</h3>
            <p class="hint">
              <label>?</label>
              'Use the below fields to define your {{ "KEYWORD.treatment" | translate }} items and construct a {{
              "PHRASE.treatment plan" | translate }} for your {{ "KEYWORD.patient" | translate }}
            </p>
          </div>
        </mat-card>

        <div *ngIf="hasVisits == true" @ngIfAnimation>
          <div class="row clearfix flex flexHeading primary-gradient-img mat-elevation-z2 anim">
            <h2 class="visitHeading pull-left">Visit 1</h2>
          </div>
        </div>
        <div class="row clearfix accordionContainer mb anim" *ngFor="let v of visitPanels; let iv = index"
             @ngIfAnimation>
          <div class="row clearfix flex flexHeading primary-gradient-img mat-elevation-z2 anim" *ngIf="iv >= 1"
               @ngIfAnimation>
            <div class="flexHeading flex full-width">
              <h2 class="visitHeading pull-left">Visit {{ iv + 1 }}</h2>
              <button class="pull-left ml" mat-icon-button matTooltip="Remove visit">
                <mat-icon class="" (click)="removeVisitRow(v)">delete</mat-icon>
              </button>
            </div>
            <mat-form-field class="qtr-width">
              <mat-label>Days since previous visit</mat-label>
              <input class="pull-right full-width" name="daysSince" placeholder="Days since previous visit" matInput/>
            </mat-form-field>
          </div>
          <!-- item selection component -->
          <app-quotation-create-item></app-quotation-create-item>
        </div>

        <div class="row clearfix">
          <button (click)="newVisitRow()" mat-raised-button color="primary">
            Add visit
            <mat-icon>queue</mat-icon>
          </button>
        </div>

        <mat-card class="treatmentTotals mt">
          <div class="row clearfix totalHeading primary-gradient-img">
            <h2 class="summary-header rm-m"> {{"KEYWORD.Treatment" | translate}} totals</h2>
          </div>

          <div class="row clearfix flex">
            <div class="totalBlock">
              <div class="block">
                <label>Visits</label>
                <h2 class="rm-m totalValue">1</h2>
              </div>
              <div class="block">
                <label> {{"KEYWORD.Treatment" | translate}} items</label>
                <h2 class="rm-m totalValue">3</h2>
              </div>
              <div class="block">
                <label>Documents</label>
                <h2 class="rm-m totalValue">13</h2>
              </div>
            </div>
            <div class="totalBlock text-right">
              <label> {{"KEYWORD.Treatment" | translate}} total value</label>
              <h2 class="rm-m totalValue">$1000.00</h2>
            </div>
          </div>

          <div class="row clearfix text-right grey mt">
            <button class="btn-small" mat-button color="accent">
              <mat-icon>save</mat-icon>
              Save as draft
            </button>
            <button class="btn-small" mat-button color="primary">
              <mat-icon>attach_money</mat-icon> Payment options
            </button>
            <button class="btn-small" mat-button color="primary">
              <mat-icon>print</mat-icon>
              Print view
            </button>
          </div>
        </mat-card>
      </div>
      <div class="row clearfix">
        <div class="full-width step-button-row text-center">
          <button class="step-back" mat-mini-fab color="primary" awPreviousStep>
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <button class="step-send" class="btn-large" mat-raised-button color="accent" awNextStep>
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </aw-wizard-step>

  <aw-wizard-step stepTitle="Review and Confirm" navigationSymbol="3">
    <!--  Step 3  -->
    <div class="container animated fadeInDown" id="step-3">
      <div class="row clearfix">
        <mat-card>
          <div class="card-header primary-gradient-img clearfix inModal">
            <div class="row clearfix">
              <div class="row titleArea clearfix">
                <mat-icon class="fas fa-file-invoice-dollar pull-left"></mat-icon>
                <h2 class="summary-header rm-m">
                  <!-- {{ beneficiary.CalculatedName}} -->
                  Review &amp; Confirm
                  <span class="subLabel"></span>
                </h2>
              </div>
            </div>
            <!-- <div class="action-button">

                </div> -->
          </div>

          <div class="row clearfix grey pos">
            <div class="column clearfix">
              <div class="icons-col">
                <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
              </div>
              <div class="row-content-col mb" id="defaultMes">
                <div class="row clearfix">
                  <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb pull-left"> {{"KEYWORD.Treatment" | translate}} plan
                    totals</h3>
                </div>

                <div class="row clearfix flex">
                  <div class="qtr-width">
                    <label>Visits</label>
                    <h2 class="rm-m totalValue">1</h2>
                  </div>
                  <div class="qtr-width">
                    <label> {{"KEYWORD.Treatment" | translate}} items</label>
                    <h2 class="rm-m totalValue">3</h2>
                  </div>
                  <div class="qtr-width">
                    <label>Documents</label>
                    <h2 class="rm-m totalValue">13</h2>
                  </div>
                  <div class="qtr-width text-right">
                    <label> {{"KEYWORD.Treatment" | translate}} total value</label>
                    <h2 class="rm-m totalValue">$1000.00</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row clearfix mt">
            <div class="column clearfix">
              <div class="icons-col">
                <mat-icon>email</mat-icon>
              </div>
              <div class="row-content-col" id="defaultMes">
                <div class="row clearfix">
                  <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb pull-left">Your personal message</h3>
                  <div class="defaultMsgArea pull-right">
                    <mat-icon class="pull-left">chat</mat-icon>

                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Add Default Message</mat-label>

                      <mat-select class="full-width" class="defaultMsg btn-small pull-right" name="messageOptions"
                                  placeholder="Add Default Message">
                        <!-- <mat-option *ngFor="let option of dentMsgOptions" [value]="option.key">
                        <p class="small rm-mt rm-mb">{{option.value}}</p>
                      </mat-option> -->
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Personal message to appear with invitation</mat-label>

                  <textarea class="dentMsg increase-input-field" [(ngModel)]="dentMsg" [userData]="'message.content'"
                            name="dentMsg" matInput
                            placeholder="*Personal message to appear with invitation"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="inclusions row clearfix">
            <div class="column clearfix mb">
              <div class="icons-col">
                <mat-icon>person_add</mat-icon>
              </div>
              <div class="row-content-col" id="defaultMes">
                <div class="row clearfix">
                  <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb">Additional inclusions</h3>
                  <p>
                    You can offer your {{ "KEYWORD.patient" | translate }} additional {{ "KEYWORD.treatments" |
                    translate }} to be displayed at the bottom of their treatment
                    plan as 'optional inclusions' which your {{ "KEYWORD.patient" | translate }} can add. Please select
                    any inclusions which you may
                    want to attach to this invitation.
                  </p>
                  <p class="small">
                    Inclusion {{ "KEYWORD.treatment" | translate | titlecase }} Items can be set by marking
                    a {{ "PHRASE.treatment product" | translate }} as a 'favourite' in
                    the {{"KEYWORD.Treatment" | translate}}
                    Group tab.
                  </p>
                </div>
                <div class="row clearfix">
                  <div class="half-width">
                    <div class="col-left">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Inclusions</mat-label>

                        <mat-select class="full-width" [(ngModel)]="selectedInclusion" [formControl]="inclusions"
                                    placeholder="Inclusions" multiple>
                          <mat-option *ngFor="let inclusion of inclusionList" [value]="inclusion">{{ inclusion }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <!-- demo inclusion item -->
                <div class="row clearfix" *ngIf="selectedInclusion && selectedInclusion.includes('Scale & Clean')"
                     @ngIfAnimation>
                  <div class="inclusion-item anim">
                    <app-ep-document-view-image class="inclusionIcon" [hideZoomButton]="true" [defaultPicture]="
                        'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/notFoundImage.png)'
                      ">
                    </app-ep-document-view-image>
                    <p class="bold sr-title">Scale &amp; Clean</p>
                    <p>Whole of Mouth</p>
                    <p>$120.00</p>
                    <button [matMenuTriggerFor]="menu" mat-icon-button>
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item>
                        <mat-icon>edit</mat-icon>
                        <span> Edit inclusion item </span>
                      </button>
                      <button mat-menu-item>
                        <mat-icon>delete</mat-icon>
                        <span>Remove inclusion item</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
                <!-- demo inclusion item -->
                <div class="row clearfix" *ngIf="selectedInclusion && selectedInclusion.includes('Fissure Sealant')"
                     @ngIfAnimation>
                  <div class="inclusion-item anim">
                    <app-ep-document-view-image class="inclusionIcon" [hideZoomButton]="true" [defaultPicture]="
                        'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/notFoundImage.png)'
                      ">
                    </app-ep-document-view-image>
                    <p class="bold sr-title">Fissure Sealant</p>
                    <p>Whole of Mouth</p>
                    <p>$210.50</p>
                    <button [matMenuTriggerFor]="menu" mat-icon-button>
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item>
                        <mat-icon>edit</mat-icon>
                        <span> Edit inclusion item </span>
                      </button>
                      <button mat-menu-item>
                        <mat-icon>delete</mat-icon>
                        <span>Remove inclusion item</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
                <!-- demo inclusion item -->
                <div class="row clearfix" *ngIf="selectedInclusion && selectedInclusion.includes('Whitening')"
                     @ngIfAnimation>
                  <div class="inclusion-item anim">
                    <app-ep-document-view-image class="inclusionIcon" [hideZoomButton]="true" [defaultPicture]="
                        'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/notFoundImage.png)'
                      ">
                    </app-ep-document-view-image>
                    <p class="bold sr-title">Whitening</p>
                    <p>Whole of Mouth</p>
                    <p>$305.00</p>
                    <button [matMenuTriggerFor]="menu" mat-icon-button>
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item>
                        <mat-icon>edit</mat-icon>
                        <span> Edit inclusion item </span>
                      </button>
                      <button mat-menu-item>
                        <mat-icon>delete</mat-icon>
                        <span>Remove inclusion item</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="grey">
            <div class="row clearfix">
              <div class="half-width mt" id="senderName">
                <div class="column clearfix mb">
                  <div class="icons-col">
                    <mat-icon>face</mat-icon>
                  </div>

                  <div class="row-content-col">
                    <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb">Name of sender to appear with message</h3>
                    <div class="clearfix">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Sender</mat-label>

                        <!-- Sender Select -->
                        <mat-select class="full-width" [(ngModel)]="selectedSender" [userData]="'message.sender'"
                                    name="selectedSender" placeholder="Sender">
                          <mat-option *ngFor="let sender of senders" [value]="sender.ID">
                            {{ sender.CalculatedName }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>

              <div class="half-width mt" id="setting">
                <div class="column mb">
                  <div class="icons-col">
                    <mat-icon>build</mat-icon>
                  </div>

                  <div class="row-content-col">
                    <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb">Settings</h3>
                    <p>
                      The invitation will be sent to
                      <span class="invite-sent-to" [userData]="'patient.firstName'"></span>
                      <span class="invite-sent-to" [userData]="'patient.lastName'"></span>
                    </p>
                    <div class="alternate-theme">
                      <mat-checkbox [(ngModel)]="sendSMS" [userData]="'message.viaSMS'" name="sendSMS">
                        Send SMS to:
                      </mat-checkbox>
                      <span class="send-sms-to" [userData]="'patient.mobile'" [pipe]="'phone'"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row clearfix" id="schedule">
              <div class="column clearfix mb">
                <div class="icons-col">
                  <mat-icon>alarm</mat-icon>
                </div>
                <div class="row-content-col">
                  <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb">Schedule</h3>
                  <p>
                    The membership invitation you created will be sent to your {{ "KEYWORD.patient" | translate }}
                    immediately after you click on
                    the button "Send". <br/>If you wish to change the scheduled SMS delivery date and time please
                    modify below.
                  </p>
                  <div class="row clearfix">
                    <div class="half-width">
                      <div class="col-left">
                        <div class="input-control">
                          <app-date-input class="thin" [label]="'Email Send Date:'" [userDataName]="'message.sendDate'"
                                          [minDate]="'today'"></app-date-input>
                        </div>
                        <button class="btn-small inline" *ngIf="false" (click)="openEmailPreviewDialog()"
                                mat-raised-button>
                          <mat-icon>visibility_on</mat-icon>
                          Email Preview
                        </button>
                      </div>
                    </div>
                    <div class="half-width">
                      <div class="col-right animated fadeInDown" *ngIf="sendSMS">
                        <div class="input-control">
                          <app-date-input class="thin" [isTime]="true" [label]="'SMS Send Date:'"
                                          [userDataName]="'message.sendDateSms'" [minDate]="'today'"></app-date-input>
                        </div>
                        <button class="btn-small inline" *ngIf="false" (click)="openSmsPreviewDialog()"
                                mat-raised-button>
                          <mat-icon>visibility_on</mat-icon> Preview SMS
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr/>
            <div class="row clearfix text-right">
              <button class="btn-small" mat-button color="accent">
                <mat-icon>save</mat-icon>
                Save as draft
              </button>
              <button class="btn-small" mat-button color="primary">
                <mat-icon>attach_money</mat-icon> Payment options
              </button>
              <button class="btn-small" mat-button color="primary">
                <mat-icon>print</mat-icon>
                Print view
              </button>
            </div>
          </div>
        </mat-card>
      </div>
      <div class="row clearfix">
        <div class="full-width step-button-row text-center">
          <button class="step-back" mat-mini-fab color="primary" awPreviousStep>
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <button class="step-send" class="btn-large" mat-raised-button color="accent" awNextStep>
            Send
            <mat-icon>send</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <!--  / Step 2 -->
  </aw-wizard-step>

  <!-- The completion step -->
  <aw-wizard-completion-step title="Send!" navigationSymbol="4">
    <!--  Step 3 - finished -->
    <div class="container" id="step-4">
      <div class="row clearfix">
        <mat-card class="card successful airplaneCard animated fadeInDown" @ngIfAnimation>
          <div class="row clearfix anim">
            <div class="full-width text-center">
              <div class="column row clearfix">
                <h1 class="sm-h1 sr-title rm-mt">Well Done!</h1>
                <h3 class="sm-h3 rm-mt">
                  Your invitation to
                  <strong><span class="invite-sent-to">Name</span> <span class="invite-sent-to">LastName</span></strong>
                  is on it's way!
                </h3>
              </div>
              <div class="row clearfix mb">
                <app-plane-animation></app-plane-animation>
              </div>
              <div class="row clearfix mb">
                <button class="step-back btn-small" (click)="cancel()" mat-raised-button color="primary">
                  <mat-icon>keyboard_arrow_left</mat-icon>
                  Dashboard
                </button>

                <!-- <button class="view-invite" mat-raised-button class="btn-small" color="warn" (click)="openInviteViewDetails()"
                  aria-label="Click here to view sent Invitation">
                  <mat-icon>visibility_on</mat-icon> View Invitation
                </button> -->
                <!-- <button class="view-invite" mat-raised-button class="btn-small" color="accent" *ngIf="isPromoterOrAdmin==true " (click)="invitationPage()">{{"InvitationLink" | translate}}</button> -->

                <!-- ADDED FOR STAGING -->
                <button class="view-invite" class="btn-small"
                        [routerLink]="['/consumer/', { outlets: { page: ['membership-card'] } }]" mat-raised-button
                        color="accent">
                  <mat-icon>exit_to_app</mat-icon>
                  Invitation Link
                </button>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
    <!--  / Step 4  -->
  </aw-wizard-completion-step>
</aw-wizard>
