/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tasks-list-overview.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../tasks-list/tasks-list.component.ngfactory";
import * as i3 from "../tasks-list/tasks-list.component";
import * as i4 from "../shared/tasks.service";
import * as i5 from "../../../core/authentication/shared/authentication.service";
import * as i6 from "../../operator/operator-filter/operator-filter.component.ngfactory";
import * as i7 from "../../operator/operator-filter/operator-filter.component";
import * as i8 from "../../merchant/shared/merchant.service";
import * as i9 from "../../product/shared/product.service";
import * as i10 from "../../dentist/shared/dentist.service";
import * as i11 from "@angular/common";
import * as i12 from "./tasks-list-overview.component";
var styles_TasksListOverviewComponent = [i0.styles];
var RenderType_TasksListOverviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TasksListOverviewComponent, data: {} });
export { RenderType_TasksListOverviewComponent as RenderType_TasksListOverviewComponent };
function View_TasksListOverviewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tasks-list", [], null, null, null, i2.View_TasksListComponent_0, i2.RenderType_TasksListComponent)), i1.ɵdid(1, 770048, null, 0, i3.TasksListComponent, [i4.TasksService, i5.AuthenticationService], { merchantID: [0, "merchantID"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.merchantID; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TasksListOverviewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "pleaseChoose"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No merchant selected"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "character_warning"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Choose your desired merchant from the drop-down above to view their messages"]))], null, null); }
function View_TasksListOverviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-operator-filter", [["class", "filter"], ["id", "part1"]], null, [[null, "search"], [null, "getMerchant"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("search" === en)) {
        var pd_0 = (_co.applySearch($event) !== false);
        ad = (pd_0 && ad);
    } if (("getMerchant" === en)) {
        var pd_1 = (_co.getMerchant($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_OperatorFilterComponent_0, i6.RenderType_OperatorFilterComponent)), i1.ɵdid(2, 114688, null, 0, i7.OperatorFilterComponent, [i5.AuthenticationService, i8.MerchantService, i9.ProductService, i10.DentistService], { selectDefaultAccount: [0, "selectDefaultAccount"], displayMerchant: [1, "displayMerchant"], displayDateFrom: [2, "displayDateFrom"], displayDateTo: [3, "displayDateTo"], displayDentist: [4, "displayDentist"], displayDateRange: [5, "displayDateRange"], displayTimePicker: [6, "displayTimePicker"] }, { getMerchant: "getMerchant", search: "search" }), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TasksListOverviewComponent_2)), i1.ɵdid(5, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TasksListOverviewComponent_3)), i1.ɵdid(8, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = true; var currVal_2 = false; var currVal_3 = false; var currVal_4 = false; var currVal_5 = false; var currVal_6 = false; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = (_co.merchantID != "none"); _ck(_v, 5, 0, currVal_7); var currVal_8 = (_co.merchantID == "none"); _ck(_v, 8, 0, currVal_8); }, null); }
export function View_TasksListOverviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TasksListOverviewComponent_1)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isPromoterOrAdmin == true); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TasksListOverviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tasks-list-overview", [], null, null, null, View_TasksListOverviewComponent_0, RenderType_TasksListOverviewComponent)), i1.ɵdid(1, 114688, null, 0, i12.TasksListOverviewComponent, [i5.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TasksListOverviewComponentNgFactory = i1.ɵccf("app-tasks-list-overview", i12.TasksListOverviewComponent, View_TasksListOverviewComponent_Host_0, {}, {}, []);
export { TasksListOverviewComponentNgFactory as TasksListOverviewComponentNgFactory };
