import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'lodash';

@Pipe({
  name: 'objectPropertyValue',
})
export class ObjectPropertyValuePipe implements PipeTransform {
  transform(targetObjects: { [key: string]: any }[], property: string) {
    try {
      const result = map(targetObjects, property);
      return result;
    } catch (e) {
      return targetObjects;
    }
  }
}
