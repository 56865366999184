<ipv-title-modal [title]="('KEYWORD.dentistry' | translate | titlecase) + ' Types'" [hideAllDefaultButtons]="true"
                 [icon]="''">
  <ng-container content>
    <ng-container *ngFor="let dentistryType of fullDentistryTypes">
      <mat-checkbox
        class="text-wrap"
        [(ngModel)]="selectedDentistryTypes[dentistryType.Label]"
        [value]="dentistryType.Label"
        style="display: block; text-align: left"
      >
        <span style="color: black"> {{ dentistryType['Label'] | titlecase }} </span>
      </mat-checkbox>
    </ng-container>
  </ng-container>
</ipv-title-modal>

<mat-dialog-actions style="flex-direction: row-reverse">
  <button class="button-save" [color]="'primary'" (click)="save()" mat-raised-button>Save</button>
</mat-dialog-actions>
