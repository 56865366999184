<label class="tot">
  <span class="larger">{{ selectedTeeth.length }}</span>
  <span *ngIf="selectedTeeth.length > 1">Teeth</span>
  <span *ngIf="selectedTeeth.length == 1">Tooth</span>
</label>
<div class="rel row clearfix">
  <label class="left">LEFT</label>
  <div class="row clearfix block dviewCont">
    <div class="rel flex row dview rm-mb clearfix">
      <app-tooth-view
        class="toothView"
        *ngFor="let tooth1 of teethTop"
        [selected]="isTeethExist(tooth1)"
        [tooth]="getToothObject(tooth1)"
        [isAdult]="isAdult"
      ></app-tooth-view>
    </div>
    <div class="rel flex row dview rm-mt clearfix">
      <app-tooth-view
        class="toothView"
        *ngFor="let tooth2 of teethBottom"
        [selected]="isTeethExist(tooth2)"
        [tooth]="getToothObject(tooth2)"
        [isAdult]="isAdult"
      ></app-tooth-view>
    </div>
  </div>
  <label class="right">RIGHT</label>
</div>
