import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'FilterArrayMultipleValueInRange',
})
export class FilterArrayMultipleValueInRange implements PipeTransform {
  constructor() {}

  transform(expression: any, format: any, value: any) {
    try {
      let result = [];

      if (
        expression &&
        format &&
        Array.isArray(expression) &&
        typeof format == 'string' &&
        Array.isArray(value) &&
        value.length > 0 &&
        value.length <= 2
      ) {
        let tmp = [];

        if (value.length == 2) {
          tmp = _.filter(expression, (item) => {
            if (value[0] == null && value[1] == null) {
              return true;
            } else if (value[1] == null) {
              return Number(item[format]) >= value[0];
            } else if (value[0] == null) {
              return Number(item[format]) <= value[1];
            } else if (value[0] == 0) {
              return Number(item[format]) >= value[0] && Number(item[format]) <= value[1];
            } else {
              return Number(item[format]) >= value[0] && Number(item[format]) <= value[1];
            }
          });
        } else {
          tmp = _.filter(expression, (item) => {
            return Number(item[format]) >= value[0];
          });
        }

        if (tmp.length > 0) {
          result = result.concat(tmp);
        }

        return result;
      } else if (
        expression &&
        format &&
        Array.isArray(expression) &&
        Array.isArray(format) &&
        Array.isArray(value) &&
        value.length > 0 &&
        value.length <= 2
      ) {
        result = expression.filter(function (item) {
          for (let j = 0; j < format.length; j++) {
            if (value.length == 2) {
              if (value[0] == null && value[1] == null) {
                return true;
              } else if (value[1] == null) {
                if (item[format[j]] >= value[0]) {
                  return true;
                }
              } else if (value[0] == null) {
                if (item[format[j]] <= value[1]) {
                  return true;
                }
              } else if (value[0] == 0) {
                if (item[format[j]] >= value[0] && item[format[j]] <= value[1]) {
                  return true;
                }
              } else {
                if (item[format[j]] >= value[0] && item[format[j]] <= value[1]) {
                  return true;
                }
              }
            } else {
              if (item[format[j]] >= value[0]) {
                return true;
              }
            }
          }

          return false;
        });

        return result;
      } else {
        return expression;
      }
    } catch (e) {
      return expression;
    }
  }
}
