import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { BeneficiaryViewComponent } from '../../beneficiary/beneficiary-view/beneficiary-view.component';
import { DrugsService } from '../shared/drugs.service';

@Component({
  selector: 'drugs-view',
  templateUrl: './drugs-view.component.html',
  styleUrls: ['./drugs-view.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class DrugsViewComponent implements OnInit {
  @Input()
  drugID;
  @Input()
  drug;
  closeModal = new EventEmitter();
  @Input()
  isModal = false;
  illnesses = [];
  allergies = [];
  sideEffects = [];

  isAdminOrPromoter = false;

  constructor(
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private drugService: DrugsService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.drugID) {
        this.drugID = data.drugID;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['drugID']) {
        this.drugID = params['drugID'];
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;

        if (this.drugID) {
          this.drugService.getDrugDetails(this.drugID).subscribe((res) => {
            if (res) {
              this.drug = res;
            }
          });
          const drugSideEffectPayload = {
            drugID: this.drugID,
            fields: 'Usage.Label,IllnessOrAllergy',
          };
          this.drugService.getDrugUsage(drugSideEffectPayload).subscribe((res) => {
            if (res) {
              for (let i = 0; i < res.length; i++) {
                if (res[i] && res[i]['Usage.Label'] && res[i]['IllnessOrAllergy'] == 'A') {
                  this.allergies.push(res[i]['Usage.Label']);
                } else if (res[i] && res[i]['Usage.Label'] && res[i]['IllnessOrAllergy'] == 'I') {
                  this.illnesses.push(res[i]['Usage.Label']);
                }
              }
            }
          });
          const drugUsagePayload = {
            fields: 'SideEffect.Label',
            drugID: this.drugID,
          };
          this.drugService.getDrugSideEffect(drugUsagePayload).subscribe((res) => {
            if (res) {
              for (let i = 0; i < res.length; i++) {
                if (res[i] && res[i]['SideEffect.Label']) {
                  this.sideEffects.push(res[i]['SideEffect.Label']);
                }
              }
            }
          });
        }
      });
    });
  }

  closeEvent() {
    this.closeModal.emit(true);
  }

  viewSupplier(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(BeneficiaryViewComponent, {
        data: id,
        width: '600px',
        panelClass: 'noCard',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  edit() {
    if (this.drugID) {
      this.router.navigate(['/merchant', { outlets: { page: ['drugs', 'drug-edit', this.drugID] } }]);

      this.closeEvent();
    }
  }

  disable() {
    if (this.drugID) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to disable this Medication ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          const payload = {
            isActive: false,
          };
          this.drugService.editDrug(this.drugID, payload).subscribe((res) => {
            if (res) {
              this.drug = res;

              NotifyAppComponent.displayToast('success', 'Successful operation', 'Medication has been Updated');
            }

            ref.close();
          });
        }
      });
    }
  }

  enable() {
    if (this.drugID) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to enable this Medication ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          const payload = {
            isActive: true,
          };
          this.drugService.editDrug(this.drugID, payload).subscribe((res) => {
            if (res) {
              this.drug = res;

              NotifyAppComponent.displayToast('success', 'Successful operation', 'Medication has been Updated');
            }
            ref.close();
          });
        }
      });
    }
  }

  remove() {
    if (this.drugID) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to remove this Medication ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.drugService.deleteDrug(this.drugID).subscribe((res) => {
            if (res) {
              this.router.navigate(['/merchant', { outlets: { page: ['drugs', 'drug-list'] } }]);
              NotifyAppComponent.displayToast('success', 'Successful operation', 'Medication has been Removed');
            }
          });
        }
      });
    }
  }
}
