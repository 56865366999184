import { Component, OnInit } from '@angular/core';

import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';

@Component({
  selector: 'app-referral-view',
  templateUrl: './referral-view.component.html',
  styleUrls: ['./referral-view.component.css'],
})
export class ReferralViewComponent implements OnInit {
  isReferredFrom = false;
  isReferredTo = true;
  isPromoterOrAdmin = false;

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });
  }
  contact() {
    AuthenticationService.contactUSEvent.emit();
  }
}
