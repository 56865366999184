import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { FileService } from '../../shared/services/file.service';
import { UtilsService } from '../../shared/services/utils.service';
import { SharedModule } from '../../shared/shared.module';
import { OperatorModule } from '../operator/operator.module';
import { SmileMakeoverManagerRoutingModule } from './smile-makeover-manager-routing.module';
import { SmileMakeoverManagerComponent } from './smile-makeover-manager.component';
import { SmileMakeoverModalViewComponent } from './smile-makeover-modal-view/smile-makeover-modal-view.component';

export class CustomHammerConfig extends HammerGestureConfig {
  overrides = {
    pinch: { enable: false },
    rotate: { enable: false },
  };
}

@NgModule({
  imports: [CommonModule, SharedModule, FormsModule, SmileMakeoverManagerRoutingModule, OperatorModule],
  exports: [SmileMakeoverManagerComponent, SmileMakeoverModalViewComponent],
  declarations: [SmileMakeoverManagerComponent, SmileMakeoverModalViewComponent],
  providers: [{ provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig }, FileService, UtilsService],
  entryComponents: [SmileMakeoverModalViewComponent],
})
export class SmileMakeoverManagerModule {}
