var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { SecurityCodeInfoComponent } from '../../../core/authentication/security-code-info/security-code-info.component';
import { SecurityCodeInput } from '../../../core/authentication/security-code-input/security-code-input.component';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ErrorModalComponent } from '../../../core/helper/error-modal/error-modal.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { HeaderService } from '../../../shared/services/header.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { GoogleReviewsPromptDialogComponent } from '../../google-reviews/dialogs/google-reviews-prompt-dialog-modal/google-reviews-prompt-dialog.component';
import { InvitationService } from '../../invitation/shared/services/invitation.service';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { PatientTreatmentViewComponent } from '../../patient/patient-treatment-view/patient-treatment-view.component';
import { PatientService } from '../../patient/shared/patient.service';
export class MainCardComponent {
    constructor(utilService, authenticationService, activeRoute, router, patientService, merchantService, invitationService, customPhonePipe) {
        this.utilService = utilService;
        this.authenticationService = authenticationService;
        this.activeRoute = activeRoute;
        this.router = router;
        this.patientService = patientService;
        this.merchantService = merchantService;
        this.invitationService = invitationService;
        this.customPhonePipe = customPhonePipe;
        this.isModuleQuotebuilderActive = Settings.global['isModuleQuotebuilderActive'];
        this.isModuleGoogleReviewActive = Settings.global['isModuleGoogleReviewActive'];
        this.closeAction = false;
        this.hideContent = true;
        this.isDefered = false;
        this.isNotProceed = false;
        this.isProceed = false;
        this.isValidCard = true;
        this.isNotProceedApllicationChange = false;
        this.isNotProceedNoFinance = false;
        this.isNotProceedNoFinanceWithDiscount = false;
        this.hasResult = false;
        this.cardAccess = false;
        this.isProceedWithDiscount = false;
        this.isRemoved = false;
        this.isExpired = false;
        this.settings = Settings.global;
        this.thankYouFirstName = null;
        this.thankYouMerchantPhone = this.settings['contactPhone'];
        this.thankYouMerchantEmail = this.settings['contactEmail'];
        this.thankYouMerchantName = null;
        this.thankYouDentistPhone = this.settings['contactPhone'];
        this.thankYouDentistEmail = this.settings['contactEmail'];
        this.thankYouDentistName = null;
        this.thankyouThirdPartyBookingURL = null;
        this.weeksDefered = 'One Week';
        this.displayDiscount = false;
        this.context = Settings.global['context'];
        this.sessionType = 'guest';
        this.isModuleConsumerPortalActive = Settings.global['isModuleConsumerPortalActive'];
        this.viewOnly = false;
        this.redirection = false;
        this.isCountDown = true;
        this.accessByMerchant = false;
    }
    ngOnInit() {
        this.activeRoute.params.subscribe((params) => {
            if (params['consumerID']) {
                this.invitationID = params['consumerID'];
            }
            if (this.invitationID) {
                if (params['action']) {
                    this.actions = params['action'];
                }
                this.setup();
                AuthenticationService.loginEvent.subscribe((res) => {
                    if (this.hideContent == true) {
                        this.setup();
                    }
                });
            }
            else {
                this.router.navigate(['/404']);
            }
        });
    }
    setup() {
        this.authenticationService.getSessionType().subscribe((sessionType) => __awaiter(this, void 0, void 0, function* () {
            this.sessionType = sessionType;
            if (this.invitationID) {
                this.patientService.getCustomerInvitation(this.invitationID, null).subscribe((res) => __awaiter(this, void 0, void 0, function* () {
                    /* if(res.merchant['Google_Review_Place_ID']) {} */
                    if (res) {
                        this.customInv = res;
                        HeaderService.backInvitationButton.emit({ id: this.invitationID, display: false });
                        this.utilService.getAccessInvitationList(this.customInv['ID']).subscribe((access) => {
                            if (access) {
                                this.isModuleConsumerPortalActive = access['isModuleConsumerPortalActive'];
                                this.isModuleGoogleReviewActive = access['isModuleGoogleReviewActive'];
                            }
                            if (this.sessionType == 'customer' && this.isModuleConsumerPortalActive == false) {
                                HeaderService.hideCustomerPortal.emit(false);
                            }
                            else if (this.isModuleConsumerPortalActive == false) {
                                HeaderService.hideCustomerPortal.emit(true);
                            }
                            else {
                                HeaderService.hideCustomerPortal.emit(false);
                            }
                        });
                        const signupObject = {
                            cardID: null,
                            invitationID: null,
                            wakandaID: null,
                        };
                        if (res.customerID) {
                            signupObject.cardID = res.customerID;
                        }
                        if (this.invitationID) {
                            signupObject.wakandaID = this.invitationID;
                        }
                        if (res && res['ID']) {
                            signupObject.invitationID = res['ID'];
                        }
                        if (res && res.viewOnly == true && this.sessionType == 'customer') {
                            this.viewOnly = res.viewOnly;
                        }
                        this.cardAccess = res.cardAccess;
                        if (this.cardAccess == true) {
                            this.hideContent = false;
                        }
                        AuthenticationService.signupObject.emit(signupObject);
                        if (this.sessionType != 'customer' && res && res.merchant) {
                            HeaderService.MerchantName.emit(res.merchant['TradingAs']);
                            HeaderService.MerchantObject.emit(res.merchant);
                        }
                        if (res &&
                            res.invitation &&
                            res.invitation['PatientChoice.Code'] &&
                            res.remove != true &&
                            res.invitation['PatientChoice.Code'] === 'P_APP') {
                            this.isProceed = true;
                            if (res.invitation['Date.Activated'] !== '0000-00-00') {
                                this.status = 'activated';
                            }
                            else if (res.invitation['Date.Completed'] !== '0000-00-00') {
                                this.status = 'completed';
                            }
                            else if (res.invitation['Date.FinalApproval'] !== '0000-00-00') {
                                this.status = 'finalApproval';
                            }
                            else if (res.invitation['Date.Approved'] !== '0000-00-00') {
                                this.status = 'approved';
                            }
                            else if (res.invitation['Date.Declined'] !== '0000-00-00') {
                                this.status = 'declined';
                            }
                            else if (res.invitation['Date.Referred'] !== '0000-00-00') {
                                this.status = 'referred';
                            }
                            else if (res.invitation['Date.Commenced'] !== '0000-00-00') {
                                this.status = 'commenced';
                            }
                            else if (res.invitation['Date.PlanningToCommence'] !== '0000-00-00') {
                                this.status = 'palningToCommence';
                            }
                            else if (this.viewOnly != true &&
                                Settings.global['isWakandaIntegrated'] &&
                                Settings.global['isWakandaIntegrated'] === true) {
                                let invitationCard = Settings.global['wakandaInvitationCardLink'];
                                invitationCard = invitationCard.replace('_id_', this.invitationID);
                                window.location.href = invitationCard;
                            }
                        }
                        else if (res &&
                            res.merchant &&
                            res.invitation &&
                            res.dentist &&
                            (res.cardAccess == true || res.cardAccess == null)) {
                            /* this.displayGoogleReviews(res) */
                            this.thankYouFirstName = res.invitation['FirstName'];
                            this.thankYouMerchantPhone = res.merchant['phones.Number'];
                            this.thankYouMerchantEmail = res.merchant['emails.Email'];
                            this.thankYouMerchantName = res.merchant['CalculatedName'];
                            this.thankYouDentistPhone = res.dentist['emails.Email'];
                            this.thankYouDentistEmail = res.dentist['dentemails.Email'];
                            this.thankYouDentistName = res.dentist['CalculatedName'];
                            this.merchantInformedConsent = res.merchant['InformedConsent_Declaration'];
                            this.thankyouThirdPartyBookingURL = res.merchant['ThirdPartyBooking_URL'];
                            this.isValidCard = true;
                            this.dentistID = res.invitation['DentistContact_key'];
                            this.merchantID = res.invitation['Merchant_Key'];
                            this.ID = res.invitation['ID'];
                            this.thankyouHasBookedAppointment = yield this.invitationService
                                .hasAppointmentBooked(this.ID)
                                .toPromise();
                            if (this.customInv &&
                                this.customInv.invitation &&
                                this.customInv.invitation['CashDiscount.Expiry.Date'] != '0000-00-00') {
                                this.displayDiscount = moment().isBefore(moment(this.customInv['CashDiscount.Expiry.Date'], 'YYYY-MM-DD'));
                            }
                        }
                        else if (res.invitation && res.invitation.cardAccess == false) {
                            /* this.displayGoogleReviews(res) */
                            this.dentistID = res['DentistContact_key'];
                            this.merchantID = res['Merchant_Key'];
                            this.ID = res.ID;
                            this.thankyouHasBookedAppointment = yield this.invitationService
                                .hasAppointmentBooked(this.ID)
                                .toPromise();
                            this.thankYouFirstName = res.invitation['FirstName'];
                            this.thankYouMerchantPhone = res.merchant['phones.Number'];
                            this.thankYouMerchantEmail = res.merchant['emails.Email'];
                            this.thankYouMerchantName = res.merchant['CalculatedName'];
                            this.thankYouDentistPhone = res.dentist['emails.Email'];
                            this.thankYouDentistEmail = res.dentist['dentemails.Email'];
                            this.thankYouDentistName = res.dentist['CalculatedName'];
                            this.merchantInformedConsent = res.merchant['InformedConsent_Declaration'];
                            this.thankyouThirdPartyBookingURL = res.merchant['ThirdPartyBooking_URL'];
                            if (res.expired == true) {
                                this.isExpired = true;
                                this.hasResult = true;
                                this.status = 'expired';
                            }
                            else if (res.removed == true) {
                                this.isRemoved = true;
                                this.hasResult = true;
                                this.status = 'removed';
                            }
                            else if (res.invitation['PatientChoice.Code'] && res.invitation['PatientChoice.Code'] === 'DSMO') {
                                this.isNotProceedApllicationChange = true;
                                this.hasResult = true;
                                this.status = 'notProceed';
                            }
                            else if (res.invitation['PatientChoice.Code'] && res.invitation['PatientChoice.Code'] === 'NOT') {
                                this.isNotProceed = true;
                                this.hasResult = true;
                                this.status = 'notProceed';
                            }
                            else if (res.invitation['PatientChoice.Code'] && res.invitation['PatientChoice.Code'] === 'T_OTH') {
                                this.isNotProceedNoFinance = true;
                                this.hasResult = true;
                                this.status = 'notProceed';
                            }
                            else if (res.invitation['PatientChoice.Code'] && res.invitation['PatientChoice.Code'] === 'T_OTH_D') {
                                this.isNotProceedNoFinanceWithDiscount = true;
                                this.hasResult = true;
                                this.status = 'notProceed';
                            }
                            else if (res.invitation['PatientChoice.Code'] && res.invitation['PatientChoice.Code'] === 'T_OTH_G') {
                                this.isNotProceedNoFinance = true;
                                this.hasResult = true;
                                this.status = 'notProceed';
                            }
                            else if (res.invitation['PatientChoice.Code'] && res.invitation['PatientChoice.Code'] === 'DISC') {
                                this.isNotProceedNoFinance = true;
                                this.hasResult = true;
                                this.status = 'notProceed';
                            }
                            else if (res.invitation['PatientChoice.Code'] && res.invitation['PatientChoice.Code'] === 'DEFER') {
                                this.isDefered = true;
                                this.hasResult = true;
                                this.status = 'defered';
                            }
                        }
                        else {
                            this.router.navigate(['/404']);
                        }
                    }
                    else {
                        this.router.navigate(['/404']);
                    }
                }));
            }
        }));
    }
    closeEvent() {
        this.closeAction = true;
    }
    ngOnDestroy() {
        HeaderService.backInvitationButton.emit({ id: this.invitationID, display: true });
    }
    displayGoogleReviews(res) {
        if (res &&
            res.merchant['Google_Review_Place_ID'] &&
            res.invitation &&
            res.invitation['Send_Google_Review'] == '1' &&
            this.isModuleGoogleReviewActive == true) {
            const refReview = RootAppComponent.dialog.open(GoogleReviewsPromptDialogComponent, {
                width: '500px',
                panelClass: 'merchCard',
                data: {
                    merchantID: res.merchant['ID'],
                    patientName: res.invitation['FirstName'] + ' ' + res.invitation['LastName'],
                    practiceName: res.merchant['TradingAs'],
                },
            });
            refReview.componentInstance.close.subscribe((res) => {
                refReview.close();
            });
        }
    }
    consumerChoice(choice) {
        return __awaiter(this, void 0, void 0, function* () {
            if (choice && choice === 'DSMO') {
                this.isNotProceedApllicationChange = true;
                this.hideContent = true;
                this.hasResult = true;
            }
            else if (choice && choice === 'NOT') {
                this.isNotProceed = true;
                this.hideContent = true;
                this.hasResult = true;
            }
            else if (choice && choice === 'T_OTH') {
                this.isNotProceed = true;
                this.hideContent = true;
                this.hasResult = true;
            }
            else if (choice && choice === 'T_OTH_D') {
                this.isNotProceedNoFinanceWithDiscount = true;
                this.hideContent = true;
                this.hasResult = true;
            }
            else if (choice && choice === 'T_OTH_G') {
                this.isNotProceedNoFinance = true;
                this.hideContent = true;
                this.hasResult = true;
            }
            else if (choice && choice === 'DISC') {
                this.isNotProceedNoFinance = true;
                this.hideContent = true;
                this.hasResult = true;
            }
            else if (choice && choice === 'DEFER') {
                this.isDefered = true;
                this.hideContent = true;
                this.hasResult = true;
            }
            else if (this.customInv &&
                this.customInv.invitation &&
                choice &&
                choice.productID &&
                choice.choice &&
                choice.choice == 'P_APP' &&
                this.accessByMerchant == true) {
                this.thankYouFirstName = this.customInv.invitation['FirstName'];
                this.thankYouMerchantPhone = this.customInv.merchant['phones.Number'];
                this.thankYouMerchantEmail = this.customInv.merchant['emails.Email'];
                this.thankYouMerchantName = this.customInv.merchant['CalculatedName'];
                this.thankYouDentistPhone = this.customInv.dentist['emails.Email'];
                this.thankYouDentistEmail = this.customInv.dentist['dentemails.Email'];
                this.thankYouDentistName = this.customInv.dentist['CalculatedName'];
                this.isProceed = true;
                this.hideContent = true;
                this.hasResult = true;
                this.status = 'palningToCommence';
                this.customInv.Product_Key = choice.productID;
                this.thankyouHasBookedAppointment = yield this.invitationService
                    .hasAppointmentBooked(this.customInv.ID)
                    .toPromise();
            }
        });
    }
    deferedWeek(d) {
        if (d) {
            this.weeksDefered = d;
        }
    }
    proceedToWakanda() {
        if (this.invitationID) {
            let invitationCard = Settings.global['wakandaInvitationCardLink'];
            invitationCard = invitationCard.replace('_id_', this.invitationID);
            if (this.accessByMerchant != true) {
                window.location.href = invitationCard;
            }
            else {
                let merchantID;
                let productID;
                if (this.customInv['Merchant_Key']) {
                    merchantID = this.customInv['Merchant_Key'];
                }
                else if (this.customInv.invitation['Merchant_Key']) {
                    merchantID = this.customInv.invitation['Merchant_Key'];
                }
                if (this.customInv['Product_Key']) {
                    productID = this.customInv['Product_Key'];
                }
                else if (this.customInv.invitation['Product_Key']) {
                    productID = this.customInv.invitation['Product_Key'];
                }
                if (this.customInv && this.customInv.invitation) {
                    this.merchantService.canAssitPatientProduct(merchantID, productID, this.sessionType).subscribe((res) => {
                        if (res) {
                            const description = `<p>You need <strong>${this.customInv.invitation['FirstName']} ${this.customInv.invitation['LastName']}</strong>'s   ` +
                                ` consent to assist with the application process.</p>` +
                                `<p>If your patient has requested your assistance,   ` +
                                `click the button below and a secure code will be sent to your patient's mobile <strong> :  ` +
                                `${this.customPhonePipe.transform(this.customInv.invitation['Mobile'])} </strong>. </p>` +
                                `<p>Once you have the code, you'll need to enter it on the next screen. </p>` +
                                `<p><strong>IMPORTANT - Only request a code if the patient has asked for your assistance.</strong></p>`;
                            const ref2 = RootAppComponent.dialog.open(SecurityCodeInfoComponent, {
                                data: {
                                    mobileNumber: this.customInv.invitation['Mobile'],
                                    description,
                                },
                                width: '700px',
                            });
                            ref2.componentInstance.closeModal.subscribe((res) => {
                                ref2.close();
                            });
                            ref2.componentInstance.onConfirm.subscribe((res) => {
                                ref2.close();
                                ref2.afterClosed().subscribe((afterClosed) => {
                                    if (res == true) {
                                        let securityCodeID;
                                        let securityCode;
                                        this.invitationService.requestInvitationCode(this.customInv['ID']).subscribe((res) => {
                                            if (res) {
                                                const smsSent = true;
                                                let emailSent = false;
                                                if (res && res['Send.Email'] == '1') {
                                                    emailSent = true;
                                                }
                                                securityCodeID = res['ID'];
                                                const ref = RootAppComponent.dialog.open(SecurityCodeInput, {
                                                    data: {
                                                        smsSent,
                                                        emailSent,
                                                    },
                                                    width: '600px',
                                                    panelClass: 'noCard',
                                                });
                                                ref.componentInstance.closeModal.subscribe((res) => {
                                                    ref.close();
                                                });
                                                ref.componentInstance.sendCode.subscribe((code) => {
                                                    this.invitationService.requestInvitationCode(this.customInv['ID']).subscribe((res) => {
                                                        securityCodeID = res['ID'];
                                                        NotifyAppComponent.displayToast('success', 'Successfull', 'Authorisation code is sent again');
                                                    });
                                                });
                                                ref.componentInstance.getCode.subscribe((code) => {
                                                    if (code) {
                                                        securityCode = code;
                                                        const payload = {};
                                                        payload.securityCode = securityCode;
                                                        payload.securityCodeID = securityCodeID;
                                                        this.invitationService
                                                            .validateInvitationCode(this.customInv['ID'], payload)
                                                            .subscribe((res) => {
                                                            if (res) {
                                                                ref.close();
                                                                NotifyAppComponent.displayToast('success', 'Successfull selected', 'You will be redirecting to a page to finish you application ');
                                                                window.open(invitationCard, '_blank');
                                                            }
                                                        });
                                                    }
                                                });
                                            }
                                        });
                                    }
                                });
                            });
                        }
                        else {
                            const refError = RootAppComponent.dialog.open(ErrorModalComponent, {
                                data: {
                                    content: `<p><strong>      You do not have access to make this decision on behalf of your patient.</strong></p>` +
                                        `<p style='margin:5px' >  Contact the help desk to request permission.</p>` +
                                        `<p style='margin:5px' > Alternatively, your patient can proceed with an application for finance themselves by clicking on the links sent to them by email and SMS.
                    </p>` +
                                        "<p style='margin:5px' >Questions? Please contact us. </p>",
                                    title: 'Sorry!',
                                    displayContact: true,
                                },
                                width: '700px',
                            });
                            const sub = refError.componentInstance.closeModal.subscribe((data) => {
                                refError.close();
                            });
                        }
                    });
                }
            }
        }
    }
    viewTreatment(e) {
        if (this.ID && e == true) {
            const ref = RootAppComponent.dialog.open(PatientTreatmentViewComponent, {
                data: {
                    invitationID: this.ID,
                },
                width: '850px',
            });
            ref.componentInstance.close.subscribe((res) => {
                ref.close();
            });
        }
    }
}
