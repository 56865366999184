<mat-card class="rel" @ngIfAnimation style="margin-bottom: 10px">
  <div class="card-header primary-gradient-img clearfix stacked" *ngIf="isModal">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="clearfix">
        <h2 class="sm-h2 pull-left rm-mb">Schedule Appointment</h2>
      </div>
    </div>
  </div>

  <form #applyForm="ngForm">
    <mat-dialog-content class="stacked-card-view">
      <div class="firstScreen" [ngClass]="{ active: isFirstScreen }">
        <div class="leftContainer">
          <div class="full-width flex">
            <h4 class="primary-color subtitle rm-mb"
                [ngClass]="{ 'rm-mt': !isEmbedded }">{{"KEYWORD.practitioner" | translate | titlecase}}</h4>
          </div>

          <div class="full-width flex">
            <mat-form-field class="full-width small" appearance="outline">
              <mat-select
                class="full-width"
                [(ngModel)]="selectedDentist"
                (ngModelChange)="selectedDentistChange($event)"
                name="dentist"
                [placeholder]="'Select a ' + ('KEYWORD.practitioner' | translate)"
                required
              >
                <mat-option *ngFor="let o of dentistOptions" [value]="o">
                  {{ o['CalculatedName'] }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="full-width flex">
            <h4 class="primary-color subtitle rm-mb rm-mt">Appointment Type</h4>
          </div>

          <div class="full-width">
            <mat-form-field class="full-width small" appearance="outline">
              <mat-select
                [(ngModel)]="type"
                [matTooltip]="type ? replaceAllString(type.Description) : ''"
                (ngModelChange)="addSubType()"
                name="reasonOfVisit"
                placeholder="Reason of visit"
                matTooltipPosition="after"
                required
              >
                <mat-option class="label2" *ngFor="let b of types" [value]="b">
                  {{ b.Label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <ng-container *ngIf="subTypes && subTypes.length > 0">
            <div class="full-width">
              <mat-form-field class="full-width small" appearance="outline">
                <mat-select
                  [(ngModel)]="subType"
                  [matTooltip]="subType ? replaceAllString(subType.Description) : ''"
                  (ngModelChange)="subTypeSelect()"
                  name="bestContactTimetypesubTypes"
                  placeholder="More details"
                  matTooltipPosition="after"
                >
                  <mat-option class="label2" *ngFor="let b of subTypes" [value]="b">
                    {{ b.Label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>

          <div class="full-width flex">
            <h4 class="primary-color subtitle rm-mb rm-mt">Duration</h4>
          </div>

          <div class="full-width flex">
            <mat-form-field class="full-width small" appearance="outline">
              <mat-select
                class="full-width"
                [(ngModel)]="duration"
                (ngModelChange)="selectedDurationChange($event)"
                name="duration"
              >
                <mat-option *ngFor="let o of durations" [value]="o.value">
                  {{ o.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="full-width flex">
            <h4 class="primary-color subtitle rm-mb rm-mt">Buffer</h4>
          </div>

          <div class="bufferBefore flex">
            <mat-form-field class="full-width small" appearance="outline">
              <mat-label>Before appointment</mat-label>
              <mat-select
                class="full-width"
                [(ngModel)]="bufferBefore"
                (ngModelChange)="selectedBufferChange($event)"
                name="bufferBefore"
              >
                <mat-option *ngFor="let o of buffers" [value]="o.value">
                  {{ o.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="bufferAfter flex">
            <mat-form-field class="full-width small" appearance="outline">
              <mat-label>After appointment</mat-label>
              <mat-select
                class="full-width"
                [(ngModel)]="bufferAfter"
                (ngModelChange)="selectedBufferChange($event)"
                name="bufferAfter"
              >
                <mat-option *ngFor="let o of buffers" [value]="o.value">
                  {{ o.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <ng-container *ngIf="merchantWorkingHours && !isLoadingInit">
            <div class="full-width flex">
              <h4 class="primary-color subtitle rm-mb rm-mt">Choose the date and time</h4>
            </div>

            <mat-calendar
              class="clearfix full-width"
              [selected]="selectedDate"
              [minDate]="dateNow"
              [maxDate]="maxScheduleDate"
              [dateFilter]="filterDates"
              [dateClass]="dateClass()"
              (selectedChange)="selectedDateChanged($event)"
              (monthSelected)="getAppointmentsOfMonth($event)"
            >
            </mat-calendar>

            <div class="timeZone flex cleafix full-width mb" *ngIf="merchantTimezone">
              <mat-icon class="ml-auto">public</mat-icon>
              <p class="small rm-mt mr-auto">{{ merchantTimezone }} ({{ tzTimeStr }})</p>
            </div>

            <div class="clearfix full-width" style="border-top: 1px rgba(0, 0, 0, 0.12) solid">
              <div class="full-width clearfix flex">
                <div class="full-width mr ml flex">
                  <p class="legend-color full-width rm-m small-margin-right small text-right" color="primary">100%</p>

                  <div class="full-width legend-color used-percent-0" matTooltip="100% available for booking"></div>

                  <div class="full-width legend-color used-percent-10" matTooltip="90% available for booking"></div>

                  <div class="full-width legend-color used-percent-20" matTooltip="80% available for booking"></div>

                  <div class="full-width legend-color used-percent-30" matTooltip="70% available for booking"></div>
                  <div class="full-width legend-color used-percent-40" matTooltip="60% available for booking"></div>
                  <div class="full-width legend-color used-percent-50" matTooltip="50% available for booking"></div>
                  <div class="full-width legend-color used-percent-60" matTooltip="40% available for booking"></div>
                  <div class="full-width legend-color used-percent-70" matTooltip="30% available for booking"></div>
                  <div class="full-width legend-color used-percent-80" matTooltip="20% available for booking"></div>
                  <div class="full-width legend-color used-percent-90" matTooltip="10% available for booking"></div>

                  <div class="full-width legend-color used-percent-100" matTooltip="Fully booked day"></div>
                  <p class="legend-color full-width rm-m small-margin-left small text-l" color="primary">0%</p>
                </div>
              </div>
              <label class="clearfix full-width text-center mb">Day availability for booking</label>
            </div>
          </ng-container>
        </div>

        <div class="rightContainer">
          <div class="dateSelected">
            {{ selectedDate | customDate: 'dddd, MMMM DD YYYY':false }}
          </div>
          <div class="timeOptionsContainer" *ngIf="dateTimeOptions.length > 0; else noTime">
            <ng-container *ngFor="let time of dateTimeOptions; let idx = index">
              <ng-container *ngIf="time.value.ID; else timeCard">
                <app-schedule-appointment-time-card
                  class="timeOptionChip appointmentTimeCard"
                  [ngClass]="{ mockTimeCard: time.value.ID === 'mock' }"
                  [appointment]="time.value"
                  [style.borderColor]="time.value.ID !== 'mock' ? appointmentUtil.getStatusColor(time.value) : ''"
                  [style.color]="time.value.ID !== 'mock' ? appointmentUtil.getStatusColor(time.value) : ''"
                  (cancelMockAppointment)="removeMockAppointment(true)"
                ></app-schedule-appointment-time-card>
              </ng-container>
              <ng-template #timeCard>
                <div
                  class="timeOptionChip"
                  [ngClass]="{
                    'd-none':
                      mockAppointment &&
                      mockAppointment._unixStart <= time.value.getTime() &&
                      mockAppointment._unixEnd > time.value.getTime(),
                    disabled: !time.enabled,
                    confirm: time.confirm,
                    active: selectedDateTime && time.value.getTime() === selectedDateTime.getTime()
                  }"
                  (click)="confirmTime(time)"
                >
                  {{ time.value | customDate: 'h:mm a':false }}
                </div>

                <div
                  class="timeOptionConfirmChip"
                  [ngClass]="{
                    confirm: time.confirm,
                    'd-none':
                      mockAppointment &&
                      mockAppointment._unixStart <= time.value.getTime() &&
                      mockAppointment._unixEnd > time.value.getTime()
                  }"
                  (click)="selectTime(time, idx)"
                >
                  Confirm
                </div>
              </ng-template>
            </ng-container>
          </div>
          <ng-template #noTime>
            <div class="timeOptionsContainer">
              <div *ngIf="selectedDentist && !isLoadingDateTimeOptions">No work for this day</div>
              <div class="spinnerContainer" *ngIf="isLoadingDateTimeOptions">
                <mat-spinner diameter="20"></mat-spinner>
              </div>
              <div *ngIf="!selectedDentist">Please select {{"KEYWORD.practioner" | translate}}</div>
            </div>
          </ng-template>
        </div>
      </div>

      <div class="nextScreen" [ngClass]="{ active: !isFirstScreen }">
        <div class="full-width flex">
          <h4 class="primary-color subtitle rm-mb rm-mt">{{"KEYWORD.patient" | translate | titlecase}} Details</h4>
        </div>

        <div class="full-width flex clearfix" *ngIf="appointment">
          <div class="clearfix customer-picture text-center">
            <app-customer-prospect-view-picture
              class="supplierIconAppointmentSchedule pull-left mr"
              [hideZoomButton]="false"
              [patientID]="appointment['CustomerOrProspect_Key']"
            >
            </app-customer-prospect-view-picture>
          </div>
          <div class="clearfix">
            <div
              class="full-width flex appointment-details-line"
              *ngIf="appointment['CustomerOrProspect_CalculatedName']"
            >
              <p class="full-width patient-details">
                <mat-icon class="fas fa-user appointment-details-line-icon mr" color="accent"></mat-icon>
                {{ appointment['CustomerOrProspect_CalculatedName'] }}
              </p>
            </div>
            <div class="full-width flex appointment-details-line" *ngIf="appointment['CustomerOrProspect_Mobile']">
              <p class="full-width patient-details">
                <mat-icon class="fas fa-phone appointment-details-line-icon mr" color="accent"></mat-icon>
                {{ appointment.CustomerOrProspect_Mobile | customPhone }}
              </p>
            </div>
            <div class="full-width flex appointment-details-line" *ngIf="appointment['CustomerOrProspect_Email']">
              <p class="full-width patient-details">
                <mat-icon class="fas fa-at appointment-details-line-icon mr" color="accent"></mat-icon>
                {{ appointment.CustomerOrProspect_Email }}
              </p>
            </div>
          </div>
        </div>

        <div class="full-width flex mt">
          <h4 class="primary-color subtitle rm-mb rm-mt mb">Appointment Details</h4>
        </div>

        <div class="full-width flex appointment-details-line" *ngIf="selectedDentist && selectedDentist.CalculatedName">
          <p class="half-width">
            <mat-icon class="fas fa-user appointment-details-line-icon" color="accent"></mat-icon>
            <strong> Practioner</strong>
          </p>
          <p class="full-width">
            <span>{{ selectedDentist.CalculatedName }} </span>

            <button (click)="viewDentist(selectedDentist.ID)" mat-icon-button color="accent">
              <mat-icon>visibility</mat-icon>
            </button>
          </p>
        </div>

        <div class="full-width flex appointment-details-line" *ngIf="type && type.Label">
          <p class="half-width">
            <mat-icon class="fas fa-heartbeat appointment-details-line-icon" color="accent"></mat-icon>
            <strong> Reason for visiting</strong>
          </p>
          <div class="full-width">
            <p class="full-width">
              <span matTooltip=" {{ type.Description | cleanString }}">
                {{ type.Label }}
              </span>
              <span *ngIf="subType && subType.Label" matTooltip=" {{ subType.Description | cleanString }}"
              >- {{ subType.Label }}</span
              >
            </p>
          </div>
        </div>

        <div class="full-width flex appointment-details-line">
          <p class="half-width">
            <mat-icon class="fas fa-calendar appointment-details-line-icon" color="accent"></mat-icon>
            <strong>Date & Time</strong>
          </p>
          <div class="full-width">
            <p class="full-width">
              {{ getDateTimeDuration() }}
            </p>
          </div>
        </div>

        <div class="full-width flex appointment-details-line" *ngIf="duration">
          <p class="half-width">
            <mat-icon class="fas fa-clock appointment-details-line-icon" color="accent"></mat-icon>
            <strong>Duration</strong>
          </p>
          <div class="full-width">
            <p class="full-width">{{ duration }} minutes</p>
          </div>
        </div>

        <div class="full-width flex mt">
          <h4 class="primary-color subtitle rm-mb rm-mt mb">Notification Options</h4>
        </div>

        <div class="clearfix full-width mb">
          <mat-slide-toggle class="clearfix" [(ngModel)]="smsConfirmAppointment" name="checkboxValue"
          >Send SMS to confirm appointment
          </mat-slide-toggle>
        </div>

        <div class="clearfix full-width mb">
          <mat-slide-toggle class="clearfix" [(ngModel)]="smsReminderAppointment" name="checkboxValueSecond"
          >Send SMS reminder 24hrs before the appointment
          </mat-slide-toggle>
        </div>

        <div class="clearfix full-width">
          <mat-slide-toggle class="clearfix" [(ngModel)]="googleReviewPrompt" name="promptGoogleReview">
            Prompt {{ "KEYWORD.patient" | translate }} to provide a google review
          </mat-slide-toggle>
        </div>

        <div class="full-width mt">
          <h4 class="primary-color subtitle rm-mb rm-mt">3rd party calendars</h4>

          <p class="clearfix small">
            Add your appointment to your favorit Calendar, Connect your 3rd party calendars including iCloud, Microsoft
            and Google.
          </p>
        </div>

        <div class="clearfix full-width flex mb">
          <div class="full-width mr">
            <div class="clearfix full-width mt">
              <mat-slide-toggle class="clearfix full-width" [(ngModel)]="addToAppleCalendar" name="addToAppleCalendar">
                <mat-icon class="fab fa-apple"></mat-icon> Add To Icloud Calendar
              </mat-slide-toggle>
            </div>
            <div class="clearfix full-width mt">
              <mat-slide-toggle
                class="clearfix full-width"
                [(ngModel)]="addToGoogleCalendar"
                name="addToGoogleCalendar"
              >
                <mat-icon class="fab fa-google"></mat-icon> Add To Google Calendar
              </mat-slide-toggle>
            </div>

            <div class="clearfix full-width mt">
              <mat-slide-toggle
                class="clearfix full-width"
                [(ngModel)]="addToMicrosoftCalendar"
                name="addToMicrosoftCalendar"
              >
                <mat-icon class="fab fa-microsoft"></mat-icon> Add to Microsoft Calendar
              </mat-slide-toggle>
            </div>
          </div>
        </div>

        <div class="full-width flex mt mb">
          <h4 class="primary-color subtitle rm-mb rm-mt">Provide a personalised message to
            the {{"KEYWORD.patient" | translate}}</h4>
        </div>

        <mat-form-field class="clearfix full-width mb text-input" appearance="outline">
          <textarea
            class="replymsg-c"
            [(ngModel)]="comment"
            matInput
            [placeholder]="'Optionally add a comment that will be seen by your ' + ('KEYWORD.patient' | translate)"
            name="msg"
          ></textarea>
        </mat-form-field>
      </div>
    </mat-dialog-content>

    <div class="drop-buttons" [style.visibility]="isEmbedded ? 'hidden' : 'visible'">
      <button class="ml" *ngIf="!isFirstScreen" (click)="back()" mat-raised-button color="accent">
        <span>Back</span>
      </button>

      <button
        class="ml"
        *ngIf="isFirstScreen"
        [disabled]="isStep1Invalid()"
        (click)="next()"
        mat-raised-button
        color="accent"
      >
        <span>Next</span>
      </button>

      <button
        class="ml"
        *ngIf="!isFirstScreen"
        [disabled]="isStep2Invalid()"
        (click)="submit()"
        mat-raised-button
        color="primary"
      >
        <span>{{ buttonLabel }}</span>
      </button>
    </div>
  </form>
</mat-card>

<app-appointment-action-hidden [runAction]="runAction" (result)="getActionResult($event)">
</app-appointment-action-hidden>
