import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Inject, Input, OnInit, Optional, ViewChild, ViewContainerRef } from '@angular/core';

import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { ExistingPatientService } from '../../existing-patient/shared/existing-patient.service';
import { AddressEditModalComponent } from '../address-edit-modal/address-edit-modal.component';
import { ContactDetailsEditModalComponent } from '../contact-details-edit-modal/contact-details-edit-modal.component';

@Component({
  selector: 'app-prospect-details-card-edit',
  templateUrl: './prospect-details-card-edit.component.html',
  styleUrls: ['./prospect-details-card-edit.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('mat-card', style({ opacity: 0 }), { optional: true }),
        query(
          'mat-card',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: '0' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('mat-card', style({ opacity: 1 }), { optional: true }),
        query(
          'mat-card',
          stagger('100ms', [
            animate(
              '0.2s ease-out',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: '0' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ProspectDetailsCardEditComponent implements OnInit {
  @Input() prospectID;
  @Input() customerObject;
  @Input() dateTo;
  @Input() DateFrom;

  sourceID;

  checkedEmail = true;
  checkedAddress = true;
  checkedMobile = true;
  checkedReferral = true;

  editMode1 = true;
  editMode2 = true;
  editMode3 = true;
  editMode4 = true;
  editMode5 = true;
  editMode6 = true;
  editMode7 = true;
  editMode8 = true;

  genders = [];
  titles = [];
  cardTypes = [];
  occupation = [];
  relationshipStatus = [];
  contactMethods = [];
  contactTypes = [];
  contactTimes = [];
  cardStatus = [];
  messageTypes = [];
  idTypes = [];
  customerGroups = [];

  displayedColumns = ['LastName', 'FirstName', 'ContactType', 'mobile', 'Mailout', 'messageType', 'Actions'];

  displayedColumnsQ = ['Date', 'QuotationStatus', 'ContractStatus', 'LoanAmount', 'Product', 'ProductType', 'Actions'];
  displayedColumnsC = ['Date', 'ContractStatus', 'LoanAmount', 'Product', 'ProductType', 'Actions'];
  displayedColumnsPM = ['CardType', 'BSB', 'AccountNumber', 'Expiry', 'AccountHolder', 'Active', 'Actions'];
  displayedColumnsPH = ['DueDate', 'Paid', 'Amount', 'Status', 'Rejected', 'Type', 'Actions'];
  displayedColumnsM = ['Description', 'Type', 'Status', 'Written', 'Submitted', 'Sent', 'Time', 'Actions'];
  displayedColumnsAL = ['ActionType', 'Subject', 'Date', 'Time', 'Recontact', 'FromToOperator', 'Actions'];
  displayedColumnsCC = ['InitialDate', 'FinalDate', 'InitialResult', 'FinalResult', 'Product', 'ContractID', 'Actions'];
  displayedColumnsCCC = [
    'InitialDate',
    'FinalDate',
    'InitialResult',
    'FinalResult',
    'Product',
    'ContractID',
    'Actions',
  ];
  displayedColumnsIDC = [
    'InitialDate',
    'FinalDate',
    'InitialResult',
    'FinalResult',
    'Product',
    'ContractID',
    'Actions',
  ];
  displayedColumnsID = ['IDType', 'Current', 'Locked', 'IDNumber', 'Issued', 'Expiry', 'DMS', 'Actions'];
  displayedColumnsD = ['Label', 'Description', 'Extension', 'Version', 'LastModified', 'Type', 'Area', 'Actions'];

  customer = {};
  isReferral = false;
  subjectArray = [];
  selectedCustomer = {};

  // pages

  page = 'profilePage';

  // profile
  mainInformation = true;
  marketingInformation = true;
  logonsDetails = true;
  addressDetails = true;
  contactDetails = true;
  lendingDetails = true;
  messageDetails = true;
  invitationDetails = true;
  actionlogDetails = true;
  verificationDetails = true;
  documentsInformation = true;
  preferencesDetails = true;
  profileImage = true;
  isPromoterOrAdmin = false;

  electronicConsent = true;
  marketingConsent = false;
  displayDetailsCard = false;
  entityTypeSet = 'consumer';

  isAdressValid = false;
  mySuburb;
  myState;
  myPostCode;
  myStreetNumber;
  myStreetName;
  myUnitNumber;
  myStreetType;
  myLatitude;
  myLongitude;
  myOccupation;
  myAccountManager;
  phone;
  mobile;
  email;
  fax;
  birthday;
  validateOccupation = false;

  entityTypes = [
    { value: 'cons-0', viewValue: 'Consumer - Individual' },
    { value: 'cons-1', viewValue: 'Consumer - Joint Borrowers' },
    { value: 'comm-0', viewValue: 'Commercial - Sole Trader' },
    { value: 'comm-1', viewValue: 'Commercial - Company' },
    { value: 'comm-2', viewValue: 'Commercial - Partnership' },
    { value: 'comm-3', viewValue: 'Commercial - Trust' },
  ];
  industryTypes = [
    { value: 'indus-0', viewValue: 'Accommodation and Food Services' },
    { value: 'indus-1', viewValue: 'Administrative and Support Services' },
    { value: 'indus-2', viewValue: 'Agriculture, Forestry and Fishing' },
    { value: 'indus-3', viewValue: 'Arts and Recreational Services' },
    { value: 'indus-4', viewValue: 'Construction' },
    { value: 'indus-5', viewValue: 'Education and Training' },
    { value: 'indus-6', viewValue: 'Electricity, Gas, Water and Waste Services' },
    { value: 'indus-7', viewValue: 'Financial and Insurance Service' },
    { value: 'indus-8', viewValue: 'Health Care and Social Assitance' },
    { value: 'indus-9', viewValue: 'Information Media and Telecommunications' },
    { value: 'indus-10', viewValue: 'Manufacturing' },
    { value: 'indus-11', viewValue: 'Mining' },
    { value: 'indus-12', viewValue: 'Professional, Scientific and Technical Services' },
    { value: 'indus-13', viewValue: 'Public Administration and Safety' },
    { value: 'indus-14', viewValue: 'Retail Trade' },
    { value: 'indus-15', viewValue: 'Transport, Postal and Warehousing' },
    { value: 'indus-16', viewValue: 'Wholesale Trade' },
    { value: 'indus-17', viewValue: 'Other Services' },
    { value: 'indus-18', viewValue: 'Inadequately described or not stated' },
  ];

  referralSources = [
    { value: 'ref-0', viewValue: 'TV' },
    { value: 'ref-1', viewValue: 'Radio' },
    { value: 'ref-2', viewValue: 'Newspaper' },
    { value: 'ref-3', viewValue: 'Social Media' },
    { value: 'ref-4', viewValue: 'Magazine' },
    { value: 'ref-5', viewValue: 'Local Advertisement' },
    { value: 'ref-6', viewValue: 'Other' },
  ];

  autoHide;
  invitations: any;
  existingPatientID; // = "6E744472500ACF4683AAAFC815FF53E5";
  sessionType = 'guest';

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private location: Location,
    private existingPatientService: ExistingPatientService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    private lookupService: LookupService,
    private snackBar: MatSnackBar,
    public viewContainerRef: ViewContainerRef,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.propsectID) {
        this.prospectID = data.prospectID;
      }
    }
  }

  openSnackBar() {
    const config = new MatSnackBarConfig();
    config.duration = 3500;
    config.viewContainerRef = this.viewContainerRef;
    this.snackBar.open("Don't forget to save your changes", 'Dismiss', config);
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        if (params['prospectID']) {
          this.prospectID = params['prospectID'];
        }

        this.authenticationService.getSessionType().subscribe((sessionType) => {
          if (sessionType) {
            this.sessionType = sessionType;
          }
          this.existingPatientService.getProspectDetails(this.prospectID, {}, this.sessionType).subscribe((res) => {
            this.selectedCustomer = res;

            this.mySuburb = this.selectedCustomer['addresses.Suburb'];
            this.myState = this.selectedCustomer['addresses.State'];
            this.myPostCode = this.selectedCustomer['addresses.Postcode'];
            this.myStreetNumber = this.selectedCustomer['addresses.Street Nr'];
            this.myStreetName = this.selectedCustomer['addresses.Street Name'];
            this.myUnitNumber = this.selectedCustomer['addresses.Unit'];
            this.myStreetType = this.selectedCustomer['addresses.Street Type'];
            this.myLatitude = this.selectedCustomer['Address.Latitude'];
            this.myLongitude = this.selectedCustomer['Address.Longitude'];
            this.myOccupation = this.selectedCustomer['Occupation'];
            this.myAccountManager = this.selectedCustomer['AccountManager'];
            if (this.selectedCustomer['emails.Send'] == '1') {
              this.checkedEmail = true;
            } else {
              this.checkedEmail = false;
            }
            if (this.selectedCustomer['mobiles.Send'] == '1') {
              this.checkedMobile = true;
            } else {
              this.checkedMobile = false;
            }
            if (this.selectedCustomer['addresses.Send'] == '1') {
              this.checkedAddress = true;
            } else {
              this.checkedAddress = false;
            }
            if (this.selectedCustomer['ReferredCustomer'] == '1') {
              this.checkedReferral = true;
            } else {
              this.checkedReferral = false;
            }
          });
          // this.dentistService.getContactDetails(this.myAccountManager).subscribe(res => {
          //   console.log(res);
          // })

          this.lookupService.getLookup('CodeLookup', 'CardType').subscribe((data) => {
            this.cardTypes = data;
          });
          this.lookupService.getLookup('CodeLookup', 'Gender').subscribe((data) => {
            this.genders = data;
          });
          this.lookupService.getLookup('SimpleLookup', 'Salutation').subscribe((data) => {
            this.titles = data;
          });
          this.lookupService.getLookup('SimpleLookup', 'Occupation').subscribe((data) => {
            this.occupation = data;
          });
          this.lookupService.getLookup('CodeLookup', 'RelationshipStatus').subscribe((data) => {
            this.relationshipStatus = data;
          });
          this.lookupService.getLookup('CodeLookup', 'CardStatus').subscribe((data) => {
            this.cardStatus = data;
          });
          this.lookupService.getLookup('CodeLookup', 'ContactMethod').subscribe((data) => {
            this.contactMethods = data;
          });
          this.lookupService.getLookup('CodeLookup', 'ContactTime').subscribe((data) => {
            this.contactTimes = data;
          });
          this.lookupService.getLookup('CodeLookup', 'MessageType').subscribe((data) => {
            this.messageTypes = data;
          });
          this.lookupService.getLookup('CodeLookup', 'ID_Type').subscribe((data) => {
            this.idTypes = data;
          });
          // this.lookupService.getLookup("CodeLookup", "IndustryType").subscribe(data => {
          //   this.industryTypes = data;
          // });
        });
      });
    });
  }

  getAdress(adr) {
    if (adr) {
      this.mySuburb = adr.suburb;
      this.myState = adr.state;
      this.myPostCode = adr.postCode;
      this.myStreetNumber = adr.streetNumber;
      this.myStreetName = adr.streetName;
      this.myUnitNumber = adr.unitNumber;
      this.myStreetType = adr.streetType;
      this.myLatitude = adr.latitude;
      this.myLongitude = adr.longitude;
    }
  }

  validateOccupationEvent(v) {
    this.validateOccupation = v;
  }

  getOccupation(o) {
    if (o && o['ID']) {
      this.myOccupation = o.occupationLabel;
    }
  }

  getMobile(m) {
    this.mobile = m;
  }

  getPhone(p) {
    this.phone = p;
  }

  getFax(f) {
    this.fax = f;
  }

  getEmail(e) {
    this.email = e;
  }

  getDate(b) {
    this.birthday = b;
  }

  getPhoneLabels(selectedCustomer) {
    let result = [];
    if (selectedCustomer && selectedCustomer['phones.Labels']) {
      result = selectedCustomer['phones.Labels'].split(';');
    }
    return result;
  }

  getNumber(label) {
    if (label == 'Work') {
      return this.selectedCustomer['Phone.Work.Number'];
    } else if (label == 'Home') {
      return this.selectedCustomer['Phone.Home.Number'];
    } else if (label == 'Daytime') {
      return this.selectedCustomer['Phone.Daytime.Number'];
    } else if (label == 'Mobile') {
      return this.selectedCustomer['Phone.Mobile.Number'];
    } else if (label == 'Weekend') {
      return this.selectedCustomer['Phone.Weekend.Number'];
    } else {
      return null;
    }
  }

  getMobileLabels(selectedCustomer) {
    let result = [];
    if (selectedCustomer && selectedCustomer['mobiles.Labels']) {
      result = selectedCustomer['mobiles.Labels'].split(';');
    }
    return result;
  }

  getEmailLabels(selectedCustomer) {
    let result = [];
    if (selectedCustomer && selectedCustomer['emails.Labels']) {
      result = selectedCustomer['emails.Labels'].split(';');
    }
    return result;
  }

  getEmailAddress(label) {
    if (label == 'Business') {
      return this.selectedCustomer['email.Business'];
    } else if (label == 'Work') {
      return this.selectedCustomer['email.Work'];
    } else if (label == 'Home') {
      return this.selectedCustomer['email.Home'];
    } else if (label == 'Personal') {
      return this.selectedCustomer['email.Personal'];
    } else if (label == 'Relative') {
      return this.selectedCustomer['email.Relative'];
    } else {
      return null;
    }
  }

  getAddressLabels(selectedCustomer) {
    let result = [];
    if (selectedCustomer && selectedCustomer['addresses.Labels']) {
      result = selectedCustomer['addresses.Labels'].split(';');
    }
    return result;
  }

  getDiffAddress(label) {
    if (label == 'Physical') {
      return this.selectedCustomer['address.Physical.Calculated'];
    } else if (label == 'Work') {
      return this.selectedCustomer['address.Work.Calculated'];
    } else if (label == 'Home') {
      return this.selectedCustomer['address.Home.Calculated'];
    } else if (label == 'Postal') {
      return this.selectedCustomer['address.Postal.Calculated'];
    } else if (label == 'Holiday') {
      return this.selectedCustomer['address.Holiday.Calculated'];
    } else if (label == 'Relative') {
      return this.selectedCustomer['address.Relative.Calculated'];
    } else {
      return null;
    }
  }

  editMainInfo() {
    const mainInfo = {
      cardType: this.selectedCustomer['CardType'],
      title: this.selectedCustomer['Salutation'],
      firstName: this.selectedCustomer['FirstName'],
      midleName: this.selectedCustomer['MiddleName'],
      lastName: this.selectedCustomer['Name'],
      preferredName: this.selectedCustomer['PreferredName'],
    };
    this.editDetails(mainInfo);
    this.editMode1 = true;
  }

  editAddedInfo() {
    const addedInfo = {
      status: this.selectedCustomer['Status'],
      accountManager: this.selectedCustomer['AccountManager'],
      gender: this.selectedCustomer['Gender.Label'],
      birthday: this.selectedCustomer['DateOfBirth'],
      relationshipStatus: this.selectedCustomer['RelationshipStatus'],
      occupation: this.selectedCustomer['Occupation'],
    };
    this.editDetails(addedInfo);
    this.editMode2 = true;
  }

  editEmergencyContacts() {
    const emergContacts = {
      carerName: this.selectedCustomer['Carer.FullName'],
      emergencyName: this.selectedCustomer['Emergency.FullName'],
      gpName: this.selectedCustomer['GP.FullName'],
      dentistName: this.selectedCustomer['Dentist.FullName'],
      guardianName: this.selectedCustomer['Guardian.FullName'],
      /// Insert Contact Numbers here using ID to query Contact table ////
    };
    this.editDetails(emergContacts);
    this.editMode3 = true;
  }

  editAddressDetails() {
    const addressDetails = {
      primaryAddress: this.selectedCustomer['addresses.Calculated'],
      primaryAddressLabel: this.selectedCustomer['addresses.Primary'],
      addressPropertyName: this.selectedCustomer['addresses.Property'],
      streetName: this.myStreetName,
      streetNumber: this.myStreetNumber,
      streetType: this.myStreetType,
      unitNumber: this.myUnitNumber,
      latitude: this.myLatitude,
      longitude: this.myLongitude,
      suburb: this.mySuburb,
      state: this.myState,
      postCode: this.myPostCode,
      holidayAddress: this.selectedCustomer['address.Holiday.Calculated'],
      homeAddress: this.selectedCustomer['address.Home.Calculated'],
      physicalAddress: this.selectedCustomer['address.Physical.Calculated'],
      postalAddress: this.selectedCustomer['address.Postal.Calculated'],
      relativeAddress: this.selectedCustomer['address.Relative.Calculated'],
      workAddress: this.selectedCustomer['address.Work.Calculated'],
      addressLabels: this.selectedCustomer['addresses.Labels'],
    };
    this.editDetails(addressDetails);
    this.editMode4 = true;
  }

  editContactDetails() {
    const contactInfo = {
      emailBusiness: this.selectedCustomer['email.Business'],
      emailHome: this.selectedCustomer['email.Home'],
      emailPersonal: this.selectedCustomer['email.Personal'],
      emailRelative: this.selectedCustomer['email.Relative'],
      emailWork: this.selectedCustomer['email.Work'],
      primaryEmail: this.selectedCustomer['emails.Email'],
      primaryEmailLabel: this.selectedCustomer['emails.Primary'],
      emailLabels: this.selectedCustomer['emails.Labels'],
      phoneDaytime: this.selectedCustomer['Phone.Daytime.Number'],
      phoneEvening: this.selectedCustomer['Phone.Evening.Number'],
      phoneHome: this.selectedCustomer['Phone.Home.Number'],
      phoneMobile: this.selectedCustomer['Phone.Mobile.Number'],
      phoneWeekend: this.selectedCustomer['Phone.Weekend.Number'],
      phoneWork: this.selectedCustomer['Phone.Work.Number'],
      primaryPhone: this.selectedCustomer['phones.Number'],
      primaryPhoneLabel: this.selectedCustomer['phones.Primary'],
      phonesLabels: this.selectedCustomer['phones.Labels'],
      url: this.selectedCustomer['URL'],
    };
    this.editDetails(contactInfo);
    this.editMode5 = true;
  }

  editPreferences() {
    const prefDetails = {
      contactMethod: this.selectedCustomer['PreferredContactMethod.Label'],
      contactTime: this.selectedCustomer['PreferredContactTime.Label'],
      messageType: this.selectedCustomer['PreferredMessageType.Label'],
      referralSource: this.selectedCustomer['ReferralSource.Category'],
      referralSpecific: this.selectedCustomer['ReferralSource.Specific'],
    };
    this.editDetails(prefDetails);
    this.editMode6 = true;
  }

  editIDDetails() {
    const IDDetails = {};
    this.editDetails(IDDetails);
    this.editMode8 = true;
  }

  editDetails(updatedData) {
    // let payload = {

    //   marketingConsent: this.selectedCustomer['MarketingConsent'],
    //   electronicConsent: this.selectedCustomer['ElectronicConsent'],
    //   logonEnabled: this.selectedCustomer['Logon.Enabled'],
    //   isAdministrator: this.selectedCustomer['Administrator'],
    //   isWakandaMaster: this.selectedCustomer['WakandaMasterUser']
    // };

    this.existingPatientService.editProspect(this.selectedCustomer['ID'], updatedData).subscribe((res) => {
      if (res && typeof res == 'object') {
        this.selectedCustomer = res;

        NotifyAppComponent.displayToast(
          'success',
          'Successful Operation',
          'Patients details have been successfully updated'
        );
      }
    });
  }

  addAddress() {
    const ref = this.dialog.open(AddressEditModalComponent, {
      width: '700px',
      height: '350px',
      panelClass: 'noCard',
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  addContactDetails() {
    const ref = this.dialog.open(ContactDetailsEditModalComponent, {
      width: '700px',
      height: '350px',
      panelClass: 'noCard',
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  openActionControlModal() {
    // let ref = this.dialog.open(ActionLogCreateComponent, {
    //   width: "700px",
    //   height: "350px",
    //   panelClass: "noCard"
    // });
    // const sub = ref.componentInstance.closeModal.subscribe(data => {
    //   ref.close();
    // });
  }

  backClicked() {
    this.location.back();
  }
}
