import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { orderBy } from 'lodash';
import { ClipboardService } from 'ngx-clipboard';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { SideMenuService } from '../../../shared/services/side-menu.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { EconnectTableViewComponent } from '../econnect-table-view/econnect-table-view.component';
import { EconnectService } from '../shared/econnect.service';

@Component({
  selector: 'app-econnect-table-list',
  templateUrl: './econnect-table-list.component.html',
  styleUrls: ['./econnect-table-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class EconnectTableListComponent implements OnInit {
  operations;
  isPromoterOrAdmin = false;
  @Input()
  tableID;
  @Input()
  pageSize = 30;
  pageSizeOptions = [10, 25, 50, 100, 200];

  @Output()
  getTableID = new EventEmitter();

  utils = new UtilsClass();
  displayedColumns = ['copy', 'name', 'TableRetrievalCost', 'IsFunction', 'view'];

  filteredSize = null;
  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  destroyEvent = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private econnectService: EconnectService,
    private dialog: MatDialog,
    private activeRoute: ActivatedRoute,
    private _clipboardService: ClipboardService,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['tableID']) {
        this.tableID = params['tableID'];
      }
    });
    SideMenuService.goBack.subscribe((res) => {
      if (res == true) {
        this.hideViewDetails();
      }
    });
    const payload = {};
    this.listDB = new LoadRecords(this.econnectService, this.destroyEvent, payload, this.isPromoterOrAdmin);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  ngOnChanges(changes: SimpleChanges) {
    const payload = {};
    this.listDB = new LoadRecords(this.econnectService, this.destroyEvent, payload, this.isPromoterOrAdmin);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  select(ID) {
    if (ID) {
      this.tableID = ID;
      this.getTableID.emit(ID);
      window.history.replaceState({}, '', `/admin/(adminPageRoute:econnect-documentation/${ID})`);
    }
  }

  setFilter(event, field) {
    let filter;

    if (event === true && typeof event === 'boolean') {
      filter = {
        field,
        value: '1',
      };
    } else if (event === false && typeof event === 'boolean') {
      filter = {
        field,
        value: '',
      };
    } else if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  copy(ID) {
    if (ID) {
      this._clipboardService.copy("'" + ID + "'");
      window.history.replaceState({}, '', `/admin/(adminPageRoute:econnect-documentation/${ID})`);
      NotifyAppComponent.displayToast('success', 'Table name copied', 'Table name has been copied to your clipboard');
    }
  }

  openQuickViewDialog(row) {
    const ref = this.dialog.open(EconnectTableViewComponent, {
      data: {
        table: row,
      },
      width: '640px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  toNumber(n) {
    if (n) {
      return Number(n);
    } else {
      return 0;
    }
  }

  hideViewDetails() {}

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  isDestoyed = false;
  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  serviceRef;

  constructor(private econnectService: EconnectService, private destroyEvent, params, private isPromoterOrAdmin) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    const payload = params || {};

    payload.orderBy = 'ID';
    payload.section = 0;
    payload.limit = 500;
    payload.fields =
      'ID,Product_Type_Code,TableRetrievalCost,Comments,Description,DepreciatedTableName,CanCreate,CanModify,CanQuery,CanDelete,CanSort,IsFunction';

    this.serviceRef = this.econnectService.getTableList(payload).subscribe((res) => {
      if (res && res.length > 0) {
        for (let i = 0; i < res.length; i++) {
          // res[i]['fullName'] = res[i]['ID'] + " " + res[i]['Description'] + " " + res[i]['Comments'] + " " + res[i]['DepreciatedTableName']

          if (res[i] && res[i]['CanCreate'] == '1') {
            res[i]['CanCreateValue'] = 'CanCreate';
          }
          if (res[i] && res[i]['CanModify'] == '1') {
            res[i]['CanModifyValue'] = 'CanModify';
          }
          if (res[i] && res[i]['CanQuery'] == '1') {
            res[i]['CanQueryValue'] = 'CanQuery';
          }
          if (res[i] && res[i]['CanDelete'] == '1') {
            res[i]['CanDeleteValue'] = 'CanDelete';
          }
          if (res[i] && res[i]['CanSort'] == '1') {
            res[i]['CanSortValue'] = 'CanSort';
          }
          if (res[i] && res[i]['IsFunction'] == '1') {
            res[i]['IsFunctionValue'] = 'IsFunction';
          }
        }
      }
      this.count = res.length;
      this.items = res;

      this.items = orderBy(this.items, ['ID'], ['asc']);
      this.dataChange.next(this.items.slice());
      this.firstLoad = true;
      const innerFunction = (section) => {
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.econnectService.getTableList(payload).subscribe((res) => {
          if (res && res.length > 0) {
            for (let i = 0; i < res.length; i++) {
              // res[i]['fullName'] = res[i]['ID'] + " " + res[i]['Description'] + " " + res[i]['Comments'] + " " + res[i]['DepreciatedTableName']

              if (res[i] && res[i]['CanCreate'] == '1') {
                res[i]['CanCreateValue'] = 'CanCreate';
              }
              if (res[i] && res[i]['CanModify'] == '1') {
                res[i]['CanModifyValue'] = 'CanModify';
              }
              if (res[i] && res[i]['CanQuery'] == '1') {
                res[i]['CanQueryValue'] = 'CanQuery';
              }
              if (res[i] && res[i]['CanDelete'] == '1') {
                res[i]['CanDeleteValue'] = 'CanDelete';
              }
              if (res[i] && res[i]['CanSort'] == '1') {
                res[i]['CanSortValue'] = 'CanSort';
              }
              if (res[i] && res[i]['IsFunction'] == '1') {
                res[i]['IsFunctionValue'] = 'IsFunction';
              }
            }
          }
          if (res.length > 0) {
            this.count = res.count;
            this.items = this.items.concat(res);

            this.items = orderBy(this.items, ['ID'], ['asc']);
            this.dataChange.next(this.items.slice());
            if (this.isDestoyed != true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };

      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  set removeItem(id) {
    const data = this._tableDatabase.data.filter((row) => row.ID != id);
    this._tableDatabase.dataChange.next(data);
  }

  set replaceItem(item) {
    const _index = this._tableDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    this._tableDatabase.data[_index] = item;
    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
          let f = '';

          f = item[this.field] || '';

          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'name':
          [propertyA, propertyB] = [a['ID'], b['ID']];
          break;

        case 'CanCreate':
          [propertyA, propertyB] = [a['CanCreate'], b['CanCreate']];
          break;
        case 'CanModify':
          [propertyA, propertyB] = [a['CanModify'], b['CanModify']];
          break;
        case 'CanQuery':
          [propertyA, propertyB] = [a['CanQuery'], b['CanQuery']];
          break;
        case 'CanDelete':
          [propertyA, propertyB] = [a['CanDelete'], b['CanDelete']];
          break;
        case 'CanSort':
          [propertyA, propertyB] = [a['CanSort'], b['CanSort']];
          break;
        case 'IsFunction':
          [propertyA, propertyB] = [a['IsFunction'], b['IsFunction']];
          break;
        case 'TableRetrievalCost':
          [propertyA, propertyB] = [a['TableRetrievalCost'], b['TableRetrievalCost']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
