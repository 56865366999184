<div class="full-width in-front-div" [class.infrontdiv]="isFullScreen == true">
  <div class="full-width" [class.infrontdivinner]="isFullScreen == true">
    <div class="top-menu-place-holder full-width" *ngIf="isFullScreen == true"></div>
    <div class="full-width" [class.topmenufront]="isFullScreen == true">
      <button
        class="full-screen-button pull-right ml"
        *ngIf="isFullScreen == true"
        (click)="switchFullScreen(false)"
        mat-raised-button
        color="accent"
      >
        <mat-icon class="fas fa-compress-alt"></mat-icon>
      </button>

      <button
        class="full-screen-button pull-right ml"
        *ngIf="isFullScreen != true"
        (click)="switchFullScreen(true)"
        mat-raised-button
        color="accent"
      >
        <mat-icon class="fas fa-expand-alt"></mat-icon>
      </button>

      <div class="action-view-container pull-right" *ngIf="isEditStyle == true">
        <button
          class="action-view-button first"
          [ngClass]="{ selected: viewType == 'view1' }"
          (click)="changeViewType('view1')"
          mat-icon-button
          color="accent"
        >
          View 1
        </button>
        <button
          class="action-view-button"
          [ngClass]="{ selected: viewType == 'view2' }"
          (click)="changeViewType('view2')"
          mat-icon-button
          color="accent"
        >
          View 2
        </button>
        <button
          class="action-view-button"
          [ngClass]="{ selected: viewType == 'view3' }"
          (click)="changeViewType('view3')"
          mat-icon-button
          color="accent"
        >
          View 3
        </button>
        <button
          class="action-view-button last"
          [ngClass]="{ selected: viewType == 'view4' }"
          (click)="changeViewType('view4')"
          mat-icon-button
          color="accent"
        >
          View 4
        </button>
      </div>

      <div class="pull-left option-container">
        <mat-form-field class="status-input clearfix text-center" appearance="outline">
          <mat-label>Editor Type</mat-label>
          <mat-select
            [(ngModel)]="inputType"
            (ngModelChange)="changeEditorType()"
            name="inputType"
            placeholder="Editor Type"
          >
            <mat-option *ngIf="isTinyEditorActive == true" [value]="'TinyEditor'">
              <span><strong>TinyMCE</strong> Graphic Editor</span>
            </mat-option>
            <mat-option *ngIf="isRawActive == true" [value]="'Raw'">
              <mat-icon class="fas fa-code mr"></mat-icon>
              <span>Code Editor</span>
            </mat-option>
            <mat-option *ngIf="isUnlayerActive == true" [value]="'Editor'">
              <span><strong>Unlayer</strong> Graphic Editor</span>
            </mat-option>
            <mat-option *ngIf="isTextActive == true" [value]="'Text'">
              <mat-icon class="fas fa-align-justify mr"></mat-icon>
              <span> Text Editor</span>
            </mat-option>
            <mat-option *ngIf="displayUploader == true" [value]="'Document'">
              <mat-icon class="fas fa-upload mr"></mat-icon>
              <span>Upload File</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="action-view-container pull-left ml">
        <button class="action-view-button-style2 btn mr" (click)="displayGallery()" mat-raised-button color="accent">
          <mat-icon class="fas fa-images smr"></mat-icon>
          Gallery
        </button>

        <button
          class="action-view-button-style2 btn"
          *ngIf="inputType != 'Editor'"
          [matMenuTriggerFor]="menu"
          mat-raised-button
          color="primary"
        >
          <mat-icon class="smr">add</mat-icon> Add Content
        </button>

        <mat-menu #menu="matMenu">
          <button (click)="editorLookup('Template')" mat-menu-item>
            <mat-icon class="fas fa-cogs mr"></mat-icon>
            Add component
          </button>
          <hr/>
          <button (click)="editorLookup('Style')" mat-menu-item>
            <mat-icon class="fas fa-fill mr"></mat-icon> Apply Style
          </button>
          <hr/>
          <button (click)="editorLookup('PageTem')" mat-menu-item>
            <mat-icon class="fas fa-paint-roller mr"></mat-icon> Apply Template
          </button>
          <hr/>
          <button (click)="addDocument()" mat-menu-item>
            <mat-icon class="fas fa-folder-open mr"></mat-icon> Add Asset
          </button>
          <hr/>
          <button [matMenuTriggerFor]="menuInner10" mat-menu-item>
            <mat-icon class="fas fa-toolbox mr"></mat-icon>
            Add Static Components
          </button>

          <mat-menu #menuInner10="matMenu">
            <button (click)="iframeContent('contact', false)" mat-menu-item>Contact Us</button>

            <button (click)="iframeContent('calculator', false)" mat-menu-item>Product Calculator</button>

            <button (click)="linkPopup()" mat-menu-item>Link As Popup</button>
          </mat-menu>

          <hr *ngIf="staticContent && staticContent.length > 0"/>
          <button *ngIf="staticContent && staticContent.length > 0" [matMenuTriggerFor]="menuInner1" mat-menu-item>
            <mat-icon class="fas fa-user mr"></mat-icon>
            Add {{ "KEYWORD.practice" | translate | titlecase}} Components
          </button>

          <mat-menu #menuInner1="matMenu">
            <button (click)="iframeContent('appointment', true)" mat-menu-item>Appointment Request</button>

            <button (click)="iframeContent('contact', true)" mat-menu-item>
              Contact {{ "KEYWORD.practice" | translate | titlecase}}
            </button>

            <button (click)="iframeContent('calculator', true)" mat-menu-item>Product Calculator</button>

            <hr/>
            <button *ngFor="let c of staticContent" (click)="displayStatic(c)" mat-menu-item>
              {{ c.label }}
            </button>
          </mat-menu>
        </mat-menu>
      </div>

      <mat-checkbox
        class="slider-input-select pull-left ml mr"
        [(ngModel)]="isEditStyle"
        (ngModelChange)="changeCSSInput()"
        name="isFreeSMS"
      >
        Display Styling Input?
      </mat-checkbox>
    </div>
    <hr class="mb"/>
    <div
      class="full-width css-input-container"
      *ngIf="updatingContent !== true"
      [class.flex]="viewType == 'view3'"
      [class.viewType2]="viewType == 'view2'"
      [class.viewType4]="viewType == 'view4'"
      [class.viewType1]="viewType == 'view1'"
      [class.isEditStyle]="isEditStyle != true"
      [class.viewType3]="viewType == 'view3'"
      [hidden]="isHidden"
    >
      <div
        class="full-width main-container-top"
        [class.flex]="viewType == 'view4'"
        [class.mr]="viewType == 'view3' || viewType == 'view4'"
      >
        <div class="full-width preview-container-html" [class.mr]="viewType == 'view4'">
          <h3 class="rm-m" [class.removeH3]="viewType == 'view3'">Content</h3>
          <app-html-input
            class="full-width html-editor-container"
            [label]="''"
            [isFroalaEditorActive]="false"
            [inputType]="inputType"
            [isUnlayerActive]="true"
            [isTinyEditorActive]="true"
            [structure]="_structure"
            [isRawPreview]="false"
            [isRawActive]="true"
            [isTextActive]="true"
            [displayUploader]="false"
            [isCss]="false"
            [content]="_contentDefault"
            [title]="''"
            [displayEditorOptions]="false"
            [description]="descriptionHTML"
            [descriptionDocument]="descriptionDocument"
            [descriptionRaw]="descriptionRaw"
            [descriptionEditor]="descriptionEditor"
            (getContent)="getContentEvent($event, true)"
          ></app-html-input>
        </div>

        <div
          class="full-width preview-container"
          *ngIf="isEditStyle == true"
          [class.flex]="viewType == 'view1'"
          [class.ml]="viewType == 'view4'"
          [hidden]="load == false"
        >
          <div class="full-width preview-container-css" [class.mr]="viewType == 'view1'">
            <h3 class="rm-m">Styling</h3>
            <app-html-input
              class="full-width css-editor-container mb"
              [label]="''"
              [isComponent]="isComponent"
              [componentID]="componentID"
              [editorId]="'editorIdCSSHTML'"
              [isFroalaEditorActive]="false"
              [inputType]="'Raw'"
              [displayEditorOptions]="false"
              [isUnlayerActive]="false"
              [isTinyEditorActive]="false"
              [isRawPreview]="false"
              [isRawActive]="true"
              [isTextActive]="false"
              [displayUploader]="false"
              [isCss]="true"
              [content]="_styleDefault"
              [title]="''"
              [description]="descriptionCSS"
              [descriptionDocument]="''"
              [descriptionEditor]="''"
              (getContent)="getStyleEvent($event)"
            >
            </app-html-input>
          </div>
          <div
            class="full-width preview-container-html-preview"
            *ngIf="viewType != 'view3' && viewType != 'view4'"
            [class.ml]="viewType == 'view1'"
          >
            <h3 class="rm-m">Preview</h3>
            <div class="full-width flex">
              <div class="full-width clearfix">
                <div
                  class="full-width pull-left description-container mr"
                  *ngIf="descriptionPreview"
                  [innerHTML]="descriptionPreview | clearText | safeHtml"
                ></div>
              </div>
            </div>
            <button
              class="pull-right action-button preview smr"
              *ngIf="_preview"
              (click)="ViewHtmlContent()"
              mat-icon-button
              color="accent"
              matTooltip="Preview"
            >
              <mat-icon class="fas fa-eye"></mat-icon>
            </button>
            <app-inner-html-isolated class="full-width css-preview-content" *ngIf="_preview" [content]="_preview">
            </app-inner-html-isolated>
            <app-empty-list-message
              class="full-width"
              *ngIf="!_preview"
              [title]="'No content'"
              [message]="'Nothing to display'"
            ></app-empty-list-message>
          </div>
        </div>
      </div>
      <div
        class="full-width preview-container-html-preview"
        *ngIf="(viewType == 'view3' || viewType == 'view4') && isEditStyle == true"
        [class.ml]="viewType == 'view3'"
      >
        <h3 class="rm-m">Preview</h3>
        <div class="full-width flex">
          <div class="full-width clearfix">
            <div
              class="full-width pull-left description-container mr"
              *ngIf="descriptionPreview"
              [innerHTML]="descriptionPreview | clearText | safeHtml"
            ></div>
          </div>
        </div>
        <button
          class="pull-right action-button preview smr"
          *ngIf="_preview"
          (click)="ViewHtmlContent()"
          mat-icon-button
          color="accent"
          matTooltip="Preview"
        >
          <mat-icon class="fas fa-eye"></mat-icon>
        </button>
        <app-inner-html-isolated
          class="full-width css-preview-content"
          *ngIf="_preview"
          [content]="_preview"
          [class.emaileditor]="inputType == 'Editor'"
        >
        </app-inner-html-isolated>

        <app-empty-list-message
          class="full-width"
          *ngIf="!_preview"
          [title]="'No content'"
          [message]="'Nothing to display'"
        ></app-empty-list-message>
      </div>
    </div>

    <div class="top-menu-place-holder2 full-width" *ngIf="isFullScreen == true"></div>
  </div>
</div>
