<mat-tab-group *ngIf="patient" [dynamicHeight]="true">
  <mat-tab label="Main Details">
    <mat-card class="card">
      <div class="row clearfix">
        <div class="column">
          <div class="clearfix">
            <div class="half-width">
              <div class="col-left">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Type</mat-label>
                  <mat-select class="full-width" [(ngModel)]="patient.CardType" placeholder="Type">
                    <mat-option *ngFor="let type of types" [value]="type.Code">
                      {{ type.Label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="half-width">
              <div class="col-right">
                <!-- Gender Select -->
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Gender</mat-label>
                  <mat-select class="full-width" [(ngModel)]="patient['Gender.Code']" placeholder="Gender">
                    <mat-option *ngFor="let gender of genders" [value]="gender.Code">
                      {{ gender.Label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row clearfix flex">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>First Name</mat-label>
              <input [(ngModel)]="patient.FirstName" matInput placeholder="First Name"/>
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Middle Name</mat-label>
              <input [(ngModel)]="patient.MiddleName" matInput placeholder="Middle Name"/>
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Surname</mat-label>
              <input [(ngModel)]="patient.ShortName" matInput placeholder="Surname"/>
            </mat-form-field>
          </div>
          <div class="row clearfix flex">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Date of Birth</mat-label>
              <input [(ngModel)]="patient.DateOfBirth" matInput placeholder="Date of Birth"/>
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Maternal Name</mat-label>
              <input [(ngModel)]="patient.Name" matInput placeholder="Maternal Name"/>
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Preferred Name</mat-label>
              <input [(ngModel)]="patient.PreferredName" matInput placeholder="Preferred Name"/>
            </mat-form-field>
          </div>
          <div class="row clearfix flex">
            <div class="clearfix full-width">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Relationship</mat-label>
                <mat-select class="full-width" placeholder="Relationship">
                  <mat-option *ngFor="let relationship of relationships" [value]="relationship.value">
                    {{ relationship.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="clearfix full-width">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Occupation</mat-label>
                <mat-select class="full-width" [(ngModel)]="patient.Occupation" placeholder="Occupation">
                  <mat-option *ngFor="let occupation of occupations" [value]="occupation.value">
                    {{ occupation.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Drivers License</mat-label>
              <input matInput placeholder="Drivers License" value=""/>
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-card>
  </mat-tab>
  <mat-tab label="Address Details">
    <!-- <mat-card class="card">
    <div class="row clearfix">
      <div class="column">
        <h2 class="mat-h2 sr-title">{{ "AddressDetails" | translate }}</h2>
        <div class="row clearfix ">
          <div class="input-cont-height thrd-width">
            <div class="col-left">
              <mat-select placeholder="Address Type" class="full-width" [(ngModel)]="patient['addresses.Street Type']">
                <mat-option *ngFor="let addressType of addressTypes" [value]="addressType.value">
                  {{ addressType.viewValue }}
                </mat-option>
              </mat-select>
            </div>
          </div>
        </div>
        <div class="row clearfix flex">
          <mat-form-field class="full-width" *ngIf="addressType == 0">
            <input matInput placeholder="Unit" value="">
          </mat-form-field>
          <mat-form-field class="full-width" *ngIf="addressType == 0">
            <input matInput placeholder="Street Number" value="">
          </mat-form-field>
        </div>
        <div class="row clearfix" *ngIf="addressType == 1">
          <mat-form-field class="full-width" appearance="outline">
            <input matInput placeholder="PO Box Address" value="">
          </mat-form-field>
        </div>
        <div class="row clearfix" *ngIf="addressType == 0">
          <mat-form-field class="full-width" appearance="outline">
            <input matInput placeholder="Property Name" value="">
          </mat-form-field>
        </div>
        <div class="row clearfix flex" *ngIf="addressType == 0">
          <mat-form-field class="full-width" appearance="outline">
            <input matInput placeholder="Street Name" value="">
          </mat-form-field>
          <mat-form-field class="full-width" appearance="outline">
            <input matInput placeholder="Street Type" value="">
          </mat-form-field>
          <mat-form-field class="full-width" appearance="outline">
            <input matInput placeholder="Suburb" [(ngModel)]="patient['addresses.Suburb']">
          </mat-form-field>
        </div>
        <div class="row clearfix flex">
          <mat-form-field class="full-width" appearance="outline">
            <input matInput placeholder="State" [(ngModel)]="patient['addresses.State']">
          </mat-form-field>
          <mat-form-field class="full-width" appearance="outline">
            <input matInput placeholder="Postcode" [(ngModel)]="patient['addresses.Postcode']">
          </mat-form-field>
          <mat-select placeholder="Country" class="full-width" [(ngModel)]="patient['addresses.Country.Code']">
            <mat-option *ngFor="let countryName of countryNames" [value]="countryName.value">
              {{ countryName.viewValue }}
            </mat-option>
          </mat-select>
        </div>
      </div>
    </div>
  </mat-card> -->
    <mat-card class="card">
      <div class="row clearfix">
        <app-address-input
          [postCode]="myPostCode"
          [state]="myState"
          [suburb]="mySuburb"
          [streetNumber]="myStreetNumber"
          [streetName]="myStreetName"
          [unitNumber]="myUnitNumber"
          [streetType]="myStreetType"
          [latitude]="myLatitude"
          [longitude]="myLongitude"
          (getAdress)="getAdress($event)"
          (validate)="isAdressValid($event)"
        >
        </app-address-input>
      </div>
    </mat-card>
  </mat-tab>
  <mat-tab label="Contact Details">
    <mat-card class="card">
      <h2 class="mat-h2 sr-title">Contact Details</h2>
      <div class="clearfix">
        <div class="two-thrd-width">
          <div class="col-left">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Type</mat-label>
              <mat-select class="qtr-width align" placeholder="Type">
                <mat-option *ngFor="let contactType of contactTypes" [value]="contactType.value">
                  {{ contactType.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-right">
            <mat-form-field class="thr-qtr-width">
              <mat-label>Home Phone Number</mat-label>
              <input [(ngModel)]="patient['Phone.Home.Number']" matInput placeholder="Home Phone Number"/>
            </mat-form-field>
          </div>
        </div>
        <div class="thrd-width alternate-theme">
          <div class="col-right align-chk">
            <mat-radio-group [(ngModel)]="patient['mobiles.Send']">
              <mat-checkbox [checked]="patient['mobiles.Send'] == 1" value="1">Send</mat-checkbox>
              <mat-checkbox [checked]="patient['mobiles.Send'] == 0" value="0"
              >Primary
              </mat-checkbox>
            </mat-radio-group>
          </div>
        </div>
      </div>
      <div class="clearfix">
        <div class="two-thrd-width">
          <div class="col-left">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Type</mat-label>
              <mat-select class="qtr-width align" placeholder="Type">
                <mat-option *ngFor="let contactType of contactTypes" [value]="contactType.value">
                  {{ contactType.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-right">
            <mat-form-field class="thr-qtr-width">
              <mat-label>Work Mobile Number</mat-label>
              <input [(ngModel)]="patient['Phone.Work.Number']" matInput placeholder="Work Mobile Number"/>
            </mat-form-field>
          </div>
        </div>
        <div class="thrd-width alternate-theme">
          <div class="col-right align-chk">
            <mat-radio-group [(ngModel)]="patient['Phones.Send']">
              <mat-checkbox [checked]="patient['Phones.Send'] == 1" value="1">Send</mat-checkbox>
              <mat-checkbox [checked]="patient['Phones.Send'] == 0" value="0">Primary</mat-checkbox>
            </mat-radio-group>
          </div>
        </div>
      </div>
      <div class="clearfix">
        <div class="two-thrd-width">
          <div class="col-left">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Type</mat-label>

              <mat-select class="qtr-width align" placeholder="Type">
                <mat-option *ngFor="let contactType of contactTypes" [value]="contactType.value">
                  {{ contactType.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-right">
            <mat-form-field class="thr-qtr-width">
              <mat-label>Work Fax Number</mat-label>
              <input [(ngModel)]="patient['faxes.Number']" matInput placeholder="Work Fax Number"/>
            </mat-form-field>
          </div>
        </div>
        <div class="thrd-width alternate-theme">
          <div class="col-right align-chk">
            <mat-radio-group [(ngModel)]="patient['faxes.Send']">
              <mat-checkbox [checked]="patient['faxes.Send'] == 1" value="1">Send</mat-checkbox>
              <mat-checkbox [checked]="patient['faxes.Send'] == 0" value="0">Primary</mat-checkbox>
            </mat-radio-group>
          </div>
        </div>
      </div>
      <div class="clearfix">
        <div class="two-thrd-width">
          <div class="col-left">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Type</mat-label>

              <mat-select class="qtr-width align" placeholder="Type">
                <mat-option *ngFor="let contactType of contactTypes" [value]="contactType.value">
                  {{ contactType.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-right">
            <mat-form-field class="thr-qtr-width">
              <mat-label>Email Address</mat-label>
              <input [(ngModel)]="patient['emails.Email']" matInput placeholder="Email Address"/>
            </mat-form-field>
          </div>
        </div>
        <div class="thrd-width alternate-theme">
          <div class="col-right align-chk">
            <mat-radio-group [(ngModel)]="patient['emails.Send']">
              <mat-checkbox [checked]="patient['emails.Send'] == 1" value="1">Send</mat-checkbox>
              <mat-checkbox [checked]="patient['emails.Send'] == 0" value="0">Primary</mat-checkbox>
            </mat-radio-group>
          </div>
        </div>
      </div>
      <h2 class="mat-h2 sr-title">Chat Account</h2>
      <div class="clearfix">
        <div class="full-width">
          <div class="col-left">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Type</mat-label>
              <mat-select class="qtr-width align" placeholder="Type">
                <mat-option *ngFor="let socialContactType of socialContactTypes" [value]="socialContactType.value">
                  {{ socialContactType.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-right">
            <mat-form-field class="thr-qtr-width">
              <mat-label>Address</mat-label>
              <input [(ngModel)]="patient['addresses.Calculated']" matInput placeholder="Address"/>
            </mat-form-field>
          </div>
        </div>
      </div>
      <h2 class="mat-h2 sr-title">Website</h2>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Website</mat-label>
        <input [(ngModel)]="patient.URL" matInput placeholder="Website"/>
      </mat-form-field>
    </mat-card>
  </mat-tab>
  <mat-tab label="Administrative">
    <mat-card class="card">
      <div class="column">
        <div class="clearfix">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select class="full-width" [(ngModel)]="patient.Status" placeholder="Status">
              <mat-option *ngFor="let status of statuss" [value]="status.value">
                {{ status.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="clearfix">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Cons. Type</mat-label>
            <mat-select class="full-width" [(ngModel)]="patient.AccountManager" placeholder="Cons. Type">
              <mat-option *ngFor="let consType of consTypes" [value]="consType.value">
                {{ consType.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="clearfix">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Account Manager</mat-label>
            <input [(ngModel)]="patient.AccountManager" matInput placeholder="Account Manager"/>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
  </mat-tab>
</mat-tab-group>
