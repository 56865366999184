import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { TagsService } from '../../tags/shared/tags.service';
import { TagCreateEditComponent } from '../../tags/tag-create-edit/tag-create-edit.component';
import { SocialMediaService } from '../shared/social-media.service';
import { SocialMediaViewComponent } from '../social-media-view/social-media-view.component';

@Component({
  selector: 'app-social-media-create-edit',
  templateUrl: './social-media-create-edit.component.html',
  styleUrls: ['./social-media-create-edit.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class SocialMediaCreateEditComponent implements OnInit {
  @Input()
  socialMediaType = 'Facebook';

  @Input()
  socialMediaID;

  isActive = true;

  template = {
    label: 'Here',
  };
  postPictureLink;
  postPicture;
  loadingPost = true;

  postPictureLinkThumbnail;
  postPictureThumbnail;
  fileIDThumbnail;
  fileID;

  pinchZoomProperties = [
    {
      wheel: true,
      autoHeight: true,
    },
  ];
  isModal = false;
  maxLength = 63206;

  addTag = new EventEmitter();
  applyDownload = new EventEmitter();
  selectedTags = [];
  defaultSelectedTags = [];
  tags = [];
  orginalTemplateTag = [];
  sessionType;
  isPromoterOrAdmin;

  removetag = new EventEmitter();

  settings = Settings.global;
  utils = new UtilsClass();
  @Output()
  close = new EventEmitter();

  @Output()
  getResult = new EventEmitter();
  step = 1;
  postLabel;
  postText;
  createdTemplate;
  suppliers = [];
  selectedSuppliers = [];

  allowExtensions = ['jpeg', 'jpg', 'png','heic' , 'gif', 'tiff', 'bmp', 'ico'];
  secondStepCreate = true;
  maxFileSize = 5;
  actionCallLabel;
  actionCallDescription;

  minLengthCTALabel = 30;
  maxLengthCTALabel = 60;

  minLengthCTADescription = 55;
  maxLengthCTADescription = 110;

  dynamicData = [
    {
      label: 'Trading As',
      code: '{{practiceTradingAs}}',
    },
    {
      label: 'Phone number',
      code: '{{practicePhoneNumber}}',
    },
    {
      label: 'Email',
      code: '{{practiceEmail}}',
    },
    {
      label: 'Address',
      code: '{{practiceAddress}}',
    },
    {
      label: 'Web Site',
      code: '{{practiceSite}}',
    },
  ];

  constructor(
    private authenticationService: AuthenticationService,
    private socialMediaService: SocialMediaService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private tagService: TagsService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.socialMediaType) {
        this.socialMediaType = data.socialMediaType;
        this.isModal = true;
      }

      if (data.socialMediaID) {
        this.socialMediaID = data.socialMediaID;
        this.isModal = true;
      }

      if (data.secondStepCreate != null) {
        this.secondStepCreate = data.secondStepCreate;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['socialMediaID']) {
        this.socialMediaID = params['socialMediaID'];
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
        this.isPromoterOrAdmin = isPromoterOrAdmin;

        this.authenticationService.getSessionType().subscribe((r) => {
          this.sessionType = r;

          const pTag = {
            isPromoter: true,
            fields: 'Label',
          };
          this.tagService.getTagsList(pTag, this.sessionType).subscribe((res) => {
            if (res) {
              this.tags = res;
            }
          });

          const p = {
            fields: 'ID,Label',
            isActive: true,
          };

          this.socialMediaService.getBeneficiaryList(p, this.sessionType).subscribe((res) => {
            if (res) {
              this.suppliers = res;
            }
          });
          this.setup();
        });
      });
    });
  }

  setup() {
    if (this.socialMediaID) {
      this.socialMediaService.getSocialMediaDetails(this.socialMediaID, {}, this.sessionType).subscribe((res) => {
        if (res) {
          if (res['Label']) {
            this.postLabel = res['Label'];
          }

          if (res['Post']) {
            this.postText = res['Post'];
          }

          if (res['Tag.Label'] && res['StaticList.Label']) {
            res['StaticList.Label'] = res['Tag.Label'] + '|' + res['StaticList.Label'];
          } else if (res['Tag.Label']) {
            res['StaticList.Label'] = res['Tag.Label'];
          }

          if (res['StaticList.Label']) {
            this.defaultSelectedTags = res['StaticList.Label'].split('|');
          }

          if (res['SupplierKeys']) {
            this.selectedSuppliers = res['SupplierKeys'].split('|');
          }

          if (res['SocialMediaType.Code']) {
            this.socialMediaType = res['SocialMediaType.Code'];
          }
          if (res['ActionCall.Label']) {
            this.actionCallLabel = res['ActionCall.Label'];
          }
          if (res['ActionCall.Description']) {
            this.actionCallDescription = res['ActionCall.Description'];
          }

          this.changeDescription();
          this.changeTitle();

          this.loadingPost = true;
          this.socialMediaService.getPostImage(this.socialMediaID).subscribe((res) => {
            if (res) {
              this.postPicture = res;
            } else {
              this.postPicture = null;
            }
            this.loadingPost = false;
          });

          this.postPictureThumbnail = this.socialMediaService.getThumbnailImageStreamLink(
            this.socialMediaID,
            res['LastModified']
          );
          this.changeSocialMediType();
        }
      });
    } else {
      this.changeSocialMediType();
      this.loadingPost = false;
    }
  }

  createEdit() {
    const payload: any = {
      label: this.postLabel,
      post: this.postText,
      fileID: this.fileID,
      thumbnailID: this.fileIDThumbnail,
      supplierIDs: this.selectedSuppliers,
      isActive: this.isActive,
      tags: this.selectedTags,
      actionCallDescription: this.actionCallDescription || 'void',
      actionCallLabel: this.actionCallLabel || 'void',
    };
    if (this.socialMediaID) {
      this.socialMediaService.editSocialMedia(this.socialMediaID, payload, this.sessionType).subscribe((res) => {
        if (res) {
          NotifyAppComponent.displayToast('success', 'Success!', 'Template is edited.');

          this.createdTemplate = res;
          if (this.secondStepCreate == true) {
            this.step = 2;
          }
          this.getResult.emit(this.createdTemplate);
        }
      });
    } else {
      payload.socialMediaType = this.socialMediaType;
      this.socialMediaService.createSocialMedia(payload, this.sessionType).subscribe((res) => {
        if (res) {
          NotifyAppComponent.displayToast('success', 'Success!', 'Template is created.');

          this.createdTemplate = res;
          if (this.secondStepCreate == true) {
            this.step = 2;
          }
          this.getResult.emit(this.createdTemplate);
        }
      });
    }
  }

  changeSocialMediType() {
    // let publicSite = this.settings['publicSiteFrontendLink'];
    // if (publicSite && publicSite.indexOf('http') == -1)
    //   publicSite = 'https://' + this.settings['publicSiteFrontendLink']

    // let urlLength = publicSite.length + 50;
    const urlLength = 40;
    if (this.socialMediaType) {
      if (this.socialMediaType == 'Facebook') {
        this.maxLength = 63206 - urlLength;
      } else if (this.socialMediaType == 'Instagram') {
        this.maxLength = 2200 - urlLength;
      } else if (this.socialMediaType == 'Twitter') {
        this.maxLength = 280 - urlLength;
      } else if (this.socialMediaType == 'LinkedIn') {
        this.maxLength = 700 - urlLength;
      } else if (this.socialMediaType == 'Pinterest') {
        this.maxLength = 500 - urlLength;
      }
    }
  }

  changeTitle() {
    if (!this.actionCallLabel) {
      this.actionCallLabel = this.postLabel;
    }
  }

  changeDescription() {
    if (!this.actionCallDescription) {
      this.actionCallDescription = this.postText;
    }
  }

  onCompleteThumbnail(fileID) {
    if (fileID && fileID[0]) {
      this.fileIDThumbnail = fileID[0];

      this.postPictureThumbnail = null;
      this.postPictureLinkThumbnail = this.utils.getFileLink(this.fileIDThumbnail);
    } else {
      this.postPictureLinkThumbnail = null;
    }
  }

  onComplete(fileID) {
    if (fileID && fileID[0]) {
      this.fileID = fileID[0];

      this.postPicture = null;
      this.postPictureLink = this.utils.getFileLink(this.fileID);
    } else {
      this.postPictureLink = null;
    }
  }

  createNewTag() {
    const ref = RootAppComponent.dialog.open(TagCreateEditComponent, {
      data: {},

      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res && res.Label) {
        if (
          (!this.tags || (this.tags && this.tags.length <= 0)) &&
          (!this.defaultSelectedTags || (this.defaultSelectedTags && this.defaultSelectedTags.length <= 0))
        ) {
          this.defaultSelectedTags = JSON.parse(JSON.stringify([res.Label]));
          this.tags = JSON.parse(JSON.stringify([res.Label]));
        } else {
          if (!this.tags || (this.tags && this.tags.length <= 0)) {
            this.tags.push(res.Label);
          }
          this.addTag.emit(res.Label);
        }
        ref.close();
      }
    });
  }

  getRemovedTag(e) {
    if (e && this.socialMediaID) {
      const confirmDialog = new ConfirmDialog(
        'delete',
        'Remove tag from template',
        '<p>Are you sure ?</p>',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              removeSocialMediaID: this.socialMediaID,
            };
            this.tagService.addTagsToItemByLabel(e, payload, this.sessionType).subscribe((res) => {
              if (res) {
                this.removetag.emit(e);
                NotifyAppComponent.displayToast('success', 'Success!', 'Tag is removed.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    } else if (this.socialMediaID) {
      this.removetag.emit(e);
    }
  }

  getSelectedTags(e) {
    this.selectedTags = e || [];
  }

  download(url, name) {
    const link = document.createElement('a');
    link.download = name;
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  downloadImage() {
    this.applyDownload.emit(true);
  }

  closeModal() {
    this.close.emit(true);
  }

  newTemplate() {
    this.socialMediaID = null;
    this.postText = null;
    this.postLabel = null;
    this.selectedTags = [];
    this.selectedSuppliers = [];
    this.fileID = null;
    this.fileIDThumbnail = null;
    this.postPictureThumbnail = null;
    this.postPicture = null;
    this.postPictureLinkThumbnail = null;
    this.postPictureLink = null;
    this.loadingPost = false;
    this.isActive = true;
    this.createdTemplate = null;
    this.step = 1;
  }

  templateList() {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['social-media-template-list'],
        },
      },
    ]);
  }

  viewSocialMedia(id) {
    const ref3 = RootAppComponent.dialog.open(SocialMediaViewComponent, {
      data: {
        templateID: id,
      },
      width: '900px',
      panelClass: ['noCard', 'full-big-screen'],
    });
    ref3.componentInstance.confirmShare.subscribe((res) => {
      ref3.close();
    });
    ref3.componentInstance.close.subscribe((res) => {
      ref3.close();
    });
  }

  addPersonalisationCTALabel(code) {
    if (code) {
      if (this.actionCallLabel) {
        this.actionCallLabel = this.actionCallLabel + ' ' + code + ' ';
      } else {
        this.actionCallLabel = code + ' ';
      }
    }
  }

  addPersonalisationCTADescription(code) {
    if (code) {
      if (this.actionCallDescription) {
        this.actionCallDescription = this.actionCallDescription + ' ' + code + ' ';
      } else {
        this.actionCallDescription = code + ' ';
      }
    }
  }

  addPersonalisationText(code) {
    if (code) {
      if (this.postText) {
        this.postText = this.postText + ' ' + code + ' ';
      } else {
        this.postText = code + ' ';
      }
    }
  }
}
