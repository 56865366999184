<div class="row clearfix">
  <h1 class="dataHeader rm-mt rm-mb pull-left">
    Contacts:
    <span class="badge" countUp></span>
  </h1>
  <button class="btn-small pull-right" id="newMessage" mat-raised-button color="accent">
    <mat-icon>add</mat-icon>
    New Contact
  </button>
</div>
<br/>
<div class="row clearfix">
  <div class="full width">
    <mat-card class="list-card">
      <mat-table class="list-table" #table matSort>
        <!--- Note that these columns can be defined in any order.
                          The actual rendered columns are set as a property on the row definition" -->
        <!-- ID Column -->

        <!-- Progress Column -->
        <ng-container cdkColumnDef="LastName">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Last Name</mat-header-cell>
          <mat-cell *cdkCellDef="let row"></mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="FirstName">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> First Name</mat-header-cell>
          <mat-cell *cdkCellDef="let row"></mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container cdkColumnDef="ContactType">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Contact Type</mat-header-cell>
          <mat-cell *cdkCellDef="let row"></mat-cell>
        </ng-container>
        <!-- Name Column -->

        <!-- Color Column -->
        <ng-container cdkColumnDef="mobile">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Phone/Number</mat-header-cell>
          <mat-cell *cdkCellDef="let row">1234567890</mat-cell>
        </ng-container>
        <!-- Color Column -->
        <ng-container cdkColumnDef="Mailout">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Mailout</mat-header-cell>
          <mat-cell *cdkCellDef="let row">Yes</mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="messageType">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Message Type</mat-header-cell>
          <mat-cell *cdkCellDef="let row">Email</mat-cell>
        </ng-container>

        <!-- Action Button Column -->
        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item>
                  <mat-icon>delete</mat-icon>
                  <span>Delete Contact</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </mat-card>
  </div>
</div>
