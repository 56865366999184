import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'price-service-view',
  templateUrl: './price-service-view.component.html',
  styleUrls: ['./price-service-view.component.css'],
})
export class PriceServiceViewComponent implements OnInit {
  dontAskAgainType;

  ngOnInit() {}

  onSimple() {}

  onAdvanced() {}

  startTheTour() {}
}
