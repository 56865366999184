import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArchwizardModule } from 'angular-archwizard';
import { ExistingPatientCreateComponent } from './existing-patient-create/existing-patient-create.component';
import { ExistingPatientImportComponent } from './existing-patient-import/existing-patient-import.component';
import { ExistingPatientListArchivesComponent } from './existing-patient-list-archives/existing-patient-list-archives.component';
import { ExistingPatientListIntroModalComponent } from './existing-patient-list-intro-modal/existing-patient-list-intro-modal.component';
import { ExistingPatientListComponent } from './existing-patient-list/existing-patient-list.component';
import { ExistingPatientSummaryComponent } from './existing-patient-summary/existing-patient-summary.component';
import { ExistingPatientViewComponent } from './existing-patient-view/existing-patient-view.component';
import { ExistingPatientRoutingModule } from './shared/existing-patient-routing.module';
import { ExistingPatientService } from './shared/existing-patient.service';

import { MatSortModule } from '@angular/material/sort';
import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '../../shared/shared.module';
import { CustomerModule } from '../customer/customer.module';
import { OperatorModule } from '../operator/operator.module';
import { ActivePatientSummaryComponent } from './active-patient-summary/active-patient-summary.component';
import { ExistingPatientCreateLookupComponent } from './existing-patient-create-lookup/existing-patient-create-lookup.component';
import { ExistingPatientInvitationViewComponent } from './existing-patient-invitation-view/existing-patient-invitation-view.component';
import { ExistingPatientListGlobalComponent } from './existing-patient-list-global/existing-patient-list-global.component';
import { ExistingPatientListMapComponent } from './existing-patient-list-map/existing-patient-list-map.component';
import { ExistingPatientListOverViewComponent } from './existing-patient-list-overview/existing-patient-list-overview.component';
import { ExistingPatientMapOverViewComponent } from './existing-patient-map-overview/existing-patient-map-overview.component';
import { ExistingPatientViewDetailsComponent } from './existing-patient-view-details/existing-patient-view-details.component';
import { ProspectEditComponent } from './prospect-edit/prospect-edit.component';
import { ProspectPrintViewComponent } from './prospect-print-view/prospect-print-view.component';
import { ProspectViewPictureComponent } from './prospect-view-picture/prospect-view-picture.component';
import { ProspectViewComponent } from './prospect-view/prospect-view.component';

@NgModule({
  imports: [
    NgPipesModule,
    CommonModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    OperatorModule,
    CdkTableModule,
    ArchwizardModule,
    ExistingPatientRoutingModule,
    CustomerModule,
    MatSortModule,
  ],
  declarations: [
    ExistingPatientListMapComponent,
    ProspectEditComponent,
    ProspectViewComponent,
    ExistingPatientInvitationViewComponent,
    ExistingPatientListComponent,
    ExistingPatientListOverViewComponent,
    ExistingPatientListArchivesComponent,
    ExistingPatientViewComponent,
    ExistingPatientViewDetailsComponent,
    ExistingPatientSummaryComponent,
    ExistingPatientCreateComponent,
    ExistingPatientCreateLookupComponent,
    ExistingPatientListGlobalComponent,
    ExistingPatientImportComponent,
    ExistingPatientListIntroModalComponent,
    ActivePatientSummaryComponent,
    ExistingPatientMapOverViewComponent,
    ProspectViewPictureComponent,
    ProspectPrintViewComponent,
  ],
  exports: [
    ProspectEditComponent,
    ExistingPatientMapOverViewComponent,
    ExistingPatientListMapComponent,
    ProspectViewComponent,
    ExistingPatientInvitationViewComponent,
    ExistingPatientListComponent,
    ExistingPatientListOverViewComponent,
    ExistingPatientListArchivesComponent,
    ExistingPatientViewComponent,
    ExistingPatientViewDetailsComponent,
    ExistingPatientListGlobalComponent,
    ExistingPatientSummaryComponent,
    ExistingPatientCreateComponent,
    ExistingPatientCreateLookupComponent,
    ExistingPatientImportComponent,
    ExistingPatientListIntroModalComponent,
    ActivePatientSummaryComponent,
    ProspectViewPictureComponent,
    ProspectPrintViewComponent,
  ],
  providers: [ExistingPatientService],
})
export class ExistingPatientModule {}
