<div class=" contract rel  flex clearfix" *ngIf="settlement ">
  <mat-card class="full-width">



    <div class="card-header primary-gradient-img clearfix inModal">
      <div class="row clearfix">
        <button class="btn-close btn-clear mat-button white" *ngIf="isModal==true" (click)="closeEvent()">
          <mat-icon>clear</mat-icon>
        </button>
        <div class="row full-width titleArea clearfix">
          <mat-icon class="pull-left fas fa-search-dollar"
            style="font-size: 3rem; margin-right: 20px; line-height: 3rem; height: 3rem; width: 3rem"></mat-icon>
          <h2 class="summary-header rm-m">
            Settlment Details for : {{settlement['Customer.CalculatedName']}}
            <span class="subLabel">Please review Support Document </span>
          </h2>



        </div>

        <app-invitation-actions *ngIf="settlement" [customerID]="settlement['Customer_key']" [settlement]="settlement"
          [displaySettlementActions]="false" [items]="items" [displayNewSettlement]="false"
          class="  contract-action-invitation mt  pull-right " [buttonType]="'buttonAccent'" [settlementOnList]="false"
          [contractID]="settlement['Contract_key']" [merchantID]="settlement['Merchant_Key']"
          [productID]="settlement['Product_key']" (getSettlementUpdate)="getSettlementUpdate($event)"
          (close)="closeEvent()">
        </app-invitation-actions>
      </div>
    </div>


    <mat-dialog-content [ngClass]="{ 'no-modal-height': isModal != true }">



      <div class="row full-width clearfix flex mb mt">
        <div class=" full-width left-side-setttlement">

          <h3 *ngIf="settlement && settlement['Contract_key']" class=" sr-title rm-m  smb"
            style="  background: #f3f3f3 !important;   padding-top: 16px !important;  padding: 16px !important;   margin-bottom: -16px !important;">
            Contract Details
          <hr>
          </h3>


          <div class="full-width settlement-amount-card mb" *ngIf="settlement && settlement['Contract_key']">



            <app-contract-view class="full-width" [contractID]=" settlement['Contract_key']"
              [newSettlementButton]="false" [isModal]="false">
            </app-contract-view>

          </div>


          <!--
          <h3 class=" sr-title rm-m mt smb">{{'Patient'| translate}} name: {{settlement['Customer.CalculatedName']}}
          </h3> -->

          <!--
          <hr class=" full-width"> -->



          <h3 *ngIf="settlement && settlement['Contract_key']" class=" sr-title rm-m  smb">Settlements Details </h3>


          <div class="row clearfix full-width flex " *ngIf="isGroupSettlements == false">
            <div class="full-width">
              <label>Creation Date</label>
              <p class="rm-mt smt">{{ settlement['DateTimeCreated'] | customDate }}</p>
            </div>



            <div class="full-width">
              <label>Type</label>
              <span class="chip smt materialRequest" *ngIf="settlement && settlement.Is_Materials_Request=='1'">
                Material Advance
              </span>
              <span class="chip   smt approved" *ngIf="settlement && settlement.Is_Materials_Request!='1'">
                Settlement
              </span>

            </div>

            <div class="full-width">
              <label>Settled Amount</label>
              <h3 class=" sr-title rm-mt  smt ">{{ settledAmount | customCurrency }}</h3>
            </div>

            <mat-icon class="fas fa-equals full-width mt" *ngIf="settlement['Insurance.Contribution'] != '0'">
            </mat-icon>

            <div class="full-width" *ngIf="settlement['Insurance.Contribution'] != '0'">
              <label>Requested Amount</label>
              <h3 class=" sr-title rm-mt  smt ">
                {{ settlement['Settlement.Amount'] | customCurrency }}
              </h3>
            </div>

            <mat-icon class="fas fa-minus full-width mt" *ngIf="settlement['Insurance.Contribution'] != '0'"></mat-icon>

            <div class="full-width" *ngIf="settlement['Insurance.Contribution'] != '0'">
              <label>Insurance Contribution</label>
              <h3 class=" sr-title rm-mt  smt ">
                {{ settlement['Insurance.Contribution'] | customCurrency }}
              </h3>
            </div>
          </div>

          <div class="row clearfix full-width flex" *ngIf="isGroupSettlements == true">
            <div class="full-width">
              <label>Creation Date</label>
              <p class="rm-mt smt">{{ settlement['DateTimeCreated'] | customDate }}</p>
            </div>
            <div class="full-width">
              <label>Settled Amount</label>
              <h3 class=" sr-title rm-mt  smt ">{{ groupSettlementsAmount | customCurrency }}</h3>
            </div>


            <!--<div class="full-width" *ngIf="settlement['Insurance.Contribution']!='0'">
            <label>Requested Amount</label>
            <p class="rm-mt">{{settlement['Settlement.Amount'] | customCurrency}}</p>
          </div>

          <div class="full-width" *ngIf="settlement['Insurance.Contribution']!='0'">
            <label>Insurance Contribution</label>
            <p class="rm-mt">{{settlement['Insurance.Contribution'] | customCurrency}}</p>
          </div> -->
          </div>

          <hr class=" full-width">

          <div class="full-width">
            <div class="row clearfix flex">
              <p class="full-width">Consented Via</p>
              <div class="full-width checkBoxMargin">
                <p class="chip text-center"
                  [ngStyle]="{ 'background-color': authorisedByForm == false ? '#1b8bdd' : '' }">
                  SMS
                </p>
              </div>
              <div class="full-width checkBoxMargin">
                <p class="chip text-center"
                  [ngStyle]="{ 'background-color': authorisedByForm == true ? '#1b8bdd' : '' }">
                  FORM
                </p>
              </div>
            </div>

            <hr class=" full-width">
            <div class="row clearfix flex mt">
              <div class="full-width" *ngIf="settlement['ConsentedViaPaperForm'] == '1'">
                <label>Review {{ "KEYWORD.patient" | translate | titlecase }} Acknowledgement</label>

                <p class="full-width" *ngIf="isRefund == false">
                  <span class="chip" *ngIf="isVerifiedAuthrisedForm == null">Pending</span>
                  <span class="chip" *ngIf="isVerifiedAuthrisedForm == true"
                    [style.background-color]="'#1b8bdd'">Verified</span>
                  <span class="chip" *ngIf="isVerifiedAuthrisedForm == false"
                    [style.background-color]="'#dd1a1b'">Reject</span>
                </p>
                <p class="full-width" *ngIf="isRefund == true">
                  <span class="chip" *ngIf="isVerifiedRefundForm == null">Pending</span>
                  <span class="chip" *ngIf="isVerifiedRefundForm == true"
                    [style.background-color]="'#1b8bdd'">Verified</span>
                  <span class="chip" *ngIf="isVerifiedRefundForm == false"
                    [style.background-color]="'#dd1a1b'">Reject</span>
                </p>
              </div>
              <div class="full-width" *ngIf="isRefund == false && isPreTreatment == false">
                <label>Review Invoice</label>
                <p class="full-width">
                  <span class="chip" *ngIf="isVerifiedInvoice == null">Pending</span>
                  <span class="chip" *ngIf="isVerifiedInvoice == true"
                    [style.background-color]="'#1b8bdd'">Verified</span>
                  <span class="chip" *ngIf="isVerifiedInvoice == false"
                    [style.background-color]="'#dd1a1b'">Reject</span>
                </p>
              </div>
            </div>
            <hr class=" full-width" *ngIf="isPreTreatment == true">
            <div class="row clearfix" *ngIf="isPreTreatment == true">

              <label>Purchase of Materials</label>

              <p>{{ settlement['Invoice.Details'] }}</p>
            </div>

            <hr class=" full-width" *ngIf="settlement['document']">


            <h3 *ngIf="settlement['document']" class=" sr-title rm-m smt smb">Supported Documents <span class="badge mr"
                [endVal]="settlement['document'].length" countUp></span> </h3>


            <app-ep-document-list class=" full-width" *ngIf="settlement['document']" [title]="' '"
              [verifiedIDs]="verifiedIDs" [rejectedIDs]="rejectedIDs" [documents]="settlement['document']"
              [isVerify]="true" [noViewOnClick]="true" (getViewDocumentObject)="getViewDocumentObject($event)">
            </app-ep-document-list>
          </div>





        </div>
        <div class="full-width settlement-support-docs flex ml"
          *ngIf="docObject && docObject.document && docObject.document.ID"
          style="   margin-top: -16px;  max-width: 42vw !important;  min-width: 42vw !important;  ">
          <mat-card class="full-width flex">
            <app-ep-document-view class=" full-width" [cardID]="docObject.cardID" [isDownload]="docObject.isDownload"
              [isModal]="false" [isPrint]="docObject.isPrint" [isVerify]="docObject.isVerify"
              [isRedirection]="docObject.isRedirection" [document]="docObject.document" [title]="docObject.title"
              [description]="docObject.description" (applyVerify)="verifyEvent($event,docObject.document.ID)">
            </app-ep-document-view>

          </mat-card>
        </div>

        <div class="full-width ml mr settlement-support-docs-empty"
          *ngIf="settlement && settlement.document &&  settlement.document.length>0 && (!docObject || (docObject && !docObject.document))"
          style="   margin-top: -16px;  max-width: 42vw !important;  min-width: 42vw !important;  ">
          <app-empty-list-message class="full-width ml" [title]="'No document selected'"
            [message]="'Select a supporting document to preview and verify.'">
          </app-empty-list-message>

        </div>
        <!-- <mat-card class="full-width ml">
        <div class="card-header clearfix inModal" [class.primary-gradient-img]="isRefund == false"
          [class.error-gradient]="isRefund == true">
          <h2 class="summary-header rm-m">Support Details</h2>
        </div>
        <div class="row clearfix" *ngIf="settlement['document'].length > 0">
          <app-ep-document-list [title]="'Settlement documents'" [documents]="settlement['document']" [isVerify]="true"
            [noViewOnClick]="true" (getViewDocumentObject)="getViewDocumentObject($event)"
            (applyVerify)="applyVerifyEvent($event)"></app-ep-document-list>
        </div>

        <div class="row clearfix" *ngIf="isPreTreatment == true">
          <hr />
          <h4 class="sr-title">Purchase of Materials</h4>
          <p>{{ settlement['Invoice.Details'] }}</p>
        </div>
      </mat-card> -->
      </div>

      <!-- <div class="full-width settlement-amount-card" *ngIf="settlement && settlement['Contract_key']">


      <app-contract-view class="full-width" [contractID]=" settlement['Contract_key']" [newSettlementButton]="false"
        [isModal]="false">
      </app-contract-view>

    </div> -->

      <!-- <div class="full-width" *ngIf="docObject && docObject.document">

      <app-ep-document-view [cardID]="docObject.cardID" [isDownload]="docObject.isDownload"
        [isPrint]="docObject.isPrint" [isVerify]="docObject.isVerify" [isRedirection]="docObject.isRedirection"
        [document]="docObject.document" [title]="docObject.title" [description]="docObject.description">
      </app-ep-document-view>
    </div> -->

    </mat-dialog-content>
    <hr>
    <div class="row clearfix text-center  mt" *ngIf="isModal!=true">


      <button class="pull-left btn-large" (click)="declineSettlement()" mat-raised-button
        color="accent">Decline</button>
      <!-- <button class="ml btn-large pull-left" *ngIf="isPreTreatment == false" (click)="reUploadDoc()" mat-raised-button
        color="accent" [matTooltip]="'Inform ' + ('KEYWORD.dentist' | translate) + ' to re-upload support document'">
        Invalid support document
      </button> -->
      <button class="pull-right  btn-large" *ngIf="isRefund == false"
        [disabled]="!(settlement && settlement['document'] && settlement['document'].length>0 && verifiedIDs && verifiedIDs.length>0 && verifiedIDs.length == settlement['document'].length  )"
        (click)="approveSettlementEvent()" mat-raised-button color="primary">
        Approve
      </button>
      <button class="pull-right  btn-large" *ngIf="isRefund == true && settlement['ConsentedViaPaperForm'] == '1'"
        [disabled]="!(settlement && settlement['document'] && settlement['document'].length>0 && verifiedIDs && verifiedIDs.length>0 && verifiedIDs.length == settlement['document'].length  )"
        (click)="
        approveSettlementEvent()" mat-raised-button color="primary">
        Approve
      </button>
      <button class="pull-right  btn-large" *ngIf="isRefund == true && settlement['ConsentedViaPaperForm'] != '1'"
        (click)="approveSettlementEvent()" mat-raised-button color="primary">
        Approve
      </button>
    </div>


    <div class="row clearfix text-center  mt" *ngIf="isModal==true">

      <button class=" btn-large mr" (click)="declineSettlement()" mat-raised-button color="accent">Decline</button>
      <!-- <button class="ml btn-large pull-left" *ngIf="isPreTreatment == false" (click)="reUploadDoc()" mat-raised-button
        color="accent" [matTooltip]="'Inform ' + ('KEYWORD.dentist' | translate) + ' to re-upload support document'">
        Invalid support document
      </button> -->
      <button class="  btn-large ml" *ngIf="isRefund == false"
        [disabled]="!(settlement && settlement['document'] && settlement['document'].length>0 && verifiedIDs && verifiedIDs.length>0 && verifiedIDs.length == settlement['document'].length  )"
        (click)="approveSettlementEvent()" mat-raised-button color="primary">
        Approve
      </button>
      <button class="  btn-large  ml" *ngIf="isRefund == true && settlement['ConsentedViaPaperForm'] == '1'"
        [disabled]="!(settlement && settlement['document'] && settlement['document'].length>0 && verifiedIDs && verifiedIDs.length>0 && verifiedIDs.length == settlement['document'].length  )"
        (click)="
        approveSettlementEvent()" mat-raised-button color="primary">
        Approve
      </button>
      <button class="  btn-large  ml" *ngIf="isRefund == true && settlement['ConsentedViaPaperForm'] != '1'"
        (click)="approveSettlementEvent()" mat-raised-button color="primary">
        Approve
      </button>
    </div>

  </mat-card>
</div>
