<div class="rel dentist-card merchantView clearfix" *ngIf="merchant" @onChangeAnimation>
  <div class="animate">
    <div class="card-header primary-gradient-img clearfix">
      <div class="row clearfix">
        <!-- Modal dismiss button -->
        <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeEvent()">
          <mat-icon>clear</mat-icon>
        </button>
        <!-- merchant calculated name -->

        <div class="row clearfix">
          <app-merchant-view-picture class="headNavView userIconView" [merchantID]="merchant['ID']">
          </app-merchant-view-picture>

          <h2 class="summary-header rm-m">
            {{ merchant.CalculatedName }}
            <span class="subLabel">Merchant</span>
          </h2>
        </div>
      </div>
      <div class="action-button">
        <button class="actionMenuToggle" [matMenuTriggerFor]="menu" mat-raised-button>
          Actions <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            class=""
            *ngIf="
              merchant['emails.Email'] && (!currentUser || (currentUser && merchant.ID != currentUser['practiceKey']))
            "
            (click)="contact()"
            mat-menu-item
            color="accent"
            matTooltip="Contact {{ merchant.CalculatedName }}"
          >
            <mat-icon>contact_mail</mat-icon>
            <span>Contact Mechant</span>
          </button>
          <button
            *ngIf="merchant['addresses.Calculated']"
            (click)="displayMap()"
            mat-menu-item
            color="accent"
            matTooltip="View on map"
          >
            <mat-icon>place</mat-icon>
            <span>View on map</span>
          </button>
          <button
            *ngIf="isPromoterOrAdmin == true && merchant['ID']"
            (click)="viewMerchantLogs(merchant['ID'])"
            mat-menu-item
            matTooltip="View merchant logs"
          >
            <mat-icon class="fa fa-list-alt"></mat-icon>
            <span>View merchant logs</span>
          </button>

          <button
            *ngIf="isPromoterOrAdmin == true && merchant['ID']"
            (click)="merchantAccess(merchant['ID'])"
            mat-menu-item
          >
            <mat-icon class="fa fa-key"></mat-icon>
            <span>Merchant Access</span>
          </button>
        </mat-menu>
      </div>
    </div>

    <div class="full-width mb clearfix row">
      <div class="row clearfix details flex">
        <mat-icon class="ico">person</mat-icon>
        <div class="row-content">
          <p class="rm-mb rm-mt lead">{{ merchant['TradingAs'] }}</p>
          <p class="small">Trading As</p>
        </div>
      </div>
      <hr *ngIf="merchant['ABN']"/>
      <div class="row clearfix details flex" *ngIf="merchant['ABN']">
        <mat-icon class="ico"></mat-icon>
        <div class="row-content">
          <p class="rm-mb rm-mt lead">{{ merchant['ABN'] }}</p>
          <p class="small">ABN</p>
        </div>
      </div>
      <hr *ngIf="merchant['phones.Number']"/>
      <div class="row clearfix details flex" *ngIf="merchant['phones.Number']">
        <mat-icon class="ico">phone</mat-icon>
        <!-- Phone numbers -->
        <div class="row-content">
          <p class="rm-mb rm-mt lead">{{ merchant['phones.Number'] | customPhone: 'landLine' }}</p>
          <p class="small">Phone</p>
        </div>
      </div>
      <hr *ngIf="merchant['mobiles.Number']"/>
      <div class="row clearfix details flex" *ngIf="merchant['mobiles.Number']">
        <mat-icon class="ico"></mat-icon>
        <div class="row-content">
          <p class="rm-mb rm-mt lead">{{ merchant['mobiles.Number'] | customPhone: 'landLine' }}</p>
          <p class="small">Mobile</p>
        </div>
      </div>
      <!-- Email Addresses -->
      <hr/>
      <div class="row clearfix details flex" *ngIf="merchant['emails.Email']">
        <mat-icon class="ico">email</mat-icon>
        <div class="row-content">
          <p class="rm-mb rm-mt lead">{{ merchant['emails.Email'] }}</p>
          <p class="small">Email</p>
        </div>
      </div>
      <hr *ngIf="merchant['addresses.Calculated']"/>
      <div class="row clearfix details flex" *ngIf="merchant['addresses.Calculated']">
        <mat-icon class="ico">location_on</mat-icon>
        <div class="row-content">
          <p class="rm-mb rm-mt lead">{{ merchant['addresses.Calculated'] }}</p>
          <p class="small">Address</p>
        </div>
      </div>
    </div>
  </div>
</div>
