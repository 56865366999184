import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { MerchantLinkService } from '../shared/merchant-link.service';

import { environment } from 'environments/environment';
import * as _ from 'lodash';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { MerchantLinkCreateEditComponent } from '../merchant-link-create-edit/merchant-link-create-edit.component';
@Component({
  selector: 'merchant-link-lookup',
  templateUrl: './merchant-link-lookup.component.html',
  styleUrls: ['./merchant-link-lookup.component.css'],
})
export class MerchantLinkLookupComponent implements OnInit {
  @Output()
  getResult = new EventEmitter();

  @Output()
  close = new EventEmitter();

  merchantID;
  sessionType;

  merchantLinks = [];
  maxLength = 80;
  isLoaded = false;
  isCreate = true;
  typeFilter;
  isDefaultLinkExist = false;
  isUserLinkExist = false;
  currentPage;
  currentPageDefault = 1;
  currentPageUser = 1;
  currentPageAll = 1;

  isDestoyed = false;
  itemsPerPage = 20;
  serviceRef;
  filterData;
  orderByFilter = 'label';
  isPromoterOrAdmin = false;
  viewOnly = false;

  constructor(
    private authenticationService: AuthenticationService,
    private merchantLinkService: MerchantLinkService,

    private utilService: UtilsService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.merchantID) {
        this.merchantID = data.merchantID;
      }
      if (data.isCreate == true) {
        this.isCreate = true;
      } else if (data.isCreate == false) {
        this.isCreate = false;
      }
      if (data.viewOnly == true) {
        this.viewOnly = true;
      } else if (data.viewOnly == false) {
        this.viewOnly = false;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
      this.isPromoterOrAdmin = isPromoterOrAdmin;
      this.authenticationService.getSessionType().subscribe((s) => {
        this.sessionType = s;

        this.setup();
      });
    });
  }

  backToOne() {
    this.currentPage = 1;
    this.currentPageDefault = 1;
    this.currentPageUser = 1;
  }

  changePage(e) {
    if (e) {
      if (this.typeFilter == 'user') {
        this.currentPageUser = e;
        this.currentPage = e;
      } else if (this.typeFilter == 'default') {
        this.currentPageDefault = e;
        this.currentPage = e;
      } else {
        this.currentPageAll = e;
        this.currentPage = e;
      }
    }
  }
  changeTab() {
    if (this.typeFilter == 'user') {
      this.currentPage = this.currentPageUser;
    } else if (this.typeFilter == 'default') {
      this.currentPage = this.currentPageDefault;
    } else {
      this.currentPage = this.currentPageAll;
    }
  }
  setup() {
    const payload = {
      merchantID: this.merchantID,
      addDefault: true,
      fields: 'ID,Link,Label,Is_Default,DateTimeCreated',
      section: 1,
      orderBy: 'Label',
    };
    this.merchantLinkService.getMerchantLinkList(payload, this.sessionType).subscribe((res) => {
      this.isLoaded = true;
      if (res && res.length > 0) {
        res = _.map(res, (item) => {
          if (item) {
            item = this.buildItem(item);
          }
          return item;
        });

        this.merchantLinks = res;

        const usermerchantLinksCount = this.merchantLinks.filter((template) => template.type === 'user').length;

        if (usermerchantLinksCount > 0) {
          this.typeFilter = 'user';
        } else {
          this.typeFilter = 'default';
        }

        const innerFunction = (section) => {
          section = section + 1;
          payload.section = section;

          this.serviceRef = this.merchantLinkService.getMerchantLinkList(payload, this.sessionType).subscribe((res) => {
            if (res.length > 0) {
              res = _.map(res, (item) => {
                if (item) {
                  item = this.buildItem(item);
                }
                return item;
              });

              this.merchantLinks = this.merchantLinks.concat(res);

              if (this.typeFilter != 'user') {
                const _usermerchantLinksCount = this.merchantLinks.filter(
                  (template) => template.type === 'user'
                ).length;

                if (_usermerchantLinksCount > 0) {
                  this.typeFilter = 'user';
                } else {
                  this.typeFilter = 'default';
                }
              }

              if (this.isDestoyed != true) {
                innerFunction(section);
              }
            } else {
              return true;
            }
          });
        };

        if (this.isDestoyed != true) {
          innerFunction(1);
        }
      }
    });
  }

  orderBy(d) {
    if (d) {
      let isUpOrder = true;

      if (d && d[0] == '-') {
        isUpOrder = false;
      }

      if (this.orderByFilter === d) {
        if (isUpOrder == true) {
          this.orderByFilter = '-' + d;
        } else {
          this.orderByFilter = d.replace('-', '');
        }
      } else {
        this.orderByFilter = d;
      }
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
  previewLink(url) {
    if (url) {
      if (url.indexOf('http') === -1) {
        window.open('https://' + url, '_blank');
      } else {
        window.open(url, '_blank');
      }
    }
  }

  previewOrSubmit(d) {
    if (d && d.Link) {
      if (this.viewOnly == true) {
        this.previewLink(d.Link);
      } else {
        if (d.ID) {
          const l = `${environment.nodeUrl}/merchant-link/redirect/${d.ID}`;
          this.utilService.createShortcut(l).subscribe((r) => {
            if (r && r.shortLink) {
              let label = d.Link;
              if (d.Label) {
                label = d.Label;
              }

              const result = {
                link: d.Link,
                shortLink: r.shortLink,
                title: label,
                htmlLink: `<a title="${label}" target="_blank" href="${r.shortLink}">${r.shortLink}</a>`,
              };

              if (label) {
                result.htmlLink = `<a title="${label}" target="_blank" href="${r.shortLink}">${label}</a>`;
              }

              this.getResult.emit(result);
            }
          });
        }
      }
    }
  }

  delete(id) {
    if (id) {
      const confirm = new ConfirmDialog('delete', 'Are you sure  ?', 'Remove a the Link', 'No', 'Yes');
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.merchantLinkService.deleteMerchantLink(id, this.sessionType).subscribe((r) => {
            if (r) {
              const index = this.merchantLinks.findIndex((e) => {
                if (e && e.ID && e.ID == id) {
                  return true;
                }

                return false;
              });

              if (index !== -1) {
                this.merchantLinks.splice(index, 1);

                this.merchantLinks = JSON.parse(JSON.stringify(this.merchantLinks));
              }

              NotifyAppComponent.displayToast('success', 'Successfull', 'Link is removed');
              ref.close();
            }
          });
        }
      });
    }
  }

  edit(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(MerchantLinkCreateEditComponent, {
        data: {
          createTemplate: true,
          displayAddTemplate: false,
          displayLookup: false,
          merchantLinkID: id,
        },
        width: '800px',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
      ref.componentInstance.getResult.subscribe((item) => {
        if (item) {
          if (item) {
            item = this.buildItem(item);
          }

          const index = this.merchantLinks.findIndex((e) => {
            if (e && e.ID && e.ID == item.ID) {
              return true;
            }

            return false;
          });

          if (index !== -1) {
            this.merchantLinks[index] = item;

            this.merchantLinks = JSON.parse(JSON.stringify(this.merchantLinks));

            this.backToOne();

            this.filterData = item.Label;
            this.typeFilter = null;
          } else {
            this.merchantLinks.push(item);

            this.merchantLinks = JSON.parse(JSON.stringify(this.merchantLinks));

            this.backToOne();

            this.filterData = item.Label;
            this.typeFilter = null;
          }

          ref.close();
        }
      });
    }
  }
  create() {
    const ref = RootAppComponent.dialog.open(MerchantLinkCreateEditComponent, {
      data: {
        createTemplate: true,
        displayAddTemplate: false,
        displayLookup: false,
      },
      width: '800px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.getResult.subscribe((item) => {
      if (item) {
        if (item) {
          item = this.buildItem(item);
        }

        const index = this.merchantLinks.findIndex((e) => {
          if (e && e.Link && e.Link == item.Link) {
            return true;
          }

          return false;
        });

        if (index !== -1) {
          this.merchantLinks[index] = item;

          this.merchantLinks = JSON.parse(JSON.stringify(this.merchantLinks));

          this.backToOne();

          this.filterData = item.Label;
          this.typeFilter = null;
        } else {
          this.merchantLinks.push(item);

          this.merchantLinks = JSON.parse(JSON.stringify(this.merchantLinks));

          this.backToOne();

          this.filterData = item.Label;
          this.typeFilter = null;
        }

        ref.close();
      }
    });
  }

  download(item) {
    if (item && item.ID) {
      item.downloadQRCode.emit();
    }
  }

  getRedirectURL(id) {
    if (id) {
      return `${environment.nodeUrl}/merchant-link/redirect/${id}`;
    }

    return null;
  }
  buildItem(_item) {
    const item = _item;

    if (item) {
      item.search = '';
      if (item.Label) {
        item.search = item.search + ' ' + item.Label;
      }
      if (item.Link) {
        item.search = item.search + ' ' + item.Link;
      }
      if (typeof item.tags === 'string') {
        item.search = item.search + ' ' + item.tags;
      } else if (Array.isArray(item.tags)) {
        item.search = item.search + ' ' + item.tags.join('|');
      }

      if (item.Is_Default === '1') {
        item.type = 'default';
      } else {
        item.type = 'user';
      }

      item.downloadQRCode = new EventEmitter();
    }

    return item;
  }
}
