<ng-container *ngIf="viewModel$ | async as viewModel">
  <mat-sidenav-container class="profile-container" *ngIf="viewModel.beneficiary">
    <!-- supplier profile in side nav -->
    <mat-sidenav class="profile-sidebar" #sidenavProfile [mode]="navMode" [opened]="openFlag" [position]="'end'">
      <app-beneficiary-view [beneficiaryID]="viewModel.beneficiary['ID']" [abnDetails]="false"></app-beneficiary-view>
    </mat-sidenav>

    <div class="tab-container">
      <div class="row clearfix">
        <mat-tab-group class="sub-cont-list" dynamicHeight="true">
          <!-- treatment docs lists -->
          <mat-tab [label]="'KEYWORD.Treatment Products' | translate">
            <ng-template mat-tab-label>
              <mat-icon class="fas fa-map-marker-alt"></mat-icon>

              <span> {{"PHRASE.treatment product" | translate | titlecase}}s </span>

              <span class="ml chip" [endVal]="productLength" countUp></span>
            </ng-template>

            <ipv-dynamic-product-list
              class="list-filter-card clearfix full-width"
              [pageSize]="20"
              [miniDisplay]="true"
              [supplierID]="viewModel.beneficiary['ID']"
              [defaultHeading]="false"
              (getLength)="getProductLength($event)"
            >
            </ipv-dynamic-product-list>
          </mat-tab>

          <mat-tab *ngIf="viewModel.industryType === 'dental'" label="Supplier Documents">
            <ng-template mat-tab-label>
              <mat-icon class="fas fa-file"></mat-icon> Supplier Documents

              <span class="ml chip" [endVal]="documentLength" countUp></span>
            </ng-template>

            <!-- treatment docs if promoter -->
            <div class="full-width flex flexCol catalogue">
              <app-document-list
                class="list-filter-card clearfix full-width"
                [type]="'SRVSPL'"
                [pageSize]="20"
                [cardID]="viewModel.beneficiary['ID']"
                (getLength)="getDocumentLength($event)"
              >
              </app-document-list>
            </div>
          </mat-tab>

          <!-- subsidary / office locations list card -->
          <mat-tab label="Locations">
            <ng-template mat-tab-label>
              <mat-icon class="fas fa-map-marker-alt"></mat-icon>Locations

              <span class="ml chip" [endVal]="subLength" countUp></span>
            </ng-template>
            <app-beneficiary-subsidiary-list
              [beneficiaryID]="viewModel.beneficiary['ID']"
              (getLength)="getSubLength($event)"
            >
            </app-beneficiary-subsidiary-list>
          </mat-tab>
          <!-- contact list card -->
          <mat-tab label="Contacts">
            <ng-template mat-tab-label>
              <mat-icon class="fas fa-id-badge"></mat-icon>Contacts

              <span class="ml chip" [endVal]="contactLength" countUp></span>
            </ng-template>
            <app-contact-list
              [cardID]="viewModel.beneficiary['ID']"
              [type]="'supplier'"
              [selectSub]="true"
              [simpleList]="true"
              [hideTitle]="true"
              (getLength)="getContactLength($event)"
            ></app-contact-list>
          </mat-tab>
        </mat-tab-group>
      </div>

      <!-- back btn -->
      <div class="row clearfix mt">
        <button (click)="goBack()" mat-raised-button color="accent">
          <mat-icon>chevron_left</mat-icon>
          Back
        </button>
      </div>
    </div>
  </mat-sidenav-container>
</ng-container>
