<div class="container profiles">
  <mat-card class="card details">
    <div class="tab-content clearfix" @ngIfAnimation>
      <div class="tab-content-inner">
        <div class="row clearfix">
          <button
            class="pull-right"
            (click)="viewVideoInfo('Cash discounts', 'vimeo.com/355935655/d2720c17e1')"
            mat-raised-button
            color="primary"
          >
            What's this?
            <mat-icon class="fas fa-info-circle"></mat-icon>
          </button>
        </div>
        <app-discount-setting class="clearfix" [merchantID]="merchantID"></app-discount-setting>
      </div>
    </div>
  </mat-card>
</div>
