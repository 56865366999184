<div class="full-width" *ngIf="tag && tag.Cards_key">
  <app-customer-prospect-list
    *ngIf="isPromoterOrAdmin != true"
    [IDs]="tag.Cards_key"
    [title]="('KEYWORD.patient' | translate | titlecase) + 's' + ' under the tag ' + tag.Label"
  >
  </app-customer-prospect-list>

  <app-customer-prospect-list-global
    *ngIf="isPromoterOrAdmin == true"
    [IDs]="tag.Cards_key"
    [merchantID]="tag.Merchant_key"
    [title]="('KEYWORD.patient' | translate | titlecase) + 's' + ' under the Tag ' + tag.Label"
  >
  </app-customer-prospect-list-global>
</div>

<div class="full-width mt">
  <button
    class="clearfix pull-left"
    [routerLink]="['/merchant', { outlets: { page: ['audiences-settings'] } }]"
    mat-raised-button
    color="primary"
  >
    <mat-icon>keyboard_arrow_left</mat-icon> Audiences
  </button>
</div>
