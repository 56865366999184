<mat-dialog-content class="content-container" *ngIf="logObject">
  <div class="card-header primary-gradient-img clearfix stacked">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m">
          {{ logObject.baseUrl | ltrim: '/api' }}
          <span class="subLabel" *ngIf="logObject.url">{{ logObject.url | ltrim: '/api' }}</span>
        </h2>
        <p class="clearfix small rm-mb">
          <span
            [ngClass]="{
              'chip-enable': logObject.status < 300,
              'chip-disable': logObject.status >= 300
            }"
            >{{ logObject.status }}</span
          >
          <span
            [ngClass]="{
              'chip-enable': !logObject.response.Error,
              'chip-disable': logObject.response.Error
            }"
          >{{ logObject.response.Error ? utils.errorLabel(logObject.response.Error.code) : 'Success' }}</span
          >
        </p>
        <p class="clearfix small rm-mb">
          <span> {{ logObject.date | TimeLabelPast }} ago </span>
        </p>
      </div>
    </div>
  </div>
  <pre class="log-view">
      <div [innerHTML]="logObject | json"></div>
   </pre>
</mat-dialog-content>
