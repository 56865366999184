import { Component } from '@angular/core';

import { TitleCasePipe } from '@angular/common';
import { switchMap, tap } from 'rxjs/operators';
import {
  ConfirmModalComponent,
  ConfirmModalConfig,
} from '../../shared/components/confirm-modal/confirm-modal.component';
import { RootAppComponent } from '../../shared/components/root-component/root-component.component';
import { NotifyAppComponent } from '../../shared/types/notify-app-component';
import {
  WishlistManagerModalComponent,
  WishlistManagerModalConfig,
  WishlistManagerModalResult,
} from './shared/modals/wishlist-manager-modal/wishlist-manager-modal.component';
import { WishlistCategory, WishlistItem, WishlistService } from './shared/services/wishlist.service';

@Component({
  selector: 'ipv-ventures-wishlist',
  templateUrl: './wishlist.page.html',
  styleUrls: ['./wishlist.page.css'],
  providers: [TitleCasePipe],
})
export class WishlistPage {
  category: WishlistCategory | 'all' = 'all';
  wishlist$ = this.wishlistService.fetchWishlist({}).pipe(
    tap((wishlist) => {
      this.wishlistService.setWishlist(wishlist);
    }),
    switchMap(() => {
      return this.wishlistService.getWishlist$();
    })
  );

  constructor(private wishlistService: WishlistService, private titleCasePipe: TitleCasePipe) {}

  openWishlistItemCreateModal(wishlist: WishlistItem[]) {
    const wishlistManagerCreateModal = RootAppComponent.dialog.open<
      WishlistManagerModalComponent,
      WishlistManagerModalConfig
    >(WishlistManagerModalComponent, {
      data: {
        Code: this.category,
      },
    });

    wishlistManagerCreateModal.componentInstance.submitted.subscribe(
      (wishlistManagerCreateModalResult: WishlistManagerModalResult) => {
        const newWishlistItem = {
          Label: wishlistManagerCreateModalResult.Label,
          Code: wishlistManagerCreateModalResult.Code,
          Description: wishlistManagerCreateModalResult.Description,
        };

        this.wishlistService.createWishlistItem(newWishlistItem, wishlist).subscribe((wishlistItem: WishlistItem) => {
          NotifyAppComponent.displayToast(
            'success',
            'Successful Operation',
            `New wishlist item ${this.titleCasePipe.transform(wishlistItem.Label)}  created.`
          );
          wishlistManagerCreateModal.close();
        });
      }
    );
  }

  openWishlistItemUpdateModal(wishlistItem: WishlistItem, wishlist: WishlistItem[]) {
    const wishlistManagerUpdateModal = RootAppComponent.dialog.open<
      WishlistManagerModalComponent,
      WishlistManagerModalConfig
    >(WishlistManagerModalComponent, {
      data: {
        Code: this.category,
        set: {
          ID: wishlistItem.ID,
          Label: wishlistItem.Label,
          Code: wishlistItem.Code,
          Description: wishlistItem.Description,
        },
      },
    });

    wishlistManagerUpdateModal.componentInstance.submitted.subscribe(
      (wishlistManagerUpdateModalResult: WishlistManagerModalResult) => {
        const updateWishlistItem = {
          ID: wishlistManagerUpdateModalResult.ID,
          Code: wishlistManagerUpdateModalResult.Code,
          Label: wishlistManagerUpdateModalResult.Label,
          Description: wishlistManagerUpdateModalResult.Description,
        };

        this.wishlistService.updateWishlistItem(updateWishlistItem, wishlist).subscribe(() => {
          NotifyAppComponent.displayToast(
            'success',
            'Successful Operation',
            `Wishlist item ${this.titleCasePipe.transform(wishlistItem.Label)}  updated.`
          );
          wishlistManagerUpdateModal.close();
        });
      }
    );
  }

  openWishlistItemDeleteModal(wishlistItem: WishlistItem, wishlist: WishlistItem[]) {
    const wishlistManagerDeleteModal = RootAppComponent.dialog.open<ConfirmModalComponent, ConfirmModalConfig>(
      ConfirmModalComponent,
      {
        data: {
          message: `Are you sure you want to delete ${this.titleCasePipe.transform(wishlistItem.Label)}?`,
          acceptButtonText: 'Yes',
        },
      }
    );

    wishlistManagerDeleteModal.componentInstance.acceptButtonClicked.subscribe(() => {
      const deleteWishlistItem = {
        ID: wishlistItem.ID,
        Code: wishlistItem.Code,
        Label: wishlistItem.Label,
        Description: wishlistItem.Description,
      };

      this.wishlistService.deleteWishlistItem(deleteWishlistItem, wishlist).subscribe(() => {
        NotifyAppComponent.displayToast(
          'success',
          'Successful Operation',
          `Wishlist item ${this.titleCasePipe.transform(wishlistItem.Label)}  deleted.`
        );
        wishlistManagerDeleteModal.close();
      });
    });
  }
}
