/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./treatment-document.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "../../../shared/components/ep-document-list-mini/ep-document-list-mini.component.ngfactory";
import * as i6 from "../../../shared/components/ep-document-list-mini/ep-document-list-mini.component";
import * as i7 from "@angular/material/dialog";
import * as i8 from "../../../shared/services/utils.service";
import * as i9 from "@angular/common";
import * as i10 from "./treatment-document.component";
import * as i11 from "../../../core/authentication/shared/authentication.service";
import * as i12 from "../../invitation/shared/services/invitation.service";
import * as i13 from "../shared/treatment.service";
var styles_TreatmentDocumentComponent = [i0.styles];
var RenderType_TreatmentDocumentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TreatmentDocumentComponent, data: {} });
export { RenderType_TreatmentDocumentComponent as RenderType_TreatmentDocumentComponent };
function View_TreatmentDocumentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-card", [["class", "treatment treatment-shadow-box mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [[2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "app-ep-document-list-mini", [["class", "white-title"]], null, [[null, "getViewedDocument"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("getViewedDocument" === en)) {
        var pd_0 = (_co.getViewedDocumentEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_EpDocumentListMiniComponent_0, i5.RenderType_EpDocumentListMiniComponent)), i1.ɵdid(3, 114688, null, 0, i6.EpDocumentListMiniComponent, [i7.MatDialog, i8.UtilsService], { documents: [0, "documents"], cardID: [1, "cardID"], view: [2, "view"], title: [3, "title"], instructionLine: [4, "instructionLine"], displayDate: [5, "displayDate"], displayViewedDate: [6, "displayViewedDate"], displayStatus: [7, "displayStatus"], viewedDocuments: [8, "viewedDocuments"], isDownload: [9, "isDownload"], isFilter: [10, "isFilter"] }, { getViewedDocument: "getViewedDocument" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.treatmentDocuments; var currVal_2 = _co.cardID; var currVal_3 = _co.view; var currVal_4 = _co.treatmentDocumentTitle; var currVal_5 = _co.treatmentInstructionLine; var currVal_6 = false; var currVal_7 = _co.isDisplayViewedDate; var currVal_8 = true; var currVal_9 = _co.viewedDocuments; var currVal_10 = _co.isDownload; var currVal_11 = _co.isFilter; _ck(_v, 3, 1, [currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0); }); }
function View_TreatmentDocumentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-card", [["class", "treatment treatment-shadow-box mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [[2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "app-ep-document-list-mini", [["class", "white-title"]], null, [[null, "getViewedDocument"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("getViewedDocument" === en)) {
        var pd_0 = (_co.getViewedDocumentEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_EpDocumentListMiniComponent_0, i5.RenderType_EpDocumentListMiniComponent)), i1.ɵdid(3, 114688, null, 0, i6.EpDocumentListMiniComponent, [i7.MatDialog, i8.UtilsService], { documents: [0, "documents"], view: [1, "view"], title: [2, "title"], instructionLine: [3, "instructionLine"], isAction: [4, "isAction"], displayDate: [5, "displayDate"], displayViewedDate: [6, "displayViewedDate"], displayStatus: [7, "displayStatus"], viewedDocuments: [8, "viewedDocuments"], isRedirection: [9, "isRedirection"], isPrint: [10, "isPrint"], isFilter: [11, "isFilter"] }, { getViewedDocument: "getViewedDocument" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.stockArtTreatmentDocuments; var currVal_2 = _co.view; var currVal_3 = _co.stockArtTreatmentDocumentTitle; var currVal_4 = _co.stockArtTreatmentInstructionLine; var currVal_5 = false; var currVal_6 = false; var currVal_7 = _co.isDisplayViewedDate; var currVal_8 = true; var currVal_9 = _co.viewedDocuments; var currVal_10 = false; var currVal_11 = _co.isPrint; var currVal_12 = _co.isFilter; _ck(_v, 3, 1, [currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12]); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0); }); }
function View_TreatmentDocumentComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-card", [["class", "treatment treatment-shadow-box mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [[2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "app-ep-document-list-mini", [["class", "white-title"]], null, [[null, "getViewedDocument"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("getViewedDocument" === en)) {
        var pd_0 = (_co.getViewedDocumentEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_EpDocumentListMiniComponent_0, i5.RenderType_EpDocumentListMiniComponent)), i1.ɵdid(3, 114688, null, 0, i6.EpDocumentListMiniComponent, [i7.MatDialog, i8.UtilsService], { documents: [0, "documents"], cardID: [1, "cardID"], view: [2, "view"], title: [3, "title"], instructionLine: [4, "instructionLine"], isAction: [5, "isAction"], displayDate: [6, "displayDate"], displayViewedDate: [7, "displayViewedDate"], displayStatus: [8, "displayStatus"], viewedDocuments: [9, "viewedDocuments"], isDownload: [10, "isDownload"], isRedirection: [11, "isRedirection"], isPrint: [12, "isPrint"], isFilter: [13, "isFilter"] }, { getViewedDocument: "getViewedDocument" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.preTreatmentDocuments; var currVal_2 = _co.cardID; var currVal_3 = _co.view; var currVal_4 = _co.preTreatmentDocumentTitle; var currVal_5 = _co.preTreatmentInstructionLine; var currVal_6 = false; var currVal_7 = false; var currVal_8 = _co.isDisplayViewedDate; var currVal_9 = true; var currVal_10 = _co.viewedDocuments; var currVal_11 = _co.isDownload; var currVal_12 = false; var currVal_13 = _co.isPrint; var currVal_14 = _co.isFilter; _ck(_v, 3, 1, [currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14]); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0); }); }
function View_TreatmentDocumentComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-card", [["class", "treatment treatment-shadow-box mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [[2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "app-ep-document-list-mini", [["class", "white-title"]], null, [[null, "getViewedDocument"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("getViewedDocument" === en)) {
        var pd_0 = (_co.getViewedDocumentEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_EpDocumentListMiniComponent_0, i5.RenderType_EpDocumentListMiniComponent)), i1.ɵdid(3, 114688, null, 0, i6.EpDocumentListMiniComponent, [i7.MatDialog, i8.UtilsService], { documents: [0, "documents"], cardID: [1, "cardID"], view: [2, "view"], title: [3, "title"], instructionLine: [4, "instructionLine"], isAction: [5, "isAction"], displayDate: [6, "displayDate"], displayViewedDate: [7, "displayViewedDate"], displayStatus: [8, "displayStatus"], viewedDocuments: [9, "viewedDocuments"], isDownload: [10, "isDownload"], isRedirection: [11, "isRedirection"], isPrint: [12, "isPrint"], isFilter: [13, "isFilter"] }, { getViewedDocument: "getViewedDocument" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.postTreatmentDocuments; var currVal_2 = _co.cardID; var currVal_3 = _co.view; var currVal_4 = _co.postTreatmentDocumentTitle; var currVal_5 = _co.postTreatmentInstructionLine; var currVal_6 = false; var currVal_7 = false; var currVal_8 = _co.isDisplayViewedDate; var currVal_9 = true; var currVal_10 = _co.viewedDocuments; var currVal_11 = _co.isDownload; var currVal_12 = false; var currVal_13 = _co.isPrint; var currVal_14 = _co.isFilter; _ck(_v, 3, 1, [currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14]); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0); }); }
function View_TreatmentDocumentComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-card", [["class", "treatment treatment-shadow-box mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [[2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "app-ep-document-list-mini", [["class", "white-title"]], null, [[null, "getViewedDocument"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("getViewedDocument" === en)) {
        var pd_0 = (_co.getViewedDocumentEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_EpDocumentListMiniComponent_0, i5.RenderType_EpDocumentListMiniComponent)), i1.ɵdid(3, 114688, null, 0, i6.EpDocumentListMiniComponent, [i7.MatDialog, i8.UtilsService], { documents: [0, "documents"], view: [1, "view"], title: [2, "title"], instructionLine: [3, "instructionLine"], isAction: [4, "isAction"], displayDate: [5, "displayDate"], displayViewedDate: [6, "displayViewedDate"], displayStatus: [7, "displayStatus"], viewedDocuments: [8, "viewedDocuments"], isRedirection: [9, "isRedirection"], isPrint: [10, "isPrint"], isFilter: [11, "isFilter"] }, { getViewedDocument: "getViewedDocument" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.marketingTreatmentDocuments; var currVal_2 = _co.view; var currVal_3 = _co.marketingTreatmentDocumentTitle; var currVal_4 = _co.marketingTreatmentInstructionLine; var currVal_5 = false; var currVal_6 = false; var currVal_7 = _co.isDisplayViewedDate; var currVal_8 = true; var currVal_9 = _co.viewedDocuments; var currVal_10 = false; var currVal_11 = _co.isPrint; var currVal_12 = _co.isFilter; _ck(_v, 3, 1, [currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12]); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0); }); }
export function View_TreatmentDocumentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "full-width clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TreatmentDocumentComponent_1)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TreatmentDocumentComponent_2)), i1.ɵdid(4, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TreatmentDocumentComponent_3)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TreatmentDocumentComponent_4)), i1.ɵdid(8, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TreatmentDocumentComponent_5)), i1.ɵdid(10, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.treatmentDocuments && (_co.treatmentDocuments.length > 0)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.stockArtTreatmentDocuments && (_co.stockArtTreatmentDocuments.length > 0)); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.preTreatmentDocuments && (_co.preTreatmentDocuments.length > 0)); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.postTreatmentDocuments && (_co.postTreatmentDocuments.length > 0)); _ck(_v, 8, 0, currVal_3); var currVal_4 = (_co.marketingTreatmentDocuments && (_co.marketingTreatmentDocuments.length > 0)); _ck(_v, 10, 0, currVal_4); }, null); }
export function View_TreatmentDocumentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-treatment-document", [], null, null, null, View_TreatmentDocumentComponent_0, RenderType_TreatmentDocumentComponent)), i1.ɵdid(1, 114688, null, 0, i10.TreatmentDocumentComponent, [i11.AuthenticationService, i12.InvitationService, i13.TreatmentService, i8.UtilsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TreatmentDocumentComponentNgFactory = i1.ɵccf("app-treatment-document", i10.TreatmentDocumentComponent, View_TreatmentDocumentComponent_Host_0, { invitationID: "invitationID", isPublic: "isPublic", treatmentDocumentTitle: "treatmentDocumentTitle", treatmentInstructionLine: "treatmentInstructionLine", preTreatmentDocumentTitle: "preTreatmentDocumentTitle", preTreatmentInstructionLine: "preTreatmentInstructionLine", postTreatmentDocumentTitle: "postTreatmentDocumentTitle", postTreatmentInstructionLine: "postTreatmentInstructionLine", marketingTreatmentDocumentTitle: "marketingTreatmentDocumentTitle", marketingTreatmentInstructionLine: "marketingTreatmentInstructionLine", stockArtTreatmentDocumentTitle: "stockArtTreatmentDocumentTitle", stockArtTreatmentInstructionLine: "stockArtTreatmentInstructionLine", view: "view", isDisplayViewedDate: "isDisplayViewedDate", isFilter: "isFilter", viewedDocuments: "viewedDocuments", canView: "canView" }, { getDocuments: "getDocuments", getDocumentsLength: "getDocumentsLength", getViewedDocuments: "getViewedDocuments", noDocuments: "noDocuments" }, []);
export { TreatmentDocumentComponentNgFactory as TreatmentDocumentComponentNgFactory };
