import { timer as observableTimer } from 'rxjs';

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { finalize, map, takeUntil } from 'rxjs/operators';

import 'rxjs/add/observable/timer';
import 'rxjs/add/operator/finally';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/takeUntil';

import { AuthenticationService } from '../../authentication/shared/authentication.service';

@Component({
  selector: 'app-session-countdown-modal',
  templateUrl: './session-countdown-modal.component.html',
  styleUrls: ['./session-countdown-modal.component.css'],
})
export class SessionCountdownModalComponent implements OnInit {
  @Output() close = new EventEmitter();
  timer = 60;
  countDown;
  timedOut = false;

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit() {
    const interval = 1000;
    const duration = 10000 * 6;
    this.timedOut = false;
    const stream$ = observableTimer(0, interval).pipe(
      finalize(() => {
        this.timedOut = true;
      }),
      takeUntil(observableTimer(duration + interval)),
      map((value) => duration - value * interval)
    );
    stream$.subscribe((value) => (this.timer = value / 1000));
  }
  closeEvent() {
    this.close.emit(true);
  }
  refreshSession() {
    this.authenticationService.refreshSession().subscribe((res) => {
      this.authenticationService.getCurrentUserForce().subscribe((res1) => {
        this.close.emit(true);
      });
    });
  }
}
