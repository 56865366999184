<div class="rel dash intro">
  <mat-dialog-content id="sub-plan-dialog-content">
    <div class="full-width beta-container">
      <button class="btn-close btn-clear close-button mat-button white" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>

      <div class="full-width inner text-center">
        <h1>Good News!</h1>
        <p class="rm-mb">
          We have been busy updating <br/>
          new content and features <br/>in your <strong>Subscription</strong>.
        </p>
        <br/>
        <p>
          To find out more about what has changed, <br/>
          click <strong>CHECK IT OUT</strong> below. <br/>
        </p>
      </div>

      <div class="full-width footer">
        <div class="full-width text-center">
          <button class="smt check-module btn-large" (click)="redirect()" mat-raised-button>CHECK IT OUT</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
