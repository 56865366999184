import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { MapViewComponent } from '../../../shared/components/map-view/map-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { ActionLogListComponent } from '../../action-log/action-log-list/action-log-list.component';
import { CustomerProspectService } from '../../customer-prospect/shared/customerProspect.service';
import { InvitationExistingPatientListComponent } from '../../invitation/invitation-existing-patient-list/invitation-existing-patient-list.component';
import { NotesModal, NotesModalConfig } from '../../notes/modals/notes-modal/notes.modal';
import { ProductService } from '../../product/shared/product.service';
import { CustomerService } from '../shared/customer.service';

@Component({
  selector: 'app-customer-view',
  templateUrl: './customer-view.component.html',
  styleUrls: ['./customer-view.component.css'],
  animations: [
    trigger('onChangeAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-10%)', offset: 0, height: 0, margin: 0 }),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*', margin: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*', margin: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(5%)', offset: 1.0, height: 0, margin: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class CustomerViewComponent implements OnInit {
  @Input()
  customerID;

  @Input()
  customer;

  @Input()
  customerObject;

  @Output()
  closeModal = new EventEmitter();

  isReferral = false;
  isPromoterOrAdmin = false;
  subjectArray = [];
  displayMapFlag = true;

  profileLink;

  isModuleNoteFeaturesActive = Settings.global['isModuleNoteFeaturesActive'];
  isModuleTreatmentPlanActive = Settings.global['isModuleTreatmentPlanActive'];
  sessionType = 'guest';
  financedProductGroup;

  isModal = false;

  isCustomer = false;

  constructor(
    private location: Location,
    private customerService: CustomerService,
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private customerProspectService: CustomerProspectService,
    private router: Router,
    private utilService: UtilsService,
    private productService: ProductService,
    private dialog: MatDialog,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.customerID = data;

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }
      this.authenticationService.isCustomer().subscribe((isCustomer) => {
        this.isCustomer = isCustomer;

        this.utilService.getCurrentAccess().subscribe((access) => {
          if (access) {
            this.isModuleNoteFeaturesActive = access['isModuleNoteFeaturesActive'];
            this.isModuleTreatmentPlanActive = access['isModuleTreatmentPlanActive'];
          }

          this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
            this.isPromoterOrAdmin = res;
          });
          this.activeRoute.params.subscribe((params) => {
            if (params['customerID']) {
              this.customerID = params['customerID'];
            }
            if (this.customerObject != null && typeof this.customerObject == 'object') {
              this.customerService
                .getCustomerDetails(this.customerObject['ID'], {}, this.sessionType)
                .subscribe((res) => {
                  if (res) {
                    this.customer = res;

                    this.customerID = this.customerObject['ID'];
                    this.getPicture();
                  }
                });
            } else if (this.customerID) {
              this.customerService.getCustomerDetails(this.customerID, {}, this.sessionType).subscribe((res) => {
                if (res) {
                  this.customer = res;
                  this.getPicture();
                }
              });
            }
            const p1 = {
              isFinance: true,
            };

            this.productService.getMerchantProductGroup(p1, this.isPromoterOrAdmin).subscribe((res) => {
              this.financedProductGroup = res;
            });
          });
        });
      });
    });
  }

  getPicture() {
    if (this.customerID) {
      this.getProfilePicture();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.customerID && changes.customerID.previousValue != changes.customerID.currentValue) {
      if (this.customerID) {
        this.customerService.getCustomerDetails(this.customerID, {}, this.sessionType).subscribe((res) => {
          if (res) {
            this.customer = res;
            this.getPicture();
          }
        });
      }
    }
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  closeEvent() {
    this.closeModal.emit(true);
  }

  getMoreInfo() {
    const phone = '';

    const result =
      "<p><strong>Dentist's name: </strong>" +
      this.customer['CalculatedName'] +
      '</p><p><strong>Phone: </strong>' +
      String(phone) +
      "</p><p><strong>Email: </strong> <a class='emailLink primary-color' href='mailto:" +
      String(this.customer['emails.Email']) +
      "' title='Email Dentist' target='_blank'>" +
      this.customer['emails.Email'] +
      '</a></p>';

    return result;
  }

  displayMap() {
    const location = {
      zoom: 17,
      postCode: this.customer['addresses.Postcode'],
      state: this.customer['addresses.State'],
      streetType: this.customer['addresses.Street Type'],
      streetName: this.customer['addresses.Street Name'],
      streetNumber: this.customer['addresses.Street Nr'],
      suburb: this.customer['addresses.Suburb'],
      country: this.customer['addresses.Country.Code'],
      addressLabel: this.customer['addresses.Calculated'],
      latitude: this.customer['Address.Latitude'],
      longitude: this.customer['Address.Longitude'],
      moreInfo: this.getMoreInfo(),
    };

    const ref = RootAppComponent.dialog.open(MapViewComponent, {
      data: location,
      panelClass: 'modal-map',
      width: '800px',
      height: '600px',
    });

    ref.componentInstance.addCoordinate.subscribe((res) => {
      if (res && res.latitude && res.longitude) {
        const payload = {
          latitude: res.latitude,
          longitude: res.longitude,
        };
        this.customerService.addCoordinate(this.customerID, payload).subscribe((res) => {});
      }
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  contact() {
    const data = {
      targetType: 'customer',
      targetID: this.customerID,
      asGuest: false,
      asProfile: true,
      asPractice: true,
      subjectArray: this.subjectArray,
    };

    AuthenticationService.contactInputEvent.emit(data);
  }

  editDetails(id) {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['customer-profile', id, 'patient'],
        },
      },
    ]);
    this.closeModal.emit(true);
  }

  // populateDetailCard(ID) {
  //   this.router.navigate([
  //     "/merchant",
  //     { outlets: { 'page': ["customer-details-card-edit", ID] } }
  //   ]);
  //   this.dialogRef.close();
  // }

  viewCustomerLogs(ID) {
    const ref = RootAppComponent.dialog.open(ActionLogListComponent, {
      data: {
        targetID: ID,
      },
      width: '900px',
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  notFoundEvent(e) {
    if (e == null) {
      this.displayMapFlag = false;
    } else {
      this.displayMapFlag = !e;
    }
  }

  linkToInvitation(p) {
    if (p && p['GroupCode']) {
      const payload = {
        productGroupCode: p['GroupCode'],
      };
      this.customerProspectService.linkCustomerToInv(this.customer['ID'], payload).subscribe((res) => {
        if (res.success) {
          this.router.navigate(['/merchant', { outlets: { page: ['invitation-create', res.data] } }]);
        }
      });
    }
  }

  createNote(patientID: string) {
    this.dialog.open<NotesModal, NotesModalConfig>(NotesModal, {
      data: {
        patientID,
        parentRoute: 'merchant',
        noteListMode: 'create',
        relationshipLink: {
          label: 'patient',
          route: `customer-profile/${patientID}/patient`,
          itemID: patientID,
        },
      },
    });
  }

  viewNotes(patientID: string) {
    this.dialog.open<NotesModal, NotesModalConfig>(NotesModal, {
      data: {
        parentRoute: 'merchant',
        patientID,
      },
    });
  }

  archivePatient() {
    const confirmDialog = new ConfirmDialog(
      'delete',
      'Archive Patient?',
      '<p><strong>Are you sure?</strong></p><p class="noFurther">He/She will not recieve any further notifications.</p>',
      'No',
      'Yes, archive'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        const payload = {
          firstName: this.customer.FristName,
          lastName: this.customer.Name,
          status: 'Archived',
        };

        this.customerService.editCustomer(this.customer['ID'], payload).subscribe((res) => {
          if (res) {
            NotifyAppComponent.displayToast(
              'success',
              'Successful operation',
              'The patient has been successfully archived'
            );

            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }

  patientInvitation() {
    const ref = RootAppComponent.dialog.open(InvitationExistingPatientListComponent, {
      data: this.customer['ExistingPerson_key'],
      width: '80%',
      panelClass: 'noCard',
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  getProfilePicture() {
    this.profileLink = null;

    this.profileLink = this.customerService.getProfilePicStreamLink(this.customerID);
  }
}
