/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./data-table-control-container.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./data-table-control-container.component";
var styles_DataTableControlContainerComponent = [i0.styles];
var RenderType_DataTableControlContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DataTableControlContainerComponent, data: {} });
export { RenderType_DataTableControlContainerComponent as RenderType_DataTableControlContainerComponent };
export function View_DataTableControlContainerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "row clearfix searchArea primary-gradient-img"], ["style", "margin-top: 20px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "row clearfix flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "full-width clearfix flex animated fadeInDown"], ["style", "min-height: 64px"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_DataTableControlContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-data-table-control-container", [], null, null, null, View_DataTableControlContainerComponent_0, RenderType_DataTableControlContainerComponent)), i1.ɵdid(1, 114688, null, 0, i2.DataTableControlContainerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DataTableControlContainerComponentNgFactory = i1.ɵccf("ipv-data-table-control-container", i2.DataTableControlContainerComponent, View_DataTableControlContainerComponent_Host_0, {}, {}, ["*"]);
export { DataTableControlContainerComponentNgFactory as DataTableControlContainerComponentNgFactory };
