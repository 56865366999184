import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { MerchantAccessCreateComponent } from '../merchant-access-create/merchant-access-create.component';
import { MerchantService } from '../shared/merchant.service';

@Component({
  selector: 'app-merchant-map-modal',
  templateUrl: './merchant-map-modal.component.html',
  styleUrls: ['./merchant-map-modal.component.css'],
  animations: [
    trigger('onChangeAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([style({ opacity: 0, offset: 0, height: 0 }), style({ opacity: 1, offset: 1.0, height: '*' })])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MerchantMapModalComponent implements OnInit {
  @Input() merchantID;
  merchant;
  displayMapFlag = true;

  @Input()
  invitationID;

  @Input()
  addressLine = null;

  @Input('canClose')
  canClose = false;

  @Input()
  positions;

  @Input()
  postCode;

  @Input()
  unitNumber;

  @Input()
  suburb;

  @Input()
  state;

  @Input()
  streetName;

  @Input()
  streetNumber;

  @Input()
  streetType;

  @Input()
  zoom = 12;

  address = '';

  @Input()
  icon = 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/marker.png';

  @Input()
  country = 'AU';

  @Input()
  moreInfo = null;

  @Input()
  addressLabel;

  @Input()
  latitude;

  @Input()
  longitude;

  @Output()
  close = new EventEmitter();

  @Output()
  loading = false;
  profilePic;
  subjectArray = [];
  currentUser;
  isPromoterOrAdmin = false;
  isModal = false;
  sessionType = 'guest';

  constructor(
    private merchantService: MerchantService,
    private authenticationService: AuthenticationService,
    private customPhone: CustomPhonePipe,
    private activeRoute: ActivatedRoute,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.isModal = true;

      if (data.merchantID) {
        this.merchantID = data.merchantID;
      }

      if (data.positions) {
        this.positions = data.positions;
      }

      if (data.postCode) {
        this.postCode = data.postCode;
        this.canClose = true;
      }
      if (data.unitNumber) {
        this.unitNumber = data.unitNumber;
      }
      this.canClose = true;

      if (data.icon) {
        this.icon = data.icon;
      }

      if (data.suburb) {
        this.suburb = data.suburb;
        this.canClose = true;
      }
      if (data.state) {
        this.state = data.state;
        this.canClose = true;
      }
      if (data.streetName) {
        this.streetName = data.streetName;
        this.canClose = true;
      }
      if (data.streetNumber) {
        this.streetNumber = data.streetNumber;
        this.canClose = true;
      }
      if (data.streetType) {
        this.streetType = data.streetType;
        this.canClose = true;
      }
      if (data.country) {
        this.country = data.country;

        if (data.zoom) {
          this.zoom = data.zoom;
        }

        if (data.moreInfo) {
          this.moreInfo = data.moreInfo;
        }

        if (data.addressLabel) {
          this.addressLabel = data.addressLabel;
        }

        if (data.latitude) {
          this.latitude = data.latitude;
        }

        if (data.longitude) {
          this.longitude = data.longitude;
        }
      }
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['consumerID']) {
        this.invitationID = params['consumerID'];
      }
      this.authenticationService.getSessionType().subscribe((sessionType) => {
        if (sessionType) {
          this.sessionType = sessionType;
        }

        this.authenticationService.getCurrentUser().subscribe((res) => {
          if (res) {
            this.currentUser = res.data;
          }
        });

        this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
          this.isPromoterOrAdmin = res;
        });

        if (this.merchantID) {
          this.merchantService.getMerchantDetails(this.merchantID, {}, this.sessionType).subscribe((res1) => {
            this.merchant = res1;
            this.loading = true;

            this.merchantService.getPracticePic(this.merchantID).subscribe((res) => {
              this.loading = false;
              if (res) {
                this.profilePic = res;
              }
            });
          });
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.merchantService.getMerchantDetails(this.merchantID, {}, this.sessionType).subscribe((res1) => {
      this.merchant = res1;
      this.loading = true;

      this.merchantService.getPracticePic(this.merchantID).subscribe((res) => {
        this.loading = false;
        if (res) {
          this.profilePic = res;
        }
      });
    });
  }

  notFoundEvent(e) {
    if (e == null) {
      this.displayMapFlag = false;
    } else {
      this.displayMapFlag = !e;
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  merchantAccess(ID) {
    const ref = RootAppComponent.dialog.open(MerchantAccessCreateComponent, {
      data: ID,
      width: '800px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }
}
