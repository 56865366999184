<div class="row clearfix">
  <h3 class="sm-h3 sr-title rm-mt pull-left rm-mb">Claim Health Fund</h3>
  <img class="hicaps-logo pull-right"
       src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/brandLogos/logo-hicaps-new.png"/>
</div>
<hr/>
<mat-dialog-content>
  <div class="row clearfix">
    <p class="larger rm-mt">Register claim paid directly to your {{ "KEYWORD.practice" | translate }}</p>
  </div>
  <div class="row clearfix">
    <mat-form-field class="full-width adjustSelect" appearance="outline">
      <mat-label>Select fund</mat-label>
      <mat-select class="full-width adjustSelect" [(ngModel)]="selectedFund.name" name="selectFund"
                  placeholder="Select fund" required>
        <mat-option *ngFor="let f of fundOptions" [value]="f">
          {{ f }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width anim" appearance="outline">
      <mat-label>Amount - Between 0 and {{ maxAmount }}</mat-label>
      <span class="inputPrefix" matPrefix>$ &nbsp;</span>
      <input [(ngModel)]="selectedFund.amount" [formControl]="amountInput" type="number" matInput name="offsetAmount"
             placeholder="Amount - Between 0 and {{ maxAmount }}" required/>
      <mat-error>Please enter a value between 0 and {{ maxAmount }}</mat-error>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Reference</mat-label>
      <input [(ngModel)]="selectedFund.reference" matInput name="reference" placeholder="Reference" required/>
    </mat-form-field>
  </div>
  <div class="row clearfix">
    <h4 class="sr-title rm-mt rm-mb sm-h4">What happens next?</h4>
    <p class="small rm-m">Once approved, the following will occur:</p>
    <ul>
      <li>
        <p class="small rm-m">
          The amount you can claim from {{ "BRANDING.Brand Name" | translate | titlecase }} will reduce by
          <span *ngIf="!selectedFund.amount">the amount you set</span><span *ngIf="selectedFund.amount"
                                                                            [ngStyle]="{ color: '#1ca90c' }">{{
          selectedFund.amount | customCurrency
          }}</span>.
        </p>
      </li>
      <li>
        <p class="small rm-m">
          We will apply
          <span *ngIf="!selectedFund.amount">the amount</span>
          <span *ngIf="selectedFund.amount" [ngStyle]="{ color: '#1ca90c' }">{{
            selectedFund.amount | customCurrency
            }}</span>
          to the end of the {{ "KEYWORD.patient" | translate}}'s loan, thus reducing the amount the {{ "KEYWORD.patient"
          }} will
          repay.
        </p>
      </li>
    </ul>
  </div>
  <!-- <div class="row clearfix">
    <p class="small"><strong>Disclaimer:</strong> To proceed with requesting a claim from a health fund, you must agree
      to the
      <a>terms &amp; conditions</a> and declare that you have read and understood these by checking the box below.</p>
    <div class="clearfix flex well">
      <mat-slide-toggle class="mt mb mr" [(ngModel)]="selectedFund.isAccepted"></mat-slide-toggle>
      <p>I/We confirm that we have claimed the amount stated above from the health fund and we want to apply a credit
        to the {{ "KEYWORD.patient" | translate }}'s account. I/We understand that once this is approved by   {{"BRANDING.Brand Name" | translate}} this is non-reversible.</p>
    </div>

  </div> -->
</mat-dialog-content>
<hr/>
<mat-dialog-actions class="justify-right">
  <button (click)="close()" mat-raised-button color="primary">Cancel</button>
  <button [disabled]="
      selectedFund.name == null || selectedFund.amount == null || amountInput.invalid || selectedFund.reference == null
    " (click)="sendFundAmountEvent()" mat-raised-button color="accent">
    Confirm
  </button>
</mat-dialog-actions>
