/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-prospect-details.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../customer-details-card-edit/customer-details-card-edit.component.ngfactory";
import * as i3 from "../customer-details-card-edit/customer-details-card-edit.component";
import * as i4 from "@angular/common";
import * as i5 from "../../customer/shared/customer.service";
import * as i6 from "@angular/router";
import * as i7 from "../../../core/authentication/shared/authentication.service";
import * as i8 from "@angular/material/dialog";
import * as i9 from "../../../shared/services/lookup.service";
import * as i10 from "@angular/material/snack-bar";
import * as i11 from "../prospect-details-card-edit/prospect-details-card-edit.component.ngfactory";
import * as i12 from "../prospect-details-card-edit/prospect-details-card-edit.component";
import * as i13 from "../../existing-patient/shared/existing-patient.service";
import * as i14 from "./customer-prospect-details.component";
import * as i15 from "../../customer-prospect/shared/customerProspect.service";
var styles_CustomerProspectDetailsComponent = [i0.styles];
var RenderType_CustomerProspectDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerProspectDetailsComponent, data: {} });
export { RenderType_CustomerProspectDetailsComponent as RenderType_CustomerProspectDetailsComponent };
function View_CustomerProspectDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "app-customer-details-card-edit", [["class", ""]], null, [["window", "beforeunload"]], function (_v, en, $event) { var ad = true; if (("window:beforeunload" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).unloadNotification($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CustomerDetailsCardEditComponent_0, i2.RenderType_CustomerDetailsCardEditComponent)), i1.ɵdid(1, 114688, null, 0, i3.CustomerDetailsCardEditComponent, [i4.Location, i5.CustomerService, i6.ActivatedRoute, i7.AuthenticationService, i8.MatDialog, i9.LookupService, i10.MatSnackBar, i1.ViewContainerRef, [2, i8.MAT_DIALOG_DATA]], { customerID: [0, "customerID"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.patient["ID"]; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CustomerProspectDetailsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "app-prospect-details-card-edit", [["class", ""]], null, null, null, i11.View_ProspectDetailsCardEditComponent_0, i11.RenderType_ProspectDetailsCardEditComponent)), i1.ɵdid(1, 114688, null, 0, i12.ProspectDetailsCardEditComponent, [i4.Location, i13.ExistingPatientService, i6.ActivatedRoute, i6.Router, i7.AuthenticationService, i8.MatDialog, i9.LookupService, i10.MatSnackBar, i1.ViewContainerRef, [2, i8.MAT_DIALOG_DATA]], { prospectID: [0, "prospectID"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.patient["ID"]; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CustomerProspectDetailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerProspectDetailsComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerProspectDetailsComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.patient && _co.patient["ID"]) && (_co.patient["TableName"] == "Customer")); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.patient && _co.patient["ID"]) && (_co.patient["TableName"] == "Prospect")); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_CustomerProspectDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-prospect-details", [], null, null, null, View_CustomerProspectDetailsComponent_0, RenderType_CustomerProspectDetailsComponent)), i1.ɵdid(1, 114688, null, 0, i14.CustomerProspectDetailsComponent, [i7.AuthenticationService, i15.CustomerProspectService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerProspectDetailsComponentNgFactory = i1.ɵccf("app-customer-prospect-details", i14.CustomerProspectDetailsComponent, View_CustomerProspectDetailsComponent_Host_0, { patientID: "patientID" }, {}, []);
export { CustomerProspectDetailsComponentNgFactory as CustomerProspectDetailsComponentNgFactory };
