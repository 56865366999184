import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { ProductCalculatorComponent } from '../product-calculator/product-calculator.component';
import { ProductViewModalComponent } from '../product-view-modal/product-view-modal.component';
import { ProductService } from '../shared/product.service';

@Component({
  selector: 'app-suitable-dynamic-product-list',
  templateUrl: './suitable-product-list.component.html',
  styleUrls: ['./suitable-product-list.component.css'],
})
export class SuitableProductListComponent implements OnInit {
  @Input() amount = 0;
  @Input() merchantKey = null;

  @Input() groupCode;
  index = 0;
  options: any = [];
  option: any = {};

  constructor(private productService: ProductService) {}

  ngOnInit() {
    this.refresh();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.refresh();
  }

  refresh() {
    const payload = {
      amount: this.amount,
      merchantKey: this.merchantKey,
      groupCode: this.groupCode || null,
      orderBy: '',
    };

    UtilsClass.startLoadingForce();
    this.productService.getSuitableProduct(payload).subscribe((res) => {
      UtilsClass.stopLoadingForce();

      this.options = res;
      this.option = res[0];
    });
  }

  getNextOption() {
    this.index++;
    this.option = this.options[this.index];
  }

  getPreviousOption() {
    this.index--;
    this.option = this.options[this.index];
  }

  openProductDetails() {
    const product = { ID: this.option.ID };
    const ref = RootAppComponent.dialog.open(ProductViewModalComponent, {
      width: '750px',
      panelClass: 'noCard',
      data: product,
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  simulateProduct() {
    const amount = this.amount;

    const ref = RootAppComponent.dialog.open(ProductCalculatorComponent, {
      data: {
        ProductID: this.option.ID,
        dynamicAmount: false,
        isPatientContribution: false,
        amount,
      },
      panelClass: 'noCard',
      width: '850px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }
}
