<div class="card-header clearfix stacked">
  <div class="flex clearfix card-sub-header">
    <h1 class="dataHeader">
      Logs:
      <span class="badge">{{ dataSource.filteredData.length }}</span>
    </h1>
    <div class="row clearfix search-log-container">
      <div class="full-width flex">
        <form #searchLog="ngForm">
          <div class="qtr-width">
            <div class="column">
              <mat-form-field class="full-width margin-tp">
                <mat-label>Log Size </mat-label>
                <input
                  [(ngModel)]="maxLogNumber"
                  [minlength]="500"
                  name="MobilephonePrefix"
                  type="number"
                  matInput
                  placeholder="'Log Size'"
                  required
                />
              </mat-form-field>
            </div>
          </div>

          <div class="qtr-width">
            <div class="column">
              <app-date-input
                class="full-width margin-btm-large"
                [label]="'Log From'"
                [maxDate]="dateTo"
                [isClear]="true"
                (selectedDate)="selectDateFromFilter($event)"
                name="fromDate"
              ></app-date-input>
            </div>
          </div>
          <div class="qtr-width">
            <div class="column">
              <app-date-input
                class="full-width margin-btm-large"
                [label]="'Log To'"
                [isClear]="true"
                (selectedDate)="selectDateToFilter($event)"
                name="toDate"
              ></app-date-input>
            </div>
          </div>
          <div class="clearfix qtr-width pull-right text-center">
            <button
              class="search-button"
              [disabled]="!searchLog.form.valid"
              (click)="filterLogs()"
              mat-raised-button
              color="accent"
            >
              Search
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="row clearfix mb searchArea primary-gradient-img">
  <div class="filterCardless clearfix flex">
    <app-date-input
      class="log-date margin-btm"
      [label]="'Log Date'"
      [isClear]="true"
      (selectedDate)="setFilter($event, 'date')"
      name="fromDate"
    ></app-date-input>
    <mat-form-field class="full-width mat-select-adjust" appearance="outline">
      <mat-label>Module</mat-label>
      <mat-select
        class="full-width"
        (selectionChange)="setFilter($event.value, 'baseUrl')"
        name="module"
        placeholder=""
      >
        <mat-option [value]="null">
          All
        </mat-option>
        <mat-option
          *ngFor="let a of listDB.records | objectPropertyValue: 'baseUrl' | unique | without: ['', null] | orderBy"
          [value]="a"
        >
          {{ a | ltrim: '/api' }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width mat-select-adjust" appearance="outline">
      <mat-label>Method</mat-label>
      <mat-select
        class="margin-btm"
        (selectionChange)="setFilter($event.value, 'url')"
        name="Method"
        placeholder="Method"
      >
        <mat-option [value]="null"> All </mat-option>
        <mat-option
          *ngFor="let a of listDB.records | objectPropertyValue: 'url' | unique | without: ['', null] | orderBy"
          [value]="a"
        >
          {{ a | ltrim: '/api' }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width mat-select-adjust" appearance="outline">
      <mat-label>Response Status</mat-label>
      <mat-select
        class="margin-btm"
        (selectionChange)="setFilter($event.value, 'responseStatus')"
        name="Status"
        placeholder="Response status"
      >
        <mat-option [value]="null"> All </mat-option>
        <mat-option
          *ngFor="let status of listDB.records | objectPropertyValue: 'status' | unique | without: ['', null] | orderBy"
          [value]="status"
        >
          <span class="status-box-info" *ngIf="status == 200">{{ status }} </span>
          <span class="status-box-sucess" *ngIf="status == 400">{{ status }} </span>
          <span class="status-box-error" *ngIf="status != 200 && status != 400">{{ status }} </span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="full-width mat-select-adjust" appearance="outline">
      <mat-label>Role</mat-label>
      <mat-select
        class="margin-btm"
        (selectionChange)="setFilter($event.value, 'user.session-type')"
        name="module"
        placeholder="Role"
      >
        <mat-option [value]="null"> All </mat-option>
        <mat-option
          *ngFor="
            let a of listDB.records | objectPropertyValue: 'user.session-type' | unique | without: ['', null] | orderBy
          "
          [value]="a"
        >
          {{ a }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="full-width mat-select-adjust" appearance="outline">
      <mat-label>Status</mat-label>
      <mat-select
        class="margin-btm"
        (selectionChange)="setFilter($event.value, 'status')"
        name="Status"
        placeholder="Status"
      >
        <mat-option [value]="null"> All </mat-option>
        <mat-option
          *ngFor="
            let a of listDB.records
              | objectPropertyValue: 'response.Error.code'
              | unique
              | without: ['', null]
              | orderBy
          "
          [value]="!a ? 200 : a"
        >
          <span class="status-label-sucess" *ngIf="!a">Success</span>
          <span class="status-label-error" *ngIf="a">{{ utils.errorLabel(a) }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="full-width mat-select-adjust" appearance="outline">
      <mat-label>User</mat-label>
      <mat-select
        class="margin-btm"
        (selectionChange)="setFilter($event.value, 'user.name')"
        name="User"
        placeholder="User"
      >
        <mat-option [value]="null"> All </mat-option>
        <mat-option
          *ngFor="let a of listDB.records | objectPropertyValue: 'user.name' | unique | without: ['', null] | orderBy"
          [value]="a"
        >
          {{ a }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="table-responsive">
  <mat-table class="list-table" id="list-table " #table [dataSource]="dataSource" matSort>
    <ng-container cdkColumnDef="geolocation">
      <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <button
          class="btn-clear btn-view"
          *ngIf="row.lat || row.lng"
          (click)="displayMap(row)"
          mat-button
          matTooltip="Show Location"
        >
          <mat-icon>place</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="browser">
      <mat-header-cell *cdkHeaderCellDef> Browser</mat-header-cell>
      <mat-cell *cdkCellDef="let row" matTooltip="{{ row.browser }}">
        {{ row.browser }}
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="os">
      <mat-header-cell *cdkHeaderCellDef> OS</mat-header-cell>
      <mat-cell *cdkCellDef="let row" matTooltip="{{ row.os }}">
        {{ row.os }}
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="date">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Date</mat-header-cell>
      <mat-cell *cdkCellDef="let row" (click)="displayLog(row)">
        {{ row.date | customDateTime }}
      </mat-cell>
    </ng-container>
    <ng-container cdkColumnDef="module">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Module</mat-header-cell>
      <mat-cell *cdkCellDef="let row" (click)="displayLog(row)" matTooltip="{{ row['baseUrl'] | ltrim: '/api' }}">
        {{ row['baseUrl'] | ltrim: '/api' }}
      </mat-cell>
    </ng-container>
    <ng-container cdkColumnDef="method">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Method</mat-header-cell>
      <mat-cell *cdkCellDef="let row" (click)="displayLog(row)" matTooltip=" {{ row.url | ltrim: '/api' }}">
        {{ row.url | ltrim: '/api' }}
      </mat-cell>
    </ng-container>
    <ng-container cdkColumnDef="type">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Type</mat-header-cell>
      <mat-cell *cdkCellDef="let row" (click)="displayLog(row)" matTooltip=" {{ row.type }}/{{ row.method }}">
        {{ row.type }}/{{ row.method }}
      </mat-cell>
    </ng-container>
    <ng-container cdkColumnDef="responseStatus">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Response Status</mat-header-cell>
      <mat-cell *cdkCellDef="let row" (click)="displayLog(row)">
        <span class="status-label-error" *ngIf="row.status >= 300">{{ row.status }} </span>
        <span class="status-label-sucess" *ngIf="row.status == 200">{{ row.status }} </span>
      </mat-cell>
    </ng-container>
    <ng-container cdkColumnDef="status">
      <mat-header-cell *cdkHeaderCellDef> Status </mat-header-cell>
      <mat-cell *cdkCellDef="let row" (click)="displayLog(row)">
        <span class="status-box-sucess" *ngIf="!row['response'] || (row['response'] && !row['response']['Error'])"
        >Success</span
        >
        <span class="status-box-error" *ngIf="row && row['response'] && row['response']['Error']">{{
          utils.errorLabel(row['response']['Error']['code'])
        }}</span>
      </mat-cell>
    </ng-container>
    <ng-container cdkColumnDef="message">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Message</mat-header-cell>
      <mat-cell *cdkCellDef="let row" (click)="displayLog(row)" matTooltip=" {{ utils.getMessage(row) }}">
        {{ utils.getMessage(row) }}
      </mat-cell>
    </ng-container>
    <ng-container cdkColumnDef="username">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> User</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{ row['user']['name'] }}
      </mat-cell>
    </ng-container>
    <ng-container cdkColumnDef="role">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Role</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{ row['user']['session-type'] }}
      </mat-cell>
    </ng-container>
    <ng-container cdkColumnDef="Actions">
      <mat-header-cell *cdkHeaderCellDef> Actions </mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <div style="float: right">
          <button [matMenuTriggerFor]="menu" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button (click)="displayLog(row)" mat-menu-item>
              <mat-icon>search</mat-icon>
              <span>Quick View</span>
            </button>

            <button
              *ngIf="
                row['request'] &&
                row['request']['GPS-Location-r'] &&
                row['request']['GPS-Location-r'].split('/').length == 2
              "
              (click)="displayUserLocation(row['request']['GPS-Location-r'])"
              mat-menu-item
            >
              <mat-icon>folder_shared</mat-icon>
              <span>Location</span>
            </button>
          </mat-menu>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator
    #paginator
    [length]="dataSource.filteredData.length"
    [pageIndex]="0"
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 25, 100]"
  >
  </mat-paginator>
</div>
