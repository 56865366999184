/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./star-rating-create.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./star-rating-create.component";
var styles_StarRatingCreateComponent = [i0.styles];
var RenderType_StarRatingCreateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StarRatingCreateComponent, data: {} });
export { RenderType_StarRatingCreateComponent as RenderType_StarRatingCreateComponent };
function View_StarRatingCreateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["class", "starList"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setStar(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "i", [["class", "fas fa-star animated"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { unchk: 0, tada: 1 })], function (_ck, _v) { var currVal_0 = "fas fa-star animated"; var currVal_1 = _ck(_v, 4, 0, _v.context.$implicit, !_v.context.$implicit); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_StarRatingCreateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row clearfix text-center starRating"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StarRatingCreateComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "row clearfix text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", ": "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", " stars"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.starList; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.label; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.rating; _ck(_v, 7, 0, currVal_2); }); }
export function View_StarRatingCreateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-star-rating-create", [], null, null, null, View_StarRatingCreateComponent_0, RenderType_StarRatingCreateComponent)), i1.ɵdid(1, 114688, null, 0, i3.StarRatingCreateComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StarRatingCreateComponentNgFactory = i1.ɵccf("app-star-rating-create", i3.StarRatingCreateComponent, View_StarRatingCreateComponent_Host_0, { rating: "rating", label: "label" }, { setRating: "setRating" }, []);
export { StarRatingCreateComponentNgFactory as StarRatingCreateComponentNgFactory };
