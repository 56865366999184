<mat-card class="card mat-elevation-z5 rm-mt">
  <div class="colour-stripe"></div>
  <h3 class="sr-title sm-h3">Personal Information</h3>
  <div class="row clearfix mb">
    <div class="column">
      <div class="icons-col">
        <mat-icon>person</mat-icon>
      </div>
      <div class="row-content-col summary">
        <div class="row clearfix">
          <div class="half-width">
            <div class="col-left">
              <div class="block">
                <label>Title: </label>
                <p class="larger">Value</p>
              </div>
            </div>
          </div>
          <div class="half-width">
            <div class="col-right">
              <div class="block">
                <label>Gender: </label>
                <p class="larger">{{ 'PersonvaluealInformation' | translate }}</p>
              </div>
            </div>
          </div>
        </div>
        <hr/>
        <div class="row clearfix">
          <div class="full-width">
            <div class="block">
              <label>Name: </label>
              <p class="larger">Firstname Middlename SurnamePersonal Information</p>
            </div>
          </div>
        </div>
        <hr/>
        <div class="row clearfix">
          <div class="half-width">
            <div class="col-left">
              <div class="block">
                <label>Date of birth: </label>
                <p class="larger">Value</p>
              </div>
            </div>
          </div>
        </div>
        <hr/>
        <div class="row clearfix">
          <div class="half-width">
            <div class="col-right">
              <div class="block">
                <label>Relationship status: </label>
                <p class="larger">Value</p>
              </div>
            </div>
          </div>
          <div class="half-width">
            <div class="col-left">
              <div class="block">
                <label>Number of dependants: </label>
                <p class="larger">Value</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>
