<ng-container *ngIf="appState$ | async as appState">
  <form #productForm="ngForm">
    <div class="top__actions flex">
      <h2 class="sr-title mt mr" style="text-align: left; font-weight: bold">Select Customer</h2>

      <div style="max-height: 40px; display: flex; margin-top: 15px">
        <button class="btn mr" (click)="selectPatient(appState.practice.ID)" mat-raised-button color="primary">
          Select Customer
        </button>

        <button
          class="btn"
          [matTooltip]="'Add New Customer'"
          (click)="newPatient(appState.practice)"
          mat-raised-button
          color="accent"
        >
          <mat-icon class="fas fa-user-plus smr" style="font-size: 1rem; margin-top: 10px"></mat-icon>
          New Customer
        </button>
      </div>
    </div>

    <div class="mt text-left">
      <h3>Name</h3>
      <p>
        <span *ngIf="selectedPatient">
          {{ selectedPatient['CalculatedName'] }}
        </span>
        <span *ngIf="!selectedPatient"> - </span>
      </p>
    </div>

    <div class="mt text-left">
      <h3>Email</h3>
      <p>
        <span *ngIf="selectedPatient">
          {{ selectedPatient['emails.Email'] }}
        </span>
        <span *ngIf="!selectedPatient"> - </span>
      </p>
    </div>

    <div class="mt text-left">
      <h3>Mobile</h3>
      <p>
        <span *ngIf="selectedPatient">
          {{ selectedPatient['mobiles.Number'] | customPhone }}
        </span>
        <span *ngIf="!selectedPatient"> - </span>
      </p>
    </div>

    <h2 class="sr-title mt" style="text-align: left; font-weight: bold">Select Product</h2>

    <h2 class="sr-title mt" style="text-align: left; font-weight: bold">Details</h2>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Invoice Number</mat-label>
      <input
        [(ngModel)]="productInvoice.Invoice_Number"
        matInput
        name="invoiceNumber"
        placeholder="Invoice Number"
        required
      />
    </mat-form-field>

    <mat-form-field class="full-width mr" appearance="outline">
      <mat-label>Amount Borrowed</mat-label>
      <input
        [(ngModel)]="productInvoice.Amount_Borrowed"
        [options]="{ align: 'left' }"
        currencyMask
        matInput
        name="amountBorrowed"
        placeholder="Amount Borrowed"
      />
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Notes</mat-label>
      <textarea
        class="textarea-control-smaller"
        [(ngModel)]="productInvoice.Notes"
        matInput
        placeholder="notes"
        name="notes"
      ></textarea>
    </mat-form-field>
  </form>

  <div class="row clearfix text-center button-row">
    <button class="" (click)="close()" mat-raised-button color="primary">Close</button>
    <button
      class="ml"
      *ngIf="formMode === 'create'"
      [disabled]="!productForm.form.valid"
      (click)="create()"
      mat-raised-button
      color="accent"
    >
      Save
      <mat-icon>check</mat-icon>
    </button>

    <button
      class="ml"
      *ngIf="formMode === 'update'"
      [disabled]="!productForm.form.valid"
      (click)="update()"
      mat-raised-button
      color="accent"
    >
      Save
      <mat-icon>check</mat-icon>
    </button>
  </div>
</ng-container>
