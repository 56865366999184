import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ContractService } from '../shared/contract.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { SmsConversationComponent } from '../../message/sms-conversation/sms-conversation.component';
import { NotesModal, NotesModalConfig } from '../../notes/modals/notes-modal/notes.modal';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { DentistViewComponent } from '../../dentist/dentist-view/dentist-view.component';
import { CustomerProspectViewModelComponent } from '../../customer-prospect/customer-prospect-view-model/customer-prospect-view-model.component';
import { environment } from 'environments/environment';
import { Settings } from '../../../shared/types/settings';
import { WakandaFilesComponent } from '../../invitation/wakanda-files-view/wakanda-files-view.component';
import { MessageTimelineComponent, MessageType } from '../../message/message-timeline/message-timeline.component';
import { ProductViewModalComponent } from '../../product/product-view-modal/product-view-modal.component';
import { EventLogListComponent } from '../../action-log/event-log-list/event-log-list.component';
import { InvitationViewModalComponent } from '../../invitation/invitation-view-modal/invitation-view-modal.component';
import { TextMessageCreateComponent } from '../../message/text-message-create/text-message-create.component';
import { SmsPromoterComponent } from '../../message/sms-promoter/sms-promoter.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

import * as _ from 'lodash';
import { BlobViewComponent } from '../../../shared/components/blob-view/blob-view.component';
import { InnerHtmlIsolatedModalComponent } from '../../../shared/components/inner-html-isolated-modal/inner-html-isolated-modal.component';
import { EPDocumentCreateEditComponent } from '../../../shared/components/ep-document-create-edit/ep-document-create-edit.component';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { ClipboardService } from 'ngx-clipboard';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { ConfirmDialogSingleButtonComponent } from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { QuotationDecisionModalComponent } from '../../quotation/quotation-decision-modal/quotation-decision-modal.component';
import { ConfirmDialogMessageComponent } from '../../../shared/components/confirm-dialog-message/confirm-dialog-message.component';

@Component({
  selector: 'app-contract-overview-view',
  templateUrl: './contract-overview-view.component.html',
  styleUrls: ['./contract-overview-view.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class ContractOverviewViewComponent implements OnInit {
  customer;
  contract;
  invitation;
  contractID;
  isModulePatientSMSActive = Settings.global['isModulePatientSMSActive'];

  capacityCheck;
  paymentMethod;
  creditCheck;
  idDocument;
  IDCheck;

  sessionType;
  isPromoterOrAdmin = false;

  _documents = [];

  selectedTab = 'overview';
  AllowedStatusCodes = [];
  documentTypes = [
    { Label: 'Card - Images', TypeCode: 'CrdImage' },
    { Label: 'Customer Statement', TypeCode: 'CSTA' },
    { Label: 'Customer Statement', TypeCode: 'CSTA' },
    { Label: 'DDR', TypeCode: 'DDA' },
    { Label: 'Document Gallery Video', TypeCode: 'DGVIDEO' },
    { Label: 'Drivers License', TypeCode: 'IDL' },
    { Label: 'Executed Loan Contract', TypeCode: 'CLN' },
    { Label: 'Executed Payment Plan Contract', TypeCode: 'CPP' },
    { Label: 'ID Card', TypeCode: 'IDC' },
    { Label: 'Image Gallery Video', TypeCode: 'IGVIDEO' },
    { Label: 'Images', TypeCode: 'APT' },
    { Label: 'Invoices', TypeCode: 'INV' },
    { Label: 'MW-CC-BatchFile', TypeCode: 'MWCC_S' },
    { Label: 'Medical Record - Correspondenc', TypeCode: 'MEDR_C' },
    { Label: 'Medical Record - Images', TypeCode: 'MEDR_I' },
    { Label: 'Medical Record - Referrals', TypeCode: 'MEDR_R' },
    { Label: 'Medical Record - Treatment Pla', TypeCode: 'MEDR_T' },
    { Label: 'Medical Record - Videos', TypeCode: 'MEDR_V' },
    { Label: 'Medical Record - Xrays', TypeCode: 'MEDR_X' },
    { Label: 'Medicare Card', TypeCode: 'IDM' },
    { Label: 'Merchant - Settlement', TypeCode: 'MSttlmt' },
    { Label: 'Merchant Note - Document', TypeCode: 'MERN_D' },
    { Label: 'Merchant Note - Image', TypeCode: 'MERN_I' },
    { Label: 'Merchant Note - PDF', TypeCode: 'MERN_P' },
    { Label: 'Merchant Note - Spreadsheet', TypeCode: 'MERN_S' },
    { Label: 'Merchant Note - Videos', TypeCode: 'MERN_V' },
    { Label: 'Passport', TypeCode: 'IDP' },
    { Label: 'Patient Acknowledgement', TypeCode: 'CUSACK' },
    { Label: 'Personal Circumstances', TypeCode: 'PRCCRC' },
    { Label: 'Personal Circumstances', TypeCode: 'PRCCRC' },
    { Label: 'Proceedure Gallery', TypeCode: 'SRVSTC' },
    { Label: 'Promoter Note - Document', TypeCode: 'PRMN_D' },
    { Label: 'Promoter Note - Image', TypeCode: 'PRMN_I' },
    { Label: 'Promoter Note - PDF', TypeCode: 'PRMN_P' },
    { Label: 'Promoter Note - Spreadsheet', TypeCode: 'PRMN_S' },
    { Label: 'Promoter Note - Videos', TypeCode: 'PRMN_V' },
    { Label: 'Proof Of Employment', TypeCode: 'POE' },
    { Label: 'Proof Of Identity', TypeCode: 'PID' },
    { Label: 'Proof of Residential Address', TypeCode: 'PRA' },
    { Label: 'Rescheduling Audit', TypeCode: 'RschAudit' },
    { Label: 'Settlement Request', TypeCode: 'STLAUT' },
    { Label: 'Signed Informed Consent', TypeCode: 'CUS_SIC' },
    { Label: 'Treatment Info', TypeCode: 'SRV' },
    { Label: 'Treatment Plan', TypeCode: 'TRP' },
    { Label: 'Video', TypeCode: 'VIDEO' },
  ];

  tabs = [
    {
      code: 'overview',
      label: 'Overview',
      icon: 'fas fa-file-contract',
    },
    {
      code: 'settlement',
      label: 'Settlement',
      icon: 'fas fa-piggy-bank',
    },
    {
      code: 'capacity_check',
      label: 'Capacity Check ',
      icon: 'fas fa-check-double',
    },
    {
      code: 'credit_check',
      label: 'Credit Check',
      icon: 'fas fa-credit-card',
    },
    {
      code: 'ID_check',
      label: 'ID Check',
      icon: 'fas fa-id-card',
    },
    {
      code: 'notes',
      label: 'Notes',
      icon: 'fas fa-sticky-note',
    },
    {
      code: 'messages',
      label: 'Messages',
      icon: 'fas fa-comments',
    },
    {
      code: 'supporting_documents',
      label: 'Supporting Documents',
      icon: 'fas fa-folder-open',
    },
    {
      code: 'payment_methods',
      label: 'Payment Methods',
      icon: 'fas fa-credit-card',
    },
    {
      code: 'audit_history',
      label: 'Audit History',
      icon: 'fas fa-clipboard-check',
    },
  ];

  vedaHTML;
  product;
  customerID;
  isLoad = false;

  possibleStatus = [];

  vedaLoad = false;
  isCapacityCheckLoaded = false;
  isCreditCheckLoaded = false;
  isIDCheckLoaded = false;
  isNoteLoaded = false;
  isMessageLoaded = false;
  isPaymentMethodLoaded = false;
  isSettlementLoaded = false;
  isAuditHistoryLoaded = false;

  constructor(
    private utilsService: UtilsService,
    private authenticationService: AuthenticationService,

    private router: Router,
    private activeRoute: ActivatedRoute,
    private contractService: ContractService,
    private dialog: MatDialog,
    private _clipboardService: ClipboardService
  ) {}

  ngOnInit() {
    this.utilsService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModulePatientSMSActive = access['isModulePatientSMSActive'];
      }
    });

    this.activeRoute.params.subscribe((params) => {
      if (params['contractID']) {
        this.contractID = params['contractID'];
      }
      if (params['tab']) {
        this.selectedTab = params['tab'];
      }

      this.authenticationService.getSessionType().subscribe((s) => {
        this.sessionType = s;
        this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
          this.isPromoterOrAdmin = isPromoterOrAdmin;

          this.setup(true);
        });
      });
    });
  }

  setup(firstLoad = false) {
    if (this.contractID) {
      this.isLoad = false;
      this.vedaHTML;
      this.vedaLoad = false;
      this.isCapacityCheckLoaded = false;
      this.isCreditCheckLoaded = false;
      this.isIDCheckLoaded = false;
      this.isNoteLoaded = false;
      this.isMessageLoaded = false;
      this.isPaymentMethodLoaded = false;
      this.isSettlementLoaded = false;
      this.isAuditHistoryLoaded = false;
      this.customer = null;

      this.invitation = null;

      this.capacityCheck = null;
      this.paymentMethod = null;
      this.idDocument = null;
      this.creditCheck = null;
      this.IDCheck = null;
      this.product = null;
      this.contract = null;

      this.contractService
        .getContractDetails(
          this.contractID,
          {
            selectProduct: true,
            selectDocument: true,
            selectCustomer: true,
            selectInvitation: true,
            selectAllowedStatus: true,
            selectStats: true,
            selectIDDocument: true,
            selectCapacityCheck: true,
            selectCreditCheck: true,
            selectIDCheck: true,
            selectPaymentMethod: true,
          },
          this.isPromoterOrAdmin
        )
        .subscribe((r) => {
          if (r && r.ID) {
            if (r.customer && r.customer.ID) {
              this.customer = r.customer;
              this.customerID = this.customer.ID;
            }

            if (r.invitation && r.invitation.ID) {
              this.invitation = r.invitation;
            }
            if (r.AllowedStatusCodes && r.AllowedStatusCodes.length > 0) {
              this.AllowedStatusCodes = JSON.parse(JSON.stringify(r.AllowedStatusCodes));
            } else {
              this.AllowedStatusCodes = [];
            }

            if (r.capacityCheck && r.capacityCheck.ID) {
              this.capacityCheck = r.capacityCheck;
            }
            if (r.paymentMethod && r.paymentMethod.ID) {
              this.paymentMethod = r.paymentMethod;
            }

            if (r.idDocument && r.idDocument.ID) {
              this.idDocument = r.idDocument;
            }
            if (r.creditCheck && r.creditCheck.ID) {
              this.creditCheck = r.creditCheck;
            }
            if (r.IDCheck && r.IDCheck.ID) {
              this.IDCheck = r.IDCheck;
            }

            if (r.product && r.product.ID) {
              this.product = r.product;
            }

            this.contract = r;

            if (firstLoad == true) {
              this.tabAction();
            }
            this.buildDocuments();

            this.buildTabs();
          }

          this.isLoad = true;
        });
    } else {
      this.isLoad = true;
    }
  }
  getCardType(d) {
    if (d === 'MC') {
      return 'MC';
    } else if (d === 'AX') {
      return 'AX';
    } else if (d === 'VS') {
      return 'VS';
    } else if (d === 'VIS') {
      return 'VS';
    } else if (d === 'VISA') {
      return 'VS';
    }

    return 'CC';
  }

  info(title, s) {
    if (s) {
      const confirmDialog2 = new ConfirmDialogSingleButton('fas fa-info', title, '<p>' + s + '</p>', 'OKAY');
      const ref2 = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
        data: confirmDialog2,
        width: '450px',
      });
      ref2.componentInstance.onConfirm.subscribe((res) => {
        ref2.close();
      });
    }
  }

  capacityCheckPerform() {
    if (this.contract && this.contract.ID) {
      const confirm = new ConfirmDialog(
        'fas fa-check-double',
        '',
        'Are you sure you want to perform Capacity Check for this customer?',
        'No',
        'Yes'
      );
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.contractService.performCheck(this.contract.ID, {}, 'Capacity').subscribe((r) => {
            if (r && r.ID) {
              this.capacityCheck = r;

              NotifyAppComponent.displayToast('success', 'Success!', 'Capacity Check is performed');
              ref.close();
            }
          });
        }
      });
    }
  }

  refresh() {
    if (this.contract && this.contract.ID) {
      const confirm = new ConfirmDialog(
        'fas fa-sync-alt',
        '',
        'Are you sure you want to refresh this page?',
        'No',
        'Yes'
      );
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.selectedTab = 'overview';
          this.setup(true);

          ref.close();
        }
      });
    }
  }

  creditCheckPerform() {
    if (this.contract && this.contract.ID) {
      const confirm = new ConfirmDialog(
        'fas fa-credit-card',
        '',
        'Are you sure you want to perform Credit Check for this customer?',
        'No',
        'Yes'
      );
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.contractService.performCheck(this.contract.ID, {}, 'Credit').subscribe((r) => {
            if (r && r.ID) {
              this.creditCheck = r;

              NotifyAppComponent.displayToast('success', 'Success!', 'Credit Check is performed');
              ref.close();
            }
          });
        }
      });
    }
  }

  IDCheckPerform() {
    if (this.contract && this.contract.ID) {
      const confirm = new ConfirmDialog(
        'fas fa-id-card',
        '',
        'Are you sure you want to perform Identity Check for this customer?',
        'No',
        'Yes'
      );
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.contractService.performCheck(this.contract.ID, {}, 'Identity').subscribe((r) => {
            if (r && r.ID) {
              this.IDCheck = r;

              NotifyAppComponent.displayToast('success', 'Success!', 'ID Check is performed');
              ref.close();
            }
          });
        }
      });
    }
  }

  buildTabs() {
    if (this.contract && this.contract['ContractDate'] === '0000-00-00') {
      this.tabs = [
        {
          code: 'overview',
          label: 'Overview',
          icon: 'fas fa-file-contract',
        },
        {
          code: 'settlement',
          label: 'Settlement',
          icon: 'fas fa-piggy-bank',
        },
        // {
        //   code: 'capacity_check',
        //   label: 'Capacity Check ',
        //   icon: 'fas fa-check-double',
        // },
        {
          code: 'credit_check',
          label: 'Credit Check',
          icon: 'fas fa-credit-card',
        },
        // {
        //   code: 'ID_check',
        //   label: 'ID Check',
        //   icon: 'fas fa-id-card',
        // },
        {
          code: 'notes',
          label: 'Notes',
          icon: 'fas fa-sticky-note',
        },
        {
          code: 'messages',
          label: 'Messages',
          icon: 'fas fa-comments',
        },
        {
          code: 'supporting_documents',
          label: 'Supporting Documents',
          icon: 'fas fa-folder-open',
        },
        {
          code: 'payment_methods',
          label: 'Payment Methods',
          icon: 'fas fa-credit-card',
        },
        {
          code: 'audit_history',
          label: 'Audit History',
          icon: 'fas fa-clipboard-check',
        },
      ];
    } else if (
      this.contract &&
      this.contract['ContractDate'] != '0000-00-00' &&
      this.product &&
      this.product.isPaymentPlan != true
    ) {
      this.tabs = [
        {
          code: 'overview',
          label: 'Overview',
          icon: 'fas fa-file-contract',
        },
        {
          code: 'settlement',
          label: 'Settlement',
          icon: 'fas fa-piggy-bank',
        },
        // {
        //   code: 'capacity_check',
        //   label: 'Capacity Check ',
        //   icon: 'fas fa-check-double',
        // },
        {
          code: 'credit_check',
          label: 'Credit Check',
          icon: 'fas fa-credit-card',
        },
        {
          code: 'ID_check',
          label: 'ID Check',
          icon: 'fas fa-id-card',
        },
        {
          code: 'notes',
          label: 'Notes',
          icon: 'fas fa-sticky-note',
        },
        {
          code: 'messages',
          label: 'Messages',
          icon: 'fas fa-comments',
        },
        {
          code: 'supporting_documents',
          label: 'Supporting Documents',
          icon: 'fas fa-folder-open',
        },
        {
          code: 'payment_methods',
          label: 'Payment Methods',
          icon: 'fas fa-credit-card',
        },
        {
          code: 'audit_history',
          label: 'Audit History',
          icon: 'fas fa-clipboard-check',
        },
      ];
    }
  }
  openProductView(id) {
    const ref = this.dialog.open(ProductViewModalComponent, {
      data: id,
      width: '750px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }
  viewContractLogs(id) {
    const ref = this.dialog.open(EventLogListComponent, {
      data: {
        contractID: id,
        type: 'contract',
        itemID: id,
      },
      width: '900px',
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }
  openInvitationView(id) {
    if (id) {
      const ref = this.dialog.open(InvitationViewModalComponent, {
        data: {
          invitationID: id,
          membership: false,
        },
        width: '900px',
      });

      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }
  fullInvitationDetails(invitationID, patientID) {
    if (invitationID && patientID) {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['customer-profile', invitationID, 'treatment', patientID],
          },
        },
      ]);
    }
  }

  contactBySMS(id) {
    const ref = RootAppComponent.dialog.open(TextMessageCreateComponent, {
      data: {
        contractID: id,
      },
      width: '650px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  sendSMS(id, merchantID) {
    if (id && merchantID) {
      const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
        data: {
          cardID: id,
          merchantID: merchantID,
          singleChat: true,
          contractID: id,
        },
        panelClass: 'noCard',
        width: '500px',
      });
      ref2.componentInstance.close.subscribe((data) => {
        ref2.close();
      });

      ref2.componentInstance.getResult.subscribe((data) => {
        if (data) {
          const ref = RootAppComponent.dialog.open(SmsConversationComponent, {
            data,
            panelClass: 'bigger-screen',
            width: '70%',
            height: '95vh',
          });
          const sub = ref.componentInstance.close.subscribe((data) => {
            ref.close();
          });
        }

        ref2.close();
      });
    }
  }

  getInvitationUpdate(d) {
    if (d && d.ID) {
      this.invitation = d;
    }
  }
  createSettlement(id) {
    if (id) {

      let p = { contractID: id }

      AuthenticationService.newSettlement.emit(p);
      // this.router.navigate(['/merchant', { outlets: { page: ['settlement-create', id] } }]);
    }
  }

  viewNotes(patientID: string) {
    this.dialog.open<NotesModal, NotesModalConfig>(NotesModal, {
      data: {
        parentRoute: 'merchant',
        patientID,
      },
    });
  }
  createNote(patientID: string, contractID: string) {
    this.dialog.open<NotesModal, NotesModalConfig>(NotesModal, {
      data: {
        patientID,
        parentRoute: 'merchant',
        noteListMode: 'create',
        relationshipLink: {
          label: 'contract',
          route: `customer-profile/${patientID}/contract/${contractID}`,
          itemID: contractID,
        },
      },
    });
  }
  viewMerchant(id) {
    const ref = this.dialog.open(MerchantViewComponent, {
      data: id,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }
  viewDentist(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(DentistViewComponent, {
        data: id,
        width: '550px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  sendEmail(id) {
    if (id) {
      const data = {
        targetType: 'customerProspect',
        targetID: id,
        asGuest: false,
        asProfile: true,
        asPractice: true,
      };
      AuthenticationService.contactInputEvent.emit(data);
    }
  }
  contactByEmail(id) {
    const data = {
      targetType: 'contract',
      targetID: id,
      asGuest: false,
      asProfile: true,
      asPractice: true,
      subjectArray: [],
    };
    AuthenticationService.contactInputEvent.emit(data);
  }

  openCustomerView(id) {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['customer-profile', id, 'patient'],
        },
      },
    ]);
  }
  quickView(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(CustomerProspectViewModelComponent, {
        data: {
          patientID: id,
          isFullDetails: true,
        },
        width: '700px',
      });
      ref.componentInstance.detailedView.subscribe((res) => {
        ref.close();
        ref.afterClosed().subscribe((r) => {
          this.router.navigate([
            '/merchant',
            {
              outlets: {
                page: ['customer-profile', id],
              },
            },
          ]);
        });
      });

      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  viewMessagesContract(id) {
    const ref = this.dialog.open(MessageTimelineComponent, {
      data: {
        contractID: id,
        messageType: MessageType.message,
      },
      width: '900px',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }
  viewMessagesInvitation(id) {
    const ref = this.dialog.open(MessageTimelineComponent, {
      data: {
        invitationID: id,
        messageType: MessageType.message,
      },
      width: '900px',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }
  viewMessages(id) {
    const ref = this.dialog.open(MessageTimelineComponent, {
      data: {
        cardID: id,
        messageType: MessageType.message,
      },
      width: '900px',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }
  contactDentist(id) {
    if (id) {
      const data = {
        targetType: 'contact',
        targetID: id,
        asGuest: false,
        asProfile: true,
        asPractice: true,
      };
      AuthenticationService.contactInputEvent.emit(data);
    }
  }

  contactSMSDentist(id) {
    if (id) {
      const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
        data: {
          cardID: id,
          isContactStaff: true,
          singleChat: true,
        },
        panelClass: 'bigger-screen',
        width: '70%',
        height: '95vh',
      });
      const sub = ref2.componentInstance.close.subscribe((data) => {
        ref2.close();
      });
    }
  }

  redirect(invitation) {
    if (invitation) {
      if (invitation && invitation.Instant_Finance_Offer_Key) {
        const url = `${environment.nodeUrl}/d/` + invitation.Instant_Finance_Offer_Key;
        window.open(url, '_blank', 'noopener');
      } else if (invitation && invitation.WakandaID) {
        const url =
          Settings.global['consumerFrontendLink'] + '/consumer/(page:invitation-card/' + invitation.WakandaID + ')';
        window.open(url, '_blank', 'noopener');
      }
    }
  }
  wakandaFiles(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(WakandaFilesComponent, {
        data: {
          invitationID: id,
        },
        width: '810px',
      });

      ref.componentInstance.closeEvent.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res && res.ID && res.DocumentType_Code) {
          this.utilsService.getDocumentTypeByCode(res.DocumentType_Code, { fields: 'Label' }).subscribe((r) => {
            if (r && r.Label) {
              res.DocumentType_Label = r.Label;
            }

            this.addContractDoc(res);
            ref.close();
          });
        } else {
          ref.close();
        }
      });
    }
  }
  copy(ID) {
    if (ID) {
      this._clipboardService.copy(ID);
      NotifyAppComponent.displayToast('success', 'Copied', 'Copied');
    }
  }
  buildDocuments() {
    if (this.contract && this.contract.documents) {
      this._documents = [];

      let docs = {};

      _.map(this.contract.documents, (item) => {
        if (item && item.DocumentType_Code) {
          if (docs[item.DocumentType_Code]) {
            docs[item.DocumentType_Code].documents.push(item);
          } else {
            docs[item.DocumentType_Code] = {
              code: item.DocumentType_Code,
              label: item.DocumentType_Label,
              documents: [item],
            };
          }
        }
      });
      this._documents = Object.values(docs);
    }
  }

  replaceRouting() {
    window.history.replaceState(
      {},
      '',
      `/merchant/(page:contract-overview-details/${this.contract.ID}/${this.selectedTab})`
    );

    this.tabAction();
  }

  tabAction() {
    if (this.selectedTab === 'credit_check') {
      this.getHTML();
    }

    if (this.selectedTab === 'settlement' && this.isSettlementLoaded == false) {
      this.isSettlementLoaded = true;
    } else if (this.selectedTab === 'capacity_check' && this.isCapacityCheckLoaded == false) {
      this.isCapacityCheckLoaded = true;
    } else if (this.selectedTab === 'credit_check' && this.isCreditCheckLoaded == false) {
      this.isCreditCheckLoaded = true;
    } else if (this.selectedTab === 'ID_check' && this.isIDCheckLoaded == false) {
      this.isIDCheckLoaded = true;
    } else if (this.selectedTab === 'notes' && this.isNoteLoaded == false) {
      this.isNoteLoaded = true;
    } else if (this.selectedTab === 'messages' && this.isMessageLoaded == false) {
      this.isMessageLoaded = true;
    } else if (this.selectedTab === 'payment_methods' && this.isPaymentMethodLoaded == false) {
      this.isPaymentMethodLoaded = true;
    } else if (this.selectedTab === 'audit_history' && this.isAuditHistoryLoaded == false) {
      this.isAuditHistoryLoaded = true;
    }
  }

  changeTab(d) {
    if (d) {
      this.selectedTab = d;
      this.tabAction();
    }
  }
  getHTML(forceNewAll = false, summaryOnly = true) {
    if (forceNewAll === true || !this.vedaHTML) {
      this.vedaHTML = null;
      this.vedaLoad = false;

      this.contractService
        .getVedaHTML(this.customerID, { forceNewAll: forceNewAll, summaryOnly: summaryOnly, waitForModify: true })
        .subscribe((r) => {
          if (r) {
            this.vedaHTML = r;
          }

          this.vedaLoad = true;
        });
    }
  }

  vedaPDFView() {
    this.contractService.getVedaPDF(this.customerID, { forceNew: false, summaryOnly: false }).subscribe((r) => {
      if (r) {
        const ref = RootAppComponent.dialog.open(BlobViewComponent, {
          data: {
            content: r,
            title: 'Full PDF credit check report - ' + this.customer['CalculatedName'],
            label: 'Full PDF credit check report - ' + this.customer['CalculatedName'],
            extension: 'pdf',
            downloadPullRight: true,
            isPrint: false,
          },
          width: '80vw',
          height: '80vh',
          panelClass: ['bigger-blob-screen', 'max-width-panel-1200'],
        });

        ref.componentInstance.closeModal.subscribe((res) => {
          if (res == true) {
            ref.close();
          }
        });
      }
    });
  }

  vedaHTMLView() {
    this.contractService.getVedaHTML(this.customerID, { forceNew: false, summaryOnly: false }).subscribe((r) => {
      if (r) {
        const ref = RootAppComponent.dialog.open(InnerHtmlIsolatedModalComponent, {
          data: {
            content: r,
            title: 'Full  credit check report',
            description: this.customer['CalculatedName'],
          },
          width: '80vw',
          height: '80vh',
          panelClass: ['noCard', 'max-width-panel-1200'],
        });

        ref.componentInstance.close.subscribe((res) => {
          if (res == true) {
            ref.close();
          }
        });
      }
    });
  }

  addDocument(docType = null, label = null) {
    const ref = RootAppComponent.dialog.open(EPDocumentCreateEditComponent, {
      data: {
        hideThumbnail: true,
        hideCheckBoxes: true,
        type: docType,
        displayMerchantList: false,
        hideDocumentType: true,
        label: label,
        cardID: this.customer.ID,
        contractID: this.contract.ID,
      },
      width: '750px',
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.result.subscribe((res) => {
      if (res && res.ID && res.DocumentType_Code) {
        this.utilsService.getDocumentTypeByCode(res.DocumentType_Code, { fields: 'Label' }).subscribe((r) => {
          if (r && r.Label) {
            res.DocumentType_Label = r.Label;
          }

          this.addContractDoc(res);
          ref.close();
        });
      } else {
        ref.close();
      }
    });
  }

  addContractDoc(d) {
    if (d && d.ID && d.DocumentType_Code) {
      if (this._documents && this._documents.length > 0) {
        let index = this._documents.findIndex((item) => {
          if (item && item.code && item.code == d.DocumentType_Code) {
            return true;
          }
          return false;
        });

        if (index !== -1 && this._documents[index]) {
          if (this._documents[index].documents && this._documents[index].documents.length > 0) {
            this._documents[index].documents.push(d);
          } else {
            this._documents[index].documents = [d];
          }
        } else {
          this._documents.push({
            code: d.DocumentType_Code,
            label: d.DocumentType_Label,
            documents: [d],
          });
        }
      } else {
        this._documents = [
          {
            code: d.DocumentType_Code,
            label: d.DocumentType_Label,
            documents: [d],
          },
        ];
      }

      this._documents = JSON.parse(JSON.stringify(this._documents));
    }
  }
  addDocumentGlobal() {
    const ref = RootAppComponent.dialog.open(EPDocumentCreateEditComponent, {
      data: {
        hideThumbnail: true,
        hideCheckBoxes: true,
        displayMerchantList: false,
        hideDocumentType: false,
        types: this.documentTypes,
        cardID: this.customer.ID,
        contractID: this.contract.ID,
      },
      width: '750px',
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.result.subscribe((res) => {
      if (res && res.ID && res.DocumentType_Code) {
        this.utilsService.getDocumentTypeByCode(res.DocumentType_Code, { fields: 'Label' }).subscribe((r) => {
          if (r && r.Label) {
            res.DocumentType_Label = r.Label;
          }

          this.addContractDoc(res);
          ref.close();
        });
      } else {
        ref.close();
      }
    });
  }
  toNumber(n) {
    if (n) {
      return Number(n);
    } else {
      return 0;
    }
  }
  newSettlement() {

    let p = { contractID: this.contract.ID }

    AuthenticationService.newSettlement.emit(p);

    // const page = 'settlement-create';

    // this.router.navigate(['/merchant', { outlets: { page: [page, this.contract.ID] } }]);
  }

  makeDecision(checkType) {
    const ref = RootAppComponent.dialog.open(QuotationDecisionModalComponent, {
      data: {
        checkType,
        quotationID: this.contract['ID'],
      },
      width: '600px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      this.contractService
        .getContractDetails(
          this.contractID,
          {
            selectAllowedStatus: true,
            fields:
              'ID,Quotation.Status.Label,Quotation.Status.Code,ContractDate,Status.Code,Status,AllowedStatusValues',
          },
          this.isPromoterOrAdmin
        )
        .subscribe((contract) => {
          if (res && res.ID) {
            if (checkType === 'Identity') {
              this.IDCheck = res;
            } else if (checkType === 'Credit') {
              this.creditCheck = res;
            } else if (checkType === 'Capacity') {
              this.capacityCheck = res;
            }
          }

          if (contract && contract.ID) {
            this.contract['Quotation.Status.Label'] = contract['Quotation.Status.Label'];
            this.contract['ContractDate'] = contract['ContractDate'];
            this.contract['Quotation.Status.Code'] = contract['Quotation.Status.Code'];
            this.contract['Status.Code'] = contract['Status.Code'];
            this.contract['Status'] = contract['Status'];

            if (contract.AllowedStatusCodes && contract.AllowedStatusCodes.length > 0) {
              this.AllowedStatusCodes = JSON.parse(JSON.stringify(contract.AllowedStatusCodes));
            } else {
              this.AllowedStatusCodes = [];
            }
          }

          ref.close();
        });
    });
  }

  changeStatus(status) {
    const ref = RootAppComponent.dialog.open(ConfirmDialogMessageComponent, {
      data: {
        icon: 'fas fa-pen-alt',
        title: 'Convert Status',
        description: `<p class="lead"> Convert contract status to <strong> ${status['Label']} </strong></p>`,
        yesLabel: 'Convert',
        noLabel: 'Cancel',
        textLabel: 'Reason',
      },
      width: '650px',
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res && res.confirm == true) {
        const payload = {
          reason: res.message,
          status: status['Code'],
        };

        this.contractService.changeContractStatus(this.contractID, payload).subscribe((res) => {
          this.contractService
            .getContractDetails(
              this.contractID,
              {
                selectAllowedStatus: true,
                fields:
                  'ID,Quotation.Status.Label,Quotation.Status.Code,ContractDate,Status.Code,Status,AllowedStatusValues',
              },
              this.isPromoterOrAdmin
            )
            .subscribe((contract) => {
              if (contract && contract.ID) {
                this.contract['Quotation.Status.Label'] = contract['Quotation.Status.Label'];
                this.contract['ContractDate'] = contract['ContractDate'];
                this.contract['Quotation.Status.Code'] = contract['Quotation.Status.Code'];
                this.contract['Status.Code'] = contract['Status.Code'];
                this.contract['Status'] = contract['Status'];

                if (contract.AllowedStatusCodes && contract.AllowedStatusCodes.length > 0) {
                  this.AllowedStatusCodes = JSON.parse(JSON.stringify(contract.AllowedStatusCodes));
                } else {
                  this.AllowedStatusCodes = [];
                }
              }

              ref.close();
            });
        });
      } else {
        ref.close();
      }
    });
  }
}
