/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./privacy.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./privacy.component";
var styles_PrivacyComponent = [i0.styles];
var RenderType_PrivacyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PrivacyComponent, data: {} });
export { RenderType_PrivacyComponent as RenderType_PrivacyComponent };
export function View_PrivacyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["privacy works!"]))], null, null); }
export function View_PrivacyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-privacy", [], null, null, null, View_PrivacyComponent_0, RenderType_PrivacyComponent)), i1.ɵdid(1, 114688, null, 0, i2.PrivacyComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrivacyComponentNgFactory = i1.ɵccf("app-privacy", i2.PrivacyComponent, View_PrivacyComponent_Host_0, {}, {}, []);
export { PrivacyComponentNgFactory as PrivacyComponentNgFactory };
