import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CompleterData } from 'ng2-completer';
import { TeethShape } from '../../treatment/shared/teeth-shape';

@Component({
  selector: 'app-invitation-emergency',
  templateUrl: './invitation-emergency.component.html',
  styleUrls: ['./invitation-emergency.component.css'],
})
export class InvitationEmergencyComponent implements OnInit {
  genders = [
    { value: 'male-0', viewValue: 'Male' },
    { value: 'female-1', viewValue: 'Female' },
  ];

  titles = [
    { value: 'mr-0', viewValue: 'Mr' },
    { value: 'ms-1', viewValue: 'Ms' },
    { value: 'mrs-2', viewValue: 'Mrs' },
    { value: 'dr-3', viewValue: 'Dr' },
  ];

  senders = [
    { value: '0', viewValue: 'Sender 1' },
    { value: '1', viewValue: 'Sender 2' },
    { value: '2', viewValue: 'Sender 3' },
    { value: '3', viewValue: 'Sender 4' },
  ];

  diagnosisOpts = [
    {
      value: 'General',
      description: 'General diagnosis options available',
      numOptions: 11,
      checked: false,
    },
    {
      value: 'Localised',
      description: 'Localised diagnosis options available',
      numOptions: 8,
      checked: false,
    },
  ];

  diagnosisOptGens = [
    {
      value: 'General',
      description: 'General diagnosis options available',
      numOptions: 11,
      checked: false,
    },
  ];

  diagnosisOptLocs = [
    {
      value: 'Localised',
      description: 'Localised diagnosis options available',
      numOptions: 8,
      checked: false,
    },
  ];

  treatOpts = [
    {
      value: 'Restore',
      description: 'Restoration options available',
      numOptions: 20,
      checked: false,
    },
    {
      value: 'Replace',
      description: 'Replacement options available',
      numOptions: 11,
      checked: false,
    },
    {
      value: 'Remove',
      description: 'Removal options available',
      numOptions: 4,
      checked: false,
    },
  ];

  diagnosisGeneralInnerOpts = [
    {
      value: 'Bruxism',
      description:
        'Bruxism is the involuntary gnashing, grinding, or clenching of teeth. It is usually an unconscious activity-log, whether the individual is awake or asleep; often associated with fatigue, anxiety, emotional stress, or fear and frequently triggered by occlusal irregularities.',
      checked: false,
      numberDocs: 4,
    },
    {
      value: 'Crowded Teeth',
      description:
        'A form of dental malocclusion in which there is a reversal of the normal relationship of the mandibular and maxillary teeth, with lateral displacement of opposing teeth (i.e., a poor bite) and difficulty in mastication.',
      checked: false,
      numberDocs: 3,
    },
    {
      value: 'Decay (Diet)',
      description:
        'Tooth decay, also known as dental caries or cavities, is a breakdown of teeth due to acids made by bacteria. ... The cause of caries is acid from bacteria dissolving the hard tissues of the teeth (enamel, dentin and cementum).',
      checked: false,
      numberDocs: 5,
    },
    {
      value: 'Discoloured Teeth',
      description:
        'Tooth discoloration (also termed tooth staining) is abnormal tooth color, hue or translucency. External discoloration is accumulation of stains on the tooth surface. Internal discoloration is due to absorption of pigment particles into tooth structure.',
      checked: false,
      numberDocs: 4,
    },
    {
      value: 'Erosion (Reflux or Diet)',
      description:
        'Acid erosion, also known as dental erosion, is a type of tooth wear. It is defined as the irreversible loss of tooth structure due to chemical dissolution by acids not of bacterial origin.',
      checked: false,
      numberDocs: 3,
    },
    {
      value: 'Facial Pain',
      description:
        'Atypical facial pain is variable and can be characterized as dull, aching, burning, sharp, squeezing, or crushing quality and has a psychogenic origin. The pain may occur on both sides of the face, differing from trigeminal nerve pain.',
      checked: false,
      numberDocs: 5,
    },
    {
      value: 'Gum Disease',
      description:
        'Inflammation of the soft tissue (gingiva) and abnormal loss of bone that surrounds the teeth and holds them in place. Gum disease is caused by toxins secreted by bacteria in "plaque" that accumulate over time along the gum line. This plaque is a mixture of food, saliva, and bacteria.',
      checked: false,
      numberDocs: 4,
    },
    {
      value: 'Mouth Breathing',
      description:
        'Mouth breathing (also termed open-mouth breathing or a mouth breathing habit) is breathing through the mouth rather than the nose. Generally speaking healthy humans may breathe through their nose, their mouth, or both.',
      checked: false,
      numberDocs: 4,
    },
    {
      value: 'Sleep Apnoea',
      description:
        "Sleep apnoea is a serious sleep disorder that occurs when a person's breathing is interrupted during sleep. People with untreated sleep apnea stop breathing repeatedly during their sleep, sometimes hundreds of times.",
      checked: false,
      numberDocs: 4,
    },
    {
      value: 'Tongue Thrust',
      description:
        'Tongue thrust (also called reverse swallow or immature swallow) is the common name of orofacial muscular imbalance, a human behavioral pattern in which the tongue protrudes through the anterior incisors during swallowing, during speech, and while the tongue is at rest.',
      checked: false,
      numberDocs: 4,
    },
    {
      value: 'Tongue Tie',
      description:
        "Tongue-tie (ankyloglossia) is a condition in which an unusually short, thick or tight band of tissue (lingual frenulum) tethers the bottom of the tongue's tip to the floor of the mouth. If necessary, tongue-tie can be treated with a surgical cut to release the frenulum (frenotomy).",
      checked: false,
      numberDocs: 4,
    },
  ];

  diagnosisLocalInnerOpts = [
    {
      value: 'Broken Tooth',
      description:
        'Cracked tooth is defined as an incomplete fracture of the dentine in a vital posterior tooth that involves the dentine and occasionally extends into the pulp.',
      checked: false,
      numberDocs: 4,
    },
    {
      value: 'Cracked Tooth',
      description:
        'Cracked tooth is defined as an incomplete fracture of the dentine in a vital posterior tooth that involves the dentine and occasionally extends into the pulp.',
      checked: false,
      numberDocs: 3,
    },
    {
      value: 'Decay Risk',
      description:
        'Decay most often occurs in your back teeth (molars and premolars). These teeth have lots of grooves, pits and crannies, and multiple roots that can collect food particles.',
      checked: false,
      numberDocs: 5,
    },
    {
      value: 'Decayed Tooth',
      description:
        'Decay most often occurs in your back teeth (molars and premolars). These teeth have lots of grooves, pits and crannies, and multiple roots that can collect food particles.',
      checked: false,
      numberDocs: 4,
    },
    {
      value: 'Failing Filling ',
      description:
        'Dental fillings may last many years; however, even- tually all fillings need to be replaced. Constant assault from eating and drinking, or stress from clenching or grinding, eventually may cause a dental filling to fail. Bacteria cause tooth decay.',
      checked: false,
      numberDocs: 3,
    },
    {
      value: 'Infected Tooth',
      description:
        'A dental abscess, or tooth abscess, is a buildup of pus that forms inside the teeth or gums. The abscess typically comes from a bacterial infection, often one that has accumulated in the soft pulp of the tooth.',
      checked: false,
      numberDocs: 5,
    },
    {
      value: 'Missing Tooth',
      description: 'Tooth missing through disease or injury.',
      checked: false,
      numberDocs: 4,
    },
    {
      value: 'Worn Tooth',
      description:
        'Tooth wear (also termed non-carious tooth substance loss) refers to loss of tooth substance by means other than dental caries or dental trauma.',
      checked: false,
      numberDocs: 4,
    },
  ];

  severityOption = new FormControl();

  severityOptions = ['Low', 'Moderate', 'High', 'Severe', 'Urgent'];

  additionalOption = new FormControl();

  additionalOptions = ['Standard (Amalgam)', 'Composite Resin', 'Ceramic (Porcelain)', 'Gold'];

  treatInnerOpts = [
    {
      value: 'Fillings',
      description:
        'White Plastic Filling (composite resin). These are more aesthetic than dental amalgam, however cost more and take longer to place. The advantage over dental amalgam is they bond to the remaining tooth structure, potentially making it stronger in some situations',
      checked: false,
      cost: 112,
      price: 170,
      selected: true,
      numberDocs: 4,
      numberOptions: 4,
    },
    {
      value: 'Periodontics',
      description: 'Replacement options available',
      checked: false,
      cost: 120,
      price: 144,
      selected: true,
      numberDocs: 3,
      numberOptions: 2,
    },
    {
      value: 'Root Canal Therapy',
      description:
        'A root canal therapy cleans, disinfects, and seals the inside of an infected tooth, eliminating pain (if present), and allowing the tooth to be saved.',
      checked: false,
      cost: 120,
      price: 168,
      selected: true,
      numberDocs: 5,
      numberOptions: 1,
    },
    {
      value: 'Tooth Whitening',
      description:
        'Chairside professional tooth whitening is a procedure designed to lighten the color of your teeth using a hydrogen peroxide mixture. It produces maximum whitening results in the shortest possible time with minimum sensitivity.',
      checked: false,
      cost: 120,
      price: 144,
      selected: true,
      numberDocs: 4,
      numberOptions: 3,
    },
  ];

  sendSMS = false;
  selectedTreatments;
  notes = false;

  diagnosisMsg =
    'Dental amalgam is a long-lasting and durable filling material used to replace tooth structure. Your dentist has suggested this as treatment to replace an old filling that has cracked.';
  treatMsg =
    'You may or may not require local anaesthetic depending on how deep the cavity is (please inform your dentist of your preference). The old restoration and/or decay will be removed with a drill. A ring is generally placed around the tooth and the amalgam packed into the cavity and carved into shape.';

  // constructor() { }

  @Input() selectedTeeth = [];
  @Output() select = new EventEmitter();
  isAdult = true;
  teethBottom = [];
  teethTop = [];
  options = [];
  selectedValue: any;
  dataService: CompleterData;
  isTopSelected = false;
  isBottomSelected = false;
  isAllSelected = false;
  selectQ1;
  selectQ2;
  selectQ3;
  selectQ4;

  step = 0;

  treatStep = 0;

  constructor(private dialog: MatDialog) {
    // this.selectedTeeth = data;
  }

  ngOnInit() {
    TeethShape.getTopTeethNumbers(this.isAdult).forEach((num) => {
      this.teethTop.push({
        hideNumber: false,
        toothNumber: num,
        selected: this.selectedTeeth.indexOf(num) !== -1 ? true : false,
      });
      this.options.push(num);
    });
    TeethShape.getBottomTeethNumbers(this.isAdult).forEach((num) => {
      this.teethBottom.push({
        hideNumber: false,
        toothNumber: num,
        selected: this.selectedTeeth.indexOf(num) !== -1 ? true : false,
      });
      this.options.push(num);
    });
    this.selectedTeeth.forEach((tooth) => {
      this.getSelectedteeth(tooth);
    });
  }

  selectBottom() {
    this.isBottomSelected = !this.isBottomSelected;
    this.teethBottom.forEach((tooth) => {
      let t = {
        selected: this.isBottomSelected,
        toothNumber: tooth.toothNumber,
      };
      tooth.selected = t.selected;
      if (tooth.selected === true) {
        this.selectedTeeth.push(tooth);
      } else {
        this.selectedTeeth.forEach((selectedtooth) => {
          if (t.toothNumber === selectedtooth.toothNumber) {
            selectedtooth.selected = t.selected;
          }
        });
        this.selectedTeeth = this.selectedTeeth.filter((tooth) => {
          return tooth.selected;
        });
      }
    });
    this.change();
  }

  deselectAll() {}

  selectAll() {
    this.isBottomSelected = !this.isBottomSelected;
    this.teethBottom.forEach((tooth) => {
      let t = {
        selected: this.isBottomSelected,
        toothNumber: tooth.toothNumber,
      };
      tooth.selected = t.selected;
      if (tooth.selected === true) {
        this.selectedTeeth.push(tooth);
      } else {
        this.selectedTeeth.forEach((selectedtooth) => {
          if (t.toothNumber === selectedtooth.toothNumber) {
            selectedtooth.selected = t.selected;
          }
        });
        this.selectedTeeth = this.selectedTeeth.filter((tooth) => {
          return tooth.selected;
        });
      }
    });

    this.isTopSelected = !this.isTopSelected;
    this.teethTop.forEach((tooth) => {
      let t = {
        selected: this.isTopSelected,
        toothNumber: tooth.toothNumber,
      };
      tooth.selected = t.selected;
      if (tooth.selected === true) {
        this.selectedTeeth.push(tooth);
      } else {
        this.selectedTeeth.forEach((selectedtooth) => {
          if (t.toothNumber === selectedtooth.toothNumber) {
            selectedtooth.selected = t.selected;
          }
        });
        this.selectedTeeth = this.selectedTeeth.filter((tooth) => {
          return tooth.selected;
        });
      }
    });
    this.change();
  }

  selectTop() {
    this.isTopSelected = !this.isTopSelected;
    this.teethTop.forEach((tooth) => {
      let t = {
        selected: this.isTopSelected,
        toothNumber: tooth.toothNumber,
      };
      tooth.selected = t.selected;
      if (tooth.selected === true) {
        this.selectedTeeth.push(tooth);
      } else {
        this.selectedTeeth.forEach((selectedtooth) => {
          if (t.toothNumber === selectedtooth.toothNumber) {
            selectedtooth.selected = t.selected;
          }
        });
        this.selectedTeeth = this.selectedTeeth.filter((tooth) => {
          return tooth.selected;
        });
      }
    });
    this.change();
  }

  selectTooth(t) {
    if (
      t.toothNumber >= TeethShape.getTopTeethNumbers(this.isAdult)[7] &&
      t.toothNumber <=
        TeethShape.getTopTeethNumbers(this.isAdult)[TeethShape.getTopTeethNumbers(this.isAdult).length - 1]
    ) {
      this.teethTop.forEach((tooth) => {
        if (t.toothNumber === tooth.toothNumber) {
          tooth.selected = t.selected;
          if (tooth.selected === true) {
            this.selectedTeeth.push(tooth);
          } else {
            this.selectedTeeth.forEach((selectedtooth) => {
              if (t.toothNumber === selectedtooth.toothNumber) {
                selectedtooth.selected = t.selected;
              }
            });
            this.selectedTeeth = this.selectedTeeth.filter((tooth) => {
              return tooth.selected;
            });
          }
        }
      });
    } else {
      this.teethBottom.forEach((tooth) => {
        if (t.toothNumber === tooth.toothNumber) {
          tooth.selected = t.selected;
          if (tooth.selected === true) {
            this.selectedTeeth.push(tooth);
          } else {
            this.selectedTeeth.forEach((selectedtooth) => {
              if (t.toothNumber === selectedtooth.toothNumber) {
                selectedtooth.selected = t.selected;
              }
            });
            this.selectedTeeth = this.selectedTeeth.filter((tooth) => {
              return tooth.selected;
            });
          }
        }
      });
    }
    this.change();
  }

  change() {
    this.select.emit({
      selectedTeeth: this.selectedTeeth,
      isAdult: this.isAdult,
    });
  }

  getSelectedteeth(t) {
    if (
      t.toothNumber >= TeethShape.getTopTeethNumbers(this.isAdult)[7] &&
      t.toothNumber <=
        TeethShape.getTopTeethNumbers(this.isAdult)[TeethShape.getTopTeethNumbers(this.isAdult).length - 1]
    ) {
      this.teethTop.forEach((tooth) => {
        if (t.toothNumber === tooth.toothNumber) {
          tooth.selected = t.selected;
        }
      });
    } else {
      this.teethBottom.forEach((tooth) => {
        if (t.toothNumber === tooth.toothNumber) {
          tooth.selected = t.selected;
        }
      });
    }
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  scrollTop() {
    window.scrollTo(0, 0);
  }

  setTreatStep(index: number) {
    this.treatStep = index;
  }

  nextTreatStep() {
    this.treatStep++;
  }

  prevTreatStep() {
    this.treatStep--;
  }
}
