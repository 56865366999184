<div class="rel dash invit">
  <div class="card-header primary-gradient-img inModal">
    <div class="row titleArea clearfix full-width">
      <img class="animated zoomIn feature-img dashboard character_Pair"/>
      <h2 class="summary-header rm-m white">
        Invitations
      </h2>
    </div>
    <button class="btn-close btn-clear mat-button" (click)="doNotShow()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <app-video-tutorials-modal [defaultSelectedTopic]="'418331841'" [autoplay]="false"></app-video-tutorials-modal>

    <div class="row clearfix mb">
      <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb text-center">
        Before you get started here are some important things you should know:
      </h3>
      <div class="mb grey flex">
        <div class="flex flex-col text-center column thrd-width">
          <mat-icon class="fas fa-paper-plane"></mat-icon>
          <p>
            An Email/SMS will be sent to your {{ "KEYWORD.patient" | translate }}, with an invitation to apply for the
            selected product
          </p>
        </div>
        <div class="flex flex-col text-center column thrd-width">
          <mat-icon class="fas fa-envelope-open-text"></mat-icon>
          <p>
            You will need to attach a copy of the proposed {{ 'PHRASE.treatment plan' | translate }} and you may also
            choose to attach other information you wish your {{ "KEYWORD.patient" | translate }} to receive
          </p>
        </div>
        <div class="flex flex-col text-center column thrd-width">
          <mat-icon class="fas fa-pen-nib"></mat-icon>
          <p>
            In addition, you can personalise your invitation by entering a message and scheduling the date and time to
            send on the next screen.
          </p>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <hr/>
  <mat-checkbox [(ngModel)]="showBeforeStartingInvitation">Please don't show this message again</mat-checkbox>
  <button class="ml pull-right" (click)="doNotShow()" mat-raised-button color="accent">
    Got It!
  </button>
</div>
