import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { TagsService } from '../shared/tags.service';
import { TagCreateEditComponent } from '../tag-create-edit/tag-create-edit.component';

@Component({
  selector: 'app-tag-patient-manager',
  templateUrl: './tag-patient-manager.component.html',
  styleUrls: ['./tag-patient-manager.component.css'],
})
export class TagPatientManagerComponent implements OnInit {
  @Input()
  isRemoved = true;

  @Input()
  patientTags;

  @Input()
  patientID;

  @Output()
  getResult = new EventEmitter();

  @Output()
  getFullResult = new EventEmitter();

  close = new EventEmitter();
  isModal = true;
  sessionType;

  removetag = new EventEmitter();
  tags = [];
  defaultSelectedTags = [];

  constructor(
    private authenticationService: AuthenticationService,
    private tagsService: TagsService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.patientID != null) {
        this.patientID = data.patientID;
      }
      if (data.isRemoved != null) {
        this.isRemoved = data.isRemoved;
      }

      if (data.patientTags != null) {
        this.patientTags = data.patientTags;
        this.defaultSelectedTags = JSON.parse(JSON.stringify(data.patientTags));
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((res) => {
      this.sessionType = res;

      const pTag = {
        fields: 'Label',
      };
      this.tagsService.getTagsList(pTag, this.sessionType).subscribe((res) => {
        if (res) {
          this.tags = res;
        }
      });
    });
  }

  closeEvent() {
    this.close.emit(true);
  }

  getRemovedTag(value: string): void {
    if (value) {
      const confirmDialog = new ConfirmDialog(
        'cancel',
        'Remove ' + value + ' Tag',
        '<p>Are you sure?</p>',

        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          const p = {
            label: value,
            cardIDs: [this.patientID],
          };
          this.tagsService.deleteTagForCard(p, this.sessionType).subscribe((res) => {
            if (res) {
              NotifyAppComponent.displayToast('success', 'Success!', 'Tag is removed.');

              this.removetag.emit(value);

              const index = this.patientTags.indexOf(value);
              if (index != -1) {
                this.patientTags.splice(index, 1);
              }

              this.getResult.emit(this.patientTags);

              ref.close();
            }
          });
        } else {
          ref.close();
        }
      });
    }
  }

  createNewTag() {
    const ref = RootAppComponent.dialog.open(TagCreateEditComponent, {
      data: {},

      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        this.tags.push(res);
        this.tags = JSON.parse(JSON.stringify(this.tags));
        ref.close();
      }
    });
  }

  getSelectedTags(e) {
    this.patientTags = e || [];
  }

  save() {
    if (this.patientTags && this.patientTags.length > 0) {
      const payload = {
        labels: this.patientTags.join(','),
        cardID: this.patientID,
      };

      this.tagsService.createTagForCard(payload, this.sessionType).subscribe((res) => {
        if (res) {
          NotifyAppComponent.displayToast('success', 'Create Tag', 'You have successfully create a new tag');

          const result = [];

          for (let i = 0; i < res.length; i++) {
            if (res[i] && res[i]['Label']) {
              result.push(res[i]['Label']);
            }
          }
          this.getResult.emit(result);
          this.getFullResult.emit(res);
          this.closeEvent();
        }
      });
    }
  }
}
