<h1>Latest {{ "PHRASE.treatment plan" | translate | titlecase }}s </h1>
<h2>Recent Accepted Applications</h2>
<div>
  <mat-table #table [dataSource]="dataSource" matSort>
    <!-- ID Column -->
    <ng-container cdkColumnDef="Date">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> {{ 'DateApplied' | translate }} </mat-header-cell>
      <mat-cell *cdkCellDef="let row"> {{ row['DateTimeCreated'] | customDate }} </mat-cell>
    </ng-container>

    <!-- Surname Column -->
    <ng-container cdkColumnDef="LastName">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Surname</mat-header-cell>
      <mat-cell *cdkCellDef="let row"> {{ row.LastName }} </mat-cell>
    </ng-container>

    <!-- Firstname Column -->
    <ng-container cdkColumnDef="FirstName">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> First Name </mat-header-cell>
      <mat-cell *cdkCellDef="let row"> {{ row.FirstName }} </mat-cell>
    </ng-container>

    <!-- Source Column -->
    <ng-container cdkColumnDef="Source">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Source</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <span *ngIf="row['PatientChoice.Code'] == 'COLD'">Via Web</span>
        <span *ngIf="row['PatientChoice.Code'] != 'COLD'">Invitation</span>
      </mat-cell>
    </ng-container>

    <!-- Product Column -->
    <ng-container cdkColumnDef="Product">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Product</mat-header-cell>
      <mat-cell *cdkCellDef="let row"> {{ row['Product.Label.Marketing'] }} </mat-cell>
    </ng-container>

    <!-- Invitation Status Column -->
    <ng-container cdkColumnDef="InvitationStatus">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Status</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <span class="list-label" [style.background]="row['Status.Invitation.Colour']">{{
          row['Status.Invitation.Short_NE'] }}
        </span></mat-cell>
    </ng-container>

    <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>
<br/>
<div class="row clearfix">
  <div class="pull-right">
    <a [routerLink]="['/merchant/', { outlets: { page: ['invitation-list'] } }]" mat-raised-button color="primary">View
      All</a>
  </div>

  <span class="history pull-left">
    <mat-icon>history</mat-icon>data updated {{ dataSource.lastData | amTimeAgo }}
  </span>
</div>
