import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BeneficiaryCreateEditComponent } from '../beneficiary-create-edit/beneficiary-create-edit.component';
import { beneficiaryDocumentGalleryOverviewComponent } from '../beneficiary-document-gallery-overview/beneficiary-document-gallery-overview.component';
import { BeneficiaryListMapComponent } from '../beneficiary-list-map/beneficiary-list-map.component';
import { BeneficiaryViewComponent } from '../beneficiary-view/beneficiary-view.component';
import { BeneficiaryPage } from '../beneficiary.page';

import { BeneficiaryCatalogueViewComponent } from '../beneficiary-catalogue-view/beneficiary-catalogue-view.component';
import { BeneficiarySubsidiaryCreateEditComponent } from '../beneficiary-subsidiary-create-edit/beneficiary-subsidiary-create-edit.component';
import { BeneficiarySubsidiaryListViewComponent } from '../beneficiary-subsidiary-list/beneficiary-subsidiary-list.component';

const routes: Routes = [


  {
    path: 'beneficiaryDocumentGalleryOverviewComponent',
    component: beneficiaryDocumentGalleryOverviewComponent,
  },

  // manufacurers/distributors catalogue view
  {
    path: 'BeneficiaryCatalogueView',
    component: BeneficiaryCatalogueViewComponent,
  },

  // subsidiaries

  {
    path: 'BeneficiarySubsidiaryCreateEdit',
    component: BeneficiarySubsidiaryCreateEditComponent,
  },
  {
    path: 'BeneficiaryDistributorList',
    component: BeneficiarySubsidiaryListViewComponent,
  },
  {
    path: 'BeneficiaryViewComponent',
    component: BeneficiaryViewComponent,
  },
  {
    path: 'BeneficiaryCreateEditComponent',
    component: BeneficiaryCreateEditComponent,
  },

  {
    path: 'BeneficiaryListMapComponent',
    component: BeneficiaryListMapComponent,
  },
  {
    path: 'beneficiary',
    component: BeneficiaryPage,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BeneficiaryRoutingModule {}
