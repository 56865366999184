<div class="clearfix">
  <div class="file-container">
    <mat-card class="card clearfix main-card-template">
      <ng-container *ngIf="!isCheckingAccount; else checkingAccount">
        <ng-container *ngIf="isGoogleReviewsEnabled; else googleReviewsDisabled">
          <ng-container *ngIf="isServiceReady; else preparingService">
            <ng-container *ngIf="businessInfo$ | async as businessInfo">
              <ng-container *ngIf="businessInfo !== null">
                <div class="card-header primary-gradient-img clearfix inModal camps">
                  <div class="row clearfix full-width content">
                    <div
                      class="row titleArea clearfix"
                      style="max-width: none; float: none; display: block; width: 100%"
                    >
                      <div class="display: flex; justify-content: space-between">
                        <div class="title">
                          <mat-icon class="pull-left">star</mat-icon>

                          <div style="display: flex; justify-content: space-between; flex-wrap: nowrap">
                            <h2 class="summary-header rm-m" style="line-height: 24px">
                              {{ businessInfo.Google_Reviews_Business_Name }}
                            </h2>

                            <div style="white-space: nowrap; margin: 0px 15px; line-height: 30px">
                              {{ businessInfo.Google_Reviews_Business_Rating }}
                            </div>

                            <app-rating
                              [rating]="businessInfo.Google_Reviews_Business_Rating"
                              [color]="'white'"
                              style="line-height: 25px"
                            >
                            </app-rating>

                            <div style="white-space: nowrap; margin: 0px 15px; line-height: 30px">
                              {{ businessInfo.Google_Reviews_Business_Review_Count }}
                              Google Reviews
                            </div>

                            <div style="white-space: nowrap; margin: 0px 15px; line-height: 30px">
                              <button [matMenuTriggerFor]="googleReviewMenu" mat-icon-button>
                                <mat-icon style="color: white">more_vert</mat-icon>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="searchArea" style="display: flex; margin: 10px 0px !important; padding: 0px !important">
                      <mat-icon class="mt">search</mat-icon>

                      <mat-form-field class="ml full-width" appearance="outline">
                        <mat-label> Name</mat-label>
                        <!-- (input)="setFilter($event.target.value ,'Label')" -->
                        <input class="clearfix" [(ngModel)]="nameFilter" name="name" placeholder="Name" matInput/>
                      </mat-form-field>

                      <app-date-input
                        class="ml full-width"
                        [label]="'Date From'"
                        [isClear]="true"
                        [defaultDate]="null"
                        (selectedDate)="setDateFrom($event)"
                        name="fromDate"
                      >
                      </app-date-input>

                      <app-date-input
                        class="ml full-width"
                        [label]="'Date To'"
                        [isClear]="true"
                        [defaultDate]="null"
                        (selectedDate)="setDateTo($event)"
                        name="toDate"
                      >
                      </app-date-input>

                      <!-- (selectionChange)="setFilter($event.value ,'Active')" -->
                      <mat-form-field class="ml full-width" appearance="outline">
                        <mat-label>Sort by Date</mat-label>
                        <mat-select
                          class="full-width"
                          [(ngModel)]="orderByDateFilter"
                          name="Type"
                          placeholder="Sort by Date"
                        >
                          <mat-option [value]="'-updateTime'">
                            Newest first
                          </mat-option>

                          <mat-option [value]="'updateTime'">
                            Oldest first
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="ml full-width" appearance="outline">
                        <mat-label>Sort by Rating</mat-label>
                        <mat-select
                          class="full-width"
                          [(ngModel)]="orderByRatingFilter"
                          name="Type"
                          placeholder="Order by Rating"
                        >
                          <mat-option [value]="''">
                            All
                          </mat-option>

                          <mat-option [value]="'-starRating'">
                            Highest first
                          </mat-option>

                          <mat-option [value]="'starRating'">
                            Lowest first
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <!-- | FilterArrayMultipleValueInRange:['updateTime']:[dateFromFilter,dateToFilter] -->
                <mat-dialog-content class="full-width" [ngClass]="{ 'no-modal-height': false }">
                  <ng-container>
                    <div style="display: flex; flex-wrap: wrap; width: 100%">
                      <sr-google-review
                        *ngFor="
                          let googleReview of googleReviews
                            | FilterArrayMultipleContainValue: ['reviewer.displayName']:nameFilter
                            | orderBy: [orderByDateFilter]
                            | orderBy: [orderByRatingFilter]
                        "
                        [googleReview]="googleReview"
                        [Google_Reviews_Location_ID]="Google_Reviews_Location_ID"
                        [Google_Reviews_Account_ID]="Google_Reviews_Account_ID"
                        [merchantID]="merchantID"
                        [sessionType]="sessionType"
                        (replySent)="updateReply($event)"
                        style="flex-basis: 33.333333%"
                      >
                      </sr-google-review>
                    </div>
                  </ng-container>
                </mat-dialog-content>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="isFetchingGoogleReviews">
              <div class="card-header primary-gradient-img clearfix inModal camps">
                <div class="row clearfix full-width content">
                  <div class="row titleArea clearfix" style="max-width: none; float: none; display: block">
                    <div class="display: flex; justify-content: space-between">
                      <div class="title">
                        <mat-icon class="pull-left">star</mat-icon>

                        <ng-container>
                          <h2 class="summary-header rm-m" style="white-space: nowrap; line-height: 25px">
                            Google Reviews
                          </h2>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <mat-dialog-content class="full-width" [ngClass]="{ 'no-modal-height': false }">
                <p style="text-align: center">Fetching Google Reviews...</p>
              </mat-dialog-content>
            </ng-container>
          </ng-container>

          <ng-template #preparingService>
            <div class="card-header primary-gradient-img clearfix inModal camps">
              <div class="row clearfix full-width content">
                <div class="row titleArea clearfix" style="max-width: none; float: none; display: block">
                  <div class="display: flex; justify-content: space-between">
                    <div class="title">
                      <mat-icon class="pull-left">star</mat-icon>

                      <ng-container>
                        <h2 class="summary-header rm-m" style="line-height: 24px">Google Reviews</h2>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <mat-dialog-content class="full-width" [ngClass]="{ 'no-modal-height': false }">
              <p style="margin-top: 20px; margin-bottom: 20px; text-align: center">
                Preparing Google Reviews service...
              </p>
            </mat-dialog-content>
          </ng-template>
        </ng-container>

        <ng-template #googleReviewsDisabled>
          <div class="card-header primary-gradient-img clearfix inModal camps">
            <div class="row clearfix full-width content">
              <div class="row titleArea clearfix" style="max-width: none; float: none; display: block">
                <div class="display: flex; justify-content: space-between">
                  <div class="title">
                    <mat-icon class="pull-left">star</mat-icon>

                    <ng-container>
                      <h2 class="summary-header rm-m" style="white-space: nowrap; line-height: 25px">Google Reviews</h2>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <mat-dialog-content class="full-width" [ngClass]="{ 'no-modal-height': false }">
            <div style="display: block; margin: 0px auto; width: 305px">
              <sr-google-reviews-config (connected)="setGoogleReviewData($event)"></sr-google-reviews-config>
            </div>
          </mat-dialog-content>
        </ng-template>
      </ng-container>

      <ng-template #checkingAccount>
        <div class="card-header primary-gradient-img clearfix inModal camps">
          <div class="row clearfix full-width content">
            <div class="row titleArea clearfix" style="max-width: none; float: none; display: block">
              <div class="display: flex; justify-content: space-between">
                <div class="title">
                  <mat-icon class="pull-left">star</mat-icon>

                  <ng-container>
                    <h2 class="summary-header rm-m" style="white-space: nowrap; line-height: 25px">Google Reviews</h2>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>

        <mat-dialog-content class="full-width" [ngClass]="{ 'no-modal-height': false }">
          <p style="margin-top: 20px; margin-bottom: 20px; text-align: center">
            Checking Google Review Account Status...
          </p>
        </mat-dialog-content>
      </ng-template>
    </mat-card>
  </div>
</div>
\

<mat-menu #googleReviewMenu="matMenu">
  <button (click)="disconnectAccount()" mat-menu-item color="primary">Disconnect</button>
</mat-menu>
