import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SocialMediaCreateEditComponent } from '../social-media-create-edit/social-media-create-edit.component';
import { SocialMediaGalleryListComponent } from '../social-media-gallery-list/social-media-gallery-list.component';
import { SocialMediaListComponent } from '../social-media-list/social-media-list.component';
import { SocialMediaViewComponent } from '../social-media-view/social-media-view.component';

const routes: Routes = [
  {
    path: 'SocialMediaGalleryListComponent',
    component: SocialMediaGalleryListComponent,
  },

  {
    path: 'SocialMediaViewComponent',
    component: SocialMediaViewComponent,
  },

  {
    path: 'SocialMediaViewComponent/:templateID',
    component: SocialMediaViewComponent,
  },
  {
    path: 'SocialMediaListComponent',
    component: SocialMediaListComponent,
  },
  {
    path: 'SocialMediaCreateEditComponent',
    component: SocialMediaCreateEditComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SocialMediaRoutingModule {}
