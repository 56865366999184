<ng-container *ngIf="printMode == false">
  <div class="row clearfix illList mt" *ngIf="illnessList && illnessList.length > 0">
    <mat-card class="card illnessList clearfix no-box-card mt mb" *ngFor="let item of illnessList; let i = index">
      <div class="card-header primary-gradient-img clearfix inModal">
        <div class="row clearfix">
          <illness-allergy-icon
            class="pull-left"
            [illClass]="'illness'"
            [iconContent]="item['Type.Label']"
            [numOfSteps]="illnessList.length"
            [step]="i"
          ></illness-allergy-icon>

          <h2 class="summary-header rm-m">
            {{ item['Type.Label'] }}
          </h2>
        </div>
        <button
          *ngIf="disableModify == false"
          (click)="modifyIllness(item)"
          mat-mini-fab
          color="accent"
          matTooltip="Edit"
        >
          <mat-icon>edit</mat-icon>
        </button>

        <p *ngIf="this.archivedIllnessCodeList && this.archivedIllnessCodeList?.indexOf(item['Type.Code']) !== -1">
          ...
        </p>
      </div>

      <div class="row clearfix flex text-center">
        <div class="full-width clearfix">
          <div class="row clearfix full-width flex force">
            <div class="full-width">
              <h5 class="sr-title">{{"KEYWORD.patient" | translate | titlecase}} still suffering?</h5>
              <p class="rm-mt" *ngIf="item['IsOngoing'] == '1'">Yes</p>
              <p class="rm-mt" *ngIf="item['IsOngoing'] == '0'">No</p>
            </div>

            <div class="full-width" *ngIf="item['FirstOccurDate'] && item['FirstOccurDate'] != '0000-00-00'">
              <h5 class="sr-title">First occurred</h5>
              <p class="rm-mt">{{ formatDate(item['FirstOccurDate']) }}</p>
            </div>
            <!-- <hr class="mobShow" *ngIf="item['CeaseDate'] && item['CeaseDate']!='0000-00-00'"> -->
            <div class="full-width" *ngIf="item['CeaseDate'] && item['CeaseDate'] != '0000-00-00'">
              <h5 class="sr-title">Finished</h5>
              <p class="rm-mt">{{ formatDate(item['CeaseDate']) }}</p>
            </div>

            <div class="full-width" *ngIf="item['FirstOccurDate'] && item['FirstOccurDate'] != '0000-00-00'">
              <h5 class="sr-title">Illness occuring duration</h5>
              <p class="rm-mt" *ngIf="item['IsOngoing'] == '0'">
                {{ item['FirstOccurDate'] | timeLeft: item['CeaseDate']:'years,months':false }}
              </p>

              <p class="rm-mt" *ngIf="item['IsOngoing'] == '1'">
                {{ item['FirstOccurDate'] | timeLeft: null:'years,months':false }}
              </p>
            </div>
          </div>
          <hr *ngIf="item['Description']"/>
          <div class="row clearfix" *ngIf="item['Description']">
            <h5 class="sr-title">Additional notes from {{"KEYWORD.patient" | translate}}</h5>
            <p class="rm-mt">{{ item['Description'] }}</p>
          </div>

          <hr [hidden]="medicationExist != true"/>
          <div class="row clearfix flex full-width">
            <app-medication-list
              class="full-width"
              *ngIf="isLoaded == true"
              [canView]="true"
              [title]="'Medications'"
              [canCreate]="false"
              [canEdit]="false"
              [illnessID]="item.ID"
              [allergyCode]="item['Category.Code']"
              [medications]="item.medications"
              [startDate]="item['FirstOccurDate']"
              [stopDate]="item['CeaseDate']"
              [showStopDate]="!stringToBoolean(item['IsOngoing'])"
              [canAction]="false"
              [canYesNo]="false"
              (getMedicationExist)="getMedicationExist($event)"
            >
            </app-medication-list>
          </div>
        </div>
      </div>
    </mat-card>

    <div class="row clearfix add-mt full-width add-mb" *ngIf="isCreate == true">
      <button class="full-width" (click)="createNew()" mat-raised-button color="warn">
        <mat-icon>add</mat-icon> Add Illness
      </button>
    </div>
  </div>

  <div class="row clearfix full-width" *ngIf="illnessList && illnessList.length <= 0">
    <div class="image-area row clearfix allergies" *ngIf="isConsumer == true">
      <img
        class="big animated bounceInDown"
        src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/allergies-tissue.png"
      />
    </div>

    <h3 class="text-center full-width mt mb white" *ngIf="isConsumer == true">No allergies added.</h3>
    <app-empty-list-message *ngIf="isConsumer == false"></app-empty-list-message>

    <div class="row clearfix full-width add-mt add-mb" *ngIf="isCreate == true">
      <button class="full-width" (click)="createNew()" mat-raised-button color="warn">
        <mat-icon>add</mat-icon> Add Illness
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="printMode == true">
  <div class="row clearfix illList" *ngIf="illnessList && illnessList.length > 0">
    <div class="illnessList clearfix mt" *ngFor="let item of illnessList; let i = index">
      <div class="row clearfix">
        <h5 class="rm-mt">
          <strong>{{ item['Type.Label'] }}</strong>
        </h5>
      </div>

      <p *ngIf="this.archivedIllnessCodeList && this.archivedIllnessCodeList?.indexOf(item['Type.Code']) !== -1">...</p>

      <div class="row clearfix">
        <div class="row clearfix">
          <p class="">
            Still suffering from this illness, medical condition or treatment?
            <strong *ngIf="item['IsOngoing'] == '1'">Yes</strong>
            <strong *ngIf="item['IsOngoing'] == '0'">No</strong>
          </p>
        </div>
        <div class="row clearfix">
          <p class="rm-mt" *ngIf="item['FirstOccurDate'] && item['FirstOccurDate'] != '0000-00-00'">
            First occurred:
            <strong>{{ formatDate(item['FirstOccurDate']) }}</strong>
          </p>
          <p class="rm-mt" *ngIf="item['CeaseDate'] && item['CeaseDate'] != '0000-00-00'">
            Finished:
            <strong>{{ formatDate(item['CeaseDate']) }}</strong>
          </p>
        </div>

        <div class="row clearfix" *ngIf="medicationExist == true">
          <h6 class="rm-mt">
            <strong>Medications</strong>
          </h6>
        </div>

        <div class="row clearfix flex full-width">
          <app-medication-list
            class="full-width"
            *ngIf="isLoaded == true"
            [canView]="false"
            [title]="''"
            [canCreate]="false"
            [canEdit]="false"
            [illnessID]="item.ID"
            [allergyCode]="item['Category.Code']"
            [medications]="item.medications"
            [startDate]="item['FirstOccurDate']"
            [stopDate]="item['CeaseDate']"
            [showStopDate]="!stringToBoolean(item['IsOngoing'])"
            [canAction]="false"
            [canYesNo]="false"
            (getMedicationExist)="getMedicationExist($event)"
          >
          </app-medication-list>
        </div>

        <div class="row clearfix">
          <p class="rm-mt" *ngIf="item['Description']">
            Additional notes from {{"KEYWORD.patient" | translate}}
            <strong>{{ item['Description'] }}</strong>
          </p>
        </div>
      </div>

      <hr class="mb"/>
    </div>
  </div>
</ng-container>
