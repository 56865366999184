import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ipv-background-header',
  templateUrl: './background-header.component.html',
  styleUrls: ['./background-header.component.css'],
})
export class BackgroundHeaderComponent implements OnInit {
  @Input() title = 'title here';

  constructor() {}

  ngOnInit() {}
}
