import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, MatSort } from '@angular/material';
import { Router } from '@angular/router';
import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import * as _ from 'lodash';
import { InvitationTemplateMergerComponent } from '../../../feature/invitation-template/invitation-template-merger/invitation-template-merger.component';
import { ThirdPartyService } from '../shared/third-party.service';
import { ThirdPartyClientQuickEditComponent } from '../third-party-client-quick-edit/third-party-client-quick-edit.component';
import { ThirdPartyClientViewComponent } from '../third-party-client-view/third-party-client-view.component';

@Component({
  selector: 'app-third-party-client-list',
  templateUrl: './third-party-client-list.component.html',
  styleUrls: ['./third-party-client-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('10ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-10%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('10ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-10%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ThirdPartyClientListComponent implements OnInit {
  @Input()
  canCreate = true;

  @Input()
  title = 'Third-Party Applications';
  @Input()
  isActive;
  @Input()
  templateID;
  @Input()
  pageSize = 10;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  nature;
  util = new UtilsClass();

  displayedColumns = [
    'selectCheckBox',
    'Picture',
    'Label',
    'Email',
    'Phone',
    'Template',
    'Media',
    'Date',
    'active',
    'Actions',
  ];
  filters = [];

  selectedIDs = [];
  searchVals = new FormControl();

  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  @Output()
  updateAllTemplate = new EventEmitter();

  destroyEvent = new EventEmitter();

  filteredSize = null;
  sessionType;
  isPromoterOrAdmin = false;
  templateIDs = [];
  templates = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private thirdPartyService: ThirdPartyService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
      this.isPromoterOrAdmin = isPromoterOrAdmin;
    });

    this.authenticationService.getSessionType().subscribe((res) => {
      this.sessionType = res;
      const p = {
        isActive: this.isActive,
        templateID: this.templateID,
      };
      this.listDB = new LoadRecords(this.thirdPartyService, this.sessionType, this.destroyEvent, p);

      this.listDB.getTemplates.subscribe((tags) => {
        this.templates = JSON.parse(JSON.stringify(tags || []));
      });
      this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const p = {
      isActive: this.isActive,
      templateID: this.templateID,
    };
    this.listDB = new LoadRecords(this.thirdPartyService, this.sessionType, this.destroyEvent, p);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  setFilter(event, field) {
    let filter;
    if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  selectPage() {
    const index = this.dataSource['_paginator']['index'] || 0;
    const pageSize = this.dataSource['_paginator']['pageSize'] || 0;

    for (let i = 0; i < (index + 1) * pageSize; i++) {
      const o = this.dataSource.filteredData[index * pageSize + i];

      if (o && o['ID'] && this.selectedIDs.indexOf(o['ID']) == -1) {
        this.selectedIDs.push(o['ID']);
      }
    }
  }

  select(id, isSelected) {
    if (isSelected == true && this.selectedIDs.indexOf(id) == -1) {
      this.selectedIDs.push(id);
    } else {
      const _index = this.selectedIDs.indexOf(id);
      this.selectedIDs.splice(_index, 1);
    }
  }

  unselect() {
    this.selectedIDs = [];
  }

  isSelected(id) {
    if (!this.selectedIDs || this.selectedIDs.length <= 0) {
      return false;
    } else {
      if (this.selectedIDs.indexOf(id) != -1) {
        return true;
      }
    }

    return false;
  }

  selectAll() {
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      const o = this.dataSource.filteredData[i];

      if (o && o['ID'] && this.selectedIDs.indexOf(o['ID']) == -1) {
        this.selectedIDs.push(o['ID']);
      }
    }
  }

  removeGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirm = new ConfirmDialog(
        'fas fa-delemte',
        '',
        'Are you sure you want to remove these Applications  ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.thirdPartyService.deleteThirdPartyClientGroup(this.selectedIDs).subscribe((res) => {
            if (res && res.length > 0) {
              for (let i = 0; i < res.length; i++) {
                this.dataSource.removeItem = res[i];
              }

              this.selectedIDs = [];
            }
            ref.close();

            NotifyAppComponent.displayToast('success', 'Success!', 'Medications are removed.');
          });
        }
      });
    }
  }

  create() {
    this.router.navigate(['/merchant', { outlets: { page: ['third-party-client-create-edit'] } }]);
  }

  Edit(id) {
    if (id) {
      this.router.navigate(['/merchant', { outlets: { page: ['third-party-client-create-edit', id] } }]);
    }
  }

  viewTemplate(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(InvitationTemplateMergerComponent, {
        data: {
          templateID: id,
          viewOnly: true,
          hideTreatmentValue: true,
        },
        width: '1200px',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  view(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(ThirdPartyClientViewComponent, {
        data: {
          thirdPartyClientID: id,
          isViewTemplate: true,
        },
        width: '700px',
        panelClass: 'noCard',
        height: '1000px',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
      ref.componentInstance.getViewTemplate.subscribe((res) => {
        if (res) {
          const ref2 = RootAppComponent.dialog.open(InvitationTemplateMergerComponent, {
            data: {
              templateID: res,
              viewOnly: true,
              hideTreatmentValue: true,
            },
            width: '1200px',
          });
          ref2.componentInstance.close.subscribe((res) => {
            ref2.close();
          });
        }
      });
    }
  }

  quickEdit(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(ThirdPartyClientQuickEditComponent, {
        data: {
          thirdPartyClientID: id,
        },
        width: '700px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res && res.ID) {
          if (
            res &&
            res.updateAll === true &&
            res.templateIDOld &&
            res.templateID &&
            res.templateIDOld != res.templateID
          ) {
            this.updateAllTemplate.emit(res);
          }
          this.dataSource.replaceItem = res;

          ref.close();
        }
      });
    }
  }

  delete(id) {
    if (id) {
      const confirmDialog = new ConfirmDialog(
        'cancel',
        'Are you sure you want to continue?',
        '<p>By removing this, all API keys created for this Application will be removed too</p>',

        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            this.thirdPartyService.deleteThirdPartyClient(id).subscribe((res) => {
              if (res) {
                this.dataSource.removeItem = id;
                NotifyAppComponent.displayToast('success', 'Success!', 'Application is removed.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  enableGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialog = new ConfirmDialog(
        'cancel',
        'Enable Selected Applications',
        '<p>Once the Applications is enabled it will not appear as an option for the user.</p>',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              isActive: true,
              ids: this.selectedIDs.join(','),
            };
            this.thirdPartyService.modifyThirdPartyClientGroup(payload).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Applications are enabled.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  disableGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialog = new ConfirmDialog(
        'cancel',
        'Disable Selected Applications',
        '<p>Once the Application is disabled it will not appear as an option for the user.</p>',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              isActive: false,
              ids: this.selectedIDs.join(','),
            };
            this.thirdPartyService.modifyThirdPartyClientGroup(payload).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Applications are disabled.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  enable(ID) {
    const confirmDialog = new ConfirmDialog(
      'check_circle',
      'Enable Application',
      '<p>Once the Application is enabled it will  appear as an option for the user.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            isActive: true,
          };
          this.thirdPartyService.modifyThirdPartyClient(ID, payload).subscribe((res) => {
            if (res) {
              res.ID = ID;
              this.dataSource.replaceItem = res;

              NotifyAppComponent.displayToast('success', 'Success!', 'Application is enabled.');
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  disable(ID) {
    const confirmDialog = new ConfirmDialog(
      'cancel',
      'Disable Application',
      '<p>Once the Application is disabled it will not appear as an option for the user.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            isActive: false,
          };
          this.thirdPartyService.modifyThirdPartyClient(ID, payload).subscribe((res) => {
            if (res) {
              this.dataSource.replaceItem = res;

              NotifyAppComponent.displayToast('success', 'Success!', 'Application is disabled.');
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  openLink(d) {
    if (d) {
      if (d.indexOf('http') === -1) {
        const _d = 'https://' + d;
        window.open(_d, '_blank');
      } else {
        window.open(d, '_blank');
      }
    }
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  templates = [];
  public getTemplates = new EventEmitter();
  isDestoyed = false;

  serviceRef;

  constructor(private thirdPartyService: ThirdPartyService, private sessionType, private destroyEvent, private params) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    const payload = JSON.parse(JSON.stringify(params));

    payload.section = 0;
    payload.orderBy = 'Label';

    this.serviceRef = this.thirdPartyService.getThirdPartyClientList(payload, this.sessionType).subscribe((res) => {
      if (res) {
        for (let i = 0; i < res.length; i++) {
          res[i]['fullName'] = '';
          if (res[i] && res[i]['Label']) {
            res[i]['fullName'] = String(res[i]['fullName'] + res[i]['Label']).toLocaleLowerCase();
          }

          if (res[i] && res[i]['Description']) {
            res[i]['fullName'] = String(res[i]['fullName'] + res[i]['Description']).toLocaleLowerCase();
          }

          if (res[i] && res[i]['Template_Label']) {
            res[i]['fullName'] = String(res[i]['fullName'] + res[i]['Template_Label']).toLocaleLowerCase();
          }

          if (res[i] && res[i]['Email']) {
            res[i]['fullName'] = String(res[i]['fullName'] + res[i]['Email']).toLocaleLowerCase();
          }

          if (res[i] && res[i]['Phone']) {
            res[i]['fullName'] = String(res[i]['fullName'] + res[i]['Phone']).toLocaleLowerCase();
          }

          if (res[i] && res[i]['Address.Calculated']) {
            res[i]['fullName'] = String(res[i]['fullName'] + res[i]['Address.Calculated']).toLocaleLowerCase();
          }

          if (res[i] && res[i]['Website']) {
            res[i]['fullName'] = String(res[i]['fullName'] + res[i]['Website']).toLocaleLowerCase();
          }

          if (res[i] && res[i]['Template_Label'] && res[i]['Template_Key']) {
            const p = {
              Label: res[i]['Template_Label'],
              ID: res[i]['Template_Key'],
            };

            this.templates.push(p);
          }
        }
      }
      this.templates = _.uniqBy(this.templates, 'ID');
      this.getTemplates.emit(this.templates);

      this.count = res.length;
      this.items = res;
      this.dataChange.next(this.items.slice());
      this.firstLoad = true;
      const innerFunction = (section) => {
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.thirdPartyService.getThirdPartyClientList(payload, this.sessionType).subscribe((res) => {
          if (res.length > 0) {
            if (res) {
              for (let i = 0; i < res.length; i++) {
                res[i]['fullName'] = '';
                if (res[i] && res[i]['Label']) {
                  res[i]['fullName'] = String(res[i]['fullName'] + res[i]['Label']).toLocaleLowerCase();
                }

                if (res[i] && res[i]['Description']) {
                  res[i]['fullName'] = String(res[i]['fullName'] + res[i]['Description']).toLocaleLowerCase();
                }

                if (res[i] && res[i]['Template_Label']) {
                  res[i]['fullName'] = String(res[i]['fullName'] + res[i]['Template_Label']).toLocaleLowerCase();
                }

                if (res[i] && res[i]['Email']) {
                  res[i]['fullName'] = String(res[i]['fullName'] + res[i]['Email']).toLocaleLowerCase();
                }

                if (res[i] && res[i]['Phone']) {
                  res[i]['fullName'] = String(res[i]['fullName'] + res[i]['Phone']).toLocaleLowerCase();
                }

                if (res[i] && res[i]['Address.Calculated']) {
                  res[i]['fullName'] = String(res[i]['fullName'] + res[i]['Address.Calculated']).toLocaleLowerCase();
                }

                if (res[i] && res[i]['Website']) {
                  res[i]['fullName'] = String(res[i]['fullName'] + res[i]['Website']).toLocaleLowerCase();
                }

                if (res[i] && res[i]['Template_Label'] && res[i]['Template_Key']) {
                  const p = {
                    Label: res[i]['Template_Label'],
                    ID: res[i]['Template_Key'],
                  };

                  this.templates.push(p);
                }
              }
            }
            this.templates = _.uniqBy(this.templates, 'ID');
            this.getTemplates.emit(this.templates);

            this.count = res.count;
            this.items = this.items.concat(res);
            this.dataChange.next(this.items.slice());
            if (this.isDestoyed != true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };

      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  set addItem(res) {
    this._tableDatabase.data.unshift(res);

    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  set replaceItem(item) {
    const _index = this._tableDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    this._tableDatabase.data[_index] = item;
    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  set removeItem(id) {
    const data = this._tableDatabase.data.filter((row) => row.ID != id);
    this._tableDatabase.dataChange.next(data);
  }

  set removeItemByTaskID(taskID) {
    const data = this._tableDatabase.data.filter((row) => row['Task_key'] != taskID);
    this._tableDatabase.dataChange.next(data);
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
          if (this.field == 'Templates') {
            if (!this.filter || (this.filter && this.filter.length <= 0)) {
              return true;
            } else if (
              item &&
              item['Template_Key'] &&
              this.filter &&
              this.filter.length > 0 &&
              this.filter.indexOf(item['Template_Key']) !== -1
            ) {
              return true;
            }

            return false;
          } else if (this.field == 'Applications') {
            if (!this.filter || (this.filter && this.filter.length <= 0)) {
              return true;
            } else if (
              item &&
              item['Third_Party_Client_Key'] &&
              this.filter &&
              this.filter.length > 0 &&
              this.filter.indexOf(item['Third_Party_Client_Key']) !== -1
            ) {
              return true;
            }

            return false;
          } else {
            let f = '';
            f = item[this.field] || '';

            const searchStr = f.toLowerCase();

            return searchStr.indexOf(this.filter.toLowerCase()) != -1;
          }
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'Label':
          [propertyA, propertyB] = [a['Label'], b['Label']];
          break;

        case 'Description':
          [propertyA, propertyB] = [a['Task.Description'], b['Task.Description']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
