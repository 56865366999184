import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { WizardComponent } from 'angular-archwizard';
import { InvitationTemplateLookupComponent } from '../../../feature/invitation-template/invitation-template-lookup/invitation-template-lookup.component';
import { InvitationTemplateMergerComponent } from '../../../feature/invitation-template/invitation-template-merger/invitation-template-merger.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { ThirdPartyService } from '../shared/third-party.service';
import { ThirdPartyClientViewComponent } from '../third-party-client-view/third-party-client-view.component';

@Component({
  selector: 'app-third-party-client-create-edit',
  templateUrl: './third-party-client-create-edit.component.html',
  styleUrls: ['./third-party-client-create-edit.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ThirdPartyClientCreateEditComponent implements OnInit {
  @Input()
  thirdPartyClientID;

  @Input()
  thirdPartyClient;

  updateAll = true;

  generateNew = false;

  @Input()
  createdRecord;

  @ViewChild('wizard', { static: false }) wizard: WizardComponent;

  isAdressValid = false;
  isPhoneValid = false;
  isEmailValid = false;
  sessionType;

  label;
  description;
  isActive = true;
  website;

  _phone;
  _email;
  _suburb;
  _state;
  _postCode;
  _unitNumber;
  _property;
  _streetNumber;
  _streetName;
  _streetType;
  _streetFullType;

  templateIDOld;

  phone;
  email;

  suburb;
  state;
  postCode;
  unitNumber;
  property;
  streetNumber;
  streetName;
  streetType;
  streetFullType;

  facebook;
  instagram;
  twitter;
  linkedIn;
  pinterest;
  tumblr;
  vimeo;
  youtube;

  templateID;
  templateLabel;

  loadAddress = false;
  displayManual = false;
  isManual = false;

  secretKey;

  secretID;

  pinchZoomProperties = [
    {
      wheel: true,
      autoHeight: true,
    },
  ];

  settings = Settings.global;
  utils = new UtilsClass();
  postPictureLink;
  postPicture;

  maxFileSize = 5;
  allowExtensions = ['jpeg', 'jpg', 'png','heic', 'gif', 'tiff', 'bmp', 'ico'];
  applyDownload = new EventEmitter();

  loadingPost = false;
  fileID;

  constructor(
    private authenticationService: AuthenticationService,
    private thirdPartyService: ThirdPartyService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    UtilsClass.scrollUp();
    this.activeRoute.params.subscribe((params) => {
      if (params['thirdPartyClientID']) {
        this.thirdPartyClientID = params['thirdPartyClientID'];
      }
      this.authenticationService.getSessionType().subscribe((res) => {
        this.sessionType = res;

        this.setup();
      });
    });
  }

  setup() {
    if (this.thirdPartyClientID) {
      this.thirdPartyService
        .getThirdPartyClientDetails(this.thirdPartyClientID, {}, this.sessionType)
        .subscribe((res) => {
          if (res) {
            this.thirdPartyClient = res;

            this.buildItem();
          }
        });
    } else {
      this.loadAddress = true;
      this.displayManual = true;
    }
  }

  buildItem() {
    if (this.thirdPartyClient && this.thirdPartyClient.ID) {
      this.postPicture = this.thirdPartyService.getThirdPartyClientImageStreamLink(this.thirdPartyClient.ID);

      if (this.thirdPartyClient['Label']) {
        this.label = this.thirdPartyClient['Label'];
      }

      if (this.thirdPartyClient['Description']) {
        this.description = this.thirdPartyClient['Description'];
      }

      if (this.thirdPartyClient['Is_Active'] === '0') {
        this.isActive = false;
      } else if (this.thirdPartyClient['Is_Active'] === '1') {
        this.isActive = true;
      }

      if (this.thirdPartyClient['Website']) {
        this.website = this.thirdPartyClient['Website'];
      }

      if (this.thirdPartyClient['Phone']) {
        this._phone = this.thirdPartyClient['Phone'];
      }

      if (this.thirdPartyClient['Template_Key']) {
        this.templateID = this.thirdPartyClient['Template_Key'];
        this.templateIDOld = this.thirdPartyClient['Template_Key'];
      }

      if (this.thirdPartyClient['Template_Label']) {
        this.templateLabel = this.thirdPartyClient['Template_Label'];
      }

      if (this.thirdPartyClient['Secrect_ID']) {
        this.secretID = this.thirdPartyClient['Secrect_ID'];
      }

      if (this.thirdPartyClient['Secrect_Key']) {
        this.secretKey = this.thirdPartyClient['Secrect_Key'];
      }

      if (this.thirdPartyClient['Email']) {
        this._email = this.thirdPartyClient['Email'];
      }

      if (this.thirdPartyClient['Address.Suburb']) {
        this._suburb = this.thirdPartyClient['Address.Suburb'];
      }

      if (this.thirdPartyClient['Address.State']) {
        this._state = this.thirdPartyClient['Address.State'];
      }

      if (this.thirdPartyClient['Address.Postcode']) {
        this._postCode = this.thirdPartyClient['Address.Postcode'];
      }

      if (this.thirdPartyClient['Address.UnitNumber']) {
        this._unitNumber = this.thirdPartyClient['Address.UnitNumber'];
      }

      if (this.thirdPartyClient['Address.Property']) {
        this._property = this.thirdPartyClient['Address.Property'];
      }
      if (this.thirdPartyClient['Address.Street Nr']) {
        this._streetNumber = this.thirdPartyClient['Address.Street Nr'];
      }
      if (this.thirdPartyClient['Address.Street Name']) {
        this._streetName = this.thirdPartyClient['Address.Street Name'];
      }
      if (this.thirdPartyClient['Address.Street Type']) {
        this._streetType = this.thirdPartyClient['Address.Street Type'];
      }
      if (this.thirdPartyClient['address.Full Street Type']) {
        this._streetFullType = this.thirdPartyClient['address.Full Street Type'];
      }
      if (this.thirdPartyClient['Facebook']) {
        this.facebook = this.thirdPartyClient['Facebook'];
      }
      if (this.thirdPartyClient['Instagram']) {
        this.instagram = this.thirdPartyClient['Instagram'];
      }
      if (this.thirdPartyClient['Twitter']) {
        this.twitter = this.thirdPartyClient['Twitter'];
      }
      if (this.thirdPartyClient['LinkedIn']) {
        this.linkedIn = this.thirdPartyClient['LinkedIn'];
      }
      if (this.thirdPartyClient['Pinterest']) {
        this.pinterest = this.thirdPartyClient['Pinterest'];
      }
      if (this.thirdPartyClient['Tumblr']) {
        this.tumblr = this.thirdPartyClient['Tumblr'];
      }

      if (this.thirdPartyClient['Vimeo']) {
        this.vimeo = this.thirdPartyClient['Vimeo'];
      }
      if (this.thirdPartyClient['YouTube']) {
        this.youtube = this.thirdPartyClient['YouTube'];
      }
    }

    this.loadAddress = true;
  }

  onComplete(fileID) {
    if (fileID && fileID[0]) {
      this.fileID = fileID[0];
      this.postPicture = null;
      this.postPictureLink = this.utils.getFileLink(this.fileID);
    } else {
      this.postPictureLink = null;
    }
  }

  openTemplateLookup() {
    const ref = RootAppComponent.dialog.open(InvitationTemplateLookupComponent, {
      data: {
        type: 'default',
        isModal: true,
        viewOnClick: false,
        viewOnly: false,
        hideTreatmentValue: true,
        isInvitationContext: false,
      },
      panelClass: 'bigger-screen',
      width: '820px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getTemplate.subscribe((result) => {
      if (result && result._id && result.label) {
        this.templateID = result._id;
        this.templateLabel = result.label;
        ref.close();
      }
    });
  }

  viewTemplate(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(InvitationTemplateMergerComponent, {
        data: {
          templateID: id,
          viewOnly: true,
          hideTreatmentValue: true,
        },
        width: '1200px',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  getAddress(adr) {
    if (adr) {
      this.suburb = adr.suburb;
      this.state = adr.state;
      this.postCode = adr.postCode;
      this.streetNumber = adr.streetNumber;
      this.streetName = adr.streetName;
      this.unitNumber = adr.unitNumber;
      this.streetType = adr.streetType;
    }
  }

  isAdressValidEvent(v) {
    this.isAdressValid = v || false;
  }

  isPhoneValidEvent(v) {
    this.isPhoneValid = v || false;
  }

  isEmailValidEvent(v) {
    this.isEmailValid = v || false;
  }

  getPhone(d) {
    this.phone = d;
  }

  getEmail(d) {
    this.email = d;
  }

  proceed() {
    UtilsClass.scrollUp();
    this.wizard.goToNextStep();
  }

  save() {
    const p = {
      label: this.label,
      description: this.description,
      isActive: this.isActive,
      website: this.website,
      phone: this.phone,
      email: this.email,
      suburb: this.suburb,
      state: this.state,
      postCode: this.postCode,
      unitNumber: this.unitNumber,
      property: this.property,
      streetNumber: this.streetNumber,
      streetName: this.streetName,
      streetType: this.streetType,
      streetFullType: this.streetFullType,
      facebook: this.facebook,
      instagram: this.instagram,
      twitter: this.twitter,
      linkedIn: this.linkedIn,
      pinterest: this.pinterest,
      tumblr: this.tumblr,
      vimeo: this.vimeo,
      youtube: this.youtube,
      secretKey: this.secretKey,
      secretID: this.secretID,
      fileID: this.fileID,
      generateNew: this.generateNew,
      templateID: this.templateID,
      templateLabel: this.templateLabel,
      updateAll: this.updateAll,
    };

    if (this.thirdPartyClientID) {
      this.thirdPartyService.modifyThirdPartyClient(this.thirdPartyClientID, p).subscribe((res) => {
        if (res) {
          this.createdRecord = res;

          NotifyAppComponent.displayToast('success', 'Success!', 'Application is Modified');
          this.proceed();
        }
      });
    } else {
      this.thirdPartyService.createThirdPartyClient(p).subscribe((res) => {
        if (res) {
          this.createdRecord = res;

          NotifyAppComponent.displayToast('success', 'Success!', 'Application is Modified');
          this.proceed();
        }
      });
    }
  }

  clientList() {
    this.router.navigate(['/merchant', { outlets: { page: ['third-party-client-list'] } }]);
  }

  view(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(ThirdPartyClientViewComponent, {
        data: {
          thirdPartyClientID: id,
        },
        width: '700px',
        panelClass: 'noCard',
        height: '1000px',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }
}
