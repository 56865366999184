import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { HeaderService } from '../../../shared/services/header.service';
import { MarketingService } from '../shared/marketing.service';
import { MarketingUtilClass } from '../shared/marketingUtil';
/**
 * @title Feature-rich data table
 */
@Component({
  selector: 'app-marketing-list-overview',
  templateUrl: './marketing-list-overview.component.html',
  styleUrls: ['./marketing-list-overview.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MarketingListOverViewComponent implements OnInit {
  isAdminOrPromoter = null;

  count;

  @Input()
  dateFrom;
  @Input()
  dateTo;
  @Input()
  merchantID = 'none';

  isTest = false;
  statusArray = ['PEND', 'TIMD', 'CMPL', 'CNCL,ABRT', 'expired'];

  @Input()
  status = 'CMPL';

  @Output()
  campaignID = new EventEmitter();

  stats = {};

  marketingUtil = new MarketingUtilClass();

  successedEmail = 0;
  successedSMS = 0;

  failedEmail = 0;
  failedSMS = 0;

  allEmail = 0;
  allSMS = 0;

  allTarget = 0;
  successedTarget = 0;
  failedTarget = 0;

  constructor(private AuthenticationService: AuthenticationService, private marketingService: MarketingService) {}

  ngOnInit() {
    this.AuthenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isAdminOrPromoter = res;

      if (this.isAdminOrPromoter == true) {
        const _mID = JSON.parse(localStorage.getItem('selectedMerchant'));
        if (_mID) {
          this.merchantID = _mID;
        }

        HeaderService.getPromoterViewAsObject().subscribe((res) => {
          if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'promoter') {
            this.merchantID = null;
          } else if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'merchant') {
            this.merchantID = res['merchantID'];
          }
        });
      }

      this.getStats();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getStats();
  }

  logit(a, b) {}

  getClass(s) {
    if (this.status == s) {
      return 'selectStatus';
    } else {
      return '';
    }
  }
  getStats() {
    let payload;

    this.count = null;
    if (this.isAdminOrPromoter == true) {
      payload = {
        dateTo: this.dateTo,
        dateFrom: this.dateFrom,
        status: this.status,
      };
      if (this.merchantID && this.merchantID != 'none') {
        payload.merchantID = this.merchantID;
      }
    } else {
      payload = {
        dateTo: this.dateTo,
        dateFrom: this.dateFrom,
        status: this.status,
      };
    }
    this.marketingService.getStatistics(payload, this.isAdminOrPromoter).subscribe((res) => {
      if (res) {
        if (res['global']) {
          this.stats = res['global'];
          if (this.stats['Sum(Targets.Distinct)']) {
            this.allTarget = Number(this.stats['Sum(Targets.Distinct)']);
          }
          if (this.stats['Sum(Reached.Distinct)']) {
            this.successedTarget = Number(this.stats['Sum(Reached.Distinct)']);
          }
          if (this.stats['Sum(Targets.Distinct)'] && this.stats['Sum(Reached.Distinct)']) {
            this.failedTarget =
              Number(this.stats['Sum(Targets.Distinct)']) - Number(this.stats['Sum(Reached.Distinct)']);
          }
          if (this.stats['Sum(Reached.Email)']) {
            this.allEmail = Number(this.stats['Sum(Reached.Email)']);
          }
          if (this.stats['Sum(Sent.Email)']) {
            this.successedEmail = Number(this.stats['Sum(Sent.Email)']);
          }
          this.failedEmail = this.allEmail - this.successedEmail;
          if (this.stats['Sum(Reached.SMS)']) {
            this.allSMS = Number(this.stats['Sum(Reached.SMS)']);
          }
          if (this.stats['Sum(Sent.SMS)']) {
            this.successedSMS = Number(this.stats['Sum(Sent.SMS)']);
          }
          this.failedSMS = this.allSMS - this.successedSMS;
        }
      }
    });
  }

  toNumber(n) {
    if (n) {
      return Number(n);
    } else {
      return 0;
    }
  }

  getID(e) {
    this.campaignID.emit(e);
  }

  getCount(count) {
    this.count = count;
  }
}
