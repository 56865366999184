import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  WISHLIST_CATEGORIES,
  WishlistCategory,
  WishlistItemLabelExistsValidator,
  WishlistService,
} from '../../services/wishlist.service';

export class WishlistManagerModalConfig {
  Code: WishlistCategory | 'all';
  set?: WishlistManagerModalResult;
}

export class WishlistManagerModalResult {
  ID?: string;
  Code: WishlistCategory;
  Label: string;
  Description: string;
}

@Component({
  selector: 'ipv-ventures-smile-makeover-modal-view',
  templateUrl: './wishlist-manager-modal.component.html',
  styleUrls: ['./wishlist-manager-modal.component.css'],
})
export class WishlistManagerModalComponent implements OnInit {
  @Output() submitted = new EventEmitter<WishlistManagerModalResult>();

  mode: 'create' | 'update' = 'create';

  categories = WISHLIST_CATEGORIES;

  formGroup = new FormGroup({
    Label: new FormControl(
      null,
      [Validators.required, Validators.minLength(2)],
      [WishlistItemLabelExistsValidator.createValidator(this.wishlistService)]
    ),
    Code: new FormControl(null, Validators.required),
    Description: new FormControl(null, Validators.required),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: WishlistManagerModalConfig,
    private wishlistService: WishlistService
  ) {}

  get Label() {
    return this.formGroup.get('Label');
  }

  get Code() {
    return this.formGroup.get('Code');
  }

  get Description() {
    return this.formGroup.get('Description');
  }

  ngOnInit() {
    if (this.data) {
      if (this.data.Code !== 'all') {
        this.formGroup.patchValue({
          Code: this.data.Code,
        });
      }

      if (this.data.set) {
        this.mode = 'update';

        this.formGroup.patchValue({
          Label: this.data.set.Label,
          Code: this.data.set.Code,
          Description: this.data.set.Description,
        });
      }
    }
  }

  save() {
    const Label = this.formGroup.get('Label').value;

    const Code = this.formGroup.get('Code').value as WishlistCategory;

    const Description = this.formGroup.get('Description').value;

    const result: WishlistManagerModalResult =
      this.mode === 'create' ? { Label, Code, Description } : { Label, Code, Description, ID: this.data.set.ID };

    this.submitted.emit(result);
  }
}
