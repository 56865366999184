<h2 class="sr-title">Users Management</h2>

<div class="well treatment-block">
  <div class="clearfix">
    <div>
      <mat-tab-group dynamicHeight="true">
        <mat-tab>
          <ng-template mat-tab-label> Active Sessions</ng-template>
          <app-active-sessions> </app-active-sessions>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label> Connection History</ng-template>
          <app-connection-history></app-connection-history>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label> Operators Management</ng-template>
          <app-operator-list> </app-operator-list>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
