import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UtilsService } from '../../../../../../common/shared/services/utils.service';
import { Settings } from '../../../../../../common/shared/types/settings';

@Component({
  selector: 'app-dashboard-intro-modal',
  templateUrl: './dashboard-intro-modal.component.html',
  styleUrls: ['./dashboard-intro-modal.component.css'],
})
export class DashboardIntroModalComponent implements OnInit {
  showOnDashLogin = JSON.parse(localStorage.getItem('showOnDashLogin'));

  isModuleTutorialsActive = Settings.global['isModuleTutorialsActive'];
  @Output()
  closeModal = new EventEmitter();

  // @Output()
  // startTour = new EventEmitter();

  constructor(private utilService: UtilsService) {}

  ngOnInit() {
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleTutorialsActive = access['isModuleTutorialsActive'];
      }
    });
  }

  doNotShow() {
    localStorage.setItem('showOnDashLogin', JSON.stringify(this.showOnDashLogin));

    this.closeModal.emit(this.showOnDashLogin);
  }

  // startTheTour() {
  //   this.startTour.emit(this.startTour);
  // }
}
