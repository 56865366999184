<div class="rel">
  <aw-wizard #wizard [disableNavigationBar]="true" navBarLayout="large-filled-symbols">
    <aw-wizard-step
      [stepTitle]="('KEYWORD.patient' | translate | titlecase) + ' information'"
      [navigationSymbol]="{ symbol: start }"
      awOptionalStep
    >
      <!-- Step 1  -->
      <div class="container" id="step-1">
        <div class="row clearfix">
          <div class="full-width rel">
            <div class="text-center">
              <h2 class="sr-title inline-block pull-left" *ngIf="!patientID">New AI Scan</h2>
              <!-- Patient Lookup Trigger -->
              <button
                class="btn-small mt mb pull-right"
                id="searchPatient"
                *ngIf="displayLookup == true"
                (click)="openPatientLookUpDialog()"
                mat-raised-button
                color="primary"
              >
                {{"KEYWORD.patient" | translate | titlecase}} Lookup
                <mat-icon>contacts</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <mat-card>
          <div class="colour-stripe"></div>

          <div class="clearfix" *ngIf="isAdminOrPromoter == true && displayLookup == true">
            <p class="help-label">
              To send an invitation on behalf of a merchant select a
              merchant below, otherwise, the invitation will be sent by the promoter.
            </p>
            <div class="row clearfix selectMerchant">
              <div class="icons-col">
                <mat-icon class="fas fa-store-alt"></mat-icon>
              </div>
              <div class="row-content-col">
                <mat-form-field class="margin-btm half-width" appearance="outline">
                  <mat-label>Please Select a Merchant</mat-label>
                  <mat-select
                    class=""
                    [(ngModel)]="merchant"
                    (ngModelChange)="changeMerchant($event)"
                    name="MerchantName"
                    placeholder="Merchant"
                  >
                    <mat-option [value]="null"> My Account</mat-option>
                    <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m">
                      <span
                        class="list-label pull-right"
                        *ngIf="m['Status'] && m['Status'] == 'Active'"
                        [style.background]="'#00d07a'"
                      >
                        {{ m['Status'] }}
                      </span>

                      <span
                        class="list-label pull-right"
                        *ngIf="m['Status'] && m['Status'] != 'Active'"
                        [style.background]="'#ff5722'"
                      >
                        {{ m['Status'] }}
                      </span>
                      {{ m['TradingAs'] || m['CalculatedName'] }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <button
                  class="pull-right merchBtn"
                  *ngIf="merchant"
                  (click)="viewMerchant()"
                  mat-raised-button
                  color="primary"
                >
                  <mat-icon>person</mat-icon>
                  <span class="mobHide">View Merchant</span>
                </button>
              </div>
            </div>
            <hr class="mt mb"/>
          </div>

          <p class="help-label">
            Please start by entering the details of your {{ "KEYWORD.patient" | translate }}. You can use the '{{
            'KEYWORD.patient' | translate | titlecase
            }}
            Lookup' button to search your existing {{ "KEYWORD.patient" | translate }} records.
          </p>
          <form #createForm="ngForm">
            <div class="row clearfix">
              <!-- <div class="half-width"> -->
              <div class="row clearfix">
                <div class="row-content-col">
                  <div class="clearfix">
                    <div class="row clearfix flex">
                      <!-- Gender Select -->
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Gender</mat-label>
                        <mat-select
                          class=""
                          [(ngModel)]="patient.genderCode"
                          [disabled]="isFinDMatch"
                          name="gender"
                          placeholder="Gender"
                          required
                        >
                          <mat-option *ngFor="let gender of genders" [value]="gender.Code">
                            {{ gender.Label }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <!-- Title Select -->
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Title</mat-label>
                        <mat-select
                          class="full-width"
                          [(ngModel)]="patient.title"
                          [disabled]="isFinDMatch"
                          name="title"
                          placeholder="Title"
                        >
                          <mat-option *ngFor="let title of titles" [value]="title.Label">
                            {{ title.Label }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row clearfix flex">
                    <!-- First Name -->
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>First Name</mat-label>
                      <input
                        class="mat-block"
                        [(ngModel)]="patient.firstName"
                        [disabled]="isFinDMatch"
                        name="firstName"
                        matInput
                        [placeholder]="('PLACEHOLDER.First Name' | translate)"
                        required
                      />
                    </mat-form-field>

                    <!-- Middle Name -->
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Middle Name</mat-label>
                      <input
                        class="mat-block"
                        [(ngModel)]="patient.middleName"
                        [disabled]="isFinDMatch"
                        name="middleName"
                        matInput
                        [placeholder]="('PLACEHOLDER.Middle Name' | translate)"
                      />
                    </mat-form-field>

                    <!-- Surname -->
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Surname</mat-label>
                      <input
                        class="mat-block"
                        [(ngModel)]="patient.lastName"
                        [disabled]="isFinDMatch"
                        name="lastName"
                        matInput
                        [placeholder]="('PLACEHOLDER.Surname' | translate)"
                        required
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <!-- </div> -->
              <hr class="mt mb" />


              <div class="patient-details mt" #patientContactBlock>
                <div class="row clearfix">
                  <div class="icons-col">
                    <mat-icon class="">phone</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <div class="row clearfix flex">

                      <app-phone-input
                        class="full-width"
                        [clear]="clearMobile"
                        [phoneNumber]="patient.mobile"
                        [label]="'Mobile'"
                        [required]="true"
                        [isDisabled]="isFinDMatch"
                        (validate)="validateMobile($event)"
                        (getPhone)="getMobile($event)"
                      >
                      </app-phone-input>

                      <app-phone-input
                        class="full-width"
                        [clear]="clearHomePhone"
                        [phoneNumber]="patient.homePhone"
                        [isLandLine]="true"
                        [label]="'Home'"
                        [required]="false"
                        [isDisabled]="isFinDMatch"
                        (validate)="validateHomePhone($event)"
                        (getPhone)="getHomePhone($event)"
                      >
                      </app-phone-input>

                      <app-phone-input
                        class="full-width"
                        [clear]="clearWorkPhone"
                        [phoneNumber]="patient.workPhone"
                        [isLandLine]="true"
                        [label]="'Work'"
                        [required]="false"
                        [isDisabled]="isFinDMatch"
                        (validate)="validateWorkPhone($event)"
                        (getPhone)="getWorkPhone($event)"
                      >
                      </app-phone-input>
                    </div>
                  </div>
                </div>
                <div class="row clearfix">
                  <div class="icons-col">
                    <mat-icon>email</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <!-- Email Address -->
                    <app-email-input
                      class="full-width"
                      [clear]="clearEmail"
                      [email]="patient.email"
                      [label]="'Email'"
                      [required]="true"
                      [isDisabled]="isFinDMatch"
                      (getEmail)="getEmail($event)"
                      (validate)="validateEmail($event)"
                    >
                    </app-email-input>
                  </div>
                </div>

                <div class="row clearfix">
                  <div class="icons-col">
                    <mat-icon>account_circle</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <app-date-input
                      class="full-width"
                      [defaultDate]="patient.dateOfBirth"
                      [clear]="clearDate"
                      [startView]="'multi-year'"
                      [disabled]="isFinDMatch"
                      [disabledAll]="isFinDMatch"
                      [isTime]="false"
                      [maxDate]="'toDay'"
                      (selectedDate)="selectDateToFilter($event)"
                      label="Date of birth*"
                    >
                    </app-date-input>
                  </div>
                </div>

                <div
                  class="row clearfix"
                  *ngIf="
                    (!patient.genderCode ||
                      !isEmailValid ||
                      !patient._dateOfBirth ||
                      !isMobileValid ||
                      !isHomePhoneValid ||
                      !isWorkPhoneValid) &&
                    isFinDMatch == true &&
                    patientID
                  "
                >
                  <div class="icons-col">
                    <mat-icon class="error-invitation" (click)="modifyProfile()">info_outline</mat-icon>
                  </div>
                  <div class="row-content-col row clearfix flex diag">
                    <p class="small mt warning" (click)="modifyProfile()">
                      The {{ "KEYWORD.patient" | translate }} you've selected is missing some details. Please click
                      'modify' to update the {{ "KEYWORD.patient" | translate }}'s profile.
                    </p>
                  </div>
                </div>

                <button
                  class="pull-right mat-stroked-mini-fab mat-elevation-z1"
                  *ngIf="isFinDMatch == true && patientID"
                  [matTooltip]="'Clear ' + ('KEYWORD.patient' | translate | titlecase) + ' Details'"
                  (click)="clearPatientDetails()"
                  mat-stroked-button
                  color="accent"
                >
                  <mat-icon class="fas fa-times"></mat-icon>
                </button>
              </div>
            </div>
          </form>
        </mat-card>

        <div class="row clearfix">
          <div class="full-width text-center step-button-row">
            <button
              class="step-back"
              [routerLink]="['/merchant/', { outlets: { page: ['home'] } }]"
              mat-mini-fab
              color="primary"
              matTooltip="Return to dashboard"
            >
              <mat-icon>home</mat-icon>
            </button>

            <button
              class="btn-large"
              *ngIf="isFinDMatch == false"
              [disabled]="
                !createForm.form.valid ||
                !isEmailValid ||
                !patient._dateOfBirth ||
                !isMobileValid ||
                !isHomePhoneValid ||
                !isWorkPhoneValid
              "
              (click)="step1()"
              mat-raised-button
              color="accent"
            >
              Next
              <mat-icon>chevron_right</mat-icon>
            </button>

            <button
              class="btn-large"
              *ngIf="isFinDMatch == true && patientID"
              [disabled]="
                !patient.genderCode ||
                !isEmailValid ||
                !patient._dateOfBirth ||
                !isMobileValid ||
                !isHomePhoneValid ||
                !isWorkPhoneValid
              "
              (click)="step1()"
              mat-raised-button
              color="accent"
            >
              Next
              <mat-icon>chevron_right</mat-icon>
            </button>

            <button
              class="pull-right btn-large"
              *ngIf="isFinDMatch == true && patientID"
              (click)="modifyProfile()"
              mat-raised-button
              color="primary"
              matTooltip="Edit profile"
            >
              <mat-icon>edit</mat-icon>
              Modify
            </button>
          </div>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step [navigationSymbol]="{ symbol: start + 1 }" stepTitle="Upload Xrays">
      <!--  Step 2  -->
      <h2 class="sr-title inline-block text-center">Add xray images and submit!</h2>

      <div class="container" id="step-2">
        <div class="row clearfix">
          <ipv-wizard-step-card
            *ngIf="patient"
            [title]="'Upload Dental Xray'"
            [instructions]="'Upload bitewing xray images or a panoramic xray image for scanning'"
          >
            <div class="sr-dental-xray-image-uploader-container">
              <mat-form-field class="sr-dental-xray-image-uploader-selector" appearance="outline">
                <mat-label>Select a dental xray type...</mat-label>
                <mat-select
                  class="full-width"
                  [(ngModel)]="selectedXrayOptionType"
                  (ngModelChange)="setXrayOptionType($event)"
                  placeholder="Select a dental xray type..."
                >
                  <mat-option *ngFor="let xrayOptionType of xrayOptionTypes" [value]="xrayOptionType">
                    {{ xrayOptionType | titlecase }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <ng-container *ngIf="!isDisabled; else xrayDisabled">
                <ng-container *ngIf="selectedXrayOptionType === 'panoramic'; else biteWingsDragAndDrop">
                  <app-file-uploader
                    [hasCustomTitleText]="true"
                    [allowedExtensions]="['png', 'jpg', 'jpeg']"
                    [displayToaster]="false"
                    [documentType]="'Scan / X-Ray'"
                    [fileDescription]="currentFileDescriptionPanoramic"
                    [fileName]="currentFileNamePanoramic"
                    [limit]="1"
                    (onCompleteAll)="getXrayImageId($event[0], 'panoramic')"
                  >
                    Drag and Drop or Click to upload <b> Panoramic </b> Xray image
                  </app-file-uploader>

                  <div class="clearfix" style="margin-top: 20px">
                    <app-file-list
                      class="text-center centered"
                      [title]="null"
                      [justify]="'center'"
                      [documentTypes]="['Scan / X-Ray']"
                      [canAction]="false"
                      [canEdit]="true"
                      [hasExtraInfo]="false"
                      [files]="getPanoramicImageIds()"
                      (removed)="removePanoramicXrayImage()"
                    >
                    </app-file-list>
                  </div>
                </ng-container>

                <ng-template #biteWingsDragAndDrop>
                  <div class="bitewing-uploader">
                    <div class="bitewing-uploader-left">
                      <app-file-uploader
                        [hasCustomTitleText]="true"
                        [allowedExtensions]="['png', 'jpg', 'jpeg']"
                        [displayToaster]="false"
                        [documentType]="'Scan / X-Ray'"
                        [fileDescription]="currentFileDescriptionLeft"
                        [fileName]="currentFileNameLeft"
                        [isTwoThirdWidth]="false"
                        [limit]="1"
                        (onCompleteAll)="getXrayImageId($event[0], 'left')"
                      >
                        Drag and Drop or Click to upload <b> Left Bitewing </b> Xray image
                      </app-file-uploader>

                      <div class="clearfix" style="margin-top: 20px">
                        <app-file-list
                          class="text-center centered"
                          [isModal]="true"
                          [title]="null"
                          [justify]="'center'"
                          [documentTypes]="['Scan / X-Ray']"
                          [canAction]="false"
                          [canEdit]="true"
                          [hasExtraInfo]="false"
                          [files]="getLeftBitewingImageIds()"
                          (removed)="removeLeftBitewingXrayImage()"
                        >
                        </app-file-list>
                      </div>
                    </div>

                    <div class="bitewing-uploader-right">
                      <app-file-uploader
                        [hasCustomTitleText]="true"
                        [allowedExtensions]="['png', 'jpg', 'jpeg']"
                        [displayToaster]="false"
                        [documentType]="'Scan / X-Ray'"
                        [fileDescription]="currentFileDescriptionRight"
                        [fileName]="currentFileNameRight"
                        [limit]="1"
                        [isTwoThirdWidth]="false"
                        (onCompleteAll)="getXrayImageId($event[0], 'right')"
                      >
                        Drag and Drop or Click to upload <b> Right Bitewing </b> Xray image
                      </app-file-uploader>

                      <div class="clearfix" style="margin-top: 20px">
                        <app-file-list
                          class="text-center centered"
                          [isModal]="true"
                          [title]="null"
                          [justify]="'center'"
                          [documentTypes]="['Scan / X-Ray']"
                          [canAction]="false"
                          [canEdit]="true"
                          [hasExtraInfo]="false"
                          [files]="getRightBitewingImageIds()"
                          (removed)="removeRightBitewingXrayImage()"
                        >
                        </app-file-list>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ng-container>

              <ng-template #xrayDisabled>
                <app-file-uploader [isDisabled]="true" [title]="'Waiting for xray type...'"></app-file-uploader>
              </ng-template>
            </div>
          </ipv-wizard-step-card>
        </div>

        <div class="row clearfix">
          <div class="full-width step-button-row text-center">
            <button
              class="step-back"
              mat-mini-fab
              color="primary"
              awPreviousStep
            >
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>

            <button
              class="btn-large"
              [disabled]="
                !(
                  dentalXrayScanResult.imageIDPanoramic ||
                  (dentalXrayScanResult.imageIDLeft && dentalXrayScanResult.imageIDRight)
                )
              "
              (click)="submitXray()"
              mat-raised-button
              color="accent"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <!--  / Step 2 -->
    </aw-wizard-step>

    <aw-wizard-step [navigationSymbol]="{ symbol: start + 2 }" stepTitle="AI Scan Report Demo">
      <!--  Step 3  -->
      <h2 class="sr-title inline-block text-center">AI Scan Results</h2>

      <div class="container" id="step-3">
        <div class="row clearfix">
          <app-ai-scan-report-view
            *ngIf="isAiScanReportReady"
            [patient]="patient"
            [dentalXrayScanResult]="dentalXrayScanResult"
          ></app-ai-scan-report-view>
        </div>

        <div class="row clearfix">
          <div class="full-width step-button-row text-center">
            <button
              class="step-back"
              mat-mini-fab
              color="primary"
              awPreviousStep
            >
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>

            <a
              class="btn-large"
              [routerLink]="['/merchant/', { outlets: { page: ['ai-scans-list-overview'] } }]"
              mat-raised-button
              color="accent"
            >View Scans List
            </a>
          </div>
        </div>
      </div>
      <!--  / Step 3 -->
    </aw-wizard-step>
  </aw-wizard>
</div>
