import { NgForm } from '@angular/forms';
import { ComponentCanDeactivate } from './component-can-deactivate';

export abstract class FormCanDeactivate extends ComponentCanDeactivate {
  abstract get form(): NgForm;
  abstract get addForm(): NgForm;
  abstract get emergencyForm(): NgForm;
  abstract get contactForm(): NgForm;
  abstract get preferenceForm(): NgForm;
  abstract get idForm(): NgForm;
  abstract get addressForm(): NgForm;

  canDeactivate(): boolean {
    return this.form.submitted || !this.form.dirty;
  }
  canDeactivateAdd(): boolean {
    return this.addForm.submitted || !this.addForm.dirty;
  }
  canDeactivateEmerg(): boolean {
    return this.emergencyForm.submitted || !this.emergencyForm.dirty;
  }
  canDeactivateContact(): boolean {
    return this.contactForm.submitted || !this.contactForm.dirty;
  }
  canDeactivatePref(): boolean {
    return this.preferenceForm.submitted || !this.preferenceForm.dirty;
  }
  canDeactivateAddress(): boolean {
    return this.addressForm.submitted || !this.addressForm.dirty;
  }
  canDeactivateID(): boolean {
    return this.idForm.submitted || !this.idForm.dirty;
  }
}
