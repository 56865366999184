import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-details-card-view',
  templateUrl: './details-card-view.component.html',
  styleUrls: ['./details-card-view.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('mat-card', style({ opacity: 0 }), { optional: true }),
        query(
          'mat-card',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: '0' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('mat-card', style({ opacity: 1 }), { optional: true }),
        query(
          'mat-card',
          stagger('100ms', [
            animate(
              '0.2s ease-out',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: '0' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class DetailsCardViewComponent implements OnInit {
  // pages

  page = 'profilePage';

  // profile
  mainInformation = true;
  addressDetails = true;
  contactDetails = true;
  preferencesDetails = true;
  profileImage = true;

  constructor() {}

  ngOnInit() {}
}
