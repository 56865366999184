import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { trigger, style, transition, animate, keyframes, query, stagger, state } from '@angular/animations';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { MAT_DIALOG_DATA, MatAutocomplete, MatAutocompleteSelectedEvent, MatChipInputEvent } from '@angular/material';
import { TagsService } from '../../tags/shared/tags.service';
import { Router } from '@angular/router';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { MessageGroupCardListComponent } from '../../message/message-group-card-list/message-group-card-list.component';

import * as _ from 'lodash';
import { LookupService } from '../../../shared/services/lookup.service';
import { ContractService } from '../shared/contract.service';
import { InvitationLookupComponent } from '../../invitation/invitation-lookup/invitation-lookup.component';
import { CustomerProspectViewModelComponent } from '../../customer-prospect/customer-prospect-view-model/customer-prospect-view-model.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
@Component({
  selector: 'app-contract-list-server',
  templateUrl: './contract-list-server.component.html',
  styleUrls: ['./contract-list-server.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, width: '*' })),
      transition(':enter', [style({ opacity: 0, width: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, width: 0 }))),
    ]),
  ],
})
export class ContractListServerComponent implements OnInit {
  sessionType;
  merchantID = null;
  merchants;
  partEmail;
  partFirstName;
  partLastName;
  partMobile;
  partPhone;
  contractID;
  email;
  currentMerchant;
  customerID;
  statusCodes;
  quotationStatusCodes;
  invitationID;
  invitationIDOrWakandaID;

  displayRange = true ;
  selectedRange = 'all';
  merchant;
  @ViewChild('tagInput', { static: false }) tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
  filtered = false;

  dateFrom: string;
  dateTo: string;
  util = new UtilsClass();
  isPromoterOrAdmin = false;
  @Input()
  backLabel = 'Back to Lookup';

  @Input()
  title = 'Application Lookup';
  @Input()
  description = 'You can search for Applications using any (or all) of the field below:';

  maxRecords = null;
  recordLength = ['10', '20', '50', '100', '200', '300', '400', '500', '600', '700', '800', '900', '1000'];

  defaultContractStatus;
  defaultQuotationStatus;

  @Input()
  manualSelection = false;

  close = new EventEmitter();

  selectedCustomer;
  getRemoved = new EventEmitter();

  @Input()
  cards = [];

  @Input()
  currentCards = [];

  totalCards = [];

  isModal = false;

  customerSelected = false;

  @Output()
  getSelectedCards = new EventEmitter();

  constructor(
    private AuthenticationService: AuthenticationService,

    private contractService: ContractService,
    private lookupService: LookupService,
    private utilsService: UtilsService,
    private router: Router,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.merchantID) {
        this.merchantID = data.merchantID;

        this.isModal = true;
      }

      if (data.title) {
        this.title = data.title;

        this.isModal = true;
      }

      if (data.description) {
        this.description = data.description;

        this.isModal = true;
      }

      if (data.backLabel) {
        this.backLabel = data.backLabel;

        this.isModal = true;
      }

      if (data.manualSelection === true) {
        this.manualSelection = true;
        this.isModal = true;
      } else if (data.manualSelection === false) {
        this.manualSelection = false;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.AuthenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      this.contractService.getStatusColorIcon(false).subscribe((res) => {
        if (res && res.length > 0) {
          this.defaultContractStatus = _.orderBy(res, 'Label');
        }
      });

      this.contractService.getStatusColorIcon(true).subscribe((res) => {
        if (res && res.length > 0) {
          this.defaultQuotationStatus = _.orderBy(res, 'Label');
        }
      });
      this.AuthenticationService.getCurrentPractice().subscribe((d) => {
        if (d && d.ID) {
          this.currentMerchant = d;
        }
      });
      this.AuthenticationService.getMerchantList({ fields: 'ID,TradingAs' }, this.isPromoterOrAdmin).subscribe((r) => {
        if (r && r.length > 0) {
          this.merchants = r;
        }
      });
    });
  }

  selectPatient() {
    const ref = RootAppComponent.dialog.open(InvitationLookupComponent, {
      data: {
        merchantID: this.merchantID,
        onlyMyAccount: false,
        displayChatButton: false,
        displayMerchantList: true,
        tableName: 'Customer',
        useStrict: true,
      },
      width: '600px',
    });
    ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });

    ref.componentInstance.getSelectedPatient.subscribe((u) => {
      if (u && u.ID) {
        this.customerID = u.ID;

        this.selectedCustomer = u;

        if (this.selectedCustomer.Merchant_key) {
          this.merchantID = this.selectedCustomer.Merchant_key;

          this.changeMerchant();
        }

        this.customerSelected = true;
        ref.close();
      }
    });
  }

  clearCustomer() {
    this.customerSelected = false;
    this.customerID = null;
    this.selectedCustomer = null;
  }

  changeMerchant() {
    if (this.merchantID) {
      const v = this.merchants.findIndex((item) => {
        if (item && item.ID && item.ID == this.merchantID) {
          return true;
        }

        return false;
      });

      if (v !== -1) {
        this.merchant = this.merchants[v];
      }
    }
  }
  goToApplicationList() {
    if (this.isModal == true) {
      this.filtered = true;
    } else {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['contract-list-overview', 'all', '{"dateRange":"all"}'],
          },
        },
      ]);
    }
  }

  viewPatient(id) {
    if (id) {
      if (id) {
        const ref = RootAppComponent.dialog.open(CustomerProspectViewModelComponent, {
          data: {
            patientID: id,
            isFullDetails: false,
          },
          width: '700px',
        });
        ref.componentInstance.close.subscribe((res) => {
          if (res == true) {
            ref.close();
          }
        });

        ref.componentInstance.detailedView.subscribe((res) => {
          if (res == true) {
            ref.close();
          }
        });
      }
    }
  }
  apply() {
    this.filtered = true;
  }
  setDateRangeFilter(range: { startDate: string; endDate: string }) {
    if (range && range.startDate && range.startDate.indexOf('1973') === -1) {
      this.dateFrom = this.util.EPdateFormat(range.startDate);
      this.dateTo = this.util.EPdateFormat(range.endDate);
    } else {
      this.dateFrom = null;
      this.dateTo = null;
    }
  }

  backSearch() {
    this.filtered = false;
  }


  viewMerchant(id) {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: id,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  closeEvent() {
    this.close.emit(true);
  }



  submit() {
    this.getSelectedCards.emit(this.totalCards);
  }

  getWakandaID(link) {
    if (link && link.indexOf('http') !== -1) {
      let urlParams = new URLSearchParams(link.split('?')[1]);

      // Accessing individual parameters
      let invitationID = urlParams.get('invitationID');

      if (invitationID) {
        return invitationID;
      } else {
        return null;
      }
    }
  }

  checkInvitationID() {
    if (this.invitationID) {
      let result = this.getWakandaID(this.invitationID);

      if (result) {
        this.invitationIDOrWakandaID = result;
      } else {
        this.invitationIDOrWakandaID = this.invitationID;
      }
    }
  }

  clearAll() {
    const confirm = new ConfirmDialog(
      'fas fa-times',
      '',
      'Are you sure you want to clear all search filters?',
      'No',
      'Yes'
    );
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,

    });

    ref.backdropClick().subscribe(r=>{

    })
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        this.invitationIDOrWakandaID = null;
        this.invitationID = null;
        this.customerID = null;
        this.contractID = null;
        this.clearCustomer();
        this.partEmail = null;
        this.partFirstName = null;
        this.partLastName = null;
        this.partMobile = null;
        this.quotationStatusCodes = [];
        this.statusCodes = [];
        this.dateFrom = null;
        this.dateTo = null ;
        this.maxRecords = null;
        this.merchant= null;
        this.merchantID = null;
        this.selectedRange = 'all';
        this.displayRange  =false ;
        setTimeout(() => {
          this.displayRange=true;
          ref.close();
        }, 500);

      }
    });



  }
}
