
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { LookupService } from '../../../shared/services/lookup.service';
import { TagCreateEditComponent } from '../../tags/tag-create-edit/tag-create-edit.component';
import { CustomerProspectService } from '../shared/customerProspect.service';

import * as _ from "lodash";
import { MAT_DIALOG_DATA } from '@angular/material';
import { InvitationLookupSecondComponent } from '../../invitation/invitation-lookup-second/invitation-lookup-second.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';

@Component({
  selector: 'customer-prospect-create',
  templateUrl: './customer-prospect-create.component.html',
  styleUrls: ['./customer-prospect-create.component.css']
})
export class CustomerProspectCreateComponent implements OnInit {

  @Output()
  getResult = new EventEmitter();

  @Output()
  close = new EventEmitter();


  isMobileRequired = true;
  isEmailRequired = true;

  merchantID;
  sessionType;
  isReffered = false
  tags = []
  selectedTags = []
  defaultSelectedTags = []
  addTag = new EventEmitter();
  isMobileValid = false;
  isHomePhoneValid = false;
  isWorkPhoneValid = false;
  isEmailValid = false;
  isAdminOrPromoter = false;
  isAddressValid = true;

  firstName
  lastName
  middleName
  mobile;
  workPhone
  homePhone;
  email
  dateOfBirth
  gender
  title;
  postCode;
  state;
  suburb;
  streetNumber;
  streetName;
  unitNumber;
  streetType;
  latitude;
  longitude;

  genders = []
  titles = []
  loadTag = false;

  isPromoterOrAdmin = false;
  merchants = [];

  marketingConsentEmail = true;
  marketingConsent = true;

  customerID;

  isLoaded = false;


  isMinView = false;

  customer;


  constructor(
    private customerProspectService: CustomerProspectService,
    private authenticationService: AuthenticationService,
    private lookupService: LookupService,
    @Optional()
    @Inject(MAT_DIALOG_DATA) public data: any

  ) {
    if (data && data.merchantID) {
      this.merchantID = data.merchantID;
    }

    if (data && data.customerID) {
      this.customerID = data.customerID;
    }
    if (data && data.isMobileRequired === true) {
      this.isMobileRequired = true;
    } else if (data && data.isMobileRequired === false) {
      this.isMobileRequired = false;
    }
    if (data && data.isEmailRequired === true) {
      this.isEmailRequired = true;
    } else if (data && data.isEmailRequired === false) {
      this.isEmailRequired = false;
    }

    if (data && data.isMinView === true) {
      this.isMinView = true;
    } else if (data && data.isMinView === false) {
      this.isMinView = false;
    }


  }

  ngOnInit() {


    if (this.isMinView && !this.customerID) {
      this.isMinView = false;
    }

    if (this.isMinView != true || !this.customerID) {
      this.lookupService.getLookup('CodeLookup', 'Gender').subscribe(data => {
        this.genders = data;
      });
      this.lookupService
        .getLookup('SimpleLookup', 'Salutation')
        .subscribe(data => {
          this.titles = data;
        });
    }
    this.authenticationService.isPromoterOrAdmin().subscribe(isPromoterOrAdmin => {
      this.isPromoterOrAdmin = isPromoterOrAdmin;
      this.authenticationService.getSessionType().subscribe(r => {
        this.sessionType = r;


        if (this.isPromoterOrAdmin == true) {

          if (!this.merchantID) {

            this.authenticationService.getCurrentPractice().subscribe((res) => {
              if (res && res.ID) {
                this.merchantID = res.ID;
              }

              this.getTags()
            })
          }
          else {
            this.getTags()
          }
          this.authenticationService.getMerchantList({ fields: 'ID,TradingAs' }).subscribe((merchants) => {
            if (merchants) {
              this.merchants = merchants;
            }
          });
        }
        else {
          this.getTags()
        }

      })

    })
  }

  setup() {
    if (this.customerID) {

      this.customerProspectService.getCustomerProspectDetails(this.customerID, {}, this.sessionType).subscribe(r => {
        if (r) {


          this.customer = r;

          if (r['Merchant_key']) {
            this.merchantID = r['Merchant_key']
          }


          if (r['FirstName']) {
            this.firstName = r['FirstName']
          }

          if (r['Name']) {
            this.lastName = r['Name']
          }

          if (r['MiddleName']) {
            this.middleName = r['MiddleName']
          }

          if (r['mobiles.Number']) {
            this.mobile = r['mobiles.Number']
          }

          if (r['phones.Number']) {
            this.homePhone = r['phones.Number']
          }
          if (r['emails.Email']) {
            this.email = r['emails.Email']
          }
          if (r['DateOfBirth'] && r['DateOfBirth'] != '0000-00-00') {
            this.dateOfBirth = r['DateOfBirth']
          }
          if (r['Gender.Code']) {
            this.gender = r['Gender.Code']
          }
          if (r['Salutation']) {
            this.title = r['Salutation']
          }
          if (r['addresses.Postcode']) {
            this.postCode = r['addresses.Postcode']
          }
          if (r['addresses.State']) {
            this.state = r['addresses.State']
          }
          if (r['addresses.Suburb']) {
            this.suburb = r['addresses.Suburb']
          }
          if (r['addresses.Street Nr']) {
            this.streetNumber = r['addresses.Street Nr']
          }
          if (r['addresses.Street Name']) {
            this.streetName = r['addresses.Street Name']
          }
          if (r['addresses.Unit']) {
            this.unitNumber = r['addresses.Unit']
          }
          if (r['addresses.Street Type']) {
            this.streetType = r['addresses.Street Type']
          }
          if (r['tags']) {
            this.defaultSelectedTags = r['tags']
            this.selectedTags = r['tags']
          }

          if (r['MarketingConsent_Email.Given'] === '0') {
            this.marketingConsentEmail = false;
          }
          else {
            this.marketingConsentEmail = true;
          }

          if (r['MarketingConsent.Given'] === '0') {
            this.marketingConsent = false;
          }
          else {
            this.marketingConsent = true;
          }
        }

        this.isLoaded = true;
      })

    }
    else {
      this.isLoaded = true;
    }
  }



  getTags() {

    this.loadTag = false;
    let pTag = {
      isPromoter: false,
      merchantID: this.merchantID,
      fields: 'Label'
    }

    this.customerProspectService.getTagsList(pTag, this.sessionType).subscribe(res => {
      if (res) {


        this.tags = _.map(res, item => {
          if (item && item.Label)
            return item.Label

          return null
        });
      }

      this.setup();

      this.loadTag = true;
    })


  }

  getMobile(p) {
    this.mobile = p;
  }

  getHomePhone(p) {
    this.homePhone = p;
  }

  getWorkPhone(p) {
    this.workPhone = p;
  }

  validateMobile(v) {
    this.isMobileValid = v;
  }

  validateHomePhone(v) {
    this.isHomePhoneValid = v;
  }

  validateWorkPhone(v) {
    this.isWorkPhoneValid = v;
  }

  validateEmail(v) {
    this.isEmailValid = v;
  }

  getEmail(b) {
    this.email = b
  }
  closeEvent() {
    this.close.emit(true)
  }

  getDateOfBirth(d) {

    this.dateOfBirth = d;
  }

  createPatient() {
    let payload: any = {
      mobile: this.mobile,
      phone: this.homePhone,
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      dateOfBirth: this.dateOfBirth,
      merchantID: this.merchantID,
      tags: this.selectedTags,
      gender: this.gender,
      title: this.title,
      isReffered: this.isReffered,

      suburb: this.suburb,
      state: this.state,
      postCode: this.postCode,
      streetNumber: this.streetNumber,
      streetName: this.streetName,
      unitNumber: this.unitNumber,
      streetType: this.streetType,
      latitude: this.latitude,
      longitude: this.longitude,

      marketingConsent: this.marketingConsent,
      marketingConsentEmail: this.marketingConsentEmail,
    };


    if (this.isMinView == true) {
      payload = {
        merchantID: this.merchantID,
        tags: this.selectedTags,

        marketingConsent: this.marketingConsent,
        marketingConsentEmail: this.marketingConsentEmail,
      };
    }

    if (this.customerID) {
      this.customerProspectService.modifyCustomerProspect(this.customerID, payload, this.sessionType).subscribe(r => {
        if (r && r.ID) {
          this.getResult.emit(r)
        }
      })
    }
    else {
      this.customerProspectService.createProspect(payload, this.sessionType).subscribe(r => {
        if (r && r.ID) {
          this.getResult.emit(r)
        }
      })
    }


  }
  save() {


    let payload = {
      mobile: this.mobile,
      phone: this.homePhone,
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      dateOfBirth: this.dateOfBirth,
      merchantID: this.merchantID,
      tags: this.selectedTags,
      isMarketing: !this.isReffered

    };

    if (this.customerID) {
      this.createPatient()
    }
    else {

      this.customerProspectService.patientLookup(payload, this.isAdminOrPromoter).subscribe((res) => {
        if (res && res.length > 0) {
          let patientLookup = RootAppComponent.dialog.open(InvitationLookupSecondComponent, {
            data: {
              patients: res,
              hideFilter: true,
              showNextBtn: true,
              displayChatButton: false,
              displayCreateButton: false,
            },
            width: '550px',
          });

          patientLookup.componentInstance.closeModal.subscribe((res) => {
            patientLookup.close();
          });
          patientLookup.componentInstance.detailedView.subscribe((res) => {
            patientLookup.close();
          });


          patientLookup.componentInstance.createNew.subscribe((res) => {
            if (res) {
              patientLookup.close();
              patientLookup.afterClosed().subscribe(r => {

                this.createPatient()

              });
            }

          });
        } else {

          this.createPatient()

        }
      })
    }





  }
  isAddressValidEvent(d) {
    this.isAddressValid = d
  }
  getRemovedTag(value: string): void {
    if (value) {
      let confirmDialog = new ConfirmDialog(
        "cancel",
        "Are you sure?",
        "<p>Remove <strong> " + value + "</strong> Tag</p>",

        "Cancel",
        "Okay"
      );

      let ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          let p = {
            label: value,
            merchantID: this.merchantID,
            cardIDs: [this.customerID]
          };
          this.customerProspectService.deleteTagForCard(p, this.sessionType).subscribe((res) => {
            if (res) {
              NotifyAppComponent.displayToast("success", "Success!", "Tag is removed.");



              let index = this.defaultSelectedTags.indexOf(value);
              if (index != -1) {
                this.defaultSelectedTags.splice(index, 1);
              }


              let indexs = this.selectedTags.indexOf(value);
              if (indexs != -1) {
                this.selectedTags.splice(indexs, 1);
              }
              this.defaultSelectedTags = JSON.parse(JSON.stringify(this.defaultSelectedTags))
              this.selectedTags = JSON.parse(JSON.stringify(this.selectedTags))
              ref.close();
            }
          });
        } else {
          ref.close();
        }
      });
    }
  }
  getAddress(adr) {
    if (adr) {
      this.suburb = adr.suburb;
      this.state = adr.state;
      this.postCode = adr.postCode;
      this.streetNumber = adr.streetNumber;
      this.streetName = adr.streetName;
      this.unitNumber = adr.unitNumber;
      this.streetType = adr.streetType;
      this.latitude = adr.latitude;
      this.longitude = adr.longitude;



    }
  }

  createNewTag() {
    let ref = RootAppComponent.dialog.open(TagCreateEditComponent, {
      data: {
        merchantID: this.merchantID
      },

      width: "600px"
    });

    ref.componentInstance.close.subscribe(res => {

      ref.close()
    })


    ref.componentInstance.getResult.subscribe(res => {

      if (res && res.Label) {


        if ((!this.tags || (this.tags && this.tags.length <= 0)) && (!this.defaultSelectedTags || (this.defaultSelectedTags && this.defaultSelectedTags.length <= 0))) {

          this.defaultSelectedTags = JSON.parse(JSON.stringify([res.Label]));
          this.tags = JSON.parse(JSON.stringify([res.Label]));
        }
        else {
          if (!this.tags || (this.tags && this.tags.length <= 0)) {
            this.tags.push(res.Label)
          }
          this.addTag.emit(res.Label);
        }
        ref.close()
      }
    })

  }

  getSelectedTags(e) {

    console.log(e)
    this.selectedTags = e || [];

  }

}
