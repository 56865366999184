<div class="row clearfix">
  <div class="row clearfix masonry" *ngIf="contract">
    <div class="row clearfix column full-width">
      <h2 class="sr-title ndge rm-mt pull-left">{{ contractTabTitle }} - {{ contract['ProductName'] }}</h2>

      <div class="pull-right ml">
        <!-- <button [matMenuTriggerFor]="actionMenu" mat-raised-button>
          Actions
          <mat-icon>more_vert</mat-icon>
        </button> -->

        <app-invitation-actions *ngIf="contract" [customerID]="contract['Customer_key']"
          class="   pull-right " [buttonType]="'buttonAccent'"
          [invitationID]="contract['Invitation_key']" [items]="items" [contractID]="contract['ID']"
          [merchantID]="contract['Merchant_key']" [dentistID]="contract['DentistContact_key']"
          [campaignID]="contract['Campaign_Key']" [contract]="contract" [productID]="contract['Product_key']">
        </app-invitation-actions>

        <mat-menu #actionMenu="matMenu">
          <button *ngIf="contract['Merchant_key']" (click)="viewMerchant(contract['Merchant_key'])" mat-menu-item>
            <mat-icon>person</mat-icon>
            <span>View Merchant</span>
          </button>

          <button *ngIf="contract['Customer_key']" (click)="openCustomerDetailsDialog(contract['Customer_key'])"
            mat-menu-item>
            <mat-icon>portrait</mat-icon>
            <span>View customer </span>
          </button>

          <button *ngIf="contract['Customer_key']" (click)="contact(contract['Customer_key'])" mat-menu-item>
            <mat-icon>contact_mail</mat-icon>
            <span>Contact customer </span>
          </button>

          <button *ngIf="contract['Product_key']" (click)="viewProduct(contract['Product_key'])" mat-menu-item>
            <mat-icon>business_center</mat-icon>
            <span>View product </span>
          </button>

          <button *ngIf="contract['Invitation_key']" (click)="openInviteViewDialog(contract['Invitation_key'])"
            mat-menu-item>
            <mat-icon>search</mat-icon>
            <span>View invitation </span>
          </button>

          <button *ngIf="contract['CapacityCheck']" (click)="displayCheckDetail('CapacityCheck')" mat-menu-item>
            <mat-icon class="fas fa-user-tag"></mat-icon>
            <span>Capacity Check</span>
          </button>

          <button *ngIf="contract['CreditCheck']" (click)="displayCheckDetail('CreditCheck')" mat-menu-item>
            <mat-icon class="fas fa-user-tag"></mat-icon>
            <span>Credit Check</span>
          </button>

          <button (click)="displayFundingHistory(contract)" mat-menu-item>
            <mat-icon class="fas fa-hand-holding-usd"></mat-icon>
            <span>Funding History</span>
          </button>
        </mat-menu>
      </div>
    </div>

    <div class="row clearfix full-width">
      <app-contract-detail-overview *ngIf="setContractTab == 'overview'" [contractObject]="contract">
      </app-contract-detail-overview>

      <app-contract-detail-payments *ngIf="setContractTab == 'repayment' || setContractTab == 'invoice'"
        [contractObject]="contract" [setContractTab]="setContractTab">
      </app-contract-detail-payments>

      <mat-card class="clearfix" *ngIf="setContractTab == 'notes'">
        <div class="colour-stripe"></div>
        <app-event-log-list [type]="'contract'" [contractID]="contractID"></app-event-log-list>
      </mat-card>

      <mat-card class="clearfix" *ngIf="setContractTab == 'compliance'">
        <div class="colour-stripe"></div>
        <app-compliance-task [contractID]="contractID"></app-compliance-task>
      </mat-card>

      <app-contract-detail-documents *ngIf="setContractTab == 'documents'" [contractObject]="contract">
      </app-contract-detail-documents>
    </div>
  </div>
</div>

<div class="row clearfix" *ngIf="
    isParameter == true && displayCreditCheck != true && displayCapacityCheck != true && displayIdentityCheck != true
  ">
  <div class="full-width text-center">
    <button class="btn-large" (click)="goBack()" mat-raised-button color="accent">Go Back</button>
  </div>
</div>
