<div class="row clearfix rel" *ngIf="positions.length > 0">
  <div class="pull-right closeModal">
    <!-- Modal dismiss button -->
    <button class="btn-close btn-clear mat-button" *ngIf="canClose == true" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <agm-map
    class="mapDisplay"
    [latitude]="positions[0] ? toNumber(positions[0].Latitude) : null"
    [longitude]="positions[0] ? toNumber(positions[0].Longitude) : null"
    [zoom]="zoom"
    [styles]="styles"
  >
    <div *ngIf="canClick == true">
      <agm-marker
        *ngFor="let position of positions"
        [iconUrl]="icon"
        [latitude]="toNumber(position.Latitude)"
        [longitude]="toNumber(position.Longitude)"
        [markerDraggable]="false"
      >
        <agm-snazzy-info-window [isOpen]="true" [maxWidth]="300" [maxHeight]="500" [closeWhenOthersOpen]="false">
          <ng-template>
            <strong *ngIf="position.time"> {{ position.time | TimeLabelPast }} ago </strong>
            <p>{{ position?.time | customDateTime }}</p>
            <p>{{ addressLine?.AddressLine }}</p>
            <div *ngIf="position.moreInfo" [innerHtml]="position.moreInfo | safeHtml"></div>
          </ng-template>
        </agm-snazzy-info-window>
      </agm-marker>
    </div>

    <div *ngIf="canClick == false">
      <agm-marker
        *ngFor="let position of positions"
        [iconUrl]="icon"
        [latitude]="toNumber(position.Latitude)"
        [longitude]="toNumber(position.Longitude)"
        [markerDraggable]="false"
      >
        <agm-snazzy-info-window [isOpen]="true" [maxWidth]="300" [maxHeight]="500" [closeWhenOthersOpen]="false">
          <ng-template>
            <p>{{ addressLineFull }}</p>
            <div *ngIf="position.moreInfo" [innerHtml]="position.moreInfo | safeHtml"></div>
          </ng-template>
        </agm-snazzy-info-window>
      </agm-marker>
    </div>
  </agm-map>

  <div class="row clearfix informationPane" *ngIf="showDetails == true && (address || moreInfo || positionTime)">
    <div class="column">
      <h2 class="sr-title sm-h2 rm-mb">Details</h2>
      <hr />
      <p class="rm-mt" *ngIf="positionTime">
        <strong>Time :</strong> {{ positionTime | customDateTime }} ({{ positionTime | TimeLabelPast }} ago)
      </p>
      <p class="rm-mt" *ngIf="address"><strong>Address : </strong> {{ address }}</p>
      <div *ngIf="moreInfo" [innerHtml]="moreInfo | safeHtml"></div>
    </div>
  </div>
</div>
<div class="loadingMap clearfix row text-center rel" *ngIf="loading && positions.length === 0">
  <p>Loading, please wait.</p>
  <!-- <div class="loadingMap"> -->
  <img class="" src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/map_loader.gif"/>

  <!-- </div> -->
</div>

<div
  class="container clearfix"
  *ngIf="notFound == true && loading == false && (!positions || (positions && positions.length <= 0))"
>
  <div class="pull-right closeModal" *ngIf="canClose == true">
    <!-- Modal dismiss button -->
    <button class="btn-close btn-clear mat-button" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <app-simple404
    [imageSource]="'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/broken_map.png'"
    [errorTitle]="'Location not found'"
    [errorContent]="'There might be a problem with the address you are looking for.'"
    [displayContact]="false"
  >
  </app-simple404>
</div>
