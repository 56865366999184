<div class="contract clearfix masonry" *ngIf="contract && contract.ID && customer && customer.ID && isLoad==true">
  <!-- <div class="row clearfix">
    <h2 class="sr-title ndge rm-mt pull-left">Application referred</h2>


  </div> -->
  <mat-card class="mb clearfix">
    <div class="card-header primary-gradient-img row">
      <div class="row clearfix flex flex-center mb">
        <div class="full-width">
          <div class="row clearfix">
            <app-card-picture class="sms-merchant-contact-pic2999  text-center pull-left mr" [cardID]="customer.ID"
              [hideZoomButton]="false" [tableName]="'Customer'" [LastModified]="customer['LastModified']">
            </app-card-picture>

            <h2 class="summary-header rm-m white">
              <span class="click-title" (click)="quickView(contract['Customer_key'])">
                {{ customer['CalculatedName'] }}</span>
              <span class="subLabel">
                Quote Date: <strong>{{ contract['Quotation.Date'] | customDate }}</strong></span>
              <span class="subLabel">
                Contract ID: <strong>{{ contract['ID'] }}</strong>

                <button class="position sml" style="
                height: 24px;
                width: 24px;
                line-height: 24px;
            " (click)="copy(contract['ID'])" mat-button mat-icon-button>
                  <mat-icon style="  font-size: 1rem;">file_copy</mat-icon>
                </button>
              </span>
              <span class="subLabel" *ngIf="customer && customer.ID">
                Customer ID: <strong>{{ customer['ID'] }}</strong>


                <button class="position sml" style="
                height: 24px;
                width: 24px;
                line-height: 24px;
            " (click)="copy(customer.ID)" mat-button mat-icon-button>
                  <mat-icon style=" font-size: 1rem; ">file_copy</mat-icon>
                </button></span>
            </h2>
          </div>


        </div>



        <div class="full-width ">
          <div class="row clearfix text-right">

            <span *ngIf="contract['Quotation.Status.Label'] != 'Won'" style="    font-size: 1.1rem;"
              class="chip chip-error  pull-right">
              Referred
            </span>

            <span *ngIf="contract['Quotation.Status.Label'] == 'Won' && contract['ContractDate'] == '0000-00-00'"
              style="    font-size: 1.1rem;" class="chip chip-enable  pull-right">
              Converted to contract
            </span>


            <span *ngIf="contract['ContractDate'] && contract['ContractDate'] != '0000-00-00'"
              style="    font-size: 1.1rem;" class="chip chip-enable  pull-right">
              <mat-icon style=" font-size: 1rem; ">file_copy</mat-icon> Contract is active
            </span>
          </div>

          <app-invitation-actions *ngIf="contract && !invitation" class="  contract-action-invitation   pull-right mt"
            [displayContractDetails]="false" [buttonType]="'buttonAccent'" [customerID]="contract['Customer_key']"
            [invitationID]="contract['Invitation_key']" [contractID]="contract['ID']" [contract]="contract"
            [merchantID]="contract['Merchant_key']" [dentistID]="null" [campaignID]="null" [instantOfferID]="null"
            [landingPageID]="null" [invitation]="invitation" [productID]="contract['Product_key']"
            (getDeletedInvitationID)="getDeletedInvitationID($event)" [displayDeleteInvitation]="false"
            (getInvitationUpdate)="getInvitationUpdate($event)"></app-invitation-actions>

          <app-invitation-actions *ngIf="contract && invitation" class=" contract-action-invitation  pull-right mt"
            [displayContractDetails]="false" [buttonType]="'buttonAccent'" [customerID]="contract['Customer_key']"
            [invitationID]="contract['Invitation_key']" [contractID]="contract['ID']" [contract]="contract"
            [merchantID]="contract['Merchant_key']" [dentistID]="invitation['DentistContact_key']"
            [campaignID]="invitation['Campaign_Key']" [instantOfferID]="invitation['Instant_Finance_Offer_Key']"
            [landingPageID]="invitation['Document_Link_key']" [invitation]="invitation"
            [displayDeleteInvitation]="false" [productID]="contract['Product_key']"
            (getDeletedInvitationID)="getDeletedInvitationID($event)"
            (getInvitationUpdate)="getInvitationUpdate($event)"></app-invitation-actions>


          <!-- <button class=" btn  pull-right mt" [matMenuTriggerFor]="menusaz" mat-raised-button color="accent">
            <mat-icon class="smt smr" style="font-size:1rem !important;">more_vert</mat-icon> Actions
          </button>


          <mat-menu #menusaz="matMenu">


            <button [matMenuTriggerFor]="epVerMenu" mat-menu-item>
              <mat-icon class="smr">description</mat-icon>
              Contract
            </button>
            <mat-menu #epVerMenu="matMenu">


              <button *ngIf="contract['Product_key']" (click)="openProductView(contract['Product_key'])" mat-menu-item>
                <mat-icon class="smr">business_center</mat-icon>
                <span>View Product</span>
              </button>

              <mat-divider></mat-divider>

              <button *ngIf="isPromoterOrAdmin == true && contract['ID']" (click)="viewContractLogs(contract['ID'])"
                mat-menu-item>
                <mat-icon class="fa fa-list-alt smr"></mat-icon>
                <span>View Contract Logs</span>
              </button>



              <button (click)="viewMessagesContract(contract['ID'])" mat-menu-item>
                <mat-icon class="fas fa-calendar-week smr"></mat-icon>
                <span>View Events</span>
              </button>

            </mat-menu>


            <button [matMenuTriggerFor]="messageMenuInvitation"
              *ngIf="contract['Invitation_key'] && contract['Customer_key']" mat-menu-item>
              <mat-icon class="fas fa-envelope smr"></mat-icon>
              Invitation
            </button>

            <mat-menu #messageMenuInvitation="matMenu">

              <button *ngIf="contract['Invitation_key']" (click)="openInvitationView(contract['Invitation_key'])"
                mat-menu-item>
                <mat-icon class="smr"> description</mat-icon>
                <span> Quick View </span>
              </button>
              <button *ngIf="contract['Customer_key']"
                (click)="fullInvitationDetails(contract['Invitation_key'] , contract['Customer_key'])" mat-menu-item>
                <mat-icon class="fas fa-id-card smr"></mat-icon>
                <span> Detailed view </span>
              </button>



              <button (click)="viewMessagesInvitation(contract['Invitation_key'])" mat-menu-item>
                <mat-icon class="fas fa-calendar-week smr"></mat-icon>
                <span>View Events</span>
              </button>


              <mat-divider></mat-divider>


              <button *ngIf="contract['Invitation_key'] " (click)="wakandaFiles(contract['Invitation_key'])"
                mat-menu-item>
                <mat-icon class="smr">attach_file</mat-icon>
                <span>Wakanda Files</span>
              </button>

              <button *ngIf="contract['Invitation_key'] " (click)="redirect(contract['Invitation_key'])" mat-menu-item>
                <mat-icon class="smr">link</mat-icon>
                <span>Invitation link</span>
              </button>
              <mat-divider></mat-divider>
              <button (click)="openCloneInvitationModal(contract['Invitation_key'], contract['Customer_key'])"
                mat-menu-item>
                <mat-icon class="smr">file_copy</mat-icon>
                <span>Clone</span>
              </button>



            </mat-menu>

            <mat-divider></mat-divider>



            <button [matMenuTriggerFor]="messageMenuCustomer" *ngIf="contract['Customer_key']" mat-menu-item>
              <mat-icon class="fas fa-user smr"></mat-icon>
              {{'Patient' | translate}}
            </button>
            <mat-menu #messageMenuCustomer="matMenu">

              <button *ngIf="contract['Customer_key']" (click)="quickView(contract['Customer_key'])" mat-menu-item>
                <mat-icon class="fas fa-user smr"></mat-icon>
                <span> Quick view</span>
              </button>

              <button *ngIf="contract['Customer_key']" (click)="openCustomerView(contract['Customer_key'])"
                mat-menu-item>
                <mat-icon class="fas fa-id-card smr"></mat-icon>
                <span> Detailed view </span>
              </button>


              <button (click)="viewMessages(contract['Customer_key'])" mat-menu-item>
                <mat-icon class="fas fa-calendar-week smr"></mat-icon>
                <span>View Events</span>
              </button>


              <mat-divider></mat-divider>

              <button *ngIf="isModulePatientSMSActive == true && contract['Customer_key']"
                (click)="sendSMS(contract['Customer_key'],contract['Merchant_key'])" mat-menu-item>
                <mat-icon class="fas fa-sms smr"></mat-icon>
                <span>Send SMS</span>
              </button>



              <button *ngIf=" contract['Customer_key']" (click)="sendEmail(contract['Customer_key'])" mat-menu-item>
                <mat-icon class="fas fa-at smr"></mat-icon>
                <span>Send Email</span>
              </button>


            </mat-menu>



            <button [matMenuTriggerFor]="messageMenuDesntist" *ngIf="invitation && invitation.ID" mat-menu-item>
              <mat-icon class="       {{'fas fa-tooth' | translate }} smr"></mat-icon>
              {{'Dentist' | translate}}
            </button>
            <mat-menu #messageMenuDesntist="matMenu">

              <button *ngIf="invitation && invitation['DentistContact_key']"
                (click)="viewDentist(invitation['DentistContact_key'])" mat-menu-item>
                <mat-icon class="fas fa-user smr"></mat-icon>
                <span> Quick view</span>
              </button>

              <mat-divider></mat-divider>

              <button *ngIf="isModulePatientSMSActive == true && invitation && invitation['DentistContact_key']"
                (click)="contactSMSDentist(invitation['DentistContact_key'])" mat-menu-item>
                <mat-icon class="fas fa-sms smr"></mat-icon>
                <span>Send SMS</span>
              </button>



              <button *ngIf="invitation &&  invitation['DentistContact_key']"
                (click)="contactDentist(invitation['DentistContact_key'])" mat-menu-item>
                <mat-icon class="fas fa-at smr"></mat-icon>
                <span>Send Email</span>
              </button>


            </mat-menu>

            <button *ngIf="contract['Merchant_key']" (click)="viewMerchant(contract['Merchant_key'])" mat-menu-item>
              <mat-icon class="fas fa-building smr"></mat-icon>
              View {{'merchant' | translate}}
            </button>


            <mat-divider></mat-divider>


            <button *ngIf="contract['Status.Code'] == 'ACTIVE' && toNumber(contract['Settlements.NotYetDrawn']) > 0"
              (click)="createSettlement(contract['ID'])" mat-menu-item>
              <mat-icon class="fas fa-file-contract smr" aria-hidden="true"></mat-icon>
              <span>New Settlement</span>
            </button>


            <mat-divider></mat-divider>


            <button [matMenuTriggerFor]="messageMenuInvitation4800" *ngIf="contract['Customer_key'] && contract['ID']"
              mat-menu-item>
              <mat-icon class="smr">note_add</mat-icon>
              Notes
            </button>

            <mat-menu #messageMenuInvitation4800="matMenu">


              <button (click)="createNote(contract['Customer_key'], contract['ID'])" mat-menu-item>
                <mat-icon class="smr">note_add</mat-icon>
                <span>Create Note</span>
              </button>

              <button (click)="viewNotes(contract['Customer_key'])" mat-menu-item>
                <mat-icon class="fa fa-list-alt smr"></mat-icon>
                <span>View Notes</span>
              </button>


            </mat-menu>

          </mat-menu> -->



          <button class=" btn  pull-right mr mt" (click)="refresh()"
            style="background:none !important; color:#fff; border:2px solid #fff;" mat-raised-button color="accent">
            Refresh

            <mat-icon class="fas fa-sync-alt smt sml" style="font-size:1rem !important;"></mat-icon>
          </button>

          <button *ngIf="AllowedStatusCodes && AllowedStatusCodes.length>0" class=" btn  pull-right mt mr"
            style="background:none !important; color:#fff; border:2px solid #fff;" [matMenuTriggerFor]="menu19980"
            mat-raised-button color="accent">

            Change Status

            <mat-icon class="fas fa-pen-alt sml"></mat-icon>
          </button>

          <mat-menu #menu19980="matMenu">
            <div class="full-width" *ngFor="let p of AllowedStatusCodes">
              <button (click)="changeStatus(p)" mat-menu-item>
                <mat-icon [style.color]="p['Color']" class="{{p.Icon}} mr" style="width:30px !important;"></mat-icon>
                <strong> {{ p['Label'] }}</strong>

              </button>
              <hr>
            </div>
          </mat-menu>


        </div>
      </div>


    </div>

    <div class="row clearfix flex mt" *ngIf="contract">
      <div class="contract clearfix full-width value mt" *ngIf="customer">




        <p class="bold">
          <mat-icon class="fas fa-phone"></mat-icon>
          {{ customer['mobiles.Number'] | customPhone }}
        </p>

        <p class="bold">
          <mat-icon class="fas fa-envelope"></mat-icon>
          {{ customer['emails.Email'] }}
        </p>

        <p class="bold">
          <mat-icon class="fas fa-map-marker-alt"></mat-icon>
          {{ customer['addresses.Calculated'] }}
        </p>
      </div>
      <div class="row clearfix half-width">
        <div class="block">
          <mat-icon class="fas fa-hand-holding-usd" style="font-size: 29px !important;"></mat-icon>
          <label>Product</label>
          <h4 class="sr-title click-title rm-mt" (click)="openProductView(contract['Product_key'])">
            {{ contract['ProductName'] }}</h4>
        </div>
      </div>

      <div class="row clearfix half-width">
        <div class="block">
          <app-card-picture class="sms-merchant-contact-pic299  text-center  " [cardID]="contract.Merchant_key"
            [hideZoomButton]="false" [tableName]="'Merchant'">
          </app-card-picture>
          <label>{{ "KEYWORD.practitioner" | translate | titlecase }}</label>
          <h4 class="sr-title click-title rm-mt" (click)="viewMerchant(contract['Merchant_key'])">
            {{ contract['MerchantName'] }}</h4>
        </div>
      </div>

      <div class="row clearfix half-width" *ngIf="invitation && invitation.DentistContact_key">
        <div class="block">
          <app-card-picture class="sms-merchant-contact-pic299  text-center  " [cardID]="invitation.DentistContact_key"
            [hideZoomButton]="false" [tableName]="'Contact'">
          </app-card-picture>
          <label>{{ "Dentist" | translate | titlecase }}</label>
          <h4 class="sr-title click-title rm-mt" (click)="viewDentist(invitation['DentistContact_key'])">
            {{ invitation['DentistContactName'] }}</h4>
        </div>
      </div>

    </div>

    <div class="full-width filter-control  mb">
      <mat-button-toggle-group class="btn-group full-width flex tab-container-contract" [(ngModel)]="selectedTab"
        (ngModelChange)="replaceRouting()" name="sortListSetting">
        <mat-button-toggle class="text-center full-width" *ngFor="let t of tabs;" [value]="t.code">
          <mat-icon class="{{t.icon}}"></mat-icon> <br>
          <p class="rm-m small text-center"><strong>{{t.label}}</strong></p>
        </mat-button-toggle>
      </mat-button-toggle-group>



    </div>
    <div class="full-width clearfix" *ngIf="contract && contract.ID" [hidden]="selectedTab!=='overview'  "
      @simpleFadeAnimation>




      <div class="full-width contract-overview-holder  contract-overview-top flex clearfix">


        <app-contract-view class="thrd-width " *ngIf="contract && contract.ID" [contract]="contract" [isSimple]="true"
          [hideCapacity]="true" [hideHeader]="true" [chartOnly]="true">
        </app-contract-view>


        <div class="full-width flex">
          <div class="clearfix smt smb full-width" style="  box-shadow: none !important;">

            <div class="full-width flex contract-line">
              <div class="full-width">
                <h3 class="sr-title rm-m full-width  text-left">
                  Contract Status
                </h3>
                <p class="text-left full-width">

                  <strong class="chip chip-enable" [style.background]="contract['Status.Color']">
                    <mat-icon class="{{contract['Status.Icon']}} smr" style="font-size: 1rem !important;"></mat-icon>
                    {{contract['Status']}}
                  </strong>


                </p>
              </div>
              <div class="full-width">
                <h3 class="sr-title rm-m full-width  text-left">
                  Quotation
                </h3>
                <p class="text-left full-width">
                  <strong class="chip chip-enable smr" [style.background]="contract['Quotation.Color']">
                    <mat-icon class="{{contract['Quotation.Icon']}} smr" style="font-size: 1rem !important;"></mat-icon>
                    {{contract['Quotation.Status.Label']}}
                  </strong>

                </p>
              </div>




              <div class="full-width">
                <h3 class="sr-title rm-m full-width  text-left">
                  Payment frequency
                </h3>
                <p class="text-left full-width">
                  {{contract['RepaymentFrequency.Adverb']}}
                </p>
              </div>

              <div class="full-width">
                <h3 class="sr-title rm-m full-width  text-left">
                  Instalments
                </h3>
                <p class="text-left full-width">
                  {{contract['LoanInstalments']}} {{contract['RepaymentFrequency.Noun.Singular']}}(s)
                </p>
              </div>


            </div>

            <hr *ngIf="paymentMethod">

            <div class="full-width flex contract-line white-line" *ngIf="paymentMethod">


              <div class="full-width">

                <h3 class="sr-title rm-m full-width  text-left" *ngIf="paymentMethod['creditCard.Type.Code']">
                  {{paymentMethod['creditCard.Type.Label']}}
                </h3>

                <h3 class="sr-title rm-m full-width  text-left" *ngIf="!paymentMethod['creditCard.Type.Code']">
                  Bank Account
                </h3>


                <img *ngIf="!paymentMethod['BSB'] &&   getCardType(paymentMethod['creditCard.Type.Code'])==='MC'"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/card_11041055.png"
                  class=" img-cc-type pull-left mr " />
                <img *ngIf="!paymentMethod['BSB'] &&  getCardType(paymentMethod['creditCard.Type.Code'])==='VS' "
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/card_11041038.png"
                  class=" img-cc-type pull-left mr " />
                <img *ngIf="!paymentMethod['BSB'] &&  getCardType(paymentMethod['creditCard.Type.Code'])==='AX' "
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/american-express_349228.png"
                  class=" img-cc-type pull-left mr " />
                <img *ngIf="!paymentMethod['BSB'] &&  getCardType(paymentMethod['creditCard.Type.Code'])==='CC' "
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/credit-card_147258.png"
                  class=" img-cc-type pull-left mr " />
                <img *ngIf="paymentMethod['BSB']"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/bank_2830289.png"
                  class=" img-cc-type  pull-left mr " />


              </div>

              <div class="full-width" *ngIf="paymentMethod['BSB']">
                <h3 class="sr-title rm-m full-width  text-left">
                  Account Holder
                </h3>
                <p class="text-left full-width">
                  {{paymentMethod['AccountHolder']}}
                </p>


              </div>


              <div class="full-width" *ngIf="paymentMethod['BSB']">
                <h3 class="sr-title rm-m full-width  text-left">
                  Account Number
                </h3>
                <p class="text-left full-width">
                  {{paymentMethod['AccountNumber']}}
                </p>


              </div>
              <div class="full-width" *ngIf="!paymentMethod['BSB']">
                <h3 class="sr-title rm-m full-width  text-left">
                  Card Holder
                </h3>
                <p class="text-left full-width">
                  {{paymentMethod['AccountHolder']}}
                </p>


              </div>


              <div class="full-width" *ngIf="!paymentMethod['BSB']">
                <h3 class="sr-title rm-m full-width  text-left">
                  Card Number
                </h3>
                <p class="text-left full-width">
                  {{paymentMethod['AccountNumber']}}
                </p>


              </div>


              <div class="full-width" *ngIf="paymentMethod['BSB']">
                <h3 class="sr-title rm-m full-width  text-left">
                  BSB
                </h3>
                <p class="text-left full-width">
                  {{paymentMethod['BSB']}}
                </p>


              </div>


            </div>
            <hr *ngIf="idDocument">
            <div class="full-width flex contract-line" *ngIf="idDocument">

              <div class="full-width">
                <h3 class="sr-title rm-m full-width  text-left">
                  ID Document
                </h3>
                <p class="text-left full-width">
                  {{idDocument['Type.Label']}}
                </p>


              </div>

              <div class="full-width">
                <h3 class="sr-title rm-m full-width  text-left">
                  Name on ID
                </h3>
                <p class="text-left full-width">
                  {{idDocument['Owner.Name'] || '...'}}
                </p>


              </div>

              <div class="full-width">
                <h3 class="sr-title rm-m full-width  text-left">
                  ID Number
                </h3>
                <p class="text-left full-width">
                  {{idDocument['Number'] || '...'}}
                </p>


              </div>
              <div class="full-width">
                <h3 class="sr-title rm-m full-width  text-left">
                  Issuer
                </h3>
                <p class="text-left full-width">
                  {{idDocument['Issuer.Label'] || idDocument['Issuer.Code'] || '...'}}
                </p>


              </div>


              <div class="full-width" *ngIf="idDocument['Date.Issue'] && idDocument['Date.Issue']!='0000-00-00'">
                <h3 class="sr-title rm-m full-width  text-left">
                  Issue Date
                </h3>
                <p class="text-left full-width">
                  {{idDocument['Date.Issue'] | customDate}}
                </p>


              </div>

              <div class="full-width" *ngIf="!idDocument['Date.Issue'] || idDocument['Date.Issue']=='0000-00-00'">
                <h3 class="sr-title rm-m full-width  text-left">
                  Issue Date
                </h3>
                <p class="text-left full-width">
                  ...
                </p>


              </div>



            </div>
            <hr>
            <div class="full-width">
              <div class="full-width capacity-v flex contract-line  white-line" *ngIf="contract && contract.isPaymentPlan!=true">
                <h3 class="sr-title rm-m full-width " style="line-height: 40px;">
                  <mat-icon class="fas fa-check-double smr" style="font-size:1rem !important;"></mat-icon> Capacity
                  Check
                </h3>
                <div class="full-width" *ngIf="capacityCheck" style="line-height: 40px;">
                  <strong class="chip chip-enable"
                    *ngIf="capacityCheck['Result.Current'] && capacityCheck['Result.Current'] == 'Accept'">
                    <mat-icon class="fas fa-thumbs-up"></mat-icon>
                    PASS
                  </strong>
                  <strong class="chip chip-error"
                    *ngIf="capacityCheck['Result.Current'] && capacityCheck['Result.Current'] != 'Accept'">
                    <mat-icon class="fas fa-thumbs-down"></mat-icon>
                    FAIL
                  </strong>
                  <strong class="chip chip-unknow" *ngIf="!capacityCheck['Result.Current']">
                    <mat-icon class="fas fa-question-circle"></mat-icon>
                    Not Performed
                  </strong>



                  <button class="position sml" *ngIf="capacityCheck['Result.Reason']"
                    style=" height: 24px;  width: 24px;  line-height: 24px;"
                    (click)="info('Capacity Check',capacityCheck['Result.Reason'])" mat-button mat-icon-button>
                    <mat-icon class="fas fa-info-circle" style=" font-size: 1rem; "></mat-icon>
                  </button>

                </div>

                <div class="full-width" *ngIf="!capacityCheck" style="line-height: 40px;">

                  <strong class="chip chip-unknow">
                    <mat-icon class="fas fa-question-circle"></mat-icon>
                    Not Performed
                  </strong>

                </div>



                <p class="full-width rm-m"
                  matTooltip=" {{ (capacityCheck['Date.Current']+' '+capacityCheck['Time.Current'])  | customDateTime}}"
                  *ngIf="capacityCheck && capacityCheck['Date.Current'] && capacityCheck['Date.Current']!='0000-00-00'"
                  style="line-height: 40px;">
                  {{ (capacityCheck['Date.Current']+' '+capacityCheck['Time.Current'])  | TimeLabelPast}} ago
                </p>

                <p class="full-width rm-m"
                  *ngIf="!capacityCheck || (capacityCheck && !capacityCheck['Date.Current'] ) ||  (capacityCheck && capacityCheck['Date.Current'] && capacityCheck['Date.Current']=='0000-00-00')"
                  style="line-height: 40px;">
                  ...
                </p>


                <div class="full-width">
                  <button class=" btn  pull-right smr" (click)="changeTab('capacity_check')" mat-icon-button
                    color="accent">
                    <mat-icon class="fas fa-chevron-right"></mat-icon>
                  </button>

                  <button class=" btn  pull-right smr" *ngIf="!capacityCheck && contract['Status.Code']==='APPL_COMP'  "
                    (click)="capacityCheckPerform()" mat-raised-button color="accent">
                    Perform
                  </button>


                  <button class=" btn  pull-right smr"
                    *ngIf="capacityCheck && (capacityCheck['Result.Current'] == 'Decline' || capacityCheck['Result.Current'] == 'Hard Refer' || capacityCheck['Result.Current'] == 'Refer')"
                    (click)="makeDecision('Capacity')" mat-raised-button color="accent">
                    Decide
                  </button>

                </div>

              </div>
              <hr *ngIf="contract && contract.isPaymentPlan!=true">
              <div class="full-width contract-line">
                <div class="full-width capacity-v flex">
                  <h3 class="sr-title rm-m full-width " style="line-height: 40px;">
                    <mat-icon class="fas fa-credit-card smr" style="font-size:1rem !important;"></mat-icon> Credit Check
                  </h3>
                  <div class="full-width" *ngIf="creditCheck" style="line-height: 40px;">
                    <strong class="chip chip-enable"
                      *ngIf="creditCheck['Result.Current'] && creditCheck['Result.Current'] == 'Accept'">
                      <mat-icon class="fas fa-thumbs-up"></mat-icon>
                      PASS
                    </strong>
                    <strong class="chip chip-error"
                      *ngIf="creditCheck['Result.Current'] && creditCheck['Result.Current'] != 'Accept'">
                      <mat-icon class="fas fa-thumbs-down"></mat-icon>
                      FAIL
                    </strong>
                    <strong class="chip chip-unknow" *ngIf="!creditCheck['Result.Current']">
                      <mat-icon class="fas fa-question-circle"></mat-icon>
                      Not Performed
                    </strong>



                    <button class="position sml" *ngIf="creditCheck['Result.Reason']"
                      style=" height: 24px;  width: 24px;  line-height: 24px;"
                      (click)="info('Credit Check',creditCheck['Result.Reason'])" mat-button mat-icon-button>
                      <mat-icon class="fas fa-info-circle" style=" font-size: 1rem; "></mat-icon>
                    </button>

                  </div>

                  <div class="full-width" *ngIf="!creditCheck" style="line-height: 40px;">

                    <strong class="chip chip-unknow">
                      <mat-icon class="fas fa-question-circle"></mat-icon>
                      Not Performed
                    </strong>

                  </div>


                  <p class="full-width rm-m"
                    matTooltip=" {{ (creditCheck['Date.Current']+' '+creditCheck['Time.Current'])  | customDateTime}}"
                    *ngIf="creditCheck && creditCheck['Date.Current'] && creditCheck['Date.Current']!='0000-00-00'"
                    style="line-height: 40px;">
                    {{ (creditCheck['Date.Current']+' '+creditCheck['Time.Current'])  | TimeLabelPast}} ago
                  </p>


                  <p class="full-width rm-m"
                    *ngIf="!creditCheck ||  (capacityCheck && !capacityCheck['Date.Current'] ) || (creditCheck && creditCheck['Date.Current'] && creditCheck['Date.Current']=='0000-00-00')"
                    style="line-height: 40px;">
                    ...
                  </p>

                  <div class="full-width">
                    <button class=" btn  pull-right smr" (click)="changeTab('credit_check')" mat-icon-button
                      color="accent">
                      <mat-icon class="fas fa-chevron-right"></mat-icon>
                    </button>

                    <button class=" btn  pull-right smr" *ngIf="!creditCheck && contract['Status.Code']==='APPL_COMP'  "
                      (click)="creditCheckPerform()" mat-raised-button color="accent">
                      Perform
                    </button>

                    <button class=" btn  pull-right smr"
                      *ngIf="creditCheck && (creditCheck['Result.Current'] == 'Decline' || creditCheck['Result.Current'] == 'Hard Refer' || creditCheck['Result.Current'] == 'Refer')"
                      (click)="makeDecision('Credit')" mat-raised-button color="accent">
                      Decide
                    </button>

                  </div>
                </div>
              </div>
              <hr>
              <div class="full-width contract-line  white-line">
                <div class="full-width capacity-v flex">
                  <h3 class="sr-title rm-m full-width " style="line-height: 40px;">
                    <mat-icon class="fas fa-id-card smr" style="font-size:1rem !important;"></mat-icon> Identity Check
                  </h3>
                  <div class="full-width" *ngIf="IDCheck" style="line-height: 40px;">
                    <strong class="chip chip-enable"
                      *ngIf="IDCheck['Result.Current'] && IDCheck['Result.Current'] == 'Accept'">
                      <mat-icon class="fas fa-thumbs-up"></mat-icon>
                      PASS
                    </strong>
                    <strong class="chip chip-error"
                      *ngIf="IDCheck['Result.Current'] && IDCheck['Result.Current'] != 'Accept'">
                      <mat-icon class="fas fa-thumbs-down"></mat-icon>
                      FAIL
                    </strong>
                    <strong class="chip chip-unknow" *ngIf="!IDCheck['Result.Current']">
                      <mat-icon class="fas fa-question-circle"></mat-icon>
                      Not Performed
                    </strong>

                    <button class="position sml" *ngIf="IDCheck['Result.Reason']"
                      style=" height: 24px;  width: 24px;  line-height: 24px;"
                      (click)="info('Identity Check',IDCheck['Result.Reason'])" mat-button mat-icon-button>
                      <mat-icon class="fas fa-info-circle" style=" font-size: 1rem; "></mat-icon>
                    </button>


                  </div>

                  <div class="full-width" *ngIf="!IDCheck" style="line-height: 40px;">

                    <strong class="chip chip-unknow">
                      <mat-icon class="fas fa-question-circle"></mat-icon>
                      Not Performed
                    </strong>

                  </div>


                  <p class="full-width rm-m"
                    matTooltip=" {{ (IDCheck['Date.Current']+' '+IDCheck['Time.Current'])  | customDateTime}}"
                    *ngIf="IDCheck && IDCheck['Date.Current'] && IDCheck['Date.Current']!='0000-00-00'"
                    style="line-height: 40px;">
                    {{ (IDCheck['Date.Current']+' '+IDCheck['Time.Current'])  | TimeLabelPast}} ago
                  </p>

                  <p class="full-width rm-m"
                    *ngIf="!IDCheck || (IDCheck && !IDCheck['Date.Current'] ) ||   (IDCheck && IDCheck['Date.Current'] && IDCheck['Date.Current']=='0000-00-00')"
                    style="line-height: 40px;">
                    ...
                  </p>

                  <div class="full-width">



                    <button class=" btn  pull-right smr" (click)="changeTab('ID_check')" mat-icon-button color="accent">
                      <mat-icon class="fas fa-chevron-right"></mat-icon>
                    </button>

                    <button class=" btn  pull-right smr" *ngIf="!IDCheck && false  " (click)="IDCheckPerform()"
                      mat-raised-button color="accent">
                      Perform
                    </button>


                    <button class=" btn  pull-right smr"
                      *ngIf="IDCheck && (IDCheck['Result.Current'] == 'Decline' || IDCheck['Result.Current'] == 'Hard Refer' || IDCheck['Result.Current'] == 'Refer')"
                      (click)="makeDecision('Identity')" mat-raised-button color="accent">
                      Decide
                    </button>

                  </div>
                </div>
              </div>
            </div>

            <hr>
          </div>
        </div>
      </div>
      <app-contract-detail-overview [contractObject]="contract" [displayFunder]='false'>
      </app-contract-detail-overview>


    </div>


    <div class="full-width clearfix" *ngIf="isSettlementLoaded==true" [hidden]="selectedTab != 'settlement'"
      @simpleFadeAnimation>




      <div class="full-width clearfix"
        style="background: #f5f5f5;padding: 16px;margin-left: -16px;margin-top: -30px; border-bottom: 1px solid #e5e5e5; ">
        <h3 class="sr-title pull-left rm-m  mt">Settlement overview</h3>

        <button class="btn pull-right mr" *ngIf="(contract['Settlements.NotYetDrawn'] | toNumber) > 0"
          (click)="newSettlement()" mat-raised-button color="accent" matTooltip="Create settlement for this contract">
          <mat-icon class="fas fa-comment-dollar" aria-hidden="true"></mat-icon>
          New Settlement
        </button>




      </div>


      <div class="full-width contract-overview-holder   clearfix" style="    margin-top: 30px !important;      ">


        <app-contract-view class="full-width settlement-contract-view " *ngIf="contract && contract.ID"
          [contract]="contract" [hideMainBody]="false" [displayNoSettlement]="false" [isSimple]="false"
          [isMiniSettlementExpanded]="true" [hideCapacity]="true" [hideHeader]="true" [isShowFull]="false">
        </app-contract-view>
      </div>
    </div>

    <div class="full-width clearfix" [hidden]="selectedTab!=='supporting_documents'" @simpleFadeAnimation>
      <div class="full-width clearfix">





        <button class="pull-right btn " mat-raised-button color="accent" (click)="addDocumentGlobal()">
          <mat-icon class="smr">add</mat-icon> Add document
        </button>


        <button class="pull-right btn mr " *ngIf="contract['Invitation_key'] " mat-raised-button color="primary"
          (click)="wakandaFiles(contract['Invitation_key'])">
          <mat-icon class="smr">attach_file</mat-icon>
          <span>Wakanda Files</span>
        </button>
      </div>
      <div class="full-width clearfix" *ngIf="_documents && _documents.length>0">




        <div class="thrd-width card-doc-holder pull-left flex" *ngFor="let docs of _documents; let index = index;"
          [ngClass]="{ single: index % 2 == 1 }">


          <mat-card class="card  full-width  clearfix smb card-doc" style="background:#f5f5f5;"
            [ngClass]="{ pair: index % 3 == 1 }">



            <div class="full-width">

              <button class="pull-right smt" mat-mini-fab color="accent" (click)="addDocument(docs.code,docs.label)">
                <mat-icon>add</mat-icon>
              </button>



              <h3 class="sr-title text-left pull-left text-center">
                {{docs.label | translate }}
                <span class="badge">
                  <span [endVal]="docs.documents.length" countUp></span>
                </span>
              </h3>

            </div>
            <div class="colour-stripe"></div>
            <div class="full-width document-contract-holder">
              <app-ep-document-list class="contract-overview-com" [isDownload]="true" [isPrint]="false"
                [canRemove]="false" [justify]="'left'" [isModal]="false" [isFilter]="false" [defaultCheck]="false"
                [isRedirection]="false" [documents]="docs.documents" [title]="''" [canCheck]="false"
                [listViewType]="'default'">
              </app-ep-document-list>
            </div>

          </mat-card>

        </div>
      </div>
      <div *ngIf="!_documents ||(_documents && _documents.length <= 0)">
        <app-empty-list-message [title]="'No Documents Found'" style=" margin: auto !important;" [message]="'There is no documents to show'">
        </app-empty-list-message>
      </div>
    </div>

    <div class="full-width clearfix" *ngIf="isPaymentMethodLoaded==true" [hidden]="selectedTab != 'payment_methods'"
      @simpleFadeAnimation>
      <app-bank-account-list-user class="full-width" [userID]="customer['ID']" [canEdit]="true">
      </app-bank-account-list-user>
    </div>


    <div class="full-width clearfix" *ngIf="isNoteLoaded==true" [hidden]="selectedTab != 'notes'" @simpleFadeAnimation>


      <div class="full-width clearfix"
        style="background: #f5f5f5;padding: 16px;margin-left: -16px;margin-top: -30px; border-bottom: 1px solid #e5e5e5; ">
        <h3 class="sr-title pull-left rm-m  mt">Contract notes</h3>

        <button class="btn pull-right mr" (click)="createNote(contract['Customer_key'], contract['ID'])"
          mat-raised-button color="accent" matTooltip="New note">
          <mat-icon class="smr">note_add</mat-icon>
          <span>Create Note</span>
        </button>


      </div>

      <div class="full-width clearfix">

        <app-message-timeline class="clearfix" [messageType]="'note'" [contractID]="contract['ID']">
        </app-message-timeline>
      </div>
    </div>


    <div class="full-width clearfix" *ngIf="isCreditCheckLoaded==true" [hidden]="selectedTab != 'credit_check'"
      @simpleFadeAnimation>

      <div class="full-width clearfix" *ngIf="customerID && vedaLoad == true ">


        <app-empty-list-message *ngIf="!vedaHTML" style=" margin: auto !important;" [title]="'No Report Found'"
          [message]="'We could not generate the VEDA Report'">
        </app-empty-list-message>
        <div class="full-width clearfix" *ngIf="vedaHTML"
          style="background: #f5f5f5;padding: 16px;margin-left: -16px;margin-top: -30px; border-bottom: 1px solid #e5e5e5; ">
          <h3 class="sr-title pull-left rm-m  mt">Credit Check Summary</h3>

          <button class=" btn pull-right " (click)="vedaHTMLView()" mat-raised-button color="accent">
            Full report <mat-icon class="fas fa-file-contract sml"></mat-icon>
          </button>

          <button class=" btn  pull-right smr" (click)="vedaPDFView()" mat-icon-button color="accent">
            <mat-icon class="fas fa-file-pdf"></mat-icon>
          </button>
          <button class=" btn  pull-right smr" (click)="getHTML(true,true)" mat-icon-button color="primary">
            <mat-icon class="fas fa-sync-alt"></mat-icon>
          </button>





        </div>

        <div class="full-width smt clearfix" *ngIf="vedaHTML">
          <app-inner-html-isolated class="full-width clearfix" [content]="vedaHTML"></app-inner-html-isolated>

        </div>

      </div>


    </div>


    <div class="full-width clearfix" *ngIf="isMessageLoaded==true" [hidden]="selectedTab != 'messages'"
      @simpleFadeAnimation>

      <app-message-timeline class="clearfix" [messageType]="'message'" [contractID]="contract['ID']">
      </app-message-timeline>
    </div>



    <div class="full-width clearfix" *ngIf="isCapacityCheckLoaded==true" [hidden]="selectedTab != 'capacity_check'"
      @simpleFadeAnimation>
      <div class="full-width clearfix">
        <mat-tab-group class="sub-tab-group" style="margin-top: -31px !important;">
          <!-- capacity check EP screen -->
          <mat-tab #capcheckov label="Capacity Check">
            <ng-template mat-tab-label>
              <mat-icon class="fas fa-check-double"></mat-icon>
              Capacity Check
            </ng-template>

            <ng-template matTabContent>
              <app-contract-capacity-check [contractID]="contract['ID']" [isDisplayFull]="false"
                [checkstatus]="contract['CapacityCheck.Status']">
              </app-contract-capacity-check>
            </ng-template>
          </mat-tab>

          <!-- capacity check personal circumstances -->
          <mat-tab #overview label="Personal Circumstances">
            <ng-template mat-tab-label>
              <mat-icon class="fas fa-user-check"></mat-icon>
              Personal Circumstances
            </ng-template>
            <ng-template matTabContent>
              <!-- <mat-card class="mb no-pad">
            <div class="colour-stripe "></div>


            <div class="row clearfix">
              <div class="column"> -->
              <app-personal-circumstances [contractID]="contract['ID']"
                [checkstatus]="contract['CapacityCheck.Status']">
              </app-personal-circumstances>
              <!-- </div>
            </div>
          </mat-card> -->
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>




    <div class="full-width clearfix" *ngIf="isIDCheckLoaded==true" [hidden]="selectedTab != 'ID_check'"
      @simpleFadeAnimation>
      <div class="  full-width  clearfix ">
        <app-contract-identity-check class="  full-width  clearfix " [contractID]="contract['ID']" [patient]="customer"
          [checkstatus]="contract['IdentityCheckStatus.Status']">
        </app-contract-identity-check>



      </div>
    </div>


    <div class="full-width clearfix" *ngIf="isAuditHistoryLoaded==true" [hidden]="selectedTab != 'audit_history'"
      @simpleFadeAnimation>
      <div class="  full-width  clearfix ">
        <app-event-log-list [largeHeading]="false" [type]="'contract'" [onlyAutoAction]="true" [isExpanded]="true"
          [contractID]="contract['ID']">
        </app-event-log-list>


      </div>
    </div>






    <!-- <div class="row clearfix">
    {{contract['contract.Status.Label']}}
</div> -->
  </mat-card>
</div>
