import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'admin-system-settings',
  templateUrl: './admin-system-settings.component.html',
  styleUrls: ['./admin-system-settings.component.css'],
})
export class AdminSystemSettingsComponent implements OnInit {
  updateModes = [
    { value: '0', viewValue: 'Automatic' },
    { value: '1', viewValue: 'Manual' },
  ];

  updateFrequencys = [
    { value: '0', viewValue: 'Hourly' },
    { value: '1', viewValue: 'Nightly' },
    { value: '2', viewValue: 'Twice Weekly' },
    { value: '3', viewValue: 'Weekly' },
  ];

  updateMethods = [
    { value: '0', viewValue: 'Merge' },
    { value: '1', viewValue: 'Replace' },
  ];

  //  dynamically get timezones
  timeZones = [
    { value: '0', viewValue: '+8 GMT (Perth, Singapore)' },
    { value: '1', viewValue: '+9 GMT' },
  ];

  checked = true;

  constructor() {}

  ngOnInit() {}
}
