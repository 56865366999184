/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-prospect-view.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../customer/customer-view/customer-view.component.ngfactory";
import * as i3 from "../../customer/customer-view/customer-view.component";
import * as i4 from "@angular/common";
import * as i5 from "../../customer/shared/customer.service";
import * as i6 from "@angular/router";
import * as i7 from "../../../core/authentication/shared/authentication.service";
import * as i8 from "../shared/customerProspect.service";
import * as i9 from "../../../shared/services/utils.service";
import * as i10 from "../../product/shared/product.service";
import * as i11 from "@angular/material/dialog";
import * as i12 from "../../existing-patient/prospect-view/prospect-view.component.ngfactory";
import * as i13 from "../../existing-patient/prospect-view/prospect-view.component";
import * as i14 from "../../existing-patient/shared/existing-patient.service";
import * as i15 from "../../customer/customer-print-view/customer-print-view.component.ngfactory";
import * as i16 from "../../customer/customer-print-view/customer-print-view.component";
import * as i17 from "../../../shared/services/header.service";
import * as i18 from "../../existing-patient/prospect-print-view/prospect-print-view.component.ngfactory";
import * as i19 from "../../existing-patient/prospect-print-view/prospect-print-view.component";
import * as i20 from "./customer-prospect-view.component";
var styles_CustomerProspectViewComponent = [i0.styles];
var RenderType_CustomerProspectViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerProspectViewComponent, data: {} });
export { RenderType_CustomerProspectViewComponent as RenderType_CustomerProspectViewComponent };
function View_CustomerProspectViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-view", [["class", ""]], null, [[null, "closeModal"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeModal" === en)) {
        var pd_0 = (_co.closeEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CustomerViewComponent_0, i2.RenderType_CustomerViewComponent)), i1.ɵdid(1, 638976, null, 0, i3.CustomerViewComponent, [i4.Location, i5.CustomerService, i6.ActivatedRoute, i7.AuthenticationService, i8.CustomerProspectService, i6.Router, i9.UtilsService, i10.ProductService, i11.MatDialog, [2, i11.MAT_DIALOG_DATA]], { customerID: [0, "customerID"] }, { closeModal: "closeModal" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customerID; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CustomerProspectViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-prospect-view", [["class", ""]], null, [[null, "closeModal"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeModal" === en)) {
        var pd_0 = (_co.closeEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_ProspectViewComponent_0, i12.RenderType_ProspectViewComponent)), i1.ɵdid(1, 638976, null, 0, i13.ProspectViewComponent, [i4.Location, i6.ActivatedRoute, i14.ExistingPatientService, i7.AuthenticationService, i8.CustomerProspectService, i6.Router, i9.UtilsService, i10.ProductService, [2, i11.MAT_DIALOG_DATA]], { prospectID: [0, "prospectID"] }, { closeModal: "closeModal" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.prospectID; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CustomerProspectViewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-print-view", [["class", ""]], null, null, null, i15.View_CustomerPrintViewComponent_0, i15.RenderType_CustomerPrintViewComponent)), i1.ɵdid(1, 638976, null, 0, i16.CustomerPrintViewComponent, [i4.Location, i5.CustomerService, i6.ActivatedRoute, i7.AuthenticationService, i8.CustomerProspectService, i6.Router, i17.HeaderService, i9.UtilsService, i10.ProductService, [2, i11.MAT_DIALOG_DATA]], { customerID: [0, "customerID"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customerID; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CustomerProspectViewComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-prospect-print-view", [["class", ""]], null, null, null, i18.View_ProspectPrintViewComponent_0, i18.RenderType_ProspectPrintViewComponent)), i1.ɵdid(1, 638976, null, 0, i19.ProspectPrintViewComponent, [i4.Location, i6.ActivatedRoute, i14.ExistingPatientService, i7.AuthenticationService, i8.CustomerProspectService, i6.Router, i9.UtilsService, i10.ProductService, [2, i11.MAT_DIALOG_DATA]], { prospectID: [0, "prospectID"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.prospectID; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CustomerProspectViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerProspectViewComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerProspectViewComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerProspectViewComponent_3)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerProspectViewComponent_4)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customerID; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.prospectID; _ck(_v, 3, 0, currVal_1); var currVal_2 = ((_co.printMode == true) && _co.customerID); _ck(_v, 5, 0, currVal_2); var currVal_3 = ((_co.printMode == true) && _co.prospectID); _ck(_v, 7, 0, currVal_3); }, null); }
export function View_CustomerProspectViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-prospect-view", [], null, null, null, View_CustomerProspectViewComponent_0, RenderType_CustomerProspectViewComponent)), i1.ɵdid(1, 638976, null, 0, i20.CustomerProspectViewComponent, [i7.AuthenticationService, i8.CustomerProspectService, [2, i11.MAT_DIALOG_DATA]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerProspectViewComponentNgFactory = i1.ɵccf("app-customer-prospect-view", i20.CustomerProspectViewComponent, View_CustomerProspectViewComponent_Host_0, { patientID: "patientID", printMode: "printMode" }, {}, []);
export { CustomerProspectViewComponentNgFactory as CustomerProspectViewComponentNgFactory };
