<div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div class="row clearfix" *ngIf="cardID && medicalHistoryID == null && displayMedicalHistoryDetail == false">
  <button class="clearfix pull-right" (click)="inviteMedicalHistory()" mat-raised-button color="primary">
    <mat-icon>add</mat-icon>
    Health history invitation
  </button>
</div>

<div [hidden]="listDB.data.length == 0">
  <div [hidden]="displayMedicalHistoryDetail == true">
    <h1 class="dataHeader">
      <!-- Contracts -->
      Health History
      <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
    </h1>
    <mat-card class="list-card">
      <!-- Search -->
      <div class="row clearfix searchArea primary-gradient-img">
        <div class="row justify flex">
          <div class="full-width clearfix flex animated fadeInDown">
            <mat-icon class="mt">search</mat-icon>

            <mat-form-field class="margin-btm" appearance="outline">
              <mat-label>{{"KEYWORD.patient" | translate | titlecase}} Name</mat-label>
              <input
                class="clearfix"
                (input)="setFilter($event.target.value, 'Card.Name')"
                name="PatientName"
                [placeholder]="('KEYWORD.patient' | translate | titlecase) + ' Name'"
                matInput
              />
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- Table -->
      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->
        <!-- ID Column -->
        <ng-container cdkColumnDef="Date">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Created At</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="viewMedicalHistory(row['ID'], row['Card_key'])"
            matTooltip="Created at: {{ row['Date.Activated'] | customDateTime }}"
          >
            {{ row['Date.Activated'] | customDateTime }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="profilePicture" sticky>
          <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
          <mat-cell class="text-center" *cdkCellDef="let row">
            <app-customer-prospect-view-picture class="supplierIconAppointment" [patientID]="row['Card_key']">
            </app-customer-prospect-view-picture>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="PatientName" sticky>
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> {{ "KEYWORD.patient" | translate | titlecase }}Name
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row" matTooltip="{{ row['Card.Name'] }}">
            <span *ngIf="!row['isMerged']; else merged" (click)="viewMedicalHistory(row['ID'], row['Card_key'])">
              {{ row['Card.Name'] }}
            </span>

            <ng-template #merged>
              <span class="full-width flex">
                <span class="full-width" (click)="viewMedicalHistory(row['ID'], row['Card_key'])">
                  {{ row['Card.Name'] }}
                </span>
                <span class="btn-show-all full-width ml text-right mr" (click)="expendItems(row)">
                  Various (show all <strong *ngIf="row.mergedLength">{{ row.mergedLength }}</strong
                >)
                </span>
              </span>
            </ng-template>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Status">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Status</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewMedicalHistory(row['ID'], row['Card_key'])">
            <span
              class="list-label"
              *ngIf="row['Status'] == 'Complete'"
              [style.background]="'#00d07a'"
              matTooltip="Completed at: {{ row['Date.Completed'] | customDate }}"
            >
              {{ row['Status'] }}
            </span>

            <span
              class="list-label"
              *ngIf="row['Status'] != 'Complete'"
              [style.background]="'#ff5722'"
              matTooltip="{{ row['Status'] }}"
            >
              {{ row['Status'] }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Progress">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Progress</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="viewMedicalHistory(row['ID'], row['Card_key'])"
            matTooltip="Progress: {{ getProgress(row) }}%"
          >
            <span class="list-label progress-case" [style.background]="getProgressColor(getProgress(row))">
              {{ getProgress(row) }}%
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="updated">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Last updated</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="viewMedicalHistory(row['ID'], row['Card_key'])"
            matTooltip="{{ row['LastModified_Human'] | customDateTime }}"
          >
            <span class="list-label" [style.background]="utils.daysPastColor(row['LastModified_Human'])">
              {{ row['LastModified_Human'] | TimeLabelPast }} ago</span
            >
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button *ngIf="row['ID']" (click)="viewMedicalHistory(row['ID'], row['Card_key'])" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span>View Health History</span>
                </button>

                <button *ngIf="row['Card_key']" (click)="viewPatient(row['Card_key'])" mat-menu-item>
                  <mat-icon class="fas fa-user"></mat-icon>
                  <span>{{ "KEYWORD.patient" | translate | titlecase }} Profile</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginator
        [length]="dataSource.filteredData.length"
        [pageIndex]="0"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
      >
      </mat-paginator>
    </mat-card>
  </div>

  <div *ngIf="medicalHistoryID != null && displayMedicalHistoryDetail == true">
    <div class="pull-right rel buttonrow" *ngIf="showDetailsInList == true">
      <button
        class="clearfix pull-right"
        *ngIf="cardID"
        (click)="inviteMedicalHistory()"
        mat-raised-button
        color="primary"
      >
        <mat-icon>add</mat-icon>
        Health history invitation
      </button>

      <button class="pull-right btn-back" (click)="cancelViewMedicalHistory()" mat-raised-button color="accent">
        <mat-icon>chevron_left</mat-icon>

        <span> Show medical histories </span>
      </button>
    </div>
    <app-medical-history-view [medicalHistoryID]="medicalHistoryID"></app-medical-history-view>
    <div class="full-width text-center" *ngIf="showDetailsInList == false">
      <button class="mb mt pull-left" (click)="cancelViewMedicalHistory()" mat-raised-button color="primary">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Health History list
      </button>
    </div>
  </div>
</div>
<div *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <app-empty-list-message></app-empty-list-message>
</div>
