import { EventEmitter } from '@angular/core';
import { environment } from 'environments/environment';
import * as $ from 'jquery';
import { NotifyAppComponent } from './notify-app-component';

export class Settings {
  static global = {};

  public static refreshSettings = new EventEmitter();

  public static getIcon() {
    let iconUrl = 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/favicon.ico';

    if (Settings.global['BrandingIcon']) {
      iconUrl = environment.nodeUrl + '/files/file-view/' + Settings.global['BrandingIcon'];
    }

    return iconUrl;
  }

  public static getCharacterArray() {
    const characterArray = [];

    const imageArray = Settings.getImagesArray();

    for (let k = 2; k < imageArray.length; k++) {
      characterArray.push(imageArray[k]);
    }

    return characterArray;
  }

  public static getImagesArray() {
    const __mainBg = {
      id: 'BrandingBackgroundImageMerchant',
      source: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/background.png',
      selector: 'site_background',
      title: 'Background image',
      description:
        "This is your website's background image. It needs to be a JPG file no more than 4mb in size, and minimum 1920x1080px.",
    };

    const imageArray = [
      {
        id: 'BrandingBackgroundImageMerchant',
        source: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/background.png',
        selector: 'site_background',
        title: 'Background image',
        description:
          "This is your website's background image. It needs to be a JPG file no more than 4mb in size, and minimum 1920x1080px.",
      },

      {
        id: 'BrandingBackgroundImageConsumer',
        source: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/background.png',
        selector: 'site_background_consumer',
        title: 'Background image',
        description:
          "This is your website's background image. It needs to be a JPG file no more than 4mb in size, and minimum 1920x1080px.",
      },

      {
        id: 'BrandingBackgroundImagePromoter',
        source: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/background.png',
        selector: 'site_background_promoter',
        title: 'Background image',
        description:
          "This is your website's background image. It needs to be a JPG file no more than 4mb in size, and minimum 1920x1080px.",
      },

      {
        id: 'BrandingBackgroundImageSupplier',
        source: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/background.png',
        selector: 'site_background_supplier',
        title: 'Background image',
        description:
          "This is your website's background image. It needs to be a JPG file no more than 4mb in size, and minimum 1920x1080px.",
      },

      {
        id: 'BrandingBackgroundImageFunder',
        source: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/background.png',
        selector: 'site_background_funder',
        title: 'Background image',
        description:
          "This is your website's background image. It needs to be a JPG file no more than 4mb in size, and minimum 1920x1080px.",
      },

      {
        id: 'BrandingLogoImage',
        source: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/logo.png',
        selector: 'site_logo',
        title: 'Site Logo',
        description: "This is your company's logo, in PNG format.",
      },
      {
        id: 'backgroundPatternId',
        source: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/pattern.png',
        selector: 'background_pattern',
        title: 'Background Pattern',
        description: "This is a subtle, repeating pattern that can enhance your site's background.",
      },
      {
        id: 'loadingGifId',
        source: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/loader.gif',
        selector: 'loading_gif',
        title: 'Loading Gif',
        description: "This is your company's Loading Gif, in Gif format.",
      },
      {
        id: 'userDataLoadingId',
        source: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/userDataLoader.gif',
        selector: 'user_data_loading',
        title: 'User Data Loading Gif',
        description: "This is your company's User Data Loading Gif, in Gif format",
      },
      {
        id: 'characterWarningId',
        source:
          'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/character/arms-folded.png',
        selector: 'character_warning',
        title: 'Warning ',
        description: 'Warning ',
      },
      {
        id: 'characterErrorId',
        source:
          'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/character/dent_male_error.png',
        selector: 'character_error',
        title: 'Error ',
        description: 'Error ',
      },
      {
        id: 'characterCommenceApplicationId',
        source:
          'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/character/commence-application-dentist.png',
        selector: 'character_commence_application',
        title: 'Commance Application ',
        description: 'Commance Application ',
      },

      {
        id: 'characterPairId',
        source:
          'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/character/dentist_hero_pair.png',
        selector: 'character_Pair',
        title: 'Hero Pair ',
        description: 'Hero Pair ',
      },
      {
        id: 'characterPaymentScheduleId',
        source:
          'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/character/dentist_payment_schedule.png',
        selector: 'character_payment_schedule',
        title: 'Payment Schedule ',
        description: 'Payment Schedule ',
      },
      {
        id: 'characterSecrectId',
        source:
          'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/character/dentist_secrets.png',
        selector: 'character_secrect',
        title: 'Secrect ',
        description: 'Secrect ',
      },
      {
        id: 'characterClipboardId',
        source:
          'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/character/dentist-clipboard.png',
        selector: 'character_clipboard',
        title: 'Clip Board ',
        description: 'Clip Board ',
      },
      {
        id: 'characterClipboardThumbsupId',
        source:
          'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/character/dentist-clipboard-thumbsup.png',
        selector: 'character_clipboard_thumbsup',
        title: 'Clip Board Thumps Up ',
        description: 'Clip Board Thumps Up ',
      },
      {
        id: 'characterThumbsupId',
        source:
          'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/character/dentist-thumbs-up.png',
        selector: 'character_thumbsup',
        title: 'Thumps Up ',
        description: 'Thumps Up ',
      },
      {
        id: 'characterIntroId',
        source:
          'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/character/dent-topIntro-thumbsup.png',
        selector: 'character_intro',
        title: 'Introduction ',
        description: 'Introduction ',
      },
      {
        id: 'characterTimeoutId',
        source:
          'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/character/dent-topIntro-thumbsup.png',
        selector: 'character_time_error',
        title: 'Session Timeout ',
        description: "Dialog displayed when a user session reaches it's expiry time",
      },
    ];

    for (let k = 0; k < imageArray.length; k++) {
      if (
        (imageArray[k]['id'] == 'BrandingBackgroundImageConsumer' ||
          imageArray[k]['id'] == 'BrandingBackgroundImageSupplier' ||
          imageArray[k]['id'] == 'BrandingBackgroundImageFunder' ||
          imageArray[k]['id'] == 'BrandingBackgroundImagePromoter') &&
        !Settings.global[imageArray[k]['id']]
      ) {
        imageArray[k]['source'] = environment.nodeUrl + '/files/file-view/' + Settings.global[__mainBg['id']];
      } else if (Settings.global[imageArray[k]['id']]) {
        imageArray[k]['source'] = environment.nodeUrl + '/files/file-view/' + Settings.global[imageArray[k]['id']];
      }
    }

    return imageArray;
  }

  public static applyImages() {
    let iconUrl = 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/favicon.ico';

    if (Settings && Settings.global) {
      if (Settings.global['BrandingIcon']) {
        iconUrl = environment.nodeUrl + '/files/file-view/' + Settings.global['BrandingIcon'];
      }

      const oldLink = document.getElementById('dynamic-favicon');
      if (oldLink && oldLink['href'] && oldLink['href'] != iconUrl) {
        const link = document.createElement('link');

        link.id = 'dynamic-favicon';
        link.rel = 'icon';
        link.href = iconUrl;
        if (oldLink) {
          document.head.removeChild(oldLink);
        }
        document.head.appendChild(link);
      }

      const imageArray = Settings.getImagesArray();

      for (let k = 0; k < imageArray.length; k++) {
        const sl = document.getElementsByClassName(imageArray[k]['selector']);

        for (let i = 0; i < sl.length; i++) {
          if (sl[i]['tagName'] == 'DIV') {
            if (sl[i]['style']['background-image'] != "url('" + imageArray[k]['source'] + "')") {
              sl[i]['style']['background-image'] = "url('" + imageArray[k]['source'] + "')";
            }

            sl[i]['style']['background-repeat'] = 'no-repeat';

            sl[i]['style']['background-position'] = 'center';
          } else if (sl[i]['tagName'] == 'IMG') {
            if (sl[i]['src'] != imageArray[k]['source']) {
              sl[i]['src'] = imageArray[k]['source'];
            }
          }
        }
      }
    }
  }

  public static getContext() {
    if (Settings.global && Settings.global['context']) {
      return Settings.global['context'];
    }

    return null;
  }

  public static getLabelSource() {
    if (Settings.global && Settings.global['labelSource']) {
      return Settings.global['labelSource'];
    }

    return null;
  }

  public static applySettings() {
    try {
      if (Settings.global && Settings.global['_id'] && environment.nodeUrl) {
        Settings.applyImages();
      }

      if (Settings.global && Settings.global['pageTitle']) {
        document.title = Settings.global['pageTitle'];
      }

      Settings.applyTheme(Settings.global['cssId']);
    } catch (e) {
      NotifyAppComponent.navigation.emit('/500-error');
    }
  }

  public static applyTheme(id) {
    $('#loaderForce').show();
    const cssId = 'themeCss';
    const head = document.getElementsByTagName('head')[0];
    let link = document.getElementById(cssId);
    if (id && id != null) {
      if (link) {
        link.remove();
      }
      link = document.createElement('link');
      link.id = cssId;
      link['rel'] = 'stylesheet';
      link['type'] = 'text/css';
      link['href'] = environment.nodeUrl + '/files/file-view/' + id;
      link['media'] = 'all';
      head.appendChild(link);
      $('#loaderForce').hide();
    } else {
      if (link) {
        link.remove();
      }
      link = document.createElement('link');
      link.id = cssId;
      link['rel'] = 'stylesheet';
      link['type'] = 'text/css';
      link['href'] = './assets/styles/theme.css';
      link['media'] = 'all';
      head.appendChild(link);
      $('#loaderForce').hide();
    }
  }

  constructor() {}
}

export class PublicSettings {
  [key: string]: any;

  _id: string;
  BrandingLogoImage: string;
  BrandingBackgroundImageMerchant: string;
  BrandingBackgroundImageConsumer: string;
  BrandingBackgroundImageSupplier: string;
  BrandingBackgroundImagePromoter: string;
  BrandingBackgroundImageFunder: string;
  BrandingIcon: string;
  address: string;
  nodeJSServer: string;
  nodeJSPort: number;
  website: string;
  lastUpdated: string;
  createdAt: string;
  contactPhone: string;
  contactEmail: string;
  contactName: string;
  currencyDigits: string;
  currencyDisplaySymbol: boolean;
  currencyCode: string;
  timeFormat: string;
  dateTimeFormat: string;
  dateFormat: string;
  fileAllowedExt: string[];
  fileMaxSize: number;
  storedClientInfoNumber: number;
  storedGeoLocationNumber: number;
  listItemNumber: number;
  currency: string;
  availableLanguages: string[];
  language: string;
  consumerAuthenticationDuration: number;
  recordsLifeTime: number;
  logsLifeTime: number;
  sessionConsumerLifeTime: number;
  sessionRemeberedConsumerLifeTime: number;
  sessionRemeberedLifeTime: number;
  sessionLifeTime: number;
  warningColor: string;
  accentColor: string;
  primaryColor: string;
  copyRight: string;
  snapLine: string;
  brandName: string;
  pageTitle: string;
  updatedByName: string;
  ECversion: string;
  mapApi: string;
  mapKleberResult: number;
  mapKleberAutoComplet: number;
  theme: string;
  isConsumerLogin: boolean;
  context: string;
  labelSource: string;
  mapValidator: string;
  phoneValidator: string;
  emailValidator: string;
  defaultCountryCode: string;
  defaultCountryPhonePrefix: string;
  defaultLandPhonePrefix: string;
  defaultMobilePhonePrefix: string;
  displayPhonePrefix: boolean;
  dynamicPhonePrefix: boolean;
  isKleberPhoneStrictMode: boolean;
  isKleberEmailStrictMode: boolean;
  phoneFormat: string;
  landPhoneFormat: string;
  listPageSize: number;
  isWakandaIntegrated: boolean;
  wakandaLALink: string;
  wakandaPPLink: string;
  wakandaInvitationCardLink: string;
  twitter: string;
  facebook: string;
  youtube: string;
  instagram: string;
  publicWebSite: string;
  hubSpotUserId: string;
  cssId: string;
  themeId: string;
  characterWarningId: string;
  characterErrorId: string;
  characterCommenceApplicationId: string;
  characterPairId: string;
  characterPaymentScheduleId: string;
  characterSecrectId: string;
  characterClipboardId: string;
  characterClipboardThumbsupId: string;
  characterThumbsupId: string;
  characterIntroId: string;
  loadingGifId: string;
  userDataLoadingId: string;
  mapGooleApiKey: string;
  themeDefaultScssColors: { isPalette: boolean; name: string; palette: string; color: string }[];
  ThemeDefaultScssFile: string;
  chartColors: string[];
  chartShowXAxis: boolean;
  chartShowYAxis: boolean;
  chartShowLegend: boolean;
  chartShowXAxisLabel: boolean;
  chartShowYAxisLabel: boolean;
  chartRoundDomains: boolean;
  chartShowGridLines: boolean;
  chartShowDoughnut: boolean;
  chartShowTrimLabels: boolean;
  chartBarPadding: number;
  chartShowTimeline: boolean;
  chartShowRefLabels: boolean;
  chartAutoScale: boolean;
  chartShowLabel: boolean;
  chartGradient: boolean;
  siteOnline: boolean;
  securityCodeLifeTime: number;
  securityCodeMethod: string;
  defaultMessages: { id: string; value: string }[];
  documentTypes: string[];
  googleID: string;
  facebookID: string;
  offlineMessage: string;
  defaultSendInvitationSMS: boolean;
  serverTimeZone: string;
  serverTimeZoneUTC: number;
  merchantFrontendLink: string;
  publicSiteFrontendLink: string;
  consumerFrontendLink: string;
  promoterFrontendLink: string;
  funderFrontendLink: string;
  supplierFrontendLink: string;
  tmpFrontendLink: string;
  activeAWSS3: boolean;
  TinyAPIKey: string;
  isFroalaEditorActive: boolean;
  isUnlayerActive: boolean;
  isTinyEditorActive: boolean;
  calendarGuestBooking: string;
  calendarConsumerBooking: string;
  calendarMerchantBooking: string;
  calendarFunderBooking: string;
  calendarSupplierBooking: string;
  calendarPartnerBooking: string;
  calendarInvestorBooking: string;
  calendarSpecialistBooking: string;
  consumerPublicDrift: string;
  dentistPublicDrift: string;
  specialistPublicDrift: string;
  investorPublicDrift: string;
  merchantProspectPublicSiteDrift: string;
  merchantPublicSiteDrift: string;
  consumerDrift: string;
  merchantDrift: string;
  funderDrift: string;
  patientCardDrift: string;
  supplierDrift: string;
  consumerLoginDrift: string;
  merchantLoginDrift: string;
  funderLoginDrift: string;
  supplierLoginDrift: string;
  errorDrift: string;
  unsubscribeDrift: string;
  dynamicUserTimeZone: boolean;
  MarketingTemplateMasterAndManual: boolean;
  MarketingTemplateDefault: boolean;
  MarketingManual: boolean;
  MarketingIsCampaign: boolean;
  subscriptionPlanStartDays: number;
  subscriptionPlanWaivedPeriod: number;
  subscriptionPlanMinTreatmentPlan: number;
  industryType: string;
  industryProfileTable: string;
  lookupHash: string;
  documentTypeHash: string;
  suburbHash: string;
  appPromoterVersionNumber: string;
  appMerchantVersionNumber: string;
  appConsumerVersionNumber: string;
  appIntegrationVersionNumber: string;
  appPublicSiteVersionNumber: string;
  appPromoterVersionDate: string;
  appMerchantVersionDate: string;
  appConsumerVersionDate: string;
  appIntegrationVersionDate: string;
  appPublicSiteVersionDate: string;
  globalMerchantPhoneNumber: string;
  globalPromoterPhoneNumber: string;
  displayEmojis: boolean;
  invitationLinkOnStep: boolean;
}

export class ModuleOptions {
  [key: string]: boolean;

  isModuleMarketingActive: boolean;
  isModuleDarkModeActive: boolean;
  isModuleDiscountsActive: boolean;
  isModuleStaffActive: boolean;
  isModuleExternalLoginActive: boolean;
  isModuleEmergercyActive: boolean;
  isModuleMapViewActive: boolean;
  isModuleChangeLogActive: boolean;
  isModuleMembershipActive: boolean;
  isModuleReferralActive: boolean;
  isModuleAppointmentActive: boolean;
  isModuleMediaGalleryActive: boolean;
  isModuleCustomMessagesActive: boolean;
  isModuleCustomerActive: boolean;
  isModuleContractActive: boolean;
  isModulePatientHistoryActive: boolean;
  isModuleTreatmentPlanActive: boolean;
  isModuleExperimentalFeaturesActive: boolean;
  isModuleExperimentalFeaturesActiveBeta: boolean;
  isModuleDemoFeaturesActive: boolean;
  isModuleDemoFeaturesActiveBeta: boolean;
  isModuleFacilityActive: boolean;
  isModuleConsumerPortalActive: boolean;
  isModulePatientDocumentActive: boolean;
  isModuleSettlementsActive: boolean;
  isModuleSettlementsAdvancedActive: boolean;
  isModulePracticeActive: boolean;
  isModuleBeneficiaryActive: boolean;
  isModuleInformedConsentActive: boolean;
  isModulePatientsActive: boolean;
  isModuleMyPracticeActive: boolean;
  isModulePerformanceActive: boolean;
  isModuleTipsActive: boolean;
  isModuleOverviewActive: boolean;
  isModuleExtendedProductsActive: boolean;
  isModuleAdvancedTreatmentActive: boolean;
  isModuleTutorialsActive: boolean;
  isModuleQuotebuilderActive: boolean;
  isModuleTreatmentGroupActive: boolean;
  isModuleTreatmentGroupActiveBeta: boolean;
  isModulePatienCardtSecurityActive: boolean;
  isModulePatienCardtSecurityActiveBeta: boolean;
  isModuleMarketingActiveBeta: boolean;
  isModuleDarkModeActiveBeta: boolean;
  isModuleDiscountsActiveBeta: boolean;
  isModuleStaffActiveBeta: boolean;
  isModuleExternalLoginActiveBeta: boolean;
  isModuleEmergercyActiveBeta: boolean;
  isModuleMapViewActiveBeta: boolean;
  isModuleChangeLogActiveBeta: boolean;
  isModuleMembershipActiveBeta: boolean;
  isModuleReferralActiveBeta: boolean;
  isModuleAppointmentActiveBeta: boolean;
  isModuleMediaGalleryActiveBeta: boolean;
  isModuleCustomMessagesActiveBeta: boolean;
  isModuleCustomerActiveBeta: boolean;
  isModuleContractActiveBeta: boolean;
  isModulePatientHistoryActiveBeta: boolean;
  isModuleTreatmentPlanActiveBeta: boolean;
  isModuleConsumerPortalActiveBeta: boolean;
  isModulePatientDocumentActiveBeta: boolean;
  isModuleFacilityActiveBeta: boolean;
  isModuleSettlementsActiveBeta: boolean;
  isModuleSettlementsAdvancedActiveBeta: boolean;
  isModulePracticeActiveBeta: boolean;
  isModuleBeneficiaryActiveBeta: boolean;
  isModuleInformedConsentActiveBeta: boolean;
  isModulePatientsActiveBeta: boolean;
  isModuleMyPracticeActiveBeta: boolean;
  isModulePerformanceActiveBete: boolean;
  isModuleTipsActiveBeta: boolean;
  isModuleOverviewActiveBeta: boolean;
  isModuleExtendedProductsActiveBeta: boolean;
  isModuleAdvancedTreatmentActiveBeta: boolean;
  isModuleTutorialsActiveBeta: boolean;
  isModuleTreatmentTemplateActive: boolean;
  isModuleTreatmentTemplateActiveBeta: boolean;
  isModulePrivateDocumentActiveBeta: boolean;
  isModulePrivateDocumentActive: boolean;
  isModuleNoteFeaturesActive: boolean;
  isModuleNoteFeaturesActiveBeta: boolean;
  isModuleMerchantInformedConsentActive: boolean;
  isModuleMerchantInformedConsentActiveBeta: boolean;
  isModuleWikiActive: boolean;
  isModuleWikiActiveBeta: boolean;
  isModuleMedicationActive: boolean;
  isModuleMedicationActiveBeta: boolean;
  isModuleStockArtActive: boolean;
  isModuleStockArtActiveBeta: boolean;
  isModuleBoostReachActive: boolean;
  isModuleBoostReachActiveBeta: boolean;
  isModulePatientPortalAccessActive: boolean;
  isModulePatientPortalAccessActiveBeta: boolean;
  isModuleProductPermissionActive: boolean;
  isModuleProductPermissionActiveBeta: boolean;
  isModulePracticeLandingPageActive: boolean;
  isModulePracticeLandingPageActiveBeta: boolean;
  isModuleGoogleReviewActive: boolean;
  isModuleGoogleReviewActiveBeta: boolean;
  isModuleAiScansActive: boolean;
  isModuleAiScansActiveBeta: boolean;
  isModuleNewPatientInvitationActive: boolean;
  isModuleNewPatientInvitationActiveBeta: boolean;
  isModuleMarketingSocialMediaActive: boolean;
  isModuleMarketingSocialMediaActiveBeta: boolean;
  isModuleMakeOverActive: boolean;
  isModuleThirdPartyActive: boolean;
  isModulePatientSMSActive: boolean;
  isModuleSMSOfferActive: boolean;
  isModuleDocumentLinkActive: boolean;
}

export type ModuleOption =
  | ''
  | 'isModuleMarketingActive'
  | 'isModuleDarkModeActive'
  | 'isModuleDiscountsActive'
  | 'isModuleStaffActive'
  | 'isModuleExternalLoginActive'
  | 'isModuleEmergercyActive'
  | 'isModuleMapViewActive'
  | 'isModuleChangeLogActive'
  | 'isModuleMembershipActive'
  | 'isModuleReferralActive'
  | 'isModuleAppointmentActive'
  | 'isModuleMediaGalleryActive'
  | 'isModuleCustomMessagesActive'
  | 'isModuleCustomerActive'
  | 'isModuleContractActive'
  | 'isModulePatientHistoryActive'
  | 'isModuleTreatmentPlanActive'
  | 'isModuleExperimentalFeaturesActive'
  | 'isModuleExperimentalFeaturesActiveBeta'
  | 'isModuleDemoFeaturesActive'
  | 'isModuleDemoFeaturesActiveBeta'
  | 'isModuleFacilityActive'
  | 'isModuleConsumerPortalActive'
  | 'isModulePatientDocumentActive'
  | 'isModuleSettlementsActive'
  | 'isModuleSettlementsAdvancedActive'
  | 'isModulePracticeActive'
  | 'isModuleBeneficiaryActive'
  | 'isModuleInformedConsentActive'
  | 'isModulePatientsActive'
  | 'isModuleMyPracticeActive'
  | 'isModulePerformanceActive'
  | 'isModuleTipsActive'
  | 'isModuleOverviewActive'
  | 'isModuleExtendedProductsActive'
  | 'isModuleAdvancedTreatmentActive'
  | 'isModuleTutorialsActive'
  | 'isModuleQuotebuilderActive'
  | 'isModuleTreatmentGroupActive'
  | 'isModuleTreatmentGroupActiveBeta'
  | 'isModulePatienCardtSecurityActive'
  | 'isModulePatienCardtSecurityActiveBeta'
  | 'isModuleMarketingActiveBeta'
  | 'isModuleDarkModeActiveBeta'
  | 'isModuleDiscountsActiveBeta'
  | 'isModuleStaffActiveBeta'
  | 'isModuleExternalLoginActiveBeta'
  | 'isModuleEmergercyActiveBeta'
  | 'isModuleMapViewActiveBeta'
  | 'isModuleChangeLogActiveBeta'
  | 'isModuleMembershipActiveBeta'
  | 'isModuleReferralActiveBeta'
  | 'isModuleAppointmentActiveBeta'
  | 'isModuleMediaGalleryActiveBeta'
  | 'isModuleCustomMessagesActiveBeta'
  | 'isModuleCustomerActiveBeta'
  | 'isModuleContractActiveBeta'
  | 'isModulePatientHistoryActiveBeta'
  | 'isModuleTreatmentPlanActiveBeta'
  | 'isModuleConsumerPortalActiveBeta'
  | 'isModulePatientDocumentActiveBeta'
  | 'isModuleFacilityActiveBeta'
  | 'isModuleSettlementsActiveBeta'
  | 'isModuleSettlementsAdvancedActiveBeta'
  | 'isModulePracticeActiveBeta'
  | 'isModuleBeneficiaryActiveBeta'
  | 'isModuleInformedConsentActiveBeta'
  | 'isModulePatientsActiveBeta'
  | 'isModuleMyPracticeActiveBeta'
  | 'isModulePerformanceActiveBete'
  | 'isModuleTipsActiveBeta'
  | 'isModuleOverviewActiveBeta'
  | 'isModuleExtendedProductsActiveBeta'
  | 'isModuleAdvancedTreatmentActiveBeta'
  | 'isModuleTutorialsActiveBeta'
  | 'isModuleTreatmentTemplateActive'
  | 'isModuleTreatmentTemplateActiveBeta'
  | 'isModulePrivateDocumentActiveBeta'
  | 'isModulePrivateDocumentActive'
  | 'isModuleNoteFeaturesActive'
  | 'isModuleNoteFeaturesActiveBeta'
  | 'isModuleMerchantInformedConsentActive'
  | 'isModuleMerchantInformedConsentActiveBeta'
  | 'isModuleWikiActive'
  | 'isModuleWikiActiveBeta'
  | 'isModuleMedicationActive'
  | 'isModuleMedicationActiveBeta'
  | 'isModuleStockArtActive'
  | 'isModuleStockArtActiveBeta'
  | 'isModuleBoostReachActive'
  | 'isModuleBoostReachActiveBeta'
  | 'isModulePatientPortalAccessActive'
  | 'isModulePatientPortalAccessActiveBeta'
  | 'isModuleProductPermissionActive'
  | 'isModuleProductPermissionActiveBeta'
  | 'isModulePracticeLandingPageActive'
  | 'isModulePracticeLandingPageActiveBeta'
  | 'isModuleGoogleReviewActive'
  | 'isModuleGoogleReviewActiveBeta'
  | 'isModuleAiScansActive'
  | 'isModuleAiScansActiveBeta'
  | 'isModuleNewPatientInvitationActive'
  | 'isModuleNewPatientInvitationActiveBeta'
  | 'isModuleMarketingSocialMediaActive'
  | 'isModuleMarketingSocialMediaActiveBeta'
  | 'isModuleMakeOverActive'
  | 'isModuleThirdPartyActive'
  | 'isModulePatientSMSActive'
  | 'isModuleSMSOfferActive'
  | 'isModuleDocumentLinkActive';
