/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./consumer-redirection.component";
import * as i2 from "@angular/router";
var styles_ConsumerRedirectionComponent = [];
var RenderType_ConsumerRedirectionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConsumerRedirectionComponent, data: {} });
export { RenderType_ConsumerRedirectionComponent as RenderType_ConsumerRedirectionComponent };
export function View_ConsumerRedirectionComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_ConsumerRedirectionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-consumer-redirection", [], null, null, null, View_ConsumerRedirectionComponent_0, RenderType_ConsumerRedirectionComponent)), i0.ɵdid(1, 114688, null, 0, i1.ConsumerRedirectionComponent, [i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConsumerRedirectionComponentNgFactory = i0.ɵccf("app-consumer-redirection", i1.ConsumerRedirectionComponent, View_ConsumerRedirectionComponent_Host_0, {}, {}, []);
export { ConsumerRedirectionComponentNgFactory as ConsumerRedirectionComponentNgFactory };
