import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { Settings } from '../../types/settings';

@Component({
  selector: 'app-calendly',
  styleUrls: ['./calendly.component.css'],
  templateUrl: './calendly.component.html',
})
export class CalendlyComponent implements OnInit {
  @ViewChild('container', { static: true }) container: ElementRef;
  myExternalPageLink =
    'https://calendly.com/smileright/smile-right-training-session?hide_event_type_details=1&background_color=f7f7f7&text_color=585858&primary_color=f42938';

  myTemplat;
  iframeSrc;

  settings = Settings.global;
  close = new EventEmitter();
  sessionType = 'guest';

  constructor(private sanitizer: DomSanitizer, private authenticationService: AuthenticationService) {}

  public ngOnInit() {
    this.authenticationService.getSessionType().subscribe((res) => {
      this.sessionType = res;

      let link;

      if (this.sessionType == 'guest') {
        link = this.settings['calendarGuestBooking'];
      } else if (this.sessionType == 'merchant') {
        link = this.settings['calendarMerchantBooking'];
      } else if (this.sessionType == 'admin') {
        link = this.settings['calendarMerchantBooking'];
      } else if (this.sessionType == 'supplier') {
        link = this.settings['calendarSupplierBooking'];
      } else if (this.sessionType == 'funder') {
        link = this.settings['calendarFunderBooking'];
      } else if (this.sessionType == 'consumer') {
        link = this.settings['calendarConsumerBooking'];
      }

      if (link && link.indexOf('calendly.com') != -1) {
        this.myExternalPageLink = link;
        this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.myExternalPageLink);
      } else {
        window.open(link, '_blank');
        this.close.emit(true);
      }
    });
  }

  closeEvent() {
    this.close.emit(true);
  }
}
