<div class="rel dentist-card" *ngIf="merchant" @onChangeAnimation>
  <div class="animate">
    <mat-dialog-content class="DentistProfile">
      <div class="card-header primary-gradient-img clearfix stacked">
        <button class="btn-close btn-clear mat-button" (click)="closeEvent()">
          <mat-icon>clear</mat-icon>
        </button>

        <div class="row clearfix">
          <div class="row titleArea clearfix">
            <app-ep-document-view-image
              class="profile-lead-image"
              [hideZoomButton]="true"
              [defaultPicture]="
                'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/practice-pic.png'
              "
              [link]="profileLink"
              tabindex="0"
              alt="Your default profile image"
            >
            </app-ep-document-view-image>
            <h2 class="summary-header rm-m">
              {{ merchant['TradingAs'] }}
              <span class="subLabel" *ngIf="merchant['addresses.Calculated']">{{
                merchant['addresses.Calculated']
                }}</span>
            </h2>
          </div>
        </div>
      </div>

      <div class="grey-row upper clearfix mb">
        <div class="socials clicky" (click)="emailPractice(merchant['emails.Email'])">
          <mat-icon class="pull-left mr">email</mat-icon>
          <div class="text-left">
            <label>Email</label>
            <p class="rm-mt">{{ merchant['emails.Email'] }}</p>
          </div>
        </div>
      </div>

      <div class="row clearfix profile">
        <div class="row clearfix bio" *ngIf="merchant['Description']">
          <read-more [text]="merchant['Description']" [maxLength]="450"></read-more>
        </div>

        <hr/>

        <div
          class="row clearfix flex flex-wrap mb"
          *ngIf="
            merchant['URL'] ||
            merchant['Facebook'] ||
            merchant['Twitter'] ||
            merchant['LinkedIn'] ||
            merchant['Instagram'] ||
            merchant['Pinterest'] ||
            merchant['Tumblr'] ||
            merchant['Vimeo']
          "
        >
          <div class="row clearfix socials">
            <!-- open social links in new tab -->
            <button
              *ngIf="merchant['URL']"
              (click)="openSocialLink(merchant['URL'])"
              mat-stroked-button
              matTooltip="Visit website"
            >
              Website <mat-icon class="fas fa-globe"></mat-icon>
            </button>
            <button
              *ngIf="merchant['Facebook']"
              (click)="openSocialLink(merchant['Facebook'])"
              mat-stroked-button
              matTooltip="Visit Facebook page"
            >
              Facebook <mat-icon class="fab fa-facebook"></mat-icon>
            </button>
            <button
              *ngIf="merchant['Twitter']"
              (click)="openSocialLink(merchant['Twitter'])"
              mat-stroked-button
              matTooltip="Visit Twitter page"
            >
              Twitter <mat-icon class="fab fa-twitter"></mat-icon>
            </button>
            <button
              *ngIf="merchant['LinkedIn']"
              (click)="openSocialLink(merchant['LinkedIn'])"
              mat-stroked-button
              matTooltip="Visit LinkedIn page"
            >
              LinkedIn <mat-icon class="fab fa-linkedin"></mat-icon>
            </button>
            <button
              *ngIf="merchant['Instagram']"
              (click)="openSocialLink(merchant['Instagram'])"
              mat-stroked-button
              matTooltip="Visit Instagram page"
            >
              Instagram <mat-icon class="fab fa-instagram"></mat-icon>
            </button>
            <button
              *ngIf="merchant['Pinterest']"
              (click)="openSocialLink(merchant['Pinterest'])"
              mat-stroked-button
              matTooltip="Visit Pinterest page"
            >
              Pinterest <mat-icon class="fab fa-pinterest"></mat-icon>
            </button>
            <button
              *ngIf="merchant['Tumblr']"
              (click)="openSocialLink(merchant['Tumblr'])"
              mat-stroked-button
              matTooltip="Visit Tumblr page"
            >
              Tumblr <mat-icon class="fab fa-tumblr"></mat-icon>
            </button>
            <button
              *ngIf="merchant['Vimeo']"
              (click)="openSocialLink(merchant['Vimeo'])"
              mat-stroked-button
              matTooltip="Visit Vimeo page"
            >
              Vimeo <mat-icon class="fab fa-vimeo"></mat-icon>
            </button>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <button
      class="contact-btn primary-bg"
      *ngIf="merchant['phones.Number']"
      (click)="dialPractice(merchant['phones.Number'])"
      mat-button
    >
      <mat-icon>phone</mat-icon>
      {{ merchant['phones.Number'] | customPhone: 'landLine' }}
    </button>

    <button
      class="contact-btn primary-bg"
      *ngIf="!merchant['phones.Number'] && merchant['mobiles.Number']"
      (click)="dialPractice(merchant['mobiles.Number'])"
      mat-button
    >
      <mat-icon>phone</mat-icon>
      {{ merchant['mobiles.Number'] | customPhone }}
    </button>
  </div>
</div>
