import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';

import { TextEditorViewComponent } from '../text-editor-view/text-editor-view.component';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Range } from 'ngx-quill';
import { ClearTextPipe } from '../../pipes/clear-text.pipe';
import { UtilsClass } from '../../types/utils/utils.class';
import { RootAppComponent } from '../root-component/root-component.component';
import { TextEditorInsertHtmlComponent } from '../text-editor-insert-html/text-editor-insert-html.component';

@Component({
  selector: 'app-text-editor-input',
  templateUrl: './text-editor-input.component.html',
  styleUrls: ['./text-editor-input.component.css'],
  providers: [ClearTextPipe],
})
export class TextEditorInputComponent implements OnInit {
  @Input()
  content;

  @Input()
  toolbar;

  @Input()
  description;

  @Input()
  title = 'Text Content';

  @Input()
  actionLabel = 'Save';

  @Input()
  isAction = true;

  @Input()
  isMultiView = true;
  @Input()
  views = ['editor', 'raw'];
  @Input()
  view = 'editor';

  @Input()
  isPreview = true;

  @Input()
  isRequired = false;

  @Output()
  getContent = new EventEmitter();

  @Output()
  getAction = new EventEmitter();

  isModal = false;

  @Input()
  clear = new EventEmitter();

  @Output()
  validate = new EventEmitter();

  @Output()
  cursorPos = new EventEmitter<number>();

  isValidate = false;
  util = new UtilsClass();

  private quill: any;

  editorOptions = {
    fontSize: 12,
    formatOnType: true,
    formatOnPaste: true,
    language: 'html',
    autoIndent: true,
    autoClosingBrackets: 'always',
    dragAndDrop: true,
    fixedOverflowWidgets: true,
    theme: 'vs-dark',
  };

  options: any = {
    maxLines: 1000,
    printMargin: false,
    enableBasicAutocompletion: true,
  };

  code;

  constructor(
    private ClearTextPipe: ClearTextPipe,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.content) {
        this.content = this.clearText(data.content);

        this.isModal = true;
      }
      if (data.title) {
        this.title = data.title;
        this.isModal = true;
      }

      if (data.description) {
        this.description = data.description;
        this.isModal = true;
      }

      if (data.actionLabel) {
        this.actionLabel = data.actionLabel;
        this.isModal = true;
      }

      if (data.isAction != null) {
        this.isAction = data.isAction;
      }

      if (data.isMultiView != null) {
        this.isMultiView = data.isMultiView;
      }

      if (data.isPreview != null) {
        this.isPreview = data.isPreview;
      }

      if (data.isRequired != null) {
        this.isRequired = data.isRequired;
      }
    }
  }

  ngOnInit() {
    if (this.clear) {
      this.clear.subscribe((res) => {
        this.content = '';
        this.sendResult();
      });
    }
  }

  clearText(d) {
    if (d) {
      d = this.ClearTextPipe.transform(d);
    }

    return d;
  }

  inserHTML() {
    const ref = RootAppComponent.dialog.open(TextEditorInsertHtmlComponent, {
      data: {},
      width: '600px',
      panelClass: 'noCard',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  preview() {
    const ref = RootAppComponent.dialog.open(TextEditorViewComponent, {
      data: {
        title: this.title,
        description: this.description,
        content: this.content,
      },
      width: '800px',
      panelClass: 'noCard',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  sendResult() {
    let d = this.content || '';
    if (d) {
      d = this.clearText(d);
    }
    this.getContent.emit(d);

    this.validateEvent();
  }

  validateEvent() {
    if (this.isRequired == true && !this.content) {
      this.isValidate = false;
    }

    this.isValidate = true;

    this.validate.emit(this.isValidate);
  }

  actionEvent() {
    let d = this.content || '';
    if (d) {
      d = this.clearText(d);
    }

    this.getContent.emit(d);
  }

  onEditorChanged(data: { editor; event; range: Range; oldRange: Range; source }) {
    if (data && data.editor) {
      this.quill = data.editor;
    }

    if (data && data.range) {
      this.cursorPos.emit(data.range.index);
    }
  }

  insertText(index: number, text: string): void {
    this.quill.insertText(index, text, 'user');
  }
}
