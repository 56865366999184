import { Component, OnInit, Optional, Input, Inject, ViewChild, Output, EventEmitter } from "@angular/core";
import { CustomerProspectService } from "../shared/customerProspect.service";
import { AuthenticationService } from "../../../core/authentication/shared/authentication.service";
import { MAT_DIALOG_DATA } from "@angular/material";
import { UtilsService } from "../../../shared/services/utils.service";
import { Settings } from "../../../shared/types/settings";
import { MerchantService } from "../../merchant/shared/merchant.service";
import { DentistService } from "../../dentist/shared/dentist.service";
import { DefaultMessageService } from "../../default-message/shared/default-message.service";

import { DocumentUrlViewComponent } from "../../../shared/components/document-url-view/document-url-view.component";
import { CustomPhonePipe } from "../../../shared/pipes/custom-phone.pipe";
import { UtilsClass } from "../../../shared/types/utils/utils.class";
import { NotifyAppComponent } from "../../../shared/types/notify-app-component";
import { Router } from "@angular/router";
import { WizardComponent } from "angular-archwizard";
import { RootAppComponent } from "../../../shared/components/root-component/root-component.component";
import {
  MessageTemplateCreateEditComponent
} from "../../default-message/message-template-create-edit/message-template-create-edit.component";
import { environment } from "environments/environment";

@Component({
  selector: "app-patient-group-invite-modal",
  templateUrl: "./group-invite-modal.component.html",
  styleUrls: ["./group-invite-modal.component.css"]
})
export class GroupInviteModalComponent implements OnInit {
  @Input()
  patientsID;

  @Input()
  needMedicalHistory;

  @Input()
  merchantID;

  @Input()
  modalType;

  @Output()
  getResult = new EventEmitter();

  close = new EventEmitter();

  isAdminOrPromoter = false;
  isModal = false;

  isModuleCustomMessages = Settings.global["isModuleCustomMessagesActive"];

  merchants = [];
  contacts = [];

  selectedSender;
  selectedMerchant;
  contact;
  merchant;

  defaultMessageLists = [];
  rawMessage;
  message;

  util = new UtilsClass();

  complete = false;
  stopFlying = false;
  flyAway = false;
  sessionType = "merchant";
  @ViewChild(WizardComponent, { static: false }) public wizard: WizardComponent;

  constructor(
    private utilService: UtilsService,
    private customerProspectService: CustomerProspectService,
    private authenticationService: AuthenticationService,
    private merchantService: MerchantService,
    private dentistService: DentistService,
    private defaultMessageSerivce: DefaultMessageService,
    private customePhonePipe: CustomPhonePipe,
    private router: Router,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.patientsID != null) {
        this.patientsID = data.patientsID;
      }

      if (data.merchantID != null) {
        this.merchantID = data.merchantID;
      }

      if (data.needMedicalHistory != null) {
        this.needMedicalHistory = data.needMedicalHistory;
      }

      if (data.modalType != null) {
        this.modalType = data.modalType;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;

        this.utilService.getCurrentAccess().subscribe((access) => {
          if (access) {
            this.isModuleCustomMessages = access["isModuleCustomMessagesActive"];

            if (this.isAdminOrPromoter == true) {
              let p = {
                fields: "ID,Name,FirstName,TradingAs,CalculatedName,Status"
              };

              this.merchantService.getList(p, this.isAdminOrPromoter).subscribe((res) => {
                if (res) {
                  this.merchants = res;

                  if (this.merchantID) {
                    for (var i = 0; i < this.merchants.length; i++) {
                      if (this.merchants[i] && this.merchants[i]["ID"] && this.merchants[i]["ID"] == this.merchantID) {
                        this.merchant = this.merchants[i];
                      }
                    }
                  }
                }
              });
            }

            let __p = {
              fields: "ID,Name,FirstName,TradingAs,CalculatedName"
            };

            this.dentistService.getList(__p, this.isAdminOrPromoter).subscribe((res) => {
              if (res && res.length > 0) {
                this.contacts = res;

                this.authenticationService.getCurrentDentist().subscribe((res) => {
                  if (res) {
                    this.selectedSender = res;

                    for (var i = 0; i < this.contacts.length; i++) {
                      if (
                        this.contacts[i] &&
                        this.contacts[i]["ID"] &&
                        this.selectedSender &&
                        this.selectedSender["ID"] &&
                        this.contacts[i]["ID"] == this.selectedSender["ID"]
                      ) {
                        this.contact = this.contacts[i];
                      }
                    }
                  }
                });
              }
            });

            this.getDefaultMessageList();
          }

          this.authenticationService.getCurrentPractice().subscribe((res) => {
            if (res) {
              this.selectedMerchant = res;
            }
          });
        });
      });
    });
  }

  getDefaultMessageList(merchantID = null) {
    if (this.isModuleCustomMessages == true) {
      if (this.isAdminOrPromoter == false || merchantID == null) {
        let payload = {
          purposeCode: "MerMHInv",
          orderBy: "Label",
          onlyActive: true
        };
        this.defaultMessageSerivce.getDefaultMessageList(payload).subscribe((res) => {
          if (res && res.length > 0) {
            if (this.defaultMessageLists && this.defaultMessageLists.length > 0) {
              this.defaultMessageLists = [];
            }

            for (var i = 0; i < res.length; i++) {
              let r = {
                ID: res[i]["ID"],
                label: res[i]["Label"],
                value: res[i]["Content"]
              };

              this.defaultMessageLists.push(r);
            }
          }
        });
      } else {
        let payload = {
          onlyActive: true,
          purposeCode: "MerMHInv",
          orderBy: "Label",
          merchantID: this.merchantID
        };
        this.defaultMessageSerivce.getDefaultMessageListGlobal(payload).subscribe((res) => {
          if (res && res.length > 0) {
            if (this.defaultMessageLists && this.defaultMessageLists.length > 0) {
              this.defaultMessageLists = [];
            }

            for (var i = 0; i < res.length; i++) {
              let r = {
                ID: res[i]["ID"],
                label: res[i]["Label"],
                value: res[i]["Content"]
              };

              this.defaultMessageLists.push(r);
            }
          }
        });
      }
    }
  }

  addNewMessage() {
    let ref = RootAppComponent.dialog.open(MessageTemplateCreateEditComponent, {
      data: {
        merchantID: this.merchantID,
        purposeCode: "MerMHInv",
        displayProductGroup: false,
        displayPurpose: false
      },
      width: "900px",
      panelClass: "noCard"
    });

    ref.componentInstance.createdMessage.subscribe((data) => {
      if (data) {
        let r = {
          ID: data["ID"],
          label: data["Label"],
          value: data["Content"]
        };

        this.defaultMessageLists.push(r);

        ref.close();
      }
    });

    ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
  }

  viewVideoInfo(videoTitle, docUrl) {
    let ref = RootAppComponent.dialog.open(DocumentUrlViewComponent, {
      data: {
        title: videoTitle,
        description: "",
        documentUrl: docUrl
      },
      width: "800px",
      panelClass: "noCard"
    });
    ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
  }

  exitToDash() {
    this.flyAway = true;
    setTimeout(() => {
      this.router.navigate(["/merchant"]);
    }, 1800);
  }

  changeSender() {
    if (this.contact && this.contact.ID) {
      this.dentistService.getContactDetails(this.contact.ID, {}, this.sessionType).subscribe((res) => {
        if (res) {
          this.selectedSender = res;
          this.message = this.convertMessage();
        }
      });
    }
  }

  convertMessage() {
    let result = this.message;

    let rule = {};

    if (this.selectedMerchant && this.selectedSender && this.rawMessage) {
      if (this.selectedSender["Salutation"]) {
        rule["DentistSalutation"] = this.selectedSender["Salutation"];
      } else {
        rule["DentistSalutation"] = "";
      }

      if (this.selectedSender["FirstName"]) {
        rule["DentistFirstName"] = this.selectedSender["FirstName"];
      } else {
        rule["DentistFirstName"] = "";
      }

      if (this.selectedSender["MiddleName"]) {
        rule["DentistMiddleName"] = this.selectedSender["MiddleName"];
      } else {
        rule["DentistMiddleName"] = "";
      }

      if (this.selectedSender["Name"]) {
        rule["DentistLastName"] = this.selectedSender["Name"];
      } else {
        rule["DentistLastName"] = "";
      }

      if (this.selectedSender["mobiles.Number"]) {
        rule["DentistMobileNumber"] = this.customePhonePipe.transform(this.selectedSender["mobiles.Number"], "false");
      } else {
        rule["DentistMobileNumber"] = "";
      }

      if (this.selectedSender["phones.Number"]) {
        rule["DentistPhoneNumber"] = this.customePhonePipe.transform(this.selectedSender["phones.Number"], "true");
      } else {
        rule["DentistPhoneNumber"] = "";
      }

      if (this.selectedSender["emails.Email"]) {
        rule["DentistEmail"] = this.selectedSender["emails.Email"];
      } else {
        rule["DentistEmail"] = "";
      }

      if (this.selectedSender["addresses.Calculated"]) {
        rule["DentistAddress"] = this.selectedSender["addresses.Calculated"];
      } else {
        rule["DentistAddress"] = "";
      }

      if (this.selectedMerchant["Name"]) {
        rule["PracticeLegalName"] = this.selectedMerchant["Name"];
      } else {
        rule["PracticeLegalName"] = "";
      }

      if (this.selectedMerchant["Name"]) {
        rule["PracticeLegalName"] = this.selectedMerchant["Name"];
      } else {
        rule["PracticeLegalName"] = "";
      }

      if (this.selectedMerchant["addresses.Calculated"]) {
        rule["PracticeAddress"] = this.selectedMerchant["addresses.Calculated"];
      } else {
        rule["PracticeAddress"] = "";
      }

      if (this.selectedMerchant["TradingAs"]) {
        rule["PracticeTradingAs"] = this.selectedMerchant["TradingAs"];
      } else {
        rule["PracticeTradingAs"] = "";
      }

      if (this.selectedMerchant["phones.Number"]) {
        rule["PracticePhoneNumber"] = this.customePhonePipe.transform(this.selectedMerchant["phones.Number"], "true");
      } else {
        rule["PracticePhoneNumber"] = "";
      }

      if (this.selectedMerchant["mobiles.Number"]) {
        rule["PracticeMobileNumber"] = this.customePhonePipe.transform(
          this.selectedMerchant["mobiles.Number"],
          "false"
        );
      } else {
        rule["PracticeMobileNumber"] = "";
      }

      if (this.selectedMerchant["addresses.Calculated"]) {
        rule["PracticeAddress"] = this.selectedMerchant["addresses.Calculated"];
      } else {
        rule["PracticeAddress"] = "";
      }

      if (this.selectedMerchant["ABN"]) {
        rule["PracticeABN"] = this.selectedMerchant["ABN"];
      } else {
        rule["PracticeABN"] = "";
      }

      if (this.selectedMerchant["ACN"]) {
        rule["PracticeACN"] = this.selectedMerchant["ACN"];
      } else {
        rule["PracticeACN"] = "";
      }

      if (this.selectedMerchant["emails.Email"]) {
        rule["PracticeEmail"] = this.selectedMerchant["emails.Email"];
      } else {
        rule["PracticeEmail"] = "";
      }

      if (this.selectedMerchant["Facebook"]) {
        rule["PracticeFacebook"] = this.selectedMerchant["Facebook"];
      } else {
        rule["PracticeFacebook"] = "";
      }

      if (this.selectedMerchant["Instagram"]) {
        rule["PracticeInstagram"] = this.selectedMerchant["Instagram"];
      } else {
        rule["PracticeInstagram"] = "";
      }

      if (this.selectedMerchant["Twitter"]) {
        rule["PracticeTwitter"] = this.selectedMerchant["Twitter"];
      } else {
        rule["PracticeTwitter"] = "";
      }

      if (this.selectedMerchant["LinkedIn"]) {
        rule["PracticeLinkedIn"] = this.selectedMerchant["LinkedIn"];
      } else {
        rule["PracticeLinkedIn"] = "";
      }

      if (this.selectedMerchant["Pinterest"]) {
        rule["PracticePinterest"] = this.selectedMerchant["Pinterest"];
      } else {
        rule["PracticePinterest"] = "";
      }

      if (this.selectedMerchant["Tumblr"]) {
        rule["PracticeTumblr"] = this.selectedMerchant["Tumblr"];
      } else {
        rule["PracticeTumblr"] = "";
      }

      if (this.selectedMerchant["Vimeo"]) {
        rule["PracticeVimeo"] = this.selectedMerchant["Vimeo"];
      } else {
        rule["PracticeVimeo"] = "";
      }

      if (this.selectedMerchant["YouTube"]) {
        rule["PracticeYouTube"] = this.selectedMerchant["YouTube"];
      } else {
        rule["PracticeYouTube"] = "";
      }

      if (this.selectedMerchant["URL"]) {
        rule["PracticeWebSite"] = this.selectedMerchant["URL"];
      } else {
        rule["PracticeWebSite"] = "";
      }

      if (this.selectedMerchant["Twilio_Number"]) {
        rule["DedicatedSMSNumber"] = this.customePhonePipe.transform(this.selectedMerchant["Twilio_Number"], 'false');
      } else {
        rule["DedicatedSMSNumber"] = "";
      }

      if (this.selectedMerchant['Terminal_Code']) {
        rule[
          'SelfServicePage'
        ] = `${environment.nodeUrl}/s/${this.merchant['Terminal_Code']}?t=lp`;
      } else {
        rule['SelfServicePage'] = '';
      }
      if (this.selectedMerchant["Terminal_Code"]) {
        rule[
          "PracitceAppointment"
          ] = `${Settings.global["publicSiteFrontendLink"]}/a/${this.selectedMerchant["Terminal_Code"]}`;
      } else {
        rule["PracitceAppointment"] = "";
      }

      if (this.selectedMerchant["Terminal_Code"]) {
        rule[
          "PracticePage"
          ] = `${Settings.global["publicSiteFrontendLink"]}/l/p/s/${this.selectedMerchant["Terminal_Code"]}`;
      } else {
        rule["PracticePage"] = "";
      }

      if (this.selectedMerchant["Terminal_Code"]) {
        rule[
          "TipsAndOffers"
          ] = `${Settings.global["publicSiteFrontendLink"]}/l/d/s/${this.selectedMerchant["Terminal_Code"]}`;
      } else {
        rule["TipsAndOffers"] = "";
      }

      result = this.util.convertMessage(this.rawMessage, rule);
    }
    return result;
  }

  defaultMessageChange(m) {
    if (m && m.value) {
      this.rawMessage = m.value;
      this.message = this.convertMessage();
    }
  }

  sendResult() {
    if (this.contact && this.contact.ID && this.patientsID) {
      let p = {
        ids: this.patientsID.join(","),
        message: this.message,
        contactID: this.contact.ID,
        merchantID: this.merchantID
      };

      if (this.needMedicalHistory == true) {
        this.customerProspectService.inviteMedicalHistoryGroup(p, this.isAdminOrPromoter).subscribe((res) => {
          if (res) {
            this.getResult.emit(res);

            NotifyAppComponent.displayToast(
              "success",
              "Successful operation",
              "The patient has been successfully invited "
            );

            this.wizard.goToNextStep();
            this.complete = true;
          }
        });
      } else {
        this.customerProspectService.inviteProfileUpdateGroup(p, this.isAdminOrPromoter).subscribe((res) => {
          if (res) {
            this.getResult.emit(res);

            NotifyAppComponent.displayToast(
              "success",
              "Successful operation",
              "The patient has been successfully invited "
            );

            this.wizard.goToNextStep();
            this.complete = true;
          }
        });
      }
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
}
