import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { AiScanCreateComponent } from './ai-scan-create/ai-scan-create.component';
import { AiScanReportViewComponent } from './ai-scan-report-view/ai-scan-report-view.component';
import { AiScansListGlobalComponent } from './ai-scans-list-global/ai-scans-list-global.component';
import { AiScansListOverViewComponent } from './ai-scans-list-overview/ai-scans-list-overview.component';
import { AiScansRoutingModule } from './shared/ai-scans-routing.module';
import { AiScansService } from './shared/services/ai-scans.service';
import { DentalXrService } from './shared/services/dental-xr.service';

@NgModule({
  imports: [SharedModule, AiScansRoutingModule],
  declarations: [
    AiScansListOverViewComponent,
    AiScansListGlobalComponent,
    AiScanCreateComponent,
    AiScanReportViewComponent,
  ],
  exports: [AiScansListOverViewComponent, AiScansListGlobalComponent, AiScanCreateComponent, AiScanReportViewComponent],
  providers: [AiScansService, DentalXrService],
})
export class AiScansModule {}
