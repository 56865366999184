<mat-card class="rel supplier-card brandView clearfix mb" *ngIf="treatment" @ngIfAnimation>
  <div class="card-header primary-gradient-img clearfix inModal rel">
    <div class="row clearfix content">
      <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModal()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <app-brand-view-logo class="supplierIcon pull-left" [brandID]="treatment['ID']"
                             [LastModified]="treatment['LastModified']">
        </app-brand-view-logo>

        <h2 class="summary-header rm-m" *ngIf="treatment['Label'] != 'N/A'">
          {{ treatmentUtil.getLabelTitle(treatment['Label']) }}
          <span class="subLabel">{{ treatmentUtil.getLabelSubTitle(treatment['Label']) }}</span>
        </h2>

        <h2 class="summary-header rm-m" *ngIf="treatment['Label'] == 'N/A' && treatment['Type.Label'] != 'N/A'">
          {{ treatmentUtil.getLabelTitle(treatment['Type.Label']) }}
          <span class="subLabel">{{ treatmentUtil.getLabelSubTitle(treatment['Type.Label']) }}</span>
        </h2>

        <h2 class="summary-header rm-m" *ngIf="
            treatment['Label'] == 'N/A' && treatment['Type.Label'] == 'N/A' && treatment['Category.Label'] != 'N/A'
          ">
          {{ treatmentUtil.getLabelTitle(treatment['Category.Label']) }}
          <span class="subLabel">{{ treatmentUtil.getLabelSubTitle(treatment['Category.Label']) }}</span>
        </h2>

        <div class="date-chip-area">
          <p class="small rm-m mr" *ngIf="treatment.DateTimeCreated && treatment.DateTimeCreated != '0000-00-00'">
            <mat-icon class="fas fa-clock"></mat-icon>
            {{ treatment.DateTimeCreated | TimeLabelPast }} ago
          </p>

          <p class="clearfix small rm-m" *ngIf="treatment['Active'] == '1' && isGlobal == true">
            <span class="chip-enable">Active</span>
          </p>
          <p class="clearfix small rm-m" *ngIf="treatment['Active'] == '0' && isGlobal == true">
            <span class="chip-disable">Not active</span>
          </p>
          <p class="clearfix small rm-m" *ngIf="treatment['IsOmitted'] == '1' && isGlobal != true">
            <span class="chip-disable">Not active</span>
          </p>
          <p class="clearfix small rm-m" *ngIf="treatment['IsOmitted'] == '0' && isGlobal != true">
            <span class="chip-enable"> Active</span>
          </p>
        </div>
      </div>
    </div>
    <div class="colourOverlay" [ngStyle]="{ background: treatment['Category.Colour'] }"></div>
  </div>

  <mat-dialog-content class="stacked-card-view indOvf">
    <div class="row clearfix flex catType">
      <div class="block full-width clicktext" *ngIf="treatment['Category.Label']">
        <mat-icon class="fas fa-th"></mat-icon>
        <div class="row-content">
          <p class="small rm-m">Service Category</p>
          <h4 class="reduce-marg" [ngStyle]="{ color: treatment['Category.Colour'] }"
              (click)="viewCategory(treatment['Category_key'])">
            {{ treatmentUtil.getLabel(treatment['Category.Label']) }}
          </h4>
        </div>
      </div>

      <div class="block full-width clicktext" *ngIf="treatment['Type.Label']" matTooltip="Click to view Type details">
        <mat-icon class="fas fa-th-large"></mat-icon>
        <div class="row-content">
          <p class="small rm-m">Item Code</p>
          <h4 class="reduce-marg" [ngStyle]="{ color: treatment['Category.Colour'] }"
              (click)="viewType(treatment['Type_key'])">
            {{ treatmentUtil.getLabel(treatment['Type.Label']) }}
          </h4>
        </div>
      </div>
    </div>

    <hr/>

    <div class="row clearfix catType flex">
      <mat-icon class="fas fa-box-open pull-left"></mat-icon>
      <div class="row-content pull-left full-width">
        <p class="small rm-m">Product</p>
      </div>
    </div>

    <div class="row clearfix product-display">
      <app-treatment-product-list-global *ngIf="isGlobal == true && isPromoterOrAdmin == true"
                                         [brandID]="treatment['ID']" [isActive]="true"
                                         [microDisplay]="true"></app-treatment-product-list-global>
      <app-treatment-product-list *ngIf="isGlobal == false || isPromoterOrAdmin == false" [brandID]="treatment['ID']"
                                  [isActive]="true" [microDisplay]="true"></app-treatment-product-list>
    </div>
  </mat-dialog-content>
</mat-card>
