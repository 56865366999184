<div class="container">
  <div class="row clearfix">
    <!-- blue heading -->
    <form #addForm="ngForm">
      <div *ngIf="isDone != true">
        <mat-card class="">
          <div class="card-header primary-gradient-img clearfix slim-header">
            <div class="row clearfix">
              <button class="btn-close btn-clear mat-button" *ngIf="isModal == true" (click)="closeEvent()">
                <mat-icon>clear</mat-icon>
              </button>

              <p class="small rm-m pull-right" *ngIf="createdDate">
                <mat-icon class="fas fa-clock extra-header-icon"></mat-icon>
                {{ createdDate | TimeLabelPast }} ago
              </p>

              <p class="small rm-m mr pull-right" *ngIf="views">
                <mat-icon class="fas fa-eye extra-header-icon"></mat-icon>
                {{ views || 1 }}
              </p>

              <div class="row titleArea clearfix">
                <h2 class="message-title summary-header rm-m" *ngIf="isEditMode == false">
                  Add new Wiki Article
                  <span class="subLabel">Insert Wiki Article Details</span>
                </h2>
                <h2 class="message-title summary-header rm-m" *ngIf="isEditMode == true">
                  Edit Wiki Article
                  <span class="subLabel" *ngIf="title">{{ title }}</span>
                </h2>
              </div>
            </div>
          </div>

          <div class="row clearfix">
            <h3 class="sm-h3 sr-title">Page details</h3>

            <div class="row clearfix full-width flex">
              <div class="full-width">
                <mat-slide-toggle class="pull-right" *ngIf="isEditMode == true" [(ngModel)]="isActive" name="isActive">
                  Is Active?
                </mat-slide-toggle>

                <p class="small">
                  <strong>Title</strong>
                  and
                  <strong>Code</strong> must be unique
                </p>

                <p class="small">
                  You can use the the <strong>Code</strong> to access to the article via the link
                  <strong>/wiki/(wikiPageRoute:view-code/@code)</strong> (to use static Code instead of variable ID)
                </p>

                <div class="full-width mb clearfix">
                  <div class="row clearfix flex">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Title</mat-label>
                      <input
                        [(ngModel)]="title"
                        (change)="buildCode()"
                        name="title"
                        matInput
                        placeholder="Title"
                        required
                      />
                    </mat-form-field>

                    <mat-form-field class="qtr-width" appearance="outline">
                      <mat-label>Code</mat-label>
                      <input [(ngModel)]="code" name="code" matInput placeholder="Code " required/>
                    </mat-form-field>
                  </div>

                  <div class="row clearfix full-width flex flexcenter mb">
                    <label class="mr">Audience : </label>

                    <mat-checkbox
                      class="mr"
                      [checked]="isRoleActive('guest')"
                      (change)="sliderChangeRole($event, 'guest')"
                    >
                      Guest
                    </mat-checkbox>
                    <mat-checkbox
                      class="mr"
                      [checked]="isRoleActive('customer')"
                      (change)="sliderChangeRole($event, 'customer')"
                    >
                      Customer
                    </mat-checkbox>

                    <mat-checkbox
                      class="mr"
                      [checked]="isRoleActive('merchant')"
                      (change)="sliderChangeRole($event, 'merchant')"
                    >
                      Merchant
                    </mat-checkbox>

                    <mat-checkbox
                      class="mr"
                      [checked]="isRoleActive('supplier')"
                      (change)="sliderChangeRole($event, 'supplier')"
                    >
                      Supplier
                    </mat-checkbox>

                    <mat-checkbox
                      class="mr"
                      [checked]="isRoleActive('funder')"
                      (change)="sliderChangeRole($event, 'funder')"
                    >
                      Funder
                    </mat-checkbox>

                    <mat-checkbox
                      class="mr"
                      [checked]="isRoleActive('promoter')"
                      (change)="sliderChangeRole($event, 'promoter')"
                    >
                      Promoter
                    </mat-checkbox>

                    <mat-slide-toggle class="pull-right" [(ngModel)]="isMenuDisplayed" name="isMenuDisplayed">
                      Add the Article on side menu?
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>

              <div class="uploadBlock right qtr-width">
                <div class="row clearfix pic-img image">
                  <div class="notUploaded" *ngIf="!pageFileSrc">
                    <app-ep-document-view-image
                      class="clearfix notYetUploaded"
                      [defaultPicture]="
                        'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cloud-upload-alt-solid.png)'
                      "
                    >
                    </app-ep-document-view-image>
                  </div>

                  <div class="displayUploadImg mt" *ngIf="pageFileSrc">
                    <app-ep-document-view-image
                      class="full-width text-center clearfix"
                      [hideZoomButton]="false"
                      [imageLink]="pageFileSrc"
                    >
                    </app-ep-document-view-image>
                  </div>
                </div>
                <div class="row clearfix">
                  <h4 class="sr-title sm-h4 rm-mb">Upload Article Logo</h4>
                  <app-file-uploader
                    class="text-center clearfix full-width"
                    [allowedExtensionTypes]="allowedExtensionTypes"
                    [isFixedAspectRatio]="true"
                    [isImageCropping]="true"
                    [canSkip]="false"
                    [limit]="1"
                    [uploadDirectly]="true"
                    [isDragable]="true"
                    [title]="'CLICK HERE to upload'"
                    (onCompleteAll)="onCompletePageAll($event)"
                  >
                  </app-file-uploader>
                </div>
              </div>
            </div>

            <div class="full-width clearfix">
              <h3 class="sm-h3 sr-title rm-m">Category</h3>
              <div class="full-width flex clearfix">
                <p class="small" *ngIf="groups && groups.length > 0">
                  You can add new <strong>category</strong> Or select an existing one from list bellow
                </p>
                <p class="small" *ngIf="!groups && !groups.length == 0">
                  Add new category , Please keep the group title short it will be displayed on the side menu
                </p>
                <mat-slide-toggle
                  class="ml"
                  *ngIf="groups && groups.length > 0"
                  [(ngModel)]="createNewGroup"
                  name="createNewGroup"
                >
                  Create new category
                </mat-slide-toggle>
              </div>

              <div class="row clearfix full-width flex" *ngIf="createNewGroup == true">
                <div class="full-width">
                  <div class="full-width clearfix flexcenter">
                    <mat-form-field class="full-width clearfix" appearance="outline">
                      <mat-label>Category Title</mat-label>
                      <input
                        [(ngModel)]="groupTitle"
                        (change)="buildCodeGroup()"
                        name="Grouptitle"
                        matInput
                        placeholder="Enter a new category title"
                        required
                      />
                    </mat-form-field>

                    <div class="full-width clearfix flexcenter flex">
                      <mat-form-field class="full-width clearfix" appearance="outline">
                        <mat-label>Category Code</mat-label>
                        <input
                          [(ngModel)]="groupCode"
                          name="groupCode"
                          matInput
                          placeholder="Category Code, must be unique"
                          required
                        />
                      </mat-form-field>

                      <mat-form-field class="full-width clearfix" appearance="outline">
                        <mat-label>Category Icon (exp:fas fa-clock )</mat-label>
                        <input [(ngModel)]="groupIcon" name="Grouptitle" matInput placeholder="Category Icon"/>
                      </mat-form-field>

                      <mat-form-field class="full-width clearfix" appearance="outline">
                        <mat-label>Category Order by display</mat-label>

                        <mat-select
                          class="full-width"
                          [(ngModel)]="groupOrderBy"
                          placeholder="Category Order by display"
                          name="groupOrderBy"
                          required
                        >
                          <mat-option [value]="'priority'"> Priority</mat-option>

                          <mat-option [value]="'-created_at'"> Newest first</mat-option>

                          <mat-option [value]="'created_at'"> Oldest first</mat-option>

                          <mat-option [value]="'-views'"> Most Popular first</mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="full-width clearfix" appearance="outline">
                        <mat-label>Category Left Menu Options</mat-label>

                        <mat-select
                          class="full-width"
                          [(ngModel)]="groupMenu"
                          placeholder="Category Left Menu Options"
                          name="groupMenu"
                          required
                        >
                          <mat-option [value]="'all'"> Category and Articles</mat-option>

                          <mat-option [value]="'only'"> only Category</mat-option>

                          <mat-option [value]="'none'"> Don't display</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <mat-form-field class="full-width clearfix" appearance="outline">
                      <mat-label>Category Description</mat-label>
                      <textarea
                        class="dentMsg increase-input-field"
                        [(ngModel)]="groupDescription"
                        name="groupDescription"
                        matInput
                        placeholder="Category Description"
                      ></textarea>
                    </mat-form-field>

                    <div class="full-width flex flexcenter clearfix">
                      <label>Category Audience </label>
                      <mat-checkbox
                        class="mr"
                        [checked]="isGroupRoleActive('guest')"
                        (change)="sliderChangeGroupRole($event, 'guest')"
                      >
                        Guest
                      </mat-checkbox>
                      <mat-checkbox
                        class="mr"
                        [checked]="isGroupRoleActive('customer')"
                        (change)="sliderChangeGroupRole($event, 'customer')"
                      >
                        Customer
                      </mat-checkbox>

                      <mat-checkbox
                        class="mr"
                        [checked]="isGroupRoleActive('merchant')"
                        (change)="sliderChangeGroupRole($event, 'merchant')"
                      >
                        Merchant
                      </mat-checkbox>

                      <mat-checkbox
                        class="mr"
                        [checked]="isGroupRoleActive('supplier')"
                        (change)="sliderChangeGroupRole($event, 'supplier')"
                      >
                        Supplier
                      </mat-checkbox>

                      <mat-checkbox
                        class="mr"
                        [checked]="isGroupRoleActive('funder')"
                        (change)="sliderChangeGroupRole($event, 'funder')"
                      >
                        Funder
                      </mat-checkbox>

                      <mat-checkbox
                        class="mr"
                        [checked]="isGroupRoleActive('promoter')"
                        (change)="sliderChangeGroupRole($event, 'promoter')"
                      >
                        Promoter
                      </mat-checkbox>
                    </div>
                  </div>
                </div>

                <div class="uploadBlock right qtr-width">
                  <div class="row clearfix pic-img image">
                    <div class="notUploaded" *ngIf="!groupFileSrc">
                      <app-ep-document-view-image
                        class="clearfix notYetUploaded"
                        [defaultPicture]="
                          'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cloud-upload-alt-solid.png)'
                        "
                      >
                      </app-ep-document-view-image>
                    </div>

                    <div class="displayUploadImg mt" *ngIf="groupFileSrc">
                      <app-ep-document-view-image
                        class="full-width text-center clearfix"
                        [hideZoomButton]="false"
                        [imageLink]="groupFileSrc"
                      >
                      </app-ep-document-view-image>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <h4 class="sr-title sm-h4 rm-mb">Upload Category Cover</h4>
                    <app-file-uploader
                      class="text-center clearfix full-width"
                      [allowedExtensionTypes]="allowedExtensionTypes"
                      [isImageCropping]="true"
                      [canSkip]="false"
                      [limit]="1"
                      [uploadDirectly]="true"
                      [isDragable]="true"
                      [title]="'CLICK HERE to upload'"
                      (onCompleteAll)="onCompleteCategoryAll($event)"
                    >
                    </app-file-uploader>
                  </div>
                </div>
              </div>

              <div class="full-width clearfix" *ngIf="createNewGroup == false">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Select Group</mat-label>
                  <mat-select
                    class="full-width"
                    [(ngModel)]="groupID"
                    placeholder="Select Group"
                    name="SelectGroup"
                    required
                  >
                    <mat-option *ngFor="let group of groups" [value]="group._id">
                      {{ group.title }}
                      <span class="chip chipGood" *ngFor="let r of group.roles">
                        {{ r }}
                      </span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="row clearfix">
            <h3 class="sm-h3 sr-title rm-m">Page Summary</h3>
            <p class="small">
              Insert Wiki Article <strong>Summary </strong>that contains a breif description of wiki content . this
              summary will be used in Search along with <strong>keywords</strong>
              You can use the first pharaph of your Article content as summary
            </p>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Summary</mat-label>
              <textarea
                class="dentMsg increase-input-field"
                [(ngModel)]="summary"
                name="Summary"
                matInput
                placeholder="Summary"
                required
              ></textarea>
            </mat-form-field>
          </div>
        </mat-card>

        <mat-card class="card">
          <div class="colour-stripe"></div>
          <div class="clearfix row">
            <h3 class="sm-h3 sr-title rm-m">Wiki Article content</h3>
            <p class="small">
              Insert Wiki content, you can switch between <i>text-editor</i> and <i>Raw HTML</i>, click on
              <strong>Html+</strong> to get html content for EP document link or Vimeo video.
            </p>

            <app-text-editor-input
              class="clearfix"
              [content]="_content"
              [title]="''"
              [isPreview]="true"
              [isAction]="false"
              (getContent)="getContent($event)"
              name="editor"
            >
            </app-text-editor-input>
          </div>
        </mat-card>

        <mat-card class="card">
          <div class="colour-stripe"></div>
          <!-- keywords intro description -->
          <div class="row clearfix flex flexwrap">
            <div class="row clearfix full-width">
              <h3 class="sm-h3 sr-title rm-m">Keywords</h3>

              <p class="small">
                <strong>keyworks</strong> will be used to filter the Wiki Articles or look for similiar content , when
                you are inserting a <strong>keywork</strong> an auto-complete suggest list will appear with existing
                ones
                to avoid duplications.
              </p>

              <p class="small">
                <strong>You need to insert at least one keyword </strong> , Write a <strong>keywork</strong> and press
                <i>'Enter'</i> , you can remove the keywork by clicking on <i>'X'</i>.
              </p>

              <mat-form-field class="full-width clearfix">
                <mat-chip-list #chipList name="tags-wiki">
                  <mat-chip
                    *ngFor="let tag of tags"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="removeTag(tag)"
                  >
                    {{ tag }}
                    <mat-icon *ngIf="removable" matChipRemove>cancel</mat-icon>
                  </mat-chip>
                  <input
                    #tagInput
                    [(ngModel)]="tagCtrl"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="addOnBlur"
                    (change)="tagChange($event)"
                    (matChipInputTokenEnd)="addTag($event)"
                    name="tags-wiki-model "
                    placeholder="Add KeyWord"
                  />
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                  <mat-option
                    *ngFor="let wikiTag of wikiTags | FilterArrayMultipleContainValue: '*':tagCtrl"
                    [value]="wikiTag"
                  >
                    {{ wikiTag }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>

            <!-- references intro description -->
            <div class="full-width row clearfix">
              <h3 class="sm-h3 sr-title rm-m">References</h3>

              <p class="small">
                <strong>References</strong> will be added at the end of Wiki Articles to redirect the user to Resources,
                public siteArticleor any external link for more details
              </p>

              <p class="small">
                You can add a link for document from EP by inserting <strong>@EP:%DocumentID%</strong> for example
                <i>@EP:B73D331494EE85498126DE9DDF8A61F9</i>
              </p>

              <p class="reference-container" *ngFor="let r of references; let index = index">
                <button
                  class="ml"
                  class=""
                  (click)="removeReference(index)"
                  matTooltip="Remove reference"
                  color="accent"
                  mat-button
                >
                  <mat-icon>delete</mat-icon>
                </button>

                <a class="link-class" (click)="redirectLink(r.label, r.link)">{{ r.label }}</a>
              </p>
              <div class="full-width flex clearfix">
                <mat-form-field class="full-width mr" appearance="outline">
                  <mat-label>Label*</mat-label>
                  <input [(ngModel)]="referenceLabel" name="referenceLabel" matInput placeholder="Label*"/>
                </mat-form-field>
                <mat-form-field class="full-width mr" appearance="outline">
                  <mat-label>Link*</mat-label>
                  <input [(ngModel)]="referenceLink" name="referenceLink" matInput placeholder="Link*"/>
                </mat-form-field>
                <button class="" (click)="addReference()" matTooltip="Add reference" color="primary" mat-button>
                  <mat-icon>add</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </mat-card>

        <div class="row clearfix text-center step-button-row mt">
          <button
            class="btn btn-primary"
            *ngIf="isEditMode != true"
            [disabled]="!addForm.form.valid || !roles || roles.length < 1"
            (click)="saveWiki()"
            mat-raised-button
            type="submit"
            color="accent"
          >
            <mat-icon>add</mat-icon> <strong>Create Wiki Article</strong>
          </button>

          <button
            class="btn btn-primary"
            *ngIf="isEditMode == true"
            [disabled]="!addForm.form.valid || !roles || roles.length < 1"
            (click)="saveWiki()"
            mat-raised-button
            type="submit"
            color="accent"
          >
            <mat-icon>add</mat-icon> <strong>Edit Wiki Article</strong>
          </button>
        </div>
      </div>
    </form>

    <!-- created result -->

    <div class="animated fadeInDown" id="step-4" *ngIf="isDone == true && wiki">
      <mat-card class="">
        <div class="row clearfix text-center">
          <h2 class="sr-title inline-block rm-mt" tabindex="0">
            <span *ngIf="isEditMode == false">Wiki Article created!</span>
            <span *ngIf="isEditMode == true">Wiki Article details updated!</span>
          </h2>
        </div>
        <div class="row clearfix">
          <div class="full-width text-center">
            <hr class="mt"/>
            <div class="svg-box">
              <svg class="circular green-stroke">
                <circle class="path" cx="75" cy="75" r="50" fill="none" stroke-width="5" stroke-miterlimit="10" />
              </svg>
              <svg class="checkmark green-stroke">
                <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                  <path class="checkmark__check" fill="none" d="M616.306,283.025L634.087,300.805L673.361,261.53" />
                </g>
              </svg>
            </div>

            <div class="row clearfix">
              <div class="full-width text-center step-button-row">
                <button
                  class="step-next mr"
                  *ngIf="isModal == true"
                  (click)="closeEvent()"
                  mat-raised-button
                  color="primary"
                >
                  Close
                </button>

                <button
                  class="step-next"
                  class="ml mr"
                  *ngIf="wiki && wiki.group"
                  (click)="goCategory()"
                  mat-raised-button
                  color="accent"
                >
                  <mat-icon>view_quilt</mat-icon> View Category
                </button>

                <button class="step-next" class="ml mr" (click)="viewPage()" mat-raised-button color="accent">
                  <mat-icon>visibility</mat-icon> View Article
                </button>

                <button class="step-next" class="ml mr" (click)="createPage()" mat-raised-button color="primary">
                  <mat-icon>add</mat-icon> Create new Article
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
