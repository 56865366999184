<div class="rel" *ngIf="merchantServicesItems && firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div class="pull-left" *ngIf="firstLoadEvent == true">
  <mat-form-field class="mat-select-adjust" appearance="outline">
    <mat-label>Service Group</mat-label>
    <mat-select class="full-width" [(ngModel)]="selectedServiceGroupKey" (selectionChange)="changeServiceGroup()">
      <mat-option *ngFor="let merchantServiceGroup of merchantServicesGroups" [value]="merchantServiceGroup['ID']">
        {{ merchantServiceGroup.Label | titlecase }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="pull-right" *ngIf="firstLoadEvent == true">
  <button class="btn-small" (click)="openCreateMerchantServiceItemDialog()" mat-raised-button color="accent">
    <mat-icon>add</mat-icon> New Service Item
  </button>
</div>

<div [hidden]="merchantServicesGroups.length == 0" style="margin-top: 80px">
  <div>
    <mat-card class="list-card">
      <table class="list-table" #table [dataSource]="datasource" mat-table matSort style="width: 100%">
        <ng-container cdkColumnDef="Label" sticky>
          <th *matHeaderCellDef mat-header-cell mat-sort-header style="width: 250px">Label</th>
          <td *matCellDef="let row" mat-cell style="width: 250px">{{ row.Label | titlecase }}</td>
        </ng-container>

        <ng-container cdkColumnDef="Description" sticky>
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Description</th>
          <td *matCellDef="let row" mat-cell>{{ row.Description }}</td>
        </ng-container>

        <!-- Action Button Column -->
        <ng-container cdkColumnDef="Actions" sticky>
          <th *cdkHeaderCellDef mat-header-cell mat-sort-header style="width: 50px"></th>
          <td *cdkCellDef="let row" mat-cell style="width: 50px">
            <button [matMenuTriggerFor]="menu" mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button (click)="openEditMerchantServiceItemDialog(row)" mat-menu-item>
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>

              <button (click)="openDeleteMerchantServiceItemDialog(row['ID'])" mat-menu-item>
                <mat-icon>delete</mat-icon>
                <span>Remove</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
      </table>
      <mat-paginator
        #paginator
        [length]="merchantServicesGroups.length"
        [pageIndex]="0"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
      >
      </mat-paginator>
    </mat-card>
  </div>
</div>

<div *ngIf="merchantServicesGroups && merchantServicesGroups.length == 0 && firstLoadEvent != false">
  <app-empty-list-message></app-empty-list-message>
</div>
