import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { TemplateService } from '../shared/template-service';

@Component({
  selector: 'app-template-purpose-create-edit',
  templateUrl: './template-purpose-create-edit.component.html',
  styleUrls: ['./template-purpose-create-edit.component.css'],
})
export class TemplatePurposeCreateEditComponent implements OnInit {
  @Input()
  templatePurposeID;

  @Input()
  isModal = false;

  @Output()
  close = new EventEmitter();

  @Output()
  getResult = new EventEmitter();

  templatePurpose = {
    label: null,
    description: null,
  };

  isEdit = false;

  constructor(
    private templateService: TemplateService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.templatePurposeID != null) {
        this.templatePurposeID = data.templatePurposeID;
      }

      if (data.isModal != null) {
        this.isModal = data.isModal;
      }
    }
  }

  ngOnInit() {
    if (this.templatePurposeID) {
      this.templateService.getTemplatePurposeDetails(this.templatePurposeID).subscribe((res) => {
        if (res) {
          this.isEdit = true;

          if (res['Label']) {
            this.templatePurpose.label = res['Label'];
          }

          if (res['Description']) {
            this.templatePurpose.description = res['Description'];
          }
        }
      });
    }
  }

  closeModal() {
    this.close.emit(true);
  }

  create() {
    const payload = {
      label: this.templatePurpose.label,
      description: this.templatePurpose.description,
    };

    this.templateService.createPurpose(payload).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast(
          'success',
          'Create template purpose',
          'You have successfully created a new template purpose.'
        );

        this.getResult.emit(res);
      }
    });
  }

  edit() {
    const payload = {
      label: this.templatePurpose.label,
      description: this.templatePurpose.description,
    };

    this.templateService.modifyPurpose(this.templatePurposeID, payload).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast(
          'success',
          'Modify template purpose',
          'You have successfully modified this template purpose.'
        );

        this.getResult.emit(res);
      }
    });
  }
}
