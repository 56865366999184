<div [hidden]="listDB.data.length == 0 || showDocList == true">
  <div class="row clearfix" *ngIf="microDisplay == false">
    <h2 class="dataHeader" *ngIf="defaultHeading == true">
      <img
        src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/brandLogos/ADA-Fed-Logo.png"
      />
      Promoter {{ "KEYWORD.Treatment Products" | translate }}
      <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
    </h2>
    <h2 class="dataHeader" *ngIf="defaultHeading == false">
      <img
        src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/brandLogos/ADA-Fed-Logo.png"
      />
      Promoter {{ "KEYWORD.Treatment Products" | translate }}
      <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
    </h2>
  </div>

  <div class="row clearfix flex ctl mb" *ngIf="microDisplay == false" @ngIfAnimation>
    <div class="full-width flex">
      <button (click)="add()" mat-raised-button color="primary">
        <mat-icon>add</mat-icon>
        Add Product
      </button>
      <button (click)="goToGuide()" mat-raised-button color="accent">
        Download Guide <mat-icon class="fas fa-file-download"></mat-icon>
      </button>
      <button (click)="goToShop()" mat-raised-button color="primary">
        ADA Shop <mat-icon class="fas fa-shopping-basket"></mat-icon>
      </button>
    </div>
    <mat-button-toggle-group
      class="btn-group rm-m half-width"
      [(ngModel)]="ActiveFilter"
      (change)="setFilter($event.value, 'Active')"
    >
      <mat-button-toggle class="" [value]="null">
        All
      </mat-button-toggle>

      <mat-button-toggle class="" [value]="'1'">
        Active
      </mat-button-toggle>
      <mat-button-toggle class="" [value]="'0'">
        Not Active
      </mat-button-toggle>
    </mat-button-toggle-group>

    <button
      class="row select-btn"
      (click)="selectAll()"
      mat-stroked-button
      color=""
      matTooltip="Select All"
    >
      <mat-icon>border_all</mat-icon>
    </button>

    <button
      class="row select-btn"
      (click)="selectPage()"
      mat-stroked-button
      color=""
      matTooltip="Select Page"
    >
      <mat-icon>border_outer</mat-icon>
    </button>

    <button
      class="animated"
      *ngIf="selectedIDs && selectedIDs.length > 0"
      [matMenuTriggerFor]="ActionBtnmenu"
      mat-stroked-button
      matTooltip="Options"
    >
      <mat-icon class="fas fa-cog"></mat-icon>  <span *ngIf="selectedIDs && selectedIDs.length>0"
        class="selected-number-item  accent-background">{{selectedIDs.length}}</span>
    </button>
    <mat-menu #ActionBtnmenu="matMenu">
      <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
        <mat-icon>select_all</mat-icon> Deselect
      </button>

      <button class="row select-btn" (click)="updateGroup()" mat-menu-item color="">
        <mat-icon>edit</mat-icon>
        Edit Group
      </button>

      <button class="row select-btn" (click)="disableGroup()" mat-menu-item color="">
        <mat-icon>cancel</mat-icon>
        Disable
      </button>

      <button class="row select-btn" (click)="enableGroup()" mat-menu-item color="">
        <mat-icon>check_circle</mat-icon>
        Enable
      </button>

      <button class="row select-btn" (click)="addDocumentGroup()" mat-menu-item color="">
        <mat-icon>add_circle</mat-icon>
        Add document
      </button>

      <button class="row select-btn" (click)="importDocumentGroup()" mat-menu-item color="">
        <mat-icon>import_export</mat-icon>
        Import document
      </button>
    </mat-menu>
  </div>

  <div class="row clearfix">
    <mat-card class="list-card">
      <div class="row clearfix searchArea primary-gradient-img" *ngIf="microDisplay == false">
        <div class="row clearfix full-width flex" id="search">
          <mat-icon class="mt mr">search</mat-icon>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Label, Item Code</mat-label>
            <input class="clearfix" (input)="setFilter($event.target.value, 'fullLabel')" name="Labels" matInput/>
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Category</mat-label>
            <mat-select
              class=""
              (selectionChange)="setFilter($event.value, 'Category.Label')"
              name="Category"
              placeholder="Category"
            >
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option
                *ngFor="
                  let a of dataSource.filteredData
                    | objectPropertyValue: 'Category.Label'
                    | unique
                    | without: ['', null]
                    | orderBy
                "
                [value]="a"
              >
                {{ treatmentUtil.getLabel(a) }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Type</mat-label>
            <mat-select
              class=""
              (selectionChange)="setFilter($event.value, 'Type.Label')"
              name="Type"
              placeholder="Type"
            >
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option
                *ngFor="
                  let a of dataSource.filteredData
                    | objectPropertyValue: 'Type.Label'
                    | unique
                    | without: ['', null]
                    | orderBy
                "
                [value]="a"
              >
                {{ treatmentUtil.getLabel(a) }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Brand</mat-label>
            <mat-select
              class=""
              (selectionChange)="setFilter($event.value, 'Brand.Label')"
              name="Brand"
              placeholder="Brand"
            >
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option
                *ngFor="
                  let a of dataSource.filteredData
                    | objectPropertyValue: 'Brand.Label'
                    | unique
                    | without: ['', null, 'N/A']
                    | orderBy
                "
                [value]="a"
              >
                {{ treatmentUtil.getLabel(a) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <ng-container cdkColumnDef="selectCheckBox" sticky>
          <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div class="margin-btm pull-left">
              <mat-checkbox
                [checked]="isSelected(row['ID'])"
                (change)="select(row['ID'], $event.checked)"
                color="accent"
              >
              </mat-checkbox>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="logoPic">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Logo</mat-header-cell>
          <mat-cell class="text-center" *cdkCellDef="let row" (click)="viewTreatment(row['ID'])">
            <app-product-view-logo
              class="supplierIcon2 prod text-center"
              *ngIf="row"
              [hideZoomButton]="true"
              [productID]="row['ID']"
              [LastModified]="row['LastModified']"
            >
            </app-product-view-logo>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="cataloguePic">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Catalogue</mat-header-cell>
          <mat-cell class="text-center" *cdkCellDef="let row" (click)="viewTreatment(row['ID'])">
            <app-product-view-catalogue
              class="supplierIcon2 text-center"
              *ngIf="row"
              [productID]="row['ID']"
              [LastModified]="row['LastModified']"
            >
            </app-product-view-catalogue>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Category">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Category</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="viewTreatment(row['ID'])"
            matTooltip="{{ treatmentUtil.getLabel(row['Category.Label']) }}"
          >
            <strong [ngStyle]="{ color: row['Category.Colour'] }">{{
              treatmentUtil.getLabel(row['Category.Label'])
            }}</strong>
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Type">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Type</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="viewTreatment(row['ID'])"
            matTooltip="{{ treatmentUtil.getLabel(row['Type.Label']) }}"
          >
            {{ treatmentUtil.getLabel(row['Type.Label']) }}</mat-cell
          >
        </ng-container>
        <ng-container cdkColumnDef="Brand">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Brand</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="viewTreatment(row['ID'])"
            matTooltip="{{ treatmentUtil.getLabel(row['Brand.Label']) }}"
          >
            <span>{{ treatmentUtil.getLabel(row['Brand.Label']) }}</span>
            <!-- <span *ngIf="row['IsNullBrand']=='1'">{{null}}</span> -->
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Product">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Product</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="viewTreatment(row['ID'])"
            matTooltip="{{ treatmentUtil.getLabel(row['Label']) }}"
          >
            <span>{{ treatmentUtil.getLabel(row['Label']) }}</span>
            <span class="value chip new" *ngIf="isNew(row['DateTimeCreated']) == true">NEW</span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="ActiveStatus">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>
            Active
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTreatment(row['ID'])">
            <span
              class="list-label smaller"
              *ngIf="row['Active'] == '1'"
              matTooltip="Active"
              style="background-color: rgb(90, 186, 71)"
            >
              <mat-icon>check</mat-icon>
            </span>
            <span
              class="list-label smaller"
              *ngIf="row['Active'] != '1'"
              matTooltip="Inactive"
              style="background-color: rgb(239, 71, 39)"
            >
              <mat-icon>close</mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Documents">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>
            Documents
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <button
              class="btn-small"
              (click)="viewDocuments(row['ID'], row['Label'])"
              matTooltip=" View Product Documents"
              mat-button
              color="warning"
            >
              <!-- {{row['NumberOfDocuments'] || '...'
              }} -->
              {{
              row['AssociatedDocuments.ProdAndInherited.SRVPRE'].length +
              row['AssociatedDocuments.ProdAndInherited.SRVPST'].length +
              row['AssociatedDocuments.ProdAndInherited.SRVMKT'].length +
              row['AssociatedDocuments.ProdAndInherited.SRVSPL'].length +
              row['AssociatedDocuments.ProdAndInherited.SRV'].length +
              row['AssociatedDocuments.ProdAndInherited.SRVSTC'].length
              }}
            </button>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="itemCode">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Item Code</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <span
              *ngIf="!row['isMerged']; else merged"
              (click)="viewTreatment(row['ID'])"
              matTooltip="{{ row['Item_Code.Effective'] }}"
            >{{ row['Item_Code.Effective'] }}</span
            >
            <ng-template #merged>
              <span class="btn-show-all" (click)="expandProduct(row)">
                Various (show all <strong *ngIf="row.mergedLength">{{ row.mergedLength }}</strong
              >)
              </span>
            </ng-template>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="SellPrice">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Sell Price</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="viewTreatment(row['ID'])"
            matTooltip="{{ row['DefaultPrice.Sell'] | customCurrency }}"
          >
            {{ row['DefaultPrice.Sell'] | customCurrency }}</mat-cell
          >
        </ng-container>

        <ng-container cdkColumnDef="CostPrice">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Cost Price</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="viewTreatment(row['ID'])"
            matTooltip="{{ row['DefaultPrice.Cost.Item'] | customCurrency }}"
          >
            {{ row['DefaultPrice.Cost.Item'] | customCurrency }}</mat-cell
          >
        </ng-container>

        <ng-container cdkColumnDef="Manufacturer">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>
            Manufacturer
          </mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="viewTreatment(row['ID'])"
            matTooltip="{{ row['Manufacturer.Name.Effective'] }}"
          >
            {{ row['Manufacturer.Name.Effective'] || '...' }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Distributor">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>
            Distributor
          </mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="viewTreatment(row['ID'])"
            matTooltip="{{ row['Distributor.Name.Effective'] }}"
          >
            {{ row['Distributor.Name.Effective'] || '...' }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Date">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Created Date</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="viewTreatment(row['ID'])"
            matTooltip="{{ row['DateTimeCreated'] | customDateTime }}"
          >
            {{ row['DateTimeCreated'] | customDate }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="list">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> </mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <button (click)="viewDocuments(row['ID'], row['Label'])" mat-icon-button matTooltip="View Document List">
              <mat-icon>list</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button (click)="viewTreatment(row['ID'])" mat-menu-item>
                  <mat-icon class="fas fa-box-open"></mat-icon>
                  <span>View Product</span>
                </button>
                <button (click)="editTreatment(row['ID'])" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span>Edit Product</span>
                </button>

                <button *ngIf="row['ID']" (click)="addDocument(row['ID'])" mat-menu-item>
                  <mat-icon>add_circle</mat-icon>
                  <span>Add Document</span>
                </button>

                <button *ngIf="row['ID']" (click)="importDocument(row['ID'])" mat-menu-item>
                  <mat-icon>import_export</mat-icon>
                  <span>Import Document</span>
                </button>

                <button *ngIf="row['Category_key']" (click)="viewCategory(row['Category_key'])" mat-menu-item>
                  <mat-icon class="fas fa-th"></mat-icon>
                  <span>View Category</span>
                </button>

                <button *ngIf="row['Type_key']" (click)="viewType(row['Type_key'])" mat-menu-item>
                  <mat-icon class="fas fa-th-large"></mat-icon>
                  <span>View Type</span>
                </button>

                <button *ngIf="row['Brand_key']" (click)="viewBrand(row['Brand_key'])" mat-menu-item>
                  <mat-icon class="fas fa-parachute-box"></mat-icon>
                  <span>View Brand</span>
                </button>

                <button
                  *ngIf="row['ID']"
                  (click)="viewDocuments(row['ID'], treatmentUtil.getLabel(row['Label']))"
                  mat-menu-item
                >
                  <mat-icon>book</mat-icon>
                  <span>View Product Documents</span>
                </button>

                <button
                  *ngIf="row['Manufacturer_Key.Effective']"
                  (click)="viewSupplier(row['Manufacturer_Key.Effective'])"
                  mat-menu-item
                >
                  <mat-icon class="fas fa-industry mr"></mat-icon>
                  <span>Manufacturer {{ row['Manufacturer.Name.Effective'] }}</span>
                </button>

                <button
                  *ngIf="row['Distributor_Key.Effective']"
                  (click)="viewSupplier(row['Distributor_Key.Effective'])"
                  mat-menu-item
                >
                  <mat-icon class="fas fa-truck mr"></mat-icon>
                  <span>Distributor {{ row['Distributor.Name.Effective'] }}</span>
                </button>

                <button
                  *ngIf="row['Active'] == '1' && isPromoterOrAdmin == true"
                  (click)="disable(row['ID'])"
                  mat-menu-item
                >
                  <mat-icon>cancel</mat-icon>
                  <span>Disable</span>
                </button>

                <button
                  *ngIf="row['Active'] == '0' && isPromoterOrAdmin == true"
                  (click)="enable(row['ID'])"
                  mat-menu-item
                >
                  <mat-icon>check_circle</mat-icon>
                  <span>Enable</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginator
        [length]="dataSource.filteredData.length"
        [pageIndex]="0"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        [hidden]="microDisplay == true"
      >
      </mat-paginator>
    </mat-card>
  </div>
</div>

<div class="clearfix mt" *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <app-empty-list-message [title]="'No records found'" [message]="'There are no records in the database'">
  </app-empty-list-message>
</div>

<div class="showDocumentList" *ngIf="showDocList == true">
  <div class="row clearfix">
    <app-treatment-document-gallery-container class="clearfix" [productID]="docProductID" [title]="docListTitle">
    </app-treatment-document-gallery-container>
  </div>
  <div class="row clearfix mt">
    <button (click)="hideViewDetails()" mat-raised-button color="primary">
      <mat-icon>chevron_left</mat-icon> Products List
    </button>
  </div>
</div>
