import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { AppointmentLookupService } from '../shared/appointmentLookup.service';
import { AppointmentUtilClass } from '../shared/appointmentUtil';

@Component({
  selector: 'app-appointment-lookup-create',
  templateUrl: './appointment-lookup-create.component.html',
  styleUrls: ['./appointment-lookup-create.component.css'],
})
export class AppointmentLookupCreateComponent implements OnInit {
  @Input() merchantID: string;
  // used as form field
  @Input() type = 'subType';

  @Input() categoryID;

  @Output() sendResult = new EventEmitter();
  @Output() closeModal = new EventEmitter();

  sessionType;
  isPromoterOrAdmin;
  isModal = false;

  displayLookupType = false;
  displayCategoryList = false;
  displayDurationBuffers = false;

  label;
  description;
  defaultDuration;
  buffersPre = '0';
  buffersPost = '0';

  @Input() lookupType;
  lookupTypes = [
    {
      code: 'AppointmentTypes',
      label: 'Reason for visiting',
    },
    {
      code: 'BestAppointmentTime',
      label: 'Best appointment time',
    },
  ];

  categories = [];

  appointmentUtil = new AppointmentUtilClass();
  durations = this.appointmentUtil.getDurationArray();
  buffers = this.appointmentUtil.getBufferArray();

  category;

  merchant;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data,
    private appointmentLookupService: AppointmentLookupService,
    private authenticationService: AuthenticationService,
    private merchantService: MerchantService
  ) {
    if (data) {
      if (data.lookupType) {
        this.lookupType = data.lookupType;
      }
      if (data.type) {
        this.type = data.type;
      }

      if (data.merchantID) {
        this.merchantID = data.merchantID;
      }
      if (data.categoryID) {
        this.categoryID = data.categoryID;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
        this.isPromoterOrAdmin = isPromoterOrAdmin;

        if (this.isPromoterOrAdmin == true && this.merchantID) {
          this.merchantService
            .getMerchantDetails(
              this.merchantID,
              { fields: 'Default_Buffers_Pre,Default_Buffers_Post' },
              this.sessionType
            )
            .subscribe((merchant) => {
              if (merchant) {
                this.merchant = merchant;
                this.setup();
              }
            });
        } else {
          this.authenticationService.getCurrentPractice().subscribe((merchant) => {
            if (merchant) {
              this.merchant = merchant;
              this.setup();
            }
          });
        }
      });
    });

    this.updateView();
  }

  setup() {
    if (this.categoryID) {
      this.lookupType = 'AppointmentTypes';

      if (this.categoryID == 'AppointmentTypes') {
        this.type = 'type';
      } else {
        this.type = 'subType';
      }
    }

    this.updateView();

    if (this.displayCategoryList == true && this.type == 'subType') {
      const p = {
        merchantID: this.merchantID,
        lookupType: this.lookupType,
      };

      this.appointmentLookupService.list(p, this.sessionType).subscribe((res) => {
        if (res) {
          this.categories = res;
          if (this.categoryID && this.categoryID != 'AppointmentTypes') {
            const index = this.categories.findIndex((p) => {
              if (p && p.Category) {
                return true;
              }
              return false;
            });

            if (index != -1) {
              this.category = this.categories[index];
            }

            if (this.category) {
              if (this.category['DefaultDuration']) {
                this.defaultDuration = String(this.category['DefaultDuration']);
              }

              if (this.category['Buffers_Pre']) {
                this.buffersPre = String(this.category['Buffers_Pre']);
              } else if (this.merchant && this.merchant['Default_Buffers_Pre']) {
                this.buffersPre = this.merchant['Default_Buffers_Pre'];
              } else {
                this.buffersPre = '0';
              }

              if (this.category['Buffers_Post']) {
                this.buffersPost = String(this.category['Buffers_Post']);
              } else if (this.merchant && this.merchant['Default_Buffers_Post']) {
                this.buffersPost = this.merchant['Default_Buffers_Post'];
              } else {
                this.buffersPost = '0';
              }
            }
          }
        }
      });
    } else if (this.categoryID) {
      const p = {
        merchantID: this.merchantID,
        code: this.categoryID,
        lookupType: this.lookupType,
        limit: 1,
        section: 1,
      };

      this.appointmentLookupService.list(p, this.sessionType).subscribe((res) => {
        if (res) {
          this.categories = res;
          if (this.categoryID && this.categoryID != 'AppointmentTypes') {
            const index = this.categories.findIndex((p) => {
              if (p && p.Category) {
                return true;
              }
              return false;
            });

            if (index != -1) {
              this.category = this.categories[index];
            }

            if (this.category) {
              if (this.category['DefaultDuration']) {
                this.defaultDuration = String(this.category['DefaultDuration']);
              }
              if (this.category['Buffers_Pre']) {
                this.buffersPre = String(this.category['Buffers_Pre']);
              } else if (this.merchant && this.merchant['Default_Buffers_Pre']) {
                this.buffersPre = this.merchant['Default_Buffers_Pre'];
              } else {
                this.buffersPre = '0';
              }

              if (this.category['Buffers_Post']) {
                this.buffersPost = String(this.category['Buffers_Post']);
              } else if (this.merchant && this.merchant['Default_Buffers_Post']) {
                this.buffersPost = this.merchant['Default_Buffers_Post'];
              } else {
                this.buffersPost = '0';
              }
            }
          }
        }
      });
    }
  }

  selectCategory() {
    if (this.category) {
      if (this.category['DefaultDuration']) {
        this.defaultDuration = String(this.category['DefaultDuration']);
      }

      if (this.category['Buffers_Pre']) {
        this.buffersPre = String(this.category['Buffers_Pre']);
      } else if (this.merchant && this.merchant['Default_Buffers_Pre']) {
        this.buffersPre = this.merchant['Default_Buffers_Pre'];
      } else {
        this.buffersPre = '0';
      }

      if (this.category['Buffers_Post']) {
        this.buffersPost = String(this.category['Buffers_Post']);
      } else if (this.merchant && this.merchant['Default_Buffers_Post']) {
        this.buffersPost = this.merchant['Default_Buffers_Post'];
      } else {
        this.buffersPost = '0';
      }
    }
  }

  closeEvent(): void {
    this.closeModal.emit(true);
  }

  onSave() {
    let category = this.lookupType;
    if (this.type === 'subType') {
      if (this.category && this.category.Code) {
        category = this.category.Code;
      }
    }

    const payload: any = {
      lookupType: this.lookupType,
      category,
      label: this.label,
      description: this.description,
      defaultDuration: this.defaultDuration,
      buffersPre: this.buffersPre,
      buffersPost: this.buffersPost,
      type: this.type ? this.type : 'type',
      merchantID: this.merchantID,
    };

    this.appointmentLookupService.create(payload, this.sessionType).subscribe((res) => {
      if (res) {
        this.sendResult.emit(res);

        NotifyAppComponent.displayToast('success', 'Successful operation', '');
      }
    });
  }

  onSelectLookupType(): void {
    if (this.lookupType === 'BestAppointmentTime') {
      this.displayCategoryList = false;
      this.displayDurationBuffers = false;
      this.type = 'type';
    } else {
      this.displayCategoryList = true;
      this.displayDurationBuffers = true;
      this.type = 'type';
    }
  }

  updateView(): void {
    if (this.lookupType) {
      this.displayLookupType = false;
    } else if (this.type === 'type' || this.type === 'subType') {
      this.lookupType = 'AppointmentTypes';
      this.displayLookupType = false;
      this.displayDurationBuffers = true;
    } else {
      this.displayLookupType = true;
      this.displayDurationBuffers = false;
    }

    if (this.lookupType === 'BestAppointmentTime') {
      this.displayCategoryList = false;
      this.displayDurationBuffers = false;
    } else if (this.lookupType === 'AppointmentTypes' && this.type === 'type') {
      this.displayCategoryList = false;
      this.displayDurationBuffers = true;
    } else if (this.lookupType === 'AppointmentTypes' && this.type === 'subType') {
      this.displayCategoryList = false;
      this.displayDurationBuffers = true;
    } else {
      this.displayCategoryList = true;
      this.displayDurationBuffers = true;
    }
  }
}
