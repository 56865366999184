<div class="rel dash intro">
  <mat-dialog-content class="beta-dash" id="sub-plan-dialog-content">
    <div class="full-width beta-container">
      <div
        class="full-width beta-container-with-image"
        *ngIf="settings && settings['context'] != 'p4p' && settings['context'] != 'breeze'"
      ></div>
      <div
        class="full-width beta-container-with-image breeze"
        *ngIf="settings && settings['context'] == 'breeze'"
      ></div>
      <div class="full-width beta-container-with-image p4p" *ngIf="settings && settings['context'] == 'p4p'">
        <img
          class="duke-space-ship"
          *ngIf="settings && settings['context'] == 'p4p'"
          src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/P4p+spaceship.png"
        />
      </div>

      <div class="full-width inner text-center">
        <h1>Beta Release</h1>

        <p class="rm-mb">We have granted you access to this pre-release feature.</p>
        <p>
          The developers and testers are still finalising, so the final <br/>
          release may differ from what is available. Whilst we do our best, <br/>
          not all of these features are fully finished, so that they may be bugs. <br/>
        </p>

        <h3>Have an idea or feedback?</h3>
        <p>Let us know what <span>you</span> want on the platform.</p>
      </div>

      <div class="full-width footer" *ngIf="settings && settings['context'] != 'breeze'">
        <div class="full-width text-center">
          <mat-checkbox
            class="text-center smt smb ml"
            [(ngModel)]="showBeta"
            matTooltip="Please don't show this message For this module"
          >
            <span>Please don't show this message <strong>For this module</strong></span>
          </mat-checkbox>
        </div>

        <div class="full-width">
          <button class="pull-left smt ml btn" (click)="doNotShow()" mat-raised-button color="accent">Close</button>

          <button class="pull-right smt check-module mr btn" (click)="doNotShow2()" mat-raised-button>
            View Module
          </button>
        </div>
      </div>

      <div class="full-width footer breeze" *ngIf="settings && settings['context'] == 'breeze'">
        <div class="full-width text-center">
          <mat-checkbox
            class="text-center smt smb ml"
            [(ngModel)]="showBeta"
            matTooltip="Please don't show this message For this module"
          >
            <span style="color: black !important; text-shadow: none !important"
            >Please don't show this message <strong>For this module</strong></span
            >
          </mat-checkbox>
        </div>

        <div class="full-width">
          <button class="pull-left smt ml btn" (click)="doNotShow()" mat-raised-button color="accent">Close</button>

          <button class="pull-right smt check-module mr btn" (click)="doNotShow2()" mat-raised-button>
            View Module
          </button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
