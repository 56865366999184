import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { PatientService } from '../shared/patient.service';

@Component({
  selector: 'app-patient-card-changes-modal',
  templateUrl: './patient-card-changes-modal.component.html',
  styleUrls: ['./patient-card-changes-modal.component.css'],
})
export class PatientCardChangesModalComponent implements OnInit {
  @Input() invID = '';
  @Input() invitationID = '';
  @Output()
  done: EventEmitter<any> = new EventEmitter();

  @Output()
  invitation: EventEmitter<any> = new EventEmitter();

  @Input()
  force = false;
  feedback = '';

  constructor(private patientService: PatientService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.invID = data.invitationID;
    if (data.force != null) {
      this.force = data.force;
    }
  }

  ngOnInit() {}

  changeInvitation() {
    const payload = {
      feedback: this.feedback,
    };

    this.patientService.changeInivation(this.invID, payload, this.force).subscribe((res) => {
      NotifyAppComponent.displayToast(
        'success',
        'Thank you',
        'Your feedback has been sent to your dentist , We will contact you shortly! '
      );

      this.done.emit(true);
      this.invitation.emit(res);
    });
  }
}
