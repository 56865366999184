<div class="rel min-height-card2 " *ngIf="listDB && listDB?.firstLoadEvent == false">
  <div class="row clearfix padded rel">
    <h3 class="dataHeader rm-mt transparent">Fetching data...</h3>
  </div>
</div>

<div [hidden]="!listDB || (listDB && !listDB.data) || (listDB && listDB.data && listDB.data.length == 0)">
  <div class="row clearfix">


    <!-- <div class="pull-right rel buttonrow">
      <button class="clearfix pull-right" (click)="createNew()" mat-raised-button color="accent">
        <mat-icon>add</mat-icon> New Landing Page
      </button>
    </div> -->

    <!-- <div class="pull-right selection-items" @ngIfAnimation>
      <button class="animated mr" (click)="selectAll()" mat-stroked-button color="" matTooltip="Select All">
        <mat-icon>border_all</mat-icon>
      </button>
      <button class="animated mr" (click)="selectPage()" mat-stroked-button color="" matTooltip="Select Page">
        <mat-icon>border_outer</mat-icon>
      </button>

      <button class="animated mr" *ngIf="selectedIDs && selectedIDs.length > 0" [matMenuTriggerFor]="ActionBtnmenu"
        mat-stroked-button matTooltip="Options">
        <mat-icon class="fas fa-cog"></mat-icon>
      </button>
      <mat-menu #ActionBtnmenu="matMenu">
        <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
          <mat-icon>select_all</mat-icon> Deselect
        </button>

        <hr />

        <button class="row select-btn" *ngIf="selectedIDs && selectedIDs.length > 0" (click)="enableGroup()"
          mat-menu-item color="">
          <mat-icon>check_circle</mat-icon>
          Enable
        </button>

        <button class="row select-btn" *ngIf="selectedIDs && selectedIDs.length > 0" (click)="disableGroup()"
          mat-menu-item color="">
          <mat-icon>cancel</mat-icon>
          Disable
        </button>

        <button class="row select-btn" *ngIf="selectedIDs && selectedIDs.length > 0" (click)="removeGroup()"
          mat-menu-item color="">
          <mat-icon>delete</mat-icon>
          Remove
        </button>
      </mat-menu>
    </div> -->
  </div>

  <div class="row clearfix">
    <mat-card class="list-card">
      <div class="row clearfix  ">
        <div class="row clearfix flex">
          <h3 class="dataHeader rm-m pull-left   smr mt" style=" width: 218px;">
            Campaigns
            <span class="badge" [endVal]="dataSource?.filteredData?.length" countUp></span>
          </h3>


          <div class="full-width flex animated fadeInDown">


            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Search...</mat-label>
              <input class="clearfix" (input)="setFilter($event.target.value, 'search')" name="search" matInput />

              <mat-icon class=" smt smr" matPrefix>search</mat-icon>

            </mat-form-field>



          </div>

          <div class="qtr-width  selection-items" @ngIfAnimation>
            <button class="animated   pull-right  smt   " *ngIf="selectedIDs && selectedIDs.length > 0"
              [matMenuTriggerFor]="ActionBtnmenu" mat-icon-button matTooltip="Options">
              <mat-icon class="fas fa-cog"></mat-icon>  <span *ngIf="selectedIDs && selectedIDs.length>0"
                class="selected-number-item  accent-background">{{selectedIDs.length}}</span>
            </button>

            <button class="animated  pull-right   smt  " (click)="selectPage()" mat-icon-button color=""
              matTooltip="Select Page">
              <mat-icon>border_outer</mat-icon>
            </button>

            <button class="animated  pull-right smt sml" (click)="selectAll()" mat-icon-button color=""
              matTooltip="Select All">
              <mat-icon>border_all</mat-icon>
            </button>



            <mat-menu #ActionBtnmenu="matMenu">
              <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
                <mat-icon>select_all</mat-icon> Deselect
              </button>

              <hr />



              <button *ngIf="selectedIDs && selectedIDs.length > 0" (click)="changeStatusGroup('Paused')" mat-menu-item>
                <mat-icon class="fas fa-pause smr" [ngStyle]="{ 'color': messageUtil.getStatusColor('Paused', true)  }">
                </mat-icon><strong [ngStyle]="{ 'color':  messageUtil.getStatusColor('Paused', true)  }">
                  Pause</strong>
              </button>

              <button *ngIf="selectedIDs && selectedIDs.length > 0" (click)="changeStatusGroup('Sending')"
                mat-menu-item>
                <mat-icon class="fas fa-play smr" [ngStyle]="{ 'color': messageUtil.getStatusColor('Sending', true)  }">
                </mat-icon> <strong [ngStyle]="{ 'color':  messageUtil.getStatusColor('Sending', true) }">
                  Resume</strong>
              </button>


              <button *ngIf="selectedIDs && selectedIDs.length > 0" (click)="changeStatusGroup('Complete')"
                mat-menu-item>
                <mat-icon class="fas {{messageUtil.getStatusIcon('Complete') }} smr" [ngStyle]="{ 'color': messageUtil.getStatusColor('Complete', true)  }">
                </mat-icon> <strong [ngStyle]="{ 'color':  messageUtil.getStatusColor('Complete', true) }">
                  Complete</strong>
              </button>

              <hr />



              <button *ngIf="selectedIDs && selectedIDs.length > 0" (click)="changeStatusGroup('Canceled')"
                mat-menu-item>
                <mat-icon class="fas fa-ban smr" [ngStyle]="{ 'color': messageUtil.getStatusColor('Canceled', true) }">
                </mat-icon> <strong [ngStyle]="{ 'color':  messageUtil.getStatusColor('Canceled', true)  }">
                  Cancel</strong>
              </button>
              <hr>
              <button *ngIf="selectedIDs && selectedIDs.length > 0" (click)="removeGroup()" mat-menu-item>
                <mat-icon class="fas fa-trash smr" style="color:red;"></mat-icon> <span style="color:red;"> Delete</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>

      <!-- Table -->
      <mat-table class="list-table min-height-card" #table [dataSource]="dataSource" matSort>

        <ng-container cdkColumnDef="selectCheckBox">
          <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div class="margin-btm pull-left">
              <mat-checkbox [checked]="isSelected(row['ID'])" (change)="select(row['ID'], $event.checked)"
                color="accent">
              </mat-checkbox>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Label">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Label</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
            <h3>
              {{ row['Label'] }}
            </h3>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Merchant">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> {{'Merchant' | translate}}</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
            <app-card-picture class="side-card-picture2 mr" [tableName]="'Merchant'" [cardID]="row['Merchant_key']">
            </app-card-picture> {{ row['Merchant_Trading_As']  }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Sender">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Sender</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
            <app-card-picture class="side-card-picture2 mr" [tableName]="'Contact'" [cardID]="row['Sender_key']">
            </app-card-picture> {{ row['Sender.Name']  }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Date">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Create Date</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
            {{ row['DateTimeCreated']  | TimeLabelPast }} ago
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="DateSubmit">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Submit Date </mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])"
            matTooltip="{{ row['Submit_DateTime'] | customDate }}">
            <span *ngIf="row['Submit_DateTime'] "> {{ row['Submit_DateTime']  | TimeLabelPast }} ago </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Recipients">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Targets</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
            <strong class=" ml text-right">{{ row['Target_Number'] }}</strong>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Status">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>
            Status
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
            <span [ngStyle]="{ 'background-color':  row['Status.Color']  }" class="status-container title-status mr  ">
              <mat-icon class="{{messageUtil.getStatusIcon(row['Status.Label'])}} smr "
                style="font-size: 1.2rem;width: 0.9rem;height: 0.9rem;">
              </mat-icon>
              {{row['Status.Label']}}
              <span style="font-size: 0.6rem;float: right;width: 100%;display: block;text-align: center;"
                *ngIf="row['Status.Label']==='Scheduled'">
                {{row['Date_To_be_Sent'] | customDateTime}}
              </span>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button *ngIf="row['ID']" (click)="quickView(row['ID'])" mat-menu-item>
                  <mat-icon class="fas fa-eye mr"></mat-icon>
                  <span>Quick View</span>
                </button>


                <button *ngIf="row['ID']" (click)="quickView(row['ID'])" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span>Detailed View</span>
                </button>

                <hr *ngIf="row['Status.Label'] ==='Draft'" >

                <button *ngIf="row['Status.Label'] ==='Draft'" (click)="continue(row.ID)" mat-menu-item>
                  <mat-icon class="fas fa-edit smr"></mat-icon> Continue Editing
                </button>




                <hr *ngIf="row['Status.Label']==='Sending' || row['Status.Label']==='Sending'">



                <button *ngIf="row['Status.Label']==='Sending'" (click)="changeStatus(row,'Paused')" mat-menu-item>
                  <mat-icon class="fas fa-pause smr"
                    [ngStyle]="{ 'color': messageUtil.getStatusColor('Paused', true)  }">
                  </mat-icon><strong [ngStyle]="{ 'color':  messageUtil.getStatusColor('Paused', true)  }">
                    Pause</strong>
                </button>

                <button *ngIf="row['Status.Label']==='Paused'" (click)="changeStatus(row,'Sending')" mat-menu-item>
                  <mat-icon class="fas fa-play smr"
                    [ngStyle]="{ 'color': messageUtil.getStatusColor('Sending', true)  }">
                  </mat-icon> <strong [ngStyle]="{ 'color':  messageUtil.getStatusColor('Sending', true) }">
                    Resume</strong>
                </button>






                <button
                  *ngIf="row['Status.Label']==='Pending' || row['Status.Label']==='Sending' || row['Status.Label']==='Paused' || row['Status.Label']==='Scheduled'"
                  (click)="changeStatus('Complete')" mat-menu-item>
                  <mat-icon class="fas {{messageUtil.getStatusIcon('Complete') }} smr"
                    [ngStyle]="{ 'color': messageUtil.getStatusColor('Complete', true)  }">
                  </mat-icon> <strong [ngStyle]="{ 'color':  messageUtil.getStatusColor('Complete', true) }">
                    Complete</strong>
                </button>
                <hr *ngIf="canApplyStatus(row,'Canceled') ">

                <button *ngIf="canApplyStatus(row,'Canceled')" (click)="changeStatus(row,'Canceled')" mat-menu-item>
                  <mat-icon class="fas fa-ban smr"
                    [ngStyle]="{ 'color': messageUtil.getStatusColor('Canceled', true) }">
                  </mat-icon> <strong [ngStyle]="{ 'color':  messageUtil.getStatusColor('Canceled', true)  }">
                    Cancel</strong>
                </button>

                <hr>
                <button (click)="clone(row)" mat-menu-item>
                  <mat-icon class="fas fa-clone smr"></mat-icon> Clone
                </button>
                <hr>
                <button (click)="remove(row.ID)" mat-menu-item>
                  <mat-icon class="fas fa-trash smr" style="color:red;"></mat-icon> <span style="color:red;"> Delete</span>
                </button>




              </mat-menu>




            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator #paginator [length]="dataSource?.filteredData?.length" [pageIndex]="0" [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>
    </mat-card>
  </div>
</div>
<div *ngIf="listDB?.data?.length == 0 && listDB?.firstLoadEvent != false">
  <app-empty-list-message [message]="'No record found to display'" [isAction]="true" [actionLabel]="'New Bulk '+messageLabel"
    (actionEvent)="add()"></app-empty-list-message>
</div>
