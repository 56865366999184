import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContractListComponent } from './contract-list/contract-list.component';

import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { ArchwizardModule } from 'angular-archwizard';
import { VerticalTimelineModule } from 'angular-vertical-timeline';
// import { ChartsModule } from 'ng2-charts';
import { NgxGaugeModule } from 'ngx-gauge';
import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '../../shared/shared.module';
import { ActionLogModule } from '../action-log/action-log.module';
import { BankAccountModule } from '../bank-account/bank-account.module';
import { CustomerModule } from '../customer/customer.module';
import { OperatorModule } from '../operator/operator.module';
import { SettlementApproveViewComponent } from '../settlement/settlement-approve-view/settlement-approve-view.component';
import { SettlementContractListComponent } from '../settlement/settlement-contract-list/settlement-contract-list.component';
import { SettlementListGlobalComponent } from '../settlement/settlement-list-global/settlement-list-global.component';
import { SettlementListComponent } from '../settlement/settlement-list/settlement-list.component';
import { SettlementViewGroupComponent } from '../settlement/settlement-view-group/settlement-view-group.component';
import { SettlementViewMiniComponent } from '../settlement/settlement-view-mini/settlement-view-mini.component';
import { SettlementViewComponent } from '../settlement/settlement-view/settlement-view.component';
import { ComplianceTaskComponent } from './compliance-task/compliance-task.component';
import { ContractArrearsListComponent } from './contract-arrears-list/contract-arrears-list.component';
import { ContractCapacityCheckComponent } from './contract-capacity-check/contract-capacity-check.component';
import { ContractCreditCheckComponent } from './contract-credit-check/contract-credit-check.component';
import { ContractDetailContactsComponent } from './contract-detail-contacts/contract-detail-contacts.component';
import { ContractDetailDocumentsComponent } from './contract-detail-documents/contract-detail-documents.component';
import { ContractDetailFundingHistoryComponent } from './contract-detail-funding-history/contract-detail-funding-history.component';
import { ContractDetailKPIComponent } from './contract-detail-kpi/contract-detail-kpi.component';
import { ContractDetailOverviewComponent } from './contract-detail-overview/contract-detail-overview.component';
import { ContractDetailPaymentsComponent } from './contract-detail-payments/contract-detail-payments.component';
import { ContractDetailSharedSectionComponent } from './contract-detail-shared-section/contract-detail-shared-section.component';
import { ContractDetailComponent } from './contract-detail/contract-detail.component';
import { ContractDocumentViewComponent } from './contract-document-view/contract-document-view.component';
import { ContractDocumentComponent } from './contract-document/contract-document.component';
import { ContractIdentityCheckComponent } from './contract-identity-check/contract-identity-check.component';
import { ContractIntroModalComponent } from './contract-intro-modal/contract-intro-modal.component';
import { ContractListActiveComponent } from './contract-list-active/contract-list-active.component';
import { ContractListGlobalComponent } from './contract-list-global/contract-list-global.component';
import { ContractListOverviewComponent } from './contract-list-overview/contract-list-overview.component';
import { ContractOverviewComponent } from './contract-overview/contract-overview.component';
import { ContractViewSimpleComponent } from './contract-view-simple/contract-view-simple.component';
import { ContractViewComponent } from './contract-view/contract-view.component';
import { ContractsViewComponent } from './contracts-view/contracts-view.component';
import { PersonalCircumstancesViewComponent } from './personal-circumstances-view/personal-circumstances-view.component';
import { PersonalCircumstancesComponent } from './personal-circumstances/personal-circumstances.component';
import { ContractRoutingModule } from './shared/contract-routing.module';

import { MatListModule } from '@angular/material/list';
import { PersonalCircumstancesModule } from '../personal-circumstance/personal-circumstances.module';
import { ContractArrangementModalComponent } from './contract-arrangement-modal/contract-arrangement-modal.component';
import { ContractArrangementsComponent } from './contract-arrangements/contract-arrangements.component';
import { ContractDetailAddPaymentComponent } from './contract-detail-add-payment/contract-detail-add-payment.component';
import { ContractInvoicesSettlementsComponent } from './contract-invoices-settlements/contract-invoices-settlements.component';
import { ContractSupportingDocumentsComponent } from './contract-supporting-documents/contract-supporting-documents.component';
import { ContractWaiversComponent } from './contract-waivers/contract-waivers.component';
import { ContractService } from './shared/contract.service';
import { ContractOverviewViewComponent } from './contract-overview-view/contract-overview-view.component';
import { CardModule } from '../card/card.module';
import { MessageModule } from '../message/message.module';
import { ContractListServerComponent } from './contract-list-server/contract-list-server.component';

@NgModule({
  imports: [
    NgPipesModule,
    CommonModule,
    FormsModule,
    CdkTableModule,
    ArchwizardModule,
    SharedModule,
    NgxGaugeModule,
    ContractRoutingModule,
    ReactiveFormsModule,
    CustomerModule,
    OperatorModule,
    ActionLogModule,
    BankAccountModule,
    VerticalTimelineModule,
    PersonalCircumstancesModule,
    MatSortModule,
    MatListModule,
    CardModule,
    MessageModule
  ],
  declarations: [
    SettlementContractListComponent,
    SettlementViewMiniComponent,
    SettlementViewGroupComponent,
    ContractsViewComponent,
    ContractDocumentComponent,
    SettlementViewComponent,
    ContractListActiveComponent,
    ContractViewSimpleComponent,
    ContractViewComponent,
    ContractDocumentViewComponent,
    ContractIntroModalComponent,
    ContractOverviewComponent,
    ContractListComponent,
    ContractListOverviewComponent,
    ContractDetailComponent,
    ContractDetailOverviewComponent,
    ContractDetailPaymentsComponent,
    ContractDetailKPIComponent,
    ContractDetailContactsComponent,
    ContractDetailFundingHistoryComponent,
    ContractDetailDocumentsComponent,
    ContractDetailSharedSectionComponent,
    PersonalCircumstancesComponent,
    SettlementListGlobalComponent,
    SettlementListComponent,
    ContractCreditCheckComponent,
    ContractCapacityCheckComponent,
    ContractListGlobalComponent,
    PersonalCircumstancesViewComponent,
    ContractIdentityCheckComponent,
    ComplianceTaskComponent,
    ContractArrearsListComponent,
    SettlementApproveViewComponent,
    ContractSupportingDocumentsComponent,
    ContractInvoicesSettlementsComponent,
    ContractWaiversComponent,
    ContractArrangementsComponent,
    ContractArrangementModalComponent,
    ContractDetailAddPaymentComponent,
    ContractOverviewViewComponent,
    ContractListServerComponent
  ],
  exports: [
    SettlementContractListComponent,
    SettlementViewMiniComponent,
    SettlementViewGroupComponent,
    ContractsViewComponent,
    ContractDocumentComponent,
    SettlementViewComponent,
    ContractListActiveComponent,
    ContractViewSimpleComponent,
    ContractViewComponent,
    ContractDocumentViewComponent,
    ContractIntroModalComponent,
    ContractOverviewComponent,
    ContractListComponent,
    ContractListOverviewComponent,
    ContractDetailComponent,
    ContractDetailOverviewComponent,
    ContractDetailPaymentsComponent,
    ContractDetailKPIComponent,
    ContractDetailContactsComponent,
    ContractDetailFundingHistoryComponent,
    ContractDetailDocumentsComponent,
    ContractDetailSharedSectionComponent,
    PersonalCircumstancesComponent,
    SettlementListGlobalComponent,
    SettlementListComponent,
    ContractCreditCheckComponent,
    ContractCapacityCheckComponent,
    ContractListGlobalComponent,
    PersonalCircumstancesViewComponent,
    ContractIdentityCheckComponent,
    ComplianceTaskComponent,
    SettlementApproveViewComponent,
    ContractSupportingDocumentsComponent,
    ContractInvoicesSettlementsComponent,
    ContractWaiversComponent,
    ContractArrangementsComponent,
    ContractArrangementModalComponent,
    ContractDetailAddPaymentComponent,
    ContractOverviewViewComponent,
    ContractListServerComponent
  ],
  providers: [ContractService],
})
export class ContractModule {}
