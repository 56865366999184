<div class="container error-page">
  <mat-card class="error text-center">
    <img class="character_time_error" />
    <div class="circle"></div>

    <h1 class="sm-h2 accent-color">Session timeout</h1>
    <hr class="rm-m"/>
    <p>We noticed you have been inactive for while.</p>
    <p>You have been automatically logged out for security reasons.</p>

    <div class="button-row">
      <button class="btn-large" (click)="login()" mat-raised-button color="accent">login Again</button>
    </div>
  </mat-card>
</div>
