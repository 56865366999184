import { Observable, of as observableOf } from 'rxjs';

import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../authentication/shared/authentication.service';

@Injectable()
export class SessionsService {
  constructor(private http: HttpClient) {}

  /**
   * @description
   * Get Active Sessions
   */

  getConnectionHistoryList(payload) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    return this.http.get(`${environment.nodeUrl}/4dusers`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  getOneConnectionHistory(id) {
    return this.http.get(`${environment.nodeUrl}/4dusers/${id}`, {}).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  getConnectionHistoryProfilePicture(id) {
    return `${environment.nodeUrl}/4dusers/session-picture-stream/${id}`;
  }

  deleteOneConnectionHistory(id) {
    return this.http.delete(`${environment.nodeUrl}/4dusers/${id}`);
  }

  getActiveSessions(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    return this.http.get(`${environment.nodeUrl}/sessions`, options).pipe(map((res: HttpResponse<any>) => res['data']));
  }
  /**
   * @description
   * Delete Session by Id
   * @param id
   */
  deleteSession(id) {
    return this.http.delete(`${environment.nodeUrl}/sessions/${id}`);
  }

  deleteSessions(ids: string[]) {
    return this.http.delete(`${environment.nodeUrl}/sessions/group/${ids.join(',')}`);
  }

  delete4dUsers(ids: string[]) {
    return this.http.delete(`${environment.nodeUrl}/4dusers/group/${ids.join(',')}`);
  }

  getOneSession(id) {
    return this.http.get(`${environment.nodeUrl}/sessions/${id}`, {}).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  getSessionProfilePicture(id) {
    return `${environment.nodeUrl}/sessions/session-picture-stream/${id}`;
  }

  getProfilePic() {
    if (AuthenticationService.profilePic != null) {
      return observableOf(AuthenticationService.profilePic);
    } else {
      return this.http.get(`${environment.nodeUrl}/current/profile-picture`).pipe(
        map((res: HttpResponse<any>) => {
          AuthenticationService.profilePic = res['data'];
          return AuthenticationService.profilePic;
        })
      );
    }
  }

  getPracticePic() {
    if (AuthenticationService.practicePic != null) {
      return observableOf(AuthenticationService.practicePic);
    } else {
      return this.http.get(`${environment.nodeUrl}/current/practice-picture`).pipe(
        map((res: HttpResponse<any>) => {
          AuthenticationService.practicePic = res['data'];
          return AuthenticationService.practicePic;
        })
      );
    }
  }

  uploadProfilePic(payload) {
    return this.http.put(`${environment.nodeUrl}/current/profile-picture`, payload).pipe(
      map((res: HttpResponse<any>) => {
        if (res && res['data']) {
          AuthenticationService.profilePic = res['data'];

          AuthenticationService.updateProfilePicture.emit(res['data']);
          return res['data'];
        } else {
          return null;
        }
      })
    );
  }

  uploadPracticePic(payload) {
    return this.http.put(`${environment.nodeUrl}/current/practice-picture`, payload).pipe(
      map((res: HttpResponse<any>) => {
        AuthenticationService.practicePic = null;

        return res['data'];
      })
    );
  }

  uploadPracticePicCover(payload) {
    return this.http.put(`${environment.nodeUrl}/current/practice-picture-cover`, payload).pipe(
      map((res: HttpResponse<any>) => {
        AuthenticationService.practicePic = null;

        return res['data'];
      })
    );
  }

  uploadPracticeAppointmentCover(payload) {
    return this.http.put(`${environment.nodeUrl}/current/practice-appointment-cover`, payload).pipe(
      map((res: HttpResponse<any>) => {
        AuthenticationService.practicePic = null;

        return res['data'];
      })
    );
  }

  uploadImportData(payload) {
    return this.http.put(`${environment.nodeUrl}/current/import-data`, payload).pipe(
      map((res: HttpResponse<any>) => {
        // AuthenticationService.practicePic = null;

        return res['data'];
      })
    );
  }

  resetPicture() {
    AuthenticationService.profilePic = null;
    AuthenticationService.practicePic = null;
  }

  changePassword(payload) {
    return this.http.put(`${environment.nodeUrl}/current/password`, payload);
  }

  editProfile(payload) {
    return this.http.put(`${environment.nodeUrl}/current/profile`, payload);
  }

  /**
   * @description
   * Get $D users connection
   * @param payload
   */
}
