import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../authentication/shared/authentication.service';

@Component({
  selector: 'app-econnect-overview',
  templateUrl: './econnect-overview.component.html',
  styleUrls: ['./econnect-overview.component.css'],
})
export class EconnectOverviewComponent implements OnInit {
  tableID;
  isPromoterOrAdmin = false;

  constructor(private activeRoute: ActivatedRoute, private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      this.activeRoute.params.subscribe((params) => {
        if (params['tableID']) {
          this.tableID = params['tableID'];
        }
      });
    });
  }

  getTableID(e) {
    if (e) {
      this.tableID = e;
    }
  }
}
