<div class="container containerHH animated faster text-center">
  <div class="row clearfix text-center title-pad add-mb">
    <h1 class="white">What's my Health History?</h1>

    <p class="white text-center lead mobile2">
      Whenever you visit your {{ "KEYWORD.practitioner" | translate | titlecase }} (or GP), you're asked to give them
      some basic
      details about your health history - usually with a paper form on a clipboard.
    </p>
  </div>

  <ng-container>
    <div class="full-width clearfix">
      <div class="row clearfix flex mt">
        <mat-card class="card card-header welcome clearfix">
          <div class="row flex">
            <div class="header-image"></div>

            <div class="content-frame">
              <p class="larger">
                To save time, paper and the environment, {{ merchant?.TradingAs }} is<strong
              ><i> "going green"</i></strong
              >
                and now doing this electronically. <br/>By clicking the get started button below, you can complete this
                task before your appointment, thus streamlining the whole process - simply and securely!
              </p>

              <hr/>
              <h3 class="text-center sr-title">Benefits include:</h3>

              <swiper [config]="config">
                <div class="block swiper-slide">
                  <mat-icon class="primary-color fas fa-file-signature"></mat-icon>
                  <p>No manual forms when attending the {{"KEYWORD.practice" | translate}}</p>
                </div>
                <div class="block swiper-slide">
                  <mat-icon class="primary-color fas fa-lightbulb"></mat-icon>
                  <p>Easy to use and Informative</p>
                </div>
                <div class="block swiper-slide">
                  <mat-icon class="primary-color fas fa-clock"></mat-icon>
                  <p>It takes only a few minutes to complete</p>
                </div>
                <div class="block swiper-slide">
                  <mat-icon class="primary-color fas fa-pills"></mat-icon>
                  <p>Captures all illnesses, allergies, medications taken by yourself</p>
                </div>
                <div class="block swiper-slide">
                  <mat-icon class="primary-color fas fa-check"></mat-icon>
                  <p>Provides your {{"KEYWORD.dentist" | translate}} everything needed to make informed decisions on
                    your behalf.</p>
                </div>
                <div class="block swiper-slide">
                  <mat-icon class="primary-color fas fa-users-cog"></mat-icon>
                  <p>
                    You can update your details as your circumstances change, and you can even print a copy for your
                    records.
                  </p>
                </div>
                <div class="block swiper-slide">
                  <mat-icon class="primary-color fas fa-user-lock"></mat-icon>
                  <p>At all times, your information remains confidential between you and
                    our {{"KEYWORD.practice" | translate}}.</p>
                </div>
                <div class="block swiper-slide">
                  <mat-icon class="primary-color fas fa-eye-slash"></mat-icon>
                  <p>Your data is secured, and we will not share with any government agency or third party.</p>
                </div>
              </swiper>
            </div>
          </div>
        </mat-card>
      </div>

      <app-dentist-view-consumer
        class="dentist-info row clearfix"
        *ngIf="invitation && invitation['DentistContact_key']"
        [dentistID]="invitation['DentistContact_key']"
        [messageFromDentist]="invitation.MessageFromDentist"
      >
      </app-dentist-view-consumer>

      <mat-card
        class="treatment secondary-gradient rel animate first botton clearfix mb"
        [matRippleColor]="'rgba(255,255,255,0.14)'"
        (click)="proceedEvent()"
        matRipple
      >
        <div class="row clearfix mobWrap valueLabels">
          <div class="col-left full-width clearfix text-center">
            <h2 class="summary-header sr-title rm-m mt text-center">Your Health History:</h2>
            <p class="rm-mt text-center">
              <span>Click Get started to commence</span>
            </p>
          </div>
          <div class="col-right full-width clearfix valueLabels text-right">
            <button class="full-width full-height btn-outline pop" mat-button>
              <span> Get started! <mat-icon>chevron_right</mat-icon> </span>
            </button>
          </div>
        </div>
        <mat-icon class="background fas {{'fa-tooth' | translate}}"></mat-icon>
      </mat-card>
    </div>
  </ng-container>
</div>
<div class="row clearfix full-width help-footer mt" *ngIf="displayFooter == true">
  <div class="container">
    <!-- <h4 class="sr-title">Need some help?</h4> -->
    <div class="row clearfix text-center">
      <button (click)="contactPractice('I need help to complete this form')" mat-button color="primary">
        <mat-icon>help</mat-icon> Need help completing this form?
      </button>
      <button (click)="contactPractice('I need to speak to a staff member in private')" mat-button color="primary">
        <mat-icon>sentiment_satisfied</mat-icon> Need to speak in private to a staff member?
      </button>
    </div>
  </div>
</div>
