import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PerformanceIntroModalComponent } from '../performance-intro-modal/performance-intro-modal.component';
import { PerformancePromoterComponent } from '../performance-promoter/performance-promoter.component';
import { SubAccountStatisticalReportComponent } from '../sub-account-statistical-report/sub-account-statistical-report.component';

const routes: Routes = [
  {
    path: 'performance-promoter',
    component: PerformancePromoterComponent,
  },
  {
    path: 'performance-intro-modal',
    component: PerformanceIntroModalComponent,
  },
  {
    path: 'sub-account-statistical-report',
    component: SubAccountStatisticalReportComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PerformanceRoutingModule {}
