import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';

@Injectable()
export class InstantOfferService {
  constructor(private http: HttpClient) {}

  getTagsList(body = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }
    options = {
      params,
    };
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/tag-merchant/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/tag-merchant', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/tag-merchant', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMerchantList(body = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (let key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };

    return this.http.get(environment.nodeUrl + '/merchant', options).pipe(map((res: HttpResponse<any>) => res['data']));
  }

  instantOfferListTheme(): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/instant-offer/theme', {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createInstantOffer(payload, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .post(environment.nodeUrl + '/instant-offer/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/instant-offer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  createInstantOfferLink(payload, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .post(environment.nodeUrl + '/offer-link/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/offer-link', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  modifyInstantOffer(id, payload, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .put(environment.nodeUrl + '/instant-offer/global/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/instant-offer/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  modifyInstantOfferLink(id, payload, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .put(environment.nodeUrl + '/offer-link/global/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/offer-link/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  getInstantOffer(ID, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/instant-offer/global/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/instant-offer/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/instant-offer/public/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  getInstantOfferLink(ID, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/offer-link/global/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/offer-link/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/offer-link/public/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getInstantOfferList(payload = null, sessionType) {
    let url = 'instant-offer';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'instant-offer/global';
    }
    let params: HttpParams = new HttpParams();
    let options = {};
    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = {
        params,
      };
    }
    return this.http.get(`${environment.nodeUrl}/${url}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  getInstantOfferLinkList(payload = null, sessionType) {
    let url = 'offer-link';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'offer-link/global';
    }
    let params: HttpParams = new HttpParams();
    let options = {};
    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = {
        params,
      };
    }
    return this.http.get(`${environment.nodeUrl}/${url}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  deleteInstantOffer(id, sessionType) {
    let url = 'instant-offer';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'instant-offer/global';
    }
    return this.http.delete(`${environment.nodeUrl}/${url}/${id}`, {}).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  deleteInstantOfferLink(id, sessionType) {
    let url = 'offer-link';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'offer-link/global';
    }
    return this.http.delete(`${environment.nodeUrl}/${url}/${id}`, {}).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  modifyInstantOfferGroup(payload, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .put(`${environment.nodeUrl}/instant-offer/group/global`, payload)
        .pipe(map((res: Response) => res['data']));
    } else {
      return this.http
        .put(`${environment.nodeUrl}/instant-offer/group`, payload)
        .pipe(map((res: Response) => res['data']));
    }
  }

  deleteInstantOfferGroup(ids, sessionType): Observable<any> {
    let _ids;
    if (Array.isArray(ids) == true) {
      _ids = ids.join(',');
    } else if (typeof ids == 'string') {
      _ids = ids;
    }
    if (_ids) {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.http
          .delete(environment.nodeUrl + '/instant-offer/group/global/' + _ids)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.http
          .delete(environment.nodeUrl + '/instant-offer/group/' + _ids)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }
}
