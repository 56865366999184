<div class="rel text-center cdia">
  <!-- <mat-icon class="xlarge-modal-icon accent-color">{{confirmOptionDialog.icon}}</mat-icon>
  <h2 mat-dialog-title class="sr-title rm-mb mt">{{confirmOptionDialog.title}}</h2> -->

  <div class="card-header primary-gradient-img clearfix confirmModal" [ngClass]="confirmOptionDialog.icon">
    <button
      class="btn-close btn-clear mat-button smt white custom-close"
      [ngClass]="!confirmOptionDialog.title ? 'no-title-close' : ''"
      (click)="onNo()"
      matDialogClose
    >
      <mat-icon>clear</mat-icon>
    </button>
    <div
      class="full-width flex main-title-container"
      [ngClass]="{ 'main-icon-container-no-title': confirmOptionDialog && !confirmOptionDialog.title }"
    >
      <div class="main-icon-container text-center">
        <mat-icon class="main-icon accent-color" *ngIf="isFontAwsome(confirmOptionDialog.icon) != true">
          {{ confirmOptionDialog.icon || 'info' }}</mat-icon
        >

        <mat-icon
          class="main-icon accent-color {{ confirmOptionDialog.icon || 'fas fa-info' }}"
          *ngIf="isFontAwsome(confirmOptionDialog.icon) == true"
        ></mat-icon>
      </div>
      <div class="row full-width title-container clearfix">
        <div class="title-container-sub">
          <h2 class="text-left title-header rm-m">
            {{ confirmOptionDialog.title }}
          </h2>
        </div>
      </div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="row clearfix">
      <div [innerHTML]="confirmOptionDialog.confirmMessage | safeHtml"></div>
    </div>
    <hr/>
    <div class="row clearfix">
      <div [innerHTML]="confirmOptionDialog.optionsLabel | safeHtml"></div>
    </div>
    <div class="row clearfix mt">
      <mat-select class="full-width" [(ngModel)]="choice" placeholder="Please select">
        <mat-option *ngFor="let option of options" [value]="option">
          {{ option.viewValue }}
        </mat-option>
      </mat-select>
    </div>
    <hr/>
  </mat-dialog-content>
  <mat-dialog-actions class="button-row text-center">
    <button (click)="onNo()" mat-raised-button matDialogClose>{{ confirmOptionDialog.noMessage }}</button>
    <!-- Can optionally provide a result for the closing dialog. -->
    <button [disabled]="choice == null" (click)="onYes()" mat-raised-button color="accent">
      {{ confirmOptionDialog.yesMessage }}
    </button>
  </mat-dialog-actions>
</div>
