<div class="row clearfix defineMessage">
  <!-- blue heading -->
  <div class="card-header primary-gradient-img clearfix">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear pull-right mat-button" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <!-- merchant calculated name -->
      <div class="row titleArea clearfix">
        <mat-icon class="fas fa-tag pull-left"></mat-icon>
        <h2 class="message-title summary-header rm-m">Edit {{ "KEYWORD.patient" | translate | titlecase }} Tags</h2>
      </div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="full-width mt clearfix">
      <p class="small">
        Use tags to make it easier to categorize and find {{ "KEYWORD.patient" | translate }}s when sending invitations
        and marketing campaigns.
      </p>
    </div>

    <div class="full-width flex row clearfix mb">
      <app-tag-list
        class="full-width"
        [tags]="tags"
        [selectedTags]="defaultSelectedTags"
        [tagFormatIsObject]="true"
        [title]="'Attach tags to this patient'"
        [canRemove]="true"
        [canCreate]="true"
        [directRemoveDefault]="true"
        [hasFilter]="true"
        [canSelect]="false"
        [remove]="removetag"
        (getRemovedTag)="getRemovedTag($event)"
        (getCreate)="createNewTag()"
        (getSelectedTags)="getSelectedTags($event)"
      >
      </app-tag-list>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="row clearfix text-center mt">
      <button (click)="closeEvent()" mat-raised-button color="primary">Close</button>

      <button (click)="save()" mat-raised-button color="accent">Save</button>
    </div>
  </mat-dialog-actions>
</div>
