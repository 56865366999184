import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Settings } from '../../../shared/types/settings';

import * as moment from 'moment';
import { trigger, style, transition, animate, keyframes, query, stagger } from '@angular/animations';

import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { MerchantService } from '../shared/merchant.service';
import { TranslateService } from '@ngx-translate/core';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';

@Component({
  selector: 'app-version-update',
  templateUrl: './version-update.component.html',
  styleUrls: ['./version-update.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.stacked-card-view', style({ opacity: 0 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.stacked-card-view', style({ opacity: 1 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class VersionUpdateComponent implements OnInit {
  promoterVersion = 'none';
  merchantVersion = 'none';
  consumerVersion = 'none';
  integrationVersion = 'none';
  publicSiteVersion = 'none';

  isLoaded = false;

  messageMode = 'all';
  promoterVersionText;
  merchantVersionText;
  consumerVersionText;
  integrationVersionText;
  publicSiteVersionText;
  isTranslationHash = false;
  isLookupHash = false;
  isDocumentTypeHash = false;
  isSuburbHash = false;
  _versionDescription;
  versionDescription;
  updateDescription = false;
  isCacheHash = false;
  versionDate;

  cleanMessageGroupLocalData = false;
  updateLastVersion = false;
  versionDescriptions = [];
  displayLog = false;

  @Output() close = new EventEmitter();

  settings = Settings.global;

  constructor(
    private helperService: MerchantService,

    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.helperService.getGlobalSettings().subscribe((res) => {
      if (res && res[0]) {
        this.settings = res[0];

        this.promoterVersionText = this.settings['appPromoterVersionNumber'];
        this.merchantVersionText = this.settings['appMerchantVersionNumber'];
        this.consumerVersionText = this.settings['appConsumerVersionNumber'];
        this.integrationVersionText = this.settings['appIntegrationVersionNumber'];
        this.publicSiteVersionText = this.settings['appPublicSiteVersionNumber'];

        this.versionDescription = this.settings['versionDescription'];

        this._versionDescription = this.settings['versionDescription'];

        this.versionDescriptions = this.settings['versionDescriptions'] || [];
      }

      this.isLoaded = true;
    });
  }

  save() {
    let update = false;

    let versionObejct = {
      applications: [],
      versionDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      versionDescription: this.versionDescription,
      id: btoa(Math.random().toString()).substring(2, 20),
    };

    let payload: any = {
      lookupHash: null,
      documentTypeHash: null,
      suburbHash: null,
      appPromoterVersionNumber: null,
      appMerchantVersionNumber: null,
      appConsumerVersionNumber: null,
      appIntegrationVersionNumber: null,
      appPublicSiteVersionNumber: null,
      translateHash: null,
      appPromoterVersionDate: null,
      appMerchantVersionDate: null,
      appConsumerVersionDate: null,
      appIntegrationVersionDate: null,
      appPublicSiteVersionDate: null,
      versionDescription: null,
      versionDate: null,
      cacheHash: null,
      cacheClearupDate: null,
    };

    if (this.isLookupHash == true) {
      payload.lookupHash = btoa(Math.random().toString()).substring(2, 20);
      update = true;
    } else {
      delete payload.lookupHash;
    }
    if (this.isDocumentTypeHash == true) {
      payload.documentTypeHash = btoa(Math.random().toString()).substring(2, 20);
      update = true;
    } else {
      delete payload.documentTypeHash;
    }

    if (this.isSuburbHash == true) {
      payload.suburbHash = btoa(Math.random().toString()).substring(2, 20);
      update = true;
    } else {
      delete payload.suburbHash;
    }

    if (this.promoterVersion == 'manual') {
      payload.appPromoterVersionNumber = this.promoterVersionText;
      payload.appPromoterVersionDate = moment().format('YYYY-MM-DD HH:mm:ss');
      update = true;

      versionObejct.applications.push({
        name: 'Promoter',
        version: payload.appPromoterVersionNumber,
      });
    } else if (this.promoterVersion !== 'none') {
      let p = this.calculateVersion(this.promoterVersion, 'appPromoterVersionNumber');
      if (p) {
        payload.appPromoterVersionNumber = p;
        payload.appPromoterVersionDate = moment().format('YYYY-MM-DD HH:mm:ss');
        update = true;

        versionObejct.applications.push({
          name: 'Promoter',
          version: payload.appPromoterVersionNumber,
        });
      } else {
        delete payload.appPromoterVersionNumber;
        delete payload.appPromoterVersionDate;
      }
    } else {
      delete payload.appPromoterVersionNumber;
      delete payload.appPromoterVersionDate;
    }

    if (this.merchantVersion == 'manual') {
      payload.appMerchantVersionNumber = this.merchantVersionText;
      payload.appMerchantVersionDate = moment().format('YYYY-MM-DD HH:mm:ss');
      update = true;

      versionObejct.applications.push({
        name: 'Merchant',
        version: payload.appMerchantVersionNumber,
      });
    } else if (this.merchantVersion !== 'none') {
      let p = this.calculateVersion(this.merchantVersion, 'appMerchantVersionNumber');
      if (p) {
        payload.appMerchantVersionNumber = p;
        payload.appMerchantVersionDate = moment().format('YYYY-MM-DD HH:mm:ss');
        update = true;

        versionObejct.applications.push({
          name: 'Merchant',
          version: payload.appMerchantVersionNumber,
        });
      } else {
        delete payload.appMerchantVersionNumber;
        delete payload.appMerchantVersionDate;
      }
    } else {
      delete payload.appMerchantVersionNumber;
      delete payload.appMerchantVersionDate;
    }

    if (this.consumerVersion == 'manual') {
      payload.appConsumerVersionNumber = this.consumerVersionText;
      payload.appConsumerVersionDate = moment().format('YYYY-MM-DD HH:mm:ss');

      versionObejct.applications.push({
        name: 'Consumer',
        version: payload.appConsumerVersionNumber,
      });

      update = true;
    } else if (this.consumerVersion !== 'none') {
      let p = this.calculateVersion(this.consumerVersion, 'appConsumerVersionNumber');
      if (p) {
        payload.appConsumerVersionNumber = p;
        payload.appConsumerVersionDate = moment().format('YYYY-MM-DD HH:mm:ss');
        update = true;

        versionObejct.applications.push({
          name: 'Consumer',
          version: payload.appConsumerVersionNumber,
        });
      } else {
        delete payload.appConsumerVersionNumber;
        delete payload.appConsumerVersionDate;
      }
    } else {
      delete payload.appConsumerVersionNumber;
      delete payload.appConsumerVersionDate;
    }

    if (this.integrationVersion == 'manual') {
      payload.appIntegrationVersionNumber = this.integrationVersionText;
      payload.appIntegrationVersionDate = moment().format('YYYY-MM-DD HH:mm:ss');
      update = true;

      versionObejct.applications.push({
        name: 'Integration',
        version: payload.appIntegrationVersionNumber,
      });
    } else if (this.integrationVersion !== 'none') {
      let p = this.calculateVersion(this.integrationVersion, 'appIntegrationVersionNumber');
      if (p) {
        payload.appIntegrationVersionNumber = p;
        payload.appIntegrationVersionDate = moment().format('YYYY-MM-DD HH:mm:ss');
        update = true;

        versionObejct.applications.push({
          name: 'Integration',
          version: payload.appIntegrationVersionNumber,
        });
      } else {
        delete payload.appIntegrationVersionNumber;
        delete payload.appIntegrationVersionDate;
      }
    } else {
      delete payload.appIntegrationVersionNumber;
      delete payload.appIntegrationVersionDate;
    }

    if (this.publicSiteVersion == 'manual') {
      payload.appPublicSiteVersionNumber = this.publicSiteVersionText;
      payload.appPublicSiteVersionDate = moment().format('YYYY-MM-DD HH:mm:ss');
      update = true;

      versionObejct.applications.push({
        name: 'Public Site',
        version: payload.appPublicSiteVersionNumber,
      });
    } else if (this.publicSiteVersion !== 'none') {
      let p = this.calculateVersion(this.publicSiteVersion, 'appPublicSiteVersionNumber');
      if (p) {
        payload.appPublicSiteVersionNumber = p;
        payload.appPublicSiteVersionDate = moment().format('YYYY-MM-DD HH:mm:ss');

        versionObejct.applications.push({
          name: 'Public Site',
          version: payload.appPublicSiteVersionNumber,
        });

        update = true;
      } else {
        delete payload.appPublicSiteVersionNumber;
        delete payload.appPublicSiteVersionDate;
      }
    } else {
      delete payload.appPublicSiteVersionNumber;
      delete payload.appPublicSiteVersionDate;
    }

    if (this.isTranslationHash == true) {
      payload.translateHash = btoa(Math.random().toString()).substring(2, 20);
      update = true;
    } else {
      delete payload.translateHash;
    }

    if (this.isCacheHash == true) {
      payload.cacheHash = btoa(Math.random().toString()).substring(2, 20);
      payload.cacheClearupDate = moment().format('YYYY-MM-DD HH:mm:ss');
      update = true;
    } else {
      delete payload.cacheHash;
      delete payload.cacheClearupDate;
    }

    if (this.versionDescription && this.updateDescription == true) {
      payload.versionDescription = this.versionDescription;
      payload.versionDate = moment().format('YYYY-MM-DD HH:mm:ss');
      update = true;
    } else {
      delete payload.versionDescription;
      delete payload.versionDate;

      versionObejct = null;
    }

    if (this.cleanMessageGroupLocalData == true) {
      update = true;
    }

    if (update === true) {
      let versionArray = this.buildVersionArray(versionObejct);

      if (versionArray && versionArray.length > 0) {
        payload.versionDescriptions = versionArray;
      }

      if (this.settings && this.settings['_id']) {
        this.helperService.updateSettings(this.settings['_id'], payload).subscribe((r) => {
          if (r) {
            NotifyAppComponent.displayToast('success', 'Success!', 'Settings are updated');
            Settings.global['lookupHash'] = r['lookupHash'];
            Settings.global['translateHash'] = r['translateHash'];
            Settings.global['documentTypeHash'] = r['documentTypeHash'];
            Settings.global['suburbHash'] = r['suburbHash'];

            let context = Settings.global['context'] || 'default';
            let translateHash = Settings.global['translateHash'] || '297749565899';
            if (context && translateHash) {
              this.translate.use(context + '/' + translateHash);
            } else if (context) {
              this.translate.use(context + '/297749565899');
            } else {
              this.translate.use('default');
            }

            if (this.cleanMessageGroupLocalData == true) {
              this.helperService.cleanMessageGroupLocalData().subscribe((r) => {
                this.cleanMessageGroupLocalData=false
                this.closeEvent();
              });
            } else {
              this.closeEvent();
            }
          }
        });
      }
    } else {
      this.closeEvent();
    }
  }

  buildVersionArray(v) {
    if (v) {
      console.log(v);
      if (this.updateLastVersion === true) {
        if (this.versionDescriptions.length === 0) {
          this.versionDescriptions.push(v);
        } else {
          this.versionDescriptions[this.versionDescriptions.length - 1] = v;
        }
      } else {
        this.versionDescriptions.push(v);
      }

      if (this.versionDescriptions.length > 100) {
        // Remove the first element
        this.versionDescriptions.shift();
      }

      return this.versionDescriptions;
    } else {
      return null;
    }
  }

  getResultMessageDisclaimer(e) {
    if (e) {
      this.versionDescription = e.message;
    } else {
      this.versionDescription = null;
    }
  }

  calculateVersion(type, defaultValue) {
    try {
      if (type && type != 'none' && type != 'manual' && this.settings[defaultValue]) {
        let _array = this.settings[defaultValue].split('.');

        let major = Number(_array[0]) || 0;
        let minor = Number(_array[1]) || 0;
        let bug = Number(_array[2]) || 0;

        if (type === 'major') {
          major = major + 1;
          minor = 0;
          bug = 0;
        } else if (type === 'minor') {
          minor = minor + 1;
          bug = 0;
        } else if (type === 'bug') {
          bug = bug + 1;
        }

        return major + '.' + minor + '.' + bug;
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  removeVersion(id) {
    if (id) {
      const confirmDialogParams = {
        data: new ConfirmDialog(
          'fas fa-info',
          ' ',
          '<p>Are you sure you want to remove this version?</p>',
          'No',
          'Yes'
        ),
      };
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, confirmDialogParams);
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          let index = this.versionDescriptions.findIndex((item) => {
            if (item && item.id == id) {
              return true;
            }
            return false;
          });

          if (index != -1) {
            this.versionDescriptions.splice(index, 1);

            this.versionDescriptions = JSON.parse(JSON.stringify(this.versionDescriptions));
          }

          ref.close();
        } else {
          ref.close();
        }
      });
    }
  }
  formatDate(d) {
    if (d) {
      return moment(d, 'YYYY-MM-DD HH:mm:ss').toDate();
    }

    return null;
  }

  closeEvent() {
    this.close.emit();
  }
}
