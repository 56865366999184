import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { HelperService } from '../shared/helper.service';
import { TemplateReviewComponent } from '../template-review/template-review.component';

@Component({
  selector: 'app-template-marketing-create-edit',
  templateUrl: './template-marketing-create-edit.component.html',
  styleUrls: ['./template-marketing-create-edit.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class TemplateMarketingCreateEditComponent implements OnInit {
  @ViewChild('wizard', { static: true }) wizard: WizardComponent;

  isPromoterOrAdmin = false;

  pinchZoomProperties = [
    {
      wheel: true,
      autoHeight: true,
    },
  ];

  twitterFileID;
  facebookFileID;
  instagramFileID;
  linkedInFileID;
  logoFileID;

  twitterImage;
  facebookImage;
  instagramImage;
  linkedInImage;
  logoFileImage;

  twitterImageLoading = false;
  facebookImageLoading = false;
  instagramImageLoading = false;
  linkedInImageLoading = false;
  logoFileImageLoading = false;

  settings = Settings.global;
  @Input()
  templateID;

  @Input()
  templateRawID;

  template: any = {};

  utils = new UtilsClass();

  postURLLength = 0;

  constructor(
    private utilService: UtilsService,
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      this.activeRoute.params.subscribe((params) => {
        if (params['templateRawID']) {
          this.templateRawID = params['templateRawID'];
        }

        this.setup();
      });
    });
  }

  setup() {
    if (this.templateRawID) {
      this.helperService.getCreateTemplateOveride(this.templateRawID).subscribe((res) => {
        if (res) {
          this.fillData(res);
        }

        this.getTwitterImage();
        this.getFacebookImage();
        this.getLinkedinImage();
        this.getLogoImage();
        this.getInstagramImage();
      });
    }
  }

  fillData(res) {
    if (res) {
      if (res['ID']) {
        this.templateID = res['ID'];
      }
      if (res['Label']) {
        this.template.name = res['Label'];
      }
      if (res['Tag']) {
        this.template.tag = res['Tag'];
      }

      if (res['Subject']) {
        this.template.subject = res['Subject'];
      }

      if (res['LandingPageURL']) {
        this.template.landingPageURL = res['LandingPageURL'];
      }

      if (res['LandingPageCode']) {
        this.template.landingPageCode = res['LandingPageCode'];
      } else if (res['Tag']) {
        this.template.landingPageCode = this.utils.getTemplateCode(res['Tag']);
      }

      if (res['Facebook_Post']) {
        this.template.facebookPost = res['Facebook_Post'];
      }

      if (res['Twitter_Post']) {
        this.template.twitterPost = res['Twitter_Post'];
      }

      if (res['LinkedIn_Post']) {
        this.template.linkedInPost = res['LinkedIn_Post'];
      }

      if (res['Instagram_Post']) {
        this.template.instagramPost = res['Instagram_Post'];
      }
    }
  }

  download(url, name) {
    const link = document.createElement('a');
    link.download = name;
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  viewTemplate() {
    if (this.template && this.template.ID) {
      const ref = RootAppComponent.dialog.open(TemplateReviewComponent, {
        data: {
          templateID: this.template.ID,
          isPrint: false,
          isProceed: false,
          isDownload: true,
          proceedLabel: null,
        },
        width: '700px',
      });
      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
    }
  }

  onCompleteLogo(fileID) {
    if (fileID && fileID[0]) {
      this.logoFileID = fileID[0];
    }
  }

  onCompleteFacebook(fileID) {
    if (fileID && fileID[0]) {
      this.facebookFileID = fileID[0];

      const payload = {
        fileID: this.facebookFileID,
      };

      this.helperService.editTemplateOverideFacebook(this.templateID, payload).subscribe((res) => {
        if (res) {
          this.facebookImage = res;
        }
      });
    }
  }

  onCompleteInstagram(fileID) {
    if (fileID && fileID[0]) {
      this.instagramFileID = fileID[0];

      const payload = {
        fileID: this.instagramFileID,
      };

      this.helperService.editTemplateOverideInstagram(this.templateID, payload).subscribe((res) => {
        if (res) {
          this.instagramImage = res;
        }
      });
    }
  }

  onCompleteLinkedin(fileID) {
    if (fileID && fileID[0]) {
      this.linkedInFileID = fileID[0];
      const payload = {
        fileID: this.linkedInFileID,
      };

      this.helperService.editTemplateOverideLinkedin(this.templateID, payload).subscribe((res) => {
        if (res) {
          this.linkedInImage = res;
        }
      });
    }
  }

  onCompleteTwitter(fileID) {
    if (fileID && fileID[0]) {
      this.twitterFileID = fileID[0];
      const payload = {
        fileID: this.twitterFileID,
      };

      this.helperService.editTemplateOverideTwitter(this.templateID, payload).subscribe((res) => {
        if (res) {
          this.twitterImage = res;
        }
      });
    }
  }

  getLogoImage() {
    if (this.templateID) {
      this.logoFileImageLoading = true;

      this.helperService.getTemplateOverideLogo(this.templateID).subscribe((res) => {
        if (res) {
          this.logoFileImage = res;
        }

        this.logoFileImageLoading = false;
      });
    }
  }

  getFacebookImage() {
    if (this.templateID) {
      this.facebookImageLoading = true;

      this.helperService.getTemplateOverideFacebook(this.templateID).subscribe((res) => {
        if (res) {
          this.facebookImage = res;
        }

        this.facebookImageLoading = false;
      });
    }
  }

  getTwitterImage() {
    if (this.templateID) {
      this.twitterImageLoading = true;

      this.helperService.getTemplateOverideTwitter(this.templateID).subscribe((res) => {
        if (res) {
          this.twitterImage = res;
        }

        this.twitterImageLoading = false;
      });
    }
  }

  getLinkedinImage() {
    if (this.templateID) {
      this.linkedInImageLoading = true;

      this.helperService.getTemplateOverideLinkedin(this.templateID).subscribe((res) => {
        if (res) {
          this.linkedInImage = res;
        }

        this.linkedInImageLoading = false;
      });
    }
  }

  getInstagramImage() {
    if (this.templateID) {
      this.instagramImageLoading = true;

      this.helperService.getTemplateOverideInstagram(this.templateID).subscribe((res) => {
        if (res) {
          this.instagramImage = res;
        }

        this.instagramImageLoading = false;
      });
    }
  }

  finish() {
    if (this.templateID) {
      this.helperService.editTemplateOveride(this.templateID, this.template).subscribe((res) => {
        if (res) {
          NotifyAppComponent.displayToast('success', 'Successful operation', 'Template has been Updated');

          this.wizard.goToNextStep();
        }
      });
    }
  }
}
