import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Location } from '@angular/common';
import { ExistingPatientService } from '../shared/existing-patient.service';

import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';

@Component({
  selector: 'app-existing-patient-view',
  templateUrl: './existing-patient-view.component.html',
  styleUrls: ['./existing-patient-view.component.css'],
})
export class ExistingPatientViewComponent implements OnInit {
  profileLink;
  sessionType = 'guest';

  @Input() existingPatientID;
  isReferral = false;
  existingPatient = {};

  subjectArray = [];

  isPromoterOrAdmin = false;

  constructor(
    private location: Location,
    private authenticationService: AuthenticationService,
    private existingPatientService: ExistingPatientService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.existingPatientID = data;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        this.existingPatientService
          .getExistingPatientDetails(this.existingPatientID, {}, this.sessionType)
          .subscribe((res) => {
            this.existingPatient = res;
          });

        this.getPicture();
      });
    });
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  contact() {
    let data = {
      targetType: 'existingPerson',
      targetID: this.existingPatient['ID'],
      asGuest: false,
      asProfile: true,
      asPractice: true,
      subjectArray: this.subjectArray,
    };
    AuthenticationService.contactInputEvent.emit(data);
  }

  getPicture() {
    this.profileLink = null;

    if (this.existingPatient['ID']) {
      this.profileLink = this.existingPatientService.getProfilePicStreamLink(this.existingPatient['ID']);
    }
  }

  openCustomerEdit() {}
}
