/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./existing-patient-view-details.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../customer/customer-view/customer-view.component.ngfactory";
import * as i3 from "../../customer/customer-view/customer-view.component";
import * as i4 from "@angular/common";
import * as i5 from "../../customer/shared/customer.service";
import * as i6 from "@angular/router";
import * as i7 from "../../../core/authentication/shared/authentication.service";
import * as i8 from "../../customer-prospect/shared/customerProspect.service";
import * as i9 from "../../../shared/services/utils.service";
import * as i10 from "../../product/shared/product.service";
import * as i11 from "@angular/material/dialog";
import * as i12 from "../prospect-view/prospect-view.component.ngfactory";
import * as i13 from "../prospect-view/prospect-view.component";
import * as i14 from "../shared/existing-patient.service";
import * as i15 from "../existing-patient-invitation-view/existing-patient-invitation-view.component.ngfactory";
import * as i16 from "../existing-patient-invitation-view/existing-patient-invitation-view.component";
import * as i17 from "./existing-patient-view-details.component";
var styles_ExistingPatientViewDetailsComponent = [i0.styles];
var RenderType_ExistingPatientViewDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExistingPatientViewDetailsComponent, data: {} });
export { RenderType_ExistingPatientViewDetailsComponent as RenderType_ExistingPatientViewDetailsComponent };
function View_ExistingPatientViewDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-view", [["class", ""]], null, null, null, i2.View_CustomerViewComponent_0, i2.RenderType_CustomerViewComponent)), i1.ɵdid(1, 638976, null, 0, i3.CustomerViewComponent, [i4.Location, i5.CustomerService, i6.ActivatedRoute, i7.AuthenticationService, i8.CustomerProspectService, i6.Router, i9.UtilsService, i10.ProductService, i11.MatDialog, [2, i11.MAT_DIALOG_DATA]], { customerID: [0, "customerID"], customerObject: [1, "customerObject"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.existingPatientID; var currVal_1 = _co.existingPatient; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ExistingPatientViewDetailsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-prospect-view", [["class", ""]], null, null, null, i12.View_ProspectViewComponent_0, i12.RenderType_ProspectViewComponent)), i1.ɵdid(1, 638976, null, 0, i13.ProspectViewComponent, [i4.Location, i6.ActivatedRoute, i14.ExistingPatientService, i7.AuthenticationService, i8.CustomerProspectService, i6.Router, i9.UtilsService, i10.ProductService, [2, i11.MAT_DIALOG_DATA]], { prospectID: [0, "prospectID"], prospectObject: [1, "prospectObject"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.existingPatient["ID"]; var currVal_1 = _co.existingPatient; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ExistingPatientViewDetailsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-existing-patient-invitation-view", [["class", ""]], null, null, null, i15.View_ExistingPatientInvitationViewComponent_0, i15.RenderType_ExistingPatientInvitationViewComponent)), i1.ɵdid(1, 114688, null, 0, i16.ExistingPatientInvitationViewComponent, [i4.Location, i6.ActivatedRoute, i14.ExistingPatientService, [2, i11.MAT_DIALOG_DATA]], { invitationID: [0, "invitationID"], invitedPatientObject: [1, "invitedPatientObject"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.existingPatient["ID"]; var currVal_1 = _co.existingPatient; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ExistingPatientViewDetailsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "p", [["class", "full-width small"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Invitations: "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "badge"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.existingPatient["asInvitation"]; _ck(_v, 3, 0, currVal_0); }); }
function View_ExistingPatientViewDetailsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "p", [["class", "full-width small"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Prospect records: "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "badge"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.existingPatient["asProspect"]; _ck(_v, 3, 0, currVal_0); }); }
function View_ExistingPatientViewDetailsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "p", [["class", "full-width small"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Customer records: "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "badge"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.existingPatient["asCustomer"]; _ck(_v, 3, 0, currVal_0); }); }
function View_ExistingPatientViewDetailsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "clearfix row padded"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "well flex reducedPadding"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExistingPatientViewDetailsComponent_5)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExistingPatientViewDetailsComponent_6)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExistingPatientViewDetailsComponent_7)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.existingPatient["asInvitation"]; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.existingPatient["asProspect"]; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.existingPatient["asCustomer"]; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_ExistingPatientViewDetailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "white-background clearfix full-width"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExistingPatientViewDetailsComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExistingPatientViewDetailsComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExistingPatientViewDetailsComponent_3)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExistingPatientViewDetailsComponent_4)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.existingPatient && _co.existingPatient["ID"]) && (_co.existingPatient["TableName"] == "Customer")); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.existingPatient && _co.existingPatient["ID"]) && (_co.existingPatient["TableName"] == "Prospect")); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.existingPatient && _co.existingPatient["ID"]) && (_co.existingPatient["TableName"] == "Invitation")); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.isPromoterOrAdmin == true); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_ExistingPatientViewDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-existing-patient-view-details", [], null, null, null, View_ExistingPatientViewDetailsComponent_0, RenderType_ExistingPatientViewDetailsComponent)), i1.ɵdid(1, 114688, null, 0, i17.ExistingPatientViewDetailsComponent, [i4.Location, i7.AuthenticationService, i14.ExistingPatientService, [2, i11.MAT_DIALOG_DATA]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExistingPatientViewDetailsComponentNgFactory = i1.ɵccf("app-existing-patient-view-details", i17.ExistingPatientViewDetailsComponent, View_ExistingPatientViewDetailsComponent_Host_0, { existingPatientID: "existingPatientID" }, {}, []);
export { ExistingPatientViewDetailsComponentNgFactory as ExistingPatientViewDetailsComponentNgFactory };
