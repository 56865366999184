import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'WithoutArrayMultipleValue',
})
export class WithoutArrayMultipleValue implements PipeTransform {
  constructor() {}

  transform(expression: any, format: any, value: any) {
    try {
      let result = expression;

      if (
        expression &&
        format &&
        Array.isArray(expression) &&
        typeof format == 'string' &&
        Array.isArray(value) &&
        value.length > 0
      ) {
        result = _.reject(expression, (item) => {
          if (item) {
            if (format == '*') {
              if (value.indexOf(item) != -1) {
                return true;
              }
            } else {
              if (value.indexOf(item[format]) != -1) {
                return true;
              }
            }
          }

          return false;
        });

        return result;
      } else if (
        expression &&
        format &&
        Array.isArray(expression) &&
        typeof format == 'string' &&
        typeof value == 'string'
      ) {
        result = _.reject(expression, (item) => {
          if (item) {
            if (format == '*') {
              if (item == value) {
                return true;
              }
            } else {
              if (item[format] == value) {
                return true;
              }
            }
          }

          return false;
        });

        return result;
      } else {
        return expression;
      }
    } catch (e) {
      return expression;
    }
  }
}
