import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MerchantService } from '../shared/merchant.service';

@Component({
  selector: 'app-merchant-view-picture',
  templateUrl: './merchant-view-picture.component.html',
  styleUrls: ['./merchant-view-picture.component.css'],
})
export class MerchantViewPictureComponent implements OnInit {
  @Input() merchantID;

  @Output() close = new EventEmitter();

  @Input() hideZoomButton = false;

  profileLink;

  constructor(
    private merchantService: MerchantService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.merchantID = data;
    }
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.merchantID && changes.merchantID.previousValue != changes.merchantID.currentValue) {
      if (this.merchantID) {
        this.getPicture();
      } else {
        this.profileLink = null;
      }
    }
  }

  getPicture() {
    this.profileLink = null;

    if (this.merchantID) {
      this.profileLink = this.merchantService.getPracticePicStreamLink(this.merchantID);
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
}
