import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { DrugsViewComponent } from '../drugs-view/drugs-view.component';
import { DrugsService } from '../shared/drugs.service';

@Component({
  selector: 'app-medication-view',
  templateUrl: './medication-view.component.html',
  styleUrls: ['./medication-view.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class MedicationViewComponent implements OnInit {
  @Input()
  medicationID;

  @Input()
  medication;

  sessionType;
  @Output()
  close = new EventEmitter();

  title = 'View medication';
  description = '';
  isModal = true;

  constructor(
    private authenticationService: AuthenticationService,
    private drugsService: DrugsService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.medicationID) {
        this.medicationID = data.medicationID;
      } else if (data.medication) {
        this.medication = data.medication;
      }

      if (data.title) {
        this.title = data.title;
      }
      if (data.description) {
        this.description = data.description;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      if (this.medicationID) {
        const p = {};
        this.drugsService.getMedicationDetails(this.medicationID, p, this.sessionType).subscribe((res) => {
          this.medication = res;
        });
      }
    });
  }

  closeModal() {
    this.close.emit(true);
  }

  viewDrug(id) {
    const ref = RootAppComponent.dialog.open(DrugsViewComponent, {
      data: {
        drugID: id,
      },

      width: '800px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }
}
