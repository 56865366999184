import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TreatmentService } from '../shared/treatment.service';

@Component({
  selector: 'app-product-view-catalogue',
  templateUrl: './treatment-product-view-catalogue.component.html',
  styleUrls: ['./treatment-product-view-catalogue.component.css'],
})
export class TreatmentProductViewCatalogueComponent implements OnInit {
  @Input() productID;
  @Input()
  LastModified = null;
  @Input() hideZoomButton = false;
  @Output() close = new EventEmitter();

  @Output() clickImage = new EventEmitter();

  profileLink;

  constructor(
    private TreatmentService: TreatmentService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.productID = data;
    }
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.productID && changes.productID.previousValue != changes.productID.currentValue) {
      if (this.productID) {
        this.getPicture();
      } else {
        this.profileLink = null;
      }
    }
  }

  getPicture() {
    this.profileLink = null;

    if (this.productID) {
      this.profileLink = this.TreatmentService.getProductCatalogueStreamLink(this.productID, this.LastModified);
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  showFullSizeEvent(e) {
    this.clickImage.emit(e);
  }
}
