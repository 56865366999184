<div class="rel full-width">
  <div class="full-width flex container-modal">
    <div class="full-width">
      <div class="context full-width text-center">
        <div class="full-width text-center">
          <div class="image-thumbnail-sub-plan-congratulation full-width">
            <img
              src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/character/commence-application-dentist.png"
            />
          </div>
        </div>

        <h1>Welcome!</h1>
        <div class="context-description">
          <p class="text-center">You will be redirected in a moment.</p>
        </div>
      </div>

      <div
        class="area"
        [attr.style]="
          'background:linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
        "
        [attr.style]="
          'background:-moz-linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
        "
        [attr.style]="
          'background:-webkit-linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important'
            | safeStyle
        "
      >
        <ul class="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </div>
  </div>
</div>
