import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { HeaderService } from '../../../shared/services/header.service';
import { SideMenuService } from '../../../shared/services/side-menu.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { SubAccountStatisticalReportComponent } from '../../performance/sub-account-statistical-report/sub-account-statistical-report.component';
import { MarketingNewCampaignModalComponent } from '../marketing-new-campaign-modal/marketing-new-campaign-modal.component';
import { MarketingService } from '../shared/marketing.service';
import { MarketingUtilClass } from '../shared/marketingUtil';

@Component({
  selector: 'app-marketing-over-view',
  templateUrl: './marketing-over-view.component.html',
  styleUrls: ['./marketing-over-view.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-50px)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-50px)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
    trigger('labelAnimation', [
      transition('void => *', [
        query('p', style({ opacity: 0 }), { optional: true }),
        query(
          'p',
          stagger('100ms', [
            animate(
              '0.15s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateX(-30px)', offset: 0 }),
                style({ opacity: 1, transform: 'translateX(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MarketingOverViewComponent implements OnInit {
  statsLoaded = false;

  marketingUtil = new MarketingUtilClass();

  isPromoterOrAdmin = false;

  merchants = [];
  selectStatus;
  selectedStatus = 'CMPL';
  merchantID;
  dateFrom = null;
  dateTo = null;

  emailReputation = 'none';
  emailReputationColor;
  subAccountObj;

  _merchantID = null;
  _dateFrom = null;
  _dateTo = null;

  showCampaignViewDetais = false;
  campaignID = null;

  statsStatus = [];
  statsGlobal = [];
  // chartColors = Settings.global['chartColors'];
  chartColors = ['#1b8bdd', '#ff9800', '#4caf50', '#f44336', '#a43604'];

  customColorScheme = [
    {
      name: 'Pending',
      value: '#1b8bdd',
    },
    {
      name: 'Draft',
      value: '#ff9800',
    },
    {
      name: 'Complete',
      value: '#4caf50',
    },
    {
      name: 'Cancelled',
      value: '#f44336',
    },
    {
      name: 'Expired',
      value: '#a43604',
    },
  ];

  isDisplayMerchant = true;

  hideGoBackBtn = false;

  searchApplied = false;

  sendNull = true;
  selectedRange = 'days180';

  constructor(
    private authenticationService: AuthenticationService,
    private marketingService: MarketingService,
    private utilService: UtilsService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    SideMenuService.goBack.subscribe((res) => {
      if (res == true) {
        this.cancelCampaignViewDetais();
      }
    });
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
      // this.getStats();

      if (this.isPromoterOrAdmin == true) {
        const _mID = JSON.parse(localStorage.getItem('selectedMerchant'));
        if (_mID) {
          this.merchantID = _mID;
          // this.isDisplayMerchant = false;
          // this.getStats();
        } else {
          // this.getStats();
        }

        HeaderService.getPromoterViewAsObject().subscribe((res) => {
          if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'promoter') {
            this.merchantID = null;
            this.isDisplayMerchant = true;
            // this.getStats();
          } else if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'merchant') {
            this.merchantID = res['merchantID'];
            this.isDisplayMerchant = false;
            // this.getStats();
          }
        });
      } else {
        // this.getStats();
      }
    });

    this.utilService.getSubAccountStatisticalReport().subscribe((res) => {
      if (res && res.length > 0 && res[0].Reputation) {
        this.subAccountObj = res[0];
        const _reputationValue = Number(res[0].Reputation);
        if (_reputationValue >= 90) {
          this.emailReputation = 'Excellent';
          this.emailReputationColor = '4caf50';
        } else if (_reputationValue >= 75 && _reputationValue < 90) {
          this.emailReputation = 'Good';
          this.emailReputationColor = 'cddc39';
        } else if (_reputationValue >= 50 && _reputationValue < 75) {
          this.emailReputation = 'Fair';
          this.emailReputationColor = 'ff9800';
        } else {
          this.emailReputation = 'Poor';
          this.emailReputationColor = 'f44336';
        }
      } else {
        this.subAccountObj = null;
        this.emailReputation = 'none';
        this.emailReputationColor = 'e3e3e3';
      }
    });
  }

  viewSubAccountStatisticalData() {
    if (this.subAccountObj) {
      const ref = RootAppComponent.dialog.open(SubAccountStatisticalReportComponent, {
        data: this.subAccountObj,
        width: '950px',
      });

      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  getStats() {
    let payload;

    if (this.isPromoterOrAdmin == true) {
      payload = {
        selectStatus: true,
        dateTo: this.dateTo,
        dateFrom: this.dateFrom,
      };

      if (this.merchantID && this.merchantID != 'none') {
        payload.merchantID = this.merchantID;
      }
    } else {
      payload = {
        selectStatus: true,
        dateTo: this.dateTo,
        dateFrom: this.dateFrom,
      };
    }
    this.statsLoaded = false;
    this.marketingService.getStatistics(payload, this.isPromoterOrAdmin).subscribe((res) => {
      this.statsLoaded = true;
      if (res) {
        if (res['global']) {
          this.statsGlobal = res['global'];
        }

        if (res['status']) {
          this.statsStatus = res['status'];
        }
      }
    });
  }

  triggerNewCampaign() {
    const ref = RootAppComponent.dialog.open(MarketingNewCampaignModalComponent, {
      width: '750px',
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  getMerchant(d) {
    this._merchantID = d;
    this.changeDetectorRef.detectChanges();
  }

  getDateFrom(d) {
    this._dateFrom = d;
  }

  getDateTo(d) {
    this._dateTo = d;
  }

  applySearch(result) {
    if (result.merchantID) {
      this.merchantID = result.merchantID;
    }
    this.dateFrom = result.dateFrom;
    this.dateTo = result.dateTo;

    this.searchApplied = true;

    this.getStats();
    this.changeDetectorRef.detectChanges();
  }

  displayMarketing(id) {
    if (id) {
      this.selectedStatus = id;
    }
  }

  toNumber(n) {
    if (n) {
      return Number(n);
    } else {
      return 0;
    }
  }

  getID(e) {
    this.showCampaignViewDetais = true;
    this.campaignID = e;
  }

  cancelCampaignViewDetais() {
    this.showCampaignViewDetais = false;
    this.campaignID = null;
  }

  hideGobackBtnEvent(e) {
    this.hideGoBackBtn = e;
  }

  getColorByName(d) {
    const index = this.customColorScheme.findIndex((e) => {
      if (e && e.name && e.name == d) {
        return true;
      }
    });

    if (index != -1) {
      return this.customColorScheme[index]['value'];
    } else {
      return '#ff9800';
    }
  }
}
