import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DocumentUrlViewComponent } from '../../../shared/components/document-url-view/document-url-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
@Component({
  selector: 'app-getting-started-practice-manager',
  templateUrl: './getting-started-practice-manager.component.html',
  styleUrls: ['./getting-started-practice-manager.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class GettingStartedPracticeManagerComponent implements OnInit {
  public player;
  public page;
  price = 22;
  practiceName = 'Smile Right';
  practiceAddress = 'training@smileright.com.au';
  practiceNumber = '1300 793 983';

  standAlone = true;

  constructor(public dialog: MatDialog) {}

  public ngOnInit() {
    this.page = 1;
    window.scrollTo(0, 0);

    if (document && document.querySelector('mat-sidenav-content')) {
      document.querySelector('mat-sidenav-content').scrollTo(0, 0);
    }
    // if (!this.merchant) {

    //   this.merchant = {
    //     TradingAs: "Merchant"
    //   };

    //   this.minAmount = 5

    // }
  }

  private pageS(value) {
    this.page = value;
    window.scrollTo(0, 0);

    if (document && document.querySelector('mat-sidenav-content')) {
      document.querySelector('mat-sidenav-content').scrollTo(0, 0);
    }
  }

  public openNewTab() {
    window.open(
      'https://email-graphics-smileright.s3.ap-southeast-2.amazonaws.com/pdfs/Smile_Right_User+Guide_Online.pdf',
      '_blank'
    );
  }
  public openNewTab1() {
    window.open('https://app.smileright.com.au/wiki', '_blank');
  }
  public openNewTab2() {
    window.open('https://get.smileright.com.au/smile-right-academy', '_blank');
  }

  viewVideoInfo(videoTitle, docUrl) {
    const ref = RootAppComponent.dialog.open(DocumentUrlViewComponent, {
      data: {
        title: videoTitle,
        description: '',
        documentUrl: docUrl,
      },
      width: '800px',
      panelClass: 'noCard',
    });
    ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
  }
}
