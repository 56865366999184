import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { ContractService } from '../../contract/shared/contract.service';
import { CustomerService } from '../../customer/shared/customer.service';

@Component({
  selector: 'app-quotation-detail-view',
  templateUrl: './quotation-detail-view.component.html',
  styleUrls: ['./quotation-detail-view.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: '*' }))),
    ]),
  ],
})
export class QuotationDetailViewComponent implements OnInit {
  @Input()
  quotationID;

  @Input()
  setApplicationsTab = 0;

  quotation;
  patient;

  loading;

  isAdminOrPromoter = false;

  frequency;

  selectedFrequency;
  selectedDuration;
  enableSubmitBtn = false;
  paymentList;
  totalRepayment;
  totalInterest;
  isParamter = false;
  quotationProductSubType;

  paymentsTab = false;
  complianceTasksTab = false;
  actionLogTab = false;
  documentsTab = false;
  sessionType = 'guest';

  constructor(
    private contractService: ContractService,
    private authenticationService: AuthenticationService,
    private customerService: CustomerService,
    private lookupService: LookupService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['quotationID']) {
        this.quotationID = params['quotationID'];
        this.isParamter = true;
      }
      this.authenticationService.getSessionType().subscribe((sessionType) => {
        if (sessionType) {
          this.sessionType = sessionType;
        }
        this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
          this.isAdminOrPromoter = res;

          this.contractService
            .fetchContractDetails$(this.quotationID, null, this.isAdminOrPromoter)
            .subscribe((res) => {
              this.quotation = res;

              this.getQuotationPayment();
              this.selectedFrequency = this.quotation['RepaymentFrequency.Code'];
              this.selectedDuration = this.quotation['LoanInstalments'];
              this.customerService
                .getCustomerDetails(this.quotation['Customer_key'], {}, this.sessionType)
                .subscribe((res) => {
                  this.patient = res;
                });

              this.lookupService.getLookup('CodeLookUp', 'Frequency').subscribe((res) => {
                this.frequency = res;
              });
            });
        });
      });
    });
  }

  subTabChange() {
    console.log(this.setApplicationsTab);
  }

  tabChange(index) {
    if (index == 1) {
      this.paymentsTab = true;
    } else if (index == 2) {
      this.complianceTasksTab = true;
    } else if (index == 3) {
      this.actionLogTab = true;
    } else if (index == 4) {
      this.documentsTab = true;
    }
  }

  toNumber(value) {
    return Number(value);
  }

  changeDuration(e) {
    // console.log(e)
  }

  changeFrequency(e) {
    this.selectedFrequency = e;
    this.enableSubmitBtn = true;
  }

  submit() {
    const payload = {
      id: this.quotation['ID'],
      frequencyCode: this.selectedFrequency,
    };

    this.contractService.editContract(this.quotation['ID'], payload, this.isAdminOrPromoter).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast(
          'success',
          'Successfully Operation',
          'You have successfully modified the Repayment Frequency'
        );

        this.quotation = res;
        this.selectedFrequency = this.quotation['RepaymentFrequency.Code'];
        this.selectedDuration = this.quotation['LoanInstalments'];

        this.enableSubmitBtn = false;
      }
    });
  }

  getQuotationPayment() {
    // Format the payment data, combine all data into one single list
    this.contractService.getQuotationPayment(this.quotation['ID']).subscribe((res) => {
      // console.log(res);
      this.quotationProductSubType = res['Payment.CalculationData'][0]['ProductSubType'];

      const repaymentAmounts = res['Payment.CalculationData'][0]['Repayments'];
      this.totalRepayment = repaymentAmounts['Amounts'].reduce((a, b) => a + b);
      const paymentListA = [];
      const paymentListB = [];

      if (res['Payment.CalculationData'][0]['ProductSubType'] != 'Payment Plan') {
        const establishmentFees = res['Payment.CalculationData'][0]['EstablishmentFee'];
        const accountKeepingFees = res['Payment.CalculationData'][0]['Fees']['Customer']['ACK_FE']['Schedule'];

        this.totalInterest = establishmentFees['InterestAmounts'].reduce((a, b) => a + b);

        for (let i = 0; i < repaymentAmounts['Dates'].length; i++) {
          if (repaymentAmounts['Dates'][i] === establishmentFees['Dates'][i]) {
            paymentListA.push({
              dates: repaymentAmounts['Dates'][i],
              repayments: repaymentAmounts['Amounts'][i],
              fee: establishmentFees['Amounts'][i] - establishmentFees['InterestAmounts'][i],
              interest: establishmentFees['InterestAmounts'][i],
              offsetCode: repaymentAmounts['Relative']['Offset_Code'][i],
              offsetHowMany: repaymentAmounts['Relative']['Offset_HowMany'][i],
            });
          }
        }

        for (let ii = 0; ii < accountKeepingFees['Dates'].length; ii++) {
          paymentListB.push({
            dates: accountKeepingFees['Dates'][ii],
            akf: accountKeepingFees['Amounts'][ii],
            offsetHowMany: ii + 1,
          });
        }

        this.paymentList = paymentListA.concat(paymentListB);

        this.paymentList.sort((a, b) => {
          return a.dates.localeCompare(b.dates);
        });

        if (this.quotation['RepaymentFrequency.Code'] == '4') {
          const _paymentlist = this.paymentList;

          const _p = _paymentlist.reduce((acc, elem) => {
            const _filter = acc.filter((item) => item.dates == elem.dates)[0];
            if (_filter) {
              _filter.akf = elem['akf'];
            } else {
              acc.push(elem);
            }
            return acc;
          }, []);
          this.paymentList = _p;
        }
      } else {
        for (let i = 0; i < repaymentAmounts['Dates'].length; i++) {
          paymentListA.push({
            dates: repaymentAmounts['Dates'][i],
            repayments: repaymentAmounts['Amounts'][i],
            offsetCode: repaymentAmounts['Relative']['Offset_Code'][i],
            offsetHowMany: repaymentAmounts['Relative']['Offset_HowMany'][i],
          });
        }

        this.paymentList = paymentListA;
      }
    });
  }

  getOffsetCodeLabel(code) {
    if (code == '1') {
      return 'Week';
    } else if (code == '2') {
      return 'Fortnight';
    } else if (code == '4') {
      return 'Month';
    }
  }

  openContractDetail(ID) {
    // console.log(ID)
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['contract-details', ID],
        },
      },
    ]);
  }
}
