<mat-card
  class="rel supplier-card brandView clearfix mb"
  *ngIf="field && field.ID && isPromoterOrAdmin == true"
  @ngIfAnimation
>
  <div class="card-header primary-gradient-img clearfix inModal rel">
    <div class="row clearfix content">
      <button class="btn-close btn-clear mat-button white" (click)="closeModal()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m">
          {{ field.FieldName }}
          <span class="subLabel" *ngIf="field['TableName']">{{ field['TableName'] }}</span>
        </h2>
      </div>
    </div>
  </div>
  <mat-dialog-content class="stacked-card-view indOvf">
    <div class="row clearfix mb">
      <h3 class="sr-title rm-m sm-h3" *ngIf="field.Comments">Comment</h3>
      <p class="rm-m" *ngIf="field.Comments" [innerHTML]="field.Comments | safeHtml"></p>
    </div>

    <div class="full-width clearfix flex">
      <div class="full-width mr" *ngIf="field.QueryComparitors">
        <h3 class="sr-title rm-m sm-h3">Comparitors</h3>
        <p class="rm-m">
          {{ field['QueryComparitors'] }}
        </p>
      </div>

      <div class="full-width mr" *ngIf="field.LookupInfo" (click)="copyLookup(field.LookupInfo)">
        <h3 class="sr-title rm-m sm-h3">
          Lookup info
          <mat-icon
            class="ml open-lookup"
            (click)="$event.stopPropagation(); openLookup(field.LookupInfo)"
            matTooltip="Open lookup list"
          >
            launch
          </mat-icon>
        </h3>
        <p class="rm-m" [innerHTML]="field.LookupInfo | safeHtml"></p>
      </div>

      <div class="full-width mr" *ngIf="field.Type">
        <h3 class="sr-title rm-m sm-h3">Type</h3>
        <p class="rm-m">
          {{ getType(field) }}
        </p>
      </div>
    </div>

    <hr class="mt"/>
    <div class="flex clearfix full-width mt">
      <p class="clearfix small rm-m" *ngIf="field['IsMandatory'] == '1'">
        <span class="chip-enable">Mandatory</span>
      </p>
      <p class="clearfix small rm-m" *ngIf="field['IsMandatory'] == '0'">
        <span class="chip-disable">Mandatory</span>
      </p>

      <p class="clearfix small rm-m" *ngIf="field['CanSetOnce'] == '1'">
        <span class="chip-enable">Set Once</span>
      </p>
      <p class="clearfix small rm-m" *ngIf="field['CanSetOnce'] == '0'">
        <span class="chip-disable">Set Once</span>
      </p>

      <p class="clearfix small rm-m" *ngIf="field['CanGet'] == '1'">
        <span class="chip-enable">Can Get</span>
      </p>
      <p class="clearfix small rm-m" *ngIf="field['CanGet'] == '0'">
        <span class="chip-disable">Can Get</span>
      </p>

      <p class="clearfix small rm-m" *ngIf="field['CanModify'] == '1'">
        <span class="chip-enable">Can Modify</span>
      </p>
      <p class="clearfix small rm-m" *ngIf="field['CanModify'] == '0'">
        <span class="chip-disable">Can Modify</span>
      </p>

      <p class="clearfix small rm-m" *ngIf="field['CanQuery'] == '1'">
        <span class="chip-enable">Can Query</span>
      </p>
      <p class="clearfix small rm-m" *ngIf="field['CanQuery'] == '0'">
        <span class="chip-disable">Can Query</span>
      </p>

      <p class="clearfix small rm-m" *ngIf="field['CanSort'] == '1'">
        <span class="chip-enable">Can Sort</span>
      </p>
      <p class="clearfix small rm-m" *ngIf="field['CanSort'] == '0'">
        <span class="chip-disable">Can Sort</span>
      </p>

      <p class="clearfix small rm-m" *ngIf="field['CanQueryWithIN'] == '1'">
        <span class="chip-enable">IN Query</span>
      </p>
      <p class="clearfix small rm-m" *ngIf="field['CanQueryWithIN'] == '0'">
        <span class="chip-disable">IN Query</span>
      </p>
    </div>
  </mat-dialog-content>
</mat-card>
