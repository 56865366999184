<div class="row clearfix flex" *ngIf="files.length > 0">
  <div class="two-thrd-width flex">
    <p>
      {{ title }} <strong>({{ files.length }})</strong>
    </p>
  </div>
  <div class="thrd-width flex">
    <button class="btn-small" (click)="openDialog()" mat-button>
      <mat-icon>visibility</mat-icon>
    </button>
  </div>
</div>
