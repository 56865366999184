import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { DocumentUrlViewComponent } from '../../../shared/components/document-url-view/document-url-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { MerchantService } from '../../merchant/shared/merchant.service';

@Component({
  selector: 'app-medical-history-setting',
  templateUrl: './medical-history-setting.component.html',
  styleUrls: ['./medical-history-setting.component.css'],
})
export class MedicalHistorySettingComponent implements OnInit {
  @Input()
  merchantID;
  enabled;
  enabled2;
  isPromoterOrAdmin = false;
  appointmentCancellingHours;
  hideFilter = false;

  isGlobal = true;
  @Input()
  title = 'Health History Messages';

  purposeCodes = 'MerMHInv';

  purposeCode = 'MerMHInv';

  merchant;
  sessionType = 'merchant';

  healthHistoryActive = false;
  healthHistoryAutoUpdate = false;
  healthHistoryUpdateDuration;
  isFirstLoad = false;
  healthHistoryActiveReal = false;

  constructor(private merchantService: MerchantService, private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        if (this.isPromoterOrAdmin == true && this.merchantID) {
          this.merchantService
            .getMerchantDetails(
              this.merchantID,
              {
                fields:
                  'ID,HealthHistoryEnabled.Inherited,HealthHistoryEnabled.Overridden,HealthHistoryEnabled.Effective,Health_History_Update_Duration,Health_History_Auto_Update',
              },
              this.sessionType
            )
            .subscribe((merchant) => {
              if (merchant) {
                this.merchant = merchant;
                this.setup();
              }
            });
        } else {
          this.authenticationService.getCurrentPractice().subscribe((merchant) => {
            if (merchant) {
              this.merchant = merchant;
              this.setup();
            }
          });
        }
      });
    });
  }

  setup() {
    if (this.merchant) {
      if (this.merchant['HealthHistoryEnabled.Overridden'] == 'Y') {
        this.healthHistoryActive = true;
        this.healthHistoryActiveReal = true;
      } else {
        this.healthHistoryActive = false;
        this.healthHistoryActiveReal = false;
      }

      if (this.merchant['Health_History_Update_Duration']) {
        this.healthHistoryUpdateDuration = Number(this.merchant['Health_History_Update_Duration']);
      }

      if (this.merchant['Health_History_Auto_Update'] == '1') {
        this.healthHistoryAutoUpdate = true;
      } else {
        this.healthHistoryAutoUpdate = false;
      }
    }
    this.isFirstLoad = true;

    AuthenticationService.healthHistoryAccess.emit(this.healthHistoryActiveReal);
  }

  viewVideoInfo(videoTitle, docUrl) {
    const ref = RootAppComponent.dialog.open(DocumentUrlViewComponent, {
      data: {
        title: videoTitle,
        description: '',
        documentUrl: docUrl,
      },
      width: '800px',
      panelClass: 'noCard',
    });
    const sub = ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
  }

  enableHH() {
    if (this.isPromoterOrAdmin == true && this.merchantID) {
      this.merchantService.editMerchant(this.merchantID, { healthHistoryActive: true }).subscribe((merchant) => {
        if (merchant) {
          this.merchant = merchant;
          this.setup();

          NotifyAppComponent.displayToast('success', 'The practice has been updated', 'Successfull update');
        }
      });
    } else {
      this.authenticationService.editCurrentPractice({ healthHistoryActive: true }).subscribe((merchant) => {
        if (merchant) {
          this.merchant = merchant;
          this.setup();

          NotifyAppComponent.displayToast('success', 'Your practice has been updated', 'Successful update');
        }
      });
    }
  }

  saveHH() {
    const payload = {
      healthHistoryActive: this.healthHistoryActive,
      healthHistoryUpdateDuration: this.healthHistoryUpdateDuration,
      healthHistoryAutoUpdate: this.healthHistoryAutoUpdate,
    };
    if (this.isPromoterOrAdmin == true && this.merchantID) {
      this.merchantService.editMerchant(this.merchantID, payload).subscribe((merchant) => {
        if (merchant) {
          this.merchant = merchant;
          this.setup();

          NotifyAppComponent.displayToast('success', 'The practice has been updated', 'Successful update');
        }
      });
    } else {
      this.authenticationService.editCurrentPractice(payload).subscribe((merchant) => {
        if (merchant) {
          this.merchant = merchant;
          this.setup();

          NotifyAppComponent.displayToast('success', 'Your practice has been updated', 'Successful update');
        }
      });
    }
  }
}
