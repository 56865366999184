import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { Settings } from '../../../shared/types/settings';
import { TreatmentService } from '../shared/treatment.service';
import { treatmentUtil } from '../shared/treatmentUtil';
import { TreatmentProductViewComponent } from '../treatment-product-view/treatment-product-view.component';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { MAT_DIALOG_DATA } from '@angular/material';
import { intersection } from 'lodash';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { TreatmentBrandViewComponent } from '../treatment-brand-view/treatment-brand-view.component';
import { TreatmentCategoryViewComponent } from '../treatment-category-view/treatment-category-view.component';
import { TreatmentTypeViewComponent } from '../treatment-type-view/treatment-type-view.component';

@Component({
  selector: 'app-treatment-informed-consent-view',
  templateUrl: './treatment-informed-consent-view.component.html',
  styleUrls: ['./treatment-informed-consent-view.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.2s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class TreatmentInformedConsentViewComponent implements OnInit {
  @Input()
  justify = 'left';

  @Input() productID;

  @Input() isMiniList = false;
  @Input() justSelected = false;

  @Input() merchantID;

  @Input() preSelectedDocuments;
  @Input() postSelectedDocuments;
  @Input() marketingSelectedDocuments;
  @Input() stockArtSelectedDocuments;

  @Input()
  DocumentlistViewType = 'thumbnails';
  @Input() isDownload = true;
  @Input() isRedirection = true;
  @Input() isPrint = true;

  @Input()
  isPreviewModal = false;

  @Input()
  displayPreDocuments = true;

  @Input()
  displayPostDocuments = true;

  @Input()
  displayMarketingDocuments = true;

  @Input()
  displayStockArtDocuments = true;

  treatmentUtil = new treatmentUtil();

  preAllDocuments = [];
  postAllDocuments = [];
  marketingAllDocuments = [];
  stockArtAllDocuments = [];

  preRequiredDocuments = [];
  postRequiredDocuments = [];
  marketingRequiredDocuments = [];
  stockArtRequiredDocuments = [];

  preDocuments = [];
  postDocuments = [];
  marketingDocuments = [];
  stockDocuments = [];

  product;

  isAdminOrPromoter = false;

  isModuleTreatmentGroupActive = Settings.global['isModuleTreatmentGroupActive'];

  itemCode;

  @Input() isModal = false;

  constructor(
    private authenticationService: AuthenticationService,
    private utilService: UtilsService,
    private treatmentService: TreatmentService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.productID) {
        this.productID = data.productID;
      }

      if (data.merchantID) {
        this.merchantID = data.merchantID;
      }

      if (data.preSelectedDocuments && data.preSelectedDocuments.length > 0) {
        this.preSelectedDocuments = data.preSelectedDocuments;
      }

      if (data.postSelectedDocuments && data.postSelectedDocuments.length > 0) {
        this.postSelectedDocuments = data.postSelectedDocuments;
      }

      if (data.marketingSelectedDocuments && data.marketingSelectedDocuments.length > 0) {
        this.marketingSelectedDocuments = data.marketingSelectedDocuments;
      }

      if (data.stockArtSelectedDocuments && data.stockArtSelectedDocuments.length > 0) {
        this.stockArtSelectedDocuments = data.stockArtSelectedDocuments;
      }

      if (data.isDownload != null) {
        this.isDownload = data.isDownload;
      }
      if (data.justSelected != null) {
        this.justSelected = data.justSelected;
      }

      if (data.isRedirection != null) {
        this.isRedirection = data.isRedirection;
      }

      if (data.isPrint != null) {
        this.isPrint = data.isPrint;
      }

      if (data.displayPreDocuments != null) {
        this.displayPreDocuments = data.displayPreDocuments;
      }

      if (data.displayPostDocuments != null) {
        this.displayPostDocuments = data.displayPostDocuments;
      }

      if (data.displayMarketingDocuments != null) {
        this.displayMarketingDocuments = data.displayMarketingDocuments;
      }

      if (data.displayStockArtDocuments != null) {
        this.displayStockArtDocuments = data.displayStockArtDocuments;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isAdminOrPromoter = res;

      if (this.isAdminOrPromoter == false) {
        this.isDownload = false;
        this.isRedirection = false;
        this.isPrint = false;
      }

      this.utilService.getCurrentAccess().subscribe((access) => {
        if (access) {
          this.isModuleTreatmentGroupActive = access['isModuleTreatmentGroupActive'];
        }

        if (this.isModuleTreatmentGroupActive == true) {
          this.setup();
        }
      });
    });
  }

  setup() {
    if (this.productID) {
      const payload = {
        merchantID: this.merchantID,
      };
      this.treatmentService
        .getTreatmentProductDetails(this.productID, payload, this.isAdminOrPromoter)
        .subscribe((res) => {
          if (res) {
            this.product = res;

            this.getDocuments();
          }
        });
    }
  }

  viewProduct(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(TreatmentProductViewComponent, {
        data: {
          productID: id,
          isGlobal: false,
          merchantID: this.merchantID,
        },

        width: '800px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewType(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(TreatmentTypeViewComponent, {
        data: {
          typeID: id,
          isGlobal: true,
        },

        width: '700px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewCategory(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(TreatmentCategoryViewComponent, {
        data: {
          categoryID: id,
        },

        width: '700px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewBrand(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(TreatmentBrandViewComponent, {
        data: {
          brandID: id,
          isGlobal: false,
          merchantID: this.merchantID,
        },

        width: '700px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  getDocuments() {
    this.marketingAllDocuments = [];
    this.postAllDocuments = [];
    this.preAllDocuments = [];
    this.stockArtAllDocuments = [];

    const types = [];
    const payload = {
      section: 0,
      documentTypes: 'SRVPRE,SRVPST,SRVMKT,SRVSTC',
      fields: 'Document.Type.Code,Mandatory.Effective,Document_key',
      includeOmmitted: false,
      merchantID: this.merchantID,
      productID: this.productID,
      includeInherited: true,
    };

    if (this.displayPreDocuments == true) {
      types.push('SRVPRE');
    }

    if (this.displayPostDocuments == true) {
      types.push('SRVPST');
    }

    if (this.displayMarketingDocuments == true) {
      types.push('SRVMKT');
    }

    if (this.displayStockArtDocuments == true) {
      types.push('SRVSTC');
    }

    if (types && types.length > 0) {
      this.treatmentService.getTreatmentDocumentList(payload, this.isAdminOrPromoter).subscribe((_res) => {
        const res = _res['data'];
        if (res && res.length > 0) {
          const _preDoc = [];
          const _postDoc = [];
          const _marketingDoc = [];
          const _stockArtDoc = [];

          for (let i = 0; i < res.length; i++) {
            if (
              res[i] &&
              res[i] &&
              res[i]['Document_key'] &&
              res[i]['Document.Type.Code'] &&
              res[i]['Document.Type.Code'] == 'SRVPST'
            ) {
              _postDoc.push(res[i]['Document_key']);

              if (res[i]['Mandatory.Effective'] === '1') {
                this.postRequiredDocuments.push(res[i]['Document_key']);
              }
            } else if (
              res[i] &&
              res[i]['Document_key'] &&
              res[i]['Document.Type.Code'] &&
              res[i]['Document.Type.Code'] == 'SRVPRE'
            ) {
              _preDoc.push(res[i]['Document_key']);

              if (res[i]['Mandatory.Effective'] === '1') {
                this.preRequiredDocuments.push(res[i]['Document_key']);
              }
            } else if (
              res[i] &&
              res[i]['Document_key'] &&
              res[i]['Document.Type.Code'] &&
              res[i]['Document.Type.Code'] == 'SRVMKT'
            ) {
              _marketingDoc.push(res[i]['Document_key']);

              if (res[i]['Mandatory.Effective'] === '1') {
                this.marketingRequiredDocuments.push(res[i]['Document_key']);
              }
            } else if (
              res[i] &&
              res[i]['Document_key'] &&
              res[i]['Document.Type.Code'] &&
              res[i]['Document.Type.Code'] == 'SRVSTC'
            ) {
              _stockArtDoc.push(res[i]['Document_key']);

              if (res[i]['Mandatory.Effective'] === '1') {
                this.stockArtRequiredDocuments.push(res[i]['Document_key']);
              }
            }
          }

          if (_preDoc && _preDoc.length > 0) {
            if (this.justSelected == true) {
              if (this.preSelectedDocuments && this.preSelectedDocuments.length > 0) {
                this.preAllDocuments = intersection(_preDoc, this.preSelectedDocuments);
              } else {
                this.preAllDocuments = [];
              }
            } else {
              this.preAllDocuments = _preDoc;
            }
          } else {
            this.preAllDocuments = [];
          }

          if (_postDoc && _postDoc.length > 0) {
            if (this.justSelected == true) {
              if (this.postSelectedDocuments && this.postSelectedDocuments.length > 0) {
                this.postAllDocuments = intersection(_postDoc, this.postSelectedDocuments);
              } else {
                this.postAllDocuments = [];
              }
            } else {
              this.postAllDocuments = _postDoc;
            }
          } else {
            this.postAllDocuments = [];
          }

          if (_marketingDoc && _marketingDoc.length > 0) {
            if (this.justSelected == true) {
              if (this.marketingSelectedDocuments && this.marketingSelectedDocuments.length > 0) {
                this.marketingAllDocuments = intersection(_marketingDoc, this.marketingSelectedDocuments);
              } else {
                this.marketingAllDocuments = [];
              }
            } else {
              this.marketingAllDocuments = _marketingDoc;
            }
          } else {
            this.marketingAllDocuments = [];
          }

          if (_stockArtDoc && _stockArtDoc.length > 0) {
            if (this.justSelected == true) {
              if (this.stockArtSelectedDocuments && this.stockArtSelectedDocuments.length > 0) {
                this.stockArtAllDocuments = intersection(_stockArtDoc, this.stockArtSelectedDocuments);
              } else {
                this.stockArtAllDocuments = [];
              }
            } else {
              this.stockArtAllDocuments = _stockArtDoc;
            }
          } else {
            this.stockArtAllDocuments = [];
          }

          // if (this.preAllDocuments.length == 0 && this.preAllDocuments.length == 0 && this.marketingAllDocuments.length == 0) {

          // }
        } else {
          this.marketingAllDocuments = [];
          this.postAllDocuments = [];
          this.preAllDocuments = [];
          this.stockArtAllDocuments = [];
        }
      });
    }
  }
}
