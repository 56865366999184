import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-plane-animation',
  templateUrl: './plane-animation.component.html',
  styleUrls: ['./plane-animation.component.css'],
})
export class PlaneAnimationComponent implements OnInit {
  @Input()
  flyAway = false;

  constructor() {}

  ngOnInit() {}
}
