import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'genderCode' })
export class GenderCodePipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}

  transform(value: string) {
    switch (value) {
      case 'M':
        return 'Male';
        break;

      case 'F':
        return 'Female';
        break;

      default:
        return 'Other';
        break;
    }
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
