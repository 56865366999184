import { Component, Input } from '@angular/core';

@Component({
  selector: 'ipv-list-label-print-compatible',
  templateUrl: './list-label-print-compatible.component.html',
  styleUrls: ['./list-label-print-compatible.component.css'],
})
export class ListLabelPrintCompatibleComponent {
  @Input() color = '#6fb662';
}
