<div class="rel text-center auth-modal">
  <div class="float-close" *ngIf="isModal == true">
    <!-- Modal dismiss button -->
    <button class="btn-close btn-clear mat-button" (click)="closeModal()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <mat-icon class="large-modal-icon" *ngIf="isModal == true" style="padding-top: 5px">verified_user</mat-icon>
  <h3 class="sr-title sm-h3 no-pad">Secure Code</h3>

  <p class="lead rm-mt rm-mb">Let's just verify your code.</p>
  <p class="">
    We've just sent you a SMS containing a six-digit code to verify your account. Please type this code into the field
    below.
  </p>

  <div class="clearfix row well">
    <!-- 2FA INPUT -->
    <mat-form-field class="full-width large-input">
      <mat-label>Secure Code</mat-label>
      <input
        id="auth-input"
        [(ngModel)]="code"
        (keyup.enter)="verfityCode()"
        matInput
        placeholder="Secure Code"
        name="code"
        maxlength="6"
      />
    </mat-form-field>

    <!-- Show counter -->
    <div class="resend-countdown text-center">
      <p class="small">
        Didn't recieve your code? We can resend your code in <span>{{ timeLeft }}</span> seconds.
      </p>
    </div>
    <!-- Hide button until counter at zero -->
    <div class="resend-button text-center" *ngIf="timeLeft == 0">
      <p class="small">Didn't recieve your code?</p>
      <button class="btn-small" (click)="countDown()" mat-raised-button>Send again</button>
    </div>
  </div>
  <br/>

  <div class="drop-buttons verify">
    <button
      class="btn-large"
      [disabled]="code && code.length != 6"
      (click)="verfityCode()"
      mat-raised-button
      color="accent"
    >
      Verify!
    </button>
  </div>

  <!--  Success animation to show before disappearing modal -->
  <div class="feedbackOverlay text-center" id="auth-success" *ngIf="authenticationSuccess">
    <svg
      class="animated"
      id="successAnimation"
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 70 70"
    >
      <path
        id="successAnimationResult"
        fill="#D8D8D8"
        d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"
      />
      <circle
        id="successAnimationCircle"
        cx="35"
        cy="35"
        r="24"
        stroke="#979797"
        stroke-width="2"
        stroke-linecap="round"
        fill="transparent"
      />
      <polyline
        id="successAnimationCheck"
        stroke="#979797"
        stroke-width="2"
        points="23 34 34 43 47 27"
        fill="transparent"
      />
    </svg>
  </div>
</div>
