import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'landing-page-header-image',
  templateUrl: './landing-page-header-image.component.html',
  styleUrls: ['./landing-page-header-image.component.css'],
})
export class LandingPageHeaderImageComponent implements OnInit {
  @Input() headerImage;
  @Input() hideZoomButton;

  profileLink;
  nodeServer = environment.nodeUrl;
  merchantID;

  settings: any = Settings.global;

  constructor(private AuthenticationService: AuthenticationService) {}

  ngOnInit() {
    if (!this.merchantID) {
      this.AuthenticationService.getCurrentPractice().subscribe((r) => {
        if (r && r.ID) {
          this.merchantID = r.ID;
        }
      });
    }
  }

  getCircle(headerImage) {
    if (headerImage && headerImage.indexOf('?type=c') != -1) {
      return true;
    }

    return false;
  }
}
