<!--  If account is disabled, show this -->
<div class="rel error text-center">
  <mat-icon class="large-modal-icon" color="accent">error</mat-icon>
  <h3 class="sr-title sm-h3 no-pad">Oops! There seems to be a problem.</h3>
  <p class="">It appears that your account is currently inactive.</p>
  <p>Please contact Us.</p>
  <br/>

  <div class="drop-buttons">
    <button class="btn-large" (click)="contactUS()" mat-raised-button color="primary">Contact Us</button>
    <button class="btn-large" (click)="closeModalEvent()" mat-raised-button color="accent">Close</button>
  </div>
</div>
