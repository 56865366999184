import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilsService } from '../../services/utils.service';
import { UtilsClass } from '../../types/utils/utils.class';

@Component({
  selector: 'app-occupation-input',
  templateUrl: './occupation-input.component.html',
  styleUrls: ['./occupation-input.component.css'],
})
export class OccupationInputComponent implements OnInit {
  _occupation;
  occupation;

  occupations = [];

  @Input()
  occupationID;

  @Input()
  occupationLabel;

  @Output()
  getOccupation = new EventEmitter();

  @Output()
  validate = new EventEmitter();

  @Input()
  isRequired = true;

  requiredLabel = 'required';

  isValidate = false;
  util = new UtilsClass();

  occupationFilter;

  @Input()
  clear = new EventEmitter();

  constructor(private utilService: UtilsService) {}

  ngOnInit() {
    if (this.clear) {
      this.clear.subscribe((res) => {
        this.occupationID = null;
        this.occupation = null;
        this.sendResult();
      });
    }

    if (this.isRequired == false) {
      this.requiredLabel = null;
    }

    const payload = {
      limit: 300,
      fields: 'ID,Label',
    };

    this.utilService.getSimpleLookup('Occupation', payload).subscribe((res) => {
      if (res && res.length > 0) {
        this.occupations = res;

        if (this.occupationLabel) {
          for (let i = 0; i < this.occupations.length; i++) {
            if (this.occupations[i]['Label'] == this.occupationLabel) {
              this._occupation = this.occupations[i]['Label'];
              this.occupationFilter = this._occupation;
            }
          }
        }

        if (this.occupationID) {
          for (let i = 0; i < this.occupations.length; i++) {
            if (this.occupations[i]['ID'] == this.occupationID) {
              this._occupation = this.occupations[i]['Label'];
              this.occupationFilter = this._occupation;
            }
          }
        }
      }

      this.sendResult();
    });
  }

  chooseOccupation(e) {
    if (e && typeof e == 'object' && e.ID && e.Label) {
      this.occupation = e;
      this._occupation = e.Label;

      this.sendResult();
    }
  }

  sendResult() {
    this.validateField();
    this.getOccupation.emit(this.occupation);
  }

  filterResult() {
    this.occupationFilter = this._occupation;
  }

  validateField() {
    let ruleValidator;
    if (this.isRequired != false) {
      ruleValidator = {
        occupation: 'string|required',
      };
    } else {
      ruleValidator = {
        occupation: 'string',
      };
    }

    const p = {
      occupation: this.occupation,
    };
    this.isValidate = this.util.validateFields(p, ruleValidator);

    this.validate.emit(this.isValidate);
  }
}
