/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./message-view.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../shared/components/blob-view/blob-view.component.ngfactory";
import * as i5 from "../../../shared/components/blob-view/blob-view.component";
import * as i6 from "ngx-device-detector";
import * as i7 from "@angular/platform-browser";
import * as i8 from "@angular/material/dialog";
import * as i9 from "@angular/common";
import * as i10 from "../../../shared/pipes/safe-html.pipe";
import * as i11 from "./message-view.component";
import * as i12 from "../../../core/authentication/shared/authentication.service";
import * as i13 from "../shared/message.service";
var styles_MessageViewComponent = [i0.styles];
var RenderType_MessageViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessageViewComponent, data: {} });
export { RenderType_MessageViewComponent as RenderType_MessageViewComponent };
function View_MessageViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "card-header primary-gradient-img clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "button", [["class", "btn-close btn-clear white pull-right mat-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["clear"])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "row titleArea clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "h2", [["class", "message-title summary-header rm-m"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "mat-icon", [["class", "fas fa-eye mr mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(9, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, null, [" View Message "]))], function (_ck, _v) { _ck(_v, 4, 0); _ck(_v, 9, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).inline; var currVal_1 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 9).inline; var currVal_3 = (((i1.ɵnov(_v, 9).color !== "primary") && (i1.ɵnov(_v, 9).color !== "accent")) && (i1.ɵnov(_v, 9).color !== "warn")); _ck(_v, 8, 0, currVal_2, currVal_3); }); }
function View_MessageViewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-blob-view", [["class", "row clearfix flex flexCol full-width full-height"]], null, null, null, i4.View_BlobViewComponent_0, i4.RenderType_BlobViewComponent)), i1.ɵdid(2, 638976, null, 0, i5.BlobViewComponent, [i6.DeviceDetectorService, i7.DomSanitizer, [2, i8.MAT_DIALOG_DATA], i1.NgZone], { content: [0, "content"], extension: [1, "extension"], isDownload: [2, "isDownload"], isProceed: [3, "isProceed"], isPrint: [4, "isPrint"], canProceed: [5, "canProceed"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message.BodyBase64; var currVal_1 = "html"; var currVal_2 = false; var currVal_3 = false; var currVal_4 = false; var currVal_5 = false; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_MessageViewComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i8.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 12, "div", [["class", "marvel-device iphone8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "top-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "sleep"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "volume"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "camera"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "sensor"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "speaker"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "screen"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "span", [["class", "messages"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "p", [["class", "speech-bubble preview"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(13, 1), (_l()(), i1.ɵeld(14, 0, null, null, 0, "div", [["class", "home"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "div", [["class", "bottom-bar"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i1.ɵnov(_v.parent.parent, 0), _co.message.Body)); _ck(_v, 12, 0, currVal_0); }); }
function View_MessageViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageViewComponent_2)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageViewComponent_3)), i1.ɵdid(4, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageViewComponent_4)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isModal == true); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.messageType == "E"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.messageType == "S"); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_MessageViewComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i10.SafeHtmlPipe, [i7.DomSanitizer]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageViewComponent_1)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MessageViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-message-view", [], null, null, null, View_MessageViewComponent_0, RenderType_MessageViewComponent)), i1.ɵdid(1, 114688, null, 0, i11.MessageViewComponent, [i12.AuthenticationService, i13.MessageService, [2, i8.MAT_DIALOG_DATA]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MessageViewComponentNgFactory = i1.ɵccf("app-message-view", i11.MessageViewComponent, View_MessageViewComponent_Host_0, { messageID: "messageID", messageType: "messageType", resendButton: "resendButton" }, { close: "close" }, []);
export { MessageViewComponentNgFactory as MessageViewComponentNgFactory };
