<div class="container rel" @ngIfAnimation>
  <mat-tab-group class="mobNavi" [(selectedIndex)]="selectedTab" (selectedIndexChange)="tabClick($event)">
    <!--  introduction tab  -->
    <mat-tab [label]="('KEYWORD.dental' | translate | titlecase)">
      <ng-template mat-tab-label>
        <div class="button-content">
          <mat-icon class="fas fa-address-card"></mat-icon>
          <p class="icon-text">{{ "KEYWORD.patient" | translate | titlecase }} card</p>
        </div>
      </ng-template>
      <div class="showOverflow" *ngIf="selectedTab == 0" @ngIfTab>
        <div class="intro-text animate main row clearfix">
          <div class="textBlock" *ngIf="context != 'vets'">
            <h1 class="patientcard-title rm-mb">
              <span>Hello</span>&nbsp;<span class="patientName">{{ customInv.FirstName }}</span>
            </h1>
            <p class="sub add-mb rm-mt lead">Your {{ 'PHRASE.treatment plan' | translate }} is ready to view!</p>
          </div>
          <div class="textBlock vets" *ngIf="context == 'vets'">
            <h1 class="patientcard-title rm-m">
              <span>Hello</span> <span class="patientName">{{ customInv.FirstName }}</span>
            </h1>
            <p class="sub rm-m lead">Your {{ 'PHRASE.treatment plan' | translate }} is ready to view!</p>
          </div>
        </div>

        <app-invitation-status class="clearfix row" [invitation]="customInv" [patientCard]="true" [newTab]="false">
        </app-invitation-status>

        <div class="intro-video-container mat-elevation-z5 clearfix" style="padding: 56.25% 0 0 0; position: relative">
          <iframe src="https://player.vimeo.com/video/362512520?byline=0"
                  style="position: absolute; top: 0; left: 0; width: 100%; height: 100%" frameborder="0"
                  allow="autoplay; fullscreen" allowfullscreen></iframe>
        </div>

        <!-- dentist view -->

        <app-dentist-view-consumer class="dentist-info row clearfix" *ngIf="customInv['DentistContact_key']"
                                   [dentistID]="customInv['DentistContact_key']"
                                   [messageFromDentist]="customInv.MessageFromDentist">
        </app-dentist-view-consumer>

        <!--{{ "KEYWORD.treatment" | translate }} cta -->
        <mat-card class="treatment secondary-gradient rel animate first botton mb"
                  [matRippleColor]="'rgba(255,255,255,0.14)'" (click)="changeTab()" matRipple>
          <div class="row clearfix flex valueLabels">
            <div class="col-left full-width clearfix">
              <h2 class="summary-header sr-title rm-m mt">Your {{"KEYWORD.treatment" | translate}}:</h2>
              <p class="rm-mt">
                <span *ngIf="treatmentCategorySet == false">See attached {{ "PHRASE.treatment plan" | translate }}
                  document</span>
                <span *ngIf="treatmentCategorySet == true">Category - Type - Brand</span>
              </p>
            </div>
            <div class="col-right full-width clearfix valueLabels text-right">
              <button class="full-width full-height btn-outline pop" *ngIf="viewOnly != true" mat-button>
                <span class="mobHide"> Get started! <mat-icon>chevron_right</mat-icon> </span>
                <span class="mobShow"> Go! <mat-icon class="fas fa-hand-point-right"></mat-icon> </span>
              </button>

              <button class="full-width full-height btn-outline pop" *ngIf="viewOnly == true" mat-button>
                <span class="mobHide"> Check Documents! <mat-icon>chevron_right</mat-icon> </span>
                <span class="mobShow"> Go! <mat-icon class="fas fa-hand-point-right"></mat-icon> </span>
              </button>
            </div>
          </div>
          <mat-icon class="background fas fa-paw" *ngIf="context == 'vets'"></mat-icon>
          <mat-icon class="background fas {{'fa-tooth' | translate}}" *ngIf="context != 'vets'"></mat-icon>
        </mat-card>
      </div>
    </mat-tab>

    <!-- {{ "KEYWORD.treatment" | translate }} details tab  -->
    <mat-tab (onEnter)="stepTwoVisited = true" label="Tab Two">
      <ng-template mat-tab-label>
        <div class="button-content">
          <div class="documentIndicator" *ngIf="documents && documents.length > 0">{{ documents.length }}</div>

          <mat-icon class="fas {{'fa-tooth' | translate}}" *ngIf="context != 'vets'"></mat-icon>
          <mat-icon class="fas fa-paw" *ngIf="context == 'vets'"></mat-icon>
          <p class="icon-text">{{ "KEYWORD.treatment" | translate | titlecase }}</p>
        </div>
      </ng-template>

      <div class="showOverflow" @ngIfTab>
        <div class="intro animate">
          <h2 class="patientcard-title add-mb">Please review your {{"KEYWORD.treatment" | translate}}</h2>
        </div>

        <app-treatment-document class="clearfix animate" *ngIf="isInitialLoaded == true"
                                [invitationID]="customInv['ID']" [isPublic]="true" [canView]="!accessByMerchant"
                                [treatmentDocumentTitle]="'Your proposed ' + ('KEYWORD.treatment' | translate) + ' documents'"
                                [preTreatmentDocumentTitle]="'Before Your Treatment'"
                                [postTreatmentDocumentTitle]="'After Your Treatment'"
          [marketingTreatmentDocumentTitle]="'More Details'"
                                [treatmentInstructionLine]="'Please click to view what your ' + ('KEYWORD.dentist' | translate) + ' has reccomended'"
          [preTreatmentInstructionLine]="
            'IMPORTANT: Please click to view the following documents prior to your treatment'
          " [postTreatmentInstructionLine]="
            'IMPORTANT: Please click to view the following documents after to your treatment'
          " [marketingTreatmentInstructionLine]="'Click to view further information on your proposed treatment'"
                                [isFilter]="false" (getDocuments)="getDocuments($event)"
                                (getDocuments)="getDocuments($event)">
        </app-treatment-document>

        <div class="row clearfix flex full-width">
          <mat-card class="full-width payment-cta totalValues animate" *ngIf="treatment">
            <div class="row clearfix flex mt mb">
              <div class="full-width text-left clearfix validUntil">
                <p class="small rm-m">This proposal is valid until</p>
                <h2 class="rm-m">
                  {{ customInv['Date.Expiry'] | customDate }}
                </h2>
              </div>
              <div class="full-width text-left clearfix">
                <!-- <div class="text-left pull-left clearfix">
                 &nbsp;
                </div>
                -->
                <div class="text-right pull-right clearfix">
                  <p class="small rm-m">Total {{ "KEYWORD.treatment" | translate }} value:</p>
                  <h2 class="rm-m">
                    {{ customInv['Treatment.Value'] | customCurrency }}
                    <!-- <strong *ngIf="treatment.treatmentCategory && treatment.treatmentCategory.label"> - {{ "Category" | translate }}: {{treatment.treatmentCategory?.label}}</strong>
                  <strong *ngIf="treatment.treatmentBrand && treatment.treatmentBrand.label" > - {{ "Brand" | translate }}: {{treatment.treatmentBrand?.label}}</strong> -->
                  </h2>
                </div>
              </div>
            </div>
          </mat-card>

          <mat-card class="full-width payment-cta rel botton animate" *ngIf="minAmount"
                    [matRippleColor]="'rgba(255,255,255,0.14)'" (click)="changeTab()" matRipple>
            <div class="row clearfix">
              <h3 class="rm-m">Smooth out your {{ "KEYWORD.treatment" | translate }} cost</h3>
              <hr/>
              <button class="payment-options-cta" mat-button>
                From as little as <span class="valuePerDay">{{ minAmount | customCurrency }}/day!</span>
                <mat-icon class="fas fa-hand-point-right"></mat-icon>
              </button>
            </div>
            <mat-icon class="background fas fa-dollar-sign"></mat-icon>
          </mat-card>
        </div>
        <div class="row clearfix flex full-width" *ngIf="isModuleAppointmentOldActive == true">
          <!-- appointment cta -->
          <mat-card class="full-width treatment animate appointment primary-gradient rel">
            <div class="row clearfix valueLabels text-center">
              <div class="copy">
                <h2 class="summary-header rm-m">Your next appointment:</h2>
                <p class="lead rm-mt">Tuesday, <strong>18th February</strong> at <strong>9.30am</strong></p>
              </div>
              <hr/>
              <button mat-button>
                <mat-icon>event_available</mat-icon>
                Add to calendar
              </button>
            </div>
            <mat-icon class="background fas fa-calendar-check"></mat-icon>
          </mat-card>
        </div>
        <!-- whats next -->
        <div class="row clearfix flex full-width mb" *ngIf="viewOnly != true">
          <mat-card class="treatment animate secondary-gradient rel animate full-width botton"
                    [matRippleColor]="'rgba(255,255,255,0.14)'" (click)="changeTab()" matRipple>
            <div class="row clearfix flex valueLabels">
              <div class="col-left full-width clearfix">
                <h2 class="white rm-m">What's next?</h2>
              </div>
              <div class="col-right full-width clearfix valueLabels text-right">
                <button class="full-width full-height btn-outline pop" mat-button>
                  See your options! <mat-icon>chevron_right</mat-icon>
                </button>
              </div>
            </div>
            <mat-icon class="background fas fa-paw" *ngIf="context == 'vets'"></mat-icon>
            <mat-icon class="background fas {{'fa-tooth' | translate}}" *ngIf="context != 'vets'"></mat-icon>
          </mat-card>
        </div>

        <div class="row clearfix flex full-width mb" *ngIf="
            isModulePatientHistoryActive == true &&
            customInv['Needs_HealthHistory'] == '1' &&
            customInv['Prospect_key'] &&
            customInv['Merchant_Key']
          ">
          <mat-card class="health-record animate full-width">
            <div class="card-header-image-area" mat-card-image>
              <div class="image">
                <img
                  src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/character/character-history.png"/>
              </div>
              <div class="half-width">
                <h2 class="white rm-mb">{{ customInv.FirstName }}, <br/>is your dental history current?</h2>
                <p class="mb">
                  Streamline your appointments by providing {{ customInv.MerchantName }} with an updated record of your
                  {{"KEYWORD.dental" | translate}} {{"PARAGRAPH.and health history" | translate}}.
                </p>
                <button class="btn-large" (click)="createMedicalHistory()" mat-raised-button color="primary">
                  Update now
                  <mat-icon>chevron_right</mat-icon>
                </button>
              </div>
            </div>
          </mat-card>
        </div>

        <div class="row clearfix backBtn animate text-center">
          <button class="mat-elevation-z4" (click)="backTab()" mat-raised-button color="accent">
            <mat-icon>chevron_left</mat-icon> Back
          </button>
        </div>
      </div>
    </mat-tab>

    <!--  proceed tab  -->
    <mat-tab *ngIf="viewOnly != true" label="Tab Three ">
      <ng-template mat-tab-label>
        <div class="button-content">
          <mat-icon class="fas fa-hand-point-right"></mat-icon>
          <p class="icon-text">What's next?</p>
        </div>
      </ng-template>

      <div class="showOverflow" *ngIf="selectedTab == 2" @ngIfTab>
        <div class="intro animate">
          <h2 class="patientcard-title add-mb">Let your {{"KEYWORD.dentist" | translate}} know how you would like to
            proceed</h2>
        </div>
        <!-- payment product list -->
        <app-suitable-product-compact-list class="product-selection animate" *ngIf="customInv['treatment.Value']"
                                           [frequency]="'1'" [showDisabled]="true"
                                           [groupCode]="customInv['Product.ThisGroupOnly.Code']"
                                           [merchantKey]="customInv['Merchant_Key']"
                                           [amount]="customInv['treatment.Value']"
                                           (select)="selectProduct($event)"></app-suitable-product-compact-list>

        <div class="minorPad">
          <mat-card class="changes-card rel botton animate" (click)="changeTab()" matRipple>
            <div class="row clearfix">
              <h2 class="accent rm-m">Not what you expected?</h2>
              <p class="mb">
                If there's something wrong with the proposed {{ "KEYWORD.treatment" | translate }} or you're not ready
                to proceed, please let your {{ "KEYWORD.dentist" | translate }} know...
              </p>
              <button class="btn-outline" mat-raised-button>See my options <mat-icon>chevron_right</mat-icon></button>
            </div>
            <mat-icon class="background fas fa-grimace"></mat-icon>
          </mat-card>
        </div>

        <div class="prodItem cashDiscount" *ngIf="
            isModuleDiscountsActive == true && customInv['CashDiscount.Offered.YesNo'] == '1' && displayDiscount == true
          ">
          <mat-card class="full-width prodCard rel mat-elevation-z7 animate pointer" (click)="openDiscountInfoModal()">
            <div class="ribbon">
              <span>SAVINGS</span>
            </div>
            <div class="text-center nooverflow" [matRippleColor]="'rgba(255,255,255,0.14)'" matRipple>
              <h3 class="white from rm-mt">Want to save</h3>
              <p class="mainValue smaller rm-m">{{ customInv['CashDiscount.Offered.Amount'] | customCurrency }}</p>
              <h3 class="white small per-day">off your {{"KEYWORD.treatment" | translate}}?</h3>

              <div class="row clearfix">
                <button class="btn-outline full-width" (click)="openDiscountInfoModal()" mat-raised-button color=""
                        aria-hidden="true" tabindex="-1">
                  Click here!
                  <mat-icon class="fas fa-hand-point-right"></mat-icon>
                </button>
              </div>

              <h6 class="small white rm-mb">
                <span
                  *ngIf="customInv['CashDiscount.Expiry.Date'] && customInv['CashDiscount.Expiry.Date'] != '0000-00-00'">
                  This offer is only valid for
                  <span *ngIf="cashDiscountDateCounter && cashDiscountDateCounter.d > 0">
                    {{ cashDiscountDateCounter.d | number: '2.0-0' }} day(s),
                  </span>
                  <span *ngIf="cashDiscountDateCounter">{{ cashDiscountDateCounter.h | number: '2.0-0' }} Hour(s)
                    {{ cashDiscountDateCounter.m | number: '2.0-0' }} Min(s)
                  </span>
                  when you pay in full upfront (Cash or card)
                </span>
              </h6>
            </div>

            <div class="deepBg"></div>
          </mat-card>
        </div>

        <div class="prodItem" *ngIf="
            isModuleDiscountsActive == true && customInv['CashDiscount.Offered.YesNo'] == '1' && displayDiscount == true
          ">
          <mat-card class="product-selection discount primary-gradient-img botton animate single"
                    [matRippleColor]="'rgba(255,255,255,0.14)'" (click)="confirmNotFincance()" matRipple>
            <h2 class="lending-prod-compact-h2">Proceed, and pay at the {{"KEYWORD.practice" | translate}} on the day
            </h2>
            <h3 class="white mb text-center rm-mt">(Cash or card)</h3>
            <p class="text-center">
              If you wish to proceed with {{ "KEYWORD.treatment" | translate }} and settle with cash or card at the
              {{"KEYWORD.practice" | translate}} on the day - click
              below
            </p>
            <div class="buttons-content">
              <button class="btn-outline full-width" mat-raised-button color="" aria-hidden="true" tabindex="-1">
                Proceed and pay on the day
                <mat-icon class="fas fa-hand-point-right"></mat-icon>
              </button>
            </div>
          </mat-card>
        </div>

        <div class="prodItem">
          <mat-card class="product-selection discount primary-gradient-img botton animate single" *ngIf="
              isModuleDiscountsActive == false ||
              customInv['CashDiscount.Offered.YesNo'] != '1' ||
              displayDiscount == false
            " [matRippleColor]="'rgba(255,255,255,0.14)'" (click)="confirmNotFincance()" matRipple>
            <div class="text-center noDisc">
              <h3 class="lending-prod-compact-h2 rm-mt">
                Prefer to pay with
                <br class="mobshow"/>
                cash or card?
              </h3>
              <p>
                If you wish to proceed with this {{ "KEYWORD.treatment" | translate }} and settle with cash or card at
                the {{ "KEYWORD.practice" | translate }} on the day - click below.
              </p>
              <button class="btn-outline payProceed" mat-raised-button color="primary">
                Proceed and pay on the day
                <mat-icon aria-hidden="true">chevron_right</mat-icon>
              </button>
            </div>
          </mat-card>
        </div>

        <div class="row clearfix backBtn animate text-center">
          <button class="mat-elevation-z4" (click)="backTab()" mat-raised-button color="accent">
            <mat-icon>chevron_left</mat-icon> Back
          </button>
        </div>
      </div>
    </mat-tab>

    <!--  more options tab  -->
    <mat-tab *ngIf="viewOnly != true" label="More Options">
      <ng-template mat-tab-label>
        <div class="button-content">
          <mat-icon class="fas fa-ellipsis-v"></mat-icon>
          <p class="icon-text">More</p>
        </div>
      </ng-template>

      <div class="showOverflow" *ngIf="selectedTab == 3" @ngIfTab>
        <div class="intro animate">
          <h2 class="patientcard-title">Need to change something?</h2>
        </div>
        <!-- request changes -->
        <mat-card class="alt-option botton animate changes" [matRippleColor]="'rgba(255,255,255,0.14)'"
                  (click)="openPatientCardChangesModal()" matRipple>
          <h2 class="rm-mb">Request Changes</h2>
          <p class="mb">{{"PHRASE.treatment plan" | translate | titlecase}} Request Modifications</p>

          <button class="cool" mat-button>
            <mat-icon aria-hidden="true">edit</mat-icon>
            Request Changes
          </button>
          <mat-icon class="background fas fa-pencil-alt"></mat-icon>
        </mat-card>
        <!-- request deferral -->
        <mat-card class="alt-option botton animate hold" [matRippleColor]="'rgba(255,255,255,0.14)'"
                  (click)="openPatientCardDeferModal()" matRipple>
          <h2 class="rm-mb">Hold off for now</h2>
          <p class="mb">
            If you need to defer your {{ "KEYWORD.treatment" | translate }}, we can raincheck to a later date.
          </p>

          <button class="warm" mat-button>
            <mat-icon aria-hidden="true">access_time</mat-icon>
            Hold Off
          </button>
          <mat-icon class="background fas fa-stopwatch"></mat-icon>
        </mat-card>
        <!-- request dont proceed -->
        <mat-card class="alt-option botton animate cancel botton" [matRippleColor]="'rgba(255,255,255,0.14)'"
                  (click)="openPatientCardStopModal()" matRipple>
          <h2 class="rm-mb">Don't proceed</h2>
          <p class="mb">
            If you don't want to go ahead with your {{ "KEYWORD.treatment" | translate }}, please let us know.
          </p>

          <button class="hot" mat-button>
            <mat-icon aria-hidden="true">report</mat-icon>
            Don't proceed
          </button>
          <mat-icon class="background fas fa-hand-paper"></mat-icon>
        </mat-card>

        <div class="row clearfix backBtn animate text-center">
          <button class="mat-elevation-z4" (click)="backTab()" mat-raised-button color="accent">
            <mat-icon>chevron_left</mat-icon> Back
          </button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
