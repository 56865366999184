import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { OperatorContactComponent } from '../../../feature/operator/operator-contact/operator-contact.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { LogUserListComponent } from '../../log/log-user-list/log-user-list.component';
import { AgentDetailsComponent } from '../agent-details/agent-details.component';
import { SessionsService } from '../shared/sessions.service';

@Component({
  selector: 'app-connection-history-view',
  templateUrl: './connection-history-view.component.html',
  styleUrls: ['./connection-history-view.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.stacked-card-view', style({ opacity: 0 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.stacked-card-view', style({ opacity: 1 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ConnectionHistoryViewComponent implements OnInit {
  @Input() cnhID = null;
  @Output()
  close: EventEmitter<any> = new EventEmitter();
  isModal = true;
  cnHistory;
  constructor(
    private customPhone: CustomPhonePipe,
    private sessionService: SessionsService,

    private activeRoute: ActivatedRoute,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'object') {
      this.cnhID = data.ID;
      this.isModal = true;
    }
  }

  async ngOnInit() {
    if (!this.cnhID) {
      const params = await this.activeRoute.params.toPromise();
      if (params && params['cnhID ']) {
        this.cnhID = params['cnhID '];
      }
    }
    if (this.cnhID) {
      this.sessionService.getOneConnectionHistory(this.cnhID).subscribe((res) => {
        this.cnHistory = res;
      });
    }
  }

  displayContact() {
    RootAppComponent.dialog.open(OperatorContactComponent, {
      width: '600px',
      data: this.cnHistory.id_4d,
    });
  }

  viewUserLogs() {
    const ref = RootAppComponent.dialog.open(LogUserListComponent, {
      data: this.cnHistory.id_4d,
      width: '90%',
      height: '80%',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  displayAgentDetails() {
    let label = 'Browser Details';

    if (this.cnHistory && this.cnHistory.name) {
      label = label + ': ' + this.cnHistory.name;
    }

    const ref = RootAppComponent.dialog.open(AgentDetailsComponent, {
      data: {
        label,
        agents: this.cnHistory.clientInfoHistory,
        cnhID: this.cnhID,
      },
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  closeEvent() {
    this.close.emit(true);
  }
}
