import { Component, EventEmitter, Inject, Input, OnInit, Optional, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { CustomerProspectService } from '../shared/customerProspect.service';

@Component({
  selector: 'app-customer-prospect-view',
  templateUrl: './customer-prospect-view.component.html',
  styleUrls: ['./customer-prospect-view.component.css'],
})
export class CustomerProspectViewComponent implements OnInit {
  @Input()
  patientID;

  @Input()
  printMode = false;

  closeModal = new EventEmitter();

  isModal = false;
  patient;

  prospectID;
  customerID;
  sessionType = 'guest';

  constructor(
    private authenticationService: AuthenticationService,
    private customerProspectService: CustomerProspectService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.patientID = data;
      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }
      const payload = {
        fields: 'ID,TableName',
      };
      this.customerProspectService
        .getCustomerProspectDetails(this.patientID, payload, this.sessionType)
        .subscribe((res) => {
          this.patient = res;

          if (this.patient['TableName'] === 'Prospect') {
            this.prospectID = this.patient['ID'];
          } else if (this.patient['TableName'] === 'Customer') {
            this.customerID = this.patient['ID'];
          }
        });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.patientID.currentValue != changes.patientID.previousValue) {
      const payload = {
        fields: 'ID,TableName',
      };

      this.customerProspectService
        .getCustomerProspectDetails(this.patientID, payload, this.sessionType)
        .subscribe((res) => {
          this.patient = res;

          if (this.patient['TableName'] === 'Prospect') {
            this.prospectID = this.patient['ID'];
            this.customerID = null;
          } else if (this.patient['TableName'] === 'Customer') {
            this.customerID = this.patient['ID'];
            this.prospectID = null;
          }
        });
    }
  }

  closeEvent(e) {
    this.closeModal.emit(e);
  }
}
