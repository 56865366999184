import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { ActionLogViewComponent } from '../../action-log/action-log-view/action-log-view.component';
import { ActionLogService } from '../../action-log/shared/action-log.service';
import { InvitationService } from '../../invitation/shared/services/invitation.service';
import { NotesModal, NotesModalConfig } from '../../notes/modals/notes-modal/notes.modal';
import { MessageViewComponent } from '../message-view/message-view.component';
import { MessageService } from '../shared/message.service';
import { SmsConversationComponent } from '../sms-conversation/sms-conversation.component';
import { SmsPromoterComponent } from '../sms-promoter/sms-promoter.component';
import { TextMessageCreateComponent } from '../text-message-create/text-message-create.component';

@Component({
  selector: 'app-message-timeline',
  templateUrl: './message-timeline.component.html',
  styleUrls: ['./message-timeline.component.css'],
  animations: [
    trigger('fade', [transition(':enter', [style({ opacity: 0 }), animate('.6s ease')])]),
    trigger('stagger', [transition(':enter', [query(':enter', stagger('.3s', [animateChild()]), { optional: true })])]),
  ],
})
export class MessageTimelineComponent implements OnInit {
  @Input()
  invitationID;

  @Input()
  contractID;

  @Input()
  patientID;
  @Input()
  merchantID;

  @Input()
  messageType = MessageType.all;

  @Input()
  cardID;

  isModal = false;
  isAdminOrPromoter = false;
  messages;
  sessionType = 'guest';
  util = new UtilsClass();

  @Output()
  close = new EventEmitter();

  invitation;

  isModulePatientSMSActive = Settings.global['isModulePatientSMSActive'];


  isLoad = false ;

  constructor(
    private UtilsService: UtilsService,
    private messageService: MessageService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    private invitationService: InvitationService,
    private actionlogService: ActionLogService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.isModal = true;
      this.contractID = data.contractID;
      this.cardID = data.cardID;


      this.invitationID = data.invitationID;
      this.messageType = data.messageType || MessageType.all;
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  ngOnInit() {
    this.UtilsService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModulePatientSMSActive = access['isModulePatientSMSActive'];
      }
    });
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;

        if (this.invitationID) {
          this.invitationService
            .getInvitationDetails(this.invitationID, {}, this.sessionType, false)
            .subscribe((resInv) => {
              if (resInv) {
                this.invitation = resInv;
                let patientResponse;
                const dentistMessage = {
                  DateTimeCreated: resInv['DateTimeCreated'],
                  Description: resInv['MessageFromDentist'],
                  Type: 'Dentist Message',
                };
                if (resInv['PatientChoice.Code'] === 'NOT') {
                  let _response;
                  if (resInv['PatientDeclineReasons.Feedback']) {
                    _response =
                      resInv['PatientDeclineReasons.Labels'] + '<BR>' + resInv['PatientDeclineReasons.Feedback'];
                  } else {
                    _response = resInv['PatientDeclineReasons.Labels'];
                  }

                  const payloadEventLog = {
                    invitationID: this.invitationID,
                    eventCode: 'INV-CrCmm.Rspd.Fin',
                  };
                  this.actionlogService
                    .getInvitationEvents(payloadEventLog, this.sessionType)
                    .subscribe((resEventLog) => {
                      if (resEventLog) {
                        patientResponse = {
                          DateTimeCreated: resEventLog[0]['DateTime_ISO'],
                          Description: _response,
                          Type: 'Patient Response',
                        };
                      }

                      this.combineMessages(dentistMessage, patientResponse);
                    });
                } else if (resInv['PatientChoice.Code'] === 'DSMO') {
                  const _response = resInv['PatientFeedback'];

                  const payloadEventLog = {
                    invitationID: this.invitationID,
                    eventCode: 'INV-CrCmm.Rspd.Fin',
                  };
                  this.actionlogService
                    .getInvitationEvents(payloadEventLog, this.sessionType)
                    .subscribe((resEventLog) => {
                      if (resEventLog) {
                        patientResponse = {
                          DateTimeCreated: resEventLog[0]['DateTime_ISO'],
                          Description: _response,
                          Type: 'Patient Response',
                        };
                      }

                      this.combineMessages(dentistMessage, patientResponse);
                    });
                } else {
                  this.combineMessages(dentistMessage, patientResponse);
                }
              }
            });
        } else if (this.cardID) {
          // let payloadMessageList: any = {
          //   cardID: this.cardID,
          //   fields:
          //     "ID,DateTimeCreated,Description,Type,Status.Code,Status.Label,Date.written,Date.submitted,Date.sent,Time.sent," +
          //     "TemplateTag",
          // };

          // this.messageService
          //   .getMessageList(payloadMessageList, this.sessionType)
          //   .subscribe((resMessageList) => {
          //     this.messages = resMessageList;
          //   });

          if (this.messageType === MessageType.message) {
            const payloadMessageList: any = {
              cardID: this.cardID,
              fields:
                'ID,DateTimeCreated,Description,Type,Status.Code,Status.Label,Date.written,Date.submitted,Date.sent,Time.sent,' +
                'TemplateTag',
            };

            this.messageService.getMessageList(payloadMessageList, this.sessionType).subscribe((resMessageList) => {
              this.messages = resMessageList;

              this.isLoad=true
            });
          }

          if (this.messageType === MessageType.note) {
            const payload = {
              itemID: this.contractID,
              withoutAutoAction: true,
            };

            this.actionlogService.getActionLogList(payload, this.sessionType).subscribe((res) => {
              if (res) {
                const aa = res.map((x) => {
                  return {
                    ID: x['ID'],
                    DateTimeCreated: x['DateTimeCreated'],
                    Description: `${x['Result']}`,
                    Type: 'Note Message',
                  };
                });

                this.messages = aa;

                this.messages.sort((b, a) => {
                  return a['DateTimeCreated'].localeCompare(b['DateTimeCreated']);
                });

                this.isLoad=true
              }
            });
          }
        } else if (this.contractID) {


          if (this.messageType === MessageType.message) {
            const payloadMessageList: any = {
              contractID: this.contractID,
              fields:
                'ID,DateTimeCreated,Description,Type,Status.Code,Status.Label,Date.written,Date.submitted,Date.sent,Time.sent,' +
                'TemplateTag',
            };

            this.messageService.getMessageList(payloadMessageList, this.sessionType).subscribe((resMessageList) => {
              this.messages = resMessageList;

              this.isLoad=true
            });
          }

          if (this.messageType === MessageType.note) {
            const payload = {
              itemID: this.contractID,
              withoutAutoAction: true,
            };

            this.actionlogService.getActionLogList(payload, this.sessionType).subscribe((res) => {
              if (res) {
                const aa = res.map((x) => {
                  return {
                    ID: x['ID'],
                    DateTimeCreated: x['DateTimeCreated'],
                    Description: `${x['Result']}`,
                    Type: 'Note Message',
                  };
                });

                this.messages = aa;

                this.messages.sort((b, a) => {
                  return a['DateTimeCreated'].localeCompare(b['DateTimeCreated']);
                });

                this.isLoad=true
              }
            });
          }
        }
      });
    });
  }

  sendSMS(id, merchantID) {
    if (id) {
      if (this.sessionType == 'admin' || this.sessionType == 'promoter') {
        const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
          data: {
            merchantID,
            cardID: id,
            singleChat: true,
            contractID: this.contractID,
            invitationID:this.invitationID
          },
          panelClass: 'noCard',
          width: '500px',
        });
        ref2.componentInstance.close.subscribe((data) => {
          ref2.close();
        });

        ref2.componentInstance.getResult.subscribe((data) => {
          if (data) {
            const ref = RootAppComponent.dialog.open(SmsConversationComponent, {
              data,
              panelClass: 'bigger-screen',
              width: '70%',
              height: '95vh',
            });
            const sub = ref.componentInstance.close.subscribe((data) => {
              ref.close();
            });
          }

          ref2.close();
        });
      } else {
        const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
          data: {
            cardID: id,
            singleChat: true,
            contractID: this.contractID,
            invitationID:this.invitationID
          },
          panelClass: 'bigger-screen',
          width: '70%',
          height: '95vh',
        });
        const sub = ref2.componentInstance.close.subscribe((data) => {
          ref2.close();
        });
      }
    }
  }

  createNote(patientID: string) {
    this.dialog.open<NotesModal, NotesModalConfig>(NotesModal, {
      data: {
        patientID,
        parentRoute: 'merchant',
        noteListMode: 'create',
        relationshipLink: {
          label: 'patient',
          route: `customer-profile/${patientID}/patient`,
          itemID: patientID,
        },
      },
    });
  }

  viewNotes(patientID: string) {
    this.dialog.open<NotesModal, NotesModalConfig>(NotesModal, {
      data: {
        parentRoute: 'merchant',
        patientID,
      },
    });
  }

  combineMessages(dentistMessage, patientResponse) {
    const payloadMessageList: any = {
      invitationID: this.invitationID,
      fields:
        'ID,DateTimeCreated,Description,Type,Status.Code,Status.Label,Date.written,Date.submitted,Date.sent,Time.sent,' +
        'TemplateTag',
    };

    this.messageService.getMessageList(payloadMessageList, this.sessionType).subscribe((resMessageList) => {
      if (resMessageList) {
        if (patientResponse) {
          this.messages = resMessageList.concat(dentistMessage, patientResponse);
        } else {
          this.messages = resMessageList.concat(dentistMessage);
        }

        const payload = {
          itemID: this.invitationID,
          withoutAutoAction: true,
        };
        this.actionlogService.getActionLogList(payload, this.sessionType).subscribe((res) => {
          if (res) {
            const aa = res.map((x) => {
              return {
                ID: x['ID'],
                DateTimeCreated: x['DateTimeCreated'],
                Description: `${x['Result']}`,
                Type: 'Note Message',
              };
            });

            this.messages = this.messages.concat(aa);

            this.messages.sort((b, a) => {
              return a['DateTimeCreated'].localeCompare(b['DateTimeCreated']);
            });
          }
        });
      }

      this.isLoad=true
    });
  }

  viewContent(id, type) {
    /*     let ref = RootAppComponent.dialog.open(SmsViewDialogComponent, {
          data: {
            messageID: id,
            messageType: type
          },
          width: "800px",
        });

        ref.componentInstance.close.subscribe((res) => {
          ref.close();
        }); */

    const ref = RootAppComponent.dialog.open(MessageViewComponent, {
      data: {
        messageID: id,
        messageType: type,
      },
      width: '800px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewNote(ID) {
    const ref = RootAppComponent.dialog.open(ActionLogViewComponent, {
      data: ID,
      width: '650px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  contactBySMS() {
    const ref = RootAppComponent.dialog.open(TextMessageCreateComponent, {
      data: {
        contractID: this.contractID,
        invitationID: this.invitationID,
      },
      width: '650px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  contactByEmail() {
    if (this.contractID) {
      const data = {
        targetType: 'contract',
        targetID: this.contractID,
        asGuest: false,
        asProfile: true,
        asPractice: true,
        subjectArray: [],
      };
      AuthenticationService.contactInputEvent.emit(data);
    } else if (this.invitationID) {
      const data = {
        targetType: 'invitation',
        targetID: this.invitationID,
        asGuest: false,
        asProfile: true,
        asPractice: true,
        subjectArray: [],
      };
      AuthenticationService.contactInputEvent.emit(data);
    }
  }
}

export enum MessageType {
  all = 'all',
  message = 'message',
  note = 'note',
  email = 'email',
}
