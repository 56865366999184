import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { SettlementService } from '../shared/settlement.service';

import { SideMenuService } from '../../../shared/services/side-menu.service';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

@Component({
  selector: 'app-settlement-summary-list',
  templateUrl: './settlement-summary-list.component.html',
  styleUrls: ['./settlement-summary-list.component.css'],
})
export class SettlementSummaryListComponent implements OnInit {
  isPromoterOrAdmin = false;
  summaryObject = [];
  _summaryObject = [];
  displayedColumns = [
    'Merchant',
    'Outstanding',
    'Settled',
    'Approved',
    'Requested',
    'Pending',
    'Withdrawn',
    'Declined',
    'Voided',
  ];

  patientDisplayedColumns = [
    'Patient',
    'Outstanding',
    'Settled',
    'Approved',
    'Requested',
    'Pending',
    'Withdrawn',
    'Declined',
    'Voided',
  ];

  patientSummary = [];
  _patientSummary = [];

  dateFrom;
  dateTo;

  displayPatientSummary = false;
  displaySettlementList = false;
  merchantID;
  contractID;

  hideContent = false;

  util = new UtilsClass();

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private settlementService: SettlementService
  ) {}

  ngOnInit() {
    SideMenuService.goBack.subscribe((res) => {
      if (res == true) {
        this.hidePatientSummary();
      }
    });
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      if (this.isPromoterOrAdmin == true) {
        this.setup();
      } else {
        this.router.navigate(['/merchant']);
      }
    });
  }

  setup() {
    const payload = {
      dateFrom: this.util.EPdateFormat(this.dateFrom) || null,
      dateTo: this.util.EPdateFormat(this.dateTo) || null,
      allMerchantSummary: true,
      selectGlobal: false,
    };
    this.settlementService.statistics(payload).subscribe((res) => {
      if (res && res['merchantSummary'] && res['merchantSummary'].length > 0) {
        this.summaryObject = res['merchantSummary'];
        this._summaryObject = res['merchantSummary'];
      }
    });
  }

  calculateTotal(val, array) {
    if (array && array.length > 0) {
      const list = array;
      let result;

      switch (val) {
        case 'Outstanding':
          result = list.map((x) => x.OUTSTANDING).reduce((a, c) => a + c);
          break;
        case 'Settled':
          result = list.map((x) => x.PAID).reduce((a, c) => a + c);
          break;
        case 'Approved':
          result = list.map((x) => x.APPR).reduce((a, c) => a + c);
          break;
        case 'Requested':
          result = list.map((x) => x.REQU).reduce((a, c) => a + c);
          break;
        case 'Pending':
          result = list.map((x) => x.PEND).reduce((a, c) => a + c);
          break;
        case 'Withdrawn':
          result = list.map((x) => x.WDRN).reduce((a, c) => a + c);
          break;
        case 'Declined':
          result = list.map((x) => x.DECL).reduce((a, c) => a + c);
          break;
        case 'Voided':
          result = list.map((x) => x.VOID).reduce((a, c) => a + c);
          break;
      }

      return result;
    }
  }

  openPatientSummary(id) {
    this.displayPatientSummary = true;
    this.merchantID = id;

    const p = {
      merchantID: id,
      selectPatient: true,
      selectGlobal: false,
    };

    this.settlementService.statistics(p).subscribe((res) => {
      if (res && res['patientSummary'] && res['patientSummary'].length > 0) {
        this.patientSummary = res['patientSummary'];
        this._patientSummary = res['patientSummary'];
      }
    });
  }

  hidePatientSummary() {
    this.displayPatientSummary = false;
    this.merchantID = null;
  }

  applySearch(evt) {
    this.dateFrom = evt.dateFrom;
    this.dateTo = evt.dateTo;

    this.setup();
  }

  hideContentEvent(e) {
    this.hideContent = e;
  }

  filterStatus(status) {
    if (this._summaryObject && this._summaryObject.length > 0) {
      const list = this._summaryObject;
      let result;

      switch (status) {
        case 'Outstanding':
          result = list.filter((x) => Number(x.OUTSTANDING) > 0);
          break;
        case 'Settled':
          result = list.filter((x) => Number(x.PAID) > 0);
          break;
        case 'Approved':
          result = list.filter((x) => Number(x.APPR) > 0);
          break;
        case 'Requested':
          result = list.filter((x) => Number(x.REQU) > 0);
          break;
        case 'Pending':
          result = list.filter((x) => Number(x.PEND) > 0);
          break;
        case 'Withdrawn':
          result = list.filter((x) => Number(x.WDRN) > 0);
          break;
        case 'Declined':
          result = list.filter((x) => Number(x.DECL) > 0);
          break;
        case 'Voided':
          result = list.filter((x) => Number(x.VOID) > 0);
          break;
        default:
          result = this._summaryObject;
      }

      this.summaryObject = result;
    }
  }

  filterPatientStatus(status) {
    if (this._patientSummary && this._patientSummary.length > 0) {
      const list = this._patientSummary;
      let result;

      switch (status) {
        case 'Outstanding':
          result = list.filter((x) => Number(x.OUTSTANDING) > 0);
          break;
        case 'Settled':
          result = list.filter((x) => Number(x.PAID) > 0);
          break;
        case 'Approved':
          result = list.filter((x) => Number(x.APPR) > 0);
          break;
        case 'Requested':
          result = list.filter((x) => Number(x.REQU) > 0);
          break;
        case 'Pending':
          result = list.filter((x) => Number(x.PEND) > 0);
          break;
        case 'Withdrawn':
          result = list.filter((x) => Number(x.WDRN) > 0);
          break;
        case 'Declined':
          result = list.filter((x) => Number(x.DECL) > 0);
          break;
        case 'Voided':
          result = list.filter((x) => Number(x.VOID) > 0);
          break;
        default:
          result = this._patientSummary;
      }

      this.patientSummary = result;
    }
  }

  openSettlementList(ID) {
    this.contractID = ID;
    this.displaySettlementList = true;
  }

  hideSettlementList() {
    this.contractID = null;
    this.displaySettlementList = false;
  }
}
