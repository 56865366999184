<h1 class="dataHeader rm-mt transparent" *ngIf="search && !tag && pages && pages.length > 0">
  <span class="badge">{{ pages.length }}</span> Page(s) Found for "{{ search }}"
</h1>

<h1 class="dataHeader rm-mt transparent" *ngIf="!search && tag && pages && pages.length > 0">
  <span class="badge">{{ pages.length }}</span> Page(s) Found for the keyword <span class="chip">{{ tag }}</span>
</h1>

<div class="container" *ngIf="pages && pages.length > 0 && loading == true">
  <div class="row clearfix">
    <div class="row sorting mb">
      <mat-button-toggle-group
        class="pull-right"
        *ngIf="displatOrderLayout == true"
        [(ngModel)]="orderBy"
        (change)="orderLayout($event.value)"
      >
        <mat-button-toggle *ngIf="groupID" [value]="'priority'"> Display Order</mat-button-toggle>

        <mat-button-toggle [value]="'-created_at'"> Newest</mat-button-toggle>
        <mat-button-toggle [value]="'created_at'"> Oldest</mat-button-toggle>
        <mat-button-toggle [value]="'-views'"> Most Popular</mat-button-toggle>
      </mat-button-toggle-group>

      <mat-button-toggle-group
        class="pull-right mr"
        *ngIf="displayLayout == true"
        [(ngModel)]="layout"
        (change)="listLayout($event.value)"
      >
        <mat-button-toggle [value]="'grid'"> Grid</mat-button-toggle>
        <mat-button-toggle [value]="'list'"> List</mat-button-toggle>
      </mat-button-toggle-group>

      <h2 class="sr-title rm-m pull-left" *ngIf="title">{{ title }}</h2>
    </div>
  </div>

  <div class="row clearfix {{ layout }}">
    <div class="card-cont" *ngFor="let wiki of pages | OrderArrayObject: orderByFilter">
      <mat-card class="card" *ngIf="wiki">
        <div class="card-header primary-gradient-img clearfix slim-header">
          <div class="row clearfix">
            <!-- Modal dismiss button -->
            <div class="row clearfix">
              <div class="pull-right" *ngIf="isPromoterOrAdmin == true">
                <button class="actionMenuToggle" [matMenuTriggerFor]="menu" mat-icon-button>
                  <mat-icon class="white">more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button (click)="edit(wiki)" mat-menu-item>
                    <mat-icon class="fas fa-edit"></mat-icon>
                    <span>Edit</span>
                  </button>

                  <button (click)="editGoup(wiki)" mat-menu-item>
                    <mat-icon class="fas fa-edit"></mat-icon>
                    <span>Edit Category</span>
                  </button>

                  <button *ngIf="wiki.isActive != true" (click)="enable(wiki)" mat-menu-item>
                    <mat-icon class="fas fa-check-circle"></mat-icon>
                    <span>Enable</span>
                  </button>

                  <button *ngIf="wiki.isActive == true" (click)="disable(wiki)" mat-menu-item>
                    <mat-icon class="fas fa-ban"></mat-icon>
                    <span>Disable</span>
                  </button>

                  <button (click)="remove(wiki)" mat-menu-item>
                    <mat-icon class="fas fa-trash-alt"></mat-icon>
                    <span>Delete</span>
                  </button>
                </mat-menu>
              </div>

              <app-ep-document-view-image
                class="themeImage-mini mr pull-left"
                *ngIf="wiki && wiki.fileID"
                [hideZoomButton]="true"
                [imageLink]="getSrc(wiki.fileID)"
                (click)="moveToPage(wiki._id)"
              >
              </app-ep-document-view-image>

              <!-- merchant calculated name -->
              <div class="row titleArea pull-left pointer">
                <h2 class="message-title summary-header rm-m" *ngIf="wiki && wiki.group && wiki.group.title">
                  <div
                    [content]="wiki.title"
                    [searchTerm]="search"
                    [caseSensitive]="false"
                    (click)="moveToPage(wiki._id)"
                    ngxTextHighlight
                  ></div>

                  <span
                    class="subLabel poitner-cursor"
                    *ngIf="wiki.group && wiki.group._id"
                    (click)="moveToGroup(wiki.group._id)"
                  >
                    <mat-icon
                      class="{{ wiki.group.icon }} extra-header-icon"
                      *ngIf="wiki.group && wiki.group.icon && isAwsome(wiki.group.icon) == true"
                    ></mat-icon>
                    <mat-icon
                      class="extra-header-icon"
                      *ngIf="wiki.group && wiki.group.icon && isAwsome(wiki.group.icon) != true"
                    >{{ wiki.group.icon }}</mat-icon
                    >

                    {{ wiki.group.title }}
                  </span>
                </h2>
              </div>
            </div>

            <div class="info row clearfix">
              <p class="rm-m small pull-right" *ngIf="wiki.created_at">
                <mat-icon class="fas fa-clock extra-header-icon"></mat-icon>
                {{ wiki.created_at | TimeLabelPast }} ago
              </p>
              <p class="rm-m small ml mr pull-right" *ngIf="wiki.created_at">
                <mat-icon class="fas fa-eye extra-header-icon"></mat-icon>
                {{ wiki.views || 1 }}
              </p>
              <p class="small rm-m mr pull-right" *ngIf="wiki['isActive'] == true && isPromoterOrAdmin == true">
                <span class="chip-enable">Active</span>
              </p>
              <p class="small rm-m mr pull-right" *ngIf="wiki['isActive'] != true && isPromoterOrAdmin == true">
                <span class="chip-disable">Not active</span>
              </p>

              <p
                class="small rm-m pull-right"
                *ngIf="wiki && wiki.roles && wiki.roles.length > 0 && isPromoterOrAdmin == true"
              >
                <span class="chip-only" *ngFor="let r of wiki.roles | orderBy" matTooltip="{{ r }}">{{
                  firstLetterUpperCase(r)
                  }}</span>
              </p>

              <div
                class="time-change-hours-class text-center flexcenter pull-left"
                *ngIf="
                  groupID &&
                  pages &&
                  pages.length > 1 &&
                  isPriorityOrder() == true &&
                  (sessionType == 'admin' || sessionType == 'promoter')
                "
              >
                <mat-icon
                  class="fas fa-chevron-up clearfix change-time pull-left"
                  (click)="moveWiki(wiki['_id'], 'up')"
                  matTooltip="Move Article Up"
                  color="primary"
                ></mat-icon>

                <mat-icon
                  class="fas fa-chevron-down change-time clearfix pull-left"
                  (click)="moveWiki(wiki['_id'], 'down')"
                  matTooltip="Move Article Down"
                  color="primary"
                ></mat-icon>
              </div>
            </div>
          </div>
        </div>

        <div class="clearfix mt content-area" *ngIf="wiki.summary">
          <div [content]="wiki.summary" [searchTerm]="search" [caseSensitive]="false" ngxTextHighlight></div>
        </div>

        <hr class="mt" *ngIf="wiki.summary"/>
        <div class="clearfix mt">
          <button
            class="pull-right"
            *ngIf="wiki && wiki._id"
            (click)="moveToPage(wiki._id)"
            mat-stroked-button
            color="primary"
          >
            Read more...
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>

          <mat-chip-list class="clearfix pull-left mr" *ngFor="let r of wiki.tags">
            <mat-chip
              class="poitner-cursor"
              [ngClass]="{ selectedChip: isTagInclude(r) == true }"
              (click)="searchEvent(r)"
              matTooltip="Look for pages with similiar keyword"
            >{{ r }}</mat-chip
            >
          </mat-chip-list>
        </div>
      </mat-card>
    </div>
  </div>
</div>

<div class="clearfix full-width mt" *ngIf="!pages || pages.length == 0">
  <app-empty-list-message
    class="full-width"
    [title]="'No Result Found'"
    [message]="'We could not find any result for your search'"
  >
  </app-empty-list-message>
</div>
