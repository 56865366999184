import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { AppointmentUtilClass } from '../shared/appointmentUtil';

@Component({
  selector: 'app-appointment-description',
  templateUrl: './appointment-description.component.html',
  styleUrls: ['./appointment-description.component.css'],
})
export class AppointmentDescriptionComponent implements OnInit {
  @Input()
  appointment;

  @Input()
  message;

  @Input()
  isMerchant = false;

  @Input()
  isFullDetails = true;

  appointmentUtil = new AppointmentUtilClass();

  datePassed = false;

  constructor(private customDate?: CustomDatePipe) {}

  ngOnInit() {
    this.datePassed = this.appointmentUtil.isDatePass(this.appointment);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.datePassed = this.appointmentUtil.isDatePass(this.appointment);
  }
}
