import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardIntroModalComponent } from '../dashboard-intro-modal/dashboard-intro-modal.component';
import { DashbordOverViewComponent } from '../dashbord-over-view/dashbord-over-view.component';

const routes: Routes = [
  { path: 'dashboard-overview', component: DashbordOverViewComponent },
  { path: 'dashboard-intro-modal', component: DashboardIntroModalComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
