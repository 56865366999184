import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { Settings } from '../../../shared/types/settings';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { TreatmentService } from '../shared/treatment.service';
import { TreatmentTypeCreateEditComponent } from '../treatment-type-create-edit/treatment-type-create-edit.component';

import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { SideMenuService } from '../../../shared/services/side-menu.service';
import { treatmentUtil } from '../shared/treatmentUtil';
import { TreatmentCategoryViewComponent } from '../treatment-category-view/treatment-category-view.component';
import { TreatmentDocumentCreateComponent } from '../treatment-document-create/treatment-document-create.component';
import { TreatmentDocumentImportOverviewComponent } from '../treatment-document-import-overview/treatment-document-import-overview.component';
import { treatmentEditModalComponent } from '../treatment-edit-modal/treatment-edit-modal.component';
import { TreatmentIntroModalComponent } from '../treatment-intro-modal/treatment-intro-modal.component';
import { TreatmentTypeViewComponent } from '../treatment-type-view/treatment-type-view.component';

@Component({
  selector: 'app-type-list',
  templateUrl: './treatment-type-list.component.html',
  styleUrls: ['./treatment-type-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.5s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.5s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class TreatmentTypeListComponent implements OnInit {
  isPromoterOrAdmin = false;

  showDocList = false;
  showBrandList = false;
  showProductList = false;

  docListTitle;
  brandListTitle;
  productListTitle;

  selectedTypeID;

  treatmentUtil = new treatmentUtil();

  @Input()
  title = 'Item Codes';

  @Input()
  categoryID;

  @Input()
  canMultiSelect = false;

  isActiveFilter;

  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  utils = new UtilsClass();

  displayedColumns = [
    // 'list',
    'ProfileImage',
    'Label',
    'itemCode',
    'Category',
    'Brands',
    'Products',
    'Documents',
    'fieldColor',
    'IsActive',
    'Actions',
  ];
  filters = [];

  selectedIDs = [];

  searchVals = new FormControl();

  searchValList = ['Label', 'Category'];

  expandedRow: number;
  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  filteredSize = null;

  destroyEvent = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private treatmentService: TreatmentService,
    private dialog: MatDialog,
    private router: Router,
    private customDate: CustomDatePipe,
    private authenticationService: AuthenticationService
  ) {
    const ref = RootAppComponent.dialog.open(TreatmentIntroModalComponent, {
      width: '700px',
    });
    if (ref.componentInstance.showOnTreatPageLogin == true) {
      ref.close();
    } else {
      const sub = ref.componentInstance.closeModal.subscribe((data) => {
        ref.close();
      });
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      if (this.isPromoterOrAdmin == true) {
        this.canMultiSelect = true;
        this.displayedColumns = [
          // 'list',
          'ProfileImage',
          'Label',
          'itemCode',
          'Category',
          'Brands',
          'Products',
          'Documents',
          'IsActive',
          'fieldColor',
          'Actions',
        ];
      }

      if (this.canMultiSelect == true) {
        this.displayedColumns.unshift('selectCheckBox');
      } else if (this.isPromoterOrAdmin == false) {
        this.displayedColumns = [
          // 'list',
          'ProfileImage',
          'Label',
          'itemCode',
          'Category',
          'Brands',
          'Products',
          'Documents',
          'fieldColor',
          'Actions',
        ];
      }
    });

    const params = {
      categoryID: this.categoryID,
    };

    SideMenuService.goBack.subscribe((res) => {
      if (res == true) {
        this.hideViewDetails();
      }
    });

    this.listDB = new LoadRecords(this.treatmentService, this.destroyEvent, params, this.isPromoterOrAdmin);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  ngOnChanges(changes: SimpleChanges) {
    const params = {
      categoryID: this.categoryID,
    };

    this.listDB = new LoadRecords(this.treatmentService, this.destroyEvent, params, this.isPromoterOrAdmin);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  goToGuide() {
    const newWindow = window.open(
      'https://email-graphics-smileright.s3-ap-southeast-2.amazonaws.com/docs-19/Australian_Schedule-2.pdf',
      '_blank'
    );
  }

  goToShop() {
    const newWindow = window.open(
      'https://www.ada.org.au/Utility-Pages/Login?returnurl=/Utility-Pages/SSO?ssoreturnurl=https://ada.storehippo.com/',
      '_blank'
    );
  }

  setFilter(event, field) {
    let filter;

    if (field == 'ApprovedOrActive') {
      let _event;
      if (event == true) {
        _event = '1';
      } else {
        _event = '0';
      }
      filter = {
        field,
        value: _event,
      };
    } else if (typeof event == 'object' && event != null) {
      const v = this.customDate.transform(event, Settings.global['dateFormat']);
      filter = {
        field,
        value: v,
      };
    } else if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  selectAll() {
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      const o = this.dataSource.filteredData[i];

      if (o && o['ID'] && this.selectedIDs.indexOf(o['ID']) == -1) {
        this.selectedIDs.push(o['ID']);
      }
    }
  }

  updateGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const ref = RootAppComponent.dialog.open(treatmentEditModalComponent, {
        width: '600px',
        panelClass: 'noCard',
      });
      ref.componentInstance.result.subscribe((payload) => {
        if (payload) {
          if (this.selectedIDs && this.selectedIDs.length > 0) {
            const ids = this.selectedIDs.join(',');
            payload['ids'] = ids;

            this.treatmentService.editTreatmentTypeGroupGlobal(payload).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Types are updated.');
              }

              ref.close();
            });
          } else {
            ref.close();
          }
        } else {
          ref.close();
        }
      });
    }
  }

  viewCategory(id) {
    const ref = RootAppComponent.dialog.open(TreatmentCategoryViewComponent, {
      data: {
        categoryID: id,
      },

      width: '700px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewType(id) {
    const ref = RootAppComponent.dialog.open(TreatmentTypeViewComponent, {
      data: {
        typeID: id,
        isGlobal: true,
      },

      width: '700px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  selectPage() {
    const index = this.dataSource['_paginator']['index'] || 0;
    const pageSize = this.dataSource['_paginator']['pageSize'] || 0;

    for (let i = 0; i < (index + 1) * pageSize; i++) {
      const o = this.dataSource.filteredData[index * pageSize + i];

      if (o && o['ID'] && this.selectedIDs.indexOf(o['ID']) == -1) {
        this.selectedIDs.push(o['ID']);
      }
    }
  }

  select(id, isSelected) {
    if (isSelected == true && this.selectedIDs.indexOf(id) == -1) {
      this.selectedIDs.push(id);
    } else {
      const _index = this.selectedIDs.indexOf(id);
      this.selectedIDs.splice(_index, 1);
    }
  }

  unselect() {
    this.selectedIDs = [];
  }

  isSelected(id) {
    if (!this.selectedIDs || this.selectedIDs.length <= 0) {
      return false;
    } else {
      if (this.selectedIDs.indexOf(id) != -1) {
        return true;
      }
    }

    return false;
  }

  importDocument(typeID) {
    const ref = RootAppComponent.dialog.open(TreatmentDocumentImportOverviewComponent, {
      data: {
        readonly: false,
        displayUserDocuments: false,
        loadUserDocument: false,
        selectedView: 'system',
        displayUserDocumentType: false,
        displayICDocumentType: true,
        isICSideMenu: true,
        isUserSideMenu: false,
      },
      width: '90vw'
    });

    ref.componentInstance.getDocuments.subscribe((res) => {
      if (res && res.length > 0) {
        this.treatmentService.importDocumentTreatmentType(typeID, res).subscribe((res2) => {
          ref.close();
        });
      }
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  importDocumentGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const ref = RootAppComponent.dialog.open(TreatmentDocumentImportOverviewComponent, {
        data: {
          readonly: false,
          displayUserDocuments: false,
          loadUserDocument: false,
          selectedView: 'system',
          displayUserDocumentType: true,
          displayICDocumentType: true,
          isICSideMenu: true,
          isUserSideMenu: false,
        },
        width: '90vw'
      });

      ref.componentInstance.getDocuments.subscribe((res) => {
        if (res && res.length > 0) {
          this.treatmentService.importDocumentTreatmentTypeGroup(this.selectedIDs, res).subscribe((res2) => {
            ref.close();
          });
        }
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
    }
  }

  addDocument(typeID, categoryID) {
    const ref = this.dialog.open(TreatmentDocumentCreateComponent, {
      width: '750px',
      data: {
        typeID,
        categoryID,
      },
    });

    ref.componentInstance.create.subscribe((res) => {
      if (res) {
        ref.close();
      }
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  addDocumentGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialog = new ConfirmDialog(
        'cancel',
        'Add New Document',
        '<p>You are about to create a document for multiple treatment brands</p>',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const ref2 = this.dialog.open(TreatmentDocumentCreateComponent, {
              width: '750px',
              data: {
                multiCreate: true,
              },
            });

            ref2.componentInstance.getDocument.subscribe((payload) => {
              if (payload) {
                payload.ids = this.selectedIDs.join(',');

                this.treatmentService.addDocumentTreatmentTypeGroupGlobal(payload).subscribe((res) => {
                  if (res && res.length > 0) {
                    ref2.close();
                    this.selectedIDs = [];

                    NotifyAppComponent.displayToast('success', 'Success!', 'Document are added.');
                  }
                });
              }
            });

            ref2.componentInstance.close.subscribe((res) => {
              ref2.close();
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  enableGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialog = new ConfirmDialog(
        'check_circle',
        'Enable Selected Types',
        '<p>Once the Type is enabled it will  appear as an option for the user.</p>',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              isActive: true,
              ids: this.selectedIDs.join(','),
            };
            this.treatmentService.editTreatmentTypeGroupGlobal(payload).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Types are enabled.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  disableGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialog = new ConfirmDialog(
        'cancel',
        'Disable Selected Types',
        '<p>Once the Type is disabled it will not appear as an option for the user .</p>' +
          '<p>All sub types and brands will be disabled too .</p>',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              isActive: false,
              ids: this.selectedIDs.join(','),
            };
            this.treatmentService.editTreatmentTypeGroupGlobal(payload).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Types are disabled.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  getActiveLabel(a) {
    if (a == '1') {
      return 'True';
    }

    if (a == '0') {
      return 'False';
    }
  }

  enable(ID) {
    const confirmDialog = new ConfirmDialog(
      'check_circle',
      'Enable Type',
      '<p>Once the Type is enabled it will  appear as an option for the user.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            isActive: true,
          };
          this.treatmentService.editTypeGlobal(ID, payload).subscribe((res) => {
            if (res) {
              this.dataSource.replaceItem = res;

              if (res && res['Active'] == '0') {
                NotifyAppComponent.displayToast(
                  'warning',
                  'Warning',
                  'Type is not enabled because it Treatment Category is Disabled.'
                );
              } else {
                NotifyAppComponent.displayToast('success', 'Success!', 'Type is enabled.');
              }
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  disable(ID) {
    const confirmDialog = new ConfirmDialog(
      'cancel',
      'Disable Type',
      '<p>Once the Type is disabled it will not appear as an option for the user.</p>' +
        '<p>All sub brands will be disabled too .</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            isActive: false,
          };
          this.treatmentService.editTypeGlobal(ID, payload).subscribe((res) => {
            if (res) {
              this.dataSource.replaceItem = res;

              NotifyAppComponent.displayToast('success', 'Success!', 'Type is disabled.');
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  toNumber(n) {
    if (n) {
      return Number(n);
    } else {
      return 0;
    }
  }

  viewDocuments(id, label) {
    this.showDocList = true;
    this.selectedTypeID = id;
    this.docListTitle = 'Category Documents for: ' + label;
  }

  viewProducts(id, label) {
    this.showProductList = true;
    this.selectedTypeID = id;
    this.productListTitle = 'Treatment Products for: ' + label;
  }

  viewBrands(id, label) {
    this.showBrandList = true;
    this.selectedTypeID = id;
    this.brandListTitle = 'Treatment Brands for: ' + label;
  }

  hideViewDetails() {
    this.showBrandList = false;
    this.showDocList = false;
    this.showProductList = false;
  }

  add() {
    const ref = this.dialog.open(TreatmentTypeCreateEditComponent, {
      data: {},
      width: '800px',
      panelClass: 'noCard',
    });

    ref.componentInstance.result.subscribe((res) => {
      if (res) {
        this.dataSource.addItem = res;
        ref.close();
      }
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  edit(id) {
    if (id) {
      const ref = this.dialog.open(TreatmentTypeCreateEditComponent, {
        data: {
          typeID: id,
        },
        width: '800px',
        panelClass: 'noCard',
      });

      ref.componentInstance.result.subscribe((res) => {
        if (res) {
          this.dataSource.replaceItem = res;
          ref.close();
        }
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
    }
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  isDestoyed = false;
  serviceRef;

  constructor(private treatmentService: TreatmentService, private destroyEvent, params, private isPromoterOrAdmin) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    const payload = {
      isNullType: false,
      section: 0,
      orderBy: 'Dafault_Item_Code',
      fields:
        'ID,LastModified,Label,Active,Category.Label,Category.Colour,Category_key,Dafault_Item_Code,NumberOfBrands,NumberOfProducts,NumberOfDocuments',
    };

    if (params && params.categoryID) {
      payload['categoryID'] = params.categoryID;
    }

    if (this.isPromoterOrAdmin != true) {
      payload['isActive'] = true;
    }

    this.serviceRef = this.treatmentService.getTreatmentTypeList(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res && res.length > 0) {
        for (let i = 0; i < res.length; i++) {
          res[i]['fullLabel'] = res[i]['Label'] + ' ' + res[i]['Dafault_Item_Code'];
        }
      }

      this.count = res.length;
      this.items = res;

      this.dataChange.next(this.items.slice());
      this.firstLoad = true;

      const innerFunction = (section) => {
        const length = this.items.length;
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.treatmentService
          .getTreatmentTypeList(payload, this.isPromoterOrAdmin)
          .subscribe((res) => {
            if (res.length > 0) {
              for (let i = 0; i < res.length; i++) {
                res[i]['fullLabel'] = res[i]['Label'] + ' ' + res[i]['Dafault_Item_Code'];
              }

              this.count = res.count;
              this.items = this.items.concat(res);

              this.dataChange.next(this.items.slice());
              if (this.isDestoyed != true) {
                innerFunction(section);
              }
            } else {
              return true;
            }
          });
        UtilsClass.stopLoadingInterceptor();
      };

      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  set removeItem(id) {
    const data = this._tableDatabase.data.filter((row) => row.ID != id);
    this._tableDatabase.dataChange.next(data);
  }

  set addItem(res) {
    this._tableDatabase.data.unshift(res);

    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  set replaceItem(item) {
    const _index = this._tableDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    this._tableDatabase.data[_index] = item;
    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
          let f = '';

          f = item[this.field] || '';

          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'Label':
          [propertyA, propertyB] = [a['Label'], b['Label']];
          break;

        case 'itemCode':
          [propertyA, propertyB] = [a['Dafault_Item_Code'], b['Dafault_Item_Code']];
          break;

        case 'Category':
          [propertyA, propertyB] = [a['Category.Label'], b['Category.Label']];
          break;

        case 'IsActive':
          [propertyA, propertyB] = [a['Active'], b['Active']];
          break;

        case 'Types':
          [propertyA, propertyB] = [a['NumberOfTypes'], b['NumberOfTypes']];
          break;

        case 'Brands':
          [propertyA, propertyB] = [a['NumberOfBrands'], b['NumberOfBrands']];
          break;

        case 'Products':
          [propertyA, propertyB] = [a['NumberOfProducts'], b['NumberOfProducts']];
          break;

        case 'Documents':
          [propertyA, propertyB] = [a['NumberOfDocuments'], b['NumberOfDocuments']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
