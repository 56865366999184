import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../authentication/shared/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'admin-general-settings',
  templateUrl: './admin-general-settings.component.html',
  styleUrls: ['./admin-general-settings.component.css'],
})
export class AdminGeneralSettingsComponent implements OnInit {
  private color1 = '#1e88e5';
  private color2 = '#f44336';
  private color3 = '#ff5722';

  isAdmin = false;
  constructor(
    private authenticationService: AuthenticationService,

    private router: Router,

  ) { }

  ngOnInit() {


    this.authenticationService.isPromoterOrAdmin().subscribe(r => {

      this.isAdmin = r;
      if (r != true) {
        this.router.navigate([
          '/merchant',
          { outlets: { page: ['home'] } },
        ]);
      }
    })

  }

}
