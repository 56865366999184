<ipv-data-table
  [table]="summaryReport.table"
  [rename]="{
    treatmentValue: 'Treatment Value',
    dueAmount: 'Amount Due',
    typeCode: 'Code'
  }"
  [customComponents]="{
    treatmentValue: treatmentValue,
    dueAmount: dueAmount,
    typeCode: typeCode
  }"
  [formatHeaders]="{
    treatmentValue: 'right',
    dueAmount: 'right',
    typeCode: 'right'
  }"
>
</ipv-data-table>

<ng-template #treatmentValue let-record>
  <div *ngIf="record.treatmentValue > 0" style="text-align: right">
    {{ record.treatmentValue | invertNumber | customCurrency }}
  </div>
</ng-template>

<ng-template #dueAmount let-record>
  <div *ngIf="record.dueAmount !== 0" style="text-align: right">
    {{ record.dueAmount | invertNumber | customCurrency }}
  </div>
</ng-template>

<ng-template #typeCode let-record>
  <div style="text-align: right">{{ record.typeCode }}</div>
</ng-template>
