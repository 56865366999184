/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wiki-redirection.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./wiki-redirection.component";
import * as i3 from "@angular/router";
var styles_WikiRedirectionComponent = [i0.styles];
var RenderType_WikiRedirectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WikiRedirectionComponent, data: {} });
export { RenderType_WikiRedirectionComponent as RenderType_WikiRedirectionComponent };
export function View_WikiRedirectionComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_WikiRedirectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-wiki-redirection", [], null, null, null, View_WikiRedirectionComponent_0, RenderType_WikiRedirectionComponent)), i1.ɵdid(1, 114688, null, 0, i2.WikiRedirectionComponent, [i3.ActivatedRoute, i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WikiRedirectionComponentNgFactory = i1.ɵccf("app-wiki-redirection", i2.WikiRedirectionComponent, View_WikiRedirectionComponent_Host_0, { link: "link", param: "param" }, {}, []);
export { WikiRedirectionComponentNgFactory as WikiRedirectionComponentNgFactory };
