<app-ep-document-view-image
  class="full-width contact-v-p"
  [defaultPicture]="
    'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
  "

  [hideZoomButton]="hideZoomButton"
  [link]="profileLink"
>
</app-ep-document-view-image>
