<div class="row clearfix gridView" @fade>
  <div class="file-container">
    <mat-card class="card main-card-template">
      <div class="card-header primary-gradient-img clearfix inModal camps" *ngIf="displayHeader">
        <div class="row clearfix content">
          <div class="mr pull-left flex social-media-icon">
            <div class="full-width icon-social-media text-center">
              <mat-icon class="{{ icon }}  big-icon theme-color-second"></mat-icon>
            </div>
          </div>

          <div class="row titleArea clearfix">
            <h2 class="summary-header rm-m">
              {{ title }}

              <span class="subLabel full-width" *ngIf="description">{{ description }} </span>

              <span class="subLabel" *ngIf="templates && templates.length"
              >Available templates: <span class="badge">{{ templates.length }}</span></span
              >
            </h2>
          </div>
        </div>
      </div>

      <!-- filter controls -->
      <div class="row clearfix flex" *ngIf="templates && templates.length > 3">
        <mat-form-field class="full-width" *ngIf="templates.length >= 4" appearance="outline">
          <mat-label>{{ 'Filter Result' | translate }}</mat-label>
          <input
            [(ngModel)]="filter"
            matInput
            name="name"
            placeholder="{{ 'Filter Result' | translate }}"
            tabindex="1"
          />
        </mat-form-field>

        <mat-form-field class="half-width" appearance="outline">
          <mat-label>Tags</mat-label>

          <mat-select class="margin-btm" [(ngModel)]="selectedTagList" name="Tags" multiple placeholder="Tags">
            <mat-option *ngFor="let t of tagsList | orderBy" [value]="t">
              {{ t }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="full-width filter-control">
          <mat-button-toggle-group
            class="btn-group"
            *ngIf="templates.length >= 4"
            [(ngModel)]="orderByFilter"
            name="sortListSetting"
          >
            <mat-button-toggle class="text-center" [value]="'-DateTimeCreated'" matTooltip="Sort by last added">
              <mat-icon class="fa fa-history"></mat-icon> Recently added
            </mat-button-toggle>
            <mat-button-toggle class="text-center" [value]="'Label'" matTooltip="Sort alphabetically">
              <mat-icon class="fa fa-sort-alpha-down"></mat-icon> Sort by name
            </mat-button-toggle>
          </mat-button-toggle-group>

          <mat-checkbox
            class="ml"
            *ngIf="isIncludeSMSCheckbox == true"
            [checked]="mustSupportSms"
            (change)="supportSms($event)"
          >Includes SMS
          </mat-checkbox>
        </div>
      </div>

      <!-- promoter filter -->
      <div
        class="row clearfix"
        *ngIf="
          isAdminOrPromoter &&
          isMerchantCampaign == false &&
          isPatientCampaign == false &&
          social != true &&
          templates &&
          templates.length > 3
        "
      >
        <mat-button-toggle-group class="btn-group full-width" [(ngModel)]="tab">
          <mat-button-toggle
            class="text-center full-width"
            *ngFor="let t of tabs"
            [hidden]="
              (templates
                | FilterArrayMultipleContainValue: ['Tag']:t.with
                | FilterArrayMultipleNotContainValue: ['Tag']:t.without
                | lengthOfArray) <= 0
            "
            [value]="t"
          >
            <span
              [matBadge]="
                templates
                  | FilterArrayMultipleContainValue: ['Label', 'Tag']:filter
                  | FilterArrayMultipleValue: ['Supports.Email']:emailFilter
                  | FilterArrayMultipleValue: ['Supports.SMS']:smsFilter
                  | FilterArrayMultipleContainValue: ['Tag']:t.with
                  | FilterArrayMultipleNotContainValue: ['Tag']:t.without
                  | lengthOfArray
              "
              matBadgeColor="accent"
            >{{ t['label'] }}</span
            >
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div
        class="row full-width clearfix"
        *ngIf="
          isAdminOrPromoter &&
          isMerchantCampaign == false &&
          isPatientCampaign == false &&
          social == true &&
          templates &&
          templates.length > 3
        "
      >
        <mat-button-toggle-group class="btn-group full-width" [(ngModel)]="socialMediaType">
          <mat-button-toggle value=""> All</mat-button-toggle>

          <mat-button-toggle value="facebook">
            <mat-icon class="fab fa-facebook"></mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="twitter">
            <mat-icon class="fab fa-twitter"></mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="instagram">
            <mat-icon class="fab fa-instagram"></mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="linkedin">
            <mat-icon class="fab fa-linkedin"></mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <hr class="mb" *ngIf="templates && templates.length > 3"/>

      <div
        class="row clearfix text-center templateContainer flex flexwrap mb"
        *ngIf="templates"
        [@stagger]="templates.length"
        matchItemHeight="matchThis"
      >
        <mat-card
          class="template-preview file animate mat-elevation-z4"
          *ngFor="
            let t of templates
              | FilterArrayMultipleContainValue: ['Label', 'Tag']:filter
              | FilterArrayMultipleValue: ['Supports.Email']:emailFilter
              | FilterArrayMultipleContainValue: ['StaticList.Label']:selectedTagList
              | FilterArrayMultipleValue: ['Supports.SMS']:smsFilter
              | FilterArrayMultipleContainValue: ['Tag']:tab.with
              | FilterArrayMultipleContainValue: ['socialMediaPost']:socialMediaType
              | FilterArrayMultipleNotContainValue: ['Tag']:tab.without
              | OrderArrayObject: [orderByFilter]
          "
          [@fade]
          [class.selectedTemplate]="selectedTemplate && t['ID'] == selectedTemplate['ID']"
          (click)="actionEvent(t)"
          matRipple
        >
          <button
            class="preview-btn"
            [matMenuTriggerFor]="previewMenu"
            (click)="$event.stopPropagation()"
            mat-flat-button
            color="accent"
            matTooltip="Preview"
          >
            <mat-icon class="fas fa-search"></mat-icon>
          </button>

          <mat-menu #previewMenu="matMenu">
            <button
              *ngIf="t['Supports.Email'] == '1' && previewEmailButton == true"
              (click)="previewTemplateModal(t, 'email')"
              mat-menu-item
            >
              <mat-icon class="fas fa-envelope"></mat-icon>
              <span>Preview Email Template</span>
            </button>
            <button
              *ngIf="t['Supports.SMS'] == '1' && previewSMSButton == true"
              (click)="previewTemplateModal(t, 'sms')"
              mat-menu-item
            >
              <mat-icon class="fas fa-mobile-alt"></mat-icon>
              <span>Preview SMS Template</span>
            </button>
            <button
              *ngIf="(t['LandingPageURL'] || t['LandingPageCode']) && previewLandingPageButton == true"
              (click)="openLandingPage(t)"
              mat-menu-item
            >
              <mat-icon class="fas fa-external-link-alt"></mat-icon>
              <span>Go to Landing Page</span>
            </button>

            <button
              *ngIf="isAdminOrPromoter == true && editTemplateButton == true && t['Is_ManuallyCreated'] == '1'"
              (click)="Edit(t)"
              mat-menu-item
            >
              <mat-icon class="fas fa-edit"></mat-icon>
              <span>Edit Template</span>
            </button>

            <button
              *ngIf="isAdminOrPromoter == true && editSocialMediaButton == true"
              (click)="EditSocialMedia(t)"
              mat-menu-item
            >
              <mat-icon class="fas fa-share-alt-square"></mat-icon>
              <span>Edit Social media</span>
            </button>

            <button
              *ngIf="t && t['Tag'] && t['IsOfBusinessNature'] != '1' && viewSocialMediaButton == true"
              (click)="viewSocialMediaPosts(t['Tag'])"
              mat-menu-item
            >
              <mat-icon>insert_comment</mat-icon>
              <span>{{ 'View Social Media Posts' | translate }}</span>
            </button>

            <button *ngIf="sendTestEmail == true" (click)="testCampaign(t['Tag'])" mat-menu-item>
              <mat-icon>send</mat-icon>
              <span>{{ 'Send a test campaign' | translate }}</span>
            </button>
          </mat-menu>

          <div class="status-row icons-bar">
            <mat-icon class="fas fa-envelope" *ngIf="t['Supports.Email'] == '1'" matTooltip="Has an Email template">
            </mat-icon>
            <mat-icon class="fas fa-mobile-alt" *ngIf="t['Supports.SMS'] == '1'" matTooltip="Has a SMS template">
            </mat-icon>
            <mat-icon
              class="fas fa-external-link-square-alt"
              *ngIf="t['LandingPageURL'] || t['LandingPageCode']"
              matTooltip="Has a landing page"
            >
            </mat-icon>

            <mat-icon class="fab fa-facebook" *ngIf="t['Facebook_Post']"></mat-icon>
            <mat-icon class="fab fa-instagram" *ngIf="t['Instagram_Post']"></mat-icon>
            <mat-icon class="fab fa-linkedin" *ngIf="t['LinkedIn_Post']"></mat-icon>
            <mat-icon class="fab fa-twitter" *ngIf="t['Twitter_Post']"></mat-icon>
          </div>
          <div class="full-width cleafix image-container">
            <div
              class="template-header-tags full-width row clearfix matchThis"
              *ngIf="t['StaticList.Label'] && t['StaticList.Label'].length > 0"
            >
              <p
                class="small inner-tags text-center rm-mt rm-mb"
                *ngIf="t['StaticList.Label'] && t['StaticList.Label'].length > 0"
              >
                <span *ngFor="let d of t['StaticList.Label'] | orderBy"> #{{ d }} </span>
              </p>
            </div>
            <app-ep-document-view-image
              class="squareImg social-media-thumbnail cleafix"
              [hideZoomButton]="true"
              [defaultPicture]="
                'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/marketing-place-holder.png)'
              "
              [link]="getImageLink(t['ID'], t['LastModified'])"
            >
            </app-ep-document-view-image>
          </div>
          <div class="template-header row clearfix matchThis">
            <h3 class="sr-title rm-m">{{ t['Label'] }}</h3>
          </div>
        </mat-card>

        <div class="text-center full-width clearfix">
          <button
            class="text-center btn-large ml mr"
            *ngIf="displayLoadMore == true"
            (click)="loadMore()"
            mat-raised-button
            color="accent"
          >
            Load More Templates <mat-icon>more_horiz</mat-icon>
          </button>
        </div>
      </div>
      <div
        class="margin-div clearfix text-center mt mb"
        *ngIf="(!templates || (templates && templates.length <= 0)) && finishLoading == true"
      >
        <app-empty-list-message [title]="''" [message]="'No template found'"></app-empty-list-message>
      </div>
    </mat-card>
  </div>
</div>
