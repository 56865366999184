import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UtilsService } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-treatment-intro-modal',
  templateUrl: './treatment-intro-modal.component.html',
  styleUrls: ['./treatment-intro-modal.component.css'],
})
export class TreatmentIntroModalComponent implements OnInit {
  showOnTreatPageLogin = JSON.parse(localStorage.getItem('showOnTreatPageLogin'));

  @Output()
  closeModal = new EventEmitter();

  constructor(private utilService: UtilsService) {}

  ngOnInit() {
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        // this.isModuleTutorialsActive = access['isModuleTutorialsActive'];
      }
    });
  }

  doNotShow() {
    localStorage.setItem('showOnTreatPageLogin', JSON.stringify(this.showOnTreatPageLogin));

    this.closeModal.emit(this.showOnTreatPageLogin);
  }
}
