import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactCreateEditModalComponent } from '../contact-create-edit-modal/contact-create-edit-modal.component';
import { ContactLookupComponent } from '../contact-lookup/contact-lookup.component';
import { ContactCreateEditComponent } from '../contact-create-edit/contact-create-edit.component';
import { ContactViewComponent } from '../contact-view/contact-view.component';

const routes: Routes = [
  {
    path: 'ContactViewContactViewComponent',
    component: ContactViewComponent,
  },
  {
    path: 'ContactCreateEditModalContactCreateEditModalComponent',
    component: ContactCreateEditModalComponent,
  },
  {
    path: 'ContactLookupContactLookupComponent',
    component: ContactLookupComponent,
  },
  {
    path: 'cContactCreateEditContactCreateEditComponent',
    component: ContactCreateEditComponent,
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContactRoutingModule {}
