export class ConfirmDialog {
  confirmMessage: string;
  noMessage: string;
  yesMessage: string;
  title: string;
  icon: string;
  imageLink?: string;

  constructor(icon, title, confirmMessage, noMessage, yesMessage, imageLink = null) {
    this.icon = icon;
    this.title = title;
    this.confirmMessage = confirmMessage;
    this.noMessage = noMessage;
    this.yesMessage = yesMessage;
    this.imageLink = imageLink;
  }
}
