/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-product-form.modal.ts.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./components/retail-customer-product-form/retail-customer-product-form.component.ngfactory";
import * as i3 from "../../../../../shared/pipes/binary-bool/binary-bool.pipe";
import * as i4 from "./components/retail-customer-product-form/retail-customer-product-form.component";
import * as i5 from "@angular/material/dialog";
import * as i6 from "../../../shared/services/product-customer.service";
import * as i7 from "../../../../../core/app-state/app-state.service";
import * as i8 from "../../../../../shared/services/utils.service";
import * as i9 from "./components/dental-customer-product-form/dental-customer-product-form.component.ngfactory";
import * as i10 from "./components/dental-customer-product-form/dental-customer-product-form.component";
import * as i11 from "../../../../../shared/components/title-modal/title-modal.component.ngfactory";
import * as i12 from "../../../../../shared/components/title-modal/title-modal.component";
import * as i13 from "@angular/common";
import * as i14 from "./customer-product-form.modal.ts.component";
var styles_CustomerProductFormModalComponent = [i0.styles];
var RenderType_CustomerProductFormModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerProductFormModalComponent, data: {} });
export { RenderType_CustomerProductFormModalComponent as RenderType_CustomerProductFormModalComponent };
function View_CustomerProductFormModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ipv-retail-customer-product-form", [], null, null, null, i2.View_RetailCustomerProductFormComponent_0, i2.RenderType_RetailCustomerProductFormComponent)), i1.ɵprd(4608, null, i3.BinaryBoolPipe, i3.BinaryBoolPipe, []), i1.ɵdid(2, 245760, null, 0, i4.RetailCustomerProductFormComponent, [i5.MatDialogRef, i6.ProductCustomerService, i7.AppStateService, i8.UtilsService, i5.MatDialog], { existingProductCustomer: [0, "existingProductCustomer"], practice: [1, "practice"], formMode: [2, "formMode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.productCustomer; var currVal_1 = _co.practice; var currVal_2 = _co.formMode; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_CustomerProductFormModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ipv-retail-customer-product-form", [], null, null, null, i2.View_RetailCustomerProductFormComponent_0, i2.RenderType_RetailCustomerProductFormComponent)), i1.ɵprd(4608, null, i3.BinaryBoolPipe, i3.BinaryBoolPipe, []), i1.ɵdid(2, 245760, null, 0, i4.RetailCustomerProductFormComponent, [i5.MatDialogRef, i6.ProductCustomerService, i7.AppStateService, i8.UtilsService, i5.MatDialog], { existingProductCustomer: [0, "existingProductCustomer"], practice: [1, "practice"], formMode: [2, "formMode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.productCustomer; var currVal_1 = _co.practice; var currVal_2 = _co.formMode; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_CustomerProductFormModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-dental-customer-product-form", [], null, null, null, i9.View_DentalCustomerProductFormComponent_0, i9.RenderType_DentalCustomerProductFormComponent)), i1.ɵdid(1, 114688, null, 0, i10.DentalCustomerProductFormComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_CustomerProductFormModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "ipv-title-modal", [], null, null, null, i11.View_TitleModalComponent_0, i11.RenderType_TitleModalComponent)), i1.ɵdid(1, 114688, null, 0, i12.TitleModalComponent, [i5.MAT_DIALOG_DATA], { icon: [0, "icon"], classIcon: [1, "classIcon"], title: [2, "title"], hideAllDefaultButtons: [3, "hideAllDefaultButtons"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 8, "div", [["content", ""], ["style", "padding-top: 10px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, null, null, null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i13.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerProductFormModalComponent_1)), i1.ɵdid(6, 278528, null, 0, i13.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i13.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerProductFormModalComponent_2)), i1.ɵdid(8, 278528, null, 0, i13.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i13.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerProductFormModalComponent_3)), i1.ɵdid(10, 278528, null, 0, i13.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i13.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = null; var currVal_1 = null; var currVal_2 = _co.title; var currVal_3 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.industryType; _ck(_v, 4, 0, currVal_4); var currVal_5 = "retail"; _ck(_v, 6, 0, currVal_5); var currVal_6 = "breeze"; _ck(_v, 8, 0, currVal_6); var currVal_7 = "dental"; _ck(_v, 10, 0, currVal_7); }, null); }
export function View_CustomerProductFormModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-customer-product-form", [["class", "modal"]], null, null, null, View_CustomerProductFormModalComponent_0, RenderType_CustomerProductFormModalComponent)), i1.ɵdid(1, 49152, null, 0, i14.CustomerProductFormModalComponent, [i5.MAT_DIALOG_DATA], null, null)], null, null); }
var CustomerProductFormModalComponentNgFactory = i1.ɵccf("ipv-customer-product-form.modal", i14.CustomerProductFormModalComponent, View_CustomerProductFormModalComponent_Host_0, {}, {}, []);
export { CustomerProductFormModalComponentNgFactory as CustomerProductFormModalComponentNgFactory };
