import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AiScansListOverViewComponent } from '../ai-scans-list-overview/ai-scans-list-overview.component';
import { AiScansListGlobalComponent } from '../ai-scans-list-global/ai-scans-list-global.component';
import { AiScanCreateComponent } from '../ai-scan-create/ai-scan-create.component';
import { AiScanReportViewComponent } from '../ai-scan-report-view/ai-scan-report-view.component';

const routes: Routes = [
  {
    path: 'ai-scans-list-overview',
    component: AiScansListOverViewComponent
  },
  {
    path: 'ai-scans-list-global',
    component: AiScansListGlobalComponent
  },
  {
    path: 'ai-scan-create',
    component: AiScanCreateComponent
  },

  {
    path: 'ai-scan-report-view',
    component: AiScanReportViewComponent
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AiScansRoutingModule { }
