import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { ProductCalculatorComponent } from '../product-calculator/product-calculator.component';
// import { DragScroll } from "ngx-drag-scroll";
import { ProductService } from '../shared/product.service';

@Component({
  selector: 'app-suitable-product-compact-list',
  templateUrl: './suitable-product-compact-list.component.html',
  styleUrls: ['./suitable-product-compact-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('mat-card', style({ opacity: 0 }), { optional: true }),
        query(
          'mat-card',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('mat-card', style({ opacity: 1 }), { optional: true }),
        query(
          'mat-card',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class SuitableProductCompactListComponent implements OnInit {
  isModuleDiscountsActive = Settings.global['isModuleDiscountsActive'];

  // isModuleDiscountsActive = true;

  @Input() frequency = 1;

  @Input() selectTitle = 'Proceed & Apply ';

  @Input() amount;

  @Input() groupCode;

  @Input() showDisabled = false;

  @Input() isCold = false;

  @Input() merchantKey = null;

  @Input()
  proceedLabel = 'Select & Apply';

  @Input() displaySimulator = true;

  @Output() select = new EventEmitter();

  @Input()
  isModal = false;

  @Output()
  closeFinanceCalculator = new EventEmitter();
  options = [];
  product: any;
  termsAndConditions = [];
  terms: any;
  utils = new UtilsClass();

  sessionType;

  step = 0;
  indexArray = [];

  selectedIdx = -1;
  // @ViewChild("nav", { read: DragScroll })
  // ds: DragScroll;

  constructor(
    private utilService: UtilsService,
    private authenticationService: AuthenticationService,
    private productService: ProductService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  // moveLeft() {
  //   this.ds.moveLeft();
  // }

  // moveRight() {
  //   this.ds.moveRight();
  // }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((r) => {
      this.sessionType = r;

      this.utilService.getCurrentAccess().subscribe((access) => {
        if (access) {
          this.isModuleDiscountsActive = access['isModuleDiscountsActive'];
        }

        if (!this.groupCode || this.groupCode == '') {
          this.groupCode = 'GPF';
        }

        const payload = {
          frequency: this.frequency,
          amount: this.amount,
          merchantKey: this.merchantKey,
          fields: 'ID,Label.Marketing,Label.Web,BasedOnMaxDuration.Repayments.Weekly,SubType,Description',
          groupCode: this.groupCode || null,
          orderBy: '',
        };

        this.productService.getSuitableProduct(payload, this.isCold).subscribe((res) => {
          this.options = res;
          if (this.options.length <= 0) {
            this.select.emit(null);
            // NotifyAppComponent.displayToast(
            //   "warning",
            //   "No Products",
            //   "We do not have any products for the amount you've choosen, Please contact us for more details."
            // );
          }
        });
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.amount && !changes.amount.firstChange) || (changes.groupCode && !changes.groupCode.firstChange)) {
      const payload = {
        amount: this.amount,
        frequency: this.frequency,
        merchantKey: this.merchantKey,
        fields: 'ID,Label.Marketing,Label.Web,BasedOnMaxDuration.Repayments.Weekly,SubType,Description',
        groupCode: this.groupCode || null,
        orderBy: '',
      };

      this.productService.getSuitableProduct(payload, this.isCold).subscribe((res) => {
        this.options = res;

        if (this.options.length < 0) {
          NotifyAppComponent.displayToast(
            'warning',
            'No Products',
            "We do not have any products for the amount you've choosen, Please contact us for more details."
          );
        }
      });
    }
  }

  setStep(index: number, option: any) {
    this.step = index;
    let termsFound: any;
    this.termsAndConditions.forEach((item) => {
      if (item.step == this.step) {
        termsFound = item;
      }
    });

    if (option && !termsFound) {
      this.productService.getProdDetails(option.ID).subscribe((res) => {
        this.product = res;
        const terms = this.utils.formatTermsAndConditions2(this.product.TermsAndConditions);
        this.termsAndConditions.push({
          step: this.step,
          termsAndConditions: terms,
        });
        this.indexArray.push(this.step);
        this.terms = terms;
      });
    } else if (option) {
      this.terms = termsFound.termsAndConditions;
    }
  }

  smileRightProductsPage() {
    // window.open("https://www.smileright.com.au/products.html", "_blank");
    window.open('https://smileright.com.au/consumer/(page:products)', '_blank');
  }

  selectProduct(product) {
    this.select.emit(product);
  }

  simulateProduct(product) {
    const ref = this.dialog.open(ProductCalculatorComponent, {
      data: {
        dentalInfo: true,
        moreInfo: false,
        ProductID: product.ID,
        dynamicAmount: false,
        amount: this.amount,
      },
      width: '850px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  clickFlip(index): void {
    this.selectedIdx = index;
  }

  flipClose(index): void {
    this.selectedIdx = -1;
  }

  // clickFlip() {
  //     this.wasClicked= !this.wasClicked;
  // }
  newInvition() {
    if (this.groupCode) {
      this.closeFinanceCalculator.emit(true);
      this.router.navigate(['/merchant', { outlets: { page: ['new-invitation', this.groupCode, this.amount] } }]);
    }
  }
}
