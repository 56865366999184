import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { LookupService } from '../../../shared/services/lookup.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { ContactViewComponent } from '../contact-view/contact-view.component';
import { ContactService } from '../shared/contact.service';

@Component({
  selector: 'app-contact-create-edit',
  templateUrl: './contact-create-edit.component.html',
  styleUrls: ['./contact-create-edit.component.css'],
})
export class ContactCreateEditComponent implements OnInit {
  @Input()
  cardID;

  @Input()
  title = 'supplier';

  @Input()
  contactID;

  @Input()
  possibleTypes;

  @Output()
  closeEvent = new EventEmitter();

  isModal = false;
  isPromoterOrAdmin = false;

  contact = {
    cardID: null,
    firstName: null,
    title: null,
    lastName: null,
    midleName: null,
    gender: null,
    mobile: null,
    phone: null,
    email: null,
    unitNumber: null,
    streetNumber: null,
    streetName: null,
    streetType: null,
    suburb: null,
    state: null,
    postCode: null,
    latitude: null,
    longitude: null,
    contactTypeCode: null,
    fileID: null,
    isOperator: false,
    relativeTypeCode: null,
  };

  genders = [];
  titles = [];
  relativeTypes = [];

  validateMobile = false;
  validateEmail = false;
  validateAddress = false;
  validatePhone = false;

  isFile = false;
  isEdit = false;
  src;
  profileLink;

  contactCreated;
  types = [];
  isParameter = false;
  sessionType = 'merchant';

  @ViewChild('wizard', { static: true }) wizard: WizardComponent;

  constructor(
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private lookupService: LookupService,
    private contactService: ContactService,
    private location: Location,
    private utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['cardID']) {
        this.cardID = params['cardID'];
      }

      if (params['title']) {
        this.title = params['title'];
      }

      if (params['contactID']) {
        this.contactID = params['contactID'];
        this.isParameter = true;
      }

      this.authenticationService.getSessionType().subscribe((sessionType) => {
        if (sessionType) {
          this.sessionType = sessionType;
        }

        this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
          this.isPromoterOrAdmin = res;
          this.setup();
          if (this.cardID) {
            this.contact.cardID = this.cardID;
          }

          if (this.possibleTypes) {
            this.getTypes(this.possibleTypes);
          }

          if (this.contactID) {
            this.isEdit = true;
            this.existingContactSetup();
          }
        });
      });
    });
  }

  setup() {
    this.lookupService.getLookup('CodeLookup', 'Gender').subscribe((res) => {
      this.genders = res;
    });
    this.lookupService.getLookup('SimpleLookup', 'Salutation').subscribe((res) => {
      this.titles = res;
    });
    this.lookupService.getLookup('CodeLookup', 'RelativeType').subscribe((res) => {
      this.relativeTypes = res;
    });
  }

  existingContactSetup() {
    if (this.contactID) {
      this.getProfilePicture();

      this.contactService.getContactDetails(this.contactID, {}, this.sessionType).subscribe((res) => {
        if (res) {
          this.contact = {
            cardID: res['ContactForCard_key'],
            firstName: res['FirstName'],
            title: res['Salutation'],
            lastName: res['Name'],
            midleName: res['MiddleName'],
            gender: res['Gender.Code'],
            mobile: res['mobiles.Number'],
            phone: res['phones.Number'],
            email: res['emails.Email'],
            unitNumber: res['addresses.Unit'],
            streetNumber: res['addresses.Street Nr'],
            streetName: res['addresses.Street Name'],
            streetType: res['addresses.Street Type'],
            suburb: res['addresses.Suburb'],
            state: res['addresses.State'],
            postCode: res['addresses.Postcode'],
            latitude: res['Address.Latitude'],
            longitude: res['Address.Longitude'],
            contactTypeCode: res['ContactType.Code'],
            fileID: null,
            isOperator: false,
            relativeTypeCode: res['RelativeType.Code'],
          };
          this.getTypes(res['PossibleContactTypes']);
        }
      });
    }
  }

  getTypes(typeString) {
    if (typeString) {
      const _types = typeString.split(';');
      for (let i = 0; i < _types.length; i++) {
        const item = _types[i].split(':');
        this.types[i] = {
          Code: item[0],
          Label: item[1],
        };
      }
    }
  }

  closeModal() {
    this.closeEvent.emit(true);
  }

  createContact() {
    this.contactService.createContact(this.contact, this.sessionType).subscribe((res) => {
      if (res) {
        this.contactCreated = res;

        NotifyAppComponent.displayToast('success', 'Successful operation', 'Profile has been successfully created');
        this.wizard.goToNextStep();
      }
    });
  }

  editContact() {
    const payload = {
      cardID: this.contact.cardID,
      firstName: this.contact.firstName,
      title: this.contact.title,
      lastName: this.contact.lastName,
      midleName: this.contact.midleName || 'void',
      gender: this.contact.gender,
      mobile: this.contact.mobile,
      phone: this.contact.phone,
      email: this.contact.email,
      unitNumber: this.contact.unitNumber,
      streetNumber: this.contact.streetNumber,
      streetName: this.contact.streetName,
      streetType: this.contact.streetType,
      suburb: this.contact.suburb,
      state: this.contact.state,
      postCode: this.contact.postCode,
      latitude: this.contact.latitude,
      longitude: this.contact.longitude,
      contactTypeCode: this.contact.contactTypeCode,
      fileID: this.contact.fileID,
      isOperator: this.contact.isOperator,
      relativeTypeCode: this.contact.relativeTypeCode,
    };
    this.contactService.modifyContact(this.contactID, payload, this.sessionType).subscribe((res) => {
      if (res) {
        this.contactCreated = res;

        NotifyAppComponent.displayToast('success', 'Successful operation', 'Profile has been successfully updated');
        this.wizard.goToNextStep();
      }
    });
  }

  onCompleteAll(fileID) {
    if (fileID && fileID[0]) {
      this.contact.fileID = fileID[0];
      this.changeImage();
    }
  }

  changeImage() {
    this.utilsService.getFileDetails(this.contact.fileID).subscribe((res) => {
      if (res) {
        if (res.contentType == 'image/png' || res.contentType == 'image/jpg' || res.contentType == 'image/jpeg') {
          this.isFile = true;
          this.src = `${environment.nodeUrl}/files/file-view/${this.contact.fileID}`;

          NotifyAppComponent.displayToast('success', 'Successful operation', 'Profile has been successfully updated');
        } else {
          NotifyAppComponent.displayToast('warning', 'Error operation', 'Format of image should be png/jpg/jpeg');
        }
      }
    });
  }

  validateMobileEvent(e) {
    this.validateMobile = e;
  }

  validatePhoneEvent(e) {
    this.validatePhone = e;
  }

  validateEmailEvent(e) {
    this.validateEmail = e;
  }

  isAddressValidEvent(e) {
    this.validateAddress = e;
  }

  getMobile(e) {
    this.contact.mobile = e;
  }

  getPhone(e) {
    this.contact.phone = e;
  }

  getEmail(e) {
    this.contact.email = e;
  }

  getAddress(adr) {
    if (adr) {
      this.contact.suburb = adr.suburb;
      this.contact.state = adr.state;
      this.contact.postCode = adr.postCode;
      this.contact.streetNumber = adr.streetNumber;
      this.contact.streetName = adr.streetName;
      this.contact.unitNumber = adr.unitNumber;
      this.contact.streetType = adr.streetType;
      this.contact.latitude = adr.latitude;
      this.contact.longitude = adr.longitude;
    }
  }

  goBack() {
    this.location.back();
  }

  viewContact() {
    if (this.contactCreated && this.contactCreated['ID']) {
      const ref = RootAppComponent.dialog.open(ContactViewComponent, {
        data: this.contactCreated['ID'],
        width: '600px',
      });

      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  getProfilePicture() {
    this.profileLink = null;

    this.profileLink = this.contactService.getProfilePicStreamLink(this.contactID);
  }
}
