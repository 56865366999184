import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogSingleButtonComponent } from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { DentistViewModalComponent } from '../../dentist/dentist-view-modal/dentist-view-modal.component';
import { MerchantViewModalComponent } from '../../merchant/merchant-view-modal/merchant-view-modal.component';
import { AppointmentService } from '../shared/appointment.service';
import { AppointmentUtilClass } from '../shared/appointmentUtil';

import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-appointment-view',
  templateUrl: './appointment-view.component.html',
  styleUrls: ['./appointment-view.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.stacked-card-view', style({ opacity: 0 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.stacked-card-view', style({ opacity: 1 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class AppointmentViewComponent implements OnInit {
  @Input() appointmentID;

  @Input() closeOnProposeBooking = false;
  appointment;

  @Output()
  close = new EventEmitter();

  @Output()
  proposeBooking = new EventEmitter();

  @Output()
  updateAppointment = new EventEmitter();

  @Output()
  deleteAppointment = new EventEmitter();

  isPromoterOrAdmin;
  sessionType;
  dateNow = new Date();
  runAction = new EventEmitter();
  util = new UtilsClass();
  settings = Settings.global;
  // calEvent: CalendarEvent;

  appointmentUtil = new AppointmentUtilClass();

  PatientKeyword = 'KEYWORD.Patient';
  patientKeyword = 'KEYWORD.patient';
  PracticeKeyword = 'Keyword.Practice';
  practiceKeyword = 'KEYWORD.practice';

  constructor(
    private appointmentService: AppointmentService,
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private customDate: CustomDatePipe,
    private router: Router,
    private translatePipe: TranslatePipe,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      if (data.appointmentID) {
        this.appointmentID = data.appointmentID;
      }

      if (data.closeOnProposeBooking != null) {
        this.closeOnProposeBooking = data.closeOnProposeBooking;
      }

      // this.isModal = true;
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  ngOnInit() {
    this.PatientKeyword = this.translatePipe.transform(this.PatientKeyword);
    this.PatientKeyword = this.translatePipe.transform(this.PatientKeyword);
    this.PracticeKeyword = this.translatePipe.transform(this.PracticeKeyword);
    this.practiceKeyword = this.translatePipe.transform(this.practiceKeyword);

    this.authenticationService.isPromoterOrAdmin().subscribe(async (res) => {
      this.isPromoterOrAdmin = res;

      this.authenticationService.getSessionType().subscribe((sessionType) => {
        if (sessionType) {
          this.sessionType = sessionType;

          if (this.appointmentID) {
            this.appointmentService.getAppointmentDetails(this.appointmentID, sessionType).subscribe((res) => {
              this.appointment = res;
            });
          }
        }
      });
    });
  }

  openMerchantViewDialog() {
    const ref = RootAppComponent.dialog.open(MerchantViewModalComponent, {
      data: this.appointment['Merchant_key'],
      width: '550px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  openDentistViewDialog() {
    const ref = RootAppComponent.dialog.open(DentistViewModalComponent, {
      data: this.appointment['MerchantContact_key'],
      width: '550px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  gotoCustomerPage() {
    window.open(`merchant/(page:customer-profile/${this.appointment['CustomerOrProspect_Key']}/patient)`, '_blank');
  }

  runActionEvent(item, operation, isGroup = false) {
    if (item) {
      const p = {
        item,
        operation,
        isGroup,
      };
      if (this.closeOnProposeBooking && operation == 'proposeBooking') {
        this.proposeBooking.emit(p);
      } else if (this.closeOnProposeBooking && operation == 'reschedule') {
        this.proposeBooking.emit(p);
      } else {
        this.runAction.emit(p);
      }
    }
  }

  getActionResult(r) {
    if (r && r.ID) {
      this.appointment = r;
      this.updateAppointment.emit(r);
    }
  }

  publicPage(key) {
    if (key && this.settings && this.settings['consumerFrontendLink']) {
      window.open(this.settings['consumerFrontendLink'] + '/consumer/(page:appointment-card/' + key + ')', '_blank');
    }
  }

  showComment(title, comment) {
    if (title && comment) {
      const confirmDialog2 = new ConfirmDialogSingleButton('comment', title, comment, 'OKAY');

      const ref2 = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
        data: confirmDialog2,
      });

      ref2.componentInstance.onConfirm.subscribe((res) => {
        ref2.close();
      });
    }
  }

  removeAppointment(item) {
    const confirmDialogParams = {
      data: new ConfirmDialog(
        'fas fa-info',
        'Are you sure?',
        '<p>You are about to remove this appointment</p>',
        'No',
        'Yes'
      ),
    };
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, confirmDialogParams);
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        this.appointmentService.removeAppointment(item.ID, this.sessionType).subscribe((res) => {
          if (res) {
            this.deleteAppointment.emit(item.ID);
            this.closeEvent();
            NotifyAppComponent.displayToast('success', 'Remove appointment', 'Appointment is now removed');
          }
          ref.close();
        });
      } else {
        ref.close();
      }
    });
  }

  openInvitationViewDialog() {
    window.open(
      `merchant/(page:customer-profile/${this.appointment['CustomerOrProspect_Key']}/treatment/${this.appointment['Invitation_key']}))`,
      '_blank'
    );
  }

  gotoCampaignPage() {
    const confirmDialogParams = {
      data: new ConfirmDialog(
        'fas fa-info',
        'Are you sure you want to continue?',
        '<p>You will be redirected to the campaign page</p>',

        'No',
        'Yes'
      ),
    };
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, confirmDialogParams);
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        this.router.navigate([
          '/merchant',
          {
            outlets: {
              page: ['marketing-campaign-view', this.appointment['Campaign_Key']],
            },
          },
        ]);
        ref.close();
        ref.afterClosed().subscribe((res) => {
          this.closeEvent();
        });
      } else {
        ref.close();
      }
    });
  }
}
