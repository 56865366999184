import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { SessionsService } from '../../../core/session/shared/sessions.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { ProductService } from '../../product/shared/product.service';

export class AppointmentPageCustomizeComponentDialogData {
  currentPractice: any;
}

@Component({
  selector: 'app-appointment-page-customize',
  templateUrl: './appointment-page-customize.component.html',
  styleUrls: ['./appointment-page-customize.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({
                  opacity: 0,
                  transform: 'translateY(-75%)',
                  offset: 0,
                  height: 0,
                }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({
                  opacity: 1,
                  transform: 'translateY(0)',
                  offset: 1.0,
                  height: '*',
                }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({
                  opacity: 1,
                  transform: 'translateY(0)',
                  offset: 0,
                  height: '*',
                }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({
                  opacity: 0,
                  transform: 'translateY(-75%)',
                  offset: 1.0,
                  height: 0,
                }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class AppointmentPageCustomizeComponent implements OnInit, OnDestroy {
  isModal = false;
  loadFooter = false;
  settings = Settings.global;

  title = 'Request an Appointment';
  description =
    'Let your dentist know what time might suit you best for an appointment. The  practice will then contact you to arrange a suitable appointment date.';

  backgroundCode = 'default';

  backgroundFileID;
  backgroundImage;

  showContact = true;
  showHeader = true;
  showPracticeName = true;
  backgroundColor = '#1b8bdd';
  backgroundStyle;

  width = 650;
  height = 800;

  iframeFooterSrc;
  queryParams;
  iframeFooterCode;
  contactColor = '#ffffff';
  contactBackgroundColor = '#1b8bdd';

  currentPractice;

  // Background Image from session-over-view component
  allowedExtensionTypes = ['image'];
  practiceCoverLink;
  isNewPracticePictureCover = false;
  disableEditPractice = false;

  @Output()
  closeModal = new EventEmitter();

  // Returns the updated currentPractice
  @Output()
  editedCurrentPractice = new EventEmitter();

  // Private
  private _unsubscribeAll = new Subject<any>();

  constructor(
    private authenticationService: AuthenticationService,
    private productService: ProductService,
    private lookupService: LookupService,
    private _sanitizer: DomSanitizer,
    private router: Router,
    private SessionsService: SessionsService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: AppointmentPageCustomizeComponentDialogData
  ) {
    if (data) {
      this.isModal = true;

      // set currentPractice
      if (data.currentPractice) {
        this.currentPractice = data.currentPractice;
        this.updateFields(this.currentPractice);
      }
    }
  }

  ngOnInit(): void {
    // this.authenticationService.getCurrentUser().subscribe(res2 => {

    //   if (res2 && res2.success && res2.data.Role && (res2.data.Role == 'admin' || res2.data.Role == 'merchant-admin')) {
    //     this.disableEditPractice = false;
    //   }

    // });

    // From session-over-view
    this.getPracticePictureCover();
    AuthenticationService.updatePracticePictureCover.subscribe((res) => {
      if (res) {
        this.isNewPracticePictureCover = !this.isNewPracticePictureCover;
        this.getPracticeCoverTimer();
      }
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  closeEvent(): void {
    this.closeModal.emit(true);
  }

  onCompleteAll(fileID) {
    if (fileID && fileID[0]) {
      this.backgroundFileID = fileID[0];
      this.backgroundImage = `${environment.nodeUrl}/files/file-view/${this.backgroundFileID}`;
    }
  }

  onSave(): void {
    const payload = {
      appointmentPageURL: this.getAppointmentPageURL(),
    };

    this.authenticationService.editCurrentPractice(payload).subscribe((res) => {
      this.authenticationService.getCurrentPracticeForce().subscribe((res1) => {
        if (res && res1) {
          this.editedCurrentPractice.emit(res1);
          NotifyAppComponent.displayToast(
            'success',
            'Successful Operation',
            'You have successfully edited Appointment Page Look and Feel!'
          );
          this.closeModal.emit(true);
        }
      });
    });
  }

  onCompleteAllPracticeBackground(fileID) {
    if (fileID && fileID[0]) {
      const payload = {
        fileID: fileID[0],
      };
      this.SessionsService.uploadPracticeAppointmentCover(payload).subscribe((res) => {
        this.getPracticePictureCover();

        AuthenticationService.updatePracticePictureCover.emit(res);
      });
    }
  }

  updateFooterPreview() {
    const url = this.settings['publicSiteFrontendLink'] + '/' + this.getAppointmentPageURL() + '&' + 'footerOnly=true';
    this.iframeFooterSrc = this._sanitizer.bypassSecurityTrustResourceUrl(url);
    this.loadFooter = false;
  }

  onReset(): void {
    this.title = 'Request an Appointment';
    this.description =
      'Let your dentist know what time might suit you best for an appointment. The  practice will then contact you to arrange a suitable appointment date.';
    this.backgroundCode = 'default';
    this.backgroundFileID = null;
    this.backgroundImage = null;
    this.showContact = true;
    this.showHeader = true;
    this.backgroundColor = '#1b8bdd';
    this.backgroundStyle = null;
    this.contactColor = '#ffffff';
    this.contactBackgroundColor = '#1b8bdd';

    this.updateFooterPreview();
  }

  private getPracticePictureCover(): void {
    this.practiceCoverLink = null;
    this.practiceCoverLink = this.authenticationService.getPracticeAppointmentCoverStreamLink();
  }

  private getPracticeCoverTimer(): void {
    this.practiceCoverLink = null;
    let countdown = 0;
    const interval = 1000;
    const duration = 3 * 1000;
    const stream$ = Observable.timer(0, interval)
      .finally(() => {
        this.practiceCoverLink = this.authenticationService.getPracticeAppointmentCoverStreamLink();
      })
      .takeUntil(Observable.timer(duration + interval))
      .map((value) => duration - value * interval);
    stream$.subscribe((value) => (countdown = value));
  }

  // Link without the domain
  private getAppointmentPageURL(): string {
    const params = [];
    let queryParams;

    if (this.title) {
      params.push('pageTitle=' + encodeURI(this.title));
    }
    if (this.description) {
      params.push('pageDescription=' + encodeURI(this.description));
    }

    if (this.backgroundCode == 'default') {
      params.push('showBackground=true');
    } else if (this.backgroundCode == 'color' && this.backgroundColor) {
      params.push('backgroundColor=' + encodeURI(this.backgroundColor.replace('#', '')));
      params.push('showBackground=false');
    } else if (this.backgroundCode == 'customizedImage' && this.backgroundImage) {
      params.push('backgroundImage=' + encodeURI(this.backgroundImage));
      params.push('showBackground=false');
    } else if (this.backgroundCode == 'customizedStyle' && this.backgroundStyle) {
      params.push('backgroundStyle=' + encodeURI(this.backgroundStyle));
      params.push('showBackground=false');
    } else {
      params.push('showBackground=true');
    }

    if (this.showContact == true) {
      params.push('showContact=true');
    } else {
      params.push('showContact=false');
    }

    if (this.showHeader == true) {
      params.push('showHeader=true');
    } else {
      params.push('showHeader=false');
    }

    params.push(`showPracticeName=${this.showPracticeName}`);

    if (this.contactColor) {
      params.push('contactColor=' + encodeURI(this.contactColor.replace('#', '')));
    }

    if (this.contactBackgroundColor) {
      params.push('contactBackgroundColor=' + encodeURI(this.contactBackgroundColor.replace('#', '')));
    }

    if (params && params.length > 0) {
      queryParams = params.join('&');
      queryParams = 'appointment/' + this.currentPractice['ID'] + '?' + queryParams;
    } else {
      queryParams = 'appointment/' + this.currentPractice['ID'];
    }

    return queryParams;
  }

  private updateFields(currentPractice): void {
    // parse the url
    const url: string = currentPractice['AppointmentPageURL'] || '';

    const urlTree = this.router.parseUrl(url);
    const paramMap = urlTree.queryParamMap;

    // pageTitle
    const pageTitle = paramMap.get('pageTitle');
    if (pageTitle) {
      this.title = pageTitle;
    }

    // pageDescription
    const pageDescription = paramMap.get('pageDescription');
    if (pageDescription) {
      this.description = pageDescription;
    }

    // background params
    const showBackground = paramMap.get('showBackground');
    const backgroundColor = paramMap.get('backgroundColor');
    const backgroundStyle = paramMap.get('backgroundStyle');
    const backgroundImage = paramMap.get('backgroundImage'); // separate element

    if (showBackground === 'true') {
      this.backgroundCode = 'default';
    } else if (backgroundColor) {
      this.backgroundCode = 'color';
      this.backgroundColor = this.convertForColorPicker(backgroundColor);
    } else if (backgroundImage) {
      this.backgroundCode = 'customizedImage';
      this.backgroundImage = backgroundImage;
    } else if (backgroundStyle) {
      this.backgroundCode = 'customizedStyle';
      this.backgroundStyle = backgroundStyle;
    }

    // showContact
    const showContact = paramMap.get('showContact');
    if (showContact) {
      this.showContact = showContact === 'true' ? true : false;
    }

    // showHeader
    const showHeader = paramMap.get('showHeader');
    if (showHeader) {
      this.showHeader = showHeader === 'true' ? true : false;
    }

    // contactColor
    const contactColor = paramMap.get('contactColor');
    if (contactColor) {
      this.contactColor = this.convertForColorPicker(contactColor);
    }

    // contactBackgroundColor
    const contactBackgroundColor = paramMap.get('contactBackgroundColor');
    if (contactBackgroundColor) {
      this.contactBackgroundColor = this.convertForColorPicker(contactBackgroundColor);
    }

    this.updateFooterPreview();
  }

  onFrameLoad(e) {
    this.loadFooter = true;
  }

  private convertForColorPicker(color: string) {
    return color.search('rgb') === -1 ? '#' + color : color;
  }
}
