import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-appointment-lookup-list-overview',
  templateUrl: './appointment-lookup-list-overview.component.html',
  styleUrls: ['./appointment-lookup-list-overview.component.css'],
})
export class AppointmentLookupListOverviewComponent implements OnInit {
  isPromoterOrAdmin = false;
  _merchantID = null;
  @Input()
  merchantID = 'none';
  lookupType = 'AppointmentTypes';
  category;
  categorySelected = false;
  @Input()
  displayAdmin = true;

  @Input()
  pageSize = Settings.global['listPageSize'] || 20;

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      if (this.merchantID == 'none') {
        this.authenticationService.getCurrentPractice().subscribe((m) => {
          if (m && m.ID) {
            this.merchantID = m.ID;
          }
        });
      }
    });
  }

  getMerchant(d) {
    this._merchantID = d;
  }

  applySearch(result) {
    this.merchantID = result.merchantID;
  }

  selectCategory(c) {
    if (c) {
      this.categorySelected = true;
      this.category = c;
    }
  }

  goBack() {
    this.categorySelected = false;
    this.category = null;
  }
}
