<mat-card class="card">
  <div class="card-header">
    <h3 class="rm-mt rm-mb sr-title sm-h3">Your tip</h3>
    <p class="rm-mt">You can enable or disable individual tips at any time.</p>
    <hr/>
  </div>
  <mat-table #table [dataSource]="dataSource" matSort>
    <!-- ID Column -->
    <ng-container cdkColumnDef="priority">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Priority</mat-header-cell>
      <mat-cell *cdkCellDef="let row" (click)="viewTip(row._id)">
        <mat-form-field appearance="outline">
          <input
            id="cc-name"
            #input
            [value]="row.priority"
            (keyup)="onPriorityEnter(input.value, row)"
            type="text"
            matInput
          />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <!-- Surname Column -->

    <ng-container cdkColumnDef="active">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header></mat-header-cell>
      <mat-cell *cdkCellDef="let row" (click)="viewTip(row._id)">
        <mat-slide-toggle [(ngModel)]="row.isActive" (ngModelChange)="editIsActiveProp(row)" labelPosition="before">
        </mat-slide-toggle>
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="title">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Title</mat-header-cell>
      <mat-cell *cdkCellDef="let row" (click)="viewTip(row._id)">
        {{ row.title }}
      </mat-cell>
    </ng-container>

    <!-- Firstname Column -->
    <ng-container cdkColumnDef="content">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Content</mat-header-cell>
      <mat-cell *cdkCellDef="let row" (click)="viewTip(row._id)">
        <div class="tipContent" [innerHTML]="row.content | safeHtml"></div>
      </mat-cell>
    </ng-container>

    <!-- Source Column -->
    <ng-container cdkColumnDef="updated_at">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>Last Update</mat-header-cell>
      <mat-cell *cdkCellDef="let row" (click)="viewTip(row._id)">
        <span> {{ row.updated_at | TimeLabelPast }} ago</span>
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="Actions">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <button [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button *ngIf="row && row._id" (click)="viewTip(row._id)" mat-menu-item>
            <mat-icon>visibility</mat-icon>
            <span>View</span>
          </button>

          <button *ngIf="row && row._id" (click)="updateTipEvent(row._id)" mat-menu-item>
            <mat-icon>edit</mat-icon>
            <span>Edit</span>
          </button>

          <button (click)="onClickDeleteButton(row)" mat-menu-item>
            <mat-icon>delete</mat-icon>
            <span>Remove</span>
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>

    <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator
    #paginator
    [length]="dataSource.filteredData.length"
    [pageIndex]="0"
    [pageSize]="5"
    [pageSizeOptions]="[5, 10, 25, 100]"
  >
  </mat-paginator>
</mat-card>
