import { animate, keyframes, query, stagger, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { timer as observableTimer } from 'rxjs';
import { AuthenticationService } from '../../../../../../common/core/authentication/shared/authentication.service';
import { CustomerProspectUtilClass } from '../../../../../../common/feature/customer-prospect/shared/customerProspectUtil';
import { InvitationService } from '../../../../../../common/feature/invitation/shared/services/invitation.service';
import { InvitationUtilClass } from '../../../../../../common/feature/invitation/shared/types/invitation-util.class';
import { TipService } from '../../../../../../common/feature/tip/shared/tip.service';
import { RootAppComponent } from '../../../../../../common/shared/components/root-component/root-component.component';
import { VideoTutorialsModalComponent } from '../../../../../../common/shared/components/video-tutorials-modal/video-tutorials-modal.component';
import { HeaderService } from '../../../../../../common/shared/services/header.service';
import { SideMenuService } from '../../../../../../common/shared/services/side-menu.service';
import { UtilsService } from '../../../../../../common/shared/services/utils.service';
import { Settings } from '../../../../../../common/shared/types/settings';
import { UtilsClass } from '../../../../../../common/shared/types/utils/utils.class';
import { DashboardIntroModalComponent } from '../dashboard-intro-modal/dashboard-intro-modal.component';
import { OperatorChangePasswordComponent } from '../../../../../../common/feature/operator/operator-change-password/operator-change-password.component';
import { PasswordChangeComponent } from '../../../../../../common/core/authentication/password-change/password-change.component';

class Topic {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-dashbord-over-view',
  templateUrl: './dashbord-over-view.component.html',
  styleUrls: ['./dashbord-over-view.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('mat-card', style({ opacity: 0 }), { optional: true }),
        query(
          'mat-card',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 0 }),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('mat-card', style({ opacity: 1 }), { optional: true }),
        query(
          'mat-card',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
    trigger('labelAnimation', [
      transition('void => *', [
        query('label', style({ opacity: 0 }), { optional: true }),
        query(
          'label',
          stagger('100ms', [
            animate(
              '0.15s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateX(-30px)', offset: 0 }),
                style({ opacity: 1, transform: 'translateX(5px)', offset: 0.5 }),
                style({ opacity: 1, transform: 'translateX(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class DashbordOverViewComponent implements OnInit, OnDestroy, AfterViewInit {
  guid: any;
  notifications;

  promoterTools = false;

  isModuleFeeFundingActive = false;

  displayInvitation = false;
  displayPatients = false;
  displayMembership = false;

  displayActivePatients = false;
  isDisplayNotes = false;

  tableName = null;
  status;
  statisticInvitation;
  statisticActionLogs;
  statisticMembership;

  actionLogsLoaded = true;
  invitationMembershipLoaded = false;
  patientLoaded = true;
  invitationLoaded = false;
  setup = true;

  invitationUtil = new InvitationUtilClass();

  customerProspectUtil = new CustomerProspectUtilClass();

  statisticPatient = [];

  util = new UtilsClass();

  merchants = [];

  merchantID = null;
  dateFrom = null;
  dateTo = null;

  _merchantID = null;
  _dateFrom = null;
  _dateTo = null;

  context = Settings.global['context'];

  chartColors = Settings.global['chartColors'];
  @Output()
  getInvitationCount = new EventEmitter();

  @Output()
  getPatientCount = new EventEmitter();

  invitations = [];
  patients = [];

  tips: any[] = [];
  currentTip: any = ({} = {});
  currentTipIndex = 0;

  isModuleExtendedProductsActive = Settings.global['isModuleExtendedProductsActive'];
  isModuleTreatmentPlanActive = Settings.global['isModuleTreatmentPlanActive'];
  isModuleFacilityActive = Settings.global['isModuleFacilityActive'];
  isModuleMembershipActive = Settings.global['isModuleMembershipActive'];
  isModuleSettlementsActive = Settings.global['isModuleSettlementsActive'];
  isModuleTipsActive = Settings.global['isModuleTipsActive'];
  isModulePatientsActive = false;
  isModuleOverviewActive = Settings.global['isModuleOverviewActive'];
  isModulePerformanceActive = Settings.global['isModulePerformanceActive'];
  isModuleEmergercyActive = Settings.global['isModuleEmergercyActive'];
  isModuleNoteFeaturesActive = Settings.global['isModuleNoteFeaturesActive'];
  isModuleExperimentalFeaturesActive = Settings.global['isModuleExperimentalFeaturesActive'];

  showOnboarding = true;

  isModuleStarRatingActive = false;

  isPromoterOrAdmin = false;

  newColorSet = ['#00dc58', '#00c4d8', '#ffbc00', '#ff8d00', '#e30561', '#8e1368', '#EE1023'];

  stripe = false;

  totalActivities = 0;
  activityDateFrom;
  noteListGroupFilter = 'merchant';

  topics: Topic[] = [
    { value: '418752721', viewValue: 'Personalised Invitations' },
    { value: '418331841', viewValue: 'Treatment Templates' },
    { value: '355935655', viewValue: 'Cash Discounts' },
    { value: '356129743', viewValue: 'Cloning Invitations' },
    { value: '358964874', viewValue: 'Default Messages' },
    { value: '389155530', viewValue: 'Invitation templates' },
    { value: '362512520', viewValue: 'Welcome to your Patient Card' },
  ];

  defaultSelectedTopic = '';
  safeUrl: any;

  isDisplayMerchant = true;

  sendNull = true;
  selectedRange = 'days30';

  isSelectMerchantFromFilter = false;

  invitationStatusGroup = 'proceed';
  membershipStatusGroup = 'proceed';

  selectedValueBills: string;
  selectedEngagementValue: string;
  timerOut;

  timerOutCountDown;

  hideTaskPanel = false;

  selectedMerchantID;
  isSession = true;

  elevation = '';
  currentUser;
  protected readonly transition = transition;

  constructor(
    private activeRouter: ActivatedRoute,
    private utilService: UtilsService,
    private tipService: TipService,
    private router: Router,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private invitationService: InvitationService,
    private sanitizer: DomSanitizer
  ) {
    const ref = this.dialog.open(DashboardIntroModalComponent, {
      width: '650px',
    });

    if (ref.componentInstance.showOnDashLogin === true) {
      ref.close();
    } else {
      const sub = ref.componentInstance.closeModal.subscribe((data) => {
        ref.close();
      });
    }
  }

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe((res) => {
      if (res && res.data) {
        this.currentUser = res.data;
      }
      this.utilService.getCurrentAccess().subscribe((access) => {
        if (access) {
          this.isModuleExtendedProductsActive = access['isModuleExtendedProductsActive'];
          this.isModuleTreatmentPlanActive = access['isModuleTreatmentPlanActive'];
          this.isModuleFacilityActive = access['isModuleFacilityActive'];
          this.isModuleMembershipActive = access['isModuleMembershipActive'];
          this.isModuleSettlementsActive = access['isModuleSettlementsActive'];
          this.isModuleTipsActive = access['isModuleTipsActive'];
          this.isModuleOverviewActive = access['isModuleOverviewActive'];
          this.isModulePerformanceActive = access['isModulePerformanceActive'];
          this.isModuleEmergercyActive = access['isModuleEmergercyActive'];

          this.isModuleNoteFeaturesActive = access['isModuleNoteFeaturesActive'];
          this.isModuleExperimentalFeaturesActive = access['isModuleExperimentalFeaturesActive'];
        }

        this.activeRouter.params.subscribe((param) => {
          if (param['videoID']) {
            const ref = RootAppComponent.dialog.open(VideoTutorialsModalComponent, {
              data: {
                isModal: true,
                videoID: param['videoID'],
              },
              width: '650px',
            });

            ref.componentInstance.closeModal.subscribe((res) => {
              if (res === true) {
                ref.close();
              }
            });
          }
          if (
            param['redirection'] &&
            param['redirection'] == 'initial' &&
            this.currentUser &&
            this.currentUser['operatorID']
          ) {
            const ref = RootAppComponent.dialog.open(PasswordChangeComponent, {
              data: {
                title: 'Change Password',
                isModal: true,
                canClose: false,
              },
              width: '650px',
              panelClass: 'noCard',
              hasBackdrop: false,
            });
            ref.backdropClick().subscribe((res) => {});

            ref.componentInstance.close.subscribe((res) => {
              if (res == true) {
                ref.close();
              }
            });
          }
        });

        SideMenuService.goBack.subscribe((res) => {
          if (res === true) {
            this.goBack();
          }
        });
        this.authenticationService.getCurrentUser().subscribe((res) => {
          this.activityDateFrom = res.data.last_login;
        });

        this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
          this.isPromoterOrAdmin = res;
          this.promoterTools = res;

          if (this.isPromoterOrAdmin === true) {
            this.showOnboarding = false;
            const _mID = JSON.parse(localStorage.getItem('selectedMerchant'));
            if (_mID) {
              this.merchantID = _mID;
              this.selectedMerchantID = _mID;
              this.isSelectMerchantFromFilter = true;
              this.applySearch();
            }

            HeaderService.getPromoterViewAsObject().subscribe((obj) => {
              if (obj && obj['promoterViewAs'] && obj['promoterViewAs'] === 'merchant') {
                this.isSession = false;
                this.isDisplayMerchant = false;
                this.promoterTools = false;
                this.merchantID = obj.merchantID;
                this.selectedMerchantID = obj.merchantID;
                this.showOnboarding = true;

                this.isModuleExtendedProductsActive = obj['isModuleExtendedProductsActive'];
                this.isModuleTreatmentPlanActive = obj['isModuleTreatmentPlanActive'];
                this.isModuleFacilityActive = obj['isModuleFacilityActive'];
                this.isModuleMembershipActive = obj['isModuleMembershipActive'];
                this.isModuleSettlementsActive = obj['isModuleSettlementsActive'];
                this.isModuleTipsActive = obj['isModuleTipsActive'];
                this.isModulePatientsActive = obj['isModulePatientsActive'];
                this.isModuleOverviewActive = obj['isModuleOverviewActive'];
                this.isModulePerformanceActive = obj['isModulePerformanceActive'];
                this.isModuleEmergercyActive = obj['isModuleEmergercyActive'];

                this.isModuleNoteFeaturesActive = obj['isModuleNoteFeaturesActive'];
                this.isModuleExperimentalFeaturesActive = obj['isModuleExperimentalFeaturesActive'];
              } else if (obj && obj['promoterViewAs'] && obj['promoterViewAs'] === 'promoter') {
                this.isSession = true;
                this.isDisplayMerchant = true;
                this.promoterTools = true;
                this.merchantID = null;
                this.selectedMerchantID = null;
                this.showOnboarding = false;
              }
            });
          }
          setTimeout(() => {
            this.tipService.getTipsHidden().subscribe(
              (response: any) => {
                if (response && response.length > 0) {
                  this.tips = response;
                  this.currentTip = this.tips[0];
                  this.currentTipIndex = 0;

                  if (this.tips.length > 1) {
                    this.timerOut = observableTimer(20000, 20000);

                    this.timerOutCountDown = this.timerOut.subscribe(() => {
                      this.nextTip();
                    });
                  }
                }
              },
              (err: any) => {}
            );
          }, 500);
        });

        this.invitationStatusGroup = 'proceed';
        this.membershipStatusGroup = 'proceed';
        this.selectedValueBills = 'billsChart1';
        this.selectedEngagementValue = 'engagementChart1';
      });

      this.defaultSelectedTopic = this.topics[3].value;
      this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        'https://player.vimeo.com/video/' + this.defaultSelectedTopic + '?byline=0&portrait=0'
      );
    });
  }

  ngOnDestroy() {
    if (this.tips.length > 1) {
      this.timerOutCountDown.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    setTimeout((_) => {
      window.dispatchEvent(new Event('resize'));
    }, 10);
  }

  nextTip() {
    this.currentTipIndex = (this.currentTipIndex + 1) % this.tips.length;
    this.currentTip = this.tips[this.currentTipIndex];
  }

  previousTip() {
    if (this.currentTipIndex === 0) {
      this.currentTipIndex = this.tips.length;
    }
    this.currentTipIndex = (this.currentTipIndex - 1) % this.tips.length;
    this.currentTip = this.tips[this.currentTipIndex];
  }

  getMerchant(d) {
    this._merchantID = d;
  }

  getDateFrom(d) {
    this._dateFrom = d;
  }

  getDateTo(d) {
    this._dateTo = d;
  }

  getResult(r) {
    this.merchantID = r.merchantID;
    this.dateFrom = r.dateFrom;
    this.dateTo = r.dateTo;
    if (r.dateRange) {
      this.activityDateFrom = this.dateFrom;
    }
    if (this.isPromoterOrAdmin === true && r.isChangeMerchant === true) {
      this.isSelectMerchantFromFilter = r.isChangeMerchant;
      this.applySearch();
    } else if (this.isPromoterOrAdmin === true && r.isChangeMerchant === false && this.setup === true) {
      this.setup = false;
      this.applySearch();
    } else if (this.isPromoterOrAdmin === false) {
      this.applySearch();
    }
  }

  applySearch() {
    let _merchant = null;
    if (this.merchantID && this.merchantID !== 'none') {
      _merchant = this.merchantID;
    }
    const payloadInvitation = {
      merchantID: _merchant,
      dateFrom: this.util.EPdateFormat(this.dateFrom),
      dateTo: this.util.EPdateFormat(this.dateTo),
      selectProduct: false,
      selectYears: false,
      selectDays: false,
      selectMonths: false,
      selectDentist: false,
      selectMerchant: false,
      selectStatus: true,
    };

    setTimeout(() => {
      this.invitationService.getStatistics(payloadInvitation, this.isPromoterOrAdmin, false, true).subscribe((res) => {
        if (res) {
          this.statisticInvitation = res;
        }

        this.invitationLoaded = true;
      });
    }, 1000);
  }

  goBack() {
    this.displayInvitation = false;
    this.displayPatients = false;
    this.displayMembership = false;

    this.displayActivePatients = false;

    this.isDisplayNotes = false;
  }

  displayInvitations(status) {
    this.status = status;
    this.displayPatients = false;
    this.displayMembership = false;
    this.displayInvitation = true;
  }

  displayMembershipList(status) {
    this.status = status;
    this.displayPatients = false;
    this.displayMembership = true;
    this.displayInvitation = false;
  }

  displayPatientsList(tableName) {
    if (this.isPromoterOrAdmin === true && !this.merchantID) {
      this.router.navigate(['/merchant', { outlets: { page: ['customer-prospect-list-overview'] } }]);
    }
    this.tableName = tableName;
    this.displayInvitation = false;
    this.displayPatients = true;
    this.displayMembership = false;
  }

  newPatientInv() {
    this.invitationService.getGUID().subscribe((res) => {
      this.guid = res;
      this.router.navigate(['/merchant', { outlets: { page: ['new-invitation'] } }]);
    });
  }

  selectionChangedBilling(item) {
    this.selectedValueBills = item.value;
  }

  selectionEngagementChanged(item) {
    this.selectedEngagementValue = item.value;
  }

  displayNotes() {
    this.isDisplayNotes = true;
    this.displayPatients = false;
    this.displayMembership = false;
    this.displayInvitation = false;
  }

  cancelDisplayNotes() {
    this.isDisplayNotes = false;
    this.displayPatients = false;
    this.displayMembership = false;
    this.displayInvitation = false;

    this.dateTo = null;
    this.dateFrom = null;
  }

  getNoteListGroupFilter(e) {
    this.noteListGroupFilter = e;
  }

  toNumer(e) {
    return Number(e);
  }

  hideTaskPanelEvent(e) {
    this.hideTaskPanel = e;
  }
}
