import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';

@Component({
  selector: 'app-tags-list-overview',
  templateUrl: './tags-list-overview.component.html',
  styleUrls: ['./tags-list-overview.component.css'],
})
export class TagsListOverviewComponent implements OnInit {
  @Input()
  inputMerchantID;

  @Input()
  hideFilter = false;

  isPromoterOrAdmin = false;

  merchantID = 'none';

  @Input()
  staticAudience = false;

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      if (this.isPromoterOrAdmin == true && this.inputMerchantID) {
        this.merchantID = this.inputMerchantID;
      } else {
        this.authenticationService.getCurrentPractice().subscribe((res) => {
          if (res && res['ID']) {
            this.merchantID = res['ID'];
          }
        });
      }
    });
  }

  applySearch(result) {
    this.merchantID = result.merchantID;
  }

  getMerchant(e) {
    this.merchantID = e;
  }
}
