import { Location } from '@angular/common';
import { Component, Input, ViewChildren } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppStateService } from '../../../core/app-state/app-state.service';
import { AppState } from '../../../core/app-state/app-state.type';
import { Beneficiary, BeneficiaryService } from '../shared/beneficiary.service';

@Component({
  selector: 'app-beneficiary-catalogue',
  templateUrl: './beneficiary-catalogue-view.component.html',
  styleUrls: ['./beneficiary-catalogue-view.component.css'],
})
export class BeneficiaryCatalogueViewComponent {
  @Input() supplierID;

  @ViewChildren('sidenav') sidenav: MatSidenav;
  navMode: 'over' | 'push' | 'side' = 'side';
  openFlag = true;

  isAdminOrPromoter = false;

  productLength = 0;
  documentLength = 0;
  subLength = 0;
  contactLength = 0;

  isFetchingInitialData = true;

  viewModel$ = this.appStateService.getAppState$().pipe(
    switchMap((appState) =>
      this.getFilters$().pipe(
        switchMap(([supplierID]) =>
          this.fetchData$({ supplierID }).pipe(
            switchMap(([beneficiary]) => this.getData$({ beneficiary })),
            switchMap(([beneficiary]) =>
              this.getViewModel$({
                appState,
                beneficiary,
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private beneficiaryService: BeneficiaryService,
    private activeRoute: ActivatedRoute,
    private location: Location,
    private appStateService: AppStateService
  ) {}

  getFilters$() {
    return combineLatest([this.getSupplierIDFilter$()]);
  }

  getSupplierIDFilter$() {
    return this.activeRoute.params.pipe(map((routeParams) => routeParams['supplierID'] as string));
  }

  fetchData$(parameters: { supplierID: string }) {
    const { supplierID } = parameters;
    return combineLatest([this.beneficiaryService.fetchBeneficiary$<Beneficiary>({ supplierID })]);
  }

  getData$(parameters: { beneficiary: Beneficiary }) {
    const { beneficiary } = parameters;
    this.beneficiaryService.setBeneficiary(beneficiary);
    return combineLatest([this.beneficiaryService.getBeneficiary$<Beneficiary>()]);
  }

  getViewModel$(parameters: { appState: AppState; beneficiary: Beneficiary }) {
    const { appState, beneficiary } = parameters;
    this.isFetchingInitialData = false;
    const { user, practice, sessionType, isPromoterOrAdmin, dentist, publicSettings } = appState;
    const result = {
      user,
      practice,
      sessionType,
      isPromoterOrAdmin,
      dentist,
      beneficiary,
      industryType: publicSettings.industryType,
    };

    return of(result);
  }

  goBack() {
    this.location.back();
  }

  getDocumentLength(s) {
    if (s) {
      this.documentLength = s;
    }
  }

  getProductLength(s) {
    if (s) {
      this.productLength = s;
    }
  }

  getContactLength(s) {
    if (s) {
      this.contactLength = s;
    }
  }

  getSubLength(s) {
    if (s) {
      this.subLength = s;
    }
  }
}
