import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { CustomPhonePipe } from '../../pipes/custom-phone.pipe';
import { UserDataService } from '../../services/user-data.service';
import { UtilsService } from '../../services/utils.service';
import { ConfirmDialog } from '../../types/confirm-dialog';
import { NotifyAppComponent } from '../../types/notify-app-component';
import { Settings } from '../../types/settings';
import { UtilsClass } from '../../types/utils/utils.class';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.css'],
  providers: [CustomPhonePipe],
})
export class PhoneInputComponent implements OnInit {
  util = new UtilsClass();

  @Input() label = 'Phone Number';

  @Input() prefixLabel = null;

  @Input() required = true;

  @Input() userDataName = null;

  @Input() phoneNumber = null;

  @Input() isLandLine = false;

  @Input() isDisabled = false;

  @Output() validate = new EventEmitter();

  @Output() getPhone = new EventEmitter();
  changeOnce = false;

  @Input()
  clear = new EventEmitter();

  invitationID = null;

  isStrictMode = Settings.global['isKleberPhoneStrictMode'];
  prefix =
    this.isLandLine == false ? Settings.global['defaultMobilePhonePrefix'] : Settings.global['defaultLandPhonePrefix'];

  isDynamicPrefix = Settings.global['dynamicPhonePrefix'];
  validator = Settings.global['phoneValidator'];
  random1 = 'telephone' + String(Math.random()).substring(3, 9);
  random2 = 'telephone' + String(Math.random()).substring(3, 9);
  selectedPhoneNumber;
  selectedPrefix;
  isValidate;

  sessionType;

  constructor(
    private dialog: MatDialog,
    private activeRoute: ActivatedRoute,
    private customPhone: CustomPhonePipe,
    private userDataService: UserDataService,
    private utilSevice: UtilsService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.authenticationService.getSessionType().subscribe((r) => {
      this.sessionType = r;

      this.applyFormat();

      this.isInputValidate(false).then((res) => {
        if (res == true) {
          this.isValidate = true;
          this.validate.emit(true);
          if (this.selectedPhoneNumber) {
            this.getPhone.emit(this.selectedPrefix + '-' + this.selectedPhoneNumber);
          } else {
            this.getPhone.emit(null);
          }
        } else {
          this.isValidate = false;
          this.validate.emit(false);
        }
      });
    });
  }

  ngOnInit() {
    if (this.clear) {
      this.clear.subscribe((res) => {
        this.selectedPhoneNumber = null;
        this.phoneNumber = null;
        this.selectedPrefix = null;
        this.sendResult();
      });
    }

    this.authenticationService.getSessionType().subscribe((r) => {
      this.sessionType = r;

      this.applyFormat();

      if (this.userDataName) {
        this.activeRoute.params.subscribe((params) => {
          if (params['consumerID']) {
            this.invitationID = params['consumerID'];
          } else if (params['invitationID']) {
            this.invitationID = params['invitationID'];
          }

          if (this.invitationID) {
            this.userDataService
              .getUserDataByFields(this.invitationID, [this.userDataName], this.sessionType)
              .subscribe((res) => {
                if (res) {
                  this.phoneNumber = res[this.userDataName];

                  this.applyFormat();
                }

                this.isInputValidate(false).then((res1) => {
                  if (res1 == true) {
                    this.isValidate = true;
                    this.validate.emit(true);
                    if (this.selectedPhoneNumber) {
                      this.getPhone.emit(this.selectedPrefix + '-' + this.selectedPhoneNumber);
                    } else {
                      this.getPhone.emit(null);
                    }
                  } else {
                    this.isValidate = false;
                    this.validate.emit(false);
                  }
                });
              });

            this.userDataService.userDataEvent.subscribe((data) => {
              if (data && data.content && this.util.selectObjectByString(data.content, this.userDataName)) {
                this.phoneNumber = this.util.selectObjectByString(data.content, this.userDataName);

                this.applyFormat();

                this.sendResult(false, false);
              } else if (
                data &&
                data.content &&
                this.util.selectObjectByString(data.content, this.userDataName) === null
              ) {
                this.selectedPhoneNumber = null;
                this.phoneNumber = null;

                this.sendResult(false, false);
              }
            });
          }
        });
      } else {
        this.isInputValidate(false).then((res) => {
          if (res == true) {
            this.isValidate = true;
            this.validate.emit(true);
            if (this.selectedPhoneNumber) {
              this.getPhone.emit(this.selectedPrefix + '-' + this.selectedPhoneNumber);
            } else {
              this.getPhone.emit(null);
            }
          } else {
            this.isValidate = false;
            this.validate.emit(false);
          }
        });
      }
    });
  }

  applyFormat() {
    if (this.phoneNumber && this.phoneNumber != 'undefined' && this.phoneNumber != 'Undefined') {
      const format = this.formatPhone(this.phoneNumber);

      this.selectedPhoneNumber = null;
      if (format && format['phone']) {
        this.selectedPhoneNumber = format['phone'];
      }

      this.selectedPrefix = null;
      if (format && format['prefix']) {
        this.selectedPrefix = format['prefix'];
      }
    } else {
      this.selectedPhoneNumber = null;

      let defaultPrefix = Settings.global['defaultMobilePhonePrefix'];

      if (this.isLandLine == true) {
        defaultPrefix = Settings.global['defaultLandPhonePrefix'];
      }
      this.selectedPrefix = defaultPrefix;
    }
  }

  formatPhone(expression) {
    let result;

    let defaultPrefix = Settings.global['defaultMobilePhonePrefix'];

    if (this.isLandLine == true) {
      defaultPrefix = Settings.global['defaultLandPhonePrefix'];
    }

    let phoneFromat = '';
    let parts = [];
    let phoneNumber;
    let prefix = null;
    try {
      if (!expression || expression === 'undefined') {
        result = {
          phone: null,
          prefix: defaultPrefix,
        };

        return result;
      }

      phoneFromat = Settings.global['phoneFormat'];

      parts = expression.split('-');

      if (parts.length == 3) {
        prefix = parts[1];
        phoneNumber = parts[2];
      } else if (parts.length == 2) {
        prefix = parts[0];
        phoneNumber = parts[1];
      } else {
        phoneNumber = parts[0];
      }

      if (phoneNumber.length == 9) {
        phoneNumber = '0' + phoneNumber;
      } else if (phoneNumber.length > 10) {
        prefix = phoneNumber.substr(0, phoneNumber.length - 9);
        phoneNumber = '0' + phoneNumber.substr(phoneNumber.length - 9, phoneNumber.length);

        if (prefix.length > 0 && prefix[0] == '0') {
          prefix = prefix.slice(1, prefix.length);
        }

        if (prefix.length > 0 && prefix[0] == '0') {
          prefix = prefix.slice(1, prefix.length);
        }

        if (prefix.length > 0 && prefix[0] == '0') {
          prefix = prefix.slice(1, prefix.length);
        }

        if (prefix && prefix.length <= 0) {
          prefix = null;
        }
      }

      if (!prefix) {
        prefix = defaultPrefix;
      }

      result = {
        phone: phoneNumber,
        prefix,
      };

      return result;
    } catch (e) {
      result = {
        phone: expression,
        prefix: defaultPrefix,
      };
    }
  }

  isInputValidate(validateKleber = true): Promise<any> {
    return new Promise((resolve) => {
      try {
        if (this.required) {
          if (this.isDynamicPrefix == true && !this.selectedPrefix) {
            throw false;
          } else if (this.isDynamicPrefix == false && !this.selectedPhoneNumber) {
            throw false;
          }
        }

        if (!Number.isInteger(Number(this.selectedPhoneNumber))) {
          throw false;
        }

        if (
          this.selectedPhoneNumber &&
          this.isLandLine == true &&
          this.selectedPhoneNumber.length == 6 &&
          this.validator != 'kleber'
        ) {
          resolve(true);
        } else if (
          this.selectedPhoneNumber &&
          (this.selectedPhoneNumber.length != 10 || /^([0-9]{10})$/.test(this.selectedPhoneNumber) == false)
        ) {
          throw false;
        }

        if (this.selectedPhoneNumber && this.validator == 'kleber' && validateKleber == true) {
          this.utilSevice.verifyPhone(this.selectedPrefix, this.selectedPhoneNumber).subscribe((res) => {
            if (res['success'] == true && res['data'].Valid == true) {
              NotifyAppComponent.displayToast(
                'success',
                '+' + this.selectedPrefix + '-' + this.selectedPhoneNumber + ' is Valid',
                res['data'].message
              );

              resolve(true);
            } else {
              if (this.isStrictMode == false) {
                const confirm = new ConfirmDialog(
                  'help',
                  '',
                  'We Were Unable to verify Your phone number ' +
                    this.customPhone.transform(this.selectedPrefix + '-' + this.selectedPhoneNumber, null) +
                    ' Do you want to continue? ',
                  'No',
                  'Yes'
                );

                const ref = this.dialog.open(ConfirmDialogComponent, {
                  data: confirm,
                });
                ref.componentInstance.onConfirm.subscribe((confirmation) => {
                  if (confirmation === false) {
                    ref.close();
                    throw false;
                  } else {
                    ref.close();
                    resolve(true);
                  }
                });
              } else {
                resolve(false);
              }
            }
          });
        } else {
          resolve(true);
        }
      } catch (e) {
        resolve(false);
      }
    });
  }

  sendResult(isKleber = true, saveUserData = true) {
    this.isValidate = false;
    this.validate.emit(false);
    this.changeOnce = true;

    this.isInputValidate(isKleber).then((res) => {
      if (res == false) {
        this.isValidate = false;
        this.validate.emit(false);
        this.getPhone.emit(null);
      } else {
        if (this.invitationID && this.userDataName && saveUserData == true) {
          UtilsClass.savingData();
          let saveDate;

          if (this.selectedPhoneNumber && this.selectedPrefix) {
            saveDate = {
              attribute: this.userDataName,
              value: this.selectedPrefix + '-' + this.selectedPhoneNumber,
            };
          } else {
            saveDate = {
              attribute: this.userDataName,
              value: null,
            };
          }

          this.userDataService.setUserData(this.invitationID, saveDate, this.sessionType).subscribe((res) => {
            this.isValidate = true;
            this.validate.emit(true);

            if (this.selectedPhoneNumber && this.selectedPrefix) {
              this.getPhone.emit(this.selectedPrefix + '-' + this.selectedPhoneNumber);
            } else {
              this.getPhone.emit(null);
            }
          });
        } else {
          this.isValidate = true;
          this.validate.emit(true);

          this.validate.emit(true);

          if (this.selectedPhoneNumber && this.selectedPrefix) {
            this.getPhone.emit(this.selectedPrefix + '-' + this.selectedPhoneNumber);
          } else {
            this.getPhone.emit(null);
          }
        }
      }
    });
  }
}
