<div class="clearfix" *ngIf="isAdminOrPromoter == true && load==true">
  <div *ngIf="hideExtraComponents == false">
    <app-operator-filter
      class="dash clearfix"
      id="part1"
      [displayMerchant]="false"
      [displayDateFrom]="true"
      [displayDateTo]="true"
      [sendNull]="true"
      [selectDateRange]="selectedRange"
      [autoSelectMerchant]="true"
      [displayDentist]="false"
      (getMerchant)="getMerchant($event)"
      (getDateTo)="getDateTo($event)"
      (getDateFrom)="getDateFrom($event)"
      (search)="applySearch($event)"
    ></app-operator-filter>
  </div>

  <div class="row clearfix">
    <app-quotation-list-global
      [status]="status"
      [pageSize]="pageSize"
      [dateFrom]="dateFrom"
      [dateTo]="dateTo"
    ></app-quotation-list-global>
  </div>
  <!-- <div class="row clearfix">
        <div *ngIf="merchantID=='none'" class="pleaseChoose" @ngIfAnimation>
            <p>
                <strong>No merchant selected</strong>
            </p>
            <img class="character_warning" />
            <p></p>
        </div>
    </div> -->
</div>

<div class="clearfix" *ngIf="isAdminOrPromoter == false">
  <!-- <div *ngIf="hideExtraComponents==false">
        <app-operator-filter [displayMerchant]="false" [displayDateFrom]="true" [displayDateTo]="true" [displayDentist]="false"
            (getDateTo)="getDateTo($event)" (getDateFrom)="getDateFrom($event)" (search)="applySearch($event)" id="part1"></app-operator-filter>
    </div>
    <div class="row clearfix">
        <app-quotation-list [dateFrom]="dateFrom" [dateTo]="dateTo" (detailsView)="detailsView($event)"></app-quotation-list>
    </div> -->
</div>
