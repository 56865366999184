<mat-card class="card existing-patient-summary" *ngIf="existingPatient">
  <div class="card-header primary-gradient-img clearfix inModal">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear mat-button white" matDialogClose>
        <mat-icon>clear</mat-icon>
      </button>
      <!-- merchant calculated name -->
      <div class="row titleArea clearfix">
        <app-ep-document-view-image
          class="patientView userIconView pull-left"
          [defaultPicture]="
            'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
          "
          [link]="profileLink"
        ></app-ep-document-view-image>
        <h2 class="summary-header rm-m">
          {{ existingPatient.calculatedName }}
          <span class="subLabel">{{ "KEYWORD.patient" | translate | titlecase }}</span>
        </h2>
      </div>
    </div>
    <div class="action-button">
      <button class="actionMenuToggle" [matMenuTriggerFor]="btnPatMenu" mat-raised-button>
        Actions
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #btnPatMenu="matMenu">
        <!-- CUSTOMER EDIT NEEDS TO BE ON THIS VIEW -->
        <!-- <button mat-menu-item color="accent" (click)="openCustomerEdit()">
                    <mat-icon>person</mat-icon> View/Edit Profile
                  </button> -->

        <button class="" *ngIf="existingPatient['email']" (click)="contact()" mat-menu-item color="accent">
          <mat-icon>contact_mail</mat-icon>
          Contact {{ existingPatient.firstName }}
        </button>
      </mat-menu>
    </div>
  </div>

  <mat-dialog-content>
    <div class="row clearfix" *ngIf="existingPatient['mobile'] || existingPatient['workPhone']">
      <div class="icons">
        <mat-icon>phone</mat-icon>
      </div>
      <!-- Phone numbers -->
      <div class="row-content">
        <p class="rm-m" *ngIf="existingPatient['mobile']">{{ existingPatient['mobile'] | customPhone }}</p>
        <p class="small" *ngIf="existingPatient['mobile']">Phone/Mobile</p>
        <!--  -->
        <p class="rm-m" *ngIf="existingPatient['workPhone'] && existingPatient['workPhone'] != 'undefined'">
          {{ existingPatient['workPhone'] | customPhone: 'landLine' }}
        </p>
        <p class="small" *ngIf="existingPatient['workPhone'] && existingPatient['workPhone'] != 'undefined'">
          Work
        </p>
      </div>
    </div>

    <mat-divider *ngIf="existingPatient['mobile'] || existingPatient['workPhone']"></mat-divider>
    <!-- Email Addresses -->
    <div class="row clearfix" *ngIf="existingPatient['email']">
      <div class="icons">
        <mat-icon>email</mat-icon>
      </div>
      <div class="row-content">
        <p class="rm-m">{{ existingPatient['email'] }}</p>
        <p class="small">Primary Email</p>
      </div>
    </div>

    <mat-divider *ngIf="existingPatient['adress'] || existingPatient['postCode']"></mat-divider>
    <!-- Location Information -->
    <div class="row clearfix" *ngIf="existingPatient['adress'] || existingPatient['postCode']">
      <div class="icons">
        <mat-icon>location_on</mat-icon>
      </div>
      <div class="row-content">
        <p class="rm-m">{{ existingPatient['adress'] }}</p>
        <p class="small">Home Address</p>
        <!--  -->
        <p class="rm-m">{{ existingPatient['postCode'] }}</p>
        <p class="small">Postal Address</p>
      </div>
    </div>

    <mat-divider></mat-divider>
    <!-- Social Media Accounts -->

    <p *ngIf="false">
      <span class="status-box pull-right">Referral </span>
    </p>

    <mat-divider *ngIf="isPromoterOrAdmin == true"></mat-divider>

    <div class="full-width text-center clearfix row flex" *ngIf="isPromoterOrAdmin == true">
      <p class="full-width" *ngIf="existingPatient['asInvitation']">
        <span class="ship">As Invitation : </span> <span class="badge">{{ existingPatient['asInvitation'] }} </span>
      </p>

      <p class="full-width" *ngIf="existingPatient['asProspect']">
        <span class="ship">As Prospect : </span> <span class="badge">{{ existingPatient['asProspect'] }} </span>
      </p>

      <p class="full-width" *ngIf="existingPatient['asCustomer']">
        <span class="ship">As Customer : </span> <span class="badge">{{ existingPatient['asCustomer'] }} </span>
      </p>
    </div>
  </mat-dialog-content>
</mat-card>
