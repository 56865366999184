export const CONTEXTS = [
  {
    name: 'Breeze Living',
    value: 'breeze',
    icon: 'fas fa-couch',
  },

  {
    name: 'Default',
    value: 'default',
    icon: 'settings',
  },
  {
    name: 'Dental',
    value: 'dental',
    icon: 'add_to_queue',
  },
  {
    name: 'Pay for Paws',
    value: 'p4p',
    icon: 'fas fa-paw',
  },
  {
    name: 'Retail',
    value: 'retail',
    icon: 'devices_other',
  },

  {
    name: 'Veterinary',
    value: 'vets',
    icon: 'pets',
  },
];
