<div class="row clearfix flex">
  <mat-card class="full-width card doc-card">
    <div class="card-header primary-gradient-img row flex flex-center">
      <h4 class="summary-header rm-m white">ID Documents</h4>
      <button class="white" (click)="addSupportingDocument()" mat-stroked-button>
        <mat-icon>add</mat-icon>
        Add document
      </button>
    </div>
    <div class="row clearfix" *ngIf="IDDocuments.length > 0">
      <mat-list class="full-width" #id>
        <mat-list-item class="flex full-width" *ngFor="let id of IDDocuments">
          <app-ep-document-thumbnail-view class="thumbnail-card-support" [documentID]="id.ID">
          </app-ep-document-thumbnail-view>

          <div class="full-width">
            <p class="rm-m full-width">{{ id.Label }}</p>
            <div class="full-width flex">
              <p class="rm-m full-width"><strong>Added:</strong> {{ id.DateTimeCreated | customDateTime }}</p>
              <p
                class="small bordered rm-m full-width"
                [style.border-color]="util.daysPastColor(id['DateTimeCreated'])"
                [style.color]="util.daysPastColor(id['DateTimeCreated'])"
              >
                {{ id['DateTimeCreated'] | TimeLabelPast }} ago
                <span class="backgroundColor" [style.background]="util.daysPastColor(id['DateTimeCreated'])"></span>
              </p>
            </div>

            <button
              class="full-width"
              [ngClass]="{ selectedDoc: selectedDocType == 'id' && id.ID == selectedDocID }"
              (click)="viewDocs(id.ID, 'id')"
              mat-flat-button
            >
              {{ id.Label }}

              <span class="label mat-elevation-z2 referred" *ngIf="id['PromoterDecision'] == ''">Referred</span>
              <span
                class="label mat-elevation-z2"
                *ngIf="id['PromoterDecision'] != ''"
                [ngClass]="{ accepted: id['PromoterDecision'] == 'PASS', rejected: id['PromoterDecision'] == 'REJECT' }"
              >{{ id['PromoterDecision'] }}</span
              >
            </button>
          </div>
        </mat-list-item>
      </mat-list>
    </div>

    <div class="row clearfix" *ngIf="IDDocuments.length == 0">
      <p>No documnents found in database</p>
    </div>
  </mat-card>

  <mat-card class="full-width card doc-card">
    <div class="card-header primary-gradient-img row flex flex-center">
      <h4 class="summary-header rm-m white">Employment</h4>
      <button class="white" (click)="addSupportingDocument()" mat-stroked-button>
        <mat-icon>add</mat-icon>
        Add document
      </button>
    </div>
    <div class="row clearfix" *ngIf="employDocuments.length > 0">
      <mat-list class="full-width" #employment>
        <mat-list-item class="flex full-width" *ngFor="let employment of employDocuments">
          <app-ep-document-thumbnail-view class="thumbnail-card-support" [documentID]="employment.ID">
          </app-ep-document-thumbnail-view>

          <div class="full-width">
            <button
              class="full-width"
              [ngClass]="{ selectedDoc: selectedDocType == 'employment' && employment.ID == selectedDocID }"
              (click)="viewDocs(employment.ID, 'employment')"
              mat-flat-button
            >
              {{ employment.Label }}

              <span class="label mat-elevation-z2 referred" *ngIf="employment['PromoterDecision'] == ''">Referred</span>
              <span
                class="label mat-elevation-z2"
                *ngIf="employment['PromoterDecision'] != ''"
                [ngClass]="{
                  accepted: employment['PromoterDecision'] == 'PASS',
                  rejected: employment['PromoterDecision'] == 'REJECT'
                }"
              >{{ employment['PromoterDecision'] }}</span
              >
            </button>
          </div>
        </mat-list-item>
      </mat-list>
    </div>

    <div class="row clearfix" *ngIf="employDocuments.length == 0">
      <p>No documnents found in database</p>
    </div>
  </mat-card>

  <mat-card class="full-width card doc-card">
    <div class="card-header primary-gradient-img row flex flex-center">
      <h4 class="summary-header rm-m white">Residence &amp; Properties</h4>
      <button class="white" (click)="addSupportingDocument()" mat-stroked-button>
        <mat-icon>add</mat-icon>
        Add document
      </button>
    </div>

    <div class="row clearfix" *ngIf="residenceDocuments.length > 0">
      <mat-list class="full-width" #residence>
        <mat-list-item class="flex full-width" *ngFor="let residence of residenceDocuments">
          <app-ep-document-thumbnail-view class="full-width thumbnail-card-support" [documentID]="residence.ID">
          </app-ep-document-thumbnail-view>

          <div class="full-width">
            <button
              class="full-width clearfix"
              [ngClass]="{ selectedDoc: selectedDocType == 'residence' && residence.ID == selectedDocID }"
              (click)="viewDocs(residence.ID, 'residence')"
              mat-flat-button
            >
              {{ residence.Label }}

              <span class="label mat-elevation-z2 referred" *ngIf="residence['PromoterDecision'] == ''">Referred</span>
              <span
                class="label mat-elevation-z2"
                *ngIf="residence['PromoterDecision'] != ''"
                [ngClass]="{
                  accepted: residence['PromoterDecision'] == 'PASS',
                  rejected: residence['PromoterDecision'] == 'REJECT'
                }"
              >{{ residence['PromoterDecision'] }}</span
              >
            </button>
          </div>
        </mat-list-item>
      </mat-list>
    </div>

    <div class="row clearfix" *ngIf="residenceDocuments.length == 0">
      <p>No documnents found in database</p>
    </div>
  </mat-card>
</div>

<div class="row clearfix flex">
  <mat-card class="full-width card flex flex-col">
    <div class="colour-stripe"></div>

    <h3 class="sr-title sm-h3 rm-m">Preview</h3>
    <hr/>
    <div class="row clearfix flex full-height">
      <div class="well text-center full-width">
        <p *ngIf="selectedDocID == null">Please select a document</p>
        <app-ep-document-view *ngIf="selectedDocID" [documentID]="selectedDocID"></app-ep-document-view>
      </div>
    </div>
  </mat-card>

  <mat-card class="thrd-width card" *ngIf="selectedDocID">
    <div class="colour-stripe"></div>

    <h3 class="sr-title sm-h3 rm-m">Checklist</h3>
    <hr/>
    <div class="row clearfix" *ngIf="isPassDecision == true">
      <p>This document has been successfully validated.</p>
    </div>
    <div class="row clearfix" *ngIf="isPassDecision == false">
      <p class="small">
        Compare the information below with what's visible in the uploaded document to confirm that the information
        recorded is correct.
      </p>

      <hr/>
      <div class="row clearfix">
        <div class="row clearfix" *ngIf="mulCheckLists.length > 0">
          <mat-form-field appearance="outline">
            <mat-select [(ngModel)]="selectCheckList" (selectionChange)="changeCheckListValue($event.value)">
              <mat-option *ngFor="let m of mulCheckLists" [value]="m">
                <span *ngIf="selectedDocType == 'employment'">{{ m['Info.Employment.Employer.Name'] }}</span>
                <span *ngIf="selectedDocType == 'id'">{{ m['Type.Label'] }}</span>
                <span *ngIf="selectedDocType == 'residence'">{{ m['Adress.Calculated'] }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-selection-list class="full-width" #information>
          <mat-list-option *ngFor="let info of infoList">
            <div class="bold" mat-line>{{ info.label }}</div>
            <div mat-line>{{ info.value }}</div>
          </mat-list-option>
        </mat-selection-list>
      </div>
      <div class="divider-bar">
        <mat-icon class="fas fa-exclamation-triangle"></mat-icon>
      </div>
      <div class="row clearfix">
        <!-- <p class="small"><strong>If you can't perform this task</strong> because the quality of the document is too low,
          you can request the {{ "KEYWORD.patient" | translate }} supplies a new document.</p> -->
        <!-- <button mat-stroked-button>
          <mat-icon class="fas fa-cloud-upload-alt sm"></mat-icon> Request re-upload
        </button> -->

        <button class="pull-right pos" (click)="makeDecision()" mat-raised-button color="accent">
          <mat-icon class="fas fa-clipboard-check sm"></mat-icon> Decide
        </button>
      </div>
    </div>
  </mat-card>
</div>

<div class="row clearfix flex" *ngIf="false">
  <mat-card class="full-width card">
    <div class="colour-stripe"></div>

    <h3 class="sr-title sm-h3 rm-m pull-left">Information &amp; Decision</h3>

    <button class="pull-right pos" (click)="makeDecision()" mat-raised-button color="accent">
      <mat-icon class="fas fa-clipboard-check sm"></mat-icon> Decide
    </button>
    <hr/>
    <div class="row clearfix flex">
      <div class="full-width">
        <div class="column">
          <h4>File information</h4>

          <div class="row clearfix document-version">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Document version</mat-label>
              <mat-select>
                <mat-option> Please select</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- date added -->
          <div class="row clearfix date-added infoblock mt">
            <p class="bold rm-m small">Uploaded by</p>
            <p class="rm-m small">FirstName LastName</p>
            <p class="rm-m small">00/00/0000 / 00:00pm</p>
          </div>

          <!-- show if modified -->
          <div class="row clearfix last-modified infoblock mt">
            <p class="bold rm-m small">Last modified</p>
            <p class="rm-m small">FirstName LastName</p>
            <p class="rm-m small">00/00/0000 / 00:00pm</p>
          </div>
        </div>
      </div>

      <div class="full-width">
        <div class="column">
          <h4>File history</h4>

          <!-- history decision block -->
          <div class="row clearfix infoblock mt">
            <p class="bold rm-m small">Initial decision</p>
            <p class="rm-m small">ePremium</p>
            <p class="rm-m small">00/00/0000 / 00:00pm</p>
            <span class="label pos referred">Referred</span>
          </div>

          <!-- history decision block -->
          <div class="row clearfix infoblock mt">
            <p class="bold rm-m small">Final decision</p>
            <p class="rm-m small">Operator name</p>
            <p class="rm-m small">00/00/0000 / 00:00pm</p>
            <span class="label pos accepted">Accepted</span>
          </div>
        </div>
      </div>

      <div class="full-width">
        <div class="column">
          <h4>File comments</h4>

          <div class="add-comment">
            <button *ngIf="!showAddComment" (click)="showAddComment = !showAddComment" mat-stroked-button>
              Add comment
            </button>
            <div class="row clearfix" *ngIf="showAddComment" @simpleFadeAnimation>
              <mat-form-field class="full-width" appearance="outline">
                <textarea matInput></textarea>
              </mat-form-field>
              <button class="pull-right" (click)="showAddComment = !showAddComment" mat-stroked-button color="primary">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
