<div class="row clearfix full-width" *ngIf="!idCheckObj && loading==false">



  <app-empty-list-message [title]="'Identity Check'"
    [message]='"This contract-identity-check.component has not performed a identity check yet."'>
  </app-empty-list-message>


</div>

<div class="full-width row flex id-holder-card">

  <div class="row clearfix full-width flex id-holder-card-inner" *ngIf="patient  && loading==false">
    <mat-card class="clearfix  full-width mb id-card">
      <div class="colour-stripe"></div>
      <h3 class="sr-title rm-m">Identity Information</h3>
      <hr />
      <div class="row clearfix flex">
        <div class="full-width">
          <div class="column">
            <p><strong>Gender:</strong> {{ patient['Gender.Label'] }}</p>
            <p><strong>Name:</strong> {{ patient['CalculatedName'] }}</p>
            <p><strong>Address:</strong> {{ patient['addresses.Calculated'] }}</p>
          </div>
        </div>
        <div style="width:60% !important;">
          <div class="column">
            <p><strong>Date of birth:</strong> {{ patient['DateOfBirth'] }}</p>
            <p><strong>ID Type:</strong></p>
            <p><strong>ID Number:</strong></p>
          </div>
        </div>
      </div>

    </mat-card>
  </div>

  <div class="row clearfix full-width flex ml id-holder-card-inner" *ngIf="idCheckObj  && loading==false">
    <mat-card class="clearfix  full-width mb id-card">
      <div class="colour-stripe"></div>
      <h3 class="sr-title rm-m">ID Check</h3>
      <hr />

      <div class="row clearfix flex">
        <div class="half-width column">
          <div class="well clearfix" style="      padding-right: 5px;      padding-left: 5px;     ">
            <h4 class="rm-m">Service provider results</h4>
            <hr class="mb" />

            <div class="full-width">
              <div class="value">
                <p class="bold">Verification</p>
                <p>
                  {{ idCheckObj['IDReport.VerificationResult'] }}
                  <span class="chip pass">{{ idCheckObj['IDReport.VerificationPoints'] }}</span>
                </p>
              </div>

              <div class="value">
                <p class="bold">Fraud Assessment</p>
                <p>
                  {{ idCheckObj['IDReport.FraudAssessmentResult'] }}
                  <span class="chip pass">{{ idCheckObj['IDReport.FraudAssessmentScore'] }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="half-width column">
          <div class="well clearfix" style="     padding-right: 5px;        padding-left: 5px;  ">
            <h4 class="rm-m">References</h4>
            <hr class="mb" />

            <div class="full-width">
              <div class="value">
                <p class="bold">VEDA - ID Matrix</p>
                <p>{{ idCheckObj['IDReport.VEDA_IDMatrix'] }}</p>
              </div>

              <div class="value">
                <p class="bold">Our reference</p>
                <p>{{ idCheckObj['IDReport.Our_Reference'] }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>
<div class="row clearfix full-width">
  <mat-tab-group
    *ngIf="idCheckObj  && loading==false &&( (rules && rules.length>0) || (searches && searches.length>0) || (searchValues && searchValues.length>0) || (fraudAssessments && fraudAssessments.length>0))">
    <mat-tab label="Rules" *ngIf="rules && rules.length>0">
      <ng-template mat-tab-label>
        <mat-icon class="fas fa-folder-open"></mat-icon>
        Rules
      </ng-template>

      <ng-template matTabContent>
        <mat-card class="card">
          <div class="colour-stripe"></div>
          <h3 class="sr-title rm-m">Rules</h3>
          <hr />

          <div class="row clearfix flex">
            <div class="full-width">
              <p class="small">Name</p>
            </div>
            <div class="full-width">
              <p class="small">Result</p>
            </div>
          </div>

          <div class="row clearfix flex" *ngFor="let rule of rules">
            <div class="full-width">
              <p>
                {{ rule.Name }}
              </p>
            </div>

            <div class="full-width">
              <p>
                <span *ngIf="rule.Result == true">
                  <mat-icon>done</mat-icon>
                </span>
                <span *ngIf="rule.Result != true">
                  <mat-icon>close</mat-icon>
                </span>
              </p>
            </div>
          </div>
        </mat-card>
      </ng-template>
    </mat-tab>

    <mat-tab label="Searches" *ngIf="searches && searches.length>0">
      <ng-template mat-tab-label>
        <mat-icon class="fas fa-folder-open"></mat-icon>
        Searches
      </ng-template>

      <ng-template matTabContent>
        <mat-card class="card">
          <div class="colour-stripe"></div>
          <h3 class="sr-title rm-m">Results</h3>
          <hr />

          <div class="row clearfix">
            <div class="row clearfix flex">
              <div class="full-width">
                <p class="small">Name</p>
              </div>
              <div class="full-width">
                <p class="small">Result</p>
              </div>
              <div class="full-width">
                <p class="small">Raw sorce</p>
              </div>
              <div class="full-width">
                <p class="small">Weight</p>
              </div>
              <div class="full-width">
                <p class="small">ID</p>
              </div>
            </div>

            <div class="row clearfix flex" *ngFor="let search of searches">
              <div class="full-width">
                <p class="small">
                  {{ search['name'] }}
                </p>
              </div>
              <div class="full-width">
                <p class="small">
                  {{ search['result'] }}
                </p>
              </div>
              <div class="full-width">
                <p class="small">
                  {{ search['rawScore'] }}
                </p>
              </div>
              <div class="full-width">
                <p class="small">
                  {{ search['weight'] }}
                </p>
              </div>
              <div class="full-width">
                <p class="small">
                  {{ search['ID'] }}
                </p>
              </div>
            </div>
          </div>

          <div class="row clearfix mt">
            <button class="pull-right" *ngIf="checkstatus != 'Accept'" (click)="makeDecision('IDCheck')"
              mat-raised-button color="accent">
              <mat-icon class="fas fa-clipboard-check"></mat-icon> Decide
            </button>
          </div>
        </mat-card>
      </ng-template>
    </mat-tab>

    <mat-tab label="Search Values" *ngIf="searchValues && searchValues.length>0">
      <ng-template mat-tab-label>
        <mat-icon class="fas fa-folder-open"></mat-icon>
        Search Values
      </ng-template>

      <ng-template matTabContent>
        <mat-card class="card">
          <div class="colour-stripe"></div>
          <h3 class="sr-title rm-m">Result Values</h3>
          <hr />

          <div class="row clearfix flex">
            <div class="full-width">
              <p class="small">Name</p>
            </div>
            <div class="full-width">
              <p class="small">Value</p>
            </div>
          </div>

          <div class="row clearfix flex" *ngFor="let searchValue of searchValues">
            <div class="full-width">
              <p>
                {{ searchValue.name }}
              </p>
            </div>

            <div class="full-width">
              <p>
                {{ searchValue.value }}
              </p>
            </div>
          </div>
        </mat-card>
      </ng-template>
    </mat-tab>

    <mat-tab label="Fraud Assessment" *ngIf="fraudAssessments && fraudAssessments.length>0">
      <ng-template mat-tab-label>
        <mat-icon class="fas fa-folder-open"></mat-icon>
        Fraud Assessment
      </ng-template>

      <ng-template matTabContent>
        <mat-card class="card">
          <div class="colour-stripe"></div>
          <h3 class="sr-title rm-m">Fraud Assessment</h3>
          <hr />

          <div class="row clearfix flex">
            <div class="full-width">
              <p class="small">Factor</p>
            </div>
            <div class="full-width">
              <p class="small">Result</p>
            </div>
            <div class="full-width">
              <p class="small">Score</p>
            </div>
            <div class="full-width">
              <p class="small">Weight</p>
            </div>
            <div class="full-width">
              <p class="small">Threshold</p>
            </div>
          </div>

          <div class="row clearfix flex" *ngFor="let fraudAssessment of fraudAssessments">
            <div class="full-width">
              <p>
                {{ fraudAssessment.Factor }}
              </p>
            </div>

            <div class="full-width">
              <p>
                {{ fraudAssessment.Result }}
              </p>
            </div>
            <div class="full-width">
              <p>
                {{ fraudAssessment.Score }}
              </p>
            </div>

            <div class="full-width">
              <p>
                {{ fraudAssessment.Weight }}
              </p>
            </div>
            <div class="full-width">
              <p>
                {{ fraudAssessment.Threshold }}
              </p>
            </div>
          </div>
        </mat-card>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
