import { ThemeCreateComponent } from '../../theme/theme-create/theme-create.component';
import { Routes } from '@angular/router';
import { AdminMainComponent } from '../pages/admin-main/admin-main.component';
import { AdminSystemSettingsComponent } from '../features/admin-system-settings/admin-system-settings.component';
import { AdminCommunicationSettingsComponent } from '../features/admin-communication-settings/admin-communication-settings.component';
import { AdminDocumentsCenterComponent } from '../features/admin-documents-center/admin-documents-center.component';
import { AdminLogsComponent } from '../features/admin-logs/admin-logs.component';
import { AdminUsersComponent } from '../features/admin-users/admin-users.component';
import { AdminTipComponent } from '../features/admin-tip/admin-tip.component';
import { AdminGeneralSettingsComponent } from '../features/admin-general-settings/admin-general-settings.component';
import { AdminLanguagesComponent } from '../features/admin-languages/admin-languages.component';
import { AdminThemeComponent } from '../features/admin-theme/admin-theme.component';
import { AdminHelpDocumentsCenterComponent } from '../features/admin-help-documents-center/admin-help-documents-center.component';
import { AuthAdminGuard } from './admin-guard.service';
import { EconnectOverviewComponent } from '../../econnect/econnect-overview/econnect-overview.component';
const ɵ0 = { title: 'Administration Dashboard' }, ɵ1 = { title: 'Site Settings' }, ɵ2 = { title: 'Tips Setttings' }, ɵ3 = { title: 'Users Setttings' }, ɵ4 = { title: 'Logs Setttings' }, ɵ5 = { title: 'Communication Settings' }, ɵ6 = { title: 'Document Center Setttings' }, ɵ7 = { title: 'Languages Setttings' }, ɵ8 = { title: 'System Setttings' }, ɵ9 = { title: 'Themes Setttings' }, ɵ10 = { title: 'Create Theme' }, ɵ11 = { title: 'Edit Theme' }, ɵ12 = { title: 'Help Center' }, ɵ13 = { title: 'Econnect Documentation' }, ɵ14 = { title: 'Econnect Documentation' }, ɵ15 = { title: 'Econnect Documentation' };
const routes = [
    {
        path: 'admin',
        canActivate: [AuthAdminGuard],
        component: AdminMainComponent,
        children: [
            {
                path: '', component: AdminGeneralSettingsComponent,
                data: ɵ0,
                outlet: 'adminPageRoute'
            },
            {
                path: 'general', component: AdminGeneralSettingsComponent,
                data: ɵ1,
                outlet: 'adminPageRoute'
            },
            {
                path: 'tip', component: AdminTipComponent,
                data: ɵ2,
                outlet: 'adminPageRoute'
            },
            {
                path: 'users', component: AdminUsersComponent,
                data: ɵ3, outlet: 'adminPageRoute'
            },
            {
                path: 'logs', component: AdminLogsComponent,
                data: ɵ4, outlet: 'adminPageRoute'
            },
            {
                path: 'communication-settings', component: AdminCommunicationSettingsComponent,
                data: ɵ5, outlet: 'adminPageRoute'
            },
            {
                path: 'document-center', component: AdminDocumentsCenterComponent,
                data: ɵ6, outlet: 'adminPageRoute'
            },
            {
                path: 'languages', component: AdminLanguagesComponent,
                data: ɵ7, outlet: 'adminPageRoute'
            },
            {
                path: 'system', component: AdminSystemSettingsComponent,
                data: ɵ8, outlet: 'adminPageRoute'
            },
            {
                path: 'theme', component: AdminThemeComponent,
                data: ɵ9, outlet: 'adminPageRoute'
            },
            {
                path: 'theme-create', component: ThemeCreateComponent,
                data: ɵ10, outlet: 'adminPageRoute'
            },
            {
                path: 'theme-create/:id', component: ThemeCreateComponent,
                data: ɵ11, outlet: 'adminPageRoute'
            },
            {
                path: 'help-document-center', component: AdminHelpDocumentsCenterComponent,
                data: ɵ12, outlet: 'adminPageRoute'
            },
            {
                path: 'econnect-documentation',
                component: EconnectOverviewComponent,
                data: ɵ13,
                outlet: 'adminPageRoute'
            },
            {
                path: 'econnect-documentation/:tableID',
                component: EconnectOverviewComponent,
                data: ɵ14,
                outlet: 'adminPageRoute'
            },
            {
                path: 'econnect-documentation/:tableID/:fieldID',
                component: EconnectOverviewComponent,
                data: ɵ15,
                outlet: 'adminPageRoute'
            },
        ]
    }
];
export class AdminRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15 };
