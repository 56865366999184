import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UtilsClass } from '../../types/utils/utils.class';

@Component({
  selector: 'app-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.css'],
})
export class TimeInputComponent implements OnInit {
  requiredLabel = 'required';

  @Input()
  timePickerType = 'legacy';

  @Input()
  isRequired = false;

  @Output()
  validate = new EventEmitter();

  @Output()
  getTime = new EventEmitter();

  util = new UtilsClass();

  @Input()
  clear = new EventEmitter();

  @Input()
  label = 'Select time';

  @Input()
  time;

  hours = 0;
  minutes = 0;

  disableHours = false;
  disableMinutes = false;

  timePeriod = 'AM';

  @ViewChild('hoursInputID', { static: false }) hoursInput: ElementRef;

  @ViewChild('minutesInputID', { static: false }) minutesInput: ElementRef;

  ngOnInit() {
    if (this.isRequired == false) {
      this.requiredLabel = null;
    }

    this.setup();

    if (this.clear) {
      this.clear.subscribe((res) => {
        this.time = null;
        this.sendResult();
      });
    }
  }

  setup() {
    if (this.time) {
      const _time = this.time.split(':');

      if (_time.length == 2 || _time.length == 3) {
        this.hours = Number(_time[0]);
        if (this.hours > 12) {
          this.hours = this.hours - 12;
          this.timePeriod = 'PM';
        }
        this.minutes = Number(_time[1]);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.time && changes.time.previousValue != changes.time.currentValue) {
      this.setup();
    }
  }

  sendResult() {
    let _h = '00';
    let _m = '00';

    if (this.hours) {
      _h = String(Number(this.hours));

      if (Number(_h) > 11 || Number(_h) < 0) {
        _h = '00';
      }

      if (Number(_h) == 0) {
        _h = '00';
      } else if (_h.length == 1) {
        _h = '0' + _h;
      }
    }

    if (this.minutes) {
      _m = String(Number(this.minutes));

      if (Number(_m) > 59 || Number(_m) < 0) {
        _m = '00';
      }

      if (Number(_m) == 0) {
        _m = '00';
      } else if (_m.length == 1) {
        _m = '0' + _m;
      }
    }

    if (this.timePeriod == 'PM') {
      _h = String(Number(_h) + 12);
    }

    const result = _h + ':' + _m;

    this.getTime.emit(result);

    this.validateField();
  }

  focusHours() {
    setTimeout(() => this.hoursInput.nativeElement.select());
  }

  focusMinutes() {
    setTimeout(() => this.minutesInput.nativeElement.select());
  }

  changingHours(e) {
    if (e) {
      this.disableHours = true;

      let _hours = e;

      if (String(_hours).length > 2 || Number(_hours) > 11 || Number(_hours) < 0) {
        this.hoursInput.nativeElement.value = '00';
        _hours = 0;
      }

      if (Number(_hours) > 11 || Number(_hours) < 0) {
        _hours = 0;
      }

      _hours = Number(_hours);

      this.disableHours = false;

      this.hours = _hours;

      this.hoursInput.nativeElement.value = ('0' + _hours).slice(-2);

      this.sendResult();
    } else {
      this.hoursInput.nativeElement.value = '00';
    }
  }

  changingMinutes(e) {
    if (e) {
      this.disableMinutes = true;
      let _minutes = e;

      if (String(_minutes).length > 2 || Number(_minutes) > 59 || Number(_minutes) < 0) {
        this.minutesInput.nativeElement.value = '00';
        _minutes = 0;
      }

      if (Number(_minutes) > 59 || Number(_minutes) < 0) {
        _minutes = 0;
      }

      _minutes = Number(_minutes);

      this.disableMinutes = false;
      this.minutes = _minutes;

      this.minutesInput.nativeElement.value = ('0' + _minutes).slice(-2);
      this.sendResult();
    } else {
      this.minutesInput.nativeElement.value = '00';
    }
  }

  changingtimePeriod(e) {
    if (e) {
      this.timePeriod = e;
      this.sendResult();
    }
  }

  validateField() {
    let ruleValidator;
    let isValidate = true;

    const o = {
      time: this.time,
    };
    if (this.isRequired != false) {
      ruleValidator = {
        time: 'string|required',
      };
    } else {
      ruleValidator = {
        time: 'string',
      };
    }

    isValidate = this.util.validateFields(o, ruleValidator);

    this.validate.emit(isValidate);
  }

  changeHoursUP() {
    let _hour = Number(this.hoursInput.nativeElement.value);

    if (_hour < 12 && _hour >= 0) {
      _hour = _hour + 1;

      this.hours = _hour;

      this.hoursInput.nativeElement.value = ('0' + _hour).slice(-2);

      this.sendResult();
    }
  }

  changeHoursDown() {
    let _hour = Number(this.hoursInput.nativeElement.value);

    if (_hour <= 12 && _hour > 0) {
      _hour = _hour - 1;
      this.hours = _hour;
      this.hoursInput.nativeElement.value = ('0' + _hour).slice(-2);

      this.sendResult();
    }
  }

  changeMinUP() {
    let _min = Number(this.minutesInput.nativeElement.value);

    if (_min < 59 && _min >= 0) {
      _min = _min + 1;

      this.minutes = _min;

      this.minutesInput.nativeElement.value = ('0' + _min).slice(-2);
      this.sendResult();
    }
  }

  changeMinDown() {
    let _min = Number(this.minutesInput.nativeElement.value);

    if (_min <= 59 && _min > 0) {
      _min = _min - 1;
      this.minutes = _min;
      this.minutesInput.nativeElement.value = ('0' + _min).slice(-2);
      this.sendResult();
    }
  }
}
