import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { CustomerProspectService } from '../../customer-prospect/shared/customerProspect.service';

@Component({
  selector: 'app-customer-prospect-details',
  templateUrl: './customer-prospect-details.component.html',
  styleUrls: ['./customer-prospect-details.component.css'],
})
export class CustomerProspectDetailsComponent implements OnInit {
  @Input()
  patientID;

  isModal = false;
  patient;
  sessionType = 'guest';

  constructor(
    private authenticationService: AuthenticationService,
    private customerProspectService: CustomerProspectService
  ) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      if (this.patientID) {
        this.customerProspectService
          .getCustomerProspectDetails(this.patientID, {}, this.sessionType)
          .subscribe((res) => {
            this.patient = res;
          });
      }
    });
  }
}
