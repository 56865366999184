import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { SimplePageViewComponent } from '../../../core/helper/simple-page-view/simple-page-view.component';
import { ConfirmDialogSingleButtonComponent } from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { InstantOfferCreateComponent } from '../../instant-finance-offer/instant-offer-create/instant-offer-create.component';
import { SmsConversationComponent } from '../../message/sms-conversation/sms-conversation.component';
import { SmsPromoterComponent } from '../../message/sms-promoter/sms-promoter.component';
import { ProductUtil } from '../../product/shared/productUtil.type';
import { InvitationLookupComponent } from '../invitation-lookup/invitation-lookup.component';

@Component({
  selector: 'app-invitation-new-button',
  templateUrl: './invitation-new-button.component.html',
  styleUrls: ['./invitation-new-button.component.css'],
})
export class InvitationNewButtonComponent implements OnInit {
  @Input()
  icon = 'local_post_office';
  @Input()
  hideQuery = false;

  @Input()
  title = null;

  @Input()
  btnLarge = false;

  @Input()
  isSideMenu = false;

  @Input()
  isInviteButton = false;

  @Input()
  isQuoteButton = false;

  @Input()
  isMemberButton = false;

  @Input()
  isQuickInvitation = false;

  @Input()
  isInHeader = false;

  productGroupCode;

  financedProductGroup = [];
  membershipProductGroup = [];

  context = Settings.global['context'];

  toolTips = [];
  productGroup = [];
  isModuleSMSOfferActive = Settings.global['isModuleSMSOfferActive'];
  isModuleMembershipActive = Settings.global['isModuleMembershipActive'];
  isModuleTreatmentPlanActive = Settings.global['isModuleTreatmentPlanActive'];
  isModuleQuotebuilderActive = Settings.global['isModuleQuotebuilderActive'];
  isModuleEmergercyActive = Settings.global['isModuleEmergercyActive'];
  isModuleExperimentalFeaturesActive = Settings.global['isModuleExperimentalFeaturesActive'];

  isModuleTreatmentTemplateActive = Settings.global['isModuleTreatmentTemplateActive'];
  isModuleAppointmentActive = Settings.global['isModuleAppointmentActive'];

  isModulePatientHistoryActive = Settings.global['isModulePatientHistoryActive'];
  isModuleGoogleReviewActive = Settings.global['isModuleGoogleReviewActive'];

  isPromoterOrAdmin = false;

  isSmileClub = false;
  isInnerHouse = false;
  currentPractice;
  isAppointmentDisabled = false;
  productUtil = new ProductUtil();
  healthHistoryActive = true;

  displayInfoToolTip = true;
  utils = new UtilsClass();

  myOptions = {
    'placement': 'bottom',
    'show-delay': 0,
    'hide-delay': 50000,
    'animation-duration': 0,
    'autoPlacement': false,
    'auto-placement': false,
    'tooltip-class':'header-tooltip-new'
}


  constructor(
    private authenticationService: AuthenticationService,
    private utilService: UtilsService,
    private router: Router
  ) {}

  ngOnInit() {

    if (localStorage.getItem('displayInfoToolTip') === 'false') {
      this.displayInfoToolTip = false;
    }
    AuthenticationService.getDisplayInfoToolTip.subscribe((res) => {
      this.displayInfoToolTip = res ;
    });

    AuthenticationService.updateToolTip.subscribe((res) => {
      if (res === true) {
        this.utilService.getToolTip().subscribe((r) => {
          if (r) {
            this.toolTips = r;
          }
        });
      }
    });

    AuthenticationService.appoinmentAccess.subscribe((r) => {
      this.isAppointmentDisabled = r;
    });

    AuthenticationService.healthHistoryAccess.subscribe((r) => {
      this.healthHistoryActive = r;
    });

    this.utilService.getToolTip().subscribe((r) => {
      if (r) {
        this.toolTips = r;
      }
    });
    this.authenticationService.getCurrentPractice().subscribe((currentPractice) => {
      if (currentPractice) {
        this.currentPractice = currentPractice;
        this.checkBookingEngine();
        this.checkHH();
      }
    });
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      if (this.isSideMenu != true) {
        this.displayMenu();
      }

      this.utilService.getCurrentAccess().subscribe((access) => {
        if (access) {
          this.isModuleSMSOfferActive = access['isModuleSMSOfferActive'];
          this.isModuleMembershipActive = access['isModuleMembershipActive'];
          this.isModuleTreatmentPlanActive = access['isModuleTreatmentPlanActive'];
          this.isModuleExperimentalFeaturesActive = access['isModuleExperimentalFeaturesActive'];
          this.isModuleEmergercyActive = access['isModuleEmergercyActive'];
          this.isModuleTreatmentTemplateActive = access['isModuleTreatmentTemplateActive'];
          this.isModulePatientHistoryActive = access['isModulePatientHistoryActive'];
          this.isModuleGoogleReviewActive = access['isModuleGoogleReviewActive'];

          this.isModuleAppointmentActive = access['isModuleAppointmentActive'];
        }
      });
    });
  }

  displayMenu() {
    if ((this.financedProductGroup && this.financedProductGroup.length <= 0) || !this.financedProductGroup) {
      if (this.isModuleTreatmentPlanActive == true && this.isInviteButton == true) {
        if (this.hideQuery === true) {
          this.authenticationService.getCurrentFinancedProductGroupHidden().subscribe((res) => {
            if (res) {
              this.financedProductGroup = res;

              const index = this.financedProductGroup.findIndex((item) => {
                if (item && item['GroupCode'] === 'MEM') {
                  return true;
                }

                return false;
              });

              if (index != -1) {
                this.isSmileClub = true;
              }

              const index2 = this.financedProductGroup.findIndex((item) => {
                if (item && item['GroupCode'] === 'IHF') {
                  return true;
                }

                return false;
              });

              if (index2 != -1) {
                this.isInnerHouse = true;
              }
            }
          });
        } else {
          this.authenticationService.getCurrentFinancedProductGroup().subscribe((res) => {
            if (res) {
              this.financedProductGroup = res;

              const index = this.financedProductGroup.findIndex((item) => {
                if (item && item['GroupCode'] === 'MEM') {
                  return true;
                }

                return false;
              });

              if (index != -1) {
                this.isSmileClub = true;
              }
              const index2 = this.financedProductGroup.findIndex((item) => {
                if (item && item['GroupCode'] === 'IHF') {
                  return true;
                }

                return false;
              });

              if (index2 != -1) {
                this.isInnerHouse = true;
              }
            }
          });
        }
      }
    }
  }

  getLabelFromProductGroup(p) {
    return this.utils.getLabelFromProductGroup(p);
  }

  newMembership(p) {
    if (p && p['GroupCode']) {
      this.router.navigate(['/merchant', { outlets: { page: ['membership-redirection', p['GroupCode']] } }]);
    }
  }

  newPatientInv(p) {
    console.log('new patient invitation');
    if (p && p['GroupCode']) {
      this.authenticationService.getCurrentFinancedProductGroup().subscribe((res) => {
        const check = res.some((val) => {
          if (val['GroupCode'] === p['GroupCode']) {
            return true;
          } else {
            return false;
          }
        });

        if (check == true) {
          this.router.navigate(['/merchant', { outlets: { page: ['new-invitation', p['GroupCode']] } }]);
        } else {
          const confirmDialog = new ConfirmDialogSingleButton(
            'warning',
            p['GroupLabel'] + " hasn't been set up for your practice.",
            'Please contact Smile Right for more information',
            'Okay'
          );
          const ref = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
            data: confirmDialog,
            width: '650px',
          });

          ref.componentInstance.onConfirm.subscribe((res) => {
            ref.close();
          });
        }
      });
    }
  }

  getProductIcon(p) {
    if (p && p['GroupCode']) {
      return this.productUtil.getProductGroupIcon(p['GroupCode']);
    } else {
      return 'envelope-open';
    }
  }

  checkBookingEngine() {
    if (this.currentPractice && this.currentPractice['ThirdPartyBooking_URL']) {
      this.isAppointmentDisabled = true;
    } else {
      this.isAppointmentDisabled = false;
    }
  }

  checkHH() {
    if (this.currentPractice && this.currentPractice['HealthHistoryEnabled.Overridden'] == 'Y') {
      this.healthHistoryActive = true;
    } else {
      this.healthHistoryActive = false;
    }
  }

  applyFinance() {
    const ref = RootAppComponent.dialog.open(SimplePageViewComponent, {
      data: {
        simplePageCode: 'ProductAssitanceTerms',
        title: 'Informed Consent',
        canAccept: true,
        hideHeader: true,
        payload: {},
      },
      width: '800px',
    });

    ref.componentInstance.proceed.subscribe((res) => {
      ref.close();
      ref.afterClosed().subscribe((res) => {
        this.router.navigate(['/merchant', { outlets: { page: ['quick-invitation', 'GPF'] } }]);
      });
    });
  }

  applyFinanceSmileClub() {
    const ref = RootAppComponent.dialog.open(SimplePageViewComponent, {
      data: {
        simplePageCode: 'ProductAssitanceTerms',
        title: 'Informed Consent',
        canAccept: true,
        hideHeader: true,
        payload: {},
      },
      width: '800px',
    });

    ref.componentInstance.proceed.subscribe((res) => {
      ref.close();
      ref.afterClosed().subscribe((res) => {
        this.router.navigate(['/merchant', { outlets: { page: ['quick-invitation-club', 'MEM'] } }]);
      });
    });
  }

  applyInnerHouse() {
    const ref = RootAppComponent.dialog.open(SimplePageViewComponent, {
      data: {
        simplePageCode: 'ProductAssitanceTerms',
        title: 'Informed Consent',
        canAccept: true,
        hideHeader: true,
        payload: {},
      },
      width: '800px',
    });

    ref.componentInstance.proceed.subscribe((res) => {
      ref.close();
      ref.afterClosed().subscribe((res) => {
        this.router.navigate(['/merchant', { outlets: { page: ['quick-invitation-club', 'IHF'] } }]);
      });
    });
  }

  patientLookup() {
    const ref = RootAppComponent.dialog.open(InvitationLookupComponent, {
      data: {
        viewOnClick: false,
        isModal: true,
        useStrict: true,
        isHeader: true,
        displayMerchantList: true,
        displayFinanceOffer: false,
        isNewPatient: true,
        headerTitle: 'New Finance Offer',
        title: 'You are creating a Finance Offer, you can search for them using any (or all) of the field below:',
        headerIcon: 'fas fa-dollar-sign',
      },
      width: '600px',
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res && res.ID) {
        const ref2 = RootAppComponent.dialog.open(InstantOfferCreateComponent, {
          data: {
            cardID: res.ID,
            isActive: true,
            sendSMS: true,
            sendEmail: true,
          },
          panelClass: 'noCard',
        });

        ref2.componentInstance.close.subscribe((res) => {
          ref2.close();
        });

        ref2.componentInstance.getResult.subscribe((r) => {
          if (r && r.ID) {
            NotifyAppComponent.displayToast(
              'success',
              'Successfull',
              'Instant Finance Offer is created and sent to the patient.'
            );

            ref2.close();
          }
        });
      }
    });
    ref.componentInstance.getNewPatient.subscribe((res) => {
      if (res && res.ID) {
        const ref2 = RootAppComponent.dialog.open(InstantOfferCreateComponent, {
          data: {
            cardID: res.ID,
            isActive: true,
            sendSMS: true,
            sendEmail: true,
          },
          panelClass: 'noCard',
        });

        ref2.componentInstance.close.subscribe((res) => {
          ref2.close();
        });

        ref2.componentInstance.getResult.subscribe((r) => {
          if (r && r.ID) {
            NotifyAppComponent.displayToast(
              'success',
              'Successfull',
              'Instant Finance Offer is created and sent to the patient.'
            );

            ref2.close();
          }
        });
      }
    });
    ref.componentInstance.patientChat.subscribe((res) => {
      if (res && res.cardID) {
        if (res.isPromoterOrAdmin == true) {
          const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true,
            },
            panelClass: 'noCard',
            width: '500px',
          });
          ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });

          ref2.componentInstance.getResult.subscribe((data) => {
            if (data) {
              const ref = RootAppComponent.dialog.open(SmsConversationComponent, {
                data,
                panelClass: 'bigger-screen',
                width: '70%',
                height: '95vh',
              });
              const sub = ref.componentInstance.close.subscribe((data) => {
                ref.close();
              });
            }

            ref2.close();
          });
        } else {
          const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true,
            },
            panelClass: 'bigger-screen',
            width: '70%',
            height: '95vh',
          });
          const sub = ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });
        }
      }
    });

    ref.componentInstance.detailedView.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });

    ref.backdropClick().subscribe((res) => {
      ref.close();
    });
  }

  getToolTip(code) {
    if (this.toolTips && this.toolTips[code]) {
      return this.toolTips[code];
    }

    return null;
  }

  displayToolTip(code) {
    if (this.toolTips && this.toolTips[code]) {
      return true;
    }

    return false;
  }
}
