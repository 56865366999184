import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { HeaderService } from '../../../shared/services/header.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { Settings } from '../../../shared/types/settings';
import { MedicalHistoryService } from '../shared/medical-history.service';

@Component({
  selector: 'app-medical-history-card',
  templateUrl: './medical-history-card.component.html',
  styleUrls: ['./medical-history-card.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),

    trigger('ngIfTab', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.3s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.3s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MedicalHistoryCardComponent implements OnInit {
  @Input()
  customerID;
  @Input()
  redirect = true;

  @Output()
  proceed = new EventEmitter();

  sessionType = 'guest';

  medicalHistory;
  @Input()
  displayFooter = true;
  @Input()
  invitation;
  @Input()
  merchant;
  @Input()
  dentist;
  @Input()
  patient;

  isModuleConsumerPortalActive = Settings.global['isModuleConsumerPortalActive'];

  medicalInvitation;
  public config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: true,
    pagination: false,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
  };

  constructor(
    private router: Router,
    private utilService: UtilsService,
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private medicalHistoryService: MedicalHistoryService
  ) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      this.activeRoute.params.subscribe((params) => {
        if (params['customerID']) {
          this.customerID = params['customerID'];
        } else if (params['consumerID']) {
          this.customerID = params['consumerID'];
        }

        this.setup();
      });
    });
  }

  setup() {
    if (this.customerID) {
      this.medicalHistoryService.getMedicalHistoryPatientID(this.customerID, {}, this.sessionType).subscribe((res) => {
        if (res && res.medicalHistory) {
          this.medicalHistory = res.medicalHistory;

          if (this.medicalHistory.ID) {
            this.utilService.getAccessMedicalHistoryList(this.medicalHistory.ID).subscribe((access) => {
              if (access) {
                this.isModuleConsumerPortalActive = access['isModuleConsumerPortalActive'];
              }

              if (res && res.invitation) {
                this.invitation = res.invitation;
              }

              if (res && res.merchant) {
                this.merchant = res.merchant;
              }

              if (res && res.patient) {
                this.patient = res.patient;
              }
              if (res && res.dentist) {
                this.dentist = res.dentist;
              }

              this.build();
            });
          }
        } else {
          this.medicalInvitation = {
            status: 'notExist',
            isValid: false,
            canProceed: false,
          };
        }
      });
    }
  }

  build() {
    if (this.medicalHistory) {
      if (this.sessionType == 'customer' && this.isModuleConsumerPortalActive == false) {
        HeaderService.hideCustomerPortal.emit(false);
      } else if (this.isModuleConsumerPortalActive == false) {
        HeaderService.hideCustomerPortal.emit(true);
      } else {
        HeaderService.hideCustomerPortal.emit(false);
      }

      const signupObject = {
        cardID: null,
      };

      if (this.medicalHistory && this.medicalHistory.Card_key) {
        signupObject.cardID = this.medicalHistory.Card_key;
      }

      AuthenticationService.signupObject.emit(signupObject);

      if (this.merchant) {
        HeaderService.MerchantObject.emit(this.merchant);
        HeaderService.MerchantName.emit(this.merchant['TradingAs']);
      }
    }
  }

  proceedEvent() {
    if (this.redirect == true) {
      if (this.customerID) {
        this.authenticationService.getSessionType().subscribe((res) => {
          if (res && res == 'customer') {
            this.router.navigate(['/consumer-app', { outlets: { page: ['medical', this.customerID, 'step', 1] } }]);
          } else {
            this.router.navigate(['/consumer-app', { outlets: { page: ['medical', this.customerID, 'step', 1] } }]);
          }
        });
      }
    } else {
      this.proceed.emit(true);
    }
  }

  contactPractice(subject: any) {
    if (this.customerID && this.merchant && this.merchant.ID) {
      const data = {
        cardID: this.customerID,
        targetType: 'merchant',
        targetID: this.merchant.ID,
        asGuest: true,
        asProfile: false,
        asPractice: false,
        subject: subject || null,
      };
      AuthenticationService.contactInputEvent.emit(data);

      if (subject.includes('help')) {
        this.medicalHistory.needsHelpWithForm = true;
      }

      if (subject.includes('staff')) {
        this.medicalHistory.speakInPrivateToStaff = true;
      }
    }
  }
}
