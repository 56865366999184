import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of } from 'rxjs';
import { AppStateService } from '../../../core/app-state/app-state.service';
import { DEFAULT_MERCHANT_OPTION } from '../../../feature/merchant/shared/data/merchant';
import { MerchantService } from '../../../feature/merchant/shared/merchant.service';
import { Merchant } from '../../../feature/merchant/shared/types/merchant.type';

@Component({
  selector: 'ipv-promoter-merchant-selector',
  templateUrl: './promoter-merchant-selector.component.html',
  styleUrls: ['./promoter-merchant-selector.component.css'],
})
export class PromoterMerchantSelectorComponent implements OnInit, OnDestroy {
  @Input() startWithAllMerchants = false;
  @Output() merchantSet = new EventEmitter<Partial<Merchant>>();

  payload = { fields: 'ID,TradingAs,CalculatedName,Status,addresses.Calculated,phones.Number' };

  merchant: Partial<Merchant> = null;
  merchants: Partial<Merchant>[] = [];
  allMerchantsOptionValue = DEFAULT_MERCHANT_OPTION;

  constructor(private merchantService: MerchantService, private appStateService: AppStateService) {}

  ngOnInit() {
    this.appStateService
      .getAppState$()
      .mergeMap((appState) => {
        this.merchant = appState.practice;
        this.merchantSet.emit(this.merchant);
        if (appState.isPromoterOrAdmin) {
          return this.merchantService.fetchMerchants$<Merchant>(this.payload).pipe(untilDestroyed(this));
        } else {
          return of([]);
        }
      })
      .subscribe((merchants) => {
        this.merchants = merchants;
      });
  }

  ngOnDestroy() {
    // required for untilDestroyed();
  }

  setMerchant() {
    this.merchantSet.emit(this.merchant);
  }

  matchMerchants(merchantFromList: Partial<Merchant>, selectedMerchant: Partial<Merchant>): boolean {
    return merchantFromList.ID === selectedMerchant.ID;
  }
}
