<div class="row clearfix">
  <div class="pull-right" *ngIf="isModal == true">
    <button class="btn-close btn-clear pull-right mat-button" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div class="row clearfix collapsable" *ngIf="actionLogs">
    <div class="row clearfix flex">
      <!-- <button class="pull-right" matTooltip="Record New Action" (click)="newAction()"><mat-icon class="fas fa-plus"></mat-icon></button> -->
      <h1 class="row clearfix rm-mt full-width">

        View notes
        <span class="badge">
          <mat-icon>new_releases</mat-icon>
          <span [endVal]="actionLogs.length" countUp></span>
        </span>
      </h1>
      <div class="row clearfix flex-center flex clearfix full-width">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Filter</mat-label>
          <input name="filter" matInput placeholder="Filter by date, type or label"/>
        </mat-form-field>

        <mat-button-toggle-group
          class="btn-group full-width rm-mt"
          *ngIf="isPromoterOrAdmin == true"
          [(ngModel)]="typeFilter"
          (change)="setFilter()"
        >
          <mat-button-toggle [value]="'none'">
            All
          </mat-button-toggle>
          <mat-button-toggle [value]="'manual'"> Manual</mat-button-toggle>
          <mat-button-toggle [value]="'auto'"> Auto</mat-button-toggle>
          <mat-button-toggle [value]="'internal'"> Internal</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <hr/>
    <!-- IF NO MODAL -->
    <mat-dialog-content *ngIf="actionLogs && actionLogs.length > 0 && isModal == false">
      <vertical-timeline [@stagger]="actionLogs.length">
        <vertical-timeline-card
          class=""
          *ngFor="let c of actionLogs"
          [@fade]
          [color]="actionLogUtil.getTypeColor(c['Action'])"
          [dateValue]="c['DateTimeCreated'] | convertDate"
          [timeString]="c['DateTimeCreated'] | customDateTime: 'HH:mm'"
          [ngClass]="c['Subject']"
        >
          <button class="pull-right white" [matMenuTriggerFor]="menu" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button (click)="viewLog(c['ID'])" mat-menu-item>
              <mat-icon>insert_chart_outlined</mat-icon>
              <span>View detail</span>
            </button>
          </mat-menu>

          <div class="row clearfix"></div>

          <mat-accordion>
            <mat-expansion-panel>
              <!-- // using actionLogUtil.getTypeColor(c['Action']) to get color  -->

              <mat-expansion-panel-header>
                <h3>
                  <mat-icon class="fas" [ngClass]="actionLogUtil.getSubjectIcon(c['Action'], c['Subject'])"></mat-icon>
                  <span class="badge ml">{{ c['Action'] }}</span>
                  {{ c['Subject'] }}
                </h3>
              </mat-expansion-panel-header>
              <div class="row clearfix">
                <div class="row clearfix">
                  <p class="small"><strong>Operator: </strong>{{ c['Operator_Name'] }}</p>
                </div>
                <div class="row clearfix">
                  <p class="small"><strong>Description: </strong>{{ c['Description'] }}</p>
                </div>
                <div class="row clearfix">
                  <p class="small"><strong>Result: </strong>{{ c['Result'] }}</p>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </vertical-timeline-card>
      </vertical-timeline>
    </mat-dialog-content>
    <!-- IF IN MODAL -->
    <mat-dialog-content *ngIf="actionLogs && actionLogs.length > 0 && isModal == true">
      <vertical-timeline [@stagger]="actionLogs.length">
        <!-- Add some cards to the timeline. It works with *ngFor too -->

        <!-- If no value is passed for the date, the current time is used. -->
        <!-- <vertical-timeline-card [dateValue]="myDate" [timeString]="'15:18'">
           <h1>Today</h1>
          </vertical-timeline-card> -->

        <vertical-timeline-card
          class=""
          *ngFor="let c of actionLogs"
          [@fade]
          [dateValue]="c['DateTimeCreated'] | convertDate"
          [timeString]="c['DateTimeCreated'] | customDateTime: 'HH:mm'"
          [ngClass]="c['Subject']"
        >
          <button class="pull-right white" [matMenuTriggerFor]="menu" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button (click)="viewLog(c['ID'])" mat-menu-item>
              <mat-icon>insert_chart_outlined</mat-icon>
              <span>View detail</span>
            </button>
          </mat-menu>

          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <h3>
                  <mat-icon class="fas" [ngClass]="actionLogUtil.getSubjectIcon(c['Action'], c['Subject'])"></mat-icon>
                  <span class="badge ml">{{ c['Action'] }}</span>
                  {{ c['Subject'] }}
                </h3>
              </mat-expansion-panel-header>
              <div class="row clearfix">
                <p class="small"><strong>Result: </strong>{{ c['Result'] }}</p>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </vertical-timeline-card>

        <!-- doc : https://github.com/TechGapItalia/angular-vertical-timeline -->
      </vertical-timeline>
    </mat-dialog-content>

    <mat-dialog-content>
      <app-empty-list-message
        class="full-width row clearfix"
        *ngIf="actionLogs && actionLogs.length == 0"
        [title]="''"
        [message]="' No log found'"
      ></app-empty-list-message>
    </mat-dialog-content>
  </div>
</div>
