<h2
  class="text-center clearfix mb mat-h2 no-p brandName"
  *ngIf="sessionTypeParams == 'admin'"
  matTooltip="Help Center viewed as Admin"
  matRipple
>
  <mat-icon class="fas fa-users-cog left-menu-icon mr"></mat-icon>
  Admin
</h2>
<h2
  class="text-center clearfix mb mat-h2 no-p brandName"
  *ngIf="sessionTypeParams == 'promoter'"
  matTooltip="Help Center viewed as Promoter"
  matRipple
>
  <mat-icon class="fas fa-universal-access left-menu-icon mr"></mat-icon>
  Promoter
</h2>
<h2
  class="text-center clearfix mb mat-h2 no-p brandName"
  *ngIf="sessionTypeParams == 'funder'"
  matTooltip="Help Center viewed as Funder"
  matRipple
>
  <mat-icon class="fas fa-user-shield left-menu-icon mr"></mat-icon>
  Funder
</h2>
<h2
  class="text-center clearfix mb mat-h2 no-p brandName"
  *ngIf="sessionTypeParams == 'merchant'"
  matTooltip="Help Center viewed as Merchant"
  matRipple
>
  <mat-icon class="fas fa-user-tie left-menu-icon mr"></mat-icon>
  Merchant
</h2>

<h2
  class="text-center clearfix mb mat-h2 no-p brandName"
  *ngIf="sessionTypeParams == 'supplier'"
  matTooltip="Help Center viewed as Supplier"
  matRipple
>
  <mat-icon class="fas fa-user-tag left-menu-icon mr"></mat-icon>
  Supplier
</h2>
<h2
  class="text-center clearfix mb mat-h2 no-p brandName"
  *ngIf="sessionTypeParams == 'customer'"
  matTooltip="Help Center viewed as Customer"
  matRipple
>
  <mat-icon class="fas fa-user left-menu-icon mr"></mat-icon>
  Customer
</h2>
<h2
  class="text-center clearfix mb mat-h2 no-p brandName"
  *ngIf="sessionTypeParams == 'guest'"
  matTooltip="Help Center viewed as Guest"
  matRipple
>
  <mat-icon class="fas fa-user-astronaut left-menu-icon mr"></mat-icon>
  Guest
</h2>

<div class="full-width flexcenter text-center clearfix mt" *ngIf="loading == true">
  <i class="fas fa-spinner fa-pulse extra-header-icon-hard"> </i>
</div>

<div class="sideNavAccordion rel mt" *ngIf="loading != true && groups && groups.length > 0">
  <!-- accordion style nav for nested pages -->
  <mat-accordion *ngFor="let group of groups">
    <mat-expansion-panel *ngIf="group.pagesLength > 0 && group.pages && group.pages.length > 0">
      <mat-expansion-panel-header class="expanded-class">
        <h5 class="rm header-link-class">
          <mat-icon
            class="{{ group.icon }} extra-header-icon"
            *ngIf="group && group.icon && isAwsome(group.icon) == true"
          >
          </mat-icon>
          <mat-icon class="extra-header-icon" *ngIf="group && group.icon && isAwsome(group.icon) != true"
          >{{ group.icon }}
          </mat-icon>

          {{ group.title }}

          <span class="value chip new" *ngIf="isNew(group['created_at']) == true">NEW</span>
        </h5>
      </mat-expansion-panel-header>

      <!-- list of pages in subNav -->
      <mat-list>
        <mat-list-item matRipple>
          <a class="clearfix link-class" (click)="moveToGroup(group._id)" mat-button> Overview </a>
          <mat-divider></mat-divider>
        </mat-list-item>

        <mat-list-item *ngFor="let p of group.pages | OrderArrayObject: group.orderBy" matRipple>
          <a class="clearfix link-class" (click)="moveToPage(p._id)" mat-button>
            {{ p.title }}
          </a>

          <span class="value chip new" *ngIf="isNew(p['created_at']) == true">NEW</span>

          <mat-divider></mat-divider>
        </mat-list-item>
      </mat-list>
    </mat-expansion-panel>

    <div class="single" *ngIf="group.pagesLength == 0">
      <h5 class="rm header-link-class" (click)="moveToGroup(group._id)" matRipple>
        <mat-icon
          class="{{ group.icon }} extra-header-icon"
          *ngIf="group && group.icon && isAwsome(group.icon) == true"
        >
        </mat-icon>
        <mat-icon class="extra-header-icon" *ngIf="group && group.icon && isAwsome(group.icon) != true"
        >{{ group.icon }}
        </mat-icon>

        {{ group.title }}

        <span class="value chip new" *ngIf="isNew(group['created_at']) == true">NEW</span>
      </h5>
    </div>
  </mat-accordion>
</div>
