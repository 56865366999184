<div class="row clearfix">
  <div class="card-header primary-gradient-img clearfix inModal">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeEvent()" tabIndex="-1">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <mat-icon class="pull-left">alarm</mat-icon>
        <h2 class="summary-header rm-m">
          <!-- {{ beneficiary.CalculatedName}} -->
          Define practicing hours
        </h2>
      </div>
    </div>
  </div>

  <mat-dialog-content class="full-width maxHeight">
    <div class="clearfix row" *ngIf="isPromoterOrAdmin == true">
      <div class="row clearfix well selectMerchant" *ngIf="isMerchant == true">
        <mat-form-field class="margin-btm half-width" appearance="outline">
          <mat-label>Please Select a Merchant</mat-label>
          <mat-select class=" " [(ngModel)]="merchantID" name="MerchantName" placeholder="Please Select a Merchant">
            <mat-option [value]="null"> My Account</mat-option>
            <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m['ID']">
              <span
                class="list-label pull-right"
                *ngIf="m['Status'] && m['Status'] == 'Active'"
                [style.background]="'#00d07a'"
              >
                {{ m['Status'] }}
              </span>

              <span
                class="list-label pull-right"
                *ngIf="m['Status'] && m['Status'] != 'Active'"
                [style.background]="'#ff5722'"
              >
                {{ m['Status'] }}
              </span>
              {{ m['TradingAs'] || m['CalculatedName'] }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <button
          class="pull-right merchBtn"
          *ngIf="merchantID && merchantID != 'none'"
          (click)="viewMerchant()"
          mat-raised-button
          color="primary"
        >
          <mat-icon>person</mat-icon>
          <span class="mobHide">View Merchant</span>
        </button>
      </div>

      <div class="row clearfix well selectMerchant" *ngIf="isDenist == true">
        <mat-form-field class="margin-btm half-width" appearance="outline">
          <mat-label>Please Select a {{ "KEYWORD.practitioner" | translate | titlecase }}</mat-label>
          <mat-select class=" " [(ngModel)]="dentistID" name="MerchantName" placeholder="Please Select a Merchant">
            <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m['ID']">
              <span
                class="list-label pull-right"
                *ngIf="m['Status'] && m['Status'] == 'Active'"
                [style.background]="'#00d07a'"
              >
                {{ m['Status'] }}
              </span>

              <span
                class="list-label pull-right"
                *ngIf="m['Status'] && m['Status'] != 'Active'"
                [style.background]="'#ff5722'"
              >
                {{ m['Status'] }}
              </span>
              {{ m['TradingAs'] || m['CalculatedName'] }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <button class="pull-right merchBtn" *ngIf="dentistID" (click)="viewDentist()" mat-raised-button color="primary">
          <mat-icon>person</mat-icon>
          <span class="mobHide">View {{ "KEYWORD.practitioner" | translate | titlecase }}</span>
        </button>
      </div>
    </div>

    <hr class="mb"/>
    <div class="row clearfix">
      <app-merchant-trading-hours
        *ngIf="loaded == true"
        [dentistID]="dentistID"
        [merchantID]="merchantID"
        [isSetup]="isSetup"
        (close)="closeModal($event)"
        (getTradingHours)="getTradingHours.emit($event)"
      ></app-merchant-trading-hours>
    </div>
  </mat-dialog-content>
</div>
