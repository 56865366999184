import { Action, createReducer, on } from '@ngrx/store';
import { Dentist } from '../../feature/dentist/shared/dentist.type';
import { Merchant } from '../../feature/merchant/shared/types/merchant.type';
import { SessionType } from '../../shared/types/session-type.type';
import { ModuleOptions, PublicSettings } from '../../shared/types/settings';
import { User } from '../authentication/shared/shared/types/authentication.type';
import {
  setCurrentDentistAction,
  setCurrentIsPromoterOrAdminAction,
  setCurrentPracticeAction,
  setCurrentPublicModulesAction,
  setCurrentPublicSettingsAction,
  setCurrentSessionTypeAction,
  setCurrentUserAction,
} from './app-state.actions';
import {
  initialDentist,
  initialIsPromoterOrAdmin,
  initialPractice,
  initialPublicModules,
  initialPublicSettings,
  initialSessionType,
  initialUser,
} from './app-state.state';

const originalCurrentPracticeReducer = createReducer(
  initialPractice,
  on(setCurrentPracticeAction, (state, action) => {
    const practice = action.practice;

    if (practice === undefined) {
      return state;
    } else {
      return practice;
    }
  })
);

export function currentPracticeReducer(state: Partial<Merchant>, action: Action) {
  return originalCurrentPracticeReducer(state, action);
}

const originalCurrentUserReducer = createReducer(
  initialUser,
  on(setCurrentUserAction, (state, action) => {
    const user = action.user;

    if (user === undefined) {
      return state;
    } else {
      return user;
    }
  })
);

export function currentUserReducer(state: User, action: Action) {
  return originalCurrentUserReducer(state, action);
}

export const originalCurrentSessionTypeReducer = createReducer(
  initialSessionType,
  on(setCurrentSessionTypeAction, (state, action) => action.sessionType)
);

export function currentSessionTypeReducer(state: SessionType, action: Action) {
  return originalCurrentSessionTypeReducer(state, action);
}

export const originalCurrentIsPromoterOrAdminReducer = createReducer(
  initialIsPromoterOrAdmin,
  on(setCurrentIsPromoterOrAdminAction, (state, action) => action.isPromoterOrAdmin)
);

export function currentIsPromoterOrAdminReducer(state: boolean, action: Action) {
  return originalCurrentIsPromoterOrAdminReducer(state, action);
}

export const originalCurrentDentistReducer = createReducer(
  initialDentist,
  on(setCurrentDentistAction, (state, action) => action.dentist)
);

export function currentDentistReducer(state: Dentist, action: Action) {
  return originalCurrentDentistReducer(state, action);
}

export const originalCurrentPublicSettingsReducer = createReducer(
  initialPublicSettings,
  on(setCurrentPublicSettingsAction, (state, action) => action.publicSettings)
);

export function currentPublicSettingsReducer(state: PublicSettings, action: Action) {
  return originalCurrentPublicSettingsReducer(state, action);
}

export const originalCurrentPublicModulesReducer = createReducer(
  initialPublicModules,
  on(setCurrentPublicModulesAction, (state, action) => action.publicModules)
);

export function currentPublicModulesReducer(state: ModuleOptions, action: Action) {
  return originalCurrentPublicModulesReducer(state, action);
}
