<!-- Modal dismiss button -->
<div class="rel consFaqModal">
  <div class="card-header primary-gradient-img clearfix inModal">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear mat-button white" matDialogClose>
        <mat-icon>clear</mat-icon>
      </button>
      <!-- merchant calculated name -->
      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m">Frequently asked questions</h2>
      </div>
    </div>
  </div>
  <mat-tab-group class="controlHeight" id="faq-tabs" dynamicHeight="true">
    <!-- <div class="row clearfix">
    <app-simple-page-view [simplePageCode]="simplePageCode"> </app-simple-page-view>
  </div> -->
    <!-- <div> -->
    <mat-tab label="general-faq">
      <mat-dialog-content>
        <ng-template mat-tab-label>Frequently Asked Questions</ng-template>

        <mat-accordion class="faqAccordion">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>Am I eligble for a Personal Loan?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <h3>Eligibility:</h3>
            <ul>
              <li>Be at least 18 years old.</li>
              <li>Have regular permanent income.</li>
              <li>Be an Australian citizen, permanent resident or have a valid visa*.</li>
            </ul>
            <p class="small">
              *
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/email-graphics-smileright/docs-17/list-acceptable-visas.pdf"
                target="_blank" title="List of acceptable visas" angulartics2On="click"
                analytics-event="Download Visa Form">Valid&nbsp;visa (PDF 183kb)</a>&nbsp;(Opens in new window) To find
              out whether your Visa qualifies you please feel free call our
              Personal Lending Centre on {{ 'BRANDING.Contact Phone' | translate }} to discuss.
            </p>
            <h3>What information do I need to provide?</h3>
            <ul>
              <li>Details of your current financial situation.(Statement)</li>
              <li>Proof of employment (Current payslip).</li>
              <li>Proof of Identity (If we are unable to identify you electronically).</li>
            </ul>
            <p>
              We may use the contact information that you give us to contact you to help you complete your application
              or if we need to discuss your application with you.
            </p>
            <h3>What happens when I apply?</h3>
            <p>
              When you have finished completing the application we will give you feedback on your application, typically
              in 60 seconds. Once conditionally approved your contract is presented online for your review and
              electronic acceptance. <br/>
              After we have completed our loan assessment and your loan is approved, a copy of your accepted contract
              will then be emailed to you for your records.
            </p>
            <p>
              {{ 'PARAGRAPH.Core FAQ Modal Notified' | translate }}
            </p>
            <p>It's that easy!</p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>Am I eligible for a Payment Plan?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <h3>Eligibility:</h3>
            <ul>
              <li>Be at least 18 years old.</li>
              <li>Have regular permanent income.</li>
              <li>Be an Australian citizen, permanent resident or have a valid visa*.</li>
            </ul>
            <p class="small">
              *
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/email-graphics-smileright/docs-17/list-acceptable-visas.pdf"
                target="_blank" title="List of acceptable visas" angulartics2On="click"
                analytics-event="Download Visa Form">Valid&nbsp;visa (PDF 183kb)</a>&nbsp;(Opens in new window) To find
              out whether your Visa qualifies you please feel free call our
              Personal Lending Centre on {{ 'BRANDING.Contact Phone' | translate }} to discuss.
            </p>
            <h3>What information do I need to provide?</h3>
            <ul>
              <li>Basic personal information.</li>
              <li>Proof of Identity (If we are unable to identify you electronically)</li>
            </ul>
            <p class="dark">
              We may use the contact information that you give us to contact you to help you complete your application.
            </p>
            <h3>What happens when I apply?</h3>
            <p class="dark">
              The application process is "real-time" and will provide you an instant credit decision based on the
              information you provide. Once approved your contract is presented online for your review and electronic
              acceptance. A copy of your accepted contract will then be emailed to you for your records and
              {{ 'PARAGRAPH.Core FAQ Modal Notified' | translate }}
            </p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>How can I apply?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <p>
              Apply online, for personal loans it usually takes around 15 minutes and around 5 minutes for a payment
              plan.
            </p>
            <p>Click <a [routerLink]="['../products']">here</a> to apply.</p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>What {{"KEYWORD.procedure" | translate | titlecase}}s can I apply for?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <p>
              You can apply to pay for any {{ 'PHRASE.dental treatment' | translate }} or
              {{ 'KEYWORD.procedure' | translate }} provided by an {{ "BRANDING.Brand Name" | translate | titlecase }}
              approved
              {{ "KEYWORD.practice" | translate }}. For a full list of what you can apply for - Click
              <a [routerLink]="['../finance']">here</a>.
            </p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>What is the Limit for a Personal Loan?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <p>You can apply online to borrow <strong>up to $30,000.</strong></p>
            <p>If you need an amount greater then this call our friendly team to discuss your needs.</p>
          </mat-expansion-panel>
          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>What is the Limit of a Payment Plan?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <p>You can apply online for any amount <strong>between $100 and $2000.</strong></p>
            <p>
              If you need an amount greater then this, please call our friendly team to discuss your needs on
              <strong>{{ 'BRANDING.Contact Phone' | translate }}.</strong>
            </p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>What do I need in order to apply?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <h3>Personal Loan</h3>
            <ul class="disc">
              <li>Details of your current financial situation.</li>
              <li>Proof of employment (Current payslip).</li>
              <li>Proof of Identity (If we are unable identify you electronically).</li>
            </ul>
            <h3>Payment Plan</h3>
            <ul class="disc">
              <li>Basic personal information.</li>
              <li>Proof of Identity (If we are unable to electronically identify you).</li>
            </ul>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>How long does it take for Approval?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <p>
              The application process is "real-time" and will provide you an instant decision based on the information
              you provide. Once approved your contract is presented online for your review and electronic acceptance.
            </p>
            <p>
              A copy of your accepted contract will then be emailed to you for your records and
              {{ 'PARAGRAPH.Core FAQ Modal Notified' | translate }}.
            </p>
            <p>It's that easy!</p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>I've used up my Health Insurance Benefits. Can I apply?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <p>Yes, you can still apply and we are here to help. For a full list of what you can apply for</p>
            <p>Click Here!</p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>What are the Costs of {{ "KEYWORD.treatment" | translate | titlecase }} Provided?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <p>
              Fees and charges will vary depending on the product and
              {{ "KEYWORD.practitioner" | translate }} selected.
            </p>
            <p>They are all outlined <a>here.</a></p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>How much are Membership Plans?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <p>It depends on what your {{ "KEYWORD.practice" | translate }} offers.</p>
            <p>
              {{ 'PARAGRAPH.Core FAQ Consumer Modal Membership Plan Cost.' | translate }}
            </p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>How does a Membership Plan save me money?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <p>
              {{ 'PARAGRAPH.Core FAQ Consumer Modal Save Money' | translate }}
            </p>
            <p>{{ 'PARAGRAPH.Core FAQ Consumer Modal Insurance Premiums' | translate }}</p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>What Benefits do I get from a {{ "KEYWORD.dental" | translate | titlecase }} Membership
                  Plan?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <p>
              These vary according to what your {{ "KEYWORD.practice" | translate }} offers. Why don’t you ask them? If
              they don’t have a {{ "KEYWORD.dental" | translate | titlecase }} Membership plan, let us know and we’ll
              get in touch
              with them (<a>Click here</a> to populate the invite a {{ "KEYWORD.practitioner" | translate }} form).
            </p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>How can I work out my savings on a Membership Plan against my Current Health Insurance?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <p>
              Call your health insurance provider and ask them how much your monthly premiums would be if you removed
              your top dental cover. <strong>Remember</strong>, regular dental care can save you from experiencing
              dental pain and cost you less in the long run Prevention is better than the cure!
            </p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>Can I have Membership Benefits and still claim my Health Insurance Benefits?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <p>
              Yes, you definitely can still claim your health insurance benefits on top of the     {{ "KEYWORD.practice" | translate }} membership
              benefits.
            </p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>What happens if I Cancel my Membership?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <p>
              You will no longer receive the benefits of the membership plan. It is best to engage your
              {{ "KEYWORD.practice" | translate }} on their terms and conditions of membership cancellation.
            </p>
            <p class="small">Most terms and conditions will require a minimal notice period.</p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>How do I Sign-Up for a Membership Plan?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <p>
              Click here to fill out your details and we will get in touch with you shortly!
              <!--  (link to Keep Me Informed form) -->
            </p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>Do all {{ 'KEYWORD.Dental Practices' | translate }} have membership
                  plans?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <p>
              NO, not all of them but you’re right! They should! Let them know that
              {{ "BRANDING.Brand Name" | translate | titlecase }} can help them to help you! Click here to get them in
              touch with
              us.
              <!-- Link to invite -->
            </p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>My {{"KEYWORD.practitioner" | translate}} is a preferred provider and I pay no gap for Hygiene
                  Appointments?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <p>
              After your routine care appointment we will issue you with a receipt for the total cost of
              the {{"KEYWORD.treatment" | translate}}.
              You can then claim the full rebate from your insurance provider. You will not pay any money to the
              {{"KEYWORD.practice" | translate}} on the day of your hygiene appointment as it is included in the monthly
              membership fee.
            </p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>Have a question that’s not been answered here?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <p>
              Please contact a member of our team on <strong>{{ 'BRANDING.Contact Phone' | translate }}</strong>.
            </p>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-dialog-content>
    </mat-tab>

    <mat-tab label="existing-customer">
      <mat-dialog-content>
        <ng-template mat-tab-label>Existing Customers</ng-template>

        <mat-accordion class="faqAccordion">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>What are your business opening hours?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <p>
              We are open <strong>{{ 'BRANDING.Operating Hours AWST' | translate }}</strong>
              <br/>
              ({{ 'BRANDING.Operating Hours EST' | translate }})
            </p>
            <p class="small">*non daylight savings time</p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>Is there a contact number I can call for support?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <p>
              If you have any queries please call our friendly support team on
              <strong>{{ 'BRANDING.Contact Phone' | translate }}</strong>
            </p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>Is it possible to change my repayment dates?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <p>
              If you need to change the date of your repayments please call our friendly support team on
              <strong>{{ 'BRANDING.Contact Phone' | translate }}</strong>
            </p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>Are you having trouble with repayments?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <p>We are sorry to hear that.</p>
            <p>
              Life doesn't always go as planned, and at {{ "BRANDING.Brand Name" | translate | titlecase }} we
              understand that
              sometimes you might need some assistance and we are keen to help.
            </p>
            <p>Click <a [routerLink]="['../consumer-help-hardship']">here</a> to find out more.</p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>Can I nominate someone else to discuss my {{ "BRANDING.Brand Name" | translate | titlecase }}
                  account?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <p>
              If you wish to nominate someone to discuss your account with us
              <a href="/assets/docs/letter_of_authority.pdf" target="_blank" title="Letter of Authority Form"
                 angulartics2On="click" analytics-event="Download Letter of Authority">click here</a>
              to complete the following form.
            </p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>I am unhappy with my {{ 'PHRASE.dental treatment' | translate }} I have received. What is my
                  next
                  step?
                </strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <p>Sorry to hear that.</p>
            <p>
              If you are having difficulty with your {{ "KEYWORD.practitioner" | translate }} over a
              {{ "KEYWORD.treatment" | translate }} or {{ 'KEYWORD.procedure' | translate }} we encourage you to put
              your compliant in writing and talk to your {{ "KEYWORD.practitioner" | translate }} to try and resolve the
              issue first.
            </p>
            <p>If you are unable to resolve the issue there is a formal channel for you to register your complaint.</p>
            <p>
              Australian Health {{ "KEYWORD.practitioner" | translate | titlecase }} Regulation Authority (AHPRA) -
              <a href="http://www.ahpra.gov.au/Notifications/Make-a-complaint.aspx" target="_blank"
                 [title]="'Australian Health ' + ('KEYWORD.practitioner' | translate | titlecase) + ' Regulation Authority'"
                 angulartics2On="click" analytics-event="Clicked AHPRA Link">Click here</a>
              to find out more.
            </p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>Are you unhappy or dissatisfied with the current service you are receiving?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <p class="dark">
              {{ "BRANDING.Brand Name" | translate | titlecase }} strives to provide the highest possible level of
              customer service
              to assure consumers that they can have confidence when dealing with our company at any level.
            </p>
            <p class="dark">
              We are proud of our customer service culture and make every effort to ensure that our customers are
              satisfied with the service they receive. However should our customers feel that our service is not what it
              should be, we want to hear about it and resolve any issues.
            </p>
            <p class="dark">We undertake to reply promptly to you once we are in receipt of any complaint.</p>
            <p class="dark">
              Should you wish to make a compliant you can either direct your enquiry to us via one of the following
              options;
            </p>
            <h3>Online</h3>
            <p class="dark">Complete the online form found <a [routerLink]="['../contact']">here</a>.</p>
            <h3>Phone</h3>
            <p class="dark">Feel free to call {{ 'BRANDING.Contact Phone' | translate }}</p>
            <h3>Postal</h3>
            <p class="dark">
              Compliance Manager <br/>
              {{ 'BRANDING.Contact PO Box' | translate }} <br/>
              {{ 'BRANDING.Contact Street Address' | translate }}
            </p>
            <p class="dark">Please allow up to 10 business days for a response.</p>
            <p class="dark bold">What happens if you are still unhappy with the outcome?</p>
            <p class="dark">
              If after lodging a compliant with and providing us with time to respond and we do not reach agreement on
              your complaint what is next?
            </p>
            <p class="dark">
              You can refer certain matters to the Credit and Investments Ombudsman (CIO), who can investigate and
              resolve disputes of up to $250,000. This service is free to you. You can lodge your complaint by phone,
              fax or mail.
            </p>
            <p class="dark bold">Credit Ombudsman Service Limited</p>
            <h3>Postal</h3>
            <p class="dark">
              PO Box A252 <br/>
              Sydney South NSW 1235
            </p>
            <h3>Phone</h3>
            <p class="dark">1800 138 422</p>
            <h3>Website</h3>
            <p class="dark">
              <a href="http://www.cio.org.au" title="Credit Ombudsman Service Limited" target="_blank"
                 angulartics2On="click" analytics-event="Clicked Ombudsman Link">http://www.cio.org.au</a>
            </p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>Would you like to make an alteration to your membership plan?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <p>
              You can speak directly with your {{ 'PHRASE.dental practice' | translate }}
              to change your membership plans at any time.
            </p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>Need assistance in relation to your {{ 'PHRASE.dental practice' | translate }}?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <p>
              Simply call your friendly {{ "KEYWORD.practice" | translate }} team member and they’ll be happy to help.
            </p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>Can my {{ "KEYWORD.practice" | translate }} change the terms of my membership plan?</strong>
              </mat-panel-title>
              <mat-panel-description> &nbsp;</mat-panel-description>
            </mat-expansion-panel-header>
            <p>
              Yes, they can, and they will also inform you of any changes before it happens. That is why seeing your
              {{ "KEYWORD.practice" | translate }} on a regular basis ensures you get the most updated information.
            </p>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-dialog-content>
    </mat-tab>
    <!-- </div> -->
    <!-- ngif -->

    <!-- <div> -->
    <!-- ALL THE CONSUMER FAQ STUFF -->
    <!-- <mat-tab label="consumer-faq1" *ngIf="usertype=='admin'">
      <ng-template mat-tab-label><strong>Frequently Asked Questions</strong></ng-template>
      <mat-dialog-content>
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>What are your business opening hours?</strong>
              </mat-panel-title>
              <mat-panel-description>
                &nbsp;
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p>We are open <strong>Monday to Friday 7am to 5pm AWST.</strong>
              <br> (10:30am to 7PM EST*)</p>
            <p class="small">*non daylight savings time</p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>Is there a contact number I can call for support?</strong>
              </mat-panel-title>
              <mat-panel-description>
                &nbsp;
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p>If you have any queries please call our friendly support team on <strong>{{ 'BRANDING.Contact Phone' | translate }}</strong></p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>Is it possible to change my repayment dates?</strong>
              </mat-panel-title>
              <mat-panel-description>
                &nbsp;
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p>If you need to change the date of your repayments please call our friendly support team on <strong>1300
                793 983</strong></p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>Are you having trouble with repayments?</strong>
              </mat-panel-title>
              <mat-panel-description>
                &nbsp;
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p>We are sorry to hear that.</p>
            <p>Life doesn't always go as planned, and at   {{"BRANDING.Brand Name" | translate}} we understand that sometimes you might need some
              assistance and we are keen to help. </p>
            <p>Click <a [routerLink]=" ['../consumer-help-hardship'] ">here</a> to find out more.</p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>Can I nominate someone else to discuss my   {{"BRANDING.Brand Name" | translate}} account?</strong>
              </mat-panel-title>
              <mat-panel-description>
                &nbsp;
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p>If you wish to nominate someone to discuss your account with us <a href="/assets/docs/letter_of_authority.pdf"
                target="_blank" title="Letter of Authority Form" angulartics2On="click" analytics-event="Download Letter of Authority">click
                here</a> to complete the following form.</p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>I am unhappy with my dental {{ "KEYWORD.treatment" | translate }} I have received. What is my next step? </strong>
              </mat-panel-title>
              <mat-panel-description>
                &nbsp;
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p>Sorry to hear that.</p>
            <p>If you are having difficulty with your {{ "KEYWORD.practitioner" | translate }} over a {{ "KEYWORD.treatment" | translate }} or procedure we encourage you to put your
              compliant in writing and talk to your {{ "KEYWORD.practitioner" | translate }} to try and resolve the issue first.</p>
            <p>If you are unable to resolve the issue there is a formal channel for you to register your complaint.</p>
            <p>Australian Health {{ "KEYWORD.practitioner" | translate | titlecase }} Regulation Authority (AHPRA) - <a href="http://www.ahpra.gov.au/Notifications/Make-a-complaint.aspx"
                target="_blank" title="Australian Health {{ "KEYWORD.practitioner" | translate | titlecase }} Regulation Authority" angulartics2On="click"
                analytics-event="Clicked AHPRA Link">Click here</a> to find out more.</p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>Are you unhappy or dissatisfied with the current service you are receiving?</strong>
              </mat-panel-title>
              <mat-panel-description>
                &nbsp;
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p class="dark">  {{"BRANDING.Brand Name" | translate}} strives to provide the highest possible level of customer service to assure
              consumers that they can have confidence when dealing with our company at any level.</p>
            <p class="dark">We are proud of our customer service culture and make every effort to ensure that our
              customers are satisfied with the service they receive. However should our customers feel that our service
              is not what it should be, we want to hear about it and resolve any issues.</p>
            <p class="dark">We undertake to reply promptly to you once we are in receipt of any complaint.</p>
            <p class="dark">Should you wish to make a compliant you can either direct your enquiry to us via one of the
              following options;</p>
            <h3>Online</h3>
            <p class="dark">Complete the online form found <a [routerLink]=" ['../contact'] ">here</a>.</p>
            <h3>Phone</h3>
            <p class="dark">Feel free to call {{ 'BRANDING.Contact Phone' | translate }}</p>
            <h3>Postal</h3>
            <p class="dark">Compliance Manager
              <br> PO Box 7795
              <br> Cloisters Square WA 6850</p>
            <p class="dark">Please allow up to 10 business days for a response.</p>
            <p class="dark bold">What happens if you are still unhappy with the outcome?</p>
            <p class="dark">If after lodging a compliant with and providing us with time to respond and we do not reach
              agreement on your complaint what is next?</p>
            <p class="dark">You can refer certain matters to the Credit and Investments Ombudsman (CIO), who can
              investigate and resolve disputes of up to $250,000. This service is free to you. You can lodge your
              complaint by phone, fax or mail.</p>
            <p class="dark bold">Credit Ombudsman Service Limited</p>
            <h3>Postal</h3>
            <p class="dark">PO Box A252
              <br> Sydney South NSW 1235</p>
            <h3>Phone</h3>
            <p class="dark">1800 138 422</p>
            <h3>Website</h3>
            <p class="dark"><a href="http://www.cio.org.au" title="Credit Ombudsman Service Limited" target="_blank"
                angulartics2On="click" analytics-event="Clicked Ombudsman Link">http://www.cio.org.au</a></p>
          </mat-expansion-panel>


          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>Would you like to make an alteration to your membership plan?</strong>
              </mat-panel-title>
              <mat-panel-description>
                &nbsp;
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p>You can speak directly with your {{ "KEYWORD.dental" | translate }} {{ "KEYWORD.practice" | translate }} to change your membership plans at any time.</p>
          </mat-expansion-panel>


          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>Need assistance in relation to your {{ "KEYWORD.dental" | translate }} {{ "KEYWORD.practice" | translate }}?</strong>
              </mat-panel-title>
              <mat-panel-description>
                &nbsp;
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p>Simply call your friendly practice team member and they’ll be happy to help.</p>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>Can my practice change the terms of my membership plan?</strong>
              </mat-panel-title>
              <mat-panel-description>
                &nbsp;
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p>Yes, they can, and they will also inform you of any changes before it happens. That is why seeing your
              practice on a regular basis ensures you get the most updated information.</p>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-dialog-content>
    </mat-tab> -->
  </mat-tab-group>

  <!-- </div> -->
</div>
