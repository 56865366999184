import { Component, Input, OnInit } from '@angular/core';
import { Settings } from '../../../shared/types/settings';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-media-gallery-documents',
  templateUrl: './media-gallery-documents.component.html',
  styleUrls: ['./media-gallery-documents.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MediaGalleryDocumentsComponent implements OnInit {
  @Input()
  hasFilter = true;

  @Input()
  contentType = 'all';

  settings = Settings.global;

  directorys = [
    {
      name: 'Documents Home',
    },
  ];
  folders = [
    {
      name: 'Contracts',
      updated: new Date('1/1/16'),
    },
    {
      name: 'Informed Consent',
      updated: new Date('1/17/16'),
    },
  ];
  files = [
    {
      name: 'SR-Patient-information.pdf',
      updated: new Date('2/20/16'),
    },
    {
      name: 'how-to-upload.pdf',
      updated: new Date('1/18/16'),
    },
  ];

  constructor() {}

  ngOnInit() {}

  actionButtonMenu() {}
}
