<div class="site_background" id="loginPageContainer">

  <div style="  background:none !important;background: rgb(0, 0, 0,0.8) !important;position: absolute;top: 0;right: 0;bottom: 0;left: 0;display: block;width: 100%;height: 100%;">

    <div class="container text-center">
      <div class="row clearfix credential-reset" *ngIf="isError != true">
        <mat-card class="card" style="    top: calc( 50vh - 350px) !important;">
          <h3 class="sr-title sm-h3">Reset your password</h3>
          <p>You have requested to reset your password. Please enter your new password in the fields below.</p>
          <!-- <p>We will send you a SMS message to verify your account before your new password is set.</p> -->
          <br /><br />
          <!-- Password -->
          <form #resetForm="ngForm">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Password</mat-label>
              <!-- <input matInput placeholder="Password" value="" type="password" autocomplete="off"> -->
              <input [(ngModel)]="password" [type]="hide ? 'password' : 'text'" [min]="8" name="rePassword2" matInput
                placeholder="Password" value="" pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$" required />
              <mat-icon (click)="hide = !hide" matSuffix>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
            </mat-form-field>
            <div class="row clearfix">
              <p class="small rm-mt">Password strength</p>
              <password-strength-meter [password]="password"></password-strength-meter>
            </div>
            <!-- Repeat Password -->
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Repeat Password</mat-label>
              <!-- <input matInput placeholder="Repeat Password" value="" type="password" autocomplete="off"> -->
              <input [(ngModel)]="verifiedPassword" [type]="hide ? 'password' : 'text'" [min]="8" name="rePassword"
                matInput placeholder="Repeat Password" value="" pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$"
                required />
              <mat-icon (click)="hide = !hide" matSuffix>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
            </mat-form-field>
            <!-- Confirm -->

            <!-- <p class="small">Your password must be 8-20 characters long, contain letters and numbers, and must not contain spaces, special characters, or emoji.</p> -->

            <div class="drop-button">
              <button class="" [disabled]="!resetForm.form.valid || password != verifiedPassword"
                (click)="resetPassword()" mat-raised-button color="accent">
                Update Password
              </button>
            </div>
          </form>
        </mat-card>
      </div>
    </div>
  </div>
</div>
