import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ContactService {
  params: HttpParams;

  constructor(private http: HttpClient) {}

  getContactList(cardID, payload, type): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    if (type === 'supplier') {
      return this.http
        .get(environment.nodeUrl + '/contact/supplier/' + cardID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (type === 'patient') {
      return this.http
        .get(environment.nodeUrl + '/contact/patient/' + cardID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  createContact(payload, sessionType) {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .post(environment.nodeUrl + '/contact/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .post(environment.nodeUrl + '/contact', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer') {
      return this.http
        .post(environment.nodeUrl + '/contact/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/contact', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getContactDetails(ID, payload = {}, session) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (session == 'admin' || session == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/contact/global/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (session == 'merchant' || session == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/contact/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (session == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/contact/public-customer/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/contact/public/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getProfilePic(ID, payload = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(`${environment.nodeUrl}/contact/profile-picture/` + ID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getProfilePicStreamLink(ID) {
    if (ID) {
      return `${environment.nodeUrl}/contact/profile-picture-stream/` + ID;
    }
    return null;
  }

  getProfilePicStreamLinkCached(ID, LastModified = null) {
    if (
      ID &&
      LastModified &&
      LastModified != '' &&
      LastModified != undefined &&
      LastModified != 'null' &&
      LastModified != 'undefined '
    ) {
      return `${environment.nodeUrl}/contact/profile-picture-stream-cached/${LastModified}/` + ID;
    } else if (ID) {
      return `${environment.nodeUrl}/contact/profile-picture-stream/` + ID;
    }
    return null;
  }

  modifyContact(ID, payload, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .put(environment.nodeUrl + '/contact/edit/global/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .put(environment.nodeUrl + '/contact/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer') {
      return this.http
        .put(environment.nodeUrl + '/contact/customer/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/contact/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
}
