<mat-card class="rel supplier-card clearfix mb" *ngIf="operator">

  <div class="card-header primary-gradient-img clearfix stacked">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">

        <h2 class="summary-header text-left rm-m">
          Password Reset
        </h2>

      </div>
    </div>

  </div>
  <form #passwordChange="ngForm">
    <mat-dialog-content>
      <div class="full-width ">


        <p>


          Once you verify the user data and click on "Submit":

          <br><br>
          - The user's password will be changed to a <strong> temporary password. </strong><br><br>
          - All active user sessions will be <strong>terminated.</strong><br><br>
          - The user will receive an email containing the new password and will be <strong>prompted </strong>to change
          it upon the initial
          login.
        </p>

        <div class="full-width mt mb  flex">

          <div class="full-width user-name-container ">


            <div class="full-width flex smt mb" *ngIf="operator.DateTimeCreated">

              <p class="full-width  rm-m mr  "> <strong>Joint Date: </strong></p>
              <p class="full-width  rm-m   ">{{operator.DateTimeCreated  | TimeLabelPast }} ago </p>
            </div>


            <div class="full-width mb flex" *ngIf="operator.UserName">

              <p class="full-width  rm-m  mr "> <strong>Username: </strong></p>
              <p class="full-width  rm-m   ">{{operator.UserName}}</p>
            </div>

            <div class="full-width mb flex" *ngIf="operator.card && operator.card['CalculatedName']">

              <p class="full-width  rm-m  mr "> <strong>Name: </strong></p>
              <p class="full-width   rm-m  ">{{operator.card['CalculatedName']}}</p>
            </div>



            <div class="full-width  mb flex" *ngIf="operator.card && operator.card['emails.Email']">

              <p class="full-width  rm-m  mr "> <strong>Email: </strong></p>
              <p class="full-width   rm-m  ">{{operator.card['emails.Email']}}</p>
            </div>



            <div class="full-width  mb flex" *ngIf="operator.card && operator.card['mobiles.Number']">

              <p class="full-width   rm-m mr "> <strong>Mobile: </strong></p>
              <p class="full-width   rm-m  ">{{operator.card['mobiles.Number'] | customPhone}}</p>
            </div>



            <div class="full-width  mb flex" *ngIf="operator.card && operator.card['phones.Number']">

              <p class="full-width rm-m mr "> <strong>Phone: </strong></p>
              <p class="full-width rm-m  ">{{operator.card['phones.Number']}}</p>
            </div>

            <div class="full-width  mb flex" *ngIf="operator.card &&  operator.card['ContactType.Label']">

              <p class="full-width  rm-m mr "> <strong>Role: </strong></p>
              <p class="full-width  rm-m  ">{{  operator.card['ContactType.Label'] }}</p>
            </div>


          </div>


        </div>


      </div>
    </mat-dialog-content>
    <hr>
    <div class="drop-button text-center full-width ">

      <button mat-raised-button color="accent" class="btn-large" (click)="submit()">Submit</button>

    </div>

  </form>
</mat-card>
