import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { MapMultipleViewComponent } from '../../../shared/components/map-multiple-view/map-multiple-view.component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { BeneficiaryService } from '../shared/beneficiary.service';

@Component({
  selector: 'app-beneficiary-list-map',
  templateUrl: './beneficiary-list-map.component.html',
  styleUrls: ['./beneficiary-list-map.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class BeneficiaryListMapComponent implements OnInit {
  @ViewChild(MapMultipleViewComponent, { static: false }) public mapComponent: MapMultipleViewComponent;

  @Input() searchType = 'name';

  hasMapSearch = false;

  icon = 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/purple-dot.png';

  addMarkerEvent = new EventEmitter();

  selectedItemEvent = new EventEmitter();

  selectedItemIndex;

  selectedItem;

  isLoaded = false;
  beneficiaries = [];
  serviceRef;

  _beneficiaries = [];

  isPromoterOrAdmin;

  p = 1;
  itemPageIndex = 0;

  filter;

  description = 'Click on marker to view supplier details';
  title = 'Suppliers';

  constructor(
    private beneficiaryService: BeneficiaryService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      const payload = {
        section: 0,
        limit: 300,
        hasAdress: true,
        orderBy: 'CalculatedName',
        fields:
          'ID,addresses.Calculated,addresses.Suburb,addresses.State,addresses.Postcode,' +
          'addresses.Unit,addresses.Street Nr,addresses.Street Name,addresses.Street Type,Address.Latitude,Address.Longitude,' +
          'CalculatedName',
      };

      this.serviceRef = this.beneficiaryService.getList(payload, this.isPromoterOrAdmin).subscribe((res) => {
        if (res && res.length > 0) {
          this.isLoaded = true;
          this.beneficiaries = res;

          for (let i = 0; i < this.beneficiaries.length; i++) {
            // this.addMarkerEvent.emit(this.beneficiaries[i])

            if (this.beneficiaries[i]) {
              this.beneficiaries[i].icon = this.icon;
              this.addMarkerEvent.emit(this.beneficiaries[i]);
            }
          }

          const innerFunction = (section) => {
            section = section + 1;
            UtilsClass.loadingDataSection(section);
            payload.section = section;

            this.serviceRef = this.beneficiaryService.getList(payload, this.isPromoterOrAdmin).subscribe((res) => {
              if (res.length > 0) {
                this.beneficiaries = this.beneficiaries.concat(res);

                this.beneficiaries.sort((a, b) => {
                  return a.CalculatedName.localeCompare(b.CalculatedName);
                });

                for (let i = res.length; i < this.beneficiaries.length; i++) {
                  if (this.beneficiaries[i]) {
                    this.beneficiaries[i].icon = this.icon;
                    this.addMarkerEvent.emit(this.beneficiaries[i]);
                  }
                }

                innerFunction(section);
              } else {
                return true;
              }
            });
            UtilsClass.stopLoadingInterceptor();
          };

          innerFunction(1);
        } else {
          this.isLoaded = true;
        }
      });
    });
  }

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }

  getActionEvent(e) {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['beneficiary'],
        },
      },
    ]);
  }

 

  getSelected(item) {
    if (item && item['ID']) {
      this.selectedItemIndex;

      this.beneficiaries.map((x, index) => {
        if (x && x['ID']) {
          if (x['ID'] == item['ID']) {
            this.selectedItemIndex = index;

            return index;
          }
        }
      });
      this.selectedItem = item;
      this.itemPageIndex = Math.trunc(this.selectedItemIndex / 10) + 1;
      this.pageChange(this.itemPageIndex);
    } else {
      this.pageChange(1);
      this.selectedItem = null;
      this.selectedItemIndex = null;
      this.itemPageIndex = null;
    }
  }

  closeSelectedItem() {
    this.pageChange(1);
    this.selectedItem = null;
    this.selectedItemIndex = null;
    this.itemPageIndex = null;
  }

  addCoordinateEvent(item) {
    if (item && item['ID'] && item['latitude'] && item['longitude']) {
      const payload = {
        latitude: item['latitude'],
        longitude: item['longitude'],
      };
      this.beneficiaryService.addCoordinate(item['ID'], payload).subscribe((res) => {});
    }
  }

  selectItem(item, index, pageIndex) {
    if (item && item['ID']) {
      this.selectedItemIndex = index;
      this.selectedItemEvent.emit(item);
      this.selectedItem = item;
      this.itemPageIndex = pageIndex;
      this.pageChange(pageIndex);
    }
  }

  pageChange(pageIndex) {
    this.p = pageIndex;
  }

  filterList(e) {
    if (e) {
      this.beneficiaries = this.beneficiaries.filter(
        (item) => -1 < item['CalculatedName'].toLowerCase().indexOf(e.toLowerCase())
      );
    }
  }

  getLoading(i, p) {
    i = (p - 1) * 10 + i;
    return this.beneficiaries[i]['loading'];
  }

  // map control input

  practiceLocation() {
    this.mapComponent.changeToCurrentPractice();
  }

  myLocation() {
    this.mapComponent.changeToCurrent();
  }

  resetMap() {
    this.mapComponent.resetMap();
  }
}
