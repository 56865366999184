<mat-form-field class="full-width" appearance="outline">
  <mat-label>{{ 'Occupation' | translate }}</mat-label>
  <input
    [(ngModel)]="_occupation"
    [required]="requiredLabel"
    [matAutocomplete]="auto"
    (ngModelChange)="filterResult()"
    type="text"
    name="auto"
    placeholder="Occupation"
    matInput
  />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="chooseOccupation($event.option.value)">
    <mat-option *ngFor="let o of occupations | FilterArrayMultipleContainValue: 'Label':[occupationFilter]" [value]="o">
      {{ o.Label }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
