import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { ArchwizardModule } from 'angular-archwizard';
import { MomentModule } from 'ngx-moment';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgPipesModule } from 'ngx-pipes';
import { DentistModule } from '../dentist/dentist.module';
import { DocumentModule } from '../document/document.module';
import { MessageModule } from '../message/message.module';
import { MerchantProspectListComponent } from './merchant-prospect-list/merchant-prospect-list.component';
import { MerchantProspectRoutingModule } from './shared/merchant-prospect-routing.module';
import { MerchantProspectService } from './shared/merchant-prospect.service';

@NgModule({
  imports: [
    NgPipesModule,
    CommonModule,
    MomentModule,
    FormsModule,
    ReactiveFormsModule,
    CdkTableModule,
    ArchwizardModule,
    SharedModule,
    NgxPaginationModule,
    MatSortModule,
    MerchantProspectRoutingModule,
    DentistModule,
    MessageModule,
    DocumentModule,
  ],
  declarations: [MerchantProspectListComponent],
  exports: [MerchantProspectListComponent],
  providers: [MerchantProspectService],
})
export class MerchantProspectModule {}
