<app-general-settings [features]="['languages']"></app-general-settings>

<!-- All Communication Settings Layout Here -->

<mat-card class="card container">
  <mat-card-header class="row">
    <mat-card-title>
      <p class="lead rm-mt">Communication Settings</p>
    </mat-card-title>
  </mat-card-header>

  <mat-card-content class="clearfix center-content flex-row flex-blocks add-mb add-mt">
    <div class="qtr-width extra-padded">
      <mat-select class="full-width" placeholder="Select a Product...">
        <mat-option *ngFor="let product of products" [value]="product.value">
          {{ product.viewValue }}
        </mat-option>
      </mat-select>
    </div>

    <div class="half-width flex-blocks">
      <!-- Come back a style these a lot better -->
      <button class="button btn-large-spec" (click)="lendingOptionsLoadup()" mat-raised-button color="accent">
        Lending
      </button>
      <button class="button btn-large-spec" (click)="generalOptionsLoadup()" mat-raised-button color="warn">
        General
      </button>
    </div>
  </mat-card-content>

  <mat-card-content class="clearfix full-width flex-row flex-block add-mb">
    <div class="row clearfix full-width flex-row flex-block">
      <div class="qtr-width">
        <div class="column">
          <table class="full-width">
            <tr class="set-height">
              <td class="set-padding"></td>
            </tr>
            <tr class="bg-grey set-height">
              <td class="set-padding">
                <strong>Invitation</strong>
              </td>
            </tr>
            <tr class="set-height">
              <td class="set-paddingx2">Sent</td>
            </tr>
            <tr class="set-height">
              <td class="set-paddingx2">Viewed</td>
            </tr>
            <tr class="bg-grey set-height">
              <td class="set-padding">
                <strong>Application</strong>
              </td>
            </tr>
            <tr class="set-height">
              <td class="set-paddingx2">Commenced</td>
            </tr>
            <tr class="set-height">
              <td class="set-paddingx2">Finalised</td>
            </tr>
            <tr class="bg-grey set-height">
              <td class="set-padding">
                <strong>Credit Review</strong>
              </td>
            </tr>
            <tr class="set-height">
              <td class="set-paddingx2">Approved</td>
            </tr>
            <tr class="set-height">
              <td class="set-paddingx2">Referred</td>
            </tr>
            <tr class="set-height">
              <td class="set-paddingx2">Declined</td>
            </tr>
            <tr class="bg-grey set-height">
              <td class="set-padding">
                <strong>Contract</strong>
              </td>
            </tr>
            <tr class="set-height">
              <td class="set-paddingx2">Commenced</td>
            </tr>
            <tr class="set-height">
              <td class="set-paddingx2">Finalised</td>
            </tr>
          </table>
        </div>
        <!-- END OF COLUMN DIV -->
      </div>
      <!-- END OF HALF-WIDTH DIV -->

      <div class="thr-qtr-width-width">
        <div class="column">
          <div class="qtr-width">
            <table class="tbl-style">
              <tr class="set-height txt-algn">
                <td colspan="6"><span class="tbl-header">Consumer</span></td>
              </tr>
              <tr class="set-height" *ngFor="let icon of icons">
                <td>
                  <label class="checkbox-container" (click)="addOptionTag()">
                    <input type="checkbox"/>
                    <mat-icon class="checkmark">{{ icon.icon1 }}</mat-icon>
                  </label>
                </td>
                <td>
                  <label class="checkbox-container" (click)="addOptionTag()">
                    <input type="checkbox"/>
                    <mat-icon class="checkmark">{{ icon.icon2 }}</mat-icon>
                  </label>
                </td>
                <td>
                  <label class="checkbox-container" (click)="addOptionTag()">
                    <input type="checkbox"/>
                    <mat-icon class="checkmark">{{ icon.icon3 }}</mat-icon>
                  </label>
                </td>
                <td>
                  <label class="checkbox-container" (click)="addOptionTag()">
                    <input type="checkbox"/>
                    <mat-icon class="checkmark">{{ icon.icon4 }}</mat-icon>
                  </label>
                </td>
                <td>
                  <button class="btn-hover" (click)="loadTemplateEditor()" mat-icon-button>
                    <mat-icon>{{ icon.icon5 }}</mat-icon>
                  </button>
                </td>
                <td>
                  <button class="btn-hover" (click)="loadTemplateEditor()" mat-icon-button>
                    <mat-icon>{{ icon.icon6 }}</mat-icon>
                  </button>
                </td>
              </tr>
            </table>
          </div>
          <div class="qtr-width">
            <table class="tbl-style">
              <tr class="set-height txt-algn">
                <td colspan="6"><span class="tbl-header">Merchant</span></td>
              </tr>
              <tr class="set-height" *ngFor="let icon of icons">
                <td>
                  <label class="checkbox-container" (click)="addOptionTag()">
                    <input type="checkbox"/>
                    <mat-icon class="checkmark">{{ icon.icon1 }}</mat-icon>
                  </label>
                </td>
                <td>
                  <label class="checkbox-container" (click)="addOptionTag()">
                    <input type="checkbox"/>
                    <mat-icon class="checkmark">{{ icon.icon2 }}</mat-icon>
                  </label>
                </td>
                <td>
                  <label class="checkbox-container" (click)="addOptionTag()">
                    <input type="checkbox"/>
                    <mat-icon class="checkmark">{{ icon.icon3 }}</mat-icon>
                  </label>
                </td>
                <td>
                  <label class="checkbox-container" (click)="addOptionTag()">
                    <input type="checkbox"/>
                    <mat-icon class="checkmark">{{ icon.icon4 }}</mat-icon>
                  </label>
                </td>
                <td>
                  <button class="btn-hover" (click)="loadTemplateEditor()" mat-icon-button>
                    <mat-icon>{{ icon.icon5 }}</mat-icon>
                  </button>
                </td>
                <td>
                  <button class="btn-hover" (click)="loadTemplateEditor()" mat-icon-button>
                    <mat-icon>{{ icon.icon6 }}</mat-icon>
                  </button>
                </td>
              </tr>
            </table>
          </div>
          <div class="qtr-width">
            <table class="tbl-style">
              <tr class="set-height txt-algn">
                <td colspan="6"><span class="tbl-header">Funder</span></td>
              </tr>
              <tr class="set-height" *ngFor="let icon of icons">
                <td>
                  <label class="checkbox-container" (click)="addOptionTag()">
                    <input type="checkbox"/>
                    <mat-icon class="checkmark">{{ icon.icon1 }}</mat-icon>
                  </label>
                </td>
                <td>
                  <label class="checkbox-container" (click)="addOptionTag()">
                    <input type="checkbox"/>
                    <mat-icon class="checkmark">{{ icon.icon2 }}</mat-icon>
                  </label>
                </td>
                <td>
                  <label class="checkbox-container" (click)="addOptionTag()">
                    <input type="checkbox"/>
                    <mat-icon class="checkmark">{{ icon.icon3 }}</mat-icon>
                  </label>
                </td>
                <td>
                  <label class="checkbox-container" (click)="addOptionTag()">
                    <input type="checkbox"/>
                    <mat-icon class="checkmark">{{ icon.icon4 }}</mat-icon>
                  </label>
                </td>
                <td>
                  <button class="btn-hover" (click)="loadTemplateEditor()" mat-icon-button>
                    <mat-icon>{{ icon.icon5 }}</mat-icon>
                  </button>
                </td>
                <td>
                  <button class="btn-hover" (click)="loadTemplateEditor()" mat-icon-button>
                    <mat-icon>{{ icon.icon6 }}</mat-icon>
                  </button>
                </td>
              </tr>
            </table>
          </div>
          <div class="qtr-width">
            <table class="tbl-style">
              <tr class="set-height txt-algn">
                <td colspan="6"><span class="tbl-header">Promoter</span></td>
              </tr>
              <tr class="set-height" *ngFor="let icon of icons">
                <td>
                  <label class="checkbox-container" (click)="addOptionTag()">
                    <input type="checkbox"/>
                    <mat-icon class="checkmark">{{ icon.icon1 }}</mat-icon>
                  </label>
                </td>
                <td>
                  <label class="checkbox-container" (click)="addOptionTag()">
                    <input type="checkbox"/>
                    <mat-icon class="checkmark">{{ icon.icon2 }}</mat-icon>
                  </label>
                </td>
                <td>
                  <label class="checkbox-container" (click)="addOptionTag()">
                    <input type="checkbox"/>
                    <mat-icon class="checkmark">{{ icon.icon3 }}</mat-icon>
                  </label>
                </td>
                <td>
                  <label class="checkbox-container" (click)="addOptionTag()">
                    <input type="checkbox"/>
                    <mat-icon class="checkmark">{{ icon.icon4 }}</mat-icon>
                  </label>
                </td>
                <td>
                  <button class="btn-hover" (click)="loadTemplateEditor()" mat-icon-button>
                    <mat-icon>{{ icon.icon5 }}</mat-icon>
                  </button>
                </td>
                <td>
                  <button class="btn-hover" (click)="loadTemplateEditor()" mat-icon-button>
                    <mat-icon>{{ icon.icon6 }}</mat-icon>
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <!-- END OF COLUMN DIV -->
      </div>
      <!-- END OF HALF-WIDTH DIV -->
    </div>
    <!-- END OF ROW DIV -->
  </mat-card-content>

  <mat-card-content class="clearfix full-width flex-row flex-block add-mb">
    <!-- Come back a style these a lot better -->

    <table class="center-table add-mt">
      <tr class="bg-grey">
        <th class="cell-padding" colspan="2">
          <p class="lead"><strong>Legend</strong></p>
        </th>
      </tr>
      <tr class="table-header-border">
        <td class="cell-padding cell-width">
          <p class="lead rm-mt"><strong>Icons</strong></p>
        </td>
        <td class="cell-padding cell-width">
          <p class="lead rm-mt"><strong>Actions</strong></p>
        </td>
      </tr>
      <tr>
        <td class="cell-padding cell-width tbl-border-right">
          <mat-icon>email</mat-icon>
          <p><strong>Email</strong></p>
          <p>
            Use the checkbox under this icon to enable or disable the sending of a confirmation email for this action
          </p>
        </td>
        <td class="cell-padding cell-width">
          <mat-icon class="purple">email</mat-icon>
          <p><strong>View/Modify Email</strong></p>
          <p>Use this button to preview or modify the message text in the email that is sent</p>
        </td>
      </tr>
      <tr>
        <td class="cell-padding cell-width tbl-border-right">
          <mat-icon>sms</mat-icon>
          <p><strong>Email</strong></p>
          <p>Use the checkbox under this icon to enable or disable the sending of a confirmation SMS for this action</p>
        </td>
        <td class="cell-padding cell-width">
          <mat-icon class="purple">sms</mat-icon>
          <p><strong>View/Modify SMS</strong></p>
          <p>Use this button to preview or modify the message text in the SMS that is sent</p>
        </td>
      </tr>
      <tr>
        <td class="cell-padding cell-width tbl-border-right tbl-border-btm">
          <mat-icon>lock</mat-icon>
          <p><strong>Mandatory Action</strong></p>
          <p>Use the checkbox under this icon to set this action as mandatory</p>
        </td>
        <td class="cell-padding cell-width tbl-border-btm"></td>
      </tr>
    </table>
  </mat-card-content>
  <mat-card-content class="clearfix flex-row">
    <div class="set-dim">
      <p>Last updated at: time/date</p>
      <p>by: name</p>
    </div>
    <div class="set-dim right">
      <button class="button btn-large-spec btn-margin" (click)="confirmChanges()" mat-raised-button color="primary">
        Confirm
      </button>
    </div>
  </mat-card-content>
</mat-card>

<!-- All Language and Specific Units Settings Layout Here -->

<mat-card class="card">
  <mat-card-header>
    <p class="lead rm-mt">Units &amp; Language</p>
  </mat-card-header>
  <div class="row clearfix">
    <div class="half-width">
      <div class="column">
        <label>Default site localisation</label>
        <!-- update mode -->
        <div class="clearfix">
          <mat-select class="full-width" placeholder="Language" name="defaultLanguage" required>
            <mat-option *ngFor="let defaultLanguage of defaultLanguages" [value]="defaultLanguage.value">
              {{ defaultLanguage.viewValue }}
            </mat-option>
          </mat-select>
        </div>
      </div>
    </div>

    <div class="half-width">
      <div class="column">
        <label>Default site currency</label>
        <!-- update mode -->
        <div class="clearfix">
          <mat-select class="full-width" placeholder="Currency" name="defaultCurrency" required>
            <mat-option *ngFor="let defaultCurrency of defaultCurrencys" [value]="defaultCurrency.value">
              {{ defaultCurrency.viewValue }}
            </mat-option>
          </mat-select>
        </div>
      </div>
    </div>
  </div>
</mat-card>
<div class="row clearfix">
  <span class="history"><mat-icon>history</mat-icon>data updated 0 days ago</span>
  <div class="pull-right">
    <button class="" mat-raised-button color="accent">Save changes</button>
  </div>
</div>
