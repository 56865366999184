<div class="rel" *ngIf="link">
  <form #resetForm="ngForm">
    <div
      class="card-header primary-gradient-img clearfix confirmModal"
      [attr.style]="'background:' + theme.background + ' !important' | safeStyle"
    >
      <button class="btn-close btn-clear mat-button smt white custom-close" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="full-width flex main-title-container">
        <div class="row full-width title-container clearfix">
          <div class="title-container-sub">
            <landing-page-header-image
              class="pull-left landing-page-header-image-view mr"
              [headerImage]="link['Header_Image']"
              [hideZoomButton]="false"
            >
            </landing-page-header-image>

            <h2 class="text-left title-header rm-m">
              {{ link.Label }}

              <span class="subLabel">
                <span class="mr">
                  Visitors

                  <span class="badge mr" [endVal]="link.Visitors" countUp></span>
                </span>
                <span class="mr" *ngIf="link && link.documents && link.documents.length">
                  Attached Documents

                  <span class="badge mr" [endVal]="link.documents.length" countUp></span>
                </span>

                <span class="ml chip chipBeta" *ngIf="link['IsActive'] != '1'">Not Active</span>
                <span class="ml chip chipBeta2" *ngIf="link['IsActive'] == '1'"> Active</span>
              </span>
            </h2>
          </div>
        </div>
      </div>
    </div>

    <mat-dialog-content>
      <div class="full-width">
        <div class="full-width text-center"></div>

        <div class="full-width detail-container">
          <div class="box text-center">
            <h3 class="sr-title text-left smt smb" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              Type
            </h3>
            <p class="text-left rm-m">
              <strong>{{ link['Type.Label'] }}</strong>
            </p>
          </div>
          <div class="box text-center" *ngIf="link['Type.Code'] == 'FLandPg'">
            <h3
              class="sr-title text-left smt smb"
              *ngIf="settings && settings['context'] != 'breeze' "
              [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
            >
              {{ "KEYWORD.treatment" | translate | titlecase }} Value
            </h3>

            <h3
              class="sr-title text-left smt smb"
              *ngIf="settings && settings['context'] == 'breeze' "
              [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
            >
              Amount to Finance
            </h3>
            <p class="text-left rm-m">
              <strong>{{ link.Treatment_Amount | customCurrency }}</strong>
            </p>
          </div>
          <div class="box text-center" *ngIf="link['Type.Code'] == 'FLandPg' && link['IsDefault'] !== '1'">
            <h3 class="sr-title text-left smt smb" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              Sender
            </h3>
            <p class="text-left rm-m click-link" (click)="viewDentist(link.Contact_key)">
              <strong>{{ link.Contact_CalculatedName }}</strong>
            </p>
          </div>

          <div
            class="box text-center"
            *ngIf="link['Type.Code'] == 'FLandPg' && link['IsDefault'] === '1' && currentDentist && currentDentist.ID"
          >
            <h3 class="sr-title text-left smt smb" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              Sender
            </h3>
            <p class="text-left rm-m click-link" (click)="viewDentist(currentDentist.ID)">
              <strong>{{ currentDentist.CalculatedName }}</strong>
            </p>
          </div>

          <div class="box text-center" *ngIf="link['Type.Code'] == 'FLandPg'">
            <h3 class="sr-title text-left smt smb" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              Finance Type
            </h3>
            <p class="text-left rm-m">
              <mat-icon
                class=" fas {{
                  productUtil.getProductGroupIcon(link['Product_Group.Code'])
                }} product-group-ico-small mr"
              >
              </mat-icon>
              <strong> {{ productUtil.getLabel(link['Product_Group.Code']) }}</strong>
            </p>
          </div>

          <div class="box text-center" *ngIf="link.IsDefault == '1'">
            <h3 class="sr-title text-left smt smb" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              {{ settings.brandName }} Template
            </h3>
            <p class="text-left rm-m good" *ngIf="link.IsDefault == '1'">
              <strong>Yes</strong>
            </p>
            <p class="text-left rm-m bad" *ngIf="link.IsDefault != '1'">
              <strong>No</strong>
            </p>
          </div>

          <div
            class="box text-center"
            *ngIf="settings && settings['context'] != 'breeze' "
          >
            <h3 class="sr-title text-left smt smb" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              Tips & Offers
            </h3>
            <p class="text-left rm-m good" *ngIf="link.IsPublic == '1'">
              <strong>Yes</strong>
            </p>
            <p class="text-left rm-m bad" *ngIf="link.IsPublic != '1'">
              <strong>No</strong>
            </p>
          </div>

          <div
            class="box text-center"
            *ngIf="link['Date.Expire'] != '2200-01-01' && link['Date.Expire'] != '0000-00-00'"
          >
            <h3 class="sr-title text-left smt smb" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              Is Expired
            </h3>
            <p class="text-left rm-m good" *ngIf="link.isExpired == false">
              <strong>No</strong>
            </p>
            <p class="text-left rm-m bad" *ngIf="link.isExpired !== false">
              <strong>Yes</strong>
            </p>
          </div>

          <div class="box text-center">
            <h3 class="sr-title text-left smt smb" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              Expired At
            </h3>
            <p
              class="text-left rm-m good"
              *ngIf="link['Date.Expire'] == '2200-01-01' || link['Date.Expire'] == '0000-00-00'"
            >
              <strong>Never</strong>
            </p>
            <p
              class="text-left rm-m"
              *ngIf="link['Date.Expire'] != '2200-01-01' && link['Date.Expire'] != '0000-00-00'"
            >
              <strong>{{ link['Date.Expire'] | customDate }}</strong>
            </p>
          </div>
        </div>

        <h3
          class="sr-title text-left full-width mt smb"
          *ngIf="link && link.tags && link.tags.length > 0"
          [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
        >
          Tags
        </h3>

        <mat-chip-list class="full-width clearfix" #chipList name="tags-patient">
          <mat-chip *ngFor="let tag of link.tags" [selectable]="false" [removable]="false">
            {{ tag }}
          </mat-chip>
        </mat-chip-list>

        <h3
          class="sr-title text-left full-width smt smb"
          [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
        >
          Title
        </h3>
        <p class="rm-m">{{ link.Title }}</p>

        <h3
          class="sr-title text-left full-width mt smb"
          [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
        >
          Description
        </h3>

        <read-more
          class="text-left full-width read-more-module22 read-more-message-input rm-m"
          [animated]="true"
          [text]="utils.URLify(description || '...', false)"
          [design]="'noPropagation'"
          [maxLength]="maxLength"
          [isCollapsed]="true"
        >
        </read-more>

        <h3
          class="sr-title text-left full-width mt smb"
          [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
        >
          Message
        </h3>

        <read-more
          class="text-left full-width read-more-module22 read-more-message-input rm-m"
          [animated]="true"
          [text]="utils.URLify(message || '...', false)"
          [design]="'noPropagation'"
          [maxLength]="maxLength"
          [isCollapsed]="true"
        >
        </read-more>

        <h3
          class="sr-title text-left pull-left text-center"
          *ngIf="link && link.documents && link.documents.length > 0"
          [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
        >
          Attached Documents
          <span>({{ link.documents.length }})</span>
        </h3>

        <div class="full-width" *ngIf="link && link.documents && link.documents.length > 0">
          <app-ep-document-list
            class="document-link"
            [isDownload]="false"
            [isPrint]="false"
            [canRemove]="false"
            [justify]="'left'"
            [isModal]="false"
            [isFilter]="false"
            [defaultCheck]="false"
            [isRedirection]="false"
            [documents]="link.documents"
            [title]="''"
            [canEdit]="true"
            [canCheck]="false"
            [listViewType]="'thumbnails'"
          >
          </app-ep-document-list>
        </div>
      </div>
    </mat-dialog-content>

    <hr/>
    <div class="drop-button text-center mt text-center full-width">
      <button
        class="btn-large pull-right"
        *ngIf="link && link.ID"
        [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
        (click)="openIframe()"
        mat-raised-button
        color="primary"
      >
        View Landing Page
      </button>

      <button
        class="btn-large pull-right ml mr"
        *ngIf="displayClone == true && link.IsDefault == '1' && isPromoterOrAdmin != true"
        [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
        (click)="cloneEvent()"
        mat-raised-button
        color="accent"
      >
        <mat-icon class="fas fa-copy smr"></mat-icon>Clone
      </button>

      <button
        class="btn-large pull-right ml mr"
        [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
        (click)="share()"
        mat-raised-button
        color="accent"
      >
        <mat-icon class="fas fa-share smr"></mat-icon>Share
      </button>

      <button
        class="btn-large ml mr pull-right"
        *ngIf="link && link.ID && isSubmit == true"
        [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
        (click)="sendResult()"
        mat-raised-button
        color="primary"
      >
        Submit
      </button>

      <button class="btn-large pull-left" (click)="closeEvent()" mat-raised-button color="primary">close</button>
    </div>
  </form>
</div>
