import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CdkTableModule } from '@angular/cdk/table';
import { ArchwizardModule } from 'angular-archwizard';
import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '../../shared/shared.module';
import { BankAccountModule } from '../bank-account/bank-account.module';
import { BeneficiaryModule } from '../beneficiary/beneficiary.module';
import { ContractModule } from '../contract/contract.module';
import { CustomerModule } from '../customer/customer.module';
import { InvitationModule } from '../invitation/invitation.module';
import { HealthfundIntroModalComponent } from './healthfund-intro-modal/healthfund-intro-modal.component';
import { SettlementArchiveComponent } from './settlement-archive/settlement-archive.component';
import { SettlementCreateIntroModalComponent } from './settlement-create-intro-modal/settlement-create-intro-modal.component';
import { SettlementCustomerListGlobalComponent } from './settlement-customer-list-global/settlement-customer-list-global.component';
import { SettlementCustomerListOverViewComponent } from './settlement-customer-list-overview/settlement-customer-list-overview.component';
import { SettlementCustomerListComponent } from './settlement-customer-list/settlement-customer-list.component';
import { SettlementHealthFundModalComponent } from './settlement-healthfund-modal/settlement-healthfund-modal.component';
import { SettlementIntroModalComponent } from './settlement-intro-modal/settlement-intro-modal.component';
import { SettlementListOverViewComponent } from './settlement-list-overview/settlement-list-overview.component';
import { SettlementModifyComponent } from './settlement-modify/settlement-modify.component';
import { SettlementOverviewComponent } from './settlement-overview/settlement-overview.component';
import { SettlementRequestComponent } from './settlement-request/settlement-request.component';
import { SettlementThirdPartyModalComponent } from './settlement-thirdparty-modal/settlement-thirdparty-modal.component';
import { SettlementRoutingModule } from './shared/settlement-routing.module';
import { ThirdpartyIntroModalComponent } from './thirdparty-intro-modal/thirdparty-intro-modal.component';

import { MatSortModule } from '@angular/material/sort';
import { OperatorModule } from '../operator/operator.module';
import { SettlementCreateComponent } from './settlement-create/settlement-create.component';
import { SettlementDueSettlementsReportPage } from './settlement-due-settlements-report/settlement-due-settlements-report.page';
import { SettlementDueSettlementsReportMainComponent } from './settlement-due-settlements-report/shared/components/settlement-due-settlements-report-main/settlement-due-settlements-report-main.component';
import { SettlementPaymentsReportPage } from './settlement-payments-report/settlement-payments-report.page';
import { SettlementPaymentsReportPatientPaymentsComponent } from './settlement-payments-report/shared/components/settlement-payments-report-patient-payments/settlement-payments-report-patient-payments.component';
import { SettlementsPaymentsReportMerchantFeesComponent } from './settlement-payments-report/shared/components/settlements-payments-report-merchant-fees/settlements-payments-report-merchant-fees.component';
import { SettlementRefundCreateComponent } from './settlement-refund-create/settlement-refund-create.component';
import { SettlementRefundIntroModalComponent } from './settlement-refund-intro-modal/settlement-refund-intro-modal.component';
import { SettlementSummaryListComponent } from './settlement-summary-list/settlement-summary-list.component';
import { SettlementService } from './shared/settlement.service';
import { CardModule } from '../card/card.module';
import { SettlementNewModalComponent } from './settlement-new-modal/settlement-new-modal.component';

@NgModule({
  imports: [
    NgPipesModule,
    ContractModule,
    CommonModule,
    FormsModule,
    CdkTableModule,
    ArchwizardModule,
    SharedModule,
    CustomerModule,
    CardModule,

    SettlementRoutingModule,
    ReactiveFormsModule,
    InvitationModule,
    BeneficiaryModule,
    BankAccountModule,
    MatSortModule,
    OperatorModule,
  ],
  declarations: [
    SettlementCreateComponent,
    SettlementRequestComponent,
    SettlementCustomerListOverViewComponent,
    SettlementCustomerListGlobalComponent,
    SettlementOverviewComponent,
    SettlementModifyComponent,
    SettlementArchiveComponent,
    SettlementIntroModalComponent,
    SettlementCustomerListComponent,
    SettlementThirdPartyModalComponent,
    SettlementHealthFundModalComponent,
    SettlementCreateIntroModalComponent,
    SettlementRefundIntroModalComponent,
    HealthfundIntroModalComponent,
    ThirdpartyIntroModalComponent,
    SettlementListOverViewComponent,
    SettlementSummaryListComponent,
    SettlementRefundCreateComponent,
    SettlementPaymentsReportPage,
    SettlementDueSettlementsReportPage,
    SettlementDueSettlementsReportMainComponent,
    SettlementsPaymentsReportMerchantFeesComponent,
    SettlementPaymentsReportPatientPaymentsComponent,
    SettlementNewModalComponent,
  ],
  exports: [
    SettlementCreateComponent,
    SettlementRequestComponent,
    SettlementCustomerListOverViewComponent,
    SettlementCustomerListGlobalComponent,
    SettlementOverviewComponent,
    SettlementModifyComponent,
    SettlementArchiveComponent,
    SettlementIntroModalComponent,
    SettlementCustomerListComponent,
    SettlementThirdPartyModalComponent,
    SettlementHealthFundModalComponent,
    SettlementCreateIntroModalComponent,
    SettlementRefundIntroModalComponent,
    HealthfundIntroModalComponent,
    ThirdpartyIntroModalComponent,
    SettlementListOverViewComponent,
    SettlementSummaryListComponent,
    SettlementRefundCreateComponent,
    SettlementPaymentsReportPage,
    SettlementDueSettlementsReportPage,
    SettlementDueSettlementsReportMainComponent,
    SettlementsPaymentsReportMerchantFeesComponent,
    SettlementPaymentsReportPatientPaymentsComponent,
    SettlementNewModalComponent,
  ],
  providers: [SettlementService],
})
export class SettlementModule {}
