import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { MerchantViewComponent } from '../merchant-view/merchant-view.component';
import { MerchantService } from '../shared/merchant.service';

@Component({
  selector: 'app-merchant-lookup',
  templateUrl: './merchant-lookup.component.html',
  styleUrls: ['./merchant-lookup.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class MerchantLookupComponent implements OnInit {
  @Input()
  merchantID;
  merchant;
  merchantLabel = 'Select Merchant';
  applyTitle = 'Apply';
  isPromoterOrAdmin = false;
  isSlected = false;
  close = new EventEmitter();
  saveLocalStorage = false;

  filterMerchantList = [];

  isContinue = false;
  merchants = [];
  result = new EventEmitter();
  sessionType = 'guest';

  constructor(
    private authenticationService: AuthenticationService,
    private merchantService: MerchantService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.merchantID) {
        this.merchantID = data.merchantID;
      }

      if (data.saveLocalStorage != null) {
        this.saveLocalStorage = data.saveLocalStorage;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      this.authenticationService.getSessionType().subscribe((sessionType) => {
        if (sessionType) {
          this.sessionType = sessionType;
        }

        const payload = {
          fields: 'ID,TradingAs,CalculatedName,Status',
        };
        this.merchantService.getList(payload, this.isPromoterOrAdmin).subscribe((res1) => {
          if (res1) {
            this.merchants = JSON.parse(JSON.stringify(res1));
            this.filterMerchantList = res1;

            if (this.merchantID) {
              this.applyTitle = 'Continue With The Merchant';
              this.isSlected = true;
              const index1 = this.filterMerchantList.findIndex((x) => {
                if (x && x['ID'] && this.merchantID == x['ID']) {
                  return true;
                } else {
                  return false;
                }
              });

              if (index1 != -1) {
                this.merchant = this.filterMerchantList[index1];
              }
            }
          }
        });
      });
    });
  }

  getMerchant(e) {
    if (e) {
      this.merchantID = e;

      this.merchantService.getMerchantDetails(this.merchantID, {}, this.sessionType).subscribe((res) => {
        if (res) {
          this.merchant = res;
        }
      });
    }
  }

  apply() {
    const result = {
      merchantID: this.merchant.ID,
      tradingAs: this.merchant.TradingAs,
    };

    if (this.saveLocalStorage == true && this.merchant.ID) {
      localStorage.setItem('selectedMerchant', this.merchant.ID);
    }
    this.result.emit(result);
  }

  getOptionText(option) {
    if (option && option.TradingAs) {
      return option.TradingAs;
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  filterMerchant(value) {
    if (value && value.srcElement && value.srcElement.value) {
      this.filterMerchantList = this.merchants.filter((x) => {
        if (x && x['TradingAs'] && x['TradingAs'].toLowerCase().indexOf(value.srcElement.value.toLowerCase()) != -1) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.filterMerchantList = JSON.parse(JSON.stringify(this.merchants));
    }
  }

  getSelectedMerchant(value) {
    if (value && value.ID) {
      this.applyTitle = 'Apply';

      this.merchantID = value.ID;
    }
  }

  viewMerchant() {
    if (this.merchantID) {
      const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
        data: this.merchantID,
        width: '600px',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }
}
