/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ngx-gallery.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/ngx-gallery/ngx-gallery.ngfactory";
import * as i3 from "ngx-gallery";
import * as i4 from "./ngx-gallery.component";
var styles_GalleryComponent = [i0.styles];
var RenderType_GalleryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GalleryComponent, data: {} });
export { RenderType_GalleryComponent as RenderType_GalleryComponent };
export function View_GalleryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ngx-gallery", [], [[2, "inCard", null], [4, "width", null], [4, "height", null], [4, "left", null]], [[null, "previewOpen"], [null, "previewClose"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("previewOpen" === en)) {
        var pd_1 = (_co.previewOpen() !== false);
        ad = (pd_1 && ad);
    } if (("previewClose" === en)) {
        var pd_2 = (_co.previewClose() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_NgxGalleryComponent_0, i2.RenderType_NgxGalleryComponent)), i1.ɵprd(4608, null, i3.NgxGalleryHelperService, i3.NgxGalleryHelperService, [i1.Renderer]), i1.ɵdid(2, 4571136, null, 0, i3.NgxGalleryComponent, [i1.ElementRef], { options: [0, "options"], images: [1, "images"] }, { previewOpen: "previewOpen", previewClose: "previewClose" })], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.galleryOptions; var currVal_5 = _co.galleryImages; _ck(_v, 2, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inCard; var currVal_1 = i1.ɵnov(_v, 2).width; var currVal_2 = i1.ɵnov(_v, 2).height; var currVal_3 = i1.ɵnov(_v, 2).left; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_GalleryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-gallery", [], null, null, null, View_GalleryComponent_0, RenderType_GalleryComponent)), i1.ɵdid(1, 114688, null, 0, i4.GalleryComponent, [i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GalleryComponentNgFactory = i1.ɵccf("app-gallery", i4.GalleryComponent, View_GalleryComponent_Host_0, { inCard: "inCard", canPreview: "canPreview", galleryOptions: "galleryOptions", galleryImages: "galleryImages" }, {}, []);
export { GalleryComponentNgFactory as GalleryComponentNgFactory };
