<div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="listDB.data.length == 0">
  <div class="row clearfix">
    <h1 class="dataHeader rm-m pull-left">
      {{ title }}
      <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
    </h1>

    <div class="pull-right selection-items" *ngIf="false" @ngIfAnimation>
      <button class="animated" (click)="selectAll()" mat-stroked-button color="" matTooltip="Select All">
        <mat-icon>border_all</mat-icon>
      </button>

      <!-- <button mat-stroked-button color="" class="animated" (click)="selectPage()"
          matTooltip="{{'Select Page' | translate }}">
          <mat-icon>border_outer</mat-icon>
        </button> -->

      <mat-button-toggle-group class="btn-group rm-m selectType" [(ngModel)]="typeFilter"
                               (ngModelChange)="setFilter(typeFilter, 'type')">
        <mat-button-toggle class="" [value]="null"> All</mat-button-toggle>

        <mat-button-toggle class="" [value]="'user'"> Merchant</mat-button-toggle>

        <mat-button-toggle class="" [value]="'default'">
          {{ "BRANDING.Brand Name" | translate | titlecase }}
        </mat-button-toggle>
      </mat-button-toggle-group>

      <button class="animated" *ngIf="selectedIDs && selectedIDs.length > 0" [matMenuTriggerFor]="ActionBtnmenu"
              mat-stroked-button matTooltip="Options">
        <mat-icon class="fas fa-cog"></mat-icon>  <span *ngIf="selectedIDs && selectedIDs.length>0"
          class="selected-number-item  accent-background">{{selectedIDs.length}}</span>
      </button>
      <mat-menu #ActionBtnmenu="matMenu">
        <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
          <mat-icon>select_all</mat-icon>
          Deselect
        </button>

        <button class="row select-btn" (click)="disableGroup()" mat-menu-item color="">
          <mat-icon>cancel</mat-icon>
          Disable
        </button>

        <button class="row select-btn" (click)="enableGroup()" mat-menu-item color="">
          <mat-icon>check_circle</mat-icon>
          Enable
        </button>

        <button class="row select-btn" *ngIf="isPromoterOrAdmin == true" (click)="markDefaultGroup()" mat-menu-item
                color="">
          Set as {{ "BRANDING.Brand Name" | translate | titlecase }} Template
        </button>

        <button class="row select-btn" *ngIf="isPromoterOrAdmin == true" (click)="removeDefaultGroup()" mat-menu-item
                color="">
          Set as User Template
        </button>

        <button class="row select-btn" *ngIf="selectedIDs && selectedIDs.length > 0" (click)="removeGroup()"
                mat-menu-item color="">
          <mat-icon>delete</mat-icon>
          Remove
        </button>
      </mat-menu>
    </div>
  </div>

  <div class="row clearfix">
    <mat-card class="list-card">
      <div class="row clearfix mb searchArea primary-gradient-img">
        <div class="row clearfix flex">
          <div class="full-width flex animated fadeInDown">
            <mat-icon class="mt mr">search</mat-icon>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Tag</mat-label>
              <input class="clearfix" (input)="setFilter($event.target.value, 'Tag')" name="tag" matInput/>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- Table -->
      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <ng-container cdkColumnDef="selectCheckBox" sticky>
          <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div class="margin-btm pull-left">
              <mat-checkbox [disabled]="isPromoterOrAdmin != true && row.type == 'default'"
                            [checked]="isSelected(row['_id'])" (change)="select(row['_id'], $event.checked)"
                            color="accent">
              </mat-checkbox>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Picture" sticky>
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Picture</mat-header-cell>
          <mat-cell class="text-center" *cdkCellDef="let row" (click)="viewTemplate(row['ID'])">
            <app-template-picture-view class="supplierIcon2 prod text-center" *ngIf="row" [hideZoomButton]="true"
                                       [templateID]="row['ID']" [LastModified]="row['LastModified']"
                                       [isPhysicalTemplate]="true">
            </app-template-picture-view>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Label" sticky>
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Label</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTemplate(row['ID'])" matTooltip="{{ row['Label'] }}">
            {{ row['Label'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Tag">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Tag</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTemplate(row['ID'])"
                    matTooltip="{{ templateUtil.getSubTag(row['Tag']) }}">
            {{ templateUtil.getSubTag(row['Tag']) }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Date">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Date Created</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTemplate(row['ID'])"
                    matTooltip="{{ row['DateTimeCreated'] | customDateTime }}">
            <span> {{ row['DateTimeCreated'] | customDateTime }}</span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button (click)="viewTemplate(row['ID'])" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span>View Template</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="pageSize"
                     [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>
    </mat-card>
  </div>
</div>
<div *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <div class="pull-right rel buttonrow">
    <button class="clearfix pull-right" (click)="createNew()" mat-raised-button color="primary">
      <mat-icon>add</mat-icon>
      Create new Template
    </button>
  </div>

  <app-empty-list-message></app-empty-list-message>
</div>
