<div
  class="row clearfix flex flex1 medical-history wht {{ merchantClass }} {{ merchantClass2 }}"
  [hidden]="!(patient && patient.ID && patientID && merchantID)"
>
  <!-- begin health history creation form -->

  <mat-progress-bar [value]="stepCounter" mode=" determinate" color="warn"></mat-progress-bar>

  <aw-wizard class="" id="part 1" #wizard @ngIfAnimation navBarLayout="large-filled-symbols">
    <aw-wizard-step
      class="animate"
      [navigationSymbol]="{ symbol: '0' }"
      (stepEnter)="stepEntered($event); setStepCounter(0)"
      awOptionalStep
      title="Before Start"
    >
      <app-medical-history-card
        *ngIf="medicalHistoryFull"
        [displayFooter]="false"
        [redirect]="false"
        [merchant]="medicalHistoryFull.merchant"
        [dentist]="medicalHistoryFull.dentist"
        [patient]="medicalHistoryFull.patient"
        [invitation]="medicalHistoryFull.invitation"
        (proceed)="selectedNextStepForward()"
      >
      </app-medical-history-card>
    </aw-wizard-step>

    <aw-wizard-step
      class="animate"
      [navigationSymbol]="{ symbol: '1' }"
      (stepEnter)="stepEntered($event); setStepCounter(0)"
      awOptionalStep
      title="Before Start"
    >
      <div class="container animated faster {{ enterslide }}">
        <div class="row clearfix text-center">
          <h2 class="white sm-h2 animate rm-mt">Before we get started, we just need to confirm...</h2>

          <h3 class="white animate rm-mb">
            <img
              class="flag lg"
              src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/flags/1x1/gb.svg"
            />Can you read and write English?
          </h3>
          <h5 class="white animate rm-mb">
            <img
              class="flag"
              src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/flags/1x1/cn.svg"
            />你是否能使用英语完成此表格?
          </h5>
          <h5 class="white animate">
            <img
              class="flag"
              src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/flags/1x1/it.svg"
            />Sai leggere e scrivere in inglese?
          </h5>
          <h5 class="white animate rm-mb" dir="rtl">
            هل تستطيع القراءة والكتابة باللغة الإنجليزية؟<img
            class="flag"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/flags/1x1/sa.svg"
          />
          </h5>
          <h5 class="white animate rm-mb">
            <img
              class="flag"
              src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/flags/1x1/vn.svg"
            />Bạn có thể đọc và viết bằng tiếng Anh không?
          </h5>
        </div>

        <div class="row clearfix animate mt">
          <mat-button-toggle-group
            class="divided-toggle"
            #readswritesenglish="matButtonToggleGroup"
            [(ngModel)]="medicalHistory.readsWritesEnglish"
          >
            <mat-button-toggle
              class="btn-large btn-icon no"
              [value]="false"
              (click)="openLanguageDialog()"
              color="accent"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-down"></mat-icon>
              No
            </mat-button-toggle>
            <mat-button-toggle
              class="btn-large btn-icon yes"
              [value]="true"
              (click)="proceedAndShowStar(1)"
              color="primary"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="row clearfix animate text-center step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
        </div>
        <!-- flex fix -->
        <div class="row clearfix">&nbsp;</div>
      </div>
    </aw-wizard-step>

    <!-- guardian details -->
    <aw-wizard-step
      [navigationSymbol]="{ symbol: '2' }"
      (stepEnter)="stepEntered($event); setStepCounter(0)"
      awOptionalStep
      title="Preliminary Questions"
    >
      <div class="container  animated faster {{ enterslide }}" @ngIfAnimation>
        <form class="flex flexCol" #createGuardianForm="ngForm">
          <div class="row clearfix text-center">
            <h3 class="white animate rm-mt">
              Are you completing this form on behalf of a child, dependant or someone you care for?
            </h3>
          </div>

          <div class="rel" *ngIf="!medicalHistory.formFilledOnBehalf" @ngIfAnimation>
            <div class="image-area row clearfix anim animate">
              <img
                class="center big animated bounceInDown delay-05s"
                src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/kid-1.png"
              />
              <img
                class="big animated bounceInLeft"
                src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/person-old-1.png"
              />
              <img
                class="right big animated bounceInRight delay-02s"
                src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/person-chair-1.png"
              />
            </div>
          </div>

          <div class="row clearfix animate">
            <mat-button-toggle-group
              class="divided-toggle mobileFlow"
              [(ngModel)]="medicalHistory.formFilledOnBehalf"
              name="guardianDetails"
            >
              <mat-button-toggle class="btn-large btn-icon no" [value]="true" (click)="guardianCheck()" color="accent">
                <mat-icon class="fas fa-users"></mat-icon>On behalf of someone
              </mat-button-toggle>
              <mat-button-toggle
                class="btn-large btn-icon yes"
                [value]="false"
                (click)="editPatientDetails()"
                color="primary"
              >
                <mat-icon class="fas fa-user"></mat-icon>
                Myself
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="row clearfix animate" *ngIf="medicalHistory.formFilledOnBehalf == true">
            <div class="row clearfix text-center" *ngIf="hasGuardian == true && isModifyGuardian == false">
              <div class="animate">
                <h3 class="white rm-mt">Guardian Details</h3>
                <h3 class="white hlight">
                  {{ guardianObject.title }} {{ guardianObject.firstname }}
                  {{ guardianObject.lastname }}
                </h3>
                <h3 class="white add-mt" *ngIf="guardianObject.email">and guardian email address:</h3>
                <h3 class="white hlight" *ngIf="guardianObject.email">{{ guardianObject.email }}</h3>
                <h3 class="white add-mt" *ngIf="guardianObject.mobile">and guardian mobile number:</h3>
                <h3 class="white hlight" *ngIf="guardianObject.mobile">{{ guardianObject.mobile }}</h3>
              </div>

              <button class="mr" (click)="removeGuardian()" mat-raised-button color="accent">
                Remove
                <mat-icon>block</mat-icon>
              </button>
              <button class="mt" (click)="isModifyGuardian = true" mat-raised-button color="warn">
                Edit
                <mat-icon>edit</mat-icon>
              </button>

              <div class="row clearfix text-center animated fadeInUp delay-1s add-mt" @ngIfAnimation>
                <h3 class="white animate">Press 'Next' to continue</h3>
              </div>
            </div>

            <div class="row clearfix" *ngIf="hasGuardian == false || isModifyGuardian == true">
              <div class="row clearfix">
                <h3 class="white animate text-center">Guardian Details</h3>
                <div class="animate wht">
                  <h3 class="white text-center">Please enter your details below.</h3>
                  <div class="flex row clearfix">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Type of guardian</mat-label>
                      <mat-select
                        class=" "
                        [(ngModel)]="guardianObject.contactType"
                        name="typeOf"
                        placeholder="Type of guardian"
                        required
                      >
                        <mat-option
                          *ngFor="let p of possibleContactTypes | OrderArrayObject: ['Label']"
                          [value]="p.Code"
                        >
                          {{ p.Label }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div
                    class="flex row clearfix"
                    *ngIf="guardianObject.contactType == 'NOK' || guardianObject.contactType == 'REL'"
                  >
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Relateive Type</mat-label>
                      <mat-select
                        class="full-width"
                        [(ngModel)]="guardianObject.relativeTypeCode"
                        placeholder="Relative Type"
                        name="RelativeType"
                        required
                      >
                        <mat-option *ngFor="let r of relativeTypes | OrderArrayObject: ['Label']" [value]="r.Code">
                          {{ r.Label }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="flex row clearfix">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Gender</mat-label>
                      <mat-select
                        class="full-width"
                        [(ngModel)]="guardianObject.gender"
                        name="gender"
                        placeholder="Gender"
                        required
                      >
                        <mat-option *ngFor="let gender of genders | OrderArrayObject: ['Label']" [value]="gender.Code">
                          {{ gender.Label }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Title</mat-label>
                      <mat-select
                        class="full-width"
                        [(ngModel)]="guardianObject.title"
                        placeholder="Title"
                        name="title"
                        required
                      >
                        <mat-option *ngFor="let title of titles | OrderArrayObject: ['Label']" [value]="title.Label">
                          {{ title.Label }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="flex row clearfix">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>First name</mat-label>
                      <input
                        class="mat-block"
                        #guardName
                        [(ngModel)]="guardianObject.firstname"
                        matInput
                        placeholder="First name"
                        name="guardName"
                        required
                      />
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Middle name(s)</mat-label>
                      <input
                        class="mat-block"
                        [(ngModel)]="guardianObject.middlename"
                        matInput
                        placeholder="Middle name(s)"
                        name="middleName"
                      />
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Surname</mat-label>
                      <input
                        class="mat-block"
                        [(ngModel)]="guardianObject.lastname"
                        matInput
                        placeholder="Surname"
                        name="surName"
                        required
                      />
                    </mat-form-field>
                  </div>
                  <div class="flex row clearfix">
                    <app-email-input
                      class="full-width"
                      [email]="guardianObject.email"
                      [label]="'Email Address'"
                      [required]="false"
                      (validate)="validateGuardianEmailEvent($event)"
                      (getEmail)="getGuardianEmail($event)"
                    ></app-email-input>

                    <app-phone-input
                      class="full-width"
                      [phoneNumber]="guardianObject.mobile"
                      [label]="'Mobile Number'"
                      [required]="false"
                      (validate)="validateGuardianMobileEvent($event)"
                      (getPhone)="getGuardianMobile($event)"
                    ></app-phone-input>
                  </div>

                  <div class="row clearfix text-center" *ngIf="guardName.value.length >= 1" @ngIfAnimation>
                    <h3 class="white animate">Press 'Next' to continue</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="row clearfix text-center animate step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            class="saveAndGo"
            *ngIf="medicalHistory.formFilledOnBehalf != true"
            [disabled]="medicalHistory.formFilledOnBehalf == null"
            (click)="selectedNextStep()"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>

          <button
            class="saveAndGo"
            *ngIf="medicalHistory.formFilledOnBehalf == true"
            [disabled]="
              !createGuardianForm.form.valid || isGuardianEmailValid == false || isGuardianMobileValid == false
            "
            (click)="saveGuardian()"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <!-- personal details -->
    <aw-wizard-step
      [navigationSymbol]="{ symbol: '3' }"
      (stepEnter)="stepEntered($event); setStepCounter(66)"
      awOptionalStep
      title="Personal Information"
    >
      <div class="container  animated faster {{ enterslide }}" *ngIf="patient">
        <form class="flex flexCol" #editPatientForm="ngForm">
          <div class="flex flexCol">
            <div class="row clearfix text-center">
              <h3 class="white animate rm-mt">We need to confirm your details are current</h3>
            </div>

            <div class="rel" *ngIf="isNameCorrect == null || isNameCorrect == true" @ngIfAnimation>
              <div class="image-area row clearfix anim animate">
                <img
                  class="full-width clearfix center name-details"
                  src="https://email-graphics-smileright.s3-ap-southeast-2.amazonaws.com/mailimg/medical-history-header.png"
                />
              </div>
            </div>

            <div class="row clearfix text-center">
              <h3 class="white animate rm-mt">We've got your details down as</h3>
            </div>
            <div
              class="row clearfix text-center"
              *ngIf="(isNameCorrect == null || isNameCorrect == true) && patient.email && patient.dateOfBirth"
              @ngIfAnimation
            >
              <div class="animate">
                <h3 class="white hlight mb">{{ patient.title }} {{ patient.firstname }} {{ patient.lastname }}</h3>

                <h4 class="white rm-m">Address:</h4>
                <h3 class="white hlight mb" *ngIf="patient.address">{{ patient.address }}</h3>
                <h3 class="white hlight mb" *ngIf="!patient.address">...</h3>

                <div class="full-width flex mb">
                  <div class="full-width mr">
                    <h4 class="white rm-m" *ngIf="patient.mobile">Mobile*:</h4>
                    <h3 class="white hlight" *ngIf="patient.mobile">{{ patient.mobile | customPhone }}</h3>

                    <h3 class="white hlight" *ngIf="!patient.mobile">...</h3>
                  </div>

                  <div class="full-width">
                    <h4 class="white rm-m">Phone:</h4>
                    <h3 class="white hlight" *ngIf="patient.phone">{{ patient.phone | customPhone }}</h3>
                    <h3 class="white hlight" *ngIf="!patient.phone">...</h3>
                  </div>
                </div>

                <div class="full-width flex mb">
                  <div class="full-width mr">
                    <h4 class="white rm-m">Email*:</h4>
                    <h3 class="white hlight">{{ patient.email }}</h3>
                  </div>

                  <div class="full-width">
                    <h4 class="white rm-m">Date of birth*:</h4>
                    <h3 class="white hlight" *ngIf="patient.dateOfBirth">
                      {{ patient.dateOfBirth | customDate }}
                    </h3>

                    <h3 class="white hlight" *ngIf="!patient.dateOfBirth">...</h3>
                  </div>
                </div>
              </div>

              <div class="animate">
                <h2 class="white">Is this correct?</h2>
              </div>
            </div>

            <div
              class="row clearfix"
              *ngIf="(isNameCorrect == null || isNameCorrect == true) && patient.email && patient.dateOfBirth"
              @ngIfAnimation
            >
              <mat-button-toggle-group
                class="divided-toggle animate"
                [(ngModel)]="isNameCorrect"
                name="editPatientDetails"
              >
                <mat-button-toggle class="btn-large btn-icon no" [value]="false" color="accent" ngDefaultControl>
                  <mat-icon class="fas fa-thumbs-down"></mat-icon>
                  No
                </mat-button-toggle>
                <mat-button-toggle
                  class="btn-large btn-icon yes"
                  [value]="true"
                  (click)="selectedNextStep()"
                  color="primary"
                  ngDefaultControl
                >
                  <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>

            <div
              class="row clearfix text-center wht flex flexCol"
              *ngIf="
                (isNameCorrect == false ||
                  !patient.email ||
                  !patient.dateOfBirth ||
                  patient.dateOfBirth == '0000-00-00') &&
                medicalHistoryFull &&
                medicalHistoryFull.patient
              "
              @ngIfAnimation
            >
              <div class="animate">
                <h3 class="white rm-mb">Our apologies!</h3>
                <h3 class="white">Please update your details below.</h3>
                <div class="full-width flex">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Gender</mat-label>

                    <mat-select
                      class="full-width"
                      [(ngModel)]="patient.gender"
                      name="gender"
                      placeholder="Gender"
                      required
                    >
                      <mat-option *ngFor="let gender of genders | OrderArrayObject: ['Label']" [value]="gender.Code">
                        {{ gender.Label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Title</mat-label>
                    <mat-select
                      class="full-width"
                      [(ngModel)]="patient.title"
                      placeholder="Title"
                      name="title"
                      required
                    >
                      <mat-option *ngFor="let title of titles | OrderArrayObject: ['Label']" [value]="title.Label">
                        {{ title.Label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="full-width flex">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>First name</mat-label>
                    <input
                      class="mat-block"
                      #firstName
                      [(ngModel)]="patient.firstname"
                      matInput
                      placeholder="First name"
                      name="firstName"
                      required
                    />
                  </mat-form-field>
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Middle name(s)</mat-label>
                    <input
                      class="mat-block"
                      [(ngModel)]="patient.middlename"
                      matInput
                      placeholder="Middle name(s)"
                      name="middleName"
                    />
                  </mat-form-field>
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Surname</mat-label>
                    <input
                      class="mat-block"
                      [(ngModel)]="patient.lastname"
                      matInput
                      placeholder="Surname"
                      name="surName"
                      required
                    />
                  </mat-form-field>
                </div>

                <div class="full-width flex">
                  <app-date-input
                    class="full-width"
                    *ngIf="medicalHistoryFull.patient.DateOfBirth != '0000-00-00'"
                    [defaultDate]="medicalHistoryFull.patient.DateOfBirth"
                    [startView]="'multi-year'"
                    [isTime]="false"
                    [maxDate]="'toDay'"
                    [startView]="'multi-year'"
                    (selectedDate)="selectDateToFilter($event)"
                    label="Date of birth"
                  >
                  </app-date-input>

                  <app-date-input
                    class="full-width"
                    *ngIf="medicalHistoryFull.patient.DateOfBirth == '0000-00-00'"
                    [defaultDate]="null"
                    [isTime]="false"
                    [maxDate]="'toDay'"
                    (selectedDate)="selectDateToFilter($event)"
                    label="Date of birth"
                  >
                  </app-date-input>
                </div>

                <h3 class="white">Contact details</h3>
                <div class="full-width flex">
                  <app-phone-input
                    class="full-width"
                    [phoneNumber]="medicalHistoryFull.patient['mobiles.Number']"
                    [label]="'Mobile'"
                    [required]="true"
                    (validate)="validateMobile($event)"
                    (getPhone)="getMobile($event)"
                  >
                  </app-phone-input>

                  <app-phone-input
                    class="full-width"
                    [phoneNumber]="medicalHistoryFull.patient['phones.Number']"
                    [isLandLine]="true"
                    [label]="'Home Phone'"
                    [required]="false"
                    (validate)="validateHomePhone($event)"
                    (getPhone)="getHomePhone($event)"
                  >
                  </app-phone-input>
                </div>
                <div class="full-width flex">
                  <app-email-input
                    class="full-width"
                    [email]="medicalHistoryFull.patient['emails.Email']"
                    [label]="'Email Address'"
                    [required]="true"
                    (validate)="validatePatientEmailEvent($event)"
                    (getEmail)="getPatientEmail($event)"
                  ></app-email-input>
                </div>

                <h3 class="white">Address details</h3>
                <div class="full-width flex">
                  <app-address-input
                    class="full-width"
                    [isRequired]="false"
                    [displayMap]="false"
                    [canClose]="false"
                    [noIcons]="false"
                    [postCode]="medicalHistoryFull.patient['addresses.Postcode']"
                    [state]="medicalHistoryFull.patient['addresses.State']"
                    [suburb]="medicalHistoryFull.patient['addresses.Suburb']"
                    [streetNumber]="medicalHistoryFull.patient['addresses.Street Nr']"
                    [streetName]="medicalHistoryFull.patient['addresses.Street Name']"
                    [unitNumber]="medicalHistoryFull.patient['addresses.Unit']"
                    [streetType]="medicalHistoryFull.patient['addresses.Street Type']"
                    [latitude]="medicalHistoryFull.patient['Address.Latitude']"
                    [longitude]="medicalHistoryFull.patient['Address.Longitude']"
                    (getAdress)="getAdress($event)"
                    (validate)="isAdressProfileValidEvent($event)"
                  ></app-address-input>
                </div>
              </div>

              <div class="row clearfix text-center" *ngIf="firstName.value.length >= 1" @ngIfAnimation>
                <h3 class="white animate">Press 'Next' to continue</h3>
              </div>
            </div>
          </div>
        </form>
        <div class="row clearfix animate text-center step-navigation">
          <button (click)="selectedNextStepBackward(); isNameCorrect = null" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            *ngIf="isNameCorrect != false"
            (click)="selectedNextStep(); isNameCorrect = true"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon> chevron_right</mat-icon>
          </button>
          <button
            *ngIf="isNameCorrect == false"
            [disabled]="
              !editPatientForm.form.valid ||
              isPatientEmailValid == false ||
              isAddressValid == false ||
              isPhoneValid == false ||
              isMobileValid == false ||
              !patient.dateOfBirth ||
              patient.dateOfBirth == '0000-00-00' ||
              isPatientEmailValid == false
            "
            (click)="updatePatient()"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon> chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step
      [navigationSymbol]="{ symbol: '4' }"
      (stepEnter)="stepEntered($event); setStepCounter(95)"
      awOptionalStep
      title="Check Date"
    >
      <div class="container  animated faster {{ enterslide }}" @ngIfAnimation>
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt">Our records show your last scale and clean was on this date</h3>
        </div>

        <div class="rel" @ngIfAnimation>
          <div class="image-area row clearfix anim">
            <img
              class="big animated bounceInLeft"
              src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/last-check.png"
            />
          </div>
          <div class="animate">
            <div class="row clearfix wht check" *ngIf="!changeDateCheckup">
              <div class="full-width mb">
                <h3
                  class="white cleafix full-width text-center rm-m"
                  *ngIf="medicalHistory.lastCheckUpDate && medicalHistory.lastCheckUpDate != '0000-00-00'"
                  @ngIfAnimation
                >
                  {{ medicalHistory.lastCheckUpDate | customDate }} -
                  {{ medicalHistory.lastCheckUpDate | timeLeft: null:'years,months,days':true }}
                </h3>

                <h3
                  class="white cleafix full-width text-center rm-m"
                  *ngIf="!medicalHistory.lastCheckUpDate || medicalHistory.lastCheckUpDate == '0000-00-00'"
                  @ngIfAnimation
                >
                  Never at our {{"KEYWORD.practice" | translate}}
                </h3>
              </div>

              <div class="animate full-width">
                <h2 class="white clearfix text-center full-width">Is this correct?</h2>
              </div>
            </div>
            <div class="row clearfix wht check" *ngIf="changeDateCheckup == true">
              <div class="full-width text-center clearfix flex">
                <mat-calendar
                  class="clearfix half-width date-checkup-calendar"
                  *ngIf="medicalHistory.lastCheckUpDate && medicalHistory.lastCheckUpDate != '0000-00-00'"
                  @ngIfAnimation
                  [startView]="'year'"
                  [maxDate]="dateNow"
                  [selected]="medicalHistory.lastCheckUpDate"
                  (selectedChange)="selectedDateChanged($event)"
                >
                </mat-calendar>

                <mat-calendar
                  class="clearfix half-width date-checkup-calendar"
                  *ngIf="!medicalHistory.lastCheckUpDate || medicalHistory.lastCheckUpDate == '0000-00-00'"
                  @ngIfAnimation
                  [startView]="'year'"
                  [maxDate]="dateNow"
                  [selected]="null"
                  (selectedChange)="selectedDateChanged($event)"
                >
                </mat-calendar>
              </div>

              <div
                class="full-width flex mb clearfix"
                *ngIf="medicalHistory.lastCheckUpDate && medicalHistory.lastCheckUpDate != '0000-00-00'"
              >
                <div class="full-width mb">
                  <h3 class="full-width text-center white">
                    Your visit was on {{ medicalHistory.lastCheckUpDate | customDate }} -
                    {{ medicalHistory.lastCheckUpDate | timeLeft: null:'years,months,days':true }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row clearfix" *ngIf="changeDateCheckup != true" @ngIfAnimation>
          <mat-button-toggle-group
            class="divided-toggle animate"
            [(ngModel)]="changeDateCheckup"
            name="editPatientDetails"
          >
            <mat-button-toggle class="btn-large btn-icon no" [value]="true" color="accent" ngDefaultControl>
              <mat-icon class="fas fa-thumbs-down"></mat-icon>
              No
            </mat-button-toggle>
            <mat-button-toggle
              class="btn-large btn-icon yes"
              [value]="false"
              (click)="saveMedicalHistoryAndProceed(2)"
              color="primary"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="row clearfix animate text-center step-navigation">
          <button (click)="selectedNextStepBackward(); changeDateCheckup = false" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button (click)="saveMedicalHistoryAndProceed(2)" mat-raised-button color="accent">
            Next
            <mat-icon> chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step
      *ngIf="patient && patient.gender == 'F'"
      [navigationSymbol]="{ symbol: '5' }"
      (stepEnter)="stepEntered($event); setStepCounter(15)"
      awOptionalStep
      title="Pregnancy"
    >
      <div class="container animated faster {{ enterslide }}" *ngIf="patient">
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt">Are you pregnant?</h3>
        </div>

        <div class="image-area row clearfix">
          <img
            class="big animated bounceInRight"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/pregnant.png"
          />
        </div>

        <div class="row clearfix animate">
          <mat-button-toggle-group class="divided-toggle" [(ngModel)]="medicalHistory.isPregnant">
            <mat-button-toggle
              class="btn-large btn-icon no"
              [value]="false"
              (click)="selectedNextStepForward()"
              color="accent"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-down"></mat-icon>
              No
            </mat-button-toggle>
            <mat-button-toggle class="btn-large btn-icon yes" [value]="true" color="primary" ngDefaultControl>
              <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="row clearfix" *ngIf="medicalHistory.isPregnant == true" @ngIfAnimation>
          <div class="wht animate clearfix">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>How many months?</mat-label>
              <input
                class="mat-block"
                [(ngModel)]="medicalHistory.pregnancyMonths"
                matInput
                placeholder="How many months?"
                name="cultureDiff"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            [disabled]="medicalHistory.isPregnant == null"
            (click)="selectedNextStep()"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step
      *ngIf="patient && patient.gender == 'F'"
      [navigationSymbol]="{ symbol: '6' }"
      (stepEnter)="stepEntered($event); setStepCounter(30)"
      awOptionalStep
      title="Breastfeeding"
    >
      <div class="container animated faster {{ enterslide }}" *ngIf="patient">
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt">Are you breastfeeding?</h3>
        </div>

        <div class="image-area row clearfix">
          <img
            class="big fast animated bounceInDown"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/baby.png"
          />
        </div>

        <div class="row clearfix animate">
          <mat-button-toggle-group class="divided-toggle" [(ngModel)]="medicalHistory.isBreastFeeding">
            <mat-button-toggle
              class="btn-large btn-icon no"
              [value]="false"
              (click)="selectedNextStepForward()"
              color="accent"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-down"></mat-icon>
              No
            </mat-button-toggle>
            <mat-button-toggle
              class="btn-large btn-icon yes"
              [value]="true"
              (click)="selectedNextStepForward()"
              color="primary"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            [disabled]="medicalHistory.isBreastFeeding == null"
            (click)="selectedNextStepForward()"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step
      [navigationSymbol]="{ symbol: '7' }"
      (stepEnter)="stepEntered($event); setStepCounter(45)"
      awOptionalStep
      title="Health History"
    >
      <div class="container  animated faster {{ enterslide }}" *ngIf="firstLoad == true">
        <div class="row clearfix">
          <div class="row clearfix" *ngIf="isReviewIllness == false" @ngIfAnimation>
            <div class="animate">
              <div class="row clearfix" *ngIf="isEdit == true && isEmptyIllnessList == false && isAddIllness == false">
                <h3 class="white text-center rm-mt">Your illnesses</h3>
                <app-illness-list
                  *ngIf="medicalHistoryID"
                  [medicalHistoryID]="medicalHistoryID"
                  [illnessList]="medicalHistoryFull.illness"
                  [isCreate]="false"
                  (isEmptyList)="isEmptyListEvent($event)"
                  (getNumberOfIllness)="getNumberOfIllness($event)"
                >
                </app-illness-list>
              </div>

              <div class="row clearfix" *ngIf="isEmptyIllnessList == true || isAddIllness == true">
                <h3 class="white text-center rm-mt">
                  Have you ever had any of the following illnesses, medical conditions or treatment?
                </h3>

                <div class="image-area shorter row clearfix anim">
                  <img
                    class="sml animated bounceInDown"
                    src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/medical-box.png"
                  />
                </div>

                <p class="rm-mt white text-center mb">
                  Please choose those that apply, and provide some details in the window that appears. When you are
                  done, click 'Next'.
                </p>
                <div
                  class="row clearfix illnessList"
                  *ngFor="let i of illnessList | WithoutArrayMultipleValue: 'Code':currentIllnesses"
                  @ngIfAnimationFast
                >
                  <mat-checkbox
                    class="full-width mat-elevation-z6 anime"
                    [(ngModel)]="i['showIllnessDescription']"
                    (ngModelChange)="selectIllness(i)"
                  >{{ i.Label }}
                  </mat-checkbox>
                </div>
              </div>
              <div class="row clearfix flex text-center add-mt add-mb">
                <button
                  class="full-width"
                  *ngIf="isEdit == true && isEmptyIllnessList == false && isAddIllness == false"
                  (click)="addNewIllness()"
                  mat-raised-button
                  color="warn"
                >
                  <mat-icon>add</mat-icon> Add illness
                </button>
                <button
                  class="full-width"
                  *ngIf="isAddIllness == true || isEmptyIllnessList == true"
                  (click)="reviewIllnesses()"
                  mat-raised-button
                  color="warn"
                >
                  <mat-icon>search</mat-icon>
                  Review illness <span *ngIf="myIllnessCount">({{ myIllnessCount }})</span>
                </button>
              </div>
            </div>
          </div>
          <div class="row clearfix" *ngIf="isReviewIllness == true" @ngIfAnimation>
            <div class="animate">
              <div class="row clearfix">
                <h3 class="white text-center rm-mt">
                  Please review your illnesses, medical condition or {{ "KEYWORD.treatment" | translate }} declared.
                </h3>
                <app-illness-list
                  *ngIf="medicalHistoryID"
                  [illnessList]="medicalHistoryFull.illness"
                  [medicalHistoryID]="medicalHistoryID"
                  [isCreate]="false"
                  (getNumberOfIllness)="getNumberOfIllness($event)"
                >
                </app-illness-list>
              </div>

              <div class="row clearfix mt mb">
                <button class="full-width" (click)="addNewIllness()" mat-raised-button color="warn">
                  <mat-icon>add</mat-icon> Add illness
                </button>
                <!-- <button mat-raised-button color="accent" (click)="isReviewIllness=true" *ngIf="isAddIllness==true || isEmptyIllnessList==true">Review Illness</button> -->
              </div>
            </div>
          </div>
        </div>
        <div class="row clearfix text-center flex navi additional-mt">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button (click)="selectedNextStep()" mat-raised-button color="accent">
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step
      [navigationSymbol]="{ symbol: '8' }"
      (stepEnter)="stepEntered($event); setStepCounter(65)"
      awOptionalStep
      title="Allergies"
    >
      <div class="container  animated faster {{ enterslide }}">
        <div class="row clearfix">
          <h3 class="white animate text-center rm-mt">Do you have any allergies?</h3>
          <p class="rm-mt white text-center mb">
            If you have had allergies or ever experienced an allergic reaction use the "Add Allergy" button to detail
            your allergies.
          </p>

          <div class="row clearfix" *ngIf="isEdit == true">
            <app-allergy-list
              class="allergyList"
              [allergyList]="medicalHistoryFull.allergy"
              [medicalHistoryID]="medicalHistoryID"
              [isConsumer]="true"
            >
            </app-allergy-list>
          </div>
        </div>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button (click)="selectedNextStep()" mat-raised-button color="accent">
            Next
            <mat-icon> chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <!-- medicare -->
    <aw-wizard-step
      [navigationSymbol]="{ symbol: '9' }"
      (stepEnter)="stepEntered($event); setStepCounter(75)"
      awOptionalStep
      title="Insurance & Payment Information"
    >
      <div class="container  animated faster {{ enterslide }}">
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt">Do you have a Medicare card?</h3>
        </div>

        <div class="image-area row clearfix privHealth medicare">
          <img
            class="big p1 bottom animated bounceInDown"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/Medicare_brand.png"
          />
        </div>

        <div class="row clearfix animate">
          <mat-button-toggle-group class="divided-toggle" [(ngModel)]="hasMedicare">
            <mat-button-toggle
              class="btn-large btn-icon no"
              [value]="false"
              (click)="selectedNextStep()"
              color="accent"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-down"></mat-icon>
              No
            </mat-button-toggle>
            <mat-button-toggle class="btn-large btn-icon yes" [value]="true" color="primary" ngDefaultControl>
              <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <form #MedicareForm="ngForm">
          <div class="row clearfix wht" *ngIf="hasMedicare == true" @ngIfAnimation>
            <div class="row clearfix">
              <div class="row clearfix mt">
                <mat-form-field class="full-width animate" appearance="outline">
                  <mat-label>Enter the card number e.g. 6102 69352 4</mat-label>
                  <input
                    class="mat-block"
                    [(ngModel)]="medicareNumber"
                    matInput
                    placeholder="Enter the card number e.g. 6102 69352 4"
                    name="medicareNumber"
                    required
                  />
                </mat-form-field>
              </div>

              <div class="row clearfix flex">
                <mat-form-field class="full-width animate" appearance="outline">
                  <mat-label>Enter the reference number next to your name e.g. 1</mat-label>
                  <input
                    class="mat-block"
                    [(ngModel)]="medicareReferenceNumber"
                    matInput
                    placeholder="{{ 'Enter the reference number next to your name e.g. 1' | translate }}"
                    name="medicareReferenceNumber"
                    required
                  />
                </mat-form-field>

                <div class="full-width animate wht">
                  <app-date-input
                    class=""
                    [defaultDate]="medicareExpiryDefaultDate"
                    [label]="'Expiry date:'"
                    [isClear]="true"
                    (selectedDate)="getMedicareDate($event)"
                    name="medicareExpiry"
                  ></app-date-input>
                </div>
              </div>
            </div>

            <div class="row clearfix text-center" *ngIf="medicareNumber != null" @ngIfAnimation>
              <h3 class="white animate">Press 'Next' to continue</h3>
            </div>
          </div>
        </form>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            *ngIf="hasMedicare == true"
            [disabled]="!MedicareForm.form.valid || !medicareExpiry"
            (click)="createMedicare()"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
          <button
            *ngIf="hasMedicare != true"
            [disabled]="hasMedicare == null"
            (click)="selectedNextStep()"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <!-- gp doctor -->
    <aw-wizard-step
      [navigationSymbol]="{ symbol: '10' }"
      (stepEnter)="stepEntered($event); setStepCounter(85)"
      awOptionalStep
      title="Dental History"
    >
      <div class="container  animated faster {{ enterslide }}">
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt">Do you have a GP or family doctor you visit?</h3>
        </div>

        <div class="image-area row clearfix pracName">
          <img
            class="big p3 bottom animated bounceInDown delay-05s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/prac-4.png"
          />
          <img
            class="big p4 bottom animated bounceInDown delay-04s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/prac-3.png"
          />
          <img
            class="big p2 bottom animated bounceInDown delay-02s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/prac-2.png"
          />
          <img
            class="big p1 bottom animated bounceInDown"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/prac-1.png"
          />
        </div>

        <div class="row clearfix animate">
          <mat-button-toggle-group class="divided-toggle mobileFlow" [(ngModel)]="hasGpDoctor">
            <mat-button-toggle
              class="btn-large btn-icon no"
              [value]="false"
              (click)="selectedNextStep()"
              color="accent"
            >
              <mat-icon class="fas fa-thumbs-down"></mat-icon>
              I don't have a GP
            </mat-button-toggle>
            <mat-button-toggle class="btn-large btn-icon yes" [value]="true" color="primary">
              <mat-icon class="fas fa-thumbs-up"></mat-icon>I have a GP
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <form #gpForm="ngForm">
          <div class="row clearfix mt" *ngIf="hasGpDoctor == true" @ngIfAnimation>
            <div class="row clearfix flex animate">
              <mat-form-field class="full-width wht" appearance="outline">
                <mat-label>{{ "KEYWORD.practice" | translate | titlecase}} Name</mat-label>
                <input
                  class="mat-block"
                  [(ngModel)]="gpDoctor.practiceName"
                  matInput
                  [placeholder]="('KEYWORD.practice' | translate | titlecase) + ' Name'"
                  name="dentalExistingPracticeName"
                  required
                />
              </mat-form-field>
            </div>

            <div class="row clearfix flex animate">
              <mat-form-field class="full-width wht" appearance="outline">
                <mat-label> {{ "KEYWORD.Doctor's" | translate }} first name</mat-label>
                <input
                  class="mat-block"
                  [(ngModel)]="gpDoctor.firstname"
                  [placeholder]="('KEYWORD.Doctor\'s' | translate) + ' first name'"
                  matInput
                  name="gpDoctorFirstName"
                  required
                />
              </mat-form-field>

              <mat-form-field class="full-width wht" appearance="outline">
                <mat-label>{{ "KEYWORD.Doctor's" | translate }} last name</mat-label>
                <input
                  class="mat-block"
                  [(ngModel)]="gpDoctor.lastname"
                  [placeholder]="('KEYWORD.Doctor\'s' | translate) + ' last name'"
                  matInput
                  name="gpDoctorLastName"
                  required
                />
              </mat-form-field>
            </div>

            <div class="row clearfix flex animate">
              <app-email-input
                class="full-width"
                [email]="gpDoctor.email"
                [label]="'Email Address'"
                [required]="false"
                (validate)="validateGpDoctorEmailEvent($event)"
                (getEmail)="getGpDoctorEmail($event)"
              ></app-email-input>

              <app-phone-input
                class="full-width"
                [phoneNumber]="gpDoctor.mobile"
                [label]="'Contact Number'"
                [required]="false"
                (validate)="validateGpDoctorMobileEvent($event)"
                (getPhone)="getGpDoctorMobile($event)"
              ></app-phone-input>
            </div>
            <div class="row clearfix text-center" *ngIf="gpDoctor.practiceName" @ngIfAnimation>
              <h3 class="white animate">Press 'Next' to continue</h3>
            </div>
          </div>
        </form>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            *ngIf="hasGpDoctor != true"
            [disabled]="hasGpDoctor == null"
            (click)="selectedNextStep()"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>

          <button
            *ngIf="hasGpDoctor == true"
            [disabled]="!gpForm.form.valid"
            (click)="createGPDoctor()"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <!-- health insurance -->

    <aw-wizard-step
      [navigationSymbol]="{ symbol: '11' }"
      (stepEnter)="stepEntered($event); setStepCounter(95)"
      awOptionalStep
      title="Insurance & Payment Information"
    >
      <div class="container  animated faster {{ enterslide }}">
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt">Do you have health insurance?</h3>
        </div>

        <div class="image-area row clearfix privHealth">
          <img
            class="big p4 animated bounceInDown delay-05s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/doc-4.png"
          />
          <img
            class="big p3 animated bounceInDown delay-04s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/doc-3.png"
          />
          <img
            class="big p2 animated bounceInDown delay-02s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/doc-2.png"
          />
          <img
            class="big p1 animated bounceInDown"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/doc-1.png"
          />
        </div>

        <div class="row clearfix animate">
          <mat-button-toggle-group class="divided-toggle" [(ngModel)]="hasHealthInsurance">
            <mat-button-toggle
              class="btn-large btn-icon no"
              [value]="false"
              (click)="proceedAndShowStar(3)"
              color="accent"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-down"></mat-icon>
              No
            </mat-button-toggle>
            <mat-button-toggle class="btn-large btn-icon yes" [value]="true" color="primary" ngDefaultControl>
              <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <form #healthInsuranceForm="ngForm">
          <div class="row clearfix wht" *ngIf="hasHealthInsurance == true" @ngIfAnimation>
            <div class="row clearfix">
              <div class="row clearfix flex">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Private health fund name</mat-label>
                  <mat-select
                    class="full-width"
                    [(ngModel)]="healthFundName"
                    name="fundName"
                    placeholder="Private health fund name"
                    required
                  >
                    <mat-option
                      *ngFor="
                        let healthfund of healthfunds
                          | OrderArrayObject: ['Label']
                          | WithoutArrayMultipleValue: 'Code':['OTHRS']
                      "
                      [value]="healthfund.Code"
                    >
                      {{ healthfund.Label }}
                    </mat-option>

                    <mat-option [value]="'OTHRS'"> Other</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="full-width" *ngIf="healthFundName == 'OTHRS'" appearance="outline">
                  <mat-label>Enter Private health fund name</mat-label>
                  <input
                    class="mat-block"
                    [(ngModel)]="healthFundNameOther"
                    matInput
                    placeholder="Enter Private health fund name"
                    name="healthFundNameOther"
                    required
                  />
                </mat-form-field>
              </div>

              <div class="row clearfix flex mt">
                <mat-form-field class="full-width animate" appearance="outline">
                  <mat-label>Health Fund Number</mat-label>
                  <input
                    class="mat-block"
                    [(ngModel)]="healthFundNumber"
                    matInput
                    placeholder="Health Fund Number"
                    name="healthFundNumber"
                    required
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="row clearfix text-center" *ngIf="healthFundNumber != null" @ngIfAnimation>
              <h3 class="white animate">Press 'Next' to continue</h3>
            </div>
          </div>
        </form>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            *ngIf="hasHealthInsurance == true"
            [disabled]="!healthInsuranceForm.form.valid"
            (click)="createHealthFund()"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
          <button
            *ngIf="hasHealthInsurance != true"
            [disabled]="hasHealthInsurance == null"
            (click)="createHealthFund()"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step
      class=""
      [navigationSymbol]="{ symbol: '12' }"
      (stepEnter)="stepEntered($event); setStepCounter(0)"
      awOptionalStep
      title="Do you smoke"
    >
      <div class="container animated faster {{ enterslide }}">
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt">Are you a smoker?</h3>
        </div>

        <div class="image-area row clearfix smoke" [class.shrink]="medicalHistory.isSmoking == true">
          <img
            class="big fast ash animated bottom bounceInDown"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/ashtray.png"
          />
          <img
            class="med animated c1 delay-02s bounceInDown"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/cig-1.png"
          />
          <img
            class="med animated c2 delay-03s bounceInDown"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/cig-2.png"
          />
          <img
            class="med animated c3 delay-05s bounceInDown"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/cig-3.png"
          />
          <div class="med animated c1 bounceInDown add add1" *ngIf="medicalHistory.smokingTimes >= 2">
            <img
              class=""
              src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/cig-2.png"
            />
          </div>
          <div class="med animated c2 bounceInDown add add2" *ngIf="medicalHistory.smokingTimes >= 3">
            <img
              class=""
              src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/cig-1.png"
            />
          </div>
          <div class="med animated c2 bounceInDown add add3" *ngIf="medicalHistory.smokingTimes >= 4">
            <img
              class=""
              src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/cig-3.png"
            />
          </div>
          <div class="med animated c2 bounceInDown add add4 delay-02s" *ngIf="medicalHistory.smokingTimes >= 4">
            <img
              class=""
              src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/cig-2.png"
            />
          </div>
          <div class="med animated c2 bounceInDown add add5" *ngIf="medicalHistory.smokingTimes >= 5">
            <img
              class=""
              src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/cig-3.png"
            />
          </div>
          <div class="med animated c2 bounceInDown add add6 delay-02s" *ngIf="medicalHistory.smokingTimes >= 5">
            <img
              class=""
              src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/cig-2.png"
            />
          </div>
          <div class="med animated c2 bounceInDown add add7 delay-04s" *ngIf="medicalHistory.smokingTimes >= 5">
            <img
              class=""
              src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/cig-3.png"
            />
          </div>
          <img
            class="big moke animated bottom fadeIn delay-1s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/smoke.png"
          />
        </div>

        <div class="row clearfix animate">
          <mat-button-toggle-group class="divided-toggle" [(ngModel)]="medicalHistory.isSmoking">
            <mat-button-toggle
              class="btn-large btn-icon no"
              [value]="false"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="accent"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-down"></mat-icon>
              No
            </mat-button-toggle>
            <mat-button-toggle class="btn-large btn-icon yes" [value]="true" color="primary" ngDefaultControl>
              <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="row clearfix" *ngIf="medicalHistory.isSmoking == true" @ngIfAnimation>
          <div class="animate clearfix">
            <div class="row clearfix text-center">
              <h3 class="white animate">How much do you smoke per day?</h3>
            </div>

            <div class="row clearfix">
              <p class="small white mobPullLeft">Just a couple</p>
              <p class="small white mobPullRight">A pack or more</p>
            </div>
            <div class="row clearfix column">
              <mat-slider
                class="slider-lrg full-width"
                [(ngModel)]="medicalHistory.smokingTimes"
                min="1"
                max="5"
                step="1"
                tickInterval="1"
              ></mat-slider>
            </div>
            <div class="row clearfix text-center">
              <p
                class="white bold animate"
                *ngIf="
                  medicalHistory.smokingTimes == 0 ||
                  medicalHistory.smokingTimes == null ||
                  medicalHistory.smokingTimes == false
                "
                @ngIfAnimation
              >
                Please select
              </p>
              <p class="white bold animate" *ngIf="medicalHistory.smokingTimes == 1" @ngIfAnimation>Just a couple</p>
              <p class="white bold animate" *ngIf="medicalHistory.smokingTimes == 2" @ngIfAnimation>5 - 10</p>
              <p class="white bold animate" *ngIf="medicalHistory.smokingTimes == 3" @ngIfAnimation>10 - 20</p>
              <p class="white bold animate" *ngIf="medicalHistory.smokingTimes == 4" @ngIfAnimation>20 - 30</p>
              <p class="white bold animate" *ngIf="medicalHistory.smokingTimes == 5" @ngIfAnimation>A pack or more</p>
            </div>
          </div>
        </div>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            [disabled]="medicalHistory.isSmoking == null"
            (click)="updateHealthHistoryNotComplete('step')"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step
      class=""
      [navigationSymbol]="{ symbol: '13' }"
      (stepEnter)="stepEntered($event); setStepCounter(33)"
      awOptionalStep
      title="Do you drink"
    >
      <div class="container animated faster {{ enterslide }}">
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt">Do you drink alcohol?</h3>
        </div>

        <div class="image-area row clearfix drink mb" [class.shrink]="medicalHistory.drinkWine == true">
          <img
            class="center animated bottom bounceInDown"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/drink-1.png"
          />
          <img
            class="sml animated right delay-02s bounceInDown"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/drink-2.png"
          />

          <img
            class="sml animated add-1 bounceInDown"
            *ngIf="medicalHistory.drinkingTimes >= 2"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/drink-3.png"
          />
          <img
            class="animated add-2 bounceInDown"
            *ngIf="medicalHistory.drinkingTimes >= 3"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/drink-4.png"
          />
          <img
            class="animated add-3 bounceInDown"
            *ngIf="medicalHistory.drinkingTimes >= 4"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/drink-5.png"
          />
          <img
            class="sml animated add-4 bounceInDown"
            *ngIf="medicalHistory.drinkingTimes >= 5"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/drink-3.png"
          />
        </div>

        <div class="row clearfix animate">
          <mat-button-toggle-group class="divided-toggle" [(ngModel)]="medicalHistory.drinkWine">
            <mat-button-toggle
              class="btn-large btn-icon no"
              [value]="false"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="accent"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-down"></mat-icon>
              No
            </mat-button-toggle>
            <mat-button-toggle class="btn-large btn-icon yes" [value]="true" color="primary" ngDefaultControl>
              <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="row clearfix" *ngIf="medicalHistory.drinkWine == true" @ngIfAnimation>
          <div class="animate clearfix">
            <div class="row clearfix text-center">
              <h3 class="white animate">How much do you drink per day?</h3>
            </div>

            <div class="row clearfix">
              <p class="small white mobPullLeft">Just a couple</p>
              <p class="small white mobPullRight">Like a fish</p>
            </div>
            <div class="row clearfix column">
              <mat-slider
                class="slider-lrg full-width"
                [(ngModel)]="medicalHistory.drinkingTimes"
                min="1"
                max="5"
                step="1"
                tickInterval="1"
              ></mat-slider>
            </div>
            <div class="row clearfix text-center">
              <p
                class="white bold animate"
                *ngIf="
                  medicalHistory.drinkingTimes == 0 ||
                  medicalHistory.drinkingTimes == null ||
                  medicalHistory.drinkingTimes == false
                "
                @ngIfAnimation
              >
                Please select
              </p>
              <p class="white bold animate" *ngIf="medicalHistory.drinkingTimes == 1" @ngIfAnimation>Just a couple</p>
              <p class="white bold animate" *ngIf="medicalHistory.drinkingTimes == 2" @ngIfAnimation>up to 3 drinks</p>
              <p class="white bold animate" *ngIf="medicalHistory.drinkingTimes == 3" @ngIfAnimation>up to 6 drinks</p>
              <p class="white bold animate" *ngIf="medicalHistory.drinkingTimes == 4" @ngIfAnimation>up to 10 drinks</p>
              <p class="white bold animate" *ngIf="medicalHistory.drinkingTimes == 5" @ngIfAnimation>Like a fish</p>
            </div>
          </div>
        </div>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            [disabled]="medicalHistory.drinkWine == null"
            (click)="updateHealthHistoryNotComplete('step')"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step
      class=""
      [navigationSymbol]="{ symbol: '14' }"
      (stepEnter)="stepEntered($event); setStepCounter(66)"
      awOptionalStep
      title="Stress"
    >
      <div class="container animated faster {{ enterslide }}">
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt">Has there been any recent family stress or social changes at home?</h3>
        </div>

        <div class="image-area row clearfix stress">
          <img
            class="med top animated bounceInLeft delay-01s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/bad-weather-1.png"
          />
          <img
            class="big animated right bounceInRight"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/stress.png"
          />
        </div>

        <div class="row clearfix animate">
          <mat-button-toggle-group class="divided-toggle" [(ngModel)]="medicalHistory.hasFamilyStress">
            <mat-button-toggle
              class="btn-large btn-icon no"
              [value]="false"
              (click)="updateHealthHistoryNotComplete('proceed', 4)"
              color="accent"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-down"></mat-icon>
              No
            </mat-button-toggle>
            <mat-button-toggle class="btn-large btn-icon yes" [value]="true" color="primary" ngDefaultControl>
              <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="row clearfix" *ngIf="medicalHistory.hasFamilyStress == true" @ngIfAnimation>
          <div class="wht animate clearfix">
            <p class="white text-center">
              Did you know that stress also damages your teeth and gums? Research has shown that there is a connection
              between long-term stress and deteriorating oral health.
            </p>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Please tell us more...</mat-label>
              <textarea
                class="mat-block"
                [(ngModel)]="medicalHistory.familyStressDescription"
                matInput
                placeholder="Please tell us more..."
                name="cultureDiff"
              ></textarea>
            </mat-form-field>
          </div>
        </div>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            [disabled]="medicalHistory.hasFamilyStress == null"
            (click)="updateHealthHistoryNotComplete('proceed', 4)"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step
      class=""
      [navigationSymbol]="{ symbol: '15' }"
      (stepEnter)="stepEntered($event); triggerTheAnimation(); setStepCounter(0)"
      (stepExit)="endTheAnimation()"
      awOptionalStep
      title="Immediate problem"
    >
      <div class="container animated faster {{ enterslide }}">
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt">Do you have an immediate dental problem?</h3>
        </div>
        <!-- <div class="rel" *ngIf="!medicalHistory.hasDentalProblem" @ngIfAnimation> -->
        <div class="rel" @ngIfAnimation>
          <div class="image-area row clearfix anim">
            <img
              class="big animated bounceInLeft"
              src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/doctor-happy.png"
            />
            <like-animation class="sml animated infinite heartBeat" [type]="'tooth'" [anim]="anim"></like-animation>
          </div>
        </div>

        <div class="row clearfix animate">
          <mat-button-toggle-group
            class="divided-toggle"
            #immediateProblems="matButtonToggleGroup"
            [(ngModel)]="medicalHistory.hasDentalProblem"
          >
            <mat-button-toggle
              class="btn-large btn-icon no"
              [value]="false"
              (click)="updateHealthHistoryNotComplete('step')"
              color="accent"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-down"></mat-icon>
              No
            </mat-button-toggle>
            <mat-button-toggle class="btn-large btn-icon yes" [value]="true" color="primary" ngDefaultControl>
              <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="row clearfix mt" *ngIf="medicalHistory.hasDentalProblem == true" @ngIfAnimation>
          <div class="wht animate clearfix">
            <p class="white text-center mobile">
              Do you have any immediate problems that you are experiencing, including chronic mouth or facial pain,
              tooth decay, bleeding or soreness of gums?
            </p>
            <mat-form-field class="full-width animate" appearance="outline">
              <mat-label>Please explain...Type</mat-label>
              <textarea
                class="mat-block"
                [(ngModel)]="medicalHistory.dentalProblemDescription"
                matInput
                placeholder="Please explain...Type"
                name="dentalProblemDescription"
              ></textarea>
            </mat-form-field>
          </div>
        </div>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            [disabled]="medicalHistory.hasDentalProblem == null"
            (click)="updateHealthHistoryNotComplete('step')"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step
      [navigationSymbol]="{ symbol: '16' }"
      (stepEnter)="stepEntered($event); setStepCounter(5)"
      awOptionalStep
      title="Cleans how often"
    >
      <div class="container  animated faster {{ enterslide }}">
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt mobile">How many times a day do you clean your teeth?</h3>
        </div>

        <div class="image-area row clearfix">
          <img
            class="center-3 big animated bounceInDown delay-02s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/toothpaste-1.png"
          />
          <img
            class="center-4 big animated bounceInDown delay-05s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/toothbrush-2.png"
          />
          <img
            class="big fast animated cup fadeInUp"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/cup-1.png"
          />
        </div>

        <div class="row clearfix animate">
          <mat-button-toggle-group class="divided-toggle mobileFlow" [(ngModel)]="medicalHistory.cleansHowOften">
            <mat-button-toggle
              class="btn-large btn-icon multiple primary-bg low list-button-hoving"
              [value]="0"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="primary"
              ngDefaultControl
            >
              <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
              <span class="numberLabel"> Rarely</span>
            </mat-button-toggle>
            <mat-button-toggle
              class="btn-large btn-icon multiple primary-bg med list-button-hoving"
              [value]="1"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="accent"
              ngDefaultControl
            >
              <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
              <span class="numberLabel"> Once</span>
            </mat-button-toggle>
            <mat-button-toggle
              class="btn-large btn-icon multiple primary-bg hig list-button-hoving"
              [value]="2"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="primary"
              ngDefaultControl
            >
              <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
              <span class="numberLabel"> Twice</span>
            </mat-button-toggle>
            <mat-button-toggle
              class="btn-large btn-icon multiple primary-bg hig list-button-hoving"
              [value]="3"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="primary"
              ngDefaultControl
            >
              <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
              <span class="numberLabel"> 3 or more</span>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            [disabled]="medicalHistory.cleansHowOften == null"
            (click)="updateHealthHistoryNotComplete('step')"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <!-- sensitivity -->

    <aw-wizard-step
      class=""
      [navigationSymbol]="{ symbol: '17' }"
      (stepEnter)="stepEntered($event); setStepCounter(10)"
      awOptionalStep
      title="Sensitivity"
    >
      <div class="container animated faster {{ enterslide }}">
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt">Are any of your teeth sensitive to heat?</h3>
        </div>

        <div class="image-area row clearfix sensitive">
          <img
            class="big p2 animated fadeIn delay-03s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/sens-hot-2.png"
          />
          <img
            class="big p1 animated bounceInDown"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/sens-hot-1.png"
          />
        </div>

        <div class="row clearfix animate text-center">
          <mat-button-toggle-group class="divided-toggle animate" [(ngModel)]="sensitiveArray.hot">
            <mat-button-toggle
              class="btn-large btn-icon no"
              [value]="false"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="accent"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-down"></mat-icon>
              No
            </mat-button-toggle>
            <mat-button-toggle class="btn-large btn-icon yes" [value]="true" color="primary" ngDefaultControl>
              <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="row clearfix" *ngIf="sensitiveArray.hot == true" @ngIfAnimation>
          <div class="animate clearfix">
            <div class="row clearfix text-center">
              <h3 class="white animate">How bad is your sensitivity?</h3>
            </div>
            <div class="row clearfix">
              <p class="small white mobPullLeft">Mild</p>
              <p class="small white mobPullRight">Severe</p>
            </div>
            <div class="row clearfix column">
              <mat-slider
                class="slider-lrg full-width"
                [(ngModel)]="medicalHistory.sensitiveTeethWithHot"
                min="1"
                max="5"
                step="1"
                tickInterval="1"
              ></mat-slider>
            </div>
            <div class="row clearfix text-center">
              <p
                class="white bold animate"
                *ngIf="medicalHistory.sensitiveTeethWithHot == 0 || medicalHistory.sensitiveTeethWithHot == null"
                @ngIfAnimation
              >
                Please select
              </p>
              <p class="white bold animate" *ngIf="medicalHistory.sensitiveTeethWithHot == 1" @ngIfAnimation>Mild</p>
              <p class="white bold animate" *ngIf="medicalHistory.sensitiveTeethWithHot == 2" @ngIfAnimation>
                Moderate
              </p>
              <p class="white bold animate" *ngIf="medicalHistory.sensitiveTeethWithHot == 3" @ngIfAnimation>Bad</p>
              <p class="white bold animate" *ngIf="medicalHistory.sensitiveTeethWithHot == 4" @ngIfAnimation>
                Quite bad
              </p>
              <p class="white bold animate" *ngIf="medicalHistory.sensitiveTeethWithHot == 5" @ngIfAnimation>Severe</p>
            </div>
          </div>
        </div>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            [disabled]="sensitiveArray.hot == null"
            (click)="updateHealthHistoryNotComplete('step')"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step
      class=""
      [navigationSymbol]="{ symbol: '18' }"
      (stepEnter)="stepEntered($event); setStepCounter(15)"
      awOptionalStep
      title="Sensitivity"
    >
      <div class="container animated faster {{ enterslide }}">
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt">Are any of your teeth sensitive to cold?</h3>
        </div>

        <div class="image-area row clearfix sensitive">
          <img
            class="big p3 animated bounceInDown delay-03s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/sens-cold-3.png"
          />
          <img
            class="big p2 animated bounceInDown delay-01s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/sens-cold-2.png"
          />
          <img
            class="big p1 animated bounceInDown"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/sens-cold-1.png"
          />
        </div>

        <div class="row clearfix animate text-center">
          <mat-button-toggle-group class="divided-toggle animate" [(ngModel)]="sensitiveArray.cold">
            <mat-button-toggle
              class="btn-large btn-icon no"
              [value]="false"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="accent"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-down"></mat-icon>
              No
            </mat-button-toggle>
            <mat-button-toggle class="btn-large btn-icon yes" [value]="true" color="primary" ngDefaultControl>
              <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="row clearfix" *ngIf="sensitiveArray.cold == true" @ngIfAnimation>
          <div class="animate clearfix">
            <div class="row clearfix text-center">
              <h3 class="white animate">How bad is your sensitivity?</h3>
            </div>
            <div class="row clearfix">
              <p class="small white mobPullLeft">Mild</p>
              <p class="small white mobPullRight">Severe</p>
            </div>
            <div class="row clearfix column">
              <mat-slider
                class="slider-lrg full-width"
                [(ngModel)]="medicalHistory.sensitiveTeethWithCold"
                min="1"
                max="5"
                step="1"
                tickInterval="1"
              ></mat-slider>
            </div>
            <div class="row clearfix text-center">
              <p
                class="white bold animate"
                *ngIf="medicalHistory.sensitiveTeethWithCold == 0 || medicalHistory.sensitiveTeethWithCold == null"
                @ngIfAnimation
              >
                Please select
              </p>
              <p class="white bold animate" *ngIf="medicalHistory.sensitiveTeethWithCold == 1" @ngIfAnimation>Mild</p>
              <p class="white bold animate" *ngIf="medicalHistory.sensitiveTeethWithCold == 2" @ngIfAnimation>
                Moderate
              </p>
              <p class="white bold animate" *ngIf="medicalHistory.sensitiveTeethWithCold == 3" @ngIfAnimation>Bad</p>
              <p class="white bold animate" *ngIf="medicalHistory.sensitiveTeethWithCold == 4" @ngIfAnimation>
                Quite bad
              </p>
              <p class="white bold animate" *ngIf="medicalHistory.sensitiveTeethWithCold == 5" @ngIfAnimation>Severe</p>
            </div>
          </div>
        </div>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            [disabled]="sensitiveArray.cold == null"
            (click)="updateHealthHistoryNotComplete('step')"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step
      class=""
      [navigationSymbol]="{ symbol: '19' }"
      (stepEnter)="stepEntered($event); setStepCounter(20)"
      awOptionalStep
      title="Sensitivity"
    >
      <div class="container animated faster {{ enterslide }}">
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt">Are any of your teeth sensitive to chewing normally?</h3>
        </div>

        <div class="image-area row clearfix sensitive">
          <img
            class="big p2 animated zoomIn delay-04s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/sens-chew-2.png"
          />
          <img
            class="big p1 animated bounceInLeft"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/sens-chew-1.png"
          />
        </div>

        <div class="row clearfix animate text-center">
          <mat-button-toggle-group class="divided-toggle animate" [(ngModel)]="sensitiveArray.chew">
            <mat-button-toggle
              class="btn-large btn-icon no"
              [value]="false"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="accent"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-down"></mat-icon>
              No
            </mat-button-toggle>
            <mat-button-toggle class="btn-large btn-icon yes" [value]="true" color="primary" ngDefaultControl>
              <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="row clearfix" *ngIf="sensitiveArray.chew == true" @ngIfAnimation>
          <div class="animate clearfix">
            <div class="row clearfix text-center">
              <h3 class="white animate">How bad is your sensitivity?</h3>
            </div>
            <div class="row clearfix">
              <p class="small white mobPullLeft">Mild</p>
              <p class="small white mobPullRight">Severe</p>
            </div>
            <div class="row clearfix column">
              <mat-slider
                class="slider-lrg full-width"
                [(ngModel)]="medicalHistory.sensitiveTeethWithChew"
                min="1"
                max="5"
                step="1"
                tickInterval="1"
              ></mat-slider>
            </div>
            <div class="row clearfix text-center">
              <p
                class="white bold animate"
                *ngIf="medicalHistory.sensitiveTeethWithChew == 0 || medicalHistory.sensitiveTeethWithChew == null"
                @ngIfAnimation
              >
                Please select
              </p>
              <p class="white bold animate" *ngIf="medicalHistory.sensitiveTeethWithChew == 1" @ngIfAnimation>Mild</p>
              <p class="white bold animate" *ngIf="medicalHistory.sensitiveTeethWithChew == 2" @ngIfAnimation>
                Moderate
              </p>
              <p class="white bold animate" *ngIf="medicalHistory.sensitiveTeethWithChew == 3" @ngIfAnimation>Bad</p>
              <p class="white bold animate" *ngIf="medicalHistory.sensitiveTeethWithChew == 4" @ngIfAnimation>
                Quite bad
              </p>
              <p class="white bold animate" *ngIf="medicalHistory.sensitiveTeethWithChew == 5" @ngIfAnimation>Severe</p>
            </div>
          </div>
        </div>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            [disabled]="sensitiveArray.chew == null"
            (click)="updateHealthHistoryNotComplete('step')"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step
      class=""
      [navigationSymbol]="{ symbol: '20' }"
      (stepEnter)="stepEntered($event); setStepCounter(25)"
      awOptionalStep
      title="Sensitivity"
    >
      <div class="container animated faster {{ enterslide }}">
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt">Are any of your teeth sensitive to sweet foods?</h3>
        </div>

        <div class="image-area row clearfix sensitive">
          <img
            class="big p1 animated bounceInLeft"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/sens-sweet.png"
          />
        </div>

        <div class="row clearfix animate text-center">
          <mat-button-toggle-group class="divided-toggle animate" [(ngModel)]="sensitiveArray.sweet">
            <mat-button-toggle
              class="btn-large btn-icon no"
              [value]="false"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="accent"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-down"></mat-icon>
              No
            </mat-button-toggle>
            <mat-button-toggle class="btn-large btn-icon yes" [value]="true" color="primary" ngDefaultControl>
              <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="row clearfix" *ngIf="sensitiveArray.sweet == true" @ngIfAnimation>
          <div class="animate clearfix">
            <div class="row clearfix text-center">
              <h3 class="white animate">How bad is your sensitivity?</h3>
            </div>
            <div class="row clearfix">
              <p class="small white mobPullLeft">Mild</p>
              <p class="small white mobPullRight">Severe</p>
            </div>
            <div class="row clearfix column">
              <mat-slider
                class="slider-lrg full-width"
                [(ngModel)]="medicalHistory.sensitiveTeethWithSweet"
                min="1"
                max="5"
                step="1"
                tickInterval="1"
              ></mat-slider>
            </div>
            <div class="row clearfix text-center">
              <p
                class="white bold animate"
                *ngIf="medicalHistory.sensitiveTeethWithSweet == 0 || medicalHistory.sensitiveTeethWithSweet == null"
                @ngIfAnimation
              >
                Please select
              </p>
              <p class="white bold animate" *ngIf="medicalHistory.sensitiveTeethWithSweet == 1" @ngIfAnimation>Mild</p>
              <p class="white bold animate" *ngIf="medicalHistory.sensitiveTeethWithSweet == 2" @ngIfAnimation>
                Moderate
              </p>
              <p class="white bold animate" *ngIf="medicalHistory.sensitiveTeethWithSweet == 3" @ngIfAnimation>Bad</p>
              <p class="white bold animate" *ngIf="medicalHistory.sensitiveTeethWithSweet == 4" @ngIfAnimation>
                Quite bad
              </p>
              <p class="white bold animate" *ngIf="medicalHistory.sensitiveTeethWithSweet == 5" @ngIfAnimation>
                Severe
              </p>
            </div>
          </div>
        </div>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            [disabled]="sensitiveArray.sweet == null"
            (click)="updateHealthHistoryNotComplete('step')"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <!-- / sensitivity -->

    <aw-wizard-step
      [navigationSymbol]="{ symbol: '21' }"
      (stepEnter)="stepEntered($event); setStepCounter(30)"
      awOptionalStep
      title="Food stuck in your teeth"
    >
      <div class="container  animated faster {{ enterslide }}">
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt mobile">Does food tend to get caught in your teeth?</h3>
        </div>

        <div class="image-area row clearfix">
          <img
            class="center-1 sml animated bounceInDown delay-02s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/food-1.png"
          />
          <img
            class="center-2 sml animated bounceInDown delay-05s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/food-2.png"
          />
          <img
            class="big fast animated bottom fadeInUp"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/teeth-sad-1.png"
          />
        </div>

        <div class="row clearfix animate">
          <mat-button-toggle-group class="divided-toggle" [(ngModel)]="medicalHistory.foodCaughtInTeeth">
            <mat-button-toggle
              class="btn-large btn-icon no"
              [value]="false"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="accent"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-down"></mat-icon>
              No
            </mat-button-toggle>
            <mat-button-toggle
              class="btn-large btn-icon yes"
              [value]="true"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="primary"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            [disabled]="medicalHistory.foodCaughtInTeeth == null"
            (click)="updateHealthHistoryNotComplete('step')"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step
      [navigationSymbol]="{ symbol: '22' }"
      (stepEnter)="stepEntered($event); setStepCounter(35)"
      awOptionalStep
      title="Sore gums"
    >
      <div class="container  animated faster {{ enterslide }}">
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt">Do your gums bleed or hurt when you clean your teeth?</h3>
        </div>

        <div class="image-area row clearfix soregum">
          <img
            class="animated offscreenRight bounceInRight delay-05s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/toothbrush.png"
          />
          <img
            class="big fast animated bottom fadeInUp"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/teeth-sad-2.png"
          />
        </div>

        <div class="row clearfix animate">
          <mat-button-toggle-group class="divided-toggle" [(ngModel)]="medicalHistory.gumsBleed">
            <mat-button-toggle
              class="btn-large btn-icon no"
              [value]="false"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="accent"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-down"></mat-icon>
              No
            </mat-button-toggle>
            <mat-button-toggle
              class="btn-large btn-icon yes"
              [value]="true"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="primary"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            [disabled]="medicalHistory.gumsBleed == null"
            (click)="updateHealthHistoryNotComplete('step')"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step
      [navigationSymbol]="{ symbol: '23' }"
      (stepEnter)="stepEntered($event); setStepCounter(40)"
      awOptionalStep
      title="Breath"
    >
      <div class="container  animated faster {{ enterslide }}">
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt">Do you feel you suffer from bad breath?</h3>
        </div>

        <div class="image-area row clearfix">
          <img
            class="big over left animated delay-02s bounceInLeft"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/smell-1.png"
          />
          <img
            class="big over right animated delay-05s bounceInRight"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/smell-2.png"
          />
          <img
            class="big fast animated bottom fadeInUp"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/teeth-sad-1.png"
          />
        </div>

        <div class="row clearfix animate">
          <mat-button-toggle-group class="divided-toggle" [(ngModel)]="isHasBadBreath">
            <mat-button-toggle
              class="btn-large btn-icon no"
              [value]="false"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="accent"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-down"></mat-icon>
              No
            </mat-button-toggle>
            <mat-button-toggle
              class="btn-large btn-icon yes"
              [value]="true"
              (click)="medicalHistory.hasBadBreath = '1'"
              color="primary"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="row clearfix mt" *ngIf="isHasBadBreath == true" @ngIfAnimation>
          <div class="animate clearfix">
            <div class="row clearfix text-center">
              <h3 class="white animate">How bad is it?</h3>
            </div>
            <div class="row clearfix">
              <p class="small white mobPullLeft">Not that bad</p>
              <p class="small white mobPullRight">Birds fall from the sky</p>
            </div>
            <div class="row clearfix column">
              <mat-slider
                class="slider-lrg full-width"
                [(ngModel)]="medicalHistory.hasBadBreath"
                min="1"
                max="5"
                step="1"
                tickInterval="1"
              ></mat-slider>
            </div>
          </div>
        </div>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            [disabled]="medicalHistory.hasBadBreath == null"
            (click)="updateHealthHistoryNotComplete('step')"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step
      [navigationSymbol]="{ symbol: '24' }"
      (stepEnter)="stepEntered($event); setStepCounter(45)"
      awOptionalStep
      title="Clicking jaw"
    >
      <div class="container  animated faster {{ enterslide }}">
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt">Do you have a clicking/locking jaw when you chew or talk?</h3>
        </div>

        <div class="image-area row clearfix clicking">
          <img
            class="sml fast left animated fadeInRight delay-05s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/pain-zing.png"
          />
          <img
            class="big animated center bounceInUp"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/click-jaw.png"
          />
        </div>

        <div class="row clearfix animate">
          <mat-button-toggle-group class="divided-toggle" [(ngModel)]="medicalHistory.hasClickingLockingJaw">
            <mat-button-toggle
              class="btn-large btn-icon no"
              [value]="false"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="accent"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-down"></mat-icon>
              No
            </mat-button-toggle>
            <mat-button-toggle
              class="btn-large btn-icon yes"
              [value]="true"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="primary"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            [disabled]="medicalHistory.hasClickingLockingJaw == null"
            (click)="updateHealthHistoryNotComplete('step')"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step
      [navigationSymbol]="{ symbol: '25' }"
      (stepEnter)="stepEntered($event); setStepCounter(50)"
      awOptionalStep
      title="Clenching / Grinding"
    >
      <div class="container  animated faster {{ enterslide }}">
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt">Do you clench or grind your teeth while you sleep?</h3>
        </div>

        <div class="image-area row clearfix grind">
          <img
            class="sml fast left animated fadeInRight delay-05s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/pain-zing.png"
          />
          <img
            class="big animated top bounceInLeft"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/grind-top.png"
          />
          <img
            class="big animated bottom bounceInRight"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/grind-bottom.png"
          />
        </div>

        <div class="row clearfix animate">
          <mat-button-toggle-group class="divided-toggle" [(ngModel)]="medicalHistory.doesClenchOrGrind">
            <mat-button-toggle
              class="btn-large btn-icon no"
              [value]="false"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="accent"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-down"></mat-icon>
              No
            </mat-button-toggle>
            <mat-button-toggle
              class="btn-large btn-icon yes"
              [value]="true"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="primary"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            [disabled]="medicalHistory.doesClenchOrGrind == null"
            (click)="updateHealthHistoryNotComplete('step')"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step
      [navigationSymbol]="{ symbol: '26' }"
      (stepEnter)="stepEntered($event); setStepCounter(55)"
      awOptionalStep
      title="Facial pain"
    >
      <div class="container  animated faster {{ enterslide }}">
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt">Do you get headaches, jaw pain or facial pain?</h3>
        </div>

        <div class="image-area row clearfix headache">
          <img
            class="sml fast left animated fadeInRight delay-04s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/pain-zing.png"
          />
          <img
            class="sml fast right animated fadeInLeft delay-06s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/pain-zing-2.png"
          />
          <img
            class="big animated center bounceInUp"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/headache-1.png"
          />
        </div>

        <div class="row clearfix animate">
          <mat-button-toggle-group class="divided-toggle" [(ngModel)]="medicalHistory.hasHeadacheOrJawOrFacialPain">
            <mat-button-toggle
              class="btn-large btn-icon no"
              [value]="false"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="accent"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-down"></mat-icon>
              No
            </mat-button-toggle>
            <mat-button-toggle
              class="btn-large btn-icon yes"
              [value]="true"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="primary"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            [disabled]="medicalHistory.hasHeadacheOrJawOrFacialPain == null"
            (click)="updateHealthHistoryNotComplete('step')"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step
      [navigationSymbol]="{ symbol: '27' }"
      (stepEnter)="stepEntered($event); setStepCounter(60)"
      awOptionalStep
      title="Sore muscles"
    >
      <div class="container  animated faster {{ enterslide }}">
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt mobile">Are you experiencing sore muscles of the jaw, neck or shoulders?</h3>
        </div>
        <div class="image-area row clearfix muscle">
          <img
            class="sml fast right animated fadeInRight delay-05s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/pain-zing-2.png"
          />
          <img
            class="big animated center bounceInUp"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/pain-shoulder.png"
          />
        </div>

        <div class="row clearfix animate">
          <mat-button-toggle-group class="divided-toggle" [(ngModel)]="medicalHistory.hasSoreMuscles">
            <mat-button-toggle
              class="btn-large btn-icon no"
              [value]="false"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="accent"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-down"></mat-icon>
              No
            </mat-button-toggle>
            <mat-button-toggle
              class="btn-large btn-icon yes"
              [value]="true"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="primary"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            [disabled]="medicalHistory.hasSoreMuscles == null"
            (click)="updateHealthHistoryNotComplete('step')"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step
      [navigationSymbol]="{ symbol: '28' }"
      (stepEnter)="stepEntered($event); setStepCounter(65)"
      awOptionalStep
      title="Splints"
    >
      <div class="container  animated faster {{ enterslide }}">
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt">Do you wear a splint or bite plate while you sleep?</h3>
        </div>

        <div class="image-area row clearfix splint">
          <img
            class="big animated left bounceInLeft"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/splint.png"
          />
          <img
            class="big animated right bounceInRight delay-01s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/retainer.png"
          />
        </div>

        <div class="row clearfix animate">
          <mat-button-toggle-group class="divided-toggle" [(ngModel)]="medicalHistory.wearsNightPlate">
            <mat-button-toggle
              class="btn-large btn-icon no"
              [value]="false"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="accent"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-down"></mat-icon>
              No
            </mat-button-toggle>
            <mat-button-toggle
              class="btn-large btn-icon yes"
              [value]="true"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="primary"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            [disabled]="medicalHistory.wearsNightPlate == null"
            (click)="updateHealthHistoryNotComplete('step')"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step
      [navigationSymbol]="{ symbol: '29' }"
      (stepEnter)="stepEntered($event); setStepCounter(70)"
      awOptionalStep
      title="Bite lips or cheeks"
    >
      <div class="container  animated faster {{ enterslide }}">
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt">Do you bite your lips or cheeks often?</h3>
        </div>

        <div class="image-area row clearfix bite-chk">
          <img
            class="sml fast right animated jackInTheBox delay-04s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/pain-zing-2.png"
          />
          <img
            class="big animated center bounceInUp"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/bite-cheek.png"
          />
        </div>

        <div class="row clearfix animate">
          <mat-button-toggle-group class="divided-toggle" [(ngModel)]="medicalHistory.doesBiteCheeks">
            <mat-button-toggle
              class="btn-large btn-icon no"
              [value]="false"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="accent"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-down"></mat-icon>
              No
            </mat-button-toggle>
            <mat-button-toggle
              class="btn-large btn-icon yes"
              [value]="true"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="primary"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            [disabled]="medicalHistory.doesBiteCheeks == null"
            (click)="updateHealthHistoryNotComplete('step')"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step
      [navigationSymbol]="{ symbol: '30' }"
      (stepEnter)="stepEntered($event); setStepCounter(75)"
      awOptionalStep
      title="Sore teeth"
    >
      <div class="container  animated faster {{ enterslide }}">
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt">Do your teeth hurt when you bite hard?</h3>
        </div>

        <div class="image-area row clearfix clicking">
          <img
            class="sml fast left animated bounceInLeft delay-02s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/pain-zing.png"
          />
          <img
            class="big animated center bounceInUp"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/bite-pain.png"
          />
        </div>

        <div class="row clearfix animate">
          <mat-button-toggle-group class="divided-toggle" [(ngModel)]="medicalHistory.hurtsWhenBiteHard">
            <mat-button-toggle
              class="btn-large btn-icon no"
              [value]="false"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="accent"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-down"></mat-icon>
              No
            </mat-button-toggle>
            <mat-button-toggle
              class="btn-large btn-icon yes"
              [value]="true"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="primary"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            [disabled]="medicalHistory.hurtsWhenBiteHard == null"
            (click)="updateHealthHistoryNotComplete('step')"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step
      [navigationSymbol]="{ symbol: '31' }"
      (stepEnter)="stepEntered($event); setStepCounter(80)"
      awOptionalStep
      title="Periodontics"
    >
      <div class="container  animated faster {{ enterslide }}">
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt">Have you ever had {{ "KEYWORD.treatment" | translate }} for gum disease?</h3>
        </div>

        <div class="image-area row clearfix">
          <img
            class="big pick animated bounceInRight delay-03s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/pick.png"
          />
          <img
            class="big bottom animated bounceInLeft"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/periodont-1.png"
          />
        </div>

        <div class="row clearfix animate">
          <mat-button-toggle-group class="divided-toggle" [(ngModel)]="medicalHistory.hasPeridontalTreatment">
            <mat-button-toggle
              class="btn-large btn-icon no"
              [value]="false"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="accent"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-down"></mat-icon>
              No
            </mat-button-toggle>
            <mat-button-toggle
              class="btn-large btn-icon yes"
              [value]="true"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="primary"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            [disabled]="medicalHistory.hasPeridontalTreatment == null"
            (click)="updateHealthHistoryNotComplete('step')"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step
      [navigationSymbol]="{ symbol: '32' }"
      (stepEnter)="stepEntered($event); setStepCounter(85)"
      awOptionalStep
      title="Bite adjustment"
    >
      <div class="container  animated faster {{ enterslide }}">
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt">Have you ever had your bite adjusted?</h3>
        </div>

        <div class="image-area row clearfix braces">
          <img
            class="med animated bounceInRight delay-02s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/tooth-braces.png"
          />
          <img
            class="med animated bounceInRight delay-04s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/tooth-braces.png"
          />
          <img
            class="med animated bounceInRight delay-05s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/tooth-braces.png"
          />
          <img
            class="brace animated bounceInRight"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/brace.png"
          />
        </div>

        <div class="row clearfix animate">
          <mat-button-toggle-group class="divided-toggle" [(ngModel)]="medicalHistory.hasBiteAdjusted">
            <mat-button-toggle
              class="btn-large btn-icon no"
              [value]="false"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="accent"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-down"></mat-icon>
              No
            </mat-button-toggle>
            <mat-button-toggle
              class="btn-large btn-icon yes"
              [value]="true"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="primary"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            [disabled]="medicalHistory.hasBiteAdjusted == null"
            (click)="updateHealthHistoryNotComplete('step')"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step
      [navigationSymbol]="{ symbol: '33' }"
      (stepEnter)="stepEntered($event); setStepCounter(90)"
      awOptionalStep
      title="Teeth discolouration"
    >
      <div class="container  animated faster {{ enterslide }}">
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt">
            When you smile do you have any concerns about the appearance of your teeth (tooth position, colour etc)?
          </h3>
        </div>

        <div class="image-area row clearfix colour">
          <img
            class="big animated bottom bounceInLeft"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/coloured-teeth.png"
          />
        </div>

        <div class="row clearfix animate">
          <mat-button-toggle-group class="divided-toggle" [(ngModel)]="isConsciousOfColourAppearance">
            <mat-button-toggle
              class="btn-large btn-icon no"
              [value]="false"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="accent"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-down"></mat-icon>
              No
            </mat-button-toggle>
            <mat-button-toggle
              class="btn-large btn-icon yes"
              [value]="true"
              (click)="medicalHistory.consciousOfColourAppearance = '1'"
              color="primary"
              ngDefaultControl
            >
              <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="row clearfix mt" *ngIf="isConsciousOfColourAppearance == true" @ngIfAnimation>
          <div class="animate clearfix">
            <div class="row clearfix text-center">
              <h3 class="white animate">How concerned are you?</h3>
            </div>
            <div class="row clearfix">
              <p class="small white mobPullLeft">Not that concerned</p>
              <p class="small white mobPullRight">Very concerned</p>
            </div>
            <div class="row clearfix column">
              <mat-slider
                class="slider-lrg full-width"
                [(ngModel)]="medicalHistory.consciousOfColourAppearance"
                min="1"
                max="5"
                step="1"
                tickInterval="1"
              ></mat-slider>
            </div>
          </div>
        </div>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            [disabled]="medicalHistory.consciousOfColourAppearance == null"
            (click)="updateHealthHistoryNotComplete('step')"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step
      [navigationSymbol]="{ symbol: '34' }"
      (stepEnter)="stepEntered($event); setStepCounter(90); triggerAnim(false)"
      awOptionalStep
      title="Fear of treatment"
    >
      <div class="container  animated faster {{ enterslide }}">
        <div class="row clearfix text-center">
          <h3 class="white animate rm-mt mobile">
            On a scale of 1-5 (1 being totally relaxed and 5 being utterly terrified), how do you feel about dental
            treatment?
          </h3>
        </div>

        <div class="image-area row clearfix fear">
          <img
            class="big left animated bounceInLeft delay-01s"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/dont-panic-1.png"
          />
          <img
            class="big right animated bounceInRight"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dental-history/dont-panic-2.png"
          />
        </div>

        <div class="row clearfix text-center">
          <mat-button-toggle-group class="divided-toggle mobileFlow" [(ngModel)]="medicalHistory.terrifiedLevel">
            <mat-button-toggle
              class="btn-large btn-icon multiple primary-bg hig animate list-button-hoving"
              [value]="1"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="primary"
              ngDefaultControl
            >
              <mat-icon class="fas fa-grin-beam"></mat-icon>
              <span class="numberLabel"> 1</span>
            </mat-button-toggle>
            <mat-button-toggle
              class="btn-large btn-icon multiple primary-bg hig animate list-button-hoving"
              [value]="2"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="accent"
              ngDefaultControl
            >
              <mat-icon class="fas fa-smile"></mat-icon>
              <span class="numberLabel"> 2</span>
            </mat-button-toggle>
            <mat-button-toggle
              class="btn-large btn-icon multiple primary-bg med animate list-button-hoving"
              [value]="3"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="primary"
              ngDefaultControl
            >
              <mat-icon class="fas fa-meh"></mat-icon>
              <span class="numberLabel"> 3</span>
            </mat-button-toggle>
            <mat-button-toggle
              class="btn-large btn-icon multiple primary-bg low animate list-button-hoving"
              [value]="4"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="primary"
              ngDefaultControl
            >
              <mat-icon class="fas fa-sad-tear"></mat-icon>
              <span class="numberLabel"> 4</span>
            </mat-button-toggle>
            <mat-button-toggle
              class="btn-large btn-icon multiple primary-bg low animate list-button-hoving"
              [value]="5"
              (click)="updateHealthHistoryNotComplete('forward')"
              color="primary"
              ngDefaultControl
            >
              <mat-icon class="fas fa-sad-cry"></mat-icon>
              <span class="numberLabel"> 5</span>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
          <button
            [disabled]="medicalHistory.terrifiedLevel == null"
            (click)="updateHealthHistoryNotComplete('step')"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step
      [navigationSymbol]="{ symbol: '35' }"
      (stepEnter)="stepEntered($event); setStepCounter(100); triggerAnim(true)"
      awOptionalStep
      title="Review"
    >
      <div class="container  animated faster {{ enterslide }}">
        <div class="row clearfix text-center">
          <h1 class="white animate">That's the hard part done!</h1>
        </div>

        <div class="row clearfix text-center">
          <like-animation class="animated infinite heartBeat complete" [type]="'heart'" [anim]="anim"></like-animation>
        </div>

        <div class="row clearfix text-center">
          <p class="white">
            You have answered all the questions so all that is left to do is just press submit to send it through to
            your {{ "KEYWORD.practitioner" | translate | titlecase }}!
          </p>
          <p class="white">Press submit to finalise.</p>
        </div>

        <div class="row clearfix animate">
          <button
            class="btn-large full-width add-mb btn-submit btn-icon"
            (click)="openContentDialogComplete()"
            mat-raised-button
            color="accent"
          >
            <mat-icon class="fas fa-thumbs-up"></mat-icon>
            Submit!
          </button>
        </div>

        <div class="row clearfix text-center animate flex navi step-navigation">
          <button class="" (click)="selectedNextStepBackward()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-completion-step
      [navigationSymbol]="{ symbol: '36' }"
      (stepEnter)="stepEntered($event); triggerAnim(false)"
      title="complete!"
    >
      <div class="container animated faster {{ enterslide }}">
        <mat-card class="row clearfix animated bounceInRight text-center">
          <h1 class="white animate rm-mt">
            <img class="character_clipboard_thumbsup"/>
            Thanks for updating your health history!
          </h1>
          <div style="padding-top: 10px">
            <p>
              We've securely passed your information through to
              your {{ "KEYWORD.practitioner" | translate | titlecase }} at
              {{ merchant['TradingAs'] }}.
            </p>
            <p>If you have any questions, please contact us.</p>
          </div>

          <div class="row clearfix flex step-navigation add-mt">
            <button class="full-width pull-left" (click)="backToPatientCard()" mat-raised-button color="primary">
              <mat-icon>chevron_left</mat-icon>
              <span *ngIf="createdObject && createdObject.ID"> View Health history</span>

              <span *ngIf="!createdObject"> Back to Health history</span>
            </button>

            <button
              class="full-width pull-right"
              *ngIf="medicalInvitation && medicalInvitation['DentistContact_key']"
              (click)="contactDentist(medicalInvitation['DentistContact_key'])"
              mat-raised-button
              color="primary"
            >
              <mat-icon>email</mat-icon>
              Contact us
            </button>
          </div>
        </mat-card>
      </div>
    </aw-wizard-completion-step>
  </aw-wizard>
</div>

<div
  class="row clearfix flex flex1 medical-history wht"
  *ngIf="medicalInvitation && (medicalInvitation.isValid == false || medicalInvitation.canProceed == false)"
>
  <app-thank-you
    class="thankYou clearFix"
    *ngIf="
      medicalInvitation && medicalInvitation.status && medicalInvitation.status == 'completed' && merchant && dentist
    "
    [typeOfCard]="'thankyou'"
    [firstName]="medicalInvitation.FirstName"
    [merchantID]="merchant.ID"
    [dentistID]="dentist.ID"
    [merchantPhone]="merchant['phones.Number']"
    [merchantEmail]="merchant['emails.Email']"
    [merchantName]="merchant['TradingAs']"
    [dentistPhone]="dentist['phones.Number']"
    [subjectRequestInvitation]="subjectRequestInvitation"
    [dentistEmail]="dentist['CalculatedName']"
    [dentistName]="dentist['emails.Email']"
    [displayViewTreatment]="false"
  >
  </app-thank-you>

  <app-thank-you
    class="thankYou clearFix"
    *ngIf="
      medicalInvitation && medicalInvitation.status && medicalInvitation.status == 'expired' && merchant && dentist
    "
    [typeOfCard]="'expiredRemoved'"
    [firstName]="medicalInvitation.FirstName"
    [merchantID]="merchant.ID"
    [dentistID]="dentist.ID"
    [merchantPhone]="merchant['phones.Number']"
    [merchantEmail]="merchant['emails.Email']"
    [merchantName]="merchant['TradingAs']"
    [dentistPhone]="dentist['phones.Number']"
    [dentistEmail]="dentist['CalculatedName']"
    [dentistName]="dentist['emails.Email']"
    [subjectRequestInvitation]="subjectRequestInvitation"
    [title]="'Your invitation from your ' + ('KEYWORD.dentist' | translate) + ' looks to have expired.'"
    [displayViewTreatment]="false"
  >
  </app-thank-you>

  <app-thank-you
    class="thankYou clearFix"
    *ngIf="medicalInvitation && medicalInvitation.status && medicalInvitation.status == 'voided' && merchant && dentist"
    [typeOfCard]="'expiredRemoved'"
    [firstName]="medicalInvitation.FirstName"
    [merchantID]="merchant.ID"
    [dentistID]="dentist.ID"
    [merchantPhone]="merchant['phones.Number']"
    [merchantEmail]="merchant['emails.Email']"
    [merchantName]="merchant['TradingAs']"
    [dentistPhone]="dentist['phones.Number']"
    [dentistEmail]="dentist['CalculatedName']"
    [dentistName]="dentist['emails.Email']"
    [subjectRequestInvitation]="subjectRequestInvitation"
    [title]="'Your invitation from your ' + ('KEYWORD.dentist' | translate) + ' looks to have expired.'"
    [displayViewTreatment]="false"
  >
  </app-thank-you>

  <app-thank-you
    class="thankYou clearFix"
    *ngIf="medicalInvitation && medicalInvitation.status && medicalInvitation.status == 'notExist'"
    [typeOfCard]="'expiredRemoved'"
    [firstName]="medicalInvitation.FirstName"
    [merchantID]="merchant.ID"
    [dentistID]="dentist.ID"
    [merchantPhone]="merchant['phones.Number']"
    [merchantEmail]="merchant['emails.Email']"
    [merchantName]="merchant['TradingAs']"
    [dentistPhone]="dentist['phones.Number']"
    [dentistEmail]="dentist['CalculatedName']"
    [dentistName]="dentist['emails.Email']"
    [subjectRequestInvitation]="subjectRequestInvitation"
    [title]="'The invitation Does not exist.'"
    [displayViewTreatment]="false"
  >
  </app-thank-you>
</div>

<div class="row clearfix full-width help-footer mt">
  <div class="container">
    <!-- <h4 class="sr-title">Need some help?</h4> -->
    <div class="row clearfix text-center">
      <button (click)="contactPractice('I need help to complete this form')" mat-button color="primary">
        <mat-icon>help</mat-icon> Need help completing this form?
      </button>
      <button (click)="contactPractice('I need to speak a staff member in private')" mat-button color="primary">
        <mat-icon>sentiment_satisfied</mat-icon> Need to speak in private to a staff member?
      </button>
    </div>
  </div>
</div>
