<aw-wizard #wizard navBarLayout="large-filled-symbols">
  <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Define campaign">
    <div class="container">
      <div class="clearfix row gridView" *ngIf="isPromoterOrAdmin == true">
        <div class="row clearfix selectMerchant">
          <mat-form-field class="mb full-width" appearance="outline">
            <mat-label>Please Select a Merchant</mat-label>
            <mat-select
              class=""
              [(ngModel)]="merchantID"
              (change)="changeMerchant()"
              name="MerchantName"
              placeholder=""
            >
              <mat-option [value]="null"> My Account</mat-option>
              <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m['ID']">
                <span
                  class="list-label pull-right"
                  *ngIf="m['Status'] && m['Status'] == 'Active'"
                  [style.background]="'#00d07a'"
                >
                  {{ m['Status'] }}
                </span>
                <span
                  class="list-label pull-right"
                  *ngIf="m['Status'] && m['Status'] != 'Active'"
                  [style.background]="'#ff5722'"
                >
                  {{ m['Status'] }}
                </span>
                {{ m['TradingAs'] || m['CalculatedName'] }}
              </mat-option>
            </mat-select>
            <mat-hint
            >To send an invitation on behalf of a merchant select a
              merchant below, otherwise, the invitation will be sent by the promoter.
            </mat-hint>
          </mat-form-field>
          <button
            class="pull-right merchBtn"
            *ngIf="merchantID && merchantID != 'none'"
            (click)="viewMerchant()"
            mat-raised-button
            color="primary"
          >
            <mat-icon>person</mat-icon>
            <span class="mobHide">View Merchant</span>
          </button>
        </div>
      </div>
      <div class="row full-width clearfix">
        <app-media-gallery-templates-list
          class="main-sm-template full-width"
          [isDefault]="isDefault"
          [isManualCreated]="isManualCreated"
          [isManualCreatedOrDefault]="isManualCreatedOrDefault"
          [icon]="icon"
          [displayHeader]="true"
          [isForMerchant]="false"
          [isBusiness]="false"
          [isBusinessOrDefault]="true"
          [isCampaign]="isCampaign"
          [isMerchantTemplate]="false"
          [isActive]="true"
          [isDownload]="false"
          [isProceed]="false"
          [isPrint]="false"
          [title]="title"
          [description]="description"
          [merchantID]="merchantID"
          [isPatientCampaign]="true"
          (selectTemplate)="selectTemplateEvent($event)"
        >
        </app-media-gallery-templates-list>
      </div>
      <div class="full-width clearfix">
        <div class="row clearfix mt">
          <div class="flex sp-between add-mt gridView">
            <button
              class=""
              [routerLink]="['/merchant/', { outlets: { page: ['marketing-over-view'] } }]"
              mat-raised-button
              color="primary"
              type="button"
            >
              <mat-icon>chevron_left</mat-icon>
              <span>Return to Marketing Overview </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </aw-wizard-step>
  <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Campaign Details">
    <form #createForm="ngForm" name="form">
      <mat-card class="rel supplier-card brandView mt clearfix mb container create-container" @ngIfAnimation>
        <div class="card-header primary-gradient-img clearfix inModal rel">
          <div class="row clearfix content">
            <div class="mr pull-left flex social-media-icon">
              <div class="full-width icon-social-media text-center">
                <mat-icon class="fas fa-hashtag big-icon theme-color-second"></mat-icon>
              </div>
            </div>
            <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModal()">
              <mat-icon>clear</mat-icon>
            </button>
            <div class="row titleArea clearfix">
              <h2 class="summary-header rm-m">
                New social media campaign
                <span class="subLabel"
                >We've prepared everything you need to create social media posts in simple steps.
                </span>
              </h2>
            </div>
          </div>
        </div>
        <div class="full-width clearfix">
          <div class="full-width clearfix mb" *ngIf="selectedTemplate">
            <button
              class="inline mt btn-new-invite selectExistingPatient pull-right"
              (click)="previewTemplateModal()"
              mat-raised-button
              color="accent"
            >
              Preview Template
            </button>
            <div class="row clearfix text-center flex">
              <app-ep-document-view-image
                class="pull-left mr display-image-inner"
                [defaultPicture]="
                  'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/marketing-place-holder.png)'
                "
                [link]="getImageLink(selectedTemplateID, selectedTemplate['LastModified'])"
              >
              </app-ep-document-view-image>
              <h2 class="lg-h2 sr-title ml pull-left">
                New campagin for : <strong>{{ selectedTemplate['Label'] }}</strong>
              </h2>
            </div>
          </div>
          <hr/>

          <div class="row clearfix flex">
            <!-- <div class="half-width"> -->
            <mat-card class="clearfix high create-container no-box-card">
              <div class="row clearfix flex">
                <!-- <div class="half-width"> -->
                <div class="clearfix high create-container full-width">
                  <div class="row clearfix">
                    <h2 class="sr-title sm-h2">
                      <mat-icon class="mr fas fa-envelope"></mat-icon>
                      Campaign Details
                    </h2>
                  </div>
                  <div class="row clearfix">
                    <div class="row clearfix flex campaignDetail">
                      <div class="full-width clearfix mr">
                        <div class="row clearfix">
                          <h4 class="primary-color rm-m sm-mb">Label (for your own record)</h4>
                        </div>

                        <mat-form-field class="full-width" appearance="outline">
                          <input
                            [(ngModel)]="campaignName"
                            name="campaignName"
                            matInput
                            placeholder="Label (for your own record)."
                            required
                          />
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="row clearfix flex campaignDetail">
                      <div class="full-width clearfix mr">
                        <div class="row clearfix">
                          <h4 class="primary-color rm-m sm-mb">Call to Action (CTA)</h4>
                        </div>

                        <mat-form-field class="full-width" appearance="outline">
                          <mat-label>The CTA captures attention on your landing pages.</mat-label>

                          <input
                            [(ngModel)]="actionCallLabel"
                            name="actionCallLabel"
                            matInput
                            placeholder="The CTA captures attention on your landing pages."
                            required
                          />
                        </mat-form-field>
                      </div>
                      <div
                        class="full-width clearfix"
                        *ngIf="
                          selectedTemplate && selectedTemplate['MetaData'] && selectedTemplate['MetaData']['Amount']
                        "
                      >
                        <div class="row clearfix">
                          <h4 class="primary-color rm-m sm-mb">Sender</h4>
                        </div>

                        <mat-form-field class="full-width" appearance="outline">
                          <mat-select
                            class=""
                            [(ngModel)]="contactID"
                            name="selectedSender"
                            placeholder="Choose which member of the team is sending the campaign."
                            required
                          >
                            <mat-option *ngFor="let sender of contacts" [value]="sender.ID">
                              {{ sender.CalculatedName }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row clearfix flex campaignDetail">
                      <div class="full-width clearfix">
                        <div class="row clearfix">
                          <h4 class="primary-color rm-m sm-mb">Description/Preview</h4>
                        </div>

                        <mat-form-field class="full-width" appearance="outline">
                          <mat-label
                          >The description accompanies the CTA and provides a preview of the campaign offer.
                          </mat-label>

                          <textarea
                            [(ngModel)]="actionCallDescription"
                            [maxLength]="350"
                            name="actionCallDescription"
                            matInput
                            placeholder="The description accompanies the CTA and provides a preview of the campaign offer."
                            mdTextareaAutosize
                          ></textarea>

                          <mat-hint class="pull-right" align="end"
                          >Text length: {{ actionCallDescription?.length || 0 }}/ 350
                          </mat-hint>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix mt" *ngIf="selectedTemplate && selectedTemplate['MetaData']">
                    <app-metadata-input
                      [metadata]="selectedTemplate['MetaData']"
                      [marketingUserData]="userData"
                      (validate)="validateUserDataEvent($event)"
                      (getUserData)="getUserData($event)"
                    >
                    </app-metadata-input>
                  </div>

                  <div class="row clearfix mb">
                    <div class="row clearfix">
                      <h2 class="sr-title sm-h2">
                        <mat-icon class="mr fas fa-laptop"></mat-icon>
                        {{ "KEYWORD.practice" | translate | titlecase}} Page
                      </h2>
                    </div>

                    <section class="slideToggle">
                      <mat-slide-toggle [(ngModel)]="isPublicCampaign" name="isPublicCampaign"
                      >Extend the reach of the campaign by publishing on
                        your {{ "KEYWORD.practice" | translate | titlecase}} Page
                        and
                        {{ "KEYWORD.dental" | translate | titlecase }} Tips and Offers Page
                      </mat-slide-toggle>
                    </section>
                  </div>

                  <div class="row clearfix">
                    <div class="row clearfix">
                      <h2 class="sr-title sm-h2">
                        <mat-icon class="mr fas fa-calendar-alt"></mat-icon>
                        Time limit
                      </h2>
                    </div>
                    <section class="slideToggle">
                      <mat-slide-toggle [(ngModel)]="hasExpirationDate" name="consentPatient"
                      >If you wish to limit the duration of the offer, enter the expiry date here. Otherwise, the
                        offer will be ongoing.
                      </mat-slide-toggle>
                    </section>
                    <div class="full-width clearfix mt flex flexCenter" *ngIf="hasExpirationDate == true">
                      <div class="emailToggle">
                        <label style="display: inline; padding-right: 1rem">Expired at'</label>
                      </div>
                      <div class="full-width clearfix">
                        <app-date-input
                          class="thin emailDate"
                          [isTime]="true"
                          [label]="'Date:'"
                          [defaultDate]="defaultExpirationDate"
                          [minDate]="'toDay'"
                          (selectedDate)="selectedExpirationDate($event)"
                        >
                        </app-date-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </mat-card>
      <div class="create-container">
        <div class="row clearfix mt">
          <button
            class="pull-left"
            *ngIf="!campaignID"
            (click)="backToTemplate()"
            mat-raised-button
            color="primary"
            type="button"
          >
            <mat-icon>chevron_left</mat-icon>
            <span>Back</span>
          </button>
          <button
            class="pull-right"
            *ngIf="selectedTemplate && !validateMataData(selectedTemplate['MetaData'])"
            [disabled]="
              !createForm.form.valid || (validateUserData == false && selectedTemplate && selectedTemplate['MetaData'])
            "
            (click)="createCampaignPending()"
            mat-raised-button
            color="accent"
            type="button"
          >
            <span>Submit</span>
            <mat-icon>chevron_right</mat-icon>
          </button>
          <button
            class="pull-right"
            *ngIf="selectedTemplate && validateMataData(selectedTemplate['MetaData'])"
            [disabled]="!createForm.form.valid"
            (click)="createCampaignPending()"
            mat-raised-button
            color=" accent"
            type="button"
          >
            <span>Submit</span>
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </form>
  </aw-wizard-step>

  <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Share your Campaign">
    <div class="full-width clearfix" *ngIf="createdCampaign && createdCampaign.ID">
      <app-social-media-gallery-list class="full-width clearfix main-sm-template" [campaignID]="createdCampaign.ID">
      </app-social-media-gallery-list>

      <div class="full-width clearfix">
        <div class="row clearfix mt">
          <button class="pull-right" mat-raised-button color="accent" type="button" awNextStep>
            <span>Finish</span>
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </aw-wizard-step>

  <aw-wizard-completion-step [navigationSymbol]="{ symbol: '4' }" stepTitle="Send!">
    <div class="container" id="step-4" *ngIf="createdCampaign && createdCampaign.ID" @ngIfAnimation>
      <div class="animate clearfix">
        <div class="row clearfix">
          <mat-card class="card successful">
            <div class="row clearfix">
              <div class="full-width text-center">
                <h1 class="sm-h1 sr-title">Well Done!</h1>
                <h3 class="sm-h3 rm-m">Social media Campaign is Created</h3>
                <div class="row clearfix">
                  <div class="svg-box">
                    <svg class="circular green-stroke">
                      <circle class="path" cx="75" cy="75" r="50" fill="none" stroke-width="5" stroke-miterlimit="10" />
                    </svg>
                    <svg class="checkmark green-stroke">
                      <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                        <path
                          class="checkmark__check"
                          fill="none"
                          d="M616.306,283.025L634.087,300.805L673.361,261.53"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div class="row clearfix text-center button-row">
              <button
                *ngIf="createdCampaign && createdCampaign.ID"
                (click)="viewCampaign()"
                mat-raised-button
                color="accent"
              >
                <mat-icon>visibility</mat-icon>
                View Campaign
              </button>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
    <!--  / Step 3  -->
  </aw-wizard-completion-step>
</aw-wizard>
