import { Location } from '@angular/common';
import { Component, Input, OnInit, ViewChildren } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { DocumentCreateComponent } from '../../document/document-create/document-create.component';
import { MerchantProspectService } from '../../merchant-prospect/shared/merchant-prospect.service';

@Component({
  selector: 'app-merchant-prospect-profile',
  templateUrl: './merchant-prospect-profile.component.html',
  styleUrls: ['./merchant-prospect-profile.component.css'],
})
export class MerchantProspectProfileComponent implements OnInit {
  @Input()
  merchantProspectID;

  merchantProspect;

  isPromoterOrAdmin = false;

  profileLink;

  isAdressValid = false;
  validateMobile = false;
  validatePhone = false;
  validateEmail = false;
  validateReplyToEmail = false;

  merchantProspectMobile;
  merchantProspectPhone;
  merchantProspectEmail;
  merchantProspectReplyToEmail;

  merchantProspectSuburb;
  merchantProspectState;
  merchantProspectPostCode;
  merchantProspectStreetNumber;
  merchantProspectStreetName;
  merchantProspectUnitNumber;
  merchantProspectStreetType;
  merchantProspectLatitude;
  merchantProspectLongitude;

  profileDescription;

  @ViewChildren('sidenav') sidenav: MatSidenav;
  navMode = 'side';
  openFlag = true;

  settings = 'practice';
  dentistID;

  constructor(
    private merchantProspectService: MerchantProspectService,
    private router: Router,
    private activeRouter: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private location: Location
  ) {}

  ngOnInit() {
    this.activeRouter.params.subscribe((params) => {
      if (params['merchantProspectID']) {
        this.merchantProspectID = params['merchantProspectID'];
      }

      if (params['settings']) {
        this.settings = params['settings'];
      }

      if (params['itemID']) {
        if (this.settings == 'staff') {
          this.dentistID = params['itemID'];
        }
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res1) => {
        this.isPromoterOrAdmin = res1;

        if (this.isPromoterOrAdmin == true) {
          this.setup();
        } else {
          this.router.navigate(['/merchant']);
        }
      });
    });
  }

  setup() {
    if (this.merchantProspectID) {
      this.merchantProspectService.getMerchantProspectDetails(this.merchantProspectID).subscribe((res) => {
        if (res) {
          this.merchantProspect = res;

          this.merchantProspectPhone = this.merchantProspect['phones.Number'];
          this.merchantProspectMobile = this.merchantProspect['mobiles.Number'];
          this.merchantProspectEmail = this.merchantProspect['emails.Email'];

          this.merchantProspectReplyToEmail = this.merchantProspect['email.ReplyTo'];

          this.merchantProspectSuburb = this.merchantProspect['addresses.Suburb'];
          this.merchantProspectState = this.merchantProspect['addresses.State'];
          this.merchantProspectPostCode = this.merchantProspect['addresses.Postcode'];
          this.merchantProspectStreetNumber = this.merchantProspect['addresses.Street Nr'];
          this.merchantProspectStreetName = this.merchantProspect['addresses.Street Name'];
          this.merchantProspectUnitNumber = this.merchantProspect['addresses.Unit'];
          this.merchantProspectStreetType = this.merchantProspect['addresses.Street Type'];
          this.merchantProspectLatitude = this.merchantProspect['Address.Latitude'];
          this.merchantProspectLongitude = this.merchantProspect['Address.Longitude'];

          this.profileDescription = this.merchantProspect['Description'];
          this.getPicture();
        }
      });
    }
  }

  getPicture() {
    this.profileLink = null;

    if (this.merchantProspectID) {
      this.profileLink = this.merchantProspectService.getProfilePicStreamLink(this.merchantProspectID);
    }
  }

  onCompleteAllPractice(fileID) {
    if (fileID && fileID[0]) {
      const payload = {
        fileID: fileID[0],
      };
      this.profileLink = null;

      this.merchantProspectService
        .uploadMerchantProspectProfilePic(this.merchantProspect['ID'], payload)
        .subscribe((res) => {
          this.getPicture();
        });
    }
  }

  goBack() {
    this.location.back();
  }

  editPractice() {
    const payload = {
      tradingAs: this.merchantProspect['TradingAs'],
      abn: this.merchantProspect['ABN'],
      acn: this.merchantProspect['ACN'],

      email: this.merchantProspectEmail,
      mobile: this.merchantProspectMobile,
      workPhone: this.merchantProspectPhone,

      streetName: this.merchantProspectStreetName,
      streetNumber: this.merchantProspectStreetNumber,
      streetType: this.merchantProspectStreetType,
      unitNumber: this.merchantProspectUnitNumber,
      suburb: this.merchantProspectSuburb,
      state: this.merchantProspectState,
      postCode: this.merchantProspectPostCode,
      latitude: this.merchantProspectLatitude,
      longitude: this.merchantProspectLongitude,
      replyToEmail: this.merchantProspectReplyToEmail,
      thirdPartyBooking_URL: this.merchantProspect['ThirdPartyBooking_URL'],

      facebook: this.merchantProspect['Facebook'],
      instagram: this.merchantProspect['Instagram'],
      twitter: this.merchantProspect['Twitter'],
      linkedin: this.merchantProspect['LinkedIn'],

      pinterest: this.merchantProspect['Pinterest'],
      tumblr: this.merchantProspect['Tumblr'],
      vimeo: this.merchantProspect['Vimeo'],
      url: this.merchantProspect['URL'],

      description: this.profileDescription,
    };

    this.merchantProspectService.editMerchantProspect(this.merchantProspectID, payload).subscribe((res) => {
      if (res) {
        this.setup();
        NotifyAppComponent.displayToast(
          'success',
          'Update Practice Profile',
          `You have successfully updated ${this.merchantProspect['TradingAs']}'s profile.`
        );
      }
    });
  }

  getMobile(m) {
    this.merchantProspectMobile = m;
  }

  getPhone(p) {
    this.merchantProspectPhone = p;
  }

  getEmail(e) {
    this.merchantProspectEmail = e;
  }

  getReplyToEmail(e) {
    this.merchantProspectReplyToEmail = e;
  }

  validateMobileEvent(v) {
    this.validateMobile = v;
  }

  validatePhoneEvent(v) {
    this.validatePhone = v;
  }

  validateEmailEvent(v) {
    this.validateEmail = v;
  }

  validateReplyToEmailEvent(v) {
    this.validateReplyToEmail = v;
  }

  isAdressValidEvent(v) {
    this.isAdressValid = v;
  }

  getAdress(adr) {
    if (adr) {
      this.merchantProspectSuburb = adr.suburb;
      this.merchantProspectState = adr.state;
      this.merchantProspectPostCode = adr.postCode;
      this.merchantProspectStreetNumber = adr.streetNumber;
      this.merchantProspectStreetName = adr.streetName;
      this.merchantProspectUnitNumber = adr.unitNumber;
      this.merchantProspectStreetType = adr.streetType;
      this.merchantProspectLatitude = adr.latitude;
      this.merchantProspectLongitude = adr.longitude;
    }
  }

  getProfileContent(c) {
    this.profileDescription = c;
  }

  displayDentistList() {
    this.dentistID = null;
  }

  uploadDocument() {
    const ref = RootAppComponent.dialog.open(DocumentCreateComponent, {
      data: {
        cardID: this.merchantProspectID,
      },
      width: '650px',
    });

    ref.componentInstance.create.subscribe((res) => {
      if (res) {
        ref.close();
      }
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }
}
