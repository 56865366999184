<!-- header if standalone -->
<div class="card-header primary-gradient-img clearfix inModal" *ngIf="embedded == false">
  <div class="row clearfix">
    <!-- Modal dismiss button -->
    <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeEvent()" matDialogClose>
      <mat-icon>clear</mat-icon>
    </button>

    <div class="row titleArea clearfix">
      <mat-icon class="pull-left white fas fa-university large-icon"></mat-icon>

      <h2 class="summary-header rm-m" *ngIf="userName && !title && bankAccounts && bankAccounts.length > 0">
        Available Bank Accounts
        <span class="subLabel">{{ userName }}</span>
      </h2>

      <h2 class="summary-header rm-m" *ngIf="!userName && title && bankAccounts && bankAccounts.length > 1">
        {{ title }}
      </h2>
    </div>
  </div>
  <!-- <div class="action-button">
          <button mat-raised-button [matMenuTriggerFor]="btnPatMenu" class="actionMenuToggle">
            Actions <mat-icon>more_vert</mat-icon>
          </button>

              <mat-menu #btnPatMenu="matMenu">
                  <button class="" mat-menu-item color="accent" (click)="contact()" *ngIf="existingPatient['email']">
                    <mat-icon>contact_mail</mat-icon> Contact {{existingPatient.firstName}}
                  </button>
            </mat-menu>
        </div> -->
</div>
<div class="grey-row" *ngIf="description && bankAccounts && bankAccounts.length > 1 && embedded == true">
  <p class="mb">
    {{ description }}
  </p>
</div>

<!-- header if embedded -->
<div *ngIf="embedded == true">
  <h2 class="summary-header rm-mt patientcard-title clearfix sr-title"
    *ngIf="userName && !title && bankAccounts && bankAccounts.length > 0">
    <strong> {{ userName }}</strong> - Bank Accounts
  </h2>

  <h2 class="summary-header rm-mt patientcard-title clearfix sr-title"
    *ngIf="!userName && title && bankAccounts && bankAccounts.length > 1">
    {{ title }}
  </h2>

  <p class="mb" *ngIf="description && bankAccounts && bankAccounts.length > 1">
    {{ description }}
  </p>
</div>

<!-- display output -->
<div class="clearfix row listAreaDisplay" *ngIf="userID">
  <!-- accounts do exist -->
  <div class="row clearfix high" *ngIf="bankAccounts && bankAccounts.length > 0">
    <div class="clearfix settleWell" *ngFor="let b of bankAccounts; let i = index" [class.white-line]="i%2==1"  (click)="selectBank(b)">
      <div class="row clearfix listTemplate flex"  (click)="openQuickViewDialog(b['ID'])"
        [class.selectedBankAccount]="bankAccount && b['ID'] == bankAccount['ID'] && isAction == true"
        [class.selectableBank]="isAction == true">
        <!-- type icon -->

        <!-- main info content -->
        <div class="full-width">


          <!-- lower row of information -->
          <div class="status-row full-width row clearfix flex grey">


            <div class="two-thrd-width">



              <img  *ngIf="b['AccountType.Code'] == 'CC' &&  getCardType(b['Card.Type.Code'])==='MC'"  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/card_11041055.png" class=" img-cc-type pull-left mr "  />
              <img  *ngIf="b['AccountType.Code'] == 'CC'  &&  getCardType(b['Card.Type.Code'])==='VS' "  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/card_11041038.png" class=" img-cc-type pull-left mr "  />
              <img  *ngIf="b['AccountType.Code'] == 'CC'  &&  getCardType(b['Card.Type.Code'])==='AX' "  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/american-express_349228.png" class=" img-cc-type pull-left mr "  />
              <img  *ngIf="b['AccountType.Code'] == 'CC'  &&  getCardType(b['Card.Type.Code'])==='CC' "  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/credit-card_147258.png" class=" img-cc-type pull-left mr "  />
              <img  *ngIf="b['AccountType.Code'] != 'CC'"  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/bank_2830289.png" class=" img-cc-type  pull-left mr "  />

              <h4 class="sr-title cc-title  " *ngIf="b['AccountType.Code'] == 'CC'">Credit Card</h4>

              <h4 class="sr-title  cc-title" *ngIf="b['AccountType.Code'] != 'CC'">Bank Account</h4>
            </div>

            <div class="row clearfix half-width" *ngIf="b['AccountType.Code'] == 'CC'">
              <label class="small full-width">Card Type</label>
              <p class="small full-width rm-mt">{{ b['Card.Type.Label'] }}</p>
            </div>

            <div class="row clearfix half-width" *ngIf="b['AccountType.Code'] != 'CC'">
              <label class="small full-width"> BankName </label>
              <p class="small full-width rm-mt">{{ b['BankName'] }}</p>
            </div>
            <div class="full-width flex" *ngIf="b['AccountType.Code'] != 'CC'">
              <div class="row clearfix full-width" >
                <label class="small full-width"> BSB Number </label>
                <p class="small full-width rm-mt">{{ b['BankCode'] }}-{{ b['BranchCode'] }}</p>
              </div>

              <div class="row clearfix full-width" >
                <label class="small full-width"> Account Number </label>
                <p class="small full-width rm-mt">{{ b['AccountNumber.Full'] }}</p>
              </div>
            </div>
            <div class="row clearfix full-width" *ngIf="b['AccountType.Code'] == 'CC'">
              <label class="small full-width">Card Number</label>
              <p class="small full-width rm-mt">{{ b['Card.Number.Full'] }}</p>
            </div>

            <p class="small half-width text-right nowrap"
              *ngIf="b['HasBeenVerified'] == '1' && isPromoterOrAdmin == true">
              <span class="chip chipGood">Verified <mat-icon>check</mat-icon> </span>
            </p>

            <p class="small half-width text-right nowrap"
              *ngIf="b['HasBeenVerified'] == '0' && isPromoterOrAdmin == true">
              <span class="chip chipBad">Unverified <mat-icon>highlight_off</mat-icon> </span>
            </p>

            <p class="small half-width text-right nowrap" *ngIf="b['IsActive'] == '1' && isPromoterOrAdmin == true">
              <span class="chip chipGood">Activated <mat-icon>check</mat-icon> </span>
            </p>

            <p class="small half-width text-right nowrap" *ngIf="b['IsActive'] == '0' && isPromoterOrAdmin == true">
              <span class="chip chipBad">Not Activated <mat-icon>highlight_off</mat-icon> </span>
            </p>

            <button *ngIf="isPromoterOrAdmin == true && canEdit == true" [matMenuTriggerFor]="menu" class="ml" (click)="$event.stopPropagation()" mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button *ngIf="b['ID']" (click)="openQuickViewDialog(b['ID'])" mat-menu-item>
                <mat-icon>description</mat-icon>
                <span>Quick View</span>
              </button>
              <button *ngIf="b['IsActive'] == '1' && isPromoterOrAdmin == true && canEdit == true"
                (click)="disable(b['ID'])" mat-menu-item>
                <mat-icon>cancel</mat-icon>
                <span>Disable</span>
              </button>
              <button *ngIf="b['IsActive'] == '0' && isPromoterOrAdmin == true && canEdit == true"
                (click)="enable(b['ID'])" mat-menu-item>
                <mat-icon>check_circle</mat-icon>
                <span>Enable</span>
              </button>
            </mat-menu>

          </div>

          <div class="full-width text-center clearfix row">
            <button class="pull-right" *ngIf="isActionButton == true" (click)="actionEvent(t)" mat-raised-button
              color="primary" matTooltip="{{ actionLabel }}">
              <span>{{ actionLabel }}</span>
              <mat-icon *ngIf="actionIcon">{{ actionIcon }}</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--  no account to display -->
  <div class="add-pad clearfix high overFlowContainer" *ngIf="!bankAccounts || bankAccounts.length <= 0">
    <div class="card-header primary-gradient-img clearfix inModal" *ngIf="embedded == false">
      <div class="row clearfix">
        <!-- Modal dismiss button -->
        <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeEvent()"
          matDialogClose>
          <mat-icon>clear</mat-icon>
        </button>

        <div class="row titleArea clearfix">
          <mat-icon class="pull-left white fas fa-university large-icon"></mat-icon>

          <h2 class="summary-header rm-m" *ngIf="userName">
            Bank Accounts
            <span class="subLabel">{{ userName }}</span>
          </h2>

          <h2 class="summary-header rm-m" *ngIf="!userName">Bank Accounts</h2>
        </div>
      </div>
    </div>

    <h2 class="summary-header rm-mt patientcard-title clearfix sr-title" *ngIf="userName && embedded == true">
      <strong> {{ userName }}</strong> - Bank Accounts
    </h2>

    <app-empty-list-message class="clearfix" *ngIf="isPromoterOrAdmin == true && canEdit == true && isLoaded==true"
      [title]="'No data to show'" [message]="'No Bank Account found for this user'" [isAction]="true"
      [actionIcon]="'add'" [actionLabel]="'Create New Bank Account'" (actionEvent)="actionEventEmpty($event)">
    </app-empty-list-message>
    <app-empty-list-message class="clearfix"
      *ngIf="(isPromoterOrAdmin == false || (isPromoterOrAdmin == true && canEdit != true))&& isLoaded==true"
      [title]="'No data to show'" [message]="'No Active Bank Account found for this user'" [isAction]="false">
    </app-empty-list-message>
  </div>
</div>
