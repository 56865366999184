import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultMessageListGlobalComponent } from '../default-message-list-global/default-message-list-global.component';
import { DefaultMessageListOverviewComponent } from '../default-message-list-overview/default-message-list-overview.component';
import { DefaultMessageListComponent } from '../default-message-list/default-message-list.component';
import { DefaultMessageLookupComponent } from '../default-message-lookup/default-message-lookup.component';
import { DefaultMessageViewComponent } from '../default-message-view/default-message-view.component';
import { MessageTemplateCreateEditComponent } from '../message-template-create-edit/message-template-create-edit.component';

const routes: Routes = [
  {
    path: 'default-message-list',
    component: DefaultMessageListComponent,
  },
  {
    path: 'default-message-view',
    component: DefaultMessageViewComponent,
  },
  {
    path: 'default-message-list-global',
    component: DefaultMessageListGlobalComponent,
  },
  {
    path: 'dMessageTemplateCreateEditComponent',
    component: MessageTemplateCreateEditComponent,
  },

  {
    path: 'eefault-message-list-overview',
    component: DefaultMessageListOverviewComponent,
  },
  {
    path: 'DefaultMessageLookupComponentTEST',
    component: DefaultMessageLookupComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DefaultMessageRoutingModule {}
