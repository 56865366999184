import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { InvitationTemplateLookupComponent } from '../../../feature/invitation-template/invitation-template-lookup/invitation-template-lookup.component';
import { InvitationTemplateMergerComponent } from '../../../feature/invitation-template/invitation-template-merger/invitation-template-merger.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { ThirdPartyService } from '../shared/third-party.service';
import { ThirdPartyClientViewComponent } from '../third-party-client-view/third-party-client-view.component';

@Component({
  selector: 'app-third-party-access-create',
  templateUrl: './third-party-access-create.component.html',
  styleUrls: ['./third-party-access-create.component.css'],
})
export class ThirdPartyAccessCreateComponent implements OnInit {
  merchantID;
  originTemplateID;
  originTemplateLabel;
  thirdPartyAccessID;
  thirdPartyAccess;

  dentists = [];
  clients = [];
  types = [];

  thirdPartyAccessIDs = [];

  comment;

  dentistID;
  clientID;
  typeCode = 'API_key';
  apiKey;

  isManual = false;
  isActive = true;

  removeOld = false;

  merchantTradingAs;

  isPromoterOrAdmin = false;

  templateID;
  templateLabel;
  @Output() getResultGroup = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() getResult = new EventEmitter();
  sessionType;

  clientName;

  constructor(
    private authenticationService: AuthenticationService,
    private ThirdPartyService: ThirdPartyService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.thirdPartyAccessID) {
        this.thirdPartyAccessID = data.thirdPartyAccessID;
      }

      if (data.thirdPartyAccessIDs) {
        let _thirdPartyAccessIDs = data.thirdPartyAccessIDs;

        if (typeof _thirdPartyAccessIDs === 'string') {
          _thirdPartyAccessIDs = _thirdPartyAccessIDs.split(',');
        }

        if (_thirdPartyAccessIDs && _thirdPartyAccessIDs.length > 0 && Array.isArray(_thirdPartyAccessIDs)) {
          this.thirdPartyAccessIDs = _thirdPartyAccessIDs;
        }
      }
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((r) => {
      this.isPromoterOrAdmin = r;

      this.authenticationService.getSessionType().subscribe((sessionType) => {
        this.sessionType = sessionType;

        if ((this.sessionType !== 'promoter' && this.sessionType == 'admin') || !this.merchantID) {
          this.authenticationService.getCurrentPractice().subscribe((res) => {
            if (res && res.ID) {
              this.merchantID = res.ID;
              this.setup();
            }
          });
        } else {
          this.setup();
        }
      });
    });
  }

  view(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(ThirdPartyClientViewComponent, {
        data: {
          thirdPartyClientID: id,
        },
        width: '700px',
        panelClass: 'noCard',
        height: '1000px',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  setup() {
    if (this.merchantID) {
      this.authenticationService
        .getMerchantDetails(this.merchantID, { fields: 'TradingAs' }, this.sessionType)
        .subscribe((res) => {
          if (res && res['TradingAs']) {
            this.merchantTradingAs = res['TradingAs'];
          }
        });

      this.authenticationService
        .getDentistList(
          {
            fields: 'ID,CalculatedName',
            orderBy: 'CalculatedName',
            merchantID: this.merchantID,
          },
          this.isPromoterOrAdmin
        )
        .subscribe((res) => {
          if (res) {
            this.dentists = res;
          }
        });
    }

    this.ThirdPartyService.getThirdPartyClientList(
      { fields: 'ID,Label,Is_Active,Template_Key,Template_Label' },
      this.sessionType
    ).subscribe((res) => {
      if (res) {
        this.clients = res;
      }
    });
    if (this.thirdPartyAccessID) {
      this.ThirdPartyService.getThirdPartyAccessDetails(this.thirdPartyAccessID, {}, this.sessionType).subscribe(
        (res) => {
          if (res) {
            this.thirdPartyAccess = res;

            if (this.thirdPartyAccess['Merchant_Trading_As']) {
              this.merchantTradingAs = this.thirdPartyAccess['Merchant_Trading_As'];
            }

            if (this.thirdPartyAccess['Dentist_key']) {
              this.dentistID = this.thirdPartyAccess['Dentist_key'];
            }

            if (this.thirdPartyAccess['Template_Key']) {
              this.templateID = this.thirdPartyAccess['Template_Key'];
            }

            if (this.thirdPartyAccess['Comment']) {
              this.comment = this.thirdPartyAccess['Comment'];
            }

            if (this.thirdPartyAccess['Template_Label']) {
              this.templateLabel = this.thirdPartyAccess['Template_Label'];
            }
            if (this.thirdPartyAccess['Third_Party_Client_Key']) {
              this.clientID = this.thirdPartyAccess['Third_Party_Client_Key'];
            }

            if (this.thirdPartyAccess['Third_Party_Client_Template_Key']) {
              this.originTemplateID = this.thirdPartyAccess['Third_Party_Client_Template_Key'];
            }
            if (this.thirdPartyAccess['Third_Party_Client_Template_Label']) {
              this.originTemplateLabel = this.thirdPartyAccess['Third_Party_Client_Template_Label'];
            }
            if (this.thirdPartyAccess['Type.Code']) {
              this.typeCode = this.thirdPartyAccess['Type.Code'];
            }
            if (this.thirdPartyAccess['Is_Active'] === '1') {
              this.isActive = true;
            } else if (this.thirdPartyAccess['Is_Active'] === '0') {
              this.isActive = false;
            }

            if (this.thirdPartyAccess['Merchant_Trading_As']) {
              this.merchantTradingAs = this.thirdPartyAccess['Merchant_Trading_As'];
            }
          }
        }
      );
    }
  }

  save() {
    if (this.thirdPartyAccessIDs && this.thirdPartyAccessIDs.length > 0) {
      const p = {
        templateID: this.templateID,
        templateLabel: this.templateLabel,
        ids: this.thirdPartyAccessIDs.join(','),
      };
      this.ThirdPartyService.modifyThirdPartyAccessGroup(p, this.sessionType).subscribe((res) => {
        if (res) {
          this.getResultGroup.emit(res);

          NotifyAppComponent.displayToast('success', 'Success!', 'Accesses are Modified');
        }
      });
    } else if (this.thirdPartyAccessID) {
      const p = {
        comment: this.comment,
        isActive: this.isActive,
        dentistID: this.dentistID,
        templateID: this.templateID,
        templateLabel: this.templateLabel,
      };

      this.ThirdPartyService.modifyThirdPartyAccess(this.thirdPartyAccessID, p, this.sessionType).subscribe((res) => {
        if (res) {
          this.getResult.emit(res);

          NotifyAppComponent.displayToast('success', 'Success!', 'Access is Modified');
        }
      });
    } else {
      const p: any = {
        comment: this.comment,
        merchantID: this.merchantID,
        dentistID: this.dentistID,
        clientID: this.clientID,
        typeCode: this.typeCode,
        removeOld: this.removeOld,
        isActive: this.isActive,
        templateID: this.templateID,
        templateLabel: this.templateLabel,
      };

      if (this.isManual == true) {
        p.apiKey = this.apiKey;
      }

      this.ThirdPartyService.createThirdPartyAccess(p, this.sessionType).subscribe((res) => {
        if (res) {
          this.getResult.emit(res);

          NotifyAppComponent.displayToast('success', 'Success!', 'Access is Modified');
        }
      });
    }
  }

  getClientObject() {
    if (this.clientID) {
      const v = this.clients.findIndex((item) => {
        if (item && item.ID && item.ID == this.clientID) {
          return true;
        }

        return false;
      });

      if (v !== -1) {
        const item = this.clients[v];

        if (item['Label']) {
          this.clientName = item['Label'];
        }
        if (item['Template_Key'] && item['Template_Label']) {
          this.templateID = item['Template_Key'];
          this.templateLabel = item['Template_Label'];
        }
      }
    }
  }

  openTemplateLookup() {
    const ref = RootAppComponent.dialog.open(InvitationTemplateLookupComponent, {
      data: {
        merchantID: this.merchantID,
        isModal: true,
        viewOnClick: false,
        viewOnly: false,
        hideTreatmentValue: true,
        isInvitationContext: false,
      },
      panelClass: 'bigger-screen',
      width: '820px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getTemplate.subscribe((result) => {
      if (result && result._id && result.label) {
        this.templateID = result._id;
        this.templateLabel = result.label;
        ref.close();
      }
    });
  }

  viewTemplate(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(InvitationTemplateMergerComponent, {
        data: {
          templateID: id,
          viewOnly: true,
          hideTreatmentValue: true,
        },
        width: '1200px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res === 'all') {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            this.closeEvent();
          });
        } else {
          ref.close();
        }
      });
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  reset() {
    if (this.originTemplateID && this.originTemplateLabel) {
      this.templateID = this.originTemplateID;
      this.templateLabel = this.originTemplateLabel;
    }
  }
}
