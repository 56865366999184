import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { HeaderService } from '../../../shared/services/header.service';
import { Settings } from '../../../shared/types/settings';

/**
 * @title Feature-rich data table
 */
@Component({
  selector: 'app-invitation-list-overview',
  templateUrl: './invitation-list-overview.component.html',
  styleUrls: ['./invitation-list-overview.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({
                  opacity: 0,
                  transform: 'translateY(-75%)',
                  offset: 0,
                  height: 0,
                }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({
                  opacity: 1,
                  transform: 'translateY(0)',
                  offset: 1.0,
                  height: '*',
                }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({
                  opacity: 1,
                  transform: 'translateY(0)',
                  offset: 0,
                  height: '*',
                }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({
                  opacity: 0,
                  transform: 'translateY(-75%)',
                  offset: 1.0,
                  height: 0,
                }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class InvitationListOverViewComponent implements OnInit {
  isAdminOrPromoter = null;

  merchants = [];
  dateFrom;
  dateTo;
  _dateFrom;
  _dateTo;
  _merchantID = null;
  @Input()
  productGroupCode;
  @Input()
  merchantID = null;
  productID;
  status;
  dentistID;

  isDisplayEmailRep = true;

  role;
  @Input()
  hideExtraComponents = false;

  isStatusParameter = false;

  sendNull = true;
  @Input()
  selectedRange = 'days90';

  @Input()
  selectedRangePromoter = 'days60';
  displayResult = true;

  @Input()
  displayedColumns;
  @Input()
  isExpired;

  @Input()
  hasPatientChoice;

  @Input()
  patientID;

  @Input()
  onClickEvent = false;

  @Output()
  clickEvent = new EventEmitter();

  @Input()
  membership = false;

  @Input()
  voided = false;

  @Input()
  pageSize = Settings.global['listPageSize'] || 20;

  isDisplayMerchant = true;

  searchApplied = false;

  load = false;

  constructor(
    private AuthenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.AuthenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isAdminOrPromoter = res;

      this.activeRoute.params.subscribe((params) => {
        if (params['status']) {
          this.isStatusParameter = true;
          this.status = params['status'] === 'all' ? null : params['status'];
          this.isDisplayEmailRep = false;
        }
        if (params['data']) {
          let data = JSON.parse(params['data']);

          if (data.dateRange) {
            this.selectedRange = data.dateRange;
            this.selectedRangePromoter = data.dateRange;
          }
        }

        this.load = true;
      });
      if (this.voided == true || this.membership == true) {
        this.isDisplayEmailRep = false;
      }
      if (this.isAdminOrPromoter == true) {
        const _mID = JSON.parse(localStorage.getItem('selectedMerchant'));
        if (_mID) {
          this.merchantID = _mID;
          // this.isDisplayMerchant = false;
        }

        HeaderService.getPromoterViewAsObject().subscribe((res) => {
          if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'promoter') {
            this.merchantID = null;
            this.isDisplayMerchant = true;
          } else if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'merchant') {
            this.merchantID = res['merchantID'];
            this.isDisplayMerchant = false;
          }
        });
      }
    });
  }

  viewDetails(e) {
    this.hideExtraComponents = e;
  }

  getMerchant(d) {
    this._merchantID = d;

    this.displayResult = false;
    setTimeout(() => {
      this.displayResult = true;
    }, 500);
  }

  getDateFrom(d) {
    this._dateFrom = d;

    this.displayResult = false;
    setTimeout(() => {
      this.displayResult = true;
    }, 500);
  }

  getDateTo(d) {
    this._dateTo = d;

    this.displayResult = false;
    setTimeout(() => {
      this.displayResult = true;
    }, 500);
  }

  applySearch(result) {
    this.merchantID = result.merchantID;
    this.dateFrom = result.dateFrom;
    this.dateTo = result.dateTo;
    this.productID = result.productID;
    this.dentistID = result.dentistID;

    if (this.isStatusParameter == false) {
      this.status = result.status;
    }

    this.searchApplied = true;
    this.changeDetectorRef.detectChanges();

    this.displayResult = false;
    setTimeout(() => {
      this.displayResult = true;
    }, 500);
  }

  clickEventAction(id) {
    this.clickEvent.emit(id);
  }
}
