<div class="clearfix" *ngIf="isAdminOrPromoter == true && load==true">
  <div [hidden]="hideExtraComponents">
    <app-operator-filter id="part1" [sendNull]="true" [selectDateRange]="selectedRangePromoter"
      [displayMerchant]="isDisplayMerchant" [displayDateFrom]="true" [setAutoSelectMerchant]="true"
      [displayDateTo]="true" [displayDentist]="false" [selectedMerchant]="merchantID"
      (getMerchant)="getMerchant($event)" (getDateTo)="getDateTo($event)" (getDateFrom)="getDateFrom($event)"
      (search)="applySearch($event)"></app-operator-filter>
  </div>

  <div class="row clearfix">
    <app-invitation-list-global *ngIf="merchantID != 'none' && searchApplied == true && displayResult==true "
      [isLoadSubAccountStatistics]="isDisplayEmailRep" [productGroupCode]="productGroupCode" [voided]="voided"
      [displayedColumns]="displayedColumns" [isExpired]="isExpired" [hasPatientChoice]="hasPatientChoice"
      [patientID]="patientID" [membership]="membership" [onClickEvent]="onClickEvent" [pageSize]="pageSize"
      [productID]="productID" [staffID]="dentistID" [status]="status" [dateFrom]="dateFrom" [dateTo]="dateTo"
      [merchantID]="merchantID" (viewDetails)="viewDetails($event)" (clickEvent)="clickEventAction($event)">
    </app-invitation-list-global>
  </div>
  <div class="row clearfix" [hidden]="hideExtraComponents">
    <div class="pleaseChoose" *ngIf="merchantID == 'none'" @ngIfAnimation>
      <p>
        <strong>No merchant selected</strong>
      </p>
      <img class="character_warning" />
      <p>
        Choose your desired merchant from the drop-down above to view their
        {{ "KEYWORD.patient" | translate }}s
      </p>
    </div>
  </div>
</div>

<div class="clearfix" *ngIf="isAdminOrPromoter == false && load==true">
  <div [hidden]="hideExtraComponents">
    <app-operator-filter id="part1" [sendNull]="true" [selectDateRange]="selectedRange"
      [displayMerchant]="false" [displayDateFrom]="true" [displayDateTo]="true" [displayTimePicker]="true"
      [displayDentist]="false" (getMerchant)="getMerchant($event)" (getDateTo)="getDateTo($event)"
      (getDateFrom)="getDateFrom($event)" (search)="applySearch($event)"></app-operator-filter>
  </div>
  <app-invitation-list id="part1" *ngIf="searchApplied == true && displayResult==true" [isLoadSubAccountStatistics]="isDisplayEmailRep"
    [productGroupCode]="productGroupCode" [voided]="voided" [displayedColumns]="displayedColumns"
    [onClickEvent]="onClickEvent" [isExpired]="isExpired" [hasPatientChoice]="hasPatientChoice" [patientID]="patientID"
    [membership]="membership" [pageSize]="pageSize" [status]="status" [productID]="productID" [dateFrom]="dateFrom"
    [dateTo]="dateTo" (clickEvent)="clickEventAction($event)" (viewDetails)="viewDetails($event)">
  </app-invitation-list>
</div>
