export class GoogleReviewsConfig {
  Google_Review_Place_ID: string;
  Google_Reviews_Account_ID: string;
  Google_Reviews_Location_ID: string;
}

export class GmbAccountResponse {
  accounts: GmbAccount[];
  nextPageToken: string;
}

export class GmbAccount {
  name?: string;
  accountName: string;
  primaryOwner?: string;
  type: GmbAccountType;
  role?: GmbAccountRole;
  verificationState?: GmbVerificationState;
  vettedState?: GmbVettedState;
  accountNumber?: string;
  permissionLevel?: GmbPermissionLevel;
  organizationInfo?: GmbOrganizationInfo;
  accountId: string;
}

export class GmbOrganizationInfo {
  registeredDomain: string;
  address: GmbPostalAddress;
  phoneNumber: string;
}

export class GmbPhoneNumbers {
  primaryPhone: string;
  additionalPhones: string[];
}

export class GmbLocationResponse {
  locations: GmbLocation[];
  nextPageToken: string;
  totalSize: number;
}

export class GmbLocation {
  name: string;
  languageCode?: string;
  storeCode?: string;
  title: string;
  locationName?: string;
  phoneNumbers?: GmbPhoneNumbers;
  storefrontAddress: GmbPostalAddress;
  categories?: GmbCategories;
  websiteUri?: string;
  regularHours?: GmbBusinessHours;
  specialHours?: GmbSpecialHours;
  serviceArea?: GmbServiceAreaBusiness;
  labels?: string[];
  adWordsLocationExtensions?: GmbAdWordsLocationExtensions;
  latlng?: GmbLatLng;
  openInfo?: GmbOpenInfo;
  metadata?: GmbMetadata;
  /* serviceItem?: GmbPriceList[]; update*/
  profile?: GmbProfile;
  relationshipData?: GmbRelationshipData;
  moreHours?: GmbMoreHours[];
}

export class GmbPostalAddress {
  revision?: number;
  regionCode: string;
  languageCode?: string;
  postalCode?: string;
  sortingCode?: string;
  administrativeArea?: string;
  locality: string;
  sublocality: string;
  addressLines: string[];
  recipients?: string[];
  organization?: string;
}

export class GmbCategory {
  displayName: string;
  categoryId: string;
  serviceTypes: GmbServiceType[];
  moreHoursTypes: GmbMoreHoursType[];
}

export class GmbCategories {
  primaryCategory: GmbCategory;
  additionalCategories: GmbCategory;
}

export class GmbServiceType {
  serviceTypeId: string;
  displayName: string;
}

export class GmbMoreHoursType {
  hoursTypeId: string;
  displayName: string;
  localizedDisplayName: string;
}

export class GmbBusinessHours {
  periods: GmbTimePeriod[];
}

export class GmbTimePeriod {
  openDay: GmbDayOfWeek;
  openTime: string;
  closeDay: GmbDayOfWeek;
  closeTime: string;
}

export class GmbSpecialHours {
  specialHourPeriods: GmbSpecialHourPeriod[];
}

export class GmbSpecialHourPeriod {
  startDate: GmbDate;
  openTime: string;
  endDate: GmbDate;
  closeTime: string;
  isClosed: boolean;
}

export class GmbDate {
  year: number;
  month: number;
  day: number;
}

export class GmbServiceAreaBusiness {
  businessType: GmbBusinessType;
  radius: GmbPointRadius;
  places: GmbPlaces;
}

export class GmbPointRadius {
  latlng: GmbLatLng;
  radiusKm: number;
}

export class GmbLatLng {
  latitude: number;
  longitude: number;
}

export class GmbPlaces {
  placesInfos: GmbPlaceInfo[];
}

export class GmbPlaceInfo {
  name: string;
  placeId: string;
}

export class GmbLocationKey {
  plusPageId?: string;
  placeId: string;
  explicitNoPlaceId?: boolean;
  requestId?: string;
}

export class GmbAdWordsLocationExtensions {
  adPhone: string;
}

export class GmbOpenInfo {
  status: GmbOpenForBusiness;
  canReopen: boolean;
  openingDate: GmbDate;
}

export class GmbLocationState {
  isGoogleUpdated: boolean;
  isDuplicate: boolean;
  isSuspended: boolean;
  canUpdate: boolean;
  canDelete: boolean;
  isVerified: boolean;
  needsReverification: boolean;
  isPendingReview: boolean;
  isDisabled: boolean;
  isPublished: boolean;
  isDisconnected: boolean;
  isLocalPostApiDisabled: boolean;
  canModifyServiceList: boolean;
  canHaveFoodMenus: boolean;
  hasPendingEdits: boolean;
  hasPendingVerification: boolean;
  canOperateHealthData: boolean;
  canOperateLodgingData: boolean;
}

export class GmbAttribute {
  attributeId: string;
  valueType: GmbAttributeValueType;
  values: unknown[];
  repeatedEnumValue: GmbRepeatedEnumAttributeValue;
  urlValues: GmbUrlAttributeValue[];
}

export class GmbRepeatedEnumAttributeValue {
  setValues: string[];
  unsetValues: string[];
}

export class GmbUrlAttributeValue {
  url: string;
}

export class GmbProfile {
  description: string;
}

export class GmbRelationshipData {
  parentChain: string;
}

export class GmbMoreHours {
  hoursTypeId: string;
  periods: GmbTimePeriod[];
}

export class GmbMetadata {
  duplicate: GmbDuplicate;
  mapsUrl: string;
  newReviewUrl: string;
}

export class GmbDuplicate {
  locationName: string;
  placeId: string;
  access: GmbAccess;
}

export class GmbPriceList {
  priceListId: string;
  labels: GmbLabel[];
  sourceUrl: string;
  sections: GmbSection[];
}

export class GmbLabel {
  displayName: string;
  description: string;
  languageCode: string;
}

export class GmbSection {
  sectionId: string;
  labels: GmbLabel[];
  sectionType: GmbSectionType;
  items: GmbItem[];
}

export class GmbItem {
  itemId: string;
  labels: GmbLabel[];
  price: GmbMoney;
}

export class GmbMoney {
  currencyCode: string;
  units: string;
  nanos: number;
}

export class GmbReview {
  name?: string;
  reviewId: string;
  reviewer: GmbReviewer;
  starRating: GmbStarRating | number;
  comment: string;
  createTime: number;
  updateTime: number | Date;
  createTimeText: string;
  updateTimeText: string;
  reviewReply?: GmbReviewReply;
}

export class GmbPlaceReview {
  reviews: GmbReview[];
  averageRating: number;
  totalReviewCount: number;
  nextPageToken: string;
}

export class GmbReviewer {
  profilePhotoUrl?: string;
  displayName: string;
  isAnonymous?: boolean;
}

export class GmbReviewReply {
  comment: string;
  updateTime?: number;
}

export enum GmbAccountType {
  accountTypeUnspecified = 'ACCOUNT_TYPE_UNSPECIFIED',
  PERSONAL = 'PERSONAL',
  locationGroup = 'LOCATION_GROUP',
  userGroup = 'USER_GROUP',
  organization = 'ORGANIZATION',
}

export enum GmbAccountRole {
  accountRoleUnspecified = 'ACCOUNT_ROLE_UNSPECIFIED',
  primaryOwner = 'PRIMARY_OWNER',
  owner = 'OWNER',
  manager = 'MANAGER',
  siteManager = 'SITE_MANAGER',
}

export enum GmbVerificationState {
  verificationStateUnspecified = 'VERIFICATION_STATE_UNSPECIFIED',
  verified = 'VERIFIED',
  unverified = 'UNVERIFIED',
  verificationRequested = 'VERIFICATION_REQUESTED',
}

export enum GmbVettedState {
  verificationStateUnspecified = 'VERIFICATION_STATE_UNSPECIFIED',
  verified = 'VERIFIED',
  unverified = 'UNVERIFIED',
  verificationRequested = 'VERIFICATION_REQUESTED',
}

export enum GmbPermissionLevel {
  permissionLevelUnspecified = 'PERMISSION_LEVEL_UNSPECIFIED',
  ownerLevel = 'OWNER_LEVEL',
  memeberLevel = 'MEMBER_LEVEL',
}

export enum GmbDayOfWeek {
  DAY_OF_WEEK_UNSPECIFIED = 'DAY_OF_WEEK_UNSPECIFIED',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export enum GmbBusinessType {
  BUSINESS_TYPE_UNSPECIFIED = 'BUSINESS_TYPE_UNSPECIFIED',
  CUSTOMER_LOCATION_ONLY = 'CUSTOMER_LOCATION_ONLY',
  CUSTOMER_AND_BUSINESS_LOCATION = 'CUSTOMER_AND_BUSINESS_LOCATION',
}

export enum GmbOpenForBusiness {
  OPEN_FOR_BUSINESS_UNSPECIFIED = 'OPEN_FOR_BUSINESS_UNSPECIFIED',
  OPEN = 'OPEN',
  CLOSED_PERMANENTLY = 'CLOSED_PERMANENTLY',
  CLOSED_TEMPORARILY = 'CLOSED_TEMPORARILY',
}

export enum GmbAttributeValueType {
  ATTRIBUTE_VALUE_TYPE_UNSPECIFIED = 'ATTRIBUTE_VALUE_TYPE_UNSPECIFIED',
  BOOL = 'BOOL',
  ENUM = 'ENUM',
  URL = 'URL',
  REPEATED_ENUM = 'REPEATED_ENUM',
}

export enum GmbAccess {
  ACCESS_UNSPECIFIED = 'ACCESS_UNSPECIFIED',
  ACCESS_UNKNOWN = 'ACCESS_UNKNOWN',
  ALLOWED = 'ALLOWED',
  INSUFFICIENT = 'INSUFFICIENT',
}

export enum GmbSectionType {
  SECTION_TYPE_UNSPECIFIED = 'SECTION_TYPE_UNSPECIFIED',
  FOOD = 'FOOD',
  SERVICES = 'SERVICES',
}

export enum GmbStarRating {
  STAR_RATING_UNSPECIFIED = 'STAR_RATING_UNSPECIFIED',
  ONE = 'ONE',
  TWO = 'TWO',
  THREE = 'THREE',
  FOUR = 'FOUR',
  FIVE = 'FIVE',
}
