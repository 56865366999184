import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TasksService } from '../shared/tasks.service';

@Component({
  selector: 'app-tast-manager-picture',
  templateUrl: './tast-manager-picture.component.html',
  styleUrls: ['./tast-manager-picture.component.css'],
})
export class TastManagerPictureComponent implements OnInit {
  @Input() taskID;

  @Input()
  hideZoomButton = false;

  profileLink;

  constructor(private tasksService: TasksService) {}

  ngOnInit() {
    if (this.taskID) {
      this.getProfilePicture();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.taskID.previousValue != changes.taskID.currentValue) {
      if (this.taskID) {
        this.getProfilePicture();
      } else {
        this.profileLink = null;
      }
    }
  }

  getProfilePicture() {
    this.profileLink = null;

    this.profileLink = this.tasksService.getPracticeTaskPicture(this.taskID);
  }
}
