<div *ngIf="loading!==true" style="    white-space: pre-line;">
  <div class="rel" *ngIf="design=='mode1'">

    <div *ngIf="!header && isCollapsed" class="read-more-text collapse"
      [ngClass]="{'pointer-read-more': (hideButton!=true && isCollapsed ==true ) }"
      (click)="$event.stopPropagation();openReadMore();"
      [innerHTML]="URLify(currentText | clearText) | textLength:maxLength | safeHtml " [@slideInOut]>
    </div>

    <div
      class="read-more-text collapse"
      *ngIf="header && isCollapsed"
      [@slideInOut]
      [ngClass]="{ 'pointer-read-more': hideButton != true && isCollapsed == true }"
      [innerHTML]="header | clearText | textLength: maxLength | safeHtml"
      (click)="$event.stopPropagation(); openReadMore()"
    ></div>

    <div
      class="read-more-text open"
      *ngIf="!isCollapsed"
      [@slideInOut]
      [innerHTML]="URLify(currentText | clearText) | safeHtml"
    ></div>

    <hr [class.hidden]="hideToggle"/>

    <button
      class="btn-small readMoreBtn pull-right"
      [class.hidden]="hideToggle"
      (click)="$event.stopPropagation(); toggleView()"
      mat-button
    >
      Read {{ isCollapsed ? textMore : textLess }}
      <mat-icon *ngIf="isCollapsed">expand_more</mat-icon>
      <mat-icon *ngIf="!isCollapsed">expand_less</mat-icon>
    </button>
  </div>

  <div class="rel" *ngIf="design == 'mode2' && animated == true">
    <div
      class="read-more-text collapse just-text"
      *ngIf="!header && isCollapsed"
      [@slideInOut]
      [ngClass]="{ 'pointer-read-more': hideButton != true && isCollapsed == true && canCollapse == true }"
      [innerHTML]="currentText | clearText | textLength: maxLength | safeHtml"
      (click)="$event.stopPropagation(); openReadMore()"
    ></div>

    <div
      class="read-more-text collapse just-text-header"
      *ngIf="header && isCollapsed"
      [@slideInOut]
      [ngClass]="{ 'pointer-read-more': hideButton != true && isCollapsed == true && canCollapse == true }"
      [innerHTML]="header | clearText | textLength: maxLength | safeHtml"
      (click)="$event.stopPropagation(); openReadMore()"
    ></div>

    <div
      class="read-more-text open"
      *ngIf="currentText && currentText !== undefined && currentText !== 'undefined' && !isCollapsed"
    >
      <app-inner-html-isolated [content]="currentText"></app-inner-html-isolated>
    </div>
    <button
      class="btn-small readMoreBtn2 pull-right"
      *ngIf="hideButton != true && hideButtonForce != true"
      (click)="$event.stopPropagation(); toggleView()"
      mat-button
    >
      {{ isCollapsed ? textMore : textLess }}
      <mat-icon class="fas fa-sort-down icon1" *ngIf="isCollapsed"></mat-icon>
      <mat-icon class="fas fa-sort-up icon2" *ngIf="!isCollapsed"></mat-icon>
    </button>
  </div>
  <div class="rel" *ngIf="design == 'mode2' && animated != true">
    <div
      class="read-more-text collapse just-text"
      *ngIf="!header && isCollapsed"
      [ngClass]="{ 'pointer-read-more': hideButton != true && isCollapsed == true && canCollapse == true }"
      [innerHTML]="currentText | clearText | textLength: maxLength | safeHtml"
      (click)="$event.stopPropagation(); openReadMore()"
    ></div>
    <div
      class="read-more-text collapse just-text-header"
      *ngIf="header && isCollapsed"
      [ngClass]="{ 'pointer-read-more': hideButton != true && isCollapsed == true && canCollapse == true }"
      [innerHTML]="header | clearText | textLength: maxLength | safeHtml"
      (click)="$event.stopPropagation(); openReadMore()"
    ></div>

    <div
      class="read-more-text open"
      *ngIf="currentText && currentText !== undefined && currentText !== 'undefined' && !isCollapsed"
    >
      <app-inner-html-isolated [content]="currentText"></app-inner-html-isolated>
    </div>
    <button
      class="btn-small readMoreBtn2 pull-right"
      *ngIf="hideButton != true && hideButtonForce != true"
      (click)="$event.stopPropagation(); toggleView()"
      mat-button
    >
      {{ isCollapsed ? textMore : textLess }}
      <mat-icon class="fas fa-sort-down icon1" *ngIf="isCollapsed"></mat-icon>
      <mat-icon class="fas fa-sort-up icon2" *ngIf="!isCollapsed"></mat-icon>
    </button>
  </div>

  <div class="rel" *ngIf="design == 'noPropagation' && animated == true">
    <div
      class="read-more-text collapse just-text"
      *ngIf="!header && isCollapsed"
      [@slideInOut]
      [ngClass]="{ 'pointer-read-more': hideButton != true && isCollapsed == true && canCollapse == true }"
      [innerHTML]="currentText | clearText | textLength: maxLength | safeHtml"
    ></div>

    <div
      class="read-more-text collapse just-text-header"
      *ngIf="header && isCollapsed"
      [@slideInOut]
      [ngClass]="{ 'pointer-read-more': hideButton != true && isCollapsed == true && canCollapse == true }"
      [innerHTML]="header | clearText | textLength: maxLength | safeHtml"
    ></div>

    <div *ngIf="!header && isCollapsed" class="read-more-text collapse just-text"
      [ngClass]="{'pointer-read-more':(hideButton!=true && isCollapsed ==true  && canCollapse==true ) }"
      [innerHTML]="currentText | clearText | textLength:maxLength | safeHtml " [@slideInOut]>
    </div>


    <div *ngIf="header && isCollapsed" class="read-more-text collapse just-text-header"
      [ngClass]="{'pointer-read-more':(hideButton!=true && isCollapsed ==true  && canCollapse==true ) }"
      [innerHTML]="header | clearText | textLength:maxLength | safeHtml " [@slideInOut]>
    </div>


    <div class="read-more-text open"
      *ngIf="currentText && currentText!==undefined && currentText!=='undefined' && !isCollapsed">

      <app-inner-html-isolated [content]="currentText">

      </app-inner-html-isolated>
    </div>
    <button mat-button *ngIf="hideButton!=true && hideButtonForce!=true" class="btn-small readMoreBtn2 pull-right"
      (click)="$event.stopPropagation();toggleView();">
      {{isCollapsed? textMore:textLess}} <mat-icon *ngIf="isCollapsed" class="fas fa-sort-down icon1 "></mat-icon>
      <mat-icon *ngIf="!isCollapsed" class="fas fa-sort-up icon2"></mat-icon>

    </button>
  </div>
  <div class="rel" *ngIf="design == 'noPropagation' && animated != true">
    <div
      class="read-more-text collapse just-text"
      *ngIf="!header && isCollapsed"
      [ngClass]="{ 'pointer-read-more': hideButton != true && isCollapsed == true && canCollapse == true }"
      [innerHTML]="currentText | clearText | textLength: maxLength | safeHtml"
    ></div>
    <div
      class="read-more-text collapse just-text-header"
      *ngIf="header && isCollapsed"
      [ngClass]="{ 'pointer-read-more': hideButton != true && isCollapsed == true && canCollapse == true }"
      [innerHTML]="header | clearText | textLength: maxLength | safeHtml"
    ></div>


    <div *ngIf="!header && isCollapsed" class="read-more-text collapse just-text"
      [ngClass]="{'pointer-read-more': (hideButton!=true && isCollapsed ==true   && canCollapse==true) }"
      [innerHTML]="currentText  | clearText| textLength:maxLength | safeHtml ">
    </div>
    <div *ngIf="header && isCollapsed" class="read-more-text collapse just-text-header"
      [ngClass]="{'pointer-read-more':(hideButton!=true && isCollapsed ==true  && canCollapse==true ) }"
      [innerHTML]="header  | clearText| textLength:maxLength | safeHtml ">
    </div>

    <div class="read-more-text open"
      *ngIf="currentText && currentText!==undefined && currentText!=='undefined' && !isCollapsed">

      <app-inner-html-isolated [content]="currentText">

      </app-inner-html-isolated>
    </div>
    <button mat-button *ngIf="hideButton!=true && hideButtonForce!=true" class="btn-small readMoreBtn2 pull-right"
      (click)="$event.stopPropagation();toggleView();">
      {{isCollapsed? textMore:textLess}} <mat-icon *ngIf="isCollapsed" class="fas fa-sort-down icon1 "></mat-icon>
      <mat-icon *ngIf="!isCollapsed" class="fas fa-sort-up icon2"></mat-icon>

    </button>
  </div>
</div>
<div *ngIf="loading === true">
  <div class="lds-ellipsis">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
