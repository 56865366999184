import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DrugsService } from '../shared/drugs.service';

@Component({
  selector: 'app-drugs-image',
  templateUrl: './drugs-image.component.html',
  styleUrls: ['./drugs-image.component.css'],
})
export class DrugsImageComponent implements OnInit {
  @Input() drugID;

  @Input() hideZoomButton = false;

  @Output() close = new EventEmitter();

  imageLink;

  constructor(
    private drugService: DrugsService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.drugID = data;
    }
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.drugID && changes.drugID.previousValue != changes.drugID.currentValue) {
      if (this.drugID) {
        this.getPicture();
      } else {
        this.imageLink = null;
      }
    }
  }

  getPicture() {
    this.imageLink = null;
    if (this.drugID) {
      this.imageLink = this.drugService.getDrugImageStreamLink(this.drugID);
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
}
