import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'icon-view',
  templateUrl: './icon-view.component.html',
  styleUrls: ['./icon-view.component.css'],
})
export class IconViewComponent implements OnInit {
  isAwsomeFont = true;

  @Input()
  icon;

  constructor() {}

  ngOnInit() {
    this.isFontAwsome();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.isFontAwsome();
  }

  isFontAwsome() {
    if (this.icon) {
      if (this.icon.indexOf('fas ') !== -1 || this.icon.indexOf('fab-') !== -1) {
        this.isAwsomeFont = true;
      } else {
        this.isAwsomeFont = false;
      }
    } else {
      this.isAwsomeFont = false;
    }
  }
}
