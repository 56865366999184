import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { LookupService } from '../../../shared/services/lookup.service';
import { InvitationLookupComponent } from '../../invitation/invitation-lookup/invitation-lookup.component';
import { SmsConversationComponent } from '../../message/sms-conversation/sms-conversation.component';
import { SmsPromoterComponent } from '../../message/sms-promoter/sms-promoter.component';

@Component({
  selector: 'app-quotation-invitation-create',
  templateUrl: './quotation-invitation-create.component.html',
  styleUrls: ['./quotation-invitation-create.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.anim', style({ opacity: 0 }), { optional: true }),
        query(
          '.anim',
          stagger('100ms', [
            animate(
              '0.3s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.anim', style({ opacity: 1 }), { optional: true }),
        query(
          '.anim',
          stagger('100ms', [
            animate(
              '0.3s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class QuotationInvitationCreateComponent implements OnInit {
  @ViewChild(WizardComponent, { static: true }) public wizard: WizardComponent;

  headerType: String = 'quotation';

  @Input()
  membership = false;

  isAdult = true;

  messageOptions;
  dentMsg;

  invitationType: any = [];

  user = {
    firstname: '',
    title: '',
    lastname: '',
    middlename: '',
    gender: '',
    mobile: '',
    home_phone: '',
    work_phone: '',
    email: '',
  };

  isPromoterOrAdmin = false;

  genders = [];
  titles = [];
  merchantID;
  invitationID = '';

  senders = [];
  selectedSender = null;
  isSelectedSender = true;
  sendSMS = false;
  sendDate = new Date();
  sendTime = new Date();

  align;

  hasVisits = false;

  inclusions = new FormControl();

  inclusionList = ['Scale & Clean', 'Fissure Sealant', 'Whitening'];

  selectedInclusion = [];

  // VARS FOR TREATMENT SELECT

  visitPanels: Visit[] = [{ value: '0', treatmentLabelValue: '', canDelete: false }];

  constructor(private dialog: MatDialog, private router: Router, private lookupService: LookupService) {}

  ngOnInit() {
    this.lookupService.getLookup('CodeLookup', 'Gender').subscribe((data) => {
      this.genders = data;
    });
    this.lookupService.getLookup('SimpleLookup', 'Salutation').subscribe((data) => {
      this.titles = data;
    });
  }

  // fake adding and removing treatment row
  newVisitRow() {
    this.visitPanels.push(new Visit('3', 'Item Name', true));

    this.checkVisits();

    // this.nextStep();
  }

  removeVisitRow(iv) {
    const index: number = this.visitPanels.indexOf(iv);
    if (index !== -1) {
      this.visitPanels.splice(index, 1);
      this.checkVisits();
    }

    // console.log(this.visitPanels.length)
  }

  checkVisits() {
    if (this.visitPanels.length >= 2) {
      this.hasVisits = true;
    } else if (this.visitPanels.length == 1) {
      this.hasVisits = false;
    }
  }

  openPatientLookUpDialog() {
    const ref = this.dialog.open(InvitationLookupComponent, {
      data: {
        invitationID: this.invitationID,
        merchantID: this.merchantID,
        useStrict: true,
      },
      width: '600px',
    });
    const sub = ref.componentInstance.close.subscribe((data) => {
      ref.close();
    });

    ref.componentInstance.patientChat.subscribe((res) => {
      if (res && res.cardID) {
        if (res.isPromoterOrAdmin == true) {
          const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true,
            },
            panelClass: 'noCard',
            width: '500px',
          });
          ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });

          ref2.componentInstance.getResult.subscribe((data) => {
            if (data) {
              const ref3 = RootAppComponent.dialog.open(SmsConversationComponent, {
                data,
                panelClass: 'bigger-screen',
                width: '70%',
                height: '95vh',
              });
              const sub = ref3.componentInstance.close.subscribe((data) => {
                ref3.close();
              });
            }

            ref2.close();
          });
        } else {
          const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true,
            },
            panelClass: 'bigger-screen',
            width: '70%',
            height: '95vh',
          });
          const sub = ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });
        }
      }
    });
    ref.componentInstance.detailedView.subscribe((res) => {
      ref.close();
    });
  }

  invitationPage() {}

  cancel() {
    this.router.navigate(['/merchant', { outlets: { page: ['home'] } }]);
  }
}

// fake treatment array
export class Visit {
  constructor(public value: string, public treatmentLabelValue: string, public canDelete: boolean) {}
}
