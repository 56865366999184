<ipv-data-table
  [table]="reportTable.fullTable"
  [rename]="{
    treatmentValue: 'Treatment Value'
  }"
  [customComponents]="{
    treatmentValue: treatmentValue,
    fee: fee
  }"
  [formatHeaders]="{
    treatmentValue: 'right',
    fee: 'right'
  }"
  [paginate]="false"
>
</ipv-data-table>

<ng-template #treatmentValue let-record>
  <div *ngIf="record.treatmentValue > 0" style="text-align: right">
    {{ record.treatmentValue | invertNumber | customCurrency }}
  </div>
</ng-template>

<ng-template #fee let-record>
  <div *ngIf="record.fee !== 0" style="text-align: right">{{ record.fee | invertNumber | customCurrency }}</div>
</ng-template>
