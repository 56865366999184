import { Component, ElementRef, EventEmitter, Input, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { UtilsClass } from '../../types/utils/utils.class';

@Component({
  selector: 'app-generate-qr',
  templateUrl: './generate-qr.component.html',
  styleUrls: ['./generate-qr.component.css'],
})
export class GenerateQRComponent implements OnInit {
  @Input() source;

  @Input() size = 256; // in px

  @Input() downloadSize = 198; // in px

  @Input() download = new EventEmitter();

  @Input() downloadLabel=' download';


  util = new UtilsClass();

  constructor(private elRef: ElementRef) {}

  ngOnInit() {
    this.download.subscribe(() => {
      this.downloadQRCode();
    });
  }

  downloadQRCode() {
    const el = $(this.elRef.nativeElement).find('qrcode.downloadqr .qrcode canvas') as any;
    if (el) {
      const a = $('<a>').attr('href', el[0].toDataURL()).attr('download', 'QR Code: '+this.downloadLabel+'.png').appendTo('body');

      a[0].click();

      a.remove();
    }
  }
}
