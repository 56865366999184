<ng-container *ngIf="operator">
  <div class="flex full-width clearfix row">
    <div class="thrd-width row">
      <div class="full-width clear-fix">
        <app-operator-view class="full-width" [operatorID]="operatorID"></app-operator-view>
        <app-active-sessions class="full-width" [operatorID]="operatorID"></app-active-sessions>
        <app-connection-history class="full-width" [operatorID]="operatorID"></app-connection-history>
      </div>
    </div>
    <div class="ml two-thrd-width row">
      <div class="full-width clear-fix log-user-list-container">
        <app-log-user-list [userID]="operatorID" [pageSize]="30"></app-log-user-list>
      </div>
    </div>
  </div>
</ng-container>
