import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MerchantService } from '../../shared/merchant.service';

@Component({
  selector: 'sr-add-health-fund-profile-dialog',
  templateUrl: './add-dentistry-types-profile-dialog.component.html',
  styleUrls: ['./add-dentistry-types-profile-dialog.component.css'],
})
export class AddDentistryTypesProfileDialogComponent implements OnInit {
  @Output() addDentistryTypesProfile: EventEmitter<any> = new EventEmitter();

  merchantID;

  fullDentistryTypes = [];
  currentDentistryTypes = [];
  selectedDentistryTypes = {};

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private merchantService: MerchantService) {
    if (this.data) {
      this.merchantID = data.merchantID;
    }
  }

  ngOnInit(): void {
    this.merchantService.getDentistryTypesList({}).subscribe((fullDentistryTypes) => {
      this.fullDentistryTypes = fullDentistryTypes
        .sort((a, b) => (a.Label > b.Label ? 1 : -1))
        .map((dentistryType) => {
          return { ...dentistryType, Label: dentistryType.Label.toLowerCase() };
        });

      this.merchantService
        .getMerchantProfileDentistryTypesList({ Merchant_key: this.merchantID })
        .subscribe((currentDentistryTypes) => {
          this.currentDentistryTypes = currentDentistryTypes;

          this.currentDentistryTypes.forEach((currentDentistryType) => {
            const dentistry_Types_Label = currentDentistryType['Label'].toLowerCase();

            this.selectedDentistryTypes[dentistry_Types_Label] = true;
          });
        });
    });
  }

  save() {
    const newItems = [];
    const removedItems = [];

    for (const key in this.selectedDentistryTypes) {
      if (this.selectedDentistryTypes[key]) {
        const itemExistsIndex = this.currentDentistryTypes.findIndex((currentDentistryType) => {
          return currentDentistryType['Label'].toLowerCase() === key.toLowerCase();
        });

        if (itemExistsIndex < 0) {
          const itemDataIndex = this.fullDentistryTypes.findIndex((fullDentistryType) => {
            return fullDentistryType['Label'].toLowerCase() === key.toLowerCase();
          });

          const fullItemData = this.fullDentistryTypes[itemDataIndex];

          const newDentistryType = {
            Merchant_key: this.merchantID,
            DentistryType_key: fullItemData['ID'],
          };

          newItems.push(newDentistryType);
        }
      } else if (this.selectedDentistryTypes[key] === false) {
        const itemExistsIndex = this.currentDentistryTypes.findIndex((currentDentistryType) => {
          return currentDentistryType['Label'].toLowerCase() === key.toLowerCase();
        });

        if (itemExistsIndex > -1) {
          removedItems.push(this.currentDentistryTypes[itemExistsIndex].ID);
        }
      }
    }

    this.addDentistryTypesProfile.emit({
      removedDentistryTypes: removedItems,
      newDentistryTypes: newItems,
    });
  }
}
