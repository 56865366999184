<div class="row clearfix">
  <div class="animated fadeInDown pleaseChoose">
    <p>
      <strong>No merchant selected messages</strong>
    </p>
    <img class="character_warning"/>
    <p>
      Choose your desired merchant from the drop-down above to view their {{ dataName }}
    </p>
  </div>
</div>
