<div class="rel dash invit">
  <div class="card-header primary-gradient-img inModal">
    <div class="row titleArea clearfix full-width">
      <h2 class="summary-header rm-m white">
        {{ 'Direct Debit' | translate }}
      </h2>
    </div>
    <button class="btn-close btn-clear mat-button" (click)="close()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <p>
      Direct Debit is an automated process that makes your {{ "BRANDING.Brand Name" | translate | titlecase }}
      repayments from the
      bank account or debit card of your choice.
    </p>

    <p><strong>DIRECT DEBIT REQUEST</strong></p>
    <p>
      Request and Authority to debit the account named above to pay {{"BRANDING.Payments Brand Name" | translate}}
      Ltd, ACN {{"BRANDING.Payments ACN" | translate}}, User ID {{"BRANDING.Payments User ID" | translate}}
      (" {{ "BRANDING.Brand Name" | translate | titlecase }}")
    </p>

    <div class="row clearfix">
      <div class="half-width">
        <p><strong>Request and Authority to Debit:</strong></p>
      </div>
      <div class="half-width">
        <p>
          Request and Authority to Debit: You request and authorise {{ "BRANDING.Brand Name" | translate | titlecase }}
          to arrange,
          through its own financial institution, for any amount {{ "BRANDING.Brand Name" | translate | titlecase }} may
          debit or
          charge your nominated account (details provided above). This debit or charge will be made through the Bulk
          Electronic Clearing System (BECS) from your account held at the financial institution you have nominated above
          and will be subject to the terms and conditions of the Direct Debit Request Service Agreement.
        </p>
      </div>
    </div>
    <div class="row clearfix">
      <div class="half-width">
        <p><strong>Acknowledgement:</strong></p>
      </div>
      <div class="half-width">
        <p>
          By authorising this Direct Debit Request you acknowledge having read and understood the terms and conditions
          governing the debit arrangements between you and {{ "BRANDING.Brand Name" | translate | titlecase }} as set
          out in this
          Request and in your Direct Debit Request Service Agreement.
        </p>
      </div>
    </div>
    <div class="row clearfix">
      <div class="half-width">
        <p><strong>Payment Details:</strong></p>
      </div>
      <div class="half-width">
        <p>
          This authorisation is for all payments required to be made under either the
          {{ "BRANDING.Brand Name" | translate | titlecase }} Payment Plan Contract or {{"PHRASE.medical procedure" |
          translate |
          uppercase}} Loan Contract
          you have with
          {{ "BRANDING.Brand Name" | translate | titlecase }}.
        </p>
      </div>
    </div>
    <div class="row clearfix">
      <div class="half-width">
        <p><strong>Electronic Signature:</strong></p>
      </div>
      <div class="half-width">
        <p>You authorise and agree to the terms of this Direct Debit Request and the Direct Debit Service Agreement.</p>
      </div>
    </div>

    <p><strong>DIRECT DEBIT SERVICE AGREEMENT</strong></p>
    <p>
      This document provides information to you regarding the direct debiting of your account. By signing the Direct
      Debit Request (DDR) you acknowledge you have read and agree to these terms.
    </p>
    <ol>
      <li>
        <p>The DDR will be used to debit amounts due by you under your finance agreement with us.</p>
      </li>
      <li>
        <p>
          If a payment falls due on a non-business day, the amount will be debited on the next business day. If you are
          uncertain when a debit will be processed to your account, you should contact us.
        </p>
      </li>
      <li>
        <p>The purpose for which the DDR is used will not be changed without giving you at least 14 days’ notice.</p>
      </li>
      <li>
        <p>
          All information relating to you and your nominated account will be kept confidential subject to any consents
          you have granted. We may disclose information for the purpose of resolving any disputed payment or claim.
        </p>
      </li>
      <li>
        <p>You may not terminate the DDR without our consent.</p>
      </li>
      <li>
        <p>
          You may request a stop to any individual debit by giving written notice to us at least five business days
          prior to the payment due date. You may also contact your financial institution.
        </p>
      </li>
      <li>
        <p>
          You may request deferment or alteration to payments under the DDR by contacting us at least five business days
          prior to the payment due date.
        </p>
      </li>
      <li>
        <p>
          If you consider that a debit has been incorrectly made, you should contact us or your financial institution.
          We will determine whether the debit was correct, and if not, arrange for an adjustment. Claims may also be
          directed to your financial institution.
        </p>
      </li>
      <li>
        <p>
          You must ensure there are sufficient clear funds available in the nominated account to meet each debit on its
          due date. You must advise us if the account nominated by you to receive the DDR is transferred or closed.
        </p>
      </li>
      <li>
        <p>
          You must arrange a suitable alternate payment method with us if you wish to cancel the DDR and we agree to its
          cancellation.
        </p>
      </li>
      <li>
        <p>
          You must not close or alter the account being debited without our prior written consent and unless approved
          alternate payment arrangements have been made.
        </p>
      </li>
      <li>
        <p>
          If a payment is dishonoured, you may be charged fees by your financial institution, you may incur fees under
          your contract with us, and you may be in default under that contract.
        </p>
      </li>
      <li>
        <p>
          You agree that we may assign this authority to anybody who takes over the ownership or running of your
          account.
        </p>
      </li>
    </ol>
  </mat-dialog-content>
</div>