import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Observable } from 'rxjs';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { SessionsService } from '../shared/sessions.service';

@Component({
  selector: 'app-session-view-profile-picture',
  templateUrl: './session-view-profile-picture.component.html',
  styleUrls: ['./session-view-profile-picture.component.css'],
})
export class SessionViewProfilePictureComponent implements OnInit {
  @Input() sessionID: string;
  @Input() cnhID: string;
  @Input()
  hideZoomButton = false;

  profileLink;

  @Output() close = new EventEmitter();

  profilePic;

  isNewProfilePicture = false;

  constructor(private sessionService: SessionsService) {}

  ngOnInit() {
    this.getProfilePicture();

    AuthenticationService.updateProfilePicture.subscribe((res) => {
      if (res) {
        this.isNewProfilePicture = !this.isNewProfilePicture;

        this.getProfilePictureTimer();
      }
    });
  }

  getProfilePicture() {
    this.profileLink = null;

    if (this.sessionID) {
      this.profileLink = this.sessionService.getSessionProfilePicture(this.sessionID);
    } else if (this.cnhID) {
      this.profileLink = this.sessionService.getConnectionHistoryProfilePicture(this.cnhID);
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  getProfilePictureTimer() {
    this.profileLink = null;
    let countdown = 0;
    const interval = 1000;
    const duration = 3 * 1000;
    const stream$ = Observable.timer(0, interval)
      .finally(() => {
        this.getProfilePicture();
      })
      .takeUntil(Observable.timer(duration + interval))
      .map((value) => duration - value * interval);
    stream$.subscribe((value) => (countdown = value));
  }
}
