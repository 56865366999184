<ipv-container-modal [enableDragging]="false">
  <ng-container banner>
    <h2>New Service Group</h2>
  </ng-container>

  <ng-container content>
    <div class="row clearfix" style="margin-bottom: 20px">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Label</mat-label>
        <input
          class="text-center"
          id="cc-name"
          [(ngModel)]="merchantCreateService.Label"
          type="text"
          placeholder="Title"
          matInput
        />
      </mat-form-field>

      <hr class="mb"/>
    </div>
    <div class="row clearfix mb text-center">
      <mat-form-field class="full-width description" appearance="outline">
        <mat-label>Description</mat-label>
        <textarea
          class="full-width"
          [(ngModel)]="merchantCreateService.Description"
          matInput
          name="content"
          placeholder="Description"
          rows="10"
          required
        ></textarea>
      </mat-form-field>
    </div>

    <div class="full-width text-center clearfix">
      <button class="action-button" (click)="save()" mat-raised-button type="button" color="primary">
        Save
      </button>
    </div>
  </ng-container>
</ipv-container-modal>
