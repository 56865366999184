<div class="container profiles">
  <mat-card class="card details">
    <div class="tab-content clearfix" *ngIf="settings == 'templates'" @ngIfAnimation>
      <div class="tab-content-inner">
        <div class="row clearfix">
          <h3 class="sm-h3 sr-title rm-mb rm-pt">SMS Templates</h3>
          <p>
            Speed up sending SMSs by creating customised messages. You can use the tools below to create as many SMS
            Templates as you wish, and personalised tags (such as a {{ "KEYWORD.patient" | translate }}'s name, Landing
            Pages etc.) can be added using the 'insert personalisation'.
          </p>
          <hr/>
        </div>
        <div class="full-width mt">
          <app-default-message-list-overview
            class="clearfix app-default-message-list-overview-card"
            [purposeCodes]="purposeCodes"
            [inputMerchantID]="merchantID"
            [hideFilter]="hideFilter"
            [messageMode]="'sms'"
          ></app-default-message-list-overview>
        </div>
      </div>
    </div>
  </mat-card>
</div>
