/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./editor-component-list-default.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../editor-component-list/editor-component-list.component.ngfactory";
import * as i3 from "../editor-component-list/editor-component-list.component";
import * as i4 from "@angular/router";
import * as i5 from "../shared/editor-component.service";
import * as i6 from "../../../core/authentication/shared/authentication.service";
import * as i7 from "./editor-component-list-default.component";
var styles_EditorComponentListDefaultComponent = [i0.styles];
var RenderType_EditorComponentListDefaultComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EditorComponentListDefaultComponent, data: {} });
export { RenderType_EditorComponentListDefaultComponent as RenderType_EditorComponentListDefaultComponent };
export function View_EditorComponentListDefaultComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-editor-component-list", [], null, null, null, i2.View_EditorComponentListComponent_0, i2.RenderType_EditorComponentListComponent)), i1.ɵdid(1, 770048, null, 0, i3.EditorComponentListComponent, [i4.Router, i5.EditorComponentService, i6.AuthenticationService], { isDefault: [0, "isDefault"] }, null)], function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_EditorComponentListDefaultComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-editor-component-list-default", [], null, null, null, View_EditorComponentListDefaultComponent_0, RenderType_EditorComponentListDefaultComponent)), i1.ɵdid(1, 114688, null, 0, i7.EditorComponentListDefaultComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditorComponentListDefaultComponentNgFactory = i1.ɵccf("app-editor-component-list-default", i7.EditorComponentListDefaultComponent, View_EditorComponentListDefaultComponent_Host_0, {}, {}, []);
export { EditorComponentListDefaultComponentNgFactory as EditorComponentListDefaultComponentNgFactory };
