<div class="full-width white-background" [ngClass]="isModal == true ? 'modalClass' : ''">
  <mat-card class="rel sms-card clearfix" *ngIf="card && card.ID">
    <button class="btn-close btn-clear mat-button pull-right" *ngIf="isModal == true" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>


    <mat-dialog-content [ngClass]="{'no-modal-height': isModal !=true}">




      <message-input *ngIf='isContactLoaded==true' class="full-width  clearfix" [isModal]="false" [clear]="clear"
        [isContactStaff]="isContactStaff" [displayInstantOffer]="true" [message]="_message" [card]="card"
        [textLength]="textLength" [displayEmoji]="displayEmoji" [displayMessageTemplate]="displayMessageTemplate"
        [contact]="contact" [displayPatient]="displayPatient" [displayContact]="displayContact"
        [displayMerchant]="displayMerchant" [displayPromoter]="displayPromoter" [displayMarketing]="displayMarketing"
        [displayExpend]="displayExpend" [displayDentalOffers]="displayDentalOffers" [displayDocument]="displayDocument"
        [saveAsTemplate]="saveAsTemplate" [isSMSPreview]="isSMSPreview" [isDesktopPreview]="isDesktopPreview"
        [visitorsType]="visitorsType" [displaySaveAsTemplate]="displaySaveAsTemplate" [encode]="encode"
        [tableName]="tableName" [label]="label" [merchant]="merchant" (getResult)="getResultEvent($event)">
      </message-input>

      <div class="full-width" *ngIf="isContactLoaded != true" style="height: 250px">
        <h3 class="dataHeader rm-mt transparent">Loading Sender Details...</h3>
      </div>
    </mat-dialog-content>

    <div class="drop-button text-center white-background hide-750px" *ngIf="isAction == true">
      <hr *ngIf="isModal == true" />

      <button class="pull-left action-button btn-large mr" *ngIf="isModal == true" (click)="closeEvent()"
        mat-raised-button color="primary">
        Close
      </button>


      <mat-form-field class="pull-left " *ngIf="isCustomizedSender == true" style="margin-top: -2px;min-width: 400px;"
        appearance="outline">
        <mat-label>Choose a Sender*</mat-label>
        <mat-select class="full-width" [(ngModel)]="contactID" (ngModelChange)="changeDentist()" placeholder=""
          name="detnistID">

          <mat-select-trigger>
            <div class="full-width flex flex-cust" *ngIf="contact && contact.ID">

              <strong class="smr"> {{ contact.CalculatedName }}</strong> <span
                *ngIf="contact['ContactType.Label']">({{ contact['ContactType.Label'] }})</span>

              <span *ngIf="!contact['ContactType.Label']">(Staff)</span>

            </div>
          </mat-select-trigger>



          <mat-option [value]="null">
            <strong>My account</strong>
          </mat-option>

          <hr />

          <mat-option *ngFor="let p of dentists" [value]="p['ID']"
            style="          border-bottom: 1px solid #f1f1f1;      ">
            <app-card-picture class="invitationContactLookup1200000 text-center  pull-left mr" [tableName]="'Contact'"
              [cardID]="p['ID']" [hideZoomButton]="true" [LastModified]="p['LastModified']">
            </app-card-picture>

            <p class="rm-m "> <strong> {{ p.CalculatedName }}</strong></p>
            <p class="rm-m small " *ngIf="p['ContactType.Label']">{{ p['ContactType.Label'] }}</p>
            <p class="rm-m small " *ngIf="!p['ContactType.Label']">Staff</p>
          </mat-option>
        </mat-select>
      </mat-form-field>


      <button class="pull-right action-button submit-button btn-large ml"
        *ngIf="isSendSMS == true && isModulePatientSMSActive == true" [matTooltipDisabled]="getPromoterNumber()==null"
        matTooltip="Send from: {{getPromoterNumber() | customPhone}}"
        [disabled]="!convertedMessage || !cardID || !merchantID || !contactID" (click)="save()" mat-raised-button
        color="accent">
        <span *ngIf="isPromoterOrAdmin==true">Send as Promoter</span>
        <span *ngIf="isPromoterOrAdmin!=true">Send</span>
        <mat-icon class="fas fa-paper-plane ml"></mat-icon>
      </button>

      <button class="ml pull-right action-button submit-button btn-large ml"
        *ngIf="isSendSMS == true && isModulePatientSMSActive != true" [matTooltipDisabled]="getPromoterNumber()==null"
        matTooltip="Send from: {{getPromoterNumber() | customPhone}}"
        [disabled]="!convertedMessage || !cardID || !merchantID || !contactID" (click)="viewModule()" mat-raised-button
        color="accent">
        <span *ngIf="isPromoterOrAdmin==true">Send as Promoter</span>
        <span *ngIf="isPromoterOrAdmin!=true">Send</span>
        <mat-icon class="fas fa-paper-plane ml"></mat-icon>
      </button>

      <button class="pull-right action-button submit-button btn-large ml"
        [matTooltipDisabled]="getMerchantNumber()==null" matTooltip="Send from: {{getMerchantNumber() | customPhone}}"
        *ngIf="isSendSMS == true && isModulePatientSMSActive == true && isPromoterOrAdmin == true"
        [disabled]="!convertedMessage || !cardID || !merchantID || !contactID" (click)="save(true)" mat-raised-button
        color="primary">
        <span *ngIf="!merchant && merchantID">Send as {{'Merchant' | translate}}</span>
        <span *ngIf="merchant && merchant.ID">Send as {{merchant.TradingAs}}</span>
        <mat-icon class="{{'fas fa-tooth' | translate}} ml"></mat-icon>
      </button>




    </div>


    <div class="drop-button text-center white-background display-750px" *ngIf="isAction == true">
      <hr *ngIf="isModal == true" />

      <button class="pull-left action-button btn    smr" style="margin-top: 3px !important;" *ngIf="isModal == true" (click)="closeEvent()"
        mat-raised-button color="primary">
        Close
      </button>


      <mat-form-field class="pull-left  min-dentist-list100" *ngIf="isCustomizedSender == true" style="margin-top: -2px;width: 250px !important;"
        appearance="outline">
        <mat-label>Choose a Sender*</mat-label>
        <mat-select class="full-width" [(ngModel)]="contactID" (ngModelChange)="changeDentist()" placeholder=""
          name="detnistID">

          <mat-select-trigger>
            <div class="full-width flex flex-cust" *ngIf="contact && contact.ID">

              <strong class="smr"> {{ contact.CalculatedName }}</strong> <span
                *ngIf="contact['ContactType.Label']">({{ contact['ContactType.Label'] }})</span>

              <span *ngIf="!contact['ContactType.Label']">(Staff)</span>

            </div>
          </mat-select-trigger>



          <mat-option [value]="null">
            <strong>My account</strong>
          </mat-option>

          <hr />

          <mat-option *ngFor="let p of dentists" [value]="p['ID']"
            style="          border-bottom: 1px solid #f1f1f1;      ">
            <app-card-picture class="invitationContactLookup1200000 text-center  pull-left mr" [tableName]="'Contact'"
              [cardID]="p['ID']" [hideZoomButton]="true" [LastModified]="p['LastModified']">
            </app-card-picture>

            <p class="rm-m "> <strong> {{ p.CalculatedName }}</strong></p>
            <p class="rm-m small " *ngIf="p['ContactType.Label']">{{ p['ContactType.Label'] }}</p>
            <p class="rm-m small " *ngIf="!p['ContactType.Label']">Staff</p>
          </mat-option>
        </mat-select>
      </mat-form-field>


      <button class="pull-right action-button submit-button btn  sml" style="margin-top: 3px !important;"
        *ngIf="isSendSMS == true && isModulePatientSMSActive == true" [matTooltipDisabled]="getPromoterNumber()==null"
        matTooltip="Send from: {{getPromoterNumber() | customPhone}}"
        [disabled]="!convertedMessage || !cardID || !merchantID || !contactID" (click)="save()" mat-raised-button
        color="accent">
        <span *ngIf="isPromoterOrAdmin==true">Send as Promoter</span>
        <span *ngIf="isPromoterOrAdmin!=true">Send</span>
      </button>

      <button class="ml pull-right action-button submit-button btn    sml" style="margin-top: 3px !important;"
        *ngIf="isSendSMS == true && isModulePatientSMSActive != true" [matTooltipDisabled]="getPromoterNumber()==null"
        matTooltip="Send from: {{getPromoterNumber() | customPhone}}"
        [disabled]="!convertedMessage || !cardID || !merchantID || !contactID" (click)="viewModule()" mat-raised-button
        color="accent">
        <span *ngIf="isPromoterOrAdmin==true">Send as Promoter</span>
        <span *ngIf="isPromoterOrAdmin!=true">Send</span>
        <mat-icon class="fas fa-paper-plane ml"></mat-icon>
      </button>

      <button class="pull-right action-button submit-button btn    sml" style="margin-top: 3px !important;"
        [matTooltipDisabled]="getMerchantNumber()==null" matTooltip="Send from: {{getMerchantNumber() | customPhone}}"
        *ngIf="isSendSMS == true && isModulePatientSMSActive == true && isPromoterOrAdmin == true"
        [disabled]="!convertedMessage || !cardID || !merchantID || !contactID" (click)="save(true)" mat-raised-button
        color="primary">
        <span >Send as {{'Merchant' | translate}}</span>
      </button>




    </div>

  </mat-card>
</div>
