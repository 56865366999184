import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';

@Component({
  selector: 'app-marketing-campaign-create-edit-overview',
  templateUrl: './marketing-campaign-create-edit-overview.component.html',
  styleUrls: ['./marketing-campaign-create-edit-overview.component.css'],
})
export class MarketingCampaignCreateEditOverviewComponent implements OnInit {
  isPromoterOrAdmin = false;
  merchants;
  merchantID;
  operators;

  constructor(private authenticationService: AuthenticationService, private router: Router) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      if (this.isPromoterOrAdmin == true) {
        const confirmDialog = new ConfirmDialog(
          'fas fa-info',
          'New Marketing Campaign',
          'Please choose a group to create your campaign',
          'Operator',
          'Patient'
        );

        const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
          data: confirmDialog,
          width: '650px',
          disableClose: true,
        });
        ref.componentInstance.closeEvent.subscribe((res) => {
          if (res == true) {
            ref.close();
            this.router.navigate(['/merchant', { outlets: { page: ['marketing-over-view'] } }]);
          }
        });
        ref.componentInstance.onConfirm.subscribe((res) => {
          if (res == true) {
            ref.close();
            this.router.navigate(['/merchant', { outlets: { page: ['marketing-campaign-create'] } }]);
          } else {
            ref.close();
            this.router.navigate(['/merchant', { outlets: { page: ['marketing-campaign-create-operator'] } }]);
          }
        });
      }
    });
  }
}
