import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Location } from '@angular/common';
import { ExistingPatientService } from '../shared/existing-patient.service';

import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { MapViewComponent } from '../../../shared/components/map-view/map-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { ActionLogListComponent } from '../../action-log/action-log-list/action-log-list.component';
import { CustomerProspectService } from '../../customer-prospect/shared/customerProspect.service';
import { InvitationExistingPatientListComponent } from '../../invitation/invitation-existing-patient-list/invitation-existing-patient-list.component';
import { ProductService } from '../../product/shared/product.service';
import { ProductUtil } from '../../product/shared/productUtil.type';

@Component({
  selector: 'app-prospect-print-view',
  templateUrl: './prospect-print-view.component.html',
  styleUrls: ['./prospect-print-view.component.css'],
})
export class ProspectPrintViewComponent implements OnInit {
  @Input() prospectID;
  @Input() prospect;

  @Input()
  prospectObject;

  isReferral = false;
  isPromoterOrAdmin = false;
  subjectArray = [];
  isModal = false;
  @Output()
  closeModal = new EventEmitter();
  displayMapFlag = true;
  profileLink;

  isModuleNoteFeaturesActive = Settings.global['isModuleNoteFeaturesActive'];
  isModuleTreatmentPlanActive = Settings.global['isModuleTreatmentPlanActive'];

  productUtil = new ProductUtil();
  financedProductGroup;

  isCustomer = false;

  sessionType = 'guest';

  constructor(
    private location: Location,
    private activeRoute: ActivatedRoute,
    private ExistingPatientService: ExistingPatientService,
    private authenticationService: AuthenticationService,
    private customerProspectService: CustomerProspectService,
    private router: Router,
    private utilService: UtilsService,
    private productService: ProductService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.prospectID = data;

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      this.authenticationService.isCustomer().subscribe((isCustomer) => {
        this.isCustomer = isCustomer;

        this.utilService.getCurrentAccess().subscribe((access) => {
          if (access) {
            this.isModuleNoteFeaturesActive = access['isModuleNoteFeaturesActive'];
            this.isModuleTreatmentPlanActive = access['isModuleTreatmentPlanActive'];
          }

          this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
            this.isPromoterOrAdmin = res;
          });
          this.activeRoute.params.subscribe((params) => {
            if (params['prospectID']) {
              this.prospectID = params['prospectID'];
            }
            if (this.prospectObject != null && typeof this.prospectObject == 'object') {
              this.ExistingPatientService.getProspectDetails(this.prospectObject['ID'], {}, this.sessionType).subscribe(
                (res) => {
                  if (res) {
                    this.prospect = res;

                    this.prospectID = this.prospectObject['ID'];

                    this.getPicture();
                  }
                }
              );
            } else if (this.prospectID) {
              this.ExistingPatientService.getProspectDetails(this.prospectID, {}, this.sessionType).subscribe((res) => {
                if (res) {
                  this.prospect = res;
                  this.getPicture();
                }
              });
            }

            const p1 = {
              isFinance: true,
            };

            this.productService.getMerchantProductGroup(p1, this.isPromoterOrAdmin).subscribe((res) => {
              this.financedProductGroup = res;
            });
          });
        });
      });
    });
  }

  getPicture() {
    if (this.prospectID) {
      this.getProfilePicture();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.prospectID && changes.prospectID.previousValue != changes.prospectID.currentValue) {
      if (this.prospectID) {
        this.ExistingPatientService.getProspectDetails(this.prospectID, {}, this.sessionType).subscribe((res) => {
          if (res) {
            this.prospect = res;

            this.getPicture();
          }
        });
      }
    }
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  // populateDetailCard(ID) {
  //   this.router.navigate([
  //     "/merchant",
  //     { outlets: { 'page': ["prospect-details-card-edit", ID] } }
  //   ]);
  //   this.dialogRef.close();
  // }

  editDetails(id) {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['customer-profile', id, 'patient'],
        },
      },
    ]);
    this.closeModal.emit(true);
  }

  displayMap() {
    const location = {
      zoom: 17,
      postCode: this.prospect['addresses.Postcode'],
      state: this.prospect['addresses.State'],
      streetType: this.prospect['addresses.Street Type'],
      streetName: this.prospect['addresses.Street Name'],
      streetNumber: this.prospect['addresses.Street Nr'],
      suburb: this.prospect['addresses.Suburb'],
      country: this.prospect['addresses.Country.Code'],
      addressLabel: this.prospect['addresses.Calculated'],
      latitude: this.prospect['Address.Latitude'],
      longitude: this.prospect['Address.Longitude'],
    };

    const ref = RootAppComponent.dialog.open(MapViewComponent, {
      data: location,
      panelClass: 'modal-map',
      width: '800px',
      height: '600px',
    });

    ref.componentInstance.addCoordinate.subscribe((res) => {
      if (res && res.latitude && res.longitude) {
        const payload = {
          latitude: res.latitude,
          longitude: res.longitude,
        };
        this.ExistingPatientService.addCoordinate(this.prospectID, payload).subscribe((res) => {});
      }
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  contact() {
    const data = {
      targetType: 'prospect',
      targetID: this.prospectID,
      asGuest: false,
      asProfile: true,
      asPractice: true,
      subjectArray: this.subjectArray,
    };
    AuthenticationService.contactInputEvent.emit(data);
  }

  viewProspectLogs(ID) {
    const ref = RootAppComponent.dialog.open(ActionLogListComponent, {
      data: {
        targetID: ID,
      },
      width: '900px',
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  close() {
    this.closeModal.emit(true);
  }

  notFoundEvent(e) {
    if (e == null) {
      this.displayMapFlag = false;
    } else {
      this.displayMapFlag = !e;
    }
  }

  linkToInvitation(p) {
    if (p && p['GroupCode']) {
      const payload = {
        productGroupCode: p['GroupCode'],
      };
      this.customerProspectService.linkCustomerToInv(this.prospect['ID'], payload).subscribe((res) => {
        if (res.success) {
          this.router.navigate(['/merchant', { outlets: { page: ['invitation-create', res.data] } }]);
        }
      });
    }
  }

  archivePatient() {
    const confirmDialog = new ConfirmDialog(
      'delete',
      'Archive Patient?',
      '<p><strong>Are you sure?</strong></p><p class="noFurther">He/She will not recieve any further notifications.</p>',
      'No',
      'Yes, archive'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        const payload = {
          firstName: this.prospect.FristName,
          lastName: this.prospect.Name,
          status: 'Archived',
        };

        this.ExistingPatientService.editProspect(this.prospect['ID'], payload).subscribe((res) => {
          if (res) {
            NotifyAppComponent.displayToast(
              'success',
              'Successful operation',
              'The patient has been successfully archived'
            );

            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }

  patientInvitation() {
    const ref = RootAppComponent.dialog.open(InvitationExistingPatientListComponent, {
      data: this.prospect['ExistingPerson_key'],
      width: '80%',
      panelClass: 'noCard',
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  getProfilePicture() {
    this.profileLink = null;

    this.profileLink = this.ExistingPatientService.getProfilePicStreamLink(this.prospectID);
  }
}
