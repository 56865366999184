import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { PatientService } from '../shared/patient.service';

@Component({
  selector: 'app-patient-card-defer-modal',
  templateUrl: './patient-card-defer-modal.component.html',
  styleUrls: ['./patient-card-defer-modal.component.css'],
})
export class PatientCardDeferModalComponent implements OnInit {
  @Input() invID = '';

  @Input() force = false;

  @Output()
  done: EventEmitter<any> = new EventEmitter();

  @Output()
  invitation: EventEmitter<any> = new EventEmitter();

  choice: any;
  holdOffs = [
    { value: 'P_L1x', viewValue: 'One Week' },
    { value: 'P_L2x', viewValue: 'Two Weeks' },
    { value: 'P_L3x', viewValue: 'Three Weeks' },
    { value: 'P_L4x', viewValue: 'Four Weeks' },
  ];

  constructor(private patientService: PatientService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.invID = data.invitationID;
    if (data.force != null) {
      this.force = data.force;
    }
  }

  ngOnInit() {}

  holdInv() {
    this.patientService.holdInivation(this.invID, { choice: this.choice.value }, this.force).subscribe((res) => {
      NotifyAppComponent.displayToast(
        'success',
        'Successfull operation',
        'Your invitation will be held off for ' + this.choice.viewValue
      );

      this.done.emit(this.choice.viewValue);
      this.invitation.emit(res);
    });
  }
}
