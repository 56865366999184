import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { tap } from 'rxjs/operators';
import { labelAnimation } from '../../../shared/animations/label.animation';
import { onChangeAnimation } from '../../../shared/animations/onChange.animation';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { UtilsService } from '../../../shared/services/utils.service';
import { SettlementService } from '../shared/settlement.service';
import { SettlementDueSettlementsReportService } from './shared/services/settlement-due-settlements-report.service';
import {
  DueSettlementsReportRequest,
  DueSettlementsReportRequestOptionals,
} from './shared/types/due-settlements-report.type';

@Component({
  selector: 'ipv-settlement-payments-report',
  templateUrl: './settlement-due-settlements-report.page.html',
  styleUrls: ['./settlement-due-settlements-report.page.css'],
  animations: [labelAnimation, onChangeAnimation],
  providers: [CustomDatePipe, SettlementDueSettlementsReportService],
})
export class SettlementDueSettlementsReportPage implements OnInit, OnDestroy {
  dueSettlementsReport$ = this.settlementService.getDueSettlementsReport$().pipe(
    tap((dueSettlementsReport) => {
      if (dueSettlementsReport) {
        this.summaryRecordCount = dueSettlementsReport.Date_Due.length;
        this.summaryTotal = dueSettlementsReport.Total_Due_Amount;
      }
    })
  );

  startDate: string;
  endDate: string;

  showEndDate = true;

  initialStartDate = new Date();
  initialEndDate = new Date();

  summaryTotal = 0;
  summaryRecordCount = 0;

  constructor(
    private router: Router,
    private settlementService: SettlementService,
    private customDatePipe: CustomDatePipe,
    private utilsService: UtilsService,
    private settlementDueSettlementsReportService: SettlementDueSettlementsReportService
  ) {}

  ngOnInit() {
    if (new Date().getDay() !== 5) {
      this.initialStartDate = this.utilsService.getNextFriday();
      this.initialEndDate = this.utilsService.getNextFriday();
    }
  }

  ngOnDestroy() {
    // required for untilDestroyed();
  }

  setStartDate(startDate: string) {
    if (startDate) {
      this.startDate = startDate;
      this.initialStartDate = new Date(this.startDate);
      this.updateDueSettlementsReport();
    }
  }

  setEndDate(endDate: string) {
    if (endDate) {
      this.endDate = endDate;
      this.initialEndDate = new Date(this.endDate);
      this.updateDueSettlementsReport();
    }
  }

  /**
   * Check that the payload has the right combination of properties to be accepted by the server
   */
  isValidPayload(payload: DueSettlementsReportRequest) {
    if (payload.StartDate && payload.EndDate) {
      return true;
    } else {
      return false;
    }
  }

  updateDueSettlementsReport() {
    let optional: DueSettlementsReportRequestOptionals = {};

    optional = this.endDate ? { ...optional, endDate: this.endDate } : optional;

    const payload = new DueSettlementsReportRequest(this.startDate, optional);

    if (this.isValidPayload(payload)) {
      this.settlementService
        .fetchDueSettlementsReport$(payload)
        .pipe(
          untilDestroyed(this),
          tap((dueSettlementsReport) => {
            this.settlementService.setDueSettlementsReport(dueSettlementsReport);
          })
        )
        .subscribe();
    }
  }

  openTablePrint() {
    this.settlementDueSettlementsReportService.setPrintButtonClicked(true);
  }
}
