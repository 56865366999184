import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';

@Component({
  selector: 'landing-page-new-modal',
  templateUrl: './landing-page-new-modal.component.html',
  styleUrls: ['./landing-page-new-modal.component.css'],
})
export class LandingPageNewModalComponent implements OnInit {
  @Output()
  close = new EventEmitter();

  isPromoterOrAdmin = false;

  getType = new EventEmitter();

  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });
  }

  sendType(t) {
    if (t) {
      this.getType.emit(t);
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
}
