<div class="card-header primary-gradient-img clearfix inModal">
  <div class="row clearfix">
    <button class="btn-close btn-clear mat-button white" (click)="close()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="row titleArea clearfix">
      <mat-icon class="pull-left">send</mat-icon>
      <h2 class="summary-header rm-m" *ngIf="isCommunication==true">
        Create new Communication campaign
        <span class="subLabel">Please select type of campaign</span>
      </h2>

      <h2 class="summary-header rm-m" *ngIf="isCommunication!=true">
        Create new campaign
        <span class="subLabel">Please select type of campaign</span>
      </h2>
    </div>
  </div>
</div>

<mat-dialog-content>
  <div class="row clearfix flex flex-wrap" *ngIf="isCommunication!=true" [class.promoter]="isPromoterOrAdmin">
    <button class="selection-btn button gradient purple animated zoomIn faster" [disabled]="!patientCampaignActive"
      (click)="triggerNewPatientCampaign()" mat-raised-button>
      <div class="btn-container">
        <img
          src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/btn-icon-merketing-2.png" />
        <h3 class="">{{"KEYWORD.patient" | translate | titlecase}} campaign
          <mat-icon>chevron_right</mat-icon>
        </h3>
        <p class="small">Send a bulk email campaign that links to a {{ "KEYWORD.patient" | translate }} landing page</p>
      </div>
    </button>

    <button class="selection-btn button gradient red animated zoomIn faster delay-0-3s"
      [disabled]="!socialCampaignActive" (click)="triggerNewSocialCampaign()" mat-raised-button>
      <div class="btn-container">
        <img
          src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/btn-icon-merketing-1.png" />
        <h3 class="">Social media post <mat-icon>chevron_right</mat-icon>
        </h3>
        <p class="small">Create and share a post to your social media account</p>
      </div>
    </button>

    <button class="selection-btn button gradient blue animated zoomIn faster delay-0-6s"
      [disabled]="!blogCampaignActive" (click)="triggerNewBlogCampaign()" mat-raised-button>
      <div class="btn-container">
        <img
          src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/btn-icon-merketing-3.png" />
        <h3 class="">
          Blog post
          <mat-icon>chevron_right</mat-icon>
        </h3>
        <p class="small">Create a new article within the blog</p>
      </div>
    </button>
  </div>
  <div class="row clearfix flex flex-wrap" *ngIf="isCommunication==true">
    <button class="selection-btn button gradient purple animated zoomIn faster" [disabled]="!patientCampaignActive"
      (click)="bulkSMS()" mat-raised-button>
      <div class="btn-container">
        <img
          src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/conversation_2704167+(1).png" />
        <h3 class="">{{"KEYWORD.patient" | translate | titlecase}} campaign
          <mat-icon>chevron_right</mat-icon>
        </h3>
        <p class="small">Send a bulk <strong>customized SMS</strong> campaign to a {{ "KEYWORD.patient" | translate }}
        </p>
      </div>
    </button>

    <button class="selection-btn button gradient purple animated zoomIn faster" [disabled]="true" (click)="bulkEmail()"
      mat-raised-button>
      <div class="btn-container">
        <img src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/email_873388.png" />
        <h3 class="">{{"KEYWORD.patient" | translate | titlecase}} campaign
          <mat-icon>chevron_right</mat-icon>
        </h3>
        <p class="small">Send a bulk <strong>customized Email</strong> campaign to a {{ "KEYWORD.patient" | translate }}
        </p>
      </div>
    </button>


  </div>

</mat-dialog-content>
