import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { DocumentUrlViewComponent } from '../../../shared/components/document-url-view/document-url-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-default-message-list-overview',
  templateUrl: './default-message-list-overview.component.html',
  styleUrls: ['./default-message-list-overview.component.css'],
})
export class DefaultMessageListOverviewComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();

  @Input() inputMerchantID;
  @Input() hideFilter = false;
  @Input() isGlobal = true;
  @Input() pageSize = Settings.global['listPageSize'] || 20;
  @Input() purposeCode;
  @Input() productGroupCode;
  @Input() purposeCodes;
  @Input() notPurposeCodes;
  @Input() productGroupCodes;
  @Input() title = 'Personal Messages';
  @Input() messageMode: 'default' | 'sms' | 'all' = 'default';
  description;
  isPromoterOrAdmin = false;
  merchantID = 'none';

  isLoaded = false;

  displayResult = true;
  type;

  constructor(private authenticationService: AuthenticationService, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    const messageMode = this.activatedRoute.snapshot.params['messageMode'];

    this.type = this.activatedRoute.snapshot.params['type'];

    this.messageMode = messageMode ? messageMode : this.messageMode;

    this.authenticationService.isPromoterOrAdmin().subscribe((r) => {
      this.isPromoterOrAdmin = r;

      this.authenticationService.getCurrentPractice().subscribe((m) => {
        if (m && m.ID) {
          this.merchantID = m ? m.ID : this.inputMerchantID;
        }
        this.isLoaded = true;
      });
    });
  }

  applySearch(result) {
    if (this.hideFilter === false) {
      this.merchantID = result.merchantID;
    }

    this.displayResult = false;
    setTimeout(() => {
      this.displayResult = true;
    }, 500);

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  viewVideoInfo(videoTitle, docUrl) {
    const ref = RootAppComponent.dialog.open(DocumentUrlViewComponent, {
      data: {
        title: videoTitle,
        description: '',
        documentUrl: docUrl,
      },
      width: '800px',
      panelClass: 'noCard',
    });
    const sub = ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
  }
}
