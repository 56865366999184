import { Settings } from '../../../shared/types/settings';

export class DefaultMessageUtilClass {
  settings = Settings.global;



  patientPersonalisation = [
    {
      id: 'Patient Title/Salutation',
      value: '{{PatientSalutation}}',
    },
    {
      id: 'Patient First Name',
      value: '{{PatientFirstName}}',
    },
    {
      id: 'Patient Middle Name',
      value: '{{PatientMiddleName}}',
    },
    {
      id: 'Patient Last Name',
      value: '{{PatientLastName}}',
    },
    {
      id: 'Patient Mobile Number',
      value: '{{PatientMobileNumber}}',
    },
    // {
    //   id: "Patient Home Phone Number",
    //   value: "{{PatientHomePhoneNumber}}"
    // },
    {
      id: 'Patient Work Phone Number',
      value: '{{PatientWorkPhoneNumber}}',
    },
    {
      id: 'Patient Email',
      value: '{{PatientEmail}}',
    },
    // {
    //   id: "Patient Address",
    //   value: "{{PatientAddress}}"
    // },
  ];

  dentistPersonalisation = [
    {
      id: 'Dentist Title/Salutation',
      value: '{{DentistSalutation}}',
    },
    {
      id: 'Dentist First Name',
      value: '{{DentistFirstName}}',
    },
    {
      id: 'Dentist Middle Name',
      value: '{{DentistMiddleName}}',
    },
    {
      id: 'Dentist Last Name',
      value: '{{DentistLastName}}',
    },
    {
      id: 'Dentist Mobile Number',
      value: '{{DentistMobileNumber}}',
    },
    {
      id: 'Dentist Phone Number',
      value: '{{DentistPhoneNumber}}',
    },
    {
      id: 'Dentist Email',
      value: '{{DentistEmail}}',
    },
    {
      id: 'Dentist Address',
      value: '{{DentistAddress}}',
    },
  ];

  practicePersonalisation = [
    {
      id: 'Practice Legal Name',
      value: '{{PracticeLegalName}}',
    },
    {
      id: 'Practice Trading As',
      value: '{{PracticeTradingAs}}',
    },
    {
      id: 'Practice Phone Number',
      value: '{{PracticePhoneNumber}}',
    },
    {
      id: 'Practice Mobile Number',
      value: '{{PracticeMobileNumber}}',
    },
    {
      id: 'Practice Address',
      value: '{{PracticeAddress}}',
    },
    {
      id: 'Practice ABN',
      value: '{{PracticeABN}}',
    },
    {
      id: 'Practice ACN',
      value: '{{PracticeACN}}',
    },
    {
      id: 'Practice Email',
      value: '{{PracticeEmail}}',
    },
  ];

  replaceAndAddWords = (source, word_to_replace, wordsToAdd) => {
    const result = [];


      source.forEach((item) => {
        result.push({
          ...item,
          id: item.id.replace(word_to_replace, wordsToAdd),
          value: item.value.replace(word_to_replace, wordsToAdd),
        });
      });


    return result;
  };


  constructor() {}

  ngOnInit() {}

  getPatientPersonalisation() {
    if (this.settings && this.settings['context'] === 'p4p') {

      return this.replaceAndAddWords(this.patientPersonalisation,'Patient','Client') ;
    } else if (this.settings && this.settings['context'] === 'breeze') {

      return this.replaceAndAddWords(this.patientPersonalisation,'Patient','Customer') ;
    } else {
      return this.patientPersonalisation;
    }
  }
  getDentistPersonalisation() {
    if (this.settings && this.settings['context'] === 'p4p') {

      return this.replaceAndAddWords(this.dentistPersonalisation,'Dentist','Veterinarian');
    } else if (this.settings && this.settings['context'] === 'breeze') {

      return this.replaceAndAddWords(this.dentistPersonalisation,'Dentist','Dealer');
    } else {
      return this.dentistPersonalisation;
    }
  }


  getMerchantPersonalisation() {
    if (this.settings && this.settings['context'] === 'p4p') {

      return this.practicePersonalisation;
    } else if (this.settings && this.settings['context'] === 'breeze') {

      return this.replaceAndAddWords(this.practicePersonalisation,'Practice','Merchant');
    } else {
      return this.practicePersonalisation;
    }
  }



  getGroupLabel(code) {
    if (!code) {
      return '';
    }

    const labelObject = {
      SPCM: 'Specialists - Membership',
      GPM: 'General Practitioners - Membership',
      GPF: 'General Practitioners - Finance',
      SPCF: 'Specialists - Finance',
    };

    if (labelObject[code]) {
      return labelObject[code];
    } else {
      return code;
    }
  }

  getPurposeLabel(code) {
    if (!code) {
      return '';
    }

    const labelObject = {
      MerInv: 'Invitations by Merchants',
      MerInv_Mem: 'Membership Invitations by Merchants',
      MerInv_Chk: 'Checkup Invitations by Merchants',
      ActLog: 'Action Logs',
      MarCust: 'Marketing to Customers',
      MarMer: 'Marketing to Merchants',
      MarMerPros: 'Marketing to Merchant Prospects',
      Contacts: 'Contacts',
      GoogleRev: 'Google Review Invitation',
    };
    if (labelObject[code]) {
      return labelObject[code];
    } else {
      return code;
    }
  }
}
