<mat-card class="card" *ngIf="settlementtKey && settlement">
  <form #modifyForm="ngForm" name="form">
    <div class="pull-right">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear mat-button" (click)="closeDialog()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>

    <div class="colour-stripe"></div>
    <p class="help-label">Please enter the details of your settlement..</p>
    <div class="row clearfix">
      <!-- <div class="half-width"> -->
      <div class="column">
        <div class="icons-col">
          <mat-icon>local_atm</mat-icon>
        </div>
        <div class="row-content-col">
          <div class="clearfix">
            <div class="half-width">
              <div class="col-left">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Settlement Amount</mat-label>

                  <input
                    [(ngModel)]="settlement.settlementAmount"
                    name="SettlementAmount"
                    currencyMask
                    type="number"
                    matInput
                    placeholder="Settlement Amount"
                    required
                  />
                </mat-form-field>

                <div class="full-width">
                  <label>Invoice Date </label> {{ settlement['invoiceDate'] | customDate }}
                </div>
              </div>
            </div>
            <div class="half-width">
              <div class="col-right">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Invoice Amount</mat-label>
                  <input
                    [(ngModel)]="settlement.invoiceAmount"
                    name="InvoiceAmount"
                    currencyMask
                    type="number"
                    matInput
                    placeholder="Invoice Amount"
                    required
                  />
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Invoice Reference</mat-label>
                  <input
                    [(ngModel)]="settlement.invoiceReference"
                    [disabled]="'true'"
                    name="invoiceReference"
                    type="text"
                    matInput
                    placeholder="Invoice Reference"
                    required
                    required
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="row full-width">
          <div class="margin-btm">
            <mat-checkbox [(ngModel)]="settlement.isFinalized" name="isFinalized" color="primary">
              Is the Settlement Finalized?
            </mat-checkbox>
          </div>

          <hr class="mt mb"/>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Description</mat-label>
            <textarea
              [(ngModel)]="settlement.invoiceDescription"
              [disabled]="'true'"
              name="message"
              matInput
              placeholder="Description"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
      <hr class="mt mb"/>
      <div class="clearfix full-width text-center">
        <button class="btn-large" [disabled]="!modifyForm.form.valid" (click)="edit()" mat-raised-button color="accent">
          Edit Settlement
        </button>
      </div>
    </div>
  </form>
</mat-card>
