import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Settings } from '../../../../../../common/shared/types/settings';

@Component({
  selector: 'app-consumer-redirection',
  template: ``,
  styles: [],
})
export class ConsumerRedirectionComponent implements OnInit {
  settings = Settings.global;

  constructor(private router: Router) {}

  ngOnInit() {
    if (this.router && this.router.url) {
      const fileLink = this.router.url;

      if (fileLink[0] === '/') {
        window.location.href = this.settings['consumerFrontendLink'] + fileLink;
      } else {
        window.location.href = this.settings['consumerFrontendLink'] + '/' + fileLink;
      }
    }
  }
}
