<span *ngIf="isSideMenu == false">
  <!-- normal invitations -->
  <button
    class="inline btn-new-invite gradient red"
    id="newTreatmentInvite"
    [matMenuTriggerFor]="extendedInviteMenu"
    [class.btn-large]="btnLarge == true"
    mat-raised-button
    color="accent"
    [matTooltip]="'Create' + ('KEYWORD.treatment' | translate | titlecase) + 'Group'"
  >
    <mat-icon [class.sh]="!title">{{ icon }}</mat-icon>
    <mat-icon class="small" *ngIf="!title" [class.sh]="!title">play_arrow</mat-icon>
    <span *ngIf="title">{{ title }}</span>
  </button>
</span>

<span *ngIf="isSideMenu == true">
  <!-- side menu invite button -->
  <button class="sideMenuButton" [matMenuTriggerFor]="extendedInviteMenu" mat-button>
    <mat-icon>add</mat-icon>
    {{ title }}
  </button>
</span>

<!-- normal invitation menu -->
<mat-menu class="wideMenu navHeadMenu" #extendedInviteMenu="matMenu">
  <button (click)="newCategory()" mat-menu-item>New {{ "KEYWORD.treatment" | translate | titlecase }}
    <strong>Category</strong>
  </button>

  <mat-divider></mat-divider>
  <button (click)="newType()" mat-menu-item>New {{ "KEYWORD.treatment" | translate | titlecase }}<strong>Type</strong>
  </button>
  <mat-divider></mat-divider>

  <button (click)="newBrand()" mat-menu-item>New {{ "KEYWORD.treatment" | translate | titlecase }}<strong>Brand</strong>
  </button>
  <mat-divider></mat-divider>

  <button (click)="newProduct()" mat-menu-item>New {{ "KEYWORD.treatment" | translate | titlecase }}
    <strong>Product</strong>
  </button>
  <mat-divider></mat-divider>

  <button (click)="newMedication()" mat-menu-item>New <strong>Medication</strong></button>
  <mat-divider></mat-divider>

  <button (click)="newDocument()" mat-menu-item>New {{ "KEYWORD.treatment" | translate | titlecase }}
    <strong>Document</strong>
  </button>
  <mat-divider></mat-divider>
</mat-menu>
