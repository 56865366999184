<div class="rel editDetails">
  <div class="float-close">
    <button class="btn-close btn-clear mat-button" matDialogClose>
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <mat-card class="card">
    <form #customerDetails="ngForm" *ngIf="customer">
      <mat-dialog-content>
        <h2 class="mat-h2 sr-title">Address Details</h2>

        <div class="row clearfix">
          <mat-select class="full-width" name="addressLabels" placeholder="Type of Address">
            <mat-option *ngFor="let a of addressLabels" [value]="a.name">
              {{ a.name }}
            </mat-option>
          </mat-select>
          <hr class="mb"/>
          <app-address-input
            [displayMap]="true"
            [postCode]="myPostCode"
            [state]="myState"
            [suburb]="mySuburb"
            [streetNumber]="myStreetNumber"
            [streetName]="myStreetName"
            [unitNumber]="myUnitNumber"
            [streetType]="myStreetType"
            [latitude]="myLatitude"
            [longitude]="myLongitude"
            (getAdress)="getAdress($event)"
            (validate)="isAdressValidEvent($event)"
          ></app-address-input>
        </div>
      </mat-dialog-content>

      <div class="full-width text-center">
        <!-- <button mat-raised-button (click)="editDetail()" color="accent" [disabled]="!customerDetails.form.valid || isAdressValid==false || validateEmail==false || validatePhone==false">Save</button> -->
        <button mat-raised-button color="accent">Save Address</button>
      </div>
    </form>
  </mat-card>
</div>
