<!-- small round fab -->
<button
  class="print-button fab"
  *ngIf="buttonType == 'fab'"
  (click)="clickEvent()"
  mat-mini-fab
  color="primary"
  matTooltip="Print page"
>
  <span class="print-icon"></span>
</button>
<!-- small square icon -->
<button
  class="small print-button"
  *ngIf="buttonType == 'small'"
  (click)="clickEvent()"
  mat-raised-button
  color="primary"
  matTooltip="Print page"
>
  <span class="text">{{ label }}</span> <span class="print-icon"></span>
</button>
<!-- small wide with text -->
<button class="wide print-button" *ngIf="buttonType == 'wide'" (click)="clickEvent()" mat-raised-button color="primary">
  <span class="text">{{ label }}</span> <span class="print-icon"></span>
</button>
<!-- large wide with text -->
<button
  class="wide btn-large print-button"
  *ngIf="buttonType == 'large'"
  (click)="clickEvent()"
  mat-raised-button
  color="primary"
>
  <span class="text">{{ label }}</span> <span class="print-icon"></span>
</button>
