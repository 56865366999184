import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { map } from 'rxjs/operators';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { SideMenuService } from '../../../shared/services/side-menu.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { EventLogListComponent } from '../../action-log/event-log-list/event-log-list.component';
import { ContractService } from '../../contract/shared/contract.service';
import { InvitationViewModalComponent } from '../../invitation/invitation-view-modal/invitation-view-modal.component';
import { InvitationService } from '../../invitation/shared/services/invitation.service';
import { MessageTimelineComponent, MessageType } from '../../message/message-timeline/message-timeline.component';
import { TextMessageCreateComponent } from '../../message/text-message-create/text-message-create.component';
import { NotesModal, NotesModalConfig } from '../../notes/modals/notes-modal/notes.modal';
import { ProductViewModalComponent } from '../../product/product-view-modal/product-view-modal.component';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { WakandaFilesComponent } from '../../invitation/wakanda-files-view/wakanda-files-view.component';
import { environment } from 'environments/environment';
import { CustomerProspectViewModelComponent } from '../../customer-prospect/customer-prospect-view-model/customer-prospect-view-model.component';
import { SmsPromoterComponent } from '../../message/sms-promoter/sms-promoter.component';
import { SmsConversationComponent } from '../../message/sms-conversation/sms-conversation.component';
import { DentistViewComponent } from '../../dentist/dentist-view/dentist-view.component';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';

@Component({
  selector: 'app-quotation-list-global',
  templateUrl: './quotation-list-global.component.html',
  styleUrls: ['./quotation-list-global.component.css'],
  providers: [CustomDatePipe],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class QuotationListGlobalComponent implements OnInit {
  @Input()
  dateFrom;

  @Input()
  dateTo;

  @Input()
  merchantID;

  @Input()
  customerID;

  @Input()
  status;

  @Input()
  showDetailInList = false;

  @Output()
  createdSettlement = new EventEmitter();

  @Output()
  myQuotationID = new EventEmitter();

  @Output()
  detailsView = new EventEmitter();

  displayQuotationDetailView = false;
  displayPersonalCirView = false;
  items = [
    'invitation',
    'contract',
    'customer',
    'dentist',
    'merchant',
    'instantOffer',
    'appointment',
    'campaign',
    'landingPage',
    'settlement',
    'note',
  ];
  quotationID = null;

  @Input()
  pageSize = Settings.global['listPageSize'] || 20;

  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  utils = new UtilsClass();
  displayedColumns = [
    'ApplicationDate',
    'Merchant',
    'CustomerName',
    // "Merchant",
    // "Funder",
    // "CustomerMobile",
    // "CustomerEmail",
    // "ProductName",
    // "LoanPurpose",
    // "CapacityCheckStatus",
    // "CreditCheckStatus",
    'Status',
    'Product',
    'AmountInvoice',
    'AmountFinanced',
    'timeElapsed',
    // "AmountLoan",
    // "AmountOriginal",
    'Actions',
  ];

  filters = [];

  searchVals = new FormControl();

  searchValList = [
    'Application Date',
    'Status',
    'Customer FullName',
    'Mobile',
    'Email',
    'Product Name',
    'Loan Purpose',
    'CapacityCheck Status',
    'CreditCheck Status',
  ];

  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  filteredSize = null;

  isPromoterOrAdmin = false;

  title;
  dateNow;

  destroyEvent = new EventEmitter();

  isModulePatientSMSActive = Settings.global['isModulePatientSMSActive'];
  isModuleNoteFeaturesActive = Settings.global['isModuleNoteFeaturesActive'];
  isModuleExperimentalFeaturesActive = Settings.global['isModuleExperimentalFeaturesActive'];
  isModuleTreatmentTemplateActive = Settings.global['isModuleTreatmentTemplateActive'];
  isModuleAppointmentActive = Settings.global['isModuleAppointmentActive'];

  isModulePatientPortalAccessActive = Settings.global['isModulePatientPortalAccessActive'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private utilsService: UtilsService,
    private contractService: ContractService,
    private dialog: MatDialog,
    private router: Router,
    private customDate: CustomDatePipe,
    private authenticationService: AuthenticationService,
    private invitationService: InvitationService,
    private utilService: UtilsService,

    private customPhonePipe: CustomPhonePipe
  ) {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });
  }

  ngOnInit() {
    this.utilsService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleNoteFeaturesActive = access['isModuleNoteFeaturesActive'];
        this.isModuleTreatmentTemplateActive = access['isModuleTreatmentTemplateActive'];
        this.isModuleExperimentalFeaturesActive = access['isModuleExperimentalFeaturesActive'];
        this.isModuleAppointmentActive = access['isModuleAppointmentActive'];
        this.isModulePatientPortalAccessActive = access['isModulePatientPortalAccessActive'];
        this.isModulePatientSMSActive = access['isModulePatientSMSActive'];
      }
    });

    SideMenuService.goBack.subscribe((res) => {
      if (res == true) {
        this.cancelQuotationDetailView();
        this.cancelPersonalCircumstanceView();
      }
    });

    this.utilService.getServerDate().subscribe((res) => {
      this.dateNow = res;
    });

    // const params = {
    //   merchantID: this.merchantID,
    //   dateFrom: this.dateFrom,
    //   dateTo: this.dateTo,
    //   customerID: this.customerID,
    //   status: this.status,
    // };

    // this.listDB = new LoadRecords(
    //   this.invitationService,
    //   this.destroyEvent,
    //   this.isPromoterOrAdmin,
    //   params,
    //   this.customPhonePipe
    // );
    // this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
    this.getTitle();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.utilService.getServerDate().subscribe((res) => {
      this.dateNow = res;
    });

    const params = {
      merchantID: this.merchantID,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      customerID: this.customerID,
      status: this.status,
    };
    this.listDB = new LoadRecords(
      this.invitationService,
      this.destroyEvent,
      this.isPromoterOrAdmin,
      params,
      this.customPhonePipe
    );
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
    this.getTitle();
  }


  getInvitationUpdate(d) {
    if (d && d.ID) {
      this.dataSource.replaceItem = d;
    }
  }

  getDeletedInvitationID(id) {
    if (id) {
      this.dataSource.removeItem = id;
    }
  }

  getTitle() {
    if (this.status) {
      this.displayedColumns = [
        'ApplicationDate',
        'Merchant',
        'CustomerName',
        // "Merchant",
        // "Funder",
        // "CustomerMobile",
        // "CustomerEmail",
        // "ProductName",
        // "LoanPurpose",
        // "CapacityCheckStatus",
        // "CreditCheckStatus",
        //  "Status",
        'Product',
        'AmountInvoice',
        'AmountFinanced',
        'timeElapsed',
        // "AmountLoan",
        // "AmountOriginal",
        'Actions',
      ];
    }
    if (this.status == 'referredApplication') {
      this.title = 'Referred';
    } else if (this.status == 'commencedApplication') {
      this.title = 'Commenced';
    } else if (this.status == 'yetCommencedApplication') {
      this.title = 'Yet To Commenced';
    } else {
      this.title = 'Other';
    }
  }

  setFilter(event, field) {
    let filter;

    if (field == 'ApprovedOrActive') {
      let _event;
      if (event == true) {
        _event = '1';
      } else {
        _event = '0';
      }
      filter = {
        field,
        value: _event,
      };
    } else if (typeof event == 'object' && event != null) {
      const v = this.customDate.transform(event, Settings.global['dateFormat']);
      filter = {
        field,
        value: v,
      };
    } else if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  openQuotationDetailView(item) {
    const url = this.router.url;

    if (this.showDetailInList == true) {
      this.quotationID = item['QuotationORContract_Key'];
      // this.displayQuotationDetailView = true;
      // this.detailsView.emit(true);

      // emit contract id to parent
      this.myQuotationID.emit(item['QuotationORContract_Key']);
    } else {
      if (item['QuotationORContract_Key'] && item['QuotationORContract_Key'] != '00000000000000000000000000000000') {
        if (url.indexOf('promoter') != -1) {
          this.router.navigate([
            '/promoter',
            { outlets: { page: ['contract-overview-details', item['QuotationORContract_Key']] } },
          ]);
        } else {
          this.router.navigate([
            '/merchant',
            { outlets: { page: ['contract-overview-details', item['QuotationORContract_Key']] } },
          ]);
        }
      } else {
        this.router.navigate([
          '/merchant',
          {
            outlets: {
              page: ['customer-profile', item['Prospect_key'], 'treatment', item['ID']],
            },
          },
        ]);
      }
    }
  }

  cancelQuotationDetailView() {
    this.quotationID = null;
    this.displayQuotationDetailView = false;
    this.detailsView.emit(false);
  }

  openPersonalCircumstanceView(id) {
    this.quotationID = id;
    this.displayPersonalCirView = true;
    this.detailsView.emit(true);
  }

  cancelPersonalCircumstanceView() {
    this.quotationID = null;
    this.displayPersonalCirView = false;
    this.detailsView.emit(true);
  }
  wakandaFiles(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(WakandaFilesComponent, {
        data: {
          invitationID: id,
        },
        width: '810px',
      });

      ref.componentInstance.closeEvent.subscribe((res) => {
        ref.close();
      });
    }
  }
  quickView(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(CustomerProspectViewModelComponent, {
        data: {
          patientID: id,
          isFullDetails: true,
        },
        width: '700px',
      });
      ref.componentInstance.detailedView.subscribe((res) => {
        ref.close();
        ref.afterClosed().subscribe((r) => {
          this.router.navigate([
            '/merchant',
            {
              outlets: {
                page: ['customer-profile', id],
              },
            },
          ]);
        });
      });

      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }
  redirect(invitation) {
    if (invitation && invitation.Instant_Finance_Offer_Key) {
      const url = `${environment.nodeUrl}/d/` + invitation.Instant_Finance_Offer_Key;
      window.open(url, '_blank', 'noopener');
    } else if (invitation && invitation.WakandaID) {
      const url =
        Settings.global['consumerFrontendLink'] + '/consumer/(page:invitation-card/' + invitation.WakandaID + ')';
      window.open(url, '_blank', 'noopener');
    }
  }

  openInvitationView(id) {
    const ref = this.dialog.open(InvitationViewModalComponent, {
      data: {
        invitationID: id,
        membership: false,
      },
      width: '900px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }
  fullInvitationDetails(invitationID, patientID) {
    if (invitationID && patientID) {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['customer-profile', invitationID, 'treatment', patientID],
          },
        },
      ]);
    }
  }
  openProductView(id) {
    const ref = this.dialog.open(ProductViewModalComponent, {
      data: id,
      width: '750px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }
  openCustomerView(id) {
    // let ref = this.dialog.open(CustomerViewComponent, {
    //   data: id,
    //   width: "600px",
    //   panelClass: "noCard"
    // });

    // ref.componentInstance.closeModal.subscribe(res => {
    //   ref.close();
    // })
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['customer-profile', id, 'patient'],
        },
      },
    ]);
  }

  sendSMS(id, merchantID) {
    if (id && merchantID) {
      const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
        data: {
          cardID: id,
          merchantID: merchantID,
          singleChat: true,
          contractID:id
        },
        panelClass: 'noCard',
        width: '500px',
      });
      ref2.componentInstance.close.subscribe((data) => {
        ref2.close();
      });

      ref2.componentInstance.getResult.subscribe((data) => {
        if (data) {
          const ref = RootAppComponent.dialog.open(SmsConversationComponent, {
            data,
            panelClass: 'bigger-screen',
            width: '70%',
            height: '95vh',
          });
          const sub = ref.componentInstance.close.subscribe((data) => {
            ref.close();
          });
        }

        ref2.close();
      });
    }
  }
  viewMerchant(id) {
    const ref = this.dialog.open(MerchantViewComponent, {
      data: id,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }
  contactDentist(DentistContact_key) {
    if (DentistContact_key) {
      const data = {
        targetType: 'contact',
        targetID: DentistContact_key,
        asGuest: false,
        asProfile: true,
        asPractice: true,
      };
      AuthenticationService.contactInputEvent.emit(data);
    }
  }

  contactSMSDentist(DentistContact_key) {
    if (DentistContact_key) {
      if (DentistContact_key) {
        const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
          data: {
            cardID: DentistContact_key,
            isContactStaff: true,
            singleChat: true,
          },
          panelClass: 'bigger-screen',
          width: '70%',
          height: '95vh',
        });
        const sub = ref2.componentInstance.close.subscribe((data) => {
          ref2.close();
        });
      }
    }
  }
  viewDentist(DentistContact_key) {
    if (DentistContact_key) {
      const ref = RootAppComponent.dialog.open(DentistViewComponent, {
        data: DentistContact_key,
        width: '550px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  sendEmail(id) {
    if (id) {
      const data = {
        targetType: 'customerProspect',
        targetID: id,
        asGuest: false,
        asProfile: true,
        asPractice: true,
      };
      AuthenticationService.contactInputEvent.emit(data);
    }
  }
  viewContractLogs(id) {
    const ref = this.dialog.open(EventLogListComponent, {
      data: {
        contractID: id,
        type: 'contract',
        itemID: id,
      },
      width: '900px',
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }
  viewMessagesContract(id) {
    const ref = this.dialog.open(MessageTimelineComponent, {
      data: {
        contractID: id,
        messageType: MessageType.message,
      },
      width: '900px',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }
  viewMessagesInvitation(id) {
    const ref = this.dialog.open(MessageTimelineComponent, {
      data: {
        invitationID: id,
        messageType: MessageType.message,
      },
      width: '900px',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }
  viewMessages(id) {
    const ref = this.dialog.open(MessageTimelineComponent, {
      data: {
        cardID: id,
        messageType: MessageType.message,
      },
      width: '900px',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  createNote(item) {
    const route =
      item['QuotationORContract_Key'] && item['QuotationORContract_Key'] !== '00000000000000000000000000000000'
        ? `contract-overview-details/${item['QuotationORContract_Key']}`
        : `customer-profile/${item['Prospect_key']}/treatment/${item['ID']}`;

    this.dialog.open<NotesModal, NotesModalConfig>(NotesModal, {
      data: {
        patientID: item['Prospect_key'],
        parentRoute: 'merchant',
        noteListMode: 'create',
        relationshipLink: {
          label: 'quotation',
          route,
          itemID: item['ID'],
        },
      },
    });
  }

  openDetailsViewEP(ID, pID) {
    const url = this.router.url;

    if (url.indexOf('promoter') != -1) {
      this.router.navigate([
        '/merchant',
        {
          outlets: { page: ['contract-overview-details', ID] },
          data: { title: 'Referred Application' },
        },
      ]);
    } else {
      this.router.navigate([
        '/merchant',
        {
          outlets: { page: ['contract-overview-details', ID] },
          data: { title: 'Referred Application' },
        },
      ]);
    }
  }

  viewNotes(patientID: string) {
    this.dialog.open<NotesModal, NotesModalConfig>(NotesModal, {
      data: {
        parentRoute: 'merchant',
        patientID,
      },
    });
  }

  contactBySMS(id) {
    const ref = RootAppComponent.dialog.open(TextMessageCreateComponent, {
      data: {
        patientID: id,
      },
      width: '650px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  contactByEmail(id) {
    const data = {
      targetType: 'prospect',
      targetID: id,
      asGuest: false,
      asProfile: true,
      asPractice: true,
      subjectArray: [],
    };
    AuthenticationService.contactInputEvent.emit(data);
  }

  toNumber(value) {
    return Number(value);
  }

  getTotalColumn(field) {
    return this.dataSource.filteredData.map((t) => t[field]).reduce((acc, value) => Number(acc) + Number(value), 0);
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  util = new UtilsClass();
  isDestoyed = false;

  serviceRef;

  constructor(
    private invitationService: InvitationService,
    private destroyEvent,
    private isPromoterOrAdmin,
    private params,

    private customPhonePipe: CustomPhonePipe
  ) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    const payload = {
      section: 0,
      dateFrom: this.util.EPdateFormat(this.params.dateFrom),
      dateTo: this.util.EPdateFormat(this.params.dateTo),
      merchantID: this.params.merchantID,
      status: this.params.status,
      prospectID: this.params.customerID,

      fields:
        'ID,Prospect_key,Campaign_Key,Instant_Finance_Offer_Key,Document_Link_key,LastModified,DateTimeCreated,Merchant_Key,MerchantName,FirstName,MiddleName,LastName,Treatment.Value,Contract_Financed_Amount,Funder_Key,Funder_Name,ProductName,Product.Label.Internal,Product.Label.Marketing,Product.Label.Web,' +
        'Prospect_key,Status.Application.Short_NE,Contract_Key,QuotationORContract_Key,LastEvent.DateTimeCreated,LastEvent.EventCode,LastEvent.EventDescription,Product_Key,' +
        'DentistContact_key,DentistName,MerchantName,Contract_Key,Email,Mobile,Quotation_Key,ProductName,Product.Label.Internal,Product.Label.Marketing,Product.Label.Web,WakandaID,ExistingPatientID',
    };

    this.serviceRef = this.invitationService.getInvitations(payload, this.isPromoterOrAdmin).subscribe((res) => {
      this.count = res.data.length;
      this.items = res.data;

      for (let i = 0; i < this.items.length; i++) {
        this.buildRecord(i);
      }
      this.dataChange.next(this.items.slice());
      this.firstLoad = true;
      const innerFunction = (section) => {
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.invitationService.getInvitations(payload, this.isPromoterOrAdmin).subscribe((res) => {
          if (res.data.length > 0) {
            this.count = res.data.count;
            this.items = this.items.concat(res.data);
            for (let i = length; i < this.items.length; i++) {
              this.buildRecord(i);
            }
            this.dataChange.next(this.items.slice());
            if (this.isDestoyed != true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };

      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  buildRecord(i) {
    if (this.items[i]) {
      this.items[i]['fullName'] = '';

      if (this.items[i]['ID']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['ID'];
      }
      if (this.items[i]['MerchantName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['MerchantName'];
      }
      if (this.items[i]['Merchant_Key']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Merchant_Key'];
      }
      if (this.items[i]['Prospect_key']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Prospect_key'];
      }
      if (this.items[i]['FirstName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['FirstName'];
      }
      if (this.items[i]['MiddleName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['MiddleName'];
      }
      if (this.items[i]['LastName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['LastName'];
      }
      if (this.items[i]['Product_Key']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Product_Key'];
      }

      if (this.items[i]['ProductName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['ProductName'];
      }

      if (this.items[i]['Product.Label.Internal']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Product.Label.Internal'];
      }

      if (this.items[i]['Product.Label.Marketing']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Product.Label.Marketing'];
      }

      if (this.items[i]['Product.Label.Web']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Product.Label.Web'];
      }

      if (this.items[i]['Funder_Key']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Funder_Key'];
      }
      if (this.items[i]['Funder_Name']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Funder_Name'];
      }

      if (this.items[i]['DentistContact_key']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['DentistContact_key'];
      }
      if (this.items[i]['DentistName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['DentistName'];
      }
      if (this.items[i]['MerchantName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['MerchantName'];
      }
      if (this.items[i]['Contract_Key']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Contract_Key'];
      }

      if (this.items[i]['Email']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Email'];
      }
      if (this.items[i]['Mobile']) {
        this.items[i]['fullName'] =
          this.items[i]['fullName'] +
          ' ' +
          this.customPhonePipe.transform(this.items[i]['Mobile'], 'none').replace(/ /g, '');
      }

      if (this.items[i]['Quotation_Key']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Quotation_Key'];
      }
      if (this.items[i]['ProductName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['ProductName'];
      }
      if (this.items[i]['Product.Label.Internal']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Product.Label.Internal'];
      }

      if (this.items[i]['Product.Label.Marketing']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Product.Label.Marketing'];
      }

      if (this.items[i]['Product.Label.Web']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Product.Label.Web'];
      }
      if (this.items[i]['WakandaID']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['WakandaID'];
      }

      if (this.items[i]['ExistingPatientID']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['ExistingPatientID'];
      }
    }
  }
  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }
 set removeItem(id) {
    const data = this._tableDatabase.data.filter((row) => row.ID != id);
    this._tableDatabase.dataChange.next(data);
  }

  set replaceItem(item) {
    const _index = this._tableDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    this._tableDatabase.data[_index] = item;
    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
          let f = '';

          f = item[this.field] || '';

          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'ApplicationDate':
          [propertyA, propertyB] = [
            moment(a['ApplicationDate']).toDate().getTime(),
            moment(b['ApplicationDate']).toDate().getTime(),
          ];
          break;
        case 'Merchant':
          [propertyA, propertyB] = [a['MerchantName'], b['MerchantName']];
          break;

        case 'Status':
          [propertyA, propertyB] = [a['Status'], b['Status']];
          break;
        case 'CustomerName':
          [propertyA, propertyB] = [a['Customer.FullName'], b['Customer.FullName']];
          break;
        case 'CustomerMobile':
          [propertyA, propertyB] = [a['Customer.Mobile'], b['Customer.Mobile']];
          break;
        case 'CustomerEmail':
          [propertyA, propertyB] = [a['Customer.Email'], b['Customer.Email']];
          break;
        case 'ProductName':
          [propertyA, propertyB] = [a['ProductName'], b['ProductName']];
          break;
        case 'LoanPurpose':
          [propertyA, propertyB] = [a['LoanPurpose'], b['LoanPurpose']];
          break;
        case 'CapacityCheckStatus':
          [propertyA, propertyB] = [a['CapacityCheck.Status'], b['CapacityCheck.Status']];
          break;
        case 'CreditCheckStatus':
          [propertyA, propertyB] = [a['CreditCheck.Status'], b['CreditCheck.Status']];
          break;
        case 'AmountInvoice':
          [propertyA, propertyB] = [a['Amount.Invoice'], b['Amount.Invoice']];
          break;
        case 'AmountFinanced':
          [propertyA, propertyB] = [a['Amount.Financed'], b['Amount.Financed']];
          break;
        case 'AmountLoan':
          [propertyA, propertyB] = [a['Amount.Loan.Original'], b['Amount.Loan.Original']];
          break;
        case 'AmountOriginal':
          [propertyA, propertyB] = [a['Amount.Original'], b['Amount.Original']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
