<div class="rel lookup">
  <div *ngIf="!hideFilter">
    <div class="clearfix">
      <!-- <mat-icon class="large-modal-icon">account_circle</mat-icon>
      <h3 class="sr-title sm-h3"></h3>
      <p class="rm-mt"></p>
 -->

      <div class="card-header primary-gradient-img clearfix inModal">
        <div class="row clearfix">
          <button
            class="btn-close btn-clear mat-button white"
            *ngIf="isModal == true"
            (click)="closeEvent()"
            tabIndex="-1"
          >
            <mat-icon>clear</mat-icon>
          </button>
          <div class="row titleArea clearfix">
            <mat-icon class="pull-left patient-main-icon" *ngIf="!headerIcon">account_circle</mat-icon>
            <mat-icon class="{{ headerIcon }} pull-left patient-main-icon" *ngIf="headerIcon"></mat-icon>
            <h2 class="summary-header rm-m">
              <!-- {{ beneficiary.CalculatedName}} -->
              {{ headerTitle | translate }}

              <span class="subLabel" *ngIf="hideFilter != true" > {{title | translate }}</span>
              <span class="subLabel" *ngIf="hideFilter == true" > {{title2  | translate}}</span>
            </h2>
          </div>

          <button
            class="btn mr new-patient-button pull-right"
            *ngIf="isNewPatient == true"
            (click)="newPatient()"
            mat-raised-button
            color="accent"
          >
            <mat-icon class="fas fa-user-plus smr" style="font-size: 1rem; margin-top: 10px"></mat-icon>
            New
            {{ "KEYWORD.patient" | translate | titlecase }}
          </button>

          <!-- <button class="btn-close btn-clear mat-button white" matDialogClose>
            <mat-icon>clear</mat-icon>
          </button> -->
        </div>
      </div>

      <div class="clearfix full-width previous-search" *ngIf="patients && patients.length > 0">
        <button class="clearfix pull-right" (click)="goForward()" mat-raised-button color="primary">
          {{ patients.length }} {{ "KEYWORD.patient" | translate | titlecase }}(s)
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
        <p class="rm-m mr line-height">View the result of your previous search</p>
      </div>

      <hr class="mb" *ngIf="patients && patients.length > 0"/>

      <form class="lookup-form" #lookupForm="ngForm">
        <div class="full-width clearfix mt">
          <mat-form-field
            class="full-width"
            *ngIf="
              merchants &&
              merchants.length > 0 &&
              currentMerchant &&
              currentMerchant.ID &&
              displayMerchantList == true &&
              isPromoterOrAdmin == true
            "
            appearance="outline"
          >
            <mat-label>Merchant</mat-label>

            <mat-select
              [(ngModel)]="merchantID"
              (ngModelChange)="getClientObject()"
              name="merchantID"
              placeholder="Merchant"
            >
              <mat-select-trigger>
                <div class="full-width flex flex-cust" *ngIf="merchant && merchant.ID">
                  <app-card-picture
                    class="sms-merchant-pic prod text-center pull-left mr"
                    [hideZoomButton]="true"
                    [cardID]="merchant.ID"
                    [hideZoomButton]="false"
                    [tableName]="'Merchant'"
                    [LastModified]="merchant['LastModified']"
                  >
                  </app-card-picture>
                  <p class="rm-m third-party-p full-width pull-left">
                    {{ merchant.TradingAs }}
                  </p>
                  <button
                    class="mr"
                    (click)="$event.stopPropagation(); viewMerchant(merchantID)"
                    mat-button
                    mat-icon-button

                  >
                    <mat-icon>visibility</mat-icon>
                  </button>
                </div>
              </mat-select-trigger>
              <mat-option [value]="currentMerchant.ID">
                <div class="full-width flex flex-cust">
                  <app-card-picture
                    class="sms-merchant-pic prod text-center pull-left mr"
                    [hideZoomButton]="true"
                    [cardID]="currentMerchant.ID"
                    [hideZoomButton]="false"
                    [tableName]="'Merchant'"
                    [LastModified]="currentMerchant['LastModified']"
                  >
                  </app-card-picture>
                  <p class="rm-m third-party-p full-width pull-left">
                    <strong>Promoter</strong>
                  </p>

                  <button
                    class="mr"
                    (click)="$event.stopPropagation(); viewMerchant(currentMerchant['ID'])"
                    mat-button
                    mat-icon-button

                  >
                    <mat-icon style="width: 40px">visibility</mat-icon>
                  </button>
                </div>
              </mat-option>

              <mat-option *ngFor="let s of merchants | OrderArrayObject: ['TradingAs']" [value]="s.ID">
                <div class="full-width flex flex-cust">
                  <app-card-picture
                    class="sms-merchant-pic prod text-center pull-left mr"
                    [hideZoomButton]="true"
                    [cardID]="s.ID"
                    [hideZoomButton]="false"
                    [tableName]="'Merchant'"
                    [LastModified]="s['LastModified']"
                  >
                  </app-card-picture>
                  <p class="rm-m third-party-p full-width pull-left">
                    {{ s.TradingAs }}
                  </p>

                  <button
                    class="mr"
                    (click)="$event.stopPropagation(); viewMerchant(s['ID'])"
                    mat-button
                    mat-icon-button

                  >
                    <mat-icon style="width: 40px">visibility</mat-icon>
                  </button>
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="full-width clearfix flex">
          <!-- <mat-form-field class="full-width" *ngIf="useStrict != true" appearance="outline">
            <mat-label>Name</mat-label>
            <input [(ngModel)]="filterName" matInput name="name" placeholder="Name" tabIndex="1"/>
          </mat-form-field>

          <mat-form-field class="full-width" *ngIf="useStrict == true" appearance="outline">
            <mat-label>First name, Surname, Middle name, Full name...</mat-label>
            <input
              [(ngModel)]="calculatedName"
              matInput
              name="calculatedName"
              placeholder="First name, Surname, Full name"
              tabIndex="1"
            />
          </mat-form-field> -->

          <mat-form-field class="full-width smr"  appearance="outline">
            <mat-label>First Name</mat-label>
            <input [(ngModel)]="firstName" matInput name="firstName" placeholder="First Name" tabIndex="1"/>
          </mat-form-field>

          <mat-form-field class="half-width  smr"  appearance="outline">
            <mat-label>Middle Name</mat-label>
            <input [(ngModel)]="middleName" matInput name="middleName" placeholder="Middle Name" tabIndex="1"/>
          </mat-form-field>

          <mat-form-field class="full-width"  appearance="outline">
            <mat-label>Last Name</mat-label>
            <input [(ngModel)]="lastName" matInput name="lastName" placeholder="Last Name" tabIndex="1"/>
          </mat-form-field>



          <!-- <mat-form-field class="full-width ml" *ngIf="useStrict==true" appearance="outline">
            <mat-label>Surname</mat-label>
            <input matInput name="lastName" placeholder="Surname" tabIndex="2" [(ngModel)]="lastName">
          </mat-form-field> -->
        </div>
        <div class="full-width clearfix flex">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Mobile</mat-label>
            <input [(ngModel)]="mobile" matInput name="mobile" placeholder="Mobile" tabIndex="3"/>
          </mat-form-field>

          <mat-form-field class="full-width ml" appearance="outline">
            <mat-label>Phone</mat-label>
            <input [(ngModel)]="filterPhone" matInput name="phone" placeholder="Phone" tabIndex="3"/>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Email</mat-label>
          <input
            [(ngModel)]="filterEmail"
            matInput
            name="email"
            type="email"
            tabIndex="4"
            placeholder="Email"
          />
        </mat-form-field>

        <app-date-input
          class="full-width"
          [defaultDate]="null"
          [isTime]="false"
          [startView]="'multi-year'"
          [maxDate]="'toDay'"
          [isClear]="isClear"
          (selectedDate)="selectDateToFilter($event)"
          label="Date of birth"
        >
        </app-date-input>
        <div class="full-width clearfix mb" *ngIf="tags && tags.length > 0">
          <app-tag-list
            class="full-width mb"
            [tags]="tags"
            [selectedTags]="selectedTags"
            [openPosition]="'above'"
            [tagFormatIsObject]="true"
            [title]="'Tags'"
            [canRemove]="true"
            [canCreate]="false"
            [directRemoveDefault]="true"
            [directRemove]="true"
            [hasFilter]="true"
            [canSelect]="false"
            (getSelectedTags)="getSelectedTags($event)"
          >
          </app-tag-list>
        </div>

        <div class="drop-buttons">
          <button
            class="inline btn-new-invite btn-large"
            [disabled]="
              !lookupForm.form.valid ||
              ((dateOfBirth == '' || dateOfBirth == null) &&
                !calculatedName &&
                !mobile &&
                !firstName &&
                !middleName &&
                !lastName &&
                !filterName &&
                !filterPhone &&
                !filterEmail &&
                (!selectedTags || (selectedTags && selectedTags.length <= 0)))
            "
            (click)="getPatients()"
            mat-raised-button
            color="accent"
            tabIndex="4"
          >
            Search
          </button>
        </div>
      </form>
    </div>
  </div>

  <div *ngIf="hideFilter">
    <div>
      <div class="card-header primary-gradient-img clearfix inModal">
        <div class="row clearfix">
          <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeEvent()">
            <mat-icon>clear</mat-icon>
          </button>
          <div class="row titleArea clearfix">
            <mat-icon class="pull-left patient-main-icon">account_circle</mat-icon>
            <h2 class="summary-header rm-m">
              <!-- {{ beneficiary.CalculatedName}} -->
              Possible Match
              <span class="subLabel">
                You detected a possible matching {{ "KEYWORD.patient" }} records in our database. <br/>
                Please confirm if any of the matches below are are the same records as the one you are entering.
              </span>
            </h2>
          </div>
        </div>
      </div>
      <h1 class="sr-title sm-h1 rm-mt">
        {{ patientLabel }}

        <span
          class="badge master"
          *ngIf="patients"
          [endVal]="
            (patients
              | FilterArrayMultipleOrContain
                : ['Name', 'FirstName', 'emails.Email','MiddleName', 'phone.Number', 'mobiles.Number']
                : filterData
              | lengthOfArray) || 0
          "
          countUp
        ></span>
      </h1>
      <div class="clearfix flex flex-center">
        <mat-form-field class="full-width" *ngIf="patients.length > 3" appearance="outline">
          <mat-label>Search results by Name, email or phone number</mat-label>
          <input [(ngModel)]="filterData" matInput name="search" tabIndex="1"/>
        </mat-form-field>

        <button class="actionMenuToggle ml mr" *ngIf="patients.length > 3" [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon class="fas fa-sort"></mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <button
            [ngClass]="{ 'accent-color': orderByFilter === 'CalculatedName' || orderByFilter === '-CalculatedName' }"
            (click)="orderBy('CalculatedName')"
            mat-menu-item
          >
            <span>Name</span>
            <span *ngIf="orderByFilter === 'CalculatedName' || orderByFilter === '-CalculatedName'">
              <mat-icon class="accent-color" *ngIf="orderByFilter === '-CalculatedName'">arrow_drop_up</mat-icon>
              <mat-icon class="accent-color" *ngIf="orderByFilter !== '-CalculatedName'">arrow_drop_down</mat-icon>
            </span>
          </button>

          <button
            [ngClass]="{ 'accent-color': orderByFilter === 'DateTimeCreated' || orderByFilter === '-DateTimeCreated' }"
            (click)="orderBy('DateTimeCreated')"
            mat-menu-item
          >
            <span>Join Date</span>
            <span *ngIf="orderByFilter === 'DateTimeCreated' || orderByFilter === '-DateTimeCreated'">
              <mat-icon class="accent-color" *ngIf="orderByFilter === '-DateTimeCreated'">arrow_drop_down</mat-icon>
              <mat-icon class="accent-color" *ngIf="orderByFilter !== '-DateTimeCreated'"> arrow_drop_up</mat-icon>
            </span>
          </button>

          <button
            [ngClass]="{ 'accent-color': orderByFilter === 'emails.Email' || orderByFilter === '-emails.Email' }"
            (click)="orderBy('emails.Email')"
            mat-menu-item
          >
            <span>Email</span>
            <span *ngIf="orderByFilter === 'emails.Email' || orderByFilter === '-emails.Email'">
              <mat-icon class="accent-color" *ngIf="orderByFilter === '-emails.Email'">arrow_drop_up</mat-icon>
              <mat-icon class="accent-color" *ngIf="orderByFilter !== '-emails.Email'">arrow_drop_down</mat-icon>
            </span>
          </button>
        </mat-menu>
      </div>
    </div>

    <div class="patientContainer">
      <div
        class="patientRecord row clearfix"
        *ngFor="
          let patient of patients
            | FilterArrayMultipleOrContain: ['Name', 'FirstName', 'emails.Email','MiddleName', 'mobiles.Number']:filterData
            | OrderArrayObject: [orderByFilter]
            | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }
        "
        [ngClass]="{ activeExistingPatient: selectedPatient == patient }"
      >
        <div class="pull-right upper-side">
          <button
            class=""
            *ngIf="patient && patient['ID'] && patient['TableName']"
            [matMenuTriggerFor]="menu"
            color="accent"
            mat-icon-button
            matTooltip="View  Details"
          >
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <button (click)="openPatientViewDialog(patient['ID'])" mat-menu-item>
              <mat-icon class="smr">visibility</mat-icon>
              Quick View
            </button>
            <hr/>
            <button (click)="viewPatient(patient['ID'])" mat-menu-item>
              <mat-icon class="smr">description</mat-icon>
              Detailed View
            </button>

            <hr/>
            <button
              *ngIf="displayChatButton == true && isModulePatientSMSActive == true"
              (click)="displayChat(patient['ID'])"
              mat-menu-item
            >
              <mat-icon class="smr">textsms</mat-icon>
              SMS Chat
            </button>

            <hr/>
            <button
              *ngIf="displayFinanceOffer == true && isModuleSMSOfferActive == true"
              (click)="displayInstantOfferEvent(patient['ID'])"
              mat-menu-item
              color="accent"
            >
              <mat-icon class="fas fa-dollar-sign smr"></mat-icon>
              Instant Finance Offer
            </button>
          </mat-menu>
        </div>

        <div
          class="flex truncate"
          (click)="SelectRecordClickEvent(patient)"
          matTooltip="Click to choose {{ patient['FirstName'] }} {{ patient['MiddleName'] }} {{ patient['Name'] }}"
        >
          <div class="profile-result">
            <!-- avatar image -->
            <app-invitation-customer-prospect-view-picture
              class="pull-left patientIcon invitationLookup mr"
              [patientID]="patient['ID']"
              [modifiedDate]="patient['LastModified']"
            >
            </app-invitation-customer-prospect-view-picture>
            <!-- profile information -->
            <div class="pull-left">
              <h3 class="sr-title rm-m">{{ patient['FirstName'] }} {{ patient['MiddleName'] }} {{ patient['Name'] }}</h3>
              <p class="small rm-m" *ngIf="patient['mobiles.Number']" matLine>
                {{ patient['mobiles.Number'] | customPhone }}
              </p>
              <p class="small rm-m" matLine>
                {{ patient['emails.Email'] }}
              </p>
              <p class="small rm-m" *ngIf="patient.DateOfBirth" matLine>
                Age:
                <ng-container *ngIf="utilsClass.calculateAge(patient.DateOfBirth)">
                  {{ utilsClass.calculateAge(patient.DateOfBirth) }}
                </ng-container>
                <ng-container *ngIf="!utilsClass.calculateAge(patient.DateOfBirth)"> ...</ng-container>
              </p>
              <p
                class="small rm-mt"
                *ngIf="patient.DateTimeCreated"
                matLine
                matTooltip="{{ patient.DateTimeCreated | customDate }}"
              >
                Join Date: {{ patient.DateTimeCreated | TimeLabelPast }} ago
              </p>
            </div>
          </div>
        </div>

        <mat-divider></mat-divider>
      </div>

      <div
        class="full-width"
        *ngIf="
          (patients
            | FilterArrayMultipleOrContain
              : ['Name', 'FirstName', 'emails.Email','MiddleName', 'phone.Number', 'mobiles.Number']
              : filterData
            | lengthOfArray) <= 0
        "
      >
        <app-empty-list-message [message]="'No Record found'"></app-empty-list-message>
      </div>
    </div>

    <div
      class="full-width text-center page-container mt flex mb"
      *ngIf="
        (patients
          | FilterArrayMultipleOrContain
            : ['Name', 'FirstName', 'emails.Email','MiddleName', 'phone.Number', 'mobiles.Number']
            : filterData
          | lengthOfArray) > 10
      "
    >
      <pagination-controls class="full-width clearfix" (pageChange)="changePage($event)" responsive="true">
      </pagination-controls>
    </div>

    <div class="drop-button text-center clearfix mt full-width" *ngIf="isModal == true">
      <ng-container *ngIf="showBackButton == true">
        <button
          class="inline btn-new-invite selectExistingPatient pull-left"
          (click)="goBack()"
          mat-raised-button
          color="accent"
        >
          <mat-icon>keyboard_arrow_left</mat-icon> Back
        </button>
      </ng-container>

      <button
        class="inline btn-new-invite selectExistingPatient"
        [ngClass]="{ 'pull-right': showNextBtn != true, 'pull-left': showBackButton != true }"
        (click)="closeEvent()"
        mat-raised-button
        color="primary"
      >
        Close
      </button>

      <ng-container *ngIf="showNextBtn == true">
        <button
          class="inline btn-new-invite selectExistingPatient pull-right"
          (click)="noMatch()"
          mat-raised-button
          color="accent"
        >
          Add New {{ "KEYWORD.patient" | translate | titlecase }}
          <mat-icon>add</mat-icon>
        </button>
      </ng-container>
    </div>
  </div>
</div>
