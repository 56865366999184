import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, merge as observableMerge, Observable, Subject } from 'rxjs';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';
import { map } from 'rxjs/operators';
import { OperatorContactComponent } from '../../../feature/operator/operator-contact/operator-contact.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { MapViewComponent } from '../../../shared/components/map-view/map-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { LogUserListComponent } from '../../log/log-user-list/log-user-list.component';
import { AgentDetailsComponent } from '../agent-details/agent-details.component';
import { ConnectionHistoryViewComponent } from '../connection-history-view/connection-history-view.component';
import { SessionsService } from '../shared/sessions.service';

@Component({
  selector: 'app-connection-history',
  templateUrl: './connection-history.component.html',
  styleUrls: ['./connection-history.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('10ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-10%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('10ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-10%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ConnectionHistoryComponent implements OnInit {
  @Input()
  data = {
    email: '',
    name: '',
    phone: '',
  };
  @Input() numberOfRecords = 100;

  @Input() operatorID;
  @Input() cardKey;

  rows = [];
  displayedColumns = [
    'selectCheckBox',
    'geolocation',
    'ProfileImage',
    'name',
    // "TableName",
    // "TradingAs",
    'email',
    'sessionType',
    'last_check',
    'Actions',
  ];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;
  public sessionDB: LoadSessions | null;
  dataSource: SessionsDataSource | null;
  @ViewChild('input', { static: false }) input;
  priority = new Subject<any>();
  selectedIDs = [];
  destroyEvent = new EventEmitter();

  util = new UtilsClass();

  constructor(private sessionsService: SessionsService) {}

  ngOnInit() {
    if (this.operatorID || this.cardKey) {
      this.displayedColumns = ['selectCheckBox', 'geolocation', 'sessionType', 'last_check', 'Actions'];
    }

    const payload = {
      operatorID: this.operatorID,
      cardKey: this.cardKey,
    };

    this.sessionDB = new LoadSessions(this.sessionsService, this.destroyEvent, this.numberOfRecords, payload);
    this.dataSource = new SessionsDataSource(this.sessionDB, this.paginator, this.sort);
  }

  ngOnChanges(changes: SimpleChanges) {
    const payload = {
      operatorID: this.operatorID,
      cardKey: this.cardKey,
    };

    this.sessionDB = new LoadSessions(this.sessionsService, this.destroyEvent, this.numberOfRecords, payload);
    this.dataSource = new SessionsDataSource(this.sessionDB, this.paginator, this.sort);
  }

  isSelected(id) {
    if (!this.selectedIDs || this.selectedIDs.length <= 0) {
      return false;
    } else {
      if (this.selectedIDs.indexOf(id) != -1) {
        return true;
      }
    }

    return false;
  }

  select(id, isSelected) {
    if (isSelected == true && this.selectedIDs.indexOf(id) == -1) {
      this.selectedIDs.push(id);
    } else {
      const _index = this.selectedIDs.indexOf(id);
      this.selectedIDs.splice(_index, 1);
    }
  }

  moreInfoTohtml(moreInfo) {
    let html = '';
    if (moreInfo) {
      if (moreInfo.browser) {
        html = html + '<strong>- Browser: </strong>' + moreInfo.browser + ' (Version ' + moreInfo.browser_version + ')';
      }
      if (moreInfo.device) {
        html = html + '<br><strong>- Device: </strong>' + moreInfo.device;
      }
      if (moreInfo.os) {
        html = html + '<br><strong>- Operating system: </strong>' + moreInfo.os;

        html = html + '<br><strong>- Version: </strong>' + moreInfo.os_version;
      }
      return html;
    } else {
      return null;
    }
  }

  displayMap(geolocation) {
    for (let i = 0; i < geolocation.length; i++) {
      if (geolocation[i] && geolocation[i].moreInfo) {
        geolocation[i].moreInfo = this.moreInfoTohtml(geolocation[i].moreInfo);
      }

      if (geolocation[i].lat) {
        geolocation[i].Latitude = geolocation[i].lat;
      }
      if (geolocation[i].lng) {
        geolocation[i].Longitude = geolocation[i].lng;
      }

      geolocation[i].time = geolocation[i].last_check || new Date();
    }

    const ref = RootAppComponent.dialog.open(MapViewComponent, {
      data: {
        positions: geolocation,
      },

      panelClass: 'modal-map',
      width: '800px',
      height: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  displayContact(session) {
    RootAppComponent.dialog.open(OperatorContactComponent, {
      width: '600px',
      data: session.id_4d,
    });
  }

  viewUserLogs(session) {
    const ref = RootAppComponent.dialog.open(LogUserListComponent, {
      data: session.id_4d,
      width: '90%',
      height: '80%',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  displayAgentDetails(row) {
    let label = 'Browser Details';

    if (row && row.name) {
      label = label + ': ' + row.name;
    }

    const ref = RootAppComponent.dialog.open(AgentDetailsComponent, {
      data: {
        label,
        agents: row.clientInfoHistory,
        cnhID: row._id,
      },
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  displayInfo(id) {
    const ref = RootAppComponent.dialog.open(ConnectionHistoryViewComponent, {
      width: '600px',
      data: {
        ID: id,
      },
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  setFilter(event, field) {
    let filter;
    if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
  }

  selectAll() {
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      const o = this.dataSource.filteredData[i];

      if (o && o['_id'] && this.selectedIDs.indexOf(o['_id']) == -1) {
        this.selectedIDs.push(o['_id']);
      }
    }
  }

  selectPage() {
    const index = this.dataSource['_paginator']['index'] || 0;
    const pageSize = this.dataSource['_paginator']['pageSize'] || 0;

    for (let i = 0; i < (index + 1) * pageSize; i++) {
      const o = this.dataSource.filteredData[index * pageSize + i];

      if (o && o['_id'] && this.selectedIDs.indexOf(o['_id']) == -1) {
        this.selectedIDs.push(o['_id']);
      }
    }
  }

  unselect() {
    this.selectedIDs = [];
  }

  delete4dUsers() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialogParams = {
        data: new ConfirmDialog(
          'fas fa-info',
          'Are you sure?',
          '<p>This will delete selected records. </p>',
          'No',
          'Yes'
        ),
      };

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, confirmDialogParams);
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            this.sessionsService.delete4dUsers(this.selectedIDs).subscribe((res) => {
              for (let i = 0; i < this.selectedIDs.length; i++) {
                this.dataSource.removeItem = this.selectedIDs[i];
              }

              this.selectedIDs = [];

              NotifyAppComponent.displayToast('success', 'Success!', 'Sessions are deleted.');
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }
}

export class LoadSessions implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  sessions = [];
  count: any;
  isDestoyed = false;
  firstLoad = false;

  get data(): any[] {
    return this.dataChange.value;
  }

  get firstLoadEvent() {
    return this.firstLoad;
  }

  serviceRef;

  constructor(private sessionsService: SessionsService, private destroyEvent, private limit, private params) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });
    const payload = {
      limit,
      section: 0,
      orderBy: 'last_check',
      fields: '',
      operatorID: null,
      cardKey: null,
    };

    if (this.params && this.params.operatorID) {
      payload.operatorID = this.params.operatorID;
    }

    if (this.params && this.params.cardKey) {
      payload.cardKey = this.params.cardKey;
    }
    this.serviceRef = this.sessionsService.getConnectionHistoryList(payload).subscribe((res) => {
      this.count = res.length;
      this.sessions = res;
      this.firstLoad = true;
      this.dataChange.next(this.sessions.slice());
      this.firstLoad = true;
      const innerFunction = (section) => {
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;
        this.serviceRef = this.sessionsService.getActiveSessions(payload).subscribe((res) => {
          if (res.length > 0) {
            this.count = res.count;
            this.sessions = this.sessions.concat(res);
            this.dataChange.next(this.sessions.slice());
            if (this.isDestoyed != true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };

      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class SessionsDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';
  dateF = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this.dateF = item.dateFrom ? item.dateFrom : '';
    this._filterChange.next(item.value);
  }

  set removeItem(id) {
    const data = this._invDatabase.data.filter((row) => row._id != id);
    this._invDatabase.dataChange.next(data);
  }

  filteredData: any[] = [];

  constructor(private _invDatabase: any, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._invDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._invDatabase.data.slice().filter((item: any) => {
          if (Array.isArray(this.field)) {
            let res = false;
            this.field.forEach((col) => {
              let f = '';
              f = item[col] || '';

              const searchStr = f.toLowerCase();
              res = res || searchStr.indexOf(this.filter.toLowerCase()) != -1;
            });
            return res;
          } else {
            let f = '';
            f = item[this.field] || '';

            const searchStr = f.toLowerCase();
            return searchStr.indexOf(this.filter.toLowerCase()) != -1;
          }
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'name':
          [propertyA, propertyB] = [a.name, b.name];
          break;
        case 'TableName':
          [propertyA, propertyB] = [a.TableName, b.TableName];
          break;
        case 'email':
          [propertyA, propertyB] = [a.email, b.email];
          break;
        case 'sessionType':
          [propertyA, propertyB] = [a.sessionType, b.sessionType];
          break;
        // case "last_check":
        //   [propertyA, propertyB] = [a.last_check, b.last_check];
        //   break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
