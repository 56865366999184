<div class="full-width clearfix">
  <div class="main-banner blue-banner"></div>

  <div class="row clearfix container mt ml mr">
    <div class="clearfix container-inner-parent">
      <div class="clearfix container-inner mt">
        <div class="guid guid-right flex clearfix full-width">
          <div class="container guid-main" @ngIfAnimation data-aos="fade-right" data-aos-delay="400"
            data-aos-once="true">
            <div class="half-width txt-box">
              <div class="txt" @ngIfAnimation data-aos="fade-left" data-aos-delay="400" data-aos-once="true">
                <h1 class="text-left header-title-sub-list">Find a plan that’s right for you!</h1>
                <p class="text-left header-description-sub-list">
                  Whether you want better {{ "KEYWORD.patient" | translate }} communication and finance options, or the
                  whole package deal - {{ "BRANDING.Brand Name" | translate | titlecase }} has a plan to suit all your
                  needs.
                </p>
              </div>
            </div>
            <div class="half-width img-box-left">
              <div class="guid-imgbox guid-imgbox-left">



                <img class="img"  *ngIf="context!=='p4p'"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/campaign/price/Asset_1.png" />

                <img class="img" style="max-width: 40% !important;  margin-bottom: -40px !important;"
                  *ngIf="context=='p4p'"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/p4p/vet-duke.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="full-width row container-card-sub-plan">
      <div class="sub-plan-card-app flex row" *ngFor="let subscriptionPlan of subscriptionPlans">
        <app-subscription-plan-card class="full-width row sub-plan-card" [canClose]="false" [isClosed]="false"
          [canCheck]="true" [subscriptionPlan]="subscriptionPlan" [isSubscriptionButton]="false"
          [isSelectButton]="false" [isSignupButton]="true" [isViewButton]="true" (getCheck)="check($event)"
          (getUncheck)="check($event)" (getRemove)="remove($event)">
        </app-subscription-plan-card>
      </div>
    </div>
  </div>
  <div class="text-center full-width mb" *ngIf="selectedIDs && selectedIDs.length > 1">
    <button class="btn-large clearfix" (click)="compareSelected()" mat-raised-button color="accent">
      Compare Selected Plans ({{ selectedIDs.length }})
      <mat-icon class="fas fa-chevron-right sml smr"></mat-icon>
    </button>
  </div>
</div>

<app-card-footer [firstBackgroundWhite]="false" [items]="['plans', 'features', 'free', 'demo']"
  [featureType]="featureType"></app-card-footer>
