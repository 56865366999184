<ipv-title-modal [title]="'Health Fund'" [hideAllDefaultButtons]="true" [icon]="''">
  <ng-container content>
    <ng-container *ngFor="let healthFund of fullHealthFunds">
      <mat-checkbox
        class="text-wrap"
        [(ngModel)]="selectedHealthFunds[healthFund.Label]"
        [value]="healthFund.Label"
        style="display: block; text-align: left"
      >
        <span style="color: black"> {{ healthFund['Label'] | titlecase }} </span>
      </mat-checkbox>
    </ng-container>
  </ng-container>
</ipv-title-modal>

<mat-dialog-actions style="flex-direction: row-reverse">
  <button class="button-save" [color]="'primary'" (click)="save()" mat-raised-button>Save</button>
</mat-dialog-actions>
