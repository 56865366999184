<div class="rel" *ngIf="invitationsDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>
<div [hidden]="invitationsDB.data.length == 0">
  <div class="row clearfix flex" [hidden]="displayInvitationView == true">
    <div class="half-width">
      <h1 class="dataHeader">
        <span> Invitations</span>
        <span class="badge" id="totalInvitation">
          <mat-icon>email</mat-icon>
          <span [endVal]="dataSource.filteredData.length" countUp></span>
        </span>
      </h1>
    </div>

    <div class="pull-right toggles" *ngIf="false" [hidden]="displayInvitationView == true">
      <mat-button-toggle-group #group1="matButtonToggleGroup" [(ngModel)]="selectedTreatmentValue"
                               [ngClass]="{ disableStyle: activeButton == false && status != null }">
        <mat-button-toggle class="pos" [value]="'proceed'" matTooltip="Proceeding">
          <mat-icon>mood</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle class="maybe" [value]="'maybe'" matTooltip="Maybe">
          <mat-icon>sentiment_satisfied</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle class="neg" [value]="'notProceed'" matTooltip="Not Proceeding">
          <mat-icon>mood_bad</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
      <button class="" *ngIf="status" (click)="reactive()" mat-button color="primary">Active Me</button>
    </div>
  </div>
  <div [hidden]="displayInvitationView == true">
    <mat-card class="list-card">
      <!-- search -->
      <div class="row clearfix searchArea primary-gradient-img">
        <div class="row justify flex">
          <div class="full-width row clearfix flex">
            <mat-icon class="schIcon mt">search</mat-icon>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>First Name</mat-label>
              <input class="clearfix" (input)="setFilter($event.target.value, 'FirstName')" name="FirstName"
                     placeholder="" matInput/>
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Last Name</mat-label>
              <input class="clearfix" (input)="setFilter($event.target.value, 'LastName')" name="LastName"
                     placeholder="" matInput/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Mobile Number</mat-label>
              <input class="clearfix" (input)="setFilter(convertMobile($event.target.value), 'Mobile')" name="LastMobileName"
                     placeholder="" matInput/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Email</mat-label>
              <input class="clearfix" (input)="setFilter($event.target.value, 'Email')" name="Email" placeholder=""
                     matInput/>
            </mat-form-field>
          </div>

          <div class="ml timeOutput clearfix" *ngIf="dateFrom != null || dateTo != null">
            <mat-icon class="pull-left mt">schedule</mat-icon>
            <div class="pull-right">
              <p class="small rm-mb">
                <span *ngIf="dateFrom">From:
                  <strong>{{ dateFrom | customDateTime }} </strong>
                </span>
              </p>
              <p class="small rm-mt">
                <span *ngIf="dateTo">
                  To:
                  <strong>{{ dateTo | customDateTime }}</strong>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="full-width clearfix flex animated flexwrap fadeInDown" *ngIf="searchVals.value?.length > 0">
          <!-- <app-date-input *ngIf="searchVals.value?.indexOf('Date') !== -1 " class=" full-width" [isClear]="true" name="dateFrom"
          (selectedDate)="dateFromEvent($event)" [label]="dateFromLabel"></app-date-input>
            <app-date-input *ngIf="searchVals.value?.indexOf('Date') !== -1 " class=" full-width" [isClear]="true" name="dateTo"
            (selectedDate)="dateToEvent($event)" [label]="dateToLabel"></app-date-input> -->

          <mat-form-field class="mat-select-adjust full-width" *ngIf="searchVals.value?.indexOf('Dentist') !== -1"
                          appearance="outline">
            <mat-label>{{ "KEYWORD.practitioner" | translate | titlecase }}</mat-label>
            <mat-select class="full-width" (selectionChange)="setFilter($event.value, 'DentistContactName')"
                        name="DentistContactName" placeholder="">
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option *ngFor="
                  let a of invitationsDB.data
                    | objectPropertyValue: 'DentistContactName'
                    | unique
                    | without: ['', null]
                    | orderBy
                " [value]="a">
                {{ a }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Amount') !== -1" appearance="outline">
            <mat-label>Amount</mat-label>
            <input class="clearfix" (input)="setFilter($event.target.value, 'treatment.Value')" name="Amount"
                   placeholder="" CurrenyMask matInput/>
          </mat-form-field>
          <mat-form-field class="mat-select-adjust full-width" *ngIf="searchVals.value?.indexOf('Product') !== -1"
                          appearance="outline">
            <mat-label>Product</mat-label>
            <mat-select class="full-width" (selectionChange)="setFilter($event.value, 'Product.Label.Marketing')"
                        name="Product" placeholder="">
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option *ngFor="
                  let p of invitationsDB.data
                    | objectPropertyValue: 'Product.Label.Marketing'
                    | unique
                    | without: ['', null]
                    | orderBy
                " [value]="p">
                {{ p }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="mat-select-adjust full-width" *ngIf="searchVals.value?.indexOf('Product Group') !== -1"
                          appearance="outline">
            <mat-label>Product Group</mat-label>
            <mat-select class="full-width" (selectionChange)="setFilter($event.value, 'Product.ThisGroupOnly.Label')"
                        name="ProductGroup" placeholder="">
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option *ngFor="
                  let pg of invitationsDB.data
                    | objectPropertyValue: 'Product.ThisGroupOnly.Label'
                    | unique
                    | without: ['', null]
                    | orderBy
                " [value]="pg">
                {{ pg }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="mat-select-adjust full-width" *ngIf="searchVals.value?.indexOf('Email Status') !== -1"
                          appearance="outline">
            <mat-label>Email Status</mat-label>
            <mat-select class="full-width" (selectionChange)="setFilter($event.value, 'Status.Email.Short_NE')"
                        name="emailStatus" placeholder="">
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option *ngFor="
                  let e of invitationsDB.data
                    | objectPropertyValue: 'Status.Email.Short_NE'
                    | unique
                    | without: ['', null]
                    | orderBy
                " [value]="e">
                {{ e }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="mat-select-adjust full-width"
                          *ngIf="searchVals.value?.indexOf('Application Status') !== -1" appearance="outline">
            <mat-label>Application Status</mat-label>
            <mat-select class="full-width" (selectionChange)="setFilter($event.value, 'Status.Application.Short_NE')"
                        name="AppStatus" placeholder="">
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option *ngFor="
                  let a of invitationsDB.data
                    | objectPropertyValue: 'Status.Application.Short_NE'
                    | unique
                    | without: ['', null]
                    | orderBy
                " [value]="a">
                {{ a }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="mat-select-adjust full-width"
                          *ngIf="searchVals.value?.indexOf('Contract Status') !== -1" appearance="outline">
            <mat-label>Contract Status</mat-label>
            <mat-select class="full-width" (selectionChange)="setFilter($event.value, 'Status.Contract.Short_NE')" c
                        name="ContractStatus" placeholder="">
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option *ngFor="
                  let c of invitationsDB.data
                    | objectPropertyValue: 'Status.Contract.Short_NE'
                    | unique
                    | without: ['', null]
                    | orderBy
                " [value]="c">
                {{ c }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="mat-select-adjust full-width"
                          *ngIf="searchVals.value?.indexOf('Invitation Status') !== -1" appearance="outline">
            <mat-label>Invitation Status</mat-label>
            <mat-select class="full-width" (selectionChange)="setFilter($event.value, 'Status.Invitation.Short_NE')"
                        name="invitationStatus" placeholder="">
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option *ngFor="
                  let i of invitationsDB.data
                    | objectPropertyValue: 'Status.Invitation.Short_NE'
                    | unique
                    | without: ['', null]
                    | orderBy
                " [value]="i">
                {{ i }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <table #table [dataSource]="dataSource" [class.isStretched]="stretchList" mat-table matSort>
        <ng-container matColumnDef="Customer" sticky>
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Customer</th>
          <td class="rel" *matCellDef="let row"
              [ngStyle]="{ 'border-left': '3px solid ' + row['Status.Invitation.Colour'] }" mat-cell>
            <span class="content" *ngIf="!row['isMerged']; else merged" (click)="openInviteViewDetails(row)">
              {{ row.FirstName | lowercase | ucfirst }} {{ row.LastName | lowercase | ucfirst }}
            </span>
            <ng-template #merged>
              <span class="content full-width flex">
                <span class="full-width" (click)="openInviteViewDetails(row)">
                  {{ row.FirstName | lowercase | ucfirst }}
                  {{ row.LastName | lowercase | ucfirst }}
                </span>
                <span class="btn-show-all full-width ml text-right" (click)="expendItems(row)">
                  Various (show all <strong *ngIf="row.mergedLength">{{ row.mergedLength }}</strong>)
                </span>
              </span>
            </ng-template>

            <span class="colour" [ngStyle]="{ background: row['Status.Invitation.Colour'] }"></span>
          </td>
        </ng-container>

        <!-- Date Column -->
        <ng-container matColumnDef="Date">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Date</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row)" mat-cell>
            {{ row['DateTimeCreated'] | customDateTime }}
          </td>
        </ng-container>

        <!-- Merchant Column -->
        <ng-container matColumnDef="Merchant">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Merchant</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row)" mat-cell>
            {{ row.MerchantName }}
          </td>
        </ng-container>

        <!-- Operator Column -->
        <ng-container matColumnDef="Operator">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Operator</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row)" mat-cell>
            {{ row.DentistContactName }}
          </td>
        </ng-container>

        <!-- Amount Column -->
        <ng-container matColumnDef="Amount">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Amount</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row)" mat-cell>
            {{ row['treatment.Value'] | customCurrency }}
          </td>
        </ng-container>

        <!-- Product Column -->
        <ng-container matColumnDef="Product">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Product</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row)" mat-cell>
            {{ row['Product.Label.Marketing'] }}
          </td>
        </ng-container>

        <!-- Product Group Column -->
        <ng-container matColumnDef="ProductGroup">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Product Group</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row)" mat-cell
              matTooltip="{{ row['Product.ThisGroupOnly.Label'] }}">
            {{ row['Product.ThisGroupOnly.Code'] }}
          </td>
        </ng-container>

        <ng-container matColumnDef="mobile">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Mobile</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row)" mat-cell
              matTooltip="{{ row['Mobile'] | customPhone }}">
            {{ row['Mobile'] | customPhone }}
          </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Email</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row)" mat-cell matTooltip="{{ row['Email'] }}">
            {{ row['Email'] }}
          </td>
        </ng-container>

        <ng-container matColumnDef="InformedConsent">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Informed Consent</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row)" mat-cell
              matTooltip="Signed {{ row['InformedConsent.Given.When'] | customDate }}">
            <span class="list-label" *ngIf="row['InformedConsent.Given.Flag'] == '1'" [style.background]="'#6fb662'">
              <mat-icon class="consent">check</mat-icon> Consent
            </span>
            <span *ngIf="row['InformedConsent.Given.Flag'] == '0'"></span>
          </td>
        </ng-container>

        <!-- Source Column -->
        <ng-container matColumnDef="Source">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Source</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row)" mat-cell>
            <span *ngIf="row['PatientChoice.Code'] == 'COLD'" matTooltip="Web"><i class="fas fa-globe"></i></span>
            <span *ngIf="row['PatientChoice.Code'] != 'COLD'" matTooltip="Invitation"><i
              class="fas fa-envelope"></i></span>
            <span *ngIf="row['SendInvite.ViaSMS'] == '1'" matTooltip="Included SMS"><i
              class="fas fa-mobile-alt"></i></span>
          </td>
        </ng-container>

        <!-- EmailStatus Column -->
        <ng-container matColumnDef="EmailStatus">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Email status</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row)" mat-cell>
            <span class="list-label" [style.background]="row['Status.Email.Colour'] || '#b4bcc1'">{{
              row['Status.Email.Short_NE'] }}
            </span>
          </td>
        </ng-container>

        <!-- InvitationStatus Column -->
        <ng-container matColumnDef="InvitationStatus">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Invitation status</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row)" mat-cell>
            <span class="list-label" [style.background]="row['Status.Invitation.Colour'] || '#b4bcc1'">{{
              row['Status.Invitation.Short_NE'] }}
            </span>
          </td>
        </ng-container>

        <!-- ApplicationStatus Column -->
        <ng-container matColumnDef="ApplicationStatus">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Application status</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row)" mat-cell>
            <span class="list-label" [style.background]="row['Status.Application.Colour'] || '#b4bcc1'">{{
              row['Status.Application.Short_NE'] }}
            </span>
          </td>
        </ng-container>

        <!-- ContractStatus Column -->
        <ng-container matColumnDef="ContractStatus">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Contract Status</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row)" mat-cell>
            <span class="list-label" [style.background]="row['Status.Contract.Colour'] || '#b4bcc1'">{{
              row['Status.Contract.Short_NE']
              }}</span>
          </td>
        </ng-container>

        <!-- timeElapsed Column -->
        <ng-container matColumnDef="timeElapsed">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Time Elapsed</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row)" mat-cell>
            <span class="list-label" [style.background]="utils.daysPastColor(row['DateTimeCreated'])"
                  matTooltip="{{ row['DateTimeCreated'] | customDateTime }}">
              {{ row['DateTimeCreated'] | TimeLabelPast }} ago
            </span>
          </td>
        </ng-container>

        <!-- timeInvited Column -->
        <ng-container matColumnDef="timeInvited">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Invited</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row)" mat-cell>
            <span class="list-label" [style.background]="utils.daysPastColor(row['DateTimeCreated'])"
                  matTooltip="{{ row['DateTimeCreated'] | customDateTime }}">
              {{ row['DateTimeCreated'] | TimeLabelPast }} ago
            </span>
          </td>
        </ng-container>

        <!-- timeResponded Column -->
        <ng-container matColumnDef="timeResponded">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Responded</th>
          <td *matCellDef="let row" (click)="openInviteViewDetails(row)" mat-cell>
            <span class="list-label" [style.background]="utils.daysPastColor(row['Date.Responded'])"
                  matTooltip="{{ row['Date.Responded'] }}">
              {{ row['Date.Responded'] | TimeLabelPast }} ago
            </span>
          </td>
        </ng-container>

        <!-- Response Column -->
        <ng-container matColumnDef="Response">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Response</th>
          <td *matCellDef="let row" [matTooltip]="
              row['PatientChoice.Code'] == 'DSMO' || row['PatientChoice.Code'] == 'NOT'
                ? 'Clicking to view ' + ('KEYWORD.patient' | translate) + ' response'
                : ''
            " (click)="openPatientResponse(row)" mat-cell>
            <mat-icon class="fas fa-grimace" *ngIf="row['PatientChoice.Code'] == 'DSMO'"></mat-icon>
            <mat-icon class="fas fa-grin-squint-tears" *ngIf="row['PatientChoice.Code'] == 'NOT'"></mat-icon>
            {{ row['PatientChoice.Label_LongThirdPerson'] }}
          </td>
        </ng-container>

        <!-- fieldColor Column -->
        <ng-container matColumnDef="fieldColor">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Colour</th>
          <td *matCellDef="let row" [ngStyle]="{ background: row['Status.Invitation.Colour'] }" mat-cell></td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="Actions" stickyEnd>
          <th *matHeaderCellDef mat-header-cell>Actions</th>
          <td *matCellDef="let row" mat-cell>
            <button [matMenuTriggerFor]="menu" mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu class="actionMenu" #menu="matMenu">
              <button (click)="openInviteViewDetails(row)" mat-menu-item>
                <mat-icon>search</mat-icon>
                <span>Quick View</span>
              </button>

              <button (click)="createNote(row)" mat-menu-item>
                <mat-icon>note_add</mat-icon>
                <span>Create Note</span>
              </button>

              <button (click)="viewNotes(row['Prospect_key'])" mat-menu-item>
                <mat-icon class="fa fa-list-alt"></mat-icon>
                <span>View notes</span>
              </button>

              <button *ngIf="
                  row &&
                  row['ID'] &&
                  row['Prospect_key'] &&
                  row['Merchant_key'] &&
                  isModulePatientPortalAccessActive == true
                " (click)="openAuthCodeDialog(row)" mat-menu-item>
                <mat-icon>vpn_key</mat-icon>
                <span>{{"KEYWORD.patient" | translate | titlecase}} Portal Access</span>
              </button>

              <mat-divider></mat-divider>

              <!-- profile sub menu -->

              <button [matMenuTriggerFor]="submenuProfiles" mat-menu-item>
                <mat-icon>portrait</mat-icon>
                Information - {{ row.FirstName | lowercase | ucfirst }} {{ row.LastName | lowercase | ucfirst }}
              </button>
              <mat-menu #submenuProfiles="matMenu">
                <button *ngIf="
                    row['Prospect_key'] &&
                    row['Prospect_key'] != '00000000000000000000000000000000' &&
                    row['Prospect_key'] != '20202020202020202020202020202020'
                  " (click)="viewPatient(row['Prospect_key'])" mat-menu-item>
                  <mat-icon class="fas fa-user"></mat-icon>
                  <span>Profile - {{ row.FirstName | lowercase | ucfirst }}
                    {{ row.LastName | lowercase | ucfirst }}
                  </span>
                </button>

                <button
                  *ngIf="row['DentistContact_key'] && row['DentistContact_key'] != '00000000000000000000000000000000'"
                  (click)="viewOperator(row['DentistContact_key'])" mat-menu-item>
                  <mat-icon class="fas fa-user-mat"></mat-icon>
                  <span>{{ "KEYWORD.dentist" | translate | titlecase }} Profile</span>
                </button>

                <mat-divider></mat-divider>

                <button *ngIf="row['Email']" (click)="contact(row['ID'])" mat-menu-item>
                  <mat-icon>contact_mail</mat-icon>
                  <span>Contact - {{ row.FirstName | lowercase | ucfirst }}
                    {{ row.LastName | lowercase | ucfirst }}</span>
                </button>

                <mat-divider></mat-divider>

                <button *ngIf="row['Email']" [matMenuTriggerFor]="treatmentInvite" mat-menu-item>
                  <mat-icon>face</mat-icon>
                  <span>Invite - {{"KEYWORD.Treatment" | translate}}</span>
                </button>

                <mat-menu #treatmentInvite="matMenu">
                  <div *ngIf="financedProductGroup">
                    <div *ngFor="let p of financedProductGroup">
                      <button (click)="linkToInvitation(p, row['Prospect_key'])" mat-menu-item
                              matTooltip="New {{ productUtil.getLabel(p['GroupCode']) }} Invitation">
                        New <strong>{{ productUtil.getLabel(p['GroupCode']) }}</strong> Invitation
                        <mat-icon
                          class="position fas {{ productUtil.getProductGroupIcon(p['GroupCode']) }} "></mat-icon>
                      </button>
                      <mat-divider></mat-divider>
                    </div>
                  </div>
                </mat-menu>
              </mat-menu>

              <button (click)="resendInvitation(row)" mat-menu-item>
                <mat-icon>refresh</mat-icon>
                <span>Resend Invitation</span>
              </button>

              <div>
                <button *ngIf="voided != true" (click)="deleteInvitation(row['ID'])" mat-menu-item>
                  <mat-icon class="warn-color">delete</mat-icon>
                  <span class="warn-color">Archive</span>
                </button>
              </div>
            </mat-menu>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
      </table>

      <div class="row pagination flex">
        <p class="small info left">
          <mat-icon>info</mat-icon>
          <strong>What do all these statuses mean?</strong>See below for more information on how your {{
          "KEYWORD.patient" | translate }}s interact
          with the platform.
        </p>

        <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="pageSize"
                       [pageSizeOptions]="pageSizeOptions">
        </mat-paginator>
      </div>
      <!-- legacy table removed after a2b5c01 -->
    </mat-card>

    <app-message-response-legend></app-message-response-legend>
  </div>
  <div *ngIf="invitationID != null && displayInvitationView == true">
    <div class="full-width rel" *ngIf="showInvDetailInList == true">
      <button class="absPos btn-back" (click)="cancelInviteViewDetails()" mat-raised-button color="accent">
        <mat-icon>chevron_left</mat-icon>

        <span>Show all {{ "KEYWORD.patient" | translate }} invitations</span>
      </button>
    </div>

    <app-invitation-view [membership]="membership" [invitationID]="invitationID"></app-invitation-view>

    <div class="full-width text-center" *ngIf="showInvDetailInList == false">
      <button class="mt" (click)="cancelInviteViewDetails()" mat-raised-button color="accent">
        <mat-icon>chevron_left</mat-icon>
        <span>Go Back</span>
      </button>
    </div>
  </div>
</div>
<div *ngIf="invitationsDB.data.length == 0 && invitationsDB.firstLoadEvent != false">
  <app-empty-list-message *ngIf="!purposeCode || (purposeCode && purposeCode != 'COM_UHH' && purposeCode != 'COM_GGL')">
  </app-empty-list-message>
  <app-empty-list-message *ngIf="purposeCode && purposeCode == 'COM_UHH'" [actionIcon]="'add'" [isAction]="true"
                          [actionLabel]="'New invitation'"
                          (actionEvent)="createHealthHistoryInvitation()"></app-empty-list-message>

  <app-empty-list-message *ngIf="purposeCode && purposeCode == 'COM_GGL'" [actionIcon]="'add'" [isAction]="true"
                          [actionLabel]="'New invitation'"
                          (actionEvent)="createGoogleReviewInvitation()"></app-empty-list-message>
</div>
