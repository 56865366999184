import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-medical-history-invitation-redirection',
  templateUrl: './medical-history-invitation-redirection.component.html',
  styleUrls: ['./medical-history-invitation-redirection.component.css'],
})
export class MedicalHistoryInvitationRedirectionComponent implements OnInit {
  patientID;
  link = 'medical-history-invitation-create';

  constructor(private activeRoute: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['patientID']) {
        this.patientID = params['patientID'];
      }

      if (this.patientID) {
        this.router.navigate(['/merchant', { outlets: { page: ['medical-history', this.link, this.patientID] } }], {
          replaceUrl: true,
        });
      } else {
        this.router.navigate(['/merchant', { outlets: { page: ['medical-history', this.link] } }], {
          replaceUrl: true,
        });
      }
    });
  }
}
