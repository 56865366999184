import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { QuillEditorComponent } from 'ngx-quill';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { DefaultMessageUtilClass } from '../../default-message/shared/defaultMessageUtil';
import { MerchantService } from '../shared/merchant.service';

@Component({
  selector: 'app-merchant-informed-consent',
  templateUrl: './merchant-informed-consent.component.html',
  styleUrls: ['./merchant-informed-consent.component.css'],
})
export class MerchantInformedConsentComponent implements OnInit {
  @Input()
  merchantID;

  @Output()
  getResult = new EventEmitter();

  informedConsent;
  _informedConsent;

  utilMessage = new DefaultMessageUtilClass();

  cursorPos;

  isPromoterOrAdmin = false;

  option = 'desktop';

  isEdit = false;
  sessionType = 'guest';
  @ViewChild('editor', { static: false }) editor: QuillEditorComponent;

  constructor(private authenticationService: AuthenticationService, private merchantService: MerchantService) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
      this.authenticationService.getSessionType().subscribe((sessionType) => {
        if (sessionType) {
          this.sessionType = sessionType;
        }
        if (this.isPromoterOrAdmin == true && this.merchantID) {
          this.merchantService.getMerchantDetails(this.merchantID, {}, this.sessionType).subscribe((res) => {
            if (res) {
              this.informedConsent = res['InformedConsent_Declaration'];
            }
          });
        } else {
          this.authenticationService.getCurrentPracticeForce().subscribe((res) => {
            this.informedConsent = res['InformedConsent_Declaration'];
          });
        }
      });
    });
  }

  addPersonalisation(p) {
    // if (this.cursorPos) {
    //   this.editor.quillEditor.insertText(this.cursorPos.index, p.value)
    // }
    // else {
    //   let length = this.editor.quillEditor.getLength();
    //   this.editor.quillEditor.insertText(length, p.value)
    // }

    this.informedConsent = this.informedConsent + ' ' + p.value;
  }

  getContent(c) {
    this._informedConsent = c;
  }

  onChange() {
    const temp = this.editor.quillEditor.getSelection();
    this.cursorPos = temp;
  }

  save() {
    const payload = {
      informedConsent: this._informedConsent,
    };
    this.authenticationService.editCurrentPractice(payload).subscribe((res) => {
      // this.authenticationService.getCurrentUserForce().subscribe(res1 => {
      NotifyAppComponent.displayToast('success', 'Successed', 'Your informed consent has been updated successfully');

      this.informedConsent = res['InformedConsent_Declaration'];
      // })
    });
  }

  showEditView() {
    this.isEdit = !this.isEdit;

    if (this.isEdit == true) {
      this._informedConsent = this.informedConsent;
    }
  }
}
