import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import * as $ from 'jquery';
import { ZoomInInfoComponent } from '../../../core/helper/zoom-in-info/zoom-in-info.component';
import { RootAppComponent } from '../root-component/root-component.component';

@Component({
  selector: 'app-view-image-simple',
  templateUrl: './view-image-simple.component.html',
  styleUrls: ['./view-image-simple.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ViewImageSimpleComponent implements OnInit {
  @Input()
  image =
    'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/default-image-thumbnail.png)';

  @Input()
  defaultImage =
    'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/default-image-thumbnail.png)';

  _defaultImage;
  @Output()
  close = new EventEmitter();

  pinchZoomProperties = [
    {
      wheel: true,
      autoHeight: true,
    },
  ];

  isFullScreenModal = false;

  isError = false;
  isLoaded = false;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.link) {
        this.image = data.link;
      }

      if (data.defaultImage) {
        this.defaultImage = data.defaultImage;
      }
    }
  }

  ngOnInit() {
    if (this.defaultImage && this.defaultImage.indexOf('url') == -1) {
      this._defaultImage = 'url(' + this.defaultImage + ')';
    } else if (this.defaultImage && this.defaultImage.indexOf('url') != -1) {
      this._defaultImage = this.defaultImage;
    }
    this.displayZoomInInfo();
  }

  closeEvent() {
    this.close.emit(true);
  }

  srcWithoutURL(d) {
    let _d: String;
    if (d) {
      if (d.indexOf('url') != -1) {
        _d = _d.replace('url(', '');

        if (_d[_d.length - 1] == ')') {
          _d = _d.slice(0, -1);
        }
      }
    }

    return _d;
  }

  onLoad() {
    this.isLoaded = true;
    this.isError = false;
  }

  onError() {
    this.isLoaded = false;
    this.isError = true;
  }
  fullScreenModal() {
    if (this.isFullScreenModal != true) {
      $('.mat-dialog-container').last().addClass('full-screen');
      this.isFullScreenModal = true;
    } else {
      $('.mat-dialog-container').removeClass('full-screen');
      this.isFullScreenModal = false;
    }
  }

  ngOnDestroy() {
    if (this.isFullScreenModal == true) {
      $('.mat-dialog-container').removeClass('full-screen');
    }
  }

  displayZoomInInfo() {
    let show = false;

    if (localStorage.getItem('zoomInImageModule') === 'true') {
      show = true;
    }

    if (show !== true) {
      const ref = RootAppComponent.dialog.open(ZoomInInfoComponent, {
        data: {},
        width: '650px',
        height: '700px',
        panelClass: 'noCard',
      });

      const sub = ref.componentInstance.close.subscribe((data) => {
        ref.close();
      });
    }
  }
}
