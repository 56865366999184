/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./template-list-overview.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../template-list/template-list.component.ngfactory";
import * as i3 from "../../../shared/pipes/custom-date.pipe";
import * as i4 from "../template-list/template-list.component";
import * as i5 from "@angular/router";
import * as i6 from "../../../core/authentication/shared/authentication.service";
import * as i7 from "../shared/template-service";
import * as i8 from "../../../shared/services/utils.service";
import * as i9 from "./template-list-overview.component";
var styles_TemplateListOverviewComponent = [i0.styles];
var RenderType_TemplateListOverviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TemplateListOverviewComponent, data: { "animation": [{ type: 7, name: "ngIfAnimation", definitions: [{ type: 1, expr: "void => *", animation: [{ type: 11, selector: "*", animation: { type: 6, styles: { opacity: 0 }, offset: null }, options: { optional: true } }, { type: 11, selector: "*", animation: { type: 12, timings: "100ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 0, transform: "translateY(-75%)", offset: 0, height: 0 }, offset: null }, { type: 6, styles: { opacity: 1, transform: "translateY(0)", offset: 1, height: "*" }, offset: null }] }, timings: "0.8s ease-out" }] }, options: { optional: true } }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 11, selector: "*", animation: { type: 6, styles: { opacity: 1 }, offset: null }, options: { optional: true } }, { type: 11, selector: "*", animation: { type: 12, timings: "100ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 1, transform: "translateY(0)", offset: 0, height: "*" }, offset: null }, { type: 6, styles: { opacity: 0, transform: "translateY(-75%)", offset: 1, height: 0 }, offset: null }] }, timings: "0.8s ease-in" }] }, options: { optional: true } }], options: null }], options: {} }] } });
export { RenderType_TemplateListOverviewComponent as RenderType_TemplateListOverviewComponent };
export function View_TemplateListOverviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-template-list", [], null, null, null, i2.View_TemplateListComponent_0, i2.RenderType_TemplateListComponent)), i1.ɵprd(512, null, i3.CustomDatePipe, i3.CustomDatePipe, []), i1.ɵdid(2, 770048, null, 0, i4.TemplateListComponent, [i5.Router, i3.CustomDatePipe, i6.AuthenticationService, i7.TemplateService, i8.UtilsService], { pageSize: [0, "pageSize"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pageSize; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TemplateListOverviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-template-list-overview", [], null, null, null, View_TemplateListOverviewComponent_0, RenderType_TemplateListOverviewComponent)), i1.ɵdid(1, 114688, null, 0, i9.TemplateListOverviewComponent, [i6.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TemplateListOverviewComponentNgFactory = i1.ɵccf("app-template-list-overview", i9.TemplateListOverviewComponent, View_TemplateListOverviewComponent_Host_0, { inputMerchantID: "inputMerchantID", hideFilter: "hideFilter", pageSize: "pageSize" }, {}, []);
export { TemplateListOverviewComponentNgFactory as TemplateListOverviewComponentNgFactory };
