import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { PersonalCircumstancesService } from '../shared/personal-circumstances.service';

@Component({
  selector: 'app-pci-investment-property',
  templateUrl: './investment-property.component.html',
  styleUrls: ['./investment-property.component.css'],
})
export class PCIInvestmentPropertyComponent implements OnInit {
  @Input()
  personalCirID;

  @Input()
  pciEntryObj;

  @Input()
  disabled = false;

  @Output()
  close = new EventEmitter();

  @Output()
  getResult = new EventEmitter();

  isModal = false;

  propertyOwner;

  pcDetail = {
    updatedReason: null,
    updatedComment: null,
    infoDetails: null,
    incomeValue: null,
    incomeFrequency: null,
    infoIncomeTotalAmount: null,
    infoIncomeTotalFrequency: null,
    infoIncomeActual: null,
    infoIncomeDeemed: null,
    infoOwned: null,
    infoWhoOwns: null,
    infoNumberofOwners: null,
    infoAgentName: null,
    infoPropertyManager: null,
    infoAgentContact: null,
    infoMarketValue: null,
    infoEquityPercent: null,
    infoBankCode: null,
    infoTypeOfLoanCode: null,
    infoAmountBorrowed: null,
    infoFacilityLimit: null,
    expenseValue: null,
    expenseFrequency: null,
    infoRepaymentValue: null,
    infoRepaymentFrequency: null,
    assetValue: null,
    liabilityValue: null,
    addressUnit: null,
    addressStreetNumber: null,
    addressStreetName: null,
    addressStreetType: null,
    addressStreetSuburb: null,
    addressStreetPostCode: null,
    addressStreetState: null,
    modifiedField: null,
    fileID: null,
    maintenanceExpense: 0,
    ratesExpense: 0,
    agentFeesExpense: 0,
    totalExpense: 0,
    applicantExpense: 0,
    deemedExpense: 0,
  };

  isPromoterOrAdmin = false;

  persCircumUpdReasons = [];
  propertyOwnerList = [];
  frequencies = [];
  bankList = [];
  loanTypeList = [];

  isValidAddress = false;

  // maintenance = 0;
  // rates = 0;
  // agentFees = 0;
  // totalExpenses = 0;
  // applicantExpense = 0;
  // deemedExpense = 0;

  constructor(
    private authenticationService: AuthenticationService,
    private pciService: PersonalCircumstancesService,
    private lookupService: LookupService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.personalCirID) {
        this.personalCirID = data.personalCirID;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
      if (this.isPromoterOrAdmin == true) {
        this.getDetails();

        this.lookup();
      }
    });
  }

  lookup() {
    this.lookupService.getLookup('CodeLookup', 'PersCircumUpdReason').subscribe((res) => {
      if (res) {
        this.persCircumUpdReasons = res;
      }
    });

    this.lookupService.getLookup('CodeLookup', 'PropertyOwner').subscribe((res) => {
      if (res) {
        this.propertyOwnerList = res;
      }
    });

    this.lookupService.getLookup('CodeLookup', 'Frequency').subscribe((res) => {
      if (res) {
        this.frequencies = res;
      }
    });

    this.pciService.getBankList().subscribe((res) => {
      if (res) {
        this.bankList = res;
      }
    });

    this.lookupService.getLookup('CodeLookup', 'HomeLoanType').subscribe((res) => {
      if (res) {
        this.loanTypeList = res;
      }
    });
  }

  submit() {
    this.pcDetail.incomeValue = this.pcDetail.infoIncomeDeemed;
    this.pcDetail.expenseValue = this.pcDetail.deemedExpense;

    this.pciService.editPCIInvestmentProperty(this.pciEntryObj['ID'], this.pcDetail).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast('success', 'Modify record', 'You have successfully modified this record');

        this.pciEntryObj = res;

        this.getDetails();

        const modifiedField = this.pciEntryObj['Modified_Fields'].split('|');
        for (let i = 0; i < modifiedField.length; i++) {
          this.highlightField(modifiedField[i]);
        }

        this.getResult.emit(res);
      }
    });
  }

  create() {
    this.pcDetail.incomeValue = this.pcDetail.infoIncomeDeemed;
    this.pcDetail.expenseValue = this.pcDetail.deemedExpense;
    this.pcDetail['personalCircumstanceID'] = this.personalCirID;

    this.pciService.createPCIInvestmentProperty(this.pcDetail).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast('success', 'Create record', 'You have successfully created a record');

        this.getResult.emit(res);
      }
    });
  }

  getDetails() {
    if (this.pciEntryObj) {
      if (this.pciEntryObj['Updating_Reason.Code']) {
        this.pcDetail.updatedReason = this.pciEntryObj['Updating_Reason.Code'];
      }

      if (this.pciEntryObj['Updating_Reason.Comment']) {
        this.pcDetail.updatedComment = this.pciEntryObj['Updating_Reason.Comment'];
      }

      if (this.pciEntryObj['Info.MarketValue']) {
        this.pcDetail.infoMarketValue = this.pciEntryObj['Info.MarketValue'];
      }

      if (this.pciEntryObj['Info.WhoOwns']) {
        this.pcDetail.infoWhoOwns = this.pciEntryObj['Info.WhoOwns'];

        this.ownerChange(this.pcDetail.infoWhoOwns);
      }

      if (this.pciEntryObj['Info.NumberOfOwners']) {
        this.pcDetail.infoNumberofOwners = this.pciEntryObj['Info.NumberOfOwners'];
      }

      // if (this.pciEntryObj['Info.EquityPercent']) {
      //   this.pcDetail.infoEquityPercent = this.pciEntryObj['Info.EquityPercent'];
      // }

      // if (this.pciEntryObj['Asset.Value']) {
      //   this.pcDetail.assetValue = this.pciEntryObj['Asset.Value'];
      // }

      // if (this.pciEntryObj['Info.Income.Actual']) {
      //   this.pcDetail.infoIncomeActual = this.pciEntryObj['Info.Income.Actual'];
      // }

      if (this.pciEntryObj['Info.Income.Deemed']) {
        this.pcDetail.infoIncomeDeemed = this.pciEntryObj['Info.Income.Deemed'];
      }

      if (this.pciEntryObj['Info.IncomeTotal.Amount']) {
        this.pcDetail.infoIncomeTotalAmount = this.pciEntryObj['Info.IncomeTotal.Amount'];

        if (this.pciEntryObj['Info.EquityPercent']) {
          this.pcDetail.infoIncomeActual =
            Number(this.pciEntryObj['Info.IncomeTotal.Amount']) *
            (Number(this.pciEntryObj['Info.EquityPercent']) / 100);
        }
      }

      if (this.pciEntryObj['Info.IncomeTotal.Frequency']) {
        this.pcDetail.infoIncomeTotalFrequency = this.pciEntryObj['Info.IncomeTotal.Frequency'];
      }

      if (this.pciEntryObj['Expense.Value']) {
        this.pcDetail.expenseValue = this.pciEntryObj['Expense.Value'];
      }

      if (this.pciEntryObj['Expense.Frequency']) {
        this.pcDetail.expenseFrequency = this.pciEntryObj['Expense.Frequency'];
      }

      if (this.pciEntryObj['MaintenanceAllowanceExpense']) {
        this.pcDetail.maintenanceExpense = this.pciEntryObj['MaintenanceAllowanceExpense'];
      }

      if (this.pciEntryObj['RatesExpense']) {
        this.pcDetail.ratesExpense = this.pciEntryObj['RatesExpense'];
      }

      if (this.pciEntryObj['AgentFeesExpense']) {
        this.pcDetail.agentFeesExpense = this.pciEntryObj['AgentFeesExpense'];
      }

      if (this.pciEntryObj['Info.Expense.TotalAmount']) {
        this.pcDetail.totalExpense = this.pciEntryObj['Info.Expense.TotalAmount'];
      }

      if (this.pciEntryObj['Info.Expense.Actual']) {
        this.pcDetail.applicantExpense = this.pciEntryObj['Info.Expense.Actual'];
      }

      if (this.pciEntryObj['Info.Expense.Deemed']) {
        this.pcDetail.deemedExpense = this.pciEntryObj['Info.Expense.Deemed'];
      }

      if (this.pciEntryObj['Info.Owned']) {
        this.pcDetail.infoOwned = this.pciEntryObj['Info.Owned'];
      }

      if (this.pciEntryObj['Info.Bank.Code']) {
        this.pcDetail.infoBankCode = this.pciEntryObj['Info.Bank.Code'];
      }

      if (this.pciEntryObj['Info.TypeOfLoan.Code']) {
        this.pcDetail.infoTypeOfLoanCode = this.pciEntryObj['Info.TypeOfLoan.Code'];
      }

      if (this.pciEntryObj['Info.AmountInitiallyBorrowed']) {
        this.pcDetail.infoAmountBorrowed = this.pciEntryObj['Info.AmountInitiallyBorrowed'];
      }

      if (this.pciEntryObj['Liability.Value']) {
        this.pcDetail.liabilityValue = this.pciEntryObj['Liability.Value'];
      }

      if (this.pciEntryObj['Info.RepaymentsTotal.Amount']) {
        this.pcDetail.infoRepaymentValue = this.pciEntryObj['Info.RepaymentsTotal.Amount'];
      }

      if (this.pciEntryObj['Info.RepaymentsTotal.Frequency']) {
        this.pcDetail.infoRepaymentFrequency = this.pciEntryObj['Info.RepaymentsTotal.Frequency'];
      }

      if (this.pciEntryObj['Modified_Fields']) {
        this.pcDetail.modifiedField = this.pciEntryObj['Modified_Fields'];
      }

      if (this.pciEntryObj['Address.Suburb']) {
        this.pcDetail.addressStreetSuburb = this.pciEntryObj['Address.Suburb'];
      }

      if (this.pciEntryObj['Address.State']) {
        this.pcDetail.addressStreetState = this.pciEntryObj['Address.State'];
      }

      if (this.pciEntryObj['Address.Postcode']) {
        this.pcDetail.addressStreetPostCode = this.pciEntryObj['Address.Postcode'];
      }

      if (this.pciEntryObj['Address.StreetNr']) {
        this.pcDetail.addressStreetNumber = this.pciEntryObj['Address.StreetNr'];
      }

      if (this.pciEntryObj['Address.StreetName']) {
        this.pcDetail.addressStreetName = this.pciEntryObj['Address.StreetName'];
      }

      if (this.pciEntryObj['Address.Unit']) {
        this.pcDetail.addressUnit = this.pciEntryObj['Address.Unit'];
      }

      if (this.pciEntryObj['Address.StreetType.Abbreviated']) {
        this.pcDetail.addressStreetType = this.pciEntryObj['Address.StreetType.Abbreviated'];
      }
    }
  }

  getInvestmentPropertyAddress(address) {
    if (address) {
      this.pcDetail.addressUnit = address.unitNumber;
      this.pcDetail.addressStreetNumber = address.streetNumber;
      this.pcDetail.addressStreetName = address.streetName;
      this.pcDetail.addressStreetType = address.streetType;
      this.pcDetail.addressStreetSuburb = address.suburb;
      this.pcDetail.addressStreetState = address.state;
      this.pcDetail.addressStreetPostCode = address.postCode;
    }
  }

  isInvestmentPropertyAddressValidEvent(v) {
    this.isValidAddress = v;
  }

  closeModalEvent() {
    this.close.emit(true);
  }

  onCompleteAll(e) {
    if (e && e[0]) {
      this.pcDetail.fileID = e[0];
    }
  }

  removeFile(e) {
    if (e && e == this.pcDetail.fileID) {
      this.pcDetail.fileID = null;
    }
  }

  highlightField(field) {
    let result = '';

    if (this.isModal == false && this.pcDetail.modifiedField) {
      const _modifiedField = this.pcDetail.modifiedField.split('|');

      if (_modifiedField.indexOf(field) != -1) {
        result = 'highlight';
      }
    }

    return result;
  }

  ownerChange(e) {
    if (e == 'CUS') {
      this.pcDetail.infoEquityPercent = 100;
    } else if (e == 'CSP') {
      this.pcDetail.infoEquityPercent = 50;
    }

    this.equityPercentChangeEvent();

    if (this.pcDetail.infoMarketValue) {
      this.calcuateEquityValue(this.pcDetail.infoEquityPercent);
    }
  }

  calcuateEquityPercent(e) {
    this.pcDetail.infoEquityPercent = 100 / e;

    this.equityPercentChangeEvent();

    if (this.pcDetail.infoMarketValue) {
      this.calcuateEquityValue(this.pcDetail.infoEquityPercent);
    }
  }

  calcuateEquityValue(e) {
    this.pcDetail.assetValue = this.pcDetail.infoMarketValue * (this.pcDetail.infoEquityPercent / 100);
  }

  calculateApplicantShareRentPaid(e) {
    if (this.pcDetail.infoEquityPercent) {
      this.pcDetail.infoIncomeActual = e * (this.pcDetail.infoEquityPercent / 100);
      this.pcDetail.infoIncomeDeemed = this.pcDetail.infoIncomeActual;
    }
  }

  maintenanceValueChange(e) {
    if (e) {
      // this.pcDetail.expenseValue += Number(e.substring(2));
      this.pcDetail.maintenanceExpense = Number(e);
      this.calculateExpense();
    }
  }

  reatesValueChange(e) {
    if (e) {
      // this.pcDetail.expenseValue += Number(e.substring(2));
      this.pcDetail.ratesExpense = Number(e);
      this.calculateExpense();
    }
  }

  agentFeesValueChange(e) {
    if (e) {
      // this.pcDetail.expenseValue += Number(e.substring(2));
      this.pcDetail.agentFeesExpense = Number(e);
      this.calculateExpense();
    }
  }

  calculateExpense() {
    this.pcDetail.totalExpense =
      Number(this.pcDetail.maintenanceExpense) +
      Number(this.pcDetail.ratesExpense) +
      Number(this.pcDetail.agentFeesExpense);
    this.pcDetail.applicantExpense =
      (Number(this.pcDetail.maintenanceExpense) +
        Number(this.pcDetail.ratesExpense) +
        Number(this.pcDetail.agentFeesExpense)) *
      (Number(this.pcDetail.infoEquityPercent) / 100);
    this.pcDetail.deemedExpense =
      (Number(this.pcDetail.maintenanceExpense) +
        Number(this.pcDetail.ratesExpense) +
        Number(this.pcDetail.agentFeesExpense)) *
      (Number(this.pcDetail.infoEquityPercent) / 100);
  }

  equityPercentChangeEvent() {
    this.pcDetail.infoIncomeActual =
      Number(this.pcDetail.infoIncomeTotalAmount) * (Number(this.pcDetail.infoEquityPercent) / 100);
    this.pcDetail.infoIncomeDeemed =
      Number(this.pcDetail.infoIncomeTotalAmount) * (Number(this.pcDetail.infoEquityPercent) / 100);
    this.pcDetail.applicantExpense =
      (Number(this.pcDetail.maintenanceExpense) +
        Number(this.pcDetail.ratesExpense) +
        Number(this.pcDetail.agentFeesExpense)) *
      (Number(this.pcDetail.infoEquityPercent) / 100);
    this.pcDetail.deemedExpense =
      (Number(this.pcDetail.maintenanceExpense) +
        Number(this.pcDetail.ratesExpense) +
        Number(this.pcDetail.agentFeesExpense)) *
      (Number(this.pcDetail.infoEquityPercent) / 100);
  }
}
