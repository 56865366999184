<div class="negative-corner bg-light-gray">
  <div id="blueBg"></div>

  <section class="introduction">
    <div class="container">
      <div class="row clearfix introduction-hero">
        <div class="text pull-right half-width">
          <div class="col-left">
            <h1 class="white-header">Everything you need to go digital</h1>
            <p class="white lead">
              Welcome to the first step on your journey to growing your
              {{ "KEYWORD.practice" | translate }}! This page will help you and your team with everything they need to
              start communicating digitally.
            </p>
          </div>
        </div>
        <div class="text pull-left half-width">
          <img class="getting-started-image"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/gettingstarted-intro.png"
               mat-card-image/>
        </div>
      </div>
    </div>
  </section>

  <section class="top-section">
    <div class="container bg-white topIntro">
      <h2 class="heading-text">Getting Started</h2>
      <p class="paragraph-default">
        A new platform can be overwhelming - there are so many new features to explore, and you aren't sure where to
        start. Taking those first few steps can be daunting, but the hard part is over! We have made it easy for you to
        get started with these three simple steps. Click on each step, follow the instructions, and you will be up and
        running in no time.
      </p>
    </div>
    <div class="steps-container">
      <ul class="steps">
        <li>
          <a [routerLink]="['/merchant', { outlets: { page: ['setting-up'] } }]">
            <span class="steps-counter">1</span>
            <p class="getting-started-page">Setting Up</p>
          </a>
        </li>
        <li>
          <a [routerLink]="['/merchant', { outlets: { page: ['sending-treatments-plans'] } }]">
            <span class="steps-counter">2</span>
            <p class="getting-started-page">Sending {{"PHRASE.treatment plan" | translate | titlecase}}s</p>
          </a>
        </li>
        <li>
          <a [routerLink]="['/merchant', { outlets: { page: ['getting-paid'] } }]">
            <span class="steps-counter">3</span>
            <p class="getting-started-page">Getting Paid</p>
          </a>
        </li>
      </ul>
    </div>
  </section>

  <section class="bg-gray">
    <div class="container">
      <div class="basic-column">
        <div class="column with-image">
          <img class="column-image download-image"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/gettingstarted-img1.png"
               alt=""/>
        </div>
        <div class="column">
          <h3 class="text-red heading-text download-heading">Read Me!</h3>
          <p class="download-paragraph">
            We're created a detailed User Guide to help take you through each feature of your
            {{ "KEYWORD.practice" | translate }} portal. With insights and helpful links to articles, you will be a
            portal pro in no time!
          </p>
          <a class="btn-large mt btn-red" mat-raised-button color="accent"
            href="https://email-graphics-smileright.s3-ap-southeast-2.amazonaws.com/pdfs/Smile_Right_User+Guide_Online.pdf"
             target="_blank">Download User Guide
            <mat-icon fontSet="fas" fontIcon="fa-angle-right"></mat-icon>
          </a>
        </div>
      </div>
    </div>
  </section>

  <section class="bg-white">
    <div class="container">
      <div class="basic-column centered-column">
        <div class="column with-image">
          <img class="column-image help-image"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/gettingstarted-img2.png"
               alt=""/>
        </div>
        <div class="column">
          <h4 class="heading-text heading-sub help-subheading">
            Need some more detailed help? Visit the help centre located on your portal's side menu for access to easy to
            follow "how-to" articles.
          </h4>
          <a class="btn-large mt btn-red" [routerLink]="['/wiki/']" mat-raised-button color="accent"
             target="_blank">View help centre
            <mat-icon fontSet="fas" fontIcon="fa-angle-right"></mat-icon>
          </a>
        </div>
      </div>
    </div>
  </section>

  <section class="bg-gray">
    <div class="container">
      <div class="basic-column centered-column">
        <div class="column with-image">
          <img class="book-image"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/gettingstarted-img3.jpg"
               alt=""/>
        </div>
        <div class="column">
          <h4 class="heading-text heading-sub">Want to be shown how?</h4>
          <p class="getting-started-page">
            Learning a new skill is difficult, and trying to learn it all by yourself doesn't help either. But fear not!
            To tackle this issue
            {{ "BRANDING.Brand Name" | translate | titlecase }} created the Academy!
          </p>
          <p class="getting-started-page">
            Get unlimited access to our expert team who will help you become a portal pro and effectively communicate
            with your {{ "KEYWORD.patient" | translate }}s. We have a range of sessions available, simple just select
            what you want to focus on, choose a time that suits you, and you're booked!
          </p>
          <a class="btn-large mt btn-red" mat-raised-button color="accent"
             href="https://get.smileright.com.au/smile-right-academy" target="_blank">Book Training
            <mat-icon fontSet="fas" fontIcon="fa-angle-right"></mat-icon>
          </a>
        </div>
        <div class="subfooter-info">
          <p class="getting-started-page">Online Chatbot: 24/7365</p>
          <p class="getting-started-page">Email: {{"BRANDING.Contact Training Email" | translate}}</p>
        </div>
        <div class="subfooter-info">
          <p class="getting-started-page">Phone: 1300 <strong>OR</strong> (08)</p>
          <p class="getting-started-page">{{"BRANDING.Operating Hours AEST" | translate}}</p>
        </div>
      </div>
    </div>
  </section>
</div>
