import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MerchantService } from '../shared/merchant.service';

import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { DentistViewComponent } from '../../dentist/dentist-view/dentist-view.component';
import { DentistService } from '../../dentist/shared/dentist.service';
import { MerchantViewComponent } from '../merchant-view/merchant-view.component';

@Component({
  selector: 'app-merchant-trading-hours-overview',
  templateUrl: './merchant-trading-hours-overview.component.html',
  styleUrls: ['./merchant-trading-hours-overview.component.css'],
})
export class MerchantTradingHoursOverviewComponent implements OnInit {
  @Input() merchantID;

  @Input() isSetup = false;

  @Input() isMerchant = true;
  @Input() isDentist = false;
  @Input()
  dentistID;

  isModal = false;
  isPromoterOrAdmin = false;

  @Output() close = new EventEmitter();

  @Output() getTradingHours = new EventEmitter();

  merchants = [];
  title;

  sessionType = 'merchant';
  dentists;
  loaded = false;
  constructor(
    private dentistService: DentistService,
    private authenticationService: AuthenticationService,
    private merchantService: MerchantService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.merchantID = data.merchantID;
      this.dentistID = data.dentistID;

      if (data.isSetup != null) {
        this.isSetup = data.isSetup;
      }

      if (data.isMerchant != null) {
        this.isMerchant = data.isMerchant;
      }

      if (data.isDentist != null) {
        this.isDentist = data.isDentist;
      }
      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      if (this.isPromoterOrAdmin == true) {
        if (this.isMerchant) {
          const payload = {
            fields: 'ID,TradingAs,CalculatedName,Status',
          };
          this.merchantService.getList(payload).subscribe((res) => {
            this.merchants = res;
          });
        }
        if (this.isDentist == true) {
          if (this.isPromoterOrAdmin == true) {
            const _payload = {
              merchantID: this.merchantID,
              fields: 'ID,TradingAs,CalculatedName',
            };

            this.dentistService.getList(_payload, this.isPromoterOrAdmin).subscribe((res) => {
              if (res) {
                this.dentists = res;
              }
            });
          }
        }
      }

      this.loaded = true;
    });
  }

  closeEvent() {
    this.close.emit(true);
  }

  viewMerchant() {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: this.merchantID,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewDentist() {
    if (this.dentistID) {
      const ref = RootAppComponent.dialog.open(DentistViewComponent, {
        data: this.dentistID,
        width: '600px',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }
  closeModal(e) {
    if (e == true) {
      this.close.emit(e);
    }
  }
}
