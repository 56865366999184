<div class="row clearfix padded mb">
  <div class="rel" *ngIf="existingPatientsDB && existingPatientsDB.firstLoadEvent == false" @ngIfAnimation>
    <div class="row clearfix padded rel">
      <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
    </div>
  </div>

  <div class="pull-right">
    <!-- <button mat-raised-button class="btn-small" color="primary">{{"PatientLookUp"
  | translate}} <mat-icon>contacts</mat-icon></button> -->

    <mat-form-field class="mr" *ngIf="marketingFilters.length > 0 && false" appearance="outline">
      <mat-label>Marketing Filter</mat-label>
      <mat-select
        class=""
        (selectionChange)="changeFilter($event.value)"
        name="marketingFilter"
        placeholder="Marketing Filter"
      >
        <mat-option (click)="addNewFilter()">
          <p class="small rm-mt rm-mb">
            <mat-icon class="addnew">add</mat-icon>
            Create new
          </p>
        </mat-option>
        <mat-divider></mat-divider>
        <mat-option [value]="null">
          All
        </mat-option>
        <mat-option *ngFor="let a of marketingFilters" [value]="a">
          {{ a['Label'] }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!--  / bulk actions -->

    <button class="btn-small" (click)="openNewScan()" mat-raised-button color="accent" style="margin-right: 10px">
      <mat-icon>add</mat-icon> CREATE NEW SCAN
    </button>

    <button class="btn-small" mat-raised-button color="accent">
      <mat-icon>group_add</mat-icon> CREATE NEW AUDIENCE
    </button>

    <!--     <mat-menu #appPatientMenu="matMenu">
      <button mat-menu-item [routerLink]="['/merchant/', {outlets: {'page': ['existing-patient-create']}}]">
        <mat-icon>person_add</mat-icon> Add new patient
      </button>
      <button mat-menu-item>
        <mat-icon>face</mat-icon> Invite - Update Profile
      </button>
    </mat-menu> -->

    <!-- <button mat-raised-button color="primary" class="btn-small ml" (click)="uploadData()">
    <mat-icon class="fas fa-users"></mat-icon> {{"UploadDataImport" | translate}}
  </button> -->
  </div>

  <div [hidden]="existingPatientsDB.data.length == 0">
    <div [hidden]="displayDetailCard == true || displayInvitations == true">
      <div class="row clearfix">
        <p class="pull-right" *ngIf="dateFrom != null || dateTo != null">
          <span *ngIf="dateFrom"
          >From:
            <strong>{{ dateFrom | customDateTime }} </strong>
          </span>

          <span *ngIf="dateTo">
            To:
            <strong>{{ dateTo | customDateTime }}</strong>
          </span>
        </p>

        <h1 class="dataHeader rm-mt rm-mb pull-left">
          {{ title }}:
          <span class="badge" [endVal]="existingPatientsDB.data.length" countUp></span>
        </h1>
      </div>

      <mat-card class="list-card">
        <!-- search -->
        <div class="row clearfix searchArea primary-gradient-img">
          <div class="row clearfix flex">
            <div class="full-width clearfix flex animated fadeInDown">
              <mat-form-field class="full-width white" appearance="outline">
                <mat-label>First Name</mat-label>
                <input
                  class="clearfix"
                  (input)="setFilter($event.target.value, 'FirstName')"
                  name="FirstName"
                  placeholder="First Name"
                  matInput
                />
              </mat-form-field>
              <mat-form-field class="full-width white" appearance="outline">
                <mat-label>Last Name</mat-label>
                <input
                  class="clearfix"
                  (input)="setFilter($event.target.value, 'Name')"
                  name="LastName"
                  placeholder="Last Name"
                  matInput
                />
              </mat-form-field>

              <app-date-input
                class="qtr-width"
                [label]="'Date From'"
                [isClear]="true"
                [defaultDate]="null"
                (selectedDate)="setFilter($event, 'fromDate')"
                name="fromDate"
              ></app-date-input>

              <app-date-input
                class="qtr-width"
                [label]="'Date To'"
                [isClear]="true"
                [defaultDate]="null"
                (selectedDate)="setFilter($event, 'toDate')"
                name="toDate"
              ></app-date-input>

              <mat-form-field class="full-width white" appearance="outline">
                <mat-label>Operator Name</mat-label>
                <input class="clearfix" name="operatorName" placeholder="Operator Name" matInput/>
              </mat-form-field>

              <mat-form-field class="white full-width" *ngIf="merchantTags.length > 0" appearance="outline">
                <mat-label>Tag</mat-label>
                <mat-select class="" (selectionChange)="setFilter($event.value, 'Tags')" name="Tag" placeholder="Tag">
                  <mat-option [value]="null">
                    All
                  </mat-option>
                  <mat-option *ngFor="let a of merchantTags | orderBy" [value]="a">
                    {{ a }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <table class="list-table" #table [dataSource]="dataSource" mat-table matSort>
          <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
          <!-- ID Column -->

          <ng-container cdkColumnDef="FirstName" sticky>
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Customer</th>
            <td
              *matCellDef="let row"
              (click)="openDentalXrayReport(row['ID'])"
              mat-cell
              matTooltip="{{ row.CalculatedName | lowercase | ucfirst }}"
            >
              {{ row.CalculatedName | lowercase | ucfirst }}
            </td>
          </ng-container>

          <ng-container cdkColumnDef="tags">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Tags</th>
            <td *matCellDef="let row" (click)="openDentalXrayReport(row['ID'])" mat-cell>
              <mat-chip-list>
                <mat-chip *ngFor="let tag of row['Tags']">{{ tag }}</mat-chip>
              </mat-chip-list>
            </td>
          </ng-container>

          <!-- Action Button Column -->
          <ng-container cdkColumnDef="Actions" stickyEnd>
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Actions</th>
            <td *matCellDef="let row" mat-cell>
              <div style="float: right">
                <button [matMenuTriggerFor]="menu" mat-icon-button>
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button (click)="openDentalXrayReport(row['ID'])" mat-menu-item>
                    <mat-icon class="fa fa-file-contract"></mat-icon>
                    <span>Create Guarantee</span>
                  </button>

                  <button (click)="openDentalXrayReport(row['ID'])" mat-menu-item>
                    <mat-icon>group</mat-icon>
                    <span>Create Membership</span>
                  </button>
                </mat-menu>
              </div>
            </td>
          </ng-container>

          <!-- <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row> -->

          <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
        </table>
        <mat-paginator
          #paginator
          [length]="dataSource.filteredData.length"
          [pageIndex]="0"
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions"
        >
        </mat-paginator>
      </mat-card>
    </div>
  </div>

  <div *ngIf="existingPatientsDB && existingPatientsDB.data.length == 0 && existingPatientsDB.firstLoadEvent != false">
    <app-empty-list-message></app-empty-list-message>
  </div>
</div>
