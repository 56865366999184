import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'sr-google-review-reply-dialog',
  templateUrl: './google-review-reply-dialog.component.html',
  styleUrls: ['./google-review-reply-dialog.component.css'],
})
export class GoogleReviewReplyDialogComponent implements OnInit {
  @Input() clientName = 'Client Name';
  @Input() comment = null;
  @Input() profilePhotoReviewLink = null;
  @Input() starRating = null;
  @Input() editMode = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { clientName: string; comment: string; profilePhotoReviewLink: string; starRating: number },
    private dialogRef: MatDialogRef<GoogleReviewReplyDialogComponent>
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.clientName = this.data.clientName;
      this.comment = this.data.comment;
      this.profilePhotoReviewLink = this.data.profilePhotoReviewLink;
      this.starRating = this.data.starRating;

      if (this.comment === null) {
        this.editMode = true;
      }
    }
  }

  sendReply() {
    this.dialogRef.close(this.comment);
  }

  enableEditMode() {
    this.editMode = true;
  }

  close() {
    this.dialogRef.close(false);
  }
}
