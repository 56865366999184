import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../shared/services/utils.service';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { MarketingService } from '../shared/marketing.service';

@Component({
  selector: 'app-marketing-redirection',
  templateUrl: './marketing-redirection.component.html',
  styleUrls: ['./marketing-redirection.component.css'],
})
export class MarketingRedirectionComponent implements OnInit {
  settings = Settings.global;
  linkCode;
  linkID;
  isPromoterOrAdmin = false;

  utils = new UtilsClass();

  constructor(
    private router: Router,
    private utilService: UtilsService,
    private marketingService: MarketingService,
    private activeRouter: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activeRouter.params.subscribe((params) => {
      if (params['linkCode']) {
        this.linkCode = params['linkCode'];
      }

      if (params['linkID']) {
        this.linkID = params['linkID'];
      }

      if (this.linkID) {
        if (this.linkCode == 'm') {
          const paylaod = {
            fields: 'LandingPageCode,ID',
          };
          this.marketingService
            .getMarketingTemplatePromoter(this.linkID, paylaod, this.isPromoterOrAdmin)
            .subscribe((template) => {
              if (template) {
                if (template.LandingPageCode) {
                  const postURL = this.utils.buildTemplateURL(template.LandingPageCode, this.linkID);

                  window.location.href = postURL;
                }
              } else {
                window.location.href = this.settings['publicSiteFrontendLink'];
              }
            });
        } else if (this.linkCode == 'i') {
          this.router.navigate(['/consumer', { outlets: { page: ['invitation-card', this.linkID] } }], {
            replaceUrl: true,
          });
        } else if (this.linkCode == 'l') {
          this.utilService.getShortcutLink(this.linkID).subscribe((res) => {
            if (res && res['link']) {
              window.location.href = res['link'];
            } else {
              window.location.href = this.settings['publicSiteFrontendLink'];
            }
          });
        } else {
          window.location.href = this.settings['publicSiteFrontendLink'];
        }
      } else {
        window.location.href = this.settings['publicSiteFrontendLink'];
      }
    });
  }
}
