<mat-card class="card">
  <div class="colour-stripe"></div>
  <div class="row flex title">
    <h3 class="sr-title sm-h3 rm-m">Draw-Down Details</h3>
    <button mat-raised-button color="accent" disabled>
      <mat-icon>add</mat-icon>
      New
    </button>
  </div>
  <hr/>
  <div class="row clearfix">
    <table class="full-width" [dataSource]="drawDownSource" mat-table>
      <ng-container matColumnDef="invoiceDate">
        <th *matHeaderCellDef mat-header-cell>Invoice Date</th>
        <td *matCellDef="let drawdown" mat-cell>{{ drawdown.invoiceDate }}</td>
        <td *matFooterCellDef mat-footer-cell><strong>Total</strong></td>
      </ng-container>

      <ng-container matColumnDef="reference">
        <th *matHeaderCellDef mat-header-cell>Reference</th>
        <td *matCellDef="let drawdown" mat-cell>{{ drawdown.reference }}</td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th *matHeaderCellDef mat-header-cell>Description</th>
        <td *matCellDef="let drawdown" mat-cell>{{ drawdown.description }}</td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th *matHeaderCellDef mat-header-cell>Amount</th>
        <td *matCellDef="let drawdown" mat-cell>{{ drawdown.amount | customCurrency }}</td>
        <td *matFooterCellDef mat-footer-cell>
          <strong>{{ getTotalCost() | customCurrency }}</strong>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th *matHeaderCellDef mat-header-cell>Status</th>
        <td *matCellDef="let drawdown" mat-cell>{{ drawdown.status }}</td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>

      <ng-container matColumnDef="paidTo">
        <th *matHeaderCellDef mat-header-cell>Paid to</th>
        <td *matCellDef="let drawdown" mat-cell>{{ drawdown.paidTo }}</td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
      <tr *matFooterRowDef="displayedColumns" mat-footer-row></tr>
    </table>
  </div>
</mat-card>

<mat-card class="card">
  <div class="colour-stripe"></div>
  <div class="row flex title">
    <h3 class="sr-title sm-h3 rm-m">Payment to {{ "KEYWORD.practitioner" | translate | titlecase }} and/or
      Beneficiary</h3>
    <button mat-raised-button color="accent" disabled>
      <mat-icon>add</mat-icon>
      New
    </button>
  </div>
  <hr/>

  <div class="row clearfix">
    <table class="full-width" [dataSource]="paymentSource" mat-table>
      <ng-container matColumnDef="due">
        <th *matHeaderCellDef mat-header-cell>Due</th>
        <td *matCellDef="let drawdown" mat-cell>{{ drawdown.due }}</td>
        <td *matFooterCellDef mat-footer-cell><strong>Total</strong></td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th *matHeaderCellDef mat-header-cell>Status</th>
        <td *matCellDef="let drawdown" mat-cell>{{ drawdown.status }}</td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th *matHeaderCellDef mat-header-cell>Amount</th>
        <td *matCellDef="let drawdown" mat-cell>{{ drawdown.amount | customCurrency }}</td>
        <td *matFooterCellDef mat-footer-cell>
          <strong>{{ getTotalPayment() | customCurrency }}</strong>
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th *matHeaderCellDef mat-header-cell>Type</th>
        <td *matCellDef="let drawdown" mat-cell>{{ drawdown.type }}</td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th *matHeaderCellDef mat-header-cell>Description</th>
        <td *matCellDef="let drawdown" mat-cell>{{ drawdown.description }}</td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>

      <ng-container matColumnDef="invoice">
        <th *matHeaderCellDef mat-header-cell>Invoice</th>
        <td *matCellDef="let drawdown" mat-cell>{{ drawdown.invoice }}</td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>

      <ng-container matColumnDef="account">
        <th *matHeaderCellDef mat-header-cell>Account</th>
        <td *matCellDef="let drawdown" mat-cell>{{ drawdown.account }}</td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th *matHeaderCellDef mat-header-cell>ID</th>
        <td *matCellDef="let drawdown" mat-cell>{{ drawdown.id }}</td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns2" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns2" mat-row></tr>
      <tr *matFooterRowDef="displayedColumns2" mat-footer-row></tr>
    </table>
  </div>
</mat-card>
