import { HttpClient, HttpParams } from '@angular/common/http';
import { ComponentStore } from '@ngrx/component-store';
import { environment } from 'environments/environment';
import { map, switchMap } from 'rxjs/operators';
import { AppStateService } from '../../../../core/app-state/app-state.service';
import { UtilsService } from '../../../../shared/services/utils.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../../shared/services/utils.service";
import * as i3 from "../../../../core/app-state/app-state.service";
class TranslationState {
}
export class TranslationService extends ComponentStore {
    constructor(httpClient, utilsService, appStateService) {
        super({
            contextFilter: 'default',
            translation: [],
            searchTextFilter: '',
        });
        this.httpClient = httpClient;
        this.utilsService = utilsService;
        this.appStateService = appStateService;
        this.baseUrl = environment.nodeUrl;
    }
    getContextFilter$() {
        return this.select((state) => state.contextFilter);
    }
    setContextFilter(contextFilter) {
        this.patchState({ contextFilter });
    }
    getSearchTextFilter$() {
        return this.select((state) => state.searchTextFilter);
    }
    setSearchTextFilter(searchTextFilter) {
        this.patchState({ searchTextFilter });
    }
    createTranslation$(payload) {
        const finalPayload = {
            code: payload.context,
            content: payload.translation,
            apply: payload.apply,
            updateDefault: payload.updateDefault,
        };
        const endpoint = 'translation-admin';
        const url = `${this.baseUrl}/${endpoint}`;
        return this.httpClient.post(url, finalPayload);
    }
    fetchContextTranslations$(payload) {
        return this.appStateService.getAppState$().pipe(switchMap((appState) => {
            const params = this.utilsService.getHttpParamsFromPayload(payload);
            const options = { params };
            const endpoint = `translation-admin/${payload.context}`;
            const url = `${this.baseUrl}/${endpoint}`;
            return this.httpClient.get(url, options).pipe(map((response) => {
                return response['data']['content'];
            }));
        }));
    }
    deleteTranslation(code) {
        const endpoint = `translation-admin/${code}`;
        const url = `${this.baseUrl}/${endpoint}`;
        return this.httpClient.delete(url).pipe(map((response) => {
            return response['data'];
        }));
    }
    exportTranslation() {
        const endpoint = `translation-admin/export`;
        const url = `${this.baseUrl}/${endpoint}`;
        return this.httpClient.get(url).pipe(map((response) => {
            return response['data'];
        }));
    }
    importTranslation(payload) {
        const endpoint = `translation-admin/import`;
        const url = `${this.baseUrl}/${endpoint}`;
        return this.httpClient.post(url, payload).pipe(map((response) => {
            return response['data'];
        }));
    }
    createTranslation(payload) {
        const endpoint = `translation-admin`;
        const url = `${this.baseUrl}/${endpoint}`;
        return this.httpClient.post(url, payload).pipe(map((response) => {
            return response['data'];
        }));
    }
    getTranslation(id, body) {
        let params = new HttpParams();
        let options = {};
        for (const key in body) {
            if (body.hasOwnProperty(key)) {
                params = params.set(key, body[key]);
            }
        }
        options = {
            params,
        };
        const endpoint = `translation-admin/${id}`;
        const url = `${this.baseUrl}/${endpoint}`;
        return this.httpClient.get(url, options).pipe(map((response) => {
            return response['data'];
        }));
    }
}
TranslationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TranslationService_Factory() { return new TranslationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.UtilsService), i0.ɵɵinject(i3.AppStateService)); }, token: TranslationService, providedIn: "root" });
