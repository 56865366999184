import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { selectAppState } from './app-state.selectors';
import { AppState } from './app-state.type';

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  constructor(private store: Store) {}

  getAppState$(): Observable<AppState> {
    return this.store.select(selectAppState).pipe(take(1));
  }
}
