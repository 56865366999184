import { Component, OnInit } from '@angular/core';
import { TasksService } from '../shared/tasks.service';

import { SideMenuService } from '../../../shared/services/side-menu.service';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

@Component({
  selector: 'app-tasks-summary-list',
  templateUrl: './tasks-summary-list.component.html',
  styleUrls: ['./tasks-summary-list.component.css'],
})
export class TasksSummaryListComponent implements OnInit {
  listObject = [];

  merchantID;
  displayMerchantTasks = false;

  tasks = [];

  constructor(private tasksService: TasksService) {}

  ngOnInit() {
    SideMenuService.goBack.subscribe((res) => {
      if (res == true) {
        this.merchantID = null;
        this.displayMerchantTasks = false;
      }
    });

    const p = {
      orderBy: 'Name',
    };
    this.tasksService.getPracticeTaskList(p).subscribe((res) => {
      if (res) {
        this.tasks = res;
      }
    });

    this.setup();
  }

  setup() {
    const payload = {
      orderBy: 'Card.TradingAs,Task.Name',
      fields: 'Task.Name,IsCompleted,Card_key,Card.TradingAs',
      section: 1,
    };

    this.tasksService.getFullAssignedTasksList(payload).subscribe((res) => {
      if (res) {
        this.listObject = res;

        const innerFunction = (section) => {
          section = section + 1;
          UtilsClass.loadingDataSection(section);
          payload.section = section;

          this.tasksService.getFullAssignedTasksList(payload).subscribe((res) => {
            if (res) {
              if (res.length > 0) {
                this.listObject = this.listObject.concat(res);

                innerFunction(payload.section);
              }
            }
          });
        };

        innerFunction(1);
        UtilsClass.stopLoadingInterceptor();
      }
    });
  }

  groupBy(objectArray, property) {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  openMerchantTask(id) {
    this.merchantID = id;
    this.displayMerchantTasks = true;
  }

  hideMerchantTask() {
    this.merchantID = null;
    this.displayMerchantTasks = false;
    this.setup();
  }

  getTask(id, list) {
    return list.filter((x) => x['Card_key'] == id);
  }

  getMerchantName(id) {
    return this.listObject.find((x) => x['Card_key'] == id)['Card.TradingAs'];
  }
}
