import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../../src/environments/environment';
import { AppStateService } from '../../../core/app-state/app-state.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { DataResponse } from '../../../shared/types/data-response.type';
import { QueryParameters } from '../../../shared/types/query-parameters.type';

export class InformedConsent {
  'ID': string;
  readonly 'DateTimeCreated': string;
  readonly 'LastModified': string;
  readonly 'LastModified_Human': string;
  'TreatmentPlan_key': string;
  'Invitation_key': string;
  'Invitation.FirstName': string;
  'Invitation.LastName': string;
  'Merchant_key': string;
  'Merchant.Name': string;
  'Services.Presented.When': string;
  'Services.Presented.Flag': '0' | '1';
  'InformedConsent.Given.When': string;
  'InformedConsent.Given.Flag': '0' | '1';
  'Documents.Presented': string;
  'Documents.Viewed': string;
  'PatientDeclaration': string;
  'PatientDeclaration_Doc_Key': string;
  'Invitation.Email': string;
}

export class InformedConsentQuery {
  payload: Partial<InformedConsent> &
    QueryParameters & {
      invitationID?: string;
      merchantID?: string;
      DateFrom?: string;
      DateTo?: string;
      'Invitation.FirstName'?: string;
      'Invitation.LastName'?: string;
      'Invitation.Email'?: string;
      'InformedConsent.Given.Flag'?: string;
      merchantIdFilter?: string;
      dateFromFilter?: string;
      dateToFilter?: string;
      firstNameFilter?: string;
      lastNameFilter?: string;
      emailFilter?: string;
      isInformedConsentGivenFilter?: '0' | '1';
      isPromoterOrAdmin?: boolean;
    };
}

class InformedConsentState {
  informedConsentList: Partial<InformedConsent>[] | InformedConsent[];
  informedConsent: Partial<InformedConsent> | InformedConsent;
  dateRangeFilter: { startDate: string; endDate?: string };
  firstNameFilter: string;
  lastNameFilter: string;
  emailFilter: string;
  informedConsentGivenFlagFilter: '0' | '1';
  merchantID: string;
}

@Injectable()
export class InformedConsentService extends ComponentStore<InformedConsentState> {
  private baseUrl = environment.nodeUrl;

  constructor(
    private utilsService: UtilsService,
    private httpClient: HttpClient,
    private appStateService: AppStateService
  ) {
    super({
      informedConsent: null,
      informedConsentList: [],
      dateRangeFilter: {
        startDate: null,
        endDate: null,
      },
      firstNameFilter: null,
      lastNameFilter: null,
      emailFilter: null,
      informedConsentGivenFlagFilter: '1',
      merchantID: null,
    });
  }

  getInformedConsentList$<T extends InformedConsent | Partial<InformedConsent>>(): Observable<T[]> {
    return this.select((state) => state.informedConsentList) as Observable<T[]>;
  }

  getInformedConsentList<T extends InformedConsent | Partial<InformedConsent>>(): T[] {
    return this.get().informedConsentList as T[];
  }

  setInformedConsentList(informedConsentList: Partial<InformedConsent>[] | InformedConsent[]) {
    this.patchState({ informedConsentList });
  }

  fetchInformedConsentList$<T extends InformedConsent | Partial<InformedConsent>>(
    payload: InformedConsentQuery['payload']
  ): Observable<T[]> {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const params = this.utilsService.getHttpParamsFromPayload(payload);

        const options = { params };

        const endpoint = (() => {
          const promoterRoute = 'informed-consent/global';
          const merchantRoute = 'informed-consent';
          const customerRoute = 'informed-consent/customer';
          const publicRoute = 'informed-consent/public';
          switch (appState.sessionType) {
            case 'admin':
              return promoterRoute;
            case 'promoter':
              return promoterRoute;
            case 'merchant':
              return merchantRoute;
            case 'merchant-admin':
              return merchantRoute;
            case 'customer':
              return customerRoute;
            default:
              return publicRoute;
          }
        })();

        const url = `${this.baseUrl}/${endpoint}`;

        return this.httpClient.get<DataResponse<T[]>>(url, options).pipe(map((response) => response['data']));
      })
    );
  }

  getMerchantIdFilter$() {
    return this.select((state) => state.merchantID);
  }

  setMerchantIdFilter(merchantID: string) {
    this.patchState({ merchantID });
  }

  getFirstNameFilter$() {
    return this.select((state) => state.firstNameFilter);
  }

  setFirstNameFilter(firstNameFilter: string) {
    this.patchState({ firstNameFilter });
  }

  getLastNameFilter$() {
    return this.select((state) => state.lastNameFilter);
  }

  setLastNameFilter(lastNameFilter: string) {
    this.patchState({ lastNameFilter });
  }

  getEmailFilter$() {
    return this.select((state) => state.emailFilter);
  }

  setEmailFilter(emailFilter: string) {
    this.patchState({ emailFilter });
  }

  setInformedConsentGivenFilter(informedConsentGivenFlagFilter: '0' | '1') {
    this.patchState({ informedConsentGivenFlagFilter });
  }

  getInformedConsentGivenFilter$() {
    return this.select((state) => state.informedConsentGivenFlagFilter);
  }

  getDateRangeFilter$() {
    return this.select((state) => state.dateRangeFilter);
  }

  setDateRangeFilter(dateRangeFilter: { startDate: string; endDate: string }) {
    this.patchState({ dateRangeFilter });
  }

  fetchInformedConsent$<T extends InformedConsent | Partial<InformedConsent>>(id: string) {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const endpoint = (() => {
          const promoterRoute = 'informed-consent/global';
          const merchantRoute = 'informed-consent';
          const customerRoute = 'informed-consent/customer';
          const publicRoute = 'informed-consent/public';
          switch (appState.sessionType) {
            case 'admin':
              return promoterRoute;
            case 'promoter':
              return promoterRoute;
            case 'merchant':
              return merchantRoute;
            case 'merchant-admin':
              return merchantRoute;
            case 'customer':
              return customerRoute;
            default:
              return publicRoute;
          }
        })();

        const url = `${this.baseUrl}/${endpoint}/${id}`;

        return this.httpClient.get<DataResponse<T>>(url).pipe(map((response) => response['data']));
      })
    );
  }

  updateInformedConsent(id: string, payload: Partial<InformedConsent>) {
    const endpoint = `informed-consent/${id}`;
    const url = `${this.baseUrl}/${endpoint}`;

    return this.httpClient.put(url, payload).pipe(
      map((response: DataResponse<InformedConsent>) => response['data']),
      tap((informedConsent: InformedConsent) => {
        this.patchState({ informedConsent });

        if (this.get().informedConsentList.length > 0) {
          const currentInformedConsentList = this.get().informedConsentList;

          const itemIndex = currentInformedConsentList.findIndex((item) => item.ID === informedConsent.ID);

          if (itemIndex > -1) {
            currentInformedConsentList[itemIndex] = informedConsent;
            const updatedInformedConsentList = [...currentInformedConsentList];
            this.patchState({ informedConsentList: updatedInformedConsentList });
          }
        }
      })
    );
  }

  editInformedConsentList(informedConsent: InformedConsent) {
    const informedConsentList = this.get().informedConsentList;
    const itemIndex = informedConsentList.findIndex((item) => item.ID === informedConsent.ID);
    informedConsentList[itemIndex] = informedConsent;

    this.patchState({ informedConsentList });
  }

  createInformedConsentPDF(informedConsentID: string) {
    const endpoint = `informed-consent/createPDF/${informedConsentID}`;
    const url = `${this.baseUrl}/${endpoint}`;

    return this.httpClient.get(url).pipe(map((response: HttpResponse<any>) => response['data']));
  }
}
