<div class="center">
  <!-- control whether card is flippable -->
  <div class="card" [class.noflip]="flippable == false">
    <div class="base" [class.flip]="flippable == true">
      <!-- show credit card -->
      <div class="front cc" *ngIf="cardType == 'cc'">
        <div class="strip-bottom"></div>
        <div class="strip-top"></div>
        <svg class="logo" width="40" height="40" viewbox="0 0 17.5 16.2">
          <path d="M3.2 0l5.4 5.6L14.3 0l3.2 3v9L13 16.2V7.8l-4.4 4.1L4.5 8v8.2L0 12V3l3.2-3z" fill="white"></path>
        </svg>
        <div class="investor">Investor</div>
        <div class="chip">
          <div class="chip-line"></div>
          <div class="chip-line"></div>
          <div class="chip-line"></div>
          <div class="chip-line"></div>
          <div class="chip-main"></div>
        </div>
        <svg class="wave" viewBox="0 3.71 26.959 38.787" width="26.959" height="38.787" fill="white">
          <path
            d="M19.709 3.719c.266.043.5.187.656.406 4.125 5.207 6.594 11.781 6.594 18.938 0 7.156-2.469 13.73-6.594 18.937-.195.336-.57.531-.957.492a.9946.9946 0 0 1-.851-.66c-.129-.367-.035-.777.246-1.051 3.855-4.867 6.156-11.023 6.156-17.718 0-6.696-2.301-12.852-6.156-17.719-.262-.317-.301-.762-.102-1.121.204-.36.602-.559 1.008-.504z"
          ></path>
          <path
            d="M13.74 7.563c.231.039.442.164.594.343 3.508 4.059 5.625 9.371 5.625 15.157 0 5.785-2.113 11.097-5.625 15.156-.363.422-1 .472-1.422.109-.422-.363-.472-1-.109-1.422 3.211-3.711 5.156-8.551 5.156-13.843 0-5.293-1.949-10.133-5.156-13.844-.27-.309-.324-.75-.141-1.114.188-.367.578-.582.985-.542h.093z"
          ></path>
          <path
            d="M7.584 11.438c.227.031.438.144.594.312 2.953 2.863 4.781 6.875 4.781 11.313 0 4.433-1.828 8.449-4.781 11.312-.398.387-1.035.383-1.422-.016-.387-.398-.383-1.035.016-1.421 2.582-2.504 4.187-5.993 4.187-9.875 0-3.883-1.605-7.372-4.187-9.875-.321-.282-.426-.739-.266-1.133.164-.395.559-.641.984-.617h.094zM1.178 15.531c.121.02.238.063.344.125 2.633 1.414 4.437 4.215 4.437 7.407 0 3.195-1.797 5.996-4.437 7.406-.492.258-1.102.07-1.36-.422-.257-.492-.07-1.102.422-1.359 2.012-1.075 3.375-3.176 3.375-5.625 0-2.446-1.371-4.551-3.375-5.625-.441-.204-.676-.692-.551-1.165.122-.468.567-.785 1.051-.742h.094z"
          ></path>
        </svg>
        <div class="card-number">
          <div class="section">5453</div>
          <div class="section">2000</div>
          <div class="section">0000</div>
          <div class="section">0000</div>
        </div>
        <div class="end">
          <span class="end-text">exp. end:</span><span class="end-date"> {{ expiry }}</span>
        </div>
        <div class="card-holder">{{ name }}</div>
        <div class="card-brand">
          <div class="master">
            <div class="circle master-red"></div>
            <div class="circle master-yellow"></div>
          </div>
        </div>
      </div>

      <!-- show medicare or health fund -->
      <div class="front health {{ cardBrand }}" *ngIf="cardType == 'health'">
        <div class="row clearfix">
          <div class="brandname">
            <span *ngIf="cardBrand == 'medicare'">{{ cardBrand }}</span>
            <span *ngIf="cardBrand == 'Other'">{{ cardLabel }}</span>
            <img *ngIf="imgURL && cardBrand != 'medicare'" [src]="imgURL"/>
          </div>
        </div>

        <div class="row clearfix card-number">
          <p class="small rm-m" *ngIf="cardBrand != 'medicare'"><strong>member</strong></p>
          <div class="section">{{ number }}</div>
        </div>

        <div class="card-holder">
          {{ reference }} {{ name }}
          <br/>
          <p class="small rm-mb" *ngIf="cardBrand != 'medicare'">
            <strong>card issue {{ reference }}</strong>
          </p>
        </div>
        <div class="end" *ngIf="expiry">
          <span class="end-text">exp. end:</span><span class="end-date"> {{ expiry }}</span>
        </div>
      </div>

      <!-- show flippable back of card -->
      <div class="back" *ngIf="flippable == true">
        <div class="strip-black"></div>
        <div class="ccv">
          <label>ccv</label>
          <div>123</div>
        </div>
        <div class="terms">
          <p>
            This card is property of Monzo Bank, Wonderland. Misuse is criminal offence. If found, please return to
            Monzo Bank or to the nearest bank with MasterCard logo.
          </p>
          <p>Use of this card is subject to the credit card agreement.</p>
        </div>
      </div>
    </div>
  </div>
</div>
