<div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="listDB.data.length == 0">
  <div [hidden]="displayQuotationDetailView == true || displayPersonalCirView == true">
    <h1 class="dataHeader">
      {{ title }}
      <span class="badge">{{ dataSource.filteredData.length }}</span>
    </h1>
    <mat-card class="list-card">
      <!-- Filters -->
      <div class="row clearfix searchArea primary-gradient-img">
        <div class="row flex justify">
          <!-- <div class="qtr-width flex" id="search">
            <mat-icon class="schIcon mt mr">search</mat-icon>
            <mat-form-field class="schSel full-width" appearance="outline">
              <mat-label>Add Filters</mat-label>
              <mat-select class="" matInput placeholder="Add Filters" [formControl]="searchVals"
                multiple>

                <mat-select-trigger>
                  {{searchVals.value ? searchVals.value[0] : ''}}
                  <span *ngIf="searchVals.value?.length > 1" class="additional-selection">
                    (+{{searchVals.value.length - 1}} others)
                  </span>
                </mat-select-trigger>
                <mat-option *ngFor="let searchVal of searchValList" [value]="searchVal">{{searchVal}}</mat-option>
              </mat-select>
            </mat-form-field>

          </div> -->

          <div class="full-width clearfix flex animated fadeInDown">
            <mat-icon class="schIcon mt mr">search</mat-icon>



            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Search by name, mobile, email, contract ID, invitation ID ...</mat-label>
              <input class="clearfix" (input)="setFilter($event.target.value, 'fullName')" name="CustomerFullName"
                placeholder="Search by name, mobile, email, contract ID, invitation ID ..." matInput />
            </mat-form-field>
          </div>

          <!-- date output -->
          <div class="ml timeOutput clearfix" *ngIf="dateFrom != null || dateTo != null">


            <div class="pull-right">
              <p class="small rm-mb" *ngIf="dateFrom != null || dateTo != null">
                <span *ngIf="dateFrom">From:
                  <strong>{{ dateFrom | customDateTime }} </strong>
                </span>
              </p>
              <p class="small rm-mt" *ngIf="dateFrom != null || dateTo != null">
                <span *ngIf="dateTo">
                  To:
                  <strong>{{ dateTo | customDateTime }}</strong>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <table class="list-table" #table [dataSource]="dataSource" mat-table matSort>
        <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
        <!-- ID Column -->
        <ng-container matColumnDef="ApplicationDate">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Date
          </th>
          >
          <td *matCellDef="let row" (click)="openQuotationDetailView(row)" mat-cell
            matTooltip="{{ row['DateTimeCreated'] | customDate }}">
            {{ row['DateTimeCreated'] | customDate }}
          </td>
          <td *matFooterCellDef mat-footer-cell>Totals</td>
        </ng-container>
        <ng-container matColumnDef="Merchant">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{ "KEYWORD.practice" | translate | titlecase}}
          </th>
          >
          <td *matCellDef="let row" (click)="openQuotationDetailView(row)" mat-cell
            matTooltip="{{ row['MerchantName'] }}">
      {{ row['MerchantName'] }}
          </td>
          <td *matFooterCellDef mat-footer-cell></td>
        </ng-container>
        <ng-container matColumnDef="CustomerName">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Applicant Name
          </th>
          <td *matCellDef="let row" (click)="openQuotationDetailView(row)" mat-cell>

            <strong>
              {{ row.FirstName | lowercase | ucfirst }}
              {{ row.LastName | lowercase | ucfirst }} </strong>
          </td>
          <td *matFooterCellDef mat-footer-cell></td>
        </ng-container>

        <ng-container matColumnDef="Funder">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Funder
          </th>
          <td *matCellDef="let row" (click)="openQuotationDetailView(row)" mat-cell
            matTooltip="{{ row['Funder_Name'] }}">
            {{ row['Funder_Name'] }}
          </td>
          <td *matFooterCellDef mat-footer-cell></td>
        </ng-container>

        <ng-container matColumnDef="Status">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Status
          </th>
          <td *matCellDef="let row" (click)="openQuotationDetailView(row)" mat-cell
            matTooltip="{{ row['Status.Application.Short_NE'] }}">
            {{ row['Status.Application.Short_NE'] }}
          </td>
          <td *matFooterCellDef mat-footer-cell></td>
        </ng-container>

        <ng-container matColumnDef="timeElapsed">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Time Elapsed</th>
          <td *matCellDef="let row" (click)="openQuotationDetailView(row)" mat-cell>
            <span class="list-label" [style.background]="utils.daysPastColor(row['DateTimeCreated'])"
              matTooltip="{{ row['LastEvent.DateTimeCreated'] | customDateTime }}">
              {{ row['LastEvent.DateTimeCreated'] | TimeLabelPast }} ago
            </span>
          </td>
          <td *matFooterCellDef mat-footer-cell></td>
        </ng-container>

        <ng-container matColumnDef="AmountInvoice">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{ "KEYWORD.treatment" | translate | titlecase }}
          </th>
          <td *matCellDef="let row" (click)="openQuotationDetailView(row)" mat-cell
            matTooltip="{{ row['Treatment.Value'] | customCurrency }}">
            {{ row['Treatment.Value'] | customCurrency }}
          </td>
          <td *matFooterCellDef mat-footer-cell>
            {{ getTotalColumn('Treatment.Value') | customCurrency }}
          </td>
        </ng-container>
        <ng-container matColumnDef="AmountFinanced">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Financed
          </th>
          <td *matCellDef="let row" (click)="openQuotationDetailView(row)" mat-cell
            matTooltip="{{ row['Contract_Financed_Amount'] | customCurrency }}">
            {{ row['Contract_Financed_Amount'] | customCurrency }}
          </td>
          <td *matFooterCellDef mat-footer-cell>
            {{ getTotalColumn('Contract_Financed_Amount') | customCurrency }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Product">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Product
          </th>
          <td *matCellDef="let row"  (click)="openQuotationDetailView(row)" mat-cell
            >
            {{ row['Product.Label.Internal'] }}
          </td>
          <td *matFooterCellDef mat-footer-cell></td>
        </ng-container>


        <ng-container matColumnDef="AmountLoan">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Original Loan Amount
          </th>
          <td *matCellDef="let row" (click)="openQuotationDetailView(row)" mat-cell
            matTooltip="{{ row['Amount.Loan.Original'] | customCurrency }}">
            {{ row['Amount.Loan.Original'] | customCurrency }}
          </td>
          <td *matFooterCellDef mat-footer-cell>
            {{ getTotalColumn('Amount.Loan.Original') | customCurrency }}
          </td>
        </ng-container>
        <ng-container matColumnDef="AmountOriginal">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Original Amount</th>
          <td *matCellDef="let row" (click)="openQuotationDetailView(row)" mat-cell
            matTooltip="{{ row['Amount.Original'] | customCurrency }}">
            {{ row['Amount.Original'] | customCurrency }}
          </td>
          <td *matFooterCellDef mat-footer-cell>
            {{ getTotalColumn('Amount.Original') | customCurrency }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Actions">




          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Actions
          </th>
          <td *matCellDef="let row" mat-cell>

            <app-invitation-actions
            *ngIf="row"
            [customerID]="row['Prospect_key']"
            [invitationID]="row['ID']"
            [contractID]="row['QuotationORContract_Key']"
            [merchantID]="row['Merchant_Key']"
            [dentistID]="row['DentistContact_key']"
            [campaignID]="row['Campaign_Key']"
            [instantOfferID]="row['Instant_Finance_Offer_Key']"
            [landingPageID]="row['Document_Link_key']"
            [invitation]="row"
            [productID]="row['Product_Key']"


            (getDeletedInvitationID)="getDeletedInvitationID($event)"
            (getInvitationUpdate)="getInvitationUpdate($event)"


            ></app-invitation-actions>

            <div style="float: right">    <!-- <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
           -->
              <mat-menu #menu="matMenu">


                <button [matMenuTriggerFor]="messageMenuInvitation" *ngIf="row['ID'] && row['Prospect_key']"
                  mat-menu-item>
                  <mat-icon class="fas fa-envelope smr"></mat-icon>
                  Quotation
                </button>

                <mat-menu #messageMenuInvitation="matMenu">

                  <button *ngIf="row['ID']" (click)="openInvitationView(row['ID'])" mat-menu-item>
                    <mat-icon class="smr"> description</mat-icon>
                    <span> Quick View </span>
                  </button>
                  <button *ngIf="row['Prospect_key']" (click)="fullInvitationDetails(row['ID'] , row['Prospect_key'])"
                    mat-menu-item>
                    <mat-icon class="fas fa-id-card smr"></mat-icon>
                    <span> Detailed view </span>
                  </button>



                  <button (click)="viewMessagesInvitation(row['ID'])" mat-menu-item>
                    <mat-icon class="fas fa-calendar-week smr"></mat-icon>
                    <span>View Events</span>
                  </button>


                  <mat-divider></mat-divider>
                  <button *ngIf="row['Product_Key']" (click)="openProductView(row['Product_Key'])" mat-menu-item>
                    <mat-icon class="smr">business_center</mat-icon>
                    <span>View Product</span>
                  </button>

                  <mat-divider></mat-divider>
                  <button *ngIf="row['ID'] " (click)="wakandaFiles(row['ID'])" mat-menu-item>
                    <mat-icon class="smr">attach_file</mat-icon>
                    <span>Wakanda Files</span>
                  </button>

                  <button *ngIf="row['ID'] " (click)="redirect(row)" mat-menu-item>
                    <mat-icon class="smr">link</mat-icon>
                    <span>Invitation link</span>
                  </button>
                  <!-- <mat-divider></mat-divider>
                  <button (click)="openCloneInvitationModal(row['ID'], row['Prospect_key'])" mat-menu-item>
                    <mat-icon class="smr">file_copy</mat-icon>
                    <span>Clone</span>
                  </button> -->



                </mat-menu>

                <mat-divider *ngIf="row['ID'] && row['Prospect_key']"></mat-divider>

                <button [matMenuTriggerFor]="epVerMenu" *ngIf="row['Contract_Key'] && row['Prospect_key']"
                  mat-menu-item>
                  <mat-icon class="smr">description</mat-icon>
                  Contract
                </button>
                <mat-menu #epVerMenu="matMenu">
                  <button *ngIf="row['ID']" (click)="openDetailsViewEP(row['Contract_Key'], row['Prospect_key'])"
                    mat-menu-item>
                    <mat-icon class="smr">description</mat-icon>
                    <span>Contract Details</span>
                  </button>



                  <button *ngIf="isPromoterOrAdmin == true && row['Contract_Key']"
                    (click)="viewContractLogs(row['Contract_Key'])" mat-menu-item>
                    <mat-icon class="fa fa-list-alt smr"></mat-icon>
                    <span>View Contract Logs</span>
                  </button>



                  <button (click)="viewMessagesContract(row['Contract_Key'])" mat-menu-item>
                    <mat-icon class="fas fa-calendar-week smr"></mat-icon>
                    <span>View Events</span>
                  </button>

                  <mat-divider></mat-divider>


                  <button (click)="openPersonalCircumstanceView(row['Contract_Key'])" mat-menu-item>
                    <mat-icon class="fas fa-user-tag smr" aria-hidden="true"></mat-icon>
                    <span>Personal Circumstances</span>
                  </button>



                </mat-menu>




                <button [matMenuTriggerFor]="messageMenuCustomer" *ngIf="row['Prospect_key']" mat-menu-item>
                  <mat-icon class="fas fa-user smr"></mat-icon>
                  {{'Patient' | translate}}
                </button>
                <mat-menu #messageMenuCustomer="matMenu">

                  <button (click)="quickView(row['Prospect_key'])" mat-menu-item>
                    <mat-icon class="fas fa-user smr"></mat-icon>
                    <span> Quick view</span>
                  </button>

                  <button (click)="openCustomerView(row['Prospect_key'])" mat-menu-item>
                    <mat-icon class="fas fa-id-card smr"></mat-icon>
                    <span> Detailed view </span>
                  </button>

                  <button (click)="viewMessages(row['Prospect_key'])" mat-menu-item>
                    <mat-icon class="fas fa-calendar-week smr"></mat-icon>
                    <span>View Events</span>
                  </button>



                  <mat-divider></mat-divider>

                  <button *ngIf="isModulePatientSMSActive == true && row['Prospect_key']"
                    (click)="sendSMS(row['Prospect_key'],row['Merchant_Key'])" mat-menu-item>
                    <mat-icon class="fas fa-sms smr"></mat-icon>
                    <span>Send SMS</span>
                  </button>



                  <button *ngIf=" row['Prospect_key']" (click)="sendEmail(row['Prospect_key'])" mat-menu-item>
                    <mat-icon class="fas fa-at smr"></mat-icon>
                    <span>Send Email</span>
                  </button>


                </mat-menu>



                <button [matMenuTriggerFor]="messageMenuCustomersssss" *ngIf="row['DentistContact_key']" mat-menu-item>
                  <mat-icon class="       {{'fas fa-tooth' | translate }} smr"></mat-icon>
                  {{'Dentist' | translate}}
                </button>
                <mat-menu #messageMenuCustomersssss="matMenu">

                  <button *ngIf="row['DentistContact_key']" (click)="viewDentist(row['DentistContact_key'])"
                    mat-menu-item>
                    <mat-icon class="fas fa-user smr"></mat-icon>
                    <span> Quick view</span>
                  </button>

                  <mat-divider></mat-divider>

                  <button *ngIf="isModulePatientSMSActive == true && row['DentistContact_key']"
                    (click)="contactSMSDentist(row['DentistContact_key'])" mat-menu-item>
                    <mat-icon class="fas fa-sms smr"></mat-icon>
                    <span>Send SMS</span>
                  </button>



                  <button *ngIf=" row['DentistContact_key']" (click)="contactDentist(row['DentistContact_key'])"
                    mat-menu-item>
                    <mat-icon class="fas fa-at smr"></mat-icon>
                    <span>Send Email</span>
                  </button>


                </mat-menu>

                <button *ngIf="row['Merchant_Key']" (click)="viewMerchant(row['Merchant_Key'])" mat-menu-item>
                  <mat-icon class="fas fa-building smr"></mat-icon>
                  View {{'merchant' | translate}}
                </button>



                <!-- <button mat-menu-item *ngIf="row['Invitation_key']" (click)="openInviteViewDetails(row['Invitation_key'])">
                  <mat-icon>description</mat-icon>
                  <span> {{"InvitationDetailsView" | translate}} </span>
                </button> -->


                <!--
                <mat-divider></mat-divider>


                <button *ngIf="row['Status.Code'] == 'ACTIVE' && toNumber(row['Settlements.NotYetDrawn']) > 0"
                  (click)="createSettlement(row['ID'])" mat-menu-item>
                  <mat-icon class="fas fa-file-contract smr" aria-hidden="true"></mat-icon>
                  <span>New Settlement</span>
                </button> -->


                <mat-divider></mat-divider>


                <button [matMenuTriggerFor]="messageMenuInvitation4800" *ngIf="row['Prospect_key'] && row['ID']"
                  mat-menu-item>
                  <mat-icon class="smr">note_add</mat-icon>
                  Notes
                </button>

                <mat-menu #messageMenuInvitation4800="matMenu">


                  <button (click)="createNote(row)" mat-menu-item>
                    <mat-icon class="smr">note_add</mat-icon>
                    <span>Create Note</span>
                  </button>

                  <button (click)="viewNotes(row['Prospect_key'])" mat-menu-item>
                    <mat-icon class="fa fa-list-alt smr"></mat-icon>
                    <span>View Notes</span>
                  </button>



                </mat-menu>


                <!--


                <hr>
                <hr>




                <button *ngIf="row['Quotation_Key']" (click)="openQuotationDetailView(row)" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span> Quotation Details View </span>
                </button>
                <button *ngIf="row['Invitation_key']" (click)="openInvitationView(row['ID'])" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span> Quick Invitation View </span>
                </button>
                <button (click)="createNote(row)" mat-menu-item>
                  <mat-icon>note_add</mat-icon>
                  <span>Create Note</span>
                </button>

                <button (click)="viewNotes(row['Prospect_key'])" mat-menu-item>
                  <mat-icon class="fa fa-list-alt"></mat-icon>
                  <span>View notes</span>
                </button>
                <button *ngIf="row['Product_Key']" (click)="openProductView(row['Product_Key'])" mat-menu-item>
                  <mat-icon>business_center</mat-icon>
                  <span>View Product</span>
                </button>
                <button *ngIf="row['Prospect_key']" (click)="openCustomerView(row['Prospect_key'])" mat-menu-item>
                  <mat-icon>person</mat-icon>
                  <span> View Customer </span>
                </button>
                <button *ngIf="isPromoterOrAdmin == true && row['QuotationORContract_Key']"
                  (click)="viewContractLogs(row['QuotationORContract_Key'])" mat-menu-item>
                  <mat-icon class="fa fa-list-alt"></mat-icon>
                  <span>View Contract Logs</span>
                </button>
                <button (click)="openPersonalCircumstanceView(row['QuotationORContract_Key'])" mat-menu-item>
                  <mat-icon class="fas fa-user-tag" aria-hidden="true"></mat-icon>
                  <span>Personal Circumstance</span>
                </button>

                <button (click)="viewMessages(row['ID'])" mat-menu-item>
                  <mat-icon class="fas fa-calendar-week smr"></mat-icon>
                  <span>View Events</span>
                </button>

                <button (click)="viewNotes(row['ID'])" mat-menu-item>
                  <mat-icon>note</mat-icon>
                  <span>View notes</span>
                </button>

                <button [matMenuTriggerFor]="messageMenu" mat-menu-item>
                  <mat-icon>list</mat-icon>
                  Create Message
                </button>
                <mat-menu #messageMenu="matMenu">
                  <button (click)="contactByEmail(row['Prospect_key'])" mat-menu-item>Create Email message</button>
                  <button (click)="contactBySMS(row['Prospect_key'])" mat-menu-item>Create SMS message</button>
                </mat-menu> -->

              </mat-menu>
            </div>
          </td>
          <td *matFooterCellDef mat-footer-cell></td>
        </ng-container>
        <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
        <tr *matFooterRowDef="displayedColumns; sticky: true" mat-footer-row></tr>
      </table>
      <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>
    </mat-card>
  </div>

  <div *ngIf="quotationID != null && displayQuotationDetailView == true">
    <div class="full-width rel" *ngIf="showDetailInList == true">
      <button class="absPos btn-back" (click)="cancelQuotationDetailView()" mat-raised-button color="accent">
        <mat-icon>chevron_left</mat-icon>
        Show this {{ "KEYWORD.patient" | translate }}'s quotations
      </button>
    </div>
    <app-quotation-detail-view [quotationID]="quotationID"></app-quotation-detail-view>
    <div class="full-width text-center" *ngIf="showDetailInList == false">
      <button class="" (click)="cancelQuotationDetailView()" mat-raised-button color="accent">
        <mat-icon>chevron_left</mat-icon>
        Go Back
      </button>
    </div>
  </div>
  <div *ngIf="quotationID != null && displayPersonalCirView == true">
    <app-personal-circumstances [contractID]="quotationID"></app-personal-circumstances>
    <div class="full-width text-center">
      <button class="" (click)="cancelPersonalCircumstanceView()" mat-raised-button color="accent">
        <mat-icon>chevron_left</mat-icon>
        Go Back
      </button>
    </div>
  </div>
</div>

<div *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <app-empty-list-message></app-empty-list-message>
</div>
