<div class="row clearfix defineMessage full-width">
  <!-- blue heading -->
  <div class="card-header primary-gradient-img clearfix">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear pull-right mat-button" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <!-- merchant calculated name -->
      <div class="row titleArea clearfix">
        <mat-icon class="fas fa-pen-square pull-left"></mat-icon>
        <h2 class="message-title summary-header rm-m">
          <span>Edit Campaign</span>
        </h2>
      </div>
    </div>
  </div>
  <!-- end blue heading -->

  <div class="create-well">
    <form #msgForm="ngForm">
      <!--  mat dialog scrolling area -->
      <mat-dialog-content>
        <div class="row clearfix mt">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Label</mat-label>
            <input [(ngModel)]="campaignName" matInput placeholder="Label" name="campaignName" required/>
          </mat-form-field>
        </div>

        <div class="row clearfix mt">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Call to Action (CTA)</mat-label>
            <input
              [(ngModel)]="actionCallLabel"
              matInput
              placeholder="The CTA captures attention on your landing pages."
              name="actionCallLabel"
              required
            />
          </mat-form-field>

          <button
            class="pull-right btn-new-invite clip-button"
            *ngIf="actionCallLabel && actionCallLabel != actionCallLabelDefault"
            (click)="resetLabel()"
            mat-raised-button
            color="accent"
            matTooltip="Copy "
          >
            Reset to default <mat-icon class="fas fa-history"></mat-icon>
          </button>
        </div>

        <div class="row clearfix mt">
          <mat-form-field class="full-width messageContent" appearance="outline">
            <mat-label>Description/Preview</mat-label>
            <textarea
              class="dentMsg increase-input-field"
              [(ngModel)]="actionCallDescription"
              [maxLength]="350"
              name="actionCallDescription"
              matInput
              placeholder="The description accompanies the CTA and provides a preview of the campaign offer."
            ></textarea>
          </mat-form-field>

          <button
            class="pull-right btn-new-invite clip-button"
            *ngIf="actionCallDescriptionDefault && actionCallDescription != actionCallDescriptionDefault"
            (click)="resetDescription()"
            mat-raised-button
            color="accent"
            matTooltip="Copy "
          >
            Reset to default <mat-icon class="fas fa-history"></mat-icon>
          </button>
        </div>

        <div class="row clearfix mt">
          <section class="slideToggle">
            <mat-slide-toggle [(ngModel)]="isPublicCampaign" name="isPublicCampaign"
            >Extend the reach of the campaign by publishing on your {{ "KEYWORD.practice" | translate | titlecase}} Page
              and
              {{ "KEYWORD.dental" | translate | titlecase }} Tips and Offers Page
            </mat-slide-toggle>
          </section>
        </div>

        <div class="row clearfix mt mb">
          <section class="slideToggle">
            <mat-slide-toggle [(ngModel)]="hasExpirationDate" name="consentPatient"
            >If you wish to limit the duration of the offer, enter the expiry date here. Otherwise, the offer will be
              ongoing.
            </mat-slide-toggle>
          </section>

          <div class="full-width clearfix mt flex flexCenter" *ngIf="hasExpirationDate == true">
            <div class="emailToggle">
              <label style="display: inline; padding-right: 1rem">Expired at'</label>
            </div>

            <div class="full-width clearfix">
              <app-date-input
                class="thin emailDate full-width"
                [isTime]="true"
                [label]="'Date:'"
                [defaultDate]="whenToExpired"
                [minDate]="'toDay'"
                (selectedDate)="selectedExpirationDate($event)"
              >
              </app-date-input>
            </div>
          </div>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions>
        <div class="row clearfix text-center">
          <button [disabled]="!msgForm.valid" (click)="create()" mat-raised-button color="accent">
            Save
            <mat-icon>check</mat-icon>
          </button>
        </div>
      </mat-dialog-actions>
    </form>
  </div>
</div>
