<aw-wizard #wizard navBarLayout="large-filled-symbols">
  <aw-wizard-step stepTitle="Bulk Import" navigationSymbol="1">
    <!-- Step 1  -->
    <div class="container" id="step-1">
      <h2 class="sm-h2 sr-title">Bulk Import {{ "KEYWORD.patient" | translate | titlecase}}s</h2>
      <mat-card class="card">
        <div class="row clearfix">
          <p>
            You can quickly and easily upload large numbers of {{ "KEYWORD.patient" | translate }} records in several
            ways using the Bulk Uploader.
          </p>
          <h4 class="sm-h4 sr-title">Sync from your existing service</h4>
          <p>
            {{ "BRANDING.Brand Name" | translate | titlecase }} is compatible with your existing
            {{ 'BRANDING.practice' | translate }} management service. Simply click the 'Sync from service' button below
            and follow the prompts to link your records.
          </p>
          <button mat-raised-button>
            Sync from Service
            <mat-icon>refresh</mat-icon>
          </button>
          <hr/>
          <h4 class="sm-h4 sr-title">Drag and Drop a database file</h4>
          <p>You can upload a <strong>.tsv file</strong> of your {{ "KEYWORD.patient" | translate }} lists using the
            below button.</p>
          <hr/>
          <h4 class="sm-h4 sr-title">Copy/Paste</h4>
          <p>
            Copy and paste your {{ "KEYWORD.patient" | translate }} details using the format below: First name, Last
            name, Phone number, and Email address, all separated by commas. Separate additional
            {{ "KEYWORD.patient" | translate }}s with the Enter key.
          </p>
          <p>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>First Name, Last Name, Phone Number, Email Address...</mat-label>
              <textarea matInput placeholder="First Name, Last Name, Phone Number, Email Address..."></textarea>
            </mat-form-field>
          </p>
        </div>
      </mat-card>
      <div class="row clearfix">
        <div class="full-width text-center step-button-row">
          <button class="step-back" (click)="cancel()" mat-mini-fab color="primary">
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <button class="btn-large" mat-raised-button type="submit" color="accent" awNextStep>
            Next
          </button>
        </div>
      </div>
    </div>
  </aw-wizard-step>

  <aw-wizard-step [stepTitle]="('KEYWORD.patient' | translate | titlecase) + ' Information'" navigationSymbol="2">
    <!-- Step 1  -->
    <div class="container" id="step-1">
      <h2 class="sm-h2 sr-title">Sanitise Records</h2>
      <mat-card class="card">
        <div class="row clearfix">
          <p>Cool, that went smoothly! Now it's time to just double check your data before importing.</p>

          <p>LIST OF DUPLICATE RECORDS AND/OR MALFORMED RECORDS</p>
        </div>
      </mat-card>
      <div class="row clearfix">
        <div class="full-width text-center step-button-row">
          <button class="step-back" mat-mini-fab color="primary" awPreviousStep>
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <button class="btn-large" mat-raised-button type="submit" color="accent" awNextStep>
            Next
          </button>
        </div>
      </div>
    </div>
  </aw-wizard-step>

  <aw-wizard-step [stepTitle]="('KEYWORD.patient' | translate | titlecase) + ' Information'" navigationSymbol="3">
    <!-- Step 1  -->
    <div class="container" id="step-1">
      <div class="row clearfix">
        <mat-card class="card successful">
          <div class="row clearfix">
            <div class="full-width text-center">
              <h1 class="sm-h1 sr-title">Well Done</h1>
              <h3 class="sm-h3 rm-mt">00 {{ "KEYWORD.patient" | translate }} records imported</h3>

              <div class="row clearfix">
                <div class="svg-box">
                  <svg class="circular green-stroke">
                    <circle class="path" cx="75" cy="75" r="50" fill="none" stroke-width="5" stroke-miterlimit="10" />
                  </svg>
                  <svg class="checkmark green-stroke">
                    <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                      <path class="checkmark__check" fill="none" d="M616.306,283.025L634.087,300.805L673.361,261.53" />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
      <div class="row clearfix">
        <div class="full-width text-center step-button-row">
          <button class="full-back" [routerLink]="['/merchant/', { outlets: { page: ['customer-list'] } }]"
                  mat-raised-button color="primary">
            <mat-icon>keyboard_arrow_left</mat-icon>
            {{ "KEYWORD.patient" | translate | titlecase}}s Overview
          </button>
        </div>
      </div>
    </div>
  </aw-wizard-step>
</aw-wizard>
