import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-language-create',
  templateUrl: './language-create.component.html',
  styleUrls: ['./language-create.component.css'],
})
export class LanguageCreateComponent implements OnInit {
  @Output() add = new EventEmitter();
  code = '';
  label = '';
  constructor() {}

  ngOnInit() {}

  create() {
    this.add.emit({
      code: this.code,
      label: this.label,
    });
  }
}
