import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { WikiService } from '../shared/wiki.service';

@Component({
  selector: 'app-wiki-introduction',
  templateUrl: './wiki-introduction.component.html',
  styleUrls: ['./wiki-introduction.component.css'],
})
export class WikiIntroductionComponent implements OnInit {
  @Output() closeModal = new EventEmitter();

  isModal = false;
  recentPages;
  utils = new UtilsClass();

  search;
  sessionType;

  sessionTypeParams;
  recentPagesLimit = 4;
  recentPagesOrderBy = '-created_at';

  loading = false;

  constructor(
    public authenticationService: AuthenticationService,
    private router: Router,
    private wikiService: WikiService,
    private activeRoute: ActivatedRoute,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {}

  ngOnInit() {
    this.loading = true;

    const _res = this.utils.getSessionTypeParams(this.router.url);

    if (_res) {
      this.sessionTypeParams = _res;
    }

    this.authenticationService.getSessionType().subscribe((res) => {
      if (res && this.sessionTypeParams && (res === 'admin' || res == 'promoter')) {
        this.sessionType = this.sessionTypeParams;
      } else if (res) {
        this.sessionType = res;

        this.sessionTypeParams = null;
      }

      const params = {
        limit: 5,
        fields: '_id,title',
        orderBy: '-created_at',
        isActive: true,
      };

      this.wikiService.getWikiList(params, this.sessionType).subscribe((res) => {
        if (res) {
          this.recentPages = res;
        }

        this.loading = false;
      });
    });
  }

  moveToPage(id) {
    if (id) {
      this.router.navigate([
        '/wiki',
        {
          outlets: {
            wikiPageRoute: ['view', id],
          },
        },
      ]);
    }
  }

  isNew(date) {
    if (date) {
      const days = this.utils.daysPast(date);

      if (days < 15) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  searchEvent(t) {
    if (t) {
      this.router.navigate([
        '/wiki',
        {
          outlets: {
            wikiPageRoute: ['tag', t],
          },
        },
      ]);
    }
  }
}
