import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-editor-component-list-default',
  templateUrl: './editor-component-list-default.component.html',
  styleUrls: ['./editor-component-list-default.component.css'],
})
export class EditorComponentListDefaultComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
