<!-- <button *ngIf="isRedirect==true" mat-raised-button [routerLink]="['/merchant/', {outlets: {'page': ['marketing-campaign-create']}}]"
  color="primary" class="pull-right">Create New Campaign<mat-icon>chevron_right</mat-icon></button> -->

<app-media-gallery-templates-list
  *ngIf="templateLoaded == true"
  [isDefault]="isDefault"
  [actionLabel]="actionLabel"
  [title]="title"
  [description]="description"
  [isForMerchant]="isForMerchant"
  [isBusiness]="isBusiness"
  [isBusinessOrDefault]="isBusinessOrDefault"
  [isCampaign]="isCampaign"
  [isManualCreated]="isManualCreated"
  [isManualCreatedOrDefault]="isManualCreatedOrDefault"
  [isActive]="isActive"
  [isMerchantTemplate]="isMerchantTemplate"
  [emailLabel]="emailLabel"
  [mode]="'grid'"
  [isAction]="true"
  [smsLabel]="smsLabel"
  [merchantID]="merchantID"
  [selectedTemplateID]="templateID"
  [isPrint]="isPrint"
  [isProceed]="isProceed"
  [isProceed]="isProceed"
  [isMerchantCampaign]="isMerchantCampaign"
  [isPatientCampaign]="isPatientCampaign"
  [social]="false"
  (selectTemplate)="selectTemplateEvent($event)"
  (actionProceed)="actionProceedEvent($event)"
>
</app-media-gallery-templates-list>
