import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { environment } from 'environments/environment';
import { Observable, of as observableOf } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UtilsService } from '../../../shared/services/utils.service';
import { DataRequest } from '../../../shared/types/data-request.type';
import { DataResponse } from '../../../shared/types/data-response.type';
import { SessionType } from '../../../shared/types/session-type.type';
import { CustomerAndProspect } from '../../invitation/shared/types/invitation.type';

export class CustomerProspectState {
  customerAndProspect: CustomerAndProspect | Partial<CustomerAndProspect>;
  customerAndProspectList: CustomerAndProspect[] | Partial<CustomerAndProspect>[];
}

@Injectable()
export class CustomerProspectService extends ComponentStore<CustomerProspectState> {
  private baseUrl = environment.nodeUrl;

  constructor(private utilsService: UtilsService, private httpClient: HttpClient) {
    super({
      customerAndProspect: null,
      customerAndProspectList: [],
    });
  }

  getCustomerAndProspect$() {
    return this.select((state) => state.customerAndProspect) as Observable<CustomerAndProspect>;
  }

  setCustomerAndProspect(customerAndProspect: CustomerAndProspect | Partial<CustomerAndProspect>) {
    this.patchState({ customerAndProspect });
  }

  fetchCustomerAndProspect$(
    ID: string,
    payload: DataRequest,
    sessionType: SessionType = 'guest'
  ): Observable<CustomerAndProspect> {
    const params = this.utilsService.getHttpParamsFromPayload(payload);

    const adminRoute = 'customer-prospect/global';
    const merchantRoute = 'customer-prospect';
    const publicRoute = 'customer-prospect/public';

    const options = { params };

    const endpoint = (() => {
      switch (sessionType) {
        case 'admin' || 'promoter':
          return adminRoute;
          break;
        case 'merchant' || 'merchant-admin':
          return merchantRoute;
          break;
        default:
          return publicRoute;
      }
    })();

    const url = `${this.baseUrl}/${endpoint}/${ID}`;

    return this.httpClient
      .get<DataResponse<CustomerAndProspect>>(url, options)
      .pipe(map((response) => response['data']));
  }

  getCustomerAndProspectList$() {
    return this.select((state) => state.customerAndProspectList) as Observable<CustomerAndProspect[]>;
  }

  getCustomerAndProspectList() {
    return this.get().customerAndProspectList as CustomerAndProspect[];
  }

  setCustomerAndProspectList(customerAndProspectList: CustomerAndProspect[] | Partial<CustomerAndProspect>[]) {
    this.patchState({ customerAndProspectList });
  }

  fetchCustomerAndProspectList$(payload: DataRequest, isPromoterOrAdmin = false): Observable<CustomerAndProspect[]> {
    const params = this.utilsService.getHttpParamsFromPayload(payload);

    const options = { params };

    const endpoint = isPromoterOrAdmin ? 'customer-prospect/global' : 'customer-prospect';

    const url = `${this.baseUrl}/${endpoint}`;

    return this.httpClient
      .get<DataResponse<CustomerAndProspect[]>>(url, options)
      .pipe(map((response) => response['data']));
  }

  getCustomerProspectList(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.httpClient
      .get(`${environment.nodeUrl}/customer-prospect`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getCustomerProspectDetails(ID, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .get(environment.nodeUrl + '/customer-prospect/global/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.httpClient
        .get(environment.nodeUrl + '/customer-prospect/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/customer-prospect/public/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getCustomerProspectListGlobal(payload, isAdminOrPromoter = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isAdminOrPromoter == true) {
      return this.httpClient
        .get(`${environment.nodeUrl}/customer-prospect/global`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(`${environment.nodeUrl}/customer-prospect`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  linkCustomerToInv(customerID, payload = {}): Observable<any> {
    return this.httpClient.post(`${environment.nodeUrl}/customer/invitation/${customerID}`, payload);
  }

  linkProspectToInv(prospectID, payload = {}): Observable<any> {
    return this.httpClient.post(`${environment.nodeUrl}/prospect/invitation/${prospectID}`, payload);
  }

  getCustomerInvitations(customerID, body): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };
    return this.httpClient
      .get(environment.nodeUrl + '/invitation/customer/' + customerID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getProspectInvitations(prospectID, body): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };
    return this.httpClient
      .get(environment.nodeUrl + '/invitation/prospect/' + prospectID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getStatistics(body, isPromoterOrAdmin = false, isHidden = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };

    if (isHidden == true) {
      if (isPromoterOrAdmin == true) {
        return this.httpClient
          .get(environment.nodeUrl + '/customer-prospect/statistics-hidden/global', options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .get(environment.nodeUrl + '/customer-prospect/statistics-hidden', options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (isPromoterOrAdmin == true) {
        return this.httpClient.get(environment.nodeUrl + '/customer-prospect/statistics/global', options).pipe(
          tap(console.log),
          map((res: HttpResponse<any>) => res['data'])
        );
      } else {
        return this.httpClient
          .get(environment.nodeUrl + '/customer-prospect/statistics', options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  uploadProfilePic(ID, payload = {}) {
    return this.httpClient
      .put(`${environment.nodeUrl}/customer-prospect/profile-picture/` + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getProfilePic(ID, payload = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.httpClient
      .get(`${environment.nodeUrl}/customer-prospect/profile-picture/` + ID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getProfilePicStreamLink(ID, LastModified = null) {
    if (
      LastModified &&
      LastModified != '' &&
      LastModified != undefined &&
      LastModified != 'null' &&
      LastModified != 'undefined '
    ) {
      return `${environment.nodeUrl}/customer-prospect/profile-picture-stream-cached/${LastModified}/` + ID;
    } else if (ID) {
      return `${environment.nodeUrl}/customer-prospect/profile-picture-stream/` + ID;
    }
    return null;
  }

  patientLookup(body, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .get(environment.nodeUrl + '/customer-prospect/lookup/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/customer-prospect/lookup', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getHealthcare(payload, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .get(environment.nodeUrl + '/id-document/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.httpClient
        .get(environment.nodeUrl + '/id-document', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer') {
      return this.httpClient
        .get(environment.nodeUrl + '/id-document/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return observableOf(null);
    }
  }

  createHealthcare(payload, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .post(environment.nodeUrl + '/id-document/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.httpClient
        .post(environment.nodeUrl + '/id-document', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer') {
      return this.httpClient
        .post(environment.nodeUrl + '/id-document/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  deActiveAndCreateHealthcare(ID, payload, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .put(environment.nodeUrl + '/id-document/global/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.httpClient
        .put(environment.nodeUrl + '/id-document/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer') {
      return this.httpClient
        .put(environment.nodeUrl + '/id-document/customer/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  modifyCustomerProspect(ID = null, payload, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .put(environment.nodeUrl + '/customer-prospect/global/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.httpClient
        .put(environment.nodeUrl + '/customer-prospect/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer') {
      return this.httpClient
        .put(environment.nodeUrl + '/customer-prospect/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .put(environment.nodeUrl + '/customer-prospect/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  removeHealthcare(ID, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .put(environment.nodeUrl + '/id-document/deactive/global/' + ID, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.httpClient
        .put(environment.nodeUrl + '/id-document/deactive/' + ID, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer') {
      return this.httpClient
        .put(environment.nodeUrl + '/id-document/deactive/customer/' + ID, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  linkToInv(existingPatientID, invitationID = null): Observable<any> {
    const payload = {
      invitationID,
    };
    return this.httpClient.post(`${environment.nodeUrl}/customer-prospect/invitation/${existingPatientID}`, payload);
  }

  invitationLookup(payload, isPromoterOrAdmin = false): Observable<any> {
    let urlPatient = environment.nodeUrl + '/customer-prospect/lookup';
    let params: HttpParams = new HttpParams();
    const options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    if (isPromoterOrAdmin == true) {
      urlPatient = environment.nodeUrl + '/customer-prospect/lookup/global';
    }

    return this.httpClient.get(urlPatient, options);
  }

  searchByInvitationId(invitationID, payload = {}, isPromoterOrAdmin = false): Observable<any> {
    let urlPatient = environment.nodeUrl + '/customer-prospect/userdata/' + invitationID;
    let params: HttpParams = new HttpParams();
    const options = {};

    if (isPromoterOrAdmin == true) {
      urlPatient = environment.nodeUrl + '/customer-prospect/userdata/global/' + invitationID;
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options['params'] = params;
    }
    return this.httpClient.get(urlPatient, options);
  }

  inviteMedicalHistory(payload, isAdminOrPromoter = false): Observable<any> {
    if (isAdminOrPromoter == true) {
      return this.httpClient
        .post(environment.nodeUrl + '/customer-prospect/medical-history/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .post(environment.nodeUrl + '/customer-prospect/medical-history', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  inviteMedicalHistoryGroup(payload, isAdminOrPromoter = false): Observable<any> {
    if (isAdminOrPromoter == true) {
      return this.httpClient
        .post(environment.nodeUrl + '/customer-prospect/medical-history/group/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .post(environment.nodeUrl + '/customer-prospect/medical-history/group', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  inviteProfileUpdateGroup(payload, isAdminOrPromoter = false): Observable<any> {
    if (isAdminOrPromoter == true) {
      return this.httpClient
        .post(environment.nodeUrl + '/customer-prospect/profile-update/group/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .post(environment.nodeUrl + '/customer-prospect/profile-update/group', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getProspectDetails(ID, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .get(environment.nodeUrl + '/prospect/global/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.httpClient
        .get(environment.nodeUrl + '/prospect/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/prospect/public/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  createProspect(payload, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .post(environment.nodeUrl + '/prospect/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .post(environment.nodeUrl + '/prospect', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  editProspect(ID, payload): Observable<any> {
    return this.httpClient
      .put(environment.nodeUrl + '/prospect/' + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getCustomerDetails(ID, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .get(environment.nodeUrl + '/customer/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/customer/public/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  editCustomer(ID, payload): Observable<any> {
    return this.httpClient
      .put(environment.nodeUrl + '/customer/' + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getConsumerConfirmOptions(ID): Observable<any> {
    return this.httpClient
      .get(environment.nodeUrl + '/customer-prospect/customer/' + ID, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  confirmConsumerDetail(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.httpClient
      .get(environment.nodeUrl + '/customer-prospect/consumer-confirm', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createMarketing(payload, isAdminOrPromoter = false) {
    if (isAdminOrPromoter == true) {
      const urlPatient = environment.nodeUrl + '/marketing/global';
      return this.httpClient.post(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      const urlPatient = environment.nodeUrl + '/marketing';
      return this.httpClient.post(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  editProspectMarketingConsentGroup(payload): Observable<any> {
    return this.httpClient
      .put(environment.nodeUrl + '/prospect/group', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editCustomerMarketingConsentGroup(payload): Observable<any> {
    return this.httpClient
      .put(environment.nodeUrl + '/customer/group', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getMerchantTag(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .get(environment.nodeUrl + '/tag-merchant/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/tag-merchant', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getTagsList(body = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }
    options = {
      params,
    };
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .get(environment.nodeUrl + '/tag-merchant/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.httpClient
        .get(environment.nodeUrl + '/tag-merchant', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/tag-merchant', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  modifyMerchantTag(ID, payload, isAdminOrPromoter = false): Observable<any> {
    if (isAdminOrPromoter == true) {
      return this.httpClient
        .put(environment.nodeUrl + '/tag-merchant/global/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .put(environment.nodeUrl + '/tag-merchant/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  createMerchantTag(payload, isAdminOrPromoter = false): Observable<any> {
    if (isAdminOrPromoter == true) {
      return this.httpClient
        .post(environment.nodeUrl + '/tag-merchant/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .post(environment.nodeUrl + '/tag-merchant', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getPatientDocumentList(payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .get(environment.nodeUrl + '/customer-prospect/patient-document/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer') {
      return this.httpClient
        .get(environment.nodeUrl + '/customer-prospect/patient-document/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/customer-prospect/patient-document', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  createPatientDocument(payload = {}, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .post(environment.nodeUrl + '/customer-prospect/patient-document/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .post(environment.nodeUrl + '/customer-prospect/patient-document', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  triggerWebhook(id, code, transactionToken = null, transactionWebhookURL = null): Observable<any> {
    const payload = {
      code,
      transactionToken,
      transactionWebhookURL,
    };
    return this.httpClient.post(`${environment.nodeUrl}/customer-prospect/web-hook/${id}`, payload);
  }
  deleteTagForCard(payload, sessionType): Observable<any> {
    if (sessionType == "admin" || sessionType == "promoter") {
      return this.httpClient
        .post(environment.nodeUrl + "/tag-merchant/delete/card/global", payload).pipe(
          map((res: HttpResponse<any>) => res['data']));
    }
    else if (sessionType == "merchant" || sessionType == "merchant-admin") {
      return this.httpClient
        .post(environment.nodeUrl + "/tag-merchant/delete/card", payload).pipe(
          map((res: HttpResponse<any>) => res['data']));
    }
    else {
      return this.httpClient
        .post(environment.nodeUrl + "/tag-merchant/delete/card", payload).pipe(
          map((res: HttpResponse<any>) => res['data']));
    }
  }
  removePatientDocument(ID, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .delete(environment.nodeUrl + '/customer-prospect/patient-document/global/' + ID, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .delete(environment.nodeUrl + '/customer-prospect/patient-document/' + ID, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  getCustomerProspectMergedList(body = {}, sessionType): Observable<any> {

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .post(environment.nodeUrl + '/customer-prospect/merged-list/global', body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .post(environment.nodeUrl + '/customer-prospect/merged-list', body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getCustomerProspectMergedListHidden(body = {}, sessionType): Observable<any> {

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .post(environment.nodeUrl + '/customer-prospect/merged-list-hidden/global', body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .post(environment.nodeUrl + '/customer-prospect/merged-list-hidden', body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

}
