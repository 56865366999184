<div class="rel" *ngIf="sessionDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="sessionDB.data.length == 0">
  <div class="row clearfix">
    <div class="row flex ctl pull-right" @ngIfAnimation>
      <button
        class="row select-btn"
        (click)="selectAll()"
        mat-stroked-button
        color=""
        matTooltip="Select All"
      >
        <mat-icon>border_all</mat-icon>
      </button>
      <button
        class="row select-btn"
        (click)="selectPage()"
        mat-stroked-button
        color=""
        matTooltip="Select Page"
      >
        <mat-icon>border_outer</mat-icon>
      </button>
      <button
        class="animated"
        *ngIf="selectedIDs && selectedIDs.length > 0"
        [matMenuTriggerFor]="ActionBtnmenu"
        mat-stroked-button
        matTooltip="Options"
      >
        <mat-icon class="fas fa-cog"></mat-icon>  <span *ngIf="selectedIDs && selectedIDs.length>0"
          class="selected-number-item  accent-background">{{selectedIDs.length}}</span>
      </button>
      <mat-menu #ActionBtnmenu="matMenu">
        <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
          <mat-icon>select_all</mat-icon> Deselect
        </button>

        <button class="row select-btn" (click)="deleteSessions()" mat-menu-item color="">
          <mat-icon>cancel</mat-icon>
          Delete Sessions
        </button>
      </mat-menu>
    </div>

    <h2 class="dataHeader rm-mt">
      Active Sessions
      <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>

      <p class="small">List of users currently connected to the applications</p>
    </h2>
  </div>
  <div class="row clearfix mb searchArea primary-gradient-img" *ngIf="!operatorID && !cardKey">
    <div class="row clearfix flex">
      <div class="full-width flex animated fadeInDown">
        <mat-icon class="mt">search</mat-icon>

        <mat-form-field class="ml full-width" appearance="outline">
          <mat-label>Email, Name</mat-label>
          <input
            class="clearfix"
            (input)="setFilter($event.target.value, ['email', 'CalculatedName'])"
            name="EmailOrName"
            placeholder=""
            matInput
          />
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Session Type</mat-label>
          <mat-select
            class=""
            (selectionChange)="setFilter($event.value, 'sessionType')"
            name="sessionType"
            placeholder="Session Type"
          >
            <mat-option [value]="null">
              All
            </mat-option>
            <mat-option
              *ngFor="
                let a of dataSource.filteredData
                  | objectPropertyValue: 'sessionType'
                  | unique
                  | without: ['', null]
                  | orderBy
              "
              [value]="a"
            >
              {{ a }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Merchant Name</mat-label>
          <mat-select
            class=""
            (selectionChange)="setFilter($event.value, 'TradingAs')"
            name="MerchantName"
            placeholder="Merchant Name"
          >
            <mat-option [value]="null">
              All
            </mat-option>
            <mat-option
              *ngFor="
                let a of dataSource.filteredData
                  | objectPropertyValue: 'TradingAs'
                  | unique
                  | without: ['', null]
                  | orderBy
              "
              [value]="a"
            >
              {{ a }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Online Status</mat-label>
          <mat-select
            class=""
            (selectionChange)="setFilter($event.value, 'isAlive')"
            name="OnlineStatus"
            placeholder="Online Status"
          >
            <mat-option [value]="null">
              All
            </mat-option>
            <mat-option [value]="true">
              <span class="status-label-sucess">online </span>
            </mat-option>
            <mat-option [value]="false">
              <span class="status-label-error">offline </span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
    <ng-container cdkColumnDef="selectCheckBox" sticky>
      <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <div class="margin-btm pull-left">
          <mat-checkbox [checked]="isSelected(row['_id'])" (change)="select(row['_id'], $event.checked)" color="accent">
          </mat-checkbox>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="geolocation">
      <mat-header-cell *cdkHeaderCellDef>
        <mat-icon>public</mat-icon>
      </mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <button
          class="btn-clear btn-view"
          *ngIf="row.geoLocation"
          (click)="displayMap(row.geoLocation)"
          mat-button
          matTooltip="Show Location"
        >
          <mat-icon>place</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="ProfileImage" sticky>
      <mat-header-cell *cdkHeaderCellDef> Profile Image</mat-header-cell>
      <mat-cell class="text-center" *cdkCellDef="let row">
        <app-session-view-profile-picture
          class="headNavView-list text-center userIconView"
          *ngIf="row"
          [hideZoomButton]="true"
          [sessionID]="row['_id']"
        >
        </app-session-view-profile-picture>
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="CalculatedName" sticky>
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Name</mat-header-cell>
      <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row['_id'])">
        {{ row.CalculatedName }}
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="sessionType" sticky>
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> sessionType</mat-header-cell>
      <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row['_id'])">
        {{ row.sessionType }}
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="email">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Email</mat-header-cell>
      <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row['_id'])">
        {{ row.email }}
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="TradingAs">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>      {{ "KEYWORD.practice" | translate }}  Name</mat-header-cell>
      <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row['_id'])">
        {{ row.TradingAs }}
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="last_check">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Last Login</mat-header-cell>
      <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row['_id'])">
        {{ row.last_check | TimeLabelPast }} ago
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="expires">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Expires</mat-header-cell>
      <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row['_id'])">
        {{ row.expires | timeLeft }}
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="online">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Online</mat-header-cell>
      <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row['_id'])">
        <span class="chip chip-enable" *ngIf="row.isAlive">Online </span>
        <span class="chip chip-disable" *ngIf="!row.isAlive">Offline </span>
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="Actions">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <div style="float: right">
          <button [matMenuTriggerFor]="menu" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button (click)="openQuickViewDialog(row['_id'])" mat-menu-item>
              <mat-icon>description</mat-icon>
              <span>Quick View</span>
            </button>
            <button (click)="displayUserInfo(row)" mat-menu-item>
              <mat-icon>person</mat-icon>
              <span>Operator quick view</span>
            </button>

            <button (click)="goToOperatorSessionOverview(row)" mat-menu-item>
              <mat-icon>account_circle</mat-icon>
              <span>Operator details</span>
            </button>
            <mat-divider></mat-divider>

            <button *ngIf="row.email && !operatorID && !cardKey" (click)="displayContact(row)" mat-menu-item>
              <mat-icon>person_pin_circle</mat-icon>
              <span>Show Locations</span>
            </button>
            <button (click)="viewUserLogs(row)" mat-menu-item>
              <mat-icon>list</mat-icon>
              <span>User Logs</span>
            </button>

            <button *ngIf="row.email && !operatorID && !cardKey" (click)="contactUser(row)" mat-menu-item>
              <mat-icon>contact_mail</mat-icon>
              <span>Contact User</span>
            </button>
            <mat-divider></mat-divider>
            <button (click)="changePassword(row)" mat-menu-item>
              <mat-icon>vpn_key</mat-icon>
              <span>Change Password</span>
            </button>

            <button (click)="deleteSession(row)" mat-menu-item>
              <mat-icon>delete</mat-icon>
              <span>Delete Session</span>
            </button>
          </mat-menu>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator
    #paginator
    [length]="dataSource.filteredData.length"
    [pageIndex]="0"
    [pageSize]="25"
    [pageSizeOptions]="[5, 10, 25, 100]"
  >
  </mat-paginator>
</div>

<div *ngIf="sessionDB.data.length == 0 && sessionDB.firstLoadEvent != false">
  <app-empty-list-message [message]="'No active sessions found'"></app-empty-list-message>
</div>
