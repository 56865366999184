import { Routes } from '@angular/router';
import { InformedConsentTreatmentPresentedPage } from '../informed-consent-treatment-presented/informed-consent-treatment-presented.page';
const ɵ0 = {
    title: ' ',
};
const routes = [
    {
        path: 'informed-consent-treatment-presented',
        component: InformedConsentTreatmentPresentedPage,
        data: ɵ0,
    },
];
export class InformedConsentRoutingModule {
}
export { ɵ0 };
