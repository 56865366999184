<mat-card class="rel supplier-card clearfix mb" *ngIf="merchant">
  <div class="card-header primary-gradient-img clearfix stacked">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix supplierIcon-container-app">
        <app-ep-document-view-image
          class="supplierIcon-c pull-left"
          [defaultPicture]="
            'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
          "
          [hideZoomButton]="false"
          [link]="merchantProfile"
        >
        </app-ep-document-view-image>
        <h2 class="summary-header rm-m">
          Request an Appointment
          <span class="subLabel"> {{ merchant.TradingAs }}</span>
        </h2>
      </div>
    </div>
  </div>

  <form #difApplyForm="ngForm">
    <mat-dialog-content class="stacked-card-view">
      <div class="full-width">
        <div class="row clearfix">
          <mat-card class="mb">
            <p class="mb">Let us know what suits you best for an appointment.</p>

            <p class="mb">
              Once requested, we will contact you during regular office hours to confirm a suitable appointment date and
              time.
            </p>

            <div class="row clearfix flex">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Best time for you</mat-label>
                <mat-select [(ngModel)]="bestContactTime" name="bestContactTime" required>
                  <mat-option *ngFor="let b of bestContactTimes" [value]="b.Code">
                    {{ b.Label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="row clearfix" *ngIf="types && types.length > 0 && displayReasonForVisiting == true">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Reason for visiting</mat-label>
                <mat-select [(ngModel)]="type" (ngModelChange)="addSubType()" name="bestContactTimetypess" required>
                  <mat-option class="label2" *ngFor="let b of types" [value]="b">
                    {{ b.Label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <p
                class="clearfix full-width small no-margin"
                *ngIf="type && type.Label2 && displayReasonForVisiting == true"
              >
                {{ replaceAllString(type.Label2) }}
              </p>
            </div>
            <div class="row clearfix" *ngIf="subTypes && subTypes.length > 0">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Please give us more details</mat-label>
                <mat-select [(ngModel)]="subType" name="bestContactTimetypesubTypes" required>
                  <mat-option class="label2" *ngFor="let b of subTypes" [value]="b"> {{ b.Label }}></mat-option>
                </mat-select>
              </mat-form-field>

              <p class="clearfix full-width small no-margin" *ngIf="subType && subType.Label2">
                {{ replaceAllString(subType.Label2) }}
              </p>
            </div>

            <div class="row clearfix">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label> Anything else you would like us to know?</mat-label>
                <textarea
                  [(ngModel)]="comment"
                  name="comments"
                  matInput
                  placeholder="Anything else you would like us to know?"
                ></textarea>
              </mat-form-field>
            </div>
          </mat-card>
        </div>
      </div>
    </mat-dialog-content>
    <div class="drop-button text-center full-width">
      <button class="btn-large ml mr" (click)="closeEvent()" color="primary" mat-raised-button>
        {{ cancelLabel }}
      </button>

      <button
        class="btn-large ml mr"
        [disabled]="difApplyForm.invalid"
        (click)="book()"
        color="accent"
        mat-raised-button
      >
        {{ submitLabel }}
        <mat-icon>chevron_right </mat-icon>
      </button>
    </div>
  </form>
</mat-card>
