/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product-form.modal.ts.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./components/retail-product-form/retail-product-form.component.ngfactory";
import * as i3 from "../../../../../shared/pipes/binary-bool/binary-bool.pipe";
import * as i4 from "./components/retail-product-form/retail-product-form.component";
import * as i5 from "../../../../../core/app-state/app-state.service";
import * as i6 from "../../../../../shared/services/utils.service";
import * as i7 from "@angular/material/dialog";
import * as i8 from "../../../shared/services/product-catalogue.service";
import * as i9 from "../../../../beneficiary/shared/beneficiary.service";
import * as i10 from "./components/dental-product-form/dental-product-form.component.ngfactory";
import * as i11 from "./components/dental-product-form/dental-product-form.component";
import * as i12 from "../../../../../shared/components/title-modal/title-modal.component.ngfactory";
import * as i13 from "../../../../../shared/components/title-modal/title-modal.component";
import * as i14 from "@angular/common";
import * as i15 from "./product-form.modal.ts.component";
var styles_ProductFormModalComponent = [i0.styles];
var RenderType_ProductFormModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductFormModalComponent, data: {} });
export { RenderType_ProductFormModalComponent as RenderType_ProductFormModalComponent };
function View_ProductFormModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ipv-retail-product-form", [], null, null, null, i2.View_RetailProductFormComponent_0, i2.RenderType_RetailProductFormComponent)), i1.ɵprd(512, null, i3.BinaryBoolPipe, i3.BinaryBoolPipe, []), i1.ɵdid(2, 49152, null, 0, i4.RetailProductFormComponent, [i5.AppStateService, i6.UtilsService, i7.MatDialogRef, i8.ProductCatalogueService, i3.BinaryBoolPipe, i9.BeneficiaryService], { existingProduct: [0, "existingProduct"], formMode: [1, "formMode"], selectedSupplierKey: [2, "selectedSupplierKey"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.existingProduct; var currVal_1 = _co.formMode; var currVal_2 = _co.selectedSupplierKey; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ProductFormModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ipv-retail-product-form", [], null, null, null, i2.View_RetailProductFormComponent_0, i2.RenderType_RetailProductFormComponent)), i1.ɵprd(512, null, i3.BinaryBoolPipe, i3.BinaryBoolPipe, []), i1.ɵdid(2, 49152, null, 0, i4.RetailProductFormComponent, [i5.AppStateService, i6.UtilsService, i7.MatDialogRef, i8.ProductCatalogueService, i3.BinaryBoolPipe, i9.BeneficiaryService], { existingProduct: [0, "existingProduct"], formMode: [1, "formMode"], selectedSupplierKey: [2, "selectedSupplierKey"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.existingProduct; var currVal_1 = _co.formMode; var currVal_2 = _co.selectedSupplierKey; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ProductFormModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-dental-product-form", [], null, null, null, i10.View_DentalProductFormComponent_0, i10.RenderType_DentalProductFormComponent)), i1.ɵdid(1, 114688, null, 0, i11.DentalProductFormComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_ProductFormModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "ipv-title-modal", [], null, null, null, i12.View_TitleModalComponent_0, i12.RenderType_TitleModalComponent)), i1.ɵdid(1, 114688, null, 0, i13.TitleModalComponent, [i7.MAT_DIALOG_DATA], { icon: [0, "icon"], classIcon: [1, "classIcon"], title: [2, "title"], hideAllDefaultButtons: [3, "hideAllDefaultButtons"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 8, "div", [["content", ""], ["style", "padding-top: 10px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, null, null, null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i14.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductFormModalComponent_1)), i1.ɵdid(6, 278528, null, 0, i14.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i14.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductFormModalComponent_2)), i1.ɵdid(8, 278528, null, 0, i14.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i14.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductFormModalComponent_3)), i1.ɵdid(10, 278528, null, 0, i14.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i14.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = null; var currVal_1 = null; var currVal_2 = _co.title; var currVal_3 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.industryType; _ck(_v, 4, 0, currVal_4); var currVal_5 = "retail"; _ck(_v, 6, 0, currVal_5); var currVal_6 = "breeze"; _ck(_v, 8, 0, currVal_6); var currVal_7 = "dental"; _ck(_v, 10, 0, currVal_7); }, null); }
export function View_ProductFormModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-product-form", [["class", "modal ts"]], null, null, null, View_ProductFormModalComponent_0, RenderType_ProductFormModalComponent)), i1.ɵdid(1, 49152, null, 0, i15.ProductFormModalComponent, [i7.MAT_DIALOG_DATA], null, null)], null, null); }
var ProductFormModalComponentNgFactory = i1.ɵccf("ipv-product-form.modal.ts", i15.ProductFormModalComponent, View_ProductFormModalComponent_Host_0, {}, {}, []);
export { ProductFormModalComponentNgFactory as ProductFormModalComponentNgFactory };
