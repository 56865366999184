import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { DefaultMessageService } from '../../default-message/shared/default-message.service';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { InvitationTemplateService } from '../shared/invitation-template.service';

@Component({
  selector: 'app-invitation-template-view',
  templateUrl: './invitation-template-view.component.html',
  styleUrls: ['./invitation-template-view.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('10ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-10%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('10ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-10%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class InvitationTemplateViewComponent implements OnInit {
  treatment;

  @Input()
  invitationTemplateID;

  @Input()
  merchantID = localStorage.getItem('selectedMerchant');

  @Output() close = new EventEmitter();

  @Output() delete = new EventEmitter();

  isModal = false;

  treatmentAmount = 0;

  isPromoterOrAdmin = false;

  cashDiscountOveridden = true;
  cashDiscountOffered = false;
  cashDiscountMinAmount;
  cashDiscountPercent;
  cashDiscountExpiredDays;
  cashDiscountDate;
  cashDiscountAmount = 0;

  hideDiscount = false;
  sessionType = 'guest';
  merchant;
  message;
  @Input() isDownload = true;
  @Input() isRedirection = true;
  @Input() isPrint = true;

  constructor(
    private invitationTemplateService: InvitationTemplateService,
    private router: Router,
    private customePhonePipe: CustomPhonePipe,
    private defaultMessageService: DefaultMessageService,
    private authenticationService: AuthenticationService,
    private merchantService: MerchantService,
    private activeRouter: ActivatedRoute,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.invitationTemplateID) {
        this.invitationTemplateID = data.invitationTemplateID;
        this.isModal = true;
      }

      if (data.merchantID) {
        this.merchantID = data.merchantID;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        if (this.isPromoterOrAdmin == false) {
          this.isDownload = false;
          this.isRedirection = false;
          this.isPrint = false;
        }

        this.activeRouter.params.subscribe((params) => {
          if (params['invitationTemplateID']) {
            this.invitationTemplateID = params['invitationTemplateID'];
          }

          if (this.isPromoterOrAdmin == true && this.merchantID) {
            this.merchantService.getMerchantDetails(this.merchantID, {}, this.sessionType).subscribe((res) => {
              if (res && res['ID']) {
                this.merchant = res;
                this.merchantID = res['ID'];

                this.setup();
              }
            });
          } else {
            this.authenticationService.getCurrentPractice().subscribe((res) => {
              if (res && res['ID']) {
                this.merchant = res;
                this.merchantID = res['ID'];

                this.setup();
              }
            });
          }
        });
      });
    });
  }

  getTypeLabel(type) {
    if (type && type == 'default') {
      return 'Smile Right';
    }

    return 'Practice';
  }

  setup() {
    if (this.invitationTemplateID) {
      const payload = {
        merchantID: this.merchantID,
      };

      this.invitationTemplateService
        .getInvitationTemplateDetails(this.invitationTemplateID, payload, this.isPromoterOrAdmin)
        .subscribe((res) => {
          if (res) {
            this.treatment = res;

            if (this.treatment && this.treatment.content && this.treatment.content.totalTreatmentValue) {
              this.treatmentAmount = this.treatment.content.totalTreatmentValue;
            }

            if (this.treatment && this.treatment.content && this.treatment.content.cashDiscountOffered == true) {
              this.hideDiscount = false;
              this.calculCashDiscount();
            } else {
              this.hideDiscount = true;
            }

            if (
              res &&
              res['userID'] &&
              (this.isPromoterOrAdmin != true || (this.isPromoterOrAdmin == true && !this.merchantID))
            ) {
              this.merchantID = res['userID'];
            }

            if (
              this.treatment &&
              this.treatment.content &&
              this.treatment.content.message &&
              this.treatment.content.message.contentRaw
            ) {
              const payload: any = {
                isPatientPlaceHolder: true,
                isDentistPlaceHolder: true,
                isMerchantPlaceHolder: true,
                rawContent: res.content.message.contentRaw,
                merchant: this.merchant,
              };

              this.defaultMessageService
                .convertMessage(null, payload, this.customePhonePipe, this.isPromoterOrAdmin)
                .subscribe((res) => {
                  if (res) {
                    this.message = res;
                  }
                });
            }
          }
        });
    }
  }

  viewMerchant(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
        data: id,
        width: '600px',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  remove() {
    if (this.invitationTemplateID) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to remove this template  ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.invitationTemplateService
            .deleteInvitationTemplate(this.invitationTemplateID, this.isPromoterOrAdmin)
            .subscribe((res) => {
              if (res) {
                ref.close();

                NotifyAppComponent.displayToast('success', 'Successful operation', 'Treatment Template is removed');

                this.delete.emit(this.invitationTemplateID);

                this.router.navigate(['/merchant', { outlets: { page: ['treatment-saved-template'] } }]);
              }
            });
        }
      });
    }
  }

  closeModalEvent() {
    this.close.emit(true);
  }

  disable() {
    if (this.invitationTemplateID) {
      const confirmDialog = new ConfirmDialog('cancel', 'Disable Template', '<p>Are you sure ? </p>', 'Cancel', 'Yes');

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              isActive: false,
            };
            this.invitationTemplateService
              .modifyInvitationTemplate(this.invitationTemplateID, payload, this.isPromoterOrAdmin)
              .subscribe((res) => {
                if (res) {
                  this.router.navigate(['/merchant', { outlets: { page: ['treatment-saved-template'] } }]);

                  NotifyAppComponent.displayToast('success', 'Success!', 'Template is disabled.');
                }
              });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  enable() {
    if (this.invitationTemplateID) {
      const confirmDialog = new ConfirmDialog(
        'check_circle',
        'Enable Template',
        '<p>Are you sure ? </p>',
        'Cancel',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              isActive: true,
            };
            this.invitationTemplateService
              .modifyInvitationTemplate(this.invitationTemplateID, payload, this.isPromoterOrAdmin)
              .subscribe((res) => {
                if (res) {
                  this.router.navigate(['/merchant', { outlets: { page: ['treatment-saved-template'] } }]);

                  NotifyAppComponent.displayToast('success', 'Success!', 'Template is enabled.');
                }
              });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  calculCashDiscount() {
    if (this.merchant) {
      if (
        this.treatmentAmount > 0 &&
        this.merchant &&
        this.merchant['CashDiscount.AllowOverride'] == '0' &&
        Number(this.merchant['CashDiscount.MinInvoiceValue']) > Number(this.treatmentAmount)
      ) {
        this.hideDiscount = true;
        this.cashDiscountOffered = false;
        if (this.merchant['CashDiscount.AllowOverride'] == '0') {
          this.cashDiscountOveridden = false;
        } else {
          this.cashDiscountOveridden = true;
        }
        this.cashDiscountPercent = Number(this.merchant['CashDiscount.Percentage'] || 0);
        this.cashDiscountExpiredDays = Number(this.merchant['CashDiscount.Expiry.Days'] || 0);
        this.cashDiscountMinAmount = Number(this.merchant['CashDiscount.MinInvoiceValue'] || 0);
        this.calculateCashDiscountDate();
        this.calculateAmountFromPercent();
        const payload = {};
        payload['cashDiscountOffered'] = false;
        payload['cashDiscountExpiredDays'] = null;
        payload['cashDiscountPercent'] = null;
      } else if (
        this.treatmentAmount > 0 &&
        this.merchant &&
        this.merchant['CashDiscount.AllowOverride'] != '0' &&
        Number(this.merchant['CashDiscount.MinInvoiceValue']) > Number(this.treatmentAmount)
      ) {
        this.hideDiscount = false;
        this.cashDiscountOffered = false;
        this.cashDiscountOveridden = true;
        this.cashDiscountPercent = Number(this.merchant['CashDiscount.Percentage'] || 0);
        this.cashDiscountExpiredDays = Number(this.merchant['CashDiscount.Expiry.Days'] || 0);
        this.cashDiscountMinAmount = Number(this.merchant['CashDiscount.MinInvoiceValue'] || 0);
        this.calculateCashDiscountDate();
        this.calculateAmountFromPercent();
        const payload = {};
        payload['cashDiscountOffered'] = false;
        payload['cashDiscountExpiredDays'] = null;
        payload['cashDiscountPercent'] = null;
      } else if (
        this.treatmentAmount > 0 &&
        this.merchant['CashDiscount.Offered'] == '0' &&
        Number(this.merchant['CashDiscount.MinInvoiceValue']) < Number(this.treatmentAmount || 0)
      ) {
        this.hideDiscount = false;
        this.cashDiscountOffered = false;
        this.cashDiscountOveridden = true;
        this.cashDiscountPercent = Number(this.merchant['CashDiscount.Percentage'] || 0);
        this.cashDiscountExpiredDays = Number(this.merchant['CashDiscount.Expiry.Days'] || 0);
        this.cashDiscountMinAmount = Number(this.merchant['CashDiscount.MinInvoiceValue'] || 0);
        this.calculateCashDiscountDate();
        this.calculateAmountFromPercent();
        const payload = {};
        payload['cashDiscountOffered'] = false;
        payload['cashDiscountExpiredDays'] = null;
        payload['cashDiscountPercent'] = null;
      } else {
        this.hideDiscount = false;
        this.cashDiscountOffered = true;
        if (this.merchant['CashDiscount.AllowOverride'] == '0') {
          this.cashDiscountOveridden = false;
        } else {
          this.cashDiscountOveridden = true;
        }
        this.cashDiscountPercent = Number(this.merchant['CashDiscount.Percentage'] || 0);
        this.cashDiscountExpiredDays = Number(this.merchant['CashDiscount.Expiry.Days'] || 0);
        this.cashDiscountMinAmount = Number(this.merchant['CashDiscount.MinInvoiceValue'] || 0);
        this.calculateCashDiscountDate();
        this.calculateAmountFromPercent();
        const payload = {};
        payload['cashDiscountOffered'] = true;
        payload['cashDiscountExpiredDays'] = this.cashDiscountExpiredDays;
        payload['cashDiscountPercent'] = this.cashDiscountPercent;
      }
    }
  }

  calculateAmountFromPercent() {
    if (this.treatmentAmount > 0 && this.cashDiscountPercent && this.cashDiscountPercent <= 100) {
      this.cashDiscountAmount = (this.treatmentAmount * this.cashDiscountPercent) / 100;
    }
  }

  calculateCashDiscountDate() {
    if (this.cashDiscountExpiredDays) {
      this.cashDiscountDate = moment().add(this.cashDiscountExpiredDays, 'd');
    }
  }

  cancel() {
    this.router.navigate(['/merchant', { outlets: { page: ['treatment-saved-template'] } }]);
  }

  edit() {
    if (this.invitationTemplateID) {
      this.router.navigate([
        '/merchant',
        { outlets: { page: ['invitation-template-edit', this.invitationTemplateID] } },
      ]);
    }
  }
}
