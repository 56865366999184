<div class="container" @ngIfAnimation [hidden]="showCampaignViewDetais == true">
  <!--  -->

  <div class="clearfix row full-width" *ngIf="isPromoterOrAdmin == true">
    <app-operator-filter
      [sendNull]="true"
      [selectDateRange]="selectedRange"
      [displayMerchant]="isDisplayMerchant"
      [displayDateFrom]="true"
      [displayDateTo]="true"
      [displayProduct]="false"
      [displayDentist]="false"
      [setAutoSelectMerchant]="true"
      (search)="applySearch($event)"
      (getMerchant)="getMerchant($event)"
    >
    </app-operator-filter>
  </div>

  <div class="clearfix row full-width" *ngIf="isPromoterOrAdmin == false">
    <app-operator-filter
      [sendNull]="true"
      [selectDateRange]="selectedRange"
      [displayMerchant]="false"
      [displayDateFrom]="true"
      [displayDateTo]="true"
      [displayProduct]="false"
      [displayDentist]="false"
      (search)="applySearch($event)"
    ></app-operator-filter>
  </div>

  <div class="row clearfix full-width flex mb">
    <div class="two-thrd-width flex flexCol" *ngIf="statsStatus && statsStatus.length > 0">
      <div class="row clearfix mb">
        <div class="pull-left">
          <h2 class="lg-h2 rm-m sr-title">Campaign Overview</h2>
          <label class="subtitle"> Email &amp; SMS Campaign engagement statistics </label>
        </div>

        <button
          class="pull-right"
          *ngIf="emailReputation && emailReputation != 'none'"
          (click)="viewSubAccountStatisticalData()"
          mat-stroked-button
          color="primary"
          matTooltip="View Details"
        >
          <mat-icon>search</mat-icon>
          Email Reputation &nbsp;
          <span class="chip mat-elevation-z1" [ngStyle]="{ 'background-color': '#' + emailReputationColor }">{{
            emailReputation
            }}</span>
        </button>
      </div>

      <div class="subStatcontainer animate">
        <h2 class="sm-h2 sr-title text-center rm-mt" *ngIf="statsStatus && statsStatus.length > 0">
          This is a global overview of your Campaigns
        </h2>

        <div class="row clearfix mb">
          <div class="half-width rel">
            <div class="row clearfix full-width rel" *ngIf="statsStatus && statsStatus.length > 0">
              <div class="total anim">
                <span class="totLab">Total</span>
                <span class="totNum">{{ statsStatus | objectPropertyValue: 'Count(*)' | toNumberArray | sum }}</span>
              </div>

              <app-chart-input
                class="row"
                [titleIsTop]="false"
                [data]="statsStatus"
                [dataNameAttribute]="'name'"
                [dataValueAttribute]="'Count(*)'"
                [chartType]="'pie'"
                [customColorScheme]="customColorScheme"
                [showLegend]="false"
                [showLabel]="false"
                [title]="'Campaigns vs Status'"
              ></app-chart-input>
            </div>
            <div class="row clearfix full-width text-center rel" *ngIf="statsLoaded == false">
              <!-- css loader -->
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <!-- /css loader -->
            </div>
          </div>
          <div class="half-width">
            <div class="row clearfix">
              <mat-list>
                <mat-list-item
                  class="animate quickStat"
                  *ngFor="let p of statsStatus; let i = index"
                  (click)="displayMarketing(p.ID)"
                >
                  <h3 class="mb mt" [style.color]="getColorByName(p.name)" mdLine>
                    <mat-icon
                      class="fas {{ marketingUtil.getIconClass(p['ID']) }} "
                      [style.color]="getColorByName(p.name)"
                    ></mat-icon>

                    {{ p['Count(*)'] }} {{ p['name'] }}
                  </h3>

                  <mat-divider></mat-divider>
                </mat-list-item>
              </mat-list>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="thrd-width flex" [class.center]="statsStatus && statsStatus.length == 0">
      <div class="col-right flex">
        <mat-card class="tipInfo rel">
          <div class="row clearfix text-center heading primary-gradient">
            <img
              src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/marketing-campaign-cta.png"
            />
          </div>
          <div class="row clearfix text-center">
            <h2 class="sm-h2 sr-title rm-mb">Create campaigns on the go</h2>
            <p>
              Now you can create campaigns whenever and wherever you need to with our marketing module. Only
              {{ "KEYWORD.patient" | translate }}s who have agreed to marketing consent will receive marketing-related
              campaigns.
            </p>

            <button class="btn-large mt" (click)="triggerNewCampaign()" mat-raised-button color="primary">
              Create New Campaign
              <mat-icon>chevron_right</mat-icon>
            </button>

            <button
              class="create-new mt"
              *ngIf="isPromoterOrAdmin"
              [routerLink]="['/merchant/', { outlets: { page: ['template-create'] } }]"
              mat-stroked-button
              color="primary"
            >
              Create new template
            </button>

            <!-- Used for create campaign for operator and patient, waiting the logic from EP to carry on the work on frontend -->
            <!-- <button mat-raised-button [routerLink]="['/merchant/', {outlets: {'page': ['marketing-campaign-create']}}]"
            color="primary" class="btn-large mt" *ngIf="isPromoterOrAdmin==false">Create New Campaign<mat-icon>chevron_right</mat-icon></button>
            <button mat-raised-button [routerLink]="['/merchant/', {outlets: {'page': ['marketing-campaign-create-overview']}}]"
              color="primary" class="btn-large mt" *ngIf="isPromoterOrAdmin==true">Create New Campaign<mat-icon>chevron_right</mat-icon></button> -->
          </div>
        </mat-card>
      </div>
    </div>
  </div>

  <div class="clearfix row full-width" *ngIf="statsStatus && statsStatus.length > 0">
    <app-marketing-list-overview
      class="animate"
      *ngIf="searchApplied == true"
      [dateFrom]="dateFrom"
      [status]="selectedStatus"
      [merchantID]="merchantID"
      [dateTo]="dateTo"
      (campaignID)="getID($event)"
    >
    </app-marketing-list-overview>
  </div>
</div>

<div *ngIf="campaignID != null && showCampaignViewDetais == true">
  <app-marketing-campaign-view
    class="animate"
    [campaignID]="campaignID"
    [showBack]="false"
    (hideGobackBtn)="hideGobackBtnEvent($event)"
  ></app-marketing-campaign-view>
  <div class="full-width">
    <button
      class=""
      *ngIf="hideGoBackBtn == false"
      (click)="cancelCampaignViewDetais()"
      mat-raised-button
      color="accent"
    >
      <mat-icon>chevron_left</mat-icon>
      Document Name Go back to list
    </button>
  </div>
</div>
