import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import * as $ from 'jquery';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SecurityCodeInput } from '../../../core/authentication/security-code-input/security-code-input.component';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../services/utils.service';
import { Epdocument } from '../../types/epdocument';
import { NotifyAppComponent } from '../../types/notify-app-component';
import { UtilsClass } from '../../types/utils/utils.class';

@Component({
  selector: 'app-ep-document-view',
  templateUrl: './ep-document-view.component.html',
  styleUrls: ['./ep-document-view.component.css'],
})
export class EpDocumentViewComponent implements OnInit {
  @Input() title;
  @Input() description;
  @Input() selectedDocument;
  @Input() contentOnly = false;

  @Input() cardID;

  @Input() documentID;

  @Input()
  isExternalLink = false;

  @Input() isDownload = true;
  @Input() isRedirection = true;
  @Input() isPrint = true;
  @Input() isVerify = false;
  @Input() isReplace = false;

  util = new UtilsClass();

  @Output() download = new EventEmitter();
  @Output() readed = new EventEmitter();

  @Output() noFileFound = new EventEmitter();

  @Input() link;

  safeLink;

  @Input() loadDocument = new EventEmitter();

  @Input()
  defaultPicture =
    'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/default-image-thumbnail.png)';

  @Input()
  defaultLockedDocumentPicture =
    'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/document-prototype.jpg)';

  zoomInInfo = false;
  applyPrint = new EventEmitter();
  applyDownload = new EventEmitter();

  @Output() applyVerify = new EventEmitter();
  @Input('document')
  document: Epdocument;

  browser;
  isMicrosoftEdge = false;
  @Input()
  skipeChange = false;
  @Input()
  isModal = false;
  isVideo = false;
  isVimeo = false;
  vimeoHtml;
  defaultImage;
  safeVideoURL: SafeResourceUrl;

  @Output() closeModal = new EventEmitter();

  isFullScreenModal = false;
  accountType = 'guest';
  displayReplaceSection = false;
  replaceFileID;
  isPromoterOrAdmin = false;

  constructor(
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private deviceDetectorService: DeviceDetectorService,
    private activeRoute: ActivatedRoute,
    private utilService: UtilsService,
    // video link sanitizing
    private _sanitizer: DomSanitizer,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.isDownload != null) {
        this.isDownload = data.isDownload;
      }

      if (data.isPrint != null) {
        this.isPrint = data.isPrint;
      }

      if (data.contentOnly != null) {
        this.contentOnly = data.contentOnly;
      }
      if (data.isVerify != null) {
        this.isVerify = data.isVerify;
      }

      if (data.isReplace != null) {
        this.isReplace = data.isReplace;
      }

      if (data.isRedirection != null) {
        this.isRedirection = data.isRedirection;
      }

      if (data.cardID) {
        this.cardID = data.cardID;
      }

      if (data.link) {
        this.link = data.link;
      }

      if (data.documentID) {
        this.documentID = data.documentID;
      }

      if (data.title) {
        this.title = data.title;
      }

      if (data.document) {
        this.document = data.document;
      }

      if (data.description) {
        this.description = data.description;
      }

      if (data.selectedDocument) {
        this.selectedDocument = data.selectedDocument;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((res1) => {
      this.accountType = res1;

      if (this.accountType != 'guest') {
        if (!this.cardID) {
          this.authenticationService.getCurrentPractice().subscribe((res2) => {
            this.authenticationService.isPromoterOrAdmin().subscribe((isAdmin) => {
              this.isPromoterOrAdmin = isAdmin;
            });

            if (res2) {
              this.cardID = res2['ID'];
            }

            this.setup();
          });
        } else {
          this.setup();
        }
      } else {
        this.setup();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
   if (changes.documentID && !changes.documentID.isFirstChange()) {
      this.document = null;

      this.authenticationService.getSessionType().subscribe((res1) => {
        this.accountType = res1;

        if (this.accountType != 'guest') {
          if (!this.cardID) {
            this.authenticationService.getCurrentPractice().subscribe((res2) => {
              if (res2) {
                this.cardID = res2['ID'];
              }

              this.setup();
            });
          } else {
            this.setup();
          }
        } else {
          this.setup();
        }
      });
    }else {

      this.setup();
    }
  }

  setup() {
    this.browser = this.deviceDetectorService.browser;

    if (this.browser == 'ms-edge') {
      this.isMicrosoftEdge = true;
    }

    this.activeRoute.params.subscribe((params) => {
      if (params['documentID']) {
        this.documentID = params['documentID'];
      }

      if (params['title']) {
        this.title = params['title'];
      }

      if (params['description']) {
        this.description = params['description'];
      }

      if (this.document) {
        this.getDocument(this.document);
      } else if (!this.document && this.documentID) {
        this.getDocument(this.documentID);
      } else {
        this.noFileFound.emit(true);
      }
    });
  }

  getDocument(d) {
    const payload = {
      fields:
        'ID,Card.Name,DateTimeCreated,Label,Description,OperatorNotes,' +
        'ProgramArea.Code,ProgramArea.Label,DocumentType_key,AWS_URL,AWS_Bucket_Name,DocumentType_Code,' +
        'CardTable,Card_key,Invitation_key,Contract_key,DrawDown_key,Payment_key,' +
        'Waiver_key,DirectPayment_key,ReferencedRecordTable,DateAdded,PromoterDecision,' +
        'VersionNumber,Extension,ContainsSensitiveData,Tags,URL.Base,URL.Base_key,URL.Complete,URL.Relative',
    };

    if (d && typeof d == 'string') {
      this.utilService.getEpdocumentCached(d, payload, this.accountType).subscribe((res) => {
        if (res) {
          this.document = res;

          if (this.document) {
            this.defaultImage = this.util.getDefaultPic(null, this.document['Extension']);
          }

          this.setupURl();
          this.sendResult();
        }
      });
    } else if (d && d['ID'] && !d['Extension'] && (!d['URL.Complete'] || d['URL.Complete'] == '')) {
      this.documentID = d['ID'];
      this.utilService.getEpdocumentCached(d['ID'], payload, this.accountType).subscribe((res) => {
        if (res) {
          this.document = res;
          if (this.document) {
            this.defaultImage = this.util.getDefaultPic(null, this.document['Extension']);
          }

          this.setupURl();
          this.sendResult();
        }
      });
    } else if (d && d['ID'] && d['Extension'] && d['AWS_URL']) {
      this.documentID = d['ID'];
      this.document = d;
      if (this.document) {
        this.defaultImage = this.util.getDefaultPic(null, this.document['Extension']);
      }
      this.setupURl();
      this.sendResult();
    } else if (d && d['ID'] && d['Extension'] && d['URL.Complete']) {
      this.documentID = d['ID'];
      this.document = d;
      if (this.document) {
        this.defaultImage = this.util.getDefaultPic(null, this.document['Extension']);
      }
      this.setupURl();
      this.sendResult();
    } else if (d['ID']) {
      this.documentID = d['ID'];
      this.document = d;
      if (this.document) {
        this.defaultImage = this.util.getDefaultPic(null, this.document['Extension']);
      }
      this.setupURl();
      this.sendResult();
    }
  }

  setupURl() {
    this.safeLink = null;

    if (this.document['URL.Complete']) {
      this.isVideo = false;
      this.isVimeo = false;

      let _url = this.document['URL.Complete'];

      if (_url.indexOf('https://vimeo.com/') != -1) {
        let id = _url.replace('https://vimeo.com/', '');
        let tag;

        if (id && id.split('/')[1]) {
          tag = id.split('/')[1];
        }

        id = id.split('/')[0];

        if (id) {
          _url = 'https://player.vimeo.com/video/' + id;

          if (tag) {
            _url = _url + '?h=' + tag;
          }

          _url = _url.replace('.png', '');
          this.isVideo = true;
        }
      } else if (_url.indexOf('youtube.com')) {
        _url = _url.replace('watch?v=', 'embed/');
        _url = _url.replace('watch/?v=', 'embed/');

        _url = _url.replace('.png', '');
        this.isVideo = true;

        this.isVimeo = true;
      }

      this.safeVideoURL = this._sanitizer.bypassSecurityTrustResourceUrl(_url);
    } else if (this.documentID) {
      this.link = null;
      this.link = this.utilService.getDocumentStreamLink(this.documentID);

      if (this.link.indexOf(environment.nodeUrl) == -1) {
        if (this.link[0] == '/') {
          this.link = environment.nodeUrl + this.link;
        } else {
          this.link = environment.nodeUrl + '/' + this.link;
        }
      }

      this.safeLink = this.link;
    }
  }

  openLink() {
    let _url = this.document['URL.Complete'];

    _url = _url.replace('.png', '');

    window.open(_url, '_blank');
  }

  downloadEvent() {
    this.applyDownload.emit(true);
  }

  printEvent() {
    this.applyPrint.emit(true);
  }

  closeModalEvent() {
    this.closeModal.emit(true);
  }

  downloadFile() {
    this.applyDownload.emit(true);
  }

  verifyDocument() {
    this.applyVerify.emit(true);
  }

  rejectDocument() {
    this.applyVerify.emit(false);
  }

  replaceDocument() {
    this.displayReplaceSection = true;
  }

  onCompleteAllFile(e) {
    if (e && e[0]) {
      this.replaceFileID = e[0];
    }
  }

  removeNewTreatmentDocument(e) {
    if (e && e._id == this.replaceFileID) {
      this.replaceFileID = null;
    }
  }

  replaceDoc() {
    const payload = {
      fileID: this.replaceFileID,
    };
    this.utilService.editEpdocument(this.document['ID'], payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast('success', 'Import Data', 'You have successfully uploaded the file.');
        this.closeModal.emit(true);
      }
    });
  }

  openInNewTab() {
    if (this.document && this.document['ID'] && this.title && this.description) {
      window.open('/document/' + this.document['ID'] + '/' + this.title + '/' + this.description, '_blank');
    } else if (this.document && this.document['ID'] && this.title && !this.description) {
      window.open('/document/' + this.document['ID'] + '/' + this.title, '_blank');
    } else if (this.document && this.document['ID'] && !this.title && !this.description) {
      window.open('/document/' + this.document['ID'], '_blank');
    }
  }

  requestDocument(d) {
    const payload: any = {
      cardID: this.cardID,
      fields:
        'ID,DateTimeCreated,Label,Description,OperatorNotes,' +
        'ProgramArea.Code,ProgramArea.Label,DocumentType_key,AWS_URL,AWS_Bucket_Name,DocumentType_Code,' +
        'CardTable,Card_key,Invitation_key,Contract_key,DrawDown_key,Payment_key,' +
        'Waiver_key,DirectPayment_key,ReferencedRecordTable,DateAdded,' +
        'VersionNumber,Extension,ContainsSensitiveData,Tags,URL.Base,URL.Base_key,URL.Complete,URL.Relative',
    };
    let securityCodeID;
    let securityCode;

    if (d && d['ID']) {
      this.utilService.requestEPDocument(d['ID'], payload).subscribe((res) => {
        if (res) {
          const smsSent = true;

          let emailSent = false;
          if (res && res['Send.Email'] == '1') {
            emailSent = true;
          }

          securityCodeID = res['ID'];

          const ref = this.dialog.open(SecurityCodeInput, {
            data: {
              smsSent,
              emailSent,
            },
            width: '600px',
            panelClass: 'noCard',
          });

          ref.componentInstance.closeModal.subscribe((res) => {
            ref.close();
          });

          ref.componentInstance.sendCode.subscribe((code) => {
            this.utilService.requestEPDocument(d['ID'], payload).subscribe((res) => {
              securityCodeID = res['ID'];

              NotifyAppComponent.displayToast('success', 'Successfull', 'Authorisation code is sent again');
            });
          });
          ref.componentInstance.getCode.subscribe((code) => {
            if (code) {
              securityCode = code;
              payload.securityCode = securityCode;
              payload.securityCodeID = securityCodeID;

              this.utilService.getEpdocumentCached(d['ID'], payload, this.accountType).subscribe((res) => {
                if (res) {
                  ref.close();

                  this.document = res;

                  this.setupURl();
                  this.sendResult();
                }
              });
            }
          });
        }
      });
    }
  }

  sendResult() {
    if (this.document) {
      if (
        this.document['ContainsSensitiveData'] === '1' &&
        this.document['URL.Complete'] == '' &&
        this.document['URL.Complete'] == '' &&
        !this.safeLink
      ) {
        this.loadDocument.emit(null);
      } else {
        this.loadDocument.emit(this.document);
      }
    }
  }

  fullScreenModal() {
    if (this.isFullScreenModal != true) {
      $('.mat-dialog-container').last().addClass('full-screen');
      this.isFullScreenModal = true;
    } else {
      $('.mat-dialog-container').removeClass('full-screen');
      this.isFullScreenModal = false;
    }
  }

  zoomInInfoEvent(e) {
    this.zoomInInfo = e;
  }

  ngOnDestroy() {
    if (this.isFullScreenModal == true) {
      $('.mat-dialog-container').removeClass('full-screen');
    }
  }
}
