import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { map, switchMap, tap } from 'rxjs/operators';
import { UtilsClass } from '../../../../../../../../../../../../shared/types/utils/utils.class';
import { NotesService } from '../../../../../../../../../../shared/services/notes.service';
import { Note } from '../../../../../../../../../../shared/type/notes.type';

import { User } from '../../../../../../../../../../../../core/authentication/shared/shared/types/authentication.type';
import { NotifyAppComponent } from '../../../../../../../../../../../../shared/types/notify-app-component';
import { Dentist } from '../../../../../../../../../../../dentist/shared/dentist.type';
import { CustomerAndProspect } from '../../../../../../../../../../../invitation/shared/types/invitation.type';
import { Merchant } from '../../../../../../../../../../../merchant/shared/types/merchant.type';
import { NoteTemplateFormModalComponent, NoteTemplateFormModalConfig } from './note-template-form-modal.component';
import { NoteTextAreaExpandedModalConfig } from './note-text-modal.component';

export class NoteTemplateModalConfig {
  patient: Partial<CustomerAndProspect> | User;
  practice: Partial<Merchant>;
  dentist: Dentist;
}

@Component({
  selector: 'ipv-note-template-modal',
  templateUrl: './note-template-modal.component.html',
  styleUrls: ['./note-template-modal.component.css'],
})
export class NoteTemplateModalComponent implements OnInit, OnDestroy {
  utils = new UtilsClass();

  noteTemplates$ = this.notesService.getNoteTemplates$<Note>().pipe(
    map((notes) => {
      const filteredNotes = notes.filter(
        (note) => note.Template_Label.includes(this.searchFilter) || note.Description.includes(this.searchFilter)
      );

      return filteredNotes;
    })
  );

  @Output() templateSelected = new EventEmitter<Note>();

  @Input() title = 'Note Template';
  @Input() subtitle = `Below is a list of Note Templates available. Click to view the template, or if you are feeling
    creative press the 'create your own' button.`;
  @Input() displayMore = true;

  @Input() practice: Partial<Merchant>;
  @Input() patient: Partial<CustomerAndProspect> | User;
  @Input() dentist: Dentist;

  searchFilter = '';
  isCollapsed = true;

  orderByDirection = 'Template_Label';

  constructor(
    private dialogRef: MatDialogRef<NoteTemplateModalComponent>,
    private notesService: NotesService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: NoteTextAreaExpandedModalConfig
  ) {}

  ngOnInit() {
    if (this.data) {
      this.patient = this.data.patient;
      this.dentist = this.data.dentist;
      this.practice = this.data.practice;
    }

    this.filterSelectedNotes();
  }

  ngOnDestroy() {
    /** required for untilDestroyed (Replace with @UntilDestroyed() Decorator in angular 9 and delete ngOnDestroy()) */
  }

  changeSearchFilter() {
    const noteTemplates = [...this.notesService.getNoteTemplates()];
    this.notesService.setNoteTemplates(noteTemplates);
  }

  closeEvent() {
    this.dialogRef.close();
  }

  orderBy(orderDirection) {
    if (orderDirection) {
      let isUpOrder = true;

      if (orderDirection && orderDirection[0] === '-') {
        isUpOrder = false;
      }

      if (this.orderByDirection === orderDirection) {
        if (isUpOrder === true) {
          this.orderByDirection = '-' + orderDirection;
        } else {
          this.orderByDirection = orderDirection.replace('-', '');
        }
      } else {
        this.orderByDirection = orderDirection;
      }
    }
    this.filterSelectedNotes();
  }

  collapse(e) {
    this.isCollapsed = e;
  }

  isNew(date) {
    if (date) {
      const days = this.utils.daysPast(date);

      if (days < 15) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  selectNoteTemplate(note: Note) {
    this.templateSelected.emit(note);
    this.dialogRef.close();
  }

  openNoteTemplateFormModal() {
    this.dialog.open<NoteTemplateFormModalComponent, NoteTemplateFormModalConfig>(NoteTemplateFormModalComponent, {
      data: {
        disableApply: true,
        practice: this.practice,
        patient: this.patient,
        dentist: this.dentist,
      },
    });
  }

  openNoteTemplateFormModalUpdateMode(note: Note) {
    this.dialog.open<NoteTemplateFormModalComponent, NoteTemplateFormModalConfig>(NoteTemplateFormModalComponent, {
      data: {
        formMode: 'update',
        disableApply: true,
        noteID: note.ID,
        note,
        noteText: note.Description,
        label: note.Template_Label,
        practice: this.practice,
        patient: this.patient,
        dentist: this.dentist,
      },
    });
  }

  deleteNote(note: Note) {
    this.notesService
      .deleteNote$(note)
      .pipe(
        switchMap(() => {
          return this.notesService.removeNoteTemplate$(note);
        })
      )
      .subscribe((id) => {
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Successfully removed template');
      });
  }

  filterSelectedNotes() {
    return this.notesService
      .fetchNoteTemplates$<Note>({ orderBy: this.orderByDirection })
      .pipe(
        untilDestroyed(this),
        tap((notes) => {
          this.notesService.setNoteTemplates(notes);
        })
      )
      .subscribe();
  }
}
