import { ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { CompleterData } from 'ng2-completer';
import { DentalArchModalComponent } from '../dental-arch-modal/dental-arch-modal.component';
import { TeethShape } from '../shared/teeth-shape';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';

const COMMA = 188;

@Component({
  selector: 'app-dental-arch-create',
  templateUrl: './dental-arch-create.component.html',
  styleUrls: ['./dental-arch-create.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.anim', style({ opacity: 0 }), { optional: true }),
        query(
          '.anim',
          stagger('100ms', [
            animate(
              '0.3s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.anim', style({ opacity: 1 }), { optional: true }),
        query(
          '.anim',
          stagger('100ms', [
            animate(
              '0.3s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class DentalArchCreateComponent implements OnInit {
  @Input() selectedTeeth = [];
  @Output() selectTeeth = new EventEmitter();
  @Input() isAdult = true;
  @Input() clearDataEvent: EventEmitter<Boolean>;

  selectedTeethNumber = [];
  teethBottom = [];
  teethTop = [];
  options = [];
  selectedValue: any;
  dataService: CompleterData;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  // Enter, comma
  separatorKeysCodes = [ENTER, COMMA];

  tooths = [];

  constructor() {}

  ngOnInit() {
    TeethShape.getTopTeethNumbers(this.isAdult).forEach((num) => {
      this.teethTop.push({
        hideNumber: false,
        toothNumber: num,
        selected: this.selectedTeeth.indexOf(num) !== -1 ? true : false,
      });
      this.options.push(num);
    });
    TeethShape.getBottomTeethNumbers(this.isAdult).forEach((num) => {
      this.teethBottom.push({
        hideNumber: false,
        toothNumber: num,
        selected: this.selectedTeeth.indexOf(num) !== -1 ? true : false,
      });
      this.options.push(num);
    });

    this.clearDataEvent.subscribe((isClear) => {
      if (isClear === true) {
        this.selectedTeeth = [];
      }
    });
  }
  selectTooth(event) {
    if (
      event.toothNumber >= TeethShape.getTopTeethNumbers(this.isAdult)[7] &&
      event.toothNumber <=
        TeethShape.getTopTeethNumbers(this.isAdult)[TeethShape.getTopTeethNumbers(this.isAdult).length - 1]
    ) {
      this.teethTop.forEach((tooth) => {
        if (tooth.toothNumber === event.toothNumber) {
          const toothCopy = JSON.parse(JSON.stringify(tooth));
          tooth.selected = event.selected;
          tooth.selected === true
            ? this.selectedTeeth.push(tooth)
            : (this.selectedTeeth = this.selectedTeeth.filter((tooth) => {
                return tooth.selected;
              }));
        }
      });
    } else {
      this.teethBottom.forEach((tooth) => {
        if (tooth.toothNumber === event.toothNumber) {
          const toothCopy = JSON.parse(JSON.stringify(tooth));
          tooth.selected = event.selected;
          tooth.selected === true
            ? this.selectedTeeth.push(tooth)
            : (this.selectedTeeth = this.selectedTeeth.filter((tooth) => {
                return tooth.selected;
              }));
        }
      });
    }
    this.change();
  }
  change() {
    const selectedTeeth = this.selectedTeeth.map((a) => Number(a.toothNumber));

    this.selectTeeth.emit({
      selectedTeeth,
      isAdult: this.isAdult,
    });
  }
  selectToothFromInput() {
    const num = this.selectedValue;

    if (
      num >= TeethShape.getTopTeethNumbers(this.isAdult)[7] &&
      num <= TeethShape.getTopTeethNumbers(this.isAdult)[TeethShape.getTopTeethNumbers(this.isAdult).length - 1]
    ) {
      this.teethTop.forEach((tooth) => {
        if (tooth.toothNumber === num) {
          tooth.selected = !tooth.selected;
          tooth.selected === true
            ? this.selectedTeeth.push(tooth)
            : (this.selectedTeeth = this.selectedTeeth.filter((tooth) => {
                return tooth.selected;
              }));
        }
      });
    } else {
      this.teethBottom.forEach((tooth) => {
        if (tooth.toothNumber === num) {
          tooth.selected = !tooth.selected;
          tooth.selected === true
            ? this.selectedTeeth.push(tooth)
            : (this.selectedTeeth = this.selectedTeeth.filter((tooth) => {
                return tooth.selected;
              }));
        }
      });
    }
    this.selectedValue = null;
    this.change();
  }
  checkKeyUp(event) {
    if (event.code === 'Enter') {
      this.selectToothFromInput();
    }
  }

  openToothLookUpDialog() {
    const ref = RootAppComponent.dialog.open(DentalArchModalComponent, {
      data: this.selectedTeeth,
      width: '900px',
    });
    const dialog = ref.componentInstance.select.subscribe((data) => {
      this.isAdult = data.isAdult;
      this.selectedTeeth = data.selectedTeeth;
      this.change();
    });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our person
    if ((value || '').trim()) {
      this.tooths.push({ name: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(tooth: any): void {
    const index = this.tooths.indexOf(tooth);

    if (index >= 0) {
      this.tooths.splice(index, 1);
    }
  }

  clearData() {
    this.selectedTeeth = [];
    this.change();
  }
}
