import { Component, Input } from '@angular/core';
import { ProductInvoice } from '../../../../../shared/services/product-invoice.service';

@Component({
  selector: 'ipv-retail-view-product-invoice',
  templateUrl: './retail-view-product-invoice.component.html',
  styleUrls: ['./retail-view-product-invoice.component.css'],
})
export class RetailViewProductInvoiceComponent {
  @Input() invoiceAppliance: ProductInvoice;
}
