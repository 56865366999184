import { Component, Input, OnChanges, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { EMPTY_REPORT } from '../../../../../../shared/components/data-table/shared/data/data';
import { DataTablePrintModalComponent } from '../../../../../../shared/components/data-table/shared/modals/data-table-print-modal/data-table-print-modal.component';
import { DataTablePrintModalConfig } from '../../../../../../shared/components/data-table/shared/types/data-table.type';
import { Merchant } from '../../../../../merchant/shared/types/merchant.type';
import { SettlementPaymentsReportService } from '../../services/settlement-payments-report.service';
import { MerchantFeeRecord, MerchantFeeReport } from '../../types/settlement-payments-report.type';

@Component({
  selector: 'ipv-settlements-payments-report-merchant-fees',
  templateUrl: './settlements-payments-report-merchant-fees.component.html',
  styleUrls: ['./settlements-payments-report-merchant-fees.component.css'],
})
export class SettlementsPaymentsReportMerchantFeesComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('treatmentValue', { static: false }) public treatmentValue: TemplateRef<any>;
  @ViewChild('fee', { static: false }) public fee: TemplateRef<any>;

  @Input() merchantFeeReport: MerchantFeeReport;
  @Input() merchant: Partial<Merchant>;

  reportTable = EMPTY_REPORT;

  constructor(private settlementPaymentsReportService: SettlementPaymentsReportService, private dialog: MatDialog) {}

  ngOnInit() {
    this.settlementPaymentsReportService
      .getPrintButtonClicked()
      .pipe(untilDestroyed(this))
      .subscribe((tableName) => {
        if (tableName === 'merchant-fees') {
          this.openTablePrint();
        }
      });
  }

  ngOnChanges() {
    if (
      this.merchantFeeReport &&
      this.merchantFeeReport.Date_Processed &&
      this.merchantFeeReport.Date_Processed.length > 0
    ) {
      this.reportTable = this.getMerchantFeeTables(this.merchantFeeReport);
    }
  }

  ngOnDestroy() {
    // required for untilDestroyed();
  }

  getMerchantFeeTables(merchantFeeReport: MerchantFeeReport): {
    fullTable: MerchantFeeRecord[];
    table: MerchantFeeRecord[];
    totalRecord: MerchantFeeRecord;
  } {
    const table: MerchantFeeRecord[] = [];

    let totalFee = 0;

    const recordsLength = merchantFeeReport.Date_Processed.length;

    for (let index = 0; index < recordsLength; index++) {
      totalFee = totalFee + merchantFeeReport.Fees_Amount[index];

      const record: MerchantFeeRecord = {
        date: merchantFeeReport.Date_Processed[index],
        patient: merchantFeeReport.Patient_Name[index],
        product: merchantFeeReport.Product_Label[index],
        treatmentValue: merchantFeeReport.Treatment_Value[index],
        fee: merchantFeeReport.Fees_Amount[index],
      };

      table.push(record);
    }

    table.sort((first, second) => (first.date > second.date ? 1 : second.date > first.date ? -1 : 0));

    const totalRecord: MerchantFeeRecord = {
      date: 'Total',
      patient: '',
      product: '',
      treatmentValue: 0,
      fee: totalFee,
    };

    return {
      fullTable: table.concat(totalRecord),
      table,
      totalRecord,
    };
  }

  openTablePrint() {
    this.dialog.open<DataTablePrintModalComponent, DataTablePrintModalConfig>(DataTablePrintModalComponent, {
      data: {
        table: this.reportTable.fullTable,
        customComponents: {
          treatmentValue: this.treatmentValue,
          fee: this.fee,
        },
        title: 'Merchant Fee Report for' + this.merchant.TradingAs,
        formatHeaders: {
          treatmentValue: 'right',
          fee: 'right',
        },
        rename: {
          treatmentValue: 'Treatment Value',
        },
        practiceOrMerchant: this.merchant,
      },
      width: '700px',
    });
  }
}
