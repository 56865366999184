import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '../../../../../../../../../../../../core/authentication/shared/shared/types/authentication.type';
import { Dentist } from '../../../../../../../../../../../dentist/shared/dentist.type';
import { CustomerAndProspect } from '../../../../../../../../../../../invitation/shared/types/invitation.type';
import { Merchant } from '../../../../../../../../../../../merchant/shared/types/merchant.type';

export class NoteTextAreaExpandedModalConfig {
  noteText: string;
  patient: Partial<CustomerAndProspect> | User;
  practice: Partial<Merchant>;
  dentist: Dentist;
}

@Component({
  selector: 'ipv-note-text-modal',
  templateUrl: './note-text-modal.component.html',
  styleUrls: ['./note-text-modal.component.css'],
})
export class NoteTextModalComponent implements OnInit {
  @Output() noteTextUpdated: EventEmitter<string> = new EventEmitter();

  @Input() label = 'Enter a activity-log here';
  @Input() noteText: string;
  @Input() patient: Partial<CustomerAndProspect> | User;
  @Input() practice: Partial<Merchant>;
  @Input() dentist: Dentist;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: NoteTextAreaExpandedModalConfig,
    private dialogRef: MatDialogRef<NoteTextModalComponent>
  ) {}

  ngOnInit() {
    this.dialogRef.addPanelClass(['noCard', 'bigger-screen']);

    if (this.data) {
      this.noteText = this.data.noteText;
      this.patient = this.data.patient;
      this.dentist = this.data.dentist;
      this.practice = this.data.practice;
    }
  }

  setNoteValue(text: string) {
    this.noteText = text;
  }

  close() {
    this.dialogRef.close();
  }

  done() {
    this.noteTextUpdated.emit(this.noteText);
    this.dialogRef.close();
  }
}
