import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { TagsService } from '../../tags/shared/tags.service';

@Component({
  selector: 'app-customer-prospect-tag-view',
  templateUrl: './customer-prospect-tag-view.component.html',
  styleUrls: ['./customer-prospect-tag-view.component.css'],
})
export class CustomerProspectTagViewComponent implements OnInit {
  @Input()
  tagID;

  tag;
  isPromoterOrAdmin = false;
  sessionType;

  constructor(
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private tagService: TagsService
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['tagID']) {
        this.tagID = params['tagID'];
      }

      this.authenticationService.getSessionType().subscribe((st) => {
        this.sessionType = st;
        this.authenticationService.isPromoterOrAdmin().subscribe((a) => {
          this.isPromoterOrAdmin = a;
          this.setup();
        });
      });
    });
  }

  setup() {
    if (this.tagID) {
      this.tagService.getOneTag(this.tagID, {}, this.sessionType).subscribe((res) => {
        if (res) {
          this.tag = res;
        }
      });
    }
  }
}
