import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogMessageComponent } from '../../../shared/components/confirm-dialog-message/confirm-dialog-message.component';
import { ConfirmDialogMultipleComponent } from '../../../shared/components/confirm-dialog-multiple/confirm-dialog-multiple.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { EventLogListComponent } from '../../action-log/event-log-list/event-log-list.component';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { SettlementModifyComponent } from '../settlement-modify/settlement-modify.component';
import { SettlementRequestComponent } from '../settlement-request/settlement-request.component';
import { SettlementService } from '../shared/settlement.service';

import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { BankAccountViewComponent } from '../../bank-account/bank-account-view/bank-account-view.component';
import { BeneficiaryViewComponent } from '../../beneficiary/beneficiary-view/beneficiary-view.component';
import { ContractViewComponent } from '../../contract/contract-view/contract-view.component';
import { ContractService } from '../../contract/shared/contract.service';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-settlement-view',
  templateUrl: './settlement-view.component.html',
  styleUrls: ['./settlement-view.component.css'],
})
export class SettlementViewComponent implements OnInit {
  Settlement = null;
  settelmentDocuments = [];
  @Input()
  settlementID = null;

  @Output() close = new EventEmitter();

  @Output() changedSettlements = new EventEmitter();

  isGroupSettlements = false;
  groupSettlements;
  groupSettlementsAmount;
  groupSettlementsInvoiceAmount;

  isModal = false;
  chartData;
  customChartColor;

  contract;
  statsData;
  summaryChartData;
  customSummaryChartColor;
  settleToSupplier;

  isRefund = false;

  isPromoterOrAdmin = false;

  color = Settings.global['primaryColor'];

  constructor(
    private SettlementService: SettlementService,
    private contractService: ContractService,
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.settlementID = data;
      this.isModal = true;
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['settlementID']) {
        this.settlementID = params['settlementID'];
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;
        const payload = {
          selectDocument: true,
        };
        this.SettlementService.getSettlementDetails(this.settlementID, payload).subscribe((res) => {
          if (res) {
            this.Settlement = res;

            if (this.Settlement['Status.Color']) {
              this.color = this.Settlement['Status.Color'];
            }
            if (this.Settlement['SettleTo.TableName'] == 'Customer') {
              this.isRefund = true;
            }

            this.getContract(this.Settlement['Contract_key']);
            if (this.Settlement['DrawDownGroupID']) {
              this.isGroupSettlements = true;
              this.setupGroupSettlements();
            } else {
              if (this.Settlement['Insurance.Provider']) {
                this.getSettlementChart(
                  this.Settlement['Settlement.Amount'] - this.Settlement['Insurance.Contribution'],
                  Number(this.Settlement['Insurance.Contribution']),
                  0
                );
              } else {
                this.getSettlementChart(Number(this.Settlement['Settlement.Amount']), 0, 0);
              }
            }
            if (res.document && res.document.length > 0) {
              this.settelmentDocuments = res.document;
            }
          }
        });
      });
    });
  }

  getContract(contractID) {
    let approvedData;

    const payload = {
      selectStats: true,
      fields: 'Amount.Invoice,Settlements.NotYetDrawn',
    };
    this.contractService.fetchContractDetails$(contractID, payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        this.contract = res;
        this.statsData = this.contract.stats;
        approvedData = this.statsData.filter((elem) => elem['ID'] == 'APPR')[0];
      }

      const payload2 = {
        onlySupplier: true,
        contractID,
        fields: 'Status.Code,Settlement.Amount',
      };
      this.SettlementService.getSettlementList(payload2, this.isPromoterOrAdmin).subscribe((res) => {
        if (res) {
          this.settleToSupplier = res;
          if (res.length > 0 && res.some((elem) => elem['Status.Code'] == 'APPR') == true) {
            const approvedSupplierSettlement = parseInt(
              res
                .filter((elem) => elem['Status.Code'] == 'APPR')
                .map((x) => x['Settlement.Amount'])
                .reduce((a, b) => (Number(a) + Number(b)).toString()),
              10
            );

            this.getSummaryChart(
              approvedData['Sum(Settlement.Amount)'] -
                approvedData['Sum(Insurance.Contribution)'] -
                approvedSupplierSettlement,
              approvedData['Sum(Insurance.Contribution)'],
              approvedSupplierSettlement,
              Number(this.contract['Settlements.NotYetDrawn'])
            );
          } else {
            this.getSummaryChart(
              approvedData['Sum(Settlement.Amount)'] - approvedData['Sum(Insurance.Contribution)'],
              approvedData['Sum(Insurance.Contribution)'],
              0,
              Number(this.contract['Settlements.NotYetDrawn'])
            );
          }
        }
      });
    });
  }

  closeModalEvent() {
    this.close.emit(true);
  }

  setupGroupSettlements() {
    const payload = {
      groupID: this.Settlement['DrawDownGroupID'],
    };
    this.SettlementService.getSettlementList(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        this.groupSettlements = res;
        this.groupSettlementsAmount = this.groupSettlements
          .map((x) => x['Settlement.Amount'])
          .reduce((a, b) => Number(a) + Number(b));
        this.groupSettlementsInvoiceAmount = this.groupSettlements
          .map((x) => x['Invoice.Amount'])
          .reduce((a, b) => Number(a) + Number(b));

        const _payToSupplier = this.groupSettlements
          .filter((x) => x['SettleTo.TableName'] == 'Supplier')
          .map((x) => x['Settlement.Amount'])
          .reduce((a, b) => Number(a) + Number(b));

        this.getSettlementChart(
          this.Settlement['Settlement.Amount'] - this.Settlement['Insurance.Contribution'],
          Number(this.Settlement['Insurance.Contribution']),
          Number(_payToSupplier)
        );
      }
    });
  }

  getSettlementChart(x?, y?, z?) {
    this.chartData = [
      {
        name: 'Settled to Practice',
        value: x || 0,
      },
      {
        name: 'Settled to Insurance',
        value: y || 0,
      },
      {
        name: 'Settled to Supplier',
        value: z || 0,
      },
    ];
    this.customChartColor = [
      {
        name: 'Settled to Practice',
        value: '#1b8bdd',
      },
      {
        name: 'Settled to Insurance',
        value: '#ffbc00',
      },
      {
        name: 'Settled to Supplier',
        value: '#dd1b1b',
      },
    ];
  }

  getSummaryChart(a?, b?, c?, d?) {
    this.summaryChartData = [
      {
        name: 'Settled to Practice',
        value: a || 0,
      },
      {
        name: 'Settled to Insurance',
        value: b || 0,
      },
      {
        name: 'Settled to Supplier',
        value: c || 0,
      },
      {
        name: 'Available Balance',
        value: d || 0,
      },
    ];
    this.customSummaryChartColor = [
      {
        name: 'Settled to Practice',
        value: '#1b8bdd',
      },
      {
        name: 'Settled to Insurance',
        value: '#ffbc00',
      },
      {
        name: 'Settled to Supplier',
        value: '#dd1b1b',
      },
      {
        name: 'Available Balance',
        value: '#00d07a ',
      },
    ];
  }

  editSettlement() {
    const ref = this.dialog.open(SettlementModifyComponent, {
      data: this.Settlement['ID'],
      width: '600px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.modifiedSettlement.subscribe((res) => {
      if (res) {
        this.Settlement = res;
      }
    });
  }

  openMerchantDialog() {
    const ref = this.dialog.open(MerchantViewComponent, {
      data: this.Settlement['Merchant_key'],
      width: '650px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  openContractDialog() {
    const ref = this.dialog.open(ContractViewComponent, {
      data: {
        contractID: this.Settlement['Contract_key'],
        isSimple: true,
      },
      width: '900px',
      panelClass: 'noCard',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  contractDetails() {
    this.close.emit(true);
    this.router.navigate(['/merchant', { outlets: { page: ['contract-view', this.Settlement['Contract_key']] } }]);
  }

  withdrawSettlement() {
    if (this.Settlement['ID'] && this.Settlement['DrawDownGroupID'] == '') {
      const confirm = new ConfirmDialog('block', '', 'Are you sure you want to withdraw this settlement?', 'No', 'Yes');

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation == true) {
          this.SettlementService.cancel(this.Settlement['ID'], this.isPromoterOrAdmin).subscribe((res) => {
            if (res) {
              this.Settlement = res;

              if (this.Settlement && this.Settlement.groupArray && this.Settlement.groupArray.length > 0) {
                this.changedSettlements.emit(this.Settlement.groupArray);
              }

              NotifyAppComponent.displayToast('success', 'Success!', 'Your Settlement has been withdrawn');

              ref.close();
            }
          });
        } else {
          ref.close();
        }
      });
    } else if (this.Settlement['ID'] && this.Settlement['DrawDownGroupID'] != '') {
      const confirm = new ConfirmDialog('block', '', 'Are you sure you want to withdraw this settlement?', 'No', 'Yes');

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation == true) {
          this.SettlementService.cancel(this.Settlement['ID'], this.isPromoterOrAdmin, true).subscribe((res) => {
            if (res) {
              this.Settlement = res;

              if (this.Settlement && this.Settlement.groupArray && this.Settlement.groupArray.length > 0) {
                this.changedSettlements.emit(this.Settlement.groupArray);
              }

              NotifyAppComponent.displayToast('success', 'Success!', 'Your Settlement has been withdrawn');
            }
          });
        } else {
          ref.close();
        }
      });
    }
  }

  approveSettlement() {
    if (this.Settlement['ID'] && this.Settlement['DrawDownGroupID'] == '') {
      const confirm = new ConfirmDialog(
        'thumb_up_alt',
        '',
        'Are you sure you want to approve this settlement?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation == true) {
          this.SettlementService.approve(this.Settlement['ID'], false).subscribe((res) => {
            if (res) {
              this.Settlement = res;

              if (this.Settlement && this.Settlement.groupArray && this.Settlement.groupArray.length > 0) {
                this.changedSettlements.emit(this.Settlement.groupArray);
              }

              NotifyAppComponent.displayToast('success', 'Success!', 'Your Settlement has been approved');

              ref.close();
            }
          });
        } else {
          ref.close();
        }
      });
    } else if (this.Settlement['ID'] && this.Settlement['DrawDownGroupID'] != '') {
      const ref2 = RootAppComponent.dialog.open(ConfirmDialogMultipleComponent, {
        data: {
          icon: 'thumb_down_alt',
          description: 'This settlement is part of a settlemennt group.',
          yesLabel: 'Yes, proceed.',
          noLabel: 'Cancel',
          isSecondConfirmation: false,
          informationLabel: 'View settlement group',
          title: 'Are you sure you want to approve this settlement group ?',
        },
        width: '500px',
      });

      ref2.componentInstance.confirmation.subscribe((confirmation) => {
        if (confirmation == true) {
          this.SettlementService.approve(this.Settlement['ID'], true).subscribe((res) => {
            if (res) {
              this.Settlement = res;

              if (this.Settlement && this.Settlement.groupArray && this.Settlement.groupArray.length > 0) {
                this.changedSettlements.emit(this.Settlement.groupArray);
              }

              NotifyAppComponent.displayToast('success', 'Success!', 'Your Settlement has been approved');

              ref2.close();
            }
          });
        }
      });
      ref2.componentInstance.information.subscribe((information) => {
        if (information == true) {
          this.viewSettlementGroup();
        }
      });
    }
  }

  declineSettlement() {
    if (this.Settlement['ID'] && this.Settlement['DrawDownGroupID'] == '') {
      const ref = RootAppComponent.dialog.open(ConfirmDialogMessageComponent, {
        data: {
          icon: 'thumb_down_alt',
          title: 'Are you sure you want to decline this settlement?',
          description: '',
          yesLabel: 'Yes,Proceed',
          noLabel: 'No',
          messageLabel: 'Please enter the reason',
        },
      });
      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation && confirmation.confirm == true) {
          this.SettlementService.decline(this.Settlement['ID'], confirmation.message, false).subscribe((res) => {
            if (res) {
              this.Settlement = res;

              if (this.Settlement && this.Settlement.groupArray && this.Settlement.groupArray.length > 0) {
                this.changedSettlements.emit(this.Settlement.groupArray);
              }

              NotifyAppComponent.displayToast('success', 'Success!', 'Your Settlement has been declined');

              ref.close();
            }
          });
        } else {
          ref.close();
        }
      });
    } else if (this.Settlement['ID'] && this.Settlement['DrawDownGroupID'] != '') {
      const ref = RootAppComponent.dialog.open(ConfirmDialogMessageComponent, {
        data: {
          icon: 'thumb_down_alt',
          title: 'Are you sure you want to decline this settlement?',
          description: '',
          yesLabel: 'Yes,Proceed',
          noLabel: 'No',
          messageLabel: 'Please enter the reason',
        },
      });
      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation && confirmation.confirm == true) {
          const ref2 = RootAppComponent.dialog.open(ConfirmDialogMultipleComponent, {
            data: {
              icon: 'thumb_down_alt',
              description: 'This settlement is part of a settlemennt group.',
              yesLabel: 'Yes, proceed.',
              noLabel: 'Cancel',
              isSecondConfirmation: false,
              informationLabel: 'View settlement group',
              title: 'Are you sure you want to decline this settlement group ?',
            },
            width: '500px',
          });

          ref2.componentInstance.confirmation.subscribe((confirmation) => {
            if (confirmation == true) {
              this.SettlementService.decline(this.Settlement['ID'], confirmation.message, true).subscribe((res) => {
                if (res) {
                  this.Settlement = res;

                  if (this.Settlement && this.Settlement.groupArray && this.Settlement.groupArray.length > 0) {
                    this.changedSettlements.emit(this.Settlement.groupArray);
                  }

                  NotifyAppComponent.displayToast('success', 'Success!', 'Your Settlement has been declined');

                  ref2.close();
                }
              });
            }
          });

          ref2.componentInstance.information.subscribe((information) => {
            if (information == true) {
              this.viewSettlementGroup();
            }
          });
        } else {
          ref.close();
        }
      });
    }
  }

  requestSettlement() {
    if (this.Settlement['ID']) {
      const ref = RootAppComponent.dialog.open(SettlementRequestComponent, {
        data: this.Settlement['ID'],
        width: '820px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.result.subscribe((res) => {
        if (res) {
          this.Settlement = res;

          if (this.Settlement && this.Settlement.groupArray && this.Settlement.groupArray.length > 0) {
            this.changedSettlements.emit(this.Settlement.groupArray);
          }

          ref.close();
        }
      });
    }
  }

  voidSettlement() {
    if (this.Settlement['ID']) {
      const confirm = new ConfirmDialog('delete', '', 'Are you sure you want to remove this settlement?', 'No', 'Yes');

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation == true) {
          this.SettlementService.void(this.Settlement['ID'], this.isPromoterOrAdmin).subscribe((res) => {
            if (res) {
              this.Settlement = res;

              if (this.Settlement && this.Settlement.groupArray && this.Settlement.groupArray.length > 0) {
                this.changedSettlements.emit(this.Settlement.groupArray);
              }

              NotifyAppComponent.displayToast('success', 'Success!', 'Your Settlement has been removed');

              ref.close();
            }
          });
        } else {
          ref.close();
        }
      });
    }
  }

  viewSettlementLogs() {
    const ref = this.dialog.open(EventLogListComponent, {
      data: {
        itemID: this.Settlement['ID'],
      },
      width: '900px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  viewSettlementGroup() {
    if (this.Settlement['DrawDownGroupID']) {
      this.router.navigate([
        '/merchant',
        { outlets: { page: ['settlement-group', this.Settlement['DrawDownGroupID']] } },
      ]);
    }
  }

  viewBeneficiary() {
    if (this.Settlement['SettleTo.Card_key']) {
      const ref = RootAppComponent.dialog.open(BeneficiaryViewComponent, {
        data: this.Settlement['SettleTo.Card_key'],
        width: '850px',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewBankAccount() {
    if (this.Settlement['SettleTo.Account_key']) {
      const ref = RootAppComponent.dialog.open(BankAccountViewComponent, {
        data: this.Settlement['SettleTo.Account_key'],
        width: '850px',
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
    }
  }

  toNumber(value) {
    if (value && typeof value == 'string') {
      return Number(value);
    } else if (value && Array.isArray(value)) {
      return value.map((x) => Number(x));
    }
  }
}
