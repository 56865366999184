import { EnvironmentType } from './environment-type';

export const environment: EnvironmentType = {
  production: true,
  EPUrl: 'dev',
  version: 'dev',
  origin: '',
  nodeUrl: 'https://app.smileright.com.au/api',
  facebookID: '505104573602250',
  linkedInID: '',
  twitter: '',
  googleAPIKey: 'AIzaSyBOXorgJyfG8gNjknZdFKt6V9rbGGIJ6cg',
  googleID: '332332019514-3f2c0bohbqi58ruh2c3o5oss161llogh.apps.googleusercontent.com',
  msID: '097f08e9-1f43-4053-9d02-4daa43f1ab97',
  client_id: '297749565872-1n17bm3jt617bap4k70au02eusomemsh.apps.googleusercontent.com',
  discoveryDocs: [
    'https://mybusiness.googleapis.com/$discovery/rest?version=v4',
    'https://mybusiness.googleapis.com/$discovery/rest?version=v1',
  ],
  scope: 'https://www.googleapis.com/auth/business.manage',
};
