import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ContractService } from '../shared/contract.service';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';

@Component({
  selector: 'app-contract-identity-check',
  templateUrl: './contract-identity-check.component.html',
  styleUrls: ['./contract-identity-check.component.css'],
})
export class ContractIdentityCheckComponent implements OnInit {
  @Input()
  contractID;

  @Input()
  patient;

  @Input()
  checkstatus;

  @Output()
  close = new EventEmitter();

  isModal = false;

  idCheckObj;
  isPromoterOrAdmin = false;

  searches = [];
  searchValues = [];
  fraudAssessments = [];
  rules = [];
  loading = true;
  constructor(
    private activeRouter: ActivatedRoute,
    private contractService: ContractService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.contractID = data;
      this.isModal = true;
    }
  }

  ngOnInit() {
    this.activeRouter.params.subscribe((params) => {
      if (params['contractID']) {
        this.contractID = params['contractID'];
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        this.setup();
      });
    });
  }

  setup() {
    this.loading = true;
    if (this.contractID) {
      this.contractService.getIDCheckDetails(this.contractID).subscribe((res) => {
        if (res) {
          this.idCheckObj = res;

          let _rules;

          if (
            this.idCheckObj['IDReport.ReportBag'] &&
            this.idCheckObj['IDReport.ReportBag']['Verification'] &&
            this.idCheckObj['IDReport.ReportBag']['Verification']['Rules']
          ) {
            _rules = this.idCheckObj['IDReport.ReportBag']['Verification']['Rules'];
          }
          if (_rules) {
            this.rules = Object.keys(_rules).map((key) => {
              return {
                Name: key,
                Result: _rules[key],
              };
            });
          }

          let _searches;

          if (
            this.idCheckObj['IDReport.ReportBag'] &&
            this.idCheckObj['IDReport.ReportBag']['Verification'] &&
            this.idCheckObj['IDReport.ReportBag']['Verification']['Searches']
          ) {
            _searches = this.idCheckObj['IDReport.ReportBag']['Verification']['Searches'];
          }

          if (_searches) {
            this.searches = Object.keys(_searches).map((key) => {
              return {
                name: _searches[key]['Label'],
                result: _searches[key]['Result'],
                rawScore: _searches[key]['Raw Score'],
                weight: _searches[key]['Weight'],
                ID: key,
              };
            });
          }

          let _searchValues;

          if (
            this.idCheckObj['IDReport.ReportBag'] &&
            this.idCheckObj['IDReport.ReportBag']['Verification'] &&
            this.idCheckObj['IDReport.ReportBag']['Verification']['SearchValues']
          ) {
            _searchValues = this.idCheckObj['IDReport.ReportBag']['Verification']['SearchValues'];
          }

          if (_searchValues) {
            const temp = Object.keys(_searchValues).map((key) => {
              const result = [];
              if (typeof _searchValues[key] != 'string') {
                const temp = Object.keys(_searchValues[key]).map((key2) => {
                  return {
                    name: `${key}-${key2}`,
                    value: _searchValues[key][key2],
                  };
                });

                result.push(temp);
              } else {
                const b = {
                  name: key,
                  value: _searchValues[key],
                };

                result.push(b);
              }
              return result;
            });
            this.searchValues = _.flattenDeep(temp);
          }

          let _fraudAssessment;

          if (
            this.idCheckObj['IDReport.ReportBag'] &&
            this.idCheckObj['IDReport.ReportBag']['FraudAssessment'] &&
            this.idCheckObj['IDReport.ReportBag']['FraudAssessment']['Factors']
          ) {
            _fraudAssessment = this.idCheckObj['IDReport.ReportBag']['FraudAssessment']['Factors'];
          }

          if (_fraudAssessment) {
            this.fraudAssessments = Object.keys(_fraudAssessment).map((key) => {
              return {
                Factor: key,
                Result: _fraudAssessment[key]['Result'],
                Score: _fraudAssessment[key]['Score'],
                Weight: _fraudAssessment[key]['Weight'],
                Threshold: _fraudAssessment[key]['Threshold'],
              };
            });
          }
        }

        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
}
