<div class="clearfix" *ngIf="role && (role == 'admin' || role == 'promoter')">
  <app-operator-filter
    id="part1"
    *ngIf="hideFilter == false"
    [displayMerchant]="true"
    [displayDateRange]="false"
    [displayDateFrom]="false"
    [displayTimePicker]="false"
    [displayDateTo]="false"
    [displayDentist]="false"
    [setAutoSelectMerchant]="true"
    [selectedMerchant]="merchantID"
    (search)="applySearch($event)"
  >
  </app-operator-filter>

  <div class="row clearfix pull-right">
    <mat-button-toggle-group class="btn-group rm-m selectType" [(ngModel)]="typeFilter">
      <mat-button-toggle class="" [value]="'a'">
        <mat-icon class="fas fa-envelope-open"></mat-icon>
        {{ "KEYWORD.treatment" | translate | titlecase }}
      </mat-button-toggle>

      <mat-button-toggle class="" [value]="'b'">
        <mat-icon class="fas fa-heartbeat"></mat-icon>
        Health History
      </mat-button-toggle>

      <mat-button-toggle class="" [value]="'c'">
        <mat-icon>face</mat-icon>
        Profile Update
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="row clearfix" *ngIf="typeFilter && typeFilter == 'a'">
    <app-invitation-list-global
      *ngIf="merchantID != 'none'"
      [voided]="true"
      [merchantID]="merchantID"
    ></app-invitation-list-global>
  </div>
  <div class="row clearfix" *ngIf="typeFilter && typeFilter == 'b'">
    <app-invitation-communication-list-global
      *ngIf="merchantID != 'none'"
      [voided]="true"
      [purposeCode]="'COM_UHH'"
      [merchantID]="merchantID"
    ></app-invitation-communication-list-global>
  </div>
  <div class="row clearfix" *ngIf="typeFilter && typeFilter == 'c'">
    <app-invitation-communication-list-global
      *ngIf="merchantID != 'none'"
      [voided]="true"
      [purposeCode]="'COM_UCD'"
      [merchantID]="merchantID"
    ></app-invitation-communication-list-global>
  </div>
  <div class="row clearfix">
    <div class="pleaseChoose" *ngIf="merchantID == 'none'" @ngIfAnimation>
      <p>
        <strong>No merchant selected</strong>
      </p>
      <img class="character_warning" />
      <p>Choose your desired merchant from the drop-down above to view their invitations</p>
    </div>
  </div>
</div>

<div class="row clearfix" *ngIf="role && (role == 'merchant' || role == 'merchant-admin')">
  <div class="row clearfix pull-right">
    <mat-button-toggle-group class="btn-group rm-m selectType" [(ngModel)]="typeFilter">
      <mat-button-toggle class="" [value]="'a'">
        <mat-icon class="fas fa-envelope-open"></mat-icon>
        {{ "KEYWORD.treatment" | translate | titlecase }}
      </mat-button-toggle>

      <mat-button-toggle class="" [value]="'b'">
        <mat-icon class="fas fa-heartbeat"></mat-icon>
        Health History
      </mat-button-toggle>

      <mat-button-toggle class="" [value]="'c'">
        <mat-icon>face</mat-icon>
        Profile Update
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="row clearfix" *ngIf="typeFilter && typeFilter == 'a'">
    <app-invitation-list [voided]="true"> </app-invitation-list>
  </div>
  <div class="row clearfix" *ngIf="typeFilter && typeFilter == 'b'">
    <app-invitation-communication-list [voided]="true" [purposeCode]="'COM_UHH'"></app-invitation-communication-list>
  </div>
  <div class="row clearfix" *ngIf="typeFilter && typeFilter == 'c'">
    <app-invitation-communication-list [voided]="true" [purposeCode]="'COM_UCD'"></app-invitation-communication-list>
  </div>
</div>
