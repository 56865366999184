<mat-card class="rel supplier-card clearfix mb" *ngIf="operator" @ngIfAnimation>
  <mat-dialog-content class="stacked-card-view">
    <div class="card-header primary-gradient-img clearfix stacked">
      <div class="row clearfix">
        <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
          <mat-icon>clear</mat-icon>
        </button>
        <div class="row titleArea clearfix">
          <h2 class="summary-header rm-m">
            {{ operator['CalculatedName'] }}
            <span class="subLabel" *ngIf="operator['TradingAs']">Trading as: {{ operator['TradingAs'] }}</span>
          </h2>
          <p class="clearfix small rm-mb">
            <span class="chip-enable">{{ operator['Role'] }}</span>
            <span class="chip-enable">{{ operator['UltimateTableName'] }}</span>
            <span class="chip-enable" *ngIf="operator.IsAdministrator == '1'">Admin</span>
            <span
              [ngClass]="{
                'chip-enable': operator.enabled,
                'chip-disable': !operator.enabled
              }"
            >{{ operator.enabled ? 'enabled' : 'disabled' }}</span
            >
          </p>
        </div>
      </div>
    </div>

    <div class="full-width mb clearfix row">
      <div class="row clearfix">
        <p class="lead rm-mb rm-mt">Update Password</p>
        <p class="small">
          Update user's password using the below fields. Please select a password that is at least 8 characters long,
          and has at least one number and one upper case letter.
        </p>
        <form #passwordChange="ngForm">
          <div class="row clearfix">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>New Password</mat-label>
              <input
                [(ngModel)]="newPassword1"
                [min]="8"
                matInput
                name="p2"
                placeholder="New Password"
                value=""
                type="password"
                pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$"
                required
                autocomplete="off"
              />
            </mat-form-field>
          </div>
          <div class="row clearfix" *ngIf="newPassword1" @simpleFadeAnimation>
            <p class="small rm-mt">Password strength</p>
            <password-strength-meter [password]="newPassword1"></password-strength-meter>
          </div>
          <div class="row clearfix">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Confirm New Password</mat-label>
              <input
                [(ngModel)]="newPassword2"
                [min]="8"
                matInput
                name="p3"
                placeholder="Confirm New Password"
                value=""
                type="password"
                pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$"
                required
                autocomplete="off"
              />
            </mat-form-field>
          </div>

          <div class="full-width row clearfix">
            <button
              class="pull-right"
              [disabled]="!passwordChange.form.valid || newPassword2 != newPassword1"
              (click)="updatePassword()"
              mat-raised-button
              color="accent"
            >
              Change
            </button>
          </div>
        </form>
      </div>
    </div>
  </mat-dialog-content>
</mat-card>
