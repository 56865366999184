<!-- Modal dismiss button -->
<div class="full-width clearFix">
  <div class="full-width clearFix">
    <button class="btn-close btn-clear mat-button modalCloser white" matDialogClose>
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <div class="row clearfix">


    <app-contact-input class="full-width clearfix"  [invitationID]="invitationID" [targetType]="'us'"
      [subjectArray]="subjectArray" [settings]="settings" [simplePageCode]="simplePageCode">
    </app-contact-input>
  </div>
</div>
