import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { IframeViewComponent } from '../../../shared/components/iframe-view/iframe-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { DocumentService } from '../shared/document.service';

import { map as _map } from 'lodash';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { CustomCurrencyPipe } from '../../../shared/pipes/custom-currency.pipe';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { ProductService } from '../../product/shared/product.service';
import { ProductUtil } from '../../product/shared/productUtil.type';
import { DocumentLinkCreateComponent } from '../document-link-create/document-link-create.component';
import { DocumentLinkViewComponent } from '../document-link-view/document-link-view.component';
import { LandingPageNewModalComponent } from '../landing-page-new-modal/landing-page-new-modal.component';

@Component({
  selector: 'app-document-link-lookup',
  templateUrl: './document-link-lookup.component.html',
  styleUrls: ['./document-link-lookup.component.css'],
})
export class DocumentLinkLookupComponent implements OnInit {
  typeFilter;
  @Input()
  merchantID;

  messageMode = 'all';
  maxLength = 300;

  @Input()
  viewOnClick = false;

  createNew = true;
  @Input()
  type;

  @Output()
  getResult = new EventEmitter();

  @Output()
  getDocumentLinkID = new EventEmitter();
  getDocumentLinkURL = new EventEmitter();
  @Output()
  getDocumentLink = new EventEmitter();
  productGroup;
  showNextBtn = false;
  financedProductGroup;
  productUtil = new ProductUtil();
  filterData = '';
  selectedPatient: any;
  utilsClass = new UtilsClass();
  financeFilter;
  isPromoterOrAdmin = true;
  isClonedStored = false;
  isClonedDefault = false;
  isClonedPublic = false;
  cloneDefault = false;
  @Output()
  close = new EventEmitter();

  themeObject = {};
  documentLinks = [];
  isModal = false;

  orderByFilter = 'label';
  selectAll;
  documentLink;
  @Input()
  title = 'Select your Landing Page';

  isInvitationContext = true;

  @Input()
  description =
    "Below is a list of Landing Pages available. Select the one you wish to apply, or if you are feeling creative press the 'create your own' button.";
  serviceRef;
  isDestoyed = false;
  isLoaded = false;
  itemsPerPage = 20;
  isHeader = false;
  utils = new UtilsClass();
  currentPage;
  currentPageDefault = 1;
  currentPageUser = 1;
  currentPageSM = 1;
  viewOnly = false;
  sessionType;

  merchant;
  cardID;
  contactID;
  promoter;
  contact;
  card: any = {
    ID: '00000000000000000000',
    Salutation: 'Mr',
    FirstName: 'John',
    MiddleName: 'Al',
    Name: 'Doe',
    'mobiles.Number': '0061101010101',
    'phones.Number': '0062202020202',
    'emails.Email': 'john.doe@smileright.com.au',
    'addresses.Calculated': 'PO Box 7795 Cloisters Square 6850 Western Australia',
  };

  loadCard = false;
  loadMerchant = false;
  loadPromoter = false;
  loadContact = false;
  isCollapsed = true;

  tablename = 'CustomersAndProspects';
  @Input()
  visitorsType;
  types = [
    {
      Code: 'FLandPg',
      Label: 'Finance Landing Page',
      Icon: 'fas fa-search-dollar',
    },
    {
      Code: 'PLandPg',
      Label: 'Product Landing Page',
      Icon: 'fas fa-images',
    },
  ];
  settings: any = Settings.global;
  currentPractice;
  dentistPractice;

  constructor(
    private productService: ProductService,
    private authenticationService: AuthenticationService,
    private documentService: DocumentService,
    private customPhonePipe: CustomPhonePipe,
    private currencyPipe: CustomCurrencyPipe,
    private router: Router,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.merchantID) {
        this.merchantID = data.merchantID;
        this.isModal = true;
      }
      if (data && data.cardID) {
        this.cardID = data.cardID;
      }
      if (data && data.card) {
        this.card = data.card;
      }
      if (data && data.visitorsType) {
        this.visitorsType = data.visitorsType;
      }

      if (data && data.promoter) {
        this.promoter = data.promoter;
      }
      if (data && data.contact) {
        this.contact = data.contact;
      }
      if (data && data.contactID) {
        this.contactID = data.contactID;
      }

      if (data && data.merchantID) {
        this.merchantID = data.merchantID;
      }

      if (data && data.tablename) {
        this.tablename = data.tablename;
      }
      if (data && data.tableName) {
        this.tablename = data.tableName;
      }

      if (data && data.merchant) {
        this.merchant = data.merchant;
      }
      if (data.createNew != null) {
        this.createNew = data.createNew;
        this.isModal = true;
      }
      if (data.messageMode) {
        this.messageMode = data.messageMode;
        this.isModal = true;
      }
      if (data.isHeader != null) {
        this.isHeader = data.isHeader;
        this.isModal = true;
      }

      if (data.viewOnly != null) {
        this.viewOnly = data.viewOnly;
        this.isModal = true;
      }

      if (data.cloneDefault != null) {
        this.cloneDefault = data.cloneDefault;
        this.isModal = true;
      }

      if (data.isClonedPublic != null) {
        this.isClonedPublic = data.isClonedPublic;
        this.isModal = true;
      }
      if (data.isClonedDefault != null) {
        this.isClonedDefault = data.isClonedDefault;
        this.isModal = true;
      }
      if (data.isClonedStored != null) {
        this.isClonedStored = data.isClonedStored;
        this.isModal = true;
      }

      if (data.viewOnClick != null) {
        this.viewOnClick = data.viewOnClick;
        this.isModal = true;
      }
      if (data.selectAll != null) {
        this.selectAll = data.selectAll;
        this.isModal = true;
      }

      if (data.type) {
        this.type = data.type;
        this.isModal = true;
      }

      if (data.isModal != null) {
        this.isModal = data.isModal;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getCurrentDentist().subscribe((dentistPractice) => {
      this.dentistPractice = dentistPractice;
      this.authenticationService.getCurrentPractice().subscribe((m) => {
        this.currentPractice = m;

        if (m && m.ID) {
          if (this.isPromoterOrAdmin == true && !this.merchantID) {
            this.merchantID = m.ID;
          } else if (this.isPromoterOrAdmin != true) {
            this.merchantID = m.ID;
          }

          const p1 = {
            merchantID: this.merchantID,
            isFinance: true,
          };
          this.productService.getMerchantProductGroup(p1, this.isPromoterOrAdmin).subscribe((financedProductGroup) => {
            if (financedProductGroup && financedProductGroup.length > 0) {
              this.financedProductGroup = financedProductGroup;
            }
          });

          if (this.createNew != true) {
            this.description = 'Below is a list of Landing Pages available. Select the one you wish to apply.';
          }
          this.authenticationService.getSessionType().subscribe((getSessionType) => {
            this.sessionType = getSessionType;
            this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
              this.isPromoterOrAdmin = res;
              this.documentService.documentLinkListTheme().subscribe((t) => {
                if (t && t.length > 0) {
                  _map(t, (item) => {
                    if (item && item.code) {
                      this.themeObject[item.code] = item.background;
                    }

                    return true;
                  });
                }

                this.setupData();
                this.setup();
              });
            });
          });
        }
      });
    });
  }

  isNew(date) {
    if (date) {
      const days = this.utils.daysPast(date);

      if (days < 15) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  setup() {
    this.typeFilter = null;

    const payload = {
      orderBy: 'Label',
      merchantID: this.merchantID,
      limit: 500,
      section: 1,
      addDefault: true,
      isActive: true,
      isStored: true,
      isExpired: false,
    };

    this.serviceRef = this.documentService.getDocumentLinkList(payload, this.isPromoterOrAdmin).subscribe((res) => {
      this.isLoaded = true;
      if (res && res.length > 0) {
        res = _map(res, (item) => {
          if (item) {
            item = this.buildItem(item);
          }
          return item;
        });

        this.documentLinks = res;

        const userDocumentLinksCount = this.documentLinks.filter((template) => template.type === 'user').length;

        if (userDocumentLinksCount > 0) {
          this.typeFilter = 'user';
        } else {
          this.typeFilter = 'default';
        }

        const innerFunction = (section) => {
          section = section + 1;
          payload.section = section;

          this.serviceRef = this.documentService
            .getDocumentLinkList(payload, this.isPromoterOrAdmin)
            .subscribe((res) => {
              if (res.length > 0) {
                res = _map(res, (item) => {
                  if (item) {
                    item = this.buildItem(item);
                  }
                  return item;
                });

                this.documentLinks = this.documentLinks.concat(res);

                if (this.typeFilter != 'user') {
                  const _userDocumentLinksCount = this.documentLinks.filter(
                    (template) => template.type === 'user'
                  ).length;

                  if (_userDocumentLinksCount > 0) {
                    this.typeFilter = 'user';
                  } else {
                    this.typeFilter = 'default';
                  }
                }

                if (this.isDestoyed != true) {
                  innerFunction(section);
                }
              } else {
                return true;
              }
            });
        };

        if (this.isDestoyed != true) {
          innerFunction(1);
        }
      }
    });
  }

  selectDocumentLink(documentLink) {
    if (documentLink && documentLink.ID) {
      if (this.viewOnClick == true) {
        this.documentLink = documentLink;

        const ref2 = RootAppComponent.dialog.open(DocumentLinkViewComponent, {
          data: {
            linkID: documentLink.ID,
            card: this.card,
            merchant: this.merchant,
            contact: this.contact,
            isSubmit: !this.viewOnly,
          },
          width: '950px',
        });

        ref2.componentInstance.close.subscribe((res) => {
          ref2.close();
        });
        ref2.componentInstance.getResult.subscribe((res) => {
          if (res) {
            this.documentLink = documentLink;
            ref2.close();
            ref2.afterClosed().subscribe((r) => {
              this.sendResult();
            });
          }
        });
      } else {
        this.documentLink = documentLink;

        this.sendResult();
      }
    }
  }

  openLandingPage(record) {
    if (record.ID) {
      let link = `${Settings.global['publicSiteFrontendLink']}/landing-page-overview/${record.ID}`;

      if (record['IsDefault'] === '1') {
        link = `${Settings.global['publicSiteFrontendLink']}/landing-page-overview/${record.ID}?m=${this.currentPractice.ID}&c=${this.dentistPractice.ID}`;
      }

      const ref2 = RootAppComponent.dialog.open(IframeViewComponent, {
        data: {
          link,
          extraDetails: record.IsDefault != '1' || this.isPromoterOrAdmin == true,
        },
        width: '80%',
        height: '92vh',
        panelClass: 'noCard',
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });
    }
  }

  setupData() {
    this.authenticationService.getPromoter().subscribe((r) => {
      if (r && r.data) {
        this.promoter = r.data;
      } else if (r) {
        this.promoter = r;
      }

      this.loadPromoter = true;
    });

    if (this.cardID) {
      this.authenticationService.getCardDetails(this.cardID, this.tablename, {}, this.sessionType).subscribe((r) => {
        if (r && r.ID) {
          this.card = r;
        }

        this.loadCard = true;
      });
    } else {
      this.loadCard = true;
    }

    if (this.merchantID && this.isPromoterOrAdmin == true) {
      this.authenticationService.getCardDetails(this.merchantID, 'Merchant', {}, this.sessionType).subscribe((r) => {
        if (r && r.ID) {
          this.merchant = r;
        }

        this.loadMerchant = true;
      });
    } else {
      this.authenticationService.getCurrentPractice().subscribe((r) => {
        if (r && r.ID) {
          this.merchant = r;
        }

        this.loadMerchant = true;
      });
    }

    if (this.contactID) {
      this.authenticationService.getCardDetails(this.contactID, 'Contact', {}, this.sessionType).subscribe((r) => {
        if (r && r.ID) {
          this.contact = r;
        }

        this.loadContact = true;
      });
    } else {
      this.authenticationService.getCurrentDentist().subscribe((r) => {
        if (r && r.ID) {
          this.contact = r;
        }

        this.loadContact = true;
      });
    }
  }

  ngOnDestroy() {
    this.isDestoyed = true;
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }

  changeLandingPageType() {
    if (this.financeFilter != 'FLandPg') {
      this.productGroup = null;
    }
  }

  buildContent(item, isBold = false) {
    if (
      this.loadCard == true &&
      this.loadMerchant == true &&
      this.loadPromoter == true &&
      this.loadContact == true &&
      item
    ) {
      const description = this.utils.fillMessage(
        this.promoter,
        this.merchant,
        this.contact,
        this.card,
        item.Description,
        isBold,
        this.customPhonePipe,
        this.currencyPipe
      );
      const message = this.utils.fillMessage(
        this.promoter,
        this.merchant,
        this.contact,
        this.card,
        item.Message,
        isBold,
        this.customPhonePipe,
        this.currencyPipe
      );

      return `${description}<hr   style="margin-right: 30% !important;margin-top: 20px;margin-bottom: 20px;height: 1px;border: none;background: #e4e4e4;">${message}`;
    }
  }

  buildContentMessage(item, isBold = false) {
    if (
      this.loadCard == true &&
      this.loadMerchant == true &&
      this.loadPromoter == true &&
      this.loadContact == true &&
      item
    ) {
      return this.utils.fillMessage(
        this.promoter,
        this.merchant,
        this.contact,
        this.card,
        item.Message,
        isBold,
        this.customPhonePipe,
        this.currencyPipe
      );
    }
  }

  buildContentDescription(item, isBold = false) {
    if (
      this.loadCard == true &&
      this.loadMerchant == true &&
      this.loadPromoter == true &&
      this.loadContact == true &&
      item
    ) {
      return this.utils.fillMessage(
        this.promoter,
        this.merchant,
        this.contact,
        this.card,
        item.Description,
        isBold,
        this.customPhonePipe,
        this.currencyPipe
      );
    }
  }

  sendResult() {
    if (this.documentLink && this.documentLink.ID) {
      if (this.cloneDefault === true && this.documentLink && this.documentLink.IsDefault === '1') {
        const p = {
          isPublic: this.isClonedPublic,
          isDefault: this.isClonedDefault,
          isStored: this.isClonedStored,
        };

        this.documentService.cloneDocumentLink(this.documentLink.ID, p, this.sessionType).subscribe((doc) => {
          if (doc && doc.ID) {
            const link = this.documentService.getLinkByVisitorType(doc.ID, this.visitorsType);
            this.getResult.emit(doc);
            this.getDocumentLink.emit(doc);
            this.getDocumentLinkID.emit(doc.ID);

            this.getDocumentLinkURL.emit(link);
          }
        });
      } else {
        const link = this.documentService.getLinkByVisitorType(this.documentLink.ID, this.visitorsType);
        this.getResult.emit(this.documentLink);
        this.getDocumentLink.emit(this.documentLink);
        this.getDocumentLinkID.emit(this.documentLink.ID);

        this.getDocumentLinkURL.emit(link);
      }
    } else {
      this.getResult.emit(null);
    }
  }

  backToOne() {
    this.currentPage = 1;
    this.currentPageDefault = 1;
    this.currentPageSM = 1;
    this.currentPageUser = 1;
  }

  changePage(e) {
    if (e) {
      if (this.typeFilter == 'user') {
        this.currentPageUser = e;
        this.currentPage = e;
      } else if (this.typeFilter == 'default') {
        this.currentPageDefault = e;
        this.currentPage = e;
      } else {
        this.currentPageSM = e;
        this.currentPage = e;
      }
    }
  }

  changeTab() {
    if (this.typeFilter == 'user') {
      this.currentPage = this.currentPageUser;
    } else if (this.typeFilter == 'default') {
      this.currentPage = this.currentPageDefault;
    } else {
      this.currentPage = this.currentPageSM;
    }
  }

  view(id) {
    if (id) {
      const ref2 = RootAppComponent.dialog.open(DocumentLinkViewComponent, {
        data: {
          linkID: id,
          card: this.card,
          merchant: this.merchant,
          contact: this.contact,
        },
        width: '950px',
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });
    }
  }

  orderBy(d) {
    if (d) {
      let isUpOrder = true;

      if (d && d[0] == '-') {
        isUpOrder = false;
      }

      if (this.orderByFilter === d) {
        if (isUpOrder == true) {
          this.orderByFilter = '-' + d;
        } else {
          this.orderByFilter = d.replace('-', '');
        }
      } else {
        this.orderByFilter = d;
      }
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  buildItem(_item) {
    const item = _item;

    if (item) {
      item.search = '';
      if (item.Label) {
        item.search = item.search + ' ' + item.Label;
      }
      if (item.Title) {
        item.search = item.search + ' ' + item.Title;
      }
      if (item.Message) {
        item.search = item.search + ' ' + item.Message;
      }
      if (item.Description) {
        item.search = item.search + ' ' + item.Description;
      }
      if (item['Product_Group.Code']) {
        item.search = item.search + ' ' + item['Product_Group.Code'];
      }
      if (item['Product_Group.Label']) {
        item.search = item.search + ' ' + item['Product_Group.Label'];
      }
      if (item['Product_Group.Label']) {
        item.search = item.search + ' ' + this.productUtil.getLabel(item['Product_Group.Code']);
      }

      if (item['Treatment_Amount']) {
        item.search = item.search + ' ' + item['Treatment_Amount'];
      }
      if (item['Contact_CalculatedName']) {
        item.search = item.search + ' ' + item['Contact_CalculatedName'];
      }
      if (typeof item.Tags === 'string') {
        item.search = item.search + ' ' + item.Tags;
      } else if (Array.isArray(item.Tags)) {
        item.search = item.search + ' ' + item.Tags.join('|');
      }

      if (item && item['DocumentFKs']) {
        item['DocumentFKs'] = item['DocumentFKs'].split('|');
      } else {
        item['DocumentFKs'] = [];
      }
      if (item && item['Tags']) {
        item['Tags'] = item['Tags'].split('|');
      } else {
        item['Tags'] = [];
      }

      if (item.IsDefault === '1') {
        item.type = 'default';
      } else {
        item.type = 'user';
      }

      if (item.Is_Finance === '1') {
        item.financeType = 'Financed';
      } else {
        item.financeType = 'free';
      }

      if (item['Product_Group.Code'] && item.Is_Finance === '1') {
        item.productGroup = item['Product_Group.Code'];
      } else {
        item.productGroup = null;
      }

      if (item.Treatment_Amount) {
        item.Treatment_Amount = Number(item.Treatment_Amount);
      } else {
        item.Treatment_Amount = 0;
      }
    }

    return item;
  }

  gotoNewDocumentLink() {
    const refLandingPageNewModalComponent = RootAppComponent.dialog.open(LandingPageNewModalComponent, {
      data: {},
      width: '650px',
    });

    refLandingPageNewModalComponent.componentInstance.getType.subscribe((type) => {
      refLandingPageNewModalComponent.close();

      refLandingPageNewModalComponent.afterClosed().subscribe((r) => {
        const ref2 = RootAppComponent.dialog.open(DocumentLinkCreateComponent, {
          data: {
            merchantID: this.merchantID,
            messageMode: this.messageMode,
            addToStore: true,
            type,
          },
          panelClass: 'noCard',
        });
        ref2.backdropClick().subscribe((res) => {});
        ref2.componentInstance.close.subscribe((res) => {
          ref2.close();
        });
        ref2.componentInstance.getResult.subscribe((item) => {
          if (item) {
            if (item && item.raw) {
              item = this.buildItem(item.raw);
            } else if (item) {
              item = this.buildItem(item);
            }
            this.documentLink = item;

            this.documentLinks.push(item);

            this.documentLinks = JSON.parse(JSON.stringify(this.documentLinks));

            this.backToOne();

            this.filterData = item.Label;
            this.typeFilter = null;

            ref2.close();
            ref2.afterClosed().subscribe((r) => {
              this.sendResult();
            });
          }
        });
      });

      refLandingPageNewModalComponent.componentInstance.close.subscribe((res) => {
        refLandingPageNewModalComponent.close();
      });
    });
  }

  clone(document) {
    if (document && document.ID) {
      const _doc = JSON.parse(JSON.stringify(document));
      _doc.Label = document.Label + ' (Cloned)';
      _doc.documentIDs = document.DocumentFKs;
      _doc.tags = document.Tags;
      if (_doc.Is_Finance === '1') {
        _doc['Type.Code'] = 'FLandPg';
      }
      const ref2 = RootAppComponent.dialog.open(DocumentLinkCreateComponent, {
        data: {
          merchantID: this.merchantID,
          addToStore: true,
          link: _doc,
        },
        panelClass: 'noCard',
      });
      ref2.backdropClick().subscribe((res) => {});
      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });

      ref2.componentInstance.getResult.subscribe((item) => {
        if (item) {
          if (item && item.raw) {
            item = this.buildItem(item.raw);
          } else if (item) {
            item = this.buildItem(item);
          }

          this.documentLink = item;

          this.documentLinks.push(item);

          this.documentLinks = JSON.parse(JSON.stringify(this.documentLinks));

          this.backToOne();

          this.filterData = item.Label;
          this.typeFilter = null;

          ref2.close();
          ref2.afterClosed().subscribe((r) => {
            this.sendResult();
          });
        }
      });
    }
  }

  edit(t) {
    if (t) {
      const ref2 = RootAppComponent.dialog.open(DocumentLinkCreateComponent, {
        data: {
          merchantID: this.merchantID,
          addToStore: true,
          linkID: t,
        },
        panelClass: 'noCard',
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });
      ref2.backdropClick().subscribe((res) => {});
      ref2.componentInstance.getResult.subscribe((item) => {
        if (item) {
          if (item && item.raw) {
            item = this.buildItem(item.raw);
          } else if (item) {
            item = this.buildItem(item);
          }

          this.documentLink = item;

          const index = this.documentLinks.findIndex((e) => {
            if (e && e.ID && e.ID == item.ID) {
              return true;
            }

            return false;
          });

          if (index !== -1) {
            this.documentLinks[index] = item;

            this.documentLinks = JSON.parse(JSON.stringify(this.documentLinks));

            this.backToOne();

            this.filterData = item.Label;
            this.typeFilter = null;
            ref2.close();
            ref2.afterClosed().subscribe((r) => {
              this.sendResult();
            });
          }
        }
      });
    }
  }

  delete(id) {
    if (id) {
      const confirm = new ConfirmDialog('delete', 'Are you sure  ?', 'Remove a message Template', 'No', 'Yes');
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.documentService.deleteDocumentLink(id, this.sessionType).subscribe((r) => {
            if (r) {
              const index = this.documentLinks.findIndex((e) => {
                if (e && e.ID && e.ID == id) {
                  return true;
                }

                return false;
              });

              if (index !== -1) {
                this.documentLinks.splice(index, 1);

                this.documentLinks = JSON.parse(JSON.stringify(this.documentLinks));
              }

              NotifyAppComponent.displayToast('success', 'Successfull', 'Message Template is removed');
              ref.close();
            }
          });
        }
      });
    }
  }

  list() {
    this.closeEvent();
    this.router.navigate(['/merchant', { outlets: { page: ['document-link-list', 'false'] } }]);
  }

  listTemplate() {
    this.closeEvent();
    this.router.navigate(['/merchant', { outlets: { page: ['document-link-list', 'true'] } }]);
  }
  collapse(e) {
    this.isCollapsed = e;
  }
}
