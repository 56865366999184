import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsService } from '../../services/utils.service';
import { NotifyAppComponent } from '../../types/notify-app-component';

@Component({
  selector: 'app-text-editor-html-insert',
  templateUrl: './text-editor-insert-html.component.html',
  styleUrls: ['./text-editor-insert-html.component.css'],
})
export class TextEditorInsertHtmlComponent implements OnInit {
  @Input()
  sourceVideo;

  @Input()
  sourceDocument;

  resultVideo;

  @Input()
  isDownload = true;

  @Input()
  isRedirection = true;

  docs = [];

  resultDocument;

  @Input()
  type = 'document';

  isModal = true;

  editorOptions = {
    formatOnType: true,
    formatOnPaste: true,
    language: 'html',
    autoIndent: true,
    autoClosingBrackets: 'always',
    dragAndDrop: true,
    fixedOverflowWidgets: true,
    theme: 'vs-dark',
  };

  @Output() closeModal = new EventEmitter();

  constructor(
    private sharedService: UtilsService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.sourceVideo) {
        this.sourceVideo = data.sourceVideo;
      }

      if (data.sourceDocument) {
        this.sourceDocument = data.sourceDocument;
      }
      if (data.type) {
        this.type = data.type;
      }
    }
  }

  ngOnInit() {}

  changeSource() {
    if (this.type == 'video') {
      if (this.sourceVideo && this.sourceVideo.indexOf('vimeo')) {
        let id = this.sourceVideo.replace('https://', '');
        let tag;
        id = this.sourceVideo.replace('vimeo.com/', '');

        if (id && id.split('/')[1]) {
          tag = id.split('/')[1];
        }

        id = id.split('/')[0];

        if (id) {
          let link = 'https://player.vimeo.com/video/' + id;
          if (tag) {
            link = link + '?h=' + tag;
          }

          this.resultVideo =
            '<iframe [src]="' +
            link +
            '" width="560" height="315" frameborder="0" ' +
            ' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
        }
      } else if (this.sourceVideo && this.sourceVideo.indexOf('youtube')) {
        let link = this.sourceVideo.replace('watch?v=', 'embed/');
        link = link.replace('watch/?v=', 'embed/');

        link = link.replace('.png', '');

        if (link) {
          this.resultVideo =
            '<iframe [src]="' +
            link +
            '" width="560" height="315" frameborder="0" ' +
            ' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
        }
      } else {
        this.resultVideo = 'Invalid URL';
      }
    }

    if (this.type == 'document' && this.sourceDocument) {
      this.docs = [];
      const p = {
        fields: 'ID',
      };

      this.sharedService.getEpdocument(this.sourceDocument, p).subscribe((res) => {
        if (res && res['ID']) {
          this.docs = [res['ID']];

          if (this.sourceDocument && window && window.location && window.location.origin) {
            this.resultDocument = window.location.origin + '/document/' + this.sourceDocument + '/View Document';
          }
        } else {
          NotifyAppComponent.displayToast('warning', '404', 'Document not');
        }
      });
    }
  }

  closeEvent() {
    this.closeModal.emit(true);
  }
}
