import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { Settings } from '../../../shared/types/settings';
import { DocumentService } from '../shared/document.service';
import { environment } from 'environments/environment';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { FileInsertionComponent } from '../../../shared/components/file-insertion/file-insertion.component';
import { LandingPageHeaderLookupComponent } from '../landing-page-header-lookup/landing-page-header-lookup.component';
import { BlobViewComponent } from '../../../shared/components/blob-view/blob-view.component';

import { trigger, state, style, transition, animate } from "@angular/animations";

@Component({
  selector: 'document-link-pdf-create',
  templateUrl: './document-link-pdf-create.component.html',
  styleUrls: ['./document-link-pdf-create.component.css'],
  animations: [
    trigger("simpleFadeAnimation", [
      state("in", style({ opacity: 1, height: "*" })),
      transition(":enter", [
        style({ opacity: 0, height: 0 }),
        animate(300)
      ]),
      transition(":leave",
        animate(300, style({ opacity: 0, height: 0 })))
    ])
  ]
})
export class DocumentLinkPdfCreateComponent implements OnInit {


  isHeaderImage = true

  close = new EventEmitter();
  applyDownload = new EventEmitter();

  getResultWithContent = new EventEmitter();
  getContent = new EventEmitter();

  sessionType;

  title;

  content
  _description;

  description;
  descriptionRaw;

  headerImage
  callForAction;


  settings = Settings.global;
  loadDescription = false;
  theme = {
    code: 'blue',
    background: 'linear-gradient(135deg, #0092eb 0%, #0dbad3 100%)',
    color: '#1b8bdd'
  }

  landingPage
  landingPageID;



  isHideDescription = false;
  messageMode = 'all'



  isPromoterOrAdmin;

  merchantID;
  contactID;
  cardID;
  merchant;
  promoter;
  card
  contact;

  displayPatient = false;
  tableName


  loadMessage = false;
  loadContact = false;
  loadMerchant = false;
  loadPromoter = false;
  loadCard = false;

  currentDentist;
  currentPractice;

  displayMessage = false;
  constructor(
    private authenticationService: AuthenticationService,
    private landingPageService: DocumentService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.description = this._description;
    this.descriptionRaw = this._description;

    if (data) {
      if (data && data.landingPageID) {
        this.landingPageID = data.landingPageID;
      } else if (data && data.landingPage) {
        this.landingPage = data.landingPage
      }


      if (data && data.cardID) {
        this.cardID = data.cardID;
      }
      if (data && data.card) {
        this.card = data.card;
      }
      if (data && data.merchant) {
        this.merchant = data.merchant;
      }
      if (data && data.messageMode) {
        this.messageMode = data.messageMode;
      }




      if (data && data.promoter) {
        this.promoter = data.promoter;
      }
      if (data && data.contact) {
        this.contact = data.contact;
      }
      if (data && data.contactID) {
        this.contactID = data.contactID;
      }

      if (data && data.tableName) {
        this.tableName = data.tableName;
      }





      if (data && data.displayMessage != null) {
        this.displayMessage = data.displayMessage;
      }


      if (data && data.merchantID) {
        this.merchantID = data.merchantID;
      }



      if (data && data.title) {
        this.title = data.title;
      }
      if (data && data.description) {
        this.description = data.description;
      }


    }
  }

  ngOnInit() {


    this.authenticationService.getCurrentDentist().subscribe(currentDentist => {
      this.authenticationService.getSessionType().subscribe(sessionType => {
        this.sessionType = sessionType;
        this.authenticationService.isPromoterOrAdmin().subscribe(r => {
          this.isPromoterOrAdmin = r;

          if (currentDentist && currentDentist.ID) {
            this.currentDentist = currentDentist;
          }

          if ((!this.merchantID && !this.merchant) || this.isPromoterOrAdmin != true) {

            this.authenticationService.getCurrentPractice().subscribe(r => {
              if (r && r.ID) {
                this.merchantID = r.ID;
                this.merchant = r;





                this.init()
              }
            })

          }
          else if ((this.merchant && this.merchant.ID) || this.merchantID) {
            if (this.merchant && this.merchant.ID) {
              if (!this.merchantID) {
                this.merchantID = this.merchant.ID;
              }




              this.init()
            }
            else {
              this.authenticationService.getMerchantDetails(this.merchantID, {}, this.sessionType).subscribe(r => {

                if (r && r.ID) {
                  this.merchant = r;





                  this.init()

                }
              })
            }

          }
        })
      })
    })

  }
  init() {

    if (this.landingPageID) {


      this.landingPageService.getDocumentLink(this.landingPageID, {}, this.sessionType).subscribe(r => {

        if (r && r.ID) {

          this.landingPage = r;

          if (this.landingPage && this.landingPage.ID)
            this.buildLandingPage(this.landingPage);
          this.loadMessage = true;
          this.authenticationService.getSessionType().subscribe(r => {
            this.sessionType = r;
            if (!this.merchantID) {
              this.authenticationService.getCurrentPractice().subscribe(r => {
                if (r && r.ID) {
                  this.merchantID = r.ID;
                }
                this.setup()
              })
            }
            else {
              this.setup()
            }

          })
        }
      })
    } else if (this.landingPage && this.landingPage.ID) {
      this.landingPageID = this.landingPage.ID;
      this.buildLandingPage(this.landingPage);
      this.loadMessage = true;
      this.authenticationService.getSessionType().subscribe(r => {
        this.sessionType = r;
        if (!this.merchantID) {
          this.authenticationService.getCurrentPractice().subscribe(r => {
            if (r && r.ID) {
              this.merchantID = r.ID;
            }
            this.setup()
          })
        }
        else {
          this.setup()
        }
      })

    }
  }



  setup() {

    if (this.cardID) {

      this.authenticationService.getCardDetails(this.cardID, this.tableName, {}, this.sessionType).subscribe(r => {
        if (r && r.ID) {
          this.card = r;
          this.cardID = r.ID;
        }
        this.loadCard = true;
      })

    }


    if (!this.promoter) {
      this.authenticationService.getPromoter().subscribe(r => {
        if (r) {
          this.promoter = r;
        }
        this.loadPromoter = true;
      })
    }
    else {
      this.loadPromoter = true;
    }

    if (!this.contactID && !this.contact) {

      this.authenticationService.getCurrentDentist().subscribe(r => {
        if (r) {
          this.contact = r;
          this.contactID = r.ID;
        }
        this.loadContact = true;
      })
    }

    else if (this.contact && this.contact.ID) {
      this.contactID = this.contact.ID
      this.loadContact = true;
    } else if (this.contactID) {

      this.authenticationService.getCardDetails(this.contactID, 'Contact', {}, this.sessionType).subscribe(r => {
        if (r && r.ID) {
          this.contact = r;
          this.contactID = r.ID;
        }
        this.loadContact = true;
      })

    }

    if (!this.merchantID && !this.merchant) {

      this.authenticationService.getCurrentPractice().subscribe(r => {
        if (r) {
          this.merchant = r;
        }
        this.loadMerchant = true;
      })
    } else if (this.merchant && this.merchant.ID) {
      this.merchantID = this.merchant.ID
      this.loadMerchant = true;
    } else if (this.merchantID) {

      this.authenticationService.getCardDetails(this.merchantID, 'Merchant', {}, this.sessionType).subscribe(r => {
        if (r && r.ID) {
          this.merchant = r;
          this.merchantID = r.ID;
        }
        this.loadMerchant = true;
      })

    }
  }




  getResultDescription(e) {

    if (e) {
      this.description = e.convertedMessage
      this.descriptionRaw = e.message
    }
    else {
      this.description = null
      this.descriptionRaw = null
    }
  }



  closeEvent() {
    this.close.emit(true)
  }


  buildLandingPage(landingPage) {
    if (landingPage) {





      if (landingPage['PDF_Title']) {
        this.title = landingPage['PDF_Title'];
      }
      else if (landingPage['Title']) {
        this.title = landingPage['Title'];
      }

      if (landingPage['PDF_Header_Image']) {
        if (landingPage['PDF_Header_Image'] == 'none') {
          this.isHeaderImage = false;
          this.headerImage = "merchant"
        }
        else {
          this.headerImage = landingPage['PDF_Header_Image'];
        }

      }
      else if (landingPage['Header_Image']) {
        if (landingPage['Header_Image'] == 'none') {
          this.isHeaderImage = false;
          this.headerImage = "merchant"
        }
        else {
          this.headerImage = landingPage['Header_Image'];
        }



      }


      if (landingPage['PDF_Description']) {


        if (landingPage['PDF_Description'] === 'none') {
          this.isHideDescription = true;
          this.description = '';
          this._description = '';
          this.descriptionRaw = '';
        }
        else {
          this.description = landingPage['PDF_Description'];
          this._description = landingPage['PDF_Description'];
          this.descriptionRaw = landingPage['PDF_Description'];
        }



      } else if (landingPage['Description']) {

        let description = landingPage['Description'].replace(/<h2/g, '<p');;
        description = description.replace(/<\/h2>/g, '</p>');;

        this.description = description;
        this._description = description;
        this.descriptionRaw = description;

      }


      if (landingPage['PDF_Call_To_Action']) {
        this.callForAction = landingPage['PDF_Call_To_Action'];
      } else {
        if (landingPage['Type.Code'] === 'FLandPg') {

          this.callForAction = `We understand and have teamed up with ${this.settings['brandName']} to make things more affordable. \n You can apply for a payment plan now. The application process takes as little as 5 minutesto complete, and you’ll receive feedback in less than 60 seconds.`;

        }
        else {
          this.callForAction = `There is always something new happening in the world.\n See what's happening and how we are changing our engagement with you to digital treatment presentation.`;
        }
      }


      if (landingPage['Contact_key']) {
        this.contactID = landingPage['Contact_key'];
      }


      if (landingPage['theme']) {

        this.theme = landingPage['theme']
      }

      this.loadDescription = true;
    }
  }

  getCircle(headerImage) {
    if (headerImage && headerImage.indexOf("?type=c") != -1) {
      return true
    }

    return false;
  }

  getMerchantLink() {
    return environment.nodeUrl + '/merchant/practice-picture-stream/' + this.merchantID;
  }
  upload() {
    let ref = RootAppComponent.dialog.open(FileInsertionComponent, {
      data: {
        documentType: 'LP_HI',
        displayGallery: false,
        isGallery: true,
        isFixedAspectRatio: true,
        roundCropper: true,
        aspectRatioType: 'full',
        documentNature: 'image',
        keepOriginal: false,
        maxFileSize: 1
      },
      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.getResult.subscribe((document) => {
      if (document && document.AWS_Bucket_Name && document.AWS_URL) {
        this.headerImage = `${Settings.global['AWSS3Prefix']}/${document.AWS_Bucket_Name}${document.AWS_URL}?type=c`

        ref.close();
      }
    });
  }

  galleryHeader() {
    let ref2 = RootAppComponent.dialog.open(LandingPageHeaderLookupComponent, {
      data: {
        merchantID: this.merchantID
      },
      width: "900px",
    });

    ref2.componentInstance.close.subscribe((res) => {
      ref2.close();
    });

    ref2.componentInstance.getResult.subscribe((res) => {

      if (res) {

        this.headerImage = res
        ref2.close();
      }

    });
  }

  preview() {


    let p = {
      title: this.title,
      callForAction: this.callForAction,
      description: this.description,
      headerImage: this.headerImage,
      hideMessage: !this.displayMessage
    }

    if (this.isHeaderImage == false) {
      p.headerImage = 'none'
    }
    if (this.isHideDescription == true) {
      p.description = 'none'
    }


    this.landingPageService.getLandingPagePDFBlob(this.landingPageID, p, this.sessionType).subscribe(content => {

      this.content = content;

      this.displayContent()

    })



  }

  displayContent() {
    let ref = RootAppComponent.dialog.open(BlobViewComponent, {
      data: {
        content: this.content,
        title: null,
        label: this.landingPage.Label,
        description: null,
        isProceed: false,
        isPrint: false,
        downloadPullRight: true,
        noTitleMode: true,
      },
      width: "800px",
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  save() {
    let p = {
      PDFTitle: this.title,
      PDFCallToAction: this.callForAction,
      PDFDescription: this.description,
      PDFHeaderImage: this.headerImage,
      hideMessage: !this.displayMessage
    }

    if (this.isHeaderImage == false) {
      p.PDFHeaderImage = 'none'
    }

    if (this.isHideDescription == true) {
      p.PDFDescription = 'none'
    }


    this.landingPageService.modifyDocumentLink(this.landingPageID, p, this.sessionType).subscribe(r => {

      if (r && r.ID) {
        


        this.landingPageService.getLandingPagePDFBlob(this.landingPageID, { hideMessage: !this.displayMessage,lastModified: r['LastModified'] }, this.sessionType).subscribe(content => {

          this.content = content;

          this.getResultWithContent.emit({ content: this.content, LastModified: r.LastModified })
          this.getContent.emit(content)

        })
      }
    })
  }



}
