import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { OperatorService } from '../../operator/shared/operator.service';
import { ContractService } from '../shared/contract.service';

@Component({
  selector: 'app-compliance-task',
  templateUrl: './compliance-task.component.html',
  styleUrls: ['./compliance-task.component.css'],
  animations: [
    trigger('fade', [transition(':enter', [style({ opacity: 0 }), animate('.6s ease')])]),
    trigger('stagger', [transition(':enter', [query(':enter', stagger('.3s', [animateChild()]), { optional: true })])]),
  ],
})
export class ComplianceTaskComponent implements OnInit {
  @Input()
  contractID;

  @Input()
  largeHeading = true;

  @Input()
  isStaticHeight = false;

  @Output()
  closeEvent = new EventEmitter();

  isModal = false;
  isAdminOrPromoter = false;

  complianceTask;

  util = new UtilsClass();

  constructor(
    private activeRouter: ActivatedRoute,
    private contractService: ContractService,
    private authenticationService: AuthenticationService,
    private operatorService: OperatorService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.contractID = data;

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.activeRouter.params.subscribe((params) => {
      if (params['contractID']) {
        this.contractID = params['contractID'];
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;

        const payload = {
          contractID: this.contractID,
        };
        this.contractService.getComplianceTask(payload, this.isAdminOrPromoter).subscribe((res) => {
          this.complianceTask = res;
        });
      });
    });
  }

  closeModal() {
    this.closeEvent.emit(true);
  }

  rearrangeNote(note) {
    if (note && typeof note === 'string') {
      return note.split(/(?=\[)/g);
    }
  }

  getOperator(id) {
    let name;
    // this.operatorService.getfullOperatorDetails(id).subscribe(res => {
    //   name = res['CalculatedName'];
    // })
    return name;
  }
}
