import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { InvitationService } from '../../invitation/shared/services/invitation.service';

@Component({
  selector: 'app-merchant-main-card',
  templateUrl: './merchant-main-card.component.html',
  styleUrls: ['./merchant-main-card.component.css'],
})
export class MerchantMainCardComponent implements OnInit {
  @Input()
  invitationID;
  @Input()
  actions;

  wakandaID;
  accessByMerchant = true;
  display = false;
  sessionType = 'merchant';

  constructor(
    private invitationService: InvitationService,
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.activeRoute.params.subscribe((params) => {
      if (!params['invitationID']) {
        this.router.navigate(['/404']);
      } else {
        this.invitationID = params['invitationID'];

        if (params['action']) {
          this.actions = params['action'];
        }

        this.authenticationService.getSessionType().subscribe((sessionType) => {
          this.sessionType = sessionType;

          const p = {
            fields: 'WakandaID',
          };
          this.invitationService
            .getInvitationDetails(this.invitationID, p, this.sessionType, false)
            .subscribe((res) => {
              if (res && res.WakandaID) {
                this.wakandaID = res.WakandaID;
                this.display = true;
              } else {
                this.router.navigate(['/404']);
              }
            });
        });
      }
    });
  }

  ngOnInit() {}
}
