import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

@Component({
  selector: 'app-log-view',
  templateUrl: './log-view.component.html',
  styleUrls: ['./log-view.component.css'],
})
export class LogViewComponent implements OnInit {
  @Input() logObject = {};
  @Output()
  close = new EventEmitter();
  utils = new UtilsClass();

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.logObject = data;
    }
  }

  ngOnInit() {}

  closeEvent() {
    this.close.emit(true);
  }
}
