<mat-card class="rel supplier-card clearfix mb" *ngIf="thirdPartyAccess" @ngIfAnimation>
  <div class="card-header primary-gradient-img clearfix stacked">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <app-third-party-client-picture
          class="profile-picture-view"
          [thirdPartyClientID]="thirdPartyAccess['Third_Party_Client_Key']"
        >
        </app-third-party-client-picture>
        <h2 class="summary-header rm-m">
          {{ thirdPartyAccess['Comment'] }}
        </h2>
        <p class="clearfix small rm-mb">
          <span
            [ngClass]="{
              'chip-enable': thirdPartyAccess.Is_Active === '1',
              'chip-disable': !thirdPartyAccess.Is_Active === '0'
            }"
          >{{ thirdPartyAccess.Is_Active == '0' ? 'Not Active' : 'Active' }}</span
          >
        </p>
      </div>
    </div>
  </div>

  <mat-dialog-content>
    <p class="full-width mb small">
      Access to your profile is always controlled by you. In order for integration to occur you need to enter the API
      Key into the <strong>{{ thirdPartyAccess['Label'] }}</strong> or supply these details to
      <strong>{{ thirdPartyAccess['Label'] }}</strong> for them to configure.
    </p>
    <div class="full-width flex">
      <div class="full-width text-center secret-container mb" *ngIf="thirdPartyAccess['API_Key']">
        <div class="full-width text-left" *ngIf="thirdPartyAccess['API_Key']">
          <p class="full-width text-left rm-m">
            <button
              class="pull-right"
              (click)="copy(thirdPartyAccess['API_Key'])"
              mat-button
              mat-icon-button
            >
              <mat-icon>file_copy</mat-icon>
            </button>

            <strong>API Key: </strong> {{ thirdPartyAccess['API_Key'] }}
          </p>
        </div>
      </div>
    </div>

    <div class="full-width mb clearfix row">
      <div class="full-width row clearfix details flex" *ngIf="thirdPartyAccess['Merchant_Trading_As']">
        <mat-icon class="ico">home</mat-icon>
        <div class="row-content clearfix">
          <p class="rm-mb rm-mt">
            {{ thirdPartyAccess['Merchant_Trading_As'] }}

            <button
              class="ml smr pull-right btn"
              (click)="viewMerchant(thirdPartyAccess['Merchant_Key'])"
              style="height: 20px; width: 20px"
              mat-button
              mat-icon-button
            >
              <mat-icon>visibility</mat-icon>
            </button>
          </p>
          <p class="small">{{ "KEYWORD.practice" | translate | titlecase}} Name</p>
        </div>
      </div>

      <div class="full-width row clearfix details flex" *ngIf="thirdPartyAccess['Dentist_Calculated_Name']">
        <mat-icon class="ico">person</mat-icon>
        <div class="row-content clearfix">
          <p class="rm-mb rm-mt">
            {{ thirdPartyAccess['Dentist_Calculated_Name'] }}
          </p>
          <p class="small">Default Staff Member</p>
        </div>
      </div>

      <div class="row full-width clearfix details flex" *ngIf="thirdPartyAccess['Template_Label']">
        <mat-icon class="ico">brush</mat-icon>
        <div class="row-content">
          <p class="rm-mb rm-mt">
            {{ thirdPartyAccess['Template_Label'] }}
            <button
              class="ml smr pull-right btn"
              *ngIf="isViewTemplate == true"
              (click)="viewTemplate(thirdPartyAccess['Template_Key'])"
              style="height: 20px; width: 20px"
              mat-button
              mat-icon-button
            >
              <mat-icon>visibility</mat-icon>
            </button>
          </p>
          <p class="small">Default Template</p>
        </div>
      </div>

      <h3 class="sr-title mt rm-mb">Third-Party Application</h3>

      <div class="row full-width clearfix details flex" *ngIf="thirdPartyAccess['Label']">
        <mat-icon class="ico">verified_user</mat-icon>
        <div class="row-content">
          <p class="rm-mb rm-mt">{{ thirdPartyAccess['Label'] }}</p>
          <p class="small">Name</p>
        </div>
      </div>

      <div class="row full-width clearfix details flex" *ngIf="thirdPartyAccess['Description']">
        <mat-icon class="ico">description</mat-icon>
        <div class="row-content">
          <p class="rm-mb rm-mt small">{{ thirdPartyAccess['Description'] }}</p>
          <p class="small">Description</p>
        </div>
      </div>

      <div class="row full-width clearfix details flex" *ngIf="thirdPartyAccess['Email']">
        <mat-icon class="ico">email</mat-icon>
        <div class="row-content">
          <p class="rm-mb rm-mt">{{ thirdPartyAccess['Email'] }}</p>
          <p class="small">Email</p>
        </div>
      </div>
      <div class="full-width row clearfix details flex" *ngIf="thirdPartyAccess['Phone']">
        <mat-icon class="ico">phone</mat-icon>
        <div class="row-content clearfix">
          <p class="rm-mb rm-mt">
            {{ thirdPartyAccess['Phone'] | customPhone: 'landLine' }}
          </p>
          <p class="small">Phone</p>
        </div>
      </div>

      <div class="full-width row clearfix details flex" *ngIf="thirdPartyAccess['Address.Calculated']">
        <mat-icon class="ico">location_on</mat-icon>
        <div class="row-content clearfix">
          <p class="rm-mb rm-mt">
            {{ thirdPartyAccess['Address.Calculated'] }}
          </p>
          <p class="small">Address</p>
        </div>
      </div>

      <hr/>

      <div
        class="full-width text-center mb"
        *ngIf="
          thirdPartyAccess['Website'] ||
          thirdPartyAccess['Facebook'] ||
          thirdPartyAccess['Instagram'] ||
          thirdPartyAccess['Twitter'] ||
          thirdPartyAccess['LinkedIn'] ||
          thirdPartyAccess['Pinterest'] ||
          thirdPartyAccess['Tumblr'] ||
          thirdPartyAccess['Vimeo'] ||
          thirdPartyAccess['YouTube']
        "
      >
        <button
          class="connect btn-small smr"
          *ngIf="thirdPartyAccess['Website']"
          (click)="openLink(thirdPartyAccess['Website'])"
          mat-mini-fab
          color="accent"
          matTooltip="Website"
        >
          <mat-icon class="fas fa-globe"></mat-icon>
        </button>

        <button
          class="connect btn-small smr"
          *ngIf="thirdPartyAccess['Facebook']"
          (click)="openLink(thirdPartyAccess['Facebook'])"
          mat-mini-fab
          color="accent"
          matTooltip="Facebook"
        >
          <mat-icon class="fab fa-facebook-f" style="color: #4267b2 !important"></mat-icon>
        </button>

        <button
          class="connect btn-small smr"
          *ngIf="thirdPartyAccess['Instagram']"
          (click)="openLink(thirdPartyAccess['Instagram'])"
          mat-mini-fab
          color="accent"
          matTooltip="Instagram"
        >
          <mat-icon class="fab fa-instagram" style="color: #e1306c !important"></mat-icon>
        </button>

        <button
          class="connect btn-small smr"
          *ngIf="thirdPartyAccess['Twitter']"
          (click)="openLink(thirdPartyAccess['Twitter'])"
          mat-mini-fab
          color="accent"
          matTooltip="Twitter"
        >
          <mat-icon class="fab fa-twitter" style="color: #00acee !important"></mat-icon>
        </button>

        <button
          class="connect btn-small smr"
          *ngIf="thirdPartyAccess['LinkedIn']"
          (click)="openLink(thirdPartyAccess['LinkedIn'])"
          mat-mini-fab
          color="accent"
          matTooltip="LinkedIn"
        >
          <mat-icon class="fab fa-linkedin" style="color: #0072b1 !important"></mat-icon>
        </button>

        <button
          class="connect btn-small smr"
          *ngIf="thirdPartyAccess['Pinterest']"
          (click)="openLink(thirdPartyAccess['Pinterest'])"
          mat-mini-fab
          color="accent"
          matTooltip="Pinterest"
        >
          <mat-icon class="fab fa-pinterest" style="color: #e60023 !important"></mat-icon>
        </button>

        <button
          class="connect btn-small smr"
          *ngIf="thirdPartyAccess['Tumblr']"
          (click)="openLink(thirdPartyAccess['Tumblr'])"
          mat-mini-fab
          color="accent"
          matTooltip="Tumblr"
        >
          <mat-icon class="fab fa-tumblr" style="color: #36465d !important"></mat-icon>
        </button>

        <button
          class="connect btn-small smr"
          *ngIf="thirdPartyAccess['Vimeo']"
          (click)="openLink(thirdPartyAccess['Vimeo'])"
          mat-mini-fab
          color="accent"
          matTooltip="Vimeo"
        >
          <mat-icon class="fab fa-vimeo" style="color: #1ab7ea !important"></mat-icon>
        </button>

        <button
          class="connect btn-small smr"
          *ngIf="thirdPartyAccess['YouTube']"
          (click)="openLink(thirdPartyAccess['YouTube'])"
          mat-mini-fab
          color="accent"
          matTooltip="YouTube"
        >
          <mat-icon style="color: #ff0000 !important" lass="fab fa-youtube"></mat-icon>
        </button>
      </div>
    </div>
  </mat-dialog-content>
</mat-card>
