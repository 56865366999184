import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { CustomerProspectModule } from '../customer-prospect/customerProspect.module';
import { OperatorModule } from '../operator/operator.module';
import { ActivityLogListGlobalComponent } from './activity-log-list-global/activity-log-list-global.component';
import { ActivityLogListOverviewComponent } from './activity-log-list-overview/activity-log-list-overview.component';
import { ActivityLogListComponent } from './activity-log-list/activity-log-list.component';
import { ActivityLogRoutingModule } from './shared/activity-log-routing.module';
import { ActivityLogService } from './shared/activity-log.service';

@NgModule({
  imports: [SharedModule, OperatorModule, CustomerProspectModule, ActivityLogRoutingModule],
  declarations: [ActivityLogListOverviewComponent, ActivityLogListGlobalComponent, ActivityLogListComponent],
  exports: [ActivityLogListOverviewComponent, ActivityLogListGlobalComponent, ActivityLogListComponent],
  providers: [ActivityLogService],
})
export class ActivityLogModule {}
