<mat-dialog-content class="dentist-list-container" [ngClass]="{ 'no-modal-height': isModal != true }">
  <div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
    <div class="row clearfix padded rel">
      <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
    </div>
  </div>
  <div [hidden]="listDB.data.length == 0">
    <div>
      <h1 class="dataHeader">
        <!-- Contracts -->
        {{title}}
        <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
      </h1>
      <mat-card class="list-card">
        <div class="row clearfix searchArea primary-gradient-img">
          <!-- Search -->

          <div class="row clearfix flex">


            <div class="full-width flex animated fadeInDown clearfix">
              <mat-form-field class="full-width mr" appearance="outline">
                <mat-label>Search...</mat-label>
                <input class="clearfix" (input)="setFilter($event.target.value, 'fullName')" name="CustomerName"
                  placeholder="Search..." matInput />
              </mat-form-field>


              <mat-form-field class="qtr-width " *ngIf="(isPromoterOrAdmin==true ||  isMerchantAdmin == true ) "
                appearance="outline">
                <mat-label>Account Status</mat-label>
                <mat-select class="full-width " (selectionChange)="setFilter($event.value, 'hasOperatorAccount')"
                  name="accountType" placeholder="Account Status">
                  <mat-option [value]="null">
                    All
                  </mat-option>
                  <mat-option [value]="'0'">
                    <mat-icon class="fas fa-user smr"></mat-icon> Staff Without Account
                  </mat-option>
                  <mat-option [value]="'1'">
                    <mat-icon class="fas fa-sign-in-alt smr"></mat-icon> Staff With Account
                  </mat-option>

                </mat-select>
              </mat-form-field>


            </div>
          </div>
        </div>

        <!-- Table -->
        <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
          <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
          <!-- ID Column -->
          <ng-container cdkColumnDef="ProfileImage" sticky>
            <mat-header-cell *cdkHeaderCellDef mat-sort-header></mat-header-cell>
            <mat-cell class="text-center" *cdkCellDef="let row" (click)="openQuick(row['ID'])">

              <app-card-picture class="pull-left  invitationContactLookup120 " [cardID]="row['ID']"
                [LastModified]="row['LastModified']" [tableName]="'Contact'">
              </app-card-picture>

            </mat-cell>
          </ng-container>
          <ng-container cdkColumnDef="MerchantName">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> Merchant</mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="openQuick(row['ID'])" matTooltip="{{ row['ContactForCardName'] }}">
              {{ row['ContactForCardName'] }}
            </mat-cell>
          </ng-container>
          <ng-container cdkColumnDef="TradingAS">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> Trading As</mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="openQuick(row['ID'])" matTooltip="{{ row['TradingAS'] }}">
              {{ row['TradingAS'] }}
            </mat-cell>
          </ng-container>
          <ng-container cdkColumnDef="DentistName">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> Name</mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="openQuick(row['ID'])" matTooltip="{{ row['CalculatedName'] }}">
              <strong> {{ row['CalculatedName'] }}</strong>
            </mat-cell>
          </ng-container>
          <ng-container cdkColumnDef="DentistPhone">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> Phone</mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="openQuick(row['ID'])"
              matTooltip="{{ row['phones.Number'] | customPhone: 'landLine' }}">
              {{ row['phones.Number'] | customPhone: 'landLine' }}
            </mat-cell>
          </ng-container>
          <ng-container cdkColumnDef="DentistMobile">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> Mobile</mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="openQuick(row['ID'])"
              matTooltip="{{ row['mobiles.Number'] | customPhone }}">
              {{ row['mobiles.Number'] | customPhone }}
            </mat-cell>
          </ng-container>
          <ng-container cdkColumnDef="DentistEmail">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> Email</mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="openQuick(row['ID'])" matTooltip="{{ row['emails.Email'] }}">
              {{ row['emails.Email'] }}
            </mat-cell>
          </ng-container>
          <ng-container cdkColumnDef="Type">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> Contact type</mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="openQuick(row['ID'])" matTooltip="{{ row['ContactType.Label'] }}">
              {{ row['ContactType.Label'] }}
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="Login">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> Login Permission</mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="openQuick(row['ID'])"
              matTooltip="{{ row['EConnectLogin.Permission'] }}">
              <span class="chip" [ngClass]="{
                enable: row['EConnectLogin.Permission'] == 'Enabled',
                disable: row['EConnectLogin.Permission'] == 'Disabled',
                noAccess: row['EConnectLogin.Permission'] == 'No Access'
              }">
                {{ row['EConnectLogin.Permission'] }}</span>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="LastAccess">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> Last login</mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="openQuick(row['ID'])">
              <span class="list-label"
                *ngIf="row['EConnectLogin.LastAccessed'] && row['EConnectLogin.LastAccessed']!=' '"
                [style.background]="util.daysPastColor(row['EConnectLogin.LastAccessed'])"
                matTooltip="{{ row['EConnectLogin.LastAccessed'] | customDateTime }}">
                {{row['EConnectLogin.LastAccessed'] | TimeLabelPast }} ago</span>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="IsAdmin">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header>Admin</mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="openQuick(row['ID'])">



              <span class="list-label smaller" *ngIf="row['operatorIsAdmin'] == '1'"
                style="background-color: rgb(90, 186, 71)">
                <mat-icon>check</mat-icon>
              </span>
              <span class="list-label smaller" *ngIf="row['operatorIsAdmin'] != '1'" style="background-color: #bbbbbb">
                <mat-icon>close</mat-icon>
              </span>

            </mat-cell>
          </ng-container>


          <ng-container cdkColumnDef="hasAccount">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header>Account</mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="openQuick(row['ID'])">

              <span class="list-label smaller" *ngIf="row['hasOperatorAccount'] == '1'"
                style="background-color: rgb(90, 186, 71)">
                <mat-icon>check</mat-icon>
              </span>
              <span class="list-label smaller" *ngIf="row['hasOperatorAccount'] != '1'"
                style="background-color: rgb(239, 71, 39)">
                <mat-icon>close</mat-icon>
              </span>

            </mat-cell>
          </ng-container>



          <ng-container cdkColumnDef="CreatedDate">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> Join Date</mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="openQuick(row['ID'])"
              matTooltip="{{ row['DateTimeCreated'] | customDateTime }}">
              <span>
                {{ row.DateTimeCreated | TimeLabelPast }} ago</span>
            </mat-cell>
          </ng-container>
          <ng-container cdkColumnDef="Actions">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <div style="float: right">
                <button [matMenuTriggerFor]="menu" mat-icon-button>
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button *ngIf="row['ID']" (click)="openQuick(row['ID'])" mat-menu-item>
                    <mat-icon class=" fas fa-eye smr  "></mat-icon>
                    <span>Quick View </span>
                  </button>


                  <button *ngIf="row['ID']" (click)="openQuickViewDialog(row['ID'])" mat-menu-item>
                    <mat-icon class="smr ">description</mat-icon>
                    <span>Detailed View </span>
                  </button>


                  <button *ngIf="row['ID']" (click)="editContactDetails(row)" mat-menu-item>
                    <mat-icon class="smr ">edit</mat-icon>
                    <span>Quick Edit </span>
                  </button>
                  <hr *ngIf="row['mobiles.Number'] || row['emails.Email']
              ">

                  <button *ngIf="row['ID']  && row['mobiles.Number']" (click)="contactSMS(row['ID'])" mat-menu-item>
                    <mat-icon class="fas fa-sms  smr"></mat-icon>
                    <span> Contact By SMS </span>
                  </button>


                  <button *ngIf="row['ID'] && row['emails.Email']" (click)="contact(row['ID'])" mat-menu-item>
                    <mat-icon class="fas fa-at smr"></mat-icon>
                    <span> Contact By Email </span>
                  </button>

                  <hr *ngIf="
                (isPromoterOrAdmin == true || isMerchantAdmin == true) &&
                row['EConnectLogin.Permission'] == 'Enabled'
              ">
                  <button *ngIf="
                    (isPromoterOrAdmin == true || isMerchantAdmin == true) &&
                    row['EConnectLogin.Permission'] == 'Enabled'
                  " (click)="disableLogin(row['EConnectLogin.Econnect_Key'])" mat-menu-item>
                    <mat-icon class="smr ">lock</mat-icon>
                    <span> Disable login</span>
                  </button>

                  <button *ngIf="isPromoterOrAdmin == true || isMerchantAdmin == true"
                    (click)="editWorkingHours(row['ContactForCard_key'], row['ID'])" mat-menu-item>
                    <mat-icon class="smr ">av_timer</mat-icon>
                    <span> Edit Working Hours </span>
                  </button>


                  <hr *ngIf="
                (isPromoterOrAdmin == true || isMerchantAdmin == true) &&
                row['EConnectLogin.Permission'] == 'Disabled'
              ">
                  <button *ngIf="
                    (isPromoterOrAdmin == true || isMerchantAdmin == true) &&
                    row['EConnectLogin.Permission'] == 'Disabled'
                  " (click)="enableLogin(row['EConnectLogin.Econnect_Key'])" mat-menu-item>
                    <mat-icon class="smr ">vpn_key</mat-icon>
                    <span> Enable login </span>
                  </button>


                  <button *ngIf="isPromoterOrAdmin == true || isMerchantAdmin == true "
                    (click)="operatorAccess(row['EConnectLogin.Econnect_Key'])" mat-menu-item>
                    <mat-icon class="fas fa-key smr "></mat-icon>
                    <span>Portals Access</span>
                  </button>




                  <button *ngIf="isPromoterOrAdmin == true && row['IsAdmin'] == true"
                    (click)="unsetMerchantAdmin(row['EConnectLogin.Econnect_Key'])" mat-menu-item>
                    <mat-icon class="fas fa-user-cog smr"></mat-icon>
                    <span> Set Role to {{ 'KEYWORD.practice' | translate }} </span>
                  </button>

                  <button *ngIf="isPromoterOrAdmin == true && row['IsAdmin'] == false"
                    (click)="setMerchantAdmin(row['EConnectLogin.Econnect_Key'])" mat-menu-item>
                    <mat-icon class="fas fa-user-shield smr"></mat-icon>
                    <span>Set Role to Merchant-Admin</span>
                  </button>
                  <button *ngIf="
                    (isPromoterOrAdmin == true || isMerchantAdmin == true) &&
                    row['EConnectLogin.Permission'] == 'No Access'
                  " (click)="createLogin(row['ID'])" mat-menu-item>
                    <mat-icon class="smr">vpn_key</mat-icon>
                    <span> Create login access </span>
                  </button>

                  <hr
                    *ngIf="(isPromoterOrAdmin==true ||  isMerchantAdmin == true ) && row['operatorID'] && row['emails.Email']" />
                  <button
                    *ngIf="(isPromoterOrAdmin==true ||  isMerchantAdmin == true ) && row['operatorID'] && row['emails.Email']"
                    (click)="passwordUpdate(row.operatorID)" mat-menu-item>
                    <mat-icon class=" fas fa-lock-open mr"></mat-icon>
                    <span>Prompt password update</span>
                  </button>


                  <hr *ngIf="isPromoterOrAdmin==true  && row['operatorID'] && row['ID']" />
                  <button color="accent" class="btn" *ngIf="isPromoterOrAdmin==true  && row['operatorID'] && row['ID']"
                    (click)="loginAsUser(row)" mat-menu-item>
                    <mat-icon class="  fas fa-user-lock accent-color mr"></mat-icon>
                    <span class=" accent-color">Login as user</span>
                  </button>

                </mat-menu>

              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions">
        </mat-paginator>
      </mat-card>
    </div>
  </div>
  <div class="clearfix full-width" *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
    <app-empty-list-message></app-empty-list-message>
  </div>
</mat-dialog-content>
<div class="full-width mt" *ngIf="isModal==true">
  <button class="pull-left btn-large  ml" (click)="closeEvent()" mat-raised-button color="primary">
    Close
  </button>


  <button class="pull-right btn-large  ml" [matMenuTriggerFor]="menuListsasaS"
    *ngIf="isPromoterOrAdmin==true  || isMerchantAdmin==true" mat-raised-button color="accent">
    <mat-icon>person_add</mat-icon> Add a Staff Member
  </button>
  <mat-menu #menuListsasaS="matMenu">
    <button (click)="createNewContact()" mat-menu-item>
      <strong>Without</strong> Sign-in Account
    </button>
    <hr />
    <button [routerLink]="['/merchant/', { outlets: { page: ['dentist-invite-create'] } }]" mat-menu-item>
      <strong>With</strong> Sign-in Account
    </button>

  </mat-menu>


</div>
