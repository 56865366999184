import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, skip, switchMap, take } from 'rxjs/operators';
import { AppStateService } from '../../../../../../../core/app-state/app-state.service';
import { CustomerProspectService } from '../../../../../../customer-prospect/shared/customerProspect.service';
import { CustomerAndProspect } from '../../../../../../invitation/shared/types/invitation.type';
import { Merchant } from '../../../../../../merchant/shared/types/merchant.type';
import { Note, NoteFilterType } from '../../../../../shared/type/notes.type';
import { NoteModalMode } from '../../../notes.modal';

@Component({
  selector: 'ipv-note-patient-list',
  templateUrl: './note-patient-list.component.html',
  styleUrls: ['./note-patient-list.component.css'],
})
export class NotePatientListComponent implements OnDestroy, OnInit {
  @ViewChild('patientFilter', { static: true }) patientNameFilter: ElementRef<HTMLInputElement>;

  @Output() merchantSet = new EventEmitter<Partial<Merchant>>();
  @Output() noteModalModeSetToList: EventEmitter<NoteModalMode> = new EventEmitter();
  @Output() selectPatientButtonClicked: EventEmitter<ElementRef<HTMLInputElement>> = new EventEmitter();
  @Output() newPatientButtonClicked: EventEmitter<ElementRef<HTMLInputElement>> = new EventEmitter();
  @Output() patientNameFilterUpdated: EventEmitter<string> = new EventEmitter();
  @Output() loadMoreClicked: EventEmitter<void> = new EventEmitter();
  @Output() filterByChanged: EventEmitter<NoteFilterType> = new EventEmitter();
  @Output() areNotesPrivateChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() noteSelected = new EventEmitter<CustomerAndProspect>();

  @Input() practice: Partial<Merchant>;
  @Input() notes: Note[];
  @Input() showLoadMoreButton: boolean;

  patientNameFilterDebounceSubject = new BehaviorSubject<string>('');

  selectedPatient: Partial<CustomerAndProspect>;

  filterBy: NoteFilterType = 'latest';
  areNotesPrivate = false;

  constructor(private customerProspectService: CustomerProspectService, private appStateService: AppStateService) {}

  ngOnInit() {
    this.patientNameFilterDebounceSubject
      .pipe(untilDestroyed(this), skip(1), debounceTime(1300))
      .subscribe((nameFilter) => {
        this.patientNameFilterUpdated.emit(nameFilter);
      });
  }

  ngOnDestroy() {
    /** required for untilDestroyed (Replace with @UntilDestroyed() Decorator in angular 9 and delete ngOnDestroy()) */
  }

  selectPatient() {
    this.selectPatientButtonClicked.emit(this.patientNameFilter);
  }

  newPatient() {
    this.newPatientButtonClicked.emit(this.patientNameFilter);
  }

  setNameFilter(nameFilter: string) {
    this.patientNameFilterDebounceSubject.next(nameFilter);
  }

  setAreNotesPrivate(areNotesPrivate: MatButtonToggleChange) {
    this.areNotesPrivate = areNotesPrivate.value;
    this.areNotesPrivateChanged.emit(areNotesPrivate.value);
  }

  clearPatientNameFilter() {
    this.patientNameFilterUpdated.emit('');
    this.patientNameFilter.nativeElement.value = '';
  }

  loadMore() {
    this.loadMoreClicked.emit();
  }

  changeFilterBy(filterBy: NoteFilterType) {
    this.filterBy = filterBy;
    this.filterByChanged.emit(filterBy);
  }

  selectNote(patientID: string) {
    this.appStateService
      .getAppState$()
      .pipe(
        take(1),
        switchMap((appState) => {
          return this.customerProspectService.fetchCustomerAndProspect$(patientID, {}, appState.sessionType);
        })
      )
      .subscribe((patient) => {
        this.noteSelected.emit(patient);
        this.noteModalModeSetToList.emit('list');
      });
  }

  setMerchant(merchant: Partial<Merchant>) {
    this.merchantSet.emit(merchant);
  }

  getFollowUpCount(whoKey: string) {
    return this.notes.filter(
      (note) => note['Who.key'] === whoKey && note.RecontactDone === '0' && note['Dates.Recontact'] !== '0000-00-00'
    ).length;
  }
}
