/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dynamic-product-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./components/retail-product-list/retail-product-list.component.ngfactory";
import * as i3 from "./components/retail-product-list/retail-product-list.component";
import * as i4 from "../shared/services/product-catalogue.service";
import * as i5 from "@angular/material/dialog";
import * as i6 from "../../../core/app-state/app-state.service";
import * as i7 from "./components/dental-product-list/dental-product-list.component.ngfactory";
import * as i8 from "../../../shared/pipes/binary-bool/binary-bool.pipe";
import * as i9 from "./components/dental-product-list/dental-product-list.component";
import * as i10 from "../../treatment/shared/treatment.service";
import * as i11 from "@angular/common";
import * as i12 from "./dynamic-product-list.component";
var styles_DynamicProductListComponent = [i0.styles];
var RenderType_DynamicProductListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DynamicProductListComponent, data: {} });
export { RenderType_DynamicProductListComponent as RenderType_DynamicProductListComponent };
function View_DynamicProductListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-retail-product-list", [["class", "list-filter-card clearfix full-width"]], null, [[null, "getLength"], [null, "productSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("getLength" === en)) {
        var pd_0 = (_co.getProductLength($event) !== false);
        ad = (pd_0 && ad);
    } if (("productSelected" === en)) {
        var pd_1 = (_co.setProductSelected($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_RetailProductListComponent_0, i2.RenderType_RetailProductListComponent)), i1.ɵdid(1, 49152, null, 0, i3.RetailProductListComponent, [i4.ProductCatalogueService, i5.MatDialog, i6.AppStateService], { supplierID: [0, "supplierID"], modalTitle: [1, "modalTitle"], pageMode: [2, "pageMode"] }, { productSelected: "productSelected", getLength: "getLength" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.supplierID; var currVal_1 = _co.modalTitle; var currVal_2 = _co.pageMode; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_DynamicProductListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-retail-product-list", [["class", "list-filter-card clearfix full-width"]], null, [[null, "productSelected"], [null, "getLength"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("productSelected" === en)) {
        var pd_0 = (_co.setProductSelected($event) !== false);
        ad = (pd_0 && ad);
    } if (("getLength" === en)) {
        var pd_1 = (_co.getProductLength($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_RetailProductListComponent_0, i2.RenderType_RetailProductListComponent)), i1.ɵdid(1, 49152, null, 0, i3.RetailProductListComponent, [i4.ProductCatalogueService, i5.MatDialog, i6.AppStateService], { supplierID: [0, "supplierID"], modalTitle: [1, "modalTitle"], pageMode: [2, "pageMode"] }, { productSelected: "productSelected", getLength: "getLength" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.supplierID; var currVal_1 = _co.modalTitle; var currVal_2 = _co.pageMode; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_DynamicProductListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ipv-dental-product-list", [["class", "list-filter-card clearfix full-width"]], null, [[null, "getLength"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("getLength" === en)) {
        var pd_0 = (_co.getProductLength($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_DentalProductListComponent_0, i7.RenderType_DentalProductListComponent)), i1.ɵprd(512, null, i8.BinaryBoolPipe, i8.BinaryBoolPipe, []), i1.ɵdid(2, 49152, null, 0, i9.DentalProductListComponent, [i10.TreatmentService, i5.MatDialog, i6.AppStateService, i8.BinaryBoolPipe], { supplierID: [0, "supplierID"], miniDisplay: [1, "miniDisplay"], defaultHeading: [2, "defaultHeading"], pageSize: [3, "pageSize"] }, { getLength: "getLength" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.supplierID; var currVal_1 = _co.miniDisplay; var currVal_2 = _co.defaultHeading; var currVal_3 = _co.pageSize; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_DynamicProductListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ipv-dental-product-list", [["class", "list-filter-card clearfix full-width"]], null, [[null, "getLength"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("getLength" === en)) {
        var pd_0 = (_co.getProductLength($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_DentalProductListComponent_0, i7.RenderType_DentalProductListComponent)), i1.ɵprd(512, null, i8.BinaryBoolPipe, i8.BinaryBoolPipe, []), i1.ɵdid(2, 49152, null, 0, i9.DentalProductListComponent, [i10.TreatmentService, i5.MatDialog, i6.AppStateService, i8.BinaryBoolPipe], { supplierID: [0, "supplierID"], miniDisplay: [1, "miniDisplay"], defaultHeading: [2, "defaultHeading"], pageSize: [3, "pageSize"] }, { getLength: "getLength" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.supplierID; var currVal_1 = _co.miniDisplay; var currVal_2 = _co.defaultHeading; var currVal_3 = _co.pageSize; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_DynamicProductListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, null, null, null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i11.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicProductListComponent_2)), i1.ɵdid(4, 278528, null, 0, i11.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i11.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicProductListComponent_3)), i1.ɵdid(6, 278528, null, 0, i11.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i11.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicProductListComponent_4)), i1.ɵdid(8, 278528, null, 0, i11.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i11.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicProductListComponent_5)), i1.ɵdid(10, 278528, null, 0, i11.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i11.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 2, 0, currVal_0); var currVal_1 = "retail"; _ck(_v, 4, 0, currVal_1); var currVal_2 = "breeze"; _ck(_v, 6, 0, currVal_2); var currVal_3 = "none"; _ck(_v, 8, 0, currVal_3); var currVal_4 = "dental"; _ck(_v, 10, 0, currVal_4); }, null); }
export function View_DynamicProductListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_DynamicProductListComponent_1)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i11.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.industryType$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DynamicProductListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-dynamic-product-list", [], null, null, null, View_DynamicProductListComponent_0, RenderType_DynamicProductListComponent)), i1.ɵdid(1, 49152, null, 0, i12.DynamicProductListComponent, [i6.AppStateService, [2, i5.MatDialogRef], [2, i5.MAT_DIALOG_DATA]], null, null)], null, null); }
var DynamicProductListComponentNgFactory = i1.ɵccf("ipv-dynamic-product-list", i12.DynamicProductListComponent, View_DynamicProductListComponent_Host_0, { pageSize: "pageSize", miniDisplay: "miniDisplay", supplierID: "supplierID", defaultHeading: "defaultHeading", modalTitle: "modalTitle" }, { getLength: "getLength", productSelected: "productSelected" }, []);
export { DynamicProductListComponentNgFactory as DynamicProductListComponentNgFactory };
