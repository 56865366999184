import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { MapMultipleViewComponent } from '../../../shared/components/map-multiple-view/map-multiple-view.component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { CustomerService } from '../../customer/shared/customer.service';
import { ExistingPatientService } from '../../existing-patient/shared/existing-patient.service';

@Component({
  selector: 'app-customer-prospect-list-map',
  templateUrl: './customer-prospect-list-map.component.html',
  styleUrls: ['./customer-prospect-list-map.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class CustomerProspectListMapComponent implements OnInit {
  @ViewChild(MapMultipleViewComponent, { static: false }) public mapComponent: MapMultipleViewComponent;

  @Input() merchantID;

  @Input() dateFrom;
  @Input() dateTo;

  @Input() searchType = 'name';

  hasMapSearch = false;

  radius = 5000;
  showRadius = false;

  util = new UtilsClass();

  addMarkerEvent = new EventEmitter();

  resetPositionsEvent = new EventEmitter();

  isLoaded = false;
  existingPatients = [];
  serviceRef;
  description =
    'Click on marker to view patient details , Financed customers are on green circles , and prospect on orange circles';
  title = 'Patients';

  isAdminOrPromoter = false;
  dataArray;

  customerIcon = 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/green-dot.png';
  prospectIcon = 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/orange-dot.png';

  selectedItemEvent = new EventEmitter();
  p = 1;
  itemPageIndex = 0;
  filter;
  selectedItemIndex;
  selectedItem;

  displayMap = false;

  constructor(
    private authenticationService: AuthenticationService,
    private customerService: CustomerService,
    private existingPatientService: ExistingPatientService
  ) {}

  ngOnInit() {
    this.setup();
  }

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }

  setup() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }

    this.resetPositionsEvent.emit(true);

    this.displayMap = false;
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isAdminOrPromoter = res;
      const payload = {
        section: 0,
        limit: 300,
        merchantID: this.merchantID,
        dateTo: this.util.EPdateFormat(this.dateTo),
        dateFrom: this.util.EPdateFormat(this.dateFrom),
        hasAdress: true,
        orderBy: 'CalculatedName',
        fields:
          'ID,TableName,LastModified,addresses.Calculated,addresses.Suburb,addresses.State,addresses.Postcode,addresses.Country.Code,' +
          'addresses.Unit,addresses.Street Nr,addresses.Street Name,addresses.Street Type,Address.Latitude,Address.Longitude,' +
          'CalculatedName',
      };

      this.serviceRef = this.existingPatientService
        .customerOrProspectList(payload, this.isAdminOrPromoter)
        .subscribe((res) => {
          this.displayMap = true;
          if (res && res.length > 0) {
            this.isLoaded = true;
            this.existingPatients = res;

            this.existingPatients.sort((a, b) => {
              return a.CalculatedName.localeCompare(b.CalculatedName);
            });
            for (let i = 0; i < this.existingPatients.length; i++) {
              if (this.existingPatients[i]) {
                this.existingPatients[i].icon = this.getIcon(this.existingPatients[i]);
                this.addMarkerEvent.emit(this.existingPatients[i]);
              }
            }

            this.dataArray = this.existingPatients;

            const innerFunction = (section) => {
              section = section + 1;

              UtilsClass.loadingDataSection(section);
              payload.section = section;

              this.serviceRef = this.existingPatientService
                .customerOrProspectList(payload, this.isAdminOrPromoter)
                .subscribe((res) => {
                  if (res.length > 0) {
                    this.existingPatients = this.existingPatients.concat(res);

                    this.existingPatients.sort((a, b) => {
                      return a.CalculatedName.localeCompare(b.CalculatedName);
                    });

                    for (let i = res.length; i < this.existingPatients.length; i++) {
                      const o = this.existingPatients[i];

                      o.icon = this.getIcon(o);

                      this.addMarkerEvent.emit(o);
                    }

                    innerFunction(section);
                  } else {
                    return true;
                  }
                });
              UtilsClass.stopLoadingInterceptor();
            };

            innerFunction(1);
          } else {
            this.isLoaded = true;
          }
        });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.merchantID && changes.merchantID.previousValue != changes.merchantID.currentValue) ||
      (changes.dateTo && changes.dateTo.previousValue != changes.dateTo.currentValue) ||
      (changes.dateFrom && changes.dateFrom.previousValue != changes.dateFrom.currentValue)
    ) {
      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;
        this.setup();
      });
    }
  }

  getIcon(item) {
    if (item && item['TableName']) {
      if (item['TableName'] == 'Prospect') {
        return this.prospectIcon;
      } else if (item['TableName'] == 'Customer') {
        return this.customerIcon;
      }
    }
  }

  addCoordinateEvent(item) {
    if (item && item['ID'] && item['latitude'] && item['longitude'] && item['TableName']) {
      const payload = {
        latitude: item['latitude'],
        longitude: item['longitude'],
      };
      if (item['TableName'] == 'Prospect') {
        this.existingPatientService.addCoordinate(item['ID'], payload).subscribe((res) => {});
      } else if (item['TableName'] == 'Customer') {
        this.customerService.addCoordinate(item['ID'], payload).subscribe((res) => {});
      }
    }
  }

  getSelected(item) {
    if (item && item['ID'] && item['TableName']) {
      this.selectedItemIndex;

      this.existingPatients.map((x, index) => {
        if (x && x['ID']) {
          if (x['ID'] == item['ID']) {
            this.selectedItemIndex = index;

            return index;
          }
        }
      });

      this.selectedItem = item;
      this.itemPageIndex = Math.trunc(this.selectedItemIndex / 10) + 1;
      this.pageChange(this.itemPageIndex);
    } else {
      this.pageChange(1);
      this.selectedItem = null;
      this.selectedItemIndex = null;
      this.itemPageIndex = null;
    }
  }

  closeSelectedItem() {
    this.pageChange(1);
    this.selectedItem = null;
    this.selectedItemIndex = null;
    this.itemPageIndex = null;
  }

  selectItem(item, index, pageIndex) {
    if (item && item['ID']) {
      this.selectedItemIndex = index;
      this.selectedItemEvent.emit(item);
      this.selectedItem = item;
      this.itemPageIndex = pageIndex;
      this.pageChange(pageIndex);
    }
  }

  pageChange(pageIndex) {
    this.p = pageIndex;
  }

  // radius circle adjust
  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'km';
    }

    return value;
  }

  // map control input

  practiceLocation() {
    this.mapComponent.changeToCurrentPractice();
  }

  myLocation() {
    this.mapComponent.changeToCurrent();
  }

  resetMap() {
    this.mapComponent.resetMap();
  }
}
