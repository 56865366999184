<div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="listDB.data.length == 0">
  <div class="row clearfix">
    <h1 class="dataHeader rm-m pull-left">
      {{ title }}
      <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
    </h1>

    <div class="pull-right rel buttonrow">
      <button class="clearfix pull-right" (click)="createNew()" mat-raised-button color="accent">
        <mat-icon>add</mat-icon>
        Create new Template
      </button>
    </div>

    <div class="pull-right selection-items" @ngIfAnimation>
      <button
        class="animated mr"
        (click)="selectAll()"
        mat-stroked-button
        color=""
        matTooltip="Select All"
      >
        <mat-icon>border_all</mat-icon>
      </button>

      <button
        class="animated mr"
        *ngIf="selectedIDs && selectedIDs.length > 0"
        [matMenuTriggerFor]="ActionBtnmenu"
        mat-stroked-button
        matTooltip="Options"
      >
        <mat-icon class="fas fa-cog"></mat-icon>  <span *ngIf="selectedIDs && selectedIDs.length>0"
          class="selected-number-item  accent-background">{{selectedIDs.length}}</span>
      </button>
      <mat-menu #ActionBtnmenu="matMenu">
        <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
          <mat-icon>select_all</mat-icon> Deselect
        </button>

        <!-- <button mat-menu-item color="" class="row select-btn" (click)="disableGroup()">
          <mat-icon>cancel</mat-icon> {{"Disable" | translate }}
        </button>

        <button mat-menu-item color="" class="row select-btn" (click)="enableGroup()">
          <mat-icon>check_circle</mat-icon> {{"Enable" | translate}}
        </button> -->

        <hr/>
        <button
          class="row select-btn"
          *ngIf="isPromoterOrAdmin == true"
          (click)="setDefaultGroup()"
          mat-menu-item
          color=""
        >
          <mat-icon>visibility</mat-icon> Publish
        </button>

        <button
          class="row select-btn"
          *ngIf="isPromoterOrAdmin == true"
          (click)="setNotDefaultGroup()"
          mat-menu-item
          color=""
        >
          <mat-icon>visibility_off</mat-icon> Hide
        </button>
        <hr/>

        <button
          class="row select-btn"
          *ngIf="selectedIDs && selectedIDs.length > 0"
          (click)="removeGroup()"
          mat-menu-item
          color=""
        >
          <mat-icon>delete</mat-icon>
          Remove
        </button>
      </mat-menu>

      <!-- <button mat-stroked-button color="" class="animated" (click)="selectPage()"
          matTooltip="{{'Select Page' | translate }}">
          <mat-icon>border_outer</mat-icon>
        </button> -->

      <mat-button-toggle-group
        class="btn-group rm-m selectType mr"
        [(ngModel)]="typeFilter"
        (ngModelChange)="setFilter(typeFilter, 'Is_ManuallyCreated')"
      >
        <mat-button-toggle class="" [value]="null"> All</mat-button-toggle>

        <mat-button-toggle class="" [value]="'1'"> Manual created</mat-button-toggle>

        <mat-button-toggle class="" [value]="'0'"> Master</mat-button-toggle>
      </mat-button-toggle-group>

      <mat-button-toggle-group
        class="btn-group rm-m selectType mr ml"
        [(ngModel)]="typeFilter2"
        (ngModelChange)="setFilter(typeFilter2, 'isPublished')"
      >
        <mat-button-toggle class="" [value]="null"> All</mat-button-toggle>

        <mat-button-toggle class="" [value]="'1'"> Published</mat-button-toggle>

        <mat-button-toggle class="" [value]="'0'"> not Published</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <div class="clearfix">
    <p class="small" *ngIf="templateType == 'master'">
      Only <strong>Master</strong> templates are published, you can change this setting from admin panel under
      <strong>Communication Tab</strong>
    </p>
    <p class="small" *ngIf="templateType == 'manual'">
      Only <strong>Manual created</strong> templates can be published, you can change this setting from admin panel
      under
      <strong>Communication Tab</strong>
    </p>

    <p class="small" *ngIf="templateType == 'masterAndManual'">
      Both <strong>Manual created</strong> and <strong>Master</strong> templates are published, you can change this
      setting from admin panel under
      <strong>Communication Tab</strong>
    </p>
  </div>

  <div class="row clearfix">
    <mat-card class="list-card">
      <div class="row clearfix mb searchArea primary-gradient-img">
        <div class="row clearfix flex">
          <div class="full-width flex animated fadeInDown">
            <mat-icon class="mt mr">search</mat-icon>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Label</mat-label>
              <input class="clearfix" (input)="setFilter($event.target.value, 'Label')" name="tag" matInput/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Purpose</mat-label>

              <mat-select
                class="margin-btm"
                (selectionChange)="setFilter($event.value, 'realPurpose')"
                name="Purpose"
                placeholder="Purpose"
              >
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option
                  *ngFor="
                    let p of dataSource.filteredData
                      | objectPropertyValue: 'realPurpose'
                      | unique
                      | without: ['', null]
                      | orderBy
                  "
                  [value]="p"
                >
                  {{ p | ucfirst }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="white full-width" *ngIf="merchantTags.length > 0" appearance="outline">
              <mat-label>Tags</mat-label>
              <mat-select
                class=""
                (selectionChange)="setFilter($event.value, 'Tags')"
                name="Tag"
                placeholder="Tag"
                multiple
              >
                <mat-option *ngFor="let a of merchantTags | orderBy" [value]="a">
                  {{ a }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Target</mat-label>

              <mat-select
                class="margin-btm"
                (selectionChange)="setFilter($event.value, 'Target')"
                name="Target"
                placeholder="Target"
              >
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option
                  *ngFor="
                    let p of dataSource.filteredData
                      | objectPropertyValue: 'Target'
                      | unique
                      | without: ['', null]
                      | orderBy
                  "
                  [value]="p"
                >
                  {{ p }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>SMS</mat-label>

              <mat-select
                class="margin-btm"
                (selectionChange)="setFilter($event.value, 'Supports.SMS')"
                name="smsSupportL"
                placeholder="Owner"
              >
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option [value]="'1'"> Support SMS</mat-option>

                <mat-option [value]="'0'"> Not Support SMS</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- Table -->
      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <ng-container cdkColumnDef="selectCheckBox" sticky>
          <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div class="margin-btm pull-left">
              <mat-checkbox
                [disabled]="row['Is_ManuallyCreated'] != '1'"
                [checked]="isSelected(row['ID'])"
                (change)="select(row['ID'], $event.checked)"
                color="accent"
              >
              </mat-checkbox>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Picture" sticky>
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Picture</mat-header-cell>
          <mat-cell class="text-center" *cdkCellDef="let row" (click)="viewTemplate(row['ID'])">
            <app-template-picture-view
              class="supplierIcon2 prod text-center"
              *ngIf="row"
              [hideZoomButton]="true"
              [templateID]="row['ID']"
              [LastModified]="row['LastModified']"
            >
            </app-template-picture-view>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Label" sticky>
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Label</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTemplate(row['ID'])" matTooltip="{{ row['Label'] }}">
            <h3>{{ row['Label'] }}</h3>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Purpose">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Purpose</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTemplate(row['ID'])" matTooltip="{{ row['Purpose_Label'] }}">
            {{ row['Purpose_Label'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Owner">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Owner</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="viewTemplate(row['ID'])"
            matTooltip="{{ templateUtil.getTemplageOwner(row['Tag']) }}"
          >
            {{ templateUtil.getTemplageOwner(row['Tag']) }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Target">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Target</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="viewTemplate(row['ID'])"
            matTooltip="{{ templateUtil.getTemplateTarget(row['Tag']) }}"
          >
            {{ templateUtil.getTemplateTarget(row['Tag']) }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Date">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Date Created</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="viewTemplate(row['ID'])"
            matTooltip="{{ row['DateTimeCreated'] | customDateTime }}"
          >
            <span> {{ row['DateTimeCreated'] | TimeLabelPast }} ago </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="active">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Active</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row['ID'])">
            <span
              class="list-label smaller"
              *ngIf="row['Is_ManuallyCreated'] != '1'"
              matTooltip="Active"
              style="background-color: rgb(90, 186, 71)"
            >
              <mat-icon>check</mat-icon>
            </span>

            <span
              class="list-label smaller"
              *ngIf="row['Deactivated'] != '1' && row['Is_ManuallyCreated'] == '1'"
              matTooltip="Active"
              style="background-color: rgb(90, 186, 71)"
            >
              <mat-icon>check</mat-icon>
            </span>
            <span
              class="list-label smaller"
              *ngIf="row['Deactivated'] == '1' && row['Is_ManuallyCreated'] == '1'"
              matTooltip="Inactive"
              style="background-color: rgb(239, 71, 39)"
            >
              <mat-icon>close</mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="IsDefault">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Published</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row['ID'])">
            <span
              class="list-label smaller"
              *ngIf="row['isPublished'] == '1'"
              matTooltip="Published"
              style="background-color: rgb(90, 186, 71)"
            >
              <mat-icon>check</mat-icon>
            </span>
            <span
              class="list-label smaller"
              *ngIf="row['isPublished'] != '1'"
              matTooltip="Not Published"
              style="background-color: rgb(239, 71, 39)"
            >
              <mat-icon>close</mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Editable">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Master</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row['ID'])">
            <span
              class="list-label smaller"
              *ngIf="row['Is_ManuallyCreated'] == '0'"
              matTooltip="Master Template"
              style="background-color: rgb(90, 186, 71)"
            >
              <mat-icon>check</mat-icon>
            </span>
            <span
              class="list-label smaller"
              *ngIf="row['Is_ManuallyCreated'] != '0'"
              matTooltip="Manually created"
              style="background-color: rgb(239, 71, 39)"
            >
              <mat-icon>close</mat-icon>
            </span>
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Tags">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Tags</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTemplate(row['ID'])">
            <div *ngIf="row && row['Tags']">
              <mat-chip-list>
                <mat-chip *ngFor="let tag of row['Tags'] | orderBy" [ngClass]="{ 'default-chip': indexOfTag(tag) }">
                  {{ tag }}
                </mat-chip>
              </mat-chip-list>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="supportSMS">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> SMS</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row['ID'])">
            <span
              class="list-label smaller"
              *ngIf="row['Supports.SMS'] == '1'"
              matTooltip="Support SMS"
              style="background-color: rgb(90, 186, 71)"
            >
              <mat-icon>check</mat-icon>
            </span>
            <span
              class="list-label smaller"
              *ngIf="row['Supports.SMS'] != '1'"
              matTooltip="Support SMS"
              style="background-color: rgb(239, 71, 39)"
            >
              <mat-icon>close</mat-icon>
            </span>
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button (click)="viewTemplate(row['ID'])" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span>View Template</span>
                </button>

                <button
                  *ngIf="row['LandingPageURL'] || row['LandingPageCode']"
                  (click)="openLandingPage(row)"
                  mat-menu-item
                >
                  <mat-icon>exit_to_app</mat-icon>
                  <span>Go to Landing Page</span>
                </button>

                <button (click)="testCampaign(row['Tag'])" mat-menu-item>
                  <mat-icon>send</mat-icon>
                  <span>Send a test Email</span>
                </button>

                <button *ngIf="row['Is_ManuallyCreated'] == '1'" (click)="editTemplate(row['ID'])" mat-menu-item>
                  <mat-icon>edit</mat-icon>
                  <span>Edit Template</span>
                </button>
                <!--
                <hr *ngIf="row['Is_ManuallyCreated']=='1'">

                <button *ngIf="row['Is_ManuallyCreated']=='1' && row['Deactivated']!='1' && isPromoterOrAdmin==true"
                  mat-menu-item (click)="disable(row['ID'])">
                  <mat-icon>cancel</mat-icon>
                  <span>{{"Disable" | translate}}</span>
                </button>

                <button *ngIf="row['Is_ManuallyCreated']=='1' && row['Deactivated']=='1' && isPromoterOrAdmin==true"
                  mat-menu-item (click)="enable(row['ID'])">
                  <mat-icon>check_circle</mat-icon>
                  <span>{{"Enable" | translate}}</span>
                </button> -->

                <hr *ngIf="row['Is_ManuallyCreated'] == '1'"/>

                <button
                  *ngIf="
                    templateType != 'master' &&
                    row['Is_ManuallyCreated'] == '1' &&
                    row['isPublished'] != '1' &&
                    isPromoterOrAdmin == true
                  "
                  (click)="markAsDefault(row['ID'])"
                  mat-menu-item
                >
                  <mat-icon>visibility</mat-icon>
                  <span>Publish</span>
                </button>

                <button
                  *ngIf="
                    templateType != 'master' &&
                    row['Is_ManuallyCreated'] == '1' &&
                    row['isPublished'] == '1' &&
                    isPromoterOrAdmin == true
                  "
                  (click)="markAsNotDefault(row['ID'])"
                  mat-menu-item
                >
                  <mat-icon>visibility_off</mat-icon>
                  <span>Hide</span>
                </button>

                <hr *ngIf="row['Is_ManuallyCreated'] == '1'"/>
                <button *ngIf="row['Is_ManuallyCreated'] == '1'" (click)="removed(row['ID'])" mat-menu-item>
                  <mat-icon>remove_circle</mat-icon>
                  <span>Remove Template</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginator
        [length]="dataSource.filteredData.length"
        [pageIndex]="0"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
      >
      </mat-paginator>
    </mat-card>
  </div>
</div>
<div *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <div class="pull-right rel buttonrow">
    <button class="clearfix pull-right" (click)="createNew()" mat-raised-button color="primary">
      <mat-icon>add</mat-icon>
      Create new Template
    </button>
  </div>

  <app-empty-list-message></app-empty-list-message>
</div>
