/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./appointment-action-hidden.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./appointment-action-hidden.component";
import * as i3 from "../../../core/authentication/shared/authentication.service";
import * as i4 from "../shared/appointment.service";
import * as i5 from "../../../shared/pipes/custom-date.pipe";
import * as i6 from "../../merchant/shared/merchant.service";
import * as i7 from "../../dentist/shared/dentist.service";
var styles_AppointmentActionHiddenComponent = [i0.styles];
var RenderType_AppointmentActionHiddenComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppointmentActionHiddenComponent, data: {} });
export { RenderType_AppointmentActionHiddenComponent as RenderType_AppointmentActionHiddenComponent };
export function View_AppointmentActionHiddenComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_AppointmentActionHiddenComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-appointment-action-hidden", [], null, null, null, View_AppointmentActionHiddenComponent_0, RenderType_AppointmentActionHiddenComponent)), i1.ɵdid(1, 114688, null, 0, i2.AppointmentActionHiddenComponent, [i3.AuthenticationService, i4.AppointmentService, i5.CustomDatePipe, i6.MerchantService, i7.DentistService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppointmentActionHiddenComponentNgFactory = i1.ɵccf("app-appointment-action-hidden", i2.AppointmentActionHiddenComponent, View_AppointmentActionHiddenComponent_Host_0, { runAction: "runAction", merchantID: "merchantID" }, { result: "result" }, []);
export { AppointmentActionHiddenComponentNgFactory as AppointmentActionHiddenComponentNgFactory };
