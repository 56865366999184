<div class="full-width clearfix text-center row" *ngIf="displayList == false">
  <button class="mb mt pull-left" *ngIf="documents && documents.length > 0" (click)="displayDocument()"
    mat-raised-button color="primary">
    <mat-icon>document</mat-icon>
    Display document({{ documents.length }})
  </button>
</div>

<div class="full-width clearfix row" *ngIf="settelmentDocuments && settelmentDocuments.length > 0">
  <app-ep-document-list [title]="'Settlement documents'" [documents]="settelmentDocuments"></app-ep-document-list>
</div>

<div *ngIf="isSkipLookup === false || isSkipLookup === true">
  <aw-wizard class="settlementCreate" [disableNavigationBar]="true" navBarLayout="large-filled-symbols">
    <aw-wizard-step *ngIf="isSkipLookup == false" [navigationSymbol]="{ symbol: counter + 1 }">
      <div class="container" id="step-0" *ngIf="hideLookup == false" @ngIfAnimation>
        <div class="row clearfix text-center">
          <h2 class="sr-title inline-block">Select {{ "KEYWORD.patient" | translate }} and contract</h2>
        </div>
        <mat-card class="card clearfix full-width">
          <div class="row clearfix"
            *ngIf="displayContractList == false && displayCustomerList == false && !customerID && !contractID">
            <div class="card-header primary-gradient-img clearfix inModal mb">
              <div class="row clearfix">
                <!-- merchant calculated name -->
                <div class="row titleArea clearfix noPad">
                  <h2 class="summary-header rm-m">
                    {{ "KEYWORD.patient" | translate | titlecase }} Lookup
                    <span class="subLabel">
                      Search for your {{ "KEYWORD.patient" | translate }} that you wish to process a settlement request
                      for.
                    </span>
                  </h2>
                </div>
              </div>
            </div>

            <!-- <h3 class="sr-title sm-h3 rm-mt">Customer Lookup</h3> -->
            <div class="row clearfix" *ngIf="isPromoterOrAdmin == true && hideMerchantSelect == false">
              <p class="help-label">
                To send an invitation on behalf of a merchant select a
                merchant below, otherwise, the invitation will be sent by the promoter.
              </p>

              <div class="row clearfix selectMerchant flex">
                <mat-form-field class="full-width mb" appearance="outline">
                  <mat-label>Please Select a Merchant</mat-label>
                  <mat-select [(ngModel)]="merchantID" (ngModelChange)="changeMerchant()" name="MerchantName">
                    <mat-option [value]="null"> My Account</mat-option>
                    <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']"
                      [value]="m['ID']">
                      <span class="list-label pull-right" *ngIf="m['Status'] && m['Status'] == 'Active'"
                        [style.background]="'#00d07a'">
                        {{ m['Status'] }}
                      </span>

                      <span class="list-label pull-right" *ngIf="m['Status'] && m['Status'] != 'Active'"
                        [style.background]="'#ff5722'">
                        {{ m['Status'] }}
                      </span>
                      {{ m['TradingAs'] || m['CalculatedName'] }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="full-width" *ngIf="merchantID && merchantID != 'none'">
                  <button class="pull-right merchBtn" (click)="viewMerchant()" mat-raised-button color="primary">
                    <mat-icon>person</mat-icon>
                    <span class="mobHide">View Merchant</span>
                  </button>
                </div>
              </div>
            </div>

            <form class="lookup-form" #lookupForm="ngForm">

              <div class="full-width flex">
                <mat-form-field class="full-width anim mr" appearance="outline">
                  <mat-label>First name</mat-label>
                  <input [(ngModel)]="filterFirstName" (keyup.enter)="getCustomer()" matInput name="filterFirstName"
                    tabindex="1" />
                </mat-form-field>

                <mat-form-field class="half-width anim mr" appearance="outline">
                  <mat-label>Middle name</mat-label>
                  <input [(ngModel)]="filterMiddleName" (keyup.enter)="getCustomer()" matInput name="nameMiddle"
                    tabindex="2" />
                </mat-form-field>



                <mat-form-field class="full-width anim" appearance="outline">
                  <mat-label>Last name</mat-label>
                  <input [(ngModel)]="filterName" (keyup.enter)="getCustomer()" matInput name="name" tabindex="3" />
                </mat-form-field>

              </div>

              <mat-form-field class="full-width anim" appearance="outline">
                <mat-label>Mobile</mat-label>
                <input [(ngModel)]="filterPhone" (keyup.enter)="getCustomer()" matInput name="phone" tabindex="4" />
              </mat-form-field>
              <mat-form-field class="full-width anim add-mb" appearance="outline">
                <mat-label>Email</mat-label>
                <input [(ngModel)]="filterEmail" (keyup.enter)="getCustomer()" matInput name="email" type="email"
                  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" tabindex="5" />
              </mat-form-field>

              <div class="drop-buttons">
                <button class="inline btn-new-invite btn-large"
                  [disabled]="!lookupForm.form.valid || (filterName == ''&& filterPhone == '' && filterFirstName == '' && filterMiddleName == '' && filterEmail == '')"
                  (click)="getCustomer()" type="button" mat-raised-button color="accent" tabindex="4">
                  Search
                </button>
              </div>
            </form>
          </div>

          <div class="row clearfix full-width"
            *ngIf="displayCustomerList == true && displayContractList == false && !customerID && !contractID">
            <div class="card-header primary-gradient-img clearfix inModal mb">
              <div class="row clearfix">
                <!-- merchant calculated name -->
                <div class="row clearfix noPad">
                  <h2 class="summary-header rm-m">
                    Possible Match
                    <span class="subLabel">
                      Select a customer below that you intend to process a settlement request for.
                    </span>
                  </h2>
                </div>
              </div>
            </div>
            <div class="full-width clearfix row flex" *ngIf="customers && customers.length > 3">
              <mat-form-field class="full-width anim" appearance="outline">
                <mat-label>Filter: First name, Last Name, Email, phone.</mat-label>
                <input [(ngModel)]="filterCustomer" matInput name="filterCustomer"
                  placeholder="Filter: First name, Last Name, Email, phone." />
              </mat-form-field>
            </div>

            <div class="customerContainer full-width clearfix" @ngIfAnimation>
              <!-- customer list item -->
              <div class="customerRecord noCard" *ngFor="
                  let customer of customers
                    | FilterArrayMultipleValue: ['FirstName', 'Name', 'mobiles.Number', 'emails.Email']:filterCustomer
                    | OrderArrayObject: ['FirstName', 'Name']
                    | unique: 'ID'
                " [ngClass]="{ activeExistingcustomer: selectedCustomer && selectedCustomer['ID'] == customer['ID'] }"
                (click)="selectCustomer(customer)">
                <div class="row clearfix anim">
                  <div class="action-button pull-right">
                    <button class="actionMenuToggle" [matMenuTriggerFor]="btnPatMenu" mat-icon-button>
                      <mat-icon>more_vert</mat-icon>
                    </button>

                    <mat-menu #btnPatMenu="matMenu">
                      <button class="" (click)="openCustomerDetailsDialog(customer['ID'])" mat-menu-item color="accent"
                        matTooltip="View Profile: {{ customer['FirstName'] }} {{ customer['Name'] }} ">
                        <mat-icon>visibility</mat-icon>
                        View {{ "KEYWORD.patient" | translate | titlecase }}
                      </button>
                    </mat-menu>
                  </div>

                  <app-customer-view-picture class="customerContainer-style" [patientID]="customer['ID']">
                  </app-customer-view-picture>

                  <div class="pull-left">
                    <h3 class="sr-title rm-mb">{{ customer['FirstName'] }} {{ customer['MiddleName'] }}
                      {{ customer['Name'] }}</h3>
                    <div>
                      <p class="small reduce-m" mdLine>
                        <span class="labelName">
                          Phone:
                        </span>
                        <span class="value">
                          {{ customer['mobiles.Number'] | customPhone }}
                        </span>
                      </p>
                    </div>
                    <div>
                      <p class="small reduce-m" mdLine>
                        <span class="labelName">
                          Email:
                        </span>
                        <span class="value">
                          {{ customer['emails.Email'] }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="drop-buttons">
                <button class="step-back pull-left position" (click)="goBackCustomerLlookup()" mat-mini-fab
                  color="primary">
                  <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <button class="inline btn-new-invite btn-large"
                  [disabled]="!selectedCustomer || !selectedCustomer['ID']" (click)="contractLookup()" mat-raised-button
                  color="accent">
                  Search
                </button>
              </div>
            </div>
          </div>
          <div class="row clearfix full-width disCust"
            *ngIf="displayCustomerList == false && displayContractList == true && customerID && !contractID">
            <!-- Customer card header -->
            <div class="cardheader clearfix primary-gradient-img text-left" *ngIf="selectedCustomer" @ngIfAnimation>
              <div class="row clearfix">
                <div class="pull-left">
                  <app-customer-view-picture class="customerContainer-style" [patientID]="selectedCustomer['ID']">
                  </app-customer-view-picture>
                </div>
                <div class="pull-left">
                  <div class="row clearfix">
                    <h1 class="lg-h1 sr-title white-color rm-mt">
                      {{ selectedCustomer['FirstName'] }} {{ selectedCustomer['Name'] }}
                    </h1>
                    <div class="anim clearfix full-width">
                      <p class="pull-left rm-mt">
                        <mat-icon>phone</mat-icon>
                        {{ selectedCustomer['mobiles.Number'] | customPhone }}
                      </p>
                    </div>
                    <div class="anim clearfix full-width">
                      <p class="pull-left rm-mt">
                        <mat-icon>email</mat-icon>
                        {{ selectedCustomer['emails.Email'] }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="action-button" *ngIf="isIntegrated != true">
                  <button class="actionMenuToggle" [matMenuTriggerFor]="btnPatMenu" mat-raised-button>
                    Actions
                    <mat-icon>more_vert</mat-icon>
                  </button>

                  <mat-menu #btnPatMenu="matMenu">
                    <button class="" (click)="openCustomerDetailsDialog(selectedCustomer['ID'])" mat-menu-item
                      matTooltip="View Profile: {{ selectedCustomer['FirstName'] }} {{ selectedCustomer['Name'] }} ">
                      <mat-icon>visibility</mat-icon>
                      View {{ "KEYWORD.patient" | translate | titlecase }}
                    </button>
                  </mat-menu>
                </div>
              </div>
            </div>

            <!-- selected customer contract list -->

            <div class="customerContainer full-width clearfix"
              *ngIf="!contracts || (contracts && contracts.length <= 0)">
              <app-empty-list-message [title]="'No contract found'"
                [message]="'There no active contract for the selected customer'"></app-empty-list-message>
            </div>

            <p class="rm-mt" *ngIf="contracts && contracts.length > 0">Please select a contract from the list below</p>

            <div class="customerContainer full-width clearfix" *ngIf="contracts && contracts.length > 0">
              <!-- contract block repeater -->
              <div class="customerRecord contract" *ngFor="let contract of contracts" @ngIfAnimation
                [ngClass]="{ activeExistingcustomer: selectedContract && selectedContract['ID'] == contract['ID'] }"
                (click)="selectContract(contract)">
                <div class="row clearfix anim">
                  <div class="row clearfix">
                    <div class="half-width">
                      <h3 class="sr-title rm-mb smb">{{ contract['ProductName'] }}</h3>
                      <p class="small rm-m">
                        <span class="labelName smt">
                          Applied:
                        </span>
                        <span class="value">
                          {{ contract['ApplicationDate'] | customDate }}
                        </span>
                      </p>
                    </div>
                    <div class="half-width text-right smt ">
                      <label class="labelName rm-mt smb">
                        Available balance:
                      </label>
                      <p class="value rm-m"
                        [ngStyle]="{ color: calculBalance(contract['Amount.Invoice'],contract['Settlements.NotYetDrawn']) > 0 ? '#1ca90c' : 'red' }">
                        <strong>{{ calculBalance(contract['Amount.Invoice'],contract['Settlements.NotYetDrawn']) | customCurrency }}</strong>
                      </p>
                    </div>
                  </div>

                  <span *ngIf="selectedContract && selectedContract['ID'] == contract['ID']" @ngIfGrow>
                    <hr class="animG" />
                    <div class="row clearfix animG">
                      <div class="half-width">
                        <p class="small rm-mt">
                          <span class="labelName">
                            Total amount financed:
                          </span>
                          <span class="value">
                            {{ contract['Amount.Invoice'] | customCurrency }}
                          </span>
                        </p>
                      </div>
                      <div class="half-width">
                        <div class="col-right">
                          <p class="small rm-mt">
                            <span class="labelName">
                              Paid/Approved for payment:
                            </span>
                            <span class="value">
                              {{ contract['Settlements.Drawn'] | customCurrency }}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row clearfix full-width animG">
                      <div class="half-width">
                        <p class="small rm-mt">
                          <span class="labelName">
                            Settlement deadline:
                          </span>
                          <span class="value"
                            *ngIf="contract['NextDrawDown.Before'] && contract['NextDrawDown.Before'] !== '0000-00-00'">
                            {{ contract['NextDrawDown.Before'] | customCurrency }}
                          </span>
                        </p>
                      </div>
                      <div class="half-width">
                        <div class="col-right rel">
                          <div class="text-right" *ngIf="isPromoterOrAdmin == true">
                            <button class="actionMenuToggle btn-small" [matMenuTriggerFor]="btnPatMenu" mat-button>
                              Actions
                              <mat-icon>more_vert</mat-icon>
                            </button>

                            <mat-menu #btnPatMenu="matMenu">
                              <button class="darkem" (click)="openContractDetailsDialog(selectedContract['ID'])"
                                mat-menu-item>
                                <mat-icon>visibility</mat-icon> View Contract
                              </button>
                            </mat-menu>
                          </div>
                          <!-- <p class="rm-mt">
                            <span class="labelName">
                              {{"Paid/Approved for payment:" | translate}}
                            </span>
                            <span class="value">
                              {{ contract['Settlements.Drawn'] | customCurrency}}
                            </span>
                          </p> -->
                        </div>
                      </div>
                      <hr *ngIf="contractOnHoldSettlements && contractOnHoldSettlements.length>0">
                      <div class="full-width smt"
                        *ngIf="contractOnHoldSettlements && contractOnHoldSettlements.length>0">

                        <div class="full-width flex">

                          <p class="small full-width rm-mt">
                            Settlements on hold:


                          </p>

                          <p class="small full-width  contract-settlement rm-mt"
                            (click)="$event.stopPropagation(); settlementListOverview(contract.ID,'PEND');"
                            *ngIf="(contractOnHoldSettlements| FilterArrayMultipleValue: ['Status.Code']:'PEND' | lengthOfArray)>0">
                            <strong class="pending  pull-right">Pending

                              <span class="bubble  ml  pull-right"
                                style="background: rgb(243, 156, 18) !important; color:#fff;">
                                {{contractOnHoldSettlements| FilterArrayMultipleValue: ['Status.Code']:'PEND' | lengthOfArray}}</span>
                            </strong>
                          </p>
                          <p class="small full-width contract-settlement rm-mt"
                            (click)="$event.stopPropagation(); settlementListOverview(contract.ID,'REQU');"
                            *ngIf="(contractOnHoldSettlements| FilterArrayMultipleValue: ['Status.Code']:'REQU' | lengthOfArray)>0">
                            <strong class="requested  pull-right">Requested


                              <span class="bubble  ml  pull-right" style="background: #1b8bdd !important; color:#fff;">
                                {{contractOnHoldSettlements| FilterArrayMultipleValue: ['Status.Code']:'REQU' | lengthOfArray}}</span>

                            </strong>
                          </p>
                        </div>

                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div class="drop-buttons">
              <button class="step-back pull-left position"
                *ngIf="isSkipLookup == false && customers && customers.length > 0" (click)="goBackCustomerList()"
                mat-mini-fab color="primary">
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button>

              <button class="inline btn-new-invite btn-large" *ngIf="isMaterialRequest==true"
                [disabled]="!selectedContract || !selectedContract['ID']" (click)="requestSettlementEvent()"
                mat-raised-button color="accent">
                Create Material Advance
                <mat-icon>chevron_right</mat-icon>
              </button>


              <button class="inline btn-new-invite btn-large" *ngIf="isMaterialRequest!=true"
                [disabled]="!selectedContract || !selectedContract['ID']" (click)="requestSettlementEvent()"
                mat-raised-button color="accent">
                Create New Settlement
                <mat-icon>chevron_right</mat-icon>
              </button>
            </div>
          </div>
        </mat-card>
      </div>
      <div class="container" *ngIf="hideLookup == true">
        <div class="row clearfix add-mt text-center full-width">
          <!-- css loader -->
          <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <!-- /css loader -->
        </div>
      </div>
    </aw-wizard-step>
    <aw-wizard-step [navigationSymbol]="{ symbol: counter + 2 }">
      <!-- Step 1  -->

      <div class="container" id="step-1" *ngIf="selectedContract">
        <!-- <div class="row clearfix text-center" *ngIf="isMaterialRequest==true">
          <h2 class="sr-title inline-block">Create Material Advance</h2>
        </div>
        <div class="row clearfix text-center" *ngIf="isMaterialRequest!=true">
          <h2 class="sr-title inline-block">Create New Settlement</h2>
        </div> -->
        <div class="row clearfix">
          <div class="thrd-width">
            <div class="col-left">
              <mat-card class="display">
                <div class="row clearfix rel">
                  <div class="total anim">
                    <span class="totLab">Available</span>
                    <span class="balance" [endVal]="balance" countUp></span>
                    <!-- <span class="totSub">Total</span> -->
                  </div>
                  <app-chart-input class="row" [colorScheme]="charColors" [data]="settlementChart" [chartType]="'pie'"
                    [title]="''" [autoScale]="true" [showLegend]="false" [showLabel]="false"></app-chart-input>
                </div>
                <mat-divider></mat-divider>
                <mat-list>
                  <h4 class="sr-title rm-mt">Finance details</h4>
                  <mat-list-item class="totFin">
                    Total amount financed: <strong>{{ selectedContract['Amount.Invoice'] | customCurrency }}</strong>
                  </mat-list-item>
                  <mat-list-item class="less" *ngIf="isMaterialRequest!=true">
                    Paid to {{"KEYWORD.practice" | translate}}:
                    <strong matTooltip="Less">
                      <mat-icon>remove</mat-icon>
                      {{ toNumber(selectedContract['Amount.Invoice']) - toNumber(balance) | customCurrency }}
                    </strong>
                  </mat-list-item>

                  <mat-list-item class="avail" *ngIf="isMaterialRequest!=true">
                    Available balance: <strong [ngStyle]="{ color: '#1ca90c' }">{{ balance | customCurrency }}</strong>
                  </mat-list-item>
                  <mat-list-item class="avail" *ngIf="isMaterialRequest==true">
                    Available to request: <strong
                      [ngStyle]="{ color: '#1ca90c' }">{{ balance | customCurrency }}</strong>
                  </mat-list-item>
                  <mat-list-item class="less" *ngIf="isMaterialRequest!=true">
                    Requested/Pending:
                    <strong *ngIf="selectedContract && selectedContract.stats" matTooltip="Less">
                      <mat-icon>remove</mat-icon>

                      <span [class.alertPendingPayment]="
                          toNumber(
                            selectedContract.stats
                              | FilterArrayMultipleValue: 'ID':settlementPendingStatus
                              | objectPropertyValue: 'Sum(Settlement.Amount)'
                              | sum
                          ) > toNumber(selectedContract['Settlements.NotYetDrawn'])
                        ">
                        {{
                        selectedContract.stats
                          | FilterArrayMultipleValue: 'ID':settlementPendingStatus
                          | objectPropertyValue: 'Sum(Settlement.Amount)'
                          | sum
                          | customCurrency
                        }}
                      </span>
                    </strong>
                    <strong *ngIf="!selectedContract || !selectedContract.stats" matTooltip="Less">
                      <mat-icon>remove</mat-icon>

                      <span>
                        {{ 0 | customCurrency }}
                      </span>
                    </strong>
                  </mat-list-item>
                </mat-list>
                <div class="row clearfix" *ngIf="settlementAmount" @onChangeAnimation>
                  <hr class="animate" />
                  <mat-list *ngIf="isMaterialRequest!=true">
                    <h4 class="sr-title rm-mt">Request details</h4>
                    <mat-list-item class="animate totFin">
                      Settlement Requested: <strong>{{ settlementAmount | customCurrency }}</strong>
                    </mat-list-item>

                    <mat-list-item class="less" *ngIf="healthFundAmount" @onChangeAnimation>
                      <div class="animate justify">
                        Health Fund:
                        <strong matTooltip="Less">
                          <mat-icon>remove</mat-icon>
                          {{ healthFundAmount | customCurrency }}
                        </strong>
                      </div>
                    </mat-list-item>
                    <mat-list-item class="less" *ngIf="getTotalBeneficiaryAmount() > 0" @onChangeAnimation>
                      <div class="animate justify">
                        Third Party:
                        <strong matTooltip="Less">
                          <mat-icon>remove</mat-icon>
                          {{ getTotalBeneficiaryAmount() | customCurrency }}
                        </strong>
                      </div>
                    </mat-list-item>
                    <mat-list-item class="avail" *ngIf="settlementAmount" @onChangeAnimation>
                      <div class="animate justify">
                        Paying to {{"KEYWORD.practice" | translate}}:
                        <strong [ngStyle]="{ color: '#1ca90c' }">{{
                          toNumber(settlementAmount) -
                          toNumber(healthFundAmount) -
                          toNumber(getTotalBeneficiaryAmount()) | customCurrency
                          }}</strong>
                      </div>
                    </mat-list-item>
                  </mat-list>

                  <mat-list *ngIf="isMaterialRequest==true">
                    <h4 class="sr-title rm-mt">Request details</h4>

                    <mat-list-item class="avail" *ngIf="settlementAmount" @onChangeAnimation>
                      <div class="animate justify">
                        Material Advance Amount:
                        <strong [ngStyle]="{ color: '#1ca90c' }">{{
                          toNumber(settlementAmount) -
                          toNumber(healthFundAmount) -
                          toNumber(getTotalBeneficiaryAmount()) | customCurrency
                          }}</strong>
                      </div>
                    </mat-list-item>
                  </mat-list>
                </div>
              </mat-card>
            </div>
          </div>
          <div class="two-thrd-width" style="margin-top: 7px;">
            <mat-card class="blue-heading">
              <div class="card-header primary-gradient-img clearfix inModal">
                <div class="row clearfix">
                  <!-- merchant calculated name -->
                  <div class="row titleArea clearfix" style="    max-width: 90% !important;">
                    <app-customer-view-picture class="customerContainer-style"
                      [patientID]="selectedContract['Customer_key']">
                    </app-customer-view-picture>



                    <h2 class="summary-header rm-m" *ngIf="isMaterialRequest==true">
                      Create Material advance for
                      <strong><span class="patientCalculatedName" *ngIf="contractID">{{
                        selectedContract['Customer.FullName']
                        }}</span></strong>.
                      <span class="subLabel">The next steps will guide you through creating your request</span>
                    </h2>

                    <h2 class="summary-header rm-m" *ngIf="isMaterialRequest!=true">
                      Create Settlement request for
                      <strong><span class="patientCalculatedName" *ngIf="contractID">{{
                        selectedContract['Customer.FullName']
                        }}</span></strong>.
                      <span class="subLabel">The next steps will guide you through creating your request</span>
                    </h2>




                  </div>


                </div>
                <div class="action-button" *ngIf="isIntegrated != true">

                  <app-invitation-actions *ngIf="selectedContract" [customerID]="selectedContract['Customer_key']"
                    [displayNewSettlement]="false" class="  contract-action-invitation ml   pull-right "
                    [buttonType]="'buttonAccent'" [invitationID]="selectedContract['Invitation_key']" [items]="items"
                    [contractID]="selectedContract['ID']" [merchantID]="selectedContract['Merchant_key']"
                    [dentistID]="selectedContract['DentistContact_key']" [campaignID]="selectedContract['Campaign_Key']"
                    [contract]="selectedContract" [productID]="selectedContract['Product_key']">
                  </app-invitation-actions>

                  <button class="text-center   ml   pull-right  " *ngIf="  contractID &&
                      toNumber(
                        selectedContract.stats
                          | FilterArrayMultipleValue: 'ID':settlementPendingStatus
                          | objectPropertyValue: 'Count(*)'
                          | sum
                      ) > 0
                    " color="primary" (click)="settlementListOverview(selectedContract.ID,null,'REQU,PEND')"
                    mat-raised-button color="primary">
                    Requested/Pending
                    <span class="count-settlement">{{
                          toNumber(
                            selectedContract.stats
                              | FilterArrayMultipleValue: 'ID':settlementPendingStatus
                              | objectPropertyValue: 'Count(*)'
                              | sum
                          )
                          }}</span>
                  </button>


                  <!--
                  <button class="actionMenuToggle" [matMenuTriggerFor]="btnPatMenu" mat-raised-button>
                    Actions <mat-icon>more_vert</mat-icon>
                  </button> -->

                  <mat-menu #btnPatMenu="matMenu">
                    <button *ngIf="customerID" (click)="openCustomerDetailsDialog(customerID)" mat-menu-item
                      matTooltip="View customer" color="primary">
                      <mat-icon>person</mat-icon> Customer
                    </button>
                    <mat-divider></mat-divider>
                    <button *ngIf="contractID" (click)="openContractDetailsDialog(contractID)" mat-menu-item
                      matTooltip="View contract" color="primary">
                      <mat-icon>visibility</mat-icon> Contract
                    </button>
                    <mat-divider></mat-divider>
                    <button *ngIf="
                        contractID &&
                        toNumber(
                          selectedContract.stats
                            | FilterArrayMultipleValue: 'ID':settlementPendingStatus
                            | objectPropertyValue: 'Count(*)'
                            | sum
                        ) > 0
                      " (click)="settlementList(contractID, 'pending')" matTooltip="Requested/Pending settlement list"
                      mat-menu-item color="accent">
                      <mat-icon>list_alt</mat-icon>
                      Requested/Pending
                      <span class="count-settlement" color="accent">{{
                        toNumber(
                          selectedContract.stats
                            | FilterArrayMultipleValue: 'ID':settlementPendingStatus
                            | objectPropertyValue: 'Count(*)'
                            | sum
                        )
                        }}</span>
                    </button>
                    <mat-divider *ngIf="
                        contractID &&
                        toNumber(
                          selectedContract.stats
                            | FilterArrayMultipleValue: 'ID':settlementPendingStatus
                            | objectPropertyValue: 'Count(*)'
                            | sum
                        ) == 0 &&
                        toNumber(selectedContract.stats | objectPropertyValue: 'Count(*)' | sum) > 0
                      ">
                    </mat-divider>
                    <button *ngIf="
                        contractID &&
                        toNumber(
                          selectedContract.stats
                            | FilterArrayMultipleValue: 'ID':settlementPendingStatus
                            | objectPropertyValue: 'Count(*)'
                            | sum
                        ) == 0 &&
                        toNumber(selectedContract.stats | objectPropertyValue: 'Count(*)' | sum) > 0
                      " (click)="settlementList(contractID, 'all')" matTooltip="Settlementlist" mat-menu-item
                      color="accent">
                      <mat-icon>list_alt</mat-icon>
                      Settlement(s)
                      <span class="count-settlement" color="accent">{{
                        toNumber(selectedContract.stats | objectPropertyValue: 'Count(*)' | sum)
                        }}</span>
                    </button>
                  </mat-menu>
                </div>
              </div>
            </mat-card>

            <!-- start accordion -->
            <form #checkForm="ngForm">
              <mat-accordion class="card">
                <!-- ACCORDION STEP ONE - DEFINE AMOUNT -->
                <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle="true">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <h3 class="sm-h3 sr-title" *ngIf="isMaterialRequest==true">
                        <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
                        Define material advance value
                      </h3>

                      <h3 class="sm-h3 sr-title" *ngIf="isMaterialRequest!=true">
                        <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
                        Define settlement value
                      </h3>
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <div class="row clearfix contentPanel">
                    <p class="larger rm-mt" *ngIf="isMaterialRequest==true">


                      <span *ngIf="context!='breeze'">
                        You have the right to request an advance of up to {{balance | customCurrency}} to cover
                        third-party lab fees for this contract before treating the {{'patient' | translate}}.


                      </span>


                      <span *ngIf="context=='breeze'">
                        You have the right to request an advance of up to {{balance | customCurrency}} to cover
                        third-party lab fees for this contract .


                      </span>


                    </p>
                    <p class="larger rm-mt" *ngIf="isMaterialRequest!=true">
                      To get started, enter the value of {{ selectedContract['Customer.FirstName'] }}
                      's {{ "KEYWORD.treatment" | translate }} and
                      set the date of your request.
                    </p>
                    <div class="row clearfix icon">
                      <mat-icon class="fas fa-hand-point-right sr-title pull-left bounceHoriz"></mat-icon>
                      <!-- Settlement Value -->
                      <mat-form-field class="full-width anim large-input pull-left" appearance="outline">
                        <mat-label *ngIf="isMaterialRequest!=true">Settlement Amount</mat-label>
                        <mat-label *ngIf="isMaterialRequest==true">Material Advance Amount</mat-label>
                        <span class="prefix-dollar inputPrefix" matPrefix>$ &nbsp;</span>
                        <input [(ngModel)]="settlementAmount" [formControl]="settlementAmountInput" type="number"
                          matInput name="settlementAmount" placeholder="Please enter amount between 0 and {{ balance }}"
                          required />
                        <mat-error>Please enter amount between 0 and {{ balance }}</mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <mat-action-row>
                    <div class="full-width">
                      <button class="btn-large pull-left position"
                        *ngIf="isSkipLookup == false && contracts && contracts.length > 0 && isIntegrated != true"
                        (click)="goBackContractList()" mat-raised-button color="primary">
                        <mat-icon>keyboard_arrow_left</mat-icon> Back
                      </button>

                      <button class="btn-large pull-right" [disabled]="!settlementAmount || settlementAmount > balance"
                        (click)="nextStep()" mat-raised-button color="accent">
                        Next
                        <mat-icon>chevron_right</mat-icon>
                      </button>
                    </div>
                  </mat-action-row>
                </mat-expansion-panel>

                <!-- ACCORDION STEP TWO - ADD HICAPS PAYMENT -->
                <mat-expansion-panel
                  *ngIf="settings && settings['context'] &&  settings['context']!='p4p' && settings['context']!='breeze' && isModuleSettlementsAdvancedActive == true && isMaterialRequest!=true"
                  [expanded]="step === 1 && settings && settings['context'] &&  settings['context']!='p4p' && settings['context']!='breeze'"
                  (opened)="setStep(1)" hideToggle="true" [disabled]="!settlementAmount">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="full-title">
                      <h3 class="sm-h3 sr-title rm-p title-h3">
                        <mat-icon class="fas fa-hand-holding-heart"></mat-icon> Health fund claim
                      </h3>
                      <img class="hicaps-logo pull-right mt"
                        src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/brandLogos/logo-hicaps-new.png" />
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <div class="row clearfix contentPanel">


                    <div class="healthFundArea" [class.disabled]="!settlementAmount">

                      <div class="row clearfix">
                        <p class="larger rm-mt">
                          Acknowledge a claim you have made directly from the
                          {{ "KEYWORD.patient" | translate }}'s healthcare provider.
                        </p>
                        <p>
                          You should do this after you have successfully received an acknowledgement from your HICAPS
                          terminal.
                        </p>

                        <!-- add new claim button, vanish when set -->
                        <button class="btn-large mb" *ngIf="healthFundIsSet != true" [disabled]="
                            !contractID ||
                            !checkForm.valid ||
                            !settlementAmount ||
                            settlementAmount <= 0 ||
                            settlementAmount > balance ||
                            settlementAmount - getTotalBeneficiaryAmount() <= 0
                          " (click)="openHealthFundReq()" mat-raised-button color="primary">
                          <mat-icon class="fas fa-plus-circle"></mat-icon> Add a HICAPS claim
                        </button>
                      </div>
                      <!-- display payment area -->
                      <div class="row clearfix" *ngIf="healthFundIsSet == true" @onChangeAnimation>
                        <div class="row icon animate clearfix">
                          <div class="pull-left">
                            <div>
                              <p class="animate rm-m larger">
                                <strong>{{ healthFundAmount | customCurrency }}</strong>
                              </p>
                              <p class="animate rm-m small">
                                Paid to <strong>{{ healthFundName }}</strong>
                              </p>
                            </div>
                          </div>
                          <div class="pull-right">
                            <button class="btn-small" (click)="editHealthFundReqSubStep()" mat-button color="warn">
                              <mat-icon class="fas fa-pen"></mat-icon> Edit Claim
                            </button>
                            <!-- reset to no claim -->
                            <button class="btn-small" (click)="
                                healthFundIsSet = false;
                                healthFundAmount = '';
                                healthFundReference = '';
                                healthFundName = '';
                                isFundApproved = false
                              " mat-button color="accent">
                              <mat-icon class="fas fa-trash-alt"></mat-icon> Remove Claim
                            </button>
                          </div>
                        </div>

                        <div class="row clearfix animate">
                          <p class="small">
                            <strong>Disclaimer:</strong> To proceed with requesting a claim from a health fund, you must
                            agree to the <a>terms &amp; conditions</a> and declare that you have read and understood
                            these by checking the box below.
                          </p>

                          <div class="clearfix row icon rm-bb">
                            <h3 class="sr-title rm-m">Click button below to accept</h3>
                            <div class="clearfix flex row">
                              <!-- <mat-slide-toggle class="mt mb mr" [(ngModel)]="selectedFund.isAccepted"></mat-slide-toggle> -->
                              <mat-slide-toggle class="mt mb mr" [(ngModel)]="isFundApproved" name="healthFundCheck">
                              </mat-slide-toggle>

                              <p>
                                I/We confirm that we have claimed the amount stated above from the health fund and we
                                want to apply a credit to the {{ "KEYWORD.patient" | translate }}'s account. I/We
                                understand that once this is approved by {{ "BRANDING.Brand Name" | translate |
                                titlecase }} this is
                                non-reversible.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <mat-action-row>
                    <button class="btn-large" (click)="prevStep()" mat-raised-button color="primary">
                      <mat-icon>chevron_left</mat-icon> Previous
                    </button>
                    <button class="btn-large" [disabled]="healthFundIsSet == true && !isFundApproved"
                      (click)="nextStep()" mat-raised-button color="accent">
                      Next
                      <mat-icon>chevron_right</mat-icon>
                    </button>
                  </mat-action-row>
                </mat-expansion-panel>
                <!-- ACCORDION STEP THREE - 3RD PARTY PAYMENT -->
                <mat-expansion-panel
                  *ngIf="settings && settings['context'] &&  settings['context']!='p4p' && settings['context']!='breeze' && isModuleSettlementsAdvancedActive == true && isMaterialRequest!=true"
                  [expanded]="step === 2 && settings && settings['context'] &&  settings['context']!='p4p' && settings['context']!='breeze'"
                  (opened)="setStep(2)" hideToggle="true" [disabled]="!settlementAmount">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <h3 class="sm-h3 sr-title rm-p">
                        <mat-icon class="fas fa-hand-holding-usd"></mat-icon> Payment to third parties
                      </h3>
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <div class="row clearfix contentPanel">

                    <div class="thirdPartyArea" [class.disabled]="!settlementAmount">
                      <div class="row clearfix">
                        <p class="larger">
                          Get {{ "BRANDING.Brand Name" | translate | titlecase }} to settle direct to an approved third
                          party
                          supplier.
                        </p>
                        <!-- add payment button, removes when payment added -->
                        <button class="btn-large mb" *ngIf="thirdPartyIsRequired != true" [disabled]="
                            !contractID ||
                            !checkForm.valid ||
                            !settlementAmount ||
                            settlementAmount <= 0 ||
                            settlementAmount > balance ||
                            settlementAmount - healthFundAmount <= 0
                          " (click)="openThirdPartyReq()" mat-raised-button color="primary">
                          <mat-icon class="fas fa-comment-dollar"></mat-icon>&nbsp; Add a third party payment
                        </button>
                      </div>

                      <!-- payment rows -->
                      <div class="row clearfix"
                        *ngIf="beneficiaries && beneficiaries.length > 0 && thirdPartyIsRequired">
                        <div *ngFor="let b of beneficiaries; let i = index">
                          <div class="icon row animate clearfix rm-bb"
                            *ngIf="b.beneficiary && b.bankAccount && b.beneficiaryAmount >= 0 && b.active == true">
                            <div class="row clearfix flex">
                              <!-- display 3rd party details -->

                              <div class="full-width pull-left">
                                <app-ep-document-view-image class="headNavViewBeneficiary mr pull-left Beneficiary"
                                  [defaultPicture]="
                                    'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/3rd_party_placeholder.png)'
                                  " [link]="b.beneficiary['profileLink']">
                                </app-ep-document-view-image>

                                <p class="animate rm-m">Amount to pay:</p>
                                <p class="animate rm-m larger">
                                  <strong>{{ b.beneficiaryAmount | customCurrency }}</strong>
                                </p>
                                <p class="animate rm-m small">
                                  Paid to <strong>{{ b.beneficiary.Label }}</strong>
                                </p>
                              </div>
                              <!-- buttons for merchant -->
                              <div class="pull-right" *ngIf="isPromoterOrAdmin == false">
                                <button class="btn-small" (click)="editThirdPartyReq(i)" mat-button color="warn">
                                  <mat-icon class="fas fa-pen"></mat-icon> Edit Payment
                                </button>
                                <button class="btn-small" (click)="removeThirdPartyReq(i)" mat-button color="accent">
                                  <mat-icon class="fas fa-trash-alt"></mat-icon> Remove Payment
                                </button>
                              </div>
                              <!-- action menu for promoter -->
                              <div class="pull-right" *ngIf="isPromoterOrAdmin == true">
                                <button [matMenuTriggerFor]="menuBeneficiary" mat-icon-button>
                                  <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menuBeneficiary="matMenu">
                                  <button (click)="viewBeneficiary(b)" mat-menu-item>
                                    <mat-icon>visibility</mat-icon> View Beneficiary
                                  </button>
                                  <mat-divider></mat-divider>
                                  <button (click)="editThirdPartyReq(i)" mat-menu-item>
                                    <mat-icon>create</mat-icon> Edit Payment
                                  </button>
                                  <mat-divider></mat-divider>
                                  <button (click)="removeThirdPartyReq(i)" mat-menu-item>
                                    <mat-icon>delete</mat-icon> Remove Payment
                                  </button>
                                </mat-menu>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- disclaimer area -->
                        <div class="row full-width clearfix icon rm-bb">
                          <div class="row clearfix mb"
                            *ngIf="beneficiaries && beneficiaries.length > 0 && thirdPartyIsRequired">
                            <button class="pull-right" (click)="openThirdPartyReqSubStep()" mat-raised-button
                              color="primary" matTooltip="Add More Third Parties">
                              <mat-icon class="fas fa-comment-dollar"></mat-icon>&nbsp; Add another payment
                            </button>
                          </div>

                          <p class="small">
                            <strong>Disclaimer:</strong> Any payments made to external providers are non-refundable. To
                            proceed with requesting a payment to a third party entitiy, you must agree to the
                            <a>terms &amp; conditions</a> and declare that you have read and understood these by
                            checking the box below.
                          </p>
                          <!-- checkbox toggle to confirm disclaimer -->
                          <div class="row clearfix icon rm-bb">
                            <h3 class="sr-title rm-m">Click button below to accept</h3>
                            <div class="row clearfix flex">
                              <mat-slide-toggle class="mt mb mr" [(ngModel)]="isBeneficiaryApproved"
                                name="confirmSlider"></mat-slide-toggle>
                              <p>
                                I/We confirm that we instruct {{ "BRANDING.Brand Name" | translate | titlecase }} to
                                settle amount
                                stated above. I/We understand that once this is approved by
                                {{ "BRANDING.Brand Name" | translate | titlecase }} this transaction is non-reversible.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <mat-action-row>
                    <button class="btn-large" (click)="prevStep()" mat-raised-button color="primary">
                      <mat-icon>chevron_left</mat-icon> Previous
                    </button>
                    <button class="btn-large" [disabled]="beneficiaries.length > 0 && !isBeneficiaryApproved"
                      (click)="nextStep()" mat-raised-button color="accent">
                      Next
                      <mat-icon>chevron_right</mat-icon>
                    </button>
                  </mat-action-row>
                </mat-expansion-panel>

                <!-- ACCORDION STEP FOUR - CONFIRMATION SELECT -->
                <mat-expansion-panel
                  [expanded]="step === 3 || (isModuleSettlementsAdvancedActive != true && step === 1 )  || ( step === 1 &&  isMaterialRequest==true)|| ( step === 1 && settings && settings['context'] &&  (settings['context']=='p4p' || settings['context']=='breeze'))"
                  (opened)="setStep(3)" hideToggle="true" [disabled]="!settlementAmount">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <h3 class="sm-h3 sr-title rm-p">
                        <mat-icon class="fas fa-user-check"></mat-icon> Confirm with your patient
                      </h3>
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <!-- disclaimer text -->
                  <div class="row clearfix contentPanel">
                    <div class="row clearfix">
                      <div class="row clearfix">
                        <img class="leftImg"
                          src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/character/pen-and-phone-dentist.png" />
                        <!-- <hr class="rm-mt mb"> -->
                        <div class="row clearfix flex align-top">
                          <!-- <button [disabled]=" ! settlementAmount  ||  settlementAmount<=0 ||  settlementAmount>balance"
                            mat-button class="help-btn" (click)="previewMessage()"
                            matTooltip="What will my customer see?">
                            <mat-icon>help_outline</mat-icon>
                          </button> -->
                          <p class="larger rm-m">
                            To authorise this request, your {{ "KEYWORD.patient" | translate }} can choose to receive a
                            SMS code to their mobile and quote it back to you to, or you can print a physical form to
                            sign.
                          </p>
                        </div>
                        <div class="row clearfix">
                          <button class="mt pull-right" *ngIf="isIntegrated != true" [disabled]="
                              !contractID ||
                              !checkForm.valid ||
                              !settlementAmount ||
                              settlementAmount <= 0 ||
                              settlementAmount > balance
                            " (click)="saveDraft()" mat-raised-button color="accent">
                            <mat-icon class="fas fa-save"></mat-icon>Save as Draft
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <mat-action-row>
                    <button class="" (click)="prevStep()" mat-raised-button color="primary">
                      <mat-icon>chevron_left</mat-icon> Previous
                    </button>
                    <!-- print a form -->
                    <button class="pull-right increase-margin" [disabled]="
                        !contractID ||
                        !checkForm.valid ||
                        !settlementAmount ||
                        settlementAmount <= 0 ||
                        settlementAmount > balance
                      " (click)="proceedWithForm()" mat-raised-button color="accent">
                      <mat-icon class="fas fa-print"></mat-icon>&nbsp; Get Form <mat-icon>chevron_right</mat-icon>
                    </button>
                    <!-- send a sms -->
                    <button class="pull-right increase-margin" [disabled]="
                        !contractID ||
                        !checkForm.valid ||
                        !settlementAmount ||
                        settlementAmount <= 0 ||
                        settlementAmount > balance
                      " (click)="proceedWithCode()" mat-raised-button color="accent">
                      <mat-icon class="fas fa-mobile-alt"></mat-icon>&nbsp; Send Code <mat-icon>chevron_right</mat-icon>
                    </button>
                  </mat-action-row>
                </mat-expansion-panel>
              </mat-accordion>
            </form>
          </div>
        </div>
      </div>

      <!--  / Step 1  -->
    </aw-wizard-step>
    <aw-wizard-step [navigationSymbol]="{ symbol: counter + 3 }" awOptionalStep>
      <!--  Step 2 with sms -->
      <div class="container animated fadeInDown clearfix" id="step-2">
        <form #smsForm="ngForm">
          <div class="row clearfix text-center">
            <h2 class="sr-title inline-block rm-mt">Request authorisation</h2>
          </div>
          <div class="row clearfix" *ngIf="option == 'securityCode'">
            <div class="column">
              <mat-card>
                <div class="cardheader primary-gradient-img">
                  <p class="lead rm-mt"><strong>1.</strong> Authorise settlement request</p>

                  <p>
                    An Authorisation code has been sent to your customer via
                    <Strong *ngIf="emailSent == false && smsSent == true">SMS</Strong>
                    <Strong *ngIf="emailSent == true && smsSent == false">and Email</Strong>

                    <Strong *ngIf="emailSent == true && smsSent == true"> SMS and Email</Strong>
                    , please get them to recite the code and enter it here.
                  </p>

                  <p>The code will be valid for {{ settings['securityCodeLifeTime'] || 60 }} min(s)</p>
                </div>
                <div class="clearfix">
                  <div class="clearfix">
                    <mat-form-field class="full-width large-input rm-mb" appearance="outline">
                      <mat-label>Enter authorisation code here</mat-label>
                      <input class="mat-block" [(ngModel)]="securityCode" matInput
                        placeholder="Enter authorisation code here" name="smsCode" required />
                    </mat-form-field>
                  </div>
                  <div class="full-width text-center">
                    <p class="small small">
                      Didn't recieve your code? Wait <strong>{{ timeLeft }}</strong> seconds and try sending again.
                    </p>
                    <button class="btn-small clearfix" [disabled]="timeLeft > 0" (click)="sendCodeAgain()"
                      mat-raised-button color="accent">
                      Send Authorisation Code Again
                    </button>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>

          <div class="row clearfix" *ngIf="option == 'document'">
            <div class="column">
              <mat-card class="clearfix">
                <div class="cardheader primary-gradient-img">
                  <p class="lead rm-mt"><strong>1.</strong> Authorise settlement request</p>

                  <p>
                    If you have given your customer an Authorisation form to sign, please scan it in and then upload it
                    using the uploader below.
                  </p>
                </div>
                <div class="clearfix full-width">
                  <!-- <app-file-uploader></app-file-uploader> -->
                  <!--

                    <app-blob-view *ngIf="authorisationFormContent" class="full-width" [content]="authorisationFormContent"
                      [label]="'Authorisation Form for '+selectedContract['Customer.FullName']" [extension]="'pdf'"></app-blob-view> -->

                  <label class="mb clearfix">Please upload the signed {{ "KEYWORD.patient" | translate }} authorisation
                    form in the field below to
                    continue.</label>

                  <app-file-uploader class="text-center clearfix" class="text-center"
                    [label]="'Authorisation Form for ' + selectedContract['Customer.FullName']"
                    [fileName]="'Authorisation Form for ' + selectedContract['Customer.FullName']"
                    [uploadDirectly]="true" [isDragable]="true" [limit]="1" (onCompleteAll)="onCompleteAllForm($event)">
                  </app-file-uploader>

                  <app-file-list class="full-width file-list-container-settlement" *ngIf="settlementfileID"
                    [showInfo]="false"
                    [defaultLabel]="'Authorisation Form for ' + selectedContract['Customer.FullName']"
                    [files]="[settlementfileID]" [canPrivateDocument]="false" [documentTypes]="[]" [canGallery]="false"
                    [hasExtraInfo]="false" [invitationID]="''" [hasResponsiveWidth]="true" [canEdit]="true"
                    [canAction]="false" (remove)="removeAuthorisationForm($event)">
                  </app-file-list>
                </div>
              </mat-card>
            </div>
          </div>

          <div class="row clearfix">
            <div class="column">
              <div class="full-width step-button-row text-center">
                <button class="step-back" (click)="goBackCreateSettlement()" mat-mini-fab color="primary">
                  <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <div class="pull-right">
                  <button class="step-next" class="btn-large" *ngIf="option == 'securityCode'"
                    [disabled]="!smsForm.valid" (click)="validateSettlementCode()" mat-raised-button color="accent">
                    Authorise Settlement
                    <mat-icon>chevron_right</mat-icon>
                  </button>

                  <button class="step-next" class="btn-large" *ngIf="option == 'document'"
                    [disabled]="!contractID || !settlementfileID" (click)="createSettlement()" mat-raised-button
                    color="accent">
                    Authorise Settlement
                    <mat-icon>chevron_right</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </aw-wizard-step>
    <aw-wizard-step [navigationSymbol]="{ symbol: counter + 4 }" awOptionalStep>
      <!--  Step 3  -->
      <div class="container animated fadeInDown" id="step-3">
        <form #invoiceForm="ngForm">
          <div class="row clearfix text-center">
            <h2 class="sr-title inline-block rm-mt">Invoice details &amp; upload</h2>
          </div>
          <div class="row clearfix">
            <div class="column">
              <mat-card class="clearfix" *ngIf="settlementObject && settlementObject['ID']">
                <div class="cardheader primary-gradient-img" *ngIf="isPreTreatment == true">
                  <p class="lead rm-mt"><strong>Enter Settlement Details </strong></p>
                  <p>Select the type of service to confirm.</p>
                  <p>
                    This will change the settlement status from <strong>Pending</strong> to <strong>Requested</strong>
                  </p>
                </div>
                <div class="cardheader primary-gradient-img" *ngIf="isPreTreatment != true">
                  <p class="lead rm-mt"><strong>Enter Settlement Details </strong></p>
                  <p>
                    Enter the invoice reference number, select the type of service, date, and upload a copy of the
                    invoice document to confirm.
                  </p>
                  <p>
                    This will change the settlement status from <strong>Pending</strong> to <strong>Requested</strong>
                  </p>
                </div>
                <div class="clearfix full-width">
                  <div class="row clearfix">
                    <mat-slide-toggle class="mb full-width" [color]="warn" [checked]="isPreTreatment"
                      [labelPosition]="before" (change)="isPreTreatmentToggle($event.checked)">
                      Is this settlement to cover the purchase of materials prior to {{"KEYWORD.treatment" | translate}}
                      ?
                    </mat-slide-toggle>
                  </div>
                  <!-- Invoice Number -->
                  <mat-form-field class="full-width clearfix" appearance="outline">
                    <mat-label>Invoice Reference No</mat-label>
                    <input class="mat-block" [(ngModel)]="invoiceReference" [readonly]="isPreTreatment"
                      name="invoiceDReferenceon" matInput placeholder="Invoice Reference No" required />
                  </mat-form-field>

                  <mat-form-field class="full-width adjustSelect"
                    *ngIf="settings && settings['context'] &&  settings['context']!='p4p' && settings['context']!='breeze'"
                    appearance="outline">
                    <mat-label>Type of service</mat-label>
                    <mat-select class="full-width adjustSelect" [(ngModel)]="serviceType" placeholder="Type of service"
                      name="serviceType">
                      <mat-option *ngFor="let s of serviceTypes | objectPropertyValue: 'Label'" [value]="s">
                        {{ s }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <!-- Invoice Date -->
                  <!-- <label>Invoice date</label> -->
                  <app-date-input class="thin full-width clearfix" *ngIf="isPreTreatment == false"
                    [label]="'Invoice date:'" [minDate]="'today'" (selectedDate)="selectInvoiceDate($event)"
                    name="toDate"></app-date-input>
                  <!-- <hr class="mb mt"> -->

                  <div class="clearfix" *ngIf="isPreTreatment == false">
                    <label class="mb">Invoice upload</label>

                    <app-file-uploader class="text-center clearfix" class="text-center"
                      [label]="'Invoice for ' + selectedContract['Customer.FullName']"
                      [fileName]="'Invoice for ' + selectedContract['Customer.FullName']" [uploadDirectly]="true"
                      [isDragable]="true" [limit]="1" (onCompleteAll)="onCompleteAllInvoice($event)">
                    </app-file-uploader>

                    <app-file-list class="full-width file-list-container-settlement" *ngIf="invoiceFileID"
                      [showInfo]="false" [defaultLabel]="'Invoice for ' + selectedContract['Customer.FullName']"
                      [files]="[invoiceFileID]" [canPrivateDocument]="false" [documentTypes]="[]" [canGallery]="false"
                      [hasExtraInfo]="false" [invitationID]="''" [hasResponsiveWidth]="true" [canEdit]="true"
                      [canAction]="false" (remove)="removeInvoiceFile($event)">
                    </app-file-list>
                  </div>

                  <div class="clearfix" *ngIf="isPreTreatment == true">
                    <mat-form-field class="full-width clearfix" appearance="outline">
                      <mat-label>Description</mat-label>
                      <textarea [(ngModel)]="invoiceDetails" matInput name="materialDetails"
                        placeholder="Briefly describe the materials been ordered"></textarea>
                    </mat-form-field>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
          <div class="row clearfix">
            <div class="column">
              <div class="full-width step-button-row text-center">
                <!-- <button class="step-back" mat-mini-fab color="primary" awPreviousStep>
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button> -->
                <div class="pull-left">
                  <button class="step-next" class="btn-large" *ngIf="isPreTreatment == false && isIntegrated != true"
                    (click)="updateLater()" mat-raised-button color="accent">
                    Enter Invoice later
                  </button>
                </div>

                <div class="pull-right">
                  <button class="step-next" class="btn-large"
                    [disabled]="!invoiceForm.valid || (!invoiceFileID && isPreTreatment == false)"
                    (click)="createInvoice()" mat-raised-button color="accent">
                    Confirm Settlement
                    <mat-icon>chevron_right</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!--  / Step 3  -->
    </aw-wizard-step>
    <!-- The completion step -->
    <aw-wizard-completion-step [navigationSymbol]="{ symbol: counter + 5 }">
      <!--  Step 4 - finished -->
      <div class="container animated fadeInDown" id="step-4" *ngIf="settlementObject">
        <div class="row clearfix">
          <div class="full-width text-center">
            <mat-card>
              <h1 class="sr-title sm-h1"
                *ngIf="settlementObject && settlementObject['Status.Code'] == 'REQU' && settlementObject['Is_Materials_Request']!='1'">
                Settlement request is being processed
              </h1>

              <h1 class="sr-title sm-h1"
                *ngIf="settlementObject && settlementObject['Status.Code'] == 'PEND' && settlementObject['Is_Materials_Request']!='1'">
                Draft Settlement created
              </h1>


              <h1 class="sr-title sm-h1"
                *ngIf="settlementObject && settlementObject['Status.Code'] == 'REQU' && settlementObject['Is_Materials_Request']=='1'">
                Material Advance request is being processed
              </h1>

              <h1 class="sr-title sm-h1"
                *ngIf="settlementObject && settlementObject['Status.Code'] == 'PEND' && settlementObject['Is_Materials_Request']=='1'">
                Draft Material Advance created
              </h1>


              <hr class="mt" />
              <div class="svg-box">
                <svg class="circular green-stroke">
                  <circle class="path" cx="75" cy="75" r="50" fill="none" stroke-width="5" stroke-miterlimit="10" />
                </svg>
                <svg class="checkmark green-stroke">
                  <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                    <path class="checkmark__check" fill="none" d="M616.306,283.025L634.087,300.805L673.361,261.53" />
                  </g>
                </svg>
              </div>

              <p *ngIf="settlementObject && settlementObject['Status.Code'] == 'PEND'">
                You can update the invoice details for this settlement anytime by clicking on
                <strong> Continue Settlement Request</strong> on <strong>Actions</strong> , or click on
                <strong>contract details</strong> for more options.
              </p>
              <p *ngIf="isPromoterOrAdmin == true && settlementObject['Status.Code'] == 'REQU'">
                As promoter you can Approve/Decline this settlement by clicking on <strong>Actions</strong>, or click on
                <strong>contract details</strong> for more options.
              </p>

              <mat-list class="small" *ngIf="settlementObject">
                <mat-list-item class="odd mb">
                  Status:
                  <span class="chip" [class.approved]="settlementObject['Status.Code'] == 'APPR'"
                    [class.cancelled]="settlementObject['Status.Code'] == 'WDRN'"
                    [class.declined]="settlementObject['Status.Code'] == 'DECL'"
                    [class.requested]="settlementObject['Status.Code'] == 'REQU'"
                    [class.pending]="settlementObject['Status.Code'] == 'PEND'">
                    {{ settlementObject['Status.Label'] }}
                  </span>
                </mat-list-item>

                <mat-list-item class="even">
                  Date of Request: <strong>{{ settlementObject['Settlement.Date'] | customDate }}</strong>
                </mat-list-item>
                <mat-list-item class="odd">
                  Invoice Number: <strong>{{ settlementObject['Invoice.Reference'] }}</strong>
                </mat-list-item>
                <mat-list-item class="even">
                  Invoice Amount: <strong>{{ settlementObject['Invoice.Amount'] | customCurrency }}</strong>
                </mat-list-item>
                <mat-list-item class="even">
                  Date of Invoice: <strong>{{ settlementObject['Invoice.Date'] | customDate }}</strong>
                </mat-list-item>
                <mat-list-item class="odd" *ngIf="settlementObject['Is_Materials_Request']!='1'">
                  Available Balance:
                  <strong>{{
                    toNumber(balance) - toNumber(settlementObject['Settlement.Amount']) | customCurrency
                    }}</strong>
                </mat-list-item>

                <mat-list-item class="odd" *ngIf="settlementObject['Is_Materials_Request']=='1'">
                  Available Balance:
                  <strong>{{
                    toNumber(settlementsNotYetDrawn) - toNumber(settlementObject['Settlement.Amount']) | customCurrency
                    }}</strong>
                </mat-list-item>

                <mat-list-item class="avail" *ngIf="
                    (settlementObject && !settlementObject.groupArray) ||
                    (settlementObject && settlementObject.groupArray && settlementObject.groupArray.length <= 0)
                  ">
                  <span *ngIf="settlementObject['Is_Materials_Request']!='1'">Settlement Value:</span>
                  <span *ngIf="settlementObject['Is_Materials_Request']=='1'">Material Advance Value:</span>

                  <strong>{{ settlementObject['Settlement.Amount'] | customCurrency }}</strong>
                </mat-list-item>

                <mat-list-item class="avail"
                  *ngIf="settlementObject && settlementObject.groupArray && settlementObject.groupArray.length > 0">
                  <span *ngIf="settlementObject['Is_Materials_Request']!='1'">Settlement Value:</span>
                  <span *ngIf="settlementObject['Is_Materials_Request']=='1'">Material Advance Value:</span>
                  <strong>{{
                    settlementObject.groupArray
                      | objectPropertyValue: 'Settlement.Amount'
                      | toNumberArray
                      | sum
                      | customCurrency
                    }}</strong>
                </mat-list-item>
              </mat-list>
              <hr class="mb" />
              <p class="larger" *ngIf="settlementObject['Is_Materials_Request']!='1'">
                You will recieve confirmation via email when the settlement is successfully processed.
              </p>
              <p class="larger" *ngIf="settlementObject['Is_Materials_Request']=='1'">
                You will recieve confirmation via email when the Material Advance is successfully processed.
              </p>
            </mat-card>
          </div>
        </div>
        <div class="row clearfix">
          <div class="full-width text-center step-button-row" *ngIf="isIntegrated != true">
            <button class="step-next mr" *ngIf="settlementObject && settlementObject['ID']" (click)="viewSettlement()"
              mat-raised-button color="accent">
              <mat-icon>visibility</mat-icon>
              View Settlement
            </button>

            <button class="full-back ml" *ngIf="settlementObject && settlementObject['ID']" (click)="contractDetails()"
              mat-raised-button color="primary">
              Contract Details
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <!--  / Step 3  -->
    </aw-wizard-completion-step>
  </aw-wizard>
</div>
