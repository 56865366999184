import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { isSameDay, isSameMonth } from 'date-fns';
import { Subject, timer } from 'rxjs';

@Component({
  selector: 'app-calendar-view',
  templateUrl: './calendar-view.component.html',
  styleUrls: ['./calendar-view.component.css'],
})
export class CalendarViewComponent implements OnInit {
  @Input() events: CalendarEventType[] = [];
  @Input() view: CalendarView = CalendarView.Month;
  @Input() viewDate: Date = new Date();
  @Input() canEdit = true;
  @Input() canRemove = true;
  @Input() viewList = false;
  @Input() tableLabel = 'Events';
  @Input() titleColumnLabel = 'Title';
  @Input() colorColumnLabel = 'Color';
  @Input() colorTypeColumnLabel = 'Color Type';
  @Input() startsAtColumnLabel = 'Start At';
  @Input() endsAtColumnLabel = 'Ends At';
  @Input() addEvent: EventEmitter<any>;
  @Input() editEvent: EventEmitter<any>;
  @Input() removeEvent: EventEmitter<any>;

  @Input() isMonthClickOpenDay = false;
  @Input() displayDayView = true;
  @Input() displayMonthView = true;
  @Input() displayWeekView = true;
  @Input() displayDaySelector = true;
  @Input() displayTodaySelector = true;
  @Input() zoomPanel = true;
  @Input() changeViewOnDayClick = true;

  @Input()
  activeDayIsOpen = false;
  @Input()
  CalendarView = CalendarView;
  @Input()
  dayStartHour = 0;
  @Input()
  dayStartMinute = 0;
  @Input()
  hourSegments = 1;

  @Input()
  dayEndHour = 23;

  @Input()
  dayEndMinute = 59;
  @Input()
  weekStartsOn = 1;
  @Input()
  hourSegmentHeight = 40;
  @Output() sendEdit = new EventEmitter();
  @Output() sendSelected = new EventEmitter();
  @Output() sendRemove = new EventEmitter();
  @Output() sendSelectedDay = new EventEmitter();
  @Output() getViewType = new EventEmitter();

  refresh: Subject<any> = new Subject();

  selectedDateEvents = [];
  displayedColumns = ['title', 'color', 'colorType', 'startsAt', 'endsAt', 'actions'];
  eventFrontColor = '#fff';

  toDayDate = new Date();

  monthDayClick = 0;
  clickSubscribe;

  constructor() {}

  ngOnInit() {
    if (this.events) {
      this.events = this.events.map((ev: CalendarEventType) => {
        return this.buildActions(ev);
      });
    }
    if (this.addEvent) {
      this.addEvent.subscribe((item: CalendarEventType) => {
        item = this.buildActions(item);
        this.events.push(item);
      });
    }

    if (this.editEvent) {
      this.editEvent.subscribe((item: CalendarEventType) => {
        if (item && item.id) {
          const idx = this.events.findIndex((ev) => ev.id === item.id);

          if (idx !== -1) {
            this.events[idx] = item;

            this.refresh.next();
          }
        }
      });
    }

    if (this.removeEvent) {
      this.removeEvent.subscribe((id) => {
        if (id) {
          const idx = this.events.findIndex((ev) => ev.id === id);

          if (idx !== -1) {
            this.events.splice(idx, 1);

            this.refresh.next();
          }
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.events) {
      this.events = this.events.map((ev: CalendarEventType) => {
        return this.buildActions(ev);
      });
    }
  }

  zomeIn() {
    this.hourSegmentHeight = this.hourSegmentHeight + 5;
  }

  zomeOut() {
    this.hourSegmentHeight = this.hourSegmentHeight - 5;
  }

  buildActions(ev: CalendarEventType) {
    ev.actions = ev.actions || [];
    if (this.canEdit) {
      ev.actions.push({
        label: '<i class="fas fa-fw fa-pencil-alt"></i>',
        a11yLabel: 'Edit',
        onClick: ({ event }: { event: CalendarEventType }): void => {
          this.handleEvent('Edit', event);
        },
      });
    }

    if (this.canRemove) {
      ev.actions.push({
        label: '<i class="fas fa-fw fa-trash-alt"></i>',
        a11yLabel: 'Delete',
        onClick: ({ event }: { event: CalendarEventType }): void => {
          this.handleEvent('Delete', event);
        },
      });
    }
    return ev;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEventType[] }): void {
    if (this.changeViewOnDayClick == true) {
      if (isSameDay(this.viewDate, date)) {
        this.monthDayClick++;
      } else {
        this.monthDayClick = 1;
      }

      if (this.clickSubscribe) {
        this.clickSubscribe.unsubscribe();
      }

      const stream$ = timer(350);
      this.clickSubscribe = stream$.subscribe((x) => {
        this.monthDayClick = 0;
      });
      this.toDayDate = date || null;
      if (this.isMonthClickOpenDay == true) {
        this.viewDate = date;
        this.view = CalendarView.Day;
      } else {
        if (this.monthDayClick >= 2) {
          this.viewDate = date;
          this.view = CalendarView.Day;
        } else {
          if (isSameMonth(date, this.viewDate)) {
            if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
              this.activeDayIsOpen = false;
              this.selectedDateEvents = [];
            } else {
              this.activeDayIsOpen = true;
              this.selectedDateEvents = events;
            }
            this.viewDate = date;
          }
        }
      }
    }
    this.sendSelectedDay.emit(date);
  }

  selectDate(d) {
    if (d) {
      this.viewDate = d;
      this.viewTypeChange(CalendarView.Day);
    }
  }

  viewToday() {
    this.viewDate = new Date();
    this.viewTypeChange(CalendarView.Day);
  }

  eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
  }

  dayHeaderClickedWeek(e) {
    if (e && e.day && e.day.date) {
      this.viewDate = e.day.date;
      this.viewTypeChange(CalendarView.Day);
    }
  }

  handleEvent(action: string, event: CalendarEventType): void {
    switch (action) {
      case 'Clicked':
        this.sendSelected.emit(event.id);
        break;
      case 'Edit':
        this.sendEdit.emit(event.id);
        break;
      case 'Delete':
        this.sendRemove.emit(event.id);
        break;
    }
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  viewTypeChange(t) {
    if (t) {
      this.view = t;
      this.getViewType.emit(t);
    }
  }

  changeHoursUP() {
    if (this.hourSegments < 21) {
      this.hourSegments = this.hourSegments + 1;
    }
  }

  changeHoursDown() {
    if (this.hourSegments > 0) {
      this.hourSegments = this.hourSegments - 1;
    }
  }
}

// You can add more colors
export const CalendarViewColors = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  grey: {
    primary: '#a1a1a1',
    secondary: '#d8d8d8',
  },
  green: {
    primary: '#2bff00',
    secondary: '#84f16e',
  },
};

class addedAttr {
  colorType?: string;
}

export type CalendarEventType = CalendarEvent & addedAttr;
