import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../services/utils.service';
import { ConfirmDialog } from '../../types/confirm-dialog';
import { NotifyAppComponent } from '../../types/notify-app-component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../root-component/root-component.component';

@Component({
  selector: 'app-email-label-input.component',
  templateUrl: './email-label-input.component.html',
  styleUrls: ['./email-label-input.component.css'],
})
export class EmailLabelInputComponent implements OnInit {
  @Input()
  ownerID;

  @Input()
  label;

  @Input()
  isEdit = false;

  @Output()
  getResult = new EventEmitter();

  @Output()
  close = new EventEmitter();

  isModal = false;

  @Input()
  myEmail = null;

  @Input()
  isPrimary = false;

  @Input()
  emailTypes;

  clearEmail = new EventEmitter();

  validateEmail = false;

  isRequired = true;
  @Input()
  type = 'current';
  sessionType;

  constructor(
    private utilService: UtilsService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.ownerID) {
        this.ownerID = data.ownerID;
      }
      if (data.type) {
        this.type = data.type;
      }
      if (data.isEdit) {
        this.isEdit = data.isEdit;
      }

      if (data.label) {
        this.label = data.label;
      }

      if (data.email) {
        this.myEmail = data.email;
      }

      if (data.isPrimary) {
        this.isPrimary = data.isPrimary;
      }

      if (data.emailTypes) {
        this.emailTypes = data.emailTypes;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((st) => {
      this.sessionType = st;

      if (this.isEdit == true) {
        this.isRequired = false;
      }
    });
  }

  closeModal() {
    this.close.emit(true);
  }

  getEmail(e) {
    this.myEmail = e;
  }

  validateEmailEvent(v) {
    this.validateEmail = v;
  }

  create() {
    const payload = {
      ownerID: this.ownerID,
      label: this.label,
      email: this.myEmail,
      isPrimary: this.isPrimary,
    };

    this.utilService.createEmailByLabel(payload, this.type, this.sessionType).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast(
          'success',
          'Create new Address',
          'You have successfully created a new email with label ' + res.Label
        );
        this.getResult.emit(res);
        this.emailTypes.push(this.label);
        this.close.emit(true);
      }
    });
  }

  modify() {
    const payload = {
      email: this.myEmail || 'void',
      isPrimary: this.isPrimary || false,
    };

    this.utilService
      .modifyEmailByLabel(this.ownerID, this.label, payload, this.type, this.sessionType)
      .subscribe((res) => {
        if (res) {
          NotifyAppComponent.displayToast(
            'success',
            'Update Address',
            'You have successfully updating the email address'
          );
          this.getResult.emit(res);
        }
      });
  }

  getEmailByType(e) {
    if (e) {
      this.utilService.getEmailByLabel(this.ownerID, e, this.type, this.sessionType).subscribe((res) => {
        if (res) {
          this.myEmail = res['Email'];
          this.isPrimary = res['IsPrimary'] == '1' ? true : false;
        }
      });
    } else {
      this.isPrimary = null;
      this.isEdit = false;
      this.clearEmail.emit(true);
      this.isRequired = true;
    }
  }

  convertToLowerCase(val) {
    if (val) {
      return val.toLowerCase();
    }
  }

  removeEmailByLabel() {
    const confirmDialog = new ConfirmDialog(
      'warning',
      'Delete Email',
      'Are you sure to remove this email with label permanently?',
      'Cancel',
      'Proceed'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      width: '650px',
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        this.utilService.deleteEmailByLabel(this.ownerID, this.label, this.type, this.sessionType).subscribe((res) => {
          if (res) {
            NotifyAppComponent.displayToast('success', 'Delete Email', 'You have successfully deleted this email');
            this.emailTypes.splice(this.emailTypes.indexOf(this.label), 1);
            ref.close();
            this.close.emit(true);
          }
        });
      } else {
        ref.close();
      }
    });
  }
}
