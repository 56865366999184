import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ContractService } from '../shared/contract.service';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { QuotationDecisionModalComponent } from '../../quotation/quotation-decision-modal/quotation-decision-modal.component';

@Component({
  selector: 'app-contract-credit-check',
  templateUrl: './contract-credit-check.component.html',
  styleUrls: ['./contract-credit-check.component.css'],
})
export class ContractCreditCheckComponent implements OnInit {
  @Input()
  contractID;

  @Input()
  isDisplayFull = true;

  @Input()
  checkstatus;

  @Output()
  close = new EventEmitter();

  isModal = false;

  contract;
  isPromoterOrAdmin = false;
  creditDetails;

  gaugeType = 'semi';
  // gaugeValue = 28.3;
  gaugeLabel = 'Equifax Applied Score';

  moreIsBetter = {
    '100': { color: '#db291b' },
    '300': { color: '#ff5722' },
    '400': { color: '#ff9800' },
    '600': { color: '#ffc107' },
    '700': { color: '#ffeb3b' },
    '800': { color: '#8bc34a' },
  };

  constructor(
    private activeRouter: ActivatedRoute,
    private contractSerivce: ContractService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.contractID = data;
      this.isModal = true;
    }
  }

  ngOnInit() {
    this.activeRouter.params.subscribe((params) => {
      if (params['contractID']) {
        this.contractID = params['contractID'];
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        this.contractSerivce.fetchContractDetails$(this.contractID, null, this.isPromoterOrAdmin).subscribe((res) => {
          this.contract = res;

          this.contractSerivce.getCreditCheckDetails(this.contract['ID']).subscribe((res) => {
            this.creditDetails = res;
          });
        });
      });
    });
  }

  closeEvent() {
    this.close.emit(true);
  }

  // decision modal
  makeDecision() {
    const ref = RootAppComponent.dialog.open(QuotationDecisionModalComponent, {
      data: {
        title: 'Credit check',
      },
      width: '600px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }
}
