import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { BlobViewComponent } from '../../../shared/components/blob-view/blob-view.component';
import { ConfirmDialogSingleButtonComponent } from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { FilterArrayMultipleValue } from '../../../shared/pipes/filter-array-object.pipe';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { ContractViewSimpleComponent } from '../../contract/contract-view-simple/contract-view-simple.component';
import { ContractService } from '../../contract/shared/contract.service';
import { CustomerViewComponent } from '../../customer/customer-view/customer-view.component';
import { CustomerService } from '../../customer/shared/customer.service';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { SettlementRefundIntroModalComponent } from '../settlement-refund-intro-modal/settlement-refund-intro-modal.component';
import { SettlementViewComponent } from '../settlement-view/settlement-view.component';
import { SettlementService } from '../shared/settlement.service';

import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { HeaderService } from '../../../shared/services/header.service';

@Component({
  selector: 'app-settlement-refund-create',
  templateUrl: './settlement-refund-create.component.html',
  styleUrls: ['./settlement-refund-create.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.anim', style({ opacity: 0 }), { optional: true }),
        query(
          '.anim',
          stagger('100ms', [
            animate(
              '0.2s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
    trigger('onChangeAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.3s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-10%)', offset: 0, height: 0, margin: 0 }),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*', margin: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.3s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*', margin: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(5%)', offset: 1.0, height: 0, margin: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class SettlementRefundCreateComponent implements OnInit {
  @Input() contractID = null;
  @Input() customerID = null;

  filterEmail = '';
  filterName = '';
  filterPhone = '';
  filterCustomer;

  isSkipLookup = false;
  hideLookup = false;
  selectedContract = null;
  selectedCustomer = null;
  contracts: any = [];
  customers: any = [];
  displayContractList = false;
  displayCustomerList = false;

  isPromoterOrAdmin = false;

  merchantID = 'none';
  merchants = [];
  hideMerchantSelect = false;

  settlementChart;
  charColors = ['#FF0000', '#1ca90c'];

  balance;
  settlementAmountInput;
  settlementPendingStatus = ['PEND', 'REQU'];
  requestedPendingAmount = 0;
  settlementfileID;
  settlementAmount;
  settlementObject;

  step = 0;

  authorisationFormContent;
  authorisationFormTitle = 'Authorisation form unsigned';
  authorisationFormDescription = 'To proceed you must either download or print this form for the patient to sign';

  option = 'securityCode';

  timeLeft: number;
  interval;

  securityCodeID;
  securityCode;
  smsSent = false;
  emailSent = false;

  settings = Settings.global;

  isConfirmed = false;

  sessionType = 'guest';
  @ViewChild(WizardComponent, { static: false })
  public wizard: WizardComponent;

  constructor(
    private router: Router,
    private customerService: CustomerService,
    private contractService: ContractService,
    private merchantService: MerchantService,
    private settlementService: SettlementService,
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private FilterArrayMultipleValue: FilterArrayMultipleValue
  ) {
    this.settlementAmountInput = new FormControl('');
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      this.activeRoute.params.subscribe((params) => {
        if (params['customerKey']) {
          this.customerID = params['customerKey'];
        }

        if (params['contractKey']) {
          this.contractID = params['contractKey'];
        }
        this.authenticationService.getSessionType().subscribe((sessionType) => {
          if (sessionType) {
            this.sessionType = sessionType;
          }

          if (this.isPromoterOrAdmin == true && !this.customerID && !this.contractID) {
            HeaderService.getPromoterViewAsObject().subscribe((obj) => {
              if (obj && obj['promoterViewAs'] && obj['promoterViewAs'] == 'merchant') {
                this.merchantID = obj.merchantID;
                this.hideMerchantSelect = true;
              } else if (obj && obj['promoterViewAs'] && obj['promoterViewAs'] == 'promoter') {
                this.merchantID = null;
                this.hideMerchantSelect = false;
              }
            });
            const temp = localStorage.getItem('selectedMerchant');

            const payload = {
              fields: 'ID,TradingAs,CalculatedName,Status',
            };
            this.merchantService.getList(payload).subscribe((res) => {
              this.merchants = res;
            });

            if (temp) {
              this.merchantID = temp;
              this.hideMerchantSelect = true;
            }
          }

          if (this.customerID && !this.contractID) {
            this.customerID = this.customerID;
            this.customerService.getCustomerDetails(this.customerID, {}, this.sessionType).subscribe((res) => {
              if (res) {
                this.selectedCustomer = res;

                this.contractLookup();
              }
            });
          } else if (this.contractID) {
            this.isSkipLookup = true;
            const payload = {
              selectStats: true,
            };
            this.contractService
              .fetchContractDetails$(this.contractID, payload, this.isPromoterOrAdmin)
              .subscribe((res1) => {
                this.customerID = res1['Customer_key'];
                this.selectContract(res1);

                this.customerService.getCustomerDetails(this.customerID, {}, this.sessionType).subscribe((res) => {
                  if (res) {
                    this.selectedCustomer = res;

                    this.isSkipLookup = true;

                    this.contractID = this.selectedContract['ID'];
                    this.balance = this.selectedContract['Settlements.NotYetDrawn'];

                    if (Number(this.selectedContract['Settlements.NotYetDrawn']) > 0) {
                      this.balance = Number(this.selectedContract['Settlements.NotYetDrawn']);
                      this.settlementAmountInput = new FormControl('', [
                        Validators.max(this.selectedContract['Settlements.NotYetDrawn']),
                        Validators.min(0),
                      ]);

                      const requestedPendingArray = this.FilterArrayMultipleValue.transform(
                        this.selectedContract['stats'],
                        'ID',
                        this.settlementPendingStatus
                      );

                      this.requestedPendingAmount = 0;

                      for (let i = 0; i < requestedPendingArray.length; i++) {
                        this.requestedPendingAmount =
                          this.requestedPendingAmount + Number(requestedPendingArray['Sum(Settlement.Amount)']);
                      }

                      this.settlementChart = [
                        {
                          name: ' Paid/Approved for payment',
                          value: Number(this.selectedContract['Amount.Invoice']) - Number(this.balance),
                        },
                        {
                          name: 'Available Balance',
                          value: this.balance,
                        },
                      ];
                    } else {
                      NotifyAppComponent.displayToast(
                        'warning',
                        'Sorry!',
                        'No Balance left to drawdown for this contract'
                      );
                    }
                  }
                });
              });
          }
        });
      });
    });
  }

  getCustomer() {
    let payload = {};
    if (this.isPromoterOrAdmin == true) {
      payload = {
        email: this.filterEmail,
        name: this.filterName,
        fields: 'ID,FirstName,Name,mobiles.Number,emails.Email',
        phone: this.filterPhone,
        hasActiveOrSuspendedOrCompleteContract: true,
      };
      if (this.merchantID && this.merchantID !== 'none') {
        payload['merchantID'] = this.merchantID;
      }
    } else {
      payload = {
        email: this.filterEmail,
        fields: 'ID,FirstName,Name,mobiles.Number,emails.Email',
        name: this.filterName,
        phone: this.filterPhone,
        hasActiveOrSuspendedOrCompleteContract: true,
      };
    }
    this.customerService.customerLookup(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res && res.length > 0) {
        this.customers = res;
        this.displayCustomerList = true;
        this.displayContractList = false;

        // if (this.customers && this.customers.length == 1) {
        //   this.selectCustomer(this.customers[0])
        // }
      } else {
        NotifyAppComponent.displayToast(
          'warning',
          'Sorry!',
          'We did not find any match of customer with active contract in our database.'
        );
      }
    });
  }

  selectCustomer(c) {
    this.selectedCustomer = c;

    // this.wizard.goToNextStep();
    this.contractLookup();
  }

  openCustomerDetailsDialog(id) {
    const ref = RootAppComponent.dialog.open(CustomerViewComponent, {
      data: id,
      width: '600px',
      panelClass: 'noCard',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  openContractDetailsDialog(id) {
    const ref = RootAppComponent.dialog.open(ContractViewSimpleComponent, {
      data: id,
      width: '800px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  buildContractView(moveNext = true) {
    this.hideLookup = true;

    this.contractID = this.selectedContract['ID'];
    const payload = {
      selectStats: true,
    };
    this.contractService.fetchContractDetails$(this.contractID, payload, this.isPromoterOrAdmin).subscribe((res1) => {
      if (res1) {
        this.selectedContract = res1;

        if (Number(this.selectedContract['Settlements.NotYetDrawn']) > 0) {
          this.balance = Number(this.selectedContract['Settlements.NotYetDrawn']);

          this.settlementAmountInput = new FormControl('', [
            Validators.max(this.selectedContract['Settlements.NotYetDrawn']),
            Validators.min(0),
          ]);

          const requestedPendingArray = this.FilterArrayMultipleValue.transform(
            this.selectedContract['stats'],
            'ID',
            this.settlementPendingStatus
          );

          this.requestedPendingAmount = 0;

          for (let i = 0; i < requestedPendingArray.length; i++) {
            this.requestedPendingAmount =
              this.requestedPendingAmount + Number(requestedPendingArray['Sum(Settlement.Amount)']);
          }

          this.settlementChart = [
            {
              name: ' Paid/Approved for payment',
              value: Number(this.selectedContract['Amount.Invoice']) - Number(this.balance),
            },
            {
              name: 'Available Balance',
              value: this.balance,
            },
          ];
        } else {
          NotifyAppComponent.displayToast('warning', 'Sorry!', 'No Balance left to drawdown for this contract');
        }

        if (moveNext == true) {
          this.wizard.goToNextStep();
        }
      }
    });
  }

  contractLookup() {
    if (this.selectedCustomer) {
      const payload = {
        customerID: this.selectedCustomer['ID'],
        fields:
          'ID,ProductName,Settlements.NotYetDrawn,ApplicationDate,Amount.Invoice,' +
          'Settlements.Drawn,Customer.FullName,NextDrawDown.Before',
      };
      this.contractService.getContractList(payload, this.isPromoterOrAdmin).subscribe((res) => {
        const tempList = [];
        res.map((x) => {
          if (Number(x['Settlements.NotYetDrawn']) > 0) {
            tempList.push(x);
          }
        });
        if (tempList.length <= 0) {
          NotifyAppComponent.displayToast(
            'warning',
            'Sorry!',
            'We did not find any contract with active contract in our database.'
          );
          this.contracts = tempList;
          this.goBackCustomerList();
        } else if (tempList.length == 1) {
          this.customerID = this.selectedCustomer['ID'];
          this.contracts = tempList;
          this.selectContract(this.contracts[0]);
          this.requestSettlement();
        } else {
          this.customerID = this.selectedCustomer['ID'];
          this.contracts = tempList;
          this.displayContractList = true;
          this.displayCustomerList = false;
        }

        // if (this.contracts && this.contracts.length == 1) {
        //   this.selectContract(this.contracts[0])
        // }
      });
    }
  }

  selectContract(c) {
    this.selectedContract = c;
  }

  requestSettlement() {
    const ref = RootAppComponent.dialog.open(SettlementRefundIntroModalComponent, {
      width: '750px',
      panelClass: 'noCard',
    });
    if (ref.componentInstance.showOnSettlementCreate == true) {
      ref.close();
      ref.afterClosed().subscribe((res) => {
        this.requestSettlementSubStep();
      });
    } else {
      ref.componentInstance.closeModal.subscribe((data) => {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          this.requestSettlementSubStep();
        });
      });
    }

    ref.backdropClick().subscribe(() => {
      ref.close();
      ref.afterClosed().subscribe((res) => {
        this.requestSettlementSubStep();
      });
    });
  }

  requestSettlementSubStep() {
    const canDrowDown = this.canDrawDown(this.selectedContract);

    if (Number(this.selectedContract['Settlements.NotYetDrawn']) > 0 && canDrowDown == true) {
      this.buildContractView(true);
    } else {
      const confirmMsg = new ConfirmDialogSingleButton(
        'block',
        'Cannot proceed further',
        '<p>There is no balance left to create a new settlement</p>',
        'Go Back'
      );
      const ref = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
        data: confirmMsg,
      });
      ref.componentInstance.onConfirm.subscribe((res) => {
        ref.close();
      });
    }
  }

  canDrawDown(c) {
    return true;
  }

  goBackCustomerLlookup() {
    this.displayContractList = false;
    this.displayCustomerList = false;
    this.customerID = null;
    this.contractID = null;
  }

  goBackCustomerList() {
    this.displayContractList = false;
    this.displayCustomerList = true;
    this.customerID = null;
  }

  goBackContractList() {
    this.hideLookup = false;
    this.displayContractList = true;
    this.displayCustomerList = false;
    this.contractID = null;
    this.settlementfileID = null;
    this.settlementAmount = null;
    this.wizard.goToPreviousStep();
  }

  removeAuthorisationForm(e) {
    if (e && e == this.settlementfileID) {
      this.settlementfileID = null;
    }
  }

  onCompleteAllForm(e) {
    if (e && e[0]) {
      this.settlementfileID = e[0];
    }
  }

  goBackCreateSettlement() {
    this.option = null;
    this.wizard.goToPreviousStep();

    if (this.settlementObject) {
      this.settlementService.cancel(this.settlementObject['ID'], this.isPromoterOrAdmin).subscribe((res) => {
        if (res) {
          // NotifyAppComponent.displayToast(
          //   'success',
          //   'Success!',
          //   'Your Settlement has been withdrawn'
          // );
        }
      });
    }
  }

  proceedWithCode() {
    this.option = 'securityCode';
    this.createSettlement();
  }

  saveDraft() {
    const confirm = new ConfirmDialog(
      'warning',
      'Are you sure ?',
      'If you choose to authorise later, the settlement will not be processed until you return and complete the authorisation. Are you sure you want to authorise later?',
      'Authorise now',
      'Authorise later'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
      width: '650px',
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        ref.close();
        if (!this.settlementObject) {
          this.createSettlement();
        }
        // if (this.isSkipLookup==true) {
        //   this.wizard.goToStep(3);
        // } else {
        //   this.wizard.goToStep(4);
        // }
      } else {
        ref.close();
      }
    });
  }

  createSettlement() {
    let payload;

    if (this.option == 'document') {
      payload = {
        contractID: this.selectedContract['ID'],
        settlementAmount: this.settlementAmount,
        fileID: this.settlementfileID,
      };
    } else {
      payload = {
        contractID: this.selectedContract['ID'],
        settlementAmount: this.settlementAmount,
        // securityCode: this.securityCode,
        // securityCodeID: this.securityCodeID,
      };
    }

    if (this.isPromoterOrAdmin == true && this.merchantID != 'none') {
      payload['merchantID'] = this.merchantID;
    }

    this.settlementService.createRefund(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        this.settlementObject = res;
        NotifyAppComponent.displayToast('success', 'Success!', 'Your Settlement has been created.');

        this.wizard.goToNextStep();

        if (this.option == 'securityCode') {
          const payload = {
            customerID: this.selectedCustomer['ID'],
            settlementID: this.settlementObject['ID'],
            contractID: this.contractID,
          };

          this.settlementService.requestSettlementCode(payload).subscribe((res) => {
            if (res) {
              this.countDown();
              this.smsSent = true;

              this.emailSent = false;
              if (res && res['IsValid'] == '1') {
                this.emailSent = true;
              }

              this.securityCodeID = res.ID;
            }
          });
        }
      }
    });
  }

  validateSettlementCode() {
    if (this.settlementObject && this.securityCodeID && this.securityCode) {
      const payload = {
        settlementID: this.settlementObject['ID'],
        securityCodeID: this.securityCodeID,
        securityCode: this.securityCode,
        cardID: this.selectedCustomer['ID'],
      };

      this.settlementService.validateSettlementCode(payload).subscribe((res) => {
        if (res) {
          this.settlementObject = res;
          NotifyAppComponent.displayToast(
            'success',
            'Successful operation',
            'The settlement has been authorised by security code'
          );
          this.wizard.goToNextStep();
        }
      });
    }
  }

  countDown() {
    this.timeLeft = 60;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  viewMerchant() {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: this.merchantID,
      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    if (this.isConfirmed == true) {
      this.step++;
    } else {
      const confirmMsg = new ConfirmDialog(
        'warning',
        'Create Refund',
        'You are about to create refund to the customer. <br> This will reduce how much Smile Right will pay you for this treatment by $' +
          this.settlementAmountInput.value +
          ' and an amount of $' +
          this.settlementAmountInput.value +
          ' will be applied to the patients loan with Smile Right. <br><br> <strong>Once submitted for processing this cannot be undone. Are you sure about this?</strong>',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmMsg,
        width: '650px',
        disableClose: true,
      });

      ref.componentInstance.onConfirm.subscribe((res) => {
        if (res == true) {
          this.step++;
          ref.close();
          this.isConfirmed = true;
        } else {
          this.isConfirmed = false;
          ref.close();
          this.router.navigate(['/merchant', { outlets: { page: ['settlement-customer-list-overview'] } }]);
        }
      });
    }
  }

  prevStep() {
    this.step--;
  }

  proceedWithForm() {
    const payload = {
      settlementAmount: this.settlementAmount,
      isRefund: true,
    };

    this.settlementService.getAuthorisationForm(this.contractID, payload).subscribe((res) => {
      if (res) {
        this.option = 'document';

        this.authorisationFormContent = res;
        const ref = RootAppComponent.dialog.open(BlobViewComponent, {
          data: {
            labelProceed: 'Proceed',
            labelPrint: 'Print',
            labelDownload: 'Download',
            content: this.authorisationFormContent,
            title: this.authorisationFormTitle,
            description: this.authorisationFormDescription,
            isDownload: true,
            isPrint: true,
            isProceed: true,
            label: 'Authorisation form for ' + this.selectedContract['Customer.FullName'],
          },
          width: '800px',
          panelClass: 'ScrollNoCard',
        });

        ref.componentInstance.closeModal.subscribe((res) => {
          ref.close();
        });

        ref.componentInstance.proceed.subscribe((res) => {
          if (res == true) {
            ref.close();
            this.wizard.goToNextStep();
          }
        });
      }
    });
  }

  viewSettlement() {
    const ref = RootAppComponent.dialog.open(SettlementViewComponent, {
      data: this.settlementObject['ID'],
      width: '850px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  contractDetails() {
    this.router.navigate([
      '/merchant',
      { outlets: { page: ['contract-view', this.settlementObject['Contract_key']] } },
    ]);
  }

  toNumber(n) {
    if (n) {
      return Number(n);
    } else {
      return 0;
    }
  }
}
